const UnitsPovažskáBystrica = {
  Brvnište: [
    { lat: 49.229352, lng: 18.442405 },
    { lat: 49.229139, lng: 18.4405838 },
    { lat: 49.2287577, lng: 18.4393283 },
    { lat: 49.2281896, lng: 18.4380204 },
    { lat: 49.2277037, lng: 18.436626 },
    { lat: 49.2277378, lng: 18.4360163 },
    { lat: 49.2279862, lng: 18.4348697 },
    { lat: 49.2273295, lng: 18.4333228 },
    { lat: 49.2263602, lng: 18.4304446 },
    { lat: 49.2264003, lng: 18.4304333 },
    { lat: 49.2263447, lng: 18.4300362 },
    { lat: 49.2261028, lng: 18.4295526 },
    { lat: 49.225889, lng: 18.4293194 },
    { lat: 49.225505, lng: 18.4290625 },
    { lat: 49.225058, lng: 18.4283608 },
    { lat: 49.2251289, lng: 18.4272895 },
    { lat: 49.2245746, lng: 18.4264808 },
    { lat: 49.22447, lng: 18.4248449 },
    { lat: 49.2245604, lng: 18.4242101 },
    { lat: 49.224869, lng: 18.4240403 },
    { lat: 49.2246759, lng: 18.4233577 },
    { lat: 49.2246465, lng: 18.4232519 },
    { lat: 49.2245749, lng: 18.4229998 },
    { lat: 49.2243918, lng: 18.422662 },
    { lat: 49.2243599, lng: 18.42237 },
    { lat: 49.2246523, lng: 18.4222925 },
    { lat: 49.224544, lng: 18.421716 },
    { lat: 49.2241094, lng: 18.4212995 },
    { lat: 49.2236361, lng: 18.4211052 },
    { lat: 49.2231749, lng: 18.4212121 },
    { lat: 49.2229785, lng: 18.4210655 },
    { lat: 49.222664, lng: 18.4211068 },
    { lat: 49.2224214, lng: 18.421192 },
    { lat: 49.2222416, lng: 18.4208329 },
    { lat: 49.2220764, lng: 18.4208231 },
    { lat: 49.222048, lng: 18.4207199 },
    { lat: 49.2218896, lng: 18.4205439 },
    { lat: 49.221869, lng: 18.4203964 },
    { lat: 49.2218294, lng: 18.4202916 },
    { lat: 49.2218031, lng: 18.4202212 },
    { lat: 49.2217966, lng: 18.4202058 },
    { lat: 49.2216931, lng: 18.4199882 },
    { lat: 49.2216843, lng: 18.4199695 },
    { lat: 49.2216522, lng: 18.4199022 },
    { lat: 49.2216498, lng: 18.4198966 },
    { lat: 49.2216306, lng: 18.419858 },
    { lat: 49.2216119, lng: 18.4198226 },
    { lat: 49.221519, lng: 18.419651 },
    { lat: 49.2215165, lng: 18.4196477 },
    { lat: 49.2215101, lng: 18.4196352 },
    { lat: 49.2214964, lng: 18.4196097 },
    { lat: 49.2213245, lng: 18.4192947 },
    { lat: 49.2213006, lng: 18.4192692 },
    { lat: 49.221279, lng: 18.4192458 },
    { lat: 49.221263, lng: 18.4192212 },
    { lat: 49.2212507, lng: 18.4192026 },
    { lat: 49.2212471, lng: 18.4191978 },
    { lat: 49.2211842, lng: 18.4191025 },
    { lat: 49.2211793, lng: 18.4190959 },
    { lat: 49.221162, lng: 18.4190696 },
    { lat: 49.2211362, lng: 18.4190299 },
    { lat: 49.2208709, lng: 18.4185723 },
    { lat: 49.2208333, lng: 18.4185087 },
    { lat: 49.2206886, lng: 18.4182758 },
    { lat: 49.2205451, lng: 18.4181073 },
    { lat: 49.2204653, lng: 18.417967 },
    { lat: 49.2204253, lng: 18.4178386 },
    { lat: 49.2204071, lng: 18.4177518 },
    { lat: 49.2203736, lng: 18.4175952 },
    { lat: 49.2203833, lng: 18.4175864 },
    { lat: 49.2202357, lng: 18.417099 },
    { lat: 49.2201698, lng: 18.416885 },
    { lat: 49.220156, lng: 18.4168361 },
    { lat: 49.2201299, lng: 18.4167519 },
    { lat: 49.2201173, lng: 18.4167091 },
    { lat: 49.219966, lng: 18.416193 },
    { lat: 49.2199558, lng: 18.4161711 },
    { lat: 49.219953, lng: 18.4161621 },
    { lat: 49.2198805, lng: 18.4160521 },
    { lat: 49.2197315, lng: 18.4158275 },
    { lat: 49.2195974, lng: 18.4156238 },
    { lat: 49.2195238, lng: 18.4155366 },
    { lat: 49.2194677, lng: 18.4154633 },
    { lat: 49.219439, lng: 18.4154312 },
    { lat: 49.2194304, lng: 18.4154206 },
    { lat: 49.2193177, lng: 18.4152754 },
    { lat: 49.2193197, lng: 18.415273 },
    { lat: 49.2194812, lng: 18.4149473 },
    { lat: 49.2196088, lng: 18.4146203 },
    { lat: 49.2196321, lng: 18.414268 },
    { lat: 49.2195827, lng: 18.4139627 },
    { lat: 49.219495, lng: 18.4137057 },
    { lat: 49.2193905, lng: 18.4134634 },
    { lat: 49.2192289, lng: 18.4131524 },
    { lat: 49.2191572, lng: 18.4128971 },
    { lat: 49.2191349, lng: 18.4125917 },
    { lat: 49.2191432, lng: 18.4118201 },
    { lat: 49.2192118, lng: 18.4114084 },
    { lat: 49.2192147, lng: 18.4111962 },
    { lat: 49.2192085, lng: 18.4110402 },
    { lat: 49.2195822, lng: 18.4107825 },
    { lat: 49.2195662, lng: 18.4104923 },
    { lat: 49.2196589, lng: 18.4100248 },
    { lat: 49.2195056, lng: 18.4093286 },
    { lat: 49.2196184, lng: 18.409145 },
    { lat: 49.2193728, lng: 18.4087026 },
    { lat: 49.2192503, lng: 18.4080969 },
    { lat: 49.2188887, lng: 18.4074718 },
    { lat: 49.2186891, lng: 18.4076559 },
    { lat: 49.2184439, lng: 18.4078339 },
    { lat: 49.2183682, lng: 18.4072154 },
    { lat: 49.2179643, lng: 18.4067668 },
    { lat: 49.2176184, lng: 18.4061597 },
    { lat: 49.2172868, lng: 18.4056889 },
    { lat: 49.2178835, lng: 18.4053616 },
    { lat: 49.217671, lng: 18.4045124 },
    { lat: 49.2178597, lng: 18.4044791 },
    { lat: 49.2176141, lng: 18.4032807 },
    { lat: 49.2170389, lng: 18.4035045 },
    { lat: 49.2165321, lng: 18.4033388 },
    { lat: 49.2159311, lng: 18.4025645 },
    { lat: 49.2155725, lng: 18.4027907 },
    { lat: 49.2153712, lng: 18.4022483 },
    { lat: 49.2153238, lng: 18.4014714 },
    { lat: 49.2152144, lng: 18.4002853 },
    { lat: 49.2150476, lng: 18.3992903 },
    { lat: 49.2149505, lng: 18.3982676 },
    { lat: 49.2150399, lng: 18.3969456 },
    { lat: 49.214814, lng: 18.3965276 },
    { lat: 49.2140964, lng: 18.3968334 },
    { lat: 49.2133994, lng: 18.3959191 },
    { lat: 49.2131488, lng: 18.3960383 },
    { lat: 49.2130918, lng: 18.3969437 },
    { lat: 49.2133826, lng: 18.3989821 },
    { lat: 49.2134191, lng: 18.3993396 },
    { lat: 49.2136051, lng: 18.4005206 },
    { lat: 49.2136432, lng: 18.4010698 },
    { lat: 49.2135566, lng: 18.4017658 },
    { lat: 49.2119544, lng: 18.4020053 },
    { lat: 49.2115876, lng: 18.4022076 },
    { lat: 49.2114551, lng: 18.4023378 },
    { lat: 49.2110431, lng: 18.4028861 },
    { lat: 49.2101286, lng: 18.4038344 },
    { lat: 49.2100977, lng: 18.4038665 },
    { lat: 49.2100508, lng: 18.4039148 },
    { lat: 49.2093697, lng: 18.4043682 },
    { lat: 49.2085299, lng: 18.4049935 },
    { lat: 49.2082536, lng: 18.4049954 },
    { lat: 49.2080158, lng: 18.405307 },
    { lat: 49.2072067, lng: 18.4054023 },
    { lat: 49.2063636, lng: 18.4056174 },
    { lat: 49.2061977, lng: 18.4056175 },
    { lat: 49.2060707, lng: 18.4055443 },
    { lat: 49.2060274, lng: 18.405578 },
    { lat: 49.2057731, lng: 18.4057548 },
    { lat: 49.2042756, lng: 18.4066841 },
    { lat: 49.2043264, lng: 18.4069272 },
    { lat: 49.2034271, lng: 18.4084759 },
    { lat: 49.2032191, lng: 18.4090632 },
    { lat: 49.2030681, lng: 18.4092927 },
    { lat: 49.2022063, lng: 18.4101712 },
    { lat: 49.2017242, lng: 18.4105506 },
    { lat: 49.2016603, lng: 18.4106006 },
    { lat: 49.2006375, lng: 18.4115663 },
    { lat: 49.2005645, lng: 18.4116352 },
    { lat: 49.2000017, lng: 18.4121662 },
    { lat: 49.1997428, lng: 18.4125861 },
    { lat: 49.1995016, lng: 18.4132458 },
    { lat: 49.1994102, lng: 18.4135503 },
    { lat: 49.1992632, lng: 18.4137533 },
    { lat: 49.1989595, lng: 18.4142777 },
    { lat: 49.1984283, lng: 18.4149364 },
    { lat: 49.1988128, lng: 18.4158974 },
    { lat: 49.1988443, lng: 18.4164041 },
    { lat: 49.1981886, lng: 18.4168501 },
    { lat: 49.1981193, lng: 18.4171896 },
    { lat: 49.19864, lng: 18.4178969 },
    { lat: 49.1993141, lng: 18.4179728 },
    { lat: 49.1997928, lng: 18.4180809 },
    { lat: 49.2001109, lng: 18.4182865 },
    { lat: 49.2006744, lng: 18.4188516 },
    { lat: 49.2002237, lng: 18.4198846 },
    { lat: 49.2012335, lng: 18.4206708 },
    { lat: 49.2020226, lng: 18.4222348 },
    { lat: 49.202996, lng: 18.423446 },
    { lat: 49.2036244, lng: 18.4248635 },
    { lat: 49.2037589, lng: 18.4257914 },
    { lat: 49.2044175, lng: 18.4272027 },
    { lat: 49.2044501, lng: 18.4275289 },
    { lat: 49.2045289, lng: 18.4276671 },
    { lat: 49.2046951, lng: 18.4280253 },
    { lat: 49.2047179, lng: 18.4292395 },
    { lat: 49.2049719, lng: 18.4300862 },
    { lat: 49.2049686, lng: 18.4303746 },
    { lat: 49.2049615, lng: 18.4309237 },
    { lat: 49.2049582, lng: 18.4312131 },
    { lat: 49.2049569, lng: 18.431227 },
    { lat: 49.2049394, lng: 18.4313389 },
    { lat: 49.2049818, lng: 18.4315776 },
    { lat: 49.2050732, lng: 18.4320923 },
    { lat: 49.2051057, lng: 18.4322764 },
    { lat: 49.2051818, lng: 18.4324092 },
    { lat: 49.2051969, lng: 18.4324348 },
    { lat: 49.2052793, lng: 18.4325785 },
    { lat: 49.2052979, lng: 18.4326113 },
    { lat: 49.2053253, lng: 18.432659 },
    { lat: 49.2056075, lng: 18.4332775 },
    { lat: 49.2058875, lng: 18.4342067 },
    { lat: 49.2060179, lng: 18.4348967 },
    { lat: 49.2060448, lng: 18.4350383 },
    { lat: 49.2063211, lng: 18.4364934 },
    { lat: 49.2063354, lng: 18.4365897 },
    { lat: 49.206407, lng: 18.4369516 },
    { lat: 49.206694, lng: 18.4397139 },
    { lat: 49.2080734, lng: 18.4431864 },
    { lat: 49.208131, lng: 18.4437449 },
    { lat: 49.2084584, lng: 18.4443654 },
    { lat: 49.2101499, lng: 18.4468684 },
    { lat: 49.2105156, lng: 18.4473657 },
    { lat: 49.210877, lng: 18.448076 },
    { lat: 49.211025, lng: 18.447855 },
    { lat: 49.211402, lng: 18.447461 },
    { lat: 49.212336, lng: 18.446647 },
    { lat: 49.212646, lng: 18.446504 },
    { lat: 49.212696, lng: 18.446482 },
    { lat: 49.212717, lng: 18.446472 },
    { lat: 49.212781, lng: 18.446566 },
    { lat: 49.213151, lng: 18.446555 },
    { lat: 49.213339, lng: 18.446569 },
    { lat: 49.213514, lng: 18.44662 },
    { lat: 49.213791, lng: 18.446836 },
    { lat: 49.213896, lng: 18.446956 },
    { lat: 49.214031, lng: 18.447129 },
    { lat: 49.214142, lng: 18.447306 },
    { lat: 49.214274, lng: 18.447441 },
    { lat: 49.21459, lng: 18.447662 },
    { lat: 49.214593, lng: 18.447665 },
    { lat: 49.214626, lng: 18.447686 },
    { lat: 49.214656, lng: 18.447707 },
    { lat: 49.214722, lng: 18.447751 },
    { lat: 49.214804, lng: 18.447808 },
    { lat: 49.214995, lng: 18.447966 },
    { lat: 49.215119, lng: 18.448117 },
    { lat: 49.215552, lng: 18.448925 },
    { lat: 49.216059, lng: 18.449796 },
    { lat: 49.216669, lng: 18.45065 },
    { lat: 49.217098, lng: 18.450429 },
    { lat: 49.217308, lng: 18.450321 },
    { lat: 49.217567, lng: 18.450234 },
    { lat: 49.217624, lng: 18.450248 },
    { lat: 49.217701, lng: 18.450296 },
    { lat: 49.217748, lng: 18.450362 },
    { lat: 49.217869, lng: 18.450523 },
    { lat: 49.217959, lng: 18.450643 },
    { lat: 49.218031, lng: 18.450754 },
    { lat: 49.218078, lng: 18.450662 },
    { lat: 49.218215, lng: 18.450792 },
    { lat: 49.218394, lng: 18.450536 },
    { lat: 49.218428, lng: 18.450488 },
    { lat: 49.218596, lng: 18.450246 },
    { lat: 49.218792, lng: 18.449962 },
    { lat: 49.219405, lng: 18.449374 },
    { lat: 49.219708, lng: 18.449074 },
    { lat: 49.219738, lng: 18.449046 },
    { lat: 49.219929, lng: 18.44878 },
    { lat: 49.219969, lng: 18.448236 },
    { lat: 49.219975, lng: 18.448156 },
    { lat: 49.220005, lng: 18.448024 },
    { lat: 49.220015, lng: 18.447972 },
    { lat: 49.220052, lng: 18.447828 },
    { lat: 49.22009, lng: 18.447433 },
    { lat: 49.220157, lng: 18.447018 },
    { lat: 49.220324, lng: 18.446761 },
    { lat: 49.220706, lng: 18.446468 },
    { lat: 49.220906, lng: 18.446348 },
    { lat: 49.221065, lng: 18.446154 },
    { lat: 49.221199, lng: 18.445793 },
    { lat: 49.221297, lng: 18.445611 },
    { lat: 49.221413, lng: 18.445441 },
    { lat: 49.221571, lng: 18.445242 },
    { lat: 49.221686, lng: 18.445095 },
    { lat: 49.221693, lng: 18.445088 },
    { lat: 49.22184, lng: 18.444959 },
    { lat: 49.221883, lng: 18.444933 },
    { lat: 49.222133, lng: 18.444773 },
    { lat: 49.222689, lng: 18.444285 },
    { lat: 49.223685, lng: 18.443096 },
    { lat: 49.223714, lng: 18.443061 },
    { lat: 49.224022, lng: 18.442705 },
    { lat: 49.224055, lng: 18.442668 },
    { lat: 49.224104, lng: 18.44261 },
    { lat: 49.225048, lng: 18.441751 },
    { lat: 49.225264, lng: 18.441637 },
    { lat: 49.225606, lng: 18.441409 },
    { lat: 49.225927, lng: 18.441179 },
    { lat: 49.226457, lng: 18.441189 },
    { lat: 49.226484, lng: 18.441189 },
    { lat: 49.226524, lng: 18.4412 },
    { lat: 49.226854, lng: 18.441287 },
    { lat: 49.22703, lng: 18.441214 },
    { lat: 49.22745, lng: 18.440967 },
    { lat: 49.228124, lng: 18.441162 },
    { lat: 49.228629, lng: 18.441632 },
    { lat: 49.228977, lng: 18.442146 },
    { lat: 49.228998, lng: 18.442178 },
    { lat: 49.229021, lng: 18.442212 },
    { lat: 49.229352, lng: 18.442405 },
  ],
  Stupné: [
    { lat: 49.1984283, lng: 18.4149364 },
    { lat: 49.1977658, lng: 18.4151314 },
    { lat: 49.1977298, lng: 18.4151769 },
    { lat: 49.1971638, lng: 18.4145056 },
    { lat: 49.1968266, lng: 18.4137315 },
    { lat: 49.196413, lng: 18.4130416 },
    { lat: 49.1953481, lng: 18.4112349 },
    { lat: 49.1945751, lng: 18.4115397 },
    { lat: 49.1944776, lng: 18.4116337 },
    { lat: 49.1943248, lng: 18.4119514 },
    { lat: 49.193889, lng: 18.4130077 },
    { lat: 49.1935162, lng: 18.4136533 },
    { lat: 49.1928192, lng: 18.413716 },
    { lat: 49.1925025, lng: 18.4136389 },
    { lat: 49.1923275, lng: 18.4136388 },
    { lat: 49.1920217, lng: 18.4137581 },
    { lat: 49.1907569, lng: 18.4151251 },
    { lat: 49.1899596, lng: 18.415905 },
    { lat: 49.1896866, lng: 18.4160309 },
    { lat: 49.1895384, lng: 18.4160817 },
    { lat: 49.188858, lng: 18.4159559 },
    { lat: 49.1884208, lng: 18.4160068 },
    { lat: 49.1883502, lng: 18.4160137 },
    { lat: 49.1882701, lng: 18.4160155 },
    { lat: 49.1878791, lng: 18.416002 },
    { lat: 49.1874455, lng: 18.4160965 },
    { lat: 49.1870821, lng: 18.4162362 },
    { lat: 49.1868077, lng: 18.4162499 },
    { lat: 49.1862432, lng: 18.4167343 },
    { lat: 49.185308, lng: 18.417552 },
    { lat: 49.1844232, lng: 18.4184838 },
    { lat: 49.1844286, lng: 18.4188298 },
    { lat: 49.1840458, lng: 18.4191715 },
    { lat: 49.1829338, lng: 18.4203624 },
    { lat: 49.1831236, lng: 18.4207363 },
    { lat: 49.183291, lng: 18.4218919 },
    { lat: 49.1833674, lng: 18.4226849 },
    { lat: 49.1839477, lng: 18.4243752 },
    { lat: 49.184022, lng: 18.4248849 },
    { lat: 49.1840327, lng: 18.4249977 },
    { lat: 49.1841301, lng: 18.425803 },
    { lat: 49.1835759, lng: 18.4270495 },
    { lat: 49.1833816, lng: 18.4275756 },
    { lat: 49.182018, lng: 18.4318257 },
    { lat: 49.1819312, lng: 18.4319715 },
    { lat: 49.1819543, lng: 18.4336429 },
    { lat: 49.1819079, lng: 18.4345838 },
    { lat: 49.181913, lng: 18.4346574 },
    { lat: 49.1822976, lng: 18.4375927 },
    { lat: 49.1817353, lng: 18.439947 },
    { lat: 49.1813907, lng: 18.4409627 },
    { lat: 49.181431, lng: 18.4410416 },
    { lat: 49.1823351, lng: 18.4429832 },
    { lat: 49.1824817, lng: 18.4432708 },
    { lat: 49.1826885, lng: 18.4439744 },
    { lat: 49.1827323, lng: 18.4441375 },
    { lat: 49.1827905, lng: 18.4443353 },
    { lat: 49.1830883, lng: 18.4453426 },
    { lat: 49.1831674, lng: 18.445613 },
    { lat: 49.18317, lng: 18.4456181 },
    { lat: 49.1831822, lng: 18.4456569 },
    { lat: 49.1831837, lng: 18.4456621 },
    { lat: 49.1832244, lng: 18.4458281 },
    { lat: 49.183261, lng: 18.4459801 },
    { lat: 49.1832989, lng: 18.4461373 },
    { lat: 49.1833588, lng: 18.4463869 },
    { lat: 49.1834215, lng: 18.4466259 },
    { lat: 49.183453, lng: 18.4467474 },
    { lat: 49.1834531, lng: 18.4467499 },
    { lat: 49.1835437, lng: 18.4470838 },
    { lat: 49.1835805, lng: 18.4472211 },
    { lat: 49.1836366, lng: 18.4474206 },
    { lat: 49.1836619, lng: 18.4475111 },
    { lat: 49.183698, lng: 18.4476363 },
    { lat: 49.1837065, lng: 18.4476681 },
    { lat: 49.1837345, lng: 18.4477667 },
    { lat: 49.184227, lng: 18.4474918 },
    { lat: 49.1844495, lng: 18.4473524 },
    { lat: 49.1845042, lng: 18.4474884 },
    { lat: 49.1847202, lng: 18.4477226 },
    { lat: 49.1848928, lng: 18.4479094 },
    { lat: 49.1850092, lng: 18.4480352 },
    { lat: 49.1850583, lng: 18.4480887 },
    { lat: 49.1850752, lng: 18.4481068 },
    { lat: 49.1853388, lng: 18.4483696 },
    { lat: 49.1853555, lng: 18.4483855 },
    { lat: 49.1853973, lng: 18.4484284 },
    { lat: 49.1855304, lng: 18.4483873 },
    { lat: 49.1856746, lng: 18.4483426 },
    { lat: 49.1856812, lng: 18.4483405 },
    { lat: 49.1857091, lng: 18.4484175 },
    { lat: 49.1859246, lng: 18.4483349 },
    { lat: 49.1860537, lng: 18.4481806 },
    { lat: 49.1863302, lng: 18.4478722 },
    { lat: 49.1865467, lng: 18.4476239 },
    { lat: 49.1865763, lng: 18.4475896 },
    { lat: 49.186811, lng: 18.4474052 },
    { lat: 49.1868644, lng: 18.4475967 },
    { lat: 49.1868806, lng: 18.4475021 },
    { lat: 49.1876357, lng: 18.4468313 },
    { lat: 49.1877769, lng: 18.4467322 },
    { lat: 49.1883524, lng: 18.446653 },
    { lat: 49.1896116, lng: 18.4451801 },
    { lat: 49.1900598, lng: 18.4449401 },
    { lat: 49.1904354, lng: 18.4447776 },
    { lat: 49.1904717, lng: 18.4447376 },
    { lat: 49.1909633, lng: 18.4439338 },
    { lat: 49.1909853, lng: 18.4439025 },
    { lat: 49.1911999, lng: 18.443597 },
    { lat: 49.1912281, lng: 18.443557 },
    { lat: 49.1912902, lng: 18.443641 },
    { lat: 49.1912983, lng: 18.4436654 },
    { lat: 49.1913461, lng: 18.4438176 },
    { lat: 49.1914144, lng: 18.4442805 },
    { lat: 49.1914256, lng: 18.4443398 },
    { lat: 49.1914342, lng: 18.4444564 },
    { lat: 49.1914073, lng: 18.4451372 },
    { lat: 49.1914292, lng: 18.4456194 },
    { lat: 49.1913958, lng: 18.4461228 },
    { lat: 49.1913475, lng: 18.4462703 },
    { lat: 49.1910617, lng: 18.4466325 },
    { lat: 49.1911463, lng: 18.4474944 },
    { lat: 49.1910412, lng: 18.4479669 },
    { lat: 49.1914962, lng: 18.4490025 },
    { lat: 49.191592, lng: 18.4494119 },
    { lat: 49.1921494, lng: 18.4496837 },
    { lat: 49.1922403, lng: 18.4494884 },
    { lat: 49.1924972, lng: 18.449567 },
    { lat: 49.1925558, lng: 18.4494431 },
    { lat: 49.1928132, lng: 18.4495296 },
    { lat: 49.1930999, lng: 18.4498012 },
    { lat: 49.1934083, lng: 18.450098 },
    { lat: 49.193511, lng: 18.4506942 },
    { lat: 49.1934441, lng: 18.4527895 },
    { lat: 49.1935137, lng: 18.4538339 },
    { lat: 49.193605, lng: 18.4541833 },
    { lat: 49.19387, lng: 18.4552111 },
    { lat: 49.1942542, lng: 18.4577586 },
    { lat: 49.1944497, lng: 18.458074 },
    { lat: 49.1949991, lng: 18.4586939 },
    { lat: 49.1958365, lng: 18.4583324 },
    { lat: 49.1961956, lng: 18.4587713 },
    { lat: 49.1964075, lng: 18.4589753 },
    { lat: 49.1968173, lng: 18.4596812 },
    { lat: 49.1971856, lng: 18.4602711 },
    { lat: 49.1974451, lng: 18.4609103 },
    { lat: 49.197717, lng: 18.4616975 },
    { lat: 49.198013, lng: 18.462819 },
    { lat: 49.198894, lng: 18.463055 },
    { lat: 49.198925, lng: 18.463063 },
    { lat: 49.199109, lng: 18.463113 },
    { lat: 49.199678, lng: 18.463215 },
    { lat: 49.200279, lng: 18.463623 },
    { lat: 49.200378, lng: 18.463691 },
    { lat: 49.201333, lng: 18.463799 },
    { lat: 49.201543, lng: 18.463891 },
    { lat: 49.202872, lng: 18.464475 },
    { lat: 49.203647, lng: 18.464525 },
    { lat: 49.204572, lng: 18.464305 },
    { lat: 49.204731, lng: 18.464071 },
    { lat: 49.205161, lng: 18.463601 },
    { lat: 49.205249, lng: 18.46304 },
    { lat: 49.205569, lng: 18.461973 },
    { lat: 49.205972, lng: 18.461052 },
    { lat: 49.206199, lng: 18.460593 },
    { lat: 49.206587, lng: 18.459991 },
    { lat: 49.206901, lng: 18.459553 },
    { lat: 49.207188, lng: 18.459207 },
    { lat: 49.207685, lng: 18.458732 },
    { lat: 49.2078, lng: 18.458599 },
    { lat: 49.20806, lng: 18.458064 },
    { lat: 49.208388, lng: 18.457291 },
    { lat: 49.208554, lng: 18.456897 },
    { lat: 49.20895, lng: 18.455589 },
    { lat: 49.209142, lng: 18.454955 },
    { lat: 49.20917, lng: 18.454858 },
    { lat: 49.209216, lng: 18.454804 },
    { lat: 49.209689, lng: 18.453361 },
    { lat: 49.209889, lng: 18.452817 },
    { lat: 49.210094, lng: 18.452075 },
    { lat: 49.210163, lng: 18.450327 },
    { lat: 49.210082, lng: 18.44957 },
    { lat: 49.210456, lng: 18.448751 },
    { lat: 49.210877, lng: 18.448076 },
    { lat: 49.2105156, lng: 18.4473657 },
    { lat: 49.2101499, lng: 18.4468684 },
    { lat: 49.2084584, lng: 18.4443654 },
    { lat: 49.208131, lng: 18.4437449 },
    { lat: 49.2080734, lng: 18.4431864 },
    { lat: 49.206694, lng: 18.4397139 },
    { lat: 49.206407, lng: 18.4369516 },
    { lat: 49.2063354, lng: 18.4365897 },
    { lat: 49.2063211, lng: 18.4364934 },
    { lat: 49.2060448, lng: 18.4350383 },
    { lat: 49.2060179, lng: 18.4348967 },
    { lat: 49.2058875, lng: 18.4342067 },
    { lat: 49.2056075, lng: 18.4332775 },
    { lat: 49.2053253, lng: 18.432659 },
    { lat: 49.2052979, lng: 18.4326113 },
    { lat: 49.2052793, lng: 18.4325785 },
    { lat: 49.2051969, lng: 18.4324348 },
    { lat: 49.2051818, lng: 18.4324092 },
    { lat: 49.2051057, lng: 18.4322764 },
    { lat: 49.2050732, lng: 18.4320923 },
    { lat: 49.2049818, lng: 18.4315776 },
    { lat: 49.2049394, lng: 18.4313389 },
    { lat: 49.2049569, lng: 18.431227 },
    { lat: 49.2049582, lng: 18.4312131 },
    { lat: 49.2049615, lng: 18.4309237 },
    { lat: 49.2049686, lng: 18.4303746 },
    { lat: 49.2049719, lng: 18.4300862 },
    { lat: 49.2047179, lng: 18.4292395 },
    { lat: 49.2046951, lng: 18.4280253 },
    { lat: 49.2045289, lng: 18.4276671 },
    { lat: 49.2044501, lng: 18.4275289 },
    { lat: 49.2044175, lng: 18.4272027 },
    { lat: 49.2037589, lng: 18.4257914 },
    { lat: 49.2036244, lng: 18.4248635 },
    { lat: 49.202996, lng: 18.423446 },
    { lat: 49.2020226, lng: 18.4222348 },
    { lat: 49.2012335, lng: 18.4206708 },
    { lat: 49.2002237, lng: 18.4198846 },
    { lat: 49.2006744, lng: 18.4188516 },
    { lat: 49.2001109, lng: 18.4182865 },
    { lat: 49.1997928, lng: 18.4180809 },
    { lat: 49.1993141, lng: 18.4179728 },
    { lat: 49.19864, lng: 18.4178969 },
    { lat: 49.1981193, lng: 18.4171896 },
    { lat: 49.1981886, lng: 18.4168501 },
    { lat: 49.1988443, lng: 18.4164041 },
    { lat: 49.1988128, lng: 18.4158974 },
    { lat: 49.1984283, lng: 18.4149364 },
  ],
  Jasenica: [
    { lat: 49.1636457, lng: 18.4391265 },
    { lat: 49.1641389, lng: 18.4397872 },
    { lat: 49.1648076, lng: 18.4404822 },
    { lat: 49.1653415, lng: 18.4417211 },
    { lat: 49.1654592, lng: 18.4420775 },
    { lat: 49.1654839, lng: 18.4424665 },
    { lat: 49.1654661, lng: 18.4439908 },
    { lat: 49.1660636, lng: 18.4450139 },
    { lat: 49.1662604, lng: 18.44564 },
    { lat: 49.1662798, lng: 18.4459952 },
    { lat: 49.1661269, lng: 18.4471763 },
    { lat: 49.1665661, lng: 18.4484783 },
    { lat: 49.1668178, lng: 18.4492832 },
    { lat: 49.1673902, lng: 18.4513294 },
    { lat: 49.1674432, lng: 18.4513277 },
    { lat: 49.1683056, lng: 18.4513803 },
    { lat: 49.1681416, lng: 18.4532868 },
    { lat: 49.1690664, lng: 18.4544995 },
    { lat: 49.1690011, lng: 18.4548506 },
    { lat: 49.1679424, lng: 18.4567154 },
    { lat: 49.1689368, lng: 18.4576734 },
    { lat: 49.1710179, lng: 18.4592594 },
    { lat: 49.171144, lng: 18.4594413 },
    { lat: 49.1708406, lng: 18.4600205 },
    { lat: 49.1710001, lng: 18.4603763 },
    { lat: 49.1711411, lng: 18.460522 },
    { lat: 49.1711764, lng: 18.4605687 },
    { lat: 49.1713718, lng: 18.4612542 },
    { lat: 49.1722227, lng: 18.4625387 },
    { lat: 49.172247, lng: 18.4625702 },
    { lat: 49.1726704, lng: 18.4631151 },
    { lat: 49.1726814, lng: 18.4631288 },
    { lat: 49.17284, lng: 18.4633076 },
    { lat: 49.1729329, lng: 18.4633755 },
    { lat: 49.1734887, lng: 18.4637839 },
    { lat: 49.1744281, lng: 18.4645249 },
    { lat: 49.1754234, lng: 18.4646977 },
    { lat: 49.1761575, lng: 18.4649213 },
    { lat: 49.1779755, lng: 18.4653464 },
    { lat: 49.1785622, lng: 18.4659487 },
    { lat: 49.1787307, lng: 18.4661212 },
    { lat: 49.1793652, lng: 18.4667116 },
    { lat: 49.1799861, lng: 18.467173 },
    { lat: 49.1805468, lng: 18.4674669 },
    { lat: 49.1806387, lng: 18.4673308 },
    { lat: 49.1814452, lng: 18.4679908 },
    { lat: 49.1830558, lng: 18.4681134 },
    { lat: 49.1835693, lng: 18.468179 },
    { lat: 49.1839272, lng: 18.4682892 },
    { lat: 49.1840533, lng: 18.4681836 },
    { lat: 49.1850365, lng: 18.4692479 },
    { lat: 49.1856007, lng: 18.4683627 },
    { lat: 49.1859665, lng: 18.4681236 },
    { lat: 49.1862664, lng: 18.4683483 },
    { lat: 49.1871903, lng: 18.4682871 },
    { lat: 49.1878317, lng: 18.4678269 },
    { lat: 49.1883937, lng: 18.4680363 },
    { lat: 49.1888353, lng: 18.4679239 },
    { lat: 49.1890746, lng: 18.4679277 },
    { lat: 49.1891832, lng: 18.4680776 },
    { lat: 49.1892168, lng: 18.4681368 },
    { lat: 49.189266, lng: 18.468181 },
    { lat: 49.189312, lng: 18.467991 },
    { lat: 49.189501, lng: 18.467647 },
    { lat: 49.190491, lng: 18.467674 },
    { lat: 49.191104, lng: 18.467512 },
    { lat: 49.191429, lng: 18.466393 },
    { lat: 49.191845, lng: 18.465961 },
    { lat: 49.192027, lng: 18.465474 },
    { lat: 49.19215, lng: 18.46541 },
    { lat: 49.192417, lng: 18.465438 },
    { lat: 49.192696, lng: 18.465244 },
    { lat: 49.19273, lng: 18.465189 },
    { lat: 49.193058, lng: 18.46453 },
    { lat: 49.193173, lng: 18.464338 },
    { lat: 49.193362, lng: 18.464156 },
    { lat: 49.193567, lng: 18.464142 },
    { lat: 49.194236, lng: 18.464139 },
    { lat: 49.19506, lng: 18.464287 },
    { lat: 49.195562, lng: 18.464058 },
    { lat: 49.196674, lng: 18.463234 },
    { lat: 49.196718, lng: 18.463201 },
    { lat: 49.196917, lng: 18.463431 },
    { lat: 49.197663, lng: 18.46319 },
    { lat: 49.197851, lng: 18.463347 },
    { lat: 49.198193, lng: 18.463197 },
    { lat: 49.198013, lng: 18.462819 },
    { lat: 49.197717, lng: 18.4616975 },
    { lat: 49.1974451, lng: 18.4609103 },
    { lat: 49.1971856, lng: 18.4602711 },
    { lat: 49.1968173, lng: 18.4596812 },
    { lat: 49.1964075, lng: 18.4589753 },
    { lat: 49.1961956, lng: 18.4587713 },
    { lat: 49.1958365, lng: 18.4583324 },
    { lat: 49.1949991, lng: 18.4586939 },
    { lat: 49.1944497, lng: 18.458074 },
    { lat: 49.1942542, lng: 18.4577586 },
    { lat: 49.19387, lng: 18.4552111 },
    { lat: 49.193605, lng: 18.4541833 },
    { lat: 49.1935137, lng: 18.4538339 },
    { lat: 49.1934441, lng: 18.4527895 },
    { lat: 49.193511, lng: 18.4506942 },
    { lat: 49.1934083, lng: 18.450098 },
    { lat: 49.1930999, lng: 18.4498012 },
    { lat: 49.1928132, lng: 18.4495296 },
    { lat: 49.1925558, lng: 18.4494431 },
    { lat: 49.1924972, lng: 18.449567 },
    { lat: 49.1922403, lng: 18.4494884 },
    { lat: 49.1921494, lng: 18.4496837 },
    { lat: 49.191592, lng: 18.4494119 },
    { lat: 49.1914962, lng: 18.4490025 },
    { lat: 49.1910412, lng: 18.4479669 },
    { lat: 49.1911463, lng: 18.4474944 },
    { lat: 49.1910617, lng: 18.4466325 },
    { lat: 49.1913475, lng: 18.4462703 },
    { lat: 49.1913958, lng: 18.4461228 },
    { lat: 49.1914292, lng: 18.4456194 },
    { lat: 49.1914073, lng: 18.4451372 },
    { lat: 49.1914342, lng: 18.4444564 },
    { lat: 49.1914256, lng: 18.4443398 },
    { lat: 49.1914144, lng: 18.4442805 },
    { lat: 49.1913461, lng: 18.4438176 },
    { lat: 49.1912983, lng: 18.4436654 },
    { lat: 49.1912902, lng: 18.443641 },
    { lat: 49.1912281, lng: 18.443557 },
    { lat: 49.1911999, lng: 18.443597 },
    { lat: 49.1909853, lng: 18.4439025 },
    { lat: 49.1909633, lng: 18.4439338 },
    { lat: 49.1904717, lng: 18.4447376 },
    { lat: 49.1904354, lng: 18.4447776 },
    { lat: 49.1900598, lng: 18.4449401 },
    { lat: 49.1896116, lng: 18.4451801 },
    { lat: 49.1883524, lng: 18.446653 },
    { lat: 49.1877769, lng: 18.4467322 },
    { lat: 49.1876357, lng: 18.4468313 },
    { lat: 49.1868806, lng: 18.4475021 },
    { lat: 49.1868644, lng: 18.4475967 },
    { lat: 49.186811, lng: 18.4474052 },
    { lat: 49.1865763, lng: 18.4475896 },
    { lat: 49.1865467, lng: 18.4476239 },
    { lat: 49.1863302, lng: 18.4478722 },
    { lat: 49.1860537, lng: 18.4481806 },
    { lat: 49.1859246, lng: 18.4483349 },
    { lat: 49.1857091, lng: 18.4484175 },
    { lat: 49.1856812, lng: 18.4483405 },
    { lat: 49.1856746, lng: 18.4483426 },
    { lat: 49.1855304, lng: 18.4483873 },
    { lat: 49.1853973, lng: 18.4484284 },
    { lat: 49.1853555, lng: 18.4483855 },
    { lat: 49.1853388, lng: 18.4483696 },
    { lat: 49.1850752, lng: 18.4481068 },
    { lat: 49.1850583, lng: 18.4480887 },
    { lat: 49.1850092, lng: 18.4480352 },
    { lat: 49.1848928, lng: 18.4479094 },
    { lat: 49.1847202, lng: 18.4477226 },
    { lat: 49.1845042, lng: 18.4474884 },
    { lat: 49.1844495, lng: 18.4473524 },
    { lat: 49.184227, lng: 18.4474918 },
    { lat: 49.1837345, lng: 18.4477667 },
    { lat: 49.1837065, lng: 18.4476681 },
    { lat: 49.183698, lng: 18.4476363 },
    { lat: 49.1836619, lng: 18.4475111 },
    { lat: 49.1836366, lng: 18.4474206 },
    { lat: 49.1835805, lng: 18.4472211 },
    { lat: 49.1835437, lng: 18.4470838 },
    { lat: 49.1834531, lng: 18.4467499 },
    { lat: 49.183453, lng: 18.4467474 },
    { lat: 49.1834215, lng: 18.4466259 },
    { lat: 49.1833588, lng: 18.4463869 },
    { lat: 49.1832989, lng: 18.4461373 },
    { lat: 49.183261, lng: 18.4459801 },
    { lat: 49.1832244, lng: 18.4458281 },
    { lat: 49.1831837, lng: 18.4456621 },
    { lat: 49.1831822, lng: 18.4456569 },
    { lat: 49.18317, lng: 18.4456181 },
    { lat: 49.1831674, lng: 18.445613 },
    { lat: 49.1830883, lng: 18.4453426 },
    { lat: 49.1827905, lng: 18.4443353 },
    { lat: 49.1827323, lng: 18.4441375 },
    { lat: 49.1826885, lng: 18.4439744 },
    { lat: 49.1824817, lng: 18.4432708 },
    { lat: 49.1823351, lng: 18.4429832 },
    { lat: 49.181431, lng: 18.4410416 },
    { lat: 49.1813907, lng: 18.4409627 },
    { lat: 49.1817353, lng: 18.439947 },
    { lat: 49.1822976, lng: 18.4375927 },
    { lat: 49.181913, lng: 18.4346574 },
    { lat: 49.1819079, lng: 18.4345838 },
    { lat: 49.1819543, lng: 18.4336429 },
    { lat: 49.1819312, lng: 18.4319715 },
    { lat: 49.182018, lng: 18.4318257 },
    { lat: 49.1833816, lng: 18.4275756 },
    { lat: 49.1835759, lng: 18.4270495 },
    { lat: 49.1841301, lng: 18.425803 },
    { lat: 49.1840327, lng: 18.4249977 },
    { lat: 49.184022, lng: 18.4248849 },
    { lat: 49.1839477, lng: 18.4243752 },
    { lat: 49.1833674, lng: 18.4226849 },
    { lat: 49.183291, lng: 18.4218919 },
    { lat: 49.1831236, lng: 18.4207363 },
    { lat: 49.1829338, lng: 18.4203624 },
    { lat: 49.1819478, lng: 18.4206685 },
    { lat: 49.1809828, lng: 18.4211075 },
    { lat: 49.1809266, lng: 18.421133 },
    { lat: 49.1798155, lng: 18.4216233 },
    { lat: 49.1794712, lng: 18.421926 },
    { lat: 49.1792174, lng: 18.422072 },
    { lat: 49.1789321, lng: 18.4222142 },
    { lat: 49.177987, lng: 18.4222989 },
    { lat: 49.1770518, lng: 18.4223606 },
    { lat: 49.1766551, lng: 18.4223453 },
    { lat: 49.1757898, lng: 18.4222014 },
    { lat: 49.1753454, lng: 18.421916 },
    { lat: 49.1752917, lng: 18.4218606 },
    { lat: 49.1746769, lng: 18.4223935 },
    { lat: 49.1742968, lng: 18.4226597 },
    { lat: 49.1738686, lng: 18.4230578 },
    { lat: 49.1734178, lng: 18.4234342 },
    { lat: 49.1730807, lng: 18.423705 },
    { lat: 49.1729575, lng: 18.4238021 },
    { lat: 49.1724154, lng: 18.4241803 },
    { lat: 49.1720798, lng: 18.4244362 },
    { lat: 49.1720207, lng: 18.4245095 },
    { lat: 49.1716105, lng: 18.4246592 },
    { lat: 49.1712618, lng: 18.4248019 },
    { lat: 49.1705782, lng: 18.4251282 },
    { lat: 49.1692357, lng: 18.426293 },
    { lat: 49.1689947, lng: 18.4267518 },
    { lat: 49.1689809, lng: 18.4267479 },
    { lat: 49.1688243, lng: 18.4270165 },
    { lat: 49.1686632, lng: 18.4271237 },
    { lat: 49.1685646, lng: 18.4271359 },
    { lat: 49.168324, lng: 18.4270476 },
    { lat: 49.1681369, lng: 18.4269682 },
    { lat: 49.1677545, lng: 18.4269689 },
    { lat: 49.1675745, lng: 18.4268935 },
    { lat: 49.167415, lng: 18.4268865 },
    { lat: 49.1670306, lng: 18.4274869 },
    { lat: 49.1667168, lng: 18.4281378 },
    { lat: 49.1666951, lng: 18.4281741 },
    { lat: 49.1665174, lng: 18.4288614 },
    { lat: 49.1663179, lng: 18.4297241 },
    { lat: 49.1662003, lng: 18.4300062 },
    { lat: 49.1658098, lng: 18.4299628 },
    { lat: 49.1656676, lng: 18.4301048 },
    { lat: 49.1654843, lng: 18.4305853 },
    { lat: 49.1653625, lng: 18.4309541 },
    { lat: 49.1653291, lng: 18.4311282 },
    { lat: 49.1652647, lng: 18.4312689 },
    { lat: 49.1649619, lng: 18.4319786 },
    { lat: 49.1649447, lng: 18.432117 },
    { lat: 49.1642401, lng: 18.4332937 },
    { lat: 49.1640119, lng: 18.433535 },
    { lat: 49.1636802, lng: 18.4338414 },
    { lat: 49.1636683, lng: 18.4352052 },
    { lat: 49.1637768, lng: 18.435967 },
    { lat: 49.1641659, lng: 18.4366631 },
    { lat: 49.1636739, lng: 18.4375434 },
    { lat: 49.1636457, lng: 18.4391265 },
  ],
  Papradno: [
    { lat: 49.313305, lng: 18.325089 },
    { lat: 49.3131145, lng: 18.3245932 },
    { lat: 49.3129154, lng: 18.3242399 },
    { lat: 49.3123759, lng: 18.3235047 },
    { lat: 49.3123576, lng: 18.3234828 },
    { lat: 49.3119934, lng: 18.3230397 },
    { lat: 49.3114419, lng: 18.3215631 },
    { lat: 49.3112883, lng: 18.3210144 },
    { lat: 49.3112477, lng: 18.3208743 },
    { lat: 49.3109438, lng: 18.3200169 },
    { lat: 49.3105471, lng: 18.3183882 },
    { lat: 49.3102466, lng: 18.3169859 },
    { lat: 49.3099925, lng: 18.3159697 },
    { lat: 49.3097663, lng: 18.3148869 },
    { lat: 49.3094179, lng: 18.3145271 },
    { lat: 49.3089501, lng: 18.313808 },
    { lat: 49.3086797, lng: 18.3131054 },
    { lat: 49.307836, lng: 18.3117736 },
    { lat: 49.3068824, lng: 18.3102748 },
    { lat: 49.306649, lng: 18.3092295 },
    { lat: 49.3062344, lng: 18.3073602 },
    { lat: 49.3061108, lng: 18.3058551 },
    { lat: 49.3059205, lng: 18.3046518 },
    { lat: 49.3057785, lng: 18.3038243 },
    { lat: 49.3057116, lng: 18.3034388 },
    { lat: 49.3056697, lng: 18.3031941 },
    { lat: 49.3047692, lng: 18.3035901 },
    { lat: 49.3032481, lng: 18.3039676 },
    { lat: 49.3016136, lng: 18.3047577 },
    { lat: 49.3007992, lng: 18.3052652 },
    { lat: 49.3001066, lng: 18.3058275 },
    { lat: 49.2994577, lng: 18.306492 },
    { lat: 49.2985993, lng: 18.3072399 },
    { lat: 49.2980892, lng: 18.3078029 },
    { lat: 49.2972979, lng: 18.3086383 },
    { lat: 49.2959534, lng: 18.309431 },
    { lat: 49.2948569, lng: 18.309168 },
    { lat: 49.2944424, lng: 18.3090985 },
    { lat: 49.2944068, lng: 18.3091092 },
    { lat: 49.2943692, lng: 18.3091206 },
    { lat: 49.2937601, lng: 18.3093045 },
    { lat: 49.2937257, lng: 18.3093151 },
    { lat: 49.2926142, lng: 18.3096116 },
    { lat: 49.2915147, lng: 18.3099838 },
    { lat: 49.2914097, lng: 18.3113966 },
    { lat: 49.2913106, lng: 18.3125505 },
    { lat: 49.2909465, lng: 18.3137886 },
    { lat: 49.290363, lng: 18.3153372 },
    { lat: 49.2902278, lng: 18.3155822 },
    { lat: 49.289919, lng: 18.3168422 },
    { lat: 49.2896101, lng: 18.3180755 },
    { lat: 49.2895027, lng: 18.3186811 },
    { lat: 49.2894918, lng: 18.3187473 },
    { lat: 49.2892285, lng: 18.3197263 },
    { lat: 49.2890253, lng: 18.3225164 },
    { lat: 49.2888012, lng: 18.323089 },
    { lat: 49.2885218, lng: 18.3235416 },
    { lat: 49.2880485, lng: 18.3240758 },
    { lat: 49.2875601, lng: 18.3246636 },
    { lat: 49.2869421, lng: 18.3251721 },
    { lat: 49.2868273, lng: 18.325584 },
    { lat: 49.2860165, lng: 18.3263345 },
    { lat: 49.2850275, lng: 18.3270698 },
    { lat: 49.2842337, lng: 18.3272827 },
    { lat: 49.2827555, lng: 18.3273118 },
    { lat: 49.2824858, lng: 18.3274143 },
    { lat: 49.2823314, lng: 18.3274382 },
    { lat: 49.2818574, lng: 18.326501 },
    { lat: 49.2810754, lng: 18.3260723 },
    { lat: 49.2807049, lng: 18.3257402 },
    { lat: 49.2800571, lng: 18.325107 },
    { lat: 49.2793918, lng: 18.3245683 },
    { lat: 49.2788876, lng: 18.3242671 },
    { lat: 49.2786389, lng: 18.3243192 },
    { lat: 49.2770149, lng: 18.325721 },
    { lat: 49.2764315, lng: 18.3264462 },
    { lat: 49.2760192, lng: 18.3266203 },
    { lat: 49.2752403, lng: 18.3272781 },
    { lat: 49.2746107, lng: 18.3281456 },
    { lat: 49.2740839, lng: 18.3285296 },
    { lat: 49.2733082, lng: 18.3293875 },
    { lat: 49.2720138, lng: 18.3293212 },
    { lat: 49.2714084, lng: 18.3292844 },
    { lat: 49.2703573, lng: 18.3301395 },
    { lat: 49.2693452, lng: 18.3311509 },
    { lat: 49.2685352, lng: 18.3319799 },
    { lat: 49.2682631, lng: 18.3322588 },
    { lat: 49.268228, lng: 18.3333097 },
    { lat: 49.2672488, lng: 18.3346879 },
    { lat: 49.2668146, lng: 18.3362673 },
    { lat: 49.2659613, lng: 18.3374363 },
    { lat: 49.2658575, lng: 18.3386275 },
    { lat: 49.2653039, lng: 18.3388749 },
    { lat: 49.2646591, lng: 18.3388474 },
    { lat: 49.2637289, lng: 18.3393231 },
    { lat: 49.2629994, lng: 18.3397123 },
    { lat: 49.2626062, lng: 18.3398595 },
    { lat: 49.2619052, lng: 18.3400755 },
    { lat: 49.260366, lng: 18.3398443 },
    { lat: 49.2598396, lng: 18.340016 },
    { lat: 49.2592503, lng: 18.3400876 },
    { lat: 49.25752, lng: 18.3405782 },
    { lat: 49.2570311, lng: 18.3407942 },
    { lat: 49.2565733, lng: 18.3409602 },
    { lat: 49.2555865, lng: 18.3410646 },
    { lat: 49.2550254, lng: 18.3414625 },
    { lat: 49.2547085, lng: 18.3419948 },
    { lat: 49.2545643, lng: 18.3422418 },
    { lat: 49.2539948, lng: 18.3426265 },
    { lat: 49.2536787, lng: 18.3430303 },
    { lat: 49.2534836, lng: 18.3431086 },
    { lat: 49.2518676, lng: 18.3451262 },
    { lat: 49.2517325, lng: 18.3452942 },
    { lat: 49.2517968, lng: 18.3458846 },
    { lat: 49.2516432, lng: 18.3471402 },
    { lat: 49.2518153, lng: 18.3477172 },
    { lat: 49.2520185, lng: 18.3483283 },
    { lat: 49.2523254, lng: 18.3490774 },
    { lat: 49.2524026, lng: 18.3493518 },
    { lat: 49.2525412, lng: 18.3498379 },
    { lat: 49.2525962, lng: 18.3507247 },
    { lat: 49.2526967, lng: 18.3521247 },
    { lat: 49.2526368, lng: 18.35377 },
    { lat: 49.252351, lng: 18.3554072 },
    { lat: 49.2504887, lng: 18.3568283 },
    { lat: 49.2488459, lng: 18.3585937 },
    { lat: 49.2473698, lng: 18.3604739 },
    { lat: 49.2466297, lng: 18.3615082 },
    { lat: 49.2464695, lng: 18.3617102 },
    { lat: 49.246177, lng: 18.364022 },
    { lat: 49.2447939, lng: 18.3693285 },
    { lat: 49.2444186, lng: 18.3697 },
    { lat: 49.2433721, lng: 18.3698503 },
    { lat: 49.2428482, lng: 18.3699422 },
    { lat: 49.2426523, lng: 18.3700502 },
    { lat: 49.2419111, lng: 18.3704782 },
    { lat: 49.2415984, lng: 18.3706587 },
    { lat: 49.2411961, lng: 18.3710122 },
    { lat: 49.2408923, lng: 18.3717587 },
    { lat: 49.2404443, lng: 18.3726706 },
    { lat: 49.2402227, lng: 18.3736572 },
    { lat: 49.2401556, lng: 18.3741358 },
    { lat: 49.2399345, lng: 18.3750956 },
    { lat: 49.2398621, lng: 18.3757638 },
    { lat: 49.2398533, lng: 18.3773762 },
    { lat: 49.239814, lng: 18.377828 },
    { lat: 49.2394465, lng: 18.3777729 },
    { lat: 49.2391297, lng: 18.377692 },
    { lat: 49.2375461, lng: 18.3775541 },
    { lat: 49.2367586, lng: 18.3773302 },
    { lat: 49.2363126, lng: 18.3772797 },
    { lat: 49.2356683, lng: 18.3774185 },
    { lat: 49.2349089, lng: 18.3771722 },
    { lat: 49.2345076, lng: 18.3766868 },
    { lat: 49.234379, lng: 18.3766647 },
    { lat: 49.2340328, lng: 18.3766879 },
    { lat: 49.2337554, lng: 18.3768955 },
    { lat: 49.231973, lng: 18.3788074 },
    { lat: 49.2313226, lng: 18.3795303 },
    { lat: 49.2312271, lng: 18.3795563 },
    { lat: 49.2310866, lng: 18.3795669 },
    { lat: 49.2306836, lng: 18.3793154 },
    { lat: 49.229818, lng: 18.3793939 },
    { lat: 49.2296876, lng: 18.3794804 },
    { lat: 49.2294105, lng: 18.3797742 },
    { lat: 49.2293148, lng: 18.3798817 },
    { lat: 49.2291387, lng: 18.3799584 },
    { lat: 49.2284499, lng: 18.3802922 },
    { lat: 49.2277507, lng: 18.3808052 },
    { lat: 49.2273983, lng: 18.3812874 },
    { lat: 49.2271486, lng: 18.3817261 },
    { lat: 49.2266856, lng: 18.3824333 },
    { lat: 49.2256627, lng: 18.3831324 },
    { lat: 49.2240547, lng: 18.384343 },
    { lat: 49.2238139, lng: 18.3845202 },
    { lat: 49.2233175, lng: 18.3842125 },
    { lat: 49.2230821, lng: 18.3842602 },
    { lat: 49.2228253, lng: 18.3841417 },
    { lat: 49.2227, lng: 18.3843636 },
    { lat: 49.2217387, lng: 18.3847712 },
    { lat: 49.2211833, lng: 18.3858836 },
    { lat: 49.2211486, lng: 18.3865395 },
    { lat: 49.22051, lng: 18.3872544 },
    { lat: 49.2193385, lng: 18.3876086 },
    { lat: 49.21868, lng: 18.3877768 },
    { lat: 49.2185686, lng: 18.3878651 },
    { lat: 49.217911, lng: 18.3884766 },
    { lat: 49.2173755, lng: 18.3883393 },
    { lat: 49.2168299, lng: 18.3883689 },
    { lat: 49.2167986, lng: 18.388392 },
    { lat: 49.2160918, lng: 18.3890545 },
    { lat: 49.2155636, lng: 18.3892523 },
    { lat: 49.2149883, lng: 18.3893569 },
    { lat: 49.2142726, lng: 18.3894255 },
    { lat: 49.2141241, lng: 18.3896171 },
    { lat: 49.2140529, lng: 18.3899238 },
    { lat: 49.2138342, lng: 18.3901715 },
    { lat: 49.2139915, lng: 18.3913405 },
    { lat: 49.2140342, lng: 18.3919297 },
    { lat: 49.2140436, lng: 18.3920183 },
    { lat: 49.2142017, lng: 18.3935737 },
    { lat: 49.2138603, lng: 18.3945998 },
    { lat: 49.2135383, lng: 18.3952871 },
    { lat: 49.2133994, lng: 18.3959191 },
    { lat: 49.2140964, lng: 18.3968334 },
    { lat: 49.214814, lng: 18.3965276 },
    { lat: 49.2150399, lng: 18.3969456 },
    { lat: 49.2149505, lng: 18.3982676 },
    { lat: 49.2150476, lng: 18.3992903 },
    { lat: 49.2152144, lng: 18.4002853 },
    { lat: 49.2153238, lng: 18.4014714 },
    { lat: 49.2153712, lng: 18.4022483 },
    { lat: 49.2155725, lng: 18.4027907 },
    { lat: 49.2159311, lng: 18.4025645 },
    { lat: 49.2165321, lng: 18.4033388 },
    { lat: 49.2170389, lng: 18.4035045 },
    { lat: 49.2176141, lng: 18.4032807 },
    { lat: 49.2178597, lng: 18.4044791 },
    { lat: 49.217671, lng: 18.4045124 },
    { lat: 49.2178835, lng: 18.4053616 },
    { lat: 49.2172868, lng: 18.4056889 },
    { lat: 49.2176184, lng: 18.4061597 },
    { lat: 49.2179643, lng: 18.4067668 },
    { lat: 49.2183682, lng: 18.4072154 },
    { lat: 49.2184439, lng: 18.4078339 },
    { lat: 49.2186891, lng: 18.4076559 },
    { lat: 49.2188887, lng: 18.4074718 },
    { lat: 49.2192503, lng: 18.4080969 },
    { lat: 49.2193728, lng: 18.4087026 },
    { lat: 49.2196184, lng: 18.409145 },
    { lat: 49.2195056, lng: 18.4093286 },
    { lat: 49.2196589, lng: 18.4100248 },
    { lat: 49.2195662, lng: 18.4104923 },
    { lat: 49.2195822, lng: 18.4107825 },
    { lat: 49.2192085, lng: 18.4110402 },
    { lat: 49.2192147, lng: 18.4111962 },
    { lat: 49.2192118, lng: 18.4114084 },
    { lat: 49.2191432, lng: 18.4118201 },
    { lat: 49.2191349, lng: 18.4125917 },
    { lat: 49.2191572, lng: 18.4128971 },
    { lat: 49.2192289, lng: 18.4131524 },
    { lat: 49.2193905, lng: 18.4134634 },
    { lat: 49.219495, lng: 18.4137057 },
    { lat: 49.2195827, lng: 18.4139627 },
    { lat: 49.2196321, lng: 18.414268 },
    { lat: 49.2196088, lng: 18.4146203 },
    { lat: 49.2194812, lng: 18.4149473 },
    { lat: 49.2193197, lng: 18.415273 },
    { lat: 49.2193177, lng: 18.4152754 },
    { lat: 49.2194304, lng: 18.4154206 },
    { lat: 49.219439, lng: 18.4154312 },
    { lat: 49.2194677, lng: 18.4154633 },
    { lat: 49.2195238, lng: 18.4155366 },
    { lat: 49.2195974, lng: 18.4156238 },
    { lat: 49.2197315, lng: 18.4158275 },
    { lat: 49.2198805, lng: 18.4160521 },
    { lat: 49.219953, lng: 18.4161621 },
    { lat: 49.2199558, lng: 18.4161711 },
    { lat: 49.219966, lng: 18.416193 },
    { lat: 49.2201173, lng: 18.4167091 },
    { lat: 49.2201299, lng: 18.4167519 },
    { lat: 49.220156, lng: 18.4168361 },
    { lat: 49.2201698, lng: 18.416885 },
    { lat: 49.2202357, lng: 18.417099 },
    { lat: 49.2203833, lng: 18.4175864 },
    { lat: 49.2203736, lng: 18.4175952 },
    { lat: 49.2204071, lng: 18.4177518 },
    { lat: 49.2204253, lng: 18.4178386 },
    { lat: 49.2204653, lng: 18.417967 },
    { lat: 49.2205451, lng: 18.4181073 },
    { lat: 49.2206886, lng: 18.4182758 },
    { lat: 49.2208333, lng: 18.4185087 },
    { lat: 49.2208709, lng: 18.4185723 },
    { lat: 49.2211362, lng: 18.4190299 },
    { lat: 49.221162, lng: 18.4190696 },
    { lat: 49.2211793, lng: 18.4190959 },
    { lat: 49.2211842, lng: 18.4191025 },
    { lat: 49.2212471, lng: 18.4191978 },
    { lat: 49.2212507, lng: 18.4192026 },
    { lat: 49.221263, lng: 18.4192212 },
    { lat: 49.221279, lng: 18.4192458 },
    { lat: 49.2213006, lng: 18.4192692 },
    { lat: 49.2213245, lng: 18.4192947 },
    { lat: 49.2214964, lng: 18.4196097 },
    { lat: 49.2215101, lng: 18.4196352 },
    { lat: 49.2215165, lng: 18.4196477 },
    { lat: 49.221519, lng: 18.419651 },
    { lat: 49.2216119, lng: 18.4198226 },
    { lat: 49.2216306, lng: 18.419858 },
    { lat: 49.2216498, lng: 18.4198966 },
    { lat: 49.2216522, lng: 18.4199022 },
    { lat: 49.2216843, lng: 18.4199695 },
    { lat: 49.2216931, lng: 18.4199882 },
    { lat: 49.2217966, lng: 18.4202058 },
    { lat: 49.2218031, lng: 18.4202212 },
    { lat: 49.2218294, lng: 18.4202916 },
    { lat: 49.221869, lng: 18.4203964 },
    { lat: 49.2218896, lng: 18.4205439 },
    { lat: 49.222048, lng: 18.4207199 },
    { lat: 49.2220764, lng: 18.4208231 },
    { lat: 49.2222416, lng: 18.4208329 },
    { lat: 49.2224214, lng: 18.421192 },
    { lat: 49.222664, lng: 18.4211068 },
    { lat: 49.2229785, lng: 18.4210655 },
    { lat: 49.2231749, lng: 18.4212121 },
    { lat: 49.2236361, lng: 18.4211052 },
    { lat: 49.2241094, lng: 18.4212995 },
    { lat: 49.224544, lng: 18.421716 },
    { lat: 49.2246523, lng: 18.4222925 },
    { lat: 49.2243599, lng: 18.42237 },
    { lat: 49.2243918, lng: 18.422662 },
    { lat: 49.2245749, lng: 18.4229998 },
    { lat: 49.2246465, lng: 18.4232519 },
    { lat: 49.2246759, lng: 18.4233577 },
    { lat: 49.224869, lng: 18.4240403 },
    { lat: 49.2245604, lng: 18.4242101 },
    { lat: 49.22447, lng: 18.4248449 },
    { lat: 49.2245746, lng: 18.4264808 },
    { lat: 49.2251289, lng: 18.4272895 },
    { lat: 49.225058, lng: 18.4283608 },
    { lat: 49.225505, lng: 18.4290625 },
    { lat: 49.225889, lng: 18.4293194 },
    { lat: 49.2261028, lng: 18.4295526 },
    { lat: 49.2263447, lng: 18.4300362 },
    { lat: 49.2264003, lng: 18.4304333 },
    { lat: 49.2263602, lng: 18.4304446 },
    { lat: 49.2273295, lng: 18.4333228 },
    { lat: 49.2279862, lng: 18.4348697 },
    { lat: 49.2277378, lng: 18.4360163 },
    { lat: 49.2277037, lng: 18.436626 },
    { lat: 49.2281896, lng: 18.4380204 },
    { lat: 49.2287577, lng: 18.4393283 },
    { lat: 49.229139, lng: 18.4405838 },
    { lat: 49.229352, lng: 18.442405 },
    { lat: 49.230238, lng: 18.442279 },
    { lat: 49.230591, lng: 18.442096 },
    { lat: 49.230821, lng: 18.442234 },
    { lat: 49.23119, lng: 18.442123 },
    { lat: 49.231241, lng: 18.442103 },
    { lat: 49.231273, lng: 18.442097 },
    { lat: 49.231541, lng: 18.44217 },
    { lat: 49.231641, lng: 18.442123 },
    { lat: 49.23188, lng: 18.441957 },
    { lat: 49.232097, lng: 18.441818 },
    { lat: 49.232382, lng: 18.441677 },
    { lat: 49.232435, lng: 18.44165 },
    { lat: 49.232499, lng: 18.441618 },
    { lat: 49.232878, lng: 18.441239 },
    { lat: 49.233056, lng: 18.441152 },
    { lat: 49.233442, lng: 18.440816 },
    { lat: 49.233751, lng: 18.440599 },
    { lat: 49.23483, lng: 18.439881 },
    { lat: 49.235097, lng: 18.439704 },
    { lat: 49.235517, lng: 18.439634 },
    { lat: 49.235809, lng: 18.439592 },
    { lat: 49.236026, lng: 18.43943 },
    { lat: 49.236127, lng: 18.439398 },
    { lat: 49.236272, lng: 18.439355 },
    { lat: 49.236392, lng: 18.439235 },
    { lat: 49.236424, lng: 18.439203 },
    { lat: 49.236601, lng: 18.439063 },
    { lat: 49.236756, lng: 18.438963 },
    { lat: 49.236878, lng: 18.43892 },
    { lat: 49.23711, lng: 18.438751 },
    { lat: 49.237325, lng: 18.438569 },
    { lat: 49.237666, lng: 18.438194 },
    { lat: 49.237864, lng: 18.438034 },
    { lat: 49.238547, lng: 18.437739 },
    { lat: 49.238945, lng: 18.437371 },
    { lat: 49.238984, lng: 18.437336 },
    { lat: 49.239508, lng: 18.436853 },
    { lat: 49.239725, lng: 18.436646 },
    { lat: 49.239802, lng: 18.436567 },
    { lat: 49.240385, lng: 18.436031 },
    { lat: 49.240536, lng: 18.435755 },
    { lat: 49.240642, lng: 18.435682 },
    { lat: 49.241006, lng: 18.435552 },
    { lat: 49.24125, lng: 18.43538 },
    { lat: 49.241357, lng: 18.435339 },
    { lat: 49.241471, lng: 18.435326 },
    { lat: 49.241808, lng: 18.435539 },
    { lat: 49.241862, lng: 18.435255 },
    { lat: 49.241959, lng: 18.435048 },
    { lat: 49.242324, lng: 18.434752 },
    { lat: 49.242452, lng: 18.434662 },
    { lat: 49.24266, lng: 18.434501 },
    { lat: 49.242948, lng: 18.43412 },
    { lat: 49.243463, lng: 18.433448 },
    { lat: 49.243697, lng: 18.433229 },
    { lat: 49.244157, lng: 18.432649 },
    { lat: 49.244261, lng: 18.432497 },
    { lat: 49.244392, lng: 18.43235 },
    { lat: 49.244658, lng: 18.431989 },
    { lat: 49.244747, lng: 18.431911 },
    { lat: 49.244878, lng: 18.431879 },
    { lat: 49.244911, lng: 18.431846 },
    { lat: 49.245155, lng: 18.431543 },
    { lat: 49.245448, lng: 18.43118 },
    { lat: 49.245654, lng: 18.430898 },
    { lat: 49.245817, lng: 18.430089 },
    { lat: 49.246068, lng: 18.429279 },
    { lat: 49.246246, lng: 18.429 },
    { lat: 49.246197, lng: 18.428568 },
    { lat: 49.246502, lng: 18.428242 },
    { lat: 49.246793, lng: 18.427998 },
    { lat: 49.247077, lng: 18.427827 },
    { lat: 49.247501, lng: 18.427507 },
    { lat: 49.248019, lng: 18.427142 },
    { lat: 49.249872, lng: 18.428889 },
    { lat: 49.250068, lng: 18.429177 },
    { lat: 49.250281, lng: 18.429393 },
    { lat: 49.250715, lng: 18.42962 },
    { lat: 49.251117, lng: 18.429752 },
    { lat: 49.251423, lng: 18.429872 },
    { lat: 49.251645, lng: 18.42985 },
    { lat: 49.252307, lng: 18.429987 },
    { lat: 49.2524922, lng: 18.4301087 },
    { lat: 49.253062, lng: 18.430483 },
    { lat: 49.25326, lng: 18.430578 },
    { lat: 49.253845, lng: 18.430926 },
    { lat: 49.254146, lng: 18.43081 },
    { lat: 49.254993, lng: 18.430339 },
    { lat: 49.255528, lng: 18.429864 },
    { lat: 49.255937, lng: 18.429559 },
    { lat: 49.25625, lng: 18.429195 },
    { lat: 49.25668, lng: 18.429004 },
    { lat: 49.257068, lng: 18.428715 },
    { lat: 49.25753, lng: 18.428215 },
    { lat: 49.258063, lng: 18.427684 },
    { lat: 49.258328, lng: 18.427194 },
    { lat: 49.258562, lng: 18.42663 },
    { lat: 49.258891, lng: 18.426123 },
    { lat: 49.259011, lng: 18.426036 },
    { lat: 49.259231, lng: 18.425924 },
    { lat: 49.259411, lng: 18.425808 },
    { lat: 49.259705, lng: 18.425569 },
    { lat: 49.260126, lng: 18.425336 },
    { lat: 49.260516, lng: 18.425073 },
    { lat: 49.260949, lng: 18.424907 },
    { lat: 49.261524, lng: 18.424391 },
    { lat: 49.261764, lng: 18.424172 },
    { lat: 49.262143, lng: 18.423979 },
    { lat: 49.26319, lng: 18.422512 },
    { lat: 49.2639771, lng: 18.422095 },
    { lat: 49.264437, lng: 18.421965 },
    { lat: 49.264566, lng: 18.421955 },
    { lat: 49.264623, lng: 18.421678 },
    { lat: 49.264712, lng: 18.421313 },
    { lat: 49.264954, lng: 18.420762 },
    { lat: 49.265073, lng: 18.420514 },
    { lat: 49.265302, lng: 18.420084 },
    { lat: 49.26558, lng: 18.419749 },
    { lat: 49.265794, lng: 18.419464 },
    { lat: 49.2658673, lng: 18.4193519 },
    { lat: 49.266342, lng: 18.418626 },
    { lat: 49.266934, lng: 18.418155 },
    { lat: 49.266912, lng: 18.417253 },
    { lat: 49.266993, lng: 18.416008 },
    { lat: 49.267082, lng: 18.415324 },
    { lat: 49.267082, lng: 18.414982 },
    { lat: 49.267655, lng: 18.414864 },
    { lat: 49.268093, lng: 18.414613 },
    { lat: 49.268281, lng: 18.41457 },
    { lat: 49.268621, lng: 18.41434 },
    { lat: 49.268878, lng: 18.414216 },
    { lat: 49.269412, lng: 18.413953 },
    { lat: 49.269834, lng: 18.413289 },
    { lat: 49.270206, lng: 18.412833 },
    { lat: 49.270399, lng: 18.412366 },
    { lat: 49.270723, lng: 18.411871 },
    { lat: 49.270939, lng: 18.411157 },
    { lat: 49.270894, lng: 18.410255 },
    { lat: 49.271156, lng: 18.410129 },
    { lat: 49.271682, lng: 18.409874 },
    { lat: 49.271887, lng: 18.409638 },
    { lat: 49.272208, lng: 18.40964 },
    { lat: 49.272617, lng: 18.409643 },
    { lat: 49.273106, lng: 18.409486 },
    { lat: 49.273593, lng: 18.40939 },
    { lat: 49.274222, lng: 18.409144 },
    { lat: 49.27457, lng: 18.408975 },
    { lat: 49.275083, lng: 18.408763 },
    { lat: 49.275506, lng: 18.408702 },
    { lat: 49.275863, lng: 18.408533 },
    { lat: 49.276249, lng: 18.408349 },
    { lat: 49.276411, lng: 18.4081 },
    { lat: 49.276599, lng: 18.407274 },
    { lat: 49.276793, lng: 18.406556 },
    { lat: 49.277128, lng: 18.406164 },
    { lat: 49.277458, lng: 18.405841 },
    { lat: 49.277835, lng: 18.405662 },
    { lat: 49.278131, lng: 18.405519 },
    { lat: 49.278928, lng: 18.405078 },
    { lat: 49.279465, lng: 18.404772 },
    { lat: 49.280115, lng: 18.404372 },
    { lat: 49.280149, lng: 18.404351 },
    { lat: 49.280399, lng: 18.404202 },
    { lat: 49.280686, lng: 18.404049 },
    { lat: 49.281154, lng: 18.40367 },
    { lat: 49.281635, lng: 18.403331 },
    { lat: 49.281948, lng: 18.403091 },
    { lat: 49.282199, lng: 18.402882 },
    { lat: 49.282396, lng: 18.402694 },
    { lat: 49.282594, lng: 18.401307 },
    { lat: 49.282767, lng: 18.400608 },
    { lat: 49.28287, lng: 18.399767 },
    { lat: 49.282749, lng: 18.399024 },
    { lat: 49.282561, lng: 18.398324 },
    { lat: 49.282718, lng: 18.398252 },
    { lat: 49.283278, lng: 18.39824 },
    { lat: 49.283314, lng: 18.39824 },
    { lat: 49.283586, lng: 18.398243 },
    { lat: 49.283595, lng: 18.398243 },
    { lat: 49.284511, lng: 18.398003 },
    { lat: 49.284711, lng: 18.398048 },
    { lat: 49.285197, lng: 18.39808 },
    { lat: 49.285436, lng: 18.397874 },
    { lat: 49.285879, lng: 18.397545 },
    { lat: 49.286213, lng: 18.397317 },
    { lat: 49.286555, lng: 18.397161 },
    { lat: 49.287034, lng: 18.397078 },
    { lat: 49.287102, lng: 18.397067 },
    { lat: 49.287269, lng: 18.39702 },
    { lat: 49.287409, lng: 18.39698 },
    { lat: 49.287747, lng: 18.396897 },
    { lat: 49.288213, lng: 18.39682 },
    { lat: 49.288493, lng: 18.396717 },
    { lat: 49.289, lng: 18.396895 },
    { lat: 49.289533, lng: 18.397205 },
    { lat: 49.289876, lng: 18.397119 },
    { lat: 49.290211, lng: 18.39674 },
    { lat: 49.290655, lng: 18.396238 },
    { lat: 49.291021, lng: 18.395661 },
    { lat: 49.291119, lng: 18.395426 },
    { lat: 49.291452, lng: 18.395102 },
    { lat: 49.291734, lng: 18.394894 },
    { lat: 49.291867, lng: 18.394796 },
    { lat: 49.292268, lng: 18.394632 },
    { lat: 49.293194, lng: 18.39396 },
    { lat: 49.293375, lng: 18.393829 },
    { lat: 49.293423, lng: 18.393794 },
    { lat: 49.294195, lng: 18.393363 },
    { lat: 49.29453, lng: 18.393103 },
    { lat: 49.294938, lng: 18.392898 },
    { lat: 49.295377, lng: 18.392654 },
    { lat: 49.295819, lng: 18.392272 },
    { lat: 49.296175, lng: 18.391892 },
    { lat: 49.296319, lng: 18.391739 },
    { lat: 49.296489, lng: 18.391079 },
    { lat: 49.296438, lng: 18.390245 },
    { lat: 49.296529, lng: 18.389148 },
    { lat: 49.296672, lng: 18.387982 },
    { lat: 49.296734, lng: 18.387378 },
    { lat: 49.296846, lng: 18.386616 },
    { lat: 49.296965, lng: 18.385401 },
    { lat: 49.297181, lng: 18.384804 },
    { lat: 49.297392, lng: 18.384301 },
    { lat: 49.297542, lng: 18.383779 },
    { lat: 49.297635, lng: 18.38347 },
    { lat: 49.297865, lng: 18.383273 },
    { lat: 49.298284, lng: 18.382929 },
    { lat: 49.298648, lng: 18.382561 },
    { lat: 49.299055, lng: 18.382245 },
    { lat: 49.299328, lng: 18.382106 },
    { lat: 49.29974, lng: 18.381826 },
    { lat: 49.299843, lng: 18.38159 },
    { lat: 49.300096, lng: 18.381223 },
    { lat: 49.300275, lng: 18.381025 },
    { lat: 49.3004, lng: 18.381111 },
    { lat: 49.300584, lng: 18.381249 },
    { lat: 49.301078, lng: 18.381255 },
    { lat: 49.301402, lng: 18.381002 },
    { lat: 49.301573, lng: 18.380889 },
    { lat: 49.301741, lng: 18.380769 },
    { lat: 49.302076, lng: 18.380502 },
    { lat: 49.30216, lng: 18.379999 },
    { lat: 49.302179, lng: 18.379722 },
    { lat: 49.302413, lng: 18.379135 },
    { lat: 49.302636, lng: 18.378586 },
    { lat: 49.302828, lng: 18.378093 },
    { lat: 49.303032, lng: 18.377528 },
    { lat: 49.30321, lng: 18.37729 },
    { lat: 49.303376, lng: 18.377103 },
    { lat: 49.30366, lng: 18.377002 },
    { lat: 49.304147, lng: 18.376589 },
    { lat: 49.304521, lng: 18.376466 },
    { lat: 49.304964, lng: 18.376622 },
    { lat: 49.305178, lng: 18.376721 },
    { lat: 49.305586, lng: 18.376655 },
    { lat: 49.306174, lng: 18.376393 },
    { lat: 49.3066, lng: 18.37613 },
    { lat: 49.307045, lng: 18.375812 },
    { lat: 49.307678, lng: 18.375366 },
    { lat: 49.30811, lng: 18.375254 },
    { lat: 49.308937, lng: 18.375051 },
    { lat: 49.30931, lng: 18.374896 },
    { lat: 49.309768, lng: 18.374711 },
    { lat: 49.310521, lng: 18.374492 },
    { lat: 49.310813, lng: 18.374617 },
    { lat: 49.310838, lng: 18.374627 },
    { lat: 49.311352, lng: 18.37499 },
    { lat: 49.311929, lng: 18.375125 },
    { lat: 49.312417, lng: 18.375184 },
    { lat: 49.312894, lng: 18.375441 },
    { lat: 49.313383, lng: 18.375773 },
    { lat: 49.31344, lng: 18.375812 },
    { lat: 49.313796, lng: 18.375928 },
    { lat: 49.314105, lng: 18.375923 },
    { lat: 49.314762, lng: 18.375998 },
    { lat: 49.315157, lng: 18.375931 },
    { lat: 49.315434, lng: 18.375951 },
    { lat: 49.315992, lng: 18.375763 },
    { lat: 49.316428, lng: 18.37536 },
    { lat: 49.316556, lng: 18.375239 },
    { lat: 49.316941, lng: 18.374904 },
    { lat: 49.31726, lng: 18.374493 },
    { lat: 49.31773, lng: 18.374107 },
    { lat: 49.318162, lng: 18.373839 },
    { lat: 49.318564, lng: 18.37367 },
    { lat: 49.318983, lng: 18.373402 },
    { lat: 49.318851, lng: 18.372729 },
    { lat: 49.31868, lng: 18.370246 },
    { lat: 49.318829, lng: 18.368828 },
    { lat: 49.319016, lng: 18.367739 },
    { lat: 49.319061, lng: 18.367331 },
    { lat: 49.318572, lng: 18.365882 },
    { lat: 49.318381, lng: 18.365333 },
    { lat: 49.318202, lng: 18.364894 },
    { lat: 49.317738, lng: 18.363778 },
    { lat: 49.317524, lng: 18.362985 },
    { lat: 49.317459, lng: 18.362009 },
    { lat: 49.317535, lng: 18.361558 },
    { lat: 49.317596, lng: 18.361184 },
    { lat: 49.317772, lng: 18.360671 },
    { lat: 49.317953, lng: 18.360206 },
    { lat: 49.318197, lng: 18.359709 },
    { lat: 49.318777, lng: 18.358701 },
    { lat: 49.319077, lng: 18.358252 },
    { lat: 49.319424, lng: 18.357318 },
    { lat: 49.319646, lng: 18.35637 },
    { lat: 49.319705, lng: 18.355895 },
    { lat: 49.319579, lng: 18.35547 },
    { lat: 49.319348, lng: 18.355027 },
    { lat: 49.318945, lng: 18.35395 },
    { lat: 49.318768, lng: 18.352812 },
    { lat: 49.31866, lng: 18.351352 },
    { lat: 49.318666, lng: 18.349832 },
    { lat: 49.318621, lng: 18.349267 },
    { lat: 49.318499, lng: 18.348765 },
    { lat: 49.318192, lng: 18.348027 },
    { lat: 49.317565, lng: 18.346 },
    { lat: 49.317359, lng: 18.345221 },
    { lat: 49.317211, lng: 18.34451 },
    { lat: 49.317146, lng: 18.344158 },
    { lat: 49.316838, lng: 18.342837 },
    { lat: 49.316609, lng: 18.341883 },
    { lat: 49.316062, lng: 18.34076 },
    { lat: 49.315835, lng: 18.339652 },
    { lat: 49.315818, lng: 18.339226 },
    { lat: 49.315834, lng: 18.338822 },
    { lat: 49.315761, lng: 18.337919 },
    { lat: 49.3156, lng: 18.337231 },
    { lat: 49.315245, lng: 18.33578 },
    { lat: 49.315179, lng: 18.335245 },
    { lat: 49.315088, lng: 18.333895 },
    { lat: 49.315162, lng: 18.332679 },
    { lat: 49.315257, lng: 18.331911 },
    { lat: 49.315264, lng: 18.331358 },
    { lat: 49.315089, lng: 18.3305 },
    { lat: 49.314735, lng: 18.329071 },
    { lat: 49.314367, lng: 18.328173 },
    { lat: 49.314342, lng: 18.328114 },
    { lat: 49.314088, lng: 18.327678 },
    { lat: 49.313663, lng: 18.326475 },
    { lat: 49.313421, lng: 18.325617 },
    { lat: 49.313305, lng: 18.325089 },
  ],
  DolnáMariková: [
    { lat: 49.215976, lng: 18.306681 },
    { lat: 49.216105, lng: 18.306656 },
    { lat: 49.216008, lng: 18.30678 },
    { lat: 49.215935, lng: 18.306903 },
    { lat: 49.215948, lng: 18.307251 },
    { lat: 49.216121, lng: 18.308356 },
    { lat: 49.216215, lng: 18.308793 },
    { lat: 49.216306, lng: 18.309092 },
    { lat: 49.216363, lng: 18.309316 },
    { lat: 49.216409, lng: 18.309652 },
    { lat: 49.216446, lng: 18.310041 },
    { lat: 49.216463, lng: 18.310171 },
    { lat: 49.216426, lng: 18.310384 },
    { lat: 49.216227, lng: 18.311212 },
    { lat: 49.216226, lng: 18.311292 },
    { lat: 49.216208, lng: 18.311482 },
    { lat: 49.216189, lng: 18.311754 },
    { lat: 49.21623, lng: 18.311992 },
    { lat: 49.216258, lng: 18.312941 },
    { lat: 49.21626, lng: 18.31305 },
    { lat: 49.216154, lng: 18.313273 },
    { lat: 49.216045, lng: 18.313433 },
    { lat: 49.216083, lng: 18.313543 },
    { lat: 49.216157, lng: 18.313716 },
    { lat: 49.216166, lng: 18.313822 },
    { lat: 49.216267, lng: 18.314375 },
    { lat: 49.216195, lng: 18.31464 },
    { lat: 49.216102, lng: 18.314957 },
    { lat: 49.216093, lng: 18.315006 },
    { lat: 49.216081, lng: 18.315285 },
    { lat: 49.216082, lng: 18.315798 },
    { lat: 49.216084, lng: 18.316235 },
    { lat: 49.216078, lng: 18.316612 },
    { lat: 49.216075, lng: 18.316741 },
    { lat: 49.216053, lng: 18.316904 },
    { lat: 49.21603, lng: 18.31718 },
    { lat: 49.215985, lng: 18.317358 },
    { lat: 49.215941, lng: 18.317544 },
    { lat: 49.215831, lng: 18.317787 },
    { lat: 49.215662, lng: 18.318212 },
    { lat: 49.215625, lng: 18.318505 },
    { lat: 49.215574, lng: 18.319064 },
    { lat: 49.215496, lng: 18.319216 },
    { lat: 49.215455, lng: 18.319569 },
    { lat: 49.215519, lng: 18.320542 },
    { lat: 49.215536, lng: 18.320765 },
    { lat: 49.215596, lng: 18.321044 },
    { lat: 49.215677, lng: 18.321499 },
    { lat: 49.215678, lng: 18.321704 },
    { lat: 49.215713, lng: 18.321967 },
    { lat: 49.21581, lng: 18.322507 },
    { lat: 49.215866, lng: 18.322905 },
    { lat: 49.215862, lng: 18.323195 },
    { lat: 49.215765, lng: 18.323754 },
    { lat: 49.215479, lng: 18.32463 },
    { lat: 49.215191, lng: 18.325614 },
    { lat: 49.214702, lng: 18.327328 },
    { lat: 49.214294, lng: 18.328632 },
    { lat: 49.213838, lng: 18.330313 },
    { lat: 49.213668, lng: 18.330768 },
    { lat: 49.213591, lng: 18.330827 },
    { lat: 49.213311, lng: 18.330578 },
    { lat: 49.213139, lng: 18.330609 },
    { lat: 49.212906, lng: 18.330636 },
    { lat: 49.212769, lng: 18.330567 },
    { lat: 49.212346, lng: 18.330103 },
    { lat: 49.21225, lng: 18.329978 },
    { lat: 49.212062, lng: 18.329719 },
    { lat: 49.211965, lng: 18.329681 },
    { lat: 49.211541, lng: 18.329701 },
    { lat: 49.211369, lng: 18.329606 },
    { lat: 49.21124, lng: 18.329449 },
    { lat: 49.21112, lng: 18.329159 },
    { lat: 49.21103, lng: 18.328988 },
    { lat: 49.210955, lng: 18.328773 },
    { lat: 49.2098, lng: 18.327108 },
    { lat: 49.209706, lng: 18.327081 },
    { lat: 49.209006, lng: 18.326864 },
    { lat: 49.208765, lng: 18.326068 },
    { lat: 49.208034, lng: 18.325814 },
    { lat: 49.206986, lng: 18.325899 },
    { lat: 49.206719, lng: 18.326161 },
    { lat: 49.206535, lng: 18.325953 },
    { lat: 49.206417, lng: 18.32581 },
    { lat: 49.206205, lng: 18.32596 },
    { lat: 49.205925, lng: 18.325907 },
    { lat: 49.205551, lng: 18.326474 },
    { lat: 49.204646, lng: 18.326396 },
    { lat: 49.204482, lng: 18.326082 },
    { lat: 49.203856, lng: 18.326128 },
    { lat: 49.20339, lng: 18.325574 },
    { lat: 49.203318, lng: 18.325571 },
    { lat: 49.202921, lng: 18.325591 },
    { lat: 49.202281, lng: 18.3253 },
    { lat: 49.20105, lng: 18.324712 },
    { lat: 49.200023, lng: 18.32402 },
    { lat: 49.199982, lng: 18.324008 },
    { lat: 49.199317, lng: 18.323856 },
    { lat: 49.198959, lng: 18.323913 },
    { lat: 49.198166, lng: 18.324204 },
    { lat: 49.197821, lng: 18.32414 },
    { lat: 49.197224, lng: 18.323933 },
    { lat: 49.197116, lng: 18.323966 },
    { lat: 49.196983, lng: 18.32401 },
    { lat: 49.196152, lng: 18.324521 },
    { lat: 49.196026, lng: 18.324559 },
    { lat: 49.195315, lng: 18.324738 },
    { lat: 49.195312, lng: 18.3247959 },
    { lat: 49.1952894, lng: 18.3252408 },
    { lat: 49.1952943, lng: 18.3253292 },
    { lat: 49.1951873, lng: 18.3274721 },
    { lat: 49.1951876, lng: 18.32752 },
    { lat: 49.195086, lng: 18.3295772 },
    { lat: 49.19503, lng: 18.331018 },
    { lat: 49.1948541, lng: 18.3321906 },
    { lat: 49.1948538, lng: 18.3322274 },
    { lat: 49.1948253, lng: 18.3324246 },
    { lat: 49.1955031, lng: 18.3345822 },
    { lat: 49.1955683, lng: 18.3347836 },
    { lat: 49.1956942, lng: 18.3349347 },
    { lat: 49.1959319, lng: 18.3353132 },
    { lat: 49.1956555, lng: 18.3360413 },
    { lat: 49.195633, lng: 18.3362655 },
    { lat: 49.1953339, lng: 18.3367273 },
    { lat: 49.1952511, lng: 18.3367813 },
    { lat: 49.1949878, lng: 18.3371382 },
    { lat: 49.1946241, lng: 18.3387251 },
    { lat: 49.1938858, lng: 18.3393642 },
    { lat: 49.193646, lng: 18.3393931 },
    { lat: 49.1934255, lng: 18.3394066 },
    { lat: 49.1932694, lng: 18.3394768 },
    { lat: 49.1931308, lng: 18.3395826 },
    { lat: 49.1930289, lng: 18.3397381 },
    { lat: 49.1929937, lng: 18.3398361 },
    { lat: 49.1929913, lng: 18.339852 },
    { lat: 49.1928653, lng: 18.340222 },
    { lat: 49.1928451, lng: 18.3404282 },
    { lat: 49.1928455, lng: 18.3404738 },
    { lat: 49.1926673, lng: 18.3422553 },
    { lat: 49.1923554, lng: 18.344939 },
    { lat: 49.1923491, lng: 18.34505 },
    { lat: 49.1922992, lng: 18.3453689 },
    { lat: 49.1922461, lng: 18.3457952 },
    { lat: 49.1922094, lng: 18.3461069 },
    { lat: 49.192209, lng: 18.3462188 },
    { lat: 49.1922221, lng: 18.3466203 },
    { lat: 49.192221, lng: 18.3466605 },
    { lat: 49.1922229, lng: 18.3468121 },
    { lat: 49.1922139, lng: 18.346915 },
    { lat: 49.1921753, lng: 18.3470744 },
    { lat: 49.1921256, lng: 18.348147 },
    { lat: 49.1921462, lng: 18.3490433 },
    { lat: 49.1917156, lng: 18.3514405 },
    { lat: 49.1919585, lng: 18.3518517 },
    { lat: 49.1919305, lng: 18.3522195 },
    { lat: 49.1917087, lng: 18.3527366 },
    { lat: 49.1916888, lng: 18.3527814 },
    { lat: 49.1915441, lng: 18.3531207 },
    { lat: 49.1907018, lng: 18.3543468 },
    { lat: 49.1907, lng: 18.3543952 },
    { lat: 49.1904417, lng: 18.3538085 },
    { lat: 49.1904191, lng: 18.3536455 },
    { lat: 49.1904272, lng: 18.3535074 },
    { lat: 49.1904613, lng: 18.3533486 },
    { lat: 49.1905709, lng: 18.3530293 },
    { lat: 49.190811, lng: 18.3525176 },
    { lat: 49.1908871, lng: 18.3522646 },
    { lat: 49.1899804, lng: 18.3514995 },
    { lat: 49.1892706, lng: 18.3524874 },
    { lat: 49.1891345, lng: 18.3534683 },
    { lat: 49.1891306, lng: 18.3535201 },
    { lat: 49.1889821, lng: 18.3547635 },
    { lat: 49.188793, lng: 18.3556042 },
    { lat: 49.1885854, lng: 18.3564537 },
    { lat: 49.1885668, lng: 18.3564843 },
    { lat: 49.1886358, lng: 18.357523 },
    { lat: 49.188666, lng: 18.3577835 },
    { lat: 49.1886919, lng: 18.357803 },
    { lat: 49.1890107, lng: 18.3580169 },
    { lat: 49.188754, lng: 18.359082 },
    { lat: 49.1889377, lng: 18.3588952 },
    { lat: 49.18947, lng: 18.3586283 },
    { lat: 49.1895712, lng: 18.3585627 },
    { lat: 49.1896638, lng: 18.3585241 },
    { lat: 49.1896603, lng: 18.3578723 },
    { lat: 49.1896959, lng: 18.3576801 },
    { lat: 49.1897828, lng: 18.3574795 },
    { lat: 49.1899114, lng: 18.3573956 },
    { lat: 49.1909432, lng: 18.3587504 },
    { lat: 49.1914152, lng: 18.358331 },
    { lat: 49.1916787, lng: 18.3599652 },
    { lat: 49.1916577, lng: 18.3600151 },
    { lat: 49.1913277, lng: 18.3606526 },
    { lat: 49.1914305, lng: 18.361123 },
    { lat: 49.1916978, lng: 18.3615071 },
    { lat: 49.1917681, lng: 18.3616757 },
    { lat: 49.1918579, lng: 18.3617082 },
    { lat: 49.1919359, lng: 18.361793 },
    { lat: 49.1920547, lng: 18.3620027 },
    { lat: 49.1921999, lng: 18.3622804 },
    { lat: 49.1922844, lng: 18.3635387 },
    { lat: 49.1922179, lng: 18.3637841 },
    { lat: 49.1922124, lng: 18.3639866 },
    { lat: 49.1922265, lng: 18.3643849 },
    { lat: 49.1923548, lng: 18.3651296 },
    { lat: 49.1927345, lng: 18.3666851 },
    { lat: 49.1928252, lng: 18.3667551 },
    { lat: 49.1931637, lng: 18.3669377 },
    { lat: 49.193371, lng: 18.367117 },
    { lat: 49.193444, lng: 18.367155 },
    { lat: 49.1934739, lng: 18.3671602 },
    { lat: 49.1937364, lng: 18.3675035 },
    { lat: 49.1938168, lng: 18.3676111 },
    { lat: 49.1939026, lng: 18.3677335 },
    { lat: 49.1942109, lng: 18.3681432 },
    { lat: 49.1943709, lng: 18.3684888 },
    { lat: 49.1946874, lng: 18.3689637 },
    { lat: 49.1951116, lng: 18.3695978 },
    { lat: 49.195231, lng: 18.3698576 },
    { lat: 49.1952463, lng: 18.3699296 },
    { lat: 49.1952397, lng: 18.369955 },
    { lat: 49.1952942, lng: 18.3701019 },
    { lat: 49.1953189, lng: 18.3701215 },
    { lat: 49.1953909, lng: 18.3701782 },
    { lat: 49.19551, lng: 18.3702716 },
    { lat: 49.1955772, lng: 18.3703247 },
    { lat: 49.195614, lng: 18.3703348 },
    { lat: 49.1960354, lng: 18.3704486 },
    { lat: 49.1960822, lng: 18.3704783 },
    { lat: 49.1961417, lng: 18.3705162 },
    { lat: 49.1961956, lng: 18.3705504 },
    { lat: 49.1966109, lng: 18.3706143 },
    { lat: 49.1967003, lng: 18.3708442 },
    { lat: 49.1967897, lng: 18.3711128 },
    { lat: 49.1971462, lng: 18.3715407 },
    { lat: 49.1981815, lng: 18.3725317 },
    { lat: 49.198841, lng: 18.3730951 },
    { lat: 49.1991113, lng: 18.3735728 },
    { lat: 49.1991859, lng: 18.3737374 },
    { lat: 49.1999185, lng: 18.3750726 },
    { lat: 49.2002418, lng: 18.3756947 },
    { lat: 49.2006173, lng: 18.3761185 },
    { lat: 49.2005993, lng: 18.3761605 },
    { lat: 49.2003835, lng: 18.3768059 },
    { lat: 49.2003306, lng: 18.3769516 },
    { lat: 49.2002824, lng: 18.3771003 },
    { lat: 49.2002047, lng: 18.3773026 },
    { lat: 49.2002083, lng: 18.3783658 },
    { lat: 49.2010127, lng: 18.3795909 },
    { lat: 49.2016008, lng: 18.3795555 },
    { lat: 49.2019952, lng: 18.3795275 },
    { lat: 49.2030638, lng: 18.3818359 },
    { lat: 49.2033928, lng: 18.3824994 },
    { lat: 49.2037732, lng: 18.3826461 },
    { lat: 49.2043664, lng: 18.3828493 },
    { lat: 49.2052102, lng: 18.3834188 },
    { lat: 49.2054776, lng: 18.3838681 },
    { lat: 49.2062714, lng: 18.383984 },
    { lat: 49.2063051, lng: 18.3839825 },
    { lat: 49.2063819, lng: 18.3834356 },
    { lat: 49.2067772, lng: 18.3836682 },
    { lat: 49.2087769, lng: 18.3832947 },
    { lat: 49.2097695, lng: 18.3826194 },
    { lat: 49.2102791, lng: 18.3822694 },
    { lat: 49.2102409, lng: 18.3817203 },
    { lat: 49.2102161, lng: 18.3812934 },
    { lat: 49.2103021, lng: 18.3812796 },
    { lat: 49.211262, lng: 18.3811129 },
    { lat: 49.2125553, lng: 18.3808177 },
    { lat: 49.2128007, lng: 18.3801956 },
    { lat: 49.2137683, lng: 18.3808252 },
    { lat: 49.2150365, lng: 18.3817039 },
    { lat: 49.2149507, lng: 18.3819699 },
    { lat: 49.2151376, lng: 18.3822879 },
    { lat: 49.2158696, lng: 18.3835296 },
    { lat: 49.2163222, lng: 18.3843088 },
    { lat: 49.2169924, lng: 18.3845547 },
    { lat: 49.2173949, lng: 18.3847373 },
    { lat: 49.2186764, lng: 18.385307 },
    { lat: 49.2189972, lng: 18.384987 },
    { lat: 49.2188436, lng: 18.3849648 },
    { lat: 49.2178705, lng: 18.383852 },
    { lat: 49.2185446, lng: 18.3824376 },
    { lat: 49.2186287, lng: 18.3824282 },
    { lat: 49.2187871, lng: 18.3823941 },
    { lat: 49.21906, lng: 18.3822887 },
    { lat: 49.2190812, lng: 18.3822843 },
    { lat: 49.2194936, lng: 18.3821543 },
    { lat: 49.2202842, lng: 18.3828866 },
    { lat: 49.2210299, lng: 18.3839878 },
    { lat: 49.2203792, lng: 18.3852753 },
    { lat: 49.2211486, lng: 18.3865395 },
    { lat: 49.2211833, lng: 18.3858836 },
    { lat: 49.2217387, lng: 18.3847712 },
    { lat: 49.2227, lng: 18.3843636 },
    { lat: 49.2228253, lng: 18.3841417 },
    { lat: 49.2230821, lng: 18.3842602 },
    { lat: 49.2233175, lng: 18.3842125 },
    { lat: 49.2238139, lng: 18.3845202 },
    { lat: 49.2240547, lng: 18.384343 },
    { lat: 49.2256627, lng: 18.3831324 },
    { lat: 49.2266856, lng: 18.3824333 },
    { lat: 49.2271486, lng: 18.3817261 },
    { lat: 49.2273983, lng: 18.3812874 },
    { lat: 49.2277507, lng: 18.3808052 },
    { lat: 49.2284499, lng: 18.3802922 },
    { lat: 49.2291387, lng: 18.3799584 },
    { lat: 49.2293148, lng: 18.3798817 },
    { lat: 49.2294105, lng: 18.3797742 },
    { lat: 49.2296876, lng: 18.3794804 },
    { lat: 49.229818, lng: 18.3793939 },
    { lat: 49.2306836, lng: 18.3793154 },
    { lat: 49.2310866, lng: 18.3795669 },
    { lat: 49.2312271, lng: 18.3795563 },
    { lat: 49.2313226, lng: 18.3795303 },
    { lat: 49.231973, lng: 18.3788074 },
    { lat: 49.2337554, lng: 18.3768955 },
    { lat: 49.2340328, lng: 18.3766879 },
    { lat: 49.234379, lng: 18.3766647 },
    { lat: 49.2345076, lng: 18.3766868 },
    { lat: 49.2349089, lng: 18.3771722 },
    { lat: 49.2356683, lng: 18.3774185 },
    { lat: 49.2363126, lng: 18.3772797 },
    { lat: 49.2367586, lng: 18.3773302 },
    { lat: 49.2375461, lng: 18.3775541 },
    { lat: 49.2391297, lng: 18.377692 },
    { lat: 49.2394465, lng: 18.3777729 },
    { lat: 49.239814, lng: 18.377828 },
    { lat: 49.2398533, lng: 18.3773762 },
    { lat: 49.2398621, lng: 18.3757638 },
    { lat: 49.2399345, lng: 18.3750956 },
    { lat: 49.2401556, lng: 18.3741358 },
    { lat: 49.2402227, lng: 18.3736572 },
    { lat: 49.2404443, lng: 18.3726706 },
    { lat: 49.2408923, lng: 18.3717587 },
    { lat: 49.2411961, lng: 18.3710122 },
    { lat: 49.2415984, lng: 18.3706587 },
    { lat: 49.2419111, lng: 18.3704782 },
    { lat: 49.2426523, lng: 18.3700502 },
    { lat: 49.2428482, lng: 18.3699422 },
    { lat: 49.2433721, lng: 18.3698503 },
    { lat: 49.2444186, lng: 18.3697 },
    { lat: 49.2447939, lng: 18.3693285 },
    { lat: 49.246177, lng: 18.364022 },
    { lat: 49.2464695, lng: 18.3617102 },
    { lat: 49.2466297, lng: 18.3615082 },
    { lat: 49.2473698, lng: 18.3604739 },
    { lat: 49.2488459, lng: 18.3585937 },
    { lat: 49.2504887, lng: 18.3568283 },
    { lat: 49.252351, lng: 18.3554072 },
    { lat: 49.2526368, lng: 18.35377 },
    { lat: 49.2526967, lng: 18.3521247 },
    { lat: 49.2525962, lng: 18.3507247 },
    { lat: 49.2525412, lng: 18.3498379 },
    { lat: 49.2510055, lng: 18.3504782 },
    { lat: 49.2507536, lng: 18.3505131 },
    { lat: 49.2507088, lng: 18.3505201 },
    { lat: 49.2499827, lng: 18.3513373 },
    { lat: 49.2494942, lng: 18.351846 },
    { lat: 49.2487153, lng: 18.3527698 },
    { lat: 49.2479684, lng: 18.3535798 },
    { lat: 49.2467364, lng: 18.355158 },
    { lat: 49.246165, lng: 18.3556349 },
    { lat: 49.2460824, lng: 18.3556326 },
    { lat: 49.2460039, lng: 18.3557005 },
    { lat: 49.2458783, lng: 18.355737 },
    { lat: 49.2457144, lng: 18.3559745 },
    { lat: 49.2454407, lng: 18.356184 },
    { lat: 49.2453217, lng: 18.3562753 },
    { lat: 49.2447676, lng: 18.3566146 },
    { lat: 49.2442547, lng: 18.3568685 },
    { lat: 49.2442061, lng: 18.3568652 },
    { lat: 49.2441178, lng: 18.3558032 },
    { lat: 49.2440849, lng: 18.3554371 },
    { lat: 49.243732, lng: 18.3512563 },
    { lat: 49.2418761, lng: 18.3525319 },
    { lat: 49.241467, lng: 18.3528214 },
    { lat: 49.2412992, lng: 18.35295 },
    { lat: 49.2410579, lng: 18.3531348 },
    { lat: 49.2407715, lng: 18.3533618 },
    { lat: 49.2404596, lng: 18.3536369 },
    { lat: 49.2401964, lng: 18.3538727 },
    { lat: 49.2399748, lng: 18.3541687 },
    { lat: 49.2398594, lng: 18.3542617 },
    { lat: 49.2396834, lng: 18.3543448 },
    { lat: 49.2394096, lng: 18.3543904 },
    { lat: 49.2390912, lng: 18.3543053 },
    { lat: 49.2387771, lng: 18.3541918 },
    { lat: 49.2385704, lng: 18.3541089 },
    { lat: 49.238097, lng: 18.3539114 },
    { lat: 49.2380904, lng: 18.3538942 },
    { lat: 49.2371345, lng: 18.352773 },
    { lat: 49.2370798, lng: 18.3527193 },
    { lat: 49.2367181, lng: 18.3522811 },
    { lat: 49.2358124, lng: 18.3514719 },
    { lat: 49.2346344, lng: 18.351347 },
    { lat: 49.2332316, lng: 18.3505252 },
    { lat: 49.2310529, lng: 18.3513483 },
    { lat: 49.2292403, lng: 18.3497935 },
    { lat: 49.2284235, lng: 18.3482699 },
    { lat: 49.2272502, lng: 18.3451675 },
    { lat: 49.2261541, lng: 18.3440602 },
    { lat: 49.2260213, lng: 18.3440071 },
    { lat: 49.2259766, lng: 18.3439932 },
    { lat: 49.2255914, lng: 18.3438434 },
    { lat: 49.2255135, lng: 18.3438185 },
    { lat: 49.225448, lng: 18.3438189 },
    { lat: 49.2252702, lng: 18.3437624 },
    { lat: 49.2249302, lng: 18.3435757 },
    { lat: 49.2248276, lng: 18.3434943 },
    { lat: 49.2246277, lng: 18.3434333 },
    { lat: 49.2243496, lng: 18.3432182 },
    { lat: 49.2240522, lng: 18.3430019 },
    { lat: 49.2237748, lng: 18.3428431 },
    { lat: 49.2228794, lng: 18.3413884 },
    { lat: 49.2221064, lng: 18.3336137 },
    { lat: 49.221309, lng: 18.3339386 },
    { lat: 49.2210463, lng: 18.3340451 },
    { lat: 49.2207378, lng: 18.3340954 },
    { lat: 49.2205422, lng: 18.3342501 },
    { lat: 49.2204922, lng: 18.3340184 },
    { lat: 49.2204676, lng: 18.3339433 },
    { lat: 49.2203983, lng: 18.3336913 },
    { lat: 49.2203819, lng: 18.3337036 },
    { lat: 49.2203048, lng: 18.333571 },
    { lat: 49.2202911, lng: 18.333549 },
    { lat: 49.2202714, lng: 18.333518 },
    { lat: 49.2202312, lng: 18.3334467 },
    { lat: 49.2201951, lng: 18.3334857 },
    { lat: 49.219856, lng: 18.3338575 },
    { lat: 49.2196309, lng: 18.3340935 },
    { lat: 49.2195125, lng: 18.3342118 },
    { lat: 49.2194527, lng: 18.3341114 },
    { lat: 49.21937, lng: 18.3338237 },
    { lat: 49.219249, lng: 18.3334136 },
    { lat: 49.2192036, lng: 18.3332256 },
    { lat: 49.2190635, lng: 18.3323719 },
    { lat: 49.2189262, lng: 18.3313258 },
    { lat: 49.2186558, lng: 18.329658 },
    { lat: 49.2185264, lng: 18.3286696 },
    { lat: 49.2184429, lng: 18.3279601 },
    { lat: 49.2194389, lng: 18.3259595 },
    { lat: 49.2197197, lng: 18.324967 },
    { lat: 49.2198875, lng: 18.3238927 },
    { lat: 49.221325, lng: 18.3210526 },
    { lat: 49.2223391, lng: 18.3189911 },
    { lat: 49.22319, lng: 18.3174412 },
    { lat: 49.2234097, lng: 18.3170327 },
    { lat: 49.2236915, lng: 18.3165038 },
    { lat: 49.223996, lng: 18.3148266 },
    { lat: 49.2246758, lng: 18.3125811 },
    { lat: 49.2265995, lng: 18.3102251 },
    { lat: 49.2266825, lng: 18.3099766 },
    { lat: 49.2274697, lng: 18.307552 },
    { lat: 49.2275872, lng: 18.3060824 },
    { lat: 49.2276235, lng: 18.3056835 },
    { lat: 49.2276715, lng: 18.3050934 },
    { lat: 49.2278012, lng: 18.3042643 },
    { lat: 49.2278468, lng: 18.3041123 },
    { lat: 49.2279506, lng: 18.3036476 },
    { lat: 49.2281314, lng: 18.3031543 },
    { lat: 49.2283826, lng: 18.3026203 },
    { lat: 49.2286568, lng: 18.3021006 },
    { lat: 49.2287294, lng: 18.3019872 },
    { lat: 49.2287801, lng: 18.3018419 },
    { lat: 49.2290969, lng: 18.301336 },
    { lat: 49.2292124, lng: 18.3011224 },
    { lat: 49.2292487, lng: 18.3009649 },
    { lat: 49.2293179, lng: 18.3004065 },
    { lat: 49.2294153, lng: 18.3001116 },
    { lat: 49.2295549, lng: 18.2996839 },
    { lat: 49.2296319, lng: 18.298945 },
    { lat: 49.2295457, lng: 18.2985171 },
    { lat: 49.2298018, lng: 18.2981348 },
    { lat: 49.2300927, lng: 18.2974284 },
    { lat: 49.2309651, lng: 18.2968095 },
    { lat: 49.2319027, lng: 18.2959884 },
    { lat: 49.2333067, lng: 18.2950308 },
    { lat: 49.2332938, lng: 18.2946411 },
    { lat: 49.2332388, lng: 18.2930361 },
    { lat: 49.2332296, lng: 18.2927927 },
    { lat: 49.2331754, lng: 18.2920664 },
    { lat: 49.2329471, lng: 18.2908129 },
    { lat: 49.2324079, lng: 18.2887256 },
    { lat: 49.2323383, lng: 18.2873631 },
    { lat: 49.232311, lng: 18.2862537 },
    { lat: 49.232421, lng: 18.2852195 },
    { lat: 49.2324742, lng: 18.2846021 },
    { lat: 49.2325716, lng: 18.2843077 },
    { lat: 49.2329602, lng: 18.2831332 },
    { lat: 49.2328098, lng: 18.282341 },
    { lat: 49.2326677, lng: 18.2816219 },
    { lat: 49.2327095, lng: 18.2799969 },
    { lat: 49.2326946, lng: 18.2793697 },
    { lat: 49.2327121, lng: 18.2782782 },
    { lat: 49.2329454, lng: 18.277331 },
    { lat: 49.2331356, lng: 18.275455 },
    { lat: 49.233357, lng: 18.271427 },
    { lat: 49.232942, lng: 18.272197 },
    { lat: 49.232401, lng: 18.272991 },
    { lat: 49.231844, lng: 18.273949 },
    { lat: 49.231525, lng: 18.274554 },
    { lat: 49.230953, lng: 18.275631 },
    { lat: 49.231648, lng: 18.277448 },
    { lat: 49.231932, lng: 18.278216 },
    { lat: 49.232163, lng: 18.279008 },
    { lat: 49.232078, lng: 18.279415 },
    { lat: 49.232133, lng: 18.279578 },
    { lat: 49.232154, lng: 18.279638 },
    { lat: 49.231614, lng: 18.279951 },
    { lat: 49.231226, lng: 18.280243 },
    { lat: 49.230936, lng: 18.280618 },
    { lat: 49.230875, lng: 18.280697 },
    { lat: 49.230694, lng: 18.280842 },
    { lat: 49.230714, lng: 18.280857 },
    { lat: 49.230651, lng: 18.280992 },
    { lat: 49.230345, lng: 18.281079 },
    { lat: 49.229814, lng: 18.28149 },
    { lat: 49.229628, lng: 18.281725 },
    { lat: 49.229314, lng: 18.281968 },
    { lat: 49.228816, lng: 18.282477 },
    { lat: 49.228375, lng: 18.282664 },
    { lat: 49.228321, lng: 18.282677 },
    { lat: 49.227774, lng: 18.282943 },
    { lat: 49.227121, lng: 18.283375 },
    { lat: 49.226713, lng: 18.28376 },
    { lat: 49.226651, lng: 18.283802 },
    { lat: 49.226579, lng: 18.283845 },
    { lat: 49.226062, lng: 18.284142 },
    { lat: 49.225622, lng: 18.284456 },
    { lat: 49.225136, lng: 18.28449 },
    { lat: 49.224554, lng: 18.284658 },
    { lat: 49.224119, lng: 18.284869 },
    { lat: 49.223656, lng: 18.285199 },
    { lat: 49.223116, lng: 18.285586 },
    { lat: 49.222715, lng: 18.285791 },
    { lat: 49.222453, lng: 18.286207 },
    { lat: 49.222073, lng: 18.286557 },
    { lat: 49.221671, lng: 18.286939 },
    { lat: 49.221554, lng: 18.287044 },
    { lat: 49.221443, lng: 18.287571 },
    { lat: 49.221309, lng: 18.287656 },
    { lat: 49.221202, lng: 18.287755 },
    { lat: 49.221167, lng: 18.287983 },
    { lat: 49.22103, lng: 18.288191 },
    { lat: 49.220726, lng: 18.288775 },
    { lat: 49.22042, lng: 18.289171 },
    { lat: 49.219881, lng: 18.290202 },
    { lat: 49.219643, lng: 18.290278 },
    { lat: 49.219601, lng: 18.290292 },
    { lat: 49.219513, lng: 18.290109 },
    { lat: 49.21935, lng: 18.289763 },
    { lat: 49.219252, lng: 18.28959 },
    { lat: 49.219226, lng: 18.289549 },
    { lat: 49.219176, lng: 18.28947 },
    { lat: 49.218945, lng: 18.288848 },
    { lat: 49.218702, lng: 18.288137 },
    { lat: 49.218484, lng: 18.287199 },
    { lat: 49.218437, lng: 18.286802 },
    { lat: 49.218606, lng: 18.286146 },
    { lat: 49.218844, lng: 18.285594 },
    { lat: 49.218974, lng: 18.285297 },
    { lat: 49.219011, lng: 18.28521 },
    { lat: 49.219155, lng: 18.284658 },
    { lat: 49.219167, lng: 18.284238 },
    { lat: 49.21904, lng: 18.283455 },
    { lat: 49.218922, lng: 18.283084 },
    { lat: 49.218441, lng: 18.283776 },
    { lat: 49.218394, lng: 18.283844 },
    { lat: 49.218194, lng: 18.284104 },
    { lat: 49.218174, lng: 18.28413 },
    { lat: 49.217889, lng: 18.2845 },
    { lat: 49.217726, lng: 18.28468 },
    { lat: 49.217571, lng: 18.284835 },
    { lat: 49.216885, lng: 18.285522 },
    { lat: 49.216542, lng: 18.285865 },
    { lat: 49.216426, lng: 18.285991 },
    { lat: 49.216278, lng: 18.286145 },
    { lat: 49.216248, lng: 18.286178 },
    { lat: 49.215786, lng: 18.286699 },
    { lat: 49.215181, lng: 18.287309 },
    { lat: 49.214863, lng: 18.287444 },
    { lat: 49.214262, lng: 18.287697 },
    { lat: 49.214255, lng: 18.28771 },
    { lat: 49.214226, lng: 18.287761 },
    { lat: 49.213584, lng: 18.288764 },
    { lat: 49.213103, lng: 18.289518 },
    { lat: 49.212984, lng: 18.28969 },
    { lat: 49.212945, lng: 18.289748 },
    { lat: 49.21293, lng: 18.289773 },
    { lat: 49.213139, lng: 18.290165 },
    { lat: 49.213199, lng: 18.290282 },
    { lat: 49.21405, lng: 18.291887 },
    { lat: 49.214424, lng: 18.292776 },
    { lat: 49.214459, lng: 18.292866 },
    { lat: 49.214528, lng: 18.293076 },
    { lat: 49.21464, lng: 18.293232 },
    { lat: 49.214699, lng: 18.293339 },
    { lat: 49.214959, lng: 18.293825 },
    { lat: 49.215396, lng: 18.294673 },
    { lat: 49.21542, lng: 18.29478 },
    { lat: 49.215519, lng: 18.295124 },
    { lat: 49.215811, lng: 18.295927 },
    { lat: 49.21591, lng: 18.296199 },
    { lat: 49.21621, lng: 18.297287 },
    { lat: 49.216626, lng: 18.298563 },
    { lat: 49.216633, lng: 18.298633 },
    { lat: 49.216644, lng: 18.298728 },
    { lat: 49.21666, lng: 18.298882 },
    { lat: 49.216671, lng: 18.298975 },
    { lat: 49.216789, lng: 18.299131 },
    { lat: 49.216776, lng: 18.29931 },
    { lat: 49.216764, lng: 18.299376 },
    { lat: 49.216806, lng: 18.299464 },
    { lat: 49.216886, lng: 18.29984 },
    { lat: 49.216886, lng: 18.299845 },
    { lat: 49.216888, lng: 18.299942 },
    { lat: 49.216891, lng: 18.300087 },
    { lat: 49.216939, lng: 18.300169 },
    { lat: 49.217051, lng: 18.300365 },
    { lat: 49.217091, lng: 18.300646 },
    { lat: 49.217039, lng: 18.300761 },
    { lat: 49.217059, lng: 18.300892 },
    { lat: 49.217094, lng: 18.3011 },
    { lat: 49.217111, lng: 18.3012 },
    { lat: 49.217107, lng: 18.301504 },
    { lat: 49.217068, lng: 18.301545 },
    { lat: 49.217005, lng: 18.301647 },
    { lat: 49.216948, lng: 18.301741 },
    { lat: 49.216895, lng: 18.301918 },
    { lat: 49.216909, lng: 18.302257 },
    { lat: 49.216911, lng: 18.3023 },
    { lat: 49.216866, lng: 18.302446 },
    { lat: 49.216788, lng: 18.302691 },
    { lat: 49.216736, lng: 18.302848 },
    { lat: 49.216665, lng: 18.303063 },
    { lat: 49.216621, lng: 18.303243 },
    { lat: 49.216621, lng: 18.303598 },
    { lat: 49.216588, lng: 18.303752 },
    { lat: 49.216489, lng: 18.304034 },
    { lat: 49.216423, lng: 18.304293 },
    { lat: 49.216417, lng: 18.304701 },
    { lat: 49.216349, lng: 18.304902 },
    { lat: 49.216148, lng: 18.305134 },
    { lat: 49.216082, lng: 18.305452 },
    { lat: 49.216149, lng: 18.305851 },
    { lat: 49.21615, lng: 18.306146 },
    { lat: 49.216078, lng: 18.306501 },
    { lat: 49.215976, lng: 18.306681 },
  ],
  Hatné: [
    { lat: 49.2211486, lng: 18.3865395 },
    { lat: 49.2203792, lng: 18.3852753 },
    { lat: 49.2210299, lng: 18.3839878 },
    { lat: 49.2202842, lng: 18.3828866 },
    { lat: 49.2194936, lng: 18.3821543 },
    { lat: 49.2190812, lng: 18.3822843 },
    { lat: 49.21906, lng: 18.3822887 },
    { lat: 49.2187871, lng: 18.3823941 },
    { lat: 49.2186287, lng: 18.3824282 },
    { lat: 49.2185446, lng: 18.3824376 },
    { lat: 49.2178705, lng: 18.383852 },
    { lat: 49.2188436, lng: 18.3849648 },
    { lat: 49.2189972, lng: 18.384987 },
    { lat: 49.2186764, lng: 18.385307 },
    { lat: 49.2173949, lng: 18.3847373 },
    { lat: 49.2169924, lng: 18.3845547 },
    { lat: 49.2163222, lng: 18.3843088 },
    { lat: 49.2158696, lng: 18.3835296 },
    { lat: 49.2151376, lng: 18.3822879 },
    { lat: 49.2149507, lng: 18.3819699 },
    { lat: 49.2150365, lng: 18.3817039 },
    { lat: 49.2137683, lng: 18.3808252 },
    { lat: 49.2128007, lng: 18.3801956 },
    { lat: 49.2125553, lng: 18.3808177 },
    { lat: 49.211262, lng: 18.3811129 },
    { lat: 49.2103021, lng: 18.3812796 },
    { lat: 49.2102161, lng: 18.3812934 },
    { lat: 49.2102409, lng: 18.3817203 },
    { lat: 49.2102791, lng: 18.3822694 },
    { lat: 49.2097695, lng: 18.3826194 },
    { lat: 49.2087769, lng: 18.3832947 },
    { lat: 49.2067772, lng: 18.3836682 },
    { lat: 49.2063819, lng: 18.3834356 },
    { lat: 49.2063051, lng: 18.3839825 },
    { lat: 49.2062714, lng: 18.383984 },
    { lat: 49.2054776, lng: 18.3838681 },
    { lat: 49.2052102, lng: 18.3834188 },
    { lat: 49.2043664, lng: 18.3828493 },
    { lat: 49.2037732, lng: 18.3826461 },
    { lat: 49.2033928, lng: 18.3824994 },
    { lat: 49.2030638, lng: 18.3818359 },
    { lat: 49.2019952, lng: 18.3795275 },
    { lat: 49.2016008, lng: 18.3795555 },
    { lat: 49.2010127, lng: 18.3795909 },
    { lat: 49.2002083, lng: 18.3783658 },
    { lat: 49.2002047, lng: 18.3773026 },
    { lat: 49.2002824, lng: 18.3771003 },
    { lat: 49.2003306, lng: 18.3769516 },
    { lat: 49.2003835, lng: 18.3768059 },
    { lat: 49.2005993, lng: 18.3761605 },
    { lat: 49.2006173, lng: 18.3761185 },
    { lat: 49.2002418, lng: 18.3756947 },
    { lat: 49.1999185, lng: 18.3750726 },
    { lat: 49.1991859, lng: 18.3737374 },
    { lat: 49.1991113, lng: 18.3735728 },
    { lat: 49.198841, lng: 18.3730951 },
    { lat: 49.1981815, lng: 18.3725317 },
    { lat: 49.1971462, lng: 18.3715407 },
    { lat: 49.1967897, lng: 18.3711128 },
    { lat: 49.1967003, lng: 18.3708442 },
    { lat: 49.1966109, lng: 18.3706143 },
    { lat: 49.1961956, lng: 18.3705504 },
    { lat: 49.1961417, lng: 18.3705162 },
    { lat: 49.1960822, lng: 18.3704783 },
    { lat: 49.1960354, lng: 18.3704486 },
    { lat: 49.195614, lng: 18.3703348 },
    { lat: 49.1955772, lng: 18.3703247 },
    { lat: 49.19551, lng: 18.3702716 },
    { lat: 49.1953909, lng: 18.3701782 },
    { lat: 49.1953189, lng: 18.3701215 },
    { lat: 49.1952942, lng: 18.3701019 },
    { lat: 49.1952397, lng: 18.369955 },
    { lat: 49.1952463, lng: 18.3699296 },
    { lat: 49.195231, lng: 18.3698576 },
    { lat: 49.1951116, lng: 18.3695978 },
    { lat: 49.1946874, lng: 18.3689637 },
    { lat: 49.1943709, lng: 18.3684888 },
    { lat: 49.1942109, lng: 18.3681432 },
    { lat: 49.1939026, lng: 18.3677335 },
    { lat: 49.1938168, lng: 18.3676111 },
    { lat: 49.1937364, lng: 18.3675035 },
    { lat: 49.1934739, lng: 18.3671602 },
    { lat: 49.193444, lng: 18.367155 },
    { lat: 49.193371, lng: 18.367117 },
    { lat: 49.1931637, lng: 18.3669377 },
    { lat: 49.1928252, lng: 18.3667551 },
    { lat: 49.1927345, lng: 18.3666851 },
    { lat: 49.1923548, lng: 18.3651296 },
    { lat: 49.1922265, lng: 18.3643849 },
    { lat: 49.1922124, lng: 18.3639866 },
    { lat: 49.1922179, lng: 18.3637841 },
    { lat: 49.1922844, lng: 18.3635387 },
    { lat: 49.1921999, lng: 18.3622804 },
    { lat: 49.1920547, lng: 18.3620027 },
    { lat: 49.1919359, lng: 18.361793 },
    { lat: 49.1918579, lng: 18.3617082 },
    { lat: 49.1917681, lng: 18.3616757 },
    { lat: 49.1916978, lng: 18.3615071 },
    { lat: 49.1914305, lng: 18.361123 },
    { lat: 49.1913277, lng: 18.3606526 },
    { lat: 49.1916577, lng: 18.3600151 },
    { lat: 49.1916787, lng: 18.3599652 },
    { lat: 49.1914152, lng: 18.358331 },
    { lat: 49.1909432, lng: 18.3587504 },
    { lat: 49.1899114, lng: 18.3573956 },
    { lat: 49.1897828, lng: 18.3574795 },
    { lat: 49.1896959, lng: 18.3576801 },
    { lat: 49.1896603, lng: 18.3578723 },
    { lat: 49.1896638, lng: 18.3585241 },
    { lat: 49.1896771, lng: 18.3585395 },
    { lat: 49.1899269, lng: 18.359926 },
    { lat: 49.1895825, lng: 18.3599828 },
    { lat: 49.1890976, lng: 18.3606356 },
    { lat: 49.1893147, lng: 18.3628595 },
    { lat: 49.1893333, lng: 18.3630706 },
    { lat: 49.1884779, lng: 18.363432 },
    { lat: 49.1880154, lng: 18.36538 },
    { lat: 49.1875063, lng: 18.3664309 },
    { lat: 49.1872959, lng: 18.367194 },
    { lat: 49.1873242, lng: 18.3677439 },
    { lat: 49.1873609, lng: 18.3683028 },
    { lat: 49.1873502, lng: 18.3683334 },
    { lat: 49.1873344, lng: 18.3683742 },
    { lat: 49.1873108, lng: 18.3684375 },
    { lat: 49.1868507, lng: 18.368256 },
    { lat: 49.1867763, lng: 18.3682381 },
    { lat: 49.1867321, lng: 18.3683347 },
    { lat: 49.1867201, lng: 18.3683629 },
    { lat: 49.1867041, lng: 18.3684014 },
    { lat: 49.1862674, lng: 18.369412 },
    { lat: 49.1859571, lng: 18.3691885 },
    { lat: 49.1857255, lng: 18.3689379 },
    { lat: 49.1852123, lng: 18.3682086 },
    { lat: 49.1847182, lng: 18.3676594 },
    { lat: 49.1842126, lng: 18.3669638 },
    { lat: 49.183779, lng: 18.3672604 },
    { lat: 49.1836396, lng: 18.3669259 },
    { lat: 49.1826934, lng: 18.3668296 },
    { lat: 49.1821681, lng: 18.3654127 },
    { lat: 49.182155, lng: 18.3653812 },
    { lat: 49.1819506, lng: 18.3647676 },
    { lat: 49.1819265, lng: 18.3647811 },
    { lat: 49.1815564, lng: 18.3651229 },
    { lat: 49.1806119, lng: 18.3655464 },
    { lat: 49.1804073, lng: 18.36438 },
    { lat: 49.1802127, lng: 18.3632332 },
    { lat: 49.1802789, lng: 18.3631641 },
    { lat: 49.1802974, lng: 18.3631318 },
    { lat: 49.1807575, lng: 18.3627034 },
    { lat: 49.1807733, lng: 18.362644 },
    { lat: 49.1807661, lng: 18.3626154 },
    { lat: 49.1807414, lng: 18.362618 },
    { lat: 49.1793972, lng: 18.3626056 },
    { lat: 49.1791088, lng: 18.3625315 },
    { lat: 49.1790399, lng: 18.3625296 },
    { lat: 49.1779444, lng: 18.3622648 },
    { lat: 49.1777812, lng: 18.362229 },
    { lat: 49.1776427, lng: 18.3619303 },
    { lat: 49.1775723, lng: 18.3617793 },
    { lat: 49.1769412, lng: 18.3619943 },
    { lat: 49.17598, lng: 18.361992 },
    { lat: 49.1750749, lng: 18.3619461 },
    { lat: 49.1744511, lng: 18.3618865 },
    { lat: 49.1743721, lng: 18.3618662 },
    { lat: 49.1743235, lng: 18.3619035 },
    { lat: 49.1742394, lng: 18.3619755 },
    { lat: 49.1738494, lng: 18.36212 },
    { lat: 49.1737547, lng: 18.3622033 },
    { lat: 49.1736663, lng: 18.3623802 },
    { lat: 49.1735158, lng: 18.3625753 },
    { lat: 49.1733697, lng: 18.3627261 },
    { lat: 49.1733434, lng: 18.3627194 },
    { lat: 49.1727444, lng: 18.3630647 },
    { lat: 49.1724811, lng: 18.3631599 },
    { lat: 49.1726984, lng: 18.3633937 },
    { lat: 49.1727639, lng: 18.3634163 },
    { lat: 49.1729003, lng: 18.3634145 },
    { lat: 49.1732025, lng: 18.3634735 },
    { lat: 49.173349, lng: 18.3635092 },
    { lat: 49.1739871, lng: 18.3636454 },
    { lat: 49.1740179, lng: 18.3636508 },
    { lat: 49.1744759, lng: 18.363955 },
    { lat: 49.175694, lng: 18.3649061 },
    { lat: 49.1758989, lng: 18.365121 },
    { lat: 49.1759345, lng: 18.3651542 },
    { lat: 49.1766353, lng: 18.3659092 },
    { lat: 49.176701, lng: 18.3659544 },
    { lat: 49.1767242, lng: 18.365968 },
    { lat: 49.1771774, lng: 18.3662879 },
    { lat: 49.1782258, lng: 18.3670241 },
    { lat: 49.1785994, lng: 18.3673537 },
    { lat: 49.1788382, lng: 18.3668399 },
    { lat: 49.1788588, lng: 18.3668037 },
    { lat: 49.1791264, lng: 18.3673175 },
    { lat: 49.1793746, lng: 18.3676834 },
    { lat: 49.1794953, lng: 18.3678847 },
    { lat: 49.1795184, lng: 18.3679143 },
    { lat: 49.1795391, lng: 18.3679632 },
    { lat: 49.1797498, lng: 18.3682041 },
    { lat: 49.1798705, lng: 18.3684054 },
    { lat: 49.1799866, lng: 18.3686058 },
    { lat: 49.1804513, lng: 18.3694996 },
    { lat: 49.1806707, lng: 18.3697938 },
    { lat: 49.1809146, lng: 18.3701452 },
    { lat: 49.1813484, lng: 18.3706025 },
    { lat: 49.1815468, lng: 18.3707224 },
    { lat: 49.1816695, lng: 18.3708183 },
    { lat: 49.1818888, lng: 18.3709884 },
    { lat: 49.1820456, lng: 18.3711522 },
    { lat: 49.1821521, lng: 18.3712825 },
    { lat: 49.182208, lng: 18.3713337 },
    { lat: 49.1822729, lng: 18.3714068 },
    { lat: 49.1824613, lng: 18.3715489 },
    { lat: 49.1825804, lng: 18.3716828 },
    { lat: 49.1826969, lng: 18.3718501 },
    { lat: 49.1827871, lng: 18.3720331 },
    { lat: 49.1828493, lng: 18.3721759 },
    { lat: 49.182856, lng: 18.372197 },
    { lat: 49.1829333, lng: 18.3724319 },
    { lat: 49.1830515, lng: 18.3728749 },
    { lat: 49.1830901, lng: 18.3731022 },
    { lat: 49.1831337, lng: 18.3732785 },
    { lat: 49.1831636, lng: 18.3734515 },
    { lat: 49.1832143, lng: 18.3737969 },
    { lat: 49.1832562, lng: 18.3741252 },
    { lat: 49.1832765, lng: 18.3744491 },
    { lat: 49.1833015, lng: 18.3746782 },
    { lat: 49.1833292, lng: 18.3747896 },
    { lat: 49.1833669, lng: 18.3748996 },
    { lat: 49.1834876, lng: 18.3752059 },
    { lat: 49.1835628, lng: 18.3754212 },
    { lat: 49.1835842, lng: 18.3755641 },
    { lat: 49.1836046, lng: 18.3757082 },
    { lat: 49.1836207, lng: 18.3758564 },
    { lat: 49.1836504, lng: 18.3760031 },
    { lat: 49.1835163, lng: 18.3763521 },
    { lat: 49.1835885, lng: 18.3766995 },
    { lat: 49.183621, lng: 18.3769585 },
    { lat: 49.1836251, lng: 18.3770334 },
    { lat: 49.1836765, lng: 18.3774126 },
    { lat: 49.1837715, lng: 18.3781493 },
    { lat: 49.1838646, lng: 18.3790744 },
    { lat: 49.1839101, lng: 18.3794286 },
    { lat: 49.1839199, lng: 18.3797892 },
    { lat: 49.1838978, lng: 18.3799594 },
    { lat: 49.1838644, lng: 18.3801499 },
    { lat: 49.1838364, lng: 18.3803789 },
    { lat: 49.1838087, lng: 18.3807124 },
    { lat: 49.1838086, lng: 18.3809144 },
    { lat: 49.1838241, lng: 18.3809903 },
    { lat: 49.1838869, lng: 18.3810658 },
    { lat: 49.1841594, lng: 18.3813823 },
    { lat: 49.1842051, lng: 18.3813923 },
    { lat: 49.1842723, lng: 18.3813825 },
    { lat: 49.1842583, lng: 18.3814168 },
    { lat: 49.1840497, lng: 18.3816429 },
    { lat: 49.1839962, lng: 18.3817562 },
    { lat: 49.1839223, lng: 18.3819085 },
    { lat: 49.1838815, lng: 18.3821888 },
    { lat: 49.1838098, lng: 18.3823405 },
    { lat: 49.1836832, lng: 18.3826409 },
    { lat: 49.1834766, lng: 18.3828832 },
    { lat: 49.1835206, lng: 18.3831274 },
    { lat: 49.183532, lng: 18.38317 },
    { lat: 49.1835334, lng: 18.3831773 },
    { lat: 49.1835657, lng: 18.3832489 },
    { lat: 49.1836142, lng: 18.383353 },
    { lat: 49.1836891, lng: 18.3835011 },
    { lat: 49.1836442, lng: 18.3836686 },
    { lat: 49.1836645, lng: 18.38371 },
    { lat: 49.1836671, lng: 18.3837151 },
    { lat: 49.1838332, lng: 18.3839252 },
    { lat: 49.1841551, lng: 18.3843406 },
    { lat: 49.184183, lng: 18.3843741 },
    { lat: 49.1842454, lng: 18.3842808 },
    { lat: 49.1842741, lng: 18.3843289 },
    { lat: 49.1843724, lng: 18.3844951 },
    { lat: 49.1839154, lng: 18.3848608 },
    { lat: 49.1839055, lng: 18.3848221 },
    { lat: 49.1837514, lng: 18.3849317 },
    { lat: 49.1836954, lng: 18.3850031 },
    { lat: 49.1835582, lng: 18.3850699 },
    { lat: 49.1839276, lng: 18.3855475 },
    { lat: 49.1840734, lng: 18.3857373 },
    { lat: 49.1842801, lng: 18.3860064 },
    { lat: 49.1843059, lng: 18.3860451 },
    { lat: 49.1843511, lng: 18.3861072 },
    { lat: 49.1843774, lng: 18.3861352 },
    { lat: 49.1845178, lng: 18.3858777 },
    { lat: 49.184519, lng: 18.3858791 },
    { lat: 49.1849178, lng: 18.3863371 },
    { lat: 49.1850912, lng: 18.3865562 },
    { lat: 49.1853516, lng: 18.3866761 },
    { lat: 49.1855698, lng: 18.3866635 },
    { lat: 49.1858111, lng: 18.386642 },
    { lat: 49.1860357, lng: 18.3866655 },
    { lat: 49.186207, lng: 18.3867033 },
    { lat: 49.1864556, lng: 18.3867723 },
    { lat: 49.1864936, lng: 18.386764 },
    { lat: 49.187015, lng: 18.3868318 },
    { lat: 49.1877728, lng: 18.3868857 },
    { lat: 49.1883177, lng: 18.3869076 },
    { lat: 49.1883979, lng: 18.386931 },
    { lat: 49.1892941, lng: 18.3876126 },
    { lat: 49.1893286, lng: 18.3876242 },
    { lat: 49.1909012, lng: 18.3879938 },
    { lat: 49.1909591, lng: 18.3877755 },
    { lat: 49.191347, lng: 18.3883638 },
    { lat: 49.1915783, lng: 18.3880981 },
    { lat: 49.1919008, lng: 18.3886892 },
    { lat: 49.1923491, lng: 18.3881873 },
    { lat: 49.1924294, lng: 18.3881086 },
    { lat: 49.1925589, lng: 18.3882679 },
    { lat: 49.192794, lng: 18.3885637 },
    { lat: 49.1929303, lng: 18.3888036 },
    { lat: 49.1930387, lng: 18.3889689 },
    { lat: 49.1931602, lng: 18.3891664 },
    { lat: 49.1937221, lng: 18.3899835 },
    { lat: 49.1938033, lng: 18.3900675 },
    { lat: 49.1939312, lng: 18.3900932 },
    { lat: 49.1939746, lng: 18.3900619 },
    { lat: 49.1941358, lng: 18.3900824 },
    { lat: 49.1942544, lng: 18.3901025 },
    { lat: 49.194363, lng: 18.3901904 },
    { lat: 49.1945435, lng: 18.3904366 },
    { lat: 49.1948652, lng: 18.3906835 },
    { lat: 49.1952736, lng: 18.3908868 },
    { lat: 49.1956255, lng: 18.3909921 },
    { lat: 49.1958976, lng: 18.3910141 },
    { lat: 49.1961956, lng: 18.3911027 },
    { lat: 49.1962704, lng: 18.3911475 },
    { lat: 49.1962434, lng: 18.3913937 },
    { lat: 49.1962188, lng: 18.3915015 },
    { lat: 49.1961769, lng: 18.391658 },
    { lat: 49.1962225, lng: 18.3918325 },
    { lat: 49.1963458, lng: 18.3919375 },
    { lat: 49.196403, lng: 18.3919348 },
    { lat: 49.1965618, lng: 18.3919717 },
    { lat: 49.196596, lng: 18.3919745 },
    { lat: 49.1967553, lng: 18.3922483 },
    { lat: 49.1969137, lng: 18.3928252 },
    { lat: 49.1971004, lng: 18.3933253 },
    { lat: 49.1971235, lng: 18.3933357 },
    { lat: 49.1973035, lng: 18.3935096 },
    { lat: 49.1973555, lng: 18.3938169 },
    { lat: 49.1973815, lng: 18.3941674 },
    { lat: 49.1975745, lng: 18.3945769 },
    { lat: 49.1977723, lng: 18.394726 },
    { lat: 49.1980083, lng: 18.3947306 },
    { lat: 49.1981169, lng: 18.394776 },
    { lat: 49.1983095, lng: 18.3948347 },
    { lat: 49.1984104, lng: 18.3947193 },
    { lat: 49.1989279, lng: 18.3941639 },
    { lat: 49.1991568, lng: 18.3939162 },
    { lat: 49.2001892, lng: 18.3934915 },
    { lat: 49.2002956, lng: 18.3934576 },
    { lat: 49.2003444, lng: 18.393443 },
    { lat: 49.2006514, lng: 18.3946094 },
    { lat: 49.2006606, lng: 18.3946551 },
    { lat: 49.2002243, lng: 18.3953323 },
    { lat: 49.2000357, lng: 18.3956323 },
    { lat: 49.2007395, lng: 18.3966737 },
    { lat: 49.2005665, lng: 18.3969748 },
    { lat: 49.200538, lng: 18.3970268 },
    { lat: 49.2003383, lng: 18.3972926 },
    { lat: 49.1999847, lng: 18.3965926 },
    { lat: 49.200019, lng: 18.3965379 },
    { lat: 49.199901, lng: 18.3963392 },
    { lat: 49.1997968, lng: 18.3962287 },
    { lat: 49.1995386, lng: 18.39607 },
    { lat: 49.1994586, lng: 18.3959688 },
    { lat: 49.1993811, lng: 18.3957898 },
    { lat: 49.1993469, lng: 18.3957435 },
    { lat: 49.1993039, lng: 18.395724 },
    { lat: 49.199121, lng: 18.3957204 },
    { lat: 49.1987569, lng: 18.3955384 },
    { lat: 49.198672, lng: 18.3956155 },
    { lat: 49.1986569, lng: 18.3956503 },
    { lat: 49.1986033, lng: 18.3957618 },
    { lat: 49.1985583, lng: 18.3958854 },
    { lat: 49.198484, lng: 18.39599 },
    { lat: 49.1984546, lng: 18.3961121 },
    { lat: 49.1983645, lng: 18.3982764 },
    { lat: 49.1990077, lng: 18.3977305 },
    { lat: 49.1991042, lng: 18.3976416 },
    { lat: 49.1992254, lng: 18.3975463 },
    { lat: 49.1992487, lng: 18.3976039 },
    { lat: 49.199595, lng: 18.3985401 },
    { lat: 49.1998234, lng: 18.3991031 },
    { lat: 49.2000479, lng: 18.3996099 },
    { lat: 49.2003676, lng: 18.4001733 },
    { lat: 49.2008553, lng: 18.4003245 },
    { lat: 49.2009302, lng: 18.3993523 },
    { lat: 49.2009334, lng: 18.3993075 },
    { lat: 49.2009707, lng: 18.3988565 },
    { lat: 49.2012764, lng: 18.3987551 },
    { lat: 49.2012475, lng: 18.3985794 },
    { lat: 49.2012374, lng: 18.3985201 },
    { lat: 49.2014073, lng: 18.398348 },
    { lat: 49.2014814, lng: 18.3983635 },
    { lat: 49.2016239, lng: 18.3985926 },
    { lat: 49.2016263, lng: 18.3985977 },
    { lat: 49.2017504, lng: 18.3985774 },
    { lat: 49.2024597, lng: 18.3989391 },
    { lat: 49.2025709, lng: 18.3985047 },
    { lat: 49.2025904, lng: 18.398447 },
    { lat: 49.2026333, lng: 18.3982639 },
    { lat: 49.2033362, lng: 18.3976556 },
    { lat: 49.2037944, lng: 18.3979646 },
    { lat: 49.2038176, lng: 18.3979739 },
    { lat: 49.2044026, lng: 18.3983974 },
    { lat: 49.2039903, lng: 18.3988537 },
    { lat: 49.2039628, lng: 18.3989292 },
    { lat: 49.2038749, lng: 18.3990708 },
    { lat: 49.2037781, lng: 18.3992024 },
    { lat: 49.2042615, lng: 18.3994153 },
    { lat: 49.2049472, lng: 18.3998445 },
    { lat: 49.2056746, lng: 18.400041 },
    { lat: 49.2056247, lng: 18.3987485 },
    { lat: 49.2052982, lng: 18.3984561 },
    { lat: 49.2056171, lng: 18.3974294 },
    { lat: 49.2057397, lng: 18.3970563 },
    { lat: 49.2059982, lng: 18.3971605 },
    { lat: 49.2060253, lng: 18.3970787 },
    { lat: 49.2061245, lng: 18.3966875 },
    { lat: 49.2062791, lng: 18.3967735 },
    { lat: 49.2062725, lng: 18.3968763 },
    { lat: 49.2062461, lng: 18.3971932 },
    { lat: 49.2065775, lng: 18.3977415 },
    { lat: 49.2067456, lng: 18.397969 },
    { lat: 49.2068091, lng: 18.3979938 },
    { lat: 49.207582, lng: 18.3986138 },
    { lat: 49.2079039, lng: 18.3990053 },
    { lat: 49.2080707, lng: 18.3989016 },
    { lat: 49.2083982, lng: 18.3988643 },
    { lat: 49.2087307, lng: 18.3983105 },
    { lat: 49.2088889, lng: 18.3980733 },
    { lat: 49.2089318, lng: 18.3976817 },
    { lat: 49.2094529, lng: 18.3969471 },
    { lat: 49.2097172, lng: 18.3969136 },
    { lat: 49.2102964, lng: 18.3963929 },
    { lat: 49.2104961, lng: 18.3964948 },
    { lat: 49.2107462, lng: 18.3965283 },
    { lat: 49.2108816, lng: 18.3967555 },
    { lat: 49.2126205, lng: 18.3968687 },
    { lat: 49.2130918, lng: 18.3969437 },
    { lat: 49.2131488, lng: 18.3960383 },
    { lat: 49.2133994, lng: 18.3959191 },
    { lat: 49.2135383, lng: 18.3952871 },
    { lat: 49.2138603, lng: 18.3945998 },
    { lat: 49.2142017, lng: 18.3935737 },
    { lat: 49.2140436, lng: 18.3920183 },
    { lat: 49.2140342, lng: 18.3919297 },
    { lat: 49.2139915, lng: 18.3913405 },
    { lat: 49.2138342, lng: 18.3901715 },
    { lat: 49.2140529, lng: 18.3899238 },
    { lat: 49.2141241, lng: 18.3896171 },
    { lat: 49.2142726, lng: 18.3894255 },
    { lat: 49.2149883, lng: 18.3893569 },
    { lat: 49.2155636, lng: 18.3892523 },
    { lat: 49.2160918, lng: 18.3890545 },
    { lat: 49.2167986, lng: 18.388392 },
    { lat: 49.2168299, lng: 18.3883689 },
    { lat: 49.2173755, lng: 18.3883393 },
    { lat: 49.217911, lng: 18.3884766 },
    { lat: 49.2185686, lng: 18.3878651 },
    { lat: 49.21868, lng: 18.3877768 },
    { lat: 49.2193385, lng: 18.3876086 },
    { lat: 49.22051, lng: 18.3872544 },
    { lat: 49.2211486, lng: 18.3865395 },
  ],
  HornáMariková: [
    { lat: 49.2525412, lng: 18.3498379 },
    { lat: 49.2524026, lng: 18.3493518 },
    { lat: 49.2523254, lng: 18.3490774 },
    { lat: 49.2520185, lng: 18.3483283 },
    { lat: 49.2518153, lng: 18.3477172 },
    { lat: 49.2516432, lng: 18.3471402 },
    { lat: 49.2517968, lng: 18.3458846 },
    { lat: 49.2517325, lng: 18.3452942 },
    { lat: 49.2518676, lng: 18.3451262 },
    { lat: 49.2534836, lng: 18.3431086 },
    { lat: 49.2536787, lng: 18.3430303 },
    { lat: 49.2539948, lng: 18.3426265 },
    { lat: 49.2545643, lng: 18.3422418 },
    { lat: 49.2547085, lng: 18.3419948 },
    { lat: 49.2550254, lng: 18.3414625 },
    { lat: 49.2555865, lng: 18.3410646 },
    { lat: 49.2565733, lng: 18.3409602 },
    { lat: 49.2570311, lng: 18.3407942 },
    { lat: 49.25752, lng: 18.3405782 },
    { lat: 49.2592503, lng: 18.3400876 },
    { lat: 49.2598396, lng: 18.340016 },
    { lat: 49.260366, lng: 18.3398443 },
    { lat: 49.2619052, lng: 18.3400755 },
    { lat: 49.2626062, lng: 18.3398595 },
    { lat: 49.2629994, lng: 18.3397123 },
    { lat: 49.2637289, lng: 18.3393231 },
    { lat: 49.2646591, lng: 18.3388474 },
    { lat: 49.2653039, lng: 18.3388749 },
    { lat: 49.2658575, lng: 18.3386275 },
    { lat: 49.2659613, lng: 18.3374363 },
    { lat: 49.2668146, lng: 18.3362673 },
    { lat: 49.2672488, lng: 18.3346879 },
    { lat: 49.268228, lng: 18.3333097 },
    { lat: 49.2682631, lng: 18.3322588 },
    { lat: 49.2685352, lng: 18.3319799 },
    { lat: 49.2693452, lng: 18.3311509 },
    { lat: 49.2703573, lng: 18.3301395 },
    { lat: 49.2714084, lng: 18.3292844 },
    { lat: 49.2720138, lng: 18.3293212 },
    { lat: 49.2733082, lng: 18.3293875 },
    { lat: 49.2740839, lng: 18.3285296 },
    { lat: 49.2746107, lng: 18.3281456 },
    { lat: 49.2752403, lng: 18.3272781 },
    { lat: 49.2760192, lng: 18.3266203 },
    { lat: 49.2764315, lng: 18.3264462 },
    { lat: 49.2770149, lng: 18.325721 },
    { lat: 49.2786389, lng: 18.3243192 },
    { lat: 49.2788876, lng: 18.3242671 },
    { lat: 49.2793918, lng: 18.3245683 },
    { lat: 49.2800571, lng: 18.325107 },
    { lat: 49.2807049, lng: 18.3257402 },
    { lat: 49.2810754, lng: 18.3260723 },
    { lat: 49.2818574, lng: 18.326501 },
    { lat: 49.2823314, lng: 18.3274382 },
    { lat: 49.2824858, lng: 18.3274143 },
    { lat: 49.2827555, lng: 18.3273118 },
    { lat: 49.2842337, lng: 18.3272827 },
    { lat: 49.2850275, lng: 18.3270698 },
    { lat: 49.2860165, lng: 18.3263345 },
    { lat: 49.2868273, lng: 18.325584 },
    { lat: 49.2869421, lng: 18.3251721 },
    { lat: 49.2875601, lng: 18.3246636 },
    { lat: 49.2880485, lng: 18.3240758 },
    { lat: 49.2885218, lng: 18.3235416 },
    { lat: 49.2888012, lng: 18.323089 },
    { lat: 49.2890253, lng: 18.3225164 },
    { lat: 49.2892285, lng: 18.3197263 },
    { lat: 49.2894918, lng: 18.3187473 },
    { lat: 49.2895027, lng: 18.3186811 },
    { lat: 49.2896101, lng: 18.3180755 },
    { lat: 49.289919, lng: 18.3168422 },
    { lat: 49.2902278, lng: 18.3155822 },
    { lat: 49.290363, lng: 18.3153372 },
    { lat: 49.2909465, lng: 18.3137886 },
    { lat: 49.2913106, lng: 18.3125505 },
    { lat: 49.2914097, lng: 18.3113966 },
    { lat: 49.2915147, lng: 18.3099838 },
    { lat: 49.2926142, lng: 18.3096116 },
    { lat: 49.2937257, lng: 18.3093151 },
    { lat: 49.2937601, lng: 18.3093045 },
    { lat: 49.2943692, lng: 18.3091206 },
    { lat: 49.2944068, lng: 18.3091092 },
    { lat: 49.2944424, lng: 18.3090985 },
    { lat: 49.2948569, lng: 18.309168 },
    { lat: 49.2959534, lng: 18.309431 },
    { lat: 49.2972979, lng: 18.3086383 },
    { lat: 49.2980892, lng: 18.3078029 },
    { lat: 49.2985993, lng: 18.3072399 },
    { lat: 49.2994577, lng: 18.306492 },
    { lat: 49.3001066, lng: 18.3058275 },
    { lat: 49.3007992, lng: 18.3052652 },
    { lat: 49.3016136, lng: 18.3047577 },
    { lat: 49.3032481, lng: 18.3039676 },
    { lat: 49.3047692, lng: 18.3035901 },
    { lat: 49.3056697, lng: 18.3031941 },
    { lat: 49.3057116, lng: 18.3034388 },
    { lat: 49.3057785, lng: 18.3038243 },
    { lat: 49.3059205, lng: 18.3046518 },
    { lat: 49.3061108, lng: 18.3058551 },
    { lat: 49.3062344, lng: 18.3073602 },
    { lat: 49.306649, lng: 18.3092295 },
    { lat: 49.3068824, lng: 18.3102748 },
    { lat: 49.307836, lng: 18.3117736 },
    { lat: 49.3086797, lng: 18.3131054 },
    { lat: 49.3089501, lng: 18.313808 },
    { lat: 49.3094179, lng: 18.3145271 },
    { lat: 49.3097663, lng: 18.3148869 },
    { lat: 49.3099925, lng: 18.3159697 },
    { lat: 49.3102466, lng: 18.3169859 },
    { lat: 49.3105471, lng: 18.3183882 },
    { lat: 49.3109438, lng: 18.3200169 },
    { lat: 49.3112477, lng: 18.3208743 },
    { lat: 49.3112883, lng: 18.3210144 },
    { lat: 49.3114419, lng: 18.3215631 },
    { lat: 49.3119934, lng: 18.3230397 },
    { lat: 49.3123576, lng: 18.3234828 },
    { lat: 49.3123759, lng: 18.3235047 },
    { lat: 49.3129154, lng: 18.3242399 },
    { lat: 49.3131145, lng: 18.3245932 },
    { lat: 49.313305, lng: 18.325089 },
    { lat: 49.314447, lng: 18.32337 },
    { lat: 49.314536, lng: 18.32317 },
    { lat: 49.315022, lng: 18.322675 },
    { lat: 49.315109, lng: 18.322605 },
    { lat: 49.315049, lng: 18.322436 },
    { lat: 49.315004, lng: 18.322302 },
    { lat: 49.314857, lng: 18.32186 },
    { lat: 49.314698, lng: 18.321399 },
    { lat: 49.314416, lng: 18.320609 },
    { lat: 49.314136, lng: 18.319778 },
    { lat: 49.314017, lng: 18.319448 },
    { lat: 49.313848, lng: 18.318944 },
    { lat: 49.313622, lng: 18.318268 },
    { lat: 49.31336, lng: 18.317488 },
    { lat: 49.313095, lng: 18.316716 },
    { lat: 49.313022, lng: 18.31641 },
    { lat: 49.312958, lng: 18.31607 },
    { lat: 49.31274, lng: 18.315141 },
    { lat: 49.312541, lng: 18.314176 },
    { lat: 49.312329, lng: 18.313098 },
    { lat: 49.312145, lng: 18.312139 },
    { lat: 49.311974, lng: 18.311254 },
    { lat: 49.311873, lng: 18.31072 },
    { lat: 49.311855, lng: 18.31063 },
    { lat: 49.311772, lng: 18.31019 },
    { lat: 49.311569, lng: 18.309095 },
    { lat: 49.311527, lng: 18.30886 },
    { lat: 49.311463, lng: 18.308487 },
    { lat: 49.311405, lng: 18.308178 },
    { lat: 49.311361, lng: 18.307966 },
    { lat: 49.311216, lng: 18.30727 },
    { lat: 49.311017, lng: 18.306316 },
    { lat: 49.310963, lng: 18.30607 },
    { lat: 49.310945, lng: 18.305988 },
    { lat: 49.310908, lng: 18.30582 },
    { lat: 49.310866, lng: 18.305631 },
    { lat: 49.31074, lng: 18.304933 },
    { lat: 49.310621, lng: 18.304253 },
    { lat: 49.310473, lng: 18.30348 },
    { lat: 49.310241, lng: 18.302273 },
    { lat: 49.31005, lng: 18.301237 },
    { lat: 49.310022, lng: 18.301088 },
    { lat: 49.309431, lng: 18.301366 },
    { lat: 49.309283, lng: 18.301436 },
    { lat: 49.308879, lng: 18.301694 },
    { lat: 49.308527, lng: 18.301881 },
    { lat: 49.308322, lng: 18.301935 },
    { lat: 49.308141, lng: 18.301981 },
    { lat: 49.307816, lng: 18.30209 },
    { lat: 49.307528, lng: 18.302261 },
    { lat: 49.306874, lng: 18.30266 },
    { lat: 49.306348, lng: 18.302927 },
    { lat: 49.305925, lng: 18.303135 },
    { lat: 49.305792, lng: 18.302585 },
    { lat: 49.305455, lng: 18.301236 },
    { lat: 49.30538, lng: 18.300662 },
    { lat: 49.305157, lng: 18.299767 },
    { lat: 49.304665, lng: 18.298379 },
    { lat: 49.304392, lng: 18.296965 },
    { lat: 49.304347, lng: 18.296325 },
    { lat: 49.304339, lng: 18.296218 },
    { lat: 49.3042721, lng: 18.2952631 },
    { lat: 49.3042842, lng: 18.2951336 },
    { lat: 49.3043166, lng: 18.2949017 },
    { lat: 49.3043706, lng: 18.2944751 },
    { lat: 49.304285, lng: 18.293447 },
    { lat: 49.304198, lng: 18.292472 },
    { lat: 49.30412, lng: 18.292124 },
    { lat: 49.304114, lng: 18.292093 },
    { lat: 49.304013, lng: 18.29164 },
    { lat: 49.304078, lng: 18.290473 },
    { lat: 49.304131, lng: 18.289534 },
    { lat: 49.304139, lng: 18.289471 },
    { lat: 49.304553, lng: 18.288973 },
    { lat: 49.304497, lng: 18.288565 },
    { lat: 49.304439, lng: 18.288142 },
    { lat: 49.303811, lng: 18.287696 },
    { lat: 49.303667, lng: 18.287793 },
    { lat: 49.303305, lng: 18.28661 },
    { lat: 49.303146, lng: 18.285661 },
    { lat: 49.3031232, lng: 18.2844184 },
    { lat: 49.303119, lng: 18.284192 },
    { lat: 49.303318, lng: 18.283358 },
    { lat: 49.303459, lng: 18.282784 },
    { lat: 49.303596, lng: 18.282232 },
    { lat: 49.303763, lng: 18.28156 },
    { lat: 49.303699, lng: 18.280665 },
    { lat: 49.303635, lng: 18.279741 },
    { lat: 49.303481, lng: 18.279226 },
    { lat: 49.303363, lng: 18.278834 },
    { lat: 49.303321, lng: 18.27855 },
    { lat: 49.303259, lng: 18.278144 },
    { lat: 49.303206, lng: 18.278031 },
    { lat: 49.303189, lng: 18.277993 },
    { lat: 49.302809, lng: 18.277172 },
    { lat: 49.302498, lng: 18.276496 },
    { lat: 49.302374, lng: 18.27623 },
    { lat: 49.302046, lng: 18.275512 },
    { lat: 49.301748, lng: 18.274864 },
    { lat: 49.301979, lng: 18.273737 },
    { lat: 49.302129, lng: 18.27257 },
    { lat: 49.302084, lng: 18.271897 },
    { lat: 49.301687, lng: 18.271137 },
    { lat: 49.301552, lng: 18.27086 },
    { lat: 49.30147, lng: 18.27069 },
    { lat: 49.300779, lng: 18.269596 },
    { lat: 49.300195, lng: 18.26868 },
    { lat: 49.299738, lng: 18.267684 },
    { lat: 49.299473, lng: 18.266778 },
    { lat: 49.299322, lng: 18.266259 },
    { lat: 49.298964, lng: 18.265605 },
    { lat: 49.298687, lng: 18.265101 },
    { lat: 49.2985, lng: 18.264477 },
    { lat: 49.298271, lng: 18.263762 },
    { lat: 49.298281, lng: 18.263625 },
    { lat: 49.298286, lng: 18.263559 },
    { lat: 49.298354, lng: 18.262584 },
    { lat: 49.298445, lng: 18.262218 },
    { lat: 49.298627, lng: 18.261496 },
    { lat: 49.29845, lng: 18.260587 },
    { lat: 49.298346, lng: 18.259878 },
    { lat: 49.298284, lng: 18.259452 },
    { lat: 49.298021, lng: 18.258955 },
    { lat: 49.297686, lng: 18.258324 },
    { lat: 49.297379, lng: 18.257714 },
    { lat: 49.297115, lng: 18.257188 },
    { lat: 49.297098, lng: 18.257123 },
    { lat: 49.296823, lng: 18.256073 },
    { lat: 49.296725, lng: 18.255402 },
    { lat: 49.296694, lng: 18.255193 },
    { lat: 49.296624, lng: 18.254713 },
    { lat: 49.296462, lng: 18.253381 },
    { lat: 49.296466, lng: 18.25275 },
    { lat: 49.2963513, lng: 18.2520431 },
    { lat: 49.296206, lng: 18.251047 },
    { lat: 49.296078, lng: 18.250644 },
    { lat: 49.296008, lng: 18.250432 },
    { lat: 49.295877, lng: 18.250122 },
    { lat: 49.295488, lng: 18.249387 },
    { lat: 49.295284, lng: 18.249255 },
    { lat: 49.29508, lng: 18.248723 },
    { lat: 49.294834, lng: 18.24841 },
    { lat: 49.29474, lng: 18.248114 },
    { lat: 49.2945347, lng: 18.248195 },
    { lat: 49.294393, lng: 18.248251 },
    { lat: 49.293989, lng: 18.248409 },
    { lat: 49.293944, lng: 18.248426 },
    { lat: 49.293465, lng: 18.248523 },
    { lat: 49.293022, lng: 18.248725 },
    { lat: 49.292872, lng: 18.248793 },
    { lat: 49.292305, lng: 18.248819 },
    { lat: 49.29182, lng: 18.248696 },
    { lat: 49.29164, lng: 18.248518 },
    { lat: 49.29128, lng: 18.248577 },
    { lat: 49.290452, lng: 18.248512 },
    { lat: 49.290081, lng: 18.248337 },
    { lat: 49.289533, lng: 18.248382 },
    { lat: 49.287416, lng: 18.248148 },
    { lat: 49.287349, lng: 18.248141 },
    { lat: 49.286055, lng: 18.248399 },
    { lat: 49.285813, lng: 18.248271 },
    { lat: 49.285759, lng: 18.248181 },
    { lat: 49.285267, lng: 18.247342 },
    { lat: 49.283921, lng: 18.247621 },
    { lat: 49.283651, lng: 18.24754 },
    { lat: 49.283596, lng: 18.247523 },
    { lat: 49.283473, lng: 18.247487 },
    { lat: 49.283101, lng: 18.247536 },
    { lat: 49.282588, lng: 18.247402 },
    { lat: 49.281735, lng: 18.247679 },
    { lat: 49.281256, lng: 18.248049 },
    { lat: 49.28079, lng: 18.248203 },
    { lat: 49.280758, lng: 18.248213 },
    { lat: 49.279941, lng: 18.248483 },
    { lat: 49.27947, lng: 18.247888 },
    { lat: 49.279174, lng: 18.247669 },
    { lat: 49.278981, lng: 18.24764 },
    { lat: 49.278752, lng: 18.247744 },
    { lat: 49.278474, lng: 18.247867 },
    { lat: 49.278229, lng: 18.24831 },
    { lat: 49.277528, lng: 18.248565 },
    { lat: 49.2768, lng: 18.248679 },
    { lat: 49.275617, lng: 18.249271 },
    { lat: 49.2755313, lng: 18.2492133 },
    { lat: 49.275127, lng: 18.248941 },
    { lat: 49.274641, lng: 18.248121 },
    { lat: 49.273555, lng: 18.248391 },
    { lat: 49.273079, lng: 18.248711 },
    { lat: 49.272997, lng: 18.248862 },
    { lat: 49.272725, lng: 18.248861 },
    { lat: 49.272508, lng: 18.24911 },
    { lat: 49.271109, lng: 18.249854 },
    { lat: 49.2704873, lng: 18.2504078 },
    { lat: 49.26937, lng: 18.251403 },
    { lat: 49.268769, lng: 18.251938 },
    { lat: 49.268494, lng: 18.252183 },
    { lat: 49.267982, lng: 18.253163 },
    { lat: 49.268105, lng: 18.253733 },
    { lat: 49.267852, lng: 18.25437 },
    { lat: 49.267796, lng: 18.254823 },
    { lat: 49.267672, lng: 18.255561 },
    { lat: 49.267642, lng: 18.255737 },
    { lat: 49.267514, lng: 18.256082 },
    { lat: 49.266435, lng: 18.257081 },
    { lat: 49.265805, lng: 18.258194 },
    { lat: 49.265647, lng: 18.258417 },
    { lat: 49.265544, lng: 18.258564 },
    { lat: 49.264674, lng: 18.259789 },
    { lat: 49.264663, lng: 18.259802 },
    { lat: 49.264268, lng: 18.260256 },
    { lat: 49.263994, lng: 18.26056 },
    { lat: 49.263891, lng: 18.260738 },
    { lat: 49.263734, lng: 18.261006 },
    { lat: 49.263623, lng: 18.261197 },
    { lat: 49.263565, lng: 18.262268 },
    { lat: 49.263309, lng: 18.262413 },
    { lat: 49.263193, lng: 18.262479 },
    { lat: 49.263091, lng: 18.262537 },
    { lat: 49.263, lng: 18.26255 },
    { lat: 49.262903, lng: 18.262565 },
    { lat: 49.262731, lng: 18.26259 },
    { lat: 49.262541, lng: 18.262619 },
    { lat: 49.262408, lng: 18.262639 },
    { lat: 49.262189, lng: 18.262637 },
    { lat: 49.261806, lng: 18.26251 },
    { lat: 49.26155, lng: 18.262426 },
    { lat: 49.261515, lng: 18.262292 },
    { lat: 49.261207, lng: 18.262026 },
    { lat: 49.260608, lng: 18.26151 },
    { lat: 49.260489, lng: 18.26148 },
    { lat: 49.260156, lng: 18.261399 },
    { lat: 49.259288, lng: 18.261184 },
    { lat: 49.259111, lng: 18.261233 },
    { lat: 49.258359, lng: 18.261444 },
    { lat: 49.257301, lng: 18.26174 },
    { lat: 49.257043, lng: 18.261812 },
    { lat: 49.256888, lng: 18.261856 },
    { lat: 49.255943, lng: 18.2616022 },
    { lat: 49.254851, lng: 18.261309 },
    { lat: 49.253904, lng: 18.26114 },
    { lat: 49.253701, lng: 18.261106 },
    { lat: 49.253169, lng: 18.261171 },
    { lat: 49.252758, lng: 18.261416 },
    { lat: 49.251813, lng: 18.261981 },
    { lat: 49.251381, lng: 18.262239 },
    { lat: 49.250002, lng: 18.26296 },
    { lat: 49.249783, lng: 18.262969 },
    { lat: 49.249669, lng: 18.263064 },
    { lat: 49.249067, lng: 18.264755 },
    { lat: 49.248493, lng: 18.265249 },
    { lat: 49.247545, lng: 18.265513 },
    { lat: 49.246636, lng: 18.265766 },
    { lat: 49.246594, lng: 18.265778 },
    { lat: 49.245632, lng: 18.266046 },
    { lat: 49.24503, lng: 18.265987 },
    { lat: 49.244414, lng: 18.265927 },
    { lat: 49.244311, lng: 18.265948 },
    { lat: 49.243947, lng: 18.266023 },
    { lat: 49.2438, lng: 18.266062 },
    { lat: 49.24358, lng: 18.26612 },
    { lat: 49.243282, lng: 18.266274 },
    { lat: 49.243028, lng: 18.266406 },
    { lat: 49.241904, lng: 18.266988 },
    { lat: 49.240523, lng: 18.267703 },
    { lat: 49.240265, lng: 18.267838 },
    { lat: 49.240117, lng: 18.267896 },
    { lat: 49.238881, lng: 18.268386 },
    { lat: 49.238845, lng: 18.268393 },
    { lat: 49.238812, lng: 18.268398 },
    { lat: 49.23826, lng: 18.268497 },
    { lat: 49.237262, lng: 18.269048 },
    { lat: 49.236499, lng: 18.269253 },
    { lat: 49.233357, lng: 18.271427 },
    { lat: 49.2331356, lng: 18.275455 },
    { lat: 49.2329454, lng: 18.277331 },
    { lat: 49.2327121, lng: 18.2782782 },
    { lat: 49.2326946, lng: 18.2793697 },
    { lat: 49.2327095, lng: 18.2799969 },
    { lat: 49.2326677, lng: 18.2816219 },
    { lat: 49.2328098, lng: 18.282341 },
    { lat: 49.2329602, lng: 18.2831332 },
    { lat: 49.2325716, lng: 18.2843077 },
    { lat: 49.2324742, lng: 18.2846021 },
    { lat: 49.232421, lng: 18.2852195 },
    { lat: 49.232311, lng: 18.2862537 },
    { lat: 49.2323383, lng: 18.2873631 },
    { lat: 49.2324079, lng: 18.2887256 },
    { lat: 49.2329471, lng: 18.2908129 },
    { lat: 49.2331754, lng: 18.2920664 },
    { lat: 49.2332296, lng: 18.2927927 },
    { lat: 49.2332388, lng: 18.2930361 },
    { lat: 49.2332938, lng: 18.2946411 },
    { lat: 49.2333067, lng: 18.2950308 },
    { lat: 49.2319027, lng: 18.2959884 },
    { lat: 49.2309651, lng: 18.2968095 },
    { lat: 49.2300927, lng: 18.2974284 },
    { lat: 49.2298018, lng: 18.2981348 },
    { lat: 49.2295457, lng: 18.2985171 },
    { lat: 49.2296319, lng: 18.298945 },
    { lat: 49.2295549, lng: 18.2996839 },
    { lat: 49.2294153, lng: 18.3001116 },
    { lat: 49.2293179, lng: 18.3004065 },
    { lat: 49.2292487, lng: 18.3009649 },
    { lat: 49.2292124, lng: 18.3011224 },
    { lat: 49.2290969, lng: 18.301336 },
    { lat: 49.2287801, lng: 18.3018419 },
    { lat: 49.2287294, lng: 18.3019872 },
    { lat: 49.2286568, lng: 18.3021006 },
    { lat: 49.2283826, lng: 18.3026203 },
    { lat: 49.2281314, lng: 18.3031543 },
    { lat: 49.2279506, lng: 18.3036476 },
    { lat: 49.2278468, lng: 18.3041123 },
    { lat: 49.2278012, lng: 18.3042643 },
    { lat: 49.2276715, lng: 18.3050934 },
    { lat: 49.2276235, lng: 18.3056835 },
    { lat: 49.2275872, lng: 18.3060824 },
    { lat: 49.2274697, lng: 18.307552 },
    { lat: 49.2266825, lng: 18.3099766 },
    { lat: 49.2265995, lng: 18.3102251 },
    { lat: 49.2246758, lng: 18.3125811 },
    { lat: 49.223996, lng: 18.3148266 },
    { lat: 49.2236915, lng: 18.3165038 },
    { lat: 49.2234097, lng: 18.3170327 },
    { lat: 49.22319, lng: 18.3174412 },
    { lat: 49.2223391, lng: 18.3189911 },
    { lat: 49.221325, lng: 18.3210526 },
    { lat: 49.2198875, lng: 18.3238927 },
    { lat: 49.2197197, lng: 18.324967 },
    { lat: 49.2194389, lng: 18.3259595 },
    { lat: 49.2184429, lng: 18.3279601 },
    { lat: 49.2185264, lng: 18.3286696 },
    { lat: 49.2186558, lng: 18.329658 },
    { lat: 49.2189262, lng: 18.3313258 },
    { lat: 49.2190635, lng: 18.3323719 },
    { lat: 49.2192036, lng: 18.3332256 },
    { lat: 49.219249, lng: 18.3334136 },
    { lat: 49.21937, lng: 18.3338237 },
    { lat: 49.2194527, lng: 18.3341114 },
    { lat: 49.2195125, lng: 18.3342118 },
    { lat: 49.2196309, lng: 18.3340935 },
    { lat: 49.219856, lng: 18.3338575 },
    { lat: 49.2201951, lng: 18.3334857 },
    { lat: 49.2202312, lng: 18.3334467 },
    { lat: 49.2202714, lng: 18.333518 },
    { lat: 49.2202911, lng: 18.333549 },
    { lat: 49.2203048, lng: 18.333571 },
    { lat: 49.2203819, lng: 18.3337036 },
    { lat: 49.2203983, lng: 18.3336913 },
    { lat: 49.2204676, lng: 18.3339433 },
    { lat: 49.2204922, lng: 18.3340184 },
    { lat: 49.2205422, lng: 18.3342501 },
    { lat: 49.2207378, lng: 18.3340954 },
    { lat: 49.2210463, lng: 18.3340451 },
    { lat: 49.221309, lng: 18.3339386 },
    { lat: 49.2221064, lng: 18.3336137 },
    { lat: 49.2228794, lng: 18.3413884 },
    { lat: 49.2237748, lng: 18.3428431 },
    { lat: 49.2240522, lng: 18.3430019 },
    { lat: 49.2243496, lng: 18.3432182 },
    { lat: 49.2246277, lng: 18.3434333 },
    { lat: 49.2248276, lng: 18.3434943 },
    { lat: 49.2249302, lng: 18.3435757 },
    { lat: 49.2252702, lng: 18.3437624 },
    { lat: 49.225448, lng: 18.3438189 },
    { lat: 49.2255135, lng: 18.3438185 },
    { lat: 49.2255914, lng: 18.3438434 },
    { lat: 49.2259766, lng: 18.3439932 },
    { lat: 49.2260213, lng: 18.3440071 },
    { lat: 49.2261541, lng: 18.3440602 },
    { lat: 49.2272502, lng: 18.3451675 },
    { lat: 49.2284235, lng: 18.3482699 },
    { lat: 49.2292403, lng: 18.3497935 },
    { lat: 49.2310529, lng: 18.3513483 },
    { lat: 49.2332316, lng: 18.3505252 },
    { lat: 49.2346344, lng: 18.351347 },
    { lat: 49.2358124, lng: 18.3514719 },
    { lat: 49.2367181, lng: 18.3522811 },
    { lat: 49.2370798, lng: 18.3527193 },
    { lat: 49.2371345, lng: 18.352773 },
    { lat: 49.2380904, lng: 18.3538942 },
    { lat: 49.238097, lng: 18.3539114 },
    { lat: 49.2385704, lng: 18.3541089 },
    { lat: 49.2387771, lng: 18.3541918 },
    { lat: 49.2390912, lng: 18.3543053 },
    { lat: 49.2394096, lng: 18.3543904 },
    { lat: 49.2396834, lng: 18.3543448 },
    { lat: 49.2398594, lng: 18.3542617 },
    { lat: 49.2399748, lng: 18.3541687 },
    { lat: 49.2401964, lng: 18.3538727 },
    { lat: 49.2404596, lng: 18.3536369 },
    { lat: 49.2407715, lng: 18.3533618 },
    { lat: 49.2410579, lng: 18.3531348 },
    { lat: 49.2412992, lng: 18.35295 },
    { lat: 49.241467, lng: 18.3528214 },
    { lat: 49.2418761, lng: 18.3525319 },
    { lat: 49.243732, lng: 18.3512563 },
    { lat: 49.2440849, lng: 18.3554371 },
    { lat: 49.2441178, lng: 18.3558032 },
    { lat: 49.2442061, lng: 18.3568652 },
    { lat: 49.2442547, lng: 18.3568685 },
    { lat: 49.2447676, lng: 18.3566146 },
    { lat: 49.2453217, lng: 18.3562753 },
    { lat: 49.2454407, lng: 18.356184 },
    { lat: 49.2457144, lng: 18.3559745 },
    { lat: 49.2458783, lng: 18.355737 },
    { lat: 49.2460039, lng: 18.3557005 },
    { lat: 49.2460824, lng: 18.3556326 },
    { lat: 49.246165, lng: 18.3556349 },
    { lat: 49.2467364, lng: 18.355158 },
    { lat: 49.2479684, lng: 18.3535798 },
    { lat: 49.2487153, lng: 18.3527698 },
    { lat: 49.2494942, lng: 18.351846 },
    { lat: 49.2499827, lng: 18.3513373 },
    { lat: 49.2507088, lng: 18.3505201 },
    { lat: 49.2507536, lng: 18.3505131 },
    { lat: 49.2510055, lng: 18.3504782 },
    { lat: 49.2525412, lng: 18.3498379 },
  ],
  Klieština: [
    { lat: 49.171032, lng: 18.357541 },
    { lat: 49.170777, lng: 18.35783 },
    { lat: 49.170702, lng: 18.358073 },
    { lat: 49.170604, lng: 18.3584725 },
    { lat: 49.1705362, lng: 18.3587539 },
    { lat: 49.1704594, lng: 18.3589971 },
    { lat: 49.1704245, lng: 18.359199 },
    { lat: 49.1704109, lng: 18.3592802 },
    { lat: 49.170693, lng: 18.3590382 },
    { lat: 49.1714458, lng: 18.3607284 },
    { lat: 49.1717842, lng: 18.3616816 },
    { lat: 49.1720376, lng: 18.3622429 },
    { lat: 49.1721731, lng: 18.3626699 },
    { lat: 49.1721967, lng: 18.362689 },
    { lat: 49.1724688, lng: 18.3631407 },
    { lat: 49.1724811, lng: 18.3631599 },
    { lat: 49.1727444, lng: 18.3630647 },
    { lat: 49.1733434, lng: 18.3627194 },
    { lat: 49.1733697, lng: 18.3627261 },
    { lat: 49.1735158, lng: 18.3625753 },
    { lat: 49.1736663, lng: 18.3623802 },
    { lat: 49.1737547, lng: 18.3622033 },
    { lat: 49.1738494, lng: 18.36212 },
    { lat: 49.1742394, lng: 18.3619755 },
    { lat: 49.1743235, lng: 18.3619035 },
    { lat: 49.1743721, lng: 18.3618662 },
    { lat: 49.1744511, lng: 18.3618865 },
    { lat: 49.1750749, lng: 18.3619461 },
    { lat: 49.17598, lng: 18.361992 },
    { lat: 49.1769412, lng: 18.3619943 },
    { lat: 49.1775723, lng: 18.3617793 },
    { lat: 49.1776427, lng: 18.3619303 },
    { lat: 49.1777812, lng: 18.362229 },
    { lat: 49.1779444, lng: 18.3622648 },
    { lat: 49.1790399, lng: 18.3625296 },
    { lat: 49.1791088, lng: 18.3625315 },
    { lat: 49.1793972, lng: 18.3626056 },
    { lat: 49.1807414, lng: 18.362618 },
    { lat: 49.1807661, lng: 18.3626154 },
    { lat: 49.1807733, lng: 18.362644 },
    { lat: 49.1807575, lng: 18.3627034 },
    { lat: 49.1802974, lng: 18.3631318 },
    { lat: 49.1802789, lng: 18.3631641 },
    { lat: 49.1802127, lng: 18.3632332 },
    { lat: 49.1804073, lng: 18.36438 },
    { lat: 49.1806119, lng: 18.3655464 },
    { lat: 49.1815564, lng: 18.3651229 },
    { lat: 49.1819265, lng: 18.3647811 },
    { lat: 49.1819506, lng: 18.3647676 },
    { lat: 49.182155, lng: 18.3653812 },
    { lat: 49.1821681, lng: 18.3654127 },
    { lat: 49.1826934, lng: 18.3668296 },
    { lat: 49.1836396, lng: 18.3669259 },
    { lat: 49.183779, lng: 18.3672604 },
    { lat: 49.1842126, lng: 18.3669638 },
    { lat: 49.1847182, lng: 18.3676594 },
    { lat: 49.1852123, lng: 18.3682086 },
    { lat: 49.1857255, lng: 18.3689379 },
    { lat: 49.1859571, lng: 18.3691885 },
    { lat: 49.1862674, lng: 18.369412 },
    { lat: 49.1867041, lng: 18.3684014 },
    { lat: 49.1867201, lng: 18.3683629 },
    { lat: 49.1867321, lng: 18.3683347 },
    { lat: 49.1867763, lng: 18.3682381 },
    { lat: 49.1868507, lng: 18.368256 },
    { lat: 49.1873108, lng: 18.3684375 },
    { lat: 49.1873344, lng: 18.3683742 },
    { lat: 49.1873502, lng: 18.3683334 },
    { lat: 49.1873609, lng: 18.3683028 },
    { lat: 49.1873242, lng: 18.3677439 },
    { lat: 49.1872959, lng: 18.367194 },
    { lat: 49.1875063, lng: 18.3664309 },
    { lat: 49.1880154, lng: 18.36538 },
    { lat: 49.1884779, lng: 18.363432 },
    { lat: 49.1893333, lng: 18.3630706 },
    { lat: 49.1893147, lng: 18.3628595 },
    { lat: 49.1890976, lng: 18.3606356 },
    { lat: 49.1895825, lng: 18.3599828 },
    { lat: 49.1899269, lng: 18.359926 },
    { lat: 49.1896771, lng: 18.3585395 },
    { lat: 49.1896638, lng: 18.3585241 },
    { lat: 49.1895712, lng: 18.3585627 },
    { lat: 49.18947, lng: 18.3586283 },
    { lat: 49.1889377, lng: 18.3588952 },
    { lat: 49.188754, lng: 18.359082 },
    { lat: 49.1890107, lng: 18.3580169 },
    { lat: 49.1886919, lng: 18.357803 },
    { lat: 49.188666, lng: 18.3577835 },
    { lat: 49.1886358, lng: 18.357523 },
    { lat: 49.1885668, lng: 18.3564843 },
    { lat: 49.1885854, lng: 18.3564537 },
    { lat: 49.188793, lng: 18.3556042 },
    { lat: 49.1889821, lng: 18.3547635 },
    { lat: 49.1891306, lng: 18.3535201 },
    { lat: 49.1891345, lng: 18.3534683 },
    { lat: 49.1892706, lng: 18.3524874 },
    { lat: 49.1899804, lng: 18.3514995 },
    { lat: 49.1908871, lng: 18.3522646 },
    { lat: 49.190811, lng: 18.3525176 },
    { lat: 49.1905709, lng: 18.3530293 },
    { lat: 49.1904613, lng: 18.3533486 },
    { lat: 49.1904272, lng: 18.3535074 },
    { lat: 49.1904191, lng: 18.3536455 },
    { lat: 49.1904417, lng: 18.3538085 },
    { lat: 49.1907, lng: 18.3543952 },
    { lat: 49.1907018, lng: 18.3543468 },
    { lat: 49.1915441, lng: 18.3531207 },
    { lat: 49.1916888, lng: 18.3527814 },
    { lat: 49.1917087, lng: 18.3527366 },
    { lat: 49.1919305, lng: 18.3522195 },
    { lat: 49.1919585, lng: 18.3518517 },
    { lat: 49.1917156, lng: 18.3514405 },
    { lat: 49.1921462, lng: 18.3490433 },
    { lat: 49.1921256, lng: 18.348147 },
    { lat: 49.1921753, lng: 18.3470744 },
    { lat: 49.1922139, lng: 18.346915 },
    { lat: 49.1922229, lng: 18.3468121 },
    { lat: 49.192221, lng: 18.3466605 },
    { lat: 49.1922221, lng: 18.3466203 },
    { lat: 49.192209, lng: 18.3462188 },
    { lat: 49.1922094, lng: 18.3461069 },
    { lat: 49.1922461, lng: 18.3457952 },
    { lat: 49.1922992, lng: 18.3453689 },
    { lat: 49.1923491, lng: 18.34505 },
    { lat: 49.1923554, lng: 18.344939 },
    { lat: 49.1926673, lng: 18.3422553 },
    { lat: 49.1928455, lng: 18.3404738 },
    { lat: 49.1928451, lng: 18.3404282 },
    { lat: 49.1928653, lng: 18.340222 },
    { lat: 49.1929913, lng: 18.339852 },
    { lat: 49.1929937, lng: 18.3398361 },
    { lat: 49.1930289, lng: 18.3397381 },
    { lat: 49.1931308, lng: 18.3395826 },
    { lat: 49.1932694, lng: 18.3394768 },
    { lat: 49.1934255, lng: 18.3394066 },
    { lat: 49.193646, lng: 18.3393931 },
    { lat: 49.1938858, lng: 18.3393642 },
    { lat: 49.1946241, lng: 18.3387251 },
    { lat: 49.1949878, lng: 18.3371382 },
    { lat: 49.1952511, lng: 18.3367813 },
    { lat: 49.1953339, lng: 18.3367273 },
    { lat: 49.195633, lng: 18.3362655 },
    { lat: 49.1956555, lng: 18.3360413 },
    { lat: 49.1959319, lng: 18.3353132 },
    { lat: 49.1956942, lng: 18.3349347 },
    { lat: 49.1955683, lng: 18.3347836 },
    { lat: 49.1955031, lng: 18.3345822 },
    { lat: 49.1948253, lng: 18.3324246 },
    { lat: 49.1948538, lng: 18.3322274 },
    { lat: 49.1948541, lng: 18.3321906 },
    { lat: 49.19503, lng: 18.331018 },
    { lat: 49.195086, lng: 18.3295772 },
    { lat: 49.1951876, lng: 18.32752 },
    { lat: 49.1951873, lng: 18.3274721 },
    { lat: 49.1952943, lng: 18.3253292 },
    { lat: 49.1952894, lng: 18.3252408 },
    { lat: 49.195312, lng: 18.3247959 },
    { lat: 49.195315, lng: 18.324738 },
    { lat: 49.194381, lng: 18.324566 },
    { lat: 49.193967, lng: 18.32447 },
    { lat: 49.193393, lng: 18.323866 },
    { lat: 49.192703, lng: 18.32363 },
    { lat: 49.190968, lng: 18.323637 },
    { lat: 49.190938, lng: 18.323642 },
    { lat: 49.190522, lng: 18.324598 },
    { lat: 49.190579, lng: 18.325039 },
    { lat: 49.190502, lng: 18.325247 },
    { lat: 49.190479, lng: 18.325403 },
    { lat: 49.189192, lng: 18.325766 },
    { lat: 49.188589, lng: 18.32607 },
    { lat: 49.188051, lng: 18.32602 },
    { lat: 49.187328, lng: 18.325826 },
    { lat: 49.186921, lng: 18.325666 },
    { lat: 49.186603, lng: 18.325529 },
    { lat: 49.185841, lng: 18.325613 },
    { lat: 49.1858, lng: 18.325619 },
    { lat: 49.185067, lng: 18.325652 },
    { lat: 49.184244, lng: 18.325539 },
    { lat: 49.184133, lng: 18.325559 },
    { lat: 49.181592, lng: 18.326992 },
    { lat: 49.181557, lng: 18.327022 },
    { lat: 49.1814, lng: 18.327168 },
    { lat: 49.181397, lng: 18.32751 },
    { lat: 49.181361, lng: 18.328172 },
    { lat: 49.181505, lng: 18.32903 },
    { lat: 49.18155, lng: 18.329639 },
    { lat: 49.182233, lng: 18.330853 },
    { lat: 49.181743, lng: 18.331612 },
    { lat: 49.181475, lng: 18.332125 },
    { lat: 49.181143, lng: 18.332528 },
    { lat: 49.181126, lng: 18.332549 },
    { lat: 49.180803, lng: 18.33346 },
    { lat: 49.180121, lng: 18.335181 },
    { lat: 49.18007, lng: 18.335304 },
    { lat: 49.180067, lng: 18.33534 },
    { lat: 49.180063, lng: 18.335381 },
    { lat: 49.180045, lng: 18.33561 },
    { lat: 49.179839, lng: 18.336052 },
    { lat: 49.179586, lng: 18.33645 },
    { lat: 49.179601, lng: 18.336472 },
    { lat: 49.179434, lng: 18.336732 },
    { lat: 49.179427, lng: 18.336742 },
    { lat: 49.179424, lng: 18.336765 },
    { lat: 49.179236, lng: 18.337928 },
    { lat: 49.179029, lng: 18.338505 },
    { lat: 49.178988, lng: 18.338574 },
    { lat: 49.178347, lng: 18.339592 },
    { lat: 49.177441, lng: 18.340267 },
    { lat: 49.176919, lng: 18.340507 },
    { lat: 49.176139, lng: 18.340684 },
    { lat: 49.175903, lng: 18.341 },
    { lat: 49.175877, lng: 18.341034 },
    { lat: 49.175055, lng: 18.341649 },
    { lat: 49.174705, lng: 18.342092 },
    { lat: 49.174597, lng: 18.34235 },
    { lat: 49.173956, lng: 18.342836 },
    { lat: 49.173737, lng: 18.343094 },
    { lat: 49.173508, lng: 18.343421 },
    { lat: 49.173544, lng: 18.343998 },
    { lat: 49.173351, lng: 18.345218 },
    { lat: 49.17331, lng: 18.345759 },
    { lat: 49.173121, lng: 18.346044 },
    { lat: 49.17311, lng: 18.346088 },
    { lat: 49.173004, lng: 18.346516 },
    { lat: 49.172895, lng: 18.34725 },
    { lat: 49.172888, lng: 18.347607 },
    { lat: 49.172915, lng: 18.347835 },
    { lat: 49.172974, lng: 18.34806 },
    { lat: 49.173057, lng: 18.348323 },
    { lat: 49.173115, lng: 18.348737 },
    { lat: 49.17312, lng: 18.34891 },
    { lat: 49.173122, lng: 18.34895 },
    { lat: 49.173121, lng: 18.348959 },
    { lat: 49.173106, lng: 18.349119 },
    { lat: 49.173059, lng: 18.349402 },
    { lat: 49.172972, lng: 18.349623 },
    { lat: 49.172873, lng: 18.349808 },
    { lat: 49.172751, lng: 18.350021 },
    { lat: 49.172669, lng: 18.350247 },
    { lat: 49.172626, lng: 18.350425 },
    { lat: 49.172609, lng: 18.350496 },
    { lat: 49.172567, lng: 18.350831 },
    { lat: 49.172443, lng: 18.351579 },
    { lat: 49.172395, lng: 18.351988 },
    { lat: 49.172374, lng: 18.352189 },
    { lat: 49.172321, lng: 18.352419 },
    { lat: 49.172255, lng: 18.352633 },
    { lat: 49.172201, lng: 18.352933 },
    { lat: 49.172183, lng: 18.353194 },
    { lat: 49.172212, lng: 18.353845 },
    { lat: 49.172329, lng: 18.354798 },
    { lat: 49.172072, lng: 18.355713 },
    { lat: 49.171666, lng: 18.356802 },
    { lat: 49.171653, lng: 18.356836 },
    { lat: 49.171643, lng: 18.356864 },
    { lat: 49.171387, lng: 18.357225 },
    { lat: 49.171269, lng: 18.357324 },
    { lat: 49.171115, lng: 18.357453 },
    { lat: 49.171032, lng: 18.357541 },
  ],
  PovažskáBystrica: [
    { lat: 49.1636457, lng: 18.4391265 },
    { lat: 49.1629515, lng: 18.4393016 },
    { lat: 49.1618656, lng: 18.4408067 },
    { lat: 49.1612812, lng: 18.4411347 },
    { lat: 49.1612062, lng: 18.4411846 },
    { lat: 49.1609754, lng: 18.4413388 },
    { lat: 49.1603515, lng: 18.4417457 },
    { lat: 49.1599414, lng: 18.4418573 },
    { lat: 49.159532, lng: 18.4420635 },
    { lat: 49.1592016, lng: 18.4424565 },
    { lat: 49.1587655, lng: 18.4425258 },
    { lat: 49.1580624, lng: 18.4436712 },
    { lat: 49.1576369, lng: 18.4435436 },
    { lat: 49.1572477, lng: 18.4436238 },
    { lat: 49.1564847, lng: 18.4434512 },
    { lat: 49.1561029, lng: 18.4435855 },
    { lat: 49.1556827, lng: 18.4437381 },
    { lat: 49.1553667, lng: 18.4438595 },
    { lat: 49.1548333, lng: 18.4436154 },
    { lat: 49.154439, lng: 18.4431318 },
    { lat: 49.1541254, lng: 18.4426396 },
    { lat: 49.1537471, lng: 18.4426724 },
    { lat: 49.1535151, lng: 18.4428825 },
    { lat: 49.1531377, lng: 18.4429939 },
    { lat: 49.1526162, lng: 18.4426585 },
    { lat: 49.1519453, lng: 18.4424585 },
    { lat: 49.1519835, lng: 18.4412629 },
    { lat: 49.1520544, lng: 18.4401533 },
    { lat: 49.1520689, lng: 18.439943 },
    { lat: 49.1520691, lng: 18.4399271 },
    { lat: 49.1520772, lng: 18.4398086 },
    { lat: 49.1520684, lng: 18.4397486 },
    { lat: 49.1520029, lng: 18.4392966 },
    { lat: 49.1518982, lng: 18.438521 },
    { lat: 49.1517805, lng: 18.4376926 },
    { lat: 49.1514036, lng: 18.4368692 },
    { lat: 49.1511541, lng: 18.4350184 },
    { lat: 49.1509819, lng: 18.4344089 },
    { lat: 49.1507686, lng: 18.4336899 },
    { lat: 49.1506679, lng: 18.4332338 },
    { lat: 49.1506794, lng: 18.4329292 },
    { lat: 49.1509147, lng: 18.4324696 },
    { lat: 49.1509377, lng: 18.4322517 },
    { lat: 49.1509144, lng: 18.4320118 },
    { lat: 49.1509894, lng: 18.4316132 },
    { lat: 49.1509589, lng: 18.4314489 },
    { lat: 49.150864, lng: 18.4310152 },
    { lat: 49.150837, lng: 18.4309129 },
    { lat: 49.150699, lng: 18.4306399 },
    { lat: 49.150522, lng: 18.4301893 },
    { lat: 49.1505258, lng: 18.429807 },
    { lat: 49.1504415, lng: 18.4294666 },
    { lat: 49.150301, lng: 18.4291248 },
    { lat: 49.1501583, lng: 18.4287672 },
    { lat: 49.1500564, lng: 18.4284123 },
    { lat: 49.1498391, lng: 18.4278666 },
    { lat: 49.149772, lng: 18.4275889 },
    { lat: 49.1497092, lng: 18.4273281 },
    { lat: 49.1497299, lng: 18.4266588 },
    { lat: 49.1497448, lng: 18.4262962 },
    { lat: 49.1497643, lng: 18.4256651 },
    { lat: 49.1495403, lng: 18.4249372 },
    { lat: 49.1491335, lng: 18.4239821 },
    { lat: 49.1487894, lng: 18.4226293 },
    { lat: 49.1485412, lng: 18.4215842 },
    { lat: 49.148533, lng: 18.4215578 },
    { lat: 49.1482848, lng: 18.4206161 },
    { lat: 49.1480861, lng: 18.4194238 },
    { lat: 49.1480829, lng: 18.419407 },
    { lat: 49.1480709, lng: 18.4193335 },
    { lat: 49.148066, lng: 18.4193071 },
    { lat: 49.1480458, lng: 18.4192063 },
    { lat: 49.1480394, lng: 18.4191715 },
    { lat: 49.1480173, lng: 18.4190586 },
    { lat: 49.148642, lng: 18.4187055 },
    { lat: 49.1489319, lng: 18.418542 },
    { lat: 49.1492659, lng: 18.41836 },
    { lat: 49.1496623, lng: 18.4181433 },
    { lat: 49.15001, lng: 18.4178807 },
    { lat: 49.1501562, lng: 18.4177705 },
    { lat: 49.1506336, lng: 18.4174888 },
    { lat: 49.1506662, lng: 18.4174682 },
    { lat: 49.1509575, lng: 18.4172255 },
    { lat: 49.151121, lng: 18.4170393 },
    { lat: 49.1512782, lng: 18.4168219 },
    { lat: 49.1519378, lng: 18.4158959 },
    { lat: 49.1523425, lng: 18.415277 },
    { lat: 49.1525319, lng: 18.4149884 },
    { lat: 49.1526878, lng: 18.4148098 },
    { lat: 49.1526751, lng: 18.4147848 },
    { lat: 49.1528326, lng: 18.4142249 },
    { lat: 49.152819, lng: 18.4140384 },
    { lat: 49.1530023, lng: 18.4134972 },
    { lat: 49.1533689, lng: 18.4129846 },
    { lat: 49.154023, lng: 18.4120202 },
    { lat: 49.1542069, lng: 18.4116944 },
    { lat: 49.1544138, lng: 18.411397 },
    { lat: 49.1547044, lng: 18.4109176 },
    { lat: 49.1549851, lng: 18.410505 },
    { lat: 49.1551919, lng: 18.4098379 },
    { lat: 49.1553423, lng: 18.4093135 },
    { lat: 49.1557434, lng: 18.4079765 },
    { lat: 49.1559389, lng: 18.4073093 },
    { lat: 49.156104, lng: 18.4068461 },
    { lat: 49.1561412, lng: 18.4067632 },
    { lat: 49.1565724, lng: 18.4057261 },
    { lat: 49.1566925, lng: 18.405777 },
    { lat: 49.1570126, lng: 18.4047929 },
    { lat: 49.1571977, lng: 18.4044244 },
    { lat: 49.1573898, lng: 18.404022 },
    { lat: 49.1574894, lng: 18.4037436 },
    { lat: 49.1575034, lng: 18.4036704 },
    { lat: 49.1575222, lng: 18.4036022 },
    { lat: 49.1575417, lng: 18.4035275 },
    { lat: 49.1575499, lng: 18.4034911 },
    { lat: 49.1575553, lng: 18.4034466 },
    { lat: 49.157566, lng: 18.4033748 },
    { lat: 49.1575765, lng: 18.4032981 },
    { lat: 49.1575879, lng: 18.4032184 },
    { lat: 49.1575958, lng: 18.4031585 },
    { lat: 49.1576104, lng: 18.4029739 },
    { lat: 49.1576142, lng: 18.4029399 },
    { lat: 49.1576374, lng: 18.4025632 },
    { lat: 49.157647, lng: 18.4023478 },
    { lat: 49.1576464, lng: 18.4021337 },
    { lat: 49.1576466, lng: 18.4019127 },
    { lat: 49.1576466, lng: 18.4018323 },
    { lat: 49.1576211, lng: 18.4004931 },
    { lat: 49.1576844, lng: 18.4003105 },
    { lat: 49.157212, lng: 18.3998057 },
    { lat: 49.1566344, lng: 18.3989457 },
    { lat: 49.1567171, lng: 18.39879 },
    { lat: 49.1570519, lng: 18.3977856 },
    { lat: 49.1573719, lng: 18.3967813 },
    { lat: 49.157458, lng: 18.3966446 },
    { lat: 49.1574995, lng: 18.3965796 },
    { lat: 49.1575401, lng: 18.3965177 },
    { lat: 49.1575567, lng: 18.3964907 },
    { lat: 49.1577628, lng: 18.3964434 },
    { lat: 49.1579723, lng: 18.3965592 },
    { lat: 49.1580723, lng: 18.3963912 },
    { lat: 49.1581147, lng: 18.3963204 },
    { lat: 49.1581549, lng: 18.3962519 },
    { lat: 49.1582191, lng: 18.3961478 },
    { lat: 49.1582665, lng: 18.3960675 },
    { lat: 49.1583327, lng: 18.3959586 },
    { lat: 49.1583885, lng: 18.3958667 },
    { lat: 49.1584071, lng: 18.3958357 },
    { lat: 49.1584182, lng: 18.3958122 },
    { lat: 49.1582969, lng: 18.3954732 },
    { lat: 49.1582244, lng: 18.3950803 },
    { lat: 49.1581303, lng: 18.3948482 },
    { lat: 49.1578346, lng: 18.3941733 },
    { lat: 49.1576343, lng: 18.3935898 },
    { lat: 49.1571205, lng: 18.3921113 },
    { lat: 49.1570379, lng: 18.3919045 },
    { lat: 49.1569271, lng: 18.3917367 },
    { lat: 49.1568793, lng: 18.3917081 },
    { lat: 49.1568535, lng: 18.3916916 },
    { lat: 49.1567536, lng: 18.3916588 },
    { lat: 49.1566706, lng: 18.3916881 },
    { lat: 49.1565771, lng: 18.3917925 },
    { lat: 49.1564603, lng: 18.3918627 },
    { lat: 49.1564259, lng: 18.3918545 },
    { lat: 49.1563711, lng: 18.3917794 },
    { lat: 49.1563306, lng: 18.3917821 },
    { lat: 49.1563132, lng: 18.3917517 },
    { lat: 49.1562932, lng: 18.3917177 },
    { lat: 49.1561857, lng: 18.3917526 },
    { lat: 49.1560784, lng: 18.3917295 },
    { lat: 49.155563, lng: 18.3914865 },
    { lat: 49.1554267, lng: 18.3913686 },
    { lat: 49.1553563, lng: 18.3912991 },
    { lat: 49.1553129, lng: 18.3912495 },
    { lat: 49.1552608, lng: 18.3912459 },
    { lat: 49.1552496, lng: 18.3912054 },
    { lat: 49.1552414, lng: 18.3910976 },
    { lat: 49.1553653, lng: 18.3909523 },
    { lat: 49.1553983, lng: 18.3908336 },
    { lat: 49.1554489, lng: 18.390671 },
    { lat: 49.1555328, lng: 18.3905332 },
    { lat: 49.1554934, lng: 18.3903945 },
    { lat: 49.1555114, lng: 18.390208 },
    { lat: 49.1554854, lng: 18.3901684 },
    { lat: 49.1553741, lng: 18.3901534 },
    { lat: 49.1553376, lng: 18.3901237 },
    { lat: 49.1553032, lng: 18.389994 },
    { lat: 49.1553466, lng: 18.3898805 },
    { lat: 49.1553663, lng: 18.3897679 },
    { lat: 49.1552924, lng: 18.3892081 },
    { lat: 49.1552617, lng: 18.38908 },
    { lat: 49.1551401, lng: 18.3889827 },
    { lat: 49.1550296, lng: 18.3889233 },
    { lat: 49.1549777, lng: 18.3888621 },
    { lat: 49.1550006, lng: 18.3887447 },
    { lat: 49.1549854, lng: 18.3886935 },
    { lat: 49.1549266, lng: 18.3886094 },
    { lat: 49.154842, lng: 18.3883674 },
    { lat: 49.1548397, lng: 18.3882638 },
    { lat: 49.1548151, lng: 18.3881453 },
    { lat: 49.1548215, lng: 18.3879754 },
    { lat: 49.1547941, lng: 18.3878878 },
    { lat: 49.1547644, lng: 18.3878004 },
    { lat: 49.154794, lng: 18.3876633 },
    { lat: 49.1547744, lng: 18.3875141 },
    { lat: 49.1547274, lng: 18.3873778 },
    { lat: 49.154686, lng: 18.3871591 },
    { lat: 49.1545638, lng: 18.3868051 },
    { lat: 49.1545063, lng: 18.3865606 },
    { lat: 49.1544707, lng: 18.3863871 },
    { lat: 49.1544102, lng: 18.3861313 },
    { lat: 49.1543643, lng: 18.3859725 },
    { lat: 49.1542481, lng: 18.3857481 },
    { lat: 49.1541196, lng: 18.3854055 },
    { lat: 49.1541049, lng: 18.3853233 },
    { lat: 49.1540498, lng: 18.3852461 },
    { lat: 49.1540199, lng: 18.3851548 },
    { lat: 49.1538256, lng: 18.3846815 },
    { lat: 49.153641, lng: 18.384342 },
    { lat: 49.1536038, lng: 18.3842402 },
    { lat: 49.1535666, lng: 18.3840372 },
    { lat: 49.1534948, lng: 18.3838814 },
    { lat: 49.1534082, lng: 18.383743 },
    { lat: 49.1532703, lng: 18.3834542 },
    { lat: 49.1531368, lng: 18.38308 },
    { lat: 49.153085, lng: 18.3827768 },
    { lat: 49.1530981, lng: 18.3827068 },
    { lat: 49.1531859, lng: 18.3824787 },
    { lat: 49.1531555, lng: 18.3823592 },
    { lat: 49.153124, lng: 18.3822996 },
    { lat: 49.1530767, lng: 18.3822589 },
    { lat: 49.1528103, lng: 18.3824008 },
    { lat: 49.1527185, lng: 18.3823704 },
    { lat: 49.1526449, lng: 18.3822848 },
    { lat: 49.1525978, lng: 18.382168 },
    { lat: 49.1525931, lng: 18.382083 },
    { lat: 49.1526256, lng: 18.3817741 },
    { lat: 49.1525382, lng: 18.3816012 },
    { lat: 49.1524309, lng: 18.381579 },
    { lat: 49.1520526, lng: 18.3813877 },
    { lat: 49.1518973, lng: 18.3813136 },
    { lat: 49.1517736, lng: 18.3812589 },
    { lat: 49.1517215, lng: 18.3812365 },
    { lat: 49.1516536, lng: 18.3811901 },
    { lat: 49.1514678, lng: 18.3810538 },
    { lat: 49.1513496, lng: 18.3809366 },
    { lat: 49.1510371, lng: 18.3807538 },
    { lat: 49.1509495, lng: 18.3806799 },
    { lat: 49.1508934, lng: 18.3805633 },
    { lat: 49.1507882, lng: 18.3804774 },
    { lat: 49.15061, lng: 18.3804557 },
    { lat: 49.150453, lng: 18.3803509 },
    { lat: 49.1503592, lng: 18.3802469 },
    { lat: 49.1502568, lng: 18.3801075 },
    { lat: 49.1500902, lng: 18.3801342 },
    { lat: 49.1500019, lng: 18.3801115 },
    { lat: 49.1499124, lng: 18.3800822 },
    { lat: 49.1498425, lng: 18.3800036 },
    { lat: 49.1497475, lng: 18.3798753 },
    { lat: 49.1496832, lng: 18.379752 },
    { lat: 49.1496705, lng: 18.379809 },
    { lat: 49.1491714, lng: 18.3795951 },
    { lat: 49.1484756, lng: 18.3794596 },
    { lat: 49.1466955, lng: 18.3791133 },
    { lat: 49.145158, lng: 18.3788102 },
    { lat: 49.1443767, lng: 18.3791833 },
    { lat: 49.1441142, lng: 18.3788828 },
    { lat: 49.1439164, lng: 18.3786921 },
    { lat: 49.1438475, lng: 18.3785287 },
    { lat: 49.1422862, lng: 18.3773096 },
    { lat: 49.1422344, lng: 18.3772294 },
    { lat: 49.1420859, lng: 18.3770947 },
    { lat: 49.1419862, lng: 18.3770056 },
    { lat: 49.1417423, lng: 18.3767787 },
    { lat: 49.1410478, lng: 18.37652 },
    { lat: 49.1402005, lng: 18.3764172 },
    { lat: 49.139282, lng: 18.376354 },
    { lat: 49.138096, lng: 18.376314 },
    { lat: 49.138056, lng: 18.376276 },
    { lat: 49.137948, lng: 18.376344 },
    { lat: 49.137317, lng: 18.376414 },
    { lat: 49.137214, lng: 18.376418 },
    { lat: 49.137162, lng: 18.376372 },
    { lat: 49.13689, lng: 18.376398 },
    { lat: 49.136383, lng: 18.376435 },
    { lat: 49.136047, lng: 18.376458 },
    { lat: 49.135635, lng: 18.376536 },
    { lat: 49.134999, lng: 18.376734 },
    { lat: 49.134369, lng: 18.376996 },
    { lat: 49.134297, lng: 18.376984 },
    { lat: 49.134088, lng: 18.37706 },
    { lat: 49.133538, lng: 18.377279 },
    { lat: 49.133388, lng: 18.377347 },
    { lat: 49.133096, lng: 18.377563 },
    { lat: 49.132995, lng: 18.377626 },
    { lat: 49.132731, lng: 18.377785 },
    { lat: 49.132357, lng: 18.37787 },
    { lat: 49.131538, lng: 18.378343 },
    { lat: 49.130856, lng: 18.378771 },
    { lat: 49.130667, lng: 18.378867 },
    { lat: 49.130348, lng: 18.379157 },
    { lat: 49.129654, lng: 18.379536 },
    { lat: 49.12924, lng: 18.379745 },
    { lat: 49.128672, lng: 18.380096 },
    { lat: 49.128293, lng: 18.380287 },
    { lat: 49.127173, lng: 18.38093 },
    { lat: 49.126719, lng: 18.381201 },
    { lat: 49.126402, lng: 18.381386 },
    { lat: 49.126271, lng: 18.381455 },
    { lat: 49.125766, lng: 18.381737 },
    { lat: 49.125294, lng: 18.381942 },
    { lat: 49.125055, lng: 18.38205 },
    { lat: 49.124958, lng: 18.382189 },
    { lat: 49.124559, lng: 18.382396 },
    { lat: 49.124469, lng: 18.38241 },
    { lat: 49.124262, lng: 18.382425 },
    { lat: 49.124224, lng: 18.382399 },
    { lat: 49.124055, lng: 18.382251 },
    { lat: 49.123722, lng: 18.382132 },
    { lat: 49.123447, lng: 18.381987 },
    { lat: 49.123214, lng: 18.381836 },
    { lat: 49.122905, lng: 18.381558 },
    { lat: 49.122837, lng: 18.381504 },
    { lat: 49.122615, lng: 18.381273 },
    { lat: 49.122586, lng: 18.381213 },
    { lat: 49.122269, lng: 18.380801 },
    { lat: 49.122007, lng: 18.380392 },
    { lat: 49.121886, lng: 18.380291 },
    { lat: 49.121811, lng: 18.380179 },
    { lat: 49.121533, lng: 18.379777 },
    { lat: 49.121408, lng: 18.379624 },
    { lat: 49.121104, lng: 18.379077 },
    { lat: 49.120688, lng: 18.379575 },
    { lat: 49.120634, lng: 18.379726 },
    { lat: 49.120611, lng: 18.379844 },
    { lat: 49.120611, lng: 18.380037 },
    { lat: 49.120626, lng: 18.380095 },
    { lat: 49.120684, lng: 18.380159 },
    { lat: 49.120703, lng: 18.380229 },
    { lat: 49.120662, lng: 18.380344 },
    { lat: 49.120622, lng: 18.380381 },
    { lat: 49.12058, lng: 18.380342 },
    { lat: 49.120513, lng: 18.380367 },
    { lat: 49.120476, lng: 18.380501 },
    { lat: 49.1205, lng: 18.380589 },
    { lat: 49.120502, lng: 18.380668 },
    { lat: 49.120502, lng: 18.380758 },
    { lat: 49.120443, lng: 18.380836 },
    { lat: 49.120329, lng: 18.380815 },
    { lat: 49.120303, lng: 18.380828 },
    { lat: 49.1202, lng: 18.381192 },
    { lat: 49.120332, lng: 18.381472 },
    { lat: 49.120339, lng: 18.381487 },
    { lat: 49.119923, lng: 18.381971 },
    { lat: 49.119633, lng: 18.382403 },
    { lat: 49.119643, lng: 18.38242 },
    { lat: 49.1199, lng: 18.382787 },
    { lat: 49.120008, lng: 18.382987 },
    { lat: 49.120118, lng: 18.383297 },
    { lat: 49.120197, lng: 18.38358 },
    { lat: 49.120285, lng: 18.383747 },
    { lat: 49.120517, lng: 18.38433 },
    { lat: 49.120658, lng: 18.385169 },
    { lat: 49.120372, lng: 18.385679 },
    { lat: 49.120589, lng: 18.386319 },
    { lat: 49.12089, lng: 18.386819 },
    { lat: 49.120986, lng: 18.387053 },
    { lat: 49.121071, lng: 18.387598 },
    { lat: 49.121187, lng: 18.388465 },
    { lat: 49.121227, lng: 18.388891 },
    { lat: 49.12137, lng: 18.389184 },
    { lat: 49.121384, lng: 18.389234 },
    { lat: 49.121465, lng: 18.389535 },
    { lat: 49.121496, lng: 18.389656 },
    { lat: 49.121546, lng: 18.389849 },
    { lat: 49.121661, lng: 18.39027 },
    { lat: 49.121609, lng: 18.390749 },
    { lat: 49.121483, lng: 18.39135 },
    { lat: 49.121472, lng: 18.391773 },
    { lat: 49.121516, lng: 18.392524 },
    { lat: 49.121479, lng: 18.393273 },
    { lat: 49.121447, lng: 18.393889 },
    { lat: 49.121401, lng: 18.394056 },
    { lat: 49.121343, lng: 18.394271 },
    { lat: 49.121277, lng: 18.394504 },
    { lat: 49.121177, lng: 18.395027 },
    { lat: 49.121081, lng: 18.395226 },
    { lat: 49.121119, lng: 18.395769 },
    { lat: 49.121142, lng: 18.395792 },
    { lat: 49.121059, lng: 18.396019 },
    { lat: 49.121048, lng: 18.396006 },
    { lat: 49.12086, lng: 18.395881 },
    { lat: 49.120698, lng: 18.396094 },
    { lat: 49.120443, lng: 18.396271 },
    { lat: 49.119784, lng: 18.396568 },
    { lat: 49.11912, lng: 18.39689 },
    { lat: 49.11896, lng: 18.396962 },
    { lat: 49.118565, lng: 18.396918 },
    { lat: 49.118065, lng: 18.396911 },
    { lat: 49.117366, lng: 18.396941 },
    { lat: 49.117093, lng: 18.39701 },
    { lat: 49.116626, lng: 18.3973 },
    { lat: 49.11633, lng: 18.396894 },
    { lat: 49.115839, lng: 18.397066 },
    { lat: 49.115417, lng: 18.397589 },
    { lat: 49.114812, lng: 18.398163 },
    { lat: 49.114217, lng: 18.399025 },
    { lat: 49.11406, lng: 18.399209 },
    { lat: 49.113987, lng: 18.399294 },
    { lat: 49.113913, lng: 18.399267 },
    { lat: 49.113924, lng: 18.398781 },
    { lat: 49.113698, lng: 18.398664 },
    { lat: 49.113698, lng: 18.398683 },
    { lat: 49.113227, lng: 18.398401 },
    { lat: 49.112712, lng: 18.398372 },
    { lat: 49.112368, lng: 18.398182 },
    { lat: 49.112214, lng: 18.398083 },
    { lat: 49.112073, lng: 18.397827 },
    { lat: 49.111762, lng: 18.397295 },
    { lat: 49.111595, lng: 18.397008 },
    { lat: 49.111491, lng: 18.396714 },
    { lat: 49.111258, lng: 18.395952 },
    { lat: 49.11105, lng: 18.395456 },
    { lat: 49.110719, lng: 18.394981 },
    { lat: 49.110523, lng: 18.394365 },
    { lat: 49.11038, lng: 18.39411 },
    { lat: 49.109923, lng: 18.393403 },
    { lat: 49.10968, lng: 18.392692 },
    { lat: 49.109509, lng: 18.392345 },
    { lat: 49.10934, lng: 18.391877 },
    { lat: 49.109322, lng: 18.391433 },
    { lat: 49.109083, lng: 18.390681 },
    { lat: 49.108884, lng: 18.390037 },
    { lat: 49.108897, lng: 18.39003 },
    { lat: 49.108907, lng: 18.389859 },
    { lat: 49.109066, lng: 18.389403 },
    { lat: 49.109006, lng: 18.389091 },
    { lat: 49.109019, lng: 18.389084 },
    { lat: 49.108917, lng: 18.388793 },
    { lat: 49.1087, lng: 18.388427 },
    { lat: 49.108612, lng: 18.387824 },
    { lat: 49.10865, lng: 18.387466 },
    { lat: 49.108551, lng: 18.387168 },
    { lat: 49.108254, lng: 18.387033 },
    { lat: 49.108075, lng: 18.386762 },
    { lat: 49.107817, lng: 18.386223 },
    { lat: 49.107695, lng: 18.386043 },
    { lat: 49.107505, lng: 18.385956 },
    { lat: 49.107178, lng: 18.386117 },
    { lat: 49.107025, lng: 18.386206 },
    { lat: 49.106774, lng: 18.386227 },
    { lat: 49.106503, lng: 18.386083 },
    { lat: 49.106403, lng: 18.386069 },
    { lat: 49.106343, lng: 18.386095 },
    { lat: 49.106166, lng: 18.38617 },
    { lat: 49.106065, lng: 18.386157 },
    { lat: 49.105895, lng: 18.385972 },
    { lat: 49.105882, lng: 18.385995 },
    { lat: 49.105794, lng: 18.38589 },
    { lat: 49.105419, lng: 18.385658 },
    { lat: 49.105157, lng: 18.3853 },
    { lat: 49.10505, lng: 18.384782 },
    { lat: 49.104874, lng: 18.384538 },
    { lat: 49.104612, lng: 18.384064 },
    { lat: 49.104493, lng: 18.383659 },
    { lat: 49.103938, lng: 18.382897 },
    { lat: 49.10352, lng: 18.382802 },
    { lat: 49.10321, lng: 18.382752 },
    { lat: 49.102604, lng: 18.382733 },
    { lat: 49.102026, lng: 18.382723 },
    { lat: 49.101878, lng: 18.382724 },
    { lat: 49.101894, lng: 18.383206 },
    { lat: 49.101956, lng: 18.383885 },
    { lat: 49.101929, lng: 18.384173 },
    { lat: 49.10179, lng: 18.384512 },
    { lat: 49.101915, lng: 18.38494 },
    { lat: 49.10203, lng: 18.385401 },
    { lat: 49.102149, lng: 18.386213 },
    { lat: 49.102181, lng: 18.386519 },
    { lat: 49.102274, lng: 18.386871 },
    { lat: 49.102159, lng: 18.388154 },
    { lat: 49.102038, lng: 18.388942 },
    { lat: 49.101883, lng: 18.389407 },
    { lat: 49.101699, lng: 18.389698 },
    { lat: 49.10153, lng: 18.390175 },
    { lat: 49.101478, lng: 18.390733 },
    { lat: 49.101322, lng: 18.390963 },
    { lat: 49.101198, lng: 18.391206 },
    { lat: 49.101022, lng: 18.391683 },
    { lat: 49.100755, lng: 18.39203 },
    { lat: 49.100372, lng: 18.392782 },
    { lat: 49.099893, lng: 18.393388 },
    { lat: 49.099418, lng: 18.39397 },
    { lat: 49.099142, lng: 18.394012 },
    { lat: 49.099026, lng: 18.39421 },
    { lat: 49.098987, lng: 18.394364 },
    { lat: 49.099046, lng: 18.394642 },
    { lat: 49.098959, lng: 18.395009 },
    { lat: 49.099048, lng: 18.395396 },
    { lat: 49.099021, lng: 18.395556 },
    { lat: 49.099096, lng: 18.395683 },
    { lat: 49.099147, lng: 18.396054 },
    { lat: 49.099103, lng: 18.396569 },
    { lat: 49.09886, lng: 18.396762 },
    { lat: 49.09866, lng: 18.396742 },
    { lat: 49.098333, lng: 18.396578 },
    { lat: 49.098127, lng: 18.39642 },
    { lat: 49.098122, lng: 18.396469 },
    { lat: 49.0983558, lng: 18.3975875 },
    { lat: 49.0986264, lng: 18.398483 },
    { lat: 49.0987577, lng: 18.3989185 },
    { lat: 49.099031, lng: 18.3998024 },
    { lat: 49.0984316, lng: 18.400772 },
    { lat: 49.0978606, lng: 18.4022572 },
    { lat: 49.097488, lng: 18.4035367 },
    { lat: 49.0970702, lng: 18.4042426 },
    { lat: 49.0966618, lng: 18.4047739 },
    { lat: 49.0963567, lng: 18.4050072 },
    { lat: 49.0962399, lng: 18.4051403 },
    { lat: 49.0961968, lng: 18.4051775 },
    { lat: 49.0956626, lng: 18.4053075 },
    { lat: 49.0950521, lng: 18.4053044 },
    { lat: 49.0947964, lng: 18.4054314 },
    { lat: 49.0944453, lng: 18.4057344 },
    { lat: 49.094277, lng: 18.4059545 },
    { lat: 49.0940226, lng: 18.4066784 },
    { lat: 49.0938298, lng: 18.4075589 },
    { lat: 49.0937467, lng: 18.4082391 },
    { lat: 49.0936352, lng: 18.4097922 },
    { lat: 49.0936358, lng: 18.4103745 },
    { lat: 49.0937238, lng: 18.4109058 },
    { lat: 49.0937467, lng: 18.4111777 },
    { lat: 49.0937003, lng: 18.4120317 },
    { lat: 49.0938256, lng: 18.4128313 },
    { lat: 49.0939706, lng: 18.4136179 },
    { lat: 49.093682, lng: 18.4145614 },
    { lat: 49.0935222, lng: 18.4147712 },
    { lat: 49.0933405, lng: 18.4152399 },
    { lat: 49.0930721, lng: 18.4159981 },
    { lat: 49.0926193, lng: 18.4164383 },
    { lat: 49.0924481, lng: 18.416607 },
    { lat: 49.0924362, lng: 18.4166158 },
    { lat: 49.0923842, lng: 18.4166552 },
    { lat: 49.0923744, lng: 18.416662 },
    { lat: 49.0923127, lng: 18.416709 },
    { lat: 49.0922942, lng: 18.4167225 },
    { lat: 49.0922628, lng: 18.4167668 },
    { lat: 49.0917327, lng: 18.4175232 },
    { lat: 49.0917316, lng: 18.417525 },
    { lat: 49.091648, lng: 18.4176442 },
    { lat: 49.0915456, lng: 18.4177908 },
    { lat: 49.0915319, lng: 18.4178095 },
    { lat: 49.0914807, lng: 18.4178833 },
    { lat: 49.0909767, lng: 18.4186637 },
    { lat: 49.0908062, lng: 18.4189263 },
    { lat: 49.0906096, lng: 18.4191462 },
    { lat: 49.0905002, lng: 18.4192502 },
    { lat: 49.0904003, lng: 18.4193608 },
    { lat: 49.0903481, lng: 18.4194359 },
    { lat: 49.0902959, lng: 18.4194933 },
    { lat: 49.0901716, lng: 18.4196735 },
    { lat: 49.0901685, lng: 18.4196773 },
    { lat: 49.0897476, lng: 18.4202873 },
    { lat: 49.0895627, lng: 18.4204747 },
    { lat: 49.0894417, lng: 18.4205497 },
    { lat: 49.0892565, lng: 18.420649 },
    { lat: 49.0891068, lng: 18.4207162 },
    { lat: 49.0888944, lng: 18.4208117 },
    { lat: 49.0888024, lng: 18.4208827 },
    { lat: 49.0884019, lng: 18.4211889 },
    { lat: 49.0880214, lng: 18.4212437 },
    { lat: 49.087694, lng: 18.4213404 },
    { lat: 49.0871721, lng: 18.4218408 },
    { lat: 49.0867899, lng: 18.4222717 },
    { lat: 49.0864316, lng: 18.4228531 },
    { lat: 49.0860977, lng: 18.4235286 },
    { lat: 49.0857968, lng: 18.4241466 },
    { lat: 49.0853774, lng: 18.4246199 },
    { lat: 49.0850843, lng: 18.4250133 },
    { lat: 49.0847269, lng: 18.4254874 },
    { lat: 49.0844606, lng: 18.4259362 },
    { lat: 49.0839004, lng: 18.4269067 },
    { lat: 49.0835146, lng: 18.4273973 },
    { lat: 49.0825468, lng: 18.4282977 },
    { lat: 49.0822049, lng: 18.4286041 },
    { lat: 49.0813576, lng: 18.4293179 },
    { lat: 49.0813521, lng: 18.4292988 },
    { lat: 49.0813476, lng: 18.4292798 },
    { lat: 49.0813389, lng: 18.4292858 },
    { lat: 49.0813313, lng: 18.4292903 },
    { lat: 49.0813224, lng: 18.4292328 },
    { lat: 49.0813273, lng: 18.4290742 },
    { lat: 49.081337, lng: 18.428985 },
    { lat: 49.0813308, lng: 18.428892 },
    { lat: 49.0813124, lng: 18.4288048 },
    { lat: 49.0812868, lng: 18.4285439 },
    { lat: 49.0812552, lng: 18.4283393 },
    { lat: 49.0812495, lng: 18.4282565 },
    { lat: 49.081237, lng: 18.4281746 },
    { lat: 49.0811561, lng: 18.4277481 },
    { lat: 49.081134, lng: 18.4275731 },
    { lat: 49.0811234, lng: 18.4273602 },
    { lat: 49.0811204, lng: 18.4271627 },
    { lat: 49.081108, lng: 18.4268972 },
    { lat: 49.081112, lng: 18.4266009 },
    { lat: 49.0811809, lng: 18.4258048 },
    { lat: 49.0812689, lng: 18.4248219 },
    { lat: 49.0812922, lng: 18.4246327 },
    { lat: 49.0813261, lng: 18.424388 },
    { lat: 49.0813646, lng: 18.4240232 },
    { lat: 49.081377, lng: 18.423795 },
    { lat: 49.0813842, lng: 18.4235592 },
    { lat: 49.0813702, lng: 18.4235524 },
    { lat: 49.0813588, lng: 18.4235486 },
    { lat: 49.0813209, lng: 18.4235369 },
    { lat: 49.081267, lng: 18.4235197 },
    { lat: 49.0812449, lng: 18.4234876 },
    { lat: 49.081215, lng: 18.4233957 },
    { lat: 49.081212, lng: 18.4233828 },
    { lat: 49.0811918, lng: 18.4233215 },
    { lat: 49.081159, lng: 18.4232201 },
    { lat: 49.081122, lng: 18.4230999 },
    { lat: 49.0810688, lng: 18.4229324 },
    { lat: 49.0810126, lng: 18.4227325 },
    { lat: 49.0809494, lng: 18.4225264 },
    { lat: 49.0809271, lng: 18.4224688 },
    { lat: 49.0808953, lng: 18.4223858 },
    { lat: 49.0808682, lng: 18.4223227 },
    { lat: 49.0808643, lng: 18.4223128 },
    { lat: 49.0808528, lng: 18.4222882 },
    { lat: 49.0807841, lng: 18.4221681 },
    { lat: 49.0807398, lng: 18.4221009 },
    { lat: 49.0807275, lng: 18.4220816 },
    { lat: 49.0806576, lng: 18.4220018 },
    { lat: 49.0805993, lng: 18.4219483 },
    { lat: 49.0805433, lng: 18.4219137 },
    { lat: 49.0805386, lng: 18.4219105 },
    { lat: 49.0805197, lng: 18.4218955 },
    { lat: 49.0804865, lng: 18.4218661 },
    { lat: 49.0804273, lng: 18.4218359 },
    { lat: 49.0803913, lng: 18.4218173 },
    { lat: 49.0803485, lng: 18.4217979 },
    { lat: 49.0802213, lng: 18.4217408 },
    { lat: 49.0801629, lng: 18.4217234 },
    { lat: 49.0800974, lng: 18.421703 },
    { lat: 49.079987, lng: 18.4216656 },
    { lat: 49.0798891, lng: 18.421629 },
    { lat: 49.0798504, lng: 18.4216209 },
    { lat: 49.0798172, lng: 18.4216129 },
    { lat: 49.0797253, lng: 18.4216239 },
    { lat: 49.0796799, lng: 18.4216394 },
    { lat: 49.0795959, lng: 18.4216912 },
    { lat: 49.0794934, lng: 18.4217554 },
    { lat: 49.0793834, lng: 18.4218486 },
    { lat: 49.0790958, lng: 18.4221547 },
    { lat: 49.0790343, lng: 18.4222063 },
    { lat: 49.0788268, lng: 18.4222479 },
    { lat: 49.0787473, lng: 18.4223412 },
    { lat: 49.0786427, lng: 18.4223445 },
    { lat: 49.0786021, lng: 18.4222439 },
    { lat: 49.0785069, lng: 18.4221132 },
    { lat: 49.0784287, lng: 18.4221279 },
    { lat: 49.0783406, lng: 18.4221233 },
    { lat: 49.0783096, lng: 18.4220949 },
    { lat: 49.0781135, lng: 18.4220779 },
    { lat: 49.0780602, lng: 18.4220905 },
    { lat: 49.0779304, lng: 18.4221114 },
    { lat: 49.0778969, lng: 18.4221169 },
    { lat: 49.0778673, lng: 18.4221536 },
    { lat: 49.0778578, lng: 18.4222495 },
    { lat: 49.0777975, lng: 18.4223622 },
    { lat: 49.0777379, lng: 18.4225879 },
    { lat: 49.0777611, lng: 18.4227023 },
    { lat: 49.0778156, lng: 18.4229141 },
    { lat: 49.0778183, lng: 18.4230245 },
    { lat: 49.0777782, lng: 18.4233193 },
    { lat: 49.0777802, lng: 18.4236023 },
    { lat: 49.0776999, lng: 18.4237012 },
    { lat: 49.0776979, lng: 18.4239285 },
    { lat: 49.0777109, lng: 18.4240638 },
    { lat: 49.0776766, lng: 18.4242985 },
    { lat: 49.0775907, lng: 18.4246239 },
    { lat: 49.0775996, lng: 18.4248466 },
    { lat: 49.0776151, lng: 18.4248828 },
    { lat: 49.0775479, lng: 18.4251364 },
    { lat: 49.0774407, lng: 18.4253005 },
    { lat: 49.0773797, lng: 18.4254013 },
    { lat: 49.0772845, lng: 18.4254753 },
    { lat: 49.0772414, lng: 18.4255713 },
    { lat: 49.0770803, lng: 18.4257402 },
    { lat: 49.0770213, lng: 18.425897 },
    { lat: 49.0769367, lng: 18.4260001 },
    { lat: 49.0768447, lng: 18.4260509 },
    { lat: 49.076776, lng: 18.4261133 },
    { lat: 49.076703, lng: 18.4262831 },
    { lat: 49.0766156, lng: 18.4264983 },
    { lat: 49.0766104, lng: 18.426526 },
    { lat: 49.076512, lng: 18.4267271 },
    { lat: 49.0763749, lng: 18.4268378 },
    { lat: 49.0763511, lng: 18.4269176 },
    { lat: 49.0763182, lng: 18.4270382 },
    { lat: 49.0762509, lng: 18.4270853 },
    { lat: 49.0761772, lng: 18.4271399 },
    { lat: 49.0761478, lng: 18.427014 },
    { lat: 49.0760963, lng: 18.4270425 },
    { lat: 49.0760241, lng: 18.4271663 },
    { lat: 49.0758206, lng: 18.4276901 },
    { lat: 49.0759083, lng: 18.4279095 },
    { lat: 49.0759107, lng: 18.4280758 },
    { lat: 49.075762, lng: 18.4284492 },
    { lat: 49.0757051, lng: 18.4285623 },
    { lat: 49.0755969, lng: 18.428749 },
    { lat: 49.075515, lng: 18.429349 },
    { lat: 49.0754854, lng: 18.4295697 },
    { lat: 49.0754887, lng: 18.429773 },
    { lat: 49.0754807, lng: 18.4299555 },
    { lat: 49.0754903, lng: 18.4300883 },
    { lat: 49.0754663, lng: 18.4302051 },
    { lat: 49.0754208, lng: 18.4302812 },
    { lat: 49.0754354, lng: 18.4304422 },
    { lat: 49.0754626, lng: 18.4305279 },
    { lat: 49.0754189, lng: 18.4306756 },
    { lat: 49.0754192, lng: 18.4309901 },
    { lat: 49.0753778, lng: 18.4310155 },
    { lat: 49.07534, lng: 18.4311488 },
    { lat: 49.0752792, lng: 18.4312733 },
    { lat: 49.0751632, lng: 18.4314408 },
    { lat: 49.0751426, lng: 18.4316187 },
    { lat: 49.0750977, lng: 18.4316647 },
    { lat: 49.075081, lng: 18.4316895 },
    { lat: 49.0751114, lng: 18.4317484 },
    { lat: 49.0750644, lng: 18.4318777 },
    { lat: 49.0748638, lng: 18.4321278 },
    { lat: 49.0748637, lng: 18.4321668 },
    { lat: 49.0748867, lng: 18.4322571 },
    { lat: 49.0748831, lng: 18.4323344 },
    { lat: 49.0747136, lng: 18.4324121 },
    { lat: 49.0746004, lng: 18.4326284 },
    { lat: 49.0745623, lng: 18.4326754 },
    { lat: 49.0745568, lng: 18.4327603 },
    { lat: 49.0746416, lng: 18.4329261 },
    { lat: 49.0746741, lng: 18.4330869 },
    { lat: 49.0745694, lng: 18.4332771 },
    { lat: 49.074577, lng: 18.4333722 },
    { lat: 49.0745405, lng: 18.4335096 },
    { lat: 49.0743949, lng: 18.4336299 },
    { lat: 49.0744107, lng: 18.4338347 },
    { lat: 49.0743758, lng: 18.4338993 },
    { lat: 49.0742289, lng: 18.4339348 },
    { lat: 49.0740742, lng: 18.434115 },
    { lat: 49.0740561, lng: 18.4342779 },
    { lat: 49.0740184, lng: 18.434352 },
    { lat: 49.0738971, lng: 18.434361 },
    { lat: 49.0737479, lng: 18.4347254 },
    { lat: 49.0736362, lng: 18.434805 },
    { lat: 49.0734879, lng: 18.4348379 },
    { lat: 49.073512, lng: 18.435029 },
    { lat: 49.0734589, lng: 18.435295 },
    { lat: 49.0733283, lng: 18.4354417 },
    { lat: 49.0732894, lng: 18.4354308 },
    { lat: 49.0732016, lng: 18.4355167 },
    { lat: 49.0731893, lng: 18.4355594 },
    { lat: 49.0731944, lng: 18.4357962 },
    { lat: 49.0729162, lng: 18.435926 },
    { lat: 49.0729249, lng: 18.4360837 },
    { lat: 49.0726789, lng: 18.4362473 },
    { lat: 49.0726696, lng: 18.4364266 },
    { lat: 49.0724509, lng: 18.4365532 },
    { lat: 49.0724131, lng: 18.4366448 },
    { lat: 49.0722386, lng: 18.4367942 },
    { lat: 49.072116, lng: 18.4370446 },
    { lat: 49.0719989, lng: 18.4371727 },
    { lat: 49.07197, lng: 18.4372228 },
    { lat: 49.0714698, lng: 18.438157 },
    { lat: 49.0714076, lng: 18.4382139 },
    { lat: 49.0714157, lng: 18.4382379 },
    { lat: 49.0710665, lng: 18.4388017 },
    { lat: 49.0707711, lng: 18.4393771 },
    { lat: 49.0701927, lng: 18.439935 },
    { lat: 49.0697552, lng: 18.4402862 },
    { lat: 49.068811, lng: 18.4410253 },
    { lat: 49.0681108, lng: 18.4415858 },
    { lat: 49.0676867, lng: 18.4419334 },
    { lat: 49.0676321, lng: 18.4421529 },
    { lat: 49.067075, lng: 18.4424818 },
    { lat: 49.0668776, lng: 18.4427687 },
    { lat: 49.0664473, lng: 18.4431287 },
    { lat: 49.0662458, lng: 18.4433814 },
    { lat: 49.0660452, lng: 18.4436327 },
    { lat: 49.0659546, lng: 18.4437484 },
    { lat: 49.0657561, lng: 18.443915 },
    { lat: 49.0655603, lng: 18.4440655 },
    { lat: 49.0654485, lng: 18.4441657 },
    { lat: 49.0653929, lng: 18.4442214 },
    { lat: 49.0652858, lng: 18.4443453 },
    { lat: 49.0651341, lng: 18.4445623 },
    { lat: 49.0650916, lng: 18.4446704 },
    { lat: 49.0648935, lng: 18.4452321 },
    { lat: 49.0648357, lng: 18.4453517 },
    { lat: 49.0648166, lng: 18.4453925 },
    { lat: 49.0644633, lng: 18.4461276 },
    { lat: 49.0640998, lng: 18.446533 },
    { lat: 49.0636261, lng: 18.4466568 },
    { lat: 49.0635805, lng: 18.4466688 },
    { lat: 49.0626987, lng: 18.4468994 },
    { lat: 49.0626629, lng: 18.4469271 },
    { lat: 49.0622304, lng: 18.4472667 },
    { lat: 49.0613091, lng: 18.4482152 },
    { lat: 49.0612307, lng: 18.4482865 },
    { lat: 49.0612027, lng: 18.4483115 },
    { lat: 49.0603422, lng: 18.4490943 },
    { lat: 49.0603602, lng: 18.4492171 },
    { lat: 49.0604217, lng: 18.4496182 },
    { lat: 49.0598844, lng: 18.450104 },
    { lat: 49.0598221, lng: 18.4501608 },
    { lat: 49.0597995, lng: 18.4501808 },
    { lat: 49.0594953, lng: 18.4507006 },
    { lat: 49.0594788, lng: 18.450728 },
    { lat: 49.0597734, lng: 18.452458 },
    { lat: 49.0599096, lng: 18.4530889 },
    { lat: 49.0599204, lng: 18.4531415 },
    { lat: 49.0599733, lng: 18.4533865 },
    { lat: 49.0602229, lng: 18.4547076 },
    { lat: 49.0604164, lng: 18.4551908 },
    { lat: 49.0604281, lng: 18.4552174 },
    { lat: 49.0604307, lng: 18.4552252 },
    { lat: 49.0608633, lng: 18.4564065 },
    { lat: 49.061067, lng: 18.4569316 },
    { lat: 49.0610868, lng: 18.4569848 },
    { lat: 49.0613259, lng: 18.4575999 },
    { lat: 49.0617422, lng: 18.4586087 },
    { lat: 49.0617643, lng: 18.4586611 },
    { lat: 49.0618044, lng: 18.4587565 },
    { lat: 49.0623281, lng: 18.4598722 },
    { lat: 49.062831, lng: 18.4609597 },
    { lat: 49.0632425, lng: 18.4622288 },
    { lat: 49.0634023, lng: 18.4632497 },
    { lat: 49.0634516, lng: 18.4648315 },
    { lat: 49.0632883, lng: 18.4654724 },
    { lat: 49.0631715, lng: 18.466428 },
    { lat: 49.0631996, lng: 18.4666329 },
    { lat: 49.0631114, lng: 18.4675347 },
    { lat: 49.0630763, lng: 18.467718 },
    { lat: 49.0629684, lng: 18.4682806 },
    { lat: 49.0628608, lng: 18.4688483 },
    { lat: 49.0625236, lng: 18.4701279 },
    { lat: 49.0623068, lng: 18.4712753 },
    { lat: 49.0620364, lng: 18.4725186 },
    { lat: 49.0617655, lng: 18.4737919 },
    { lat: 49.0616259, lng: 18.4742939 },
    { lat: 49.0619686, lng: 18.4746589 },
    { lat: 49.0610738, lng: 18.4759125 },
    { lat: 49.0609827, lng: 18.4761434 },
    { lat: 49.0603527, lng: 18.4771012 },
    { lat: 49.0599253, lng: 18.4777823 },
    { lat: 49.0595798, lng: 18.4782499 },
    { lat: 49.0591862, lng: 18.4788718 },
    { lat: 49.0583735, lng: 18.4787804 },
    { lat: 49.0576013, lng: 18.4787447 },
    { lat: 49.0569453, lng: 18.4787557 },
    { lat: 49.0565563, lng: 18.4785501 },
    { lat: 49.0562246, lng: 18.4785916 },
    { lat: 49.0557039, lng: 18.4787 },
    { lat: 49.055318, lng: 18.4788445 },
    { lat: 49.0547755, lng: 18.4791561 },
    { lat: 49.0543551, lng: 18.4799659 },
    { lat: 49.0543367, lng: 18.4800008 },
    { lat: 49.0543358, lng: 18.4800036 },
    { lat: 49.054262, lng: 18.4802024 },
    { lat: 49.0542094, lng: 18.4810963 },
    { lat: 49.0542424, lng: 18.4822761 },
    { lat: 49.0542816, lng: 18.4833845 },
    { lat: 49.0542802, lng: 18.4842886 },
    { lat: 49.054279, lng: 18.4845983 },
    { lat: 49.0542652, lng: 18.484862 },
    { lat: 49.0543503, lng: 18.4851799 },
    { lat: 49.0543512, lng: 18.485403 },
    { lat: 49.054408, lng: 18.486338 },
    { lat: 49.0543422, lng: 18.4869315 },
    { lat: 49.0544247, lng: 18.4874105 },
    { lat: 49.0543757, lng: 18.4878537 },
    { lat: 49.0543942, lng: 18.4879024 },
    { lat: 49.054501, lng: 18.4881858 },
    { lat: 49.0561482, lng: 18.4893306 },
    { lat: 49.0576994, lng: 18.4898087 },
    { lat: 49.0581788, lng: 18.48948 },
    { lat: 49.058656, lng: 18.4892326 },
    { lat: 49.0590549, lng: 18.4894523 },
    { lat: 49.0603708, lng: 18.4905171 },
    { lat: 49.0608406, lng: 18.4911695 },
    { lat: 49.0611008, lng: 18.4912452 },
    { lat: 49.0618188, lng: 18.4914845 },
    { lat: 49.0625541, lng: 18.4921431 },
    { lat: 49.0625672, lng: 18.4913896 },
    { lat: 49.0627097, lng: 18.4903034 },
    { lat: 49.0625497, lng: 18.4881781 },
    { lat: 49.0626331, lng: 18.48828 },
    { lat: 49.0628881, lng: 18.4885918 },
    { lat: 49.0631954, lng: 18.4889712 },
    { lat: 49.0637764, lng: 18.4895733 },
    { lat: 49.0643392, lng: 18.4897158 },
    { lat: 49.0647333, lng: 18.4896008 },
    { lat: 49.0648553, lng: 18.4892108 },
    { lat: 49.0649549, lng: 18.4889077 },
    { lat: 49.065235, lng: 18.4892653 },
    { lat: 49.0654577, lng: 18.4894402 },
    { lat: 49.0658872, lng: 18.4894138 },
    { lat: 49.0659102, lng: 18.4901868 },
    { lat: 49.0655762, lng: 18.4912383 },
    { lat: 49.0656652, lng: 18.4913185 },
    { lat: 49.0657225, lng: 18.4913761 },
    { lat: 49.065787, lng: 18.4914421 },
    { lat: 49.0659091, lng: 18.4915508 },
    { lat: 49.0659705, lng: 18.4916022 },
    { lat: 49.0660327, lng: 18.491647 },
    { lat: 49.0660961, lng: 18.4916901 },
    { lat: 49.0666252, lng: 18.4919615 },
    { lat: 49.0672597, lng: 18.491684 },
    { lat: 49.0673758, lng: 18.49164 },
    { lat: 49.0675536, lng: 18.4915485 },
    { lat: 49.0679971, lng: 18.4914929 },
    { lat: 49.0685057, lng: 18.4914706 },
    { lat: 49.0687083, lng: 18.4916484 },
    { lat: 49.0690432, lng: 18.4919541 },
    { lat: 49.0692607, lng: 18.4921575 },
    { lat: 49.069622, lng: 18.4928681 },
    { lat: 49.0698337, lng: 18.4933791 },
    { lat: 49.070088, lng: 18.4940904 },
    { lat: 49.0703532, lng: 18.4947328 },
    { lat: 49.0706872, lng: 18.4956868 },
    { lat: 49.070712, lng: 18.4957279 },
    { lat: 49.0708198, lng: 18.4959287 },
    { lat: 49.0708407, lng: 18.4959574 },
    { lat: 49.0708455, lng: 18.4959632 },
    { lat: 49.0710532, lng: 18.4964406 },
    { lat: 49.0710698, lng: 18.496477 },
    { lat: 49.0714021, lng: 18.4969056 },
    { lat: 49.0720224, lng: 18.4978966 },
    { lat: 49.072323, lng: 18.4984017 },
    { lat: 49.0727239, lng: 18.4990532 },
    { lat: 49.0730883, lng: 18.4996141 },
    { lat: 49.0736821, lng: 18.5006353 },
    { lat: 49.0740312, lng: 18.5012257 },
    { lat: 49.0744751, lng: 18.5021294 },
    { lat: 49.0751193, lng: 18.5024466 },
    { lat: 49.075327, lng: 18.5025489 },
    { lat: 49.0757666, lng: 18.5029372 },
    { lat: 49.0764686, lng: 18.5032527 },
    { lat: 49.0772097, lng: 18.5029647 },
    { lat: 49.0778605, lng: 18.5028399 },
    { lat: 49.0780612, lng: 18.5025705 },
    { lat: 49.0785385, lng: 18.5021783 },
    { lat: 49.078908, lng: 18.5020464 },
    { lat: 49.0791727, lng: 18.5018334 },
    { lat: 49.0795309, lng: 18.5018046 },
    { lat: 49.0796647, lng: 18.5018152 },
    { lat: 49.0800056, lng: 18.5011999 },
    { lat: 49.0801403, lng: 18.5007528 },
    { lat: 49.0806419, lng: 18.5002486 },
    { lat: 49.0811432, lng: 18.4998865 },
    { lat: 49.0814212, lng: 18.4995445 },
    { lat: 49.0821576, lng: 18.4999133 },
    { lat: 49.0824057, lng: 18.5002031 },
    { lat: 49.082655, lng: 18.5003489 },
    { lat: 49.0829425, lng: 18.5001183 },
    { lat: 49.0833795, lng: 18.5000025 },
    { lat: 49.0838236, lng: 18.4998528 },
    { lat: 49.0840247, lng: 18.4998992 },
    { lat: 49.0842604, lng: 18.4998792 },
    { lat: 49.0845583, lng: 18.4996519 },
    { lat: 49.0848829, lng: 18.4994636 },
    { lat: 49.0853301, lng: 18.4990985 },
    { lat: 49.0855887, lng: 18.4987319 },
    { lat: 49.0859338, lng: 18.4985861 },
    { lat: 49.0863466, lng: 18.4985631 },
    { lat: 49.0869659, lng: 18.4986069 },
    { lat: 49.0872338, lng: 18.4981212 },
    { lat: 49.0876476, lng: 18.4977449 },
    { lat: 49.0879246, lng: 18.4978181 },
    { lat: 49.0884497, lng: 18.4979757 },
    { lat: 49.0888723, lng: 18.4981964 },
    { lat: 49.089371, lng: 18.4984881 },
    { lat: 49.0895265, lng: 18.4985267 },
    { lat: 49.0896952, lng: 18.4986218 },
    { lat: 49.0901985, lng: 18.4987493 },
    { lat: 49.0905181, lng: 18.4987365 },
    { lat: 49.090502, lng: 18.4992705 },
    { lat: 49.0905248, lng: 18.4992732 },
    { lat: 49.0905963, lng: 18.4992821 },
    { lat: 49.0906122, lng: 18.4992839 },
    { lat: 49.0907378, lng: 18.499293 },
    { lat: 49.0907857, lng: 18.49895 },
    { lat: 49.0908739, lng: 18.4987855 },
    { lat: 49.0910614, lng: 18.4987086 },
    { lat: 49.0909349, lng: 18.4992032 },
    { lat: 49.0909619, lng: 18.4992424 },
    { lat: 49.0909851, lng: 18.4992755 },
    { lat: 49.0910047, lng: 18.499304 },
    { lat: 49.0912306, lng: 18.4994541 },
    { lat: 49.091644, lng: 18.4996101 },
    { lat: 49.0917154, lng: 18.4996153 },
    { lat: 49.0918206, lng: 18.4996225 },
    { lat: 49.0920101, lng: 18.4995388 },
    { lat: 49.0921258, lng: 18.4994882 },
    { lat: 49.0923373, lng: 18.4994986 },
    { lat: 49.0924308, lng: 18.4988769 },
    { lat: 49.0924445, lng: 18.4988786 },
    { lat: 49.0926509, lng: 18.4988997 },
    { lat: 49.0927068, lng: 18.4992018 },
    { lat: 49.0926945, lng: 18.4996178 },
    { lat: 49.0926069, lng: 18.5005756 },
    { lat: 49.0926856, lng: 18.5010677 },
    { lat: 49.0926018, lng: 18.5020576 },
    { lat: 49.0925842, lng: 18.5022725 },
    { lat: 49.0925576, lng: 18.5025918 },
    { lat: 49.0925844, lng: 18.5027734 },
    { lat: 49.0926134, lng: 18.5029767 },
    { lat: 49.0926184, lng: 18.5029833 },
    { lat: 49.0926916, lng: 18.5031065 },
    { lat: 49.0931617, lng: 18.5038883 },
    { lat: 49.0936377, lng: 18.5041798 },
    { lat: 49.0943751, lng: 18.5047537 },
    { lat: 49.0943888, lng: 18.50511 },
    { lat: 49.0949036, lng: 18.5067133 },
    { lat: 49.0953802, lng: 18.506872 },
    { lat: 49.0956558, lng: 18.5073942 },
    { lat: 49.0960014, lng: 18.5086681 },
    { lat: 49.0963163, lng: 18.5098955 },
    { lat: 49.0963265, lng: 18.5100826 },
    { lat: 49.0962564, lng: 18.5104957 },
    { lat: 49.0961113, lng: 18.5110211 },
    { lat: 49.0958902, lng: 18.5115191 },
    { lat: 49.0957388, lng: 18.5117834 },
    { lat: 49.0973493, lng: 18.5127565 },
    { lat: 49.0977032, lng: 18.5129379 },
    { lat: 49.0989728, lng: 18.5135091 },
    { lat: 49.0993165, lng: 18.5163685 },
    { lat: 49.1011801, lng: 18.5171827 },
    { lat: 49.1013276, lng: 18.5175527 },
    { lat: 49.1019059, lng: 18.5204562 },
    { lat: 49.1020044, lng: 18.5206903 },
    { lat: 49.1021073, lng: 18.5208613 },
    { lat: 49.1023158, lng: 18.521127 },
    { lat: 49.1016241, lng: 18.5239307 },
    { lat: 49.1012853, lng: 18.5252523 },
    { lat: 49.1009646, lng: 18.5260768 },
    { lat: 49.1007922, lng: 18.526433 },
    { lat: 49.1005212, lng: 18.527511 },
    { lat: 49.1004621, lng: 18.5278136 },
    { lat: 49.100568, lng: 18.5281228 },
    { lat: 49.1008209, lng: 18.5283789 },
    { lat: 49.1011847, lng: 18.5288263 },
    { lat: 49.1015014, lng: 18.5291112 },
    { lat: 49.1018181, lng: 18.5294205 },
    { lat: 49.1019934, lng: 18.5299913 },
    { lat: 49.1026483, lng: 18.5309828 },
    { lat: 49.102612, lng: 18.5311648 },
    { lat: 49.1026106, lng: 18.531368 },
    { lat: 49.1034119, lng: 18.5324186 },
    { lat: 49.1035992, lng: 18.5324617 },
    { lat: 49.1043307, lng: 18.5333476 },
    { lat: 49.1048605, lng: 18.5334664 },
    { lat: 49.1052796, lng: 18.5338555 },
    { lat: 49.1057157, lng: 18.5338255 },
    { lat: 49.1058893, lng: 18.5343448 },
    { lat: 49.1065901, lng: 18.5359965 },
    { lat: 49.1065544, lng: 18.5380463 },
    { lat: 49.1071251, lng: 18.538837 },
    { lat: 49.1071643, lng: 18.5388926 },
    { lat: 49.1073642, lng: 18.5391692 },
    { lat: 49.107946, lng: 18.5395835 },
    { lat: 49.109086, lng: 18.5401831 },
    { lat: 49.1101272, lng: 18.5406443 },
    { lat: 49.1103434, lng: 18.5404083 },
    { lat: 49.1109498, lng: 18.5401299 },
    { lat: 49.1115782, lng: 18.5400081 },
    { lat: 49.1124337, lng: 18.5402482 },
    { lat: 49.1125127, lng: 18.5402699 },
    { lat: 49.1129178, lng: 18.5400214 },
    { lat: 49.1134922, lng: 18.540403 },
    { lat: 49.1136968, lng: 18.5409546 },
    { lat: 49.1140442, lng: 18.5412654 },
    { lat: 49.1144379, lng: 18.5416841 },
    { lat: 49.1148162, lng: 18.5420474 },
    { lat: 49.1154357, lng: 18.5430113 },
    { lat: 49.1162099, lng: 18.5441074 },
    { lat: 49.1166082, lng: 18.5447147 },
    { lat: 49.1169999, lng: 18.5454499 },
    { lat: 49.117335, lng: 18.544556 },
    { lat: 49.1176097, lng: 18.5435855 },
    { lat: 49.1179179, lng: 18.5424872 },
    { lat: 49.1181241, lng: 18.5417528 },
    { lat: 49.1183069, lng: 18.5410245 },
    { lat: 49.1186388, lng: 18.5404658 },
    { lat: 49.1187363, lng: 18.5397258 },
    { lat: 49.1187596, lng: 18.5395519 },
    { lat: 49.1188055, lng: 18.5394627 },
    { lat: 49.1191962, lng: 18.5386761 },
    { lat: 49.1195454, lng: 18.5378775 },
    { lat: 49.1199699, lng: 18.5372188 },
    { lat: 49.1196336, lng: 18.5366309 },
    { lat: 49.1192756, lng: 18.5362269 },
    { lat: 49.1190006, lng: 18.5356479 },
    { lat: 49.1192601, lng: 18.5352739 },
    { lat: 49.1197632, lng: 18.5349005 },
    { lat: 49.1200607, lng: 18.5345813 },
    { lat: 49.1203344, lng: 18.5344927 },
    { lat: 49.1204465, lng: 18.5343764 },
    { lat: 49.1207458, lng: 18.5341339 },
    { lat: 49.1211257, lng: 18.5337961 },
    { lat: 49.1214311, lng: 18.5332492 },
    { lat: 49.1216743, lng: 18.5327842 },
    { lat: 49.122034, lng: 18.53224 },
    { lat: 49.1222641, lng: 18.5312421 },
    { lat: 49.1224936, lng: 18.5306064 },
    { lat: 49.1227452, lng: 18.5300667 },
    { lat: 49.1229568, lng: 18.5294322 },
    { lat: 49.1233072, lng: 18.5284273 },
    { lat: 49.1233663, lng: 18.5282489 },
    { lat: 49.1234864, lng: 18.528009 },
    { lat: 49.1236383, lng: 18.5277094 },
    { lat: 49.1237171, lng: 18.5275605 },
    { lat: 49.1238883, lng: 18.5274305 },
    { lat: 49.1241982, lng: 18.5271566 },
    { lat: 49.1246608, lng: 18.5266808 },
    { lat: 49.1250144, lng: 18.5261296 },
    { lat: 49.1254009, lng: 18.5253936 },
    { lat: 49.1254393, lng: 18.5248553 },
    { lat: 49.1254643, lng: 18.524317 },
    { lat: 49.1254383, lng: 18.5238586 },
    { lat: 49.1254337, lng: 18.5237745 },
    { lat: 49.1254288, lng: 18.5236831 },
    { lat: 49.1254362, lng: 18.5232172 },
    { lat: 49.1254001, lng: 18.5228626 },
    { lat: 49.1252752, lng: 18.5222441 },
    { lat: 49.1252243, lng: 18.5218059 },
    { lat: 49.1250767, lng: 18.5211649 },
    { lat: 49.1249529, lng: 18.5204824 },
    { lat: 49.1248964, lng: 18.5200237 },
    { lat: 49.1248779, lng: 18.519578 },
    { lat: 49.1249128, lng: 18.5185793 },
    { lat: 49.1249284, lng: 18.5179741 },
    { lat: 49.1249148, lng: 18.5168912 },
    { lat: 49.1248969, lng: 18.5166033 },
    { lat: 49.1248993, lng: 18.5160663 },
    { lat: 49.1249454, lng: 18.5153982 },
    { lat: 49.1250696, lng: 18.5151311 },
    { lat: 49.1251141, lng: 18.5148711 },
    { lat: 49.1251801, lng: 18.5142174 },
    { lat: 49.1251803, lng: 18.5136601 },
    { lat: 49.1251482, lng: 18.5134421 },
    { lat: 49.1252679, lng: 18.5129875 },
    { lat: 49.1254554, lng: 18.5124762 },
    { lat: 49.1256933, lng: 18.5120358 },
    { lat: 49.1258193, lng: 18.5119693 },
    { lat: 49.1262153, lng: 18.5118453 },
    { lat: 49.1267777, lng: 18.5114634 },
    { lat: 49.126888, lng: 18.5110231 },
    { lat: 49.1271963, lng: 18.5108025 },
    { lat: 49.1272816, lng: 18.5109597 },
    { lat: 49.1273205, lng: 18.5110335 },
    { lat: 49.127466, lng: 18.5113031 },
    { lat: 49.12756, lng: 18.5114767 },
    { lat: 49.1275906, lng: 18.5113954 },
    { lat: 49.1280206, lng: 18.5105267 },
    { lat: 49.1284955, lng: 18.5097823 },
    { lat: 49.1289182, lng: 18.5089895 },
    { lat: 49.1289635, lng: 18.5089287 },
    { lat: 49.1293549, lng: 18.5084118 },
    { lat: 49.1300255, lng: 18.5073225 },
    { lat: 49.1302547, lng: 18.5075343 },
    { lat: 49.1304957, lng: 18.5077573 },
    { lat: 49.1309222, lng: 18.5081113 },
    { lat: 49.1313975, lng: 18.508887 },
    { lat: 49.1316409, lng: 18.5091743 },
    { lat: 49.1324433, lng: 18.5098067 },
    { lat: 49.1324805, lng: 18.5100275 },
    { lat: 49.1326118, lng: 18.5108073 },
    { lat: 49.1327273, lng: 18.5108167 },
    { lat: 49.1330037, lng: 18.5108398 },
    { lat: 49.1333824, lng: 18.5107826 },
    { lat: 49.1334904, lng: 18.5107663 },
    { lat: 49.1339412, lng: 18.5108431 },
    { lat: 49.134099, lng: 18.5107794 },
    { lat: 49.13444, lng: 18.5106409 },
    { lat: 49.1352412, lng: 18.5100659 },
    { lat: 49.1356065, lng: 18.5097936 },
    { lat: 49.1359934, lng: 18.5100855 },
    { lat: 49.1360295, lng: 18.5103333 },
    { lat: 49.1361804, lng: 18.5105158 },
    { lat: 49.1365641, lng: 18.5106857 },
    { lat: 49.1366006, lng: 18.5109003 },
    { lat: 49.1371364, lng: 18.5107975 },
    { lat: 49.1373462, lng: 18.5105906 },
    { lat: 49.1376411, lng: 18.5108275 },
    { lat: 49.138113, lng: 18.5110025 },
    { lat: 49.1382868, lng: 18.51088 },
    { lat: 49.139049, lng: 18.5109584 },
    { lat: 49.1394706, lng: 18.5109318 },
    { lat: 49.1395632, lng: 18.5108728 },
    { lat: 49.1395926, lng: 18.5107896 },
    { lat: 49.139609, lng: 18.5107386 },
    { lat: 49.1396404, lng: 18.5106546 },
    { lat: 49.1398108, lng: 18.510656 },
    { lat: 49.1398616, lng: 18.5106964 },
    { lat: 49.1399345, lng: 18.5107319 },
    { lat: 49.1400659, lng: 18.5108875 },
    { lat: 49.1401514, lng: 18.5109869 },
    { lat: 49.1402531, lng: 18.5109044 },
    { lat: 49.1406299, lng: 18.5104296 },
    { lat: 49.1406228, lng: 18.5104224 },
    { lat: 49.1410831, lng: 18.5103222 },
    { lat: 49.1420837, lng: 18.510718 },
    { lat: 49.1423583, lng: 18.5112679 },
    { lat: 49.142653, lng: 18.5115027 },
    { lat: 49.1430808, lng: 18.5115085 },
    { lat: 49.1430839, lng: 18.5111922 },
    { lat: 49.1429345, lng: 18.5106631 },
    { lat: 49.142871, lng: 18.510366 },
    { lat: 49.1429151, lng: 18.5103058 },
    { lat: 49.1429969, lng: 18.510213 },
    { lat: 49.1431496, lng: 18.5101997 },
    { lat: 49.1432793, lng: 18.5102009 },
    { lat: 49.1433583, lng: 18.5101391 },
    { lat: 49.1434689, lng: 18.5100992 },
    { lat: 49.1434962, lng: 18.5100604 },
    { lat: 49.1435681, lng: 18.5100166 },
    { lat: 49.1437179, lng: 18.5099492 },
    { lat: 49.1440649, lng: 18.5105845 },
    { lat: 49.1441024, lng: 18.5106722 },
    { lat: 49.1442121, lng: 18.5109287 },
    { lat: 49.144247, lng: 18.5110928 },
    { lat: 49.1442626, lng: 18.5114837 },
    { lat: 49.1442721, lng: 18.5117829 },
    { lat: 49.1442589, lng: 18.5119334 },
    { lat: 49.1442879, lng: 18.5119287 },
    { lat: 49.144588, lng: 18.5124067 },
    { lat: 49.145352, lng: 18.5122896 },
    { lat: 49.1465265, lng: 18.5118604 },
    { lat: 49.1464358, lng: 18.5113737 },
    { lat: 49.1466886, lng: 18.5114387 },
    { lat: 49.146931, lng: 18.5120181 },
    { lat: 49.1471744, lng: 18.5124516 },
    { lat: 49.1473892, lng: 18.5127095 },
    { lat: 49.147808, lng: 18.5131094 },
    { lat: 49.1478376, lng: 18.5133024 },
    { lat: 49.1481452, lng: 18.5136493 },
    { lat: 49.1481313, lng: 18.5141837 },
    { lat: 49.1483793, lng: 18.5143866 },
    { lat: 49.1483959, lng: 18.5149221 },
    { lat: 49.1486817, lng: 18.5149734 },
    { lat: 49.1488737, lng: 18.5154563 },
    { lat: 49.1489433, lng: 18.5154897 },
    { lat: 49.1490187, lng: 18.5157137 },
    { lat: 49.1491853, lng: 18.5158341 },
    { lat: 49.1492281, lng: 18.5158957 },
    { lat: 49.1492421, lng: 18.5158408 },
    { lat: 49.1493751, lng: 18.5154655 },
    { lat: 49.1495329, lng: 18.5151321 },
    { lat: 49.1497743, lng: 18.514634 },
    { lat: 49.1499679, lng: 18.5146254 },
    { lat: 49.1500861, lng: 18.5144558 },
    { lat: 49.1501397, lng: 18.514342 },
    { lat: 49.1502073, lng: 18.5142767 },
    { lat: 49.1502635, lng: 18.5141291 },
    { lat: 49.1503633, lng: 18.5140545 },
    { lat: 49.1504028, lng: 18.5136408 },
    { lat: 49.1504147, lng: 18.5120935 },
    { lat: 49.1506833, lng: 18.5108297 },
    { lat: 49.150761, lng: 18.5101205 },
    { lat: 49.1506692, lng: 18.5100715 },
    { lat: 49.1508525, lng: 18.5095213 },
    { lat: 49.1511549, lng: 18.5089419 },
    { lat: 49.1523449, lng: 18.5073447 },
    { lat: 49.1526413, lng: 18.5068622 },
    { lat: 49.1532942, lng: 18.5061337 },
    { lat: 49.1523808, lng: 18.5047272 },
    { lat: 49.1518052, lng: 18.5036002 },
    { lat: 49.1514912, lng: 18.5027625 },
    { lat: 49.1515123, lng: 18.5027143 },
    { lat: 49.1517186, lng: 18.502152 },
    { lat: 49.1518043, lng: 18.5017174 },
    { lat: 49.15198, lng: 18.5011126 },
    { lat: 49.1520881, lng: 18.5008175 },
    { lat: 49.1520893, lng: 18.5004037 },
    { lat: 49.152072, lng: 18.5000243 },
    { lat: 49.1520709, lng: 18.4997336 },
    { lat: 49.1520919, lng: 18.4993735 },
    { lat: 49.1521872, lng: 18.4990316 },
    { lat: 49.1523247, lng: 18.4983023 },
    { lat: 49.1523656, lng: 18.498019 },
    { lat: 49.1523932, lng: 18.4978014 },
    { lat: 49.1525619, lng: 18.4967978 },
    { lat: 49.1525607, lng: 18.496691 },
    { lat: 49.1526133, lng: 18.4965178 },
    { lat: 49.152931, lng: 18.4960362 },
    { lat: 49.1533931, lng: 18.4954291 },
    { lat: 49.1541402, lng: 18.4944606 },
    { lat: 49.1541859, lng: 18.4944105 },
    { lat: 49.1543881, lng: 18.4942086 },
    { lat: 49.154438, lng: 18.494131 },
    { lat: 49.1548208, lng: 18.4937661 },
    { lat: 49.154868, lng: 18.4936814 },
    { lat: 49.1548926, lng: 18.4936352 },
    { lat: 49.155126, lng: 18.4931999 },
    { lat: 49.1551607, lng: 18.4931314 },
    { lat: 49.155547, lng: 18.4923749 },
    { lat: 49.1559661, lng: 18.491248 },
    { lat: 49.1561964, lng: 18.4898038 },
    { lat: 49.1562699, lng: 18.4887304 },
    { lat: 49.1572569, lng: 18.4889588 },
    { lat: 49.1574686, lng: 18.4860984 },
    { lat: 49.1574786, lng: 18.4857411 },
    { lat: 49.1573664, lng: 18.4853387 },
    { lat: 49.1574169, lng: 18.4848171 },
    { lat: 49.1573777, lng: 18.4844725 },
    { lat: 49.1576913, lng: 18.4840983 },
    { lat: 49.1578567, lng: 18.4835116 },
    { lat: 49.1578924, lng: 18.4829459 },
    { lat: 49.1581198, lng: 18.4816559 },
    { lat: 49.158306, lng: 18.4818031 },
    { lat: 49.1581277, lng: 18.4810354 },
    { lat: 49.1582904, lng: 18.4805668 },
    { lat: 49.1587152, lng: 18.48058 },
    { lat: 49.1590742, lng: 18.4803995 },
    { lat: 49.1597601, lng: 18.4813929 },
    { lat: 49.1595064, lng: 18.4807733 },
    { lat: 49.1606629, lng: 18.478055 },
    { lat: 49.1606671, lng: 18.4780467 },
    { lat: 49.160724, lng: 18.4779117 },
    { lat: 49.1607774, lng: 18.4778159 },
    { lat: 49.1607979, lng: 18.4777785 },
    { lat: 49.1608106, lng: 18.4777605 },
    { lat: 49.1608367, lng: 18.4777242 },
    { lat: 49.160866, lng: 18.4776821 },
    { lat: 49.1608964, lng: 18.4776399 },
    { lat: 49.1609341, lng: 18.4775866 },
    { lat: 49.1605358, lng: 18.4768194 },
    { lat: 49.1603463, lng: 18.4761585 },
    { lat: 49.1601845, lng: 18.4755298 },
    { lat: 49.1600512, lng: 18.4751748 },
    { lat: 49.1600951, lng: 18.4751117 },
    { lat: 49.1601493, lng: 18.4750497 },
    { lat: 49.1605301, lng: 18.4742773 },
    { lat: 49.1606895, lng: 18.4739529 },
    { lat: 49.1606985, lng: 18.4739337 },
    { lat: 49.1609574, lng: 18.4734082 },
    { lat: 49.1609738, lng: 18.473376 },
    { lat: 49.1609899, lng: 18.4733421 },
    { lat: 49.1612013, lng: 18.4729166 },
    { lat: 49.1612297, lng: 18.4728586 },
    { lat: 49.1612688, lng: 18.4727696 },
    { lat: 49.1613671, lng: 18.4725461 },
    { lat: 49.1615844, lng: 18.4724754 },
    { lat: 49.1617063, lng: 18.4724354 },
    { lat: 49.1617972, lng: 18.4724059 },
    { lat: 49.1627381, lng: 18.4720997 },
    { lat: 49.1636458, lng: 18.4718047 },
    { lat: 49.1637165, lng: 18.4717793 },
    { lat: 49.1637637, lng: 18.4719398 },
    { lat: 49.1638065, lng: 18.4720839 },
    { lat: 49.1640501, lng: 18.4729086 },
    { lat: 49.1661672, lng: 18.4770582 },
    { lat: 49.1663759, lng: 18.4758612 },
    { lat: 49.1666002, lng: 18.4760857 },
    { lat: 49.1666587, lng: 18.4761448 },
    { lat: 49.1669809, lng: 18.4764681 },
    { lat: 49.167371, lng: 18.4768585 },
    { lat: 49.1674227, lng: 18.4767725 },
    { lat: 49.1675159, lng: 18.4766195 },
    { lat: 49.1684471, lng: 18.477456 },
    { lat: 49.1687447, lng: 18.4777232 },
    { lat: 49.1687802, lng: 18.4777543 },
    { lat: 49.1693214, lng: 18.4786174 },
    { lat: 49.1695077, lng: 18.4788286 },
    { lat: 49.1695479, lng: 18.4788605 },
    { lat: 49.1698765, lng: 18.4792605 },
    { lat: 49.1706035, lng: 18.4799334 },
    { lat: 49.1707639, lng: 18.480061 },
    { lat: 49.1708063, lng: 18.4800933 },
    { lat: 49.1711386, lng: 18.4803561 },
    { lat: 49.1711765, lng: 18.4803875 },
    { lat: 49.171316, lng: 18.4804644 },
    { lat: 49.1715286, lng: 18.4805777 },
    { lat: 49.1716507, lng: 18.4806429 },
    { lat: 49.1717636, lng: 18.4806668 },
    { lat: 49.1720351, lng: 18.4807239 },
    { lat: 49.1723227, lng: 18.4807851 },
    { lat: 49.172556, lng: 18.4808226 },
    { lat: 49.173348, lng: 18.4810116 },
    { lat: 49.1740152, lng: 18.4808577 },
    { lat: 49.174053, lng: 18.4808491 },
    { lat: 49.1741592, lng: 18.4808718 },
    { lat: 49.1743865, lng: 18.4809443 },
    { lat: 49.1755012, lng: 18.4805977 },
    { lat: 49.1756272, lng: 18.4805309 },
    { lat: 49.1764472, lng: 18.4803439 },
    { lat: 49.1770101, lng: 18.4803852 },
    { lat: 49.1773147, lng: 18.4804076 },
    { lat: 49.1778818, lng: 18.4805689 },
    { lat: 49.1782745, lng: 18.4807383 },
    { lat: 49.1786627, lng: 18.4809468 },
    { lat: 49.1788412, lng: 18.4810784 },
    { lat: 49.1789753, lng: 18.4811771 },
    { lat: 49.1793869, lng: 18.4815473 },
    { lat: 49.1797779, lng: 18.481893 },
    { lat: 49.1801718, lng: 18.482413 },
    { lat: 49.1804495, lng: 18.482875 },
    { lat: 49.1807385, lng: 18.4833957 },
    { lat: 49.1809446, lng: 18.4838224 },
    { lat: 49.1811828, lng: 18.4845124 },
    { lat: 49.1814954, lng: 18.485589 },
    { lat: 49.1815912, lng: 18.4861274 },
    { lat: 49.181636, lng: 18.486587 },
    { lat: 49.181704, lng: 18.48651 },
    { lat: 49.181742, lng: 18.486467 },
    { lat: 49.182856, lng: 18.485236 },
    { lat: 49.182919, lng: 18.485166 },
    { lat: 49.183194, lng: 18.484894 },
    { lat: 49.183261, lng: 18.484829 },
    { lat: 49.183538, lng: 18.48474 },
    { lat: 49.18361, lng: 18.484502 },
    { lat: 49.183845, lng: 18.484276 },
    { lat: 49.183943, lng: 18.48411 },
    { lat: 49.18395, lng: 18.483883 },
    { lat: 49.184036, lng: 18.483748 },
    { lat: 49.184167, lng: 18.483699 },
    { lat: 49.184271, lng: 18.483553 },
    { lat: 49.184346, lng: 18.483385 },
    { lat: 49.184463, lng: 18.483259 },
    { lat: 49.184482, lng: 18.483106 },
    { lat: 49.184453, lng: 18.482916 },
    { lat: 49.184488, lng: 18.482721 },
    { lat: 49.184667, lng: 18.482506 },
    { lat: 49.184629, lng: 18.482323 },
    { lat: 49.184719, lng: 18.482197 },
    { lat: 49.184835, lng: 18.482095 },
    { lat: 49.184846, lng: 18.481904 },
    { lat: 49.184921, lng: 18.48173 },
    { lat: 49.185158, lng: 18.481726 },
    { lat: 49.185275, lng: 18.481677 },
    { lat: 49.185431, lng: 18.481508 },
    { lat: 49.185667, lng: 18.48129 },
    { lat: 49.185924, lng: 18.481119 },
    { lat: 49.186073, lng: 18.48105 },
    { lat: 49.18633, lng: 18.480842 },
    { lat: 49.186533, lng: 18.480633 },
    { lat: 49.186662, lng: 18.480607 },
    { lat: 49.186702, lng: 18.480543 },
    { lat: 49.186826, lng: 18.480509 },
    { lat: 49.186884, lng: 18.480283 },
    { lat: 49.187145, lng: 18.480194 },
    { lat: 49.187306, lng: 18.479789 },
    { lat: 49.187402, lng: 18.479322 },
    { lat: 49.187597, lng: 18.478974 },
    { lat: 49.187706, lng: 18.47878 },
    { lat: 49.187727, lng: 18.47858 },
    { lat: 49.18776, lng: 18.478315 },
    { lat: 49.18793, lng: 18.477903 },
    { lat: 49.187962, lng: 18.477629 },
    { lat: 49.188001, lng: 18.477355 },
    { lat: 49.188014, lng: 18.47725 },
    { lat: 49.187984, lng: 18.476589 },
    { lat: 49.187847, lng: 18.476128 },
    { lat: 49.187813, lng: 18.475891 },
    { lat: 49.187793, lng: 18.475637 },
    { lat: 49.187791, lng: 18.475366 },
    { lat: 49.187805, lng: 18.475107 },
    { lat: 49.187846, lng: 18.474823 },
    { lat: 49.187833, lng: 18.474588 },
    { lat: 49.187763, lng: 18.474348 },
    { lat: 49.187746, lng: 18.473799 },
    { lat: 49.187816, lng: 18.473288 },
    { lat: 49.187824, lng: 18.472494 },
    { lat: 49.187756, lng: 18.471963 },
    { lat: 49.187798, lng: 18.471457 },
    { lat: 49.18783, lng: 18.471138 },
    { lat: 49.187888, lng: 18.470879 },
    { lat: 49.187986, lng: 18.470691 },
    { lat: 49.188117, lng: 18.470529 },
    { lat: 49.188332, lng: 18.470151 },
    { lat: 49.188542, lng: 18.469533 },
    { lat: 49.188625, lng: 18.469399 },
    { lat: 49.188767, lng: 18.469297 },
    { lat: 49.189084, lng: 18.468831 },
    { lat: 49.189266, lng: 18.468181 },
    { lat: 49.1892168, lng: 18.4681368 },
    { lat: 49.1891832, lng: 18.4680776 },
    { lat: 49.1890746, lng: 18.4679277 },
    { lat: 49.1888353, lng: 18.4679239 },
    { lat: 49.1883937, lng: 18.4680363 },
    { lat: 49.1878317, lng: 18.4678269 },
    { lat: 49.1871903, lng: 18.4682871 },
    { lat: 49.1862664, lng: 18.4683483 },
    { lat: 49.1859665, lng: 18.4681236 },
    { lat: 49.1856007, lng: 18.4683627 },
    { lat: 49.1850365, lng: 18.4692479 },
    { lat: 49.1840533, lng: 18.4681836 },
    { lat: 49.1839272, lng: 18.4682892 },
    { lat: 49.1835693, lng: 18.468179 },
    { lat: 49.1830558, lng: 18.4681134 },
    { lat: 49.1814452, lng: 18.4679908 },
    { lat: 49.1806387, lng: 18.4673308 },
    { lat: 49.1805468, lng: 18.4674669 },
    { lat: 49.1799861, lng: 18.467173 },
    { lat: 49.1793652, lng: 18.4667116 },
    { lat: 49.1787307, lng: 18.4661212 },
    { lat: 49.1785622, lng: 18.4659487 },
    { lat: 49.1779755, lng: 18.4653464 },
    { lat: 49.1761575, lng: 18.4649213 },
    { lat: 49.1754234, lng: 18.4646977 },
    { lat: 49.1744281, lng: 18.4645249 },
    { lat: 49.1734887, lng: 18.4637839 },
    { lat: 49.1729329, lng: 18.4633755 },
    { lat: 49.17284, lng: 18.4633076 },
    { lat: 49.1726814, lng: 18.4631288 },
    { lat: 49.1726704, lng: 18.4631151 },
    { lat: 49.172247, lng: 18.4625702 },
    { lat: 49.1722227, lng: 18.4625387 },
    { lat: 49.1713718, lng: 18.4612542 },
    { lat: 49.1711764, lng: 18.4605687 },
    { lat: 49.1711411, lng: 18.460522 },
    { lat: 49.1710001, lng: 18.4603763 },
    { lat: 49.1708406, lng: 18.4600205 },
    { lat: 49.171144, lng: 18.4594413 },
    { lat: 49.1710179, lng: 18.4592594 },
    { lat: 49.1689368, lng: 18.4576734 },
    { lat: 49.1679424, lng: 18.4567154 },
    { lat: 49.1690011, lng: 18.4548506 },
    { lat: 49.1690664, lng: 18.4544995 },
    { lat: 49.1681416, lng: 18.4532868 },
    { lat: 49.1683056, lng: 18.4513803 },
    { lat: 49.1674432, lng: 18.4513277 },
    { lat: 49.1673902, lng: 18.4513294 },
    { lat: 49.1668178, lng: 18.4492832 },
    { lat: 49.1665661, lng: 18.4484783 },
    { lat: 49.1661269, lng: 18.4471763 },
    { lat: 49.1662798, lng: 18.4459952 },
    { lat: 49.1662604, lng: 18.44564 },
    { lat: 49.1660636, lng: 18.4450139 },
    { lat: 49.1654661, lng: 18.4439908 },
    { lat: 49.1654839, lng: 18.4424665 },
    { lat: 49.1654592, lng: 18.4420775 },
    { lat: 49.1653415, lng: 18.4417211 },
    { lat: 49.1648076, lng: 18.4404822 },
    { lat: 49.1641389, lng: 18.4397872 },
    { lat: 49.1636457, lng: 18.4391265 },
  ],
  Kostolec: [
    { lat: 49.1252679, lng: 18.5129875 },
    { lat: 49.1251482, lng: 18.5134421 },
    { lat: 49.1251803, lng: 18.5136601 },
    { lat: 49.1251801, lng: 18.5142174 },
    { lat: 49.1251141, lng: 18.5148711 },
    { lat: 49.1250696, lng: 18.5151311 },
    { lat: 49.1249454, lng: 18.5153982 },
    { lat: 49.1248993, lng: 18.5160663 },
    { lat: 49.1248969, lng: 18.5166033 },
    { lat: 49.1249148, lng: 18.5168912 },
    { lat: 49.1249284, lng: 18.5179741 },
    { lat: 49.1249128, lng: 18.5185793 },
    { lat: 49.1248779, lng: 18.519578 },
    { lat: 49.1248964, lng: 18.5200237 },
    { lat: 49.1249529, lng: 18.5204824 },
    { lat: 49.1250767, lng: 18.5211649 },
    { lat: 49.1252243, lng: 18.5218059 },
    { lat: 49.1252752, lng: 18.5222441 },
    { lat: 49.1254001, lng: 18.5228626 },
    { lat: 49.1254362, lng: 18.5232172 },
    { lat: 49.1254288, lng: 18.5236831 },
    { lat: 49.1254337, lng: 18.5237745 },
    { lat: 49.1254383, lng: 18.5238586 },
    { lat: 49.1254643, lng: 18.524317 },
    { lat: 49.1254393, lng: 18.5248553 },
    { lat: 49.1254009, lng: 18.5253936 },
    { lat: 49.1250144, lng: 18.5261296 },
    { lat: 49.1246608, lng: 18.5266808 },
    { lat: 49.1241982, lng: 18.5271566 },
    { lat: 49.1238883, lng: 18.5274305 },
    { lat: 49.1237171, lng: 18.5275605 },
    { lat: 49.1236383, lng: 18.5277094 },
    { lat: 49.1234864, lng: 18.528009 },
    { lat: 49.1233663, lng: 18.5282489 },
    { lat: 49.1233072, lng: 18.5284273 },
    { lat: 49.1229568, lng: 18.5294322 },
    { lat: 49.1227452, lng: 18.5300667 },
    { lat: 49.1224936, lng: 18.5306064 },
    { lat: 49.1222641, lng: 18.5312421 },
    { lat: 49.122034, lng: 18.53224 },
    { lat: 49.1216743, lng: 18.5327842 },
    { lat: 49.1214311, lng: 18.5332492 },
    { lat: 49.1211257, lng: 18.5337961 },
    { lat: 49.1207458, lng: 18.5341339 },
    { lat: 49.1204465, lng: 18.5343764 },
    { lat: 49.1203344, lng: 18.5344927 },
    { lat: 49.1200607, lng: 18.5345813 },
    { lat: 49.1197632, lng: 18.5349005 },
    { lat: 49.1192601, lng: 18.5352739 },
    { lat: 49.1190006, lng: 18.5356479 },
    { lat: 49.1192756, lng: 18.5362269 },
    { lat: 49.1196336, lng: 18.5366309 },
    { lat: 49.1199699, lng: 18.5372188 },
    { lat: 49.1195454, lng: 18.5378775 },
    { lat: 49.1191962, lng: 18.5386761 },
    { lat: 49.1188055, lng: 18.5394627 },
    { lat: 49.1187596, lng: 18.5395519 },
    { lat: 49.1187363, lng: 18.5397258 },
    { lat: 49.1186388, lng: 18.5404658 },
    { lat: 49.1183069, lng: 18.5410245 },
    { lat: 49.1181241, lng: 18.5417528 },
    { lat: 49.1179179, lng: 18.5424872 },
    { lat: 49.1176097, lng: 18.5435855 },
    { lat: 49.117335, lng: 18.544556 },
    { lat: 49.1169999, lng: 18.5454499 },
    { lat: 49.1179489, lng: 18.5467739 },
    { lat: 49.1186439, lng: 18.5476119 },
    { lat: 49.1194753, lng: 18.5473441 },
    { lat: 49.12024, lng: 18.5480339 },
    { lat: 49.1210344, lng: 18.5489177 },
    { lat: 49.1217707, lng: 18.5496437 },
    { lat: 49.121742, lng: 18.5489264 },
    { lat: 49.1216093, lng: 18.5483051 },
    { lat: 49.1216526, lng: 18.5472076 },
    { lat: 49.1215776, lng: 18.5465097 },
    { lat: 49.1218804, lng: 18.5459757 },
    { lat: 49.1222091, lng: 18.5454631 },
    { lat: 49.1224421, lng: 18.5452054 },
    { lat: 49.1227668, lng: 18.5444725 },
    { lat: 49.1228276, lng: 18.5437607 },
    { lat: 49.1229103, lng: 18.5435364 },
    { lat: 49.123228, lng: 18.5428212 },
    { lat: 49.1237313, lng: 18.5419069 },
    { lat: 49.12387, lng: 18.5416356 },
    { lat: 49.1239828, lng: 18.5412197 },
    { lat: 49.1241456, lng: 18.5414962 },
    { lat: 49.1244453, lng: 18.5418045 },
    { lat: 49.1247716, lng: 18.542146 },
    { lat: 49.1250903, lng: 18.5430527 },
    { lat: 49.1254821, lng: 18.5434796 },
    { lat: 49.1257687, lng: 18.5437735 },
    { lat: 49.1263588, lng: 18.5441309 },
    { lat: 49.1264802, lng: 18.545292 },
    { lat: 49.1265562, lng: 18.5464077 },
    { lat: 49.12643, lng: 18.5471183 },
    { lat: 49.1265369, lng: 18.547783 },
    { lat: 49.1267014, lng: 18.5486371 },
    { lat: 49.1268404, lng: 18.549225 },
    { lat: 49.1269047, lng: 18.5493733 },
    { lat: 49.1269348, lng: 18.5494262 },
    { lat: 49.126964, lng: 18.5494883 },
    { lat: 49.1269868, lng: 18.549534 },
    { lat: 49.1272588, lng: 18.5500607 },
    { lat: 49.1275881, lng: 18.5504769 },
    { lat: 49.1278698, lng: 18.5507234 },
    { lat: 49.1280518, lng: 18.5509185 },
    { lat: 49.1283865, lng: 18.551416 },
    { lat: 49.1285036, lng: 18.5514946 },
    { lat: 49.1291343, lng: 18.5510615 },
    { lat: 49.1294012, lng: 18.5509903 },
    { lat: 49.1297925, lng: 18.550988 },
    { lat: 49.1300249, lng: 18.5509258 },
    { lat: 49.1301881, lng: 18.550859 },
    { lat: 49.1306514, lng: 18.5509362 },
    { lat: 49.1308607, lng: 18.5508851 },
    { lat: 49.1310765, lng: 18.5508105 },
    { lat: 49.131336, lng: 18.5506013 },
    { lat: 49.1317598, lng: 18.550387 },
    { lat: 49.1321309, lng: 18.5499713 },
    { lat: 49.1322905, lng: 18.5498753 },
    { lat: 49.1325845, lng: 18.549954 },
    { lat: 49.132774, lng: 18.5498283 },
    { lat: 49.1332278, lng: 18.5497304 },
    { lat: 49.1333685, lng: 18.5495168 },
    { lat: 49.1334648, lng: 18.5494626 },
    { lat: 49.1339074, lng: 18.5494074 },
    { lat: 49.1343247, lng: 18.5493046 },
    { lat: 49.1346351, lng: 18.5494352 },
    { lat: 49.1347881, lng: 18.5496545 },
    { lat: 49.1349416, lng: 18.5496563 },
    { lat: 49.1351946, lng: 18.5501032 },
    { lat: 49.1354208, lng: 18.5503443 },
    { lat: 49.1357088, lng: 18.5506855 },
    { lat: 49.1359988, lng: 18.5507941 },
    { lat: 49.1362599, lng: 18.5506142 },
    { lat: 49.1365327, lng: 18.5506548 },
    { lat: 49.1369263, lng: 18.5507571 },
    { lat: 49.1373339, lng: 18.5509557 },
    { lat: 49.1379416, lng: 18.5509716 },
    { lat: 49.1383132, lng: 18.5507517 },
    { lat: 49.138661, lng: 18.5507171 },
    { lat: 49.1388437, lng: 18.5508426 },
    { lat: 49.1393351, lng: 18.5506477 },
    { lat: 49.1395597, lng: 18.5502341 },
    { lat: 49.1398173, lng: 18.550075 },
    { lat: 49.1402453, lng: 18.5496245 },
    { lat: 49.1401075, lng: 18.5493479 },
    { lat: 49.1400016, lng: 18.5490818 },
    { lat: 49.1399773, lng: 18.5490276 },
    { lat: 49.1398813, lng: 18.5487952 },
    { lat: 49.1397806, lng: 18.5484554 },
    { lat: 49.1396271, lng: 18.5482237 },
    { lat: 49.1395942, lng: 18.5481776 },
    { lat: 49.1394024, lng: 18.5479686 },
    { lat: 49.1392477, lng: 18.5475257 },
    { lat: 49.1389434, lng: 18.5470907 },
    { lat: 49.1387488, lng: 18.5469771 },
    { lat: 49.1384519, lng: 18.5469276 },
    { lat: 49.1383189, lng: 18.5467433 },
    { lat: 49.1381078, lng: 18.5465515 },
    { lat: 49.1378279, lng: 18.5462757 },
    { lat: 49.1375059, lng: 18.545868 },
    { lat: 49.1374438, lng: 18.5457022 },
    { lat: 49.1373325, lng: 18.5451445 },
    { lat: 49.1376628, lng: 18.5450373 },
    { lat: 49.1377902, lng: 18.5450145 },
    { lat: 49.1379555, lng: 18.5449233 },
    { lat: 49.1381909, lng: 18.5447475 },
    { lat: 49.1384693, lng: 18.5446632 },
    { lat: 49.1385095, lng: 18.5445925 },
    { lat: 49.1387647, lng: 18.5439518 },
    { lat: 49.1388924, lng: 18.5435997 },
    { lat: 49.1390281, lng: 18.5428844 },
    { lat: 49.1390434, lng: 18.5428233 },
    { lat: 49.1392579, lng: 18.5421263 },
    { lat: 49.1392687, lng: 18.542091 },
    { lat: 49.139402, lng: 18.542072 },
    { lat: 49.1394533, lng: 18.5417716 },
    { lat: 49.1395416, lng: 18.5412538 },
    { lat: 49.1395559, lng: 18.5411231 },
    { lat: 49.1395991, lng: 18.5404624 },
    { lat: 49.1396036, lng: 18.5403981 },
    { lat: 49.1396057, lng: 18.5403535 },
    { lat: 49.1396075, lng: 18.5402214 },
    { lat: 49.139157, lng: 18.538858 },
    { lat: 49.1390945, lng: 18.5382209 },
    { lat: 49.1393149, lng: 18.5374798 },
    { lat: 49.1395689, lng: 18.5367332 },
    { lat: 49.139775, lng: 18.5364167 },
    { lat: 49.1399031, lng: 18.5362178 },
    { lat: 49.1400155, lng: 18.5361922 },
    { lat: 49.1402561, lng: 18.5360713 },
    { lat: 49.1404909, lng: 18.5361798 },
    { lat: 49.1406032, lng: 18.5361089 },
    { lat: 49.1406412, lng: 18.5358515 },
    { lat: 49.1405773, lng: 18.5351713 },
    { lat: 49.1406871, lng: 18.5349285 },
    { lat: 49.1406528, lng: 18.5340025 },
    { lat: 49.1406908, lng: 18.5339972 },
    { lat: 49.1408655, lng: 18.5332236 },
    { lat: 49.1412355, lng: 18.532516 },
    { lat: 49.1412918, lng: 18.532202 },
    { lat: 49.1413457, lng: 18.5319069 },
    { lat: 49.1414379, lng: 18.5313998 },
    { lat: 49.1416743, lng: 18.5304748 },
    { lat: 49.141898, lng: 18.5297314 },
    { lat: 49.1422304, lng: 18.5289761 },
    { lat: 49.1424851, lng: 18.5284534 },
    { lat: 49.1426101, lng: 18.5282827 },
    { lat: 49.1427623, lng: 18.5275537 },
    { lat: 49.1427514, lng: 18.5275603 },
    { lat: 49.1422861, lng: 18.5273404 },
    { lat: 49.1419453, lng: 18.5273152 },
    { lat: 49.1417061, lng: 18.5273372 },
    { lat: 49.1414206, lng: 18.5273731 },
    { lat: 49.1409754, lng: 18.5276518 },
    { lat: 49.140837, lng: 18.5276972 },
    { lat: 49.1403348, lng: 18.5270891 },
    { lat: 49.1397869, lng: 18.5268641 },
    { lat: 49.1394274, lng: 18.526829 },
    { lat: 49.139291, lng: 18.5271208 },
    { lat: 49.1390463, lng: 18.5277683 },
    { lat: 49.1389311, lng: 18.5280986 },
    { lat: 49.1387347, lng: 18.5278886 },
    { lat: 49.1385875, lng: 18.5277512 },
    { lat: 49.1383596, lng: 18.5275842 },
    { lat: 49.1380889, lng: 18.5273572 },
    { lat: 49.1378282, lng: 18.5279148 },
    { lat: 49.1376546, lng: 18.5283122 },
    { lat: 49.1375361, lng: 18.5285823 },
    { lat: 49.1373599, lng: 18.5290148 },
    { lat: 49.1372929, lng: 18.5291723 },
    { lat: 49.1372125, lng: 18.5292491 },
    { lat: 49.1371207, lng: 18.5293651 },
    { lat: 49.137004, lng: 18.5295019 },
    { lat: 49.1368006, lng: 18.5295394 },
    { lat: 49.1368023, lng: 18.5295691 },
    { lat: 49.1368012, lng: 18.5296111 },
    { lat: 49.1367993, lng: 18.5296375 },
    { lat: 49.136815, lng: 18.5299489 },
    { lat: 49.1368023, lng: 18.5301504 },
    { lat: 49.1365374, lng: 18.5300076 },
    { lat: 49.1363913, lng: 18.529915 },
    { lat: 49.1362984, lng: 18.5299487 },
    { lat: 49.136086, lng: 18.5296087 },
    { lat: 49.1359422, lng: 18.5295152 },
    { lat: 49.1359607, lng: 18.5293788 },
    { lat: 49.1359646, lng: 18.5293236 },
    { lat: 49.1359744, lng: 18.5292761 },
    { lat: 49.1359754, lng: 18.5292311 },
    { lat: 49.1360256, lng: 18.5287439 },
    { lat: 49.1358864, lng: 18.5282784 },
    { lat: 49.1357384, lng: 18.5278759 },
    { lat: 49.1355761, lng: 18.5277748 },
    { lat: 49.1355204, lng: 18.5277045 },
    { lat: 49.1354, lng: 18.5275009 },
    { lat: 49.135081, lng: 18.5270881 },
    { lat: 49.1348136, lng: 18.5267109 },
    { lat: 49.1346624, lng: 18.5264816 },
    { lat: 49.1345493, lng: 18.5263309 },
    { lat: 49.1342263, lng: 18.5260517 },
    { lat: 49.1341152, lng: 18.5258551 },
    { lat: 49.1340457, lng: 18.5257394 },
    { lat: 49.1337729, lng: 18.5254294 },
    { lat: 49.1332983, lng: 18.5251625 },
    { lat: 49.133135, lng: 18.5250633 },
    { lat: 49.132941, lng: 18.5248977 },
    { lat: 49.1327029, lng: 18.5246235 },
    { lat: 49.1324673, lng: 18.5243555 },
    { lat: 49.1312487, lng: 18.5231397 },
    { lat: 49.1312094, lng: 18.5231016 },
    { lat: 49.1311652, lng: 18.5230571 },
    { lat: 49.1303563, lng: 18.521888 },
    { lat: 49.1303213, lng: 18.521805 },
    { lat: 49.1296346, lng: 18.520312 },
    { lat: 49.128976, lng: 18.5195859 },
    { lat: 49.1287389, lng: 18.5193527 },
    { lat: 49.128099, lng: 18.5184951 },
    { lat: 49.1276615, lng: 18.5177893 },
    { lat: 49.1272732, lng: 18.5171402 },
    { lat: 49.1270924, lng: 18.5166561 },
    { lat: 49.1269646, lng: 18.5156943 },
    { lat: 49.1269065, lng: 18.5149141 },
    { lat: 49.1267594, lng: 18.5143251 },
    { lat: 49.1266412, lng: 18.5138506 },
    { lat: 49.1266682, lng: 18.5132895 },
    { lat: 49.1268585, lng: 18.5125157 },
    { lat: 49.1269187, lng: 18.5121506 },
    { lat: 49.1268512, lng: 18.511821 },
    { lat: 49.1267777, lng: 18.5114634 },
    { lat: 49.1262153, lng: 18.5118453 },
    { lat: 49.1258193, lng: 18.5119693 },
    { lat: 49.1256933, lng: 18.5120358 },
    { lat: 49.1254554, lng: 18.5124762 },
    { lat: 49.1252679, lng: 18.5129875 },
  ],
  PlevníkDrienové: [
    { lat: 49.181097, lng: 18.487831 },
    { lat: 49.181108, lng: 18.487806 },
    { lat: 49.18115, lng: 18.487709 },
    { lat: 49.181184, lng: 18.48763 },
    { lat: 49.181216, lng: 18.487555 },
    { lat: 49.181225, lng: 18.487534 },
    { lat: 49.181527, lng: 18.486839 },
    { lat: 49.181636, lng: 18.486587 },
    { lat: 49.1815912, lng: 18.4861274 },
    { lat: 49.1814954, lng: 18.485589 },
    { lat: 49.1811828, lng: 18.4845124 },
    { lat: 49.1809446, lng: 18.4838224 },
    { lat: 49.1807385, lng: 18.4833957 },
    { lat: 49.1804495, lng: 18.482875 },
    { lat: 49.1801718, lng: 18.482413 },
    { lat: 49.1797779, lng: 18.481893 },
    { lat: 49.1793869, lng: 18.4815473 },
    { lat: 49.1789753, lng: 18.4811771 },
    { lat: 49.1788412, lng: 18.4810784 },
    { lat: 49.1786627, lng: 18.4809468 },
    { lat: 49.1782745, lng: 18.4807383 },
    { lat: 49.1778818, lng: 18.4805689 },
    { lat: 49.1773147, lng: 18.4804076 },
    { lat: 49.1770101, lng: 18.4803852 },
    { lat: 49.1764472, lng: 18.4803439 },
    { lat: 49.1756272, lng: 18.4805309 },
    { lat: 49.1755012, lng: 18.4805977 },
    { lat: 49.1743865, lng: 18.4809443 },
    { lat: 49.1741592, lng: 18.4808718 },
    { lat: 49.174053, lng: 18.4808491 },
    { lat: 49.1740152, lng: 18.4808577 },
    { lat: 49.173348, lng: 18.4810116 },
    { lat: 49.172556, lng: 18.4808226 },
    { lat: 49.1723227, lng: 18.4807851 },
    { lat: 49.1720351, lng: 18.4807239 },
    { lat: 49.1717636, lng: 18.4806668 },
    { lat: 49.1716507, lng: 18.4806429 },
    { lat: 49.1715286, lng: 18.4805777 },
    { lat: 49.171316, lng: 18.4804644 },
    { lat: 49.1711765, lng: 18.4803875 },
    { lat: 49.1711386, lng: 18.4803561 },
    { lat: 49.1708063, lng: 18.4800933 },
    { lat: 49.1707639, lng: 18.480061 },
    { lat: 49.1706035, lng: 18.4799334 },
    { lat: 49.1698765, lng: 18.4792605 },
    { lat: 49.1695479, lng: 18.4788605 },
    { lat: 49.1695077, lng: 18.4788286 },
    { lat: 49.1693214, lng: 18.4786174 },
    { lat: 49.1687802, lng: 18.4777543 },
    { lat: 49.1687447, lng: 18.4777232 },
    { lat: 49.1684471, lng: 18.477456 },
    { lat: 49.1675159, lng: 18.4766195 },
    { lat: 49.1674227, lng: 18.4767725 },
    { lat: 49.167371, lng: 18.4768585 },
    { lat: 49.1669809, lng: 18.4764681 },
    { lat: 49.1666587, lng: 18.4761448 },
    { lat: 49.1666002, lng: 18.4760857 },
    { lat: 49.1663759, lng: 18.4758612 },
    { lat: 49.1661672, lng: 18.4770582 },
    { lat: 49.1640501, lng: 18.4729086 },
    { lat: 49.1638065, lng: 18.4720839 },
    { lat: 49.1637637, lng: 18.4719398 },
    { lat: 49.1637165, lng: 18.4717793 },
    { lat: 49.1636458, lng: 18.4718047 },
    { lat: 49.1627381, lng: 18.4720997 },
    { lat: 49.1617972, lng: 18.4724059 },
    { lat: 49.1617063, lng: 18.4724354 },
    { lat: 49.1615844, lng: 18.4724754 },
    { lat: 49.1613671, lng: 18.4725461 },
    { lat: 49.1612688, lng: 18.4727696 },
    { lat: 49.1612297, lng: 18.4728586 },
    { lat: 49.1612013, lng: 18.4729166 },
    { lat: 49.1609899, lng: 18.4733421 },
    { lat: 49.1609738, lng: 18.473376 },
    { lat: 49.1609574, lng: 18.4734082 },
    { lat: 49.1606985, lng: 18.4739337 },
    { lat: 49.1606895, lng: 18.4739529 },
    { lat: 49.1605301, lng: 18.4742773 },
    { lat: 49.1601493, lng: 18.4750497 },
    { lat: 49.1600951, lng: 18.4751117 },
    { lat: 49.1600512, lng: 18.4751748 },
    { lat: 49.1601845, lng: 18.4755298 },
    { lat: 49.1603463, lng: 18.4761585 },
    { lat: 49.1605358, lng: 18.4768194 },
    { lat: 49.1609341, lng: 18.4775866 },
    { lat: 49.1608964, lng: 18.4776399 },
    { lat: 49.160866, lng: 18.4776821 },
    { lat: 49.1608367, lng: 18.4777242 },
    { lat: 49.1608106, lng: 18.4777605 },
    { lat: 49.1607979, lng: 18.4777785 },
    { lat: 49.1607774, lng: 18.4778159 },
    { lat: 49.160724, lng: 18.4779117 },
    { lat: 49.1606671, lng: 18.4780467 },
    { lat: 49.1606629, lng: 18.478055 },
    { lat: 49.1595064, lng: 18.4807733 },
    { lat: 49.1597601, lng: 18.4813929 },
    { lat: 49.1590742, lng: 18.4803995 },
    { lat: 49.1587152, lng: 18.48058 },
    { lat: 49.1582904, lng: 18.4805668 },
    { lat: 49.1581277, lng: 18.4810354 },
    { lat: 49.158306, lng: 18.4818031 },
    { lat: 49.1581198, lng: 18.4816559 },
    { lat: 49.1578924, lng: 18.4829459 },
    { lat: 49.1578567, lng: 18.4835116 },
    { lat: 49.1576913, lng: 18.4840983 },
    { lat: 49.1573777, lng: 18.4844725 },
    { lat: 49.1574169, lng: 18.4848171 },
    { lat: 49.1573664, lng: 18.4853387 },
    { lat: 49.1574786, lng: 18.4857411 },
    { lat: 49.1574686, lng: 18.4860984 },
    { lat: 49.1572569, lng: 18.4889588 },
    { lat: 49.1562699, lng: 18.4887304 },
    { lat: 49.1561964, lng: 18.4898038 },
    { lat: 49.1559661, lng: 18.491248 },
    { lat: 49.155547, lng: 18.4923749 },
    { lat: 49.1551607, lng: 18.4931314 },
    { lat: 49.155126, lng: 18.4931999 },
    { lat: 49.1548926, lng: 18.4936352 },
    { lat: 49.154868, lng: 18.4936814 },
    { lat: 49.1548208, lng: 18.4937661 },
    { lat: 49.154438, lng: 18.494131 },
    { lat: 49.1543881, lng: 18.4942086 },
    { lat: 49.1541859, lng: 18.4944105 },
    { lat: 49.1541402, lng: 18.4944606 },
    { lat: 49.1533931, lng: 18.4954291 },
    { lat: 49.152931, lng: 18.4960362 },
    { lat: 49.1526133, lng: 18.4965178 },
    { lat: 49.1525607, lng: 18.496691 },
    { lat: 49.1525619, lng: 18.4967978 },
    { lat: 49.1523932, lng: 18.4978014 },
    { lat: 49.1523656, lng: 18.498019 },
    { lat: 49.1523247, lng: 18.4983023 },
    { lat: 49.1521872, lng: 18.4990316 },
    { lat: 49.1520919, lng: 18.4993735 },
    { lat: 49.1520709, lng: 18.4997336 },
    { lat: 49.152072, lng: 18.5000243 },
    { lat: 49.1520893, lng: 18.5004037 },
    { lat: 49.1520881, lng: 18.5008175 },
    { lat: 49.15198, lng: 18.5011126 },
    { lat: 49.1518043, lng: 18.5017174 },
    { lat: 49.1517186, lng: 18.502152 },
    { lat: 49.1515123, lng: 18.5027143 },
    { lat: 49.1514912, lng: 18.5027625 },
    { lat: 49.1518052, lng: 18.5036002 },
    { lat: 49.1523808, lng: 18.5047272 },
    { lat: 49.1532942, lng: 18.5061337 },
    { lat: 49.1526413, lng: 18.5068622 },
    { lat: 49.1523449, lng: 18.5073447 },
    { lat: 49.1511549, lng: 18.5089419 },
    { lat: 49.1508525, lng: 18.5095213 },
    { lat: 49.1506692, lng: 18.5100715 },
    { lat: 49.150761, lng: 18.5101205 },
    { lat: 49.1506833, lng: 18.5108297 },
    { lat: 49.1504147, lng: 18.5120935 },
    { lat: 49.1504028, lng: 18.5136408 },
    { lat: 49.1503633, lng: 18.5140545 },
    { lat: 49.1502635, lng: 18.5141291 },
    { lat: 49.1502073, lng: 18.5142767 },
    { lat: 49.1501397, lng: 18.514342 },
    { lat: 49.1500861, lng: 18.5144558 },
    { lat: 49.1499679, lng: 18.5146254 },
    { lat: 49.1497743, lng: 18.514634 },
    { lat: 49.1495329, lng: 18.5151321 },
    { lat: 49.1493751, lng: 18.5154655 },
    { lat: 49.1492421, lng: 18.5158408 },
    { lat: 49.1492281, lng: 18.5158957 },
    { lat: 49.1491853, lng: 18.5158341 },
    { lat: 49.1487608, lng: 18.5159516 },
    { lat: 49.1485153, lng: 18.5160827 },
    { lat: 49.1476834, lng: 18.5165097 },
    { lat: 49.1472099, lng: 18.517426 },
    { lat: 49.1472564, lng: 18.5179528 },
    { lat: 49.1469519, lng: 18.519615 },
    { lat: 49.1467965, lng: 18.5197052 },
    { lat: 49.1467282, lng: 18.5201484 },
    { lat: 49.1466877, lng: 18.5202767 },
    { lat: 49.1466757, lng: 18.520325 },
    { lat: 49.1465209, lng: 18.5207765 },
    { lat: 49.1462861, lng: 18.5216902 },
    { lat: 49.1461715, lng: 18.5221341 },
    { lat: 49.1460153, lng: 18.5227476 },
    { lat: 49.1458405, lng: 18.5227067 },
    { lat: 49.1455925, lng: 18.5230427 },
    { lat: 49.1454095, lng: 18.5233908 },
    { lat: 49.1452834, lng: 18.523748 },
    { lat: 49.1451014, lng: 18.5242595 },
    { lat: 49.1444915, lng: 18.5247245 },
    { lat: 49.1444093, lng: 18.5253309 },
    { lat: 49.1444072, lng: 18.5253747 },
    { lat: 49.1441231, lng: 18.5255017 },
    { lat: 49.1435272, lng: 18.5262025 },
    { lat: 49.1433473, lng: 18.5262125 },
    { lat: 49.1433099, lng: 18.5263343 },
    { lat: 49.1431077, lng: 18.5264945 },
    { lat: 49.1428716, lng: 18.5264894 },
    { lat: 49.1427623, lng: 18.5275537 },
    { lat: 49.1426101, lng: 18.5282827 },
    { lat: 49.1424851, lng: 18.5284534 },
    { lat: 49.1422304, lng: 18.5289761 },
    { lat: 49.141898, lng: 18.5297314 },
    { lat: 49.1416743, lng: 18.5304748 },
    { lat: 49.1414379, lng: 18.5313998 },
    { lat: 49.1413457, lng: 18.5319069 },
    { lat: 49.1412918, lng: 18.532202 },
    { lat: 49.1412355, lng: 18.532516 },
    { lat: 49.1408655, lng: 18.5332236 },
    { lat: 49.1406908, lng: 18.5339972 },
    { lat: 49.1406528, lng: 18.5340025 },
    { lat: 49.1406871, lng: 18.5349285 },
    { lat: 49.1405773, lng: 18.5351713 },
    { lat: 49.1406412, lng: 18.5358515 },
    { lat: 49.1406032, lng: 18.5361089 },
    { lat: 49.1404909, lng: 18.5361798 },
    { lat: 49.1402561, lng: 18.5360713 },
    { lat: 49.1400155, lng: 18.5361922 },
    { lat: 49.1399031, lng: 18.5362178 },
    { lat: 49.139775, lng: 18.5364167 },
    { lat: 49.1395689, lng: 18.5367332 },
    { lat: 49.1393149, lng: 18.5374798 },
    { lat: 49.1390945, lng: 18.5382209 },
    { lat: 49.139157, lng: 18.538858 },
    { lat: 49.1396075, lng: 18.5402214 },
    { lat: 49.1396057, lng: 18.5403535 },
    { lat: 49.1396036, lng: 18.5403981 },
    { lat: 49.1395991, lng: 18.5404624 },
    { lat: 49.1395559, lng: 18.5411231 },
    { lat: 49.1395416, lng: 18.5412538 },
    { lat: 49.1394533, lng: 18.5417716 },
    { lat: 49.139402, lng: 18.542072 },
    { lat: 49.1392687, lng: 18.542091 },
    { lat: 49.1392579, lng: 18.5421263 },
    { lat: 49.1390434, lng: 18.5428233 },
    { lat: 49.1390281, lng: 18.5428844 },
    { lat: 49.1388924, lng: 18.5435997 },
    { lat: 49.1387647, lng: 18.5439518 },
    { lat: 49.1385095, lng: 18.5445925 },
    { lat: 49.1384693, lng: 18.5446632 },
    { lat: 49.1381909, lng: 18.5447475 },
    { lat: 49.1379555, lng: 18.5449233 },
    { lat: 49.1377902, lng: 18.5450145 },
    { lat: 49.1376628, lng: 18.5450373 },
    { lat: 49.1373325, lng: 18.5451445 },
    { lat: 49.1374438, lng: 18.5457022 },
    { lat: 49.1375059, lng: 18.545868 },
    { lat: 49.1378279, lng: 18.5462757 },
    { lat: 49.1381078, lng: 18.5465515 },
    { lat: 49.1383189, lng: 18.5467433 },
    { lat: 49.1384519, lng: 18.5469276 },
    { lat: 49.1387488, lng: 18.5469771 },
    { lat: 49.1389434, lng: 18.5470907 },
    { lat: 49.1392477, lng: 18.5475257 },
    { lat: 49.1394024, lng: 18.5479686 },
    { lat: 49.1395942, lng: 18.5481776 },
    { lat: 49.1396271, lng: 18.5482237 },
    { lat: 49.1397806, lng: 18.5484554 },
    { lat: 49.1398813, lng: 18.5487952 },
    { lat: 49.1399773, lng: 18.5490276 },
    { lat: 49.1400016, lng: 18.5490818 },
    { lat: 49.1401075, lng: 18.5493479 },
    { lat: 49.1402453, lng: 18.5496245 },
    { lat: 49.140786, lng: 18.5500094 },
    { lat: 49.1415256, lng: 18.5503992 },
    { lat: 49.1425672, lng: 18.5505573 },
    { lat: 49.1431702, lng: 18.5505092 },
    { lat: 49.1435508, lng: 18.5506 },
    { lat: 49.1439325, lng: 18.5508198 },
    { lat: 49.1445396, lng: 18.5508665 },
    { lat: 49.145167, lng: 18.5511868 },
    { lat: 49.1454747, lng: 18.5514341 },
    { lat: 49.1458492, lng: 18.5527079 },
    { lat: 49.1460555, lng: 18.5533348 },
    { lat: 49.146777, lng: 18.553361 },
    { lat: 49.147679, lng: 18.553397 },
    { lat: 49.148807, lng: 18.552796 },
    { lat: 49.149043, lng: 18.552765 },
    { lat: 49.150124, lng: 18.553074 },
    { lat: 49.150495, lng: 18.55346 },
    { lat: 49.151076, lng: 18.553707 },
    { lat: 49.152592, lng: 18.554063 },
    { lat: 49.152913, lng: 18.554395 },
    { lat: 49.153605, lng: 18.554827 },
    { lat: 49.153748, lng: 18.554805 },
    { lat: 49.154207, lng: 18.554872 },
    { lat: 49.154833, lng: 18.555127 },
    { lat: 49.155421, lng: 18.553425 },
    { lat: 49.156177, lng: 18.552173 },
    { lat: 49.156422, lng: 18.551583 },
    { lat: 49.158011, lng: 18.548141 },
    { lat: 49.157286, lng: 18.547546 },
    { lat: 49.157295, lng: 18.547311 },
    { lat: 49.157843, lng: 18.546874 },
    { lat: 49.157942, lng: 18.5465 },
    { lat: 49.157969, lng: 18.546456 },
    { lat: 49.158254, lng: 18.54493 },
    { lat: 49.158259, lng: 18.544738 },
    { lat: 49.158271, lng: 18.544238 },
    { lat: 49.158419, lng: 18.543706 },
    { lat: 49.158398, lng: 18.54352 },
    { lat: 49.158381, lng: 18.543384 },
    { lat: 49.158372, lng: 18.542834 },
    { lat: 49.158346, lng: 18.542553 },
    { lat: 49.158596, lng: 18.541668 },
    { lat: 49.158797, lng: 18.540956 },
    { lat: 49.158841, lng: 18.540072 },
    { lat: 49.158965, lng: 18.539706 },
    { lat: 49.159104, lng: 18.539334 },
    { lat: 49.159692, lng: 18.538734 },
    { lat: 49.159938, lng: 18.537694 },
    { lat: 49.160014, lng: 18.537539 },
    { lat: 49.160212, lng: 18.537299 },
    { lat: 49.160591, lng: 18.537003 },
    { lat: 49.161402, lng: 18.536543 },
    { lat: 49.1618, lng: 18.536418 },
    { lat: 49.162409, lng: 18.536105 },
    { lat: 49.162506, lng: 18.535689 },
    { lat: 49.162621, lng: 18.535429 },
    { lat: 49.162874, lng: 18.534886 },
    { lat: 49.163564, lng: 18.534487 },
    { lat: 49.163697, lng: 18.534264 },
    { lat: 49.164078, lng: 18.534045 },
    { lat: 49.164206, lng: 18.533767 },
    { lat: 49.16424, lng: 18.532899 },
    { lat: 49.164174, lng: 18.531947 },
    { lat: 49.164036, lng: 18.531115 },
    { lat: 49.163845, lng: 18.530358 },
    { lat: 49.163464, lng: 18.529046 },
    { lat: 49.16336, lng: 18.528686 },
    { lat: 49.163361, lng: 18.528452 },
    { lat: 49.163407, lng: 18.527823 },
    { lat: 49.163417, lng: 18.527744 },
    { lat: 49.163478, lng: 18.527114 },
    { lat: 49.163509, lng: 18.526681 },
    { lat: 49.163525, lng: 18.526526 },
    { lat: 49.163545, lng: 18.526375 },
    { lat: 49.163571, lng: 18.526154 },
    { lat: 49.163644, lng: 18.525781 },
    { lat: 49.163757, lng: 18.525226 },
    { lat: 49.163781, lng: 18.524686 },
    { lat: 49.163816, lng: 18.524153 },
    { lat: 49.164059, lng: 18.523303 },
    { lat: 49.164266, lng: 18.522695 },
    { lat: 49.164467, lng: 18.522212 },
    { lat: 49.164759, lng: 18.521596 },
    { lat: 49.165076, lng: 18.52093 },
    { lat: 49.165404, lng: 18.520091 },
    { lat: 49.165788, lng: 18.519145 },
    { lat: 49.165961, lng: 18.518502 },
    { lat: 49.166068, lng: 18.518112 },
    { lat: 49.166322, lng: 18.517352 },
    { lat: 49.166437, lng: 18.517091 },
    { lat: 49.166976, lng: 18.516266 },
    { lat: 49.16794, lng: 18.515214 },
    { lat: 49.168108, lng: 18.514923 },
    { lat: 49.169246, lng: 18.514091 },
    { lat: 49.169554, lng: 18.51407 },
    { lat: 49.170352, lng: 18.513953 },
    { lat: 49.170384, lng: 18.51392 },
    { lat: 49.170655, lng: 18.513582 },
    { lat: 49.170823, lng: 18.513478 },
    { lat: 49.171084, lng: 18.513062 },
    { lat: 49.171551, lng: 18.512881 },
    { lat: 49.17173, lng: 18.512636 },
    { lat: 49.17173, lng: 18.51244 },
    { lat: 49.171765, lng: 18.512186 },
    { lat: 49.171765, lng: 18.512011 },
    { lat: 49.172, lng: 18.511764 },
    { lat: 49.172172, lng: 18.511704 },
    { lat: 49.172384, lng: 18.511676 },
    { lat: 49.172658, lng: 18.511552 },
    { lat: 49.172753, lng: 18.511434 },
    { lat: 49.172814, lng: 18.511314 },
    { lat: 49.172809, lng: 18.511214 },
    { lat: 49.172827, lng: 18.51111 },
    { lat: 49.172835, lng: 18.510812 },
    { lat: 49.173007, lng: 18.510222 },
    { lat: 49.173235, lng: 18.509988 },
    { lat: 49.173453, lng: 18.509497 },
    { lat: 49.173518, lng: 18.509381 },
    { lat: 49.173878, lng: 18.508982 },
    { lat: 49.173706, lng: 18.508533 },
    { lat: 49.173477, lng: 18.508222 },
    { lat: 49.173615, lng: 18.507916 },
    { lat: 49.173899, lng: 18.508141 },
    { lat: 49.174111, lng: 18.508403 },
    { lat: 49.174198, lng: 18.508301 },
    { lat: 49.17447, lng: 18.50793 },
    { lat: 49.17486, lng: 18.508958 },
    { lat: 49.175025, lng: 18.508836 },
    { lat: 49.175122, lng: 18.508765 },
    { lat: 49.175415, lng: 18.508499 },
    { lat: 49.175849, lng: 18.508106 },
    { lat: 49.175601, lng: 18.507331 },
    { lat: 49.175542, lng: 18.507131 },
    { lat: 49.175451, lng: 18.50675 },
    { lat: 49.17548, lng: 18.506747 },
    { lat: 49.175543, lng: 18.506722 },
    { lat: 49.175561, lng: 18.506717 },
    { lat: 49.175589, lng: 18.506685 },
    { lat: 49.17564, lng: 18.506668 },
    { lat: 49.175896, lng: 18.506582 },
    { lat: 49.175942, lng: 18.506436 },
    { lat: 49.175952, lng: 18.506217 },
    { lat: 49.175818, lng: 18.505592 },
    { lat: 49.175792, lng: 18.50545 },
    { lat: 49.175791, lng: 18.505178 },
    { lat: 49.175809, lng: 18.504996 },
    { lat: 49.175839, lng: 18.504823 },
    { lat: 49.175911, lng: 18.504609 },
    { lat: 49.175953, lng: 18.504325 },
    { lat: 49.175993, lng: 18.504198 },
    { lat: 49.176067, lng: 18.504069 },
    { lat: 49.176284, lng: 18.503596 },
    { lat: 49.17636, lng: 18.503603 },
    { lat: 49.176503, lng: 18.503651 },
    { lat: 49.176584, lng: 18.50365 },
    { lat: 49.176653, lng: 18.503602 },
    { lat: 49.176745, lng: 18.503473 },
    { lat: 49.176799, lng: 18.50333 },
    { lat: 49.176756, lng: 18.503053 },
    { lat: 49.176803, lng: 18.502883 },
    { lat: 49.176811, lng: 18.502778 },
    { lat: 49.176869, lng: 18.502661 },
    { lat: 49.177063, lng: 18.502319 },
    { lat: 49.177194, lng: 18.502124 },
    { lat: 49.177233, lng: 18.501989 },
    { lat: 49.177219, lng: 18.501822 },
    { lat: 49.177145, lng: 18.501288 },
    { lat: 49.177148, lng: 18.501179 },
    { lat: 49.177163, lng: 18.501132 },
    { lat: 49.177231, lng: 18.501059 },
    { lat: 49.177246, lng: 18.501038 },
    { lat: 49.177259, lng: 18.501017 },
    { lat: 49.177293, lng: 18.500979 },
    { lat: 49.177327, lng: 18.500922 },
    { lat: 49.177339, lng: 18.500891 },
    { lat: 49.177357, lng: 18.500868 },
    { lat: 49.17735, lng: 18.500786 },
    { lat: 49.177356, lng: 18.500762 },
    { lat: 49.177321, lng: 18.500563 },
    { lat: 49.177246, lng: 18.500332 },
    { lat: 49.177185, lng: 18.500144 },
    { lat: 49.177275, lng: 18.500003 },
    { lat: 49.177277, lng: 18.499921 },
    { lat: 49.177192, lng: 18.499756 },
    { lat: 49.177165, lng: 18.499499 },
    { lat: 49.17716, lng: 18.499449 },
    { lat: 49.17713, lng: 18.499251 },
    { lat: 49.177102, lng: 18.498825 },
    { lat: 49.177104, lng: 18.498358 },
    { lat: 49.177113, lng: 18.498157 },
    { lat: 49.177119, lng: 18.497987 },
    { lat: 49.177151, lng: 18.49736 },
    { lat: 49.177219, lng: 18.496731 },
    { lat: 49.17748, lng: 18.496774 },
    { lat: 49.17761, lng: 18.496768 },
    { lat: 49.177713, lng: 18.496729 },
    { lat: 49.177746, lng: 18.496716 },
    { lat: 49.177856, lng: 18.496622 },
    { lat: 49.17781, lng: 18.496496 },
    { lat: 49.177786, lng: 18.496428 },
    { lat: 49.177931, lng: 18.496186 },
    { lat: 49.178072, lng: 18.495853 },
    { lat: 49.178076, lng: 18.495844 },
    { lat: 49.178291, lng: 18.495386 },
    { lat: 49.178632, lng: 18.494552 },
    { lat: 49.178759, lng: 18.49419 },
    { lat: 49.178942, lng: 18.493599 },
    { lat: 49.178994, lng: 18.493456 },
    { lat: 49.17921, lng: 18.492846 },
    { lat: 49.179292, lng: 18.492614 },
    { lat: 49.179626, lng: 18.491672 },
    { lat: 49.179774, lng: 18.491288 },
    { lat: 49.179976, lng: 18.490759 },
    { lat: 49.180027, lng: 18.490586 },
    { lat: 49.180079, lng: 18.490405 },
    { lat: 49.180242, lng: 18.48996 },
    { lat: 49.180284, lng: 18.489843 },
    { lat: 49.180311, lng: 18.489772 },
    { lat: 49.180406, lng: 18.48951 },
    { lat: 49.180428, lng: 18.489448 },
    { lat: 49.180701, lng: 18.488788 },
    { lat: 49.180764, lng: 18.488636 },
    { lat: 49.180811, lng: 18.488522 },
    { lat: 49.181005, lng: 18.488056 },
    { lat: 49.18103, lng: 18.487994 },
    { lat: 49.181038, lng: 18.487974 },
    { lat: 49.181052, lng: 18.48794 },
    { lat: 49.181097, lng: 18.487831 },
  ],
  Záskalie: [
    { lat: 49.1300255, lng: 18.5073225 },
    { lat: 49.1293549, lng: 18.5084118 },
    { lat: 49.1289635, lng: 18.5089287 },
    { lat: 49.1289182, lng: 18.5089895 },
    { lat: 49.1284955, lng: 18.5097823 },
    { lat: 49.1280206, lng: 18.5105267 },
    { lat: 49.1275906, lng: 18.5113954 },
    { lat: 49.12756, lng: 18.5114767 },
    { lat: 49.127466, lng: 18.5113031 },
    { lat: 49.1273205, lng: 18.5110335 },
    { lat: 49.1272816, lng: 18.5109597 },
    { lat: 49.1271963, lng: 18.5108025 },
    { lat: 49.126888, lng: 18.5110231 },
    { lat: 49.1267777, lng: 18.5114634 },
    { lat: 49.1268512, lng: 18.511821 },
    { lat: 49.1269187, lng: 18.5121506 },
    { lat: 49.1268585, lng: 18.5125157 },
    { lat: 49.1266682, lng: 18.5132895 },
    { lat: 49.1266412, lng: 18.5138506 },
    { lat: 49.1267594, lng: 18.5143251 },
    { lat: 49.1269065, lng: 18.5149141 },
    { lat: 49.1269646, lng: 18.5156943 },
    { lat: 49.1270924, lng: 18.5166561 },
    { lat: 49.1272732, lng: 18.5171402 },
    { lat: 49.1276615, lng: 18.5177893 },
    { lat: 49.128099, lng: 18.5184951 },
    { lat: 49.1287389, lng: 18.5193527 },
    { lat: 49.128976, lng: 18.5195859 },
    { lat: 49.1296346, lng: 18.520312 },
    { lat: 49.1303213, lng: 18.521805 },
    { lat: 49.1303563, lng: 18.521888 },
    { lat: 49.1311652, lng: 18.5230571 },
    { lat: 49.1312094, lng: 18.5231016 },
    { lat: 49.1312487, lng: 18.5231397 },
    { lat: 49.1324673, lng: 18.5243555 },
    { lat: 49.1327029, lng: 18.5246235 },
    { lat: 49.132941, lng: 18.5248977 },
    { lat: 49.133135, lng: 18.5250633 },
    { lat: 49.1332983, lng: 18.5251625 },
    { lat: 49.1337729, lng: 18.5254294 },
    { lat: 49.1340457, lng: 18.5257394 },
    { lat: 49.1341152, lng: 18.5258551 },
    { lat: 49.1342263, lng: 18.5260517 },
    { lat: 49.1345493, lng: 18.5263309 },
    { lat: 49.1346624, lng: 18.5264816 },
    { lat: 49.1348136, lng: 18.5267109 },
    { lat: 49.135081, lng: 18.5270881 },
    { lat: 49.1354, lng: 18.5275009 },
    { lat: 49.1355204, lng: 18.5277045 },
    { lat: 49.1355761, lng: 18.5277748 },
    { lat: 49.1357384, lng: 18.5278759 },
    { lat: 49.1358864, lng: 18.5282784 },
    { lat: 49.1360256, lng: 18.5287439 },
    { lat: 49.1359754, lng: 18.5292311 },
    { lat: 49.1359744, lng: 18.5292761 },
    { lat: 49.1359646, lng: 18.5293236 },
    { lat: 49.1359607, lng: 18.5293788 },
    { lat: 49.1359422, lng: 18.5295152 },
    { lat: 49.136086, lng: 18.5296087 },
    { lat: 49.1362984, lng: 18.5299487 },
    { lat: 49.1363913, lng: 18.529915 },
    { lat: 49.1365374, lng: 18.5300076 },
    { lat: 49.1368023, lng: 18.5301504 },
    { lat: 49.136815, lng: 18.5299489 },
    { lat: 49.1367993, lng: 18.5296375 },
    { lat: 49.1368012, lng: 18.5296111 },
    { lat: 49.1368023, lng: 18.5295691 },
    { lat: 49.1368006, lng: 18.5295394 },
    { lat: 49.137004, lng: 18.5295019 },
    { lat: 49.1371207, lng: 18.5293651 },
    { lat: 49.1372125, lng: 18.5292491 },
    { lat: 49.1372929, lng: 18.5291723 },
    { lat: 49.1373599, lng: 18.5290148 },
    { lat: 49.1375361, lng: 18.5285823 },
    { lat: 49.1376546, lng: 18.5283122 },
    { lat: 49.1378282, lng: 18.5279148 },
    { lat: 49.1380889, lng: 18.5273572 },
    { lat: 49.1383596, lng: 18.5275842 },
    { lat: 49.1385875, lng: 18.5277512 },
    { lat: 49.1387347, lng: 18.5278886 },
    { lat: 49.1389311, lng: 18.5280986 },
    { lat: 49.1390463, lng: 18.5277683 },
    { lat: 49.139291, lng: 18.5271208 },
    { lat: 49.1394274, lng: 18.526829 },
    { lat: 49.1397869, lng: 18.5268641 },
    { lat: 49.1403348, lng: 18.5270891 },
    { lat: 49.140837, lng: 18.5276972 },
    { lat: 49.1409754, lng: 18.5276518 },
    { lat: 49.1414206, lng: 18.5273731 },
    { lat: 49.1417061, lng: 18.5273372 },
    { lat: 49.1419453, lng: 18.5273152 },
    { lat: 49.1422861, lng: 18.5273404 },
    { lat: 49.1427514, lng: 18.5275603 },
    { lat: 49.1427623, lng: 18.5275537 },
    { lat: 49.1428716, lng: 18.5264894 },
    { lat: 49.1431077, lng: 18.5264945 },
    { lat: 49.1433099, lng: 18.5263343 },
    { lat: 49.1433473, lng: 18.5262125 },
    { lat: 49.1435272, lng: 18.5262025 },
    { lat: 49.1441231, lng: 18.5255017 },
    { lat: 49.1444072, lng: 18.5253747 },
    { lat: 49.1444093, lng: 18.5253309 },
    { lat: 49.1444915, lng: 18.5247245 },
    { lat: 49.1451014, lng: 18.5242595 },
    { lat: 49.1452834, lng: 18.523748 },
    { lat: 49.1454095, lng: 18.5233908 },
    { lat: 49.1455925, lng: 18.5230427 },
    { lat: 49.1458405, lng: 18.5227067 },
    { lat: 49.1460153, lng: 18.5227476 },
    { lat: 49.1461715, lng: 18.5221341 },
    { lat: 49.1462861, lng: 18.5216902 },
    { lat: 49.1465209, lng: 18.5207765 },
    { lat: 49.1466757, lng: 18.520325 },
    { lat: 49.1466877, lng: 18.5202767 },
    { lat: 49.1467282, lng: 18.5201484 },
    { lat: 49.1467965, lng: 18.5197052 },
    { lat: 49.1469519, lng: 18.519615 },
    { lat: 49.1472564, lng: 18.5179528 },
    { lat: 49.1472099, lng: 18.517426 },
    { lat: 49.1476834, lng: 18.5165097 },
    { lat: 49.1485153, lng: 18.5160827 },
    { lat: 49.1487608, lng: 18.5159516 },
    { lat: 49.1491853, lng: 18.5158341 },
    { lat: 49.1490187, lng: 18.5157137 },
    { lat: 49.1489433, lng: 18.5154897 },
    { lat: 49.1488737, lng: 18.5154563 },
    { lat: 49.1486817, lng: 18.5149734 },
    { lat: 49.1483959, lng: 18.5149221 },
    { lat: 49.1483793, lng: 18.5143866 },
    { lat: 49.1481313, lng: 18.5141837 },
    { lat: 49.1481452, lng: 18.5136493 },
    { lat: 49.1478376, lng: 18.5133024 },
    { lat: 49.147808, lng: 18.5131094 },
    { lat: 49.1473892, lng: 18.5127095 },
    { lat: 49.1471744, lng: 18.5124516 },
    { lat: 49.146931, lng: 18.5120181 },
    { lat: 49.1466886, lng: 18.5114387 },
    { lat: 49.1464358, lng: 18.5113737 },
    { lat: 49.1465265, lng: 18.5118604 },
    { lat: 49.145352, lng: 18.5122896 },
    { lat: 49.144588, lng: 18.5124067 },
    { lat: 49.1442879, lng: 18.5119287 },
    { lat: 49.1442589, lng: 18.5119334 },
    { lat: 49.1442721, lng: 18.5117829 },
    { lat: 49.1442626, lng: 18.5114837 },
    { lat: 49.144247, lng: 18.5110928 },
    { lat: 49.1442121, lng: 18.5109287 },
    { lat: 49.1441024, lng: 18.5106722 },
    { lat: 49.1440649, lng: 18.5105845 },
    { lat: 49.1437179, lng: 18.5099492 },
    { lat: 49.1435681, lng: 18.5100166 },
    { lat: 49.1434962, lng: 18.5100604 },
    { lat: 49.1434689, lng: 18.5100992 },
    { lat: 49.1433583, lng: 18.5101391 },
    { lat: 49.1432793, lng: 18.5102009 },
    { lat: 49.1431496, lng: 18.5101997 },
    { lat: 49.1429969, lng: 18.510213 },
    { lat: 49.1429151, lng: 18.5103058 },
    { lat: 49.142871, lng: 18.510366 },
    { lat: 49.1429345, lng: 18.5106631 },
    { lat: 49.1430839, lng: 18.5111922 },
    { lat: 49.1430808, lng: 18.5115085 },
    { lat: 49.142653, lng: 18.5115027 },
    { lat: 49.1423583, lng: 18.5112679 },
    { lat: 49.1420837, lng: 18.510718 },
    { lat: 49.1410831, lng: 18.5103222 },
    { lat: 49.1406228, lng: 18.5104224 },
    { lat: 49.1406299, lng: 18.5104296 },
    { lat: 49.1402531, lng: 18.5109044 },
    { lat: 49.1401514, lng: 18.5109869 },
    { lat: 49.1400659, lng: 18.5108875 },
    { lat: 49.1399345, lng: 18.5107319 },
    { lat: 49.1398616, lng: 18.5106964 },
    { lat: 49.1398108, lng: 18.510656 },
    { lat: 49.1396404, lng: 18.5106546 },
    { lat: 49.139609, lng: 18.5107386 },
    { lat: 49.1395926, lng: 18.5107896 },
    { lat: 49.1395632, lng: 18.5108728 },
    { lat: 49.1394706, lng: 18.5109318 },
    { lat: 49.139049, lng: 18.5109584 },
    { lat: 49.1382868, lng: 18.51088 },
    { lat: 49.138113, lng: 18.5110025 },
    { lat: 49.1376411, lng: 18.5108275 },
    { lat: 49.1373462, lng: 18.5105906 },
    { lat: 49.1371364, lng: 18.5107975 },
    { lat: 49.1366006, lng: 18.5109003 },
    { lat: 49.1365641, lng: 18.5106857 },
    { lat: 49.1361804, lng: 18.5105158 },
    { lat: 49.1360295, lng: 18.5103333 },
    { lat: 49.1359934, lng: 18.5100855 },
    { lat: 49.1356065, lng: 18.5097936 },
    { lat: 49.1352412, lng: 18.5100659 },
    { lat: 49.13444, lng: 18.5106409 },
    { lat: 49.134099, lng: 18.5107794 },
    { lat: 49.1339412, lng: 18.5108431 },
    { lat: 49.1334904, lng: 18.5107663 },
    { lat: 49.1333824, lng: 18.5107826 },
    { lat: 49.1330037, lng: 18.5108398 },
    { lat: 49.1327273, lng: 18.5108167 },
    { lat: 49.1326118, lng: 18.5108073 },
    { lat: 49.1324805, lng: 18.5100275 },
    { lat: 49.1324433, lng: 18.5098067 },
    { lat: 49.1316409, lng: 18.5091743 },
    { lat: 49.1313975, lng: 18.508887 },
    { lat: 49.1309222, lng: 18.5081113 },
    { lat: 49.1304957, lng: 18.5077573 },
    { lat: 49.1302547, lng: 18.5075343 },
    { lat: 49.1300255, lng: 18.5073225 },
  ],
  Bodiná: [
    { lat: 49.104973, lng: 18.580743 },
    { lat: 49.105037, lng: 18.5809 },
    { lat: 49.10584, lng: 18.582203 },
    { lat: 49.105841, lng: 18.582205 },
    { lat: 49.106131, lng: 18.58286 },
    { lat: 49.106483, lng: 18.583413 },
    { lat: 49.107195, lng: 18.584274 },
    { lat: 49.107366, lng: 18.584609 },
    { lat: 49.107392, lng: 18.584658 },
    { lat: 49.107841, lng: 18.585232 },
    { lat: 49.108266, lng: 18.585774 },
    { lat: 49.109309, lng: 18.586874 },
    { lat: 49.109598, lng: 18.587271 },
    { lat: 49.11034, lng: 18.587609 },
    { lat: 49.112784, lng: 18.590106 },
    { lat: 49.113066, lng: 18.590114 },
    { lat: 49.113255, lng: 18.590119 },
    { lat: 49.113308, lng: 18.590121 },
    { lat: 49.113775, lng: 18.590602 },
    { lat: 49.114003, lng: 18.59042 },
    { lat: 49.1140097, lng: 18.5904168 },
    { lat: 49.114129, lng: 18.59036 },
    { lat: 49.114716, lng: 18.590625 },
    { lat: 49.115229, lng: 18.590975 },
    { lat: 49.115592, lng: 18.591066 },
    { lat: 49.116076, lng: 18.591054 },
    { lat: 49.116488, lng: 18.590958 },
    { lat: 49.117211, lng: 18.590632 },
    { lat: 49.117767, lng: 18.590453 },
    { lat: 49.117993, lng: 18.589738 },
    { lat: 49.118123, lng: 18.589452 },
    { lat: 49.118217, lng: 18.589126 },
    { lat: 49.1182415, lng: 18.5890716 },
    { lat: 49.118411, lng: 18.588988 },
    { lat: 49.118668, lng: 18.588712 },
    { lat: 49.118827, lng: 18.588651 },
    { lat: 49.119257, lng: 18.588496 },
    { lat: 49.119763, lng: 18.588277 },
    { lat: 49.120089, lng: 18.587863 },
    { lat: 49.120726, lng: 18.587197 },
    { lat: 49.120996, lng: 18.586796 },
    { lat: 49.121111, lng: 18.586513 },
    { lat: 49.121584, lng: 18.58448 },
    { lat: 49.121598, lng: 18.58443 },
    { lat: 49.121639, lng: 18.584276 },
    { lat: 49.12175, lng: 18.583372 },
    { lat: 49.121811, lng: 18.58303 },
    { lat: 49.121922, lng: 18.582507 },
    { lat: 49.121949, lng: 18.5819 },
    { lat: 49.12212, lng: 18.581199 },
    { lat: 49.122308, lng: 18.579687 },
    { lat: 49.122544, lng: 18.579204 },
    { lat: 49.122985, lng: 18.57851 },
    { lat: 49.12335, lng: 18.578091 },
    { lat: 49.123696, lng: 18.577453 },
    { lat: 49.123972, lng: 18.576311 },
    { lat: 49.124301, lng: 18.574688 },
    { lat: 49.1244894, lng: 18.5738221 },
    { lat: 49.1248835, lng: 18.5727639 },
    { lat: 49.1250349, lng: 18.5718024 },
    { lat: 49.1251306, lng: 18.5709217 },
    { lat: 49.1251643, lng: 18.5700359 },
    { lat: 49.1250934, lng: 18.5689299 },
    { lat: 49.1250483, lng: 18.5686373 },
    { lat: 49.1243195, lng: 18.5691791 },
    { lat: 49.1229556, lng: 18.5694225 },
    { lat: 49.1221182, lng: 18.5695819 },
    { lat: 49.1219607, lng: 18.5693362 },
    { lat: 49.1217729, lng: 18.5681941 },
    { lat: 49.1220069, lng: 18.566696 },
    { lat: 49.122028, lng: 18.5665443 },
    { lat: 49.1217631, lng: 18.5656869 },
    { lat: 49.1215599, lng: 18.5647615 },
    { lat: 49.1215614, lng: 18.5642466 },
    { lat: 49.1216754, lng: 18.5636421 },
    { lat: 49.1218604, lng: 18.5630353 },
    { lat: 49.1219002, lng: 18.5629576 },
    { lat: 49.1222153, lng: 18.5620881 },
    { lat: 49.122425, lng: 18.5613329 },
    { lat: 49.1225356, lng: 18.560874 },
    { lat: 49.1225378, lng: 18.5605799 },
    { lat: 49.1225296, lng: 18.5603218 },
    { lat: 49.1224109, lng: 18.5597094 },
    { lat: 49.1220403, lng: 18.5589443 },
    { lat: 49.1219916, lng: 18.5583345 },
    { lat: 49.1213288, lng: 18.5564378 },
    { lat: 49.1211025, lng: 18.5562366 },
    { lat: 49.1211722, lng: 18.5541225 },
    { lat: 49.1209965, lng: 18.5516411 },
    { lat: 49.1212321, lng: 18.5509898 },
    { lat: 49.1217707, lng: 18.5496437 },
    { lat: 49.1210344, lng: 18.5489177 },
    { lat: 49.12024, lng: 18.5480339 },
    { lat: 49.1194753, lng: 18.5473441 },
    { lat: 49.1186439, lng: 18.5476119 },
    { lat: 49.1179489, lng: 18.5467739 },
    { lat: 49.1169999, lng: 18.5454499 },
    { lat: 49.1166082, lng: 18.5447147 },
    { lat: 49.1162099, lng: 18.5441074 },
    { lat: 49.1154357, lng: 18.5430113 },
    { lat: 49.1148162, lng: 18.5420474 },
    { lat: 49.1144379, lng: 18.5416841 },
    { lat: 49.1140442, lng: 18.5412654 },
    { lat: 49.1136968, lng: 18.5409546 },
    { lat: 49.1134922, lng: 18.540403 },
    { lat: 49.1129178, lng: 18.5400214 },
    { lat: 49.1125127, lng: 18.5402699 },
    { lat: 49.1124337, lng: 18.5402482 },
    { lat: 49.1115782, lng: 18.5400081 },
    { lat: 49.1109498, lng: 18.5401299 },
    { lat: 49.1103434, lng: 18.5404083 },
    { lat: 49.1101272, lng: 18.5406443 },
    { lat: 49.1098397, lng: 18.5409363 },
    { lat: 49.1094929, lng: 18.5419092 },
    { lat: 49.109255, lng: 18.5428265 },
    { lat: 49.109022, lng: 18.5438371 },
    { lat: 49.1082521, lng: 18.5442215 },
    { lat: 49.1072302, lng: 18.5448684 },
    { lat: 49.1065781, lng: 18.545552 },
    { lat: 49.1058984, lng: 18.5468948 },
    { lat: 49.1057528, lng: 18.5476633 },
    { lat: 49.1055469, lng: 18.548134 },
    { lat: 49.1037121, lng: 18.5495775 },
    { lat: 49.1028287, lng: 18.5501379 },
    { lat: 49.1023285, lng: 18.5512342 },
    { lat: 49.1016876, lng: 18.5526287 },
    { lat: 49.1014162, lng: 18.5533261 },
    { lat: 49.1013931, lng: 18.5533563 },
    { lat: 49.1013625, lng: 18.5534161 },
    { lat: 49.1013551, lng: 18.5534255 },
    { lat: 49.1012867, lng: 18.553433 },
    { lat: 49.101266, lng: 18.5535097 },
    { lat: 49.1012307, lng: 18.5535665 },
    { lat: 49.101178, lng: 18.5535294 },
    { lat: 49.1011182, lng: 18.5535286 },
    { lat: 49.1010844, lng: 18.5535514 },
    { lat: 49.1010361, lng: 18.5535958 },
    { lat: 49.1010246, lng: 18.553613 },
    { lat: 49.1008899, lng: 18.5538142 },
    { lat: 49.1007041, lng: 18.5540702 },
    { lat: 49.1006095, lng: 18.5542197 },
    { lat: 49.100513, lng: 18.5543925 },
    { lat: 49.1003471, lng: 18.5546843 },
    { lat: 49.1000452, lng: 18.5550261 },
    { lat: 49.0999984, lng: 18.5551838 },
    { lat: 49.0999946, lng: 18.5551976 },
    { lat: 49.0999926, lng: 18.5552006 },
    { lat: 49.0999789, lng: 18.5552183 },
    { lat: 49.0999473, lng: 18.5552604 },
    { lat: 49.0999222, lng: 18.5552772 },
    { lat: 49.0999137, lng: 18.5552848 },
    { lat: 49.099888, lng: 18.5553103 },
    { lat: 49.0997156, lng: 18.5554816 },
    { lat: 49.0995956, lng: 18.5555985 },
    { lat: 49.0995695, lng: 18.5556169 },
    { lat: 49.0995361, lng: 18.5556034 },
    { lat: 49.0995266, lng: 18.5555737 },
    { lat: 49.0986076, lng: 18.5565175 },
    { lat: 49.098583, lng: 18.5565428 },
    { lat: 49.0978304, lng: 18.5572894 },
    { lat: 49.0970346, lng: 18.5583237 },
    { lat: 49.0961644, lng: 18.5593969 },
    { lat: 49.0956793, lng: 18.559751 },
    { lat: 49.094928, lng: 18.5600002 },
    { lat: 49.0945351, lng: 18.5605756 },
    { lat: 49.09429, lng: 18.561177 },
    { lat: 49.094177, lng: 18.561271 },
    { lat: 49.094026, lng: 18.561399 },
    { lat: 49.094066, lng: 18.562133 },
    { lat: 49.094232, lng: 18.562448 },
    { lat: 49.094825, lng: 18.563239 },
    { lat: 49.09594, lng: 18.564589 },
    { lat: 49.09788, lng: 18.567647 },
    { lat: 49.099065, lng: 18.568919 },
    { lat: 49.100346, lng: 18.569787 },
    { lat: 49.100777, lng: 18.570557 },
    { lat: 49.100812, lng: 18.571139 },
    { lat: 49.1008937, lng: 18.5723673 },
    { lat: 49.101006, lng: 18.574057 },
    { lat: 49.101509, lng: 18.574554 },
    { lat: 49.102805, lng: 18.575256 },
    { lat: 49.103719, lng: 18.576392 },
    { lat: 49.104001, lng: 18.576639 },
    { lat: 49.104445, lng: 18.577174 },
    { lat: 49.104513, lng: 18.578446 },
    { lat: 49.104604, lng: 18.57892 },
    { lat: 49.104829, lng: 18.579703 },
    { lat: 49.104872, lng: 18.580076 },
    { lat: 49.104928, lng: 18.580584 },
    { lat: 49.104973, lng: 18.580743 },
  ],
  MaléLednice: [
    { lat: 49.082181, lng: 18.586448 },
    { lat: 49.082232, lng: 18.586242 },
    { lat: 49.082248, lng: 18.586179 },
    { lat: 49.082273, lng: 18.586077 },
    { lat: 49.082319, lng: 18.586068 },
    { lat: 49.082656, lng: 18.586004 },
    { lat: 49.082661, lng: 18.585976 },
    { lat: 49.082783, lng: 18.585316 },
    { lat: 49.082786, lng: 18.585294 },
    { lat: 49.082904, lng: 18.584896 },
    { lat: 49.08305, lng: 18.58465 },
    { lat: 49.083189, lng: 18.584573 },
    { lat: 49.083272, lng: 18.584527 },
    { lat: 49.083371, lng: 18.584473 },
    { lat: 49.083561, lng: 18.584542 },
    { lat: 49.08359, lng: 18.584106 },
    { lat: 49.083599, lng: 18.584078 },
    { lat: 49.083623, lng: 18.583987 },
    { lat: 49.083771, lng: 18.583456 },
    { lat: 49.083802, lng: 18.583343 },
    { lat: 49.084006, lng: 18.583053 },
    { lat: 49.084093, lng: 18.582884 },
    { lat: 49.084533, lng: 18.582026 },
    { lat: 49.084765, lng: 18.581393 },
    { lat: 49.08501, lng: 18.581068 },
    { lat: 49.085129, lng: 18.580942 },
    { lat: 49.085435, lng: 18.580366 },
    { lat: 49.085532, lng: 18.580388 },
    { lat: 49.085649, lng: 18.580416 },
    { lat: 49.085685, lng: 18.580325 },
    { lat: 49.0862, lng: 18.579029 },
    { lat: 49.086507, lng: 18.57798 },
    { lat: 49.0868, lng: 18.57705 },
    { lat: 49.087123, lng: 18.576207 },
    { lat: 49.087394, lng: 18.575221 },
    { lat: 49.08741, lng: 18.574834 },
    { lat: 49.087375, lng: 18.574391 },
    { lat: 49.087429, lng: 18.573044 },
    { lat: 49.087371, lng: 18.571188 },
    { lat: 49.087367, lng: 18.56964 },
    { lat: 49.087481, lng: 18.56899 },
    { lat: 49.087591, lng: 18.568561 },
    { lat: 49.087754, lng: 18.568073 },
    { lat: 49.087879, lng: 18.566716 },
    { lat: 49.087886, lng: 18.566637 },
    { lat: 49.08791, lng: 18.56637 },
    { lat: 49.087943, lng: 18.565781 },
    { lat: 49.08784, lng: 18.564529 },
    { lat: 49.087838, lng: 18.564041 },
    { lat: 49.087967, lng: 18.562845 },
    { lat: 49.088085, lng: 18.562365 },
    { lat: 49.088564, lng: 18.561058 },
    { lat: 49.08849, lng: 18.560525 },
    { lat: 49.088325, lng: 18.560247 },
    { lat: 49.087854, lng: 18.558171 },
    { lat: 49.087588, lng: 18.557 },
    { lat: 49.0859869, lng: 18.5573777 },
    { lat: 49.0852491, lng: 18.5580033 },
    { lat: 49.084989, lng: 18.5579254 },
    { lat: 49.0843518, lng: 18.5575287 },
    { lat: 49.0834412, lng: 18.5575221 },
    { lat: 49.0831344, lng: 18.5575853 },
    { lat: 49.0827084, lng: 18.5576739 },
    { lat: 49.0818321, lng: 18.5576757 },
    { lat: 49.0790162, lng: 18.555659 },
    { lat: 49.0779851, lng: 18.554964 },
    { lat: 49.0774754, lng: 18.5541933 },
    { lat: 49.0752285, lng: 18.5525624 },
    { lat: 49.074645, lng: 18.5515535 },
    { lat: 49.0739547, lng: 18.5496939 },
    { lat: 49.0738588, lng: 18.5482768 },
    { lat: 49.072665, lng: 18.5472041 },
    { lat: 49.0714086, lng: 18.5463298 },
    { lat: 49.0694531, lng: 18.5434989 },
    { lat: 49.0687849, lng: 18.5413245 },
    { lat: 49.0679072, lng: 18.5394435 },
    { lat: 49.066721, lng: 18.5386601 },
    { lat: 49.0665542, lng: 18.5384754 },
    { lat: 49.0664222, lng: 18.5396815 },
    { lat: 49.0663902, lng: 18.5411536 },
    { lat: 49.0659084, lng: 18.5417946 },
    { lat: 49.0655607, lng: 18.5423729 },
    { lat: 49.0652796, lng: 18.5428056 },
    { lat: 49.0646028, lng: 18.5438474 },
    { lat: 49.0641271, lng: 18.5442061 },
    { lat: 49.0638802, lng: 18.5448138 },
    { lat: 49.0636362, lng: 18.5454506 },
    { lat: 49.063234, lng: 18.5465638 },
    { lat: 49.0627946, lng: 18.5477191 },
    { lat: 49.0627621, lng: 18.5477464 },
    { lat: 49.0627502, lng: 18.547756 },
    { lat: 49.0627217, lng: 18.5477095 },
    { lat: 49.0627002, lng: 18.5476649 },
    { lat: 49.0626582, lng: 18.5475754 },
    { lat: 49.0626421, lng: 18.547383 },
    { lat: 49.0626362, lng: 18.5472318 },
    { lat: 49.0626792, lng: 18.5470268 },
    { lat: 49.0626543, lng: 18.5468163 },
    { lat: 49.0626545, lng: 18.5465911 },
    { lat: 49.0625637, lng: 18.5464356 },
    { lat: 49.0625013, lng: 18.546155 },
    { lat: 49.0624892, lng: 18.5460144 },
    { lat: 49.0624788, lng: 18.5458442 },
    { lat: 49.0625021, lng: 18.545733 },
    { lat: 49.0625159, lng: 18.5455931 },
    { lat: 49.0625033, lng: 18.5454634 },
    { lat: 49.0625004, lng: 18.5454102 },
    { lat: 49.0625021, lng: 18.5453165 },
    { lat: 49.0624866, lng: 18.545094 },
    { lat: 49.0624289, lng: 18.544757 },
    { lat: 49.062348, lng: 18.5448028 },
    { lat: 49.0619071, lng: 18.5450357 },
    { lat: 49.0619537, lng: 18.5454379 },
    { lat: 49.0615609, lng: 18.5456196 },
    { lat: 49.0607739, lng: 18.5456235 },
    { lat: 49.0607079, lng: 18.5459046 },
    { lat: 49.0605951, lng: 18.5461338 },
    { lat: 49.0606996, lng: 18.5464803 },
    { lat: 49.060538, lng: 18.5471638 },
    { lat: 49.0603363, lng: 18.5478353 },
    { lat: 49.0602707, lng: 18.5479773 },
    { lat: 49.0601037, lng: 18.5482252 },
    { lat: 49.0599723, lng: 18.5484447 },
    { lat: 49.0599413, lng: 18.5484977 },
    { lat: 49.0595601, lng: 18.5491647 },
    { lat: 49.059382, lng: 18.549542 },
    { lat: 49.059118, lng: 18.5499793 },
    { lat: 49.0586935, lng: 18.5507844 },
    { lat: 49.0586279, lng: 18.5513238 },
    { lat: 49.0580547, lng: 18.5524892 },
    { lat: 49.0575758, lng: 18.5537476 },
    { lat: 49.0576627, lng: 18.554146 },
    { lat: 49.0577381, lng: 18.5544151 },
    { lat: 49.0578533, lng: 18.5545422 },
    { lat: 49.0579958, lng: 18.5546968 },
    { lat: 49.0581379, lng: 18.5548431 },
    { lat: 49.0582794, lng: 18.5550188 },
    { lat: 49.0584052, lng: 18.5552167 },
    { lat: 49.0585152, lng: 18.5554367 },
    { lat: 49.0585997, lng: 18.5556858 },
    { lat: 49.0587439, lng: 18.5558922 },
    { lat: 49.0587803, lng: 18.5561468 },
    { lat: 49.058829, lng: 18.5561721 },
    { lat: 49.0586487, lng: 18.5568237 },
    { lat: 49.0585787, lng: 18.5574481 },
    { lat: 49.0585473, lng: 18.557661 },
    { lat: 49.0585096, lng: 18.5578184 },
    { lat: 49.0584473, lng: 18.5578969 },
    { lat: 49.0583498, lng: 18.5582207 },
    { lat: 49.0582566, lng: 18.5587507 },
    { lat: 49.0582184, lng: 18.5589218 },
    { lat: 49.058245, lng: 18.5590169 },
    { lat: 49.0582429, lng: 18.5591453 },
    { lat: 49.0578412, lng: 18.5606882 },
    { lat: 49.0578243, lng: 18.5607518 },
    { lat: 49.0576699, lng: 18.5619865 },
    { lat: 49.0572463, lng: 18.5631438 },
    { lat: 49.0576196, lng: 18.5635627 },
    { lat: 49.0579647, lng: 18.5638546 },
    { lat: 49.0581672, lng: 18.564179 },
    { lat: 49.0585332, lng: 18.5656096 },
    { lat: 49.0587232, lng: 18.5661642 },
    { lat: 49.0587235, lng: 18.5667547 },
    { lat: 49.0584855, lng: 18.5670073 },
    { lat: 49.0583142, lng: 18.5674484 },
    { lat: 49.0582904, lng: 18.5675089 },
    { lat: 49.0582765, lng: 18.5675445 },
    { lat: 49.0582517, lng: 18.5675853 },
    { lat: 49.0582424, lng: 18.5676645 },
    { lat: 49.0582149, lng: 18.5677802 },
    { lat: 49.0581956, lng: 18.5678412 },
    { lat: 49.0581842, lng: 18.5682807 },
    { lat: 49.0582375, lng: 18.5684312 },
    { lat: 49.0581546, lng: 18.5687325 },
    { lat: 49.0576538, lng: 18.5696543 },
    { lat: 49.0575728, lng: 18.5697399 },
    { lat: 49.0573823, lng: 18.5698696 },
    { lat: 49.0571755, lng: 18.570009 },
    { lat: 49.0570791, lng: 18.5709183 },
    { lat: 49.0569486, lng: 18.5710706 },
    { lat: 49.0568636, lng: 18.5711687 },
    { lat: 49.0568606, lng: 18.5715098 },
    { lat: 49.0568448, lng: 18.5727877 },
    { lat: 49.056891, lng: 18.5742111 },
    { lat: 49.0567988, lng: 18.5752831 },
    { lat: 49.0563123, lng: 18.575274 },
    { lat: 49.0554401, lng: 18.5761068 },
    { lat: 49.054792, lng: 18.5765528 },
    { lat: 49.0542399, lng: 18.577006 },
    { lat: 49.0534623, lng: 18.5777716 },
    { lat: 49.0530055, lng: 18.578189 },
    { lat: 49.0526094, lng: 18.578457 },
    { lat: 49.0520623, lng: 18.5788972 },
    { lat: 49.0515498, lng: 18.5792647 },
    { lat: 49.0510897, lng: 18.5794551 },
    { lat: 49.0509318, lng: 18.5795373 },
    { lat: 49.0506826, lng: 18.5798751 },
    { lat: 49.0504791, lng: 18.5801789 },
    { lat: 49.0502587, lng: 18.580653 },
    { lat: 49.0498795, lng: 18.5814247 },
    { lat: 49.0496843, lng: 18.5818845 },
    { lat: 49.0495775, lng: 18.582017 },
    { lat: 49.0492759, lng: 18.5822591 },
    { lat: 49.0490273, lng: 18.5823124 },
    { lat: 49.0488236, lng: 18.5822579 },
    { lat: 49.0485151, lng: 18.5822892 },
    { lat: 49.0483247, lng: 18.5823556 },
    { lat: 49.0481451, lng: 18.5825184 },
    { lat: 49.0478167, lng: 18.5829543 },
    { lat: 49.0474604, lng: 18.5833542 },
    { lat: 49.0473996, lng: 18.583419 },
    { lat: 49.0470456, lng: 18.5833803 },
    { lat: 49.0464318, lng: 18.5832083 },
    { lat: 49.0460456, lng: 18.5832713 },
    { lat: 49.0454378, lng: 18.5833704 },
    { lat: 49.0453965, lng: 18.5833771 },
    { lat: 49.0450723, lng: 18.5837871 },
    { lat: 49.0449645, lng: 18.584236 },
    { lat: 49.0449126, lng: 18.5844518 },
    { lat: 49.0448483, lng: 18.5847197 },
    { lat: 49.0448447, lng: 18.5847347 },
    { lat: 49.0448006, lng: 18.5846904 },
    { lat: 49.0445047, lng: 18.5849139 },
    { lat: 49.0441863, lng: 18.5853696 },
    { lat: 49.0436908, lng: 18.5862647 },
    { lat: 49.0435478, lng: 18.5864793 },
    { lat: 49.0435011, lng: 18.5865529 },
    { lat: 49.0433231, lng: 18.5868718 },
    { lat: 49.0431353, lng: 18.5870701 },
    { lat: 49.0431489, lng: 18.5871135 },
    { lat: 49.0431485, lng: 18.5871912 },
    { lat: 49.0429489, lng: 18.5878835 },
    { lat: 49.0429432, lng: 18.5884472 },
    { lat: 49.0428923, lng: 18.5889486 },
    { lat: 49.0428565, lng: 18.5890177 },
    { lat: 49.042746, lng: 18.5891224 },
    { lat: 49.0423199, lng: 18.5892527 },
    { lat: 49.042099, lng: 18.5891292 },
    { lat: 49.0411617, lng: 18.5882478 },
    { lat: 49.0407702, lng: 18.588015 },
    { lat: 49.0404291, lng: 18.5880463 },
    { lat: 49.0400621, lng: 18.5884579 },
    { lat: 49.0397343, lng: 18.588384 },
    { lat: 49.0392891, lng: 18.5884288 },
    { lat: 49.039087, lng: 18.5882147 },
    { lat: 49.0389535, lng: 18.5879365 },
    { lat: 49.0381858, lng: 18.5876499 },
    { lat: 49.0380493, lng: 18.5877535 },
    { lat: 49.0377816, lng: 18.5883731 },
    { lat: 49.0374101, lng: 18.5880322 },
    { lat: 49.037123, lng: 18.587978 },
    { lat: 49.0365517, lng: 18.5873999 },
    { lat: 49.0363733, lng: 18.5871658 },
    { lat: 49.0362298, lng: 18.586888 },
    { lat: 49.0361344, lng: 18.5865183 },
    { lat: 49.0359936, lng: 18.5858097 },
    { lat: 49.0353488, lng: 18.5851879 },
    { lat: 49.0349933, lng: 18.5852235 },
    { lat: 49.0341215, lng: 18.5854551 },
    { lat: 49.033642, lng: 18.5855153 },
    { lat: 49.0331888, lng: 18.5851828 },
    { lat: 49.0330618, lng: 18.5851285 },
    { lat: 49.0328034, lng: 18.585023 },
    { lat: 49.032442, lng: 18.5847631 },
    { lat: 49.0319686, lng: 18.5847457 },
    { lat: 49.031561, lng: 18.584633 },
    { lat: 49.0310172, lng: 18.5852179 },
    { lat: 49.0304795, lng: 18.5855781 },
    { lat: 49.0300652, lng: 18.5855916 },
    { lat: 49.0291805, lng: 18.5860228 },
    { lat: 49.0286005, lng: 18.5864195 },
    { lat: 49.0284916, lng: 18.5870988 },
    { lat: 49.0285081, lng: 18.5871303 },
    { lat: 49.0285181, lng: 18.5871493 },
    { lat: 49.0283864, lng: 18.587325 },
    { lat: 49.0282994, lng: 18.5874667 },
    { lat: 49.028259, lng: 18.587575 },
    { lat: 49.0282124, lng: 18.5877373 },
    { lat: 49.0281672, lng: 18.5878609 },
    { lat: 49.0281036, lng: 18.5879795 },
    { lat: 49.0279862, lng: 18.588145 },
    { lat: 49.0278895, lng: 18.5883177 },
    { lat: 49.0278064, lng: 18.5884078 },
    { lat: 49.0276615, lng: 18.5885459 },
    { lat: 49.0274717, lng: 18.5885799 },
    { lat: 49.0273774, lng: 18.5886718 },
    { lat: 49.0272983, lng: 18.5886913 },
    { lat: 49.0271578, lng: 18.5888062 },
    { lat: 49.0268904, lng: 18.5887825 },
    { lat: 49.0262763, lng: 18.5889194 },
    { lat: 49.0259523, lng: 18.58862 },
    { lat: 49.0257648, lng: 18.5885515 },
    { lat: 49.0254267, lng: 18.5880315 },
    { lat: 49.0249167, lng: 18.5879021 },
    { lat: 49.0243101, lng: 18.5880104 },
    { lat: 49.0239299, lng: 18.5881118 },
    { lat: 49.0234251, lng: 18.5878718 },
    { lat: 49.0228174, lng: 18.5877686 },
    { lat: 49.022446, lng: 18.587868 },
    { lat: 49.0220919, lng: 18.5880153 },
    { lat: 49.0216232, lng: 18.5885082 },
    { lat: 49.0211848, lng: 18.5888908 },
    { lat: 49.020263, lng: 18.589339 },
    { lat: 49.020397, lng: 18.590108 },
    { lat: 49.020846, lng: 18.590446 },
    { lat: 49.021334, lng: 18.59102 },
    { lat: 49.021314, lng: 18.591747 },
    { lat: 49.021209, lng: 18.592601 },
    { lat: 49.021126, lng: 18.593616 },
    { lat: 49.021229, lng: 18.594155 },
    { lat: 49.021969, lng: 18.594589 },
    { lat: 49.022177, lng: 18.594741 },
    { lat: 49.022399, lng: 18.594938 },
    { lat: 49.022587, lng: 18.595537 },
    { lat: 49.023115, lng: 18.595888 },
    { lat: 49.023507, lng: 18.59696 },
    { lat: 49.023676, lng: 18.59752 },
    { lat: 49.02384, lng: 18.59799 },
    { lat: 49.023876, lng: 18.598811 },
    { lat: 49.024039, lng: 18.599383 },
    { lat: 49.024233, lng: 18.599602 },
    { lat: 49.02484, lng: 18.599619 },
    { lat: 49.025533, lng: 18.598922 },
    { lat: 49.026201, lng: 18.59869 },
    { lat: 49.027001, lng: 18.598701 },
    { lat: 49.027372, lng: 18.598999 },
    { lat: 49.027921, lng: 18.599644 },
    { lat: 49.028377, lng: 18.600362 },
    { lat: 49.028488, lng: 18.600384 },
    { lat: 49.029842, lng: 18.600826 },
    { lat: 49.0302731, lng: 18.6010314 },
    { lat: 49.030329, lng: 18.601058 },
    { lat: 49.0305332, lng: 18.6011368 },
    { lat: 49.030987, lng: 18.601312 },
    { lat: 49.031479, lng: 18.601522 },
    { lat: 49.03176, lng: 18.601435 },
    { lat: 49.03233, lng: 18.600869 },
    { lat: 49.032896, lng: 18.600313 },
    { lat: 49.033233, lng: 18.599877 },
    { lat: 49.033577, lng: 18.599535 },
    { lat: 49.034307, lng: 18.599711 },
    { lat: 49.035057, lng: 18.599995 },
    { lat: 49.035455, lng: 18.600113 },
    { lat: 49.035802, lng: 18.600168 },
    { lat: 49.03609, lng: 18.600216 },
    { lat: 49.036422, lng: 18.600486 },
    { lat: 49.036704, lng: 18.600568 },
    { lat: 49.037337, lng: 18.600862 },
    { lat: 49.037623, lng: 18.600712 },
    { lat: 49.038143, lng: 18.60013 },
    { lat: 49.038443, lng: 18.600398 },
    { lat: 49.038547, lng: 18.600633 },
    { lat: 49.038616, lng: 18.600689 },
    { lat: 49.039033, lng: 18.600591 },
    { lat: 49.039463, lng: 18.600727 },
    { lat: 49.039523, lng: 18.600829 },
    { lat: 49.039667, lng: 18.600923 },
    { lat: 49.039915, lng: 18.601162 },
    { lat: 49.040214, lng: 18.60115 },
    { lat: 49.040424, lng: 18.601487 },
    { lat: 49.040785, lng: 18.602142 },
    { lat: 49.041074, lng: 18.602614 },
    { lat: 49.041579, lng: 18.601799 },
    { lat: 49.041979, lng: 18.60115 },
    { lat: 49.042618, lng: 18.600195 },
    { lat: 49.043209, lng: 18.599228 },
    { lat: 49.043594, lng: 18.59854 },
    { lat: 49.044315, lng: 18.597523 },
    { lat: 49.044563, lng: 18.597179 },
    { lat: 49.044744, lng: 18.596911 },
    { lat: 49.044929, lng: 18.596606 },
    { lat: 49.045058, lng: 18.596471 },
    { lat: 49.045093, lng: 18.596434 },
    { lat: 49.045094, lng: 18.596433 },
    { lat: 49.045543, lng: 18.596253 },
    { lat: 49.046966, lng: 18.595699 },
    { lat: 49.047493, lng: 18.59553 },
    { lat: 49.048118, lng: 18.59514 },
    { lat: 49.048468, lng: 18.595653 },
    { lat: 49.048649, lng: 18.595939 },
    { lat: 49.048896, lng: 18.596339 },
    { lat: 49.049058, lng: 18.596619 },
    { lat: 49.04913, lng: 18.596783 },
    { lat: 49.049205, lng: 18.596861 },
    { lat: 49.049313, lng: 18.597042 },
    { lat: 49.049462, lng: 18.59726 },
    { lat: 49.049641, lng: 18.598083 },
    { lat: 49.049778, lng: 18.598303 },
    { lat: 49.049851, lng: 18.598674 },
    { lat: 49.050002, lng: 18.598992 },
    { lat: 49.050374, lng: 18.59916 },
    { lat: 49.050442, lng: 18.599301 },
    { lat: 49.050472, lng: 18.599461 },
    { lat: 49.050524, lng: 18.599557 },
    { lat: 49.050773, lng: 18.599762 },
    { lat: 49.050984, lng: 18.600264 },
    { lat: 49.051495, lng: 18.60073 },
    { lat: 49.052003, lng: 18.601342 },
    { lat: 49.052053, lng: 18.601412 },
    { lat: 49.052132, lng: 18.601606 },
    { lat: 49.052278, lng: 18.601765 },
    { lat: 49.052507, lng: 18.602246 },
    { lat: 49.052589, lng: 18.602325 },
    { lat: 49.05259, lng: 18.602327 },
    { lat: 49.052628, lng: 18.602419 },
    { lat: 49.052634, lng: 18.602434 },
    { lat: 49.052841, lng: 18.602322 },
    { lat: 49.053355, lng: 18.602481 },
    { lat: 49.053638, lng: 18.602621 },
    { lat: 49.054127, lng: 18.602795 },
    { lat: 49.054172, lng: 18.602759 },
    { lat: 49.054434, lng: 18.602543 },
    { lat: 49.054757, lng: 18.602278 },
    { lat: 49.055845, lng: 18.601382 },
    { lat: 49.056673, lng: 18.600563 },
    { lat: 49.0570227, lng: 18.6002477 },
    { lat: 49.057135, lng: 18.600153 },
    { lat: 49.057717, lng: 18.599875 },
    { lat: 49.057764, lng: 18.599887 },
    { lat: 49.058452, lng: 18.600048 },
    { lat: 49.058532, lng: 18.600032 },
    { lat: 49.059438, lng: 18.599846 },
    { lat: 49.059729, lng: 18.599786 },
    { lat: 49.05977, lng: 18.599757 },
    { lat: 49.060119, lng: 18.599504 },
    { lat: 49.060158, lng: 18.599476 },
    { lat: 49.060572, lng: 18.59949 },
    { lat: 49.060989, lng: 18.599608 },
    { lat: 49.061305, lng: 18.599731 },
    { lat: 49.061658, lng: 18.599912 },
    { lat: 49.061794, lng: 18.600019 },
    { lat: 49.062295, lng: 18.600414 },
    { lat: 49.062655, lng: 18.600781 },
    { lat: 49.062873, lng: 18.601168 },
    { lat: 49.062881, lng: 18.601183 },
    { lat: 49.062959, lng: 18.601322 },
    { lat: 49.063121, lng: 18.601945 },
    { lat: 49.063225, lng: 18.602664 },
    { lat: 49.063496, lng: 18.602593 },
    { lat: 49.063704, lng: 18.602539 },
    { lat: 49.064028, lng: 18.602454 },
    { lat: 49.064555, lng: 18.602318 },
    { lat: 49.066085, lng: 18.60192 },
    { lat: 49.06609, lng: 18.601918 },
    { lat: 49.06651, lng: 18.601659 },
    { lat: 49.066794, lng: 18.601483 },
    { lat: 49.067107, lng: 18.601289 },
    { lat: 49.067147, lng: 18.601265 },
    { lat: 49.067215, lng: 18.601223 },
    { lat: 49.067652, lng: 18.600978 },
    { lat: 49.068378, lng: 18.60057 },
    { lat: 49.068513, lng: 18.60046 },
    { lat: 49.068711, lng: 18.600292 },
    { lat: 49.068937, lng: 18.600021 },
    { lat: 49.069471, lng: 18.598654 },
    { lat: 49.069768, lng: 18.597895 },
    { lat: 49.069788, lng: 18.597901 },
    { lat: 49.071062, lng: 18.598318 },
    { lat: 49.072285, lng: 18.598809 },
    { lat: 49.072581, lng: 18.598844 },
    { lat: 49.072743, lng: 18.598923 },
    { lat: 49.072899, lng: 18.597657 },
    { lat: 49.073112, lng: 18.596759 },
    { lat: 49.073157, lng: 18.596576 },
    { lat: 49.073523, lng: 18.595958 },
    { lat: 49.073666, lng: 18.595812 },
    { lat: 49.073796, lng: 18.595678 },
    { lat: 49.07385, lng: 18.595069 },
    { lat: 49.073868, lng: 18.594867 },
    { lat: 49.074338, lng: 18.594041 },
    { lat: 49.074381, lng: 18.593964 },
    { lat: 49.074576, lng: 18.593621 },
    { lat: 49.07476, lng: 18.593068 },
    { lat: 49.074795, lng: 18.593004 },
    { lat: 49.075208, lng: 18.592266 },
    { lat: 49.075634, lng: 18.591751 },
    { lat: 49.075736, lng: 18.591554 },
    { lat: 49.075774, lng: 18.591466 },
    { lat: 49.075802, lng: 18.591382 },
    { lat: 49.075804, lng: 18.591376 },
    { lat: 49.075908, lng: 18.591205 },
    { lat: 49.076034, lng: 18.590996 },
    { lat: 49.076202, lng: 18.591103 },
    { lat: 49.076687, lng: 18.59023 },
    { lat: 49.076864, lng: 18.589908 },
    { lat: 49.076903, lng: 18.589839 },
    { lat: 49.077671, lng: 18.590354 },
    { lat: 49.078068, lng: 18.589558 },
    { lat: 49.078122, lng: 18.58945 },
    { lat: 49.078582, lng: 18.58881 },
    { lat: 49.078593, lng: 18.588816 },
    { lat: 49.079144, lng: 18.589095 },
    { lat: 49.079478, lng: 18.589265 },
    { lat: 49.079618, lng: 18.589336 },
    { lat: 49.080679, lng: 18.589579 },
    { lat: 49.080877, lng: 18.589531 },
    { lat: 49.080888, lng: 18.589461 },
    { lat: 49.081058, lng: 18.588347 },
    { lat: 49.081071, lng: 18.588346 },
    { lat: 49.0813, lng: 18.588336 },
    { lat: 49.081343, lng: 18.588162 },
    { lat: 49.081455, lng: 18.587699 },
    { lat: 49.081547, lng: 18.587662 },
    { lat: 49.081942, lng: 18.587504 },
    { lat: 49.081979, lng: 18.587295 },
    { lat: 49.082088, lng: 18.58665 },
    { lat: 49.082169, lng: 18.586593 },
    { lat: 49.082181, lng: 18.586448 },
  ],
  Počarová: [
    { lat: 49.0714021, lng: 18.4969056 },
    { lat: 49.0710698, lng: 18.496477 },
    { lat: 49.0710532, lng: 18.4964406 },
    { lat: 49.0708455, lng: 18.4959632 },
    { lat: 49.0708407, lng: 18.4959574 },
    { lat: 49.0708107, lng: 18.4960899 },
    { lat: 49.0707499, lng: 18.4962375 },
    { lat: 49.0706749, lng: 18.4963312 },
    { lat: 49.0706206, lng: 18.4963276 },
    { lat: 49.070513, lng: 18.4963405 },
    { lat: 49.070465, lng: 18.4963908 },
    { lat: 49.0704289, lng: 18.4963713 },
    { lat: 49.0704085, lng: 18.4963884 },
    { lat: 49.0704218, lng: 18.4965091 },
    { lat: 49.0704191, lng: 18.4965635 },
    { lat: 49.0704088, lng: 18.4965816 },
    { lat: 49.070299, lng: 18.4965938 },
    { lat: 49.0702418, lng: 18.4966636 },
    { lat: 49.0701265, lng: 18.4967203 },
    { lat: 49.0700791, lng: 18.4968018 },
    { lat: 49.0700068, lng: 18.4968419 },
    { lat: 49.0698119, lng: 18.4968453 },
    { lat: 49.0697511, lng: 18.4968051 },
    { lat: 49.0696369, lng: 18.4968221 },
    { lat: 49.069572, lng: 18.4968745 },
    { lat: 49.0695531, lng: 18.4968783 },
    { lat: 49.0695291, lng: 18.4968725 },
    { lat: 49.0694182, lng: 18.4968677 },
    { lat: 49.0693634, lng: 18.4968325 },
    { lat: 49.0692288, lng: 18.4966804 },
    { lat: 49.0691229, lng: 18.4965594 },
    { lat: 49.0690394, lng: 18.4964356 },
    { lat: 49.0690073, lng: 18.4963422 },
    { lat: 49.0689725, lng: 18.4962858 },
    { lat: 49.0688749, lng: 18.4962508 },
    { lat: 49.0688111, lng: 18.4961988 },
    { lat: 49.0687922, lng: 18.4961622 },
    { lat: 49.0687643, lng: 18.4961069 },
    { lat: 49.0687479, lng: 18.4960742 },
    { lat: 49.0687167, lng: 18.4960411 },
    { lat: 49.0686355, lng: 18.4959562 },
    { lat: 49.0683555, lng: 18.495663 },
    { lat: 49.0683441, lng: 18.4956831 },
    { lat: 49.0680945, lng: 18.4961086 },
    { lat: 49.0680365, lng: 18.4962055 },
    { lat: 49.0676396, lng: 18.4968691 },
    { lat: 49.0676158, lng: 18.4969099 },
    { lat: 49.0675886, lng: 18.496948 },
    { lat: 49.0674234, lng: 18.4971791 },
    { lat: 49.0669336, lng: 18.4978642 },
    { lat: 49.0667319, lng: 18.4981575 },
    { lat: 49.0663814, lng: 18.4986399 },
    { lat: 49.0663741, lng: 18.49865 },
    { lat: 49.0663341, lng: 18.4985866 },
    { lat: 49.0662349, lng: 18.4988494 },
    { lat: 49.0661428, lng: 18.4989796 },
    { lat: 49.0658477, lng: 18.4991736 },
    { lat: 49.065681, lng: 18.4992382 },
    { lat: 49.06564, lng: 18.4992542 },
    { lat: 49.0653606, lng: 18.4993623 },
    { lat: 49.0651167, lng: 18.4993996 },
    { lat: 49.0649741, lng: 18.4993699 },
    { lat: 49.064967, lng: 18.4996132 },
    { lat: 49.0644071, lng: 18.4994604 },
    { lat: 49.0642028, lng: 18.4994149 },
    { lat: 49.0638792, lng: 18.4994596 },
    { lat: 49.0637595, lng: 18.4996009 },
    { lat: 49.0637051, lng: 18.4996585 },
    { lat: 49.0636635, lng: 18.4997242 },
    { lat: 49.0633194, lng: 18.5003843 },
    { lat: 49.0632114, lng: 18.5005961 },
    { lat: 49.0631684, lng: 18.5006776 },
    { lat: 49.0630908, lng: 18.5008259 },
    { lat: 49.0629555, lng: 18.5010948 },
    { lat: 49.062617, lng: 18.5017551 },
    { lat: 49.0624319, lng: 18.5021888 },
    { lat: 49.0623707, lng: 18.5024316 },
    { lat: 49.0622608, lng: 18.5034575 },
    { lat: 49.0619743, lng: 18.5047611 },
    { lat: 49.0619645, lng: 18.5048098 },
    { lat: 49.061932, lng: 18.5049585 },
    { lat: 49.0619039, lng: 18.5050025 },
    { lat: 49.0618657, lng: 18.5050262 },
    { lat: 49.0618784, lng: 18.5050739 },
    { lat: 49.0619312, lng: 18.5052751 },
    { lat: 49.0618887, lng: 18.5054495 },
    { lat: 49.0618883, lng: 18.5055023 },
    { lat: 49.0618958, lng: 18.5055571 },
    { lat: 49.0618761, lng: 18.5055686 },
    { lat: 49.0618759, lng: 18.5056887 },
    { lat: 49.0619202, lng: 18.5057574 },
    { lat: 49.0619096, lng: 18.5057893 },
    { lat: 49.061894, lng: 18.5058769 },
    { lat: 49.0619109, lng: 18.5059397 },
    { lat: 49.0618751, lng: 18.5061489 },
    { lat: 49.0618815, lng: 18.506248 },
    { lat: 49.0619132, lng: 18.506332 },
    { lat: 49.0619134, lng: 18.5063573 },
    { lat: 49.0618996, lng: 18.5063733 },
    { lat: 49.061908, lng: 18.5064233 },
    { lat: 49.0619051, lng: 18.5065981 },
    { lat: 49.0618853, lng: 18.50665 },
    { lat: 49.0618517, lng: 18.5067787 },
    { lat: 49.0618466, lng: 18.5068698 },
    { lat: 49.0618644, lng: 18.5071356 },
    { lat: 49.0618484, lng: 18.507194 },
    { lat: 49.0618545, lng: 18.5073053 },
    { lat: 49.0618307, lng: 18.5074488 },
    { lat: 49.0617891, lng: 18.5076364 },
    { lat: 49.0617232, lng: 18.507876 },
    { lat: 49.0616895, lng: 18.5078994 },
    { lat: 49.061647, lng: 18.5079893 },
    { lat: 49.0615942, lng: 18.5080767 },
    { lat: 49.0614598, lng: 18.5081768 },
    { lat: 49.0613463, lng: 18.5082666 },
    { lat: 49.0612161, lng: 18.5083219 },
    { lat: 49.0611643, lng: 18.5084068 },
    { lat: 49.061082, lng: 18.5084896 },
    { lat: 49.0610767, lng: 18.5084946 },
    { lat: 49.0609628, lng: 18.5086617 },
    { lat: 49.0609366, lng: 18.5087186 },
    { lat: 49.0609128, lng: 18.5087381 },
    { lat: 49.0608647, lng: 18.5088681 },
    { lat: 49.0607587, lng: 18.5090981 },
    { lat: 49.0607395, lng: 18.509161 },
    { lat: 49.0606474, lng: 18.5092701 },
    { lat: 49.0605848, lng: 18.5094479 },
    { lat: 49.0605131, lng: 18.5095798 },
    { lat: 49.0604598, lng: 18.5096785 },
    { lat: 49.0604257, lng: 18.5097769 },
    { lat: 49.0604041, lng: 18.5098769 },
    { lat: 49.0603526, lng: 18.5099258 },
    { lat: 49.0603246, lng: 18.5102209 },
    { lat: 49.0603483, lng: 18.5103019 },
    { lat: 49.0603499, lng: 18.5104365 },
    { lat: 49.0603053, lng: 18.5106326 },
    { lat: 49.0603245, lng: 18.5107005 },
    { lat: 49.0603298, lng: 18.5107311 },
    { lat: 49.0603108, lng: 18.5107529 },
    { lat: 49.060295, lng: 18.5107738 },
    { lat: 49.0602907, lng: 18.5107988 },
    { lat: 49.0602562, lng: 18.5109533 },
    { lat: 49.0602565, lng: 18.5109804 },
    { lat: 49.0602634, lng: 18.5111047 },
    { lat: 49.0602681, lng: 18.5111402 },
    { lat: 49.0602728, lng: 18.5111757 },
    { lat: 49.0602229, lng: 18.5113034 },
    { lat: 49.0601731, lng: 18.5114157 },
    { lat: 49.0601081, lng: 18.5115482 },
    { lat: 49.0601104, lng: 18.5116317 },
    { lat: 49.0600777, lng: 18.5117139 },
    { lat: 49.0600802, lng: 18.5117603 },
    { lat: 49.0600921, lng: 18.5117919 },
    { lat: 49.0600925, lng: 18.5118627 },
    { lat: 49.0600935, lng: 18.5119633 },
    { lat: 49.0600421, lng: 18.5119935 },
    { lat: 49.0599655, lng: 18.5121819 },
    { lat: 49.0599421, lng: 18.5122492 },
    { lat: 49.0598915, lng: 18.5123561 },
    { lat: 49.0598677, lng: 18.5123947 },
    { lat: 49.0597752, lng: 18.5128705 },
    { lat: 49.0595925, lng: 18.5131838 },
    { lat: 49.0596513, lng: 18.5134572 },
    { lat: 49.0595584, lng: 18.5138218 },
    { lat: 49.0594808, lng: 18.5140117 },
    { lat: 49.0593388, lng: 18.5145754 },
    { lat: 49.0593811, lng: 18.5152901 },
    { lat: 49.0595175, lng: 18.5155574 },
    { lat: 49.0594688, lng: 18.5156375 },
    { lat: 49.0595909, lng: 18.5169701 },
    { lat: 49.0595899, lng: 18.5170343 },
    { lat: 49.0595866, lng: 18.5171827 },
    { lat: 49.0595789, lng: 18.5175388 },
    { lat: 49.0595771, lng: 18.5176293 },
    { lat: 49.0595763, lng: 18.5176557 },
    { lat: 49.0596131, lng: 18.5180839 },
    { lat: 49.0597194, lng: 18.5183588 },
    { lat: 49.0597868, lng: 18.5184375 },
    { lat: 49.0600556, lng: 18.5187545 },
    { lat: 49.0601956, lng: 18.519007 },
    { lat: 49.0604159, lng: 18.5197821 },
    { lat: 49.0604665, lng: 18.5201738 },
    { lat: 49.0607797, lng: 18.5211433 },
    { lat: 49.0607322, lng: 18.5214291 },
    { lat: 49.0608306, lng: 18.5213939 },
    { lat: 49.0608473, lng: 18.521388 },
    { lat: 49.0608837, lng: 18.5213752 },
    { lat: 49.0609026, lng: 18.5213681 },
    { lat: 49.0609279, lng: 18.5213588 },
    { lat: 49.0619573, lng: 18.5210595 },
    { lat: 49.0620812, lng: 18.5210353 },
    { lat: 49.0631418, lng: 18.520753 },
    { lat: 49.0637563, lng: 18.5206011 },
    { lat: 49.0637733, lng: 18.5207692 },
    { lat: 49.0638412, lng: 18.5210448 },
    { lat: 49.0639072, lng: 18.5211775 },
    { lat: 49.0640013, lng: 18.521374 },
    { lat: 49.0641607, lng: 18.5215487 },
    { lat: 49.0642333, lng: 18.5216588 },
    { lat: 49.0645519, lng: 18.5221877 },
    { lat: 49.0647296, lng: 18.5224711 },
    { lat: 49.0649439, lng: 18.5228659 },
    { lat: 49.0649979, lng: 18.5229651 },
    { lat: 49.0650682, lng: 18.5231175 },
    { lat: 49.0653106, lng: 18.5237372 },
    { lat: 49.0653954, lng: 18.5242823 },
    { lat: 49.0654164, lng: 18.524358 },
    { lat: 49.0654239, lng: 18.5244535 },
    { lat: 49.0654442, lng: 18.5248486 },
    { lat: 49.0654662, lng: 18.5250446 },
    { lat: 49.0654809, lng: 18.5251089 },
    { lat: 49.0655892, lng: 18.5254212 },
    { lat: 49.0657649, lng: 18.5262266 },
    { lat: 49.0658319, lng: 18.5264011 },
    { lat: 49.0660164, lng: 18.5266014 },
    { lat: 49.0660964, lng: 18.5270559 },
    { lat: 49.0661443, lng: 18.5272538 },
    { lat: 49.0662311, lng: 18.5276674 },
    { lat: 49.0661236, lng: 18.5281194 },
    { lat: 49.0660904, lng: 18.5284012 },
    { lat: 49.0660676, lng: 18.5290821 },
    { lat: 49.0661449, lng: 18.5295296 },
    { lat: 49.0662485, lng: 18.5311279 },
    { lat: 49.0666042, lng: 18.5331117 },
    { lat: 49.0666293, lng: 18.5333064 },
    { lat: 49.0666356, lng: 18.5333585 },
    { lat: 49.0666473, lng: 18.5334502 },
    { lat: 49.0667333, lng: 18.533393 },
    { lat: 49.0669134, lng: 18.533219 },
    { lat: 49.0669948, lng: 18.5330546 },
    { lat: 49.0671326, lng: 18.5327485 },
    { lat: 49.0675913, lng: 18.5311177 },
    { lat: 49.0675702, lng: 18.5308754 },
    { lat: 49.0676534, lng: 18.5303699 },
    { lat: 49.0677338, lng: 18.5298789 },
    { lat: 49.0684946, lng: 18.5292851 },
    { lat: 49.0689221, lng: 18.5291213 },
    { lat: 49.0691066, lng: 18.5294222 },
    { lat: 49.0692534, lng: 18.5293624 },
    { lat: 49.0693722, lng: 18.5290781 },
    { lat: 49.0695691, lng: 18.5289458 },
    { lat: 49.0697149, lng: 18.5287638 },
    { lat: 49.0701547, lng: 18.5285952 },
    { lat: 49.0702888, lng: 18.5286157 },
    { lat: 49.0703662, lng: 18.528628 },
    { lat: 49.070672, lng: 18.5287333 },
    { lat: 49.0713323, lng: 18.5286584 },
    { lat: 49.0714354, lng: 18.5286884 },
    { lat: 49.0716559, lng: 18.5286152 },
    { lat: 49.0717941, lng: 18.5286253 },
    { lat: 49.072066, lng: 18.528274 },
    { lat: 49.0720933, lng: 18.5282375 },
    { lat: 49.072125, lng: 18.5281979 },
    { lat: 49.0721419, lng: 18.5281757 },
    { lat: 49.0722062, lng: 18.5280942 },
    { lat: 49.0722674, lng: 18.5280156 },
    { lat: 49.0723105, lng: 18.527853 },
    { lat: 49.0722821, lng: 18.5274339 },
    { lat: 49.0723342, lng: 18.5268575 },
    { lat: 49.0723383, lng: 18.5268062 },
    { lat: 49.0723541, lng: 18.526433 },
    { lat: 49.0722559, lng: 18.5257041 },
    { lat: 49.0722113, lng: 18.5253805 },
    { lat: 49.0721046, lng: 18.5250998 },
    { lat: 49.071997, lng: 18.5248414 },
    { lat: 49.0718956, lng: 18.5247182 },
    { lat: 49.0716763, lng: 18.5244597 },
    { lat: 49.0713859, lng: 18.5239509 },
    { lat: 49.0712402, lng: 18.5238645 },
    { lat: 49.0709115, lng: 18.5234204 },
    { lat: 49.0707636, lng: 18.5230858 },
    { lat: 49.070663, lng: 18.5227079 },
    { lat: 49.0706162, lng: 18.5224469 },
    { lat: 49.0706391, lng: 18.5222877 },
    { lat: 49.0705451, lng: 18.5220515 },
    { lat: 49.0705078, lng: 18.5219267 },
    { lat: 49.0703464, lng: 18.521716 },
    { lat: 49.0701639, lng: 18.5210771 },
    { lat: 49.0702164, lng: 18.5204427 },
    { lat: 49.0701222, lng: 18.5201434 },
    { lat: 49.0701778, lng: 18.5200242 },
    { lat: 49.0701673, lng: 18.5199953 },
    { lat: 49.0701473, lng: 18.5199415 },
    { lat: 49.070142, lng: 18.5199272 },
    { lat: 49.0701223, lng: 18.519875 },
    { lat: 49.0701298, lng: 18.5198669 },
    { lat: 49.0701354, lng: 18.5198468 },
    { lat: 49.0701754, lng: 18.5197108 },
    { lat: 49.0701494, lng: 18.5193137 },
    { lat: 49.0704504, lng: 18.5192715 },
    { lat: 49.0704952, lng: 18.5192637 },
    { lat: 49.0705295, lng: 18.5191701 },
    { lat: 49.0706856, lng: 18.5189703 },
    { lat: 49.07086, lng: 18.5188588 },
    { lat: 49.0708275, lng: 18.5186143 },
    { lat: 49.0707425, lng: 18.5183791 },
    { lat: 49.0705889, lng: 18.5181464 },
    { lat: 49.0704806, lng: 18.5179365 },
    { lat: 49.0704345, lng: 18.517941 },
    { lat: 49.0704424, lng: 18.5175242 },
    { lat: 49.070444, lng: 18.5175132 },
    { lat: 49.0704601, lng: 18.5175173 },
    { lat: 49.0705242, lng: 18.5172031 },
    { lat: 49.0705391, lng: 18.5172077 },
    { lat: 49.0705779, lng: 18.5171731 },
    { lat: 49.0706861, lng: 18.5170492 },
    { lat: 49.070736, lng: 18.5168262 },
    { lat: 49.0707914, lng: 18.5165789 },
    { lat: 49.0707658, lng: 18.5165014 },
    { lat: 49.0708871, lng: 18.5163479 },
    { lat: 49.0708688, lng: 18.5157815 },
    { lat: 49.0706904, lng: 18.5153439 },
    { lat: 49.0706691, lng: 18.515303 },
    { lat: 49.0705469, lng: 18.5153608 },
    { lat: 49.07039, lng: 18.5151917 },
    { lat: 49.0703829, lng: 18.5151844 },
    { lat: 49.0703743, lng: 18.5151725 },
    { lat: 49.070367, lng: 18.5151641 },
    { lat: 49.0704607, lng: 18.5149569 },
    { lat: 49.0704733, lng: 18.514876 },
    { lat: 49.070525, lng: 18.5148531 },
    { lat: 49.0706303, lng: 18.514694 },
    { lat: 49.0707274, lng: 18.5144665 },
    { lat: 49.0707444, lng: 18.5144276 },
    { lat: 49.0709197, lng: 18.5139998 },
    { lat: 49.0710572, lng: 18.5136472 },
    { lat: 49.0710168, lng: 18.5136087 },
    { lat: 49.0711282, lng: 18.5131051 },
    { lat: 49.0713632, lng: 18.5125747 },
    { lat: 49.0715654, lng: 18.5115646 },
    { lat: 49.0716312, lng: 18.511425 },
    { lat: 49.0716749, lng: 18.5112538 },
    { lat: 49.0716125, lng: 18.510751 },
    { lat: 49.0715911, lng: 18.5103566 },
    { lat: 49.0716102, lng: 18.5101887 },
    { lat: 49.0716297, lng: 18.509968 },
    { lat: 49.0717348, lng: 18.5096377 },
    { lat: 49.0717148, lng: 18.509417 },
    { lat: 49.0717695, lng: 18.5091782 },
    { lat: 49.0717983, lng: 18.5089013 },
    { lat: 49.0718704, lng: 18.5088957 },
    { lat: 49.0718682, lng: 18.5087121 },
    { lat: 49.071854, lng: 18.5083256 },
    { lat: 49.0716864, lng: 18.5077128 },
    { lat: 49.0713829, lng: 18.50713 },
    { lat: 49.0711719, lng: 18.5065502 },
    { lat: 49.071047, lng: 18.5055405 },
    { lat: 49.0708041, lng: 18.5051197 },
    { lat: 49.0705136, lng: 18.5043843 },
    { lat: 49.070734, lng: 18.5038727 },
    { lat: 49.070772, lng: 18.5029956 },
    { lat: 49.0707754, lng: 18.5029159 },
    { lat: 49.0709783, lng: 18.5032036 },
    { lat: 49.0710157, lng: 18.5026059 },
    { lat: 49.0710154, lng: 18.5025579 },
    { lat: 49.0710271, lng: 18.5024204 },
    { lat: 49.071264, lng: 18.501757 },
    { lat: 49.0712125, lng: 18.500669 },
    { lat: 49.0712148, lng: 18.5003786 },
    { lat: 49.0714021, lng: 18.4969056 },
  ],
  Vrchteplá: [
    { lat: 49.1402453, lng: 18.5496245 },
    { lat: 49.1398173, lng: 18.550075 },
    { lat: 49.1395597, lng: 18.5502341 },
    { lat: 49.1393351, lng: 18.5506477 },
    { lat: 49.1388437, lng: 18.5508426 },
    { lat: 49.138661, lng: 18.5507171 },
    { lat: 49.1383132, lng: 18.5507517 },
    { lat: 49.1379416, lng: 18.5509716 },
    { lat: 49.1373339, lng: 18.5509557 },
    { lat: 49.1369263, lng: 18.5507571 },
    { lat: 49.1365327, lng: 18.5506548 },
    { lat: 49.1362599, lng: 18.5506142 },
    { lat: 49.1359988, lng: 18.5507941 },
    { lat: 49.1357088, lng: 18.5506855 },
    { lat: 49.1354208, lng: 18.5503443 },
    { lat: 49.1351946, lng: 18.5501032 },
    { lat: 49.1349416, lng: 18.5496563 },
    { lat: 49.1347881, lng: 18.5496545 },
    { lat: 49.1346351, lng: 18.5494352 },
    { lat: 49.1343247, lng: 18.5493046 },
    { lat: 49.1339074, lng: 18.5494074 },
    { lat: 49.1334648, lng: 18.5494626 },
    { lat: 49.1333685, lng: 18.5495168 },
    { lat: 49.1332278, lng: 18.5497304 },
    { lat: 49.132774, lng: 18.5498283 },
    { lat: 49.1325845, lng: 18.549954 },
    { lat: 49.1322905, lng: 18.5498753 },
    { lat: 49.1321309, lng: 18.5499713 },
    { lat: 49.1317598, lng: 18.550387 },
    { lat: 49.131336, lng: 18.5506013 },
    { lat: 49.1310765, lng: 18.5508105 },
    { lat: 49.1308607, lng: 18.5508851 },
    { lat: 49.1306514, lng: 18.5509362 },
    { lat: 49.1301881, lng: 18.550859 },
    { lat: 49.1300249, lng: 18.5509258 },
    { lat: 49.1297925, lng: 18.550988 },
    { lat: 49.1294012, lng: 18.5509903 },
    { lat: 49.1291343, lng: 18.5510615 },
    { lat: 49.1285036, lng: 18.5514946 },
    { lat: 49.1283865, lng: 18.551416 },
    { lat: 49.1280518, lng: 18.5509185 },
    { lat: 49.1278698, lng: 18.5507234 },
    { lat: 49.1275881, lng: 18.5504769 },
    { lat: 49.1272588, lng: 18.5500607 },
    { lat: 49.1269868, lng: 18.549534 },
    { lat: 49.126964, lng: 18.5494883 },
    { lat: 49.1269348, lng: 18.5494262 },
    { lat: 49.1269047, lng: 18.5493733 },
    { lat: 49.1268404, lng: 18.549225 },
    { lat: 49.1267014, lng: 18.5486371 },
    { lat: 49.1265369, lng: 18.547783 },
    { lat: 49.12643, lng: 18.5471183 },
    { lat: 49.1265562, lng: 18.5464077 },
    { lat: 49.1264802, lng: 18.545292 },
    { lat: 49.1263588, lng: 18.5441309 },
    { lat: 49.1257687, lng: 18.5437735 },
    { lat: 49.1254821, lng: 18.5434796 },
    { lat: 49.1250903, lng: 18.5430527 },
    { lat: 49.1247716, lng: 18.542146 },
    { lat: 49.1244453, lng: 18.5418045 },
    { lat: 49.1241456, lng: 18.5414962 },
    { lat: 49.1239828, lng: 18.5412197 },
    { lat: 49.12387, lng: 18.5416356 },
    { lat: 49.1237313, lng: 18.5419069 },
    { lat: 49.123228, lng: 18.5428212 },
    { lat: 49.1229103, lng: 18.5435364 },
    { lat: 49.1228276, lng: 18.5437607 },
    { lat: 49.1227668, lng: 18.5444725 },
    { lat: 49.1224421, lng: 18.5452054 },
    { lat: 49.1222091, lng: 18.5454631 },
    { lat: 49.1218804, lng: 18.5459757 },
    { lat: 49.1215776, lng: 18.5465097 },
    { lat: 49.1216526, lng: 18.5472076 },
    { lat: 49.1216093, lng: 18.5483051 },
    { lat: 49.121742, lng: 18.5489264 },
    { lat: 49.1217707, lng: 18.5496437 },
    { lat: 49.1212321, lng: 18.5509898 },
    { lat: 49.1209965, lng: 18.5516411 },
    { lat: 49.1211722, lng: 18.5541225 },
    { lat: 49.1211025, lng: 18.5562366 },
    { lat: 49.1213288, lng: 18.5564378 },
    { lat: 49.1219916, lng: 18.5583345 },
    { lat: 49.1220403, lng: 18.5589443 },
    { lat: 49.1224109, lng: 18.5597094 },
    { lat: 49.1225296, lng: 18.5603218 },
    { lat: 49.1225378, lng: 18.5605799 },
    { lat: 49.1225356, lng: 18.560874 },
    { lat: 49.122425, lng: 18.5613329 },
    { lat: 49.1222153, lng: 18.5620881 },
    { lat: 49.1219002, lng: 18.5629576 },
    { lat: 49.1218604, lng: 18.5630353 },
    { lat: 49.1216754, lng: 18.5636421 },
    { lat: 49.1215614, lng: 18.5642466 },
    { lat: 49.1215599, lng: 18.5647615 },
    { lat: 49.1217631, lng: 18.5656869 },
    { lat: 49.122028, lng: 18.5665443 },
    { lat: 49.1220069, lng: 18.566696 },
    { lat: 49.1217729, lng: 18.5681941 },
    { lat: 49.1219607, lng: 18.5693362 },
    { lat: 49.1221182, lng: 18.5695819 },
    { lat: 49.1229556, lng: 18.5694225 },
    { lat: 49.1243195, lng: 18.5691791 },
    { lat: 49.1250483, lng: 18.5686373 },
    { lat: 49.1250934, lng: 18.5689299 },
    { lat: 49.1251643, lng: 18.5700359 },
    { lat: 49.1251306, lng: 18.5709217 },
    { lat: 49.1250349, lng: 18.5718024 },
    { lat: 49.1248835, lng: 18.5727639 },
    { lat: 49.1244894, lng: 18.5738221 },
    { lat: 49.124301, lng: 18.574688 },
    { lat: 49.124629, lng: 18.574538 },
    { lat: 49.125361, lng: 18.574767 },
    { lat: 49.125637, lng: 18.575039 },
    { lat: 49.125952, lng: 18.575599 },
    { lat: 49.126227, lng: 18.575925 },
    { lat: 49.126528, lng: 18.576088 },
    { lat: 49.126904, lng: 18.57617 },
    { lat: 49.127179, lng: 18.576158 },
    { lat: 49.127871, lng: 18.57636 },
    { lat: 49.128348, lng: 18.576423 },
    { lat: 49.129092, lng: 18.576071 },
    { lat: 49.12942, lng: 18.575984 },
    { lat: 49.129616, lng: 18.576078 },
    { lat: 49.130137, lng: 18.576182 },
    { lat: 49.130404, lng: 18.576254 },
    { lat: 49.131032, lng: 18.576531 },
    { lat: 49.131396, lng: 18.576727 },
    { lat: 49.131772, lng: 18.576959 },
    { lat: 49.132121, lng: 18.577068 },
    { lat: 49.132345, lng: 18.577141 },
    { lat: 49.132887, lng: 18.577523 },
    { lat: 49.133846, lng: 18.577811 },
    { lat: 49.134381, lng: 18.577849 },
    { lat: 49.134595, lng: 18.577824 },
    { lat: 49.135011, lng: 18.577648 },
    { lat: 49.135439, lng: 18.577395 },
    { lat: 49.135927, lng: 18.576905 },
    { lat: 49.136247, lng: 18.57653 },
    { lat: 49.136933, lng: 18.576131 },
    { lat: 49.137651, lng: 18.575496 },
    { lat: 49.14048, lng: 18.5761 },
    { lat: 49.142482, lng: 18.575425 },
    { lat: 49.143188, lng: 18.574914 },
    { lat: 49.143056, lng: 18.573399 },
    { lat: 49.143081, lng: 18.57245 },
    { lat: 49.143191, lng: 18.571237 },
    { lat: 49.143317, lng: 18.570869 },
    { lat: 49.143458, lng: 18.569807 },
    { lat: 49.143478, lng: 18.569725 },
    { lat: 49.143765, lng: 18.568618 },
    { lat: 49.144021, lng: 18.567695 },
    { lat: 49.144111, lng: 18.567186 },
    { lat: 49.143978, lng: 18.566772 },
    { lat: 49.143982, lng: 18.565946 },
    { lat: 49.143999, lng: 18.565361 },
    { lat: 49.144075, lng: 18.564644 },
    { lat: 49.144181, lng: 18.563758 },
    { lat: 49.144336, lng: 18.563034 },
    { lat: 49.144874, lng: 18.561613 },
    { lat: 49.145151, lng: 18.560687 },
    { lat: 49.14534, lng: 18.559905 },
    { lat: 49.145354, lng: 18.559845 },
    { lat: 49.145577, lng: 18.55878 },
    { lat: 49.145883, lng: 18.557418 },
    { lat: 49.146094, lng: 18.556578 },
    { lat: 49.146245, lng: 18.555784 },
    { lat: 49.146777, lng: 18.553361 },
    { lat: 49.1460555, lng: 18.5533348 },
    { lat: 49.1458492, lng: 18.5527079 },
    { lat: 49.1454747, lng: 18.5514341 },
    { lat: 49.145167, lng: 18.5511868 },
    { lat: 49.1445396, lng: 18.5508665 },
    { lat: 49.1439325, lng: 18.5508198 },
    { lat: 49.1435508, lng: 18.5506 },
    { lat: 49.1431702, lng: 18.5505092 },
    { lat: 49.1425672, lng: 18.5505573 },
    { lat: 49.1415256, lng: 18.5503992 },
    { lat: 49.140786, lng: 18.5500094 },
    { lat: 49.1402453, lng: 18.5496245 },
  ],
  HornýLieskov: [
    { lat: 49.0752792, lng: 18.4312733 },
    { lat: 49.07534, lng: 18.4311488 },
    { lat: 49.0753778, lng: 18.4310155 },
    { lat: 49.0754192, lng: 18.4309901 },
    { lat: 49.0754189, lng: 18.4306756 },
    { lat: 49.0754626, lng: 18.4305279 },
    { lat: 49.0754354, lng: 18.4304422 },
    { lat: 49.0754208, lng: 18.4302812 },
    { lat: 49.0754663, lng: 18.4302051 },
    { lat: 49.0754903, lng: 18.4300883 },
    { lat: 49.0754807, lng: 18.4299555 },
    { lat: 49.0754887, lng: 18.429773 },
    { lat: 49.0754854, lng: 18.4295697 },
    { lat: 49.075515, lng: 18.429349 },
    { lat: 49.0755969, lng: 18.428749 },
    { lat: 49.0757051, lng: 18.4285623 },
    { lat: 49.075762, lng: 18.4284492 },
    { lat: 49.0759107, lng: 18.4280758 },
    { lat: 49.0759083, lng: 18.4279095 },
    { lat: 49.0758206, lng: 18.4276901 },
    { lat: 49.0760241, lng: 18.4271663 },
    { lat: 49.0760963, lng: 18.4270425 },
    { lat: 49.0761478, lng: 18.427014 },
    { lat: 49.0761772, lng: 18.4271399 },
    { lat: 49.0762509, lng: 18.4270853 },
    { lat: 49.0763182, lng: 18.4270382 },
    { lat: 49.0763511, lng: 18.4269176 },
    { lat: 49.0763749, lng: 18.4268378 },
    { lat: 49.076512, lng: 18.4267271 },
    { lat: 49.0766104, lng: 18.426526 },
    { lat: 49.0766156, lng: 18.4264983 },
    { lat: 49.076703, lng: 18.4262831 },
    { lat: 49.076776, lng: 18.4261133 },
    { lat: 49.0768447, lng: 18.4260509 },
    { lat: 49.0769367, lng: 18.4260001 },
    { lat: 49.0770213, lng: 18.425897 },
    { lat: 49.0770803, lng: 18.4257402 },
    { lat: 49.0772414, lng: 18.4255713 },
    { lat: 49.0772845, lng: 18.4254753 },
    { lat: 49.0773797, lng: 18.4254013 },
    { lat: 49.0774407, lng: 18.4253005 },
    { lat: 49.0775479, lng: 18.4251364 },
    { lat: 49.0776151, lng: 18.4248828 },
    { lat: 49.0775996, lng: 18.4248466 },
    { lat: 49.0775907, lng: 18.4246239 },
    { lat: 49.0776766, lng: 18.4242985 },
    { lat: 49.0777109, lng: 18.4240638 },
    { lat: 49.0776979, lng: 18.4239285 },
    { lat: 49.0776999, lng: 18.4237012 },
    { lat: 49.0777802, lng: 18.4236023 },
    { lat: 49.0777782, lng: 18.4233193 },
    { lat: 49.0778183, lng: 18.4230245 },
    { lat: 49.0778156, lng: 18.4229141 },
    { lat: 49.0777611, lng: 18.4227023 },
    { lat: 49.0777379, lng: 18.4225879 },
    { lat: 49.0777975, lng: 18.4223622 },
    { lat: 49.0778578, lng: 18.4222495 },
    { lat: 49.0778673, lng: 18.4221536 },
    { lat: 49.0778969, lng: 18.4221169 },
    { lat: 49.0778487, lng: 18.4221218 },
    { lat: 49.0777809, lng: 18.4218955 },
    { lat: 49.0777847, lng: 18.421659 },
    { lat: 49.0777803, lng: 18.4213943 },
    { lat: 49.0777475, lng: 18.4213534 },
    { lat: 49.0776837, lng: 18.4213408 },
    { lat: 49.0776575, lng: 18.421152 },
    { lat: 49.0776603, lng: 18.4209164 },
    { lat: 49.0776796, lng: 18.4208563 },
    { lat: 49.0776466, lng: 18.4208112 },
    { lat: 49.0776041, lng: 18.4208372 },
    { lat: 49.0769732, lng: 18.4206694 },
    { lat: 49.0769873, lng: 18.4203734 },
    { lat: 49.0770191, lng: 18.4199475 },
    { lat: 49.0770784, lng: 18.4195914 },
    { lat: 49.0772013, lng: 18.4190176 },
    { lat: 49.0772987, lng: 18.4184737 },
    { lat: 49.0773422, lng: 18.4180352 },
    { lat: 49.0770895, lng: 18.4179535 },
    { lat: 49.0770845, lng: 18.4175751 },
    { lat: 49.076754, lng: 18.4174743 },
    { lat: 49.0767521, lng: 18.4171536 },
    { lat: 49.0768216, lng: 18.4162664 },
    { lat: 49.0768242, lng: 18.416232 },
    { lat: 49.0766384, lng: 18.4160952 },
    { lat: 49.0764843, lng: 18.4159814 },
    { lat: 49.0763713, lng: 18.4158942 },
    { lat: 49.0763135, lng: 18.4158496 },
    { lat: 49.0761384, lng: 18.4158038 },
    { lat: 49.0759472, lng: 18.4157536 },
    { lat: 49.0759141, lng: 18.4157467 },
    { lat: 49.0756903, lng: 18.4155542 },
    { lat: 49.0756764, lng: 18.4155486 },
    { lat: 49.0755922, lng: 18.4155135 },
    { lat: 49.0753115, lng: 18.4153967 },
    { lat: 49.0752931, lng: 18.4153895 },
    { lat: 49.0752417, lng: 18.4153974 },
    { lat: 49.0752136, lng: 18.4154009 },
    { lat: 49.0751308, lng: 18.4154115 },
    { lat: 49.0748839, lng: 18.4154143 },
    { lat: 49.0745203, lng: 18.4153056 },
    { lat: 49.0744261, lng: 18.4153164 },
    { lat: 49.0742138, lng: 18.4153929 },
    { lat: 49.0740237, lng: 18.4150164 },
    { lat: 49.0738603, lng: 18.4148351 },
    { lat: 49.0737207, lng: 18.4147168 },
    { lat: 49.0736492, lng: 18.4146903 },
    { lat: 49.073392, lng: 18.4146489 },
    { lat: 49.0730418, lng: 18.4147419 },
    { lat: 49.0729521, lng: 18.4147919 },
    { lat: 49.0728434, lng: 18.4148869 },
    { lat: 49.0726357, lng: 18.4145971 },
    { lat: 49.0725856, lng: 18.4144671 },
    { lat: 49.0724898, lng: 18.4141833 },
    { lat: 49.0722882, lng: 18.4134334 },
    { lat: 49.07219, lng: 18.4134317 },
    { lat: 49.0720843, lng: 18.4134177 },
    { lat: 49.0720299, lng: 18.413372 },
    { lat: 49.0719288, lng: 18.4132369 },
    { lat: 49.0717506, lng: 18.4129726 },
    { lat: 49.0713554, lng: 18.412536 },
    { lat: 49.0712956, lng: 18.4123936 },
    { lat: 49.071243, lng: 18.4121557 },
    { lat: 49.0710793, lng: 18.4119295 },
    { lat: 49.0708664, lng: 18.4116696 },
    { lat: 49.0706366, lng: 18.4113067 },
    { lat: 49.0704895, lng: 18.4111165 },
    { lat: 49.0703816, lng: 18.4109787 },
    { lat: 49.0703122, lng: 18.4109293 },
    { lat: 49.0704508, lng: 18.4105586 },
    { lat: 49.0705006, lng: 18.4103781 },
    { lat: 49.0706248, lng: 18.4097686 },
    { lat: 49.0703409, lng: 18.4094696 },
    { lat: 49.0700281, lng: 18.4094823 },
    { lat: 49.0697821, lng: 18.4094014 },
    { lat: 49.0694348, lng: 18.4091366 },
    { lat: 49.0691986, lng: 18.4090696 },
    { lat: 49.0690797, lng: 18.4089806 },
    { lat: 49.068943, lng: 18.4089345 },
    { lat: 49.0687677, lng: 18.4090494 },
    { lat: 49.0686621, lng: 18.4090559 },
    { lat: 49.0683215, lng: 18.4091188 },
    { lat: 49.0680856, lng: 18.408995 },
    { lat: 49.0679017, lng: 18.4082976 },
    { lat: 49.0679228, lng: 18.4080488 },
    { lat: 49.0679113, lng: 18.4073309 },
    { lat: 49.067888, lng: 18.4070936 },
    { lat: 49.0678895, lng: 18.4070177 },
    { lat: 49.0679225, lng: 18.4069226 },
    { lat: 49.0679944, lng: 18.4068134 },
    { lat: 49.0682211, lng: 18.4062843 },
    { lat: 49.0683078, lng: 18.4059942 },
    { lat: 49.0683552, lng: 18.4058949 },
    { lat: 49.0683707, lng: 18.4058483 },
    { lat: 49.0684694, lng: 18.4055507 },
    { lat: 49.0683786, lng: 18.4053577 },
    { lat: 49.0682361, lng: 18.4053097 },
    { lat: 49.06814, lng: 18.405226 },
    { lat: 49.0680452, lng: 18.4051025 },
    { lat: 49.0679261, lng: 18.4048868 },
    { lat: 49.0678961, lng: 18.4047714 },
    { lat: 49.0679602, lng: 18.4046464 },
    { lat: 49.0678986, lng: 18.4045125 },
    { lat: 49.0677626, lng: 18.4043184 },
    { lat: 49.067725, lng: 18.4041873 },
    { lat: 49.0677921, lng: 18.4040344 },
    { lat: 49.0678904, lng: 18.403875 },
    { lat: 49.0679609, lng: 18.4037836 },
    { lat: 49.0680059, lng: 18.4036568 },
    { lat: 49.0680214, lng: 18.4035711 },
    { lat: 49.0679824, lng: 18.4034581 },
    { lat: 49.067584, lng: 18.4029847 },
    { lat: 49.0674408, lng: 18.4024961 },
    { lat: 49.0674298, lng: 18.4022583 },
    { lat: 49.0673964, lng: 18.4020841 },
    { lat: 49.0673342, lng: 18.4019162 },
    { lat: 49.0672912, lng: 18.4018341 },
    { lat: 49.0670306, lng: 18.4015266 },
    { lat: 49.0669801, lng: 18.4014288 },
    { lat: 49.06683, lng: 18.4012253 },
    { lat: 49.0666881, lng: 18.401146 },
    { lat: 49.0665072, lng: 18.4012218 },
    { lat: 49.0662141, lng: 18.4012867 },
    { lat: 49.0661317, lng: 18.4010008 },
    { lat: 49.0660622, lng: 18.4006003 },
    { lat: 49.0660159, lng: 18.4005181 },
    { lat: 49.0660131, lng: 18.400446 },
    { lat: 49.0659352, lng: 18.4002414 },
    { lat: 49.0659078, lng: 18.4002161 },
    { lat: 49.06584, lng: 18.4000313 },
    { lat: 49.0658394, lng: 18.399979 },
    { lat: 49.0659622, lng: 18.3997521 },
    { lat: 49.0660072, lng: 18.3996073 },
    { lat: 49.0659094, lng: 18.3994925 },
    { lat: 49.0657618, lng: 18.3991898 },
    { lat: 49.06564, lng: 18.399052 },
    { lat: 49.065573, lng: 18.39893 },
    { lat: 49.065371, lng: 18.398782 },
    { lat: 49.065136, lng: 18.398915 },
    { lat: 49.065037, lng: 18.399023 },
    { lat: 49.064918, lng: 18.398805 },
    { lat: 49.064828, lng: 18.398786 },
    { lat: 49.064705, lng: 18.398755 },
    { lat: 49.064601, lng: 18.398581 },
    { lat: 49.064466, lng: 18.398475 },
    { lat: 49.064402, lng: 18.398313 },
    { lat: 49.064335, lng: 18.398176 },
    { lat: 49.064196, lng: 18.397954 },
    { lat: 49.064106, lng: 18.397851 },
    { lat: 49.064052, lng: 18.397785 },
    { lat: 49.063957, lng: 18.397625 },
    { lat: 49.063906, lng: 18.397488 },
    { lat: 49.063897, lng: 18.397342 },
    { lat: 49.063859, lng: 18.397159 },
    { lat: 49.063773, lng: 18.397012 },
    { lat: 49.063656, lng: 18.396645 },
    { lat: 49.0636378, lng: 18.3967064 },
    { lat: 49.063112, lng: 18.3979062 },
    { lat: 49.0631001, lng: 18.3979347 },
    { lat: 49.0630985, lng: 18.3979478 },
    { lat: 49.0630757, lng: 18.3982682 },
    { lat: 49.0630852, lng: 18.3984398 },
    { lat: 49.0630734, lng: 18.3987367 },
    { lat: 49.0630684, lng: 18.3989516 },
    { lat: 49.0630104, lng: 18.399452 },
    { lat: 49.0629687, lng: 18.3999626 },
    { lat: 49.0629554, lng: 18.400212 },
    { lat: 49.0629805, lng: 18.4005023 },
    { lat: 49.0629909, lng: 18.4007718 },
    { lat: 49.0630247, lng: 18.4011571 },
    { lat: 49.0630798, lng: 18.4017049 },
    { lat: 49.0631082, lng: 18.4018498 },
    { lat: 49.0631465, lng: 18.402298 },
    { lat: 49.0632288, lng: 18.4029479 },
    { lat: 49.0632286, lng: 18.4030692 },
    { lat: 49.0632009, lng: 18.4034035 },
    { lat: 49.0630803, lng: 18.404281 },
    { lat: 49.0630257, lng: 18.4046014 },
    { lat: 49.0629779, lng: 18.4050608 },
    { lat: 49.0629747, lng: 18.4052067 },
    { lat: 49.0629842, lng: 18.4052977 },
    { lat: 49.063221, lng: 18.4060708 },
    { lat: 49.0633113, lng: 18.4062773 },
    { lat: 49.0635007, lng: 18.4066199 },
    { lat: 49.0637664, lng: 18.4071413 },
    { lat: 49.0639754, lng: 18.4074106 },
    { lat: 49.06407, lng: 18.4075713 },
    { lat: 49.0642082, lng: 18.4078877 },
    { lat: 49.0643732, lng: 18.4084653 },
    { lat: 49.0643788, lng: 18.4087294 },
    { lat: 49.0643782, lng: 18.4089629 },
    { lat: 49.0643628, lng: 18.4090915 },
    { lat: 49.0641816, lng: 18.4097739 },
    { lat: 49.0641143, lng: 18.409943 },
    { lat: 49.0639208, lng: 18.4100944 },
    { lat: 49.0636526, lng: 18.4102631 },
    { lat: 49.0635102, lng: 18.4103212 },
    { lat: 49.0634068, lng: 18.4103701 },
    { lat: 49.0634157, lng: 18.4104064 },
    { lat: 49.0634843, lng: 18.4105249 },
    { lat: 49.0635096, lng: 18.4106357 },
    { lat: 49.0635298, lng: 18.4107981 },
    { lat: 49.0635835, lng: 18.4110741 },
    { lat: 49.0636463, lng: 18.4112938 },
    { lat: 49.0636672, lng: 18.411466 },
    { lat: 49.0636655, lng: 18.4116597 },
    { lat: 49.0636531, lng: 18.4118437 },
    { lat: 49.0636266, lng: 18.4120187 },
    { lat: 49.0635942, lng: 18.4121658 },
    { lat: 49.063517, lng: 18.4124241 },
    { lat: 49.0633998, lng: 18.4127936 },
    { lat: 49.0633892, lng: 18.4130504 },
    { lat: 49.0633513, lng: 18.4132419 },
    { lat: 49.0632703, lng: 18.4133895 },
    { lat: 49.0631699, lng: 18.4135304 },
    { lat: 49.0629652, lng: 18.4139316 },
    { lat: 49.0628377, lng: 18.4141139 },
    { lat: 49.0626806, lng: 18.4143114 },
    { lat: 49.0625758, lng: 18.4144784 },
    { lat: 49.0626626, lng: 18.4149042 },
    { lat: 49.0627547, lng: 18.4155528 },
    { lat: 49.0628384, lng: 18.4158619 },
    { lat: 49.0628376, lng: 18.4160518 },
    { lat: 49.0628166, lng: 18.4161412 },
    { lat: 49.0627723, lng: 18.4161961 },
    { lat: 49.0627362, lng: 18.4163612 },
    { lat: 49.0626647, lng: 18.4165174 },
    { lat: 49.0626953, lng: 18.416641 },
    { lat: 49.0626931, lng: 18.4167843 },
    { lat: 49.0628005, lng: 18.4173186 },
    { lat: 49.0628437, lng: 18.4176521 },
    { lat: 49.0629058, lng: 18.4178172 },
    { lat: 49.0629396, lng: 18.4180205 },
    { lat: 49.0629563, lng: 18.4182201 },
    { lat: 49.062948, lng: 18.4184383 },
    { lat: 49.0628957, lng: 18.4186561 },
    { lat: 49.0628342, lng: 18.4188287 },
    { lat: 49.0627094, lng: 18.4193478 },
    { lat: 49.0626929, lng: 18.4198467 },
    { lat: 49.0626631, lng: 18.4201033 },
    { lat: 49.0626776, lng: 18.4202443 },
    { lat: 49.0626541, lng: 18.4203899 },
    { lat: 49.0625634, lng: 18.4205238 },
    { lat: 49.0623486, lng: 18.420495 },
    { lat: 49.0623221, lng: 18.4204847 },
    { lat: 49.0618924, lng: 18.420283 },
    { lat: 49.0617744, lng: 18.4201263 },
    { lat: 49.0615687, lng: 18.4197116 },
    { lat: 49.0614883, lng: 18.4195841 },
    { lat: 49.0614154, lng: 18.4193866 },
    { lat: 49.061396, lng: 18.4194222 },
    { lat: 49.0612858, lng: 18.4197564 },
    { lat: 49.0611216, lng: 18.4204184 },
    { lat: 49.0610353, lng: 18.4207578 },
    { lat: 49.0608437, lng: 18.4214125 },
    { lat: 49.060682, lng: 18.4218368 },
    { lat: 49.0606252, lng: 18.4219926 },
    { lat: 49.0603421, lng: 18.4217471 },
    { lat: 49.060103, lng: 18.4215647 },
    { lat: 49.0597103, lng: 18.4213567 },
    { lat: 49.0594506, lng: 18.4211676 },
    { lat: 49.0592887, lng: 18.4210976 },
    { lat: 49.0589614, lng: 18.4219523 },
    { lat: 49.0589494, lng: 18.4219812 },
    { lat: 49.0587886, lng: 18.4225006 },
    { lat: 49.0589326, lng: 18.4225752 },
    { lat: 49.0591358, lng: 18.4226196 },
    { lat: 49.0596155, lng: 18.4228687 },
    { lat: 49.0597224, lng: 18.4230291 },
    { lat: 49.0597495, lng: 18.4231728 },
    { lat: 49.0598132, lng: 18.4233434 },
    { lat: 49.0597428, lng: 18.4234398 },
    { lat: 49.0594324, lng: 18.4233122 },
    { lat: 49.0593589, lng: 18.4235137 },
    { lat: 49.0593162, lng: 18.4238832 },
    { lat: 49.0592522, lng: 18.4240356 },
    { lat: 49.0592318, lng: 18.4241148 },
    { lat: 49.0592207, lng: 18.4241576 },
    { lat: 49.0592055, lng: 18.4241883 },
    { lat: 49.058969, lng: 18.4240941 },
    { lat: 49.058961, lng: 18.4240908 },
    { lat: 49.0589576, lng: 18.4241126 },
    { lat: 49.0588953, lng: 18.4244344 },
    { lat: 49.0588866, lng: 18.4244812 },
    { lat: 49.0588636, lng: 18.4245757 },
    { lat: 49.0588589, lng: 18.4247146 },
    { lat: 49.0588861, lng: 18.4250251 },
    { lat: 49.058926, lng: 18.4255027 },
    { lat: 49.0589656, lng: 18.4256676 },
    { lat: 49.0589832, lng: 18.4258867 },
    { lat: 49.0589745, lng: 18.4260346 },
    { lat: 49.0589456, lng: 18.4261232 },
    { lat: 49.0589037, lng: 18.4262016 },
    { lat: 49.0588726, lng: 18.4263127 },
    { lat: 49.0587731, lng: 18.4265547 },
    { lat: 49.0587164, lng: 18.4267542 },
    { lat: 49.0585965, lng: 18.4272202 },
    { lat: 49.0584539, lng: 18.4275373 },
    { lat: 49.0583241, lng: 18.4277406 },
    { lat: 49.0581034, lng: 18.428159 },
    { lat: 49.0577856, lng: 18.428695 },
    { lat: 49.0577608, lng: 18.4287563 },
    { lat: 49.057674, lng: 18.428962 },
    { lat: 49.0575262, lng: 18.4294328 },
    { lat: 49.057509, lng: 18.4294894 },
    { lat: 49.0574985, lng: 18.4295023 },
    { lat: 49.0574047, lng: 18.4297238 },
    { lat: 49.0573051, lng: 18.4299622 },
    { lat: 49.0571653, lng: 18.4300681 },
    { lat: 49.0571326, lng: 18.4301504 },
    { lat: 49.0571029, lng: 18.4303063 },
    { lat: 49.0570654, lng: 18.4304035 },
    { lat: 49.0569433, lng: 18.4305619 },
    { lat: 49.0568786, lng: 18.430677 },
    { lat: 49.0568411, lng: 18.430756 },
    { lat: 49.0567853, lng: 18.430704 },
    { lat: 49.056661, lng: 18.4309258 },
    { lat: 49.0563571, lng: 18.4313066 },
    { lat: 49.0562138, lng: 18.4314689 },
    { lat: 49.0561742, lng: 18.4315096 },
    { lat: 49.0560215, lng: 18.4316035 },
    { lat: 49.0556812, lng: 18.431794 },
    { lat: 49.0554821, lng: 18.4313936 },
    { lat: 49.0552219, lng: 18.4308297 },
    { lat: 49.0548567, lng: 18.4300988 },
    { lat: 49.0547822, lng: 18.4298916 },
    { lat: 49.0547272, lng: 18.429938 },
    { lat: 49.0544728, lng: 18.4301521 },
    { lat: 49.0543051, lng: 18.4302612 },
    { lat: 49.0541493, lng: 18.4304624 },
    { lat: 49.0540979, lng: 18.4306142 },
    { lat: 49.054045, lng: 18.4308645 },
    { lat: 49.0540542, lng: 18.43101 },
    { lat: 49.0540548, lng: 18.4311227 },
    { lat: 49.0540555, lng: 18.4313805 },
    { lat: 49.0538925, lng: 18.4321895 },
    { lat: 49.0541207, lng: 18.432483 },
    { lat: 49.0542128, lng: 18.4326386 },
    { lat: 49.0543571, lng: 18.432924 },
    { lat: 49.0544496, lng: 18.4331276 },
    { lat: 49.054968, lng: 18.4344303 },
    { lat: 49.0551095, lng: 18.4347061 },
    { lat: 49.0552536, lng: 18.4348843 },
    { lat: 49.0556428, lng: 18.4352123 },
    { lat: 49.0557766, lng: 18.4352665 },
    { lat: 49.0559494, lng: 18.435371 },
    { lat: 49.0561312, lng: 18.4355383 },
    { lat: 49.0561062, lng: 18.435617 },
    { lat: 49.0560444, lng: 18.4357871 },
    { lat: 49.0556719, lng: 18.4368481 },
    { lat: 49.0551239, lng: 18.4381426 },
    { lat: 49.0550158, lng: 18.4383911 },
    { lat: 49.0544628, lng: 18.4396367 },
    { lat: 49.0541422, lng: 18.4393262 },
    { lat: 49.0537049, lng: 18.4387367 },
    { lat: 49.0537935, lng: 18.4385841 },
    { lat: 49.0536327, lng: 18.4381622 },
    { lat: 49.0534664, lng: 18.4379719 },
    { lat: 49.0533759, lng: 18.4381086 },
    { lat: 49.0529747, lng: 18.4375405 },
    { lat: 49.0525593, lng: 18.4371489 },
    { lat: 49.0523446, lng: 18.4376537 },
    { lat: 49.0530122, lng: 18.4386944 },
    { lat: 49.0535995, lng: 18.4395288 },
    { lat: 49.0540292, lng: 18.4403884 },
    { lat: 49.0542638, lng: 18.4408579 },
    { lat: 49.054497, lng: 18.4413256 },
    { lat: 49.0550229, lng: 18.4422527 },
    { lat: 49.0550517, lng: 18.4423041 },
    { lat: 49.0557048, lng: 18.4434509 },
    { lat: 49.0557304, lng: 18.4434869 },
    { lat: 49.0563176, lng: 18.4443192 },
    { lat: 49.0563324, lng: 18.4443403 },
    { lat: 49.0563764, lng: 18.4444422 },
    { lat: 49.0568877, lng: 18.4452475 },
    { lat: 49.0569187, lng: 18.4452792 },
    { lat: 49.0569391, lng: 18.4453219 },
    { lat: 49.0569966, lng: 18.4454011 },
    { lat: 49.0572672, lng: 18.4457923 },
    { lat: 49.0575788, lng: 18.4462882 },
    { lat: 49.0576854, lng: 18.4464428 },
    { lat: 49.0578596, lng: 18.4466949 },
    { lat: 49.05813, lng: 18.4471011 },
    { lat: 49.0584828, lng: 18.4476304 },
    { lat: 49.058805, lng: 18.448115 },
    { lat: 49.0591001, lng: 18.4485577 },
    { lat: 49.0591604, lng: 18.4486478 },
    { lat: 49.0593512, lng: 18.4489805 },
    { lat: 49.0594507, lng: 18.4492126 },
    { lat: 49.0597057, lng: 18.4498075 },
    { lat: 49.0597587, lng: 18.4499302 },
    { lat: 49.0597818, lng: 18.4500025 },
    { lat: 49.0597995, lng: 18.4501808 },
    { lat: 49.0598221, lng: 18.4501608 },
    { lat: 49.0598844, lng: 18.450104 },
    { lat: 49.0604217, lng: 18.4496182 },
    { lat: 49.0603602, lng: 18.4492171 },
    { lat: 49.0603422, lng: 18.4490943 },
    { lat: 49.0612027, lng: 18.4483115 },
    { lat: 49.0612307, lng: 18.4482865 },
    { lat: 49.0613091, lng: 18.4482152 },
    { lat: 49.0622304, lng: 18.4472667 },
    { lat: 49.0626629, lng: 18.4469271 },
    { lat: 49.0626987, lng: 18.4468994 },
    { lat: 49.0635805, lng: 18.4466688 },
    { lat: 49.0636261, lng: 18.4466568 },
    { lat: 49.0640998, lng: 18.446533 },
    { lat: 49.0644633, lng: 18.4461276 },
    { lat: 49.0648166, lng: 18.4453925 },
    { lat: 49.0648357, lng: 18.4453517 },
    { lat: 49.0648935, lng: 18.4452321 },
    { lat: 49.0650916, lng: 18.4446704 },
    { lat: 49.0651341, lng: 18.4445623 },
    { lat: 49.0652858, lng: 18.4443453 },
    { lat: 49.0653929, lng: 18.4442214 },
    { lat: 49.0654485, lng: 18.4441657 },
    { lat: 49.0655603, lng: 18.4440655 },
    { lat: 49.0657561, lng: 18.443915 },
    { lat: 49.0659546, lng: 18.4437484 },
    { lat: 49.0660452, lng: 18.4436327 },
    { lat: 49.0662458, lng: 18.4433814 },
    { lat: 49.0664473, lng: 18.4431287 },
    { lat: 49.0668776, lng: 18.4427687 },
    { lat: 49.067075, lng: 18.4424818 },
    { lat: 49.0676321, lng: 18.4421529 },
    { lat: 49.0676867, lng: 18.4419334 },
    { lat: 49.0681108, lng: 18.4415858 },
    { lat: 49.068811, lng: 18.4410253 },
    { lat: 49.0697552, lng: 18.4402862 },
    { lat: 49.0701927, lng: 18.439935 },
    { lat: 49.0707711, lng: 18.4393771 },
    { lat: 49.0710665, lng: 18.4388017 },
    { lat: 49.0714157, lng: 18.4382379 },
    { lat: 49.0714076, lng: 18.4382139 },
    { lat: 49.0714698, lng: 18.438157 },
    { lat: 49.07197, lng: 18.4372228 },
    { lat: 49.0719989, lng: 18.4371727 },
    { lat: 49.072116, lng: 18.4370446 },
    { lat: 49.0722386, lng: 18.4367942 },
    { lat: 49.0724131, lng: 18.4366448 },
    { lat: 49.0724509, lng: 18.4365532 },
    { lat: 49.0726696, lng: 18.4364266 },
    { lat: 49.0726789, lng: 18.4362473 },
    { lat: 49.0729249, lng: 18.4360837 },
    { lat: 49.0729162, lng: 18.435926 },
    { lat: 49.0731944, lng: 18.4357962 },
    { lat: 49.0731893, lng: 18.4355594 },
    { lat: 49.0732016, lng: 18.4355167 },
    { lat: 49.0732894, lng: 18.4354308 },
    { lat: 49.0733283, lng: 18.4354417 },
    { lat: 49.0734589, lng: 18.435295 },
    { lat: 49.073512, lng: 18.435029 },
    { lat: 49.0734879, lng: 18.4348379 },
    { lat: 49.0736362, lng: 18.434805 },
    { lat: 49.0737479, lng: 18.4347254 },
    { lat: 49.0738971, lng: 18.434361 },
    { lat: 49.0740184, lng: 18.434352 },
    { lat: 49.0740561, lng: 18.4342779 },
    { lat: 49.0740742, lng: 18.434115 },
    { lat: 49.0742289, lng: 18.4339348 },
    { lat: 49.0743758, lng: 18.4338993 },
    { lat: 49.0744107, lng: 18.4338347 },
    { lat: 49.0743949, lng: 18.4336299 },
    { lat: 49.0745405, lng: 18.4335096 },
    { lat: 49.074577, lng: 18.4333722 },
    { lat: 49.0745694, lng: 18.4332771 },
    { lat: 49.0746741, lng: 18.4330869 },
    { lat: 49.0746416, lng: 18.4329261 },
    { lat: 49.0745568, lng: 18.4327603 },
    { lat: 49.0745623, lng: 18.4326754 },
    { lat: 49.0746004, lng: 18.4326284 },
    { lat: 49.0747136, lng: 18.4324121 },
    { lat: 49.0748831, lng: 18.4323344 },
    { lat: 49.0748867, lng: 18.4322571 },
    { lat: 49.0748637, lng: 18.4321668 },
    { lat: 49.0748638, lng: 18.4321278 },
    { lat: 49.0750644, lng: 18.4318777 },
    { lat: 49.0751114, lng: 18.4317484 },
    { lat: 49.075081, lng: 18.4316895 },
    { lat: 49.0750977, lng: 18.4316647 },
    { lat: 49.0751426, lng: 18.4316187 },
    { lat: 49.0751632, lng: 18.4314408 },
    { lat: 49.0752792, lng: 18.4312733 },
  ],
  Podskalie: [
    { lat: 49.0465437, lng: 18.4934004 },
    { lat: 49.0466771, lng: 18.493242 },
    { lat: 49.0469455, lng: 18.4929109 },
    { lat: 49.0473436, lng: 18.4923928 },
    { lat: 49.0475303, lng: 18.4921566 },
    { lat: 49.0475653, lng: 18.4921128 },
    { lat: 49.0479882, lng: 18.4915757 },
    { lat: 49.0486413, lng: 18.4908116 },
    { lat: 49.049058, lng: 18.4903243 },
    { lat: 49.0490929, lng: 18.4902825 },
    { lat: 49.0495963, lng: 18.4896874 },
    { lat: 49.0499466, lng: 18.4891005 },
    { lat: 49.0507429, lng: 18.4881095 },
    { lat: 49.0509312, lng: 18.4880054 },
    { lat: 49.0512662, lng: 18.4879002 },
    { lat: 49.0516035, lng: 18.4878198 },
    { lat: 49.0523199, lng: 18.4879036 },
    { lat: 49.0528087, lng: 18.4881392 },
    { lat: 49.0533286, lng: 18.4882392 },
    { lat: 49.0535568, lng: 18.4879176 },
    { lat: 49.0543757, lng: 18.4878537 },
    { lat: 49.0544247, lng: 18.4874105 },
    { lat: 49.0543422, lng: 18.4869315 },
    { lat: 49.054408, lng: 18.486338 },
    { lat: 49.0543512, lng: 18.485403 },
    { lat: 49.0543503, lng: 18.4851799 },
    { lat: 49.0542652, lng: 18.484862 },
    { lat: 49.054279, lng: 18.4845983 },
    { lat: 49.0542802, lng: 18.4842886 },
    { lat: 49.0542816, lng: 18.4833845 },
    { lat: 49.0542424, lng: 18.4822761 },
    { lat: 49.0542094, lng: 18.4810963 },
    { lat: 49.054262, lng: 18.4802024 },
    { lat: 49.0543358, lng: 18.4800036 },
    { lat: 49.0543367, lng: 18.4800008 },
    { lat: 49.0543551, lng: 18.4799659 },
    { lat: 49.0547755, lng: 18.4791561 },
    { lat: 49.055318, lng: 18.4788445 },
    { lat: 49.0557039, lng: 18.4787 },
    { lat: 49.0562246, lng: 18.4785916 },
    { lat: 49.0565563, lng: 18.4785501 },
    { lat: 49.0569453, lng: 18.4787557 },
    { lat: 49.0576013, lng: 18.4787447 },
    { lat: 49.0583735, lng: 18.4787804 },
    { lat: 49.0591862, lng: 18.4788718 },
    { lat: 49.0595798, lng: 18.4782499 },
    { lat: 49.0599253, lng: 18.4777823 },
    { lat: 49.0603527, lng: 18.4771012 },
    { lat: 49.0609827, lng: 18.4761434 },
    { lat: 49.0610738, lng: 18.4759125 },
    { lat: 49.0619686, lng: 18.4746589 },
    { lat: 49.0616259, lng: 18.4742939 },
    { lat: 49.0617655, lng: 18.4737919 },
    { lat: 49.0620364, lng: 18.4725186 },
    { lat: 49.0623068, lng: 18.4712753 },
    { lat: 49.0625236, lng: 18.4701279 },
    { lat: 49.0628608, lng: 18.4688483 },
    { lat: 49.0629684, lng: 18.4682806 },
    { lat: 49.0630763, lng: 18.467718 },
    { lat: 49.0631114, lng: 18.4675347 },
    { lat: 49.0631996, lng: 18.4666329 },
    { lat: 49.0631715, lng: 18.466428 },
    { lat: 49.0632883, lng: 18.4654724 },
    { lat: 49.0634516, lng: 18.4648315 },
    { lat: 49.0634023, lng: 18.4632497 },
    { lat: 49.0632425, lng: 18.4622288 },
    { lat: 49.062831, lng: 18.4609597 },
    { lat: 49.0623281, lng: 18.4598722 },
    { lat: 49.0618044, lng: 18.4587565 },
    { lat: 49.0617643, lng: 18.4586611 },
    { lat: 49.0617422, lng: 18.4586087 },
    { lat: 49.0613259, lng: 18.4575999 },
    { lat: 49.0610868, lng: 18.4569848 },
    { lat: 49.061067, lng: 18.4569316 },
    { lat: 49.0608633, lng: 18.4564065 },
    { lat: 49.0604307, lng: 18.4552252 },
    { lat: 49.0604281, lng: 18.4552174 },
    { lat: 49.0604164, lng: 18.4551908 },
    { lat: 49.0602229, lng: 18.4547076 },
    { lat: 49.0599733, lng: 18.4533865 },
    { lat: 49.0599204, lng: 18.4531415 },
    { lat: 49.0599096, lng: 18.4530889 },
    { lat: 49.0597734, lng: 18.452458 },
    { lat: 49.0594788, lng: 18.450728 },
    { lat: 49.0594953, lng: 18.4507006 },
    { lat: 49.0597995, lng: 18.4501808 },
    { lat: 49.0597818, lng: 18.4500025 },
    { lat: 49.0597587, lng: 18.4499302 },
    { lat: 49.0597057, lng: 18.4498075 },
    { lat: 49.0594507, lng: 18.4492126 },
    { lat: 49.0593512, lng: 18.4489805 },
    { lat: 49.0591604, lng: 18.4486478 },
    { lat: 49.0591001, lng: 18.4485577 },
    { lat: 49.058805, lng: 18.448115 },
    { lat: 49.0584828, lng: 18.4476304 },
    { lat: 49.05813, lng: 18.4471011 },
    { lat: 49.0578596, lng: 18.4466949 },
    { lat: 49.0576854, lng: 18.4464428 },
    { lat: 49.0575788, lng: 18.4462882 },
    { lat: 49.0572672, lng: 18.4457923 },
    { lat: 49.0569966, lng: 18.4454011 },
    { lat: 49.0569391, lng: 18.4453219 },
    { lat: 49.0569187, lng: 18.4452792 },
    { lat: 49.0568877, lng: 18.4452475 },
    { lat: 49.0563764, lng: 18.4444422 },
    { lat: 49.0563324, lng: 18.4443403 },
    { lat: 49.0563176, lng: 18.4443192 },
    { lat: 49.0557304, lng: 18.4434869 },
    { lat: 49.0557048, lng: 18.4434509 },
    { lat: 49.0543442, lng: 18.4449459 },
    { lat: 49.0543039, lng: 18.4449901 },
    { lat: 49.0535206, lng: 18.4460276 },
    { lat: 49.0526644, lng: 18.4472591 },
    { lat: 49.0525496, lng: 18.4474286 },
    { lat: 49.0518366, lng: 18.4484736 },
    { lat: 49.0515829, lng: 18.448188 },
    { lat: 49.0510553, lng: 18.4479474 },
    { lat: 49.0502588, lng: 18.4474121 },
    { lat: 49.0500022, lng: 18.4475032 },
    { lat: 49.0494881, lng: 18.4473248 },
    { lat: 49.04857, lng: 18.4470992 },
    { lat: 49.0478551, lng: 18.4469209 },
    { lat: 49.0472101, lng: 18.4468436 },
    { lat: 49.0468216, lng: 18.446897 },
    { lat: 49.0467945, lng: 18.4469773 },
    { lat: 49.0466712, lng: 18.44734 },
    { lat: 49.0462575, lng: 18.4478603 },
    { lat: 49.0456184, lng: 18.448299 },
    { lat: 49.0449713, lng: 18.4492709 },
    { lat: 49.0445529, lng: 18.4501959 },
    { lat: 49.0439551, lng: 18.4513246 },
    { lat: 49.0438582, lng: 18.4506699 },
    { lat: 49.0434644, lng: 18.4501753 },
    { lat: 49.0426814, lng: 18.4494547 },
    { lat: 49.0416797, lng: 18.4485516 },
    { lat: 49.0412109, lng: 18.4478407 },
    { lat: 49.0411555, lng: 18.4479833 },
    { lat: 49.040967, lng: 18.448474 },
    { lat: 49.0408568, lng: 18.4485195 },
    { lat: 49.0407459, lng: 18.4482896 },
    { lat: 49.0404866, lng: 18.4485374 },
    { lat: 49.0404149, lng: 18.4485877 },
    { lat: 49.040004, lng: 18.4488687 },
    { lat: 49.0397453, lng: 18.4490457 },
    { lat: 49.0397078, lng: 18.4490813 },
    { lat: 49.0396659, lng: 18.4491213 },
    { lat: 49.0396231, lng: 18.4491623 },
    { lat: 49.0394173, lng: 18.4493589 },
    { lat: 49.0392635, lng: 18.4494338 },
    { lat: 49.0390976, lng: 18.4495139 },
    { lat: 49.0390767, lng: 18.4495241 },
    { lat: 49.0388812, lng: 18.4496191 },
    { lat: 49.0388537, lng: 18.4496323 },
    { lat: 49.038802, lng: 18.4496572 },
    { lat: 49.0377076, lng: 18.4512131 },
    { lat: 49.0368203, lng: 18.4524737 },
    { lat: 49.0373054, lng: 18.4537897 },
    { lat: 49.0372365, lng: 18.4544025 },
    { lat: 49.0363987, lng: 18.4566484 },
    { lat: 49.0362697, lng: 18.4570297 },
    { lat: 49.0360901, lng: 18.4584838 },
    { lat: 49.036166, lng: 18.4588804 },
    { lat: 49.035891, lng: 18.459708 },
    { lat: 49.035867, lng: 18.4601745 },
    { lat: 49.0362379, lng: 18.4608892 },
    { lat: 49.0363441, lng: 18.4622509 },
    { lat: 49.0364522, lng: 18.4625754 },
    { lat: 49.0365604, lng: 18.462902 },
    { lat: 49.0375787, lng: 18.4652001 },
    { lat: 49.0378716, lng: 18.4702147 },
    { lat: 49.0378804, lng: 18.4702325 },
    { lat: 49.0379048, lng: 18.4702844 },
    { lat: 49.0380194, lng: 18.4705244 },
    { lat: 49.0380271, lng: 18.4705407 },
    { lat: 49.0380349, lng: 18.4705581 },
    { lat: 49.0380437, lng: 18.4705767 },
    { lat: 49.0380564, lng: 18.4706021 },
    { lat: 49.0389418, lng: 18.4708896 },
    { lat: 49.0389767, lng: 18.4714651 },
    { lat: 49.0388982, lng: 18.4724192 },
    { lat: 49.0388195, lng: 18.4731675 },
    { lat: 49.0387806, lng: 18.4740216 },
    { lat: 49.03866, lng: 18.4747858 },
    { lat: 49.0390317, lng: 18.4751466 },
    { lat: 49.039621, lng: 18.4754426 },
    { lat: 49.040138, lng: 18.4770565 },
    { lat: 49.0396222, lng: 18.4772383 },
    { lat: 49.0387624, lng: 18.4779131 },
    { lat: 49.0364348, lng: 18.4790167 },
    { lat: 49.0363907, lng: 18.4789935 },
    { lat: 49.0363478, lng: 18.4789714 },
    { lat: 49.0363234, lng: 18.4789578 },
    { lat: 49.0362796, lng: 18.4792725 },
    { lat: 49.0362747, lng: 18.4796768 },
    { lat: 49.0363026, lng: 18.4804556 },
    { lat: 49.0362626, lng: 18.4807738 },
    { lat: 49.0360532, lng: 18.4813187 },
    { lat: 49.0359439, lng: 18.4817118 },
    { lat: 49.0358598, lng: 18.4821955 },
    { lat: 49.0359129, lng: 18.4830628 },
    { lat: 49.0359962, lng: 18.4842163 },
    { lat: 49.0358815, lng: 18.484758 },
    { lat: 49.0358964, lng: 18.4847844 },
    { lat: 49.0359292, lng: 18.4848485 },
    { lat: 49.0359494, lng: 18.4848859 },
    { lat: 49.0365955, lng: 18.4851719 },
    { lat: 49.0370249, lng: 18.4854347 },
    { lat: 49.0373241, lng: 18.4857487 },
    { lat: 49.0377545, lng: 18.4861093 },
    { lat: 49.0382372, lng: 18.4864592 },
    { lat: 49.038716, lng: 18.4868427 },
    { lat: 49.0391879, lng: 18.4869926 },
    { lat: 49.0394721, lng: 18.4871364 },
    { lat: 49.0398861, lng: 18.487529 },
    { lat: 49.0401311, lng: 18.4875345 },
    { lat: 49.0402155, lng: 18.4882731 },
    { lat: 49.0402997, lng: 18.4888656 },
    { lat: 49.0403495, lng: 18.489941 },
    { lat: 49.0403906, lng: 18.4910043 },
    { lat: 49.0405011, lng: 18.4918716 },
    { lat: 49.0406501, lng: 18.4919559 },
    { lat: 49.0413432, lng: 18.4925461 },
    { lat: 49.0425972, lng: 18.4921887 },
    { lat: 49.0437889, lng: 18.492052 },
    { lat: 49.0440572, lng: 18.4922147 },
    { lat: 49.044427, lng: 18.4923149 },
    { lat: 49.0447498, lng: 18.492608 },
    { lat: 49.0464161, lng: 18.4932347 },
    { lat: 49.0465437, lng: 18.4934004 },
  ],
  Slopná: [
    { lat: 49.0452497, lng: 18.4279177 },
    { lat: 49.0454597, lng: 18.4277572 },
    { lat: 49.0455345, lng: 18.4272116 },
    { lat: 49.0458187, lng: 18.427353 },
    { lat: 49.0461989, lng: 18.4271302 },
    { lat: 49.0463048, lng: 18.4268614 },
    { lat: 49.0461762, lng: 18.4266146 },
    { lat: 49.046226, lng: 18.4263533 },
    { lat: 49.0463572, lng: 18.4261967 },
    { lat: 49.0466335, lng: 18.4266244 },
    { lat: 49.0468743, lng: 18.4259188 },
    { lat: 49.0468605, lng: 18.4255869 },
    { lat: 49.0469787, lng: 18.4255614 },
    { lat: 49.0469546, lng: 18.4255116 },
    { lat: 49.0469267, lng: 18.4254551 },
    { lat: 49.0472874, lng: 18.4255126 },
    { lat: 49.0473771, lng: 18.4251354 },
    { lat: 49.0472336, lng: 18.4247636 },
    { lat: 49.0472202, lng: 18.424479 },
    { lat: 49.0476378, lng: 18.4236853 },
    { lat: 49.0478897, lng: 18.4230996 },
    { lat: 49.0481664, lng: 18.4227784 },
    { lat: 49.0482967, lng: 18.4222792 },
    { lat: 49.0486459, lng: 18.4214937 },
    { lat: 49.0488485, lng: 18.4215042 },
    { lat: 49.048902, lng: 18.4213898 },
    { lat: 49.0488575, lng: 18.4209934 },
    { lat: 49.0490277, lng: 18.4204794 },
    { lat: 49.0492923, lng: 18.4201431 },
    { lat: 49.0495811, lng: 18.4201033 },
    { lat: 49.0496969, lng: 18.4197494 },
    { lat: 49.0498953, lng: 18.4196246 },
    { lat: 49.0499639, lng: 18.4193351 },
    { lat: 49.0503981, lng: 18.4190872 },
    { lat: 49.0505685, lng: 18.4191297 },
    { lat: 49.0507589, lng: 18.4188599 },
    { lat: 49.0509597, lng: 18.4187982 },
    { lat: 49.0517359, lng: 18.4180437 },
    { lat: 49.0520969, lng: 18.4174542 },
    { lat: 49.0521227, lng: 18.4173275 },
    { lat: 49.0522975, lng: 18.4164702 },
    { lat: 49.0524101, lng: 18.4159122 },
    { lat: 49.052526, lng: 18.4153144 },
    { lat: 49.0525338, lng: 18.415294 },
    { lat: 49.0525346, lng: 18.4152892 },
    { lat: 49.0525623, lng: 18.415217 },
    { lat: 49.052683, lng: 18.4148926 },
    { lat: 49.0528936, lng: 18.4143336 },
    { lat: 49.0530239, lng: 18.4136693 },
    { lat: 49.053362, lng: 18.4134186 },
    { lat: 49.0533582, lng: 18.4133705 },
    { lat: 49.0533361, lng: 18.4130748 },
    { lat: 49.0533346, lng: 18.4130473 },
    { lat: 49.0535132, lng: 18.4126046 },
    { lat: 49.0535984, lng: 18.4123906 },
    { lat: 49.0536788, lng: 18.4121924 },
    { lat: 49.0536644, lng: 18.4120553 },
    { lat: 49.0536101, lng: 18.4115224 },
    { lat: 49.0536877, lng: 18.411292 },
    { lat: 49.0535442, lng: 18.4111035 },
    { lat: 49.0534495, lng: 18.410474 },
    { lat: 49.0534818, lng: 18.4102627 },
    { lat: 49.053486, lng: 18.4102352 },
    { lat: 49.0534944, lng: 18.4101818 },
    { lat: 49.0535027, lng: 18.4101291 },
    { lat: 49.0535086, lng: 18.410092 },
    { lat: 49.0535126, lng: 18.4100631 },
    { lat: 49.0535176, lng: 18.4100315 },
    { lat: 49.0535227, lng: 18.4100009 },
    { lat: 49.0536384, lng: 18.4096244 },
    { lat: 49.053675, lng: 18.4095321 },
    { lat: 49.0538536, lng: 18.4091523 },
    { lat: 49.0540273, lng: 18.4087837 },
    { lat: 49.0540931, lng: 18.4082407 },
    { lat: 49.0542106, lng: 18.4081618 },
    { lat: 49.0543846, lng: 18.4080665 },
    { lat: 49.0545853, lng: 18.408021 },
    { lat: 49.0547314, lng: 18.4078081 },
    { lat: 49.0549097, lng: 18.4076874 },
    { lat: 49.0550106, lng: 18.4074511 },
    { lat: 49.0547629, lng: 18.407317 },
    { lat: 49.0546162, lng: 18.4065419 },
    { lat: 49.0546728, lng: 18.406381 },
    { lat: 49.0544921, lng: 18.4063367 },
    { lat: 49.0545312, lng: 18.4058221 },
    { lat: 49.0545539, lng: 18.4057616 },
    { lat: 49.0547414, lng: 18.4057671 },
    { lat: 49.054885, lng: 18.4058548 },
    { lat: 49.0551867, lng: 18.4057003 },
    { lat: 49.055142, lng: 18.4054424 },
    { lat: 49.0553246, lng: 18.4053385 },
    { lat: 49.0555755, lng: 18.4053865 },
    { lat: 49.0556183, lng: 18.4050395 },
    { lat: 49.0555111, lng: 18.4047128 },
    { lat: 49.0556549, lng: 18.4045599 },
    { lat: 49.0558113, lng: 18.4045728 },
    { lat: 49.0558624, lng: 18.4044977 },
    { lat: 49.0558361, lng: 18.4041247 },
    { lat: 49.0555303, lng: 18.4038159 },
    { lat: 49.0555146, lng: 18.403717 },
    { lat: 49.0555805, lng: 18.4030718 },
    { lat: 49.0556557, lng: 18.4029646 },
    { lat: 49.0558585, lng: 18.4028782 },
    { lat: 49.0560316, lng: 18.4022543 },
    { lat: 49.0560241, lng: 18.4019567 },
    { lat: 49.0560606, lng: 18.4018623 },
    { lat: 49.0562564, lng: 18.4018523 },
    { lat: 49.0564475, lng: 18.4019222 },
    { lat: 49.056693, lng: 18.4017516 },
    { lat: 49.0568734, lng: 18.4012218 },
    { lat: 49.0569898, lng: 18.4003906 },
    { lat: 49.0571594, lng: 18.399927 },
    { lat: 49.057337, lng: 18.3997132 },
    { lat: 49.0573912, lng: 18.39951 },
    { lat: 49.0574213, lng: 18.3994612 },
    { lat: 49.0574471, lng: 18.3994205 },
    { lat: 49.057685, lng: 18.3989699 },
    { lat: 49.0577805, lng: 18.3979832 },
    { lat: 49.0579682, lng: 18.3975437 },
    { lat: 49.0583773, lng: 18.3974358 },
    { lat: 49.0584706, lng: 18.396717 },
    { lat: 49.0587472, lng: 18.3965388 },
    { lat: 49.0589906, lng: 18.3962478 },
    { lat: 49.0591168, lng: 18.3959608 },
    { lat: 49.0594343, lng: 18.3959484 },
    { lat: 49.0596051, lng: 18.3956729 },
    { lat: 49.0594613, lng: 18.3954394 },
    { lat: 49.0597852, lng: 18.3946065 },
    { lat: 49.0599068, lng: 18.3945429 },
    { lat: 49.0599752, lng: 18.3945542 },
    { lat: 49.0600866, lng: 18.3943468 },
    { lat: 49.0599419, lng: 18.3939319 },
    { lat: 49.0599755, lng: 18.3936857 },
    { lat: 49.0598479, lng: 18.3934578 },
    { lat: 49.0597691, lng: 18.3933185 },
    { lat: 49.0597423, lng: 18.3929989 },
    { lat: 49.0600085, lng: 18.3923474 },
    { lat: 49.05994, lng: 18.392252 },
    { lat: 49.059894, lng: 18.392148 },
    { lat: 49.059925, lng: 18.391967 },
    { lat: 49.060442, lng: 18.390877 },
    { lat: 49.060477, lng: 18.390539 },
    { lat: 49.060417, lng: 18.390363 },
    { lat: 49.060333, lng: 18.390119 },
    { lat: 49.060456, lng: 18.389886 },
    { lat: 49.060578, lng: 18.389848 },
    { lat: 49.060609, lng: 18.38984 },
    { lat: 49.060654, lng: 18.389831 },
    { lat: 49.060772, lng: 18.389808 },
    { lat: 49.061022, lng: 18.389476 },
    { lat: 49.06085, lng: 18.38939 },
    { lat: 49.060636, lng: 18.389282 },
    { lat: 49.060561, lng: 18.389215 },
    { lat: 49.060556, lng: 18.389207 },
    { lat: 49.060507, lng: 18.389161 },
    { lat: 49.060451, lng: 18.389107 },
    { lat: 49.060208, lng: 18.388764 },
    { lat: 49.060174, lng: 18.388735 },
    { lat: 49.060119, lng: 18.388671 },
    { lat: 49.059864, lng: 18.388363 },
    { lat: 49.059652, lng: 18.388194 },
    { lat: 49.059453, lng: 18.387886 },
    { lat: 49.059075, lng: 18.387164 },
    { lat: 49.058831, lng: 18.38711 },
    { lat: 49.058489, lng: 18.387244 },
    { lat: 49.058311, lng: 18.38726 },
    { lat: 49.057981, lng: 18.387086 },
    { lat: 49.057672, lng: 18.387043 },
    { lat: 49.057318, lng: 18.387113 },
    { lat: 49.056983, lng: 18.387223 },
    { lat: 49.056381, lng: 18.387136 },
    { lat: 49.056078, lng: 18.387325 },
    { lat: 49.055694, lng: 18.387562 },
    { lat: 49.055404, lng: 18.387955 },
    { lat: 49.055125, lng: 18.388463 },
    { lat: 49.054972, lng: 18.388362 },
    { lat: 49.054769, lng: 18.388353 },
    { lat: 49.054343, lng: 18.388379 },
    { lat: 49.054291, lng: 18.388356 },
    { lat: 49.054247, lng: 18.388337 },
    { lat: 49.054208, lng: 18.38832 },
    { lat: 49.054096, lng: 18.388271 },
    { lat: 49.053924, lng: 18.388195 },
    { lat: 49.053729, lng: 18.388205 },
    { lat: 49.053599, lng: 18.388037 },
    { lat: 49.053504, lng: 18.387774 },
    { lat: 49.05324, lng: 18.387466 },
    { lat: 49.053017, lng: 18.387368 },
    { lat: 49.052848, lng: 18.387078 },
    { lat: 49.05266, lng: 18.386992 },
    { lat: 49.052564, lng: 18.38689 },
    { lat: 49.052201, lng: 18.386504 },
    { lat: 49.052151, lng: 18.386472 },
    { lat: 49.052, lng: 18.386411 },
    { lat: 49.051714, lng: 18.386347 },
    { lat: 49.051156, lng: 18.386517 },
    { lat: 49.051034, lng: 18.386345 },
    { lat: 49.050799, lng: 18.38636 },
    { lat: 49.050533, lng: 18.386278 },
    { lat: 49.050307, lng: 18.386154 },
    { lat: 49.050139, lng: 18.386197 },
    { lat: 49.049924, lng: 18.386451 },
    { lat: 49.049818, lng: 18.386715 },
    { lat: 49.049703, lng: 18.386999 },
    { lat: 49.047862, lng: 18.384287 },
    { lat: 49.047318, lng: 18.383625 },
    { lat: 49.045934, lng: 18.384704 },
    { lat: 49.045639, lng: 18.384857 },
    { lat: 49.045158, lng: 18.385322 },
    { lat: 49.04388, lng: 18.386402 },
    { lat: 49.043324, lng: 18.38701 },
    { lat: 49.042933, lng: 18.387294 },
    { lat: 49.042721, lng: 18.387684 },
    { lat: 49.042144, lng: 18.388309 },
    { lat: 49.041817, lng: 18.388342 },
    { lat: 49.041154, lng: 18.388757 },
    { lat: 49.040004, lng: 18.389492 },
    { lat: 49.039383, lng: 18.389992 },
    { lat: 49.038508, lng: 18.390795 },
    { lat: 49.037394, lng: 18.391769 },
    { lat: 49.037326, lng: 18.3919315 },
    { lat: 49.036367, lng: 18.394221 },
    { lat: 49.035108, lng: 18.393883 },
    { lat: 49.034707, lng: 18.393776 },
    { lat: 49.033441, lng: 18.393463 },
    { lat: 49.032828, lng: 18.393302 },
    { lat: 49.031241, lng: 18.393313 },
    { lat: 49.031109, lng: 18.393313 },
    { lat: 49.030428, lng: 18.393635 },
    { lat: 49.029909, lng: 18.394029 },
    { lat: 49.0292, lng: 18.394226 },
    { lat: 49.028499, lng: 18.395192 },
    { lat: 49.027641, lng: 18.396173 },
    { lat: 49.02677, lng: 18.397274 },
    { lat: 49.026182, lng: 18.398063 },
    { lat: 49.025668, lng: 18.398691 },
    { lat: 49.024295, lng: 18.400821 },
    { lat: 49.024037, lng: 18.401086 },
    { lat: 49.023662, lng: 18.401333 },
    { lat: 49.022972, lng: 18.401938 },
    { lat: 49.022653, lng: 18.402077 },
    { lat: 49.022242, lng: 18.402538 },
    { lat: 49.021482, lng: 18.403203 },
    { lat: 49.021001, lng: 18.403871 },
    { lat: 49.020697, lng: 18.40447 },
    { lat: 49.0208962, lng: 18.4049404 },
    { lat: 49.0208833, lng: 18.4052552 },
    { lat: 49.0215861, lng: 18.4062136 },
    { lat: 49.0223578, lng: 18.4072128 },
    { lat: 49.0223142, lng: 18.407852 },
    { lat: 49.022493, lng: 18.4079458 },
    { lat: 49.023069, lng: 18.4085898 },
    { lat: 49.0234642, lng: 18.4099826 },
    { lat: 49.0238623, lng: 18.4121 },
    { lat: 49.0241529, lng: 18.4144588 },
    { lat: 49.0253049, lng: 18.4152171 },
    { lat: 49.025386, lng: 18.4149517 },
    { lat: 49.0263383, lng: 18.4147106 },
    { lat: 49.027139, lng: 18.4138904 },
    { lat: 49.0279462, lng: 18.4129431 },
    { lat: 49.0284694, lng: 18.4118152 },
    { lat: 49.0289526, lng: 18.411394 },
    { lat: 49.029777, lng: 18.4115957 },
    { lat: 49.0303243, lng: 18.4109663 },
    { lat: 49.0308129, lng: 18.4120495 },
    { lat: 49.0317022, lng: 18.4109564 },
    { lat: 49.0317869, lng: 18.4108327 },
    { lat: 49.0318752, lng: 18.4106363 },
    { lat: 49.0319816, lng: 18.4104395 },
    { lat: 49.0322599, lng: 18.4106372 },
    { lat: 49.0326231, lng: 18.4121853 },
    { lat: 49.0330653, lng: 18.4131458 },
    { lat: 49.0335634, lng: 18.4135234 },
    { lat: 49.0352287, lng: 18.4169567 },
    { lat: 49.0355761, lng: 18.4177123 },
    { lat: 49.0359397, lng: 18.4180848 },
    { lat: 49.0361649, lng: 18.4186694 },
    { lat: 49.0366406, lng: 18.4181754 },
    { lat: 49.0368279, lng: 18.4184603 },
    { lat: 49.0369091, lng: 18.4187451 },
    { lat: 49.037169, lng: 18.4193468 },
    { lat: 49.0369914, lng: 18.4197044 },
    { lat: 49.0370968, lng: 18.4199184 },
    { lat: 49.037784, lng: 18.4195749 },
    { lat: 49.0379146, lng: 18.4197731 },
    { lat: 49.0380617, lng: 18.4199666 },
    { lat: 49.0381838, lng: 18.4204817 },
    { lat: 49.0382086, lng: 18.4212788 },
    { lat: 49.0390343, lng: 18.4219922 },
    { lat: 49.0393897, lng: 18.4218729 },
    { lat: 49.0397232, lng: 18.4222715 },
    { lat: 49.0402211, lng: 18.4226484 },
    { lat: 49.0396131, lng: 18.4238748 },
    { lat: 49.0399659, lng: 18.4244422 },
    { lat: 49.040388, lng: 18.423998 },
    { lat: 49.0405457, lng: 18.4241757 },
    { lat: 49.0402989, lng: 18.4246315 },
    { lat: 49.0406697, lng: 18.4254012 },
    { lat: 49.0411313, lng: 18.4266944 },
    { lat: 49.0411646, lng: 18.4267858 },
    { lat: 49.0416639, lng: 18.4266969 },
    { lat: 49.0419895, lng: 18.4267711 },
    { lat: 49.0421808, lng: 18.4268643 },
    { lat: 49.0420322, lng: 18.4274021 },
    { lat: 49.0425828, lng: 18.4274036 },
    { lat: 49.0425944, lng: 18.4275939 },
    { lat: 49.042838, lng: 18.4275718 },
    { lat: 49.0433957, lng: 18.4276614 },
    { lat: 49.0435539, lng: 18.4276872 },
    { lat: 49.0442591, lng: 18.4277932 },
    { lat: 49.0452497, lng: 18.4279177 },
  ],
  Sverepec: [
    { lat: 49.093122, lng: 18.38873 },
    { lat: 49.092443, lng: 18.38947 },
    { lat: 49.091903, lng: 18.389666 },
    { lat: 49.091723, lng: 18.389699 },
    { lat: 49.090663, lng: 18.38985 },
    { lat: 49.09047, lng: 18.389856 },
    { lat: 49.090053, lng: 18.389667 },
    { lat: 49.089378, lng: 18.38934 },
    { lat: 49.089043, lng: 18.389213 },
    { lat: 49.088968, lng: 18.389233 },
    { lat: 49.088864, lng: 18.389595 },
    { lat: 49.088034, lng: 18.389196 },
    { lat: 49.086975, lng: 18.389248 },
    { lat: 49.085636, lng: 18.389846 },
    { lat: 49.085578, lng: 18.389852 },
    { lat: 49.085528, lng: 18.389858 },
    { lat: 49.084213, lng: 18.389995 },
    { lat: 49.083818, lng: 18.390142 },
    { lat: 49.083669, lng: 18.390322 },
    { lat: 49.083618, lng: 18.390383 },
    { lat: 49.082953, lng: 18.390709 },
    { lat: 49.082812, lng: 18.390773 },
    { lat: 49.082719, lng: 18.390893 },
    { lat: 49.082499, lng: 18.391177 },
    { lat: 49.082304, lng: 18.391305 },
    { lat: 49.081532, lng: 18.390583 },
    { lat: 49.080713, lng: 18.390127 },
    { lat: 49.080372, lng: 18.390366 },
    { lat: 49.080171, lng: 18.390555 },
    { lat: 49.080027, lng: 18.390885 },
    { lat: 49.079841, lng: 18.390593 },
    { lat: 49.079245, lng: 18.39073 },
    { lat: 49.078503, lng: 18.391631 },
    { lat: 49.078131, lng: 18.39247 },
    { lat: 49.077574, lng: 18.391838 },
    { lat: 49.077092, lng: 18.39174 },
    { lat: 49.07593, lng: 18.39175 },
    { lat: 49.074851, lng: 18.391899 },
    { lat: 49.074408, lng: 18.392119 },
    { lat: 49.073021, lng: 18.393641 },
    { lat: 49.072508, lng: 18.394068 },
    { lat: 49.071313, lng: 18.39443 },
    { lat: 49.070313, lng: 18.394539 },
    { lat: 49.070106, lng: 18.394623 },
    { lat: 49.069974, lng: 18.394676 },
    { lat: 49.06993, lng: 18.394694 },
    { lat: 49.069927, lng: 18.394696 },
    { lat: 49.069856, lng: 18.394724 },
    { lat: 49.06982, lng: 18.394739 },
    { lat: 49.069684, lng: 18.394772 },
    { lat: 49.069642, lng: 18.394782 },
    { lat: 49.069601, lng: 18.394763 },
    { lat: 49.069552, lng: 18.394732 },
    { lat: 49.069542, lng: 18.394725 },
    { lat: 49.069452, lng: 18.394669 },
    { lat: 49.069418, lng: 18.394528 },
    { lat: 49.069347, lng: 18.394244 },
    { lat: 49.069313, lng: 18.394108 },
    { lat: 49.069098, lng: 18.39406 },
    { lat: 49.069048, lng: 18.393981 },
    { lat: 49.068975, lng: 18.393867 },
    { lat: 49.06883, lng: 18.393477 },
    { lat: 49.068758, lng: 18.393169 },
    { lat: 49.068715, lng: 18.392987 },
    { lat: 49.06868, lng: 18.392881 },
    { lat: 49.068604, lng: 18.392655 },
    { lat: 49.068647, lng: 18.39259 },
    { lat: 49.068671, lng: 18.392553 },
    { lat: 49.068582, lng: 18.392403 },
    { lat: 49.068576, lng: 18.392394 },
    { lat: 49.068489, lng: 18.392247 },
    { lat: 49.068384, lng: 18.392301 },
    { lat: 49.068341, lng: 18.392324 },
    { lat: 49.068233, lng: 18.392306 },
    { lat: 49.068053, lng: 18.392278 },
    { lat: 49.068023, lng: 18.392186 },
    { lat: 49.068007, lng: 18.391951 },
    { lat: 49.067662, lng: 18.392532 },
    { lat: 49.067366, lng: 18.392749 },
    { lat: 49.067951, lng: 18.394376 },
    { lat: 49.067886, lng: 18.394937 },
    { lat: 49.067109, lng: 18.395408 },
    { lat: 49.06631, lng: 18.396095 },
    { lat: 49.066406, lng: 18.396521 },
    { lat: 49.066698, lng: 18.397111 },
    { lat: 49.066279, lng: 18.398192 },
    { lat: 49.065707, lng: 18.398962 },
    { lat: 49.065681, lng: 18.399003 },
    { lat: 49.065667, lng: 18.399016 },
    { lat: 49.06564, lng: 18.399052 },
    { lat: 49.0657618, lng: 18.3991898 },
    { lat: 49.0659094, lng: 18.3994925 },
    { lat: 49.0660072, lng: 18.3996073 },
    { lat: 49.0659622, lng: 18.3997521 },
    { lat: 49.0658394, lng: 18.399979 },
    { lat: 49.06584, lng: 18.4000313 },
    { lat: 49.0659078, lng: 18.4002161 },
    { lat: 49.0659352, lng: 18.4002414 },
    { lat: 49.0660131, lng: 18.400446 },
    { lat: 49.0660159, lng: 18.4005181 },
    { lat: 49.0660622, lng: 18.4006003 },
    { lat: 49.0661317, lng: 18.4010008 },
    { lat: 49.0662141, lng: 18.4012867 },
    { lat: 49.0665072, lng: 18.4012218 },
    { lat: 49.0666881, lng: 18.401146 },
    { lat: 49.06683, lng: 18.4012253 },
    { lat: 49.0669801, lng: 18.4014288 },
    { lat: 49.0670306, lng: 18.4015266 },
    { lat: 49.0672912, lng: 18.4018341 },
    { lat: 49.0673342, lng: 18.4019162 },
    { lat: 49.0673964, lng: 18.4020841 },
    { lat: 49.0674298, lng: 18.4022583 },
    { lat: 49.0674408, lng: 18.4024961 },
    { lat: 49.067584, lng: 18.4029847 },
    { lat: 49.0679824, lng: 18.4034581 },
    { lat: 49.0680214, lng: 18.4035711 },
    { lat: 49.0680059, lng: 18.4036568 },
    { lat: 49.0679609, lng: 18.4037836 },
    { lat: 49.0678904, lng: 18.403875 },
    { lat: 49.0677921, lng: 18.4040344 },
    { lat: 49.067725, lng: 18.4041873 },
    { lat: 49.0677626, lng: 18.4043184 },
    { lat: 49.0678986, lng: 18.4045125 },
    { lat: 49.0679602, lng: 18.4046464 },
    { lat: 49.0678961, lng: 18.4047714 },
    { lat: 49.0679261, lng: 18.4048868 },
    { lat: 49.0680452, lng: 18.4051025 },
    { lat: 49.06814, lng: 18.405226 },
    { lat: 49.0682361, lng: 18.4053097 },
    { lat: 49.0683786, lng: 18.4053577 },
    { lat: 49.0684694, lng: 18.4055507 },
    { lat: 49.0683707, lng: 18.4058483 },
    { lat: 49.0683552, lng: 18.4058949 },
    { lat: 49.0683078, lng: 18.4059942 },
    { lat: 49.0682211, lng: 18.4062843 },
    { lat: 49.0679944, lng: 18.4068134 },
    { lat: 49.0679225, lng: 18.4069226 },
    { lat: 49.0678895, lng: 18.4070177 },
    { lat: 49.067888, lng: 18.4070936 },
    { lat: 49.0679113, lng: 18.4073309 },
    { lat: 49.0679228, lng: 18.4080488 },
    { lat: 49.0679017, lng: 18.4082976 },
    { lat: 49.0680856, lng: 18.408995 },
    { lat: 49.0683215, lng: 18.4091188 },
    { lat: 49.0686621, lng: 18.4090559 },
    { lat: 49.0687677, lng: 18.4090494 },
    { lat: 49.068943, lng: 18.4089345 },
    { lat: 49.0690797, lng: 18.4089806 },
    { lat: 49.0691986, lng: 18.4090696 },
    { lat: 49.0694348, lng: 18.4091366 },
    { lat: 49.0697821, lng: 18.4094014 },
    { lat: 49.0700281, lng: 18.4094823 },
    { lat: 49.0703409, lng: 18.4094696 },
    { lat: 49.0706248, lng: 18.4097686 },
    { lat: 49.0705006, lng: 18.4103781 },
    { lat: 49.0704508, lng: 18.4105586 },
    { lat: 49.0703122, lng: 18.4109293 },
    { lat: 49.0703816, lng: 18.4109787 },
    { lat: 49.0704895, lng: 18.4111165 },
    { lat: 49.0706366, lng: 18.4113067 },
    { lat: 49.0708664, lng: 18.4116696 },
    { lat: 49.0710793, lng: 18.4119295 },
    { lat: 49.071243, lng: 18.4121557 },
    { lat: 49.0712956, lng: 18.4123936 },
    { lat: 49.0713554, lng: 18.412536 },
    { lat: 49.0717506, lng: 18.4129726 },
    { lat: 49.0719288, lng: 18.4132369 },
    { lat: 49.0720299, lng: 18.413372 },
    { lat: 49.0720843, lng: 18.4134177 },
    { lat: 49.07219, lng: 18.4134317 },
    { lat: 49.0722882, lng: 18.4134334 },
    { lat: 49.0724898, lng: 18.4141833 },
    { lat: 49.0725856, lng: 18.4144671 },
    { lat: 49.0726357, lng: 18.4145971 },
    { lat: 49.0728434, lng: 18.4148869 },
    { lat: 49.0729521, lng: 18.4147919 },
    { lat: 49.0730418, lng: 18.4147419 },
    { lat: 49.073392, lng: 18.4146489 },
    { lat: 49.0736492, lng: 18.4146903 },
    { lat: 49.0737207, lng: 18.4147168 },
    { lat: 49.0738603, lng: 18.4148351 },
    { lat: 49.0740237, lng: 18.4150164 },
    { lat: 49.0742138, lng: 18.4153929 },
    { lat: 49.0744261, lng: 18.4153164 },
    { lat: 49.0745203, lng: 18.4153056 },
    { lat: 49.0748839, lng: 18.4154143 },
    { lat: 49.0751308, lng: 18.4154115 },
    { lat: 49.0752136, lng: 18.4154009 },
    { lat: 49.0752417, lng: 18.4153974 },
    { lat: 49.0752931, lng: 18.4153895 },
    { lat: 49.0753115, lng: 18.4153967 },
    { lat: 49.0755922, lng: 18.4155135 },
    { lat: 49.0756764, lng: 18.4155486 },
    { lat: 49.0756903, lng: 18.4155542 },
    { lat: 49.0759141, lng: 18.4157467 },
    { lat: 49.0759472, lng: 18.4157536 },
    { lat: 49.0761384, lng: 18.4158038 },
    { lat: 49.0763135, lng: 18.4158496 },
    { lat: 49.0763713, lng: 18.4158942 },
    { lat: 49.0764843, lng: 18.4159814 },
    { lat: 49.0766384, lng: 18.4160952 },
    { lat: 49.0768242, lng: 18.416232 },
    { lat: 49.0768216, lng: 18.4162664 },
    { lat: 49.0767521, lng: 18.4171536 },
    { lat: 49.076754, lng: 18.4174743 },
    { lat: 49.0770845, lng: 18.4175751 },
    { lat: 49.0770895, lng: 18.4179535 },
    { lat: 49.0773422, lng: 18.4180352 },
    { lat: 49.0772987, lng: 18.4184737 },
    { lat: 49.0772013, lng: 18.4190176 },
    { lat: 49.0770784, lng: 18.4195914 },
    { lat: 49.0770191, lng: 18.4199475 },
    { lat: 49.0769873, lng: 18.4203734 },
    { lat: 49.0769732, lng: 18.4206694 },
    { lat: 49.0776041, lng: 18.4208372 },
    { lat: 49.0776466, lng: 18.4208112 },
    { lat: 49.0776796, lng: 18.4208563 },
    { lat: 49.0776603, lng: 18.4209164 },
    { lat: 49.0776575, lng: 18.421152 },
    { lat: 49.0776837, lng: 18.4213408 },
    { lat: 49.0777475, lng: 18.4213534 },
    { lat: 49.0777803, lng: 18.4213943 },
    { lat: 49.0777847, lng: 18.421659 },
    { lat: 49.0777809, lng: 18.4218955 },
    { lat: 49.0778487, lng: 18.4221218 },
    { lat: 49.0778969, lng: 18.4221169 },
    { lat: 49.0779304, lng: 18.4221114 },
    { lat: 49.0780602, lng: 18.4220905 },
    { lat: 49.0781135, lng: 18.4220779 },
    { lat: 49.0783096, lng: 18.4220949 },
    { lat: 49.0783406, lng: 18.4221233 },
    { lat: 49.0784287, lng: 18.4221279 },
    { lat: 49.0785069, lng: 18.4221132 },
    { lat: 49.0786021, lng: 18.4222439 },
    { lat: 49.0786427, lng: 18.4223445 },
    { lat: 49.0787473, lng: 18.4223412 },
    { lat: 49.0788268, lng: 18.4222479 },
    { lat: 49.0790343, lng: 18.4222063 },
    { lat: 49.0790958, lng: 18.4221547 },
    { lat: 49.0793834, lng: 18.4218486 },
    { lat: 49.0794934, lng: 18.4217554 },
    { lat: 49.0795959, lng: 18.4216912 },
    { lat: 49.0796799, lng: 18.4216394 },
    { lat: 49.0797253, lng: 18.4216239 },
    { lat: 49.0798172, lng: 18.4216129 },
    { lat: 49.0798504, lng: 18.4216209 },
    { lat: 49.0798891, lng: 18.421629 },
    { lat: 49.079987, lng: 18.4216656 },
    { lat: 49.0800974, lng: 18.421703 },
    { lat: 49.0801629, lng: 18.4217234 },
    { lat: 49.0802213, lng: 18.4217408 },
    { lat: 49.0803485, lng: 18.4217979 },
    { lat: 49.0803913, lng: 18.4218173 },
    { lat: 49.0804273, lng: 18.4218359 },
    { lat: 49.0804865, lng: 18.4218661 },
    { lat: 49.0805197, lng: 18.4218955 },
    { lat: 49.0805386, lng: 18.4219105 },
    { lat: 49.0805433, lng: 18.4219137 },
    { lat: 49.0805993, lng: 18.4219483 },
    { lat: 49.0806576, lng: 18.4220018 },
    { lat: 49.0807275, lng: 18.4220816 },
    { lat: 49.0807398, lng: 18.4221009 },
    { lat: 49.0807841, lng: 18.4221681 },
    { lat: 49.0808528, lng: 18.4222882 },
    { lat: 49.0808643, lng: 18.4223128 },
    { lat: 49.0808682, lng: 18.4223227 },
    { lat: 49.0808953, lng: 18.4223858 },
    { lat: 49.0809271, lng: 18.4224688 },
    { lat: 49.0809494, lng: 18.4225264 },
    { lat: 49.0810126, lng: 18.4227325 },
    { lat: 49.0810688, lng: 18.4229324 },
    { lat: 49.081122, lng: 18.4230999 },
    { lat: 49.081159, lng: 18.4232201 },
    { lat: 49.0811918, lng: 18.4233215 },
    { lat: 49.081212, lng: 18.4233828 },
    { lat: 49.081215, lng: 18.4233957 },
    { lat: 49.0812449, lng: 18.4234876 },
    { lat: 49.081267, lng: 18.4235197 },
    { lat: 49.0813209, lng: 18.4235369 },
    { lat: 49.0813588, lng: 18.4235486 },
    { lat: 49.0813702, lng: 18.4235524 },
    { lat: 49.0813842, lng: 18.4235592 },
    { lat: 49.081377, lng: 18.423795 },
    { lat: 49.0813646, lng: 18.4240232 },
    { lat: 49.0813261, lng: 18.424388 },
    { lat: 49.0812922, lng: 18.4246327 },
    { lat: 49.0812689, lng: 18.4248219 },
    { lat: 49.0811809, lng: 18.4258048 },
    { lat: 49.081112, lng: 18.4266009 },
    { lat: 49.081108, lng: 18.4268972 },
    { lat: 49.0811204, lng: 18.4271627 },
    { lat: 49.0811234, lng: 18.4273602 },
    { lat: 49.081134, lng: 18.4275731 },
    { lat: 49.0811561, lng: 18.4277481 },
    { lat: 49.081237, lng: 18.4281746 },
    { lat: 49.0812495, lng: 18.4282565 },
    { lat: 49.0812552, lng: 18.4283393 },
    { lat: 49.0812868, lng: 18.4285439 },
    { lat: 49.0813124, lng: 18.4288048 },
    { lat: 49.0813308, lng: 18.428892 },
    { lat: 49.081337, lng: 18.428985 },
    { lat: 49.0813273, lng: 18.4290742 },
    { lat: 49.0813224, lng: 18.4292328 },
    { lat: 49.0813313, lng: 18.4292903 },
    { lat: 49.0813389, lng: 18.4292858 },
    { lat: 49.0813476, lng: 18.4292798 },
    { lat: 49.0813521, lng: 18.4292988 },
    { lat: 49.0813576, lng: 18.4293179 },
    { lat: 49.0822049, lng: 18.4286041 },
    { lat: 49.0825468, lng: 18.4282977 },
    { lat: 49.0835146, lng: 18.4273973 },
    { lat: 49.0839004, lng: 18.4269067 },
    { lat: 49.0844606, lng: 18.4259362 },
    { lat: 49.0847269, lng: 18.4254874 },
    { lat: 49.0850843, lng: 18.4250133 },
    { lat: 49.0853774, lng: 18.4246199 },
    { lat: 49.0857968, lng: 18.4241466 },
    { lat: 49.0860977, lng: 18.4235286 },
    { lat: 49.0864316, lng: 18.4228531 },
    { lat: 49.0867899, lng: 18.4222717 },
    { lat: 49.0871721, lng: 18.4218408 },
    { lat: 49.087694, lng: 18.4213404 },
    { lat: 49.0880214, lng: 18.4212437 },
    { lat: 49.0884019, lng: 18.4211889 },
    { lat: 49.0888024, lng: 18.4208827 },
    { lat: 49.0888944, lng: 18.4208117 },
    { lat: 49.0891068, lng: 18.4207162 },
    { lat: 49.0892565, lng: 18.420649 },
    { lat: 49.0894417, lng: 18.4205497 },
    { lat: 49.0895627, lng: 18.4204747 },
    { lat: 49.0897476, lng: 18.4202873 },
    { lat: 49.0901685, lng: 18.4196773 },
    { lat: 49.0901716, lng: 18.4196735 },
    { lat: 49.0902959, lng: 18.4194933 },
    { lat: 49.0903481, lng: 18.4194359 },
    { lat: 49.0904003, lng: 18.4193608 },
    { lat: 49.0905002, lng: 18.4192502 },
    { lat: 49.0906096, lng: 18.4191462 },
    { lat: 49.0908062, lng: 18.4189263 },
    { lat: 49.0909767, lng: 18.4186637 },
    { lat: 49.0914807, lng: 18.4178833 },
    { lat: 49.0915319, lng: 18.4178095 },
    { lat: 49.0915456, lng: 18.4177908 },
    { lat: 49.091648, lng: 18.4176442 },
    { lat: 49.0917316, lng: 18.417525 },
    { lat: 49.0917327, lng: 18.4175232 },
    { lat: 49.0922628, lng: 18.4167668 },
    { lat: 49.0922942, lng: 18.4167225 },
    { lat: 49.0923127, lng: 18.416709 },
    { lat: 49.0923744, lng: 18.416662 },
    { lat: 49.0923842, lng: 18.4166552 },
    { lat: 49.0924362, lng: 18.4166158 },
    { lat: 49.0924481, lng: 18.416607 },
    { lat: 49.0926193, lng: 18.4164383 },
    { lat: 49.0930721, lng: 18.4159981 },
    { lat: 49.0933405, lng: 18.4152399 },
    { lat: 49.0935222, lng: 18.4147712 },
    { lat: 49.093682, lng: 18.4145614 },
    { lat: 49.0939706, lng: 18.4136179 },
    { lat: 49.0938256, lng: 18.4128313 },
    { lat: 49.0937003, lng: 18.4120317 },
    { lat: 49.0937467, lng: 18.4111777 },
    { lat: 49.0937238, lng: 18.4109058 },
    { lat: 49.0936358, lng: 18.4103745 },
    { lat: 49.0936352, lng: 18.4097922 },
    { lat: 49.0937467, lng: 18.4082391 },
    { lat: 49.0938298, lng: 18.4075589 },
    { lat: 49.0940226, lng: 18.4066784 },
    { lat: 49.094277, lng: 18.4059545 },
    { lat: 49.0944453, lng: 18.4057344 },
    { lat: 49.0947964, lng: 18.4054314 },
    { lat: 49.0950521, lng: 18.4053044 },
    { lat: 49.0956626, lng: 18.4053075 },
    { lat: 49.0961968, lng: 18.4051775 },
    { lat: 49.0962399, lng: 18.4051403 },
    { lat: 49.0963567, lng: 18.4050072 },
    { lat: 49.0966618, lng: 18.4047739 },
    { lat: 49.0970702, lng: 18.4042426 },
    { lat: 49.097488, lng: 18.4035367 },
    { lat: 49.0978606, lng: 18.4022572 },
    { lat: 49.0984316, lng: 18.400772 },
    { lat: 49.099031, lng: 18.3998024 },
    { lat: 49.0987577, lng: 18.3989185 },
    { lat: 49.0986264, lng: 18.398483 },
    { lat: 49.0983558, lng: 18.3975875 },
    { lat: 49.098122, lng: 18.396469 },
    { lat: 49.09727, lng: 18.396032 },
    { lat: 49.097086, lng: 18.395879 },
    { lat: 49.09697, lng: 18.395565 },
    { lat: 49.096514, lng: 18.395308 },
    { lat: 49.096187, lng: 18.39526 },
    { lat: 49.095896, lng: 18.395146 },
    { lat: 49.095569, lng: 18.39508 },
    { lat: 49.095378, lng: 18.395344 },
    { lat: 49.095208, lng: 18.395428 },
    { lat: 49.095008, lng: 18.395696 },
    { lat: 49.09484, lng: 18.395809 },
    { lat: 49.094542, lng: 18.395872 },
    { lat: 49.094094, lng: 18.395934 },
    { lat: 49.094082, lng: 18.39586 },
    { lat: 49.094061, lng: 18.395779 },
    { lat: 49.094015, lng: 18.395548 },
    { lat: 49.09389, lng: 18.394656 },
    { lat: 49.093783, lng: 18.394624 },
    { lat: 49.093713, lng: 18.393584 },
    { lat: 49.093557, lng: 18.393602 },
    { lat: 49.093524, lng: 18.393437 },
    { lat: 49.093248, lng: 18.392158 },
    { lat: 49.092912, lng: 18.391478 },
    { lat: 49.092935, lng: 18.390022 },
    { lat: 49.092897, lng: 18.389153 },
    { lat: 49.09294, lng: 18.389071 },
    { lat: 49.092986, lng: 18.388986 },
    { lat: 49.093122, lng: 18.38873 },
  ],
  Sádočné: [
    { lat: 49.0263999, lng: 18.5273188 },
    { lat: 49.0255202, lng: 18.527653 },
    { lat: 49.0250517, lng: 18.5279781 },
    { lat: 49.024118, lng: 18.5286258 },
    { lat: 49.0237954, lng: 18.5287709 },
    { lat: 49.0224345, lng: 18.5293829 },
    { lat: 49.0215583, lng: 18.5300525 },
    { lat: 49.0214236, lng: 18.5302722 },
    { lat: 49.0208507, lng: 18.5312088 },
    { lat: 49.0201569, lng: 18.5318285 },
    { lat: 49.019715, lng: 18.5323734 },
    { lat: 49.0189469, lng: 18.5332253 },
    { lat: 49.0181833, lng: 18.5336211 },
    { lat: 49.0179867, lng: 18.5339678 },
    { lat: 49.0168756, lng: 18.5340908 },
    { lat: 49.0164315, lng: 18.5338903 },
    { lat: 49.0163925, lng: 18.533877 },
    { lat: 49.0163365, lng: 18.5338433 },
    { lat: 49.0163042, lng: 18.5338286 },
    { lat: 49.0163619, lng: 18.5335415 },
    { lat: 49.0164377, lng: 18.5331726 },
    { lat: 49.0164881, lng: 18.532647 },
    { lat: 49.0159782, lng: 18.5323741 },
    { lat: 49.0151645, lng: 18.5319492 },
    { lat: 49.0151289, lng: 18.5319375 },
    { lat: 49.0151128, lng: 18.5319112 },
    { lat: 49.014228, lng: 18.5313599 },
    { lat: 49.0139256, lng: 18.5311719 },
    { lat: 49.013587, lng: 18.5317695 },
    { lat: 49.0133287, lng: 18.5318346 },
    { lat: 49.0127465, lng: 18.5318922 },
    { lat: 49.0117921, lng: 18.5321783 },
    { lat: 49.0112603, lng: 18.5315041 },
    { lat: 49.0107029, lng: 18.5307322 },
    { lat: 49.0103091, lng: 18.5304191 },
    { lat: 49.0099435, lng: 18.5298965 },
    { lat: 49.0096979, lng: 18.5297987 },
    { lat: 49.0087734, lng: 18.5302812 },
    { lat: 49.0087306, lng: 18.5303038 },
    { lat: 49.0085551, lng: 18.5303959 },
    { lat: 49.0074586, lng: 18.5302069 },
    { lat: 49.0065372, lng: 18.5301053 },
    { lat: 49.0053338, lng: 18.5300022 },
    { lat: 49.0043926, lng: 18.5294731 },
    { lat: 49.0038224, lng: 18.5291924 },
    { lat: 49.0033534, lng: 18.5290716 },
    { lat: 49.0025955, lng: 18.5289905 },
    { lat: 49.0021141, lng: 18.528994 },
    { lat: 49.0013797, lng: 18.5288889 },
    { lat: 49.0011482, lng: 18.5288001 },
    { lat: 49.0008527, lng: 18.5290721 },
    { lat: 49.0003162, lng: 18.5292648 },
    { lat: 48.9986471, lng: 18.5300585 },
    { lat: 48.9985533, lng: 18.5300956 },
    { lat: 48.9972789, lng: 18.5304739 },
    { lat: 48.9968785, lng: 18.5303082 },
    { lat: 48.9961593, lng: 18.5298388 },
    { lat: 48.9955713, lng: 18.5296443 },
    { lat: 48.9951554, lng: 18.5297949 },
    { lat: 48.994617, lng: 18.5296843 },
    { lat: 48.9937713, lng: 18.5302702 },
    { lat: 48.9935957, lng: 18.53034 },
    { lat: 48.9933355, lng: 18.5298271 },
    { lat: 48.9931556, lng: 18.5299204 },
    { lat: 48.9926666, lng: 18.5312385 },
    { lat: 48.991868, lng: 18.53232 },
    { lat: 48.991707, lng: 18.533453 },
    { lat: 48.991604, lng: 18.534439 },
    { lat: 48.991499, lng: 18.5354 },
    { lat: 48.991416, lng: 18.536324 },
    { lat: 48.99129, lng: 18.536961 },
    { lat: 48.990949, lng: 18.538428 },
    { lat: 48.990904, lng: 18.538878 },
    { lat: 48.991069, lng: 18.539668 },
    { lat: 48.991152, lng: 18.539785 },
    { lat: 48.991344, lng: 18.540153 },
    { lat: 48.992158, lng: 18.541298 },
    { lat: 48.992634, lng: 18.541933 },
    { lat: 48.992685, lng: 18.542002 },
    { lat: 48.992763, lng: 18.54255 },
    { lat: 48.992863, lng: 18.543347 },
    { lat: 48.992808, lng: 18.543975 },
    { lat: 48.992627, lng: 18.544984 },
    { lat: 48.992492, lng: 18.545666 },
    { lat: 48.992326, lng: 18.546806 },
    { lat: 48.992511, lng: 18.547092 },
    { lat: 48.992758, lng: 18.547508 },
    { lat: 48.992985, lng: 18.548032 },
    { lat: 48.993151, lng: 18.548544 },
    { lat: 48.99303, lng: 18.549099 },
    { lat: 48.993023, lng: 18.550297 },
    { lat: 48.993074, lng: 18.550531 },
    { lat: 48.993243, lng: 18.551321 },
    { lat: 48.993387, lng: 18.551886 },
    { lat: 48.993552, lng: 18.552222 },
    { lat: 48.99359, lng: 18.552693 },
    { lat: 48.9938017, lng: 18.5532169 },
    { lat: 48.993847, lng: 18.553329 },
    { lat: 48.994026, lng: 18.553675 },
    { lat: 48.994035, lng: 18.554091 },
    { lat: 48.994115, lng: 18.554558 },
    { lat: 48.994364, lng: 18.555259 },
    { lat: 48.9950006, lng: 18.5552353 },
    { lat: 48.9950389, lng: 18.5552446 },
    { lat: 48.9950849, lng: 18.5552557 },
    { lat: 48.9955171, lng: 18.5553604 },
    { lat: 48.9960938, lng: 18.5557413 },
    { lat: 48.9967007, lng: 18.5562028 },
    { lat: 48.9970252, lng: 18.5563638 },
    { lat: 48.9973459, lng: 18.5565795 },
    { lat: 48.99801, lng: 18.5571154 },
    { lat: 48.998002, lng: 18.5571344 },
    { lat: 48.9985548, lng: 18.5573451 },
    { lat: 48.9986368, lng: 18.5574017 },
    { lat: 48.9991126, lng: 18.557732 },
    { lat: 48.9996168, lng: 18.5581708 },
    { lat: 48.9998967, lng: 18.5584037 },
    { lat: 49.000623, lng: 18.5587586 },
    { lat: 49.0010625, lng: 18.5588959 },
    { lat: 49.0015873, lng: 18.5591752 },
    { lat: 49.0027135, lng: 18.5597061 },
    { lat: 49.0031736, lng: 18.559977 },
    { lat: 49.0036089, lng: 18.5602243 },
    { lat: 49.0040541, lng: 18.5602116 },
    { lat: 49.0041708, lng: 18.5602083 },
    { lat: 49.0043756, lng: 18.5601667 },
    { lat: 49.0046874, lng: 18.5601033 },
    { lat: 49.0047944, lng: 18.5600816 },
    { lat: 49.0049483, lng: 18.5601102 },
    { lat: 49.0053093, lng: 18.5601955 },
    { lat: 49.0054058, lng: 18.5602496 },
    { lat: 49.0054992, lng: 18.5603283 },
    { lat: 49.0056035, lng: 18.5604002 },
    { lat: 49.005785, lng: 18.5604832 },
    { lat: 49.0060484, lng: 18.5605773 },
    { lat: 49.0062875, lng: 18.5607236 },
    { lat: 49.0065363, lng: 18.5608408 },
    { lat: 49.0066725, lng: 18.560876 },
    { lat: 49.0067758, lng: 18.5608894 },
    { lat: 49.0070192, lng: 18.560864 },
    { lat: 49.0077372, lng: 18.5608555 },
    { lat: 49.0078245, lng: 18.5608017 },
    { lat: 49.0078268, lng: 18.5608014 },
    { lat: 49.0079275, lng: 18.5602454 },
    { lat: 49.0081204, lng: 18.5594317 },
    { lat: 49.0082332, lng: 18.5589495 },
    { lat: 49.0082654, lng: 18.5589014 },
    { lat: 49.0085564, lng: 18.5584618 },
    { lat: 49.0085876, lng: 18.5584137 },
    { lat: 49.0090298, lng: 18.5580804 },
    { lat: 49.0094782, lng: 18.5579475 },
    { lat: 49.0095304, lng: 18.557932 },
    { lat: 49.010392, lng: 18.5576768 },
    { lat: 49.0106411, lng: 18.55732 },
    { lat: 49.0110774, lng: 18.5566915 },
    { lat: 49.0119155, lng: 18.5565256 },
    { lat: 49.0119577, lng: 18.5565756 },
    { lat: 49.0123321, lng: 18.5570132 },
    { lat: 49.0128143, lng: 18.5572335 },
    { lat: 49.0133375, lng: 18.5574396 },
    { lat: 49.0136942, lng: 18.5572776 },
    { lat: 49.0142215, lng: 18.5579966 },
    { lat: 49.0145221, lng: 18.5583428 },
    { lat: 49.0150681, lng: 18.5588023 },
    { lat: 49.0156227, lng: 18.559569 },
    { lat: 49.016411, lng: 18.5601945 },
    { lat: 49.0169577, lng: 18.5601859 },
    { lat: 49.0175546, lng: 18.5601922 },
    { lat: 49.0187332, lng: 18.5602735 },
    { lat: 49.0193528, lng: 18.5602403 },
    { lat: 49.019581, lng: 18.560163 },
    { lat: 49.01983, lng: 18.5601152 },
    { lat: 49.0201036, lng: 18.5599205 },
    { lat: 49.0210432, lng: 18.5591901 },
    { lat: 49.0211967, lng: 18.5583985 },
    { lat: 49.0213761, lng: 18.5580451 },
    { lat: 49.0216455, lng: 18.5575843 },
    { lat: 49.0218622, lng: 18.5573781 },
    { lat: 49.0220731, lng: 18.5572098 },
    { lat: 49.0222657, lng: 18.5568286 },
    { lat: 49.0231151, lng: 18.5561856 },
    { lat: 49.0239801, lng: 18.5555995 },
    { lat: 49.0245888, lng: 18.5554041 },
    { lat: 49.0252006, lng: 18.5552471 },
    { lat: 49.0253702, lng: 18.5549836 },
    { lat: 49.0256902, lng: 18.5552682 },
    { lat: 49.0257118, lng: 18.555273 },
    { lat: 49.0257795, lng: 18.5550011 },
    { lat: 49.0257961, lng: 18.5546838 },
    { lat: 49.0258247, lng: 18.5546073 },
    { lat: 49.0258168, lng: 18.5544607 },
    { lat: 49.0257279, lng: 18.5543599 },
    { lat: 49.0253874, lng: 18.554258 },
    { lat: 49.0253291, lng: 18.554222 },
    { lat: 49.0254763, lng: 18.5540665 },
    { lat: 49.0255083, lng: 18.5540118 },
    { lat: 49.0254929, lng: 18.5536428 },
    { lat: 49.0255121, lng: 18.5535179 },
    { lat: 49.025572, lng: 18.5533979 },
    { lat: 49.0252381, lng: 18.553312 },
    { lat: 49.0249156, lng: 18.5532086 },
    { lat: 49.0247409, lng: 18.5530856 },
    { lat: 49.0246028, lng: 18.552578 },
    { lat: 49.0245062, lng: 18.552564 },
    { lat: 49.0242225, lng: 18.5525726 },
    { lat: 49.0241403, lng: 18.5521591 },
    { lat: 49.0248296, lng: 18.5519121 },
    { lat: 49.0251129, lng: 18.5517895 },
    { lat: 49.0252624, lng: 18.551594 },
    { lat: 49.0256004, lng: 18.5510018 },
    { lat: 49.0260629, lng: 18.5501083 },
    { lat: 49.0256409, lng: 18.5497083 },
    { lat: 49.0254473, lng: 18.5494231 },
    { lat: 49.025256, lng: 18.5490348 },
    { lat: 49.0258264, lng: 18.5482787 },
    { lat: 49.0261937, lng: 18.5477515 },
    { lat: 49.0267279, lng: 18.5469532 },
    { lat: 49.027036, lng: 18.546433 },
    { lat: 49.027045, lng: 18.5464118 },
    { lat: 49.0268097, lng: 18.5461692 },
    { lat: 49.0267532, lng: 18.5459822 },
    { lat: 49.026703, lng: 18.5457845 },
    { lat: 49.0266429, lng: 18.5456312 },
    { lat: 49.0264292, lng: 18.5451648 },
    { lat: 49.0260029, lng: 18.5444563 },
    { lat: 49.0258888, lng: 18.5442869 },
    { lat: 49.0258557, lng: 18.544238 },
    { lat: 49.0257233, lng: 18.5440415 },
    { lat: 49.0254556, lng: 18.5432654 },
    { lat: 49.0254501, lng: 18.5430994 },
    { lat: 49.0254267, lng: 18.5423975 },
    { lat: 49.0254287, lng: 18.5412479 },
    { lat: 49.0253767, lng: 18.5409981 },
    { lat: 49.0253424, lng: 18.5409054 },
    { lat: 49.0253128, lng: 18.5407535 },
    { lat: 49.0252952, lng: 18.5402421 },
    { lat: 49.0252883, lng: 18.5400938 },
    { lat: 49.0252961, lng: 18.5399685 },
    { lat: 49.025291, lng: 18.5395605 },
    { lat: 49.0253025, lng: 18.5390659 },
    { lat: 49.0252804, lng: 18.5388448 },
    { lat: 49.0252852, lng: 18.5386638 },
    { lat: 49.0254449, lng: 18.5375939 },
    { lat: 49.0255718, lng: 18.5368132 },
    { lat: 49.0257877, lng: 18.535907 },
    { lat: 49.0259358, lng: 18.5342708 },
    { lat: 49.0259894, lng: 18.5341156 },
    { lat: 49.0260995, lng: 18.533857 },
    { lat: 49.0264921, lng: 18.5331932 },
    { lat: 49.0266552, lng: 18.532416 },
    { lat: 49.0265866, lng: 18.5311742 },
    { lat: 49.0265837, lng: 18.531121 },
    { lat: 49.0266144, lng: 18.5311239 },
    { lat: 49.0266015, lng: 18.5305947 },
    { lat: 49.0266083, lng: 18.5304089 },
    { lat: 49.0266396, lng: 18.5288863 },
    { lat: 49.0266213, lng: 18.528883 },
    { lat: 49.0264991, lng: 18.5278384 },
    { lat: 49.0263999, lng: 18.5273188 },
  ],
  ČelkovaLehota: [
    { lat: 48.9909112, lng: 18.52567 },
    { lat: 48.990327, lng: 18.5262092 },
    { lat: 48.9902403, lng: 18.5262955 },
    { lat: 48.9903448, lng: 18.5262258 },
    { lat: 48.989415, lng: 18.527061 },
    { lat: 48.989783, lng: 18.528256 },
    { lat: 48.990107, lng: 18.52905 },
    { lat: 48.990781, lng: 18.529669 },
    { lat: 48.991933, lng: 18.530391 },
    { lat: 48.992261, lng: 18.530743 },
    { lat: 48.992304, lng: 18.530783 },
    { lat: 48.992179, lng: 18.531402 },
    { lat: 48.992021, lng: 18.53197 },
    { lat: 48.991868, lng: 18.53232 },
    { lat: 48.9926666, lng: 18.5312385 },
    { lat: 48.9931556, lng: 18.5299204 },
    { lat: 48.9933355, lng: 18.5298271 },
    { lat: 48.9935957, lng: 18.53034 },
    { lat: 48.9937713, lng: 18.5302702 },
    { lat: 48.994617, lng: 18.5296843 },
    { lat: 48.9951554, lng: 18.5297949 },
    { lat: 48.9955713, lng: 18.5296443 },
    { lat: 48.9961593, lng: 18.5298388 },
    { lat: 48.9968785, lng: 18.5303082 },
    { lat: 48.9972789, lng: 18.5304739 },
    { lat: 48.9985533, lng: 18.5300956 },
    { lat: 48.9986471, lng: 18.5300585 },
    { lat: 49.0003162, lng: 18.5292648 },
    { lat: 49.0008527, lng: 18.5290721 },
    { lat: 49.0011482, lng: 18.5288001 },
    { lat: 49.0013797, lng: 18.5288889 },
    { lat: 49.0021141, lng: 18.528994 },
    { lat: 49.0025955, lng: 18.5289905 },
    { lat: 49.0033534, lng: 18.5290716 },
    { lat: 49.0038224, lng: 18.5291924 },
    { lat: 49.0043926, lng: 18.5294731 },
    { lat: 49.0053338, lng: 18.5300022 },
    { lat: 49.0065372, lng: 18.5301053 },
    { lat: 49.0074586, lng: 18.5302069 },
    { lat: 49.0085551, lng: 18.5303959 },
    { lat: 49.0087306, lng: 18.5303038 },
    { lat: 49.0087734, lng: 18.5302812 },
    { lat: 49.0096979, lng: 18.5297987 },
    { lat: 49.0099435, lng: 18.5298965 },
    { lat: 49.0103091, lng: 18.5304191 },
    { lat: 49.0107029, lng: 18.5307322 },
    { lat: 49.0112603, lng: 18.5315041 },
    { lat: 49.0117921, lng: 18.5321783 },
    { lat: 49.0127465, lng: 18.5318922 },
    { lat: 49.0133287, lng: 18.5318346 },
    { lat: 49.013587, lng: 18.5317695 },
    { lat: 49.0139256, lng: 18.5311719 },
    { lat: 49.014228, lng: 18.5313599 },
    { lat: 49.0151128, lng: 18.5319112 },
    { lat: 49.0151289, lng: 18.5319375 },
    { lat: 49.0151645, lng: 18.5319492 },
    { lat: 49.0159782, lng: 18.5323741 },
    { lat: 49.0164881, lng: 18.532647 },
    { lat: 49.0164377, lng: 18.5331726 },
    { lat: 49.0163619, lng: 18.5335415 },
    { lat: 49.0163042, lng: 18.5338286 },
    { lat: 49.0163365, lng: 18.5338433 },
    { lat: 49.0163925, lng: 18.533877 },
    { lat: 49.0164315, lng: 18.5338903 },
    { lat: 49.0168756, lng: 18.5340908 },
    { lat: 49.0179867, lng: 18.5339678 },
    { lat: 49.0181833, lng: 18.5336211 },
    { lat: 49.0189469, lng: 18.5332253 },
    { lat: 49.019715, lng: 18.5323734 },
    { lat: 49.0201569, lng: 18.5318285 },
    { lat: 49.0208507, lng: 18.5312088 },
    { lat: 49.0214236, lng: 18.5302722 },
    { lat: 49.0215583, lng: 18.5300525 },
    { lat: 49.0224345, lng: 18.5293829 },
    { lat: 49.0237954, lng: 18.5287709 },
    { lat: 49.024118, lng: 18.5286258 },
    { lat: 49.0250517, lng: 18.5279781 },
    { lat: 49.0255202, lng: 18.527653 },
    { lat: 49.0263999, lng: 18.5273188 },
    { lat: 49.0263509, lng: 18.527062 },
    { lat: 49.026181, lng: 18.5264864 },
    { lat: 49.0260072, lng: 18.5259247 },
    { lat: 49.0258274, lng: 18.5252308 },
    { lat: 49.0257307, lng: 18.5249658 },
    { lat: 49.0248419, lng: 18.5233685 },
    { lat: 49.0242585, lng: 18.522119 },
    { lat: 49.0241539, lng: 18.5212728 },
    { lat: 49.0241917, lng: 18.5205983 },
    { lat: 49.0241588, lng: 18.5197654 },
    { lat: 49.0241772, lng: 18.5190677 },
    { lat: 49.0241536, lng: 18.5188618 },
    { lat: 49.0241326, lng: 18.5188691 },
    { lat: 49.0238901, lng: 18.5180601 },
    { lat: 49.0233166, lng: 18.517077 },
    { lat: 49.0224973, lng: 18.5163847 },
    { lat: 49.0217747, lng: 18.5150461 },
    { lat: 49.0213267, lng: 18.5140892 },
    { lat: 49.0210772, lng: 18.5135251 },
    { lat: 49.0206766, lng: 18.5129831 },
    { lat: 49.0204195, lng: 18.5124649 },
    { lat: 49.0196937, lng: 18.5110059 },
    { lat: 49.0195633, lng: 18.5110776 },
    { lat: 49.0193454, lng: 18.5111969 },
    { lat: 49.0191342, lng: 18.5113165 },
    { lat: 49.0189389, lng: 18.5113954 },
    { lat: 49.0185569, lng: 18.5115486 },
    { lat: 49.0181806, lng: 18.5116368 },
    { lat: 49.0178039, lng: 18.511742 },
    { lat: 49.017149, lng: 18.5120218 },
    { lat: 49.0163802, lng: 18.5122565 },
    { lat: 49.0158002, lng: 18.5122537 },
    { lat: 49.0157732, lng: 18.5122327 },
    { lat: 49.0157379, lng: 18.5122048 },
    { lat: 49.0157115, lng: 18.5121781 },
    { lat: 49.0151156, lng: 18.511817 },
    { lat: 49.0149638, lng: 18.5117862 },
    { lat: 49.0147202, lng: 18.51164 },
    { lat: 49.0143485, lng: 18.5114443 },
    { lat: 49.0140236, lng: 18.51163 },
    { lat: 49.0136598, lng: 18.5116804 },
    { lat: 49.0133686, lng: 18.5116152 },
    { lat: 49.0130345, lng: 18.5118596 },
    { lat: 49.0120551, lng: 18.5119755 },
    { lat: 49.0103427, lng: 18.5125138 },
    { lat: 49.0103141, lng: 18.5126721 },
    { lat: 49.0103005, lng: 18.5127514 },
    { lat: 49.0101114, lng: 18.5133197 },
    { lat: 49.009738, lng: 18.5137938 },
    { lat: 49.0095679, lng: 18.5140067 },
    { lat: 49.0092621, lng: 18.5147497 },
    { lat: 49.0090607, lng: 18.5150481 },
    { lat: 49.0087957, lng: 18.5151325 },
    { lat: 49.0084672, lng: 18.5153556 },
    { lat: 49.0083594, lng: 18.5154288 },
    { lat: 49.0080407, lng: 18.5155397 },
    { lat: 49.0074507, lng: 18.5160989 },
    { lat: 49.0071668, lng: 18.5161881 },
    { lat: 49.0069812, lng: 18.5161764 },
    { lat: 49.0066924, lng: 18.516154 },
    { lat: 49.0060892, lng: 18.5165733 },
    { lat: 49.0056959, lng: 18.5167667 },
    { lat: 49.0056424, lng: 18.5167783 },
    { lat: 49.0054283, lng: 18.5168231 },
    { lat: 49.004749, lng: 18.5170048 },
    { lat: 49.0047001, lng: 18.5170184 },
    { lat: 49.0041289, lng: 18.5174044 },
    { lat: 49.0038281, lng: 18.5176376 },
    { lat: 49.0035177, lng: 18.5179676 },
    { lat: 49.003186, lng: 18.5183995 },
    { lat: 49.0028478, lng: 18.5191504 },
    { lat: 49.0024075, lng: 18.5193707 },
    { lat: 49.0017621, lng: 18.519781 },
    { lat: 49.001285, lng: 18.5199687 },
    { lat: 49.0003602, lng: 18.5201993 },
    { lat: 48.9999186, lng: 18.5200025 },
    { lat: 48.9994938, lng: 18.5200278 },
    { lat: 48.9991687, lng: 18.5198382 },
    { lat: 48.9989245, lng: 18.5198475 },
    { lat: 48.9983202, lng: 18.5201227 },
    { lat: 48.9975799, lng: 18.5211333 },
    { lat: 48.9969017, lng: 18.5219577 },
    { lat: 48.9966703, lng: 18.5225323 },
    { lat: 48.9964681, lng: 18.5230668 },
    { lat: 48.9961703, lng: 18.5235245 },
    { lat: 48.9958091, lng: 18.5239115 },
    { lat: 48.9955904, lng: 18.5243912 },
    { lat: 48.9955387, lng: 18.5245625 },
    { lat: 48.9949008, lng: 18.5262521 },
    { lat: 48.9945772, lng: 18.5270629 },
    { lat: 48.994175, lng: 18.5276541 },
    { lat: 48.992474, lng: 18.5266764 },
    { lat: 48.9909112, lng: 18.52567 },
  ],
  Ďurďové: [
    { lat: 49.0241536, lng: 18.5188618 },
    { lat: 49.0241702, lng: 18.5188563 },
    { lat: 49.0249011, lng: 18.5185444 },
    { lat: 49.0256692, lng: 18.5182317 },
    { lat: 49.0257365, lng: 18.5182045 },
    { lat: 49.0261659, lng: 18.5180321 },
    { lat: 49.0264939, lng: 18.5179055 },
    { lat: 49.0266438, lng: 18.5178399 },
    { lat: 49.0271271, lng: 18.517581 },
    { lat: 49.0279754, lng: 18.5171471 },
    { lat: 49.0284753, lng: 18.5167138 },
    { lat: 49.0286287, lng: 18.5165495 },
    { lat: 49.0294029, lng: 18.5154365 },
    { lat: 49.0297498, lng: 18.5149741 },
    { lat: 49.0302095, lng: 18.514423 },
    { lat: 49.0308922, lng: 18.5135798 },
    { lat: 49.0313749, lng: 18.5133076 },
    { lat: 49.0323023, lng: 18.5128983 },
    { lat: 49.0325867, lng: 18.5127964 },
    { lat: 49.0333879, lng: 18.5125355 },
    { lat: 49.0342725, lng: 18.5118788 },
    { lat: 49.0343497, lng: 18.5118664 },
    { lat: 49.0343877, lng: 18.5118604 },
    { lat: 49.0356963, lng: 18.5116553 },
    { lat: 49.0373461, lng: 18.511545 },
    { lat: 49.0378676, lng: 18.5110957 },
    { lat: 49.0394676, lng: 18.5105284 },
    { lat: 49.0397897, lng: 18.5100624 },
    { lat: 49.0401369, lng: 18.5096442 },
    { lat: 49.0405791, lng: 18.5096451 },
    { lat: 49.0411429, lng: 18.5094116 },
    { lat: 49.0414226, lng: 18.5094551 },
    { lat: 49.0420029, lng: 18.5099826 },
    { lat: 49.0424935, lng: 18.5102729 },
    { lat: 49.0429888, lng: 18.5105438 },
    { lat: 49.0438067, lng: 18.5105871 },
    { lat: 49.0440212, lng: 18.5106527 },
    { lat: 49.0440375, lng: 18.5106631 },
    { lat: 49.0443067, lng: 18.5105935 },
    { lat: 49.044445, lng: 18.5103165 },
    { lat: 49.0445024, lng: 18.5094845 },
    { lat: 49.044538, lng: 18.5091244 },
    { lat: 49.0446324, lng: 18.5083103 },
    { lat: 49.0444332, lng: 18.507013 },
    { lat: 49.0445778, lng: 18.5050704 },
    { lat: 49.0452083, lng: 18.5028963 },
    { lat: 49.0462802, lng: 18.5015166 },
    { lat: 49.0464454, lng: 18.5002012 },
    { lat: 49.0464998, lng: 18.4992132 },
    { lat: 49.0465362, lng: 18.4974798 },
    { lat: 49.0466597, lng: 18.4967465 },
    { lat: 49.0469556, lng: 18.4952243 },
    { lat: 49.0469616, lng: 18.4943005 },
    { lat: 49.0466087, lng: 18.4935403 },
    { lat: 49.0465437, lng: 18.4934004 },
    { lat: 49.0464161, lng: 18.4932347 },
    { lat: 49.0447498, lng: 18.492608 },
    { lat: 49.044427, lng: 18.4923149 },
    { lat: 49.0440572, lng: 18.4922147 },
    { lat: 49.0437889, lng: 18.492052 },
    { lat: 49.0425972, lng: 18.4921887 },
    { lat: 49.0413432, lng: 18.4925461 },
    { lat: 49.0406501, lng: 18.4919559 },
    { lat: 49.0405011, lng: 18.4918716 },
    { lat: 49.0403906, lng: 18.4910043 },
    { lat: 49.0403495, lng: 18.489941 },
    { lat: 49.0402997, lng: 18.4888656 },
    { lat: 49.0402155, lng: 18.4882731 },
    { lat: 49.0401311, lng: 18.4875345 },
    { lat: 49.0398861, lng: 18.487529 },
    { lat: 49.0394721, lng: 18.4871364 },
    { lat: 49.0391879, lng: 18.4869926 },
    { lat: 49.038716, lng: 18.4868427 },
    { lat: 49.0382372, lng: 18.4864592 },
    { lat: 49.0377545, lng: 18.4861093 },
    { lat: 49.0373241, lng: 18.4857487 },
    { lat: 49.0370249, lng: 18.4854347 },
    { lat: 49.0365955, lng: 18.4851719 },
    { lat: 49.0359494, lng: 18.4848859 },
    { lat: 49.0359292, lng: 18.4848485 },
    { lat: 49.0358964, lng: 18.4847844 },
    { lat: 49.0358815, lng: 18.484758 },
    { lat: 49.0358155, lng: 18.4846878 },
    { lat: 49.0357461, lng: 18.4846143 },
    { lat: 49.0348887, lng: 18.4839099 },
    { lat: 49.0345812, lng: 18.4839998 },
    { lat: 49.0343926, lng: 18.4838328 },
    { lat: 49.0340976, lng: 18.4835724 },
    { lat: 49.0337321, lng: 18.4834292 },
    { lat: 49.0337048, lng: 18.4834223 },
    { lat: 49.0334004, lng: 18.4833428 },
    { lat: 49.0333145, lng: 18.4833203 },
    { lat: 49.0326831, lng: 18.4829314 },
    { lat: 49.0324512, lng: 18.4826516 },
    { lat: 49.0319958, lng: 18.4825352 },
    { lat: 49.0314557, lng: 18.4825392 },
    { lat: 49.0309738, lng: 18.4825113 },
    { lat: 49.0304781, lng: 18.4827096 },
    { lat: 49.0276184, lng: 18.4883175 },
    { lat: 49.0273187, lng: 18.4885493 },
    { lat: 49.0275699, lng: 18.4893699 },
    { lat: 49.027915, lng: 18.4909172 },
    { lat: 49.0279098, lng: 18.4912973 },
    { lat: 49.0275836, lng: 18.4916027 },
    { lat: 49.027765, lng: 18.4921789 },
    { lat: 49.0275612, lng: 18.4926375 },
    { lat: 49.0272053, lng: 18.493039 },
    { lat: 49.0268679, lng: 18.4932018 },
    { lat: 49.0257771, lng: 18.4942361 },
    { lat: 49.025596, lng: 18.4938942 },
    { lat: 49.0246022, lng: 18.4943463 },
    { lat: 49.0246973, lng: 18.4948699 },
    { lat: 49.0245722, lng: 18.4949529 },
    { lat: 49.0251455, lng: 18.495847 },
    { lat: 49.0249461, lng: 18.4958946 },
    { lat: 49.0246496, lng: 18.4961026 },
    { lat: 49.0242907, lng: 18.4965128 },
    { lat: 49.0237986, lng: 18.4959082 },
    { lat: 49.0234786, lng: 18.4967221 },
    { lat: 49.0230599, lng: 18.4974605 },
    { lat: 49.0229545, lng: 18.4973067 },
    { lat: 49.0227441, lng: 18.4976067 },
    { lat: 49.0228996, lng: 18.4977884 },
    { lat: 49.023215, lng: 18.4982776 },
    { lat: 49.023055, lng: 18.4986532 },
    { lat: 49.0226276, lng: 18.4982195 },
    { lat: 49.0221871, lng: 18.4987872 },
    { lat: 49.0220031, lng: 18.4988258 },
    { lat: 49.0210651, lng: 18.4995594 },
    { lat: 49.0210272, lng: 18.4995883 },
    { lat: 49.0209851, lng: 18.4996213 },
    { lat: 49.021515, lng: 18.50028 },
    { lat: 49.0215758, lng: 18.5005883 },
    { lat: 49.0218901, lng: 18.501615 },
    { lat: 49.0221135, lng: 18.5026905 },
    { lat: 49.0221259, lng: 18.5027533 },
    { lat: 49.0216292, lng: 18.5034484 },
    { lat: 49.0214236, lng: 18.5041264 },
    { lat: 49.0204673, lng: 18.5076701 },
    { lat: 49.0204343, lng: 18.5080568 },
    { lat: 49.020511, lng: 18.5081388 },
    { lat: 49.0204586, lng: 18.5083375 },
    { lat: 49.0200145, lng: 18.5096273 },
    { lat: 49.0201309, lng: 18.5097766 },
    { lat: 49.0198481, lng: 18.5101342 },
    { lat: 49.0196756, lng: 18.5107144 },
    { lat: 49.0195055, lng: 18.5110317 },
    { lat: 49.0193454, lng: 18.5111969 },
    { lat: 49.0195633, lng: 18.5110776 },
    { lat: 49.0196937, lng: 18.5110059 },
    { lat: 49.0204195, lng: 18.5124649 },
    { lat: 49.0206766, lng: 18.5129831 },
    { lat: 49.0210772, lng: 18.5135251 },
    { lat: 49.0213267, lng: 18.5140892 },
    { lat: 49.0217747, lng: 18.5150461 },
    { lat: 49.0224973, lng: 18.5163847 },
    { lat: 49.0233166, lng: 18.517077 },
    { lat: 49.0238901, lng: 18.5180601 },
    { lat: 49.0241326, lng: 18.5188691 },
    { lat: 49.0241536, lng: 18.5188618 },
  ],
  DolnýLieskov: [
    { lat: 49.00891, lng: 18.39947 },
    { lat: 49.008008, lng: 18.400168 },
    { lat: 49.006873, lng: 18.400933 },
    { lat: 49.006363, lng: 18.401281 },
    { lat: 49.006107, lng: 18.401544 },
    { lat: 49.005095, lng: 18.40252 },
    { lat: 49.004747, lng: 18.403211 },
    { lat: 49.004628, lng: 18.40378 },
    { lat: 49.001345, lng: 18.41185 },
    { lat: 49.000015, lng: 18.409753 },
    { lat: 48.999404, lng: 18.410875 },
    { lat: 48.997904, lng: 18.411327 },
    { lat: 48.998411, lng: 18.413535 },
    { lat: 48.998289, lng: 18.414974 },
    { lat: 48.998741, lng: 18.416538 },
    { lat: 48.998723, lng: 18.418686 },
    { lat: 48.998387, lng: 18.420205 },
    { lat: 48.998458, lng: 18.420259 },
    { lat: 48.998262, lng: 18.420735 },
    { lat: 48.9983149, lng: 18.4207713 },
    { lat: 48.9984631, lng: 18.4207388 },
    { lat: 48.9998251, lng: 18.4210944 },
    { lat: 49.0003831, lng: 18.4214529 },
    { lat: 49.0008784, lng: 18.4219854 },
    { lat: 49.0014035, lng: 18.4228115 },
    { lat: 49.0016005, lng: 18.4230903 },
    { lat: 49.0019432, lng: 18.4234148 },
    { lat: 49.0019717, lng: 18.4235215 },
    { lat: 49.0024184, lng: 18.4238472 },
    { lat: 49.0025642, lng: 18.4239532 },
    { lat: 49.0031092, lng: 18.4242228 },
    { lat: 49.0034252, lng: 18.4242253 },
    { lat: 49.0036855, lng: 18.4242397 },
    { lat: 49.0038449, lng: 18.4243497 },
    { lat: 49.0046361, lng: 18.4246431 },
    { lat: 49.0046726, lng: 18.4247322 },
    { lat: 49.00512, lng: 18.4248056 },
    { lat: 49.0056103, lng: 18.4250434 },
    { lat: 49.0056882, lng: 18.4250435 },
    { lat: 49.0057282, lng: 18.4250744 },
    { lat: 49.0057883, lng: 18.425121 },
    { lat: 49.005822, lng: 18.4251598 },
    { lat: 49.0058852, lng: 18.4252 },
    { lat: 49.0059526, lng: 18.4252775 },
    { lat: 49.0060919, lng: 18.4254934 },
    { lat: 49.006813, lng: 18.4261907 },
    { lat: 49.0074348, lng: 18.4268914 },
    { lat: 49.0078622, lng: 18.4275211 },
    { lat: 49.0088242, lng: 18.4291313 },
    { lat: 49.0090585, lng: 18.4294117 },
    { lat: 49.0093116, lng: 18.4295612 },
    { lat: 49.0099167, lng: 18.4297349 },
    { lat: 49.0105013, lng: 18.4299029 },
    { lat: 49.010723, lng: 18.4300166 },
    { lat: 49.0110878, lng: 18.4303504 },
    { lat: 49.0115659, lng: 18.4311426 },
    { lat: 49.01186, lng: 18.4317725 },
    { lat: 49.0122428, lng: 18.4324543 },
    { lat: 49.0126736, lng: 18.4337405 },
    { lat: 49.0132059, lng: 18.4346609 },
    { lat: 49.0135376, lng: 18.4350919 },
    { lat: 49.0137113, lng: 18.4353365 },
    { lat: 49.0140947, lng: 18.4355801 },
    { lat: 49.0144375, lng: 18.4356996 },
    { lat: 49.0146473, lng: 18.4358625 },
    { lat: 49.0148362, lng: 18.4360154 },
    { lat: 49.0150255, lng: 18.4362782 },
    { lat: 49.015317, lng: 18.4369819 },
    { lat: 49.0157162, lng: 18.4374696 },
    { lat: 49.015829, lng: 18.4379424 },
    { lat: 49.0159742, lng: 18.4381609 },
    { lat: 49.0160198, lng: 18.4382306 },
    { lat: 49.0160419, lng: 18.4382637 },
    { lat: 49.0160444, lng: 18.4382682 },
    { lat: 49.016277, lng: 18.4386217 },
    { lat: 49.0167486, lng: 18.4391131 },
    { lat: 49.0177068, lng: 18.4396339 },
    { lat: 49.0178489, lng: 18.439982 },
    { lat: 49.0178505, lng: 18.440136 },
    { lat: 49.0178606, lng: 18.440194 },
    { lat: 49.01781, lng: 18.440218 },
    { lat: 49.0178025, lng: 18.4403685 },
    { lat: 49.0179278, lng: 18.4405344 },
    { lat: 49.0179975, lng: 18.4405079 },
    { lat: 49.0180291, lng: 18.4408565 },
    { lat: 49.0181133, lng: 18.4411764 },
    { lat: 49.0182496, lng: 18.4412769 },
    { lat: 49.0182451, lng: 18.4413803 },
    { lat: 49.0183138, lng: 18.4413974 },
    { lat: 49.0184076, lng: 18.4414214 },
    { lat: 49.0184808, lng: 18.4414397 },
    { lat: 49.019476, lng: 18.4416919 },
    { lat: 49.019645, lng: 18.4418105 },
    { lat: 49.021433, lng: 18.4432251 },
    { lat: 49.0222323, lng: 18.444303 },
    { lat: 49.022395, lng: 18.4451887 },
    { lat: 49.022659, lng: 18.4458053 },
    { lat: 49.0230395, lng: 18.4464273 },
    { lat: 49.0232695, lng: 18.4469566 },
    { lat: 49.0249079, lng: 18.4492637 },
    { lat: 49.024972, lng: 18.4504906 },
    { lat: 49.0252196, lng: 18.4518136 },
    { lat: 49.025462, lng: 18.4524082 },
    { lat: 49.0257884, lng: 18.4529683 },
    { lat: 49.0263899, lng: 18.4538766 },
    { lat: 49.0263778, lng: 18.4559347 },
    { lat: 49.0264033, lng: 18.4569345 },
    { lat: 49.0268269, lng: 18.45711 },
    { lat: 49.0275333, lng: 18.4571962 },
    { lat: 49.0278374, lng: 18.4575765 },
    { lat: 49.0281955, lng: 18.4595403 },
    { lat: 49.0290451, lng: 18.4606301 },
    { lat: 49.0291736, lng: 18.4607944 },
    { lat: 49.0296353, lng: 18.4612743 },
    { lat: 49.0296546, lng: 18.4612956 },
    { lat: 49.0296977, lng: 18.4613397 },
    { lat: 49.0297515, lng: 18.4613965 },
    { lat: 49.0298173, lng: 18.4614658 },
    { lat: 49.0300496, lng: 18.4617095 },
    { lat: 49.0300759, lng: 18.4617366 },
    { lat: 49.0300794, lng: 18.4617404 },
    { lat: 49.0301165, lng: 18.4617794 },
    { lat: 49.0301345, lng: 18.4617991 },
    { lat: 49.0312352, lng: 18.4633757 },
    { lat: 49.031264, lng: 18.4634287 },
    { lat: 49.0312927, lng: 18.46348 },
    { lat: 49.0313317, lng: 18.4635534 },
    { lat: 49.0315362, lng: 18.4639285 },
    { lat: 49.0315563, lng: 18.4639658 },
    { lat: 49.0315737, lng: 18.4639976 },
    { lat: 49.0315964, lng: 18.4640396 },
    { lat: 49.0319227, lng: 18.4646403 },
    { lat: 49.0320091, lng: 18.4647979 },
    { lat: 49.0320669, lng: 18.4649035 },
    { lat: 49.0326488, lng: 18.4659728 },
    { lat: 49.0329985, lng: 18.4666323 },
    { lat: 49.0332426, lng: 18.4670921 },
    { lat: 49.0333792, lng: 18.4669516 },
    { lat: 49.0334147, lng: 18.4669164 },
    { lat: 49.0334732, lng: 18.4668552 },
    { lat: 49.0334957, lng: 18.4668321 },
    { lat: 49.0335448, lng: 18.4667818 },
    { lat: 49.0336004, lng: 18.4667253 },
    { lat: 49.0365604, lng: 18.462902 },
    { lat: 49.0364522, lng: 18.4625754 },
    { lat: 49.0363441, lng: 18.4622509 },
    { lat: 49.0362379, lng: 18.4608892 },
    { lat: 49.035867, lng: 18.4601745 },
    { lat: 49.035891, lng: 18.459708 },
    { lat: 49.036166, lng: 18.4588804 },
    { lat: 49.0360901, lng: 18.4584838 },
    { lat: 49.0362697, lng: 18.4570297 },
    { lat: 49.0363987, lng: 18.4566484 },
    { lat: 49.0372365, lng: 18.4544025 },
    { lat: 49.0373054, lng: 18.4537897 },
    { lat: 49.0368203, lng: 18.4524737 },
    { lat: 49.0377076, lng: 18.4512131 },
    { lat: 49.038802, lng: 18.4496572 },
    { lat: 49.0388537, lng: 18.4496323 },
    { lat: 49.0388812, lng: 18.4496191 },
    { lat: 49.0390767, lng: 18.4495241 },
    { lat: 49.0390976, lng: 18.4495139 },
    { lat: 49.0392635, lng: 18.4494338 },
    { lat: 49.0394173, lng: 18.4493589 },
    { lat: 49.0396231, lng: 18.4491623 },
    { lat: 49.0396659, lng: 18.4491213 },
    { lat: 49.0397078, lng: 18.4490813 },
    { lat: 49.0397453, lng: 18.4490457 },
    { lat: 49.040004, lng: 18.4488687 },
    { lat: 49.0404149, lng: 18.4485877 },
    { lat: 49.0404866, lng: 18.4485374 },
    { lat: 49.0407459, lng: 18.4482896 },
    { lat: 49.0408568, lng: 18.4485195 },
    { lat: 49.040967, lng: 18.448474 },
    { lat: 49.0411555, lng: 18.4479833 },
    { lat: 49.0412109, lng: 18.4478407 },
    { lat: 49.0416797, lng: 18.4485516 },
    { lat: 49.0426814, lng: 18.4494547 },
    { lat: 49.0434644, lng: 18.4501753 },
    { lat: 49.0438582, lng: 18.4506699 },
    { lat: 49.0439551, lng: 18.4513246 },
    { lat: 49.0445529, lng: 18.4501959 },
    { lat: 49.0449713, lng: 18.4492709 },
    { lat: 49.0456184, lng: 18.448299 },
    { lat: 49.0462575, lng: 18.4478603 },
    { lat: 49.0466712, lng: 18.44734 },
    { lat: 49.0467945, lng: 18.4469773 },
    { lat: 49.0468216, lng: 18.446897 },
    { lat: 49.0472101, lng: 18.4468436 },
    { lat: 49.0478551, lng: 18.4469209 },
    { lat: 49.04857, lng: 18.4470992 },
    { lat: 49.0494881, lng: 18.4473248 },
    { lat: 49.0500022, lng: 18.4475032 },
    { lat: 49.0502588, lng: 18.4474121 },
    { lat: 49.0510553, lng: 18.4479474 },
    { lat: 49.0515829, lng: 18.448188 },
    { lat: 49.0518366, lng: 18.4484736 },
    { lat: 49.0525496, lng: 18.4474286 },
    { lat: 49.0526644, lng: 18.4472591 },
    { lat: 49.0535206, lng: 18.4460276 },
    { lat: 49.0543039, lng: 18.4449901 },
    { lat: 49.0543442, lng: 18.4449459 },
    { lat: 49.0557048, lng: 18.4434509 },
    { lat: 49.0550517, lng: 18.4423041 },
    { lat: 49.0550229, lng: 18.4422527 },
    { lat: 49.054497, lng: 18.4413256 },
    { lat: 49.0542638, lng: 18.4408579 },
    { lat: 49.0540292, lng: 18.4403884 },
    { lat: 49.0535995, lng: 18.4395288 },
    { lat: 49.0530122, lng: 18.4386944 },
    { lat: 49.0523446, lng: 18.4376537 },
    { lat: 49.0525593, lng: 18.4371489 },
    { lat: 49.0529747, lng: 18.4375405 },
    { lat: 49.0533759, lng: 18.4381086 },
    { lat: 49.0534664, lng: 18.4379719 },
    { lat: 49.0536327, lng: 18.4381622 },
    { lat: 49.0537935, lng: 18.4385841 },
    { lat: 49.0537049, lng: 18.4387367 },
    { lat: 49.0541422, lng: 18.4393262 },
    { lat: 49.0544628, lng: 18.4396367 },
    { lat: 49.0550158, lng: 18.4383911 },
    { lat: 49.0551239, lng: 18.4381426 },
    { lat: 49.0556719, lng: 18.4368481 },
    { lat: 49.0560444, lng: 18.4357871 },
    { lat: 49.0561062, lng: 18.435617 },
    { lat: 49.0561312, lng: 18.4355383 },
    { lat: 49.0559494, lng: 18.435371 },
    { lat: 49.0557766, lng: 18.4352665 },
    { lat: 49.0556428, lng: 18.4352123 },
    { lat: 49.0552536, lng: 18.4348843 },
    { lat: 49.0551095, lng: 18.4347061 },
    { lat: 49.054968, lng: 18.4344303 },
    { lat: 49.0544496, lng: 18.4331276 },
    { lat: 49.0543571, lng: 18.432924 },
    { lat: 49.0542128, lng: 18.4326386 },
    { lat: 49.0541207, lng: 18.432483 },
    { lat: 49.0538925, lng: 18.4321895 },
    { lat: 49.0540555, lng: 18.4313805 },
    { lat: 49.0540548, lng: 18.4311227 },
    { lat: 49.0540542, lng: 18.43101 },
    { lat: 49.054045, lng: 18.4308645 },
    { lat: 49.0540979, lng: 18.4306142 },
    { lat: 49.0541493, lng: 18.4304624 },
    { lat: 49.0543051, lng: 18.4302612 },
    { lat: 49.0544728, lng: 18.4301521 },
    { lat: 49.0547272, lng: 18.429938 },
    { lat: 49.0547822, lng: 18.4298916 },
    { lat: 49.0548567, lng: 18.4300988 },
    { lat: 49.0552219, lng: 18.4308297 },
    { lat: 49.0554821, lng: 18.4313936 },
    { lat: 49.0556812, lng: 18.431794 },
    { lat: 49.0560215, lng: 18.4316035 },
    { lat: 49.0561742, lng: 18.4315096 },
    { lat: 49.0562138, lng: 18.4314689 },
    { lat: 49.0563571, lng: 18.4313066 },
    { lat: 49.056661, lng: 18.4309258 },
    { lat: 49.0567853, lng: 18.430704 },
    { lat: 49.0568411, lng: 18.430756 },
    { lat: 49.0568786, lng: 18.430677 },
    { lat: 49.0569433, lng: 18.4305619 },
    { lat: 49.0570654, lng: 18.4304035 },
    { lat: 49.0571029, lng: 18.4303063 },
    { lat: 49.0571326, lng: 18.4301504 },
    { lat: 49.0571653, lng: 18.4300681 },
    { lat: 49.0573051, lng: 18.4299622 },
    { lat: 49.0574047, lng: 18.4297238 },
    { lat: 49.0574985, lng: 18.4295023 },
    { lat: 49.057509, lng: 18.4294894 },
    { lat: 49.0575262, lng: 18.4294328 },
    { lat: 49.057674, lng: 18.428962 },
    { lat: 49.0577608, lng: 18.4287563 },
    { lat: 49.0577856, lng: 18.428695 },
    { lat: 49.0581034, lng: 18.428159 },
    { lat: 49.0583241, lng: 18.4277406 },
    { lat: 49.0584539, lng: 18.4275373 },
    { lat: 49.0585965, lng: 18.4272202 },
    { lat: 49.0587164, lng: 18.4267542 },
    { lat: 49.0587731, lng: 18.4265547 },
    { lat: 49.0588726, lng: 18.4263127 },
    { lat: 49.0589037, lng: 18.4262016 },
    { lat: 49.0589456, lng: 18.4261232 },
    { lat: 49.0589745, lng: 18.4260346 },
    { lat: 49.0589832, lng: 18.4258867 },
    { lat: 49.0589656, lng: 18.4256676 },
    { lat: 49.058926, lng: 18.4255027 },
    { lat: 49.0588861, lng: 18.4250251 },
    { lat: 49.0588589, lng: 18.4247146 },
    { lat: 49.0588636, lng: 18.4245757 },
    { lat: 49.0588866, lng: 18.4244812 },
    { lat: 49.0588953, lng: 18.4244344 },
    { lat: 49.0589576, lng: 18.4241126 },
    { lat: 49.058961, lng: 18.4240908 },
    { lat: 49.058969, lng: 18.4240941 },
    { lat: 49.0592055, lng: 18.4241883 },
    { lat: 49.0592207, lng: 18.4241576 },
    { lat: 49.0592318, lng: 18.4241148 },
    { lat: 49.0592522, lng: 18.4240356 },
    { lat: 49.0593162, lng: 18.4238832 },
    { lat: 49.0593589, lng: 18.4235137 },
    { lat: 49.0594324, lng: 18.4233122 },
    { lat: 49.0597428, lng: 18.4234398 },
    { lat: 49.0598132, lng: 18.4233434 },
    { lat: 49.0597495, lng: 18.4231728 },
    { lat: 49.0597224, lng: 18.4230291 },
    { lat: 49.0596155, lng: 18.4228687 },
    { lat: 49.0591358, lng: 18.4226196 },
    { lat: 49.0589326, lng: 18.4225752 },
    { lat: 49.0587886, lng: 18.4225006 },
    { lat: 49.0589494, lng: 18.4219812 },
    { lat: 49.0589614, lng: 18.4219523 },
    { lat: 49.0592887, lng: 18.4210976 },
    { lat: 49.0594506, lng: 18.4211676 },
    { lat: 49.0597103, lng: 18.4213567 },
    { lat: 49.060103, lng: 18.4215647 },
    { lat: 49.0603421, lng: 18.4217471 },
    { lat: 49.0606252, lng: 18.4219926 },
    { lat: 49.060682, lng: 18.4218368 },
    { lat: 49.0608437, lng: 18.4214125 },
    { lat: 49.0610353, lng: 18.4207578 },
    { lat: 49.0611216, lng: 18.4204184 },
    { lat: 49.0612858, lng: 18.4197564 },
    { lat: 49.061396, lng: 18.4194222 },
    { lat: 49.0614154, lng: 18.4193866 },
    { lat: 49.0614883, lng: 18.4195841 },
    { lat: 49.0615687, lng: 18.4197116 },
    { lat: 49.0617744, lng: 18.4201263 },
    { lat: 49.0618924, lng: 18.420283 },
    { lat: 49.0623221, lng: 18.4204847 },
    { lat: 49.0623486, lng: 18.420495 },
    { lat: 49.0625634, lng: 18.4205238 },
    { lat: 49.0626541, lng: 18.4203899 },
    { lat: 49.0626776, lng: 18.4202443 },
    { lat: 49.0626631, lng: 18.4201033 },
    { lat: 49.0626929, lng: 18.4198467 },
    { lat: 49.0627094, lng: 18.4193478 },
    { lat: 49.0628342, lng: 18.4188287 },
    { lat: 49.0628957, lng: 18.4186561 },
    { lat: 49.062948, lng: 18.4184383 },
    { lat: 49.0629563, lng: 18.4182201 },
    { lat: 49.0629396, lng: 18.4180205 },
    { lat: 49.0629058, lng: 18.4178172 },
    { lat: 49.0628437, lng: 18.4176521 },
    { lat: 49.0628005, lng: 18.4173186 },
    { lat: 49.0626931, lng: 18.4167843 },
    { lat: 49.0626953, lng: 18.416641 },
    { lat: 49.0626647, lng: 18.4165174 },
    { lat: 49.0627362, lng: 18.4163612 },
    { lat: 49.0627723, lng: 18.4161961 },
    { lat: 49.0628166, lng: 18.4161412 },
    { lat: 49.0628376, lng: 18.4160518 },
    { lat: 49.0628384, lng: 18.4158619 },
    { lat: 49.0627547, lng: 18.4155528 },
    { lat: 49.0626626, lng: 18.4149042 },
    { lat: 49.0625758, lng: 18.4144784 },
    { lat: 49.0626806, lng: 18.4143114 },
    { lat: 49.0628377, lng: 18.4141139 },
    { lat: 49.0629652, lng: 18.4139316 },
    { lat: 49.0631699, lng: 18.4135304 },
    { lat: 49.0632703, lng: 18.4133895 },
    { lat: 49.0633513, lng: 18.4132419 },
    { lat: 49.0633892, lng: 18.4130504 },
    { lat: 49.0633998, lng: 18.4127936 },
    { lat: 49.063517, lng: 18.4124241 },
    { lat: 49.0635942, lng: 18.4121658 },
    { lat: 49.0636266, lng: 18.4120187 },
    { lat: 49.0636531, lng: 18.4118437 },
    { lat: 49.0636655, lng: 18.4116597 },
    { lat: 49.0636672, lng: 18.411466 },
    { lat: 49.0636463, lng: 18.4112938 },
    { lat: 49.0635835, lng: 18.4110741 },
    { lat: 49.0635298, lng: 18.4107981 },
    { lat: 49.0635096, lng: 18.4106357 },
    { lat: 49.0634843, lng: 18.4105249 },
    { lat: 49.0634157, lng: 18.4104064 },
    { lat: 49.0634068, lng: 18.4103701 },
    { lat: 49.0635102, lng: 18.4103212 },
    { lat: 49.0636526, lng: 18.4102631 },
    { lat: 49.0639208, lng: 18.4100944 },
    { lat: 49.0641143, lng: 18.409943 },
    { lat: 49.0641816, lng: 18.4097739 },
    { lat: 49.0643628, lng: 18.4090915 },
    { lat: 49.0643782, lng: 18.4089629 },
    { lat: 49.0643788, lng: 18.4087294 },
    { lat: 49.0643732, lng: 18.4084653 },
    { lat: 49.0642082, lng: 18.4078877 },
    { lat: 49.06407, lng: 18.4075713 },
    { lat: 49.0639754, lng: 18.4074106 },
    { lat: 49.0637664, lng: 18.4071413 },
    { lat: 49.0635007, lng: 18.4066199 },
    { lat: 49.0633113, lng: 18.4062773 },
    { lat: 49.063221, lng: 18.4060708 },
    { lat: 49.0629842, lng: 18.4052977 },
    { lat: 49.0629747, lng: 18.4052067 },
    { lat: 49.0629779, lng: 18.4050608 },
    { lat: 49.0630257, lng: 18.4046014 },
    { lat: 49.0630803, lng: 18.404281 },
    { lat: 49.0632009, lng: 18.4034035 },
    { lat: 49.0632286, lng: 18.4030692 },
    { lat: 49.0632288, lng: 18.4029479 },
    { lat: 49.0631465, lng: 18.402298 },
    { lat: 49.0631082, lng: 18.4018498 },
    { lat: 49.0630798, lng: 18.4017049 },
    { lat: 49.0630247, lng: 18.4011571 },
    { lat: 49.0629909, lng: 18.4007718 },
    { lat: 49.0629805, lng: 18.4005023 },
    { lat: 49.0629554, lng: 18.400212 },
    { lat: 49.0629687, lng: 18.3999626 },
    { lat: 49.0630104, lng: 18.399452 },
    { lat: 49.0630684, lng: 18.3989516 },
    { lat: 49.0630734, lng: 18.3987367 },
    { lat: 49.0630852, lng: 18.3984398 },
    { lat: 49.0630757, lng: 18.3982682 },
    { lat: 49.0630985, lng: 18.3979478 },
    { lat: 49.0631001, lng: 18.3979347 },
    { lat: 49.063112, lng: 18.3979062 },
    { lat: 49.0636378, lng: 18.3967064 },
    { lat: 49.063656, lng: 18.396645 },
    { lat: 49.063315, lng: 18.396082 },
    { lat: 49.062842, lng: 18.394931 },
    { lat: 49.062683, lng: 18.394421 },
    { lat: 49.062241, lng: 18.393525 },
    { lat: 49.061786, lng: 18.392929 },
    { lat: 49.060857, lng: 18.392398 },
    { lat: 49.060592, lng: 18.392314 },
    { lat: 49.060552, lng: 18.392301 },
    { lat: 49.060546, lng: 18.3923 },
    { lat: 49.060498, lng: 18.392284 },
    { lat: 49.060447, lng: 18.392268 },
    { lat: 49.060204, lng: 18.39235 },
    { lat: 49.060145, lng: 18.392388 },
    { lat: 49.060083, lng: 18.392395 },
    { lat: 49.05994, lng: 18.392252 },
    { lat: 49.0600085, lng: 18.3923474 },
    { lat: 49.0597423, lng: 18.3929989 },
    { lat: 49.0597691, lng: 18.3933185 },
    { lat: 49.0598479, lng: 18.3934578 },
    { lat: 49.0599755, lng: 18.3936857 },
    { lat: 49.0599419, lng: 18.3939319 },
    { lat: 49.0600866, lng: 18.3943468 },
    { lat: 49.0599752, lng: 18.3945542 },
    { lat: 49.0599068, lng: 18.3945429 },
    { lat: 49.0597852, lng: 18.3946065 },
    { lat: 49.0594613, lng: 18.3954394 },
    { lat: 49.0596051, lng: 18.3956729 },
    { lat: 49.0594343, lng: 18.3959484 },
    { lat: 49.0591168, lng: 18.3959608 },
    { lat: 49.0589906, lng: 18.3962478 },
    { lat: 49.0587472, lng: 18.3965388 },
    { lat: 49.0584706, lng: 18.396717 },
    { lat: 49.0583773, lng: 18.3974358 },
    { lat: 49.0579682, lng: 18.3975437 },
    { lat: 49.0577805, lng: 18.3979832 },
    { lat: 49.057685, lng: 18.3989699 },
    { lat: 49.0574471, lng: 18.3994205 },
    { lat: 49.0574213, lng: 18.3994612 },
    { lat: 49.0573912, lng: 18.39951 },
    { lat: 49.057337, lng: 18.3997132 },
    { lat: 49.0571594, lng: 18.399927 },
    { lat: 49.0569898, lng: 18.4003906 },
    { lat: 49.0568734, lng: 18.4012218 },
    { lat: 49.056693, lng: 18.4017516 },
    { lat: 49.0564475, lng: 18.4019222 },
    { lat: 49.0562564, lng: 18.4018523 },
    { lat: 49.0560606, lng: 18.4018623 },
    { lat: 49.0560241, lng: 18.4019567 },
    { lat: 49.0560316, lng: 18.4022543 },
    { lat: 49.0558585, lng: 18.4028782 },
    { lat: 49.0556557, lng: 18.4029646 },
    { lat: 49.0555805, lng: 18.4030718 },
    { lat: 49.0555146, lng: 18.403717 },
    { lat: 49.0555303, lng: 18.4038159 },
    { lat: 49.0558361, lng: 18.4041247 },
    { lat: 49.0558624, lng: 18.4044977 },
    { lat: 49.0558113, lng: 18.4045728 },
    { lat: 49.0556549, lng: 18.4045599 },
    { lat: 49.0555111, lng: 18.4047128 },
    { lat: 49.0556183, lng: 18.4050395 },
    { lat: 49.0555755, lng: 18.4053865 },
    { lat: 49.0553246, lng: 18.4053385 },
    { lat: 49.055142, lng: 18.4054424 },
    { lat: 49.0551867, lng: 18.4057003 },
    { lat: 49.054885, lng: 18.4058548 },
    { lat: 49.0547414, lng: 18.4057671 },
    { lat: 49.0545539, lng: 18.4057616 },
    { lat: 49.0545312, lng: 18.4058221 },
    { lat: 49.0544921, lng: 18.4063367 },
    { lat: 49.0546728, lng: 18.406381 },
    { lat: 49.0546162, lng: 18.4065419 },
    { lat: 49.0547629, lng: 18.407317 },
    { lat: 49.0550106, lng: 18.4074511 },
    { lat: 49.0549097, lng: 18.4076874 },
    { lat: 49.0547314, lng: 18.4078081 },
    { lat: 49.0545853, lng: 18.408021 },
    { lat: 49.0543846, lng: 18.4080665 },
    { lat: 49.0542106, lng: 18.4081618 },
    { lat: 49.0540931, lng: 18.4082407 },
    { lat: 49.0540273, lng: 18.4087837 },
    { lat: 49.0538536, lng: 18.4091523 },
    { lat: 49.053675, lng: 18.4095321 },
    { lat: 49.0536384, lng: 18.4096244 },
    { lat: 49.0535227, lng: 18.4100009 },
    { lat: 49.0535176, lng: 18.4100315 },
    { lat: 49.0535126, lng: 18.4100631 },
    { lat: 49.0535086, lng: 18.410092 },
    { lat: 49.0535027, lng: 18.4101291 },
    { lat: 49.0534944, lng: 18.4101818 },
    { lat: 49.053486, lng: 18.4102352 },
    { lat: 49.0534818, lng: 18.4102627 },
    { lat: 49.0534495, lng: 18.410474 },
    { lat: 49.0535442, lng: 18.4111035 },
    { lat: 49.0536877, lng: 18.411292 },
    { lat: 49.0536101, lng: 18.4115224 },
    { lat: 49.0536644, lng: 18.4120553 },
    { lat: 49.0536788, lng: 18.4121924 },
    { lat: 49.0535984, lng: 18.4123906 },
    { lat: 49.0535132, lng: 18.4126046 },
    { lat: 49.0533346, lng: 18.4130473 },
    { lat: 49.0533361, lng: 18.4130748 },
    { lat: 49.0533582, lng: 18.4133705 },
    { lat: 49.053362, lng: 18.4134186 },
    { lat: 49.0530239, lng: 18.4136693 },
    { lat: 49.0528936, lng: 18.4143336 },
    { lat: 49.052683, lng: 18.4148926 },
    { lat: 49.0525623, lng: 18.415217 },
    { lat: 49.0525346, lng: 18.4152892 },
    { lat: 49.0525338, lng: 18.415294 },
    { lat: 49.052526, lng: 18.4153144 },
    { lat: 49.0524101, lng: 18.4159122 },
    { lat: 49.0522975, lng: 18.4164702 },
    { lat: 49.0521227, lng: 18.4173275 },
    { lat: 49.0520969, lng: 18.4174542 },
    { lat: 49.0517359, lng: 18.4180437 },
    { lat: 49.0509597, lng: 18.4187982 },
    { lat: 49.0507589, lng: 18.4188599 },
    { lat: 49.0505685, lng: 18.4191297 },
    { lat: 49.0503981, lng: 18.4190872 },
    { lat: 49.0499639, lng: 18.4193351 },
    { lat: 49.0498953, lng: 18.4196246 },
    { lat: 49.0496969, lng: 18.4197494 },
    { lat: 49.0495811, lng: 18.4201033 },
    { lat: 49.0492923, lng: 18.4201431 },
    { lat: 49.0490277, lng: 18.4204794 },
    { lat: 49.0488575, lng: 18.4209934 },
    { lat: 49.048902, lng: 18.4213898 },
    { lat: 49.0488485, lng: 18.4215042 },
    { lat: 49.0486459, lng: 18.4214937 },
    { lat: 49.0482967, lng: 18.4222792 },
    { lat: 49.0481664, lng: 18.4227784 },
    { lat: 49.0478897, lng: 18.4230996 },
    { lat: 49.0476378, lng: 18.4236853 },
    { lat: 49.0472202, lng: 18.424479 },
    { lat: 49.0472336, lng: 18.4247636 },
    { lat: 49.0473771, lng: 18.4251354 },
    { lat: 49.0472874, lng: 18.4255126 },
    { lat: 49.0469267, lng: 18.4254551 },
    { lat: 49.0469546, lng: 18.4255116 },
    { lat: 49.0469787, lng: 18.4255614 },
    { lat: 49.0468605, lng: 18.4255869 },
    { lat: 49.0468743, lng: 18.4259188 },
    { lat: 49.0466335, lng: 18.4266244 },
    { lat: 49.0463572, lng: 18.4261967 },
    { lat: 49.046226, lng: 18.4263533 },
    { lat: 49.0461762, lng: 18.4266146 },
    { lat: 49.0463048, lng: 18.4268614 },
    { lat: 49.0461989, lng: 18.4271302 },
    { lat: 49.0458187, lng: 18.427353 },
    { lat: 49.0455345, lng: 18.4272116 },
    { lat: 49.0454597, lng: 18.4277572 },
    { lat: 49.0452497, lng: 18.4279177 },
    { lat: 49.0442591, lng: 18.4277932 },
    { lat: 49.0435539, lng: 18.4276872 },
    { lat: 49.0433957, lng: 18.4276614 },
    { lat: 49.042838, lng: 18.4275718 },
    { lat: 49.0425944, lng: 18.4275939 },
    { lat: 49.0425828, lng: 18.4274036 },
    { lat: 49.0420322, lng: 18.4274021 },
    { lat: 49.0421808, lng: 18.4268643 },
    { lat: 49.0419895, lng: 18.4267711 },
    { lat: 49.0416639, lng: 18.4266969 },
    { lat: 49.0411646, lng: 18.4267858 },
    { lat: 49.0411313, lng: 18.4266944 },
    { lat: 49.0406697, lng: 18.4254012 },
    { lat: 49.0402989, lng: 18.4246315 },
    { lat: 49.0405457, lng: 18.4241757 },
    { lat: 49.040388, lng: 18.423998 },
    { lat: 49.0399659, lng: 18.4244422 },
    { lat: 49.0396131, lng: 18.4238748 },
    { lat: 49.0402211, lng: 18.4226484 },
    { lat: 49.0397232, lng: 18.4222715 },
    { lat: 49.0393897, lng: 18.4218729 },
    { lat: 49.0390343, lng: 18.4219922 },
    { lat: 49.0382086, lng: 18.4212788 },
    { lat: 49.0381838, lng: 18.4204817 },
    { lat: 49.0380617, lng: 18.4199666 },
    { lat: 49.0379146, lng: 18.4197731 },
    { lat: 49.037784, lng: 18.4195749 },
    { lat: 49.0370968, lng: 18.4199184 },
    { lat: 49.0369914, lng: 18.4197044 },
    { lat: 49.037169, lng: 18.4193468 },
    { lat: 49.0369091, lng: 18.4187451 },
    { lat: 49.0368279, lng: 18.4184603 },
    { lat: 49.0366406, lng: 18.4181754 },
    { lat: 49.0361649, lng: 18.4186694 },
    { lat: 49.0359397, lng: 18.4180848 },
    { lat: 49.0355761, lng: 18.4177123 },
    { lat: 49.0352287, lng: 18.4169567 },
    { lat: 49.0335634, lng: 18.4135234 },
    { lat: 49.0330653, lng: 18.4131458 },
    { lat: 49.0326231, lng: 18.4121853 },
    { lat: 49.0322599, lng: 18.4106372 },
    { lat: 49.0319816, lng: 18.4104395 },
    { lat: 49.0318752, lng: 18.4106363 },
    { lat: 49.0317869, lng: 18.4108327 },
    { lat: 49.0317022, lng: 18.4109564 },
    { lat: 49.0308129, lng: 18.4120495 },
    { lat: 49.0303243, lng: 18.4109663 },
    { lat: 49.029777, lng: 18.4115957 },
    { lat: 49.0289526, lng: 18.411394 },
    { lat: 49.0284694, lng: 18.4118152 },
    { lat: 49.0279462, lng: 18.4129431 },
    { lat: 49.027139, lng: 18.4138904 },
    { lat: 49.0263383, lng: 18.4147106 },
    { lat: 49.025386, lng: 18.4149517 },
    { lat: 49.0253049, lng: 18.4152171 },
    { lat: 49.0241529, lng: 18.4144588 },
    { lat: 49.0238623, lng: 18.4121 },
    { lat: 49.0234642, lng: 18.4099826 },
    { lat: 49.023069, lng: 18.4085898 },
    { lat: 49.022493, lng: 18.4079458 },
    { lat: 49.0223142, lng: 18.407852 },
    { lat: 49.0223578, lng: 18.4072128 },
    { lat: 49.0215861, lng: 18.4062136 },
    { lat: 49.0208833, lng: 18.4052552 },
    { lat: 49.0208962, lng: 18.4049404 },
    { lat: 49.020697, lng: 18.40447 },
    { lat: 49.020199, lng: 18.404227 },
    { lat: 49.019326, lng: 18.402403 },
    { lat: 49.018185, lng: 18.401796 },
    { lat: 49.0176819, lng: 18.40182 },
    { lat: 49.014828, lng: 18.401956 },
    { lat: 49.013326, lng: 18.401311 },
    { lat: 49.011827, lng: 18.402997 },
    { lat: 49.010837, lng: 18.402354 },
    { lat: 49.009372, lng: 18.40072 },
    { lat: 49.00891, lng: 18.39947 },
  ],
  Domaniža: [
    { lat: 49.0300652, lng: 18.5855916 },
    { lat: 49.0304795, lng: 18.5855781 },
    { lat: 49.0310172, lng: 18.5852179 },
    { lat: 49.031561, lng: 18.584633 },
    { lat: 49.0319686, lng: 18.5847457 },
    { lat: 49.032442, lng: 18.5847631 },
    { lat: 49.0328034, lng: 18.585023 },
    { lat: 49.0330618, lng: 18.5851285 },
    { lat: 49.0331888, lng: 18.5851828 },
    { lat: 49.033642, lng: 18.5855153 },
    { lat: 49.0341215, lng: 18.5854551 },
    { lat: 49.0349933, lng: 18.5852235 },
    { lat: 49.0353488, lng: 18.5851879 },
    { lat: 49.0359936, lng: 18.5858097 },
    { lat: 49.0361344, lng: 18.5865183 },
    { lat: 49.0362298, lng: 18.586888 },
    { lat: 49.0363733, lng: 18.5871658 },
    { lat: 49.0365517, lng: 18.5873999 },
    { lat: 49.037123, lng: 18.587978 },
    { lat: 49.0374101, lng: 18.5880322 },
    { lat: 49.0377816, lng: 18.5883731 },
    { lat: 49.0380493, lng: 18.5877535 },
    { lat: 49.0381858, lng: 18.5876499 },
    { lat: 49.0389535, lng: 18.5879365 },
    { lat: 49.039087, lng: 18.5882147 },
    { lat: 49.0392891, lng: 18.5884288 },
    { lat: 49.0397343, lng: 18.588384 },
    { lat: 49.0400621, lng: 18.5884579 },
    { lat: 49.0404291, lng: 18.5880463 },
    { lat: 49.0407702, lng: 18.588015 },
    { lat: 49.0411617, lng: 18.5882478 },
    { lat: 49.042099, lng: 18.5891292 },
    { lat: 49.0423199, lng: 18.5892527 },
    { lat: 49.042746, lng: 18.5891224 },
    { lat: 49.0428565, lng: 18.5890177 },
    { lat: 49.0428923, lng: 18.5889486 },
    { lat: 49.0429432, lng: 18.5884472 },
    { lat: 49.0429489, lng: 18.5878835 },
    { lat: 49.0431485, lng: 18.5871912 },
    { lat: 49.0431489, lng: 18.5871135 },
    { lat: 49.0431353, lng: 18.5870701 },
    { lat: 49.0433231, lng: 18.5868718 },
    { lat: 49.0435011, lng: 18.5865529 },
    { lat: 49.0435478, lng: 18.5864793 },
    { lat: 49.0436908, lng: 18.5862647 },
    { lat: 49.0441863, lng: 18.5853696 },
    { lat: 49.0445047, lng: 18.5849139 },
    { lat: 49.0448006, lng: 18.5846904 },
    { lat: 49.0448447, lng: 18.5847347 },
    { lat: 49.0448483, lng: 18.5847197 },
    { lat: 49.0449126, lng: 18.5844518 },
    { lat: 49.0449645, lng: 18.584236 },
    { lat: 49.0450723, lng: 18.5837871 },
    { lat: 49.0453965, lng: 18.5833771 },
    { lat: 49.0454378, lng: 18.5833704 },
    { lat: 49.0460456, lng: 18.5832713 },
    { lat: 49.0464318, lng: 18.5832083 },
    { lat: 49.0470456, lng: 18.5833803 },
    { lat: 49.0473996, lng: 18.583419 },
    { lat: 49.0474604, lng: 18.5833542 },
    { lat: 49.0478167, lng: 18.5829543 },
    { lat: 49.0481451, lng: 18.5825184 },
    { lat: 49.0483247, lng: 18.5823556 },
    { lat: 49.0485151, lng: 18.5822892 },
    { lat: 49.0488236, lng: 18.5822579 },
    { lat: 49.0490273, lng: 18.5823124 },
    { lat: 49.0492759, lng: 18.5822591 },
    { lat: 49.0495775, lng: 18.582017 },
    { lat: 49.0496843, lng: 18.5818845 },
    { lat: 49.0498795, lng: 18.5814247 },
    { lat: 49.0502587, lng: 18.580653 },
    { lat: 49.0504791, lng: 18.5801789 },
    { lat: 49.0506826, lng: 18.5798751 },
    { lat: 49.0509318, lng: 18.5795373 },
    { lat: 49.0510897, lng: 18.5794551 },
    { lat: 49.0515498, lng: 18.5792647 },
    { lat: 49.0520623, lng: 18.5788972 },
    { lat: 49.0526094, lng: 18.578457 },
    { lat: 49.0530055, lng: 18.578189 },
    { lat: 49.0534623, lng: 18.5777716 },
    { lat: 49.0542399, lng: 18.577006 },
    { lat: 49.054792, lng: 18.5765528 },
    { lat: 49.0554401, lng: 18.5761068 },
    { lat: 49.0563123, lng: 18.575274 },
    { lat: 49.0567988, lng: 18.5752831 },
    { lat: 49.056891, lng: 18.5742111 },
    { lat: 49.0568448, lng: 18.5727877 },
    { lat: 49.0568606, lng: 18.5715098 },
    { lat: 49.0568636, lng: 18.5711687 },
    { lat: 49.0569486, lng: 18.5710706 },
    { lat: 49.0570791, lng: 18.5709183 },
    { lat: 49.0571755, lng: 18.570009 },
    { lat: 49.0573823, lng: 18.5698696 },
    { lat: 49.0575728, lng: 18.5697399 },
    { lat: 49.0576538, lng: 18.5696543 },
    { lat: 49.0581546, lng: 18.5687325 },
    { lat: 49.0582375, lng: 18.5684312 },
    { lat: 49.0581842, lng: 18.5682807 },
    { lat: 49.0581956, lng: 18.5678412 },
    { lat: 49.0582149, lng: 18.5677802 },
    { lat: 49.0582424, lng: 18.5676645 },
    { lat: 49.0582517, lng: 18.5675853 },
    { lat: 49.0582765, lng: 18.5675445 },
    { lat: 49.0582904, lng: 18.5675089 },
    { lat: 49.0583142, lng: 18.5674484 },
    { lat: 49.0584855, lng: 18.5670073 },
    { lat: 49.0587235, lng: 18.5667547 },
    { lat: 49.0587232, lng: 18.5661642 },
    { lat: 49.0585332, lng: 18.5656096 },
    { lat: 49.0581672, lng: 18.564179 },
    { lat: 49.0579647, lng: 18.5638546 },
    { lat: 49.0576196, lng: 18.5635627 },
    { lat: 49.0572463, lng: 18.5631438 },
    { lat: 49.0576699, lng: 18.5619865 },
    { lat: 49.0578243, lng: 18.5607518 },
    { lat: 49.0578412, lng: 18.5606882 },
    { lat: 49.0582429, lng: 18.5591453 },
    { lat: 49.058245, lng: 18.5590169 },
    { lat: 49.0582184, lng: 18.5589218 },
    { lat: 49.0582566, lng: 18.5587507 },
    { lat: 49.0583498, lng: 18.5582207 },
    { lat: 49.0584473, lng: 18.5578969 },
    { lat: 49.0585096, lng: 18.5578184 },
    { lat: 49.0585473, lng: 18.557661 },
    { lat: 49.0585787, lng: 18.5574481 },
    { lat: 49.0586487, lng: 18.5568237 },
    { lat: 49.058829, lng: 18.5561721 },
    { lat: 49.0587803, lng: 18.5561468 },
    { lat: 49.0587439, lng: 18.5558922 },
    { lat: 49.0585997, lng: 18.5556858 },
    { lat: 49.0585152, lng: 18.5554367 },
    { lat: 49.0584052, lng: 18.5552167 },
    { lat: 49.0582794, lng: 18.5550188 },
    { lat: 49.0581379, lng: 18.5548431 },
    { lat: 49.0579958, lng: 18.5546968 },
    { lat: 49.0578533, lng: 18.5545422 },
    { lat: 49.0577381, lng: 18.5544151 },
    { lat: 49.0576627, lng: 18.554146 },
    { lat: 49.0575758, lng: 18.5537476 },
    { lat: 49.0580547, lng: 18.5524892 },
    { lat: 49.0586279, lng: 18.5513238 },
    { lat: 49.0586935, lng: 18.5507844 },
    { lat: 49.059118, lng: 18.5499793 },
    { lat: 49.059382, lng: 18.549542 },
    { lat: 49.0595601, lng: 18.5491647 },
    { lat: 49.0599413, lng: 18.5484977 },
    { lat: 49.0599723, lng: 18.5484447 },
    { lat: 49.0601037, lng: 18.5482252 },
    { lat: 49.0602707, lng: 18.5479773 },
    { lat: 49.0603363, lng: 18.5478353 },
    { lat: 49.060538, lng: 18.5471638 },
    { lat: 49.0606996, lng: 18.5464803 },
    { lat: 49.0605951, lng: 18.5461338 },
    { lat: 49.0607079, lng: 18.5459046 },
    { lat: 49.0607739, lng: 18.5456235 },
    { lat: 49.0615609, lng: 18.5456196 },
    { lat: 49.0619537, lng: 18.5454379 },
    { lat: 49.0619071, lng: 18.5450357 },
    { lat: 49.062348, lng: 18.5448028 },
    { lat: 49.0624289, lng: 18.544757 },
    { lat: 49.0624866, lng: 18.545094 },
    { lat: 49.0625021, lng: 18.5453165 },
    { lat: 49.0625004, lng: 18.5454102 },
    { lat: 49.0625033, lng: 18.5454634 },
    { lat: 49.0625159, lng: 18.5455931 },
    { lat: 49.0625021, lng: 18.545733 },
    { lat: 49.0624788, lng: 18.5458442 },
    { lat: 49.0624892, lng: 18.5460144 },
    { lat: 49.0625013, lng: 18.546155 },
    { lat: 49.0625637, lng: 18.5464356 },
    { lat: 49.0626545, lng: 18.5465911 },
    { lat: 49.0626543, lng: 18.5468163 },
    { lat: 49.0626792, lng: 18.5470268 },
    { lat: 49.0626362, lng: 18.5472318 },
    { lat: 49.0626421, lng: 18.547383 },
    { lat: 49.0626582, lng: 18.5475754 },
    { lat: 49.0627002, lng: 18.5476649 },
    { lat: 49.0627217, lng: 18.5477095 },
    { lat: 49.0627502, lng: 18.547756 },
    { lat: 49.0627621, lng: 18.5477464 },
    { lat: 49.0627946, lng: 18.5477191 },
    { lat: 49.063234, lng: 18.5465638 },
    { lat: 49.0636362, lng: 18.5454506 },
    { lat: 49.0638802, lng: 18.5448138 },
    { lat: 49.0641271, lng: 18.5442061 },
    { lat: 49.0646028, lng: 18.5438474 },
    { lat: 49.0652796, lng: 18.5428056 },
    { lat: 49.0655607, lng: 18.5423729 },
    { lat: 49.0659084, lng: 18.5417946 },
    { lat: 49.0663902, lng: 18.5411536 },
    { lat: 49.0664222, lng: 18.5396815 },
    { lat: 49.0665542, lng: 18.5384754 },
    { lat: 49.0663918, lng: 18.5369763 },
    { lat: 49.0664628, lng: 18.5360382 },
    { lat: 49.0665624, lng: 18.5350031 },
    { lat: 49.0666465, lng: 18.5339133 },
    { lat: 49.0666467, lng: 18.5338755 },
    { lat: 49.0666471, lng: 18.5337578 },
    { lat: 49.0666473, lng: 18.5337205 },
    { lat: 49.0666473, lng: 18.5336162 },
    { lat: 49.066647, lng: 18.5335472 },
    { lat: 49.0666473, lng: 18.5334502 },
    { lat: 49.0666356, lng: 18.5333585 },
    { lat: 49.0666293, lng: 18.5333064 },
    { lat: 49.0666042, lng: 18.5331117 },
    { lat: 49.0662485, lng: 18.5311279 },
    { lat: 49.0661449, lng: 18.5295296 },
    { lat: 49.0660676, lng: 18.5290821 },
    { lat: 49.0660904, lng: 18.5284012 },
    { lat: 49.0661236, lng: 18.5281194 },
    { lat: 49.0662311, lng: 18.5276674 },
    { lat: 49.0661443, lng: 18.5272538 },
    { lat: 49.0660964, lng: 18.5270559 },
    { lat: 49.0660164, lng: 18.5266014 },
    { lat: 49.0658319, lng: 18.5264011 },
    { lat: 49.0657649, lng: 18.5262266 },
    { lat: 49.0655892, lng: 18.5254212 },
    { lat: 49.0654809, lng: 18.5251089 },
    { lat: 49.0654662, lng: 18.5250446 },
    { lat: 49.0654442, lng: 18.5248486 },
    { lat: 49.0654239, lng: 18.5244535 },
    { lat: 49.0654164, lng: 18.524358 },
    { lat: 49.0653954, lng: 18.5242823 },
    { lat: 49.0653106, lng: 18.5237372 },
    { lat: 49.0650682, lng: 18.5231175 },
    { lat: 49.0649979, lng: 18.5229651 },
    { lat: 49.0649439, lng: 18.5228659 },
    { lat: 49.0647296, lng: 18.5224711 },
    { lat: 49.0645519, lng: 18.5221877 },
    { lat: 49.0642333, lng: 18.5216588 },
    { lat: 49.0641607, lng: 18.5215487 },
    { lat: 49.0640013, lng: 18.521374 },
    { lat: 49.0639072, lng: 18.5211775 },
    { lat: 49.0638412, lng: 18.5210448 },
    { lat: 49.0637733, lng: 18.5207692 },
    { lat: 49.0637563, lng: 18.5206011 },
    { lat: 49.0631418, lng: 18.520753 },
    { lat: 49.0620812, lng: 18.5210353 },
    { lat: 49.0619573, lng: 18.5210595 },
    { lat: 49.0609279, lng: 18.5213588 },
    { lat: 49.0609026, lng: 18.5213681 },
    { lat: 49.0608837, lng: 18.5213752 },
    { lat: 49.0608473, lng: 18.521388 },
    { lat: 49.0608306, lng: 18.5213939 },
    { lat: 49.0607322, lng: 18.5214291 },
    { lat: 49.0607797, lng: 18.5211433 },
    { lat: 49.0604665, lng: 18.5201738 },
    { lat: 49.0604159, lng: 18.5197821 },
    { lat: 49.0601956, lng: 18.519007 },
    { lat: 49.0600556, lng: 18.5187545 },
    { lat: 49.0597868, lng: 18.5184375 },
    { lat: 49.0597194, lng: 18.5183588 },
    { lat: 49.0596131, lng: 18.5180839 },
    { lat: 49.0595763, lng: 18.5176557 },
    { lat: 49.0595771, lng: 18.5176293 },
    { lat: 49.0595789, lng: 18.5175388 },
    { lat: 49.0595866, lng: 18.5171827 },
    { lat: 49.0595899, lng: 18.5170343 },
    { lat: 49.0595909, lng: 18.5169701 },
    { lat: 49.0594688, lng: 18.5156375 },
    { lat: 49.0595175, lng: 18.5155574 },
    { lat: 49.0593811, lng: 18.5152901 },
    { lat: 49.0593388, lng: 18.5145754 },
    { lat: 49.0594808, lng: 18.5140117 },
    { lat: 49.0595584, lng: 18.5138218 },
    { lat: 49.0596513, lng: 18.5134572 },
    { lat: 49.0595925, lng: 18.5131838 },
    { lat: 49.0597752, lng: 18.5128705 },
    { lat: 49.0598677, lng: 18.5123947 },
    { lat: 49.0597777, lng: 18.5123365 },
    { lat: 49.0588326, lng: 18.5118412 },
    { lat: 49.0586861, lng: 18.5118248 },
    { lat: 49.0583958, lng: 18.5116911 },
    { lat: 49.0581847, lng: 18.5117103 },
    { lat: 49.0579219, lng: 18.5117304 },
    { lat: 49.0577754, lng: 18.5117937 },
    { lat: 49.0574758, lng: 18.5118661 },
    { lat: 49.057379, lng: 18.5118479 },
    { lat: 49.056893, lng: 18.5119318 },
    { lat: 49.0564253, lng: 18.5120238 },
    { lat: 49.0561396, lng: 18.5121416 },
    { lat: 49.0560464, lng: 18.5121492 },
    { lat: 49.055816, lng: 18.512164 },
    { lat: 49.0555691, lng: 18.5123329 },
    { lat: 49.0555032, lng: 18.5123433 },
    { lat: 49.0553693, lng: 18.5123498 },
    { lat: 49.0551558, lng: 18.5124051 },
    { lat: 49.0550554, lng: 18.512365 },
    { lat: 49.0548987, lng: 18.5123873 },
    { lat: 49.0547691, lng: 18.5124092 },
    { lat: 49.0546195, lng: 18.5124572 },
    { lat: 49.0544185, lng: 18.512558 },
    { lat: 49.0542612, lng: 18.5125285 },
    { lat: 49.054178, lng: 18.5124923 },
    { lat: 49.0540536, lng: 18.5123831 },
    { lat: 49.0539326, lng: 18.5121905 },
    { lat: 49.053803, lng: 18.5120482 },
    { lat: 49.0535848, lng: 18.511829 },
    { lat: 49.0533323, lng: 18.5115016 },
    { lat: 49.0531012, lng: 18.5112558 },
    { lat: 49.0528127, lng: 18.5109902 },
    { lat: 49.0523541, lng: 18.5109994 },
    { lat: 49.0520727, lng: 18.5110931 },
    { lat: 49.0518981, lng: 18.5110972 },
    { lat: 49.0517818, lng: 18.5111578 },
    { lat: 49.0513997, lng: 18.5115568 },
    { lat: 49.0512507, lng: 18.5116369 },
    { lat: 49.0511012, lng: 18.511687 },
    { lat: 49.0509098, lng: 18.5118193 },
    { lat: 49.050838, lng: 18.5118668 },
    { lat: 49.0504427, lng: 18.5120644 },
    { lat: 49.0499759, lng: 18.5120883 },
    { lat: 49.0497336, lng: 18.5121117 },
    { lat: 49.0494582, lng: 18.5122129 },
    { lat: 49.049391, lng: 18.5122023 },
    { lat: 49.0491511, lng: 18.512167 },
    { lat: 49.0490194, lng: 18.5121088 },
    { lat: 49.0489502, lng: 18.512124 },
    { lat: 49.0483774, lng: 18.5122501 },
    { lat: 49.0480789, lng: 18.5122568 },
    { lat: 49.0479221, lng: 18.5123405 },
    { lat: 49.0477797, lng: 18.5123955 },
    { lat: 49.0476845, lng: 18.5123881 },
    { lat: 49.0475739, lng: 18.5123896 },
    { lat: 49.0470862, lng: 18.5124005 },
    { lat: 49.0469261, lng: 18.5124223 },
    { lat: 49.0467805, lng: 18.512462 },
    { lat: 49.0465489, lng: 18.5124779 },
    { lat: 49.046353, lng: 18.5125236 },
    { lat: 49.0461764, lng: 18.5125755 },
    { lat: 49.0456692, lng: 18.5126235 },
    { lat: 49.0453192, lng: 18.5120979 },
    { lat: 49.0448836, lng: 18.5112867 },
    { lat: 49.0447707, lng: 18.5112014 },
    { lat: 49.0440375, lng: 18.5106631 },
    { lat: 49.0440212, lng: 18.5106527 },
    { lat: 49.0438067, lng: 18.5105871 },
    { lat: 49.0429888, lng: 18.5105438 },
    { lat: 49.0424935, lng: 18.5102729 },
    { lat: 49.0420029, lng: 18.5099826 },
    { lat: 49.0414226, lng: 18.5094551 },
    { lat: 49.0411429, lng: 18.5094116 },
    { lat: 49.0405791, lng: 18.5096451 },
    { lat: 49.0401369, lng: 18.5096442 },
    { lat: 49.0397897, lng: 18.5100624 },
    { lat: 49.0394676, lng: 18.5105284 },
    { lat: 49.0378676, lng: 18.5110957 },
    { lat: 49.0373461, lng: 18.511545 },
    { lat: 49.0356963, lng: 18.5116553 },
    { lat: 49.0343877, lng: 18.5118604 },
    { lat: 49.0343497, lng: 18.5118664 },
    { lat: 49.0342725, lng: 18.5118788 },
    { lat: 49.0333879, lng: 18.5125355 },
    { lat: 49.0325867, lng: 18.5127964 },
    { lat: 49.0323023, lng: 18.5128983 },
    { lat: 49.0313749, lng: 18.5133076 },
    { lat: 49.0308922, lng: 18.5135798 },
    { lat: 49.0302095, lng: 18.514423 },
    { lat: 49.0297498, lng: 18.5149741 },
    { lat: 49.0294029, lng: 18.5154365 },
    { lat: 49.0286287, lng: 18.5165495 },
    { lat: 49.0284753, lng: 18.5167138 },
    { lat: 49.0279754, lng: 18.5171471 },
    { lat: 49.0271271, lng: 18.517581 },
    { lat: 49.0266438, lng: 18.5178399 },
    { lat: 49.0264939, lng: 18.5179055 },
    { lat: 49.0261659, lng: 18.5180321 },
    { lat: 49.0257365, lng: 18.5182045 },
    { lat: 49.0256692, lng: 18.5182317 },
    { lat: 49.0249011, lng: 18.5185444 },
    { lat: 49.0241702, lng: 18.5188563 },
    { lat: 49.0241536, lng: 18.5188618 },
    { lat: 49.0241772, lng: 18.5190677 },
    { lat: 49.0241588, lng: 18.5197654 },
    { lat: 49.0241917, lng: 18.5205983 },
    { lat: 49.0241539, lng: 18.5212728 },
    { lat: 49.0242585, lng: 18.522119 },
    { lat: 49.0248419, lng: 18.5233685 },
    { lat: 49.0257307, lng: 18.5249658 },
    { lat: 49.0258274, lng: 18.5252308 },
    { lat: 49.0260072, lng: 18.5259247 },
    { lat: 49.026181, lng: 18.5264864 },
    { lat: 49.0263509, lng: 18.527062 },
    { lat: 49.0263999, lng: 18.5273188 },
    { lat: 49.0264991, lng: 18.5278384 },
    { lat: 49.0266213, lng: 18.528883 },
    { lat: 49.0266396, lng: 18.5288863 },
    { lat: 49.0266083, lng: 18.5304089 },
    { lat: 49.0266015, lng: 18.5305947 },
    { lat: 49.0266144, lng: 18.5311239 },
    { lat: 49.0265837, lng: 18.531121 },
    { lat: 49.0265866, lng: 18.5311742 },
    { lat: 49.0266552, lng: 18.532416 },
    { lat: 49.0264921, lng: 18.5331932 },
    { lat: 49.0260995, lng: 18.533857 },
    { lat: 49.0259894, lng: 18.5341156 },
    { lat: 49.0259358, lng: 18.5342708 },
    { lat: 49.0257877, lng: 18.535907 },
    { lat: 49.0255718, lng: 18.5368132 },
    { lat: 49.0254449, lng: 18.5375939 },
    { lat: 49.0252852, lng: 18.5386638 },
    { lat: 49.0252804, lng: 18.5388448 },
    { lat: 49.0253025, lng: 18.5390659 },
    { lat: 49.025291, lng: 18.5395605 },
    { lat: 49.0252961, lng: 18.5399685 },
    { lat: 49.0252883, lng: 18.5400938 },
    { lat: 49.0252952, lng: 18.5402421 },
    { lat: 49.0253128, lng: 18.5407535 },
    { lat: 49.0253424, lng: 18.5409054 },
    { lat: 49.0253767, lng: 18.5409981 },
    { lat: 49.0254287, lng: 18.5412479 },
    { lat: 49.0254267, lng: 18.5423975 },
    { lat: 49.0254501, lng: 18.5430994 },
    { lat: 49.0254556, lng: 18.5432654 },
    { lat: 49.0257233, lng: 18.5440415 },
    { lat: 49.0258557, lng: 18.544238 },
    { lat: 49.0258888, lng: 18.5442869 },
    { lat: 49.0260029, lng: 18.5444563 },
    { lat: 49.0264292, lng: 18.5451648 },
    { lat: 49.0266429, lng: 18.5456312 },
    { lat: 49.026703, lng: 18.5457845 },
    { lat: 49.0267532, lng: 18.5459822 },
    { lat: 49.0268097, lng: 18.5461692 },
    { lat: 49.027045, lng: 18.5464118 },
    { lat: 49.027036, lng: 18.546433 },
    { lat: 49.0267279, lng: 18.5469532 },
    { lat: 49.0261937, lng: 18.5477515 },
    { lat: 49.0258264, lng: 18.5482787 },
    { lat: 49.025256, lng: 18.5490348 },
    { lat: 49.0254473, lng: 18.5494231 },
    { lat: 49.0256409, lng: 18.5497083 },
    { lat: 49.0260629, lng: 18.5501083 },
    { lat: 49.0256004, lng: 18.5510018 },
    { lat: 49.0252624, lng: 18.551594 },
    { lat: 49.0251129, lng: 18.5517895 },
    { lat: 49.0248296, lng: 18.5519121 },
    { lat: 49.0241403, lng: 18.5521591 },
    { lat: 49.0242225, lng: 18.5525726 },
    { lat: 49.0245062, lng: 18.552564 },
    { lat: 49.0246028, lng: 18.552578 },
    { lat: 49.0247409, lng: 18.5530856 },
    { lat: 49.0249156, lng: 18.5532086 },
    { lat: 49.0252381, lng: 18.553312 },
    { lat: 49.025572, lng: 18.5533979 },
    { lat: 49.0255121, lng: 18.5535179 },
    { lat: 49.0254929, lng: 18.5536428 },
    { lat: 49.0255083, lng: 18.5540118 },
    { lat: 49.0254763, lng: 18.5540665 },
    { lat: 49.0253291, lng: 18.554222 },
    { lat: 49.0253874, lng: 18.554258 },
    { lat: 49.0257279, lng: 18.5543599 },
    { lat: 49.0258168, lng: 18.5544607 },
    { lat: 49.0258247, lng: 18.5546073 },
    { lat: 49.0257961, lng: 18.5546838 },
    { lat: 49.0257795, lng: 18.5550011 },
    { lat: 49.0257118, lng: 18.555273 },
    { lat: 49.0256902, lng: 18.5552682 },
    { lat: 49.0253702, lng: 18.5549836 },
    { lat: 49.0252006, lng: 18.5552471 },
    { lat: 49.0245888, lng: 18.5554041 },
    { lat: 49.0239801, lng: 18.5555995 },
    { lat: 49.0231151, lng: 18.5561856 },
    { lat: 49.0222657, lng: 18.5568286 },
    { lat: 49.0220731, lng: 18.5572098 },
    { lat: 49.0218622, lng: 18.5573781 },
    { lat: 49.0216455, lng: 18.5575843 },
    { lat: 49.0213761, lng: 18.5580451 },
    { lat: 49.0211967, lng: 18.5583985 },
    { lat: 49.0210432, lng: 18.5591901 },
    { lat: 49.0201036, lng: 18.5599205 },
    { lat: 49.01983, lng: 18.5601152 },
    { lat: 49.019581, lng: 18.560163 },
    { lat: 49.0193528, lng: 18.5602403 },
    { lat: 49.0187332, lng: 18.5602735 },
    { lat: 49.0175546, lng: 18.5601922 },
    { lat: 49.0169577, lng: 18.5601859 },
    { lat: 49.016411, lng: 18.5601945 },
    { lat: 49.0156227, lng: 18.559569 },
    { lat: 49.0150681, lng: 18.5588023 },
    { lat: 49.0145221, lng: 18.5583428 },
    { lat: 49.0142215, lng: 18.5579966 },
    { lat: 49.0136942, lng: 18.5572776 },
    { lat: 49.0133375, lng: 18.5574396 },
    { lat: 49.0128143, lng: 18.5572335 },
    { lat: 49.0123321, lng: 18.5570132 },
    { lat: 49.0119577, lng: 18.5565756 },
    { lat: 49.0119155, lng: 18.5565256 },
    { lat: 49.0110774, lng: 18.5566915 },
    { lat: 49.0106411, lng: 18.55732 },
    { lat: 49.010392, lng: 18.5576768 },
    { lat: 49.0095304, lng: 18.557932 },
    { lat: 49.0094782, lng: 18.5579475 },
    { lat: 49.0090298, lng: 18.5580804 },
    { lat: 49.0085876, lng: 18.5584137 },
    { lat: 49.0085564, lng: 18.5584618 },
    { lat: 49.0082654, lng: 18.5589014 },
    { lat: 49.0082332, lng: 18.5589495 },
    { lat: 49.0081204, lng: 18.5594317 },
    { lat: 49.0079275, lng: 18.5602454 },
    { lat: 49.0078268, lng: 18.5608014 },
    { lat: 49.0078245, lng: 18.5608017 },
    { lat: 49.0077372, lng: 18.5608555 },
    { lat: 49.0070192, lng: 18.560864 },
    { lat: 49.0067758, lng: 18.5608894 },
    { lat: 49.0066725, lng: 18.560876 },
    { lat: 49.0065363, lng: 18.5608408 },
    { lat: 49.0062875, lng: 18.5607236 },
    { lat: 49.0060484, lng: 18.5605773 },
    { lat: 49.005785, lng: 18.5604832 },
    { lat: 49.0056035, lng: 18.5604002 },
    { lat: 49.0054992, lng: 18.5603283 },
    { lat: 49.0054058, lng: 18.5602496 },
    { lat: 49.0053093, lng: 18.5601955 },
    { lat: 49.0049483, lng: 18.5601102 },
    { lat: 49.0047944, lng: 18.5600816 },
    { lat: 49.0046874, lng: 18.5601033 },
    { lat: 49.0043756, lng: 18.5601667 },
    { lat: 49.0041708, lng: 18.5602083 },
    { lat: 49.0040541, lng: 18.5602116 },
    { lat: 49.0036089, lng: 18.5602243 },
    { lat: 49.0031736, lng: 18.559977 },
    { lat: 49.0027135, lng: 18.5597061 },
    { lat: 49.0015873, lng: 18.5591752 },
    { lat: 49.0010625, lng: 18.5588959 },
    { lat: 49.000623, lng: 18.5587586 },
    { lat: 48.9998967, lng: 18.5584037 },
    { lat: 48.9996168, lng: 18.5581708 },
    { lat: 48.9991126, lng: 18.557732 },
    { lat: 48.9986368, lng: 18.5574017 },
    { lat: 48.9985548, lng: 18.5573451 },
    { lat: 48.998002, lng: 18.5571344 },
    { lat: 48.99801, lng: 18.5571154 },
    { lat: 48.9973459, lng: 18.5565795 },
    { lat: 48.9970252, lng: 18.5563638 },
    { lat: 48.9967007, lng: 18.5562028 },
    { lat: 48.9960938, lng: 18.5557413 },
    { lat: 48.9955171, lng: 18.5553604 },
    { lat: 48.9950849, lng: 18.5552557 },
    { lat: 48.9950389, lng: 18.5552446 },
    { lat: 48.9950006, lng: 18.5552353 },
    { lat: 48.994364, lng: 18.555259 },
    { lat: 48.994433, lng: 18.556026 },
    { lat: 48.994439, lng: 18.556569 },
    { lat: 48.99445, lng: 18.557385 },
    { lat: 48.994499, lng: 18.558467 },
    { lat: 48.994515, lng: 18.558855 },
    { lat: 48.994526, lng: 18.559162 },
    { lat: 48.994538, lng: 18.559515 },
    { lat: 48.994593, lng: 18.560061 },
    { lat: 48.994652, lng: 18.56062 },
    { lat: 48.994871, lng: 18.560967 },
    { lat: 48.995009, lng: 18.561188 },
    { lat: 48.995195, lng: 18.561593 },
    { lat: 48.995337, lng: 18.561913 },
    { lat: 48.995438, lng: 18.562121 },
    { lat: 48.995553, lng: 18.562373 },
    { lat: 48.995637, lng: 18.562535 },
    { lat: 48.995817, lng: 18.563003 },
    { lat: 48.995918, lng: 18.563299 },
    { lat: 48.995991, lng: 18.563519 },
    { lat: 48.996123, lng: 18.56392 },
    { lat: 48.996337, lng: 18.564679 },
    { lat: 48.99645, lng: 18.565086 },
    { lat: 48.996948, lng: 18.565832 },
    { lat: 48.997055, lng: 18.566124 },
    { lat: 48.997244, lng: 18.566639 },
    { lat: 48.997587, lng: 18.567547 },
    { lat: 48.997673, lng: 18.567805 },
    { lat: 48.997886, lng: 18.568463 },
    { lat: 48.998001, lng: 18.569107 },
    { lat: 48.998098, lng: 18.569639 },
    { lat: 48.998654, lng: 18.570077 },
    { lat: 48.998908, lng: 18.570239 },
    { lat: 48.999082, lng: 18.570544 },
    { lat: 48.999425, lng: 18.570668 },
    { lat: 49.000156, lng: 18.571168 },
    { lat: 49.000496, lng: 18.571524 },
    { lat: 49.001467, lng: 18.57311 },
    { lat: 49.00169, lng: 18.573349 },
    { lat: 49.001751, lng: 18.573468 },
    { lat: 49.001835, lng: 18.573566 },
    { lat: 49.002568, lng: 18.574594 },
    { lat: 49.003136, lng: 18.5751 },
    { lat: 49.00375, lng: 18.575681 },
    { lat: 49.003969, lng: 18.575876 },
    { lat: 49.004115, lng: 18.57594 },
    { lat: 49.004337, lng: 18.576123 },
    { lat: 49.004948, lng: 18.576256 },
    { lat: 49.005352, lng: 18.576403 },
    { lat: 49.005461, lng: 18.576604 },
    { lat: 49.005712, lng: 18.576965 },
    { lat: 49.005816, lng: 18.577143 },
    { lat: 49.006086, lng: 18.577266 },
    { lat: 49.006212, lng: 18.577342 },
    { lat: 49.006296, lng: 18.577347 },
    { lat: 49.006388, lng: 18.5775 },
    { lat: 49.006925, lng: 18.577959 },
    { lat: 49.007106, lng: 18.578186 },
    { lat: 49.007788, lng: 18.578871 },
    { lat: 49.007884, lng: 18.578972 },
    { lat: 49.007992, lng: 18.579146 },
    { lat: 49.008233, lng: 18.579627 },
    { lat: 49.008367, lng: 18.579832 },
    { lat: 49.008457, lng: 18.579982 },
    { lat: 49.008618, lng: 18.580371 },
    { lat: 49.008705, lng: 18.580594 },
    { lat: 49.00922, lng: 18.581324 },
    { lat: 49.009623, lng: 18.581781 },
    { lat: 49.009797, lng: 18.582056 },
    { lat: 49.009884, lng: 18.582313 },
    { lat: 49.009998, lng: 18.582557 },
    { lat: 49.01015, lng: 18.582832 },
    { lat: 49.010244, lng: 18.582948 },
    { lat: 49.010305, lng: 18.583092 },
    { lat: 49.01038, lng: 18.583191 },
    { lat: 49.010654, lng: 18.583659 },
    { lat: 49.010924, lng: 18.584147 },
    { lat: 49.011124, lng: 18.584413 },
    { lat: 49.011269, lng: 18.58453 },
    { lat: 49.011556, lng: 18.584605 },
    { lat: 49.011762, lng: 18.584571 },
    { lat: 49.011896, lng: 18.584657 },
    { lat: 49.011978, lng: 18.584648 },
    { lat: 49.012196, lng: 18.585033 },
    { lat: 49.012281, lng: 18.585139 },
    { lat: 49.012424, lng: 18.585359 },
    { lat: 49.012621, lng: 18.585522 },
    { lat: 49.012919, lng: 18.585699 },
    { lat: 49.013114, lng: 18.5858119 },
    { lat: 49.013453, lng: 18.586008 },
    { lat: 49.013549, lng: 18.586019 },
    { lat: 49.013668, lng: 18.586089 },
    { lat: 49.013758, lng: 18.586212 },
    { lat: 49.014013, lng: 18.586771 },
    { lat: 49.014231, lng: 18.587131 },
    { lat: 49.014482, lng: 18.58731 },
    { lat: 49.014675, lng: 18.587111 },
    { lat: 49.014818, lng: 18.587013 },
    { lat: 49.015032, lng: 18.586883 },
    { lat: 49.015212, lng: 18.586836 },
    { lat: 49.015507, lng: 18.586849 },
    { lat: 49.015666, lng: 18.586991 },
    { lat: 49.0156943, lng: 18.5870032 },
    { lat: 49.0158555, lng: 18.5871331 },
    { lat: 49.0158745, lng: 18.5871421 },
    { lat: 49.015908, lng: 18.5871394 },
    { lat: 49.0159929, lng: 18.587134 },
    { lat: 49.0161365, lng: 18.5871233 },
    { lat: 49.016204, lng: 18.5870821 },
    { lat: 49.0164322, lng: 18.5869516 },
    { lat: 49.016487, lng: 18.58692 },
    { lat: 49.016678, lng: 18.586787 },
    { lat: 49.016789, lng: 18.5867208 },
    { lat: 49.016886, lng: 18.586663 },
    { lat: 49.017279, lng: 18.586565 },
    { lat: 49.017403, lng: 18.586521 },
    { lat: 49.01774, lng: 18.586404 },
    { lat: 49.018065, lng: 18.586322 },
    { lat: 49.0181204, lng: 18.5863145 },
    { lat: 49.01836, lng: 18.586282 },
    { lat: 49.018612, lng: 18.586367 },
    { lat: 49.018854, lng: 18.586945 },
    { lat: 49.019274, lng: 18.587633 },
    { lat: 49.01962, lng: 18.588247 },
    { lat: 49.020263, lng: 18.589339 },
    { lat: 49.0211848, lng: 18.5888908 },
    { lat: 49.0216232, lng: 18.5885082 },
    { lat: 49.0220919, lng: 18.5880153 },
    { lat: 49.022446, lng: 18.587868 },
    { lat: 49.0228174, lng: 18.5877686 },
    { lat: 49.0234251, lng: 18.5878718 },
    { lat: 49.0239299, lng: 18.5881118 },
    { lat: 49.0243101, lng: 18.5880104 },
    { lat: 49.0249167, lng: 18.5879021 },
    { lat: 49.0254267, lng: 18.5880315 },
    { lat: 49.0257648, lng: 18.5885515 },
    { lat: 49.0259523, lng: 18.58862 },
    { lat: 49.0262763, lng: 18.5889194 },
    { lat: 49.0268904, lng: 18.5887825 },
    { lat: 49.0271578, lng: 18.5888062 },
    { lat: 49.0272983, lng: 18.5886913 },
    { lat: 49.0273774, lng: 18.5886718 },
    { lat: 49.0274717, lng: 18.5885799 },
    { lat: 49.0276615, lng: 18.5885459 },
    { lat: 49.0278064, lng: 18.5884078 },
    { lat: 49.0278895, lng: 18.5883177 },
    { lat: 49.0279862, lng: 18.588145 },
    { lat: 49.0281036, lng: 18.5879795 },
    { lat: 49.0281672, lng: 18.5878609 },
    { lat: 49.0282124, lng: 18.5877373 },
    { lat: 49.028259, lng: 18.587575 },
    { lat: 49.0282994, lng: 18.5874667 },
    { lat: 49.0283864, lng: 18.587325 },
    { lat: 49.0285181, lng: 18.5871493 },
    { lat: 49.0285081, lng: 18.5871303 },
    { lat: 49.0284916, lng: 18.5870988 },
    { lat: 49.0286005, lng: 18.5864195 },
    { lat: 49.0291805, lng: 18.5860228 },
    { lat: 49.0300652, lng: 18.5855916 },
  ],
  Prečín: [
    { lat: 49.0465437, lng: 18.4934004 },
    { lat: 49.0466087, lng: 18.4935403 },
    { lat: 49.0469616, lng: 18.4943005 },
    { lat: 49.0469556, lng: 18.4952243 },
    { lat: 49.0466597, lng: 18.4967465 },
    { lat: 49.0465362, lng: 18.4974798 },
    { lat: 49.0464998, lng: 18.4992132 },
    { lat: 49.0464454, lng: 18.5002012 },
    { lat: 49.0462802, lng: 18.5015166 },
    { lat: 49.0452083, lng: 18.5028963 },
    { lat: 49.0445778, lng: 18.5050704 },
    { lat: 49.0444332, lng: 18.507013 },
    { lat: 49.0446324, lng: 18.5083103 },
    { lat: 49.044538, lng: 18.5091244 },
    { lat: 49.0445024, lng: 18.5094845 },
    { lat: 49.044445, lng: 18.5103165 },
    { lat: 49.0443067, lng: 18.5105935 },
    { lat: 49.0440375, lng: 18.5106631 },
    { lat: 49.0447707, lng: 18.5112014 },
    { lat: 49.0448836, lng: 18.5112867 },
    { lat: 49.0453192, lng: 18.5120979 },
    { lat: 49.0456692, lng: 18.5126235 },
    { lat: 49.0461764, lng: 18.5125755 },
    { lat: 49.046353, lng: 18.5125236 },
    { lat: 49.0465489, lng: 18.5124779 },
    { lat: 49.0467805, lng: 18.512462 },
    { lat: 49.0469261, lng: 18.5124223 },
    { lat: 49.0470862, lng: 18.5124005 },
    { lat: 49.0475739, lng: 18.5123896 },
    { lat: 49.0476845, lng: 18.5123881 },
    { lat: 49.0477797, lng: 18.5123955 },
    { lat: 49.0479221, lng: 18.5123405 },
    { lat: 49.0480789, lng: 18.5122568 },
    { lat: 49.0483774, lng: 18.5122501 },
    { lat: 49.0489502, lng: 18.512124 },
    { lat: 49.0490194, lng: 18.5121088 },
    { lat: 49.0491511, lng: 18.512167 },
    { lat: 49.049391, lng: 18.5122023 },
    { lat: 49.0494582, lng: 18.5122129 },
    { lat: 49.0497336, lng: 18.5121117 },
    { lat: 49.0499759, lng: 18.5120883 },
    { lat: 49.0504427, lng: 18.5120644 },
    { lat: 49.050838, lng: 18.5118668 },
    { lat: 49.0509098, lng: 18.5118193 },
    { lat: 49.0511012, lng: 18.511687 },
    { lat: 49.0512507, lng: 18.5116369 },
    { lat: 49.0513997, lng: 18.5115568 },
    { lat: 49.0517818, lng: 18.5111578 },
    { lat: 49.0518981, lng: 18.5110972 },
    { lat: 49.0520727, lng: 18.5110931 },
    { lat: 49.0523541, lng: 18.5109994 },
    { lat: 49.0528127, lng: 18.5109902 },
    { lat: 49.0531012, lng: 18.5112558 },
    { lat: 49.0533323, lng: 18.5115016 },
    { lat: 49.0535848, lng: 18.511829 },
    { lat: 49.053803, lng: 18.5120482 },
    { lat: 49.0539326, lng: 18.5121905 },
    { lat: 49.0540536, lng: 18.5123831 },
    { lat: 49.054178, lng: 18.5124923 },
    { lat: 49.0542612, lng: 18.5125285 },
    { lat: 49.0544185, lng: 18.512558 },
    { lat: 49.0546195, lng: 18.5124572 },
    { lat: 49.0547691, lng: 18.5124092 },
    { lat: 49.0548987, lng: 18.5123873 },
    { lat: 49.0550554, lng: 18.512365 },
    { lat: 49.0551558, lng: 18.5124051 },
    { lat: 49.0553693, lng: 18.5123498 },
    { lat: 49.0555032, lng: 18.5123433 },
    { lat: 49.0555691, lng: 18.5123329 },
    { lat: 49.055816, lng: 18.512164 },
    { lat: 49.0560464, lng: 18.5121492 },
    { lat: 49.0561396, lng: 18.5121416 },
    { lat: 49.0564253, lng: 18.5120238 },
    { lat: 49.056893, lng: 18.5119318 },
    { lat: 49.057379, lng: 18.5118479 },
    { lat: 49.0574758, lng: 18.5118661 },
    { lat: 49.0577754, lng: 18.5117937 },
    { lat: 49.0579219, lng: 18.5117304 },
    { lat: 49.0581847, lng: 18.5117103 },
    { lat: 49.0583958, lng: 18.5116911 },
    { lat: 49.0586861, lng: 18.5118248 },
    { lat: 49.0588326, lng: 18.5118412 },
    { lat: 49.0597777, lng: 18.5123365 },
    { lat: 49.0598677, lng: 18.5123947 },
    { lat: 49.0598915, lng: 18.5123561 },
    { lat: 49.0599421, lng: 18.5122492 },
    { lat: 49.0599655, lng: 18.5121819 },
    { lat: 49.0600421, lng: 18.5119935 },
    { lat: 49.0600935, lng: 18.5119633 },
    { lat: 49.0600925, lng: 18.5118627 },
    { lat: 49.0600921, lng: 18.5117919 },
    { lat: 49.0600802, lng: 18.5117603 },
    { lat: 49.0600777, lng: 18.5117139 },
    { lat: 49.0601104, lng: 18.5116317 },
    { lat: 49.0601081, lng: 18.5115482 },
    { lat: 49.0601731, lng: 18.5114157 },
    { lat: 49.0602229, lng: 18.5113034 },
    { lat: 49.0602728, lng: 18.5111757 },
    { lat: 49.0602681, lng: 18.5111402 },
    { lat: 49.0602634, lng: 18.5111047 },
    { lat: 49.0602565, lng: 18.5109804 },
    { lat: 49.0602562, lng: 18.5109533 },
    { lat: 49.0602907, lng: 18.5107988 },
    { lat: 49.060295, lng: 18.5107738 },
    { lat: 49.0603108, lng: 18.5107529 },
    { lat: 49.0603298, lng: 18.5107311 },
    { lat: 49.0603245, lng: 18.5107005 },
    { lat: 49.0603053, lng: 18.5106326 },
    { lat: 49.0603499, lng: 18.5104365 },
    { lat: 49.0603483, lng: 18.5103019 },
    { lat: 49.0603246, lng: 18.5102209 },
    { lat: 49.0603526, lng: 18.5099258 },
    { lat: 49.0604041, lng: 18.5098769 },
    { lat: 49.0604257, lng: 18.5097769 },
    { lat: 49.0604598, lng: 18.5096785 },
    { lat: 49.0605131, lng: 18.5095798 },
    { lat: 49.0605848, lng: 18.5094479 },
    { lat: 49.0606474, lng: 18.5092701 },
    { lat: 49.0607395, lng: 18.509161 },
    { lat: 49.0607587, lng: 18.5090981 },
    { lat: 49.0608647, lng: 18.5088681 },
    { lat: 49.0609128, lng: 18.5087381 },
    { lat: 49.0609366, lng: 18.5087186 },
    { lat: 49.0609628, lng: 18.5086617 },
    { lat: 49.0610767, lng: 18.5084946 },
    { lat: 49.061082, lng: 18.5084896 },
    { lat: 49.0611643, lng: 18.5084068 },
    { lat: 49.0612161, lng: 18.5083219 },
    { lat: 49.0613463, lng: 18.5082666 },
    { lat: 49.0614598, lng: 18.5081768 },
    { lat: 49.0615942, lng: 18.5080767 },
    { lat: 49.061647, lng: 18.5079893 },
    { lat: 49.0616895, lng: 18.5078994 },
    { lat: 49.0617232, lng: 18.507876 },
    { lat: 49.0617891, lng: 18.5076364 },
    { lat: 49.0618307, lng: 18.5074488 },
    { lat: 49.0618545, lng: 18.5073053 },
    { lat: 49.0618484, lng: 18.507194 },
    { lat: 49.0618644, lng: 18.5071356 },
    { lat: 49.0618466, lng: 18.5068698 },
    { lat: 49.0618517, lng: 18.5067787 },
    { lat: 49.0618853, lng: 18.50665 },
    { lat: 49.0619051, lng: 18.5065981 },
    { lat: 49.061908, lng: 18.5064233 },
    { lat: 49.0618996, lng: 18.5063733 },
    { lat: 49.0619134, lng: 18.5063573 },
    { lat: 49.0619132, lng: 18.506332 },
    { lat: 49.0618815, lng: 18.506248 },
    { lat: 49.0618751, lng: 18.5061489 },
    { lat: 49.0619109, lng: 18.5059397 },
    { lat: 49.061894, lng: 18.5058769 },
    { lat: 49.0619096, lng: 18.5057893 },
    { lat: 49.0619202, lng: 18.5057574 },
    { lat: 49.0618759, lng: 18.5056887 },
    { lat: 49.0618761, lng: 18.5055686 },
    { lat: 49.0618958, lng: 18.5055571 },
    { lat: 49.0618883, lng: 18.5055023 },
    { lat: 49.0618887, lng: 18.5054495 },
    { lat: 49.0619312, lng: 18.5052751 },
    { lat: 49.0618784, lng: 18.5050739 },
    { lat: 49.0618657, lng: 18.5050262 },
    { lat: 49.0619039, lng: 18.5050025 },
    { lat: 49.061932, lng: 18.5049585 },
    { lat: 49.0619645, lng: 18.5048098 },
    { lat: 49.0619743, lng: 18.5047611 },
    { lat: 49.0622608, lng: 18.5034575 },
    { lat: 49.0623707, lng: 18.5024316 },
    { lat: 49.0624319, lng: 18.5021888 },
    { lat: 49.062617, lng: 18.5017551 },
    { lat: 49.0629555, lng: 18.5010948 },
    { lat: 49.0630908, lng: 18.5008259 },
    { lat: 49.0631684, lng: 18.5006776 },
    { lat: 49.0632114, lng: 18.5005961 },
    { lat: 49.0633194, lng: 18.5003843 },
    { lat: 49.0636635, lng: 18.4997242 },
    { lat: 49.0637051, lng: 18.4996585 },
    { lat: 49.0637595, lng: 18.4996009 },
    { lat: 49.0638792, lng: 18.4994596 },
    { lat: 49.0642028, lng: 18.4994149 },
    { lat: 49.0644071, lng: 18.4994604 },
    { lat: 49.064967, lng: 18.4996132 },
    { lat: 49.0649741, lng: 18.4993699 },
    { lat: 49.0651167, lng: 18.4993996 },
    { lat: 49.0653606, lng: 18.4993623 },
    { lat: 49.06564, lng: 18.4992542 },
    { lat: 49.065681, lng: 18.4992382 },
    { lat: 49.0658477, lng: 18.4991736 },
    { lat: 49.0661428, lng: 18.4989796 },
    { lat: 49.0662349, lng: 18.4988494 },
    { lat: 49.0663341, lng: 18.4985866 },
    { lat: 49.0663741, lng: 18.49865 },
    { lat: 49.0663814, lng: 18.4986399 },
    { lat: 49.0667319, lng: 18.4981575 },
    { lat: 49.0669336, lng: 18.4978642 },
    { lat: 49.0674234, lng: 18.4971791 },
    { lat: 49.0675886, lng: 18.496948 },
    { lat: 49.0676158, lng: 18.4969099 },
    { lat: 49.0676396, lng: 18.4968691 },
    { lat: 49.0680365, lng: 18.4962055 },
    { lat: 49.0680945, lng: 18.4961086 },
    { lat: 49.0683441, lng: 18.4956831 },
    { lat: 49.0683555, lng: 18.495663 },
    { lat: 49.0686355, lng: 18.4959562 },
    { lat: 49.0687167, lng: 18.4960411 },
    { lat: 49.0687479, lng: 18.4960742 },
    { lat: 49.0687643, lng: 18.4961069 },
    { lat: 49.0687922, lng: 18.4961622 },
    { lat: 49.0688111, lng: 18.4961988 },
    { lat: 49.0688749, lng: 18.4962508 },
    { lat: 49.0689725, lng: 18.4962858 },
    { lat: 49.0690073, lng: 18.4963422 },
    { lat: 49.0690394, lng: 18.4964356 },
    { lat: 49.0691229, lng: 18.4965594 },
    { lat: 49.0692288, lng: 18.4966804 },
    { lat: 49.0693634, lng: 18.4968325 },
    { lat: 49.0694182, lng: 18.4968677 },
    { lat: 49.0695291, lng: 18.4968725 },
    { lat: 49.0695531, lng: 18.4968783 },
    { lat: 49.069572, lng: 18.4968745 },
    { lat: 49.0696369, lng: 18.4968221 },
    { lat: 49.0697511, lng: 18.4968051 },
    { lat: 49.0698119, lng: 18.4968453 },
    { lat: 49.0700068, lng: 18.4968419 },
    { lat: 49.0700791, lng: 18.4968018 },
    { lat: 49.0701265, lng: 18.4967203 },
    { lat: 49.0702418, lng: 18.4966636 },
    { lat: 49.070299, lng: 18.4965938 },
    { lat: 49.0704088, lng: 18.4965816 },
    { lat: 49.0704191, lng: 18.4965635 },
    { lat: 49.0704218, lng: 18.4965091 },
    { lat: 49.0704085, lng: 18.4963884 },
    { lat: 49.0704289, lng: 18.4963713 },
    { lat: 49.070465, lng: 18.4963908 },
    { lat: 49.070513, lng: 18.4963405 },
    { lat: 49.0706206, lng: 18.4963276 },
    { lat: 49.0706749, lng: 18.4963312 },
    { lat: 49.0707499, lng: 18.4962375 },
    { lat: 49.0708107, lng: 18.4960899 },
    { lat: 49.0708407, lng: 18.4959574 },
    { lat: 49.0708198, lng: 18.4959287 },
    { lat: 49.070712, lng: 18.4957279 },
    { lat: 49.0706872, lng: 18.4956868 },
    { lat: 49.0703532, lng: 18.4947328 },
    { lat: 49.070088, lng: 18.4940904 },
    { lat: 49.0698337, lng: 18.4933791 },
    { lat: 49.069622, lng: 18.4928681 },
    { lat: 49.0692607, lng: 18.4921575 },
    { lat: 49.0690432, lng: 18.4919541 },
    { lat: 49.0687083, lng: 18.4916484 },
    { lat: 49.0685057, lng: 18.4914706 },
    { lat: 49.0679971, lng: 18.4914929 },
    { lat: 49.0675536, lng: 18.4915485 },
    { lat: 49.0673758, lng: 18.49164 },
    { lat: 49.0672597, lng: 18.491684 },
    { lat: 49.0666252, lng: 18.4919615 },
    { lat: 49.0660961, lng: 18.4916901 },
    { lat: 49.0660327, lng: 18.491647 },
    { lat: 49.0659705, lng: 18.4916022 },
    { lat: 49.0659091, lng: 18.4915508 },
    { lat: 49.065787, lng: 18.4914421 },
    { lat: 49.0657225, lng: 18.4913761 },
    { lat: 49.0656652, lng: 18.4913185 },
    { lat: 49.0655762, lng: 18.4912383 },
    { lat: 49.0659102, lng: 18.4901868 },
    { lat: 49.0658872, lng: 18.4894138 },
    { lat: 49.0654577, lng: 18.4894402 },
    { lat: 49.065235, lng: 18.4892653 },
    { lat: 49.0649549, lng: 18.4889077 },
    { lat: 49.0648553, lng: 18.4892108 },
    { lat: 49.0647333, lng: 18.4896008 },
    { lat: 49.0643392, lng: 18.4897158 },
    { lat: 49.0637764, lng: 18.4895733 },
    { lat: 49.0631954, lng: 18.4889712 },
    { lat: 49.0628881, lng: 18.4885918 },
    { lat: 49.0626331, lng: 18.48828 },
    { lat: 49.0625497, lng: 18.4881781 },
    { lat: 49.0627097, lng: 18.4903034 },
    { lat: 49.0625672, lng: 18.4913896 },
    { lat: 49.0625541, lng: 18.4921431 },
    { lat: 49.0618188, lng: 18.4914845 },
    { lat: 49.0611008, lng: 18.4912452 },
    { lat: 49.0608406, lng: 18.4911695 },
    { lat: 49.0603708, lng: 18.4905171 },
    { lat: 49.0590549, lng: 18.4894523 },
    { lat: 49.058656, lng: 18.4892326 },
    { lat: 49.0581788, lng: 18.48948 },
    { lat: 49.0576994, lng: 18.4898087 },
    { lat: 49.0561482, lng: 18.4893306 },
    { lat: 49.054501, lng: 18.4881858 },
    { lat: 49.0543942, lng: 18.4879024 },
    { lat: 49.0543757, lng: 18.4878537 },
    { lat: 49.0535568, lng: 18.4879176 },
    { lat: 49.0533286, lng: 18.4882392 },
    { lat: 49.0528087, lng: 18.4881392 },
    { lat: 49.0523199, lng: 18.4879036 },
    { lat: 49.0516035, lng: 18.4878198 },
    { lat: 49.0512662, lng: 18.4879002 },
    { lat: 49.0509312, lng: 18.4880054 },
    { lat: 49.0507429, lng: 18.4881095 },
    { lat: 49.0499466, lng: 18.4891005 },
    { lat: 49.0495963, lng: 18.4896874 },
    { lat: 49.0490929, lng: 18.4902825 },
    { lat: 49.049058, lng: 18.4903243 },
    { lat: 49.0486413, lng: 18.4908116 },
    { lat: 49.0479882, lng: 18.4915757 },
    { lat: 49.0475653, lng: 18.4921128 },
    { lat: 49.0475303, lng: 18.4921566 },
    { lat: 49.0473436, lng: 18.4923928 },
    { lat: 49.0469455, lng: 18.4929109 },
    { lat: 49.0466771, lng: 18.493242 },
    { lat: 49.0465437, lng: 18.4934004 },
  ],
  PrečínExt: [
    { lat: 49.087588, lng: 18.557 },
    { lat: 49.08761, lng: 18.556998 },
    { lat: 49.088668, lng: 18.556947 },
    { lat: 49.089008, lng: 18.556941 },
    { lat: 49.090706, lng: 18.557713 },
    { lat: 49.092744, lng: 18.558824 },
    { lat: 49.093213, lng: 18.559288 },
    { lat: 49.093649, lng: 18.559581 },
    { lat: 49.09429, lng: 18.561177 },
    { lat: 49.0945351, lng: 18.5605756 },
    { lat: 49.094928, lng: 18.5600002 },
    { lat: 49.0956793, lng: 18.559751 },
    { lat: 49.0961644, lng: 18.5593969 },
    { lat: 49.0970346, lng: 18.5583237 },
    { lat: 49.0978304, lng: 18.5572894 },
    { lat: 49.098583, lng: 18.5565428 },
    { lat: 49.0986076, lng: 18.5565175 },
    { lat: 49.0995266, lng: 18.5555737 },
    { lat: 49.0995361, lng: 18.5556034 },
    { lat: 49.0995695, lng: 18.5556169 },
    { lat: 49.0995956, lng: 18.5555985 },
    { lat: 49.0997156, lng: 18.5554816 },
    { lat: 49.099888, lng: 18.5553103 },
    { lat: 49.0999137, lng: 18.5552848 },
    { lat: 49.0999222, lng: 18.5552772 },
    { lat: 49.0999473, lng: 18.5552604 },
    { lat: 49.0999789, lng: 18.5552183 },
    { lat: 49.0999926, lng: 18.5552006 },
    { lat: 49.0999946, lng: 18.5551976 },
    { lat: 49.0999984, lng: 18.5551838 },
    { lat: 49.1000452, lng: 18.5550261 },
    { lat: 49.1003471, lng: 18.5546843 },
    { lat: 49.100513, lng: 18.5543925 },
    { lat: 49.1006095, lng: 18.5542197 },
    { lat: 49.1007041, lng: 18.5540702 },
    { lat: 49.1008899, lng: 18.5538142 },
    { lat: 49.1010246, lng: 18.553613 },
    { lat: 49.1010361, lng: 18.5535958 },
    { lat: 49.1010844, lng: 18.5535514 },
    { lat: 49.1011182, lng: 18.5535286 },
    { lat: 49.101178, lng: 18.5535294 },
    { lat: 49.1012307, lng: 18.5535665 },
    { lat: 49.101266, lng: 18.5535097 },
    { lat: 49.1012867, lng: 18.553433 },
    { lat: 49.1013551, lng: 18.5534255 },
    { lat: 49.1013625, lng: 18.5534161 },
    { lat: 49.1013931, lng: 18.5533563 },
    { lat: 49.1014162, lng: 18.5533261 },
    { lat: 49.1016876, lng: 18.5526287 },
    { lat: 49.1023285, lng: 18.5512342 },
    { lat: 49.1028287, lng: 18.5501379 },
    { lat: 49.1037121, lng: 18.5495775 },
    { lat: 49.1055469, lng: 18.548134 },
    { lat: 49.1057528, lng: 18.5476633 },
    { lat: 49.1058984, lng: 18.5468948 },
    { lat: 49.1065781, lng: 18.545552 },
    { lat: 49.1072302, lng: 18.5448684 },
    { lat: 49.1082521, lng: 18.5442215 },
    { lat: 49.109022, lng: 18.5438371 },
    { lat: 49.109255, lng: 18.5428265 },
    { lat: 49.1094929, lng: 18.5419092 },
    { lat: 49.1098397, lng: 18.5409363 },
    { lat: 49.1101272, lng: 18.5406443 },
    { lat: 49.109086, lng: 18.5401831 },
    { lat: 49.107946, lng: 18.5395835 },
    { lat: 49.1073642, lng: 18.5391692 },
    { lat: 49.1071643, lng: 18.5388926 },
    { lat: 49.1071251, lng: 18.538837 },
    { lat: 49.1065544, lng: 18.5380463 },
    { lat: 49.1065901, lng: 18.5359965 },
    { lat: 49.1058893, lng: 18.5343448 },
    { lat: 49.1057157, lng: 18.5338255 },
    { lat: 49.1052796, lng: 18.5338555 },
    { lat: 49.1048605, lng: 18.5334664 },
    { lat: 49.1043307, lng: 18.5333476 },
    { lat: 49.1035992, lng: 18.5324617 },
    { lat: 49.1034119, lng: 18.5324186 },
    { lat: 49.1026106, lng: 18.531368 },
    { lat: 49.102612, lng: 18.5311648 },
    { lat: 49.1026483, lng: 18.5309828 },
    { lat: 49.1019934, lng: 18.5299913 },
    { lat: 49.1018181, lng: 18.5294205 },
    { lat: 49.1015014, lng: 18.5291112 },
    { lat: 49.1011847, lng: 18.5288263 },
    { lat: 49.1008209, lng: 18.5283789 },
    { lat: 49.100568, lng: 18.5281228 },
    { lat: 49.1004621, lng: 18.5278136 },
    { lat: 49.1005212, lng: 18.527511 },
    { lat: 49.1007922, lng: 18.526433 },
    { lat: 49.1009646, lng: 18.5260768 },
    { lat: 49.1012853, lng: 18.5252523 },
    { lat: 49.1016241, lng: 18.5239307 },
    { lat: 49.1023158, lng: 18.521127 },
    { lat: 49.1021073, lng: 18.5208613 },
    { lat: 49.1020044, lng: 18.5206903 },
    { lat: 49.1019059, lng: 18.5204562 },
    { lat: 49.1013276, lng: 18.5175527 },
    { lat: 49.1011801, lng: 18.5171827 },
    { lat: 49.0993165, lng: 18.5163685 },
    { lat: 49.0989728, lng: 18.5135091 },
    { lat: 49.0977032, lng: 18.5129379 },
    { lat: 49.0973493, lng: 18.5127565 },
    { lat: 49.0957388, lng: 18.5117834 },
    { lat: 49.0958902, lng: 18.5115191 },
    { lat: 49.0961113, lng: 18.5110211 },
    { lat: 49.0962564, lng: 18.5104957 },
    { lat: 49.0963265, lng: 18.5100826 },
    { lat: 49.0963163, lng: 18.5098955 },
    { lat: 49.0960014, lng: 18.5086681 },
    { lat: 49.0956558, lng: 18.5073942 },
    { lat: 49.0953802, lng: 18.506872 },
    { lat: 49.0949036, lng: 18.5067133 },
    { lat: 49.0943888, lng: 18.50511 },
    { lat: 49.0943751, lng: 18.5047537 },
    { lat: 49.0936377, lng: 18.5041798 },
    { lat: 49.0931617, lng: 18.5038883 },
    { lat: 49.0926916, lng: 18.5031065 },
    { lat: 49.0926184, lng: 18.5029833 },
    { lat: 49.0926134, lng: 18.5029767 },
    { lat: 49.0925844, lng: 18.5027734 },
    { lat: 49.0925576, lng: 18.5025918 },
    { lat: 49.0925842, lng: 18.5022725 },
    { lat: 49.0926018, lng: 18.5020576 },
    { lat: 49.0926856, lng: 18.5010677 },
    { lat: 49.0926069, lng: 18.5005756 },
    { lat: 49.0926945, lng: 18.4996178 },
    { lat: 49.0927068, lng: 18.4992018 },
    { lat: 49.0926509, lng: 18.4988997 },
    { lat: 49.0924445, lng: 18.4988786 },
    { lat: 49.0924308, lng: 18.4988769 },
    { lat: 49.0923373, lng: 18.4994986 },
    { lat: 49.0921258, lng: 18.4994882 },
    { lat: 49.0920101, lng: 18.4995388 },
    { lat: 49.0918206, lng: 18.4996225 },
    { lat: 49.0917154, lng: 18.4996153 },
    { lat: 49.091644, lng: 18.4996101 },
    { lat: 49.0912306, lng: 18.4994541 },
    { lat: 49.0910047, lng: 18.499304 },
    { lat: 49.0909851, lng: 18.4992755 },
    { lat: 49.0909619, lng: 18.4992424 },
    { lat: 49.0909349, lng: 18.4992032 },
    { lat: 49.0910614, lng: 18.4987086 },
    { lat: 49.0908739, lng: 18.4987855 },
    { lat: 49.0907857, lng: 18.49895 },
    { lat: 49.0907378, lng: 18.499293 },
    { lat: 49.0906122, lng: 18.4992839 },
    { lat: 49.0905963, lng: 18.4992821 },
    { lat: 49.0905248, lng: 18.4992732 },
    { lat: 49.090502, lng: 18.4992705 },
    { lat: 49.0905181, lng: 18.4987365 },
    { lat: 49.0901985, lng: 18.4987493 },
    { lat: 49.0896952, lng: 18.4986218 },
    { lat: 49.0895265, lng: 18.4985267 },
    { lat: 49.089371, lng: 18.4984881 },
    { lat: 49.0888723, lng: 18.4981964 },
    { lat: 49.0884497, lng: 18.4979757 },
    { lat: 49.0879246, lng: 18.4978181 },
    { lat: 49.0876476, lng: 18.4977449 },
    { lat: 49.0872338, lng: 18.4981212 },
    { lat: 49.0869659, lng: 18.4986069 },
    { lat: 49.0863466, lng: 18.4985631 },
    { lat: 49.0859338, lng: 18.4985861 },
    { lat: 49.0855887, lng: 18.4987319 },
    { lat: 49.0853301, lng: 18.4990985 },
    { lat: 49.0848829, lng: 18.4994636 },
    { lat: 49.0845583, lng: 18.4996519 },
    { lat: 49.0842604, lng: 18.4998792 },
    { lat: 49.0840247, lng: 18.4998992 },
    { lat: 49.0838236, lng: 18.4998528 },
    { lat: 49.0833795, lng: 18.5000025 },
    { lat: 49.0829425, lng: 18.5001183 },
    { lat: 49.082655, lng: 18.5003489 },
    { lat: 49.0824057, lng: 18.5002031 },
    { lat: 49.0821576, lng: 18.4999133 },
    { lat: 49.0814212, lng: 18.4995445 },
    { lat: 49.0811432, lng: 18.4998865 },
    { lat: 49.0806419, lng: 18.5002486 },
    { lat: 49.0801403, lng: 18.5007528 },
    { lat: 49.0800056, lng: 18.5011999 },
    { lat: 49.0796647, lng: 18.5018152 },
    { lat: 49.0795309, lng: 18.5018046 },
    { lat: 49.0791727, lng: 18.5018334 },
    { lat: 49.078908, lng: 18.5020464 },
    { lat: 49.0785385, lng: 18.5021783 },
    { lat: 49.0780612, lng: 18.5025705 },
    { lat: 49.0778605, lng: 18.5028399 },
    { lat: 49.0772097, lng: 18.5029647 },
    { lat: 49.0764686, lng: 18.5032527 },
    { lat: 49.0757666, lng: 18.5029372 },
    { lat: 49.075327, lng: 18.5025489 },
    { lat: 49.0751193, lng: 18.5024466 },
    { lat: 49.0744751, lng: 18.5021294 },
    { lat: 49.0740312, lng: 18.5012257 },
    { lat: 49.0736821, lng: 18.5006353 },
    { lat: 49.0730883, lng: 18.4996141 },
    { lat: 49.0727239, lng: 18.4990532 },
    { lat: 49.072323, lng: 18.4984017 },
    { lat: 49.0720224, lng: 18.4978966 },
    { lat: 49.0714021, lng: 18.4969056 },
    { lat: 49.0712148, lng: 18.5003786 },
    { lat: 49.0712125, lng: 18.500669 },
    { lat: 49.071264, lng: 18.501757 },
    { lat: 49.0710271, lng: 18.5024204 },
    { lat: 49.0710154, lng: 18.5025579 },
    { lat: 49.0710157, lng: 18.5026059 },
    { lat: 49.0709783, lng: 18.5032036 },
    { lat: 49.0707754, lng: 18.5029159 },
    { lat: 49.070772, lng: 18.5029956 },
    { lat: 49.070734, lng: 18.5038727 },
    { lat: 49.0705136, lng: 18.5043843 },
    { lat: 49.0708041, lng: 18.5051197 },
    { lat: 49.071047, lng: 18.5055405 },
    { lat: 49.0711719, lng: 18.5065502 },
    { lat: 49.0713829, lng: 18.50713 },
    { lat: 49.0716864, lng: 18.5077128 },
    { lat: 49.071854, lng: 18.5083256 },
    { lat: 49.0718682, lng: 18.5087121 },
    { lat: 49.0718704, lng: 18.5088957 },
    { lat: 49.0717983, lng: 18.5089013 },
    { lat: 49.0717695, lng: 18.5091782 },
    { lat: 49.0717148, lng: 18.509417 },
    { lat: 49.0717348, lng: 18.5096377 },
    { lat: 49.0716297, lng: 18.509968 },
    { lat: 49.0716102, lng: 18.5101887 },
    { lat: 49.0715911, lng: 18.5103566 },
    { lat: 49.0716125, lng: 18.510751 },
    { lat: 49.0716749, lng: 18.5112538 },
    { lat: 49.0716312, lng: 18.511425 },
    { lat: 49.0715654, lng: 18.5115646 },
    { lat: 49.0713632, lng: 18.5125747 },
    { lat: 49.0711282, lng: 18.5131051 },
    { lat: 49.0710168, lng: 18.5136087 },
    { lat: 49.0710572, lng: 18.5136472 },
    { lat: 49.0709197, lng: 18.5139998 },
    { lat: 49.0707444, lng: 18.5144276 },
    { lat: 49.0707274, lng: 18.5144665 },
    { lat: 49.0706303, lng: 18.514694 },
    { lat: 49.070525, lng: 18.5148531 },
    { lat: 49.0704733, lng: 18.514876 },
    { lat: 49.0704607, lng: 18.5149569 },
    { lat: 49.070367, lng: 18.5151641 },
    { lat: 49.0703743, lng: 18.5151725 },
    { lat: 49.0703829, lng: 18.5151844 },
    { lat: 49.07039, lng: 18.5151917 },
    { lat: 49.0705469, lng: 18.5153608 },
    { lat: 49.0706691, lng: 18.515303 },
    { lat: 49.0706904, lng: 18.5153439 },
    { lat: 49.0708688, lng: 18.5157815 },
    { lat: 49.0708871, lng: 18.5163479 },
    { lat: 49.0707658, lng: 18.5165014 },
    { lat: 49.0707914, lng: 18.5165789 },
    { lat: 49.070736, lng: 18.5168262 },
    { lat: 49.0706861, lng: 18.5170492 },
    { lat: 49.0705779, lng: 18.5171731 },
    { lat: 49.0705391, lng: 18.5172077 },
    { lat: 49.0705242, lng: 18.5172031 },
    { lat: 49.0704601, lng: 18.5175173 },
    { lat: 49.070444, lng: 18.5175132 },
    { lat: 49.0704424, lng: 18.5175242 },
    { lat: 49.0704345, lng: 18.517941 },
    { lat: 49.0704806, lng: 18.5179365 },
    { lat: 49.0705889, lng: 18.5181464 },
    { lat: 49.0707425, lng: 18.5183791 },
    { lat: 49.0708275, lng: 18.5186143 },
    { lat: 49.07086, lng: 18.5188588 },
    { lat: 49.0706856, lng: 18.5189703 },
    { lat: 49.0705295, lng: 18.5191701 },
    { lat: 49.0704952, lng: 18.5192637 },
    { lat: 49.0704504, lng: 18.5192715 },
    { lat: 49.0701494, lng: 18.5193137 },
    { lat: 49.0701754, lng: 18.5197108 },
    { lat: 49.0701354, lng: 18.5198468 },
    { lat: 49.0701298, lng: 18.5198669 },
    { lat: 49.0701223, lng: 18.519875 },
    { lat: 49.070142, lng: 18.5199272 },
    { lat: 49.0701473, lng: 18.5199415 },
    { lat: 49.0701673, lng: 18.5199953 },
    { lat: 49.0701778, lng: 18.5200242 },
    { lat: 49.0701222, lng: 18.5201434 },
    { lat: 49.0702164, lng: 18.5204427 },
    { lat: 49.0701639, lng: 18.5210771 },
    { lat: 49.0703464, lng: 18.521716 },
    { lat: 49.0705078, lng: 18.5219267 },
    { lat: 49.0705451, lng: 18.5220515 },
    { lat: 49.0706391, lng: 18.5222877 },
    { lat: 49.0706162, lng: 18.5224469 },
    { lat: 49.070663, lng: 18.5227079 },
    { lat: 49.0707636, lng: 18.5230858 },
    { lat: 49.0709115, lng: 18.5234204 },
    { lat: 49.0712402, lng: 18.5238645 },
    { lat: 49.0713859, lng: 18.5239509 },
    { lat: 49.0716763, lng: 18.5244597 },
    { lat: 49.0718956, lng: 18.5247182 },
    { lat: 49.071997, lng: 18.5248414 },
    { lat: 49.0721046, lng: 18.5250998 },
    { lat: 49.0722113, lng: 18.5253805 },
    { lat: 49.0722559, lng: 18.5257041 },
    { lat: 49.0723541, lng: 18.526433 },
    { lat: 49.0723383, lng: 18.5268062 },
    { lat: 49.0723342, lng: 18.5268575 },
    { lat: 49.0722821, lng: 18.5274339 },
    { lat: 49.0723105, lng: 18.527853 },
    { lat: 49.0722674, lng: 18.5280156 },
    { lat: 49.0722062, lng: 18.5280942 },
    { lat: 49.0721419, lng: 18.5281757 },
    { lat: 49.072125, lng: 18.5281979 },
    { lat: 49.0720933, lng: 18.5282375 },
    { lat: 49.072066, lng: 18.528274 },
    { lat: 49.0717941, lng: 18.5286253 },
    { lat: 49.0716559, lng: 18.5286152 },
    { lat: 49.0714354, lng: 18.5286884 },
    { lat: 49.0713323, lng: 18.5286584 },
    { lat: 49.070672, lng: 18.5287333 },
    { lat: 49.0703662, lng: 18.528628 },
    { lat: 49.0702888, lng: 18.5286157 },
    { lat: 49.0701547, lng: 18.5285952 },
    { lat: 49.0697149, lng: 18.5287638 },
    { lat: 49.0695691, lng: 18.5289458 },
    { lat: 49.0693722, lng: 18.5290781 },
    { lat: 49.0692534, lng: 18.5293624 },
    { lat: 49.0691066, lng: 18.5294222 },
    { lat: 49.0689221, lng: 18.5291213 },
    { lat: 49.0684946, lng: 18.5292851 },
    { lat: 49.0677338, lng: 18.5298789 },
    { lat: 49.0676534, lng: 18.5303699 },
    { lat: 49.0675702, lng: 18.5308754 },
    { lat: 49.0675913, lng: 18.5311177 },
    { lat: 49.0671326, lng: 18.5327485 },
    { lat: 49.0669948, lng: 18.5330546 },
    { lat: 49.0669134, lng: 18.533219 },
    { lat: 49.0667333, lng: 18.533393 },
    { lat: 49.0666473, lng: 18.5334502 },
    { lat: 49.066647, lng: 18.5335472 },
    { lat: 49.0666473, lng: 18.5336162 },
    { lat: 49.0666473, lng: 18.5337205 },
    { lat: 49.0666471, lng: 18.5337578 },
    { lat: 49.0666467, lng: 18.5338755 },
    { lat: 49.0666465, lng: 18.5339133 },
    { lat: 49.0665624, lng: 18.5350031 },
    { lat: 49.0664628, lng: 18.5360382 },
    { lat: 49.0663918, lng: 18.5369763 },
    { lat: 49.0665542, lng: 18.5384754 },
    { lat: 49.066721, lng: 18.5386601 },
    { lat: 49.0679072, lng: 18.5394435 },
    { lat: 49.0687849, lng: 18.5413245 },
    { lat: 49.0694531, lng: 18.5434989 },
    { lat: 49.0714086, lng: 18.5463298 },
    { lat: 49.072665, lng: 18.5472041 },
    { lat: 49.0738588, lng: 18.5482768 },
    { lat: 49.0739547, lng: 18.5496939 },
    { lat: 49.074645, lng: 18.5515535 },
    { lat: 49.0752285, lng: 18.5525624 },
    { lat: 49.0774754, lng: 18.5541933 },
    { lat: 49.0779851, lng: 18.554964 },
    { lat: 49.0790162, lng: 18.555659 },
    { lat: 49.0818321, lng: 18.5576757 },
    { lat: 49.0827084, lng: 18.5576739 },
    { lat: 49.0831344, lng: 18.5575853 },
    { lat: 49.0834412, lng: 18.5575221 },
    { lat: 49.0843518, lng: 18.5575287 },
    { lat: 49.084989, lng: 18.5579254 },
    { lat: 49.0852491, lng: 18.5580033 },
    { lat: 49.0859869, lng: 18.5573777 },
    { lat: 49.087588, lng: 18.557 },
  ],
  Pružina: [
    { lat: 49.0209851, lng: 18.4996213 },
    { lat: 49.0210272, lng: 18.4995883 },
    { lat: 49.0210651, lng: 18.4995594 },
    { lat: 49.0220031, lng: 18.4988258 },
    { lat: 49.0221871, lng: 18.4987872 },
    { lat: 49.0226276, lng: 18.4982195 },
    { lat: 49.023055, lng: 18.4986532 },
    { lat: 49.023215, lng: 18.4982776 },
    { lat: 49.0228996, lng: 18.4977884 },
    { lat: 49.0227441, lng: 18.4976067 },
    { lat: 49.0229545, lng: 18.4973067 },
    { lat: 49.0230599, lng: 18.4974605 },
    { lat: 49.0234786, lng: 18.4967221 },
    { lat: 49.0237986, lng: 18.4959082 },
    { lat: 49.0242907, lng: 18.4965128 },
    { lat: 49.0246496, lng: 18.4961026 },
    { lat: 49.0249461, lng: 18.4958946 },
    { lat: 49.0251455, lng: 18.495847 },
    { lat: 49.0245722, lng: 18.4949529 },
    { lat: 49.0246973, lng: 18.4948699 },
    { lat: 49.0246022, lng: 18.4943463 },
    { lat: 49.025596, lng: 18.4938942 },
    { lat: 49.0257771, lng: 18.4942361 },
    { lat: 49.0268679, lng: 18.4932018 },
    { lat: 49.0272053, lng: 18.493039 },
    { lat: 49.0275612, lng: 18.4926375 },
    { lat: 49.027765, lng: 18.4921789 },
    { lat: 49.0275836, lng: 18.4916027 },
    { lat: 49.0279098, lng: 18.4912973 },
    { lat: 49.027915, lng: 18.4909172 },
    { lat: 49.0275699, lng: 18.4893699 },
    { lat: 49.0273187, lng: 18.4885493 },
    { lat: 49.0276184, lng: 18.4883175 },
    { lat: 49.0304781, lng: 18.4827096 },
    { lat: 49.0309738, lng: 18.4825113 },
    { lat: 49.0314557, lng: 18.4825392 },
    { lat: 49.0319958, lng: 18.4825352 },
    { lat: 49.0324512, lng: 18.4826516 },
    { lat: 49.0326831, lng: 18.4829314 },
    { lat: 49.0333145, lng: 18.4833203 },
    { lat: 49.0334004, lng: 18.4833428 },
    { lat: 49.0337048, lng: 18.4834223 },
    { lat: 49.0337321, lng: 18.4834292 },
    { lat: 49.0340976, lng: 18.4835724 },
    { lat: 49.0343926, lng: 18.4838328 },
    { lat: 49.0345812, lng: 18.4839998 },
    { lat: 49.0348887, lng: 18.4839099 },
    { lat: 49.0357461, lng: 18.4846143 },
    { lat: 49.0358155, lng: 18.4846878 },
    { lat: 49.0358815, lng: 18.484758 },
    { lat: 49.0359962, lng: 18.4842163 },
    { lat: 49.0359129, lng: 18.4830628 },
    { lat: 49.0358598, lng: 18.4821955 },
    { lat: 49.0359439, lng: 18.4817118 },
    { lat: 49.0360532, lng: 18.4813187 },
    { lat: 49.0362626, lng: 18.4807738 },
    { lat: 49.0363026, lng: 18.4804556 },
    { lat: 49.0362747, lng: 18.4796768 },
    { lat: 49.0362796, lng: 18.4792725 },
    { lat: 49.0363234, lng: 18.4789578 },
    { lat: 49.0363478, lng: 18.4789714 },
    { lat: 49.0363907, lng: 18.4789935 },
    { lat: 49.0364348, lng: 18.4790167 },
    { lat: 49.0387624, lng: 18.4779131 },
    { lat: 49.0396222, lng: 18.4772383 },
    { lat: 49.040138, lng: 18.4770565 },
    { lat: 49.039621, lng: 18.4754426 },
    { lat: 49.0390317, lng: 18.4751466 },
    { lat: 49.03866, lng: 18.4747858 },
    { lat: 49.0387806, lng: 18.4740216 },
    { lat: 49.0388195, lng: 18.4731675 },
    { lat: 49.0388982, lng: 18.4724192 },
    { lat: 49.0389767, lng: 18.4714651 },
    { lat: 49.0389418, lng: 18.4708896 },
    { lat: 49.0380564, lng: 18.4706021 },
    { lat: 49.0380437, lng: 18.4705767 },
    { lat: 49.0380349, lng: 18.4705581 },
    { lat: 49.0380271, lng: 18.4705407 },
    { lat: 49.0380194, lng: 18.4705244 },
    { lat: 49.0379048, lng: 18.4702844 },
    { lat: 49.0378804, lng: 18.4702325 },
    { lat: 49.0378716, lng: 18.4702147 },
    { lat: 49.0375787, lng: 18.4652001 },
    { lat: 49.0365604, lng: 18.462902 },
    { lat: 49.0336004, lng: 18.4667253 },
    { lat: 49.0335448, lng: 18.4667818 },
    { lat: 49.0334957, lng: 18.4668321 },
    { lat: 49.0334732, lng: 18.4668552 },
    { lat: 49.0334147, lng: 18.4669164 },
    { lat: 49.0333792, lng: 18.4669516 },
    { lat: 49.0332426, lng: 18.4670921 },
    { lat: 49.0329985, lng: 18.4666323 },
    { lat: 49.0326488, lng: 18.4659728 },
    { lat: 49.0320669, lng: 18.4649035 },
    { lat: 49.0320091, lng: 18.4647979 },
    { lat: 49.0319227, lng: 18.4646403 },
    { lat: 49.0315964, lng: 18.4640396 },
    { lat: 49.0315737, lng: 18.4639976 },
    { lat: 49.0315563, lng: 18.4639658 },
    { lat: 49.0315362, lng: 18.4639285 },
    { lat: 49.0313317, lng: 18.4635534 },
    { lat: 49.0312927, lng: 18.46348 },
    { lat: 49.031264, lng: 18.4634287 },
    { lat: 49.0312352, lng: 18.4633757 },
    { lat: 49.0301345, lng: 18.4617991 },
    { lat: 49.0301165, lng: 18.4617794 },
    { lat: 49.0300794, lng: 18.4617404 },
    { lat: 49.0300759, lng: 18.4617366 },
    { lat: 49.0300496, lng: 18.4617095 },
    { lat: 49.0298173, lng: 18.4614658 },
    { lat: 49.0297515, lng: 18.4613965 },
    { lat: 49.0296977, lng: 18.4613397 },
    { lat: 49.0296546, lng: 18.4612956 },
    { lat: 49.0296353, lng: 18.4612743 },
    { lat: 49.0291736, lng: 18.4607944 },
    { lat: 49.0290451, lng: 18.4606301 },
    { lat: 49.0281955, lng: 18.4595403 },
    { lat: 49.0278374, lng: 18.4575765 },
    { lat: 49.0275333, lng: 18.4571962 },
    { lat: 49.0268269, lng: 18.45711 },
    { lat: 49.0264033, lng: 18.4569345 },
    { lat: 49.0263778, lng: 18.4559347 },
    { lat: 49.0263899, lng: 18.4538766 },
    { lat: 49.0257884, lng: 18.4529683 },
    { lat: 49.025462, lng: 18.4524082 },
    { lat: 49.0252196, lng: 18.4518136 },
    { lat: 49.024972, lng: 18.4504906 },
    { lat: 49.0249079, lng: 18.4492637 },
    { lat: 49.0232695, lng: 18.4469566 },
    { lat: 49.0230395, lng: 18.4464273 },
    { lat: 49.022659, lng: 18.4458053 },
    { lat: 49.022395, lng: 18.4451887 },
    { lat: 49.0222323, lng: 18.444303 },
    { lat: 49.021433, lng: 18.4432251 },
    { lat: 49.019645, lng: 18.4418105 },
    { lat: 49.019476, lng: 18.4416919 },
    { lat: 49.0184808, lng: 18.4414397 },
    { lat: 49.0184076, lng: 18.4414214 },
    { lat: 49.0183138, lng: 18.4413974 },
    { lat: 49.0182451, lng: 18.4413803 },
    { lat: 49.0182496, lng: 18.4412769 },
    { lat: 49.0181133, lng: 18.4411764 },
    { lat: 49.0180291, lng: 18.4408565 },
    { lat: 49.0179975, lng: 18.4405079 },
    { lat: 49.0179278, lng: 18.4405344 },
    { lat: 49.0178025, lng: 18.4403685 },
    { lat: 49.01781, lng: 18.440218 },
    { lat: 49.0178606, lng: 18.440194 },
    { lat: 49.0178505, lng: 18.440136 },
    { lat: 49.0178489, lng: 18.439982 },
    { lat: 49.0177068, lng: 18.4396339 },
    { lat: 49.0167486, lng: 18.4391131 },
    { lat: 49.016277, lng: 18.4386217 },
    { lat: 49.0160444, lng: 18.4382682 },
    { lat: 49.0160419, lng: 18.4382637 },
    { lat: 49.0160198, lng: 18.4382306 },
    { lat: 49.0159742, lng: 18.4381609 },
    { lat: 49.015829, lng: 18.4379424 },
    { lat: 49.0157162, lng: 18.4374696 },
    { lat: 49.015317, lng: 18.4369819 },
    { lat: 49.0150255, lng: 18.4362782 },
    { lat: 49.0148362, lng: 18.4360154 },
    { lat: 49.0146473, lng: 18.4358625 },
    { lat: 49.0144375, lng: 18.4356996 },
    { lat: 49.0140947, lng: 18.4355801 },
    { lat: 49.0137113, lng: 18.4353365 },
    { lat: 49.0135376, lng: 18.4350919 },
    { lat: 49.0132059, lng: 18.4346609 },
    { lat: 49.0126736, lng: 18.4337405 },
    { lat: 49.0122428, lng: 18.4324543 },
    { lat: 49.01186, lng: 18.4317725 },
    { lat: 49.0115659, lng: 18.4311426 },
    { lat: 49.0110878, lng: 18.4303504 },
    { lat: 49.010723, lng: 18.4300166 },
    { lat: 49.0105013, lng: 18.4299029 },
    { lat: 49.0099167, lng: 18.4297349 },
    { lat: 49.0093116, lng: 18.4295612 },
    { lat: 49.0090585, lng: 18.4294117 },
    { lat: 49.0088242, lng: 18.4291313 },
    { lat: 49.0078622, lng: 18.4275211 },
    { lat: 49.0074348, lng: 18.4268914 },
    { lat: 49.006813, lng: 18.4261907 },
    { lat: 49.0060919, lng: 18.4254934 },
    { lat: 49.0059526, lng: 18.4252775 },
    { lat: 49.0058852, lng: 18.4252 },
    { lat: 49.005822, lng: 18.4251598 },
    { lat: 49.0057883, lng: 18.425121 },
    { lat: 49.0057282, lng: 18.4250744 },
    { lat: 49.0056882, lng: 18.4250435 },
    { lat: 49.0056103, lng: 18.4250434 },
    { lat: 49.00512, lng: 18.4248056 },
    { lat: 49.0046726, lng: 18.4247322 },
    { lat: 49.0046361, lng: 18.4246431 },
    { lat: 49.0038449, lng: 18.4243497 },
    { lat: 49.0036855, lng: 18.4242397 },
    { lat: 49.0034252, lng: 18.4242253 },
    { lat: 49.0031092, lng: 18.4242228 },
    { lat: 49.0025642, lng: 18.4239532 },
    { lat: 49.0024184, lng: 18.4238472 },
    { lat: 49.0019717, lng: 18.4235215 },
    { lat: 49.0019432, lng: 18.4234148 },
    { lat: 49.0016005, lng: 18.4230903 },
    { lat: 49.0014035, lng: 18.4228115 },
    { lat: 49.0008784, lng: 18.4219854 },
    { lat: 49.0003831, lng: 18.4214529 },
    { lat: 48.9998251, lng: 18.4210944 },
    { lat: 48.9984631, lng: 18.4207388 },
    { lat: 48.9983149, lng: 18.4207713 },
    { lat: 48.998262, lng: 18.420735 },
    { lat: 48.998228, lng: 18.42077 },
    { lat: 48.998102, lng: 18.420971 },
    { lat: 48.997904, lng: 18.421134 },
    { lat: 48.997643, lng: 18.421169 },
    { lat: 48.996662, lng: 18.420949 },
    { lat: 48.995937, lng: 18.420988 },
    { lat: 48.995308, lng: 18.420585 },
    { lat: 48.99519, lng: 18.420513 },
    { lat: 48.994838, lng: 18.420411 },
    { lat: 48.994788, lng: 18.420393 },
    { lat: 48.994734, lng: 18.420375 },
    { lat: 48.994181, lng: 18.420191 },
    { lat: 48.993652, lng: 18.420115 },
    { lat: 48.99317, lng: 18.42008 },
    { lat: 48.992502, lng: 18.420097 },
    { lat: 48.992281, lng: 18.420209 },
    { lat: 48.992172, lng: 18.420229 },
    { lat: 48.991461, lng: 18.420216 },
    { lat: 48.990714, lng: 18.420303 },
    { lat: 48.990448, lng: 18.420556 },
    { lat: 48.990307, lng: 18.42069 },
    { lat: 48.990232, lng: 18.420779 },
    { lat: 48.990207, lng: 18.420793 },
    { lat: 48.99017, lng: 18.420887 },
    { lat: 48.990063, lng: 18.42107 },
    { lat: 48.990056, lng: 18.421246 },
    { lat: 48.990011, lng: 18.421686 },
    { lat: 48.989941, lng: 18.422084 },
    { lat: 48.989712, lng: 18.422796 },
    { lat: 48.989772, lng: 18.423013 },
    { lat: 48.989725, lng: 18.423605 },
    { lat: 48.989592, lng: 18.424073 },
    { lat: 48.989443, lng: 18.424558 },
    { lat: 48.989435, lng: 18.424921 },
    { lat: 48.989296, lng: 18.425444 },
    { lat: 48.989148, lng: 18.425778 },
    { lat: 48.989028, lng: 18.426197 },
    { lat: 48.988953, lng: 18.426632 },
    { lat: 48.989024, lng: 18.427035 },
    { lat: 48.988945, lng: 18.427474 },
    { lat: 48.988555, lng: 18.428348 },
    { lat: 48.988152, lng: 18.429075 },
    { lat: 48.987849, lng: 18.429676 },
    { lat: 48.987663, lng: 18.430022 },
    { lat: 48.987235, lng: 18.430414 },
    { lat: 48.986785, lng: 18.430803 },
    { lat: 48.986259, lng: 18.431242 },
    { lat: 48.985977, lng: 18.431325 },
    { lat: 48.985424, lng: 18.431691 },
    { lat: 48.985274, lng: 18.431827 },
    { lat: 48.98511, lng: 18.432065 },
    { lat: 48.984693, lng: 18.432376 },
    { lat: 48.984501, lng: 18.432448 },
    { lat: 48.98388, lng: 18.433361 },
    { lat: 48.983576, lng: 18.43381 },
    { lat: 48.983491, lng: 18.434031 },
    { lat: 48.983392, lng: 18.434288 },
    { lat: 48.983184, lng: 18.434994 },
    { lat: 48.983009, lng: 18.435394 },
    { lat: 48.982792, lng: 18.435902 },
    { lat: 48.982582, lng: 18.436536 },
    { lat: 48.982392, lng: 18.43701 },
    { lat: 48.982226, lng: 18.437509 },
    { lat: 48.98227, lng: 18.437579 },
    { lat: 48.982306, lng: 18.437624 },
    { lat: 48.984315, lng: 18.440631 },
    { lat: 48.984024, lng: 18.442165 },
    { lat: 48.983952, lng: 18.442923 },
    { lat: 48.983858, lng: 18.443718 },
    { lat: 48.983728, lng: 18.44458 },
    { lat: 48.983547, lng: 18.445415 },
    { lat: 48.98346, lng: 18.446143 },
    { lat: 48.98327, lng: 18.446883 },
    { lat: 48.983129, lng: 18.447351 },
    { lat: 48.983037, lng: 18.44781 },
    { lat: 48.98285, lng: 18.448086 },
    { lat: 48.98254, lng: 18.448298 },
    { lat: 48.982156, lng: 18.448436 },
    { lat: 48.981418, lng: 18.448855 },
    { lat: 48.981162, lng: 18.449035 },
    { lat: 48.980482, lng: 18.449755 },
    { lat: 48.979824, lng: 18.450524 },
    { lat: 48.979247, lng: 18.451147 },
    { lat: 48.978907, lng: 18.451446 },
    { lat: 48.978695, lng: 18.452054 },
    { lat: 48.978148, lng: 18.452815 },
    { lat: 48.977769, lng: 18.454425 },
    { lat: 48.977737, lng: 18.454757 },
    { lat: 48.977426, lng: 18.455355 },
    { lat: 48.976903, lng: 18.456585 },
    { lat: 48.976737, lng: 18.45707 },
    { lat: 48.976637, lng: 18.457314 },
    { lat: 48.976341, lng: 18.457793 },
    { lat: 48.976243, lng: 18.458092 },
    { lat: 48.976189, lng: 18.458381 },
    { lat: 48.976054, lng: 18.459467 },
    { lat: 48.975954, lng: 18.459966 },
    { lat: 48.975984, lng: 18.46062 },
    { lat: 48.975618, lng: 18.460622 },
    { lat: 48.974656, lng: 18.460095 },
    { lat: 48.973617, lng: 18.460025 },
    { lat: 48.973438, lng: 18.460135 },
    { lat: 48.973342, lng: 18.460179 },
    { lat: 48.973271, lng: 18.460211 },
    { lat: 48.971068, lng: 18.46122 },
    { lat: 48.970663, lng: 18.461182 },
    { lat: 48.969064, lng: 18.461017 },
    { lat: 48.968044, lng: 18.460483 },
    { lat: 48.967742, lng: 18.46041 },
    { lat: 48.966022, lng: 18.459679 },
    { lat: 48.965685, lng: 18.459535 },
    { lat: 48.963737, lng: 18.459846 },
    { lat: 48.961976, lng: 18.46061 },
    { lat: 48.960969, lng: 18.462157 },
    { lat: 48.95879, lng: 18.463365 },
    { lat: 48.957961, lng: 18.463892 },
    { lat: 48.95636, lng: 18.464963 },
    { lat: 48.95628, lng: 18.465388 },
    { lat: 48.956367, lng: 18.465987 },
    { lat: 48.956443, lng: 18.466629 },
    { lat: 48.956571, lng: 18.467521 },
    { lat: 48.95674, lng: 18.468382 },
    { lat: 48.956985, lng: 18.469657 },
    { lat: 48.957048, lng: 18.470222 },
    { lat: 48.957062, lng: 18.470287 },
    { lat: 48.956868, lng: 18.471155 },
    { lat: 48.956741, lng: 18.471765 },
    { lat: 48.956614, lng: 18.472219 },
    { lat: 48.956921, lng: 18.472888 },
    { lat: 48.957078, lng: 18.473734 },
    { lat: 48.957174, lng: 18.474334 },
    { lat: 48.957527, lng: 18.474666 },
    { lat: 48.957615, lng: 18.474757 },
    { lat: 48.957902, lng: 18.47498 },
    { lat: 48.958266, lng: 18.475436 },
    { lat: 48.9583328, lng: 18.4755676 },
    { lat: 48.958861, lng: 18.476608 },
    { lat: 48.959151, lng: 18.477038 },
    { lat: 48.959509, lng: 18.477518 },
    { lat: 48.959852, lng: 18.478101 },
    { lat: 48.960164, lng: 18.478635 },
    { lat: 48.960549, lng: 18.479201 },
    { lat: 48.961155, lng: 18.479666 },
    { lat: 48.961249, lng: 18.479874 },
    { lat: 48.961419, lng: 18.480393 },
    { lat: 48.96156, lng: 18.480822 },
    { lat: 48.961679, lng: 18.481487 },
    { lat: 48.961733, lng: 18.481878 },
    { lat: 48.962137, lng: 18.482739 },
    { lat: 48.962546, lng: 18.483848 },
    { lat: 48.962586, lng: 18.484024 },
    { lat: 48.962618, lng: 18.484937 },
    { lat: 48.962612, lng: 18.485642 },
    { lat: 48.962724, lng: 18.486007 },
    { lat: 48.962876, lng: 18.486603 },
    { lat: 48.962943, lng: 18.486923 },
    { lat: 48.963013, lng: 18.487378 },
    { lat: 48.963137, lng: 18.487725 },
    { lat: 48.963287, lng: 18.488459 },
    { lat: 48.96384, lng: 18.489549 },
    { lat: 48.964079, lng: 18.490466 },
    { lat: 48.963939, lng: 18.491272 },
    { lat: 48.963892, lng: 18.492304 },
    { lat: 48.963794, lng: 18.492984 },
    { lat: 48.963763, lng: 18.494276 },
    { lat: 48.9637085, lng: 18.4945594 },
    { lat: 48.963656, lng: 18.494751 },
    { lat: 48.963541, lng: 18.495873 },
    { lat: 48.963535, lng: 18.497191 },
    { lat: 48.964135, lng: 18.497734 },
    { lat: 48.964981, lng: 18.498622 },
    { lat: 48.965266, lng: 18.499285 },
    { lat: 48.965566, lng: 18.4997 },
    { lat: 48.965911, lng: 18.500031 },
    { lat: 48.966539, lng: 18.501182 },
    { lat: 48.966961, lng: 18.501631 },
    { lat: 48.967282, lng: 18.501999 },
    { lat: 48.967633, lng: 18.502051 },
    { lat: 48.968111, lng: 18.502219 },
    { lat: 48.968619, lng: 18.502909 },
    { lat: 48.969049, lng: 18.503296 },
    { lat: 48.969359, lng: 18.503432 },
    { lat: 48.970078, lng: 18.504267 },
    { lat: 48.97021, lng: 18.504654 },
    { lat: 48.970634, lng: 18.50559 },
    { lat: 48.971033, lng: 18.506223 },
    { lat: 48.97198, lng: 18.50603 },
    { lat: 48.972718, lng: 18.506042 },
    { lat: 48.97288, lng: 18.506066 },
    { lat: 48.973349, lng: 18.506098 },
    { lat: 48.973705, lng: 18.506337 },
    { lat: 48.974045, lng: 18.506917 },
    { lat: 48.974248, lng: 18.507263 },
    { lat: 48.974401, lng: 18.507397 },
    { lat: 48.974534, lng: 18.507514 },
    { lat: 48.975325, lng: 18.508174 },
    { lat: 48.97563, lng: 18.508259 },
    { lat: 48.9761, lng: 18.508197 },
    { lat: 48.976629, lng: 18.50848 },
    { lat: 48.976687, lng: 18.508487 },
    { lat: 48.976788, lng: 18.5085 },
    { lat: 48.976822, lng: 18.508504 },
    { lat: 48.9770075, lng: 18.5085268 },
    { lat: 48.977205, lng: 18.508551 },
    { lat: 48.978121, lng: 18.508712 },
    { lat: 48.97896, lng: 18.508882 },
    { lat: 48.979362, lng: 18.509017 },
    { lat: 48.980162, lng: 18.509181 },
    { lat: 48.980549, lng: 18.509281 },
    { lat: 48.980991, lng: 18.50932 },
    { lat: 48.981094, lng: 18.509351 },
    { lat: 48.981392, lng: 18.509069 },
    { lat: 48.981476, lng: 18.508988 },
    { lat: 48.981907, lng: 18.509071 },
    { lat: 48.982376, lng: 18.509481 },
    { lat: 48.982753, lng: 18.510177 },
    { lat: 48.982895, lng: 18.510639 },
    { lat: 48.982996, lng: 18.510773 },
    { lat: 48.983206, lng: 18.510873 },
    { lat: 48.983289, lng: 18.511648 },
    { lat: 48.983551, lng: 18.512289 },
    { lat: 48.983606, lng: 18.51252 },
    { lat: 48.983889, lng: 18.513141 },
    { lat: 48.984255, lng: 18.513453 },
    { lat: 48.9844, lng: 18.513849 },
    { lat: 48.984696, lng: 18.514572 },
    { lat: 48.985189, lng: 18.515699 },
    { lat: 48.985386, lng: 18.51662 },
    { lat: 48.98577, lng: 18.518026 },
    { lat: 48.985879, lng: 18.51846 },
    { lat: 48.98598, lng: 18.51876 },
    { lat: 48.986195, lng: 18.519428 },
    { lat: 48.986675, lng: 18.520478 },
    { lat: 48.987005, lng: 18.521188 },
    { lat: 48.987076, lng: 18.521938 },
    { lat: 48.987558, lng: 18.523128 },
    { lat: 48.988058, lng: 18.523685 },
    { lat: 48.988516, lng: 18.524896 },
    { lat: 48.989045, lng: 18.525544 },
    { lat: 48.989233, lng: 18.526461 },
    { lat: 48.989415, lng: 18.527061 },
    { lat: 48.9903448, lng: 18.5262258 },
    { lat: 48.9902403, lng: 18.5262955 },
    { lat: 48.990327, lng: 18.5262092 },
    { lat: 48.9909112, lng: 18.52567 },
    { lat: 48.992474, lng: 18.5266764 },
    { lat: 48.994175, lng: 18.5276541 },
    { lat: 48.9945772, lng: 18.5270629 },
    { lat: 48.9949008, lng: 18.5262521 },
    { lat: 48.9955387, lng: 18.5245625 },
    { lat: 48.9955904, lng: 18.5243912 },
    { lat: 48.9958091, lng: 18.5239115 },
    { lat: 48.9961703, lng: 18.5235245 },
    { lat: 48.9964681, lng: 18.5230668 },
    { lat: 48.9966703, lng: 18.5225323 },
    { lat: 48.9969017, lng: 18.5219577 },
    { lat: 48.9975799, lng: 18.5211333 },
    { lat: 48.9983202, lng: 18.5201227 },
    { lat: 48.9989245, lng: 18.5198475 },
    { lat: 48.9991687, lng: 18.5198382 },
    { lat: 48.9994938, lng: 18.5200278 },
    { lat: 48.9999186, lng: 18.5200025 },
    { lat: 49.0003602, lng: 18.5201993 },
    { lat: 49.001285, lng: 18.5199687 },
    { lat: 49.0017621, lng: 18.519781 },
    { lat: 49.0024075, lng: 18.5193707 },
    { lat: 49.0028478, lng: 18.5191504 },
    { lat: 49.003186, lng: 18.5183995 },
    { lat: 49.0035177, lng: 18.5179676 },
    { lat: 49.0038281, lng: 18.5176376 },
    { lat: 49.0041289, lng: 18.5174044 },
    { lat: 49.0047001, lng: 18.5170184 },
    { lat: 49.004749, lng: 18.5170048 },
    { lat: 49.0054283, lng: 18.5168231 },
    { lat: 49.0056424, lng: 18.5167783 },
    { lat: 49.0056959, lng: 18.5167667 },
    { lat: 49.0060892, lng: 18.5165733 },
    { lat: 49.0066924, lng: 18.516154 },
    { lat: 49.0069812, lng: 18.5161764 },
    { lat: 49.0071668, lng: 18.5161881 },
    { lat: 49.0074507, lng: 18.5160989 },
    { lat: 49.0080407, lng: 18.5155397 },
    { lat: 49.0083594, lng: 18.5154288 },
    { lat: 49.0084672, lng: 18.5153556 },
    { lat: 49.0087957, lng: 18.5151325 },
    { lat: 49.0090607, lng: 18.5150481 },
    { lat: 49.0092621, lng: 18.5147497 },
    { lat: 49.0095679, lng: 18.5140067 },
    { lat: 49.009738, lng: 18.5137938 },
    { lat: 49.0101114, lng: 18.5133197 },
    { lat: 49.0103005, lng: 18.5127514 },
    { lat: 49.0103141, lng: 18.5126721 },
    { lat: 49.0103427, lng: 18.5125138 },
    { lat: 49.0120551, lng: 18.5119755 },
    { lat: 49.0130345, lng: 18.5118596 },
    { lat: 49.0133686, lng: 18.5116152 },
    { lat: 49.0136598, lng: 18.5116804 },
    { lat: 49.0140236, lng: 18.51163 },
    { lat: 49.0143485, lng: 18.5114443 },
    { lat: 49.0147202, lng: 18.51164 },
    { lat: 49.0149638, lng: 18.5117862 },
    { lat: 49.0151156, lng: 18.511817 },
    { lat: 49.0157115, lng: 18.5121781 },
    { lat: 49.0157379, lng: 18.5122048 },
    { lat: 49.0157732, lng: 18.5122327 },
    { lat: 49.0158002, lng: 18.5122537 },
    { lat: 49.0163802, lng: 18.5122565 },
    { lat: 49.017149, lng: 18.5120218 },
    { lat: 49.0178039, lng: 18.511742 },
    { lat: 49.0181806, lng: 18.5116368 },
    { lat: 49.0185569, lng: 18.5115486 },
    { lat: 49.0189389, lng: 18.5113954 },
    { lat: 49.0191342, lng: 18.5113165 },
    { lat: 49.0193454, lng: 18.5111969 },
    { lat: 49.0195055, lng: 18.5110317 },
    { lat: 49.0196756, lng: 18.5107144 },
    { lat: 49.0198481, lng: 18.5101342 },
    { lat: 49.0201309, lng: 18.5097766 },
    { lat: 49.0200145, lng: 18.5096273 },
    { lat: 49.0204586, lng: 18.5083375 },
    { lat: 49.020511, lng: 18.5081388 },
    { lat: 49.0204343, lng: 18.5080568 },
    { lat: 49.0204673, lng: 18.5076701 },
    { lat: 49.0214236, lng: 18.5041264 },
    { lat: 49.0216292, lng: 18.5034484 },
    { lat: 49.0221259, lng: 18.5027533 },
    { lat: 49.0221135, lng: 18.5026905 },
    { lat: 49.0218901, lng: 18.501615 },
    { lat: 49.0215758, lng: 18.5005883 },
    { lat: 49.021515, lng: 18.50028 },
    { lat: 49.0209851, lng: 18.4996213 },
  ],
  Udiča: [
    { lat: 49.1984283, lng: 18.4149364 },
    { lat: 49.1989595, lng: 18.4142777 },
    { lat: 49.1992632, lng: 18.4137533 },
    { lat: 49.1994102, lng: 18.4135503 },
    { lat: 49.1995016, lng: 18.4132458 },
    { lat: 49.1997428, lng: 18.4125861 },
    { lat: 49.2000017, lng: 18.4121662 },
    { lat: 49.2005645, lng: 18.4116352 },
    { lat: 49.2006375, lng: 18.4115663 },
    { lat: 49.2016603, lng: 18.4106006 },
    { lat: 49.2017242, lng: 18.4105506 },
    { lat: 49.2022063, lng: 18.4101712 },
    { lat: 49.2030681, lng: 18.4092927 },
    { lat: 49.2032191, lng: 18.4090632 },
    { lat: 49.2034271, lng: 18.4084759 },
    { lat: 49.2043264, lng: 18.4069272 },
    { lat: 49.2042756, lng: 18.4066841 },
    { lat: 49.2057731, lng: 18.4057548 },
    { lat: 49.2060274, lng: 18.405578 },
    { lat: 49.2060707, lng: 18.4055443 },
    { lat: 49.2061977, lng: 18.4056175 },
    { lat: 49.2063636, lng: 18.4056174 },
    { lat: 49.2072067, lng: 18.4054023 },
    { lat: 49.2080158, lng: 18.405307 },
    { lat: 49.2082536, lng: 18.4049954 },
    { lat: 49.2085299, lng: 18.4049935 },
    { lat: 49.2093697, lng: 18.4043682 },
    { lat: 49.2100508, lng: 18.4039148 },
    { lat: 49.2100977, lng: 18.4038665 },
    { lat: 49.2101286, lng: 18.4038344 },
    { lat: 49.2110431, lng: 18.4028861 },
    { lat: 49.2114551, lng: 18.4023378 },
    { lat: 49.2115876, lng: 18.4022076 },
    { lat: 49.2119544, lng: 18.4020053 },
    { lat: 49.2135566, lng: 18.4017658 },
    { lat: 49.2136432, lng: 18.4010698 },
    { lat: 49.2136051, lng: 18.4005206 },
    { lat: 49.2134191, lng: 18.3993396 },
    { lat: 49.2133826, lng: 18.3989821 },
    { lat: 49.2130918, lng: 18.3969437 },
    { lat: 49.2126205, lng: 18.3968687 },
    { lat: 49.2108816, lng: 18.3967555 },
    { lat: 49.2107462, lng: 18.3965283 },
    { lat: 49.2104961, lng: 18.3964948 },
    { lat: 49.2102964, lng: 18.3963929 },
    { lat: 49.2097172, lng: 18.3969136 },
    { lat: 49.2094529, lng: 18.3969471 },
    { lat: 49.2089318, lng: 18.3976817 },
    { lat: 49.2088889, lng: 18.3980733 },
    { lat: 49.2087307, lng: 18.3983105 },
    { lat: 49.2083982, lng: 18.3988643 },
    { lat: 49.2080707, lng: 18.3989016 },
    { lat: 49.2079039, lng: 18.3990053 },
    { lat: 49.207582, lng: 18.3986138 },
    { lat: 49.2068091, lng: 18.3979938 },
    { lat: 49.2067456, lng: 18.397969 },
    { lat: 49.2065775, lng: 18.3977415 },
    { lat: 49.2062461, lng: 18.3971932 },
    { lat: 49.2062725, lng: 18.3968763 },
    { lat: 49.2062791, lng: 18.3967735 },
    { lat: 49.2061245, lng: 18.3966875 },
    { lat: 49.2060253, lng: 18.3970787 },
    { lat: 49.2059982, lng: 18.3971605 },
    { lat: 49.2057397, lng: 18.3970563 },
    { lat: 49.2056171, lng: 18.3974294 },
    { lat: 49.2052982, lng: 18.3984561 },
    { lat: 49.2056247, lng: 18.3987485 },
    { lat: 49.2056746, lng: 18.400041 },
    { lat: 49.2049472, lng: 18.3998445 },
    { lat: 49.2042615, lng: 18.3994153 },
    { lat: 49.2037781, lng: 18.3992024 },
    { lat: 49.2038749, lng: 18.3990708 },
    { lat: 49.2039628, lng: 18.3989292 },
    { lat: 49.2039903, lng: 18.3988537 },
    { lat: 49.2044026, lng: 18.3983974 },
    { lat: 49.2038176, lng: 18.3979739 },
    { lat: 49.2037944, lng: 18.3979646 },
    { lat: 49.2033362, lng: 18.3976556 },
    { lat: 49.2026333, lng: 18.3982639 },
    { lat: 49.2025904, lng: 18.398447 },
    { lat: 49.2025709, lng: 18.3985047 },
    { lat: 49.2024597, lng: 18.3989391 },
    { lat: 49.2017504, lng: 18.3985774 },
    { lat: 49.2016263, lng: 18.3985977 },
    { lat: 49.2016239, lng: 18.3985926 },
    { lat: 49.2014814, lng: 18.3983635 },
    { lat: 49.2014073, lng: 18.398348 },
    { lat: 49.2012374, lng: 18.3985201 },
    { lat: 49.2012475, lng: 18.3985794 },
    { lat: 49.2012764, lng: 18.3987551 },
    { lat: 49.2009707, lng: 18.3988565 },
    { lat: 49.2009334, lng: 18.3993075 },
    { lat: 49.2009302, lng: 18.3993523 },
    { lat: 49.2008553, lng: 18.4003245 },
    { lat: 49.2003676, lng: 18.4001733 },
    { lat: 49.2000479, lng: 18.3996099 },
    { lat: 49.1998234, lng: 18.3991031 },
    { lat: 49.199595, lng: 18.3985401 },
    { lat: 49.1992487, lng: 18.3976039 },
    { lat: 49.1992254, lng: 18.3975463 },
    { lat: 49.1991042, lng: 18.3976416 },
    { lat: 49.1990077, lng: 18.3977305 },
    { lat: 49.1983645, lng: 18.3982764 },
    { lat: 49.1984546, lng: 18.3961121 },
    { lat: 49.198484, lng: 18.39599 },
    { lat: 49.1985583, lng: 18.3958854 },
    { lat: 49.1986033, lng: 18.3957618 },
    { lat: 49.1986569, lng: 18.3956503 },
    { lat: 49.198672, lng: 18.3956155 },
    { lat: 49.1987569, lng: 18.3955384 },
    { lat: 49.199121, lng: 18.3957204 },
    { lat: 49.1993039, lng: 18.395724 },
    { lat: 49.1993469, lng: 18.3957435 },
    { lat: 49.1993811, lng: 18.3957898 },
    { lat: 49.1994586, lng: 18.3959688 },
    { lat: 49.1995386, lng: 18.39607 },
    { lat: 49.1997968, lng: 18.3962287 },
    { lat: 49.199901, lng: 18.3963392 },
    { lat: 49.200019, lng: 18.3965379 },
    { lat: 49.1999847, lng: 18.3965926 },
    { lat: 49.2003383, lng: 18.3972926 },
    { lat: 49.200538, lng: 18.3970268 },
    { lat: 49.2005665, lng: 18.3969748 },
    { lat: 49.2007395, lng: 18.3966737 },
    { lat: 49.2000357, lng: 18.3956323 },
    { lat: 49.2002243, lng: 18.3953323 },
    { lat: 49.2006606, lng: 18.3946551 },
    { lat: 49.2006514, lng: 18.3946094 },
    { lat: 49.2003444, lng: 18.393443 },
    { lat: 49.2002956, lng: 18.3934576 },
    { lat: 49.2001892, lng: 18.3934915 },
    { lat: 49.1991568, lng: 18.3939162 },
    { lat: 49.1989279, lng: 18.3941639 },
    { lat: 49.1984104, lng: 18.3947193 },
    { lat: 49.1983095, lng: 18.3948347 },
    { lat: 49.1981169, lng: 18.394776 },
    { lat: 49.1980083, lng: 18.3947306 },
    { lat: 49.1977723, lng: 18.394726 },
    { lat: 49.1975745, lng: 18.3945769 },
    { lat: 49.1973815, lng: 18.3941674 },
    { lat: 49.1973555, lng: 18.3938169 },
    { lat: 49.1973035, lng: 18.3935096 },
    { lat: 49.1971235, lng: 18.3933357 },
    { lat: 49.1971004, lng: 18.3933253 },
    { lat: 49.1969137, lng: 18.3928252 },
    { lat: 49.1967553, lng: 18.3922483 },
    { lat: 49.196596, lng: 18.3919745 },
    { lat: 49.1965618, lng: 18.3919717 },
    { lat: 49.196403, lng: 18.3919348 },
    { lat: 49.1963458, lng: 18.3919375 },
    { lat: 49.1962225, lng: 18.3918325 },
    { lat: 49.1961769, lng: 18.391658 },
    { lat: 49.1962188, lng: 18.3915015 },
    { lat: 49.1962434, lng: 18.3913937 },
    { lat: 49.1962704, lng: 18.3911475 },
    { lat: 49.1961956, lng: 18.3911027 },
    { lat: 49.1958976, lng: 18.3910141 },
    { lat: 49.1956255, lng: 18.3909921 },
    { lat: 49.1952736, lng: 18.3908868 },
    { lat: 49.1948652, lng: 18.3906835 },
    { lat: 49.1945435, lng: 18.3904366 },
    { lat: 49.194363, lng: 18.3901904 },
    { lat: 49.1942544, lng: 18.3901025 },
    { lat: 49.1941358, lng: 18.3900824 },
    { lat: 49.1939746, lng: 18.3900619 },
    { lat: 49.1939312, lng: 18.3900932 },
    { lat: 49.1938033, lng: 18.3900675 },
    { lat: 49.1937221, lng: 18.3899835 },
    { lat: 49.1931602, lng: 18.3891664 },
    { lat: 49.1930387, lng: 18.3889689 },
    { lat: 49.1929303, lng: 18.3888036 },
    { lat: 49.192794, lng: 18.3885637 },
    { lat: 49.1925589, lng: 18.3882679 },
    { lat: 49.1924294, lng: 18.3881086 },
    { lat: 49.1923491, lng: 18.3881873 },
    { lat: 49.1919008, lng: 18.3886892 },
    { lat: 49.1915783, lng: 18.3880981 },
    { lat: 49.191347, lng: 18.3883638 },
    { lat: 49.1909591, lng: 18.3877755 },
    { lat: 49.1909012, lng: 18.3879938 },
    { lat: 49.1893286, lng: 18.3876242 },
    { lat: 49.1892941, lng: 18.3876126 },
    { lat: 49.1883979, lng: 18.386931 },
    { lat: 49.1883177, lng: 18.3869076 },
    { lat: 49.1877728, lng: 18.3868857 },
    { lat: 49.187015, lng: 18.3868318 },
    { lat: 49.1864936, lng: 18.386764 },
    { lat: 49.1864556, lng: 18.3867723 },
    { lat: 49.186207, lng: 18.3867033 },
    { lat: 49.1860357, lng: 18.3866655 },
    { lat: 49.1858111, lng: 18.386642 },
    { lat: 49.1855698, lng: 18.3866635 },
    { lat: 49.1853516, lng: 18.3866761 },
    { lat: 49.1850912, lng: 18.3865562 },
    { lat: 49.1849178, lng: 18.3863371 },
    { lat: 49.184519, lng: 18.3858791 },
    { lat: 49.1845178, lng: 18.3858777 },
    { lat: 49.1843774, lng: 18.3861352 },
    { lat: 49.1843511, lng: 18.3861072 },
    { lat: 49.1843059, lng: 18.3860451 },
    { lat: 49.1842801, lng: 18.3860064 },
    { lat: 49.1840734, lng: 18.3857373 },
    { lat: 49.1839276, lng: 18.3855475 },
    { lat: 49.1835582, lng: 18.3850699 },
    { lat: 49.1836954, lng: 18.3850031 },
    { lat: 49.1837514, lng: 18.3849317 },
    { lat: 49.1839055, lng: 18.3848221 },
    { lat: 49.1839154, lng: 18.3848608 },
    { lat: 49.1843724, lng: 18.3844951 },
    { lat: 49.1842741, lng: 18.3843289 },
    { lat: 49.1842454, lng: 18.3842808 },
    { lat: 49.184183, lng: 18.3843741 },
    { lat: 49.1841551, lng: 18.3843406 },
    { lat: 49.1838332, lng: 18.3839252 },
    { lat: 49.1836671, lng: 18.3837151 },
    { lat: 49.1836645, lng: 18.38371 },
    { lat: 49.1836442, lng: 18.3836686 },
    { lat: 49.1836891, lng: 18.3835011 },
    { lat: 49.1836142, lng: 18.383353 },
    { lat: 49.1835657, lng: 18.3832489 },
    { lat: 49.1835334, lng: 18.3831773 },
    { lat: 49.183532, lng: 18.38317 },
    { lat: 49.1835206, lng: 18.3831274 },
    { lat: 49.1834766, lng: 18.3828832 },
    { lat: 49.1836832, lng: 18.3826409 },
    { lat: 49.1838098, lng: 18.3823405 },
    { lat: 49.1838815, lng: 18.3821888 },
    { lat: 49.1839223, lng: 18.3819085 },
    { lat: 49.1839962, lng: 18.3817562 },
    { lat: 49.1840497, lng: 18.3816429 },
    { lat: 49.1842583, lng: 18.3814168 },
    { lat: 49.1842723, lng: 18.3813825 },
    { lat: 49.1842051, lng: 18.3813923 },
    { lat: 49.1841594, lng: 18.3813823 },
    { lat: 49.1838869, lng: 18.3810658 },
    { lat: 49.1838241, lng: 18.3809903 },
    { lat: 49.1838086, lng: 18.3809144 },
    { lat: 49.1838087, lng: 18.3807124 },
    { lat: 49.1838364, lng: 18.3803789 },
    { lat: 49.1838644, lng: 18.3801499 },
    { lat: 49.1838978, lng: 18.3799594 },
    { lat: 49.1839199, lng: 18.3797892 },
    { lat: 49.1839101, lng: 18.3794286 },
    { lat: 49.1838646, lng: 18.3790744 },
    { lat: 49.1837715, lng: 18.3781493 },
    { lat: 49.1836765, lng: 18.3774126 },
    { lat: 49.1836251, lng: 18.3770334 },
    { lat: 49.183621, lng: 18.3769585 },
    { lat: 49.1835885, lng: 18.3766995 },
    { lat: 49.1835163, lng: 18.3763521 },
    { lat: 49.1836504, lng: 18.3760031 },
    { lat: 49.1836207, lng: 18.3758564 },
    { lat: 49.1836046, lng: 18.3757082 },
    { lat: 49.1835842, lng: 18.3755641 },
    { lat: 49.1835628, lng: 18.3754212 },
    { lat: 49.1834876, lng: 18.3752059 },
    { lat: 49.1833669, lng: 18.3748996 },
    { lat: 49.1833292, lng: 18.3747896 },
    { lat: 49.1833015, lng: 18.3746782 },
    { lat: 49.1832765, lng: 18.3744491 },
    { lat: 49.1832562, lng: 18.3741252 },
    { lat: 49.1832143, lng: 18.3737969 },
    { lat: 49.1831636, lng: 18.3734515 },
    { lat: 49.1831337, lng: 18.3732785 },
    { lat: 49.1830901, lng: 18.3731022 },
    { lat: 49.1830515, lng: 18.3728749 },
    { lat: 49.1829333, lng: 18.3724319 },
    { lat: 49.182856, lng: 18.372197 },
    { lat: 49.1828493, lng: 18.3721759 },
    { lat: 49.1827871, lng: 18.3720331 },
    { lat: 49.1826969, lng: 18.3718501 },
    { lat: 49.1825804, lng: 18.3716828 },
    { lat: 49.1824613, lng: 18.3715489 },
    { lat: 49.1822729, lng: 18.3714068 },
    { lat: 49.182208, lng: 18.3713337 },
    { lat: 49.1821521, lng: 18.3712825 },
    { lat: 49.1820456, lng: 18.3711522 },
    { lat: 49.1818888, lng: 18.3709884 },
    { lat: 49.1816695, lng: 18.3708183 },
    { lat: 49.1815468, lng: 18.3707224 },
    { lat: 49.1813484, lng: 18.3706025 },
    { lat: 49.1809146, lng: 18.3701452 },
    { lat: 49.1806707, lng: 18.3697938 },
    { lat: 49.1804513, lng: 18.3694996 },
    { lat: 49.1799866, lng: 18.3686058 },
    { lat: 49.1798705, lng: 18.3684054 },
    { lat: 49.1797498, lng: 18.3682041 },
    { lat: 49.1795391, lng: 18.3679632 },
    { lat: 49.1795184, lng: 18.3679143 },
    { lat: 49.1794953, lng: 18.3678847 },
    { lat: 49.1793746, lng: 18.3676834 },
    { lat: 49.1791264, lng: 18.3673175 },
    { lat: 49.1788588, lng: 18.3668037 },
    { lat: 49.1788382, lng: 18.3668399 },
    { lat: 49.1785994, lng: 18.3673537 },
    { lat: 49.1782258, lng: 18.3670241 },
    { lat: 49.1771774, lng: 18.3662879 },
    { lat: 49.1767242, lng: 18.365968 },
    { lat: 49.176701, lng: 18.3659544 },
    { lat: 49.1766353, lng: 18.3659092 },
    { lat: 49.1759345, lng: 18.3651542 },
    { lat: 49.1758989, lng: 18.365121 },
    { lat: 49.175694, lng: 18.3649061 },
    { lat: 49.1744759, lng: 18.363955 },
    { lat: 49.1740179, lng: 18.3636508 },
    { lat: 49.1739871, lng: 18.3636454 },
    { lat: 49.173349, lng: 18.3635092 },
    { lat: 49.1732025, lng: 18.3634735 },
    { lat: 49.1729003, lng: 18.3634145 },
    { lat: 49.1727639, lng: 18.3634163 },
    { lat: 49.1726984, lng: 18.3633937 },
    { lat: 49.1724811, lng: 18.3631599 },
    { lat: 49.1724688, lng: 18.3631407 },
    { lat: 49.1721967, lng: 18.362689 },
    { lat: 49.1721731, lng: 18.3626699 },
    { lat: 49.1720376, lng: 18.3622429 },
    { lat: 49.1717842, lng: 18.3616816 },
    { lat: 49.1714458, lng: 18.3607284 },
    { lat: 49.170693, lng: 18.3590382 },
    { lat: 49.1704109, lng: 18.3592802 },
    { lat: 49.1704245, lng: 18.359199 },
    { lat: 49.1704594, lng: 18.3589971 },
    { lat: 49.1705362, lng: 18.3587539 },
    { lat: 49.170604, lng: 18.3584725 },
    { lat: 49.170702, lng: 18.358073 },
    { lat: 49.170677, lng: 18.358055 },
    { lat: 49.17031, lng: 18.358085 },
    { lat: 49.169433, lng: 18.357768 },
    { lat: 49.16915, lng: 18.357566 },
    { lat: 49.168553, lng: 18.357532 },
    { lat: 49.166846, lng: 18.356273 },
    { lat: 49.166444, lng: 18.355944 },
    { lat: 49.166017, lng: 18.355705 },
    { lat: 49.165251, lng: 18.355502 },
    { lat: 49.164436, lng: 18.355679 },
    { lat: 49.161914, lng: 18.35625 },
    { lat: 49.161834, lng: 18.356357 },
    { lat: 49.161661, lng: 18.356601 },
    { lat: 49.161629, lng: 18.356683 },
    { lat: 49.161481, lng: 18.356896 },
    { lat: 49.160725, lng: 18.358117 },
    { lat: 49.160474, lng: 18.358512 },
    { lat: 49.160377, lng: 18.358686 },
    { lat: 49.159755, lng: 18.35996 },
    { lat: 49.159952, lng: 18.360312 },
    { lat: 49.160096, lng: 18.360522 },
    { lat: 49.16003, lng: 18.360924 },
    { lat: 49.159984, lng: 18.361246 },
    { lat: 49.159891, lng: 18.361801 },
    { lat: 49.159826, lng: 18.362095 },
    { lat: 49.159693, lng: 18.362435 },
    { lat: 49.159596, lng: 18.362638 },
    { lat: 49.159567, lng: 18.362719 },
    { lat: 49.15944, lng: 18.362912 },
    { lat: 49.159248, lng: 18.363115 },
    { lat: 49.159018, lng: 18.363282 },
    { lat: 49.158766, lng: 18.363431 },
    { lat: 49.158613, lng: 18.363588 },
    { lat: 49.158453, lng: 18.363828 },
    { lat: 49.158263, lng: 18.364072 },
    { lat: 49.158005, lng: 18.364347 },
    { lat: 49.157863, lng: 18.364529 },
    { lat: 49.157586, lng: 18.364853 },
    { lat: 49.157383, lng: 18.364938 },
    { lat: 49.157195, lng: 18.365152 },
    { lat: 49.157025, lng: 18.365352 },
    { lat: 49.156867, lng: 18.365455 },
    { lat: 49.156582, lng: 18.365573 },
    { lat: 49.156319, lng: 18.3657 },
    { lat: 49.156089, lng: 18.365773 },
    { lat: 49.155812, lng: 18.365984 },
    { lat: 49.155546, lng: 18.366217 },
    { lat: 49.155303, lng: 18.366437 },
    { lat: 49.155162, lng: 18.366573 },
    { lat: 49.154868, lng: 18.366852 },
    { lat: 49.154718, lng: 18.366974 },
    { lat: 49.154339, lng: 18.367123 },
    { lat: 49.153958, lng: 18.366182 },
    { lat: 49.153073, lng: 18.366492 },
    { lat: 49.152699, lng: 18.366468 },
    { lat: 49.152572, lng: 18.366413 },
    { lat: 49.152416, lng: 18.366849 },
    { lat: 49.152297, lng: 18.366666 },
    { lat: 49.152183, lng: 18.366884 },
    { lat: 49.151972, lng: 18.367655 },
    { lat: 49.151969, lng: 18.367732 },
    { lat: 49.151945, lng: 18.36812 },
    { lat: 49.150991, lng: 18.367983 },
    { lat: 49.150745, lng: 18.367972 },
    { lat: 49.150772, lng: 18.368358 },
    { lat: 49.150283, lng: 18.368442 },
    { lat: 49.149272, lng: 18.368647 },
    { lat: 49.148967, lng: 18.36846 },
    { lat: 49.147648, lng: 18.368203 },
    { lat: 49.14658, lng: 18.368021 },
    { lat: 49.145766, lng: 18.368066 },
    { lat: 49.143696, lng: 18.367224 },
    { lat: 49.143334, lng: 18.367141 },
    { lat: 49.1426, lng: 18.367476 },
    { lat: 49.142444, lng: 18.36749 },
    { lat: 49.142343, lng: 18.367183 },
    { lat: 49.142321, lng: 18.367213 },
    { lat: 49.142257, lng: 18.367323 },
    { lat: 49.141398, lng: 18.368693 },
    { lat: 49.140746, lng: 18.369856 },
    { lat: 49.139966, lng: 18.37005 },
    { lat: 49.139596, lng: 18.370029 },
    { lat: 49.139583, lng: 18.370248 },
    { lat: 49.139473, lng: 18.373724 },
    { lat: 49.139472, lng: 18.373785 },
    { lat: 49.139434, lng: 18.375048 },
    { lat: 49.139372, lng: 18.375277 },
    { lat: 49.139282, lng: 18.376354 },
    { lat: 49.1402005, lng: 18.3764172 },
    { lat: 49.1410478, lng: 18.37652 },
    { lat: 49.1417423, lng: 18.3767787 },
    { lat: 49.1419862, lng: 18.3770056 },
    { lat: 49.1420859, lng: 18.3770947 },
    { lat: 49.1422344, lng: 18.3772294 },
    { lat: 49.1422862, lng: 18.3773096 },
    { lat: 49.1438475, lng: 18.3785287 },
    { lat: 49.1439164, lng: 18.3786921 },
    { lat: 49.1441142, lng: 18.3788828 },
    { lat: 49.1443767, lng: 18.3791833 },
    { lat: 49.145158, lng: 18.3788102 },
    { lat: 49.1466955, lng: 18.3791133 },
    { lat: 49.1484756, lng: 18.3794596 },
    { lat: 49.1491714, lng: 18.3795951 },
    { lat: 49.1496705, lng: 18.379809 },
    { lat: 49.1496832, lng: 18.379752 },
    { lat: 49.1497475, lng: 18.3798753 },
    { lat: 49.1498425, lng: 18.3800036 },
    { lat: 49.1499124, lng: 18.3800822 },
    { lat: 49.1500019, lng: 18.3801115 },
    { lat: 49.1500902, lng: 18.3801342 },
    { lat: 49.1502568, lng: 18.3801075 },
    { lat: 49.1503592, lng: 18.3802469 },
    { lat: 49.150453, lng: 18.3803509 },
    { lat: 49.15061, lng: 18.3804557 },
    { lat: 49.1507882, lng: 18.3804774 },
    { lat: 49.1508934, lng: 18.3805633 },
    { lat: 49.1509495, lng: 18.3806799 },
    { lat: 49.1510371, lng: 18.3807538 },
    { lat: 49.1513496, lng: 18.3809366 },
    { lat: 49.1514678, lng: 18.3810538 },
    { lat: 49.1516536, lng: 18.3811901 },
    { lat: 49.1517215, lng: 18.3812365 },
    { lat: 49.1517736, lng: 18.3812589 },
    { lat: 49.1518973, lng: 18.3813136 },
    { lat: 49.1520526, lng: 18.3813877 },
    { lat: 49.1524309, lng: 18.381579 },
    { lat: 49.1525382, lng: 18.3816012 },
    { lat: 49.1526256, lng: 18.3817741 },
    { lat: 49.1525931, lng: 18.382083 },
    { lat: 49.1525978, lng: 18.382168 },
    { lat: 49.1526449, lng: 18.3822848 },
    { lat: 49.1527185, lng: 18.3823704 },
    { lat: 49.1528103, lng: 18.3824008 },
    { lat: 49.1530767, lng: 18.3822589 },
    { lat: 49.153124, lng: 18.3822996 },
    { lat: 49.1531555, lng: 18.3823592 },
    { lat: 49.1531859, lng: 18.3824787 },
    { lat: 49.1530981, lng: 18.3827068 },
    { lat: 49.153085, lng: 18.3827768 },
    { lat: 49.1531368, lng: 18.38308 },
    { lat: 49.1532703, lng: 18.3834542 },
    { lat: 49.1534082, lng: 18.383743 },
    { lat: 49.1534948, lng: 18.3838814 },
    { lat: 49.1535666, lng: 18.3840372 },
    { lat: 49.1536038, lng: 18.3842402 },
    { lat: 49.153641, lng: 18.384342 },
    { lat: 49.1538256, lng: 18.3846815 },
    { lat: 49.1540199, lng: 18.3851548 },
    { lat: 49.1540498, lng: 18.3852461 },
    { lat: 49.1541049, lng: 18.3853233 },
    { lat: 49.1541196, lng: 18.3854055 },
    { lat: 49.1542481, lng: 18.3857481 },
    { lat: 49.1543643, lng: 18.3859725 },
    { lat: 49.1544102, lng: 18.3861313 },
    { lat: 49.1544707, lng: 18.3863871 },
    { lat: 49.1545063, lng: 18.3865606 },
    { lat: 49.1545638, lng: 18.3868051 },
    { lat: 49.154686, lng: 18.3871591 },
    { lat: 49.1547274, lng: 18.3873778 },
    { lat: 49.1547744, lng: 18.3875141 },
    { lat: 49.154794, lng: 18.3876633 },
    { lat: 49.1547644, lng: 18.3878004 },
    { lat: 49.1547941, lng: 18.3878878 },
    { lat: 49.1548215, lng: 18.3879754 },
    { lat: 49.1548151, lng: 18.3881453 },
    { lat: 49.1548397, lng: 18.3882638 },
    { lat: 49.154842, lng: 18.3883674 },
    { lat: 49.1549266, lng: 18.3886094 },
    { lat: 49.1549854, lng: 18.3886935 },
    { lat: 49.1550006, lng: 18.3887447 },
    { lat: 49.1549777, lng: 18.3888621 },
    { lat: 49.1550296, lng: 18.3889233 },
    { lat: 49.1551401, lng: 18.3889827 },
    { lat: 49.1552617, lng: 18.38908 },
    { lat: 49.1552924, lng: 18.3892081 },
    { lat: 49.1553663, lng: 18.3897679 },
    { lat: 49.1553466, lng: 18.3898805 },
    { lat: 49.1553032, lng: 18.389994 },
    { lat: 49.1553376, lng: 18.3901237 },
    { lat: 49.1553741, lng: 18.3901534 },
    { lat: 49.1554854, lng: 18.3901684 },
    { lat: 49.1555114, lng: 18.390208 },
    { lat: 49.1554934, lng: 18.3903945 },
    { lat: 49.1555328, lng: 18.3905332 },
    { lat: 49.1554489, lng: 18.390671 },
    { lat: 49.1553983, lng: 18.3908336 },
    { lat: 49.1553653, lng: 18.3909523 },
    { lat: 49.1552414, lng: 18.3910976 },
    { lat: 49.1552496, lng: 18.3912054 },
    { lat: 49.1552608, lng: 18.3912459 },
    { lat: 49.1553129, lng: 18.3912495 },
    { lat: 49.1553563, lng: 18.3912991 },
    { lat: 49.1554267, lng: 18.3913686 },
    { lat: 49.155563, lng: 18.3914865 },
    { lat: 49.1560784, lng: 18.3917295 },
    { lat: 49.1561857, lng: 18.3917526 },
    { lat: 49.1562932, lng: 18.3917177 },
    { lat: 49.1563132, lng: 18.3917517 },
    { lat: 49.1563306, lng: 18.3917821 },
    { lat: 49.1563711, lng: 18.3917794 },
    { lat: 49.1564259, lng: 18.3918545 },
    { lat: 49.1564603, lng: 18.3918627 },
    { lat: 49.1565771, lng: 18.3917925 },
    { lat: 49.1566706, lng: 18.3916881 },
    { lat: 49.1567536, lng: 18.3916588 },
    { lat: 49.1568535, lng: 18.3916916 },
    { lat: 49.1568793, lng: 18.3917081 },
    { lat: 49.1569271, lng: 18.3917367 },
    { lat: 49.1570379, lng: 18.3919045 },
    { lat: 49.1571205, lng: 18.3921113 },
    { lat: 49.1576343, lng: 18.3935898 },
    { lat: 49.1578346, lng: 18.3941733 },
    { lat: 49.1581303, lng: 18.3948482 },
    { lat: 49.1582244, lng: 18.3950803 },
    { lat: 49.1582969, lng: 18.3954732 },
    { lat: 49.1584182, lng: 18.3958122 },
    { lat: 49.1584071, lng: 18.3958357 },
    { lat: 49.1583885, lng: 18.3958667 },
    { lat: 49.1583327, lng: 18.3959586 },
    { lat: 49.1582665, lng: 18.3960675 },
    { lat: 49.1582191, lng: 18.3961478 },
    { lat: 49.1581549, lng: 18.3962519 },
    { lat: 49.1581147, lng: 18.3963204 },
    { lat: 49.1580723, lng: 18.3963912 },
    { lat: 49.1579723, lng: 18.3965592 },
    { lat: 49.1577628, lng: 18.3964434 },
    { lat: 49.1575567, lng: 18.3964907 },
    { lat: 49.1575401, lng: 18.3965177 },
    { lat: 49.1574995, lng: 18.3965796 },
    { lat: 49.157458, lng: 18.3966446 },
    { lat: 49.1573719, lng: 18.3967813 },
    { lat: 49.1570519, lng: 18.3977856 },
    { lat: 49.1567171, lng: 18.39879 },
    { lat: 49.1566344, lng: 18.3989457 },
    { lat: 49.157212, lng: 18.3998057 },
    { lat: 49.1576844, lng: 18.4003105 },
    { lat: 49.1576211, lng: 18.4004931 },
    { lat: 49.1576466, lng: 18.4018323 },
    { lat: 49.1576466, lng: 18.4019127 },
    { lat: 49.1576464, lng: 18.4021337 },
    { lat: 49.157647, lng: 18.4023478 },
    { lat: 49.1576374, lng: 18.4025632 },
    { lat: 49.1576142, lng: 18.4029399 },
    { lat: 49.1576104, lng: 18.4029739 },
    { lat: 49.1575958, lng: 18.4031585 },
    { lat: 49.1575879, lng: 18.4032184 },
    { lat: 49.1575765, lng: 18.4032981 },
    { lat: 49.157566, lng: 18.4033748 },
    { lat: 49.1575553, lng: 18.4034466 },
    { lat: 49.1575499, lng: 18.4034911 },
    { lat: 49.1575417, lng: 18.4035275 },
    { lat: 49.1575222, lng: 18.4036022 },
    { lat: 49.1575034, lng: 18.4036704 },
    { lat: 49.1574894, lng: 18.4037436 },
    { lat: 49.1573898, lng: 18.404022 },
    { lat: 49.1571977, lng: 18.4044244 },
    { lat: 49.1570126, lng: 18.4047929 },
    { lat: 49.1566925, lng: 18.405777 },
    { lat: 49.1565724, lng: 18.4057261 },
    { lat: 49.1561412, lng: 18.4067632 },
    { lat: 49.156104, lng: 18.4068461 },
    { lat: 49.1559389, lng: 18.4073093 },
    { lat: 49.1557434, lng: 18.4079765 },
    { lat: 49.1553423, lng: 18.4093135 },
    { lat: 49.1551919, lng: 18.4098379 },
    { lat: 49.1549851, lng: 18.410505 },
    { lat: 49.1547044, lng: 18.4109176 },
    { lat: 49.1544138, lng: 18.411397 },
    { lat: 49.1542069, lng: 18.4116944 },
    { lat: 49.154023, lng: 18.4120202 },
    { lat: 49.1533689, lng: 18.4129846 },
    { lat: 49.1530023, lng: 18.4134972 },
    { lat: 49.152819, lng: 18.4140384 },
    { lat: 49.1528326, lng: 18.4142249 },
    { lat: 49.1526751, lng: 18.4147848 },
    { lat: 49.1526878, lng: 18.4148098 },
    { lat: 49.1525319, lng: 18.4149884 },
    { lat: 49.1523425, lng: 18.415277 },
    { lat: 49.1519378, lng: 18.4158959 },
    { lat: 49.1512782, lng: 18.4168219 },
    { lat: 49.151121, lng: 18.4170393 },
    { lat: 49.1509575, lng: 18.4172255 },
    { lat: 49.1506662, lng: 18.4174682 },
    { lat: 49.1506336, lng: 18.4174888 },
    { lat: 49.1501562, lng: 18.4177705 },
    { lat: 49.15001, lng: 18.4178807 },
    { lat: 49.1496623, lng: 18.4181433 },
    { lat: 49.1492659, lng: 18.41836 },
    { lat: 49.1489319, lng: 18.418542 },
    { lat: 49.148642, lng: 18.4187055 },
    { lat: 49.1480173, lng: 18.4190586 },
    { lat: 49.1480394, lng: 18.4191715 },
    { lat: 49.1480458, lng: 18.4192063 },
    { lat: 49.148066, lng: 18.4193071 },
    { lat: 49.1480709, lng: 18.4193335 },
    { lat: 49.1480829, lng: 18.419407 },
    { lat: 49.1480861, lng: 18.4194238 },
    { lat: 49.1482848, lng: 18.4206161 },
    { lat: 49.148533, lng: 18.4215578 },
    { lat: 49.1485412, lng: 18.4215842 },
    { lat: 49.1487894, lng: 18.4226293 },
    { lat: 49.1491335, lng: 18.4239821 },
    { lat: 49.1495403, lng: 18.4249372 },
    { lat: 49.1497643, lng: 18.4256651 },
    { lat: 49.1497448, lng: 18.4262962 },
    { lat: 49.1497299, lng: 18.4266588 },
    { lat: 49.1497092, lng: 18.4273281 },
    { lat: 49.149772, lng: 18.4275889 },
    { lat: 49.1498391, lng: 18.4278666 },
    { lat: 49.1500564, lng: 18.4284123 },
    { lat: 49.1501583, lng: 18.4287672 },
    { lat: 49.150301, lng: 18.4291248 },
    { lat: 49.1504415, lng: 18.4294666 },
    { lat: 49.1505258, lng: 18.429807 },
    { lat: 49.150522, lng: 18.4301893 },
    { lat: 49.150699, lng: 18.4306399 },
    { lat: 49.150837, lng: 18.4309129 },
    { lat: 49.150864, lng: 18.4310152 },
    { lat: 49.1509589, lng: 18.4314489 },
    { lat: 49.1509894, lng: 18.4316132 },
    { lat: 49.1509144, lng: 18.4320118 },
    { lat: 49.1509377, lng: 18.4322517 },
    { lat: 49.1509147, lng: 18.4324696 },
    { lat: 49.1506794, lng: 18.4329292 },
    { lat: 49.1506679, lng: 18.4332338 },
    { lat: 49.1507686, lng: 18.4336899 },
    { lat: 49.1509819, lng: 18.4344089 },
    { lat: 49.1511541, lng: 18.4350184 },
    { lat: 49.1514036, lng: 18.4368692 },
    { lat: 49.1517805, lng: 18.4376926 },
    { lat: 49.1518982, lng: 18.438521 },
    { lat: 49.1520029, lng: 18.4392966 },
    { lat: 49.1520684, lng: 18.4397486 },
    { lat: 49.1520772, lng: 18.4398086 },
    { lat: 49.1520691, lng: 18.4399271 },
    { lat: 49.1520689, lng: 18.439943 },
    { lat: 49.1520544, lng: 18.4401533 },
    { lat: 49.1519835, lng: 18.4412629 },
    { lat: 49.1519453, lng: 18.4424585 },
    { lat: 49.1526162, lng: 18.4426585 },
    { lat: 49.1531377, lng: 18.4429939 },
    { lat: 49.1535151, lng: 18.4428825 },
    { lat: 49.1537471, lng: 18.4426724 },
    { lat: 49.1541254, lng: 18.4426396 },
    { lat: 49.154439, lng: 18.4431318 },
    { lat: 49.1548333, lng: 18.4436154 },
    { lat: 49.1553667, lng: 18.4438595 },
    { lat: 49.1556827, lng: 18.4437381 },
    { lat: 49.1561029, lng: 18.4435855 },
    { lat: 49.1564847, lng: 18.4434512 },
    { lat: 49.1572477, lng: 18.4436238 },
    { lat: 49.1576369, lng: 18.4435436 },
    { lat: 49.1580624, lng: 18.4436712 },
    { lat: 49.1587655, lng: 18.4425258 },
    { lat: 49.1592016, lng: 18.4424565 },
    { lat: 49.159532, lng: 18.4420635 },
    { lat: 49.1599414, lng: 18.4418573 },
    { lat: 49.1603515, lng: 18.4417457 },
    { lat: 49.1609754, lng: 18.4413388 },
    { lat: 49.1612062, lng: 18.4411846 },
    { lat: 49.1612812, lng: 18.4411347 },
    { lat: 49.1618656, lng: 18.4408067 },
    { lat: 49.1629515, lng: 18.4393016 },
    { lat: 49.1636457, lng: 18.4391265 },
    { lat: 49.1636739, lng: 18.4375434 },
    { lat: 49.1641659, lng: 18.4366631 },
    { lat: 49.1637768, lng: 18.435967 },
    { lat: 49.1636683, lng: 18.4352052 },
    { lat: 49.1636802, lng: 18.4338414 },
    { lat: 49.1640119, lng: 18.433535 },
    { lat: 49.1642401, lng: 18.4332937 },
    { lat: 49.1649447, lng: 18.432117 },
    { lat: 49.1649619, lng: 18.4319786 },
    { lat: 49.1652647, lng: 18.4312689 },
    { lat: 49.1653291, lng: 18.4311282 },
    { lat: 49.1653625, lng: 18.4309541 },
    { lat: 49.1654843, lng: 18.4305853 },
    { lat: 49.1656676, lng: 18.4301048 },
    { lat: 49.1658098, lng: 18.4299628 },
    { lat: 49.1662003, lng: 18.4300062 },
    { lat: 49.1663179, lng: 18.4297241 },
    { lat: 49.1665174, lng: 18.4288614 },
    { lat: 49.1666951, lng: 18.4281741 },
    { lat: 49.1667168, lng: 18.4281378 },
    { lat: 49.1670306, lng: 18.4274869 },
    { lat: 49.167415, lng: 18.4268865 },
    { lat: 49.1675745, lng: 18.4268935 },
    { lat: 49.1677545, lng: 18.4269689 },
    { lat: 49.1681369, lng: 18.4269682 },
    { lat: 49.168324, lng: 18.4270476 },
    { lat: 49.1685646, lng: 18.4271359 },
    { lat: 49.1686632, lng: 18.4271237 },
    { lat: 49.1688243, lng: 18.4270165 },
    { lat: 49.1689809, lng: 18.4267479 },
    { lat: 49.1689947, lng: 18.4267518 },
    { lat: 49.1692357, lng: 18.426293 },
    { lat: 49.1705782, lng: 18.4251282 },
    { lat: 49.1712618, lng: 18.4248019 },
    { lat: 49.1716105, lng: 18.4246592 },
    { lat: 49.1720207, lng: 18.4245095 },
    { lat: 49.1720798, lng: 18.4244362 },
    { lat: 49.1724154, lng: 18.4241803 },
    { lat: 49.1729575, lng: 18.4238021 },
    { lat: 49.1730807, lng: 18.423705 },
    { lat: 49.1734178, lng: 18.4234342 },
    { lat: 49.1738686, lng: 18.4230578 },
    { lat: 49.1742968, lng: 18.4226597 },
    { lat: 49.1746769, lng: 18.4223935 },
    { lat: 49.1752917, lng: 18.4218606 },
    { lat: 49.1753454, lng: 18.421916 },
    { lat: 49.1757898, lng: 18.4222014 },
    { lat: 49.1766551, lng: 18.4223453 },
    { lat: 49.1770518, lng: 18.4223606 },
    { lat: 49.177987, lng: 18.4222989 },
    { lat: 49.1789321, lng: 18.4222142 },
    { lat: 49.1792174, lng: 18.422072 },
    { lat: 49.1794712, lng: 18.421926 },
    { lat: 49.1798155, lng: 18.4216233 },
    { lat: 49.1809266, lng: 18.421133 },
    { lat: 49.1809828, lng: 18.4211075 },
    { lat: 49.1819478, lng: 18.4206685 },
    { lat: 49.1829338, lng: 18.4203624 },
    { lat: 49.1840458, lng: 18.4191715 },
    { lat: 49.1844286, lng: 18.4188298 },
    { lat: 49.1844232, lng: 18.4184838 },
    { lat: 49.185308, lng: 18.417552 },
    { lat: 49.1862432, lng: 18.4167343 },
    { lat: 49.1868077, lng: 18.4162499 },
    { lat: 49.1870821, lng: 18.4162362 },
    { lat: 49.1874455, lng: 18.4160965 },
    { lat: 49.1878791, lng: 18.416002 },
    { lat: 49.1882701, lng: 18.4160155 },
    { lat: 49.1883502, lng: 18.4160137 },
    { lat: 49.1884208, lng: 18.4160068 },
    { lat: 49.188858, lng: 18.4159559 },
    { lat: 49.1895384, lng: 18.4160817 },
    { lat: 49.1896866, lng: 18.4160309 },
    { lat: 49.1899596, lng: 18.415905 },
    { lat: 49.1907569, lng: 18.4151251 },
    { lat: 49.1920217, lng: 18.4137581 },
    { lat: 49.1923275, lng: 18.4136388 },
    { lat: 49.1925025, lng: 18.4136389 },
    { lat: 49.1928192, lng: 18.413716 },
    { lat: 49.1935162, lng: 18.4136533 },
    { lat: 49.193889, lng: 18.4130077 },
    { lat: 49.1943248, lng: 18.4119514 },
    { lat: 49.1944776, lng: 18.4116337 },
    { lat: 49.1945751, lng: 18.4115397 },
    { lat: 49.1953481, lng: 18.4112349 },
    { lat: 49.196413, lng: 18.4130416 },
    { lat: 49.1968266, lng: 18.4137315 },
    { lat: 49.1971638, lng: 18.4145056 },
    { lat: 49.1977298, lng: 18.4151769 },
    { lat: 49.1977658, lng: 18.4151314 },
    { lat: 49.1984283, lng: 18.4149364 },
  ],
};

export default UnitsPovažskáBystrica;
