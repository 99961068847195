const UnitsPiešťany = {
  Šípkové: [
    { lat: 48.677589, lng: 17.713741 },
    { lat: 48.677591, lng: 17.713712 },
    { lat: 48.677596, lng: 17.713699 },
    { lat: 48.677522, lng: 17.713607 },
    { lat: 48.677452, lng: 17.71352 },
    { lat: 48.677492, lng: 17.713441 },
    { lat: 48.677502, lng: 17.713392 },
    { lat: 48.677547, lng: 17.713189 },
    { lat: 48.677581, lng: 17.71302 },
    { lat: 48.677618, lng: 17.712904 },
    { lat: 48.677632, lng: 17.712849 },
    { lat: 48.677777, lng: 17.712115 },
    { lat: 48.677786, lng: 17.712086 },
    { lat: 48.677799, lng: 17.712009 },
    { lat: 48.677838, lng: 17.711789 },
    { lat: 48.677929, lng: 17.711339 },
    { lat: 48.67803, lng: 17.710901 },
    { lat: 48.678043, lng: 17.710857 },
    { lat: 48.678128, lng: 17.710562 },
    { lat: 48.678144, lng: 17.710504 },
    { lat: 48.678175, lng: 17.710407 },
    { lat: 48.678328, lng: 17.710054 },
    { lat: 48.678396, lng: 17.709891 },
    { lat: 48.678407, lng: 17.709471 },
    { lat: 48.678452, lng: 17.709199 },
    { lat: 48.678485, lng: 17.709004 },
    { lat: 48.67851, lng: 17.708849 },
    { lat: 48.67854, lng: 17.708787 },
    { lat: 48.678608, lng: 17.708641 },
    { lat: 48.678679, lng: 17.708485 },
    { lat: 48.67873, lng: 17.708384 },
    { lat: 48.678753, lng: 17.708329 },
    { lat: 48.678783, lng: 17.708274 },
    { lat: 48.678799, lng: 17.708241 },
    { lat: 48.679535, lng: 17.707454 },
    { lat: 48.679645, lng: 17.70733 },
    { lat: 48.679641, lng: 17.707238 },
    { lat: 48.679761, lng: 17.707055 },
    { lat: 48.679967, lng: 17.706729 },
    { lat: 48.680498, lng: 17.705982 },
    { lat: 48.680707, lng: 17.705685 },
    { lat: 48.681026, lng: 17.705177 },
    { lat: 48.681293, lng: 17.704679 },
    { lat: 48.681761, lng: 17.703997 },
    { lat: 48.681801, lng: 17.703854 },
    { lat: 48.682025, lng: 17.703294 },
    { lat: 48.682103, lng: 17.703093 },
    { lat: 48.682407, lng: 17.702271 },
    { lat: 48.682516, lng: 17.701974 },
    { lat: 48.682516, lng: 17.701662 },
    { lat: 48.682486, lng: 17.701548 },
    { lat: 48.682217, lng: 17.701137 },
    { lat: 48.682115, lng: 17.700838 },
    { lat: 48.682028, lng: 17.700269 },
    { lat: 48.681982, lng: 17.69999 },
    { lat: 48.681909, lng: 17.699751 },
    { lat: 48.681805, lng: 17.699504 },
    { lat: 48.681713, lng: 17.699347 },
    { lat: 48.681631, lng: 17.699272 },
    { lat: 48.681355, lng: 17.699014 },
    { lat: 48.68099, lng: 17.698857 },
    { lat: 48.680608, lng: 17.698432 },
    { lat: 48.680435, lng: 17.698258 },
    { lat: 48.680304, lng: 17.697881 },
    { lat: 48.680221, lng: 17.697764 },
    { lat: 48.679931, lng: 17.697663 },
    { lat: 48.679666, lng: 17.697522 },
    { lat: 48.67965, lng: 17.697513 },
    { lat: 48.679431, lng: 17.697301 },
    { lat: 48.679121, lng: 17.696899 },
    { lat: 48.678937, lng: 17.696695 },
    { lat: 48.678614, lng: 17.696305 },
    { lat: 48.678485, lng: 17.696111 },
    { lat: 48.678185, lng: 17.695566 },
    { lat: 48.677915, lng: 17.695141 },
    { lat: 48.677876, lng: 17.695055 },
    { lat: 48.677705, lng: 17.694665 },
    { lat: 48.677594, lng: 17.694477 },
    { lat: 48.677482, lng: 17.694325 },
    { lat: 48.677053, lng: 17.693859 },
    { lat: 48.677049, lng: 17.693844 },
    { lat: 48.67704, lng: 17.693782 },
    { lat: 48.67664, lng: 17.693908 },
    { lat: 48.676477, lng: 17.693931 },
    { lat: 48.676324, lng: 17.693887 },
    { lat: 48.676203, lng: 17.693731 },
    { lat: 48.676055, lng: 17.693409 },
    { lat: 48.675859, lng: 17.692917 },
    { lat: 48.675776, lng: 17.692744 },
    { lat: 48.6753974, lng: 17.692396 },
    { lat: 48.6749415, lng: 17.6921075 },
    { lat: 48.6742694, lng: 17.6920125 },
    { lat: 48.6735229, lng: 17.6917533 },
    { lat: 48.6729687, lng: 17.6914463 },
    { lat: 48.6727735, lng: 17.6915039 },
    { lat: 48.6720132, lng: 17.6920808 },
    { lat: 48.6715764, lng: 17.692303 },
    { lat: 48.6705442, lng: 17.6922614 },
    { lat: 48.6698319, lng: 17.6923893 },
    { lat: 48.6695425, lng: 17.6926017 },
    { lat: 48.6692089, lng: 17.6930332 },
    { lat: 48.6691158, lng: 17.693061 },
    { lat: 48.6690068, lng: 17.6930507 },
    { lat: 48.6685562, lng: 17.692684 },
    { lat: 48.667519, lng: 17.6925656 },
    { lat: 48.6673233, lng: 17.6926104 },
    { lat: 48.6659522, lng: 17.6932975 },
    { lat: 48.6659498, lng: 17.6934029 },
    { lat: 48.6653603, lng: 17.6940766 },
    { lat: 48.6648128, lng: 17.6945148 },
    { lat: 48.6644471, lng: 17.6948987 },
    { lat: 48.6638323, lng: 17.6958388 },
    { lat: 48.663381, lng: 17.6969702 },
    { lat: 48.6630646, lng: 17.6981528 },
    { lat: 48.6627111, lng: 17.698916 },
    { lat: 48.6622861, lng: 17.7000565 },
    { lat: 48.6617109, lng: 17.7009406 },
    { lat: 48.6614132, lng: 17.7015852 },
    { lat: 48.660883, lng: 17.7024679 },
    { lat: 48.6599381, lng: 17.7037641 },
    { lat: 48.6601141, lng: 17.7041982 },
    { lat: 48.660018, lng: 17.7043968 },
    { lat: 48.659625, lng: 17.7048323 },
    { lat: 48.6595064, lng: 17.704849 },
    { lat: 48.6591822, lng: 17.7053226 },
    { lat: 48.6580605, lng: 17.7056209 },
    { lat: 48.6578845, lng: 17.7057357 },
    { lat: 48.657683, lng: 17.706107 },
    { lat: 48.65739, lng: 17.7064083 },
    { lat: 48.6573829, lng: 17.7066018 },
    { lat: 48.6572025, lng: 17.7067723 },
    { lat: 48.6570889, lng: 17.7069786 },
    { lat: 48.656654, lng: 17.7072864 },
    { lat: 48.6564655, lng: 17.707559 },
    { lat: 48.6562723, lng: 17.7076491 },
    { lat: 48.6561571, lng: 17.7079061 },
    { lat: 48.65599, lng: 17.70805 },
    { lat: 48.6558875, lng: 17.7083087 },
    { lat: 48.6558965, lng: 17.7084203 },
    { lat: 48.6557701, lng: 17.7084574 },
    { lat: 48.6557385, lng: 17.7085658 },
    { lat: 48.6556757, lng: 17.7085711 },
    { lat: 48.6556328, lng: 17.7086657 },
    { lat: 48.6553751, lng: 17.7087342 },
    { lat: 48.6552785, lng: 17.7091231 },
    { lat: 48.6550382, lng: 17.7091277 },
    { lat: 48.6549777, lng: 17.7091898 },
    { lat: 48.6549876, lng: 17.7093498 },
    { lat: 48.6548629, lng: 17.7094685 },
    { lat: 48.6547516, lng: 17.7094581 },
    { lat: 48.654596, lng: 17.7095525 },
    { lat: 48.6542674, lng: 17.7099149 },
    { lat: 48.6539834, lng: 17.7100371 },
    { lat: 48.6538465, lng: 17.7102132 },
    { lat: 48.6537777, lng: 17.7105771 },
    { lat: 48.6536965, lng: 17.7107635 },
    { lat: 48.6534508, lng: 17.7111181 },
    { lat: 48.6533435, lng: 17.7113875 },
    { lat: 48.6523256, lng: 17.7120559 },
    { lat: 48.6522686, lng: 17.7122814 },
    { lat: 48.6521343, lng: 17.7125392 },
    { lat: 48.6515508, lng: 17.7130732 },
    { lat: 48.6512155, lng: 17.7132744 },
    { lat: 48.6509436, lng: 17.7135301 },
    { lat: 48.6508351, lng: 17.7137118 },
    { lat: 48.6508192, lng: 17.7138758 },
    { lat: 48.6506221, lng: 17.7144145 },
    { lat: 48.6504038, lng: 17.714606 },
    { lat: 48.6503821, lng: 17.7147297 },
    { lat: 48.6502134, lng: 17.714941 },
    { lat: 48.6499502, lng: 17.7150298 },
    { lat: 48.6498818, lng: 17.7152751 },
    { lat: 48.6492856, lng: 17.7160597 },
    { lat: 48.6490451, lng: 17.7160425 },
    { lat: 48.6485413, lng: 17.7168979 },
    { lat: 48.647955, lng: 17.7169809 },
    { lat: 48.6478655, lng: 17.716996 },
    { lat: 48.6476491, lng: 17.7173296 },
    { lat: 48.647456, lng: 17.7175121 },
    { lat: 48.6470778, lng: 17.7176593 },
    { lat: 48.6469648, lng: 17.7176924 },
    { lat: 48.6468488, lng: 17.7177176 },
    { lat: 48.6465826, lng: 17.7176287 },
    { lat: 48.646517, lng: 17.7176822 },
    { lat: 48.6463834, lng: 17.7175871 },
    { lat: 48.6462706, lng: 17.7176612 },
    { lat: 48.645834, lng: 17.717647 },
    { lat: 48.645706, lng: 17.718041 },
    { lat: 48.6455545, lng: 17.7181276 },
    { lat: 48.6452923, lng: 17.71789 },
    { lat: 48.6451262, lng: 17.7179773 },
    { lat: 48.6450185, lng: 17.7179353 },
    { lat: 48.6449016, lng: 17.7180871 },
    { lat: 48.6444036, lng: 17.7179434 },
    { lat: 48.6442593, lng: 17.7182183 },
    { lat: 48.6439712, lng: 17.7183231 },
    { lat: 48.6438032, lng: 17.7182197 },
    { lat: 48.643608, lng: 17.71835 },
    { lat: 48.6433932, lng: 17.7182356 },
    { lat: 48.6431485, lng: 17.7184597 },
    { lat: 48.6428862, lng: 17.7182881 },
    { lat: 48.6427983, lng: 17.7184554 },
    { lat: 48.6422587, lng: 17.7186592 },
    { lat: 48.6421342, lng: 17.7187613 },
    { lat: 48.6421316, lng: 17.7189948 },
    { lat: 48.641972, lng: 17.7190962 },
    { lat: 48.641914, lng: 17.7192554 },
    { lat: 48.6414539, lng: 17.7195208 },
    { lat: 48.641272, lng: 17.7194454 },
    { lat: 48.6406884, lng: 17.7197217 },
    { lat: 48.6408548, lng: 17.7197469 },
    { lat: 48.6412414, lng: 17.7210512 },
    { lat: 48.6414699, lng: 17.7216977 },
    { lat: 48.6420693, lng: 17.723066 },
    { lat: 48.6428095, lng: 17.7253049 },
    { lat: 48.6428275, lng: 17.7253585 },
    { lat: 48.6433045, lng: 17.7266116 },
    { lat: 48.6438926, lng: 17.7279602 },
    { lat: 48.6445095, lng: 17.7291576 },
    { lat: 48.6451873, lng: 17.7302262 },
    { lat: 48.6457048, lng: 17.7313081 },
    { lat: 48.6461343, lng: 17.7324328 },
    { lat: 48.6462393, lng: 17.7329464 },
    { lat: 48.6466673, lng: 17.7339552 },
    { lat: 48.6468289, lng: 17.7346155 },
    { lat: 48.6471138, lng: 17.7352442 },
    { lat: 48.6473096, lng: 17.7358919 },
    { lat: 48.6473165, lng: 17.7361155 },
    { lat: 48.6472074, lng: 17.7364679 },
    { lat: 48.6468375, lng: 17.7370443 },
    { lat: 48.6484317, lng: 17.7400852 },
    { lat: 48.6484762, lng: 17.7401811 },
    { lat: 48.6483079, lng: 17.7403294 },
    { lat: 48.6487456, lng: 17.7411287 },
    { lat: 48.6503664, lng: 17.7431553 },
    { lat: 48.6512809, lng: 17.7441639 },
    { lat: 48.6512281, lng: 17.7446084 },
    { lat: 48.651279, lng: 17.744627 },
    { lat: 48.651465, lng: 17.744158 },
    { lat: 48.651764, lng: 17.743582 },
    { lat: 48.651892, lng: 17.743346 },
    { lat: 48.652325, lng: 17.742619 },
    { lat: 48.652552, lng: 17.742292 },
    { lat: 48.653634, lng: 17.740775 },
    { lat: 48.653703, lng: 17.740715 },
    { lat: 48.653803, lng: 17.740652 },
    { lat: 48.653925, lng: 17.740687 },
    { lat: 48.654163, lng: 17.741009 },
    { lat: 48.654302, lng: 17.741148 },
    { lat: 48.654367, lng: 17.741178 },
    { lat: 48.654424, lng: 17.741144 },
    { lat: 48.654482, lng: 17.741101 },
    { lat: 48.654574, lng: 17.740917 },
    { lat: 48.654716, lng: 17.740664 },
    { lat: 48.655014, lng: 17.740158 },
    { lat: 48.655487, lng: 17.73933 },
    { lat: 48.655671, lng: 17.739046 },
    { lat: 48.655863, lng: 17.738766 },
    { lat: 48.656145, lng: 17.73827 },
    { lat: 48.656279, lng: 17.738102 },
    { lat: 48.656678, lng: 17.7377 },
    { lat: 48.657066, lng: 17.737197 },
    { lat: 48.657289, lng: 17.736828 },
    { lat: 48.65767, lng: 17.736108 },
    { lat: 48.657861, lng: 17.735549 },
    { lat: 48.657901, lng: 17.735473 },
    { lat: 48.658487, lng: 17.734736 },
    { lat: 48.658612, lng: 17.734551 },
    { lat: 48.658849, lng: 17.734164 },
    { lat: 48.659092, lng: 17.733571 },
    { lat: 48.659141, lng: 17.733493 },
    { lat: 48.659742, lng: 17.732871 },
    { lat: 48.660138, lng: 17.732222 },
    { lat: 48.660299, lng: 17.732009 },
    { lat: 48.66065, lng: 17.731672 },
    { lat: 48.661009, lng: 17.731206 },
    { lat: 48.661214, lng: 17.730791 },
    { lat: 48.661472, lng: 17.730455 },
    { lat: 48.661619, lng: 17.730263 },
    { lat: 48.661743, lng: 17.730156 },
    { lat: 48.661928, lng: 17.730066 },
    { lat: 48.662246, lng: 17.72981 },
    { lat: 48.662535, lng: 17.729655 },
    { lat: 48.66279, lng: 17.729588 },
    { lat: 48.663373, lng: 17.729705 },
    { lat: 48.663619, lng: 17.729622 },
    { lat: 48.663804, lng: 17.729457 },
    { lat: 48.664046, lng: 17.72941 },
    { lat: 48.664269, lng: 17.729402 },
    { lat: 48.664493, lng: 17.729437 },
    { lat: 48.665745, lng: 17.729064 },
    { lat: 48.666499, lng: 17.72893 },
    { lat: 48.667012, lng: 17.728857 },
    { lat: 48.667628, lng: 17.728744 },
    { lat: 48.667979, lng: 17.728703 },
    { lat: 48.668174, lng: 17.728716 },
    { lat: 48.668511, lng: 17.728264 },
    { lat: 48.669133, lng: 17.727345 },
    { lat: 48.669212, lng: 17.727233 },
    { lat: 48.669679, lng: 17.726522 },
    { lat: 48.669966, lng: 17.726022 },
    { lat: 48.669984, lng: 17.725995 },
    { lat: 48.670281, lng: 17.725608 },
    { lat: 48.670498, lng: 17.725226 },
    { lat: 48.670652, lng: 17.725008 },
    { lat: 48.670773, lng: 17.724789 },
    { lat: 48.671925, lng: 17.722995 },
    { lat: 48.674123, lng: 17.719667 },
    { lat: 48.675227, lng: 17.717996 },
    { lat: 48.675889, lng: 17.716946 },
    { lat: 48.676253, lng: 17.716339 },
    { lat: 48.676581, lng: 17.71584 },
    { lat: 48.676653, lng: 17.715732 },
    { lat: 48.67667, lng: 17.715754 },
    { lat: 48.677589, lng: 17.713741 },
  ],
  DolnýLopašov: [
    { lat: 48.637734, lng: 17.577876 },
    { lat: 48.637713, lng: 17.577928 },
    { lat: 48.637477, lng: 17.578081 },
    { lat: 48.637177, lng: 17.578273 },
    { lat: 48.636832, lng: 17.578388 },
    { lat: 48.63665, lng: 17.578487 },
    { lat: 48.6358781, lng: 17.579968 },
    { lat: 48.6349115, lng: 17.5821146 },
    { lat: 48.6309046, lng: 17.5886519 },
    { lat: 48.6305121, lng: 17.5890688 },
    { lat: 48.6301701, lng: 17.5896015 },
    { lat: 48.6301648, lng: 17.5896097 },
    { lat: 48.6298581, lng: 17.5900874 },
    { lat: 48.6296232, lng: 17.5903742 },
    { lat: 48.6247757, lng: 17.5884251 },
    { lat: 48.6217129, lng: 17.5903553 },
    { lat: 48.6219074, lng: 17.5909713 },
    { lat: 48.6153713, lng: 17.5982509 },
    { lat: 48.6085242, lng: 17.605221 },
    { lat: 48.605428, lng: 17.6090147 },
    { lat: 48.6052037, lng: 17.6095431 },
    { lat: 48.6048514, lng: 17.6094521 },
    { lat: 48.6043546, lng: 17.609111 },
    { lat: 48.6024304, lng: 17.6116086 },
    { lat: 48.5989972, lng: 17.6121757 },
    { lat: 48.5969295, lng: 17.6126037 },
    { lat: 48.5962192, lng: 17.6127111 },
    { lat: 48.5956284, lng: 17.6126175 },
    { lat: 48.5945747, lng: 17.612922 },
    { lat: 48.5931473, lng: 17.6130638 },
    { lat: 48.5926821, lng: 17.6135989 },
    { lat: 48.5889454, lng: 17.6178974 },
    { lat: 48.5875338, lng: 17.6209175 },
    { lat: 48.5869074, lng: 17.6218749 },
    { lat: 48.5841076, lng: 17.6250516 },
    { lat: 48.5818194, lng: 17.6278351 },
    { lat: 48.581007, lng: 17.6287083 },
    { lat: 48.5801283, lng: 17.629337 },
    { lat: 48.5794519, lng: 17.6300206 },
    { lat: 48.5792448, lng: 17.6301221 },
    { lat: 48.5788856, lng: 17.6301744 },
    { lat: 48.5786769, lng: 17.6301028 },
    { lat: 48.578478, lng: 17.629777 },
    { lat: 48.578405, lng: 17.6297143 },
    { lat: 48.5782653, lng: 17.6297157 },
    { lat: 48.5772665, lng: 17.6302669 },
    { lat: 48.576087, lng: 17.6306869 },
    { lat: 48.5740303, lng: 17.6316907 },
    { lat: 48.5723293, lng: 17.6326007 },
    { lat: 48.5716751, lng: 17.6330275 },
    { lat: 48.5713734, lng: 17.6333111 },
    { lat: 48.5697801, lng: 17.6345162 },
    { lat: 48.5674129, lng: 17.6366199 },
    { lat: 48.5660324, lng: 17.6376533 },
    { lat: 48.5656347, lng: 17.6378653 },
    { lat: 48.5640806, lng: 17.6393946 },
    { lat: 48.562738, lng: 17.6408724 },
    { lat: 48.5613769, lng: 17.6421103 },
    { lat: 48.5609744, lng: 17.6427674 },
    { lat: 48.5603851, lng: 17.6435288 },
    { lat: 48.5597251, lng: 17.6441858 },
    { lat: 48.5574372, lng: 17.6460595 },
    { lat: 48.5568319, lng: 17.646653 },
    { lat: 48.5562247, lng: 17.6474539 },
    { lat: 48.5553652, lng: 17.6488744 },
    { lat: 48.5549888, lng: 17.649394 },
    { lat: 48.5544642, lng: 17.649976 },
    { lat: 48.5528579, lng: 17.6522968 },
    { lat: 48.5520361, lng: 17.6530938 },
    { lat: 48.5510482, lng: 17.6537475 },
    { lat: 48.5506174, lng: 17.654227 },
    { lat: 48.5490627, lng: 17.6565555 },
    { lat: 48.5480847, lng: 17.6576934 },
    { lat: 48.5478796, lng: 17.6580777 },
    { lat: 48.5461492, lng: 17.6575654 },
    { lat: 48.5439677, lng: 17.6567322 },
    { lat: 48.543543, lng: 17.6561681 },
    { lat: 48.5435054, lng: 17.6562176 },
    { lat: 48.5456608, lng: 17.6598134 },
    { lat: 48.5462602, lng: 17.6606592 },
    { lat: 48.5434074, lng: 17.6652457 },
    { lat: 48.5405495, lng: 17.6701456 },
    { lat: 48.5389543, lng: 17.6725051 },
    { lat: 48.5380572, lng: 17.6736189 },
    { lat: 48.5373465, lng: 17.6746062 },
    { lat: 48.5372847, lng: 17.6747728 },
    { lat: 48.5371525, lng: 17.6751877 },
    { lat: 48.5366631, lng: 17.6764308 },
    { lat: 48.5363764, lng: 17.6768839 },
    { lat: 48.5372962, lng: 17.6781124 },
    { lat: 48.5384895, lng: 17.6800477 },
    { lat: 48.5380576, lng: 17.6808159 },
    { lat: 48.5381902, lng: 17.681043 },
    { lat: 48.5386679, lng: 17.6814983 },
    { lat: 48.5390481, lng: 17.68171 },
    { lat: 48.5395527, lng: 17.681835 },
    { lat: 48.5402102, lng: 17.6817507 },
    { lat: 48.5408522, lng: 17.6815246 },
    { lat: 48.5413425, lng: 17.6812405 },
    { lat: 48.5414216, lng: 17.6813858 },
    { lat: 48.5416013, lng: 17.6811904 },
    { lat: 48.5418553, lng: 17.6810252 },
    { lat: 48.5420772, lng: 17.6810516 },
    { lat: 48.5439753, lng: 17.6819408 },
    { lat: 48.5451414, lng: 17.682157 },
    { lat: 48.5452457, lng: 17.6824177 },
    { lat: 48.5457854, lng: 17.6827256 },
    { lat: 48.5460834, lng: 17.6829778 },
    { lat: 48.5463884, lng: 17.683507 },
    { lat: 48.546961, lng: 17.683109 },
    { lat: 48.547831, lng: 17.6841881 },
    { lat: 48.5482538, lng: 17.683797 },
    { lat: 48.5485247, lng: 17.6841226 },
    { lat: 48.5495745, lng: 17.6850815 },
    { lat: 48.5496813, lng: 17.6850715 },
    { lat: 48.5499102, lng: 17.6853046 },
    { lat: 48.5507155, lng: 17.6858702 },
    { lat: 48.5518317, lng: 17.6860629 },
    { lat: 48.5523869, lng: 17.6866199 },
    { lat: 48.5535023, lng: 17.6874693 },
    { lat: 48.5544597, lng: 17.6886489 },
    { lat: 48.55582, lng: 17.6905351 },
    { lat: 48.5560343, lng: 17.6901495 },
    { lat: 48.5563281, lng: 17.6896607 },
    { lat: 48.5570062, lng: 17.6887435 },
    { lat: 48.5577875, lng: 17.6880037 },
    { lat: 48.5592875, lng: 17.6861955 },
    { lat: 48.5595184, lng: 17.6858941 },
    { lat: 48.5596314, lng: 17.6857327 },
    { lat: 48.5597633, lng: 17.6856589 },
    { lat: 48.5617562, lng: 17.6832255 },
    { lat: 48.5646918, lng: 17.6809574 },
    { lat: 48.5656536, lng: 17.6820071 },
    { lat: 48.5764397, lng: 17.6658897 },
    { lat: 48.5765939, lng: 17.6657166 },
    { lat: 48.5767722, lng: 17.6654989 },
    { lat: 48.5775019, lng: 17.6646189 },
    { lat: 48.579352, lng: 17.6623298 },
    { lat: 48.5805454, lng: 17.6610435 },
    { lat: 48.5826449, lng: 17.6581371 },
    { lat: 48.5832674, lng: 17.6573681 },
    { lat: 48.5830832, lng: 17.6569484 },
    { lat: 48.5834915, lng: 17.6569201 },
    { lat: 48.5837434, lng: 17.6567414 },
    { lat: 48.5846191, lng: 17.6554488 },
    { lat: 48.5853583, lng: 17.6546324 },
    { lat: 48.5863218, lng: 17.6533523 },
    { lat: 48.5879627, lng: 17.6505764 },
    { lat: 48.5887594, lng: 17.6494373 },
    { lat: 48.5887961, lng: 17.6493849 },
    { lat: 48.5890733, lng: 17.6487276 },
    { lat: 48.5892967, lng: 17.6481981 },
    { lat: 48.5893493, lng: 17.648118 },
    { lat: 48.589676, lng: 17.6476199 },
    { lat: 48.5897726, lng: 17.6471624 },
    { lat: 48.5898381, lng: 17.6468522 },
    { lat: 48.5905847, lng: 17.6457702 },
    { lat: 48.5915703, lng: 17.6441977 },
    { lat: 48.5943032, lng: 17.6404694 },
    { lat: 48.5956731, lng: 17.6390846 },
    { lat: 48.5965776, lng: 17.6384886 },
    { lat: 48.5976252, lng: 17.6375804 },
    { lat: 48.5996235, lng: 17.6360813 },
    { lat: 48.6029788, lng: 17.6328438 },
    { lat: 48.6044317, lng: 17.6309276 },
    { lat: 48.6047506, lng: 17.6305033 },
    { lat: 48.6069338, lng: 17.6257901 },
    { lat: 48.6071247, lng: 17.6255738 },
    { lat: 48.6076327, lng: 17.6246714 },
    { lat: 48.6081427, lng: 17.6240164 },
    { lat: 48.6085853, lng: 17.6233139 },
    { lat: 48.6089921, lng: 17.622888 },
    { lat: 48.6093793, lng: 17.6223653 },
    { lat: 48.6098633, lng: 17.6220151 },
    { lat: 48.6108895, lng: 17.6214348 },
    { lat: 48.6110998, lng: 17.6211162 },
    { lat: 48.611495, lng: 17.6207172 },
    { lat: 48.6120723, lng: 17.6197513 },
    { lat: 48.6132107, lng: 17.6187824 },
    { lat: 48.6137224, lng: 17.6184446 },
    { lat: 48.6145867, lng: 17.6177124 },
    { lat: 48.6148415, lng: 17.6173842 },
    { lat: 48.61495, lng: 17.617348 },
    { lat: 48.6154773, lng: 17.6175037 },
    { lat: 48.6164413, lng: 17.6180098 },
    { lat: 48.6167807, lng: 17.6183964 },
    { lat: 48.6173053, lng: 17.6187319 },
    { lat: 48.6176953, lng: 17.6191362 },
    { lat: 48.6180131, lng: 17.6192882 },
    { lat: 48.6186315, lng: 17.6194487 },
    { lat: 48.6187157, lng: 17.6194706 },
    { lat: 48.6188561, lng: 17.6194919 },
    { lat: 48.6189243, lng: 17.6195022 },
    { lat: 48.6191413, lng: 17.6194794 },
    { lat: 48.6196173, lng: 17.6193173 },
    { lat: 48.6200912, lng: 17.6192932 },
    { lat: 48.6209511, lng: 17.6188186 },
    { lat: 48.6212707, lng: 17.6184582 },
    { lat: 48.6219147, lng: 17.617293 },
    { lat: 48.6224101, lng: 17.6168204 },
    { lat: 48.6227586, lng: 17.616004 },
    { lat: 48.6232643, lng: 17.6143131 },
    { lat: 48.6235294, lng: 17.6137667 },
    { lat: 48.6237768, lng: 17.6130832 },
    { lat: 48.6245548, lng: 17.6116817 },
    { lat: 48.624797, lng: 17.6111361 },
    { lat: 48.6250573, lng: 17.6105498 },
    { lat: 48.625691, lng: 17.6097821 },
    { lat: 48.62599, lng: 17.6095767 },
    { lat: 48.626135, lng: 17.6093882 },
    { lat: 48.6264124, lng: 17.6087003 },
    { lat: 48.627064, lng: 17.6077826 },
    { lat: 48.6272393, lng: 17.6073388 },
    { lat: 48.6272476, lng: 17.6072883 },
    { lat: 48.6273463, lng: 17.6066891 },
    { lat: 48.6273955, lng: 17.6063906 },
    { lat: 48.6275164, lng: 17.6061944 },
    { lat: 48.6276916, lng: 17.6060712 },
    { lat: 48.6280357, lng: 17.6059974 },
    { lat: 48.6281294, lng: 17.6060497 },
    { lat: 48.6284146, lng: 17.6060046 },
    { lat: 48.6290325, lng: 17.6050006 },
    { lat: 48.6291493, lng: 17.6048672 },
    { lat: 48.6293768, lng: 17.6047486 },
    { lat: 48.6300016, lng: 17.6040913 },
    { lat: 48.6306163, lng: 17.6031277 },
    { lat: 48.6313435, lng: 17.6028045 },
    { lat: 48.632259, lng: 17.6030494 },
    { lat: 48.6324966, lng: 17.6029675 },
    { lat: 48.6330827, lng: 17.6031136 },
    { lat: 48.6334359, lng: 17.6033236 },
    { lat: 48.634347, lng: 17.6036824 },
    { lat: 48.6358918, lng: 17.6047766 },
    { lat: 48.6358954, lng: 17.6047625 },
    { lat: 48.6362371, lng: 17.6035876 },
    { lat: 48.6371893, lng: 17.6034306 },
    { lat: 48.6377382, lng: 17.6035763 },
    { lat: 48.6380103, lng: 17.6035416 },
    { lat: 48.6381279, lng: 17.6034891 },
    { lat: 48.638395, lng: 17.6033563 },
    { lat: 48.6385734, lng: 17.6033554 },
    { lat: 48.6386986, lng: 17.603225 },
    { lat: 48.6391024, lng: 17.6031483 },
    { lat: 48.6395556, lng: 17.602776 },
    { lat: 48.6399007, lng: 17.6028034 },
    { lat: 48.6402211, lng: 17.6029432 },
    { lat: 48.6404811, lng: 17.6031456 },
    { lat: 48.6406559, lng: 17.6033744 },
    { lat: 48.640957, lng: 17.6035491 },
    { lat: 48.6413789, lng: 17.6037044 },
    { lat: 48.6416475, lng: 17.6035729 },
    { lat: 48.64168, lng: 17.603557 },
    { lat: 48.641686, lng: 17.603283 },
    { lat: 48.64167, lng: 17.602791 },
    { lat: 48.641699, lng: 17.602126 },
    { lat: 48.64167, lng: 17.600908 },
    { lat: 48.641419, lng: 17.600613 },
    { lat: 48.64129, lng: 17.600291 },
    { lat: 48.641217, lng: 17.600111 },
    { lat: 48.641002, lng: 17.599486 },
    { lat: 48.640705, lng: 17.598756 },
    { lat: 48.640516, lng: 17.598237 },
    { lat: 48.640404, lng: 17.59794 },
    { lat: 48.640006, lng: 17.59695 },
    { lat: 48.639907, lng: 17.596645 },
    { lat: 48.639799, lng: 17.596375 },
    { lat: 48.639513, lng: 17.595755 },
    { lat: 48.63949, lng: 17.595621 },
    { lat: 48.639277, lng: 17.595157 },
    { lat: 48.639174, lng: 17.594881 },
    { lat: 48.638995, lng: 17.594449 },
    { lat: 48.63892, lng: 17.594091 },
    { lat: 48.638838, lng: 17.593829 },
    { lat: 48.638753, lng: 17.593317 },
    { lat: 48.638623, lng: 17.592876 },
    { lat: 48.63858, lng: 17.592582 },
    { lat: 48.638488, lng: 17.592262 },
    { lat: 48.638383, lng: 17.591955 },
    { lat: 48.63832, lng: 17.591734 },
    { lat: 48.638319, lng: 17.591205 },
    { lat: 48.638196, lng: 17.590626 },
    { lat: 48.638292, lng: 17.590208 },
    { lat: 48.638528, lng: 17.589694 },
    { lat: 48.638575, lng: 17.589323 },
    { lat: 48.638545, lng: 17.588954 },
    { lat: 48.63863, lng: 17.588688 },
    { lat: 48.638873, lng: 17.587887 },
    { lat: 48.638817, lng: 17.587659 },
    { lat: 48.638692, lng: 17.587435 },
    { lat: 48.638476, lng: 17.586689 },
    { lat: 48.638056, lng: 17.585705 },
    { lat: 48.638108, lng: 17.584791 },
    { lat: 48.638069, lng: 17.584586 },
    { lat: 48.637995, lng: 17.584383 },
    { lat: 48.637896, lng: 17.584031 },
    { lat: 48.637833, lng: 17.583488 },
    { lat: 48.6378, lng: 17.582905 },
    { lat: 48.637826, lng: 17.582367 },
    { lat: 48.637806, lng: 17.582019 },
    { lat: 48.637862, lng: 17.580286 },
    { lat: 48.637857, lng: 17.580109 },
    { lat: 48.637852, lng: 17.579977 },
    { lat: 48.637755, lng: 17.579422 },
    { lat: 48.637747, lng: 17.578969 },
    { lat: 48.637757, lng: 17.578679 },
    { lat: 48.637745, lng: 17.57837 },
    { lat: 48.637748, lng: 17.578063 },
    { lat: 48.637734, lng: 17.577876 },
  ],
  KočínLančár: [
    { lat: 48.5867377, lng: 17.6795983 },
    { lat: 48.5868488, lng: 17.6794795 },
    { lat: 48.5873403, lng: 17.6787242 },
    { lat: 48.5875981, lng: 17.6784231 },
    { lat: 48.5880894, lng: 17.6774627 },
    { lat: 48.5885412, lng: 17.6769026 },
    { lat: 48.588738, lng: 17.6767988 },
    { lat: 48.5888714, lng: 17.6766387 },
    { lat: 48.5893783, lng: 17.6756743 },
    { lat: 48.5898328, lng: 17.6752858 },
    { lat: 48.5903144, lng: 17.6743531 },
    { lat: 48.591104, lng: 17.6734345 },
    { lat: 48.5913516, lng: 17.6730421 },
    { lat: 48.5916347, lng: 17.6722726 },
    { lat: 48.5922345, lng: 17.6719355 },
    { lat: 48.592609, lng: 17.6716043 },
    { lat: 48.5929132, lng: 17.6709766 },
    { lat: 48.5931904, lng: 17.670785 },
    { lat: 48.5934237, lng: 17.6705077 },
    { lat: 48.5934884, lng: 17.6701147 },
    { lat: 48.5938018, lng: 17.6695239 },
    { lat: 48.5939952, lng: 17.6692939 },
    { lat: 48.5940807, lng: 17.6690556 },
    { lat: 48.5942297, lng: 17.668853 },
    { lat: 48.5946837, lng: 17.6684926 },
    { lat: 48.5948093, lng: 17.6681734 },
    { lat: 48.5961276, lng: 17.6663882 },
    { lat: 48.5963702, lng: 17.6662212 },
    { lat: 48.5967881, lng: 17.6669142 },
    { lat: 48.5985381, lng: 17.6694472 },
    { lat: 48.6002624, lng: 17.6676579 },
    { lat: 48.6012502, lng: 17.6668369 },
    { lat: 48.6029029, lng: 17.6659074 },
    { lat: 48.6035703, lng: 17.6654193 },
    { lat: 48.6045737, lng: 17.6648011 },
    { lat: 48.6047041, lng: 17.664626 },
    { lat: 48.6058908, lng: 17.6637742 },
    { lat: 48.6066221, lng: 17.6633529 },
    { lat: 48.6072023, lng: 17.6627355 },
    { lat: 48.6074199, lng: 17.6626568 },
    { lat: 48.6082749, lng: 17.6619344 },
    { lat: 48.6092453, lng: 17.6609497 },
    { lat: 48.6094203, lng: 17.6608228 },
    { lat: 48.6097944, lng: 17.6607366 },
    { lat: 48.6109835, lng: 17.6593272 },
    { lat: 48.6113139, lng: 17.6591026 },
    { lat: 48.6119781, lng: 17.6591443 },
    { lat: 48.6125963, lng: 17.658992 },
    { lat: 48.6129849, lng: 17.6587777 },
    { lat: 48.6136637, lng: 17.658289 },
    { lat: 48.614474, lng: 17.6577057 },
    { lat: 48.6145758, lng: 17.6575219 },
    { lat: 48.6146225, lng: 17.6574376 },
    { lat: 48.6148264, lng: 17.6570693 },
    { lat: 48.6156184, lng: 17.6562924 },
    { lat: 48.615216, lng: 17.6563793 },
    { lat: 48.6152265, lng: 17.6563581 },
    { lat: 48.6155626, lng: 17.655935 },
    { lat: 48.6150531, lng: 17.6547702 },
    { lat: 48.6153892, lng: 17.6542029 },
    { lat: 48.6158725, lng: 17.6525948 },
    { lat: 48.6160634, lng: 17.652143 },
    { lat: 48.616311, lng: 17.6517349 },
    { lat: 48.6168062, lng: 17.6512199 },
    { lat: 48.6206136, lng: 17.646033 },
    { lat: 48.6219601, lng: 17.6439457 },
    { lat: 48.6236029, lng: 17.6411598 },
    { lat: 48.6244416, lng: 17.6390631 },
    { lat: 48.6243032, lng: 17.6388901 },
    { lat: 48.6245913, lng: 17.6383697 },
    { lat: 48.625164, lng: 17.6366963 },
    { lat: 48.625277, lng: 17.6363722 },
    { lat: 48.6257894, lng: 17.6352295 },
    { lat: 48.6260492, lng: 17.6347267 },
    { lat: 48.6264854, lng: 17.6341196 },
    { lat: 48.6267453, lng: 17.6334751 },
    { lat: 48.6268569, lng: 17.6333078 },
    { lat: 48.6275538, lng: 17.6326428 },
    { lat: 48.6281126, lng: 17.6319007 },
    { lat: 48.6289293, lng: 17.6313131 },
    { lat: 48.629232, lng: 17.6300342 },
    { lat: 48.6292973, lng: 17.6294516 },
    { lat: 48.6299447, lng: 17.6282164 },
    { lat: 48.6302087, lng: 17.6269129 },
    { lat: 48.6307688, lng: 17.6245906 },
    { lat: 48.633064, lng: 17.619074 },
    { lat: 48.6348207, lng: 17.6150719 },
    { lat: 48.635469, lng: 17.613492 },
    { lat: 48.6367017, lng: 17.61075 },
    { lat: 48.6367331, lng: 17.6105113 },
    { lat: 48.6368337, lng: 17.610282 },
    { lat: 48.6369599, lng: 17.6097752 },
    { lat: 48.637708, lng: 17.609117 },
    { lat: 48.638318, lng: 17.6078816 },
    { lat: 48.638425, lng: 17.6075696 },
    { lat: 48.6383601, lng: 17.6070415 },
    { lat: 48.638306, lng: 17.6068625 },
    { lat: 48.6378555, lng: 17.6061797 },
    { lat: 48.6378653, lng: 17.6045403 },
    { lat: 48.6380103, lng: 17.6035416 },
    { lat: 48.6377382, lng: 17.6035763 },
    { lat: 48.6371893, lng: 17.6034306 },
    { lat: 48.6362371, lng: 17.6035876 },
    { lat: 48.6358954, lng: 17.6047625 },
    { lat: 48.6358918, lng: 17.6047766 },
    { lat: 48.634347, lng: 17.6036824 },
    { lat: 48.6334359, lng: 17.6033236 },
    { lat: 48.6330827, lng: 17.6031136 },
    { lat: 48.6324966, lng: 17.6029675 },
    { lat: 48.632259, lng: 17.6030494 },
    { lat: 48.6313435, lng: 17.6028045 },
    { lat: 48.6306163, lng: 17.6031277 },
    { lat: 48.6300016, lng: 17.6040913 },
    { lat: 48.6293768, lng: 17.6047486 },
    { lat: 48.6291493, lng: 17.6048672 },
    { lat: 48.6290325, lng: 17.6050006 },
    { lat: 48.6284146, lng: 17.6060046 },
    { lat: 48.6281294, lng: 17.6060497 },
    { lat: 48.6280357, lng: 17.6059974 },
    { lat: 48.6276916, lng: 17.6060712 },
    { lat: 48.6275164, lng: 17.6061944 },
    { lat: 48.6273955, lng: 17.6063906 },
    { lat: 48.6273463, lng: 17.6066891 },
    { lat: 48.6272476, lng: 17.6072883 },
    { lat: 48.6272393, lng: 17.6073388 },
    { lat: 48.627064, lng: 17.6077826 },
    { lat: 48.6264124, lng: 17.6087003 },
    { lat: 48.626135, lng: 17.6093882 },
    { lat: 48.62599, lng: 17.6095767 },
    { lat: 48.625691, lng: 17.6097821 },
    { lat: 48.6250573, lng: 17.6105498 },
    { lat: 48.624797, lng: 17.6111361 },
    { lat: 48.6245548, lng: 17.6116817 },
    { lat: 48.6237768, lng: 17.6130832 },
    { lat: 48.6235294, lng: 17.6137667 },
    { lat: 48.6232643, lng: 17.6143131 },
    { lat: 48.6227586, lng: 17.616004 },
    { lat: 48.6224101, lng: 17.6168204 },
    { lat: 48.6219147, lng: 17.617293 },
    { lat: 48.6212707, lng: 17.6184582 },
    { lat: 48.6209511, lng: 17.6188186 },
    { lat: 48.6200912, lng: 17.6192932 },
    { lat: 48.6196173, lng: 17.6193173 },
    { lat: 48.6191413, lng: 17.6194794 },
    { lat: 48.6189243, lng: 17.6195022 },
    { lat: 48.6188561, lng: 17.6194919 },
    { lat: 48.6187157, lng: 17.6194706 },
    { lat: 48.6186315, lng: 17.6194487 },
    { lat: 48.6180131, lng: 17.6192882 },
    { lat: 48.6176953, lng: 17.6191362 },
    { lat: 48.6173053, lng: 17.6187319 },
    { lat: 48.6167807, lng: 17.6183964 },
    { lat: 48.6164413, lng: 17.6180098 },
    { lat: 48.6154773, lng: 17.6175037 },
    { lat: 48.61495, lng: 17.617348 },
    { lat: 48.6148415, lng: 17.6173842 },
    { lat: 48.6145867, lng: 17.6177124 },
    { lat: 48.6137224, lng: 17.6184446 },
    { lat: 48.6132107, lng: 17.6187824 },
    { lat: 48.6120723, lng: 17.6197513 },
    { lat: 48.611495, lng: 17.6207172 },
    { lat: 48.6110998, lng: 17.6211162 },
    { lat: 48.6108895, lng: 17.6214348 },
    { lat: 48.6098633, lng: 17.6220151 },
    { lat: 48.6093793, lng: 17.6223653 },
    { lat: 48.6089921, lng: 17.622888 },
    { lat: 48.6085853, lng: 17.6233139 },
    { lat: 48.6081427, lng: 17.6240164 },
    { lat: 48.6076327, lng: 17.6246714 },
    { lat: 48.6071247, lng: 17.6255738 },
    { lat: 48.6069338, lng: 17.6257901 },
    { lat: 48.6047506, lng: 17.6305033 },
    { lat: 48.6044317, lng: 17.6309276 },
    { lat: 48.6029788, lng: 17.6328438 },
    { lat: 48.5996235, lng: 17.6360813 },
    { lat: 48.5976252, lng: 17.6375804 },
    { lat: 48.5965776, lng: 17.6384886 },
    { lat: 48.5956731, lng: 17.6390846 },
    { lat: 48.5943032, lng: 17.6404694 },
    { lat: 48.5915703, lng: 17.6441977 },
    { lat: 48.5905847, lng: 17.6457702 },
    { lat: 48.5898381, lng: 17.6468522 },
    { lat: 48.5897726, lng: 17.6471624 },
    { lat: 48.589676, lng: 17.6476199 },
    { lat: 48.5893493, lng: 17.648118 },
    { lat: 48.5892967, lng: 17.6481981 },
    { lat: 48.5890733, lng: 17.6487276 },
    { lat: 48.5887961, lng: 17.6493849 },
    { lat: 48.5887594, lng: 17.6494373 },
    { lat: 48.5879627, lng: 17.6505764 },
    { lat: 48.5863218, lng: 17.6533523 },
    { lat: 48.5853583, lng: 17.6546324 },
    { lat: 48.5846191, lng: 17.6554488 },
    { lat: 48.5837434, lng: 17.6567414 },
    { lat: 48.5834915, lng: 17.6569201 },
    { lat: 48.5830832, lng: 17.6569484 },
    { lat: 48.5832674, lng: 17.6573681 },
    { lat: 48.5826449, lng: 17.6581371 },
    { lat: 48.5805454, lng: 17.6610435 },
    { lat: 48.579352, lng: 17.6623298 },
    { lat: 48.5775019, lng: 17.6646189 },
    { lat: 48.5767722, lng: 17.6654989 },
    { lat: 48.5767762, lng: 17.6655111 },
    { lat: 48.5787547, lng: 17.6692096 },
    { lat: 48.5791453, lng: 17.6700407 },
    { lat: 48.5803131, lng: 17.6718587 },
    { lat: 48.5818405, lng: 17.6739372 },
    { lat: 48.5820807, lng: 17.67415 },
    { lat: 48.5837459, lng: 17.6757224 },
    { lat: 48.5838851, lng: 17.6759113 },
    { lat: 48.5851295, lng: 17.6775995 },
    { lat: 48.5852333, lng: 17.6777604 },
    { lat: 48.5867377, lng: 17.6795983 },
  ],
  Borovce: [
    { lat: 48.5978881, lng: 17.7135307 },
    { lat: 48.5976132, lng: 17.7128128 },
    { lat: 48.5965916, lng: 17.7112467 },
    { lat: 48.5962092, lng: 17.7105772 },
    { lat: 48.5953013, lng: 17.7093543 },
    { lat: 48.5946803, lng: 17.7082437 },
    { lat: 48.5936682, lng: 17.7068162 },
    { lat: 48.5928765, lng: 17.7049735 },
    { lat: 48.5923206, lng: 17.7033984 },
    { lat: 48.5900711, lng: 17.7014083 },
    { lat: 48.5897818, lng: 17.7011317 },
    { lat: 48.5872189, lng: 17.6954956 },
    { lat: 48.5866528, lng: 17.694689 },
    { lat: 48.5854695, lng: 17.6934359 },
    { lat: 48.585034, lng: 17.6928191 },
    { lat: 48.5841173, lng: 17.6918423 },
    { lat: 48.5840919, lng: 17.691833 },
    { lat: 48.5834767, lng: 17.6932728 },
    { lat: 48.5829033, lng: 17.6940559 },
    { lat: 48.581895, lng: 17.6958018 },
    { lat: 48.5806897, lng: 17.6976635 },
    { lat: 48.5800577, lng: 17.6988134 },
    { lat: 48.5798491, lng: 17.6991271 },
    { lat: 48.5790516, lng: 17.6999797 },
    { lat: 48.5789104, lng: 17.7002125 },
    { lat: 48.5783052, lng: 17.7018675 },
    { lat: 48.5772929, lng: 17.7039516 },
    { lat: 48.5764691, lng: 17.7054322 },
    { lat: 48.5754467, lng: 17.7070259 },
    { lat: 48.5746786, lng: 17.7086765 },
    { lat: 48.5735675, lng: 17.71077 },
    { lat: 48.5722339, lng: 17.7142662 },
    { lat: 48.5708027, lng: 17.7176076 },
    { lat: 48.5701556, lng: 17.7189374 },
    { lat: 48.569602, lng: 17.7203354 },
    { lat: 48.569249, lng: 17.7210705 },
    { lat: 48.5680403, lng: 17.7230128 },
    { lat: 48.5676264, lng: 17.7238403 },
    { lat: 48.5673715, lng: 17.7245395 },
    { lat: 48.566535, lng: 17.7260936 },
    { lat: 48.5663759, lng: 17.7265323 },
    { lat: 48.5659672, lng: 17.7271008 },
    { lat: 48.5659855, lng: 17.7272512 },
    { lat: 48.5656339, lng: 17.728119 },
    { lat: 48.5645926, lng: 17.7316202 },
    { lat: 48.5639885, lng: 17.7339566 },
    { lat: 48.5629578, lng: 17.7382912 },
    { lat: 48.5630089, lng: 17.7383147 },
    { lat: 48.5631614, lng: 17.7396499 },
    { lat: 48.5629516, lng: 17.7409889 },
    { lat: 48.5623312, lng: 17.7420039 },
    { lat: 48.5621947, lng: 17.7445691 },
    { lat: 48.5624986, lng: 17.7477002 },
    { lat: 48.5633818, lng: 17.7543193 },
    { lat: 48.5669539, lng: 17.7676527 },
    { lat: 48.5671031, lng: 17.7681908 },
    { lat: 48.5689785, lng: 17.7684291 },
    { lat: 48.5726336, lng: 17.7688446 },
    { lat: 48.5732102, lng: 17.758231 },
    { lat: 48.5817591, lng: 17.7581391 },
    { lat: 48.5819755, lng: 17.7582076 },
    { lat: 48.5819657, lng: 17.7578481 },
    { lat: 48.5822137, lng: 17.7565922 },
    { lat: 48.5822239, lng: 17.7560081 },
    { lat: 48.5823075, lng: 17.7557147 },
    { lat: 48.5823191, lng: 17.7554642 },
    { lat: 48.5826041, lng: 17.7545365 },
    { lat: 48.5825306, lng: 17.7542779 },
    { lat: 48.582609, lng: 17.7541018 },
    { lat: 48.5828295, lng: 17.7524768 },
    { lat: 48.5831257, lng: 17.7521696 },
    { lat: 48.5830237, lng: 17.7517432 },
    { lat: 48.583014, lng: 17.751347 },
    { lat: 48.5831199, lng: 17.7510155 },
    { lat: 48.5833564, lng: 17.748604 },
    { lat: 48.5834408, lng: 17.7451947 },
    { lat: 48.5836249, lng: 17.7431435 },
    { lat: 48.583775, lng: 17.7422278 },
    { lat: 48.5841498, lng: 17.7411119 },
    { lat: 48.584718, lng: 17.7389751 },
    { lat: 48.5851117, lng: 17.7364661 },
    { lat: 48.5853965, lng: 17.735315 },
    { lat: 48.585655, lng: 17.7345473 },
    { lat: 48.5868075, lng: 17.7320587 },
    { lat: 48.5871182, lng: 17.7312398 },
    { lat: 48.5878609, lng: 17.7289516 },
    { lat: 48.5881398, lng: 17.7278561 },
    { lat: 48.5886619, lng: 17.7262211 },
    { lat: 48.5888956, lng: 17.7253238 },
    { lat: 48.5889104, lng: 17.7247604 },
    { lat: 48.5891242, lng: 17.7241462 },
    { lat: 48.5897412, lng: 17.722604 },
    { lat: 48.589968, lng: 17.7221442 },
    { lat: 48.590318, lng: 17.7216709 },
    { lat: 48.5910446, lng: 17.7208601 },
    { lat: 48.5912756, lng: 17.7204143 },
    { lat: 48.5915474, lng: 17.7204634 },
    { lat: 48.5916898, lng: 17.7202487 },
    { lat: 48.5928989, lng: 17.7192801 },
    { lat: 48.5929841, lng: 17.7191575 },
    { lat: 48.5934067, lng: 17.718964 },
    { lat: 48.5938543, lng: 17.7185693 },
    { lat: 48.5940827, lng: 17.7182847 },
    { lat: 48.5944325, lng: 17.7180457 },
    { lat: 48.5951614, lng: 17.7169777 },
    { lat: 48.5954094, lng: 17.7167348 },
    { lat: 48.5961063, lng: 17.7162811 },
    { lat: 48.5964163, lng: 17.7159657 },
    { lat: 48.5967401, lng: 17.7154868 },
    { lat: 48.5968876, lng: 17.7151325 },
    { lat: 48.5975762, lng: 17.7142405 },
    { lat: 48.5978191, lng: 17.7137833 },
    { lat: 48.5978881, lng: 17.7135307 },
  ],
  Šterusy: [
    { lat: 48.5840919, lng: 17.691833 },
    { lat: 48.5841173, lng: 17.6918423 },
    { lat: 48.585034, lng: 17.6928191 },
    { lat: 48.5854695, lng: 17.6934359 },
    { lat: 48.5866528, lng: 17.694689 },
    { lat: 48.5872189, lng: 17.6954956 },
    { lat: 48.5897818, lng: 17.7011317 },
    { lat: 48.5902732, lng: 17.7003716 },
    { lat: 48.5912661, lng: 17.6984155 },
    { lat: 48.5921391, lng: 17.6969998 },
    { lat: 48.5925608, lng: 17.6960985 },
    { lat: 48.5931557, lng: 17.694441 },
    { lat: 48.5939002, lng: 17.6952679 },
    { lat: 48.5948853, lng: 17.6962028 },
    { lat: 48.5951074, lng: 17.6957442 },
    { lat: 48.5954556, lng: 17.695278 },
    { lat: 48.597409, lng: 17.6928981 },
    { lat: 48.5986617, lng: 17.6917621 },
    { lat: 48.5993903, lng: 17.690986 },
    { lat: 48.6020954, lng: 17.6886565 },
    { lat: 48.6022259, lng: 17.6890127 },
    { lat: 48.60265, lng: 17.6898607 },
    { lat: 48.6033375, lng: 17.6910022 },
    { lat: 48.605273, lng: 17.6898947 },
    { lat: 48.6063831, lng: 17.6894985 },
    { lat: 48.6079296, lng: 17.6887505 },
    { lat: 48.6087384, lng: 17.6885384 },
    { lat: 48.6094074, lng: 17.6882737 },
    { lat: 48.6134343, lng: 17.6865407 },
    { lat: 48.6136302, lng: 17.686731 },
    { lat: 48.6140371, lng: 17.6868444 },
    { lat: 48.615406, lng: 17.6867055 },
    { lat: 48.615998, lng: 17.6864933 },
    { lat: 48.6168038, lng: 17.685963 },
    { lat: 48.6167079, lng: 17.6857107 },
    { lat: 48.6169802, lng: 17.6854927 },
    { lat: 48.6166965, lng: 17.6847427 },
    { lat: 48.6168047, lng: 17.684706 },
    { lat: 48.617015, lng: 17.6844925 },
    { lat: 48.6171672, lng: 17.6841959 },
    { lat: 48.6173263, lng: 17.6840684 },
    { lat: 48.6175846, lng: 17.6839912 },
    { lat: 48.6176939, lng: 17.6837699 },
    { lat: 48.6178877, lng: 17.6838881 },
    { lat: 48.6182558, lng: 17.6842532 },
    { lat: 48.6184484, lng: 17.6843366 },
    { lat: 48.6189963, lng: 17.6843247 },
    { lat: 48.6192944, lng: 17.684233 },
    { lat: 48.619481, lng: 17.6840909 },
    { lat: 48.6197403, lng: 17.6842695 },
    { lat: 48.6209831, lng: 17.6823739 },
    { lat: 48.6208399, lng: 17.682068 },
    { lat: 48.6209446, lng: 17.681899 },
    { lat: 48.6213807, lng: 17.6813594 },
    { lat: 48.6217083, lng: 17.6811257 },
    { lat: 48.6226203, lng: 17.6797051 },
    { lat: 48.6230371, lng: 17.6792892 },
    { lat: 48.6230747, lng: 17.6791851 },
    { lat: 48.6232603, lng: 17.6790471 },
    { lat: 48.6234342, lng: 17.6788865 },
    { lat: 48.6234016, lng: 17.6788151 },
    { lat: 48.6232933, lng: 17.6784543 },
    { lat: 48.6224082, lng: 17.6764778 },
    { lat: 48.6222206, lng: 17.6762003 },
    { lat: 48.6235889, lng: 17.6733498 },
    { lat: 48.6247625, lng: 17.6714879 },
    { lat: 48.6258896, lng: 17.6705865 },
    { lat: 48.6273517, lng: 17.6696994 },
    { lat: 48.6294111, lng: 17.6679019 },
    { lat: 48.6312921, lng: 17.665597 },
    { lat: 48.6321567, lng: 17.66434 },
    { lat: 48.6327618, lng: 17.6621666 },
    { lat: 48.6337053, lng: 17.6576151 },
    { lat: 48.6339872, lng: 17.6565027 },
    { lat: 48.6339819, lng: 17.656094 },
    { lat: 48.6340758, lng: 17.6556616 },
    { lat: 48.6341228, lng: 17.6546965 },
    { lat: 48.6339479, lng: 17.6538849 },
    { lat: 48.6339841, lng: 17.6537264 },
    { lat: 48.6340144, lng: 17.6535934 },
    { lat: 48.6340959, lng: 17.6534214 },
    { lat: 48.6342564, lng: 17.6529629 },
    { lat: 48.6342685, lng: 17.6520737 },
    { lat: 48.634322, lng: 17.6517177 },
    { lat: 48.6344337, lng: 17.6514112 },
    { lat: 48.6344741, lng: 17.6513507 },
    { lat: 48.6345597, lng: 17.6512224 },
    { lat: 48.6348342, lng: 17.6510223 },
    { lat: 48.6350207, lng: 17.6510076 },
    { lat: 48.6350483, lng: 17.6509948 },
    { lat: 48.6351819, lng: 17.650914 },
    { lat: 48.6352634, lng: 17.65072 },
    { lat: 48.6351299, lng: 17.6502977 },
    { lat: 48.6349461, lng: 17.6500336 },
    { lat: 48.6347002, lng: 17.6498012 },
    { lat: 48.6346203, lng: 17.6495712 },
    { lat: 48.6346591, lng: 17.64795 },
    { lat: 48.6345538, lng: 17.6471551 },
    { lat: 48.6343749, lng: 17.6458057 },
    { lat: 48.6341051, lng: 17.6451379 },
    { lat: 48.6335921, lng: 17.6435781 },
    { lat: 48.6334245, lng: 17.6428266 },
    { lat: 48.6333853, lng: 17.642362 },
    { lat: 48.6332775, lng: 17.6418672 },
    { lat: 48.6330816, lng: 17.6414785 },
    { lat: 48.632668, lng: 17.6403158 },
    { lat: 48.6326957, lng: 17.6399303 },
    { lat: 48.631303, lng: 17.6379378 },
    { lat: 48.6309371, lng: 17.6381705 },
    { lat: 48.6308144, lng: 17.638142 },
    { lat: 48.6302203, lng: 17.6384072 },
    { lat: 48.6300274, lng: 17.6385717 },
    { lat: 48.6296329, lng: 17.6386775 },
    { lat: 48.6294499, lng: 17.6386575 },
    { lat: 48.6289363, lng: 17.638333 },
    { lat: 48.6286016, lng: 17.638417 },
    { lat: 48.6283744, lng: 17.6385754 },
    { lat: 48.6280389, lng: 17.6389879 },
    { lat: 48.6279207, lng: 17.6395166 },
    { lat: 48.62778, lng: 17.6397972 },
    { lat: 48.6276701, lng: 17.6401698 },
    { lat: 48.625164, lng: 17.6366963 },
    { lat: 48.6245913, lng: 17.6383697 },
    { lat: 48.6243032, lng: 17.6388901 },
    { lat: 48.6244416, lng: 17.6390631 },
    { lat: 48.6236029, lng: 17.6411598 },
    { lat: 48.6219601, lng: 17.6439457 },
    { lat: 48.6206136, lng: 17.646033 },
    { lat: 48.6168062, lng: 17.6512199 },
    { lat: 48.616311, lng: 17.6517349 },
    { lat: 48.6160634, lng: 17.652143 },
    { lat: 48.6158725, lng: 17.6525948 },
    { lat: 48.6153892, lng: 17.6542029 },
    { lat: 48.6150531, lng: 17.6547702 },
    { lat: 48.6155626, lng: 17.655935 },
    { lat: 48.6152265, lng: 17.6563581 },
    { lat: 48.615216, lng: 17.6563793 },
    { lat: 48.6156184, lng: 17.6562924 },
    { lat: 48.6148264, lng: 17.6570693 },
    { lat: 48.6146225, lng: 17.6574376 },
    { lat: 48.6145758, lng: 17.6575219 },
    { lat: 48.614474, lng: 17.6577057 },
    { lat: 48.6136637, lng: 17.658289 },
    { lat: 48.6129849, lng: 17.6587777 },
    { lat: 48.6125963, lng: 17.658992 },
    { lat: 48.6119781, lng: 17.6591443 },
    { lat: 48.6113139, lng: 17.6591026 },
    { lat: 48.6109835, lng: 17.6593272 },
    { lat: 48.6097944, lng: 17.6607366 },
    { lat: 48.6094203, lng: 17.6608228 },
    { lat: 48.6092453, lng: 17.6609497 },
    { lat: 48.6082749, lng: 17.6619344 },
    { lat: 48.6074199, lng: 17.6626568 },
    { lat: 48.6072023, lng: 17.6627355 },
    { lat: 48.6066221, lng: 17.6633529 },
    { lat: 48.6058908, lng: 17.6637742 },
    { lat: 48.6047041, lng: 17.664626 },
    { lat: 48.6045737, lng: 17.6648011 },
    { lat: 48.6035703, lng: 17.6654193 },
    { lat: 48.6029029, lng: 17.6659074 },
    { lat: 48.6012502, lng: 17.6668369 },
    { lat: 48.6002624, lng: 17.6676579 },
    { lat: 48.5985381, lng: 17.6694472 },
    { lat: 48.5967881, lng: 17.6669142 },
    { lat: 48.5963702, lng: 17.6662212 },
    { lat: 48.5961276, lng: 17.6663882 },
    { lat: 48.5948093, lng: 17.6681734 },
    { lat: 48.5946837, lng: 17.6684926 },
    { lat: 48.5942297, lng: 17.668853 },
    { lat: 48.5940807, lng: 17.6690556 },
    { lat: 48.5939952, lng: 17.6692939 },
    { lat: 48.5938018, lng: 17.6695239 },
    { lat: 48.5934884, lng: 17.6701147 },
    { lat: 48.5934237, lng: 17.6705077 },
    { lat: 48.5931904, lng: 17.670785 },
    { lat: 48.5929132, lng: 17.6709766 },
    { lat: 48.592609, lng: 17.6716043 },
    { lat: 48.5922345, lng: 17.6719355 },
    { lat: 48.5916347, lng: 17.6722726 },
    { lat: 48.5913516, lng: 17.6730421 },
    { lat: 48.591104, lng: 17.6734345 },
    { lat: 48.5903144, lng: 17.6743531 },
    { lat: 48.5898328, lng: 17.6752858 },
    { lat: 48.5893783, lng: 17.6756743 },
    { lat: 48.5888714, lng: 17.6766387 },
    { lat: 48.588738, lng: 17.6767988 },
    { lat: 48.5885412, lng: 17.6769026 },
    { lat: 48.5880894, lng: 17.6774627 },
    { lat: 48.5875981, lng: 17.6784231 },
    { lat: 48.5873403, lng: 17.6787242 },
    { lat: 48.5868488, lng: 17.6794795 },
    { lat: 48.5867377, lng: 17.6795983 },
    { lat: 48.5866269, lng: 17.6797612 },
    { lat: 48.5861569, lng: 17.6805014 },
    { lat: 48.5856707, lng: 17.6815241 },
    { lat: 48.5853383, lng: 17.6820946 },
    { lat: 48.5846645, lng: 17.6829701 },
    { lat: 48.5845065, lng: 17.6833181 },
    { lat: 48.5844012, lng: 17.6834385 },
    { lat: 48.584237, lng: 17.6834696 },
    { lat: 48.583603, lng: 17.6840748 },
    { lat: 48.5834005, lng: 17.6843085 },
    { lat: 48.5831031, lng: 17.684792 },
    { lat: 48.5826003, lng: 17.6851633 },
    { lat: 48.5824902, lng: 17.6853911 },
    { lat: 48.5822524, lng: 17.6854357 },
    { lat: 48.582111, lng: 17.6855933 },
    { lat: 48.5826095, lng: 17.6865107 },
    { lat: 48.5832359, lng: 17.6874895 },
    { lat: 48.5840385, lng: 17.6892455 },
    { lat: 48.5849506, lng: 17.6905902 },
    { lat: 48.5845265, lng: 17.6910331 },
    { lat: 48.5840919, lng: 17.691833 },
  ],
  Chtelnica: [
    { lat: 48.528482, lng: 17.635853 },
    { lat: 48.5409913, lng: 17.6488415 },
    { lat: 48.5410055, lng: 17.6488873 },
    { lat: 48.540838, lng: 17.6490282 },
    { lat: 48.5405962, lng: 17.6494025 },
    { lat: 48.5401173, lng: 17.6497997 },
    { lat: 48.539687, lng: 17.650319 },
    { lat: 48.539913, lng: 17.6507544 },
    { lat: 48.5404779, lng: 17.6516267 },
    { lat: 48.5410099, lng: 17.652426 },
    { lat: 48.5415472, lng: 17.6531062 },
    { lat: 48.543543, lng: 17.6561681 },
    { lat: 48.5439677, lng: 17.6567322 },
    { lat: 48.5461492, lng: 17.6575654 },
    { lat: 48.5478796, lng: 17.6580777 },
    { lat: 48.5480847, lng: 17.6576934 },
    { lat: 48.5490627, lng: 17.6565555 },
    { lat: 48.5506174, lng: 17.654227 },
    { lat: 48.5510482, lng: 17.6537475 },
    { lat: 48.5520361, lng: 17.6530938 },
    { lat: 48.5528579, lng: 17.6522968 },
    { lat: 48.5544642, lng: 17.649976 },
    { lat: 48.5549888, lng: 17.649394 },
    { lat: 48.5553652, lng: 17.6488744 },
    { lat: 48.5562247, lng: 17.6474539 },
    { lat: 48.5568319, lng: 17.646653 },
    { lat: 48.5574372, lng: 17.6460595 },
    { lat: 48.5597251, lng: 17.6441858 },
    { lat: 48.5603851, lng: 17.6435288 },
    { lat: 48.5609744, lng: 17.6427674 },
    { lat: 48.5613769, lng: 17.6421103 },
    { lat: 48.562738, lng: 17.6408724 },
    { lat: 48.5640806, lng: 17.6393946 },
    { lat: 48.5656347, lng: 17.6378653 },
    { lat: 48.5660324, lng: 17.6376533 },
    { lat: 48.5674129, lng: 17.6366199 },
    { lat: 48.5697801, lng: 17.6345162 },
    { lat: 48.5713734, lng: 17.6333111 },
    { lat: 48.5716751, lng: 17.6330275 },
    { lat: 48.5723293, lng: 17.6326007 },
    { lat: 48.5740303, lng: 17.6316907 },
    { lat: 48.576087, lng: 17.6306869 },
    { lat: 48.5772665, lng: 17.6302669 },
    { lat: 48.5782653, lng: 17.6297157 },
    { lat: 48.578405, lng: 17.6297143 },
    { lat: 48.578478, lng: 17.629777 },
    { lat: 48.5786769, lng: 17.6301028 },
    { lat: 48.5788856, lng: 17.6301744 },
    { lat: 48.5792448, lng: 17.6301221 },
    { lat: 48.5794519, lng: 17.6300206 },
    { lat: 48.5801283, lng: 17.629337 },
    { lat: 48.581007, lng: 17.6287083 },
    { lat: 48.5818194, lng: 17.6278351 },
    { lat: 48.5841076, lng: 17.6250516 },
    { lat: 48.5869074, lng: 17.6218749 },
    { lat: 48.5875338, lng: 17.6209175 },
    { lat: 48.5889454, lng: 17.6178974 },
    { lat: 48.5926821, lng: 17.6135989 },
    { lat: 48.5931473, lng: 17.6130638 },
    { lat: 48.5945747, lng: 17.612922 },
    { lat: 48.5956284, lng: 17.6126175 },
    { lat: 48.5962192, lng: 17.6127111 },
    { lat: 48.5969295, lng: 17.6126037 },
    { lat: 48.5989972, lng: 17.6121757 },
    { lat: 48.6024304, lng: 17.6116086 },
    { lat: 48.6043546, lng: 17.609111 },
    { lat: 48.6048514, lng: 17.6094521 },
    { lat: 48.6052037, lng: 17.6095431 },
    { lat: 48.605428, lng: 17.6090147 },
    { lat: 48.6085242, lng: 17.605221 },
    { lat: 48.6153713, lng: 17.5982509 },
    { lat: 48.6219074, lng: 17.5909713 },
    { lat: 48.6217129, lng: 17.5903553 },
    { lat: 48.6247757, lng: 17.5884251 },
    { lat: 48.6296232, lng: 17.5903742 },
    { lat: 48.6298581, lng: 17.5900874 },
    { lat: 48.6301648, lng: 17.5896097 },
    { lat: 48.6301701, lng: 17.5896015 },
    { lat: 48.6305121, lng: 17.5890688 },
    { lat: 48.6309046, lng: 17.5886519 },
    { lat: 48.6349115, lng: 17.5821146 },
    { lat: 48.6358781, lng: 17.579968 },
    { lat: 48.63665, lng: 17.578487 },
    { lat: 48.636423, lng: 17.578557 },
    { lat: 48.63621, lng: 17.578579 },
    { lat: 48.636124, lng: 17.578611 },
    { lat: 48.63597, lng: 17.578601 },
    { lat: 48.635861, lng: 17.578612 },
    { lat: 48.635556, lng: 17.578714 },
    { lat: 48.635207, lng: 17.578792 },
    { lat: 48.634934, lng: 17.578931 },
    { lat: 48.634575, lng: 17.578972 },
    { lat: 48.634299, lng: 17.578936 },
    { lat: 48.634092, lng: 17.578975 },
    { lat: 48.633928, lng: 17.578975 },
    { lat: 48.633693, lng: 17.579064 },
    { lat: 48.633553, lng: 17.579097 },
    { lat: 48.633294, lng: 17.579086 },
    { lat: 48.632896, lng: 17.579087 },
    { lat: 48.632433, lng: 17.57887 },
    { lat: 48.6323, lng: 17.578921 },
    { lat: 48.632228, lng: 17.578944 },
    { lat: 48.63195, lng: 17.578775 },
    { lat: 48.631786, lng: 17.57879 },
    { lat: 48.63154, lng: 17.578755 },
    { lat: 48.631362, lng: 17.578684 },
    { lat: 48.6311, lng: 17.578509 },
    { lat: 48.630893, lng: 17.578579 },
    { lat: 48.630129, lng: 17.578293 },
    { lat: 48.629604, lng: 17.578189 },
    { lat: 48.629498, lng: 17.577914 },
    { lat: 48.629301, lng: 17.577824 },
    { lat: 48.629108, lng: 17.577633 },
    { lat: 48.6288, lng: 17.577498 },
    { lat: 48.628657, lng: 17.577335 },
    { lat: 48.62809, lng: 17.576577 },
    { lat: 48.627899, lng: 17.576372 },
    { lat: 48.627505, lng: 17.5761 },
    { lat: 48.627265, lng: 17.575968 },
    { lat: 48.627049, lng: 17.575886 },
    { lat: 48.626886, lng: 17.575857 },
    { lat: 48.626594, lng: 17.575746 },
    { lat: 48.62631, lng: 17.575701 },
    { lat: 48.626112, lng: 17.575714 },
    { lat: 48.625859, lng: 17.575873 },
    { lat: 48.625707, lng: 17.575893 },
    { lat: 48.625204, lng: 17.576124 },
    { lat: 48.625051, lng: 17.576156 },
    { lat: 48.624849, lng: 17.576243 },
    { lat: 48.624511, lng: 17.576477 },
    { lat: 48.624395, lng: 17.57654 },
    { lat: 48.624257, lng: 17.576631 },
    { lat: 48.623908, lng: 17.576872 },
    { lat: 48.623796, lng: 17.576903 },
    { lat: 48.623134, lng: 17.577282 },
    { lat: 48.622943, lng: 17.577351 },
    { lat: 48.622566, lng: 17.577407 },
    { lat: 48.622458, lng: 17.577482 },
    { lat: 48.621924, lng: 17.578285 },
    { lat: 48.621822, lng: 17.57855 },
    { lat: 48.621687, lng: 17.578819 },
    { lat: 48.621574, lng: 17.579096 },
    { lat: 48.621439, lng: 17.579557 },
    { lat: 48.621323, lng: 17.579787 },
    { lat: 48.621292, lng: 17.579892 },
    { lat: 48.6212376, lng: 17.5800826 },
    { lat: 48.621166, lng: 17.580296 },
    { lat: 48.62106, lng: 17.58075 },
    { lat: 48.620979, lng: 17.580902 },
    { lat: 48.620849, lng: 17.581339 },
    { lat: 48.620423, lng: 17.582067 },
    { lat: 48.6195, lng: 17.583435 },
    { lat: 48.619302, lng: 17.58376 },
    { lat: 48.619235, lng: 17.5838426 },
    { lat: 48.619147, lng: 17.58394 },
    { lat: 48.619066, lng: 17.58396 },
    { lat: 48.618902, lng: 17.584006 },
    { lat: 48.618604, lng: 17.584137 },
    { lat: 48.618461, lng: 17.584166 },
    { lat: 48.617994, lng: 17.58363 },
    { lat: 48.617965, lng: 17.583537 },
    { lat: 48.6179753, lng: 17.5834012 },
    { lat: 48.618044, lng: 17.582496 },
    { lat: 48.617785, lng: 17.582093 },
    { lat: 48.617664, lng: 17.581848 },
    { lat: 48.617414, lng: 17.581511 },
    { lat: 48.617339, lng: 17.5813 },
    { lat: 48.617058, lng: 17.580922 },
    { lat: 48.616994, lng: 17.580484 },
    { lat: 48.616781, lng: 17.579038 },
    { lat: 48.616354, lng: 17.579225 },
    { lat: 48.6162079, lng: 17.5794732 },
    { lat: 48.615795, lng: 17.579762 },
    { lat: 48.615769, lng: 17.579789 },
    { lat: 48.615389, lng: 17.579007 },
    { lat: 48.614858, lng: 17.578462 },
    { lat: 48.614224, lng: 17.577693 },
    { lat: 48.612556, lng: 17.574762 },
    { lat: 48.610913, lng: 17.571876 },
    { lat: 48.60924, lng: 17.571504 },
    { lat: 48.607491, lng: 17.56978 },
    { lat: 48.606056, lng: 17.5724 },
    { lat: 48.603904, lng: 17.574985 },
    { lat: 48.603625, lng: 17.574827 },
    { lat: 48.603494, lng: 17.574842 },
    { lat: 48.603185, lng: 17.575035 },
    { lat: 48.602894, lng: 17.57493 },
    { lat: 48.602385, lng: 17.574326 },
    { lat: 48.602259, lng: 17.57411 },
    { lat: 48.602179, lng: 17.574088 },
    { lat: 48.601952, lng: 17.574303 },
    { lat: 48.601618, lng: 17.574224 },
    { lat: 48.601455, lng: 17.574168 },
    { lat: 48.601271, lng: 17.573984 },
    { lat: 48.601298, lng: 17.573882 },
    { lat: 48.601189, lng: 17.573737 },
    { lat: 48.59887, lng: 17.576051 },
    { lat: 48.596394, lng: 17.576423 },
    { lat: 48.594938, lng: 17.576647 },
    { lat: 48.594898, lng: 17.574002 },
    { lat: 48.594184, lng: 17.573816 },
    { lat: 48.59437, lng: 17.5718 },
    { lat: 48.594265, lng: 17.571645 },
    { lat: 48.594158, lng: 17.571603 },
    { lat: 48.594134, lng: 17.571478 },
    { lat: 48.594033, lng: 17.571255 },
    { lat: 48.593944, lng: 17.57104 },
    { lat: 48.593881, lng: 17.570758 },
    { lat: 48.593785, lng: 17.570677 },
    { lat: 48.593566, lng: 17.570616 },
    { lat: 48.593406, lng: 17.570283 },
    { lat: 48.593125, lng: 17.570301 },
    { lat: 48.593036, lng: 17.570191 },
    { lat: 48.593023, lng: 17.570142 },
    { lat: 48.592991, lng: 17.570029 },
    { lat: 48.59277, lng: 17.569806 },
    { lat: 48.592772, lng: 17.569605 },
    { lat: 48.592858, lng: 17.569362 },
    { lat: 48.592969, lng: 17.568986 },
    { lat: 48.59311, lng: 17.568609 },
    { lat: 48.593239, lng: 17.567874 },
    { lat: 48.593397, lng: 17.567799 },
    { lat: 48.593576, lng: 17.567764 },
    { lat: 48.593844, lng: 17.567912 },
    { lat: 48.594312, lng: 17.567815 },
    { lat: 48.594385, lng: 17.567802 },
    { lat: 48.594382, lng: 17.567734 },
    { lat: 48.59444, lng: 17.567548 },
    { lat: 48.594398, lng: 17.567405 },
    { lat: 48.594375, lng: 17.567209 },
    { lat: 48.59437, lng: 17.566836 },
    { lat: 48.594257, lng: 17.5667 },
    { lat: 48.594227, lng: 17.566486 },
    { lat: 48.594168, lng: 17.566175 },
    { lat: 48.594179, lng: 17.56601 },
    { lat: 48.594158, lng: 17.565722 },
    { lat: 48.594114, lng: 17.565568 },
    { lat: 48.594113, lng: 17.56543 },
    { lat: 48.594042, lng: 17.565349 },
    { lat: 48.59381, lng: 17.565137 },
    { lat: 48.593703, lng: 17.564976 },
    { lat: 48.593578, lng: 17.564776 },
    { lat: 48.59346, lng: 17.564706 },
    { lat: 48.593423, lng: 17.564618 },
    { lat: 48.59333, lng: 17.564507 },
    { lat: 48.593229, lng: 17.564501 },
    { lat: 48.593138, lng: 17.564267 },
    { lat: 48.593085, lng: 17.56421 },
    { lat: 48.593044, lng: 17.564192 },
    { lat: 48.592988, lng: 17.56399 },
    { lat: 48.59289, lng: 17.56394 },
    { lat: 48.592874, lng: 17.563847 },
    { lat: 48.592817, lng: 17.563774 },
    { lat: 48.592661, lng: 17.563651 },
    { lat: 48.592593, lng: 17.563387 },
    { lat: 48.591907, lng: 17.562668 },
    { lat: 48.591886, lng: 17.562655 },
    { lat: 48.591889, lng: 17.562637 },
    { lat: 48.591604, lng: 17.562501 },
    { lat: 48.591433, lng: 17.562436 },
    { lat: 48.591337, lng: 17.562376 },
    { lat: 48.591293, lng: 17.562384 },
    { lat: 48.590614, lng: 17.562149 },
    { lat: 48.590345, lng: 17.561807 },
    { lat: 48.590207, lng: 17.561694 },
    { lat: 48.590085, lng: 17.561422 },
    { lat: 48.590057, lng: 17.561269 },
    { lat: 48.590039, lng: 17.561217 },
    { lat: 48.589956, lng: 17.560983 },
    { lat: 48.589889, lng: 17.560899 },
    { lat: 48.58983, lng: 17.560859 },
    { lat: 48.58972, lng: 17.560785 },
    { lat: 48.58957, lng: 17.560681 },
    { lat: 48.589304, lng: 17.560595 },
    { lat: 48.588987, lng: 17.560308 },
    { lat: 48.588837, lng: 17.560171 },
    { lat: 48.588661, lng: 17.56012 },
    { lat: 48.588516, lng: 17.560061 },
    { lat: 48.587752, lng: 17.560014 },
    { lat: 48.587431, lng: 17.559771 },
    { lat: 48.587108, lng: 17.559461 },
    { lat: 48.586856, lng: 17.559298 },
    { lat: 48.586706, lng: 17.559221 },
    { lat: 48.586462, lng: 17.559184 },
    { lat: 48.586403, lng: 17.559175 },
    { lat: 48.585662, lng: 17.559239 },
    { lat: 48.585318, lng: 17.559245 },
    { lat: 48.585303, lng: 17.559244 },
    { lat: 48.585274, lng: 17.559208 },
    { lat: 48.585013, lng: 17.558891 },
    { lat: 48.584708, lng: 17.558531 },
    { lat: 48.584553, lng: 17.55842 },
    { lat: 48.584314, lng: 17.558271 },
    { lat: 48.584115, lng: 17.558158 },
    { lat: 48.584035, lng: 17.558106 },
    { lat: 48.583605, lng: 17.557888 },
    { lat: 48.583428, lng: 17.557737 },
    { lat: 48.582963, lng: 17.557581 },
    { lat: 48.582553, lng: 17.557442 },
    { lat: 48.58255, lng: 17.557456 },
    { lat: 48.582501, lng: 17.557492 },
    { lat: 48.58244, lng: 17.557538 },
    { lat: 48.58239, lng: 17.557558 },
    { lat: 48.582027, lng: 17.55724 },
    { lat: 48.581856, lng: 17.557189 },
    { lat: 48.581695, lng: 17.557056 },
    { lat: 48.581616, lng: 17.556971 },
    { lat: 48.580305, lng: 17.557144 },
    { lat: 48.579475, lng: 17.558017 },
    { lat: 48.579251, lng: 17.558292 },
    { lat: 48.579064, lng: 17.558235 },
    { lat: 48.578994, lng: 17.558014 },
    { lat: 48.578844, lng: 17.55811 },
    { lat: 48.578773, lng: 17.558001 },
    { lat: 48.578576, lng: 17.558203 },
    { lat: 48.578476, lng: 17.558132 },
    { lat: 48.578389, lng: 17.557998 },
    { lat: 48.578319, lng: 17.557597 },
    { lat: 48.578099, lng: 17.557953 },
    { lat: 48.57775, lng: 17.558698 },
    { lat: 48.577631, lng: 17.559045 },
    { lat: 48.577194, lng: 17.558618 },
    { lat: 48.576866, lng: 17.558174 },
    { lat: 48.576761, lng: 17.55837 },
    { lat: 48.576482, lng: 17.558174 },
    { lat: 48.576326, lng: 17.558005 },
    { lat: 48.57607, lng: 17.558068 },
    { lat: 48.575905, lng: 17.55819 },
    { lat: 48.575822, lng: 17.558186 },
    { lat: 48.575689, lng: 17.558021 },
    { lat: 48.575183, lng: 17.558139 },
    { lat: 48.574975, lng: 17.558306 },
    { lat: 48.574814, lng: 17.558529 },
    { lat: 48.574627, lng: 17.558889 },
    { lat: 48.574535, lng: 17.559008 },
    { lat: 48.574341, lng: 17.559434 },
    { lat: 48.573747, lng: 17.560902 },
    { lat: 48.573664, lng: 17.56117 },
    { lat: 48.573605, lng: 17.56155 },
    { lat: 48.573331, lng: 17.562313 },
    { lat: 48.573265, lng: 17.562437 },
    { lat: 48.572763, lng: 17.564186 },
    { lat: 48.57278, lng: 17.564207 },
    { lat: 48.572805, lng: 17.564239 },
    { lat: 48.572857, lng: 17.564303 },
    { lat: 48.573355, lng: 17.564402 },
    { lat: 48.5736, lng: 17.564535 },
    { lat: 48.574003, lng: 17.5651 },
    { lat: 48.574234, lng: 17.565509 },
    { lat: 48.574516, lng: 17.565803 },
    { lat: 48.574637, lng: 17.566413 },
    { lat: 48.574753, lng: 17.566518 },
    { lat: 48.574832, lng: 17.566778 },
    { lat: 48.574842, lng: 17.566957 },
    { lat: 48.574882, lng: 17.567249 },
    { lat: 48.575029, lng: 17.567563 },
    { lat: 48.575048, lng: 17.567824 },
    { lat: 48.57527, lng: 17.568276 },
    { lat: 48.575413, lng: 17.568491 },
    { lat: 48.57542, lng: 17.568681 },
    { lat: 48.575505, lng: 17.568931 },
    { lat: 48.575599, lng: 17.569049 },
    { lat: 48.575673, lng: 17.56993 },
    { lat: 48.575623, lng: 17.570379 },
    { lat: 48.575361, lng: 17.570497 },
    { lat: 48.575341, lng: 17.570507 },
    { lat: 48.575477, lng: 17.570799 },
    { lat: 48.575529, lng: 17.57093 },
    { lat: 48.575574, lng: 17.571106 },
    { lat: 48.575669, lng: 17.571288 },
    { lat: 48.575841, lng: 17.571614 },
    { lat: 48.57586, lng: 17.571653 },
    { lat: 48.575895, lng: 17.571759 },
    { lat: 48.575974, lng: 17.571945 },
    { lat: 48.576048, lng: 17.5721 },
    { lat: 48.576049, lng: 17.572128 },
    { lat: 48.576053, lng: 17.572352 },
    { lat: 48.576079, lng: 17.572592 },
    { lat: 48.576034, lng: 17.572703 },
    { lat: 48.57603, lng: 17.572749 },
    { lat: 48.576018, lng: 17.572963 },
    { lat: 48.575986, lng: 17.573118 },
    { lat: 48.576009, lng: 17.573286 },
    { lat: 48.576008, lng: 17.573286 },
    { lat: 48.575924, lng: 17.573662 },
    { lat: 48.575855, lng: 17.573666 },
    { lat: 48.575828, lng: 17.573718 },
    { lat: 48.575801, lng: 17.574046 },
    { lat: 48.57575, lng: 17.574339 },
    { lat: 48.575707, lng: 17.57448 },
    { lat: 48.575655, lng: 17.574844 },
    { lat: 48.575601, lng: 17.574985 },
    { lat: 48.575465, lng: 17.575086 },
    { lat: 48.57538, lng: 17.575114 },
    { lat: 48.575255, lng: 17.575411 },
    { lat: 48.575155, lng: 17.575537 },
    { lat: 48.575141, lng: 17.57559 },
    { lat: 48.575127, lng: 17.57564 },
    { lat: 48.575156, lng: 17.575756 },
    { lat: 48.575146, lng: 17.575785 },
    { lat: 48.575129, lng: 17.57583 },
    { lat: 48.575087, lng: 17.575946 },
    { lat: 48.574988, lng: 17.576181 },
    { lat: 48.574956, lng: 17.576281 },
    { lat: 48.574844, lng: 17.576431 },
    { lat: 48.574817, lng: 17.576585 },
    { lat: 48.574777, lng: 17.576647 },
    { lat: 48.574715, lng: 17.577116 },
    { lat: 48.574631, lng: 17.57716 },
    { lat: 48.574557, lng: 17.57741 },
    { lat: 48.574538, lng: 17.577577 },
    { lat: 48.574574, lng: 17.577615 },
    { lat: 48.574658, lng: 17.577694 },
    { lat: 48.574652, lng: 17.577733 },
    { lat: 48.574629, lng: 17.577896 },
    { lat: 48.5746, lng: 17.577958 },
    { lat: 48.574549, lng: 17.57806 },
    { lat: 48.574469, lng: 17.578303 },
    { lat: 48.574435, lng: 17.578405 },
    { lat: 48.574527, lng: 17.57871 },
    { lat: 48.574539, lng: 17.578924 },
    { lat: 48.574489, lng: 17.579104 },
    { lat: 48.574461, lng: 17.579169 },
    { lat: 48.574456, lng: 17.579182 },
    { lat: 48.574393, lng: 17.579322 },
    { lat: 48.573921, lng: 17.579493 },
    { lat: 48.573881, lng: 17.579507 },
    { lat: 48.573869, lng: 17.579496 },
    { lat: 48.573374, lng: 17.580439 },
    { lat: 48.573, lng: 17.580855 },
    { lat: 48.572812, lng: 17.581108 },
    { lat: 48.572776, lng: 17.581108 },
    { lat: 48.57272, lng: 17.581104 },
    { lat: 48.572573, lng: 17.580834 },
    { lat: 48.572384, lng: 17.580408 },
    { lat: 48.572096, lng: 17.579861 },
    { lat: 48.571923, lng: 17.579505 },
    { lat: 48.571807, lng: 17.579347 },
    { lat: 48.571757, lng: 17.579404 },
    { lat: 48.571711, lng: 17.579456 },
    { lat: 48.571679, lng: 17.579492 },
    { lat: 48.57164, lng: 17.579538 },
    { lat: 48.571576, lng: 17.579611 },
    { lat: 48.571462, lng: 17.579739 },
    { lat: 48.571349, lng: 17.579867 },
    { lat: 48.571244, lng: 17.580031 },
    { lat: 48.571154, lng: 17.58017 },
    { lat: 48.570903, lng: 17.580461 },
    { lat: 48.570644, lng: 17.580841 },
    { lat: 48.570548, lng: 17.581068 },
    { lat: 48.570461, lng: 17.581182 },
    { lat: 48.570307, lng: 17.581315 },
    { lat: 48.570085, lng: 17.581617 },
    { lat: 48.569819, lng: 17.581901 },
    { lat: 48.569929, lng: 17.582124 },
    { lat: 48.569859, lng: 17.582212 },
    { lat: 48.56982, lng: 17.582261 },
    { lat: 48.569728, lng: 17.582315 },
    { lat: 48.569612, lng: 17.582431 },
    { lat: 48.569454, lng: 17.582649 },
    { lat: 48.569294, lng: 17.582915 },
    { lat: 48.569221, lng: 17.583036 },
    { lat: 48.569113, lng: 17.583208 },
    { lat: 48.56868, lng: 17.583902 },
    { lat: 48.569317, lng: 17.584901 },
    { lat: 48.569335, lng: 17.584944 },
    { lat: 48.569205, lng: 17.585152 },
    { lat: 48.5683835, lng: 17.5860971 },
    { lat: 48.5686047, lng: 17.5859295 },
    { lat: 48.5692181, lng: 17.5871719 },
    { lat: 48.5697825, lng: 17.5877701 },
    { lat: 48.5691897, lng: 17.5881938 },
    { lat: 48.568204, lng: 17.588205 },
    { lat: 48.567812, lng: 17.588574 },
    { lat: 48.56795, lng: 17.589043 },
    { lat: 48.5681, lng: 17.589552 },
    { lat: 48.5672793, lng: 17.5902102 },
    { lat: 48.56708, lng: 17.59037 },
    { lat: 48.567201, lng: 17.590849 },
    { lat: 48.567233, lng: 17.590975 },
    { lat: 48.567104, lng: 17.591134 },
    { lat: 48.566596, lng: 17.591131 },
    { lat: 48.566426, lng: 17.591303 },
    { lat: 48.566101, lng: 17.591324 },
    { lat: 48.565915, lng: 17.591426 },
    { lat: 48.564999, lng: 17.59221 },
    { lat: 48.5649689, lng: 17.5923268 },
    { lat: 48.5640486, lng: 17.5929464 },
    { lat: 48.563371, lng: 17.593147 },
    { lat: 48.563012, lng: 17.5932 },
    { lat: 48.562905, lng: 17.59323 },
    { lat: 48.562781, lng: 17.593156 },
    { lat: 48.562723, lng: 17.59295 },
    { lat: 48.562612, lng: 17.5928 },
    { lat: 48.562546, lng: 17.592629 },
    { lat: 48.562458, lng: 17.592434 },
    { lat: 48.562438, lng: 17.592462 },
    { lat: 48.562424, lng: 17.592483 },
    { lat: 48.562178, lng: 17.593362 },
    { lat: 48.561713, lng: 17.594376 },
    { lat: 48.561134, lng: 17.595151 },
    { lat: 48.560284, lng: 17.595992 },
    { lat: 48.55957, lng: 17.596698 },
    { lat: 48.558691, lng: 17.597249 },
    { lat: 48.558188, lng: 17.597347 },
    { lat: 48.55685, lng: 17.597609 },
    { lat: 48.556424, lng: 17.598047 },
    { lat: 48.55638, lng: 17.598091 },
    { lat: 48.556057, lng: 17.598386 },
    { lat: 48.555803, lng: 17.598696 },
    { lat: 48.555631, lng: 17.598908 },
    { lat: 48.555335, lng: 17.599376 },
    { lat: 48.554946, lng: 17.599919 },
    { lat: 48.554545, lng: 17.600483 },
    { lat: 48.554208, lng: 17.600996 },
    { lat: 48.553691, lng: 17.601772 },
    { lat: 48.553673, lng: 17.601808 },
    { lat: 48.553277, lng: 17.602566 },
    { lat: 48.553214, lng: 17.602686 },
    { lat: 48.552652, lng: 17.603339 },
    { lat: 48.552192, lng: 17.603875 },
    { lat: 48.551732, lng: 17.604411 },
    { lat: 48.551508, lng: 17.604673 },
    { lat: 48.551065, lng: 17.605189 },
    { lat: 48.550602, lng: 17.605732 },
    { lat: 48.55016, lng: 17.606248 },
    { lat: 48.550148, lng: 17.606262 },
    { lat: 48.549924, lng: 17.606524 },
    { lat: 48.549694, lng: 17.606792 },
    { lat: 48.549011, lng: 17.607593 },
    { lat: 48.548781, lng: 17.607861 },
    { lat: 48.548559, lng: 17.60812 },
    { lat: 48.548277, lng: 17.608454 },
    { lat: 48.548165, lng: 17.608584 },
    { lat: 48.548137, lng: 17.608617 },
    { lat: 48.547897, lng: 17.608897 },
    { lat: 48.54767, lng: 17.609173 },
    { lat: 48.547435, lng: 17.60946 },
    { lat: 48.547194, lng: 17.609742 },
    { lat: 48.546722, lng: 17.610291 },
    { lat: 48.546482, lng: 17.61057 },
    { lat: 48.546241, lng: 17.610851 },
    { lat: 48.545764, lng: 17.611407 },
    { lat: 48.545517, lng: 17.611694 },
    { lat: 48.545282, lng: 17.61197 },
    { lat: 48.545051, lng: 17.61224 },
    { lat: 48.544804, lng: 17.612529 },
    { lat: 48.544562, lng: 17.612813 },
    { lat: 48.544325, lng: 17.613091 },
    { lat: 48.543845, lng: 17.613653 },
    { lat: 48.543272, lng: 17.614324 },
    { lat: 48.543257, lng: 17.614342 },
    { lat: 48.543234, lng: 17.614359 },
    { lat: 48.542313, lng: 17.61179 },
    { lat: 48.542049, lng: 17.611019 },
    { lat: 48.542017, lng: 17.610892 },
    { lat: 48.541992, lng: 17.610936 },
    { lat: 48.541727, lng: 17.611388 },
    { lat: 48.541655, lng: 17.611527 },
    { lat: 48.541412, lng: 17.611988 },
    { lat: 48.541319, lng: 17.612136 },
    { lat: 48.541244, lng: 17.612246 },
    { lat: 48.541144, lng: 17.612427 },
    { lat: 48.541069, lng: 17.61253 },
    { lat: 48.540902, lng: 17.612794 },
    { lat: 48.540644, lng: 17.613204 },
    { lat: 48.54043, lng: 17.613557 },
    { lat: 48.540276, lng: 17.613816 },
    { lat: 48.540222, lng: 17.613957 },
    { lat: 48.540193, lng: 17.614003 },
    { lat: 48.540118, lng: 17.614104 },
    { lat: 48.539992, lng: 17.614273 },
    { lat: 48.539918, lng: 17.614387 },
    { lat: 48.539876, lng: 17.614458 },
    { lat: 48.539765, lng: 17.614671 },
    { lat: 48.539742, lng: 17.614744 },
    { lat: 48.539711, lng: 17.614931 },
    { lat: 48.539693, lng: 17.615121 },
    { lat: 48.539689, lng: 17.615232 },
    { lat: 48.539645, lng: 17.615667 },
    { lat: 48.539487, lng: 17.616198 },
    { lat: 48.539424, lng: 17.616483 },
    { lat: 48.539228, lng: 17.61717 },
    { lat: 48.539171, lng: 17.617467 },
    { lat: 48.539163, lng: 17.617509 },
    { lat: 48.539153, lng: 17.617686 },
    { lat: 48.539118, lng: 17.617854 },
    { lat: 48.539065, lng: 17.618054 },
    { lat: 48.539058, lng: 17.618125 },
    { lat: 48.539055, lng: 17.61815 },
    { lat: 48.538804, lng: 17.618998 },
    { lat: 48.538742, lng: 17.619244 },
    { lat: 48.53843, lng: 17.620835 },
    { lat: 48.538447, lng: 17.620853 },
    { lat: 48.538438, lng: 17.620871 },
    { lat: 48.538397, lng: 17.620949 },
    { lat: 48.538358, lng: 17.621019 },
    { lat: 48.53832, lng: 17.621091 },
    { lat: 48.538315, lng: 17.621081 },
    { lat: 48.538294, lng: 17.62104 },
    { lat: 48.538063, lng: 17.620575 },
    { lat: 48.538054, lng: 17.620578 },
    { lat: 48.537805, lng: 17.620656 },
    { lat: 48.537752, lng: 17.620673 },
    { lat: 48.537695, lng: 17.62069 },
    { lat: 48.537608, lng: 17.620718 },
    { lat: 48.537577, lng: 17.620727 },
    { lat: 48.537563, lng: 17.620732 },
    { lat: 48.537512, lng: 17.620748 },
    { lat: 48.537492, lng: 17.620755 },
    { lat: 48.537431, lng: 17.620793 },
    { lat: 48.537363, lng: 17.620836 },
    { lat: 48.537317, lng: 17.620866 },
    { lat: 48.537269, lng: 17.620897 },
    { lat: 48.537172, lng: 17.620959 },
    { lat: 48.537132, lng: 17.620985 },
    { lat: 48.537093, lng: 17.62101 },
    { lat: 48.537057, lng: 17.621033 },
    { lat: 48.537015, lng: 17.62106 },
    { lat: 48.53697, lng: 17.621088 },
    { lat: 48.536919, lng: 17.621121 },
    { lat: 48.536878, lng: 17.621147 },
    { lat: 48.536846, lng: 17.621168 },
    { lat: 48.536802, lng: 17.621195 },
    { lat: 48.536564, lng: 17.62135 },
    { lat: 48.537163, lng: 17.622788 },
    { lat: 48.537189, lng: 17.62285 },
    { lat: 48.537627, lng: 17.623903 },
    { lat: 48.537609, lng: 17.623919 },
    { lat: 48.537571, lng: 17.623955 },
    { lat: 48.53753, lng: 17.623992 },
    { lat: 48.537503, lng: 17.624016 },
    { lat: 48.537478, lng: 17.624039 },
    { lat: 48.537205, lng: 17.624288 },
    { lat: 48.537095, lng: 17.624389 },
    { lat: 48.537039, lng: 17.62444 },
    { lat: 48.536991, lng: 17.624484 },
    { lat: 48.536915, lng: 17.624553 },
    { lat: 48.536901, lng: 17.624566 },
    { lat: 48.536814, lng: 17.624334 },
    { lat: 48.536774, lng: 17.624374 },
    { lat: 48.536723, lng: 17.624425 },
    { lat: 48.536676, lng: 17.624471 },
    { lat: 48.536629, lng: 17.624518 },
    { lat: 48.53658, lng: 17.624567 },
    { lat: 48.536537, lng: 17.624609 },
    { lat: 48.536484, lng: 17.624662 },
    { lat: 48.536448, lng: 17.624699 },
    { lat: 48.536405, lng: 17.62474 },
    { lat: 48.536369, lng: 17.624776 },
    { lat: 48.536286, lng: 17.624859 },
    { lat: 48.536242, lng: 17.624902 },
    { lat: 48.536198, lng: 17.624946 },
    { lat: 48.536156, lng: 17.624988 },
    { lat: 48.536075, lng: 17.625068 },
    { lat: 48.536049, lng: 17.625094 },
    { lat: 48.536028, lng: 17.625115 },
    { lat: 48.535974, lng: 17.625168 },
    { lat: 48.535929, lng: 17.625214 },
    { lat: 48.535892, lng: 17.625251 },
    { lat: 48.53585, lng: 17.625292 },
    { lat: 48.53581, lng: 17.625331 },
    { lat: 48.535801, lng: 17.62534 },
    { lat: 48.535731, lng: 17.62517 },
    { lat: 48.5357, lng: 17.625095 },
    { lat: 48.535275, lng: 17.624068 },
    { lat: 48.534854, lng: 17.622899 },
    { lat: 48.534779, lng: 17.622948 },
    { lat: 48.534746, lng: 17.62297 },
    { lat: 48.534676, lng: 17.623014 },
    { lat: 48.53464, lng: 17.623038 },
    { lat: 48.534457, lng: 17.623157 },
    { lat: 48.534243, lng: 17.623296 },
    { lat: 48.534658, lng: 17.624767 },
    { lat: 48.534407, lng: 17.624973 },
    { lat: 48.534161, lng: 17.625175 },
    { lat: 48.533931, lng: 17.625365 },
    { lat: 48.533692, lng: 17.625562 },
    { lat: 48.533409, lng: 17.624785 },
    { lat: 48.533236, lng: 17.624911 },
    { lat: 48.53281, lng: 17.62522 },
    { lat: 48.533629, lng: 17.627255 },
    { lat: 48.533661, lng: 17.627336 },
    { lat: 48.534198, lng: 17.628667 },
    { lat: 48.534317, lng: 17.628966 },
    { lat: 48.533446, lng: 17.629713 },
    { lat: 48.53316, lng: 17.62915 },
    { lat: 48.532551, lng: 17.629829 },
    { lat: 48.532528, lng: 17.629855 },
    { lat: 48.531945, lng: 17.630507 },
    { lat: 48.531084, lng: 17.631293 },
    { lat: 48.530752, lng: 17.630794 },
    { lat: 48.530491, lng: 17.631246 },
    { lat: 48.530465, lng: 17.631288 },
    { lat: 48.530048, lng: 17.632013 },
    { lat: 48.52977, lng: 17.632783 },
    { lat: 48.5295, lng: 17.632548 },
    { lat: 48.529095, lng: 17.633697 },
    { lat: 48.528887, lng: 17.634291 },
    { lat: 48.528485, lng: 17.635812 },
    { lat: 48.528482, lng: 17.635853 },
  ],
  Bašovce: [
    { lat: 48.639368, lng: 17.820472 },
    { lat: 48.639358, lng: 17.8199 },
    { lat: 48.63943, lng: 17.818174 },
    { lat: 48.6395, lng: 17.81673 },
    { lat: 48.639599, lng: 17.815194 },
    { lat: 48.639603, lng: 17.815108 },
    { lat: 48.639632, lng: 17.814693 },
    { lat: 48.639636, lng: 17.814622 },
    { lat: 48.639639, lng: 17.814549 },
    { lat: 48.639659, lng: 17.814098 },
    { lat: 48.639687, lng: 17.81365 },
    { lat: 48.639748, lng: 17.812995 },
    { lat: 48.639842, lng: 17.811947 },
    { lat: 48.639897, lng: 17.811435 },
    { lat: 48.63998, lng: 17.810557 },
    { lat: 48.640038, lng: 17.809904 },
    { lat: 48.6401, lng: 17.80861 },
    { lat: 48.640056, lng: 17.808594 },
    { lat: 48.640055, lng: 17.808647 },
    { lat: 48.639972, lng: 17.808601 },
    { lat: 48.639751, lng: 17.808595 },
    { lat: 48.639578, lng: 17.808598 },
    { lat: 48.639185, lng: 17.808642 },
    { lat: 48.63896, lng: 17.80862 },
    { lat: 48.638736, lng: 17.808486 },
    { lat: 48.638736, lng: 17.808438 },
    { lat: 48.638683, lng: 17.808343 },
    { lat: 48.638634, lng: 17.80815 },
    { lat: 48.638605, lng: 17.807868 },
    { lat: 48.638574, lng: 17.807381 },
    { lat: 48.638506, lng: 17.80721 },
    { lat: 48.638457, lng: 17.807118 },
    { lat: 48.638371, lng: 17.80709 },
    { lat: 48.63779, lng: 17.806902 },
    { lat: 48.637601, lng: 17.806841 },
    { lat: 48.637636, lng: 17.805872 },
    { lat: 48.637641, lng: 17.805762 },
    { lat: 48.637245, lng: 17.805596 },
    { lat: 48.63723, lng: 17.805261 },
    { lat: 48.637219, lng: 17.805057 },
    { lat: 48.63719, lng: 17.804891 },
    { lat: 48.637141, lng: 17.804707 },
    { lat: 48.63709, lng: 17.804418 },
    { lat: 48.637106, lng: 17.804305 },
    { lat: 48.637211, lng: 17.803883 },
    { lat: 48.637236, lng: 17.803761 },
    { lat: 48.637266, lng: 17.803615 },
    { lat: 48.637342, lng: 17.803368 },
    { lat: 48.637351, lng: 17.802994 },
    { lat: 48.637362, lng: 17.802713 },
    { lat: 48.637399, lng: 17.802451 },
    { lat: 48.637414, lng: 17.802204 },
    { lat: 48.63745, lng: 17.801772 },
    { lat: 48.637479, lng: 17.801483 },
    { lat: 48.637484, lng: 17.801435 },
    { lat: 48.637487, lng: 17.801408 },
    { lat: 48.637592, lng: 17.80049 },
    { lat: 48.637651, lng: 17.799806 },
    { lat: 48.637681, lng: 17.799578 },
    { lat: 48.637696, lng: 17.799286 },
    { lat: 48.637752, lng: 17.79857 },
    { lat: 48.637769, lng: 17.798424 },
    { lat: 48.637931, lng: 17.798424 },
    { lat: 48.638448, lng: 17.798553 },
    { lat: 48.638639, lng: 17.798637 },
    { lat: 48.638806, lng: 17.798687 },
    { lat: 48.638931, lng: 17.798701 },
    { lat: 48.639051, lng: 17.798781 },
    { lat: 48.639302, lng: 17.798889 },
    { lat: 48.639729, lng: 17.798791 },
    { lat: 48.640091, lng: 17.798703 },
    { lat: 48.640402, lng: 17.798558 },
    { lat: 48.640395, lng: 17.79849 },
    { lat: 48.640411, lng: 17.798456 },
    { lat: 48.640364, lng: 17.797809 },
    { lat: 48.640272, lng: 17.797129 },
    { lat: 48.640266, lng: 17.79708 },
    { lat: 48.640256, lng: 17.797009 },
    { lat: 48.640248, lng: 17.796954 },
    { lat: 48.640244, lng: 17.796919 },
    { lat: 48.640159, lng: 17.796462 },
    { lat: 48.640126, lng: 17.79631 },
    { lat: 48.640076, lng: 17.796074 },
    { lat: 48.639978, lng: 17.795633 },
    { lat: 48.639915, lng: 17.79524 },
    { lat: 48.639837, lng: 17.795047 },
    { lat: 48.639799, lng: 17.794866 },
    { lat: 48.639803, lng: 17.794837 },
    { lat: 48.639739, lng: 17.794735 },
    { lat: 48.639679, lng: 17.794719 },
    { lat: 48.639649, lng: 17.794756 },
    { lat: 48.639592, lng: 17.794782 },
    { lat: 48.639549, lng: 17.794749 },
    { lat: 48.639535, lng: 17.794476 },
    { lat: 48.639458, lng: 17.794423 },
    { lat: 48.63936, lng: 17.79441 },
    { lat: 48.639318, lng: 17.794345 },
    { lat: 48.639301, lng: 17.794231 },
    { lat: 48.639316, lng: 17.794068 },
    { lat: 48.63932, lng: 17.793946 },
    { lat: 48.639287, lng: 17.793853 },
    { lat: 48.639182, lng: 17.793777 },
    { lat: 48.639002, lng: 17.79373 },
    { lat: 48.638982, lng: 17.793573 },
    { lat: 48.639052, lng: 17.793381 },
    { lat: 48.639182, lng: 17.793257 },
    { lat: 48.639339, lng: 17.793061 },
    { lat: 48.639399, lng: 17.7929 },
    { lat: 48.639398, lng: 17.792881 },
    { lat: 48.639415, lng: 17.792774 },
    { lat: 48.639414, lng: 17.792672 },
    { lat: 48.639328, lng: 17.79235 },
    { lat: 48.639203, lng: 17.791738 },
    { lat: 48.639126, lng: 17.791712 },
    { lat: 48.638965, lng: 17.791551 },
    { lat: 48.638812, lng: 17.791197 },
    { lat: 48.638548, lng: 17.790745 },
    { lat: 48.638352, lng: 17.790896 },
    { lat: 48.63816, lng: 17.791055 },
    { lat: 48.638034, lng: 17.791061 },
    { lat: 48.637567, lng: 17.790778 },
    { lat: 48.637528, lng: 17.790745 },
    { lat: 48.637712, lng: 17.789992 },
    { lat: 48.637783, lng: 17.789583 },
    { lat: 48.637607, lng: 17.789549 },
    { lat: 48.637545, lng: 17.789294 },
    { lat: 48.637424, lng: 17.789137 },
    { lat: 48.637411, lng: 17.789121 },
    { lat: 48.637211, lng: 17.789084 },
    { lat: 48.637098, lng: 17.789069 },
    { lat: 48.637002, lng: 17.789028 },
    { lat: 48.636763, lng: 17.788714 },
    { lat: 48.6366305, lng: 17.788773 },
    { lat: 48.6365206, lng: 17.7886938 },
    { lat: 48.6361197, lng: 17.7886368 },
    { lat: 48.6354543, lng: 17.7882984 },
    { lat: 48.6349857, lng: 17.7879822 },
    { lat: 48.6343772, lng: 17.7872625 },
    { lat: 48.6337963, lng: 17.7872721 },
    { lat: 48.6334446, lng: 17.7872822 },
    { lat: 48.6332705, lng: 17.7901918 },
    { lat: 48.6329778, lng: 17.7902215 },
    { lat: 48.6322619, lng: 17.79007 },
    { lat: 48.6315442, lng: 17.7894501 },
    { lat: 48.6310498, lng: 17.7891021 },
    { lat: 48.6309485, lng: 17.7891048 },
    { lat: 48.6305425, lng: 17.7896646 },
    { lat: 48.6304793, lng: 17.7901088 },
    { lat: 48.6305476, lng: 17.7903591 },
    { lat: 48.6304787, lng: 17.7909847 },
    { lat: 48.6303028, lng: 17.7911138 },
    { lat: 48.6301366, lng: 17.7911495 },
    { lat: 48.6299078, lng: 17.7911185 },
    { lat: 48.6295952, lng: 17.7912877 },
    { lat: 48.6295405, lng: 17.791336 },
    { lat: 48.6294814, lng: 17.7915495 },
    { lat: 48.6293971, lng: 17.7916301 },
    { lat: 48.6293008, lng: 17.7916253 },
    { lat: 48.6291607, lng: 17.7918065 },
    { lat: 48.6290315, lng: 17.7922242 },
    { lat: 48.6289864, lng: 17.7926116 },
    { lat: 48.628761, lng: 17.7929874 },
    { lat: 48.6286181, lng: 17.793347 },
    { lat: 48.6291981, lng: 17.794394 },
    { lat: 48.6293043, lng: 17.7950973 },
    { lat: 48.6292563, lng: 17.7956586 },
    { lat: 48.6293191, lng: 17.7960924 },
    { lat: 48.6293034, lng: 17.7962623 },
    { lat: 48.6288873, lng: 17.7968227 },
    { lat: 48.6284773, lng: 17.7966906 },
    { lat: 48.6283981, lng: 17.7965022 },
    { lat: 48.6282862, lng: 17.7964089 },
    { lat: 48.6282252, lng: 17.7964106 },
    { lat: 48.6279584, lng: 17.7968821 },
    { lat: 48.6278189, lng: 17.796707 },
    { lat: 48.6277871, lng: 17.7964798 },
    { lat: 48.6276214, lng: 17.7963005 },
    { lat: 48.6274493, lng: 17.7965314 },
    { lat: 48.6270576, lng: 17.7965164 },
    { lat: 48.626878, lng: 17.7963451 },
    { lat: 48.6268164, lng: 17.7963562 },
    { lat: 48.626382, lng: 17.7969123 },
    { lat: 48.6261638, lng: 17.7969448 },
    { lat: 48.6260359, lng: 17.7971239 },
    { lat: 48.6259398, lng: 17.7971398 },
    { lat: 48.6258373, lng: 17.7972547 },
    { lat: 48.6257732, lng: 17.7974053 },
    { lat: 48.6250533, lng: 17.7971733 },
    { lat: 48.6249951, lng: 17.7974933 },
    { lat: 48.624822, lng: 17.7977664 },
    { lat: 48.6248247, lng: 17.7984166 },
    { lat: 48.624691, lng: 17.7984663 },
    { lat: 48.6245299, lng: 17.7987695 },
    { lat: 48.6240852, lng: 17.7987311 },
    { lat: 48.6234856, lng: 17.7991742 },
    { lat: 48.6227541, lng: 17.7991729 },
    { lat: 48.6223099, lng: 17.7987954 },
    { lat: 48.6221005, lng: 17.7989002 },
    { lat: 48.6216941, lng: 17.7994538 },
    { lat: 48.6213164, lng: 17.7995898 },
    { lat: 48.6213421, lng: 17.8007848 },
    { lat: 48.6221203, lng: 17.8015301 },
    { lat: 48.6222231, lng: 17.8017182 },
    { lat: 48.6222739, lng: 17.802049 },
    { lat: 48.6222576, lng: 17.80313 },
    { lat: 48.6221415, lng: 17.8035208 },
    { lat: 48.6221066, lng: 17.8038365 },
    { lat: 48.6219682, lng: 17.8042504 },
    { lat: 48.6219601, lng: 17.8048574 },
    { lat: 48.6220201, lng: 17.8053793 },
    { lat: 48.621732, lng: 17.8065765 },
    { lat: 48.6222328, lng: 17.8067153 },
    { lat: 48.6225272, lng: 17.8071161 },
    { lat: 48.6228551, lng: 17.8073685 },
    { lat: 48.622971, lng: 17.8075266 },
    { lat: 48.6229929, lng: 17.8084045 },
    { lat: 48.6229488, lng: 17.8086986 },
    { lat: 48.6226383, lng: 17.8092346 },
    { lat: 48.6221316, lng: 17.8097738 },
    { lat: 48.6215731, lng: 17.8107777 },
    { lat: 48.6214544, lng: 17.8116823 },
    { lat: 48.6215248, lng: 17.8122031 },
    { lat: 48.6217214, lng: 17.8126886 },
    { lat: 48.6219546, lng: 17.8131986 },
    { lat: 48.6222339, lng: 17.8135573 },
    { lat: 48.622525, lng: 17.815072 },
    { lat: 48.622672, lng: 17.8154266 },
    { lat: 48.6227991, lng: 17.8159203 },
    { lat: 48.622716, lng: 17.8162961 },
    { lat: 48.622412, lng: 17.8169399 },
    { lat: 48.6227437, lng: 17.8189532 },
    { lat: 48.6228757, lng: 17.8190262 },
    { lat: 48.62318, lng: 17.819055 },
    { lat: 48.6236818, lng: 17.8190282 },
    { lat: 48.623845, lng: 17.8185203 },
    { lat: 48.6238271, lng: 17.8181534 },
    { lat: 48.6239133, lng: 17.8177507 },
    { lat: 48.6241572, lng: 17.8175485 },
    { lat: 48.6247367, lng: 17.8174238 },
    { lat: 48.6248477, lng: 17.8174673 },
    { lat: 48.6249774, lng: 17.8178714 },
    { lat: 48.6251248, lng: 17.8188255 },
    { lat: 48.6250039, lng: 17.8192534 },
    { lat: 48.624788, lng: 17.8195067 },
    { lat: 48.6246994, lng: 17.8195154 },
    { lat: 48.6250981, lng: 17.8198701 },
    { lat: 48.6253761, lng: 17.8213235 },
    { lat: 48.6255504, lng: 17.8217179 },
    { lat: 48.6261295, lng: 17.8221597 },
    { lat: 48.6262245, lng: 17.8221465 },
    { lat: 48.6265221, lng: 17.8214529 },
    { lat: 48.6263799, lng: 17.8210641 },
    { lat: 48.6265295, lng: 17.8208899 },
    { lat: 48.6266305, lng: 17.820918 },
    { lat: 48.6269792, lng: 17.8213606 },
    { lat: 48.6270511, lng: 17.8219074 },
    { lat: 48.6272278, lng: 17.8224538 },
    { lat: 48.6273297, lng: 17.8231295 },
    { lat: 48.6277348, lng: 17.8234948 },
    { lat: 48.6281007, lng: 17.8236807 },
    { lat: 48.6284801, lng: 17.8236302 },
    { lat: 48.6287236, lng: 17.823716 },
    { lat: 48.6297815, lng: 17.8245122 },
    { lat: 48.6300041, lng: 17.8249424 },
    { lat: 48.6303767, lng: 17.825945 },
    { lat: 48.6305278, lng: 17.8272566 },
    { lat: 48.6313612, lng: 17.8274477 },
    { lat: 48.6323405, lng: 17.8275122 },
    { lat: 48.6326535, lng: 17.822549 },
    { lat: 48.6343528, lng: 17.8228483 },
    { lat: 48.6343407, lng: 17.8228018 },
    { lat: 48.6349327, lng: 17.8221786 },
    { lat: 48.6354953, lng: 17.8212005 },
    { lat: 48.6358485, lng: 17.820867 },
    { lat: 48.6360445, lng: 17.8198943 },
    { lat: 48.6367294, lng: 17.8199264 },
    { lat: 48.6369331, lng: 17.8202304 },
    { lat: 48.637179, lng: 17.8210622 },
    { lat: 48.6374108, lng: 17.8212906 },
    { lat: 48.6376223, lng: 17.8213344 },
    { lat: 48.6382189, lng: 17.8210072 },
    { lat: 48.6386723, lng: 17.8205398 },
    { lat: 48.639368, lng: 17.820472 },
  ],
  Prašník: [
    { lat: 48.667611, lng: 17.647853 },
    { lat: 48.667575, lng: 17.647821 },
    { lat: 48.667126, lng: 17.647403 },
    { lat: 48.666493, lng: 17.648295 },
    { lat: 48.665968, lng: 17.649233 },
    { lat: 48.665562, lng: 17.650167 },
    { lat: 48.665085, lng: 17.650911 },
    { lat: 48.664591, lng: 17.65169 },
    { lat: 48.664717, lng: 17.651695 },
    { lat: 48.664793, lng: 17.651763 },
    { lat: 48.664826, lng: 17.651946 },
    { lat: 48.664692, lng: 17.652228 },
    { lat: 48.664456, lng: 17.652435 },
    { lat: 48.664421, lng: 17.652376 },
    { lat: 48.664271, lng: 17.652532 },
    { lat: 48.66378, lng: 17.653329 },
    { lat: 48.663625, lng: 17.653227 },
    { lat: 48.663129, lng: 17.652907 },
    { lat: 48.662897, lng: 17.652762 },
    { lat: 48.662856, lng: 17.652396 },
    { lat: 48.662844, lng: 17.651445 },
    { lat: 48.662743, lng: 17.651004 },
    { lat: 48.662733, lng: 17.65076 },
    { lat: 48.662789, lng: 17.650454 },
    { lat: 48.663086, lng: 17.650001 },
    { lat: 48.662807, lng: 17.649657 },
    { lat: 48.662915, lng: 17.648995 },
    { lat: 48.662923, lng: 17.648934 },
    { lat: 48.662935, lng: 17.648854 },
    { lat: 48.662956, lng: 17.648703 },
    { lat: 48.662953, lng: 17.647946 },
    { lat: 48.662923, lng: 17.647572 },
    { lat: 48.662838, lng: 17.646487 },
    { lat: 48.662762, lng: 17.646368 },
    { lat: 48.662712, lng: 17.646291 },
    { lat: 48.662556, lng: 17.64604 },
    { lat: 48.662214, lng: 17.645762 },
    { lat: 48.662105, lng: 17.645648 },
    { lat: 48.662226, lng: 17.645244 },
    { lat: 48.662518, lng: 17.644364 },
    { lat: 48.663077, lng: 17.64271 },
    { lat: 48.663243, lng: 17.642639 },
    { lat: 48.663378, lng: 17.642446 },
    { lat: 48.663606, lng: 17.642006 },
    { lat: 48.663766, lng: 17.641212 },
    { lat: 48.663864, lng: 17.640259 },
    { lat: 48.663825, lng: 17.639509 },
    { lat: 48.66382, lng: 17.639388 },
    { lat: 48.663855, lng: 17.639197 },
    { lat: 48.663835, lng: 17.639187 },
    { lat: 48.664029, lng: 17.638672 },
    { lat: 48.664323, lng: 17.637884 },
    { lat: 48.66435, lng: 17.637812 },
    { lat: 48.664364, lng: 17.637315 },
    { lat: 48.664264, lng: 17.637192 },
    { lat: 48.663852, lng: 17.636694 },
    { lat: 48.66343, lng: 17.637001 },
    { lat: 48.663203, lng: 17.636836 },
    { lat: 48.66295, lng: 17.636363 },
    { lat: 48.662867, lng: 17.636008 },
    { lat: 48.662879, lng: 17.635958 },
    { lat: 48.66273, lng: 17.635833 },
    { lat: 48.662579, lng: 17.635742 },
    { lat: 48.662324, lng: 17.635856 },
    { lat: 48.662313, lng: 17.635861 },
    { lat: 48.6623, lng: 17.635815 },
    { lat: 48.662078, lng: 17.635276 },
    { lat: 48.661609, lng: 17.63414 },
    { lat: 48.661576, lng: 17.634057 },
    { lat: 48.66218, lng: 17.633714 },
    { lat: 48.662202, lng: 17.633757 },
    { lat: 48.662886, lng: 17.633098 },
    { lat: 48.663297, lng: 17.632765 },
    { lat: 48.663327, lng: 17.632741 },
    { lat: 48.663683, lng: 17.632453 },
    { lat: 48.663817, lng: 17.632726 },
    { lat: 48.664247, lng: 17.632242 },
    { lat: 48.664615, lng: 17.63164 },
    { lat: 48.664642, lng: 17.631503 },
    { lat: 48.664604, lng: 17.631347 },
    { lat: 48.664714, lng: 17.631054 },
    { lat: 48.665001, lng: 17.631564 },
    { lat: 48.665154, lng: 17.631676 },
    { lat: 48.665193, lng: 17.631523 },
    { lat: 48.665222, lng: 17.631535 },
    { lat: 48.665245, lng: 17.631395 },
    { lat: 48.66528, lng: 17.631179 },
    { lat: 48.665305, lng: 17.630976 },
    { lat: 48.665295, lng: 17.630963 },
    { lat: 48.665248, lng: 17.630898 },
    { lat: 48.665268, lng: 17.630855 },
    { lat: 48.665318, lng: 17.63075 },
    { lat: 48.665383, lng: 17.630597 },
    { lat: 48.665417, lng: 17.630467 },
    { lat: 48.665437, lng: 17.630396 },
    { lat: 48.665459, lng: 17.630313 },
    { lat: 48.665525, lng: 17.630076 },
    { lat: 48.665541, lng: 17.629995 },
    { lat: 48.665561, lng: 17.629874 },
    { lat: 48.665578, lng: 17.629769 },
    { lat: 48.665593, lng: 17.629674 },
    { lat: 48.665624, lng: 17.629479 },
    { lat: 48.665649, lng: 17.629316 },
    { lat: 48.665666, lng: 17.629226 },
    { lat: 48.665706, lng: 17.629145 },
    { lat: 48.665724, lng: 17.62911 },
    { lat: 48.665805, lng: 17.628946 },
    { lat: 48.666006, lng: 17.628556 },
    { lat: 48.666107, lng: 17.628359 },
    { lat: 48.666165, lng: 17.628235 },
    { lat: 48.666243, lng: 17.62805 },
    { lat: 48.666342, lng: 17.627765 },
    { lat: 48.66647, lng: 17.627377 },
    { lat: 48.666509, lng: 17.627206 },
    { lat: 48.666548, lng: 17.626934 },
    { lat: 48.666529, lng: 17.626698 },
    { lat: 48.666521, lng: 17.626649 },
    { lat: 48.666373, lng: 17.62656 },
    { lat: 48.666205, lng: 17.626458 },
    { lat: 48.666027, lng: 17.626356 },
    { lat: 48.665782, lng: 17.626208 },
    { lat: 48.665664, lng: 17.626137 },
    { lat: 48.665649, lng: 17.626129 },
    { lat: 48.66564, lng: 17.626124 },
    { lat: 48.665789, lng: 17.6257 },
    { lat: 48.665905, lng: 17.625393 },
    { lat: 48.665957, lng: 17.625219 },
    { lat: 48.665992, lng: 17.625082 },
    { lat: 48.666051, lng: 17.624828 },
    { lat: 48.666073, lng: 17.624766 },
    { lat: 48.666129, lng: 17.624638 },
    { lat: 48.666178, lng: 17.624532 },
    { lat: 48.666279, lng: 17.624367 },
    { lat: 48.666487, lng: 17.624104 },
    { lat: 48.666635, lng: 17.623959 },
    { lat: 48.66675, lng: 17.623852 },
    { lat: 48.666868, lng: 17.623786 },
    { lat: 48.666963, lng: 17.62374 },
    { lat: 48.66714, lng: 17.623668 },
    { lat: 48.66726, lng: 17.623604 },
    { lat: 48.667435, lng: 17.623415 },
    { lat: 48.667502, lng: 17.623284 },
    { lat: 48.667583, lng: 17.623128 },
    { lat: 48.667656, lng: 17.623015 },
    { lat: 48.667691, lng: 17.62297 },
    { lat: 48.667806, lng: 17.622846 },
    { lat: 48.667953, lng: 17.622705 },
    { lat: 48.668018, lng: 17.622616 },
    { lat: 48.668196, lng: 17.622368 },
    { lat: 48.668381, lng: 17.62213 },
    { lat: 48.668508, lng: 17.62206 },
    { lat: 48.668522, lng: 17.622106 },
    { lat: 48.668556, lng: 17.622097 },
    { lat: 48.668537, lng: 17.62229 },
    { lat: 48.668567, lng: 17.622511 },
    { lat: 48.668595, lng: 17.622613 },
    { lat: 48.668624, lng: 17.622676 },
    { lat: 48.668702, lng: 17.622792 },
    { lat: 48.668817, lng: 17.622977 },
    { lat: 48.668947, lng: 17.623207 },
    { lat: 48.669002, lng: 17.62352 },
    { lat: 48.669008, lng: 17.623684 },
    { lat: 48.66896, lng: 17.623661 },
    { lat: 48.668942, lng: 17.623652 },
    { lat: 48.668914, lng: 17.623638 },
    { lat: 48.668864, lng: 17.623614 },
    { lat: 48.66885, lng: 17.623674 },
    { lat: 48.668842, lng: 17.623714 },
    { lat: 48.668834, lng: 17.623748 },
    { lat: 48.668824, lng: 17.623792 },
    { lat: 48.668815, lng: 17.623831 },
    { lat: 48.668807, lng: 17.623874 },
    { lat: 48.668592, lng: 17.623745 },
    { lat: 48.66843, lng: 17.623648 },
    { lat: 48.668282, lng: 17.623573 },
    { lat: 48.668118, lng: 17.623478 },
    { lat: 48.667935, lng: 17.623348 },
    { lat: 48.667838, lng: 17.623646 },
    { lat: 48.667787, lng: 17.623803 },
    { lat: 48.667775, lng: 17.623836 },
    { lat: 48.667729, lng: 17.623909 },
    { lat: 48.667678, lng: 17.623984 },
    { lat: 48.667537, lng: 17.624308 },
    { lat: 48.667337, lng: 17.62457 },
    { lat: 48.667223, lng: 17.624642 },
    { lat: 48.667123, lng: 17.624886 },
    { lat: 48.667114, lng: 17.625048 },
    { lat: 48.667106, lng: 17.625221 },
    { lat: 48.667103, lng: 17.625304 },
    { lat: 48.667102, lng: 17.625345 },
    { lat: 48.66714, lng: 17.625342 },
    { lat: 48.667234, lng: 17.625324 },
    { lat: 48.667312, lng: 17.625325 },
    { lat: 48.667343, lng: 17.625349 },
    { lat: 48.667363, lng: 17.625364 },
    { lat: 48.667424, lng: 17.625459 },
    { lat: 48.667486, lng: 17.625963 },
    { lat: 48.667519, lng: 17.626234 },
    { lat: 48.667535, lng: 17.62636 },
    { lat: 48.667619, lng: 17.62634 },
    { lat: 48.667705, lng: 17.626313 },
    { lat: 48.667784, lng: 17.626287 },
    { lat: 48.667841, lng: 17.626202 },
    { lat: 48.667969, lng: 17.626239 },
    { lat: 48.668229, lng: 17.62607 },
    { lat: 48.66856, lng: 17.626098 },
    { lat: 48.669079, lng: 17.626256 },
    { lat: 48.66909, lng: 17.62626 },
    { lat: 48.66911, lng: 17.626265 },
    { lat: 48.669129, lng: 17.626279 },
    { lat: 48.668788, lng: 17.627035 },
    { lat: 48.668823, lng: 17.627069 },
    { lat: 48.66883, lng: 17.627079 },
    { lat: 48.669414, lng: 17.62779 },
    { lat: 48.669889, lng: 17.628221 },
    { lat: 48.669944, lng: 17.628343 },
    { lat: 48.670054, lng: 17.628588 },
    { lat: 48.670294, lng: 17.628219 },
    { lat: 48.6712, lng: 17.626362 },
    { lat: 48.671195, lng: 17.626301 },
    { lat: 48.672088, lng: 17.624663 },
    { lat: 48.672084, lng: 17.624608 },
    { lat: 48.671753, lng: 17.624134 },
    { lat: 48.671714, lng: 17.624085 },
    { lat: 48.671669, lng: 17.624031 },
    { lat: 48.6709, lng: 17.623085 },
    { lat: 48.670918, lng: 17.623056 },
    { lat: 48.671159, lng: 17.622625 },
    { lat: 48.671094, lng: 17.62254 },
    { lat: 48.670897, lng: 17.622348 },
    { lat: 48.670774, lng: 17.622204 },
    { lat: 48.670673, lng: 17.622086 },
    { lat: 48.670576, lng: 17.621981 },
    { lat: 48.670509, lng: 17.621907 },
    { lat: 48.670446, lng: 17.621834 },
    { lat: 48.670381, lng: 17.621755 },
    { lat: 48.670281, lng: 17.621632 },
    { lat: 48.670198, lng: 17.621528 },
    { lat: 48.670173, lng: 17.621494 },
    { lat: 48.670142, lng: 17.621449 },
    { lat: 48.670108, lng: 17.6214 },
    { lat: 48.670075, lng: 17.621361 },
    { lat: 48.670038, lng: 17.621319 },
    { lat: 48.66995, lng: 17.621233 },
    { lat: 48.669865, lng: 17.621156 },
    { lat: 48.669789, lng: 17.621079 },
    { lat: 48.669709, lng: 17.621005 },
    { lat: 48.669669, lng: 17.62097 },
    { lat: 48.669633, lng: 17.620937 },
    { lat: 48.669578, lng: 17.620887 },
    { lat: 48.669546, lng: 17.620859 },
    { lat: 48.669518, lng: 17.620834 },
    { lat: 48.669463, lng: 17.620788 },
    { lat: 48.669414, lng: 17.620746 },
    { lat: 48.669369, lng: 17.620707 },
    { lat: 48.66932, lng: 17.620665 },
    { lat: 48.669288, lng: 17.620636 },
    { lat: 48.669248, lng: 17.6206 },
    { lat: 48.669235, lng: 17.620588 },
    { lat: 48.669229, lng: 17.620583 },
    { lat: 48.669195, lng: 17.620553 },
    { lat: 48.669223, lng: 17.620329 },
    { lat: 48.669303, lng: 17.619887 },
    { lat: 48.669402, lng: 17.619632 },
    { lat: 48.669618, lng: 17.619221 },
    { lat: 48.669734, lng: 17.618962 },
    { lat: 48.669871, lng: 17.618607 },
    { lat: 48.669969, lng: 17.618336 },
    { lat: 48.669947, lng: 17.618322 },
    { lat: 48.669932, lng: 17.618313 },
    { lat: 48.669632, lng: 17.618131 },
    { lat: 48.669472, lng: 17.618033 },
    { lat: 48.669293, lng: 17.617932 },
    { lat: 48.66919, lng: 17.617877 },
    { lat: 48.668966, lng: 17.617752 },
    { lat: 48.668861, lng: 17.617694 },
    { lat: 48.668766, lng: 17.617632 },
    { lat: 48.668684, lng: 17.617588 },
    { lat: 48.668703, lng: 17.617286 },
    { lat: 48.668754, lng: 17.616964 },
    { lat: 48.668738, lng: 17.616952 },
    { lat: 48.668713, lng: 17.616938 },
    { lat: 48.668667, lng: 17.616909 },
    { lat: 48.668508, lng: 17.61673 },
    { lat: 48.668366, lng: 17.616614 },
    { lat: 48.668155, lng: 17.616321 },
    { lat: 48.668012, lng: 17.616113 },
    { lat: 48.66793, lng: 17.616266 },
    { lat: 48.667781, lng: 17.616532 },
    { lat: 48.667675, lng: 17.616742 },
    { lat: 48.667538, lng: 17.617031 },
    { lat: 48.667483, lng: 17.61715 },
    { lat: 48.667405, lng: 17.617301 },
    { lat: 48.667324, lng: 17.617454 },
    { lat: 48.667234, lng: 17.61756 },
    { lat: 48.667113, lng: 17.617707 },
    { lat: 48.667053, lng: 17.617746 },
    { lat: 48.66701, lng: 17.617775 },
    { lat: 48.666956, lng: 17.617809 },
    { lat: 48.666868, lng: 17.61759 },
    { lat: 48.666839, lng: 17.617519 },
    { lat: 48.666747, lng: 17.617291 },
    { lat: 48.666697, lng: 17.617145 },
    { lat: 48.666682, lng: 17.617102 },
    { lat: 48.666661, lng: 17.616908 },
    { lat: 48.666536, lng: 17.617005 },
    { lat: 48.6664, lng: 17.617069 },
    { lat: 48.666356, lng: 17.616961 },
    { lat: 48.666263, lng: 17.616868 },
    { lat: 48.66616, lng: 17.616914 },
    { lat: 48.666051, lng: 17.616771 },
    { lat: 48.666045, lng: 17.616775 },
    { lat: 48.665903, lng: 17.616856 },
    { lat: 48.66574, lng: 17.616958 },
    { lat: 48.665614, lng: 17.616656 },
    { lat: 48.665599, lng: 17.616622 },
    { lat: 48.665575, lng: 17.616618 },
    { lat: 48.665542, lng: 17.616615 },
    { lat: 48.665463, lng: 17.6167 },
    { lat: 48.665412, lng: 17.616771 },
    { lat: 48.665392, lng: 17.616798 },
    { lat: 48.6653, lng: 17.616977 },
    { lat: 48.665278, lng: 17.616984 },
    { lat: 48.66525, lng: 17.616995 },
    { lat: 48.665242, lng: 17.616938 },
    { lat: 48.665227, lng: 17.616864 },
    { lat: 48.665178, lng: 17.616752 },
    { lat: 48.66513, lng: 17.61661 },
    { lat: 48.66509, lng: 17.616568 },
    { lat: 48.664974, lng: 17.616586 },
    { lat: 48.664816, lng: 17.616695 },
    { lat: 48.66486, lng: 17.616911 },
    { lat: 48.664098, lng: 17.617389 },
    { lat: 48.663818, lng: 17.617465 },
    { lat: 48.663769, lng: 17.617419 },
    { lat: 48.663697, lng: 17.61735 },
    { lat: 48.663559, lng: 17.617233 },
    { lat: 48.663541, lng: 17.61721 },
    { lat: 48.663528, lng: 17.617192 },
    { lat: 48.663522, lng: 17.617184 },
    { lat: 48.663485, lng: 17.617135 },
    { lat: 48.663462, lng: 17.617105 },
    { lat: 48.663443, lng: 17.617079 },
    { lat: 48.663437, lng: 17.617071 },
    { lat: 48.663435, lng: 17.61707 },
    { lat: 48.663427, lng: 17.617042 },
    { lat: 48.663398, lng: 17.616937 },
    { lat: 48.663049, lng: 17.616605 },
    { lat: 48.662941, lng: 17.616507 },
    { lat: 48.662886, lng: 17.616457 },
    { lat: 48.662822, lng: 17.616402 },
    { lat: 48.662778, lng: 17.616361 },
    { lat: 48.662681, lng: 17.616275 },
    { lat: 48.662633, lng: 17.616232 },
    { lat: 48.662583, lng: 17.616186 },
    { lat: 48.662512, lng: 17.616124 },
    { lat: 48.662408, lng: 17.61603 },
    { lat: 48.66236, lng: 17.615988 },
    { lat: 48.662261, lng: 17.615898 },
    { lat: 48.662157, lng: 17.615801 },
    { lat: 48.662124, lng: 17.615786 },
    { lat: 48.662028, lng: 17.61574 },
    { lat: 48.661947, lng: 17.615703 },
    { lat: 48.661855, lng: 17.615659 },
    { lat: 48.661767, lng: 17.615618 },
    { lat: 48.66162, lng: 17.61555 },
    { lat: 48.661357, lng: 17.615425 },
    { lat: 48.66129, lng: 17.615394 },
    { lat: 48.661254, lng: 17.615399 },
    { lat: 48.661137, lng: 17.615416 },
    { lat: 48.660929, lng: 17.615447 },
    { lat: 48.660877, lng: 17.615454 },
    { lat: 48.660825, lng: 17.615462 },
    { lat: 48.660806, lng: 17.615465 },
    { lat: 48.660791, lng: 17.615467 },
    { lat: 48.660773, lng: 17.61547 },
    { lat: 48.660725, lng: 17.615477 },
    { lat: 48.660616, lng: 17.615493 },
    { lat: 48.660518, lng: 17.615508 },
    { lat: 48.660314, lng: 17.615539 },
    { lat: 48.660112, lng: 17.61557 },
    { lat: 48.659974, lng: 17.61559 },
    { lat: 48.659911, lng: 17.6156 },
    { lat: 48.659841, lng: 17.615625 },
    { lat: 48.659791, lng: 17.615642 },
    { lat: 48.659719, lng: 17.61567 },
    { lat: 48.659662, lng: 17.61569 },
    { lat: 48.659611, lng: 17.615709 },
    { lat: 48.659368, lng: 17.615796 },
    { lat: 48.659144, lng: 17.615878 },
    { lat: 48.659113, lng: 17.615905 },
    { lat: 48.659077, lng: 17.615937 },
    { lat: 48.659055, lng: 17.615946 },
    { lat: 48.659002, lng: 17.615967 },
    { lat: 48.658955, lng: 17.615987 },
    { lat: 48.658631, lng: 17.616119 },
    { lat: 48.658585, lng: 17.61614 },
    { lat: 48.658535, lng: 17.616159 },
    { lat: 48.658432, lng: 17.616201 },
    { lat: 48.658292, lng: 17.616259 },
    { lat: 48.658111, lng: 17.616332 },
    { lat: 48.658088, lng: 17.616342 },
    { lat: 48.658061, lng: 17.616353 },
    { lat: 48.658035, lng: 17.616363 },
    { lat: 48.657961, lng: 17.616393 },
    { lat: 48.657835, lng: 17.616439 },
    { lat: 48.657698, lng: 17.61649 },
    { lat: 48.657614, lng: 17.61652 },
    { lat: 48.65753, lng: 17.61655 },
    { lat: 48.657466, lng: 17.616578 },
    { lat: 48.6574, lng: 17.616696 },
    { lat: 48.657194, lng: 17.61701 },
    { lat: 48.657158, lng: 17.617038 },
    { lat: 48.656922, lng: 17.617221 },
    { lat: 48.656651, lng: 17.617426 },
    { lat: 48.656598, lng: 17.617466 },
    { lat: 48.656559, lng: 17.617495 },
    { lat: 48.656498, lng: 17.617541 },
    { lat: 48.656462, lng: 17.617569 },
    { lat: 48.656445, lng: 17.617593 },
    { lat: 48.656207, lng: 17.617919 },
    { lat: 48.656009, lng: 17.617518 },
    { lat: 48.655999, lng: 17.617498 },
    { lat: 48.655879, lng: 17.617255 },
    { lat: 48.655858, lng: 17.617211 },
    { lat: 48.655824, lng: 17.617142 },
    { lat: 48.655685, lng: 17.617408 },
    { lat: 48.655666, lng: 17.617443 },
    { lat: 48.655593, lng: 17.61763 },
    { lat: 48.655508, lng: 17.617812 },
    { lat: 48.655368, lng: 17.618063 },
    { lat: 48.655264, lng: 17.618231 },
    { lat: 48.655062, lng: 17.618438 },
    { lat: 48.65503, lng: 17.618471 },
    { lat: 48.654775, lng: 17.618782 },
    { lat: 48.654689, lng: 17.618844 },
    { lat: 48.654561, lng: 17.619096 },
    { lat: 48.654469, lng: 17.619207 },
    { lat: 48.654442, lng: 17.619239 },
    { lat: 48.654341, lng: 17.619326 },
    { lat: 48.654337, lng: 17.619335 },
    { lat: 48.654327, lng: 17.619357 },
    { lat: 48.654318, lng: 17.619377 },
    { lat: 48.654194, lng: 17.619558 },
    { lat: 48.653893, lng: 17.619823 },
    { lat: 48.653604, lng: 17.620113 },
    { lat: 48.65341, lng: 17.620261 },
    { lat: 48.653288, lng: 17.620355 },
    { lat: 48.65299, lng: 17.620534 },
    { lat: 48.652955, lng: 17.620555 },
    { lat: 48.652691, lng: 17.620703 },
    { lat: 48.652507, lng: 17.620798 },
    { lat: 48.65218, lng: 17.621014 },
    { lat: 48.652162, lng: 17.620986 },
    { lat: 48.652029, lng: 17.621076 },
    { lat: 48.651832, lng: 17.621182 },
    { lat: 48.651829, lng: 17.621168 },
    { lat: 48.651661, lng: 17.621256 },
    { lat: 48.651623, lng: 17.621242 },
    { lat: 48.651594, lng: 17.621231 },
    { lat: 48.651479, lng: 17.621189 },
    { lat: 48.651462, lng: 17.621179 },
    { lat: 48.65145, lng: 17.621173 },
    { lat: 48.6512, lng: 17.621099 },
    { lat: 48.651207, lng: 17.620561 },
    { lat: 48.651004, lng: 17.62042 },
    { lat: 48.650863, lng: 17.620148 },
    { lat: 48.650809, lng: 17.620077 },
    { lat: 48.650701, lng: 17.619947 },
    { lat: 48.650484, lng: 17.619853 },
    { lat: 48.650452, lng: 17.619901 },
    { lat: 48.650353, lng: 17.619866 },
    { lat: 48.65024, lng: 17.619793 },
    { lat: 48.650185, lng: 17.619854 },
    { lat: 48.649456, lng: 17.619604 },
    { lat: 48.649077, lng: 17.619484 },
    { lat: 48.648409, lng: 17.61924 },
    { lat: 48.64769, lng: 17.620363 },
    { lat: 48.646973, lng: 17.621485 },
    { lat: 48.646506, lng: 17.62254 },
    { lat: 48.646223, lng: 17.623182 },
    { lat: 48.646458, lng: 17.623879 },
    { lat: 48.646132, lng: 17.62442 },
    { lat: 48.645969, lng: 17.62486 },
    { lat: 48.645827, lng: 17.625095 },
    { lat: 48.645522, lng: 17.625423 },
    { lat: 48.645348, lng: 17.625501 },
    { lat: 48.644983, lng: 17.625582 },
    { lat: 48.644638, lng: 17.625509 },
    { lat: 48.644474, lng: 17.625487 },
    { lat: 48.644246, lng: 17.624494 },
    { lat: 48.6441367, lng: 17.6240165 },
    { lat: 48.64389, lng: 17.622939 },
    { lat: 48.643796, lng: 17.6225 },
    { lat: 48.643214, lng: 17.619756 },
    { lat: 48.643069, lng: 17.61909 },
    { lat: 48.641965, lng: 17.614026 },
    { lat: 48.641983, lng: 17.612877 },
    { lat: 48.64165, lng: 17.611742 },
    { lat: 48.641641, lng: 17.611714 },
    { lat: 48.641636, lng: 17.611685 },
    { lat: 48.641628, lng: 17.61162 },
    { lat: 48.641493, lng: 17.610678 },
    { lat: 48.641374, lng: 17.609095 },
    { lat: 48.641279, lng: 17.608655 },
    { lat: 48.641135, lng: 17.608118 },
    { lat: 48.641072, lng: 17.607898 },
    { lat: 48.640848, lng: 17.60726 },
    { lat: 48.640787, lng: 17.607072 },
    { lat: 48.640596, lng: 17.606758 },
    { lat: 48.640347, lng: 17.606267 },
    { lat: 48.640322, lng: 17.606217 },
    { lat: 48.640141, lng: 17.605603 },
    { lat: 48.640107, lng: 17.604916 },
    { lat: 48.640105, lng: 17.60489 },
    { lat: 48.64026, lng: 17.604712 },
    { lat: 48.640461, lng: 17.604567 },
    { lat: 48.640621, lng: 17.604495 },
    { lat: 48.641268, lng: 17.604355 },
    { lat: 48.641338, lng: 17.604326 },
    { lat: 48.641489, lng: 17.604158 },
    { lat: 48.64168, lng: 17.603557 },
    { lat: 48.6416475, lng: 17.6035729 },
    { lat: 48.6413789, lng: 17.6037044 },
    { lat: 48.640957, lng: 17.6035491 },
    { lat: 48.6406559, lng: 17.6033744 },
    { lat: 48.6404811, lng: 17.6031456 },
    { lat: 48.6402211, lng: 17.6029432 },
    { lat: 48.6399007, lng: 17.6028034 },
    { lat: 48.6395556, lng: 17.602776 },
    { lat: 48.6391024, lng: 17.6031483 },
    { lat: 48.6386986, lng: 17.603225 },
    { lat: 48.6385734, lng: 17.6033554 },
    { lat: 48.638395, lng: 17.6033563 },
    { lat: 48.6381279, lng: 17.6034891 },
    { lat: 48.6380103, lng: 17.6035416 },
    { lat: 48.6378653, lng: 17.6045403 },
    { lat: 48.6378555, lng: 17.6061797 },
    { lat: 48.638306, lng: 17.6068625 },
    { lat: 48.6383601, lng: 17.6070415 },
    { lat: 48.638425, lng: 17.6075696 },
    { lat: 48.638318, lng: 17.6078816 },
    { lat: 48.637708, lng: 17.609117 },
    { lat: 48.6369599, lng: 17.6097752 },
    { lat: 48.6368337, lng: 17.610282 },
    { lat: 48.6367331, lng: 17.6105113 },
    { lat: 48.6367017, lng: 17.61075 },
    { lat: 48.635469, lng: 17.613492 },
    { lat: 48.6348207, lng: 17.6150719 },
    { lat: 48.633064, lng: 17.619074 },
    { lat: 48.6307688, lng: 17.6245906 },
    { lat: 48.6302087, lng: 17.6269129 },
    { lat: 48.6299447, lng: 17.6282164 },
    { lat: 48.6292973, lng: 17.6294516 },
    { lat: 48.629232, lng: 17.6300342 },
    { lat: 48.6289293, lng: 17.6313131 },
    { lat: 48.6281126, lng: 17.6319007 },
    { lat: 48.6275538, lng: 17.6326428 },
    { lat: 48.6268569, lng: 17.6333078 },
    { lat: 48.6267453, lng: 17.6334751 },
    { lat: 48.6264854, lng: 17.6341196 },
    { lat: 48.6260492, lng: 17.6347267 },
    { lat: 48.6257894, lng: 17.6352295 },
    { lat: 48.625277, lng: 17.6363722 },
    { lat: 48.625164, lng: 17.6366963 },
    { lat: 48.6276701, lng: 17.6401698 },
    { lat: 48.62778, lng: 17.6397972 },
    { lat: 48.6279207, lng: 17.6395166 },
    { lat: 48.6280389, lng: 17.6389879 },
    { lat: 48.6283744, lng: 17.6385754 },
    { lat: 48.6286016, lng: 17.638417 },
    { lat: 48.6289363, lng: 17.638333 },
    { lat: 48.6294499, lng: 17.6386575 },
    { lat: 48.6296329, lng: 17.6386775 },
    { lat: 48.6300274, lng: 17.6385717 },
    { lat: 48.6302203, lng: 17.6384072 },
    { lat: 48.6308144, lng: 17.638142 },
    { lat: 48.6309371, lng: 17.6381705 },
    { lat: 48.631303, lng: 17.6379378 },
    { lat: 48.6326957, lng: 17.6399303 },
    { lat: 48.632668, lng: 17.6403158 },
    { lat: 48.6330816, lng: 17.6414785 },
    { lat: 48.6332775, lng: 17.6418672 },
    { lat: 48.6333853, lng: 17.642362 },
    { lat: 48.6334245, lng: 17.6428266 },
    { lat: 48.6335921, lng: 17.6435781 },
    { lat: 48.6341051, lng: 17.6451379 },
    { lat: 48.6343749, lng: 17.6458057 },
    { lat: 48.6345538, lng: 17.6471551 },
    { lat: 48.6346591, lng: 17.64795 },
    { lat: 48.6346203, lng: 17.6495712 },
    { lat: 48.6347002, lng: 17.6498012 },
    { lat: 48.6349461, lng: 17.6500336 },
    { lat: 48.6351299, lng: 17.6502977 },
    { lat: 48.6352634, lng: 17.65072 },
    { lat: 48.6351819, lng: 17.650914 },
    { lat: 48.6350483, lng: 17.6509948 },
    { lat: 48.6350207, lng: 17.6510076 },
    { lat: 48.6348342, lng: 17.6510223 },
    { lat: 48.6345597, lng: 17.6512224 },
    { lat: 48.6344741, lng: 17.6513507 },
    { lat: 48.6344337, lng: 17.6514112 },
    { lat: 48.634322, lng: 17.6517177 },
    { lat: 48.6342685, lng: 17.6520737 },
    { lat: 48.6342564, lng: 17.6529629 },
    { lat: 48.6340959, lng: 17.6534214 },
    { lat: 48.6340144, lng: 17.6535934 },
    { lat: 48.6339841, lng: 17.6537264 },
    { lat: 48.6339479, lng: 17.6538849 },
    { lat: 48.6341228, lng: 17.6546965 },
    { lat: 48.6340758, lng: 17.6556616 },
    { lat: 48.6339819, lng: 17.656094 },
    { lat: 48.6339872, lng: 17.6565027 },
    { lat: 48.6337053, lng: 17.6576151 },
    { lat: 48.6327618, lng: 17.6621666 },
    { lat: 48.6321567, lng: 17.66434 },
    { lat: 48.6312921, lng: 17.665597 },
    { lat: 48.6294111, lng: 17.6679019 },
    { lat: 48.6273517, lng: 17.6696994 },
    { lat: 48.6258896, lng: 17.6705865 },
    { lat: 48.6247625, lng: 17.6714879 },
    { lat: 48.6235889, lng: 17.6733498 },
    { lat: 48.6222206, lng: 17.6762003 },
    { lat: 48.6224082, lng: 17.6764778 },
    { lat: 48.6232933, lng: 17.6784543 },
    { lat: 48.6234016, lng: 17.6788151 },
    { lat: 48.6234342, lng: 17.6788865 },
    { lat: 48.6236069, lng: 17.679084 },
    { lat: 48.6237527, lng: 17.6794314 },
    { lat: 48.6238092, lng: 17.6794301 },
    { lat: 48.6238256, lng: 17.679622 },
    { lat: 48.6236717, lng: 17.6800877 },
    { lat: 48.6236729, lng: 17.6804537 },
    { lat: 48.6238447, lng: 17.6818352 },
    { lat: 48.6239604, lng: 17.6822618 },
    { lat: 48.6244778, lng: 17.6833374 },
    { lat: 48.6245885, lng: 17.6838657 },
    { lat: 48.6245625, lng: 17.6840839 },
    { lat: 48.6242929, lng: 17.6847976 },
    { lat: 48.6224649, lng: 17.6866671 },
    { lat: 48.6221914, lng: 17.6869724 },
    { lat: 48.6220428, lng: 17.6872336 },
    { lat: 48.6227403, lng: 17.6884836 },
    { lat: 48.6227795, lng: 17.6884449 },
    { lat: 48.6228701, lng: 17.6885395 },
    { lat: 48.6238867, lng: 17.6897805 },
    { lat: 48.6247672, lng: 17.6905783 },
    { lat: 48.6249555, lng: 17.6903183 },
    { lat: 48.6251745, lng: 17.6903741 },
    { lat: 48.6256606, lng: 17.6893635 },
    { lat: 48.6266549, lng: 17.690394 },
    { lat: 48.6268999, lng: 17.6899029 },
    { lat: 48.6279735, lng: 17.6908465 },
    { lat: 48.6283442, lng: 17.6909226 },
    { lat: 48.6283716, lng: 17.6905629 },
    { lat: 48.6286287, lng: 17.6895939 },
    { lat: 48.6296153, lng: 17.6873389 },
    { lat: 48.6296897, lng: 17.6872117 },
    { lat: 48.6298179, lng: 17.6871654 },
    { lat: 48.6300152, lng: 17.6873591 },
    { lat: 48.6304959, lng: 17.6869385 },
    { lat: 48.6305898, lng: 17.6865044 },
    { lat: 48.6308529, lng: 17.6861956 },
    { lat: 48.6309372, lng: 17.685842 },
    { lat: 48.6310321, lng: 17.6857173 },
    { lat: 48.6309783, lng: 17.6856167 },
    { lat: 48.6311104, lng: 17.6853585 },
    { lat: 48.6311773, lng: 17.6853116 },
    { lat: 48.6312074, lng: 17.6853584 },
    { lat: 48.631278, lng: 17.6852759 },
    { lat: 48.6314925, lng: 17.6844785 },
    { lat: 48.6314854, lng: 17.6841447 },
    { lat: 48.6317298, lng: 17.6840794 },
    { lat: 48.6319449, lng: 17.6841104 },
    { lat: 48.632804, lng: 17.6833253 },
    { lat: 48.6328985, lng: 17.6833204 },
    { lat: 48.6330214, lng: 17.6832078 },
    { lat: 48.6333905, lng: 17.6833718 },
    { lat: 48.6335115, lng: 17.6835361 },
    { lat: 48.6335314, lng: 17.6836746 },
    { lat: 48.6339204, lng: 17.6838837 },
    { lat: 48.634071, lng: 17.6840334 },
    { lat: 48.634116, lng: 17.6841609 },
    { lat: 48.6339892, lng: 17.6844462 },
    { lat: 48.6338646, lng: 17.6844749 },
    { lat: 48.6335017, lng: 17.6851413 },
    { lat: 48.6340395, lng: 17.6854929 },
    { lat: 48.6342547, lng: 17.6857034 },
    { lat: 48.634351, lng: 17.6859239 },
    { lat: 48.6344156, lng: 17.6862564 },
    { lat: 48.6346644, lng: 17.6863729 },
    { lat: 48.6347459, lng: 17.6865224 },
    { lat: 48.6352052, lng: 17.68561 },
    { lat: 48.6354958, lng: 17.6851948 },
    { lat: 48.6359005, lng: 17.6857876 },
    { lat: 48.6360127, lng: 17.686028 },
    { lat: 48.6360996, lng: 17.6864638 },
    { lat: 48.6361814, lng: 17.6865807 },
    { lat: 48.6364299, lng: 17.686693 },
    { lat: 48.6370867, lng: 17.6867076 },
    { lat: 48.6375145, lng: 17.6868862 },
    { lat: 48.6380674, lng: 17.6867152 },
    { lat: 48.6383593, lng: 17.6879984 },
    { lat: 48.6386776, lng: 17.6890158 },
    { lat: 48.6387753, lng: 17.6898624 },
    { lat: 48.6387913, lng: 17.6906112 },
    { lat: 48.6385813, lng: 17.6909751 },
    { lat: 48.6383377, lng: 17.6917242 },
    { lat: 48.6380731, lng: 17.6922828 },
    { lat: 48.6378444, lng: 17.6924368 },
    { lat: 48.6374997, lng: 17.6925016 },
    { lat: 48.6369631, lng: 17.6924454 },
    { lat: 48.6365958, lng: 17.693092 },
    { lat: 48.6361624, lng: 17.6934581 },
    { lat: 48.6349364, lng: 17.6953383 },
    { lat: 48.6354931, lng: 17.6961011 },
    { lat: 48.6359894, lng: 17.6974228 },
    { lat: 48.6353355, lng: 17.6982944 },
    { lat: 48.6345237, lng: 17.699713 },
    { lat: 48.6333654, lng: 17.7025766 },
    { lat: 48.6323234, lng: 17.7056952 },
    { lat: 48.6330766, lng: 17.7066987 },
    { lat: 48.6331456, lng: 17.7068985 },
    { lat: 48.6331984, lng: 17.7068768 },
    { lat: 48.6335776, lng: 17.7071663 },
    { lat: 48.6343258, lng: 17.7072172 },
    { lat: 48.6345637, lng: 17.7075545 },
    { lat: 48.6348539, lng: 17.7077938 },
    { lat: 48.6354559, lng: 17.708656 },
    { lat: 48.6355069, lng: 17.7088601 },
    { lat: 48.6364098, lng: 17.7100565 },
    { lat: 48.636654, lng: 17.7100809 },
    { lat: 48.6371626, lng: 17.7106525 },
    { lat: 48.637181, lng: 17.7106207 },
    { lat: 48.6374968, lng: 17.7110366 },
    { lat: 48.6407166, lng: 17.7146266 },
    { lat: 48.6446509, lng: 17.7115713 },
    { lat: 48.6447614, lng: 17.7116603 },
    { lat: 48.6457325, lng: 17.713234 },
    { lat: 48.64776, lng: 17.716567 },
    { lat: 48.6479451, lng: 17.7169658 },
    { lat: 48.647955, lng: 17.7169809 },
    { lat: 48.6485413, lng: 17.7168979 },
    { lat: 48.6490451, lng: 17.7160425 },
    { lat: 48.6492856, lng: 17.7160597 },
    { lat: 48.6498818, lng: 17.7152751 },
    { lat: 48.6499502, lng: 17.7150298 },
    { lat: 48.6502134, lng: 17.714941 },
    { lat: 48.6503821, lng: 17.7147297 },
    { lat: 48.6504038, lng: 17.714606 },
    { lat: 48.6506221, lng: 17.7144145 },
    { lat: 48.6508192, lng: 17.7138758 },
    { lat: 48.6508351, lng: 17.7137118 },
    { lat: 48.6509436, lng: 17.7135301 },
    { lat: 48.6512155, lng: 17.7132744 },
    { lat: 48.6515508, lng: 17.7130732 },
    { lat: 48.6521343, lng: 17.7125392 },
    { lat: 48.6522686, lng: 17.7122814 },
    { lat: 48.6523256, lng: 17.7120559 },
    { lat: 48.6533435, lng: 17.7113875 },
    { lat: 48.6534508, lng: 17.7111181 },
    { lat: 48.6536965, lng: 17.7107635 },
    { lat: 48.6537777, lng: 17.7105771 },
    { lat: 48.6538465, lng: 17.7102132 },
    { lat: 48.6539834, lng: 17.7100371 },
    { lat: 48.6542674, lng: 17.7099149 },
    { lat: 48.654596, lng: 17.7095525 },
    { lat: 48.6547516, lng: 17.7094581 },
    { lat: 48.6548629, lng: 17.7094685 },
    { lat: 48.6549876, lng: 17.7093498 },
    { lat: 48.6549777, lng: 17.7091898 },
    { lat: 48.6550382, lng: 17.7091277 },
    { lat: 48.6552785, lng: 17.7091231 },
    { lat: 48.6553751, lng: 17.7087342 },
    { lat: 48.6556328, lng: 17.7086657 },
    { lat: 48.6556757, lng: 17.7085711 },
    { lat: 48.6557385, lng: 17.7085658 },
    { lat: 48.6557701, lng: 17.7084574 },
    { lat: 48.6558965, lng: 17.7084203 },
    { lat: 48.6558875, lng: 17.7083087 },
    { lat: 48.65599, lng: 17.70805 },
    { lat: 48.6561571, lng: 17.7079061 },
    { lat: 48.6562723, lng: 17.7076491 },
    { lat: 48.6564655, lng: 17.707559 },
    { lat: 48.656654, lng: 17.7072864 },
    { lat: 48.6570889, lng: 17.7069786 },
    { lat: 48.6572025, lng: 17.7067723 },
    { lat: 48.6573829, lng: 17.7066018 },
    { lat: 48.65739, lng: 17.7064083 },
    { lat: 48.657683, lng: 17.706107 },
    { lat: 48.6578845, lng: 17.7057357 },
    { lat: 48.6580605, lng: 17.7056209 },
    { lat: 48.6591822, lng: 17.7053226 },
    { lat: 48.6595064, lng: 17.704849 },
    { lat: 48.659625, lng: 17.7048323 },
    { lat: 48.660018, lng: 17.7043968 },
    { lat: 48.6601141, lng: 17.7041982 },
    { lat: 48.6599381, lng: 17.7037641 },
    { lat: 48.660883, lng: 17.7024679 },
    { lat: 48.6614132, lng: 17.7015852 },
    { lat: 48.6617109, lng: 17.7009406 },
    { lat: 48.6622861, lng: 17.7000565 },
    { lat: 48.6627111, lng: 17.698916 },
    { lat: 48.6630646, lng: 17.6981528 },
    { lat: 48.663381, lng: 17.6969702 },
    { lat: 48.6638323, lng: 17.6958388 },
    { lat: 48.6644471, lng: 17.6948987 },
    { lat: 48.6648128, lng: 17.6945148 },
    { lat: 48.6653603, lng: 17.6940766 },
    { lat: 48.6659498, lng: 17.6934029 },
    { lat: 48.6659522, lng: 17.6932975 },
    { lat: 48.6673233, lng: 17.6926104 },
    { lat: 48.667519, lng: 17.6925656 },
    { lat: 48.6685562, lng: 17.692684 },
    { lat: 48.6690068, lng: 17.6930507 },
    { lat: 48.6691158, lng: 17.693061 },
    { lat: 48.6692089, lng: 17.6930332 },
    { lat: 48.6695425, lng: 17.6926017 },
    { lat: 48.6698319, lng: 17.6923893 },
    { lat: 48.6705442, lng: 17.6922614 },
    { lat: 48.6715764, lng: 17.692303 },
    { lat: 48.6720132, lng: 17.6920808 },
    { lat: 48.6727735, lng: 17.6915039 },
    { lat: 48.6729687, lng: 17.6914463 },
    { lat: 48.6735229, lng: 17.6917533 },
    { lat: 48.6742694, lng: 17.6920125 },
    { lat: 48.6749415, lng: 17.6921075 },
    { lat: 48.6753974, lng: 17.692396 },
    { lat: 48.675776, lng: 17.692744 },
    { lat: 48.675817, lng: 17.692658 },
    { lat: 48.676006, lng: 17.692262 },
    { lat: 48.676148, lng: 17.69201 },
    { lat: 48.676243, lng: 17.691805 },
    { lat: 48.676384, lng: 17.691518 },
    { lat: 48.676465, lng: 17.691348 },
    { lat: 48.676591, lng: 17.691124 },
    { lat: 48.67673, lng: 17.690919 },
    { lat: 48.676843, lng: 17.690768 },
    { lat: 48.677305, lng: 17.69014 },
    { lat: 48.677455, lng: 17.689918 },
    { lat: 48.677487, lng: 17.689868 },
    { lat: 48.677716, lng: 17.689436 },
    { lat: 48.677909, lng: 17.689039 },
    { lat: 48.677992, lng: 17.688804 },
    { lat: 48.678077, lng: 17.68852 },
    { lat: 48.678176, lng: 17.688219 },
    { lat: 48.678313, lng: 17.687788 },
    { lat: 48.678378, lng: 17.687591 },
    { lat: 48.678386, lng: 17.687575 },
    { lat: 48.678334, lng: 17.687459 },
    { lat: 48.678301, lng: 17.687316 },
    { lat: 48.678219, lng: 17.687262 },
    { lat: 48.678155, lng: 17.687116 },
    { lat: 48.678121, lng: 17.687018 },
    { lat: 48.678069, lng: 17.686817 },
    { lat: 48.677994, lng: 17.686659 },
    { lat: 48.677929, lng: 17.686586 },
    { lat: 48.677838, lng: 17.686373 },
    { lat: 48.6778, lng: 17.686308 },
    { lat: 48.677787, lng: 17.686272 },
    { lat: 48.677805, lng: 17.686196 },
    { lat: 48.677824, lng: 17.686122 },
    { lat: 48.677797, lng: 17.68602 },
    { lat: 48.677709, lng: 17.685887 },
    { lat: 48.677641, lng: 17.685812 },
    { lat: 48.677581, lng: 17.685696 },
    { lat: 48.677539, lng: 17.68566 },
    { lat: 48.677512, lng: 17.6856 },
    { lat: 48.67739, lng: 17.685519 },
    { lat: 48.677323, lng: 17.685417 },
    { lat: 48.677194, lng: 17.685313 },
    { lat: 48.677099, lng: 17.685283 },
    { lat: 48.677046, lng: 17.685239 },
    { lat: 48.677, lng: 17.685168 },
    { lat: 48.676916, lng: 17.685101 },
    { lat: 48.676875, lng: 17.685036 },
    { lat: 48.67682, lng: 17.684991 },
    { lat: 48.676785, lng: 17.684929 },
    { lat: 48.676705, lng: 17.684812 },
    { lat: 48.676541, lng: 17.684715 },
    { lat: 48.676463, lng: 17.684637 },
    { lat: 48.676406, lng: 17.68458 },
    { lat: 48.676309, lng: 17.684606 },
    { lat: 48.676224, lng: 17.684586 },
    { lat: 48.676197, lng: 17.684511 },
    { lat: 48.676148, lng: 17.684438 },
    { lat: 48.676145, lng: 17.684352 },
    { lat: 48.676161, lng: 17.684258 },
    { lat: 48.676143, lng: 17.684199 },
    { lat: 48.67611, lng: 17.684174 },
    { lat: 48.67602, lng: 17.684128 },
    { lat: 48.675947, lng: 17.684 },
    { lat: 48.675922, lng: 17.68397 },
    { lat: 48.675824, lng: 17.683982 },
    { lat: 48.675781, lng: 17.68393 },
    { lat: 48.675698, lng: 17.683879 },
    { lat: 48.67566, lng: 17.683731 },
    { lat: 48.675567, lng: 17.683682 },
    { lat: 48.675476, lng: 17.683526 },
    { lat: 48.675415, lng: 17.683395 },
    { lat: 48.6754, lng: 17.683372 },
    { lat: 48.675389, lng: 17.683367 },
    { lat: 48.675375, lng: 17.683373 },
    { lat: 48.675293, lng: 17.683441 },
    { lat: 48.675246, lng: 17.683461 },
    { lat: 48.675227, lng: 17.683461 },
    { lat: 48.675143, lng: 17.683393 },
    { lat: 48.675068, lng: 17.683388 },
    { lat: 48.674995, lng: 17.683373 },
    { lat: 48.674909, lng: 17.683324 },
    { lat: 48.674895, lng: 17.683329 },
    { lat: 48.674756, lng: 17.68319 },
    { lat: 48.67469, lng: 17.683179 },
    { lat: 48.67461, lng: 17.683181 },
    { lat: 48.67453, lng: 17.683171 },
    { lat: 48.674498, lng: 17.683072 },
    { lat: 48.674345, lng: 17.682837 },
    { lat: 48.674221, lng: 17.682645 },
    { lat: 48.674184, lng: 17.682569 },
    { lat: 48.674114, lng: 17.682507 },
    { lat: 48.674039, lng: 17.682379 },
    { lat: 48.673886, lng: 17.682219 },
    { lat: 48.673899, lng: 17.682206 },
    { lat: 48.673922, lng: 17.682179 },
    { lat: 48.673945, lng: 17.68213 },
    { lat: 48.674041, lng: 17.682033 },
    { lat: 48.674274, lng: 17.681862 },
    { lat: 48.674305, lng: 17.681827 },
    { lat: 48.674314, lng: 17.681817 },
    { lat: 48.674273, lng: 17.681743 },
    { lat: 48.674124, lng: 17.681575 },
    { lat: 48.673903, lng: 17.681401 },
    { lat: 48.673795, lng: 17.681293 },
    { lat: 48.673267, lng: 17.680806 },
    { lat: 48.672956, lng: 17.680608 },
    { lat: 48.672528, lng: 17.680235 },
    { lat: 48.672207, lng: 17.679928 },
    { lat: 48.671803, lng: 17.67938 },
    { lat: 48.671372, lng: 17.678878 },
    { lat: 48.670984, lng: 17.678319 },
    { lat: 48.670215, lng: 17.677013 },
    { lat: 48.670069, lng: 17.676864 },
    { lat: 48.669735, lng: 17.676761 },
    { lat: 48.669739, lng: 17.676696 },
    { lat: 48.669727, lng: 17.676649 },
    { lat: 48.669417, lng: 17.675333 },
    { lat: 48.669099, lng: 17.673986 },
    { lat: 48.669227, lng: 17.673941 },
    { lat: 48.66925, lng: 17.673933 },
    { lat: 48.669234, lng: 17.673885 },
    { lat: 48.668972, lng: 17.673219 },
    { lat: 48.668683, lng: 17.672563 },
    { lat: 48.668454, lng: 17.672045 },
    { lat: 48.668086, lng: 17.67121 },
    { lat: 48.667886, lng: 17.670758 },
    { lat: 48.667863, lng: 17.670709 },
    { lat: 48.668365, lng: 17.670035 },
    { lat: 48.668858, lng: 17.669547 },
    { lat: 48.66883, lng: 17.669489 },
    { lat: 48.668842, lng: 17.669443 },
    { lat: 48.668846, lng: 17.669428 },
    { lat: 48.668777, lng: 17.669327 },
    { lat: 48.668744, lng: 17.669279 },
    { lat: 48.668589, lng: 17.669172 },
    { lat: 48.66853, lng: 17.669099 },
    { lat: 48.668468, lng: 17.669068 },
    { lat: 48.668401, lng: 17.669042 },
    { lat: 48.668349, lng: 17.668974 },
    { lat: 48.668294, lng: 17.668957 },
    { lat: 48.668231, lng: 17.668914 },
    { lat: 48.668164, lng: 17.668868 },
    { lat: 48.66808, lng: 17.66883 },
    { lat: 48.667935, lng: 17.668814 },
    { lat: 48.667836, lng: 17.668729 },
    { lat: 48.667763, lng: 17.668693 },
    { lat: 48.667599, lng: 17.668811 },
    { lat: 48.667543, lng: 17.668812 },
    { lat: 48.66743, lng: 17.668893 },
    { lat: 48.667333, lng: 17.668889 },
    { lat: 48.66712, lng: 17.669063 },
    { lat: 48.666961, lng: 17.669294 },
    { lat: 48.666875, lng: 17.669428 },
    { lat: 48.666864, lng: 17.669398 },
    { lat: 48.666748, lng: 17.669201 },
    { lat: 48.666732, lng: 17.669168 },
    { lat: 48.667136, lng: 17.668391 },
    { lat: 48.667322, lng: 17.66804 },
    { lat: 48.667616, lng: 17.667474 },
    { lat: 48.667964, lng: 17.666806 },
    { lat: 48.668253, lng: 17.666164 },
    { lat: 48.668235, lng: 17.666153 },
    { lat: 48.668211, lng: 17.666139 },
    { lat: 48.668117, lng: 17.665977 },
    { lat: 48.66795, lng: 17.665911 },
    { lat: 48.66781, lng: 17.665851 },
    { lat: 48.667728, lng: 17.665816 },
    { lat: 48.667688, lng: 17.6658 },
    { lat: 48.667591, lng: 17.665759 },
    { lat: 48.667512, lng: 17.66563 },
    { lat: 48.667488, lng: 17.665591 },
    { lat: 48.66743, lng: 17.665499 },
    { lat: 48.667422, lng: 17.665485 },
    { lat: 48.667353, lng: 17.665395 },
    { lat: 48.66726, lng: 17.665273 },
    { lat: 48.66711, lng: 17.665079 },
    { lat: 48.667082, lng: 17.665043 },
    { lat: 48.667063, lng: 17.665024 },
    { lat: 48.666954, lng: 17.664916 },
    { lat: 48.666878, lng: 17.664842 },
    { lat: 48.666873, lng: 17.66487 },
    { lat: 48.666355, lng: 17.664164 },
    { lat: 48.666005, lng: 17.663734 },
    { lat: 48.665477, lng: 17.662991 },
    { lat: 48.665411, lng: 17.662916 },
    { lat: 48.665372, lng: 17.662837 },
    { lat: 48.665364, lng: 17.66275 },
    { lat: 48.665497, lng: 17.662247 },
    { lat: 48.665947, lng: 17.660189 },
    { lat: 48.666097, lng: 17.659616 },
    { lat: 48.66612, lng: 17.659453 },
    { lat: 48.666051, lng: 17.658914 },
    { lat: 48.666035, lng: 17.658911 },
    { lat: 48.665767, lng: 17.65893 },
    { lat: 48.6657, lng: 17.658698 },
    { lat: 48.665732, lng: 17.658568 },
    { lat: 48.665872, lng: 17.658125 },
    { lat: 48.665968, lng: 17.657813 },
    { lat: 48.666086, lng: 17.657431 },
    { lat: 48.666166, lng: 17.657012 },
    { lat: 48.666183, lng: 17.656929 },
    { lat: 48.666457, lng: 17.656349 },
    { lat: 48.66676, lng: 17.655331 },
    { lat: 48.666947, lng: 17.654736 },
    { lat: 48.66712, lng: 17.65421 },
    { lat: 48.667163, lng: 17.654059 },
    { lat: 48.66729, lng: 17.653602 },
    { lat: 48.667427, lng: 17.653081 },
    { lat: 48.667546, lng: 17.65229 },
    { lat: 48.667647, lng: 17.651358 },
    { lat: 48.667744, lng: 17.650695 },
    { lat: 48.667779, lng: 17.650599 },
    { lat: 48.667993, lng: 17.650001 },
    { lat: 48.668005, lng: 17.649603 },
    { lat: 48.668027, lng: 17.64893 },
    { lat: 48.667477, lng: 17.648876 },
    { lat: 48.667611, lng: 17.647853 },
  ],
  Trebatice: [
    { lat: 48.5819755, lng: 17.7582076 },
    { lat: 48.5820328, lng: 17.7582766 },
    { lat: 48.5822155, lng: 17.7584924 },
    { lat: 48.5823223, lng: 17.7585413 },
    { lat: 48.5836471, lng: 17.7619076 },
    { lat: 48.5841316, lng: 17.7617803 },
    { lat: 48.5852413, lng: 17.7628447 },
    { lat: 48.5861224, lng: 17.7635107 },
    { lat: 48.5859608, lng: 17.7641876 },
    { lat: 48.5874631, lng: 17.7655243 },
    { lat: 48.5880178, lng: 17.7660994 },
    { lat: 48.5882156, lng: 17.7661868 },
    { lat: 48.5891478, lng: 17.7666669 },
    { lat: 48.5899015, lng: 17.767155 },
    { lat: 48.5910489, lng: 17.7676748 },
    { lat: 48.5928732, lng: 17.7689236 },
    { lat: 48.5942261, lng: 17.7696266 },
    { lat: 48.5943198, lng: 17.7704149 },
    { lat: 48.5944407, lng: 17.774793 },
    { lat: 48.5944098, lng: 17.7749717 },
    { lat: 48.5947521, lng: 17.7751593 },
    { lat: 48.5950253, lng: 17.7752318 },
    { lat: 48.5966579, lng: 17.7755625 },
    { lat: 48.5973222, lng: 17.7760134 },
    { lat: 48.5983841, lng: 17.7771078 },
    { lat: 48.5985411, lng: 17.7774027 },
    { lat: 48.5989339, lng: 17.7787849 },
    { lat: 48.5991353, lng: 17.7786966 },
    { lat: 48.5991321, lng: 17.7786838 },
    { lat: 48.5991537, lng: 17.7785039 },
    { lat: 48.5997728, lng: 17.7780138 },
    { lat: 48.6007903, lng: 17.777574 },
    { lat: 48.6012928, lng: 17.7755724 },
    { lat: 48.601352, lng: 17.7751197 },
    { lat: 48.601347, lng: 17.7736383 },
    { lat: 48.6012718, lng: 17.7726711 },
    { lat: 48.6004595, lng: 17.7663423 },
    { lat: 48.600471, lng: 17.76629 },
    { lat: 48.6012251, lng: 17.7666537 },
    { lat: 48.6013384, lng: 17.7665341 },
    { lat: 48.6012553, lng: 17.7657863 },
    { lat: 48.601355, lng: 17.7646527 },
    { lat: 48.6014529, lng: 17.7624471 },
    { lat: 48.6015215, lng: 17.7620737 },
    { lat: 48.6015691, lng: 17.7594702 },
    { lat: 48.6016457, lng: 17.7587361 },
    { lat: 48.601797, lng: 17.7580718 },
    { lat: 48.6017564, lng: 17.7576708 },
    { lat: 48.6020079, lng: 17.7576124 },
    { lat: 48.6021172, lng: 17.7573125 },
    { lat: 48.6021591, lng: 17.7569478 },
    { lat: 48.6021641, lng: 17.7566093 },
    { lat: 48.6018526, lng: 17.7540246 },
    { lat: 48.6018694, lng: 17.7539138 },
    { lat: 48.6017115, lng: 17.753385 },
    { lat: 48.6016051, lng: 17.7530284 },
    { lat: 48.6014926, lng: 17.7524702 },
    { lat: 48.601416, lng: 17.7517757 },
    { lat: 48.6013639, lng: 17.7500847 },
    { lat: 48.6013597, lng: 17.7490452 },
    { lat: 48.6013946, lng: 17.7488623 },
    { lat: 48.6012804, lng: 17.7485451 },
    { lat: 48.6012783, lng: 17.7481994 },
    { lat: 48.6013252, lng: 17.7468478 },
    { lat: 48.6013608, lng: 17.7458218 },
    { lat: 48.6015485, lng: 17.7434434 },
    { lat: 48.6017944, lng: 17.7421943 },
    { lat: 48.6020061, lng: 17.7413798 },
    { lat: 48.6025715, lng: 17.739619 },
    { lat: 48.602998, lng: 17.7386445 },
    { lat: 48.6034771, lng: 17.7377543 },
    { lat: 48.6047426, lng: 17.735685 },
    { lat: 48.6055189, lng: 17.7340877 },
    { lat: 48.6059394, lng: 17.7328157 },
    { lat: 48.6063843, lng: 17.7313551 },
    { lat: 48.6069914, lng: 17.7287155 },
    { lat: 48.6071078, lng: 17.727732 },
    { lat: 48.6072367, lng: 17.7258509 },
    { lat: 48.6072654, lng: 17.7256221 },
    { lat: 48.6070661, lng: 17.7249897 },
    { lat: 48.6065734, lng: 17.7243448 },
    { lat: 48.6053664, lng: 17.7234667 },
    { lat: 48.6044913, lng: 17.723045 },
    { lat: 48.6035197, lng: 17.7224496 },
    { lat: 48.6026699, lng: 17.7215442 },
    { lat: 48.600669, lng: 17.7185484 },
    { lat: 48.599934, lng: 17.717235 },
    { lat: 48.5985575, lng: 17.7145861 },
    { lat: 48.5979074, lng: 17.7135704 },
    { lat: 48.5978881, lng: 17.7135307 },
    { lat: 48.5978191, lng: 17.7137833 },
    { lat: 48.5975762, lng: 17.7142405 },
    { lat: 48.5968876, lng: 17.7151325 },
    { lat: 48.5967401, lng: 17.7154868 },
    { lat: 48.5964163, lng: 17.7159657 },
    { lat: 48.5961063, lng: 17.7162811 },
    { lat: 48.5954094, lng: 17.7167348 },
    { lat: 48.5951614, lng: 17.7169777 },
    { lat: 48.5944325, lng: 17.7180457 },
    { lat: 48.5940827, lng: 17.7182847 },
    { lat: 48.5938543, lng: 17.7185693 },
    { lat: 48.5934067, lng: 17.718964 },
    { lat: 48.5929841, lng: 17.7191575 },
    { lat: 48.5928989, lng: 17.7192801 },
    { lat: 48.5916898, lng: 17.7202487 },
    { lat: 48.5915474, lng: 17.7204634 },
    { lat: 48.5912756, lng: 17.7204143 },
    { lat: 48.5910446, lng: 17.7208601 },
    { lat: 48.590318, lng: 17.7216709 },
    { lat: 48.589968, lng: 17.7221442 },
    { lat: 48.5897412, lng: 17.722604 },
    { lat: 48.5891242, lng: 17.7241462 },
    { lat: 48.5889104, lng: 17.7247604 },
    { lat: 48.5888956, lng: 17.7253238 },
    { lat: 48.5886619, lng: 17.7262211 },
    { lat: 48.5881398, lng: 17.7278561 },
    { lat: 48.5878609, lng: 17.7289516 },
    { lat: 48.5871182, lng: 17.7312398 },
    { lat: 48.5868075, lng: 17.7320587 },
    { lat: 48.585655, lng: 17.7345473 },
    { lat: 48.5853965, lng: 17.735315 },
    { lat: 48.5851117, lng: 17.7364661 },
    { lat: 48.584718, lng: 17.7389751 },
    { lat: 48.5841498, lng: 17.7411119 },
    { lat: 48.583775, lng: 17.7422278 },
    { lat: 48.5836249, lng: 17.7431435 },
    { lat: 48.5834408, lng: 17.7451947 },
    { lat: 48.5833564, lng: 17.748604 },
    { lat: 48.5831199, lng: 17.7510155 },
    { lat: 48.583014, lng: 17.751347 },
    { lat: 48.5830237, lng: 17.7517432 },
    { lat: 48.5831257, lng: 17.7521696 },
    { lat: 48.5828295, lng: 17.7524768 },
    { lat: 48.582609, lng: 17.7541018 },
    { lat: 48.5825306, lng: 17.7542779 },
    { lat: 48.5826041, lng: 17.7545365 },
    { lat: 48.5823191, lng: 17.7554642 },
    { lat: 48.5823075, lng: 17.7557147 },
    { lat: 48.5822239, lng: 17.7560081 },
    { lat: 48.5822137, lng: 17.7565922 },
    { lat: 48.5819657, lng: 17.7578481 },
    { lat: 48.5819755, lng: 17.7582076 },
  ],
  Piešťany: [
    { lat: 48.6251894, lng: 17.8559841 },
    { lat: 48.6256617, lng: 17.855394 },
    { lat: 48.6291032, lng: 17.8509968 },
    { lat: 48.632909, lng: 17.851204 },
    { lat: 48.635533, lng: 17.850414 },
    { lat: 48.635862, lng: 17.849342 },
    { lat: 48.636193, lng: 17.848296 },
    { lat: 48.636283, lng: 17.848037 },
    { lat: 48.636636, lng: 17.847081 },
    { lat: 48.636987, lng: 17.846064 },
    { lat: 48.636998, lng: 17.846034 },
    { lat: 48.637004, lng: 17.846019 },
    { lat: 48.637154, lng: 17.845581 },
    { lat: 48.637173, lng: 17.845529 },
    { lat: 48.637333, lng: 17.845065 },
    { lat: 48.637345, lng: 17.845028 },
    { lat: 48.637401, lng: 17.844868 },
    { lat: 48.637424, lng: 17.844801 },
    { lat: 48.637435, lng: 17.844771 },
    { lat: 48.637489, lng: 17.844615 },
    { lat: 48.637876, lng: 17.84451 },
    { lat: 48.638158, lng: 17.844433 },
    { lat: 48.638337, lng: 17.844385 },
    { lat: 48.638435, lng: 17.844358 },
    { lat: 48.638509, lng: 17.844338 },
    { lat: 48.639308, lng: 17.844121 },
    { lat: 48.640351, lng: 17.844476 },
    { lat: 48.640525, lng: 17.844535 },
    { lat: 48.64089, lng: 17.843636 },
    { lat: 48.640902, lng: 17.843514 },
    { lat: 48.640903, lng: 17.843507 },
    { lat: 48.640914, lng: 17.843395 },
    { lat: 48.640936, lng: 17.843167 },
    { lat: 48.640937, lng: 17.843145 },
    { lat: 48.640997, lng: 17.842546 },
    { lat: 48.641208, lng: 17.84207 },
    { lat: 48.641356, lng: 17.841292 },
    { lat: 48.641534, lng: 17.840839 },
    { lat: 48.641717, lng: 17.840415 },
    { lat: 48.641865, lng: 17.840183 },
    { lat: 48.642074, lng: 17.83988 },
    { lat: 48.642308, lng: 17.839623 },
    { lat: 48.642462, lng: 17.839421 },
    { lat: 48.642542, lng: 17.839277 },
    { lat: 48.642801, lng: 17.839005 },
    { lat: 48.642933, lng: 17.838884 },
    { lat: 48.64302, lng: 17.838803 },
    { lat: 48.643107, lng: 17.838724 },
    { lat: 48.643279, lng: 17.838605 },
    { lat: 48.643709, lng: 17.838323 },
    { lat: 48.644006, lng: 17.838262 },
    { lat: 48.644207, lng: 17.838269 },
    { lat: 48.644538, lng: 17.838273 },
    { lat: 48.644777, lng: 17.838252 },
    { lat: 48.644823, lng: 17.838262 },
    { lat: 48.645082, lng: 17.838385 },
    { lat: 48.6453978, lng: 17.8384839 },
    { lat: 48.64543, lng: 17.838494 },
    { lat: 48.645619, lng: 17.838571 },
    { lat: 48.645841, lng: 17.838653 },
    { lat: 48.646104, lng: 17.838736 },
    { lat: 48.646259, lng: 17.838785 },
    { lat: 48.646642, lng: 17.838868 },
    { lat: 48.646811, lng: 17.838832 },
    { lat: 48.646844, lng: 17.838825 },
    { lat: 48.646995, lng: 17.838733 },
    { lat: 48.647065, lng: 17.838692 },
    { lat: 48.647193, lng: 17.838615 },
    { lat: 48.647358, lng: 17.838553 },
    { lat: 48.647425, lng: 17.83853 },
    { lat: 48.647682, lng: 17.838593 },
    { lat: 48.647982, lng: 17.838695 },
    { lat: 48.648369, lng: 17.838757 },
    { lat: 48.648859, lng: 17.838669 },
    { lat: 48.649101, lng: 17.838678 },
    { lat: 48.649199, lng: 17.838688 },
    { lat: 48.649225, lng: 17.83869 },
    { lat: 48.649351, lng: 17.838703 },
    { lat: 48.649572, lng: 17.838726 },
    { lat: 48.649602, lng: 17.838733 },
    { lat: 48.649906, lng: 17.838815 },
    { lat: 48.649975, lng: 17.838846 },
    { lat: 48.650171, lng: 17.838931 },
    { lat: 48.650238, lng: 17.838965 },
    { lat: 48.650689, lng: 17.8392 },
    { lat: 48.651008, lng: 17.839365 },
    { lat: 48.651012, lng: 17.839328 },
    { lat: 48.651086, lng: 17.838643 },
    { lat: 48.651197, lng: 17.837615 },
    { lat: 48.651299, lng: 17.836679 },
    { lat: 48.651364, lng: 17.836221 },
    { lat: 48.651374, lng: 17.836155 },
    { lat: 48.651378, lng: 17.836118 },
    { lat: 48.651432, lng: 17.83574 },
    { lat: 48.651446, lng: 17.83565 },
    { lat: 48.651513, lng: 17.835334 },
    { lat: 48.651691, lng: 17.834519 },
    { lat: 48.651928, lng: 17.833529 },
    { lat: 48.652167, lng: 17.831968 },
    { lat: 48.652174, lng: 17.831919 },
    { lat: 48.652303, lng: 17.831075 },
    { lat: 48.652339, lng: 17.830794 },
    { lat: 48.652507, lng: 17.82977 },
    { lat: 48.652611, lng: 17.829089 },
    { lat: 48.652647, lng: 17.828842 },
    { lat: 48.652655, lng: 17.828779 },
    { lat: 48.652706, lng: 17.828439 },
    { lat: 48.652903, lng: 17.827296 },
    { lat: 48.652996, lng: 17.826707 },
    { lat: 48.653126, lng: 17.825734 },
    { lat: 48.653171, lng: 17.825053 },
    { lat: 48.653218, lng: 17.824263 },
    { lat: 48.653224, lng: 17.824207 },
    { lat: 48.653229, lng: 17.82416 },
    { lat: 48.653259, lng: 17.823878 },
    { lat: 48.653263, lng: 17.823396 },
    { lat: 48.653278, lng: 17.823337 },
    { lat: 48.653208, lng: 17.823183 },
    { lat: 48.653104, lng: 17.823004 },
    { lat: 48.653018, lng: 17.822236 },
    { lat: 48.653059, lng: 17.821905 },
    { lat: 48.653042, lng: 17.821713 },
    { lat: 48.652949, lng: 17.821559 },
    { lat: 48.652868, lng: 17.821463 },
    { lat: 48.652737, lng: 17.821453 },
    { lat: 48.652335, lng: 17.821462 },
    { lat: 48.652276, lng: 17.821443 },
    { lat: 48.652214, lng: 17.821386 },
    { lat: 48.652172, lng: 17.821347 },
    { lat: 48.652192, lng: 17.821316 },
    { lat: 48.652168, lng: 17.821274 },
    { lat: 48.652089, lng: 17.821135 },
    { lat: 48.652021, lng: 17.820835 },
    { lat: 48.651979, lng: 17.820709 },
    { lat: 48.651928, lng: 17.820587 },
    { lat: 48.651858, lng: 17.820515 },
    { lat: 48.651769, lng: 17.820461 },
    { lat: 48.651668, lng: 17.820488 },
    { lat: 48.651576, lng: 17.820686 },
    { lat: 48.65156, lng: 17.820872 },
    { lat: 48.651668, lng: 17.82113 },
    { lat: 48.65166, lng: 17.821758 },
    { lat: 48.651683, lng: 17.822191 },
    { lat: 48.651762, lng: 17.822472 },
    { lat: 48.65185, lng: 17.822684 },
    { lat: 48.651835, lng: 17.822983 },
    { lat: 48.65182, lng: 17.823096 },
    { lat: 48.651761, lng: 17.823225 },
    { lat: 48.65169, lng: 17.823298 },
    { lat: 48.651492, lng: 17.823353 },
    { lat: 48.650845, lng: 17.822898 },
    { lat: 48.650605, lng: 17.822842 },
    { lat: 48.650554, lng: 17.822792 },
    { lat: 48.650524, lng: 17.822708 },
    { lat: 48.650508, lng: 17.82266 },
    { lat: 48.650505, lng: 17.822513 },
    { lat: 48.650549, lng: 17.822261 },
    { lat: 48.650668, lng: 17.821837 },
    { lat: 48.650699, lng: 17.821843 },
    { lat: 48.650758, lng: 17.821729 },
    { lat: 48.650771, lng: 17.821503 },
    { lat: 48.650721, lng: 17.821184 },
    { lat: 48.650482, lng: 17.82088 },
    { lat: 48.65027, lng: 17.820797 },
    { lat: 48.650041, lng: 17.820827 },
    { lat: 48.649949, lng: 17.820838 },
    { lat: 48.649898, lng: 17.82094 },
    { lat: 48.649691, lng: 17.822094 },
    { lat: 48.649625, lng: 17.822562 },
    { lat: 48.649573, lng: 17.822684 },
    { lat: 48.649566, lng: 17.822872 },
    { lat: 48.649549, lng: 17.82336 },
    { lat: 48.649438, lng: 17.823491 },
    { lat: 48.649337, lng: 17.823482 },
    { lat: 48.649274, lng: 17.823449 },
    { lat: 48.649165, lng: 17.823393 },
    { lat: 48.649107, lng: 17.82335 },
    { lat: 48.649048, lng: 17.823294 },
    { lat: 48.648941, lng: 17.823124 },
    { lat: 48.648966, lng: 17.822902 },
    { lat: 48.649054, lng: 17.822771 },
    { lat: 48.649089, lng: 17.822698 },
    { lat: 48.649111, lng: 17.822653 },
    { lat: 48.649112, lng: 17.82246 },
    { lat: 48.649071, lng: 17.822337 },
    { lat: 48.648985, lng: 17.822259 },
    { lat: 48.64893, lng: 17.822235 },
    { lat: 48.648894, lng: 17.82222 },
    { lat: 48.648714, lng: 17.822306 },
    { lat: 48.648609, lng: 17.822412 },
    { lat: 48.648554, lng: 17.822503 },
    { lat: 48.648294, lng: 17.822925 },
    { lat: 48.648288, lng: 17.82293 },
    { lat: 48.648093, lng: 17.823084 },
    { lat: 48.647981, lng: 17.823124 },
    { lat: 48.647907, lng: 17.823125 },
    { lat: 48.647685, lng: 17.823082 },
    { lat: 48.647526, lng: 17.823014 },
    { lat: 48.64722, lng: 17.82261 },
    { lat: 48.647101, lng: 17.822537 },
    { lat: 48.646967, lng: 17.822576 },
    { lat: 48.646823, lng: 17.822751 },
    { lat: 48.64678, lng: 17.822864 },
    { lat: 48.64676, lng: 17.823118 },
    { lat: 48.646807, lng: 17.823299 },
    { lat: 48.646874, lng: 17.823437 },
    { lat: 48.646927, lng: 17.823592 },
    { lat: 48.646914, lng: 17.823839 },
    { lat: 48.646709, lng: 17.824095 },
    { lat: 48.646634, lng: 17.824147 },
    { lat: 48.64656, lng: 17.824123 },
    { lat: 48.646423, lng: 17.82404 },
    { lat: 48.646271, lng: 17.823866 },
    { lat: 48.646216, lng: 17.823821 },
    { lat: 48.645926, lng: 17.823581 },
    { lat: 48.645904, lng: 17.823563 },
    { lat: 48.645787, lng: 17.823379 },
    { lat: 48.6455, lng: 17.822931 },
    { lat: 48.645247, lng: 17.822561 },
    { lat: 48.64521, lng: 17.822553 },
    { lat: 48.645017, lng: 17.822512 },
    { lat: 48.644853, lng: 17.822477 },
    { lat: 48.644782, lng: 17.822462 },
    { lat: 48.644719, lng: 17.82246 },
    { lat: 48.644273, lng: 17.822479 },
    { lat: 48.644023, lng: 17.822556 },
    { lat: 48.6437, lng: 17.822548 },
    { lat: 48.643311, lng: 17.82256 },
    { lat: 48.642715, lng: 17.822025 },
    { lat: 48.642543, lng: 17.822032 },
    { lat: 48.64194, lng: 17.822294 },
    { lat: 48.641748, lng: 17.822408 },
    { lat: 48.641631, lng: 17.822413 },
    { lat: 48.641336, lng: 17.822361 },
    { lat: 48.641142, lng: 17.822207 },
    { lat: 48.640823, lng: 17.821783 },
    { lat: 48.64066, lng: 17.821598 },
    { lat: 48.640527, lng: 17.821458 },
    { lat: 48.640282, lng: 17.821257 },
    { lat: 48.639815, lng: 17.820779 },
    { lat: 48.639368, lng: 17.820472 },
    { lat: 48.6386723, lng: 17.8205398 },
    { lat: 48.6382189, lng: 17.8210072 },
    { lat: 48.6376223, lng: 17.8213344 },
    { lat: 48.6374108, lng: 17.8212906 },
    { lat: 48.637179, lng: 17.8210622 },
    { lat: 48.6369331, lng: 17.8202304 },
    { lat: 48.6367294, lng: 17.8199264 },
    { lat: 48.6360445, lng: 17.8198943 },
    { lat: 48.6358485, lng: 17.820867 },
    { lat: 48.6354953, lng: 17.8212005 },
    { lat: 48.6349327, lng: 17.8221786 },
    { lat: 48.6343407, lng: 17.8228018 },
    { lat: 48.6343528, lng: 17.8228483 },
    { lat: 48.6326535, lng: 17.822549 },
    { lat: 48.6323405, lng: 17.8275122 },
    { lat: 48.6313612, lng: 17.8274477 },
    { lat: 48.6305278, lng: 17.8272566 },
    { lat: 48.6303767, lng: 17.825945 },
    { lat: 48.6300041, lng: 17.8249424 },
    { lat: 48.6297815, lng: 17.8245122 },
    { lat: 48.6287236, lng: 17.823716 },
    { lat: 48.6284801, lng: 17.8236302 },
    { lat: 48.6281007, lng: 17.8236807 },
    { lat: 48.6277348, lng: 17.8234948 },
    { lat: 48.6273297, lng: 17.8231295 },
    { lat: 48.6272278, lng: 17.8224538 },
    { lat: 48.6270511, lng: 17.8219074 },
    { lat: 48.6269792, lng: 17.8213606 },
    { lat: 48.6266305, lng: 17.820918 },
    { lat: 48.6265295, lng: 17.8208899 },
    { lat: 48.6263799, lng: 17.8210641 },
    { lat: 48.6265221, lng: 17.8214529 },
    { lat: 48.6262245, lng: 17.8221465 },
    { lat: 48.6261295, lng: 17.8221597 },
    { lat: 48.6255504, lng: 17.8217179 },
    { lat: 48.6253761, lng: 17.8213235 },
    { lat: 48.6250981, lng: 17.8198701 },
    { lat: 48.6246994, lng: 17.8195154 },
    { lat: 48.624788, lng: 17.8195067 },
    { lat: 48.6250039, lng: 17.8192534 },
    { lat: 48.6251248, lng: 17.8188255 },
    { lat: 48.6249774, lng: 17.8178714 },
    { lat: 48.6248477, lng: 17.8174673 },
    { lat: 48.6247367, lng: 17.8174238 },
    { lat: 48.6241572, lng: 17.8175485 },
    { lat: 48.6239133, lng: 17.8177507 },
    { lat: 48.6238271, lng: 17.8181534 },
    { lat: 48.623845, lng: 17.8185203 },
    { lat: 48.6236818, lng: 17.8190282 },
    { lat: 48.62318, lng: 17.819055 },
    { lat: 48.6228757, lng: 17.8190262 },
    { lat: 48.6227437, lng: 17.8189532 },
    { lat: 48.622412, lng: 17.8169399 },
    { lat: 48.622716, lng: 17.8162961 },
    { lat: 48.6227991, lng: 17.8159203 },
    { lat: 48.622672, lng: 17.8154266 },
    { lat: 48.622525, lng: 17.815072 },
    { lat: 48.6222339, lng: 17.8135573 },
    { lat: 48.6219546, lng: 17.8131986 },
    { lat: 48.6217214, lng: 17.8126886 },
    { lat: 48.6215248, lng: 17.8122031 },
    { lat: 48.6214544, lng: 17.8116823 },
    { lat: 48.6215731, lng: 17.8107777 },
    { lat: 48.6221316, lng: 17.8097738 },
    { lat: 48.6226383, lng: 17.8092346 },
    { lat: 48.6229488, lng: 17.8086986 },
    { lat: 48.6229929, lng: 17.8084045 },
    { lat: 48.622971, lng: 17.8075266 },
    { lat: 48.6228551, lng: 17.8073685 },
    { lat: 48.6225272, lng: 17.8071161 },
    { lat: 48.6222328, lng: 17.8067153 },
    { lat: 48.621732, lng: 17.8065765 },
    { lat: 48.6220201, lng: 17.8053793 },
    { lat: 48.6219601, lng: 17.8048574 },
    { lat: 48.6219682, lng: 17.8042504 },
    { lat: 48.6221066, lng: 17.8038365 },
    { lat: 48.6221415, lng: 17.8035208 },
    { lat: 48.6222576, lng: 17.80313 },
    { lat: 48.6222739, lng: 17.802049 },
    { lat: 48.6222231, lng: 17.8017182 },
    { lat: 48.6221203, lng: 17.8015301 },
    { lat: 48.6213421, lng: 17.8007848 },
    { lat: 48.6213164, lng: 17.7995898 },
    { lat: 48.6212516, lng: 17.7996363 },
    { lat: 48.620447, lng: 17.8000316 },
    { lat: 48.6202751, lng: 17.8003966 },
    { lat: 48.6199846, lng: 17.8000406 },
    { lat: 48.6195372, lng: 17.7998652 },
    { lat: 48.6193554, lng: 17.7999029 },
    { lat: 48.619304, lng: 17.7999679 },
    { lat: 48.6193078, lng: 17.8002692 },
    { lat: 48.6193864, lng: 17.8007807 },
    { lat: 48.6193033, lng: 17.8009353 },
    { lat: 48.6192961, lng: 17.8012967 },
    { lat: 48.6191924, lng: 17.8014274 },
    { lat: 48.6190738, lng: 17.8017426 },
    { lat: 48.6184921, lng: 17.8016822 },
    { lat: 48.6184413, lng: 17.8019968 },
    { lat: 48.6179199, lng: 17.8022945 },
    { lat: 48.6177305, lng: 17.802521 },
    { lat: 48.6173515, lng: 17.8027642 },
    { lat: 48.6172387, lng: 17.8027688 },
    { lat: 48.617122, lng: 17.8026512 },
    { lat: 48.6169667, lng: 17.8027495 },
    { lat: 48.6168529, lng: 17.8029202 },
    { lat: 48.6163732, lng: 17.8029951 },
    { lat: 48.6157552, lng: 17.8037828 },
    { lat: 48.6156309, lng: 17.8038169 },
    { lat: 48.6153832, lng: 17.8035877 },
    { lat: 48.6152299, lng: 17.8032047 },
    { lat: 48.6147291, lng: 17.8040796 },
    { lat: 48.614165, lng: 17.8045308 },
    { lat: 48.613879, lng: 17.8046705 },
    { lat: 48.6116853, lng: 17.8035041 },
    { lat: 48.6116418, lng: 17.8033279 },
    { lat: 48.608813, lng: 17.7993632 },
    { lat: 48.6070879, lng: 17.7877594 },
    { lat: 48.6071571, lng: 17.786716 },
    { lat: 48.6081768, lng: 17.7832195 },
    { lat: 48.608502, lng: 17.7818406 },
    { lat: 48.6071201, lng: 17.7810487 },
    { lat: 48.6067372, lng: 17.7809537 },
    { lat: 48.6065979, lng: 17.7810391 },
    { lat: 48.6063133, lng: 17.7815515 },
    { lat: 48.6057455, lng: 17.7817912 },
    { lat: 48.6052102, lng: 17.7811627 },
    { lat: 48.6047647, lng: 17.7811161 },
    { lat: 48.604348, lng: 17.7817945 },
    { lat: 48.6046196, lng: 17.7827436 },
    { lat: 48.6041922, lng: 17.7829538 },
    { lat: 48.603782, lng: 17.7822676 },
    { lat: 48.6034392, lng: 17.7813722 },
    { lat: 48.6015644, lng: 17.7816008 },
    { lat: 48.6013304, lng: 17.7812264 },
    { lat: 48.6013226, lng: 17.780196 },
    { lat: 48.6008967, lng: 17.7798241 },
    { lat: 48.6004903, lng: 17.7796779 },
    { lat: 48.599509, lng: 17.7790468 },
    { lat: 48.5992626, lng: 17.7788243 },
    { lat: 48.5991363, lng: 17.7788441 },
    { lat: 48.5991362, lng: 17.7787121 },
    { lat: 48.5991353, lng: 17.7786966 },
    { lat: 48.5989339, lng: 17.7787849 },
    { lat: 48.5985411, lng: 17.7774027 },
    { lat: 48.5983841, lng: 17.7771078 },
    { lat: 48.5973222, lng: 17.7760134 },
    { lat: 48.5966579, lng: 17.7755625 },
    { lat: 48.5950253, lng: 17.7752318 },
    { lat: 48.5947521, lng: 17.7751593 },
    { lat: 48.5944098, lng: 17.7749717 },
    { lat: 48.5944407, lng: 17.774793 },
    { lat: 48.5943198, lng: 17.7704149 },
    { lat: 48.5942261, lng: 17.7696266 },
    { lat: 48.5928732, lng: 17.7689236 },
    { lat: 48.5910489, lng: 17.7676748 },
    { lat: 48.5899015, lng: 17.767155 },
    { lat: 48.5891478, lng: 17.7666669 },
    { lat: 48.5882156, lng: 17.7661868 },
    { lat: 48.5880178, lng: 17.7660994 },
    { lat: 48.5874631, lng: 17.7655243 },
    { lat: 48.5859608, lng: 17.7641876 },
    { lat: 48.5861224, lng: 17.7635107 },
    { lat: 48.5852413, lng: 17.7628447 },
    { lat: 48.5841316, lng: 17.7617803 },
    { lat: 48.5836471, lng: 17.7619076 },
    { lat: 48.5823223, lng: 17.7585413 },
    { lat: 48.5822155, lng: 17.7584924 },
    { lat: 48.5820328, lng: 17.7582766 },
    { lat: 48.5819755, lng: 17.7582076 },
    { lat: 48.5817591, lng: 17.7581391 },
    { lat: 48.5732102, lng: 17.758231 },
    { lat: 48.5726336, lng: 17.7688446 },
    { lat: 48.5689785, lng: 17.7684291 },
    { lat: 48.5671031, lng: 17.7681908 },
    { lat: 48.5670082, lng: 17.7711459 },
    { lat: 48.5667363, lng: 17.7725658 },
    { lat: 48.5666294, lng: 17.7744253 },
    { lat: 48.5665253, lng: 17.7749189 },
    { lat: 48.5663884, lng: 17.7753896 },
    { lat: 48.5661604, lng: 17.7751911 },
    { lat: 48.565518, lng: 17.7753014 },
    { lat: 48.5652372, lng: 17.7747538 },
    { lat: 48.5651448, lng: 17.7743907 },
    { lat: 48.5648359, lng: 17.7743411 },
    { lat: 48.5646421, lng: 17.7745488 },
    { lat: 48.5645915, lng: 17.774846 },
    { lat: 48.5643997, lng: 17.7750695 },
    { lat: 48.5642857, lng: 17.7750313 },
    { lat: 48.5639513, lng: 17.7751231 },
    { lat: 48.563846, lng: 17.7751913 },
    { lat: 48.5637592, lng: 17.7754493 },
    { lat: 48.5635104, lng: 17.7756444 },
    { lat: 48.5628922, lng: 17.7757239 },
    { lat: 48.5631556, lng: 17.7764659 },
    { lat: 48.5626569, lng: 17.7770048 },
    { lat: 48.5626474, lng: 17.7772738 },
    { lat: 48.5629687, lng: 17.7777986 },
    { lat: 48.5631674, lng: 17.7789043 },
    { lat: 48.5631446, lng: 17.7792301 },
    { lat: 48.5630814, lng: 17.7794534 },
    { lat: 48.5628945, lng: 17.7797725 },
    { lat: 48.5626865, lng: 17.7798795 },
    { lat: 48.5623129, lng: 17.7798061 },
    { lat: 48.5616349, lng: 17.779171 },
    { lat: 48.5615212, lng: 17.7791758 },
    { lat: 48.5614611, lng: 17.7790987 },
    { lat: 48.5612316, lng: 17.7789293 },
    { lat: 48.5611077, lng: 17.7786953 },
    { lat: 48.5611209, lng: 17.7777119 },
    { lat: 48.5609889, lng: 17.7771451 },
    { lat: 48.5607399, lng: 17.7770629 },
    { lat: 48.5603063, lng: 17.7773906 },
    { lat: 48.5601727, lng: 17.7779923 },
    { lat: 48.559936, lng: 17.7786422 },
    { lat: 48.5595327, lng: 17.7785805 },
    { lat: 48.5592968, lng: 17.7784337 },
    { lat: 48.5590366, lng: 17.7781147 },
    { lat: 48.5590967, lng: 17.7773094 },
    { lat: 48.5592592, lng: 17.7767005 },
    { lat: 48.5590814, lng: 17.7758813 },
    { lat: 48.558158, lng: 17.7757584 },
    { lat: 48.557958, lng: 17.7756983 },
    { lat: 48.5577397, lng: 17.775526 },
    { lat: 48.5575033, lng: 17.7754638 },
    { lat: 48.5574511, lng: 17.7762854 },
    { lat: 48.5580084, lng: 17.7780994 },
    { lat: 48.5579145, lng: 17.7783723 },
    { lat: 48.5572035, lng: 17.7783937 },
    { lat: 48.5567461, lng: 17.7786855 },
    { lat: 48.556361, lng: 17.7781159 },
    { lat: 48.5562823, lng: 17.778175 },
    { lat: 48.5561726, lng: 17.7789087 },
    { lat: 48.5561528, lng: 17.7796883 },
    { lat: 48.5556621, lng: 17.7797328 },
    { lat: 48.5554574, lng: 17.7793583 },
    { lat: 48.5549794, lng: 17.779075 },
    { lat: 48.5545899, lng: 17.779259 },
    { lat: 48.5495087, lng: 17.7813437 },
    { lat: 48.5495841, lng: 17.7815969 },
    { lat: 48.5494892, lng: 17.7827522 },
    { lat: 48.5489324, lng: 17.7825493 },
    { lat: 48.548839, lng: 17.7829204 },
    { lat: 48.5487686, lng: 17.7836307 },
    { lat: 48.5487712, lng: 17.7844093 },
    { lat: 48.5490543, lng: 17.785214 },
    { lat: 48.5495377, lng: 17.7855123 },
    { lat: 48.5497607, lng: 17.7858158 },
    { lat: 48.5498035, lng: 17.7860546 },
    { lat: 48.5498217, lng: 17.7864783 },
    { lat: 48.5497563, lng: 17.7871979 },
    { lat: 48.5497785, lng: 17.7875789 },
    { lat: 48.5496942, lng: 17.7882299 },
    { lat: 48.5496416, lng: 17.7883298 },
    { lat: 48.5496366, lng: 17.7891127 },
    { lat: 48.5498916, lng: 17.7904006 },
    { lat: 48.5500437, lng: 17.7907828 },
    { lat: 48.5501451, lng: 17.7912005 },
    { lat: 48.5501869, lng: 17.7914427 },
    { lat: 48.5501668, lng: 17.7920149 },
    { lat: 48.550265, lng: 17.7927178 },
    { lat: 48.5503004, lng: 17.7941207 },
    { lat: 48.5500718, lng: 17.7957528 },
    { lat: 48.5503813, lng: 17.7971379 },
    { lat: 48.5511773, lng: 17.7990416 },
    { lat: 48.5510181, lng: 17.8001074 },
    { lat: 48.5509303, lng: 17.8011472 },
    { lat: 48.5508981, lng: 17.8024444 },
    { lat: 48.5511557, lng: 17.8042583 },
    { lat: 48.5509641, lng: 17.8053518 },
    { lat: 48.5502139, lng: 17.8083688 },
    { lat: 48.5488021, lng: 17.8114222 },
    { lat: 48.5482516, lng: 17.8120754 },
    { lat: 48.5487263, lng: 17.8136546 },
    { lat: 48.5490461, lng: 17.8140144 },
    { lat: 48.5485611, lng: 17.8144073 },
    { lat: 48.5471454, lng: 17.822263 },
    { lat: 48.5493201, lng: 17.8273588 },
    { lat: 48.5535187, lng: 17.8328832 },
    { lat: 48.5542343, lng: 17.8342541 },
    { lat: 48.5563625, lng: 17.835499 },
    { lat: 48.5574653, lng: 17.8354192 },
    { lat: 48.5576486, lng: 17.8350771 },
    { lat: 48.5577269, lng: 17.8342114 },
    { lat: 48.5576322, lng: 17.83301 },
    { lat: 48.5589183, lng: 17.8328846 },
    { lat: 48.5594511, lng: 17.832901 },
    { lat: 48.5620439, lng: 17.831824 },
    { lat: 48.5620964, lng: 17.8300334 },
    { lat: 48.5635448, lng: 17.8292553 },
    { lat: 48.5659691, lng: 17.8285994 },
    { lat: 48.5669429, lng: 17.8289975 },
    { lat: 48.5679779, lng: 17.8295144 },
    { lat: 48.5683558, lng: 17.8295634 },
    { lat: 48.5681971, lng: 17.8289045 },
    { lat: 48.5689391, lng: 17.8293558 },
    { lat: 48.5689673, lng: 17.8293176 },
    { lat: 48.5695643, lng: 17.8295726 },
    { lat: 48.5728417, lng: 17.8305406 },
    { lat: 48.5740383, lng: 17.8310791 },
    { lat: 48.576015, lng: 17.8323175 },
    { lat: 48.5760449, lng: 17.8322713 },
    { lat: 48.5774218, lng: 17.8335093 },
    { lat: 48.5782896, lng: 17.8341329 },
    { lat: 48.5788758, lng: 17.8345289 },
    { lat: 48.5797756, lng: 17.8349196 },
    { lat: 48.581881, lng: 17.8363276 },
    { lat: 48.5833086, lng: 17.8375814 },
    { lat: 48.5847232, lng: 17.8392338 },
    { lat: 48.5847958, lng: 17.8392765 },
    { lat: 48.5849503, lng: 17.8391839 },
    { lat: 48.5850407, lng: 17.839258 },
    { lat: 48.5850383, lng: 17.8393259 },
    { lat: 48.5850275, lng: 17.8396356 },
    { lat: 48.5854239, lng: 17.8405838 },
    { lat: 48.5855982, lng: 17.8417802 },
    { lat: 48.5855984, lng: 17.8426752 },
    { lat: 48.5853838, lng: 17.8438768 },
    { lat: 48.5854519, lng: 17.8448483 },
    { lat: 48.5855284, lng: 17.8451069 },
    { lat: 48.5861658, lng: 17.8462483 },
    { lat: 48.5866986, lng: 17.8467876 },
    { lat: 48.5868319, lng: 17.8468085 },
    { lat: 48.5868376, lng: 17.8468826 },
    { lat: 48.5870124, lng: 17.8470852 },
    { lat: 48.5869856, lng: 17.8472016 },
    { lat: 48.5885636, lng: 17.8484424 },
    { lat: 48.5885971, lng: 17.8483437 },
    { lat: 48.5888575, lng: 17.8485409 },
    { lat: 48.5888248, lng: 17.8486367 },
    { lat: 48.5896921, lng: 17.8493475 },
    { lat: 48.5926827, lng: 17.8516734 },
    { lat: 48.593055, lng: 17.8519305 },
    { lat: 48.5930684, lng: 17.8518894 },
    { lat: 48.593522, lng: 17.8504931 },
    { lat: 48.5944085, lng: 17.8506282 },
    { lat: 48.5953739, lng: 17.8506309 },
    { lat: 48.5951025, lng: 17.8516087 },
    { lat: 48.5962729, lng: 17.8523316 },
    { lat: 48.5963874, lng: 17.8525812 },
    { lat: 48.5969906, lng: 17.8528849 },
    { lat: 48.5975708, lng: 17.8529029 },
    { lat: 48.5978599, lng: 17.8526626 },
    { lat: 48.5984958, lng: 17.8518484 },
    { lat: 48.5985426, lng: 17.8516172 },
    { lat: 48.5984417, lng: 17.8511639 },
    { lat: 48.5983896, lng: 17.8503797 },
    { lat: 48.6005961, lng: 17.8500944 },
    { lat: 48.6007767, lng: 17.850633 },
    { lat: 48.6053029, lng: 17.8498951 },
    { lat: 48.6082284, lng: 17.8488078 },
    { lat: 48.6089524, lng: 17.848853 },
    { lat: 48.6100062, lng: 17.8487474 },
    { lat: 48.6104812, lng: 17.8485206 },
    { lat: 48.6110938, lng: 17.8489254 },
    { lat: 48.6116046, lng: 17.8488599 },
    { lat: 48.6130964, lng: 17.8493334 },
    { lat: 48.6136087, lng: 17.849145 },
    { lat: 48.6145214, lng: 17.8503418 },
    { lat: 48.6152801, lng: 17.8530404 },
    { lat: 48.6169305, lng: 17.8545869 },
    { lat: 48.6184604, lng: 17.8561515 },
    { lat: 48.6194595, lng: 17.8563226 },
    { lat: 48.6205407, lng: 17.8560942 },
    { lat: 48.6217249, lng: 17.8551623 },
    { lat: 48.6225694, lng: 17.8550532 },
    { lat: 48.6234, lng: 17.8558845 },
    { lat: 48.6251894, lng: 17.8559841 },
  ],
  VeľkéKostoľany: [
    { lat: 48.5091064, lng: 17.7603707 },
    { lat: 48.5090779, lng: 17.7600731 },
    { lat: 48.5090085, lng: 17.7593835 },
    { lat: 48.5095024, lng: 17.758093 },
    { lat: 48.5089593, lng: 17.7576333 },
    { lat: 48.5090009, lng: 17.7565693 },
    { lat: 48.5087682, lng: 17.7553054 },
    { lat: 48.5084045, lng: 17.7542215 },
    { lat: 48.5086292, lng: 17.7522995 },
    { lat: 48.5091789, lng: 17.7513667 },
    { lat: 48.5095485, lng: 17.7504929 },
    { lat: 48.5096284, lng: 17.7501043 },
    { lat: 48.5096718, lng: 17.7494346 },
    { lat: 48.5101297, lng: 17.7490416 },
    { lat: 48.5103172, lng: 17.7485493 },
    { lat: 48.5106827, lng: 17.7482539 },
    { lat: 48.5111134, lng: 17.7476393 },
    { lat: 48.5112543, lng: 17.7472699 },
    { lat: 48.5115713, lng: 17.7454363 },
    { lat: 48.5122615, lng: 17.7448598 },
    { lat: 48.5169902, lng: 17.7386524 },
    { lat: 48.5176525, lng: 17.737034 },
    { lat: 48.5179232, lng: 17.7366827 },
    { lat: 48.519573, lng: 17.7334394 },
    { lat: 48.5205844, lng: 17.7316644 },
    { lat: 48.5210664, lng: 17.731152 },
    { lat: 48.5211941, lng: 17.7303865 },
    { lat: 48.5212516, lng: 17.7295265 },
    { lat: 48.5214688, lng: 17.72771 },
    { lat: 48.5217651, lng: 17.7257665 },
    { lat: 48.5228, lng: 17.7212558 },
    { lat: 48.5237666, lng: 17.7185076 },
    { lat: 48.52441, lng: 17.7170096 },
    { lat: 48.5245351, lng: 17.7168257 },
    { lat: 48.5257094, lng: 17.7142037 },
    { lat: 48.5267565, lng: 17.7116248 },
    { lat: 48.5285539, lng: 17.7046931 },
    { lat: 48.5281828, lng: 17.7044055 },
    { lat: 48.5288322, lng: 17.7021551 },
    { lat: 48.5294375, lng: 17.7002493 },
    { lat: 48.5295604, lng: 17.7003 },
    { lat: 48.5308584, lng: 17.6963867 },
    { lat: 48.5317901, lng: 17.6932837 },
    { lat: 48.5316386, lng: 17.6931725 },
    { lat: 48.5322015, lng: 17.6911498 },
    { lat: 48.5409128, lng: 17.6821172 },
    { lat: 48.5416229, lng: 17.6816292 },
    { lat: 48.5414216, lng: 17.6813858 },
    { lat: 48.5413425, lng: 17.6812405 },
    { lat: 48.5408522, lng: 17.6815246 },
    { lat: 48.5402102, lng: 17.6817507 },
    { lat: 48.5395527, lng: 17.681835 },
    { lat: 48.5390481, lng: 17.68171 },
    { lat: 48.5386679, lng: 17.6814983 },
    { lat: 48.5381902, lng: 17.681043 },
    { lat: 48.5380576, lng: 17.6808159 },
    { lat: 48.5384895, lng: 17.6800477 },
    { lat: 48.5372962, lng: 17.6781124 },
    { lat: 48.5363764, lng: 17.6768839 },
    { lat: 48.5366631, lng: 17.6764308 },
    { lat: 48.5371525, lng: 17.6751877 },
    { lat: 48.5372847, lng: 17.6747728 },
    { lat: 48.5372669, lng: 17.6747611 },
    { lat: 48.5369816, lng: 17.6746022 },
    { lat: 48.5355985, lng: 17.6741138 },
    { lat: 48.5344461, lng: 17.6739603 },
    { lat: 48.5317673, lng: 17.6724663 },
    { lat: 48.5318388, lng: 17.6719667 },
    { lat: 48.5305977, lng: 17.6713805 },
    { lat: 48.5294932, lng: 17.6710541 },
    { lat: 48.528157, lng: 17.6709192 },
    { lat: 48.528105, lng: 17.6711891 },
    { lat: 48.5277923, lng: 17.6711917 },
    { lat: 48.527771, lng: 17.6725487 },
    { lat: 48.5274126, lng: 17.6723988 },
    { lat: 48.5272727, lng: 17.6729048 },
    { lat: 48.5272025, lng: 17.6729749 },
    { lat: 48.5271699, lng: 17.6731235 },
    { lat: 48.5272804, lng: 17.6733736 },
    { lat: 48.5272683, lng: 17.6736168 },
    { lat: 48.5273803, lng: 17.6737089 },
    { lat: 48.5274156, lng: 17.673929 },
    { lat: 48.527355, lng: 17.6741189 },
    { lat: 48.5273913, lng: 17.6744094 },
    { lat: 48.5270988, lng: 17.6754055 },
    { lat: 48.5265733, lng: 17.6763592 },
    { lat: 48.5256531, lng: 17.6774439 },
    { lat: 48.5252194, lng: 17.6773709 },
    { lat: 48.5244093, lng: 17.6770383 },
    { lat: 48.5229184, lng: 17.6760889 },
    { lat: 48.5219901, lng: 17.6753574 },
    { lat: 48.5215286, lng: 17.6749119 },
    { lat: 48.5197283, lng: 17.6729481 },
    { lat: 48.5179659, lng: 17.6707797 },
    { lat: 48.5178125, lng: 17.6705464 },
    { lat: 48.516969, lng: 17.6687535 },
    { lat: 48.5167907, lng: 17.6682859 },
    { lat: 48.5157817, lng: 17.6662864 },
    { lat: 48.5142777, lng: 17.6637545 },
    { lat: 48.513166, lng: 17.662121 },
    { lat: 48.512543, lng: 17.662372 },
    { lat: 48.512041, lng: 17.66258 },
    { lat: 48.511572, lng: 17.662758 },
    { lat: 48.510982, lng: 17.663009 },
    { lat: 48.51045, lng: 17.663388 },
    { lat: 48.509977, lng: 17.663715 },
    { lat: 48.509598, lng: 17.663987 },
    { lat: 48.509048, lng: 17.664379 },
    { lat: 48.508826, lng: 17.664515 },
    { lat: 48.508329, lng: 17.664978 },
    { lat: 48.50805, lng: 17.665231 },
    { lat: 48.507772, lng: 17.665422 },
    { lat: 48.507353, lng: 17.665611 },
    { lat: 48.50695, lng: 17.665788 },
    { lat: 48.506523, lng: 17.665988 },
    { lat: 48.505992, lng: 17.666227 },
    { lat: 48.505641, lng: 17.66639 },
    { lat: 48.505505, lng: 17.666472 },
    { lat: 48.50511, lng: 17.666998 },
    { lat: 48.504904, lng: 17.667279 },
    { lat: 48.504508, lng: 17.667662 },
    { lat: 48.504339, lng: 17.667824 },
    { lat: 48.504184, lng: 17.667902 },
    { lat: 48.504092, lng: 17.667933 },
    { lat: 48.503856, lng: 17.668075 },
    { lat: 48.503634, lng: 17.668238 },
    { lat: 48.503364, lng: 17.668441 },
    { lat: 48.502981, lng: 17.668816 },
    { lat: 48.5026, lng: 17.669191 },
    { lat: 48.502228, lng: 17.669569 },
    { lat: 48.501955, lng: 17.669838 },
    { lat: 48.501729, lng: 17.670094 },
    { lat: 48.501473, lng: 17.670355 },
    { lat: 48.501189, lng: 17.67062 },
    { lat: 48.500869, lng: 17.67093 },
    { lat: 48.500576, lng: 17.671214 },
    { lat: 48.50025, lng: 17.671516 },
    { lat: 48.499986, lng: 17.671762 },
    { lat: 48.499646, lng: 17.672232 },
    { lat: 48.499263, lng: 17.672747 },
    { lat: 48.499073, lng: 17.672964 },
    { lat: 48.498577, lng: 17.673532 },
    { lat: 48.4985674, lng: 17.6735446 },
    { lat: 48.498374, lng: 17.673797 },
    { lat: 48.498354, lng: 17.673823 },
    { lat: 48.498042, lng: 17.674239 },
    { lat: 48.497589, lng: 17.674715 },
    { lat: 48.497528, lng: 17.674787 },
    { lat: 48.497091, lng: 17.675399 },
    { lat: 48.497049, lng: 17.675485 },
    { lat: 48.496777, lng: 17.675862 },
    { lat: 48.496706, lng: 17.67597 },
    { lat: 48.496559, lng: 17.676226 },
    { lat: 48.496386, lng: 17.676599 },
    { lat: 48.496308, lng: 17.676743 },
    { lat: 48.496199, lng: 17.67688 },
    { lat: 48.495959, lng: 17.677143 },
    { lat: 48.495493, lng: 17.677622 },
    { lat: 48.495143, lng: 17.678069 },
    { lat: 48.494857, lng: 17.678436 },
    { lat: 48.494662, lng: 17.678647 },
    { lat: 48.494595, lng: 17.678743 },
    { lat: 48.494389, lng: 17.679038 },
    { lat: 48.494334, lng: 17.679117 },
    { lat: 48.494014, lng: 17.679439 },
    { lat: 48.494065, lng: 17.679687 },
    { lat: 48.494083, lng: 17.679759 },
    { lat: 48.494127, lng: 17.679837 },
    { lat: 48.494204, lng: 17.679934 },
    { lat: 48.494405, lng: 17.680141 },
    { lat: 48.494524, lng: 17.680279 },
    { lat: 48.494666, lng: 17.680442 },
    { lat: 48.495209, lng: 17.681032 },
    { lat: 48.4954994, lng: 17.6813807 },
    { lat: 48.496624, lng: 17.6831383 },
    { lat: 48.4972355, lng: 17.6844885 },
    { lat: 48.4974428, lng: 17.6853315 },
    { lat: 48.4979238, lng: 17.6881814 },
    { lat: 48.4980476, lng: 17.6887172 },
    { lat: 48.4982887, lng: 17.6893457 },
    { lat: 48.4984852, lng: 17.6898342 },
    { lat: 48.498996, lng: 17.6916387 },
    { lat: 48.4993991, lng: 17.6927051 },
    { lat: 48.4997638, lng: 17.6946321 },
    { lat: 48.5000988, lng: 17.6958982 },
    { lat: 48.4998606, lng: 17.6967132 },
    { lat: 48.4997387, lng: 17.6973328 },
    { lat: 48.4992293, lng: 17.700814 },
    { lat: 48.4989819, lng: 17.7019959 },
    { lat: 48.4984866, lng: 17.7043621 },
    { lat: 48.4977131, lng: 17.7073971 },
    { lat: 48.4972845, lng: 17.7097849 },
    { lat: 48.4971373, lng: 17.7103986 },
    { lat: 48.4965998, lng: 17.7121448 },
    { lat: 48.4959361, lng: 17.7139133 },
    { lat: 48.4956711, lng: 17.7145008 },
    { lat: 48.494413, lng: 17.7168059 },
    { lat: 48.4934816, lng: 17.7193013 },
    { lat: 48.4933258, lng: 17.7197919 },
    { lat: 48.4933059, lng: 17.7200019 },
    { lat: 48.4933502, lng: 17.720022 },
    { lat: 48.4930621, lng: 17.7208405 },
    { lat: 48.4929461, lng: 17.7209917 },
    { lat: 48.4925797, lng: 17.7221651 },
    { lat: 48.4923749, lng: 17.7229413 },
    { lat: 48.4923144, lng: 17.7233499 },
    { lat: 48.4921635, lng: 17.7233209 },
    { lat: 48.4920687, lng: 17.7235399 },
    { lat: 48.4890633, lng: 17.7380609 },
    { lat: 48.489045, lng: 17.7382005 },
    { lat: 48.489351, lng: 17.7381096 },
    { lat: 48.4895969, lng: 17.7379431 },
    { lat: 48.4896578, lng: 17.7378673 },
    { lat: 48.489284, lng: 17.7406809 },
    { lat: 48.4887831, lng: 17.7432797 },
    { lat: 48.488433, lng: 17.7447779 },
    { lat: 48.4882681, lng: 17.7452338 },
    { lat: 48.4880781, lng: 17.7464505 },
    { lat: 48.4873301, lng: 17.7492218 },
    { lat: 48.4870907, lng: 17.7507401 },
    { lat: 48.4865945, lng: 17.7506005 },
    { lat: 48.486163, lng: 17.751766 },
    { lat: 48.48616, lng: 17.751793 },
    { lat: 48.48616, lng: 17.751796 },
    { lat: 48.486406, lng: 17.752252 },
    { lat: 48.48658, lng: 17.752629 },
    { lat: 48.486758, lng: 17.752927 },
    { lat: 48.486858, lng: 17.753065 },
    { lat: 48.486978, lng: 17.753342 },
    { lat: 48.486999, lng: 17.753536 },
    { lat: 48.487006, lng: 17.753766 },
    { lat: 48.487006, lng: 17.753926 },
    { lat: 48.487116, lng: 17.754689 },
    { lat: 48.48719, lng: 17.75491 },
    { lat: 48.487435, lng: 17.755289 },
    { lat: 48.487581, lng: 17.755534 },
    { lat: 48.48774, lng: 17.755868 },
    { lat: 48.487636, lng: 17.756108 },
    { lat: 48.487439, lng: 17.756563 },
    { lat: 48.48727, lng: 17.757011 },
    { lat: 48.487251, lng: 17.757094 },
    { lat: 48.487283, lng: 17.757321 },
    { lat: 48.487166, lng: 17.757759 },
    { lat: 48.487146, lng: 17.757995 },
    { lat: 48.487007, lng: 17.758459 },
    { lat: 48.487003, lng: 17.758523 },
    { lat: 48.487075, lng: 17.758626 },
    { lat: 48.487184, lng: 17.758826 },
    { lat: 48.487176, lng: 17.758967 },
    { lat: 48.487083, lng: 17.759225 },
    { lat: 48.487052, lng: 17.759302 },
    { lat: 48.487018, lng: 17.7595 },
    { lat: 48.48702, lng: 17.759643 },
    { lat: 48.487056, lng: 17.759786 },
    { lat: 48.487263, lng: 17.760473 },
    { lat: 48.487288, lng: 17.760631 },
    { lat: 48.487293, lng: 17.760666 },
    { lat: 48.487304, lng: 17.760731 },
    { lat: 48.487314, lng: 17.760797 },
    { lat: 48.487338, lng: 17.760946 },
    { lat: 48.487313, lng: 17.761238 },
    { lat: 48.487313, lng: 17.76137 },
    { lat: 48.487338, lng: 17.761552 },
    { lat: 48.487407, lng: 17.761741 },
    { lat: 48.487447, lng: 17.761883 },
    { lat: 48.487519, lng: 17.762201 },
    { lat: 48.487573, lng: 17.762495 },
    { lat: 48.487636, lng: 17.763236 },
    { lat: 48.487668, lng: 17.763705 },
    { lat: 48.487646, lng: 17.763904 },
    { lat: 48.487618, lng: 17.76401 },
    { lat: 48.4876, lng: 17.76409 },
    { lat: 48.487567, lng: 17.764304 },
    { lat: 48.48773, lng: 17.765494 },
    { lat: 48.487754, lng: 17.765681 },
    { lat: 48.487762, lng: 17.765766 },
    { lat: 48.48777, lng: 17.765889 },
    { lat: 48.487779, lng: 17.76592 },
    { lat: 48.487822, lng: 17.765974 },
    { lat: 48.487846, lng: 17.765979 },
    { lat: 48.487921, lng: 17.766015 },
    { lat: 48.48799, lng: 17.766042 },
    { lat: 48.488044, lng: 17.766072 },
    { lat: 48.48811, lng: 17.766103 },
    { lat: 48.488171, lng: 17.766128 },
    { lat: 48.488297, lng: 17.766163 },
    { lat: 48.488382, lng: 17.766184 },
    { lat: 48.488458, lng: 17.766212 },
    { lat: 48.488537, lng: 17.766259 },
    { lat: 48.488602, lng: 17.766291 },
    { lat: 48.488657, lng: 17.766306 },
    { lat: 48.488713, lng: 17.766316 },
    { lat: 48.48881, lng: 17.766355 },
    { lat: 48.488904, lng: 17.766393 },
    { lat: 48.489024, lng: 17.766425 },
    { lat: 48.489083, lng: 17.766441 },
    { lat: 48.48918, lng: 17.766466 },
    { lat: 48.489274, lng: 17.766482 },
    { lat: 48.489317, lng: 17.766486 },
    { lat: 48.48946, lng: 17.766505 },
    { lat: 48.489553, lng: 17.766519 },
    { lat: 48.489615, lng: 17.766528 },
    { lat: 48.489642, lng: 17.766531 },
    { lat: 48.489766, lng: 17.766556 },
    { lat: 48.490025, lng: 17.766601 },
    { lat: 48.490075, lng: 17.76661 },
    { lat: 48.490138, lng: 17.76662 },
    { lat: 48.490202, lng: 17.766633 },
    { lat: 48.490295, lng: 17.766652 },
    { lat: 48.490391, lng: 17.766676 },
    { lat: 48.490484, lng: 17.766701 },
    { lat: 48.490571, lng: 17.766726 },
    { lat: 48.490669, lng: 17.766754 },
    { lat: 48.490714, lng: 17.766768 },
    { lat: 48.490767, lng: 17.766782 },
    { lat: 48.490861, lng: 17.766808 },
    { lat: 48.490946, lng: 17.766835 },
    { lat: 48.49113, lng: 17.766883 },
    { lat: 48.491177, lng: 17.7669 },
    { lat: 48.491224, lng: 17.766914 },
    { lat: 48.491318, lng: 17.766946 },
    { lat: 48.491377, lng: 17.766961 },
    { lat: 48.491442, lng: 17.76698 },
    { lat: 48.491504, lng: 17.766996 },
    { lat: 48.491598, lng: 17.767022 },
    { lat: 48.491688, lng: 17.767045 },
    { lat: 48.491785, lng: 17.767073 },
    { lat: 48.491873, lng: 17.767096 },
    { lat: 48.491927, lng: 17.767109 },
    { lat: 48.491982, lng: 17.767118 },
    { lat: 48.492051, lng: 17.767122 },
    { lat: 48.492241, lng: 17.767127 },
    { lat: 48.492341, lng: 17.767152 },
    { lat: 48.492397, lng: 17.767172 },
    { lat: 48.492454, lng: 17.76719 },
    { lat: 48.492507, lng: 17.76721 },
    { lat: 48.49255, lng: 17.767229 },
    { lat: 48.492805, lng: 17.767323 },
    { lat: 48.492943, lng: 17.767374 },
    { lat: 48.493074, lng: 17.76743 },
    { lat: 48.493124, lng: 17.767451 },
    { lat: 48.493164, lng: 17.767468 },
    { lat: 48.493253, lng: 17.767506 },
    { lat: 48.493344, lng: 17.767545 },
    { lat: 48.493449, lng: 17.767603 },
    { lat: 48.493572, lng: 17.76766 },
    { lat: 48.493711, lng: 17.767726 },
    { lat: 48.493826, lng: 17.767773 },
    { lat: 48.49386, lng: 17.767788 },
    { lat: 48.493894, lng: 17.767802 },
    { lat: 48.494077, lng: 17.767883 },
    { lat: 48.494258, lng: 17.767961 },
    { lat: 48.494352, lng: 17.768013 },
    { lat: 48.494436, lng: 17.768075 },
    { lat: 48.494604, lng: 17.768262 },
    { lat: 48.494712, lng: 17.768354 },
    { lat: 48.494789, lng: 17.768408 },
    { lat: 48.49482, lng: 17.768425 },
    { lat: 48.49493, lng: 17.768464 },
    { lat: 48.495038, lng: 17.768496 },
    { lat: 48.49516, lng: 17.768487 },
    { lat: 48.495262, lng: 17.768492 },
    { lat: 48.495359, lng: 17.768478 },
    { lat: 48.495386, lng: 17.768476 },
    { lat: 48.495434, lng: 17.768473 },
    { lat: 48.495486, lng: 17.768469 },
    { lat: 48.495537, lng: 17.768455 },
    { lat: 48.495636, lng: 17.768435 },
    { lat: 48.49571, lng: 17.768424 },
    { lat: 48.49578, lng: 17.768412 },
    { lat: 48.495927, lng: 17.768371 },
    { lat: 48.49612, lng: 17.768319 },
    { lat: 48.496148, lng: 17.768312 },
    { lat: 48.496217, lng: 17.768296 },
    { lat: 48.49626, lng: 17.768283 },
    { lat: 48.496318, lng: 17.768265 },
    { lat: 48.496418, lng: 17.768237 },
    { lat: 48.496469, lng: 17.76822 },
    { lat: 48.496709, lng: 17.768144 },
    { lat: 48.496798, lng: 17.768109 },
    { lat: 48.496893, lng: 17.76808 },
    { lat: 48.496998, lng: 17.768045 },
    { lat: 48.497195, lng: 17.767983 },
    { lat: 48.497301, lng: 17.767951 },
    { lat: 48.497402, lng: 17.767917 },
    { lat: 48.497498, lng: 17.767884 },
    { lat: 48.497589, lng: 17.767854 },
    { lat: 48.497685, lng: 17.767823 },
    { lat: 48.49779, lng: 17.767775 },
    { lat: 48.497893, lng: 17.767731 },
    { lat: 48.49794, lng: 17.76771 },
    { lat: 48.498014, lng: 17.767678 },
    { lat: 48.498028, lng: 17.767672 },
    { lat: 48.498078, lng: 17.767649 },
    { lat: 48.498286, lng: 17.767559 },
    { lat: 48.498385, lng: 17.767513 },
    { lat: 48.498437, lng: 17.767487 },
    { lat: 48.498482, lng: 17.76747 },
    { lat: 48.499012, lng: 17.767245 },
    { lat: 48.499292, lng: 17.767127 },
    { lat: 48.499523, lng: 17.767 },
    { lat: 48.499615, lng: 17.766971 },
    { lat: 48.499716, lng: 17.767131 },
    { lat: 48.499832, lng: 17.767352 },
    { lat: 48.500245, lng: 17.767876 },
    { lat: 48.500472, lng: 17.76806 },
    { lat: 48.50053, lng: 17.768091 },
    { lat: 48.500705, lng: 17.76819 },
    { lat: 48.500797, lng: 17.768242 },
    { lat: 48.500896, lng: 17.768288 },
    { lat: 48.501269, lng: 17.768443 },
    { lat: 48.501554, lng: 17.76867 },
    { lat: 48.501608, lng: 17.768702 },
    { lat: 48.501703, lng: 17.768758 },
    { lat: 48.501786, lng: 17.768805 },
    { lat: 48.501847, lng: 17.768836 },
    { lat: 48.501968, lng: 17.768933 },
    { lat: 48.502012, lng: 17.76897 },
    { lat: 48.502059, lng: 17.769004 },
    { lat: 48.50214, lng: 17.769066 },
    { lat: 48.502185, lng: 17.769103 },
    { lat: 48.502323, lng: 17.769213 },
    { lat: 48.502399, lng: 17.769277 },
    { lat: 48.502417, lng: 17.76929 },
    { lat: 48.502457, lng: 17.769325 },
    { lat: 48.502586, lng: 17.769435 },
    { lat: 48.502637, lng: 17.769478 },
    { lat: 48.502667, lng: 17.769504 },
    { lat: 48.502698, lng: 17.769533 },
    { lat: 48.502759, lng: 17.769582 },
    { lat: 48.50289, lng: 17.769702 },
    { lat: 48.503008, lng: 17.769825 },
    { lat: 48.503063, lng: 17.76988 },
    { lat: 48.503099, lng: 17.769916 },
    { lat: 48.503379, lng: 17.770195 },
    { lat: 48.50347, lng: 17.770288 },
    { lat: 48.503676, lng: 17.770503 },
    { lat: 48.503891, lng: 17.770732 },
    { lat: 48.503976, lng: 17.770816 },
    { lat: 48.504052, lng: 17.770895 },
    { lat: 48.504333, lng: 17.771194 },
    { lat: 48.504611, lng: 17.771485 },
    { lat: 48.504699, lng: 17.771583 },
    { lat: 48.504806, lng: 17.771695 },
    { lat: 48.504985, lng: 17.771894 },
    { lat: 48.504861, lng: 17.773242 },
    { lat: 48.505396, lng: 17.772565 },
    { lat: 48.505406, lng: 17.772582 },
    { lat: 48.505444, lng: 17.77265 },
    { lat: 48.505451, lng: 17.772664 },
    { lat: 48.505111, lng: 17.773118 },
    { lat: 48.505123, lng: 17.773132 },
    { lat: 48.505384, lng: 17.773418 },
    { lat: 48.506426, lng: 17.774571 },
    { lat: 48.50661, lng: 17.774337 },
    { lat: 48.506691, lng: 17.77423 },
    { lat: 48.506777, lng: 17.774254 },
    { lat: 48.506881, lng: 17.774315 },
    { lat: 48.506975, lng: 17.774364 },
    { lat: 48.507057, lng: 17.77443 },
    { lat: 48.507146, lng: 17.774505 },
    { lat: 48.507192, lng: 17.77454 },
    { lat: 48.507238, lng: 17.774578 },
    { lat: 48.507287, lng: 17.774618 },
    { lat: 48.507337, lng: 17.774673 },
    { lat: 48.507409, lng: 17.774754 },
    { lat: 48.507484, lng: 17.774842 },
    { lat: 48.507557, lng: 17.77493 },
    { lat: 48.507644, lng: 17.775024 },
    { lat: 48.507723, lng: 17.775109 },
    { lat: 48.507786, lng: 17.775175 },
    { lat: 48.507828, lng: 17.775221 },
    { lat: 48.507885, lng: 17.775284 },
    { lat: 48.507948, lng: 17.77535 },
    { lat: 48.507999, lng: 17.775407 },
    { lat: 48.508025, lng: 17.775436 },
    { lat: 48.508051, lng: 17.775465 },
    { lat: 48.508125, lng: 17.775546 },
    { lat: 48.508194, lng: 17.775622 },
    { lat: 48.508242, lng: 17.775673 },
    { lat: 48.508298, lng: 17.775733 },
    { lat: 48.508342, lng: 17.775781 },
    { lat: 48.508387, lng: 17.775833 },
    { lat: 48.50843, lng: 17.775875 },
    { lat: 48.508536, lng: 17.775991 },
    { lat: 48.508593, lng: 17.776053 },
    { lat: 48.508648, lng: 17.776114 },
    { lat: 48.508706, lng: 17.776176 },
    { lat: 48.508742, lng: 17.776217 },
    { lat: 48.508779, lng: 17.776257 },
    { lat: 48.508811, lng: 17.776293 },
    { lat: 48.508845, lng: 17.77633 },
    { lat: 48.508975, lng: 17.776472 },
    { lat: 48.509023, lng: 17.776524 },
    { lat: 48.509176, lng: 17.776687 },
    { lat: 48.509212, lng: 17.776726 },
    { lat: 48.509254, lng: 17.776772 },
    { lat: 48.509291, lng: 17.776809 },
    { lat: 48.509327, lng: 17.77685 },
    { lat: 48.509401, lng: 17.776937 },
    { lat: 48.509585, lng: 17.77713 },
    { lat: 48.509437, lng: 17.777427 },
    { lat: 48.509777, lng: 17.777797 },
    { lat: 48.509927, lng: 17.777958 },
    { lat: 48.509978, lng: 17.778013 },
    { lat: 48.510031, lng: 17.778068 },
    { lat: 48.510079, lng: 17.778121 },
    { lat: 48.510154, lng: 17.778201 },
    { lat: 48.510323, lng: 17.777864 },
    { lat: 48.510335, lng: 17.777879 },
    { lat: 48.510432, lng: 17.777981 },
    { lat: 48.510559, lng: 17.77812 },
    { lat: 48.510618, lng: 17.778188 },
    { lat: 48.51068, lng: 17.778249 },
    { lat: 48.510754, lng: 17.778334 },
    { lat: 48.510856, lng: 17.778444 },
    { lat: 48.510995, lng: 17.778599 },
    { lat: 48.511079, lng: 17.77869 },
    { lat: 48.511164, lng: 17.778784 },
    { lat: 48.51121, lng: 17.778832 },
    { lat: 48.511254, lng: 17.77888 },
    { lat: 48.511343, lng: 17.778977 },
    { lat: 48.511405, lng: 17.779043 },
    { lat: 48.511463, lng: 17.779108 },
    { lat: 48.51148, lng: 17.779128 },
    { lat: 48.511615, lng: 17.779276 },
    { lat: 48.511701, lng: 17.779376 },
    { lat: 48.51175, lng: 17.779431 },
    { lat: 48.511808, lng: 17.779495 },
    { lat: 48.511896, lng: 17.779584 },
    { lat: 48.511935, lng: 17.779628 },
    { lat: 48.512025, lng: 17.779713 },
    { lat: 48.512067, lng: 17.779629 },
    { lat: 48.512176, lng: 17.779392 },
    { lat: 48.512272, lng: 17.779191 },
    { lat: 48.512321, lng: 17.779088 },
    { lat: 48.512184, lng: 17.77889 },
    { lat: 48.51192, lng: 17.778247 },
    { lat: 48.511553, lng: 17.777894 },
    { lat: 48.511314, lng: 17.777377 },
    { lat: 48.510768, lng: 17.776772 },
    { lat: 48.510729, lng: 17.77672 },
    { lat: 48.5101, lng: 17.776032 },
    { lat: 48.510207, lng: 17.775795 },
    { lat: 48.509697, lng: 17.775252 },
    { lat: 48.509595, lng: 17.775477 },
    { lat: 48.509368, lng: 17.775234 },
    { lat: 48.509148, lng: 17.775168 },
    { lat: 48.508284, lng: 17.774206 },
    { lat: 48.509226, lng: 17.771506 },
    { lat: 48.50952, lng: 17.770665 },
    { lat: 48.509255, lng: 17.770444 },
    { lat: 48.509024, lng: 17.770252 },
    { lat: 48.509011, lng: 17.770241 },
    { lat: 48.508942, lng: 17.770185 },
    { lat: 48.507761, lng: 17.769198 },
    { lat: 48.507936, lng: 17.769084 },
    { lat: 48.507988, lng: 17.769047 },
    { lat: 48.508, lng: 17.769039 },
    { lat: 48.508149, lng: 17.769001 },
    { lat: 48.508221, lng: 17.768982 },
    { lat: 48.508316, lng: 17.768958 },
    { lat: 48.508377, lng: 17.768918 },
    { lat: 48.508434, lng: 17.768879 },
    { lat: 48.508662, lng: 17.768649 },
    { lat: 48.508805, lng: 17.768453 },
    { lat: 48.508931, lng: 17.768266 },
    { lat: 48.509002, lng: 17.768093 },
    { lat: 48.509072, lng: 17.767854 },
    { lat: 48.509181, lng: 17.767572 },
    { lat: 48.509291, lng: 17.766697 },
    { lat: 48.509363, lng: 17.766517 },
    { lat: 48.50937, lng: 17.766502 },
    { lat: 48.509437, lng: 17.7663789 },
    { lat: 48.5095901, lng: 17.7662806 },
    { lat: 48.5096027, lng: 17.7658088 },
    { lat: 48.5094932, lng: 17.7653484 },
    { lat: 48.5086721, lng: 17.7649232 },
    { lat: 48.5083893, lng: 17.7644375 },
    { lat: 48.5083109, lng: 17.7640977 },
    { lat: 48.5083744, lng: 17.7621787 },
    { lat: 48.5085044, lng: 17.7609326 },
    { lat: 48.5086156, lng: 17.7607604 },
    { lat: 48.5091064, lng: 17.7603707 },
  ],
  Drahovce: [
    { lat: 48.496329, lng: 17.802823 },
    { lat: 48.497254, lng: 17.802787 },
    { lat: 48.497527, lng: 17.803281 },
    { lat: 48.497717, lng: 17.803887 },
    { lat: 48.497944, lng: 17.804859 },
    { lat: 48.498117, lng: 17.80562 },
    { lat: 48.498196, lng: 17.806209 },
    { lat: 48.49831, lng: 17.806948 },
    { lat: 48.498464, lng: 17.807983 },
    { lat: 48.498549, lng: 17.808799 },
    { lat: 48.498623, lng: 17.809542 },
    { lat: 48.498772, lng: 17.810616 },
    { lat: 48.499124, lng: 17.810939 },
    { lat: 48.499721, lng: 17.811469 },
    { lat: 48.500232, lng: 17.811924 },
    { lat: 48.500698, lng: 17.812343 },
    { lat: 48.501132, lng: 17.812709 },
    { lat: 48.50123, lng: 17.81314 },
    { lat: 48.501251, lng: 17.813229 },
    { lat: 48.501395, lng: 17.813812 },
    { lat: 48.501564, lng: 17.814503 },
    { lat: 48.501715, lng: 17.815129 },
    { lat: 48.501772, lng: 17.815352 },
    { lat: 48.501833, lng: 17.815592 },
    { lat: 48.501915, lng: 17.815902 },
    { lat: 48.502019, lng: 17.816323 },
    { lat: 48.502071, lng: 17.816527 },
    { lat: 48.502154, lng: 17.816849 },
    { lat: 48.50237, lng: 17.817685 },
    { lat: 48.502565, lng: 17.818445 },
    { lat: 48.502747, lng: 17.819142 },
    { lat: 48.502798, lng: 17.819345 },
    { lat: 48.503114, lng: 17.820219 },
    { lat: 48.503348, lng: 17.820858 },
    { lat: 48.503461, lng: 17.821168 },
    { lat: 48.503627, lng: 17.82168 },
    { lat: 48.503661, lng: 17.821782 },
    { lat: 48.503676, lng: 17.82183 },
    { lat: 48.503692, lng: 17.821827 },
    { lat: 48.503757, lng: 17.821792 },
    { lat: 48.50452, lng: 17.82147 },
    { lat: 48.504923, lng: 17.821305 },
    { lat: 48.505311, lng: 17.821142 },
    { lat: 48.505539, lng: 17.821048 },
    { lat: 48.505709, lng: 17.820977 },
    { lat: 48.50572, lng: 17.820973 },
    { lat: 48.505888, lng: 17.820912 },
    { lat: 48.506389, lng: 17.820708 },
    { lat: 48.506823, lng: 17.820536 },
    { lat: 48.507342, lng: 17.820318 },
    { lat: 48.507811, lng: 17.820085 },
    { lat: 48.507839, lng: 17.82007 },
    { lat: 48.508321, lng: 17.819821 },
    { lat: 48.508598, lng: 17.819673 },
    { lat: 48.508632, lng: 17.819663 },
    { lat: 48.508838, lng: 17.819604 },
    { lat: 48.508908, lng: 17.819584 },
    { lat: 48.508975, lng: 17.819565 },
    { lat: 48.509646, lng: 17.819341 },
    { lat: 48.509694, lng: 17.819326 },
    { lat: 48.510043, lng: 17.819213 },
    { lat: 48.510724, lng: 17.81898 },
    { lat: 48.51081, lng: 17.81895 },
    { lat: 48.511296, lng: 17.818777 },
    { lat: 48.511573, lng: 17.818687 },
    { lat: 48.511656, lng: 17.81866 },
    { lat: 48.512151, lng: 17.818487 },
    { lat: 48.512222, lng: 17.818463 },
    { lat: 48.512859, lng: 17.818341 },
    { lat: 48.513239, lng: 17.818258 },
    { lat: 48.513531, lng: 17.818195 },
    { lat: 48.513601, lng: 17.818178 },
    { lat: 48.513927, lng: 17.818101 },
    { lat: 48.514025, lng: 17.818078 },
    { lat: 48.514088, lng: 17.818066 },
    { lat: 48.514665, lng: 17.817954 },
    { lat: 48.515014, lng: 17.817882 },
    { lat: 48.515263, lng: 17.81783 },
    { lat: 48.515325, lng: 17.81781 },
    { lat: 48.515749, lng: 17.817663 },
    { lat: 48.515882, lng: 17.817822 },
    { lat: 48.516047, lng: 17.818036 },
    { lat: 48.516448, lng: 17.818544 },
    { lat: 48.516711, lng: 17.818877 },
    { lat: 48.516938, lng: 17.819169 },
    { lat: 48.517239, lng: 17.819552 },
    { lat: 48.517292, lng: 17.819614 },
    { lat: 48.517492, lng: 17.819856 },
    { lat: 48.517866, lng: 17.820315 },
    { lat: 48.517967, lng: 17.820438 },
    { lat: 48.518155, lng: 17.820667 },
    { lat: 48.518261, lng: 17.820796 },
    { lat: 48.51841, lng: 17.820979 },
    { lat: 48.518725, lng: 17.821364 },
    { lat: 48.519087, lng: 17.821799 },
    { lat: 48.519568, lng: 17.822369 },
    { lat: 48.519775, lng: 17.822613 },
    { lat: 48.5213418, lng: 17.8206165 },
    { lat: 48.5241506, lng: 17.8204898 },
    { lat: 48.522272, lng: 17.8257596 },
    { lat: 48.5239818, lng: 17.825824 },
    { lat: 48.5249241, lng: 17.8236004 },
    { lat: 48.5262498, lng: 17.8209414 },
    { lat: 48.5273171, lng: 17.8202673 },
    { lat: 48.5277827, lng: 17.8220299 },
    { lat: 48.5284261, lng: 17.8235246 },
    { lat: 48.5284598, lng: 17.8239491 },
    { lat: 48.5283385, lng: 17.8243495 },
    { lat: 48.5286008, lng: 17.8246316 },
    { lat: 48.5286461, lng: 17.8248939 },
    { lat: 48.5289356, lng: 17.8254205 },
    { lat: 48.5292, lng: 17.8257215 },
    { lat: 48.52945, lng: 17.8262087 },
    { lat: 48.5297653, lng: 17.826584 },
    { lat: 48.5307479, lng: 17.8274628 },
    { lat: 48.5312418, lng: 17.8277101 },
    { lat: 48.5321349, lng: 17.82775 },
    { lat: 48.5324967, lng: 17.827555 },
    { lat: 48.5372346, lng: 17.8234447 },
    { lat: 48.5381759, lng: 17.8238338 },
    { lat: 48.5394192, lng: 17.822821 },
    { lat: 48.5403177, lng: 17.8218752 },
    { lat: 48.5409631, lng: 17.8212596 },
    { lat: 48.5434164, lng: 17.8193566 },
    { lat: 48.5464517, lng: 17.8217195 },
    { lat: 48.5471454, lng: 17.822263 },
    { lat: 48.5485611, lng: 17.8144073 },
    { lat: 48.5490461, lng: 17.8140144 },
    { lat: 48.5487263, lng: 17.8136546 },
    { lat: 48.5482516, lng: 17.8120754 },
    { lat: 48.5488021, lng: 17.8114222 },
    { lat: 48.5502139, lng: 17.8083688 },
    { lat: 48.5509641, lng: 17.8053518 },
    { lat: 48.5511557, lng: 17.8042583 },
    { lat: 48.5508981, lng: 17.8024444 },
    { lat: 48.5509303, lng: 17.8011472 },
    { lat: 48.5510181, lng: 17.8001074 },
    { lat: 48.5511773, lng: 17.7990416 },
    { lat: 48.5503813, lng: 17.7971379 },
    { lat: 48.5500718, lng: 17.7957528 },
    { lat: 48.5503004, lng: 17.7941207 },
    { lat: 48.550265, lng: 17.7927178 },
    { lat: 48.5501668, lng: 17.7920149 },
    { lat: 48.5501869, lng: 17.7914427 },
    { lat: 48.5501451, lng: 17.7912005 },
    { lat: 48.5500437, lng: 17.7907828 },
    { lat: 48.5498916, lng: 17.7904006 },
    { lat: 48.5496366, lng: 17.7891127 },
    { lat: 48.5496416, lng: 17.7883298 },
    { lat: 48.5496942, lng: 17.7882299 },
    { lat: 48.5497785, lng: 17.7875789 },
    { lat: 48.5497563, lng: 17.7871979 },
    { lat: 48.5498217, lng: 17.7864783 },
    { lat: 48.5498035, lng: 17.7860546 },
    { lat: 48.5497607, lng: 17.7858158 },
    { lat: 48.5495377, lng: 17.7855123 },
    { lat: 48.5490543, lng: 17.785214 },
    { lat: 48.5487712, lng: 17.7844093 },
    { lat: 48.5487686, lng: 17.7836307 },
    { lat: 48.548839, lng: 17.7829204 },
    { lat: 48.5489324, lng: 17.7825493 },
    { lat: 48.5494892, lng: 17.7827522 },
    { lat: 48.5495841, lng: 17.7815969 },
    { lat: 48.5495087, lng: 17.7813437 },
    { lat: 48.5545899, lng: 17.779259 },
    { lat: 48.5545273, lng: 17.7782039 },
    { lat: 48.5545799, lng: 17.7769657 },
    { lat: 48.5550973, lng: 17.7748737 },
    { lat: 48.5551471, lng: 17.7728009 },
    { lat: 48.5549818, lng: 17.7708076 },
    { lat: 48.5548707, lng: 17.7686692 },
    { lat: 48.5551057, lng: 17.7682516 },
    { lat: 48.5551168, lng: 17.7650555 },
    { lat: 48.5550893, lng: 17.7638355 },
    { lat: 48.5550297, lng: 17.7636019 },
    { lat: 48.554512, lng: 17.7631311 },
    { lat: 48.5541414, lng: 17.7630182 },
    { lat: 48.5533326, lng: 17.7629922 },
    { lat: 48.552854, lng: 17.7628841 },
    { lat: 48.5525192, lng: 17.762872 },
    { lat: 48.5511532, lng: 17.7631494 },
    { lat: 48.550892, lng: 17.7632714 },
    { lat: 48.5504525, lng: 17.7632305 },
    { lat: 48.5501987, lng: 17.7631434 },
    { lat: 48.5500808, lng: 17.762749 },
    { lat: 48.5491853, lng: 17.7617373 },
    { lat: 48.5488547, lng: 17.7615369 },
    { lat: 48.5488489, lng: 17.7614804 },
    { lat: 48.5448043, lng: 17.7618226 },
    { lat: 48.5448051, lng: 17.7615588 },
    { lat: 48.5427133, lng: 17.7594001 },
    { lat: 48.5423074, lng: 17.7590602 },
    { lat: 48.5422811, lng: 17.7589246 },
    { lat: 48.5405414, lng: 17.7573328 },
    { lat: 48.5378079, lng: 17.7550015 },
    { lat: 48.5356529, lng: 17.7530641 },
    { lat: 48.5353211, lng: 17.752812 },
    { lat: 48.5329024, lng: 17.7514335 },
    { lat: 48.5327296, lng: 17.7513401 },
    { lat: 48.5241314, lng: 17.7464569 },
    { lat: 48.5235159, lng: 17.7461106 },
    { lat: 48.5237422, lng: 17.7476906 },
    { lat: 48.5251271, lng: 17.7495306 },
    { lat: 48.5251561, lng: 17.7496522 },
    { lat: 48.5246557, lng: 17.7518984 },
    { lat: 48.5243671, lng: 17.7534261 },
    { lat: 48.5242325, lng: 17.7538651 },
    { lat: 48.5237336, lng: 17.7562641 },
    { lat: 48.523609, lng: 17.7566601 },
    { lat: 48.5231653, lng: 17.7566245 },
    { lat: 48.5228025, lng: 17.7561962 },
    { lat: 48.5225523, lng: 17.7562593 },
    { lat: 48.5223925, lng: 17.7562114 },
    { lat: 48.5219026, lng: 17.7557909 },
    { lat: 48.5217689, lng: 17.7557098 },
    { lat: 48.5216174, lng: 17.7558193 },
    { lat: 48.5214357, lng: 17.7556008 },
    { lat: 48.5213228, lng: 17.7555799 },
    { lat: 48.5211882, lng: 17.7557226 },
    { lat: 48.5207484, lng: 17.7558229 },
    { lat: 48.5206243, lng: 17.7559698 },
    { lat: 48.5205526, lng: 17.7562726 },
    { lat: 48.5204504, lng: 17.7564258 },
    { lat: 48.520074, lng: 17.7561461 },
    { lat: 48.5198279, lng: 17.7557994 },
    { lat: 48.5194355, lng: 17.7559696 },
    { lat: 48.5192975, lng: 17.7558201 },
    { lat: 48.5191526, lng: 17.7558336 },
    { lat: 48.5185897, lng: 17.7560429 },
    { lat: 48.5177254, lng: 17.7553357 },
    { lat: 48.5170935, lng: 17.7550675 },
    { lat: 48.5153365, lng: 17.754881 },
    { lat: 48.5149317, lng: 17.7553642 },
    { lat: 48.5147443, lng: 17.7554024 },
    { lat: 48.5145361, lng: 17.7553564 },
    { lat: 48.5140727, lng: 17.7556597 },
    { lat: 48.5134749, lng: 17.7567502 },
    { lat: 48.5129609, lng: 17.7571471 },
    { lat: 48.5121057, lng: 17.7570643 },
    { lat: 48.5112452, lng: 17.7570972 },
    { lat: 48.5106988, lng: 17.7573566 },
    { lat: 48.5105673, lng: 17.7576032 },
    { lat: 48.5105527, lng: 17.7582294 },
    { lat: 48.5104113, lng: 17.7586062 },
    { lat: 48.5100797, lng: 17.7592532 },
    { lat: 48.5095833, lng: 17.7599385 },
    { lat: 48.5091064, lng: 17.7603707 },
    { lat: 48.5086156, lng: 17.7607604 },
    { lat: 48.5085044, lng: 17.7609326 },
    { lat: 48.5083744, lng: 17.7621787 },
    { lat: 48.5083109, lng: 17.7640977 },
    { lat: 48.5083893, lng: 17.7644375 },
    { lat: 48.5086721, lng: 17.7649232 },
    { lat: 48.5094932, lng: 17.7653484 },
    { lat: 48.5096027, lng: 17.7658088 },
    { lat: 48.5095901, lng: 17.7662806 },
    { lat: 48.509437, lng: 17.7663789 },
    { lat: 48.50937, lng: 17.766502 },
    { lat: 48.512688, lng: 17.767745 },
    { lat: 48.514503, lng: 17.768394 },
    { lat: 48.515269, lng: 17.768662 },
    { lat: 48.516755, lng: 17.76918 },
    { lat: 48.516886, lng: 17.769228 },
    { lat: 48.518748, lng: 17.76991 },
    { lat: 48.519667, lng: 17.770245 },
    { lat: 48.519676, lng: 17.770248 },
    { lat: 48.518003, lng: 17.774076 },
    { lat: 48.51799, lng: 17.774062 },
    { lat: 48.517904, lng: 17.774205 },
    { lat: 48.51762, lng: 17.774832 },
    { lat: 48.517614, lng: 17.774848 },
    { lat: 48.517585, lng: 17.774911 },
    { lat: 48.517529, lng: 17.775034 },
    { lat: 48.517506, lng: 17.775083 },
    { lat: 48.517446, lng: 17.775212 },
    { lat: 48.517414, lng: 17.775284 },
    { lat: 48.517408, lng: 17.775298 },
    { lat: 48.517372, lng: 17.775375 },
    { lat: 48.517364, lng: 17.775393 },
    { lat: 48.517052, lng: 17.776082 },
    { lat: 48.516826, lng: 17.776654 },
    { lat: 48.516758, lng: 17.776766 },
    { lat: 48.516727, lng: 17.776828 },
    { lat: 48.516482, lng: 17.777371 },
    { lat: 48.516394, lng: 17.777679 },
    { lat: 48.516337, lng: 17.777807 },
    { lat: 48.516197, lng: 17.778103 },
    { lat: 48.516035, lng: 17.778413 },
    { lat: 48.515864, lng: 17.778704 },
    { lat: 48.515708, lng: 17.779001 },
    { lat: 48.515374, lng: 17.779753 },
    { lat: 48.514911, lng: 17.78086 },
    { lat: 48.51446, lng: 17.781696 },
    { lat: 48.514404, lng: 17.781797 },
    { lat: 48.514394, lng: 17.781816 },
    { lat: 48.514289, lng: 17.782018 },
    { lat: 48.514286, lng: 17.782022 },
    { lat: 48.514267, lng: 17.78206 },
    { lat: 48.5142, lng: 17.782188 },
    { lat: 48.514197, lng: 17.782194 },
    { lat: 48.513819, lng: 17.783038 },
    { lat: 48.51373, lng: 17.783297 },
    { lat: 48.513672, lng: 17.783653 },
    { lat: 48.513612, lng: 17.784341 },
    { lat: 48.513565, lng: 17.784657 },
    { lat: 48.513439, lng: 17.785225 },
    { lat: 48.513293, lng: 17.785825 },
    { lat: 48.513194, lng: 17.786121 },
    { lat: 48.513035, lng: 17.786434 },
    { lat: 48.512574, lng: 17.787273 },
    { lat: 48.512297, lng: 17.787767 },
    { lat: 48.512079, lng: 17.788039 },
    { lat: 48.511921, lng: 17.788245 },
    { lat: 48.511549, lng: 17.788849 },
    { lat: 48.511362, lng: 17.78917 },
    { lat: 48.511233, lng: 17.789544 },
    { lat: 48.511104, lng: 17.790159 },
    { lat: 48.510906, lng: 17.790852 },
    { lat: 48.510889, lng: 17.791022 },
    { lat: 48.51089, lng: 17.791298 },
    { lat: 48.510908, lng: 17.791463 },
    { lat: 48.510869, lng: 17.791429 },
    { lat: 48.51084, lng: 17.791408 },
    { lat: 48.510734, lng: 17.791363 },
    { lat: 48.510596, lng: 17.791367 },
    { lat: 48.510522, lng: 17.791347 },
    { lat: 48.510444, lng: 17.791309 },
    { lat: 48.510322, lng: 17.791253 },
    { lat: 48.510093, lng: 17.791254 },
    { lat: 48.510076, lng: 17.791216 },
    { lat: 48.510021, lng: 17.791088 },
    { lat: 48.510009, lng: 17.791061 },
    { lat: 48.509964, lng: 17.791035 },
    { lat: 48.509865, lng: 17.79104 },
    { lat: 48.509838, lng: 17.791051 },
    { lat: 48.509695, lng: 17.791111 },
    { lat: 48.509627, lng: 17.791117 },
    { lat: 48.509548, lng: 17.791039 },
    { lat: 48.50953, lng: 17.790987 },
    { lat: 48.509516, lng: 17.790947 },
    { lat: 48.509483, lng: 17.790647 },
    { lat: 48.509436, lng: 17.790509 },
    { lat: 48.509378, lng: 17.790475 },
    { lat: 48.509271, lng: 17.790449 },
    { lat: 48.509249, lng: 17.790458 },
    { lat: 48.509154, lng: 17.790513 },
    { lat: 48.509057, lng: 17.790701 },
    { lat: 48.508999, lng: 17.790754 },
    { lat: 48.508894, lng: 17.790866 },
    { lat: 48.508813, lng: 17.790952 },
    { lat: 48.508723, lng: 17.791048 },
    { lat: 48.508602, lng: 17.791115 },
    { lat: 48.508451, lng: 17.791248 },
    { lat: 48.508411, lng: 17.791323 },
    { lat: 48.508336, lng: 17.7914 },
    { lat: 48.508245, lng: 17.791421 },
    { lat: 48.508202, lng: 17.791424 },
    { lat: 48.508099, lng: 17.79124 },
    { lat: 48.508022, lng: 17.791105 },
    { lat: 48.507948, lng: 17.790972 },
    { lat: 48.507928, lng: 17.790936 },
    { lat: 48.507855, lng: 17.790821 },
    { lat: 48.507831, lng: 17.790799 },
    { lat: 48.507807, lng: 17.790777 },
    { lat: 48.50774, lng: 17.790715 },
    { lat: 48.507607, lng: 17.790591 },
    { lat: 48.507465, lng: 17.790458 },
    { lat: 48.507386, lng: 17.790424 },
    { lat: 48.507257, lng: 17.790446 },
    { lat: 48.507145, lng: 17.79048 },
    { lat: 48.507095, lng: 17.790502 },
    { lat: 48.507074, lng: 17.790512 },
    { lat: 48.507055, lng: 17.79052 },
    { lat: 48.507003, lng: 17.790579 },
    { lat: 48.506984, lng: 17.790602 },
    { lat: 48.506971, lng: 17.790618 },
    { lat: 48.506927, lng: 17.790721 },
    { lat: 48.506893, lng: 17.790783 },
    { lat: 48.506824, lng: 17.790874 },
    { lat: 48.506762, lng: 17.790903 },
    { lat: 48.506695, lng: 17.790933 },
    { lat: 48.506633, lng: 17.790956 },
    { lat: 48.506575, lng: 17.790974 },
    { lat: 48.506511, lng: 17.791 },
    { lat: 48.506189, lng: 17.791174 },
    { lat: 48.50602, lng: 17.791256 },
    { lat: 48.505818, lng: 17.791409 },
    { lat: 48.505727, lng: 17.791516 },
    { lat: 48.505641, lng: 17.791712 },
    { lat: 48.5056, lng: 17.791789 },
    { lat: 48.505442, lng: 17.792077 },
    { lat: 48.505347, lng: 17.792229 },
    { lat: 48.505261, lng: 17.79236 },
    { lat: 48.505228, lng: 17.792419 },
    { lat: 48.505101, lng: 17.792424 },
    { lat: 48.505007, lng: 17.792242 },
    { lat: 48.50491, lng: 17.79207 },
    { lat: 48.504811, lng: 17.791923 },
    { lat: 48.504722, lng: 17.791905 },
    { lat: 48.504649, lng: 17.791893 },
    { lat: 48.504566, lng: 17.791877 },
    { lat: 48.5045, lng: 17.791856 },
    { lat: 48.504427, lng: 17.79182 },
    { lat: 48.504316, lng: 17.791792 },
    { lat: 48.504192, lng: 17.791758 },
    { lat: 48.50416, lng: 17.791747 },
    { lat: 48.504113, lng: 17.791674 },
    { lat: 48.504002, lng: 17.791587 },
    { lat: 48.503845, lng: 17.791504 },
    { lat: 48.503638, lng: 17.791501 },
    { lat: 48.503514, lng: 17.791486 },
    { lat: 48.503408, lng: 17.791573 },
    { lat: 48.503366, lng: 17.791655 },
    { lat: 48.503337, lng: 17.791686 },
    { lat: 48.503198, lng: 17.791739 },
    { lat: 48.503127, lng: 17.791712 },
    { lat: 48.503064, lng: 17.791687 },
    { lat: 48.502936, lng: 17.791654 },
    { lat: 48.50286, lng: 17.791676 },
    { lat: 48.502806, lng: 17.791707 },
    { lat: 48.502739, lng: 17.791745 },
    { lat: 48.502532, lng: 17.791844 },
    { lat: 48.502448, lng: 17.791878 },
    { lat: 48.502359, lng: 17.791909 },
    { lat: 48.502209, lng: 17.791966 },
    { lat: 48.50212, lng: 17.792029 },
    { lat: 48.501989, lng: 17.79213 },
    { lat: 48.501893, lng: 17.792143 },
    { lat: 48.501761, lng: 17.792218 },
    { lat: 48.501692, lng: 17.792261 },
    { lat: 48.501624, lng: 17.792316 },
    { lat: 48.501497, lng: 17.792472 },
    { lat: 48.501388, lng: 17.792627 },
    { lat: 48.501297, lng: 17.792722 },
    { lat: 48.50127, lng: 17.792756 },
    { lat: 48.50126, lng: 17.792767 },
    { lat: 48.501195, lng: 17.792847 },
    { lat: 48.501128, lng: 17.792969 },
    { lat: 48.501094, lng: 17.793033 },
    { lat: 48.500981, lng: 17.793213 },
    { lat: 48.50088, lng: 17.793363 },
    { lat: 48.500783, lng: 17.793502 },
    { lat: 48.500698, lng: 17.793699 },
    { lat: 48.500646, lng: 17.793801 },
    { lat: 48.500578, lng: 17.794032 },
    { lat: 48.500502, lng: 17.794333 },
    { lat: 48.5004, lng: 17.794573 },
    { lat: 48.500364, lng: 17.794779 },
    { lat: 48.50034, lng: 17.794939 },
    { lat: 48.500301, lng: 17.795094 },
    { lat: 48.500266, lng: 17.795184 },
    { lat: 48.500233, lng: 17.79527 },
    { lat: 48.500207, lng: 17.795332 },
    { lat: 48.500162, lng: 17.795441 },
    { lat: 48.500142, lng: 17.795497 },
    { lat: 48.500085, lng: 17.795633 },
    { lat: 48.500071, lng: 17.79568 },
    { lat: 48.500067, lng: 17.795694 },
    { lat: 48.500038, lng: 17.795739 },
    { lat: 48.50003, lng: 17.79575 },
    { lat: 48.500016, lng: 17.795769 },
    { lat: 48.500005, lng: 17.795784 },
    { lat: 48.499999, lng: 17.795791 },
    { lat: 48.499991, lng: 17.795804 },
    { lat: 48.499864, lng: 17.795997 },
    { lat: 48.499805, lng: 17.796098 },
    { lat: 48.499766, lng: 17.796188 },
    { lat: 48.499678, lng: 17.796407 },
    { lat: 48.499632, lng: 17.796485 },
    { lat: 48.499482, lng: 17.796778 },
    { lat: 48.49938, lng: 17.79697 },
    { lat: 48.499343, lng: 17.797035 },
    { lat: 48.499302, lng: 17.7971 },
    { lat: 48.499258, lng: 17.797159 },
    { lat: 48.49897, lng: 17.797594 },
    { lat: 48.498791, lng: 17.797792 },
    { lat: 48.498746, lng: 17.797834 },
    { lat: 48.498658, lng: 17.797918 },
    { lat: 48.498551, lng: 17.798014 },
    { lat: 48.498446, lng: 17.79813 },
    { lat: 48.498333, lng: 17.798311 },
    { lat: 48.498244, lng: 17.798409 },
    { lat: 48.498152, lng: 17.798466 },
    { lat: 48.498048, lng: 17.798504 },
    { lat: 48.497933, lng: 17.798648 },
    { lat: 48.497887, lng: 17.798715 },
    { lat: 48.49771, lng: 17.798716 },
    { lat: 48.497639, lng: 17.798737 },
    { lat: 48.49757, lng: 17.7988 },
    { lat: 48.49743, lng: 17.799064 },
    { lat: 48.497382, lng: 17.799129 },
    { lat: 48.497293, lng: 17.799244 },
    { lat: 48.497265, lng: 17.799289 },
    { lat: 48.497111, lng: 17.799464 },
    { lat: 48.497028, lng: 17.799597 },
    { lat: 48.496959, lng: 17.799696 },
    { lat: 48.49692, lng: 17.799789 },
    { lat: 48.496881, lng: 17.800054 },
    { lat: 48.496818, lng: 17.800358 },
    { lat: 48.496718, lng: 17.800858 },
    { lat: 48.496329, lng: 17.802823 },
  ],
  VeľkéOrvište: [
    { lat: 48.6334446, lng: 17.7872822 },
    { lat: 48.6332415, lng: 17.7873765 },
    { lat: 48.6328386, lng: 17.787508 },
    { lat: 48.6322954, lng: 17.7874838 },
    { lat: 48.6312964, lng: 17.7869895 },
    { lat: 48.6310143, lng: 17.786567 },
    { lat: 48.6300186, lng: 17.7861819 },
    { lat: 48.6293378, lng: 17.7857739 },
    { lat: 48.6288406, lng: 17.785693 },
    { lat: 48.6288478, lng: 17.7856456 },
    { lat: 48.62845, lng: 17.7853264 },
    { lat: 48.6284254, lng: 17.7849244 },
    { lat: 48.6283683, lng: 17.7847114 },
    { lat: 48.6281253, lng: 17.7846332 },
    { lat: 48.6279061, lng: 17.7844811 },
    { lat: 48.6262173, lng: 17.7825125 },
    { lat: 48.625362, lng: 17.7819873 },
    { lat: 48.6248436, lng: 17.7815226 },
    { lat: 48.6242864, lng: 17.7811249 },
    { lat: 48.6225363, lng: 17.7804234 },
    { lat: 48.6219634, lng: 17.7800662 },
    { lat: 48.6214125, lng: 17.7798822 },
    { lat: 48.6199528, lng: 17.7797341 },
    { lat: 48.619121, lng: 17.779499 },
    { lat: 48.6186568, lng: 17.779294 },
    { lat: 48.6174015, lng: 17.7784879 },
    { lat: 48.615941, lng: 17.7769084 },
    { lat: 48.6141105, lng: 17.7759665 },
    { lat: 48.6125851, lng: 17.7754266 },
    { lat: 48.6120646, lng: 17.7739926 },
    { lat: 48.6106409, lng: 17.7737875 },
    { lat: 48.6104593, lng: 17.7743794 },
    { lat: 48.6100478, lng: 17.7762127 },
    { lat: 48.6090451, lng: 17.7799248 },
    { lat: 48.6087869, lng: 17.7810731 },
    { lat: 48.6085601, lng: 17.7817922 },
    { lat: 48.608502, lng: 17.7818406 },
    { lat: 48.6081768, lng: 17.7832195 },
    { lat: 48.6071571, lng: 17.786716 },
    { lat: 48.6070879, lng: 17.7877594 },
    { lat: 48.608813, lng: 17.7993632 },
    { lat: 48.6116418, lng: 17.8033279 },
    { lat: 48.6116853, lng: 17.8035041 },
    { lat: 48.613879, lng: 17.8046705 },
    { lat: 48.614165, lng: 17.8045308 },
    { lat: 48.6147291, lng: 17.8040796 },
    { lat: 48.6152299, lng: 17.8032047 },
    { lat: 48.6153832, lng: 17.8035877 },
    { lat: 48.6156309, lng: 17.8038169 },
    { lat: 48.6157552, lng: 17.8037828 },
    { lat: 48.6163732, lng: 17.8029951 },
    { lat: 48.6168529, lng: 17.8029202 },
    { lat: 48.6169667, lng: 17.8027495 },
    { lat: 48.617122, lng: 17.8026512 },
    { lat: 48.6172387, lng: 17.8027688 },
    { lat: 48.6173515, lng: 17.8027642 },
    { lat: 48.6177305, lng: 17.802521 },
    { lat: 48.6179199, lng: 17.8022945 },
    { lat: 48.6184413, lng: 17.8019968 },
    { lat: 48.6184921, lng: 17.8016822 },
    { lat: 48.6190738, lng: 17.8017426 },
    { lat: 48.6191924, lng: 17.8014274 },
    { lat: 48.6192961, lng: 17.8012967 },
    { lat: 48.6193033, lng: 17.8009353 },
    { lat: 48.6193864, lng: 17.8007807 },
    { lat: 48.6193078, lng: 17.8002692 },
    { lat: 48.619304, lng: 17.7999679 },
    { lat: 48.6193554, lng: 17.7999029 },
    { lat: 48.6195372, lng: 17.7998652 },
    { lat: 48.6199846, lng: 17.8000406 },
    { lat: 48.6202751, lng: 17.8003966 },
    { lat: 48.620447, lng: 17.8000316 },
    { lat: 48.6212516, lng: 17.7996363 },
    { lat: 48.6213164, lng: 17.7995898 },
    { lat: 48.6216941, lng: 17.7994538 },
    { lat: 48.6221005, lng: 17.7989002 },
    { lat: 48.6223099, lng: 17.7987954 },
    { lat: 48.6227541, lng: 17.7991729 },
    { lat: 48.6234856, lng: 17.7991742 },
    { lat: 48.6240852, lng: 17.7987311 },
    { lat: 48.6245299, lng: 17.7987695 },
    { lat: 48.624691, lng: 17.7984663 },
    { lat: 48.6248247, lng: 17.7984166 },
    { lat: 48.624822, lng: 17.7977664 },
    { lat: 48.6249951, lng: 17.7974933 },
    { lat: 48.6250533, lng: 17.7971733 },
    { lat: 48.6257732, lng: 17.7974053 },
    { lat: 48.6258373, lng: 17.7972547 },
    { lat: 48.6259398, lng: 17.7971398 },
    { lat: 48.6260359, lng: 17.7971239 },
    { lat: 48.6261638, lng: 17.7969448 },
    { lat: 48.626382, lng: 17.7969123 },
    { lat: 48.6268164, lng: 17.7963562 },
    { lat: 48.626878, lng: 17.7963451 },
    { lat: 48.6270576, lng: 17.7965164 },
    { lat: 48.6274493, lng: 17.7965314 },
    { lat: 48.6276214, lng: 17.7963005 },
    { lat: 48.6277871, lng: 17.7964798 },
    { lat: 48.6278189, lng: 17.796707 },
    { lat: 48.6279584, lng: 17.7968821 },
    { lat: 48.6282252, lng: 17.7964106 },
    { lat: 48.6282862, lng: 17.7964089 },
    { lat: 48.6283981, lng: 17.7965022 },
    { lat: 48.6284773, lng: 17.7966906 },
    { lat: 48.6288873, lng: 17.7968227 },
    { lat: 48.6293034, lng: 17.7962623 },
    { lat: 48.6293191, lng: 17.7960924 },
    { lat: 48.6292563, lng: 17.7956586 },
    { lat: 48.6293043, lng: 17.7950973 },
    { lat: 48.6291981, lng: 17.794394 },
    { lat: 48.6286181, lng: 17.793347 },
    { lat: 48.628761, lng: 17.7929874 },
    { lat: 48.6289864, lng: 17.7926116 },
    { lat: 48.6290315, lng: 17.7922242 },
    { lat: 48.6291607, lng: 17.7918065 },
    { lat: 48.6293008, lng: 17.7916253 },
    { lat: 48.6293971, lng: 17.7916301 },
    { lat: 48.6294814, lng: 17.7915495 },
    { lat: 48.6295405, lng: 17.791336 },
    { lat: 48.6295952, lng: 17.7912877 },
    { lat: 48.6299078, lng: 17.7911185 },
    { lat: 48.6301366, lng: 17.7911495 },
    { lat: 48.6303028, lng: 17.7911138 },
    { lat: 48.6304787, lng: 17.7909847 },
    { lat: 48.6305476, lng: 17.7903591 },
    { lat: 48.6304793, lng: 17.7901088 },
    { lat: 48.6305425, lng: 17.7896646 },
    { lat: 48.6309485, lng: 17.7891048 },
    { lat: 48.6310498, lng: 17.7891021 },
    { lat: 48.6315442, lng: 17.7894501 },
    { lat: 48.6322619, lng: 17.79007 },
    { lat: 48.6329778, lng: 17.7902215 },
    { lat: 48.6332705, lng: 17.7901918 },
    { lat: 48.6334446, lng: 17.7872822 },
  ],
  Rakovice: [
    { lat: 48.5867377, lng: 17.6795983 },
    { lat: 48.5852333, lng: 17.6777604 },
    { lat: 48.5851295, lng: 17.6775995 },
    { lat: 48.5838851, lng: 17.6759113 },
    { lat: 48.5837459, lng: 17.6757224 },
    { lat: 48.5820807, lng: 17.67415 },
    { lat: 48.5818405, lng: 17.6739372 },
    { lat: 48.5813089, lng: 17.6751917 },
    { lat: 48.5798193, lng: 17.6782324 },
    { lat: 48.5782542, lng: 17.6809501 },
    { lat: 48.5763219, lng: 17.6845753 },
    { lat: 48.5759532, lng: 17.6854118 },
    { lat: 48.5757668, lng: 17.6858346 },
    { lat: 48.575433, lng: 17.686786 },
    { lat: 48.5751796, lng: 17.6873563 },
    { lat: 48.5742622, lng: 17.6890903 },
    { lat: 48.5736703, lng: 17.6897761 },
    { lat: 48.5732066, lng: 17.690454 },
    { lat: 48.5727621, lng: 17.6908248 },
    { lat: 48.5722247, lng: 17.6917139 },
    { lat: 48.5718207, lng: 17.6925546 },
    { lat: 48.5699569, lng: 17.6951959 },
    { lat: 48.5689403, lng: 17.6970441 },
    { lat: 48.5682456, lng: 17.6980761 },
    { lat: 48.5678796, lng: 17.6987622 },
    { lat: 48.5673951, lng: 17.6999057 },
    { lat: 48.5672535, lng: 17.7002818 },
    { lat: 48.566929, lng: 17.7016216 },
    { lat: 48.5666548, lng: 17.7020745 },
    { lat: 48.5666828, lng: 17.7021111 },
    { lat: 48.5665991, lng: 17.7023443 },
    { lat: 48.5650817, lng: 17.7047483 },
    { lat: 48.5643643, lng: 17.7057238 },
    { lat: 48.5642284, lng: 17.7060241 },
    { lat: 48.5638163, lng: 17.7071352 },
    { lat: 48.5636275, lng: 17.7078066 },
    { lat: 48.5634206, lng: 17.7082389 },
    { lat: 48.5629289, lng: 17.7098868 },
    { lat: 48.5626528, lng: 17.7106051 },
    { lat: 48.5619422, lng: 17.7131674 },
    { lat: 48.5614362, lng: 17.7147695 },
    { lat: 48.5602699, lng: 17.7181211 },
    { lat: 48.5599418, lng: 17.7193324 },
    { lat: 48.5581576, lng: 17.7236505 },
    { lat: 48.5577083, lng: 17.7251642 },
    { lat: 48.5571997, lng: 17.7275302 },
    { lat: 48.5565426, lng: 17.7299233 },
    { lat: 48.5551905, lng: 17.7344058 },
    { lat: 48.5547849, lng: 17.7354609 },
    { lat: 48.5547793, lng: 17.7357528 },
    { lat: 48.5547105, lng: 17.7358844 },
    { lat: 48.5547156, lng: 17.736204 },
    { lat: 48.55482, lng: 17.7367796 },
    { lat: 48.5546836, lng: 17.7369459 },
    { lat: 48.554556, lng: 17.7372364 },
    { lat: 48.5545535, lng: 17.7378397 },
    { lat: 48.5546299, lng: 17.7381412 },
    { lat: 48.554564, lng: 17.738519 },
    { lat: 48.554424, lng: 17.7387909 },
    { lat: 48.5543263, lng: 17.7394967 },
    { lat: 48.5543132, lng: 17.7404187 },
    { lat: 48.5544225, lng: 17.7418788 },
    { lat: 48.5543477, lng: 17.7428258 },
    { lat: 48.5541067, lng: 17.744114 },
    { lat: 48.5541055, lng: 17.7447161 },
    { lat: 48.5542332, lng: 17.7449031 },
    { lat: 48.5541243, lng: 17.7454961 },
    { lat: 48.5539599, lng: 17.7479741 },
    { lat: 48.5540935, lng: 17.748568 },
    { lat: 48.554196, lng: 17.7494047 },
    { lat: 48.5542713, lng: 17.7495674 },
    { lat: 48.5543587, lng: 17.750506 },
    { lat: 48.5544816, lng: 17.7509071 },
    { lat: 48.5547139, lng: 17.7513051 },
    { lat: 48.5548316, lng: 17.7516946 },
    { lat: 48.5553937, lng: 17.7525736 },
    { lat: 48.5561526, lng: 17.753621 },
    { lat: 48.5564478, lng: 17.7541236 },
    { lat: 48.556708, lng: 17.7544247 },
    { lat: 48.5573424, lng: 17.7546083 },
    { lat: 48.5581898, lng: 17.7547079 },
    { lat: 48.5583955, lng: 17.7546745 },
    { lat: 48.5589558, lng: 17.7548103 },
    { lat: 48.5595753, lng: 17.7553146 },
    { lat: 48.5596828, lng: 17.7557779 },
    { lat: 48.5602043, lng: 17.7568964 },
    { lat: 48.5608068, lng: 17.7585594 },
    { lat: 48.5614546, lng: 17.7595242 },
    { lat: 48.5613909, lng: 17.7612065 },
    { lat: 48.561404, lng: 17.7637302 },
    { lat: 48.5613333, lng: 17.7665465 },
    { lat: 48.5613409, lng: 17.7670548 },
    { lat: 48.5614853, lng: 17.7682639 },
    { lat: 48.5613404, lng: 17.7731263 },
    { lat: 48.5613617, lng: 17.7746096 },
    { lat: 48.5615376, lng: 17.7780626 },
    { lat: 48.5615382, lng: 17.7787711 },
    { lat: 48.5615212, lng: 17.7791758 },
    { lat: 48.5616349, lng: 17.779171 },
    { lat: 48.5623129, lng: 17.7798061 },
    { lat: 48.5626865, lng: 17.7798795 },
    { lat: 48.5628945, lng: 17.7797725 },
    { lat: 48.5630814, lng: 17.7794534 },
    { lat: 48.5631446, lng: 17.7792301 },
    { lat: 48.5631674, lng: 17.7789043 },
    { lat: 48.5629687, lng: 17.7777986 },
    { lat: 48.5626474, lng: 17.7772738 },
    { lat: 48.5626569, lng: 17.7770048 },
    { lat: 48.5631556, lng: 17.7764659 },
    { lat: 48.5628922, lng: 17.7757239 },
    { lat: 48.5635104, lng: 17.7756444 },
    { lat: 48.5637592, lng: 17.7754493 },
    { lat: 48.563846, lng: 17.7751913 },
    { lat: 48.5639513, lng: 17.7751231 },
    { lat: 48.5642857, lng: 17.7750313 },
    { lat: 48.5643997, lng: 17.7750695 },
    { lat: 48.5645915, lng: 17.774846 },
    { lat: 48.5646421, lng: 17.7745488 },
    { lat: 48.5648359, lng: 17.7743411 },
    { lat: 48.5651448, lng: 17.7743907 },
    { lat: 48.5652372, lng: 17.7747538 },
    { lat: 48.565518, lng: 17.7753014 },
    { lat: 48.5661604, lng: 17.7751911 },
    { lat: 48.5663884, lng: 17.7753896 },
    { lat: 48.5665253, lng: 17.7749189 },
    { lat: 48.5666294, lng: 17.7744253 },
    { lat: 48.5667363, lng: 17.7725658 },
    { lat: 48.5670082, lng: 17.7711459 },
    { lat: 48.5671031, lng: 17.7681908 },
    { lat: 48.5669539, lng: 17.7676527 },
    { lat: 48.5633818, lng: 17.7543193 },
    { lat: 48.5624986, lng: 17.7477002 },
    { lat: 48.5621947, lng: 17.7445691 },
    { lat: 48.5623312, lng: 17.7420039 },
    { lat: 48.5629516, lng: 17.7409889 },
    { lat: 48.5631614, lng: 17.7396499 },
    { lat: 48.5630089, lng: 17.7383147 },
    { lat: 48.5629578, lng: 17.7382912 },
    { lat: 48.5639885, lng: 17.7339566 },
    { lat: 48.5645926, lng: 17.7316202 },
    { lat: 48.5656339, lng: 17.728119 },
    { lat: 48.5659855, lng: 17.7272512 },
    { lat: 48.5659672, lng: 17.7271008 },
    { lat: 48.5663759, lng: 17.7265323 },
    { lat: 48.566535, lng: 17.7260936 },
    { lat: 48.5673715, lng: 17.7245395 },
    { lat: 48.5676264, lng: 17.7238403 },
    { lat: 48.5680403, lng: 17.7230128 },
    { lat: 48.569249, lng: 17.7210705 },
    { lat: 48.569602, lng: 17.7203354 },
    { lat: 48.5701556, lng: 17.7189374 },
    { lat: 48.5708027, lng: 17.7176076 },
    { lat: 48.5722339, lng: 17.7142662 },
    { lat: 48.5735675, lng: 17.71077 },
    { lat: 48.5746786, lng: 17.7086765 },
    { lat: 48.5754467, lng: 17.7070259 },
    { lat: 48.5764691, lng: 17.7054322 },
    { lat: 48.5772929, lng: 17.7039516 },
    { lat: 48.5783052, lng: 17.7018675 },
    { lat: 48.5789104, lng: 17.7002125 },
    { lat: 48.5790516, lng: 17.6999797 },
    { lat: 48.5798491, lng: 17.6991271 },
    { lat: 48.5800577, lng: 17.6988134 },
    { lat: 48.5806897, lng: 17.6976635 },
    { lat: 48.581895, lng: 17.6958018 },
    { lat: 48.5829033, lng: 17.6940559 },
    { lat: 48.5834767, lng: 17.6932728 },
    { lat: 48.5840919, lng: 17.691833 },
    { lat: 48.5845265, lng: 17.6910331 },
    { lat: 48.5849506, lng: 17.6905902 },
    { lat: 48.5840385, lng: 17.6892455 },
    { lat: 48.5832359, lng: 17.6874895 },
    { lat: 48.5826095, lng: 17.6865107 },
    { lat: 48.582111, lng: 17.6855933 },
    { lat: 48.5822524, lng: 17.6854357 },
    { lat: 48.5824902, lng: 17.6853911 },
    { lat: 48.5826003, lng: 17.6851633 },
    { lat: 48.5831031, lng: 17.684792 },
    { lat: 48.5834005, lng: 17.6843085 },
    { lat: 48.583603, lng: 17.6840748 },
    { lat: 48.584237, lng: 17.6834696 },
    { lat: 48.5844012, lng: 17.6834385 },
    { lat: 48.5845065, lng: 17.6833181 },
    { lat: 48.5846645, lng: 17.6829701 },
    { lat: 48.5853383, lng: 17.6820946 },
    { lat: 48.5856707, lng: 17.6815241 },
    { lat: 48.5861569, lng: 17.6805014 },
    { lat: 48.5866269, lng: 17.6797612 },
    { lat: 48.5867377, lng: 17.6795983 },
  ],
  MoravanynadVáhom: [
    { lat: 48.583951, lng: 17.891383 },
    { lat: 48.584016, lng: 17.891398 },
    { lat: 48.584571, lng: 17.89199 },
    { lat: 48.584721, lng: 17.892053 },
    { lat: 48.584887, lng: 17.892009 },
    { lat: 48.585247, lng: 17.891938 },
    { lat: 48.585511, lng: 17.891875 },
    { lat: 48.585897, lng: 17.891874 },
    { lat: 48.586253, lng: 17.891814 },
    { lat: 48.586467, lng: 17.89175 },
    { lat: 48.586787, lng: 17.891459 },
    { lat: 48.58685, lng: 17.891452 },
    { lat: 48.586971, lng: 17.891484 },
    { lat: 48.587191, lng: 17.891644 },
    { lat: 48.587273, lng: 17.891725 },
    { lat: 48.587407, lng: 17.891845 },
    { lat: 48.58749, lng: 17.891908 },
    { lat: 48.587572, lng: 17.891971 },
    { lat: 48.587906, lng: 17.892157 },
    { lat: 48.588006, lng: 17.892179 },
    { lat: 48.588323, lng: 17.892248 },
    { lat: 48.58841, lng: 17.892304 },
    { lat: 48.588556, lng: 17.892477 },
    { lat: 48.588681, lng: 17.892563 },
    { lat: 48.588995, lng: 17.892779 },
    { lat: 48.589098, lng: 17.892871 },
    { lat: 48.589236, lng: 17.893003 },
    { lat: 48.589366, lng: 17.893117 },
    { lat: 48.589566, lng: 17.893289 },
    { lat: 48.58983, lng: 17.893613 },
    { lat: 48.590076, lng: 17.894019 },
    { lat: 48.590564, lng: 17.894414 },
    { lat: 48.590695, lng: 17.894616 },
    { lat: 48.590937, lng: 17.895076 },
    { lat: 48.591083, lng: 17.895278 },
    { lat: 48.591319, lng: 17.89542 },
    { lat: 48.591467, lng: 17.895469 },
    { lat: 48.591744, lng: 17.89551 },
    { lat: 48.591943, lng: 17.895553 },
    { lat: 48.591994, lng: 17.895569 },
    { lat: 48.592237, lng: 17.895687 },
    { lat: 48.592367, lng: 17.895784 },
    { lat: 48.592653, lng: 17.89626 },
    { lat: 48.592729, lng: 17.89647 },
    { lat: 48.592738, lng: 17.896611 },
    { lat: 48.592668, lng: 17.897001 },
    { lat: 48.59262, lng: 17.89729 },
    { lat: 48.592602, lng: 17.897438 },
    { lat: 48.592488, lng: 17.897764 },
    { lat: 48.592437, lng: 17.897995 },
    { lat: 48.592433, lng: 17.898107 },
    { lat: 48.592461, lng: 17.898194 },
    { lat: 48.592598, lng: 17.898544 },
    { lat: 48.592751, lng: 17.899047 },
    { lat: 48.592864, lng: 17.899362 },
    { lat: 48.592897, lng: 17.899579 },
    { lat: 48.592876, lng: 17.899768 },
    { lat: 48.592771, lng: 17.900206 },
    { lat: 48.592678, lng: 17.90139 },
    { lat: 48.592537, lng: 17.901777 },
    { lat: 48.592212, lng: 17.902331 },
    { lat: 48.592143, lng: 17.902486 },
    { lat: 48.591948, lng: 17.90312 },
    { lat: 48.591807, lng: 17.903541 },
    { lat: 48.591697, lng: 17.903797 },
    { lat: 48.59163, lng: 17.904022 },
    { lat: 48.591534, lng: 17.904236 },
    { lat: 48.591265, lng: 17.904607 },
    { lat: 48.591166, lng: 17.904792 },
    { lat: 48.590898, lng: 17.905251 },
    { lat: 48.590776, lng: 17.905495 },
    { lat: 48.590601, lng: 17.905988 },
    { lat: 48.590112, lng: 17.907706 },
    { lat: 48.590044, lng: 17.907949 },
    { lat: 48.589912, lng: 17.908311 },
    { lat: 48.589775, lng: 17.908584 },
    { lat: 48.589656, lng: 17.908823 },
    { lat: 48.589411, lng: 17.909234 },
    { lat: 48.589305, lng: 17.909578 },
    { lat: 48.589161, lng: 17.909899 },
    { lat: 48.588979, lng: 17.910462 },
    { lat: 48.588856, lng: 17.91103 },
    { lat: 48.58882, lng: 17.911266 },
    { lat: 48.588828, lng: 17.911666 },
    { lat: 48.588754, lng: 17.91205 },
    { lat: 48.588742, lng: 17.912433 },
    { lat: 48.588714, lng: 17.912758 },
    { lat: 48.588702, lng: 17.913064 },
    { lat: 48.588669, lng: 17.913347 },
    { lat: 48.588551, lng: 17.913556 },
    { lat: 48.588406, lng: 17.913859 },
    { lat: 48.588264, lng: 17.914109 },
    { lat: 48.588132, lng: 17.91453 },
    { lat: 48.587985, lng: 17.914675 },
    { lat: 48.587809, lng: 17.914914 },
    { lat: 48.587743, lng: 17.915045 },
    { lat: 48.587574, lng: 17.915355 },
    { lat: 48.58737, lng: 17.915849 },
    { lat: 48.58823, lng: 17.9160229 },
    { lat: 48.5888922, lng: 17.9156233 },
    { lat: 48.5901086, lng: 17.914454 },
    { lat: 48.5906552, lng: 17.9141393 },
    { lat: 48.5910688, lng: 17.915817 },
    { lat: 48.5913482, lng: 17.9158056 },
    { lat: 48.591609, lng: 17.9155635 },
    { lat: 48.5919895, lng: 17.9153777 },
    { lat: 48.5921016, lng: 17.9151018 },
    { lat: 48.5922372, lng: 17.9149927 },
    { lat: 48.5926832, lng: 17.9140752 },
    { lat: 48.5929522, lng: 17.9137417 },
    { lat: 48.5931107, lng: 17.9134312 },
    { lat: 48.593368, lng: 17.9131471 },
    { lat: 48.5937068, lng: 17.9126469 },
    { lat: 48.5950592, lng: 17.9113354 },
    { lat: 48.5952188, lng: 17.9110397 },
    { lat: 48.5952703, lng: 17.9108262 },
    { lat: 48.5954647, lng: 17.9105154 },
    { lat: 48.595611, lng: 17.9099045 },
    { lat: 48.5957007, lng: 17.9097093 },
    { lat: 48.5959412, lng: 17.9092395 },
    { lat: 48.5962354, lng: 17.9088787 },
    { lat: 48.5970847, lng: 17.9080431 },
    { lat: 48.5971962, lng: 17.9080924 },
    { lat: 48.5973385, lng: 17.9079995 },
    { lat: 48.5975151, lng: 17.9080659 },
    { lat: 48.5975383, lng: 17.9080181 },
    { lat: 48.5977805, lng: 17.9080096 },
    { lat: 48.5978212, lng: 17.90807 },
    { lat: 48.5979106, lng: 17.9079593 },
    { lat: 48.5981576, lng: 17.9079958 },
    { lat: 48.5982913, lng: 17.9078345 },
    { lat: 48.5985479, lng: 17.9077081 },
    { lat: 48.598632, lng: 17.9075296 },
    { lat: 48.5988732, lng: 17.9076746 },
    { lat: 48.5990274, lng: 17.9076303 },
    { lat: 48.5990996, lng: 17.9073487 },
    { lat: 48.5992267, lng: 17.9071148 },
    { lat: 48.5994237, lng: 17.9069919 },
    { lat: 48.5994301, lng: 17.9068555 },
    { lat: 48.5997787, lng: 17.9066109 },
    { lat: 48.5997975, lng: 17.9064154 },
    { lat: 48.5998806, lng: 17.906444 },
    { lat: 48.5999737, lng: 17.9061916 },
    { lat: 48.6000268, lng: 17.9061383 },
    { lat: 48.6000718, lng: 17.9061913 },
    { lat: 48.6003456, lng: 17.9059377 },
    { lat: 48.6005695, lng: 17.9058336 },
    { lat: 48.6008415, lng: 17.9055891 },
    { lat: 48.6011079, lng: 17.9051589 },
    { lat: 48.601233, lng: 17.9050405 },
    { lat: 48.6014455, lng: 17.9049911 },
    { lat: 48.6016208, lng: 17.9046215 },
    { lat: 48.6016232, lng: 17.9045277 },
    { lat: 48.601964, lng: 17.9040206 },
    { lat: 48.6020834, lng: 17.9037375 },
    { lat: 48.6020751, lng: 17.9035257 },
    { lat: 48.6022241, lng: 17.9033366 },
    { lat: 48.6021744, lng: 17.9030174 },
    { lat: 48.6022573, lng: 17.9026319 },
    { lat: 48.6022844, lng: 17.9020715 },
    { lat: 48.6022294, lng: 17.9019453 },
    { lat: 48.6023118, lng: 17.9011761 },
    { lat: 48.6024689, lng: 17.9009581 },
    { lat: 48.6025611, lng: 17.9006309 },
    { lat: 48.6033004, lng: 17.8996012 },
    { lat: 48.6033635, lng: 17.8993924 },
    { lat: 48.603501, lng: 17.8991656 },
    { lat: 48.6038052, lng: 17.8988757 },
    { lat: 48.6040268, lng: 17.8984116 },
    { lat: 48.6041008, lng: 17.8980848 },
    { lat: 48.6042738, lng: 17.8978827 },
    { lat: 48.6043181, lng: 17.8979077 },
    { lat: 48.604418, lng: 17.8977288 },
    { lat: 48.6045181, lng: 17.8977051 },
    { lat: 48.6045383, lng: 17.8975704 },
    { lat: 48.6046911, lng: 17.897522 },
    { lat: 48.6050143, lng: 17.8971353 },
    { lat: 48.6053594, lng: 17.896962 },
    { lat: 48.605424, lng: 17.8968144 },
    { lat: 48.6054746, lng: 17.89683 },
    { lat: 48.6058258, lng: 17.8963649 },
    { lat: 48.6060364, lng: 17.896468 },
    { lat: 48.6061951, lng: 17.8960496 },
    { lat: 48.6062667, lng: 17.8959845 },
    { lat: 48.6064418, lng: 17.8958556 },
    { lat: 48.606724, lng: 17.895778 },
    { lat: 48.6070262, lng: 17.8954384 },
    { lat: 48.6073198, lng: 17.89529 },
    { lat: 48.6074742, lng: 17.8953056 },
    { lat: 48.6080785, lng: 17.894881 },
    { lat: 48.6081267, lng: 17.8947624 },
    { lat: 48.6081819, lng: 17.8947794 },
    { lat: 48.6082174, lng: 17.8943012 },
    { lat: 48.6080214, lng: 17.8936161 },
    { lat: 48.6079014, lng: 17.8934236 },
    { lat: 48.6078137, lng: 17.8934092 },
    { lat: 48.607779, lng: 17.8930027 },
    { lat: 48.6077131, lng: 17.8929575 },
    { lat: 48.607616, lng: 17.8926209 },
    { lat: 48.607527, lng: 17.8925689 },
    { lat: 48.6075101, lng: 17.8920822 },
    { lat: 48.6075849, lng: 17.8919931 },
    { lat: 48.6076037, lng: 17.8914067 },
    { lat: 48.6075736, lng: 17.8912096 },
    { lat: 48.6075107, lng: 17.8909711 },
    { lat: 48.6072541, lng: 17.8904434 },
    { lat: 48.6070106, lng: 17.8893793 },
    { lat: 48.6071545, lng: 17.889239 },
    { lat: 48.6071838, lng: 17.8889564 },
    { lat: 48.607144, lng: 17.888541 },
    { lat: 48.6072091, lng: 17.8885185 },
    { lat: 48.6072302, lng: 17.8881742 },
    { lat: 48.6074827, lng: 17.8882416 },
    { lat: 48.6075592, lng: 17.8880916 },
    { lat: 48.6075601, lng: 17.8857323 },
    { lat: 48.6078241, lng: 17.8841976 },
    { lat: 48.6080931, lng: 17.8829892 },
    { lat: 48.6083829, lng: 17.881992 },
    { lat: 48.6088889, lng: 17.8807828 },
    { lat: 48.6110037, lng: 17.881538 },
    { lat: 48.6121251, lng: 17.8781833 },
    { lat: 48.6128542, lng: 17.8758492 },
    { lat: 48.6130786, lng: 17.8748747 },
    { lat: 48.613287, lng: 17.8735807 },
    { lat: 48.6134711, lng: 17.8717909 },
    { lat: 48.6134455, lng: 17.87066 },
    { lat: 48.61358, lng: 17.8700101 },
    { lat: 48.6138214, lng: 17.8693361 },
    { lat: 48.6130825, lng: 17.8669 },
    { lat: 48.6155077, lng: 17.8606855 },
    { lat: 48.6163108, lng: 17.8613996 },
    { lat: 48.6181539, lng: 17.8568327 },
    { lat: 48.6184604, lng: 17.8561515 },
    { lat: 48.6169305, lng: 17.8545869 },
    { lat: 48.6152801, lng: 17.8530404 },
    { lat: 48.6145214, lng: 17.8503418 },
    { lat: 48.6136087, lng: 17.849145 },
    { lat: 48.6130964, lng: 17.8493334 },
    { lat: 48.6116046, lng: 17.8488599 },
    { lat: 48.6110938, lng: 17.8489254 },
    { lat: 48.6104812, lng: 17.8485206 },
    { lat: 48.6100062, lng: 17.8487474 },
    { lat: 48.6089524, lng: 17.848853 },
    { lat: 48.6082284, lng: 17.8488078 },
    { lat: 48.6053029, lng: 17.8498951 },
    { lat: 48.6007767, lng: 17.850633 },
    { lat: 48.6005961, lng: 17.8500944 },
    { lat: 48.5983896, lng: 17.8503797 },
    { lat: 48.5984417, lng: 17.8511639 },
    { lat: 48.5985426, lng: 17.8516172 },
    { lat: 48.5984958, lng: 17.8518484 },
    { lat: 48.5978599, lng: 17.8526626 },
    { lat: 48.5975708, lng: 17.8529029 },
    { lat: 48.5969906, lng: 17.8528849 },
    { lat: 48.5963874, lng: 17.8525812 },
    { lat: 48.5962729, lng: 17.8523316 },
    { lat: 48.5951025, lng: 17.8516087 },
    { lat: 48.5953739, lng: 17.8506309 },
    { lat: 48.5944085, lng: 17.8506282 },
    { lat: 48.593522, lng: 17.8504931 },
    { lat: 48.5930684, lng: 17.8518894 },
    { lat: 48.593055, lng: 17.8519305 },
    { lat: 48.5918955, lng: 17.8559452 },
    { lat: 48.5907677, lng: 17.8590711 },
    { lat: 48.5905814, lng: 17.8593293 },
    { lat: 48.5899714, lng: 17.8595296 },
    { lat: 48.5894062, lng: 17.8598867 },
    { lat: 48.5892904, lng: 17.8600446 },
    { lat: 48.5893814, lng: 17.860129 },
    { lat: 48.5891431, lng: 17.8605919 },
    { lat: 48.589038, lng: 17.8607034 },
    { lat: 48.5888743, lng: 17.8607395 },
    { lat: 48.5886825, lng: 17.8609863 },
    { lat: 48.5886426, lng: 17.8611633 },
    { lat: 48.588634, lng: 17.8619728 },
    { lat: 48.5884514, lng: 17.8624415 },
    { lat: 48.5884086, lng: 17.8627965 },
    { lat: 48.5883124, lng: 17.8629424 },
    { lat: 48.5882897, lng: 17.8633317 },
    { lat: 48.5882249, lng: 17.8633788 },
    { lat: 48.5882385, lng: 17.8634357 },
    { lat: 48.5883257, lng: 17.8634419 },
    { lat: 48.5883705, lng: 17.8636942 },
    { lat: 48.5884577, lng: 17.8638124 },
    { lat: 48.5884374, lng: 17.8640915 },
    { lat: 48.5885301, lng: 17.8645968 },
    { lat: 48.5885798, lng: 17.8653441 },
    { lat: 48.5884566, lng: 17.8656374 },
    { lat: 48.5885153, lng: 17.8662508 },
    { lat: 48.588294, lng: 17.8668308 },
    { lat: 48.5877773, lng: 17.867691 },
    { lat: 48.5867329, lng: 17.8682106 },
    { lat: 48.586272, lng: 17.8687093 },
    { lat: 48.5853739, lng: 17.8693856 },
    { lat: 48.5848368, lng: 17.8699309 },
    { lat: 48.5836339, lng: 17.8707217 },
    { lat: 48.5833816, lng: 17.8709743 },
    { lat: 48.5831951, lng: 17.8713051 },
    { lat: 48.5827597, lng: 17.8722784 },
    { lat: 48.5827358, lng: 17.8727427 },
    { lat: 48.5830838, lng: 17.8738457 },
    { lat: 48.5831247, lng: 17.8743535 },
    { lat: 48.5832391, lng: 17.8749182 },
    { lat: 48.5836074, lng: 17.8760091 },
    { lat: 48.5841474, lng: 17.8779907 },
    { lat: 48.5842869, lng: 17.8786924 },
    { lat: 48.584287, lng: 17.8792532 },
    { lat: 48.5844425, lng: 17.8798109 },
    { lat: 48.5847407, lng: 17.8803179 },
    { lat: 48.5847357, lng: 17.8804034 },
    { lat: 48.5848674, lng: 17.8807112 },
    { lat: 48.5849556, lng: 17.8814617 },
    { lat: 48.5849135, lng: 17.8818686 },
    { lat: 48.5850133, lng: 17.8821936 },
    { lat: 48.5850285, lng: 17.8825923 },
    { lat: 48.5851192, lng: 17.882747 },
    { lat: 48.5851546, lng: 17.8829408 },
    { lat: 48.5850491, lng: 17.8833446 },
    { lat: 48.585047, lng: 17.8837396 },
    { lat: 48.5849679, lng: 17.8841109 },
    { lat: 48.5849306, lng: 17.8852498 },
    { lat: 48.5849937, lng: 17.885715 },
    { lat: 48.5849211, lng: 17.8864002 },
    { lat: 48.5847945, lng: 17.8867354 },
    { lat: 48.5847235, lng: 17.8869233 },
    { lat: 48.5845993, lng: 17.887781 },
    { lat: 48.5846246, lng: 17.8878278 },
    { lat: 48.5844198, lng: 17.8880438 },
    { lat: 48.5842186, lng: 17.8885984 },
    { lat: 48.584224, lng: 17.8894904 },
    { lat: 48.5843766, lng: 17.8898852 },
    { lat: 48.5844421, lng: 17.8901864 },
    { lat: 48.5844198, lng: 17.8903434 },
    { lat: 48.5840694, lng: 17.8912497 },
    { lat: 48.583951, lng: 17.891383 },
  ],
  Ratnovce: [
    { lat: 48.5471454, lng: 17.822263 },
    { lat: 48.5464517, lng: 17.8217195 },
    { lat: 48.5434164, lng: 17.8193566 },
    { lat: 48.5409631, lng: 17.8212596 },
    { lat: 48.5403177, lng: 17.8218752 },
    { lat: 48.5408547, lng: 17.822368 },
    { lat: 48.5411665, lng: 17.8226504 },
    { lat: 48.5417013, lng: 17.8243287 },
    { lat: 48.5414746, lng: 17.8270331 },
    { lat: 48.5421665, lng: 17.8284557 },
    { lat: 48.5440584, lng: 17.8312675 },
    { lat: 48.5448452, lng: 17.8336586 },
    { lat: 48.5453615, lng: 17.833941 },
    { lat: 48.5459609, lng: 17.8370931 },
    { lat: 48.5463052, lng: 17.8381123 },
    { lat: 48.5466077, lng: 17.8387018 },
    { lat: 48.5468109, lng: 17.8394287 },
    { lat: 48.5470072, lng: 17.8398516 },
    { lat: 48.5470443, lng: 17.8398507 },
    { lat: 48.5469564, lng: 17.8403339 },
    { lat: 48.5469535, lng: 17.8407148 },
    { lat: 48.5471882, lng: 17.8429383 },
    { lat: 48.5474947, lng: 17.8430581 },
    { lat: 48.5479729, lng: 17.8433662 },
    { lat: 48.5480632, lng: 17.8439755 },
    { lat: 48.5477693, lng: 17.8441375 },
    { lat: 48.5475782, lng: 17.8444293 },
    { lat: 48.5474901, lng: 17.8447814 },
    { lat: 48.5474339, lng: 17.845491 },
    { lat: 48.5471774, lng: 17.8458214 },
    { lat: 48.5464329, lng: 17.8464226 },
    { lat: 48.5462221, lng: 17.8467638 },
    { lat: 48.546058, lng: 17.8468309 },
    { lat: 48.5457547, lng: 17.8468848 },
    { lat: 48.5453376, lng: 17.8469588 },
    { lat: 48.5448544, lng: 17.8468623 },
    { lat: 48.5445939, lng: 17.8467379 },
    { lat: 48.5435926, lng: 17.8466637 },
    { lat: 48.5431693, lng: 17.8464737 },
    { lat: 48.5427268, lng: 17.8462752 },
    { lat: 48.5414628, lng: 17.8459273 },
    { lat: 48.540265, lng: 17.8453894 },
    { lat: 48.5398849, lng: 17.8453396 },
    { lat: 48.5394455, lng: 17.8454469 },
    { lat: 48.5391579, lng: 17.8456921 },
    { lat: 48.5390382, lng: 17.8459683 },
    { lat: 48.5389424, lng: 17.8466957 },
    { lat: 48.5391962, lng: 17.8473799 },
    { lat: 48.5391506, lng: 17.8474468 },
    { lat: 48.5394803, lng: 17.8481145 },
    { lat: 48.5395576, lng: 17.8484212 },
    { lat: 48.5397323, lng: 17.8511657 },
    { lat: 48.5398193, lng: 17.8518886 },
    { lat: 48.5405436, lng: 17.853555 },
    { lat: 48.5404451, lng: 17.8547206 },
    { lat: 48.5400372, lng: 17.855791 },
    { lat: 48.539904, lng: 17.8562767 },
    { lat: 48.5398371, lng: 17.8571472 },
    { lat: 48.5397511, lng: 17.8577173 },
    { lat: 48.5396638, lng: 17.8580089 },
    { lat: 48.5390676, lng: 17.8594767 },
    { lat: 48.5387331, lng: 17.8600643 },
    { lat: 48.5384849, lng: 17.8604375 },
    { lat: 48.538369, lng: 17.8604453 },
    { lat: 48.5380788, lng: 17.8606292 },
    { lat: 48.5382648, lng: 17.8614065 },
    { lat: 48.5384969, lng: 17.8620111 },
    { lat: 48.5386456, lng: 17.862676 },
    { lat: 48.5388379, lng: 17.8629797 },
    { lat: 48.5390318, lng: 17.8630531 },
    { lat: 48.539186, lng: 17.863175 },
    { lat: 48.53984, lng: 17.863608 },
    { lat: 48.540269, lng: 17.863343 },
    { lat: 48.541039, lng: 17.862878 },
    { lat: 48.541218, lng: 17.862843 },
    { lat: 48.541497, lng: 17.863329 },
    { lat: 48.541805, lng: 17.863368 },
    { lat: 48.542207, lng: 17.863579 },
    { lat: 48.542368, lng: 17.863733 },
    { lat: 48.542543, lng: 17.863922 },
    { lat: 48.542877, lng: 17.863882 },
    { lat: 48.543093, lng: 17.863988 },
    { lat: 48.54327, lng: 17.864118 },
    { lat: 48.543785, lng: 17.863791 },
    { lat: 48.544193, lng: 17.863937 },
    { lat: 48.544557, lng: 17.863761 },
    { lat: 48.545043, lng: 17.864218 },
    { lat: 48.545102, lng: 17.864264 },
    { lat: 48.545525, lng: 17.864555 },
    { lat: 48.545775, lng: 17.864686 },
    { lat: 48.546188, lng: 17.865048 },
    { lat: 48.546839, lng: 17.865664 },
    { lat: 48.547133, lng: 17.865806 },
    { lat: 48.547824, lng: 17.866015 },
    { lat: 48.548382, lng: 17.866657 },
    { lat: 48.548571, lng: 17.866771 },
    { lat: 48.549397, lng: 17.867349 },
    { lat: 48.549636, lng: 17.867497 },
    { lat: 48.55049, lng: 17.868533 },
    { lat: 48.551044, lng: 17.869045 },
    { lat: 48.551204, lng: 17.869172 },
    { lat: 48.551908, lng: 17.869487 },
    { lat: 48.552005, lng: 17.869468 },
    { lat: 48.552803, lng: 17.869441 },
    { lat: 48.553172, lng: 17.869675 },
    { lat: 48.553324, lng: 17.869725 },
    { lat: 48.554355, lng: 17.869599 },
    { lat: 48.554972, lng: 17.869817 },
    { lat: 48.555817, lng: 17.869301 },
    { lat: 48.5568, lng: 17.870869 },
    { lat: 48.557945, lng: 17.871377 },
    { lat: 48.558341, lng: 17.871675 },
    { lat: 48.558455, lng: 17.87182 },
    { lat: 48.558564, lng: 17.872053 },
    { lat: 48.558582, lng: 17.87197 },
    { lat: 48.558618, lng: 17.871899 },
    { lat: 48.558679, lng: 17.87185 },
    { lat: 48.558757, lng: 17.871772 },
    { lat: 48.558764, lng: 17.871766 },
    { lat: 48.558832, lng: 17.8717 },
    { lat: 48.558855, lng: 17.871677 },
    { lat: 48.558871, lng: 17.871659 },
    { lat: 48.55891, lng: 17.87163 },
    { lat: 48.558952, lng: 17.871589 },
    { lat: 48.558988, lng: 17.87156 },
    { lat: 48.559043, lng: 17.871514 },
    { lat: 48.559093, lng: 17.871473 },
    { lat: 48.559116, lng: 17.871444 },
    { lat: 48.559159, lng: 17.871426 },
    { lat: 48.559203, lng: 17.871397 },
    { lat: 48.559218, lng: 17.871386 },
    { lat: 48.559331, lng: 17.871393 },
    { lat: 48.559367, lng: 17.871387 },
    { lat: 48.559421, lng: 17.871393 },
    { lat: 48.559472, lng: 17.871394 },
    { lat: 48.559503, lng: 17.871394 },
    { lat: 48.559565, lng: 17.871412 },
    { lat: 48.559631, lng: 17.871424 },
    { lat: 48.559686, lng: 17.871443 },
    { lat: 48.559749, lng: 17.8714714 },
    { lat: 48.559819, lng: 17.871485 },
    { lat: 48.559877, lng: 17.871492 },
    { lat: 48.559923, lng: 17.871497 },
    { lat: 48.559986, lng: 17.87151 },
    { lat: 48.560033, lng: 17.871522 },
    { lat: 48.56008, lng: 17.871528 },
    { lat: 48.56013, lng: 17.871517 },
    { lat: 48.560173, lng: 17.871517 },
    { lat: 48.560212, lng: 17.871512 },
    { lat: 48.560239, lng: 17.871512 },
    { lat: 48.560329, lng: 17.871466 },
    { lat: 48.560396, lng: 17.871425 },
    { lat: 48.560435, lng: 17.871407 },
    { lat: 48.560509, lng: 17.871367 },
    { lat: 48.560544, lng: 17.871349 },
    { lat: 48.560607, lng: 17.871338 },
    { lat: 48.560684, lng: 17.871333 },
    { lat: 48.560739, lng: 17.871322 },
    { lat: 48.56077, lng: 17.871316 },
    { lat: 48.560814, lng: 17.871311 },
    { lat: 48.560845, lng: 17.871305 },
    { lat: 48.560915, lng: 17.871318 },
    { lat: 48.560966, lng: 17.871324 },
    { lat: 48.561036, lng: 17.871342 },
    { lat: 48.561117, lng: 17.871361 },
    { lat: 48.561168, lng: 17.871367 },
    { lat: 48.561238, lng: 17.871385 },
    { lat: 48.561296, lng: 17.871391 },
    { lat: 48.561328, lng: 17.871397 },
    { lat: 48.561394, lng: 17.871427 },
    { lat: 48.561445, lng: 17.871457 },
    { lat: 48.561499, lng: 17.871476 },
    { lat: 48.561534, lng: 17.871511 },
    { lat: 48.561589, lng: 17.871547 },
    { lat: 48.561663, lng: 17.871594 },
    { lat: 48.561721, lng: 17.871642 },
    { lat: 48.56176, lng: 17.871672 },
    { lat: 48.561806, lng: 17.871707 },
    { lat: 48.561838, lng: 17.871737 },
    { lat: 48.561903, lng: 17.871797 },
    { lat: 48.561951, lng: 17.87185 },
    { lat: 48.562021, lng: 17.871927 },
    { lat: 48.56209, lng: 17.87201 },
    { lat: 48.562133, lng: 17.872051 },
    { lat: 48.562172, lng: 17.872093 },
    { lat: 48.562199, lng: 17.872129 },
    { lat: 48.562238, lng: 17.872158 },
    { lat: 48.562281, lng: 17.8722 },
    { lat: 48.562319, lng: 17.872247 },
    { lat: 48.562366, lng: 17.872301 },
    { lat: 48.562413, lng: 17.872366 },
    { lat: 48.562467, lng: 17.872431 },
    { lat: 48.562514, lng: 17.872502 },
    { lat: 48.562559, lng: 17.872585 },
    { lat: 48.562603, lng: 17.87265 },
    { lat: 48.562638, lng: 17.872703 },
    { lat: 48.562664, lng: 17.87275 },
    { lat: 48.562712, lng: 17.872804 },
    { lat: 48.562766, lng: 17.872863 },
    { lat: 48.56282, lng: 17.872935 },
    { lat: 48.562866, lng: 17.873006 },
    { lat: 48.562917, lng: 17.873071 },
    { lat: 48.562941, lng: 17.8731 },
    { lat: 48.56299, lng: 17.873195 },
    { lat: 48.563045, lng: 17.873278 },
    { lat: 48.563075, lng: 17.873331 },
    { lat: 48.563115, lng: 17.873425 },
    { lat: 48.563153, lng: 17.873532 },
    { lat: 48.563184, lng: 17.873614 },
    { lat: 48.563243, lng: 17.873727 },
    { lat: 48.5633, lng: 17.873816 },
    { lat: 48.563351, lng: 17.87391 },
    { lat: 48.563381, lng: 17.873981 },
    { lat: 48.563444, lng: 17.874117 },
    { lat: 48.563486, lng: 17.874205 },
    { lat: 48.563513, lng: 17.874282 },
    { lat: 48.563556, lng: 17.874365 },
    { lat: 48.563602, lng: 17.874448 },
    { lat: 48.563653, lng: 17.87453 },
    { lat: 48.563699, lng: 17.874613 },
    { lat: 48.563746, lng: 17.874702 },
    { lat: 48.563792, lng: 17.874761 },
    { lat: 48.563847, lng: 17.874809 },
    { lat: 48.563909, lng: 17.874863 },
    { lat: 48.563971, lng: 17.874922 },
    { lat: 48.564005, lng: 17.874952 },
    { lat: 48.564057, lng: 17.875017 },
    { lat: 48.564127, lng: 17.875106 },
    { lat: 48.564173, lng: 17.875159 },
    { lat: 48.564254, lng: 17.875242 },
    { lat: 48.564325, lng: 17.875302 },
    { lat: 48.564418, lng: 17.875385 },
    { lat: 48.564495, lng: 17.87545 },
    { lat: 48.564609, lng: 17.875611 },
    { lat: 48.5642092, lng: 17.8745861 },
    { lat: 48.5641103, lng: 17.8738484 },
    { lat: 48.5635803, lng: 17.8728343 },
    { lat: 48.5635124, lng: 17.8722171 },
    { lat: 48.563563, lng: 17.8711506 },
    { lat: 48.5637455, lng: 17.8705485 },
    { lat: 48.5641097, lng: 17.870132 },
    { lat: 48.56468, lng: 17.8698005 },
    { lat: 48.5649926, lng: 17.8694075 },
    { lat: 48.5651608, lng: 17.8693691 },
    { lat: 48.5659179, lng: 17.8696637 },
    { lat: 48.566149, lng: 17.8696761 },
    { lat: 48.5666761, lng: 17.869079 },
    { lat: 48.5667274, lng: 17.8687505 },
    { lat: 48.5669813, lng: 17.8680426 },
    { lat: 48.566974, lng: 17.8665242 },
    { lat: 48.5670213, lng: 17.8663563 },
    { lat: 48.5673487, lng: 17.8658728 },
    { lat: 48.5672112, lng: 17.8655948 },
    { lat: 48.5675254, lng: 17.8647104 },
    { lat: 48.5677427, lng: 17.8638978 },
    { lat: 48.5673813, lng: 17.8635434 },
    { lat: 48.5674148, lng: 17.8632226 },
    { lat: 48.5675862, lng: 17.8628619 },
    { lat: 48.5677014, lng: 17.8627908 },
    { lat: 48.5682499, lng: 17.8618773 },
    { lat: 48.5683562, lng: 17.8616573 },
    { lat: 48.5684021, lng: 17.8614079 },
    { lat: 48.5685148, lng: 17.8612174 },
    { lat: 48.5685555, lng: 17.8609021 },
    { lat: 48.568819, lng: 17.8605393 },
    { lat: 48.5688358, lng: 17.8602946 },
    { lat: 48.5690897, lng: 17.859772 },
    { lat: 48.5689621, lng: 17.8596588 },
    { lat: 48.5690222, lng: 17.8594046 },
    { lat: 48.5677799, lng: 17.858911 },
    { lat: 48.5671871, lng: 17.8585717 },
    { lat: 48.5677894, lng: 17.8578942 },
    { lat: 48.5677171, lng: 17.857747 },
    { lat: 48.5684413, lng: 17.8564714 },
    { lat: 48.5683814, lng: 17.8563028 },
    { lat: 48.5684304, lng: 17.8562184 },
    { lat: 48.5684983, lng: 17.8563149 },
    { lat: 48.5688226, lng: 17.855949 },
    { lat: 48.5692219, lng: 17.8552007 },
    { lat: 48.5694134, lng: 17.854727 },
    { lat: 48.5693395, lng: 17.8546239 },
    { lat: 48.5696348, lng: 17.8537997 },
    { lat: 48.5698202, lng: 17.8530046 },
    { lat: 48.570116, lng: 17.8521147 },
    { lat: 48.5703057, lng: 17.8511825 },
    { lat: 48.5704516, lng: 17.8508362 },
    { lat: 48.5709053, lng: 17.8505748 },
    { lat: 48.5720917, lng: 17.8502952 },
    { lat: 48.5719256, lng: 17.8496986 },
    { lat: 48.5719219, lng: 17.8493587 },
    { lat: 48.5717213, lng: 17.8486033 },
    { lat: 48.5714102, lng: 17.8479793 },
    { lat: 48.5710108, lng: 17.8474805 },
    { lat: 48.5708639, lng: 17.846919 },
    { lat: 48.5707295, lng: 17.8467166 },
    { lat: 48.5699664, lng: 17.8449692 },
    { lat: 48.5699705, lng: 17.8445009 },
    { lat: 48.5695545, lng: 17.844137 },
    { lat: 48.5695423, lng: 17.8438821 },
    { lat: 48.5693655, lng: 17.8435914 },
    { lat: 48.5692697, lng: 17.8436111 },
    { lat: 48.5692956, lng: 17.8427196 },
    { lat: 48.5692562, lng: 17.8427215 },
    { lat: 48.5691773, lng: 17.8424818 },
    { lat: 48.5685001, lng: 17.8420785 },
    { lat: 48.568367, lng: 17.8406134 },
    { lat: 48.5681403, lng: 17.8395398 },
    { lat: 48.5672298, lng: 17.8375655 },
    { lat: 48.5667179, lng: 17.8368304 },
    { lat: 48.566175, lng: 17.8355179 },
    { lat: 48.5654753, lng: 17.8341858 },
    { lat: 48.5650294, lng: 17.8329622 },
    { lat: 48.5635448, lng: 17.8292553 },
    { lat: 48.5620964, lng: 17.8300334 },
    { lat: 48.5620439, lng: 17.831824 },
    { lat: 48.5594511, lng: 17.832901 },
    { lat: 48.5589183, lng: 17.8328846 },
    { lat: 48.5576322, lng: 17.83301 },
    { lat: 48.5577269, lng: 17.8342114 },
    { lat: 48.5576486, lng: 17.8350771 },
    { lat: 48.5574653, lng: 17.8354192 },
    { lat: 48.5563625, lng: 17.835499 },
    { lat: 48.5542343, lng: 17.8342541 },
    { lat: 48.5535187, lng: 17.8328832 },
    { lat: 48.5493201, lng: 17.8273588 },
    { lat: 48.5471454, lng: 17.822263 },
  ],
  Vrbové: [
    { lat: 48.6072654, lng: 17.7256221 },
    { lat: 48.6103469, lng: 17.7280952 },
    { lat: 48.6104289, lng: 17.7281237 },
    { lat: 48.6115225, lng: 17.7272593 },
    { lat: 48.611766, lng: 17.7271226 },
    { lat: 48.6126794, lng: 17.7278723 },
    { lat: 48.6108136, lng: 17.7316282 },
    { lat: 48.6131697, lng: 17.7335087 },
    { lat: 48.6119662, lng: 17.7359426 },
    { lat: 48.6133196, lng: 17.7370188 },
    { lat: 48.6124677, lng: 17.7387254 },
    { lat: 48.6128888, lng: 17.7392008 },
    { lat: 48.6132618, lng: 17.7384899 },
    { lat: 48.6143819, lng: 17.7363552 },
    { lat: 48.6144882, lng: 17.7362304 },
    { lat: 48.6146688, lng: 17.7363771 },
    { lat: 48.6148683, lng: 17.7359872 },
    { lat: 48.6150951, lng: 17.7353802 },
    { lat: 48.6152389, lng: 17.7352801 },
    { lat: 48.6156412, lng: 17.735761 },
    { lat: 48.6155414, lng: 17.7365211 },
    { lat: 48.6160362, lng: 17.7366907 },
    { lat: 48.6160734, lng: 17.7363233 },
    { lat: 48.6162248, lng: 17.7360701 },
    { lat: 48.6165476, lng: 17.7359752 },
    { lat: 48.6166696, lng: 17.7356113 },
    { lat: 48.6180991, lng: 17.7372979 },
    { lat: 48.6186751, lng: 17.7379756 },
    { lat: 48.6187813, lng: 17.7380321 },
    { lat: 48.6188227, lng: 17.7378793 },
    { lat: 48.6187674, lng: 17.737623 },
    { lat: 48.6188293, lng: 17.7374951 },
    { lat: 48.6189369, lng: 17.7375557 },
    { lat: 48.6189944, lng: 17.7374798 },
    { lat: 48.6190196, lng: 17.7370834 },
    { lat: 48.6190963, lng: 17.7371558 },
    { lat: 48.6193607, lng: 17.7369937 },
    { lat: 48.6193514, lng: 17.7366582 },
    { lat: 48.619257, lng: 17.7363749 },
    { lat: 48.6195918, lng: 17.7363675 },
    { lat: 48.619578, lng: 17.7360669 },
    { lat: 48.6196328, lng: 17.7357316 },
    { lat: 48.6195905, lng: 17.735523 },
    { lat: 48.6196914, lng: 17.7350718 },
    { lat: 48.6196371, lng: 17.7346472 },
    { lat: 48.6194839, lng: 17.7343622 },
    { lat: 48.619935, lng: 17.7321574 },
    { lat: 48.6205857, lng: 17.7312314 },
    { lat: 48.6208332, lng: 17.7302281 },
    { lat: 48.6209086, lng: 17.7303524 },
    { lat: 48.6212956, lng: 17.729529 },
    { lat: 48.6215836, lng: 17.7298008 },
    { lat: 48.6219464, lng: 17.7304464 },
    { lat: 48.6222394, lng: 17.7299809 },
    { lat: 48.6223744, lng: 17.7303595 },
    { lat: 48.6224465, lng: 17.7304122 },
    { lat: 48.62301, lng: 17.7299747 },
    { lat: 48.6231046, lng: 17.7297505 },
    { lat: 48.624392, lng: 17.7283847 },
    { lat: 48.6250155, lng: 17.7291033 },
    { lat: 48.6253931, lng: 17.7292376 },
    { lat: 48.6257729, lng: 17.7290964 },
    { lat: 48.6264799, lng: 17.7291395 },
    { lat: 48.6264843, lng: 17.7290471 },
    { lat: 48.6266034, lng: 17.7290368 },
    { lat: 48.6268445, lng: 17.7286421 },
    { lat: 48.6268192, lng: 17.7284554 },
    { lat: 48.6269544, lng: 17.7284303 },
    { lat: 48.6270085, lng: 17.7285016 },
    { lat: 48.6270646, lng: 17.7287171 },
    { lat: 48.6270238, lng: 17.7289716 },
    { lat: 48.6271838, lng: 17.7289267 },
    { lat: 48.6271685, lng: 17.7286783 },
    { lat: 48.627259, lng: 17.7286463 },
    { lat: 48.6275022, lng: 17.7287452 },
    { lat: 48.6274766, lng: 17.7283495 },
    { lat: 48.6275599, lng: 17.7282696 },
    { lat: 48.6277375, lng: 17.7283335 },
    { lat: 48.627771, lng: 17.7281981 },
    { lat: 48.6279058, lng: 17.728317 },
    { lat: 48.6277548, lng: 17.7285766 },
    { lat: 48.6277532, lng: 17.728676 },
    { lat: 48.6279195, lng: 17.7286302 },
    { lat: 48.6281307, lng: 17.7282268 },
    { lat: 48.6282924, lng: 17.7282127 },
    { lat: 48.6284285, lng: 17.7282949 },
    { lat: 48.6283292, lng: 17.728407 },
    { lat: 48.6283531, lng: 17.7284636 },
    { lat: 48.6284258, lng: 17.7284345 },
    { lat: 48.6287687, lng: 17.7281029 },
    { lat: 48.6288937, lng: 17.7278583 },
    { lat: 48.6290262, lng: 17.7277365 },
    { lat: 48.6291801, lng: 17.727762 },
    { lat: 48.6292489, lng: 17.727868 },
    { lat: 48.6293463, lng: 17.7278935 },
    { lat: 48.6293698, lng: 17.7277799 },
    { lat: 48.6294694, lng: 17.7277459 },
    { lat: 48.6295102, lng: 17.7276389 },
    { lat: 48.629792, lng: 17.7276135 },
    { lat: 48.6298177, lng: 17.7275357 },
    { lat: 48.6303295, lng: 17.7275968 },
    { lat: 48.6304399, lng: 17.727722 },
    { lat: 48.6306945, lng: 17.7276198 },
    { lat: 48.6309434, lng: 17.7272975 },
    { lat: 48.6309948, lng: 17.7273837 },
    { lat: 48.6309373, lng: 17.7275135 },
    { lat: 48.6309645, lng: 17.7275864 },
    { lat: 48.6311778, lng: 17.7276375 },
    { lat: 48.631312, lng: 17.7274159 },
    { lat: 48.6314658, lng: 17.7274592 },
    { lat: 48.6321614, lng: 17.7273329 },
    { lat: 48.6325297, lng: 17.726952 },
    { lat: 48.6327288, lng: 17.7270118 },
    { lat: 48.6329311, lng: 17.7267781 },
    { lat: 48.6330135, lng: 17.726557 },
    { lat: 48.6331877, lng: 17.7264904 },
    { lat: 48.6332371, lng: 17.7263785 },
    { lat: 48.6335133, lng: 17.7263525 },
    { lat: 48.6337166, lng: 17.7261508 },
    { lat: 48.6339905, lng: 17.7263636 },
    { lat: 48.6341197, lng: 17.7262073 },
    { lat: 48.6341038, lng: 17.7260043 },
    { lat: 48.6341428, lng: 17.7259042 },
    { lat: 48.634445, lng: 17.7258092 },
    { lat: 48.6349216, lng: 17.7255344 },
    { lat: 48.6349195, lng: 17.7254091 },
    { lat: 48.6350318, lng: 17.7252359 },
    { lat: 48.63522, lng: 17.7254332 },
    { lat: 48.6353739, lng: 17.7253816 },
    { lat: 48.6356249, lng: 17.7251118 },
    { lat: 48.6356869, lng: 17.7251662 },
    { lat: 48.6358874, lng: 17.7251409 },
    { lat: 48.6360573, lng: 17.7249503 },
    { lat: 48.6361623, lng: 17.724731 },
    { lat: 48.6361654, lng: 17.7246174 },
    { lat: 48.6360914, lng: 17.7245332 },
    { lat: 48.6360246, lng: 17.7245479 },
    { lat: 48.6358299, lng: 17.7241184 },
    { lat: 48.6359095, lng: 17.7239294 },
    { lat: 48.6361071, lng: 17.7237264 },
    { lat: 48.6361709, lng: 17.723717 },
    { lat: 48.6362207, lng: 17.723865 },
    { lat: 48.6364033, lng: 17.723971 },
    { lat: 48.6365083, lng: 17.7235311 },
    { lat: 48.6365725, lng: 17.723658 },
    { lat: 48.6366913, lng: 17.7235707 },
    { lat: 48.6370271, lng: 17.7235409 },
    { lat: 48.6372873, lng: 17.7233105 },
    { lat: 48.6374297, lng: 17.7235714 },
    { lat: 48.6377263, lng: 17.7233822 },
    { lat: 48.6375633, lng: 17.7231021 },
    { lat: 48.6376921, lng: 17.7230115 },
    { lat: 48.6376625, lng: 17.7228236 },
    { lat: 48.6377565, lng: 17.7225912 },
    { lat: 48.6380019, lng: 17.7227069 },
    { lat: 48.6379371, lng: 17.7222445 },
    { lat: 48.6382819, lng: 17.7220699 },
    { lat: 48.6385277, lng: 17.7213164 },
    { lat: 48.6388335, lng: 17.721459 },
    { lat: 48.638848, lng: 17.7212006 },
    { lat: 48.6388057, lng: 17.7210245 },
    { lat: 48.6386669, lng: 17.7208466 },
    { lat: 48.6386963, lng: 17.7205523 },
    { lat: 48.6391853, lng: 17.7203917 },
    { lat: 48.6392276, lng: 17.7204357 },
    { lat: 48.6393531, lng: 17.7204041 },
    { lat: 48.6394039, lng: 17.720272 },
    { lat: 48.6395304, lng: 17.7202367 },
    { lat: 48.6397868, lng: 17.7204584 },
    { lat: 48.6403033, lng: 17.7199566 },
    { lat: 48.6406884, lng: 17.7197217 },
    { lat: 48.641272, lng: 17.7194454 },
    { lat: 48.6414539, lng: 17.7195208 },
    { lat: 48.641914, lng: 17.7192554 },
    { lat: 48.641972, lng: 17.7190962 },
    { lat: 48.6421316, lng: 17.7189948 },
    { lat: 48.6421342, lng: 17.7187613 },
    { lat: 48.6422587, lng: 17.7186592 },
    { lat: 48.6427983, lng: 17.7184554 },
    { lat: 48.6428862, lng: 17.7182881 },
    { lat: 48.6431485, lng: 17.7184597 },
    { lat: 48.6433932, lng: 17.7182356 },
    { lat: 48.643608, lng: 17.71835 },
    { lat: 48.6438032, lng: 17.7182197 },
    { lat: 48.6439712, lng: 17.7183231 },
    { lat: 48.6442593, lng: 17.7182183 },
    { lat: 48.6444036, lng: 17.7179434 },
    { lat: 48.6449016, lng: 17.7180871 },
    { lat: 48.6450185, lng: 17.7179353 },
    { lat: 48.6451262, lng: 17.7179773 },
    { lat: 48.6452923, lng: 17.71789 },
    { lat: 48.6455545, lng: 17.7181276 },
    { lat: 48.645706, lng: 17.718041 },
    { lat: 48.645834, lng: 17.717647 },
    { lat: 48.6462706, lng: 17.7176612 },
    { lat: 48.6463834, lng: 17.7175871 },
    { lat: 48.646517, lng: 17.7176822 },
    { lat: 48.6465826, lng: 17.7176287 },
    { lat: 48.6468488, lng: 17.7177176 },
    { lat: 48.6469648, lng: 17.7176924 },
    { lat: 48.6470778, lng: 17.7176593 },
    { lat: 48.647456, lng: 17.7175121 },
    { lat: 48.6476491, lng: 17.7173296 },
    { lat: 48.6478655, lng: 17.716996 },
    { lat: 48.647955, lng: 17.7169809 },
    { lat: 48.6479451, lng: 17.7169658 },
    { lat: 48.64776, lng: 17.716567 },
    { lat: 48.6457325, lng: 17.713234 },
    { lat: 48.6447614, lng: 17.7116603 },
    { lat: 48.6446509, lng: 17.7115713 },
    { lat: 48.6407166, lng: 17.7146266 },
    { lat: 48.6374968, lng: 17.7110366 },
    { lat: 48.637181, lng: 17.7106207 },
    { lat: 48.6371626, lng: 17.7106525 },
    { lat: 48.636654, lng: 17.7100809 },
    { lat: 48.6364098, lng: 17.7100565 },
    { lat: 48.6355069, lng: 17.7088601 },
    { lat: 48.6354559, lng: 17.708656 },
    { lat: 48.6348539, lng: 17.7077938 },
    { lat: 48.6345637, lng: 17.7075545 },
    { lat: 48.6343258, lng: 17.7072172 },
    { lat: 48.6335776, lng: 17.7071663 },
    { lat: 48.6331984, lng: 17.7068768 },
    { lat: 48.6331456, lng: 17.7068985 },
    { lat: 48.6330766, lng: 17.7066987 },
    { lat: 48.6323234, lng: 17.7056952 },
    { lat: 48.6333654, lng: 17.7025766 },
    { lat: 48.6345237, lng: 17.699713 },
    { lat: 48.6353355, lng: 17.6982944 },
    { lat: 48.6359894, lng: 17.6974228 },
    { lat: 48.6354931, lng: 17.6961011 },
    { lat: 48.6349364, lng: 17.6953383 },
    { lat: 48.6361624, lng: 17.6934581 },
    { lat: 48.6365958, lng: 17.693092 },
    { lat: 48.6369631, lng: 17.6924454 },
    { lat: 48.6374997, lng: 17.6925016 },
    { lat: 48.6378444, lng: 17.6924368 },
    { lat: 48.6380731, lng: 17.6922828 },
    { lat: 48.6383377, lng: 17.6917242 },
    { lat: 48.6385813, lng: 17.6909751 },
    { lat: 48.6387913, lng: 17.6906112 },
    { lat: 48.6387753, lng: 17.6898624 },
    { lat: 48.6386776, lng: 17.6890158 },
    { lat: 48.6383593, lng: 17.6879984 },
    { lat: 48.6380674, lng: 17.6867152 },
    { lat: 48.6375145, lng: 17.6868862 },
    { lat: 48.6370867, lng: 17.6867076 },
    { lat: 48.6364299, lng: 17.686693 },
    { lat: 48.6361814, lng: 17.6865807 },
    { lat: 48.6360996, lng: 17.6864638 },
    { lat: 48.6360127, lng: 17.686028 },
    { lat: 48.6359005, lng: 17.6857876 },
    { lat: 48.6354958, lng: 17.6851948 },
    { lat: 48.6352052, lng: 17.68561 },
    { lat: 48.6347459, lng: 17.6865224 },
    { lat: 48.6346644, lng: 17.6863729 },
    { lat: 48.6344156, lng: 17.6862564 },
    { lat: 48.634351, lng: 17.6859239 },
    { lat: 48.6342547, lng: 17.6857034 },
    { lat: 48.6340395, lng: 17.6854929 },
    { lat: 48.6335017, lng: 17.6851413 },
    { lat: 48.6338646, lng: 17.6844749 },
    { lat: 48.6339892, lng: 17.6844462 },
    { lat: 48.634116, lng: 17.6841609 },
    { lat: 48.634071, lng: 17.6840334 },
    { lat: 48.6339204, lng: 17.6838837 },
    { lat: 48.6335314, lng: 17.6836746 },
    { lat: 48.6335115, lng: 17.6835361 },
    { lat: 48.6333905, lng: 17.6833718 },
    { lat: 48.6330214, lng: 17.6832078 },
    { lat: 48.6328985, lng: 17.6833204 },
    { lat: 48.632804, lng: 17.6833253 },
    { lat: 48.6319449, lng: 17.6841104 },
    { lat: 48.6317298, lng: 17.6840794 },
    { lat: 48.6314854, lng: 17.6841447 },
    { lat: 48.6314925, lng: 17.6844785 },
    { lat: 48.631278, lng: 17.6852759 },
    { lat: 48.6312074, lng: 17.6853584 },
    { lat: 48.6311773, lng: 17.6853116 },
    { lat: 48.6311104, lng: 17.6853585 },
    { lat: 48.6309783, lng: 17.6856167 },
    { lat: 48.6310321, lng: 17.6857173 },
    { lat: 48.6309372, lng: 17.685842 },
    { lat: 48.6308529, lng: 17.6861956 },
    { lat: 48.6305898, lng: 17.6865044 },
    { lat: 48.6304959, lng: 17.6869385 },
    { lat: 48.6300152, lng: 17.6873591 },
    { lat: 48.6298179, lng: 17.6871654 },
    { lat: 48.6296897, lng: 17.6872117 },
    { lat: 48.6296153, lng: 17.6873389 },
    { lat: 48.6286287, lng: 17.6895939 },
    { lat: 48.6283716, lng: 17.6905629 },
    { lat: 48.6283442, lng: 17.6909226 },
    { lat: 48.6279735, lng: 17.6908465 },
    { lat: 48.6268999, lng: 17.6899029 },
    { lat: 48.6266549, lng: 17.690394 },
    { lat: 48.6256606, lng: 17.6893635 },
    { lat: 48.6251745, lng: 17.6903741 },
    { lat: 48.6249555, lng: 17.6903183 },
    { lat: 48.6247672, lng: 17.6905783 },
    { lat: 48.6238867, lng: 17.6897805 },
    { lat: 48.6228701, lng: 17.6885395 },
    { lat: 48.6227795, lng: 17.6884449 },
    { lat: 48.6227403, lng: 17.6884836 },
    { lat: 48.6220428, lng: 17.6872336 },
    { lat: 48.6221914, lng: 17.6869724 },
    { lat: 48.6224649, lng: 17.6866671 },
    { lat: 48.6242929, lng: 17.6847976 },
    { lat: 48.6245625, lng: 17.6840839 },
    { lat: 48.6245885, lng: 17.6838657 },
    { lat: 48.6244778, lng: 17.6833374 },
    { lat: 48.6239604, lng: 17.6822618 },
    { lat: 48.6238447, lng: 17.6818352 },
    { lat: 48.6236729, lng: 17.6804537 },
    { lat: 48.6236717, lng: 17.6800877 },
    { lat: 48.6238256, lng: 17.679622 },
    { lat: 48.6238092, lng: 17.6794301 },
    { lat: 48.6237527, lng: 17.6794314 },
    { lat: 48.6236069, lng: 17.679084 },
    { lat: 48.6234342, lng: 17.6788865 },
    { lat: 48.6232603, lng: 17.6790471 },
    { lat: 48.6230747, lng: 17.6791851 },
    { lat: 48.6230371, lng: 17.6792892 },
    { lat: 48.6226203, lng: 17.6797051 },
    { lat: 48.6217083, lng: 17.6811257 },
    { lat: 48.6213807, lng: 17.6813594 },
    { lat: 48.6209446, lng: 17.681899 },
    { lat: 48.6208399, lng: 17.682068 },
    { lat: 48.6209831, lng: 17.6823739 },
    { lat: 48.6197403, lng: 17.6842695 },
    { lat: 48.619481, lng: 17.6840909 },
    { lat: 48.6192944, lng: 17.684233 },
    { lat: 48.6189963, lng: 17.6843247 },
    { lat: 48.6184484, lng: 17.6843366 },
    { lat: 48.6182558, lng: 17.6842532 },
    { lat: 48.6178877, lng: 17.6838881 },
    { lat: 48.6176939, lng: 17.6837699 },
    { lat: 48.6175846, lng: 17.6839912 },
    { lat: 48.6173263, lng: 17.6840684 },
    { lat: 48.6171672, lng: 17.6841959 },
    { lat: 48.617015, lng: 17.6844925 },
    { lat: 48.6168047, lng: 17.684706 },
    { lat: 48.6166965, lng: 17.6847427 },
    { lat: 48.6169802, lng: 17.6854927 },
    { lat: 48.6167079, lng: 17.6857107 },
    { lat: 48.6168038, lng: 17.685963 },
    { lat: 48.615998, lng: 17.6864933 },
    { lat: 48.615406, lng: 17.6867055 },
    { lat: 48.6140371, lng: 17.6868444 },
    { lat: 48.6136302, lng: 17.686731 },
    { lat: 48.6134343, lng: 17.6865407 },
    { lat: 48.6094074, lng: 17.6882737 },
    { lat: 48.6087384, lng: 17.6885384 },
    { lat: 48.6079296, lng: 17.6887505 },
    { lat: 48.6063831, lng: 17.6894985 },
    { lat: 48.605273, lng: 17.6898947 },
    { lat: 48.6033375, lng: 17.6910022 },
    { lat: 48.60265, lng: 17.6898607 },
    { lat: 48.6022259, lng: 17.6890127 },
    { lat: 48.6020954, lng: 17.6886565 },
    { lat: 48.5993903, lng: 17.690986 },
    { lat: 48.5986617, lng: 17.6917621 },
    { lat: 48.597409, lng: 17.6928981 },
    { lat: 48.5954556, lng: 17.695278 },
    { lat: 48.5951074, lng: 17.6957442 },
    { lat: 48.5948853, lng: 17.6962028 },
    { lat: 48.5939002, lng: 17.6952679 },
    { lat: 48.5931557, lng: 17.694441 },
    { lat: 48.5925608, lng: 17.6960985 },
    { lat: 48.5921391, lng: 17.6969998 },
    { lat: 48.5912661, lng: 17.6984155 },
    { lat: 48.5902732, lng: 17.7003716 },
    { lat: 48.5897818, lng: 17.7011317 },
    { lat: 48.5900711, lng: 17.7014083 },
    { lat: 48.5923206, lng: 17.7033984 },
    { lat: 48.5928765, lng: 17.7049735 },
    { lat: 48.5936682, lng: 17.7068162 },
    { lat: 48.5946803, lng: 17.7082437 },
    { lat: 48.5953013, lng: 17.7093543 },
    { lat: 48.5962092, lng: 17.7105772 },
    { lat: 48.5965916, lng: 17.7112467 },
    { lat: 48.5976132, lng: 17.7128128 },
    { lat: 48.5978881, lng: 17.7135307 },
    { lat: 48.5979074, lng: 17.7135704 },
    { lat: 48.5985575, lng: 17.7145861 },
    { lat: 48.599934, lng: 17.717235 },
    { lat: 48.600669, lng: 17.7185484 },
    { lat: 48.6026699, lng: 17.7215442 },
    { lat: 48.6035197, lng: 17.7224496 },
    { lat: 48.6044913, lng: 17.723045 },
    { lat: 48.6053664, lng: 17.7234667 },
    { lat: 48.6065734, lng: 17.7243448 },
    { lat: 48.6070661, lng: 17.7249897 },
    { lat: 48.6072654, lng: 17.7256221 },
  ],
  Sokolovce: [
    { lat: 48.539186, lng: 17.863175 },
    { lat: 48.5390318, lng: 17.8630531 },
    { lat: 48.5388379, lng: 17.8629797 },
    { lat: 48.5386456, lng: 17.862676 },
    { lat: 48.5384969, lng: 17.8620111 },
    { lat: 48.5382648, lng: 17.8614065 },
    { lat: 48.5380788, lng: 17.8606292 },
    { lat: 48.538369, lng: 17.8604453 },
    { lat: 48.5384849, lng: 17.8604375 },
    { lat: 48.5387331, lng: 17.8600643 },
    { lat: 48.5390676, lng: 17.8594767 },
    { lat: 48.5396638, lng: 17.8580089 },
    { lat: 48.5397511, lng: 17.8577173 },
    { lat: 48.5398371, lng: 17.8571472 },
    { lat: 48.539904, lng: 17.8562767 },
    { lat: 48.5400372, lng: 17.855791 },
    { lat: 48.5404451, lng: 17.8547206 },
    { lat: 48.5405436, lng: 17.853555 },
    { lat: 48.5398193, lng: 17.8518886 },
    { lat: 48.5397323, lng: 17.8511657 },
    { lat: 48.5395576, lng: 17.8484212 },
    { lat: 48.5394803, lng: 17.8481145 },
    { lat: 48.5391506, lng: 17.8474468 },
    { lat: 48.5391962, lng: 17.8473799 },
    { lat: 48.5389424, lng: 17.8466957 },
    { lat: 48.5390382, lng: 17.8459683 },
    { lat: 48.5391579, lng: 17.8456921 },
    { lat: 48.5394455, lng: 17.8454469 },
    { lat: 48.5398849, lng: 17.8453396 },
    { lat: 48.540265, lng: 17.8453894 },
    { lat: 48.5414628, lng: 17.8459273 },
    { lat: 48.5427268, lng: 17.8462752 },
    { lat: 48.5431693, lng: 17.8464737 },
    { lat: 48.5435926, lng: 17.8466637 },
    { lat: 48.5445939, lng: 17.8467379 },
    { lat: 48.5448544, lng: 17.8468623 },
    { lat: 48.5453376, lng: 17.8469588 },
    { lat: 48.5457547, lng: 17.8468848 },
    { lat: 48.546058, lng: 17.8468309 },
    { lat: 48.5462221, lng: 17.8467638 },
    { lat: 48.5464329, lng: 17.8464226 },
    { lat: 48.5471774, lng: 17.8458214 },
    { lat: 48.5474339, lng: 17.845491 },
    { lat: 48.5474901, lng: 17.8447814 },
    { lat: 48.5475782, lng: 17.8444293 },
    { lat: 48.5477693, lng: 17.8441375 },
    { lat: 48.5480632, lng: 17.8439755 },
    { lat: 48.5479729, lng: 17.8433662 },
    { lat: 48.5474947, lng: 17.8430581 },
    { lat: 48.5471882, lng: 17.8429383 },
    { lat: 48.5469535, lng: 17.8407148 },
    { lat: 48.5469564, lng: 17.8403339 },
    { lat: 48.5470443, lng: 17.8398507 },
    { lat: 48.5470072, lng: 17.8398516 },
    { lat: 48.5468109, lng: 17.8394287 },
    { lat: 48.5466077, lng: 17.8387018 },
    { lat: 48.5463052, lng: 17.8381123 },
    { lat: 48.5459609, lng: 17.8370931 },
    { lat: 48.5453615, lng: 17.833941 },
    { lat: 48.5448452, lng: 17.8336586 },
    { lat: 48.5440584, lng: 17.8312675 },
    { lat: 48.5421665, lng: 17.8284557 },
    { lat: 48.5414746, lng: 17.8270331 },
    { lat: 48.5417013, lng: 17.8243287 },
    { lat: 48.5411665, lng: 17.8226504 },
    { lat: 48.5408547, lng: 17.822368 },
    { lat: 48.5403177, lng: 17.8218752 },
    { lat: 48.5394192, lng: 17.822821 },
    { lat: 48.5381759, lng: 17.8238338 },
    { lat: 48.5372346, lng: 17.8234447 },
    { lat: 48.5324967, lng: 17.827555 },
    { lat: 48.5321349, lng: 17.82775 },
    { lat: 48.5312418, lng: 17.8277101 },
    { lat: 48.5307479, lng: 17.8274628 },
    { lat: 48.5297653, lng: 17.826584 },
    { lat: 48.52945, lng: 17.8262087 },
    { lat: 48.5292, lng: 17.8257215 },
    { lat: 48.5289356, lng: 17.8254205 },
    { lat: 48.5286461, lng: 17.8248939 },
    { lat: 48.5286008, lng: 17.8246316 },
    { lat: 48.5283385, lng: 17.8243495 },
    { lat: 48.5284598, lng: 17.8239491 },
    { lat: 48.5284261, lng: 17.8235246 },
    { lat: 48.5277827, lng: 17.8220299 },
    { lat: 48.5273171, lng: 17.8202673 },
    { lat: 48.5262498, lng: 17.8209414 },
    { lat: 48.5249241, lng: 17.8236004 },
    { lat: 48.5239818, lng: 17.825824 },
    { lat: 48.522272, lng: 17.8257596 },
    { lat: 48.5241506, lng: 17.8204898 },
    { lat: 48.5213418, lng: 17.8206165 },
    { lat: 48.519775, lng: 17.822613 },
    { lat: 48.519651, lng: 17.822872 },
    { lat: 48.519564, lng: 17.823056 },
    { lat: 48.519462, lng: 17.823274 },
    { lat: 48.519421, lng: 17.823361 },
    { lat: 48.519416, lng: 17.823371 },
    { lat: 48.51941, lng: 17.823383 },
    { lat: 48.519404, lng: 17.823396 },
    { lat: 48.519375, lng: 17.823455 },
    { lat: 48.519342, lng: 17.823519 },
    { lat: 48.519172, lng: 17.823862 },
    { lat: 48.518901, lng: 17.824706 },
    { lat: 48.518672, lng: 17.825424 },
    { lat: 48.51856, lng: 17.825923 },
    { lat: 48.518546, lng: 17.825985 },
    { lat: 48.518813, lng: 17.826472 },
    { lat: 48.518985, lng: 17.826781 },
    { lat: 48.519055, lng: 17.826906 },
    { lat: 48.519208, lng: 17.82702 },
    { lat: 48.519194, lng: 17.827133 },
    { lat: 48.519293, lng: 17.827239 },
    { lat: 48.519372, lng: 17.827371 },
    { lat: 48.519498, lng: 17.827589 },
    { lat: 48.519548, lng: 17.827759 },
    { lat: 48.51962, lng: 17.828113 },
    { lat: 48.519633, lng: 17.828319 },
    { lat: 48.519672, lng: 17.828645 },
    { lat: 48.519727, lng: 17.82927 },
    { lat: 48.519736, lng: 17.829342 },
    { lat: 48.519804, lng: 17.830237 },
    { lat: 48.520234, lng: 17.830509 },
    { lat: 48.520288, lng: 17.8309 },
    { lat: 48.52052, lng: 17.831588 },
    { lat: 48.5206, lng: 17.831737 },
    { lat: 48.52074, lng: 17.831931 },
    { lat: 48.521091, lng: 17.831791 },
    { lat: 48.521166, lng: 17.832003 },
    { lat: 48.521321, lng: 17.832336 },
    { lat: 48.521448, lng: 17.832552 },
    { lat: 48.521465, lng: 17.832768 },
    { lat: 48.521468, lng: 17.832812 },
    { lat: 48.521545, lng: 17.833008 },
    { lat: 48.521803, lng: 17.833705 },
    { lat: 48.521705, lng: 17.83375 },
    { lat: 48.521872, lng: 17.834241 },
    { lat: 48.522002, lng: 17.834624 },
    { lat: 48.52179, lng: 17.834987 },
    { lat: 48.521772, lng: 17.835017 },
    { lat: 48.521691, lng: 17.835154 },
    { lat: 48.521541, lng: 17.835578 },
    { lat: 48.521569, lng: 17.836057 },
    { lat: 48.521522, lng: 17.836933 },
    { lat: 48.521474, lng: 17.837816 },
    { lat: 48.520994, lng: 17.837546 },
    { lat: 48.520869, lng: 17.83832 },
    { lat: 48.520444, lng: 17.838845 },
    { lat: 48.520232, lng: 17.839572 },
    { lat: 48.52009, lng: 17.839978 },
    { lat: 48.519884, lng: 17.840535 },
    { lat: 48.519791, lng: 17.840826 },
    { lat: 48.519589, lng: 17.841451 },
    { lat: 48.51932, lng: 17.842371 },
    { lat: 48.519871, lng: 17.842758 },
    { lat: 48.520094, lng: 17.842833 },
    { lat: 48.520276, lng: 17.843045 },
    { lat: 48.520579, lng: 17.843368 },
    { lat: 48.520762, lng: 17.843788 },
    { lat: 48.520841, lng: 17.844086 },
    { lat: 48.520836, lng: 17.844537 },
    { lat: 48.520991, lng: 17.844854 },
    { lat: 48.521519, lng: 17.84549 },
    { lat: 48.521565, lng: 17.845534 },
    { lat: 48.5216, lng: 17.845615 },
    { lat: 48.521611, lng: 17.845647 },
    { lat: 48.521803, lng: 17.846212 },
    { lat: 48.521902, lng: 17.846505 },
    { lat: 48.522002, lng: 17.846803 },
    { lat: 48.52211, lng: 17.847121 },
    { lat: 48.522215, lng: 17.847431 },
    { lat: 48.522319, lng: 17.847737 },
    { lat: 48.522425, lng: 17.848052 },
    { lat: 48.522501, lng: 17.848276 },
    { lat: 48.52255, lng: 17.848366 },
    { lat: 48.522735, lng: 17.848699 },
    { lat: 48.522791, lng: 17.848798 },
    { lat: 48.522872, lng: 17.848981 },
    { lat: 48.52301, lng: 17.849289 },
    { lat: 48.523065, lng: 17.849414 },
    { lat: 48.523158, lng: 17.849593 },
    { lat: 48.523223, lng: 17.849723 },
    { lat: 48.523308, lng: 17.849889 },
    { lat: 48.523625, lng: 17.850821 },
    { lat: 48.523874, lng: 17.851503 },
    { lat: 48.523882, lng: 17.851525 },
    { lat: 48.523954, lng: 17.851746 },
    { lat: 48.524007, lng: 17.851904 },
    { lat: 48.524314, lng: 17.852636 },
    { lat: 48.524619, lng: 17.853422 },
    { lat: 48.524608, lng: 17.853921 },
    { lat: 48.524523, lng: 17.854457 },
    { lat: 48.524493, lng: 17.854868 },
    { lat: 48.524387, lng: 17.855211 },
    { lat: 48.524435, lng: 17.85572 },
    { lat: 48.524496, lng: 17.8557 },
    { lat: 48.52451, lng: 17.855696 },
    { lat: 48.524787, lng: 17.85583 },
    { lat: 48.524915, lng: 17.855943 },
    { lat: 48.524945, lng: 17.85597 },
    { lat: 48.524968, lng: 17.85599 },
    { lat: 48.525503, lng: 17.856471 },
    { lat: 48.525576, lng: 17.856565 },
    { lat: 48.52611, lng: 17.857255 },
    { lat: 48.526404, lng: 17.857632 },
    { lat: 48.526506, lng: 17.857763 },
    { lat: 48.526697, lng: 17.858258 },
    { lat: 48.527085, lng: 17.859051 },
    { lat: 48.527183, lng: 17.85925 },
    { lat: 48.527493, lng: 17.859523 },
    { lat: 48.52756, lng: 17.859582 },
    { lat: 48.527997, lng: 17.860047 },
    { lat: 48.528214, lng: 17.860283 },
    { lat: 48.528844, lng: 17.861049 },
    { lat: 48.528916, lng: 17.86113 },
    { lat: 48.529141, lng: 17.861412 },
    { lat: 48.529098, lng: 17.861496 },
    { lat: 48.529237, lng: 17.861598 },
    { lat: 48.529389, lng: 17.861653 },
    { lat: 48.529562, lng: 17.861837 },
    { lat: 48.529629, lng: 17.861982 },
    { lat: 48.529791, lng: 17.862143 },
    { lat: 48.530152, lng: 17.862428 },
    { lat: 48.530409, lng: 17.862763 },
    { lat: 48.530621, lng: 17.862964 },
    { lat: 48.530803, lng: 17.863223 },
    { lat: 48.53112, lng: 17.863608 },
    { lat: 48.531283, lng: 17.863806 },
    { lat: 48.531408, lng: 17.863581 },
    { lat: 48.531517, lng: 17.863386 },
    { lat: 48.531573, lng: 17.863252 },
    { lat: 48.531625, lng: 17.863132 },
    { lat: 48.531809, lng: 17.862864 },
    { lat: 48.531976, lng: 17.862662 },
    { lat: 48.53207, lng: 17.862614 },
    { lat: 48.532214, lng: 17.862242 },
    { lat: 48.532335, lng: 17.862146 },
    { lat: 48.532729, lng: 17.861806 },
    { lat: 48.532875, lng: 17.862014 },
    { lat: 48.532943, lng: 17.862107 },
    { lat: 48.533096, lng: 17.862268 },
    { lat: 48.533251, lng: 17.862529 },
    { lat: 48.533448, lng: 17.862836 },
    { lat: 48.533575, lng: 17.862992 },
    { lat: 48.533796, lng: 17.863345 },
    { lat: 48.533538, lng: 17.863786 },
    { lat: 48.533119, lng: 17.864504 },
    { lat: 48.532757, lng: 17.865122 },
    { lat: 48.533161, lng: 17.865143 },
    { lat: 48.533627, lng: 17.865167 },
    { lat: 48.533784, lng: 17.865174 },
    { lat: 48.534025, lng: 17.865186 },
    { lat: 48.534475, lng: 17.865207 },
    { lat: 48.534947, lng: 17.865228 },
    { lat: 48.535427, lng: 17.86525 },
    { lat: 48.535895, lng: 17.865272 },
    { lat: 48.535902, lng: 17.865273 },
    { lat: 48.535966, lng: 17.865277 },
    { lat: 48.536425, lng: 17.865163 },
    { lat: 48.536511, lng: 17.865191 },
    { lat: 48.536559, lng: 17.865284 },
    { lat: 48.536641, lng: 17.865359 },
    { lat: 48.536736, lng: 17.86541 },
    { lat: 48.53676, lng: 17.865562 },
    { lat: 48.536783, lng: 17.865567 },
    { lat: 48.53688, lng: 17.865542 },
    { lat: 48.537026, lng: 17.865604 },
    { lat: 48.537348, lng: 17.865523 },
    { lat: 48.537403, lng: 17.865463 },
    { lat: 48.537414, lng: 17.865469 },
    { lat: 48.53752, lng: 17.865492 },
    { lat: 48.538516, lng: 17.865726 },
    { lat: 48.538535, lng: 17.865678 },
    { lat: 48.538628, lng: 17.865441 },
    { lat: 48.538672, lng: 17.86535 },
    { lat: 48.538981, lng: 17.864724 },
    { lat: 48.539103, lng: 17.864478 },
    { lat: 48.539159, lng: 17.864312 },
    { lat: 48.539187, lng: 17.864075 },
    { lat: 48.539204, lng: 17.863601 },
    { lat: 48.539216, lng: 17.863292 },
    { lat: 48.539186, lng: 17.863175 },
  ],
  Veselé: [
    { lat: 48.5615212, lng: 17.7791758 },
    { lat: 48.5615382, lng: 17.7787711 },
    { lat: 48.5615376, lng: 17.7780626 },
    { lat: 48.5613617, lng: 17.7746096 },
    { lat: 48.5613404, lng: 17.7731263 },
    { lat: 48.5614853, lng: 17.7682639 },
    { lat: 48.5613409, lng: 17.7670548 },
    { lat: 48.5613333, lng: 17.7665465 },
    { lat: 48.561404, lng: 17.7637302 },
    { lat: 48.5613909, lng: 17.7612065 },
    { lat: 48.5614546, lng: 17.7595242 },
    { lat: 48.5608068, lng: 17.7585594 },
    { lat: 48.5602043, lng: 17.7568964 },
    { lat: 48.5596828, lng: 17.7557779 },
    { lat: 48.5595753, lng: 17.7553146 },
    { lat: 48.5589558, lng: 17.7548103 },
    { lat: 48.5583955, lng: 17.7546745 },
    { lat: 48.5581898, lng: 17.7547079 },
    { lat: 48.5573424, lng: 17.7546083 },
    { lat: 48.556708, lng: 17.7544247 },
    { lat: 48.5564478, lng: 17.7541236 },
    { lat: 48.5561526, lng: 17.753621 },
    { lat: 48.5553937, lng: 17.7525736 },
    { lat: 48.5548316, lng: 17.7516946 },
    { lat: 48.5547139, lng: 17.7513051 },
    { lat: 48.5544816, lng: 17.7509071 },
    { lat: 48.5543587, lng: 17.750506 },
    { lat: 48.5542713, lng: 17.7495674 },
    { lat: 48.554196, lng: 17.7494047 },
    { lat: 48.5540935, lng: 17.748568 },
    { lat: 48.5539599, lng: 17.7479741 },
    { lat: 48.5541243, lng: 17.7454961 },
    { lat: 48.5542332, lng: 17.7449031 },
    { lat: 48.5541055, lng: 17.7447161 },
    { lat: 48.5541067, lng: 17.744114 },
    { lat: 48.5543477, lng: 17.7428258 },
    { lat: 48.5544225, lng: 17.7418788 },
    { lat: 48.5543132, lng: 17.7404187 },
    { lat: 48.5543263, lng: 17.7394967 },
    { lat: 48.554424, lng: 17.7387909 },
    { lat: 48.554564, lng: 17.738519 },
    { lat: 48.5546299, lng: 17.7381412 },
    { lat: 48.5545535, lng: 17.7378397 },
    { lat: 48.554556, lng: 17.7372364 },
    { lat: 48.5546836, lng: 17.7369459 },
    { lat: 48.55482, lng: 17.7367796 },
    { lat: 48.5547156, lng: 17.736204 },
    { lat: 48.5547105, lng: 17.7358844 },
    { lat: 48.5547793, lng: 17.7357528 },
    { lat: 48.5547849, lng: 17.7354609 },
    { lat: 48.5551905, lng: 17.7344058 },
    { lat: 48.5565426, lng: 17.7299233 },
    { lat: 48.5571997, lng: 17.7275302 },
    { lat: 48.5577083, lng: 17.7251642 },
    { lat: 48.5581576, lng: 17.7236505 },
    { lat: 48.5599418, lng: 17.7193324 },
    { lat: 48.5602699, lng: 17.7181211 },
    { lat: 48.5614362, lng: 17.7147695 },
    { lat: 48.5619422, lng: 17.7131674 },
    { lat: 48.5626528, lng: 17.7106051 },
    { lat: 48.5629289, lng: 17.7098868 },
    { lat: 48.5634206, lng: 17.7082389 },
    { lat: 48.5636275, lng: 17.7078066 },
    { lat: 48.5638163, lng: 17.7071352 },
    { lat: 48.5642284, lng: 17.7060241 },
    { lat: 48.5643643, lng: 17.7057238 },
    { lat: 48.5650817, lng: 17.7047483 },
    { lat: 48.5665991, lng: 17.7023443 },
    { lat: 48.5666828, lng: 17.7021111 },
    { lat: 48.5666548, lng: 17.7020745 },
    { lat: 48.566929, lng: 17.7016216 },
    { lat: 48.5672535, lng: 17.7002818 },
    { lat: 48.5673951, lng: 17.6999057 },
    { lat: 48.5678796, lng: 17.6987622 },
    { lat: 48.5682456, lng: 17.6980761 },
    { lat: 48.5689403, lng: 17.6970441 },
    { lat: 48.5699569, lng: 17.6951959 },
    { lat: 48.5718207, lng: 17.6925546 },
    { lat: 48.5722247, lng: 17.6917139 },
    { lat: 48.5727621, lng: 17.6908248 },
    { lat: 48.5732066, lng: 17.690454 },
    { lat: 48.5736703, lng: 17.6897761 },
    { lat: 48.5742622, lng: 17.6890903 },
    { lat: 48.5751796, lng: 17.6873563 },
    { lat: 48.575433, lng: 17.686786 },
    { lat: 48.5757668, lng: 17.6858346 },
    { lat: 48.5759532, lng: 17.6854118 },
    { lat: 48.5763219, lng: 17.6845753 },
    { lat: 48.5782542, lng: 17.6809501 },
    { lat: 48.5798193, lng: 17.6782324 },
    { lat: 48.5813089, lng: 17.6751917 },
    { lat: 48.5818405, lng: 17.6739372 },
    { lat: 48.5803131, lng: 17.6718587 },
    { lat: 48.5791453, lng: 17.6700407 },
    { lat: 48.5787547, lng: 17.6692096 },
    { lat: 48.5767762, lng: 17.6655111 },
    { lat: 48.5767722, lng: 17.6654989 },
    { lat: 48.5765939, lng: 17.6657166 },
    { lat: 48.5764397, lng: 17.6658897 },
    { lat: 48.5656536, lng: 17.6820071 },
    { lat: 48.5646918, lng: 17.6809574 },
    { lat: 48.5617562, lng: 17.6832255 },
    { lat: 48.5597633, lng: 17.6856589 },
    { lat: 48.5596314, lng: 17.6857327 },
    { lat: 48.5595184, lng: 17.6858941 },
    { lat: 48.5592875, lng: 17.6861955 },
    { lat: 48.5577875, lng: 17.6880037 },
    { lat: 48.5570062, lng: 17.6887435 },
    { lat: 48.5563281, lng: 17.6896607 },
    { lat: 48.5560343, lng: 17.6901495 },
    { lat: 48.55582, lng: 17.6905351 },
    { lat: 48.5536406, lng: 17.6958311 },
    { lat: 48.5513835, lng: 17.7023584 },
    { lat: 48.5489992, lng: 17.7072211 },
    { lat: 48.5476838, lng: 17.709606 },
    { lat: 48.5463137, lng: 17.7118537 },
    { lat: 48.5426486, lng: 17.7188665 },
    { lat: 48.5405648, lng: 17.7225209 },
    { lat: 48.5404296, lng: 17.7224159 },
    { lat: 48.5400647, lng: 17.7230115 },
    { lat: 48.5388262, lng: 17.7255497 },
    { lat: 48.5381233, lng: 17.7267093 },
    { lat: 48.5362903, lng: 17.7304582 },
    { lat: 48.5357609, lng: 17.7317754 },
    { lat: 48.5355029, lng: 17.7325512 },
    { lat: 48.5353125, lng: 17.7332669 },
    { lat: 48.5353354, lng: 17.733346 },
    { lat: 48.5352733, lng: 17.7333832 },
    { lat: 48.5352496, lng: 17.7335461 },
    { lat: 48.5350389, lng: 17.733794 },
    { lat: 48.5349442, lng: 17.734015 },
    { lat: 48.534931, lng: 17.7342366 },
    { lat: 48.534569, lng: 17.7348107 },
    { lat: 48.5344975, lng: 17.7348599 },
    { lat: 48.5342021, lng: 17.7347919 },
    { lat: 48.5338154, lng: 17.7351692 },
    { lat: 48.5341461, lng: 17.7353676 },
    { lat: 48.5344516, lng: 17.735924 },
    { lat: 48.5331953, lng: 17.7436076 },
    { lat: 48.5338249, lng: 17.7438219 },
    { lat: 48.53361, lng: 17.7458454 },
    { lat: 48.5327264, lng: 17.7512882 },
    { lat: 48.5327296, lng: 17.7513401 },
    { lat: 48.5329024, lng: 17.7514335 },
    { lat: 48.5353211, lng: 17.752812 },
    { lat: 48.5356529, lng: 17.7530641 },
    { lat: 48.5378079, lng: 17.7550015 },
    { lat: 48.5405414, lng: 17.7573328 },
    { lat: 48.5422811, lng: 17.7589246 },
    { lat: 48.5423074, lng: 17.7590602 },
    { lat: 48.5427133, lng: 17.7594001 },
    { lat: 48.5448051, lng: 17.7615588 },
    { lat: 48.5448043, lng: 17.7618226 },
    { lat: 48.5488489, lng: 17.7614804 },
    { lat: 48.5488547, lng: 17.7615369 },
    { lat: 48.5491853, lng: 17.7617373 },
    { lat: 48.5500808, lng: 17.762749 },
    { lat: 48.5501987, lng: 17.7631434 },
    { lat: 48.5504525, lng: 17.7632305 },
    { lat: 48.550892, lng: 17.7632714 },
    { lat: 48.5511532, lng: 17.7631494 },
    { lat: 48.5525192, lng: 17.762872 },
    { lat: 48.552854, lng: 17.7628841 },
    { lat: 48.5533326, lng: 17.7629922 },
    { lat: 48.5541414, lng: 17.7630182 },
    { lat: 48.554512, lng: 17.7631311 },
    { lat: 48.5550297, lng: 17.7636019 },
    { lat: 48.5550893, lng: 17.7638355 },
    { lat: 48.5551168, lng: 17.7650555 },
    { lat: 48.5551057, lng: 17.7682516 },
    { lat: 48.5548707, lng: 17.7686692 },
    { lat: 48.5549818, lng: 17.7708076 },
    { lat: 48.5551471, lng: 17.7728009 },
    { lat: 48.5550973, lng: 17.7748737 },
    { lat: 48.5545799, lng: 17.7769657 },
    { lat: 48.5545273, lng: 17.7782039 },
    { lat: 48.5545899, lng: 17.779259 },
    { lat: 48.5549794, lng: 17.779075 },
    { lat: 48.5554574, lng: 17.7793583 },
    { lat: 48.5556621, lng: 17.7797328 },
    { lat: 48.5561528, lng: 17.7796883 },
    { lat: 48.5561726, lng: 17.7789087 },
    { lat: 48.5562823, lng: 17.778175 },
    { lat: 48.556361, lng: 17.7781159 },
    { lat: 48.5567461, lng: 17.7786855 },
    { lat: 48.5572035, lng: 17.7783937 },
    { lat: 48.5579145, lng: 17.7783723 },
    { lat: 48.5580084, lng: 17.7780994 },
    { lat: 48.5574511, lng: 17.7762854 },
    { lat: 48.5575033, lng: 17.7754638 },
    { lat: 48.5577397, lng: 17.775526 },
    { lat: 48.557958, lng: 17.7756983 },
    { lat: 48.558158, lng: 17.7757584 },
    { lat: 48.5590814, lng: 17.7758813 },
    { lat: 48.5592592, lng: 17.7767005 },
    { lat: 48.5590967, lng: 17.7773094 },
    { lat: 48.5590366, lng: 17.7781147 },
    { lat: 48.5592968, lng: 17.7784337 },
    { lat: 48.5595327, lng: 17.7785805 },
    { lat: 48.559936, lng: 17.7786422 },
    { lat: 48.5601727, lng: 17.7779923 },
    { lat: 48.5603063, lng: 17.7773906 },
    { lat: 48.5607399, lng: 17.7770629 },
    { lat: 48.5609889, lng: 17.7771451 },
    { lat: 48.5611209, lng: 17.7777119 },
    { lat: 48.5611077, lng: 17.7786953 },
    { lat: 48.5612316, lng: 17.7789293 },
    { lat: 48.5614611, lng: 17.7790987 },
    { lat: 48.5615212, lng: 17.7791758 },
  ],
  Krakovany: [
    { lat: 48.6120646, lng: 17.7739926 },
    { lat: 48.6121801, lng: 17.7729682 },
    { lat: 48.6123116, lng: 17.7715991 },
    { lat: 48.6132289, lng: 17.7718078 },
    { lat: 48.6136814, lng: 17.7716964 },
    { lat: 48.6168284, lng: 17.7720348 },
    { lat: 48.6175603, lng: 17.772335 },
    { lat: 48.6180706, lng: 17.7727747 },
    { lat: 48.6188959, lng: 17.7732357 },
    { lat: 48.6192808, lng: 17.7733461 },
    { lat: 48.6193808, lng: 17.7719195 },
    { lat: 48.6196159, lng: 17.7702502 },
    { lat: 48.619928, lng: 17.7669684 },
    { lat: 48.6211756, lng: 17.7671355 },
    { lat: 48.6217737, lng: 17.7675529 },
    { lat: 48.6220912, lng: 17.7670257 },
    { lat: 48.6221717, lng: 17.7666994 },
    { lat: 48.6223083, lng: 17.7658535 },
    { lat: 48.6223699, lng: 17.7648189 },
    { lat: 48.6224066, lng: 17.763668 },
    { lat: 48.6219261, lng: 17.7632181 },
    { lat: 48.6219799, lng: 17.7629918 },
    { lat: 48.6225265, lng: 17.7621856 },
    { lat: 48.6244503, lng: 17.7622038 },
    { lat: 48.624906, lng: 17.7603121 },
    { lat: 48.6267532, lng: 17.7526726 },
    { lat: 48.6269442, lng: 17.7524739 },
    { lat: 48.628047, lng: 17.7497544 },
    { lat: 48.6325695, lng: 17.7394509 },
    { lat: 48.6379221, lng: 17.7292751 },
    { lat: 48.6384149, lng: 17.7285347 },
    { lat: 48.6393775, lng: 17.7273916 },
    { lat: 48.6405773, lng: 17.7266003 },
    { lat: 48.6424588, lng: 17.7255485 },
    { lat: 48.6428095, lng: 17.7253049 },
    { lat: 48.6420693, lng: 17.723066 },
    { lat: 48.6414699, lng: 17.7216977 },
    { lat: 48.6412414, lng: 17.7210512 },
    { lat: 48.6408548, lng: 17.7197469 },
    { lat: 48.6406884, lng: 17.7197217 },
    { lat: 48.6403033, lng: 17.7199566 },
    { lat: 48.6397868, lng: 17.7204584 },
    { lat: 48.6395304, lng: 17.7202367 },
    { lat: 48.6394039, lng: 17.720272 },
    { lat: 48.6393531, lng: 17.7204041 },
    { lat: 48.6392276, lng: 17.7204357 },
    { lat: 48.6391853, lng: 17.7203917 },
    { lat: 48.6386963, lng: 17.7205523 },
    { lat: 48.6386669, lng: 17.7208466 },
    { lat: 48.6388057, lng: 17.7210245 },
    { lat: 48.638848, lng: 17.7212006 },
    { lat: 48.6388335, lng: 17.721459 },
    { lat: 48.6385277, lng: 17.7213164 },
    { lat: 48.6382819, lng: 17.7220699 },
    { lat: 48.6379371, lng: 17.7222445 },
    { lat: 48.6380019, lng: 17.7227069 },
    { lat: 48.6377565, lng: 17.7225912 },
    { lat: 48.6376625, lng: 17.7228236 },
    { lat: 48.6376921, lng: 17.7230115 },
    { lat: 48.6375633, lng: 17.7231021 },
    { lat: 48.6377263, lng: 17.7233822 },
    { lat: 48.6374297, lng: 17.7235714 },
    { lat: 48.6372873, lng: 17.7233105 },
    { lat: 48.6370271, lng: 17.7235409 },
    { lat: 48.6366913, lng: 17.7235707 },
    { lat: 48.6365725, lng: 17.723658 },
    { lat: 48.6365083, lng: 17.7235311 },
    { lat: 48.6364033, lng: 17.723971 },
    { lat: 48.6362207, lng: 17.723865 },
    { lat: 48.6361709, lng: 17.723717 },
    { lat: 48.6361071, lng: 17.7237264 },
    { lat: 48.6359095, lng: 17.7239294 },
    { lat: 48.6358299, lng: 17.7241184 },
    { lat: 48.6360246, lng: 17.7245479 },
    { lat: 48.6360914, lng: 17.7245332 },
    { lat: 48.6361654, lng: 17.7246174 },
    { lat: 48.6361623, lng: 17.724731 },
    { lat: 48.6360573, lng: 17.7249503 },
    { lat: 48.6358874, lng: 17.7251409 },
    { lat: 48.6356869, lng: 17.7251662 },
    { lat: 48.6356249, lng: 17.7251118 },
    { lat: 48.6353739, lng: 17.7253816 },
    { lat: 48.63522, lng: 17.7254332 },
    { lat: 48.6350318, lng: 17.7252359 },
    { lat: 48.6349195, lng: 17.7254091 },
    { lat: 48.6349216, lng: 17.7255344 },
    { lat: 48.634445, lng: 17.7258092 },
    { lat: 48.6341428, lng: 17.7259042 },
    { lat: 48.6341038, lng: 17.7260043 },
    { lat: 48.6341197, lng: 17.7262073 },
    { lat: 48.6339905, lng: 17.7263636 },
    { lat: 48.6337166, lng: 17.7261508 },
    { lat: 48.6335133, lng: 17.7263525 },
    { lat: 48.6332371, lng: 17.7263785 },
    { lat: 48.6331877, lng: 17.7264904 },
    { lat: 48.6330135, lng: 17.726557 },
    { lat: 48.6329311, lng: 17.7267781 },
    { lat: 48.6327288, lng: 17.7270118 },
    { lat: 48.6325297, lng: 17.726952 },
    { lat: 48.6321614, lng: 17.7273329 },
    { lat: 48.6314658, lng: 17.7274592 },
    { lat: 48.631312, lng: 17.7274159 },
    { lat: 48.6311778, lng: 17.7276375 },
    { lat: 48.6309645, lng: 17.7275864 },
    { lat: 48.6309373, lng: 17.7275135 },
    { lat: 48.6309948, lng: 17.7273837 },
    { lat: 48.6309434, lng: 17.7272975 },
    { lat: 48.6306945, lng: 17.7276198 },
    { lat: 48.6304399, lng: 17.727722 },
    { lat: 48.6303295, lng: 17.7275968 },
    { lat: 48.6298177, lng: 17.7275357 },
    { lat: 48.629792, lng: 17.7276135 },
    { lat: 48.6295102, lng: 17.7276389 },
    { lat: 48.6294694, lng: 17.7277459 },
    { lat: 48.6293698, lng: 17.7277799 },
    { lat: 48.6293463, lng: 17.7278935 },
    { lat: 48.6292489, lng: 17.727868 },
    { lat: 48.6291801, lng: 17.727762 },
    { lat: 48.6290262, lng: 17.7277365 },
    { lat: 48.6288937, lng: 17.7278583 },
    { lat: 48.6287687, lng: 17.7281029 },
    { lat: 48.6284258, lng: 17.7284345 },
    { lat: 48.6283531, lng: 17.7284636 },
    { lat: 48.6283292, lng: 17.728407 },
    { lat: 48.6284285, lng: 17.7282949 },
    { lat: 48.6282924, lng: 17.7282127 },
    { lat: 48.6281307, lng: 17.7282268 },
    { lat: 48.6279195, lng: 17.7286302 },
    { lat: 48.6277532, lng: 17.728676 },
    { lat: 48.6277548, lng: 17.7285766 },
    { lat: 48.6279058, lng: 17.728317 },
    { lat: 48.627771, lng: 17.7281981 },
    { lat: 48.6277375, lng: 17.7283335 },
    { lat: 48.6275599, lng: 17.7282696 },
    { lat: 48.6274766, lng: 17.7283495 },
    { lat: 48.6275022, lng: 17.7287452 },
    { lat: 48.627259, lng: 17.7286463 },
    { lat: 48.6271685, lng: 17.7286783 },
    { lat: 48.6271838, lng: 17.7289267 },
    { lat: 48.6270238, lng: 17.7289716 },
    { lat: 48.6270646, lng: 17.7287171 },
    { lat: 48.6270085, lng: 17.7285016 },
    { lat: 48.6269544, lng: 17.7284303 },
    { lat: 48.6268192, lng: 17.7284554 },
    { lat: 48.6268445, lng: 17.7286421 },
    { lat: 48.6266034, lng: 17.7290368 },
    { lat: 48.6264843, lng: 17.7290471 },
    { lat: 48.6264799, lng: 17.7291395 },
    { lat: 48.6257729, lng: 17.7290964 },
    { lat: 48.6253931, lng: 17.7292376 },
    { lat: 48.6250155, lng: 17.7291033 },
    { lat: 48.624392, lng: 17.7283847 },
    { lat: 48.6231046, lng: 17.7297505 },
    { lat: 48.62301, lng: 17.7299747 },
    { lat: 48.6224465, lng: 17.7304122 },
    { lat: 48.6223744, lng: 17.7303595 },
    { lat: 48.6222394, lng: 17.7299809 },
    { lat: 48.6219464, lng: 17.7304464 },
    { lat: 48.6215836, lng: 17.7298008 },
    { lat: 48.6212956, lng: 17.729529 },
    { lat: 48.6209086, lng: 17.7303524 },
    { lat: 48.6208332, lng: 17.7302281 },
    { lat: 48.6205857, lng: 17.7312314 },
    { lat: 48.619935, lng: 17.7321574 },
    { lat: 48.6194839, lng: 17.7343622 },
    { lat: 48.6196371, lng: 17.7346472 },
    { lat: 48.6196914, lng: 17.7350718 },
    { lat: 48.6195905, lng: 17.735523 },
    { lat: 48.6196328, lng: 17.7357316 },
    { lat: 48.619578, lng: 17.7360669 },
    { lat: 48.6195918, lng: 17.7363675 },
    { lat: 48.619257, lng: 17.7363749 },
    { lat: 48.6193514, lng: 17.7366582 },
    { lat: 48.6193607, lng: 17.7369937 },
    { lat: 48.6190963, lng: 17.7371558 },
    { lat: 48.6190196, lng: 17.7370834 },
    { lat: 48.6189944, lng: 17.7374798 },
    { lat: 48.6189369, lng: 17.7375557 },
    { lat: 48.6188293, lng: 17.7374951 },
    { lat: 48.6187674, lng: 17.737623 },
    { lat: 48.6188227, lng: 17.7378793 },
    { lat: 48.6187813, lng: 17.7380321 },
    { lat: 48.6186751, lng: 17.7379756 },
    { lat: 48.6180991, lng: 17.7372979 },
    { lat: 48.6166696, lng: 17.7356113 },
    { lat: 48.6165476, lng: 17.7359752 },
    { lat: 48.6162248, lng: 17.7360701 },
    { lat: 48.6160734, lng: 17.7363233 },
    { lat: 48.6160362, lng: 17.7366907 },
    { lat: 48.6155414, lng: 17.7365211 },
    { lat: 48.6156412, lng: 17.735761 },
    { lat: 48.6152389, lng: 17.7352801 },
    { lat: 48.6150951, lng: 17.7353802 },
    { lat: 48.6148683, lng: 17.7359872 },
    { lat: 48.6146688, lng: 17.7363771 },
    { lat: 48.6144882, lng: 17.7362304 },
    { lat: 48.6143819, lng: 17.7363552 },
    { lat: 48.6132618, lng: 17.7384899 },
    { lat: 48.6128888, lng: 17.7392008 },
    { lat: 48.6124677, lng: 17.7387254 },
    { lat: 48.6133196, lng: 17.7370188 },
    { lat: 48.6119662, lng: 17.7359426 },
    { lat: 48.6131697, lng: 17.7335087 },
    { lat: 48.6108136, lng: 17.7316282 },
    { lat: 48.6126794, lng: 17.7278723 },
    { lat: 48.611766, lng: 17.7271226 },
    { lat: 48.6115225, lng: 17.7272593 },
    { lat: 48.6104289, lng: 17.7281237 },
    { lat: 48.6103469, lng: 17.7280952 },
    { lat: 48.6072654, lng: 17.7256221 },
    { lat: 48.6072367, lng: 17.7258509 },
    { lat: 48.6071078, lng: 17.727732 },
    { lat: 48.6069914, lng: 17.7287155 },
    { lat: 48.6063843, lng: 17.7313551 },
    { lat: 48.6059394, lng: 17.7328157 },
    { lat: 48.6055189, lng: 17.7340877 },
    { lat: 48.6047426, lng: 17.735685 },
    { lat: 48.6034771, lng: 17.7377543 },
    { lat: 48.602998, lng: 17.7386445 },
    { lat: 48.6025715, lng: 17.739619 },
    { lat: 48.6020061, lng: 17.7413798 },
    { lat: 48.6017944, lng: 17.7421943 },
    { lat: 48.6015485, lng: 17.7434434 },
    { lat: 48.6013608, lng: 17.7458218 },
    { lat: 48.6013252, lng: 17.7468478 },
    { lat: 48.6012783, lng: 17.7481994 },
    { lat: 48.6012804, lng: 17.7485451 },
    { lat: 48.6013946, lng: 17.7488623 },
    { lat: 48.6013597, lng: 17.7490452 },
    { lat: 48.6013639, lng: 17.7500847 },
    { lat: 48.601416, lng: 17.7517757 },
    { lat: 48.6014926, lng: 17.7524702 },
    { lat: 48.6016051, lng: 17.7530284 },
    { lat: 48.6017115, lng: 17.753385 },
    { lat: 48.6018694, lng: 17.7539138 },
    { lat: 48.6018526, lng: 17.7540246 },
    { lat: 48.6021641, lng: 17.7566093 },
    { lat: 48.6021591, lng: 17.7569478 },
    { lat: 48.6021172, lng: 17.7573125 },
    { lat: 48.6020079, lng: 17.7576124 },
    { lat: 48.6017564, lng: 17.7576708 },
    { lat: 48.601797, lng: 17.7580718 },
    { lat: 48.6016457, lng: 17.7587361 },
    { lat: 48.6015691, lng: 17.7594702 },
    { lat: 48.6015215, lng: 17.7620737 },
    { lat: 48.6014529, lng: 17.7624471 },
    { lat: 48.601355, lng: 17.7646527 },
    { lat: 48.6012553, lng: 17.7657863 },
    { lat: 48.6013384, lng: 17.7665341 },
    { lat: 48.6012251, lng: 17.7666537 },
    { lat: 48.600471, lng: 17.76629 },
    { lat: 48.6004595, lng: 17.7663423 },
    { lat: 48.6012718, lng: 17.7726711 },
    { lat: 48.601347, lng: 17.7736383 },
    { lat: 48.601352, lng: 17.7751197 },
    { lat: 48.6012928, lng: 17.7755724 },
    { lat: 48.6007903, lng: 17.777574 },
    { lat: 48.5997728, lng: 17.7780138 },
    { lat: 48.5991537, lng: 17.7785039 },
    { lat: 48.5991321, lng: 17.7786838 },
    { lat: 48.5991353, lng: 17.7786966 },
    { lat: 48.5991362, lng: 17.7787121 },
    { lat: 48.5991363, lng: 17.7788441 },
    { lat: 48.5992626, lng: 17.7788243 },
    { lat: 48.599509, lng: 17.7790468 },
    { lat: 48.6004903, lng: 17.7796779 },
    { lat: 48.6008967, lng: 17.7798241 },
    { lat: 48.6013226, lng: 17.780196 },
    { lat: 48.6013304, lng: 17.7812264 },
    { lat: 48.6015644, lng: 17.7816008 },
    { lat: 48.6034392, lng: 17.7813722 },
    { lat: 48.603782, lng: 17.7822676 },
    { lat: 48.6041922, lng: 17.7829538 },
    { lat: 48.6046196, lng: 17.7827436 },
    { lat: 48.604348, lng: 17.7817945 },
    { lat: 48.6047647, lng: 17.7811161 },
    { lat: 48.6052102, lng: 17.7811627 },
    { lat: 48.6057455, lng: 17.7817912 },
    { lat: 48.6063133, lng: 17.7815515 },
    { lat: 48.6065979, lng: 17.7810391 },
    { lat: 48.6067372, lng: 17.7809537 },
    { lat: 48.6071201, lng: 17.7810487 },
    { lat: 48.608502, lng: 17.7818406 },
    { lat: 48.6085601, lng: 17.7817922 },
    { lat: 48.6087869, lng: 17.7810731 },
    { lat: 48.6090451, lng: 17.7799248 },
    { lat: 48.6100478, lng: 17.7762127 },
    { lat: 48.6104593, lng: 17.7743794 },
    { lat: 48.6106409, lng: 17.7737875 },
    { lat: 48.6120646, lng: 17.7739926 },
  ],
  Nižná: [
    { lat: 48.528482, lng: 17.635853 },
    { lat: 48.528161, lng: 17.635818 },
    { lat: 48.527541, lng: 17.635447 },
    { lat: 48.527263, lng: 17.635246 },
    { lat: 48.527037, lng: 17.635048 },
    { lat: 48.526444, lng: 17.634485 },
    { lat: 48.526088, lng: 17.634173 },
    { lat: 48.525935, lng: 17.634057 },
    { lat: 48.525664, lng: 17.633822 },
    { lat: 48.525329, lng: 17.633537 },
    { lat: 48.525279, lng: 17.633498 },
    { lat: 48.524959, lng: 17.633254 },
    { lat: 48.524554, lng: 17.632961 },
    { lat: 48.524372, lng: 17.632769 },
    { lat: 48.524253, lng: 17.632607 },
    { lat: 48.524178, lng: 17.632496 },
    { lat: 48.523139, lng: 17.633367 },
    { lat: 48.522932, lng: 17.633543 },
    { lat: 48.522505, lng: 17.633941 },
    { lat: 48.522022, lng: 17.634397 },
    { lat: 48.521475, lng: 17.634906 },
    { lat: 48.520936, lng: 17.635421 },
    { lat: 48.520617, lng: 17.635719 },
    { lat: 48.520251, lng: 17.636122 },
    { lat: 48.519494, lng: 17.636957 },
    { lat: 48.51918, lng: 17.637308 },
    { lat: 48.518754, lng: 17.637771 },
    { lat: 48.518026, lng: 17.638677 },
    { lat: 48.51762, lng: 17.639265 },
    { lat: 48.516681, lng: 17.640791 },
    { lat: 48.516453, lng: 17.641169 },
    { lat: 48.5164448, lng: 17.6411826 },
    { lat: 48.516366, lng: 17.641313 },
    { lat: 48.516337, lng: 17.641361 },
    { lat: 48.516307, lng: 17.641417 },
    { lat: 48.515941, lng: 17.6421 },
    { lat: 48.515839, lng: 17.642256 },
    { lat: 48.515551, lng: 17.642689 },
    { lat: 48.515282, lng: 17.643118 },
    { lat: 48.51469, lng: 17.644046 },
    { lat: 48.51378, lng: 17.645441 },
    { lat: 48.513449, lng: 17.646062 },
    { lat: 48.513228, lng: 17.646469 },
    { lat: 48.512937, lng: 17.647069 },
    { lat: 48.512142, lng: 17.648697 },
    { lat: 48.510805, lng: 17.652143 },
    { lat: 48.510706, lng: 17.652393 },
    { lat: 48.51039, lng: 17.653461 },
    { lat: 48.510293, lng: 17.653741 },
    { lat: 48.509694, lng: 17.655546 },
    { lat: 48.509483, lng: 17.65656 },
    { lat: 48.509503, lng: 17.656573 },
    { lat: 48.50973, lng: 17.656719 },
    { lat: 48.51009, lng: 17.657081 },
    { lat: 48.510333, lng: 17.657404 },
    { lat: 48.510571, lng: 17.65771 },
    { lat: 48.510913, lng: 17.658119 },
    { lat: 48.511108, lng: 17.658358 },
    { lat: 48.511644, lng: 17.659041 },
    { lat: 48.512091, lng: 17.659586 },
    { lat: 48.512601, lng: 17.660204 },
    { lat: 48.513073, lng: 17.66081 },
    { lat: 48.513564, lng: 17.66138 },
    { lat: 48.513571, lng: 17.661388 },
    { lat: 48.513598, lng: 17.661421 },
    { lat: 48.513604, lng: 17.661429 },
    { lat: 48.513605, lng: 17.66144 },
    { lat: 48.513582, lng: 17.661493 },
    { lat: 48.513462, lng: 17.661674 },
    { lat: 48.513358, lng: 17.66183 },
    { lat: 48.513256, lng: 17.661984 },
    { lat: 48.51323, lng: 17.662024 },
    { lat: 48.513166, lng: 17.662121 },
    { lat: 48.5142777, lng: 17.6637545 },
    { lat: 48.5157817, lng: 17.6662864 },
    { lat: 48.5167907, lng: 17.6682859 },
    { lat: 48.516969, lng: 17.6687535 },
    { lat: 48.5178125, lng: 17.6705464 },
    { lat: 48.5179659, lng: 17.6707797 },
    { lat: 48.5197283, lng: 17.6729481 },
    { lat: 48.5215286, lng: 17.6749119 },
    { lat: 48.5219901, lng: 17.6753574 },
    { lat: 48.5229184, lng: 17.6760889 },
    { lat: 48.5244093, lng: 17.6770383 },
    { lat: 48.5252194, lng: 17.6773709 },
    { lat: 48.5256531, lng: 17.6774439 },
    { lat: 48.5265733, lng: 17.6763592 },
    { lat: 48.5270988, lng: 17.6754055 },
    { lat: 48.5273913, lng: 17.6744094 },
    { lat: 48.527355, lng: 17.6741189 },
    { lat: 48.5274156, lng: 17.673929 },
    { lat: 48.5273803, lng: 17.6737089 },
    { lat: 48.5272683, lng: 17.6736168 },
    { lat: 48.5272804, lng: 17.6733736 },
    { lat: 48.5271699, lng: 17.6731235 },
    { lat: 48.5272025, lng: 17.6729749 },
    { lat: 48.5272727, lng: 17.6729048 },
    { lat: 48.5274126, lng: 17.6723988 },
    { lat: 48.527771, lng: 17.6725487 },
    { lat: 48.5277923, lng: 17.6711917 },
    { lat: 48.528105, lng: 17.6711891 },
    { lat: 48.528157, lng: 17.6709192 },
    { lat: 48.5294932, lng: 17.6710541 },
    { lat: 48.5305977, lng: 17.6713805 },
    { lat: 48.5318388, lng: 17.6719667 },
    { lat: 48.5317673, lng: 17.6724663 },
    { lat: 48.5344461, lng: 17.6739603 },
    { lat: 48.5355985, lng: 17.6741138 },
    { lat: 48.5369816, lng: 17.6746022 },
    { lat: 48.5372669, lng: 17.6747611 },
    { lat: 48.5372847, lng: 17.6747728 },
    { lat: 48.5373465, lng: 17.6746062 },
    { lat: 48.5380572, lng: 17.6736189 },
    { lat: 48.5389543, lng: 17.6725051 },
    { lat: 48.5405495, lng: 17.6701456 },
    { lat: 48.5434074, lng: 17.6652457 },
    { lat: 48.5462602, lng: 17.6606592 },
    { lat: 48.5456608, lng: 17.6598134 },
    { lat: 48.5435054, lng: 17.6562176 },
    { lat: 48.543543, lng: 17.6561681 },
    { lat: 48.5415472, lng: 17.6531062 },
    { lat: 48.5410099, lng: 17.652426 },
    { lat: 48.5404779, lng: 17.6516267 },
    { lat: 48.539913, lng: 17.6507544 },
    { lat: 48.539687, lng: 17.650319 },
    { lat: 48.5401173, lng: 17.6497997 },
    { lat: 48.5405962, lng: 17.6494025 },
    { lat: 48.540838, lng: 17.6490282 },
    { lat: 48.5410055, lng: 17.6488873 },
    { lat: 48.5409913, lng: 17.6488415 },
    { lat: 48.528482, lng: 17.635853 },
  ],
  Dubovany: [
    { lat: 48.5495745, lng: 17.6850815 },
    { lat: 48.5485247, lng: 17.6841226 },
    { lat: 48.5482538, lng: 17.683797 },
    { lat: 48.547831, lng: 17.6841881 },
    { lat: 48.546961, lng: 17.683109 },
    { lat: 48.5463884, lng: 17.683507 },
    { lat: 48.5460834, lng: 17.6829778 },
    { lat: 48.5457854, lng: 17.6827256 },
    { lat: 48.5452457, lng: 17.6824177 },
    { lat: 48.5451414, lng: 17.682157 },
    { lat: 48.5439753, lng: 17.6819408 },
    { lat: 48.5420772, lng: 17.6810516 },
    { lat: 48.5418553, lng: 17.6810252 },
    { lat: 48.5416013, lng: 17.6811904 },
    { lat: 48.5414216, lng: 17.6813858 },
    { lat: 48.5416229, lng: 17.6816292 },
    { lat: 48.5409128, lng: 17.6821172 },
    { lat: 48.5322015, lng: 17.6911498 },
    { lat: 48.5316386, lng: 17.6931725 },
    { lat: 48.5317901, lng: 17.6932837 },
    { lat: 48.5308584, lng: 17.6963867 },
    { lat: 48.5295604, lng: 17.7003 },
    { lat: 48.5294375, lng: 17.7002493 },
    { lat: 48.5288322, lng: 17.7021551 },
    { lat: 48.5281828, lng: 17.7044055 },
    { lat: 48.5285539, lng: 17.7046931 },
    { lat: 48.5267565, lng: 17.7116248 },
    { lat: 48.5257094, lng: 17.7142037 },
    { lat: 48.5245351, lng: 17.7168257 },
    { lat: 48.52441, lng: 17.7170096 },
    { lat: 48.5237666, lng: 17.7185076 },
    { lat: 48.5228, lng: 17.7212558 },
    { lat: 48.5217651, lng: 17.7257665 },
    { lat: 48.5214688, lng: 17.72771 },
    { lat: 48.5212516, lng: 17.7295265 },
    { lat: 48.5211941, lng: 17.7303865 },
    { lat: 48.5210664, lng: 17.731152 },
    { lat: 48.5205844, lng: 17.7316644 },
    { lat: 48.519573, lng: 17.7334394 },
    { lat: 48.5179232, lng: 17.7366827 },
    { lat: 48.5176525, lng: 17.737034 },
    { lat: 48.5169902, lng: 17.7386524 },
    { lat: 48.5122615, lng: 17.7448598 },
    { lat: 48.5115713, lng: 17.7454363 },
    { lat: 48.5112543, lng: 17.7472699 },
    { lat: 48.5111134, lng: 17.7476393 },
    { lat: 48.5106827, lng: 17.7482539 },
    { lat: 48.5103172, lng: 17.7485493 },
    { lat: 48.5101297, lng: 17.7490416 },
    { lat: 48.5096718, lng: 17.7494346 },
    { lat: 48.5096284, lng: 17.7501043 },
    { lat: 48.5095485, lng: 17.7504929 },
    { lat: 48.5091789, lng: 17.7513667 },
    { lat: 48.5086292, lng: 17.7522995 },
    { lat: 48.5084045, lng: 17.7542215 },
    { lat: 48.5087682, lng: 17.7553054 },
    { lat: 48.5090009, lng: 17.7565693 },
    { lat: 48.5089593, lng: 17.7576333 },
    { lat: 48.5095024, lng: 17.758093 },
    { lat: 48.5090085, lng: 17.7593835 },
    { lat: 48.5090779, lng: 17.7600731 },
    { lat: 48.5091064, lng: 17.7603707 },
    { lat: 48.5095833, lng: 17.7599385 },
    { lat: 48.5100797, lng: 17.7592532 },
    { lat: 48.5104113, lng: 17.7586062 },
    { lat: 48.5105527, lng: 17.7582294 },
    { lat: 48.5105673, lng: 17.7576032 },
    { lat: 48.5106988, lng: 17.7573566 },
    { lat: 48.5112452, lng: 17.7570972 },
    { lat: 48.5121057, lng: 17.7570643 },
    { lat: 48.5129609, lng: 17.7571471 },
    { lat: 48.5134749, lng: 17.7567502 },
    { lat: 48.5140727, lng: 17.7556597 },
    { lat: 48.5145361, lng: 17.7553564 },
    { lat: 48.5147443, lng: 17.7554024 },
    { lat: 48.5149317, lng: 17.7553642 },
    { lat: 48.5153365, lng: 17.754881 },
    { lat: 48.5170935, lng: 17.7550675 },
    { lat: 48.5177254, lng: 17.7553357 },
    { lat: 48.5185897, lng: 17.7560429 },
    { lat: 48.5191526, lng: 17.7558336 },
    { lat: 48.5192975, lng: 17.7558201 },
    { lat: 48.5194355, lng: 17.7559696 },
    { lat: 48.5198279, lng: 17.7557994 },
    { lat: 48.520074, lng: 17.7561461 },
    { lat: 48.5204504, lng: 17.7564258 },
    { lat: 48.5205526, lng: 17.7562726 },
    { lat: 48.5206243, lng: 17.7559698 },
    { lat: 48.5207484, lng: 17.7558229 },
    { lat: 48.5211882, lng: 17.7557226 },
    { lat: 48.5213228, lng: 17.7555799 },
    { lat: 48.5214357, lng: 17.7556008 },
    { lat: 48.5216174, lng: 17.7558193 },
    { lat: 48.5217689, lng: 17.7557098 },
    { lat: 48.5219026, lng: 17.7557909 },
    { lat: 48.5223925, lng: 17.7562114 },
    { lat: 48.5225523, lng: 17.7562593 },
    { lat: 48.5228025, lng: 17.7561962 },
    { lat: 48.5231653, lng: 17.7566245 },
    { lat: 48.523609, lng: 17.7566601 },
    { lat: 48.5237336, lng: 17.7562641 },
    { lat: 48.5242325, lng: 17.7538651 },
    { lat: 48.5243671, lng: 17.7534261 },
    { lat: 48.5246557, lng: 17.7518984 },
    { lat: 48.5251561, lng: 17.7496522 },
    { lat: 48.5251271, lng: 17.7495306 },
    { lat: 48.5237422, lng: 17.7476906 },
    { lat: 48.5235159, lng: 17.7461106 },
    { lat: 48.5241314, lng: 17.7464569 },
    { lat: 48.5327296, lng: 17.7513401 },
    { lat: 48.5327264, lng: 17.7512882 },
    { lat: 48.53361, lng: 17.7458454 },
    { lat: 48.5338249, lng: 17.7438219 },
    { lat: 48.5331953, lng: 17.7436076 },
    { lat: 48.5344516, lng: 17.735924 },
    { lat: 48.5341461, lng: 17.7353676 },
    { lat: 48.5338154, lng: 17.7351692 },
    { lat: 48.5342021, lng: 17.7347919 },
    { lat: 48.5344975, lng: 17.7348599 },
    { lat: 48.534569, lng: 17.7348107 },
    { lat: 48.534931, lng: 17.7342366 },
    { lat: 48.5349442, lng: 17.734015 },
    { lat: 48.5350389, lng: 17.733794 },
    { lat: 48.5352496, lng: 17.7335461 },
    { lat: 48.5352733, lng: 17.7333832 },
    { lat: 48.5353354, lng: 17.733346 },
    { lat: 48.5353125, lng: 17.7332669 },
    { lat: 48.5355029, lng: 17.7325512 },
    { lat: 48.5357609, lng: 17.7317754 },
    { lat: 48.5362903, lng: 17.7304582 },
    { lat: 48.5381233, lng: 17.7267093 },
    { lat: 48.5388262, lng: 17.7255497 },
    { lat: 48.5400647, lng: 17.7230115 },
    { lat: 48.5404296, lng: 17.7224159 },
    { lat: 48.5405648, lng: 17.7225209 },
    { lat: 48.5426486, lng: 17.7188665 },
    { lat: 48.5463137, lng: 17.7118537 },
    { lat: 48.5476838, lng: 17.709606 },
    { lat: 48.5489992, lng: 17.7072211 },
    { lat: 48.5513835, lng: 17.7023584 },
    { lat: 48.5536406, lng: 17.6958311 },
    { lat: 48.55582, lng: 17.6905351 },
    { lat: 48.5544597, lng: 17.6886489 },
    { lat: 48.5535023, lng: 17.6874693 },
    { lat: 48.5523869, lng: 17.6866199 },
    { lat: 48.5518317, lng: 17.6860629 },
    { lat: 48.5507155, lng: 17.6858702 },
    { lat: 48.5499102, lng: 17.6853046 },
    { lat: 48.5496813, lng: 17.6850715 },
    { lat: 48.5495745, lng: 17.6850815 },
  ],
  Banka: [
    { lat: 48.583951, lng: 17.891383 },
    { lat: 48.5840694, lng: 17.8912497 },
    { lat: 48.5844198, lng: 17.8903434 },
    { lat: 48.5844421, lng: 17.8901864 },
    { lat: 48.5843766, lng: 17.8898852 },
    { lat: 48.584224, lng: 17.8894904 },
    { lat: 48.5842186, lng: 17.8885984 },
    { lat: 48.5844198, lng: 17.8880438 },
    { lat: 48.5846246, lng: 17.8878278 },
    { lat: 48.5845993, lng: 17.887781 },
    { lat: 48.5847235, lng: 17.8869233 },
    { lat: 48.5847945, lng: 17.8867354 },
    { lat: 48.5849211, lng: 17.8864002 },
    { lat: 48.5849937, lng: 17.885715 },
    { lat: 48.5849306, lng: 17.8852498 },
    { lat: 48.5849679, lng: 17.8841109 },
    { lat: 48.585047, lng: 17.8837396 },
    { lat: 48.5850491, lng: 17.8833446 },
    { lat: 48.5851546, lng: 17.8829408 },
    { lat: 48.5851192, lng: 17.882747 },
    { lat: 48.5850285, lng: 17.8825923 },
    { lat: 48.5850133, lng: 17.8821936 },
    { lat: 48.5849135, lng: 17.8818686 },
    { lat: 48.5849556, lng: 17.8814617 },
    { lat: 48.5848674, lng: 17.8807112 },
    { lat: 48.5847357, lng: 17.8804034 },
    { lat: 48.5847407, lng: 17.8803179 },
    { lat: 48.5844425, lng: 17.8798109 },
    { lat: 48.584287, lng: 17.8792532 },
    { lat: 48.5842869, lng: 17.8786924 },
    { lat: 48.5841474, lng: 17.8779907 },
    { lat: 48.5836074, lng: 17.8760091 },
    { lat: 48.5832391, lng: 17.8749182 },
    { lat: 48.5831247, lng: 17.8743535 },
    { lat: 48.5830838, lng: 17.8738457 },
    { lat: 48.5827358, lng: 17.8727427 },
    { lat: 48.5827597, lng: 17.8722784 },
    { lat: 48.5831951, lng: 17.8713051 },
    { lat: 48.5833816, lng: 17.8709743 },
    { lat: 48.5836339, lng: 17.8707217 },
    { lat: 48.5848368, lng: 17.8699309 },
    { lat: 48.5853739, lng: 17.8693856 },
    { lat: 48.586272, lng: 17.8687093 },
    { lat: 48.5867329, lng: 17.8682106 },
    { lat: 48.5877773, lng: 17.867691 },
    { lat: 48.588294, lng: 17.8668308 },
    { lat: 48.5885153, lng: 17.8662508 },
    { lat: 48.5884566, lng: 17.8656374 },
    { lat: 48.5885798, lng: 17.8653441 },
    { lat: 48.5885301, lng: 17.8645968 },
    { lat: 48.5884374, lng: 17.8640915 },
    { lat: 48.5884577, lng: 17.8638124 },
    { lat: 48.5883705, lng: 17.8636942 },
    { lat: 48.5883257, lng: 17.8634419 },
    { lat: 48.5882385, lng: 17.8634357 },
    { lat: 48.5882249, lng: 17.8633788 },
    { lat: 48.5882897, lng: 17.8633317 },
    { lat: 48.5883124, lng: 17.8629424 },
    { lat: 48.5884086, lng: 17.8627965 },
    { lat: 48.5884514, lng: 17.8624415 },
    { lat: 48.588634, lng: 17.8619728 },
    { lat: 48.5886426, lng: 17.8611633 },
    { lat: 48.5886825, lng: 17.8609863 },
    { lat: 48.5888743, lng: 17.8607395 },
    { lat: 48.589038, lng: 17.8607034 },
    { lat: 48.5891431, lng: 17.8605919 },
    { lat: 48.5893814, lng: 17.860129 },
    { lat: 48.5892904, lng: 17.8600446 },
    { lat: 48.5894062, lng: 17.8598867 },
    { lat: 48.5899714, lng: 17.8595296 },
    { lat: 48.5905814, lng: 17.8593293 },
    { lat: 48.5907677, lng: 17.8590711 },
    { lat: 48.5918955, lng: 17.8559452 },
    { lat: 48.593055, lng: 17.8519305 },
    { lat: 48.5926827, lng: 17.8516734 },
    { lat: 48.5896921, lng: 17.8493475 },
    { lat: 48.5888248, lng: 17.8486367 },
    { lat: 48.5888575, lng: 17.8485409 },
    { lat: 48.5885971, lng: 17.8483437 },
    { lat: 48.5885636, lng: 17.8484424 },
    { lat: 48.5869856, lng: 17.8472016 },
    { lat: 48.5870124, lng: 17.8470852 },
    { lat: 48.5868376, lng: 17.8468826 },
    { lat: 48.5868319, lng: 17.8468085 },
    { lat: 48.5866986, lng: 17.8467876 },
    { lat: 48.5861658, lng: 17.8462483 },
    { lat: 48.5855284, lng: 17.8451069 },
    { lat: 48.5854519, lng: 17.8448483 },
    { lat: 48.5853838, lng: 17.8438768 },
    { lat: 48.5855984, lng: 17.8426752 },
    { lat: 48.5855982, lng: 17.8417802 },
    { lat: 48.5854239, lng: 17.8405838 },
    { lat: 48.5850275, lng: 17.8396356 },
    { lat: 48.5850383, lng: 17.8393259 },
    { lat: 48.5850407, lng: 17.839258 },
    { lat: 48.5849503, lng: 17.8391839 },
    { lat: 48.5847958, lng: 17.8392765 },
    { lat: 48.5847232, lng: 17.8392338 },
    { lat: 48.5833086, lng: 17.8375814 },
    { lat: 48.581881, lng: 17.8363276 },
    { lat: 48.5797756, lng: 17.8349196 },
    { lat: 48.5788758, lng: 17.8345289 },
    { lat: 48.5782896, lng: 17.8341329 },
    { lat: 48.5774218, lng: 17.8335093 },
    { lat: 48.5760449, lng: 17.8322713 },
    { lat: 48.576015, lng: 17.8323175 },
    { lat: 48.5740383, lng: 17.8310791 },
    { lat: 48.5728417, lng: 17.8305406 },
    { lat: 48.5695643, lng: 17.8295726 },
    { lat: 48.5689673, lng: 17.8293176 },
    { lat: 48.5689391, lng: 17.8293558 },
    { lat: 48.5681971, lng: 17.8289045 },
    { lat: 48.5683558, lng: 17.8295634 },
    { lat: 48.5679779, lng: 17.8295144 },
    { lat: 48.5669429, lng: 17.8289975 },
    { lat: 48.5659691, lng: 17.8285994 },
    { lat: 48.5635448, lng: 17.8292553 },
    { lat: 48.5650294, lng: 17.8329622 },
    { lat: 48.5654753, lng: 17.8341858 },
    { lat: 48.566175, lng: 17.8355179 },
    { lat: 48.5667179, lng: 17.8368304 },
    { lat: 48.5672298, lng: 17.8375655 },
    { lat: 48.5681403, lng: 17.8395398 },
    { lat: 48.568367, lng: 17.8406134 },
    { lat: 48.5685001, lng: 17.8420785 },
    { lat: 48.5691773, lng: 17.8424818 },
    { lat: 48.5692562, lng: 17.8427215 },
    { lat: 48.5692956, lng: 17.8427196 },
    { lat: 48.5692697, lng: 17.8436111 },
    { lat: 48.5693655, lng: 17.8435914 },
    { lat: 48.5695423, lng: 17.8438821 },
    { lat: 48.5695545, lng: 17.844137 },
    { lat: 48.5699705, lng: 17.8445009 },
    { lat: 48.5699664, lng: 17.8449692 },
    { lat: 48.5707295, lng: 17.8467166 },
    { lat: 48.5708639, lng: 17.846919 },
    { lat: 48.5710108, lng: 17.8474805 },
    { lat: 48.5714102, lng: 17.8479793 },
    { lat: 48.5717213, lng: 17.8486033 },
    { lat: 48.5719219, lng: 17.8493587 },
    { lat: 48.5719256, lng: 17.8496986 },
    { lat: 48.5720917, lng: 17.8502952 },
    { lat: 48.5709053, lng: 17.8505748 },
    { lat: 48.5704516, lng: 17.8508362 },
    { lat: 48.5703057, lng: 17.8511825 },
    { lat: 48.570116, lng: 17.8521147 },
    { lat: 48.5698202, lng: 17.8530046 },
    { lat: 48.5696348, lng: 17.8537997 },
    { lat: 48.5693395, lng: 17.8546239 },
    { lat: 48.5694134, lng: 17.854727 },
    { lat: 48.5692219, lng: 17.8552007 },
    { lat: 48.5688226, lng: 17.855949 },
    { lat: 48.5684983, lng: 17.8563149 },
    { lat: 48.5684304, lng: 17.8562184 },
    { lat: 48.5683814, lng: 17.8563028 },
    { lat: 48.5684413, lng: 17.8564714 },
    { lat: 48.5677171, lng: 17.857747 },
    { lat: 48.5677894, lng: 17.8578942 },
    { lat: 48.5671871, lng: 17.8585717 },
    { lat: 48.5677799, lng: 17.858911 },
    { lat: 48.5690222, lng: 17.8594046 },
    { lat: 48.5689621, lng: 17.8596588 },
    { lat: 48.5690897, lng: 17.859772 },
    { lat: 48.5688358, lng: 17.8602946 },
    { lat: 48.568819, lng: 17.8605393 },
    { lat: 48.5685555, lng: 17.8609021 },
    { lat: 48.5685148, lng: 17.8612174 },
    { lat: 48.5684021, lng: 17.8614079 },
    { lat: 48.5683562, lng: 17.8616573 },
    { lat: 48.5682499, lng: 17.8618773 },
    { lat: 48.5677014, lng: 17.8627908 },
    { lat: 48.5675862, lng: 17.8628619 },
    { lat: 48.5674148, lng: 17.8632226 },
    { lat: 48.5673813, lng: 17.8635434 },
    { lat: 48.5677427, lng: 17.8638978 },
    { lat: 48.5675254, lng: 17.8647104 },
    { lat: 48.5672112, lng: 17.8655948 },
    { lat: 48.5673487, lng: 17.8658728 },
    { lat: 48.5670213, lng: 17.8663563 },
    { lat: 48.566974, lng: 17.8665242 },
    { lat: 48.5669813, lng: 17.8680426 },
    { lat: 48.5667274, lng: 17.8687505 },
    { lat: 48.5666761, lng: 17.869079 },
    { lat: 48.566149, lng: 17.8696761 },
    { lat: 48.5659179, lng: 17.8696637 },
    { lat: 48.5651608, lng: 17.8693691 },
    { lat: 48.5649926, lng: 17.8694075 },
    { lat: 48.56468, lng: 17.8698005 },
    { lat: 48.5641097, lng: 17.870132 },
    { lat: 48.5637455, lng: 17.8705485 },
    { lat: 48.563563, lng: 17.8711506 },
    { lat: 48.5635124, lng: 17.8722171 },
    { lat: 48.5635803, lng: 17.8728343 },
    { lat: 48.5641103, lng: 17.8738484 },
    { lat: 48.5642092, lng: 17.8745861 },
    { lat: 48.564609, lng: 17.875611 },
    { lat: 48.564826, lng: 17.875807 },
    { lat: 48.564996, lng: 17.876059 },
    { lat: 48.565117, lng: 17.876311 },
    { lat: 48.565514, lng: 17.876904 },
    { lat: 48.565536, lng: 17.876928 },
    { lat: 48.565608, lng: 17.877005 },
    { lat: 48.565885, lng: 17.877299 },
    { lat: 48.566081, lng: 17.877437 },
    { lat: 48.566269, lng: 17.877619 },
    { lat: 48.566554, lng: 17.877807 },
    { lat: 48.566789, lng: 17.877895 },
    { lat: 48.567405, lng: 17.87825 },
    { lat: 48.568007, lng: 17.878646 },
    { lat: 48.568289, lng: 17.878996 },
    { lat: 48.568457, lng: 17.879138 },
    { lat: 48.568728, lng: 17.87925 },
    { lat: 48.568904, lng: 17.879489 },
    { lat: 48.56899, lng: 17.87976 },
    { lat: 48.569217, lng: 17.880151 },
    { lat: 48.569345, lng: 17.880311 },
    { lat: 48.569584, lng: 17.880557 },
    { lat: 48.569798, lng: 17.880831 },
    { lat: 48.56998, lng: 17.881306 },
    { lat: 48.570025, lng: 17.881651 },
    { lat: 48.570025, lng: 17.881862 },
    { lat: 48.569979, lng: 17.882034 },
    { lat: 48.569704, lng: 17.882883 },
    { lat: 48.569697, lng: 17.883029 },
    { lat: 48.569812, lng: 17.883414 },
    { lat: 48.569867, lng: 17.883543 },
    { lat: 48.569905, lng: 17.88387 },
    { lat: 48.570027, lng: 17.884003 },
    { lat: 48.570528, lng: 17.884038 },
    { lat: 48.571013, lng: 17.884149 },
    { lat: 48.571142, lng: 17.884285 },
    { lat: 48.571239, lng: 17.884552 },
    { lat: 48.57129, lng: 17.884716 },
    { lat: 48.57137, lng: 17.885138 },
    { lat: 48.571458, lng: 17.885831 },
    { lat: 48.57152, lng: 17.886094 },
    { lat: 48.571701, lng: 17.886397 },
    { lat: 48.571822, lng: 17.886473 },
    { lat: 48.571889, lng: 17.886481 },
    { lat: 48.572123, lng: 17.886427 },
    { lat: 48.572309, lng: 17.886335 },
    { lat: 48.572552, lng: 17.886152 },
    { lat: 48.572768, lng: 17.885988 },
    { lat: 48.572849, lng: 17.88602 },
    { lat: 48.572913, lng: 17.886095 },
    { lat: 48.57299, lng: 17.886229 },
    { lat: 48.573334, lng: 17.887177 },
    { lat: 48.573521, lng: 17.887414 },
    { lat: 48.573629, lng: 17.887494 },
    { lat: 48.5738, lng: 17.88756 },
    { lat: 48.574017, lng: 17.887588 },
    { lat: 48.574238, lng: 17.887763 },
    { lat: 48.574359, lng: 17.887889 },
    { lat: 48.574503, lng: 17.888009 },
    { lat: 48.574594, lng: 17.888065 },
    { lat: 48.574663, lng: 17.888094 },
    { lat: 48.57476, lng: 17.888113 },
    { lat: 48.574937, lng: 17.888065 },
    { lat: 48.575101, lng: 17.88808 },
    { lat: 48.575319, lng: 17.888108 },
    { lat: 48.575408, lng: 17.888077 },
    { lat: 48.576081, lng: 17.887701 },
    { lat: 48.576411, lng: 17.887672 },
    { lat: 48.576684, lng: 17.88761 },
    { lat: 48.577121, lng: 17.88791 },
    { lat: 48.57739, lng: 17.887918 },
    { lat: 48.577528, lng: 17.887944 },
    { lat: 48.577689, lng: 17.888048 },
    { lat: 48.577842, lng: 17.888207 },
    { lat: 48.578065, lng: 17.88835 },
    { lat: 48.578665, lng: 17.888932 },
    { lat: 48.578812, lng: 17.888998 },
    { lat: 48.579053, lng: 17.889145 },
    { lat: 48.57948, lng: 17.889306 },
    { lat: 48.57999, lng: 17.889596 },
    { lat: 48.580182, lng: 17.889585 },
    { lat: 48.580268, lng: 17.889624 },
    { lat: 48.580423, lng: 17.889692 },
    { lat: 48.580647, lng: 17.889755 },
    { lat: 48.580743, lng: 17.889826 },
    { lat: 48.580824, lng: 17.889898 },
    { lat: 48.580987, lng: 17.890091 },
    { lat: 48.58174, lng: 17.890867 },
    { lat: 48.582182, lng: 17.891088 },
    { lat: 48.582342, lng: 17.891095 },
    { lat: 48.583142, lng: 17.891344 },
    { lat: 48.583297, lng: 17.891537 },
    { lat: 48.583532, lng: 17.891577 },
    { lat: 48.583951, lng: 17.891383 },
  ],
  Pečeňady: [
    { lat: 48.4984852, lng: 17.6898342 },
    { lat: 48.4982887, lng: 17.6893457 },
    { lat: 48.4980476, lng: 17.6887172 },
    { lat: 48.4979238, lng: 17.6881814 },
    { lat: 48.4974428, lng: 17.6853315 },
    { lat: 48.4972355, lng: 17.6844885 },
    { lat: 48.496624, lng: 17.6831383 },
    { lat: 48.4954994, lng: 17.6813807 },
    { lat: 48.495209, lng: 17.681032 },
    { lat: 48.494956, lng: 17.68143 },
    { lat: 48.494578, lng: 17.681947 },
    { lat: 48.494237, lng: 17.682434 },
    { lat: 48.493886, lng: 17.682936 },
    { lat: 48.493589, lng: 17.68336 },
    { lat: 48.493393, lng: 17.683619 },
    { lat: 48.493185, lng: 17.683855 },
    { lat: 48.492867, lng: 17.684232 },
    { lat: 48.49246, lng: 17.684743 },
    { lat: 48.492086, lng: 17.685196 },
    { lat: 48.491905, lng: 17.685443 },
    { lat: 48.49141, lng: 17.686212 },
    { lat: 48.491219, lng: 17.686499 },
    { lat: 48.490791, lng: 17.687022 },
    { lat: 48.490584, lng: 17.687216 },
    { lat: 48.490275, lng: 17.687574 },
    { lat: 48.490117, lng: 17.687816 },
    { lat: 48.489949, lng: 17.688052 },
    { lat: 48.489741, lng: 17.68832 },
    { lat: 48.489333, lng: 17.688969 },
    { lat: 48.489073, lng: 17.689403 },
    { lat: 48.488807, lng: 17.689836 },
    { lat: 48.48874, lng: 17.689966 },
    { lat: 48.488558, lng: 17.690238 },
    { lat: 48.488411, lng: 17.690468 },
    { lat: 48.488222, lng: 17.690732 },
    { lat: 48.487794, lng: 17.691344 },
    { lat: 48.48745, lng: 17.691924 },
    { lat: 48.487192, lng: 17.692473 },
    { lat: 48.486989, lng: 17.692892 },
    { lat: 48.486804, lng: 17.69331 },
    { lat: 48.48658, lng: 17.693751 },
    { lat: 48.486332, lng: 17.69418 },
    { lat: 48.486001, lng: 17.694706 },
    { lat: 48.485657, lng: 17.695189 },
    { lat: 48.485365, lng: 17.695669 },
    { lat: 48.485124, lng: 17.696023 },
    { lat: 48.485044, lng: 17.696141 },
    { lat: 48.484886, lng: 17.696361 },
    { lat: 48.484596, lng: 17.696831 },
    { lat: 48.484406, lng: 17.697143 },
    { lat: 48.48419, lng: 17.69749 },
    { lat: 48.484048, lng: 17.69775 },
    { lat: 48.48378, lng: 17.698184 },
    { lat: 48.483503, lng: 17.698678 },
    { lat: 48.483375, lng: 17.698897 },
    { lat: 48.483218, lng: 17.699127 },
    { lat: 48.482962, lng: 17.699575 },
    { lat: 48.482751, lng: 17.699959 },
    { lat: 48.482331, lng: 17.700658 },
    { lat: 48.482198, lng: 17.700876 },
    { lat: 48.482052, lng: 17.701095 },
    { lat: 48.481833, lng: 17.701369 },
    { lat: 48.481726, lng: 17.701451 },
    { lat: 48.481527, lng: 17.701666 },
    { lat: 48.481095, lng: 17.702246 },
    { lat: 48.480793, lng: 17.702721 },
    { lat: 48.480595, lng: 17.703031 },
    { lat: 48.480366, lng: 17.703376 },
    { lat: 48.480134, lng: 17.703757 },
    { lat: 48.479845, lng: 17.704233 },
    { lat: 48.479581, lng: 17.704695 },
    { lat: 48.479335, lng: 17.705129 },
    { lat: 48.479202, lng: 17.705378 },
    { lat: 48.479031, lng: 17.705762 },
    { lat: 48.478854, lng: 17.706203 },
    { lat: 48.478676, lng: 17.70664 },
    { lat: 48.478507, lng: 17.707121 },
    { lat: 48.478167, lng: 17.708149 },
    { lat: 48.477981, lng: 17.708715 },
    { lat: 48.477911, lng: 17.708922 },
    { lat: 48.4776, lng: 17.709681 },
    { lat: 48.477551, lng: 17.709907 },
    { lat: 48.47738, lng: 17.710694 },
    { lat: 48.477355, lng: 17.710826 },
    { lat: 48.47728, lng: 17.711152 },
    { lat: 48.4772, lng: 17.711443 },
    { lat: 48.477023, lng: 17.712058 },
    { lat: 48.476823, lng: 17.712759 },
    { lat: 48.476661, lng: 17.713355 },
    { lat: 48.476626, lng: 17.713482 },
    { lat: 48.476592, lng: 17.713586 },
    { lat: 48.476517, lng: 17.713836 },
    { lat: 48.476279, lng: 17.714678 },
    { lat: 48.476222, lng: 17.714863 },
    { lat: 48.476063, lng: 17.715316 },
    { lat: 48.475979, lng: 17.715536 },
    { lat: 48.475777, lng: 17.716096 },
    { lat: 48.475649, lng: 17.716426 },
    { lat: 48.475468, lng: 17.716883 },
    { lat: 48.475179, lng: 17.717497 },
    { lat: 48.475108, lng: 17.717651 },
    { lat: 48.474994, lng: 17.718234 },
    { lat: 48.474939, lng: 17.718529 },
    { lat: 48.474821, lng: 17.719197 },
    { lat: 48.474737, lng: 17.719983 },
    { lat: 48.474633, lng: 17.72085 },
    { lat: 48.474606, lng: 17.721182 },
    { lat: 48.474556, lng: 17.721906 },
    { lat: 48.474517, lng: 17.722006 },
    { lat: 48.474472, lng: 17.722491 },
    { lat: 48.474418, lng: 17.723043 },
    { lat: 48.474376, lng: 17.723493 },
    { lat: 48.47433, lng: 17.723978 },
    { lat: 48.474282, lng: 17.724463 },
    { lat: 48.474224, lng: 17.725063 },
    { lat: 48.474151, lng: 17.725742 },
    { lat: 48.474099, lng: 17.726316 },
    { lat: 48.474041, lng: 17.726918 },
    { lat: 48.473985, lng: 17.727517 },
    { lat: 48.473944, lng: 17.727975 },
    { lat: 48.473873, lng: 17.728533 },
    { lat: 48.473791, lng: 17.729127 },
    { lat: 48.473708, lng: 17.729738 },
    { lat: 48.473627, lng: 17.730305 },
    { lat: 48.473533, lng: 17.730974 },
    { lat: 48.4735015, lng: 17.7312058 },
    { lat: 48.473453, lng: 17.731563 },
    { lat: 48.473366, lng: 17.732184 },
    { lat: 48.473294, lng: 17.732718 },
    { lat: 48.473229, lng: 17.733178 },
    { lat: 48.473157, lng: 17.733708 },
    { lat: 48.473079, lng: 17.734224 },
    { lat: 48.473005, lng: 17.734743 },
    { lat: 48.472921, lng: 17.735273 },
    { lat: 48.472835, lng: 17.735799 },
    { lat: 48.472753, lng: 17.736322 },
    { lat: 48.472707, lng: 17.736732 },
    { lat: 48.472624, lng: 17.737556 },
    { lat: 48.47258, lng: 17.737965 },
    { lat: 48.472551, lng: 17.738626 },
    { lat: 48.472538, lng: 17.738838 },
    { lat: 48.472506, lng: 17.739353 },
    { lat: 48.472454, lng: 17.739847 },
    { lat: 48.472386, lng: 17.740239 },
    { lat: 48.472553, lng: 17.740683 },
    { lat: 48.47255, lng: 17.740852 },
    { lat: 48.472496, lng: 17.741415 },
    { lat: 48.472438, lng: 17.741753 },
    { lat: 48.472418, lng: 17.742202 },
    { lat: 48.472248, lng: 17.742489 },
    { lat: 48.472092, lng: 17.743151 },
    { lat: 48.472096, lng: 17.743462 },
    { lat: 48.472137, lng: 17.743965 },
    { lat: 48.472324, lng: 17.744332 },
    { lat: 48.472414, lng: 17.744576 },
    { lat: 48.47249, lng: 17.744992 },
    { lat: 48.472498, lng: 17.745337 },
    { lat: 48.472417, lng: 17.74575 },
    { lat: 48.472352, lng: 17.746156 },
    { lat: 48.472368, lng: 17.746156 },
    { lat: 48.47246, lng: 17.746151 },
    { lat: 48.472839, lng: 17.746148 },
    { lat: 48.473339, lng: 17.746177 },
    { lat: 48.47343, lng: 17.74628 },
    { lat: 48.473862, lng: 17.746536 },
    { lat: 48.474031, lng: 17.746541 },
    { lat: 48.474229, lng: 17.746385 },
    { lat: 48.474283, lng: 17.746369 },
    { lat: 48.47438, lng: 17.746327 },
    { lat: 48.474934, lng: 17.746281 },
    { lat: 48.475193, lng: 17.746434 },
    { lat: 48.475425, lng: 17.74647 },
    { lat: 48.475562, lng: 17.746404 },
    { lat: 48.475732, lng: 17.746362 },
    { lat: 48.475896, lng: 17.746301 },
    { lat: 48.476111, lng: 17.746229 },
    { lat: 48.476171, lng: 17.746221 },
    { lat: 48.476212, lng: 17.746217 },
    { lat: 48.476536, lng: 17.745964 },
    { lat: 48.476823, lng: 17.746155 },
    { lat: 48.4771, lng: 17.746073 },
    { lat: 48.477309, lng: 17.74613 },
    { lat: 48.477664, lng: 17.746463 },
    { lat: 48.478059, lng: 17.74687 },
    { lat: 48.478268, lng: 17.747102 },
    { lat: 48.478367, lng: 17.747255 },
    { lat: 48.478371, lng: 17.747259 },
    { lat: 48.478418, lng: 17.747304 },
    { lat: 48.478719, lng: 17.747592 },
    { lat: 48.478785, lng: 17.747689 },
    { lat: 48.479171, lng: 17.747826 },
    { lat: 48.479302, lng: 17.747872 },
    { lat: 48.479699, lng: 17.74799 },
    { lat: 48.479973, lng: 17.748095 },
    { lat: 48.480107, lng: 17.748238 },
    { lat: 48.480407, lng: 17.748332 },
    { lat: 48.480703, lng: 17.748437 },
    { lat: 48.481052, lng: 17.748547 },
    { lat: 48.481271, lng: 17.748626 },
    { lat: 48.481415, lng: 17.748687 },
    { lat: 48.48149, lng: 17.748719 },
    { lat: 48.481495, lng: 17.74872 },
    { lat: 48.481704, lng: 17.74878 },
    { lat: 48.481994, lng: 17.748958 },
    { lat: 48.482388, lng: 17.749173 },
    { lat: 48.482712, lng: 17.749387 },
    { lat: 48.483102, lng: 17.749628 },
    { lat: 48.483487, lng: 17.749868 },
    { lat: 48.483841, lng: 17.750101 },
    { lat: 48.484155, lng: 17.750306 },
    { lat: 48.484272, lng: 17.750359 },
    { lat: 48.484445, lng: 17.750483 },
    { lat: 48.484907, lng: 17.750849 },
    { lat: 48.485167, lng: 17.751093 },
    { lat: 48.485348, lng: 17.75118 },
    { lat: 48.485674, lng: 17.751286 },
    { lat: 48.485854, lng: 17.751453 },
    { lat: 48.486002, lng: 17.751632 },
    { lat: 48.486163, lng: 17.751766 },
    { lat: 48.4865945, lng: 17.7506005 },
    { lat: 48.4870907, lng: 17.7507401 },
    { lat: 48.4873301, lng: 17.7492218 },
    { lat: 48.4880781, lng: 17.7464505 },
    { lat: 48.4882681, lng: 17.7452338 },
    { lat: 48.488433, lng: 17.7447779 },
    { lat: 48.4887831, lng: 17.7432797 },
    { lat: 48.489284, lng: 17.7406809 },
    { lat: 48.4896578, lng: 17.7378673 },
    { lat: 48.4895969, lng: 17.7379431 },
    { lat: 48.489351, lng: 17.7381096 },
    { lat: 48.489045, lng: 17.7382005 },
    { lat: 48.4890633, lng: 17.7380609 },
    { lat: 48.4920687, lng: 17.7235399 },
    { lat: 48.4921635, lng: 17.7233209 },
    { lat: 48.4923144, lng: 17.7233499 },
    { lat: 48.4923749, lng: 17.7229413 },
    { lat: 48.4925797, lng: 17.7221651 },
    { lat: 48.4929461, lng: 17.7209917 },
    { lat: 48.4930621, lng: 17.7208405 },
    { lat: 48.4933502, lng: 17.720022 },
    { lat: 48.4933059, lng: 17.7200019 },
    { lat: 48.4933258, lng: 17.7197919 },
    { lat: 48.4934816, lng: 17.7193013 },
    { lat: 48.494413, lng: 17.7168059 },
    { lat: 48.4956711, lng: 17.7145008 },
    { lat: 48.4959361, lng: 17.7139133 },
    { lat: 48.4965998, lng: 17.7121448 },
    { lat: 48.4971373, lng: 17.7103986 },
    { lat: 48.4972845, lng: 17.7097849 },
    { lat: 48.4977131, lng: 17.7073971 },
    { lat: 48.4984866, lng: 17.7043621 },
    { lat: 48.4989819, lng: 17.7019959 },
    { lat: 48.4992293, lng: 17.700814 },
    { lat: 48.4997387, lng: 17.6973328 },
    { lat: 48.4998606, lng: 17.6967132 },
    { lat: 48.5000988, lng: 17.6958982 },
    { lat: 48.4997638, lng: 17.6946321 },
    { lat: 48.4993991, lng: 17.6927051 },
    { lat: 48.498996, lng: 17.6916387 },
    { lat: 48.4984852, lng: 17.6898342 },
  ],
  Hubina: [
    { lat: 48.6251894, lng: 17.8559841 },
    { lat: 48.6234, lng: 17.8558845 },
    { lat: 48.6225694, lng: 17.8550532 },
    { lat: 48.6217249, lng: 17.8551623 },
    { lat: 48.6205407, lng: 17.8560942 },
    { lat: 48.6194595, lng: 17.8563226 },
    { lat: 48.6184604, lng: 17.8561515 },
    { lat: 48.6181539, lng: 17.8568327 },
    { lat: 48.6163108, lng: 17.8613996 },
    { lat: 48.6155077, lng: 17.8606855 },
    { lat: 48.6130825, lng: 17.8669 },
    { lat: 48.6138214, lng: 17.8693361 },
    { lat: 48.61358, lng: 17.8700101 },
    { lat: 48.6134455, lng: 17.87066 },
    { lat: 48.6134711, lng: 17.8717909 },
    { lat: 48.613287, lng: 17.8735807 },
    { lat: 48.6130786, lng: 17.8748747 },
    { lat: 48.6128542, lng: 17.8758492 },
    { lat: 48.6121251, lng: 17.8781833 },
    { lat: 48.6110037, lng: 17.881538 },
    { lat: 48.6088889, lng: 17.8807828 },
    { lat: 48.6083829, lng: 17.881992 },
    { lat: 48.6080931, lng: 17.8829892 },
    { lat: 48.6078241, lng: 17.8841976 },
    { lat: 48.6075601, lng: 17.8857323 },
    { lat: 48.6075592, lng: 17.8880916 },
    { lat: 48.6074827, lng: 17.8882416 },
    { lat: 48.6072302, lng: 17.8881742 },
    { lat: 48.6072091, lng: 17.8885185 },
    { lat: 48.607144, lng: 17.888541 },
    { lat: 48.6071838, lng: 17.8889564 },
    { lat: 48.6071545, lng: 17.889239 },
    { lat: 48.6070106, lng: 17.8893793 },
    { lat: 48.6072541, lng: 17.8904434 },
    { lat: 48.6075107, lng: 17.8909711 },
    { lat: 48.6075736, lng: 17.8912096 },
    { lat: 48.6076037, lng: 17.8914067 },
    { lat: 48.6075849, lng: 17.8919931 },
    { lat: 48.6075101, lng: 17.8920822 },
    { lat: 48.607527, lng: 17.8925689 },
    { lat: 48.607616, lng: 17.8926209 },
    { lat: 48.6077131, lng: 17.8929575 },
    { lat: 48.607779, lng: 17.8930027 },
    { lat: 48.6078137, lng: 17.8934092 },
    { lat: 48.6079014, lng: 17.8934236 },
    { lat: 48.6080214, lng: 17.8936161 },
    { lat: 48.6082174, lng: 17.8943012 },
    { lat: 48.6081819, lng: 17.8947794 },
    { lat: 48.6081267, lng: 17.8947624 },
    { lat: 48.6080785, lng: 17.894881 },
    { lat: 48.6074742, lng: 17.8953056 },
    { lat: 48.6073198, lng: 17.89529 },
    { lat: 48.6070262, lng: 17.8954384 },
    { lat: 48.606724, lng: 17.895778 },
    { lat: 48.6064418, lng: 17.8958556 },
    { lat: 48.6062667, lng: 17.8959845 },
    { lat: 48.6061951, lng: 17.8960496 },
    { lat: 48.6060364, lng: 17.896468 },
    { lat: 48.6058258, lng: 17.8963649 },
    { lat: 48.6054746, lng: 17.89683 },
    { lat: 48.605424, lng: 17.8968144 },
    { lat: 48.6053594, lng: 17.896962 },
    { lat: 48.6050143, lng: 17.8971353 },
    { lat: 48.6046911, lng: 17.897522 },
    { lat: 48.6045383, lng: 17.8975704 },
    { lat: 48.6045181, lng: 17.8977051 },
    { lat: 48.604418, lng: 17.8977288 },
    { lat: 48.6043181, lng: 17.8979077 },
    { lat: 48.6042738, lng: 17.8978827 },
    { lat: 48.6041008, lng: 17.8980848 },
    { lat: 48.6040268, lng: 17.8984116 },
    { lat: 48.6038052, lng: 17.8988757 },
    { lat: 48.603501, lng: 17.8991656 },
    { lat: 48.6033635, lng: 17.8993924 },
    { lat: 48.6033004, lng: 17.8996012 },
    { lat: 48.6025611, lng: 17.9006309 },
    { lat: 48.6024689, lng: 17.9009581 },
    { lat: 48.6023118, lng: 17.9011761 },
    { lat: 48.6022294, lng: 17.9019453 },
    { lat: 48.6022844, lng: 17.9020715 },
    { lat: 48.6022573, lng: 17.9026319 },
    { lat: 48.6021744, lng: 17.9030174 },
    { lat: 48.6022241, lng: 17.9033366 },
    { lat: 48.6020751, lng: 17.9035257 },
    { lat: 48.6020834, lng: 17.9037375 },
    { lat: 48.601964, lng: 17.9040206 },
    { lat: 48.6016232, lng: 17.9045277 },
    { lat: 48.6016208, lng: 17.9046215 },
    { lat: 48.6014455, lng: 17.9049911 },
    { lat: 48.601233, lng: 17.9050405 },
    { lat: 48.6011079, lng: 17.9051589 },
    { lat: 48.6008415, lng: 17.9055891 },
    { lat: 48.6005695, lng: 17.9058336 },
    { lat: 48.6003456, lng: 17.9059377 },
    { lat: 48.6000718, lng: 17.9061913 },
    { lat: 48.6000268, lng: 17.9061383 },
    { lat: 48.5999737, lng: 17.9061916 },
    { lat: 48.5998806, lng: 17.906444 },
    { lat: 48.5997975, lng: 17.9064154 },
    { lat: 48.5997787, lng: 17.9066109 },
    { lat: 48.5994301, lng: 17.9068555 },
    { lat: 48.5994237, lng: 17.9069919 },
    { lat: 48.5992267, lng: 17.9071148 },
    { lat: 48.5990996, lng: 17.9073487 },
    { lat: 48.5990274, lng: 17.9076303 },
    { lat: 48.5988732, lng: 17.9076746 },
    { lat: 48.598632, lng: 17.9075296 },
    { lat: 48.5985479, lng: 17.9077081 },
    { lat: 48.5982913, lng: 17.9078345 },
    { lat: 48.5981576, lng: 17.9079958 },
    { lat: 48.5979106, lng: 17.9079593 },
    { lat: 48.5978212, lng: 17.90807 },
    { lat: 48.5977805, lng: 17.9080096 },
    { lat: 48.5975383, lng: 17.9080181 },
    { lat: 48.5975151, lng: 17.9080659 },
    { lat: 48.5973385, lng: 17.9079995 },
    { lat: 48.5971962, lng: 17.9080924 },
    { lat: 48.5970847, lng: 17.9080431 },
    { lat: 48.5962354, lng: 17.9088787 },
    { lat: 48.5959412, lng: 17.9092395 },
    { lat: 48.5957007, lng: 17.9097093 },
    { lat: 48.595611, lng: 17.9099045 },
    { lat: 48.5954647, lng: 17.9105154 },
    { lat: 48.5952703, lng: 17.9108262 },
    { lat: 48.5952188, lng: 17.9110397 },
    { lat: 48.5950592, lng: 17.9113354 },
    { lat: 48.5937068, lng: 17.9126469 },
    { lat: 48.593368, lng: 17.9131471 },
    { lat: 48.5931107, lng: 17.9134312 },
    { lat: 48.5929522, lng: 17.9137417 },
    { lat: 48.5926832, lng: 17.9140752 },
    { lat: 48.5922372, lng: 17.9149927 },
    { lat: 48.5921016, lng: 17.9151018 },
    { lat: 48.5919895, lng: 17.9153777 },
    { lat: 48.591609, lng: 17.9155635 },
    { lat: 48.5913482, lng: 17.9158056 },
    { lat: 48.5910688, lng: 17.915817 },
    { lat: 48.5906552, lng: 17.9141393 },
    { lat: 48.5901086, lng: 17.914454 },
    { lat: 48.5888922, lng: 17.9156233 },
    { lat: 48.58823, lng: 17.9160229 },
    { lat: 48.58737, lng: 17.915849 },
    { lat: 48.58719, lng: 17.916147 },
    { lat: 48.587105, lng: 17.916337 },
    { lat: 48.586926, lng: 17.916694 },
    { lat: 48.586742, lng: 17.916999 },
    { lat: 48.586511, lng: 17.917251 },
    { lat: 48.586438, lng: 17.917341 },
    { lat: 48.586022, lng: 17.917735 },
    { lat: 48.585875, lng: 17.917872 },
    { lat: 48.58519, lng: 17.918794 },
    { lat: 48.58504, lng: 17.919001 },
    { lat: 48.584935, lng: 17.919203 },
    { lat: 48.584787, lng: 17.919353 },
    { lat: 48.584665, lng: 17.919601 },
    { lat: 48.584568, lng: 17.919838 },
    { lat: 48.584387, lng: 17.920105 },
    { lat: 48.584208, lng: 17.920243 },
    { lat: 48.583666, lng: 17.920185 },
    { lat: 48.583131, lng: 17.920174 },
    { lat: 48.583022, lng: 17.920081 },
    { lat: 48.582778, lng: 17.919767 },
    { lat: 48.582617, lng: 17.919639 },
    { lat: 48.582242, lng: 17.919438 },
    { lat: 48.582147, lng: 17.919374 },
    { lat: 48.582112, lng: 17.919369 },
    { lat: 48.582113, lng: 17.919399 },
    { lat: 48.582102, lng: 17.919546 },
    { lat: 48.581951, lng: 17.921067 },
    { lat: 48.581719, lng: 17.923454 },
    { lat: 48.581594, lng: 17.924174 },
    { lat: 48.581614, lng: 17.925504 },
    { lat: 48.581679, lng: 17.928106 },
    { lat: 48.581797, lng: 17.930335 },
    { lat: 48.581909, lng: 17.932235 },
    { lat: 48.581917, lng: 17.932383 },
    { lat: 48.581943, lng: 17.933412 },
    { lat: 48.58198, lng: 17.934437 },
    { lat: 48.582058, lng: 17.936496 },
    { lat: 48.582107, lng: 17.937531 },
    { lat: 48.582221, lng: 17.939785 },
    { lat: 48.582256, lng: 17.94062 },
    { lat: 48.582285, lng: 17.94165 },
    { lat: 48.582296, lng: 17.942156 },
    { lat: 48.582458, lng: 17.946765 },
    { lat: 48.582615, lng: 17.947136 },
    { lat: 48.58306, lng: 17.949153 },
    { lat: 48.583024, lng: 17.952826 },
    { lat: 48.583025, lng: 17.952855 },
    { lat: 48.583543, lng: 17.952785 },
    { lat: 48.583792, lng: 17.952679 },
    { lat: 48.583963, lng: 17.952569 },
    { lat: 48.584184, lng: 17.952553 },
    { lat: 48.584271, lng: 17.952598 },
    { lat: 48.584395, lng: 17.952595 },
    { lat: 48.584548, lng: 17.95268 },
    { lat: 48.584587, lng: 17.952674 },
    { lat: 48.584681, lng: 17.952571 },
    { lat: 48.584805, lng: 17.952557 },
    { lat: 48.584914, lng: 17.952608 },
    { lat: 48.585111, lng: 17.952827 },
    { lat: 48.585205, lng: 17.952814 },
    { lat: 48.585321, lng: 17.952693 },
    { lat: 48.585437, lng: 17.952508 },
    { lat: 48.585502, lng: 17.952442 },
    { lat: 48.585708, lng: 17.952308 },
    { lat: 48.585788, lng: 17.952176 },
    { lat: 48.58585, lng: 17.952051 },
    { lat: 48.586248, lng: 17.951683 },
    { lat: 48.586543, lng: 17.951447 },
    { lat: 48.586732, lng: 17.951331 },
    { lat: 48.586833, lng: 17.951291 },
    { lat: 48.587093, lng: 17.951181 },
    { lat: 48.587183, lng: 17.951133 },
    { lat: 48.587412, lng: 17.951082 },
    { lat: 48.587502, lng: 17.951076 },
    { lat: 48.587787, lng: 17.951007 },
    { lat: 48.58788, lng: 17.950917 },
    { lat: 48.587915, lng: 17.950817 },
    { lat: 48.588096, lng: 17.950555 },
    { lat: 48.588177, lng: 17.950407 },
    { lat: 48.588524, lng: 17.949877 },
    { lat: 48.588725, lng: 17.949598 },
    { lat: 48.589036, lng: 17.949316 },
    { lat: 48.589179, lng: 17.949073 },
    { lat: 48.589874, lng: 17.949175 },
    { lat: 48.590221, lng: 17.949259 },
    { lat: 48.590557, lng: 17.949832 },
    { lat: 48.590747, lng: 17.952563 },
    { lat: 48.590793, lng: 17.953612 },
    { lat: 48.590901, lng: 17.956144 },
    { lat: 48.590832, lng: 17.958366 },
    { lat: 48.590873, lng: 17.960634 },
    { lat: 48.590999, lng: 17.963596 },
    { lat: 48.590933, lng: 17.964946 },
    { lat: 48.591082, lng: 17.967602 },
    { lat: 48.591004, lng: 17.969616 },
    { lat: 48.591071, lng: 17.971713 },
    { lat: 48.591039, lng: 17.974355 },
    { lat: 48.591039, lng: 17.974385 },
    { lat: 48.591158, lng: 17.97446 },
    { lat: 48.591225, lng: 17.974506 },
    { lat: 48.591496, lng: 17.974808 },
    { lat: 48.591723, lng: 17.975046 },
    { lat: 48.591853, lng: 17.975127 },
    { lat: 48.591998, lng: 17.975236 },
    { lat: 48.59219, lng: 17.975375 },
    { lat: 48.59248, lng: 17.975541 },
    { lat: 48.592586, lng: 17.975599 },
    { lat: 48.592682, lng: 17.975609 },
    { lat: 48.592828, lng: 17.975601 },
    { lat: 48.592999, lng: 17.975616 },
    { lat: 48.593151, lng: 17.975655 },
    { lat: 48.593308, lng: 17.975758 },
    { lat: 48.593583, lng: 17.976037 },
    { lat: 48.593767, lng: 17.976188 },
    { lat: 48.593857, lng: 17.976269 },
    { lat: 48.593963, lng: 17.976361 },
    { lat: 48.594076, lng: 17.976419 },
    { lat: 48.594448, lng: 17.97659 },
    { lat: 48.594487, lng: 17.976583 },
    { lat: 48.594638, lng: 17.976516 },
    { lat: 48.594929, lng: 17.976341 },
    { lat: 48.595206, lng: 17.976231 },
    { lat: 48.595416, lng: 17.976251 },
    { lat: 48.595597, lng: 17.976348 },
    { lat: 48.595741, lng: 17.976435 },
    { lat: 48.595843, lng: 17.976486 },
    { lat: 48.596031, lng: 17.976524 },
    { lat: 48.596276, lng: 17.976556 },
    { lat: 48.59632, lng: 17.976596 },
    { lat: 48.596348, lng: 17.976702 },
    { lat: 48.596366, lng: 17.976861 },
    { lat: 48.596311, lng: 17.977234 },
    { lat: 48.5963, lng: 17.977335 },
    { lat: 48.596257, lng: 17.978015 },
    { lat: 48.596208, lng: 17.978537 },
    { lat: 48.596211, lng: 17.978797 },
    { lat: 48.596179, lng: 17.979152 },
    { lat: 48.596111, lng: 17.979638 },
    { lat: 48.596088, lng: 17.980052 },
    { lat: 48.596045, lng: 17.980378 },
    { lat: 48.596051, lng: 17.980579 },
    { lat: 48.596089, lng: 17.980862 },
    { lat: 48.596111, lng: 17.981098 },
    { lat: 48.596115, lng: 17.981505 },
    { lat: 48.596171, lng: 17.981965 },
    { lat: 48.596209, lng: 17.982224 },
    { lat: 48.596231, lng: 17.982383 },
    { lat: 48.596256, lng: 17.982542 },
    { lat: 48.596317, lng: 17.982765 },
    { lat: 48.596421, lng: 17.983016 },
    { lat: 48.596693, lng: 17.983258 },
    { lat: 48.597121, lng: 17.983431 },
    { lat: 48.597425, lng: 17.983548 },
    { lat: 48.598233, lng: 17.983741 },
    { lat: 48.598672, lng: 17.983967 },
    { lat: 48.599003, lng: 17.984184 },
    { lat: 48.599171, lng: 17.984245 },
    { lat: 48.599261, lng: 17.98429 },
    { lat: 48.599636, lng: 17.98431 },
    { lat: 48.599827, lng: 17.98446 },
    { lat: 48.599863, lng: 17.984501 },
    { lat: 48.599914, lng: 17.984578 },
    { lat: 48.599959, lng: 17.984649 },
    { lat: 48.600011, lng: 17.984713 },
    { lat: 48.6001, lng: 17.98479 },
    { lat: 48.600174, lng: 17.984861 },
    { lat: 48.60026, lng: 17.984956 },
    { lat: 48.600342, lng: 17.985026 },
    { lat: 48.600439, lng: 17.985103 },
    { lat: 48.600548, lng: 17.985139 },
    { lat: 48.600669, lng: 17.985215 },
    { lat: 48.600786, lng: 17.98528 },
    { lat: 48.600878, lng: 17.98533 },
    { lat: 48.600929, lng: 17.985382 },
    { lat: 48.601024, lng: 17.98542 },
    { lat: 48.601066, lng: 17.985436 },
    { lat: 48.601195, lng: 17.985462 },
    { lat: 48.601297, lng: 17.985476 },
    { lat: 48.601389, lng: 17.985455 },
    { lat: 48.601483, lng: 17.985441 },
    { lat: 48.601525, lng: 17.98541 },
    { lat: 48.601617, lng: 17.985325 },
    { lat: 48.601718, lng: 17.985234 },
    { lat: 48.601768, lng: 17.985179 },
    { lat: 48.601832, lng: 17.98509 },
    { lat: 48.601874, lng: 17.985041 },
    { lat: 48.601935, lng: 17.984945 },
    { lat: 48.601997, lng: 17.984855 },
    { lat: 48.602051, lng: 17.984777 },
    { lat: 48.602134, lng: 17.984663 },
    { lat: 48.602234, lng: 17.984513 },
    { lat: 48.602345, lng: 17.984356 },
    { lat: 48.602405, lng: 17.984266 },
    { lat: 48.602486, lng: 17.984152 },
    { lat: 48.602532, lng: 17.984086 },
    { lat: 48.602543, lng: 17.984068 },
    { lat: 48.602624, lng: 17.984001 },
    { lat: 48.60274, lng: 17.983898 },
    { lat: 48.602831, lng: 17.983825 },
    { lat: 48.602902, lng: 17.983769 },
    { lat: 48.602982, lng: 17.983702 },
    { lat: 48.603032, lng: 17.983666 },
    { lat: 48.603113, lng: 17.983592 },
    { lat: 48.603148, lng: 17.98355 },
    { lat: 48.603224, lng: 17.983483 },
    { lat: 48.603348, lng: 17.983356 },
    { lat: 48.603463, lng: 17.983229 },
    { lat: 48.603562, lng: 17.983114 },
    { lat: 48.603685, lng: 17.982999 },
    { lat: 48.603751, lng: 17.98295 },
    { lat: 48.603819, lng: 17.982866 },
    { lat: 48.603913, lng: 17.982781 },
    { lat: 48.603989, lng: 17.982702 },
    { lat: 48.60407, lng: 17.982623 },
    { lat: 48.604162, lng: 17.98252 },
    { lat: 48.604216, lng: 17.982454 },
    { lat: 48.604266, lng: 17.982399 },
    { lat: 48.604315, lng: 17.982339 },
    { lat: 48.604392, lng: 17.982225 },
    { lat: 48.60445, lng: 17.982147 },
    { lat: 48.604491, lng: 17.982075 },
    { lat: 48.604583, lng: 17.981943 },
    { lat: 48.604621, lng: 17.981854 },
    { lat: 48.604677, lng: 17.981734 },
    { lat: 48.604734, lng: 17.981592 },
    { lat: 48.604814, lng: 17.981436 },
    { lat: 48.604897, lng: 17.981263 },
    { lat: 48.604969, lng: 17.981114 },
    { lat: 48.605063, lng: 17.980935 },
    { lat: 48.605155, lng: 17.980761 },
    { lat: 48.60525, lng: 17.980576 },
    { lat: 48.605333, lng: 17.980415 },
    { lat: 48.605421, lng: 17.980248 },
    { lat: 48.605653, lng: 17.980205 },
    { lat: 48.60586, lng: 17.980164 },
    { lat: 48.606139, lng: 17.98012 },
    { lat: 48.606555, lng: 17.980049 },
    { lat: 48.606882, lng: 17.979985 },
    { lat: 48.607169, lng: 17.979942 },
    { lat: 48.607238, lng: 17.979928 },
    { lat: 48.607577, lng: 17.97987 },
    { lat: 48.60826, lng: 17.979761 },
    { lat: 48.608607, lng: 17.979703 },
    { lat: 48.608941, lng: 17.979652 },
    { lat: 48.609287, lng: 17.9796 },
    { lat: 48.609702, lng: 17.979528 },
    { lat: 48.60997, lng: 17.979491 },
    { lat: 48.610285, lng: 17.979446 },
    { lat: 48.61065, lng: 17.979388 },
    { lat: 48.610985, lng: 17.979342 },
    { lat: 48.611323, lng: 17.979302 },
    { lat: 48.612007, lng: 17.979199 },
    { lat: 48.612529, lng: 17.979103 },
    { lat: 48.6125064, lng: 17.9789997 },
    { lat: 48.6123936, lng: 17.9784848 },
    { lat: 48.61225, lng: 17.977829 },
    { lat: 48.612242, lng: 17.977641 },
    { lat: 48.612192, lng: 17.97752 },
    { lat: 48.612017, lng: 17.977302 },
    { lat: 48.612013, lng: 17.97718 },
    { lat: 48.612059, lng: 17.9769 },
    { lat: 48.612179, lng: 17.976486 },
    { lat: 48.612156, lng: 17.976341 },
    { lat: 48.61209, lng: 17.97623 },
    { lat: 48.611946, lng: 17.976034 },
    { lat: 48.611793, lng: 17.975678 },
    { lat: 48.611746, lng: 17.975472 },
    { lat: 48.611731, lng: 17.9751 },
    { lat: 48.611711, lng: 17.974764 },
    { lat: 48.611729, lng: 17.974411 },
    { lat: 48.61176, lng: 17.974156 },
    { lat: 48.611805, lng: 17.973925 },
    { lat: 48.611815, lng: 17.973718 },
    { lat: 48.611704, lng: 17.973387 },
    { lat: 48.611627, lng: 17.973251 },
    { lat: 48.611602, lng: 17.973063 },
    { lat: 48.611693, lng: 17.97255 },
    { lat: 48.611667, lng: 17.972144 },
    { lat: 48.611596, lng: 17.971874 },
    { lat: 48.611572, lng: 17.971452 },
    { lat: 48.611603, lng: 17.971196 },
    { lat: 48.611576, lng: 17.970864 },
    { lat: 48.611606, lng: 17.970547 },
    { lat: 48.611621, lng: 17.97038 },
    { lat: 48.611437, lng: 17.970001 },
    { lat: 48.611106, lng: 17.969321 },
    { lat: 48.611091, lng: 17.969288 },
    { lat: 48.611093, lng: 17.969257 },
    { lat: 48.611125, lng: 17.968793 },
    { lat: 48.611172, lng: 17.968129 },
    { lat: 48.611333, lng: 17.965952 },
    { lat: 48.611373, lng: 17.9648286 },
    { lat: 48.611401, lng: 17.964044 },
    { lat: 48.611403, lng: 17.963963 },
    { lat: 48.6114095, lng: 17.9637707 },
    { lat: 48.611411, lng: 17.963727 },
    { lat: 48.611643, lng: 17.963546 },
    { lat: 48.611829, lng: 17.963473 },
    { lat: 48.611995, lng: 17.963382 },
    { lat: 48.612351, lng: 17.963264 },
    { lat: 48.6126633, lng: 17.9631057 },
    { lat: 48.612722, lng: 17.963076 },
    { lat: 48.613186, lng: 17.96294 },
    { lat: 48.613796, lng: 17.962517 },
    { lat: 48.614383, lng: 17.962197 },
    { lat: 48.614758, lng: 17.961861 },
    { lat: 48.614879, lng: 17.961732 },
    { lat: 48.615305, lng: 17.961154 },
    { lat: 48.615736, lng: 17.960326 },
    { lat: 48.616066, lng: 17.959682 },
    { lat: 48.616411, lng: 17.959017 },
    { lat: 48.616776, lng: 17.958534 },
    { lat: 48.616863, lng: 17.958318 },
    { lat: 48.61696, lng: 17.958278 },
    { lat: 48.617065, lng: 17.958379 },
    { lat: 48.618692, lng: 17.95947 },
    { lat: 48.619662, lng: 17.960015 },
    { lat: 48.620089, lng: 17.960413 },
    { lat: 48.620333, lng: 17.960782 },
    { lat: 48.620512, lng: 17.960872 },
    { lat: 48.620751, lng: 17.960343 },
    { lat: 48.621011, lng: 17.95972 },
    { lat: 48.621065, lng: 17.959343 },
    { lat: 48.62113, lng: 17.958762 },
    { lat: 48.621363, lng: 17.958104 },
    { lat: 48.621412, lng: 17.957912 },
    { lat: 48.621886, lng: 17.956974 },
    { lat: 48.621946, lng: 17.956867 },
    { lat: 48.622005, lng: 17.95672 },
    { lat: 48.621947, lng: 17.956499 },
    { lat: 48.62184, lng: 17.955698 },
    { lat: 48.621834, lng: 17.95521 },
    { lat: 48.621728, lng: 17.954082 },
    { lat: 48.621681, lng: 17.952253 },
    { lat: 48.621608, lng: 17.950317 },
    { lat: 48.6216395, lng: 17.9502426 },
    { lat: 48.621586, lng: 17.94972 },
    { lat: 48.621526, lng: 17.948059 },
    { lat: 48.621607, lng: 17.945065 },
    { lat: 48.62133, lng: 17.942616 },
    { lat: 48.621051, lng: 17.940425 },
    { lat: 48.620945, lng: 17.940003 },
    { lat: 48.621205, lng: 17.939938 },
    { lat: 48.621379, lng: 17.939951 },
    { lat: 48.621508, lng: 17.939954 },
    { lat: 48.621808, lng: 17.939833 },
    { lat: 48.622056, lng: 17.939711 },
    { lat: 48.622198, lng: 17.939618 },
    { lat: 48.622478, lng: 17.93932 },
    { lat: 48.622569, lng: 17.939223 },
    { lat: 48.622605, lng: 17.938551 },
    { lat: 48.622466, lng: 17.937125 },
    { lat: 48.622303, lng: 17.935414 },
    { lat: 48.622295, lng: 17.935347 },
    { lat: 48.622199, lng: 17.934335 },
    { lat: 48.621756, lng: 17.934278 },
    { lat: 48.621862, lng: 17.933265 },
    { lat: 48.621737, lng: 17.931831 },
    { lat: 48.621585, lng: 17.931674 },
    { lat: 48.621698, lng: 17.931458 },
    { lat: 48.621714, lng: 17.931372 },
    { lat: 48.621785, lng: 17.931199 },
    { lat: 48.622045, lng: 17.93087 },
    { lat: 48.622307, lng: 17.930669 },
    { lat: 48.622529, lng: 17.930446 },
    { lat: 48.622609, lng: 17.930324 },
    { lat: 48.622685, lng: 17.930142 },
    { lat: 48.622705, lng: 17.929922 },
    { lat: 48.622775, lng: 17.929674 },
    { lat: 48.622814, lng: 17.929541 },
    { lat: 48.622835, lng: 17.929453 },
    { lat: 48.622866, lng: 17.929325 },
    { lat: 48.623, lng: 17.929083 },
    { lat: 48.623148, lng: 17.928751 },
    { lat: 48.623369, lng: 17.928035 },
    { lat: 48.623612, lng: 17.92756 },
    { lat: 48.623671, lng: 17.9273 },
    { lat: 48.623709, lng: 17.92698 },
    { lat: 48.623747, lng: 17.926686 },
    { lat: 48.623772, lng: 17.926539 },
    { lat: 48.623763, lng: 17.926421 },
    { lat: 48.623828, lng: 17.926038 },
    { lat: 48.623955, lng: 17.925676 },
    { lat: 48.624012, lng: 17.925468 },
    { lat: 48.624015, lng: 17.925158 },
    { lat: 48.624073, lng: 17.924958 },
    { lat: 48.624053, lng: 17.924676 },
    { lat: 48.624292, lng: 17.924756 },
    { lat: 48.624348, lng: 17.924948 },
    { lat: 48.624395, lng: 17.92528 },
    { lat: 48.624476, lng: 17.925444 },
    { lat: 48.624457, lng: 17.925829 },
    { lat: 48.624854, lng: 17.926036 },
    { lat: 48.625001, lng: 17.925954 },
    { lat: 48.624962, lng: 17.925299 },
    { lat: 48.624868, lng: 17.925266 },
    { lat: 48.624715, lng: 17.925079 },
    { lat: 48.624716, lng: 17.92497 },
    { lat: 48.624593, lng: 17.924808 },
    { lat: 48.624473, lng: 17.924529 },
    { lat: 48.624672, lng: 17.924233 },
    { lat: 48.624833, lng: 17.923954 },
    { lat: 48.625015, lng: 17.923543 },
    { lat: 48.625092, lng: 17.92344 },
    { lat: 48.6252813, lng: 17.9232271 },
    { lat: 48.625582, lng: 17.922889 },
    { lat: 48.6255117, lng: 17.9228747 },
    { lat: 48.624813, lng: 17.922732 },
    { lat: 48.624799, lng: 17.922719 },
    { lat: 48.624813, lng: 17.922655 },
    { lat: 48.624891, lng: 17.922357 },
    { lat: 48.625078, lng: 17.922015 },
    { lat: 48.625141, lng: 17.921875 },
    { lat: 48.625137, lng: 17.92157 },
    { lat: 48.62519, lng: 17.921241 },
    { lat: 48.625194, lng: 17.920983 },
    { lat: 48.625144, lng: 17.920541 },
    { lat: 48.62528, lng: 17.920027 },
    { lat: 48.625361, lng: 17.919618 },
    { lat: 48.625409, lng: 17.919372 },
    { lat: 48.625419, lng: 17.919266 },
    { lat: 48.625489, lng: 17.919077 },
    { lat: 48.625583, lng: 17.918744 },
    { lat: 48.625876, lng: 17.917535 },
    { lat: 48.62539, lng: 17.916412 },
    { lat: 48.625307, lng: 17.915024 },
    { lat: 48.625315, lng: 17.914756 },
    { lat: 48.625362, lng: 17.913176 },
    { lat: 48.625331, lng: 17.912645 },
    { lat: 48.625196, lng: 17.911133 },
    { lat: 48.625103, lng: 17.910096 },
    { lat: 48.625387, lng: 17.908189 },
    { lat: 48.625811, lng: 17.905777 },
    { lat: 48.626434, lng: 17.904209 },
    { lat: 48.62683, lng: 17.903244 },
    { lat: 48.62732, lng: 17.902468 },
    { lat: 48.627748, lng: 17.901736 },
    { lat: 48.628939, lng: 17.899825 },
    { lat: 48.629425, lng: 17.899045 },
    { lat: 48.629523, lng: 17.898868 },
    { lat: 48.629687, lng: 17.898654 },
    { lat: 48.629787, lng: 17.898551 },
    { lat: 48.629961, lng: 17.898294 },
    { lat: 48.630133, lng: 17.897979 },
    { lat: 48.630305, lng: 17.897413 },
    { lat: 48.630891, lng: 17.89642 },
    { lat: 48.631333, lng: 17.896086 },
    { lat: 48.631474, lng: 17.896214 },
    { lat: 48.631577, lng: 17.896339 },
    { lat: 48.631731, lng: 17.896566 },
    { lat: 48.631818, lng: 17.896746 },
    { lat: 48.631854, lng: 17.896758 },
    { lat: 48.632207, lng: 17.897198 },
    { lat: 48.632249, lng: 17.897091 },
    { lat: 48.632079, lng: 17.896277 },
    { lat: 48.63212, lng: 17.896099 },
    { lat: 48.632276, lng: 17.895966 },
    { lat: 48.632391, lng: 17.895812 },
    { lat: 48.630842, lng: 17.894146 },
    { lat: 48.630231, lng: 17.890994 },
    { lat: 48.62998, lng: 17.889969 },
    { lat: 48.629538, lng: 17.889422 },
    { lat: 48.629119, lng: 17.88875 },
    { lat: 48.62904, lng: 17.888485 },
    { lat: 48.629001, lng: 17.888248 },
    { lat: 48.629203, lng: 17.887736 },
    { lat: 48.629173, lng: 17.887595 },
    { lat: 48.629185, lng: 17.887391 },
    { lat: 48.629256, lng: 17.887088 },
    { lat: 48.629508, lng: 17.886366 },
    { lat: 48.629534, lng: 17.886203 },
    { lat: 48.6290347, lng: 17.8860456 },
    { lat: 48.6288801, lng: 17.8862499 },
    { lat: 48.628643, lng: 17.8862297 },
    { lat: 48.6283208, lng: 17.8863346 },
    { lat: 48.6281959, lng: 17.8863232 },
    { lat: 48.6280141, lng: 17.8861327 },
    { lat: 48.627952, lng: 17.8858325 },
    { lat: 48.6282431, lng: 17.885217 },
    { lat: 48.6282458, lng: 17.8849996 },
    { lat: 48.6279479, lng: 17.884571 },
    { lat: 48.6260532, lng: 17.8824142 },
    { lat: 48.6257395, lng: 17.8816687 },
    { lat: 48.6261167, lng: 17.8809432 },
    { lat: 48.6263293, lng: 17.8806485 },
    { lat: 48.6264872, lng: 17.8805336 },
    { lat: 48.6266432, lng: 17.880054 },
    { lat: 48.6270602, lng: 17.8802643 },
    { lat: 48.6271797, lng: 17.8802461 },
    { lat: 48.6269834, lng: 17.8800971 },
    { lat: 48.626558, lng: 17.879559 },
    { lat: 48.6255623, lng: 17.8778019 },
    { lat: 48.6258374, lng: 17.8776635 },
    { lat: 48.6252644, lng: 17.8764963 },
    { lat: 48.6241046, lng: 17.8770298 },
    { lat: 48.6241836, lng: 17.8764344 },
    { lat: 48.624435, lng: 17.8759406 },
    { lat: 48.6242716, lng: 17.8751442 },
    { lat: 48.6234865, lng: 17.8750232 },
    { lat: 48.6229185, lng: 17.8737851 },
    { lat: 48.6225077, lng: 17.8736382 },
    { lat: 48.6222003, lng: 17.8731678 },
    { lat: 48.622277, lng: 17.8726242 },
    { lat: 48.6227238, lng: 17.8723418 },
    { lat: 48.6228649, lng: 17.8721572 },
    { lat: 48.6229863, lng: 17.8721658 },
    { lat: 48.6232249, lng: 17.8725622 },
    { lat: 48.6234443, lng: 17.8726273 },
    { lat: 48.6235233, lng: 17.872291 },
    { lat: 48.6237119, lng: 17.8721045 },
    { lat: 48.6240935, lng: 17.8720123 },
    { lat: 48.6241714, lng: 17.8702964 },
    { lat: 48.6247367, lng: 17.8702367 },
    { lat: 48.625191, lng: 17.8700389 },
    { lat: 48.6252531, lng: 17.8687918 },
    { lat: 48.6252057, lng: 17.8675591 },
    { lat: 48.6250035, lng: 17.8668676 },
    { lat: 48.6247062, lng: 17.8660961 },
    { lat: 48.624995, lng: 17.8660552 },
    { lat: 48.6250907, lng: 17.8659753 },
    { lat: 48.625033, lng: 17.8657139 },
    { lat: 48.6249423, lng: 17.8657638 },
    { lat: 48.6247659, lng: 17.8640611 },
    { lat: 48.6248066, lng: 17.8635431 },
    { lat: 48.6250244, lng: 17.8626631 },
    { lat: 48.6251416, lng: 17.8619543 },
    { lat: 48.6252414, lng: 17.8616455 },
    { lat: 48.6251358, lng: 17.8606292 },
    { lat: 48.6251866, lng: 17.8606275 },
    { lat: 48.6252514, lng: 17.8603734 },
    { lat: 48.6252964, lng: 17.8592743 },
    { lat: 48.6251902, lng: 17.8559975 },
    { lat: 48.6251894, lng: 17.8559841 },
  ],
  Ostrov: [
    { lat: 48.640598, lng: 17.781468 },
    { lat: 48.640552, lng: 17.781285 },
    { lat: 48.640497, lng: 17.781073 },
    { lat: 48.640491, lng: 17.780651 },
    { lat: 48.640515, lng: 17.780518 },
    { lat: 48.640682, lng: 17.780021 },
    { lat: 48.640955, lng: 17.779445 },
    { lat: 48.641084, lng: 17.779137 },
    { lat: 48.641193, lng: 17.778747 },
    { lat: 48.641233, lng: 17.778603 },
    { lat: 48.641357, lng: 17.778097 },
    { lat: 48.643157, lng: 17.770759 },
    { lat: 48.643907, lng: 17.767665 },
    { lat: 48.643988, lng: 17.767337 },
    { lat: 48.644556, lng: 17.764995 },
    { lat: 48.644588, lng: 17.764935 },
    { lat: 48.644605, lng: 17.764867 },
    { lat: 48.644722, lng: 17.764866 },
    { lat: 48.644734, lng: 17.764819 },
    { lat: 48.645032, lng: 17.76356 },
    { lat: 48.645559, lng: 17.761759 },
    { lat: 48.645934, lng: 17.760655 },
    { lat: 48.645996, lng: 17.76042 },
    { lat: 48.646167, lng: 17.759771 },
    { lat: 48.646509, lng: 17.758829 },
    { lat: 48.646526, lng: 17.758536 },
    { lat: 48.646553, lng: 17.758351 },
    { lat: 48.64663, lng: 17.758189 },
    { lat: 48.646722, lng: 17.758024 },
    { lat: 48.646833, lng: 17.757814 },
    { lat: 48.647068, lng: 17.757248 },
    { lat: 48.64731, lng: 17.756607 },
    { lat: 48.647415, lng: 17.756378 },
    { lat: 48.647553, lng: 17.756143 },
    { lat: 48.647781, lng: 17.755807 },
    { lat: 48.648333, lng: 17.755094 },
    { lat: 48.648534, lng: 17.754815 },
    { lat: 48.64892, lng: 17.754237 },
    { lat: 48.64922, lng: 17.753825 },
    { lat: 48.649402, lng: 17.753599 },
    { lat: 48.649489, lng: 17.753406 },
    { lat: 48.649712, lng: 17.752922 },
    { lat: 48.649842, lng: 17.75264 },
    { lat: 48.650121, lng: 17.751809 },
    { lat: 48.65027, lng: 17.751381 },
    { lat: 48.650373, lng: 17.751023 },
    { lat: 48.650426, lng: 17.750776 },
    { lat: 48.650806, lng: 17.749593 },
    { lat: 48.650866, lng: 17.74932 },
    { lat: 48.650904, lng: 17.749103 },
    { lat: 48.65111, lng: 17.748022 },
    { lat: 48.65121, lng: 17.747315 },
    { lat: 48.651211, lng: 17.74712 },
    { lat: 48.651237, lng: 17.7469 },
    { lat: 48.651219, lng: 17.746363 },
    { lat: 48.651165, lng: 17.745916 },
    { lat: 48.651112, lng: 17.745729 },
    { lat: 48.651125, lng: 17.745296 },
    { lat: 48.651279, lng: 17.744627 },
    { lat: 48.6512281, lng: 17.7446084 },
    { lat: 48.6512809, lng: 17.7441639 },
    { lat: 48.6503664, lng: 17.7431553 },
    { lat: 48.6487456, lng: 17.7411287 },
    { lat: 48.6483079, lng: 17.7403294 },
    { lat: 48.6484762, lng: 17.7401811 },
    { lat: 48.6484317, lng: 17.7400852 },
    { lat: 48.6468375, lng: 17.7370443 },
    { lat: 48.6472074, lng: 17.7364679 },
    { lat: 48.6473165, lng: 17.7361155 },
    { lat: 48.6473096, lng: 17.7358919 },
    { lat: 48.6471138, lng: 17.7352442 },
    { lat: 48.6468289, lng: 17.7346155 },
    { lat: 48.6466673, lng: 17.7339552 },
    { lat: 48.6462393, lng: 17.7329464 },
    { lat: 48.6461343, lng: 17.7324328 },
    { lat: 48.6457048, lng: 17.7313081 },
    { lat: 48.6451873, lng: 17.7302262 },
    { lat: 48.6445095, lng: 17.7291576 },
    { lat: 48.6438926, lng: 17.7279602 },
    { lat: 48.6433045, lng: 17.7266116 },
    { lat: 48.6428275, lng: 17.7253585 },
    { lat: 48.6428095, lng: 17.7253049 },
    { lat: 48.6424588, lng: 17.7255485 },
    { lat: 48.6405773, lng: 17.7266003 },
    { lat: 48.6393775, lng: 17.7273916 },
    { lat: 48.6384149, lng: 17.7285347 },
    { lat: 48.6379221, lng: 17.7292751 },
    { lat: 48.6325695, lng: 17.7394509 },
    { lat: 48.628047, lng: 17.7497544 },
    { lat: 48.6269442, lng: 17.7524739 },
    { lat: 48.6267532, lng: 17.7526726 },
    { lat: 48.624906, lng: 17.7603121 },
    { lat: 48.6244503, lng: 17.7622038 },
    { lat: 48.6225265, lng: 17.7621856 },
    { lat: 48.6219799, lng: 17.7629918 },
    { lat: 48.6219261, lng: 17.7632181 },
    { lat: 48.6224066, lng: 17.763668 },
    { lat: 48.6223699, lng: 17.7648189 },
    { lat: 48.6223083, lng: 17.7658535 },
    { lat: 48.6221717, lng: 17.7666994 },
    { lat: 48.6220912, lng: 17.7670257 },
    { lat: 48.6217737, lng: 17.7675529 },
    { lat: 48.6211756, lng: 17.7671355 },
    { lat: 48.619928, lng: 17.7669684 },
    { lat: 48.6196159, lng: 17.7702502 },
    { lat: 48.6193808, lng: 17.7719195 },
    { lat: 48.6192808, lng: 17.7733461 },
    { lat: 48.6188959, lng: 17.7732357 },
    { lat: 48.6180706, lng: 17.7727747 },
    { lat: 48.6175603, lng: 17.772335 },
    { lat: 48.6168284, lng: 17.7720348 },
    { lat: 48.6136814, lng: 17.7716964 },
    { lat: 48.6132289, lng: 17.7718078 },
    { lat: 48.6123116, lng: 17.7715991 },
    { lat: 48.6121801, lng: 17.7729682 },
    { lat: 48.6120646, lng: 17.7739926 },
    { lat: 48.6125851, lng: 17.7754266 },
    { lat: 48.6141105, lng: 17.7759665 },
    { lat: 48.615941, lng: 17.7769084 },
    { lat: 48.6174015, lng: 17.7784879 },
    { lat: 48.6186568, lng: 17.779294 },
    { lat: 48.619121, lng: 17.779499 },
    { lat: 48.6199528, lng: 17.7797341 },
    { lat: 48.6214125, lng: 17.7798822 },
    { lat: 48.6219634, lng: 17.7800662 },
    { lat: 48.6225363, lng: 17.7804234 },
    { lat: 48.6242864, lng: 17.7811249 },
    { lat: 48.6248436, lng: 17.7815226 },
    { lat: 48.625362, lng: 17.7819873 },
    { lat: 48.6262173, lng: 17.7825125 },
    { lat: 48.6279061, lng: 17.7844811 },
    { lat: 48.6281253, lng: 17.7846332 },
    { lat: 48.6283683, lng: 17.7847114 },
    { lat: 48.6284254, lng: 17.7849244 },
    { lat: 48.62845, lng: 17.7853264 },
    { lat: 48.6288478, lng: 17.7856456 },
    { lat: 48.6288406, lng: 17.785693 },
    { lat: 48.6293378, lng: 17.7857739 },
    { lat: 48.6300186, lng: 17.7861819 },
    { lat: 48.6310143, lng: 17.786567 },
    { lat: 48.6312964, lng: 17.7869895 },
    { lat: 48.6322954, lng: 17.7874838 },
    { lat: 48.6328386, lng: 17.787508 },
    { lat: 48.6332415, lng: 17.7873765 },
    { lat: 48.6334446, lng: 17.7872822 },
    { lat: 48.6337963, lng: 17.7872721 },
    { lat: 48.6343772, lng: 17.7872625 },
    { lat: 48.6349857, lng: 17.7879822 },
    { lat: 48.6354543, lng: 17.7882984 },
    { lat: 48.6361197, lng: 17.7886368 },
    { lat: 48.6365206, lng: 17.7886938 },
    { lat: 48.6366305, lng: 17.788773 },
    { lat: 48.636763, lng: 17.788714 },
    { lat: 48.636732, lng: 17.788426 },
    { lat: 48.636732, lng: 17.788315 },
    { lat: 48.63675, lng: 17.788222 },
    { lat: 48.63682, lng: 17.788077 },
    { lat: 48.636912, lng: 17.788025 },
    { lat: 48.637003, lng: 17.787979 },
    { lat: 48.637137, lng: 17.787877 },
    { lat: 48.637182, lng: 17.787778 },
    { lat: 48.637189, lng: 17.787618 },
    { lat: 48.637227, lng: 17.787515 },
    { lat: 48.637287, lng: 17.787432 },
    { lat: 48.63735, lng: 17.787377 },
    { lat: 48.637527, lng: 17.787224 },
    { lat: 48.637533, lng: 17.787245 },
    { lat: 48.637636, lng: 17.787116 },
    { lat: 48.637799, lng: 17.786915 },
    { lat: 48.638014, lng: 17.786539 },
    { lat: 48.638112, lng: 17.786347 },
    { lat: 48.638114, lng: 17.786271 },
    { lat: 48.638129, lng: 17.785757 },
    { lat: 48.638384, lng: 17.785598 },
    { lat: 48.63852, lng: 17.785519 },
    { lat: 48.63858, lng: 17.785455 },
    { lat: 48.638684, lng: 17.785184 },
    { lat: 48.638498, lng: 17.78514 },
    { lat: 48.63854, lng: 17.78451 },
    { lat: 48.638608, lng: 17.784011 },
    { lat: 48.638628, lng: 17.783411 },
    { lat: 48.638543, lng: 17.782483 },
    { lat: 48.638546, lng: 17.782409 },
    { lat: 48.638711, lng: 17.782316 },
    { lat: 48.638825, lng: 17.782303 },
    { lat: 48.639033, lng: 17.782281 },
    { lat: 48.639138, lng: 17.78227 },
    { lat: 48.639254, lng: 17.782294 },
    { lat: 48.639425, lng: 17.782332 },
    { lat: 48.639572, lng: 17.782422 },
    { lat: 48.639679, lng: 17.782402 },
    { lat: 48.639847, lng: 17.782326 },
    { lat: 48.640025, lng: 17.782179 },
    { lat: 48.640248, lng: 17.781847 },
    { lat: 48.640414, lng: 17.781494 },
    { lat: 48.640598, lng: 17.781468 },
  ],
  Ducové: [
    { lat: 48.629534, lng: 17.886203 },
    { lat: 48.62959, lng: 17.885887 },
    { lat: 48.629777, lng: 17.885945 },
    { lat: 48.629978, lng: 17.88514 },
    { lat: 48.627415, lng: 17.883735 },
    { lat: 48.62736, lng: 17.882925 },
    { lat: 48.627567, lng: 17.882989 },
    { lat: 48.627563, lng: 17.882759 },
    { lat: 48.627593, lng: 17.882448 },
    { lat: 48.627638, lng: 17.882185 },
    { lat: 48.627657, lng: 17.881609 },
    { lat: 48.627618, lng: 17.880466 },
    { lat: 48.628053, lng: 17.880466 },
    { lat: 48.628398, lng: 17.880475 },
    { lat: 48.629335, lng: 17.88064 },
    { lat: 48.629452, lng: 17.880622 },
    { lat: 48.630042, lng: 17.880402 },
    { lat: 48.630813, lng: 17.880106 },
    { lat: 48.632273, lng: 17.879895 },
    { lat: 48.632477, lng: 17.87987 },
    { lat: 48.632981, lng: 17.879805 },
    { lat: 48.63373, lng: 17.879473 },
    { lat: 48.634327, lng: 17.879181 },
    { lat: 48.634305, lng: 17.878525 },
    { lat: 48.634374, lng: 17.877925 },
    { lat: 48.634383, lng: 17.877849 },
    { lat: 48.634387, lng: 17.877806 },
    { lat: 48.634418, lng: 17.877435 },
    { lat: 48.634467, lng: 17.876933 },
    { lat: 48.634608, lng: 17.876188 },
    { lat: 48.634705, lng: 17.875929 },
    { lat: 48.634807, lng: 17.875814 },
    { lat: 48.635706, lng: 17.874905 },
    { lat: 48.63596, lng: 17.874609 },
    { lat: 48.636137, lng: 17.874358 },
    { lat: 48.636155, lng: 17.874327 },
    { lat: 48.636466, lng: 17.873853 },
    { lat: 48.636639, lng: 17.873655 },
    { lat: 48.637174, lng: 17.872865 },
    { lat: 48.637549, lng: 17.872242 },
    { lat: 48.637686, lng: 17.871927 },
    { lat: 48.637683, lng: 17.871768 },
    { lat: 48.637631, lng: 17.871646 },
    { lat: 48.63752, lng: 17.871475 },
    { lat: 48.63745, lng: 17.871251 },
    { lat: 48.637425, lng: 17.871171 },
    { lat: 48.637382, lng: 17.871129 },
    { lat: 48.637332, lng: 17.871092 },
    { lat: 48.637249, lng: 17.870981 },
    { lat: 48.637209, lng: 17.870842 },
    { lat: 48.637218, lng: 17.870621 },
    { lat: 48.637241, lng: 17.870221 },
    { lat: 48.63726, lng: 17.869809 },
    { lat: 48.637283, lng: 17.869349 },
    { lat: 48.637342, lng: 17.86836 },
    { lat: 48.637346, lng: 17.868207 },
    { lat: 48.637443, lng: 17.866543 },
    { lat: 48.637553, lng: 17.866077 },
    { lat: 48.637617, lng: 17.865895 },
    { lat: 48.637686, lng: 17.865807 },
    { lat: 48.637733, lng: 17.865779 },
    { lat: 48.63777, lng: 17.865752 },
    { lat: 48.638037, lng: 17.865564 },
    { lat: 48.638799, lng: 17.865167 },
    { lat: 48.63865, lng: 17.864345 },
    { lat: 48.638467, lng: 17.863413 },
    { lat: 48.638459, lng: 17.863372 },
    { lat: 48.638446, lng: 17.86331 },
    { lat: 48.638435, lng: 17.863262 },
    { lat: 48.638429, lng: 17.863225 },
    { lat: 48.638419, lng: 17.863179 },
    { lat: 48.638166, lng: 17.861836 },
    { lat: 48.638086, lng: 17.861415 },
    { lat: 48.637532, lng: 17.858475 },
    { lat: 48.637255, lng: 17.858072 },
    { lat: 48.636568, lng: 17.857793 },
    { lat: 48.636268, lng: 17.857701 },
    { lat: 48.635973, lng: 17.85749 },
    { lat: 48.635634, lng: 17.857259 },
    { lat: 48.635179, lng: 17.856793 },
    { lat: 48.634488, lng: 17.856487 },
    { lat: 48.634265, lng: 17.856406 },
    { lat: 48.633892, lng: 17.856461 },
    { lat: 48.63311, lng: 17.856667 },
    { lat: 48.632914, lng: 17.856482 },
    { lat: 48.63274, lng: 17.856462 },
    { lat: 48.632714, lng: 17.856459 },
    { lat: 48.632245, lng: 17.856403 },
    { lat: 48.632582, lng: 17.855481 },
    { lat: 48.632635, lng: 17.854327 },
    { lat: 48.63246, lng: 17.852807 },
    { lat: 48.63232, lng: 17.852308 },
    { lat: 48.632685, lng: 17.851624 },
    { lat: 48.632909, lng: 17.851204 },
    { lat: 48.6291032, lng: 17.8509968 },
    { lat: 48.6256617, lng: 17.855394 },
    { lat: 48.6251894, lng: 17.8559841 },
    { lat: 48.6251902, lng: 17.8559975 },
    { lat: 48.6252964, lng: 17.8592743 },
    { lat: 48.6252514, lng: 17.8603734 },
    { lat: 48.6251866, lng: 17.8606275 },
    { lat: 48.6251358, lng: 17.8606292 },
    { lat: 48.6252414, lng: 17.8616455 },
    { lat: 48.6251416, lng: 17.8619543 },
    { lat: 48.6250244, lng: 17.8626631 },
    { lat: 48.6248066, lng: 17.8635431 },
    { lat: 48.6247659, lng: 17.8640611 },
    { lat: 48.6249423, lng: 17.8657638 },
    { lat: 48.625033, lng: 17.8657139 },
    { lat: 48.6250907, lng: 17.8659753 },
    { lat: 48.624995, lng: 17.8660552 },
    { lat: 48.6247062, lng: 17.8660961 },
    { lat: 48.6250035, lng: 17.8668676 },
    { lat: 48.6252057, lng: 17.8675591 },
    { lat: 48.6252531, lng: 17.8687918 },
    { lat: 48.625191, lng: 17.8700389 },
    { lat: 48.6247367, lng: 17.8702367 },
    { lat: 48.6241714, lng: 17.8702964 },
    { lat: 48.6240935, lng: 17.8720123 },
    { lat: 48.6237119, lng: 17.8721045 },
    { lat: 48.6235233, lng: 17.872291 },
    { lat: 48.6234443, lng: 17.8726273 },
    { lat: 48.6232249, lng: 17.8725622 },
    { lat: 48.6229863, lng: 17.8721658 },
    { lat: 48.6228649, lng: 17.8721572 },
    { lat: 48.6227238, lng: 17.8723418 },
    { lat: 48.622277, lng: 17.8726242 },
    { lat: 48.6222003, lng: 17.8731678 },
    { lat: 48.6225077, lng: 17.8736382 },
    { lat: 48.6229185, lng: 17.8737851 },
    { lat: 48.6234865, lng: 17.8750232 },
    { lat: 48.6242716, lng: 17.8751442 },
    { lat: 48.624435, lng: 17.8759406 },
    { lat: 48.6241836, lng: 17.8764344 },
    { lat: 48.6241046, lng: 17.8770298 },
    { lat: 48.6252644, lng: 17.8764963 },
    { lat: 48.6258374, lng: 17.8776635 },
    { lat: 48.6255623, lng: 17.8778019 },
    { lat: 48.626558, lng: 17.879559 },
    { lat: 48.6269834, lng: 17.8800971 },
    { lat: 48.6271797, lng: 17.8802461 },
    { lat: 48.6270602, lng: 17.8802643 },
    { lat: 48.6266432, lng: 17.880054 },
    { lat: 48.6264872, lng: 17.8805336 },
    { lat: 48.6263293, lng: 17.8806485 },
    { lat: 48.6261167, lng: 17.8809432 },
    { lat: 48.6257395, lng: 17.8816687 },
    { lat: 48.6260532, lng: 17.8824142 },
    { lat: 48.6279479, lng: 17.884571 },
    { lat: 48.6282458, lng: 17.8849996 },
    { lat: 48.6282431, lng: 17.885217 },
    { lat: 48.627952, lng: 17.8858325 },
    { lat: 48.6280141, lng: 17.8861327 },
    { lat: 48.6281959, lng: 17.8863232 },
    { lat: 48.6283208, lng: 17.8863346 },
    { lat: 48.628643, lng: 17.8862297 },
    { lat: 48.6288801, lng: 17.8862499 },
    { lat: 48.6290347, lng: 17.8860456 },
    { lat: 48.629534, lng: 17.886203 },
  ],
};

export default UnitsPiešťany;
