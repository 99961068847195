import { motion } from "framer-motion";
import { useMediaQuery } from "usehooks-ts";
import clsx from "clsx";

export default function SubjectsScrollMenu({ size, showMenu, subMenu, subjectsMenu }) {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 640px)");
  return (
    <motion.div
      className={clsx("flex flex-col items-start overflow-x-scroll", {
        "no-scrollbar w-auto": isSmallDevice,
        "w-full": !isSmallDevice && size.full,
      })}
    >
      <div
        className={clsx("text-base w-auto flex items-center font-semibold flex-row", {
          "w-auto": (isSmallDevice || !size.full) && !subjectsMenu.FIB && !subjectsMenu.REB,
          "w-full": (!isSmallDevice && size.full) || subjectsMenu.FIB || subjectsMenu.REB,
        })}
      >
        <button
          onClick={(e) => {
            showMenu("ow");
            e.stopPropagation();
          }}
          className={clsx("py-1 border-b-2 border-gray-300", {
            "border-b-2 !border-sky-500 text-sky-500": subMenu?.owner,
            "w-24": (isSmallDevice || !size.full) && !subjectsMenu.FIB && !subjectsMenu.REB,
            "w-[20%]": !isSmallDevice && size.full && !subjectsMenu.FIB && !subjectsMenu.REB,
            "w-[33.3333%]": subjectsMenu.FIB || subjectsMenu.REB,
          })}
        >
          Informácie
        </button>
        <button
          onClick={(e) => {
            showMenu("lv");
            e.stopPropagation();
          }}
          className={clsx("py-1 border-b-2 border-gray-300", {
            "border-b-2 !border-sky-500 text-sky-500": subMenu?.lv,
            "w-32": (isSmallDevice || !size.full) && !subjectsMenu.FIB && !subjectsMenu.REB,
            "w-[20%]": !isSmallDevice && size.full && !subjectsMenu.FIB && !subjectsMenu.REB,
            "w-[33.3333%]": subjectsMenu.FIB || subjectsMenu.REB,
          })}
        >
          Nehnuteľnosti
        </button>
        {!subjectsMenu.FIB && !subjectsMenu.REB ? (
          <button
            onClick={(e) => {
              showMenu("in");
              e.stopPropagation();
            }}
            className={clsx("py-1 border-b-2 border-gray-300", {
              "border-b-2 !border-sky-500 text-sky-500": subMenu?.interest,
              "w-24": isSmallDevice || !size.full,
              "w-[20%]": !isSmallDevice && size.full,
            })}
          >
            Makléri
          </button>
        ) : null}
        <button
          onClick={(e) => {
            showMenu("ra");
            e.stopPropagation();
          }}
          className={clsx("py-1 border-b-2 border-gray-300", {
            "border-b-2 !border-sky-500 text-sky-500": subMenu?.rating,
            "w-24": (isSmallDevice || !size.full) && !subjectsMenu.FIB && !subjectsMenu.REB,
            "w-[20%]": !isSmallDevice && size.full && !subjectsMenu.FIB && !subjectsMenu.REB,
            "w-[33.3333%]": subjectsMenu.FIB || subjectsMenu.REB,
          })}
        >
          Partneri
        </button>
        {!subjectsMenu.FIB && !subjectsMenu.REB ? (
          <button
            onClick={(e) => {
              showMenu("sh");
              e.stopPropagation();
            }}
            className={clsx("py-1 border-b-2 border-gray-300", {
              "border-b-2 !border-sky-500 text-sky-500": subMenu?.share,
              "w-40": isSmallDevice || !size.full,
              "w-[20%]": !isSmallDevice && size.full,
            })}
          >
            Makléri partnerov
          </button>
        ) : null}
      </div>
    </motion.div>
  );
}
