import { motion } from "framer-motion";
import clsx from "clsx";

export default function Type({ filters, setFilters, isFilter }) {
  return (
    <motion.div
      className="w-full h-10 flex flex-col justify-center items-center select-none gap-1"
      initial={{ height: 30 }}
      animate={{ height: isFilter.type ? 120 : 30 }}
      transition={{ duration: 0.5 }}
    >
      <div className="w-full flex justify-start">
        <p className="">Typ: </p>
      </div>
      <button
        onClick={() => setFilters({ ...filters, type: "Dom" })}
        className={clsx("w-full h-8 bg-sky-300 rounded-lg", { "bg-sky-400": filters.type === "Dom" })}
      >
        Dom
      </button>
      <button
        onClick={() => {
          setFilters({ ...filters, type: "Byt" });
        }}
        className={clsx("w-full h-8 bg-sky-300 rounded-lg", { "bg-sky-400": filters.type === "Byt" })}
      >
        Byt
      </button>
      <button
        onClick={() => setFilters({ ...filters, type: "Pozemok" })}
        className={clsx("w-full h-8 bg-sky-300 rounded-lg", { "bg-sky-400": filters.type === "Pozemok" })}
      >
        Pozemok
      </button>
    </motion.div>
  );
}
