const UnitsRužomberok = {
  Bešeňová: [
    { lat: 49.104571, lng: 19.450978 },
    { lat: 49.1050583, lng: 19.4457973 },
    { lat: 49.1050764, lng: 19.4449729 },
    { lat: 49.1054396, lng: 19.4457348 },
    { lat: 49.1055173, lng: 19.4461224 },
    { lat: 49.1067472, lng: 19.4470331 },
    { lat: 49.1067482, lng: 19.4470301 },
    { lat: 49.106754, lng: 19.4469863 },
    { lat: 49.106807, lng: 19.4469885 },
    { lat: 49.1073801, lng: 19.4471997 },
    { lat: 49.1084592, lng: 19.4470202 },
    { lat: 49.1087909, lng: 19.4467835 },
    { lat: 49.1088877, lng: 19.4467073 },
    { lat: 49.1088838, lng: 19.4466477 },
    { lat: 49.1088726, lng: 19.4461615 },
    { lat: 49.1093561, lng: 19.4460658 },
    { lat: 49.1093506, lng: 19.4459948 },
    { lat: 49.1104888, lng: 19.4455618 },
    { lat: 49.1106395, lng: 19.4455514 },
    { lat: 49.1109706, lng: 19.4454763 },
    { lat: 49.1114413, lng: 19.4457128 },
    { lat: 49.1117308, lng: 19.4457332 },
    { lat: 49.1118991, lng: 19.4458147 },
    { lat: 49.1120804, lng: 19.4459363 },
    { lat: 49.112244, lng: 19.4460104 },
    { lat: 49.1122407, lng: 19.4461583 },
    { lat: 49.1123308, lng: 19.4459885 },
    { lat: 49.112317, lng: 19.4461551 },
    { lat: 49.1125931, lng: 19.4461302 },
    { lat: 49.1127465, lng: 19.4461601 },
    { lat: 49.1127616, lng: 19.4461894 },
    { lat: 49.1129697, lng: 19.4461351 },
    { lat: 49.1130843, lng: 19.4461785 },
    { lat: 49.1132329, lng: 19.4461734 },
    { lat: 49.1136128, lng: 19.446393 },
    { lat: 49.1136171, lng: 19.446272 },
    { lat: 49.1136842, lng: 19.4462821 },
    { lat: 49.1136791, lng: 19.4463913 },
    { lat: 49.1136806, lng: 19.4463987 },
    { lat: 49.1136818, lng: 19.4464252 },
    { lat: 49.1138286, lng: 19.4464799 },
    { lat: 49.1140585, lng: 19.4466052 },
    { lat: 49.1141738, lng: 19.4466583 },
    { lat: 49.1141757, lng: 19.4466265 },
    { lat: 49.1144539, lng: 19.4467193 },
    { lat: 49.1149686, lng: 19.4467395 },
    { lat: 49.1151238, lng: 19.4467351 },
    { lat: 49.1152812, lng: 19.4467474 },
    { lat: 49.1153496, lng: 19.4467915 },
    { lat: 49.1155307, lng: 19.4469299 },
    { lat: 49.115654, lng: 19.4470635 },
    { lat: 49.1159423, lng: 19.4473548 },
    { lat: 49.1160905, lng: 19.4476799 },
    { lat: 49.1163088, lng: 19.448013 },
    { lat: 49.1167683, lng: 19.4481824 },
    { lat: 49.1171503, lng: 19.4484253 },
    { lat: 49.1172287, lng: 19.4481956 },
    { lat: 49.1172585, lng: 19.4481571 },
    { lat: 49.1179314, lng: 19.4485526 },
    { lat: 49.1181842, lng: 19.448801 },
    { lat: 49.1188166, lng: 19.4492713 },
    { lat: 49.1194673, lng: 19.449694 },
    { lat: 49.1200505, lng: 19.4506589 },
    { lat: 49.1202219, lng: 19.4512262 },
    { lat: 49.1208387, lng: 19.4519878 },
    { lat: 49.1211323, lng: 19.4525157 },
    { lat: 49.1213941, lng: 19.4526434 },
    { lat: 49.1218188, lng: 19.4530335 },
    { lat: 49.1217349, lng: 19.4530677 },
    { lat: 49.1216122, lng: 19.4536831 },
    { lat: 49.1219209, lng: 19.4537763 },
    { lat: 49.1223915, lng: 19.4536751 },
    { lat: 49.122419, lng: 19.4537078 },
    { lat: 49.1233747, lng: 19.4533919 },
    { lat: 49.1236884, lng: 19.4533281 },
    { lat: 49.1242117, lng: 19.4537292 },
    { lat: 49.1242578, lng: 19.453725 },
    { lat: 49.1245622, lng: 19.453755 },
    { lat: 49.1246191, lng: 19.4536952 },
    { lat: 49.1248755, lng: 19.4539228 },
    { lat: 49.125623, lng: 19.4546436 },
    { lat: 49.1255826, lng: 19.4567951 },
    { lat: 49.123243, lng: 19.4565184 },
    { lat: 49.1228707, lng: 19.4561669 },
    { lat: 49.1225395, lng: 19.4562654 },
    { lat: 49.122278, lng: 19.4566067 },
    { lat: 49.122104, lng: 19.4569143 },
    { lat: 49.1219722, lng: 19.4574763 },
    { lat: 49.1218735, lng: 19.4576081 },
    { lat: 49.121879, lng: 19.457877 },
    { lat: 49.1225, lng: 19.45807 },
    { lat: 49.123027, lng: 19.458407 },
    { lat: 49.123344, lng: 19.458409 },
    { lat: 49.123863, lng: 19.458879 },
    { lat: 49.124135, lng: 19.459381 },
    { lat: 49.124887, lng: 19.460013 },
    { lat: 49.125335, lng: 19.460195 },
    { lat: 49.125668, lng: 19.460397 },
    { lat: 49.126487, lng: 19.460523 },
    { lat: 49.127734, lng: 19.460678 },
    { lat: 49.128499, lng: 19.46065 },
    { lat: 49.129307, lng: 19.46061 },
    { lat: 49.130257, lng: 19.460001 },
    { lat: 49.130828, lng: 19.459104 },
    { lat: 49.13175, lng: 19.457359 },
    { lat: 49.132216, lng: 19.456096 },
    { lat: 49.132743, lng: 19.454646 },
    { lat: 49.133311, lng: 19.453271 },
    { lat: 49.132452, lng: 19.45151 },
    { lat: 49.131855, lng: 19.44892 },
    { lat: 49.131692, lng: 19.447655 },
    { lat: 49.1298245, lng: 19.4475608 },
    { lat: 49.1289953, lng: 19.4475117 },
    { lat: 49.1268384, lng: 19.445243 },
    { lat: 49.126284, lng: 19.4446611 },
    { lat: 49.1253448, lng: 19.4437715 },
    { lat: 49.124907, lng: 19.4446395 },
    { lat: 49.1244133, lng: 19.4453128 },
    { lat: 49.1234584, lng: 19.4437716 },
    { lat: 49.1226782, lng: 19.4431298 },
    { lat: 49.1216727, lng: 19.442342 },
    { lat: 49.1213358, lng: 19.4418335 },
    { lat: 49.1209601, lng: 19.4416505 },
    { lat: 49.1209306, lng: 19.4415018 },
    { lat: 49.1203753, lng: 19.4412377 },
    { lat: 49.1201264, lng: 19.4404626 },
    { lat: 49.1189779, lng: 19.4404839 },
    { lat: 49.1186593, lng: 19.4382507 },
    { lat: 49.1185844, lng: 19.4378709 },
    { lat: 49.1185875, lng: 19.4378918 },
    { lat: 49.1180561, lng: 19.4374676 },
    { lat: 49.1175865, lng: 19.4364748 },
    { lat: 49.1173513, lng: 19.4352425 },
    { lat: 49.1170597, lng: 19.4346155 },
    { lat: 49.1166086, lng: 19.4341709 },
    { lat: 49.1159076, lng: 19.4328322 },
    { lat: 49.1158412, lng: 19.4323472 },
    { lat: 49.1158263, lng: 19.4322664 },
    { lat: 49.1157056, lng: 19.4312938 },
    { lat: 49.1154577, lng: 19.4312438 },
    { lat: 49.1132978, lng: 19.4316403 },
    { lat: 49.1119695, lng: 19.4308898 },
    { lat: 49.1109822, lng: 19.4304522 },
    { lat: 49.1088245, lng: 19.4304352 },
    { lat: 49.1069935, lng: 19.429635 },
    { lat: 49.1059533, lng: 19.4294603 },
    { lat: 49.1059102, lng: 19.429871 },
    { lat: 49.1051606, lng: 19.4297705 },
    { lat: 49.1050628, lng: 19.4297716 },
    { lat: 49.1046803, lng: 19.429761 },
    { lat: 49.1046852, lng: 19.4295058 },
    { lat: 49.10155, lng: 19.4291586 },
    { lat: 49.1012826, lng: 19.4290543 },
    { lat: 49.100485, lng: 19.4290571 },
    { lat: 49.100341, lng: 19.4290964 },
    { lat: 49.1000665, lng: 19.4291712 },
    { lat: 49.098499, lng: 19.4288556 },
    { lat: 49.098038, lng: 19.4288286 },
    { lat: 49.0980076, lng: 19.4288046 },
    { lat: 49.0979083, lng: 19.4287761 },
    { lat: 49.0978506, lng: 19.4279469 },
    { lat: 49.0977707, lng: 19.4271 },
    { lat: 49.0977398, lng: 19.4265575 },
    { lat: 49.09776, lng: 19.4254873 },
    { lat: 49.0978152, lng: 19.4248556 },
    { lat: 49.0979175, lng: 19.4239521 },
    { lat: 49.0980455, lng: 19.4233636 },
    { lat: 49.0985178, lng: 19.4215197 },
    { lat: 49.0970638, lng: 19.422122 },
    { lat: 49.0962628, lng: 19.423117 },
    { lat: 49.0961032, lng: 19.4223302 },
    { lat: 49.0957743, lng: 19.4231333 },
    { lat: 49.0955375, lng: 19.4241715 },
    { lat: 49.0954704, lng: 19.4254401 },
    { lat: 49.0960354, lng: 19.428001 },
    { lat: 49.0960632, lng: 19.4280868 },
    { lat: 49.0976414, lng: 19.4315226 },
    { lat: 49.0978302, lng: 19.4321935 },
    { lat: 49.0981034, lng: 19.4336377 },
    { lat: 49.0986471, lng: 19.4348623 },
    { lat: 49.0986583, lng: 19.4350071 },
    { lat: 49.098613, lng: 19.4353709 },
    { lat: 49.0986807, lng: 19.4364183 },
    { lat: 49.0986739, lng: 19.4365866 },
    { lat: 49.0987012, lng: 19.4381433 },
    { lat: 49.0987691, lng: 19.439318 },
    { lat: 49.0987614, lng: 19.4394691 },
    { lat: 49.0987981, lng: 19.440042 },
    { lat: 49.0988285, lng: 19.4413089 },
    { lat: 49.098772, lng: 19.4421089 },
    { lat: 49.098632, lng: 19.4428331 },
    { lat: 49.0983913, lng: 19.4434731 },
    { lat: 49.0981279, lng: 19.4440181 },
    { lat: 49.0969521, lng: 19.4463677 },
    { lat: 49.0966519, lng: 19.4469881 },
    { lat: 49.0965714, lng: 19.4473467 },
    { lat: 49.096557, lng: 19.447444 },
    { lat: 49.096849, lng: 19.447771 },
    { lat: 49.097149, lng: 19.448075 },
    { lat: 49.097472, lng: 19.44834 },
    { lat: 49.097705, lng: 19.448528 },
    { lat: 49.098128, lng: 19.448759 },
    { lat: 49.098644, lng: 19.448943 },
    { lat: 49.098824, lng: 19.449037 },
    { lat: 49.099178, lng: 19.449118 },
    { lat: 49.099535, lng: 19.449155 },
    { lat: 49.099899, lng: 19.449156 },
    { lat: 49.100248, lng: 19.449116 },
    { lat: 49.100607, lng: 19.449035 },
    { lat: 49.100953, lng: 19.448918 },
    { lat: 49.101309, lng: 19.448803 },
    { lat: 49.101437, lng: 19.448794 },
    { lat: 49.103212, lng: 19.449202 },
    { lat: 49.103032, lng: 19.450204 },
    { lat: 49.104043, lng: 19.450793 },
    { lat: 49.104571, lng: 19.450978 },
  ],
  Hubová: [
    { lat: 49.1193482, lng: 19.1841807 },
    { lat: 49.1193031, lng: 19.1840653 },
    { lat: 49.1191928, lng: 19.1837445 },
    { lat: 49.1190286, lng: 19.1832412 },
    { lat: 49.1187048, lng: 19.1822583 },
    { lat: 49.118542, lng: 19.1817376 },
    { lat: 49.1183977, lng: 19.1813436 },
    { lat: 49.1182661, lng: 19.1810022 },
    { lat: 49.1181514, lng: 19.1806812 },
    { lat: 49.1180736, lng: 19.180446 },
    { lat: 49.1180122, lng: 19.1802287 },
    { lat: 49.11797, lng: 19.1800295 },
    { lat: 49.1179428, lng: 19.1798631 },
    { lat: 49.1179258, lng: 19.1797041 },
    { lat: 49.117919, lng: 19.1795631 },
    { lat: 49.1179217, lng: 19.1794297 },
    { lat: 49.1179388, lng: 19.1793191 },
    { lat: 49.1179697, lng: 19.1792112 },
    { lat: 49.1180143, lng: 19.1791083 },
    { lat: 49.1181485, lng: 19.1789237 },
    { lat: 49.1185871, lng: 19.178463 },
    { lat: 49.1188544, lng: 19.1781637 },
    { lat: 49.1191862, lng: 19.1777348 },
    { lat: 49.1192937, lng: 19.1775798 },
    { lat: 49.1194024, lng: 19.1774074 },
    { lat: 49.1197181, lng: 19.1766849 },
    { lat: 49.1198128, lng: 19.1764813 },
    { lat: 49.1201593, lng: 19.1757906 },
    { lat: 49.1205701, lng: 19.1749464 },
    { lat: 49.1203236, lng: 19.174695 },
    { lat: 49.119833, lng: 19.1745287 },
    { lat: 49.1196875, lng: 19.1745483 },
    { lat: 49.1197198, lng: 19.1740765 },
    { lat: 49.1197198, lng: 19.1738477 },
    { lat: 49.1196799, lng: 19.1735801 },
    { lat: 49.1196715, lng: 19.1735728 },
    { lat: 49.1195921, lng: 19.1734223 },
    { lat: 49.1195008, lng: 19.1733319 },
    { lat: 49.1194037, lng: 19.1732572 },
    { lat: 49.1192159, lng: 19.1731395 },
    { lat: 49.1192164, lng: 19.1731358 },
    { lat: 49.1189213, lng: 19.1731882 },
    { lat: 49.1187223, lng: 19.1732186 },
    { lat: 49.1186569, lng: 19.1730104 },
    { lat: 49.1185764, lng: 19.1727017 },
    { lat: 49.1185424, lng: 19.1723468 },
    { lat: 49.1185845, lng: 19.1721729 },
    { lat: 49.1185077, lng: 19.1717991 },
    { lat: 49.1186123, lng: 19.1717686 },
    { lat: 49.1185851, lng: 19.1716263 },
    { lat: 49.1185241, lng: 19.1714169 },
    { lat: 49.118498, lng: 19.1711136 },
    { lat: 49.1185595, lng: 19.1710081 },
    { lat: 49.1185925, lng: 19.1708759 },
    { lat: 49.118677, lng: 19.1705976 },
    { lat: 49.1187154, lng: 19.1705767 },
    { lat: 49.1186592, lng: 19.1703477 },
    { lat: 49.1184854, lng: 19.1698347 },
    { lat: 49.1184411, lng: 19.1697343 },
    { lat: 49.1184263, lng: 19.169659 },
    { lat: 49.1183484, lng: 19.1693774 },
    { lat: 49.1184219, lng: 19.1693838 },
    { lat: 49.1182615, lng: 19.1689608 },
    { lat: 49.1177909, lng: 19.1685065 },
    { lat: 49.1176427, lng: 19.1683106 },
    { lat: 49.1174712, lng: 19.1681695 },
    { lat: 49.1176192, lng: 19.1680128 },
    { lat: 49.11766, lng: 19.1678805 },
    { lat: 49.1176285, lng: 19.1678585 },
    { lat: 49.1175017, lng: 19.1679368 },
    { lat: 49.117418, lng: 19.1679553 },
    { lat: 49.1173655, lng: 19.1679429 },
    { lat: 49.1173168, lng: 19.1679145 },
    { lat: 49.1172212, lng: 19.1678037 },
    { lat: 49.1170832, lng: 19.1676084 },
    { lat: 49.1169816, lng: 19.167535 },
    { lat: 49.116625, lng: 19.1676415 },
    { lat: 49.1160446, lng: 19.1677383 },
    { lat: 49.1160017, lng: 19.1676417 },
    { lat: 49.1159271, lng: 19.1676868 },
    { lat: 49.115873, lng: 19.167662 },
    { lat: 49.1158075, lng: 19.1677291 },
    { lat: 49.1157437, lng: 19.1677144 },
    { lat: 49.1156704, lng: 19.1677612 },
    { lat: 49.1156015, lng: 19.1677092 },
    { lat: 49.1155186, lng: 19.1677679 },
    { lat: 49.1152691, lng: 19.1677849 },
    { lat: 49.1151694, lng: 19.1678667 },
    { lat: 49.1149418, lng: 19.1679366 },
    { lat: 49.1148074, lng: 19.1679076 },
    { lat: 49.114625, lng: 19.1680285 },
    { lat: 49.1144629, lng: 19.1680789 },
    { lat: 49.1143699, lng: 19.1680171 },
    { lat: 49.1142933, lng: 19.1681142 },
    { lat: 49.114122, lng: 19.1682088 },
    { lat: 49.113956, lng: 19.1681764 },
    { lat: 49.1138672, lng: 19.1682732 },
    { lat: 49.1136276, lng: 19.1682624 },
    { lat: 49.1134265, lng: 19.1682995 },
    { lat: 49.1132615, lng: 19.1683585 },
    { lat: 49.1131823, lng: 19.1683088 },
    { lat: 49.1130892, lng: 19.1683858 },
    { lat: 49.1129477, lng: 19.168327 },
    { lat: 49.1126301, lng: 19.1684251 },
    { lat: 49.1125272, lng: 19.1683496 },
    { lat: 49.1124564, lng: 19.1684681 },
    { lat: 49.1121381, lng: 19.1686012 },
    { lat: 49.1120235, lng: 19.1685123 },
    { lat: 49.1119411, lng: 19.1686051 },
    { lat: 49.1117802, lng: 19.1686581 },
    { lat: 49.1116565, lng: 19.168571 },
    { lat: 49.1115517, lng: 19.1686605 },
    { lat: 49.1113653, lng: 19.1687222 },
    { lat: 49.111216, lng: 19.1688042 },
    { lat: 49.1110839, lng: 19.1687551 },
    { lat: 49.1109506, lng: 19.1688392 },
    { lat: 49.1108373, lng: 19.168992 },
    { lat: 49.1106865, lng: 19.168995 },
    { lat: 49.1106094, lng: 19.1690799 },
    { lat: 49.1105261, lng: 19.1691296 },
    { lat: 49.1103664, lng: 19.1692743 },
    { lat: 49.110179, lng: 19.1693602 },
    { lat: 49.1100082, lng: 19.1695108 },
    { lat: 49.1098482, lng: 19.1696062 },
    { lat: 49.1096828, lng: 19.1697749 },
    { lat: 49.1095122, lng: 19.1699712 },
    { lat: 49.1093957, lng: 19.1700578 },
    { lat: 49.109238, lng: 19.1700108 },
    { lat: 49.1091291, lng: 19.1700457 },
    { lat: 49.108973, lng: 19.170054 },
    { lat: 49.1088275, lng: 19.1699863 },
    { lat: 49.1087587, lng: 19.1700289 },
    { lat: 49.1086572, lng: 19.1700069 },
    { lat: 49.1085223, lng: 19.1700401 },
    { lat: 49.1083886, lng: 19.1699516 },
    { lat: 49.1082603, lng: 19.170028 },
    { lat: 49.1081676, lng: 19.1700215 },
    { lat: 49.108048, lng: 19.1700627 },
    { lat: 49.1079128, lng: 19.1699992 },
    { lat: 49.1076461, lng: 19.1700998 },
    { lat: 49.1074957, lng: 19.1699945 },
    { lat: 49.107397, lng: 19.1700956 },
    { lat: 49.1073255, lng: 19.1700644 },
    { lat: 49.1071815, lng: 19.1701199 },
    { lat: 49.1070824, lng: 19.1700958 },
    { lat: 49.1069721, lng: 19.1701464 },
    { lat: 49.1068365, lng: 19.1700235 },
    { lat: 49.1067253, lng: 19.1701026 },
    { lat: 49.1065305, lng: 19.1701523 },
    { lat: 49.1064206, lng: 19.170069 },
    { lat: 49.1063315, lng: 19.1701399 },
    { lat: 49.1061393, lng: 19.1701756 },
    { lat: 49.1060202, lng: 19.1701099 },
    { lat: 49.1059527, lng: 19.1701607 },
    { lat: 49.1057993, lng: 19.1701093 },
    { lat: 49.1056594, lng: 19.1701568 },
    { lat: 49.1054505, lng: 19.170126 },
    { lat: 49.1052012, lng: 19.1700257 },
    { lat: 49.1050861, lng: 19.1700477 },
    { lat: 49.1049262, lng: 19.1699805 },
    { lat: 49.1049118, lng: 19.1698501 },
    { lat: 49.1048484, lng: 19.1696349 },
    { lat: 49.104581, lng: 19.1693953 },
    { lat: 49.1042439, lng: 19.1691427 },
    { lat: 49.1042482, lng: 19.1687213 },
    { lat: 49.1043074, lng: 19.168593 },
    { lat: 49.1041653, lng: 19.1685224 },
    { lat: 49.1039696, lng: 19.1684139 },
    { lat: 49.1035332, lng: 19.168016 },
    { lat: 49.1034746, lng: 19.1672988 },
    { lat: 49.1034922, lng: 19.1670573 },
    { lat: 49.1034768, lng: 19.1666011 },
    { lat: 49.1034847, lng: 19.1659768 },
    { lat: 49.1035529, lng: 19.1658493 },
    { lat: 49.1035327, lng: 19.1657364 },
    { lat: 49.1034423, lng: 19.1657315 },
    { lat: 49.1034197, lng: 19.1656175 },
    { lat: 49.0999055, lng: 19.1670572 },
    { lat: 49.0996591, lng: 19.1669059 },
    { lat: 49.09911, lng: 19.1670183 },
    { lat: 49.0988786, lng: 19.1667343 },
    { lat: 49.0986596, lng: 19.1668641 },
    { lat: 49.0985775, lng: 19.166614 },
    { lat: 49.0986389, lng: 19.1663895 },
    { lat: 49.0986273, lng: 19.1658991 },
    { lat: 49.0985071, lng: 19.1659777 },
    { lat: 49.0982336, lng: 19.1659385 },
    { lat: 49.0980547, lng: 19.1654141 },
    { lat: 49.0978374, lng: 19.1647243 },
    { lat: 49.0978136, lng: 19.1643964 },
    { lat: 49.0977697, lng: 19.1640759 },
    { lat: 49.0976704, lng: 19.1637256 },
    { lat: 49.0976499, lng: 19.1631636 },
    { lat: 49.0976835, lng: 19.1627432 },
    { lat: 49.0975073, lng: 19.1626232 },
    { lat: 49.0965053, lng: 19.1622282 },
    { lat: 49.0960888, lng: 19.1621933 },
    { lat: 49.095921, lng: 19.1621953 },
    { lat: 49.0958066, lng: 19.1612831 },
    { lat: 49.0959063, lng: 19.1611338 },
    { lat: 49.0958569, lng: 19.160861 },
    { lat: 49.095766, lng: 19.1604741 },
    { lat: 49.0955879, lng: 19.1601058 },
    { lat: 49.09543, lng: 19.159987 },
    { lat: 49.0954135, lng: 19.1602507 },
    { lat: 49.095148, lng: 19.1605843 },
    { lat: 49.0950359, lng: 19.1606437 },
    { lat: 49.0950017, lng: 19.1605666 },
    { lat: 49.0945684, lng: 19.1608807 },
    { lat: 49.0945673, lng: 19.1609991 },
    { lat: 49.0945207, lng: 19.1610789 },
    { lat: 49.094443, lng: 19.1614042 },
    { lat: 49.0946991, lng: 19.1620345 },
    { lat: 49.0947687, lng: 19.1620284 },
    { lat: 49.0948601, lng: 19.1622605 },
    { lat: 49.0949529, lng: 19.1622026 },
    { lat: 49.0951115, lng: 19.162331 },
    { lat: 49.095075, lng: 19.1629243 },
    { lat: 49.0949126, lng: 19.1630869 },
    { lat: 49.0946458, lng: 19.1632774 },
    { lat: 49.0945022, lng: 19.163526 },
    { lat: 49.0946264, lng: 19.163849 },
    { lat: 49.0945215, lng: 19.1642417 },
    { lat: 49.0943174, lng: 19.1643569 },
    { lat: 49.0941313, lng: 19.1646092 },
    { lat: 49.09389, lng: 19.16484 },
    { lat: 49.093835, lng: 19.1650735 },
    { lat: 49.0935812, lng: 19.1658553 },
    { lat: 49.0935859, lng: 19.1659087 },
    { lat: 49.0935652, lng: 19.1659682 },
    { lat: 49.0935025, lng: 19.1660692 },
    { lat: 49.0934815, lng: 19.1661467 },
    { lat: 49.0934765, lng: 19.1662322 },
    { lat: 49.093431, lng: 19.1662934 },
    { lat: 49.0933842, lng: 19.1663944 },
    { lat: 49.0932999, lng: 19.1664683 },
    { lat: 49.0932119, lng: 19.1666055 },
    { lat: 49.0930491, lng: 19.166784 },
    { lat: 49.0929802, lng: 19.1668478 },
    { lat: 49.0929458, lng: 19.1669269 },
    { lat: 49.0929362, lng: 19.1670118 },
    { lat: 49.0929142, lng: 19.1670894 },
    { lat: 49.0927613, lng: 19.1672621 },
    { lat: 49.0926847, lng: 19.1673336 },
    { lat: 49.0926341, lng: 19.1674052 },
    { lat: 49.0925704, lng: 19.1674588 },
    { lat: 49.0925413, lng: 19.1675358 },
    { lat: 49.092534, lng: 19.1676391 },
    { lat: 49.0924993, lng: 19.1677124 },
    { lat: 49.0924407, lng: 19.1677842 },
    { lat: 49.0923996, lng: 19.1678672 },
    { lat: 49.0923132, lng: 19.1679884 },
    { lat: 49.0922434, lng: 19.1680578 },
    { lat: 49.092203, lng: 19.1681807 },
    { lat: 49.0921339, lng: 19.1682403 },
    { lat: 49.0920871, lng: 19.1683218 },
    { lat: 49.0919817, lng: 19.1684528 },
    { lat: 49.0918638, lng: 19.1685049 },
    { lat: 49.0918121, lng: 19.1685093 },
    { lat: 49.0905248, lng: 19.1722614 },
    { lat: 49.0890132, lng: 19.1770999 },
    { lat: 49.0886568, lng: 19.1777953 },
    { lat: 49.0886712, lng: 19.1781105 },
    { lat: 49.0886442, lng: 19.1782063 },
    { lat: 49.0886652, lng: 19.1783366 },
    { lat: 49.0886631, lng: 19.17843 },
    { lat: 49.0886248, lng: 19.1785944 },
    { lat: 49.0886471, lng: 19.1787274 },
    { lat: 49.0886395, lng: 19.1789649 },
    { lat: 49.0886591, lng: 19.1790915 },
    { lat: 49.0886275, lng: 19.1791612 },
    { lat: 49.0886237, lng: 19.1792918 },
    { lat: 49.0885952, lng: 19.1794489 },
    { lat: 49.0886187, lng: 19.1797469 },
    { lat: 49.0886158, lng: 19.179848 },
    { lat: 49.0885895, lng: 19.1799574 },
    { lat: 49.0886031, lng: 19.180145 },
    { lat: 49.0885908, lng: 19.1802856 },
    { lat: 49.088603, lng: 19.1803957 },
    { lat: 49.0885628, lng: 19.1806138 },
    { lat: 49.0885473, lng: 19.1808058 },
    { lat: 49.0885701, lng: 19.1809241 },
    { lat: 49.0885447, lng: 19.181006 },
    { lat: 49.0885022, lng: 19.1812679 },
    { lat: 49.0884629, lng: 19.1813389 },
    { lat: 49.0884711, lng: 19.1814646 },
    { lat: 49.0884253, lng: 19.1816107 },
    { lat: 49.0883852, lng: 19.1818051 },
    { lat: 49.0883469, lng: 19.1818781 },
    { lat: 49.0883428, lng: 19.1820033 },
    { lat: 49.0883, lng: 19.1821437 },
    { lat: 49.0882804, lng: 19.1822937 },
    { lat: 49.088255, lng: 19.1823534 },
    { lat: 49.0882424, lng: 19.1824404 },
    { lat: 49.0881706, lng: 19.1827715 },
    { lat: 49.088127, lng: 19.1828742 },
    { lat: 49.0881124, lng: 19.1830603 },
    { lat: 49.0881208, lng: 19.1831859 },
    { lat: 49.0881468, lng: 19.1833028 },
    { lat: 49.0881443, lng: 19.1833919 },
    { lat: 49.0881759, lng: 19.1836005 },
    { lat: 49.0881733, lng: 19.1837317 },
    { lat: 49.0881919, lng: 19.1838619 },
    { lat: 49.0881969, lng: 19.1839639 },
    { lat: 49.088173, lng: 19.184123 },
    { lat: 49.0882622, lng: 19.1843812 },
    { lat: 49.0883258, lng: 19.1846693 },
    { lat: 49.0883609, lng: 19.1847391 },
    { lat: 49.0883711, lng: 19.1848566 },
    { lat: 49.088469, lng: 19.1851135 },
    { lat: 49.0884909, lng: 19.1853987 },
    { lat: 49.0884816, lng: 19.1856249 },
    { lat: 49.0885208, lng: 19.1857608 },
    { lat: 49.088528, lng: 19.1858858 },
    { lat: 49.0885538, lng: 19.1859991 },
    { lat: 49.0885443, lng: 19.1861278 },
    { lat: 49.0885563, lng: 19.1863271 },
    { lat: 49.088583, lng: 19.1864597 },
    { lat: 49.0885827, lng: 19.1868517 },
    { lat: 49.0877639, lng: 19.1928343 },
    { lat: 49.0859399, lng: 19.1928066 },
    { lat: 49.0834301, lng: 19.1921875 },
    { lat: 49.0832073, lng: 19.1922643 },
    { lat: 49.082012, lng: 19.1919491 },
    { lat: 49.0812551, lng: 19.1920087 },
    { lat: 49.0798726, lng: 19.1919494 },
    { lat: 49.0781804, lng: 19.1919853 },
    { lat: 49.0778391, lng: 19.1917137 },
    { lat: 49.0774847, lng: 19.1907946 },
    { lat: 49.0773165, lng: 19.1899327 },
    { lat: 49.0764008, lng: 19.1904343 },
    { lat: 49.0752099, lng: 19.1920264 },
    { lat: 49.0746671, lng: 19.1918947 },
    { lat: 49.0736868, lng: 19.1921803 },
    { lat: 49.0724403, lng: 19.1925321 },
    { lat: 49.0718605, lng: 19.1924074 },
    { lat: 49.0712535, lng: 19.1932068 },
    { lat: 49.0707934, lng: 19.1940155 },
    { lat: 49.0705034, lng: 19.1947994 },
    { lat: 49.0697548, lng: 19.1954946 },
    { lat: 49.0696806, lng: 19.1983357 },
    { lat: 49.0696317, lng: 19.2011192 },
    { lat: 49.0696194, lng: 19.2022619 },
    { lat: 49.0695716, lng: 19.2022065 },
    { lat: 49.0694397, lng: 19.2022278 },
    { lat: 49.0692986, lng: 19.2025733 },
    { lat: 49.0691345, lng: 19.2030281 },
    { lat: 49.0689595, lng: 19.2030416 },
    { lat: 49.0687052, lng: 19.2037952 },
    { lat: 49.068375, lng: 19.2041701 },
    { lat: 49.0681202, lng: 19.2126986 },
    { lat: 49.0681229, lng: 19.2127299 },
    { lat: 49.0682985, lng: 19.2127534 },
    { lat: 49.0685774, lng: 19.2125759 },
    { lat: 49.0689675, lng: 19.2120613 },
    { lat: 49.0691946, lng: 19.211936 },
    { lat: 49.0694443, lng: 19.2118986 },
    { lat: 49.069865, lng: 19.212188 },
    { lat: 49.0700283, lng: 19.2121122 },
    { lat: 49.070069, lng: 19.2121185 },
    { lat: 49.0703401, lng: 19.212219 },
    { lat: 49.0705038, lng: 19.2122049 },
    { lat: 49.0707465, lng: 19.2122321 },
    { lat: 49.0709792, lng: 19.212151 },
    { lat: 49.0711821, lng: 19.2124094 },
    { lat: 49.071434, lng: 19.2125832 },
    { lat: 49.0717893, lng: 19.2130299 },
    { lat: 49.0725888, lng: 19.2141842 },
    { lat: 49.0728106, lng: 19.2145067 },
    { lat: 49.0730271, lng: 19.2147158 },
    { lat: 49.0733214, lng: 19.2147644 },
    { lat: 49.0735487, lng: 19.2147131 },
    { lat: 49.0737807, lng: 19.2146354 },
    { lat: 49.0741266, lng: 19.2144471 },
    { lat: 49.0742762, lng: 19.214489 },
    { lat: 49.0746064, lng: 19.2145326 },
    { lat: 49.0750854, lng: 19.2147001 },
    { lat: 49.0754163, lng: 19.2148755 },
    { lat: 49.0756582, lng: 19.2149117 },
    { lat: 49.0761088, lng: 19.2149059 },
    { lat: 49.0768306, lng: 19.2146371 },
    { lat: 49.0770605, lng: 19.2143787 },
    { lat: 49.077276, lng: 19.2141187 },
    { lat: 49.0777054, lng: 19.2137065 },
    { lat: 49.078366, lng: 19.2130073 },
    { lat: 49.0786914, lng: 19.2123952 },
    { lat: 49.0790017, lng: 19.211773 },
    { lat: 49.0792465, lng: 19.2116144 },
    { lat: 49.0797977, lng: 19.2115444 },
    { lat: 49.0801195, lng: 19.2114134 },
    { lat: 49.0807272, lng: 19.2112778 },
    { lat: 49.0811665, lng: 19.2107385 },
    { lat: 49.0813167, lng: 19.2103675 },
    { lat: 49.0815949, lng: 19.2100842 },
    { lat: 49.0817717, lng: 19.2098527 },
    { lat: 49.082093, lng: 19.2096635 },
    { lat: 49.0823794, lng: 19.2093877 },
    { lat: 49.0826717, lng: 19.2089759 },
    { lat: 49.0828707, lng: 19.2086164 },
    { lat: 49.0829482, lng: 19.2085385 },
    { lat: 49.0830713, lng: 19.208545 },
    { lat: 49.0833857, lng: 19.2086827 },
    { lat: 49.0839366, lng: 19.2089105 },
    { lat: 49.0843634, lng: 19.2088112 },
    { lat: 49.0848129, lng: 19.2089004 },
    { lat: 49.0849746, lng: 19.2090736 },
    { lat: 49.0851747, lng: 19.2091529 },
    { lat: 49.0853619, lng: 19.2092743 },
    { lat: 49.0855372, lng: 19.2092169 },
    { lat: 49.0857053, lng: 19.2094056 },
    { lat: 49.0861495, lng: 19.2096625 },
    { lat: 49.086427, lng: 19.2098332 },
    { lat: 49.0864941, lng: 19.2097757 },
    { lat: 49.086648, lng: 19.2098131 },
    { lat: 49.086815, lng: 19.2099541 },
    { lat: 49.0868892, lng: 19.2099073 },
    { lat: 49.0872913, lng: 19.2098993 },
    { lat: 49.0876523, lng: 19.2099293 },
    { lat: 49.0878562, lng: 19.2100633 },
    { lat: 49.0881803, lng: 19.2101963 },
    { lat: 49.088381, lng: 19.2103626 },
    { lat: 49.0884976, lng: 19.2105351 },
    { lat: 49.0885827, lng: 19.210709 },
    { lat: 49.088875, lng: 19.2113476 },
    { lat: 49.0889631, lng: 19.2115622 },
    { lat: 49.0890075, lng: 19.2117323 },
    { lat: 49.0891948, lng: 19.2118743 },
    { lat: 49.0894932, lng: 19.2122447 },
    { lat: 49.0897094, lng: 19.2123086 },
    { lat: 49.0898392, lng: 19.2122426 },
    { lat: 49.0898761, lng: 19.2123759 },
    { lat: 49.0898759, lng: 19.2124205 },
    { lat: 49.0898981, lng: 19.2125539 },
    { lat: 49.0899798, lng: 19.2124509 },
    { lat: 49.0900244, lng: 19.2124157 },
    { lat: 49.0900862, lng: 19.2123408 },
    { lat: 49.0902123, lng: 19.2122419 },
    { lat: 49.0903808, lng: 19.2122773 },
    { lat: 49.0904725, lng: 19.2124069 },
    { lat: 49.0908283, lng: 19.2123264 },
    { lat: 49.091336, lng: 19.2124997 },
    { lat: 49.091823, lng: 19.2123384 },
    { lat: 49.0920919, lng: 19.2123744 },
    { lat: 49.0922688, lng: 19.2125593 },
    { lat: 49.0925995, lng: 19.2127132 },
    { lat: 49.0929284, lng: 19.212612 },
    { lat: 49.0930627, lng: 19.2127077 },
    { lat: 49.0935, lng: 19.2127548 },
    { lat: 49.0936937, lng: 19.2128931 },
    { lat: 49.0941561, lng: 19.2130392 },
    { lat: 49.0943617, lng: 19.2131427 },
    { lat: 49.0946199, lng: 19.2131437 },
    { lat: 49.0950403, lng: 19.2130959 },
    { lat: 49.0956863, lng: 19.2134041 },
    { lat: 49.0960693, lng: 19.2135626 },
    { lat: 49.0963193, lng: 19.2135795 },
    { lat: 49.0963399, lng: 19.213611 },
    { lat: 49.096345, lng: 19.2137856 },
    { lat: 49.0965589, lng: 19.2138768 },
    { lat: 49.0968008, lng: 19.213658 },
    { lat: 49.0971135, lng: 19.2136902 },
    { lat: 49.0972876, lng: 19.2135592 },
    { lat: 49.097473, lng: 19.2129188 },
    { lat: 49.0976248, lng: 19.2127705 },
    { lat: 49.0979873, lng: 19.2127853 },
    { lat: 49.0984917, lng: 19.2129586 },
    { lat: 49.0988329, lng: 19.2129071 },
    { lat: 49.0993125, lng: 19.2128225 },
    { lat: 49.0997622, lng: 19.2128023 },
    { lat: 49.1004015, lng: 19.2129001 },
    { lat: 49.1008591, lng: 19.2133906 },
    { lat: 49.1009588, lng: 19.2134208 },
    { lat: 49.1012784, lng: 19.2132515 },
    { lat: 49.1021936, lng: 19.2138077 },
    { lat: 49.1024264, lng: 19.2140564 },
    { lat: 49.1028198, lng: 19.2142883 },
    { lat: 49.1029881, lng: 19.2145242 },
    { lat: 49.1031152, lng: 19.2146897 },
    { lat: 49.1032651, lng: 19.2151319 },
    { lat: 49.1035478, lng: 19.2153622 },
    { lat: 49.1039464, lng: 19.2154931 },
    { lat: 49.1043676, lng: 19.2159787 },
    { lat: 49.1047146, lng: 19.2162093 },
    { lat: 49.1050903, lng: 19.2163791 },
    { lat: 49.1054591, lng: 19.2165238 },
    { lat: 49.1063613, lng: 19.2169782 },
    { lat: 49.1068849, lng: 19.2173653 },
    { lat: 49.1078152, lng: 19.2180307 },
    { lat: 49.1081988, lng: 19.21834 },
    { lat: 49.1085985, lng: 19.2182819 },
    { lat: 49.1091997, lng: 19.2186669 },
    { lat: 49.1096631, lng: 19.2190435 },
    { lat: 49.1101624, lng: 19.2191154 },
    { lat: 49.1105404, lng: 19.2191698 },
    { lat: 49.1110133, lng: 19.219885 },
    { lat: 49.1112685, lng: 19.2202015 },
    { lat: 49.1120642, lng: 19.2206917 },
    { lat: 49.1122204, lng: 19.2208184 },
    { lat: 49.1122837, lng: 19.2208697 },
    { lat: 49.1126599, lng: 19.2209816 },
    { lat: 49.112934, lng: 19.221269 },
    { lat: 49.1130767, lng: 19.2216326 },
    { lat: 49.1134047, lng: 19.2222439 },
    { lat: 49.1138225, lng: 19.2230081 },
    { lat: 49.1140132, lng: 19.2235481 },
    { lat: 49.1140549, lng: 19.2238302 },
    { lat: 49.1140011, lng: 19.2242528 },
    { lat: 49.1140258, lng: 19.2244436 },
    { lat: 49.1140618, lng: 19.2244407 },
    { lat: 49.1141563, lng: 19.2245031 },
    { lat: 49.1141493, lng: 19.2248741 },
    { lat: 49.1144096, lng: 19.2248062 },
    { lat: 49.1145493, lng: 19.2248524 },
    { lat: 49.1147369, lng: 19.2249516 },
    { lat: 49.1148116, lng: 19.2250073 },
    { lat: 49.1148879, lng: 19.2250963 },
    { lat: 49.1151155, lng: 19.225417 },
    { lat: 49.1153392, lng: 19.2257809 },
    { lat: 49.1155644, lng: 19.2260821 },
    { lat: 49.1157652, lng: 19.2263198 },
    { lat: 49.116026, lng: 19.226586 },
    { lat: 49.1164841, lng: 19.2269716 },
    { lat: 49.1167565, lng: 19.2272427 },
    { lat: 49.1170442, lng: 19.227484 },
    { lat: 49.1172068, lng: 19.2275828 },
    { lat: 49.1173973, lng: 19.2275867 },
    { lat: 49.1176444, lng: 19.2275429 },
    { lat: 49.1179715, lng: 19.2273869 },
    { lat: 49.1182868, lng: 19.2272153 },
    { lat: 49.1184376, lng: 19.2271182 },
    { lat: 49.1185715, lng: 19.2270208 },
    { lat: 49.118917, lng: 19.2267491 },
    { lat: 49.1191427, lng: 19.2264738 },
    { lat: 49.1194284, lng: 19.2259953 },
    { lat: 49.1195196, lng: 19.2258045 },
    { lat: 49.1195842, lng: 19.2256011 },
    { lat: 49.1196601, lng: 19.2253044 },
    { lat: 49.1197318, lng: 19.2249448 },
    { lat: 49.1198223, lng: 19.2243921 },
    { lat: 49.119966, lng: 19.2233487 },
    { lat: 49.1200357, lng: 19.2227605 },
    { lat: 49.1200843, lng: 19.2219703 },
    { lat: 49.1200969, lng: 19.2216684 },
    { lat: 49.1201022, lng: 19.2211475 },
    { lat: 49.1200939, lng: 19.2209311 },
    { lat: 49.1200672, lng: 19.2205865 },
    { lat: 49.120043, lng: 19.2200851 },
    { lat: 49.1199869, lng: 19.2196218 },
    { lat: 49.1199421, lng: 19.2193245 },
    { lat: 49.1198854, lng: 19.2190603 },
    { lat: 49.1198629, lng: 19.2189788 },
    { lat: 49.1198213, lng: 19.2188281 },
    { lat: 49.1197459, lng: 19.2185962 },
    { lat: 49.1195893, lng: 19.2181999 },
    { lat: 49.119354, lng: 19.2177128 },
    { lat: 49.1189948, lng: 19.2169502 },
    { lat: 49.1186861, lng: 19.2162058 },
    { lat: 49.1183242, lng: 19.2150585 },
    { lat: 49.1182014, lng: 19.2145696 },
    { lat: 49.1181691, lng: 19.2143652 },
    { lat: 49.1181429, lng: 19.2140584 },
    { lat: 49.1181235, lng: 19.2135194 },
    { lat: 49.1181442, lng: 19.2131047 },
    { lat: 49.1181646, lng: 19.2129207 },
    { lat: 49.1182542, lng: 19.2123454 },
    { lat: 49.1184109, lng: 19.2116244 },
    { lat: 49.118596, lng: 19.2108833 },
    { lat: 49.118778, lng: 19.2102853 },
    { lat: 49.1190061, lng: 19.2096678 },
    { lat: 49.1193283, lng: 19.209101 },
    { lat: 49.1197357, lng: 19.2083184 },
    { lat: 49.119965, lng: 19.2078871 },
    { lat: 49.120141, lng: 19.2075863 },
    { lat: 49.1205566, lng: 19.2067434 },
    { lat: 49.1207513, lng: 19.2063142 },
    { lat: 49.1208819, lng: 19.2060556 },
    { lat: 49.1211173, lng: 19.2055164 },
    { lat: 49.121281, lng: 19.2051501 },
    { lat: 49.121448, lng: 19.2048039 },
    { lat: 49.1217499, lng: 19.2041464 },
    { lat: 49.1218441, lng: 19.2039051 },
    { lat: 49.1219345, lng: 19.2036312 },
    { lat: 49.1223252, lng: 19.2023862 },
    { lat: 49.1223656, lng: 19.2016835 },
    { lat: 49.1223607, lng: 19.2008596 },
    { lat: 49.1223189, lng: 19.2001111 },
    { lat: 49.122243, lng: 19.1993586 },
    { lat: 49.1222077, lng: 19.1981167 },
    { lat: 49.1221921, lng: 19.1974018 },
    { lat: 49.122176, lng: 19.1966664 },
    { lat: 49.1221511, lng: 19.1957835 },
    { lat: 49.1221305, lng: 19.1950518 },
    { lat: 49.1220526, lng: 19.1944003 },
    { lat: 49.1220108, lng: 19.1937896 },
    { lat: 49.1216841, lng: 19.1909079 },
    { lat: 49.1214719, lng: 19.1899271 },
    { lat: 49.1212238, lng: 19.1892281 },
    { lat: 49.120842, lng: 19.1880029 },
    { lat: 49.1205643, lng: 19.1873652 },
    { lat: 49.1203386, lng: 19.1868012 },
    { lat: 49.120045, lng: 19.1860472 },
    { lat: 49.1198024, lng: 19.185388 },
    { lat: 49.1195648, lng: 19.1847656 },
    { lat: 49.119446, lng: 19.1844796 },
    { lat: 49.1193482, lng: 19.1841807 },
  ],
  Ivachnová: [
    { lat: 49.079632, lng: 19.421394 },
    { lat: 49.0804029, lng: 19.4211072 },
    { lat: 49.0821725, lng: 19.4211106 },
    { lat: 49.0823694, lng: 19.4210588 },
    { lat: 49.0836483, lng: 19.4212467 },
    { lat: 49.0838329, lng: 19.4225505 },
    { lat: 49.0838119, lng: 19.428405 },
    { lat: 49.0836254, lng: 19.4300702 },
    { lat: 49.0861134, lng: 19.4301631 },
    { lat: 49.0870247, lng: 19.4303526 },
    { lat: 49.0888072, lng: 19.4302499 },
    { lat: 49.0912337, lng: 19.43011 },
    { lat: 49.0928191, lng: 19.4289902 },
    { lat: 49.0931083, lng: 19.4287857 },
    { lat: 49.0937078, lng: 19.4286176 },
    { lat: 49.0937905, lng: 19.4286037 },
    { lat: 49.0938184, lng: 19.4285989 },
    { lat: 49.0939451, lng: 19.4285615 },
    { lat: 49.0940229, lng: 19.428537 },
    { lat: 49.094153, lng: 19.4284967 },
    { lat: 49.0942833, lng: 19.4284662 },
    { lat: 49.0944877, lng: 19.4284474 },
    { lat: 49.0948869, lng: 19.4286288 },
    { lat: 49.0950955, lng: 19.4285808 },
    { lat: 49.0951241, lng: 19.4285198 },
    { lat: 49.0952313, lng: 19.4284928 },
    { lat: 49.0952604, lng: 19.4284689 },
    { lat: 49.0953572, lng: 19.4284379 },
    { lat: 49.0953625, lng: 19.4284359 },
    { lat: 49.0954205, lng: 19.4284178 },
    { lat: 49.0954237, lng: 19.4284171 },
    { lat: 49.0954411, lng: 19.4284037 },
    { lat: 49.0954593, lng: 19.4284039 },
    { lat: 49.0955215, lng: 19.4283606 },
    { lat: 49.0955759, lng: 19.4283223 },
    { lat: 49.0956928, lng: 19.4282404 },
    { lat: 49.0957834, lng: 19.4281777 },
    { lat: 49.0958586, lng: 19.4281247 },
    { lat: 49.0960354, lng: 19.428001 },
    { lat: 49.0954704, lng: 19.4254401 },
    { lat: 49.0955375, lng: 19.4241715 },
    { lat: 49.0957743, lng: 19.4231333 },
    { lat: 49.0961032, lng: 19.4223302 },
    { lat: 49.0968448, lng: 19.4213372 },
    { lat: 49.0982114, lng: 19.4207483 },
    { lat: 49.0984801, lng: 19.420418 },
    { lat: 49.0984891, lng: 19.4196424 },
    { lat: 49.0983842, lng: 19.4185997 },
    { lat: 49.0983466, lng: 19.4182273 },
    { lat: 49.0980748, lng: 19.4176391 },
    { lat: 49.0979069, lng: 19.4172779 },
    { lat: 49.0975002, lng: 19.4165777 },
    { lat: 49.0972357, lng: 19.4154706 },
    { lat: 49.0968811, lng: 19.4143919 },
    { lat: 49.0968121, lng: 19.4141671 },
    { lat: 49.0962811, lng: 19.4126116 },
    { lat: 49.0948418, lng: 19.4087573 },
    { lat: 49.0948198, lng: 19.4086967 },
    { lat: 49.094617, lng: 19.4076125 },
    { lat: 49.0944068, lng: 19.4059552 },
    { lat: 49.0944293, lng: 19.405106 },
    { lat: 49.0946845, lng: 19.4037878 },
    { lat: 49.0953907, lng: 19.4020451 },
    { lat: 49.0955029, lng: 19.4017661 },
    { lat: 49.0965474, lng: 19.3997342 },
    { lat: 49.0967207, lng: 19.3997529 },
    { lat: 49.0966848, lng: 19.3996367 },
    { lat: 49.0965021, lng: 19.3990768 },
    { lat: 49.0964445, lng: 19.398905 },
    { lat: 49.0963101, lng: 19.398657 },
    { lat: 49.0962443, lng: 19.3984815 },
    { lat: 49.0959318, lng: 19.3982571 },
    { lat: 49.0958604, lng: 19.3982253 },
    { lat: 49.0957287, lng: 19.3981759 },
    { lat: 49.0954847, lng: 19.3980682 },
    { lat: 49.095157, lng: 19.3979523 },
    { lat: 49.0947458, lng: 19.3978817 },
    { lat: 49.094315, lng: 19.3977572 },
    { lat: 49.0940712, lng: 19.3976239 },
    { lat: 49.0937294, lng: 19.3973985 },
    { lat: 49.0935396, lng: 19.397223 },
    { lat: 49.0932551, lng: 19.3968953 },
    { lat: 49.0931499, lng: 19.3967212 },
    { lat: 49.0930282, lng: 19.396432 },
    { lat: 49.0929844, lng: 19.3963371 },
    { lat: 49.0929037, lng: 19.3960103 },
    { lat: 49.0928422, lng: 19.3957053 },
    { lat: 49.0927795, lng: 19.3950605 },
    { lat: 49.0927975, lng: 19.3943886 },
    { lat: 49.0928152, lng: 19.3941609 },
    { lat: 49.0929124, lng: 19.3935179 },
    { lat: 49.0930017, lng: 19.3932108 },
    { lat: 49.0932865, lng: 19.3924083 },
    { lat: 49.0935635, lng: 19.3916558 },
    { lat: 49.094024, lng: 19.3899119 },
    { lat: 49.0941508, lng: 19.3892009 },
    { lat: 49.0942292, lng: 19.3885138 },
    { lat: 49.0942623, lng: 19.3878022 },
    { lat: 49.094204, lng: 19.3867233 },
    { lat: 49.0941314, lng: 19.3857268 },
    { lat: 49.0940211, lng: 19.3848643 },
    { lat: 49.0939689, lng: 19.384565 },
    { lat: 49.0938279, lng: 19.3839848 },
    { lat: 49.0937772, lng: 19.3835782 },
    { lat: 49.0935847, lng: 19.3827142 },
    { lat: 49.0935475, lng: 19.3825708 },
    { lat: 49.093343, lng: 19.3820051 },
    { lat: 49.0931776, lng: 19.3814592 },
    { lat: 49.0929525, lng: 19.3807875 },
    { lat: 49.0929136, lng: 19.3806827 },
    { lat: 49.0928762, lng: 19.3805807 },
    { lat: 49.0924806, lng: 19.3796676 },
    { lat: 49.0923049, lng: 19.3793091 },
    { lat: 49.0921735, lng: 19.379126 },
    { lat: 49.0921555, lng: 19.3790991 },
    { lat: 49.0921278, lng: 19.3790612 },
    { lat: 49.0920456, lng: 19.3789464 },
    { lat: 49.0920073, lng: 19.3788941 },
    { lat: 49.0919542, lng: 19.378819 },
    { lat: 49.0918106, lng: 19.3786185 },
    { lat: 49.0916643, lng: 19.3789123 },
    { lat: 49.0914279, lng: 19.3792041 },
    { lat: 49.0912712, lng: 19.3793973 },
    { lat: 49.0911977, lng: 19.3794876 },
    { lat: 49.0909803, lng: 19.3790557 },
    { lat: 49.0908136, lng: 19.3787239 },
    { lat: 49.089313, lng: 19.3804859 },
    { lat: 49.0880848, lng: 19.3817656 },
    { lat: 49.0858355, lng: 19.3834045 },
    { lat: 49.0857831, lng: 19.3834145 },
    { lat: 49.0856872, lng: 19.3834339 },
    { lat: 49.0856401, lng: 19.3834426 },
    { lat: 49.0853186, lng: 19.3835059 },
    { lat: 49.0848595, lng: 19.383596 },
    { lat: 49.0858092, lng: 19.3860204 },
    { lat: 49.0863506, lng: 19.3874009 },
    { lat: 49.0864735, lng: 19.3877165 },
    { lat: 49.086636, lng: 19.3881318 },
    { lat: 49.0866603, lng: 19.3881924 },
    { lat: 49.0866628, lng: 19.3881986 },
    { lat: 49.0866683, lng: 19.3882203 },
    { lat: 49.0866712, lng: 19.3882322 },
    { lat: 49.0866815, lng: 19.3882606 },
    { lat: 49.0866228, lng: 19.3883083 },
    { lat: 49.0859798, lng: 19.3888249 },
    { lat: 49.0859141, lng: 19.3889087 },
    { lat: 49.0857917, lng: 19.3891603 },
    { lat: 49.0855739, lng: 19.38983 },
    { lat: 49.0854804, lng: 19.3901184 },
    { lat: 49.0851751, lng: 19.3916895 },
    { lat: 49.0850888, lng: 19.3921303 },
    { lat: 49.0850397, lng: 19.3923844 },
    { lat: 49.0849766, lng: 19.3925545 },
    { lat: 49.0848359, lng: 19.392747 },
    { lat: 49.0843152, lng: 19.393324 },
    { lat: 49.0839743, lng: 19.3935784 },
    { lat: 49.0835786, lng: 19.3938618 },
    { lat: 49.0835676, lng: 19.3938249 },
    { lat: 49.0833529, lng: 19.3939549 },
    { lat: 49.0831285, lng: 19.3941712 },
    { lat: 49.082998, lng: 19.394318 },
    { lat: 49.082866, lng: 19.3944862 },
    { lat: 49.0827933, lng: 19.3945174 },
    { lat: 49.082723, lng: 19.3947062 },
    { lat: 49.0826494, lng: 19.3947678 },
    { lat: 49.0825395, lng: 19.3947294 },
    { lat: 49.0824664, lng: 19.3947822 },
    { lat: 49.082385, lng: 19.3949212 },
    { lat: 49.0821337, lng: 19.3948244 },
    { lat: 49.082108, lng: 19.3949767 },
    { lat: 49.0819675, lng: 19.3949117 },
    { lat: 49.0818207, lng: 19.39512 },
    { lat: 49.0816274, lng: 19.3950816 },
    { lat: 49.0815283, lng: 19.395133 },
    { lat: 49.0814484, lng: 19.3952081 },
    { lat: 49.0812771, lng: 19.3951853 },
    { lat: 49.0812521, lng: 19.3953739 },
    { lat: 49.0813093, lng: 19.3955611 },
    { lat: 49.0812186, lng: 19.3958589 },
    { lat: 49.0810392, lng: 19.3961235 },
    { lat: 49.0809314, lng: 19.3963487 },
    { lat: 49.0805436, lng: 19.3967056 },
    { lat: 49.080273, lng: 19.3966759 },
    { lat: 49.0801614, lng: 19.3967502 },
    { lat: 49.0800056, lng: 19.3971305 },
    { lat: 49.0799429, lng: 19.3972574 },
    { lat: 49.0798871, lng: 19.3973688 },
    { lat: 49.0797224, lng: 19.3976041 },
    { lat: 49.0797248, lng: 19.3977314 },
    { lat: 49.0796557, lng: 19.3979661 },
    { lat: 49.0793649, lng: 19.398058 },
    { lat: 49.0791144, lng: 19.3983432 },
    { lat: 49.0789636, lng: 19.3984925 },
    { lat: 49.07868, lng: 19.3990531 },
    { lat: 49.0785678, lng: 19.3993057 },
    { lat: 49.078515, lng: 19.3994538 },
    { lat: 49.078512, lng: 19.3994606 },
    { lat: 49.0784421, lng: 19.3997025 },
    { lat: 49.0781828, lng: 19.3999416 },
    { lat: 49.0779551, lng: 19.4003027 },
    { lat: 49.0779013, lng: 19.4005982 },
    { lat: 49.0776578, lng: 19.4006167 },
    { lat: 49.0771915, lng: 19.4017365 },
    { lat: 49.0769699, lng: 19.4019636 },
    { lat: 49.076753, lng: 19.4022124 },
    { lat: 49.0765347, lng: 19.4023935 },
    { lat: 49.076393, lng: 19.4025447 },
    { lat: 49.0762888, lng: 19.4026555 },
    { lat: 49.0762339, lng: 19.4026111 },
    { lat: 49.0761768, lng: 19.4027395 },
    { lat: 49.0760844, lng: 19.4028588 },
    { lat: 49.0758937, lng: 19.4030746 },
    { lat: 49.0758677, lng: 19.4031906 },
    { lat: 49.0757217, lng: 19.403224 },
    { lat: 49.0755864, lng: 19.4034424 },
    { lat: 49.0753297, lng: 19.4033509 },
    { lat: 49.0753077, lng: 19.4034093 },
    { lat: 49.0750675, lng: 19.4036006 },
    { lat: 49.0747826, lng: 19.4039894 },
    { lat: 49.0746306, lng: 19.4041622 },
    { lat: 49.0743838, lng: 19.4044308 },
    { lat: 49.0736114, lng: 19.4051893 },
    { lat: 49.0736096, lng: 19.4052481 },
    { lat: 49.0736015, lng: 19.4054373 },
    { lat: 49.0730703, lng: 19.4067833 },
    { lat: 49.0727328, lng: 19.4076375 },
    { lat: 49.0725655, lng: 19.4085458 },
    { lat: 49.0723789, lng: 19.4099686 },
    { lat: 49.0739976, lng: 19.410779 },
    { lat: 49.075757, lng: 19.411688 },
    { lat: 49.076076, lng: 19.411849 },
    { lat: 49.076259, lng: 19.412337 },
    { lat: 49.077005, lng: 19.414307 },
    { lat: 49.078011, lng: 19.417825 },
    { lat: 49.078176, lng: 19.418414 },
    { lat: 49.078184, lng: 19.41863 },
    { lat: 49.078192, lng: 19.41885 },
    { lat: 49.078226, lng: 19.41981 },
    { lat: 49.078241, lng: 19.420225 },
    { lat: 49.078345, lng: 19.421456 },
    { lat: 49.078373, lng: 19.42179 },
    { lat: 49.079632, lng: 19.421394 },
  ],
  Kalameny: [
    { lat: 49.170993, lng: 19.426239 },
    { lat: 49.170992, lng: 19.426236 },
    { lat: 49.170905, lng: 19.425894 },
    { lat: 49.170786, lng: 19.425418 },
    { lat: 49.170866, lng: 19.424452 },
    { lat: 49.170897, lng: 19.424203 },
    { lat: 49.170946, lng: 19.423338 },
    { lat: 49.171053, lng: 19.422746 },
    { lat: 49.171131, lng: 19.422531 },
    { lat: 49.171567, lng: 19.421685 },
    { lat: 49.171773, lng: 19.421482 },
    { lat: 49.171985, lng: 19.421225 },
    { lat: 49.172006, lng: 19.421144 },
    { lat: 49.171992, lng: 19.421073 },
    { lat: 49.171844, lng: 19.420831 },
    { lat: 49.171775, lng: 19.420631 },
    { lat: 49.171701, lng: 19.420585 },
    { lat: 49.17154, lng: 19.420302 },
    { lat: 49.171349, lng: 19.420173 },
    { lat: 49.17124, lng: 19.420193 },
    { lat: 49.171165, lng: 19.420071 },
    { lat: 49.170975, lng: 19.420028 },
    { lat: 49.170994, lng: 19.41999 },
    { lat: 49.171148, lng: 19.419764 },
    { lat: 49.171162, lng: 19.419742 },
    { lat: 49.171325, lng: 19.419504 },
    { lat: 49.171379, lng: 19.419367 },
    { lat: 49.171423, lng: 19.419256 },
    { lat: 49.171634, lng: 19.419025 },
    { lat: 49.171739, lng: 19.419029 },
    { lat: 49.171752, lng: 19.419006 },
    { lat: 49.171766, lng: 19.418884 },
    { lat: 49.171935, lng: 19.418718 },
    { lat: 49.17198, lng: 19.418583 },
    { lat: 49.172057, lng: 19.41833 },
    { lat: 49.172192, lng: 19.418165 },
    { lat: 49.172221, lng: 19.418076 },
    { lat: 49.172322, lng: 19.41776 },
    { lat: 49.172411, lng: 19.417441 },
    { lat: 49.172516, lng: 19.41706 },
    { lat: 49.1725996, lng: 19.4168886 },
    { lat: 49.172673, lng: 19.416797 },
    { lat: 49.172877, lng: 19.416503 },
    { lat: 49.173095, lng: 19.416128 },
    { lat: 49.17309, lng: 19.416071 },
    { lat: 49.173142, lng: 19.416018 },
    { lat: 49.173202, lng: 19.415877 },
    { lat: 49.173196, lng: 19.415775 },
    { lat: 49.173175, lng: 19.415313 },
    { lat: 49.173166, lng: 19.415168 },
    { lat: 49.173133, lng: 19.414837 },
    { lat: 49.173003, lng: 19.414559 },
    { lat: 49.172994, lng: 19.414413 },
    { lat: 49.172825, lng: 19.413613 },
    { lat: 49.17261, lng: 19.412597 },
    { lat: 49.172608, lng: 19.412212 },
    { lat: 49.172611, lng: 19.411786 },
    { lat: 49.1726513, lng: 19.4110864 },
    { lat: 49.1723428, lng: 19.410004 },
    { lat: 49.1721842, lng: 19.4100623 },
    { lat: 49.1718886, lng: 19.4100525 },
    { lat: 49.1717097, lng: 19.4101291 },
    { lat: 49.1714078, lng: 19.4101074 },
    { lat: 49.1710627, lng: 19.4103939 },
    { lat: 49.170694, lng: 19.4105091 },
    { lat: 49.1701054, lng: 19.4119777 },
    { lat: 49.1698906, lng: 19.4120355 },
    { lat: 49.169758, lng: 19.4120174 },
    { lat: 49.1696166, lng: 19.4119135 },
    { lat: 49.1695322, lng: 19.4117944 },
    { lat: 49.1692108, lng: 19.4109401 },
    { lat: 49.1690005, lng: 19.4108753 },
    { lat: 49.1688538, lng: 19.4107731 },
    { lat: 49.1686984, lng: 19.4104895 },
    { lat: 49.1686444, lng: 19.4097841 },
    { lat: 49.1685967, lng: 19.4086884 },
    { lat: 49.1682334, lng: 19.4080972 },
    { lat: 49.1680026, lng: 19.4076882 },
    { lat: 49.1677639, lng: 19.4073291 },
    { lat: 49.1672518, lng: 19.4059168 },
    { lat: 49.1668827, lng: 19.4055902 },
    { lat: 49.1668019, lng: 19.4051581 },
    { lat: 49.1668579, lng: 19.4046685 },
    { lat: 49.166788, lng: 19.4041611 },
    { lat: 49.1666081, lng: 19.4037106 },
    { lat: 49.1658123, lng: 19.4026917 },
    { lat: 49.1654963, lng: 19.402314 },
    { lat: 49.1650525, lng: 19.4017402 },
    { lat: 49.1647811, lng: 19.4014992 },
    { lat: 49.1642506, lng: 19.4016294 },
    { lat: 49.1640899, lng: 19.4014689 },
    { lat: 49.1638474, lng: 19.401053 },
    { lat: 49.163812, lng: 19.4008012 },
    { lat: 49.1635715, lng: 19.4005698 },
    { lat: 49.1634289, lng: 19.4002933 },
    { lat: 49.163239, lng: 19.4001856 },
    { lat: 49.1630666, lng: 19.3997236 },
    { lat: 49.1626556, lng: 19.3994873 },
    { lat: 49.1622869, lng: 19.3993894 },
    { lat: 49.1617869, lng: 19.3988412 },
    { lat: 49.1612442, lng: 19.3989 },
    { lat: 49.1609147, lng: 19.398984 },
    { lat: 49.1603904, lng: 19.398594 },
    { lat: 49.1599408, lng: 19.3984001 },
    { lat: 49.159652, lng: 19.3982481 },
    { lat: 49.1593869, lng: 19.3981962 },
    { lat: 49.1590214, lng: 19.3982574 },
    { lat: 49.1587436, lng: 19.3984377 },
    { lat: 49.1585684, lng: 19.3986007 },
    { lat: 49.1583035, lng: 19.3990057 },
    { lat: 49.1579451, lng: 19.3993661 },
    { lat: 49.1578552, lng: 19.3995148 },
    { lat: 49.1577506, lng: 19.3997378 },
    { lat: 49.1576261, lng: 19.3998226 },
    { lat: 49.1575363, lng: 19.4000246 },
    { lat: 49.1549701, lng: 19.40094 },
    { lat: 49.1536335, lng: 19.4001132 },
    { lat: 49.1535321, lng: 19.4004053 },
    { lat: 49.1532825, lng: 19.4005368 },
    { lat: 49.1529455, lng: 19.4006313 },
    { lat: 49.1523764, lng: 19.4007534 },
    { lat: 49.1519717, lng: 19.4007998 },
    { lat: 49.1512901, lng: 19.4013802 },
    { lat: 49.1507208, lng: 19.4024401 },
    { lat: 49.1503212, lng: 19.402307 },
    { lat: 49.1495457, lng: 19.4018197 },
    { lat: 49.149096, lng: 19.4015996 },
    { lat: 49.1479076, lng: 19.4016077 },
    { lat: 49.1470876, lng: 19.4012801 },
    { lat: 49.1464889, lng: 19.4012229 },
    { lat: 49.1461428, lng: 19.400931 },
    { lat: 49.1455841, lng: 19.4005269 },
    { lat: 49.1446882, lng: 19.4037642 },
    { lat: 49.1439192, lng: 19.4042124 },
    { lat: 49.1432568, lng: 19.4045807 },
    { lat: 49.1432325, lng: 19.404592 },
    { lat: 49.1425314, lng: 19.405337 },
    { lat: 49.1404883, lng: 19.405605 },
    { lat: 49.1392093, lng: 19.4060152 },
    { lat: 49.1392054, lng: 19.406342 },
    { lat: 49.13856, lng: 19.4074369 },
    { lat: 49.1381483, lng: 19.4089797 },
    { lat: 49.1377838, lng: 19.4101795 },
    { lat: 49.1369165, lng: 19.4109117 },
    { lat: 49.1360792, lng: 19.4109832 },
    { lat: 49.1322623, lng: 19.4101813 },
    { lat: 49.1312736, lng: 19.4102483 },
    { lat: 49.1299194, lng: 19.4104739 },
    { lat: 49.1291886, lng: 19.4100743 },
    { lat: 49.1278269, lng: 19.4092889 },
    { lat: 49.1267177, lng: 19.4092318 },
    { lat: 49.1258137, lng: 19.4088672 },
    { lat: 49.1246395, lng: 19.4093504 },
    { lat: 49.1237598, lng: 19.4100838 },
    { lat: 49.12325, lng: 19.4104861 },
    { lat: 49.1230115, lng: 19.4113182 },
    { lat: 49.1229419, lng: 19.4126335 },
    { lat: 49.1229312, lng: 19.4128599 },
    { lat: 49.1240062, lng: 19.4123994 },
    { lat: 49.1239873, lng: 19.4127901 },
    { lat: 49.1240146, lng: 19.4130886 },
    { lat: 49.1239867, lng: 19.4132399 },
    { lat: 49.1240067, lng: 19.4135025 },
    { lat: 49.1240585, lng: 19.4138891 },
    { lat: 49.1240145, lng: 19.4140101 },
    { lat: 49.123929, lng: 19.4144212 },
    { lat: 49.1239339, lng: 19.4146017 },
    { lat: 49.123919, lng: 19.4149314 },
    { lat: 49.1239757, lng: 19.4152056 },
    { lat: 49.1240031, lng: 19.4154793 },
    { lat: 49.123993, lng: 19.4156027 },
    { lat: 49.1239315, lng: 19.4157631 },
    { lat: 49.1239064, lng: 19.4158287 },
    { lat: 49.1238482, lng: 19.4161001 },
    { lat: 49.1237484, lng: 19.4164722 },
    { lat: 49.1237506, lng: 19.4166662 },
    { lat: 49.1237016, lng: 19.4167769 },
    { lat: 49.1235974, lng: 19.41674 },
    { lat: 49.1235867, lng: 19.4168753 },
    { lat: 49.1235761, lng: 19.4171794 },
    { lat: 49.1234018, lng: 19.4173576 },
    { lat: 49.1233552, lng: 19.4174915 },
    { lat: 49.123191, lng: 19.4177898 },
    { lat: 49.1223536, lng: 19.4186621 },
    { lat: 49.1211364, lng: 19.4201372 },
    { lat: 49.1204879, lng: 19.4207814 },
    { lat: 49.1194266, lng: 19.4215888 },
    { lat: 49.118712, lng: 19.422481 },
    { lat: 49.1183339, lng: 19.4230942 },
    { lat: 49.118047, lng: 19.4240507 },
    { lat: 49.1178245, lng: 19.4249658 },
    { lat: 49.1180082, lng: 19.426008 },
    { lat: 49.1180908, lng: 19.4264991 },
    { lat: 49.118271, lng: 19.4270593 },
    { lat: 49.1189377, lng: 19.4277991 },
    { lat: 49.1198999, lng: 19.4286229 },
    { lat: 49.1225761, lng: 19.42981 },
    { lat: 49.1233133, lng: 19.4302069 },
    { lat: 49.1245084, lng: 19.4308482 },
    { lat: 49.1254956, lng: 19.4309944 },
    { lat: 49.1262196, lng: 19.4312507 },
    { lat: 49.1271312, lng: 19.4317861 },
    { lat: 49.1282466, lng: 19.4326821 },
    { lat: 49.1290548, lng: 19.433658 },
    { lat: 49.130422, lng: 19.4345652 },
    { lat: 49.1311395, lng: 19.4317132 },
    { lat: 49.1317092, lng: 19.4302708 },
    { lat: 49.132858, lng: 19.4276338 },
    { lat: 49.1328232, lng: 19.426712 },
    { lat: 49.1326385, lng: 19.4254537 },
    { lat: 49.1327992, lng: 19.4256598 },
    { lat: 49.1331966, lng: 19.4259959 },
    { lat: 49.1335695, lng: 19.426235 },
    { lat: 49.1340383, lng: 19.4265482 },
    { lat: 49.1345122, lng: 19.4268786 },
    { lat: 49.1349197, lng: 19.4269689 },
    { lat: 49.1355285, lng: 19.427238 },
    { lat: 49.1362772, lng: 19.4275423 },
    { lat: 49.1363211, lng: 19.4274915 },
    { lat: 49.137272, lng: 19.4250379 },
    { lat: 49.1375313, lng: 19.4249415 },
    { lat: 49.1376261, lng: 19.4245315 },
    { lat: 49.1377572, lng: 19.4237151 },
    { lat: 49.1377622, lng: 19.4226054 },
    { lat: 49.1376746, lng: 19.4221042 },
    { lat: 49.1375564, lng: 19.4208239 },
    { lat: 49.1368208, lng: 19.4191049 },
    { lat: 49.1423138, lng: 19.4182461 },
    { lat: 49.1431587, lng: 19.4183129 },
    { lat: 49.1438249, lng: 19.4188992 },
    { lat: 49.1450568, lng: 19.4189026 },
    { lat: 49.1454874, lng: 19.4209197 },
    { lat: 49.1455427, lng: 19.4213122 },
    { lat: 49.1454183, lng: 19.4218616 },
    { lat: 49.1451905, lng: 19.4224113 },
    { lat: 49.1449442, lng: 19.4232251 },
    { lat: 49.1449442, lng: 19.425266 },
    { lat: 49.1443263, lng: 19.4272 },
    { lat: 49.1441184, lng: 19.4285473 },
    { lat: 49.1436761, lng: 19.4315011 },
    { lat: 49.14377, lng: 19.431578 },
    { lat: 49.14418, lng: 19.431179 },
    { lat: 49.14503, lng: 19.429385 },
    { lat: 49.145563, lng: 19.428325 },
    { lat: 49.146017, lng: 19.427745 },
    { lat: 49.146486, lng: 19.427475 },
    { lat: 49.146954, lng: 19.426567 },
    { lat: 49.147144, lng: 19.426295 },
    { lat: 49.147478, lng: 19.426478 },
    { lat: 49.147892, lng: 19.42697 },
    { lat: 49.148123, lng: 19.427085 },
    { lat: 49.148671, lng: 19.426889 },
    { lat: 49.14908, lng: 19.427305 },
    { lat: 49.149629, lng: 19.42734 },
    { lat: 49.151055, lng: 19.4276 },
    { lat: 49.151818, lng: 19.427553 },
    { lat: 49.152394, lng: 19.427721 },
    { lat: 49.152988, lng: 19.427888 },
    { lat: 49.15346, lng: 19.42775 },
    { lat: 49.153997, lng: 19.427473 },
    { lat: 49.154224, lng: 19.427334 },
    { lat: 49.154543, lng: 19.427103 },
    { lat: 49.154903, lng: 19.426842 },
    { lat: 49.157351, lng: 19.425923 },
    { lat: 49.158337, lng: 19.425532 },
    { lat: 49.159902, lng: 19.424269 },
    { lat: 49.160233, lng: 19.424833 },
    { lat: 49.160651, lng: 19.425264 },
    { lat: 49.163549, lng: 19.425582 },
    { lat: 49.164399, lng: 19.425324 },
    { lat: 49.16563, lng: 19.425197 },
    { lat: 49.166527, lng: 19.42543 },
    { lat: 49.167989, lng: 19.426074 },
    { lat: 49.169333, lng: 19.426987 },
    { lat: 49.170347, lng: 19.427036 },
    { lat: 49.170993, lng: 19.426239 },
  ],
  Komjatná: [
    { lat: 49.16299, lng: 19.265389 },
    { lat: 49.162701, lng: 19.264806 },
    { lat: 49.162418, lng: 19.263941 },
    { lat: 49.162409, lng: 19.263271 },
    { lat: 49.162516, lng: 19.262914 },
    { lat: 49.162747, lng: 19.262334 },
    { lat: 49.162941, lng: 19.261594 },
    { lat: 49.163207, lng: 19.260921 },
    { lat: 49.163226, lng: 19.260872 },
    { lat: 49.163318, lng: 19.260639 },
    { lat: 49.163769, lng: 19.25992 },
    { lat: 49.164205, lng: 19.258916 },
    { lat: 49.164479, lng: 19.258218 },
    { lat: 49.164774, lng: 19.257654 },
    { lat: 49.165058, lng: 19.25668 },
    { lat: 49.165065, lng: 19.256329 },
    { lat: 49.16508, lng: 19.255569 },
    { lat: 49.165019, lng: 19.254984 },
    { lat: 49.16494, lng: 19.254215 },
    { lat: 49.164607, lng: 19.252891 },
    { lat: 49.164098, lng: 19.251476 },
    { lat: 49.163902, lng: 19.25075 },
    { lat: 49.163885, lng: 19.250434 },
    { lat: 49.16395, lng: 19.250149 },
    { lat: 49.163757, lng: 19.249869 },
    { lat: 49.163586, lng: 19.249622 },
    { lat: 49.163434, lng: 19.249402 },
    { lat: 49.16333, lng: 19.249252 },
    { lat: 49.162726, lng: 19.24828 },
    { lat: 49.162633, lng: 19.248095 },
    { lat: 49.162407, lng: 19.247641 },
    { lat: 49.161993, lng: 19.247227 },
    { lat: 49.161799, lng: 19.247031 },
    { lat: 49.161653, lng: 19.246577 },
    { lat: 49.161441, lng: 19.246426 },
    { lat: 49.160886, lng: 19.245541 },
    { lat: 49.160331, lng: 19.244661 },
    { lat: 49.160008, lng: 19.243993 },
    { lat: 49.15979, lng: 19.243301 },
    { lat: 49.159728, lng: 19.243171 },
    { lat: 49.159288, lng: 19.242867 },
    { lat: 49.158906, lng: 19.24251 },
    { lat: 49.15871, lng: 19.242215 },
    { lat: 49.158613, lng: 19.241911 },
    { lat: 49.158537, lng: 19.24173 },
    { lat: 49.158389, lng: 19.241382 },
    { lat: 49.158264, lng: 19.241159 },
    { lat: 49.158194, lng: 19.241035 },
    { lat: 49.157915, lng: 19.24054 },
    { lat: 49.157575, lng: 19.239887 },
    { lat: 49.157451, lng: 19.239145 },
    { lat: 49.157245, lng: 19.238259 },
    { lat: 49.156983, lng: 19.237666 },
    { lat: 49.15639, lng: 19.236718 },
    { lat: 49.15554, lng: 19.235828 },
    { lat: 49.155321, lng: 19.235262 },
    { lat: 49.155045, lng: 19.234747 },
    { lat: 49.155011, lng: 19.234685 },
    { lat: 49.15499, lng: 19.234645 },
    { lat: 49.154959, lng: 19.234588 },
    { lat: 49.154598, lng: 19.234021 },
    { lat: 49.15449, lng: 19.233851 },
    { lat: 49.154205, lng: 19.233033 },
    { lat: 49.153634, lng: 19.232636 },
    { lat: 49.153009, lng: 19.232097 },
    { lat: 49.152524, lng: 19.231791 },
    { lat: 49.152454, lng: 19.231746 },
    { lat: 49.152293, lng: 19.231643 },
    { lat: 49.152055, lng: 19.231434 },
    { lat: 49.151939, lng: 19.231233 },
    { lat: 49.15173, lng: 19.230539 },
    { lat: 49.151568, lng: 19.229995 },
    { lat: 49.15143, lng: 19.229696 },
    { lat: 49.15101, lng: 19.228982 },
    { lat: 49.150448, lng: 19.227883 },
    { lat: 49.150366, lng: 19.227698 },
    { lat: 49.150293, lng: 19.227531 },
    { lat: 49.150263, lng: 19.227462 },
    { lat: 49.150155, lng: 19.227277 },
    { lat: 49.149483, lng: 19.22592 },
    { lat: 49.14894, lng: 19.224871 },
    { lat: 49.148135, lng: 19.223463 },
    { lat: 49.147765, lng: 19.223053 },
    { lat: 49.147231, lng: 19.222465 },
    { lat: 49.147063, lng: 19.222037 },
    { lat: 49.146854, lng: 19.221508 },
    { lat: 49.146844, lng: 19.221492 },
    { lat: 49.146778, lng: 19.221377 },
    { lat: 49.146647, lng: 19.221226 },
    { lat: 49.146483, lng: 19.221037 },
    { lat: 49.146409, lng: 19.220952 },
    { lat: 49.146614, lng: 19.220323 },
    { lat: 49.147008, lng: 19.219679 },
    { lat: 49.147182, lng: 19.217699 },
    { lat: 49.146728, lng: 19.217434 },
    { lat: 49.14653, lng: 19.217181 },
    { lat: 49.146289, lng: 19.216914 },
    { lat: 49.145866, lng: 19.216654 },
    { lat: 49.145295, lng: 19.21618 },
    { lat: 49.145044, lng: 19.215354 },
    { lat: 49.14471, lng: 19.214982 },
    { lat: 49.144563, lng: 19.2146 },
    { lat: 49.14384, lng: 19.214178 },
    { lat: 49.143849, lng: 19.213956 },
    { lat: 49.143332, lng: 19.213517 },
    { lat: 49.14184, lng: 19.2117 },
    { lat: 49.14168, lng: 19.21123 },
    { lat: 49.141365, lng: 19.211023 },
    { lat: 49.141117, lng: 19.210515 },
    { lat: 49.141113, lng: 19.21029 },
    { lat: 49.140916, lng: 19.209687 },
    { lat: 49.139812, lng: 19.209335 },
    { lat: 49.138702, lng: 19.209072 },
    { lat: 49.138222, lng: 19.209117 },
    { lat: 49.1364695, lng: 19.2104829 },
    { lat: 49.1327875, lng: 19.216796 },
    { lat: 49.1322982, lng: 19.2176344 },
    { lat: 49.1306441, lng: 19.2211991 },
    { lat: 49.1303996, lng: 19.2216922 },
    { lat: 49.1303769, lng: 19.2217522 },
    { lat: 49.1303256, lng: 19.221888 },
    { lat: 49.1281885, lng: 19.2271019 },
    { lat: 49.1275574, lng: 19.2304542 },
    { lat: 49.1276851, lng: 19.2316549 },
    { lat: 49.1284838, lng: 19.2343415 },
    { lat: 49.1257312, lng: 19.2397791 },
    { lat: 49.125525, lng: 19.2397528 },
    { lat: 49.1256083, lng: 19.242595 },
    { lat: 49.1253907, lng: 19.2431098 },
    { lat: 49.125607, lng: 19.2435769 },
    { lat: 49.1247701, lng: 19.2463835 },
    { lat: 49.1242842, lng: 19.2471548 },
    { lat: 49.1240639, lng: 19.2474967 },
    { lat: 49.1238723, lng: 19.2478945 },
    { lat: 49.1236111, lng: 19.2486083 },
    { lat: 49.1234945, lng: 19.2490668 },
    { lat: 49.1234783, lng: 19.2493169 },
    { lat: 49.1233321, lng: 19.2495581 },
    { lat: 49.1235352, lng: 19.249986 },
    { lat: 49.1232767, lng: 19.250402 },
    { lat: 49.1231013, lng: 19.2509264 },
    { lat: 49.1232577, lng: 19.2522134 },
    { lat: 49.1230835, lng: 19.2528593 },
    { lat: 49.1230008, lng: 19.2531556 },
    { lat: 49.1233621, lng: 19.2540849 },
    { lat: 49.1234677, lng: 19.2543624 },
    { lat: 49.1236215, lng: 19.2554785 },
    { lat: 49.1234821, lng: 19.2561223 },
    { lat: 49.1233268, lng: 19.2567171 },
    { lat: 49.1231647, lng: 19.2574246 },
    { lat: 49.122942, lng: 19.2583777 },
    { lat: 49.1221542, lng: 19.2590853 },
    { lat: 49.1216577, lng: 19.2596348 },
    { lat: 49.121598, lng: 19.2598005 },
    { lat: 49.1214028, lng: 19.2597977 },
    { lat: 49.1213821, lng: 19.260519 },
    { lat: 49.1210701, lng: 19.261487 },
    { lat: 49.120841, lng: 19.2621429 },
    { lat: 49.1204242, lng: 19.2628495 },
    { lat: 49.1205853, lng: 19.2639118 },
    { lat: 49.1206261, lng: 19.2645279 },
    { lat: 49.1206674, lng: 19.2653419 },
    { lat: 49.1207161, lng: 19.2661972 },
    { lat: 49.121517, lng: 19.2669698 },
    { lat: 49.1222088, lng: 19.2677915 },
    { lat: 49.1230538, lng: 19.2684668 },
    { lat: 49.1232464, lng: 19.2686778 },
    { lat: 49.1243555, lng: 19.2699535 },
    { lat: 49.1250475, lng: 19.270662 },
    { lat: 49.1253293, lng: 19.2708952 },
    { lat: 49.1258166, lng: 19.2715195 },
    { lat: 49.1263005, lng: 19.2721424 },
    { lat: 49.1266244, lng: 19.271987 },
    { lat: 49.1268402, lng: 19.2718764 },
    { lat: 49.1270641, lng: 19.2717544 },
    { lat: 49.1273035, lng: 19.2716426 },
    { lat: 49.1277773, lng: 19.2713914 },
    { lat: 49.1283379, lng: 19.2709294 },
    { lat: 49.1288996, lng: 19.2709869 },
    { lat: 49.1295939, lng: 19.2710852 },
    { lat: 49.1305654, lng: 19.2712949 },
    { lat: 49.1315067, lng: 19.2714665 },
    { lat: 49.1319149, lng: 19.2711618 },
    { lat: 49.132088, lng: 19.2714136 },
    { lat: 49.1321795, lng: 19.2714646 },
    { lat: 49.132184, lng: 19.2714163 },
    { lat: 49.1335106, lng: 19.2722431 },
    { lat: 49.1349589, lng: 19.272933 },
    { lat: 49.1349578, lng: 19.2729803 },
    { lat: 49.1349808, lng: 19.2730357 },
    { lat: 49.1362731, lng: 19.2734071 },
    { lat: 49.1364644, lng: 19.2728078 },
    { lat: 49.1368098, lng: 19.2721137 },
    { lat: 49.1369831, lng: 19.2713991 },
    { lat: 49.1386343, lng: 19.2722044 },
    { lat: 49.1389659, lng: 19.2714967 },
    { lat: 49.1394007, lng: 19.2708585 },
    { lat: 49.1399607, lng: 19.270147 },
    { lat: 49.1400102, lng: 19.2700526 },
    { lat: 49.140012, lng: 19.2699714 },
    { lat: 49.1399924, lng: 19.269866 },
    { lat: 49.1400188, lng: 19.2698515 },
    { lat: 49.1401843, lng: 19.2696377 },
    { lat: 49.1404386, lng: 19.2689684 },
    { lat: 49.1405364, lng: 19.2686519 },
    { lat: 49.140608, lng: 19.2683625 },
    { lat: 49.1408185, lng: 19.2667952 },
    { lat: 49.1408473, lng: 19.2660793 },
    { lat: 49.1407995, lng: 19.2657653 },
    { lat: 49.141404, lng: 19.2653731 },
    { lat: 49.1414473, lng: 19.2650483 },
    { lat: 49.1417992, lng: 19.2648905 },
    { lat: 49.1421228, lng: 19.2648218 },
    { lat: 49.1430621, lng: 19.2647833 },
    { lat: 49.1431866, lng: 19.2647958 },
    { lat: 49.1441291, lng: 19.2648729 },
    { lat: 49.1450456, lng: 19.2671457 },
    { lat: 49.1451049, lng: 19.2672294 },
    { lat: 49.1453936, lng: 19.2677061 },
    { lat: 49.1458506, lng: 19.2682792 },
    { lat: 49.1461534, lng: 19.268795 },
    { lat: 49.1464289, lng: 19.2693473 },
    { lat: 49.1468925, lng: 19.269802 },
    { lat: 49.1482521, lng: 19.2706376 },
    { lat: 49.1485891, lng: 19.2708979 },
    { lat: 49.1497655, lng: 19.2719098 },
    { lat: 49.1505597, lng: 19.2726307 },
    { lat: 49.151346, lng: 19.273237 },
    { lat: 49.152396, lng: 19.274273 },
    { lat: 49.153081, lng: 19.274709 },
    { lat: 49.153161, lng: 19.27472 },
    { lat: 49.153205, lng: 19.274603 },
    { lat: 49.153697, lng: 19.274096 },
    { lat: 49.15397, lng: 19.273759 },
    { lat: 49.154091, lng: 19.273608 },
    { lat: 49.154197, lng: 19.273477 },
    { lat: 49.154343, lng: 19.273311 },
    { lat: 49.154454, lng: 19.273168 },
    { lat: 49.154547, lng: 19.27305 },
    { lat: 49.155057, lng: 19.272553 },
    { lat: 49.155229, lng: 19.272301 },
    { lat: 49.15524, lng: 19.272283 },
    { lat: 49.155694, lng: 19.272158 },
    { lat: 49.155792, lng: 19.272133 },
    { lat: 49.15585, lng: 19.272135 },
    { lat: 49.156236, lng: 19.272076 },
    { lat: 49.156995, lng: 19.271766 },
    { lat: 49.157644, lng: 19.271413 },
    { lat: 49.158723, lng: 19.270807 },
    { lat: 49.159697, lng: 19.270567 },
    { lat: 49.160596, lng: 19.270319 },
    { lat: 49.160669, lng: 19.270298 },
    { lat: 49.160911, lng: 19.270101 },
    { lat: 49.161102, lng: 19.269846 },
    { lat: 49.161414, lng: 19.268921 },
    { lat: 49.161432, lng: 19.268864 },
    { lat: 49.161445, lng: 19.268825 },
    { lat: 49.161509, lng: 19.268608 },
    { lat: 49.161578, lng: 19.268374 },
    { lat: 49.161766, lng: 19.267734 },
    { lat: 49.161922, lng: 19.267204 },
    { lat: 49.16213, lng: 19.266496 },
    { lat: 49.16242, lng: 19.266041 },
    { lat: 49.162574, lng: 19.265901 },
    { lat: 49.16284, lng: 19.265545 },
    { lat: 49.16288, lng: 19.265503 },
    { lat: 49.162895, lng: 19.265488 },
    { lat: 49.16299, lng: 19.265389 },
  ],
  Likavka: [
    { lat: 49.1188256, lng: 19.273501 },
    { lat: 49.1180973, lng: 19.2739724 },
    { lat: 49.1178175, lng: 19.2740815 },
    { lat: 49.1173953, lng: 19.273924 },
    { lat: 49.1169064, lng: 19.2733165 },
    { lat: 49.1168473, lng: 19.2735414 },
    { lat: 49.1167126, lng: 19.2734372 },
    { lat: 49.1164635, lng: 19.2735753 },
    { lat: 49.1160229, lng: 19.273627 },
    { lat: 49.1158807, lng: 19.2733603 },
    { lat: 49.1156693, lng: 19.2732762 },
    { lat: 49.1158126, lng: 19.2729977 },
    { lat: 49.1156687, lng: 19.2726984 },
    { lat: 49.1154541, lng: 19.2726909 },
    { lat: 49.1151042, lng: 19.2728409 },
    { lat: 49.1150531, lng: 19.2730977 },
    { lat: 49.1149022, lng: 19.2734968 },
    { lat: 49.1146714, lng: 19.2736001 },
    { lat: 49.1142819, lng: 19.2735148 },
    { lat: 49.1141263, lng: 19.2738167 },
    { lat: 49.1138202, lng: 19.2739945 },
    { lat: 49.1134764, lng: 19.2738279 },
    { lat: 49.1134095, lng: 19.2736284 },
    { lat: 49.1129232, lng: 19.2736636 },
    { lat: 49.1128234, lng: 19.2734624 },
    { lat: 49.1130526, lng: 19.2727434 },
    { lat: 49.113913, lng: 19.270865 },
    { lat: 49.1141667, lng: 19.2705591 },
    { lat: 49.1143142, lng: 19.270251 },
    { lat: 49.1139291, lng: 19.2695 },
    { lat: 49.1137439, lng: 19.2694713 },
    { lat: 49.1136312, lng: 19.2692114 },
    { lat: 49.1134515, lng: 19.2687784 },
    { lat: 49.1130834, lng: 19.2683346 },
    { lat: 49.112434, lng: 19.2682138 },
    { lat: 49.1118733, lng: 19.2683666 },
    { lat: 49.1116272, lng: 19.2684792 },
    { lat: 49.1114194, lng: 19.2685141 },
    { lat: 49.1111746, lng: 19.2687247 },
    { lat: 49.1109072, lng: 19.2689303 },
    { lat: 49.1107739, lng: 19.2690185 },
    { lat: 49.1107896, lng: 19.2693222 },
    { lat: 49.110829, lng: 19.2696265 },
    { lat: 49.110931, lng: 19.2695929 },
    { lat: 49.111217, lng: 19.2692107 },
    { lat: 49.1113571, lng: 19.2698992 },
    { lat: 49.1111928, lng: 19.2706129 },
    { lat: 49.1106784, lng: 19.2707458 },
    { lat: 49.1105189, lng: 19.270779 },
    { lat: 49.1102519, lng: 19.2705485 },
    { lat: 49.1102508, lng: 19.2705461 },
    { lat: 49.1101898, lng: 19.2704503 },
    { lat: 49.1098525, lng: 19.2706571 },
    { lat: 49.1097094, lng: 19.2709137 },
    { lat: 49.1095355, lng: 19.2710502 },
    { lat: 49.1094785, lng: 19.2709198 },
    { lat: 49.1094251, lng: 19.270484 },
    { lat: 49.1103141, lng: 19.2695364 },
    { lat: 49.1102829, lng: 19.2694467 },
    { lat: 49.1102098, lng: 19.2692865 },
    { lat: 49.1099722, lng: 19.2690354 },
    { lat: 49.1096234, lng: 19.2689946 },
    { lat: 49.1089474, lng: 19.2690969 },
    { lat: 49.1089237, lng: 19.2707227 },
    { lat: 49.1088046, lng: 19.2710859 },
    { lat: 49.1088631, lng: 19.2712474 },
    { lat: 49.1089626, lng: 19.2715619 },
    { lat: 49.1089821, lng: 19.2717807 },
    { lat: 49.1094486, lng: 19.2728657 },
    { lat: 49.1092357, lng: 19.2728678 },
    { lat: 49.1090927, lng: 19.2730591 },
    { lat: 49.1089313, lng: 19.2730313 },
    { lat: 49.1084841, lng: 19.2723273 },
    { lat: 49.1083784, lng: 19.2719094 },
    { lat: 49.1087312, lng: 19.2715074 },
    { lat: 49.1082875, lng: 19.2713267 },
    { lat: 49.1071891, lng: 19.2706988 },
    { lat: 49.1069342, lng: 19.2707173 },
    { lat: 49.1068573, lng: 19.2710395 },
    { lat: 49.1068224, lng: 19.2717981 },
    { lat: 49.1069654, lng: 19.272102 },
    { lat: 49.1064982, lng: 19.2729157 },
    { lat: 49.107109, lng: 19.2743524 },
    { lat: 49.107108, lng: 19.2743564 },
    { lat: 49.1074382, lng: 19.275208 },
    { lat: 49.1075, lng: 19.2756012 },
    { lat: 49.1075604, lng: 19.2761593 },
    { lat: 49.1074917, lng: 19.2764464 },
    { lat: 49.106945, lng: 19.2764177 },
    { lat: 49.1064617, lng: 19.2766593 },
    { lat: 49.1057097, lng: 19.2769588 },
    { lat: 49.1039509, lng: 19.2781999 },
    { lat: 49.1030555, lng: 19.2789911 },
    { lat: 49.1018555, lng: 19.2790455 },
    { lat: 49.1015208, lng: 19.2797774 },
    { lat: 49.1010269, lng: 19.2808108 },
    { lat: 49.1007782, lng: 19.2811026 },
    { lat: 49.1005409, lng: 19.281513 },
    { lat: 49.1002998, lng: 19.2815397 },
    { lat: 49.1001905, lng: 19.2813281 },
    { lat: 49.1001106, lng: 19.2813067 },
    { lat: 49.0999222, lng: 19.2816599 },
    { lat: 49.0996669, lng: 19.2818377 },
    { lat: 49.0979366, lng: 19.2827499 },
    { lat: 49.0977624, lng: 19.2830195 },
    { lat: 49.0974889, lng: 19.2835741 },
    { lat: 49.0967153, lng: 19.2845576 },
    { lat: 49.0962574, lng: 19.2859447 },
    { lat: 49.0948771, lng: 19.2867554 },
    { lat: 49.0944814, lng: 19.2870595 },
    { lat: 49.0943073, lng: 19.2874268 },
    { lat: 49.092369, lng: 19.2914124 },
    { lat: 49.0912361, lng: 19.2924187 },
    { lat: 49.0909331, lng: 19.2929282 },
    { lat: 49.0904076, lng: 19.2938116 },
    { lat: 49.0900324, lng: 19.2944089 },
    { lat: 49.0897378, lng: 19.294892 },
    { lat: 49.0879428, lng: 19.2978135 },
    { lat: 49.0879114, lng: 19.2978691 },
    { lat: 49.0877498, lng: 19.2979506 },
    { lat: 49.087735, lng: 19.2980676 },
    { lat: 49.0874722, lng: 19.2986045 },
    { lat: 49.0874379, lng: 19.2986407 },
    { lat: 49.087412, lng: 19.2986602 },
    { lat: 49.0873738, lng: 19.2986903 },
    { lat: 49.0870949, lng: 19.2989505 },
    { lat: 49.0872785, lng: 19.299603 },
    { lat: 49.0873259, lng: 19.299957 },
    { lat: 49.0873062, lng: 19.3005981 },
    { lat: 49.0871871, lng: 19.3012807 },
    { lat: 49.0870945, lng: 19.3014999 },
    { lat: 49.086925, lng: 19.3016924 },
    { lat: 49.0860546, lng: 19.3026714 },
    { lat: 49.0860194, lng: 19.302595 },
    { lat: 49.0857305, lng: 19.3029255 },
    { lat: 49.0853782, lng: 19.3033721 },
    { lat: 49.0849439, lng: 19.3040165 },
    { lat: 49.0845992, lng: 19.3045999 },
    { lat: 49.0842223, lng: 19.3052403 },
    { lat: 49.0839544, lng: 19.3056956 },
    { lat: 49.0836764, lng: 19.3062126 },
    { lat: 49.0835183, lng: 19.3065063 },
    { lat: 49.0831988, lng: 19.3070105 },
    { lat: 49.0833633, lng: 19.3081471 },
    { lat: 49.0839068, lng: 19.3089228 },
    { lat: 49.0831717, lng: 19.3100949 },
    { lat: 49.0826246, lng: 19.3110568 },
    { lat: 49.0821163, lng: 19.3119116 },
    { lat: 49.0819259, lng: 19.312268 },
    { lat: 49.0817171, lng: 19.3128109 },
    { lat: 49.0815584, lng: 19.3133119 },
    { lat: 49.0814688, lng: 19.3137039 },
    { lat: 49.0813728, lng: 19.3142246 },
    { lat: 49.0813123, lng: 19.3147512 },
    { lat: 49.0812677, lng: 19.3157142 },
    { lat: 49.0812937, lng: 19.3164053 },
    { lat: 49.0816134, lng: 19.3181343 },
    { lat: 49.0817735, lng: 19.3189663 },
    { lat: 49.0813337, lng: 19.3192688 },
    { lat: 49.0813021, lng: 19.3192939 },
    { lat: 49.0810513, lng: 19.319448 },
    { lat: 49.081131, lng: 19.319656 },
    { lat: 49.0813616, lng: 19.3202857 },
    { lat: 49.0816052, lng: 19.3209572 },
    { lat: 49.0818039, lng: 19.3214617 },
    { lat: 49.0819973, lng: 19.3219805 },
    { lat: 49.0823722, lng: 19.3229698 },
    { lat: 49.0824719, lng: 19.3232404 },
    { lat: 49.0826746, lng: 19.3237596 },
    { lat: 49.0828189, lng: 19.3241701 },
    { lat: 49.0829364, lng: 19.3244611 },
    { lat: 49.083159, lng: 19.3252585 },
    { lat: 49.0833147, lng: 19.3258322 },
    { lat: 49.0834391, lng: 19.3263902 },
    { lat: 49.0834617, lng: 19.3266027 },
    { lat: 49.0834859, lng: 19.3270693 },
    { lat: 49.0835051, lng: 19.3276903 },
    { lat: 49.0834257, lng: 19.3283246 },
    { lat: 49.0839709, lng: 19.3288656 },
    { lat: 49.0845951, lng: 19.3294799 },
    { lat: 49.0848988, lng: 19.3298008 },
    { lat: 49.0854345, lng: 19.3275944 },
    { lat: 49.0864715, lng: 19.3259529 },
    { lat: 49.0870564, lng: 19.3253584 },
    { lat: 49.0876292, lng: 19.3248864 },
    { lat: 49.0883882, lng: 19.3243029 },
    { lat: 49.0886806, lng: 19.3249116 },
    { lat: 49.0891465, lng: 19.325585 },
    { lat: 49.0895448, lng: 19.3260961 },
    { lat: 49.090099, lng: 19.3269746 },
    { lat: 49.0904497, lng: 19.3279527 },
    { lat: 49.090839, lng: 19.3290837 },
    { lat: 49.0909855, lng: 19.3297735 },
    { lat: 49.0910252, lng: 19.3300191 },
    { lat: 49.0911144, lng: 19.3299974 },
    { lat: 49.0914055, lng: 19.3307196 },
    { lat: 49.0917876, lng: 19.3322484 },
    { lat: 49.092011, lng: 19.333444 },
    { lat: 49.0924902, lng: 19.3332588 },
    { lat: 49.0930002, lng: 19.3328933 },
    { lat: 49.0941173, lng: 19.3320844 },
    { lat: 49.0942596, lng: 19.3320422 },
    { lat: 49.0944172, lng: 19.3325183 },
    { lat: 49.0942072, lng: 19.333057 },
    { lat: 49.0944725, lng: 19.3337839 },
    { lat: 49.0950429, lng: 19.3333114 },
    { lat: 49.0955788, lng: 19.3347764 },
    { lat: 49.0959364, lng: 19.3345682 },
    { lat: 49.0965103, lng: 19.3345546 },
    { lat: 49.0969825, lng: 19.3346457 },
    { lat: 49.0973786, lng: 19.3352079 },
    { lat: 49.0977378, lng: 19.3357288 },
    { lat: 49.097851, lng: 19.3356891 },
    { lat: 49.0983242, lng: 19.3352327 },
    { lat: 49.0985926, lng: 19.3349767 },
    { lat: 49.0989411, lng: 19.3346202 },
    { lat: 49.0992056, lng: 19.3341849 },
    { lat: 49.0992603, lng: 19.3340785 },
    { lat: 49.0995623, lng: 19.3334329 },
    { lat: 49.0997975, lng: 19.3327824 },
    { lat: 49.0998501, lng: 19.3327515 },
    { lat: 49.1004604, lng: 19.3322872 },
    { lat: 49.1007239, lng: 19.3320911 },
    { lat: 49.1014896, lng: 19.3319571 },
    { lat: 49.1021345, lng: 19.3320379 },
    { lat: 49.102465, lng: 19.3320394 },
    { lat: 49.1028132, lng: 19.3318505 },
    { lat: 49.103259, lng: 19.3315754 },
    { lat: 49.1031969, lng: 19.3314301 },
    { lat: 49.1032518, lng: 19.3313075 },
    { lat: 49.1031828, lng: 19.3310867 },
    { lat: 49.1034138, lng: 19.3300582 },
    { lat: 49.1035419, lng: 19.3287418 },
    { lat: 49.1036258, lng: 19.3275103 },
    { lat: 49.1038965, lng: 19.3253952 },
    { lat: 49.1040057, lng: 19.3243919 },
    { lat: 49.1041263, lng: 19.3235136 },
    { lat: 49.1040938, lng: 19.3229899 },
    { lat: 49.1041326, lng: 19.3225482 },
    { lat: 49.1043053, lng: 19.3219361 },
    { lat: 49.1046839, lng: 19.3219575 },
    { lat: 49.1049167, lng: 19.3220898 },
    { lat: 49.1052001, lng: 19.3219861 },
    { lat: 49.105484, lng: 19.3220509 },
    { lat: 49.1058811, lng: 19.322349 },
    { lat: 49.1070865, lng: 19.3226235 },
    { lat: 49.1077416, lng: 19.3227738 },
    { lat: 49.1081515, lng: 19.3228605 },
    { lat: 49.1081328, lng: 19.3217032 },
    { lat: 49.1086781, lng: 19.3213188 },
    { lat: 49.1093898, lng: 19.3211665 },
    { lat: 49.1098645, lng: 19.3210532 },
    { lat: 49.1100104, lng: 19.3212757 },
    { lat: 49.1102499, lng: 19.3217174 },
    { lat: 49.1106756, lng: 19.3224027 },
    { lat: 49.1109181, lng: 19.3226671 },
    { lat: 49.1115684, lng: 19.3230238 },
    { lat: 49.1118238, lng: 19.3231357 },
    { lat: 49.1125562, lng: 19.3232772 },
    { lat: 49.1133336, lng: 19.3234361 },
    { lat: 49.1138619, lng: 19.3239566 },
    { lat: 49.114199, lng: 19.324438 },
    { lat: 49.1146227, lng: 19.3245087 },
    { lat: 49.1149938, lng: 19.3248943 },
    { lat: 49.1156197, lng: 19.3249965 },
    { lat: 49.1160067, lng: 19.325244 },
    { lat: 49.1164252, lng: 19.3254696 },
    { lat: 49.1171467, lng: 19.3256394 },
    { lat: 49.1179246, lng: 19.3258357 },
    { lat: 49.1182253, lng: 19.325906 },
    { lat: 49.1186201, lng: 19.3260292 },
    { lat: 49.1188806, lng: 19.3261762 },
    { lat: 49.1204579, lng: 19.3266208 },
    { lat: 49.12112, lng: 19.3277772 },
    { lat: 49.1206918, lng: 19.3285654 },
    { lat: 49.1207839, lng: 19.3298197 },
    { lat: 49.1199571, lng: 19.3309038 },
    { lat: 49.1197488, lng: 19.3316934 },
    { lat: 49.1199976, lng: 19.3316413 },
    { lat: 49.1200475, lng: 19.3326281 },
    { lat: 49.1204585, lng: 19.3336002 },
    { lat: 49.1209817, lng: 19.3349616 },
    { lat: 49.1213774, lng: 19.3360905 },
    { lat: 49.1217745, lng: 19.3371238 },
    { lat: 49.122162, lng: 19.3383393 },
    { lat: 49.1224888, lng: 19.3388393 },
    { lat: 49.1227694, lng: 19.339436 },
    { lat: 49.1230646, lng: 19.339557 },
    { lat: 49.1234647, lng: 19.3394376 },
    { lat: 49.1258505, lng: 19.3407294 },
    { lat: 49.1264558, lng: 19.3414461 },
    { lat: 49.1276097, lng: 19.3424983 },
    { lat: 49.1280288, lng: 19.3426827 },
    { lat: 49.1293816, lng: 19.3426958 },
    { lat: 49.1296734, lng: 19.3427877 },
    { lat: 49.129854, lng: 19.3428662 },
    { lat: 49.1307441, lng: 19.3424769 },
    { lat: 49.1311748, lng: 19.3425809 },
    { lat: 49.1313347, lng: 19.3425277 },
    { lat: 49.1324202, lng: 19.34256 },
    { lat: 49.1331828, lng: 19.3422397 },
    { lat: 49.1338062, lng: 19.3413139 },
    { lat: 49.1351132, lng: 19.3390365 },
    { lat: 49.1354699, lng: 19.3371615 },
    { lat: 49.1353622, lng: 19.3361645 },
    { lat: 49.1354098, lng: 19.3350727 },
    { lat: 49.1371332, lng: 19.3343648 },
    { lat: 49.1378035, lng: 19.3336675 },
    { lat: 49.1390075, lng: 19.3329333 },
    { lat: 49.1381281, lng: 19.3323794 },
    { lat: 49.1373579, lng: 19.3319614 },
    { lat: 49.1368739, lng: 19.3319047 },
    { lat: 49.1365061, lng: 19.3318922 },
    { lat: 49.1360333, lng: 19.3317619 },
    { lat: 49.1353388, lng: 19.3308279 },
    { lat: 49.1351136, lng: 19.3306838 },
    { lat: 49.1350629, lng: 19.3303996 },
    { lat: 49.134976, lng: 19.330136 },
    { lat: 49.1348565, lng: 19.3289664 },
    { lat: 49.1345633, lng: 19.3279146 },
    { lat: 49.134322, lng: 19.3275988 },
    { lat: 49.1341259, lng: 19.3265986 },
    { lat: 49.1341322, lng: 19.325728 },
    { lat: 49.134001, lng: 19.3250046 },
    { lat: 49.1333797, lng: 19.3231139 },
    { lat: 49.1331212, lng: 19.3224168 },
    { lat: 49.1330195, lng: 19.3221678 },
    { lat: 49.1329467, lng: 19.3218734 },
    { lat: 49.1328328, lng: 19.3213915 },
    { lat: 49.1324522, lng: 19.3207553 },
    { lat: 49.1319934, lng: 19.3202266 },
    { lat: 49.1311692, lng: 19.3196989 },
    { lat: 49.1308747, lng: 19.318955 },
    { lat: 49.1300975, lng: 19.3150347 },
    { lat: 49.1302035, lng: 19.3146794 },
    { lat: 49.1303148, lng: 19.3137944 },
    { lat: 49.1305516, lng: 19.3127963 },
    { lat: 49.1304974, lng: 19.3119605 },
    { lat: 49.1304836, lng: 19.3112409 },
    { lat: 49.1306082, lng: 19.3101648 },
    { lat: 49.1304583, lng: 19.3094975 },
    { lat: 49.1304679, lng: 19.3092028 },
    { lat: 49.1300336, lng: 19.3080961 },
    { lat: 49.1300136, lng: 19.3077741 },
    { lat: 49.1301439, lng: 19.3071717 },
    { lat: 49.1301923, lng: 19.3067172 },
    { lat: 49.1301078, lng: 19.306335 },
    { lat: 49.1298272, lng: 19.3060316 },
    { lat: 49.1296944, lng: 19.3057014 },
    { lat: 49.1294202, lng: 19.3051228 },
    { lat: 49.1293865, lng: 19.3047475 },
    { lat: 49.1291748, lng: 19.3047752 },
    { lat: 49.1289715, lng: 19.3045521 },
    { lat: 49.128689, lng: 19.3044167 },
    { lat: 49.1280478, lng: 19.3052456 },
    { lat: 49.1269856, lng: 19.3050478 },
    { lat: 49.125834, lng: 19.3048587 },
    { lat: 49.1254472, lng: 19.3045005 },
    { lat: 49.1262937, lng: 19.3011045 },
    { lat: 49.1260682, lng: 19.3008235 },
    { lat: 49.1261178, lng: 19.3006291 },
    { lat: 49.1254487, lng: 19.3003045 },
    { lat: 49.1250098, lng: 19.3002227 },
    { lat: 49.1247544, lng: 19.3001166 },
    { lat: 49.1239952, lng: 19.2996955 },
    { lat: 49.123455, lng: 19.299618 },
    { lat: 49.1225467, lng: 19.2995739 },
    { lat: 49.122556, lng: 19.2992911 },
    { lat: 49.1245355, lng: 19.2990177 },
    { lat: 49.1251391, lng: 19.2988687 },
    { lat: 49.125343, lng: 19.2989358 },
    { lat: 49.1254648, lng: 19.2990321 },
    { lat: 49.1255818, lng: 19.2992186 },
    { lat: 49.1256522, lng: 19.2993688 },
    { lat: 49.1258829, lng: 19.2993879 },
    { lat: 49.1260453, lng: 19.2992977 },
    { lat: 49.1259655, lng: 19.2990388 },
    { lat: 49.12635, lng: 19.298924 },
    { lat: 49.126318, lng: 19.2986289 },
    { lat: 49.1263305, lng: 19.2981809 },
    { lat: 49.1263893, lng: 19.2969038 },
    { lat: 49.1262437, lng: 19.2958835 },
    { lat: 49.1259186, lng: 19.295299 },
    { lat: 49.1254492, lng: 19.2946253 },
    { lat: 49.1248244, lng: 19.2940459 },
    { lat: 49.1246045, lng: 19.2935952 },
    { lat: 49.125222, lng: 19.2933784 },
    { lat: 49.125571, lng: 19.2932286 },
    { lat: 49.1261884, lng: 19.2927743 },
    { lat: 49.1265464, lng: 19.2920586 },
    { lat: 49.1266762, lng: 19.291824 },
    { lat: 49.1267763, lng: 19.291628 },
    { lat: 49.1261571, lng: 19.2908158 },
    { lat: 49.1260597, lng: 19.2903266 },
    { lat: 49.1259987, lng: 19.2900186 },
    { lat: 49.1258681, lng: 19.2898879 },
    { lat: 49.125807, lng: 19.2898268 },
    { lat: 49.1257363, lng: 19.2895503 },
    { lat: 49.1254561, lng: 19.2892254 },
    { lat: 49.1251264, lng: 19.2884817 },
    { lat: 49.1238999, lng: 19.28779 },
    { lat: 49.1238314, lng: 19.2876119 },
    { lat: 49.1231883, lng: 19.2858618 },
    { lat: 49.1229111, lng: 19.2861948 },
    { lat: 49.1226123, lng: 19.2862624 },
    { lat: 49.1224135, lng: 19.2862545 },
    { lat: 49.1219292, lng: 19.2856715 },
    { lat: 49.1208444, lng: 19.2836225 },
    { lat: 49.1216159, lng: 19.2826185 },
    { lat: 49.1206509, lng: 19.2804839 },
    { lat: 49.1202815, lng: 19.2777006 },
    { lat: 49.1198341, lng: 19.2777225 },
    { lat: 49.1188256, lng: 19.273501 },
  ],
  LiptovskáLúžna: [
    { lat: 48.926153, lng: 19.384609 },
    { lat: 48.931248, lng: 19.381291 },
    { lat: 48.932892, lng: 19.380762 },
    { lat: 48.934265, lng: 19.380321 },
    { lat: 48.93488, lng: 19.380037 },
    { lat: 48.935264, lng: 19.380109 },
    { lat: 48.936086, lng: 19.380456 },
    { lat: 48.936676, lng: 19.382188 },
    { lat: 48.937607, lng: 19.382343 },
    { lat: 48.940843, lng: 19.38288 },
    { lat: 48.940948, lng: 19.382897 },
    { lat: 48.94106, lng: 19.382916 },
    { lat: 48.941169, lng: 19.382934 },
    { lat: 48.943906, lng: 19.383389 },
    { lat: 48.943963, lng: 19.383398 },
    { lat: 48.944015, lng: 19.383407 },
    { lat: 48.944611, lng: 19.383443 },
    { lat: 48.947262, lng: 19.383605 },
    { lat: 48.947099, lng: 19.382416 },
    { lat: 48.947845, lng: 19.381031 },
    { lat: 48.948062, lng: 19.380282 },
    { lat: 48.948298, lng: 19.379468 },
    { lat: 48.948266, lng: 19.378901 },
    { lat: 48.948212, lng: 19.377948 },
    { lat: 48.948055, lng: 19.37724 },
    { lat: 48.948308, lng: 19.376281 },
    { lat: 48.949275, lng: 19.3753 },
    { lat: 48.950286, lng: 19.374458 },
    { lat: 48.951138, lng: 19.374732 },
    { lat: 48.951807, lng: 19.374324 },
    { lat: 48.95325, lng: 19.373353 },
    { lat: 48.955348, lng: 19.37194 },
    { lat: 48.955817, lng: 19.371638 },
    { lat: 48.954601, lng: 19.367947 },
    { lat: 48.9539, lng: 19.364039 },
    { lat: 48.954401, lng: 19.362348 },
    { lat: 48.955266, lng: 19.361822 },
    { lat: 48.955957, lng: 19.360675 },
    { lat: 48.956522, lng: 19.359223 },
    { lat: 48.956816, lng: 19.357926 },
    { lat: 48.957575, lng: 19.356088 },
    { lat: 48.959852, lng: 19.358576 },
    { lat: 48.963281, lng: 19.35754 },
    { lat: 48.964776, lng: 19.35775 },
    { lat: 48.965762, lng: 19.358005 },
    { lat: 48.96626, lng: 19.360853 },
    { lat: 48.96828, lng: 19.361939 },
    { lat: 48.969763, lng: 19.362737 },
    { lat: 48.970566, lng: 19.363441 },
    { lat: 48.971277, lng: 19.363569 },
    { lat: 48.971458, lng: 19.363547 },
    { lat: 48.971818, lng: 19.36339 },
    { lat: 48.972137, lng: 19.363183 },
    { lat: 48.973262, lng: 19.362379 },
    { lat: 48.974237, lng: 19.362404 },
    { lat: 48.977451, lng: 19.362698 },
    { lat: 48.978619, lng: 19.361769 },
    { lat: 48.9781447, lng: 19.3601109 },
    { lat: 48.9772101, lng: 19.3592916 },
    { lat: 48.9768121, lng: 19.35881 },
    { lat: 48.9765202, lng: 19.3577368 },
    { lat: 48.9759724, lng: 19.3567266 },
    { lat: 48.9756666, lng: 19.3564137 },
    { lat: 48.973754, lng: 19.3547374 },
    { lat: 48.9731497, lng: 19.3543282 },
    { lat: 48.9728079, lng: 19.3540314 },
    { lat: 48.9724289, lng: 19.3537868 },
    { lat: 48.9723127, lng: 19.3537366 },
    { lat: 48.9722227, lng: 19.3537156 },
    { lat: 48.9720453, lng: 19.3537288 },
    { lat: 48.9719236, lng: 19.3536836 },
    { lat: 48.9717406, lng: 19.3534574 },
    { lat: 48.9714411, lng: 19.3532013 },
    { lat: 48.9711978, lng: 19.3528213 },
    { lat: 48.9711209, lng: 19.3526274 },
    { lat: 48.9707349, lng: 19.3519703 },
    { lat: 48.9705293, lng: 19.3517218 },
    { lat: 48.970457, lng: 19.3515263 },
    { lat: 48.9704483, lng: 19.3512923 },
    { lat: 48.9705022, lng: 19.3509786 },
    { lat: 48.9705078, lng: 19.3505501 },
    { lat: 48.9706695, lng: 19.3499391 },
    { lat: 48.9706788, lng: 19.3493954 },
    { lat: 48.9705781, lng: 19.3489834 },
    { lat: 48.9705423, lng: 19.3484417 },
    { lat: 48.9705781, lng: 19.3476953 },
    { lat: 48.9706703, lng: 19.3471927 },
    { lat: 48.9706706, lng: 19.3466941 },
    { lat: 48.9706481, lng: 19.3462678 },
    { lat: 48.9705702, lng: 19.3459083 },
    { lat: 48.9704876, lng: 19.3456142 },
    { lat: 48.9704457, lng: 19.3450383 },
    { lat: 48.9704387, lng: 19.3444856 },
    { lat: 48.9703995, lng: 19.3437706 },
    { lat: 48.9703279, lng: 19.3435001 },
    { lat: 48.9702469, lng: 19.342761 },
    { lat: 48.9702338, lng: 19.3425085 },
    { lat: 48.9702619, lng: 19.3422195 },
    { lat: 48.9702185, lng: 19.3417116 },
    { lat: 48.970107, lng: 19.3414508 },
    { lat: 48.9699539, lng: 19.3412605 },
    { lat: 48.969935, lng: 19.3409354 },
    { lat: 48.9699287, lng: 19.340727 },
    { lat: 48.9699326, lng: 19.3405763 },
    { lat: 48.9699209, lng: 19.3405183 },
    { lat: 48.9697869, lng: 19.3402801 },
    { lat: 48.9697636, lng: 19.3401919 },
    { lat: 48.9698151, lng: 19.3396388 },
    { lat: 48.9697872, lng: 19.3395486 },
    { lat: 48.9695136, lng: 19.3391258 },
    { lat: 48.9694312, lng: 19.3386477 },
    { lat: 48.9694008, lng: 19.3377657 },
    { lat: 48.9693172, lng: 19.3366448 },
    { lat: 48.9692626, lng: 19.3357494 },
    { lat: 48.9690268, lng: 19.3347951 },
    { lat: 48.9689615, lng: 19.3341792 },
    { lat: 48.9689401, lng: 19.3336786 },
    { lat: 48.968816, lng: 19.3332961 },
    { lat: 48.968661, lng: 19.3331398 },
    { lat: 48.9684634, lng: 19.3327044 },
    { lat: 48.9682173, lng: 19.3324103 },
    { lat: 48.9680493, lng: 19.3323379 },
    { lat: 48.9677595, lng: 19.331999 },
    { lat: 48.9676345, lng: 19.3319061 },
    { lat: 48.9675823, lng: 19.3317332 },
    { lat: 48.9675325, lng: 19.3316095 },
    { lat: 48.967369, lng: 19.3314879 },
    { lat: 48.9672004, lng: 19.3314525 },
    { lat: 48.9666685, lng: 19.330903 },
    { lat: 48.9662889, lng: 19.3305281 },
    { lat: 48.9660744, lng: 19.3303786 },
    { lat: 48.9659589, lng: 19.3302488 },
    { lat: 48.9658774, lng: 19.3301225 },
    { lat: 48.9657056, lng: 19.3301359 },
    { lat: 48.965666, lng: 19.3301069 },
    { lat: 48.9653188, lng: 19.33027 },
    { lat: 48.9650472, lng: 19.3302011 },
    { lat: 48.9650263, lng: 19.3302382 },
    { lat: 48.964862, lng: 19.3301704 },
    { lat: 48.9647897, lng: 19.3302834 },
    { lat: 48.9647292, lng: 19.3301993 },
    { lat: 48.9645729, lng: 19.3301852 },
    { lat: 48.9644747, lng: 19.3300858 },
    { lat: 48.9642883, lng: 19.3299803 },
    { lat: 48.9641539, lng: 19.3298788 },
    { lat: 48.9639943, lng: 19.3296516 },
    { lat: 48.9638599, lng: 19.3295472 },
    { lat: 48.9637606, lng: 19.3295699 },
    { lat: 48.9636223, lng: 19.3294372 },
    { lat: 48.9635504, lng: 19.3295588 },
    { lat: 48.9635048, lng: 19.3294544 },
    { lat: 48.9632819, lng: 19.3293159 },
    { lat: 48.9630759, lng: 19.3292947 },
    { lat: 48.9629887, lng: 19.3291181 },
    { lat: 48.9629614, lng: 19.3290461 },
    { lat: 48.9628152, lng: 19.3288863 },
    { lat: 48.9626719, lng: 19.3287632 },
    { lat: 48.9625097, lng: 19.3286701 },
    { lat: 48.9623354, lng: 19.3286985 },
    { lat: 48.9621811, lng: 19.3286071 },
    { lat: 48.9620925, lng: 19.328573 },
    { lat: 48.9619433, lng: 19.3284631 },
    { lat: 48.961764, lng: 19.3285576 },
    { lat: 48.9616122, lng: 19.3284698 },
    { lat: 48.9614968, lng: 19.3284165 },
    { lat: 48.9615418, lng: 19.3281992 },
    { lat: 48.9617598, lng: 19.3273038 },
    { lat: 48.9618812, lng: 19.3268254 },
    { lat: 48.9620997, lng: 19.3256577 },
    { lat: 48.9621657, lng: 19.3251017 },
    { lat: 48.9622306, lng: 19.3244985 },
    { lat: 48.9625514, lng: 19.3242973 },
    { lat: 48.9627576, lng: 19.3242722 },
    { lat: 48.9630032, lng: 19.324202 },
    { lat: 48.9634301, lng: 19.3240311 },
    { lat: 48.9635932, lng: 19.324024 },
    { lat: 48.9638387, lng: 19.3240668 },
    { lat: 48.9641742, lng: 19.3241046 },
    { lat: 48.9643418, lng: 19.3240285 },
    { lat: 48.964742, lng: 19.3240551 },
    { lat: 48.9651829, lng: 19.3241302 },
    { lat: 48.9652889, lng: 19.3241085 },
    { lat: 48.9663051, lng: 19.3237451 },
    { lat: 48.9669112, lng: 19.3233839 },
    { lat: 48.9673879, lng: 19.3230477 },
    { lat: 48.9688772, lng: 19.3201383 },
    { lat: 48.9696221, lng: 19.3197105 },
    { lat: 48.968824, lng: 19.3165971 },
    { lat: 48.9690327, lng: 19.3155824 },
    { lat: 48.9692066, lng: 19.3152792 },
    { lat: 48.96942, lng: 19.3150298 },
    { lat: 48.9695623, lng: 19.3146358 },
    { lat: 48.9696807, lng: 19.3140897 },
    { lat: 48.9697797, lng: 19.3134891 },
    { lat: 48.9697845, lng: 19.3127159 },
    { lat: 48.9699073, lng: 19.3123591 },
    { lat: 48.9703253, lng: 19.3117877 },
    { lat: 48.9704648, lng: 19.3113792 },
    { lat: 48.970707, lng: 19.3108293 },
    { lat: 48.9706293, lng: 19.3099309 },
    { lat: 48.970559, lng: 19.3096154 },
    { lat: 48.9701277, lng: 19.3084388 },
    { lat: 48.9700986, lng: 19.3083039 },
    { lat: 48.9697923, lng: 19.3080261 },
    { lat: 48.9696684, lng: 19.307671 },
    { lat: 48.9695627, lng: 19.3072248 },
    { lat: 48.9691681, lng: 19.3058469 },
    { lat: 48.9688789, lng: 19.3054787 },
    { lat: 48.9689085, lng: 19.3044678 },
    { lat: 48.9689742, lng: 19.3039329 },
    { lat: 48.9688037, lng: 19.3035922 },
    { lat: 48.968324, lng: 19.303081 },
    { lat: 48.967948, lng: 19.3027833 },
    { lat: 48.9677674, lng: 19.302636 },
    { lat: 48.967624, lng: 19.3025603 },
    { lat: 48.9672125, lng: 19.3024642 },
    { lat: 48.967003, lng: 19.3023455 },
    { lat: 48.966781, lng: 19.3021816 },
    { lat: 48.9664205, lng: 19.3021832 },
    { lat: 48.9662321, lng: 19.3022494 },
    { lat: 48.9661774, lng: 19.3022361 },
    { lat: 48.9660134, lng: 19.3020123 },
    { lat: 48.9659388, lng: 19.3017935 },
    { lat: 48.9657907, lng: 19.301408 },
    { lat: 48.9652803, lng: 19.3007983 },
    { lat: 48.964984, lng: 19.3005425 },
    { lat: 48.964739, lng: 19.3001979 },
    { lat: 48.9643475, lng: 19.2997875 },
    { lat: 48.9639913, lng: 19.2997617 },
    { lat: 48.963752, lng: 19.3000639 },
    { lat: 48.9636604, lng: 19.3001048 },
    { lat: 48.9635677, lng: 19.3000275 },
    { lat: 48.9633462, lng: 19.2994492 },
    { lat: 48.9629079, lng: 19.2990978 },
    { lat: 48.962706, lng: 19.2990239 },
    { lat: 48.9625781, lng: 19.2988924 },
    { lat: 48.9623267, lng: 19.2983002 },
    { lat: 48.9622324, lng: 19.2981646 },
    { lat: 48.9620454, lng: 19.2976454 },
    { lat: 48.9617646, lng: 19.297315 },
    { lat: 48.9615867, lng: 19.2971994 },
    { lat: 48.9613954, lng: 19.2970163 },
    { lat: 48.9613362, lng: 19.2968643 },
    { lat: 48.9613084, lng: 19.2965148 },
    { lat: 48.961288, lng: 19.2964672 },
    { lat: 48.9612225, lng: 19.2963675 },
    { lat: 48.9611469, lng: 19.2959655 },
    { lat: 48.9611613, lng: 19.2956788 },
    { lat: 48.9610402, lng: 19.2953826 },
    { lat: 48.9609524, lng: 19.2952907 },
    { lat: 48.9609006, lng: 19.2948927 },
    { lat: 48.9608472, lng: 19.2944566 },
    { lat: 48.9609011, lng: 19.2934134 },
    { lat: 48.9608531, lng: 19.2932795 },
    { lat: 48.9609244, lng: 19.2929571 },
    { lat: 48.9608965, lng: 19.2928673 },
    { lat: 48.9609097, lng: 19.2925803 },
    { lat: 48.9609057, lng: 19.2922336 },
    { lat: 48.9608761, lng: 19.2919813 },
    { lat: 48.9608546, lng: 19.2918016 },
    { lat: 48.9609043, lng: 19.2916853 },
    { lat: 48.9609149, lng: 19.291437 },
    { lat: 48.9608821, lng: 19.2912878 },
    { lat: 48.9604267, lng: 19.2916923 },
    { lat: 48.9600669, lng: 19.2920167 },
    { lat: 48.960033, lng: 19.2922023 },
    { lat: 48.9598218, lng: 19.2925236 },
    { lat: 48.9598057, lng: 19.2925419 },
    { lat: 48.9597043, lng: 19.2925398 },
    { lat: 48.9594731, lng: 19.2924676 },
    { lat: 48.9592969, lng: 19.2926248 },
    { lat: 48.9592361, lng: 19.2928409 },
    { lat: 48.9591127, lng: 19.2930166 },
    { lat: 48.9587356, lng: 19.2933088 },
    { lat: 48.9586779, lng: 19.2934228 },
    { lat: 48.9585905, lng: 19.2933865 },
    { lat: 48.9585148, lng: 19.2933094 },
    { lat: 48.9581985, lng: 19.2934117 },
    { lat: 48.958113, lng: 19.2934874 },
    { lat: 48.9580218, lng: 19.2934656 },
    { lat: 48.9578198, lng: 19.2937227 },
    { lat: 48.9576664, lng: 19.294284 },
    { lat: 48.9576612, lng: 19.2943872 },
    { lat: 48.9574747, lng: 19.2946867 },
    { lat: 48.9573561, lng: 19.2952004 },
    { lat: 48.9573611, lng: 19.2953046 },
    { lat: 48.9573273, lng: 19.2954482 },
    { lat: 48.9573361, lng: 19.2957962 },
    { lat: 48.957221, lng: 19.2960509 },
    { lat: 48.9569688, lng: 19.2964373 },
    { lat: 48.9569574, lng: 19.296601 },
    { lat: 48.9567918, lng: 19.2969312 },
    { lat: 48.9564053, lng: 19.2971907 },
    { lat: 48.9559621, lng: 19.2978965 },
    { lat: 48.9556993, lng: 19.2986254 },
    { lat: 48.9554501, lng: 19.2989071 },
    { lat: 48.9550977, lng: 19.2989154 },
    { lat: 48.955018, lng: 19.2988997 },
    { lat: 48.9546852, lng: 19.2988187 },
    { lat: 48.954302, lng: 19.2984896 },
    { lat: 48.9539913, lng: 19.2983287 },
    { lat: 48.953706, lng: 19.2982102 },
    { lat: 48.9533352, lng: 19.2980666 },
    { lat: 48.9533385, lng: 19.2980899 },
    { lat: 48.9531111, lng: 19.2980963 },
    { lat: 48.9529817, lng: 19.2982658 },
    { lat: 48.9525565, lng: 19.2984958 },
    { lat: 48.9521405, lng: 19.2984687 },
    { lat: 48.9517243, lng: 19.2984381 },
    { lat: 48.9513083, lng: 19.2983167 },
    { lat: 48.9511785, lng: 19.2981947 },
    { lat: 48.9508995, lng: 19.2981849 },
    { lat: 48.9507186, lng: 19.297981 },
    { lat: 48.9495419, lng: 19.2976026 },
    { lat: 48.9492766, lng: 19.2976909 },
    { lat: 48.9490009, lng: 19.297794 },
    { lat: 48.9486362, lng: 19.2979213 },
    { lat: 48.948388, lng: 19.2981748 },
    { lat: 48.9480131, lng: 19.2981372 },
    { lat: 48.9475845, lng: 19.2982972 },
    { lat: 48.9470742, lng: 19.2993638 },
    { lat: 48.9467475, lng: 19.2994162 },
    { lat: 48.9462635, lng: 19.2996429 },
    { lat: 48.9459433, lng: 19.2997573 },
    { lat: 48.945764, lng: 19.2998513 },
    { lat: 48.9456388, lng: 19.299751 },
    { lat: 48.9451267, lng: 19.2992246 },
    { lat: 48.9447496, lng: 19.2989484 },
    { lat: 48.9446498, lng: 19.2986983 },
    { lat: 48.9445627, lng: 19.2985268 },
    { lat: 48.9441009, lng: 19.2982535 },
    { lat: 48.9434816, lng: 19.2978608 },
    { lat: 48.9430318, lng: 19.2978586 },
    { lat: 48.9426362, lng: 19.2976443 },
    { lat: 48.9424371, lng: 19.2975121 },
    { lat: 48.9416302, lng: 19.297163 },
    { lat: 48.9413909, lng: 19.2971309 },
    { lat: 48.9409104, lng: 19.2969808 },
    { lat: 48.9408284, lng: 19.2969654 },
    { lat: 48.940835, lng: 19.2971217 },
    { lat: 48.9407429, lng: 19.2975789 },
    { lat: 48.9406158, lng: 19.2976316 },
    { lat: 48.9404218, lng: 19.2976981 },
    { lat: 48.9403385, lng: 19.2979127 },
    { lat: 48.9402611, lng: 19.2978757 },
    { lat: 48.940137, lng: 19.2978972 },
    { lat: 48.9400905, lng: 19.2978439 },
    { lat: 48.9400325, lng: 19.2978302 },
    { lat: 48.9399954, lng: 19.2978357 },
    { lat: 48.9399351, lng: 19.2979176 },
    { lat: 48.9398951, lng: 19.298069 },
    { lat: 48.9398522, lng: 19.29814 },
    { lat: 48.9397463, lng: 19.2981639 },
    { lat: 48.9396216, lng: 19.2982174 },
    { lat: 48.9395829, lng: 19.2982578 },
    { lat: 48.9395126, lng: 19.2983688 },
    { lat: 48.9394223, lng: 19.2984852 },
    { lat: 48.9393679, lng: 19.2986927 },
    { lat: 48.9393228, lng: 19.2987622 },
    { lat: 48.9391897, lng: 19.2988284 },
    { lat: 48.9390929, lng: 19.2987591 },
    { lat: 48.9390318, lng: 19.2988759 },
    { lat: 48.9388909, lng: 19.2989459 },
    { lat: 48.9388386, lng: 19.2991025 },
    { lat: 48.9388016, lng: 19.299174 },
    { lat: 48.9386736, lng: 19.2992573 },
    { lat: 48.9386352, lng: 19.2992554 },
    { lat: 48.9386224, lng: 19.2992927 },
    { lat: 48.9385953, lng: 19.2993401 },
    { lat: 48.9385334, lng: 19.2993881 },
    { lat: 48.9385035, lng: 19.2994491 },
    { lat: 48.9383918, lng: 19.2993754 },
    { lat: 48.9384109, lng: 19.2993496 },
    { lat: 48.9383983, lng: 19.2992997 },
    { lat: 48.938365, lng: 19.2992388 },
    { lat: 48.9383125, lng: 19.299198 },
    { lat: 48.9381767, lng: 19.2991197 },
    { lat: 48.9380565, lng: 19.2990049 },
    { lat: 48.937933, lng: 19.2989034 },
    { lat: 48.9378636, lng: 19.2988645 },
    { lat: 48.9378008, lng: 19.2988 },
    { lat: 48.9376712, lng: 19.2987766 },
    { lat: 48.9375809, lng: 19.2988181 },
    { lat: 48.9374392, lng: 19.2988247 },
    { lat: 48.9372262, lng: 19.2988047 },
    { lat: 48.93704, lng: 19.2987725 },
    { lat: 48.9369402, lng: 19.2987061 },
    { lat: 48.9368767, lng: 19.2986133 },
    { lat: 48.9367968, lng: 19.2984232 },
    { lat: 48.936641, lng: 19.2981822 },
    { lat: 48.9365828, lng: 19.29819 },
    { lat: 48.9364649, lng: 19.298085 },
    { lat: 48.936289, lng: 19.2979864 },
    { lat: 48.9360693, lng: 19.2979156 },
    { lat: 48.9359674, lng: 19.2978597 },
    { lat: 48.9358659, lng: 19.2977673 },
    { lat: 48.935756, lng: 19.2976351 },
    { lat: 48.9356677, lng: 19.2975783 },
    { lat: 48.935652, lng: 19.2975549 },
    { lat: 48.9356035, lng: 19.2975341 },
    { lat: 48.9355035, lng: 19.2974439 },
    { lat: 48.9351582, lng: 19.297036 },
    { lat: 48.9349781, lng: 19.2967826 },
    { lat: 48.9347915, lng: 19.296459 },
    { lat: 48.9346459, lng: 19.2962194 },
    { lat: 48.9344028, lng: 19.2958471 },
    { lat: 48.9340928, lng: 19.2954241 },
    { lat: 48.9338972, lng: 19.2951019 },
    { lat: 48.9337701, lng: 19.2949176 },
    { lat: 48.9336263, lng: 19.2947395 },
    { lat: 48.9335175, lng: 19.2945389 },
    { lat: 48.9332327, lng: 19.2940751 },
    { lat: 48.9330984, lng: 19.2938827 },
    { lat: 48.9328107, lng: 19.2934967 },
    { lat: 48.9326699, lng: 19.2932889 },
    { lat: 48.9326653, lng: 19.2930496 },
    { lat: 48.9326384, lng: 19.2928425 },
    { lat: 48.9325498, lng: 19.2924002 },
    { lat: 48.9324357, lng: 19.2919944 },
    { lat: 48.9324382, lng: 19.2919522 },
    { lat: 48.9323949, lng: 19.2916849 },
    { lat: 48.9323681, lng: 19.2914739 },
    { lat: 48.9322719, lng: 19.2911862 },
    { lat: 48.9321091, lng: 19.2908188 },
    { lat: 48.9320304, lng: 19.2905858 },
    { lat: 48.9319604, lng: 19.2902583 },
    { lat: 48.9318977, lng: 19.2899093 },
    { lat: 48.9318844, lng: 19.2895132 },
    { lat: 48.9318887, lng: 19.2891572 },
    { lat: 48.9318469, lng: 19.2882581 },
    { lat: 48.9318432, lng: 19.2878986 },
    { lat: 48.9317501, lng: 19.2875525 },
    { lat: 48.9316624, lng: 19.2874174 },
    { lat: 48.9316186, lng: 19.2873258 },
    { lat: 48.9312548, lng: 19.2870927 },
    { lat: 48.9309664, lng: 19.2868853 },
    { lat: 48.9307164, lng: 19.2866541 },
    { lat: 48.9305178, lng: 19.2864139 },
    { lat: 48.9301964, lng: 19.2860539 },
    { lat: 48.9300405, lng: 19.2859051 },
    { lat: 48.9298832, lng: 19.285845 },
    { lat: 48.9295519, lng: 19.285803 },
    { lat: 48.9291916, lng: 19.2858097 },
    { lat: 48.9288364, lng: 19.2864668 },
    { lat: 48.9286107, lng: 19.2868347 },
    { lat: 48.9284894, lng: 19.2872466 },
    { lat: 48.9282796, lng: 19.2873833 },
    { lat: 48.9280285, lng: 19.2874612 },
    { lat: 48.9277541, lng: 19.2874706 },
    { lat: 48.9275026, lng: 19.2872546 },
    { lat: 48.9273797, lng: 19.2869018 },
    { lat: 48.9273479, lng: 19.2868253 },
    { lat: 48.927294, lng: 19.2867349 },
    { lat: 48.9269646, lng: 19.286445 },
    { lat: 48.9269128, lng: 19.285955 },
    { lat: 48.9268664, lng: 19.2858536 },
    { lat: 48.9268177, lng: 19.285635 },
    { lat: 48.92666, lng: 19.285429 },
    { lat: 48.9265478, lng: 19.2854138 },
    { lat: 48.9264988, lng: 19.2853332 },
    { lat: 48.9263864, lng: 19.285295 },
    { lat: 48.9263196, lng: 19.2851224 },
    { lat: 48.9262511, lng: 19.2850569 },
    { lat: 48.9260054, lng: 19.2848886 },
    { lat: 48.9257848, lng: 19.2846365 },
    { lat: 48.9256351, lng: 19.2843856 },
    { lat: 48.9254591, lng: 19.2841226 },
    { lat: 48.9252939, lng: 19.2839419 },
    { lat: 48.9251578, lng: 19.2836648 },
    { lat: 48.9250484, lng: 19.2833331 },
    { lat: 48.9249055, lng: 19.2832008 },
    { lat: 48.9248351, lng: 19.2830211 },
    { lat: 48.9247509, lng: 19.2829612 },
    { lat: 48.9246996, lng: 19.2828063 },
    { lat: 48.9247089, lng: 19.2827397 },
    { lat: 48.9246805, lng: 19.2824267 },
    { lat: 48.9246194, lng: 19.2821668 },
    { lat: 48.9244955, lng: 19.2820005 },
    { lat: 48.9244634, lng: 19.2818485 },
    { lat: 48.9244266, lng: 19.2817647 },
    { lat: 48.9244213, lng: 19.2815567 },
    { lat: 48.9243649, lng: 19.2814393 },
    { lat: 48.9243434, lng: 19.2809642 },
    { lat: 48.9242363, lng: 19.28078 },
    { lat: 48.9242391, lng: 19.2806471 },
    { lat: 48.9242687, lng: 19.2804409 },
    { lat: 48.9242445, lng: 19.2802909 },
    { lat: 48.9241465, lng: 19.2802211 },
    { lat: 48.9240763, lng: 19.2800733 },
    { lat: 48.9239684, lng: 19.2800346 },
    { lat: 48.9238366, lng: 19.2798209 },
    { lat: 48.9238359, lng: 19.2796195 },
    { lat: 48.9237269, lng: 19.2791341 },
    { lat: 48.9237933, lng: 19.279012 },
    { lat: 48.9237871, lng: 19.2789317 },
    { lat: 48.9238371, lng: 19.2788208 },
    { lat: 48.9237396, lng: 19.2786886 },
    { lat: 48.9237807, lng: 19.2785102 },
    { lat: 48.9237544, lng: 19.2784364 },
    { lat: 48.9237807, lng: 19.2783231 },
    { lat: 48.9237627, lng: 19.2782545 },
    { lat: 48.9237011, lng: 19.2781911 },
    { lat: 48.9237022, lng: 19.2780755 },
    { lat: 48.9236403, lng: 19.2779128 },
    { lat: 48.923533, lng: 19.2779053 },
    { lat: 48.9235413, lng: 19.277798 },
    { lat: 48.9234844, lng: 19.2775282 },
    { lat: 48.9234091, lng: 19.2774398 },
    { lat: 48.9233225, lng: 19.2772787 },
    { lat: 48.923253, lng: 19.2772624 },
    { lat: 48.9232302, lng: 19.2772344 },
    { lat: 48.9231548, lng: 19.2771904 },
    { lat: 48.9231367, lng: 19.2771612 },
    { lat: 48.9230658, lng: 19.2768103 },
    { lat: 48.9229468, lng: 19.2765647 },
    { lat: 48.9228149, lng: 19.2762136 },
    { lat: 48.9227883, lng: 19.2759822 },
    { lat: 48.9227528, lng: 19.2757836 },
    { lat: 48.9226494, lng: 19.2754165 },
    { lat: 48.9224202, lng: 19.2752913 },
    { lat: 48.9222856, lng: 19.2752312 },
    { lat: 48.9218721, lng: 19.2746475 },
    { lat: 48.9218489, lng: 19.2746099 },
    { lat: 48.9218178, lng: 19.274174 },
    { lat: 48.9217854, lng: 19.2738281 },
    { lat: 48.9216598, lng: 19.2732976 },
    { lat: 48.9215586, lng: 19.2732302 },
    { lat: 48.9215452, lng: 19.2730249 },
    { lat: 48.9212958, lng: 19.2727109 },
    { lat: 48.9211118, lng: 19.2726119 },
    { lat: 48.9209638, lng: 19.2723727 },
    { lat: 48.920894, lng: 19.2721815 },
    { lat: 48.9206944, lng: 19.2718275 },
    { lat: 48.9204818, lng: 19.2714116 },
    { lat: 48.9203047, lng: 19.2711764 },
    { lat: 48.9202517, lng: 19.2708934 },
    { lat: 48.9202473, lng: 19.2708465 },
    { lat: 48.9202358, lng: 19.2708001 },
    { lat: 48.9200952, lng: 19.2707085 },
    { lat: 48.9199661, lng: 19.270578 },
    { lat: 48.9198114, lng: 19.270479 },
    { lat: 48.9196892, lng: 19.2703061 },
    { lat: 48.9194367, lng: 19.2698329 },
    { lat: 48.9190484, lng: 19.2692361 },
    { lat: 48.9189897, lng: 19.2689982 },
    { lat: 48.918745, lng: 19.2684554 },
    { lat: 48.9186822, lng: 19.2681316 },
    { lat: 48.9183814, lng: 19.2676666 },
    { lat: 48.9183217, lng: 19.2676181 },
    { lat: 48.918294, lng: 19.2666926 },
    { lat: 48.918212, lng: 19.2664651 },
    { lat: 48.9181657, lng: 19.2660857 },
    { lat: 48.9181672, lng: 19.2658569 },
    { lat: 48.9180292, lng: 19.2657309 },
    { lat: 48.9179839, lng: 19.2651862 },
    { lat: 48.9177058, lng: 19.2645801 },
    { lat: 48.9176899, lng: 19.2643668 },
    { lat: 48.9174962, lng: 19.2638797 },
    { lat: 48.9173036, lng: 19.2636215 },
    { lat: 48.9172016, lng: 19.2634489 },
    { lat: 48.9170781, lng: 19.2632669 },
    { lat: 48.9166723, lng: 19.2630552 },
    { lat: 48.9166235, lng: 19.2628864 },
    { lat: 48.9166777, lng: 19.2626511 },
    { lat: 48.9165999, lng: 19.2620211 },
    { lat: 48.9166429, lng: 19.2617662 },
    { lat: 48.9165858, lng: 19.2614912 },
    { lat: 48.9162748, lng: 19.2616796 },
    { lat: 48.915786, lng: 19.2617599 },
    { lat: 48.9155295, lng: 19.2617449 },
    { lat: 48.9150539, lng: 19.2617706 },
    { lat: 48.9146364, lng: 19.2620189 },
    { lat: 48.914319, lng: 19.2622611 },
    { lat: 48.9140931, lng: 19.2626767 },
    { lat: 48.9138788, lng: 19.2632798 },
    { lat: 48.9135624, lng: 19.2636409 },
    { lat: 48.9132103, lng: 19.2639805 },
    { lat: 48.9128444, lng: 19.2642735 },
    { lat: 48.9123552, lng: 19.2644358 },
    { lat: 48.9120052, lng: 19.2643778 },
    { lat: 48.9114549, lng: 19.264416 },
    { lat: 48.9110545, lng: 19.264365 },
    { lat: 48.9107934, lng: 19.2641863 },
    { lat: 48.9102873, lng: 19.2642315 },
    { lat: 48.9097198, lng: 19.264243 },
    { lat: 48.9094655, lng: 19.2645094 },
    { lat: 48.9091718, lng: 19.2647766 },
    { lat: 48.9089634, lng: 19.2648748 },
    { lat: 48.9080077, lng: 19.264945 },
    { lat: 48.9076003, lng: 19.2655217 },
    { lat: 48.90705, lng: 19.2657972 },
    { lat: 48.9067281, lng: 19.265995 },
    { lat: 48.9063242, lng: 19.2662249 },
    { lat: 48.9059045, lng: 19.266545 },
    { lat: 48.9053916, lng: 19.268023 },
    { lat: 48.9048909, lng: 19.2686268 },
    { lat: 48.9044991, lng: 19.2686077 },
    { lat: 48.9040555, lng: 19.2686417 },
    { lat: 48.9035531, lng: 19.2686767 },
    { lat: 48.9031032, lng: 19.2684598 },
    { lat: 48.9030933, lng: 19.2684645 },
    { lat: 48.9018254, lng: 19.2677848 },
    { lat: 48.9010852, lng: 19.2673915 },
    { lat: 48.9005627, lng: 19.2670946 },
    { lat: 48.8998013, lng: 19.2668262 },
    { lat: 48.899598, lng: 19.2667938 },
    { lat: 48.8992055, lng: 19.2667641 },
    { lat: 48.8987972, lng: 19.2668042 },
    { lat: 48.8983622, lng: 19.2669239 },
    { lat: 48.8978831, lng: 19.2671126 },
    { lat: 48.8972784, lng: 19.2674509 },
    { lat: 48.8967729, lng: 19.267559 },
    { lat: 48.8963061, lng: 19.2678112 },
    { lat: 48.8959352, lng: 19.2679026 },
    { lat: 48.8955116, lng: 19.2682105 },
    { lat: 48.8951225, lng: 19.2683019 },
    { lat: 48.894787, lng: 19.2682859 },
    { lat: 48.8944284, lng: 19.268141 },
    { lat: 48.8943977, lng: 19.2681365 },
    { lat: 48.8934342, lng: 19.2675971 },
    { lat: 48.8931018, lng: 19.2674131 },
    { lat: 48.8927342, lng: 19.2670569 },
    { lat: 48.8924986, lng: 19.2666133 },
    { lat: 48.8922251, lng: 19.2661513 },
    { lat: 48.8919728, lng: 19.2656638 },
    { lat: 48.8917161, lng: 19.2652691 },
    { lat: 48.8914783, lng: 19.2649658 },
    { lat: 48.89123, lng: 19.2648421 },
    { lat: 48.8910998, lng: 19.2648056 },
    { lat: 48.8908251, lng: 19.2647661 },
    { lat: 48.8905036, lng: 19.2648289 },
    { lat: 48.8904117, lng: 19.2648627 },
    { lat: 48.8900052, lng: 19.2651519 },
    { lat: 48.8899504, lng: 19.2652073 },
    { lat: 48.8899599, lng: 19.2652668 },
    { lat: 48.8900087, lng: 19.2653685 },
    { lat: 48.8900808, lng: 19.265645 },
    { lat: 48.8901129, lng: 19.2657965 },
    { lat: 48.890133, lng: 19.2662635 },
    { lat: 48.8900819, lng: 19.2666563 },
    { lat: 48.8902137, lng: 19.2669609 },
    { lat: 48.8902304, lng: 19.2671421 },
    { lat: 48.8901466, lng: 19.2672746 },
    { lat: 48.8901336, lng: 19.267358 },
    { lat: 48.8902987, lng: 19.2688678 },
    { lat: 48.8901618, lng: 19.2701057 },
    { lat: 48.8900687, lng: 19.2701331 },
    { lat: 48.8900551, lng: 19.2701791 },
    { lat: 48.8897026, lng: 19.2714759 },
    { lat: 48.8895198, lng: 19.2719885 },
    { lat: 48.8894171, lng: 19.2722939 },
    { lat: 48.8891428, lng: 19.2729667 },
    { lat: 48.888837, lng: 19.2739234 },
    { lat: 48.888699, lng: 19.2746156 },
    { lat: 48.888701, lng: 19.2750322 },
    { lat: 48.8889301, lng: 19.2762878 },
    { lat: 48.8891763, lng: 19.2771664 },
    { lat: 48.8892961, lng: 19.2777592 },
    { lat: 48.8893833, lng: 19.2777013 },
    { lat: 48.8898535, lng: 19.2788096 },
    { lat: 48.8898642, lng: 19.2789391 },
    { lat: 48.8901074, lng: 19.2794259 },
    { lat: 48.8901819, lng: 19.2796874 },
    { lat: 48.8902077, lng: 19.2798282 },
    { lat: 48.8903053, lng: 19.2801437 },
    { lat: 48.8903088, lng: 19.280854 },
    { lat: 48.8903522, lng: 19.2812897 },
    { lat: 48.8902817, lng: 19.2819343 },
    { lat: 48.8903046, lng: 19.2830729 },
    { lat: 48.8903743, lng: 19.2836111 },
    { lat: 48.8905959, lng: 19.2843765 },
    { lat: 48.8907012, lng: 19.2846679 },
    { lat: 48.8908703, lng: 19.2854706 },
    { lat: 48.8910473, lng: 19.2861778 },
    { lat: 48.891075, lng: 19.2867335 },
    { lat: 48.8910643, lng: 19.2870778 },
    { lat: 48.8910094, lng: 19.28746 },
    { lat: 48.8909097, lng: 19.2877055 },
    { lat: 48.8908866, lng: 19.287717 },
    { lat: 48.8908969, lng: 19.2877689 },
    { lat: 48.8908984, lng: 19.2879629 },
    { lat: 48.8909299, lng: 19.2881274 },
    { lat: 48.8908562, lng: 19.2883103 },
    { lat: 48.8908512, lng: 19.2883438 },
    { lat: 48.8908523, lng: 19.2884414 },
    { lat: 48.8908614, lng: 19.288487 },
    { lat: 48.8908542, lng: 19.288501 },
    { lat: 48.8908485, lng: 19.2885246 },
    { lat: 48.8908181, lng: 19.2885942 },
    { lat: 48.8908096, lng: 19.2886055 },
    { lat: 48.8909046, lng: 19.2887541 },
    { lat: 48.8911732, lng: 19.2888786 },
    { lat: 48.8913178, lng: 19.2892642 },
    { lat: 48.8913291, lng: 19.28962 },
    { lat: 48.8913682, lng: 19.2898002 },
    { lat: 48.8914026, lng: 19.2901388 },
    { lat: 48.8915575, lng: 19.2904803 },
    { lat: 48.8915343, lng: 19.290725 },
    { lat: 48.8915927, lng: 19.290816 },
    { lat: 48.891577, lng: 19.2910054 },
    { lat: 48.8915307, lng: 19.2912175 },
    { lat: 48.8915849, lng: 19.2913391 },
    { lat: 48.8917145, lng: 19.2914076 },
    { lat: 48.8917496, lng: 19.2915968 },
    { lat: 48.8918331, lng: 19.2917434 },
    { lat: 48.8919805, lng: 19.2920693 },
    { lat: 48.8920451, lng: 19.2923594 },
    { lat: 48.8921182, lng: 19.292403 },
    { lat: 48.8921767, lng: 19.2924499 },
    { lat: 48.8922154, lng: 19.2928724 },
    { lat: 48.8920643, lng: 19.2933495 },
    { lat: 48.891692, lng: 19.2948045 },
    { lat: 48.8908822, lng: 19.2963723 },
    { lat: 48.8900384, lng: 19.2980556 },
    { lat: 48.889857, lng: 19.3001557 },
    { lat: 48.8898919, lng: 19.3005566 },
    { lat: 48.8900492, lng: 19.3030053 },
    { lat: 48.8900071, lng: 19.3032581 },
    { lat: 48.8897046, lng: 19.3052089 },
    { lat: 48.8896569, lng: 19.3054602 },
    { lat: 48.8895869, lng: 19.3059052 },
    { lat: 48.8888934, lng: 19.3101791 },
    { lat: 48.8889191, lng: 19.311188 },
    { lat: 48.8889307, lng: 19.3125678 },
    { lat: 48.8874566, lng: 19.3147459 },
    { lat: 48.8872678, lng: 19.3151358 },
    { lat: 48.8851148, lng: 19.3197258 },
    { lat: 48.884173, lng: 19.321826 },
    { lat: 48.884563, lng: 19.323079 },
    { lat: 48.885763, lng: 19.32425 },
    { lat: 48.886522, lng: 19.32467 },
    { lat: 48.887725, lng: 19.3256 },
    { lat: 48.888646, lng: 19.325841 },
    { lat: 48.889006, lng: 19.326662 },
    { lat: 48.889853, lng: 19.327695 },
    { lat: 48.890258, lng: 19.328042 },
    { lat: 48.89062, lng: 19.328894 },
    { lat: 48.89173, lng: 19.33021 },
    { lat: 48.893473, lng: 19.331241 },
    { lat: 48.894013, lng: 19.33226 },
    { lat: 48.894869, lng: 19.334423 },
    { lat: 48.895454, lng: 19.335764 },
    { lat: 48.896231, lng: 19.336419 },
    { lat: 48.896478, lng: 19.337178 },
    { lat: 48.89719, lng: 19.339113 },
    { lat: 48.897282, lng: 19.339822 },
    { lat: 48.897315, lng: 19.342561 },
    { lat: 48.897796, lng: 19.34596 },
    { lat: 48.898136, lng: 19.347012 },
    { lat: 48.897478, lng: 19.3488 },
    { lat: 48.897577, lng: 19.350434 },
    { lat: 48.898065, lng: 19.351284 },
    { lat: 48.899953, lng: 19.353485 },
    { lat: 48.900545, lng: 19.354471 },
    { lat: 48.900682, lng: 19.354676 },
    { lat: 48.901049, lng: 19.354972 },
    { lat: 48.901322, lng: 19.355469 },
    { lat: 48.901704, lng: 19.355526 },
    { lat: 48.903666, lng: 19.356515 },
    { lat: 48.903951, lng: 19.357463 },
    { lat: 48.904849, lng: 19.359326 },
    { lat: 48.906623, lng: 19.360755 },
    { lat: 48.907143, lng: 19.36207 },
    { lat: 48.90773, lng: 19.363253 },
    { lat: 48.908234, lng: 19.363942 },
    { lat: 48.908793, lng: 19.364353 },
    { lat: 48.909069, lng: 19.36473 },
    { lat: 48.90935, lng: 19.364939 },
    { lat: 48.910419, lng: 19.365805 },
    { lat: 48.912148, lng: 19.366436 },
    { lat: 48.914131, lng: 19.368823 },
    { lat: 48.914809, lng: 19.369639 },
    { lat: 48.916746, lng: 19.371972 },
    { lat: 48.919167, lng: 19.375144 },
    { lat: 48.921595, lng: 19.37716 },
    { lat: 48.923261, lng: 19.37922 },
    { lat: 48.925557, lng: 19.383499 },
    { lat: 48.926153, lng: 19.384609 },
  ],
  LiptovskáOsada: [
    { lat: 48.876863, lng: 19.318404 },
    { lat: 48.877312, lng: 19.318256 },
    { lat: 48.877739, lng: 19.318169 },
    { lat: 48.878281, lng: 19.318067 },
    { lat: 48.879053, lng: 19.318135 },
    { lat: 48.879515, lng: 19.318373 },
    { lat: 48.87968, lng: 19.318459 },
    { lat: 48.879808, lng: 19.318625 },
    { lat: 48.880047, lng: 19.318932 },
    { lat: 48.880374, lng: 19.319141 },
    { lat: 48.880583, lng: 19.319467 },
    { lat: 48.881412, lng: 19.320531 },
    { lat: 48.882433, lng: 19.321259 },
    { lat: 48.8833, lng: 19.321372 },
    { lat: 48.884092, lng: 19.321739 },
    { lat: 48.884173, lng: 19.321826 },
    { lat: 48.8851148, lng: 19.3197258 },
    { lat: 48.8872678, lng: 19.3151358 },
    { lat: 48.8874566, lng: 19.3147459 },
    { lat: 48.8889307, lng: 19.3125678 },
    { lat: 48.8889191, lng: 19.311188 },
    { lat: 48.8888934, lng: 19.3101791 },
    { lat: 48.8895869, lng: 19.3059052 },
    { lat: 48.8896569, lng: 19.3054602 },
    { lat: 48.8897046, lng: 19.3052089 },
    { lat: 48.8900071, lng: 19.3032581 },
    { lat: 48.8900492, lng: 19.3030053 },
    { lat: 48.8898919, lng: 19.3005566 },
    { lat: 48.889857, lng: 19.3001557 },
    { lat: 48.8900384, lng: 19.2980556 },
    { lat: 48.8908822, lng: 19.2963723 },
    { lat: 48.891692, lng: 19.2948045 },
    { lat: 48.8920643, lng: 19.2933495 },
    { lat: 48.8922154, lng: 19.2928724 },
    { lat: 48.8921767, lng: 19.2924499 },
    { lat: 48.8921182, lng: 19.292403 },
    { lat: 48.8920451, lng: 19.2923594 },
    { lat: 48.8919805, lng: 19.2920693 },
    { lat: 48.8918331, lng: 19.2917434 },
    { lat: 48.8917496, lng: 19.2915968 },
    { lat: 48.8917145, lng: 19.2914076 },
    { lat: 48.8915849, lng: 19.2913391 },
    { lat: 48.8915307, lng: 19.2912175 },
    { lat: 48.891577, lng: 19.2910054 },
    { lat: 48.8915927, lng: 19.290816 },
    { lat: 48.8915343, lng: 19.290725 },
    { lat: 48.8915575, lng: 19.2904803 },
    { lat: 48.8914026, lng: 19.2901388 },
    { lat: 48.8913682, lng: 19.2898002 },
    { lat: 48.8913291, lng: 19.28962 },
    { lat: 48.8913178, lng: 19.2892642 },
    { lat: 48.8911732, lng: 19.2888786 },
    { lat: 48.8909046, lng: 19.2887541 },
    { lat: 48.8908096, lng: 19.2886055 },
    { lat: 48.8908181, lng: 19.2885942 },
    { lat: 48.8908485, lng: 19.2885246 },
    { lat: 48.8908542, lng: 19.288501 },
    { lat: 48.8908614, lng: 19.288487 },
    { lat: 48.8908523, lng: 19.2884414 },
    { lat: 48.8908512, lng: 19.2883438 },
    { lat: 48.8908562, lng: 19.2883103 },
    { lat: 48.8909299, lng: 19.2881274 },
    { lat: 48.8908984, lng: 19.2879629 },
    { lat: 48.8908969, lng: 19.2877689 },
    { lat: 48.8908866, lng: 19.287717 },
    { lat: 48.8909097, lng: 19.2877055 },
    { lat: 48.8910094, lng: 19.28746 },
    { lat: 48.8910643, lng: 19.2870778 },
    { lat: 48.891075, lng: 19.2867335 },
    { lat: 48.8910473, lng: 19.2861778 },
    { lat: 48.8908703, lng: 19.2854706 },
    { lat: 48.8907012, lng: 19.2846679 },
    { lat: 48.8905959, lng: 19.2843765 },
    { lat: 48.8903743, lng: 19.2836111 },
    { lat: 48.8903046, lng: 19.2830729 },
    { lat: 48.8902817, lng: 19.2819343 },
    { lat: 48.8903522, lng: 19.2812897 },
    { lat: 48.8903088, lng: 19.280854 },
    { lat: 48.8903053, lng: 19.2801437 },
    { lat: 48.8902077, lng: 19.2798282 },
    { lat: 48.8901819, lng: 19.2796874 },
    { lat: 48.8901074, lng: 19.2794259 },
    { lat: 48.8898642, lng: 19.2789391 },
    { lat: 48.8898535, lng: 19.2788096 },
    { lat: 48.8893833, lng: 19.2777013 },
    { lat: 48.8892961, lng: 19.2777592 },
    { lat: 48.8891763, lng: 19.2771664 },
    { lat: 48.8889301, lng: 19.2762878 },
    { lat: 48.888701, lng: 19.2750322 },
    { lat: 48.888699, lng: 19.2746156 },
    { lat: 48.888837, lng: 19.2739234 },
    { lat: 48.8891428, lng: 19.2729667 },
    { lat: 48.8894171, lng: 19.2722939 },
    { lat: 48.8895198, lng: 19.2719885 },
    { lat: 48.8897026, lng: 19.2714759 },
    { lat: 48.8900551, lng: 19.2701791 },
    { lat: 48.8900687, lng: 19.2701331 },
    { lat: 48.8901618, lng: 19.2701057 },
    { lat: 48.8902987, lng: 19.2688678 },
    { lat: 48.8901336, lng: 19.267358 },
    { lat: 48.8901466, lng: 19.2672746 },
    { lat: 48.8902304, lng: 19.2671421 },
    { lat: 48.8902137, lng: 19.2669609 },
    { lat: 48.8900819, lng: 19.2666563 },
    { lat: 48.890133, lng: 19.2662635 },
    { lat: 48.8901129, lng: 19.2657965 },
    { lat: 48.8900808, lng: 19.265645 },
    { lat: 48.8900087, lng: 19.2653685 },
    { lat: 48.8899599, lng: 19.2652668 },
    { lat: 48.8899504, lng: 19.2652073 },
    { lat: 48.8900052, lng: 19.2651519 },
    { lat: 48.8904117, lng: 19.2648627 },
    { lat: 48.8905036, lng: 19.2648289 },
    { lat: 48.8908251, lng: 19.2647661 },
    { lat: 48.8910998, lng: 19.2648056 },
    { lat: 48.89123, lng: 19.2648421 },
    { lat: 48.8914783, lng: 19.2649658 },
    { lat: 48.8917161, lng: 19.2652691 },
    { lat: 48.8919728, lng: 19.2656638 },
    { lat: 48.8922251, lng: 19.2661513 },
    { lat: 48.8924986, lng: 19.2666133 },
    { lat: 48.8927342, lng: 19.2670569 },
    { lat: 48.8931018, lng: 19.2674131 },
    { lat: 48.8934342, lng: 19.2675971 },
    { lat: 48.8943977, lng: 19.2681365 },
    { lat: 48.8944284, lng: 19.268141 },
    { lat: 48.894787, lng: 19.2682859 },
    { lat: 48.8951225, lng: 19.2683019 },
    { lat: 48.8955116, lng: 19.2682105 },
    { lat: 48.8959352, lng: 19.2679026 },
    { lat: 48.8963061, lng: 19.2678112 },
    { lat: 48.8967729, lng: 19.267559 },
    { lat: 48.8972784, lng: 19.2674509 },
    { lat: 48.8978831, lng: 19.2671126 },
    { lat: 48.8983622, lng: 19.2669239 },
    { lat: 48.8987972, lng: 19.2668042 },
    { lat: 48.8992055, lng: 19.2667641 },
    { lat: 48.899598, lng: 19.2667938 },
    { lat: 48.8998013, lng: 19.2668262 },
    { lat: 48.9005627, lng: 19.2670946 },
    { lat: 48.9010852, lng: 19.2673915 },
    { lat: 48.9018254, lng: 19.2677848 },
    { lat: 48.9030933, lng: 19.2684645 },
    { lat: 48.9031032, lng: 19.2684598 },
    { lat: 48.9035531, lng: 19.2686767 },
    { lat: 48.9040555, lng: 19.2686417 },
    { lat: 48.9044991, lng: 19.2686077 },
    { lat: 48.9048909, lng: 19.2686268 },
    { lat: 48.9053916, lng: 19.268023 },
    { lat: 48.9059045, lng: 19.266545 },
    { lat: 48.9063242, lng: 19.2662249 },
    { lat: 48.9067281, lng: 19.265995 },
    { lat: 48.90705, lng: 19.2657972 },
    { lat: 48.9076003, lng: 19.2655217 },
    { lat: 48.9080077, lng: 19.264945 },
    { lat: 48.9089634, lng: 19.2648748 },
    { lat: 48.9091718, lng: 19.2647766 },
    { lat: 48.9094655, lng: 19.2645094 },
    { lat: 48.9097198, lng: 19.264243 },
    { lat: 48.9102873, lng: 19.2642315 },
    { lat: 48.9107934, lng: 19.2641863 },
    { lat: 48.9110545, lng: 19.264365 },
    { lat: 48.9114549, lng: 19.264416 },
    { lat: 48.9120052, lng: 19.2643778 },
    { lat: 48.9123552, lng: 19.2644358 },
    { lat: 48.9128444, lng: 19.2642735 },
    { lat: 48.9132103, lng: 19.2639805 },
    { lat: 48.9135624, lng: 19.2636409 },
    { lat: 48.9138788, lng: 19.2632798 },
    { lat: 48.9140931, lng: 19.2626767 },
    { lat: 48.914319, lng: 19.2622611 },
    { lat: 48.9146364, lng: 19.2620189 },
    { lat: 48.9150539, lng: 19.2617706 },
    { lat: 48.9155295, lng: 19.2617449 },
    { lat: 48.915786, lng: 19.2617599 },
    { lat: 48.9162748, lng: 19.2616796 },
    { lat: 48.9165858, lng: 19.2614912 },
    { lat: 48.9166429, lng: 19.2617662 },
    { lat: 48.9165999, lng: 19.2620211 },
    { lat: 48.9166777, lng: 19.2626511 },
    { lat: 48.9166235, lng: 19.2628864 },
    { lat: 48.9166723, lng: 19.2630552 },
    { lat: 48.9170781, lng: 19.2632669 },
    { lat: 48.9172016, lng: 19.2634489 },
    { lat: 48.9173036, lng: 19.2636215 },
    { lat: 48.9174962, lng: 19.2638797 },
    { lat: 48.9176899, lng: 19.2643668 },
    { lat: 48.9177058, lng: 19.2645801 },
    { lat: 48.9179839, lng: 19.2651862 },
    { lat: 48.9180292, lng: 19.2657309 },
    { lat: 48.9181672, lng: 19.2658569 },
    { lat: 48.9181657, lng: 19.2660857 },
    { lat: 48.918212, lng: 19.2664651 },
    { lat: 48.918294, lng: 19.2666926 },
    { lat: 48.9183217, lng: 19.2676181 },
    { lat: 48.9183814, lng: 19.2676666 },
    { lat: 48.9186822, lng: 19.2681316 },
    { lat: 48.918745, lng: 19.2684554 },
    { lat: 48.9189897, lng: 19.2689982 },
    { lat: 48.9190484, lng: 19.2692361 },
    { lat: 48.9194367, lng: 19.2698329 },
    { lat: 48.9196892, lng: 19.2703061 },
    { lat: 48.9198114, lng: 19.270479 },
    { lat: 48.9199661, lng: 19.270578 },
    { lat: 48.9200952, lng: 19.2707085 },
    { lat: 48.9202358, lng: 19.2708001 },
    { lat: 48.9202473, lng: 19.2708465 },
    { lat: 48.9202517, lng: 19.2708934 },
    { lat: 48.9203047, lng: 19.2711764 },
    { lat: 48.9204818, lng: 19.2714116 },
    { lat: 48.9206944, lng: 19.2718275 },
    { lat: 48.920894, lng: 19.2721815 },
    { lat: 48.9209638, lng: 19.2723727 },
    { lat: 48.9211118, lng: 19.2726119 },
    { lat: 48.9212958, lng: 19.2727109 },
    { lat: 48.9215452, lng: 19.2730249 },
    { lat: 48.9215586, lng: 19.2732302 },
    { lat: 48.9216598, lng: 19.2732976 },
    { lat: 48.9217854, lng: 19.2738281 },
    { lat: 48.9218178, lng: 19.274174 },
    { lat: 48.9218489, lng: 19.2746099 },
    { lat: 48.9218721, lng: 19.2746475 },
    { lat: 48.9222856, lng: 19.2752312 },
    { lat: 48.9224202, lng: 19.2752913 },
    { lat: 48.9226494, lng: 19.2754165 },
    { lat: 48.9227528, lng: 19.2757836 },
    { lat: 48.9227883, lng: 19.2759822 },
    { lat: 48.9228149, lng: 19.2762136 },
    { lat: 48.9229468, lng: 19.2765647 },
    { lat: 48.9230658, lng: 19.2768103 },
    { lat: 48.9231367, lng: 19.2771612 },
    { lat: 48.9231548, lng: 19.2771904 },
    { lat: 48.9232302, lng: 19.2772344 },
    { lat: 48.923253, lng: 19.2772624 },
    { lat: 48.9233225, lng: 19.2772787 },
    { lat: 48.9234091, lng: 19.2774398 },
    { lat: 48.9234844, lng: 19.2775282 },
    { lat: 48.9235413, lng: 19.277798 },
    { lat: 48.923533, lng: 19.2779053 },
    { lat: 48.9236403, lng: 19.2779128 },
    { lat: 48.9237022, lng: 19.2780755 },
    { lat: 48.9237011, lng: 19.2781911 },
    { lat: 48.9237627, lng: 19.2782545 },
    { lat: 48.9237807, lng: 19.2783231 },
    { lat: 48.9237544, lng: 19.2784364 },
    { lat: 48.9237807, lng: 19.2785102 },
    { lat: 48.9237396, lng: 19.2786886 },
    { lat: 48.9238371, lng: 19.2788208 },
    { lat: 48.9237871, lng: 19.2789317 },
    { lat: 48.9237933, lng: 19.279012 },
    { lat: 48.9237269, lng: 19.2791341 },
    { lat: 48.9238359, lng: 19.2796195 },
    { lat: 48.9238366, lng: 19.2798209 },
    { lat: 48.9239684, lng: 19.2800346 },
    { lat: 48.9240763, lng: 19.2800733 },
    { lat: 48.9241465, lng: 19.2802211 },
    { lat: 48.9242445, lng: 19.2802909 },
    { lat: 48.9242687, lng: 19.2804409 },
    { lat: 48.9242391, lng: 19.2806471 },
    { lat: 48.9242363, lng: 19.28078 },
    { lat: 48.9243434, lng: 19.2809642 },
    { lat: 48.9243649, lng: 19.2814393 },
    { lat: 48.9244213, lng: 19.2815567 },
    { lat: 48.9244266, lng: 19.2817647 },
    { lat: 48.9244634, lng: 19.2818485 },
    { lat: 48.9244955, lng: 19.2820005 },
    { lat: 48.9246194, lng: 19.2821668 },
    { lat: 48.9246805, lng: 19.2824267 },
    { lat: 48.9247089, lng: 19.2827397 },
    { lat: 48.9246996, lng: 19.2828063 },
    { lat: 48.9247509, lng: 19.2829612 },
    { lat: 48.9248351, lng: 19.2830211 },
    { lat: 48.9249055, lng: 19.2832008 },
    { lat: 48.9250484, lng: 19.2833331 },
    { lat: 48.9251578, lng: 19.2836648 },
    { lat: 48.9252939, lng: 19.2839419 },
    { lat: 48.9254591, lng: 19.2841226 },
    { lat: 48.9256351, lng: 19.2843856 },
    { lat: 48.9257848, lng: 19.2846365 },
    { lat: 48.9260054, lng: 19.2848886 },
    { lat: 48.9262511, lng: 19.2850569 },
    { lat: 48.9263196, lng: 19.2851224 },
    { lat: 48.9263864, lng: 19.285295 },
    { lat: 48.9264988, lng: 19.2853332 },
    { lat: 48.9265478, lng: 19.2854138 },
    { lat: 48.92666, lng: 19.285429 },
    { lat: 48.9268177, lng: 19.285635 },
    { lat: 48.9268664, lng: 19.2858536 },
    { lat: 48.9269128, lng: 19.285955 },
    { lat: 48.9269646, lng: 19.286445 },
    { lat: 48.927294, lng: 19.2867349 },
    { lat: 48.9273479, lng: 19.2868253 },
    { lat: 48.9273797, lng: 19.2869018 },
    { lat: 48.9275026, lng: 19.2872546 },
    { lat: 48.9277541, lng: 19.2874706 },
    { lat: 48.9280285, lng: 19.2874612 },
    { lat: 48.9282796, lng: 19.2873833 },
    { lat: 48.9284894, lng: 19.2872466 },
    { lat: 48.9286107, lng: 19.2868347 },
    { lat: 48.9288364, lng: 19.2864668 },
    { lat: 48.9291916, lng: 19.2858097 },
    { lat: 48.9295519, lng: 19.285803 },
    { lat: 48.9298832, lng: 19.285845 },
    { lat: 48.9300405, lng: 19.2859051 },
    { lat: 48.9301964, lng: 19.2860539 },
    { lat: 48.9305178, lng: 19.2864139 },
    { lat: 48.9307164, lng: 19.2866541 },
    { lat: 48.9309664, lng: 19.2868853 },
    { lat: 48.9312548, lng: 19.2870927 },
    { lat: 48.9316186, lng: 19.2873258 },
    { lat: 48.9316624, lng: 19.2874174 },
    { lat: 48.9317501, lng: 19.2875525 },
    { lat: 48.9318432, lng: 19.2878986 },
    { lat: 48.9318469, lng: 19.2882581 },
    { lat: 48.9318887, lng: 19.2891572 },
    { lat: 48.9318844, lng: 19.2895132 },
    { lat: 48.9318977, lng: 19.2899093 },
    { lat: 48.9319604, lng: 19.2902583 },
    { lat: 48.9320304, lng: 19.2905858 },
    { lat: 48.9321091, lng: 19.2908188 },
    { lat: 48.9322719, lng: 19.2911862 },
    { lat: 48.9323681, lng: 19.2914739 },
    { lat: 48.9323949, lng: 19.2916849 },
    { lat: 48.9324382, lng: 19.2919522 },
    { lat: 48.9324357, lng: 19.2919944 },
    { lat: 48.9325498, lng: 19.2924002 },
    { lat: 48.9326384, lng: 19.2928425 },
    { lat: 48.9326653, lng: 19.2930496 },
    { lat: 48.9326699, lng: 19.2932889 },
    { lat: 48.9328107, lng: 19.2934967 },
    { lat: 48.9330984, lng: 19.2938827 },
    { lat: 48.9332327, lng: 19.2940751 },
    { lat: 48.9335175, lng: 19.2945389 },
    { lat: 48.9336263, lng: 19.2947395 },
    { lat: 48.9337701, lng: 19.2949176 },
    { lat: 48.9338972, lng: 19.2951019 },
    { lat: 48.9340928, lng: 19.2954241 },
    { lat: 48.9344028, lng: 19.2958471 },
    { lat: 48.9346459, lng: 19.2962194 },
    { lat: 48.9347915, lng: 19.296459 },
    { lat: 48.9349781, lng: 19.2967826 },
    { lat: 48.9351582, lng: 19.297036 },
    { lat: 48.9355035, lng: 19.2974439 },
    { lat: 48.9356035, lng: 19.2975341 },
    { lat: 48.935652, lng: 19.2975549 },
    { lat: 48.9356677, lng: 19.2975783 },
    { lat: 48.935756, lng: 19.2976351 },
    { lat: 48.9358659, lng: 19.2977673 },
    { lat: 48.9359674, lng: 19.2978597 },
    { lat: 48.9360693, lng: 19.2979156 },
    { lat: 48.936289, lng: 19.2979864 },
    { lat: 48.9364649, lng: 19.298085 },
    { lat: 48.9365828, lng: 19.29819 },
    { lat: 48.936641, lng: 19.2981822 },
    { lat: 48.9367968, lng: 19.2984232 },
    { lat: 48.9368767, lng: 19.2986133 },
    { lat: 48.9369402, lng: 19.2987061 },
    { lat: 48.93704, lng: 19.2987725 },
    { lat: 48.9372262, lng: 19.2988047 },
    { lat: 48.9374392, lng: 19.2988247 },
    { lat: 48.9375809, lng: 19.2988181 },
    { lat: 48.9376712, lng: 19.2987766 },
    { lat: 48.9378008, lng: 19.2988 },
    { lat: 48.9378636, lng: 19.2988645 },
    { lat: 48.937933, lng: 19.2989034 },
    { lat: 48.9380565, lng: 19.2990049 },
    { lat: 48.9381767, lng: 19.2991197 },
    { lat: 48.9383125, lng: 19.299198 },
    { lat: 48.938365, lng: 19.2992388 },
    { lat: 48.9383983, lng: 19.2992997 },
    { lat: 48.9384109, lng: 19.2993496 },
    { lat: 48.9383918, lng: 19.2993754 },
    { lat: 48.9385035, lng: 19.2994491 },
    { lat: 48.9385334, lng: 19.2993881 },
    { lat: 48.9385953, lng: 19.2993401 },
    { lat: 48.9386224, lng: 19.2992927 },
    { lat: 48.9386352, lng: 19.2992554 },
    { lat: 48.9386736, lng: 19.2992573 },
    { lat: 48.9388016, lng: 19.299174 },
    { lat: 48.9388386, lng: 19.2991025 },
    { lat: 48.9388909, lng: 19.2989459 },
    { lat: 48.9390318, lng: 19.2988759 },
    { lat: 48.9390929, lng: 19.2987591 },
    { lat: 48.9391897, lng: 19.2988284 },
    { lat: 48.9393228, lng: 19.2987622 },
    { lat: 48.9393679, lng: 19.2986927 },
    { lat: 48.9394223, lng: 19.2984852 },
    { lat: 48.9395126, lng: 19.2983688 },
    { lat: 48.9395829, lng: 19.2982578 },
    { lat: 48.9396216, lng: 19.2982174 },
    { lat: 48.9397463, lng: 19.2981639 },
    { lat: 48.9398522, lng: 19.29814 },
    { lat: 48.9398951, lng: 19.298069 },
    { lat: 48.9399351, lng: 19.2979176 },
    { lat: 48.9399954, lng: 19.2978357 },
    { lat: 48.9400325, lng: 19.2978302 },
    { lat: 48.9400905, lng: 19.2978439 },
    { lat: 48.940137, lng: 19.2978972 },
    { lat: 48.9402611, lng: 19.2978757 },
    { lat: 48.9403385, lng: 19.2979127 },
    { lat: 48.9404218, lng: 19.2976981 },
    { lat: 48.9406158, lng: 19.2976316 },
    { lat: 48.9407429, lng: 19.2975789 },
    { lat: 48.940835, lng: 19.2971217 },
    { lat: 48.9408284, lng: 19.2969654 },
    { lat: 48.9409104, lng: 19.2969808 },
    { lat: 48.9413909, lng: 19.2971309 },
    { lat: 48.9416302, lng: 19.297163 },
    { lat: 48.9424371, lng: 19.2975121 },
    { lat: 48.9426362, lng: 19.2976443 },
    { lat: 48.9430318, lng: 19.2978586 },
    { lat: 48.9434816, lng: 19.2978608 },
    { lat: 48.9441009, lng: 19.2982535 },
    { lat: 48.9445627, lng: 19.2985268 },
    { lat: 48.9446498, lng: 19.2986983 },
    { lat: 48.9447496, lng: 19.2989484 },
    { lat: 48.9451267, lng: 19.2992246 },
    { lat: 48.9456388, lng: 19.299751 },
    { lat: 48.945764, lng: 19.2998513 },
    { lat: 48.9459433, lng: 19.2997573 },
    { lat: 48.9462635, lng: 19.2996429 },
    { lat: 48.9467475, lng: 19.2994162 },
    { lat: 48.9470742, lng: 19.2993638 },
    { lat: 48.9475845, lng: 19.2982972 },
    { lat: 48.9480131, lng: 19.2981372 },
    { lat: 48.948388, lng: 19.2981748 },
    { lat: 48.9486362, lng: 19.2979213 },
    { lat: 48.9490009, lng: 19.297794 },
    { lat: 48.9492766, lng: 19.2976909 },
    { lat: 48.9495419, lng: 19.2976026 },
    { lat: 48.9507186, lng: 19.297981 },
    { lat: 48.9508995, lng: 19.2981849 },
    { lat: 48.9511785, lng: 19.2981947 },
    { lat: 48.9513083, lng: 19.2983167 },
    { lat: 48.9517243, lng: 19.2984381 },
    { lat: 48.9521405, lng: 19.2984687 },
    { lat: 48.9525565, lng: 19.2984958 },
    { lat: 48.9529817, lng: 19.2982658 },
    { lat: 48.9531111, lng: 19.2980963 },
    { lat: 48.9533385, lng: 19.2980899 },
    { lat: 48.9533352, lng: 19.2980666 },
    { lat: 48.953706, lng: 19.2982102 },
    { lat: 48.9539913, lng: 19.2983287 },
    { lat: 48.954302, lng: 19.2984896 },
    { lat: 48.9546852, lng: 19.2988187 },
    { lat: 48.955018, lng: 19.2988997 },
    { lat: 48.9550977, lng: 19.2989154 },
    { lat: 48.9554501, lng: 19.2989071 },
    { lat: 48.9556993, lng: 19.2986254 },
    { lat: 48.9559621, lng: 19.2978965 },
    { lat: 48.9564053, lng: 19.2971907 },
    { lat: 48.9567918, lng: 19.2969312 },
    { lat: 48.9569574, lng: 19.296601 },
    { lat: 48.9569688, lng: 19.2964373 },
    { lat: 48.957221, lng: 19.2960509 },
    { lat: 48.9573361, lng: 19.2957962 },
    { lat: 48.9573273, lng: 19.2954482 },
    { lat: 48.9573611, lng: 19.2953046 },
    { lat: 48.9573561, lng: 19.2952004 },
    { lat: 48.9574747, lng: 19.2946867 },
    { lat: 48.9576612, lng: 19.2943872 },
    { lat: 48.9576664, lng: 19.294284 },
    { lat: 48.9578198, lng: 19.2937227 },
    { lat: 48.9580218, lng: 19.2934656 },
    { lat: 48.958113, lng: 19.2934874 },
    { lat: 48.9581985, lng: 19.2934117 },
    { lat: 48.9585148, lng: 19.2933094 },
    { lat: 48.9585905, lng: 19.2933865 },
    { lat: 48.9586779, lng: 19.2934228 },
    { lat: 48.9587356, lng: 19.2933088 },
    { lat: 48.9591127, lng: 19.2930166 },
    { lat: 48.9592361, lng: 19.2928409 },
    { lat: 48.9592969, lng: 19.2926248 },
    { lat: 48.9594731, lng: 19.2924676 },
    { lat: 48.9597043, lng: 19.2925398 },
    { lat: 48.9598057, lng: 19.2925419 },
    { lat: 48.9598218, lng: 19.2925236 },
    { lat: 48.960033, lng: 19.2922023 },
    { lat: 48.9600669, lng: 19.2920167 },
    { lat: 48.9604267, lng: 19.2916923 },
    { lat: 48.9608821, lng: 19.2912878 },
    { lat: 48.9609231, lng: 19.2912518 },
    { lat: 48.9611647, lng: 19.2907431 },
    { lat: 48.9616401, lng: 19.2902173 },
    { lat: 48.9617252, lng: 19.2901362 },
    { lat: 48.961835, lng: 19.2898131 },
    { lat: 48.9619605, lng: 19.2890938 },
    { lat: 48.9622346, lng: 19.2879646 },
    { lat: 48.9623114, lng: 19.2878015 },
    { lat: 48.9624165, lng: 19.2876249 },
    { lat: 48.9625052, lng: 19.2874739 },
    { lat: 48.9626027, lng: 19.2872253 },
    { lat: 48.9628966, lng: 19.2866287 },
    { lat: 48.9631165, lng: 19.2858982 },
    { lat: 48.963378, lng: 19.2853124 },
    { lat: 48.9636876, lng: 19.2845745 },
    { lat: 48.9637798, lng: 19.2841228 },
    { lat: 48.9640842, lng: 19.2837904 },
    { lat: 48.9643141, lng: 19.2831546 },
    { lat: 48.964544, lng: 19.2826342 },
    { lat: 48.9646168, lng: 19.2823679 },
    { lat: 48.9646813, lng: 19.2817804 },
    { lat: 48.9648321, lng: 19.2811167 },
    { lat: 48.9651003, lng: 19.2804092 },
    { lat: 48.9656532, lng: 19.2790123 },
    { lat: 48.9659796, lng: 19.2783654 },
    { lat: 48.9662355, lng: 19.2780825 },
    { lat: 48.9663973, lng: 19.2778598 },
    { lat: 48.9664649, lng: 19.2777747 },
    { lat: 48.9665279, lng: 19.2776948 },
    { lat: 48.9665656, lng: 19.2775895 },
    { lat: 48.966691, lng: 19.2773596 },
    { lat: 48.9667256, lng: 19.2772539 },
    { lat: 48.9670455, lng: 19.2764961 },
    { lat: 48.9672864, lng: 19.2762095 },
    { lat: 48.96733, lng: 19.2761092 },
    { lat: 48.9676434, lng: 19.2756391 },
    { lat: 48.9677285, lng: 19.2753216 },
    { lat: 48.9677503, lng: 19.2750709 },
    { lat: 48.9677105, lng: 19.2749455 },
    { lat: 48.9682112, lng: 19.2743388 },
    { lat: 48.968433, lng: 19.2740277 },
    { lat: 48.9687708, lng: 19.2736898 },
    { lat: 48.9688464, lng: 19.2736007 },
    { lat: 48.9687156, lng: 19.2734099 },
    { lat: 48.9686689, lng: 19.2733516 },
    { lat: 48.9686336, lng: 19.2732759 },
    { lat: 48.9680847, lng: 19.2720028 },
    { lat: 48.9679768, lng: 19.2718213 },
    { lat: 48.9679478, lng: 19.2717582 },
    { lat: 48.96789, lng: 19.2717255 },
    { lat: 48.9677722, lng: 19.2716918 },
    { lat: 48.9675655, lng: 19.2717312 },
    { lat: 48.9674484, lng: 19.2717804 },
    { lat: 48.9671799, lng: 19.2720565 },
    { lat: 48.9670692, lng: 19.2721419 },
    { lat: 48.9669809, lng: 19.2721828 },
    { lat: 48.9668252, lng: 19.2722065 },
    { lat: 48.9666197, lng: 19.2721707 },
    { lat: 48.9662477, lng: 19.2719844 },
    { lat: 48.9658469, lng: 19.2717116 },
    { lat: 48.9655707, lng: 19.2714236 },
    { lat: 48.9654875, lng: 19.2712649 },
    { lat: 48.9652504, lng: 19.2707882 },
    { lat: 48.9650742, lng: 19.2704282 },
    { lat: 48.9649337, lng: 19.270245 },
    { lat: 48.9644245, lng: 19.2701327 },
    { lat: 48.9636427, lng: 19.2698862 },
    { lat: 48.9635695, lng: 19.2698121 },
    { lat: 48.9634486, lng: 19.2695511 },
    { lat: 48.9633976, lng: 19.2692364 },
    { lat: 48.9634285, lng: 19.2689636 },
    { lat: 48.9634828, lng: 19.2685706 },
    { lat: 48.9634974, lng: 19.2682146 },
    { lat: 48.9634675, lng: 19.2680138 },
    { lat: 48.9633219, lng: 19.2676375 },
    { lat: 48.9630982, lng: 19.2673659 },
    { lat: 48.9628457, lng: 19.2671783 },
    { lat: 48.9624137, lng: 19.267169 },
    { lat: 48.9620926, lng: 19.2671755 },
    { lat: 48.9618711, lng: 19.2670934 },
    { lat: 48.9614345, lng: 19.2668684 },
    { lat: 48.960528, lng: 19.2663707 },
    { lat: 48.9597661, lng: 19.2656415 },
    { lat: 48.9597997, lng: 19.2655205 },
    { lat: 48.9598351, lng: 19.265461 },
    { lat: 48.9599596, lng: 19.2650721 },
    { lat: 48.9600968, lng: 19.2648295 },
    { lat: 48.9611199, lng: 19.2636729 },
    { lat: 48.9611363, lng: 19.2636593 },
    { lat: 48.9612474, lng: 19.2634648 },
    { lat: 48.9617028, lng: 19.2627382 },
    { lat: 48.9620718, lng: 19.2621332 },
    { lat: 48.9621154, lng: 19.261586 },
    { lat: 48.9621034, lng: 19.2615726 },
    { lat: 48.9615759, lng: 19.260867 },
    { lat: 48.9614201, lng: 19.2606511 },
    { lat: 48.9596366, lng: 19.2582952 },
    { lat: 48.9596052, lng: 19.2582506 },
    { lat: 48.9586503, lng: 19.257002 },
    { lat: 48.957795, lng: 19.2558591 },
    { lat: 48.9570192, lng: 19.2548446 },
    { lat: 48.9569131, lng: 19.2547194 },
    { lat: 48.9570074, lng: 19.251244 },
    { lat: 48.9571185, lng: 19.2510254 },
    { lat: 48.9576577, lng: 19.2503333 },
    { lat: 48.958609, lng: 19.2499523 },
    { lat: 48.9592546, lng: 19.2496694 },
    { lat: 48.9597327, lng: 19.2483607 },
    { lat: 48.9598803, lng: 19.2481743 },
    { lat: 48.9602048, lng: 19.2477025 },
    { lat: 48.9608092, lng: 19.2469126 },
    { lat: 48.9609552, lng: 19.246575 },
    { lat: 48.9610122, lng: 19.2459525 },
    { lat: 48.9610217, lng: 19.2454245 },
    { lat: 48.9609949, lng: 19.2447994 },
    { lat: 48.9608263, lng: 19.2444559 },
    { lat: 48.9612691, lng: 19.2438482 },
    { lat: 48.9627178, lng: 19.2417482 },
    { lat: 48.9631458, lng: 19.2412279 },
    { lat: 48.9648405, lng: 19.240302 },
    { lat: 48.9648887, lng: 19.2402737 },
    { lat: 48.9656029, lng: 19.2398909 },
    { lat: 48.9656357, lng: 19.2398711 },
    { lat: 48.9718214, lng: 19.2366448 },
    { lat: 48.9785944, lng: 19.2330955 },
    { lat: 48.9796408, lng: 19.2328707 },
    { lat: 48.9825118, lng: 19.2312376 },
    { lat: 48.9832727, lng: 19.2304427 },
    { lat: 48.9851962, lng: 19.2251824 },
    { lat: 48.9861596, lng: 19.2234712 },
    { lat: 48.986944, lng: 19.222278 },
    { lat: 48.9878662, lng: 19.2185765 },
    { lat: 48.9879287, lng: 19.2182817 },
    { lat: 48.9880213, lng: 19.217868 },
    { lat: 48.9885896, lng: 19.2156601 },
    { lat: 48.9893739, lng: 19.2142823 },
    { lat: 48.989874, lng: 19.2127362 },
    { lat: 48.9898453, lng: 19.2096306 },
    { lat: 48.989657, lng: 19.2075097 },
    { lat: 48.9892455, lng: 19.2062062 },
    { lat: 48.989032, lng: 19.2055198 },
    { lat: 48.9864067, lng: 19.2030297 },
    { lat: 48.9858344, lng: 19.2018757 },
    { lat: 48.9858236, lng: 19.2010501 },
    { lat: 48.985093, lng: 19.1971397 },
    { lat: 48.9856199, lng: 19.1955034 },
    { lat: 48.9844413, lng: 19.193263 },
    { lat: 48.9843477, lng: 19.19307 },
    { lat: 48.983552, lng: 19.1915447 },
    { lat: 48.9827704, lng: 19.1909085 },
    { lat: 48.9822832, lng: 19.1906965 },
    { lat: 48.9817512, lng: 19.1906026 },
    { lat: 48.9814945, lng: 19.19056 },
    { lat: 48.9810453, lng: 19.1899921 },
    { lat: 48.9808349, lng: 19.1897702 },
    { lat: 48.9800726, lng: 19.1890673 },
    { lat: 48.976891, lng: 19.1858282 },
    { lat: 48.9765124, lng: 19.185833 },
    { lat: 48.9762865, lng: 19.1857988 },
    { lat: 48.9752796, lng: 19.1858185 },
    { lat: 48.9746983, lng: 19.186032 },
    { lat: 48.9739763, lng: 19.1867599 },
    { lat: 48.9732469, lng: 19.1875902 },
    { lat: 48.972676, lng: 19.1882323 },
    { lat: 48.9724303, lng: 19.1881824 },
    { lat: 48.9700801, lng: 19.1873945 },
    { lat: 48.9691802, lng: 19.18778 },
    { lat: 48.9687112, lng: 19.1891708 },
    { lat: 48.9685571, lng: 19.1895709 },
    { lat: 48.9675598, lng: 19.1921007 },
    { lat: 48.9670127, lng: 19.1954837 },
    { lat: 48.9671017, lng: 19.1972237 },
    { lat: 48.9671598, lng: 19.1984886 },
    { lat: 48.9664349, lng: 19.2005511 },
    { lat: 48.9662389, lng: 19.2018075 },
    { lat: 48.966093, lng: 19.2027063 },
    { lat: 48.965929, lng: 19.2036689 },
    { lat: 48.9653694, lng: 19.2057035 },
    { lat: 48.9651416, lng: 19.2066566 },
    { lat: 48.964763, lng: 19.2083559 },
    { lat: 48.9647859, lng: 19.2099651 },
    { lat: 48.9647848, lng: 19.2104097 },
    { lat: 48.9644112, lng: 19.2115194 },
    { lat: 48.9638886, lng: 19.2130611 },
    { lat: 48.9607706, lng: 19.2152906 },
    { lat: 48.9594052, lng: 19.2167716 },
    { lat: 48.9583968, lng: 19.2190868 },
    { lat: 48.9582284, lng: 19.2201027 },
    { lat: 48.957713, lng: 19.2209127 },
    { lat: 48.9567331, lng: 19.2224875 },
    { lat: 48.956554, lng: 19.2232822 },
    { lat: 48.9563818, lng: 19.2240338 },
    { lat: 48.9559994, lng: 19.2247976 },
    { lat: 48.9546611, lng: 19.2256999 },
    { lat: 48.954273, lng: 19.2260663 },
    { lat: 48.9534385, lng: 19.2281766 },
    { lat: 48.95309, lng: 19.2283854 },
    { lat: 48.9530155, lng: 19.2284077 },
    { lat: 48.9525844, lng: 19.2286752 },
    { lat: 48.9512795, lng: 19.2282857 },
    { lat: 48.9511816, lng: 19.2283368 },
    { lat: 48.9507042, lng: 19.2285076 },
    { lat: 48.950617, lng: 19.2288282 },
    { lat: 48.9498856, lng: 19.2299026 },
    { lat: 48.9495633, lng: 19.2305837 },
    { lat: 48.9489066, lng: 19.2313155 },
    { lat: 48.9483579, lng: 19.2322749 },
    { lat: 48.9483361, lng: 19.2332025 },
    { lat: 48.9479713, lng: 19.2339337 },
    { lat: 48.9473099, lng: 19.2347539 },
    { lat: 48.9462375, lng: 19.2356344 },
    { lat: 48.9455796, lng: 19.2361996 },
    { lat: 48.9450181, lng: 19.236315 },
    { lat: 48.9448409, lng: 19.2373129 },
    { lat: 48.9448027, lng: 19.2378529 },
    { lat: 48.9448404, lng: 19.2381209 },
    { lat: 48.9444236, lng: 19.2388528 },
    { lat: 48.9443296, lng: 19.2390045 },
    { lat: 48.9441438, lng: 19.2390108 },
    { lat: 48.9433768, lng: 19.2389508 },
    { lat: 48.9430581, lng: 19.2391443 },
    { lat: 48.9427129, lng: 19.2396761 },
    { lat: 48.9424714, lng: 19.2403931 },
    { lat: 48.9422256, lng: 19.2407428 },
    { lat: 48.9420749, lng: 19.2410758 },
    { lat: 48.9418773, lng: 19.241983 },
    { lat: 48.941552, lng: 19.242297 },
    { lat: 48.9400375, lng: 19.2437075 },
    { lat: 48.939975, lng: 19.2436743 },
    { lat: 48.9374397, lng: 19.2423983 },
    { lat: 48.9361588, lng: 19.2410104 },
    { lat: 48.9354486, lng: 19.2415008 },
    { lat: 48.9348285, lng: 19.2419396 },
    { lat: 48.9347923, lng: 19.2418906 },
    { lat: 48.9338588, lng: 19.2404627 },
    { lat: 48.9335459, lng: 19.2400723 },
    { lat: 48.9330481, lng: 19.2401269 },
    { lat: 48.9328856, lng: 19.240873 },
    { lat: 48.9320683, lng: 19.2414972 },
    { lat: 48.9312298, lng: 19.2420606 },
    { lat: 48.9304734, lng: 19.2428078 },
    { lat: 48.9295184, lng: 19.243012 },
    { lat: 48.9287411, lng: 19.2424577 },
    { lat: 48.9281353, lng: 19.2419342 },
    { lat: 48.927027, lng: 19.2409235 },
    { lat: 48.9264616, lng: 19.240254 },
    { lat: 48.9263708, lng: 19.2398881 },
    { lat: 48.9263297, lng: 19.2397414 },
    { lat: 48.9263085, lng: 19.239439 },
    { lat: 48.9262219, lng: 19.2390934 },
    { lat: 48.9254357, lng: 19.2378667 },
    { lat: 48.9250869, lng: 19.237647 },
    { lat: 48.9248019, lng: 19.2374633 },
    { lat: 48.9236767, lng: 19.2367772 },
    { lat: 48.9223168, lng: 19.2354129 },
    { lat: 48.9217714, lng: 19.2338467 },
    { lat: 48.9208305, lng: 19.2333409 },
    { lat: 48.9204355, lng: 19.2326537 },
    { lat: 48.9199177, lng: 19.2323894 },
    { lat: 48.9191946, lng: 19.2319125 },
    { lat: 48.9184704, lng: 19.2308949 },
    { lat: 48.9179434, lng: 19.2308113 },
    { lat: 48.9179084, lng: 19.2308087 },
    { lat: 48.917462, lng: 19.2307401 },
    { lat: 48.9159831, lng: 19.2281974 },
    { lat: 48.9142663, lng: 19.2291041 },
    { lat: 48.9134667, lng: 19.2291597 },
    { lat: 48.9119978, lng: 19.2289941 },
    { lat: 48.911208, lng: 19.2294009 },
    { lat: 48.9101652, lng: 19.2300949 },
    { lat: 48.9081182, lng: 19.2326136 },
    { lat: 48.907061, lng: 19.2327996 },
    { lat: 48.9052504, lng: 19.2323455 },
    { lat: 48.9042526, lng: 19.2320841 },
    { lat: 48.9033589, lng: 19.2317378 },
    { lat: 48.9031395, lng: 19.2313283 },
    { lat: 48.9018048, lng: 19.2297351 },
    { lat: 48.9003271, lng: 19.2284549 },
    { lat: 48.8993582, lng: 19.2274138 },
    { lat: 48.8989355, lng: 19.2265071 },
    { lat: 48.8978703, lng: 19.2256426 },
    { lat: 48.8966583, lng: 19.2256123 },
    { lat: 48.8957036, lng: 19.2250068 },
    { lat: 48.8942007, lng: 19.2241148 },
    { lat: 48.890819, lng: 19.222733 },
    { lat: 48.8905939, lng: 19.2232653 },
    { lat: 48.890334, lng: 19.224154 },
    { lat: 48.890195, lng: 19.225311 },
    { lat: 48.889447, lng: 19.228221 },
    { lat: 48.889148, lng: 19.228784 },
    { lat: 48.889001, lng: 19.229328 },
    { lat: 48.888936, lng: 19.229578 },
    { lat: 48.888698, lng: 19.230497 },
    { lat: 48.888657, lng: 19.230929 },
    { lat: 48.888801, lng: 19.231022 },
    { lat: 48.88882, lng: 19.231034 },
    { lat: 48.888851, lng: 19.231055 },
    { lat: 48.890542, lng: 19.232153 },
    { lat: 48.891111, lng: 19.23245 },
    { lat: 48.892036, lng: 19.231357 },
    { lat: 48.892752, lng: 19.231359 },
    { lat: 48.892798, lng: 19.231406 },
    { lat: 48.89292, lng: 19.23147 },
    { lat: 48.893162, lng: 19.231516 },
    { lat: 48.893289, lng: 19.231515 },
    { lat: 48.893414, lng: 19.231522 },
    { lat: 48.893506, lng: 19.231545 },
    { lat: 48.893706, lng: 19.232041 },
    { lat: 48.893869, lng: 19.232432 },
    { lat: 48.894031, lng: 19.232886 },
    { lat: 48.894123, lng: 19.233115 },
    { lat: 48.894219, lng: 19.233574 },
    { lat: 48.894306, lng: 19.234007 },
    { lat: 48.894343, lng: 19.234357 },
    { lat: 48.894414, lng: 19.234754 },
    { lat: 48.89447, lng: 19.235423 },
    { lat: 48.894508, lng: 19.235929 },
    { lat: 48.894456, lng: 19.236512 },
    { lat: 48.894375, lng: 19.237052 },
    { lat: 48.894378, lng: 19.238059 },
    { lat: 48.894431, lng: 19.239067 },
    { lat: 48.894529, lng: 19.239692 },
    { lat: 48.894633, lng: 19.240605 },
    { lat: 48.894857, lng: 19.241442 },
    { lat: 48.895004, lng: 19.241791 },
    { lat: 48.895035, lng: 19.241858 },
    { lat: 48.895077, lng: 19.242059 },
    { lat: 48.895094, lng: 19.242275 },
    { lat: 48.895104, lng: 19.242527 },
    { lat: 48.895151, lng: 19.243018 },
    { lat: 48.895172, lng: 19.243578 },
    { lat: 48.895177, lng: 19.243839 },
    { lat: 48.89516, lng: 19.244051 },
    { lat: 48.895128, lng: 19.244366 },
    { lat: 48.895104, lng: 19.244543 },
    { lat: 48.89502, lng: 19.244948 },
    { lat: 48.895001, lng: 19.245053 },
    { lat: 48.894938, lng: 19.245308 },
    { lat: 48.894875, lng: 19.245479 },
    { lat: 48.8948, lng: 19.245699 },
    { lat: 48.894593, lng: 19.246209 },
    { lat: 48.894492, lng: 19.246538 },
    { lat: 48.894422, lng: 19.246728 },
    { lat: 48.894344, lng: 19.247063 },
    { lat: 48.894314, lng: 19.24729 },
    { lat: 48.894245, lng: 19.247549 },
    { lat: 48.894163, lng: 19.24775 },
    { lat: 48.894089, lng: 19.24792 },
    { lat: 48.89399, lng: 19.248153 },
    { lat: 48.893926, lng: 19.248342 },
    { lat: 48.893845, lng: 19.248587 },
    { lat: 48.893712, lng: 19.248957 },
    { lat: 48.893581, lng: 19.249231 },
    { lat: 48.893495, lng: 19.249425 },
    { lat: 48.893357, lng: 19.24968 },
    { lat: 48.893205, lng: 19.24994 },
    { lat: 48.893147, lng: 19.250059 },
    { lat: 48.892892, lng: 19.250681 },
    { lat: 48.892728, lng: 19.251107 },
    { lat: 48.892655, lng: 19.251258 },
    { lat: 48.892535, lng: 19.251553 },
    { lat: 48.892424, lng: 19.251712 },
    { lat: 48.892305, lng: 19.251862 },
    { lat: 48.892198, lng: 19.251951 },
    { lat: 48.892018, lng: 19.25207 },
    { lat: 48.891895, lng: 19.252149 },
    { lat: 48.891811, lng: 19.252256 },
    { lat: 48.891725, lng: 19.252457 },
    { lat: 48.891648, lng: 19.25264 },
    { lat: 48.891532, lng: 19.252783 },
    { lat: 48.891437, lng: 19.252819 },
    { lat: 48.891285, lng: 19.252887 },
    { lat: 48.891123, lng: 19.252991 },
    { lat: 48.890922, lng: 19.253068 },
    { lat: 48.890768, lng: 19.253184 },
    { lat: 48.890607, lng: 19.253274 },
    { lat: 48.890346, lng: 19.253344 },
    { lat: 48.890028, lng: 19.253524 },
    { lat: 48.889802, lng: 19.253666 },
    { lat: 48.889356, lng: 19.254052 },
    { lat: 48.888857, lng: 19.25428 },
    { lat: 48.888671, lng: 19.254403 },
    { lat: 48.888599, lng: 19.254449 },
    { lat: 48.888578, lng: 19.254505 },
    { lat: 48.88855, lng: 19.254596 },
    { lat: 48.888474, lng: 19.254687 },
    { lat: 48.888399, lng: 19.254733 },
    { lat: 48.888334, lng: 19.254772 },
    { lat: 48.888154, lng: 19.254806 },
    { lat: 48.888057, lng: 19.25481 },
    { lat: 48.887849, lng: 19.254846 },
    { lat: 48.887635, lng: 19.254876 },
    { lat: 48.887404, lng: 19.254897 },
    { lat: 48.887291, lng: 19.254887 },
    { lat: 48.88717, lng: 19.254866 },
    { lat: 48.886929, lng: 19.254815 },
    { lat: 48.886701, lng: 19.25475 },
    { lat: 48.886441, lng: 19.254682 },
    { lat: 48.886234, lng: 19.254645 },
    { lat: 48.88606, lng: 19.254603 },
    { lat: 48.885953, lng: 19.254601 },
    { lat: 48.88577, lng: 19.254618 },
    { lat: 48.885604, lng: 19.254623 },
    { lat: 48.885386, lng: 19.254623 },
    { lat: 48.885238, lng: 19.254588 },
    { lat: 48.885191, lng: 19.25458 },
    { lat: 48.885115, lng: 19.254545 },
    { lat: 48.885039, lng: 19.25452 },
    { lat: 48.884937, lng: 19.254486 },
    { lat: 48.884896, lng: 19.254475 },
    { lat: 48.884855, lng: 19.254465 },
    { lat: 48.884826, lng: 19.254569 },
    { lat: 48.884781, lng: 19.254764 },
    { lat: 48.884714, lng: 19.254913 },
    { lat: 48.884655, lng: 19.25519 },
    { lat: 48.884569, lng: 19.255658 },
    { lat: 48.884616, lng: 19.25619 },
    { lat: 48.884686, lng: 19.256576 },
    { lat: 48.884772, lng: 19.257052 },
    { lat: 48.8847161, lng: 19.2590475 },
    { lat: 48.88466, lng: 19.259512 },
    { lat: 48.884542, lng: 19.260037 },
    { lat: 48.884228, lng: 19.261034 },
    { lat: 48.884121, lng: 19.261532 },
    { lat: 48.884027, lng: 19.261683 },
    { lat: 48.883922, lng: 19.261867 },
    { lat: 48.883726, lng: 19.262275 },
    { lat: 48.883652, lng: 19.262464 },
    { lat: 48.883608, lng: 19.262685 },
    { lat: 48.883584, lng: 19.262886 },
    { lat: 48.88351, lng: 19.263373 },
    { lat: 48.883509, lng: 19.2634 },
    { lat: 48.883523, lng: 19.263599 },
    { lat: 48.883562, lng: 19.264183 },
    { lat: 48.883572, lng: 19.264319 },
    { lat: 48.883594, lng: 19.264719 },
    { lat: 48.8836638, lng: 19.2649951 },
    { lat: 48.883702, lng: 19.265146 },
    { lat: 48.883861, lng: 19.265287 },
    { lat: 48.883917, lng: 19.265323 },
    { lat: 48.883842, lng: 19.26537 },
    { lat: 48.883729, lng: 19.265391 },
    { lat: 48.883306, lng: 19.2653 },
    { lat: 48.883251, lng: 19.265274 },
    { lat: 48.883031, lng: 19.265297 },
    { lat: 48.882884, lng: 19.265413 },
    { lat: 48.881929, lng: 19.265567 },
    { lat: 48.881716, lng: 19.265406 },
    { lat: 48.880911, lng: 19.265329 },
    { lat: 48.880784, lng: 19.265341 },
    { lat: 48.880727, lng: 19.265415 },
    { lat: 48.880581, lng: 19.265585 },
    { lat: 48.88056, lng: 19.265579 },
    { lat: 48.879967, lng: 19.265471 },
    { lat: 48.879782, lng: 19.265458 },
    { lat: 48.879663, lng: 19.265414 },
    { lat: 48.879574, lng: 19.265361 },
    { lat: 48.879439, lng: 19.265344 },
    { lat: 48.879355, lng: 19.265399 },
    { lat: 48.8791, lng: 19.26533 },
    { lat: 48.878983, lng: 19.265243 },
    { lat: 48.878888, lng: 19.26525 },
    { lat: 48.878862, lng: 19.265258 },
    { lat: 48.878745, lng: 19.265296 },
    { lat: 48.878174, lng: 19.265118 },
    { lat: 48.877798, lng: 19.264871 },
    { lat: 48.877766, lng: 19.264855 },
    { lat: 48.87774, lng: 19.264831 },
    { lat: 48.877711, lng: 19.264814 },
    { lat: 48.877634, lng: 19.264715 },
    { lat: 48.877618, lng: 19.26476 },
    { lat: 48.877204, lng: 19.264645 },
    { lat: 48.876057, lng: 19.264543 },
    { lat: 48.875094, lng: 19.26482 },
    { lat: 48.874416, lng: 19.265145 },
    { lat: 48.872959, lng: 19.265563 },
    { lat: 48.871884, lng: 19.266083 },
    { lat: 48.870273, lng: 19.266283 },
    { lat: 48.869769, lng: 19.266534 },
    { lat: 48.869277, lng: 19.26669 },
    { lat: 48.868847, lng: 19.266602 },
    { lat: 48.868713, lng: 19.266632 },
    { lat: 48.867643, lng: 19.266938 },
    { lat: 48.867273, lng: 19.266966 },
    { lat: 48.866826, lng: 19.266748 },
    { lat: 48.866525, lng: 19.26686 },
    { lat: 48.865983, lng: 19.266911 },
    { lat: 48.865504, lng: 19.266441 },
    { lat: 48.864977, lng: 19.266482 },
    { lat: 48.864017, lng: 19.265622 },
    { lat: 48.86389, lng: 19.265171 },
    { lat: 48.863716, lng: 19.265011 },
    { lat: 48.863535, lng: 19.264331 },
    { lat: 48.86437, lng: 19.263252 },
    { lat: 48.865127, lng: 19.262001 },
    { lat: 48.864247, lng: 19.261136 },
    { lat: 48.863751, lng: 19.260782 },
    { lat: 48.861112, lng: 19.26089 },
    { lat: 48.860581, lng: 19.260796 },
    { lat: 48.860009, lng: 19.260463 },
    { lat: 48.859465, lng: 19.260157 },
    { lat: 48.859366, lng: 19.260024 },
    { lat: 48.85934, lng: 19.259904 },
    { lat: 48.859, lng: 19.258379 },
    { lat: 48.858921, lng: 19.258024 },
    { lat: 48.858739, lng: 19.257097 },
    { lat: 48.858903, lng: 19.256663 },
    { lat: 48.859036, lng: 19.256481 },
    { lat: 48.85901, lng: 19.256433 },
    { lat: 48.858886, lng: 19.256194 },
    { lat: 48.858325, lng: 19.255361 },
    { lat: 48.857838, lng: 19.255044 },
    { lat: 48.857006, lng: 19.254125 },
    { lat: 48.856654, lng: 19.253461 },
    { lat: 48.856405, lng: 19.253473 },
    { lat: 48.856112, lng: 19.252941 },
    { lat: 48.855886, lng: 19.25334 },
    { lat: 48.856132, lng: 19.254375 },
    { lat: 48.856184, lng: 19.25483 },
    { lat: 48.856266, lng: 19.255842 },
    { lat: 48.856373, lng: 19.258981 },
    { lat: 48.856413, lng: 19.25949 },
    { lat: 48.856887, lng: 19.260845 },
    { lat: 48.857326, lng: 19.261666 },
    { lat: 48.857948, lng: 19.262617 },
    { lat: 48.858846, lng: 19.26394 },
    { lat: 48.859532, lng: 19.264481 },
    { lat: 48.859591, lng: 19.265271 },
    { lat: 48.859198, lng: 19.266853 },
    { lat: 48.85864, lng: 19.268251 },
    { lat: 48.8584955, lng: 19.2687027 },
    { lat: 48.858344, lng: 19.269215 },
    { lat: 48.858298, lng: 19.269857 },
    { lat: 48.858321, lng: 19.270316 },
    { lat: 48.859171, lng: 19.274211 },
    { lat: 48.859517, lng: 19.276389 },
    { lat: 48.859601, lng: 19.277073 },
    { lat: 48.859594, lng: 19.277657 },
    { lat: 48.859826, lng: 19.278557 },
    { lat: 48.860083, lng: 19.279867 },
    { lat: 48.860352, lng: 19.281237 },
    { lat: 48.860425, lng: 19.281848 },
    { lat: 48.860466, lng: 19.281976 },
    { lat: 48.860744, lng: 19.283111 },
    { lat: 48.861056, lng: 19.285032 },
    { lat: 48.860792, lng: 19.286121 },
    { lat: 48.860531, lng: 19.286716 },
    { lat: 48.860712, lng: 19.288024 },
    { lat: 48.86067, lng: 19.2888 },
    { lat: 48.860651, lng: 19.28973 },
    { lat: 48.860304, lng: 19.29028 },
    { lat: 48.860107, lng: 19.290441 },
    { lat: 48.859774, lng: 19.291351 },
    { lat: 48.859701, lng: 19.292212 },
    { lat: 48.859589, lng: 19.292831 },
    { lat: 48.859679, lng: 19.295631 },
    { lat: 48.859687, lng: 19.296514 },
    { lat: 48.859631, lng: 19.297808 },
    { lat: 48.859975, lng: 19.299655 },
    { lat: 48.8601409, lng: 19.3000242 },
    { lat: 48.860722, lng: 19.301318 },
    { lat: 48.860871, lng: 19.302012 },
    { lat: 48.861483, lng: 19.303168 },
    { lat: 48.861493, lng: 19.303185 },
    { lat: 48.862042, lng: 19.303917 },
    { lat: 48.86225, lng: 19.304352 },
    { lat: 48.862282, lng: 19.304399 },
    { lat: 48.862441, lng: 19.30473 },
    { lat: 48.862565, lng: 19.305022 },
    { lat: 48.863889, lng: 19.30813 },
    { lat: 48.865301, lng: 19.311033 },
    { lat: 48.865802, lng: 19.312025 },
    { lat: 48.866225, lng: 19.312685 },
    { lat: 48.866699, lng: 19.313316 },
    { lat: 48.867385, lng: 19.314073 },
    { lat: 48.868772, lng: 19.315492 },
    { lat: 48.869216, lng: 19.315987 },
    { lat: 48.870308, lng: 19.317016 },
    { lat: 48.870928, lng: 19.317394 },
    { lat: 48.871947, lng: 19.317614 },
    { lat: 48.872954, lng: 19.317703 },
    { lat: 48.873496, lng: 19.317787 },
    { lat: 48.873992, lng: 19.318001 },
    { lat: 48.875565, lng: 19.318306 },
    { lat: 48.876, lng: 19.318432 },
    { lat: 48.876863, lng: 19.318404 },
  ],
  LiptovskáŠtiavnica: [
    { lat: 49.00744, lng: 19.370918 },
    { lat: 49.0081193, lng: 19.3708502 },
    { lat: 49.0081494, lng: 19.3708408 },
    { lat: 49.0086382, lng: 19.3706897 },
    { lat: 49.0089507, lng: 19.3705057 },
    { lat: 49.0092328, lng: 19.3702722 },
    { lat: 49.0098628, lng: 19.3699152 },
    { lat: 49.0099793, lng: 19.3699215 },
    { lat: 49.0100715, lng: 19.3699424 },
    { lat: 49.0107569, lng: 19.3705726 },
    { lat: 49.0112752, lng: 19.3707124 },
    { lat: 49.0117818, lng: 19.3708185 },
    { lat: 49.0124553, lng: 19.3706937 },
    { lat: 49.0133115, lng: 19.3709963 },
    { lat: 49.0134865, lng: 19.3711927 },
    { lat: 49.0142059, lng: 19.3713716 },
    { lat: 49.0145106, lng: 19.3715722 },
    { lat: 49.0147025, lng: 19.3715561 },
    { lat: 49.0148816, lng: 19.3716023 },
    { lat: 49.0157395, lng: 19.3719895 },
    { lat: 49.0171996, lng: 19.3721246 },
    { lat: 49.0185963, lng: 19.3726393 },
    { lat: 49.0205412, lng: 19.3731595 },
    { lat: 49.0207263, lng: 19.3731689 },
    { lat: 49.0217378, lng: 19.373059 },
    { lat: 49.0235442, lng: 19.3726029 },
    { lat: 49.0246173, lng: 19.3722468 },
    { lat: 49.0250805, lng: 19.3720525 },
    { lat: 49.0258361, lng: 19.3716583 },
    { lat: 49.0260084, lng: 19.3715731 },
    { lat: 49.0264458, lng: 19.3713709 },
    { lat: 49.0276628, lng: 19.3712031 },
    { lat: 49.0283274, lng: 19.3711938 },
    { lat: 49.0288378, lng: 19.3708334 },
    { lat: 49.0299188, lng: 19.3706459 },
    { lat: 49.0307551, lng: 19.370406 },
    { lat: 49.031862, lng: 19.3695983 },
    { lat: 49.0324035, lng: 19.3692711 },
    { lat: 49.0332877, lng: 19.3696229 },
    { lat: 49.0341315, lng: 19.3700257 },
    { lat: 49.0360523, lng: 19.3712117 },
    { lat: 49.0358923, lng: 19.3717366 },
    { lat: 49.0373543, lng: 19.3730418 },
    { lat: 49.0395997, lng: 19.3748755 },
    { lat: 49.0402905, lng: 19.3761733 },
    { lat: 49.0405067, lng: 19.3764778 },
    { lat: 49.0406427, lng: 19.3768804 },
    { lat: 49.040993, lng: 19.3773349 },
    { lat: 49.0411631, lng: 19.3773329 },
    { lat: 49.0422194, lng: 19.377242 },
    { lat: 49.0429136, lng: 19.3774615 },
    { lat: 49.0430842, lng: 19.3775641 },
    { lat: 49.0431372, lng: 19.3775907 },
    { lat: 49.0437134, lng: 19.377882 },
    { lat: 49.0437572, lng: 19.3779045 },
    { lat: 49.0454806, lng: 19.3777429 },
    { lat: 49.0454679, lng: 19.377186 },
    { lat: 49.0462583, lng: 19.3771954 },
    { lat: 49.0461969, lng: 19.3773519 },
    { lat: 49.0462111, lng: 19.3773629 },
    { lat: 49.0462696, lng: 19.3774115 },
    { lat: 49.0463834, lng: 19.3775062 },
    { lat: 49.0465076, lng: 19.3776081 },
    { lat: 49.0466262, lng: 19.3776318 },
    { lat: 49.0466687, lng: 19.3776283 },
    { lat: 49.0468205, lng: 19.3776474 },
    { lat: 49.0469462, lng: 19.3776344 },
    { lat: 49.0470257, lng: 19.3776262 },
    { lat: 49.0474553, lng: 19.3775824 },
    { lat: 49.0476312, lng: 19.3775619 },
    { lat: 49.0476784, lng: 19.3775564 },
    { lat: 49.0480965, lng: 19.3775081 },
    { lat: 49.0482636, lng: 19.377587 },
    { lat: 49.0482762, lng: 19.3775932 },
    { lat: 49.0483419, lng: 19.3776241 },
    { lat: 49.0483568, lng: 19.3776315 },
    { lat: 49.0484341, lng: 19.3776683 },
    { lat: 49.0484433, lng: 19.377672 },
    { lat: 49.0484549, lng: 19.3776779 },
    { lat: 49.0484701, lng: 19.3776903 },
    { lat: 49.0484773, lng: 19.3776963 },
    { lat: 49.0485217, lng: 19.3777332 },
    { lat: 49.0504431, lng: 19.3793145 },
    { lat: 49.0505413, lng: 19.3793948 },
    { lat: 49.0505472, lng: 19.3793991 },
    { lat: 49.050547, lng: 19.3793952 },
    { lat: 49.0505503, lng: 19.3793205 },
    { lat: 49.0505641, lng: 19.3790154 },
    { lat: 49.0505643, lng: 19.3789935 },
    { lat: 49.0505684, lng: 19.3789158 },
    { lat: 49.0505719, lng: 19.3788435 },
    { lat: 49.0506099, lng: 19.3779732 },
    { lat: 49.0507898, lng: 19.3761835 },
    { lat: 49.0517135, lng: 19.3765391 },
    { lat: 49.0522604, lng: 19.3748632 },
    { lat: 49.0522772, lng: 19.3748129 },
    { lat: 49.0522939, lng: 19.3747608 },
    { lat: 49.0523077, lng: 19.3747179 },
    { lat: 49.0523987, lng: 19.374786 },
    { lat: 49.0524012, lng: 19.3747876 },
    { lat: 49.0528326, lng: 19.3750486 },
    { lat: 49.0529021, lng: 19.3750903 },
    { lat: 49.0531505, lng: 19.3752411 },
    { lat: 49.0533243, lng: 19.3753466 },
    { lat: 49.0536699, lng: 19.3755555 },
    { lat: 49.0542661, lng: 19.3759169 },
    { lat: 49.0547049, lng: 19.3760927 },
    { lat: 49.0548976, lng: 19.3760934 },
    { lat: 49.054998, lng: 19.3760936 },
    { lat: 49.0550544, lng: 19.3760939 },
    { lat: 49.0550995, lng: 19.3760937 },
    { lat: 49.0551017, lng: 19.3760938 },
    { lat: 49.0551564, lng: 19.3761069 },
    { lat: 49.055309, lng: 19.3761378 },
    { lat: 49.0553702, lng: 19.3761707 },
    { lat: 49.0555176, lng: 19.3762416 },
    { lat: 49.0558878, lng: 19.3764659 },
    { lat: 49.056005, lng: 19.3766123 },
    { lat: 49.0560843, lng: 19.3768135 },
    { lat: 49.0561915, lng: 19.3770804 },
    { lat: 49.0564856, lng: 19.3775601 },
    { lat: 49.0567595, lng: 19.378018 },
    { lat: 49.0568744, lng: 19.3783019 },
    { lat: 49.0570381, lng: 19.378793 },
    { lat: 49.0570814, lng: 19.3790908 },
    { lat: 49.0571235, lng: 19.3796274 },
    { lat: 49.0572504, lng: 19.379955 },
    { lat: 49.0574062, lng: 19.3802471 },
    { lat: 49.0574506, lng: 19.3803328 },
    { lat: 49.0574844, lng: 19.3804028 },
    { lat: 49.0575107, lng: 19.3804596 },
    { lat: 49.0576199, lng: 19.3804571 },
    { lat: 49.0580312, lng: 19.3806468 },
    { lat: 49.0581002, lng: 19.3806791 },
    { lat: 49.0589078, lng: 19.3811258 },
    { lat: 49.0608185, lng: 19.3810866 },
    { lat: 49.0608227, lng: 19.3810355 },
    { lat: 49.0608234, lng: 19.3810247 },
    { lat: 49.060819, lng: 19.3809565 },
    { lat: 49.0607865, lng: 19.3804048 },
    { lat: 49.0607868, lng: 19.3803645 },
    { lat: 49.060788, lng: 19.3802951 },
    { lat: 49.0608665, lng: 19.3751657 },
    { lat: 49.0609007, lng: 19.3729408 },
    { lat: 49.0609767, lng: 19.3729764 },
    { lat: 49.0609784, lng: 19.3729654 },
    { lat: 49.0609876, lng: 19.3728961 },
    { lat: 49.0611173, lng: 19.3719154 },
    { lat: 49.0616846, lng: 19.3714688 },
    { lat: 49.0622703, lng: 19.3710084 },
    { lat: 49.0625781, lng: 19.370723 },
    { lat: 49.0625709, lng: 19.3707601 },
    { lat: 49.0625698, lng: 19.3707817 },
    { lat: 49.0630775, lng: 19.3704092 },
    { lat: 49.0637802, lng: 19.3698947 },
    { lat: 49.0637911, lng: 19.3698921 },
    { lat: 49.0640532, lng: 19.3698305 },
    { lat: 49.0642849, lng: 19.3697758 },
    { lat: 49.0645346, lng: 19.3697174 },
    { lat: 49.0647051, lng: 19.3696769 },
    { lat: 49.0650927, lng: 19.3696028 },
    { lat: 49.0658625, lng: 19.369316 },
    { lat: 49.065862, lng: 19.3693049 },
    { lat: 49.065862, lng: 19.3693038 },
    { lat: 49.0660614, lng: 19.3692814 },
    { lat: 49.0663125, lng: 19.3692537 },
    { lat: 49.0663484, lng: 19.3692232 },
    { lat: 49.0666171, lng: 19.3689952 },
    { lat: 49.0666444, lng: 19.3689729 },
    { lat: 49.0668056, lng: 19.3689769 },
    { lat: 49.066865, lng: 19.3689449 },
    { lat: 49.067046, lng: 19.368936 },
    { lat: 49.067103, lng: 19.3690243 },
    { lat: 49.0671532, lng: 19.3690368 },
    { lat: 49.0671867, lng: 19.3690062 },
    { lat: 49.0672711, lng: 19.3690276 },
    { lat: 49.0673162, lng: 19.3689767 },
    { lat: 49.0673506, lng: 19.3689916 },
    { lat: 49.0673972, lng: 19.3690933 },
    { lat: 49.0675436, lng: 19.3689476 },
    { lat: 49.0675753, lng: 19.3688777 },
    { lat: 49.0676913, lng: 19.368759 },
    { lat: 49.0677684, lng: 19.3687711 },
    { lat: 49.0678556, lng: 19.3686121 },
    { lat: 49.0679922, lng: 19.3684233 },
    { lat: 49.0680565, lng: 19.3683987 },
    { lat: 49.0681096, lng: 19.368353 },
    { lat: 49.0682359, lng: 19.3684266 },
    { lat: 49.0682712, lng: 19.3683642 },
    { lat: 49.068354, lng: 19.3683009 },
    { lat: 49.0684145, lng: 19.3684133 },
    { lat: 49.0684542, lng: 19.3683493 },
    { lat: 49.0685429, lng: 19.368271 },
    { lat: 49.0686169, lng: 19.368309 },
    { lat: 49.0686829, lng: 19.3682515 },
    { lat: 49.0687829, lng: 19.3683639 },
    { lat: 49.0688111, lng: 19.3683662 },
    { lat: 49.06884, lng: 19.3684076 },
    { lat: 49.068998, lng: 19.3684325 },
    { lat: 49.0691413, lng: 19.3684129 },
    { lat: 49.0693196, lng: 19.3684169 },
    { lat: 49.0693402, lng: 19.3682324 },
    { lat: 49.0694734, lng: 19.3682887 },
    { lat: 49.0695398, lng: 19.3681888 },
    { lat: 49.0696398, lng: 19.3680848 },
    { lat: 49.0696365, lng: 19.3679684 },
    { lat: 49.0696268, lng: 19.3679539 },
    { lat: 49.0693724, lng: 19.3672639 },
    { lat: 49.0691129, lng: 19.3668968 },
    { lat: 49.0689401, lng: 19.3665321 },
    { lat: 49.0686409, lng: 19.3662304 },
    { lat: 49.0682489, lng: 19.3661411 },
    { lat: 49.0678605, lng: 19.3657855 },
    { lat: 49.0672547, lng: 19.3654863 },
    { lat: 49.066316, lng: 19.3650541 },
    { lat: 49.0668627, lng: 19.362468 },
    { lat: 49.0675451, lng: 19.3625061 },
    { lat: 49.067733, lng: 19.3617061 },
    { lat: 49.0678883, lng: 19.3612478 },
    { lat: 49.0680358, lng: 19.3607886 },
    { lat: 49.0685143, lng: 19.3590084 },
    { lat: 49.0687752, lng: 19.3578934 },
    { lat: 49.068779, lng: 19.3578779 },
    { lat: 49.0687827, lng: 19.357862 },
    { lat: 49.0687061, lng: 19.3577891 },
    { lat: 49.0692017, lng: 19.3581434 },
    { lat: 49.0695554, lng: 19.3584783 },
    { lat: 49.0698329, lng: 19.358626 },
    { lat: 49.0698394, lng: 19.3585717 },
    { lat: 49.069848, lng: 19.3585143 },
    { lat: 49.0698688, lng: 19.3585038 },
    { lat: 49.0698831, lng: 19.3584972 },
    { lat: 49.0698913, lng: 19.358405 },
    { lat: 49.0699394, lng: 19.3546175 },
    { lat: 49.0680057, lng: 19.3541887 },
    { lat: 49.0675967, lng: 19.354119 },
    { lat: 49.0661359, lng: 19.3538054 },
    { lat: 49.0648346, lng: 19.3535969 },
    { lat: 49.0648009, lng: 19.3535977 },
    { lat: 49.0648003, lng: 19.352654 },
    { lat: 49.064785, lng: 19.3523739 },
    { lat: 49.0646677, lng: 19.3515842 },
    { lat: 49.0643858, lng: 19.3503614 },
    { lat: 49.064284, lng: 19.3498262 },
    { lat: 49.063794, lng: 19.349184 },
    { lat: 49.0636993, lng: 19.3489934 },
    { lat: 49.0636363, lng: 19.3479692 },
    { lat: 49.0631816, lng: 19.3465252 },
    { lat: 49.0627419, lng: 19.3449938 },
    { lat: 49.0626528, lng: 19.3447271 },
    { lat: 49.0622445, lng: 19.3429748 },
    { lat: 49.0621331, lng: 19.3425004 },
    { lat: 49.0621203, lng: 19.342445 },
    { lat: 49.0621075, lng: 19.3423893 },
    { lat: 49.0621125, lng: 19.3423503 },
    { lat: 49.0620993, lng: 19.3423097 },
    { lat: 49.0623797, lng: 19.3422571 },
    { lat: 49.0625506, lng: 19.3422251 },
    { lat: 49.0627114, lng: 19.342195 },
    { lat: 49.0627505, lng: 19.3421875 },
    { lat: 49.0627964, lng: 19.3421788 },
    { lat: 49.0630075, lng: 19.3421382 },
    { lat: 49.0632452, lng: 19.342092 },
    { lat: 49.0634161, lng: 19.3420597 },
    { lat: 49.0642951, lng: 19.3418903 },
    { lat: 49.0644919, lng: 19.3401864 },
    { lat: 49.0643017, lng: 19.3401192 },
    { lat: 49.0644011, lng: 19.3394833 },
    { lat: 49.0648437, lng: 19.3395955 },
    { lat: 49.0648336, lng: 19.3385705 },
    { lat: 49.0648748, lng: 19.3385333 },
    { lat: 49.0649239, lng: 19.3385024 },
    { lat: 49.064981, lng: 19.3384656 },
    { lat: 49.0652682, lng: 19.339107 },
    { lat: 49.0653323, lng: 19.3392495 },
    { lat: 49.0653929, lng: 19.339387 },
    { lat: 49.0653803, lng: 19.3392863 },
    { lat: 49.0653926, lng: 19.3387858 },
    { lat: 49.0654064, lng: 19.3386232 },
    { lat: 49.0654143, lng: 19.3385311 },
    { lat: 49.065426, lng: 19.3383925 },
    { lat: 49.0654375, lng: 19.3382572 },
    { lat: 49.0654763, lng: 19.3379777 },
    { lat: 49.0654858, lng: 19.337895 },
    { lat: 49.0655125, lng: 19.3376951 },
    { lat: 49.0655207, lng: 19.3376339 },
    { lat: 49.0654828, lng: 19.3376157 },
    { lat: 49.0654433, lng: 19.3375888 },
    { lat: 49.0652578, lng: 19.3372676 },
    { lat: 49.0651107, lng: 19.3371569 },
    { lat: 49.064954, lng: 19.3370407 },
    { lat: 49.0649088, lng: 19.3369874 },
    { lat: 49.0648951, lng: 19.3369623 },
    { lat: 49.0648807, lng: 19.3369175 },
    { lat: 49.0647943, lng: 19.3366632 },
    { lat: 49.0646502, lng: 19.3364978 },
    { lat: 49.0645405, lng: 19.33637 },
    { lat: 49.0642104, lng: 19.3360424 },
    { lat: 49.0638593, lng: 19.3356938 },
    { lat: 49.0631702, lng: 19.3351852 },
    { lat: 49.062699, lng: 19.3351333 },
    { lat: 49.0626341, lng: 19.3351447 },
    { lat: 49.0625398, lng: 19.3351723 },
    { lat: 49.0624433, lng: 19.3352281 },
    { lat: 49.0622739, lng: 19.3353433 },
    { lat: 49.0621727, lng: 19.3354655 },
    { lat: 49.0621327, lng: 19.3354774 },
    { lat: 49.0620926, lng: 19.3354649 },
    { lat: 49.0619925, lng: 19.3354205 },
    { lat: 49.0614433, lng: 19.3354361 },
    { lat: 49.0608403, lng: 19.3355804 },
    { lat: 49.0605294, lng: 19.3356525 },
    { lat: 49.0602269, lng: 19.3356925 },
    { lat: 49.0596136, lng: 19.3352576 },
    { lat: 49.0590754, lng: 19.335149 },
    { lat: 49.0582928, lng: 19.3349935 },
    { lat: 49.0580274, lng: 19.3350111 },
    { lat: 49.057217, lng: 19.3349126 },
    { lat: 49.0561965, lng: 19.3350174 },
    { lat: 49.0553842, lng: 19.3345497 },
    { lat: 49.0553518, lng: 19.3346226 },
    { lat: 49.0553804, lng: 19.3347787 },
    { lat: 49.0553794, lng: 19.3348011 },
    { lat: 49.055369, lng: 19.3348726 },
    { lat: 49.0553152, lng: 19.334852 },
    { lat: 49.0553032, lng: 19.3349075 },
    { lat: 49.0552319, lng: 19.3352836 },
    { lat: 49.0549504, lng: 19.3369332 },
    { lat: 49.0525058, lng: 19.3360699 },
    { lat: 49.0521637, lng: 19.3359371 },
    { lat: 49.0514268, lng: 19.3357058 },
    { lat: 49.0503891, lng: 19.3354712 },
    { lat: 49.0501926, lng: 19.3379438 },
    { lat: 49.0501997, lng: 19.34012 },
    { lat: 49.0502, lng: 19.3401753 },
    { lat: 49.0502004, lng: 19.3402084 },
    { lat: 49.0503103, lng: 19.3430808 },
    { lat: 49.0497393, lng: 19.343543 },
    { lat: 49.048779, lng: 19.3424692 },
    { lat: 49.0494294, lng: 19.3406545 },
    { lat: 49.047631, lng: 19.340495 },
    { lat: 49.0462805, lng: 19.3407019 },
    { lat: 49.0460431, lng: 19.34145 },
    { lat: 49.0460266, lng: 19.3422016 },
    { lat: 49.0449852, lng: 19.3419561 },
    { lat: 49.0444286, lng: 19.3412219 },
    { lat: 49.0439774, lng: 19.3409986 },
    { lat: 49.0439306, lng: 19.3421533 },
    { lat: 49.0430674, lng: 19.3420641 },
    { lat: 49.0430474, lng: 19.3418539 },
    { lat: 49.0429868, lng: 19.3411661 },
    { lat: 49.0430411, lng: 19.3400505 },
    { lat: 49.0428565, lng: 19.3398185 },
    { lat: 49.0425037, lng: 19.3396225 },
    { lat: 49.0422723, lng: 19.3398292 },
    { lat: 49.0419242, lng: 19.3393101 },
    { lat: 49.0416482, lng: 19.3393607 },
    { lat: 49.0415728, lng: 19.3390054 },
    { lat: 49.0413926, lng: 19.3388401 },
    { lat: 49.0415281, lng: 19.339847 },
    { lat: 49.0415324, lng: 19.340653 },
    { lat: 49.0415349, lng: 19.3408711 },
    { lat: 49.0415322, lng: 19.3410988 },
    { lat: 49.0410111, lng: 19.3424766 },
    { lat: 49.0409654, lng: 19.3429192 },
    { lat: 49.0408757, lng: 19.3431402 },
    { lat: 49.0406292, lng: 19.3435569 },
    { lat: 49.040535, lng: 19.3438524 },
    { lat: 49.0404746, lng: 19.3443131 },
    { lat: 49.040312, lng: 19.3448068 },
    { lat: 49.0401033, lng: 19.3448982 },
    { lat: 49.040001, lng: 19.345001 },
    { lat: 49.0396108, lng: 19.3454251 },
    { lat: 49.0394334, lng: 19.345225 },
    { lat: 49.0390435, lng: 19.3449134 },
    { lat: 49.0385217, lng: 19.344651 },
    { lat: 49.0384585, lng: 19.344363 },
    { lat: 49.039127, lng: 19.343961 },
    { lat: 49.0392009, lng: 19.3437607 },
    { lat: 49.039406, lng: 19.3435231 },
    { lat: 49.0395312, lng: 19.3431887 },
    { lat: 49.0394921, lng: 19.3429117 },
    { lat: 49.038922, lng: 19.3425321 },
    { lat: 49.0385738, lng: 19.3425084 },
    { lat: 49.0377696, lng: 19.3418983 },
    { lat: 49.0377489, lng: 19.3418894 },
    { lat: 49.0372034, lng: 19.3416491 },
    { lat: 49.0365523, lng: 19.3413251 },
    { lat: 49.0365099, lng: 19.3414767 },
    { lat: 49.0361304, lng: 19.3413368 },
    { lat: 49.036116, lng: 19.3410316 },
    { lat: 49.0364588, lng: 19.3410598 },
    { lat: 49.0376336, lng: 19.3413833 },
    { lat: 49.0376954, lng: 19.3414003 },
    { lat: 49.0380844, lng: 19.3416663 },
    { lat: 49.0386366, lng: 19.3420241 },
    { lat: 49.0392731, lng: 19.3420196 },
    { lat: 49.0393324, lng: 19.341674 },
    { lat: 49.0390029, lng: 19.3408792 },
    { lat: 49.03906, lng: 19.3399688 },
    { lat: 49.0388417, lng: 19.3386858 },
    { lat: 49.0384311, lng: 19.3379832 },
    { lat: 49.0381164, lng: 19.3376706 },
    { lat: 49.0381495, lng: 19.3383718 },
    { lat: 49.0382815, lng: 19.339065 },
    { lat: 49.0385742, lng: 19.3390577 },
    { lat: 49.0386296, lng: 19.3393714 },
    { lat: 49.0385634, lng: 19.3397845 },
    { lat: 49.037737, lng: 19.3394427 },
    { lat: 49.037597, lng: 19.339698 },
    { lat: 49.0373868, lng: 19.3395428 },
    { lat: 49.0372963, lng: 19.3397517 },
    { lat: 49.0371465, lng: 19.3396355 },
    { lat: 49.0369874, lng: 19.339511 },
    { lat: 49.0367834, lng: 19.3391784 },
    { lat: 49.0366778, lng: 19.3388728 },
    { lat: 49.0369092, lng: 19.338118 },
    { lat: 49.036992, lng: 19.337846 },
    { lat: 49.0371391, lng: 19.3378806 },
    { lat: 49.0370778, lng: 19.3371782 },
    { lat: 49.0370745, lng: 19.3371333 },
    { lat: 49.0365578, lng: 19.3369775 },
    { lat: 49.036079, lng: 19.3366269 },
    { lat: 49.0356885, lng: 19.3365898 },
    { lat: 49.0356772, lng: 19.3365889 },
    { lat: 49.0355811, lng: 19.3365797 },
    { lat: 49.0355671, lng: 19.3364285 },
    { lat: 49.0355645, lng: 19.336399 },
    { lat: 49.0354676, lng: 19.336378 },
    { lat: 49.0353607, lng: 19.3363545 },
    { lat: 49.0354239, lng: 19.3361425 },
    { lat: 49.0354482, lng: 19.3360608 },
    { lat: 49.0355679, lng: 19.3360919 },
    { lat: 49.0356008, lng: 19.3354726 },
    { lat: 49.0352415, lng: 19.3353607 },
    { lat: 49.035205, lng: 19.3353493 },
    { lat: 49.0350459, lng: 19.3352995 },
    { lat: 49.0349727, lng: 19.3352763 },
    { lat: 49.0346881, lng: 19.3354302 },
    { lat: 49.0346068, lng: 19.335474 },
    { lat: 49.0346601, lng: 19.3350034 },
    { lat: 49.0344686, lng: 19.3349133 },
    { lat: 49.0341425, lng: 19.3348542 },
    { lat: 49.0339573, lng: 19.3347527 },
    { lat: 49.0337926, lng: 19.3344866 },
    { lat: 49.0338918, lng: 19.3344168 },
    { lat: 49.034001, lng: 19.3343391 },
    { lat: 49.0340479, lng: 19.3343064 },
    { lat: 49.0340543, lng: 19.3342532 },
    { lat: 49.0335358, lng: 19.3339896 },
    { lat: 49.0331327, lng: 19.333857 },
    { lat: 49.0330882, lng: 19.3338435 },
    { lat: 49.0327348, lng: 19.3336864 },
    { lat: 49.0318623, lng: 19.3329903 },
    { lat: 49.0314498, lng: 19.3327036 },
    { lat: 49.031429, lng: 19.3326915 },
    { lat: 49.0312666, lng: 19.332595 },
    { lat: 49.031119, lng: 19.3325246 },
    { lat: 49.0309382, lng: 19.3322769 },
    { lat: 49.0307497, lng: 19.3322016 },
    { lat: 49.0306049, lng: 19.3321661 },
    { lat: 49.0302283, lng: 19.3315878 },
    { lat: 49.030044, lng: 19.3313371 },
    { lat: 49.0298834, lng: 19.3309934 },
    { lat: 49.0292971, lng: 19.3305609 },
    { lat: 49.0286861, lng: 19.330459 },
    { lat: 49.0282378, lng: 19.3302488 },
    { lat: 49.0271768, lng: 19.3299322 },
    { lat: 49.0267966, lng: 19.3304457 },
    { lat: 49.0258149, lng: 19.3309191 },
    { lat: 49.0256511, lng: 19.3311762 },
    { lat: 49.0247288, lng: 19.3308566 },
    { lat: 49.0238023, lng: 19.3301425 },
    { lat: 49.0222435, lng: 19.3286132 },
    { lat: 49.0215463, lng: 19.3278399 },
    { lat: 49.0204731, lng: 19.3271904 },
    { lat: 49.0199406, lng: 19.3267019 },
    { lat: 49.0199306, lng: 19.3263712 },
    { lat: 49.0195279, lng: 19.3259815 },
    { lat: 49.0188975, lng: 19.3254517 },
    { lat: 49.0180561, lng: 19.3248893 },
    { lat: 49.0175893, lng: 19.3243146 },
    { lat: 49.0170468, lng: 19.3238562 },
    { lat: 49.0161228, lng: 19.3227679 },
    { lat: 49.0157966, lng: 19.3222572 },
    { lat: 49.0144165, lng: 19.321815 },
    { lat: 49.0139525, lng: 19.3218465 },
    { lat: 49.0139376, lng: 19.3219371 },
    { lat: 49.0139256, lng: 19.3220616 },
    { lat: 49.0139035, lng: 19.3221685 },
    { lat: 49.0132989, lng: 19.3224664 },
    { lat: 49.012987, lng: 19.3222603 },
    { lat: 49.0108306, lng: 19.3217273 },
    { lat: 49.0099447, lng: 19.3220542 },
    { lat: 49.0098991, lng: 19.3219752 },
    { lat: 49.0098471, lng: 19.3218739 },
    { lat: 49.0097986, lng: 19.3218038 },
    { lat: 49.0091801, lng: 19.3223067 },
    { lat: 49.0077458, lng: 19.32253 },
    { lat: 49.0069205, lng: 19.3229733 },
    { lat: 49.0065634, lng: 19.3230038 },
    { lat: 49.0058738, lng: 19.323312 },
    { lat: 49.0058733, lng: 19.3233968 },
    { lat: 49.0058779, lng: 19.3235194 },
    { lat: 49.0058734, lng: 19.3236125 },
    { lat: 49.0052922, lng: 19.3238129 },
    { lat: 49.0044708, lng: 19.3241255 },
    { lat: 49.0036609, lng: 19.324347 },
    { lat: 49.0033791, lng: 19.3245441 },
    { lat: 49.0032933, lng: 19.3246041 },
    { lat: 49.0028413, lng: 19.3245807 },
    { lat: 49.0028954, lng: 19.3249596 },
    { lat: 49.0024391, lng: 19.3259852 },
    { lat: 49.0018943, lng: 19.3262642 },
    { lat: 49.0018335, lng: 19.3261916 },
    { lat: 49.0017773, lng: 19.3260976 },
    { lat: 49.0017419, lng: 19.3260433 },
    { lat: 49.0009254, lng: 19.3263402 },
    { lat: 48.9999502, lng: 19.3265941 },
    { lat: 48.9995378, lng: 19.326837 },
    { lat: 48.9991289, lng: 19.3267199 },
    { lat: 48.997792, lng: 19.3274538 },
    { lat: 48.9974349, lng: 19.3274698 },
    { lat: 48.9970389, lng: 19.3274928 },
    { lat: 48.9968397, lng: 19.3273799 },
    { lat: 48.9961813, lng: 19.3268987 },
    { lat: 48.9943148, lng: 19.3269476 },
    { lat: 48.9937343, lng: 19.3271307 },
    { lat: 48.9931278, lng: 19.3266829 },
    { lat: 48.9929159, lng: 19.3266062 },
    { lat: 48.9920459, lng: 19.3257048 },
    { lat: 48.9915915, lng: 19.3252277 },
    { lat: 48.9905559, lng: 19.3245638 },
    { lat: 48.9903003, lng: 19.3242085 },
    { lat: 48.9897264, lng: 19.3236111 },
    { lat: 48.9892187, lng: 19.3234097 },
    { lat: 48.9887725, lng: 19.3231284 },
    { lat: 48.9880262, lng: 19.3229346 },
    { lat: 48.9866646, lng: 19.3226922 },
    { lat: 48.9858216, lng: 19.3222169 },
    { lat: 48.9854472, lng: 19.3218571 },
    { lat: 48.9843231, lng: 19.3215166 },
    { lat: 48.9838067, lng: 19.3210805 },
    { lat: 48.9833266, lng: 19.3203441 },
    { lat: 48.9823247, lng: 19.318189 },
    { lat: 48.9819418, lng: 19.3176264 },
    { lat: 48.9815689, lng: 19.3174617 },
    { lat: 48.9811822, lng: 19.3174942 },
    { lat: 48.9807475, lng: 19.317274 },
    { lat: 48.9802884, lng: 19.3169598 },
    { lat: 48.9798014, lng: 19.3168352 },
    { lat: 48.9789761, lng: 19.316117 },
    { lat: 48.9782972, lng: 19.3158499 },
    { lat: 48.9779615, lng: 19.315783 },
    { lat: 48.9776952, lng: 19.3157264 },
    { lat: 48.9772146, lng: 19.3158362 },
    { lat: 48.9768218, lng: 19.3159636 },
    { lat: 48.9766079, lng: 19.3160896 },
    { lat: 48.9755995, lng: 19.3167111 },
    { lat: 48.974658, lng: 19.317013 },
    { lat: 48.9735296, lng: 19.3173846 },
    { lat: 48.9732633, lng: 19.317496 },
    { lat: 48.9729023, lng: 19.3178462 },
    { lat: 48.9723669, lng: 19.318627 },
    { lat: 48.9714967, lng: 19.3192906 },
    { lat: 48.9700288, lng: 19.3196613 },
    { lat: 48.9696221, lng: 19.3197105 },
    { lat: 48.9688772, lng: 19.3201383 },
    { lat: 48.9673879, lng: 19.3230477 },
    { lat: 48.9669112, lng: 19.3233839 },
    { lat: 48.9663051, lng: 19.3237451 },
    { lat: 48.9652889, lng: 19.3241085 },
    { lat: 48.9651829, lng: 19.3241302 },
    { lat: 48.964742, lng: 19.3240551 },
    { lat: 48.9643418, lng: 19.3240285 },
    { lat: 48.9641742, lng: 19.3241046 },
    { lat: 48.9638387, lng: 19.3240668 },
    { lat: 48.9635932, lng: 19.324024 },
    { lat: 48.9634301, lng: 19.3240311 },
    { lat: 48.9630032, lng: 19.324202 },
    { lat: 48.9627576, lng: 19.3242722 },
    { lat: 48.9625514, lng: 19.3242973 },
    { lat: 48.9622306, lng: 19.3244985 },
    { lat: 48.9621657, lng: 19.3251017 },
    { lat: 48.9620997, lng: 19.3256577 },
    { lat: 48.9618812, lng: 19.3268254 },
    { lat: 48.9617598, lng: 19.3273038 },
    { lat: 48.9615418, lng: 19.3281992 },
    { lat: 48.9614968, lng: 19.3284165 },
    { lat: 48.9616122, lng: 19.3284698 },
    { lat: 48.961764, lng: 19.3285576 },
    { lat: 48.9619433, lng: 19.3284631 },
    { lat: 48.9620925, lng: 19.328573 },
    { lat: 48.9621811, lng: 19.3286071 },
    { lat: 48.9623354, lng: 19.3286985 },
    { lat: 48.9625097, lng: 19.3286701 },
    { lat: 48.9626719, lng: 19.3287632 },
    { lat: 48.9628152, lng: 19.3288863 },
    { lat: 48.9629614, lng: 19.3290461 },
    { lat: 48.9629887, lng: 19.3291181 },
    { lat: 48.9630759, lng: 19.3292947 },
    { lat: 48.9632819, lng: 19.3293159 },
    { lat: 48.9635048, lng: 19.3294544 },
    { lat: 48.9635504, lng: 19.3295588 },
    { lat: 48.9636223, lng: 19.3294372 },
    { lat: 48.9637606, lng: 19.3295699 },
    { lat: 48.9638599, lng: 19.3295472 },
    { lat: 48.9639943, lng: 19.3296516 },
    { lat: 48.9641539, lng: 19.3298788 },
    { lat: 48.9642883, lng: 19.3299803 },
    { lat: 48.9644747, lng: 19.3300858 },
    { lat: 48.9645729, lng: 19.3301852 },
    { lat: 48.9647292, lng: 19.3301993 },
    { lat: 48.9647897, lng: 19.3302834 },
    { lat: 48.964862, lng: 19.3301704 },
    { lat: 48.9650263, lng: 19.3302382 },
    { lat: 48.9650472, lng: 19.3302011 },
    { lat: 48.9653188, lng: 19.33027 },
    { lat: 48.965666, lng: 19.3301069 },
    { lat: 48.9657056, lng: 19.3301359 },
    { lat: 48.9658774, lng: 19.3301225 },
    { lat: 48.9659589, lng: 19.3302488 },
    { lat: 48.9660744, lng: 19.3303786 },
    { lat: 48.9662889, lng: 19.3305281 },
    { lat: 48.9666685, lng: 19.330903 },
    { lat: 48.9672004, lng: 19.3314525 },
    { lat: 48.967369, lng: 19.3314879 },
    { lat: 48.9675325, lng: 19.3316095 },
    { lat: 48.9675823, lng: 19.3317332 },
    { lat: 48.9676345, lng: 19.3319061 },
    { lat: 48.9677595, lng: 19.331999 },
    { lat: 48.9680493, lng: 19.3323379 },
    { lat: 48.9682173, lng: 19.3324103 },
    { lat: 48.9684634, lng: 19.3327044 },
    { lat: 48.968661, lng: 19.3331398 },
    { lat: 48.968816, lng: 19.3332961 },
    { lat: 48.9689401, lng: 19.3336786 },
    { lat: 48.9689615, lng: 19.3341792 },
    { lat: 48.9690268, lng: 19.3347951 },
    { lat: 48.9692626, lng: 19.3357494 },
    { lat: 48.9693172, lng: 19.3366448 },
    { lat: 48.9694008, lng: 19.3377657 },
    { lat: 48.9694312, lng: 19.3386477 },
    { lat: 48.9695136, lng: 19.3391258 },
    { lat: 48.9697872, lng: 19.3395486 },
    { lat: 48.9698151, lng: 19.3396388 },
    { lat: 48.9697636, lng: 19.3401919 },
    { lat: 48.9697869, lng: 19.3402801 },
    { lat: 48.9699209, lng: 19.3405183 },
    { lat: 48.9699326, lng: 19.3405763 },
    { lat: 48.9699287, lng: 19.340727 },
    { lat: 48.969935, lng: 19.3409354 },
    { lat: 48.9699539, lng: 19.3412605 },
    { lat: 48.970107, lng: 19.3414508 },
    { lat: 48.9702185, lng: 19.3417116 },
    { lat: 48.9702619, lng: 19.3422195 },
    { lat: 48.9702338, lng: 19.3425085 },
    { lat: 48.9702469, lng: 19.342761 },
    { lat: 48.9703279, lng: 19.3435001 },
    { lat: 48.9703995, lng: 19.3437706 },
    { lat: 48.9704387, lng: 19.3444856 },
    { lat: 48.9704457, lng: 19.3450383 },
    { lat: 48.9704876, lng: 19.3456142 },
    { lat: 48.9705702, lng: 19.3459083 },
    { lat: 48.9706481, lng: 19.3462678 },
    { lat: 48.9706706, lng: 19.3466941 },
    { lat: 48.9706703, lng: 19.3471927 },
    { lat: 48.9705781, lng: 19.3476953 },
    { lat: 48.9705423, lng: 19.3484417 },
    { lat: 48.9705781, lng: 19.3489834 },
    { lat: 48.9706788, lng: 19.3493954 },
    { lat: 48.9706695, lng: 19.3499391 },
    { lat: 48.9705078, lng: 19.3505501 },
    { lat: 48.9705022, lng: 19.3509786 },
    { lat: 48.9704483, lng: 19.3512923 },
    { lat: 48.970457, lng: 19.3515263 },
    { lat: 48.9705293, lng: 19.3517218 },
    { lat: 48.9707349, lng: 19.3519703 },
    { lat: 48.9711209, lng: 19.3526274 },
    { lat: 48.9711978, lng: 19.3528213 },
    { lat: 48.9714411, lng: 19.3532013 },
    { lat: 48.9717406, lng: 19.3534574 },
    { lat: 48.9719236, lng: 19.3536836 },
    { lat: 48.9720453, lng: 19.3537288 },
    { lat: 48.9722227, lng: 19.3537156 },
    { lat: 48.9723127, lng: 19.3537366 },
    { lat: 48.9724289, lng: 19.3537868 },
    { lat: 48.9728079, lng: 19.3540314 },
    { lat: 48.9731497, lng: 19.3543282 },
    { lat: 48.973754, lng: 19.3547374 },
    { lat: 48.9756666, lng: 19.3564137 },
    { lat: 48.9759724, lng: 19.3567266 },
    { lat: 48.9765202, lng: 19.3577368 },
    { lat: 48.9768121, lng: 19.35881 },
    { lat: 48.9772101, lng: 19.3592916 },
    { lat: 48.9781447, lng: 19.3601109 },
    { lat: 48.978619, lng: 19.361769 },
    { lat: 48.979284, lng: 19.362566 },
    { lat: 48.979606, lng: 19.362589 },
    { lat: 48.979793, lng: 19.362616 },
    { lat: 48.97995, lng: 19.362576 },
    { lat: 48.980115, lng: 19.362466 },
    { lat: 48.981241, lng: 19.363065 },
    { lat: 48.982295, lng: 19.363141 },
    { lat: 48.983264, lng: 19.363649 },
    { lat: 48.985118, lng: 19.363754 },
    { lat: 48.985985, lng: 19.363372 },
    { lat: 48.986383, lng: 19.363448 },
    { lat: 48.986857, lng: 19.363705 },
    { lat: 48.98704, lng: 19.363729 },
    { lat: 48.98737, lng: 19.363902 },
    { lat: 48.988, lng: 19.363977 },
    { lat: 48.988255, lng: 19.363844 },
    { lat: 48.988536, lng: 19.363518 },
    { lat: 48.990938, lng: 19.362954 },
    { lat: 48.991854, lng: 19.362823 },
    { lat: 48.993477, lng: 19.362472 },
    { lat: 48.994445, lng: 19.362662 },
    { lat: 48.994762, lng: 19.363969 },
    { lat: 48.994986, lng: 19.364245 },
    { lat: 48.995224, lng: 19.364448 },
    { lat: 48.995389, lng: 19.365029 },
    { lat: 48.9954682, lng: 19.365077 },
    { lat: 48.996613, lng: 19.36577 },
    { lat: 48.997004, lng: 19.366402 },
    { lat: 48.997642, lng: 19.366618 },
    { lat: 48.998416, lng: 19.366869 },
    { lat: 48.998855, lng: 19.367249 },
    { lat: 48.999665, lng: 19.367436 },
    { lat: 49.000797, lng: 19.368908 },
    { lat: 49.000252, lng: 19.369786 },
    { lat: 49.001704, lng: 19.370709 },
    { lat: 49.002692, lng: 19.369847 },
    { lat: 49.005117, lng: 19.369468 },
    { lat: 49.005361, lng: 19.369539 },
    { lat: 49.0056, lng: 19.369615 },
    { lat: 49.005898, lng: 19.369755 },
    { lat: 49.006593, lng: 19.370019 },
    { lat: 49.006997, lng: 19.370469 },
    { lat: 49.00744, lng: 19.370918 },
  ],
  LiptovskéRevúce: [
    { lat: 48.926171, lng: 19.104254 },
    { lat: 48.925513, lng: 19.103339 },
    { lat: 48.924605, lng: 19.102101 },
    { lat: 48.924313, lng: 19.101519 },
    { lat: 48.924086, lng: 19.101069 },
    { lat: 48.923861, lng: 19.099935 },
    { lat: 48.923831, lng: 19.098919 },
    { lat: 48.923811, lng: 19.098252 },
    { lat: 48.923641, lng: 19.097349 },
    { lat: 48.922806, lng: 19.095429 },
    { lat: 48.922023, lng: 19.094161 },
    { lat: 48.921251, lng: 19.092979 },
    { lat: 48.920238, lng: 19.092894 },
    { lat: 48.918876, lng: 19.092704 },
    { lat: 48.917859, lng: 19.091584 },
    { lat: 48.916627, lng: 19.089724 },
    { lat: 48.916279, lng: 19.089231 },
    { lat: 48.915192, lng: 19.088684 },
    { lat: 48.914708, lng: 19.088405 },
    { lat: 48.914626, lng: 19.088357 },
    { lat: 48.913046, lng: 19.088146 },
    { lat: 48.91192, lng: 19.088227 },
    { lat: 48.910812, lng: 19.088277 },
    { lat: 48.910109, lng: 19.088283 },
    { lat: 48.909842, lng: 19.088204 },
    { lat: 48.909516, lng: 19.08771 },
    { lat: 48.909141, lng: 19.087186 },
    { lat: 48.908991, lng: 19.086128 },
    { lat: 48.908907, lng: 19.085676 },
    { lat: 48.908706, lng: 19.084213 },
    { lat: 48.908704, lng: 19.083286 },
    { lat: 48.908826, lng: 19.082561 },
    { lat: 48.907742, lng: 19.08161 },
    { lat: 48.907119, lng: 19.081106 },
    { lat: 48.906431, lng: 19.080611 },
    { lat: 48.905809, lng: 19.08032 },
    { lat: 48.903029, lng: 19.0791 },
    { lat: 48.902152, lng: 19.07923 },
    { lat: 48.901992, lng: 19.079376 },
    { lat: 48.899962, lng: 19.081217 },
    { lat: 48.899524, lng: 19.081429 },
    { lat: 48.898723, lng: 19.081386 },
    { lat: 48.896951, lng: 19.081141 },
    { lat: 48.896014, lng: 19.08078 },
    { lat: 48.894455, lng: 19.079621 },
    { lat: 48.893241, lng: 19.078914 },
    { lat: 48.890401, lng: 19.080833 },
    { lat: 48.889988, lng: 19.081277 },
    { lat: 48.889501, lng: 19.081656 },
    { lat: 48.888266, lng: 19.082428 },
    { lat: 48.886971, lng: 19.083432 },
    { lat: 48.886384, lng: 19.08268 },
    { lat: 48.885101, lng: 19.081071 },
    { lat: 48.880252, lng: 19.080019 },
    { lat: 48.877724, lng: 19.078765 },
    { lat: 48.877094, lng: 19.078636 },
    { lat: 48.876764, lng: 19.078754 },
    { lat: 48.876523, lng: 19.079196 },
    { lat: 48.875611, lng: 19.080212 },
    { lat: 48.875065, lng: 19.080608 },
    { lat: 48.874813, lng: 19.080875 },
    { lat: 48.874618, lng: 19.081041 },
    { lat: 48.874669, lng: 19.081708 },
    { lat: 48.874631, lng: 19.082223 },
    { lat: 48.874675, lng: 19.083113 },
    { lat: 48.874741, lng: 19.083532 },
    { lat: 48.87502, lng: 19.085143 },
    { lat: 48.875725, lng: 19.086395 },
    { lat: 48.877022, lng: 19.088891 },
    { lat: 48.877037, lng: 19.088955 },
    { lat: 48.877365, lng: 19.090464 },
    { lat: 48.87759, lng: 19.091493 },
    { lat: 48.877604, lng: 19.091562 },
    { lat: 48.877282, lng: 19.0924 },
    { lat: 48.877161, lng: 19.092712 },
    { lat: 48.877142, lng: 19.092763 },
    { lat: 48.876959, lng: 19.094458 },
    { lat: 48.876952, lng: 19.094521 },
    { lat: 48.876919, lng: 19.09482 },
    { lat: 48.876905, lng: 19.095276 },
    { lat: 48.876943, lng: 19.095679 },
    { lat: 48.87697, lng: 19.095932 },
    { lat: 48.876952, lng: 19.096164 },
    { lat: 48.876974, lng: 19.09636 },
    { lat: 48.876904, lng: 19.096901 },
    { lat: 48.87689, lng: 19.097008 },
    { lat: 48.876798, lng: 19.097828 },
    { lat: 48.876828, lng: 19.098284 },
    { lat: 48.876938, lng: 19.099072 },
    { lat: 48.876976, lng: 19.099431 },
    { lat: 48.877025, lng: 19.099885 },
    { lat: 48.877187, lng: 19.100219 },
    { lat: 48.877255, lng: 19.100506 },
    { lat: 48.877627, lng: 19.101416 },
    { lat: 48.878028, lng: 19.102248 },
    { lat: 48.878075, lng: 19.102345 },
    { lat: 48.878262, lng: 19.1028 },
    { lat: 48.878373, lng: 19.102964 },
    { lat: 48.878905, lng: 19.103853 },
    { lat: 48.879222, lng: 19.104108 },
    { lat: 48.879075, lng: 19.105746 },
    { lat: 48.879058, lng: 19.106333 },
    { lat: 48.879054, lng: 19.106476 },
    { lat: 48.879055, lng: 19.106765 },
    { lat: 48.879011, lng: 19.107243 },
    { lat: 48.879199, lng: 19.107736 },
    { lat: 48.879732, lng: 19.109795 },
    { lat: 48.879838, lng: 19.110317 },
    { lat: 48.87984, lng: 19.110412 },
    { lat: 48.879879, lng: 19.111944 },
    { lat: 48.879776, lng: 19.112828 },
    { lat: 48.879754, lng: 19.113176 },
    { lat: 48.879746, lng: 19.113287 },
    { lat: 48.879799, lng: 19.113539 },
    { lat: 48.88, lng: 19.11407 },
    { lat: 48.880122, lng: 19.11496 },
    { lat: 48.880195, lng: 19.115633 },
    { lat: 48.880252, lng: 19.116179 },
    { lat: 48.880229, lng: 19.116275 },
    { lat: 48.88026, lng: 19.116446 },
    { lat: 48.880262, lng: 19.117053 },
    { lat: 48.880279, lng: 19.1176 },
    { lat: 48.88039, lng: 19.118212 },
    { lat: 48.880428, lng: 19.118341 },
    { lat: 48.880383, lng: 19.118857 },
    { lat: 48.880329, lng: 19.119472 },
    { lat: 48.880009, lng: 19.119754 },
    { lat: 48.879593, lng: 19.120213 },
    { lat: 48.879432, lng: 19.120551 },
    { lat: 48.879428, lng: 19.120678 },
    { lat: 48.879424, lng: 19.120805 },
    { lat: 48.879181, lng: 19.121473 },
    { lat: 48.879479, lng: 19.122403 },
    { lat: 48.879582, lng: 19.122558 },
    { lat: 48.879737, lng: 19.123309 },
    { lat: 48.879949, lng: 19.123993 },
    { lat: 48.879994, lng: 19.124352 },
    { lat: 48.879982, lng: 19.124704 },
    { lat: 48.879765, lng: 19.125492 },
    { lat: 48.879769, lng: 19.126293 },
    { lat: 48.87977, lng: 19.126375 },
    { lat: 48.879778, lng: 19.127651 },
    { lat: 48.879778, lng: 19.127756 },
    { lat: 48.87978, lng: 19.128039 },
    { lat: 48.879893, lng: 19.128714 },
    { lat: 48.880495, lng: 19.129758 },
    { lat: 48.880811, lng: 19.130306 },
    { lat: 48.88127, lng: 19.131067 },
    { lat: 48.88129, lng: 19.131217 },
    { lat: 48.881394, lng: 19.131999 },
    { lat: 48.881358, lng: 19.13288 },
    { lat: 48.881072, lng: 19.133539 },
    { lat: 48.880628, lng: 19.134493 },
    { lat: 48.88049, lng: 19.135354 },
    { lat: 48.880453, lng: 19.135633 },
    { lat: 48.880402, lng: 19.136014 },
    { lat: 48.880991, lng: 19.136498 },
    { lat: 48.881264, lng: 19.136764 },
    { lat: 48.881633, lng: 19.137394 },
    { lat: 48.881681, lng: 19.13765 },
    { lat: 48.881687, lng: 19.138005 },
    { lat: 48.881668, lng: 19.138433 },
    { lat: 48.881662, lng: 19.138572 },
    { lat: 48.881693, lng: 19.13879 },
    { lat: 48.881747, lng: 19.13968 },
    { lat: 48.881795, lng: 19.141393 },
    { lat: 48.881867, lng: 19.14225 },
    { lat: 48.881893, lng: 19.142905 },
    { lat: 48.88199, lng: 19.143642 },
    { lat: 48.882057, lng: 19.143915 },
    { lat: 48.882066, lng: 19.144324 },
    { lat: 48.882099, lng: 19.145824 },
    { lat: 48.881709, lng: 19.147549 },
    { lat: 48.881303, lng: 19.14845 },
    { lat: 48.881195, lng: 19.14877 },
    { lat: 48.880103, lng: 19.150254 },
    { lat: 48.878929, lng: 19.150976 },
    { lat: 48.87873, lng: 19.151327 },
    { lat: 48.878639, lng: 19.151796 },
    { lat: 48.87846, lng: 19.152408 },
    { lat: 48.878147, lng: 19.152972 },
    { lat: 48.877999, lng: 19.153362 },
    { lat: 48.877889, lng: 19.153655 },
    { lat: 48.878222, lng: 19.154422 },
    { lat: 48.878436, lng: 19.154799 },
    { lat: 48.878682, lng: 19.155879 },
    { lat: 48.878687, lng: 19.156281 },
    { lat: 48.8786824, lng: 19.1564073 },
    { lat: 48.8786601, lng: 19.1565177 },
    { lat: 48.878652, lng: 19.156589 },
    { lat: 48.878653, lng: 19.156887 },
    { lat: 48.878716, lng: 19.157714 },
    { lat: 48.879016, lng: 19.157972 },
    { lat: 48.879157, lng: 19.158148 },
    { lat: 48.87928, lng: 19.15827 },
    { lat: 48.879668, lng: 19.158734 },
    { lat: 48.879736, lng: 19.158815 },
    { lat: 48.880045, lng: 19.159035 },
    { lat: 48.880349, lng: 19.159417 },
    { lat: 48.880751, lng: 19.15983 },
    { lat: 48.881199, lng: 19.160044 },
    { lat: 48.881769, lng: 19.160618 },
    { lat: 48.882056, lng: 19.160779 },
    { lat: 48.882727, lng: 19.160788 },
    { lat: 48.882972, lng: 19.160694 },
    { lat: 48.883135, lng: 19.161116 },
    { lat: 48.88341, lng: 19.161761 },
    { lat: 48.883639, lng: 19.162111 },
    { lat: 48.884313, lng: 19.163608 },
    { lat: 48.884301, lng: 19.164357 },
    { lat: 48.884346, lng: 19.165161 },
    { lat: 48.884096, lng: 19.16686 },
    { lat: 48.884051, lng: 19.166937 },
    { lat: 48.883989, lng: 19.167043 },
    { lat: 48.883965, lng: 19.167083 },
    { lat: 48.88396, lng: 19.167135 },
    { lat: 48.883887, lng: 19.168088 },
    { lat: 48.883847, lng: 19.168342 },
    { lat: 48.883871, lng: 19.168473 },
    { lat: 48.883865, lng: 19.168916 },
    { lat: 48.883878, lng: 19.169151 },
    { lat: 48.883989, lng: 19.169775 },
    { lat: 48.883972, lng: 19.17024 },
    { lat: 48.883952, lng: 19.170494 },
    { lat: 48.883797, lng: 19.171359 },
    { lat: 48.883834, lng: 19.172111 },
    { lat: 48.883638, lng: 19.172874 },
    { lat: 48.883466, lng: 19.173367 },
    { lat: 48.882702, lng: 19.174169 },
    { lat: 48.882466, lng: 19.174841 },
    { lat: 48.882468, lng: 19.175841 },
    { lat: 48.881962, lng: 19.177309 },
    { lat: 48.881774, lng: 19.178055 },
    { lat: 48.881758, lng: 19.178133 },
    { lat: 48.881672, lng: 19.178587 },
    { lat: 48.881496, lng: 19.179181 },
    { lat: 48.881262, lng: 19.179597 },
    { lat: 48.880991, lng: 19.180138 },
    { lat: 48.880634, lng: 19.181221 },
    { lat: 48.880255, lng: 19.181763 },
    { lat: 48.880386, lng: 19.182262 },
    { lat: 48.880267, lng: 19.183106 },
    { lat: 48.88033, lng: 19.18328 },
    { lat: 48.881399, lng: 19.183671 },
    { lat: 48.881506, lng: 19.183516 },
    { lat: 48.881577, lng: 19.183602 },
    { lat: 48.881957, lng: 19.183648 },
    { lat: 48.882196, lng: 19.183872 },
    { lat: 48.882397, lng: 19.184137 },
    { lat: 48.88276, lng: 19.184819 },
    { lat: 48.883099, lng: 19.185598 },
    { lat: 48.883219, lng: 19.186006 },
    { lat: 48.883364, lng: 19.186189 },
    { lat: 48.883557, lng: 19.186373 },
    { lat: 48.883714, lng: 19.186477 },
    { lat: 48.884077, lng: 19.186455 },
    { lat: 48.884377, lng: 19.186705 },
    { lat: 48.884567, lng: 19.186744 },
    { lat: 48.884745, lng: 19.186732 },
    { lat: 48.88492, lng: 19.18658 },
    { lat: 48.885036, lng: 19.187578 },
    { lat: 48.885105, lng: 19.187979 },
    { lat: 48.885124, lng: 19.188338 },
    { lat: 48.885188, lng: 19.188636 },
    { lat: 48.885089, lng: 19.189139 },
    { lat: 48.88509, lng: 19.189561 },
    { lat: 48.885041, lng: 19.19002 },
    { lat: 48.885165, lng: 19.190672 },
    { lat: 48.885388, lng: 19.191308 },
    { lat: 48.885479, lng: 19.191716 },
    { lat: 48.885524, lng: 19.192066 },
    { lat: 48.885786, lng: 19.192995 },
    { lat: 48.885778, lng: 19.193359 },
    { lat: 48.885775, lng: 19.193458 },
    { lat: 48.88575, lng: 19.193747 },
    { lat: 48.885669, lng: 19.194338 },
    { lat: 48.885513, lng: 19.195017 },
    { lat: 48.88553, lng: 19.19522 },
    { lat: 48.885563, lng: 19.19563 },
    { lat: 48.886097, lng: 19.196645 },
    { lat: 48.886369, lng: 19.197443 },
    { lat: 48.886954, lng: 19.199911 },
    { lat: 48.887043, lng: 19.200847 },
    { lat: 48.887049, lng: 19.200907 },
    { lat: 48.887185, lng: 19.201127 },
    { lat: 48.887248, lng: 19.201228 },
    { lat: 48.88724, lng: 19.20225 },
    { lat: 48.887227, lng: 19.203784 },
    { lat: 48.88705, lng: 19.204069 },
    { lat: 48.88686, lng: 19.204574 },
    { lat: 48.886983, lng: 19.204857 },
    { lat: 48.887392, lng: 19.205376 },
    { lat: 48.887735, lng: 19.205811 },
    { lat: 48.888544, lng: 19.207132 },
    { lat: 48.888701, lng: 19.207714 },
    { lat: 48.888713, lng: 19.208002 },
    { lat: 48.888836, lng: 19.208317 },
    { lat: 48.889702, lng: 19.209643 },
    { lat: 48.891004, lng: 19.211912 },
    { lat: 48.89146, lng: 19.212799 },
    { lat: 48.891339, lng: 19.21329 },
    { lat: 48.890965, lng: 19.214798 },
    { lat: 48.890834, lng: 19.215723 },
    { lat: 48.8908, lng: 19.218276 },
    { lat: 48.890819, lng: 19.222733 },
    { lat: 48.8942007, lng: 19.2241148 },
    { lat: 48.8957036, lng: 19.2250068 },
    { lat: 48.8966583, lng: 19.2256123 },
    { lat: 48.8978703, lng: 19.2256426 },
    { lat: 48.8989355, lng: 19.2265071 },
    { lat: 48.8993582, lng: 19.2274138 },
    { lat: 48.9003271, lng: 19.2284549 },
    { lat: 48.9018048, lng: 19.2297351 },
    { lat: 48.9031395, lng: 19.2313283 },
    { lat: 48.9033589, lng: 19.2317378 },
    { lat: 48.9042526, lng: 19.2320841 },
    { lat: 48.9052504, lng: 19.2323455 },
    { lat: 48.907061, lng: 19.2327996 },
    { lat: 48.9081182, lng: 19.2326136 },
    { lat: 48.9101652, lng: 19.2300949 },
    { lat: 48.911208, lng: 19.2294009 },
    { lat: 48.9119978, lng: 19.2289941 },
    { lat: 48.9134667, lng: 19.2291597 },
    { lat: 48.9142663, lng: 19.2291041 },
    { lat: 48.9159831, lng: 19.2281974 },
    { lat: 48.917462, lng: 19.2307401 },
    { lat: 48.9179084, lng: 19.2308087 },
    { lat: 48.9179434, lng: 19.2308113 },
    { lat: 48.9184704, lng: 19.2308949 },
    { lat: 48.9191946, lng: 19.2319125 },
    { lat: 48.9199177, lng: 19.2323894 },
    { lat: 48.9204355, lng: 19.2326537 },
    { lat: 48.9208305, lng: 19.2333409 },
    { lat: 48.9217714, lng: 19.2338467 },
    { lat: 48.9223168, lng: 19.2354129 },
    { lat: 48.9236767, lng: 19.2367772 },
    { lat: 48.9248019, lng: 19.2374633 },
    { lat: 48.9250869, lng: 19.237647 },
    { lat: 48.9254357, lng: 19.2378667 },
    { lat: 48.9262219, lng: 19.2390934 },
    { lat: 48.9263085, lng: 19.239439 },
    { lat: 48.9263297, lng: 19.2397414 },
    { lat: 48.9263708, lng: 19.2398881 },
    { lat: 48.9264616, lng: 19.240254 },
    { lat: 48.927027, lng: 19.2409235 },
    { lat: 48.9281353, lng: 19.2419342 },
    { lat: 48.9287411, lng: 19.2424577 },
    { lat: 48.9295184, lng: 19.243012 },
    { lat: 48.9304734, lng: 19.2428078 },
    { lat: 48.9312298, lng: 19.2420606 },
    { lat: 48.9320683, lng: 19.2414972 },
    { lat: 48.9328856, lng: 19.240873 },
    { lat: 48.9330481, lng: 19.2401269 },
    { lat: 48.9335459, lng: 19.2400723 },
    { lat: 48.9338588, lng: 19.2404627 },
    { lat: 48.9347923, lng: 19.2418906 },
    { lat: 48.9348285, lng: 19.2419396 },
    { lat: 48.9354486, lng: 19.2415008 },
    { lat: 48.9361588, lng: 19.2410104 },
    { lat: 48.9374397, lng: 19.2423983 },
    { lat: 48.939975, lng: 19.2436743 },
    { lat: 48.9400375, lng: 19.2437075 },
    { lat: 48.941552, lng: 19.242297 },
    { lat: 48.9418773, lng: 19.241983 },
    { lat: 48.9420749, lng: 19.2410758 },
    { lat: 48.9422256, lng: 19.2407428 },
    { lat: 48.9424714, lng: 19.2403931 },
    { lat: 48.9427129, lng: 19.2396761 },
    { lat: 48.9430581, lng: 19.2391443 },
    { lat: 48.9433768, lng: 19.2389508 },
    { lat: 48.9441438, lng: 19.2390108 },
    { lat: 48.9443296, lng: 19.2390045 },
    { lat: 48.9444236, lng: 19.2388528 },
    { lat: 48.9448404, lng: 19.2381209 },
    { lat: 48.9448027, lng: 19.2378529 },
    { lat: 48.9448409, lng: 19.2373129 },
    { lat: 48.9450181, lng: 19.236315 },
    { lat: 48.9455796, lng: 19.2361996 },
    { lat: 48.9462375, lng: 19.2356344 },
    { lat: 48.9473099, lng: 19.2347539 },
    { lat: 48.9479713, lng: 19.2339337 },
    { lat: 48.9483361, lng: 19.2332025 },
    { lat: 48.9483579, lng: 19.2322749 },
    { lat: 48.9489066, lng: 19.2313155 },
    { lat: 48.9495633, lng: 19.2305837 },
    { lat: 48.9498856, lng: 19.2299026 },
    { lat: 48.950617, lng: 19.2288282 },
    { lat: 48.9507042, lng: 19.2285076 },
    { lat: 48.9511816, lng: 19.2283368 },
    { lat: 48.9512795, lng: 19.2282857 },
    { lat: 48.9525844, lng: 19.2286752 },
    { lat: 48.9530155, lng: 19.2284077 },
    { lat: 48.95309, lng: 19.2283854 },
    { lat: 48.9534385, lng: 19.2281766 },
    { lat: 48.954273, lng: 19.2260663 },
    { lat: 48.9546611, lng: 19.2256999 },
    { lat: 48.9559994, lng: 19.2247976 },
    { lat: 48.9563818, lng: 19.2240338 },
    { lat: 48.956554, lng: 19.2232822 },
    { lat: 48.9567331, lng: 19.2224875 },
    { lat: 48.957713, lng: 19.2209127 },
    { lat: 48.9582284, lng: 19.2201027 },
    { lat: 48.9583968, lng: 19.2190868 },
    { lat: 48.9594052, lng: 19.2167716 },
    { lat: 48.9607706, lng: 19.2152906 },
    { lat: 48.9638886, lng: 19.2130611 },
    { lat: 48.9644112, lng: 19.2115194 },
    { lat: 48.9647848, lng: 19.2104097 },
    { lat: 48.9647859, lng: 19.2099651 },
    { lat: 48.964763, lng: 19.2083559 },
    { lat: 48.9651416, lng: 19.2066566 },
    { lat: 48.9653694, lng: 19.2057035 },
    { lat: 48.965929, lng: 19.2036689 },
    { lat: 48.966093, lng: 19.2027063 },
    { lat: 48.9662389, lng: 19.2018075 },
    { lat: 48.9664349, lng: 19.2005511 },
    { lat: 48.9671598, lng: 19.1984886 },
    { lat: 48.9671017, lng: 19.1972237 },
    { lat: 48.9670127, lng: 19.1954837 },
    { lat: 48.9675598, lng: 19.1921007 },
    { lat: 48.9685571, lng: 19.1895709 },
    { lat: 48.9687112, lng: 19.1891708 },
    { lat: 48.9691802, lng: 19.18778 },
    { lat: 48.9674604, lng: 19.1859578 },
    { lat: 48.9662191, lng: 19.1849767 },
    { lat: 48.965909, lng: 19.1843755 },
    { lat: 48.9654223, lng: 19.1839602 },
    { lat: 48.964545, lng: 19.1825419 },
    { lat: 48.9637454, lng: 19.1816287 },
    { lat: 48.9632964, lng: 19.1812504 },
    { lat: 48.9626811, lng: 19.1808869 },
    { lat: 48.9606663, lng: 19.1791601 },
    { lat: 48.9603916, lng: 19.1768346 },
    { lat: 48.9597426, lng: 19.1761912 },
    { lat: 48.9583, lng: 19.1746685 },
    { lat: 48.9580004, lng: 19.1741176 },
    { lat: 48.9570237, lng: 19.1723274 },
    { lat: 48.9550252, lng: 19.1712865 },
    { lat: 48.9527467, lng: 19.1685541 },
    { lat: 48.9521338, lng: 19.166969 },
    { lat: 48.9522546, lng: 19.1657715 },
    { lat: 48.9519964, lng: 19.1634844 },
    { lat: 48.951223, lng: 19.1623998 },
    { lat: 48.9509602, lng: 19.1609188 },
    { lat: 48.9504052, lng: 19.1602199 },
    { lat: 48.9504484, lng: 19.159534 },
    { lat: 48.9505251, lng: 19.1573247 },
    { lat: 48.9490352, lng: 19.1561095 },
    { lat: 48.9482742, lng: 19.1557348 },
    { lat: 48.9456604, lng: 19.1535753 },
    { lat: 48.9445632, lng: 19.1526731 },
    { lat: 48.9435543, lng: 19.1520013 },
    { lat: 48.9398776, lng: 19.1479351 },
    { lat: 48.9380199, lng: 19.1468573 },
    { lat: 48.9366774, lng: 19.1457078 },
    { lat: 48.9361974, lng: 19.1455046 },
    { lat: 48.9357161, lng: 19.1448612 },
    { lat: 48.9350485, lng: 19.1448793 },
    { lat: 48.9339594, lng: 19.1428959 },
    { lat: 48.9329914, lng: 19.1414081 },
    { lat: 48.9329606, lng: 19.1412807 },
    { lat: 48.9317292, lng: 19.1361817 },
    { lat: 48.930548, lng: 19.1318949 },
    { lat: 48.93049, lng: 19.1313795 },
    { lat: 48.9302257, lng: 19.1289386 },
    { lat: 48.9302218, lng: 19.1275964 },
    { lat: 48.9302132, lng: 19.126529 },
    { lat: 48.9302043, lng: 19.1238534 },
    { lat: 48.930561, lng: 19.122611 },
    { lat: 48.930674, lng: 19.121087 },
    { lat: 48.931171, lng: 19.120043 },
    { lat: 48.932096, lng: 19.1183 },
    { lat: 48.932802, lng: 19.117543 },
    { lat: 48.933902, lng: 19.11703 },
    { lat: 48.932044, lng: 19.116109 },
    { lat: 48.931186, lng: 19.11502 },
    { lat: 48.930797, lng: 19.11475 },
    { lat: 48.929735, lng: 19.11363 },
    { lat: 48.928737, lng: 19.112095 },
    { lat: 48.926729, lng: 19.107685 },
    { lat: 48.926288, lng: 19.10549 },
    { lat: 48.926171, lng: 19.104254 },
  ],
  LiptovskéSliače: [
    { lat: 49.0723789, lng: 19.4099686 },
    { lat: 49.0725655, lng: 19.4085458 },
    { lat: 49.0727328, lng: 19.4076375 },
    { lat: 49.0730703, lng: 19.4067833 },
    { lat: 49.0736015, lng: 19.4054373 },
    { lat: 49.0736096, lng: 19.4052481 },
    { lat: 49.0736114, lng: 19.4051893 },
    { lat: 49.0743838, lng: 19.4044308 },
    { lat: 49.0746306, lng: 19.4041622 },
    { lat: 49.0747826, lng: 19.4039894 },
    { lat: 49.0750675, lng: 19.4036006 },
    { lat: 49.0753077, lng: 19.4034093 },
    { lat: 49.0753297, lng: 19.4033509 },
    { lat: 49.0755864, lng: 19.4034424 },
    { lat: 49.0757217, lng: 19.403224 },
    { lat: 49.0758677, lng: 19.4031906 },
    { lat: 49.0758937, lng: 19.4030746 },
    { lat: 49.0760844, lng: 19.4028588 },
    { lat: 49.0761768, lng: 19.4027395 },
    { lat: 49.0762339, lng: 19.4026111 },
    { lat: 49.0762888, lng: 19.4026555 },
    { lat: 49.076393, lng: 19.4025447 },
    { lat: 49.0765347, lng: 19.4023935 },
    { lat: 49.076753, lng: 19.4022124 },
    { lat: 49.0769699, lng: 19.4019636 },
    { lat: 49.0771915, lng: 19.4017365 },
    { lat: 49.0776578, lng: 19.4006167 },
    { lat: 49.0779013, lng: 19.4005982 },
    { lat: 49.0779551, lng: 19.4003027 },
    { lat: 49.0781828, lng: 19.3999416 },
    { lat: 49.0784421, lng: 19.3997025 },
    { lat: 49.078512, lng: 19.3994606 },
    { lat: 49.078515, lng: 19.3994538 },
    { lat: 49.0785678, lng: 19.3993057 },
    { lat: 49.07868, lng: 19.3990531 },
    { lat: 49.0789636, lng: 19.3984925 },
    { lat: 49.0791144, lng: 19.3983432 },
    { lat: 49.0793649, lng: 19.398058 },
    { lat: 49.0796557, lng: 19.3979661 },
    { lat: 49.0797248, lng: 19.3977314 },
    { lat: 49.0797224, lng: 19.3976041 },
    { lat: 49.0798871, lng: 19.3973688 },
    { lat: 49.0799429, lng: 19.3972574 },
    { lat: 49.0800056, lng: 19.3971305 },
    { lat: 49.0801614, lng: 19.3967502 },
    { lat: 49.080273, lng: 19.3966759 },
    { lat: 49.0805436, lng: 19.3967056 },
    { lat: 49.0809314, lng: 19.3963487 },
    { lat: 49.0810392, lng: 19.3961235 },
    { lat: 49.0812186, lng: 19.3958589 },
    { lat: 49.0813093, lng: 19.3955611 },
    { lat: 49.0812521, lng: 19.3953739 },
    { lat: 49.0812771, lng: 19.3951853 },
    { lat: 49.0814484, lng: 19.3952081 },
    { lat: 49.0815283, lng: 19.395133 },
    { lat: 49.0816274, lng: 19.3950816 },
    { lat: 49.0818207, lng: 19.39512 },
    { lat: 49.0819675, lng: 19.3949117 },
    { lat: 49.082108, lng: 19.3949767 },
    { lat: 49.0821337, lng: 19.3948244 },
    { lat: 49.082385, lng: 19.3949212 },
    { lat: 49.0824664, lng: 19.3947822 },
    { lat: 49.0825395, lng: 19.3947294 },
    { lat: 49.0826494, lng: 19.3947678 },
    { lat: 49.082723, lng: 19.3947062 },
    { lat: 49.0827933, lng: 19.3945174 },
    { lat: 49.082866, lng: 19.3944862 },
    { lat: 49.082998, lng: 19.394318 },
    { lat: 49.0831285, lng: 19.3941712 },
    { lat: 49.0833529, lng: 19.3939549 },
    { lat: 49.0835676, lng: 19.3938249 },
    { lat: 49.0835786, lng: 19.3938618 },
    { lat: 49.0839743, lng: 19.3935784 },
    { lat: 49.0843152, lng: 19.393324 },
    { lat: 49.0848359, lng: 19.392747 },
    { lat: 49.0849766, lng: 19.3925545 },
    { lat: 49.0850397, lng: 19.3923844 },
    { lat: 49.0850888, lng: 19.3921303 },
    { lat: 49.0851751, lng: 19.3916895 },
    { lat: 49.0854804, lng: 19.3901184 },
    { lat: 49.0855739, lng: 19.38983 },
    { lat: 49.0857917, lng: 19.3891603 },
    { lat: 49.0859141, lng: 19.3889087 },
    { lat: 49.0859798, lng: 19.3888249 },
    { lat: 49.0866228, lng: 19.3883083 },
    { lat: 49.0866815, lng: 19.3882606 },
    { lat: 49.0866712, lng: 19.3882322 },
    { lat: 49.0866683, lng: 19.3882203 },
    { lat: 49.0866628, lng: 19.3881986 },
    { lat: 49.0866603, lng: 19.3881924 },
    { lat: 49.086636, lng: 19.3881318 },
    { lat: 49.0864735, lng: 19.3877165 },
    { lat: 49.0863506, lng: 19.3874009 },
    { lat: 49.0858092, lng: 19.3860204 },
    { lat: 49.0848595, lng: 19.383596 },
    { lat: 49.0848005, lng: 19.3836326 },
    { lat: 49.0844489, lng: 19.3822355 },
    { lat: 49.0844726, lng: 19.3818806 },
    { lat: 49.0845906, lng: 19.3801838 },
    { lat: 49.0842617, lng: 19.3789418 },
    { lat: 49.0838721, lng: 19.378992 },
    { lat: 49.0837436, lng: 19.3782952 },
    { lat: 49.0839063, lng: 19.3781847 },
    { lat: 49.0839221, lng: 19.3781841 },
    { lat: 49.0840954, lng: 19.3780829 },
    { lat: 49.0857686, lng: 19.3766334 },
    { lat: 49.0858279, lng: 19.3765802 },
    { lat: 49.0873336, lng: 19.3753056 },
    { lat: 49.0876332, lng: 19.3749217 },
    { lat: 49.0878461, lng: 19.3743894 },
    { lat: 49.0881318, lng: 19.3734188 },
    { lat: 49.0881303, lng: 19.3729534 },
    { lat: 49.087866, lng: 19.3720753 },
    { lat: 49.0867582, lng: 19.369651 },
    { lat: 49.0867157, lng: 19.3695844 },
    { lat: 49.0866792, lng: 19.3694061 },
    { lat: 49.0865282, lng: 19.3694042 },
    { lat: 49.0862173, lng: 19.3695071 },
    { lat: 49.0861572, lng: 19.3694992 },
    { lat: 49.0860407, lng: 19.3696341 },
    { lat: 49.0859506, lng: 19.3696116 },
    { lat: 49.0858532, lng: 19.3696952 },
    { lat: 49.0857325, lng: 19.3697648 },
    { lat: 49.0856177, lng: 19.3696719 },
    { lat: 49.0855967, lng: 19.3695631 },
    { lat: 49.0855501, lng: 19.3695056 },
    { lat: 49.0854732, lng: 19.3694964 },
    { lat: 49.0854259, lng: 19.3695705 },
    { lat: 49.0853719, lng: 19.3695987 },
    { lat: 49.0852869, lng: 19.3697284 },
    { lat: 49.0851595, lng: 19.3695848 },
    { lat: 49.0851425, lng: 19.3696057 },
    { lat: 49.0851049, lng: 19.3696242 },
    { lat: 49.0850972, lng: 19.3696523 },
    { lat: 49.0850817, lng: 19.3696594 },
    { lat: 49.0849813, lng: 19.3696524 },
    { lat: 49.0849748, lng: 19.3696355 },
    { lat: 49.0849408, lng: 19.3696295 },
    { lat: 49.0848918, lng: 19.369597 },
    { lat: 49.084883, lng: 19.3695052 },
    { lat: 49.0848628, lng: 19.3695074 },
    { lat: 49.0848109, lng: 19.3695807 },
    { lat: 49.084771, lng: 19.369374 },
    { lat: 49.0847074, lng: 19.3693881 },
    { lat: 49.084717, lng: 19.3694517 },
    { lat: 49.0846794, lng: 19.3694648 },
    { lat: 49.0846036, lng: 19.3694601 },
    { lat: 49.0845739, lng: 19.3695216 },
    { lat: 49.0845266, lng: 19.3695242 },
    { lat: 49.0844617, lng: 19.369486 },
    { lat: 49.0844231, lng: 19.3694327 },
    { lat: 49.0843409, lng: 19.3694571 },
    { lat: 49.0842617, lng: 19.3694502 },
    { lat: 49.0842561, lng: 19.3694721 },
    { lat: 49.0841649, lng: 19.3695266 },
    { lat: 49.0842455, lng: 19.3695829 },
    { lat: 49.0842215, lng: 19.3696768 },
    { lat: 49.0841888, lng: 19.3696987 },
    { lat: 49.0840884, lng: 19.369578 },
    { lat: 49.0840115, lng: 19.3696385 },
    { lat: 49.0839499, lng: 19.3695295 },
    { lat: 49.0838829, lng: 19.3694961 },
    { lat: 49.0838117, lng: 19.3695636 },
    { lat: 49.0838228, lng: 19.3696091 },
    { lat: 49.0838691, lng: 19.369704 },
    { lat: 49.08387, lng: 19.3697481 },
    { lat: 49.0838653, lng: 19.3697699 },
    { lat: 49.0838311, lng: 19.3697851 },
    { lat: 49.0838218, lng: 19.3697793 },
    { lat: 49.08379, lng: 19.3696993 },
    { lat: 49.083774, lng: 19.3696252 },
    { lat: 49.0837671, lng: 19.3696188 },
    { lat: 49.0837195, lng: 19.369638 },
    { lat: 49.0836686, lng: 19.369705 },
    { lat: 49.0836539, lng: 19.369703 },
    { lat: 49.083627, lng: 19.3696585 },
    { lat: 49.083549, lng: 19.369682 },
    { lat: 49.0835296, lng: 19.3697474 },
    { lat: 49.0835243, lng: 19.3697556 },
    { lat: 49.0834718, lng: 19.3697638 },
    { lat: 49.0834328, lng: 19.369823 },
    { lat: 49.0834524, lng: 19.3698774 },
    { lat: 49.0834501, lng: 19.3700193 },
    { lat: 49.0833892, lng: 19.3700205 },
    { lat: 49.0833184, lng: 19.3700472 },
    { lat: 49.0832797, lng: 19.3700881 },
    { lat: 49.0832559, lng: 19.3700832 },
    { lat: 49.0830895, lng: 19.3701412 },
    { lat: 49.0830258, lng: 19.3701514 },
    { lat: 49.0829682, lng: 19.3702932 },
    { lat: 49.0829647, lng: 19.3704604 },
    { lat: 49.082885, lng: 19.3707541 },
    { lat: 49.0828578, lng: 19.3707995 },
    { lat: 49.0828541, lng: 19.3708157 },
    { lat: 49.0828519, lng: 19.3708447 },
    { lat: 49.0828458, lng: 19.3708565 },
    { lat: 49.0828039, lng: 19.370873 },
    { lat: 49.0827831, lng: 19.3709381 },
    { lat: 49.0827204, lng: 19.3710169 },
    { lat: 49.0827129, lng: 19.3710475 },
    { lat: 49.0826521, lng: 19.3710498 },
    { lat: 49.0826203, lng: 19.3710917 },
    { lat: 49.0826086, lng: 19.3711358 },
    { lat: 49.0826226, lng: 19.3712371 },
    { lat: 49.0826179, lng: 19.3713077 },
    { lat: 49.0825812, lng: 19.3714113 },
    { lat: 49.082494, lng: 19.3714046 },
    { lat: 49.082455, lng: 19.3714153 },
    { lat: 49.0824075, lng: 19.371459 },
    { lat: 49.082388, lng: 19.3714503 },
    { lat: 49.0823769, lng: 19.3714558 },
    { lat: 49.0823586, lng: 19.3714488 },
    { lat: 49.0823121, lng: 19.3714636 },
    { lat: 49.0821838, lng: 19.3716638 },
    { lat: 49.0821501, lng: 19.3716908 },
    { lat: 49.082144, lng: 19.3717012 },
    { lat: 49.0821327, lng: 19.3717481 },
    { lat: 49.0820962, lng: 19.3717908 },
    { lat: 49.0820695, lng: 19.3718452 },
    { lat: 49.0820286, lng: 19.371814 },
    { lat: 49.0819727, lng: 19.3718014 },
    { lat: 49.0819415, lng: 19.3718293 },
    { lat: 49.0819338, lng: 19.3718563 },
    { lat: 49.0819342, lng: 19.3718901 },
    { lat: 49.0819493, lng: 19.3719258 },
    { lat: 49.0819675, lng: 19.3719271 },
    { lat: 49.0819793, lng: 19.3719165 },
    { lat: 49.0819899, lng: 19.3719247 },
    { lat: 49.0819853, lng: 19.3719489 },
    { lat: 49.0819158, lng: 19.3720783 },
    { lat: 49.081918, lng: 19.3721449 },
    { lat: 49.0819026, lng: 19.3722232 },
    { lat: 49.0818534, lng: 19.3722127 },
    { lat: 49.0817656, lng: 19.372163 },
    { lat: 49.081755, lng: 19.3721781 },
    { lat: 49.0817394, lng: 19.3722539 },
    { lat: 49.081745, lng: 19.3723015 },
    { lat: 49.0818074, lng: 19.3723083 },
    { lat: 49.0818185, lng: 19.3723294 },
    { lat: 49.0818209, lng: 19.3723802 },
    { lat: 49.0817897, lng: 19.3724152 },
    { lat: 49.0817613, lng: 19.3724794 },
    { lat: 49.0817345, lng: 19.3724859 },
    { lat: 49.0817216, lng: 19.3724978 },
    { lat: 49.080966, lng: 19.3715496 },
    { lat: 49.0802922, lng: 19.3732126 },
    { lat: 49.0793637, lng: 19.3718706 },
    { lat: 49.0788383, lng: 19.372248 },
    { lat: 49.0788787, lng: 19.3723644 },
    { lat: 49.0784661, lng: 19.3725556 },
    { lat: 49.0773629, lng: 19.3727489 },
    { lat: 49.0772367, lng: 19.3724589 },
    { lat: 49.0770544, lng: 19.3715991 },
    { lat: 49.0769248, lng: 19.3712882 },
    { lat: 49.0768181, lng: 19.3709565 },
    { lat: 49.0767129, lng: 19.3707339 },
    { lat: 49.0766556, lng: 19.3704476 },
    { lat: 49.0766181, lng: 19.3701774 },
    { lat: 49.0765441, lng: 19.3699697 },
    { lat: 49.0766444, lng: 19.3698783 },
    { lat: 49.0765451, lng: 19.3697744 },
    { lat: 49.0763639, lng: 19.3693951 },
    { lat: 49.0762066, lng: 19.3691692 },
    { lat: 49.0760784, lng: 19.3689089 },
    { lat: 49.0758908, lng: 19.3687801 },
    { lat: 49.0756064, lng: 19.3687237 },
    { lat: 49.0754754, lng: 19.3687912 },
    { lat: 49.0751748, lng: 19.3689871 },
    { lat: 49.0749972, lng: 19.3690675 },
    { lat: 49.0749182, lng: 19.3690636 },
    { lat: 49.0746456, lng: 19.3692579 },
    { lat: 49.074523, lng: 19.3691677 },
    { lat: 49.0738781, lng: 19.369378 },
    { lat: 49.0736637, lng: 19.3692533 },
    { lat: 49.0732006, lng: 19.369184 },
    { lat: 49.0730066, lng: 19.3691806 },
    { lat: 49.0729454, lng: 19.3690539 },
    { lat: 49.0729643, lng: 19.3683765 },
    { lat: 49.072904, lng: 19.368383 },
    { lat: 49.0728509, lng: 19.36841 },
    { lat: 49.0727959, lng: 19.3684613 },
    { lat: 49.0727261, lng: 19.3684125 },
    { lat: 49.0726555, lng: 19.3684234 },
    { lat: 49.0725765, lng: 19.3683245 },
    { lat: 49.0724936, lng: 19.3682393 },
    { lat: 49.0723091, lng: 19.3679805 },
    { lat: 49.0722534, lng: 19.3679966 },
    { lat: 49.0722403, lng: 19.3680498 },
    { lat: 49.0721649, lng: 19.3680285 },
    { lat: 49.0721316, lng: 19.3679232 },
    { lat: 49.0721124, lng: 19.3679451 },
    { lat: 49.0720101, lng: 19.3679757 },
    { lat: 49.0719206, lng: 19.3679146 },
    { lat: 49.0718491, lng: 19.3679515 },
    { lat: 49.0717883, lng: 19.3679027 },
    { lat: 49.0717701, lng: 19.3679292 },
    { lat: 49.0716704, lng: 19.3678926 },
    { lat: 49.0716365, lng: 19.3679347 },
    { lat: 49.0716117, lng: 19.3679148 },
    { lat: 49.0715849, lng: 19.3678724 },
    { lat: 49.0714634, lng: 19.3678748 },
    { lat: 49.0713248, lng: 19.3678288 },
    { lat: 49.0712654, lng: 19.3678813 },
    { lat: 49.0711932, lng: 19.3678579 },
    { lat: 49.0711027, lng: 19.3679234 },
    { lat: 49.0710242, lng: 19.3679316 },
    { lat: 49.0709805, lng: 19.367885 },
    { lat: 49.0709693, lng: 19.3677917 },
    { lat: 49.0709297, lng: 19.3677388 },
    { lat: 49.0708179, lng: 19.3678078 },
    { lat: 49.0706147, lng: 19.3677548 },
    { lat: 49.0705578, lng: 19.3676945 },
    { lat: 49.0704997, lng: 19.3677296 },
    { lat: 49.0704572, lng: 19.3677845 },
    { lat: 49.0704265, lng: 19.3677289 },
    { lat: 49.0703755, lng: 19.367769 },
    { lat: 49.0703224, lng: 19.3676957 },
    { lat: 49.07028, lng: 19.3677279 },
    { lat: 49.0701782, lng: 19.367697 },
    { lat: 49.0701476, lng: 19.3678157 },
    { lat: 49.0701414, lng: 19.367899 },
    { lat: 49.0700997, lng: 19.367946 },
    { lat: 49.0700721, lng: 19.3678864 },
    { lat: 49.0700574, lng: 19.3679056 },
    { lat: 49.0698862, lng: 19.3679117 },
    { lat: 49.0698491, lng: 19.3679865 },
    { lat: 49.0696365, lng: 19.3679684 },
    { lat: 49.0696398, lng: 19.3680848 },
    { lat: 49.0695398, lng: 19.3681888 },
    { lat: 49.0694734, lng: 19.3682887 },
    { lat: 49.0693402, lng: 19.3682324 },
    { lat: 49.0693196, lng: 19.3684169 },
    { lat: 49.0691413, lng: 19.3684129 },
    { lat: 49.068998, lng: 19.3684325 },
    { lat: 49.06884, lng: 19.3684076 },
    { lat: 49.0688111, lng: 19.3683662 },
    { lat: 49.0687829, lng: 19.3683639 },
    { lat: 49.0686829, lng: 19.3682515 },
    { lat: 49.0686169, lng: 19.368309 },
    { lat: 49.0685429, lng: 19.368271 },
    { lat: 49.0684542, lng: 19.3683493 },
    { lat: 49.0684145, lng: 19.3684133 },
    { lat: 49.068354, lng: 19.3683009 },
    { lat: 49.0682712, lng: 19.3683642 },
    { lat: 49.0682359, lng: 19.3684266 },
    { lat: 49.0681096, lng: 19.368353 },
    { lat: 49.0680565, lng: 19.3683987 },
    { lat: 49.0679922, lng: 19.3684233 },
    { lat: 49.0678556, lng: 19.3686121 },
    { lat: 49.0677684, lng: 19.3687711 },
    { lat: 49.0676913, lng: 19.368759 },
    { lat: 49.0675753, lng: 19.3688777 },
    { lat: 49.0675436, lng: 19.3689476 },
    { lat: 49.0673972, lng: 19.3690933 },
    { lat: 49.0673506, lng: 19.3689916 },
    { lat: 49.0673162, lng: 19.3689767 },
    { lat: 49.0672711, lng: 19.3690276 },
    { lat: 49.0671867, lng: 19.3690062 },
    { lat: 49.0671532, lng: 19.3690368 },
    { lat: 49.067103, lng: 19.3690243 },
    { lat: 49.067046, lng: 19.368936 },
    { lat: 49.066865, lng: 19.3689449 },
    { lat: 49.0668056, lng: 19.3689769 },
    { lat: 49.0666444, lng: 19.3689729 },
    { lat: 49.0666171, lng: 19.3689952 },
    { lat: 49.0663484, lng: 19.3692232 },
    { lat: 49.0663125, lng: 19.3692537 },
    { lat: 49.0660614, lng: 19.3692814 },
    { lat: 49.065862, lng: 19.3693038 },
    { lat: 49.065862, lng: 19.3693049 },
    { lat: 49.0658625, lng: 19.369316 },
    { lat: 49.0650927, lng: 19.3696028 },
    { lat: 49.0647051, lng: 19.3696769 },
    { lat: 49.0645346, lng: 19.3697174 },
    { lat: 49.0642849, lng: 19.3697758 },
    { lat: 49.0640532, lng: 19.3698305 },
    { lat: 49.0637911, lng: 19.3698921 },
    { lat: 49.0637802, lng: 19.3698947 },
    { lat: 49.0630775, lng: 19.3704092 },
    { lat: 49.0625698, lng: 19.3707817 },
    { lat: 49.0625709, lng: 19.3707601 },
    { lat: 49.0625781, lng: 19.370723 },
    { lat: 49.0622703, lng: 19.3710084 },
    { lat: 49.0616846, lng: 19.3714688 },
    { lat: 49.0611173, lng: 19.3719154 },
    { lat: 49.0609876, lng: 19.3728961 },
    { lat: 49.0609784, lng: 19.3729654 },
    { lat: 49.0609767, lng: 19.3729764 },
    { lat: 49.0609007, lng: 19.3729408 },
    { lat: 49.0608665, lng: 19.3751657 },
    { lat: 49.060788, lng: 19.3802951 },
    { lat: 49.0607868, lng: 19.3803645 },
    { lat: 49.0607865, lng: 19.3804048 },
    { lat: 49.060819, lng: 19.3809565 },
    { lat: 49.0608234, lng: 19.3810247 },
    { lat: 49.0608227, lng: 19.3810355 },
    { lat: 49.0608185, lng: 19.3810866 },
    { lat: 49.0589078, lng: 19.3811258 },
    { lat: 49.0581002, lng: 19.3806791 },
    { lat: 49.0580312, lng: 19.3806468 },
    { lat: 49.0576199, lng: 19.3804571 },
    { lat: 49.0575107, lng: 19.3804596 },
    { lat: 49.0574844, lng: 19.3804028 },
    { lat: 49.0574506, lng: 19.3803328 },
    { lat: 49.0574062, lng: 19.3802471 },
    { lat: 49.0572504, lng: 19.379955 },
    { lat: 49.0571235, lng: 19.3796274 },
    { lat: 49.0570814, lng: 19.3790908 },
    { lat: 49.0570381, lng: 19.378793 },
    { lat: 49.0568744, lng: 19.3783019 },
    { lat: 49.0567595, lng: 19.378018 },
    { lat: 49.0564856, lng: 19.3775601 },
    { lat: 49.0561915, lng: 19.3770804 },
    { lat: 49.0560843, lng: 19.3768135 },
    { lat: 49.056005, lng: 19.3766123 },
    { lat: 49.0558878, lng: 19.3764659 },
    { lat: 49.0555176, lng: 19.3762416 },
    { lat: 49.0553702, lng: 19.3761707 },
    { lat: 49.055309, lng: 19.3761378 },
    { lat: 49.0551564, lng: 19.3761069 },
    { lat: 49.0551017, lng: 19.3760938 },
    { lat: 49.0550995, lng: 19.3760937 },
    { lat: 49.0550544, lng: 19.3760939 },
    { lat: 49.054998, lng: 19.3760936 },
    { lat: 49.0548976, lng: 19.3760934 },
    { lat: 49.0547049, lng: 19.3760927 },
    { lat: 49.0542661, lng: 19.3759169 },
    { lat: 49.0536699, lng: 19.3755555 },
    { lat: 49.0533243, lng: 19.3753466 },
    { lat: 49.0531505, lng: 19.3752411 },
    { lat: 49.0529021, lng: 19.3750903 },
    { lat: 49.0528326, lng: 19.3750486 },
    { lat: 49.0524012, lng: 19.3747876 },
    { lat: 49.0523987, lng: 19.374786 },
    { lat: 49.0523077, lng: 19.3747179 },
    { lat: 49.0522939, lng: 19.3747608 },
    { lat: 49.0522772, lng: 19.3748129 },
    { lat: 49.0522604, lng: 19.3748632 },
    { lat: 49.0517135, lng: 19.3765391 },
    { lat: 49.0507898, lng: 19.3761835 },
    { lat: 49.0506099, lng: 19.3779732 },
    { lat: 49.0505719, lng: 19.3788435 },
    { lat: 49.0505684, lng: 19.3789158 },
    { lat: 49.0505643, lng: 19.3789935 },
    { lat: 49.0505641, lng: 19.3790154 },
    { lat: 49.0505503, lng: 19.3793205 },
    { lat: 49.050547, lng: 19.3793952 },
    { lat: 49.0505472, lng: 19.3793991 },
    { lat: 49.0505413, lng: 19.3793948 },
    { lat: 49.0504431, lng: 19.3793145 },
    { lat: 49.0485217, lng: 19.3777332 },
    { lat: 49.0484773, lng: 19.3776963 },
    { lat: 49.0484701, lng: 19.3776903 },
    { lat: 49.0484549, lng: 19.3776779 },
    { lat: 49.0484433, lng: 19.377672 },
    { lat: 49.0484341, lng: 19.3776683 },
    { lat: 49.0483568, lng: 19.3776315 },
    { lat: 49.0483419, lng: 19.3776241 },
    { lat: 49.0482762, lng: 19.3775932 },
    { lat: 49.0482636, lng: 19.377587 },
    { lat: 49.0480965, lng: 19.3775081 },
    { lat: 49.0476784, lng: 19.3775564 },
    { lat: 49.0476312, lng: 19.3775619 },
    { lat: 49.0474553, lng: 19.3775824 },
    { lat: 49.0470257, lng: 19.3776262 },
    { lat: 49.0469462, lng: 19.3776344 },
    { lat: 49.0468205, lng: 19.3776474 },
    { lat: 49.0466687, lng: 19.3776283 },
    { lat: 49.0466262, lng: 19.3776318 },
    { lat: 49.0465076, lng: 19.3776081 },
    { lat: 49.0463834, lng: 19.3775062 },
    { lat: 49.0462696, lng: 19.3774115 },
    { lat: 49.0462111, lng: 19.3773629 },
    { lat: 49.0461969, lng: 19.3773519 },
    { lat: 49.0462583, lng: 19.3771954 },
    { lat: 49.0454679, lng: 19.377186 },
    { lat: 49.0454806, lng: 19.3777429 },
    { lat: 49.0437572, lng: 19.3779045 },
    { lat: 49.0437134, lng: 19.377882 },
    { lat: 49.0431372, lng: 19.3775907 },
    { lat: 49.0430842, lng: 19.3775641 },
    { lat: 49.0429136, lng: 19.3774615 },
    { lat: 49.0422194, lng: 19.377242 },
    { lat: 49.0411631, lng: 19.3773329 },
    { lat: 49.040993, lng: 19.3773349 },
    { lat: 49.0406427, lng: 19.3768804 },
    { lat: 49.0405067, lng: 19.3764778 },
    { lat: 49.0402905, lng: 19.3761733 },
    { lat: 49.0395997, lng: 19.3748755 },
    { lat: 49.0373543, lng: 19.3730418 },
    { lat: 49.0358923, lng: 19.3717366 },
    { lat: 49.0360523, lng: 19.3712117 },
    { lat: 49.0341315, lng: 19.3700257 },
    { lat: 49.0332877, lng: 19.3696229 },
    { lat: 49.0324035, lng: 19.3692711 },
    { lat: 49.031862, lng: 19.3695983 },
    { lat: 49.0307551, lng: 19.370406 },
    { lat: 49.0299188, lng: 19.3706459 },
    { lat: 49.0288378, lng: 19.3708334 },
    { lat: 49.0283274, lng: 19.3711938 },
    { lat: 49.0276628, lng: 19.3712031 },
    { lat: 49.0264458, lng: 19.3713709 },
    { lat: 49.0260084, lng: 19.3715731 },
    { lat: 49.0258361, lng: 19.3716583 },
    { lat: 49.0250805, lng: 19.3720525 },
    { lat: 49.0246173, lng: 19.3722468 },
    { lat: 49.0235442, lng: 19.3726029 },
    { lat: 49.0217378, lng: 19.373059 },
    { lat: 49.0207263, lng: 19.3731689 },
    { lat: 49.0205412, lng: 19.3731595 },
    { lat: 49.0185963, lng: 19.3726393 },
    { lat: 49.0171996, lng: 19.3721246 },
    { lat: 49.0157395, lng: 19.3719895 },
    { lat: 49.0148816, lng: 19.3716023 },
    { lat: 49.0147025, lng: 19.3715561 },
    { lat: 49.0145106, lng: 19.3715722 },
    { lat: 49.0142059, lng: 19.3713716 },
    { lat: 49.0134865, lng: 19.3711927 },
    { lat: 49.0133115, lng: 19.3709963 },
    { lat: 49.0124553, lng: 19.3706937 },
    { lat: 49.0117818, lng: 19.3708185 },
    { lat: 49.0112752, lng: 19.3707124 },
    { lat: 49.0107569, lng: 19.3705726 },
    { lat: 49.0100715, lng: 19.3699424 },
    { lat: 49.0099793, lng: 19.3699215 },
    { lat: 49.0098628, lng: 19.3699152 },
    { lat: 49.0092328, lng: 19.3702722 },
    { lat: 49.0089507, lng: 19.3705057 },
    { lat: 49.0086382, lng: 19.3706897 },
    { lat: 49.0081494, lng: 19.3708408 },
    { lat: 49.0081193, lng: 19.3708502 },
    { lat: 49.00744, lng: 19.370918 },
    { lat: 49.007527, lng: 19.371312 },
    { lat: 49.007748, lng: 19.372653 },
    { lat: 49.007808, lng: 19.373033 },
    { lat: 49.007839, lng: 19.373209 },
    { lat: 49.008115, lng: 19.37431 },
    { lat: 49.009614, lng: 19.376985 },
    { lat: 49.010373, lng: 19.377872 },
    { lat: 49.011746, lng: 19.37984 },
    { lat: 49.013172, lng: 19.382649 },
    { lat: 49.013635, lng: 19.384283 },
    { lat: 49.013819, lng: 19.384919 },
    { lat: 49.014299, lng: 19.38563 },
    { lat: 49.014637, lng: 19.385999 },
    { lat: 49.014833, lng: 19.386207 },
    { lat: 49.015673, lng: 19.386647 },
    { lat: 49.017555, lng: 19.387632 },
    { lat: 49.019363, lng: 19.388358 },
    { lat: 49.019927, lng: 19.388775 },
    { lat: 49.02057, lng: 19.389055 },
    { lat: 49.020968, lng: 19.389033 },
    { lat: 49.02144, lng: 19.389001 },
    { lat: 49.022152, lng: 19.389233 },
    { lat: 49.02287, lng: 19.390018 },
    { lat: 49.023233, lng: 19.391856 },
    { lat: 49.023578, lng: 19.392985 },
    { lat: 49.024628, lng: 19.394224 },
    { lat: 49.0258, lng: 19.395309 },
    { lat: 49.027207, lng: 19.396216 },
    { lat: 49.027519, lng: 19.396267 },
    { lat: 49.028804, lng: 19.396762 },
    { lat: 49.02913, lng: 19.397411 },
    { lat: 49.029999, lng: 19.397677 },
    { lat: 49.030233, lng: 19.398501 },
    { lat: 49.030872, lng: 19.400046 },
    { lat: 49.031514, lng: 19.400833 },
    { lat: 49.033187, lng: 19.402177 },
    { lat: 49.033948, lng: 19.402522 },
    { lat: 49.034555, lng: 19.402946 },
    { lat: 49.035128, lng: 19.403228 },
    { lat: 49.035987, lng: 19.403548 },
    { lat: 49.03676, lng: 19.404345 },
    { lat: 49.037203, lng: 19.404742 },
    { lat: 49.038123, lng: 19.405184 },
    { lat: 49.038968, lng: 19.405871 },
    { lat: 49.038971, lng: 19.405849 },
    { lat: 49.039166, lng: 19.405944 },
    { lat: 49.039257, lng: 19.405988 },
    { lat: 49.039724, lng: 19.406217 },
    { lat: 49.040344, lng: 19.406598 },
    { lat: 49.040587, lng: 19.40661 },
    { lat: 49.040921, lng: 19.4067 },
    { lat: 49.041217, lng: 19.406869 },
    { lat: 49.041482, lng: 19.40714 },
    { lat: 49.041471, lng: 19.407164 },
    { lat: 49.041474, lng: 19.407227 },
    { lat: 49.041841, lng: 19.407617 },
    { lat: 49.042685, lng: 19.408515 },
    { lat: 49.043067, lng: 19.408921 },
    { lat: 49.043415, lng: 19.40915 },
    { lat: 49.043871, lng: 19.40941 },
    { lat: 49.044623, lng: 19.409888 },
    { lat: 49.045425, lng: 19.410694 },
    { lat: 49.045699, lng: 19.411279 },
    { lat: 49.045968, lng: 19.411926 },
    { lat: 49.046356, lng: 19.413086 },
    { lat: 49.046727, lng: 19.413941 },
    { lat: 49.04708, lng: 19.415089 },
    { lat: 49.047592, lng: 19.41632 },
    { lat: 49.048299, lng: 19.417302 },
    { lat: 49.048857, lng: 19.417644 },
    { lat: 49.049389, lng: 19.418186 },
    { lat: 49.049507, lng: 19.418306 },
    { lat: 49.049561, lng: 19.418361 },
    { lat: 49.049596, lng: 19.418399 },
    { lat: 49.050023, lng: 19.419169 },
    { lat: 49.049995, lng: 19.41929 },
    { lat: 49.049992, lng: 19.4193 },
    { lat: 49.049894, lng: 19.419719 },
    { lat: 49.050154, lng: 19.419879 },
    { lat: 49.050063, lng: 19.420144 },
    { lat: 49.049906, lng: 19.420088 },
    { lat: 49.049872, lng: 19.42087 },
    { lat: 49.050652, lng: 19.42039 },
    { lat: 49.050605, lng: 19.420709 },
    { lat: 49.050812, lng: 19.420792 },
    { lat: 49.05074, lng: 19.421165 },
    { lat: 49.050708, lng: 19.421498 },
    { lat: 49.051049, lng: 19.421307 },
    { lat: 49.051148, lng: 19.421374 },
    { lat: 49.0512, lng: 19.421198 },
    { lat: 49.051414, lng: 19.421054 },
    { lat: 49.051825, lng: 19.421374 },
    { lat: 49.052165, lng: 19.421509 },
    { lat: 49.052451, lng: 19.42156 },
    { lat: 49.052808, lng: 19.421566 },
    { lat: 49.053072, lng: 19.4216 },
    { lat: 49.053302, lng: 19.421661 },
    { lat: 49.053443, lng: 19.421636 },
    { lat: 49.053781, lng: 19.42169 },
    { lat: 49.054098, lng: 19.421791 },
    { lat: 49.054341, lng: 19.421792 },
    { lat: 49.05448, lng: 19.421777 },
    { lat: 49.054756, lng: 19.421687 },
    { lat: 49.054885, lng: 19.421672 },
    { lat: 49.05515, lng: 19.421627 },
    { lat: 49.055437, lng: 19.421633 },
    { lat: 49.05628, lng: 19.421543 },
    { lat: 49.05636, lng: 19.421534 },
    { lat: 49.056434, lng: 19.421526 },
    { lat: 49.056596, lng: 19.421506 },
    { lat: 49.056878, lng: 19.421477 },
    { lat: 49.057287, lng: 19.421443 },
    { lat: 49.057476, lng: 19.421437 },
    { lat: 49.057818, lng: 19.421461 },
    { lat: 49.058093, lng: 19.421428 },
    { lat: 49.058449, lng: 19.421414 },
    { lat: 49.058522, lng: 19.421441 },
    { lat: 49.058768, lng: 19.42141 },
    { lat: 49.059307, lng: 19.421191 },
    { lat: 49.059436, lng: 19.421136 },
    { lat: 49.059561, lng: 19.421037 },
    { lat: 49.059673, lng: 19.420937 },
    { lat: 49.05995, lng: 19.420578 },
    { lat: 49.060551, lng: 19.420335 },
    { lat: 49.060662, lng: 19.420293 },
    { lat: 49.060897, lng: 19.420137 },
    { lat: 49.061101, lng: 19.420018 },
    { lat: 49.061346, lng: 19.419803 },
    { lat: 49.061697, lng: 19.419453 },
    { lat: 49.062003, lng: 19.419335 },
    { lat: 49.062364, lng: 19.419061 },
    { lat: 49.0625, lng: 19.418941 },
    { lat: 49.062644, lng: 19.41887 },
    { lat: 49.063187, lng: 19.418811 },
    { lat: 49.063606, lng: 19.418816 },
    { lat: 49.064032, lng: 19.418811 },
    { lat: 49.064466, lng: 19.418753 },
    { lat: 49.064799, lng: 19.418864 },
    { lat: 49.064983, lng: 19.418868 },
    { lat: 49.06542, lng: 19.418818 },
    { lat: 49.065454, lng: 19.418815 },
    { lat: 49.065612, lng: 19.418699 },
    { lat: 49.065791, lng: 19.418557 },
    { lat: 49.065976, lng: 19.418497 },
    { lat: 49.066186, lng: 19.418539 },
    { lat: 49.0668, lng: 19.418541 },
    { lat: 49.067042, lng: 19.418611 },
    { lat: 49.067153, lng: 19.418604 },
    { lat: 49.067263, lng: 19.417407 },
    { lat: 49.06727, lng: 19.417335 },
    { lat: 49.067766, lng: 19.41682 },
    { lat: 49.067821, lng: 19.416536 },
    { lat: 49.068011, lng: 19.416047 },
    { lat: 49.068377, lng: 19.415463 },
    { lat: 49.068379, lng: 19.415461 },
    { lat: 49.068426, lng: 19.415398 },
    { lat: 49.069103, lng: 19.41334 },
    { lat: 49.069183, lng: 19.413137 },
    { lat: 49.069478, lng: 19.412382 },
    { lat: 49.06961, lng: 19.412223 },
    { lat: 49.069943, lng: 19.411965 },
    { lat: 49.07002, lng: 19.411905 },
    { lat: 49.070159, lng: 19.411797 },
    { lat: 49.07024, lng: 19.411071 },
    { lat: 49.070394, lng: 19.411043 },
    { lat: 49.070398, lng: 19.410266 },
    { lat: 49.071217, lng: 19.410143 },
    { lat: 49.0723789, lng: 19.4099686 },
  ],
  LiptovskýMichal: [
    { lat: 49.094496, lng: 19.444731 },
    { lat: 49.094736, lng: 19.44456 },
    { lat: 49.094867, lng: 19.444518 },
    { lat: 49.094966, lng: 19.444527 },
    { lat: 49.0950128, lng: 19.4445492 },
    { lat: 49.095046, lng: 19.444565 },
    { lat: 49.095122, lng: 19.444655 },
    { lat: 49.095229, lng: 19.444876 },
    { lat: 49.095402, lng: 19.44536 },
    { lat: 49.095586, lng: 19.445829 },
    { lat: 49.0957576, lng: 19.4461753 },
    { lat: 49.095803, lng: 19.446267 },
    { lat: 49.096035, lng: 19.446685 },
    { lat: 49.096286, lng: 19.447088 },
    { lat: 49.096557, lng: 19.447444 },
    { lat: 49.0965714, lng: 19.4473467 },
    { lat: 49.0966519, lng: 19.4469881 },
    { lat: 49.0969521, lng: 19.4463677 },
    { lat: 49.0981279, lng: 19.4440181 },
    { lat: 49.0983913, lng: 19.4434731 },
    { lat: 49.098632, lng: 19.4428331 },
    { lat: 49.098772, lng: 19.4421089 },
    { lat: 49.0988285, lng: 19.4413089 },
    { lat: 49.0987981, lng: 19.440042 },
    { lat: 49.0987614, lng: 19.4394691 },
    { lat: 49.0987691, lng: 19.439318 },
    { lat: 49.0987012, lng: 19.4381433 },
    { lat: 49.0986739, lng: 19.4365866 },
    { lat: 49.0986807, lng: 19.4364183 },
    { lat: 49.098613, lng: 19.4353709 },
    { lat: 49.0986583, lng: 19.4350071 },
    { lat: 49.0986471, lng: 19.4348623 },
    { lat: 49.0981034, lng: 19.4336377 },
    { lat: 49.0978302, lng: 19.4321935 },
    { lat: 49.0976414, lng: 19.4315226 },
    { lat: 49.0960632, lng: 19.4280868 },
    { lat: 49.0960354, lng: 19.428001 },
    { lat: 49.0958586, lng: 19.4281247 },
    { lat: 49.0957834, lng: 19.4281777 },
    { lat: 49.0956928, lng: 19.4282404 },
    { lat: 49.0955759, lng: 19.4283223 },
    { lat: 49.0955215, lng: 19.4283606 },
    { lat: 49.0954593, lng: 19.4284039 },
    { lat: 49.0954411, lng: 19.4284037 },
    { lat: 49.0954237, lng: 19.4284171 },
    { lat: 49.0954205, lng: 19.4284178 },
    { lat: 49.0953625, lng: 19.4284359 },
    { lat: 49.0953572, lng: 19.4284379 },
    { lat: 49.0952604, lng: 19.4284689 },
    { lat: 49.0952313, lng: 19.4284928 },
    { lat: 49.0951241, lng: 19.4285198 },
    { lat: 49.0950955, lng: 19.4285808 },
    { lat: 49.0948869, lng: 19.4286288 },
    { lat: 49.0944877, lng: 19.4284474 },
    { lat: 49.0942833, lng: 19.4284662 },
    { lat: 49.094153, lng: 19.4284967 },
    { lat: 49.0940229, lng: 19.428537 },
    { lat: 49.0939451, lng: 19.4285615 },
    { lat: 49.0938184, lng: 19.4285989 },
    { lat: 49.0937905, lng: 19.4286037 },
    { lat: 49.0937078, lng: 19.4286176 },
    { lat: 49.0931083, lng: 19.4287857 },
    { lat: 49.0928191, lng: 19.4289902 },
    { lat: 49.0912337, lng: 19.43011 },
    { lat: 49.0888072, lng: 19.4302499 },
    { lat: 49.0870247, lng: 19.4303526 },
    { lat: 49.0861134, lng: 19.4301631 },
    { lat: 49.0836254, lng: 19.4300702 },
    { lat: 49.0838119, lng: 19.428405 },
    { lat: 49.0838329, lng: 19.4225505 },
    { lat: 49.0836483, lng: 19.4212467 },
    { lat: 49.0823694, lng: 19.4210588 },
    { lat: 49.0821725, lng: 19.4211106 },
    { lat: 49.0804029, lng: 19.4211072 },
    { lat: 49.079632, lng: 19.421394 },
    { lat: 49.079602, lng: 19.421833 },
    { lat: 49.079406, lng: 19.425289 },
    { lat: 49.079282, lng: 19.426212 },
    { lat: 49.078834, lng: 19.429694 },
    { lat: 49.078827, lng: 19.429746 },
    { lat: 49.078822, lng: 19.429782 },
    { lat: 49.078958, lng: 19.429615 },
    { lat: 49.080015, lng: 19.429997 },
    { lat: 49.080827, lng: 19.429957 },
    { lat: 49.080858, lng: 19.429955 },
    { lat: 49.080982, lng: 19.429949 },
    { lat: 49.081801, lng: 19.430184 },
    { lat: 49.083595, lng: 19.430406 },
    { lat: 49.086941, lng: 19.430913 },
    { lat: 49.086938, lng: 19.432762 },
    { lat: 49.086751, lng: 19.435094 },
    { lat: 49.086695, lng: 19.435797 },
    { lat: 49.086675, lng: 19.43603 },
    { lat: 49.086715, lng: 19.436116 },
    { lat: 49.086759, lng: 19.436215 },
    { lat: 49.086801, lng: 19.436305 },
    { lat: 49.086867, lng: 19.436437 },
    { lat: 49.087114, lng: 19.436929 },
    { lat: 49.085937, lng: 19.438733 },
    { lat: 49.085649, lng: 19.439174 },
    { lat: 49.087503, lng: 19.439642 },
    { lat: 49.089016, lng: 19.4401 },
    { lat: 49.089236, lng: 19.439912 },
    { lat: 49.089689, lng: 19.44003 },
    { lat: 49.090168, lng: 19.439491 },
    { lat: 49.090405, lng: 19.439542 },
    { lat: 49.090446, lng: 19.439096 },
    { lat: 49.090597, lng: 19.438896 },
    { lat: 49.09078, lng: 19.439464 },
    { lat: 49.090853, lng: 19.439578 },
    { lat: 49.090875, lng: 19.43979 },
    { lat: 49.090765, lng: 19.440202 },
    { lat: 49.090771, lng: 19.440393 },
    { lat: 49.090797, lng: 19.440522 },
    { lat: 49.09084, lng: 19.440686 },
    { lat: 49.09075, lng: 19.441664 },
    { lat: 49.090756, lng: 19.441996 },
    { lat: 49.090751, lng: 19.44236 },
    { lat: 49.091049, lng: 19.44245 },
    { lat: 49.091256, lng: 19.442528 },
    { lat: 49.091434, lng: 19.442609 },
    { lat: 49.091593, lng: 19.442671 },
    { lat: 49.091818, lng: 19.442756 },
    { lat: 49.092088, lng: 19.442871 },
    { lat: 49.092151, lng: 19.442898 },
    { lat: 49.092574, lng: 19.443053 },
    { lat: 49.093274, lng: 19.443242 },
    { lat: 49.093368, lng: 19.443286 },
    { lat: 49.093794, lng: 19.443702 },
    { lat: 49.093982, lng: 19.444016 },
    { lat: 49.094143, lng: 19.44422 },
    { lat: 49.094186, lng: 19.444283 },
    { lat: 49.094255, lng: 19.444381 },
    { lat: 49.094301, lng: 19.444448 },
    { lat: 49.094372, lng: 19.444552 },
    { lat: 49.094496, lng: 19.444731 },
  ],
  Lisková: [
    { lat: 49.0808988, lng: 19.3329983 },
    { lat: 49.0809072, lng: 19.3331655 },
    { lat: 49.0808097, lng: 19.3331777 },
    { lat: 49.0808304, lng: 19.3335709 },
    { lat: 49.080735, lng: 19.3338657 },
    { lat: 49.0805848, lng: 19.3348352 },
    { lat: 49.0803693, lng: 19.3360222 },
    { lat: 49.0801013, lng: 19.3375313 },
    { lat: 49.080077, lng: 19.3378273 },
    { lat: 49.08002, lng: 19.3392461 },
    { lat: 49.0799465, lng: 19.3398096 },
    { lat: 49.0795964, lng: 19.3408944 },
    { lat: 49.0794947, lng: 19.34115 },
    { lat: 49.079532, lng: 19.3416061 },
    { lat: 49.0797624, lng: 19.3429812 },
    { lat: 49.0798256, lng: 19.3433888 },
    { lat: 49.0794341, lng: 19.3429941 },
    { lat: 49.0792037, lng: 19.3427701 },
    { lat: 49.0791755, lng: 19.3427229 },
    { lat: 49.0789108, lng: 19.3424649 },
    { lat: 49.0787356, lng: 19.3422846 },
    { lat: 49.0786563, lng: 19.3422303 },
    { lat: 49.0786135, lng: 19.3422551 },
    { lat: 49.0785883, lng: 19.3423151 },
    { lat: 49.0785868, lng: 19.3423803 },
    { lat: 49.0786055, lng: 19.3442814 },
    { lat: 49.0785107, lng: 19.3455673 },
    { lat: 49.0785499, lng: 19.3464725 },
    { lat: 49.0787305, lng: 19.349323 },
    { lat: 49.0773104, lng: 19.3499178 },
    { lat: 49.074565, lng: 19.3509671 },
    { lat: 49.0728561, lng: 19.3511954 },
    { lat: 49.0731162, lng: 19.3542313 },
    { lat: 49.07159, lng: 19.3546333 },
    { lat: 49.0699394, lng: 19.3546175 },
    { lat: 49.0698913, lng: 19.358405 },
    { lat: 49.0698831, lng: 19.3584972 },
    { lat: 49.0698688, lng: 19.3585038 },
    { lat: 49.069848, lng: 19.3585143 },
    { lat: 49.0698394, lng: 19.3585717 },
    { lat: 49.0698329, lng: 19.358626 },
    { lat: 49.0695554, lng: 19.3584783 },
    { lat: 49.0692017, lng: 19.3581434 },
    { lat: 49.0687061, lng: 19.3577891 },
    { lat: 49.0687827, lng: 19.357862 },
    { lat: 49.068779, lng: 19.3578779 },
    { lat: 49.0687752, lng: 19.3578934 },
    { lat: 49.0685143, lng: 19.3590084 },
    { lat: 49.0680358, lng: 19.3607886 },
    { lat: 49.0678883, lng: 19.3612478 },
    { lat: 49.067733, lng: 19.3617061 },
    { lat: 49.0675451, lng: 19.3625061 },
    { lat: 49.0668627, lng: 19.362468 },
    { lat: 49.066316, lng: 19.3650541 },
    { lat: 49.0672547, lng: 19.3654863 },
    { lat: 49.0678605, lng: 19.3657855 },
    { lat: 49.0682489, lng: 19.3661411 },
    { lat: 49.0686409, lng: 19.3662304 },
    { lat: 49.0689401, lng: 19.3665321 },
    { lat: 49.0691129, lng: 19.3668968 },
    { lat: 49.0693724, lng: 19.3672639 },
    { lat: 49.0696268, lng: 19.3679539 },
    { lat: 49.0696365, lng: 19.3679684 },
    { lat: 49.0698491, lng: 19.3679865 },
    { lat: 49.0698862, lng: 19.3679117 },
    { lat: 49.0700574, lng: 19.3679056 },
    { lat: 49.0700721, lng: 19.3678864 },
    { lat: 49.0700997, lng: 19.367946 },
    { lat: 49.0701414, lng: 19.367899 },
    { lat: 49.0701476, lng: 19.3678157 },
    { lat: 49.0701782, lng: 19.367697 },
    { lat: 49.07028, lng: 19.3677279 },
    { lat: 49.0703224, lng: 19.3676957 },
    { lat: 49.0703755, lng: 19.367769 },
    { lat: 49.0704265, lng: 19.3677289 },
    { lat: 49.0704572, lng: 19.3677845 },
    { lat: 49.0704997, lng: 19.3677296 },
    { lat: 49.0705578, lng: 19.3676945 },
    { lat: 49.0706147, lng: 19.3677548 },
    { lat: 49.0708179, lng: 19.3678078 },
    { lat: 49.0709297, lng: 19.3677388 },
    { lat: 49.0709693, lng: 19.3677917 },
    { lat: 49.0709805, lng: 19.367885 },
    { lat: 49.0710242, lng: 19.3679316 },
    { lat: 49.0711027, lng: 19.3679234 },
    { lat: 49.0711932, lng: 19.3678579 },
    { lat: 49.0712654, lng: 19.3678813 },
    { lat: 49.0713248, lng: 19.3678288 },
    { lat: 49.0714634, lng: 19.3678748 },
    { lat: 49.0715849, lng: 19.3678724 },
    { lat: 49.0716117, lng: 19.3679148 },
    { lat: 49.0716365, lng: 19.3679347 },
    { lat: 49.0716704, lng: 19.3678926 },
    { lat: 49.0717701, lng: 19.3679292 },
    { lat: 49.0717883, lng: 19.3679027 },
    { lat: 49.0718491, lng: 19.3679515 },
    { lat: 49.0719206, lng: 19.3679146 },
    { lat: 49.0720101, lng: 19.3679757 },
    { lat: 49.0721124, lng: 19.3679451 },
    { lat: 49.0721316, lng: 19.3679232 },
    { lat: 49.0721649, lng: 19.3680285 },
    { lat: 49.0722403, lng: 19.3680498 },
    { lat: 49.0722534, lng: 19.3679966 },
    { lat: 49.0723091, lng: 19.3679805 },
    { lat: 49.0724936, lng: 19.3682393 },
    { lat: 49.0725765, lng: 19.3683245 },
    { lat: 49.0726555, lng: 19.3684234 },
    { lat: 49.0727261, lng: 19.3684125 },
    { lat: 49.0727959, lng: 19.3684613 },
    { lat: 49.0728509, lng: 19.36841 },
    { lat: 49.072904, lng: 19.368383 },
    { lat: 49.0729643, lng: 19.3683765 },
    { lat: 49.0729454, lng: 19.3690539 },
    { lat: 49.0730066, lng: 19.3691806 },
    { lat: 49.0732006, lng: 19.369184 },
    { lat: 49.0736637, lng: 19.3692533 },
    { lat: 49.0738781, lng: 19.369378 },
    { lat: 49.074523, lng: 19.3691677 },
    { lat: 49.0746456, lng: 19.3692579 },
    { lat: 49.0749182, lng: 19.3690636 },
    { lat: 49.0749972, lng: 19.3690675 },
    { lat: 49.0751748, lng: 19.3689871 },
    { lat: 49.0754754, lng: 19.3687912 },
    { lat: 49.0756064, lng: 19.3687237 },
    { lat: 49.0758908, lng: 19.3687801 },
    { lat: 49.0760784, lng: 19.3689089 },
    { lat: 49.0762066, lng: 19.3691692 },
    { lat: 49.0763639, lng: 19.3693951 },
    { lat: 49.0765451, lng: 19.3697744 },
    { lat: 49.0766444, lng: 19.3698783 },
    { lat: 49.0765441, lng: 19.3699697 },
    { lat: 49.0766181, lng: 19.3701774 },
    { lat: 49.0766556, lng: 19.3704476 },
    { lat: 49.0767129, lng: 19.3707339 },
    { lat: 49.0768181, lng: 19.3709565 },
    { lat: 49.0769248, lng: 19.3712882 },
    { lat: 49.0770544, lng: 19.3715991 },
    { lat: 49.0772367, lng: 19.3724589 },
    { lat: 49.0773629, lng: 19.3727489 },
    { lat: 49.0784661, lng: 19.3725556 },
    { lat: 49.0788787, lng: 19.3723644 },
    { lat: 49.0788383, lng: 19.372248 },
    { lat: 49.0793637, lng: 19.3718706 },
    { lat: 49.0802922, lng: 19.3732126 },
    { lat: 49.080966, lng: 19.3715496 },
    { lat: 49.0817216, lng: 19.3724978 },
    { lat: 49.0817345, lng: 19.3724859 },
    { lat: 49.0817613, lng: 19.3724794 },
    { lat: 49.0817897, lng: 19.3724152 },
    { lat: 49.0818209, lng: 19.3723802 },
    { lat: 49.0818185, lng: 19.3723294 },
    { lat: 49.0818074, lng: 19.3723083 },
    { lat: 49.081745, lng: 19.3723015 },
    { lat: 49.0817394, lng: 19.3722539 },
    { lat: 49.081755, lng: 19.3721781 },
    { lat: 49.0817656, lng: 19.372163 },
    { lat: 49.0818534, lng: 19.3722127 },
    { lat: 49.0819026, lng: 19.3722232 },
    { lat: 49.081918, lng: 19.3721449 },
    { lat: 49.0819158, lng: 19.3720783 },
    { lat: 49.0819853, lng: 19.3719489 },
    { lat: 49.0819899, lng: 19.3719247 },
    { lat: 49.0819793, lng: 19.3719165 },
    { lat: 49.0819675, lng: 19.3719271 },
    { lat: 49.0819493, lng: 19.3719258 },
    { lat: 49.0819342, lng: 19.3718901 },
    { lat: 49.0819338, lng: 19.3718563 },
    { lat: 49.0819415, lng: 19.3718293 },
    { lat: 49.0819727, lng: 19.3718014 },
    { lat: 49.0820286, lng: 19.371814 },
    { lat: 49.0820695, lng: 19.3718452 },
    { lat: 49.0820962, lng: 19.3717908 },
    { lat: 49.0821327, lng: 19.3717481 },
    { lat: 49.082144, lng: 19.3717012 },
    { lat: 49.0821501, lng: 19.3716908 },
    { lat: 49.0821838, lng: 19.3716638 },
    { lat: 49.0823121, lng: 19.3714636 },
    { lat: 49.0823586, lng: 19.3714488 },
    { lat: 49.0823769, lng: 19.3714558 },
    { lat: 49.082388, lng: 19.3714503 },
    { lat: 49.0824075, lng: 19.371459 },
    { lat: 49.082455, lng: 19.3714153 },
    { lat: 49.082494, lng: 19.3714046 },
    { lat: 49.0825812, lng: 19.3714113 },
    { lat: 49.0826179, lng: 19.3713077 },
    { lat: 49.0826226, lng: 19.3712371 },
    { lat: 49.0826086, lng: 19.3711358 },
    { lat: 49.0826203, lng: 19.3710917 },
    { lat: 49.0826521, lng: 19.3710498 },
    { lat: 49.0827129, lng: 19.3710475 },
    { lat: 49.0827204, lng: 19.3710169 },
    { lat: 49.0827831, lng: 19.3709381 },
    { lat: 49.0828039, lng: 19.370873 },
    { lat: 49.0828458, lng: 19.3708565 },
    { lat: 49.0828519, lng: 19.3708447 },
    { lat: 49.0828541, lng: 19.3708157 },
    { lat: 49.0828578, lng: 19.3707995 },
    { lat: 49.082885, lng: 19.3707541 },
    { lat: 49.0829647, lng: 19.3704604 },
    { lat: 49.0829682, lng: 19.3702932 },
    { lat: 49.0830258, lng: 19.3701514 },
    { lat: 49.0830895, lng: 19.3701412 },
    { lat: 49.0832559, lng: 19.3700832 },
    { lat: 49.0832797, lng: 19.3700881 },
    { lat: 49.0833184, lng: 19.3700472 },
    { lat: 49.0833892, lng: 19.3700205 },
    { lat: 49.0834501, lng: 19.3700193 },
    { lat: 49.0834524, lng: 19.3698774 },
    { lat: 49.0834328, lng: 19.369823 },
    { lat: 49.0834718, lng: 19.3697638 },
    { lat: 49.0835243, lng: 19.3697556 },
    { lat: 49.0835296, lng: 19.3697474 },
    { lat: 49.083549, lng: 19.369682 },
    { lat: 49.083627, lng: 19.3696585 },
    { lat: 49.0836539, lng: 19.369703 },
    { lat: 49.0836686, lng: 19.369705 },
    { lat: 49.0837195, lng: 19.369638 },
    { lat: 49.0837671, lng: 19.3696188 },
    { lat: 49.083774, lng: 19.3696252 },
    { lat: 49.08379, lng: 19.3696993 },
    { lat: 49.0838218, lng: 19.3697793 },
    { lat: 49.0838311, lng: 19.3697851 },
    { lat: 49.0838653, lng: 19.3697699 },
    { lat: 49.08387, lng: 19.3697481 },
    { lat: 49.0838691, lng: 19.369704 },
    { lat: 49.0838228, lng: 19.3696091 },
    { lat: 49.0838117, lng: 19.3695636 },
    { lat: 49.0838829, lng: 19.3694961 },
    { lat: 49.0839499, lng: 19.3695295 },
    { lat: 49.0840115, lng: 19.3696385 },
    { lat: 49.0840884, lng: 19.369578 },
    { lat: 49.0841888, lng: 19.3696987 },
    { lat: 49.0842215, lng: 19.3696768 },
    { lat: 49.0842455, lng: 19.3695829 },
    { lat: 49.0841649, lng: 19.3695266 },
    { lat: 49.0842561, lng: 19.3694721 },
    { lat: 49.0842617, lng: 19.3694502 },
    { lat: 49.0843409, lng: 19.3694571 },
    { lat: 49.0844231, lng: 19.3694327 },
    { lat: 49.0844617, lng: 19.369486 },
    { lat: 49.0845266, lng: 19.3695242 },
    { lat: 49.0845739, lng: 19.3695216 },
    { lat: 49.0846036, lng: 19.3694601 },
    { lat: 49.0846794, lng: 19.3694648 },
    { lat: 49.084717, lng: 19.3694517 },
    { lat: 49.0847074, lng: 19.3693881 },
    { lat: 49.084771, lng: 19.369374 },
    { lat: 49.0848109, lng: 19.3695807 },
    { lat: 49.0848628, lng: 19.3695074 },
    { lat: 49.084883, lng: 19.3695052 },
    { lat: 49.0848918, lng: 19.369597 },
    { lat: 49.0849408, lng: 19.3696295 },
    { lat: 49.0849748, lng: 19.3696355 },
    { lat: 49.0849813, lng: 19.3696524 },
    { lat: 49.0850817, lng: 19.3696594 },
    { lat: 49.0850972, lng: 19.3696523 },
    { lat: 49.0851049, lng: 19.3696242 },
    { lat: 49.0851425, lng: 19.3696057 },
    { lat: 49.0851595, lng: 19.3695848 },
    { lat: 49.0852869, lng: 19.3697284 },
    { lat: 49.0853719, lng: 19.3695987 },
    { lat: 49.0854259, lng: 19.3695705 },
    { lat: 49.0854732, lng: 19.3694964 },
    { lat: 49.0855501, lng: 19.3695056 },
    { lat: 49.0855967, lng: 19.3695631 },
    { lat: 49.0856177, lng: 19.3696719 },
    { lat: 49.0857325, lng: 19.3697648 },
    { lat: 49.0858532, lng: 19.3696952 },
    { lat: 49.0859506, lng: 19.3696116 },
    { lat: 49.0860407, lng: 19.3696341 },
    { lat: 49.0861572, lng: 19.3694992 },
    { lat: 49.0862173, lng: 19.3695071 },
    { lat: 49.0865282, lng: 19.3694042 },
    { lat: 49.0866792, lng: 19.3694061 },
    { lat: 49.0867157, lng: 19.3695844 },
    { lat: 49.0867582, lng: 19.369651 },
    { lat: 49.087866, lng: 19.3720753 },
    { lat: 49.0881303, lng: 19.3729534 },
    { lat: 49.0881318, lng: 19.3734188 },
    { lat: 49.0878461, lng: 19.3743894 },
    { lat: 49.0876332, lng: 19.3749217 },
    { lat: 49.0873336, lng: 19.3753056 },
    { lat: 49.0858279, lng: 19.3765802 },
    { lat: 49.0857686, lng: 19.3766334 },
    { lat: 49.0840954, lng: 19.3780829 },
    { lat: 49.0839221, lng: 19.3781841 },
    { lat: 49.0839063, lng: 19.3781847 },
    { lat: 49.0837436, lng: 19.3782952 },
    { lat: 49.0838721, lng: 19.378992 },
    { lat: 49.0842617, lng: 19.3789418 },
    { lat: 49.0845906, lng: 19.3801838 },
    { lat: 49.0844726, lng: 19.3818806 },
    { lat: 49.0844489, lng: 19.3822355 },
    { lat: 49.0848005, lng: 19.3836326 },
    { lat: 49.0848595, lng: 19.383596 },
    { lat: 49.0853186, lng: 19.3835059 },
    { lat: 49.0856401, lng: 19.3834426 },
    { lat: 49.0856872, lng: 19.3834339 },
    { lat: 49.0857831, lng: 19.3834145 },
    { lat: 49.0858355, lng: 19.3834045 },
    { lat: 49.0880848, lng: 19.3817656 },
    { lat: 49.089313, lng: 19.3804859 },
    { lat: 49.0908136, lng: 19.3787239 },
    { lat: 49.0909803, lng: 19.3790557 },
    { lat: 49.0911977, lng: 19.3794876 },
    { lat: 49.0912712, lng: 19.3793973 },
    { lat: 49.0914279, lng: 19.3792041 },
    { lat: 49.0916643, lng: 19.3789123 },
    { lat: 49.0918106, lng: 19.3786185 },
    { lat: 49.0919542, lng: 19.378819 },
    { lat: 49.0920073, lng: 19.3788941 },
    { lat: 49.0920456, lng: 19.3789464 },
    { lat: 49.0921278, lng: 19.3790612 },
    { lat: 49.0921555, lng: 19.3790991 },
    { lat: 49.0921735, lng: 19.379126 },
    { lat: 49.0923049, lng: 19.3793091 },
    { lat: 49.0924806, lng: 19.3796676 },
    { lat: 49.0928762, lng: 19.3805807 },
    { lat: 49.0929136, lng: 19.3806827 },
    { lat: 49.0929525, lng: 19.3807875 },
    { lat: 49.0931776, lng: 19.3814592 },
    { lat: 49.093343, lng: 19.3820051 },
    { lat: 49.0935475, lng: 19.3825708 },
    { lat: 49.0935847, lng: 19.3827142 },
    { lat: 49.0937772, lng: 19.3835782 },
    { lat: 49.0938279, lng: 19.3839848 },
    { lat: 49.0939689, lng: 19.384565 },
    { lat: 49.0940211, lng: 19.3848643 },
    { lat: 49.0941314, lng: 19.3857268 },
    { lat: 49.094204, lng: 19.3867233 },
    { lat: 49.0944006, lng: 19.3867868 },
    { lat: 49.0950012, lng: 19.3868335 },
    { lat: 49.0956836, lng: 19.3867012 },
    { lat: 49.0958468, lng: 19.3866971 },
    { lat: 49.0968189, lng: 19.3863015 },
    { lat: 49.0971727, lng: 19.3861811 },
    { lat: 49.0980815, lng: 19.3856577 },
    { lat: 49.0981878, lng: 19.383213 },
    { lat: 49.0991813, lng: 19.3817353 },
    { lat: 49.1000262, lng: 19.3822092 },
    { lat: 49.1005958, lng: 19.3807063 },
    { lat: 49.1004337, lng: 19.380466 },
    { lat: 49.1006945, lng: 19.3800205 },
    { lat: 49.1009812, lng: 19.3794506 },
    { lat: 49.1013281, lng: 19.3796682 },
    { lat: 49.102224, lng: 19.3783157 },
    { lat: 49.1025073, lng: 19.3777497 },
    { lat: 49.1029248, lng: 19.3774979 },
    { lat: 49.1030374, lng: 19.3775921 },
    { lat: 49.1039793, lng: 19.3762151 },
    { lat: 49.1040021, lng: 19.3761014 },
    { lat: 49.1041346, lng: 19.3759 },
    { lat: 49.104638, lng: 19.3759891 },
    { lat: 49.1048544, lng: 19.3760379 },
    { lat: 49.1052064, lng: 19.3756612 },
    { lat: 49.1052503, lng: 19.3757319 },
    { lat: 49.1055808, lng: 19.3750406 },
    { lat: 49.1058082, lng: 19.3752763 },
    { lat: 49.1074082, lng: 19.3735912 },
    { lat: 49.107605, lng: 19.3741816 },
    { lat: 49.1078768, lng: 19.3739449 },
    { lat: 49.1080615, lng: 19.3736546 },
    { lat: 49.1084225, lng: 19.3732798 },
    { lat: 49.1092432, lng: 19.3725167 },
    { lat: 49.1097336, lng: 19.3723738 },
    { lat: 49.1100185, lng: 19.3725108 },
    { lat: 49.1117844, lng: 19.3712873 },
    { lat: 49.1119089, lng: 19.3696682 },
    { lat: 49.1120949, lng: 19.3689206 },
    { lat: 49.1135863, lng: 19.3682348 },
    { lat: 49.1127486, lng: 19.3672421 },
    { lat: 49.1126106, lng: 19.3658413 },
    { lat: 49.1125892, lng: 19.3650741 },
    { lat: 49.1127704, lng: 19.3646587 },
    { lat: 49.1130133, lng: 19.3642181 },
    { lat: 49.1131498, lng: 19.3636453 },
    { lat: 49.1133781, lng: 19.3626275 },
    { lat: 49.1134507, lng: 19.3622279 },
    { lat: 49.1135024, lng: 19.3616509 },
    { lat: 49.1140493, lng: 19.3608157 },
    { lat: 49.1135716, lng: 19.3599109 },
    { lat: 49.1135834, lng: 19.3598459 },
    { lat: 49.1139966, lng: 19.3588094 },
    { lat: 49.1148698, lng: 19.3569592 },
    { lat: 49.1155313, lng: 19.3554041 },
    { lat: 49.1161827, lng: 19.3549944 },
    { lat: 49.11666, lng: 19.355201 },
    { lat: 49.1170763, lng: 19.3548037 },
    { lat: 49.1174831, lng: 19.3548722 },
    { lat: 49.1185626, lng: 19.3539855 },
    { lat: 49.1199437, lng: 19.3532288 },
    { lat: 49.1209666, lng: 19.3526244 },
    { lat: 49.1210003, lng: 19.3520699 },
    { lat: 49.1208184, lng: 19.3511759 },
    { lat: 49.1208114, lng: 19.3510986 },
    { lat: 49.1208317, lng: 19.3509075 },
    { lat: 49.1212264, lng: 19.3504833 },
    { lat: 49.1213771, lng: 19.3503078 },
    { lat: 49.1214886, lng: 19.3497822 },
    { lat: 49.121939, lng: 19.3493422 },
    { lat: 49.1223579, lng: 19.3489053 },
    { lat: 49.1230155, lng: 19.3481008 },
    { lat: 49.1241937, lng: 19.3478748 },
    { lat: 49.1243279, lng: 19.3477569 },
    { lat: 49.1243663, lng: 19.347735 },
    { lat: 49.1244143, lng: 19.3478208 },
    { lat: 49.1245015, lng: 19.3480423 },
    { lat: 49.1247235, lng: 19.348429 },
    { lat: 49.1249959, lng: 19.3487086 },
    { lat: 49.1259573, lng: 19.349525 },
    { lat: 49.1267813, lng: 19.3501734 },
    { lat: 49.1270777, lng: 19.3501755 },
    { lat: 49.1278886, lng: 19.3501128 },
    { lat: 49.128351, lng: 19.3504109 },
    { lat: 49.1291834, lng: 19.3512624 },
    { lat: 49.1297285, lng: 19.3516145 },
    { lat: 49.1305895, lng: 19.351805 },
    { lat: 49.1313085, lng: 19.3518794 },
    { lat: 49.1316512, lng: 19.351739 },
    { lat: 49.1319894, lng: 19.3514545 },
    { lat: 49.1322688, lng: 19.3510936 },
    { lat: 49.1324802, lng: 19.3506991 },
    { lat: 49.1327263, lng: 19.3501813 },
    { lat: 49.1332582, lng: 19.3493911 },
    { lat: 49.1335611, lng: 19.3490992 },
    { lat: 49.1339627, lng: 19.3487948 },
    { lat: 49.1343447, lng: 19.3483873 },
    { lat: 49.1348546, lng: 19.3478468 },
    { lat: 49.135187, lng: 19.347538 },
    { lat: 49.1354779, lng: 19.3468702 },
    { lat: 49.1360316, lng: 19.3458713 },
    { lat: 49.1363527, lng: 19.344962 },
    { lat: 49.1429517, lng: 19.3456432 },
    { lat: 49.1450883, lng: 19.3462679 },
    { lat: 49.1454412, lng: 19.345478 },
    { lat: 49.1444859, lng: 19.343552 },
    { lat: 49.1440827, lng: 19.3429082 },
    { lat: 49.1436508, lng: 19.3414195 },
    { lat: 49.1431955, lng: 19.3409187 },
    { lat: 49.1425629, lng: 19.3398077 },
    { lat: 49.1418309, lng: 19.3388879 },
    { lat: 49.1412703, lng: 19.3383284 },
    { lat: 49.1403538, lng: 19.3377734 },
    { lat: 49.1398722, lng: 19.3360427 },
    { lat: 49.1397684, lng: 19.3353007 },
    { lat: 49.1397109, lng: 19.3343726 },
    { lat: 49.1395644, lng: 19.3334647 },
    { lat: 49.1390075, lng: 19.3329333 },
    { lat: 49.1378035, lng: 19.3336675 },
    { lat: 49.1371332, lng: 19.3343648 },
    { lat: 49.1354098, lng: 19.3350727 },
    { lat: 49.1353622, lng: 19.3361645 },
    { lat: 49.1354699, lng: 19.3371615 },
    { lat: 49.1351132, lng: 19.3390365 },
    { lat: 49.1338062, lng: 19.3413139 },
    { lat: 49.1331828, lng: 19.3422397 },
    { lat: 49.1324202, lng: 19.34256 },
    { lat: 49.1313347, lng: 19.3425277 },
    { lat: 49.1311748, lng: 19.3425809 },
    { lat: 49.1307441, lng: 19.3424769 },
    { lat: 49.129854, lng: 19.3428662 },
    { lat: 49.1296734, lng: 19.3427877 },
    { lat: 49.1293816, lng: 19.3426958 },
    { lat: 49.1280288, lng: 19.3426827 },
    { lat: 49.1276097, lng: 19.3424983 },
    { lat: 49.1264558, lng: 19.3414461 },
    { lat: 49.1258505, lng: 19.3407294 },
    { lat: 49.1234647, lng: 19.3394376 },
    { lat: 49.1230646, lng: 19.339557 },
    { lat: 49.1227694, lng: 19.339436 },
    { lat: 49.1224888, lng: 19.3388393 },
    { lat: 49.122162, lng: 19.3383393 },
    { lat: 49.121207, lng: 19.3374947 },
    { lat: 49.1206425, lng: 19.3369726 },
    { lat: 49.1201869, lng: 19.3366505 },
    { lat: 49.1196677, lng: 19.3363469 },
    { lat: 49.1192004, lng: 19.3359789 },
    { lat: 49.1188761, lng: 19.3357425 },
    { lat: 49.1185431, lng: 19.3355167 },
    { lat: 49.118171, lng: 19.3351786 },
    { lat: 49.1177232, lng: 19.3347859 },
    { lat: 49.1173758, lng: 19.3346163 },
    { lat: 49.1171384, lng: 19.3344294 },
    { lat: 49.1168276, lng: 19.3341752 },
    { lat: 49.1164873, lng: 19.3339139 },
    { lat: 49.116025, lng: 19.333571 },
    { lat: 49.1152955, lng: 19.3329908 },
    { lat: 49.114976, lng: 19.3327661 },
    { lat: 49.1142011, lng: 19.3321107 },
    { lat: 49.1140088, lng: 19.3320238 },
    { lat: 49.1138701, lng: 19.3319013 },
    { lat: 49.1136778, lng: 19.3317651 },
    { lat: 49.1133323, lng: 19.3317066 },
    { lat: 49.1127197, lng: 19.3316205 },
    { lat: 49.1118119, lng: 19.331561 },
    { lat: 49.1117156, lng: 19.331572 },
    { lat: 49.1110702, lng: 19.3318158 },
    { lat: 49.1104289, lng: 19.3321742 },
    { lat: 49.1098329, lng: 19.3325151 },
    { lat: 49.1095874, lng: 19.3326363 },
    { lat: 49.1087773, lng: 19.3330197 },
    { lat: 49.1080736, lng: 19.3334418 },
    { lat: 49.1078373, lng: 19.3335436 },
    { lat: 49.1073354, lng: 19.3337277 },
    { lat: 49.1068236, lng: 19.3341061 },
    { lat: 49.1066593, lng: 19.3341572 },
    { lat: 49.1065711, lng: 19.3343651 },
    { lat: 49.1065566, lng: 19.3348721 },
    { lat: 49.1064942, lng: 19.3350062 },
    { lat: 49.1063383, lng: 19.3351906 },
    { lat: 49.1061332, lng: 19.335258 },
    { lat: 49.1059557, lng: 19.3352246 },
    { lat: 49.1058179, lng: 19.3353634 },
    { lat: 49.1054882, lng: 19.3358017 },
    { lat: 49.1052647, lng: 19.3361999 },
    { lat: 49.1050267, lng: 19.3366007 },
    { lat: 49.1047381, lng: 19.3361716 },
    { lat: 49.1043975, lng: 19.3356399 },
    { lat: 49.1040184, lng: 19.3357735 },
    { lat: 49.1037566, lng: 19.3361011 },
    { lat: 49.1034069, lng: 19.3362818 },
    { lat: 49.1033342, lng: 19.3361761 },
    { lat: 49.1030425, lng: 19.3363969 },
    { lat: 49.10293, lng: 19.3363732 },
    { lat: 49.102848, lng: 19.3363074 },
    { lat: 49.1025884, lng: 19.3361815 },
    { lat: 49.1025767, lng: 19.3361497 },
    { lat: 49.1023669, lng: 19.3360263 },
    { lat: 49.1021345, lng: 19.3357806 },
    { lat: 49.101854, lng: 19.3356168 },
    { lat: 49.1017039, lng: 19.3357303 },
    { lat: 49.1016922, lng: 19.3355327 },
    { lat: 49.1012353, lng: 19.3353784 },
    { lat: 49.1008842, lng: 19.3352705 },
    { lat: 49.1007299, lng: 19.3352475 },
    { lat: 49.1009561, lng: 19.3375084 },
    { lat: 49.1008786, lng: 19.3375625 },
    { lat: 49.1007691, lng: 19.337603 },
    { lat: 49.1006851, lng: 19.3376143 },
    { lat: 49.100594, lng: 19.3377153 },
    { lat: 49.1005945, lng: 19.3377012 },
    { lat: 49.1004525, lng: 19.3371298 },
    { lat: 49.0999454, lng: 19.3374187 },
    { lat: 49.0996711, lng: 19.3374556 },
    { lat: 49.0994686, lng: 19.3378432 },
    { lat: 49.0994372, lng: 19.3380387 },
    { lat: 49.0994411, lng: 19.338121 },
    { lat: 49.0993573, lng: 19.3382071 },
    { lat: 49.099049, lng: 19.3373587 },
    { lat: 49.0988067, lng: 19.3365738 },
    { lat: 49.098693, lng: 19.3362169 },
    { lat: 49.0985754, lng: 19.3359709 },
    { lat: 49.0985386, lng: 19.3359073 },
    { lat: 49.0984414, lng: 19.3357798 },
    { lat: 49.0982256, lng: 19.3360556 },
    { lat: 49.0978645, lng: 19.336426 },
    { lat: 49.0976026, lng: 19.3365615 },
    { lat: 49.0972982, lng: 19.3368455 },
    { lat: 49.0973104, lng: 19.3366291 },
    { lat: 49.0973915, lng: 19.3364354 },
    { lat: 49.0972809, lng: 19.3361695 },
    { lat: 49.0969506, lng: 19.3369555 },
    { lat: 49.0968153, lng: 19.3373817 },
    { lat: 49.0967552, lng: 19.3377785 },
    { lat: 49.0963968, lng: 19.3381364 },
    { lat: 49.0957471, lng: 19.3387962 },
    { lat: 49.0956058, lng: 19.3389289 },
    { lat: 49.0951148, lng: 19.3393182 },
    { lat: 49.0944601, lng: 19.3398922 },
    { lat: 49.0942376, lng: 19.3401931 },
    { lat: 49.0941129, lng: 19.3400331 },
    { lat: 49.094165, lng: 19.341331 },
    { lat: 49.094055, lng: 19.3415778 },
    { lat: 49.0935412, lng: 19.3429405 },
    { lat: 49.0934031, lng: 19.3433378 },
    { lat: 49.0933498, lng: 19.3436148 },
    { lat: 49.0932539, lng: 19.3436142 },
    { lat: 49.0927826, lng: 19.3434158 },
    { lat: 49.0922167, lng: 19.3433892 },
    { lat: 49.0916186, lng: 19.3428437 },
    { lat: 49.0916105, lng: 19.3424127 },
    { lat: 49.0914701, lng: 19.3418495 },
    { lat: 49.0912526, lng: 19.3417039 },
    { lat: 49.0911825, lng: 19.3415091 },
    { lat: 49.0911076, lng: 19.340924 },
    { lat: 49.0906337, lng: 19.3403886 },
    { lat: 49.089696, lng: 19.3396444 },
    { lat: 49.089257, lng: 19.3392754 },
    { lat: 49.0889843, lng: 19.3389676 },
    { lat: 49.0887398, lng: 19.3387042 },
    { lat: 49.0886623, lng: 19.3375883 },
    { lat: 49.0884284, lng: 19.3371454 },
    { lat: 49.0874066, lng: 19.3362472 },
    { lat: 49.0871779, lng: 19.3355107 },
    { lat: 49.0867451, lng: 19.334483 },
    { lat: 49.0866349, lng: 19.3342729 },
    { lat: 49.0864477, lng: 19.3337206 },
    { lat: 49.0861073, lng: 19.3331243 },
    { lat: 49.0854538, lng: 19.3317189 },
    { lat: 49.0853827, lng: 19.3314096 },
    { lat: 49.0850161, lng: 19.3300195 },
    { lat: 49.0848988, lng: 19.3298008 },
    { lat: 49.0845951, lng: 19.3294799 },
    { lat: 49.0839709, lng: 19.3288656 },
    { lat: 49.084017, lng: 19.3295314 },
    { lat: 49.084081, lng: 19.3300245 },
    { lat: 49.0835165, lng: 19.3306239 },
    { lat: 49.0834359, lng: 19.3307766 },
    { lat: 49.0833506, lng: 19.3308794 },
    { lat: 49.0832139, lng: 19.3310857 },
    { lat: 49.0832128, lng: 19.3310883 },
    { lat: 49.0829456, lng: 19.3314901 },
    { lat: 49.082845, lng: 19.3316294 },
    { lat: 49.082694, lng: 19.3314137 },
    { lat: 49.0825079, lng: 19.3315772 },
    { lat: 49.0824206, lng: 19.3315893 },
    { lat: 49.0824235, lng: 19.3316512 },
    { lat: 49.0820687, lng: 19.3319629 },
    { lat: 49.0812118, lng: 19.3327209 },
    { lat: 49.0808868, lng: 19.332759 },
    { lat: 49.0808988, lng: 19.3329983 },
  ],
  Ludrová: [
    { lat: 49.046693, lng: 19.3142591 },
    { lat: 49.0459366, lng: 19.3146576 },
    { lat: 49.0452706, lng: 19.3142095 },
    { lat: 49.0449609, lng: 19.3143326 },
    { lat: 49.0444969, lng: 19.3148158 },
    { lat: 49.0448483, lng: 19.3151885 },
    { lat: 49.0453933, lng: 19.3155609 },
    { lat: 49.0453417, lng: 19.3159915 },
    { lat: 49.0438622, lng: 19.3158795 },
    { lat: 49.0422791, lng: 19.3139618 },
    { lat: 49.0408127, lng: 19.3151238 },
    { lat: 49.0387668, lng: 19.3167067 },
    { lat: 49.0377977, lng: 19.3170871 },
    { lat: 49.0387462, lng: 19.321118 },
    { lat: 49.0406422, lng: 19.3231728 },
    { lat: 49.0405669, lng: 19.3232978 },
    { lat: 49.0411164, lng: 19.3238547 },
    { lat: 49.0414718, lng: 19.3244121 },
    { lat: 49.0414272, lng: 19.324801 },
    { lat: 49.0418525, lng: 19.3252139 },
    { lat: 49.0419402, lng: 19.325067 },
    { lat: 49.0427006, lng: 19.3257998 },
    { lat: 49.0433032, lng: 19.326152 },
    { lat: 49.0432556, lng: 19.3262876 },
    { lat: 49.0430093, lng: 19.3264173 },
    { lat: 49.041782, lng: 19.3262982 },
    { lat: 49.0416367, lng: 19.3266572 },
    { lat: 49.0420765, lng: 19.3268968 },
    { lat: 49.0409132, lng: 19.3272782 },
    { lat: 49.0407581, lng: 19.3277134 },
    { lat: 49.0403154, lng: 19.3277892 },
    { lat: 49.040226, lng: 19.3279021 },
    { lat: 49.0398918, lng: 19.3279796 },
    { lat: 49.0392348, lng: 19.3276267 },
    { lat: 49.0391612, lng: 19.3276893 },
    { lat: 49.0390644, lng: 19.3275491 },
    { lat: 49.0387918, lng: 19.3274076 },
    { lat: 49.0386518, lng: 19.3270253 },
    { lat: 49.037534, lng: 19.3264552 },
    { lat: 49.0375312, lng: 19.3265859 },
    { lat: 49.0377304, lng: 19.3268931 },
    { lat: 49.0379721, lng: 19.3270448 },
    { lat: 49.037976, lng: 19.3271266 },
    { lat: 49.0387481, lng: 19.3277278 },
    { lat: 49.0397083, lng: 19.3286787 },
    { lat: 49.0404306, lng: 19.3291754 },
    { lat: 49.0407222, lng: 19.3292397 },
    { lat: 49.0410507, lng: 19.3295123 },
    { lat: 49.0409668, lng: 19.3298369 },
    { lat: 49.0405906, lng: 19.3303649 },
    { lat: 49.0393794, lng: 19.3302026 },
    { lat: 49.0386905, lng: 19.3300313 },
    { lat: 49.0374002, lng: 19.329535 },
    { lat: 49.0372282, lng: 19.3318987 },
    { lat: 49.0354681, lng: 19.3329074 },
    { lat: 49.034753, lng: 19.3333215 },
    { lat: 49.0341972, lng: 19.3332491 },
    { lat: 49.0341862, lng: 19.3333466 },
    { lat: 49.0341215, lng: 19.3333612 },
    { lat: 49.0340881, lng: 19.3334439 },
    { lat: 49.0340627, lng: 19.3336456 },
    { lat: 49.0340508, lng: 19.3339896 },
    { lat: 49.0340596, lng: 19.3341998 },
    { lat: 49.0340543, lng: 19.3342532 },
    { lat: 49.0340479, lng: 19.3343064 },
    { lat: 49.034001, lng: 19.3343391 },
    { lat: 49.0338918, lng: 19.3344168 },
    { lat: 49.0337926, lng: 19.3344866 },
    { lat: 49.0339573, lng: 19.3347527 },
    { lat: 49.0341425, lng: 19.3348542 },
    { lat: 49.0344686, lng: 19.3349133 },
    { lat: 49.0346601, lng: 19.3350034 },
    { lat: 49.0346068, lng: 19.335474 },
    { lat: 49.0346881, lng: 19.3354302 },
    { lat: 49.0349727, lng: 19.3352763 },
    { lat: 49.0350459, lng: 19.3352995 },
    { lat: 49.035205, lng: 19.3353493 },
    { lat: 49.0352415, lng: 19.3353607 },
    { lat: 49.0356008, lng: 19.3354726 },
    { lat: 49.0355679, lng: 19.3360919 },
    { lat: 49.0354482, lng: 19.3360608 },
    { lat: 49.0354239, lng: 19.3361425 },
    { lat: 49.0353607, lng: 19.3363545 },
    { lat: 49.0354676, lng: 19.336378 },
    { lat: 49.0355645, lng: 19.336399 },
    { lat: 49.0355671, lng: 19.3364285 },
    { lat: 49.0355811, lng: 19.3365797 },
    { lat: 49.0356772, lng: 19.3365889 },
    { lat: 49.0356885, lng: 19.3365898 },
    { lat: 49.036079, lng: 19.3366269 },
    { lat: 49.0365578, lng: 19.3369775 },
    { lat: 49.0370745, lng: 19.3371333 },
    { lat: 49.0370778, lng: 19.3371782 },
    { lat: 49.0371391, lng: 19.3378806 },
    { lat: 49.036992, lng: 19.337846 },
    { lat: 49.0369092, lng: 19.338118 },
    { lat: 49.0366778, lng: 19.3388728 },
    { lat: 49.0367834, lng: 19.3391784 },
    { lat: 49.0369874, lng: 19.339511 },
    { lat: 49.0371465, lng: 19.3396355 },
    { lat: 49.0372963, lng: 19.3397517 },
    { lat: 49.0373868, lng: 19.3395428 },
    { lat: 49.037597, lng: 19.339698 },
    { lat: 49.037737, lng: 19.3394427 },
    { lat: 49.0385634, lng: 19.3397845 },
    { lat: 49.0386296, lng: 19.3393714 },
    { lat: 49.0385742, lng: 19.3390577 },
    { lat: 49.0382815, lng: 19.339065 },
    { lat: 49.0381495, lng: 19.3383718 },
    { lat: 49.0381164, lng: 19.3376706 },
    { lat: 49.0384311, lng: 19.3379832 },
    { lat: 49.0388417, lng: 19.3386858 },
    { lat: 49.03906, lng: 19.3399688 },
    { lat: 49.0390029, lng: 19.3408792 },
    { lat: 49.0393324, lng: 19.341674 },
    { lat: 49.0392731, lng: 19.3420196 },
    { lat: 49.0386366, lng: 19.3420241 },
    { lat: 49.0380844, lng: 19.3416663 },
    { lat: 49.0376954, lng: 19.3414003 },
    { lat: 49.0376336, lng: 19.3413833 },
    { lat: 49.0364588, lng: 19.3410598 },
    { lat: 49.036116, lng: 19.3410316 },
    { lat: 49.0361304, lng: 19.3413368 },
    { lat: 49.0365099, lng: 19.3414767 },
    { lat: 49.0365523, lng: 19.3413251 },
    { lat: 49.0372034, lng: 19.3416491 },
    { lat: 49.0377489, lng: 19.3418894 },
    { lat: 49.0377696, lng: 19.3418983 },
    { lat: 49.0385738, lng: 19.3425084 },
    { lat: 49.038922, lng: 19.3425321 },
    { lat: 49.0394921, lng: 19.3429117 },
    { lat: 49.0395312, lng: 19.3431887 },
    { lat: 49.039406, lng: 19.3435231 },
    { lat: 49.0392009, lng: 19.3437607 },
    { lat: 49.039127, lng: 19.343961 },
    { lat: 49.0384585, lng: 19.344363 },
    { lat: 49.0385217, lng: 19.344651 },
    { lat: 49.0390435, lng: 19.3449134 },
    { lat: 49.0394334, lng: 19.345225 },
    { lat: 49.0396108, lng: 19.3454251 },
    { lat: 49.040001, lng: 19.345001 },
    { lat: 49.0401033, lng: 19.3448982 },
    { lat: 49.040312, lng: 19.3448068 },
    { lat: 49.0404746, lng: 19.3443131 },
    { lat: 49.040535, lng: 19.3438524 },
    { lat: 49.0406292, lng: 19.3435569 },
    { lat: 49.0408757, lng: 19.3431402 },
    { lat: 49.0409654, lng: 19.3429192 },
    { lat: 49.0410111, lng: 19.3424766 },
    { lat: 49.0415322, lng: 19.3410988 },
    { lat: 49.0415349, lng: 19.3408711 },
    { lat: 49.0415324, lng: 19.340653 },
    { lat: 49.0415281, lng: 19.339847 },
    { lat: 49.0413926, lng: 19.3388401 },
    { lat: 49.0415728, lng: 19.3390054 },
    { lat: 49.0416482, lng: 19.3393607 },
    { lat: 49.0419242, lng: 19.3393101 },
    { lat: 49.0422723, lng: 19.3398292 },
    { lat: 49.0425037, lng: 19.3396225 },
    { lat: 49.0428565, lng: 19.3398185 },
    { lat: 49.0430411, lng: 19.3400505 },
    { lat: 49.0429868, lng: 19.3411661 },
    { lat: 49.0430474, lng: 19.3418539 },
    { lat: 49.0430674, lng: 19.3420641 },
    { lat: 49.0439306, lng: 19.3421533 },
    { lat: 49.0439774, lng: 19.3409986 },
    { lat: 49.0444286, lng: 19.3412219 },
    { lat: 49.0449852, lng: 19.3419561 },
    { lat: 49.0460266, lng: 19.3422016 },
    { lat: 49.0460431, lng: 19.34145 },
    { lat: 49.0462805, lng: 19.3407019 },
    { lat: 49.047631, lng: 19.340495 },
    { lat: 49.0494294, lng: 19.3406545 },
    { lat: 49.048779, lng: 19.3424692 },
    { lat: 49.0497393, lng: 19.343543 },
    { lat: 49.0503103, lng: 19.3430808 },
    { lat: 49.0502004, lng: 19.3402084 },
    { lat: 49.0502, lng: 19.3401753 },
    { lat: 49.0501997, lng: 19.34012 },
    { lat: 49.0501926, lng: 19.3379438 },
    { lat: 49.0503891, lng: 19.3354712 },
    { lat: 49.0514268, lng: 19.3357058 },
    { lat: 49.0521637, lng: 19.3359371 },
    { lat: 49.0525058, lng: 19.3360699 },
    { lat: 49.0549504, lng: 19.3369332 },
    { lat: 49.0552319, lng: 19.3352836 },
    { lat: 49.0553032, lng: 19.3349075 },
    { lat: 49.0553152, lng: 19.334852 },
    { lat: 49.055369, lng: 19.3348726 },
    { lat: 49.0553794, lng: 19.3348011 },
    { lat: 49.0553804, lng: 19.3347787 },
    { lat: 49.0553518, lng: 19.3346226 },
    { lat: 49.0553842, lng: 19.3345497 },
    { lat: 49.0561965, lng: 19.3350174 },
    { lat: 49.057217, lng: 19.3349126 },
    { lat: 49.0580274, lng: 19.3350111 },
    { lat: 49.0582928, lng: 19.3349935 },
    { lat: 49.0590754, lng: 19.335149 },
    { lat: 49.0596136, lng: 19.3352576 },
    { lat: 49.0602269, lng: 19.3356925 },
    { lat: 49.0605294, lng: 19.3356525 },
    { lat: 49.0608403, lng: 19.3355804 },
    { lat: 49.0614433, lng: 19.3354361 },
    { lat: 49.0619925, lng: 19.3354205 },
    { lat: 49.0620926, lng: 19.3354649 },
    { lat: 49.0621327, lng: 19.3354774 },
    { lat: 49.0621727, lng: 19.3354655 },
    { lat: 49.0622739, lng: 19.3353433 },
    { lat: 49.0624433, lng: 19.3352281 },
    { lat: 49.0625398, lng: 19.3351723 },
    { lat: 49.0626341, lng: 19.3351447 },
    { lat: 49.062699, lng: 19.3351333 },
    { lat: 49.0631702, lng: 19.3351852 },
    { lat: 49.0638593, lng: 19.3356938 },
    { lat: 49.0642104, lng: 19.3360424 },
    { lat: 49.0645405, lng: 19.33637 },
    { lat: 49.0646502, lng: 19.3364978 },
    { lat: 49.0647943, lng: 19.3366632 },
    { lat: 49.0648807, lng: 19.3369175 },
    { lat: 49.0648951, lng: 19.3369623 },
    { lat: 49.0649088, lng: 19.3369874 },
    { lat: 49.064954, lng: 19.3370407 },
    { lat: 49.0651107, lng: 19.3371569 },
    { lat: 49.0652578, lng: 19.3372676 },
    { lat: 49.0654433, lng: 19.3375888 },
    { lat: 49.0654828, lng: 19.3376157 },
    { lat: 49.0655207, lng: 19.3376339 },
    { lat: 49.0656917, lng: 19.3376978 },
    { lat: 49.0657417, lng: 19.337752 },
    { lat: 49.0658327, lng: 19.338008 },
    { lat: 49.0659307, lng: 19.3380792 },
    { lat: 49.065985, lng: 19.338083 },
    { lat: 49.0659957, lng: 19.3383341 },
    { lat: 49.0659755, lng: 19.3383816 },
    { lat: 49.066176, lng: 19.3387166 },
    { lat: 49.0663688, lng: 19.3390535 },
    { lat: 49.066509, lng: 19.3392531 },
    { lat: 49.0667923, lng: 19.3394517 },
    { lat: 49.0670388, lng: 19.3397558 },
    { lat: 49.0671674, lng: 19.3399017 },
    { lat: 49.0673665, lng: 19.3402023 },
    { lat: 49.0673537, lng: 19.3401462 },
    { lat: 49.0674126, lng: 19.3398181 },
    { lat: 49.0678824, lng: 19.3400808 },
    { lat: 49.0682652, lng: 19.3402151 },
    { lat: 49.0684578, lng: 19.3400675 },
    { lat: 49.0683326, lng: 19.3380871 },
    { lat: 49.0683299, lng: 19.3380547 },
    { lat: 49.0685442, lng: 19.3380571 },
    { lat: 49.0687949, lng: 19.3378298 },
    { lat: 49.068853, lng: 19.3385351 },
    { lat: 49.0689546, lng: 19.3395164 },
    { lat: 49.0691088, lng: 19.3394873 },
    { lat: 49.0691488, lng: 19.3398837 },
    { lat: 49.0691633, lng: 19.3401157 },
    { lat: 49.0691813, lng: 19.3401163 },
    { lat: 49.0696126, lng: 19.3399189 },
    { lat: 49.0699474, lng: 19.3395616 },
    { lat: 49.070014, lng: 19.3382068 },
    { lat: 49.070299, lng: 19.3323584 },
    { lat: 49.0703084, lng: 19.3322984 },
    { lat: 49.0664676, lng: 19.3308415 },
    { lat: 49.0676581, lng: 19.3249423 },
    { lat: 49.0668821, lng: 19.3255266 },
    { lat: 49.06681, lng: 19.32555 },
    { lat: 49.0667768, lng: 19.3254677 },
    { lat: 49.0667621, lng: 19.3253964 },
    { lat: 49.0656776, lng: 19.3236007 },
    { lat: 49.0651952, lng: 19.3236529 },
    { lat: 49.0650769, lng: 19.3217927 },
    { lat: 49.0650031, lng: 19.3210471 },
    { lat: 49.0650033, lng: 19.3210269 },
    { lat: 49.0645853, lng: 19.3211026 },
    { lat: 49.0640272, lng: 19.321027 },
    { lat: 49.0635951, lng: 19.3208943 },
    { lat: 49.0625648, lng: 19.3206502 },
    { lat: 49.0624039, lng: 19.32061 },
    { lat: 49.0614015, lng: 19.3201482 },
    { lat: 49.0611267, lng: 19.3198416 },
    { lat: 49.0605515, lng: 19.3197395 },
    { lat: 49.0608399, lng: 19.3174793 },
    { lat: 49.0607648, lng: 19.3159188 },
    { lat: 49.0606002, lng: 19.3151341 },
    { lat: 49.060336, lng: 19.3138647 },
    { lat: 49.0578489, lng: 19.3142727 },
    { lat: 49.0577855, lng: 19.315052 },
    { lat: 49.056913, lng: 19.3152387 },
    { lat: 49.0559185, lng: 19.3152987 },
    { lat: 49.0559605, lng: 19.3137594 },
    { lat: 49.0559751, lng: 19.3119071 },
    { lat: 49.0559791, lng: 19.31128 },
    { lat: 49.0537863, lng: 19.3124988 },
    { lat: 49.0534705, lng: 19.3133495 },
    { lat: 49.0521384, lng: 19.3142278 },
    { lat: 49.0519573, lng: 19.3149236 },
    { lat: 49.0515224, lng: 19.3157576 },
    { lat: 49.0512815, lng: 19.3157387 },
    { lat: 49.0507349, lng: 19.3164768 },
    { lat: 49.0505494, lng: 19.3164152 },
    { lat: 49.0503738, lng: 19.3162779 },
    { lat: 49.0492494, lng: 19.3159993 },
    { lat: 49.0483179, lng: 19.3162018 },
    { lat: 49.0477775, lng: 19.3159278 },
    { lat: 49.046693, lng: 19.3142591 },
  ],
  Lúčky: [
    { lat: 49.1170892, lng: 19.3912276 },
    { lat: 49.1168487, lng: 19.3915545 },
    { lat: 49.1165504, lng: 19.3919973 },
    { lat: 49.116366, lng: 19.392803 },
    { lat: 49.1163578, lng: 19.3933234 },
    { lat: 49.1163629, lng: 19.3951468 },
    { lat: 49.1164914, lng: 19.3960642 },
    { lat: 49.1172836, lng: 19.3971051 },
    { lat: 49.1173074, lng: 19.3977361 },
    { lat: 49.1173218, lng: 19.3982116 },
    { lat: 49.1174117, lng: 19.3993646 },
    { lat: 49.1178027, lng: 19.3998752 },
    { lat: 49.1180691, lng: 19.4003155 },
    { lat: 49.1183676, lng: 19.4009437 },
    { lat: 49.1185218, lng: 19.4013479 },
    { lat: 49.119296, lng: 19.4051716 },
    { lat: 49.1195857, lng: 19.4078558 },
    { lat: 49.1192275, lng: 19.4068426 },
    { lat: 49.1190405, lng: 19.4063354 },
    { lat: 49.1176628, lng: 19.4057174 },
    { lat: 49.1168479, lng: 19.4065635 },
    { lat: 49.1163419, lng: 19.4090796 },
    { lat: 49.1162268, lng: 19.4096303 },
    { lat: 49.1168464, lng: 19.4098669 },
    { lat: 49.1166496, lng: 19.4111592 },
    { lat: 49.116639, lng: 19.4112452 },
    { lat: 49.1165193, lng: 19.4120626 },
    { lat: 49.1164378, lng: 19.4135078 },
    { lat: 49.1166332, lng: 19.4148236 },
    { lat: 49.1180171, lng: 19.415385 },
    { lat: 49.1184587, lng: 19.4153557 },
    { lat: 49.1184512, lng: 19.4154644 },
    { lat: 49.1182874, lng: 19.4158404 },
    { lat: 49.1180003, lng: 19.4164319 },
    { lat: 49.1182027, lng: 19.4171213 },
    { lat: 49.1186554, lng: 19.4175991 },
    { lat: 49.1191721, lng: 19.4183636 },
    { lat: 49.1183323, lng: 19.4191115 },
    { lat: 49.118031, lng: 19.4196606 },
    { lat: 49.1194266, lng: 19.4215888 },
    { lat: 49.1204879, lng: 19.4207814 },
    { lat: 49.1211364, lng: 19.4201372 },
    { lat: 49.1223536, lng: 19.4186621 },
    { lat: 49.123191, lng: 19.4177898 },
    { lat: 49.1233552, lng: 19.4174915 },
    { lat: 49.1234018, lng: 19.4173576 },
    { lat: 49.1235761, lng: 19.4171794 },
    { lat: 49.1235867, lng: 19.4168753 },
    { lat: 49.1235974, lng: 19.41674 },
    { lat: 49.1237016, lng: 19.4167769 },
    { lat: 49.1237506, lng: 19.4166662 },
    { lat: 49.1237484, lng: 19.4164722 },
    { lat: 49.1238482, lng: 19.4161001 },
    { lat: 49.1239064, lng: 19.4158287 },
    { lat: 49.1239315, lng: 19.4157631 },
    { lat: 49.123993, lng: 19.4156027 },
    { lat: 49.1240031, lng: 19.4154793 },
    { lat: 49.1239757, lng: 19.4152056 },
    { lat: 49.123919, lng: 19.4149314 },
    { lat: 49.1239339, lng: 19.4146017 },
    { lat: 49.123929, lng: 19.4144212 },
    { lat: 49.1240145, lng: 19.4140101 },
    { lat: 49.1240585, lng: 19.4138891 },
    { lat: 49.1240067, lng: 19.4135025 },
    { lat: 49.1239867, lng: 19.4132399 },
    { lat: 49.1240146, lng: 19.4130886 },
    { lat: 49.1239873, lng: 19.4127901 },
    { lat: 49.1240062, lng: 19.4123994 },
    { lat: 49.1229312, lng: 19.4128599 },
    { lat: 49.1229419, lng: 19.4126335 },
    { lat: 49.1230115, lng: 19.4113182 },
    { lat: 49.12325, lng: 19.4104861 },
    { lat: 49.1237598, lng: 19.4100838 },
    { lat: 49.1246395, lng: 19.4093504 },
    { lat: 49.1258137, lng: 19.4088672 },
    { lat: 49.1267177, lng: 19.4092318 },
    { lat: 49.1278269, lng: 19.4092889 },
    { lat: 49.1291886, lng: 19.4100743 },
    { lat: 49.1299194, lng: 19.4104739 },
    { lat: 49.1312736, lng: 19.4102483 },
    { lat: 49.1322623, lng: 19.4101813 },
    { lat: 49.1360792, lng: 19.4109832 },
    { lat: 49.1369165, lng: 19.4109117 },
    { lat: 49.1377838, lng: 19.4101795 },
    { lat: 49.1381483, lng: 19.4089797 },
    { lat: 49.13856, lng: 19.4074369 },
    { lat: 49.1392054, lng: 19.406342 },
    { lat: 49.1392093, lng: 19.4060152 },
    { lat: 49.1404883, lng: 19.405605 },
    { lat: 49.1425314, lng: 19.405337 },
    { lat: 49.1432325, lng: 19.404592 },
    { lat: 49.1432568, lng: 19.4045807 },
    { lat: 49.1439192, lng: 19.4042124 },
    { lat: 49.1446882, lng: 19.4037642 },
    { lat: 49.1455841, lng: 19.4005269 },
    { lat: 49.1461428, lng: 19.400931 },
    { lat: 49.1464889, lng: 19.4012229 },
    { lat: 49.1470876, lng: 19.4012801 },
    { lat: 49.1479076, lng: 19.4016077 },
    { lat: 49.149096, lng: 19.4015996 },
    { lat: 49.1495457, lng: 19.4018197 },
    { lat: 49.1503212, lng: 19.402307 },
    { lat: 49.1507208, lng: 19.4024401 },
    { lat: 49.1512901, lng: 19.4013802 },
    { lat: 49.1519717, lng: 19.4007998 },
    { lat: 49.1523764, lng: 19.4007534 },
    { lat: 49.1529455, lng: 19.4006313 },
    { lat: 49.1532825, lng: 19.4005368 },
    { lat: 49.1535321, lng: 19.4004053 },
    { lat: 49.1536335, lng: 19.4001132 },
    { lat: 49.1549701, lng: 19.40094 },
    { lat: 49.1575363, lng: 19.4000246 },
    { lat: 49.1576261, lng: 19.3998226 },
    { lat: 49.1577506, lng: 19.3997378 },
    { lat: 49.1578552, lng: 19.3995148 },
    { lat: 49.1579451, lng: 19.3993661 },
    { lat: 49.1583035, lng: 19.3990057 },
    { lat: 49.1585684, lng: 19.3986007 },
    { lat: 49.1587436, lng: 19.3984377 },
    { lat: 49.1590214, lng: 19.3982574 },
    { lat: 49.1593869, lng: 19.3981962 },
    { lat: 49.159652, lng: 19.3982481 },
    { lat: 49.1599408, lng: 19.3984001 },
    { lat: 49.1603904, lng: 19.398594 },
    { lat: 49.1609147, lng: 19.398984 },
    { lat: 49.1612442, lng: 19.3989 },
    { lat: 49.1617869, lng: 19.3988412 },
    { lat: 49.1622869, lng: 19.3993894 },
    { lat: 49.1626556, lng: 19.3994873 },
    { lat: 49.1630666, lng: 19.3997236 },
    { lat: 49.163239, lng: 19.4001856 },
    { lat: 49.1634289, lng: 19.4002933 },
    { lat: 49.1635715, lng: 19.4005698 },
    { lat: 49.163812, lng: 19.4008012 },
    { lat: 49.1638474, lng: 19.401053 },
    { lat: 49.1640899, lng: 19.4014689 },
    { lat: 49.1642506, lng: 19.4016294 },
    { lat: 49.1647811, lng: 19.4014992 },
    { lat: 49.1650525, lng: 19.4017402 },
    { lat: 49.1654963, lng: 19.402314 },
    { lat: 49.1658123, lng: 19.4026917 },
    { lat: 49.1666081, lng: 19.4037106 },
    { lat: 49.166788, lng: 19.4041611 },
    { lat: 49.1668579, lng: 19.4046685 },
    { lat: 49.1668019, lng: 19.4051581 },
    { lat: 49.1668827, lng: 19.4055902 },
    { lat: 49.1672518, lng: 19.4059168 },
    { lat: 49.1677639, lng: 19.4073291 },
    { lat: 49.1680026, lng: 19.4076882 },
    { lat: 49.1682334, lng: 19.4080972 },
    { lat: 49.1685967, lng: 19.4086884 },
    { lat: 49.1686444, lng: 19.4097841 },
    { lat: 49.1686984, lng: 19.4104895 },
    { lat: 49.1688538, lng: 19.4107731 },
    { lat: 49.1690005, lng: 19.4108753 },
    { lat: 49.1692108, lng: 19.4109401 },
    { lat: 49.1695322, lng: 19.4117944 },
    { lat: 49.1696166, lng: 19.4119135 },
    { lat: 49.169758, lng: 19.4120174 },
    { lat: 49.1698906, lng: 19.4120355 },
    { lat: 49.1701054, lng: 19.4119777 },
    { lat: 49.170694, lng: 19.4105091 },
    { lat: 49.1710627, lng: 19.4103939 },
    { lat: 49.1714078, lng: 19.4101074 },
    { lat: 49.1717097, lng: 19.4101291 },
    { lat: 49.1718886, lng: 19.4100525 },
    { lat: 49.1721842, lng: 19.4100623 },
    { lat: 49.1723428, lng: 19.410004 },
    { lat: 49.1726513, lng: 19.4110864 },
    { lat: 49.172611, lng: 19.411786 },
    { lat: 49.17263, lng: 19.411784 },
    { lat: 49.173708, lng: 19.411861 },
    { lat: 49.17463, lng: 19.411957 },
    { lat: 49.175457, lng: 19.412052 },
    { lat: 49.175315, lng: 19.410851 },
    { lat: 49.175309, lng: 19.410419 },
    { lat: 49.175203, lng: 19.409187 },
    { lat: 49.175197, lng: 19.409119 },
    { lat: 49.174906, lng: 19.407014 },
    { lat: 49.174874, lng: 19.406854 },
    { lat: 49.174731, lng: 19.406569 },
    { lat: 49.174556, lng: 19.406343 },
    { lat: 49.174259, lng: 19.405052 },
    { lat: 49.174245, lng: 19.403967 },
    { lat: 49.174217, lng: 19.403105 },
    { lat: 49.174197, lng: 19.402125 },
    { lat: 49.174224, lng: 19.401228 },
    { lat: 49.174588, lng: 19.399601 },
    { lat: 49.174517, lng: 19.399348 },
    { lat: 49.17423, lng: 19.398679 },
    { lat: 49.174078, lng: 19.398344 },
    { lat: 49.173983, lng: 19.397868 },
    { lat: 49.173876, lng: 19.397329 },
    { lat: 49.172951, lng: 19.395033 },
    { lat: 49.173018, lng: 19.394727 },
    { lat: 49.172972, lng: 19.394117 },
    { lat: 49.172948, lng: 19.394014 },
    { lat: 49.172891, lng: 19.393663 },
    { lat: 49.172789, lng: 19.39324 },
    { lat: 49.172744, lng: 19.39287 },
    { lat: 49.172646, lng: 19.392184 },
    { lat: 49.172817, lng: 19.391642 },
    { lat: 49.172826, lng: 19.391547 },
    { lat: 49.172952, lng: 19.391055 },
    { lat: 49.172932, lng: 19.391049 },
    { lat: 49.172957, lng: 19.390867 },
    { lat: 49.173, lng: 19.390478 },
    { lat: 49.173044, lng: 19.389854 },
    { lat: 49.173059, lng: 19.389435 },
    { lat: 49.173221, lng: 19.388758 },
    { lat: 49.173263, lng: 19.388416 },
    { lat: 49.173401, lng: 19.387936 },
    { lat: 49.173493, lng: 19.387485 },
    { lat: 49.173618, lng: 19.387194 },
    { lat: 49.173739, lng: 19.38658 },
    { lat: 49.173757, lng: 19.386598 },
    { lat: 49.174741, lng: 19.386059 },
    { lat: 49.175035, lng: 19.38578 },
    { lat: 49.175378, lng: 19.384941 },
    { lat: 49.1754, lng: 19.384925 },
    { lat: 49.175265, lng: 19.384774 },
    { lat: 49.175242, lng: 19.384541 },
    { lat: 49.175184, lng: 19.384205 },
    { lat: 49.17519, lng: 19.384047 },
    { lat: 49.175198, lng: 19.383878 },
    { lat: 49.175303, lng: 19.383614 },
    { lat: 49.175282, lng: 19.383384 },
    { lat: 49.175137, lng: 19.382999 },
    { lat: 49.174959, lng: 19.382523 },
    { lat: 49.174829, lng: 19.382177 },
    { lat: 49.174786, lng: 19.382029 },
    { lat: 49.17465, lng: 19.381546 },
    { lat: 49.174523, lng: 19.381099 },
    { lat: 49.174407, lng: 19.380691 },
    { lat: 49.174393, lng: 19.380645 },
    { lat: 49.174392, lng: 19.380435 },
    { lat: 49.174389, lng: 19.380256 },
    { lat: 49.174386, lng: 19.38001 },
    { lat: 49.174385, lng: 19.379856 },
    { lat: 49.174379, lng: 19.379392 },
    { lat: 49.174379, lng: 19.379343 },
    { lat: 49.174332, lng: 19.379118 },
    { lat: 49.174263, lng: 19.378923 },
    { lat: 49.174221, lng: 19.378801 },
    { lat: 49.174205, lng: 19.378757 },
    { lat: 49.174132, lng: 19.378554 },
    { lat: 49.17394, lng: 19.377553 },
    { lat: 49.17396, lng: 19.376886 },
    { lat: 49.173987, lng: 19.376859 },
    { lat: 49.174006, lng: 19.37684 },
    { lat: 49.174052, lng: 19.376794 },
    { lat: 49.174093, lng: 19.376751 },
    { lat: 49.174171, lng: 19.376673 },
    { lat: 49.174618, lng: 19.376223 },
    { lat: 49.175039, lng: 19.376089 },
    { lat: 49.175393, lng: 19.375977 },
    { lat: 49.175609, lng: 19.375878 },
    { lat: 49.176137, lng: 19.37486 },
    { lat: 49.17624, lng: 19.374677 },
    { lat: 49.176262, lng: 19.374619 },
    { lat: 49.176488, lng: 19.374022 },
    { lat: 49.176553, lng: 19.373926 },
    { lat: 49.176881, lng: 19.373411 },
    { lat: 49.17703, lng: 19.373184 },
    { lat: 49.17675, lng: 19.372416 },
    { lat: 49.176473, lng: 19.371361 },
    { lat: 49.1762, lng: 19.370327 },
    { lat: 49.175151, lng: 19.36907 },
    { lat: 49.174888, lng: 19.368611 },
    { lat: 49.17481, lng: 19.36833 },
    { lat: 49.174651, lng: 19.367793 },
    { lat: 49.174591, lng: 19.367591 },
    { lat: 49.174609, lng: 19.36718 },
    { lat: 49.174337, lng: 19.366449 },
    { lat: 49.174164, lng: 19.365976 },
    { lat: 49.173992, lng: 19.365895 },
    { lat: 49.173763, lng: 19.365473 },
    { lat: 49.173777, lng: 19.364858 },
    { lat: 49.173518, lng: 19.36446 },
    { lat: 49.173387, lng: 19.363598 },
    { lat: 49.173051, lng: 19.362782 },
    { lat: 49.172269, lng: 19.36164 },
    { lat: 49.172155, lng: 19.361074 },
    { lat: 49.171487, lng: 19.359854 },
    { lat: 49.170875, lng: 19.359008 },
    { lat: 49.170673, lng: 19.358237 },
    { lat: 49.17065, lng: 19.358044 },
    { lat: 49.170623, lng: 19.357807 },
    { lat: 49.168454, lng: 19.357202 },
    { lat: 49.167199, lng: 19.357571 },
    { lat: 49.166755, lng: 19.357507 },
    { lat: 49.165551, lng: 19.354906 },
    { lat: 49.165289, lng: 19.354434 },
    { lat: 49.165264, lng: 19.354385 },
    { lat: 49.164717, lng: 19.353695 },
    { lat: 49.162946, lng: 19.351261 },
    { lat: 49.161277, lng: 19.349847 },
    { lat: 49.16027, lng: 19.348366 },
    { lat: 49.159401, lng: 19.348709 },
    { lat: 49.15924, lng: 19.34865 },
    { lat: 49.158908, lng: 19.348763 },
    { lat: 49.158721, lng: 19.348941 },
    { lat: 49.158461, lng: 19.348957 },
    { lat: 49.158075, lng: 19.349193 },
    { lat: 49.157824, lng: 19.349276 },
    { lat: 49.157451, lng: 19.349261 },
    { lat: 49.157058, lng: 19.34911 },
    { lat: 49.156449, lng: 19.348589 },
    { lat: 49.156223, lng: 19.348547 },
    { lat: 49.155845, lng: 19.348616 },
    { lat: 49.155371, lng: 19.348545 },
    { lat: 49.154995, lng: 19.348215 },
    { lat: 49.154691, lng: 19.34807 },
    { lat: 49.1520579, lng: 19.3448555 },
    { lat: 49.1508359, lng: 19.3433755 },
    { lat: 49.1507245, lng: 19.3435453 },
    { lat: 49.1506141, lng: 19.3437175 },
    { lat: 49.150576, lng: 19.3437669 },
    { lat: 49.1501429, lng: 19.3440719 },
    { lat: 49.149369, lng: 19.34453 },
    { lat: 49.1479285, lng: 19.3447846 },
    { lat: 49.1479132, lng: 19.3443313 },
    { lat: 49.1468597, lng: 19.3448486 },
    { lat: 49.1466656, lng: 19.3450603 },
    { lat: 49.1465279, lng: 19.3451053 },
    { lat: 49.1454412, lng: 19.345478 },
    { lat: 49.1450883, lng: 19.3462679 },
    { lat: 49.1429517, lng: 19.3456432 },
    { lat: 49.1426425, lng: 19.34693 },
    { lat: 49.1417172, lng: 19.3507816 },
    { lat: 49.1406715, lng: 19.3529159 },
    { lat: 49.1402933, lng: 19.353716 },
    { lat: 49.1389312, lng: 19.3548521 },
    { lat: 49.1387101, lng: 19.3545836 },
    { lat: 49.138392, lng: 19.3547951 },
    { lat: 49.137803, lng: 19.3546841 },
    { lat: 49.1375394, lng: 19.3553309 },
    { lat: 49.1374098, lng: 19.3556172 },
    { lat: 49.1368369, lng: 19.3559932 },
    { lat: 49.135488, lng: 19.3574099 },
    { lat: 49.1345852, lng: 19.3587026 },
    { lat: 49.1339828, lng: 19.3591452 },
    { lat: 49.1341214, lng: 19.3603407 },
    { lat: 49.1341324, lng: 19.3604327 },
    { lat: 49.1342153, lng: 19.3611431 },
    { lat: 49.1338438, lng: 19.3617494 },
    { lat: 49.1338114, lng: 19.3618037 },
    { lat: 49.1336576, lng: 19.3620547 },
    { lat: 49.1327916, lng: 19.3638942 },
    { lat: 49.1316236, lng: 19.3662136 },
    { lat: 49.1313429, lng: 19.3669798 },
    { lat: 49.1304749, lng: 19.3668495 },
    { lat: 49.1299772, lng: 19.3686144 },
    { lat: 49.1301303, lng: 19.3709671 },
    { lat: 49.1303154, lng: 19.3736869 },
    { lat: 49.1289635, lng: 19.3764383 },
    { lat: 49.1278778, lng: 19.3782311 },
    { lat: 49.1277078, lng: 19.380451 },
    { lat: 49.127269, lng: 19.3824177 },
    { lat: 49.1258849, lng: 19.3826041 },
    { lat: 49.1249135, lng: 19.3816867 },
    { lat: 49.1233391, lng: 19.3861895 },
    { lat: 49.1212346, lng: 19.388326 },
    { lat: 49.1203611, lng: 19.388296 },
    { lat: 49.1186801, lng: 19.3897023 },
    { lat: 49.1170892, lng: 19.3912276 },
  ],
  Martinček: [
    { lat: 49.0848988, lng: 19.3298008 },
    { lat: 49.0850161, lng: 19.3300195 },
    { lat: 49.0853827, lng: 19.3314096 },
    { lat: 49.0854538, lng: 19.3317189 },
    { lat: 49.0861073, lng: 19.3331243 },
    { lat: 49.0864477, lng: 19.3337206 },
    { lat: 49.0866349, lng: 19.3342729 },
    { lat: 49.0867451, lng: 19.334483 },
    { lat: 49.0871779, lng: 19.3355107 },
    { lat: 49.0874066, lng: 19.3362472 },
    { lat: 49.0884284, lng: 19.3371454 },
    { lat: 49.0886623, lng: 19.3375883 },
    { lat: 49.0887398, lng: 19.3387042 },
    { lat: 49.0889843, lng: 19.3389676 },
    { lat: 49.089257, lng: 19.3392754 },
    { lat: 49.089696, lng: 19.3396444 },
    { lat: 49.0906337, lng: 19.3403886 },
    { lat: 49.0911076, lng: 19.340924 },
    { lat: 49.0911825, lng: 19.3415091 },
    { lat: 49.0912526, lng: 19.3417039 },
    { lat: 49.0914701, lng: 19.3418495 },
    { lat: 49.0916105, lng: 19.3424127 },
    { lat: 49.0916186, lng: 19.3428437 },
    { lat: 49.0922167, lng: 19.3433892 },
    { lat: 49.0927826, lng: 19.3434158 },
    { lat: 49.0932539, lng: 19.3436142 },
    { lat: 49.0933498, lng: 19.3436148 },
    { lat: 49.0934031, lng: 19.3433378 },
    { lat: 49.0935412, lng: 19.3429405 },
    { lat: 49.094055, lng: 19.3415778 },
    { lat: 49.094165, lng: 19.341331 },
    { lat: 49.0941129, lng: 19.3400331 },
    { lat: 49.0942376, lng: 19.3401931 },
    { lat: 49.0944601, lng: 19.3398922 },
    { lat: 49.0951148, lng: 19.3393182 },
    { lat: 49.0956058, lng: 19.3389289 },
    { lat: 49.0957471, lng: 19.3387962 },
    { lat: 49.0963968, lng: 19.3381364 },
    { lat: 49.0967552, lng: 19.3377785 },
    { lat: 49.0968153, lng: 19.3373817 },
    { lat: 49.0969506, lng: 19.3369555 },
    { lat: 49.0972809, lng: 19.3361695 },
    { lat: 49.0973915, lng: 19.3364354 },
    { lat: 49.0973104, lng: 19.3366291 },
    { lat: 49.0972982, lng: 19.3368455 },
    { lat: 49.0976026, lng: 19.3365615 },
    { lat: 49.0978645, lng: 19.336426 },
    { lat: 49.0982256, lng: 19.3360556 },
    { lat: 49.0984414, lng: 19.3357798 },
    { lat: 49.0985386, lng: 19.3359073 },
    { lat: 49.0985754, lng: 19.3359709 },
    { lat: 49.098693, lng: 19.3362169 },
    { lat: 49.0988067, lng: 19.3365738 },
    { lat: 49.099049, lng: 19.3373587 },
    { lat: 49.0993573, lng: 19.3382071 },
    { lat: 49.0994411, lng: 19.338121 },
    { lat: 49.0994372, lng: 19.3380387 },
    { lat: 49.0994686, lng: 19.3378432 },
    { lat: 49.0996711, lng: 19.3374556 },
    { lat: 49.0999454, lng: 19.3374187 },
    { lat: 49.1004525, lng: 19.3371298 },
    { lat: 49.1005945, lng: 19.3377012 },
    { lat: 49.100594, lng: 19.3377153 },
    { lat: 49.1006851, lng: 19.3376143 },
    { lat: 49.1007691, lng: 19.337603 },
    { lat: 49.1008786, lng: 19.3375625 },
    { lat: 49.1009561, lng: 19.3375084 },
    { lat: 49.1007299, lng: 19.3352475 },
    { lat: 49.1008842, lng: 19.3352705 },
    { lat: 49.1012353, lng: 19.3353784 },
    { lat: 49.1016922, lng: 19.3355327 },
    { lat: 49.1017039, lng: 19.3357303 },
    { lat: 49.101854, lng: 19.3356168 },
    { lat: 49.1021345, lng: 19.3357806 },
    { lat: 49.1023669, lng: 19.3360263 },
    { lat: 49.1025767, lng: 19.3361497 },
    { lat: 49.1025884, lng: 19.3361815 },
    { lat: 49.102848, lng: 19.3363074 },
    { lat: 49.10293, lng: 19.3363732 },
    { lat: 49.1030425, lng: 19.3363969 },
    { lat: 49.1033342, lng: 19.3361761 },
    { lat: 49.1034069, lng: 19.3362818 },
    { lat: 49.1037566, lng: 19.3361011 },
    { lat: 49.1040184, lng: 19.3357735 },
    { lat: 49.1043975, lng: 19.3356399 },
    { lat: 49.1047381, lng: 19.3361716 },
    { lat: 49.1050267, lng: 19.3366007 },
    { lat: 49.1052647, lng: 19.3361999 },
    { lat: 49.1054882, lng: 19.3358017 },
    { lat: 49.1058179, lng: 19.3353634 },
    { lat: 49.1059557, lng: 19.3352246 },
    { lat: 49.1061332, lng: 19.335258 },
    { lat: 49.1063383, lng: 19.3351906 },
    { lat: 49.1064942, lng: 19.3350062 },
    { lat: 49.1065566, lng: 19.3348721 },
    { lat: 49.1065711, lng: 19.3343651 },
    { lat: 49.1066593, lng: 19.3341572 },
    { lat: 49.1068236, lng: 19.3341061 },
    { lat: 49.1073354, lng: 19.3337277 },
    { lat: 49.1078373, lng: 19.3335436 },
    { lat: 49.1080736, lng: 19.3334418 },
    { lat: 49.1087773, lng: 19.3330197 },
    { lat: 49.1095874, lng: 19.3326363 },
    { lat: 49.1098329, lng: 19.3325151 },
    { lat: 49.1104289, lng: 19.3321742 },
    { lat: 49.1110702, lng: 19.3318158 },
    { lat: 49.1117156, lng: 19.331572 },
    { lat: 49.1118119, lng: 19.331561 },
    { lat: 49.1127197, lng: 19.3316205 },
    { lat: 49.1133323, lng: 19.3317066 },
    { lat: 49.1136778, lng: 19.3317651 },
    { lat: 49.1138701, lng: 19.3319013 },
    { lat: 49.1140088, lng: 19.3320238 },
    { lat: 49.1142011, lng: 19.3321107 },
    { lat: 49.114976, lng: 19.3327661 },
    { lat: 49.1152955, lng: 19.3329908 },
    { lat: 49.116025, lng: 19.333571 },
    { lat: 49.1164873, lng: 19.3339139 },
    { lat: 49.1168276, lng: 19.3341752 },
    { lat: 49.1171384, lng: 19.3344294 },
    { lat: 49.1173758, lng: 19.3346163 },
    { lat: 49.1177232, lng: 19.3347859 },
    { lat: 49.118171, lng: 19.3351786 },
    { lat: 49.1185431, lng: 19.3355167 },
    { lat: 49.1188761, lng: 19.3357425 },
    { lat: 49.1192004, lng: 19.3359789 },
    { lat: 49.1196677, lng: 19.3363469 },
    { lat: 49.1201869, lng: 19.3366505 },
    { lat: 49.1206425, lng: 19.3369726 },
    { lat: 49.121207, lng: 19.3374947 },
    { lat: 49.122162, lng: 19.3383393 },
    { lat: 49.1217745, lng: 19.3371238 },
    { lat: 49.1213774, lng: 19.3360905 },
    { lat: 49.1209817, lng: 19.3349616 },
    { lat: 49.1204585, lng: 19.3336002 },
    { lat: 49.1200475, lng: 19.3326281 },
    { lat: 49.1199976, lng: 19.3316413 },
    { lat: 49.1197488, lng: 19.3316934 },
    { lat: 49.1199571, lng: 19.3309038 },
    { lat: 49.1207839, lng: 19.3298197 },
    { lat: 49.1206918, lng: 19.3285654 },
    { lat: 49.12112, lng: 19.3277772 },
    { lat: 49.1204579, lng: 19.3266208 },
    { lat: 49.1188806, lng: 19.3261762 },
    { lat: 49.1186201, lng: 19.3260292 },
    { lat: 49.1182253, lng: 19.325906 },
    { lat: 49.1179246, lng: 19.3258357 },
    { lat: 49.1171467, lng: 19.3256394 },
    { lat: 49.1164252, lng: 19.3254696 },
    { lat: 49.1160067, lng: 19.325244 },
    { lat: 49.1156197, lng: 19.3249965 },
    { lat: 49.1149938, lng: 19.3248943 },
    { lat: 49.1146227, lng: 19.3245087 },
    { lat: 49.114199, lng: 19.324438 },
    { lat: 49.1138619, lng: 19.3239566 },
    { lat: 49.1133336, lng: 19.3234361 },
    { lat: 49.1125562, lng: 19.3232772 },
    { lat: 49.1118238, lng: 19.3231357 },
    { lat: 49.1115684, lng: 19.3230238 },
    { lat: 49.1109181, lng: 19.3226671 },
    { lat: 49.1106756, lng: 19.3224027 },
    { lat: 49.1102499, lng: 19.3217174 },
    { lat: 49.1100104, lng: 19.3212757 },
    { lat: 49.1098645, lng: 19.3210532 },
    { lat: 49.1093898, lng: 19.3211665 },
    { lat: 49.1086781, lng: 19.3213188 },
    { lat: 49.1081328, lng: 19.3217032 },
    { lat: 49.1081515, lng: 19.3228605 },
    { lat: 49.1077416, lng: 19.3227738 },
    { lat: 49.1070865, lng: 19.3226235 },
    { lat: 49.1058811, lng: 19.322349 },
    { lat: 49.105484, lng: 19.3220509 },
    { lat: 49.1052001, lng: 19.3219861 },
    { lat: 49.1049167, lng: 19.3220898 },
    { lat: 49.1046839, lng: 19.3219575 },
    { lat: 49.1043053, lng: 19.3219361 },
    { lat: 49.1041326, lng: 19.3225482 },
    { lat: 49.1040938, lng: 19.3229899 },
    { lat: 49.1041263, lng: 19.3235136 },
    { lat: 49.1040057, lng: 19.3243919 },
    { lat: 49.1038965, lng: 19.3253952 },
    { lat: 49.1036258, lng: 19.3275103 },
    { lat: 49.1035419, lng: 19.3287418 },
    { lat: 49.1034138, lng: 19.3300582 },
    { lat: 49.1031828, lng: 19.3310867 },
    { lat: 49.1032518, lng: 19.3313075 },
    { lat: 49.1031969, lng: 19.3314301 },
    { lat: 49.103259, lng: 19.3315754 },
    { lat: 49.1028132, lng: 19.3318505 },
    { lat: 49.102465, lng: 19.3320394 },
    { lat: 49.1021345, lng: 19.3320379 },
    { lat: 49.1014896, lng: 19.3319571 },
    { lat: 49.1007239, lng: 19.3320911 },
    { lat: 49.1004604, lng: 19.3322872 },
    { lat: 49.0998501, lng: 19.3327515 },
    { lat: 49.0997975, lng: 19.3327824 },
    { lat: 49.0995623, lng: 19.3334329 },
    { lat: 49.0992603, lng: 19.3340785 },
    { lat: 49.0992056, lng: 19.3341849 },
    { lat: 49.0989411, lng: 19.3346202 },
    { lat: 49.0985926, lng: 19.3349767 },
    { lat: 49.0983242, lng: 19.3352327 },
    { lat: 49.097851, lng: 19.3356891 },
    { lat: 49.0977378, lng: 19.3357288 },
    { lat: 49.0973786, lng: 19.3352079 },
    { lat: 49.0969825, lng: 19.3346457 },
    { lat: 49.0965103, lng: 19.3345546 },
    { lat: 49.0959364, lng: 19.3345682 },
    { lat: 49.0955788, lng: 19.3347764 },
    { lat: 49.0950429, lng: 19.3333114 },
    { lat: 49.0944725, lng: 19.3337839 },
    { lat: 49.0942072, lng: 19.333057 },
    { lat: 49.0944172, lng: 19.3325183 },
    { lat: 49.0942596, lng: 19.3320422 },
    { lat: 49.0941173, lng: 19.3320844 },
    { lat: 49.0930002, lng: 19.3328933 },
    { lat: 49.0924902, lng: 19.3332588 },
    { lat: 49.092011, lng: 19.333444 },
    { lat: 49.0917876, lng: 19.3322484 },
    { lat: 49.0914055, lng: 19.3307196 },
    { lat: 49.0911144, lng: 19.3299974 },
    { lat: 49.0910252, lng: 19.3300191 },
    { lat: 49.0909855, lng: 19.3297735 },
    { lat: 49.090839, lng: 19.3290837 },
    { lat: 49.0904497, lng: 19.3279527 },
    { lat: 49.090099, lng: 19.3269746 },
    { lat: 49.0895448, lng: 19.3260961 },
    { lat: 49.0891465, lng: 19.325585 },
    { lat: 49.0886806, lng: 19.3249116 },
    { lat: 49.0883882, lng: 19.3243029 },
    { lat: 49.0876292, lng: 19.3248864 },
    { lat: 49.0870564, lng: 19.3253584 },
    { lat: 49.0864715, lng: 19.3259529 },
    { lat: 49.0854345, lng: 19.3275944 },
    { lat: 49.0848988, lng: 19.3298008 },
  ],
  Potok: [
    { lat: 49.108745, lng: 19.461121 },
    { lat: 49.109068, lng: 19.460374 },
    { lat: 49.109437, lng: 19.459635 },
    { lat: 49.10961, lng: 19.45962 },
    { lat: 49.110365, lng: 19.459704 },
    { lat: 49.111161, lng: 19.459916 },
    { lat: 49.112255, lng: 19.459126 },
    { lat: 49.112586, lng: 19.459099 },
    { lat: 49.112975, lng: 19.459164 },
    { lat: 49.113657, lng: 19.459292 },
    { lat: 49.114462, lng: 19.459201 },
    { lat: 49.115058, lng: 19.458905 },
    { lat: 49.115128, lng: 19.459085 },
    { lat: 49.115426, lng: 19.458962 },
    { lat: 49.115632, lng: 19.458322 },
    { lat: 49.116149, lng: 19.458015 },
    { lat: 49.116644, lng: 19.458038 },
    { lat: 49.117802, lng: 19.458405 },
    { lat: 49.118306, lng: 19.458581 },
    { lat: 49.11869, lng: 19.45807 },
    { lat: 49.118821, lng: 19.457693 },
    { lat: 49.119441, lng: 19.457893 },
    { lat: 49.119865, lng: 19.458031 },
    { lat: 49.120222, lng: 19.458444 },
    { lat: 49.120654, lng: 19.458496 },
    { lat: 49.12155, lng: 19.457792 },
    { lat: 49.121666, lng: 19.457822 },
    { lat: 49.121739, lng: 19.457841 },
    { lat: 49.121879, lng: 19.457877 },
    { lat: 49.1218735, lng: 19.4576081 },
    { lat: 49.1219722, lng: 19.4574763 },
    { lat: 49.122104, lng: 19.4569143 },
    { lat: 49.122278, lng: 19.4566067 },
    { lat: 49.1225395, lng: 19.4562654 },
    { lat: 49.1228707, lng: 19.4561669 },
    { lat: 49.123243, lng: 19.4565184 },
    { lat: 49.1255826, lng: 19.4567951 },
    { lat: 49.125623, lng: 19.4546436 },
    { lat: 49.1248755, lng: 19.4539228 },
    { lat: 49.1246191, lng: 19.4536952 },
    { lat: 49.1245622, lng: 19.453755 },
    { lat: 49.1242578, lng: 19.453725 },
    { lat: 49.1242117, lng: 19.4537292 },
    { lat: 49.1236884, lng: 19.4533281 },
    { lat: 49.1233747, lng: 19.4533919 },
    { lat: 49.122419, lng: 19.4537078 },
    { lat: 49.1223915, lng: 19.4536751 },
    { lat: 49.1219209, lng: 19.4537763 },
    { lat: 49.1216122, lng: 19.4536831 },
    { lat: 49.1217349, lng: 19.4530677 },
    { lat: 49.1218188, lng: 19.4530335 },
    { lat: 49.1213941, lng: 19.4526434 },
    { lat: 49.1211323, lng: 19.4525157 },
    { lat: 49.1208387, lng: 19.4519878 },
    { lat: 49.1202219, lng: 19.4512262 },
    { lat: 49.1200505, lng: 19.4506589 },
    { lat: 49.1194673, lng: 19.449694 },
    { lat: 49.1188166, lng: 19.4492713 },
    { lat: 49.1181842, lng: 19.448801 },
    { lat: 49.1179314, lng: 19.4485526 },
    { lat: 49.1172585, lng: 19.4481571 },
    { lat: 49.1172287, lng: 19.4481956 },
    { lat: 49.1171503, lng: 19.4484253 },
    { lat: 49.1167683, lng: 19.4481824 },
    { lat: 49.1163088, lng: 19.448013 },
    { lat: 49.1160905, lng: 19.4476799 },
    { lat: 49.1159423, lng: 19.4473548 },
    { lat: 49.115654, lng: 19.4470635 },
    { lat: 49.1155307, lng: 19.4469299 },
    { lat: 49.1153496, lng: 19.4467915 },
    { lat: 49.1152812, lng: 19.4467474 },
    { lat: 49.1151238, lng: 19.4467351 },
    { lat: 49.1149686, lng: 19.4467395 },
    { lat: 49.1144539, lng: 19.4467193 },
    { lat: 49.1141757, lng: 19.4466265 },
    { lat: 49.1141738, lng: 19.4466583 },
    { lat: 49.1140585, lng: 19.4466052 },
    { lat: 49.1138286, lng: 19.4464799 },
    { lat: 49.1136818, lng: 19.4464252 },
    { lat: 49.1136806, lng: 19.4463987 },
    { lat: 49.1136791, lng: 19.4463913 },
    { lat: 49.1136842, lng: 19.4462821 },
    { lat: 49.1136171, lng: 19.446272 },
    { lat: 49.1136128, lng: 19.446393 },
    { lat: 49.1132329, lng: 19.4461734 },
    { lat: 49.1130843, lng: 19.4461785 },
    { lat: 49.1129697, lng: 19.4461351 },
    { lat: 49.1127616, lng: 19.4461894 },
    { lat: 49.1127465, lng: 19.4461601 },
    { lat: 49.1125931, lng: 19.4461302 },
    { lat: 49.112317, lng: 19.4461551 },
    { lat: 49.1123308, lng: 19.4459885 },
    { lat: 49.1122407, lng: 19.4461583 },
    { lat: 49.112244, lng: 19.4460104 },
    { lat: 49.1120804, lng: 19.4459363 },
    { lat: 49.1118991, lng: 19.4458147 },
    { lat: 49.1117308, lng: 19.4457332 },
    { lat: 49.1114413, lng: 19.4457128 },
    { lat: 49.1109706, lng: 19.4454763 },
    { lat: 49.1106395, lng: 19.4455514 },
    { lat: 49.1104888, lng: 19.4455618 },
    { lat: 49.1093506, lng: 19.4459948 },
    { lat: 49.1093561, lng: 19.4460658 },
    { lat: 49.1088726, lng: 19.4461615 },
    { lat: 49.1088838, lng: 19.4466477 },
    { lat: 49.1088877, lng: 19.4467073 },
    { lat: 49.1087909, lng: 19.4467835 },
    { lat: 49.1084592, lng: 19.4470202 },
    { lat: 49.1073801, lng: 19.4471997 },
    { lat: 49.106807, lng: 19.4469885 },
    { lat: 49.106754, lng: 19.4469863 },
    { lat: 49.1067482, lng: 19.4470301 },
    { lat: 49.1067472, lng: 19.4470331 },
    { lat: 49.1055173, lng: 19.4461224 },
    { lat: 49.1054396, lng: 19.4457348 },
    { lat: 49.1050764, lng: 19.4449729 },
    { lat: 49.1050583, lng: 19.4457973 },
    { lat: 49.104571, lng: 19.450978 },
    { lat: 49.105372, lng: 19.451672 },
    { lat: 49.105566, lng: 19.452033 },
    { lat: 49.105571, lng: 19.452044 },
    { lat: 49.105627, lng: 19.452123 },
    { lat: 49.105653, lng: 19.452152 },
    { lat: 49.106791, lng: 19.453441 },
    { lat: 49.107234, lng: 19.453883 },
    { lat: 49.107657, lng: 19.454427 },
    { lat: 49.107802, lng: 19.454684 },
    { lat: 49.107745, lng: 19.455747 },
    { lat: 49.107995, lng: 19.45674 },
    { lat: 49.108324, lng: 19.457333 },
    { lat: 49.1085, lng: 19.45911 },
    { lat: 49.108745, lng: 19.461121 },
  ],
  Stankovany: [
    { lat: 49.136553, lng: 19.14191 },
    { lat: 49.13629, lng: 19.141825 },
    { lat: 49.135786, lng: 19.141724 },
    { lat: 49.135482, lng: 19.141647 },
    { lat: 49.135073, lng: 19.141417 },
    { lat: 49.13464, lng: 19.141175 },
    { lat: 49.134256, lng: 19.141039 },
    { lat: 49.133721, lng: 19.14098 },
    { lat: 49.133633, lng: 19.14097 },
    { lat: 49.133092, lng: 19.14059 },
    { lat: 49.132249, lng: 19.140144 },
    { lat: 49.131804, lng: 19.140387 },
    { lat: 49.1315, lng: 19.140185 },
    { lat: 49.131137, lng: 19.139951 },
    { lat: 49.130775, lng: 19.14007 },
    { lat: 49.130327, lng: 19.140375 },
    { lat: 49.130197, lng: 19.140553 },
    { lat: 49.129984, lng: 19.140688 },
    { lat: 49.129027, lng: 19.141684 },
    { lat: 49.128644, lng: 19.142007 },
    { lat: 49.128289, lng: 19.142267 },
    { lat: 49.127436, lng: 19.143403 },
    { lat: 49.12729, lng: 19.143623 },
    { lat: 49.127075, lng: 19.143692 },
    { lat: 49.126442, lng: 19.143443 },
    { lat: 49.126185, lng: 19.143366 },
    { lat: 49.125675, lng: 19.142992 },
    { lat: 49.125076, lng: 19.142712 },
    { lat: 49.124803, lng: 19.142269 },
    { lat: 49.12456, lng: 19.142067 },
    { lat: 49.124239, lng: 19.142082 },
    { lat: 49.123858, lng: 19.142014 },
    { lat: 49.1234838, lng: 19.1435795 },
    { lat: 49.1229904, lng: 19.1446255 },
    { lat: 49.1225282, lng: 19.1453858 },
    { lat: 49.1222972, lng: 19.1461012 },
    { lat: 49.1221971, lng: 19.1476488 },
    { lat: 49.1218219, lng: 19.1481642 },
    { lat: 49.121482, lng: 19.1484982 },
    { lat: 49.1211809, lng: 19.1491189 },
    { lat: 49.1213961, lng: 19.1507798 },
    { lat: 49.1214841, lng: 19.1515418 },
    { lat: 49.1215508, lng: 19.1525839 },
    { lat: 49.1214101, lng: 19.1535841 },
    { lat: 49.1214962, lng: 19.154148 },
    { lat: 49.1221566, lng: 19.1550676 },
    { lat: 49.1227473, lng: 19.1557831 },
    { lat: 49.1232745, lng: 19.1561034 },
    { lat: 49.1237815, lng: 19.1566776 },
    { lat: 49.124173, lng: 19.1570296 },
    { lat: 49.1243148, lng: 19.1577671 },
    { lat: 49.1244067, lng: 19.1584938 },
    { lat: 49.1245738, lng: 19.1593555 },
    { lat: 49.1256211, lng: 19.1663968 },
    { lat: 49.125854, lng: 19.1663636 },
    { lat: 49.1260904, lng: 19.1664981 },
    { lat: 49.1262574, lng: 19.166548 },
    { lat: 49.1263434, lng: 19.166602 },
    { lat: 49.1264443, lng: 19.1667281 },
    { lat: 49.1265288, lng: 19.1667952 },
    { lat: 49.1266954, lng: 19.1668599 },
    { lat: 49.1268275, lng: 19.1668669 },
    { lat: 49.1268829, lng: 19.1668927 },
    { lat: 49.126965, lng: 19.1669834 },
    { lat: 49.1271404, lng: 19.1671367 },
    { lat: 49.127224, lng: 19.1672331 },
    { lat: 49.1273589, lng: 19.1673665 },
    { lat: 49.1276377, lng: 19.167624 },
    { lat: 49.1277738, lng: 19.1678062 },
    { lat: 49.1279902, lng: 19.1680406 },
    { lat: 49.1283314, lng: 19.1682615 },
    { lat: 49.1284108, lng: 19.1683634 },
    { lat: 49.1285108, lng: 19.1684971 },
    { lat: 49.1286337, lng: 19.1685909 },
    { lat: 49.1287984, lng: 19.16869 },
    { lat: 49.1288113, lng: 19.1686972 },
    { lat: 49.128865, lng: 19.1686889 },
    { lat: 49.1290578, lng: 19.1687384 },
    { lat: 49.1295369, lng: 19.1688337 },
    { lat: 49.1297119, lng: 19.1688625 },
    { lat: 49.1297782, lng: 19.168855 },
    { lat: 49.1301013, lng: 19.168618 },
    { lat: 49.1303217, lng: 19.1683952 },
    { lat: 49.130591, lng: 19.1680937 },
    { lat: 49.1307607, lng: 19.1678547 },
    { lat: 49.13108, lng: 19.1672312 },
    { lat: 49.1311033, lng: 19.1672739 },
    { lat: 49.1311295, lng: 19.1673224 },
    { lat: 49.1311557, lng: 19.1673313 },
    { lat: 49.1312831, lng: 19.1673763 },
    { lat: 49.1314196, lng: 19.167358 },
    { lat: 49.1315056, lng: 19.1673627 },
    { lat: 49.1315258, lng: 19.1677294 },
    { lat: 49.1315222, lng: 19.1678878 },
    { lat: 49.1315111, lng: 19.1682857 },
    { lat: 49.1315055, lng: 19.1683392 },
    { lat: 49.1314543, lng: 19.168832 },
    { lat: 49.131451, lng: 19.1689972 },
    { lat: 49.1314714, lng: 19.1691839 },
    { lat: 49.1314732, lng: 19.1694068 },
    { lat: 49.1314633, lng: 19.1695271 },
    { lat: 49.1313504, lng: 19.1699408 },
    { lat: 49.1312511, lng: 19.170263 },
    { lat: 49.1312285, lng: 19.1703292 },
    { lat: 49.1311991, lng: 19.1704679 },
    { lat: 49.1311652, lng: 19.1708151 },
    { lat: 49.131157, lng: 19.1709031 },
    { lat: 49.1311465, lng: 19.1710321 },
    { lat: 49.1311418, lng: 19.1710758 },
    { lat: 49.1310955, lng: 19.1716058 },
    { lat: 49.1310838, lng: 19.1716662 },
    { lat: 49.1309802, lng: 19.1721793 },
    { lat: 49.1309639, lng: 19.1722614 },
    { lat: 49.1309621, lng: 19.1722731 },
    { lat: 49.1309543, lng: 19.1723218 },
    { lat: 49.1309398, lng: 19.1724167 },
    { lat: 49.1309305, lng: 19.1724775 },
    { lat: 49.1309199, lng: 19.1725424 },
    { lat: 49.1309108, lng: 19.1726078 },
    { lat: 49.130903, lng: 19.1726544 },
    { lat: 49.1309012, lng: 19.1726665 },
    { lat: 49.1308841, lng: 19.1727764 },
    { lat: 49.1308756, lng: 19.172835 },
    { lat: 49.1308644, lng: 19.1729068 },
    { lat: 49.1307662, lng: 19.1728344 },
    { lat: 49.1306868, lng: 19.172776 },
    { lat: 49.1303753, lng: 19.172673 },
    { lat: 49.1300415, lng: 19.1726977 },
    { lat: 49.1296955, lng: 19.1728094 },
    { lat: 49.1294273, lng: 19.1729765 },
    { lat: 49.1291498, lng: 19.1729718 },
    { lat: 49.1286894, lng: 19.1726793 },
    { lat: 49.1280685, lng: 19.1726609 },
    { lat: 49.127678, lng: 19.1727417 },
    { lat: 49.1272797, lng: 19.1726437 },
    { lat: 49.1272425, lng: 19.1728268 },
    { lat: 49.1265581, lng: 19.1726147 },
    { lat: 49.1264578, lng: 19.1727088 },
    { lat: 49.1262614, lng: 19.1727727 },
    { lat: 49.1262209, lng: 19.1728867 },
    { lat: 49.1261505, lng: 19.172809 },
    { lat: 49.126129, lng: 19.1727862 },
    { lat: 49.1261076, lng: 19.1727627 },
    { lat: 49.1260494, lng: 19.1726984 },
    { lat: 49.1259688, lng: 19.172945 },
    { lat: 49.1259528, lng: 19.1729414 },
    { lat: 49.1258331, lng: 19.1729131 },
    { lat: 49.1257825, lng: 19.1728433 },
    { lat: 49.1257214, lng: 19.1728156 },
    { lat: 49.125688, lng: 19.1728 },
    { lat: 49.1256661, lng: 19.1727902 },
    { lat: 49.1256239, lng: 19.1727575 },
    { lat: 49.1256065, lng: 19.1727447 },
    { lat: 49.1255907, lng: 19.1727465 },
    { lat: 49.1255559, lng: 19.1727501 },
    { lat: 49.1255223, lng: 19.1727539 },
    { lat: 49.1254909, lng: 19.1727574 },
    { lat: 49.1254561, lng: 19.172761 },
    { lat: 49.1254258, lng: 19.1727644 },
    { lat: 49.1253892, lng: 19.1727549 },
    { lat: 49.1253574, lng: 19.1727462 },
    { lat: 49.1253529, lng: 19.1727464 },
    { lat: 49.1253292, lng: 19.1727451 },
    { lat: 49.1253032, lng: 19.1727444 },
    { lat: 49.1252771, lng: 19.172743 },
    { lat: 49.1252511, lng: 19.172742 },
    { lat: 49.1252161, lng: 19.1727409 },
    { lat: 49.1252049, lng: 19.17274 },
    { lat: 49.1250399, lng: 19.1729642 },
    { lat: 49.124786, lng: 19.1729118 },
    { lat: 49.1246209, lng: 19.1728776 },
    { lat: 49.1245666, lng: 19.1729416 },
    { lat: 49.1245253, lng: 19.1729506 },
    { lat: 49.124446, lng: 19.1729682 },
    { lat: 49.124407, lng: 19.1729769 },
    { lat: 49.124368, lng: 19.1729856 },
    { lat: 49.1243379, lng: 19.1729923 },
    { lat: 49.1243234, lng: 19.1729957 },
    { lat: 49.1243124, lng: 19.1729977 },
    { lat: 49.1241021, lng: 19.1730362 },
    { lat: 49.1239382, lng: 19.1730698 },
    { lat: 49.1238054, lng: 19.1730973 },
    { lat: 49.1234024, lng: 19.1731795 },
    { lat: 49.1233111, lng: 19.1732006 },
    { lat: 49.1232532, lng: 19.173214 },
    { lat: 49.1230336, lng: 19.1732647 },
    { lat: 49.1229917, lng: 19.1732871 },
    { lat: 49.1229533, lng: 19.1733079 },
    { lat: 49.1229148, lng: 19.1733285 },
    { lat: 49.1228842, lng: 19.1733449 },
    { lat: 49.1228446, lng: 19.1733655 },
    { lat: 49.1227995, lng: 19.1733901 },
    { lat: 49.1227491, lng: 19.1734166 },
    { lat: 49.1227028, lng: 19.1734416 },
    { lat: 49.1226655, lng: 19.1734617 },
    { lat: 49.1225918, lng: 19.173501 },
    { lat: 49.1225327, lng: 19.1735332 },
    { lat: 49.1224963, lng: 19.173552 },
    { lat: 49.1224063, lng: 19.1736007 },
    { lat: 49.1223524, lng: 19.1736291 },
    { lat: 49.1223481, lng: 19.1736317 },
    { lat: 49.122339, lng: 19.1737224 },
    { lat: 49.1223336, lng: 19.173777 },
    { lat: 49.1223317, lng: 19.1737804 },
    { lat: 49.1223109, lng: 19.1738195 },
    { lat: 49.1222904, lng: 19.1738588 },
    { lat: 49.1222696, lng: 19.1738979 },
    { lat: 49.1222376, lng: 19.1739594 },
    { lat: 49.1222048, lng: 19.1740229 },
    { lat: 49.122181, lng: 19.174068 },
    { lat: 49.1221636, lng: 19.1741024 },
    { lat: 49.1221501, lng: 19.1741277 },
    { lat: 49.1221367, lng: 19.1741526 },
    { lat: 49.1221232, lng: 19.1741318 },
    { lat: 49.1220929, lng: 19.174087 },
    { lat: 49.1220748, lng: 19.1741077 },
    { lat: 49.1220405, lng: 19.1741454 },
    { lat: 49.1219923, lng: 19.1741994 },
    { lat: 49.1219742, lng: 19.1742195 },
    { lat: 49.1219668, lng: 19.1742275 },
    { lat: 49.1219526, lng: 19.1742377 },
    { lat: 49.1219232, lng: 19.1742591 },
    { lat: 49.1218926, lng: 19.1742809 },
    { lat: 49.1218698, lng: 19.1742982 },
    { lat: 49.1218545, lng: 19.1743089 },
    { lat: 49.1218298, lng: 19.1742628 },
    { lat: 49.1218148, lng: 19.1742785 },
    { lat: 49.1217943, lng: 19.1742999 },
    { lat: 49.1217655, lng: 19.1743301 },
    { lat: 49.1217289, lng: 19.1743677 },
    { lat: 49.1216978, lng: 19.1744 },
    { lat: 49.1216925, lng: 19.1744056 },
    { lat: 49.1211608, lng: 19.1751105 },
    { lat: 49.1212203, lng: 19.1751729 },
    { lat: 49.1209973, lng: 19.175202 },
    { lat: 49.1209459, lng: 19.1752089 },
    { lat: 49.1209335, lng: 19.1752106 },
    { lat: 49.1208518, lng: 19.1752209 },
    { lat: 49.1208417, lng: 19.1752228 },
    { lat: 49.1207575, lng: 19.1751369 },
    { lat: 49.1205701, lng: 19.1749464 },
    { lat: 49.1201593, lng: 19.1757906 },
    { lat: 49.1198128, lng: 19.1764813 },
    { lat: 49.1197181, lng: 19.1766849 },
    { lat: 49.1194024, lng: 19.1774074 },
    { lat: 49.1192937, lng: 19.1775798 },
    { lat: 49.1191862, lng: 19.1777348 },
    { lat: 49.1188544, lng: 19.1781637 },
    { lat: 49.1185871, lng: 19.178463 },
    { lat: 49.1181485, lng: 19.1789237 },
    { lat: 49.1180143, lng: 19.1791083 },
    { lat: 49.1179697, lng: 19.1792112 },
    { lat: 49.1179388, lng: 19.1793191 },
    { lat: 49.1179217, lng: 19.1794297 },
    { lat: 49.117919, lng: 19.1795631 },
    { lat: 49.1179258, lng: 19.1797041 },
    { lat: 49.1179428, lng: 19.1798631 },
    { lat: 49.11797, lng: 19.1800295 },
    { lat: 49.1180122, lng: 19.1802287 },
    { lat: 49.1180736, lng: 19.180446 },
    { lat: 49.1181514, lng: 19.1806812 },
    { lat: 49.1182661, lng: 19.1810022 },
    { lat: 49.1183977, lng: 19.1813436 },
    { lat: 49.118542, lng: 19.1817376 },
    { lat: 49.1187048, lng: 19.1822583 },
    { lat: 49.1190286, lng: 19.1832412 },
    { lat: 49.1191928, lng: 19.1837445 },
    { lat: 49.1193031, lng: 19.1840653 },
    { lat: 49.1193482, lng: 19.1841807 },
    { lat: 49.1195418, lng: 19.1840575 },
    { lat: 49.1197177, lng: 19.1839459 },
    { lat: 49.1199648, lng: 19.1838098 },
    { lat: 49.1202216, lng: 19.1836686 },
    { lat: 49.1203973, lng: 19.1831281 },
    { lat: 49.1208206, lng: 19.182356 },
    { lat: 49.1211234, lng: 19.1814176 },
    { lat: 49.1216054, lng: 19.1813618 },
    { lat: 49.1218779, lng: 19.1811955 },
    { lat: 49.1239066, lng: 19.1815409 },
    { lat: 49.1239902, lng: 19.1815897 },
    { lat: 49.124441, lng: 19.181729 },
    { lat: 49.1248896, lng: 19.1822168 },
    { lat: 49.1253103, lng: 19.182335 },
    { lat: 49.1255455, lng: 19.1826316 },
    { lat: 49.1259276, lng: 19.1826307 },
    { lat: 49.1265416, lng: 19.1834602 },
    { lat: 49.1269465, lng: 19.1837007 },
    { lat: 49.1274945, lng: 19.184145 },
    { lat: 49.1283875, lng: 19.1851943 },
    { lat: 49.1291045, lng: 19.1856419 },
    { lat: 49.1295396, lng: 19.186944 },
    { lat: 49.1310025, lng: 19.1884091 },
    { lat: 49.1319722, lng: 19.1887617 },
    { lat: 49.1330411, lng: 19.1900959 },
    { lat: 49.1346204, lng: 19.1915423 },
    { lat: 49.1356588, lng: 19.1928998 },
    { lat: 49.136291, lng: 19.1937135 },
    { lat: 49.1366621, lng: 19.1942556 },
    { lat: 49.1368355, lng: 19.1945741 },
    { lat: 49.1375437, lng: 19.1953995 },
    { lat: 49.1376327, lng: 19.1956296 },
    { lat: 49.1378511, lng: 19.196208 },
    { lat: 49.1381965, lng: 19.1967998 },
    { lat: 49.1384716, lng: 19.1971515 },
    { lat: 49.1386672, lng: 19.1976738 },
    { lat: 49.1389315, lng: 19.1983378 },
    { lat: 49.1389093, lng: 19.1987435 },
    { lat: 49.1390189, lng: 19.1992121 },
    { lat: 49.139215, lng: 19.199293 },
    { lat: 49.139723, lng: 19.199424 },
    { lat: 49.140668, lng: 19.200172 },
    { lat: 49.141552, lng: 19.200534 },
    { lat: 49.142097, lng: 19.200824 },
    { lat: 49.142267, lng: 19.201213 },
    { lat: 49.142987, lng: 19.201656 },
    { lat: 49.14376, lng: 19.201868 },
    { lat: 49.144043, lng: 19.201882 },
    { lat: 49.144313, lng: 19.202097 },
    { lat: 49.144904, lng: 19.202286 },
    { lat: 49.145575, lng: 19.202841 },
    { lat: 49.146491, lng: 19.202836 },
    { lat: 49.146952, lng: 19.202708 },
    { lat: 49.147194, lng: 19.202566 },
    { lat: 49.14781, lng: 19.201996 },
    { lat: 49.148358, lng: 19.201378 },
    { lat: 49.149022, lng: 19.200643 },
    { lat: 49.149716, lng: 19.20038 },
    { lat: 49.150251, lng: 19.199848 },
    { lat: 49.150704, lng: 19.20048 },
    { lat: 49.150827, lng: 19.200901 },
    { lat: 49.151248, lng: 19.200924 },
    { lat: 49.152065, lng: 19.200882 },
    { lat: 49.152419, lng: 19.200872 },
    { lat: 49.152793, lng: 19.200817 },
    { lat: 49.152969, lng: 19.200849 },
    { lat: 49.153184, lng: 19.200887 },
    { lat: 49.153648, lng: 19.199899 },
    { lat: 49.154412, lng: 19.200576 },
    { lat: 49.154811, lng: 19.200379 },
    { lat: 49.155256, lng: 19.200266 },
    { lat: 49.155362, lng: 19.200323 },
    { lat: 49.155539, lng: 19.200339 },
    { lat: 49.155729, lng: 19.200058 },
    { lat: 49.155821, lng: 19.199725 },
    { lat: 49.156027, lng: 19.199186 },
    { lat: 49.156425, lng: 19.198679 },
    { lat: 49.156617, lng: 19.198471 },
    { lat: 49.156875, lng: 19.198267 },
    { lat: 49.157741, lng: 19.197188 },
    { lat: 49.158137, lng: 19.196798 },
    { lat: 49.158772, lng: 19.196156 },
    { lat: 49.159684, lng: 19.196052 },
    { lat: 49.159767, lng: 19.195896 },
    { lat: 49.160375, lng: 19.195391 },
    { lat: 49.160609, lng: 19.195217 },
    { lat: 49.16079, lng: 19.195019 },
    { lat: 49.16107, lng: 19.194412 },
    { lat: 49.161554, lng: 19.193812 },
    { lat: 49.162119, lng: 19.193068 },
    { lat: 49.162459, lng: 19.192687 },
    { lat: 49.162893, lng: 19.192214 },
    { lat: 49.163121, lng: 19.191646 },
    { lat: 49.163432, lng: 19.190661 },
    { lat: 49.163993, lng: 19.189974 },
    { lat: 49.16415, lng: 19.189264 },
    { lat: 49.164219, lng: 19.188613 },
    { lat: 49.164408, lng: 19.187405 },
    { lat: 49.164648, lng: 19.186793 },
    { lat: 49.164778, lng: 19.186593 },
    { lat: 49.164911, lng: 19.186157 },
    { lat: 49.165085, lng: 19.185631 },
    { lat: 49.16522, lng: 19.185008 },
    { lat: 49.165146, lng: 19.183641 },
    { lat: 49.165406, lng: 19.183042 },
    { lat: 49.165608, lng: 19.182562 },
    { lat: 49.165903, lng: 19.181721 },
    { lat: 49.165951, lng: 19.180964 },
    { lat: 49.165804, lng: 19.180577 },
    { lat: 49.165779, lng: 19.1802 },
    { lat: 49.16563, lng: 19.179417 },
    { lat: 49.16533, lng: 19.178973 },
    { lat: 49.165327, lng: 19.178707 },
    { lat: 49.165252, lng: 19.178337 },
    { lat: 49.165314, lng: 19.177703 },
    { lat: 49.165357, lng: 19.177221 },
    { lat: 49.165397, lng: 19.17691 },
    { lat: 49.165317, lng: 19.176192 },
    { lat: 49.165159, lng: 19.174982 },
    { lat: 49.165112, lng: 19.174446 },
    { lat: 49.165043, lng: 19.173975 },
    { lat: 49.16498, lng: 19.173719 },
    { lat: 49.164965, lng: 19.173588 },
    { lat: 49.164918, lng: 19.172455 },
    { lat: 49.164833, lng: 19.171958 },
    { lat: 49.164607, lng: 19.171539 },
    { lat: 49.164702, lng: 19.170873 },
    { lat: 49.164622, lng: 19.170264 },
    { lat: 49.163955, lng: 19.169632 },
    { lat: 49.163684, lng: 19.168971 },
    { lat: 49.1635383, lng: 19.1685488 },
    { lat: 49.163517, lng: 19.168487 },
    { lat: 49.163367, lng: 19.16819 },
    { lat: 49.163467, lng: 19.168048 },
    { lat: 49.163561, lng: 19.167669 },
    { lat: 49.163565, lng: 19.167478 },
    { lat: 49.163675, lng: 19.16717 },
    { lat: 49.16375, lng: 19.166897 },
    { lat: 49.163692, lng: 19.166499 },
    { lat: 49.163756, lng: 19.166259 },
    { lat: 49.163779, lng: 19.165942 },
    { lat: 49.164037, lng: 19.165268 },
    { lat: 49.16431, lng: 19.164841 },
    { lat: 49.164838, lng: 19.164719 },
    { lat: 49.16491, lng: 19.164647 },
    { lat: 49.165102, lng: 19.164398 },
    { lat: 49.16533, lng: 19.163799 },
    { lat: 49.165452, lng: 19.163262 },
    { lat: 49.16561, lng: 19.16286 },
    { lat: 49.166209, lng: 19.162394 },
    { lat: 49.166464, lng: 19.162171 },
    { lat: 49.166692, lng: 19.162052 },
    { lat: 49.16689, lng: 19.161902 },
    { lat: 49.167045, lng: 19.161816 },
    { lat: 49.167246, lng: 19.161376 },
    { lat: 49.167485, lng: 19.161178 },
    { lat: 49.167993, lng: 19.160587 },
    { lat: 49.168233, lng: 19.160353 },
    { lat: 49.168388, lng: 19.160142 },
    { lat: 49.168575, lng: 19.159892 },
    { lat: 49.168892, lng: 19.159457 },
    { lat: 49.168994, lng: 19.159306 },
    { lat: 49.16922, lng: 19.15909 },
    { lat: 49.169388, lng: 19.158694 },
    { lat: 49.169573, lng: 19.15828 },
    { lat: 49.169668, lng: 19.158155 },
    { lat: 49.169744, lng: 19.157817 },
    { lat: 49.16997, lng: 19.156965 },
    { lat: 49.170161, lng: 19.156538 },
    { lat: 49.170278, lng: 19.156443 },
    { lat: 49.170482, lng: 19.156152 },
    { lat: 49.170658, lng: 19.155376 },
    { lat: 49.170705, lng: 19.155093 },
    { lat: 49.170838, lng: 19.154238 },
    { lat: 49.170829, lng: 19.153687 },
    { lat: 49.170753, lng: 19.153425 },
    { lat: 49.170692, lng: 19.152605 },
    { lat: 49.170875, lng: 19.152336 },
    { lat: 49.170982, lng: 19.152019 },
    { lat: 49.171136, lng: 19.15183 },
    { lat: 49.17117, lng: 19.151702 },
    { lat: 49.171278, lng: 19.151527 },
    { lat: 49.171338, lng: 19.151272 },
    { lat: 49.171492, lng: 19.150994 },
    { lat: 49.171563, lng: 19.150707 },
    { lat: 49.171727, lng: 19.150437 },
    { lat: 49.171846, lng: 19.150215 },
    { lat: 49.171997, lng: 19.150034 },
    { lat: 49.171985, lng: 19.150018 },
    { lat: 49.171785, lng: 19.149694 },
    { lat: 49.171317, lng: 19.149186 },
    { lat: 49.171279, lng: 19.149151 },
    { lat: 49.170666, lng: 19.148569 },
    { lat: 49.1704, lng: 19.148401 },
    { lat: 49.169852, lng: 19.147915 },
    { lat: 49.169146, lng: 19.14733 },
    { lat: 49.168902, lng: 19.146918 },
    { lat: 49.168506, lng: 19.146299 },
    { lat: 49.168401, lng: 19.14596 },
    { lat: 49.168328, lng: 19.145969 },
    { lat: 49.168141, lng: 19.145395 },
    { lat: 49.167879, lng: 19.1448 },
    { lat: 49.167739, lng: 19.143916 },
    { lat: 49.167773, lng: 19.143316 },
    { lat: 49.167747, lng: 19.142973 },
    { lat: 49.16789, lng: 19.142362 },
    { lat: 49.167914, lng: 19.141645 },
    { lat: 49.167892, lng: 19.141174 },
    { lat: 49.167977, lng: 19.140061 },
    { lat: 49.167978, lng: 19.13965 },
    { lat: 49.167972, lng: 19.138705 },
    { lat: 49.168005, lng: 19.138459 },
    { lat: 49.168229, lng: 19.137649 },
    { lat: 49.168227, lng: 19.136659 },
    { lat: 49.168139, lng: 19.136387 },
    { lat: 49.168081, lng: 19.135961 },
    { lat: 49.167969, lng: 19.135546 },
    { lat: 49.167816, lng: 19.13484 },
    { lat: 49.167674, lng: 19.134887 },
    { lat: 49.16733, lng: 19.135001 },
    { lat: 49.167303, lng: 19.13501 },
    { lat: 49.166307, lng: 19.135086 },
    { lat: 49.165587, lng: 19.135071 },
    { lat: 49.165205, lng: 19.135017 },
    { lat: 49.164665, lng: 19.134832 },
    { lat: 49.164085, lng: 19.134534 },
    { lat: 49.164035, lng: 19.134508 },
    { lat: 49.163682, lng: 19.134314 },
    { lat: 49.162993, lng: 19.133935 },
    { lat: 49.162876, lng: 19.133935 },
    { lat: 49.162145, lng: 19.13388 },
    { lat: 49.161609, lng: 19.134168 },
    { lat: 49.161501, lng: 19.134255 },
    { lat: 49.161198, lng: 19.13456 },
    { lat: 49.160946, lng: 19.134892 },
    { lat: 49.160915, lng: 19.134946 },
    { lat: 49.160413, lng: 19.1358 },
    { lat: 49.160327, lng: 19.135935 },
    { lat: 49.160245, lng: 19.136053 },
    { lat: 49.159721, lng: 19.136858 },
    { lat: 49.159353, lng: 19.137533 },
    { lat: 49.158909, lng: 19.138002 },
    { lat: 49.158822, lng: 19.138026 },
    { lat: 49.158476, lng: 19.138137 },
    { lat: 49.158094, lng: 19.138242 },
    { lat: 49.157301, lng: 19.138569 },
    { lat: 49.15678, lng: 19.138794 },
    { lat: 49.156138, lng: 19.138982 },
    { lat: 49.155447, lng: 19.139266 },
    { lat: 49.15449, lng: 19.139464 },
    { lat: 49.154269, lng: 19.139466 },
    { lat: 49.154235, lng: 19.139466 },
    { lat: 49.15409, lng: 19.139479 },
    { lat: 49.153974, lng: 19.13948 },
    { lat: 49.153846, lng: 19.139482 },
    { lat: 49.153651, lng: 19.13951 },
    { lat: 49.15352, lng: 19.139538 },
    { lat: 49.153394, lng: 19.139508 },
    { lat: 49.153315, lng: 19.140094 },
    { lat: 49.1534177, lng: 19.1405432 },
    { lat: 49.153518, lng: 19.140694 },
    { lat: 49.153605, lng: 19.140855 },
    { lat: 49.153618, lng: 19.140918 },
    { lat: 49.153654, lng: 19.141304 },
    { lat: 49.153664, lng: 19.141413 },
    { lat: 49.1536602, lng: 19.1415522 },
    { lat: 49.153702, lng: 19.142092 },
    { lat: 49.153704, lng: 19.142365 },
    { lat: 49.153674, lng: 19.142843 },
    { lat: 49.153584, lng: 19.143479 },
    { lat: 49.153427, lng: 19.143829 },
    { lat: 49.153032, lng: 19.144295 },
    { lat: 49.152652, lng: 19.144526 },
    { lat: 49.152046, lng: 19.145179 },
    { lat: 49.151873, lng: 19.145503 },
    { lat: 49.1517154, lng: 19.1458002 },
    { lat: 49.151648, lng: 19.14593 },
    { lat: 49.151575, lng: 19.146175 },
    { lat: 49.15145, lng: 19.146477 },
    { lat: 49.151366, lng: 19.146952 },
    { lat: 49.1512931, lng: 19.1472185 },
    { lat: 49.151282, lng: 19.147433 },
    { lat: 49.151135, lng: 19.147226 },
    { lat: 49.151024, lng: 19.147167 },
    { lat: 49.150988, lng: 19.147145 },
    { lat: 49.150958, lng: 19.147131 },
    { lat: 49.150865, lng: 19.147109 },
    { lat: 49.150832, lng: 19.147104 },
    { lat: 49.150553, lng: 19.147113 },
    { lat: 49.150417, lng: 19.147344 },
    { lat: 49.150261, lng: 19.147331 },
    { lat: 49.150082, lng: 19.147234 },
    { lat: 49.149795, lng: 19.147174 },
    { lat: 49.149607, lng: 19.147014 },
    { lat: 49.149415, lng: 19.146744 },
    { lat: 49.149233, lng: 19.146645 },
    { lat: 49.149154, lng: 19.146595 },
    { lat: 49.148843, lng: 19.146489 },
    { lat: 49.148553, lng: 19.146488 },
    { lat: 49.148132, lng: 19.14645 },
    { lat: 49.147605, lng: 19.146298 },
    { lat: 49.14738, lng: 19.14625 },
    { lat: 49.147138, lng: 19.146158 },
    { lat: 49.146674, lng: 19.145968 },
    { lat: 49.146346, lng: 19.145881 },
    { lat: 49.146079, lng: 19.14578 },
    { lat: 49.145863, lng: 19.145704 },
    { lat: 49.145157, lng: 19.145556 },
    { lat: 49.144874, lng: 19.145441 },
    { lat: 49.144432, lng: 19.145399 },
    { lat: 49.144015, lng: 19.145228 },
    { lat: 49.143963, lng: 19.145249 },
    { lat: 49.143974, lng: 19.145314 },
    { lat: 49.143907, lng: 19.145308 },
    { lat: 49.143939, lng: 19.145411 },
    { lat: 49.143787, lng: 19.145443 },
    { lat: 49.143624, lng: 19.145209 },
    { lat: 49.143552, lng: 19.144993 },
    { lat: 49.142938, lng: 19.144579 },
    { lat: 49.141909, lng: 19.144269 },
    { lat: 49.141189, lng: 19.143836 },
    { lat: 49.140879, lng: 19.1435 },
    { lat: 49.140375, lng: 19.143241 },
    { lat: 49.139681, lng: 19.142797 },
    { lat: 49.138668, lng: 19.142548 },
    { lat: 49.138024, lng: 19.142463 },
    { lat: 49.137432, lng: 19.142242 },
    { lat: 49.136553, lng: 19.14191 },
  ],
  Turík: [
    { lat: 49.0967207, lng: 19.3997529 },
    { lat: 49.0972887, lng: 19.3998266 },
    { lat: 49.0964039, lng: 19.3934042 },
    { lat: 49.0964023, lng: 19.3933957 },
    { lat: 49.0965645, lng: 19.3933433 },
    { lat: 49.0970823, lng: 19.3932109 },
    { lat: 49.0976242, lng: 19.3933266 },
    { lat: 49.0980844, lng: 19.3931185 },
    { lat: 49.0984589, lng: 19.3926683 },
    { lat: 49.0987638, lng: 19.3934066 },
    { lat: 49.0991994, lng: 19.3943861 },
    { lat: 49.099408, lng: 19.3949897 },
    { lat: 49.099613, lng: 19.3956414 },
    { lat: 49.0998433, lng: 19.3954823 },
    { lat: 49.1012926, lng: 19.394638 },
    { lat: 49.1025099, lng: 19.394063 },
    { lat: 49.104821, lng: 19.3924684 },
    { lat: 49.1061002, lng: 19.3931926 },
    { lat: 49.1085632, lng: 19.3939356 },
    { lat: 49.1086679, lng: 19.3935941 },
    { lat: 49.1088795, lng: 19.3935136 },
    { lat: 49.1096691, lng: 19.3934122 },
    { lat: 49.1108878, lng: 19.3929146 },
    { lat: 49.1110272, lng: 19.3928358 },
    { lat: 49.111227, lng: 19.3926497 },
    { lat: 49.1116508, lng: 19.3924358 },
    { lat: 49.1126415, lng: 19.3920284 },
    { lat: 49.1130254, lng: 19.3918486 },
    { lat: 49.1137472, lng: 19.3911404 },
    { lat: 49.1142417, lng: 19.390917 },
    { lat: 49.1152807, lng: 19.3898567 },
    { lat: 49.116195, lng: 19.3905879 },
    { lat: 49.1170892, lng: 19.3912276 },
    { lat: 49.1186801, lng: 19.3897023 },
    { lat: 49.1203611, lng: 19.388296 },
    { lat: 49.1212346, lng: 19.388326 },
    { lat: 49.1233391, lng: 19.3861895 },
    { lat: 49.1249135, lng: 19.3816867 },
    { lat: 49.1258849, lng: 19.3826041 },
    { lat: 49.127269, lng: 19.3824177 },
    { lat: 49.1277078, lng: 19.380451 },
    { lat: 49.1278778, lng: 19.3782311 },
    { lat: 49.1289635, lng: 19.3764383 },
    { lat: 49.1303154, lng: 19.3736869 },
    { lat: 49.1301303, lng: 19.3709671 },
    { lat: 49.1299772, lng: 19.3686144 },
    { lat: 49.1304749, lng: 19.3668495 },
    { lat: 49.1313429, lng: 19.3669798 },
    { lat: 49.1316236, lng: 19.3662136 },
    { lat: 49.1327916, lng: 19.3638942 },
    { lat: 49.1336576, lng: 19.3620547 },
    { lat: 49.1338114, lng: 19.3618037 },
    { lat: 49.1338438, lng: 19.3617494 },
    { lat: 49.1342153, lng: 19.3611431 },
    { lat: 49.1341324, lng: 19.3604327 },
    { lat: 49.1341214, lng: 19.3603407 },
    { lat: 49.1339828, lng: 19.3591452 },
    { lat: 49.1345852, lng: 19.3587026 },
    { lat: 49.135488, lng: 19.3574099 },
    { lat: 49.1368369, lng: 19.3559932 },
    { lat: 49.1374098, lng: 19.3556172 },
    { lat: 49.1375394, lng: 19.3553309 },
    { lat: 49.137803, lng: 19.3546841 },
    { lat: 49.138392, lng: 19.3547951 },
    { lat: 49.1387101, lng: 19.3545836 },
    { lat: 49.1389312, lng: 19.3548521 },
    { lat: 49.1402933, lng: 19.353716 },
    { lat: 49.1406715, lng: 19.3529159 },
    { lat: 49.1417172, lng: 19.3507816 },
    { lat: 49.1426425, lng: 19.34693 },
    { lat: 49.1429517, lng: 19.3456432 },
    { lat: 49.1363527, lng: 19.344962 },
    { lat: 49.1360316, lng: 19.3458713 },
    { lat: 49.1354779, lng: 19.3468702 },
    { lat: 49.135187, lng: 19.347538 },
    { lat: 49.1348546, lng: 19.3478468 },
    { lat: 49.1343447, lng: 19.3483873 },
    { lat: 49.1339627, lng: 19.3487948 },
    { lat: 49.1335611, lng: 19.3490992 },
    { lat: 49.1332582, lng: 19.3493911 },
    { lat: 49.1327263, lng: 19.3501813 },
    { lat: 49.1324802, lng: 19.3506991 },
    { lat: 49.1322688, lng: 19.3510936 },
    { lat: 49.1319894, lng: 19.3514545 },
    { lat: 49.1316512, lng: 19.351739 },
    { lat: 49.1313085, lng: 19.3518794 },
    { lat: 49.1305895, lng: 19.351805 },
    { lat: 49.1297285, lng: 19.3516145 },
    { lat: 49.1291834, lng: 19.3512624 },
    { lat: 49.128351, lng: 19.3504109 },
    { lat: 49.1278886, lng: 19.3501128 },
    { lat: 49.1270777, lng: 19.3501755 },
    { lat: 49.1267813, lng: 19.3501734 },
    { lat: 49.1259573, lng: 19.349525 },
    { lat: 49.1249959, lng: 19.3487086 },
    { lat: 49.1247235, lng: 19.348429 },
    { lat: 49.1245015, lng: 19.3480423 },
    { lat: 49.1244143, lng: 19.3478208 },
    { lat: 49.1243663, lng: 19.347735 },
    { lat: 49.1243279, lng: 19.3477569 },
    { lat: 49.1241937, lng: 19.3478748 },
    { lat: 49.1230155, lng: 19.3481008 },
    { lat: 49.1223579, lng: 19.3489053 },
    { lat: 49.121939, lng: 19.3493422 },
    { lat: 49.1214886, lng: 19.3497822 },
    { lat: 49.1213771, lng: 19.3503078 },
    { lat: 49.1212264, lng: 19.3504833 },
    { lat: 49.1208317, lng: 19.3509075 },
    { lat: 49.1208114, lng: 19.3510986 },
    { lat: 49.1208184, lng: 19.3511759 },
    { lat: 49.1210003, lng: 19.3520699 },
    { lat: 49.1209666, lng: 19.3526244 },
    { lat: 49.1199437, lng: 19.3532288 },
    { lat: 49.1185626, lng: 19.3539855 },
    { lat: 49.1174831, lng: 19.3548722 },
    { lat: 49.1170763, lng: 19.3548037 },
    { lat: 49.11666, lng: 19.355201 },
    { lat: 49.1161827, lng: 19.3549944 },
    { lat: 49.1155313, lng: 19.3554041 },
    { lat: 49.1148698, lng: 19.3569592 },
    { lat: 49.1139966, lng: 19.3588094 },
    { lat: 49.1135834, lng: 19.3598459 },
    { lat: 49.1135716, lng: 19.3599109 },
    { lat: 49.1140493, lng: 19.3608157 },
    { lat: 49.1135024, lng: 19.3616509 },
    { lat: 49.1134507, lng: 19.3622279 },
    { lat: 49.1133781, lng: 19.3626275 },
    { lat: 49.1131498, lng: 19.3636453 },
    { lat: 49.1130133, lng: 19.3642181 },
    { lat: 49.1127704, lng: 19.3646587 },
    { lat: 49.1125892, lng: 19.3650741 },
    { lat: 49.1126106, lng: 19.3658413 },
    { lat: 49.1127486, lng: 19.3672421 },
    { lat: 49.1135863, lng: 19.3682348 },
    { lat: 49.1120949, lng: 19.3689206 },
    { lat: 49.1119089, lng: 19.3696682 },
    { lat: 49.1117844, lng: 19.3712873 },
    { lat: 49.1100185, lng: 19.3725108 },
    { lat: 49.1097336, lng: 19.3723738 },
    { lat: 49.1092432, lng: 19.3725167 },
    { lat: 49.1084225, lng: 19.3732798 },
    { lat: 49.1080615, lng: 19.3736546 },
    { lat: 49.1078768, lng: 19.3739449 },
    { lat: 49.107605, lng: 19.3741816 },
    { lat: 49.1074082, lng: 19.3735912 },
    { lat: 49.1058082, lng: 19.3752763 },
    { lat: 49.1055808, lng: 19.3750406 },
    { lat: 49.1052503, lng: 19.3757319 },
    { lat: 49.1052064, lng: 19.3756612 },
    { lat: 49.1048544, lng: 19.3760379 },
    { lat: 49.104638, lng: 19.3759891 },
    { lat: 49.1041346, lng: 19.3759 },
    { lat: 49.1040021, lng: 19.3761014 },
    { lat: 49.1039793, lng: 19.3762151 },
    { lat: 49.1030374, lng: 19.3775921 },
    { lat: 49.1029248, lng: 19.3774979 },
    { lat: 49.1025073, lng: 19.3777497 },
    { lat: 49.102224, lng: 19.3783157 },
    { lat: 49.1013281, lng: 19.3796682 },
    { lat: 49.1009812, lng: 19.3794506 },
    { lat: 49.1006945, lng: 19.3800205 },
    { lat: 49.1004337, lng: 19.380466 },
    { lat: 49.1005958, lng: 19.3807063 },
    { lat: 49.1000262, lng: 19.3822092 },
    { lat: 49.0991813, lng: 19.3817353 },
    { lat: 49.0981878, lng: 19.383213 },
    { lat: 49.0980815, lng: 19.3856577 },
    { lat: 49.0971727, lng: 19.3861811 },
    { lat: 49.0968189, lng: 19.3863015 },
    { lat: 49.0958468, lng: 19.3866971 },
    { lat: 49.0956836, lng: 19.3867012 },
    { lat: 49.0950012, lng: 19.3868335 },
    { lat: 49.0944006, lng: 19.3867868 },
    { lat: 49.094204, lng: 19.3867233 },
    { lat: 49.0942623, lng: 19.3878022 },
    { lat: 49.0942292, lng: 19.3885138 },
    { lat: 49.0941508, lng: 19.3892009 },
    { lat: 49.094024, lng: 19.3899119 },
    { lat: 49.0935635, lng: 19.3916558 },
    { lat: 49.0932865, lng: 19.3924083 },
    { lat: 49.0930017, lng: 19.3932108 },
    { lat: 49.0929124, lng: 19.3935179 },
    { lat: 49.0928152, lng: 19.3941609 },
    { lat: 49.0927975, lng: 19.3943886 },
    { lat: 49.0927795, lng: 19.3950605 },
    { lat: 49.0928422, lng: 19.3957053 },
    { lat: 49.0929037, lng: 19.3960103 },
    { lat: 49.0929844, lng: 19.3963371 },
    { lat: 49.0930282, lng: 19.396432 },
    { lat: 49.0931499, lng: 19.3967212 },
    { lat: 49.0932551, lng: 19.3968953 },
    { lat: 49.0935396, lng: 19.397223 },
    { lat: 49.0937294, lng: 19.3973985 },
    { lat: 49.0940712, lng: 19.3976239 },
    { lat: 49.094315, lng: 19.3977572 },
    { lat: 49.0947458, lng: 19.3978817 },
    { lat: 49.095157, lng: 19.3979523 },
    { lat: 49.0954847, lng: 19.3980682 },
    { lat: 49.0957287, lng: 19.3981759 },
    { lat: 49.0958604, lng: 19.3982253 },
    { lat: 49.0959318, lng: 19.3982571 },
    { lat: 49.0962443, lng: 19.3984815 },
    { lat: 49.0963101, lng: 19.398657 },
    { lat: 49.0964445, lng: 19.398905 },
    { lat: 49.0965021, lng: 19.3990768 },
    { lat: 49.0966848, lng: 19.3996367 },
    { lat: 49.0967207, lng: 19.3997529 },
  ],
  ValaskáDubová: [
    { lat: 49.1188256, lng: 19.273501 },
    { lat: 49.1198341, lng: 19.2777225 },
    { lat: 49.1202815, lng: 19.2777006 },
    { lat: 49.1206509, lng: 19.2804839 },
    { lat: 49.1216159, lng: 19.2826185 },
    { lat: 49.1208444, lng: 19.2836225 },
    { lat: 49.1219292, lng: 19.2856715 },
    { lat: 49.1224135, lng: 19.2862545 },
    { lat: 49.1226123, lng: 19.2862624 },
    { lat: 49.1229111, lng: 19.2861948 },
    { lat: 49.1231883, lng: 19.2858618 },
    { lat: 49.1238314, lng: 19.2876119 },
    { lat: 49.1238999, lng: 19.28779 },
    { lat: 49.1251264, lng: 19.2884817 },
    { lat: 49.1254561, lng: 19.2892254 },
    { lat: 49.1257363, lng: 19.2895503 },
    { lat: 49.125807, lng: 19.2898268 },
    { lat: 49.1258681, lng: 19.2898879 },
    { lat: 49.1259987, lng: 19.2900186 },
    { lat: 49.1260597, lng: 19.2903266 },
    { lat: 49.1261571, lng: 19.2908158 },
    { lat: 49.1267763, lng: 19.291628 },
    { lat: 49.1266762, lng: 19.291824 },
    { lat: 49.1265464, lng: 19.2920586 },
    { lat: 49.1261884, lng: 19.2927743 },
    { lat: 49.125571, lng: 19.2932286 },
    { lat: 49.125222, lng: 19.2933784 },
    { lat: 49.1246045, lng: 19.2935952 },
    { lat: 49.1248244, lng: 19.2940459 },
    { lat: 49.1254492, lng: 19.2946253 },
    { lat: 49.1259186, lng: 19.295299 },
    { lat: 49.1262437, lng: 19.2958835 },
    { lat: 49.1263893, lng: 19.2969038 },
    { lat: 49.1263305, lng: 19.2981809 },
    { lat: 49.126318, lng: 19.2986289 },
    { lat: 49.12635, lng: 19.298924 },
    { lat: 49.1259655, lng: 19.2990388 },
    { lat: 49.1260453, lng: 19.2992977 },
    { lat: 49.1258829, lng: 19.2993879 },
    { lat: 49.1256522, lng: 19.2993688 },
    { lat: 49.1255818, lng: 19.2992186 },
    { lat: 49.1254648, lng: 19.2990321 },
    { lat: 49.125343, lng: 19.2989358 },
    { lat: 49.1251391, lng: 19.2988687 },
    { lat: 49.1245355, lng: 19.2990177 },
    { lat: 49.122556, lng: 19.2992911 },
    { lat: 49.1225467, lng: 19.2995739 },
    { lat: 49.123455, lng: 19.299618 },
    { lat: 49.1239952, lng: 19.2996955 },
    { lat: 49.1247544, lng: 19.3001166 },
    { lat: 49.1250098, lng: 19.3002227 },
    { lat: 49.1254487, lng: 19.3003045 },
    { lat: 49.1261178, lng: 19.3006291 },
    { lat: 49.1260682, lng: 19.3008235 },
    { lat: 49.1262937, lng: 19.3011045 },
    { lat: 49.1254472, lng: 19.3045005 },
    { lat: 49.125834, lng: 19.3048587 },
    { lat: 49.1269856, lng: 19.3050478 },
    { lat: 49.1280478, lng: 19.3052456 },
    { lat: 49.128689, lng: 19.3044167 },
    { lat: 49.1289715, lng: 19.3045521 },
    { lat: 49.1291748, lng: 19.3047752 },
    { lat: 49.1293865, lng: 19.3047475 },
    { lat: 49.1294202, lng: 19.3051228 },
    { lat: 49.1296944, lng: 19.3057014 },
    { lat: 49.1298272, lng: 19.3060316 },
    { lat: 49.1301078, lng: 19.306335 },
    { lat: 49.1301923, lng: 19.3067172 },
    { lat: 49.1301439, lng: 19.3071717 },
    { lat: 49.1300136, lng: 19.3077741 },
    { lat: 49.1300336, lng: 19.3080961 },
    { lat: 49.1304679, lng: 19.3092028 },
    { lat: 49.1304583, lng: 19.3094975 },
    { lat: 49.1306082, lng: 19.3101648 },
    { lat: 49.1304836, lng: 19.3112409 },
    { lat: 49.1304974, lng: 19.3119605 },
    { lat: 49.1305516, lng: 19.3127963 },
    { lat: 49.1303148, lng: 19.3137944 },
    { lat: 49.1302035, lng: 19.3146794 },
    { lat: 49.1300975, lng: 19.3150347 },
    { lat: 49.1308747, lng: 19.318955 },
    { lat: 49.1311692, lng: 19.3196989 },
    { lat: 49.1319934, lng: 19.3202266 },
    { lat: 49.1324522, lng: 19.3207553 },
    { lat: 49.1328328, lng: 19.3213915 },
    { lat: 49.1329467, lng: 19.3218734 },
    { lat: 49.1330195, lng: 19.3221678 },
    { lat: 49.1331212, lng: 19.3224168 },
    { lat: 49.1333797, lng: 19.3231139 },
    { lat: 49.134001, lng: 19.3250046 },
    { lat: 49.1341322, lng: 19.325728 },
    { lat: 49.1341259, lng: 19.3265986 },
    { lat: 49.134322, lng: 19.3275988 },
    { lat: 49.1345633, lng: 19.3279146 },
    { lat: 49.1348565, lng: 19.3289664 },
    { lat: 49.134976, lng: 19.330136 },
    { lat: 49.1350629, lng: 19.3303996 },
    { lat: 49.1351136, lng: 19.3306838 },
    { lat: 49.1353388, lng: 19.3308279 },
    { lat: 49.1360333, lng: 19.3317619 },
    { lat: 49.1365061, lng: 19.3318922 },
    { lat: 49.1368739, lng: 19.3319047 },
    { lat: 49.1373579, lng: 19.3319614 },
    { lat: 49.1381281, lng: 19.3323794 },
    { lat: 49.1390075, lng: 19.3329333 },
    { lat: 49.1395644, lng: 19.3334647 },
    { lat: 49.1397109, lng: 19.3343726 },
    { lat: 49.1397684, lng: 19.3353007 },
    { lat: 49.1398722, lng: 19.3360427 },
    { lat: 49.1403538, lng: 19.3377734 },
    { lat: 49.1412703, lng: 19.3383284 },
    { lat: 49.1418309, lng: 19.3388879 },
    { lat: 49.1425629, lng: 19.3398077 },
    { lat: 49.1431955, lng: 19.3409187 },
    { lat: 49.1436508, lng: 19.3414195 },
    { lat: 49.1440827, lng: 19.3429082 },
    { lat: 49.1444859, lng: 19.343552 },
    { lat: 49.1454412, lng: 19.345478 },
    { lat: 49.1465279, lng: 19.3451053 },
    { lat: 49.1466656, lng: 19.3450603 },
    { lat: 49.1468597, lng: 19.3448486 },
    { lat: 49.1479132, lng: 19.3443313 },
    { lat: 49.14799, lng: 19.343339 },
    { lat: 49.148323, lng: 19.338729 },
    { lat: 49.148717, lng: 19.333219 },
    { lat: 49.148329, lng: 19.329572 },
    { lat: 49.148863, lng: 19.327413 },
    { lat: 49.149497, lng: 19.326014 },
    { lat: 49.149904, lng: 19.324886 },
    { lat: 49.15068, lng: 19.32161 },
    { lat: 49.150783, lng: 19.320242 },
    { lat: 49.150849, lng: 19.31937 },
    { lat: 49.151308, lng: 19.316805 },
    { lat: 49.152504, lng: 19.317332 },
    { lat: 49.152947, lng: 19.315016 },
    { lat: 49.152457, lng: 19.31378 },
    { lat: 49.151667, lng: 19.312105 },
    { lat: 49.151018, lng: 19.311183 },
    { lat: 49.150827, lng: 19.310908 },
    { lat: 49.150243, lng: 19.309955 },
    { lat: 49.150105, lng: 19.30973 },
    { lat: 49.150276, lng: 19.308356 },
    { lat: 49.150321, lng: 19.3081 },
    { lat: 49.150253, lng: 19.306017 },
    { lat: 49.150411, lng: 19.304908 },
    { lat: 49.150503, lng: 19.304266 },
    { lat: 49.150868, lng: 19.303391 },
    { lat: 49.151387, lng: 19.303282 },
    { lat: 49.151674, lng: 19.302952 },
    { lat: 49.151602, lng: 19.302451 },
    { lat: 49.151579, lng: 19.302072 },
    { lat: 49.151582, lng: 19.301706 },
    { lat: 49.1518, lng: 19.300662 },
    { lat: 49.151887, lng: 19.300243 },
    { lat: 49.151929, lng: 19.300044 },
    { lat: 49.151488, lng: 19.299454 },
    { lat: 49.151332, lng: 19.299139 },
    { lat: 49.150344, lng: 19.297428 },
    { lat: 49.150953, lng: 19.296641 },
    { lat: 49.15141, lng: 19.295813 },
    { lat: 49.15174, lng: 19.295026 },
    { lat: 49.151957, lng: 19.294373 },
    { lat: 49.151975, lng: 19.294317 },
    { lat: 49.152173, lng: 19.293717 },
    { lat: 49.152848, lng: 19.293229 },
    { lat: 49.152826, lng: 19.293026 },
    { lat: 49.152669, lng: 19.29165 },
    { lat: 49.152541, lng: 19.289796 },
    { lat: 49.152575, lng: 19.287945 },
    { lat: 49.15143, lng: 19.28603 },
    { lat: 49.1514, lng: 19.285846 },
    { lat: 49.151368, lng: 19.285636 },
    { lat: 49.151308, lng: 19.28527 },
    { lat: 49.151328, lng: 19.285178 },
    { lat: 49.151345, lng: 19.285092 },
    { lat: 49.151283, lng: 19.284485 },
    { lat: 49.151162, lng: 19.283335 },
    { lat: 49.151655, lng: 19.28313 },
    { lat: 49.152227, lng: 19.282827 },
    { lat: 49.151974, lng: 19.281974 },
    { lat: 49.151802, lng: 19.28176 },
    { lat: 49.151179, lng: 19.281052 },
    { lat: 49.150109, lng: 19.279847 },
    { lat: 49.150366, lng: 19.279463 },
    { lat: 49.150481, lng: 19.278783 },
    { lat: 49.150881, lng: 19.277586 },
    { lat: 49.150938, lng: 19.277283 },
    { lat: 49.150992, lng: 19.277041 },
    { lat: 49.151157, lng: 19.276169 },
    { lat: 49.15128, lng: 19.275496 },
    { lat: 49.151292, lng: 19.275456 },
    { lat: 49.151382, lng: 19.275046 },
    { lat: 49.151346, lng: 19.273237 },
    { lat: 49.1505597, lng: 19.2726307 },
    { lat: 49.1497655, lng: 19.2719098 },
    { lat: 49.1485891, lng: 19.2708979 },
    { lat: 49.1482521, lng: 19.2706376 },
    { lat: 49.1468925, lng: 19.269802 },
    { lat: 49.1464289, lng: 19.2693473 },
    { lat: 49.1461534, lng: 19.268795 },
    { lat: 49.1458506, lng: 19.2682792 },
    { lat: 49.1453936, lng: 19.2677061 },
    { lat: 49.1451049, lng: 19.2672294 },
    { lat: 49.1450456, lng: 19.2671457 },
    { lat: 49.1441291, lng: 19.2648729 },
    { lat: 49.1431866, lng: 19.2647958 },
    { lat: 49.1430621, lng: 19.2647833 },
    { lat: 49.1421228, lng: 19.2648218 },
    { lat: 49.1417992, lng: 19.2648905 },
    { lat: 49.1414473, lng: 19.2650483 },
    { lat: 49.141404, lng: 19.2653731 },
    { lat: 49.1407995, lng: 19.2657653 },
    { lat: 49.1408473, lng: 19.2660793 },
    { lat: 49.1408185, lng: 19.2667952 },
    { lat: 49.140608, lng: 19.2683625 },
    { lat: 49.1405364, lng: 19.2686519 },
    { lat: 49.1404386, lng: 19.2689684 },
    { lat: 49.1401843, lng: 19.2696377 },
    { lat: 49.1400188, lng: 19.2698515 },
    { lat: 49.1399924, lng: 19.269866 },
    { lat: 49.140012, lng: 19.2699714 },
    { lat: 49.1400102, lng: 19.2700526 },
    { lat: 49.1399607, lng: 19.270147 },
    { lat: 49.1394007, lng: 19.2708585 },
    { lat: 49.1389659, lng: 19.2714967 },
    { lat: 49.1386343, lng: 19.2722044 },
    { lat: 49.1369831, lng: 19.2713991 },
    { lat: 49.1368098, lng: 19.2721137 },
    { lat: 49.1364644, lng: 19.2728078 },
    { lat: 49.1362731, lng: 19.2734071 },
    { lat: 49.1349808, lng: 19.2730357 },
    { lat: 49.1349578, lng: 19.2729803 },
    { lat: 49.1349589, lng: 19.272933 },
    { lat: 49.1335106, lng: 19.2722431 },
    { lat: 49.132184, lng: 19.2714163 },
    { lat: 49.1321795, lng: 19.2714646 },
    { lat: 49.132088, lng: 19.2714136 },
    { lat: 49.1319149, lng: 19.2711618 },
    { lat: 49.1315067, lng: 19.2714665 },
    { lat: 49.1305654, lng: 19.2712949 },
    { lat: 49.1295939, lng: 19.2710852 },
    { lat: 49.1288996, lng: 19.2709869 },
    { lat: 49.1283379, lng: 19.2709294 },
    { lat: 49.1277773, lng: 19.2713914 },
    { lat: 49.1273035, lng: 19.2716426 },
    { lat: 49.1270641, lng: 19.2717544 },
    { lat: 49.1268402, lng: 19.2718764 },
    { lat: 49.1266244, lng: 19.271987 },
    { lat: 49.1263005, lng: 19.2721424 },
    { lat: 49.1258166, lng: 19.2715195 },
    { lat: 49.1253293, lng: 19.2708952 },
    { lat: 49.1250475, lng: 19.270662 },
    { lat: 49.1243555, lng: 19.2699535 },
    { lat: 49.1232464, lng: 19.2686778 },
    { lat: 49.1230538, lng: 19.2684668 },
    { lat: 49.1222088, lng: 19.2677915 },
    { lat: 49.121517, lng: 19.2669698 },
    { lat: 49.1207578, lng: 19.2687704 },
    { lat: 49.1201242, lng: 19.2701561 },
    { lat: 49.1199811, lng: 19.2704865 },
    { lat: 49.1197476, lng: 19.2709791 },
    { lat: 49.119384, lng: 19.271603 },
    { lat: 49.1192327, lng: 19.271858 },
    { lat: 49.1188256, lng: 19.273501 },
  ],
  Ľubochňa: [
    { lat: 49.0286786, lng: 19.2213083 },
    { lat: 49.0297912, lng: 19.2215666 },
    { lat: 49.0298335, lng: 19.2215763 },
    { lat: 49.0307703, lng: 19.2217935 },
    { lat: 49.0309314, lng: 19.2215348 },
    { lat: 49.031853, lng: 19.2197293 },
    { lat: 49.0322504, lng: 19.2199033 },
    { lat: 49.0326606, lng: 19.2202972 },
    { lat: 49.0330036, lng: 19.2207298 },
    { lat: 49.0333864, lng: 19.2209043 },
    { lat: 49.0337218, lng: 19.2209642 },
    { lat: 49.0340873, lng: 19.2206421 },
    { lat: 49.0367222, lng: 19.2218994 },
    { lat: 49.0378096, lng: 19.2216353 },
    { lat: 49.0397245, lng: 19.220191 },
    { lat: 49.041126, lng: 19.2191226 },
    { lat: 49.0423811, lng: 19.2180428 },
    { lat: 49.0413489, lng: 19.2159925 },
    { lat: 49.043052, lng: 19.2159235 },
    { lat: 49.0430747, lng: 19.2159227 },
    { lat: 49.0430696, lng: 19.2160774 },
    { lat: 49.0431397, lng: 19.2162237 },
    { lat: 49.0432544, lng: 19.216309 },
    { lat: 49.0435843, lng: 19.2165577 },
    { lat: 49.0438908, lng: 19.2166284 },
    { lat: 49.0439978, lng: 19.2165313 },
    { lat: 49.044377, lng: 19.2164953 },
    { lat: 49.0447419, lng: 19.2164657 },
    { lat: 49.045045, lng: 19.2164613 },
    { lat: 49.0452882, lng: 19.216479 },
    { lat: 49.0456959, lng: 19.2165678 },
    { lat: 49.0460051, lng: 19.2164804 },
    { lat: 49.0461305, lng: 19.2163984 },
    { lat: 49.0462086, lng: 19.2163329 },
    { lat: 49.0462554, lng: 19.2163238 },
    { lat: 49.0463239, lng: 19.2163132 },
    { lat: 49.0464649, lng: 19.2162256 },
    { lat: 49.0465598, lng: 19.2162049 },
    { lat: 49.0467221, lng: 19.2161814 },
    { lat: 49.0468541, lng: 19.2162098 },
    { lat: 49.0470602, lng: 19.2161598 },
    { lat: 49.0471739, lng: 19.2162494 },
    { lat: 49.0474307, lng: 19.2161277 },
    { lat: 49.047608, lng: 19.2161362 },
    { lat: 49.0478513, lng: 19.2160145 },
    { lat: 49.0481469, lng: 19.2159725 },
    { lat: 49.0482579, lng: 19.2160072 },
    { lat: 49.0484481, lng: 19.2159363 },
    { lat: 49.0486732, lng: 19.2157886 },
    { lat: 49.0488758, lng: 19.2156862 },
    { lat: 49.0491118, lng: 19.215511 },
    { lat: 49.0492616, lng: 19.2153211 },
    { lat: 49.0494541, lng: 19.2150807 },
    { lat: 49.0495907, lng: 19.2148789 },
    { lat: 49.0497814, lng: 19.2147423 },
    { lat: 49.0500124, lng: 19.2143923 },
    { lat: 49.0504415, lng: 19.2139411 },
    { lat: 49.0505638, lng: 19.2138811 },
    { lat: 49.0506037, lng: 19.2138472 },
    { lat: 49.050607, lng: 19.2137724 },
    { lat: 49.0506106, lng: 19.2136858 },
    { lat: 49.0508328, lng: 19.2133874 },
    { lat: 49.0510983, lng: 19.2129768 },
    { lat: 49.0513274, lng: 19.2127029 },
    { lat: 49.0514572, lng: 19.2124286 },
    { lat: 49.0516283, lng: 19.2122402 },
    { lat: 49.0518238, lng: 19.2119204 },
    { lat: 49.0521808, lng: 19.2116354 },
    { lat: 49.0523849, lng: 19.2114501 },
    { lat: 49.0525033, lng: 19.2113136 },
    { lat: 49.0526943, lng: 19.21123 },
    { lat: 49.0528675, lng: 19.2110601 },
    { lat: 49.0531995, lng: 19.2108828 },
    { lat: 49.0536194, lng: 19.2105943 },
    { lat: 49.0539946, lng: 19.2104297 },
    { lat: 49.0540457, lng: 19.2104114 },
    { lat: 49.0543407, lng: 19.2103335 },
    { lat: 49.0549335, lng: 19.2103828 },
    { lat: 49.0553377, lng: 19.210491 },
    { lat: 49.0556415, lng: 19.2105264 },
    { lat: 49.0558277, lng: 19.2105523 },
    { lat: 49.0560321, lng: 19.2106771 },
    { lat: 49.0563106, lng: 19.210937 },
    { lat: 49.0567086, lng: 19.2113129 },
    { lat: 49.0569168, lng: 19.2113752 },
    { lat: 49.0571914, lng: 19.2114842 },
    { lat: 49.0574563, lng: 19.2116688 },
    { lat: 49.0576521, lng: 19.211824 },
    { lat: 49.0579401, lng: 19.2121203 },
    { lat: 49.0583562, lng: 19.2127293 },
    { lat: 49.0585198, lng: 19.2129428 },
    { lat: 49.0587285, lng: 19.213199 },
    { lat: 49.059063, lng: 19.2134983 },
    { lat: 49.0593566, lng: 19.2137437 },
    { lat: 49.0600065, lng: 19.2138744 },
    { lat: 49.0604441, lng: 19.2139997 },
    { lat: 49.0609875, lng: 19.2142581 },
    { lat: 49.0614088, lng: 19.2142753 },
    { lat: 49.0619305, lng: 19.2144613 },
    { lat: 49.062232, lng: 19.2145422 },
    { lat: 49.0627087, lng: 19.2146988 },
    { lat: 49.0630966, lng: 19.2145393 },
    { lat: 49.0634352, lng: 19.2143677 },
    { lat: 49.0637088, lng: 19.2141233 },
    { lat: 49.0640262, lng: 19.2140946 },
    { lat: 49.0644383, lng: 19.2140154 },
    { lat: 49.0651091, lng: 19.2136201 },
    { lat: 49.0654877, lng: 19.2134526 },
    { lat: 49.0658533, lng: 19.2130677 },
    { lat: 49.0662848, lng: 19.2128763 },
    { lat: 49.0668235, lng: 19.2128754 },
    { lat: 49.0672228, lng: 19.2126959 },
    { lat: 49.0676644, lng: 19.2127338 },
    { lat: 49.0681229, lng: 19.2127299 },
    { lat: 49.0681202, lng: 19.2126986 },
    { lat: 49.068375, lng: 19.2041701 },
    { lat: 49.0687052, lng: 19.2037952 },
    { lat: 49.0689595, lng: 19.2030416 },
    { lat: 49.0691345, lng: 19.2030281 },
    { lat: 49.0692986, lng: 19.2025733 },
    { lat: 49.0694397, lng: 19.2022278 },
    { lat: 49.0695716, lng: 19.2022065 },
    { lat: 49.0696194, lng: 19.2022619 },
    { lat: 49.0696317, lng: 19.2011192 },
    { lat: 49.0696806, lng: 19.1983357 },
    { lat: 49.0697548, lng: 19.1954946 },
    { lat: 49.0705034, lng: 19.1947994 },
    { lat: 49.0707934, lng: 19.1940155 },
    { lat: 49.0712535, lng: 19.1932068 },
    { lat: 49.0718605, lng: 19.1924074 },
    { lat: 49.0724403, lng: 19.1925321 },
    { lat: 49.0736868, lng: 19.1921803 },
    { lat: 49.0746671, lng: 19.1918947 },
    { lat: 49.0752099, lng: 19.1920264 },
    { lat: 49.0764008, lng: 19.1904343 },
    { lat: 49.0773165, lng: 19.1899327 },
    { lat: 49.0774847, lng: 19.1907946 },
    { lat: 49.0778391, lng: 19.1917137 },
    { lat: 49.0781804, lng: 19.1919853 },
    { lat: 49.0798726, lng: 19.1919494 },
    { lat: 49.0812551, lng: 19.1920087 },
    { lat: 49.082012, lng: 19.1919491 },
    { lat: 49.0832073, lng: 19.1922643 },
    { lat: 49.0834301, lng: 19.1921875 },
    { lat: 49.0859399, lng: 19.1928066 },
    { lat: 49.0877639, lng: 19.1928343 },
    { lat: 49.0885827, lng: 19.1868517 },
    { lat: 49.088583, lng: 19.1864597 },
    { lat: 49.0885563, lng: 19.1863271 },
    { lat: 49.0885443, lng: 19.1861278 },
    { lat: 49.0885538, lng: 19.1859991 },
    { lat: 49.088528, lng: 19.1858858 },
    { lat: 49.0885208, lng: 19.1857608 },
    { lat: 49.0884816, lng: 19.1856249 },
    { lat: 49.0884909, lng: 19.1853987 },
    { lat: 49.088469, lng: 19.1851135 },
    { lat: 49.0883711, lng: 19.1848566 },
    { lat: 49.0883609, lng: 19.1847391 },
    { lat: 49.0883258, lng: 19.1846693 },
    { lat: 49.0882622, lng: 19.1843812 },
    { lat: 49.088173, lng: 19.184123 },
    { lat: 49.0881969, lng: 19.1839639 },
    { lat: 49.0881919, lng: 19.1838619 },
    { lat: 49.0881733, lng: 19.1837317 },
    { lat: 49.0881759, lng: 19.1836005 },
    { lat: 49.0881443, lng: 19.1833919 },
    { lat: 49.0881468, lng: 19.1833028 },
    { lat: 49.0881208, lng: 19.1831859 },
    { lat: 49.0881124, lng: 19.1830603 },
    { lat: 49.088127, lng: 19.1828742 },
    { lat: 49.0881706, lng: 19.1827715 },
    { lat: 49.0882424, lng: 19.1824404 },
    { lat: 49.088255, lng: 19.1823534 },
    { lat: 49.0882804, lng: 19.1822937 },
    { lat: 49.0883, lng: 19.1821437 },
    { lat: 49.0883428, lng: 19.1820033 },
    { lat: 49.0883469, lng: 19.1818781 },
    { lat: 49.0883852, lng: 19.1818051 },
    { lat: 49.0884253, lng: 19.1816107 },
    { lat: 49.0884711, lng: 19.1814646 },
    { lat: 49.0884629, lng: 19.1813389 },
    { lat: 49.0885022, lng: 19.1812679 },
    { lat: 49.0885447, lng: 19.181006 },
    { lat: 49.0885701, lng: 19.1809241 },
    { lat: 49.0885473, lng: 19.1808058 },
    { lat: 49.0885628, lng: 19.1806138 },
    { lat: 49.088603, lng: 19.1803957 },
    { lat: 49.0885908, lng: 19.1802856 },
    { lat: 49.0886031, lng: 19.180145 },
    { lat: 49.0885895, lng: 19.1799574 },
    { lat: 49.0886158, lng: 19.179848 },
    { lat: 49.0886187, lng: 19.1797469 },
    { lat: 49.0885952, lng: 19.1794489 },
    { lat: 49.0886237, lng: 19.1792918 },
    { lat: 49.0886275, lng: 19.1791612 },
    { lat: 49.0886591, lng: 19.1790915 },
    { lat: 49.0886395, lng: 19.1789649 },
    { lat: 49.0886471, lng: 19.1787274 },
    { lat: 49.0886248, lng: 19.1785944 },
    { lat: 49.0886631, lng: 19.17843 },
    { lat: 49.0886652, lng: 19.1783366 },
    { lat: 49.0886442, lng: 19.1782063 },
    { lat: 49.0886712, lng: 19.1781105 },
    { lat: 49.0886568, lng: 19.1777953 },
    { lat: 49.0890132, lng: 19.1770999 },
    { lat: 49.0905248, lng: 19.1722614 },
    { lat: 49.0918121, lng: 19.1685093 },
    { lat: 49.0918638, lng: 19.1685049 },
    { lat: 49.0919817, lng: 19.1684528 },
    { lat: 49.0920871, lng: 19.1683218 },
    { lat: 49.0921339, lng: 19.1682403 },
    { lat: 49.092203, lng: 19.1681807 },
    { lat: 49.0922434, lng: 19.1680578 },
    { lat: 49.0923132, lng: 19.1679884 },
    { lat: 49.0923996, lng: 19.1678672 },
    { lat: 49.0924407, lng: 19.1677842 },
    { lat: 49.0924993, lng: 19.1677124 },
    { lat: 49.092534, lng: 19.1676391 },
    { lat: 49.0925413, lng: 19.1675358 },
    { lat: 49.0925704, lng: 19.1674588 },
    { lat: 49.0926341, lng: 19.1674052 },
    { lat: 49.0926847, lng: 19.1673336 },
    { lat: 49.0927613, lng: 19.1672621 },
    { lat: 49.0929142, lng: 19.1670894 },
    { lat: 49.0929362, lng: 19.1670118 },
    { lat: 49.0929458, lng: 19.1669269 },
    { lat: 49.0929802, lng: 19.1668478 },
    { lat: 49.0930491, lng: 19.166784 },
    { lat: 49.0932119, lng: 19.1666055 },
    { lat: 49.0932999, lng: 19.1664683 },
    { lat: 49.0933842, lng: 19.1663944 },
    { lat: 49.093431, lng: 19.1662934 },
    { lat: 49.0934765, lng: 19.1662322 },
    { lat: 49.0934815, lng: 19.1661467 },
    { lat: 49.0935025, lng: 19.1660692 },
    { lat: 49.0935652, lng: 19.1659682 },
    { lat: 49.0935859, lng: 19.1659087 },
    { lat: 49.0935812, lng: 19.1658553 },
    { lat: 49.093835, lng: 19.1650735 },
    { lat: 49.09389, lng: 19.16484 },
    { lat: 49.0941313, lng: 19.1646092 },
    { lat: 49.0943174, lng: 19.1643569 },
    { lat: 49.0945215, lng: 19.1642417 },
    { lat: 49.0946264, lng: 19.163849 },
    { lat: 49.0945022, lng: 19.163526 },
    { lat: 49.0946458, lng: 19.1632774 },
    { lat: 49.0949126, lng: 19.1630869 },
    { lat: 49.095075, lng: 19.1629243 },
    { lat: 49.0951115, lng: 19.162331 },
    { lat: 49.0949529, lng: 19.1622026 },
    { lat: 49.0948601, lng: 19.1622605 },
    { lat: 49.0947687, lng: 19.1620284 },
    { lat: 49.0946991, lng: 19.1620345 },
    { lat: 49.094443, lng: 19.1614042 },
    { lat: 49.0945207, lng: 19.1610789 },
    { lat: 49.0945673, lng: 19.1609991 },
    { lat: 49.0945684, lng: 19.1608807 },
    { lat: 49.0950017, lng: 19.1605666 },
    { lat: 49.0950359, lng: 19.1606437 },
    { lat: 49.095148, lng: 19.1605843 },
    { lat: 49.0954135, lng: 19.1602507 },
    { lat: 49.09543, lng: 19.159987 },
    { lat: 49.0955879, lng: 19.1601058 },
    { lat: 49.095766, lng: 19.1604741 },
    { lat: 49.0958569, lng: 19.160861 },
    { lat: 49.0959063, lng: 19.1611338 },
    { lat: 49.0958066, lng: 19.1612831 },
    { lat: 49.095921, lng: 19.1621953 },
    { lat: 49.0960888, lng: 19.1621933 },
    { lat: 49.0965053, lng: 19.1622282 },
    { lat: 49.0975073, lng: 19.1626232 },
    { lat: 49.0976835, lng: 19.1627432 },
    { lat: 49.0976499, lng: 19.1631636 },
    { lat: 49.0976704, lng: 19.1637256 },
    { lat: 49.0977697, lng: 19.1640759 },
    { lat: 49.0978136, lng: 19.1643964 },
    { lat: 49.0978374, lng: 19.1647243 },
    { lat: 49.0980547, lng: 19.1654141 },
    { lat: 49.0982336, lng: 19.1659385 },
    { lat: 49.0985071, lng: 19.1659777 },
    { lat: 49.0986273, lng: 19.1658991 },
    { lat: 49.0986389, lng: 19.1663895 },
    { lat: 49.0985775, lng: 19.166614 },
    { lat: 49.0986596, lng: 19.1668641 },
    { lat: 49.0988786, lng: 19.1667343 },
    { lat: 49.09911, lng: 19.1670183 },
    { lat: 49.0996591, lng: 19.1669059 },
    { lat: 49.0999055, lng: 19.1670572 },
    { lat: 49.1034197, lng: 19.1656175 },
    { lat: 49.1034423, lng: 19.1657315 },
    { lat: 49.1035327, lng: 19.1657364 },
    { lat: 49.1035529, lng: 19.1658493 },
    { lat: 49.1034847, lng: 19.1659768 },
    { lat: 49.1034768, lng: 19.1666011 },
    { lat: 49.1034922, lng: 19.1670573 },
    { lat: 49.1034746, lng: 19.1672988 },
    { lat: 49.1035332, lng: 19.168016 },
    { lat: 49.1039696, lng: 19.1684139 },
    { lat: 49.1041653, lng: 19.1685224 },
    { lat: 49.1043074, lng: 19.168593 },
    { lat: 49.1042482, lng: 19.1687213 },
    { lat: 49.1042439, lng: 19.1691427 },
    { lat: 49.104581, lng: 19.1693953 },
    { lat: 49.1048484, lng: 19.1696349 },
    { lat: 49.1049118, lng: 19.1698501 },
    { lat: 49.1049262, lng: 19.1699805 },
    { lat: 49.1050861, lng: 19.1700477 },
    { lat: 49.1052012, lng: 19.1700257 },
    { lat: 49.1054505, lng: 19.170126 },
    { lat: 49.1056594, lng: 19.1701568 },
    { lat: 49.1057993, lng: 19.1701093 },
    { lat: 49.1059527, lng: 19.1701607 },
    { lat: 49.1060202, lng: 19.1701099 },
    { lat: 49.1061393, lng: 19.1701756 },
    { lat: 49.1063315, lng: 19.1701399 },
    { lat: 49.1064206, lng: 19.170069 },
    { lat: 49.1065305, lng: 19.1701523 },
    { lat: 49.1067253, lng: 19.1701026 },
    { lat: 49.1068365, lng: 19.1700235 },
    { lat: 49.1069721, lng: 19.1701464 },
    { lat: 49.1070824, lng: 19.1700958 },
    { lat: 49.1071815, lng: 19.1701199 },
    { lat: 49.1073255, lng: 19.1700644 },
    { lat: 49.107397, lng: 19.1700956 },
    { lat: 49.1074957, lng: 19.1699945 },
    { lat: 49.1076461, lng: 19.1700998 },
    { lat: 49.1079128, lng: 19.1699992 },
    { lat: 49.108048, lng: 19.1700627 },
    { lat: 49.1081676, lng: 19.1700215 },
    { lat: 49.1082603, lng: 19.170028 },
    { lat: 49.1083886, lng: 19.1699516 },
    { lat: 49.1085223, lng: 19.1700401 },
    { lat: 49.1086572, lng: 19.1700069 },
    { lat: 49.1087587, lng: 19.1700289 },
    { lat: 49.1088275, lng: 19.1699863 },
    { lat: 49.108973, lng: 19.170054 },
    { lat: 49.1091291, lng: 19.1700457 },
    { lat: 49.109238, lng: 19.1700108 },
    { lat: 49.1093957, lng: 19.1700578 },
    { lat: 49.1095122, lng: 19.1699712 },
    { lat: 49.1096828, lng: 19.1697749 },
    { lat: 49.1098482, lng: 19.1696062 },
    { lat: 49.1100082, lng: 19.1695108 },
    { lat: 49.110179, lng: 19.1693602 },
    { lat: 49.1103664, lng: 19.1692743 },
    { lat: 49.1105261, lng: 19.1691296 },
    { lat: 49.1106094, lng: 19.1690799 },
    { lat: 49.1106865, lng: 19.168995 },
    { lat: 49.1108373, lng: 19.168992 },
    { lat: 49.1109506, lng: 19.1688392 },
    { lat: 49.1110839, lng: 19.1687551 },
    { lat: 49.111216, lng: 19.1688042 },
    { lat: 49.1113653, lng: 19.1687222 },
    { lat: 49.1115517, lng: 19.1686605 },
    { lat: 49.1116565, lng: 19.168571 },
    { lat: 49.1117802, lng: 19.1686581 },
    { lat: 49.1119411, lng: 19.1686051 },
    { lat: 49.1120235, lng: 19.1685123 },
    { lat: 49.1121381, lng: 19.1686012 },
    { lat: 49.1124564, lng: 19.1684681 },
    { lat: 49.1125272, lng: 19.1683496 },
    { lat: 49.1126301, lng: 19.1684251 },
    { lat: 49.1129477, lng: 19.168327 },
    { lat: 49.1130892, lng: 19.1683858 },
    { lat: 49.1131823, lng: 19.1683088 },
    { lat: 49.1132615, lng: 19.1683585 },
    { lat: 49.1134265, lng: 19.1682995 },
    { lat: 49.1136276, lng: 19.1682624 },
    { lat: 49.1138672, lng: 19.1682732 },
    { lat: 49.113956, lng: 19.1681764 },
    { lat: 49.114122, lng: 19.1682088 },
    { lat: 49.1142933, lng: 19.1681142 },
    { lat: 49.1143699, lng: 19.1680171 },
    { lat: 49.1144629, lng: 19.1680789 },
    { lat: 49.114625, lng: 19.1680285 },
    { lat: 49.1148074, lng: 19.1679076 },
    { lat: 49.1149418, lng: 19.1679366 },
    { lat: 49.1151694, lng: 19.1678667 },
    { lat: 49.1152691, lng: 19.1677849 },
    { lat: 49.1155186, lng: 19.1677679 },
    { lat: 49.1156015, lng: 19.1677092 },
    { lat: 49.1156704, lng: 19.1677612 },
    { lat: 49.1157437, lng: 19.1677144 },
    { lat: 49.1158075, lng: 19.1677291 },
    { lat: 49.115873, lng: 19.167662 },
    { lat: 49.1159271, lng: 19.1676868 },
    { lat: 49.1160017, lng: 19.1676417 },
    { lat: 49.1160446, lng: 19.1677383 },
    { lat: 49.116625, lng: 19.1676415 },
    { lat: 49.1169816, lng: 19.167535 },
    { lat: 49.1170832, lng: 19.1676084 },
    { lat: 49.1172212, lng: 19.1678037 },
    { lat: 49.1173168, lng: 19.1679145 },
    { lat: 49.1173655, lng: 19.1679429 },
    { lat: 49.117418, lng: 19.1679553 },
    { lat: 49.1175017, lng: 19.1679368 },
    { lat: 49.1176285, lng: 19.1678585 },
    { lat: 49.11766, lng: 19.1678805 },
    { lat: 49.1176192, lng: 19.1680128 },
    { lat: 49.1174712, lng: 19.1681695 },
    { lat: 49.1176427, lng: 19.1683106 },
    { lat: 49.1177909, lng: 19.1685065 },
    { lat: 49.1182615, lng: 19.1689608 },
    { lat: 49.1184219, lng: 19.1693838 },
    { lat: 49.1183484, lng: 19.1693774 },
    { lat: 49.1184263, lng: 19.169659 },
    { lat: 49.1184411, lng: 19.1697343 },
    { lat: 49.1184854, lng: 19.1698347 },
    { lat: 49.1186592, lng: 19.1703477 },
    { lat: 49.1187154, lng: 19.1705767 },
    { lat: 49.118677, lng: 19.1705976 },
    { lat: 49.1185925, lng: 19.1708759 },
    { lat: 49.1185595, lng: 19.1710081 },
    { lat: 49.118498, lng: 19.1711136 },
    { lat: 49.1185241, lng: 19.1714169 },
    { lat: 49.1185851, lng: 19.1716263 },
    { lat: 49.1186123, lng: 19.1717686 },
    { lat: 49.1185077, lng: 19.1717991 },
    { lat: 49.1185845, lng: 19.1721729 },
    { lat: 49.1185424, lng: 19.1723468 },
    { lat: 49.1185764, lng: 19.1727017 },
    { lat: 49.1186569, lng: 19.1730104 },
    { lat: 49.1187223, lng: 19.1732186 },
    { lat: 49.1189213, lng: 19.1731882 },
    { lat: 49.1192164, lng: 19.1731358 },
    { lat: 49.1192159, lng: 19.1731395 },
    { lat: 49.1194037, lng: 19.1732572 },
    { lat: 49.1195008, lng: 19.1733319 },
    { lat: 49.1195921, lng: 19.1734223 },
    { lat: 49.1196715, lng: 19.1735728 },
    { lat: 49.1196799, lng: 19.1735801 },
    { lat: 49.1197198, lng: 19.1738477 },
    { lat: 49.1197198, lng: 19.1740765 },
    { lat: 49.1196875, lng: 19.1745483 },
    { lat: 49.119833, lng: 19.1745287 },
    { lat: 49.1203236, lng: 19.174695 },
    { lat: 49.1205701, lng: 19.1749464 },
    { lat: 49.1207575, lng: 19.1751369 },
    { lat: 49.1208417, lng: 19.1752228 },
    { lat: 49.1208518, lng: 19.1752209 },
    { lat: 49.1209335, lng: 19.1752106 },
    { lat: 49.1209459, lng: 19.1752089 },
    { lat: 49.1209973, lng: 19.175202 },
    { lat: 49.1212203, lng: 19.1751729 },
    { lat: 49.1211608, lng: 19.1751105 },
    { lat: 49.1216925, lng: 19.1744056 },
    { lat: 49.1216978, lng: 19.1744 },
    { lat: 49.1217289, lng: 19.1743677 },
    { lat: 49.1217655, lng: 19.1743301 },
    { lat: 49.1217943, lng: 19.1742999 },
    { lat: 49.1218148, lng: 19.1742785 },
    { lat: 49.1218298, lng: 19.1742628 },
    { lat: 49.1218545, lng: 19.1743089 },
    { lat: 49.1218698, lng: 19.1742982 },
    { lat: 49.1218926, lng: 19.1742809 },
    { lat: 49.1219232, lng: 19.1742591 },
    { lat: 49.1219526, lng: 19.1742377 },
    { lat: 49.1219668, lng: 19.1742275 },
    { lat: 49.1219742, lng: 19.1742195 },
    { lat: 49.1219923, lng: 19.1741994 },
    { lat: 49.1220405, lng: 19.1741454 },
    { lat: 49.1220748, lng: 19.1741077 },
    { lat: 49.1220929, lng: 19.174087 },
    { lat: 49.1221232, lng: 19.1741318 },
    { lat: 49.1221367, lng: 19.1741526 },
    { lat: 49.1221501, lng: 19.1741277 },
    { lat: 49.1221636, lng: 19.1741024 },
    { lat: 49.122181, lng: 19.174068 },
    { lat: 49.1222048, lng: 19.1740229 },
    { lat: 49.1222376, lng: 19.1739594 },
    { lat: 49.1222696, lng: 19.1738979 },
    { lat: 49.1222904, lng: 19.1738588 },
    { lat: 49.1223109, lng: 19.1738195 },
    { lat: 49.1223317, lng: 19.1737804 },
    { lat: 49.1223336, lng: 19.173777 },
    { lat: 49.122339, lng: 19.1737224 },
    { lat: 49.1223481, lng: 19.1736317 },
    { lat: 49.1223524, lng: 19.1736291 },
    { lat: 49.1224063, lng: 19.1736007 },
    { lat: 49.1224963, lng: 19.173552 },
    { lat: 49.1225327, lng: 19.1735332 },
    { lat: 49.1225918, lng: 19.173501 },
    { lat: 49.1226655, lng: 19.1734617 },
    { lat: 49.1227028, lng: 19.1734416 },
    { lat: 49.1227491, lng: 19.1734166 },
    { lat: 49.1227995, lng: 19.1733901 },
    { lat: 49.1228446, lng: 19.1733655 },
    { lat: 49.1228842, lng: 19.1733449 },
    { lat: 49.1229148, lng: 19.1733285 },
    { lat: 49.1229533, lng: 19.1733079 },
    { lat: 49.1229917, lng: 19.1732871 },
    { lat: 49.1230336, lng: 19.1732647 },
    { lat: 49.1232532, lng: 19.173214 },
    { lat: 49.1233111, lng: 19.1732006 },
    { lat: 49.1234024, lng: 19.1731795 },
    { lat: 49.1238054, lng: 19.1730973 },
    { lat: 49.1239382, lng: 19.1730698 },
    { lat: 49.1241021, lng: 19.1730362 },
    { lat: 49.1243124, lng: 19.1729977 },
    { lat: 49.1243234, lng: 19.1729957 },
    { lat: 49.1243379, lng: 19.1729923 },
    { lat: 49.124368, lng: 19.1729856 },
    { lat: 49.124407, lng: 19.1729769 },
    { lat: 49.124446, lng: 19.1729682 },
    { lat: 49.1245253, lng: 19.1729506 },
    { lat: 49.1245666, lng: 19.1729416 },
    { lat: 49.1246209, lng: 19.1728776 },
    { lat: 49.124786, lng: 19.1729118 },
    { lat: 49.1250399, lng: 19.1729642 },
    { lat: 49.1252049, lng: 19.17274 },
    { lat: 49.1252161, lng: 19.1727409 },
    { lat: 49.1252511, lng: 19.172742 },
    { lat: 49.1252771, lng: 19.172743 },
    { lat: 49.1253032, lng: 19.1727444 },
    { lat: 49.1253292, lng: 19.1727451 },
    { lat: 49.1253529, lng: 19.1727464 },
    { lat: 49.1253574, lng: 19.1727462 },
    { lat: 49.1253892, lng: 19.1727549 },
    { lat: 49.1254258, lng: 19.1727644 },
    { lat: 49.1254561, lng: 19.172761 },
    { lat: 49.1254909, lng: 19.1727574 },
    { lat: 49.1255223, lng: 19.1727539 },
    { lat: 49.1255559, lng: 19.1727501 },
    { lat: 49.1255907, lng: 19.1727465 },
    { lat: 49.1256065, lng: 19.1727447 },
    { lat: 49.1256239, lng: 19.1727575 },
    { lat: 49.1256661, lng: 19.1727902 },
    { lat: 49.125688, lng: 19.1728 },
    { lat: 49.1257214, lng: 19.1728156 },
    { lat: 49.1257825, lng: 19.1728433 },
    { lat: 49.1258331, lng: 19.1729131 },
    { lat: 49.1259528, lng: 19.1729414 },
    { lat: 49.1259688, lng: 19.172945 },
    { lat: 49.1260494, lng: 19.1726984 },
    { lat: 49.1261076, lng: 19.1727627 },
    { lat: 49.126129, lng: 19.1727862 },
    { lat: 49.1261505, lng: 19.172809 },
    { lat: 49.1262209, lng: 19.1728867 },
    { lat: 49.1262614, lng: 19.1727727 },
    { lat: 49.1264578, lng: 19.1727088 },
    { lat: 49.1265581, lng: 19.1726147 },
    { lat: 49.1272425, lng: 19.1728268 },
    { lat: 49.1272797, lng: 19.1726437 },
    { lat: 49.127678, lng: 19.1727417 },
    { lat: 49.1280685, lng: 19.1726609 },
    { lat: 49.1286894, lng: 19.1726793 },
    { lat: 49.1291498, lng: 19.1729718 },
    { lat: 49.1294273, lng: 19.1729765 },
    { lat: 49.1296955, lng: 19.1728094 },
    { lat: 49.1300415, lng: 19.1726977 },
    { lat: 49.1303753, lng: 19.172673 },
    { lat: 49.1306868, lng: 19.172776 },
    { lat: 49.1307662, lng: 19.1728344 },
    { lat: 49.1308644, lng: 19.1729068 },
    { lat: 49.1308756, lng: 19.172835 },
    { lat: 49.1308841, lng: 19.1727764 },
    { lat: 49.1309012, lng: 19.1726665 },
    { lat: 49.130903, lng: 19.1726544 },
    { lat: 49.1309108, lng: 19.1726078 },
    { lat: 49.1309199, lng: 19.1725424 },
    { lat: 49.1309305, lng: 19.1724775 },
    { lat: 49.1309398, lng: 19.1724167 },
    { lat: 49.1309543, lng: 19.1723218 },
    { lat: 49.1309621, lng: 19.1722731 },
    { lat: 49.1309639, lng: 19.1722614 },
    { lat: 49.1309802, lng: 19.1721793 },
    { lat: 49.1310838, lng: 19.1716662 },
    { lat: 49.1310955, lng: 19.1716058 },
    { lat: 49.1311418, lng: 19.1710758 },
    { lat: 49.1311465, lng: 19.1710321 },
    { lat: 49.131157, lng: 19.1709031 },
    { lat: 49.1311652, lng: 19.1708151 },
    { lat: 49.1311991, lng: 19.1704679 },
    { lat: 49.1312285, lng: 19.1703292 },
    { lat: 49.1312511, lng: 19.170263 },
    { lat: 49.1313504, lng: 19.1699408 },
    { lat: 49.1314633, lng: 19.1695271 },
    { lat: 49.1314732, lng: 19.1694068 },
    { lat: 49.1314714, lng: 19.1691839 },
    { lat: 49.131451, lng: 19.1689972 },
    { lat: 49.1314543, lng: 19.168832 },
    { lat: 49.1315055, lng: 19.1683392 },
    { lat: 49.1315111, lng: 19.1682857 },
    { lat: 49.1315222, lng: 19.1678878 },
    { lat: 49.1315258, lng: 19.1677294 },
    { lat: 49.1315056, lng: 19.1673627 },
    { lat: 49.1314196, lng: 19.167358 },
    { lat: 49.1312831, lng: 19.1673763 },
    { lat: 49.1311557, lng: 19.1673313 },
    { lat: 49.1311295, lng: 19.1673224 },
    { lat: 49.1311033, lng: 19.1672739 },
    { lat: 49.13108, lng: 19.1672312 },
    { lat: 49.1307607, lng: 19.1678547 },
    { lat: 49.130591, lng: 19.1680937 },
    { lat: 49.1303217, lng: 19.1683952 },
    { lat: 49.1301013, lng: 19.168618 },
    { lat: 49.1297782, lng: 19.168855 },
    { lat: 49.1297119, lng: 19.1688625 },
    { lat: 49.1295369, lng: 19.1688337 },
    { lat: 49.1290578, lng: 19.1687384 },
    { lat: 49.128865, lng: 19.1686889 },
    { lat: 49.1288113, lng: 19.1686972 },
    { lat: 49.1287984, lng: 19.16869 },
    { lat: 49.1286337, lng: 19.1685909 },
    { lat: 49.1285108, lng: 19.1684971 },
    { lat: 49.1284108, lng: 19.1683634 },
    { lat: 49.1283314, lng: 19.1682615 },
    { lat: 49.1279902, lng: 19.1680406 },
    { lat: 49.1277738, lng: 19.1678062 },
    { lat: 49.1276377, lng: 19.167624 },
    { lat: 49.1273589, lng: 19.1673665 },
    { lat: 49.127224, lng: 19.1672331 },
    { lat: 49.1271404, lng: 19.1671367 },
    { lat: 49.126965, lng: 19.1669834 },
    { lat: 49.1268829, lng: 19.1668927 },
    { lat: 49.1268275, lng: 19.1668669 },
    { lat: 49.1266954, lng: 19.1668599 },
    { lat: 49.1265288, lng: 19.1667952 },
    { lat: 49.1264443, lng: 19.1667281 },
    { lat: 49.1263434, lng: 19.166602 },
    { lat: 49.1262574, lng: 19.166548 },
    { lat: 49.1260904, lng: 19.1664981 },
    { lat: 49.125854, lng: 19.1663636 },
    { lat: 49.1256211, lng: 19.1663968 },
    { lat: 49.1245738, lng: 19.1593555 },
    { lat: 49.1244067, lng: 19.1584938 },
    { lat: 49.1243148, lng: 19.1577671 },
    { lat: 49.124173, lng: 19.1570296 },
    { lat: 49.1237815, lng: 19.1566776 },
    { lat: 49.1232745, lng: 19.1561034 },
    { lat: 49.1227473, lng: 19.1557831 },
    { lat: 49.1221566, lng: 19.1550676 },
    { lat: 49.1214962, lng: 19.154148 },
    { lat: 49.1214101, lng: 19.1535841 },
    { lat: 49.1215508, lng: 19.1525839 },
    { lat: 49.1214841, lng: 19.1515418 },
    { lat: 49.1213961, lng: 19.1507798 },
    { lat: 49.1211809, lng: 19.1491189 },
    { lat: 49.121482, lng: 19.1484982 },
    { lat: 49.1218219, lng: 19.1481642 },
    { lat: 49.1221971, lng: 19.1476488 },
    { lat: 49.1222972, lng: 19.1461012 },
    { lat: 49.1225282, lng: 19.1453858 },
    { lat: 49.1229904, lng: 19.1446255 },
    { lat: 49.1234838, lng: 19.1435795 },
    { lat: 49.123858, lng: 19.142014 },
    { lat: 49.123347, lng: 19.141609 },
    { lat: 49.123125, lng: 19.141527 },
    { lat: 49.122681, lng: 19.141277 },
    { lat: 49.122204, lng: 19.14105 },
    { lat: 49.122025, lng: 19.140696 },
    { lat: 49.121671, lng: 19.140257 },
    { lat: 49.121255, lng: 19.14002 },
    { lat: 49.121129, lng: 19.139848 },
    { lat: 49.120816, lng: 19.13949 },
    { lat: 49.120744, lng: 19.138931 },
    { lat: 49.120577, lng: 19.138078 },
    { lat: 49.120479, lng: 19.137407 },
    { lat: 49.119915, lng: 19.137106 },
    { lat: 49.119362, lng: 19.136706 },
    { lat: 49.119265, lng: 19.136606 },
    { lat: 49.119173, lng: 19.136511 },
    { lat: 49.118969, lng: 19.136223 },
    { lat: 49.118735, lng: 19.135348 },
    { lat: 49.11858, lng: 19.135049 },
    { lat: 49.117771, lng: 19.13415 },
    { lat: 49.117562, lng: 19.13378 },
    { lat: 49.117248, lng: 19.13353 },
    { lat: 49.117124, lng: 19.13332 },
    { lat: 49.116674, lng: 19.132821 },
    { lat: 49.116261, lng: 19.132539 },
    { lat: 49.116009, lng: 19.132142 },
    { lat: 49.115572, lng: 19.131748 },
    { lat: 49.11547, lng: 19.131546 },
    { lat: 49.115211, lng: 19.131308 },
    { lat: 49.11505, lng: 19.131228 },
    { lat: 49.114985, lng: 19.131145 },
    { lat: 49.114923, lng: 19.131109 },
    { lat: 49.114913, lng: 19.131052 },
    { lat: 49.114805, lng: 19.130914 },
    { lat: 49.114693, lng: 19.130794 },
    { lat: 49.11458, lng: 19.130533 },
    { lat: 49.114499, lng: 19.130315 },
    { lat: 49.114473, lng: 19.130085 },
    { lat: 49.114237, lng: 19.129792 },
    { lat: 49.113984, lng: 19.129418 },
    { lat: 49.113739, lng: 19.128985 },
    { lat: 49.113552, lng: 19.128584 },
    { lat: 49.113594, lng: 19.128341 },
    { lat: 49.113854, lng: 19.127545 },
    { lat: 49.11398, lng: 19.126858 },
    { lat: 49.113979, lng: 19.12644 },
    { lat: 49.114016, lng: 19.126144 },
    { lat: 49.114015, lng: 19.125928 },
    { lat: 49.113935, lng: 19.12575 },
    { lat: 49.113717, lng: 19.12539 },
    { lat: 49.113671, lng: 19.125396 },
    { lat: 49.113365, lng: 19.124637 },
    { lat: 49.113343, lng: 19.124582 },
    { lat: 49.1132036, lng: 19.1241878 },
    { lat: 49.113092, lng: 19.123691 },
    { lat: 49.112999, lng: 19.123216 },
    { lat: 49.112842, lng: 19.122875 },
    { lat: 49.112806, lng: 19.122828 },
    { lat: 49.112757, lng: 19.122759 },
    { lat: 49.112629, lng: 19.122377 },
    { lat: 49.11243, lng: 19.122024 },
    { lat: 49.11212, lng: 19.121728 },
    { lat: 49.112089, lng: 19.121697 },
    { lat: 49.1119255, lng: 19.1215644 },
    { lat: 49.11175, lng: 19.121473 },
    { lat: 49.111667, lng: 19.121445 },
    { lat: 49.111627, lng: 19.121431 },
    { lat: 49.111519, lng: 19.121395 },
    { lat: 49.111459, lng: 19.121376 },
    { lat: 49.1113929, lng: 19.1213477 },
    { lat: 49.111237, lng: 19.121281 },
    { lat: 49.111206, lng: 19.121272 },
    { lat: 49.110949, lng: 19.121199 },
    { lat: 49.110821, lng: 19.121178 },
    { lat: 49.110662, lng: 19.121153 },
    { lat: 49.110381, lng: 19.120978 },
    { lat: 49.110324, lng: 19.120942 },
    { lat: 49.10939, lng: 19.120177 },
    { lat: 49.109225, lng: 19.120081 },
    { lat: 49.108389, lng: 19.119804 },
    { lat: 49.107923, lng: 19.119672 },
    { lat: 49.107784, lng: 19.119541 },
    { lat: 49.107595, lng: 19.119647 },
    { lat: 49.10697, lng: 19.120213 },
    { lat: 49.10648, lng: 19.120592 },
    { lat: 49.105565, lng: 19.120819 },
    { lat: 49.105425, lng: 19.12091 },
    { lat: 49.105354, lng: 19.120586 },
    { lat: 49.104534, lng: 19.12086 },
    { lat: 49.104455, lng: 19.120918 },
    { lat: 49.104188, lng: 19.12107 },
    { lat: 49.103989, lng: 19.121101 },
    { lat: 49.103299, lng: 19.12175 },
    { lat: 49.10311, lng: 19.122355 },
    { lat: 49.102839, lng: 19.122298 },
    { lat: 49.102568, lng: 19.122138 },
    { lat: 49.102156, lng: 19.122241 },
    { lat: 49.101727, lng: 19.122063 },
    { lat: 49.1013, lng: 19.121843 },
    { lat: 49.100878, lng: 19.121819 },
    { lat: 49.100528, lng: 19.121665 },
    { lat: 49.100273, lng: 19.121432 },
    { lat: 49.099905, lng: 19.121174 },
    { lat: 49.099216, lng: 19.12077 },
    { lat: 49.098874, lng: 19.120648 },
    { lat: 49.098476, lng: 19.120362 },
    { lat: 49.098116, lng: 19.120203 },
    { lat: 49.097943, lng: 19.120002 },
    { lat: 49.097137, lng: 19.119521 },
    { lat: 49.096551, lng: 19.119034 },
    { lat: 49.096307, lng: 19.118907 },
    { lat: 49.096028, lng: 19.118644 },
    { lat: 49.09574, lng: 19.118517 },
    { lat: 49.095453, lng: 19.118182 },
    { lat: 49.094994, lng: 19.117951 },
    { lat: 49.094607, lng: 19.117694 },
    { lat: 49.094284, lng: 19.117534 },
    { lat: 49.094017, lng: 19.117336 },
    { lat: 49.093837, lng: 19.11714 },
    { lat: 49.093587, lng: 19.116992 },
    { lat: 49.093192, lng: 19.116588 },
    { lat: 49.093006, lng: 19.116538 },
    { lat: 49.092866, lng: 19.116413 },
    { lat: 49.092475, lng: 19.116282 },
    { lat: 49.0920453, lng: 19.1161538 },
    { lat: 49.091479, lng: 19.11615 },
    { lat: 49.09124, lng: 19.116196 },
    { lat: 49.090997, lng: 19.116348 },
    { lat: 49.090558, lng: 19.116728 },
    { lat: 49.090326, lng: 19.116886 },
    { lat: 49.090112, lng: 19.116989 },
    { lat: 49.089958, lng: 19.117047 },
    { lat: 49.089593, lng: 19.117114 },
    { lat: 49.089101, lng: 19.117178 },
    { lat: 49.089046, lng: 19.117196 },
    { lat: 49.08877, lng: 19.117281 },
    { lat: 49.088478, lng: 19.117447 },
    { lat: 49.087892, lng: 19.11799 },
    { lat: 49.087387, lng: 19.118399 },
    { lat: 49.087037, lng: 19.118425 },
    { lat: 49.086555, lng: 19.118715 },
    { lat: 49.086424, lng: 19.118765 },
    { lat: 49.086072, lng: 19.1189 },
    { lat: 49.085747, lng: 19.119006 },
    { lat: 49.085406, lng: 19.119165 },
    { lat: 49.085073, lng: 19.119374 },
    { lat: 49.084636, lng: 19.119647 },
    { lat: 49.084174, lng: 19.119889 },
    { lat: 49.083989, lng: 19.12005 },
    { lat: 49.083598, lng: 19.120282 },
    { lat: 49.083236, lng: 19.120427 },
    { lat: 49.08286, lng: 19.12077 },
    { lat: 49.082833, lng: 19.120937 },
    { lat: 49.082726, lng: 19.121602 },
    { lat: 49.082564, lng: 19.122032 },
    { lat: 49.082545, lng: 19.122344 },
    { lat: 49.082204, lng: 19.122423 },
    { lat: 49.081948, lng: 19.122529 },
    { lat: 49.081768, lng: 19.122661 },
    { lat: 49.08132, lng: 19.122671 },
    { lat: 49.081196, lng: 19.122614 },
    { lat: 49.081119, lng: 19.122542 },
    { lat: 49.081063, lng: 19.122489 },
    { lat: 49.080683, lng: 19.122258 },
    { lat: 49.080296, lng: 19.121994 },
    { lat: 49.079403, lng: 19.121507 },
    { lat: 49.079003, lng: 19.121534 },
    { lat: 49.078781, lng: 19.121723 },
    { lat: 49.078592, lng: 19.122056 },
    { lat: 49.078288, lng: 19.121864 },
    { lat: 49.078142, lng: 19.121745 },
    { lat: 49.078009, lng: 19.121555 },
    { lat: 49.077823, lng: 19.121418 },
    { lat: 49.077136, lng: 19.121187 },
    { lat: 49.07697, lng: 19.12095 },
    { lat: 49.076774, lng: 19.120767 },
    { lat: 49.076646, lng: 19.120758 },
    { lat: 49.076017, lng: 19.120992 },
    { lat: 49.075184, lng: 19.121157 },
    { lat: 49.074397, lng: 19.121663 },
    { lat: 49.073828, lng: 19.121033 },
    { lat: 49.0736, lng: 19.120577 },
    { lat: 49.073259, lng: 19.119999 },
    { lat: 49.072934, lng: 19.119652 },
    { lat: 49.072699, lng: 19.119268 },
    { lat: 49.072576, lng: 19.119444 },
    { lat: 49.072224, lng: 19.119608 },
    { lat: 49.072023, lng: 19.119635 },
    { lat: 49.071822, lng: 19.119593 },
    { lat: 49.071691, lng: 19.119566 },
    { lat: 49.071089, lng: 19.119506 },
    { lat: 49.070765, lng: 19.119371 },
    { lat: 49.070584, lng: 19.119782 },
    { lat: 49.070471, lng: 19.119957 },
    { lat: 49.070086, lng: 19.120292 },
    { lat: 49.069551, lng: 19.120646 },
    { lat: 49.069144, lng: 19.120816 },
    { lat: 49.0689928, lng: 19.1208393 },
    { lat: 49.068748, lng: 19.120877 },
    { lat: 49.068726, lng: 19.120879 },
    { lat: 49.068681, lng: 19.120883 },
    { lat: 49.068636, lng: 19.120887 },
    { lat: 49.068573, lng: 19.120894 },
    { lat: 49.068156, lng: 19.120935 },
    { lat: 49.067662, lng: 19.120925 },
    { lat: 49.067597, lng: 19.120923 },
    { lat: 49.067309, lng: 19.120956 },
    { lat: 49.067308, lng: 19.120777 },
    { lat: 49.066991, lng: 19.120568 },
    { lat: 49.066847, lng: 19.12057 },
    { lat: 49.066715, lng: 19.120126 },
    { lat: 49.066572, lng: 19.119728 },
    { lat: 49.066195, lng: 19.119624 },
    { lat: 49.065803, lng: 19.119415 },
    { lat: 49.065588, lng: 19.119564 },
    { lat: 49.065486, lng: 19.119845 },
    { lat: 49.065188, lng: 19.120416 },
    { lat: 49.064785, lng: 19.120338 },
    { lat: 49.064713, lng: 19.120793 },
    { lat: 49.064585, lng: 19.12103 },
    { lat: 49.064073, lng: 19.121186 },
    { lat: 49.06403, lng: 19.121202 },
    { lat: 49.064014, lng: 19.121208 },
    { lat: 49.063985, lng: 19.121219 },
    { lat: 49.063938, lng: 19.121237 },
    { lat: 49.063929, lng: 19.12124 },
    { lat: 49.06364, lng: 19.121349 },
    { lat: 49.06317, lng: 19.121528 },
    { lat: 49.063056, lng: 19.121571 },
    { lat: 49.062415, lng: 19.121639 },
    { lat: 49.061865, lng: 19.121999 },
    { lat: 49.061484, lng: 19.121761 },
    { lat: 49.061074, lng: 19.121741 },
    { lat: 49.060578, lng: 19.121813 },
    { lat: 49.060433, lng: 19.121744 },
    { lat: 49.06031, lng: 19.121807 },
    { lat: 49.06016, lng: 19.121722 },
    { lat: 49.060003, lng: 19.121859 },
    { lat: 49.059856, lng: 19.122079 },
    { lat: 49.059532, lng: 19.122162 },
    { lat: 49.059126, lng: 19.122459 },
    { lat: 49.058637, lng: 19.122771 },
    { lat: 49.057809, lng: 19.123456 },
    { lat: 49.057189, lng: 19.122916 },
    { lat: 49.05617, lng: 19.122476 },
    { lat: 49.056147, lng: 19.122213 },
    { lat: 49.055682, lng: 19.121545 },
    { lat: 49.055206, lng: 19.120518 },
    { lat: 49.054745, lng: 19.119942 },
    { lat: 49.05465, lng: 19.119805 },
    { lat: 49.054164, lng: 19.119094 },
    { lat: 49.053888, lng: 19.117147 },
    { lat: 49.053607, lng: 19.115355 },
    { lat: 49.053414, lng: 19.114552 },
    { lat: 49.052935, lng: 19.114488 },
    { lat: 49.052, lng: 19.113878 },
    { lat: 49.051761, lng: 19.113773 },
    { lat: 49.051227, lng: 19.113656 },
    { lat: 49.049906, lng: 19.113581 },
    { lat: 49.049162, lng: 19.113345 },
    { lat: 49.04902, lng: 19.113124 },
    { lat: 49.048682, lng: 19.112945 },
    { lat: 49.048115, lng: 19.112755 },
    { lat: 49.046959, lng: 19.112017 },
    { lat: 49.046613, lng: 19.112234 },
    { lat: 49.046602, lng: 19.1122407 },
    { lat: 49.0464408, lng: 19.1123307 },
    { lat: 49.0462423, lng: 19.1124853 },
    { lat: 49.0458801, lng: 19.1123995 },
    { lat: 49.04575, lng: 19.1122796 },
    { lat: 49.0457231, lng: 19.112267 },
    { lat: 49.045446, lng: 19.112137 },
    { lat: 49.0453755, lng: 19.1120535 },
    { lat: 49.04527, lng: 19.112005 },
    { lat: 49.0451329, lng: 19.1119677 },
    { lat: 49.044887, lng: 19.111991 },
    { lat: 49.0447215, lng: 19.1118014 },
    { lat: 49.044673, lng: 19.111765 },
    { lat: 49.0444273, lng: 19.1115099 },
    { lat: 49.0430882, lng: 19.1102833 },
    { lat: 49.042967, lng: 19.109993 },
    { lat: 49.0428491, lng: 19.1101652 },
    { lat: 49.042684, lng: 19.110185 },
    { lat: 49.0426416, lng: 19.1103154 },
    { lat: 49.0424781, lng: 19.1104281 },
    { lat: 49.0422777, lng: 19.1104925 },
    { lat: 49.041748, lng: 19.110852 },
    { lat: 49.041434, lng: 19.111076 },
    { lat: 49.0409379, lng: 19.1112596 },
    { lat: 49.0409379, lng: 19.1113722 },
    { lat: 49.0409063, lng: 19.1114178 },
    { lat: 49.0408694, lng: 19.1114366 },
    { lat: 49.0407938, lng: 19.1114634 },
    { lat: 49.040741, lng: 19.1114607 },
    { lat: 49.040125, lng: 19.111503 },
    { lat: 49.039813, lng: 19.111587 },
    { lat: 49.03886, lng: 19.112246 },
    { lat: 49.038322, lng: 19.112028 },
    { lat: 49.038309, lng: 19.112038 },
    { lat: 49.0379493, lng: 19.1123009 },
    { lat: 49.037335, lng: 19.11275 },
    { lat: 49.035558, lng: 19.112918 },
    { lat: 49.035078, lng: 19.112135 },
    { lat: 49.0347166, lng: 19.1116208 },
    { lat: 49.034556, lng: 19.111438 },
    { lat: 49.034097, lng: 19.110718 },
    { lat: 49.033205, lng: 19.109282 },
    { lat: 49.032748, lng: 19.109361 },
    { lat: 49.032191, lng: 19.108951 },
    { lat: 49.0322233, lng: 19.1089421 },
    { lat: 49.0319015, lng: 19.1087195 },
    { lat: 49.031803, lng: 19.1086578 },
    { lat: 49.0317046, lng: 19.1085345 },
    { lat: 49.031457, lng: 19.108371 },
    { lat: 49.031107, lng: 19.107969 },
    { lat: 49.0310873, lng: 19.108808 },
    { lat: 49.0310152, lng: 19.1089019 },
    { lat: 49.0309609, lng: 19.1090018 },
    { lat: 49.030844, lng: 19.109377 },
    { lat: 49.0307918, lng: 19.1093364 },
    { lat: 49.0306314, lng: 19.1099243 },
    { lat: 49.0305052, lng: 19.1102752 },
    { lat: 49.0304366, lng: 19.1105086 },
    { lat: 49.0303698, lng: 19.1107285 },
    { lat: 49.030182, lng: 19.111102 },
    { lat: 49.030095, lng: 19.111272 },
    { lat: 49.029834, lng: 19.111782 },
    { lat: 49.029228, lng: 19.112966 },
    { lat: 49.028546, lng: 19.112377 },
    { lat: 49.027999, lng: 19.111993 },
    { lat: 49.0268286, lng: 19.1115305 },
    { lat: 49.0265461, lng: 19.1114487 },
    { lat: 49.026463, lng: 19.111386 },
    { lat: 49.026091, lng: 19.111402 },
    { lat: 49.025546, lng: 19.111283 },
    { lat: 49.02499, lng: 19.1118454 },
    { lat: 49.024859, lng: 19.111978 },
    { lat: 49.024373, lng: 19.112282 },
    { lat: 49.024132, lng: 19.112324 },
    { lat: 49.023339, lng: 19.112474 },
    { lat: 49.022911, lng: 19.112568 },
    { lat: 49.022428, lng: 19.112707 },
    { lat: 49.021808, lng: 19.112985 },
    { lat: 49.021249, lng: 19.113176 },
    { lat: 49.020646, lng: 19.113107 },
    { lat: 49.0202311, lng: 19.1130275 },
    { lat: 49.0200215, lng: 19.1129934 },
    { lat: 49.019716, lng: 19.112865 },
    { lat: 49.019186, lng: 19.112783 },
    { lat: 49.0187108, lng: 19.1126475 },
    { lat: 49.018576, lng: 19.112609 },
    { lat: 49.017951, lng: 19.11232 },
    { lat: 49.0176254, lng: 19.1122656 },
    { lat: 49.0173412, lng: 19.1121485 },
    { lat: 49.016713, lng: 19.1125 },
    { lat: 49.016277, lng: 19.112676 },
    { lat: 49.015865, lng: 19.112634 },
    { lat: 49.015429, lng: 19.112591 },
    { lat: 49.015166, lng: 19.112458 },
    { lat: 49.014913, lng: 19.112317 },
    { lat: 49.014671, lng: 19.112078 },
    { lat: 49.014407, lng: 19.111876 },
    { lat: 49.013918, lng: 19.111568 },
    { lat: 49.013202, lng: 19.111205 },
    { lat: 49.012738, lng: 19.111103 },
    { lat: 49.012554, lng: 19.111041 },
    { lat: 49.011901, lng: 19.111113 },
    { lat: 49.011066, lng: 19.111383 },
    { lat: 49.009821, lng: 19.11153 },
    { lat: 49.00943, lng: 19.111613 },
    { lat: 49.00922, lng: 19.111312 },
    { lat: 49.0091126, lng: 19.1112269 },
    { lat: 49.008608, lng: 19.110827 },
    { lat: 49.008036, lng: 19.110727 },
    { lat: 49.007447, lng: 19.111169 },
    { lat: 49.006794, lng: 19.111442 },
    { lat: 49.0061495, lng: 19.1114688 },
    { lat: 49.004845, lng: 19.111381 },
    { lat: 49.004273, lng: 19.111158 },
    { lat: 49.003752, lng: 19.110785 },
    { lat: 49.003267, lng: 19.110369 },
    { lat: 49.002825, lng: 19.109676 },
    { lat: 49.002656, lng: 19.108879 },
    { lat: 49.002497, lng: 19.108193 },
    { lat: 49.002182, lng: 19.10764 },
    { lat: 49.001813, lng: 19.106922 },
    { lat: 49.001391, lng: 19.106403 },
    { lat: 49.001092, lng: 19.105715 },
    { lat: 49.001026, lng: 19.105576 },
    { lat: 49.000863, lng: 19.105433 },
    { lat: 49.000817, lng: 19.105186 },
    { lat: 49.00046, lng: 19.104344 },
    { lat: 49.00011, lng: 19.103726 },
    { lat: 48.999916, lng: 19.103473 },
    { lat: 48.999708, lng: 19.103199 },
    { lat: 48.99955, lng: 19.103069 },
    { lat: 48.999449, lng: 19.102896 },
    { lat: 48.999309, lng: 19.102826 },
    { lat: 48.99887, lng: 19.102537 },
    { lat: 48.998373, lng: 19.10218 },
    { lat: 48.997768, lng: 19.101829 },
    { lat: 48.997562, lng: 19.101731 },
    { lat: 48.9974658, lng: 19.1016895 },
    { lat: 48.997259, lng: 19.1015 },
    { lat: 48.99673, lng: 19.101314 },
    { lat: 48.996198, lng: 19.101048 },
    { lat: 48.995669, lng: 19.1009626 },
    { lat: 48.995631, lng: 19.100923 },
    { lat: 48.994928, lng: 19.100898 },
    { lat: 48.9944882, lng: 19.1009787 },
    { lat: 48.9942453, lng: 19.1010216 },
    { lat: 48.993728, lng: 19.101312 },
    { lat: 48.9935853, lng: 19.1014132 },
    { lat: 48.993408, lng: 19.101418 },
    { lat: 48.993082, lng: 19.101834 },
    { lat: 48.992724, lng: 19.101769 },
    { lat: 48.992319, lng: 19.101983 },
    { lat: 48.9921756, lng: 19.1022071 },
    { lat: 48.991833, lng: 19.102373 },
    { lat: 48.9916687, lng: 19.1026175 },
    { lat: 48.99147, lng: 19.102675 },
    { lat: 48.991067, lng: 19.103041 },
    { lat: 48.990735, lng: 19.103342 },
    { lat: 48.990351, lng: 19.103669 },
    { lat: 48.990146, lng: 19.103994 },
    { lat: 48.989749, lng: 19.104351 },
    { lat: 48.989231, lng: 19.105013 },
    { lat: 48.988639, lng: 19.105775 },
    { lat: 48.988308, lng: 19.106251 },
    { lat: 48.988027, lng: 19.106418 },
    { lat: 48.987748, lng: 19.106716 },
    { lat: 48.98737, lng: 19.106995 },
    { lat: 48.986949, lng: 19.107197 },
    { lat: 48.986799, lng: 19.107051 },
    { lat: 48.986673, lng: 19.106924 },
    { lat: 48.986501, lng: 19.106702 },
    { lat: 48.986429, lng: 19.106627 },
    { lat: 48.9861263, lng: 19.1057906 },
    { lat: 48.985995, lng: 19.105367 },
    { lat: 48.9859133, lng: 19.1053587 },
    { lat: 48.985757, lng: 19.104824 },
    { lat: 48.985595, lng: 19.10447 },
    { lat: 48.98533, lng: 19.104287 },
    { lat: 48.985013, lng: 19.103623 },
    { lat: 48.984769, lng: 19.102886 },
    { lat: 48.984575, lng: 19.10212 },
    { lat: 48.983871, lng: 19.102202 },
    { lat: 48.983307, lng: 19.102194 },
    { lat: 48.982311, lng: 19.102183 },
    { lat: 48.9821938, lng: 19.1021809 },
    { lat: 48.981902, lng: 19.102205 },
    { lat: 48.980868, lng: 19.102184 },
    { lat: 48.980116, lng: 19.102016 },
    { lat: 48.979322, lng: 19.101904 },
    { lat: 48.978623, lng: 19.101607 },
    { lat: 48.9775904, lng: 19.1017959 },
    { lat: 48.976901, lng: 19.101555 },
    { lat: 48.97637, lng: 19.101398 },
    { lat: 48.975676, lng: 19.100897 },
    { lat: 48.974863, lng: 19.10043 },
    { lat: 48.974147, lng: 19.101766 },
    { lat: 48.973615, lng: 19.102302 },
    { lat: 48.972414, lng: 19.102587 },
    { lat: 48.971691, lng: 19.102824 },
    { lat: 48.971289, lng: 19.103981 },
    { lat: 48.970777, lng: 19.105683 },
    { lat: 48.970043, lng: 19.106503 },
    { lat: 48.969613, lng: 19.107131 },
    { lat: 48.969183, lng: 19.107902 },
    { lat: 48.967954, lng: 19.111474 },
    { lat: 48.967079, lng: 19.112729 },
    { lat: 48.966583, lng: 19.113188 },
    { lat: 48.965695, lng: 19.114051 },
    { lat: 48.964155, lng: 19.115125 },
    { lat: 48.963511, lng: 19.115448 },
    { lat: 48.962812, lng: 19.11584 },
    { lat: 48.961696, lng: 19.11651 },
    { lat: 48.961316, lng: 19.116637 },
    { lat: 48.961039, lng: 19.11689 },
    { lat: 48.96076, lng: 19.117256 },
    { lat: 48.960775, lng: 19.1172543 },
    { lat: 48.960015, lng: 19.118095 },
    { lat: 48.959519, lng: 19.118444 },
    { lat: 48.958234, lng: 19.118131 },
    { lat: 48.957288, lng: 19.119217 },
    { lat: 48.956627, lng: 19.119572 },
    { lat: 48.956466, lng: 19.1197 },
    { lat: 48.9561397, lng: 19.1203102 },
    { lat: 48.956092, lng: 19.120314 },
    { lat: 48.956377, lng: 19.121634 },
    { lat: 48.956405, lng: 19.123527 },
    { lat: 48.956348, lng: 19.12388 },
    { lat: 48.956043, lng: 19.124358 },
    { lat: 48.955896, lng: 19.125311 },
    { lat: 48.955965, lng: 19.126903 },
    { lat: 48.955826, lng: 19.127535 },
    { lat: 48.955812, lng: 19.128862 },
    { lat: 48.955691, lng: 19.128992 },
    { lat: 48.955705, lng: 19.130713 },
    { lat: 48.955607, lng: 19.131631 },
    { lat: 48.955096, lng: 19.131829 },
    { lat: 48.953864, lng: 19.131697 },
    { lat: 48.952898, lng: 19.131973 },
    { lat: 48.952603, lng: 19.132694 },
    { lat: 48.952487, lng: 19.133061 },
    { lat: 48.952467, lng: 19.133114 },
    { lat: 48.952288, lng: 19.133815 },
    { lat: 48.952093, lng: 19.134696 },
    { lat: 48.951978, lng: 19.135311 },
    { lat: 48.951637, lng: 19.137595 },
    { lat: 48.951624, lng: 19.137679 },
    { lat: 48.951587, lng: 19.13792 },
    { lat: 48.951583, lng: 19.137948 },
    { lat: 48.951573, lng: 19.137991 },
    { lat: 48.951564, lng: 19.138023 },
    { lat: 48.951445, lng: 19.138126 },
    { lat: 48.951383, lng: 19.138182 },
    { lat: 48.951389, lng: 19.138239 },
    { lat: 48.951252, lng: 19.138205 },
    { lat: 48.950979, lng: 19.138135 },
    { lat: 48.950912, lng: 19.13817 },
    { lat: 48.950722, lng: 19.138165 },
    { lat: 48.950534, lng: 19.138166 },
    { lat: 48.950368, lng: 19.138081 },
    { lat: 48.950189, lng: 19.138095 },
    { lat: 48.950119, lng: 19.138108 },
    { lat: 48.950056, lng: 19.138175 },
    { lat: 48.94983, lng: 19.138292 },
    { lat: 48.949616, lng: 19.138187 },
    { lat: 48.949444, lng: 19.138108 },
    { lat: 48.949275, lng: 19.138011 },
    { lat: 48.949139, lng: 19.138157 },
    { lat: 48.949053, lng: 19.138288 },
    { lat: 48.948957, lng: 19.13835 },
    { lat: 48.948797, lng: 19.138447 },
    { lat: 48.948572, lng: 19.138508 },
    { lat: 48.948163, lng: 19.138577 },
    { lat: 48.947859, lng: 19.138713 },
    { lat: 48.947668, lng: 19.138763 },
    { lat: 48.947285, lng: 19.138907 },
    { lat: 48.946976, lng: 19.138961 },
    { lat: 48.946822, lng: 19.139063 },
    { lat: 48.946677, lng: 19.139 },
    { lat: 48.946495, lng: 19.139028 },
    { lat: 48.946336, lng: 19.139075 },
    { lat: 48.946041, lng: 19.138978 },
    { lat: 48.945823, lng: 19.13894 },
    { lat: 48.945683, lng: 19.138986 },
    { lat: 48.94544, lng: 19.139131 },
    { lat: 48.945181, lng: 19.139188 },
    { lat: 48.944909, lng: 19.139196 },
    { lat: 48.944748, lng: 19.139161 },
    { lat: 48.944639, lng: 19.139186 },
    { lat: 48.944476, lng: 19.139147 },
    { lat: 48.944368, lng: 19.139121 },
    { lat: 48.944211, lng: 19.139094 },
    { lat: 48.944075, lng: 19.139054 },
    { lat: 48.943927, lng: 19.138982 },
    { lat: 48.943821, lng: 19.139001 },
    { lat: 48.943685, lng: 19.138928 },
    { lat: 48.943586, lng: 19.138903 },
    { lat: 48.943465, lng: 19.13891 },
    { lat: 48.943242, lng: 19.13867 },
    { lat: 48.943138, lng: 19.138589 },
    { lat: 48.943003, lng: 19.138461 },
    { lat: 48.942918, lng: 19.138411 },
    { lat: 48.942637, lng: 19.138054 },
    { lat: 48.942495, lng: 19.138036 },
    { lat: 48.942379, lng: 19.137978 },
    { lat: 48.942273, lng: 19.137792 },
    { lat: 48.942169, lng: 19.13778 },
    { lat: 48.942006, lng: 19.137752 },
    { lat: 48.941818, lng: 19.137674 },
    { lat: 48.941663, lng: 19.137627 },
    { lat: 48.941533, lng: 19.137563 },
    { lat: 48.94144, lng: 19.137508 },
    { lat: 48.941277, lng: 19.13747 },
    { lat: 48.941209, lng: 19.137356 },
    { lat: 48.94101, lng: 19.137133 },
    { lat: 48.940811, lng: 19.136866 },
    { lat: 48.9407, lng: 19.136663 },
    { lat: 48.940704, lng: 19.136424 },
    { lat: 48.940654, lng: 19.13615 },
    { lat: 48.940594, lng: 19.136001 },
    { lat: 48.940518, lng: 19.135767 },
    { lat: 48.940486, lng: 19.135712 },
    { lat: 48.940499, lng: 19.135632 },
    { lat: 48.940417, lng: 19.135409 },
    { lat: 48.940357, lng: 19.135242 },
    { lat: 48.940267, lng: 19.135163 },
    { lat: 48.940251, lng: 19.135026 },
    { lat: 48.940119, lng: 19.134981 },
    { lat: 48.940052, lng: 19.134889 },
    { lat: 48.939951, lng: 19.134783 },
    { lat: 48.939833, lng: 19.134668 },
    { lat: 48.939632, lng: 19.134568 },
    { lat: 48.939476, lng: 19.134386 },
    { lat: 48.939381, lng: 19.134177 },
    { lat: 48.939275, lng: 19.134011 },
    { lat: 48.939229, lng: 19.13391 },
    { lat: 48.939123, lng: 19.133759 },
    { lat: 48.939061, lng: 19.133577 },
    { lat: 48.938976, lng: 19.133425 },
    { lat: 48.938855, lng: 19.13328 },
    { lat: 48.938469, lng: 19.132639 },
    { lat: 48.938355, lng: 19.132435 },
    { lat: 48.938238, lng: 19.132328 },
    { lat: 48.938143, lng: 19.132221 },
    { lat: 48.938112, lng: 19.132083 },
    { lat: 48.938043, lng: 19.132048 },
    { lat: 48.938001, lng: 19.131942 },
    { lat: 48.937924, lng: 19.1319 },
    { lat: 48.937903, lng: 19.131816 },
    { lat: 48.937745, lng: 19.13166 },
    { lat: 48.937647, lng: 19.131652 },
    { lat: 48.937567, lng: 19.131606 },
    { lat: 48.937492, lng: 19.131492 },
    { lat: 48.93744, lng: 19.131424 },
    { lat: 48.937287, lng: 19.131202 },
    { lat: 48.937212, lng: 19.131179 },
    { lat: 48.937138, lng: 19.131206 },
    { lat: 48.937066, lng: 19.131015 },
    { lat: 48.936952, lng: 19.130943 },
    { lat: 48.936849, lng: 19.130792 },
    { lat: 48.936736, lng: 19.130686 },
    { lat: 48.9367, lng: 19.13057 },
    { lat: 48.93666, lng: 19.130453 },
    { lat: 48.936553, lng: 19.130299 },
    { lat: 48.936179, lng: 19.130142 },
    { lat: 48.936016, lng: 19.130014 },
    { lat: 48.935807, lng: 19.129862 },
    { lat: 48.935719, lng: 19.129769 },
    { lat: 48.935656, lng: 19.129706 },
    { lat: 48.935587, lng: 19.129585 },
    { lat: 48.935442, lng: 19.129504 },
    { lat: 48.935324, lng: 19.129556 },
    { lat: 48.935144, lng: 19.129387 },
    { lat: 48.935023, lng: 19.12936 },
    { lat: 48.934819, lng: 19.129209 },
    { lat: 48.934694, lng: 19.129113 },
    { lat: 48.93447, lng: 19.128865 },
    { lat: 48.93434, lng: 19.128762 },
    { lat: 48.933647, lng: 19.128405 },
    { lat: 48.933437, lng: 19.128348 },
    { lat: 48.933267, lng: 19.12824 },
    { lat: 48.933148, lng: 19.128151 },
    { lat: 48.933013, lng: 19.128049 },
    { lat: 48.932981, lng: 19.128004 },
    { lat: 48.932835, lng: 19.127926 },
    { lat: 48.932656, lng: 19.127685 },
    { lat: 48.932445, lng: 19.12756 },
    { lat: 48.932358, lng: 19.127605 },
    { lat: 48.932271, lng: 19.127491 },
    { lat: 48.932021, lng: 19.126917 },
    { lat: 48.931996, lng: 19.126685 },
    { lat: 48.931955, lng: 19.126403 },
    { lat: 48.93172, lng: 19.126182 },
    { lat: 48.931585, lng: 19.126071 },
    { lat: 48.931595, lng: 19.125833 },
    { lat: 48.931672, lng: 19.125325 },
    { lat: 48.931705, lng: 19.125082 },
    { lat: 48.93163, lng: 19.124833 },
    { lat: 48.931392, lng: 19.124577 },
    { lat: 48.931115, lng: 19.12428 },
    { lat: 48.930561, lng: 19.122611 },
    { lat: 48.9302043, lng: 19.1238534 },
    { lat: 48.9302132, lng: 19.126529 },
    { lat: 48.9302218, lng: 19.1275964 },
    { lat: 48.9302257, lng: 19.1289386 },
    { lat: 48.93049, lng: 19.1313795 },
    { lat: 48.930548, lng: 19.1318949 },
    { lat: 48.9317292, lng: 19.1361817 },
    { lat: 48.9329606, lng: 19.1412807 },
    { lat: 48.9329914, lng: 19.1414081 },
    { lat: 48.9339594, lng: 19.1428959 },
    { lat: 48.9350485, lng: 19.1448793 },
    { lat: 48.9357161, lng: 19.1448612 },
    { lat: 48.9361974, lng: 19.1455046 },
    { lat: 48.9366774, lng: 19.1457078 },
    { lat: 48.9380199, lng: 19.1468573 },
    { lat: 48.9398776, lng: 19.1479351 },
    { lat: 48.9435543, lng: 19.1520013 },
    { lat: 48.9445632, lng: 19.1526731 },
    { lat: 48.9456604, lng: 19.1535753 },
    { lat: 48.9482742, lng: 19.1557348 },
    { lat: 48.9490352, lng: 19.1561095 },
    { lat: 48.9505251, lng: 19.1573247 },
    { lat: 48.9504484, lng: 19.159534 },
    { lat: 48.9504052, lng: 19.1602199 },
    { lat: 48.9509602, lng: 19.1609188 },
    { lat: 48.951223, lng: 19.1623998 },
    { lat: 48.9519964, lng: 19.1634844 },
    { lat: 48.9522546, lng: 19.1657715 },
    { lat: 48.9521338, lng: 19.166969 },
    { lat: 48.9527467, lng: 19.1685541 },
    { lat: 48.9550252, lng: 19.1712865 },
    { lat: 48.9570237, lng: 19.1723274 },
    { lat: 48.9580004, lng: 19.1741176 },
    { lat: 48.9583, lng: 19.1746685 },
    { lat: 48.9597426, lng: 19.1761912 },
    { lat: 48.9603916, lng: 19.1768346 },
    { lat: 48.9606663, lng: 19.1791601 },
    { lat: 48.9626811, lng: 19.1808869 },
    { lat: 48.9632964, lng: 19.1812504 },
    { lat: 48.9637454, lng: 19.1816287 },
    { lat: 48.964545, lng: 19.1825419 },
    { lat: 48.9654223, lng: 19.1839602 },
    { lat: 48.965909, lng: 19.1843755 },
    { lat: 48.9662191, lng: 19.1849767 },
    { lat: 48.9674604, lng: 19.1859578 },
    { lat: 48.9691802, lng: 19.18778 },
    { lat: 48.9700801, lng: 19.1873945 },
    { lat: 48.9724303, lng: 19.1881824 },
    { lat: 48.972676, lng: 19.1882323 },
    { lat: 48.9732469, lng: 19.1875902 },
    { lat: 48.9739763, lng: 19.1867599 },
    { lat: 48.9746983, lng: 19.186032 },
    { lat: 48.9752796, lng: 19.1858185 },
    { lat: 48.9762865, lng: 19.1857988 },
    { lat: 48.9765124, lng: 19.185833 },
    { lat: 48.976891, lng: 19.1858282 },
    { lat: 48.9800726, lng: 19.1890673 },
    { lat: 48.9808349, lng: 19.1897702 },
    { lat: 48.9810453, lng: 19.1899921 },
    { lat: 48.9814945, lng: 19.19056 },
    { lat: 48.9817512, lng: 19.1906026 },
    { lat: 48.9822832, lng: 19.1906965 },
    { lat: 48.9827704, lng: 19.1909085 },
    { lat: 48.983552, lng: 19.1915447 },
    { lat: 48.9843477, lng: 19.19307 },
    { lat: 48.9844413, lng: 19.193263 },
    { lat: 48.9856199, lng: 19.1955034 },
    { lat: 48.9886709, lng: 19.1972413 },
    { lat: 48.9895276, lng: 19.1988677 },
    { lat: 48.9899581, lng: 19.199679 },
    { lat: 48.9899484, lng: 19.1999403 },
    { lat: 48.9899272, lng: 19.2005263 },
    { lat: 48.9896286, lng: 19.2008085 },
    { lat: 48.9899992, lng: 19.2011298 },
    { lat: 48.9900474, lng: 19.2011689 },
    { lat: 48.9901769, lng: 19.2012825 },
    { lat: 48.9902805, lng: 19.2013976 },
    { lat: 48.9903245, lng: 19.2014466 },
    { lat: 48.9910308, lng: 19.2022285 },
    { lat: 48.9924196, lng: 19.2004169 },
    { lat: 48.9938308, lng: 19.2000686 },
    { lat: 48.9952572, lng: 19.2014961 },
    { lat: 48.9960013, lng: 19.2022423 },
    { lat: 48.9963792, lng: 19.2021114 },
    { lat: 48.996587, lng: 19.2020027 },
    { lat: 48.9970602, lng: 19.201674 },
    { lat: 48.9977301, lng: 19.2011933 },
    { lat: 48.9977519, lng: 19.2011309 },
    { lat: 48.9986689, lng: 19.2017275 },
    { lat: 48.9990612, lng: 19.2008454 },
    { lat: 48.9992774, lng: 19.2007695 },
    { lat: 48.9997467, lng: 19.2012403 },
    { lat: 48.9997099, lng: 19.2022944 },
    { lat: 49.0003881, lng: 19.2026378 },
    { lat: 49.0015287, lng: 19.2035677 },
    { lat: 49.0015539, lng: 19.2035753 },
    { lat: 49.0017726, lng: 19.2037604 },
    { lat: 49.0031663, lng: 19.2051686 },
    { lat: 49.0040959, lng: 19.2056048 },
    { lat: 49.0054693, lng: 19.2058471 },
    { lat: 49.0060397, lng: 19.2060572 },
    { lat: 49.0072984, lng: 19.2073553 },
    { lat: 49.007366, lng: 19.2074239 },
    { lat: 49.00792, lng: 19.2079493 },
    { lat: 49.0090335, lng: 19.2072233 },
    { lat: 49.0095318, lng: 19.2066228 },
    { lat: 49.00979, lng: 19.2062966 },
    { lat: 49.0099499, lng: 19.2061753 },
    { lat: 49.010357, lng: 19.2060163 },
    { lat: 49.0108355, lng: 19.2057294 },
    { lat: 49.0111065, lng: 19.2057163 },
    { lat: 49.0113717, lng: 19.205628 },
    { lat: 49.0119312, lng: 19.2056161 },
    { lat: 49.012692, lng: 19.2059384 },
    { lat: 49.0138591, lng: 19.2068807 },
    { lat: 49.014418, lng: 19.2071568 },
    { lat: 49.0153816, lng: 19.2074816 },
    { lat: 49.0164485, lng: 19.2079838 },
    { lat: 49.0171544, lng: 19.208455 },
    { lat: 49.018966, lng: 19.2092262 },
    { lat: 49.019774, lng: 19.2094123 },
    { lat: 49.020412, lng: 19.2096239 },
    { lat: 49.0220959, lng: 19.2115099 },
    { lat: 49.0223969, lng: 19.2119496 },
    { lat: 49.0237769, lng: 19.2135644 },
    { lat: 49.0253596, lng: 19.2153865 },
    { lat: 49.0255987, lng: 19.2159506 },
    { lat: 49.0259421, lng: 19.216762 },
    { lat: 49.0261552, lng: 19.2171595 },
    { lat: 49.0263587, lng: 19.2175422 },
    { lat: 49.0272823, lng: 19.2194899 },
    { lat: 49.0284164, lng: 19.2211268 },
    { lat: 49.0286786, lng: 19.2213083 },
  ],
  Štiavnička: [
    { lat: 49.0655207, lng: 19.3376339 },
    { lat: 49.0655125, lng: 19.3376951 },
    { lat: 49.0654858, lng: 19.337895 },
    { lat: 49.0654763, lng: 19.3379777 },
    { lat: 49.0654375, lng: 19.3382572 },
    { lat: 49.065426, lng: 19.3383925 },
    { lat: 49.0654143, lng: 19.3385311 },
    { lat: 49.0654064, lng: 19.3386232 },
    { lat: 49.0653926, lng: 19.3387858 },
    { lat: 49.0653803, lng: 19.3392863 },
    { lat: 49.0653929, lng: 19.339387 },
    { lat: 49.0653323, lng: 19.3392495 },
    { lat: 49.0652682, lng: 19.339107 },
    { lat: 49.064981, lng: 19.3384656 },
    { lat: 49.0649239, lng: 19.3385024 },
    { lat: 49.0648748, lng: 19.3385333 },
    { lat: 49.0648336, lng: 19.3385705 },
    { lat: 49.0648437, lng: 19.3395955 },
    { lat: 49.0644011, lng: 19.3394833 },
    { lat: 49.0643017, lng: 19.3401192 },
    { lat: 49.0644919, lng: 19.3401864 },
    { lat: 49.0642951, lng: 19.3418903 },
    { lat: 49.0634161, lng: 19.3420597 },
    { lat: 49.0632452, lng: 19.342092 },
    { lat: 49.0630075, lng: 19.3421382 },
    { lat: 49.0627964, lng: 19.3421788 },
    { lat: 49.0627505, lng: 19.3421875 },
    { lat: 49.0627114, lng: 19.342195 },
    { lat: 49.0625506, lng: 19.3422251 },
    { lat: 49.0623797, lng: 19.3422571 },
    { lat: 49.0620993, lng: 19.3423097 },
    { lat: 49.0621125, lng: 19.3423503 },
    { lat: 49.0621075, lng: 19.3423893 },
    { lat: 49.0621203, lng: 19.342445 },
    { lat: 49.0621331, lng: 19.3425004 },
    { lat: 49.0622445, lng: 19.3429748 },
    { lat: 49.0626528, lng: 19.3447271 },
    { lat: 49.0627419, lng: 19.3449938 },
    { lat: 49.0631816, lng: 19.3465252 },
    { lat: 49.0636363, lng: 19.3479692 },
    { lat: 49.0636993, lng: 19.3489934 },
    { lat: 49.063794, lng: 19.349184 },
    { lat: 49.064284, lng: 19.3498262 },
    { lat: 49.0643858, lng: 19.3503614 },
    { lat: 49.0646677, lng: 19.3515842 },
    { lat: 49.064785, lng: 19.3523739 },
    { lat: 49.0648003, lng: 19.352654 },
    { lat: 49.0648009, lng: 19.3535977 },
    { lat: 49.0648346, lng: 19.3535969 },
    { lat: 49.0661359, lng: 19.3538054 },
    { lat: 49.0675967, lng: 19.354119 },
    { lat: 49.0680057, lng: 19.3541887 },
    { lat: 49.0699394, lng: 19.3546175 },
    { lat: 49.07159, lng: 19.3546333 },
    { lat: 49.0731162, lng: 19.3542313 },
    { lat: 49.0728561, lng: 19.3511954 },
    { lat: 49.074565, lng: 19.3509671 },
    { lat: 49.0773104, lng: 19.3499178 },
    { lat: 49.0787305, lng: 19.349323 },
    { lat: 49.0785499, lng: 19.3464725 },
    { lat: 49.0785107, lng: 19.3455673 },
    { lat: 49.0786055, lng: 19.3442814 },
    { lat: 49.0785868, lng: 19.3423803 },
    { lat: 49.0785883, lng: 19.3423151 },
    { lat: 49.0786135, lng: 19.3422551 },
    { lat: 49.0786563, lng: 19.3422303 },
    { lat: 49.0787356, lng: 19.3422846 },
    { lat: 49.0789108, lng: 19.3424649 },
    { lat: 49.0791755, lng: 19.3427229 },
    { lat: 49.0792037, lng: 19.3427701 },
    { lat: 49.0794341, lng: 19.3429941 },
    { lat: 49.0798256, lng: 19.3433888 },
    { lat: 49.0797624, lng: 19.3429812 },
    { lat: 49.079532, lng: 19.3416061 },
    { lat: 49.0794947, lng: 19.34115 },
    { lat: 49.0795964, lng: 19.3408944 },
    { lat: 49.0799465, lng: 19.3398096 },
    { lat: 49.08002, lng: 19.3392461 },
    { lat: 49.080077, lng: 19.3378273 },
    { lat: 49.0801013, lng: 19.3375313 },
    { lat: 49.0803693, lng: 19.3360222 },
    { lat: 49.0805848, lng: 19.3348352 },
    { lat: 49.080735, lng: 19.3338657 },
    { lat: 49.0808304, lng: 19.3335709 },
    { lat: 49.0808097, lng: 19.3331777 },
    { lat: 49.0809072, lng: 19.3331655 },
    { lat: 49.0808988, lng: 19.3329983 },
    { lat: 49.0808462, lng: 19.3330448 },
    { lat: 49.080591, lng: 19.3332724 },
    { lat: 49.0805812, lng: 19.3330646 },
    { lat: 49.0800789, lng: 19.3331189 },
    { lat: 49.0800842, lng: 19.3332305 },
    { lat: 49.0800272, lng: 19.3331447 },
    { lat: 49.0799843, lng: 19.3332044 },
    { lat: 49.0798144, lng: 19.333438 },
    { lat: 49.0796634, lng: 19.3335567 },
    { lat: 49.0793965, lng: 19.3333713 },
    { lat: 49.0791685, lng: 19.3336983 },
    { lat: 49.0789739, lng: 19.3336595 },
    { lat: 49.078915, lng: 19.3337214 },
    { lat: 49.0787356, lng: 19.3340272 },
    { lat: 49.0787389, lng: 19.3340735 },
    { lat: 49.0786996, lng: 19.3340406 },
    { lat: 49.0785614, lng: 19.334134 },
    { lat: 49.0785697, lng: 19.3349056 },
    { lat: 49.0784088, lng: 19.3349326 },
    { lat: 49.0773995, lng: 19.3350643 },
    { lat: 49.0771726, lng: 19.3352944 },
    { lat: 49.0762545, lng: 19.3351814 },
    { lat: 49.0763766, lng: 19.3360718 },
    { lat: 49.0759868, lng: 19.3366498 },
    { lat: 49.0751376, lng: 19.3369239 },
    { lat: 49.0749052, lng: 19.3368451 },
    { lat: 49.074914, lng: 19.336916 },
    { lat: 49.0749417, lng: 19.3369522 },
    { lat: 49.0738658, lng: 19.3367227 },
    { lat: 49.0735261, lng: 19.3368314 },
    { lat: 49.0735234, lng: 19.3368184 },
    { lat: 49.0731162, lng: 19.3370273 },
    { lat: 49.0727917, lng: 19.3372925 },
    { lat: 49.0724479, lng: 19.3376464 },
    { lat: 49.0722105, lng: 19.3378705 },
    { lat: 49.0719851, lng: 19.33804 },
    { lat: 49.0710659, lng: 19.3385733 },
    { lat: 49.0708084, lng: 19.3387248 },
    { lat: 49.0705843, lng: 19.3388769 },
    { lat: 49.0704794, lng: 19.3389653 },
    { lat: 49.0700438, lng: 19.339458 },
    { lat: 49.0699449, lng: 19.3396287 },
    { lat: 49.0699474, lng: 19.3395616 },
    { lat: 49.0696126, lng: 19.3399189 },
    { lat: 49.0691813, lng: 19.3401163 },
    { lat: 49.0691633, lng: 19.3401157 },
    { lat: 49.0691488, lng: 19.3398837 },
    { lat: 49.0691088, lng: 19.3394873 },
    { lat: 49.0689546, lng: 19.3395164 },
    { lat: 49.068853, lng: 19.3385351 },
    { lat: 49.0687949, lng: 19.3378298 },
    { lat: 49.0685442, lng: 19.3380571 },
    { lat: 49.0683299, lng: 19.3380547 },
    { lat: 49.0683326, lng: 19.3380871 },
    { lat: 49.0684578, lng: 19.3400675 },
    { lat: 49.0682652, lng: 19.3402151 },
    { lat: 49.0678824, lng: 19.3400808 },
    { lat: 49.0674126, lng: 19.3398181 },
    { lat: 49.0673537, lng: 19.3401462 },
    { lat: 49.0673665, lng: 19.3402023 },
    { lat: 49.0671674, lng: 19.3399017 },
    { lat: 49.0670388, lng: 19.3397558 },
    { lat: 49.0667923, lng: 19.3394517 },
    { lat: 49.066509, lng: 19.3392531 },
    { lat: 49.0663688, lng: 19.3390535 },
    { lat: 49.066176, lng: 19.3387166 },
    { lat: 49.0659755, lng: 19.3383816 },
    { lat: 49.0659957, lng: 19.3383341 },
    { lat: 49.065985, lng: 19.338083 },
    { lat: 49.0659307, lng: 19.3380792 },
    { lat: 49.0658327, lng: 19.338008 },
    { lat: 49.0657417, lng: 19.337752 },
    { lat: 49.0656917, lng: 19.3376978 },
    { lat: 49.0655207, lng: 19.3376339 },
  ],
  Švošov: [
    { lat: 49.1303769, lng: 19.2217522 },
    { lat: 49.1303996, lng: 19.2216922 },
    { lat: 49.1306441, lng: 19.2211991 },
    { lat: 49.1322982, lng: 19.2176344 },
    { lat: 49.1327875, lng: 19.216796 },
    { lat: 49.1364695, lng: 19.2104829 },
    { lat: 49.138222, lng: 19.209117 },
    { lat: 49.1381, lng: 19.207791 },
    { lat: 49.138884, lng: 19.20486 },
    { lat: 49.138973, lng: 19.204529 },
    { lat: 49.139018, lng: 19.203793 },
    { lat: 49.139139, lng: 19.203158 },
    { lat: 49.139212, lng: 19.202828 },
    { lat: 49.139241, lng: 19.202535 },
    { lat: 49.139316, lng: 19.2018 },
    { lat: 49.139208, lng: 19.201409 },
    { lat: 49.139227, lng: 19.200946 },
    { lat: 49.139115, lng: 19.200685 },
    { lat: 49.13918, lng: 19.199731 },
    { lat: 49.139215, lng: 19.199293 },
    { lat: 49.1390189, lng: 19.1992121 },
    { lat: 49.1389093, lng: 19.1987435 },
    { lat: 49.1389315, lng: 19.1983378 },
    { lat: 49.1386672, lng: 19.1976738 },
    { lat: 49.1384716, lng: 19.1971515 },
    { lat: 49.1381965, lng: 19.1967998 },
    { lat: 49.1378511, lng: 19.196208 },
    { lat: 49.1376327, lng: 19.1956296 },
    { lat: 49.1375437, lng: 19.1953995 },
    { lat: 49.1368355, lng: 19.1945741 },
    { lat: 49.1366621, lng: 19.1942556 },
    { lat: 49.136291, lng: 19.1937135 },
    { lat: 49.1356588, lng: 19.1928998 },
    { lat: 49.1346204, lng: 19.1915423 },
    { lat: 49.1330411, lng: 19.1900959 },
    { lat: 49.1319722, lng: 19.1887617 },
    { lat: 49.1310025, lng: 19.1884091 },
    { lat: 49.1295396, lng: 19.186944 },
    { lat: 49.1291045, lng: 19.1856419 },
    { lat: 49.1283875, lng: 19.1851943 },
    { lat: 49.1274945, lng: 19.184145 },
    { lat: 49.1269465, lng: 19.1837007 },
    { lat: 49.1265416, lng: 19.1834602 },
    { lat: 49.1259276, lng: 19.1826307 },
    { lat: 49.1255455, lng: 19.1826316 },
    { lat: 49.1253103, lng: 19.182335 },
    { lat: 49.1248896, lng: 19.1822168 },
    { lat: 49.124441, lng: 19.181729 },
    { lat: 49.1239902, lng: 19.1815897 },
    { lat: 49.1239066, lng: 19.1815409 },
    { lat: 49.1218779, lng: 19.1811955 },
    { lat: 49.1216054, lng: 19.1813618 },
    { lat: 49.1211234, lng: 19.1814176 },
    { lat: 49.1208206, lng: 19.182356 },
    { lat: 49.1203973, lng: 19.1831281 },
    { lat: 49.1202216, lng: 19.1836686 },
    { lat: 49.1199648, lng: 19.1838098 },
    { lat: 49.1197177, lng: 19.1839459 },
    { lat: 49.1195418, lng: 19.1840575 },
    { lat: 49.1193482, lng: 19.1841807 },
    { lat: 49.119446, lng: 19.1844796 },
    { lat: 49.1195648, lng: 19.1847656 },
    { lat: 49.1198024, lng: 19.185388 },
    { lat: 49.120045, lng: 19.1860472 },
    { lat: 49.1203386, lng: 19.1868012 },
    { lat: 49.1205643, lng: 19.1873652 },
    { lat: 49.120842, lng: 19.1880029 },
    { lat: 49.1212238, lng: 19.1892281 },
    { lat: 49.1214719, lng: 19.1899271 },
    { lat: 49.1216841, lng: 19.1909079 },
    { lat: 49.1220108, lng: 19.1937896 },
    { lat: 49.1220526, lng: 19.1944003 },
    { lat: 49.1221305, lng: 19.1950518 },
    { lat: 49.1221511, lng: 19.1957835 },
    { lat: 49.122176, lng: 19.1966664 },
    { lat: 49.1221921, lng: 19.1974018 },
    { lat: 49.1222077, lng: 19.1981167 },
    { lat: 49.122243, lng: 19.1993586 },
    { lat: 49.1223189, lng: 19.2001111 },
    { lat: 49.1223607, lng: 19.2008596 },
    { lat: 49.1223656, lng: 19.2016835 },
    { lat: 49.1223252, lng: 19.2023862 },
    { lat: 49.1219345, lng: 19.2036312 },
    { lat: 49.1218441, lng: 19.2039051 },
    { lat: 49.1217499, lng: 19.2041464 },
    { lat: 49.121448, lng: 19.2048039 },
    { lat: 49.121281, lng: 19.2051501 },
    { lat: 49.1211173, lng: 19.2055164 },
    { lat: 49.1208819, lng: 19.2060556 },
    { lat: 49.1207513, lng: 19.2063142 },
    { lat: 49.1205566, lng: 19.2067434 },
    { lat: 49.120141, lng: 19.2075863 },
    { lat: 49.119965, lng: 19.2078871 },
    { lat: 49.1197357, lng: 19.2083184 },
    { lat: 49.1193283, lng: 19.209101 },
    { lat: 49.1190061, lng: 19.2096678 },
    { lat: 49.118778, lng: 19.2102853 },
    { lat: 49.118596, lng: 19.2108833 },
    { lat: 49.1184109, lng: 19.2116244 },
    { lat: 49.1182542, lng: 19.2123454 },
    { lat: 49.1181646, lng: 19.2129207 },
    { lat: 49.1181442, lng: 19.2131047 },
    { lat: 49.1181235, lng: 19.2135194 },
    { lat: 49.1181429, lng: 19.2140584 },
    { lat: 49.1181691, lng: 19.2143652 },
    { lat: 49.1182014, lng: 19.2145696 },
    { lat: 49.1183242, lng: 19.2150585 },
    { lat: 49.1186861, lng: 19.2162058 },
    { lat: 49.1189948, lng: 19.2169502 },
    { lat: 49.119354, lng: 19.2177128 },
    { lat: 49.1195893, lng: 19.2181999 },
    { lat: 49.1197459, lng: 19.2185962 },
    { lat: 49.1198213, lng: 19.2188281 },
    { lat: 49.1198629, lng: 19.2189788 },
    { lat: 49.1200709, lng: 19.2186991 },
    { lat: 49.1200223, lng: 19.2182504 },
    { lat: 49.1200723, lng: 19.218003 },
    { lat: 49.1201907, lng: 19.2177706 },
    { lat: 49.1202457, lng: 19.2176367 },
    { lat: 49.1202527, lng: 19.2176054 },
    { lat: 49.1203491, lng: 19.217456 },
    { lat: 49.1204368, lng: 19.2171735 },
    { lat: 49.1205537, lng: 19.2167667 },
    { lat: 49.1207213, lng: 19.2167591 },
    { lat: 49.1208951, lng: 19.2167198 },
    { lat: 49.1211657, lng: 19.2165564 },
    { lat: 49.1212813, lng: 19.2165248 },
    { lat: 49.1214525, lng: 19.2165682 },
    { lat: 49.1217009, lng: 19.2164825 },
    { lat: 49.121892, lng: 19.216238 },
    { lat: 49.1223632, lng: 19.2159606 },
    { lat: 49.1226214, lng: 19.2158684 },
    { lat: 49.1226748, lng: 19.2158548 },
    { lat: 49.1227573, lng: 19.2158791 },
    { lat: 49.1228709, lng: 19.2158505 },
    { lat: 49.1230217, lng: 19.2157559 },
    { lat: 49.1231926, lng: 19.2158857 },
    { lat: 49.1233193, lng: 19.2160842 },
    { lat: 49.1233655, lng: 19.2161068 },
    { lat: 49.1234614, lng: 19.2160856 },
    { lat: 49.123728, lng: 19.2162371 },
    { lat: 49.1239376, lng: 19.2164457 },
    { lat: 49.1240237, lng: 19.2166427 },
    { lat: 49.1242284, lng: 19.2168425 },
    { lat: 49.1244197, lng: 19.2169057 },
    { lat: 49.1246596, lng: 19.2168806 },
    { lat: 49.1249349, lng: 19.2169811 },
    { lat: 49.1252181, lng: 19.2170311 },
    { lat: 49.1254697, lng: 19.2170184 },
    { lat: 49.1258177, lng: 19.217009 },
    { lat: 49.125933, lng: 19.2170392 },
    { lat: 49.1261069, lng: 19.2170716 },
    { lat: 49.1262214, lng: 19.2171084 },
    { lat: 49.1262567, lng: 19.2172527 },
    { lat: 49.1262753, lng: 19.2174767 },
    { lat: 49.1264309, lng: 19.2176878 },
    { lat: 49.1265309, lng: 19.217845 },
    { lat: 49.1266533, lng: 19.2180227 },
    { lat: 49.1268272, lng: 19.2182646 },
    { lat: 49.1268605, lng: 19.2185584 },
    { lat: 49.1270371, lng: 19.2187838 },
    { lat: 49.1272015, lng: 19.2188967 },
    { lat: 49.1272951, lng: 19.2190633 },
    { lat: 49.1274518, lng: 19.2195507 },
    { lat: 49.1275509, lng: 19.2198782 },
    { lat: 49.1276065, lng: 19.2202136 },
    { lat: 49.1276267, lng: 19.2204456 },
    { lat: 49.1278547, lng: 19.2206637 },
    { lat: 49.1280218, lng: 19.2207386 },
    { lat: 49.1283004, lng: 19.220977 },
    { lat: 49.1286059, lng: 19.2209547 },
    { lat: 49.1286947, lng: 19.2208814 },
    { lat: 49.1289332, lng: 19.2208259 },
    { lat: 49.1291529, lng: 19.2208909 },
    { lat: 49.1292776, lng: 19.2208874 },
    { lat: 49.1293953, lng: 19.2211039 },
    { lat: 49.1295425, lng: 19.2213314 },
    { lat: 49.1297314, lng: 19.2214128 },
    { lat: 49.1297675, lng: 19.2216712 },
    { lat: 49.1297785, lng: 19.2216897 },
    { lat: 49.1299381, lng: 19.2217039 },
    { lat: 49.1300786, lng: 19.2216248 },
    { lat: 49.1303769, lng: 19.2217522 },
  ],
  Ružomberok: [
    { lat: 49.121517, lng: 19.2669698 },
    { lat: 49.1207161, lng: 19.2661972 },
    { lat: 49.1206674, lng: 19.2653419 },
    { lat: 49.1206261, lng: 19.2645279 },
    { lat: 49.1205853, lng: 19.2639118 },
    { lat: 49.1204242, lng: 19.2628495 },
    { lat: 49.120841, lng: 19.2621429 },
    { lat: 49.1210701, lng: 19.261487 },
    { lat: 49.1213821, lng: 19.260519 },
    { lat: 49.1214028, lng: 19.2597977 },
    { lat: 49.121598, lng: 19.2598005 },
    { lat: 49.1216577, lng: 19.2596348 },
    { lat: 49.1221542, lng: 19.2590853 },
    { lat: 49.122942, lng: 19.2583777 },
    { lat: 49.1231647, lng: 19.2574246 },
    { lat: 49.1233268, lng: 19.2567171 },
    { lat: 49.1234821, lng: 19.2561223 },
    { lat: 49.1236215, lng: 19.2554785 },
    { lat: 49.1234677, lng: 19.2543624 },
    { lat: 49.1233621, lng: 19.2540849 },
    { lat: 49.1230008, lng: 19.2531556 },
    { lat: 49.1230835, lng: 19.2528593 },
    { lat: 49.1232577, lng: 19.2522134 },
    { lat: 49.1231013, lng: 19.2509264 },
    { lat: 49.1232767, lng: 19.250402 },
    { lat: 49.1235352, lng: 19.249986 },
    { lat: 49.1233321, lng: 19.2495581 },
    { lat: 49.1234783, lng: 19.2493169 },
    { lat: 49.1234945, lng: 19.2490668 },
    { lat: 49.1236111, lng: 19.2486083 },
    { lat: 49.1238723, lng: 19.2478945 },
    { lat: 49.1240639, lng: 19.2474967 },
    { lat: 49.1242842, lng: 19.2471548 },
    { lat: 49.1247701, lng: 19.2463835 },
    { lat: 49.125607, lng: 19.2435769 },
    { lat: 49.1253907, lng: 19.2431098 },
    { lat: 49.1256083, lng: 19.242595 },
    { lat: 49.125525, lng: 19.2397528 },
    { lat: 49.1257312, lng: 19.2397791 },
    { lat: 49.1284838, lng: 19.2343415 },
    { lat: 49.1276851, lng: 19.2316549 },
    { lat: 49.1275574, lng: 19.2304542 },
    { lat: 49.1281885, lng: 19.2271019 },
    { lat: 49.1303256, lng: 19.221888 },
    { lat: 49.1303769, lng: 19.2217522 },
    { lat: 49.1300786, lng: 19.2216248 },
    { lat: 49.1299381, lng: 19.2217039 },
    { lat: 49.1297785, lng: 19.2216897 },
    { lat: 49.1297675, lng: 19.2216712 },
    { lat: 49.1297314, lng: 19.2214128 },
    { lat: 49.1295425, lng: 19.2213314 },
    { lat: 49.1293953, lng: 19.2211039 },
    { lat: 49.1292776, lng: 19.2208874 },
    { lat: 49.1291529, lng: 19.2208909 },
    { lat: 49.1289332, lng: 19.2208259 },
    { lat: 49.1286947, lng: 19.2208814 },
    { lat: 49.1286059, lng: 19.2209547 },
    { lat: 49.1283004, lng: 19.220977 },
    { lat: 49.1280218, lng: 19.2207386 },
    { lat: 49.1278547, lng: 19.2206637 },
    { lat: 49.1276267, lng: 19.2204456 },
    { lat: 49.1276065, lng: 19.2202136 },
    { lat: 49.1275509, lng: 19.2198782 },
    { lat: 49.1274518, lng: 19.2195507 },
    { lat: 49.1272951, lng: 19.2190633 },
    { lat: 49.1272015, lng: 19.2188967 },
    { lat: 49.1270371, lng: 19.2187838 },
    { lat: 49.1268605, lng: 19.2185584 },
    { lat: 49.1268272, lng: 19.2182646 },
    { lat: 49.1266533, lng: 19.2180227 },
    { lat: 49.1265309, lng: 19.217845 },
    { lat: 49.1264309, lng: 19.2176878 },
    { lat: 49.1262753, lng: 19.2174767 },
    { lat: 49.1262567, lng: 19.2172527 },
    { lat: 49.1262214, lng: 19.2171084 },
    { lat: 49.1261069, lng: 19.2170716 },
    { lat: 49.125933, lng: 19.2170392 },
    { lat: 49.1258177, lng: 19.217009 },
    { lat: 49.1254697, lng: 19.2170184 },
    { lat: 49.1252181, lng: 19.2170311 },
    { lat: 49.1249349, lng: 19.2169811 },
    { lat: 49.1246596, lng: 19.2168806 },
    { lat: 49.1244197, lng: 19.2169057 },
    { lat: 49.1242284, lng: 19.2168425 },
    { lat: 49.1240237, lng: 19.2166427 },
    { lat: 49.1239376, lng: 19.2164457 },
    { lat: 49.123728, lng: 19.2162371 },
    { lat: 49.1234614, lng: 19.2160856 },
    { lat: 49.1233655, lng: 19.2161068 },
    { lat: 49.1233193, lng: 19.2160842 },
    { lat: 49.1231926, lng: 19.2158857 },
    { lat: 49.1230217, lng: 19.2157559 },
    { lat: 49.1228709, lng: 19.2158505 },
    { lat: 49.1227573, lng: 19.2158791 },
    { lat: 49.1226748, lng: 19.2158548 },
    { lat: 49.1226214, lng: 19.2158684 },
    { lat: 49.1223632, lng: 19.2159606 },
    { lat: 49.121892, lng: 19.216238 },
    { lat: 49.1217009, lng: 19.2164825 },
    { lat: 49.1214525, lng: 19.2165682 },
    { lat: 49.1212813, lng: 19.2165248 },
    { lat: 49.1211657, lng: 19.2165564 },
    { lat: 49.1208951, lng: 19.2167198 },
    { lat: 49.1207213, lng: 19.2167591 },
    { lat: 49.1205537, lng: 19.2167667 },
    { lat: 49.1204368, lng: 19.2171735 },
    { lat: 49.1203491, lng: 19.217456 },
    { lat: 49.1202527, lng: 19.2176054 },
    { lat: 49.1202457, lng: 19.2176367 },
    { lat: 49.1201907, lng: 19.2177706 },
    { lat: 49.1200723, lng: 19.218003 },
    { lat: 49.1200223, lng: 19.2182504 },
    { lat: 49.1200709, lng: 19.2186991 },
    { lat: 49.1198629, lng: 19.2189788 },
    { lat: 49.1198854, lng: 19.2190603 },
    { lat: 49.1199421, lng: 19.2193245 },
    { lat: 49.1199869, lng: 19.2196218 },
    { lat: 49.120043, lng: 19.2200851 },
    { lat: 49.1200672, lng: 19.2205865 },
    { lat: 49.1200939, lng: 19.2209311 },
    { lat: 49.1201022, lng: 19.2211475 },
    { lat: 49.1200969, lng: 19.2216684 },
    { lat: 49.1200843, lng: 19.2219703 },
    { lat: 49.1200357, lng: 19.2227605 },
    { lat: 49.119966, lng: 19.2233487 },
    { lat: 49.1198223, lng: 19.2243921 },
    { lat: 49.1197318, lng: 19.2249448 },
    { lat: 49.1196601, lng: 19.2253044 },
    { lat: 49.1195842, lng: 19.2256011 },
    { lat: 49.1195196, lng: 19.2258045 },
    { lat: 49.1194284, lng: 19.2259953 },
    { lat: 49.1191427, lng: 19.2264738 },
    { lat: 49.118917, lng: 19.2267491 },
    { lat: 49.1185715, lng: 19.2270208 },
    { lat: 49.1184376, lng: 19.2271182 },
    { lat: 49.1182868, lng: 19.2272153 },
    { lat: 49.1179715, lng: 19.2273869 },
    { lat: 49.1176444, lng: 19.2275429 },
    { lat: 49.1173973, lng: 19.2275867 },
    { lat: 49.1172068, lng: 19.2275828 },
    { lat: 49.1170442, lng: 19.227484 },
    { lat: 49.1167565, lng: 19.2272427 },
    { lat: 49.1164841, lng: 19.2269716 },
    { lat: 49.116026, lng: 19.226586 },
    { lat: 49.1157652, lng: 19.2263198 },
    { lat: 49.1155644, lng: 19.2260821 },
    { lat: 49.1153392, lng: 19.2257809 },
    { lat: 49.1151155, lng: 19.225417 },
    { lat: 49.1148879, lng: 19.2250963 },
    { lat: 49.1148116, lng: 19.2250073 },
    { lat: 49.1147369, lng: 19.2249516 },
    { lat: 49.1145493, lng: 19.2248524 },
    { lat: 49.1144096, lng: 19.2248062 },
    { lat: 49.1141493, lng: 19.2248741 },
    { lat: 49.1141563, lng: 19.2245031 },
    { lat: 49.1140618, lng: 19.2244407 },
    { lat: 49.1140258, lng: 19.2244436 },
    { lat: 49.1140011, lng: 19.2242528 },
    { lat: 49.1140549, lng: 19.2238302 },
    { lat: 49.1140132, lng: 19.2235481 },
    { lat: 49.1138225, lng: 19.2230081 },
    { lat: 49.1134047, lng: 19.2222439 },
    { lat: 49.1130767, lng: 19.2216326 },
    { lat: 49.112934, lng: 19.221269 },
    { lat: 49.1126599, lng: 19.2209816 },
    { lat: 49.1122837, lng: 19.2208697 },
    { lat: 49.1122204, lng: 19.2208184 },
    { lat: 49.1120642, lng: 19.2206917 },
    { lat: 49.1112685, lng: 19.2202015 },
    { lat: 49.1110133, lng: 19.219885 },
    { lat: 49.1105404, lng: 19.2191698 },
    { lat: 49.1101624, lng: 19.2191154 },
    { lat: 49.1096631, lng: 19.2190435 },
    { lat: 49.1091997, lng: 19.2186669 },
    { lat: 49.1085985, lng: 19.2182819 },
    { lat: 49.1081988, lng: 19.21834 },
    { lat: 49.1078152, lng: 19.2180307 },
    { lat: 49.1068849, lng: 19.2173653 },
    { lat: 49.1063613, lng: 19.2169782 },
    { lat: 49.1054591, lng: 19.2165238 },
    { lat: 49.1050903, lng: 19.2163791 },
    { lat: 49.1047146, lng: 19.2162093 },
    { lat: 49.1043676, lng: 19.2159787 },
    { lat: 49.1039464, lng: 19.2154931 },
    { lat: 49.1035478, lng: 19.2153622 },
    { lat: 49.1032651, lng: 19.2151319 },
    { lat: 49.1031152, lng: 19.2146897 },
    { lat: 49.1029881, lng: 19.2145242 },
    { lat: 49.1028198, lng: 19.2142883 },
    { lat: 49.1024264, lng: 19.2140564 },
    { lat: 49.1021936, lng: 19.2138077 },
    { lat: 49.1012784, lng: 19.2132515 },
    { lat: 49.1009588, lng: 19.2134208 },
    { lat: 49.1008591, lng: 19.2133906 },
    { lat: 49.1004015, lng: 19.2129001 },
    { lat: 49.0997622, lng: 19.2128023 },
    { lat: 49.0993125, lng: 19.2128225 },
    { lat: 49.0988329, lng: 19.2129071 },
    { lat: 49.0984917, lng: 19.2129586 },
    { lat: 49.0979873, lng: 19.2127853 },
    { lat: 49.0976248, lng: 19.2127705 },
    { lat: 49.097473, lng: 19.2129188 },
    { lat: 49.0972876, lng: 19.2135592 },
    { lat: 49.0971135, lng: 19.2136902 },
    { lat: 49.0968008, lng: 19.213658 },
    { lat: 49.0965589, lng: 19.2138768 },
    { lat: 49.096345, lng: 19.2137856 },
    { lat: 49.0963399, lng: 19.213611 },
    { lat: 49.0963193, lng: 19.2135795 },
    { lat: 49.0960693, lng: 19.2135626 },
    { lat: 49.0956863, lng: 19.2134041 },
    { lat: 49.0950403, lng: 19.2130959 },
    { lat: 49.0946199, lng: 19.2131437 },
    { lat: 49.0943617, lng: 19.2131427 },
    { lat: 49.0941561, lng: 19.2130392 },
    { lat: 49.0936937, lng: 19.2128931 },
    { lat: 49.0935, lng: 19.2127548 },
    { lat: 49.0930627, lng: 19.2127077 },
    { lat: 49.0929284, lng: 19.212612 },
    { lat: 49.0925995, lng: 19.2127132 },
    { lat: 49.0922688, lng: 19.2125593 },
    { lat: 49.0920919, lng: 19.2123744 },
    { lat: 49.091823, lng: 19.2123384 },
    { lat: 49.091336, lng: 19.2124997 },
    { lat: 49.0908283, lng: 19.2123264 },
    { lat: 49.0904725, lng: 19.2124069 },
    { lat: 49.0903808, lng: 19.2122773 },
    { lat: 49.0902123, lng: 19.2122419 },
    { lat: 49.0900862, lng: 19.2123408 },
    { lat: 49.0900244, lng: 19.2124157 },
    { lat: 49.0899798, lng: 19.2124509 },
    { lat: 49.0898981, lng: 19.2125539 },
    { lat: 49.0898759, lng: 19.2124205 },
    { lat: 49.0898761, lng: 19.2123759 },
    { lat: 49.0898392, lng: 19.2122426 },
    { lat: 49.0897094, lng: 19.2123086 },
    { lat: 49.0894932, lng: 19.2122447 },
    { lat: 49.0891948, lng: 19.2118743 },
    { lat: 49.0890075, lng: 19.2117323 },
    { lat: 49.0889631, lng: 19.2115622 },
    { lat: 49.088875, lng: 19.2113476 },
    { lat: 49.0885827, lng: 19.210709 },
    { lat: 49.0884976, lng: 19.2105351 },
    { lat: 49.088381, lng: 19.2103626 },
    { lat: 49.0881803, lng: 19.2101963 },
    { lat: 49.0878562, lng: 19.2100633 },
    { lat: 49.0876523, lng: 19.2099293 },
    { lat: 49.0872913, lng: 19.2098993 },
    { lat: 49.0868892, lng: 19.2099073 },
    { lat: 49.086815, lng: 19.2099541 },
    { lat: 49.086648, lng: 19.2098131 },
    { lat: 49.0864941, lng: 19.2097757 },
    { lat: 49.086427, lng: 19.2098332 },
    { lat: 49.0861495, lng: 19.2096625 },
    { lat: 49.0857053, lng: 19.2094056 },
    { lat: 49.0855372, lng: 19.2092169 },
    { lat: 49.0853619, lng: 19.2092743 },
    { lat: 49.0851747, lng: 19.2091529 },
    { lat: 49.0849746, lng: 19.2090736 },
    { lat: 49.0848129, lng: 19.2089004 },
    { lat: 49.0843634, lng: 19.2088112 },
    { lat: 49.0839366, lng: 19.2089105 },
    { lat: 49.0833857, lng: 19.2086827 },
    { lat: 49.0830713, lng: 19.208545 },
    { lat: 49.0829482, lng: 19.2085385 },
    { lat: 49.0828707, lng: 19.2086164 },
    { lat: 49.0826717, lng: 19.2089759 },
    { lat: 49.0823794, lng: 19.2093877 },
    { lat: 49.082093, lng: 19.2096635 },
    { lat: 49.0817717, lng: 19.2098527 },
    { lat: 49.0815949, lng: 19.2100842 },
    { lat: 49.0813167, lng: 19.2103675 },
    { lat: 49.0811665, lng: 19.2107385 },
    { lat: 49.0807272, lng: 19.2112778 },
    { lat: 49.0801195, lng: 19.2114134 },
    { lat: 49.0797977, lng: 19.2115444 },
    { lat: 49.0792465, lng: 19.2116144 },
    { lat: 49.0790017, lng: 19.211773 },
    { lat: 49.0786914, lng: 19.2123952 },
    { lat: 49.078366, lng: 19.2130073 },
    { lat: 49.0777054, lng: 19.2137065 },
    { lat: 49.077276, lng: 19.2141187 },
    { lat: 49.0770605, lng: 19.2143787 },
    { lat: 49.0768306, lng: 19.2146371 },
    { lat: 49.0761088, lng: 19.2149059 },
    { lat: 49.0756582, lng: 19.2149117 },
    { lat: 49.0754163, lng: 19.2148755 },
    { lat: 49.0750854, lng: 19.2147001 },
    { lat: 49.0746064, lng: 19.2145326 },
    { lat: 49.0742762, lng: 19.214489 },
    { lat: 49.0741266, lng: 19.2144471 },
    { lat: 49.0737807, lng: 19.2146354 },
    { lat: 49.0735487, lng: 19.2147131 },
    { lat: 49.0733214, lng: 19.2147644 },
    { lat: 49.0730271, lng: 19.2147158 },
    { lat: 49.0728106, lng: 19.2145067 },
    { lat: 49.0725888, lng: 19.2141842 },
    { lat: 49.0717893, lng: 19.2130299 },
    { lat: 49.071434, lng: 19.2125832 },
    { lat: 49.0711821, lng: 19.2124094 },
    { lat: 49.0709792, lng: 19.212151 },
    { lat: 49.0707465, lng: 19.2122321 },
    { lat: 49.0705038, lng: 19.2122049 },
    { lat: 49.0703401, lng: 19.212219 },
    { lat: 49.070069, lng: 19.2121185 },
    { lat: 49.0700283, lng: 19.2121122 },
    { lat: 49.069865, lng: 19.212188 },
    { lat: 49.0694443, lng: 19.2118986 },
    { lat: 49.0691946, lng: 19.211936 },
    { lat: 49.0689675, lng: 19.2120613 },
    { lat: 49.0685774, lng: 19.2125759 },
    { lat: 49.0682985, lng: 19.2127534 },
    { lat: 49.0681229, lng: 19.2127299 },
    { lat: 49.0676644, lng: 19.2127338 },
    { lat: 49.0672228, lng: 19.2126959 },
    { lat: 49.0668235, lng: 19.2128754 },
    { lat: 49.0662848, lng: 19.2128763 },
    { lat: 49.0658533, lng: 19.2130677 },
    { lat: 49.0654877, lng: 19.2134526 },
    { lat: 49.0651091, lng: 19.2136201 },
    { lat: 49.0644383, lng: 19.2140154 },
    { lat: 49.0640262, lng: 19.2140946 },
    { lat: 49.0637088, lng: 19.2141233 },
    { lat: 49.0634352, lng: 19.2143677 },
    { lat: 49.0630966, lng: 19.2145393 },
    { lat: 49.0627087, lng: 19.2146988 },
    { lat: 49.062232, lng: 19.2145422 },
    { lat: 49.0619305, lng: 19.2144613 },
    { lat: 49.0614088, lng: 19.2142753 },
    { lat: 49.0609875, lng: 19.2142581 },
    { lat: 49.0604441, lng: 19.2139997 },
    { lat: 49.0600065, lng: 19.2138744 },
    { lat: 49.0593566, lng: 19.2137437 },
    { lat: 49.059063, lng: 19.2134983 },
    { lat: 49.0587285, lng: 19.213199 },
    { lat: 49.0585198, lng: 19.2129428 },
    { lat: 49.0583562, lng: 19.2127293 },
    { lat: 49.0579401, lng: 19.2121203 },
    { lat: 49.0576521, lng: 19.211824 },
    { lat: 49.0574563, lng: 19.2116688 },
    { lat: 49.0571914, lng: 19.2114842 },
    { lat: 49.0569168, lng: 19.2113752 },
    { lat: 49.0567086, lng: 19.2113129 },
    { lat: 49.0563106, lng: 19.210937 },
    { lat: 49.0560321, lng: 19.2106771 },
    { lat: 49.0558277, lng: 19.2105523 },
    { lat: 49.0556415, lng: 19.2105264 },
    { lat: 49.0553377, lng: 19.210491 },
    { lat: 49.0549335, lng: 19.2103828 },
    { lat: 49.0543407, lng: 19.2103335 },
    { lat: 49.0540457, lng: 19.2104114 },
    { lat: 49.0539946, lng: 19.2104297 },
    { lat: 49.0536194, lng: 19.2105943 },
    { lat: 49.0531995, lng: 19.2108828 },
    { lat: 49.0528675, lng: 19.2110601 },
    { lat: 49.0526943, lng: 19.21123 },
    { lat: 49.0525033, lng: 19.2113136 },
    { lat: 49.0523849, lng: 19.2114501 },
    { lat: 49.0521808, lng: 19.2116354 },
    { lat: 49.0518238, lng: 19.2119204 },
    { lat: 49.0516283, lng: 19.2122402 },
    { lat: 49.0514572, lng: 19.2124286 },
    { lat: 49.0513274, lng: 19.2127029 },
    { lat: 49.0510983, lng: 19.2129768 },
    { lat: 49.0508328, lng: 19.2133874 },
    { lat: 49.0506106, lng: 19.2136858 },
    { lat: 49.050607, lng: 19.2137724 },
    { lat: 49.0506037, lng: 19.2138472 },
    { lat: 49.0505638, lng: 19.2138811 },
    { lat: 49.0504415, lng: 19.2139411 },
    { lat: 49.0500124, lng: 19.2143923 },
    { lat: 49.0497814, lng: 19.2147423 },
    { lat: 49.0495907, lng: 19.2148789 },
    { lat: 49.0494541, lng: 19.2150807 },
    { lat: 49.0492616, lng: 19.2153211 },
    { lat: 49.0491118, lng: 19.215511 },
    { lat: 49.0488758, lng: 19.2156862 },
    { lat: 49.0486732, lng: 19.2157886 },
    { lat: 49.0484481, lng: 19.2159363 },
    { lat: 49.0482579, lng: 19.2160072 },
    { lat: 49.0481469, lng: 19.2159725 },
    { lat: 49.0478513, lng: 19.2160145 },
    { lat: 49.047608, lng: 19.2161362 },
    { lat: 49.0474307, lng: 19.2161277 },
    { lat: 49.0471739, lng: 19.2162494 },
    { lat: 49.0470602, lng: 19.2161598 },
    { lat: 49.0468541, lng: 19.2162098 },
    { lat: 49.0467221, lng: 19.2161814 },
    { lat: 49.0465598, lng: 19.2162049 },
    { lat: 49.0464649, lng: 19.2162256 },
    { lat: 49.0463239, lng: 19.2163132 },
    { lat: 49.0462554, lng: 19.2163238 },
    { lat: 49.0462086, lng: 19.2163329 },
    { lat: 49.0461305, lng: 19.2163984 },
    { lat: 49.0460051, lng: 19.2164804 },
    { lat: 49.0456959, lng: 19.2165678 },
    { lat: 49.0452882, lng: 19.216479 },
    { lat: 49.045045, lng: 19.2164613 },
    { lat: 49.0447419, lng: 19.2164657 },
    { lat: 49.044377, lng: 19.2164953 },
    { lat: 49.0439978, lng: 19.2165313 },
    { lat: 49.0438908, lng: 19.2166284 },
    { lat: 49.0435843, lng: 19.2165577 },
    { lat: 49.0432544, lng: 19.216309 },
    { lat: 49.0431397, lng: 19.2162237 },
    { lat: 49.0430696, lng: 19.2160774 },
    { lat: 49.0430747, lng: 19.2159227 },
    { lat: 49.043052, lng: 19.2159235 },
    { lat: 49.0413489, lng: 19.2159925 },
    { lat: 49.0423811, lng: 19.2180428 },
    { lat: 49.041126, lng: 19.2191226 },
    { lat: 49.0397245, lng: 19.220191 },
    { lat: 49.0378096, lng: 19.2216353 },
    { lat: 49.0367222, lng: 19.2218994 },
    { lat: 49.0340873, lng: 19.2206421 },
    { lat: 49.0337218, lng: 19.2209642 },
    { lat: 49.0333864, lng: 19.2209043 },
    { lat: 49.0330036, lng: 19.2207298 },
    { lat: 49.0326606, lng: 19.2202972 },
    { lat: 49.0322504, lng: 19.2199033 },
    { lat: 49.031853, lng: 19.2197293 },
    { lat: 49.0309314, lng: 19.2215348 },
    { lat: 49.0307703, lng: 19.2217935 },
    { lat: 49.0298335, lng: 19.2215763 },
    { lat: 49.0297912, lng: 19.2215666 },
    { lat: 49.0286786, lng: 19.2213083 },
    { lat: 49.0284164, lng: 19.2211268 },
    { lat: 49.0272823, lng: 19.2194899 },
    { lat: 49.0263587, lng: 19.2175422 },
    { lat: 49.0261552, lng: 19.2171595 },
    { lat: 49.0259421, lng: 19.216762 },
    { lat: 49.0255987, lng: 19.2159506 },
    { lat: 49.0253596, lng: 19.2153865 },
    { lat: 49.0237769, lng: 19.2135644 },
    { lat: 49.0223969, lng: 19.2119496 },
    { lat: 49.0220959, lng: 19.2115099 },
    { lat: 49.020412, lng: 19.2096239 },
    { lat: 49.019774, lng: 19.2094123 },
    { lat: 49.018966, lng: 19.2092262 },
    { lat: 49.0171544, lng: 19.208455 },
    { lat: 49.0164485, lng: 19.2079838 },
    { lat: 49.0153816, lng: 19.2074816 },
    { lat: 49.014418, lng: 19.2071568 },
    { lat: 49.0138591, lng: 19.2068807 },
    { lat: 49.012692, lng: 19.2059384 },
    { lat: 49.0119312, lng: 19.2056161 },
    { lat: 49.0113717, lng: 19.205628 },
    { lat: 49.0111065, lng: 19.2057163 },
    { lat: 49.0108355, lng: 19.2057294 },
    { lat: 49.010357, lng: 19.2060163 },
    { lat: 49.0099499, lng: 19.2061753 },
    { lat: 49.00979, lng: 19.2062966 },
    { lat: 49.0095318, lng: 19.2066228 },
    { lat: 49.0090335, lng: 19.2072233 },
    { lat: 49.00792, lng: 19.2079493 },
    { lat: 49.007366, lng: 19.2074239 },
    { lat: 49.0072984, lng: 19.2073553 },
    { lat: 49.0060397, lng: 19.2060572 },
    { lat: 49.0054693, lng: 19.2058471 },
    { lat: 49.0040959, lng: 19.2056048 },
    { lat: 49.0031663, lng: 19.2051686 },
    { lat: 49.0017726, lng: 19.2037604 },
    { lat: 49.0015539, lng: 19.2035753 },
    { lat: 49.0015287, lng: 19.2035677 },
    { lat: 49.0003881, lng: 19.2026378 },
    { lat: 48.9997099, lng: 19.2022944 },
    { lat: 48.9997467, lng: 19.2012403 },
    { lat: 48.9992774, lng: 19.2007695 },
    { lat: 48.9990612, lng: 19.2008454 },
    { lat: 48.9986689, lng: 19.2017275 },
    { lat: 48.9977519, lng: 19.2011309 },
    { lat: 48.9977301, lng: 19.2011933 },
    { lat: 48.9970602, lng: 19.201674 },
    { lat: 48.996587, lng: 19.2020027 },
    { lat: 48.9963792, lng: 19.2021114 },
    { lat: 48.9960013, lng: 19.2022423 },
    { lat: 48.9952572, lng: 19.2014961 },
    { lat: 48.9938308, lng: 19.2000686 },
    { lat: 48.9924196, lng: 19.2004169 },
    { lat: 48.9910308, lng: 19.2022285 },
    { lat: 48.9903245, lng: 19.2014466 },
    { lat: 48.9902805, lng: 19.2013976 },
    { lat: 48.9901769, lng: 19.2012825 },
    { lat: 48.9900474, lng: 19.2011689 },
    { lat: 48.9899992, lng: 19.2011298 },
    { lat: 48.9896286, lng: 19.2008085 },
    { lat: 48.9899272, lng: 19.2005263 },
    { lat: 48.9899484, lng: 19.1999403 },
    { lat: 48.9899581, lng: 19.199679 },
    { lat: 48.9895276, lng: 19.1988677 },
    { lat: 48.9886709, lng: 19.1972413 },
    { lat: 48.9856199, lng: 19.1955034 },
    { lat: 48.985093, lng: 19.1971397 },
    { lat: 48.9858236, lng: 19.2010501 },
    { lat: 48.9858344, lng: 19.2018757 },
    { lat: 48.9864067, lng: 19.2030297 },
    { lat: 48.989032, lng: 19.2055198 },
    { lat: 48.9892455, lng: 19.2062062 },
    { lat: 48.989657, lng: 19.2075097 },
    { lat: 48.9898453, lng: 19.2096306 },
    { lat: 48.989874, lng: 19.2127362 },
    { lat: 48.9893739, lng: 19.2142823 },
    { lat: 48.9885896, lng: 19.2156601 },
    { lat: 48.9880213, lng: 19.217868 },
    { lat: 48.9879287, lng: 19.2182817 },
    { lat: 48.9878662, lng: 19.2185765 },
    { lat: 48.986944, lng: 19.222278 },
    { lat: 48.9861596, lng: 19.2234712 },
    { lat: 48.9851962, lng: 19.2251824 },
    { lat: 48.9832727, lng: 19.2304427 },
    { lat: 48.9825118, lng: 19.2312376 },
    { lat: 48.9796408, lng: 19.2328707 },
    { lat: 48.9785944, lng: 19.2330955 },
    { lat: 48.9718214, lng: 19.2366448 },
    { lat: 48.9656357, lng: 19.2398711 },
    { lat: 48.9656029, lng: 19.2398909 },
    { lat: 48.9648887, lng: 19.2402737 },
    { lat: 48.9648405, lng: 19.240302 },
    { lat: 48.9631458, lng: 19.2412279 },
    { lat: 48.9627178, lng: 19.2417482 },
    { lat: 48.9612691, lng: 19.2438482 },
    { lat: 48.9608263, lng: 19.2444559 },
    { lat: 48.9609949, lng: 19.2447994 },
    { lat: 48.9610217, lng: 19.2454245 },
    { lat: 48.9610122, lng: 19.2459525 },
    { lat: 48.9609552, lng: 19.246575 },
    { lat: 48.9608092, lng: 19.2469126 },
    { lat: 48.9602048, lng: 19.2477025 },
    { lat: 48.9598803, lng: 19.2481743 },
    { lat: 48.9597327, lng: 19.2483607 },
    { lat: 48.9592546, lng: 19.2496694 },
    { lat: 48.958609, lng: 19.2499523 },
    { lat: 48.9576577, lng: 19.2503333 },
    { lat: 48.9571185, lng: 19.2510254 },
    { lat: 48.9570074, lng: 19.251244 },
    { lat: 48.9569131, lng: 19.2547194 },
    { lat: 48.9570192, lng: 19.2548446 },
    { lat: 48.957795, lng: 19.2558591 },
    { lat: 48.9586503, lng: 19.257002 },
    { lat: 48.9596052, lng: 19.2582506 },
    { lat: 48.9596366, lng: 19.2582952 },
    { lat: 48.9614201, lng: 19.2606511 },
    { lat: 48.9615759, lng: 19.260867 },
    { lat: 48.9621034, lng: 19.2615726 },
    { lat: 48.9621154, lng: 19.261586 },
    { lat: 48.9620718, lng: 19.2621332 },
    { lat: 48.9617028, lng: 19.2627382 },
    { lat: 48.9612474, lng: 19.2634648 },
    { lat: 48.9611363, lng: 19.2636593 },
    { lat: 48.9611199, lng: 19.2636729 },
    { lat: 48.9600968, lng: 19.2648295 },
    { lat: 48.9599596, lng: 19.2650721 },
    { lat: 48.9598351, lng: 19.265461 },
    { lat: 48.9597997, lng: 19.2655205 },
    { lat: 48.9597661, lng: 19.2656415 },
    { lat: 48.960528, lng: 19.2663707 },
    { lat: 48.9614345, lng: 19.2668684 },
    { lat: 48.9618711, lng: 19.2670934 },
    { lat: 48.9620926, lng: 19.2671755 },
    { lat: 48.9624137, lng: 19.267169 },
    { lat: 48.9628457, lng: 19.2671783 },
    { lat: 48.9630982, lng: 19.2673659 },
    { lat: 48.9633219, lng: 19.2676375 },
    { lat: 48.9634675, lng: 19.2680138 },
    { lat: 48.9634974, lng: 19.2682146 },
    { lat: 48.9634828, lng: 19.2685706 },
    { lat: 48.9634285, lng: 19.2689636 },
    { lat: 48.9633976, lng: 19.2692364 },
    { lat: 48.9634486, lng: 19.2695511 },
    { lat: 48.9635695, lng: 19.2698121 },
    { lat: 48.9636427, lng: 19.2698862 },
    { lat: 48.9644245, lng: 19.2701327 },
    { lat: 48.9649337, lng: 19.270245 },
    { lat: 48.9650742, lng: 19.2704282 },
    { lat: 48.9652504, lng: 19.2707882 },
    { lat: 48.9654875, lng: 19.2712649 },
    { lat: 48.9655707, lng: 19.2714236 },
    { lat: 48.9658469, lng: 19.2717116 },
    { lat: 48.9662477, lng: 19.2719844 },
    { lat: 48.9666197, lng: 19.2721707 },
    { lat: 48.9668252, lng: 19.2722065 },
    { lat: 48.9669809, lng: 19.2721828 },
    { lat: 48.9670692, lng: 19.2721419 },
    { lat: 48.9671799, lng: 19.2720565 },
    { lat: 48.9674484, lng: 19.2717804 },
    { lat: 48.9675655, lng: 19.2717312 },
    { lat: 48.9677722, lng: 19.2716918 },
    { lat: 48.96789, lng: 19.2717255 },
    { lat: 48.9679478, lng: 19.2717582 },
    { lat: 48.9679768, lng: 19.2718213 },
    { lat: 48.9680847, lng: 19.2720028 },
    { lat: 48.9686336, lng: 19.2732759 },
    { lat: 48.9686689, lng: 19.2733516 },
    { lat: 48.9687156, lng: 19.2734099 },
    { lat: 48.9688464, lng: 19.2736007 },
    { lat: 48.9687708, lng: 19.2736898 },
    { lat: 48.968433, lng: 19.2740277 },
    { lat: 48.9682112, lng: 19.2743388 },
    { lat: 48.9677105, lng: 19.2749455 },
    { lat: 48.9677503, lng: 19.2750709 },
    { lat: 48.9677285, lng: 19.2753216 },
    { lat: 48.9676434, lng: 19.2756391 },
    { lat: 48.96733, lng: 19.2761092 },
    { lat: 48.9672864, lng: 19.2762095 },
    { lat: 48.9670455, lng: 19.2764961 },
    { lat: 48.9667256, lng: 19.2772539 },
    { lat: 48.966691, lng: 19.2773596 },
    { lat: 48.9665656, lng: 19.2775895 },
    { lat: 48.9665279, lng: 19.2776948 },
    { lat: 48.9664649, lng: 19.2777747 },
    { lat: 48.9663973, lng: 19.2778598 },
    { lat: 48.9662355, lng: 19.2780825 },
    { lat: 48.9659796, lng: 19.2783654 },
    { lat: 48.9656532, lng: 19.2790123 },
    { lat: 48.9651003, lng: 19.2804092 },
    { lat: 48.9648321, lng: 19.2811167 },
    { lat: 48.9646813, lng: 19.2817804 },
    { lat: 48.9646168, lng: 19.2823679 },
    { lat: 48.964544, lng: 19.2826342 },
    { lat: 48.9643141, lng: 19.2831546 },
    { lat: 48.9640842, lng: 19.2837904 },
    { lat: 48.9637798, lng: 19.2841228 },
    { lat: 48.9636876, lng: 19.2845745 },
    { lat: 48.963378, lng: 19.2853124 },
    { lat: 48.9631165, lng: 19.2858982 },
    { lat: 48.9628966, lng: 19.2866287 },
    { lat: 48.9626027, lng: 19.2872253 },
    { lat: 48.9625052, lng: 19.2874739 },
    { lat: 48.9624165, lng: 19.2876249 },
    { lat: 48.9623114, lng: 19.2878015 },
    { lat: 48.9622346, lng: 19.2879646 },
    { lat: 48.9619605, lng: 19.2890938 },
    { lat: 48.961835, lng: 19.2898131 },
    { lat: 48.9617252, lng: 19.2901362 },
    { lat: 48.9616401, lng: 19.2902173 },
    { lat: 48.9611647, lng: 19.2907431 },
    { lat: 48.9609231, lng: 19.2912518 },
    { lat: 48.9608821, lng: 19.2912878 },
    { lat: 48.9609149, lng: 19.291437 },
    { lat: 48.9609043, lng: 19.2916853 },
    { lat: 48.9608546, lng: 19.2918016 },
    { lat: 48.9608761, lng: 19.2919813 },
    { lat: 48.9609057, lng: 19.2922336 },
    { lat: 48.9609097, lng: 19.2925803 },
    { lat: 48.9608965, lng: 19.2928673 },
    { lat: 48.9609244, lng: 19.2929571 },
    { lat: 48.9608531, lng: 19.2932795 },
    { lat: 48.9609011, lng: 19.2934134 },
    { lat: 48.9608472, lng: 19.2944566 },
    { lat: 48.9609006, lng: 19.2948927 },
    { lat: 48.9609524, lng: 19.2952907 },
    { lat: 48.9610402, lng: 19.2953826 },
    { lat: 48.9611613, lng: 19.2956788 },
    { lat: 48.9611469, lng: 19.2959655 },
    { lat: 48.9612225, lng: 19.2963675 },
    { lat: 48.961288, lng: 19.2964672 },
    { lat: 48.9613084, lng: 19.2965148 },
    { lat: 48.9613362, lng: 19.2968643 },
    { lat: 48.9613954, lng: 19.2970163 },
    { lat: 48.9615867, lng: 19.2971994 },
    { lat: 48.9617646, lng: 19.297315 },
    { lat: 48.9620454, lng: 19.2976454 },
    { lat: 48.9622324, lng: 19.2981646 },
    { lat: 48.9623267, lng: 19.2983002 },
    { lat: 48.9625781, lng: 19.2988924 },
    { lat: 48.962706, lng: 19.2990239 },
    { lat: 48.9629079, lng: 19.2990978 },
    { lat: 48.9633462, lng: 19.2994492 },
    { lat: 48.9635677, lng: 19.3000275 },
    { lat: 48.9636604, lng: 19.3001048 },
    { lat: 48.963752, lng: 19.3000639 },
    { lat: 48.9639913, lng: 19.2997617 },
    { lat: 48.9643475, lng: 19.2997875 },
    { lat: 48.964739, lng: 19.3001979 },
    { lat: 48.964984, lng: 19.3005425 },
    { lat: 48.9652803, lng: 19.3007983 },
    { lat: 48.9657907, lng: 19.301408 },
    { lat: 48.9659388, lng: 19.3017935 },
    { lat: 48.9660134, lng: 19.3020123 },
    { lat: 48.9661774, lng: 19.3022361 },
    { lat: 48.9662321, lng: 19.3022494 },
    { lat: 48.9664205, lng: 19.3021832 },
    { lat: 48.966781, lng: 19.3021816 },
    { lat: 48.967003, lng: 19.3023455 },
    { lat: 48.9672125, lng: 19.3024642 },
    { lat: 48.967624, lng: 19.3025603 },
    { lat: 48.9677674, lng: 19.302636 },
    { lat: 48.967948, lng: 19.3027833 },
    { lat: 48.968324, lng: 19.303081 },
    { lat: 48.9688037, lng: 19.3035922 },
    { lat: 48.9689742, lng: 19.3039329 },
    { lat: 48.9689085, lng: 19.3044678 },
    { lat: 48.9688789, lng: 19.3054787 },
    { lat: 48.9691681, lng: 19.3058469 },
    { lat: 48.9695627, lng: 19.3072248 },
    { lat: 48.9696684, lng: 19.307671 },
    { lat: 48.9697923, lng: 19.3080261 },
    { lat: 48.9700986, lng: 19.3083039 },
    { lat: 48.9701277, lng: 19.3084388 },
    { lat: 48.970559, lng: 19.3096154 },
    { lat: 48.9706293, lng: 19.3099309 },
    { lat: 48.970707, lng: 19.3108293 },
    { lat: 48.9704648, lng: 19.3113792 },
    { lat: 48.9703253, lng: 19.3117877 },
    { lat: 48.9699073, lng: 19.3123591 },
    { lat: 48.9697845, lng: 19.3127159 },
    { lat: 48.9697797, lng: 19.3134891 },
    { lat: 48.9696807, lng: 19.3140897 },
    { lat: 48.9695623, lng: 19.3146358 },
    { lat: 48.96942, lng: 19.3150298 },
    { lat: 48.9692066, lng: 19.3152792 },
    { lat: 48.9690327, lng: 19.3155824 },
    { lat: 48.968824, lng: 19.3165971 },
    { lat: 48.9696221, lng: 19.3197105 },
    { lat: 48.9700288, lng: 19.3196613 },
    { lat: 48.9714967, lng: 19.3192906 },
    { lat: 48.9723669, lng: 19.318627 },
    { lat: 48.9729023, lng: 19.3178462 },
    { lat: 48.9732633, lng: 19.317496 },
    { lat: 48.9735296, lng: 19.3173846 },
    { lat: 48.974658, lng: 19.317013 },
    { lat: 48.9755995, lng: 19.3167111 },
    { lat: 48.9766079, lng: 19.3160896 },
    { lat: 48.9768218, lng: 19.3159636 },
    { lat: 48.9772146, lng: 19.3158362 },
    { lat: 48.9776952, lng: 19.3157264 },
    { lat: 48.9779615, lng: 19.315783 },
    { lat: 48.9782972, lng: 19.3158499 },
    { lat: 48.9789761, lng: 19.316117 },
    { lat: 48.9798014, lng: 19.3168352 },
    { lat: 48.9802884, lng: 19.3169598 },
    { lat: 48.9807475, lng: 19.317274 },
    { lat: 48.9811822, lng: 19.3174942 },
    { lat: 48.9815689, lng: 19.3174617 },
    { lat: 48.9819418, lng: 19.3176264 },
    { lat: 48.9823247, lng: 19.318189 },
    { lat: 48.9833266, lng: 19.3203441 },
    { lat: 48.9838067, lng: 19.3210805 },
    { lat: 48.9843231, lng: 19.3215166 },
    { lat: 48.9854472, lng: 19.3218571 },
    { lat: 48.9858216, lng: 19.3222169 },
    { lat: 48.9866646, lng: 19.3226922 },
    { lat: 48.9880262, lng: 19.3229346 },
    { lat: 48.9887725, lng: 19.3231284 },
    { lat: 48.9892187, lng: 19.3234097 },
    { lat: 48.9897264, lng: 19.3236111 },
    { lat: 48.9903003, lng: 19.3242085 },
    { lat: 48.9905559, lng: 19.3245638 },
    { lat: 48.9915915, lng: 19.3252277 },
    { lat: 48.9920459, lng: 19.3257048 },
    { lat: 48.9929159, lng: 19.3266062 },
    { lat: 48.9931278, lng: 19.3266829 },
    { lat: 48.9937343, lng: 19.3271307 },
    { lat: 48.9943148, lng: 19.3269476 },
    { lat: 48.9961813, lng: 19.3268987 },
    { lat: 48.9968397, lng: 19.3273799 },
    { lat: 48.9970389, lng: 19.3274928 },
    { lat: 48.9974349, lng: 19.3274698 },
    { lat: 48.997792, lng: 19.3274538 },
    { lat: 48.9991289, lng: 19.3267199 },
    { lat: 48.9995378, lng: 19.326837 },
    { lat: 48.9999502, lng: 19.3265941 },
    { lat: 49.0009254, lng: 19.3263402 },
    { lat: 49.0017419, lng: 19.3260433 },
    { lat: 49.0017773, lng: 19.3260976 },
    { lat: 49.0018335, lng: 19.3261916 },
    { lat: 49.0018943, lng: 19.3262642 },
    { lat: 49.0024391, lng: 19.3259852 },
    { lat: 49.0028954, lng: 19.3249596 },
    { lat: 49.0028413, lng: 19.3245807 },
    { lat: 49.0032933, lng: 19.3246041 },
    { lat: 49.0033791, lng: 19.3245441 },
    { lat: 49.0036609, lng: 19.324347 },
    { lat: 49.0044708, lng: 19.3241255 },
    { lat: 49.0052922, lng: 19.3238129 },
    { lat: 49.0058734, lng: 19.3236125 },
    { lat: 49.0058779, lng: 19.3235194 },
    { lat: 49.0058733, lng: 19.3233968 },
    { lat: 49.0058738, lng: 19.323312 },
    { lat: 49.0065634, lng: 19.3230038 },
    { lat: 49.0069205, lng: 19.3229733 },
    { lat: 49.0077458, lng: 19.32253 },
    { lat: 49.0091801, lng: 19.3223067 },
    { lat: 49.0097986, lng: 19.3218038 },
    { lat: 49.0098471, lng: 19.3218739 },
    { lat: 49.0098991, lng: 19.3219752 },
    { lat: 49.0099447, lng: 19.3220542 },
    { lat: 49.0108306, lng: 19.3217273 },
    { lat: 49.012987, lng: 19.3222603 },
    { lat: 49.0132989, lng: 19.3224664 },
    { lat: 49.0139035, lng: 19.3221685 },
    { lat: 49.0139256, lng: 19.3220616 },
    { lat: 49.0139376, lng: 19.3219371 },
    { lat: 49.0139525, lng: 19.3218465 },
    { lat: 49.0144165, lng: 19.321815 },
    { lat: 49.0157966, lng: 19.3222572 },
    { lat: 49.0161228, lng: 19.3227679 },
    { lat: 49.0170468, lng: 19.3238562 },
    { lat: 49.0175893, lng: 19.3243146 },
    { lat: 49.0180561, lng: 19.3248893 },
    { lat: 49.0188975, lng: 19.3254517 },
    { lat: 49.0195279, lng: 19.3259815 },
    { lat: 49.0199306, lng: 19.3263712 },
    { lat: 49.0199406, lng: 19.3267019 },
    { lat: 49.0204731, lng: 19.3271904 },
    { lat: 49.0215463, lng: 19.3278399 },
    { lat: 49.0222435, lng: 19.3286132 },
    { lat: 49.0238023, lng: 19.3301425 },
    { lat: 49.0247288, lng: 19.3308566 },
    { lat: 49.0256511, lng: 19.3311762 },
    { lat: 49.0258149, lng: 19.3309191 },
    { lat: 49.0267966, lng: 19.3304457 },
    { lat: 49.0271768, lng: 19.3299322 },
    { lat: 49.0282378, lng: 19.3302488 },
    { lat: 49.0286861, lng: 19.330459 },
    { lat: 49.0292971, lng: 19.3305609 },
    { lat: 49.0298834, lng: 19.3309934 },
    { lat: 49.030044, lng: 19.3313371 },
    { lat: 49.0302283, lng: 19.3315878 },
    { lat: 49.0306049, lng: 19.3321661 },
    { lat: 49.0307497, lng: 19.3322016 },
    { lat: 49.0309382, lng: 19.3322769 },
    { lat: 49.031119, lng: 19.3325246 },
    { lat: 49.0312666, lng: 19.332595 },
    { lat: 49.031429, lng: 19.3326915 },
    { lat: 49.0314498, lng: 19.3327036 },
    { lat: 49.0318623, lng: 19.3329903 },
    { lat: 49.0327348, lng: 19.3336864 },
    { lat: 49.0330882, lng: 19.3338435 },
    { lat: 49.0331327, lng: 19.333857 },
    { lat: 49.0335358, lng: 19.3339896 },
    { lat: 49.0340543, lng: 19.3342532 },
    { lat: 49.0340596, lng: 19.3341998 },
    { lat: 49.0340508, lng: 19.3339896 },
    { lat: 49.0340627, lng: 19.3336456 },
    { lat: 49.0340881, lng: 19.3334439 },
    { lat: 49.0341215, lng: 19.3333612 },
    { lat: 49.0341862, lng: 19.3333466 },
    { lat: 49.0341972, lng: 19.3332491 },
    { lat: 49.034753, lng: 19.3333215 },
    { lat: 49.0354681, lng: 19.3329074 },
    { lat: 49.0372282, lng: 19.3318987 },
    { lat: 49.0374002, lng: 19.329535 },
    { lat: 49.0386905, lng: 19.3300313 },
    { lat: 49.0393794, lng: 19.3302026 },
    { lat: 49.0405906, lng: 19.3303649 },
    { lat: 49.0409668, lng: 19.3298369 },
    { lat: 49.0410507, lng: 19.3295123 },
    { lat: 49.0407222, lng: 19.3292397 },
    { lat: 49.0404306, lng: 19.3291754 },
    { lat: 49.0397083, lng: 19.3286787 },
    { lat: 49.0387481, lng: 19.3277278 },
    { lat: 49.037976, lng: 19.3271266 },
    { lat: 49.0379721, lng: 19.3270448 },
    { lat: 49.0377304, lng: 19.3268931 },
    { lat: 49.0375312, lng: 19.3265859 },
    { lat: 49.037534, lng: 19.3264552 },
    { lat: 49.0386518, lng: 19.3270253 },
    { lat: 49.0387918, lng: 19.3274076 },
    { lat: 49.0390644, lng: 19.3275491 },
    { lat: 49.0391612, lng: 19.3276893 },
    { lat: 49.0392348, lng: 19.3276267 },
    { lat: 49.0398918, lng: 19.3279796 },
    { lat: 49.040226, lng: 19.3279021 },
    { lat: 49.0403154, lng: 19.3277892 },
    { lat: 49.0407581, lng: 19.3277134 },
    { lat: 49.0409132, lng: 19.3272782 },
    { lat: 49.0420765, lng: 19.3268968 },
    { lat: 49.0416367, lng: 19.3266572 },
    { lat: 49.041782, lng: 19.3262982 },
    { lat: 49.0430093, lng: 19.3264173 },
    { lat: 49.0432556, lng: 19.3262876 },
    { lat: 49.0433032, lng: 19.326152 },
    { lat: 49.0427006, lng: 19.3257998 },
    { lat: 49.0419402, lng: 19.325067 },
    { lat: 49.0418525, lng: 19.3252139 },
    { lat: 49.0414272, lng: 19.324801 },
    { lat: 49.0414718, lng: 19.3244121 },
    { lat: 49.0411164, lng: 19.3238547 },
    { lat: 49.0405669, lng: 19.3232978 },
    { lat: 49.0406422, lng: 19.3231728 },
    { lat: 49.0387462, lng: 19.321118 },
    { lat: 49.0377977, lng: 19.3170871 },
    { lat: 49.0387668, lng: 19.3167067 },
    { lat: 49.0408127, lng: 19.3151238 },
    { lat: 49.0422791, lng: 19.3139618 },
    { lat: 49.0438622, lng: 19.3158795 },
    { lat: 49.0453417, lng: 19.3159915 },
    { lat: 49.0453933, lng: 19.3155609 },
    { lat: 49.0448483, lng: 19.3151885 },
    { lat: 49.0444969, lng: 19.3148158 },
    { lat: 49.0449609, lng: 19.3143326 },
    { lat: 49.0452706, lng: 19.3142095 },
    { lat: 49.0459366, lng: 19.3146576 },
    { lat: 49.046693, lng: 19.3142591 },
    { lat: 49.0477775, lng: 19.3159278 },
    { lat: 49.0483179, lng: 19.3162018 },
    { lat: 49.0492494, lng: 19.3159993 },
    { lat: 49.0503738, lng: 19.3162779 },
    { lat: 49.0505494, lng: 19.3164152 },
    { lat: 49.0507349, lng: 19.3164768 },
    { lat: 49.0512815, lng: 19.3157387 },
    { lat: 49.0515224, lng: 19.3157576 },
    { lat: 49.0519573, lng: 19.3149236 },
    { lat: 49.0521384, lng: 19.3142278 },
    { lat: 49.0534705, lng: 19.3133495 },
    { lat: 49.0537863, lng: 19.3124988 },
    { lat: 49.0559791, lng: 19.31128 },
    { lat: 49.0559751, lng: 19.3119071 },
    { lat: 49.0559605, lng: 19.3137594 },
    { lat: 49.0559185, lng: 19.3152987 },
    { lat: 49.056913, lng: 19.3152387 },
    { lat: 49.0577855, lng: 19.315052 },
    { lat: 49.0578489, lng: 19.3142727 },
    { lat: 49.060336, lng: 19.3138647 },
    { lat: 49.0606002, lng: 19.3151341 },
    { lat: 49.0607648, lng: 19.3159188 },
    { lat: 49.0608399, lng: 19.3174793 },
    { lat: 49.0605515, lng: 19.3197395 },
    { lat: 49.0611267, lng: 19.3198416 },
    { lat: 49.0614015, lng: 19.3201482 },
    { lat: 49.0624039, lng: 19.32061 },
    { lat: 49.0625648, lng: 19.3206502 },
    { lat: 49.0635951, lng: 19.3208943 },
    { lat: 49.0640272, lng: 19.321027 },
    { lat: 49.0645853, lng: 19.3211026 },
    { lat: 49.0650033, lng: 19.3210269 },
    { lat: 49.0650031, lng: 19.3210471 },
    { lat: 49.0650769, lng: 19.3217927 },
    { lat: 49.0651952, lng: 19.3236529 },
    { lat: 49.0656776, lng: 19.3236007 },
    { lat: 49.0667621, lng: 19.3253964 },
    { lat: 49.0667768, lng: 19.3254677 },
    { lat: 49.06681, lng: 19.32555 },
    { lat: 49.0668821, lng: 19.3255266 },
    { lat: 49.0676581, lng: 19.3249423 },
    { lat: 49.0664676, lng: 19.3308415 },
    { lat: 49.0703084, lng: 19.3322984 },
    { lat: 49.070299, lng: 19.3323584 },
    { lat: 49.070014, lng: 19.3382068 },
    { lat: 49.0699474, lng: 19.3395616 },
    { lat: 49.0699449, lng: 19.3396287 },
    { lat: 49.0700438, lng: 19.339458 },
    { lat: 49.0704794, lng: 19.3389653 },
    { lat: 49.0705843, lng: 19.3388769 },
    { lat: 49.0708084, lng: 19.3387248 },
    { lat: 49.0710659, lng: 19.3385733 },
    { lat: 49.0719851, lng: 19.33804 },
    { lat: 49.0722105, lng: 19.3378705 },
    { lat: 49.0724479, lng: 19.3376464 },
    { lat: 49.0727917, lng: 19.3372925 },
    { lat: 49.0731162, lng: 19.3370273 },
    { lat: 49.0735234, lng: 19.3368184 },
    { lat: 49.0735261, lng: 19.3368314 },
    { lat: 49.0738658, lng: 19.3367227 },
    { lat: 49.0749417, lng: 19.3369522 },
    { lat: 49.074914, lng: 19.336916 },
    { lat: 49.0749052, lng: 19.3368451 },
    { lat: 49.0751376, lng: 19.3369239 },
    { lat: 49.0759868, lng: 19.3366498 },
    { lat: 49.0763766, lng: 19.3360718 },
    { lat: 49.0762545, lng: 19.3351814 },
    { lat: 49.0771726, lng: 19.3352944 },
    { lat: 49.0773995, lng: 19.3350643 },
    { lat: 49.0784088, lng: 19.3349326 },
    { lat: 49.0785697, lng: 19.3349056 },
    { lat: 49.0785614, lng: 19.334134 },
    { lat: 49.0786996, lng: 19.3340406 },
    { lat: 49.0787389, lng: 19.3340735 },
    { lat: 49.0787356, lng: 19.3340272 },
    { lat: 49.078915, lng: 19.3337214 },
    { lat: 49.0789739, lng: 19.3336595 },
    { lat: 49.0791685, lng: 19.3336983 },
    { lat: 49.0793965, lng: 19.3333713 },
    { lat: 49.0796634, lng: 19.3335567 },
    { lat: 49.0798144, lng: 19.333438 },
    { lat: 49.0799843, lng: 19.3332044 },
    { lat: 49.0800272, lng: 19.3331447 },
    { lat: 49.0800842, lng: 19.3332305 },
    { lat: 49.0800789, lng: 19.3331189 },
    { lat: 49.0805812, lng: 19.3330646 },
    { lat: 49.080591, lng: 19.3332724 },
    { lat: 49.0808462, lng: 19.3330448 },
    { lat: 49.0808988, lng: 19.3329983 },
    { lat: 49.0808868, lng: 19.332759 },
    { lat: 49.0812118, lng: 19.3327209 },
    { lat: 49.0820687, lng: 19.3319629 },
    { lat: 49.0824235, lng: 19.3316512 },
    { lat: 49.0824206, lng: 19.3315893 },
    { lat: 49.0825079, lng: 19.3315772 },
    { lat: 49.082694, lng: 19.3314137 },
    { lat: 49.082845, lng: 19.3316294 },
    { lat: 49.0829456, lng: 19.3314901 },
    { lat: 49.0832128, lng: 19.3310883 },
    { lat: 49.0832139, lng: 19.3310857 },
    { lat: 49.0833506, lng: 19.3308794 },
    { lat: 49.0834359, lng: 19.3307766 },
    { lat: 49.0835165, lng: 19.3306239 },
    { lat: 49.084081, lng: 19.3300245 },
    { lat: 49.084017, lng: 19.3295314 },
    { lat: 49.0839709, lng: 19.3288656 },
    { lat: 49.0834257, lng: 19.3283246 },
    { lat: 49.0835051, lng: 19.3276903 },
    { lat: 49.0834859, lng: 19.3270693 },
    { lat: 49.0834617, lng: 19.3266027 },
    { lat: 49.0834391, lng: 19.3263902 },
    { lat: 49.0833147, lng: 19.3258322 },
    { lat: 49.083159, lng: 19.3252585 },
    { lat: 49.0829364, lng: 19.3244611 },
    { lat: 49.0828189, lng: 19.3241701 },
    { lat: 49.0826746, lng: 19.3237596 },
    { lat: 49.0824719, lng: 19.3232404 },
    { lat: 49.0823722, lng: 19.3229698 },
    { lat: 49.0819973, lng: 19.3219805 },
    { lat: 49.0818039, lng: 19.3214617 },
    { lat: 49.0816052, lng: 19.3209572 },
    { lat: 49.0813616, lng: 19.3202857 },
    { lat: 49.081131, lng: 19.319656 },
    { lat: 49.0810513, lng: 19.319448 },
    { lat: 49.0813021, lng: 19.3192939 },
    { lat: 49.0813337, lng: 19.3192688 },
    { lat: 49.0817735, lng: 19.3189663 },
    { lat: 49.0816134, lng: 19.3181343 },
    { lat: 49.0812937, lng: 19.3164053 },
    { lat: 49.0812677, lng: 19.3157142 },
    { lat: 49.0813123, lng: 19.3147512 },
    { lat: 49.0813728, lng: 19.3142246 },
    { lat: 49.0814688, lng: 19.3137039 },
    { lat: 49.0815584, lng: 19.3133119 },
    { lat: 49.0817171, lng: 19.3128109 },
    { lat: 49.0819259, lng: 19.312268 },
    { lat: 49.0821163, lng: 19.3119116 },
    { lat: 49.0826246, lng: 19.3110568 },
    { lat: 49.0831717, lng: 19.3100949 },
    { lat: 49.0839068, lng: 19.3089228 },
    { lat: 49.0833633, lng: 19.3081471 },
    { lat: 49.0831988, lng: 19.3070105 },
    { lat: 49.0835183, lng: 19.3065063 },
    { lat: 49.0836764, lng: 19.3062126 },
    { lat: 49.0839544, lng: 19.3056956 },
    { lat: 49.0842223, lng: 19.3052403 },
    { lat: 49.0845992, lng: 19.3045999 },
    { lat: 49.0849439, lng: 19.3040165 },
    { lat: 49.0853782, lng: 19.3033721 },
    { lat: 49.0857305, lng: 19.3029255 },
    { lat: 49.0860194, lng: 19.302595 },
    { lat: 49.0860546, lng: 19.3026714 },
    { lat: 49.086925, lng: 19.3016924 },
    { lat: 49.0870945, lng: 19.3014999 },
    { lat: 49.0871871, lng: 19.3012807 },
    { lat: 49.0873062, lng: 19.3005981 },
    { lat: 49.0873259, lng: 19.299957 },
    { lat: 49.0872785, lng: 19.299603 },
    { lat: 49.0870949, lng: 19.2989505 },
    { lat: 49.0873738, lng: 19.2986903 },
    { lat: 49.087412, lng: 19.2986602 },
    { lat: 49.0874379, lng: 19.2986407 },
    { lat: 49.0874722, lng: 19.2986045 },
    { lat: 49.087735, lng: 19.2980676 },
    { lat: 49.0877498, lng: 19.2979506 },
    { lat: 49.0879114, lng: 19.2978691 },
    { lat: 49.0879428, lng: 19.2978135 },
    { lat: 49.0897378, lng: 19.294892 },
    { lat: 49.0900324, lng: 19.2944089 },
    { lat: 49.0904076, lng: 19.2938116 },
    { lat: 49.0909331, lng: 19.2929282 },
    { lat: 49.0912361, lng: 19.2924187 },
    { lat: 49.092369, lng: 19.2914124 },
    { lat: 49.0943073, lng: 19.2874268 },
    { lat: 49.0944814, lng: 19.2870595 },
    { lat: 49.0948771, lng: 19.2867554 },
    { lat: 49.0962574, lng: 19.2859447 },
    { lat: 49.0967153, lng: 19.2845576 },
    { lat: 49.0974889, lng: 19.2835741 },
    { lat: 49.0977624, lng: 19.2830195 },
    { lat: 49.0979366, lng: 19.2827499 },
    { lat: 49.0996669, lng: 19.2818377 },
    { lat: 49.0999222, lng: 19.2816599 },
    { lat: 49.1001106, lng: 19.2813067 },
    { lat: 49.1001905, lng: 19.2813281 },
    { lat: 49.1002998, lng: 19.2815397 },
    { lat: 49.1005409, lng: 19.281513 },
    { lat: 49.1007782, lng: 19.2811026 },
    { lat: 49.1010269, lng: 19.2808108 },
    { lat: 49.1015208, lng: 19.2797774 },
    { lat: 49.1018555, lng: 19.2790455 },
    { lat: 49.1030555, lng: 19.2789911 },
    { lat: 49.1039509, lng: 19.2781999 },
    { lat: 49.1057097, lng: 19.2769588 },
    { lat: 49.1064617, lng: 19.2766593 },
    { lat: 49.106945, lng: 19.2764177 },
    { lat: 49.1074917, lng: 19.2764464 },
    { lat: 49.1075604, lng: 19.2761593 },
    { lat: 49.1075, lng: 19.2756012 },
    { lat: 49.1074382, lng: 19.275208 },
    { lat: 49.107108, lng: 19.2743564 },
    { lat: 49.107109, lng: 19.2743524 },
    { lat: 49.1064982, lng: 19.2729157 },
    { lat: 49.1069654, lng: 19.272102 },
    { lat: 49.1068224, lng: 19.2717981 },
    { lat: 49.1068573, lng: 19.2710395 },
    { lat: 49.1069342, lng: 19.2707173 },
    { lat: 49.1071891, lng: 19.2706988 },
    { lat: 49.1082875, lng: 19.2713267 },
    { lat: 49.1087312, lng: 19.2715074 },
    { lat: 49.1083784, lng: 19.2719094 },
    { lat: 49.1084841, lng: 19.2723273 },
    { lat: 49.1089313, lng: 19.2730313 },
    { lat: 49.1090927, lng: 19.2730591 },
    { lat: 49.1092357, lng: 19.2728678 },
    { lat: 49.1094486, lng: 19.2728657 },
    { lat: 49.1089821, lng: 19.2717807 },
    { lat: 49.1089626, lng: 19.2715619 },
    { lat: 49.1088631, lng: 19.2712474 },
    { lat: 49.1088046, lng: 19.2710859 },
    { lat: 49.1089237, lng: 19.2707227 },
    { lat: 49.1089474, lng: 19.2690969 },
    { lat: 49.1096234, lng: 19.2689946 },
    { lat: 49.1099722, lng: 19.2690354 },
    { lat: 49.1102098, lng: 19.2692865 },
    { lat: 49.1102829, lng: 19.2694467 },
    { lat: 49.1103141, lng: 19.2695364 },
    { lat: 49.1094251, lng: 19.270484 },
    { lat: 49.1094785, lng: 19.2709198 },
    { lat: 49.1095355, lng: 19.2710502 },
    { lat: 49.1097094, lng: 19.2709137 },
    { lat: 49.1098525, lng: 19.2706571 },
    { lat: 49.1101898, lng: 19.2704503 },
    { lat: 49.1102508, lng: 19.2705461 },
    { lat: 49.1102519, lng: 19.2705485 },
    { lat: 49.1105189, lng: 19.270779 },
    { lat: 49.1106784, lng: 19.2707458 },
    { lat: 49.1111928, lng: 19.2706129 },
    { lat: 49.1113571, lng: 19.2698992 },
    { lat: 49.111217, lng: 19.2692107 },
    { lat: 49.110931, lng: 19.2695929 },
    { lat: 49.110829, lng: 19.2696265 },
    { lat: 49.1107896, lng: 19.2693222 },
    { lat: 49.1107739, lng: 19.2690185 },
    { lat: 49.1109072, lng: 19.2689303 },
    { lat: 49.1111746, lng: 19.2687247 },
    { lat: 49.1114194, lng: 19.2685141 },
    { lat: 49.1116272, lng: 19.2684792 },
    { lat: 49.1118733, lng: 19.2683666 },
    { lat: 49.112434, lng: 19.2682138 },
    { lat: 49.1130834, lng: 19.2683346 },
    { lat: 49.1134515, lng: 19.2687784 },
    { lat: 49.1136312, lng: 19.2692114 },
    { lat: 49.1137439, lng: 19.2694713 },
    { lat: 49.1139291, lng: 19.2695 },
    { lat: 49.1143142, lng: 19.270251 },
    { lat: 49.1141667, lng: 19.2705591 },
    { lat: 49.113913, lng: 19.270865 },
    { lat: 49.1130526, lng: 19.2727434 },
    { lat: 49.1128234, lng: 19.2734624 },
    { lat: 49.1129232, lng: 19.2736636 },
    { lat: 49.1134095, lng: 19.2736284 },
    { lat: 49.1134764, lng: 19.2738279 },
    { lat: 49.1138202, lng: 19.2739945 },
    { lat: 49.1141263, lng: 19.2738167 },
    { lat: 49.1142819, lng: 19.2735148 },
    { lat: 49.1146714, lng: 19.2736001 },
    { lat: 49.1149022, lng: 19.2734968 },
    { lat: 49.1150531, lng: 19.2730977 },
    { lat: 49.1151042, lng: 19.2728409 },
    { lat: 49.1154541, lng: 19.2726909 },
    { lat: 49.1156687, lng: 19.2726984 },
    { lat: 49.1158126, lng: 19.2729977 },
    { lat: 49.1156693, lng: 19.2732762 },
    { lat: 49.1158807, lng: 19.2733603 },
    { lat: 49.1160229, lng: 19.273627 },
    { lat: 49.1164635, lng: 19.2735753 },
    { lat: 49.1167126, lng: 19.2734372 },
    { lat: 49.1168473, lng: 19.2735414 },
    { lat: 49.1169064, lng: 19.2733165 },
    { lat: 49.1173953, lng: 19.273924 },
    { lat: 49.1178175, lng: 19.2740815 },
    { lat: 49.1180973, lng: 19.2739724 },
    { lat: 49.1188256, lng: 19.273501 },
    { lat: 49.1192327, lng: 19.271858 },
    { lat: 49.119384, lng: 19.271603 },
    { lat: 49.1197476, lng: 19.2709791 },
    { lat: 49.1199811, lng: 19.2704865 },
    { lat: 49.1201242, lng: 19.2701561 },
    { lat: 49.1207578, lng: 19.2687704 },
    { lat: 49.121517, lng: 19.2669698 },
  ],
  LiptovskáTeplá: [
    { lat: 49.0961032, lng: 19.4223302 },
    { lat: 49.0962628, lng: 19.423117 },
    { lat: 49.0970638, lng: 19.422122 },
    { lat: 49.0985178, lng: 19.4215197 },
    { lat: 49.0980455, lng: 19.4233636 },
    { lat: 49.0979175, lng: 19.4239521 },
    { lat: 49.0978152, lng: 19.4248556 },
    { lat: 49.09776, lng: 19.4254873 },
    { lat: 49.0977398, lng: 19.4265575 },
    { lat: 49.0977707, lng: 19.4271 },
    { lat: 49.0978506, lng: 19.4279469 },
    { lat: 49.0979083, lng: 19.4287761 },
    { lat: 49.0980076, lng: 19.4288046 },
    { lat: 49.098038, lng: 19.4288286 },
    { lat: 49.098499, lng: 19.4288556 },
    { lat: 49.1000665, lng: 19.4291712 },
    { lat: 49.100341, lng: 19.4290964 },
    { lat: 49.100485, lng: 19.4290571 },
    { lat: 49.1012826, lng: 19.4290543 },
    { lat: 49.10155, lng: 19.4291586 },
    { lat: 49.1046852, lng: 19.4295058 },
    { lat: 49.1046803, lng: 19.429761 },
    { lat: 49.1050628, lng: 19.4297716 },
    { lat: 49.1051606, lng: 19.4297705 },
    { lat: 49.1059102, lng: 19.429871 },
    { lat: 49.1059533, lng: 19.4294603 },
    { lat: 49.1069935, lng: 19.429635 },
    { lat: 49.1088245, lng: 19.4304352 },
    { lat: 49.1109822, lng: 19.4304522 },
    { lat: 49.1119695, lng: 19.4308898 },
    { lat: 49.1132978, lng: 19.4316403 },
    { lat: 49.1154577, lng: 19.4312438 },
    { lat: 49.1157056, lng: 19.4312938 },
    { lat: 49.1158263, lng: 19.4322664 },
    { lat: 49.1158412, lng: 19.4323472 },
    { lat: 49.1159076, lng: 19.4328322 },
    { lat: 49.1166086, lng: 19.4341709 },
    { lat: 49.1170597, lng: 19.4346155 },
    { lat: 49.1173513, lng: 19.4352425 },
    { lat: 49.1175865, lng: 19.4364748 },
    { lat: 49.1180561, lng: 19.4374676 },
    { lat: 49.1185875, lng: 19.4378918 },
    { lat: 49.1185844, lng: 19.4378709 },
    { lat: 49.1186593, lng: 19.4382507 },
    { lat: 49.1189779, lng: 19.4404839 },
    { lat: 49.1201264, lng: 19.4404626 },
    { lat: 49.1203753, lng: 19.4412377 },
    { lat: 49.1209306, lng: 19.4415018 },
    { lat: 49.1209601, lng: 19.4416505 },
    { lat: 49.1213358, lng: 19.4418335 },
    { lat: 49.1216727, lng: 19.442342 },
    { lat: 49.1226782, lng: 19.4431298 },
    { lat: 49.1234584, lng: 19.4437716 },
    { lat: 49.1244133, lng: 19.4453128 },
    { lat: 49.124907, lng: 19.4446395 },
    { lat: 49.1253448, lng: 19.4437715 },
    { lat: 49.126284, lng: 19.4446611 },
    { lat: 49.1268384, lng: 19.445243 },
    { lat: 49.1289953, lng: 19.4475117 },
    { lat: 49.1298245, lng: 19.4475608 },
    { lat: 49.131692, lng: 19.447655 },
    { lat: 49.131633, lng: 19.446704 },
    { lat: 49.132283, lng: 19.445933 },
    { lat: 49.133061, lng: 19.444547 },
    { lat: 49.133365, lng: 19.442924 },
    { lat: 49.133421, lng: 19.442701 },
    { lat: 49.133677, lng: 19.441687 },
    { lat: 49.134005, lng: 19.441621 },
    { lat: 49.135765, lng: 19.440831 },
    { lat: 49.136357, lng: 19.440182 },
    { lat: 49.136415, lng: 19.440117 },
    { lat: 49.13644, lng: 19.440091 },
    { lat: 49.136884, lng: 19.438829 },
    { lat: 49.137267, lng: 19.437439 },
    { lat: 49.137601, lng: 19.436164 },
    { lat: 49.138108, lng: 19.435039 },
    { lat: 49.138804, lng: 19.433819 },
    { lat: 49.139397, lng: 19.432887 },
    { lat: 49.13971, lng: 19.43255 },
    { lat: 49.140183, lng: 19.432037 },
    { lat: 49.14059, lng: 19.431875 },
    { lat: 49.140878, lng: 19.43208 },
    { lat: 49.141138, lng: 19.43236 },
    { lat: 49.142187, lng: 19.433129 },
    { lat: 49.142804, lng: 19.433199 },
    { lat: 49.143094, lng: 19.432941 },
    { lat: 49.143316, lng: 19.432369 },
    { lat: 49.143335, lng: 19.432285 },
    { lat: 49.143487, lng: 19.431909 },
    { lat: 49.14377, lng: 19.431578 },
    { lat: 49.1436761, lng: 19.4315011 },
    { lat: 49.1441184, lng: 19.4285473 },
    { lat: 49.1443263, lng: 19.4272 },
    { lat: 49.1449442, lng: 19.425266 },
    { lat: 49.1449442, lng: 19.4232251 },
    { lat: 49.1451905, lng: 19.4224113 },
    { lat: 49.1454183, lng: 19.4218616 },
    { lat: 49.1455427, lng: 19.4213122 },
    { lat: 49.1454874, lng: 19.4209197 },
    { lat: 49.1450568, lng: 19.4189026 },
    { lat: 49.1438249, lng: 19.4188992 },
    { lat: 49.1431587, lng: 19.4183129 },
    { lat: 49.1423138, lng: 19.4182461 },
    { lat: 49.1368208, lng: 19.4191049 },
    { lat: 49.1375564, lng: 19.4208239 },
    { lat: 49.1376746, lng: 19.4221042 },
    { lat: 49.1377622, lng: 19.4226054 },
    { lat: 49.1377572, lng: 19.4237151 },
    { lat: 49.1376261, lng: 19.4245315 },
    { lat: 49.1375313, lng: 19.4249415 },
    { lat: 49.137272, lng: 19.4250379 },
    { lat: 49.1363211, lng: 19.4274915 },
    { lat: 49.1362772, lng: 19.4275423 },
    { lat: 49.1355285, lng: 19.427238 },
    { lat: 49.1349197, lng: 19.4269689 },
    { lat: 49.1345122, lng: 19.4268786 },
    { lat: 49.1340383, lng: 19.4265482 },
    { lat: 49.1335695, lng: 19.426235 },
    { lat: 49.1331966, lng: 19.4259959 },
    { lat: 49.1327992, lng: 19.4256598 },
    { lat: 49.1326385, lng: 19.4254537 },
    { lat: 49.1328232, lng: 19.426712 },
    { lat: 49.132858, lng: 19.4276338 },
    { lat: 49.1317092, lng: 19.4302708 },
    { lat: 49.1311395, lng: 19.4317132 },
    { lat: 49.130422, lng: 19.4345652 },
    { lat: 49.1290548, lng: 19.433658 },
    { lat: 49.1282466, lng: 19.4326821 },
    { lat: 49.1271312, lng: 19.4317861 },
    { lat: 49.1262196, lng: 19.4312507 },
    { lat: 49.1254956, lng: 19.4309944 },
    { lat: 49.1245084, lng: 19.4308482 },
    { lat: 49.1233133, lng: 19.4302069 },
    { lat: 49.1225761, lng: 19.42981 },
    { lat: 49.1198999, lng: 19.4286229 },
    { lat: 49.1189377, lng: 19.4277991 },
    { lat: 49.118271, lng: 19.4270593 },
    { lat: 49.1180908, lng: 19.4264991 },
    { lat: 49.1180082, lng: 19.426008 },
    { lat: 49.1178245, lng: 19.4249658 },
    { lat: 49.118047, lng: 19.4240507 },
    { lat: 49.1183339, lng: 19.4230942 },
    { lat: 49.118712, lng: 19.422481 },
    { lat: 49.1194266, lng: 19.4215888 },
    { lat: 49.118031, lng: 19.4196606 },
    { lat: 49.1183323, lng: 19.4191115 },
    { lat: 49.1191721, lng: 19.4183636 },
    { lat: 49.1186554, lng: 19.4175991 },
    { lat: 49.1182027, lng: 19.4171213 },
    { lat: 49.1180003, lng: 19.4164319 },
    { lat: 49.1182874, lng: 19.4158404 },
    { lat: 49.1184512, lng: 19.4154644 },
    { lat: 49.1184587, lng: 19.4153557 },
    { lat: 49.1180171, lng: 19.415385 },
    { lat: 49.1166332, lng: 19.4148236 },
    { lat: 49.1164378, lng: 19.4135078 },
    { lat: 49.1165193, lng: 19.4120626 },
    { lat: 49.116639, lng: 19.4112452 },
    { lat: 49.1166496, lng: 19.4111592 },
    { lat: 49.1168464, lng: 19.4098669 },
    { lat: 49.1162268, lng: 19.4096303 },
    { lat: 49.1163419, lng: 19.4090796 },
    { lat: 49.1168479, lng: 19.4065635 },
    { lat: 49.1176628, lng: 19.4057174 },
    { lat: 49.1190405, lng: 19.4063354 },
    { lat: 49.1192275, lng: 19.4068426 },
    { lat: 49.1195857, lng: 19.4078558 },
    { lat: 49.119296, lng: 19.4051716 },
    { lat: 49.1185218, lng: 19.4013479 },
    { lat: 49.1183676, lng: 19.4009437 },
    { lat: 49.1180691, lng: 19.4003155 },
    { lat: 49.1178027, lng: 19.3998752 },
    { lat: 49.1174117, lng: 19.3993646 },
    { lat: 49.1173218, lng: 19.3982116 },
    { lat: 49.1173074, lng: 19.3977361 },
    { lat: 49.1172836, lng: 19.3971051 },
    { lat: 49.1164914, lng: 19.3960642 },
    { lat: 49.1163629, lng: 19.3951468 },
    { lat: 49.1163578, lng: 19.3933234 },
    { lat: 49.116366, lng: 19.392803 },
    { lat: 49.1165504, lng: 19.3919973 },
    { lat: 49.1168487, lng: 19.3915545 },
    { lat: 49.1170892, lng: 19.3912276 },
    { lat: 49.116195, lng: 19.3905879 },
    { lat: 49.1152807, lng: 19.3898567 },
    { lat: 49.1142417, lng: 19.390917 },
    { lat: 49.1137472, lng: 19.3911404 },
    { lat: 49.1130254, lng: 19.3918486 },
    { lat: 49.1126415, lng: 19.3920284 },
    { lat: 49.1116508, lng: 19.3924358 },
    { lat: 49.111227, lng: 19.3926497 },
    { lat: 49.1110272, lng: 19.3928358 },
    { lat: 49.1108878, lng: 19.3929146 },
    { lat: 49.1096691, lng: 19.3934122 },
    { lat: 49.1088795, lng: 19.3935136 },
    { lat: 49.1086679, lng: 19.3935941 },
    { lat: 49.1085632, lng: 19.3939356 },
    { lat: 49.1061002, lng: 19.3931926 },
    { lat: 49.104821, lng: 19.3924684 },
    { lat: 49.1025099, lng: 19.394063 },
    { lat: 49.1012926, lng: 19.394638 },
    { lat: 49.0998433, lng: 19.3954823 },
    { lat: 49.099613, lng: 19.3956414 },
    { lat: 49.099408, lng: 19.3949897 },
    { lat: 49.0991994, lng: 19.3943861 },
    { lat: 49.0987638, lng: 19.3934066 },
    { lat: 49.0984589, lng: 19.3926683 },
    { lat: 49.0980844, lng: 19.3931185 },
    { lat: 49.0976242, lng: 19.3933266 },
    { lat: 49.0970823, lng: 19.3932109 },
    { lat: 49.0965645, lng: 19.3933433 },
    { lat: 49.0964023, lng: 19.3933957 },
    { lat: 49.0964039, lng: 19.3934042 },
    { lat: 49.0972887, lng: 19.3998266 },
    { lat: 49.0967207, lng: 19.3997529 },
    { lat: 49.0965474, lng: 19.3997342 },
    { lat: 49.0955029, lng: 19.4017661 },
    { lat: 49.0953907, lng: 19.4020451 },
    { lat: 49.0946845, lng: 19.4037878 },
    { lat: 49.0944293, lng: 19.405106 },
    { lat: 49.0944068, lng: 19.4059552 },
    { lat: 49.094617, lng: 19.4076125 },
    { lat: 49.0948198, lng: 19.4086967 },
    { lat: 49.0948418, lng: 19.4087573 },
    { lat: 49.0962811, lng: 19.4126116 },
    { lat: 49.0968121, lng: 19.4141671 },
    { lat: 49.0968811, lng: 19.4143919 },
    { lat: 49.0972357, lng: 19.4154706 },
    { lat: 49.0975002, lng: 19.4165777 },
    { lat: 49.0979069, lng: 19.4172779 },
    { lat: 49.0980748, lng: 19.4176391 },
    { lat: 49.0983466, lng: 19.4182273 },
    { lat: 49.0983842, lng: 19.4185997 },
    { lat: 49.0984891, lng: 19.4196424 },
    { lat: 49.0984801, lng: 19.420418 },
    { lat: 49.0982114, lng: 19.4207483 },
    { lat: 49.0968448, lng: 19.4213372 },
    { lat: 49.0961032, lng: 19.4223302 },
  ],
};

export default UnitsRužomberok;
