const UnitsPrievidza = {
  Prievidza: [
    { lat: 48.7460427, lng: 18.7030989 },
    { lat: 48.7466139, lng: 18.7023001 },
    { lat: 48.7482202, lng: 18.7013932 },
    { lat: 48.7491803, lng: 18.7014003 },
    { lat: 48.7493805, lng: 18.7014553 },
    { lat: 48.7494515, lng: 18.7014748 },
    { lat: 48.7495328, lng: 18.7014972 },
    { lat: 48.7497445, lng: 18.7018096 },
    { lat: 48.7514212, lng: 18.7020167 },
    { lat: 48.7517463, lng: 18.7024045 },
    { lat: 48.7523935, lng: 18.7032939 },
    { lat: 48.7549496, lng: 18.7002237 },
    { lat: 48.7558911, lng: 18.6990938 },
    { lat: 48.757332, lng: 18.6979395 },
    { lat: 48.7573889, lng: 18.697908 },
    { lat: 48.7574995, lng: 18.6978459 },
    { lat: 48.7576135, lng: 18.6977822 },
    { lat: 48.7576243, lng: 18.6977757 },
    { lat: 48.7576857, lng: 18.6977414 },
    { lat: 48.7579793, lng: 18.6975771 },
    { lat: 48.757988, lng: 18.6975721 },
    { lat: 48.7586232, lng: 18.6972156 },
    { lat: 48.7586738, lng: 18.6972137 },
    { lat: 48.758711, lng: 18.6972126 },
    { lat: 48.7595544, lng: 18.697188 },
    { lat: 48.7602029, lng: 18.6974438 },
    { lat: 48.7607181, lng: 18.6973969 },
    { lat: 48.7615039, lng: 18.6971802 },
    { lat: 48.7618006, lng: 18.6969054 },
    { lat: 48.7621534, lng: 18.6966059 },
    { lat: 48.7626787, lng: 18.6963658 },
    { lat: 48.7633986, lng: 18.695906 },
    { lat: 48.7638913, lng: 18.6954593 },
    { lat: 48.7641777, lng: 18.6950116 },
    { lat: 48.764342, lng: 18.6945156 },
    { lat: 48.764277, lng: 18.694185 },
    { lat: 48.764259, lng: 18.6940992 },
    { lat: 48.7642228, lng: 18.6939925 },
    { lat: 48.7639588, lng: 18.693227 },
    { lat: 48.764634, lng: 18.6928373 },
    { lat: 48.7647597, lng: 18.6929334 },
    { lat: 48.7653774, lng: 18.6923964 },
    { lat: 48.7657815, lng: 18.6917667 },
    { lat: 48.7663128, lng: 18.6913456 },
    { lat: 48.7666911, lng: 18.690759 },
    { lat: 48.7664508, lng: 18.690227 },
    { lat: 48.7662305, lng: 18.6896913 },
    { lat: 48.7669553, lng: 18.6888575 },
    { lat: 48.767525, lng: 18.6880106 },
    { lat: 48.76793, lng: 18.6877157 },
    { lat: 48.7685521, lng: 18.6872628 },
    { lat: 48.7693133, lng: 18.6868576 },
    { lat: 48.7699235, lng: 18.6865434 },
    { lat: 48.7709925, lng: 18.6876001 },
    { lat: 48.7713904, lng: 18.6880617 },
    { lat: 48.7719767, lng: 18.6870421 },
    { lat: 48.7729148, lng: 18.6850447 },
    { lat: 48.7726268, lng: 18.6839993 },
    { lat: 48.7721404, lng: 18.6836327 },
    { lat: 48.7727194, lng: 18.6825825 },
    { lat: 48.7738649, lng: 18.6804771 },
    { lat: 48.7737005, lng: 18.6783843 },
    { lat: 48.7737122, lng: 18.6779474 },
    { lat: 48.7734462, lng: 18.6778869 },
    { lat: 48.7730482, lng: 18.6777209 },
    { lat: 48.7734355, lng: 18.6764186 },
    { lat: 48.7733618, lng: 18.676411 },
    { lat: 48.7734457, lng: 18.6751488 },
    { lat: 48.7735873, lng: 18.6748013 },
    { lat: 48.773672, lng: 18.6747382 },
    { lat: 48.77383, lng: 18.6745298 },
    { lat: 48.7738246, lng: 18.6743867 },
    { lat: 48.7737683, lng: 18.6742173 },
    { lat: 48.7736496, lng: 18.6737487 },
    { lat: 48.7736477, lng: 18.6730339 },
    { lat: 48.7737767, lng: 18.6730646 },
    { lat: 48.7739587, lng: 18.6727975 },
    { lat: 48.7742355, lng: 18.6729335 },
    { lat: 48.774372, lng: 18.672702 },
    { lat: 48.7745085, lng: 18.6726817 },
    { lat: 48.7746154, lng: 18.6724005 },
    { lat: 48.7756522, lng: 18.6703763 },
    { lat: 48.7758095, lng: 18.6698998 },
    { lat: 48.7758542, lng: 18.6697649 },
    { lat: 48.775923, lng: 18.669555 },
    { lat: 48.7759973, lng: 18.6691295 },
    { lat: 48.7794635, lng: 18.6698777 },
    { lat: 48.7799441, lng: 18.6699864 },
    { lat: 48.7805529, lng: 18.6697717 },
    { lat: 48.7809367, lng: 18.6695222 },
    { lat: 48.7813973, lng: 18.6693826 },
    { lat: 48.7820788, lng: 18.6691126 },
    { lat: 48.7833902, lng: 18.6686439 },
    { lat: 48.7837589, lng: 18.6684512 },
    { lat: 48.784081, lng: 18.6682319 },
    { lat: 48.7844596, lng: 18.6679078 },
    { lat: 48.7845823, lng: 18.6677784 },
    { lat: 48.7848151, lng: 18.667407 },
    { lat: 48.7848225, lng: 18.6673959 },
    { lat: 48.7848495, lng: 18.6673529 },
    { lat: 48.7847857, lng: 18.6672988 },
    { lat: 48.7846605, lng: 18.6670661 },
    { lat: 48.7845573, lng: 18.6669056 },
    { lat: 48.7844205, lng: 18.6667512 },
    { lat: 48.7841411, lng: 18.666464 },
    { lat: 48.7841268, lng: 18.6664282 },
    { lat: 48.7841006, lng: 18.6663602 },
    { lat: 48.7840809, lng: 18.6663079 },
    { lat: 48.7840552, lng: 18.6662263 },
    { lat: 48.7839792, lng: 18.6659877 },
    { lat: 48.7838441, lng: 18.6653773 },
    { lat: 48.784001, lng: 18.665001 },
    { lat: 48.7842099, lng: 18.6646245 },
    { lat: 48.7844751, lng: 18.6642899 },
    { lat: 48.7847246, lng: 18.6642086 },
    { lat: 48.7852529, lng: 18.6641125 },
    { lat: 48.78591, lng: 18.6643777 },
    { lat: 48.7859986, lng: 18.6644137 },
    { lat: 48.7865531, lng: 18.6646379 },
    { lat: 48.7879611, lng: 18.6665708 },
    { lat: 48.7888722, lng: 18.6685762 },
    { lat: 48.7902414, lng: 18.6700138 },
    { lat: 48.79077, lng: 18.6704516 },
    { lat: 48.7908172, lng: 18.6704914 },
    { lat: 48.7914532, lng: 18.6710175 },
    { lat: 48.7918111, lng: 18.6717668 },
    { lat: 48.7925158, lng: 18.6723128 },
    { lat: 48.7925582, lng: 18.6723455 },
    { lat: 48.7943181, lng: 18.6737113 },
    { lat: 48.795567, lng: 18.672025 },
    { lat: 48.7964182, lng: 18.6700732 },
    { lat: 48.7975185, lng: 18.6692386 },
    { lat: 48.7988818, lng: 18.6677355 },
    { lat: 48.7989487, lng: 18.6676783 },
    { lat: 48.7989664, lng: 18.6675012 },
    { lat: 48.7989107, lng: 18.6673453 },
    { lat: 48.7990623, lng: 18.6669325 },
    { lat: 48.7991096, lng: 18.6665904 },
    { lat: 48.7991669, lng: 18.6664409 },
    { lat: 48.7992412, lng: 18.6664583 },
    { lat: 48.7992824, lng: 18.6664477 },
    { lat: 48.7993362, lng: 18.666147 },
    { lat: 48.7992816, lng: 18.6660095 },
    { lat: 48.7991416, lng: 18.6655212 },
    { lat: 48.7990044, lng: 18.6654458 },
    { lat: 48.7990276, lng: 18.6650954 },
    { lat: 48.7990153, lng: 18.664887 },
    { lat: 48.7990898, lng: 18.6646373 },
    { lat: 48.7990215, lng: 18.6642229 },
    { lat: 48.7988927, lng: 18.6637949 },
    { lat: 48.7988843, lng: 18.6636799 },
    { lat: 48.7987244, lng: 18.663219 },
    { lat: 48.79857, lng: 18.6631148 },
    { lat: 48.7984905, lng: 18.6629145 },
    { lat: 48.798565, lng: 18.6627053 },
    { lat: 48.7985544, lng: 18.6624851 },
    { lat: 48.7986812, lng: 18.6622627 },
    { lat: 48.7986278, lng: 18.661981 },
    { lat: 48.7987034, lng: 18.6618342 },
    { lat: 48.7986115, lng: 18.6614641 },
    { lat: 48.7984027, lng: 18.6613112 },
    { lat: 48.7984247, lng: 18.6609654 },
    { lat: 48.7983762, lng: 18.6607964 },
    { lat: 48.7983428, lng: 18.660613 },
    { lat: 48.7982448, lng: 18.6603843 },
    { lat: 48.797944, lng: 18.6602209 },
    { lat: 48.7979179, lng: 18.6599846 },
    { lat: 48.7976756, lng: 18.6597552 },
    { lat: 48.7974126, lng: 18.6593086 },
    { lat: 48.7973715, lng: 18.6589833 },
    { lat: 48.7972168, lng: 18.6587253 },
    { lat: 48.7969902, lng: 18.6585806 },
    { lat: 48.7970332, lng: 18.6583507 },
    { lat: 48.7970396, lng: 18.6582594 },
    { lat: 48.796981, lng: 18.6581328 },
    { lat: 48.7969021, lng: 18.6581342 },
    { lat: 48.7968865, lng: 18.6580517 },
    { lat: 48.7969148, lng: 18.6576751 },
    { lat: 48.7967741, lng: 18.6573228 },
    { lat: 48.7966661, lng: 18.6573053 },
    { lat: 48.7966242, lng: 18.6571759 },
    { lat: 48.7967312, lng: 18.6570042 },
    { lat: 48.7967016, lng: 18.6568717 },
    { lat: 48.796645, lng: 18.6567416 },
    { lat: 48.7965823, lng: 18.6567718 },
    { lat: 48.7965351, lng: 18.656752 },
    { lat: 48.796498, lng: 18.6566063 },
    { lat: 48.7965335, lng: 18.6564902 },
    { lat: 48.7964699, lng: 18.6564194 },
    { lat: 48.7964522, lng: 18.6562787 },
    { lat: 48.7964942, lng: 18.6560925 },
    { lat: 48.7964117, lng: 18.6560268 },
    { lat: 48.7963543, lng: 18.6558805 },
    { lat: 48.7963755, lng: 18.6558364 },
    { lat: 48.7964241, lng: 18.6557938 },
    { lat: 48.7964223, lng: 18.6557604 },
    { lat: 48.7962938, lng: 18.6556561 },
    { lat: 48.7961673, lng: 18.655716 },
    { lat: 48.796057, lng: 18.6556574 },
    { lat: 48.7960378, lng: 18.6556158 },
    { lat: 48.7959845, lng: 18.6555046 },
    { lat: 48.7960374, lng: 18.6552907 },
    { lat: 48.7960367, lng: 18.6549604 },
    { lat: 48.7961666, lng: 18.6536787 },
    { lat: 48.7956512, lng: 18.6532539 },
    { lat: 48.7956068, lng: 18.6525109 },
    { lat: 48.7950384, lng: 18.6520254 },
    { lat: 48.7947675, lng: 18.6509887 },
    { lat: 48.7944487, lng: 18.6504455 },
    { lat: 48.7942656, lng: 18.6500185 },
    { lat: 48.7941899, lng: 18.6485172 },
    { lat: 48.7942419, lng: 18.6474877 },
    { lat: 48.7943526, lng: 18.6471969 },
    { lat: 48.7944681, lng: 18.6468873 },
    { lat: 48.794452, lng: 18.6467351 },
    { lat: 48.794443, lng: 18.6466514 },
    { lat: 48.7944019, lng: 18.6462617 },
    { lat: 48.7947357, lng: 18.6454385 },
    { lat: 48.7949544, lng: 18.6449316 },
    { lat: 48.7953232, lng: 18.6440699 },
    { lat: 48.7956753, lng: 18.6432511 },
    { lat: 48.7961197, lng: 18.6422211 },
    { lat: 48.7962592, lng: 18.6418566 },
    { lat: 48.7963772, lng: 18.6414686 },
    { lat: 48.7964404, lng: 18.6412577 },
    { lat: 48.7964698, lng: 18.6412793 },
    { lat: 48.7967529, lng: 18.6399344 },
    { lat: 48.796732, lng: 18.6396922 },
    { lat: 48.7967015, lng: 18.6396273 },
    { lat: 48.7966015, lng: 18.6394655 },
    { lat: 48.7956677, lng: 18.6384564 },
    { lat: 48.7952181, lng: 18.637999 },
    { lat: 48.7946471, lng: 18.6373642 },
    { lat: 48.7940453, lng: 18.6370348 },
    { lat: 48.793089, lng: 18.6365971 },
    { lat: 48.7929676, lng: 18.6365408 },
    { lat: 48.7924777, lng: 18.6364686 },
    { lat: 48.7924991, lng: 18.6363857 },
    { lat: 48.7929276, lng: 18.6347003 },
    { lat: 48.7933577, lng: 18.6326258 },
    { lat: 48.7934226, lng: 18.6323648 },
    { lat: 48.793454, lng: 18.6322364 },
    { lat: 48.7934856, lng: 18.6321095 },
    { lat: 48.7934857, lng: 18.6320682 },
    { lat: 48.7934858, lng: 18.6319649 },
    { lat: 48.7934859, lng: 18.6319059 },
    { lat: 48.7936944, lng: 18.6310178 },
    { lat: 48.7934902, lng: 18.6308705 },
    { lat: 48.7931901, lng: 18.6306395 },
    { lat: 48.7931015, lng: 18.630562 },
    { lat: 48.792875, lng: 18.6303355 },
    { lat: 48.792804, lng: 18.6302532 },
    { lat: 48.7926652, lng: 18.6299557 },
    { lat: 48.7926057, lng: 18.6297727 },
    { lat: 48.792475, lng: 18.6295237 },
    { lat: 48.7922755, lng: 18.629232 },
    { lat: 48.7921989, lng: 18.6291293 },
    { lat: 48.7919336, lng: 18.6288706 },
    { lat: 48.7914523, lng: 18.6282677 },
    { lat: 48.7912472, lng: 18.6278932 },
    { lat: 48.7913408, lng: 18.6275975 },
    { lat: 48.792294, lng: 18.6254781 },
    { lat: 48.7923271, lng: 18.6254029 },
    { lat: 48.7923954, lng: 18.6252467 },
    { lat: 48.7924596, lng: 18.6250999 },
    { lat: 48.7924888, lng: 18.6250331 },
    { lat: 48.7925401, lng: 18.6249166 },
    { lat: 48.7925702, lng: 18.6248482 },
    { lat: 48.7924152, lng: 18.6246909 },
    { lat: 48.7923126, lng: 18.6245866 },
    { lat: 48.7921218, lng: 18.6243933 },
    { lat: 48.7919953, lng: 18.6242647 },
    { lat: 48.7919916, lng: 18.6242603 },
    { lat: 48.7919333, lng: 18.6242015 },
    { lat: 48.7918272, lng: 18.6239913 },
    { lat: 48.7916429, lng: 18.6236252 },
    { lat: 48.7915962, lng: 18.6235333 },
    { lat: 48.7915774, lng: 18.6231625 },
    { lat: 48.7915611, lng: 18.6230472 },
    { lat: 48.7913537, lng: 18.6224629 },
    { lat: 48.7909868, lng: 18.6219532 },
    { lat: 48.7904786, lng: 18.6215851 },
    { lat: 48.7898847, lng: 18.621278 },
    { lat: 48.7894294, lng: 18.6205926 },
    { lat: 48.7890507, lng: 18.6196735 },
    { lat: 48.7885787, lng: 18.6189302 },
    { lat: 48.7882275, lng: 18.6183774 },
    { lat: 48.7880955, lng: 18.617914 },
    { lat: 48.7877836, lng: 18.617337 },
    { lat: 48.7872848, lng: 18.616493 },
    { lat: 48.7872804, lng: 18.6163694 },
    { lat: 48.7872559, lng: 18.6164179 },
    { lat: 48.7872131, lng: 18.6165009 },
    { lat: 48.7871933, lng: 18.6165733 },
    { lat: 48.7870775, lng: 18.6160559 },
    { lat: 48.7870277, lng: 18.6156972 },
    { lat: 48.786942, lng: 18.6152573 },
    { lat: 48.7868231, lng: 18.6149339 },
    { lat: 48.7866411, lng: 18.6146334 },
    { lat: 48.7864409, lng: 18.6144322 },
    { lat: 48.7862331, lng: 18.6142829 },
    { lat: 48.7859514, lng: 18.6141187 },
    { lat: 48.7856674, lng: 18.6140829 },
    { lat: 48.7854161, lng: 18.6141277 },
    { lat: 48.7851902, lng: 18.6142271 },
    { lat: 48.784471, lng: 18.6144228 },
    { lat: 48.7841647, lng: 18.6144516 },
    { lat: 48.7836989, lng: 18.6144931 },
    { lat: 48.7835631, lng: 18.6145066 },
    { lat: 48.7831553, lng: 18.6143909 },
    { lat: 48.782752, lng: 18.614231 },
    { lat: 48.7824813, lng: 18.6140647 },
    { lat: 48.7820326, lng: 18.6136895 },
    { lat: 48.7817749, lng: 18.6132814 },
    { lat: 48.7814378, lng: 18.6125701 },
    { lat: 48.7811753, lng: 18.6116131 },
    { lat: 48.7807799, lng: 18.6090272 },
    { lat: 48.7805356, lng: 18.6071506 },
    { lat: 48.7802684, lng: 18.6057064 },
    { lat: 48.7800467, lng: 18.6050882 },
    { lat: 48.7797528, lng: 18.6044913 },
    { lat: 48.7797357, lng: 18.6044666 },
    { lat: 48.7791519, lng: 18.6035552 },
    { lat: 48.7782288, lng: 18.6017897 },
    { lat: 48.7772739, lng: 18.599976 },
    { lat: 48.7771584, lng: 18.5997814 },
    { lat: 48.7770412, lng: 18.5996364 },
    { lat: 48.7764606, lng: 18.5988259 },
    { lat: 48.7763678, lng: 18.5986324 },
    { lat: 48.7763045, lng: 18.598317 },
    { lat: 48.7763354, lng: 18.5979691 },
    { lat: 48.7764074, lng: 18.5976945 },
    { lat: 48.7768259, lng: 18.5970467 },
    { lat: 48.7769163, lng: 18.5968215 },
    { lat: 48.7770329, lng: 18.5966399 },
    { lat: 48.777085, lng: 18.5965591 },
    { lat: 48.7771525, lng: 18.5963687 },
    { lat: 48.7771984, lng: 18.5961116 },
    { lat: 48.7772115, lng: 18.5958533 },
    { lat: 48.7772006, lng: 18.5952325 },
    { lat: 48.7771431, lng: 18.5948786 },
    { lat: 48.7770427, lng: 18.5945874 },
    { lat: 48.7767499, lng: 18.5943011 },
    { lat: 48.7765106, lng: 18.5942153 },
    { lat: 48.7761703, lng: 18.594222 },
    { lat: 48.7756254, lng: 18.5942826 },
    { lat: 48.7756008, lng: 18.5942851 },
    { lat: 48.7755503, lng: 18.5942906 },
    { lat: 48.7752198, lng: 18.5944158 },
    { lat: 48.7751278, lng: 18.5943625 },
    { lat: 48.7750975, lng: 18.5942795 },
    { lat: 48.7751199, lng: 18.5941108 },
    { lat: 48.7751243, lng: 18.5938982 },
    { lat: 48.7750457, lng: 18.5936567 },
    { lat: 48.774918, lng: 18.5933782 },
    { lat: 48.774703, lng: 18.5928248 },
    { lat: 48.7745401, lng: 18.5921673 },
    { lat: 48.7745003, lng: 18.5921196 },
    { lat: 48.774369, lng: 18.5919642 },
    { lat: 48.7743273, lng: 18.5919247 },
    { lat: 48.7741406, lng: 18.5917464 },
    { lat: 48.7738553, lng: 18.5912453 },
    { lat: 48.7737219, lng: 18.5907807 },
    { lat: 48.7737067, lng: 18.5903544 },
    { lat: 48.7737088, lng: 18.5903087 },
    { lat: 48.7733908, lng: 18.5895188 },
    { lat: 48.7732272, lng: 18.5890355 },
    { lat: 48.7732995, lng: 18.5889955 },
    { lat: 48.7733323, lng: 18.5889775 },
    { lat: 48.7734462, lng: 18.5889142 },
    { lat: 48.7733393, lng: 18.5885843 },
    { lat: 48.7728934, lng: 18.5872836 },
    { lat: 48.7728531, lng: 18.5871446 },
    { lat: 48.7728018, lng: 18.5868644 },
    { lat: 48.7727314, lng: 18.5868952 },
    { lat: 48.7727602, lng: 18.5867612 },
    { lat: 48.7727732, lng: 18.5865842 },
    { lat: 48.7727872, lng: 18.5863831 },
    { lat: 48.7729515, lng: 18.5861269 },
    { lat: 48.7732406, lng: 18.5861335 },
    { lat: 48.7734751, lng: 18.5859443 },
    { lat: 48.7735529, lng: 18.5858817 },
    { lat: 48.7736397, lng: 18.5856932 },
    { lat: 48.7738729, lng: 18.5851861 },
    { lat: 48.7740232, lng: 18.5846501 },
    { lat: 48.7740851, lng: 18.5841866 },
    { lat: 48.7740849, lng: 18.5841824 },
    { lat: 48.7740537, lng: 18.5837528 },
    { lat: 48.7737045, lng: 18.5831558 },
    { lat: 48.773518, lng: 18.5826084 },
    { lat: 48.7735033, lng: 18.5825873 },
    { lat: 48.7733445, lng: 18.5823608 },
    { lat: 48.7732254, lng: 18.582349 },
    { lat: 48.7725077, lng: 18.5822804 },
    { lat: 48.7724868, lng: 18.5822691 },
    { lat: 48.7724519, lng: 18.5822494 },
    { lat: 48.7720868, lng: 18.5820481 },
    { lat: 48.7718225, lng: 18.5818103 },
    { lat: 48.7716313, lng: 18.5815086 },
    { lat: 48.7712144, lng: 18.5807035 },
    { lat: 48.7708648, lng: 18.580101 },
    { lat: 48.770379, lng: 18.5796059 },
    { lat: 48.7690022, lng: 18.5792201 },
    { lat: 48.7678578, lng: 18.5787049 },
    { lat: 48.7673726, lng: 18.5782643 },
    { lat: 48.7668763, lng: 18.5776204 },
    { lat: 48.7668101, lng: 18.5777083 },
    { lat: 48.7667335, lng: 18.5777929 },
    { lat: 48.7666788, lng: 18.5778662 },
    { lat: 48.7660563, lng: 18.5785567 },
    { lat: 48.7657949, lng: 18.5782077 },
    { lat: 48.7657691, lng: 18.578251 },
    { lat: 48.7655813, lng: 18.5784496 },
    { lat: 48.7652345, lng: 18.5788154 },
    { lat: 48.7647614, lng: 18.5794091 },
    { lat: 48.763928, lng: 18.5804541 },
    { lat: 48.7631801, lng: 18.5813912 },
    { lat: 48.7619612, lng: 18.5826477 },
    { lat: 48.7616225, lng: 18.5829967 },
    { lat: 48.7615894, lng: 18.5830307 },
    { lat: 48.7615573, lng: 18.5830636 },
    { lat: 48.7597414, lng: 18.5847538 },
    { lat: 48.7573475, lng: 18.5879771 },
    { lat: 48.7572292, lng: 18.5880217 },
    { lat: 48.7572165, lng: 18.5886203 },
    { lat: 48.7574003, lng: 18.5889936 },
    { lat: 48.7577843, lng: 18.5889202 },
    { lat: 48.7580472, lng: 18.5891964 },
    { lat: 48.7590068, lng: 18.5909642 },
    { lat: 48.7593252, lng: 18.5916162 },
    { lat: 48.7597729, lng: 18.5920531 },
    { lat: 48.7606139, lng: 18.5923384 },
    { lat: 48.7609591, lng: 18.5926096 },
    { lat: 48.7610187, lng: 18.5924198 },
    { lat: 48.761282, lng: 18.5926362 },
    { lat: 48.7614848, lng: 18.5928621 },
    { lat: 48.7616798, lng: 18.5931535 },
    { lat: 48.7618488, lng: 18.5935032 },
    { lat: 48.7620862, lng: 18.5940562 },
    { lat: 48.762189, lng: 18.5943313 },
    { lat: 48.76221, lng: 18.5943855 },
    { lat: 48.7622127, lng: 18.5943941 },
    { lat: 48.7623089, lng: 18.5946491 },
    { lat: 48.7625108, lng: 18.5951513 },
    { lat: 48.7627776, lng: 18.5958525 },
    { lat: 48.7627207, lng: 18.5958851 },
    { lat: 48.7626971, lng: 18.5959277 },
    { lat: 48.7626671, lng: 18.5959786 },
    { lat: 48.7626229, lng: 18.5961428 },
    { lat: 48.76249, lng: 18.5965214 },
    { lat: 48.7623246, lng: 18.5969257 },
    { lat: 48.7622263, lng: 18.5971515 },
    { lat: 48.762246, lng: 18.5971811 },
    { lat: 48.7622557, lng: 18.5971952 },
    { lat: 48.7624816, lng: 18.5976189 },
    { lat: 48.7627536, lng: 18.5981462 },
    { lat: 48.7628236, lng: 18.5985015 },
    { lat: 48.7628216, lng: 18.599048 },
    { lat: 48.7628725, lng: 18.5996995 },
    { lat: 48.7628204, lng: 18.599987 },
    { lat: 48.7627784, lng: 18.6007569 },
    { lat: 48.7627327, lng: 18.6016037 },
    { lat: 48.7622545, lng: 18.6024811 },
    { lat: 48.761717, lng: 18.6032038 },
    { lat: 48.7615888, lng: 18.6033971 },
    { lat: 48.7614143, lng: 18.6036347 },
    { lat: 48.7606073, lng: 18.6046915 },
    { lat: 48.7604746, lng: 18.6048632 },
    { lat: 48.7601573, lng: 18.6052756 },
    { lat: 48.7599738, lng: 18.6055139 },
    { lat: 48.7597525, lng: 18.605801 },
    { lat: 48.7595554, lng: 18.6060573 },
    { lat: 48.7594848, lng: 18.6061493 },
    { lat: 48.7592173, lng: 18.6064607 },
    { lat: 48.7590679, lng: 18.6065764 },
    { lat: 48.7586853, lng: 18.6068013 },
    { lat: 48.7580971, lng: 18.6070833 },
    { lat: 48.7576471, lng: 18.6072935 },
    { lat: 48.7567723, lng: 18.6078039 },
    { lat: 48.7567459, lng: 18.6077553 },
    { lat: 48.756586, lng: 18.6074031 },
    { lat: 48.7562862, lng: 18.6068848 },
    { lat: 48.7560046, lng: 18.6063476 },
    { lat: 48.7557378, lng: 18.6058125 },
    { lat: 48.755471, lng: 18.6052802 },
    { lat: 48.7551962, lng: 18.6047227 },
    { lat: 48.7549503, lng: 18.6041774 },
    { lat: 48.7547236, lng: 18.6037178 },
    { lat: 48.7547169, lng: 18.6036992 },
    { lat: 48.7547065, lng: 18.6036719 },
    { lat: 48.7546761, lng: 18.6036944 },
    { lat: 48.7546685, lng: 18.6037 },
    { lat: 48.7545871, lng: 18.6037595 },
    { lat: 48.7545795, lng: 18.6037656 },
    { lat: 48.7545231, lng: 18.6038065 },
    { lat: 48.753368, lng: 18.6045996 },
    { lat: 48.7529976, lng: 18.6048201 },
    { lat: 48.7530027, lng: 18.6048518 },
    { lat: 48.7530076, lng: 18.6048801 },
    { lat: 48.7530212, lng: 18.604943 },
    { lat: 48.7528039, lng: 18.6051188 },
    { lat: 48.7520952, lng: 18.6055713 },
    { lat: 48.7515253, lng: 18.6061533 },
    { lat: 48.7506857, lng: 18.6069606 },
    { lat: 48.7488393, lng: 18.6094472 },
    { lat: 48.7475765, lng: 18.6082444 },
    { lat: 48.7474544, lng: 18.6082789 },
    { lat: 48.7469209, lng: 18.6092832 },
    { lat: 48.7461319, lng: 18.6117013 },
    { lat: 48.7457725, lng: 18.6128806 },
    { lat: 48.745015, lng: 18.6153605 },
    { lat: 48.7446544, lng: 18.6176284 },
    { lat: 48.7444768, lng: 18.618562 },
    { lat: 48.7442656, lng: 18.6191438 },
    { lat: 48.7442322, lng: 18.619235 },
    { lat: 48.7442057, lng: 18.619309 },
    { lat: 48.7441556, lng: 18.6194468 },
    { lat: 48.7441044, lng: 18.6195852 },
    { lat: 48.7440809, lng: 18.619652 },
    { lat: 48.7440445, lng: 18.6197505 },
    { lat: 48.7439089, lng: 18.6201235 },
    { lat: 48.7438934, lng: 18.6201911 },
    { lat: 48.7438779, lng: 18.6202602 },
    { lat: 48.7438487, lng: 18.6203871 },
    { lat: 48.7438413, lng: 18.620417 },
    { lat: 48.7438119, lng: 18.6205426 },
    { lat: 48.743789, lng: 18.6206414 },
    { lat: 48.7436223, lng: 18.6212533 },
    { lat: 48.743482, lng: 18.6216022 },
    { lat: 48.7433973, lng: 18.6217677 },
    { lat: 48.7433369, lng: 18.6219231 },
    { lat: 48.7432711, lng: 18.6221035 },
    { lat: 48.7431596, lng: 18.6224016 },
    { lat: 48.7431069, lng: 18.6225743 },
    { lat: 48.7429928, lng: 18.6229491 },
    { lat: 48.7429722, lng: 18.6230467 },
    { lat: 48.7429523, lng: 18.6231604 },
    { lat: 48.7429415, lng: 18.6232734 },
    { lat: 48.7429389, lng: 18.6233091 },
    { lat: 48.7429383, lng: 18.6233397 },
    { lat: 48.7429416, lng: 18.6233604 },
    { lat: 48.7429533, lng: 18.6234301 },
    { lat: 48.7429564, lng: 18.6234463 },
    { lat: 48.7428626, lng: 18.6234648 },
    { lat: 48.7428908, lng: 18.6235269 },
    { lat: 48.7429022, lng: 18.6235715 },
    { lat: 48.7428863, lng: 18.6236938 },
    { lat: 48.7428622, lng: 18.6238766 },
    { lat: 48.7428469, lng: 18.6239898 },
    { lat: 48.7427645, lng: 18.624366 },
    { lat: 48.742682, lng: 18.6247412 },
    { lat: 48.7426017, lng: 18.6249678 },
    { lat: 48.7425712, lng: 18.6250507 },
    { lat: 48.7424606, lng: 18.6254275 },
    { lat: 48.7419746, lng: 18.6271909 },
    { lat: 48.7418979, lng: 18.6273989 },
    { lat: 48.7418698, lng: 18.627425 },
    { lat: 48.7418477, lng: 18.6274747 },
    { lat: 48.7418218, lng: 18.6275801 },
    { lat: 48.7417903, lng: 18.6276654 },
    { lat: 48.7417387, lng: 18.6277758 },
    { lat: 48.7416582, lng: 18.6278718 },
    { lat: 48.7415668, lng: 18.6279352 },
    { lat: 48.7414761, lng: 18.6279688 },
    { lat: 48.7413532, lng: 18.6279693 },
    { lat: 48.741354, lng: 18.6279829 },
    { lat: 48.741359, lng: 18.628034 },
    { lat: 48.7413597, lng: 18.6280475 },
    { lat: 48.741212, lng: 18.6284062 },
    { lat: 48.7410739, lng: 18.6286904 },
    { lat: 48.7409856, lng: 18.6288919 },
    { lat: 48.7408569, lng: 18.6291852 },
    { lat: 48.7408449, lng: 18.6292136 },
    { lat: 48.7408245, lng: 18.629211 },
    { lat: 48.7407767, lng: 18.629204 },
    { lat: 48.7407187, lng: 18.6291954 },
    { lat: 48.7406305, lng: 18.6294655 },
    { lat: 48.7405181, lng: 18.6296192 },
    { lat: 48.7405378, lng: 18.6299022 },
    { lat: 48.7403057, lng: 18.6301555 },
    { lat: 48.740042, lng: 18.6302454 },
    { lat: 48.7399952, lng: 18.630382 },
    { lat: 48.7400104, lng: 18.63054 },
    { lat: 48.7399051, lng: 18.6306169 },
    { lat: 48.7398737, lng: 18.6307028 },
    { lat: 48.739711, lng: 18.630761 },
    { lat: 48.7395775, lng: 18.6309219 },
    { lat: 48.7395108, lng: 18.6309185 },
    { lat: 48.739271, lng: 18.6311164 },
    { lat: 48.7391418, lng: 18.6313769 },
    { lat: 48.7390618, lng: 18.6315889 },
    { lat: 48.7388479, lng: 18.6318057 },
    { lat: 48.7389156, lng: 18.6320566 },
    { lat: 48.7387308, lng: 18.632271 },
    { lat: 48.7387433, lng: 18.6324192 },
    { lat: 48.738675, lng: 18.6324694 },
    { lat: 48.7386138, lng: 18.6326116 },
    { lat: 48.7386233, lng: 18.6327676 },
    { lat: 48.7384784, lng: 18.6328623 },
    { lat: 48.738304, lng: 18.6331023 },
    { lat: 48.7382621, lng: 18.6331604 },
    { lat: 48.7382212, lng: 18.6332201 },
    { lat: 48.738179, lng: 18.6332946 },
    { lat: 48.7381222, lng: 18.6333498 },
    { lat: 48.738116, lng: 18.6333595 },
    { lat: 48.7381149, lng: 18.6333617 },
    { lat: 48.7380441, lng: 18.6334716 },
    { lat: 48.7380305, lng: 18.6334914 },
    { lat: 48.7377734, lng: 18.6338909 },
    { lat: 48.7376547, lng: 18.6340735 },
    { lat: 48.7375255, lng: 18.6342734 },
    { lat: 48.7375005, lng: 18.6343122 },
    { lat: 48.7374787, lng: 18.6343874 },
    { lat: 48.7373057, lng: 18.6345494 },
    { lat: 48.7371147, lng: 18.6346261 },
    { lat: 48.737067, lng: 18.6348503 },
    { lat: 48.7370692, lng: 18.635289 },
    { lat: 48.7370218, lng: 18.6356687 },
    { lat: 48.7369855, lng: 18.6359587 },
    { lat: 48.7369721, lng: 18.6360661 },
    { lat: 48.7368182, lng: 18.6364349 },
    { lat: 48.7367973, lng: 18.6364856 },
    { lat: 48.7364935, lng: 18.6372139 },
    { lat: 48.7364809, lng: 18.6375472 },
    { lat: 48.7364735, lng: 18.6377446 },
    { lat: 48.7365061, lng: 18.6378472 },
    { lat: 48.7363919, lng: 18.6380546 },
    { lat: 48.7362863, lng: 18.6382508 },
    { lat: 48.7360786, lng: 18.6389609 },
    { lat: 48.7360557, lng: 18.6390387 },
    { lat: 48.7360184, lng: 18.6391658 },
    { lat: 48.7359985, lng: 18.6392338 },
    { lat: 48.7359812, lng: 18.6395438 },
    { lat: 48.7358866, lng: 18.6396711 },
    { lat: 48.7358455, lng: 18.6397262 },
    { lat: 48.7358235, lng: 18.6397566 },
    { lat: 48.7358175, lng: 18.6398772 },
    { lat: 48.7358135, lng: 18.6399688 },
    { lat: 48.7358081, lng: 18.6400992 },
    { lat: 48.7356752, lng: 18.6403781 },
    { lat: 48.735557, lng: 18.6409292 },
    { lat: 48.735515, lng: 18.6410284 },
    { lat: 48.7354819, lng: 18.6411049 },
    { lat: 48.7352162, lng: 18.6417242 },
    { lat: 48.7349738, lng: 18.6424201 },
    { lat: 48.7349616, lng: 18.6425071 },
    { lat: 48.734937, lng: 18.6426802 },
    { lat: 48.7349392, lng: 18.6427214 },
    { lat: 48.7349425, lng: 18.6427828 },
    { lat: 48.7349533, lng: 18.6429626 },
    { lat: 48.7349761, lng: 18.643578 },
    { lat: 48.7349787, lng: 18.6436687 },
    { lat: 48.7349975, lng: 18.6441667 },
    { lat: 48.7354483, lng: 18.6461378 },
    { lat: 48.7354375, lng: 18.6465466 },
    { lat: 48.7354052, lng: 18.64769 },
    { lat: 48.7352919, lng: 18.6479558 },
    { lat: 48.7352238, lng: 18.6481167 },
    { lat: 48.7352255, lng: 18.6483984 },
    { lat: 48.7351704, lng: 18.6499608 },
    { lat: 48.7350815, lng: 18.6500481 },
    { lat: 48.7350828, lng: 18.6500951 },
    { lat: 48.735096, lng: 18.6505498 },
    { lat: 48.734993, lng: 18.6507537 },
    { lat: 48.7349265, lng: 18.6510303 },
    { lat: 48.7349016, lng: 18.6511331 },
    { lat: 48.7347782, lng: 18.6516505 },
    { lat: 48.7347496, lng: 18.6517707 },
    { lat: 48.7346648, lng: 18.6521239 },
    { lat: 48.7346559, lng: 18.6521475 },
    { lat: 48.7345877, lng: 18.6523284 },
    { lat: 48.7343211, lng: 18.653037 },
    { lat: 48.7342841, lng: 18.6530608 },
    { lat: 48.7341316, lng: 18.65316 },
    { lat: 48.7338569, lng: 18.6533382 },
    { lat: 48.7337751, lng: 18.6533912 },
    { lat: 48.7335146, lng: 18.6535603 },
    { lat: 48.7333369, lng: 18.6535918 },
    { lat: 48.7332666, lng: 18.6536038 },
    { lat: 48.7330643, lng: 18.6536397 },
    { lat: 48.732997, lng: 18.6536875 },
    { lat: 48.7329427, lng: 18.6537274 },
    { lat: 48.7328091, lng: 18.6538236 },
    { lat: 48.732764, lng: 18.653803 },
    { lat: 48.7326785, lng: 18.6537635 },
    { lat: 48.7323671, lng: 18.6537616 },
    { lat: 48.7323301, lng: 18.6538288 },
    { lat: 48.732326, lng: 18.6541103 },
    { lat: 48.7323239, lng: 18.6542395 },
    { lat: 48.7322697, lng: 18.6542408 },
    { lat: 48.7320062, lng: 18.6542466 },
    { lat: 48.7319238, lng: 18.6543081 },
    { lat: 48.7316317, lng: 18.6546468 },
    { lat: 48.7308868, lng: 18.6552482 },
    { lat: 48.7300763, lng: 18.6555719 },
    { lat: 48.7297438, lng: 18.6555325 },
    { lat: 48.7292552, lng: 18.6557811 },
    { lat: 48.7287793, lng: 18.6560145 },
    { lat: 48.7283811, lng: 18.656603 },
    { lat: 48.7273737, lng: 18.657357 },
    { lat: 48.727028, lng: 18.6576647 },
    { lat: 48.726393, lng: 18.6575783 },
    { lat: 48.7249795, lng: 18.6576352 },
    { lat: 48.7246707, lng: 18.6577452 },
    { lat: 48.7238021, lng: 18.6576567 },
    { lat: 48.7233753, lng: 18.6575228 },
    { lat: 48.7228995, lng: 18.6575049 },
    { lat: 48.7221779, lng: 18.6576349 },
    { lat: 48.7221466, lng: 18.6576405 },
    { lat: 48.7219232, lng: 18.6576806 },
    { lat: 48.721785, lng: 18.6584496 },
    { lat: 48.721803, lng: 18.6586179 },
    { lat: 48.7219958, lng: 18.6604079 },
    { lat: 48.7221687, lng: 18.662397 },
    { lat: 48.7222088, lng: 18.6627679 },
    { lat: 48.7229062, lng: 18.6641676 },
    { lat: 48.7234979, lng: 18.665404 },
    { lat: 48.7233527, lng: 18.667015 },
    { lat: 48.7233385, lng: 18.6671728 },
    { lat: 48.7232145, lng: 18.6685453 },
    { lat: 48.7230137, lng: 18.670446 },
    { lat: 48.7228942, lng: 18.6717967 },
    { lat: 48.7243252, lng: 18.6736533 },
    { lat: 48.7245266, lng: 18.6740451 },
    { lat: 48.7250304, lng: 18.6749706 },
    { lat: 48.7248639, lng: 18.6760545 },
    { lat: 48.7250661, lng: 18.6777738 },
    { lat: 48.7247367, lng: 18.6785133 },
    { lat: 48.7247282, lng: 18.6785876 },
    { lat: 48.7246819, lng: 18.67867 },
    { lat: 48.7246971, lng: 18.6788284 },
    { lat: 48.7247436, lng: 18.6790025 },
    { lat: 48.7250723, lng: 18.6794138 },
    { lat: 48.7251811, lng: 18.6797189 },
    { lat: 48.7251829, lng: 18.6799658 },
    { lat: 48.7252254, lng: 18.6801282 },
    { lat: 48.7257905, lng: 18.6830707 },
    { lat: 48.7265545, lng: 18.684413 },
    { lat: 48.7266429, lng: 18.6864768 },
    { lat: 48.726779, lng: 18.6894119 },
    { lat: 48.7268828, lng: 18.6917869 },
    { lat: 48.7269286, lng: 18.6929038 },
    { lat: 48.7269326, lng: 18.6929779 },
    { lat: 48.7269575, lng: 18.6935957 },
    { lat: 48.7270543, lng: 18.6956262 },
    { lat: 48.7271008, lng: 18.6968412 },
    { lat: 48.7271578, lng: 18.6977651 },
    { lat: 48.7276147, lng: 18.6975548 },
    { lat: 48.7276785, lng: 18.6975258 },
    { lat: 48.7282904, lng: 18.6971757 },
    { lat: 48.7291089, lng: 18.6966425 },
    { lat: 48.7294469, lng: 18.6964219 },
    { lat: 48.730628, lng: 18.6958643 },
    { lat: 48.7306828, lng: 18.6956757 },
    { lat: 48.7307215, lng: 18.6956194 },
    { lat: 48.7309639, lng: 18.6956603 },
    { lat: 48.730994, lng: 18.6957606 },
    { lat: 48.7314389, lng: 18.6961732 },
    { lat: 48.731864, lng: 18.6965127 },
    { lat: 48.7325139, lng: 18.6971533 },
    { lat: 48.7331181, lng: 18.6974018 },
    { lat: 48.7332345, lng: 18.6974495 },
    { lat: 48.7336432, lng: 18.6976917 },
    { lat: 48.7339623, lng: 18.6978609 },
    { lat: 48.7346363, lng: 18.6983617 },
    { lat: 48.7351404, lng: 18.698044 },
    { lat: 48.7353535, lng: 18.6979133 },
    { lat: 48.7359883, lng: 18.6986549 },
    { lat: 48.7374996, lng: 18.6995061 },
    { lat: 48.7389601, lng: 18.7003331 },
    { lat: 48.739725, lng: 18.7009123 },
    { lat: 48.7405877, lng: 18.7014418 },
    { lat: 48.7424444, lng: 18.7013644 },
    { lat: 48.745187, lng: 18.7026788 },
    { lat: 48.7460427, lng: 18.7030989 },
  ],
  Chvojnica: [
    { lat: 48.8764416, lng: 18.5164512 },
    { lat: 48.8764279, lng: 18.5172137 },
    { lat: 48.8763601, lng: 18.5181428 },
    { lat: 48.8762321, lng: 18.5187326 },
    { lat: 48.87608, lng: 18.5190437 },
    { lat: 48.875935, lng: 18.5192629 },
    { lat: 48.8755609, lng: 18.5202813 },
    { lat: 48.875488, lng: 18.5204753 },
    { lat: 48.8749358, lng: 18.5219951 },
    { lat: 48.8747371, lng: 18.5224489 },
    { lat: 48.8743839, lng: 18.5232486 },
    { lat: 48.8735953, lng: 18.5250469 },
    { lat: 48.8728809, lng: 18.5253528 },
    { lat: 48.8718871, lng: 18.5263014 },
    { lat: 48.8715307, lng: 18.5265546 },
    { lat: 48.8711827, lng: 18.5268518 },
    { lat: 48.8708221, lng: 18.5270661 },
    { lat: 48.8703251, lng: 18.5273845 },
    { lat: 48.86993, lng: 18.5275863 },
    { lat: 48.8697381, lng: 18.5276644 },
    { lat: 48.8695389, lng: 18.5277176 },
    { lat: 48.8685289, lng: 18.5283686 },
    { lat: 48.867557, lng: 18.5290191 },
    { lat: 48.8674762, lng: 18.5290683 },
    { lat: 48.8674974, lng: 18.5307807 },
    { lat: 48.8676906, lng: 18.5321538 },
    { lat: 48.8675885, lng: 18.5338871 },
    { lat: 48.8666688, lng: 18.5375669 },
    { lat: 48.8660389, lng: 18.5407536 },
    { lat: 48.8658711, lng: 18.5410708 },
    { lat: 48.865167, lng: 18.5423923 },
    { lat: 48.8649009, lng: 18.5428934 },
    { lat: 48.864765, lng: 18.5431124 },
    { lat: 48.8647043, lng: 18.5431992 },
    { lat: 48.8646103, lng: 18.5434398 },
    { lat: 48.864394, lng: 18.5452932 },
    { lat: 48.8655613, lng: 18.5465175 },
    { lat: 48.8659887, lng: 18.5468235 },
    { lat: 48.866719, lng: 18.5476671 },
    { lat: 48.8678979, lng: 18.5484794 },
    { lat: 48.8679633, lng: 18.5485218 },
    { lat: 48.8685858, lng: 18.5488511 },
    { lat: 48.8693448, lng: 18.549536 },
    { lat: 48.8696265, lng: 18.5499054 },
    { lat: 48.8709628, lng: 18.5517065 },
    { lat: 48.8716247, lng: 18.5533241 },
    { lat: 48.8716436, lng: 18.5532977 },
    { lat: 48.8719071, lng: 18.5539334 },
    { lat: 48.8720512, lng: 18.5541584 },
    { lat: 48.8722628, lng: 18.554753 },
    { lat: 48.8723343, lng: 18.5547595 },
    { lat: 48.8723935, lng: 18.555062 },
    { lat: 48.8724567, lng: 18.555268 },
    { lat: 48.872542, lng: 18.5553029 },
    { lat: 48.8725838, lng: 18.5553898 },
    { lat: 48.8725844, lng: 18.5555667 },
    { lat: 48.8726092, lng: 18.5556095 },
    { lat: 48.8726488, lng: 18.5558167 },
    { lat: 48.8727314, lng: 18.5559049 },
    { lat: 48.872802, lng: 18.5563752 },
    { lat: 48.8728253, lng: 18.5567012 },
    { lat: 48.8727986, lng: 18.5570625 },
    { lat: 48.8727107, lng: 18.5573117 },
    { lat: 48.8726017, lng: 18.557695 },
    { lat: 48.8725081, lng: 18.5582782 },
    { lat: 48.8725102, lng: 18.5594206 },
    { lat: 48.8725076, lng: 18.5596828 },
    { lat: 48.8725511, lng: 18.5599053 },
    { lat: 48.8725518, lng: 18.5608941 },
    { lat: 48.8724725, lng: 18.5613071 },
    { lat: 48.8724316, lng: 18.5613634 },
    { lat: 48.8723053, lng: 18.5618202 },
    { lat: 48.8721785, lng: 18.5622061 },
    { lat: 48.8720785, lng: 18.5627335 },
    { lat: 48.8720225, lng: 18.5629515 },
    { lat: 48.8719827, lng: 18.5634878 },
    { lat: 48.8719279, lng: 18.5637243 },
    { lat: 48.8719418, lng: 18.5637931 },
    { lat: 48.8719446, lng: 18.5638885 },
    { lat: 48.8718629, lng: 18.5640029 },
    { lat: 48.8718899, lng: 18.5641486 },
    { lat: 48.8718762, lng: 18.5642872 },
    { lat: 48.8718934, lng: 18.5643341 },
    { lat: 48.8718825, lng: 18.5644043 },
    { lat: 48.871877, lng: 18.5645547 },
    { lat: 48.8718246, lng: 18.564879 },
    { lat: 48.8717624, lng: 18.5650634 },
    { lat: 48.8717241, lng: 18.565482 },
    { lat: 48.871575, lng: 18.5661419 },
    { lat: 48.8715402, lng: 18.5668747 },
    { lat: 48.8716274, lng: 18.5672139 },
    { lat: 48.8718757, lng: 18.5676359 },
    { lat: 48.8719703, lng: 18.5678201 },
    { lat: 48.8720758, lng: 18.5681456 },
    { lat: 48.872386, lng: 18.5687053 },
    { lat: 48.8728224, lng: 18.5692666 },
    { lat: 48.8730569, lng: 18.5695374 },
    { lat: 48.8732192, lng: 18.5697418 },
    { lat: 48.8733224, lng: 18.5699404 },
    { lat: 48.8734391, lng: 18.5700975 },
    { lat: 48.8736508, lng: 18.5706327 },
    { lat: 48.8737249, lng: 18.5708538 },
    { lat: 48.87385, lng: 18.5711228 },
    { lat: 48.8739936, lng: 18.5715883 },
    { lat: 48.8740843, lng: 18.5721191 },
    { lat: 48.8741639, lng: 18.5724238 },
    { lat: 48.874181, lng: 18.5725711 },
    { lat: 48.8742447, lng: 18.5728122 },
    { lat: 48.8743039, lng: 18.5730951 },
    { lat: 48.8743656, lng: 18.5735676 },
    { lat: 48.8744459, lng: 18.5739252 },
    { lat: 48.874546, lng: 18.5743163 },
    { lat: 48.8747428, lng: 18.5748479 },
    { lat: 48.8750914, lng: 18.5752052 },
    { lat: 48.875291, lng: 18.5755193 },
    { lat: 48.8755352, lng: 18.5757377 },
    { lat: 48.8756415, lng: 18.5758864 },
    { lat: 48.8757255, lng: 18.5759398 },
    { lat: 48.8758591, lng: 18.5759669 },
    { lat: 48.8760156, lng: 18.5761073 },
    { lat: 48.8760199, lng: 18.5761702 },
    { lat: 48.8760617, lng: 18.576003 },
    { lat: 48.8760834, lng: 18.5758412 },
    { lat: 48.8760676, lng: 18.5756099 },
    { lat: 48.8760669, lng: 18.5753914 },
    { lat: 48.8760991, lng: 18.5750244 },
    { lat: 48.8760516, lng: 18.5748153 },
    { lat: 48.8759706, lng: 18.5745276 },
    { lat: 48.8759707, lng: 18.5743811 },
    { lat: 48.8759037, lng: 18.5740375 },
    { lat: 48.8759129, lng: 18.5735659 },
    { lat: 48.8759579, lng: 18.5734349 },
    { lat: 48.875999, lng: 18.5732173 },
    { lat: 48.8760174, lng: 18.5729925 },
    { lat: 48.8759744, lng: 18.5729276 },
    { lat: 48.8759517, lng: 18.5728193 },
    { lat: 48.875988, lng: 18.5726995 },
    { lat: 48.8760875, lng: 18.5725986 },
    { lat: 48.8761211, lng: 18.5724891 },
    { lat: 48.8761383, lng: 18.572393 },
    { lat: 48.8761242, lng: 18.5722361 },
    { lat: 48.8762103, lng: 18.5722207 },
    { lat: 48.8763262, lng: 18.5722177 },
    { lat: 48.8763998, lng: 18.5721803 },
    { lat: 48.8766052, lng: 18.5722243 },
    { lat: 48.8766286, lng: 18.5720533 },
    { lat: 48.8764014, lng: 18.5718766 },
    { lat: 48.8764374, lng: 18.5715589 },
    { lat: 48.8764616, lng: 18.5714036 },
    { lat: 48.8765849, lng: 18.5708257 },
    { lat: 48.8769593, lng: 18.5696375 },
    { lat: 48.876997, lng: 18.5696877 },
    { lat: 48.8769911, lng: 18.5697887 },
    { lat: 48.8769959, lng: 18.5701049 },
    { lat: 48.8771496, lng: 18.5702155 },
    { lat: 48.8772867, lng: 18.5698301 },
    { lat: 48.877373, lng: 18.5696133 },
    { lat: 48.877534, lng: 18.5692299 },
    { lat: 48.8775859, lng: 18.5692704 },
    { lat: 48.8776867, lng: 18.5690289 },
    { lat: 48.8778272, lng: 18.569149 },
    { lat: 48.8778549, lng: 18.5691144 },
    { lat: 48.8781868, lng: 18.5684736 },
    { lat: 48.8783001, lng: 18.5682782 },
    { lat: 48.8781949, lng: 18.568187 },
    { lat: 48.8782472, lng: 18.5681314 },
    { lat: 48.8783388, lng: 18.5680759 },
    { lat: 48.8785538, lng: 18.5679833 },
    { lat: 48.8787476, lng: 18.5678567 },
    { lat: 48.8788002, lng: 18.5677226 },
    { lat: 48.8787406, lng: 18.5674118 },
    { lat: 48.8787475, lng: 18.5673296 },
    { lat: 48.8789581, lng: 18.5673474 },
    { lat: 48.8791626, lng: 18.5673532 },
    { lat: 48.8792351, lng: 18.5670019 },
    { lat: 48.8792671, lng: 18.5667431 },
    { lat: 48.8792454, lng: 18.566696 },
    { lat: 48.8791919, lng: 18.566645 },
    { lat: 48.8790635, lng: 18.5664176 },
    { lat: 48.8791503, lng: 18.5663757 },
    { lat: 48.8793146, lng: 18.5663889 },
    { lat: 48.8794352, lng: 18.5662191 },
    { lat: 48.8795376, lng: 18.5660283 },
    { lat: 48.8795203, lng: 18.5659569 },
    { lat: 48.8799084, lng: 18.5655653 },
    { lat: 48.8799686, lng: 18.5654898 },
    { lat: 48.8799773, lng: 18.5654012 },
    { lat: 48.8801138, lng: 18.5652115 },
    { lat: 48.8801376, lng: 18.5650921 },
    { lat: 48.880328, lng: 18.5651071 },
    { lat: 48.8803861, lng: 18.5649927 },
    { lat: 48.8803705, lng: 18.5648936 },
    { lat: 48.8803182, lng: 18.5648438 },
    { lat: 48.8804155, lng: 18.564764 },
    { lat: 48.880117, lng: 18.5641873 },
    { lat: 48.8800883, lng: 18.564159 },
    { lat: 48.880138, lng: 18.5640557 },
    { lat: 48.8798995, lng: 18.5639635 },
    { lat: 48.8799683, lng: 18.5638819 },
    { lat: 48.8801053, lng: 18.563849 },
    { lat: 48.880132, lng: 18.5636142 },
    { lat: 48.880104, lng: 18.5635105 },
    { lat: 48.8801462, lng: 18.5634788 },
    { lat: 48.8802368, lng: 18.5633406 },
    { lat: 48.8803329, lng: 18.5631771 },
    { lat: 48.8803793, lng: 18.5629509 },
    { lat: 48.8806783, lng: 18.5629728 },
    { lat: 48.8806815, lng: 18.5627843 },
    { lat: 48.8806038, lng: 18.562742 },
    { lat: 48.8806198, lng: 18.5623508 },
    { lat: 48.8806387, lng: 18.5623463 },
    { lat: 48.8806844, lng: 18.5623793 },
    { lat: 48.880822, lng: 18.5621042 },
    { lat: 48.8808654, lng: 18.5619941 },
    { lat: 48.8810316, lng: 18.5621049 },
    { lat: 48.8810759, lng: 18.5618568 },
    { lat: 48.8811567, lng: 18.5617034 },
    { lat: 48.8811906, lng: 18.5615841 },
    { lat: 48.8812879, lng: 18.5615036 },
    { lat: 48.8812888, lng: 18.561415 },
    { lat: 48.8815071, lng: 18.5613039 },
    { lat: 48.8815314, lng: 18.5612127 },
    { lat: 48.8815632, lng: 18.5610267 },
    { lat: 48.8815385, lng: 18.5608628 },
    { lat: 48.88128, lng: 18.5607946 },
    { lat: 48.8812251, lng: 18.5608026 },
    { lat: 48.8809299, lng: 18.5607458 },
    { lat: 48.8807093, lng: 18.5607364 },
    { lat: 48.8806816, lng: 18.5607656 },
    { lat: 48.8805875, lng: 18.5607551 },
    { lat: 48.8804716, lng: 18.5607807 },
    { lat: 48.8804727, lng: 18.5606565 },
    { lat: 48.8805595, lng: 18.5601347 },
    { lat: 48.8804618, lng: 18.5600594 },
    { lat: 48.880496, lng: 18.5599405 },
    { lat: 48.8805533, lng: 18.5597925 },
    { lat: 48.8806854, lng: 18.559627 },
    { lat: 48.8805661, lng: 18.5593186 },
    { lat: 48.8804915, lng: 18.5593992 },
    { lat: 48.8804543, lng: 18.5595235 },
    { lat: 48.8803389, lng: 18.5598103 },
    { lat: 48.8802803, lng: 18.5598548 },
    { lat: 48.8802331, lng: 18.5597944 },
    { lat: 48.8803584, lng: 18.5595674 },
    { lat: 48.8804313, lng: 18.5594135 },
    { lat: 48.8805237, lng: 18.5592461 },
    { lat: 48.8807225, lng: 18.5589597 },
    { lat: 48.8808832, lng: 18.5587193 },
    { lat: 48.8809847, lng: 18.5585945 },
    { lat: 48.8812118, lng: 18.5583723 },
    { lat: 48.8813194, lng: 18.558298 },
    { lat: 48.8817014, lng: 18.558021 },
    { lat: 48.8819879, lng: 18.55779 },
    { lat: 48.8821432, lng: 18.5576202 },
    { lat: 48.8826982, lng: 18.5571191 },
    { lat: 48.8830299, lng: 18.5568495 },
    { lat: 48.8833543, lng: 18.5566349 },
    { lat: 48.8835627, lng: 18.5565021 },
    { lat: 48.8835877, lng: 18.5566108 },
    { lat: 48.8835725, lng: 18.5566224 },
    { lat: 48.8836117, lng: 18.5567444 },
    { lat: 48.8836451, lng: 18.5569015 },
    { lat: 48.8836997, lng: 18.5570757 },
    { lat: 48.8837237, lng: 18.5570616 },
    { lat: 48.8838077, lng: 18.5573421 },
    { lat: 48.8838744, lng: 18.5573044 },
    { lat: 48.8839099, lng: 18.557396 },
    { lat: 48.884249, lng: 18.5572212 },
    { lat: 48.8843441, lng: 18.5571659 },
    { lat: 48.884388, lng: 18.5571641 },
    { lat: 48.8845692, lng: 18.5570533 },
    { lat: 48.8844332, lng: 18.5566857 },
    { lat: 48.8844103, lng: 18.5565993 },
    { lat: 48.8843263, lng: 18.5563582 },
    { lat: 48.884366, lng: 18.5561959 },
    { lat: 48.8845552, lng: 18.5559182 },
    { lat: 48.8845323, lng: 18.5558494 },
    { lat: 48.8847043, lng: 18.5557789 },
    { lat: 48.8848877, lng: 18.5556037 },
    { lat: 48.8849789, lng: 18.5554995 },
    { lat: 48.8850723, lng: 18.5553678 },
    { lat: 48.8851155, lng: 18.5552886 },
    { lat: 48.8853182, lng: 18.5550135 },
    { lat: 48.8853974, lng: 18.554895 },
    { lat: 48.8858262, lng: 18.5543169 },
    { lat: 48.8860706, lng: 18.5538502 },
    { lat: 48.8862415, lng: 18.5532795 },
    { lat: 48.8862857, lng: 18.5531567 },
    { lat: 48.8863169, lng: 18.553193 },
    { lat: 48.8864047, lng: 18.5530419 },
    { lat: 48.8865613, lng: 18.5528536 },
    { lat: 48.8871964, lng: 18.5523776 },
    { lat: 48.8873795, lng: 18.5522361 },
    { lat: 48.8875133, lng: 18.5521237 },
    { lat: 48.88764, lng: 18.5520068 },
    { lat: 48.8877829, lng: 18.5518575 },
    { lat: 48.8879267, lng: 18.5517195 },
    { lat: 48.8880621, lng: 18.5515743 },
    { lat: 48.8882152, lng: 18.5514258 },
    { lat: 48.8883306, lng: 18.5513483 },
    { lat: 48.8885896, lng: 18.5511554 },
    { lat: 48.8888003, lng: 18.5510044 },
    { lat: 48.888872, lng: 18.5512453 },
    { lat: 48.8890288, lng: 18.5510995 },
    { lat: 48.8891972, lng: 18.5509194 },
    { lat: 48.8891558, lng: 18.5507561 },
    { lat: 48.8894869, lng: 18.5505821 },
    { lat: 48.8897421, lng: 18.5504016 },
    { lat: 48.8898684, lng: 18.5502772 },
    { lat: 48.8900141, lng: 18.5501138 },
    { lat: 48.8901886, lng: 18.5499397 },
    { lat: 48.8902131, lng: 18.5501621 },
    { lat: 48.8902662, lng: 18.550125 },
    { lat: 48.8908555, lng: 18.5499655 },
    { lat: 48.891012, lng: 18.549795 },
    { lat: 48.8911282, lng: 18.549816 },
    { lat: 48.8916758, lng: 18.5499498 },
    { lat: 48.8920511, lng: 18.5500055 },
    { lat: 48.8922074, lng: 18.5501251 },
    { lat: 48.8923145, lng: 18.550187 },
    { lat: 48.8924041, lng: 18.5503167 },
    { lat: 48.8926088, lng: 18.55037 },
    { lat: 48.8927161, lng: 18.5503742 },
    { lat: 48.8927773, lng: 18.5504615 },
    { lat: 48.8928817, lng: 18.5504947 },
    { lat: 48.8929908, lng: 18.5505707 },
    { lat: 48.8930623, lng: 18.5505173 },
    { lat: 48.8931712, lng: 18.5506557 },
    { lat: 48.8932704, lng: 18.5507538 },
    { lat: 48.8933181, lng: 18.5508453 },
    { lat: 48.8934164, lng: 18.5508915 },
    { lat: 48.8935113, lng: 18.5508725 },
    { lat: 48.8939943, lng: 18.5512707 },
    { lat: 48.8940146, lng: 18.5514793 },
    { lat: 48.8942201, lng: 18.5515856 },
    { lat: 48.8943095, lng: 18.5516135 },
    { lat: 48.8943036, lng: 18.5516483 },
    { lat: 48.8943666, lng: 18.551668 },
    { lat: 48.8944085, lng: 18.551856 },
    { lat: 48.8944995, lng: 18.5521009 },
    { lat: 48.8945973, lng: 18.5518471 },
    { lat: 48.8947093, lng: 18.5519339 },
    { lat: 48.8946783, lng: 18.5520499 },
    { lat: 48.8950407, lng: 18.5523653 },
    { lat: 48.8951554, lng: 18.552237 },
    { lat: 48.89525, lng: 18.5523597 },
    { lat: 48.8953139, lng: 18.5524951 },
    { lat: 48.8953845, lng: 18.5527393 },
    { lat: 48.8954075, lng: 18.5528917 },
    { lat: 48.8955094, lng: 18.5528138 },
    { lat: 48.8956367, lng: 18.5525419 },
    { lat: 48.8952856, lng: 18.551748 },
    { lat: 48.8951183, lng: 18.551614 },
    { lat: 48.8950147, lng: 18.5519076 },
    { lat: 48.8949623, lng: 18.5518575 },
    { lat: 48.8947378, lng: 18.5515439 },
    { lat: 48.8945886, lng: 18.551417 },
    { lat: 48.8943912, lng: 18.55129 },
    { lat: 48.894296, lng: 18.551156 },
    { lat: 48.8941938, lng: 18.551063 },
    { lat: 48.8939586, lng: 18.5509041 },
    { lat: 48.8937252, lng: 18.5507411 },
    { lat: 48.893555, lng: 18.5506362 },
    { lat: 48.8936317, lng: 18.5503033 },
    { lat: 48.8936225, lng: 18.5502811 },
    { lat: 48.8931374, lng: 18.5499664 },
    { lat: 48.8929432, lng: 18.5498375 },
    { lat: 48.8925934, lng: 18.5496285 },
    { lat: 48.8925015, lng: 18.5495987 },
    { lat: 48.8921124, lng: 18.5495143 },
    { lat: 48.8920782, lng: 18.5498003 },
    { lat: 48.8918761, lng: 18.5497782 },
    { lat: 48.8914925, lng: 18.5496899 },
    { lat: 48.8910369, lng: 18.5495482 },
    { lat: 48.8909367, lng: 18.5494263 },
    { lat: 48.8910228, lng: 18.548932 },
    { lat: 48.8910489, lng: 18.5485635 },
    { lat: 48.8910938, lng: 18.548498 },
    { lat: 48.8910445, lng: 18.5484178 },
    { lat: 48.8911754, lng: 18.5482115 },
    { lat: 48.8912771, lng: 18.5479246 },
    { lat: 48.8914102, lng: 18.5476344 },
    { lat: 48.8915163, lng: 18.5474463 },
    { lat: 48.8916034, lng: 18.5473085 },
    { lat: 48.891779, lng: 18.5470919 },
    { lat: 48.891937, lng: 18.5469059 },
    { lat: 48.8920578, lng: 18.5467844 },
    { lat: 48.892192, lng: 18.5466784 },
    { lat: 48.8926589, lng: 18.5463673 },
    { lat: 48.8928084, lng: 18.5462734 },
    { lat: 48.8931003, lng: 18.5461262 },
    { lat: 48.8932062, lng: 18.5460821 },
    { lat: 48.8933709, lng: 18.5459558 },
    { lat: 48.8934606, lng: 18.5458442 },
    { lat: 48.8935366, lng: 18.5457247 },
    { lat: 48.8938922, lng: 18.5450472 },
    { lat: 48.8940041, lng: 18.5447583 },
    { lat: 48.8940855, lng: 18.5448464 },
    { lat: 48.8943271, lng: 18.5443506 },
    { lat: 48.8942927, lng: 18.5443014 },
    { lat: 48.8944123, lng: 18.5441739 },
    { lat: 48.8945038, lng: 18.5440532 },
    { lat: 48.8945418, lng: 18.5439875 },
    { lat: 48.8946717, lng: 18.5435716 },
    { lat: 48.8950486, lng: 18.5424734 },
    { lat: 48.8951838, lng: 18.5420386 },
    { lat: 48.8952197, lng: 18.5418244 },
    { lat: 48.8952643, lng: 18.5416109 },
    { lat: 48.8953468, lng: 18.5416547 },
    { lat: 48.8953924, lng: 18.5414974 },
    { lat: 48.8954121, lng: 18.5415074 },
    { lat: 48.895452, lng: 18.5413275 },
    { lat: 48.8954928, lng: 18.5413095 },
    { lat: 48.8955309, lng: 18.5413062 },
    { lat: 48.895556, lng: 18.5412891 },
    { lat: 48.8957521, lng: 18.541223 },
    { lat: 48.8957777, lng: 18.5410495 },
    { lat: 48.8955654, lng: 18.5410276 },
    { lat: 48.8953935, lng: 18.5409946 },
    { lat: 48.8953632, lng: 18.5409378 },
    { lat: 48.8953111, lng: 18.540953 },
    { lat: 48.8952282, lng: 18.5413817 },
    { lat: 48.8949114, lng: 18.5412366 },
    { lat: 48.8950275, lng: 18.5408852 },
    { lat: 48.8950306, lng: 18.5407751 },
    { lat: 48.8950913, lng: 18.5405395 },
    { lat: 48.8952454, lng: 18.5404321 },
    { lat: 48.8952762, lng: 18.5403325 },
    { lat: 48.8952416, lng: 18.5401748 },
    { lat: 48.8951969, lng: 18.5400348 },
    { lat: 48.8952181, lng: 18.5399903 },
    { lat: 48.8953917, lng: 18.5399883 },
    { lat: 48.8953941, lng: 18.5398664 },
    { lat: 48.8953854, lng: 18.5395406 },
    { lat: 48.8953839, lng: 18.5395347 },
    { lat: 48.8953529, lng: 18.5389826 },
    { lat: 48.8953563, lng: 18.5386104 },
    { lat: 48.8955563, lng: 18.538532 },
    { lat: 48.8954378, lng: 18.5376011 },
    { lat: 48.8952652, lng: 18.5376607 },
    { lat: 48.895255, lng: 18.5375333 },
    { lat: 48.895254, lng: 18.5369147 },
    { lat: 48.8952045, lng: 18.5365219 },
    { lat: 48.8951693, lng: 18.5361653 },
    { lat: 48.8951451, lng: 18.5359687 },
    { lat: 48.8951078, lng: 18.5358038 },
    { lat: 48.8950704, lng: 18.5356948 },
    { lat: 48.8949747, lng: 18.5355555 },
    { lat: 48.8945735, lng: 18.5350189 },
    { lat: 48.8944736, lng: 18.5348647 },
    { lat: 48.8945684, lng: 18.5347614 },
    { lat: 48.8943633, lng: 18.5340404 },
    { lat: 48.8942398, lng: 18.5340085 },
    { lat: 48.8943168, lng: 18.5331633 },
    { lat: 48.8944078, lng: 18.5325769 },
    { lat: 48.8945333, lng: 18.5319597 },
    { lat: 48.8947075, lng: 18.5316558 },
    { lat: 48.8952979, lng: 18.5304226 },
    { lat: 48.8954763, lng: 18.5300124 },
    { lat: 48.8960258, lng: 18.5291251 },
    { lat: 48.896237, lng: 18.5288596 },
    { lat: 48.8963511, lng: 18.5286754 },
    { lat: 48.8964511, lng: 18.5285248 },
    { lat: 48.8966699, lng: 18.5282748 },
    { lat: 48.8971902, lng: 18.5274729 },
    { lat: 48.8972626, lng: 18.5267924 },
    { lat: 48.8972434, lng: 18.5264618 },
    { lat: 48.8972184, lng: 18.526229 },
    { lat: 48.8971663, lng: 18.5259803 },
    { lat: 48.897079, lng: 18.5257439 },
    { lat: 48.8969966, lng: 18.5255764 },
    { lat: 48.8966315, lng: 18.5250446 },
    { lat: 48.8964246, lng: 18.5246597 },
    { lat: 48.8962517, lng: 18.5243458 },
    { lat: 48.8960444, lng: 18.5238735 },
    { lat: 48.8958956, lng: 18.523524 },
    { lat: 48.8956965, lng: 18.523222 },
    { lat: 48.895612, lng: 18.5230164 },
    { lat: 48.8956152, lng: 18.5226192 },
    { lat: 48.895532, lng: 18.5221682 },
    { lat: 48.8954215, lng: 18.5218419 },
    { lat: 48.8952123, lng: 18.5214376 },
    { lat: 48.8950623, lng: 18.5210011 },
    { lat: 48.8949935, lng: 18.5206936 },
    { lat: 48.894874, lng: 18.5202794 },
    { lat: 48.894781, lng: 18.5197946 },
    { lat: 48.894688, lng: 18.5194123 },
    { lat: 48.8945967, lng: 18.5189162 },
    { lat: 48.8945591, lng: 18.5186004 },
    { lat: 48.8944927, lng: 18.5183356 },
    { lat: 48.8943181, lng: 18.5179251 },
    { lat: 48.8942467, lng: 18.5177133 },
    { lat: 48.8941309, lng: 18.5172241 },
    { lat: 48.8940299, lng: 18.5168791 },
    { lat: 48.8939186, lng: 18.5164338 },
    { lat: 48.8937541, lng: 18.516044 },
    { lat: 48.8933736, lng: 18.5154777 },
    { lat: 48.8930695, lng: 18.5150728 },
    { lat: 48.8925752, lng: 18.5146111 },
    { lat: 48.8924384, lng: 18.5144612 },
    { lat: 48.8923207, lng: 18.5143538 },
    { lat: 48.8922131, lng: 18.5142178 },
    { lat: 48.8923385, lng: 18.5139952 },
    { lat: 48.8926288, lng: 18.5135706 },
    { lat: 48.8925507, lng: 18.5135219 },
    { lat: 48.8924856, lng: 18.5135068 },
    { lat: 48.8924257, lng: 18.5134659 },
    { lat: 48.8923598, lng: 18.5133323 },
    { lat: 48.8922599, lng: 18.5132609 },
    { lat: 48.8922326, lng: 18.5132152 },
    { lat: 48.8921892, lng: 18.5132049 },
    { lat: 48.8921039, lng: 18.5131095 },
    { lat: 48.8918971, lng: 18.5128935 },
    { lat: 48.8918859, lng: 18.5128358 },
    { lat: 48.8918483, lng: 18.5128047 },
    { lat: 48.8917617, lng: 18.5126858 },
    { lat: 48.8916797, lng: 18.5126513 },
    { lat: 48.8916376, lng: 18.5126038 },
    { lat: 48.8916099, lng: 18.5125295 },
    { lat: 48.8914479, lng: 18.5124531 },
    { lat: 48.8913571, lng: 18.5123182 },
    { lat: 48.8913266, lng: 18.5123192 },
    { lat: 48.8911878, lng: 18.5121975 },
    { lat: 48.8911382, lng: 18.5121152 },
    { lat: 48.8910562, lng: 18.5120599 },
    { lat: 48.8910024, lng: 18.5120653 },
    { lat: 48.8909231, lng: 18.5119555 },
    { lat: 48.8907759, lng: 18.5119034 },
    { lat: 48.8907197, lng: 18.5118445 },
    { lat: 48.8906212, lng: 18.511798 },
    { lat: 48.8904797, lng: 18.5116835 },
    { lat: 48.8905476, lng: 18.5113589 },
    { lat: 48.8907842, lng: 18.5108793 },
    { lat: 48.8909616, lng: 18.5107993 },
    { lat: 48.891298, lng: 18.5102674 },
    { lat: 48.8914181, lng: 18.510172 },
    { lat: 48.8918864, lng: 18.5105882 },
    { lat: 48.8920659, lng: 18.510676 },
    { lat: 48.8922639, lng: 18.5106239 },
    { lat: 48.8923206, lng: 18.5098862 },
    { lat: 48.8923812, lng: 18.5098403 },
    { lat: 48.8924998, lng: 18.5092436 },
    { lat: 48.8924683, lng: 18.5091429 },
    { lat: 48.8919639, lng: 18.5086015 },
    { lat: 48.891863, lng: 18.5084683 },
    { lat: 48.8917692, lng: 18.5082395 },
    { lat: 48.8916883, lng: 18.5080568 },
    { lat: 48.8914938, lng: 18.507759 },
    { lat: 48.8913503, lng: 18.5076319 },
    { lat: 48.8910413, lng: 18.5074442 },
    { lat: 48.8910316, lng: 18.5073715 },
    { lat: 48.8910494, lng: 18.5073073 },
    { lat: 48.8906758, lng: 18.5073435 },
    { lat: 48.8904398, lng: 18.5074631 },
    { lat: 48.8903699, lng: 18.5075041 },
    { lat: 48.8898681, lng: 18.5077543 },
    { lat: 48.8896017, lng: 18.5078968 },
    { lat: 48.8894861, lng: 18.5079274 },
    { lat: 48.8892974, lng: 18.5079002 },
    { lat: 48.8891813, lng: 18.5079212 },
    { lat: 48.8885981, lng: 18.5081092 },
    { lat: 48.8885122, lng: 18.508087 },
    { lat: 48.8882455, lng: 18.5079944 },
    { lat: 48.888026, lng: 18.5081474 },
    { lat: 48.8877389, lng: 18.5086717 },
    { lat: 48.8877352, lng: 18.5086872 },
    { lat: 48.8875439, lng: 18.5090438 },
    { lat: 48.8874053, lng: 18.5094197 },
    { lat: 48.887245, lng: 18.5104699 },
    { lat: 48.8871149, lng: 18.5112702 },
    { lat: 48.8870762, lng: 18.5113879 },
    { lat: 48.8868903, lng: 18.5116403 },
    { lat: 48.8866718, lng: 18.512038 },
    { lat: 48.8864092, lng: 18.5125577 },
    { lat: 48.8864153, lng: 18.5129372 },
    { lat: 48.8863366, lng: 18.5131116 },
    { lat: 48.8861271, lng: 18.5133426 },
    { lat: 48.8859981, lng: 18.5133964 },
    { lat: 48.8857835, lng: 18.5134527 },
    { lat: 48.8854481, lng: 18.5136959 },
    { lat: 48.8850803, lng: 18.5138779 },
    { lat: 48.8847978, lng: 18.5140366 },
    { lat: 48.8842716, lng: 18.5140238 },
    { lat: 48.8837644, lng: 18.5141584 },
    { lat: 48.8836268, lng: 18.514218 },
    { lat: 48.8833628, lng: 18.5143232 },
    { lat: 48.8828671, lng: 18.5142696 },
    { lat: 48.8817, lng: 18.5140068 },
    { lat: 48.8814109, lng: 18.5140252 },
    { lat: 48.8810284, lng: 18.5138761 },
    { lat: 48.8803319, lng: 18.514319 },
    { lat: 48.8795327, lng: 18.5147035 },
    { lat: 48.8787231, lng: 18.5146857 },
    { lat: 48.8783373, lng: 18.5149756 },
    { lat: 48.8778371, lng: 18.5151742 },
    { lat: 48.8775, lng: 18.5153842 },
    { lat: 48.8772154, lng: 18.5159134 },
    { lat: 48.8768345, lng: 18.5162704 },
    { lat: 48.8764416, lng: 18.5164512 },
  ],
  Cigeľ: [
    { lat: 48.7271578, lng: 18.6977651 },
    { lat: 48.7271008, lng: 18.6968412 },
    { lat: 48.7270543, lng: 18.6956262 },
    { lat: 48.7269575, lng: 18.6935957 },
    { lat: 48.7269326, lng: 18.6929779 },
    { lat: 48.7269286, lng: 18.6929038 },
    { lat: 48.7268828, lng: 18.6917869 },
    { lat: 48.726779, lng: 18.6894119 },
    { lat: 48.7266429, lng: 18.6864768 },
    { lat: 48.7265545, lng: 18.684413 },
    { lat: 48.7257905, lng: 18.6830707 },
    { lat: 48.7252254, lng: 18.6801282 },
    { lat: 48.7251829, lng: 18.6799658 },
    { lat: 48.7251811, lng: 18.6797189 },
    { lat: 48.7250723, lng: 18.6794138 },
    { lat: 48.7247436, lng: 18.6790025 },
    { lat: 48.7246971, lng: 18.6788284 },
    { lat: 48.7246819, lng: 18.67867 },
    { lat: 48.7247282, lng: 18.6785876 },
    { lat: 48.7247367, lng: 18.6785133 },
    { lat: 48.7250661, lng: 18.6777738 },
    { lat: 48.7248639, lng: 18.6760545 },
    { lat: 48.7250304, lng: 18.6749706 },
    { lat: 48.7245266, lng: 18.6740451 },
    { lat: 48.7243252, lng: 18.6736533 },
    { lat: 48.7228942, lng: 18.6717967 },
    { lat: 48.7230137, lng: 18.670446 },
    { lat: 48.7232145, lng: 18.6685453 },
    { lat: 48.7233385, lng: 18.6671728 },
    { lat: 48.7233527, lng: 18.667015 },
    { lat: 48.7234979, lng: 18.665404 },
    { lat: 48.7229062, lng: 18.6641676 },
    { lat: 48.7222088, lng: 18.6627679 },
    { lat: 48.7221687, lng: 18.662397 },
    { lat: 48.7219958, lng: 18.6604079 },
    { lat: 48.721803, lng: 18.6586179 },
    { lat: 48.721785, lng: 18.6584496 },
    { lat: 48.7219232, lng: 18.6576806 },
    { lat: 48.7218188, lng: 18.6572235 },
    { lat: 48.7217796, lng: 18.6564064 },
    { lat: 48.7219499, lng: 18.6550582 },
    { lat: 48.7219612, lng: 18.6547225 },
    { lat: 48.7221465, lng: 18.6540337 },
    { lat: 48.7221919, lng: 18.6537424 },
    { lat: 48.7221508, lng: 18.6520918 },
    { lat: 48.7218255, lng: 18.6504185 },
    { lat: 48.7208117, lng: 18.6477918 },
    { lat: 48.7207927, lng: 18.6469511 },
    { lat: 48.7207401, lng: 18.6444772 },
    { lat: 48.7207984, lng: 18.6440511 },
    { lat: 48.7204771, lng: 18.6434854 },
    { lat: 48.72061, lng: 18.6427476 },
    { lat: 48.7208171, lng: 18.6417306 },
    { lat: 48.7210515, lng: 18.6408436 },
    { lat: 48.7211967, lng: 18.6382072 },
    { lat: 48.7211188, lng: 18.6380196 },
    { lat: 48.7205867, lng: 18.6368028 },
    { lat: 48.7203736, lng: 18.6365136 },
    { lat: 48.7202682, lng: 18.6363779 },
    { lat: 48.7193461, lng: 18.635209 },
    { lat: 48.7186754, lng: 18.6341233 },
    { lat: 48.7183876, lng: 18.6335136 },
    { lat: 48.7181164, lng: 18.6330843 },
    { lat: 48.7174987, lng: 18.6320829 },
    { lat: 48.7173576, lng: 18.6318727 },
    { lat: 48.7169091, lng: 18.6313098 },
    { lat: 48.7158259, lng: 18.629898 },
    { lat: 48.7139601, lng: 18.628152 },
    { lat: 48.7130343, lng: 18.6273768 },
    { lat: 48.7130065, lng: 18.6273445 },
    { lat: 48.7125678, lng: 18.6269869 },
    { lat: 48.7120735, lng: 18.6266466 },
    { lat: 48.7118692, lng: 18.6264959 },
    { lat: 48.7115306, lng: 18.6263043 },
    { lat: 48.7113663, lng: 18.6262077 },
    { lat: 48.7108163, lng: 18.625921 },
    { lat: 48.7105544, lng: 18.6258542 },
    { lat: 48.7102347, lng: 18.6258428 },
    { lat: 48.7100413, lng: 18.6258555 },
    { lat: 48.7100189, lng: 18.6258161 },
    { lat: 48.7099589, lng: 18.6258742 },
    { lat: 48.7098281, lng: 18.6261041 },
    { lat: 48.7098254, lng: 18.6263059 },
    { lat: 48.7097445, lng: 18.6265844 },
    { lat: 48.7096608, lng: 18.6265811 },
    { lat: 48.7096412, lng: 18.6267824 },
    { lat: 48.7096731, lng: 18.6269578 },
    { lat: 48.70962, lng: 18.6269771 },
    { lat: 48.7096036, lng: 18.6271127 },
    { lat: 48.7095209, lng: 18.6271686 },
    { lat: 48.7095064, lng: 18.6273604 },
    { lat: 48.7093305, lng: 18.627988 },
    { lat: 48.7093387, lng: 18.6281023 },
    { lat: 48.7093077, lng: 18.6282368 },
    { lat: 48.7092411, lng: 18.6284446 },
    { lat: 48.7093728, lng: 18.6287142 },
    { lat: 48.7093285, lng: 18.6287914 },
    { lat: 48.7092937, lng: 18.6291721 },
    { lat: 48.7092765, lng: 18.6295241 },
    { lat: 48.7092938, lng: 18.6297413 },
    { lat: 48.7092478, lng: 18.6301201 },
    { lat: 48.7092496, lng: 18.6304481 },
    { lat: 48.7093644, lng: 18.6306758 },
    { lat: 48.7092748, lng: 18.6310232 },
    { lat: 48.7092993, lng: 18.6313512 },
    { lat: 48.7092221, lng: 18.6314885 },
    { lat: 48.7093103, lng: 18.6319569 },
    { lat: 48.709225, lng: 18.632572 },
    { lat: 48.7093083, lng: 18.632971 },
    { lat: 48.7092136, lng: 18.6333051 },
    { lat: 48.70909, lng: 18.6333992 },
    { lat: 48.7089039, lng: 18.634047 },
    { lat: 48.7088764, lng: 18.6342925 },
    { lat: 48.7088307, lng: 18.6344484 },
    { lat: 48.7088214, lng: 18.6346549 },
    { lat: 48.70867, lng: 18.6347963 },
    { lat: 48.7085281, lng: 18.6348212 },
    { lat: 48.7081799, lng: 18.6356042 },
    { lat: 48.7081724, lng: 18.6356101 },
    { lat: 48.7079958, lng: 18.6356211 },
    { lat: 48.7079248, lng: 18.6356019 },
    { lat: 48.7078765, lng: 18.6356043 },
    { lat: 48.7077389, lng: 18.635644 },
    { lat: 48.7076895, lng: 18.6357536 },
    { lat: 48.70765, lng: 18.6357531 },
    { lat: 48.7075808, lng: 18.635873 },
    { lat: 48.7072835, lng: 18.636027 },
    { lat: 48.7072606, lng: 18.6361465 },
    { lat: 48.7072428, lng: 18.6361941 },
    { lat: 48.7071251, lng: 18.6363788 },
    { lat: 48.7069349, lng: 18.636445 },
    { lat: 48.7068285, lng: 18.6365234 },
    { lat: 48.7067226, lng: 18.6365453 },
    { lat: 48.7066016, lng: 18.6365201 },
    { lat: 48.7065597, lng: 18.6366406 },
    { lat: 48.7065243, lng: 18.6366328 },
    { lat: 48.7064286, lng: 18.6367842 },
    { lat: 48.7053795, lng: 18.6371799 },
    { lat: 48.7051575, lng: 18.6372671 },
    { lat: 48.7049656, lng: 18.6373503 },
    { lat: 48.7047559, lng: 18.6374156 },
    { lat: 48.7045733, lng: 18.6375415 },
    { lat: 48.7044285, lng: 18.6376825 },
    { lat: 48.7043502, lng: 18.6376289 },
    { lat: 48.704254, lng: 18.6376857 },
    { lat: 48.7039733, lng: 18.6377747 },
    { lat: 48.7038957, lng: 18.6379674 },
    { lat: 48.7037631, lng: 18.6381211 },
    { lat: 48.7037349, lng: 18.6381635 },
    { lat: 48.7035516, lng: 18.6385102 },
    { lat: 48.7033459, lng: 18.6387361 },
    { lat: 48.7032827, lng: 18.6389413 },
    { lat: 48.7032452, lng: 18.6391897 },
    { lat: 48.7031339, lng: 18.6394055 },
    { lat: 48.7029055, lng: 18.6397527 },
    { lat: 48.7028533, lng: 18.6398094 },
    { lat: 48.7026762, lng: 18.6400646 },
    { lat: 48.702491, lng: 18.6402062 },
    { lat: 48.7023633, lng: 18.6402444 },
    { lat: 48.7022595, lng: 18.6402439 },
    { lat: 48.7021192, lng: 18.640214 },
    { lat: 48.7021197, lng: 18.6402018 },
    { lat: 48.701897, lng: 18.6402751 },
    { lat: 48.7019018, lng: 18.6403259 },
    { lat: 48.7018727, lng: 18.6403698 },
    { lat: 48.7017989, lng: 18.6404435 },
    { lat: 48.7017343, lng: 18.6404811 },
    { lat: 48.701627, lng: 18.6406652 },
    { lat: 48.7015463, lng: 18.6407169 },
    { lat: 48.701482, lng: 18.6407376 },
    { lat: 48.7013035, lng: 18.6407552 },
    { lat: 48.701109, lng: 18.6406801 },
    { lat: 48.7009643, lng: 18.6408018 },
    { lat: 48.7008435, lng: 18.6409904 },
    { lat: 48.7007366, lng: 18.6412056 },
    { lat: 48.7005846, lng: 18.6416499 },
    { lat: 48.700508, lng: 18.6419891 },
    { lat: 48.7004401, lng: 18.642217 },
    { lat: 48.7003239, lng: 18.6424241 },
    { lat: 48.7002598, lng: 18.6427843 },
    { lat: 48.7002147, lng: 18.6431026 },
    { lat: 48.7001421, lng: 18.6433436 },
    { lat: 48.6998879, lng: 18.6435268 },
    { lat: 48.6998186, lng: 18.6436628 },
    { lat: 48.6997469, lng: 18.6438412 },
    { lat: 48.6997439, lng: 18.6439311 },
    { lat: 48.6997067, lng: 18.6440168 },
    { lat: 48.6996269, lng: 18.6441283 },
    { lat: 48.699214, lng: 18.6443352 },
    { lat: 48.6991102, lng: 18.64444 },
    { lat: 48.6987314, lng: 18.6445692 },
    { lat: 48.6983535, lng: 18.6448215 },
    { lat: 48.6978509, lng: 18.6451447 },
    { lat: 48.6975006, lng: 18.6451301 },
    { lat: 48.6971675, lng: 18.645459 },
    { lat: 48.6967471, lng: 18.6455301 },
    { lat: 48.6966277, lng: 18.6456183 },
    { lat: 48.6966259, lng: 18.6458057 },
    { lat: 48.6966223, lng: 18.6461688 },
    { lat: 48.69655, lng: 18.6463115 },
    { lat: 48.6964606, lng: 18.646411 },
    { lat: 48.6963495, lng: 18.6464646 },
    { lat: 48.6963908, lng: 18.6466262 },
    { lat: 48.695997, lng: 18.6468319 },
    { lat: 48.6957172, lng: 18.6466426 },
    { lat: 48.6956, lng: 18.6466837 },
    { lat: 48.6950697, lng: 18.6471238 },
    { lat: 48.6949262, lng: 18.6481914 },
    { lat: 48.6948982, lng: 18.6491002 },
    { lat: 48.6950105, lng: 18.6498697 },
    { lat: 48.6951108, lng: 18.6505404 },
    { lat: 48.6953528, lng: 18.6509103 },
    { lat: 48.6954413, lng: 18.6512129 },
    { lat: 48.6956135, lng: 18.6514589 },
    { lat: 48.6956517, lng: 18.651541 },
    { lat: 48.695781, lng: 18.6518708 },
    { lat: 48.6958042, lng: 18.6520079 },
    { lat: 48.6958009, lng: 18.6522643 },
    { lat: 48.6958838, lng: 18.6525465 },
    { lat: 48.6959198, lng: 18.6531783 },
    { lat: 48.6959146, lng: 18.6533332 },
    { lat: 48.695892, lng: 18.653457 },
    { lat: 48.6958212, lng: 18.6536102 },
    { lat: 48.6956864, lng: 18.6537049 },
    { lat: 48.6957682, lng: 18.6544956 },
    { lat: 48.695768, lng: 18.6556913 },
    { lat: 48.6957579, lng: 18.6560074 },
    { lat: 48.6953843, lng: 18.6565943 },
    { lat: 48.69502, lng: 18.6571629 },
    { lat: 48.6946434, lng: 18.6573938 },
    { lat: 48.694333, lng: 18.6581482 },
    { lat: 48.6940629, lng: 18.6589209 },
    { lat: 48.6935107, lng: 18.659833 },
    { lat: 48.6933783, lng: 18.6599959 },
    { lat: 48.6931501, lng: 18.660491 },
    { lat: 48.6929118, lng: 18.6610963 },
    { lat: 48.6926416, lng: 18.6616559 },
    { lat: 48.6924655, lng: 18.6618626 },
    { lat: 48.6919679, lng: 18.6627223 },
    { lat: 48.6914475, lng: 18.6637868 },
    { lat: 48.6911853, lng: 18.664452 },
    { lat: 48.6910271, lng: 18.6648486 },
    { lat: 48.6910038, lng: 18.664899 },
    { lat: 48.6908548, lng: 18.665277 },
    { lat: 48.6903881, lng: 18.6659986 },
    { lat: 48.6899565, lng: 18.6667223 },
    { lat: 48.6896847, lng: 18.6670796 },
    { lat: 48.6893707, lng: 18.6673035 },
    { lat: 48.688838, lng: 18.6677408 },
    { lat: 48.6885464, lng: 18.6686798 },
    { lat: 48.6882666, lng: 18.6694753 },
    { lat: 48.6876429, lng: 18.6702366 },
    { lat: 48.6871128, lng: 18.6705718 },
    { lat: 48.6869466, lng: 18.6708405 },
    { lat: 48.6868064, lng: 18.6710428 },
    { lat: 48.6866921, lng: 18.671186 },
    { lat: 48.6864952, lng: 18.6715312 },
    { lat: 48.6862164, lng: 18.6719921 },
    { lat: 48.686139, lng: 18.6721057 },
    { lat: 48.6854864, lng: 18.6733613 },
    { lat: 48.6854187, lng: 18.6736116 },
    { lat: 48.6853982, lng: 18.6739489 },
    { lat: 48.685333, lng: 18.6742459 },
    { lat: 48.6853052, lng: 18.6745084 },
    { lat: 48.6852316, lng: 18.6746705 },
    { lat: 48.6851579, lng: 18.6748744 },
    { lat: 48.684923, lng: 18.6758174 },
    { lat: 48.6847509, lng: 18.6764177 },
    { lat: 48.6847032, lng: 18.6767294 },
    { lat: 48.6847685, lng: 18.6769613 },
    { lat: 48.6850869, lng: 18.6775814 },
    { lat: 48.6852409, lng: 18.6777832 },
    { lat: 48.6853142, lng: 18.6779075 },
    { lat: 48.6853591, lng: 18.6780726 },
    { lat: 48.6854855, lng: 18.6783724 },
    { lat: 48.6855458, lng: 18.6787801 },
    { lat: 48.68547, lng: 18.679202 },
    { lat: 48.6854969, lng: 18.6794714 },
    { lat: 48.6854678, lng: 18.6797287 },
    { lat: 48.6854813, lng: 18.6800472 },
    { lat: 48.6854654, lng: 18.680298 },
    { lat: 48.6854737, lng: 18.6806457 },
    { lat: 48.6855455, lng: 18.6811231 },
    { lat: 48.6856298, lng: 18.6822603 },
    { lat: 48.6856646, lng: 18.6829128 },
    { lat: 48.685716, lng: 18.6834734 },
    { lat: 48.6858088, lng: 18.6847085 },
    { lat: 48.685847, lng: 18.6872862 },
    { lat: 48.6860811, lng: 18.6871079 },
    { lat: 48.6867712, lng: 18.6861723 },
    { lat: 48.6877382, lng: 18.686627 },
    { lat: 48.6882894, lng: 18.687064 },
    { lat: 48.6886659, lng: 18.6874408 },
    { lat: 48.6890712, lng: 18.6879366 },
    { lat: 48.6893899, lng: 18.6882456 },
    { lat: 48.6897412, lng: 18.6886594 },
    { lat: 48.6899724, lng: 18.68919 },
    { lat: 48.690193, lng: 18.689347 },
    { lat: 48.6907217, lng: 18.6896169 },
    { lat: 48.6924677, lng: 18.6894522 },
    { lat: 48.6929255, lng: 18.6892399 },
    { lat: 48.6942816, lng: 18.6893749 },
    { lat: 48.6948597, lng: 18.6893047 },
    { lat: 48.6954078, lng: 18.6900852 },
    { lat: 48.6964025, lng: 18.689894 },
    { lat: 48.6968555, lng: 18.692047 },
    { lat: 48.6974243, lng: 18.6934977 },
    { lat: 48.699043, lng: 18.6950934 },
    { lat: 48.7006368, lng: 18.6957107 },
    { lat: 48.7010035, lng: 18.696668 },
    { lat: 48.7026162, lng: 18.6989122 },
    { lat: 48.7034443, lng: 18.7010815 },
    { lat: 48.7045539, lng: 18.7033731 },
    { lat: 48.7056245, lng: 18.7052674 },
    { lat: 48.7068134, lng: 18.7066334 },
    { lat: 48.7074931, lng: 18.7074129 },
    { lat: 48.7083084, lng: 18.7090863 },
    { lat: 48.7122469, lng: 18.71394 },
    { lat: 48.7117765, lng: 18.7115361 },
    { lat: 48.7123595, lng: 18.7090047 },
    { lat: 48.7131629, lng: 18.7062079 },
    { lat: 48.7148822, lng: 18.7041792 },
    { lat: 48.7153537, lng: 18.7026085 },
    { lat: 48.7171439, lng: 18.702596 },
    { lat: 48.7173671, lng: 18.7028075 },
    { lat: 48.718279, lng: 18.7030719 },
    { lat: 48.7201409, lng: 18.7036436 },
    { lat: 48.7205367, lng: 18.7028595 },
    { lat: 48.7221839, lng: 18.7017663 },
    { lat: 48.7228576, lng: 18.7017117 },
    { lat: 48.7233531, lng: 18.7022927 },
    { lat: 48.7246446, lng: 18.7013792 },
    { lat: 48.725646, lng: 18.7001053 },
    { lat: 48.7260824, lng: 18.6989818 },
    { lat: 48.7261904, lng: 18.698805 },
    { lat: 48.7267651, lng: 18.6980737 },
    { lat: 48.7271578, lng: 18.6977651 },
  ],
  Dlžín: [
    { lat: 48.8236849, lng: 18.5414556 },
    { lat: 48.8236938, lng: 18.541472 },
    { lat: 48.8260195, lng: 18.541078 },
    { lat: 48.826833, lng: 18.5404599 },
    { lat: 48.8282399, lng: 18.5397175 },
    { lat: 48.8283412, lng: 18.539673 },
    { lat: 48.829438, lng: 18.5392439 },
    { lat: 48.8302674, lng: 18.5390878 },
    { lat: 48.8312384, lng: 18.5392486 },
    { lat: 48.8324453, lng: 18.5392891 },
    { lat: 48.8337392, lng: 18.5398927 },
    { lat: 48.8347467, lng: 18.5417415 },
    { lat: 48.8347597, lng: 18.5417717 },
    { lat: 48.834834, lng: 18.5417531 },
    { lat: 48.835636, lng: 18.541687 },
    { lat: 48.8369017, lng: 18.5410696 },
    { lat: 48.8381915, lng: 18.5397922 },
    { lat: 48.8395244, lng: 18.5390621 },
    { lat: 48.8408239, lng: 18.5386717 },
    { lat: 48.8404775, lng: 18.5378781 },
    { lat: 48.8401406, lng: 18.5370991 },
    { lat: 48.8388599, lng: 18.5341679 },
    { lat: 48.8385497, lng: 18.5334454 },
    { lat: 48.8382201, lng: 18.532692 },
    { lat: 48.83787, lng: 18.5318796 },
    { lat: 48.837425, lng: 18.5307483 },
    { lat: 48.8373564, lng: 18.5306097 },
    { lat: 48.8371053, lng: 18.5299751 },
    { lat: 48.8367211, lng: 18.5290091 },
    { lat: 48.8366573, lng: 18.5288327 },
    { lat: 48.8363514, lng: 18.5282512 },
    { lat: 48.8362009, lng: 18.5279696 },
    { lat: 48.8358557, lng: 18.5273088 },
    { lat: 48.8358391, lng: 18.5269224 },
    { lat: 48.8358013, lng: 18.5256689 },
    { lat: 48.8357556, lng: 18.5253073 },
    { lat: 48.8355531, lng: 18.5237658 },
    { lat: 48.8352542, lng: 18.5232486 },
    { lat: 48.8349432, lng: 18.5227164 },
    { lat: 48.8346213, lng: 18.5224005 },
    { lat: 48.8340965, lng: 18.5218976 },
    { lat: 48.8333742, lng: 18.5217242 },
    { lat: 48.8326193, lng: 18.5218259 },
    { lat: 48.8322306, lng: 18.521876 },
    { lat: 48.8322035, lng: 18.5218736 },
    { lat: 48.8318178, lng: 18.5217307 },
    { lat: 48.8314627, lng: 18.5215475 },
    { lat: 48.8314207, lng: 18.521522 },
    { lat: 48.8312024, lng: 18.5213467 },
    { lat: 48.8308127, lng: 18.5210675 },
    { lat: 48.8307574, lng: 18.5210673 },
    { lat: 48.8306998, lng: 18.5210863 },
    { lat: 48.8304979, lng: 18.5208381 },
    { lat: 48.8302897, lng: 18.5206833 },
    { lat: 48.8301441, lng: 18.5206178 },
    { lat: 48.8299206, lng: 18.5204689 },
    { lat: 48.8294367, lng: 18.5200566 },
    { lat: 48.8293021, lng: 18.5199467 },
    { lat: 48.8288526, lng: 18.5195655 },
    { lat: 48.8286345, lng: 18.5193737 },
    { lat: 48.8285137, lng: 18.5193291 },
    { lat: 48.8279714, lng: 18.5191063 },
    { lat: 48.8278507, lng: 18.5190445 },
    { lat: 48.8275887, lng: 18.5188514 },
    { lat: 48.8273788, lng: 18.5186872 },
    { lat: 48.8272387, lng: 18.5185365 },
    { lat: 48.8270544, lng: 18.5184465 },
    { lat: 48.8268396, lng: 18.5183957 },
    { lat: 48.8267384, lng: 18.5183994 },
    { lat: 48.8265236, lng: 18.5184958 },
    { lat: 48.8264149, lng: 18.5185312 },
    { lat: 48.8261515, lng: 18.5186029 },
    { lat: 48.8258095, lng: 18.5185992 },
    { lat: 48.8256082, lng: 18.5186709 },
    { lat: 48.8254115, lng: 18.5187264 },
    { lat: 48.8251488, lng: 18.5187457 },
    { lat: 48.8248559, lng: 18.5187534 },
    { lat: 48.8245853, lng: 18.5188807 },
    { lat: 48.8243122, lng: 18.5189829 },
    { lat: 48.8237975, lng: 18.5191011 },
    { lat: 48.8237028, lng: 18.5189168 },
    { lat: 48.8235365, lng: 18.5185866 },
    { lat: 48.8235288, lng: 18.518557 },
    { lat: 48.8233313, lng: 18.5181647 },
    { lat: 48.8231141, lng: 18.517719 },
    { lat: 48.8228596, lng: 18.5172107 },
    { lat: 48.8227653, lng: 18.5170332 },
    { lat: 48.8226615, lng: 18.5168028 },
    { lat: 48.8225458, lng: 18.5165896 },
    { lat: 48.8222918, lng: 18.516088 },
    { lat: 48.8219982, lng: 18.5155034 },
    { lat: 48.8218673, lng: 18.5147785 },
    { lat: 48.8217885, lng: 18.5143695 },
    { lat: 48.8216103, lng: 18.5134007 },
    { lat: 48.8214965, lng: 18.5128032 },
    { lat: 48.8214494, lng: 18.5125213 },
    { lat: 48.8214524, lng: 18.5123283 },
    { lat: 48.8214241, lng: 18.512265 },
    { lat: 48.8213612, lng: 18.5121866 },
    { lat: 48.8213252, lng: 18.5119831 },
    { lat: 48.8212358, lng: 18.5117699 },
    { lat: 48.8211741, lng: 18.5116745 },
    { lat: 48.8210879, lng: 18.51152 },
    { lat: 48.8210242, lng: 18.5114495 },
    { lat: 48.8209862, lng: 18.5112907 },
    { lat: 48.8209475, lng: 18.5111621 },
    { lat: 48.8208817, lng: 18.5110089 },
    { lat: 48.8207206, lng: 18.5105394 },
    { lat: 48.8205956, lng: 18.5104584 },
    { lat: 48.8205504, lng: 18.5104186 },
    { lat: 48.8204291, lng: 18.5102391 },
    { lat: 48.8203192, lng: 18.510128 },
    { lat: 48.8202335, lng: 18.5099827 },
    { lat: 48.8201513, lng: 18.5099225 },
    { lat: 48.8200665, lng: 18.5099023 },
    { lat: 48.8199754, lng: 18.5099266 },
    { lat: 48.8199471, lng: 18.5099456 },
    { lat: 48.8199213, lng: 18.5099495 },
    { lat: 48.819787, lng: 18.5098622 },
    { lat: 48.8197331, lng: 18.5098872 },
    { lat: 48.8194912, lng: 18.5099215 },
    { lat: 48.8194419, lng: 18.5098883 },
    { lat: 48.8194004, lng: 18.5097643 },
    { lat: 48.8193317, lng: 18.5096025 },
    { lat: 48.8192913, lng: 18.5096273 },
    { lat: 48.8192261, lng: 18.5095284 },
    { lat: 48.8191691, lng: 18.5093738 },
    { lat: 48.8191346, lng: 18.5093394 },
    { lat: 48.8191101, lng: 18.509345 },
    { lat: 48.8189797, lng: 18.5095397 },
    { lat: 48.8188018, lng: 18.509606 },
    { lat: 48.8186855, lng: 18.5096668 },
    { lat: 48.8185732, lng: 18.5096977 },
    { lat: 48.8183216, lng: 18.5094923 },
    { lat: 48.8182162, lng: 18.5094615 },
    { lat: 48.8181574, lng: 18.5094975 },
    { lat: 48.818053, lng: 18.5095088 },
    { lat: 48.8179408, lng: 18.5093546 },
    { lat: 48.8178685, lng: 18.5093322 },
    { lat: 48.8178095, lng: 18.5092651 },
    { lat: 48.817745, lng: 18.5092786 },
    { lat: 48.8177121, lng: 18.5092355 },
    { lat: 48.8176271, lng: 18.5092064 },
    { lat: 48.8175549, lng: 18.5089792 },
    { lat: 48.8175133, lng: 18.5089415 },
    { lat: 48.8174487, lng: 18.5089349 },
    { lat: 48.8173023, lng: 18.5089422 },
    { lat: 48.8171496, lng: 18.5089116 },
    { lat: 48.8171335, lng: 18.5088857 },
    { lat: 48.8170829, lng: 18.508765 },
    { lat: 48.8170257, lng: 18.5087081 },
    { lat: 48.8169656, lng: 18.5087457 },
    { lat: 48.8168357, lng: 18.5085959 },
    { lat: 48.8167772, lng: 18.5085814 },
    { lat: 48.8167494, lng: 18.5085656 },
    { lat: 48.8167097, lng: 18.5085236 },
    { lat: 48.8166602, lng: 18.5083792 },
    { lat: 48.8165206, lng: 18.5083247 },
    { lat: 48.8164935, lng: 18.5082605 },
    { lat: 48.8164948, lng: 18.5081204 },
    { lat: 48.8165309, lng: 18.5080364 },
    { lat: 48.8165436, lng: 18.5079797 },
    { lat: 48.8165397, lng: 18.5079515 },
    { lat: 48.8165123, lng: 18.5079224 },
    { lat: 48.8164635, lng: 18.5078985 },
    { lat: 48.8163392, lng: 18.5079556 },
    { lat: 48.8163187, lng: 18.507995 },
    { lat: 48.816332, lng: 18.508134 },
    { lat: 48.8161394, lng: 18.5082441 },
    { lat: 48.8161329, lng: 18.5081407 },
    { lat: 48.8161069, lng: 18.5081025 },
    { lat: 48.8160374, lng: 18.5080689 },
    { lat: 48.8159735, lng: 18.5080745 },
    { lat: 48.815865, lng: 18.5081743 },
    { lat: 48.8157723, lng: 18.5080274 },
    { lat: 48.8157455, lng: 18.5080118 },
    { lat: 48.8156192, lng: 18.5079897 },
    { lat: 48.8155321, lng: 18.5080482 },
    { lat: 48.8155071, lng: 18.5080227 },
    { lat: 48.8154722, lng: 18.5080031 },
    { lat: 48.8154215, lng: 18.5080045 },
    { lat: 48.8153822, lng: 18.5080516 },
    { lat: 48.815301, lng: 18.5080926 },
    { lat: 48.8152238, lng: 18.5080211 },
    { lat: 48.8152016, lng: 18.5078009 },
    { lat: 48.815265, lng: 18.5077023 },
    { lat: 48.81527, lng: 18.5076076 },
    { lat: 48.815165, lng: 18.5073777 },
    { lat: 48.8150612, lng: 18.5074198 },
    { lat: 48.8147169, lng: 18.5075828 },
    { lat: 48.814492, lng: 18.5075316 },
    { lat: 48.8142714, lng: 18.507375 },
    { lat: 48.8141775, lng: 18.5073481 },
    { lat: 48.8140549, lng: 18.5073377 },
    { lat: 48.8139917, lng: 18.5073965 },
    { lat: 48.8138272, lng: 18.5073795 },
    { lat: 48.8135735, lng: 18.5071751 },
    { lat: 48.8134881, lng: 18.5070563 },
    { lat: 48.8133497, lng: 18.5067948 },
    { lat: 48.8132775, lng: 18.5066725 },
    { lat: 48.8131952, lng: 18.5065902 },
    { lat: 48.8130984, lng: 18.5065529 },
    { lat: 48.8130172, lng: 18.5065542 },
    { lat: 48.8128432, lng: 18.5064847 },
    { lat: 48.8127334, lng: 18.5063754 },
    { lat: 48.8125777, lng: 18.5061899 },
    { lat: 48.812504, lng: 18.5060792 },
    { lat: 48.8124866, lng: 18.5059089 },
    { lat: 48.812534, lng: 18.5057637 },
    { lat: 48.8124834, lng: 18.5055986 },
    { lat: 48.8123958, lng: 18.5056264 },
    { lat: 48.8122782, lng: 18.5056419 },
    { lat: 48.8122597, lng: 18.5055322 },
    { lat: 48.812188, lng: 18.5052119 },
    { lat: 48.8120925, lng: 18.5050514 },
    { lat: 48.8120257, lng: 18.5049238 },
    { lat: 48.8119681, lng: 18.5046741 },
    { lat: 48.8119528, lng: 18.5045643 },
    { lat: 48.8118324, lng: 18.5044624 },
    { lat: 48.8117209, lng: 18.5044252 },
    { lat: 48.8116092, lng: 18.5044053 },
    { lat: 48.811516, lng: 18.5042484 },
    { lat: 48.8114514, lng: 18.5040786 },
    { lat: 48.8114875, lng: 18.5039134 },
    { lat: 48.8114828, lng: 18.5037858 },
    { lat: 48.8114294, lng: 18.5036558 },
    { lat: 48.8113665, lng: 18.5035564 },
    { lat: 48.8113334, lng: 18.5034689 },
    { lat: 48.8113333, lng: 18.5034038 },
    { lat: 48.8113816, lng: 18.5033186 },
    { lat: 48.8113382, lng: 18.5031863 },
    { lat: 48.8113161, lng: 18.5029464 },
    { lat: 48.8112697, lng: 18.5029014 },
    { lat: 48.8111974, lng: 18.5028819 },
    { lat: 48.8111699, lng: 18.5028492 },
    { lat: 48.8111517, lng: 18.5027842 },
    { lat: 48.8112332, lng: 18.5025639 },
    { lat: 48.8112903, lng: 18.502454 },
    { lat: 48.8112528, lng: 18.5023041 },
    { lat: 48.8111752, lng: 18.5022691 },
    { lat: 48.8111128, lng: 18.5023242 },
    { lat: 48.8110685, lng: 18.5022968 },
    { lat: 48.8110323, lng: 18.5022319 },
    { lat: 48.8109973, lng: 18.502067 },
    { lat: 48.8110012, lng: 18.5018321 },
    { lat: 48.8109323, lng: 18.5017048 },
    { lat: 48.8108612, lng: 18.5016228 },
    { lat: 48.8107171, lng: 18.5015425 },
    { lat: 48.8106365, lng: 18.5016607 },
    { lat: 48.810519, lng: 18.5017363 },
    { lat: 48.8103912, lng: 18.5017738 },
    { lat: 48.8101856, lng: 18.501951 },
    { lat: 48.8099836, lng: 18.5021173 },
    { lat: 48.809827, lng: 18.5020805 },
    { lat: 48.8097105, lng: 18.5019079 },
    { lat: 48.8096714, lng: 18.501668 },
    { lat: 48.8096234, lng: 18.5015957 },
    { lat: 48.8095417, lng: 18.5015458 },
    { lat: 48.8093239, lng: 18.5013363 },
    { lat: 48.8091782, lng: 18.5013943 },
    { lat: 48.8089896, lng: 18.5012873 },
    { lat: 48.8088778, lng: 18.5011622 },
    { lat: 48.8089098, lng: 18.5009445 },
    { lat: 48.8088887, lng: 18.5008269 },
    { lat: 48.8086549, lng: 18.500575 },
    { lat: 48.8086015, lng: 18.5004027 },
    { lat: 48.8086418, lng: 18.50013 },
    { lat: 48.8086745, lng: 18.4997598 },
    { lat: 48.8086281, lng: 18.4995298 },
    { lat: 48.8087005, lng: 18.4992845 },
    { lat: 48.8087858, lng: 18.4992369 },
    { lat: 48.8088376, lng: 18.4989867 },
    { lat: 48.8088414, lng: 18.4987067 },
    { lat: 48.808779, lng: 18.4984966 },
    { lat: 48.8086672, lng: 18.4984319 },
    { lat: 48.8085757, lng: 18.4984921 },
    { lat: 48.8084755, lng: 18.498432 },
    { lat: 48.8083641, lng: 18.4980846 },
    { lat: 48.8083753, lng: 18.4979192 },
    { lat: 48.8084168, lng: 18.4977319 },
    { lat: 48.8084284, lng: 18.4976316 },
    { lat: 48.8081162, lng: 18.4978834 },
    { lat: 48.8077083, lng: 18.4983258 },
    { lat: 48.8070712, lng: 18.4990096 },
    { lat: 48.8065961, lng: 18.4983675 },
    { lat: 48.806343, lng: 18.4988556 },
    { lat: 48.8062484, lng: 18.4989405 },
    { lat: 48.8052927, lng: 18.4997554 },
    { lat: 48.8052029, lng: 18.4998297 },
    { lat: 48.804961, lng: 18.5000648 },
    { lat: 48.8032709, lng: 18.5011097 },
    { lat: 48.8024778, lng: 18.5015993 },
    { lat: 48.8025759, lng: 18.5019319 },
    { lat: 48.8022895, lng: 18.5035613 },
    { lat: 48.8021881, lng: 18.5040961 },
    { lat: 48.8033816, lng: 18.5052717 },
    { lat: 48.8032428, lng: 18.5056845 },
    { lat: 48.8032048, lng: 18.5059366 },
    { lat: 48.8031686, lng: 18.5063465 },
    { lat: 48.8031669, lng: 18.506419 },
    { lat: 48.8030949, lng: 18.5070213 },
    { lat: 48.8030516, lng: 18.5073012 },
    { lat: 48.8029824, lng: 18.507686 },
    { lat: 48.8029592, lng: 18.507841 },
    { lat: 48.8028179, lng: 18.5086806 },
    { lat: 48.8026514, lng: 18.5096379 },
    { lat: 48.8026386, lng: 18.5097308 },
    { lat: 48.8025644, lng: 18.5097151 },
    { lat: 48.8025268, lng: 18.5096675 },
    { lat: 48.8023897, lng: 18.5096149 },
    { lat: 48.8023505, lng: 18.5097025 },
    { lat: 48.8023289, lng: 18.5097224 },
    { lat: 48.8022784, lng: 18.5097448 },
    { lat: 48.8022458, lng: 18.5096845 },
    { lat: 48.8021779, lng: 18.5096798 },
    { lat: 48.8020364, lng: 18.5098145 },
    { lat: 48.8019708, lng: 18.5098342 },
    { lat: 48.8018595, lng: 18.5097394 },
    { lat: 48.8017298, lng: 18.509819 },
    { lat: 48.8016868, lng: 18.509819 },
    { lat: 48.8015322, lng: 18.509754 },
    { lat: 48.8013721, lng: 18.5097364 },
    { lat: 48.801291, lng: 18.5097559 },
    { lat: 48.8012646, lng: 18.5098533 },
    { lat: 48.8011784, lng: 18.5098463 },
    { lat: 48.8011281, lng: 18.509751 },
    { lat: 48.8010408, lng: 18.509763 },
    { lat: 48.8010033, lng: 18.5098607 },
    { lat: 48.8009948, lng: 18.509973 },
    { lat: 48.8008914, lng: 18.5100003 },
    { lat: 48.8008154, lng: 18.5099505 },
    { lat: 48.8006986, lng: 18.5100631 },
    { lat: 48.8006794, lng: 18.5101478 },
    { lat: 48.8006904, lng: 18.5102026 },
    { lat: 48.800681, lng: 18.5102378 },
    { lat: 48.8006348, lng: 18.5103227 },
    { lat: 48.8005165, lng: 18.5103626 },
    { lat: 48.8004441, lng: 18.5103197 },
    { lat: 48.8003502, lng: 18.5103347 },
    { lat: 48.8003893, lng: 18.5104325 },
    { lat: 48.8003201, lng: 18.5105496 },
    { lat: 48.8001986, lng: 18.5105548 },
    { lat: 48.8000196, lng: 18.5107469 },
    { lat: 48.7998928, lng: 18.5113542 },
    { lat: 48.7986789, lng: 18.5140747 },
    { lat: 48.7985733, lng: 18.5143924 },
    { lat: 48.7984626, lng: 18.5147021 },
    { lat: 48.7984583, lng: 18.514722 },
    { lat: 48.7984483, lng: 18.5147469 },
    { lat: 48.7984466, lng: 18.5147596 },
    { lat: 48.7984328, lng: 18.5147971 },
    { lat: 48.7982544, lng: 18.5148116 },
    { lat: 48.7981789, lng: 18.5148565 },
    { lat: 48.7980195, lng: 18.5149111 },
    { lat: 48.7978528, lng: 18.5149363 },
    { lat: 48.7974274, lng: 18.5148508 },
    { lat: 48.7971182, lng: 18.5148079 },
    { lat: 48.7967039, lng: 18.5146573 },
    { lat: 48.7964424, lng: 18.5145996 },
    { lat: 48.7964382, lng: 18.5146219 },
    { lat: 48.7961291, lng: 18.517184 },
    { lat: 48.7962736, lng: 18.5171642 },
    { lat: 48.796336, lng: 18.5172118 },
    { lat: 48.7963755, lng: 18.517277 },
    { lat: 48.7964892, lng: 18.5172069 },
    { lat: 48.7966346, lng: 18.5170825 },
    { lat: 48.7967029, lng: 18.5171773 },
    { lat: 48.7967932, lng: 18.51722 },
    { lat: 48.7968806, lng: 18.5171468 },
    { lat: 48.7969239, lng: 18.5170899 },
    { lat: 48.7970563, lng: 18.5171828 },
    { lat: 48.7970882, lng: 18.517333 },
    { lat: 48.7971455, lng: 18.5174951 },
    { lat: 48.7972747, lng: 18.5175454 },
    { lat: 48.797367, lng: 18.5176033 },
    { lat: 48.7974735, lng: 18.5175932 },
    { lat: 48.797507, lng: 18.5176258 },
    { lat: 48.7976003, lng: 18.5176209 },
    { lat: 48.7976633, lng: 18.5177236 },
    { lat: 48.7976927, lng: 18.5178459 },
    { lat: 48.797701, lng: 18.5179586 },
    { lat: 48.7977779, lng: 18.517939 },
    { lat: 48.7978526, lng: 18.5179861 },
    { lat: 48.7979903, lng: 18.5181313 },
    { lat: 48.7980216, lng: 18.5181464 },
    { lat: 48.7980446, lng: 18.5181338 },
    { lat: 48.7980839, lng: 18.518174 },
    { lat: 48.7981788, lng: 18.518199 },
    { lat: 48.7982658, lng: 18.5181992 },
    { lat: 48.7984095, lng: 18.5183318 },
    { lat: 48.7984211, lng: 18.518462 },
    { lat: 48.7985411, lng: 18.5186119 },
    { lat: 48.7987091, lng: 18.518612 },
    { lat: 48.7987968, lng: 18.5187721 },
    { lat: 48.7988488, lng: 18.5188146 },
    { lat: 48.7989023, lng: 18.5189671 },
    { lat: 48.7991274, lng: 18.5190648 },
    { lat: 48.799258, lng: 18.5192052 },
    { lat: 48.7993827, lng: 18.5192154 },
    { lat: 48.7994649, lng: 18.5192927 },
    { lat: 48.7994275, lng: 18.5193955 },
    { lat: 48.7994584, lng: 18.5194632 },
    { lat: 48.7994979, lng: 18.5195083 },
    { lat: 48.7996064, lng: 18.5195505 },
    { lat: 48.7996657, lng: 18.5196884 },
    { lat: 48.7998367, lng: 18.5197808 },
    { lat: 48.8000308, lng: 18.5200284 },
    { lat: 48.8000532, lng: 18.5201111 },
    { lat: 48.8001049, lng: 18.5201687 },
    { lat: 48.8002426, lng: 18.5201285 },
    { lat: 48.8002735, lng: 18.5201791 },
    { lat: 48.800298, lng: 18.5202991 },
    { lat: 48.8004648, lng: 18.5204414 },
    { lat: 48.8006557, lng: 18.5206289 },
    { lat: 48.8008398, lng: 18.5207798 },
    { lat: 48.8008217, lng: 18.5208597 },
    { lat: 48.8010565, lng: 18.5209874 },
    { lat: 48.8010579, lng: 18.5212198 },
    { lat: 48.8011025, lng: 18.5212321 },
    { lat: 48.8011733, lng: 18.521225 },
    { lat: 48.801216, lng: 18.521307 },
    { lat: 48.8011651, lng: 18.5214472 },
    { lat: 48.8011704, lng: 18.5215875 },
    { lat: 48.8011831, lng: 18.5216124 },
    { lat: 48.8013201, lng: 18.5215602 },
    { lat: 48.8013793, lng: 18.52163 },
    { lat: 48.8014048, lng: 18.5217904 },
    { lat: 48.8014562, lng: 18.5218401 },
    { lat: 48.8015695, lng: 18.5218503 },
    { lat: 48.8016357, lng: 18.5219279 },
    { lat: 48.8017274, lng: 18.5221178 },
    { lat: 48.8018047, lng: 18.5222527 },
    { lat: 48.801992, lng: 18.5225005 },
    { lat: 48.8020583, lng: 18.5225806 },
    { lat: 48.8022919, lng: 18.5227907 },
    { lat: 48.802409, lng: 18.5228484 },
    { lat: 48.8024474, lng: 18.5228511 },
    { lat: 48.8025832, lng: 18.5227962 },
    { lat: 48.8026917, lng: 18.5228613 },
    { lat: 48.8027439, lng: 18.5229088 },
    { lat: 48.8027854, lng: 18.5229666 },
    { lat: 48.8029095, lng: 18.5230491 },
    { lat: 48.8031381, lng: 18.5228992 },
    { lat: 48.8033107, lng: 18.522752 },
    { lat: 48.8034296, lng: 18.5227822 },
    { lat: 48.8035886, lng: 18.5226772 },
    { lat: 48.8039967, lng: 18.5227578 },
    { lat: 48.804148, lng: 18.52278 },
    { lat: 48.8042335, lng: 18.5228176 },
    { lat: 48.8043868, lng: 18.5228328 },
    { lat: 48.8046533, lng: 18.5228832 },
    { lat: 48.8048489, lng: 18.5228508 },
    { lat: 48.8049668, lng: 18.5228586 },
    { lat: 48.8050229, lng: 18.5228112 },
    { lat: 48.8051772, lng: 18.5228287 },
    { lat: 48.8052471, lng: 18.5229087 },
    { lat: 48.8053029, lng: 18.5229014 },
    { lat: 48.8053649, lng: 18.5227941 },
    { lat: 48.8055437, lng: 18.5227864 },
    { lat: 48.8055966, lng: 18.5228693 },
    { lat: 48.8058635, lng: 18.5227743 },
    { lat: 48.8061428, lng: 18.5227243 },
    { lat: 48.8062926, lng: 18.5228448 },
    { lat: 48.8064605, lng: 18.5229251 },
    { lat: 48.8064969, lng: 18.5229323 },
    { lat: 48.8070061, lng: 18.5228775 },
    { lat: 48.8070145, lng: 18.5228675 },
    { lat: 48.8070542, lng: 18.5229327 },
    { lat: 48.8070962, lng: 18.523043 },
    { lat: 48.8072317, lng: 18.5229831 },
    { lat: 48.8073773, lng: 18.5230655 },
    { lat: 48.8075011, lng: 18.5230207 },
    { lat: 48.8076774, lng: 18.5230855 },
    { lat: 48.8077549, lng: 18.5231857 },
    { lat: 48.8078184, lng: 18.523171 },
    { lat: 48.8079421, lng: 18.5233061 },
    { lat: 48.8081921, lng: 18.5233436 },
    { lat: 48.8083894, lng: 18.5234237 },
    { lat: 48.8084517, lng: 18.5234263 },
    { lat: 48.8085272, lng: 18.5234892 },
    { lat: 48.8089579, lng: 18.5235892 },
    { lat: 48.809127, lng: 18.5236471 },
    { lat: 48.8093214, lng: 18.5237587 },
    { lat: 48.809523, lng: 18.5238359 },
    { lat: 48.8096989, lng: 18.52404 },
    { lat: 48.8097986, lng: 18.5240694 },
    { lat: 48.8098114, lng: 18.5240958 },
    { lat: 48.8103302, lng: 18.5246539 },
    { lat: 48.8103646, lng: 18.5246865 },
    { lat: 48.8103794, lng: 18.5246889 },
    { lat: 48.8105469, lng: 18.5248433 },
    { lat: 48.8106557, lng: 18.5249532 },
    { lat: 48.8108631, lng: 18.5250979 },
    { lat: 48.8109757, lng: 18.5252377 },
    { lat: 48.8110712, lng: 18.5253124 },
    { lat: 48.8111525, lng: 18.5254371 },
    { lat: 48.8112907, lng: 18.5257015 },
    { lat: 48.8113788, lng: 18.5258043 },
    { lat: 48.8114895, lng: 18.5259916 },
    { lat: 48.8115913, lng: 18.5261234 },
    { lat: 48.8116303, lng: 18.5262159 },
    { lat: 48.8116497, lng: 18.5264704 },
    { lat: 48.8117212, lng: 18.5266426 },
    { lat: 48.8117902, lng: 18.5267503 },
    { lat: 48.8118157, lng: 18.5267628 },
    { lat: 48.8120248, lng: 18.5267921 },
    { lat: 48.8120653, lng: 18.5268096 },
    { lat: 48.812381, lng: 18.5271767 },
    { lat: 48.8126774, lng: 18.5273411 },
    { lat: 48.812834, lng: 18.5274608 },
    { lat: 48.8129858, lng: 18.5275352 },
    { lat: 48.8130851, lng: 18.5276398 },
    { lat: 48.813185, lng: 18.5278771 },
    { lat: 48.8134577, lng: 18.528309 },
    { lat: 48.8136139, lng: 18.5285636 },
    { lat: 48.8136572, lng: 18.528881 },
    { lat: 48.8136458, lng: 18.5290056 },
    { lat: 48.8136545, lng: 18.5292053 },
    { lat: 48.8136882, lng: 18.529433 },
    { lat: 48.8137643, lng: 18.5295849 },
    { lat: 48.8138037, lng: 18.5296472 },
    { lat: 48.8138978, lng: 18.5297171 },
    { lat: 48.8139422, lng: 18.5297645 },
    { lat: 48.8139854, lng: 18.5298321 },
    { lat: 48.8140614, lng: 18.530174 },
    { lat: 48.8141314, lng: 18.5303163 },
    { lat: 48.814237, lng: 18.5305809 },
    { lat: 48.8142758, lng: 18.5306508 },
    { lat: 48.814363, lng: 18.5307405 },
    { lat: 48.8145983, lng: 18.5310421 },
    { lat: 48.8146657, lng: 18.5311221 },
    { lat: 48.8147837, lng: 18.531214 },
    { lat: 48.8148495, lng: 18.5312237 },
    { lat: 48.8149951, lng: 18.5313497 },
    { lat: 48.8161716, lng: 18.5318455 },
    { lat: 48.8170367, lng: 18.5322141 },
    { lat: 48.8176251, lng: 18.5324763 },
    { lat: 48.8185842, lng: 18.5340369 },
    { lat: 48.8195307, lng: 18.5356343 },
    { lat: 48.8209832, lng: 18.5372247 },
    { lat: 48.8220948, lng: 18.5387038 },
    { lat: 48.822432, lng: 18.5394897 },
    { lat: 48.8224266, lng: 18.5395372 },
    { lat: 48.8233924, lng: 18.540928 },
    { lat: 48.8236737, lng: 18.5414359 },
    { lat: 48.8236849, lng: 18.5414556 },
  ],
  DolnéVestenice: [
    { lat: 48.741411, lng: 18.395086 },
    { lat: 48.741297, lng: 18.394741 },
    { lat: 48.741205, lng: 18.394346 },
    { lat: 48.740984, lng: 18.39338 },
    { lat: 48.740908, lng: 18.392965 },
    { lat: 48.740743, lng: 18.391399 },
    { lat: 48.739985, lng: 18.390482 },
    { lat: 48.739512, lng: 18.390655 },
    { lat: 48.739021, lng: 18.390495 },
    { lat: 48.739033, lng: 18.389902 },
    { lat: 48.738949, lng: 18.389217 },
    { lat: 48.738878, lng: 18.388617 },
    { lat: 48.738772, lng: 18.388317 },
    { lat: 48.73853, lng: 18.387899 },
    { lat: 48.738197, lng: 18.387271 },
    { lat: 48.738092, lng: 18.386984 },
    { lat: 48.737886, lng: 18.386186 },
    { lat: 48.737812, lng: 18.386015 },
    { lat: 48.737775, lng: 18.385861 },
    { lat: 48.737792, lng: 18.385248 },
    { lat: 48.737629, lng: 18.384726 },
    { lat: 48.737268, lng: 18.383899 },
    { lat: 48.737257, lng: 18.383435 },
    { lat: 48.737183, lng: 18.383178 },
    { lat: 48.737205, lng: 18.382848 },
    { lat: 48.73718, lng: 18.382622 },
    { lat: 48.737191, lng: 18.382373 },
    { lat: 48.737212, lng: 18.382044 },
    { lat: 48.737313, lng: 18.381697 },
    { lat: 48.737356, lng: 18.381485 },
    { lat: 48.737476, lng: 18.38115 },
    { lat: 48.737486, lng: 18.380873 },
    { lat: 48.737458, lng: 18.380448 },
    { lat: 48.737481, lng: 18.380196 },
    { lat: 48.73762, lng: 18.37984 },
    { lat: 48.737713, lng: 18.379462 },
    { lat: 48.737751, lng: 18.379147 },
    { lat: 48.737755, lng: 18.378856 },
    { lat: 48.737718, lng: 18.378616 },
    { lat: 48.737508, lng: 18.378254 },
    { lat: 48.737334, lng: 18.378007 },
    { lat: 48.736977, lng: 18.37758 },
    { lat: 48.736629, lng: 18.377233 },
    { lat: 48.736388, lng: 18.377066 },
    { lat: 48.736267, lng: 18.376984 },
    { lat: 48.736136, lng: 18.376922 },
    { lat: 48.735747, lng: 18.376958 },
    { lat: 48.735665, lng: 18.376934 },
    { lat: 48.735521, lng: 18.376824 },
    { lat: 48.7353, lng: 18.376567 },
    { lat: 48.735185, lng: 18.376434 },
    { lat: 48.735074, lng: 18.376252 },
    { lat: 48.734998, lng: 18.376051 },
    { lat: 48.734967, lng: 18.375891 },
    { lat: 48.735001, lng: 18.375011 },
    { lat: 48.734917, lng: 18.37477 },
    { lat: 48.734956, lng: 18.374478 },
    { lat: 48.734974, lng: 18.374132 },
    { lat: 48.735018, lng: 18.373937 },
    { lat: 48.735122, lng: 18.373248 },
    { lat: 48.735103, lng: 18.373033 },
    { lat: 48.735131, lng: 18.372371 },
    { lat: 48.735117, lng: 18.372027 },
    { lat: 48.735137, lng: 18.371407 },
    { lat: 48.73507, lng: 18.370573 },
    { lat: 48.735077, lng: 18.369842 },
    { lat: 48.734947, lng: 18.369297 },
    { lat: 48.734658, lng: 18.368494 },
    { lat: 48.734404, lng: 18.368054 },
    { lat: 48.734045, lng: 18.367664 },
    { lat: 48.73392, lng: 18.367601 },
    { lat: 48.733772, lng: 18.36753 },
    { lat: 48.73359, lng: 18.367499 },
    { lat: 48.733271, lng: 18.367392 },
    { lat: 48.732967, lng: 18.367392 },
    { lat: 48.732487, lng: 18.3676 },
    { lat: 48.732156, lng: 18.367588 },
    { lat: 48.731904, lng: 18.367558 },
    { lat: 48.731649, lng: 18.367542 },
    { lat: 48.731428, lng: 18.367559 },
    { lat: 48.731167, lng: 18.367601 },
    { lat: 48.730787, lng: 18.367498 },
    { lat: 48.730441, lng: 18.367262 },
    { lat: 48.730083, lng: 18.367088 },
    { lat: 48.729816, lng: 18.367068 },
    { lat: 48.72946, lng: 18.366986 },
    { lat: 48.728983, lng: 18.366796 },
    { lat: 48.728714, lng: 18.366644 },
    { lat: 48.728625, lng: 18.366566 },
    { lat: 48.728544, lng: 18.366469 },
    { lat: 48.728452, lng: 18.366286 },
    { lat: 48.728403, lng: 18.365997 },
    { lat: 48.728394, lng: 18.365806 },
    { lat: 48.728375, lng: 18.365193 },
    { lat: 48.728329, lng: 18.364844 },
    { lat: 48.728146, lng: 18.364255 },
    { lat: 48.727985, lng: 18.364033 },
    { lat: 48.727539, lng: 18.363662 },
    { lat: 48.727124, lng: 18.363431 },
    { lat: 48.726722, lng: 18.363236 },
    { lat: 48.72652, lng: 18.363108 },
    { lat: 48.72627, lng: 18.363014 },
    { lat: 48.726056, lng: 18.362891 },
    { lat: 48.725823, lng: 18.362813 },
    { lat: 48.725608, lng: 18.362765 },
    { lat: 48.725415, lng: 18.362666 },
    { lat: 48.725281, lng: 18.362628 },
    { lat: 48.72467, lng: 18.362686 },
    { lat: 48.724477, lng: 18.362673 },
    { lat: 48.724186, lng: 18.362649 },
    { lat: 48.723995, lng: 18.362671 },
    { lat: 48.723705, lng: 18.362754 },
    { lat: 48.723345, lng: 18.362835 },
    { lat: 48.723128, lng: 18.362852 },
    { lat: 48.722784, lng: 18.362869 },
    { lat: 48.722552, lng: 18.362917 },
    { lat: 48.721763, lng: 18.363189 },
    { lat: 48.721749, lng: 18.363159 },
    { lat: 48.721733, lng: 18.363156 },
    { lat: 48.721295, lng: 18.363243 },
    { lat: 48.721035, lng: 18.363314 },
    { lat: 48.721013, lng: 18.36336 },
    { lat: 48.720783, lng: 18.36344 },
    { lat: 48.720533, lng: 18.363582 },
    { lat: 48.719841, lng: 18.364046 },
    { lat: 48.71951, lng: 18.364312 },
    { lat: 48.719398, lng: 18.364445 },
    { lat: 48.719309, lng: 18.364543 },
    { lat: 48.718838, lng: 18.365214 },
    { lat: 48.718749, lng: 18.365376 },
    { lat: 48.718733, lng: 18.365407 },
    { lat: 48.718698, lng: 18.365471 },
    { lat: 48.718646, lng: 18.365537 },
    { lat: 48.718584, lng: 18.365495 },
    { lat: 48.718305, lng: 18.365651 },
    { lat: 48.71805, lng: 18.365754 },
    { lat: 48.717895, lng: 18.3658 },
    { lat: 48.717485, lng: 18.365831 },
    { lat: 48.717238, lng: 18.365839 },
    { lat: 48.716992, lng: 18.365816 },
    { lat: 48.716584, lng: 18.365841 },
    { lat: 48.716346, lng: 18.365889 },
    { lat: 48.71601, lng: 18.365988 },
    { lat: 48.715707, lng: 18.36611 },
    { lat: 48.715185, lng: 18.366329 },
    { lat: 48.714872, lng: 18.366539 },
    { lat: 48.714565, lng: 18.366819 },
    { lat: 48.714374, lng: 18.367016 },
    { lat: 48.714154, lng: 18.367188 },
    { lat: 48.714091, lng: 18.367252 },
    { lat: 48.713864, lng: 18.367479 },
    { lat: 48.71366, lng: 18.367677 },
    { lat: 48.713714, lng: 18.368183 },
    { lat: 48.713744, lng: 18.36846 },
    { lat: 48.713611, lng: 18.368438 },
    { lat: 48.713389, lng: 18.3684 },
    { lat: 48.713268, lng: 18.368394 },
    { lat: 48.712997, lng: 18.368294 },
    { lat: 48.712852, lng: 18.368304 },
    { lat: 48.712715, lng: 18.368311 },
    { lat: 48.712373, lng: 18.368308 },
    { lat: 48.711964, lng: 18.368286 },
    { lat: 48.711722, lng: 18.368228 },
    { lat: 48.711563, lng: 18.368876 },
    { lat: 48.711551, lng: 18.369037 },
    { lat: 48.711545, lng: 18.36921 },
    { lat: 48.711467, lng: 18.369307 },
    { lat: 48.711438, lng: 18.369437 },
    { lat: 48.711403, lng: 18.369489 },
    { lat: 48.711362, lng: 18.369738 },
    { lat: 48.711311, lng: 18.369789 },
    { lat: 48.711319, lng: 18.369994 },
    { lat: 48.711286, lng: 18.370051 },
    { lat: 48.711231, lng: 18.370037 },
    { lat: 48.710863, lng: 18.370279 },
    { lat: 48.710687, lng: 18.370549 },
    { lat: 48.710667, lng: 18.370705 },
    { lat: 48.71057, lng: 18.370766 },
    { lat: 48.710487, lng: 18.37113 },
    { lat: 48.710325, lng: 18.371429 },
    { lat: 48.710237, lng: 18.371476 },
    { lat: 48.710118, lng: 18.371583 },
    { lat: 48.709989, lng: 18.371729 },
    { lat: 48.709894, lng: 18.371789 },
    { lat: 48.709716, lng: 18.371895 },
    { lat: 48.709549, lng: 18.371945 },
    { lat: 48.709374, lng: 18.372042 },
    { lat: 48.709192, lng: 18.372177 },
    { lat: 48.709021, lng: 18.372338 },
    { lat: 48.708683, lng: 18.37286 },
    { lat: 48.707482, lng: 18.374708 },
    { lat: 48.707504, lng: 18.374763 },
    { lat: 48.707401, lng: 18.374797 },
    { lat: 48.706846, lng: 18.375423 },
    { lat: 48.70655, lng: 18.37589 },
    { lat: 48.706039, lng: 18.376273 },
    { lat: 48.705897, lng: 18.376285 },
    { lat: 48.705818, lng: 18.376139 },
    { lat: 48.705641, lng: 18.376177 },
    { lat: 48.70547, lng: 18.376056 },
    { lat: 48.70543, lng: 18.376042 },
    { lat: 48.705216, lng: 18.376031 },
    { lat: 48.704896, lng: 18.376125 },
    { lat: 48.704757, lng: 18.376292 },
    { lat: 48.704657, lng: 18.376279 },
    { lat: 48.704565, lng: 18.376382 },
    { lat: 48.70442, lng: 18.376533 },
    { lat: 48.70419, lng: 18.376641 },
    { lat: 48.703988, lng: 18.376713 },
    { lat: 48.703827, lng: 18.376917 },
    { lat: 48.703631, lng: 18.377291 },
    { lat: 48.703524, lng: 18.377424 },
    { lat: 48.703401, lng: 18.377426 },
    { lat: 48.703317, lng: 18.377469 },
    { lat: 48.703279, lng: 18.377507 },
    { lat: 48.703279, lng: 18.377594 },
    { lat: 48.703182, lng: 18.377617 },
    { lat: 48.70312, lng: 18.377602 },
    { lat: 48.703081, lng: 18.377728 },
    { lat: 48.703063, lng: 18.377814 },
    { lat: 48.70297, lng: 18.377873 },
    { lat: 48.702892, lng: 18.377908 },
    { lat: 48.702703, lng: 18.377925 },
    { lat: 48.702659, lng: 18.377967 },
    { lat: 48.702522, lng: 18.377959 },
    { lat: 48.702434, lng: 18.377996 },
    { lat: 48.702341, lng: 18.378137 },
    { lat: 48.702292, lng: 18.378174 },
    { lat: 48.702154, lng: 18.378294 },
    { lat: 48.702081, lng: 18.378264 },
    { lat: 48.701867, lng: 18.378321 },
    { lat: 48.701802, lng: 18.378369 },
    { lat: 48.701751, lng: 18.378415 },
    { lat: 48.701712, lng: 18.378345 },
    { lat: 48.701677, lng: 18.378489 },
    { lat: 48.701596, lng: 18.378497 },
    { lat: 48.701565, lng: 18.378474 },
    { lat: 48.70148, lng: 18.378595 },
    { lat: 48.701374, lng: 18.378546 },
    { lat: 48.701283, lng: 18.378736 },
    { lat: 48.701193, lng: 18.378759 },
    { lat: 48.701108, lng: 18.378848 },
    { lat: 48.701063, lng: 18.378813 },
    { lat: 48.700932, lng: 18.378911 },
    { lat: 48.700873, lng: 18.379037 },
    { lat: 48.700826, lng: 18.379026 },
    { lat: 48.700784, lng: 18.379101 },
    { lat: 48.700748, lng: 18.379102 },
    { lat: 48.700688, lng: 18.379254 },
    { lat: 48.700586, lng: 18.379292 },
    { lat: 48.700426, lng: 18.379485 },
    { lat: 48.700336, lng: 18.379527 },
    { lat: 48.700235, lng: 18.379673 },
    { lat: 48.700204, lng: 18.379672 },
    { lat: 48.700158, lng: 18.379738 },
    { lat: 48.700094, lng: 18.37975 },
    { lat: 48.70005, lng: 18.379815 },
    { lat: 48.700051, lng: 18.379893 },
    { lat: 48.699898, lng: 18.380059 },
    { lat: 48.699756, lng: 18.380099 },
    { lat: 48.699689, lng: 18.380205 },
    { lat: 48.699644, lng: 18.380238 },
    { lat: 48.699639, lng: 18.380318 },
    { lat: 48.699504, lng: 18.380379 },
    { lat: 48.699468, lng: 18.380481 },
    { lat: 48.699404, lng: 18.380551 },
    { lat: 48.699367, lng: 18.380588 },
    { lat: 48.699317, lng: 18.380526 },
    { lat: 48.699273, lng: 18.380465 },
    { lat: 48.699208, lng: 18.38051 },
    { lat: 48.699109, lng: 18.380578 },
    { lat: 48.699079, lng: 18.380704 },
    { lat: 48.699008, lng: 18.380622 },
    { lat: 48.698987, lng: 18.380742 },
    { lat: 48.698941, lng: 18.380783 },
    { lat: 48.698831, lng: 18.380749 },
    { lat: 48.698683, lng: 18.380867 },
    { lat: 48.698631, lng: 18.381109 },
    { lat: 48.69863, lng: 18.381226 },
    { lat: 48.698493, lng: 18.381239 },
    { lat: 48.698463, lng: 18.381312 },
    { lat: 48.698457, lng: 18.38139 },
    { lat: 48.698376, lng: 18.381397 },
    { lat: 48.698399, lng: 18.381484 },
    { lat: 48.698354, lng: 18.381506 },
    { lat: 48.698296, lng: 18.38148 },
    { lat: 48.698294, lng: 18.381574 },
    { lat: 48.698265, lng: 18.381651 },
    { lat: 48.698007, lng: 18.381788 },
    { lat: 48.697936, lng: 18.381773 },
    { lat: 48.697887, lng: 18.381791 },
    { lat: 48.697864, lng: 18.381824 },
    { lat: 48.69781, lng: 18.381856 },
    { lat: 48.69777, lng: 18.381834 },
    { lat: 48.697726, lng: 18.381872 },
    { lat: 48.697707, lng: 18.381923 },
    { lat: 48.69766, lng: 18.381928 },
    { lat: 48.697584, lng: 18.381909 },
    { lat: 48.697569, lng: 18.38205 },
    { lat: 48.697527, lng: 18.382135 },
    { lat: 48.697428, lng: 18.382148 },
    { lat: 48.697401, lng: 18.38222 },
    { lat: 48.697342, lng: 18.382252 },
    { lat: 48.697261, lng: 18.382211 },
    { lat: 48.697198, lng: 18.382352 },
    { lat: 48.697089, lng: 18.382301 },
    { lat: 48.696976, lng: 18.382448 },
    { lat: 48.696975, lng: 18.382539 },
    { lat: 48.696928, lng: 18.382598 },
    { lat: 48.696896, lng: 18.382633 },
    { lat: 48.696764, lng: 18.382505 },
    { lat: 48.696722, lng: 18.382515 },
    { lat: 48.696697, lng: 18.382615 },
    { lat: 48.696734, lng: 18.382709 },
    { lat: 48.696752, lng: 18.382836 },
    { lat: 48.696646, lng: 18.383073 },
    { lat: 48.696563, lng: 18.383094 },
    { lat: 48.696518, lng: 18.383042 },
    { lat: 48.69635, lng: 18.382994 },
    { lat: 48.696313, lng: 18.383016 },
    { lat: 48.696282, lng: 18.383107 },
    { lat: 48.696161, lng: 18.383148 },
    { lat: 48.696091, lng: 18.383226 },
    { lat: 48.696014, lng: 18.383239 },
    { lat: 48.695947, lng: 18.383276 },
    { lat: 48.695872, lng: 18.383346 },
    { lat: 48.695632, lng: 18.38347 },
    { lat: 48.695514, lng: 18.38337 },
    { lat: 48.695464, lng: 18.383361 },
    { lat: 48.695427, lng: 18.38343 },
    { lat: 48.695403, lng: 18.383486 },
    { lat: 48.695348, lng: 18.383525 },
    { lat: 48.695126, lng: 18.383708 },
    { lat: 48.695098, lng: 18.383731 },
    { lat: 48.69502, lng: 18.383798 },
    { lat: 48.694983, lng: 18.383829 },
    { lat: 48.694081, lng: 18.384601 },
    { lat: 48.693992, lng: 18.38468 },
    { lat: 48.69391, lng: 18.384802 },
    { lat: 48.692676, lng: 18.387212 },
    { lat: 48.692618, lng: 18.387276 },
    { lat: 48.692433, lng: 18.387326 },
    { lat: 48.692402, lng: 18.387407 },
    { lat: 48.692317, lng: 18.387513 },
    { lat: 48.692257, lng: 18.387631 },
    { lat: 48.692202, lng: 18.387737 },
    { lat: 48.692133, lng: 18.387849 },
    { lat: 48.692233, lng: 18.387918 },
    { lat: 48.692412, lng: 18.388199 },
    { lat: 48.692603, lng: 18.388487 },
    { lat: 48.692671, lng: 18.388624 },
    { lat: 48.692776, lng: 18.388835 },
    { lat: 48.693055, lng: 18.389014 },
    { lat: 48.693404, lng: 18.3893 },
    { lat: 48.693791, lng: 18.389792 },
    { lat: 48.693999, lng: 18.389897 },
    { lat: 48.694096, lng: 18.389999 },
    { lat: 48.694173, lng: 18.390081 },
    { lat: 48.694278, lng: 18.390319 },
    { lat: 48.694285, lng: 18.39041 },
    { lat: 48.694291, lng: 18.39054 },
    { lat: 48.694319, lng: 18.390699 },
    { lat: 48.694329, lng: 18.390828 },
    { lat: 48.694344, lng: 18.391047 },
    { lat: 48.694386, lng: 18.391121 },
    { lat: 48.694471, lng: 18.391279 },
    { lat: 48.694763, lng: 18.392657 },
    { lat: 48.69479, lng: 18.393574 },
    { lat: 48.694896, lng: 18.395777 },
    { lat: 48.695161, lng: 18.397223 },
    { lat: 48.695426, lng: 18.398623 },
    { lat: 48.69557, lng: 18.399114 },
    { lat: 48.695679, lng: 18.401027 },
    { lat: 48.695908, lng: 18.402818 },
    { lat: 48.69605, lng: 18.40287 },
    { lat: 48.696068, lng: 18.402877 },
    { lat: 48.696106, lng: 18.402892 },
    { lat: 48.696134, lng: 18.40287 },
    { lat: 48.696173, lng: 18.40284 },
    { lat: 48.696127, lng: 18.402963 },
    { lat: 48.696084, lng: 18.403081 },
    { lat: 48.695901, lng: 18.403433 },
    { lat: 48.695679, lng: 18.404189 },
    { lat: 48.69553, lng: 18.404691 },
    { lat: 48.695315, lng: 18.405196 },
    { lat: 48.695177, lng: 18.405404 },
    { lat: 48.695126, lng: 18.405552 },
    { lat: 48.695027, lng: 18.405774 },
    { lat: 48.694834, lng: 18.406043 },
    { lat: 48.694772, lng: 18.406142 },
    { lat: 48.694373, lng: 18.406592 },
    { lat: 48.694237, lng: 18.406694 },
    { lat: 48.694023, lng: 18.406923 },
    { lat: 48.693955, lng: 18.407023 },
    { lat: 48.693799, lng: 18.407497 },
    { lat: 48.693551, lng: 18.407747 },
    { lat: 48.693345, lng: 18.408185 },
    { lat: 48.693253, lng: 18.408392 },
    { lat: 48.692943, lng: 18.408885 },
    { lat: 48.692785, lng: 18.409228 },
    { lat: 48.692732, lng: 18.409289 },
    { lat: 48.69271, lng: 18.409315 },
    { lat: 48.692448, lng: 18.409454 },
    { lat: 48.692288, lng: 18.40954 },
    { lat: 48.6934653, lng: 18.4107206 },
    { lat: 48.6939555, lng: 18.4111558 },
    { lat: 48.694365, lng: 18.4116404 },
    { lat: 48.6948757, lng: 18.4124065 },
    { lat: 48.6951978, lng: 18.4125145 },
    { lat: 48.6961729, lng: 18.412521 },
    { lat: 48.6966642, lng: 18.41231 },
    { lat: 48.6972474, lng: 18.4120466 },
    { lat: 48.6978895, lng: 18.4119671 },
    { lat: 48.6992888, lng: 18.4123207 },
    { lat: 48.6995495, lng: 18.4122594 },
    { lat: 48.700651, lng: 18.4121258 },
    { lat: 48.7009162, lng: 18.4121082 },
    { lat: 48.7015787, lng: 18.4119901 },
    { lat: 48.7018118, lng: 18.4120633 },
    { lat: 48.7021808, lng: 18.4121268 },
    { lat: 48.7022724, lng: 18.4122134 },
    { lat: 48.702421, lng: 18.4122873 },
    { lat: 48.7027304, lng: 18.4123712 },
    { lat: 48.7027683, lng: 18.4123654 },
    { lat: 48.7028851, lng: 18.4125398 },
    { lat: 48.7030017, lng: 18.4127482 },
    { lat: 48.7031518, lng: 18.4129095 },
    { lat: 48.7032489, lng: 18.4129129 },
    { lat: 48.7032661, lng: 18.4129793 },
    { lat: 48.7032149, lng: 18.4132151 },
    { lat: 48.7032444, lng: 18.413401 },
    { lat: 48.7033787, lng: 18.4136857 },
    { lat: 48.7034171, lng: 18.4139294 },
    { lat: 48.703402, lng: 18.4143046 },
    { lat: 48.7034413, lng: 18.4145464 },
    { lat: 48.7035686, lng: 18.4148249 },
    { lat: 48.7036132, lng: 18.4147767 },
    { lat: 48.7036008, lng: 18.4147161 },
    { lat: 48.7036818, lng: 18.4147319 },
    { lat: 48.703731, lng: 18.414825 },
    { lat: 48.7038585, lng: 18.4148048 },
    { lat: 48.7040995, lng: 18.4147342 },
    { lat: 48.7045265, lng: 18.4147067 },
    { lat: 48.7047765, lng: 18.4146789 },
    { lat: 48.7051029, lng: 18.4146 },
    { lat: 48.7052697, lng: 18.4145988 },
    { lat: 48.7055626, lng: 18.414553 },
    { lat: 48.7063823, lng: 18.414342 },
    { lat: 48.7071399, lng: 18.4141279 },
    { lat: 48.7073714, lng: 18.4140103 },
    { lat: 48.7075664, lng: 18.4139448 },
    { lat: 48.7077634, lng: 18.4138583 },
    { lat: 48.7078942, lng: 18.413757 },
    { lat: 48.7079401, lng: 18.4139105 },
    { lat: 48.707942, lng: 18.4138042 },
    { lat: 48.7080215, lng: 18.4136708 },
    { lat: 48.7089016, lng: 18.4132068 },
    { lat: 48.7087293, lng: 18.4125654 },
    { lat: 48.7095047, lng: 18.412205 },
    { lat: 48.7103035, lng: 18.4117209 },
    { lat: 48.710242, lng: 18.4109603 },
    { lat: 48.7106887, lng: 18.4109189 },
    { lat: 48.7107462, lng: 18.4107973 },
    { lat: 48.7107377, lng: 18.4106233 },
    { lat: 48.7107622, lng: 18.4105163 },
    { lat: 48.7109877, lng: 18.4103122 },
    { lat: 48.7111137, lng: 18.4101036 },
    { lat: 48.7111844, lng: 18.4100361 },
    { lat: 48.7114711, lng: 18.4098982 },
    { lat: 48.711833, lng: 18.40975 },
    { lat: 48.7119053, lng: 18.4097329 },
    { lat: 48.7121219, lng: 18.4096104 },
    { lat: 48.712189, lng: 18.4096015 },
    { lat: 48.7122966, lng: 18.4095467 },
    { lat: 48.7123163, lng: 18.4095978 },
    { lat: 48.7124895, lng: 18.4094654 },
    { lat: 48.712866, lng: 18.4092385 },
    { lat: 48.7131865, lng: 18.4089956 },
    { lat: 48.7139451, lng: 18.4083157 },
    { lat: 48.7138969, lng: 18.4082015 },
    { lat: 48.7139642, lng: 18.4081122 },
    { lat: 48.7140625, lng: 18.408094 },
    { lat: 48.7141392, lng: 18.4080156 },
    { lat: 48.7142051, lng: 18.4080008 },
    { lat: 48.714323, lng: 18.407889 },
    { lat: 48.714407, lng: 18.407899 },
    { lat: 48.714447, lng: 18.4078875 },
    { lat: 48.7144706, lng: 18.407827 },
    { lat: 48.7146155, lng: 18.4077346 },
    { lat: 48.7148219, lng: 18.4075526 },
    { lat: 48.714889, lng: 18.4075594 },
    { lat: 48.7153803, lng: 18.4071865 },
    { lat: 48.7157143, lng: 18.4071479 },
    { lat: 48.7157714, lng: 18.4071796 },
    { lat: 48.7160975, lng: 18.4070166 },
    { lat: 48.7162152, lng: 18.4070245 },
    { lat: 48.7165956, lng: 18.4070083 },
    { lat: 48.7169127, lng: 18.4068865 },
    { lat: 48.7170458, lng: 18.4068049 },
    { lat: 48.7171053, lng: 18.4067191 },
    { lat: 48.7171965, lng: 18.4066971 },
    { lat: 48.7173056, lng: 18.4066318 },
    { lat: 48.7173389, lng: 18.4065797 },
    { lat: 48.7174722, lng: 18.4065034 },
    { lat: 48.7176658, lng: 18.4063337 },
    { lat: 48.7178054, lng: 18.4061448 },
    { lat: 48.7179274, lng: 18.4059246 },
    { lat: 48.7180436, lng: 18.4056426 },
    { lat: 48.7181083, lng: 18.4056061 },
    { lat: 48.7181246, lng: 18.4055337 },
    { lat: 48.7181804, lng: 18.4054033 },
    { lat: 48.718198, lng: 18.405336 },
    { lat: 48.7182494, lng: 18.4052834 },
    { lat: 48.7183055, lng: 18.4051576 },
    { lat: 48.7183672, lng: 18.4049069 },
    { lat: 48.718435, lng: 18.4048518 },
    { lat: 48.7186344, lng: 18.4047632 },
    { lat: 48.7189102, lng: 18.4044905 },
    { lat: 48.7189701, lng: 18.4043524 },
    { lat: 48.7190054, lng: 18.4043362 },
    { lat: 48.7190436, lng: 18.4042502 },
    { lat: 48.7190947, lng: 18.4042373 },
    { lat: 48.7191503, lng: 18.404183 },
    { lat: 48.719247, lng: 18.4041582 },
    { lat: 48.7195318, lng: 18.4038429 },
    { lat: 48.7195966, lng: 18.4038311 },
    { lat: 48.7197456, lng: 18.4036924 },
    { lat: 48.7197759, lng: 18.4033027 },
    { lat: 48.7197193, lng: 18.4030387 },
    { lat: 48.7197206, lng: 18.40215 },
    { lat: 48.719508, lng: 18.4012285 },
    { lat: 48.71932, lng: 18.4007312 },
    { lat: 48.7192148, lng: 18.3996924 },
    { lat: 48.7196655, lng: 18.3991766 },
    { lat: 48.7198178, lng: 18.3986734 },
    { lat: 48.7200265, lng: 18.3985915 },
    { lat: 48.7203322, lng: 18.3984092 },
    { lat: 48.7207417, lng: 18.3980259 },
    { lat: 48.7208497, lng: 18.398279 },
    { lat: 48.7214493, lng: 18.3981283 },
    { lat: 48.7216952, lng: 18.3977629 },
    { lat: 48.7219774, lng: 18.3980292 },
    { lat: 48.7221344, lng: 18.3982968 },
    { lat: 48.7223912, lng: 18.3988754 },
    { lat: 48.7226459, lng: 18.399415 },
    { lat: 48.7233537, lng: 18.3999101 },
    { lat: 48.7240125, lng: 18.4004136 },
    { lat: 48.7246556, lng: 18.4008598 },
    { lat: 48.7251941, lng: 18.4013358 },
    { lat: 48.7262288, lng: 18.40247 },
    { lat: 48.7272183, lng: 18.4035429 },
    { lat: 48.727396, lng: 18.403779 },
    { lat: 48.7274977, lng: 18.4039404 },
    { lat: 48.728076, lng: 18.404664 },
    { lat: 48.7291034, lng: 18.4055491 },
    { lat: 48.729514, lng: 18.4057324 },
    { lat: 48.7300138, lng: 18.406114 },
    { lat: 48.7309938, lng: 18.4073433 },
    { lat: 48.7320028, lng: 18.4088509 },
    { lat: 48.732072, lng: 18.4089593 },
    { lat: 48.7322106, lng: 18.4092594 },
    { lat: 48.7323534, lng: 18.409476 },
    { lat: 48.7324419, lng: 18.4095237 },
    { lat: 48.7325462, lng: 18.4096777 },
    { lat: 48.7328151, lng: 18.4098876 },
    { lat: 48.7330099, lng: 18.4101845 },
    { lat: 48.7331872, lng: 18.4102487 },
    { lat: 48.7334019, lng: 18.4101943 },
    { lat: 48.7337766, lng: 18.410356 },
    { lat: 48.7338583, lng: 18.4104268 },
    { lat: 48.734069, lng: 18.4106658 },
    { lat: 48.7341967, lng: 18.4107289 },
    { lat: 48.7343501, lng: 18.4108313 },
    { lat: 48.7344408, lng: 18.4108598 },
    { lat: 48.7345032, lng: 18.4109273 },
    { lat: 48.7345643, lng: 18.4110517 },
    { lat: 48.7345947, lng: 18.4112115 },
    { lat: 48.7346319, lng: 18.4113326 },
    { lat: 48.7347666, lng: 18.4115052 },
    { lat: 48.7349484, lng: 18.4114853 },
    { lat: 48.7350208, lng: 18.4115111 },
    { lat: 48.7350708, lng: 18.4115552 },
    { lat: 48.7352702, lng: 18.411958 },
    { lat: 48.7354052, lng: 18.4121324 },
    { lat: 48.735527, lng: 18.412193 },
    { lat: 48.7357185, lng: 18.4122301 },
    { lat: 48.7358639, lng: 18.412286 },
    { lat: 48.7360906, lng: 18.4123883 },
    { lat: 48.7362053, lng: 18.4124227 },
    { lat: 48.7363116, lng: 18.4124899 },
    { lat: 48.7364045, lng: 18.4124928 },
    { lat: 48.7364916, lng: 18.4124366 },
    { lat: 48.7365469, lng: 18.4124561 },
    { lat: 48.736604, lng: 18.4125735 },
    { lat: 48.736853, lng: 18.4128306 },
    { lat: 48.737027, lng: 18.4131995 },
    { lat: 48.7371881, lng: 18.4132952 },
    { lat: 48.7372915, lng: 18.4134933 },
    { lat: 48.7375126, lng: 18.4139609 },
    { lat: 48.7375403, lng: 18.41411 },
    { lat: 48.7376192, lng: 18.4142336 },
    { lat: 48.737706, lng: 18.414476 },
    { lat: 48.7377842, lng: 18.4145811 },
    { lat: 48.7378222, lng: 18.4146774 },
    { lat: 48.7379509, lng: 18.4151053 },
    { lat: 48.7381664, lng: 18.4156341 },
    { lat: 48.738441, lng: 18.4158836 },
    { lat: 48.7386153, lng: 18.4159351 },
    { lat: 48.7387978, lng: 18.4159323 },
    { lat: 48.7389747, lng: 18.4158262 },
    { lat: 48.7392719, lng: 18.4156326 },
    { lat: 48.7396774, lng: 18.4156411 },
    { lat: 48.7397533, lng: 18.4156071 },
    { lat: 48.7400296, lng: 18.4152575 },
    { lat: 48.7401811, lng: 18.4148575 },
    { lat: 48.7402983, lng: 18.4146947 },
    { lat: 48.7406007, lng: 18.414514 },
    { lat: 48.7405978, lng: 18.4144818 },
    { lat: 48.7405807, lng: 18.4135454 },
    { lat: 48.740845, lng: 18.411824 },
    { lat: 48.740294, lng: 18.411531 },
    { lat: 48.740038, lng: 18.411194 },
    { lat: 48.739713, lng: 18.410541 },
    { lat: 48.739369, lng: 18.409954 },
    { lat: 48.739367, lng: 18.409376 },
    { lat: 48.739288, lng: 18.409027 },
    { lat: 48.738917, lng: 18.407203 },
    { lat: 48.739101, lng: 18.40657 },
    { lat: 48.739455, lng: 18.406067 },
    { lat: 48.739633, lng: 18.40552 },
    { lat: 48.739804, lng: 18.404048 },
    { lat: 48.7396, lng: 18.403152 },
    { lat: 48.739579, lng: 18.40299 },
    { lat: 48.739603, lng: 18.402695 },
    { lat: 48.739952, lng: 18.401481 },
    { lat: 48.7399573, lng: 18.4012742 },
    { lat: 48.739964, lng: 18.40101 },
    { lat: 48.740489, lng: 18.40051 },
    { lat: 48.740676, lng: 18.400176 },
    { lat: 48.741036, lng: 18.399457 },
    { lat: 48.741289, lng: 18.398433 },
    { lat: 48.741299, lng: 18.39775 },
    { lat: 48.741301, lng: 18.397259 },
    { lat: 48.741405, lng: 18.396933 },
    { lat: 48.741573, lng: 18.396138 },
    { lat: 48.741582, lng: 18.395676 },
    { lat: 48.741543, lng: 18.395396 },
    { lat: 48.741411, lng: 18.395086 },
  ],
  HornáVes: [
    { lat: 48.584308, lng: 18.479933 },
    { lat: 48.5850342, lng: 18.4806593 },
    { lat: 48.585627, lng: 18.4811804 },
    { lat: 48.5861153, lng: 18.4816244 },
    { lat: 48.5866568, lng: 18.482159 },
    { lat: 48.5871234, lng: 18.4825391 },
    { lat: 48.5875103, lng: 18.4828636 },
    { lat: 48.5880306, lng: 18.4833614 },
    { lat: 48.5881181, lng: 18.4834516 },
    { lat: 48.5883728, lng: 18.4840624 },
    { lat: 48.5889116, lng: 18.484544 },
    { lat: 48.5895266, lng: 18.4852451 },
    { lat: 48.5893891, lng: 18.4855342 },
    { lat: 48.5893579, lng: 18.4858463 },
    { lat: 48.5894505, lng: 18.4862363 },
    { lat: 48.5895649, lng: 18.4862655 },
    { lat: 48.5899934, lng: 18.4860575 },
    { lat: 48.5901251, lng: 18.4861739 },
    { lat: 48.5903794, lng: 18.4863487 },
    { lat: 48.5906061, lng: 18.486735 },
    { lat: 48.5911041, lng: 18.4871159 },
    { lat: 48.5912618, lng: 18.4871728 },
    { lat: 48.591991, lng: 18.4877107 },
    { lat: 48.5920107, lng: 18.4879056 },
    { lat: 48.5928987, lng: 18.4880103 },
    { lat: 48.5932771, lng: 18.4882883 },
    { lat: 48.5939381, lng: 18.4890012 },
    { lat: 48.5944072, lng: 18.4894067 },
    { lat: 48.5946335, lng: 18.4890089 },
    { lat: 48.5947065, lng: 18.4890051 },
    { lat: 48.5950557, lng: 18.4891391 },
    { lat: 48.5953422, lng: 18.4892846 },
    { lat: 48.595865, lng: 18.4894551 },
    { lat: 48.5962346, lng: 18.4891397 },
    { lat: 48.5962672, lng: 18.4891171 },
    { lat: 48.5965561, lng: 18.4893892 },
    { lat: 48.5966881, lng: 18.4893263 },
    { lat: 48.5968948, lng: 18.4891268 },
    { lat: 48.5970671, lng: 18.4891601 },
    { lat: 48.5972051, lng: 18.4895787 },
    { lat: 48.5968721, lng: 18.4899415 },
    { lat: 48.596639, lng: 18.4903987 },
    { lat: 48.5964496, lng: 18.4910379 },
    { lat: 48.5963567, lng: 18.4923588 },
    { lat: 48.596609, lng: 18.4924758 },
    { lat: 48.596586, lng: 18.4933497 },
    { lat: 48.5967039, lng: 18.4933787 },
    { lat: 48.5964588, lng: 18.4941097 },
    { lat: 48.5964118, lng: 18.4943629 },
    { lat: 48.5964326, lng: 18.494536 },
    { lat: 48.5964369, lng: 18.4946342 },
    { lat: 48.596635, lng: 18.4948934 },
    { lat: 48.5965084, lng: 18.49571 },
    { lat: 48.5964265, lng: 18.4960648 },
    { lat: 48.5964515, lng: 18.49654 },
    { lat: 48.5965439, lng: 18.4966214 },
    { lat: 48.596825, lng: 18.4968282 },
    { lat: 48.5969233, lng: 18.4971791 },
    { lat: 48.5970468, lng: 18.4974134 },
    { lat: 48.5971222, lng: 18.4975333 },
    { lat: 48.5973907, lng: 18.4980863 },
    { lat: 48.5972701, lng: 18.4991563 },
    { lat: 48.5971031, lng: 18.4996904 },
    { lat: 48.5968974, lng: 18.4999089 },
    { lat: 48.5965059, lng: 18.5003766 },
    { lat: 48.5965443, lng: 18.5004591 },
    { lat: 48.5964348, lng: 18.5016278 },
    { lat: 48.5963665, lng: 18.5020283 },
    { lat: 48.5961815, lng: 18.5035246 },
    { lat: 48.5960884, lng: 18.5042522 },
    { lat: 48.5959789, lng: 18.5051932 },
    { lat: 48.5956823, lng: 18.5055855 },
    { lat: 48.5953304, lng: 18.5060774 },
    { lat: 48.5948882, lng: 18.506654 },
    { lat: 48.594718, lng: 18.5068823 },
    { lat: 48.5949184, lng: 18.5074706 },
    { lat: 48.5949645, lng: 18.5076127 },
    { lat: 48.5954566, lng: 18.509091 },
    { lat: 48.5957737, lng: 18.5100628 },
    { lat: 48.5960373, lng: 18.5109578 },
    { lat: 48.5963525, lng: 18.5111323 },
    { lat: 48.596404, lng: 18.5117427 },
    { lat: 48.5964216, lng: 18.5118557 },
    { lat: 48.5965172, lng: 18.5127749 },
    { lat: 48.5962299, lng: 18.5131083 },
    { lat: 48.5960203, lng: 18.5132991 },
    { lat: 48.5952838, lng: 18.5138774 },
    { lat: 48.5944331, lng: 18.5148816 },
    { lat: 48.5938465, lng: 18.5155976 },
    { lat: 48.5933433, lng: 18.5164179 },
    { lat: 48.5928901, lng: 18.5171836 },
    { lat: 48.5923542, lng: 18.5184573 },
    { lat: 48.5918147, lng: 18.5197279 },
    { lat: 48.5915152, lng: 18.5204558 },
    { lat: 48.5916498, lng: 18.520898 },
    { lat: 48.5918531, lng: 18.521457 },
    { lat: 48.5919242, lng: 18.5214777 },
    { lat: 48.5921222, lng: 18.521758 },
    { lat: 48.5919877, lng: 18.5221408 },
    { lat: 48.5918894, lng: 18.5222196 },
    { lat: 48.5916309, lng: 18.5224457 },
    { lat: 48.5914195, lng: 18.522621 },
    { lat: 48.5912074, lng: 18.5228053 },
    { lat: 48.590582, lng: 18.5233311 },
    { lat: 48.5902215, lng: 18.5234006 },
    { lat: 48.5899411, lng: 18.5234298 },
    { lat: 48.5897679, lng: 18.5234396 },
    { lat: 48.5892762, lng: 18.5235686 },
    { lat: 48.5886527, lng: 18.5236551 },
    { lat: 48.5884071, lng: 18.5235774 },
    { lat: 48.5881639, lng: 18.5235222 },
    { lat: 48.5877241, lng: 18.5234027 },
    { lat: 48.5874436, lng: 18.5241724 },
    { lat: 48.5872924, lng: 18.5247246 },
    { lat: 48.5868066, lng: 18.5259876 },
    { lat: 48.5866218, lng: 18.5261958 },
    { lat: 48.5860808, lng: 18.5266159 },
    { lat: 48.5855865, lng: 18.5268004 },
    { lat: 48.5851608, lng: 18.5269781 },
    { lat: 48.584463, lng: 18.5271494 },
    { lat: 48.5842518, lng: 18.5271874 },
    { lat: 48.5838627, lng: 18.527187 },
    { lat: 48.5836192, lng: 18.5273143 },
    { lat: 48.5827403, lng: 18.5277625 },
    { lat: 48.582128, lng: 18.5282192 },
    { lat: 48.5819662, lng: 18.5283148 },
    { lat: 48.5817188, lng: 18.5284507 },
    { lat: 48.581257, lng: 18.5288965 },
    { lat: 48.5808045, lng: 18.5292449 },
    { lat: 48.5804905, lng: 18.5297688 },
    { lat: 48.5802564, lng: 18.5301916 },
    { lat: 48.5800245, lng: 18.5308214 },
    { lat: 48.5798815, lng: 18.531442 },
    { lat: 48.5798965, lng: 18.5318804 },
    { lat: 48.579609, lng: 18.5328292 },
    { lat: 48.5791325, lng: 18.5333783 },
    { lat: 48.5788152, lng: 18.5337651 },
    { lat: 48.578383, lng: 18.5340486 },
    { lat: 48.5776722, lng: 18.5342713 },
    { lat: 48.5773681, lng: 18.5343857 },
    { lat: 48.5770011, lng: 18.5346646 },
    { lat: 48.5763378, lng: 18.5354285 },
    { lat: 48.575922, lng: 18.5359108 },
    { lat: 48.5755693, lng: 18.536266 },
    { lat: 48.5750615, lng: 18.5371502 },
    { lat: 48.574737, lng: 18.5373618 },
    { lat: 48.5744376, lng: 18.5375191 },
    { lat: 48.5738783, lng: 18.5380797 },
    { lat: 48.5731562, lng: 18.5384701 },
    { lat: 48.5728538, lng: 18.5388574 },
    { lat: 48.572642, lng: 18.539215 },
    { lat: 48.5723807, lng: 18.5396111 },
    { lat: 48.5721018, lng: 18.5401419 },
    { lat: 48.571575, lng: 18.5411558 },
    { lat: 48.5710778, lng: 18.5421091 },
    { lat: 48.5707933, lng: 18.5426591 },
    { lat: 48.5705742, lng: 18.5432946 },
    { lat: 48.5703014, lng: 18.5440607 },
    { lat: 48.5701415, lng: 18.5444392 },
    { lat: 48.5696049, lng: 18.5456195 },
    { lat: 48.5694216, lng: 18.5461452 },
    { lat: 48.5691124, lng: 18.546456 },
    { lat: 48.5686839, lng: 18.5475288 },
    { lat: 48.5685765, lng: 18.5480814 },
    { lat: 48.5684123, lng: 18.5483806 },
    { lat: 48.5683541, lng: 18.5487386 },
    { lat: 48.5682005, lng: 18.5489432 },
    { lat: 48.5680958, lng: 18.5492561 },
    { lat: 48.5680145, lng: 18.5495838 },
    { lat: 48.5676784, lng: 18.5499998 },
    { lat: 48.5675118, lng: 18.5504219 },
    { lat: 48.5675223, lng: 18.5505752 },
    { lat: 48.5675399, lng: 18.5508326 },
    { lat: 48.5674383, lng: 18.5512213 },
    { lat: 48.5673381, lng: 18.5518249 },
    { lat: 48.5672484, lng: 18.552185 },
    { lat: 48.5670907, lng: 18.5526009 },
    { lat: 48.5669055, lng: 18.5529925 },
    { lat: 48.5669368, lng: 18.5533462 },
    { lat: 48.566948, lng: 18.553472 },
    { lat: 48.567004, lng: 18.553846 },
    { lat: 48.567002, lng: 18.554029 },
    { lat: 48.56703, lng: 18.5543243 },
    { lat: 48.567087, lng: 18.554926 },
    { lat: 48.567177, lng: 18.55604 },
    { lat: 48.56717, lng: 18.557037 },
    { lat: 48.567169, lng: 18.557089 },
    { lat: 48.567138, lng: 18.558243 },
    { lat: 48.567037, lng: 18.559141 },
    { lat: 48.567033, lng: 18.559189 },
    { lat: 48.566927, lng: 18.560372 },
    { lat: 48.56689, lng: 18.561555 },
    { lat: 48.566901, lng: 18.561727 },
    { lat: 48.56696, lng: 18.562534 },
    { lat: 48.566703, lng: 18.563566 },
    { lat: 48.566645, lng: 18.563788 },
    { lat: 48.566587, lng: 18.564053 },
    { lat: 48.566568, lng: 18.564698 },
    { lat: 48.56654, lng: 18.565633 },
    { lat: 48.56683, lng: 18.566714 },
    { lat: 48.566977, lng: 18.56727 },
    { lat: 48.567284, lng: 18.567925 },
    { lat: 48.56801, lng: 18.567456 },
    { lat: 48.568641, lng: 18.566371 },
    { lat: 48.569265, lng: 18.565807 },
    { lat: 48.569385, lng: 18.565715 },
    { lat: 48.569409, lng: 18.565696 },
    { lat: 48.569598, lng: 18.56555 },
    { lat: 48.570069, lng: 18.565188 },
    { lat: 48.570598, lng: 18.566138 },
    { lat: 48.571133, lng: 18.567063 },
    { lat: 48.571431, lng: 18.567571 },
    { lat: 48.571854, lng: 18.568289 },
    { lat: 48.572362, lng: 18.569163 },
    { lat: 48.572693, lng: 18.569721 },
    { lat: 48.572722, lng: 18.569772 },
    { lat: 48.572922, lng: 18.570109 },
    { lat: 48.573363, lng: 18.570843 },
    { lat: 48.573683, lng: 18.571374 },
    { lat: 48.573788, lng: 18.571556 },
    { lat: 48.574248, lng: 18.572297 },
    { lat: 48.574918, lng: 18.573331 },
    { lat: 48.575655, lng: 18.574594 },
    { lat: 48.576018, lng: 18.575113 },
    { lat: 48.576037, lng: 18.57514 },
    { lat: 48.576104, lng: 18.57527 },
    { lat: 48.576136, lng: 18.575331 },
    { lat: 48.576263, lng: 18.575577 },
    { lat: 48.576293, lng: 18.575617 },
    { lat: 48.576352, lng: 18.575692 },
    { lat: 48.576417, lng: 18.575734 },
    { lat: 48.576437, lng: 18.575762 },
    { lat: 48.576668, lng: 18.576101 },
    { lat: 48.576954, lng: 18.576519 },
    { lat: 48.577447, lng: 18.577391 },
    { lat: 48.577469, lng: 18.577452 },
    { lat: 48.577248, lng: 18.578265 },
    { lat: 48.577029, lng: 18.578501 },
    { lat: 48.576956, lng: 18.578773 },
    { lat: 48.576935, lng: 18.578849 },
    { lat: 48.576513, lng: 18.580418 },
    { lat: 48.57648, lng: 18.580723 },
    { lat: 48.576289, lng: 18.582008 },
    { lat: 48.576414, lng: 18.583015 },
    { lat: 48.576528, lng: 18.58402 },
    { lat: 48.576724, lng: 18.585562 },
    { lat: 48.57698, lng: 18.586805 },
    { lat: 48.576998, lng: 18.587073 },
    { lat: 48.577005, lng: 18.587184 },
    { lat: 48.577017, lng: 18.587362 },
    { lat: 48.576989, lng: 18.587526 },
    { lat: 48.576974, lng: 18.587611 },
    { lat: 48.576824, lng: 18.588478 },
    { lat: 48.5767, lng: 18.589163 },
    { lat: 48.576682, lng: 18.589819 },
    { lat: 48.576625, lng: 18.591131 },
    { lat: 48.576316, lng: 18.592278 },
    { lat: 48.576258, lng: 18.592522 },
    { lat: 48.576229, lng: 18.592647 },
    { lat: 48.57617, lng: 18.5929 },
    { lat: 48.576043, lng: 18.593347 },
    { lat: 48.57592, lng: 18.593864 },
    { lat: 48.575862, lng: 18.594988 },
    { lat: 48.575754, lng: 18.595368 },
    { lat: 48.575704, lng: 18.595597 },
    { lat: 48.575698, lng: 18.595625 },
    { lat: 48.57568, lng: 18.595706 },
    { lat: 48.575524, lng: 18.596423 },
    { lat: 48.575457, lng: 18.596728 },
    { lat: 48.575286, lng: 18.597593 },
    { lat: 48.57528, lng: 18.597633 },
    { lat: 48.575275, lng: 18.597673 },
    { lat: 48.575273, lng: 18.597696 },
    { lat: 48.576199, lng: 18.598218 },
    { lat: 48.576688, lng: 18.598674 },
    { lat: 48.576837, lng: 18.598814 },
    { lat: 48.576966, lng: 18.598934 },
    { lat: 48.578358, lng: 18.599738 },
    { lat: 48.578642, lng: 18.599833 },
    { lat: 48.578826, lng: 18.599895 },
    { lat: 48.579237, lng: 18.600033 },
    { lat: 48.579308, lng: 18.600057 },
    { lat: 48.580092, lng: 18.60049 },
    { lat: 48.580224, lng: 18.600565 },
    { lat: 48.580795, lng: 18.600891 },
    { lat: 48.581299, lng: 18.601176 },
    { lat: 48.581772, lng: 18.601409 },
    { lat: 48.582351, lng: 18.601664 },
    { lat: 48.582413, lng: 18.601691 },
    { lat: 48.583506, lng: 18.602214 },
    { lat: 48.583841, lng: 18.602366 },
    { lat: 48.584914, lng: 18.601959 },
    { lat: 48.585765, lng: 18.601192 },
    { lat: 48.586342, lng: 18.600614 },
    { lat: 48.586801, lng: 18.600196 },
    { lat: 48.587626, lng: 18.599715 },
    { lat: 48.588381, lng: 18.599278 },
    { lat: 48.588938, lng: 18.599225 },
    { lat: 48.58974, lng: 18.599673 },
    { lat: 48.590465, lng: 18.600086 },
    { lat: 48.591133, lng: 18.600664 },
    { lat: 48.591736, lng: 18.601188 },
    { lat: 48.591957, lng: 18.60138 },
    { lat: 48.592587, lng: 18.601623 },
    { lat: 48.593121, lng: 18.602439 },
    { lat: 48.5940918, lng: 18.601243 },
    { lat: 48.5941309, lng: 18.6009821 },
    { lat: 48.594139, lng: 18.6009258 },
    { lat: 48.5942294, lng: 18.6003268 },
    { lat: 48.594691, lng: 18.5997241 },
    { lat: 48.5951992, lng: 18.5991125 },
    { lat: 48.5956366, lng: 18.5987529 },
    { lat: 48.5930747, lng: 18.5980364 },
    { lat: 48.5919686, lng: 18.5977759 },
    { lat: 48.5917794, lng: 18.5966751 },
    { lat: 48.5915999, lng: 18.5959664 },
    { lat: 48.5913286, lng: 18.5951419 },
    { lat: 48.591271, lng: 18.5940573 },
    { lat: 48.5910809, lng: 18.5930275 },
    { lat: 48.5908731, lng: 18.5916928 },
    { lat: 48.5910496, lng: 18.5900791 },
    { lat: 48.591058, lng: 18.5889646 },
    { lat: 48.5909636, lng: 18.5878479 },
    { lat: 48.5905169, lng: 18.5870817 },
    { lat: 48.5898106, lng: 18.5865353 },
    { lat: 48.5894565, lng: 18.5864529 },
    { lat: 48.5888148, lng: 18.5861871 },
    { lat: 48.5884601, lng: 18.5853872 },
    { lat: 48.5884893, lng: 18.5843019 },
    { lat: 48.5885133, lng: 18.5831018 },
    { lat: 48.5885212, lng: 18.5829783 },
    { lat: 48.5885002, lng: 18.5826563 },
    { lat: 48.5883577, lng: 18.5820251 },
    { lat: 48.5883261, lng: 18.5818352 },
    { lat: 48.5883254, lng: 18.5817833 },
    { lat: 48.5882404, lng: 18.5815066 },
    { lat: 48.5881328, lng: 18.58131 },
    { lat: 48.588135, lng: 18.5811014 },
    { lat: 48.5881649, lng: 18.580947 },
    { lat: 48.5881428, lng: 18.5807281 },
    { lat: 48.5881442, lng: 18.5807101 },
    { lat: 48.5881129, lng: 18.580342 },
    { lat: 48.5880421, lng: 18.579848 },
    { lat: 48.588067, lng: 18.5795813 },
    { lat: 48.5880387, lng: 18.5792859 },
    { lat: 48.5879937, lng: 18.5789965 },
    { lat: 48.5879376, lng: 18.5785481 },
    { lat: 48.5879367, lng: 18.5780743 },
    { lat: 48.5878602, lng: 18.577746 },
    { lat: 48.5879014, lng: 18.5775507 },
    { lat: 48.5879162, lng: 18.5770148 },
    { lat: 48.5878874, lng: 18.5768758 },
    { lat: 48.58781, lng: 18.5768038 },
    { lat: 48.5877549, lng: 18.5766389 },
    { lat: 48.5876926, lng: 18.5765319 },
    { lat: 48.5876481, lng: 18.5761918 },
    { lat: 48.5874635, lng: 18.5759481 },
    { lat: 48.5874184, lng: 18.575762 },
    { lat: 48.5874477, lng: 18.5755959 },
    { lat: 48.5873326, lng: 18.5753881 },
    { lat: 48.5872301, lng: 18.5750588 },
    { lat: 48.5870106, lng: 18.5747132 },
    { lat: 48.5869047, lng: 18.5744249 },
    { lat: 48.5868797, lng: 18.5742346 },
    { lat: 48.5867367, lng: 18.5739704 },
    { lat: 48.5866958, lng: 18.5736954 },
    { lat: 48.5865988, lng: 18.5735895 },
    { lat: 48.5863526, lng: 18.5731913 },
    { lat: 48.5861795, lng: 18.5729505 },
    { lat: 48.5861685, lng: 18.5726658 },
    { lat: 48.5861091, lng: 18.5725299 },
    { lat: 48.5861225, lng: 18.5724035 },
    { lat: 48.5861008, lng: 18.5722741 },
    { lat: 48.5860508, lng: 18.5721402 },
    { lat: 48.5859977, lng: 18.5720606 },
    { lat: 48.5856863, lng: 18.5717446 },
    { lat: 48.5856892, lng: 18.571673 },
    { lat: 48.585643, lng: 18.5715315 },
    { lat: 48.5855557, lng: 18.5713567 },
    { lat: 48.5854022, lng: 18.571189 },
    { lat: 48.5853181, lng: 18.5711328 },
    { lat: 48.5852119, lng: 18.5711537 },
    { lat: 48.5851254, lng: 18.5710533 },
    { lat: 48.5850943, lng: 18.5709214 },
    { lat: 48.5850516, lng: 18.5709017 },
    { lat: 48.5850279, lng: 18.5708826 },
    { lat: 48.5849393, lng: 18.5706619 },
    { lat: 48.5847205, lng: 18.570228 },
    { lat: 48.5847046, lng: 18.5700572 },
    { lat: 48.5845928, lng: 18.5696403 },
    { lat: 48.5844847, lng: 18.5694175 },
    { lat: 48.5844853, lng: 18.5693027 },
    { lat: 48.5845184, lng: 18.5690649 },
    { lat: 48.5844686, lng: 18.5689955 },
    { lat: 48.5844637, lng: 18.5689719 },
    { lat: 48.5844875, lng: 18.5687833 },
    { lat: 48.5844944, lng: 18.5685791 },
    { lat: 48.5844608, lng: 18.5683135 },
    { lat: 48.5844427, lng: 18.5680247 },
    { lat: 48.5844807, lng: 18.5676253 },
    { lat: 48.5845595, lng: 18.5675564 },
    { lat: 48.5845875, lng: 18.5674322 },
    { lat: 48.584593, lng: 18.5673649 },
    { lat: 48.5845697, lng: 18.5672274 },
    { lat: 48.5846625, lng: 18.5670039 },
    { lat: 48.5846302, lng: 18.5668273 },
    { lat: 48.5846759, lng: 18.5668159 },
    { lat: 48.5846862, lng: 18.5667972 },
    { lat: 48.5847388, lng: 18.5666047 },
    { lat: 48.5847668, lng: 18.5662714 },
    { lat: 48.5847182, lng: 18.5662036 },
    { lat: 48.5847236, lng: 18.5660769 },
    { lat: 48.5847454, lng: 18.5660229 },
    { lat: 48.584763, lng: 18.5659117 },
    { lat: 48.5848211, lng: 18.5657983 },
    { lat: 48.5848151, lng: 18.5657698 },
    { lat: 48.5847561, lng: 18.5657415 },
    { lat: 48.5845991, lng: 18.5651368 },
    { lat: 48.5845657, lng: 18.5648363 },
    { lat: 48.5844741, lng: 18.5645391 },
    { lat: 48.5845144, lng: 18.5644109 },
    { lat: 48.5844977, lng: 18.5641646 },
    { lat: 48.5845101, lng: 18.5641449 },
    { lat: 48.584465, lng: 18.5638341 },
    { lat: 48.5844087, lng: 18.5638151 },
    { lat: 48.5842895, lng: 18.5636802 },
    { lat: 48.5842568, lng: 18.5635731 },
    { lat: 48.584276, lng: 18.5635319 },
    { lat: 48.5843015, lng: 18.5633795 },
    { lat: 48.5842611, lng: 18.5630322 },
    { lat: 48.5842079, lng: 18.5626543 },
    { lat: 48.5842237, lng: 18.5626143 },
    { lat: 48.5844957, lng: 18.5623889 },
    { lat: 48.5846571, lng: 18.5619589 },
    { lat: 48.5847345, lng: 18.5617206 },
    { lat: 48.5847318, lng: 18.5614637 },
    { lat: 48.584839, lng: 18.5612189 },
    { lat: 48.5849402, lng: 18.5609028 },
    { lat: 48.5850023, lng: 18.5608406 },
    { lat: 48.5850343, lng: 18.5607041 },
    { lat: 48.5850608, lng: 18.5606303 },
    { lat: 48.5852241, lng: 18.5604384 },
    { lat: 48.5853995, lng: 18.560283 },
    { lat: 48.5854502, lng: 18.5602198 },
    { lat: 48.5854682, lng: 18.5601555 },
    { lat: 48.5855128, lng: 18.559777 },
    { lat: 48.58553, lng: 18.5596769 },
    { lat: 48.5856678, lng: 18.5590171 },
    { lat: 48.5856842, lng: 18.5589666 },
    { lat: 48.5857418, lng: 18.5589043 },
    { lat: 48.5858419, lng: 18.5588374 },
    { lat: 48.586044, lng: 18.5585341 },
    { lat: 48.5861692, lng: 18.5581598 },
    { lat: 48.5862636, lng: 18.5579885 },
    { lat: 48.5864375, lng: 18.557931 },
    { lat: 48.5866161, lng: 18.5576263 },
    { lat: 48.5866169, lng: 18.5570413 },
    { lat: 48.5866321, lng: 18.5570091 },
    { lat: 48.5867748, lng: 18.556877 },
    { lat: 48.5868643, lng: 18.5567152 },
    { lat: 48.5868895, lng: 18.5566843 },
    { lat: 48.5870134, lng: 18.5566195 },
    { lat: 48.5870614, lng: 18.5564428 },
    { lat: 48.5871671, lng: 18.5562915 },
    { lat: 48.5872756, lng: 18.5562129 },
    { lat: 48.5873078, lng: 18.5561009 },
    { lat: 48.5873504, lng: 18.5558256 },
    { lat: 48.5873604, lng: 18.5556576 },
    { lat: 48.5875026, lng: 18.5554512 },
    { lat: 48.5875954, lng: 18.5552508 },
    { lat: 48.5876762, lng: 18.5551639 },
    { lat: 48.5877812, lng: 18.5550988 },
    { lat: 48.587842, lng: 18.5548925 },
    { lat: 48.5879109, lng: 18.5548334 },
    { lat: 48.5880117, lng: 18.5548796 },
    { lat: 48.5880387, lng: 18.5548809 },
    { lat: 48.5880914, lng: 18.5548327 },
    { lat: 48.5881775, lng: 18.554651 },
    { lat: 48.5882166, lng: 18.5545414 },
    { lat: 48.588311, lng: 18.5544339 },
    { lat: 48.5885769, lng: 18.554407 },
    { lat: 48.5887209, lng: 18.5545252 },
    { lat: 48.5887735, lng: 18.5545159 },
    { lat: 48.5888644, lng: 18.5544262 },
    { lat: 48.5889803, lng: 18.5542323 },
    { lat: 48.5890884, lng: 18.5541473 },
    { lat: 48.5891327, lng: 18.5541314 },
    { lat: 48.5893258, lng: 18.5541146 },
    { lat: 48.5894885, lng: 18.5540592 },
    { lat: 48.5895638, lng: 18.5539921 },
    { lat: 48.589578, lng: 18.5539202 },
    { lat: 48.589657, lng: 18.5538594 },
    { lat: 48.5897673, lng: 18.553854 },
    { lat: 48.589988, lng: 18.5537222 },
    { lat: 48.5901263, lng: 18.5535707 },
    { lat: 48.5902036, lng: 18.5535405 },
    { lat: 48.5902856, lng: 18.5535367 },
    { lat: 48.5903658, lng: 18.553494 },
    { lat: 48.5904518, lng: 18.5533561 },
    { lat: 48.5906099, lng: 18.5529867 },
    { lat: 48.5908311, lng: 18.5528862 },
    { lat: 48.5908845, lng: 18.5527898 },
    { lat: 48.5909288, lng: 18.55265 },
    { lat: 48.5909477, lng: 18.5525414 },
    { lat: 48.590974, lng: 18.5524648 },
    { lat: 48.5911462, lng: 18.5520853 },
    { lat: 48.5912175, lng: 18.552027 },
    { lat: 48.5912508, lng: 18.5519787 },
    { lat: 48.59127, lng: 18.5519367 },
    { lat: 48.5913037, lng: 18.5517697 },
    { lat: 48.5912796, lng: 18.5515963 },
    { lat: 48.5912756, lng: 18.551502 },
    { lat: 48.5912833, lng: 18.5514141 },
    { lat: 48.5912986, lng: 18.5513431 },
    { lat: 48.5913182, lng: 18.5512905 },
    { lat: 48.591485, lng: 18.5509776 },
    { lat: 48.5914914, lng: 18.550866 },
    { lat: 48.5914871, lng: 18.5508084 },
    { lat: 48.591418, lng: 18.5507632 },
    { lat: 48.5914001, lng: 18.5507437 },
    { lat: 48.591406, lng: 18.5506478 },
    { lat: 48.5914323, lng: 18.5504447 },
    { lat: 48.5914242, lng: 18.5503612 },
    { lat: 48.5914135, lng: 18.5503301 },
    { lat: 48.5912808, lng: 18.5501506 },
    { lat: 48.5911921, lng: 18.5499108 },
    { lat: 48.5911173, lng: 18.5497586 },
    { lat: 48.5911273, lng: 18.5496127 },
    { lat: 48.5911586, lng: 18.5494624 },
    { lat: 48.5911429, lng: 18.5493169 },
    { lat: 48.5910146, lng: 18.5491375 },
    { lat: 48.5909167, lng: 18.5488724 },
    { lat: 48.5907901, lng: 18.5486394 },
    { lat: 48.590667, lng: 18.5484716 },
    { lat: 48.5906213, lng: 18.54838 },
    { lat: 48.5905948, lng: 18.5482878 },
    { lat: 48.5906018, lng: 18.5482306 },
    { lat: 48.5906251, lng: 18.5481597 },
    { lat: 48.5907034, lng: 18.5480259 },
    { lat: 48.5906678, lng: 18.5477642 },
    { lat: 48.5907026, lng: 18.5474912 },
    { lat: 48.5906839, lng: 18.5474618 },
    { lat: 48.5906974, lng: 18.5473141 },
    { lat: 48.590749, lng: 18.5470604 },
    { lat: 48.5907865, lng: 18.5469414 },
    { lat: 48.5908489, lng: 18.5467042 },
    { lat: 48.5908858, lng: 18.5466558 },
    { lat: 48.5909256, lng: 18.5466198 },
    { lat: 48.5910513, lng: 18.5464631 },
    { lat: 48.5910642, lng: 18.5464309 },
    { lat: 48.591057, lng: 18.5461339 },
    { lat: 48.5911143, lng: 18.5458806 },
    { lat: 48.5911713, lng: 18.5457299 },
    { lat: 48.591223, lng: 18.545664 },
    { lat: 48.5913324, lng: 18.5456205 },
    { lat: 48.5913863, lng: 18.5454951 },
    { lat: 48.5914, lng: 18.5454111 },
    { lat: 48.5914569, lng: 18.5452152 },
    { lat: 48.59159, lng: 18.5449874 },
    { lat: 48.5916974, lng: 18.5448508 },
    { lat: 48.5919699, lng: 18.5446933 },
    { lat: 48.5919918, lng: 18.5446011 },
    { lat: 48.5919744, lng: 18.5443442 },
    { lat: 48.5919883, lng: 18.5442477 },
    { lat: 48.5920729, lng: 18.5440822 },
    { lat: 48.5921174, lng: 18.5439449 },
    { lat: 48.5921421, lng: 18.5436753 },
    { lat: 48.5921725, lng: 18.543488 },
    { lat: 48.5921728, lng: 18.5433935 },
    { lat: 48.5921494, lng: 18.5433163 },
    { lat: 48.5921595, lng: 18.5431504 },
    { lat: 48.5921594, lng: 18.5429626 },
    { lat: 48.5920913, lng: 18.5427445 },
    { lat: 48.5920582, lng: 18.5425109 },
    { lat: 48.5918748, lng: 18.5421913 },
    { lat: 48.5918263, lng: 18.5420273 },
    { lat: 48.5918186, lng: 18.541946 },
    { lat: 48.591835, lng: 18.5418534 },
    { lat: 48.591903, lng: 18.5416747 },
    { lat: 48.5919025, lng: 18.5416049 },
    { lat: 48.5919368, lng: 18.5415703 },
    { lat: 48.5921881, lng: 18.5408835 },
    { lat: 48.5922574, lng: 18.5407901 },
    { lat: 48.5922626, lng: 18.540803 },
    { lat: 48.592328, lng: 18.5406587 },
    { lat: 48.5924078, lng: 18.5402419 },
    { lat: 48.5925238, lng: 18.5398042 },
    { lat: 48.5925435, lng: 18.5397108 },
    { lat: 48.5926294, lng: 18.5395259 },
    { lat: 48.5929397, lng: 18.5389707 },
    { lat: 48.5934146, lng: 18.5378605 },
    { lat: 48.5938045, lng: 18.5366572 },
    { lat: 48.5940219, lng: 18.5357453 },
    { lat: 48.5943113, lng: 18.534744 },
    { lat: 48.5943654, lng: 18.5341853 },
    { lat: 48.5944983, lng: 18.5334604 },
    { lat: 48.5945722, lng: 18.5327507 },
    { lat: 48.5946321, lng: 18.5320106 },
    { lat: 48.5948292, lng: 18.531056 },
    { lat: 48.5948634, lng: 18.5308604 },
    { lat: 48.5949534, lng: 18.5302377 },
    { lat: 48.595, lng: 18.5299749 },
    { lat: 48.59506, lng: 18.5297098 },
    { lat: 48.5952571, lng: 18.5292513 },
    { lat: 48.595453, lng: 18.5288933 },
    { lat: 48.5956219, lng: 18.5285366 },
    { lat: 48.595871, lng: 18.5279194 },
    { lat: 48.596024, lng: 18.5273338 },
    { lat: 48.5962041, lng: 18.5264173 },
    { lat: 48.5963425, lng: 18.526105 },
    { lat: 48.5966127, lng: 18.5256226 },
    { lat: 48.5974907, lng: 18.524023 },
    { lat: 48.5981657, lng: 18.5227964 },
    { lat: 48.5987599, lng: 18.5217223 },
    { lat: 48.5999763, lng: 18.5196099 },
    { lat: 48.6006305, lng: 18.5184802 },
    { lat: 48.6007484, lng: 18.5182627 },
    { lat: 48.6009307, lng: 18.5177813 },
    { lat: 48.6011871, lng: 18.5173965 },
    { lat: 48.6014497, lng: 18.5169001 },
    { lat: 48.6018021, lng: 18.5163753 },
    { lat: 48.6021417, lng: 18.5157142 },
    { lat: 48.6021871, lng: 18.5155988 },
    { lat: 48.6024274, lng: 18.5154327 },
    { lat: 48.6026008, lng: 18.5151199 },
    { lat: 48.6028111, lng: 18.5147575 },
    { lat: 48.6030424, lng: 18.5143284 },
    { lat: 48.6034018, lng: 18.513806 },
    { lat: 48.6035047, lng: 18.5136263 },
    { lat: 48.6036666, lng: 18.5134536 },
    { lat: 48.603885, lng: 18.5131127 },
    { lat: 48.604263, lng: 18.5125405 },
    { lat: 48.6044252, lng: 18.5123093 },
    { lat: 48.6046959, lng: 18.5118574 },
    { lat: 48.6048408, lng: 18.5115991 },
    { lat: 48.6050975, lng: 18.5109155 },
    { lat: 48.6052393, lng: 18.5107039 },
    { lat: 48.6055432, lng: 18.5104435 },
    { lat: 48.6056799, lng: 18.5102636 },
    { lat: 48.6060929, lng: 18.5094635 },
    { lat: 48.6062171, lng: 18.5091176 },
    { lat: 48.6063707, lng: 18.5088539 },
    { lat: 48.6066467, lng: 18.5084355 },
    { lat: 48.6068087, lng: 18.5081009 },
    { lat: 48.6069691, lng: 18.5078364 },
    { lat: 48.6071466, lng: 18.5075144 },
    { lat: 48.6072979, lng: 18.5071476 },
    { lat: 48.6075007, lng: 18.5067958 },
    { lat: 48.6075415, lng: 18.5067372 },
    { lat: 48.6080002, lng: 18.505519 },
    { lat: 48.6082846, lng: 18.5046582 },
    { lat: 48.6083876, lng: 18.50446 },
    { lat: 48.6086582, lng: 18.5039635 },
    { lat: 48.6088436, lng: 18.5036645 },
    { lat: 48.6094843, lng: 18.502269 },
    { lat: 48.6095141, lng: 18.5021534 },
    { lat: 48.6098981, lng: 18.5013593 },
    { lat: 48.6103168, lng: 18.5004842 },
    { lat: 48.610728, lng: 18.4994906 },
    { lat: 48.6112614, lng: 18.4982981 },
    { lat: 48.6115702, lng: 18.4976154 },
    { lat: 48.6116292, lng: 18.4973556 },
    { lat: 48.6118051, lng: 18.4969236 },
    { lat: 48.6118102, lng: 18.4968742 },
    { lat: 48.6122065, lng: 18.496159 },
    { lat: 48.612483, lng: 18.4957533 },
    { lat: 48.6128499, lng: 18.4950807 },
    { lat: 48.6133079, lng: 18.4944259 },
    { lat: 48.6135705, lng: 18.494034 },
    { lat: 48.6142774, lng: 18.4931655 },
    { lat: 48.6143785, lng: 18.4930138 },
    { lat: 48.6145027, lng: 18.4927491 },
    { lat: 48.6149688, lng: 18.4921 },
    { lat: 48.6153108, lng: 18.4916308 },
    { lat: 48.6156363, lng: 18.4913144 },
    { lat: 48.6157384, lng: 18.4911797 },
    { lat: 48.6161067, lng: 18.4906405 },
    { lat: 48.6164761, lng: 18.4899735 },
    { lat: 48.6165554, lng: 18.4897946 },
    { lat: 48.6165299, lng: 18.4897614 },
    { lat: 48.6165636, lng: 18.489677 },
    { lat: 48.6167201, lng: 18.4894467 },
    { lat: 48.616805, lng: 18.4895544 },
    { lat: 48.6170584, lng: 18.4890735 },
    { lat: 48.6169283, lng: 18.4889047 },
    { lat: 48.6172645, lng: 18.4882497 },
    { lat: 48.6174803, lng: 18.4878455 },
    { lat: 48.617673, lng: 18.4875547 },
    { lat: 48.6177149, lng: 18.4876211 },
    { lat: 48.6181466, lng: 18.4870004 },
    { lat: 48.6184736, lng: 18.486487 },
    { lat: 48.6188359, lng: 18.4859789 },
    { lat: 48.6191293, lng: 18.4855085 },
    { lat: 48.6191964, lng: 18.4855803 },
    { lat: 48.6193628, lng: 18.4854468 },
    { lat: 48.6195652, lng: 18.4852506 },
    { lat: 48.6201216, lng: 18.4843141 },
    { lat: 48.6202854, lng: 18.4839716 },
    { lat: 48.6202911, lng: 18.483767 },
    { lat: 48.6200745, lng: 18.4826197 },
    { lat: 48.620004, lng: 18.4826317 },
    { lat: 48.6199659, lng: 18.4826151 },
    { lat: 48.6191941, lng: 18.4809778 },
    { lat: 48.6191335, lng: 18.4809208 },
    { lat: 48.6191642, lng: 18.4808635 },
    { lat: 48.6191563, lng: 18.4808011 },
    { lat: 48.6190588, lng: 18.480732 },
    { lat: 48.6190543, lng: 18.480611 },
    { lat: 48.6191301, lng: 18.4804735 },
    { lat: 48.619176, lng: 18.4803629 },
    { lat: 48.6192487, lng: 18.4801456 },
    { lat: 48.6192084, lng: 18.4800711 },
    { lat: 48.6189509, lng: 18.4798218 },
    { lat: 48.6185886, lng: 18.4794073 },
    { lat: 48.6184505, lng: 18.4792129 },
    { lat: 48.6177612, lng: 18.4783129 },
    { lat: 48.6175779, lng: 18.4779772 },
    { lat: 48.6173597, lng: 18.4777415 },
    { lat: 48.6172453, lng: 18.4776518 },
    { lat: 48.6171022, lng: 18.4775745 },
    { lat: 48.6169859, lng: 18.4775521 },
    { lat: 48.6168195, lng: 18.477399 },
    { lat: 48.6169648, lng: 18.4772135 },
    { lat: 48.6172058, lng: 18.4767519 },
    { lat: 48.6173951, lng: 18.4763017 },
    { lat: 48.6174492, lng: 18.4759117 },
    { lat: 48.6176333, lng: 18.4751997 },
    { lat: 48.6177815, lng: 18.4748788 },
    { lat: 48.6181395, lng: 18.473827 },
    { lat: 48.6184238, lng: 18.4729488 },
    { lat: 48.6185218, lng: 18.4726812 },
    { lat: 48.6187165, lng: 18.4722442 },
    { lat: 48.619052, lng: 18.471659 },
    { lat: 48.6189896, lng: 18.4715899 },
    { lat: 48.6191758, lng: 18.4710409 },
    { lat: 48.6195381, lng: 18.4700855 },
    { lat: 48.6197765, lng: 18.4693346 },
    { lat: 48.6198692, lng: 18.4689928 },
    { lat: 48.6201163, lng: 18.4676689 },
    { lat: 48.6202638, lng: 18.4671137 },
    { lat: 48.6203888, lng: 18.4667618 },
    { lat: 48.6205485, lng: 18.466362 },
    { lat: 48.6206219, lng: 18.4660786 },
    { lat: 48.6205444, lng: 18.4660227 },
    { lat: 48.620547, lng: 18.465977 },
    { lat: 48.620457, lng: 18.465936 },
    { lat: 48.620412, lng: 18.465951 },
    { lat: 48.620372, lng: 18.466013 },
    { lat: 48.620347, lng: 18.46605 },
    { lat: 48.62031, lng: 18.466078 },
    { lat: 48.620203, lng: 18.466137 },
    { lat: 48.620146, lng: 18.466126 },
    { lat: 48.620082, lng: 18.466113 },
    { lat: 48.62005, lng: 18.4661 },
    { lat: 48.619996, lng: 18.466115 },
    { lat: 48.619959, lng: 18.46622 },
    { lat: 48.619902, lng: 18.46622 },
    { lat: 48.619887, lng: 18.466256 },
    { lat: 48.61987, lng: 18.466385 },
    { lat: 48.619821, lng: 18.466412 },
    { lat: 48.619811, lng: 18.466487 },
    { lat: 48.619765, lng: 18.466532 },
    { lat: 48.619764, lng: 18.466593 },
    { lat: 48.619676, lng: 18.466752 },
    { lat: 48.619605, lng: 18.466983 },
    { lat: 48.619594, lng: 18.467055 },
    { lat: 48.619587, lng: 18.467176 },
    { lat: 48.619568, lng: 18.467293 },
    { lat: 48.619514, lng: 18.467348 },
    { lat: 48.619501, lng: 18.467375 },
    { lat: 48.619474, lng: 18.467451 },
    { lat: 48.61941, lng: 18.467582 },
    { lat: 48.619404, lng: 18.467701 },
    { lat: 48.61931, lng: 18.467684 },
    { lat: 48.619294, lng: 18.467718 },
    { lat: 48.619322, lng: 18.467806 },
    { lat: 48.619311, lng: 18.467942 },
    { lat: 48.619251, lng: 18.468096 },
    { lat: 48.619188, lng: 18.468099 },
    { lat: 48.619164, lng: 18.468259 },
    { lat: 48.619073, lng: 18.46833 },
    { lat: 48.619042, lng: 18.468562 },
    { lat: 48.618952, lng: 18.468591 },
    { lat: 48.618874, lng: 18.468705 },
    { lat: 48.618871, lng: 18.468747 },
    { lat: 48.618916, lng: 18.46897 },
    { lat: 48.618944, lng: 18.468988 },
    { lat: 48.61901, lng: 18.469064 },
    { lat: 48.619011, lng: 18.469108 },
    { lat: 48.618958, lng: 18.469215 },
    { lat: 48.618826, lng: 18.469285 },
    { lat: 48.618749, lng: 18.469402 },
    { lat: 48.618732, lng: 18.469528 },
    { lat: 48.618604, lng: 18.469532 },
    { lat: 48.618536, lng: 18.469566 },
    { lat: 48.618501, lng: 18.46969 },
    { lat: 48.618465, lng: 18.469722 },
    { lat: 48.618459, lng: 18.46975 },
    { lat: 48.618509, lng: 18.469823 },
    { lat: 48.618517, lng: 18.469862 },
    { lat: 48.618511, lng: 18.469947 },
    { lat: 48.618454, lng: 18.47004 },
    { lat: 48.618402, lng: 18.470059 },
    { lat: 48.618311, lng: 18.470172 },
    { lat: 48.618216, lng: 18.470231 },
    { lat: 48.618213, lng: 18.47036 },
    { lat: 48.618149, lng: 18.470462 },
    { lat: 48.618103, lng: 18.470578 },
    { lat: 48.618098, lng: 18.470655 },
    { lat: 48.617987, lng: 18.47071 },
    { lat: 48.618008, lng: 18.470812 },
    { lat: 48.617919, lng: 18.470875 },
    { lat: 48.617863, lng: 18.471025 },
    { lat: 48.617777, lng: 18.471083 },
    { lat: 48.61774, lng: 18.471167 },
    { lat: 48.617784, lng: 18.471219 },
    { lat: 48.617742, lng: 18.471323 },
    { lat: 48.617714, lng: 18.471349 },
    { lat: 48.617643, lng: 18.471402 },
    { lat: 48.617617, lng: 18.471391 },
    { lat: 48.617539, lng: 18.471441 },
    { lat: 48.617456, lng: 18.471456 },
    { lat: 48.61744, lng: 18.471463 },
    { lat: 48.6174, lng: 18.471527 },
    { lat: 48.617391, lng: 18.471594 },
    { lat: 48.617329, lng: 18.471795 },
    { lat: 48.617342, lng: 18.471831 },
    { lat: 48.61736, lng: 18.472001 },
    { lat: 48.617322, lng: 18.472045 },
    { lat: 48.617234, lng: 18.472013 },
    { lat: 48.617163, lng: 18.472148 },
    { lat: 48.617187, lng: 18.472302 },
    { lat: 48.617155, lng: 18.472349 },
    { lat: 48.61716, lng: 18.472537 },
    { lat: 48.616962, lng: 18.472627 },
    { lat: 48.616906, lng: 18.472666 },
    { lat: 48.616925, lng: 18.47283 },
    { lat: 48.616969, lng: 18.472963 },
    { lat: 48.616932, lng: 18.4731 },
    { lat: 48.616948, lng: 18.473172 },
    { lat: 48.616943, lng: 18.473246 },
    { lat: 48.616886, lng: 18.473352 },
    { lat: 48.616818, lng: 18.473556 },
    { lat: 48.616835, lng: 18.473637 },
    { lat: 48.616759, lng: 18.473785 },
    { lat: 48.616646, lng: 18.473917 },
    { lat: 48.616609, lng: 18.473996 },
    { lat: 48.616576, lng: 18.474048 },
    { lat: 48.616577, lng: 18.474111 },
    { lat: 48.616562, lng: 18.474198 },
    { lat: 48.61652, lng: 18.474182 },
    { lat: 48.616043, lng: 18.473678 },
    { lat: 48.615954, lng: 18.473622 },
    { lat: 48.615886, lng: 18.47353 },
    { lat: 48.615589, lng: 18.473267 },
    { lat: 48.615414, lng: 18.473187 },
    { lat: 48.615277, lng: 18.472893 },
    { lat: 48.615119, lng: 18.472857 },
    { lat: 48.615115, lng: 18.472872 },
    { lat: 48.614633, lng: 18.472395 },
    { lat: 48.61418, lng: 18.471973 },
    { lat: 48.614191, lng: 18.471851 },
    { lat: 48.613485, lng: 18.471158 },
    { lat: 48.612618, lng: 18.47031 },
    { lat: 48.611902, lng: 18.469611 },
    { lat: 48.611564, lng: 18.469271 },
    { lat: 48.611541, lng: 18.469247 },
    { lat: 48.611343, lng: 18.469049 },
    { lat: 48.610857, lng: 18.468558 },
    { lat: 48.609948, lng: 18.467701 },
    { lat: 48.609033, lng: 18.466811 },
    { lat: 48.608437, lng: 18.466219 },
    { lat: 48.607855, lng: 18.465644 },
    { lat: 48.607722, lng: 18.465512 },
    { lat: 48.607468, lng: 18.465276 },
    { lat: 48.607454, lng: 18.465259 },
    { lat: 48.606557, lng: 18.465847 },
    { lat: 48.606183, lng: 18.466051 },
    { lat: 48.605953, lng: 18.466061 },
    { lat: 48.605596, lng: 18.466072 },
    { lat: 48.605434, lng: 18.466074 },
    { lat: 48.60524, lng: 18.466079 },
    { lat: 48.604402, lng: 18.465877 },
    { lat: 48.60345, lng: 18.465648 },
    { lat: 48.603408, lng: 18.465686 },
    { lat: 48.602909, lng: 18.466128 },
    { lat: 48.602598, lng: 18.466403 },
    { lat: 48.602047, lng: 18.466882 },
    { lat: 48.601539, lng: 18.467327 },
    { lat: 48.60133, lng: 18.467506 },
    { lat: 48.601023, lng: 18.467773 },
    { lat: 48.600325, lng: 18.468385 },
    { lat: 48.600207, lng: 18.468489 },
    { lat: 48.5997274, lng: 18.4689109 },
    { lat: 48.599667, lng: 18.468964 },
    { lat: 48.599177, lng: 18.46939 },
    { lat: 48.598953, lng: 18.469588 },
    { lat: 48.598473, lng: 18.470014 },
    { lat: 48.598235, lng: 18.47023 },
    { lat: 48.597344, lng: 18.471007 },
    { lat: 48.596286, lng: 18.471937 },
    { lat: 48.59627, lng: 18.471943 },
    { lat: 48.595637, lng: 18.472208 },
    { lat: 48.595589, lng: 18.47223 },
    { lat: 48.59503, lng: 18.472465 },
    { lat: 48.594668, lng: 18.472603 },
    { lat: 48.593859, lng: 18.472915 },
    { lat: 48.593717, lng: 18.472874 },
    { lat: 48.592769, lng: 18.472602 },
    { lat: 48.592136, lng: 18.472418 },
    { lat: 48.591509, lng: 18.472148 },
    { lat: 48.590819, lng: 18.471854 },
    { lat: 48.590403, lng: 18.471674 },
    { lat: 48.590153, lng: 18.471565 },
    { lat: 48.589647, lng: 18.47178 },
    { lat: 48.589161, lng: 18.471987 },
    { lat: 48.588742, lng: 18.472162 },
    { lat: 48.588392, lng: 18.472316 },
    { lat: 48.588205, lng: 18.472693 },
    { lat: 48.587849, lng: 18.473362 },
    { lat: 48.587546, lng: 18.473939 },
    { lat: 48.587404, lng: 18.474132 },
    { lat: 48.587075, lng: 18.47458 },
    { lat: 48.586677, lng: 18.475075 },
    { lat: 48.586362, lng: 18.475474 },
    { lat: 48.586046, lng: 18.475881 },
    { lat: 48.585757, lng: 18.476267 },
    { lat: 48.58555, lng: 18.476549 },
    { lat: 48.585451, lng: 18.476683 },
    { lat: 48.585394, lng: 18.476758 },
    { lat: 48.585002, lng: 18.477906 },
    { lat: 48.584639, lng: 18.478967 },
    { lat: 48.584364, lng: 18.479779 },
    { lat: 48.584317, lng: 18.479915 },
    { lat: 48.584308, lng: 18.479933 },
  ],
  HornéVestenice: [
    { lat: 48.740845, lng: 18.411824 },
    { lat: 48.7405807, lng: 18.4135454 },
    { lat: 48.7405978, lng: 18.4144818 },
    { lat: 48.7406007, lng: 18.414514 },
    { lat: 48.7402983, lng: 18.4146947 },
    { lat: 48.7401811, lng: 18.4148575 },
    { lat: 48.7400296, lng: 18.4152575 },
    { lat: 48.7397533, lng: 18.4156071 },
    { lat: 48.7396774, lng: 18.4156411 },
    { lat: 48.7392719, lng: 18.4156326 },
    { lat: 48.7389747, lng: 18.4158262 },
    { lat: 48.7387978, lng: 18.4159323 },
    { lat: 48.7386153, lng: 18.4159351 },
    { lat: 48.738441, lng: 18.4158836 },
    { lat: 48.7381664, lng: 18.4156341 },
    { lat: 48.7379509, lng: 18.4151053 },
    { lat: 48.7378222, lng: 18.4146774 },
    { lat: 48.7377842, lng: 18.4145811 },
    { lat: 48.737706, lng: 18.414476 },
    { lat: 48.7376192, lng: 18.4142336 },
    { lat: 48.7375403, lng: 18.41411 },
    { lat: 48.7375126, lng: 18.4139609 },
    { lat: 48.7372915, lng: 18.4134933 },
    { lat: 48.7371881, lng: 18.4132952 },
    { lat: 48.737027, lng: 18.4131995 },
    { lat: 48.736853, lng: 18.4128306 },
    { lat: 48.736604, lng: 18.4125735 },
    { lat: 48.7365469, lng: 18.4124561 },
    { lat: 48.7364916, lng: 18.4124366 },
    { lat: 48.7364045, lng: 18.4124928 },
    { lat: 48.7363116, lng: 18.4124899 },
    { lat: 48.7362053, lng: 18.4124227 },
    { lat: 48.7360906, lng: 18.4123883 },
    { lat: 48.7358639, lng: 18.412286 },
    { lat: 48.7357185, lng: 18.4122301 },
    { lat: 48.735527, lng: 18.412193 },
    { lat: 48.7354052, lng: 18.4121324 },
    { lat: 48.7352702, lng: 18.411958 },
    { lat: 48.7350708, lng: 18.4115552 },
    { lat: 48.7350208, lng: 18.4115111 },
    { lat: 48.7349484, lng: 18.4114853 },
    { lat: 48.7347666, lng: 18.4115052 },
    { lat: 48.7346319, lng: 18.4113326 },
    { lat: 48.7345947, lng: 18.4112115 },
    { lat: 48.7345643, lng: 18.4110517 },
    { lat: 48.7345032, lng: 18.4109273 },
    { lat: 48.7344408, lng: 18.4108598 },
    { lat: 48.7343501, lng: 18.4108313 },
    { lat: 48.7341967, lng: 18.4107289 },
    { lat: 48.734069, lng: 18.4106658 },
    { lat: 48.7338583, lng: 18.4104268 },
    { lat: 48.7337766, lng: 18.410356 },
    { lat: 48.7334019, lng: 18.4101943 },
    { lat: 48.7331872, lng: 18.4102487 },
    { lat: 48.7330099, lng: 18.4101845 },
    { lat: 48.7328151, lng: 18.4098876 },
    { lat: 48.7325462, lng: 18.4096777 },
    { lat: 48.7324419, lng: 18.4095237 },
    { lat: 48.7323534, lng: 18.409476 },
    { lat: 48.7322106, lng: 18.4092594 },
    { lat: 48.732072, lng: 18.4089593 },
    { lat: 48.7320028, lng: 18.4088509 },
    { lat: 48.7309938, lng: 18.4073433 },
    { lat: 48.7300138, lng: 18.406114 },
    { lat: 48.729514, lng: 18.4057324 },
    { lat: 48.7291034, lng: 18.4055491 },
    { lat: 48.728076, lng: 18.404664 },
    { lat: 48.7274977, lng: 18.4039404 },
    { lat: 48.727396, lng: 18.403779 },
    { lat: 48.7272183, lng: 18.4035429 },
    { lat: 48.7262288, lng: 18.40247 },
    { lat: 48.7251941, lng: 18.4013358 },
    { lat: 48.7246556, lng: 18.4008598 },
    { lat: 48.7240125, lng: 18.4004136 },
    { lat: 48.7233537, lng: 18.3999101 },
    { lat: 48.7226459, lng: 18.399415 },
    { lat: 48.7223912, lng: 18.3988754 },
    { lat: 48.7221344, lng: 18.3982968 },
    { lat: 48.7219774, lng: 18.3980292 },
    { lat: 48.7216952, lng: 18.3977629 },
    { lat: 48.7214493, lng: 18.3981283 },
    { lat: 48.7208497, lng: 18.398279 },
    { lat: 48.7207417, lng: 18.3980259 },
    { lat: 48.7203322, lng: 18.3984092 },
    { lat: 48.7200265, lng: 18.3985915 },
    { lat: 48.7198178, lng: 18.3986734 },
    { lat: 48.7196655, lng: 18.3991766 },
    { lat: 48.7192148, lng: 18.3996924 },
    { lat: 48.71932, lng: 18.4007312 },
    { lat: 48.719508, lng: 18.4012285 },
    { lat: 48.7197206, lng: 18.40215 },
    { lat: 48.7197193, lng: 18.4030387 },
    { lat: 48.7197759, lng: 18.4033027 },
    { lat: 48.7197456, lng: 18.4036924 },
    { lat: 48.7195966, lng: 18.4038311 },
    { lat: 48.7195318, lng: 18.4038429 },
    { lat: 48.719247, lng: 18.4041582 },
    { lat: 48.7191503, lng: 18.404183 },
    { lat: 48.7190947, lng: 18.4042373 },
    { lat: 48.7190436, lng: 18.4042502 },
    { lat: 48.7190054, lng: 18.4043362 },
    { lat: 48.7189701, lng: 18.4043524 },
    { lat: 48.7189102, lng: 18.4044905 },
    { lat: 48.7186344, lng: 18.4047632 },
    { lat: 48.718435, lng: 18.4048518 },
    { lat: 48.7183672, lng: 18.4049069 },
    { lat: 48.7183055, lng: 18.4051576 },
    { lat: 48.7182494, lng: 18.4052834 },
    { lat: 48.718198, lng: 18.405336 },
    { lat: 48.7181804, lng: 18.4054033 },
    { lat: 48.7181246, lng: 18.4055337 },
    { lat: 48.7181083, lng: 18.4056061 },
    { lat: 48.7180436, lng: 18.4056426 },
    { lat: 48.7179274, lng: 18.4059246 },
    { lat: 48.7178054, lng: 18.4061448 },
    { lat: 48.7176658, lng: 18.4063337 },
    { lat: 48.7174722, lng: 18.4065034 },
    { lat: 48.7173389, lng: 18.4065797 },
    { lat: 48.7173056, lng: 18.4066318 },
    { lat: 48.7171965, lng: 18.4066971 },
    { lat: 48.7171053, lng: 18.4067191 },
    { lat: 48.7170458, lng: 18.4068049 },
    { lat: 48.7169127, lng: 18.4068865 },
    { lat: 48.7165956, lng: 18.4070083 },
    { lat: 48.7162152, lng: 18.4070245 },
    { lat: 48.7160975, lng: 18.4070166 },
    { lat: 48.7157714, lng: 18.4071796 },
    { lat: 48.7157143, lng: 18.4071479 },
    { lat: 48.7153803, lng: 18.4071865 },
    { lat: 48.714889, lng: 18.4075594 },
    { lat: 48.7148219, lng: 18.4075526 },
    { lat: 48.7146155, lng: 18.4077346 },
    { lat: 48.7144706, lng: 18.407827 },
    { lat: 48.714447, lng: 18.4078875 },
    { lat: 48.714407, lng: 18.407899 },
    { lat: 48.714323, lng: 18.407889 },
    { lat: 48.7142051, lng: 18.4080008 },
    { lat: 48.7141392, lng: 18.4080156 },
    { lat: 48.7140625, lng: 18.408094 },
    { lat: 48.7139642, lng: 18.4081122 },
    { lat: 48.7138969, lng: 18.4082015 },
    { lat: 48.7139451, lng: 18.4083157 },
    { lat: 48.7131865, lng: 18.4089956 },
    { lat: 48.712866, lng: 18.4092385 },
    { lat: 48.7124895, lng: 18.4094654 },
    { lat: 48.7123163, lng: 18.4095978 },
    { lat: 48.7122966, lng: 18.4095467 },
    { lat: 48.712189, lng: 18.4096015 },
    { lat: 48.7121219, lng: 18.4096104 },
    { lat: 48.7119053, lng: 18.4097329 },
    { lat: 48.711833, lng: 18.40975 },
    { lat: 48.7114711, lng: 18.4098982 },
    { lat: 48.7111844, lng: 18.4100361 },
    { lat: 48.7111137, lng: 18.4101036 },
    { lat: 48.7109877, lng: 18.4103122 },
    { lat: 48.7107622, lng: 18.4105163 },
    { lat: 48.7107377, lng: 18.4106233 },
    { lat: 48.7107462, lng: 18.4107973 },
    { lat: 48.7106887, lng: 18.4109189 },
    { lat: 48.710242, lng: 18.4109603 },
    { lat: 48.7103035, lng: 18.4117209 },
    { lat: 48.7095047, lng: 18.412205 },
    { lat: 48.7087293, lng: 18.4125654 },
    { lat: 48.7089016, lng: 18.4132068 },
    { lat: 48.7080215, lng: 18.4136708 },
    { lat: 48.707942, lng: 18.4138042 },
    { lat: 48.7079401, lng: 18.4139105 },
    { lat: 48.7078942, lng: 18.413757 },
    { lat: 48.7077634, lng: 18.4138583 },
    { lat: 48.7075664, lng: 18.4139448 },
    { lat: 48.7073714, lng: 18.4140103 },
    { lat: 48.7071399, lng: 18.4141279 },
    { lat: 48.7063823, lng: 18.414342 },
    { lat: 48.7055626, lng: 18.414553 },
    { lat: 48.7052697, lng: 18.4145988 },
    { lat: 48.7051029, lng: 18.4146 },
    { lat: 48.7047765, lng: 18.4146789 },
    { lat: 48.7045265, lng: 18.4147067 },
    { lat: 48.7040995, lng: 18.4147342 },
    { lat: 48.7038585, lng: 18.4148048 },
    { lat: 48.703731, lng: 18.414825 },
    { lat: 48.7036818, lng: 18.4147319 },
    { lat: 48.7036008, lng: 18.4147161 },
    { lat: 48.7036132, lng: 18.4147767 },
    { lat: 48.7035686, lng: 18.4148249 },
    { lat: 48.7035302, lng: 18.4148658 },
    { lat: 48.7035327, lng: 18.4149916 },
    { lat: 48.7034944, lng: 18.4150722 },
    { lat: 48.7033755, lng: 18.4151441 },
    { lat: 48.7033583, lng: 18.4151209 },
    { lat: 48.7032538, lng: 18.4152306 },
    { lat: 48.7031798, lng: 18.4153996 },
    { lat: 48.7030481, lng: 18.4155492 },
    { lat: 48.7026574, lng: 18.4160892 },
    { lat: 48.7026796, lng: 18.4161439 },
    { lat: 48.7026101, lng: 18.4162506 },
    { lat: 48.7024914, lng: 18.4163896 },
    { lat: 48.702335, lng: 18.4165995 },
    { lat: 48.7019469, lng: 18.4168645 },
    { lat: 48.7017689, lng: 18.4170896 },
    { lat: 48.7016453, lng: 18.4172821 },
    { lat: 48.7015742, lng: 18.417444 },
    { lat: 48.701517, lng: 18.4176312 },
    { lat: 48.7015281, lng: 18.4180734 },
    { lat: 48.701562, lng: 18.418198 },
    { lat: 48.7017699, lng: 18.4184677 },
    { lat: 48.7018835, lng: 18.4186236 },
    { lat: 48.702205, lng: 18.4189475 },
    { lat: 48.7022564, lng: 18.4190413 },
    { lat: 48.7023162, lng: 18.4191823 },
    { lat: 48.7023559, lng: 18.4194119 },
    { lat: 48.7023859, lng: 18.4198485 },
    { lat: 48.7024689, lng: 18.4201847 },
    { lat: 48.7025121, lng: 18.420656 },
    { lat: 48.7025241, lng: 18.4210163 },
    { lat: 48.7025051, lng: 18.4212009 },
    { lat: 48.7024716, lng: 18.4217126 },
    { lat: 48.7024455, lng: 18.4219156 },
    { lat: 48.7024037, lng: 18.4226441 },
    { lat: 48.7023438, lng: 18.4228467 },
    { lat: 48.7022856, lng: 18.4232798 },
    { lat: 48.702362, lng: 18.4237605 },
    { lat: 48.7024528, lng: 18.4239943 },
    { lat: 48.7024882, lng: 18.4242866 },
    { lat: 48.7024555, lng: 18.4244268 },
    { lat: 48.7023975, lng: 18.4248256 },
    { lat: 48.7024082, lng: 18.4249161 },
    { lat: 48.7023922, lng: 18.4250142 },
    { lat: 48.7022746, lng: 18.4255573 },
    { lat: 48.7022657, lng: 18.425762 },
    { lat: 48.7022433, lng: 18.425947 },
    { lat: 48.7022383, lng: 18.4262055 },
    { lat: 48.7023445, lng: 18.4271213 },
    { lat: 48.7025239, lng: 18.4281172 },
    { lat: 48.7025394, lng: 18.4283966 },
    { lat: 48.7026122, lng: 18.4288142 },
    { lat: 48.7026452, lng: 18.429285 },
    { lat: 48.7027697, lng: 18.4296357 },
    { lat: 48.702859, lng: 18.4299886 },
    { lat: 48.7029808, lng: 18.430615 },
    { lat: 48.7031214, lng: 18.4313208 },
    { lat: 48.7035009, lng: 18.4311645 },
    { lat: 48.70351, lng: 18.431248 },
    { lat: 48.7041945, lng: 18.4309401 },
    { lat: 48.7044935, lng: 18.430799 },
    { lat: 48.7044615, lng: 18.4310742 },
    { lat: 48.7043412, lng: 18.4313255 },
    { lat: 48.7041852, lng: 18.4314824 },
    { lat: 48.7043128, lng: 18.4316484 },
    { lat: 48.7044569, lng: 18.4325956 },
    { lat: 48.7060088, lng: 18.4319175 },
    { lat: 48.7066985, lng: 18.4315579 },
    { lat: 48.7072625, lng: 18.4312725 },
    { lat: 48.707308, lng: 18.4312825 },
    { lat: 48.70724, lng: 18.4321705 },
    { lat: 48.7072783, lng: 18.4322299 },
    { lat: 48.7079837, lng: 18.4331703 },
    { lat: 48.7084584, lng: 18.4338788 },
    { lat: 48.7086229, lng: 18.4340787 },
    { lat: 48.7088332, lng: 18.4346557 },
    { lat: 48.7089923, lng: 18.4353893 },
    { lat: 48.7090081, lng: 18.4355509 },
    { lat: 48.7090839, lng: 18.4357199 },
    { lat: 48.7091581, lng: 18.435774 },
    { lat: 48.7092027, lng: 18.4357645 },
    { lat: 48.7092929, lng: 18.4356612 },
    { lat: 48.7094244, lng: 18.4355717 },
    { lat: 48.70947, lng: 18.4354992 },
    { lat: 48.7095513, lng: 18.435196 },
    { lat: 48.7096104, lng: 18.434877 },
    { lat: 48.7097253, lng: 18.4345492 },
    { lat: 48.7098272, lng: 18.4343959 },
    { lat: 48.7098528, lng: 18.4345498 },
    { lat: 48.7099623, lng: 18.4361787 },
    { lat: 48.7100059, lng: 18.436679 },
    { lat: 48.7100262, lng: 18.4368618 },
    { lat: 48.7101288, lng: 18.43731 },
    { lat: 48.7102221, lng: 18.4376289 },
    { lat: 48.7103784, lng: 18.4380267 },
    { lat: 48.710643, lng: 18.4384022 },
    { lat: 48.7108137, lng: 18.4385317 },
    { lat: 48.7110698, lng: 18.4386139 },
    { lat: 48.7113853, lng: 18.4386879 },
    { lat: 48.7117235, lng: 18.4387459 },
    { lat: 48.7119473, lng: 18.4388129 },
    { lat: 48.7120863, lng: 18.4389425 },
    { lat: 48.7122191, lng: 18.43918 },
    { lat: 48.7123168, lng: 18.4402078 },
    { lat: 48.7123005, lng: 18.440546 },
    { lat: 48.7124351, lng: 18.4405344 },
    { lat: 48.7130711, lng: 18.4408132 },
    { lat: 48.7142078, lng: 18.4408066 },
    { lat: 48.7144438, lng: 18.4408303 },
    { lat: 48.7158653, lng: 18.4411981 },
    { lat: 48.7178247, lng: 18.4417475 },
    { lat: 48.7179467, lng: 18.4422176 },
    { lat: 48.7183032, lng: 18.4421582 },
    { lat: 48.7190336, lng: 18.4421644 },
    { lat: 48.7193729, lng: 18.4424058 },
    { lat: 48.7198807, lng: 18.4427563 },
    { lat: 48.721282, lng: 18.4425547 },
    { lat: 48.7216364, lng: 18.4433324 },
    { lat: 48.7226749, lng: 18.4424875 },
    { lat: 48.7226324, lng: 18.4413317 },
    { lat: 48.72342, lng: 18.4392984 },
    { lat: 48.7235079, lng: 18.4392379 },
    { lat: 48.723631, lng: 18.4390969 },
    { lat: 48.7236486, lng: 18.4390086 },
    { lat: 48.7236843, lng: 18.4390001 },
    { lat: 48.7239137, lng: 18.4385802 },
    { lat: 48.7239797, lng: 18.4383853 },
    { lat: 48.7240202, lng: 18.4381591 },
    { lat: 48.7240719, lng: 18.437911 },
    { lat: 48.7241539, lng: 18.4377823 },
    { lat: 48.7242248, lng: 18.4376161 },
    { lat: 48.7242241, lng: 18.4375437 },
    { lat: 48.7243281, lng: 18.4374241 },
    { lat: 48.7243432, lng: 18.4373517 },
    { lat: 48.7244836, lng: 18.4370992 },
    { lat: 48.7247329, lng: 18.4368111 },
    { lat: 48.7253943, lng: 18.4358219 },
    { lat: 48.7256741, lng: 18.4363485 },
    { lat: 48.7256095, lng: 18.4364875 },
    { lat: 48.7255086, lng: 18.436864 },
    { lat: 48.7252791, lng: 18.4373258 },
    { lat: 48.725177, lng: 18.4376592 },
    { lat: 48.7252475, lng: 18.4376892 },
    { lat: 48.7253629, lng: 18.43784 },
    { lat: 48.7254658, lng: 18.4380278 },
    { lat: 48.7257808, lng: 18.4384396 },
    { lat: 48.725888, lng: 18.4386636 },
    { lat: 48.7260739, lng: 18.4392286 },
    { lat: 48.726227, lng: 18.4395908 },
    { lat: 48.7263811, lng: 18.439786 },
    { lat: 48.7267664, lng: 18.4403447 },
    { lat: 48.7268543, lng: 18.4404454 },
    { lat: 48.7269806, lng: 18.4405666 },
    { lat: 48.7271169, lng: 18.4406037 },
    { lat: 48.7273809, lng: 18.4406242 },
    { lat: 48.7275815, lng: 18.4405781 },
    { lat: 48.7277169, lng: 18.4403984 },
    { lat: 48.7276521, lng: 18.4402226 },
    { lat: 48.7283469, lng: 18.4396519 },
    { lat: 48.7286951, lng: 18.4394638 },
    { lat: 48.7291919, lng: 18.4387795 },
    { lat: 48.7295086, lng: 18.4385703 },
    { lat: 48.7304022, lng: 18.4380416 },
    { lat: 48.7307138, lng: 18.4385743 },
    { lat: 48.7310778, lng: 18.4392396 },
    { lat: 48.7318497, lng: 18.4405084 },
    { lat: 48.7341713, lng: 18.4435209 },
    { lat: 48.7346994, lng: 18.4442381 },
    { lat: 48.7356451, lng: 18.4422465 },
    { lat: 48.7358731, lng: 18.4416816 },
    { lat: 48.7366266, lng: 18.4402328 },
    { lat: 48.7366746, lng: 18.4399168 },
    { lat: 48.7367318, lng: 18.4396859 },
    { lat: 48.7371513, lng: 18.4387068 },
    { lat: 48.7373448, lng: 18.4383116 },
    { lat: 48.7378667, lng: 18.4376491 },
    { lat: 48.7384967, lng: 18.4363566 },
    { lat: 48.7386061, lng: 18.43607 },
    { lat: 48.738779, lng: 18.4355088 },
    { lat: 48.7387989, lng: 18.435275 },
    { lat: 48.7388395, lng: 18.4349715 },
    { lat: 48.7389946, lng: 18.4345758 },
    { lat: 48.7391417, lng: 18.434257 },
    { lat: 48.7394736, lng: 18.4337542 },
    { lat: 48.7395646, lng: 18.4335836 },
    { lat: 48.7397699, lng: 18.4333592 },
    { lat: 48.7399676, lng: 18.433159 },
    { lat: 48.7401871, lng: 18.4330511 },
    { lat: 48.7405143, lng: 18.43295 },
    { lat: 48.7405721, lng: 18.4328111 },
    { lat: 48.7406014, lng: 18.4323199 },
    { lat: 48.7405271, lng: 18.4321821 },
    { lat: 48.7404129, lng: 18.4318541 },
    { lat: 48.7403525, lng: 18.4315798 },
    { lat: 48.7403531, lng: 18.4314239 },
    { lat: 48.7404187, lng: 18.4311664 },
    { lat: 48.7405952, lng: 18.4307245 },
    { lat: 48.7407849, lng: 18.4301396 },
    { lat: 48.7414923, lng: 18.4291442 },
    { lat: 48.7416915, lng: 18.42875 },
    { lat: 48.7420517, lng: 18.428289 },
    { lat: 48.7427726, lng: 18.4279416 },
    { lat: 48.7430813, lng: 18.4272841 },
    { lat: 48.743175, lng: 18.4269411 },
    { lat: 48.7432166, lng: 18.4267896 },
    { lat: 48.7436384, lng: 18.4255534 },
    { lat: 48.7440524, lng: 18.4244943 },
    { lat: 48.744944, lng: 18.424502 },
    { lat: 48.7450305, lng: 18.4241525 },
    { lat: 48.7452013, lng: 18.424035 },
    { lat: 48.7454785, lng: 18.4238668 },
    { lat: 48.745884, lng: 18.423367 },
    { lat: 48.745687, lng: 18.422811 },
    { lat: 48.745807, lng: 18.422126 },
    { lat: 48.745852, lng: 18.420769 },
    { lat: 48.746067, lng: 18.41951 },
    { lat: 48.746112, lng: 18.417681 },
    { lat: 48.74565, lng: 18.417034 },
    { lat: 48.745552, lng: 18.416823 },
    { lat: 48.745361, lng: 18.416414 },
    { lat: 48.745049, lng: 18.415974 },
    { lat: 48.744607, lng: 18.415326 },
    { lat: 48.744569, lng: 18.415269 },
    { lat: 48.744028, lng: 18.414447 },
    { lat: 48.743876, lng: 18.414212 },
    { lat: 48.743856, lng: 18.414244 },
    { lat: 48.743672, lng: 18.413861 },
    { lat: 48.743449, lng: 18.41364 },
    { lat: 48.743247, lng: 18.41333 },
    { lat: 48.742879, lng: 18.41266 },
    { lat: 48.742595, lng: 18.412087 },
    { lat: 48.742427, lng: 18.411918 },
    { lat: 48.742314, lng: 18.411968 },
    { lat: 48.742321, lng: 18.41201 },
    { lat: 48.741955, lng: 18.412303 },
    { lat: 48.741694, lng: 18.412311 },
    { lat: 48.741333, lng: 18.41235 },
    { lat: 48.740845, lng: 18.411824 },
  ],
  Jalovec: [
    { lat: 48.7754892, lng: 18.7351421 },
    { lat: 48.775439, lng: 18.7351308 },
    { lat: 48.775262, lng: 18.7350852 },
    { lat: 48.7751945, lng: 18.7350469 },
    { lat: 48.7751533, lng: 18.7349914 },
    { lat: 48.7750914, lng: 18.7349742 },
    { lat: 48.7750004, lng: 18.7349155 },
    { lat: 48.774969, lng: 18.7348313 },
    { lat: 48.7748726, lng: 18.7347157 },
    { lat: 48.7747079, lng: 18.7346079 },
    { lat: 48.7746467, lng: 18.7351595 },
    { lat: 48.7745774, lng: 18.7356817 },
    { lat: 48.7745153, lng: 18.7359784 },
    { lat: 48.7744205, lng: 18.7363217 },
    { lat: 48.774337, lng: 18.7365981 },
    { lat: 48.774283, lng: 18.7365778 },
    { lat: 48.7738196, lng: 18.7364296 },
    { lat: 48.7736796, lng: 18.7366618 },
    { lat: 48.773114, lng: 18.7369289 },
    { lat: 48.7727303, lng: 18.7367721 },
    { lat: 48.7724033, lng: 18.7365592 },
    { lat: 48.7717984, lng: 18.7362114 },
    { lat: 48.7717352, lng: 18.7360614 },
    { lat: 48.771656, lng: 18.7359047 },
    { lat: 48.7715783, lng: 18.7358247 },
    { lat: 48.7714325, lng: 18.7355831 },
    { lat: 48.7713891, lng: 18.7355923 },
    { lat: 48.7713376, lng: 18.7355345 },
    { lat: 48.7711844, lng: 18.7355155 },
    { lat: 48.7709834, lng: 18.7354293 },
    { lat: 48.7707319, lng: 18.7353785 },
    { lat: 48.7704999, lng: 18.7351055 },
    { lat: 48.7703546, lng: 18.735088 },
    { lat: 48.7701517, lng: 18.7351354 },
    { lat: 48.7698925, lng: 18.7352035 },
    { lat: 48.7698497, lng: 18.7352874 },
    { lat: 48.7693875, lng: 18.7354633 },
    { lat: 48.7693356, lng: 18.735545 },
    { lat: 48.7690383, lng: 18.7357475 },
    { lat: 48.7687672, lng: 18.7358661 },
    { lat: 48.7685881, lng: 18.7357232 },
    { lat: 48.7684063, lng: 18.7356562 },
    { lat: 48.7678561, lng: 18.7353147 },
    { lat: 48.7676713, lng: 18.7352966 },
    { lat: 48.7674518, lng: 18.7351973 },
    { lat: 48.7672815, lng: 18.7350888 },
    { lat: 48.7669796, lng: 18.7349687 },
    { lat: 48.7669032, lng: 18.7349493 },
    { lat: 48.7664253, lng: 18.7348726 },
    { lat: 48.7662134, lng: 18.7347663 },
    { lat: 48.7655684, lng: 18.7343402 },
    { lat: 48.7653374, lng: 18.7341493 },
    { lat: 48.7651079, lng: 18.7339686 },
    { lat: 48.7648314, lng: 18.733839 },
    { lat: 48.7646885, lng: 18.7337608 },
    { lat: 48.7643763, lng: 18.7336749 },
    { lat: 48.7638908, lng: 18.7334314 },
    { lat: 48.7634762, lng: 18.7330969 },
    { lat: 48.7629197, lng: 18.7325515 },
    { lat: 48.7627634, lng: 18.7323265 },
    { lat: 48.7620175, lng: 18.7318462 },
    { lat: 48.7615585, lng: 18.7315056 },
    { lat: 48.7608862, lng: 18.7311614 },
    { lat: 48.7604677, lng: 18.7309868 },
    { lat: 48.7603373, lng: 18.7309114 },
    { lat: 48.7599936, lng: 18.7307675 },
    { lat: 48.7595568, lng: 18.7305018 },
    { lat: 48.7593031, lng: 18.7303333 },
    { lat: 48.759118, lng: 18.7301791 },
    { lat: 48.7585948, lng: 18.7297548 },
    { lat: 48.7581717, lng: 18.729391 },
    { lat: 48.7579624, lng: 18.7291815 },
    { lat: 48.7578061, lng: 18.7289791 },
    { lat: 48.7575964, lng: 18.728661 },
    { lat: 48.7573096, lng: 18.7278218 },
    { lat: 48.7570094, lng: 18.7268587 },
    { lat: 48.7559038, lng: 18.7238858 },
    { lat: 48.755769, lng: 18.7236187 },
    { lat: 48.7555927, lng: 18.7233962 },
    { lat: 48.7550989, lng: 18.72308 },
    { lat: 48.7545248, lng: 18.7231248 },
    { lat: 48.7544804, lng: 18.7232399 },
    { lat: 48.7544235, lng: 18.7232782 },
    { lat: 48.7543758, lng: 18.7234398 },
    { lat: 48.7541806, lng: 18.7239259 },
    { lat: 48.7542428, lng: 18.7239945 },
    { lat: 48.7542749, lng: 18.7241136 },
    { lat: 48.7543184, lng: 18.7241673 },
    { lat: 48.7543849, lng: 18.7241249 },
    { lat: 48.7544428, lng: 18.7240228 },
    { lat: 48.7545294, lng: 18.7240188 },
    { lat: 48.7545944, lng: 18.7239881 },
    { lat: 48.7546795, lng: 18.7240432 },
    { lat: 48.7546852, lng: 18.7240879 },
    { lat: 48.7546658, lng: 18.7242078 },
    { lat: 48.754708, lng: 18.7243668 },
    { lat: 48.7548026, lng: 18.7243453 },
    { lat: 48.7549132, lng: 18.7243905 },
    { lat: 48.7549908, lng: 18.7243829 },
    { lat: 48.7550685, lng: 18.7244271 },
    { lat: 48.7550949, lng: 18.7245419 },
    { lat: 48.7551773, lng: 18.7246931 },
    { lat: 48.7551616, lng: 18.7247583 },
    { lat: 48.7551961, lng: 18.7248788 },
    { lat: 48.7552134, lng: 18.7249053 },
    { lat: 48.7552904, lng: 18.7249328 },
    { lat: 48.7553563, lng: 18.7249006 },
    { lat: 48.7554095, lng: 18.7249002 },
    { lat: 48.7554324, lng: 18.7248878 },
    { lat: 48.7554231, lng: 18.7247745 },
    { lat: 48.7554997, lng: 18.7246888 },
    { lat: 48.7556058, lng: 18.7248404 },
    { lat: 48.7556724, lng: 18.7249921 },
    { lat: 48.7557362, lng: 18.7249836 },
    { lat: 48.7557709, lng: 18.7249105 },
    { lat: 48.7558655, lng: 18.7249083 },
    { lat: 48.7559004, lng: 18.724928 },
    { lat: 48.755958, lng: 18.7251466 },
    { lat: 48.7559633, lng: 18.7253718 },
    { lat: 48.7559548, lng: 18.7255105 },
    { lat: 48.7560378, lng: 18.7255433 },
    { lat: 48.7561171, lng: 18.7256796 },
    { lat: 48.7561942, lng: 18.7257517 },
    { lat: 48.7562061, lng: 18.725954 },
    { lat: 48.7561579, lng: 18.7259827 },
    { lat: 48.7560887, lng: 18.7261026 },
    { lat: 48.7561461, lng: 18.726205 },
    { lat: 48.756146, lng: 18.7263741 },
    { lat: 48.7561282, lng: 18.7264209 },
    { lat: 48.7562322, lng: 18.7265549 },
    { lat: 48.7563, lng: 18.7264713 },
    { lat: 48.7563229, lng: 18.7264564 },
    { lat: 48.7563355, lng: 18.7265027 },
    { lat: 48.7563385, lng: 18.7266049 },
    { lat: 48.7563101, lng: 18.7266838 },
    { lat: 48.7563936, lng: 18.7270468 },
    { lat: 48.7563878, lng: 18.7271079 },
    { lat: 48.7563608, lng: 18.7271326 },
    { lat: 48.7563692, lng: 18.7272078 },
    { lat: 48.7565173, lng: 18.7272774 },
    { lat: 48.7565236, lng: 18.7273292 },
    { lat: 48.7564735, lng: 18.7275583 },
    { lat: 48.7564569, lng: 18.7278419 },
    { lat: 48.7564913, lng: 18.7280247 },
    { lat: 48.756529, lng: 18.7281595 },
    { lat: 48.7565998, lng: 18.7283472 },
    { lat: 48.7565988, lng: 18.7283895 },
    { lat: 48.7565483, lng: 18.7284814 },
    { lat: 48.756518, lng: 18.7286763 },
    { lat: 48.7566158, lng: 18.7287574 },
    { lat: 48.7566579, lng: 18.7288688 },
    { lat: 48.7567106, lng: 18.7289278 },
    { lat: 48.7567127, lng: 18.7289662 },
    { lat: 48.7566627, lng: 18.7290669 },
    { lat: 48.7567227, lng: 18.7294335 },
    { lat: 48.7568101, lng: 18.729508 },
    { lat: 48.7568723, lng: 18.7297457 },
    { lat: 48.7569529, lng: 18.7299476 },
    { lat: 48.7569569, lng: 18.7302169 },
    { lat: 48.7569374, lng: 18.7302532 },
    { lat: 48.7569098, lng: 18.730263 },
    { lat: 48.7569022, lng: 18.7304201 },
    { lat: 48.7567847, lng: 18.7307135 },
    { lat: 48.7568741, lng: 18.7310426 },
    { lat: 48.7568116, lng: 18.7312006 },
    { lat: 48.7568471, lng: 18.7314872 },
    { lat: 48.7570577, lng: 18.7317609 },
    { lat: 48.7571191, lng: 18.7318768 },
    { lat: 48.7570721, lng: 18.7319501 },
    { lat: 48.7570444, lng: 18.7320649 },
    { lat: 48.7571113, lng: 18.7321362 },
    { lat: 48.757244, lng: 18.7321934 },
    { lat: 48.757492, lng: 18.7323836 },
    { lat: 48.7575568, lng: 18.7323712 },
    { lat: 48.7576738, lng: 18.7325815 },
    { lat: 48.7578122, lng: 18.7326816 },
    { lat: 48.7580826, lng: 18.7327641 },
    { lat: 48.7582355, lng: 18.7328811 },
    { lat: 48.7584048, lng: 18.7328649 },
    { lat: 48.7585439, lng: 18.7328259 },
    { lat: 48.7585722, lng: 18.7328304 },
    { lat: 48.7586337, lng: 18.7329245 },
    { lat: 48.7586715, lng: 18.7328538 },
    { lat: 48.7587085, lng: 18.7329094 },
    { lat: 48.7587057, lng: 18.7332242 },
    { lat: 48.7586692, lng: 18.7336429 },
    { lat: 48.7586049, lng: 18.734496 },
    { lat: 48.760216, lng: 18.7353626 },
    { lat: 48.7612129, lng: 18.7361759 },
    { lat: 48.7619181, lng: 18.7367982 },
    { lat: 48.7622128, lng: 18.7381509 },
    { lat: 48.7624643, lng: 18.7392637 },
    { lat: 48.7626279, lng: 18.7395478 },
    { lat: 48.7629703, lng: 18.7401788 },
    { lat: 48.7631351, lng: 18.7405654 },
    { lat: 48.7632934, lng: 18.7408737 },
    { lat: 48.7635239, lng: 18.7420359 },
    { lat: 48.7635893, lng: 18.7421874 },
    { lat: 48.7636856, lng: 18.7422802 },
    { lat: 48.7637013, lng: 18.7423627 },
    { lat: 48.7637002, lng: 18.7424489 },
    { lat: 48.7636847, lng: 18.7425623 },
    { lat: 48.7636263, lng: 18.7427821 },
    { lat: 48.7638277, lng: 18.7428782 },
    { lat: 48.7640903, lng: 18.7430492 },
    { lat: 48.7645953, lng: 18.743408 },
    { lat: 48.7647681, lng: 18.7434762 },
    { lat: 48.7655808, lng: 18.7436198 },
    { lat: 48.7656523, lng: 18.7436484 },
    { lat: 48.7659937, lng: 18.7438356 },
    { lat: 48.7664626, lng: 18.7441501 },
    { lat: 48.7666664, lng: 18.7442335 },
    { lat: 48.7667843, lng: 18.7443269 },
    { lat: 48.7668924, lng: 18.744454 },
    { lat: 48.7671489, lng: 18.7446587 },
    { lat: 48.7673614, lng: 18.7446448 },
    { lat: 48.7674948, lng: 18.7447153 },
    { lat: 48.7675497, lng: 18.7447076 },
    { lat: 48.7677016, lng: 18.7446571 },
    { lat: 48.7677245, lng: 18.7446876 },
    { lat: 48.7678724, lng: 18.7446708 },
    { lat: 48.7679155, lng: 18.7446949 },
    { lat: 48.7680272, lng: 18.7446518 },
    { lat: 48.7681855, lng: 18.7446834 },
    { lat: 48.7682612, lng: 18.7445996 },
    { lat: 48.7683708, lng: 18.74458 },
    { lat: 48.7684288, lng: 18.7446312 },
    { lat: 48.7685301, lng: 18.7446072 },
    { lat: 48.7686633, lng: 18.744525 },
    { lat: 48.7688026, lng: 18.7445586 },
    { lat: 48.7690519, lng: 18.7444295 },
    { lat: 48.7692283, lng: 18.7450403 },
    { lat: 48.7696741, lng: 18.7459672 },
    { lat: 48.7699023, lng: 18.7460995 },
    { lat: 48.7698892, lng: 18.7463452 },
    { lat: 48.7699681, lng: 18.7464741 },
    { lat: 48.7701122, lng: 18.7465532 },
    { lat: 48.7703543, lng: 18.746376 },
    { lat: 48.7704832, lng: 18.7463205 },
    { lat: 48.7705861, lng: 18.7463456 },
    { lat: 48.770622, lng: 18.7463852 },
    { lat: 48.7706824, lng: 18.7465045 },
    { lat: 48.770826, lng: 18.7469159 },
    { lat: 48.7708607, lng: 18.747104 },
    { lat: 48.7711817, lng: 18.7470961 },
    { lat: 48.7712717, lng: 18.7471363 },
    { lat: 48.7717382, lng: 18.7471534 },
    { lat: 48.7719519, lng: 18.7472092 },
    { lat: 48.7723033, lng: 18.7471146 },
    { lat: 48.7723898, lng: 18.7470659 },
    { lat: 48.7724762, lng: 18.7470544 },
    { lat: 48.7724613, lng: 18.7463233 },
    { lat: 48.7724939, lng: 18.7460005 },
    { lat: 48.7725856, lng: 18.7457088 },
    { lat: 48.7728087, lng: 18.7457058 },
    { lat: 48.7729905, lng: 18.7457118 },
    { lat: 48.77347, lng: 18.7457154 },
    { lat: 48.7737561, lng: 18.7460052 },
    { lat: 48.773858, lng: 18.7461016 },
    { lat: 48.7739059, lng: 18.7461316 },
    { lat: 48.7740313, lng: 18.7463274 },
    { lat: 48.7741523, lng: 18.7466139 },
    { lat: 48.7741647, lng: 18.7466571 },
    { lat: 48.7741716, lng: 18.7467421 },
    { lat: 48.774165, lng: 18.746789 },
    { lat: 48.7741738, lng: 18.7468076 },
    { lat: 48.7741341, lng: 18.746974 },
    { lat: 48.7741309, lng: 18.7472357 },
    { lat: 48.7742331, lng: 18.7475924 },
    { lat: 48.7743298, lng: 18.7476916 },
    { lat: 48.7743186, lng: 18.7477805 },
    { lat: 48.7745357, lng: 18.7479621 },
    { lat: 48.7745626, lng: 18.7480879 },
    { lat: 48.7745573, lng: 18.7482423 },
    { lat: 48.7746714, lng: 18.7483529 },
    { lat: 48.7748018, lng: 18.7486454 },
    { lat: 48.774931, lng: 18.748657 },
    { lat: 48.7750461, lng: 18.7486793 },
    { lat: 48.7750766, lng: 18.7487696 },
    { lat: 48.7750166, lng: 18.7488501 },
    { lat: 48.7750341, lng: 18.7490983 },
    { lat: 48.7750117, lng: 18.7492072 },
    { lat: 48.7750463, lng: 18.7493471 },
    { lat: 48.7751483, lng: 18.7494457 },
    { lat: 48.77524, lng: 18.7496016 },
    { lat: 48.7753825, lng: 18.7494818 },
    { lat: 48.7754142, lng: 18.7496113 },
    { lat: 48.7754189, lng: 18.7497642 },
    { lat: 48.7753389, lng: 18.7500216 },
    { lat: 48.775376, lng: 18.750193 },
    { lat: 48.7754823, lng: 18.7503268 },
    { lat: 48.775542, lng: 18.7505617 },
    { lat: 48.7756456, lng: 18.7508374 },
    { lat: 48.7757605, lng: 18.7509193 },
    { lat: 48.775988, lng: 18.7509777 },
    { lat: 48.7760176, lng: 18.7511133 },
    { lat: 48.7760232, lng: 18.7512417 },
    { lat: 48.7760805, lng: 18.751342 },
    { lat: 48.7761738, lng: 18.7513994 },
    { lat: 48.7763015, lng: 18.7517073 },
    { lat: 48.7763623, lng: 18.7519205 },
    { lat: 48.7763975, lng: 18.75231 },
    { lat: 48.7765893, lng: 18.7526312 },
    { lat: 48.7766099, lng: 18.7527907 },
    { lat: 48.7766946, lng: 18.7528137 },
    { lat: 48.7766944, lng: 18.7529146 },
    { lat: 48.7768532, lng: 18.7531922 },
    { lat: 48.777011, lng: 18.7533806 },
    { lat: 48.777114, lng: 18.7533028 },
    { lat: 48.7771901, lng: 18.7533347 },
    { lat: 48.7772175, lng: 18.753384 },
    { lat: 48.7771981, lng: 18.7534467 },
    { lat: 48.7772254, lng: 18.7535568 },
    { lat: 48.7773842, lng: 18.7537679 },
    { lat: 48.7774098, lng: 18.7540824 },
    { lat: 48.7774639, lng: 18.7542085 },
    { lat: 48.7776446, lng: 18.7542164 },
    { lat: 48.7779563, lng: 18.753925 },
    { lat: 48.7780233, lng: 18.7539752 },
    { lat: 48.7782146, lng: 18.7538796 },
    { lat: 48.7782626, lng: 18.7540011 },
    { lat: 48.7785253, lng: 18.754022 },
    { lat: 48.7786277, lng: 18.7539972 },
    { lat: 48.7786459, lng: 18.7539335 },
    { lat: 48.7787036, lng: 18.7539172 },
    { lat: 48.778731, lng: 18.753944 },
    { lat: 48.7787728, lng: 18.7541356 },
    { lat: 48.7788774, lng: 18.754197 },
    { lat: 48.7789324, lng: 18.7541074 },
    { lat: 48.7790851, lng: 18.7541792 },
    { lat: 48.7792148, lng: 18.7542018 },
    { lat: 48.7792996, lng: 18.7543361 },
    { lat: 48.7797238, lng: 18.7542732 },
    { lat: 48.7800599, lng: 18.7541036 },
    { lat: 48.7801195, lng: 18.7540135 },
    { lat: 48.7802009, lng: 18.7540166 },
    { lat: 48.7801921, lng: 18.7539125 },
    { lat: 48.7803086, lng: 18.7538359 },
    { lat: 48.7804038, lng: 18.7539303 },
    { lat: 48.7805662, lng: 18.7538453 },
    { lat: 48.7807365, lng: 18.7538441 },
    { lat: 48.780803, lng: 18.7537592 },
    { lat: 48.7812725, lng: 18.7535306 },
    { lat: 48.7814354, lng: 18.7535175 },
    { lat: 48.7815287, lng: 18.7534908 },
    { lat: 48.781657, lng: 18.75344 },
    { lat: 48.7817049, lng: 18.7534982 },
    { lat: 48.7817431, lng: 18.7534296 },
    { lat: 48.7817438, lng: 18.7533798 },
    { lat: 48.7817796, lng: 18.7532865 },
    { lat: 48.7818246, lng: 18.7532399 },
    { lat: 48.7819213, lng: 18.753214 },
    { lat: 48.7821286, lng: 18.7533181 },
    { lat: 48.7822198, lng: 18.753443 },
    { lat: 48.7822061, lng: 18.7534607 },
    { lat: 48.7822187, lng: 18.7534664 },
    { lat: 48.7822333, lng: 18.7537854 },
    { lat: 48.7822879, lng: 18.7538171 },
    { lat: 48.782821, lng: 18.7540228 },
    { lat: 48.7828675, lng: 18.7539457 },
    { lat: 48.78318, lng: 18.7542267 },
    { lat: 48.7832693, lng: 18.7544019 },
    { lat: 48.7835765, lng: 18.7544543 },
    { lat: 48.7839522, lng: 18.7547774 },
    { lat: 48.7840575, lng: 18.754916 },
    { lat: 48.7841403, lng: 18.7549446 },
    { lat: 48.7842398, lng: 18.7550789 },
    { lat: 48.7844057, lng: 18.75508 },
    { lat: 48.7845395, lng: 18.7552016 },
    { lat: 48.7847027, lng: 18.7552813 },
    { lat: 48.7847641, lng: 18.7553959 },
    { lat: 48.7848168, lng: 18.7555233 },
    { lat: 48.7849027, lng: 18.755587 },
    { lat: 48.7849229, lng: 18.7557605 },
    { lat: 48.784977, lng: 18.7559306 },
    { lat: 48.7849963, lng: 18.7561282 },
    { lat: 48.7850466, lng: 18.756203 },
    { lat: 48.785057, lng: 18.7563147 },
    { lat: 48.7851409, lng: 18.7565406 },
    { lat: 48.7851201, lng: 18.756634 },
    { lat: 48.7852886, lng: 18.7569448 },
    { lat: 48.7854059, lng: 18.7571185 },
    { lat: 48.7854865, lng: 18.7572132 },
    { lat: 48.7854784, lng: 18.7573143 },
    { lat: 48.7854847, lng: 18.7574351 },
    { lat: 48.7855177, lng: 18.757591 },
    { lat: 48.7855981, lng: 18.757896 },
    { lat: 48.7856129, lng: 18.758053 },
    { lat: 48.7856607, lng: 18.758103 },
    { lat: 48.7857327, lng: 18.7583125 },
    { lat: 48.7857612, lng: 18.7584679 },
    { lat: 48.7858126, lng: 18.7586108 },
    { lat: 48.7858377, lng: 18.7587229 },
    { lat: 48.7858537, lng: 18.7589656 },
    { lat: 48.7859287, lng: 18.7591926 },
    { lat: 48.7859159, lng: 18.7593748 },
    { lat: 48.7859569, lng: 18.7595125 },
    { lat: 48.7859984, lng: 18.7595737 },
    { lat: 48.7860338, lng: 18.7598612 },
    { lat: 48.7860282, lng: 18.7600958 },
    { lat: 48.7860691, lng: 18.7603566 },
    { lat: 48.7860614, lng: 18.7604483 },
    { lat: 48.7860422, lng: 18.760534 },
    { lat: 48.786033, lng: 18.7607049 },
    { lat: 48.7860148, lng: 18.7607633 },
    { lat: 48.7860226, lng: 18.7608478 },
    { lat: 48.7860193, lng: 18.7610413 },
    { lat: 48.7860464, lng: 18.7611493 },
    { lat: 48.7860387, lng: 18.76124 },
    { lat: 48.7860972, lng: 18.7613652 },
    { lat: 48.7861031, lng: 18.7615673 },
    { lat: 48.7861516, lng: 18.7616487 },
    { lat: 48.7861878, lng: 18.761762 },
    { lat: 48.7862828, lng: 18.7618933 },
    { lat: 48.7863599, lng: 18.7621583 },
    { lat: 48.7864109, lng: 18.7622076 },
    { lat: 48.7864389, lng: 18.7623322 },
    { lat: 48.7864387, lng: 18.7624585 },
    { lat: 48.7864186, lng: 18.7626118 },
    { lat: 48.7864347, lng: 18.762769 },
    { lat: 48.7864609, lng: 18.7632233 },
    { lat: 48.7864239, lng: 18.7632928 },
    { lat: 48.7864061, lng: 18.763727 },
    { lat: 48.7863834, lng: 18.7639153 },
    { lat: 48.7863321, lng: 18.7642086 },
    { lat: 48.7863277, lng: 18.7645292 },
    { lat: 48.7863158, lng: 18.7647356 },
    { lat: 48.7862754, lng: 18.7648255 },
    { lat: 48.7862534, lng: 18.7649634 },
    { lat: 48.7862137, lng: 18.7649992 },
    { lat: 48.7861651, lng: 18.7651277 },
    { lat: 48.7861633, lng: 18.7651791 },
    { lat: 48.7861282, lng: 18.7653475 },
    { lat: 48.7860557, lng: 18.7658575 },
    { lat: 48.7860199, lng: 18.765972 },
    { lat: 48.7860573, lng: 18.7660816 },
    { lat: 48.786084, lng: 18.7660988 },
    { lat: 48.7861253, lng: 18.766499 },
    { lat: 48.7860896, lng: 18.7665887 },
    { lat: 48.7861416, lng: 18.7668489 },
    { lat: 48.786179, lng: 18.7669413 },
    { lat: 48.7862081, lng: 18.7671084 },
    { lat: 48.7863173, lng: 18.7673213 },
    { lat: 48.7863986, lng: 18.7677296 },
    { lat: 48.7864747, lng: 18.7685991 },
    { lat: 48.7864199, lng: 18.7690595 },
    { lat: 48.7864303, lng: 18.7695828 },
    { lat: 48.7863944, lng: 18.7700778 },
    { lat: 48.7864571, lng: 18.7709947 },
    { lat: 48.7864262, lng: 18.7712652 },
    { lat: 48.7864377, lng: 18.7715696 },
    { lat: 48.7864754, lng: 18.7717538 },
    { lat: 48.7885276, lng: 18.7717273 },
    { lat: 48.7896982, lng: 18.7720731 },
    { lat: 48.789607, lng: 18.772485 },
    { lat: 48.789873, lng: 18.7730424 },
    { lat: 48.7898129, lng: 18.7741934 },
    { lat: 48.7898961, lng: 18.7747084 },
    { lat: 48.7902442, lng: 18.775345 },
    { lat: 48.7904063, lng: 18.7750824 },
    { lat: 48.7909134, lng: 18.7754614 },
    { lat: 48.791162, lng: 18.7753622 },
    { lat: 48.7913862, lng: 18.7756826 },
    { lat: 48.7920542, lng: 18.7758431 },
    { lat: 48.7920904, lng: 18.7763806 },
    { lat: 48.792129, lng: 18.776426 },
    { lat: 48.792849, lng: 18.77646 },
    { lat: 48.793149, lng: 18.776513 },
    { lat: 48.79329, lng: 18.776399 },
    { lat: 48.793597, lng: 18.776314 },
    { lat: 48.793997, lng: 18.776267 },
    { lat: 48.79435, lng: 18.776275 },
    { lat: 48.795168, lng: 18.776221 },
    { lat: 48.795286, lng: 18.776401 },
    { lat: 48.796239, lng: 18.776991 },
    { lat: 48.796277, lng: 18.776942 },
    { lat: 48.796321, lng: 18.776358 },
    { lat: 48.797282, lng: 18.776827 },
    { lat: 48.797418, lng: 18.776986 },
    { lat: 48.797851, lng: 18.777502 },
    { lat: 48.797965, lng: 18.77772 },
    { lat: 48.798542, lng: 18.777733 },
    { lat: 48.799149, lng: 18.777838 },
    { lat: 48.799879, lng: 18.778152 },
    { lat: 48.800386, lng: 18.778477 },
    { lat: 48.801009, lng: 18.778906 },
    { lat: 48.801432, lng: 18.779086 },
    { lat: 48.801859, lng: 18.779146 },
    { lat: 48.801992, lng: 18.779104 },
    { lat: 48.802485, lng: 18.779852 },
    { lat: 48.803407, lng: 18.780201 },
    { lat: 48.803592, lng: 18.780181 },
    { lat: 48.803714, lng: 18.780295 },
    { lat: 48.803816, lng: 18.78034 },
    { lat: 48.804147, lng: 18.780318 },
    { lat: 48.804177, lng: 18.780316 },
    { lat: 48.804205, lng: 18.780088 },
    { lat: 48.804139, lng: 18.779578 },
    { lat: 48.804273, lng: 18.778861 },
    { lat: 48.804691, lng: 18.778271 },
    { lat: 48.8048583, lng: 18.7779647 },
    { lat: 48.804951, lng: 18.777795 },
    { lat: 48.804977, lng: 18.777648 },
    { lat: 48.804852, lng: 18.777247 },
    { lat: 48.804717, lng: 18.777122 },
    { lat: 48.804439, lng: 18.776267 },
    { lat: 48.804445, lng: 18.776106 },
    { lat: 48.805351, lng: 18.774081 },
    { lat: 48.805617, lng: 18.77333 },
    { lat: 48.805603, lng: 18.773033 },
    { lat: 48.805656, lng: 18.772979 },
    { lat: 48.805708, lng: 18.772957 },
    { lat: 48.805831, lng: 18.772684 },
    { lat: 48.805883, lng: 18.77249 },
    { lat: 48.805994, lng: 18.772341 },
    { lat: 48.805874, lng: 18.7721 },
    { lat: 48.805849, lng: 18.771939 },
    { lat: 48.805981, lng: 18.771501 },
    { lat: 48.805964, lng: 18.771267 },
    { lat: 48.806013, lng: 18.770789 },
    { lat: 48.806265, lng: 18.770075 },
    { lat: 48.806357, lng: 18.769958 },
    { lat: 48.806451, lng: 18.769881 },
    { lat: 48.806639, lng: 18.769677 },
    { lat: 48.8065352, lng: 18.769482 },
    { lat: 48.806525, lng: 18.7693959 },
    { lat: 48.806479, lng: 18.7693342 },
    { lat: 48.80636, lng: 18.7690426 },
    { lat: 48.8063142, lng: 18.7688811 },
    { lat: 48.8062685, lng: 18.7687635 },
    { lat: 48.8062675, lng: 18.7685057 },
    { lat: 48.8063385, lng: 18.7680961 },
    { lat: 48.8064446, lng: 18.7676905 },
    { lat: 48.8064658, lng: 18.7674694 },
    { lat: 48.8065466, lng: 18.7672887 },
    { lat: 48.806582, lng: 18.7669327 },
    { lat: 48.806559, lng: 18.7668407 },
    { lat: 48.8065931, lng: 18.76676 },
    { lat: 48.8065924, lng: 18.7666159 },
    { lat: 48.8065596, lng: 18.7664868 },
    { lat: 48.8065948, lng: 18.7663391 },
    { lat: 48.8065253, lng: 18.7660051 },
    { lat: 48.8066164, lng: 18.7653981 },
    { lat: 48.8060839, lng: 18.7647266 },
    { lat: 48.8054061, lng: 18.7643599 },
    { lat: 48.8051951, lng: 18.7635417 },
    { lat: 48.8049201, lng: 18.7625334 },
    { lat: 48.8045008, lng: 18.7623252 },
    { lat: 48.8033277, lng: 18.7621672 },
    { lat: 48.8026328, lng: 18.7619926 },
    { lat: 48.8022933, lng: 18.7618817 },
    { lat: 48.8019281, lng: 18.7611777 },
    { lat: 48.8013747, lng: 18.7606022 },
    { lat: 48.801075, lng: 18.7605248 },
    { lat: 48.8000164, lng: 18.7597565 },
    { lat: 48.799302, lng: 18.7594458 },
    { lat: 48.7992066, lng: 18.7591311 },
    { lat: 48.7992977, lng: 18.7585041 },
    { lat: 48.7990617, lng: 18.7581323 },
    { lat: 48.7989106, lng: 18.757425 },
    { lat: 48.7989183, lng: 18.7571222 },
    { lat: 48.7987587, lng: 18.7567687 },
    { lat: 48.7987464, lng: 18.7566199 },
    { lat: 48.7986806, lng: 18.7564397 },
    { lat: 48.7984961, lng: 18.7558059 },
    { lat: 48.7982508, lng: 18.7555593 },
    { lat: 48.7977604, lng: 18.7549588 },
    { lat: 48.797616, lng: 18.7545963 },
    { lat: 48.7973123, lng: 18.7541131 },
    { lat: 48.7972318, lng: 18.7538277 },
    { lat: 48.7970947, lng: 18.7536246 },
    { lat: 48.7969828, lng: 18.7532983 },
    { lat: 48.7968369, lng: 18.7530319 },
    { lat: 48.7967806, lng: 18.7529045 },
    { lat: 48.7965966, lng: 18.7526863 },
    { lat: 48.7964252, lng: 18.7524069 },
    { lat: 48.7961693, lng: 18.7520467 },
    { lat: 48.7959744, lng: 18.7518152 },
    { lat: 48.7957222, lng: 18.7517369 },
    { lat: 48.7954746, lng: 18.7515943 },
    { lat: 48.7953341, lng: 18.7515604 },
    { lat: 48.7951857, lng: 18.7515933 },
    { lat: 48.7950066, lng: 18.7517011 },
    { lat: 48.7942752, lng: 18.751692 },
    { lat: 48.794206, lng: 18.7516603 },
    { lat: 48.793938, lng: 18.7515845 },
    { lat: 48.7936055, lng: 18.7513717 },
    { lat: 48.7934175, lng: 18.7511004 },
    { lat: 48.7931812, lng: 18.7509157 },
    { lat: 48.7929822, lng: 18.7507372 },
    { lat: 48.7929096, lng: 18.7506415 },
    { lat: 48.7928399, lng: 18.7506006 },
    { lat: 48.7925839, lng: 18.7502799 },
    { lat: 48.7924533, lng: 18.7501786 },
    { lat: 48.792092, lng: 18.750062 },
    { lat: 48.791807, lng: 18.749851 },
    { lat: 48.7916389, lng: 18.7498501 },
    { lat: 48.7915614, lng: 18.749774 },
    { lat: 48.7914619, lng: 18.7497459 },
    { lat: 48.7913988, lng: 18.7496394 },
    { lat: 48.7912992, lng: 18.7496096 },
    { lat: 48.7911501, lng: 18.7495879 },
    { lat: 48.7909506, lng: 18.7494606 },
    { lat: 48.7908805, lng: 18.7493496 },
    { lat: 48.7907486, lng: 18.7493498 },
    { lat: 48.7903493, lng: 18.7491751 },
    { lat: 48.7898175, lng: 18.7487789 },
    { lat: 48.7896267, lng: 18.7486689 },
    { lat: 48.7892859, lng: 18.7485333 },
    { lat: 48.7891159, lng: 18.748474 },
    { lat: 48.7889053, lng: 18.7484815 },
    { lat: 48.788735, lng: 18.7484166 },
    { lat: 48.7884586, lng: 18.7484134 },
    { lat: 48.7882133, lng: 18.748296 },
    { lat: 48.7881315, lng: 18.7480716 },
    { lat: 48.7880755, lng: 18.7478642 },
    { lat: 48.7878823, lng: 18.7476421 },
    { lat: 48.7876018, lng: 18.7473902 },
    { lat: 48.78751, lng: 18.7474013 },
    { lat: 48.7872269, lng: 18.7471872 },
    { lat: 48.7867384, lng: 18.7469264 },
    { lat: 48.7864877, lng: 18.7467685 },
    { lat: 48.7862912, lng: 18.7465744 },
    { lat: 48.7858604, lng: 18.7463178 },
    { lat: 48.7855422, lng: 18.7460336 },
    { lat: 48.7853462, lng: 18.7459556 },
    { lat: 48.785138, lng: 18.7457669 },
    { lat: 48.7847351, lng: 18.745632 },
    { lat: 48.7845178, lng: 18.7453207 },
    { lat: 48.784214, lng: 18.7453759 },
    { lat: 48.7840685, lng: 18.7453323 },
    { lat: 48.7839952, lng: 18.745417 },
    { lat: 48.7839383, lng: 18.7453459 },
    { lat: 48.7838303, lng: 18.74535 },
    { lat: 48.7837251, lng: 18.7451917 },
    { lat: 48.7836729, lng: 18.7448025 },
    { lat: 48.7835546, lng: 18.7446508 },
    { lat: 48.7834125, lng: 18.7445872 },
    { lat: 48.7833908, lng: 18.7443509 },
    { lat: 48.7832933, lng: 18.7441467 },
    { lat: 48.783296, lng: 18.7440044 },
    { lat: 48.7831757, lng: 18.7438376 },
    { lat: 48.7832245, lng: 18.7436744 },
    { lat: 48.7832125, lng: 18.7433608 },
    { lat: 48.7831747, lng: 18.7431769 },
    { lat: 48.7830759, lng: 18.7429084 },
    { lat: 48.7829983, lng: 18.7428252 },
    { lat: 48.7830029, lng: 18.742658 },
    { lat: 48.7828322, lng: 18.7425835 },
    { lat: 48.782718, lng: 18.7421974 },
    { lat: 48.7825126, lng: 18.7420227 },
    { lat: 48.7822278, lng: 18.7421593 },
    { lat: 48.7819887, lng: 18.7419236 },
    { lat: 48.7819001, lng: 18.7417401 },
    { lat: 48.7818302, lng: 18.7414846 },
    { lat: 48.7817063, lng: 18.7414202 },
    { lat: 48.7815896, lng: 18.7411124 },
    { lat: 48.7814882, lng: 18.7410263 },
    { lat: 48.781421, lng: 18.740738 },
    { lat: 48.7811405, lng: 18.7404032 },
    { lat: 48.7810873, lng: 18.7401196 },
    { lat: 48.7809704, lng: 18.7400003 },
    { lat: 48.7807567, lng: 18.7396889 },
    { lat: 48.7806716, lng: 18.7394425 },
    { lat: 48.7803531, lng: 18.7392646 },
    { lat: 48.7803278, lng: 18.7389995 },
    { lat: 48.7800944, lng: 18.7390863 },
    { lat: 48.7799573, lng: 18.7387946 },
    { lat: 48.7799242, lng: 18.7388072 },
    { lat: 48.7797032, lng: 18.7387663 },
    { lat: 48.7796865, lng: 18.7386649 },
    { lat: 48.7795021, lng: 18.7385467 },
    { lat: 48.7794013, lng: 18.7383865 },
    { lat: 48.7792732, lng: 18.7384184 },
    { lat: 48.7791913, lng: 18.7381462 },
    { lat: 48.7790698, lng: 18.7379395 },
    { lat: 48.7789491, lng: 18.7379187 },
    { lat: 48.7788583, lng: 18.7377781 },
    { lat: 48.77875, lng: 18.7377328 },
    { lat: 48.7786774, lng: 18.7376419 },
    { lat: 48.7786437, lng: 18.7374732 },
    { lat: 48.7785702, lng: 18.7374245 },
    { lat: 48.7785004, lng: 18.7374279 },
    { lat: 48.7784006, lng: 18.7373935 },
    { lat: 48.7783981, lng: 18.7373466 },
    { lat: 48.7783267, lng: 18.7373198 },
    { lat: 48.7782534, lng: 18.7372161 },
    { lat: 48.7782074, lng: 18.7372177 },
    { lat: 48.7780898, lng: 18.7370395 },
    { lat: 48.7779448, lng: 18.7368804 },
    { lat: 48.7778131, lng: 18.7366675 },
    { lat: 48.777762, lng: 18.7366407 },
    { lat: 48.7777089, lng: 18.7366593 },
    { lat: 48.7776693, lng: 18.7364874 },
    { lat: 48.7776057, lng: 18.7364375 },
    { lat: 48.7775366, lng: 18.7364523 },
    { lat: 48.7774482, lng: 18.7363983 },
    { lat: 48.7773763, lng: 18.7364063 },
    { lat: 48.7772489, lng: 18.7361292 },
    { lat: 48.7771924, lng: 18.7360831 },
    { lat: 48.777103, lng: 18.7360363 },
    { lat: 48.7769167, lng: 18.7357091 },
    { lat: 48.776811, lng: 18.7356929 },
    { lat: 48.7766336, lng: 18.7356021 },
    { lat: 48.7765824, lng: 18.7356383 },
    { lat: 48.7765495, lng: 18.7358475 },
    { lat: 48.7764849, lng: 18.7359275 },
    { lat: 48.7764349, lng: 18.7358982 },
    { lat: 48.7763752, lng: 18.7355997 },
    { lat: 48.776342, lng: 18.7355933 },
    { lat: 48.7762878, lng: 18.7357379 },
    { lat: 48.7761918, lng: 18.7358453 },
    { lat: 48.7761165, lng: 18.7357843 },
    { lat: 48.776075, lng: 18.7356602 },
    { lat: 48.776091, lng: 18.7354488 },
    { lat: 48.7760674, lng: 18.7352782 },
    { lat: 48.7760359, lng: 18.7352409 },
    { lat: 48.7758848, lng: 18.7352184 },
    { lat: 48.7758334, lng: 18.735161 },
    { lat: 48.7757629, lng: 18.7351967 },
    { lat: 48.7757303, lng: 18.7352642 },
    { lat: 48.7756732, lng: 18.7352259 },
    { lat: 48.7756149, lng: 18.7352521 },
    { lat: 48.7755382, lng: 18.7351948 },
    { lat: 48.7754892, lng: 18.7351421 },
  ],
  KamenecpodVtáčnikom: [
    { lat: 48.6244119, lng: 18.63458 },
    { lat: 48.624685, lng: 18.634823 },
    { lat: 48.62476, lng: 18.63599 },
    { lat: 48.62515, lng: 18.636685 },
    { lat: 48.625866, lng: 18.63718 },
    { lat: 48.626046, lng: 18.637303 },
    { lat: 48.630848, lng: 18.640622 },
    { lat: 48.633123, lng: 18.643154 },
    { lat: 48.633151, lng: 18.643863 },
    { lat: 48.6341542, lng: 18.641908 },
    { lat: 48.6356395, lng: 18.6396206 },
    { lat: 48.6374392, lng: 18.6371174 },
    { lat: 48.6395035, lng: 18.6342275 },
    { lat: 48.6417953, lng: 18.6309581 },
    { lat: 48.6418799, lng: 18.6308301 },
    { lat: 48.6427878, lng: 18.6295108 },
    { lat: 48.6454194, lng: 18.6257239 },
    { lat: 48.6457792, lng: 18.6252607 },
    { lat: 48.6466035, lng: 18.6241884 },
    { lat: 48.6480244, lng: 18.6211687 },
    { lat: 48.6481075, lng: 18.6210576 },
    { lat: 48.6483205, lng: 18.6208485 },
    { lat: 48.648486, lng: 18.6206123 },
    { lat: 48.6486638, lng: 18.6204564 },
    { lat: 48.6488892, lng: 18.6203092 },
    { lat: 48.6493415, lng: 18.6199748 },
    { lat: 48.6496206, lng: 18.619816 },
    { lat: 48.6500541, lng: 18.619593 },
    { lat: 48.6502238, lng: 18.6195589 },
    { lat: 48.6506498, lng: 18.6194071 },
    { lat: 48.6510293, lng: 18.6191861 },
    { lat: 48.651554, lng: 18.6189187 },
    { lat: 48.651863, lng: 18.618793 },
    { lat: 48.652204, lng: 18.618424 },
    { lat: 48.6524312, lng: 18.6179908 },
    { lat: 48.6526549, lng: 18.6176038 },
    { lat: 48.6528727, lng: 18.6173508 },
    { lat: 48.6530663, lng: 18.6171152 },
    { lat: 48.6531912, lng: 18.6169026 },
    { lat: 48.6533689, lng: 18.6166613 },
    { lat: 48.6535135, lng: 18.6165191 },
    { lat: 48.6536274, lng: 18.6163524 },
    { lat: 48.6540453, lng: 18.6159632 },
    { lat: 48.6542176, lng: 18.6156866 },
    { lat: 48.6544763, lng: 18.6153616 },
    { lat: 48.6546633, lng: 18.6150422 },
    { lat: 48.6551839, lng: 18.6144927 },
    { lat: 48.6556705, lng: 18.6140625 },
    { lat: 48.6559951, lng: 18.6139499 },
    { lat: 48.6563848, lng: 18.613857 },
    { lat: 48.6568304, lng: 18.6138144 },
    { lat: 48.6574635, lng: 18.6137818 },
    { lat: 48.6578939, lng: 18.6137511 },
    { lat: 48.6582337, lng: 18.613903 },
    { lat: 48.6583247, lng: 18.6139175 },
    { lat: 48.6584455, lng: 18.6139223 },
    { lat: 48.6588255, lng: 18.6138766 },
    { lat: 48.6591992, lng: 18.6137583 },
    { lat: 48.6594644, lng: 18.6134053 },
    { lat: 48.6596649, lng: 18.6130852 },
    { lat: 48.6599617, lng: 18.6124197 },
    { lat: 48.6601064, lng: 18.6121757 },
    { lat: 48.6602917, lng: 18.6117409 },
    { lat: 48.6604702, lng: 18.6114353 },
    { lat: 48.660477, lng: 18.6113074 },
    { lat: 48.6605232, lng: 18.6110991 },
    { lat: 48.6606298, lng: 18.6107363 },
    { lat: 48.6607501, lng: 18.6105164 },
    { lat: 48.6609206, lng: 18.610169 },
    { lat: 48.6609933, lng: 18.6097597 },
    { lat: 48.6610584, lng: 18.609503 },
    { lat: 48.6611237, lng: 18.6093109 },
    { lat: 48.6612375, lng: 18.6085787 },
    { lat: 48.6613129, lng: 18.6083864 },
    { lat: 48.6613769, lng: 18.6080256 },
    { lat: 48.6613773, lng: 18.6077848 },
    { lat: 48.6616039, lng: 18.6075296 },
    { lat: 48.6617979, lng: 18.6073006 },
    { lat: 48.661927, lng: 18.6075416 },
    { lat: 48.6620632, lng: 18.6076397 },
    { lat: 48.6621023, lng: 18.6073826 },
    { lat: 48.6621184, lng: 18.6072222 },
    { lat: 48.6621162, lng: 18.6070969 },
    { lat: 48.6621514, lng: 18.6069527 },
    { lat: 48.6621904, lng: 18.6067342 },
    { lat: 48.6623333, lng: 18.6063734 },
    { lat: 48.6625023, lng: 18.6061859 },
    { lat: 48.662576, lng: 18.6060848 },
    { lat: 48.6626872, lng: 18.6058899 },
    { lat: 48.6627416, lng: 18.6057497 },
    { lat: 48.6627801, lng: 18.605583 },
    { lat: 48.6627995, lng: 18.6054443 },
    { lat: 48.6627979, lng: 18.60531 },
    { lat: 48.6629635, lng: 18.6049928 },
    { lat: 48.6631344, lng: 18.6046913 },
    { lat: 48.6631812, lng: 18.60445 },
    { lat: 48.6630607, lng: 18.6043678 },
    { lat: 48.663105, lng: 18.604042 },
    { lat: 48.6634635, lng: 18.6035989 },
    { lat: 48.6635005, lng: 18.6035323 },
    { lat: 48.6636281, lng: 18.6032211 },
    { lat: 48.6636755, lng: 18.6031179 },
    { lat: 48.6639976, lng: 18.602649 },
    { lat: 48.6655872, lng: 18.6013003 },
    { lat: 48.6669078, lng: 18.6001688 },
    { lat: 48.668686, lng: 18.5986144 },
    { lat: 48.6699038, lng: 18.5975022 },
    { lat: 48.6700966, lng: 18.5973346 },
    { lat: 48.6715297, lng: 18.5959892 },
    { lat: 48.671541, lng: 18.5957822 },
    { lat: 48.6716356, lng: 18.5957195 },
    { lat: 48.6717252, lng: 18.5956228 },
    { lat: 48.6718422, lng: 18.5952819 },
    { lat: 48.6718159, lng: 18.5951502 },
    { lat: 48.6718324, lng: 18.5949794 },
    { lat: 48.671918, lng: 18.5948489 },
    { lat: 48.6719177, lng: 18.594743 },
    { lat: 48.6719899, lng: 18.5946352 },
    { lat: 48.6721466, lng: 18.5946573 },
    { lat: 48.672345, lng: 18.5945707 },
    { lat: 48.6725477, lng: 18.5945443 },
    { lat: 48.6729232, lng: 18.5944762 },
    { lat: 48.6730438, lng: 18.5945384 },
    { lat: 48.6731929, lng: 18.5945432 },
    { lat: 48.673389, lng: 18.5943969 },
    { lat: 48.6734581, lng: 18.5942318 },
    { lat: 48.6735791, lng: 18.5941968 },
    { lat: 48.6737534, lng: 18.5939812 },
    { lat: 48.6738082, lng: 18.5938056 },
    { lat: 48.6739481, lng: 18.5936995 },
    { lat: 48.6741192, lng: 18.5933201 },
    { lat: 48.6744865, lng: 18.5932052 },
    { lat: 48.6748973, lng: 18.5924818 },
    { lat: 48.6749182, lng: 18.5922818 },
    { lat: 48.675119, lng: 18.5918695 },
    { lat: 48.675268, lng: 18.5917668 },
    { lat: 48.6753967, lng: 18.5917688 },
    { lat: 48.6755114, lng: 18.5915123 },
    { lat: 48.6758806, lng: 18.5904567 },
    { lat: 48.6758203, lng: 18.5903193 },
    { lat: 48.6759004, lng: 18.590258 },
    { lat: 48.6761804, lng: 18.5905357 },
    { lat: 48.6763466, lng: 18.5901451 },
    { lat: 48.6765957, lng: 18.589746 },
    { lat: 48.67671, lng: 18.5895431 },
    { lat: 48.6767886, lng: 18.5894149 },
    { lat: 48.6772289, lng: 18.5888388 },
    { lat: 48.677341, lng: 18.5884512 },
    { lat: 48.6774096, lng: 18.5879902 },
    { lat: 48.6774147, lng: 18.587748 },
    { lat: 48.6773861, lng: 18.5876166 },
    { lat: 48.6774268, lng: 18.587225 },
    { lat: 48.6775669, lng: 18.5865207 },
    { lat: 48.6777554, lng: 18.5861872 },
    { lat: 48.6778179, lng: 18.58601 },
    { lat: 48.6778635, lng: 18.5857267 },
    { lat: 48.6778517, lng: 18.5849458 },
    { lat: 48.6799052, lng: 18.5870406 },
    { lat: 48.6801921, lng: 18.586695 },
    { lat: 48.6804233, lng: 18.5863595 },
    { lat: 48.6805953, lng: 18.586163 },
    { lat: 48.6808068, lng: 18.5857785 },
    { lat: 48.6811077, lng: 18.5861043 },
    { lat: 48.6815164, lng: 18.5852586 },
    { lat: 48.6809353, lng: 18.5841753 },
    { lat: 48.6810467, lng: 18.5836262 },
    { lat: 48.6810232, lng: 18.5833417 },
    { lat: 48.6810744, lng: 18.5831173 },
    { lat: 48.6810849, lng: 18.5828537 },
    { lat: 48.6813071, lng: 18.5822744 },
    { lat: 48.6813534, lng: 18.5820641 },
    { lat: 48.6814434, lng: 18.5818545 },
    { lat: 48.6815023, lng: 18.5816056 },
    { lat: 48.6817635, lng: 18.5816808 },
    { lat: 48.6818843, lng: 18.5816844 },
    { lat: 48.6822115, lng: 18.5814994 },
    { lat: 48.6822625, lng: 18.5810051 },
    { lat: 48.6830348, lng: 18.580271 },
    { lat: 48.683462, lng: 18.5789525 },
    { lat: 48.683892, lng: 18.5782286 },
    { lat: 48.6847374, lng: 18.5766808 },
    { lat: 48.6848173, lng: 18.5765327 },
    { lat: 48.6849293, lng: 18.5762886 },
    { lat: 48.6853686, lng: 18.5752155 },
    { lat: 48.6850725, lng: 18.5747278 },
    { lat: 48.6852506, lng: 18.5744337 },
    { lat: 48.6854147, lng: 18.574422 },
    { lat: 48.685585, lng: 18.5742381 },
    { lat: 48.6856486, lng: 18.5740599 },
    { lat: 48.6857736, lng: 18.5740102 },
    { lat: 48.6860448, lng: 18.5741251 },
    { lat: 48.686358, lng: 18.5739132 },
    { lat: 48.6865112, lng: 18.5736993 },
    { lat: 48.6867635, lng: 18.5734836 },
    { lat: 48.6865789, lng: 18.5729302 },
    { lat: 48.6865011, lng: 18.5725775 },
    { lat: 48.6864545, lng: 18.5721981 },
    { lat: 48.686126, lng: 18.5709234 },
    { lat: 48.6860918, lng: 18.5707948 },
    { lat: 48.6864967, lng: 18.5703773 },
    { lat: 48.6867421, lng: 18.5701206 },
    { lat: 48.6869777, lng: 18.5697619 },
    { lat: 48.6871449, lng: 18.5694985 },
    { lat: 48.6872233, lng: 18.5692417 },
    { lat: 48.6875136, lng: 18.5687677 },
    { lat: 48.6877814, lng: 18.5683201 },
    { lat: 48.6878566, lng: 18.5679409 },
    { lat: 48.6879631, lng: 18.567701 },
    { lat: 48.6881868, lng: 18.5675794 },
    { lat: 48.6884112, lng: 18.5675159 },
    { lat: 48.6884697, lng: 18.5676193 },
    { lat: 48.6890111, lng: 18.5674348 },
    { lat: 48.6890654, lng: 18.5673509 },
    { lat: 48.6890267, lng: 18.566807 },
    { lat: 48.6891865, lng: 18.5667779 },
    { lat: 48.689336, lng: 18.566683 },
    { lat: 48.6893897, lng: 18.5666974 },
    { lat: 48.6894483, lng: 18.5666148 },
    { lat: 48.6896908, lng: 18.5666362 },
    { lat: 48.6900223, lng: 18.5667379 },
    { lat: 48.6900826, lng: 18.5667486 },
    { lat: 48.6901239, lng: 18.5667806 },
    { lat: 48.6902392, lng: 18.5669277 },
    { lat: 48.6903912, lng: 18.5670285 },
    { lat: 48.6906628, lng: 18.5669209 },
    { lat: 48.6907757, lng: 18.5668584 },
    { lat: 48.690857, lng: 18.566714 },
    { lat: 48.6909245, lng: 18.5661123 },
    { lat: 48.690998, lng: 18.5659251 },
    { lat: 48.6909717, lng: 18.5651536 },
    { lat: 48.6913633, lng: 18.5644696 },
    { lat: 48.6915638, lng: 18.5640488 },
    { lat: 48.6916889, lng: 18.5637801 },
    { lat: 48.6919795, lng: 18.5634396 },
    { lat: 48.6920629, lng: 18.5633339 },
    { lat: 48.6924496, lng: 18.5627911 },
    { lat: 48.6921707, lng: 18.5623087 },
    { lat: 48.6883643, lng: 18.5557427 },
    { lat: 48.6883421, lng: 18.5557083 },
    { lat: 48.6883859, lng: 18.5555804 },
    { lat: 48.688432, lng: 18.5553712 },
    { lat: 48.6884972, lng: 18.554768 },
    { lat: 48.6885249, lng: 18.5544472 },
    { lat: 48.6885654, lng: 18.5538021 },
    { lat: 48.6886628, lng: 18.553498 },
    { lat: 48.6886787, lng: 18.5532743 },
    { lat: 48.6884434, lng: 18.5531968 },
    { lat: 48.6873477, lng: 18.552817 },
    { lat: 48.6862484, lng: 18.5524528 },
    { lat: 48.6860983, lng: 18.5524311 },
    { lat: 48.6857953, lng: 18.5523969 },
    { lat: 48.6852654, lng: 18.5520733 },
    { lat: 48.6849256, lng: 18.5516532 },
    { lat: 48.6848315, lng: 18.5514968 },
    { lat: 48.6846813, lng: 18.5514337 },
    { lat: 48.6842343, lng: 18.5503912 },
    { lat: 48.6840262, lng: 18.5499262 },
    { lat: 48.6838493, lng: 18.5495374 },
    { lat: 48.6837836, lng: 18.5494084 },
    { lat: 48.6836642, lng: 18.5492255 },
    { lat: 48.6835054, lng: 18.548793 },
    { lat: 48.6832541, lng: 18.5479718 },
    { lat: 48.6829868, lng: 18.54737 },
    { lat: 48.6828987, lng: 18.5466852 },
    { lat: 48.6827642, lng: 18.5461819 },
    { lat: 48.682634, lng: 18.5459667 },
    { lat: 48.6826183, lng: 18.5457824 },
    { lat: 48.6823246, lng: 18.5451949 },
    { lat: 48.6822994, lng: 18.545127 },
    { lat: 48.6821925, lng: 18.5446588 },
    { lat: 48.6821894, lng: 18.544601 },
    { lat: 48.6821471, lng: 18.5443625 },
    { lat: 48.682134, lng: 18.5443266 },
    { lat: 48.6820482, lng: 18.5442234 },
    { lat: 48.681359, lng: 18.5423205 },
    { lat: 48.6814722, lng: 18.5420602 },
    { lat: 48.6814841, lng: 18.541741 },
    { lat: 48.6808681, lng: 18.5412005 },
    { lat: 48.6807427, lng: 18.5410926 },
    { lat: 48.680396, lng: 18.5408166 },
    { lat: 48.6803031, lng: 18.5409128 },
    { lat: 48.679569, lng: 18.5417669 },
    { lat: 48.6794388, lng: 18.541902 },
    { lat: 48.6789204, lng: 18.5424908 },
    { lat: 48.678583, lng: 18.5428562 },
    { lat: 48.6780984, lng: 18.5421023 },
    { lat: 48.67758, lng: 18.5413259 },
    { lat: 48.6774482, lng: 18.5411199 },
    { lat: 48.6769859, lng: 18.5404236 },
    { lat: 48.6767963, lng: 18.5401285 },
    { lat: 48.6757003, lng: 18.5385485 },
    { lat: 48.675367, lng: 18.5380635 },
    { lat: 48.6746794, lng: 18.5370798 },
    { lat: 48.6740443, lng: 18.5361685 },
    { lat: 48.6736413, lng: 18.535709 },
    { lat: 48.6730093, lng: 18.5350015 },
    { lat: 48.6722714, lng: 18.5341926 },
    { lat: 48.667837, lng: 18.5329506 },
    { lat: 48.6677555, lng: 18.5331106 },
    { lat: 48.6670272, lng: 18.5345806 },
    { lat: 48.6666888, lng: 18.5352226 },
    { lat: 48.6665479, lng: 18.5354303 },
    { lat: 48.6662113, lng: 18.5356892 },
    { lat: 48.6660789, lng: 18.5358274 },
    { lat: 48.6659423, lng: 18.5360528 },
    { lat: 48.6657768, lng: 18.5364495 },
    { lat: 48.6658815, lng: 18.5367148 },
    { lat: 48.6667266, lng: 18.5383724 },
    { lat: 48.6656391, lng: 18.5403534 },
    { lat: 48.6643901, lng: 18.5427247 },
    { lat: 48.6645697, lng: 18.5456237 },
    { lat: 48.6635945, lng: 18.5469382 },
    { lat: 48.6631267, lng: 18.5474184 },
    { lat: 48.6625722, lng: 18.5477343 },
    { lat: 48.6621424, lng: 18.548709 },
    { lat: 48.6610602, lng: 18.5514731 },
    { lat: 48.6607197, lng: 18.5515364 },
    { lat: 48.6595852, lng: 18.5529293 },
    { lat: 48.6587212, lng: 18.5541908 },
    { lat: 48.6580723, lng: 18.5558429 },
    { lat: 48.6573653, lng: 18.5568016 },
    { lat: 48.6563052, lng: 18.5593331 },
    { lat: 48.6545496, lng: 18.5620202 },
    { lat: 48.6538272, lng: 18.5641661 },
    { lat: 48.6538367, lng: 18.5642134 },
    { lat: 48.6536213, lng: 18.5644032 },
    { lat: 48.6534986, lng: 18.5645721 },
    { lat: 48.6533198, lng: 18.5647709 },
    { lat: 48.6532245, lng: 18.5649054 },
    { lat: 48.6532134, lng: 18.5652393 },
    { lat: 48.6531408, lng: 18.5654819 },
    { lat: 48.653069, lng: 18.565697 },
    { lat: 48.6528176, lng: 18.5660713 },
    { lat: 48.6525283, lng: 18.5661029 },
    { lat: 48.6522997, lng: 18.5663592 },
    { lat: 48.6521795, lng: 18.5664511 },
    { lat: 48.6520285, lng: 18.5663739 },
    { lat: 48.6519629, lng: 18.5664936 },
    { lat: 48.6517861, lng: 18.566563 },
    { lat: 48.6514984, lng: 18.5667438 },
    { lat: 48.6514347, lng: 18.5667777 },
    { lat: 48.651223, lng: 18.5667826 },
    { lat: 48.6510198, lng: 18.5671969 },
    { lat: 48.6508937, lng: 18.5673656 },
    { lat: 48.6507362, lng: 18.5675595 },
    { lat: 48.6506945, lng: 18.5676228 },
    { lat: 48.6506242, lng: 18.5677639 },
    { lat: 48.6507021, lng: 18.5680325 },
    { lat: 48.6507169, lng: 18.5684516 },
    { lat: 48.6507194, lng: 18.5687894 },
    { lat: 48.6506949, lng: 18.5690654 },
    { lat: 48.6507115, lng: 18.5693494 },
    { lat: 48.6506636, lng: 18.5697941 },
    { lat: 48.6507224, lng: 18.5706704 },
    { lat: 48.6507254, lng: 18.5707664 },
    { lat: 48.6507022, lng: 18.5711089 },
    { lat: 48.6507177, lng: 18.5715157 },
    { lat: 48.6507054, lng: 18.5719992 },
    { lat: 48.6507289, lng: 18.5725967 },
    { lat: 48.6506562, lng: 18.573003 },
    { lat: 48.6506799, lng: 18.5732302 },
    { lat: 48.6506747, lng: 18.5735308 },
    { lat: 48.6507056, lng: 18.5739126 },
    { lat: 48.6507374, lng: 18.5744113 },
    { lat: 48.6507379, lng: 18.5745893 },
    { lat: 48.6507279, lng: 18.5747589 },
    { lat: 48.6506974, lng: 18.5749453 },
    { lat: 48.6506625, lng: 18.5750466 },
    { lat: 48.6505629, lng: 18.5752723 },
    { lat: 48.6503969, lng: 18.5755572 },
    { lat: 48.6502393, lng: 18.5758759 },
    { lat: 48.6500471, lng: 18.5761801 },
    { lat: 48.6499513, lng: 18.576366 },
    { lat: 48.64988, lng: 18.5765896 },
    { lat: 48.6497493, lng: 18.5771351 },
    { lat: 48.6496914, lng: 18.5774615 },
    { lat: 48.6496559, lng: 18.5774523 },
    { lat: 48.6490534, lng: 18.5775732 },
    { lat: 48.6477633, lng: 18.5778258 },
    { lat: 48.647534, lng: 18.5776765 },
    { lat: 48.6469796, lng: 18.5780393 },
    { lat: 48.6464028, lng: 18.5784263 },
    { lat: 48.6459601, lng: 18.5787264 },
    { lat: 48.6451566, lng: 18.5792612 },
    { lat: 48.6445678, lng: 18.5796568 },
    { lat: 48.643514, lng: 18.5798591 },
    { lat: 48.642672, lng: 18.5802841 },
    { lat: 48.6416059, lng: 18.5817369 },
    { lat: 48.6403772, lng: 18.5821241 },
    { lat: 48.6399518, lng: 18.5824345 },
    { lat: 48.6396078, lng: 18.5825369 },
    { lat: 48.6392946, lng: 18.5828769 },
    { lat: 48.6389178, lng: 18.5833768 },
    { lat: 48.6385893, lng: 18.5840796 },
    { lat: 48.6377771, lng: 18.5851977 },
    { lat: 48.6365448, lng: 18.5869136 },
    { lat: 48.636216, lng: 18.5876107 },
    { lat: 48.6356577, lng: 18.5879892 },
    { lat: 48.6353398, lng: 18.5885116 },
    { lat: 48.6349384, lng: 18.5889687 },
    { lat: 48.6339649, lng: 18.5903654 },
    { lat: 48.6328851, lng: 18.5907129 },
    { lat: 48.6313397, lng: 18.5913346 },
    { lat: 48.6299626, lng: 18.5918963 },
    { lat: 48.6294195, lng: 18.5921162 },
    { lat: 48.6279022, lng: 18.5926945 },
    { lat: 48.6272582, lng: 18.5929439 },
    { lat: 48.6262732, lng: 18.593378 },
    { lat: 48.6247657, lng: 18.5939892 },
    { lat: 48.6241341, lng: 18.5942022 },
    { lat: 48.6234912, lng: 18.5943249 },
    { lat: 48.6232239, lng: 18.5945122 },
    { lat: 48.6238691, lng: 18.5954906 },
    { lat: 48.6240816, lng: 18.595813 },
    { lat: 48.6246692, lng: 18.5965859 },
    { lat: 48.6249453, lng: 18.5972246 },
    { lat: 48.6254096, lng: 18.5982988 },
    { lat: 48.625517, lng: 18.5985582 },
    { lat: 48.6257832, lng: 18.5992012 },
    { lat: 48.6262688, lng: 18.599986 },
    { lat: 48.6266808, lng: 18.6008031 },
    { lat: 48.6267553, lng: 18.6010239 },
    { lat: 48.6270897, lng: 18.6020156 },
    { lat: 48.6272516, lng: 18.6024956 },
    { lat: 48.6273558, lng: 18.6028126 },
    { lat: 48.6274136, lng: 18.6030479 },
    { lat: 48.6276076, lng: 18.6045715 },
    { lat: 48.6277387, lng: 18.6053384 },
    { lat: 48.6278039, lng: 18.60572 },
    { lat: 48.6278953, lng: 18.6060945 },
    { lat: 48.6282775, lng: 18.607661 },
    { lat: 48.6282012, lng: 18.6080857 },
    { lat: 48.6284542, lng: 18.6093657 },
    { lat: 48.6284962, lng: 18.6095217 },
    { lat: 48.6285631, lng: 18.6111707 },
    { lat: 48.6286368, lng: 18.6124317 },
    { lat: 48.6287169, lng: 18.6134551 },
    { lat: 48.628719, lng: 18.6144365 },
    { lat: 48.6287557, lng: 18.6160133 },
    { lat: 48.6287796, lng: 18.6172101 },
    { lat: 48.6285261, lng: 18.6188237 },
    { lat: 48.6279359, lng: 18.6199058 },
    { lat: 48.6275546, lng: 18.6206372 },
    { lat: 48.627133, lng: 18.6222502 },
    { lat: 48.6268301, lng: 18.6250463 },
    { lat: 48.6263523, lng: 18.6257616 },
    { lat: 48.6260311, lng: 18.6265429 },
    { lat: 48.6258898, lng: 18.6286488 },
    { lat: 48.6249426, lng: 18.6301646 },
    { lat: 48.6247812, lng: 18.6307284 },
    { lat: 48.6246014, lng: 18.6318474 },
    { lat: 48.6244757, lng: 18.6324468 },
    { lat: 48.6242896, lng: 18.6329099 },
    { lat: 48.624167, lng: 18.6336925 },
    { lat: 48.6244119, lng: 18.63458 },
  ],
  Kanianka: [
    { lat: 48.8172081, lng: 18.5492929 },
    { lat: 48.8172127, lng: 18.5493768 },
    { lat: 48.8171817, lng: 18.5501778 },
    { lat: 48.8171576, lng: 18.5506686 },
    { lat: 48.8170004, lng: 18.551326 },
    { lat: 48.8167975, lng: 18.5521369 },
    { lat: 48.816575, lng: 18.5530447 },
    { lat: 48.8165634, lng: 18.5533715 },
    { lat: 48.8165677, lng: 18.5533896 },
    { lat: 48.8167745, lng: 18.553833 },
    { lat: 48.8168788, lng: 18.5543218 },
    { lat: 48.8170308, lng: 18.5547925 },
    { lat: 48.817058, lng: 18.5549624 },
    { lat: 48.8171166, lng: 18.5551662 },
    { lat: 48.8171327, lng: 18.5554849 },
    { lat: 48.8171619, lng: 18.5556277 },
    { lat: 48.8172564, lng: 18.5557093 },
    { lat: 48.8172431, lng: 18.5560027 },
    { lat: 48.8171838, lng: 18.5561779 },
    { lat: 48.8172045, lng: 18.5564764 },
    { lat: 48.8171312, lng: 18.5567034 },
    { lat: 48.8170928, lng: 18.557015 },
    { lat: 48.8170791, lng: 18.5572622 },
    { lat: 48.8169891, lng: 18.5574559 },
    { lat: 48.8169971, lng: 18.5576031 },
    { lat: 48.8169639, lng: 18.5576745 },
    { lat: 48.8169601, lng: 18.5579363 },
    { lat: 48.8169795, lng: 18.5580666 },
    { lat: 48.8170167, lng: 18.558169 },
    { lat: 48.8170093, lng: 18.5584065 },
    { lat: 48.8170207, lng: 18.5585783 },
    { lat: 48.8170388, lng: 18.5586799 },
    { lat: 48.8170274, lng: 18.5587629 },
    { lat: 48.8169525, lng: 18.5588141 },
    { lat: 48.8168546, lng: 18.5590711 },
    { lat: 48.8168211, lng: 18.5592417 },
    { lat: 48.8167695, lng: 18.5593494 },
    { lat: 48.8167321, lng: 18.5594922 },
    { lat: 48.816497, lng: 18.5599423 },
    { lat: 48.8164698, lng: 18.5601243 },
    { lat: 48.8164038, lng: 18.5603634 },
    { lat: 48.8162903, lng: 18.5606423 },
    { lat: 48.8162528, lng: 18.5607207 },
    { lat: 48.8161565, lng: 18.5608594 },
    { lat: 48.8160488, lng: 18.5609956 },
    { lat: 48.8159164, lng: 18.5613173 },
    { lat: 48.8158641, lng: 18.5614999 },
    { lat: 48.81579, lng: 18.5616101 },
    { lat: 48.8157086, lng: 18.5618353 },
    { lat: 48.8156606, lng: 18.5620739 },
    { lat: 48.8156674, lng: 18.5623443 },
    { lat: 48.8156278, lng: 18.5625279 },
    { lat: 48.8156098, lng: 18.5628422 },
    { lat: 48.8155827, lng: 18.5630088 },
    { lat: 48.8155765, lng: 18.5633524 },
    { lat: 48.8155445, lng: 18.5637608 },
    { lat: 48.8154083, lng: 18.5645522 },
    { lat: 48.8154143, lng: 18.5647683 },
    { lat: 48.8153735, lng: 18.5650801 },
    { lat: 48.8153382, lng: 18.5652169 },
    { lat: 48.81532, lng: 18.5654433 },
    { lat: 48.8153361, lng: 18.5656803 },
    { lat: 48.8153157, lng: 18.5658221 },
    { lat: 48.8152481, lng: 18.5661154 },
    { lat: 48.8152468, lng: 18.5662562 },
    { lat: 48.8151999, lng: 18.5663683 },
    { lat: 48.8151617, lng: 18.5665402 },
    { lat: 48.8151109, lng: 18.5668271 },
    { lat: 48.815092, lng: 18.5670023 },
    { lat: 48.815076, lng: 18.5673701 },
    { lat: 48.8151065, lng: 18.5674754 },
    { lat: 48.8150917, lng: 18.5676197 },
    { lat: 48.8150526, lng: 18.5676893 },
    { lat: 48.8149985, lng: 18.5677326 },
    { lat: 48.8149694, lng: 18.5678185 },
    { lat: 48.8149456, lng: 18.5681529 },
    { lat: 48.8149183, lng: 18.5682067 },
    { lat: 48.8149185, lng: 18.5683366 },
    { lat: 48.814869, lng: 18.5683832 },
    { lat: 48.8148439, lng: 18.5684615 },
    { lat: 48.814877, lng: 18.5685704 },
    { lat: 48.8148748, lng: 18.5686555 },
    { lat: 48.814915, lng: 18.5688134 },
    { lat: 48.8149133, lng: 18.5689084 },
    { lat: 48.8148934, lng: 18.569121 },
    { lat: 48.814741, lng: 18.5693704 },
    { lat: 48.8147629, lng: 18.5695649 },
    { lat: 48.8146987, lng: 18.5696498 },
    { lat: 48.8147013, lng: 18.5697644 },
    { lat: 48.8146452, lng: 18.5699145 },
    { lat: 48.8145999, lng: 18.5701381 },
    { lat: 48.8144696, lng: 18.5702341 },
    { lat: 48.8144009, lng: 18.5704004 },
    { lat: 48.8144013, lng: 18.5704702 },
    { lat: 48.8144215, lng: 18.5706376 },
    { lat: 48.8143229, lng: 18.5708542 },
    { lat: 48.8142066, lng: 18.5711639 },
    { lat: 48.8142178, lng: 18.571227 },
    { lat: 48.814166, lng: 18.5713741 },
    { lat: 48.8140531, lng: 18.5714775 },
    { lat: 48.8139879, lng: 18.5714989 },
    { lat: 48.8139373, lng: 18.5716508 },
    { lat: 48.8139305, lng: 18.5717537 },
    { lat: 48.8138448, lng: 18.571877 },
    { lat: 48.8137791, lng: 18.5720215 },
    { lat: 48.8137201, lng: 18.572201 },
    { lat: 48.8137485, lng: 18.5722859 },
    { lat: 48.8136681, lng: 18.5725517 },
    { lat: 48.8136225, lng: 18.5726254 },
    { lat: 48.8136353, lng: 18.5727781 },
    { lat: 48.8136218, lng: 18.5728825 },
    { lat: 48.8135918, lng: 18.5729749 },
    { lat: 48.8135666, lng: 18.5731566 },
    { lat: 48.8135205, lng: 18.5733266 },
    { lat: 48.8135137, lng: 18.5735139 },
    { lat: 48.8135343, lng: 18.5737078 },
    { lat: 48.8135274, lng: 18.5737459 },
    { lat: 48.8134583, lng: 18.5739499 },
    { lat: 48.8133661, lng: 18.5741195 },
    { lat: 48.8132386, lng: 18.5742667 },
    { lat: 48.8130641, lng: 18.5746262 },
    { lat: 48.8129985, lng: 18.5747259 },
    { lat: 48.8129342, lng: 18.5747465 },
    { lat: 48.8128766, lng: 18.5748703 },
    { lat: 48.8127938, lng: 18.5749654 },
    { lat: 48.8127766, lng: 18.575106 },
    { lat: 48.8127206, lng: 18.5753023 },
    { lat: 48.8127069, lng: 18.5753816 },
    { lat: 48.8126553, lng: 18.5755104 },
    { lat: 48.8126448, lng: 18.5756931 },
    { lat: 48.8125674, lng: 18.5758037 },
    { lat: 48.8124132, lng: 18.5761249 },
    { lat: 48.8123014, lng: 18.5762693 },
    { lat: 48.8122588, lng: 18.5764163 },
    { lat: 48.812189, lng: 18.5764848 },
    { lat: 48.8121075, lng: 18.5764988 },
    { lat: 48.8120758, lng: 18.5765167 },
    { lat: 48.8120812, lng: 18.5765772 },
    { lat: 48.8120649, lng: 18.5766286 },
    { lat: 48.8119802, lng: 18.5767322 },
    { lat: 48.8119721, lng: 18.5768323 },
    { lat: 48.8119153, lng: 18.5769263 },
    { lat: 48.8119095, lng: 18.5770899 },
    { lat: 48.8118875, lng: 18.5771864 },
    { lat: 48.8118217, lng: 18.5772625 },
    { lat: 48.811809, lng: 18.577404 },
    { lat: 48.8117653, lng: 18.5775537 },
    { lat: 48.8117728, lng: 18.5776308 },
    { lat: 48.8117798, lng: 18.5776546 },
    { lat: 48.8117523, lng: 18.577731 },
    { lat: 48.8117589, lng: 18.5778533 },
    { lat: 48.8116595, lng: 18.5779948 },
    { lat: 48.811581, lng: 18.5780038 },
    { lat: 48.8114996, lng: 18.5781249 },
    { lat: 48.8114667, lng: 18.5782277 },
    { lat: 48.8114093, lng: 18.5782291 },
    { lat: 48.811234, lng: 18.5783879 },
    { lat: 48.811172, lng: 18.5785523 },
    { lat: 48.8111632, lng: 18.5785774 },
    { lat: 48.8111587, lng: 18.5786427 },
    { lat: 48.811159, lng: 18.5786885 },
    { lat: 48.8111112, lng: 18.5788039 },
    { lat: 48.8110019, lng: 18.5790608 },
    { lat: 48.8109413, lng: 18.5792115 },
    { lat: 48.8108596, lng: 18.5793483 },
    { lat: 48.8107816, lng: 18.5794711 },
    { lat: 48.8106947, lng: 18.5796783 },
    { lat: 48.8106673, lng: 18.5797343 },
    { lat: 48.8106072, lng: 18.579935 },
    { lat: 48.8105487, lng: 18.580041 },
    { lat: 48.8105122, lng: 18.5800967 },
    { lat: 48.8104923, lng: 18.5801271 },
    { lat: 48.810488, lng: 18.5801527 },
    { lat: 48.8104758, lng: 18.5802186 },
    { lat: 48.8104735, lng: 18.5802382 },
    { lat: 48.8104585, lng: 18.5803134 },
    { lat: 48.8104454, lng: 18.5803033 },
    { lat: 48.8101458, lng: 18.5800616 },
    { lat: 48.8101053, lng: 18.5800413 },
    { lat: 48.8100221, lng: 18.5800018 },
    { lat: 48.8097891, lng: 18.5798894 },
    { lat: 48.8097777, lng: 18.579884 },
    { lat: 48.809604, lng: 18.579762 },
    { lat: 48.8092829, lng: 18.5795363 },
    { lat: 48.8089202, lng: 18.579129 },
    { lat: 48.8088911, lng: 18.5791143 },
    { lat: 48.8091094, lng: 18.5795621 },
    { lat: 48.8097075, lng: 18.5807977 },
    { lat: 48.8097856, lng: 18.580948 },
    { lat: 48.8097969, lng: 18.5809684 },
    { lat: 48.809785, lng: 18.5810024 },
    { lat: 48.8097724, lng: 18.5810412 },
    { lat: 48.8096976, lng: 18.5812616 },
    { lat: 48.8097282, lng: 18.5812857 },
    { lat: 48.8097423, lng: 18.5812972 },
    { lat: 48.8101829, lng: 18.581643 },
    { lat: 48.8102069, lng: 18.5815671 },
    { lat: 48.8103758, lng: 18.5817873 },
    { lat: 48.8105785, lng: 18.5820964 },
    { lat: 48.8106642, lng: 18.5822823 },
    { lat: 48.810746, lng: 18.5825008 },
    { lat: 48.8109162, lng: 18.5831671 },
    { lat: 48.8110686, lng: 18.5837126 },
    { lat: 48.8111383, lng: 18.5838958 },
    { lat: 48.8112462, lng: 18.5840764 },
    { lat: 48.8113644, lng: 18.5845935 },
    { lat: 48.8115489, lng: 18.5846698 },
    { lat: 48.8115626, lng: 18.5846752 },
    { lat: 48.8115898, lng: 18.5846532 },
    { lat: 48.8115398, lng: 18.5850219 },
    { lat: 48.8115096, lng: 18.5850282 },
    { lat: 48.8115078, lng: 18.585494 },
    { lat: 48.8115212, lng: 18.585741 },
    { lat: 48.8114498, lng: 18.5858411 },
    { lat: 48.811453, lng: 18.5859624 },
    { lat: 48.8114052, lng: 18.5861444 },
    { lat: 48.8113726, lng: 18.5862497 },
    { lat: 48.811209, lng: 18.586543 },
    { lat: 48.8111863, lng: 18.5867702 },
    { lat: 48.8111586, lng: 18.5868599 },
    { lat: 48.8111218, lng: 18.5869539 },
    { lat: 48.8110263, lng: 18.5870853 },
    { lat: 48.8110013, lng: 18.5871653 },
    { lat: 48.810932, lng: 18.5871775 },
    { lat: 48.8108318, lng: 18.5872432 },
    { lat: 48.8107284, lng: 18.5873752 },
    { lat: 48.8105885, lng: 18.5873538 },
    { lat: 48.8105099, lng: 18.5873997 },
    { lat: 48.8104408, lng: 18.5873721 },
    { lat: 48.8103704, lng: 18.58747 },
    { lat: 48.8102601, lng: 18.5876633 },
    { lat: 48.8101306, lng: 18.5877695 },
    { lat: 48.810039, lng: 18.587993 },
    { lat: 48.8099641, lng: 18.5880728 },
    { lat: 48.8098825, lng: 18.5882522 },
    { lat: 48.8098047, lng: 18.5882503 },
    { lat: 48.809677, lng: 18.5883502 },
    { lat: 48.8096595, lng: 18.5885081 },
    { lat: 48.8095904, lng: 18.5885241 },
    { lat: 48.8095374, lng: 18.5886059 },
    { lat: 48.8095663, lng: 18.5887035 },
    { lat: 48.8095472, lng: 18.588853 },
    { lat: 48.8094915, lng: 18.5888452 },
    { lat: 48.8094275, lng: 18.5889111 },
    { lat: 48.8094067, lng: 18.5889641 },
    { lat: 48.8093007, lng: 18.588944 },
    { lat: 48.8092378, lng: 18.5889704 },
    { lat: 48.8091243, lng: 18.5890395 },
    { lat: 48.8090336, lng: 18.589093 },
    { lat: 48.8090187, lng: 18.5891328 },
    { lat: 48.8089821, lng: 18.5892472 },
    { lat: 48.8089284, lng: 18.5894259 },
    { lat: 48.8089181, lng: 18.5894626 },
    { lat: 48.8088806, lng: 18.5895592 },
    { lat: 48.8088042, lng: 18.5896292 },
    { lat: 48.8087601, lng: 18.5896922 },
    { lat: 48.8087044, lng: 18.5897843 },
    { lat: 48.808616, lng: 18.5898418 },
    { lat: 48.8086073, lng: 18.5898475 },
    { lat: 48.8085959, lng: 18.5898457 },
    { lat: 48.8085344, lng: 18.5898322 },
    { lat: 48.8084957, lng: 18.5898474 },
    { lat: 48.808449, lng: 18.5898839 },
    { lat: 48.8083918, lng: 18.5899483 },
    { lat: 48.808378, lng: 18.5899639 },
    { lat: 48.8083109, lng: 18.5901017 },
    { lat: 48.8081682, lng: 18.5902597 },
    { lat: 48.8080676, lng: 18.5904622 },
    { lat: 48.8080243, lng: 18.5905616 },
    { lat: 48.8080195, lng: 18.5905747 },
    { lat: 48.8080087, lng: 18.5905843 },
    { lat: 48.8079658, lng: 18.5906254 },
    { lat: 48.8079353, lng: 18.5906467 },
    { lat: 48.8078895, lng: 18.5906306 },
    { lat: 48.8078801, lng: 18.5906274 },
    { lat: 48.8078234, lng: 18.5907017 },
    { lat: 48.8078092, lng: 18.590755 },
    { lat: 48.8077853, lng: 18.5909376 },
    { lat: 48.8077779, lng: 18.590989 },
    { lat: 48.8077464, lng: 18.5909925 },
    { lat: 48.80765, lng: 18.5910023 },
    { lat: 48.807621, lng: 18.5910295 },
    { lat: 48.8075813, lng: 18.5911081 },
    { lat: 48.8075714, lng: 18.5911559 },
    { lat: 48.8075899, lng: 18.5912237 },
    { lat: 48.807561, lng: 18.5913364 },
    { lat: 48.8075574, lng: 18.5915226 },
    { lat: 48.8075581, lng: 18.5915533 },
    { lat: 48.8074671, lng: 18.5917502 },
    { lat: 48.8073654, lng: 18.5918494 },
    { lat: 48.8073628, lng: 18.5919689 },
    { lat: 48.8072386, lng: 18.5920093 },
    { lat: 48.8072244, lng: 18.5919964 },
    { lat: 48.8071721, lng: 18.5919463 },
    { lat: 48.807065, lng: 18.5920118 },
    { lat: 48.8069916, lng: 18.5920102 },
    { lat: 48.8069294, lng: 18.5920268 },
    { lat: 48.8068808, lng: 18.5920897 },
    { lat: 48.8068379, lng: 18.592319 },
    { lat: 48.8067158, lng: 18.5924397 },
    { lat: 48.8065447, lng: 18.5927164 },
    { lat: 48.8065213, lng: 18.5927017 },
    { lat: 48.8064323, lng: 18.5928494 },
    { lat: 48.8063967, lng: 18.5928982 },
    { lat: 48.8063271, lng: 18.593032 },
    { lat: 48.8062641, lng: 18.593096 },
    { lat: 48.8062561, lng: 18.5931138 },
    { lat: 48.8061949, lng: 18.5932592 },
    { lat: 48.8061819, lng: 18.5932894 },
    { lat: 48.8061482, lng: 18.5933122 },
    { lat: 48.8061304, lng: 18.593377 },
    { lat: 48.8061002, lng: 18.5934884 },
    { lat: 48.8060434, lng: 18.5935649 },
    { lat: 48.8060119, lng: 18.5935641 },
    { lat: 48.805916, lng: 18.5936257 },
    { lat: 48.8059057, lng: 18.5936023 },
    { lat: 48.8058863, lng: 18.5935575 },
    { lat: 48.8058683, lng: 18.5935568 },
    { lat: 48.8058593, lng: 18.5935569 },
    { lat: 48.8058298, lng: 18.593596 },
    { lat: 48.8058253, lng: 18.5935961 },
    { lat: 48.8057676, lng: 18.5935965 },
    { lat: 48.8057143, lng: 18.5936729 },
    { lat: 48.805619, lng: 18.5937025 },
    { lat: 48.8053545, lng: 18.5938201 },
    { lat: 48.8053318, lng: 18.5937538 },
    { lat: 48.805122, lng: 18.5937118 },
    { lat: 48.8050407, lng: 18.593646 },
    { lat: 48.8049955, lng: 18.5936455 },
    { lat: 48.8047718, lng: 18.5937 },
    { lat: 48.8047188, lng: 18.5937863 },
    { lat: 48.804737, lng: 18.5938921 },
    { lat: 48.8046839, lng: 18.5940383 },
    { lat: 48.8045983, lng: 18.594124 },
    { lat: 48.8045472, lng: 18.5941979 },
    { lat: 48.804502, lng: 18.5943026 },
    { lat: 48.8044769, lng: 18.594485 },
    { lat: 48.8045076, lng: 18.5947381 },
    { lat: 48.8045031, lng: 18.594826 },
    { lat: 48.8045016, lng: 18.594882 },
    { lat: 48.8045005, lng: 18.5949233 },
    { lat: 48.8044592, lng: 18.5948897 },
    { lat: 48.8043171, lng: 18.5950977 },
    { lat: 48.8042665, lng: 18.5952903 },
    { lat: 48.804167, lng: 18.5954311 },
    { lat: 48.8038868, lng: 18.5956522 },
    { lat: 48.8035774, lng: 18.5962296 },
    { lat: 48.8035109, lng: 18.5963155 },
    { lat: 48.8034008, lng: 18.5965144 },
    { lat: 48.8032021, lng: 18.5968002 },
    { lat: 48.8031179, lng: 18.5968904 },
    { lat: 48.8030402, lng: 18.5969573 },
    { lat: 48.802966, lng: 18.596984 },
    { lat: 48.8028032, lng: 18.597081 },
    { lat: 48.8027513, lng: 18.5971228 },
    { lat: 48.8026809, lng: 18.5972804 },
    { lat: 48.8027096, lng: 18.5973087 },
    { lat: 48.8029864, lng: 18.5975289 },
    { lat: 48.8038655, lng: 18.5982671 },
    { lat: 48.8044895, lng: 18.5989424 },
    { lat: 48.8045264, lng: 18.5989779 },
    { lat: 48.8057369, lng: 18.6004048 },
    { lat: 48.8057519, lng: 18.6003733 },
    { lat: 48.8061052, lng: 18.6006867 },
    { lat: 48.8065898, lng: 18.6010391 },
    { lat: 48.8066627, lng: 18.6010948 },
    { lat: 48.8076101, lng: 18.6020559 },
    { lat: 48.8081546, lng: 18.6026127 },
    { lat: 48.8088472, lng: 18.6033297 },
    { lat: 48.8088304, lng: 18.6033602 },
    { lat: 48.809701, lng: 18.6041038 },
    { lat: 48.8099475, lng: 18.6042827 },
    { lat: 48.8106436, lng: 18.6047224 },
    { lat: 48.8111683, lng: 18.6049997 },
    { lat: 48.8115837, lng: 18.6052558 },
    { lat: 48.8120425, lng: 18.6054629 },
    { lat: 48.8126543, lng: 18.605743 },
    { lat: 48.8127221, lng: 18.6057676 },
    { lat: 48.8127278, lng: 18.605768 },
    { lat: 48.8127454, lng: 18.6057626 },
    { lat: 48.812958, lng: 18.6026319 },
    { lat: 48.8129848, lng: 18.6026457 },
    { lat: 48.8136517, lng: 18.6028425 },
    { lat: 48.8157962, lng: 18.6034991 },
    { lat: 48.8171073, lng: 18.6038814 },
    { lat: 48.8176886, lng: 18.6040587 },
    { lat: 48.8177051, lng: 18.6039436 },
    { lat: 48.8178043, lng: 18.6037545 },
    { lat: 48.8178848, lng: 18.6036379 },
    { lat: 48.8179614, lng: 18.6036346 },
    { lat: 48.8181334, lng: 18.6035024 },
    { lat: 48.8181721, lng: 18.6033609 },
    { lat: 48.8181307, lng: 18.6031795 },
    { lat: 48.8181631, lng: 18.6028992 },
    { lat: 48.8182301, lng: 18.6028437 },
    { lat: 48.8182907, lng: 18.6025252 },
    { lat: 48.8183438, lng: 18.6024012 },
    { lat: 48.8183562, lng: 18.6022368 },
    { lat: 48.8184196, lng: 18.6021542 },
    { lat: 48.8184987, lng: 18.6021799 },
    { lat: 48.8186395, lng: 18.6020286 },
    { lat: 48.8186391, lng: 18.6019603 },
    { lat: 48.8187631, lng: 18.6017485 },
    { lat: 48.8188435, lng: 18.6016308 },
    { lat: 48.8189647, lng: 18.6013889 },
    { lat: 48.8190331, lng: 18.6011926 },
    { lat: 48.819139, lng: 18.6011663 },
    { lat: 48.8191924, lng: 18.6012799 },
    { lat: 48.81923, lng: 18.60133 },
    { lat: 48.8192821, lng: 18.6013529 },
    { lat: 48.8193077, lng: 18.6013465 },
    { lat: 48.8193887, lng: 18.6012385 },
    { lat: 48.8194639, lng: 18.6011923 },
    { lat: 48.8195746, lng: 18.601194 },
    { lat: 48.8197498, lng: 18.6010324 },
    { lat: 48.8197162, lng: 18.6009138 },
    { lat: 48.8196767, lng: 18.6008464 },
    { lat: 48.8195936, lng: 18.60077 },
    { lat: 48.8195714, lng: 18.6005895 },
    { lat: 48.8195786, lng: 18.6004734 },
    { lat: 48.8196066, lng: 18.6003436 },
    { lat: 48.8195786, lng: 18.6001792 },
    { lat: 48.8197042, lng: 18.5999754 },
    { lat: 48.8197147, lng: 18.5996259 },
    { lat: 48.8196134, lng: 18.5994219 },
    { lat: 48.8197034, lng: 18.5991895 },
    { lat: 48.8197316, lng: 18.5989802 },
    { lat: 48.8198454, lng: 18.5987894 },
    { lat: 48.8199596, lng: 18.5985444 },
    { lat: 48.8199683, lng: 18.5981628 },
    { lat: 48.8199441, lng: 18.5979858 },
    { lat: 48.8200637, lng: 18.5979016 },
    { lat: 48.8200848, lng: 18.5977501 },
    { lat: 48.8200429, lng: 18.5975559 },
    { lat: 48.8199181, lng: 18.5973548 },
    { lat: 48.8199105, lng: 18.5972577 },
    { lat: 48.8200175, lng: 18.5970232 },
    { lat: 48.8200368, lng: 18.5967932 },
    { lat: 48.8202335, lng: 18.5964278 },
    { lat: 48.8204147, lng: 18.5963549 },
    { lat: 48.8205346, lng: 18.5961329 },
    { lat: 48.8205879, lng: 18.5960135 },
    { lat: 48.8207356, lng: 18.5960335 },
    { lat: 48.8210203, lng: 18.5960012 },
    { lat: 48.8211429, lng: 18.5957849 },
    { lat: 48.8212976, lng: 18.5957439 },
    { lat: 48.8213765, lng: 18.5956214 },
    { lat: 48.8215284, lng: 18.5955271 },
    { lat: 48.8216388, lng: 18.5952131 },
    { lat: 48.8218169, lng: 18.5949599 },
    { lat: 48.8219266, lng: 18.5945891 },
    { lat: 48.8219038, lng: 18.5942712 },
    { lat: 48.82202, lng: 18.5941695 },
    { lat: 48.822187, lng: 18.593623 },
    { lat: 48.8223539, lng: 18.5931857 },
    { lat: 48.8224143, lng: 18.5928425 },
    { lat: 48.822591, lng: 18.5927104 },
    { lat: 48.8226671, lng: 18.5924257 },
    { lat: 48.8226655, lng: 18.5922895 },
    { lat: 48.8226385, lng: 18.5921665 },
    { lat: 48.8225202, lng: 18.59213 },
    { lat: 48.8224353, lng: 18.5920195 },
    { lat: 48.8224198, lng: 18.5918543 },
    { lat: 48.8223471, lng: 18.5917645 },
    { lat: 48.8223183, lng: 18.5914792 },
    { lat: 48.8222038, lng: 18.5911988 },
    { lat: 48.8221131, lng: 18.5911248 },
    { lat: 48.8220783, lng: 18.5909623 },
    { lat: 48.8219997, lng: 18.5906571 },
    { lat: 48.8220076, lng: 18.5904267 },
    { lat: 48.8220244, lng: 18.5903176 },
    { lat: 48.8220426, lng: 18.5898407 },
    { lat: 48.822018, lng: 18.589678 },
    { lat: 48.821948, lng: 18.5893846 },
    { lat: 48.8220004, lng: 18.5892475 },
    { lat: 48.8220201, lng: 18.5890467 },
    { lat: 48.8220709, lng: 18.5889674 },
    { lat: 48.8221004, lng: 18.5887841 },
    { lat: 48.8220974, lng: 18.5886617 },
    { lat: 48.8221678, lng: 18.5885477 },
    { lat: 48.8221588, lng: 18.5883992 },
    { lat: 48.8222024, lng: 18.5882696 },
    { lat: 48.8221447, lng: 18.5879321 },
    { lat: 48.8221792, lng: 18.5877739 },
    { lat: 48.822139, lng: 18.5874725 },
    { lat: 48.822216, lng: 18.5871433 },
    { lat: 48.8222087, lng: 18.5869413 },
    { lat: 48.822379, lng: 18.5866292 },
    { lat: 48.8224522, lng: 18.5864401 },
    { lat: 48.8225067, lng: 18.5861527 },
    { lat: 48.8224714, lng: 18.5859591 },
    { lat: 48.8225246, lng: 18.5858588 },
    { lat: 48.822545, lng: 18.5856937 },
    { lat: 48.8224997, lng: 18.5856302 },
    { lat: 48.8225139, lng: 18.5854731 },
    { lat: 48.8224998, lng: 18.5852347 },
    { lat: 48.8227051, lng: 18.5847733 },
    { lat: 48.8227233, lng: 18.5846941 },
    { lat: 48.8228001, lng: 18.5846334 },
    { lat: 48.8228794, lng: 18.5844954 },
    { lat: 48.8230467, lng: 18.5844153 },
    { lat: 48.8233511, lng: 18.5840382 },
    { lat: 48.8234641, lng: 18.5835184 },
    { lat: 48.8235937, lng: 18.5833316 },
    { lat: 48.8236798, lng: 18.5832795 },
    { lat: 48.8237992, lng: 18.5832073 },
    { lat: 48.8240726, lng: 18.5831216 },
    { lat: 48.8241197, lng: 18.5831068 },
    { lat: 48.8242557, lng: 18.5828687 },
    { lat: 48.8243406, lng: 18.5825098 },
    { lat: 48.8243806, lng: 18.5823406 },
    { lat: 48.8244738, lng: 18.5822943 },
    { lat: 48.8245527, lng: 18.582145 },
    { lat: 48.8247444, lng: 18.582084 },
    { lat: 48.8247862, lng: 18.5820124 },
    { lat: 48.8249335, lng: 18.5817605 },
    { lat: 48.8250514, lng: 18.5816289 },
    { lat: 48.8252455, lng: 18.5815257 },
    { lat: 48.8253553, lng: 18.5812992 },
    { lat: 48.8253857, lng: 18.5812366 },
    { lat: 48.8255188, lng: 18.5810938 },
    { lat: 48.825689, lng: 18.5808002 },
    { lat: 48.8259, lng: 18.5806119 },
    { lat: 48.8260849, lng: 18.5806126 },
    { lat: 48.8261909, lng: 18.5804223 },
    { lat: 48.8261291, lng: 18.5802592 },
    { lat: 48.8262527, lng: 18.5799389 },
    { lat: 48.8264297, lng: 18.5798349 },
    { lat: 48.8264651, lng: 18.5797215 },
    { lat: 48.8265047, lng: 18.5795944 },
    { lat: 48.8265401, lng: 18.5794548 },
    { lat: 48.8265921, lng: 18.5792496 },
    { lat: 48.8266991, lng: 18.5789764 },
    { lat: 48.8268121, lng: 18.5788331 },
    { lat: 48.8268814, lng: 18.5786132 },
    { lat: 48.8269632, lng: 18.5783537 },
    { lat: 48.8270037, lng: 18.5781836 },
    { lat: 48.8271815, lng: 18.5781108 },
    { lat: 48.8272106, lng: 18.5780661 },
    { lat: 48.8273261, lng: 18.5778884 },
    { lat: 48.8273514, lng: 18.5777918 },
    { lat: 48.827372, lng: 18.577713 },
    { lat: 48.8274199, lng: 18.5776626 },
    { lat: 48.8274936, lng: 18.5775852 },
    { lat: 48.8274962, lng: 18.5775469 },
    { lat: 48.8275036, lng: 18.5774382 },
    { lat: 48.8276056, lng: 18.5771732 },
    { lat: 48.8276727, lng: 18.5768706 },
    { lat: 48.8277213, lng: 18.5765818 },
    { lat: 48.8277703, lng: 18.5764465 },
    { lat: 48.8278186, lng: 18.5762515 },
    { lat: 48.8279921, lng: 18.5758326 },
    { lat: 48.828044, lng: 18.5757911 },
    { lat: 48.8280973, lng: 18.5756498 },
    { lat: 48.8282568, lng: 18.5754525 },
    { lat: 48.8283057, lng: 18.5752896 },
    { lat: 48.8286414, lng: 18.5748459 },
    { lat: 48.8287087, lng: 18.5746532 },
    { lat: 48.8287751, lng: 18.5742562 },
    { lat: 48.8289623, lng: 18.5737525 },
    { lat: 48.8290853, lng: 18.5735461 },
    { lat: 48.8292432, lng: 18.5734194 },
    { lat: 48.8294083, lng: 18.5730721 },
    { lat: 48.8296556, lng: 18.5726189 },
    { lat: 48.8298761, lng: 18.5722962 },
    { lat: 48.8300331, lng: 18.5720086 },
    { lat: 48.8301347, lng: 18.5716594 },
    { lat: 48.830125, lng: 18.5715407 },
    { lat: 48.8302299, lng: 18.5712918 },
    { lat: 48.8302449, lng: 18.5710876 },
    { lat: 48.8304009, lng: 18.5709079 },
    { lat: 48.8304221, lng: 18.570737 },
    { lat: 48.8305247, lng: 18.5706508 },
    { lat: 48.8305707, lng: 18.5704607 },
    { lat: 48.8306131, lng: 18.5702221 },
    { lat: 48.8306394, lng: 18.5701643 },
    { lat: 48.8308137, lng: 18.5697811 },
    { lat: 48.8308294, lng: 18.569735 },
    { lat: 48.8309714, lng: 18.5693191 },
    { lat: 48.8310954, lng: 18.5690248 },
    { lat: 48.8311923, lng: 18.5686934 },
    { lat: 48.8312639, lng: 18.5685221 },
    { lat: 48.8313752, lng: 18.5682561 },
    { lat: 48.8313923, lng: 18.5682166 },
    { lat: 48.8314813, lng: 18.5680105 },
    { lat: 48.8317339, lng: 18.567673 },
    { lat: 48.8319621, lng: 18.5675154 },
    { lat: 48.832128, lng: 18.567309 },
    { lat: 48.8323443, lng: 18.567218 },
    { lat: 48.8324366, lng: 18.5671335 },
    { lat: 48.8326438, lng: 18.5671267 },
    { lat: 48.8328062, lng: 18.5669784 },
    { lat: 48.833184, lng: 18.5663571 },
    { lat: 48.8332879, lng: 18.5660678 },
    { lat: 48.8333732, lng: 18.5656616 },
    { lat: 48.8334488, lng: 18.5652065 },
    { lat: 48.8334629, lng: 18.5650338 },
    { lat: 48.8334759, lng: 18.5648737 },
    { lat: 48.8334619, lng: 18.5646163 },
    { lat: 48.833565, lng: 18.564268 },
    { lat: 48.8335707, lng: 18.5639801 },
    { lat: 48.8335338, lng: 18.5637581 },
    { lat: 48.8335599, lng: 18.5631654 },
    { lat: 48.8335692, lng: 18.5629535 },
    { lat: 48.833627, lng: 18.5625401 },
    { lat: 48.8336327, lng: 18.5624995 },
    { lat: 48.8336489, lng: 18.5622368 },
    { lat: 48.8336591, lng: 18.5620705 },
    { lat: 48.8335874, lng: 18.5616247 },
    { lat: 48.8336755, lng: 18.5614179 },
    { lat: 48.8336573, lng: 18.5610385 },
    { lat: 48.8337826, lng: 18.5606033 },
    { lat: 48.8338107, lng: 18.560206 },
    { lat: 48.833853, lng: 18.5599064 },
    { lat: 48.8339299, lng: 18.5597243 },
    { lat: 48.8338928, lng: 18.5594976 },
    { lat: 48.8339454, lng: 18.5592204 },
    { lat: 48.8339438, lng: 18.5590438 },
    { lat: 48.834027, lng: 18.5585812 },
    { lat: 48.8340221, lng: 18.5583473 },
    { lat: 48.8340596, lng: 18.5580193 },
    { lat: 48.8340536, lng: 18.5577437 },
    { lat: 48.8341235, lng: 18.5574289 },
    { lat: 48.8341009, lng: 18.5573039 },
    { lat: 48.834108, lng: 18.5571211 },
    { lat: 48.8340669, lng: 18.556887 },
    { lat: 48.834059, lng: 18.5562389 },
    { lat: 48.8340835, lng: 18.5560676 },
    { lat: 48.834059, lng: 18.5559471 },
    { lat: 48.8340749, lng: 18.5556616 },
    { lat: 48.8340844, lng: 18.5550883 },
    { lat: 48.834106, lng: 18.554758 },
    { lat: 48.8340813, lng: 18.5544252 },
    { lat: 48.8341443, lng: 18.5542577 },
    { lat: 48.8342292, lng: 18.5539532 },
    { lat: 48.8342008, lng: 18.5538006 },
    { lat: 48.8341863, lng: 18.5533506 },
    { lat: 48.8342419, lng: 18.5527955 },
    { lat: 48.8342258, lng: 18.5525204 },
    { lat: 48.8344461, lng: 18.5517539 },
    { lat: 48.8344908, lng: 18.5514372 },
    { lat: 48.8344764, lng: 18.5513595 },
    { lat: 48.8344921, lng: 18.5512727 },
    { lat: 48.8345081, lng: 18.5509464 },
    { lat: 48.8345347, lng: 18.5502914 },
    { lat: 48.8345261, lng: 18.5496588 },
    { lat: 48.834554, lng: 18.5495289 },
    { lat: 48.8343122, lng: 18.5495633 },
    { lat: 48.8344047, lng: 18.5481297 },
    { lat: 48.8341878, lng: 18.5463769 },
    { lat: 48.834257, lng: 18.5443114 },
    { lat: 48.8344982, lng: 18.543562 },
    { lat: 48.8345492, lng: 18.5430694 },
    { lat: 48.8346052, lng: 18.5427914 },
    { lat: 48.8346287, lng: 18.5421013 },
    { lat: 48.8346584, lng: 18.5419877 },
    { lat: 48.8347597, lng: 18.5417717 },
    { lat: 48.8347467, lng: 18.5417415 },
    { lat: 48.8337392, lng: 18.5398927 },
    { lat: 48.8324453, lng: 18.5392891 },
    { lat: 48.8312384, lng: 18.5392486 },
    { lat: 48.8302674, lng: 18.5390878 },
    { lat: 48.829438, lng: 18.5392439 },
    { lat: 48.8283412, lng: 18.539673 },
    { lat: 48.8282399, lng: 18.5397175 },
    { lat: 48.826833, lng: 18.5404599 },
    { lat: 48.8260195, lng: 18.541078 },
    { lat: 48.8236938, lng: 18.541472 },
    { lat: 48.8236849, lng: 18.5414556 },
    { lat: 48.8234213, lng: 18.5416886 },
    { lat: 48.8232775, lng: 18.5419267 },
    { lat: 48.8231999, lng: 18.542182 },
    { lat: 48.8231316, lng: 18.5424597 },
    { lat: 48.8230849, lng: 18.5425736 },
    { lat: 48.8230744, lng: 18.5428817 },
    { lat: 48.8230329, lng: 18.54303 },
    { lat: 48.8208131, lng: 18.5454575 },
    { lat: 48.8197687, lng: 18.5464762 },
    { lat: 48.819194, lng: 18.5478757 },
    { lat: 48.8185154, lng: 18.5489178 },
    { lat: 48.8181291, lng: 18.5491166 },
    { lat: 48.8172081, lng: 18.5492929 },
  ],
  Kocurany: [
    { lat: 48.7830701, lng: 18.5389258 },
    { lat: 48.7828322, lng: 18.5389494 },
    { lat: 48.7826483, lng: 18.5389065 },
    { lat: 48.7824328, lng: 18.5387593 },
    { lat: 48.7822632, lng: 18.5385655 },
    { lat: 48.7821394, lng: 18.5384883 },
    { lat: 48.781896, lng: 18.5384939 },
    { lat: 48.7817011, lng: 18.5384135 },
    { lat: 48.7816341, lng: 18.5383249 },
    { lat: 48.7813981, lng: 18.5381111 },
    { lat: 48.7812862, lng: 18.5377785 },
    { lat: 48.7812911, lng: 18.537618 },
    { lat: 48.7812492, lng: 18.5375549 },
    { lat: 48.7812048, lng: 18.5376474 },
    { lat: 48.7811146, lng: 18.5375671 },
    { lat: 48.7810804, lng: 18.5375803 },
    { lat: 48.7810416, lng: 18.5374678 },
    { lat: 48.7809235, lng: 18.5374951 },
    { lat: 48.7808665, lng: 18.537382 },
    { lat: 48.7807346, lng: 18.5372593 },
    { lat: 48.780306, lng: 18.5368472 },
    { lat: 48.7801807, lng: 18.536743 },
    { lat: 48.7801479, lng: 18.5365961 },
    { lat: 48.7800463, lng: 18.5365702 },
    { lat: 48.7800325, lng: 18.5366288 },
    { lat: 48.7799653, lng: 18.5365767 },
    { lat: 48.7799232, lng: 18.5366313 },
    { lat: 48.7798604, lng: 18.5365945 },
    { lat: 48.7797931, lng: 18.5366254 },
    { lat: 48.7796774, lng: 18.5367371 },
    { lat: 48.7795015, lng: 18.5366743 },
    { lat: 48.7793698, lng: 18.5367218 },
    { lat: 48.7791313, lng: 18.5366314 },
    { lat: 48.77903, lng: 18.5363862 },
    { lat: 48.7789705, lng: 18.5364509 },
    { lat: 48.7788077, lng: 18.5363442 },
    { lat: 48.7784859, lng: 18.5365031 },
    { lat: 48.7783405, lng: 18.5362162 },
    { lat: 48.7782198, lng: 18.5362785 },
    { lat: 48.778112, lng: 18.5361832 },
    { lat: 48.7780859, lng: 18.5360552 },
    { lat: 48.7779617, lng: 18.5360521 },
    { lat: 48.7779065, lng: 18.5357646 },
    { lat: 48.7778199, lng: 18.5357068 },
    { lat: 48.7777491, lng: 18.5355869 },
    { lat: 48.7776413, lng: 18.5355123 },
    { lat: 48.7774711, lng: 18.5354953 },
    { lat: 48.7774005, lng: 18.5354004 },
    { lat: 48.7772625, lng: 18.5351848 },
    { lat: 48.7772111, lng: 18.5350707 },
    { lat: 48.7770496, lng: 18.5350698 },
    { lat: 48.776961, lng: 18.5349921 },
    { lat: 48.7767027, lng: 18.5350144 },
    { lat: 48.7765726, lng: 18.5347177 },
    { lat: 48.7764138, lng: 18.5346631 },
    { lat: 48.7763177, lng: 18.5346994 },
    { lat: 48.7762549, lng: 18.5347655 },
    { lat: 48.7761195, lng: 18.534991 },
    { lat: 48.7760666, lng: 18.5350351 },
    { lat: 48.7758655, lng: 18.5350316 },
    { lat: 48.7754659, lng: 18.5349427 },
    { lat: 48.7754213, lng: 18.53487 },
    { lat: 48.7753953, lng: 18.5346011 },
    { lat: 48.7752879, lng: 18.5344485 },
    { lat: 48.775066, lng: 18.5343106 },
    { lat: 48.7747159, lng: 18.5342403 },
    { lat: 48.7745751, lng: 18.5341198 },
    { lat: 48.7743713, lng: 18.534167 },
    { lat: 48.7742951, lng: 18.5342613 },
    { lat: 48.7741771, lng: 18.5342911 },
    { lat: 48.7740367, lng: 18.5342596 },
    { lat: 48.7739404, lng: 18.5341075 },
    { lat: 48.7737909, lng: 18.5340743 },
    { lat: 48.773694, lng: 18.5342212 },
    { lat: 48.7736499, lng: 18.534324 },
    { lat: 48.7735139, lng: 18.5342083 },
    { lat: 48.7734159, lng: 18.5338804 },
    { lat: 48.7733273, lng: 18.533786 },
    { lat: 48.7732409, lng: 18.5338143 },
    { lat: 48.7732209, lng: 18.5337982 },
    { lat: 48.7731982, lng: 18.5335915 },
    { lat: 48.7731725, lng: 18.5335528 },
    { lat: 48.7730848, lng: 18.5336396 },
    { lat: 48.7730141, lng: 18.5336481 },
    { lat: 48.7729578, lng: 18.5335864 },
    { lat: 48.7728916, lng: 18.5335947 },
    { lat: 48.7728118, lng: 18.5335564 },
    { lat: 48.7727157, lng: 18.5334507 },
    { lat: 48.7725992, lng: 18.5335078 },
    { lat: 48.7725422, lng: 18.5335764 },
    { lat: 48.7724874, lng: 18.5335858 },
    { lat: 48.7723101, lng: 18.5335411 },
    { lat: 48.7720709, lng: 18.5334983 },
    { lat: 48.7720431, lng: 18.5333831 },
    { lat: 48.7720142, lng: 18.5332043 },
    { lat: 48.7718952, lng: 18.5329649 },
    { lat: 48.7718882, lng: 18.5327369 },
    { lat: 48.7717452, lng: 18.5328005 },
    { lat: 48.7716738, lng: 18.5326925 },
    { lat: 48.7715804, lng: 18.5327571 },
    { lat: 48.7715792, lng: 18.5326142 },
    { lat: 48.7715335, lng: 18.5323739 },
    { lat: 48.7713907, lng: 18.532239 },
    { lat: 48.7713814, lng: 18.5319416 },
    { lat: 48.7713153, lng: 18.5318483 },
    { lat: 48.7712061, lng: 18.5317879 },
    { lat: 48.7710443, lng: 18.5317806 },
    { lat: 48.7709764, lng: 18.5318187 },
    { lat: 48.7709532, lng: 18.5316192 },
    { lat: 48.7708643, lng: 18.5316007 },
    { lat: 48.7707629, lng: 18.5316669 },
    { lat: 48.7706559, lng: 18.5315244 },
    { lat: 48.7705031, lng: 18.5314534 },
    { lat: 48.7704933, lng: 18.5311095 },
    { lat: 48.7704047, lng: 18.5311792 },
    { lat: 48.7702959, lng: 18.5311689 },
    { lat: 48.770194, lng: 18.5310396 },
    { lat: 48.7700257, lng: 18.5310345 },
    { lat: 48.769956, lng: 18.5309964 },
    { lat: 48.7699398, lng: 18.5309283 },
    { lat: 48.7697025, lng: 18.5307774 },
    { lat: 48.769659, lng: 18.5306609 },
    { lat: 48.7695102, lng: 18.5304753 },
    { lat: 48.769392, lng: 18.5305841 },
    { lat: 48.769289, lng: 18.5305376 },
    { lat: 48.7691354, lng: 18.5304305 },
    { lat: 48.7690771, lng: 18.5304357 },
    { lat: 48.76898, lng: 18.530372 },
    { lat: 48.7689208, lng: 18.5303833 },
    { lat: 48.7688517, lng: 18.530338 },
    { lat: 48.7688505, lng: 18.5302112 },
    { lat: 48.7687439, lng: 18.5301984 },
    { lat: 48.7686674, lng: 18.5300399 },
    { lat: 48.768544, lng: 18.5300313 },
    { lat: 48.7683554, lng: 18.5300874 },
    { lat: 48.7682209, lng: 18.5299794 },
    { lat: 48.7681317, lng: 18.5298354 },
    { lat: 48.7679775, lng: 18.529821 },
    { lat: 48.7678856, lng: 18.5298297 },
    { lat: 48.7677318, lng: 18.5296969 },
    { lat: 48.7676748, lng: 18.529748 },
    { lat: 48.7675354, lng: 18.5295337 },
    { lat: 48.7674998, lng: 18.5294559 },
    { lat: 48.7673927, lng: 18.5294385 },
    { lat: 48.7673473, lng: 18.5293695 },
    { lat: 48.7672111, lng: 18.5292517 },
    { lat: 48.7671884, lng: 18.5292899 },
    { lat: 48.7671016, lng: 18.5292721 },
    { lat: 48.7670892, lng: 18.5292909 },
    { lat: 48.7670472, lng: 18.5292893 },
    { lat: 48.7669534, lng: 18.5293236 },
    { lat: 48.7668998, lng: 18.5292956 },
    { lat: 48.7667767, lng: 18.5291043 },
    { lat: 48.7666611, lng: 18.5289707 },
    { lat: 48.7665307, lng: 18.5289812 },
    { lat: 48.7662603, lng: 18.528882 },
    { lat: 48.7661666, lng: 18.5289445 },
    { lat: 48.7660911, lng: 18.5290469 },
    { lat: 48.7659894, lng: 18.5289419 },
    { lat: 48.76574, lng: 18.5289409 },
    { lat: 48.7656817, lng: 18.5290079 },
    { lat: 48.7656809, lng: 18.5291796 },
    { lat: 48.7655989, lng: 18.5292657 },
    { lat: 48.7654645, lng: 18.5293068 },
    { lat: 48.7653577, lng: 18.529476 },
    { lat: 48.7652842, lng: 18.5294938 },
    { lat: 48.7651635, lng: 18.529556 },
    { lat: 48.7650747, lng: 18.5295006 },
    { lat: 48.7649789, lng: 18.5295219 },
    { lat: 48.7647328, lng: 18.5297243 },
    { lat: 48.7646051, lng: 18.5297238 },
    { lat: 48.7645038, lng: 18.5296209 },
    { lat: 48.7643973, lng: 18.5298824 },
    { lat: 48.7643906, lng: 18.529924 },
    { lat: 48.7643856, lng: 18.5301234 },
    { lat: 48.7643384, lng: 18.530125 },
    { lat: 48.7642806, lng: 18.53004 },
    { lat: 48.7642491, lng: 18.529957 },
    { lat: 48.7641294, lng: 18.5297081 },
    { lat: 48.7640734, lng: 18.5296732 },
    { lat: 48.7639929, lng: 18.5296844 },
    { lat: 48.7638758, lng: 18.529669 },
    { lat: 48.7637747, lng: 18.5297188 },
    { lat: 48.7638534, lng: 18.5299818 },
    { lat: 48.7636587, lng: 18.5304247 },
    { lat: 48.7631443, lng: 18.5304625 },
    { lat: 48.7628397, lng: 18.5304215 },
    { lat: 48.76263, lng: 18.5303648 },
    { lat: 48.7625, lng: 18.5301701 },
    { lat: 48.7623873, lng: 18.5301091 },
    { lat: 48.7622698, lng: 18.530088 },
    { lat: 48.7621212, lng: 18.5302803 },
    { lat: 48.7619949, lng: 18.5300912 },
    { lat: 48.7619203, lng: 18.5300891 },
    { lat: 48.7618585, lng: 18.5303642 },
    { lat: 48.7617321, lng: 18.5303013 },
    { lat: 48.7617096, lng: 18.5302173 },
    { lat: 48.7616323, lng: 18.5301872 },
    { lat: 48.7615866, lng: 18.5303027 },
    { lat: 48.7615603, lng: 18.5305005 },
    { lat: 48.7614177, lng: 18.5304085 },
    { lat: 48.761166, lng: 18.5297457 },
    { lat: 48.761062, lng: 18.5296768 },
    { lat: 48.7610198, lng: 18.5296706 },
    { lat: 48.7608493, lng: 18.5299164 },
    { lat: 48.7607634, lng: 18.5299132 },
    { lat: 48.7606234, lng: 18.5297877 },
    { lat: 48.7604398, lng: 18.5296665 },
    { lat: 48.7603729, lng: 18.5295751 },
    { lat: 48.760392, lng: 18.5294111 },
    { lat: 48.7604304, lng: 18.5293091 },
    { lat: 48.760443, lng: 18.5292267 },
    { lat: 48.7604423, lng: 18.5291982 },
    { lat: 48.760301, lng: 18.5290857 },
    { lat: 48.7602518, lng: 18.5291369 },
    { lat: 48.7602377, lng: 18.5291866 },
    { lat: 48.7600826, lng: 18.5294065 },
    { lat: 48.7599748, lng: 18.5294543 },
    { lat: 48.7598773, lng: 18.5295291 },
    { lat: 48.759787, lng: 18.5294853 },
    { lat: 48.7597041, lng: 18.5294098 },
    { lat: 48.7596741, lng: 18.5292924 },
    { lat: 48.7596058, lng: 18.5292201 },
    { lat: 48.759412, lng: 18.5292239 },
    { lat: 48.759375, lng: 18.5293133 },
    { lat: 48.7593814, lng: 18.5294076 },
    { lat: 48.7593378, lng: 18.5294588 },
    { lat: 48.7590513, lng: 18.5294745 },
    { lat: 48.7590557, lng: 18.5295394 },
    { lat: 48.7590323, lng: 18.5295431 },
    { lat: 48.7590316, lng: 18.5296332 },
    { lat: 48.7588292, lng: 18.5296245 },
    { lat: 48.7587228, lng: 18.529823 },
    { lat: 48.7586295, lng: 18.5299105 },
    { lat: 48.7585889, lng: 18.5300156 },
    { lat: 48.7584998, lng: 18.530015 },
    { lat: 48.7583437, lng: 18.5300277 },
    { lat: 48.7582636, lng: 18.5301099 },
    { lat: 48.7582256, lng: 18.5303874 },
    { lat: 48.7580334, lng: 18.5304171 },
    { lat: 48.7578024, lng: 18.5303419 },
    { lat: 48.7577563, lng: 18.5303645 },
    { lat: 48.7577163, lng: 18.5304971 },
    { lat: 48.7576704, lng: 18.5305486 },
    { lat: 48.7576076, lng: 18.5305328 },
    { lat: 48.7575651, lng: 18.5304173 },
    { lat: 48.7574745, lng: 18.5303464 },
    { lat: 48.7573742, lng: 18.53037 },
    { lat: 48.7572082, lng: 18.5301391 },
    { lat: 48.757193, lng: 18.5300695 },
    { lat: 48.7571163, lng: 18.5299625 },
    { lat: 48.7570458, lng: 18.5299538 },
    { lat: 48.7569838, lng: 18.5300387 },
    { lat: 48.7569678, lng: 18.5301348 },
    { lat: 48.7568832, lng: 18.5302608 },
    { lat: 48.7567869, lng: 18.5302499 },
    { lat: 48.7566651, lng: 18.530194 },
    { lat: 48.7565857, lng: 18.5302028 },
    { lat: 48.7565452, lng: 18.5302479 },
    { lat: 48.7564338, lng: 18.5304166 },
    { lat: 48.7563778, lng: 18.5303811 },
    { lat: 48.7563194, lng: 18.5301389 },
    { lat: 48.7561825, lng: 18.5300087 },
    { lat: 48.755918, lng: 18.5301421 },
    { lat: 48.7558322, lng: 18.5300392 },
    { lat: 48.7557162, lng: 18.5299793 },
    { lat: 48.7554624, lng: 18.5298151 },
    { lat: 48.7553972, lng: 18.529716 },
    { lat: 48.7553128, lng: 18.529699 },
    { lat: 48.7548768, lng: 18.5298957 },
    { lat: 48.7548469, lng: 18.5300067 },
    { lat: 48.754802, lng: 18.5300548 },
    { lat: 48.7545931, lng: 18.5300512 },
    { lat: 48.7545625, lng: 18.5300268 },
    { lat: 48.7545368, lng: 18.5300497 },
    { lat: 48.754516, lng: 18.5301241 },
    { lat: 48.7544473, lng: 18.5301256 },
    { lat: 48.7544041, lng: 18.5300784 },
    { lat: 48.7543135, lng: 18.5300297 },
    { lat: 48.7542753, lng: 18.5299697 },
    { lat: 48.7542445, lng: 18.5298817 },
    { lat: 48.7542279, lng: 18.5298041 },
    { lat: 48.7541656, lng: 18.5297193 },
    { lat: 48.7541225, lng: 18.5297347 },
    { lat: 48.7539647, lng: 18.5300489 },
    { lat: 48.753822, lng: 18.5302015 },
    { lat: 48.7536377, lng: 18.5300897 },
    { lat: 48.7536276, lng: 18.5300692 },
    { lat: 48.7535709, lng: 18.5300445 },
    { lat: 48.7535102, lng: 18.5301103 },
    { lat: 48.7532778, lng: 18.5302741 },
    { lat: 48.7530501, lng: 18.5301545 },
    { lat: 48.7529983, lng: 18.5301542 },
    { lat: 48.75291, lng: 18.530191 },
    { lat: 48.7529023, lng: 18.5301762 },
    { lat: 48.7529098, lng: 18.5300448 },
    { lat: 48.7528605, lng: 18.5299244 },
    { lat: 48.7524992, lng: 18.529858 },
    { lat: 48.7523291, lng: 18.5297991 },
    { lat: 48.7521618, lng: 18.5298347 },
    { lat: 48.751932, lng: 18.5300239 },
    { lat: 48.7517683, lng: 18.5302516 },
    { lat: 48.7517047, lng: 18.5302428 },
    { lat: 48.7516061, lng: 18.5303148 },
    { lat: 48.7514553, lng: 18.5304884 },
    { lat: 48.7513442, lng: 18.5305394 },
    { lat: 48.7512513, lng: 18.5307609 },
    { lat: 48.7511907, lng: 18.5307845 },
    { lat: 48.7510087, lng: 18.530656 },
    { lat: 48.7509447, lng: 18.5306601 },
    { lat: 48.7508918, lng: 18.5307053 },
    { lat: 48.7508732, lng: 18.5307973 },
    { lat: 48.7508191, lng: 18.530837 },
    { lat: 48.7507131, lng: 18.5308398 },
    { lat: 48.7506464, lng: 18.5308986 },
    { lat: 48.7504043, lng: 18.5310099 },
    { lat: 48.7503629, lng: 18.5310966 },
    { lat: 48.7503999, lng: 18.5313018 },
    { lat: 48.7504641, lng: 18.5314428 },
    { lat: 48.7504679, lng: 18.5315139 },
    { lat: 48.7503967, lng: 18.5315949 },
    { lat: 48.7503538, lng: 18.5315921 },
    { lat: 48.75024, lng: 18.5316774 },
    { lat: 48.7501872, lng: 18.5317844 },
    { lat: 48.7501976, lng: 18.5320391 },
    { lat: 48.7502603, lng: 18.5321521 },
    { lat: 48.7502766, lng: 18.5321613 },
    { lat: 48.7503671, lng: 18.532291 },
    { lat: 48.7503891, lng: 18.5323859 },
    { lat: 48.7503502, lng: 18.5324962 },
    { lat: 48.75031, lng: 18.5325476 },
    { lat: 48.7502523, lng: 18.532522 },
    { lat: 48.7502582, lng: 18.5324444 },
    { lat: 48.7502525, lng: 18.5324258 },
    { lat: 48.7501804, lng: 18.5323651 },
    { lat: 48.7501261, lng: 18.5323836 },
    { lat: 48.7500326, lng: 18.5323857 },
    { lat: 48.7499954, lng: 18.5324664 },
    { lat: 48.749998, lng: 18.5326177 },
    { lat: 48.7499201, lng: 18.5328229 },
    { lat: 48.7498196, lng: 18.5328615 },
    { lat: 48.7497821, lng: 18.5329409 },
    { lat: 48.7497495, lng: 18.5330672 },
    { lat: 48.7497228, lng: 18.5333615 },
    { lat: 48.7496532, lng: 18.5334506 },
    { lat: 48.7494497, lng: 18.5335243 },
    { lat: 48.7493331, lng: 18.5335578 },
    { lat: 48.7492212, lng: 18.5336393 },
    { lat: 48.7490581, lng: 18.5333569 },
    { lat: 48.7490219, lng: 18.533335 },
    { lat: 48.7489997, lng: 18.5333399 },
    { lat: 48.7489341, lng: 18.5334835 },
    { lat: 48.7488893, lng: 18.5338802 },
    { lat: 48.7488339, lng: 18.5340043 },
    { lat: 48.7487447, lng: 18.5340201 },
    { lat: 48.7486039, lng: 18.5340033 },
    { lat: 48.7485101, lng: 18.5340225 },
    { lat: 48.7484422, lng: 18.53406 },
    { lat: 48.7480809, lng: 18.5341572 },
    { lat: 48.7477958, lng: 18.5343472 },
    { lat: 48.7477601, lng: 18.5346247 },
    { lat: 48.7475854, lng: 18.535084 },
    { lat: 48.7475153, lng: 18.535181 },
    { lat: 48.7474689, lng: 18.5353437 },
    { lat: 48.7473609, lng: 18.5354519 },
    { lat: 48.747011, lng: 18.5352611 },
    { lat: 48.7468494, lng: 18.5353188 },
    { lat: 48.74681, lng: 18.5352815 },
    { lat: 48.7468108, lng: 18.5353336 },
    { lat: 48.7467244, lng: 18.5355313 },
    { lat: 48.7464438, lng: 18.5355141 },
    { lat: 48.7463405, lng: 18.5355435 },
    { lat: 48.7462842, lng: 18.5354608 },
    { lat: 48.7462261, lng: 18.5354916 },
    { lat: 48.7461639, lng: 18.5356566 },
    { lat: 48.7460705, lng: 18.5357029 },
    { lat: 48.7459356, lng: 18.535708 },
    { lat: 48.7457831, lng: 18.5356426 },
    { lat: 48.7457459, lng: 18.5356673 },
    { lat: 48.7456734, lng: 18.5360109 },
    { lat: 48.7455835, lng: 18.5360786 },
    { lat: 48.7454596, lng: 18.5365583 },
    { lat: 48.7455275, lng: 18.5366631 },
    { lat: 48.7459094, lng: 18.5371127 },
    { lat: 48.7460957, lng: 18.5373225 },
    { lat: 48.7463078, lng: 18.5374054 },
    { lat: 48.7466082, lng: 18.5375145 },
    { lat: 48.7467524, lng: 18.537574 },
    { lat: 48.7476052, lng: 18.5384842 },
    { lat: 48.7482377, lng: 18.5388311 },
    { lat: 48.7484509, lng: 18.5390622 },
    { lat: 48.74866, lng: 18.5391723 },
    { lat: 48.748899, lng: 18.539437 },
    { lat: 48.748945, lng: 18.5395353 },
    { lat: 48.749135, lng: 18.5398122 },
    { lat: 48.749246, lng: 18.5405697 },
    { lat: 48.749329, lng: 18.5410962 },
    { lat: 48.749339, lng: 18.5411354 },
    { lat: 48.7495194, lng: 18.5414057 },
    { lat: 48.7496833, lng: 18.5416161 },
    { lat: 48.7498369, lng: 18.5417443 },
    { lat: 48.7501842, lng: 18.5420942 },
    { lat: 48.7508612, lng: 18.5421596 },
    { lat: 48.7513535, lng: 18.54248 },
    { lat: 48.7523744, lng: 18.5425676 },
    { lat: 48.7527275, lng: 18.5425454 },
    { lat: 48.7527611, lng: 18.5425627 },
    { lat: 48.7531118, lng: 18.5427045 },
    { lat: 48.7532272, lng: 18.5427916 },
    { lat: 48.7533687, lng: 18.5428426 },
    { lat: 48.7536193, lng: 18.5429909 },
    { lat: 48.7542049, lng: 18.5434927 },
    { lat: 48.7546391, lng: 18.5438232 },
    { lat: 48.7550159, lng: 18.544053 },
    { lat: 48.7552719, lng: 18.5441959 },
    { lat: 48.7555123, lng: 18.5443014 },
    { lat: 48.7560315, lng: 18.5444942 },
    { lat: 48.7562244, lng: 18.5445377 },
    { lat: 48.7572673, lng: 18.5449426 },
    { lat: 48.7575884, lng: 18.5450188 },
    { lat: 48.7578999, lng: 18.5452273 },
    { lat: 48.7580318, lng: 18.5452703 },
    { lat: 48.7583011, lng: 18.5455924 },
    { lat: 48.7587467, lng: 18.5460721 },
    { lat: 48.7598248, lng: 18.5470025 },
    { lat: 48.7602352, lng: 18.5475366 },
    { lat: 48.7605475, lng: 18.5480941 },
    { lat: 48.7608427, lng: 18.5491838 },
    { lat: 48.7612414, lng: 18.5498006 },
    { lat: 48.7615632, lng: 18.5507401 },
    { lat: 48.7620449, lng: 18.5518403 },
    { lat: 48.7625028, lng: 18.5529793 },
    { lat: 48.7629243, lng: 18.5534292 },
    { lat: 48.7629956, lng: 18.5535358 },
    { lat: 48.7631827, lng: 18.5537209 },
    { lat: 48.7633331, lng: 18.553832 },
    { lat: 48.7634187, lng: 18.5538714 },
    { lat: 48.7634533, lng: 18.5534506 },
    { lat: 48.7638328, lng: 18.5532317 },
    { lat: 48.7639055, lng: 18.5528265 },
    { lat: 48.7638628, lng: 18.552331 },
    { lat: 48.7638792, lng: 18.5521984 },
    { lat: 48.7639577, lng: 18.5519391 },
    { lat: 48.7640517, lng: 18.5516972 },
    { lat: 48.7640316, lng: 18.5515155 },
    { lat: 48.7641382, lng: 18.5514029 },
    { lat: 48.7642501, lng: 18.5511991 },
    { lat: 48.7641845, lng: 18.5509455 },
    { lat: 48.7642553, lng: 18.5507955 },
    { lat: 48.7643787, lng: 18.5504526 },
    { lat: 48.7645665, lng: 18.5503159 },
    { lat: 48.7647431, lng: 18.5502474 },
    { lat: 48.7650173, lng: 18.5499788 },
    { lat: 48.765147, lng: 18.5498328 },
    { lat: 48.7652785, lng: 18.5498036 },
    { lat: 48.7653992, lng: 18.5497414 },
    { lat: 48.7655024, lng: 18.5496491 },
    { lat: 48.7656002, lng: 18.5495397 },
    { lat: 48.7658226, lng: 18.5493132 },
    { lat: 48.765895, lng: 18.5491315 },
    { lat: 48.7659627, lng: 18.5489418 },
    { lat: 48.7660125, lng: 18.5487815 },
    { lat: 48.7660628, lng: 18.548649 },
    { lat: 48.7662646, lng: 18.5483797 },
    { lat: 48.7663851, lng: 18.548227 },
    { lat: 48.7665031, lng: 18.548119 },
    { lat: 48.7668065, lng: 18.5478685 },
    { lat: 48.7669582, lng: 18.5477127 },
    { lat: 48.7670281, lng: 18.5476496 },
    { lat: 48.7672168, lng: 18.5476776 },
    { lat: 48.7672926, lng: 18.5475763 },
    { lat: 48.7672515, lng: 18.5471738 },
    { lat: 48.7673261, lng: 18.5468838 },
    { lat: 48.7673983, lng: 18.5467822 },
    { lat: 48.7675023, lng: 18.5466827 },
    { lat: 48.76775, lng: 18.5463658 },
    { lat: 48.7678229, lng: 18.5462098 },
    { lat: 48.7680333, lng: 18.5459948 },
    { lat: 48.7681596, lng: 18.5458474 },
    { lat: 48.7682313, lng: 18.5457763 },
    { lat: 48.7683384, lng: 18.5456324 },
    { lat: 48.768437, lng: 18.5455376 },
    { lat: 48.7685495, lng: 18.54555 },
    { lat: 48.7687517, lng: 18.5457004 },
    { lat: 48.7688464, lng: 18.5456375 },
    { lat: 48.7688798, lng: 18.5456308 },
    { lat: 48.7689389, lng: 18.5456796 },
    { lat: 48.7689571, lng: 18.5457048 },
    { lat: 48.7690417, lng: 18.5457433 },
    { lat: 48.7691187, lng: 18.5456911 },
    { lat: 48.7693106, lng: 18.5456068 },
    { lat: 48.7693687, lng: 18.545493 },
    { lat: 48.7696213, lng: 18.5453886 },
    { lat: 48.7697648, lng: 18.5454354 },
    { lat: 48.7698485, lng: 18.5453757 },
    { lat: 48.7699144, lng: 18.5453667 },
    { lat: 48.7701347, lng: 18.5452495 },
    { lat: 48.7701737, lng: 18.5451964 },
    { lat: 48.7702998, lng: 18.5451507 },
    { lat: 48.7703819, lng: 18.5451708 },
    { lat: 48.7705865, lng: 18.5450119 },
    { lat: 48.7706761, lng: 18.544935 },
    { lat: 48.7707137, lng: 18.5449214 },
    { lat: 48.770783, lng: 18.5449739 },
    { lat: 48.7707972, lng: 18.5449657 },
    { lat: 48.7708877, lng: 18.5450084 },
    { lat: 48.7710143, lng: 18.5451603 },
    { lat: 48.7711204, lng: 18.5451192 },
    { lat: 48.7712112, lng: 18.5450682 },
    { lat: 48.7713037, lng: 18.5450692 },
    { lat: 48.7715648, lng: 18.5449129 },
    { lat: 48.7716387, lng: 18.5449241 },
    { lat: 48.7717213, lng: 18.5450508 },
    { lat: 48.7718988, lng: 18.545142 },
    { lat: 48.7719671, lng: 18.5451335 },
    { lat: 48.772039, lng: 18.5451685 },
    { lat: 48.7721306, lng: 18.5452376 },
    { lat: 48.7722172, lng: 18.5452729 },
    { lat: 48.7722735, lng: 18.545208 },
    { lat: 48.772308, lng: 18.5452219 },
    { lat: 48.7723463, lng: 18.5452618 },
    { lat: 48.7724142, lng: 18.5452888 },
    { lat: 48.7725034, lng: 18.5453503 },
    { lat: 48.7726648, lng: 18.5453101 },
    { lat: 48.7728341, lng: 18.5453123 },
    { lat: 48.7729108, lng: 18.5452923 },
    { lat: 48.773477, lng: 18.5456241 },
    { lat: 48.7738024, lng: 18.5456747 },
    { lat: 48.7738335, lng: 18.5457088 },
    { lat: 48.7738646, lng: 18.5457189 },
    { lat: 48.7742687, lng: 18.5459313 },
    { lat: 48.7746645, lng: 18.5465708 },
    { lat: 48.7747533, lng: 18.5467532 },
    { lat: 48.7748109, lng: 18.5467546 },
    { lat: 48.774877, lng: 18.5467882 },
    { lat: 48.7749363, lng: 18.5468409 },
    { lat: 48.7749992, lng: 18.5468749 },
    { lat: 48.7751078, lng: 18.5470473 },
    { lat: 48.7751873, lng: 18.5471222 },
    { lat: 48.7752359, lng: 18.5471422 },
    { lat: 48.7753038, lng: 18.5472736 },
    { lat: 48.7753545, lng: 18.5474361 },
    { lat: 48.7754342, lng: 18.5475549 },
    { lat: 48.7755958, lng: 18.5476617 },
    { lat: 48.7758267, lng: 18.5477406 },
    { lat: 48.7758913, lng: 18.547788 },
    { lat: 48.7760418, lng: 18.5478569 },
    { lat: 48.7762469, lng: 18.5478732 },
    { lat: 48.776336, lng: 18.5479164 },
    { lat: 48.7764221, lng: 18.5480051 },
    { lat: 48.776733, lng: 18.5482275 },
    { lat: 48.7768663, lng: 18.548272 },
    { lat: 48.7770469, lng: 18.5482324 },
    { lat: 48.7771078, lng: 18.5481926 },
    { lat: 48.777122, lng: 18.5482051 },
    { lat: 48.7771532, lng: 18.5482581 },
    { lat: 48.7772686, lng: 18.5484097 },
    { lat: 48.7773854, lng: 18.5486279 },
    { lat: 48.7777438, lng: 18.548956 },
    { lat: 48.7779666, lng: 18.5489225 },
    { lat: 48.7780134, lng: 18.5491409 },
    { lat: 48.7781543, lng: 18.5493497 },
    { lat: 48.7782437, lng: 18.5494162 },
    { lat: 48.7782417, lng: 18.5496049 },
    { lat: 48.7783412, lng: 18.5498182 },
    { lat: 48.7785717, lng: 18.550219 },
    { lat: 48.778621, lng: 18.5502773 },
    { lat: 48.7787491, lng: 18.5507037 },
    { lat: 48.7788202, lng: 18.5512846 },
    { lat: 48.7790428, lng: 18.551788 },
    { lat: 48.7792523, lng: 18.5522812 },
    { lat: 48.7793339, lng: 18.5524352 },
    { lat: 48.779415, lng: 18.5525564 },
    { lat: 48.7794993, lng: 18.5526124 },
    { lat: 48.7796049, lng: 18.5528975 },
    { lat: 48.7798919, lng: 18.5532774 },
    { lat: 48.7799901, lng: 18.5529873 },
    { lat: 48.780296, lng: 18.5533069 },
    { lat: 48.780325, lng: 18.5532342 },
    { lat: 48.780467, lng: 18.5529641 },
    { lat: 48.7808169, lng: 18.5522016 },
    { lat: 48.7809113, lng: 18.5519899 },
    { lat: 48.7809604, lng: 18.5518093 },
    { lat: 48.7809826, lng: 18.5516409 },
    { lat: 48.7810616, lng: 18.5514755 },
    { lat: 48.7811637, lng: 18.5510723 },
    { lat: 48.7813459, lng: 18.5505009 },
    { lat: 48.7814345, lng: 18.5501176 },
    { lat: 48.7814644, lng: 18.5500033 },
    { lat: 48.781497, lng: 18.5498151 },
    { lat: 48.7811251, lng: 18.5495095 },
    { lat: 48.7812354, lng: 18.5491116 },
    { lat: 48.7812805, lng: 18.5489028 },
    { lat: 48.7813049, lng: 18.548565 },
    { lat: 48.7813822, lng: 18.5469359 },
    { lat: 48.7814688, lng: 18.5465385 },
    { lat: 48.7815461, lng: 18.5452009 },
    { lat: 48.7816949, lng: 18.5451798 },
    { lat: 48.7816895, lng: 18.5448303 },
    { lat: 48.7816882, lng: 18.5441853 },
    { lat: 48.7816916, lng: 18.5431487 },
    { lat: 48.7817404, lng: 18.5422653 },
    { lat: 48.7819088, lng: 18.5415676 },
    { lat: 48.782078, lng: 18.5407764 },
    { lat: 48.7821359, lng: 18.5408071 },
    { lat: 48.7835712, lng: 18.541273 },
    { lat: 48.7838894, lng: 18.5413352 },
    { lat: 48.7843776, lng: 18.5412068 },
    { lat: 48.7846237, lng: 18.5408166 },
    { lat: 48.7847213, lng: 18.54004 },
    { lat: 48.7855174, lng: 18.5396235 },
    { lat: 48.7852199, lng: 18.5394216 },
    { lat: 48.7850123, lng: 18.5395241 },
    { lat: 48.784706, lng: 18.5395112 },
    { lat: 48.7844093, lng: 18.5395929 },
    { lat: 48.7842336, lng: 18.53954 },
    { lat: 48.7838431, lng: 18.5392429 },
    { lat: 48.7836846, lng: 18.5390888 },
    { lat: 48.7834672, lng: 18.5389911 },
    { lat: 48.7833039, lng: 18.5389954 },
    { lat: 48.7830701, lng: 18.5389258 },
  ],
  KostolnáVes: [
    { lat: 48.7961291, lng: 18.517184 },
    { lat: 48.7964382, lng: 18.5146219 },
    { lat: 48.7964424, lng: 18.5145996 },
    { lat: 48.7967039, lng: 18.5146573 },
    { lat: 48.7971182, lng: 18.5148079 },
    { lat: 48.7974274, lng: 18.5148508 },
    { lat: 48.7978528, lng: 18.5149363 },
    { lat: 48.7980195, lng: 18.5149111 },
    { lat: 48.7981789, lng: 18.5148565 },
    { lat: 48.7982544, lng: 18.5148116 },
    { lat: 48.7984328, lng: 18.5147971 },
    { lat: 48.7984466, lng: 18.5147596 },
    { lat: 48.7984483, lng: 18.5147469 },
    { lat: 48.7984583, lng: 18.514722 },
    { lat: 48.7984626, lng: 18.5147021 },
    { lat: 48.7985733, lng: 18.5143924 },
    { lat: 48.7986789, lng: 18.5140747 },
    { lat: 48.7998928, lng: 18.5113542 },
    { lat: 48.8000196, lng: 18.5107469 },
    { lat: 48.8001986, lng: 18.5105548 },
    { lat: 48.8003201, lng: 18.5105496 },
    { lat: 48.8003893, lng: 18.5104325 },
    { lat: 48.8003502, lng: 18.5103347 },
    { lat: 48.8004441, lng: 18.5103197 },
    { lat: 48.8005165, lng: 18.5103626 },
    { lat: 48.8006348, lng: 18.5103227 },
    { lat: 48.800681, lng: 18.5102378 },
    { lat: 48.8006904, lng: 18.5102026 },
    { lat: 48.8006794, lng: 18.5101478 },
    { lat: 48.8006986, lng: 18.5100631 },
    { lat: 48.8008154, lng: 18.5099505 },
    { lat: 48.8008914, lng: 18.5100003 },
    { lat: 48.8009948, lng: 18.509973 },
    { lat: 48.8010033, lng: 18.5098607 },
    { lat: 48.8010408, lng: 18.509763 },
    { lat: 48.8011281, lng: 18.509751 },
    { lat: 48.8011784, lng: 18.5098463 },
    { lat: 48.8012646, lng: 18.5098533 },
    { lat: 48.801291, lng: 18.5097559 },
    { lat: 48.8013721, lng: 18.5097364 },
    { lat: 48.8015322, lng: 18.509754 },
    { lat: 48.8016868, lng: 18.509819 },
    { lat: 48.8017298, lng: 18.509819 },
    { lat: 48.8018595, lng: 18.5097394 },
    { lat: 48.8019708, lng: 18.5098342 },
    { lat: 48.8020364, lng: 18.5098145 },
    { lat: 48.8021779, lng: 18.5096798 },
    { lat: 48.8022458, lng: 18.5096845 },
    { lat: 48.8022784, lng: 18.5097448 },
    { lat: 48.8023289, lng: 18.5097224 },
    { lat: 48.8023505, lng: 18.5097025 },
    { lat: 48.8023897, lng: 18.5096149 },
    { lat: 48.8025268, lng: 18.5096675 },
    { lat: 48.8025644, lng: 18.5097151 },
    { lat: 48.8026386, lng: 18.5097308 },
    { lat: 48.8026514, lng: 18.5096379 },
    { lat: 48.8028179, lng: 18.5086806 },
    { lat: 48.8029592, lng: 18.507841 },
    { lat: 48.8029824, lng: 18.507686 },
    { lat: 48.8030516, lng: 18.5073012 },
    { lat: 48.8030949, lng: 18.5070213 },
    { lat: 48.8031669, lng: 18.506419 },
    { lat: 48.8031686, lng: 18.5063465 },
    { lat: 48.8032048, lng: 18.5059366 },
    { lat: 48.8032428, lng: 18.5056845 },
    { lat: 48.8033816, lng: 18.5052717 },
    { lat: 48.8021881, lng: 18.5040961 },
    { lat: 48.8022895, lng: 18.5035613 },
    { lat: 48.8025759, lng: 18.5019319 },
    { lat: 48.8024778, lng: 18.5015993 },
    { lat: 48.8032709, lng: 18.5011097 },
    { lat: 48.804961, lng: 18.5000648 },
    { lat: 48.8052029, lng: 18.4998297 },
    { lat: 48.8052927, lng: 18.4997554 },
    { lat: 48.8062484, lng: 18.4989405 },
    { lat: 48.806343, lng: 18.4988556 },
    { lat: 48.8065961, lng: 18.4983675 },
    { lat: 48.8070712, lng: 18.4990096 },
    { lat: 48.8077083, lng: 18.4983258 },
    { lat: 48.8081162, lng: 18.4978834 },
    { lat: 48.8084284, lng: 18.4976316 },
    { lat: 48.8084168, lng: 18.4977319 },
    { lat: 48.8083753, lng: 18.4979192 },
    { lat: 48.8083641, lng: 18.4980846 },
    { lat: 48.8084755, lng: 18.498432 },
    { lat: 48.8085757, lng: 18.4984921 },
    { lat: 48.8086672, lng: 18.4984319 },
    { lat: 48.808779, lng: 18.4984966 },
    { lat: 48.8088414, lng: 18.4987067 },
    { lat: 48.8088376, lng: 18.4989867 },
    { lat: 48.8087858, lng: 18.4992369 },
    { lat: 48.8087005, lng: 18.4992845 },
    { lat: 48.8086281, lng: 18.4995298 },
    { lat: 48.8086745, lng: 18.4997598 },
    { lat: 48.8086418, lng: 18.50013 },
    { lat: 48.8086015, lng: 18.5004027 },
    { lat: 48.8086549, lng: 18.500575 },
    { lat: 48.8088887, lng: 18.5008269 },
    { lat: 48.8089098, lng: 18.5009445 },
    { lat: 48.8088778, lng: 18.5011622 },
    { lat: 48.8089896, lng: 18.5012873 },
    { lat: 48.8091782, lng: 18.5013943 },
    { lat: 48.8093239, lng: 18.5013363 },
    { lat: 48.8095417, lng: 18.5015458 },
    { lat: 48.8096234, lng: 18.5015957 },
    { lat: 48.8096714, lng: 18.501668 },
    { lat: 48.8097105, lng: 18.5019079 },
    { lat: 48.809827, lng: 18.5020805 },
    { lat: 48.8099836, lng: 18.5021173 },
    { lat: 48.8101856, lng: 18.501951 },
    { lat: 48.8103912, lng: 18.5017738 },
    { lat: 48.810519, lng: 18.5017363 },
    { lat: 48.8106365, lng: 18.5016607 },
    { lat: 48.8107171, lng: 18.5015425 },
    { lat: 48.8108612, lng: 18.5016228 },
    { lat: 48.8109323, lng: 18.5017048 },
    { lat: 48.8110012, lng: 18.5018321 },
    { lat: 48.8109973, lng: 18.502067 },
    { lat: 48.8110323, lng: 18.5022319 },
    { lat: 48.8110685, lng: 18.5022968 },
    { lat: 48.8111128, lng: 18.5023242 },
    { lat: 48.8111752, lng: 18.5022691 },
    { lat: 48.8112528, lng: 18.5023041 },
    { lat: 48.8112903, lng: 18.502454 },
    { lat: 48.8112332, lng: 18.5025639 },
    { lat: 48.8111517, lng: 18.5027842 },
    { lat: 48.8111699, lng: 18.5028492 },
    { lat: 48.8111974, lng: 18.5028819 },
    { lat: 48.8112697, lng: 18.5029014 },
    { lat: 48.8113161, lng: 18.5029464 },
    { lat: 48.8113382, lng: 18.5031863 },
    { lat: 48.8113816, lng: 18.5033186 },
    { lat: 48.8113333, lng: 18.5034038 },
    { lat: 48.8113334, lng: 18.5034689 },
    { lat: 48.8113665, lng: 18.5035564 },
    { lat: 48.8114294, lng: 18.5036558 },
    { lat: 48.8114828, lng: 18.5037858 },
    { lat: 48.8114875, lng: 18.5039134 },
    { lat: 48.8114514, lng: 18.5040786 },
    { lat: 48.811516, lng: 18.5042484 },
    { lat: 48.8116092, lng: 18.5044053 },
    { lat: 48.8117209, lng: 18.5044252 },
    { lat: 48.8118324, lng: 18.5044624 },
    { lat: 48.8119528, lng: 18.5045643 },
    { lat: 48.8119681, lng: 18.5046741 },
    { lat: 48.8120257, lng: 18.5049238 },
    { lat: 48.8120925, lng: 18.5050514 },
    { lat: 48.812188, lng: 18.5052119 },
    { lat: 48.8122597, lng: 18.5055322 },
    { lat: 48.8122782, lng: 18.5056419 },
    { lat: 48.8123958, lng: 18.5056264 },
    { lat: 48.8124834, lng: 18.5055986 },
    { lat: 48.812534, lng: 18.5057637 },
    { lat: 48.8124866, lng: 18.5059089 },
    { lat: 48.812504, lng: 18.5060792 },
    { lat: 48.8125777, lng: 18.5061899 },
    { lat: 48.8126329, lng: 18.5059206 },
    { lat: 48.8126623, lng: 18.5056939 },
    { lat: 48.8127122, lng: 18.5051025 },
    { lat: 48.8126951, lng: 18.5046469 },
    { lat: 48.8126864, lng: 18.5042593 },
    { lat: 48.8127172, lng: 18.5034831 },
    { lat: 48.8127294, lng: 18.5028602 },
    { lat: 48.8127317, lng: 18.5025525 },
    { lat: 48.81278, lng: 18.5016213 },
    { lat: 48.8128875, lng: 18.5005974 },
    { lat: 48.8129075, lng: 18.5002213 },
    { lat: 48.8129774, lng: 18.4996248 },
    { lat: 48.8130463, lng: 18.4992339 },
    { lat: 48.8130994, lng: 18.4988432 },
    { lat: 48.8131399, lng: 18.4985958 },
    { lat: 48.8132971, lng: 18.4986637 },
    { lat: 48.8133213, lng: 18.4987176 },
    { lat: 48.8133935, lng: 18.4987798 },
    { lat: 48.8135727, lng: 18.4988156 },
    { lat: 48.8136137, lng: 18.4987822 },
    { lat: 48.8139741, lng: 18.4989144 },
    { lat: 48.8142772, lng: 18.4990975 },
    { lat: 48.8143281, lng: 18.4988699 },
    { lat: 48.814428, lng: 18.4983291 },
    { lat: 48.8145658, lng: 18.4977721 },
    { lat: 48.8146226, lng: 18.4974328 },
    { lat: 48.8147042, lng: 18.4970453 },
    { lat: 48.8148192, lng: 18.4966339 },
    { lat: 48.8149137, lng: 18.4963611 },
    { lat: 48.8153888, lng: 18.4957699 },
    { lat: 48.8156295, lng: 18.4954271 },
    { lat: 48.8159115, lng: 18.495018 },
    { lat: 48.8164076, lng: 18.4942709 },
    { lat: 48.8167987, lng: 18.4937944 },
    { lat: 48.8172395, lng: 18.4932944 },
    { lat: 48.8175067, lng: 18.4929419 },
    { lat: 48.8175913, lng: 18.4926739 },
    { lat: 48.8176948, lng: 18.4922768 },
    { lat: 48.817792, lng: 18.4918508 },
    { lat: 48.817893, lng: 18.491468 },
    { lat: 48.8180315, lng: 18.4913224 },
    { lat: 48.818175, lng: 18.4909353 },
    { lat: 48.8182253, lng: 18.4906583 },
    { lat: 48.8182061, lng: 18.4905792 },
    { lat: 48.8182405, lng: 18.4904223 },
    { lat: 48.8182482, lng: 18.4902352 },
    { lat: 48.8183146, lng: 18.4902516 },
    { lat: 48.8184304, lng: 18.4904496 },
    { lat: 48.8185149, lng: 18.4904687 },
    { lat: 48.8186895, lng: 18.490731 },
    { lat: 48.8187849, lng: 18.4908285 },
    { lat: 48.8188069, lng: 18.4908977 },
    { lat: 48.8188637, lng: 18.4909235 },
    { lat: 48.8190182, lng: 18.4909259 },
    { lat: 48.8191149, lng: 18.4909187 },
    { lat: 48.8195195, lng: 18.4909738 },
    { lat: 48.8196313, lng: 18.4909779 },
    { lat: 48.8198553, lng: 18.4909666 },
    { lat: 48.8198961, lng: 18.4907671 },
    { lat: 48.8198024, lng: 18.490601 },
    { lat: 48.8197687, lng: 18.4902115 },
    { lat: 48.8196589, lng: 18.4899804 },
    { lat: 48.819615, lng: 18.4897322 },
    { lat: 48.81955, lng: 18.4895553 },
    { lat: 48.8194721, lng: 18.4890843 },
    { lat: 48.8193993, lng: 18.488558 },
    { lat: 48.8193816, lng: 18.4881758 },
    { lat: 48.8194893, lng: 18.4878326 },
    { lat: 48.8194835, lng: 18.487689 },
    { lat: 48.8195213, lng: 18.4875729 },
    { lat: 48.8195369, lng: 18.4874253 },
    { lat: 48.8195427, lng: 18.4869982 },
    { lat: 48.8197274, lng: 18.4866843 },
    { lat: 48.8198014, lng: 18.4866328 },
    { lat: 48.8198656, lng: 18.4865102 },
    { lat: 48.8199253, lng: 18.4862639 },
    { lat: 48.8199262, lng: 18.4854966 },
    { lat: 48.8198009, lng: 18.4852082 },
    { lat: 48.8197583, lng: 18.4850689 },
    { lat: 48.8197198, lng: 18.4848404 },
    { lat: 48.8196541, lng: 18.4843425 },
    { lat: 48.8196483, lng: 18.4842362 },
    { lat: 48.819628, lng: 18.4841335 },
    { lat: 48.8195822, lng: 18.4831796 },
    { lat: 48.8195307, lng: 18.4820138 },
    { lat: 48.8195066, lng: 18.4815958 },
    { lat: 48.8195071, lng: 18.4815027 },
    { lat: 48.8188289, lng: 18.4817646 },
    { lat: 48.8185863, lng: 18.4819266 },
    { lat: 48.8181468, lng: 18.4820589 },
    { lat: 48.8181031, lng: 18.481797 },
    { lat: 48.8180065, lng: 18.4815568 },
    { lat: 48.8178362, lng: 18.481249 },
    { lat: 48.8177418, lng: 18.4809505 },
    { lat: 48.8174982, lng: 18.4803935 },
    { lat: 48.8173167, lng: 18.4798433 },
    { lat: 48.8169518, lng: 18.4798753 },
    { lat: 48.8167029, lng: 18.4800252 },
    { lat: 48.8168652, lng: 18.4803719 },
    { lat: 48.8170933, lng: 18.4807044 },
    { lat: 48.8174023, lng: 18.4809278 },
    { lat: 48.8177143, lng: 18.4812887 },
    { lat: 48.8179353, lng: 18.4817412 },
    { lat: 48.8180168, lng: 18.4821146 },
    { lat: 48.8182231, lng: 18.4824402 },
    { lat: 48.8184411, lng: 18.4827119 },
    { lat: 48.818563, lng: 18.4827542 },
    { lat: 48.8188483, lng: 18.483 },
    { lat: 48.8186972, lng: 18.4832685 },
    { lat: 48.8187374, lng: 18.4837943 },
    { lat: 48.8184204, lng: 18.4839581 },
    { lat: 48.8182837, lng: 18.4843264 },
    { lat: 48.818038, lng: 18.484575 },
    { lat: 48.8175961, lng: 18.484641 },
    { lat: 48.8169645, lng: 18.4847882 },
    { lat: 48.8167336, lng: 18.4848366 },
    { lat: 48.8160534, lng: 18.4850017 },
    { lat: 48.8155928, lng: 18.4851378 },
    { lat: 48.8153412, lng: 18.485217 },
    { lat: 48.8151403, lng: 18.4853601 },
    { lat: 48.8148453, lng: 18.4854928 },
    { lat: 48.8147913, lng: 18.4850214 },
    { lat: 48.8145588, lng: 18.4852261 },
    { lat: 48.8142509, lng: 18.4853922 },
    { lat: 48.8138181, lng: 18.4853995 },
    { lat: 48.8134299, lng: 18.4854763 },
    { lat: 48.8129528, lng: 18.4854999 },
    { lat: 48.8126359, lng: 18.4854814 },
    { lat: 48.8123036, lng: 18.4855497 },
    { lat: 48.8120332, lng: 18.4856579 },
    { lat: 48.8116399, lng: 18.4858319 },
    { lat: 48.8112384, lng: 18.4859759 },
    { lat: 48.81088, lng: 18.4861258 },
    { lat: 48.8105471, lng: 18.4861831 },
    { lat: 48.8099283, lng: 18.4862367 },
    { lat: 48.8096054, lng: 18.486292 },
    { lat: 48.8093205, lng: 18.486485 },
    { lat: 48.8090437, lng: 18.4865803 },
    { lat: 48.8087375, lng: 18.4867373 },
    { lat: 48.8085867, lng: 18.4870308 },
    { lat: 48.8083493, lng: 18.4877829 },
    { lat: 48.8080307, lng: 18.4875238 },
    { lat: 48.8077735, lng: 18.4872995 },
    { lat: 48.807568, lng: 18.4871089 },
    { lat: 48.8073254, lng: 18.4871298 },
    { lat: 48.8070656, lng: 18.4870597 },
    { lat: 48.8065691, lng: 18.487036 },
    { lat: 48.8056281, lng: 18.4871583 },
    { lat: 48.805232, lng: 18.487215 },
    { lat: 48.8048216, lng: 18.4873003 },
    { lat: 48.8043295, lng: 18.487335 },
    { lat: 48.8038718, lng: 18.4874237 },
    { lat: 48.8035662, lng: 18.4875506 },
    { lat: 48.8034565, lng: 18.487627 },
    { lat: 48.803395, lng: 18.4876569 },
    { lat: 48.8034126, lng: 18.4877524 },
    { lat: 48.8033854, lng: 18.4878303 },
    { lat: 48.8032946, lng: 18.4879455 },
    { lat: 48.8032431, lng: 18.4879934 },
    { lat: 48.8025488, lng: 18.4884529 },
    { lat: 48.8026002, lng: 18.488055 },
    { lat: 48.8026204, lng: 18.4877866 },
    { lat: 48.8026087, lng: 18.4872663 },
    { lat: 48.8023627, lng: 18.486752 },
    { lat: 48.8020107, lng: 18.4865837 },
    { lat: 48.8015846, lng: 18.4863647 },
    { lat: 48.8010064, lng: 18.4860635 },
    { lat: 48.800662, lng: 18.4858968 },
    { lat: 48.8003532, lng: 18.4857947 },
    { lat: 48.800024, lng: 18.4857195 },
    { lat: 48.7996923, lng: 18.485675 },
    { lat: 48.7994784, lng: 18.4857448 },
    { lat: 48.7993485, lng: 18.4858839 },
    { lat: 48.7992622, lng: 18.4861209 },
    { lat: 48.7991264, lng: 18.4864243 },
    { lat: 48.7987989, lng: 18.48699 },
    { lat: 48.7983853, lng: 18.4873265 },
    { lat: 48.7979772, lng: 18.4874759 },
    { lat: 48.7976989, lng: 18.4874179 },
    { lat: 48.797297, lng: 18.4871243 },
    { lat: 48.7968479, lng: 18.4871425 },
    { lat: 48.7961152, lng: 18.4874189 },
    { lat: 48.7959245, lng: 18.487502 },
    { lat: 48.7953681, lng: 18.4876185 },
    { lat: 48.7948464, lng: 18.4877743 },
    { lat: 48.7946247, lng: 18.4877828 },
    { lat: 48.7943794, lng: 18.4877758 },
    { lat: 48.7944061, lng: 18.4881789 },
    { lat: 48.7943549, lng: 18.488435 },
    { lat: 48.7939922, lng: 18.4898459 },
    { lat: 48.7939896, lng: 18.4910687 },
    { lat: 48.7935372, lng: 18.4923657 },
    { lat: 48.7935146, lng: 18.4924451 },
    { lat: 48.7935614, lng: 18.4924971 },
    { lat: 48.7935471, lng: 18.4926284 },
    { lat: 48.7935041, lng: 18.4927709 },
    { lat: 48.7934384, lng: 18.492806 },
    { lat: 48.7934202, lng: 18.4928655 },
    { lat: 48.7934437, lng: 18.4929624 },
    { lat: 48.7934918, lng: 18.4930379 },
    { lat: 48.7935764, lng: 18.4931199 },
    { lat: 48.7937062, lng: 18.4932889 },
    { lat: 48.7937104, lng: 18.493468 },
    { lat: 48.7937291, lng: 18.4935376 },
    { lat: 48.7938227, lng: 18.4936399 },
    { lat: 48.7938966, lng: 18.4936517 },
    { lat: 48.794124, lng: 18.493791 },
    { lat: 48.7942094, lng: 18.4939241 },
    { lat: 48.7942626, lng: 18.4939506 },
    { lat: 48.7942851, lng: 18.4940129 },
    { lat: 48.7941934, lng: 18.4942116 },
    { lat: 48.7941675, lng: 18.4943125 },
    { lat: 48.794219, lng: 18.4944495 },
    { lat: 48.7943185, lng: 18.4945171 },
    { lat: 48.7943751, lng: 18.4945801 },
    { lat: 48.7943987, lng: 18.4945793 },
    { lat: 48.7944502, lng: 18.4944487 },
    { lat: 48.7945337, lng: 18.4943463 },
    { lat: 48.7946006, lng: 18.4943729 },
    { lat: 48.7946732, lng: 18.4943824 },
    { lat: 48.7947708, lng: 18.4943733 },
    { lat: 48.7947748, lng: 18.4945278 },
    { lat: 48.7947662, lng: 18.494556 },
    { lat: 48.7947167, lng: 18.4945987 },
    { lat: 48.7946356, lng: 18.4946021 },
    { lat: 48.794494, lng: 18.49467 },
    { lat: 48.7944718, lng: 18.4947823 },
    { lat: 48.7943805, lng: 18.494968 },
    { lat: 48.7943459, lng: 18.4952632 },
    { lat: 48.7942185, lng: 18.495491 },
    { lat: 48.7942192, lng: 18.4957063 },
    { lat: 48.7942456, lng: 18.4957363 },
    { lat: 48.7943491, lng: 18.4957963 },
    { lat: 48.7943474, lng: 18.4959675 },
    { lat: 48.7943633, lng: 18.4960549 },
    { lat: 48.7942085, lng: 18.4963345 },
    { lat: 48.7942018, lng: 18.4963586 },
    { lat: 48.7942009, lng: 18.4963816 },
    { lat: 48.7942092, lng: 18.4964127 },
    { lat: 48.7943849, lng: 18.4965302 },
    { lat: 48.7944358, lng: 18.4965914 },
    { lat: 48.7945307, lng: 18.4966797 },
    { lat: 48.7946741, lng: 18.4969082 },
    { lat: 48.7946701, lng: 18.4971241 },
    { lat: 48.7946404, lng: 18.4971167 },
    { lat: 48.7945754, lng: 18.4970436 },
    { lat: 48.7945602, lng: 18.4970713 },
    { lat: 48.7945509, lng: 18.4974363 },
    { lat: 48.7945656, lng: 18.4974799 },
    { lat: 48.7945998, lng: 18.497467 },
    { lat: 48.7946505, lng: 18.4973823 },
    { lat: 48.7947459, lng: 18.4974362 },
    { lat: 48.7950184, lng: 18.4977164 },
    { lat: 48.7951684, lng: 18.4978572 },
    { lat: 48.7951894, lng: 18.4979937 },
    { lat: 48.7950089, lng: 18.4983659 },
    { lat: 48.7948679, lng: 18.4983257 },
    { lat: 48.7948089, lng: 18.4984615 },
    { lat: 48.7948112, lng: 18.4985031 },
    { lat: 48.795006, lng: 18.4986618 },
    { lat: 48.7949686, lng: 18.4989252 },
    { lat: 48.7951015, lng: 18.4990681 },
    { lat: 48.7951014, lng: 18.4991053 },
    { lat: 48.7950668, lng: 18.4992366 },
    { lat: 48.7949675, lng: 18.4993582 },
    { lat: 48.7949672, lng: 18.4993958 },
    { lat: 48.7949885, lng: 18.499435 },
    { lat: 48.7950768, lng: 18.4994808 },
    { lat: 48.7951953, lng: 18.4998309 },
    { lat: 48.7952514, lng: 18.4999702 },
    { lat: 48.7953668, lng: 18.5001603 },
    { lat: 48.7954112, lng: 18.500354 },
    { lat: 48.7953828, lng: 18.5004531 },
    { lat: 48.7953752, lng: 18.5006852 },
    { lat: 48.7953867, lng: 18.500873 },
    { lat: 48.7953525, lng: 18.5009474 },
    { lat: 48.7953541, lng: 18.5010202 },
    { lat: 48.7954649, lng: 18.5012693 },
    { lat: 48.7954664, lng: 18.5013195 },
    { lat: 48.7954324, lng: 18.5015217 },
    { lat: 48.7954547, lng: 18.5018255 },
    { lat: 48.7954382, lng: 18.5018948 },
    { lat: 48.7954656, lng: 18.5020076 },
    { lat: 48.7954889, lng: 18.5022047 },
    { lat: 48.7955494, lng: 18.5022762 },
    { lat: 48.7955498, lng: 18.5023277 },
    { lat: 48.7955386, lng: 18.5023903 },
    { lat: 48.7955168, lng: 18.5024466 },
    { lat: 48.7954912, lng: 18.5024738 },
    { lat: 48.7954938, lng: 18.5026435 },
    { lat: 48.7955276, lng: 18.5029916 },
    { lat: 48.7956096, lng: 18.5033588 },
    { lat: 48.7956095, lng: 18.5034783 },
    { lat: 48.795434, lng: 18.504145 },
    { lat: 48.7951314, lng: 18.5046148 },
    { lat: 48.794878, lng: 18.5050166 },
    { lat: 48.7947148, lng: 18.5050614 },
    { lat: 48.7946176, lng: 18.5050779 },
    { lat: 48.7944731, lng: 18.5051203 },
    { lat: 48.7942332, lng: 18.5053093 },
    { lat: 48.7939797, lng: 18.5055192 },
    { lat: 48.7934839, lng: 18.5058802 },
    { lat: 48.7932378, lng: 18.5061253 },
    { lat: 48.7928487, lng: 18.5068074 },
    { lat: 48.7924825, lng: 18.507865 },
    { lat: 48.7923104, lng: 18.5085542 },
    { lat: 48.7921054, lng: 18.5094049 },
    { lat: 48.7919561, lng: 18.5100582 },
    { lat: 48.7916556, lng: 18.5111423 },
    { lat: 48.7915759, lng: 18.5115376 },
    { lat: 48.7914954, lng: 18.5125419 },
    { lat: 48.7914988, lng: 18.5133059 },
    { lat: 48.7914448, lng: 18.5142976 },
    { lat: 48.7914854, lng: 18.5153296 },
    { lat: 48.7915584, lng: 18.5162102 },
    { lat: 48.7916086, lng: 18.5169652 },
    { lat: 48.7916302, lng: 18.517361 },
    { lat: 48.7916836, lng: 18.517697 },
    { lat: 48.7916531, lng: 18.5177785 },
    { lat: 48.7916826, lng: 18.5178263 },
    { lat: 48.7917177, lng: 18.5178452 },
    { lat: 48.7917888, lng: 18.5179314 },
    { lat: 48.7918181, lng: 18.5179313 },
    { lat: 48.7918823, lng: 18.5179075 },
    { lat: 48.7919368, lng: 18.517953 },
    { lat: 48.7920115, lng: 18.5179182 },
    { lat: 48.7921277, lng: 18.517938 },
    { lat: 48.7921666, lng: 18.5179285 },
    { lat: 48.7924098, lng: 18.5179023 },
    { lat: 48.7924964, lng: 18.5178339 },
    { lat: 48.7926046, lng: 18.5178718 },
    { lat: 48.7926991, lng: 18.5178496 },
    { lat: 48.7927497, lng: 18.5179274 },
    { lat: 48.7928582, lng: 18.5179921 },
    { lat: 48.7931224, lng: 18.5178313 },
    { lat: 48.7932064, lng: 18.5178194 },
    { lat: 48.7933459, lng: 18.5177121 },
    { lat: 48.7933755, lng: 18.5176365 },
    { lat: 48.7934852, lng: 18.5175376 },
    { lat: 48.7935399, lng: 18.5175261 },
    { lat: 48.7936244, lng: 18.5174626 },
    { lat: 48.7938128, lng: 18.5174637 },
    { lat: 48.7938785, lng: 18.5173027 },
    { lat: 48.7939925, lng: 18.5172616 },
    { lat: 48.7942103, lng: 18.5172834 },
    { lat: 48.7942644, lng: 18.5171807 },
    { lat: 48.7943513, lng: 18.5171391 },
    { lat: 48.7945023, lng: 18.517138 },
    { lat: 48.794603, lng: 18.516958 },
    { lat: 48.7949261, lng: 18.5169436 },
    { lat: 48.7949443, lng: 18.5168881 },
    { lat: 48.7949625, lng: 18.5168883 },
    { lat: 48.7950815, lng: 18.5170458 },
    { lat: 48.7952402, lng: 18.517159 },
    { lat: 48.7954246, lng: 18.5170909 },
    { lat: 48.7954998, lng: 18.5170185 },
    { lat: 48.795641, lng: 18.5170019 },
    { lat: 48.7956833, lng: 18.5170535 },
    { lat: 48.7957602, lng: 18.5169941 },
    { lat: 48.7957709, lng: 18.5169445 },
    { lat: 48.796038, lng: 18.5170659 },
    { lat: 48.7961291, lng: 18.517184 },
  ],
  Koš: [
    { lat: 48.7474544, lng: 18.6082789 },
    { lat: 48.7475765, lng: 18.6082444 },
    { lat: 48.7488393, lng: 18.6094472 },
    { lat: 48.7506857, lng: 18.6069606 },
    { lat: 48.7515253, lng: 18.6061533 },
    { lat: 48.7520952, lng: 18.6055713 },
    { lat: 48.7528039, lng: 18.6051188 },
    { lat: 48.7530212, lng: 18.604943 },
    { lat: 48.7530076, lng: 18.6048801 },
    { lat: 48.7530027, lng: 18.6048518 },
    { lat: 48.7529976, lng: 18.6048201 },
    { lat: 48.753368, lng: 18.6045996 },
    { lat: 48.7545231, lng: 18.6038065 },
    { lat: 48.7545795, lng: 18.6037656 },
    { lat: 48.7545871, lng: 18.6037595 },
    { lat: 48.7546685, lng: 18.6037 },
    { lat: 48.7546761, lng: 18.6036944 },
    { lat: 48.7547065, lng: 18.6036719 },
    { lat: 48.7547169, lng: 18.6036992 },
    { lat: 48.7547236, lng: 18.6037178 },
    { lat: 48.7549503, lng: 18.6041774 },
    { lat: 48.7551962, lng: 18.6047227 },
    { lat: 48.755471, lng: 18.6052802 },
    { lat: 48.7557378, lng: 18.6058125 },
    { lat: 48.7560046, lng: 18.6063476 },
    { lat: 48.7562862, lng: 18.6068848 },
    { lat: 48.756586, lng: 18.6074031 },
    { lat: 48.7567459, lng: 18.6077553 },
    { lat: 48.7567723, lng: 18.6078039 },
    { lat: 48.7576471, lng: 18.6072935 },
    { lat: 48.7580971, lng: 18.6070833 },
    { lat: 48.7586853, lng: 18.6068013 },
    { lat: 48.7590679, lng: 18.6065764 },
    { lat: 48.7592173, lng: 18.6064607 },
    { lat: 48.7594848, lng: 18.6061493 },
    { lat: 48.7595554, lng: 18.6060573 },
    { lat: 48.7597525, lng: 18.605801 },
    { lat: 48.7599738, lng: 18.6055139 },
    { lat: 48.7601573, lng: 18.6052756 },
    { lat: 48.7604746, lng: 18.6048632 },
    { lat: 48.7606073, lng: 18.6046915 },
    { lat: 48.7614143, lng: 18.6036347 },
    { lat: 48.7615888, lng: 18.6033971 },
    { lat: 48.761717, lng: 18.6032038 },
    { lat: 48.7622545, lng: 18.6024811 },
    { lat: 48.7627327, lng: 18.6016037 },
    { lat: 48.7627784, lng: 18.6007569 },
    { lat: 48.7628204, lng: 18.599987 },
    { lat: 48.7628725, lng: 18.5996995 },
    { lat: 48.7628216, lng: 18.599048 },
    { lat: 48.7628236, lng: 18.5985015 },
    { lat: 48.7627536, lng: 18.5981462 },
    { lat: 48.7624816, lng: 18.5976189 },
    { lat: 48.7622557, lng: 18.5971952 },
    { lat: 48.762246, lng: 18.5971811 },
    { lat: 48.7622263, lng: 18.5971515 },
    { lat: 48.7623246, lng: 18.5969257 },
    { lat: 48.76249, lng: 18.5965214 },
    { lat: 48.7626229, lng: 18.5961428 },
    { lat: 48.7626671, lng: 18.5959786 },
    { lat: 48.7626971, lng: 18.5959277 },
    { lat: 48.7627207, lng: 18.5958851 },
    { lat: 48.7627776, lng: 18.5958525 },
    { lat: 48.7625108, lng: 18.5951513 },
    { lat: 48.7623089, lng: 18.5946491 },
    { lat: 48.7622127, lng: 18.5943941 },
    { lat: 48.76221, lng: 18.5943855 },
    { lat: 48.762189, lng: 18.5943313 },
    { lat: 48.7620862, lng: 18.5940562 },
    { lat: 48.7618488, lng: 18.5935032 },
    { lat: 48.7616798, lng: 18.5931535 },
    { lat: 48.7614848, lng: 18.5928621 },
    { lat: 48.761282, lng: 18.5926362 },
    { lat: 48.7610187, lng: 18.5924198 },
    { lat: 48.7609591, lng: 18.5926096 },
    { lat: 48.7606139, lng: 18.5923384 },
    { lat: 48.7597729, lng: 18.5920531 },
    { lat: 48.7593252, lng: 18.5916162 },
    { lat: 48.7590068, lng: 18.5909642 },
    { lat: 48.7580472, lng: 18.5891964 },
    { lat: 48.7577843, lng: 18.5889202 },
    { lat: 48.7574003, lng: 18.5889936 },
    { lat: 48.7572165, lng: 18.5886203 },
    { lat: 48.7572292, lng: 18.5880217 },
    { lat: 48.7571823, lng: 18.5880477 },
    { lat: 48.7569578, lng: 18.5877123 },
    { lat: 48.7569051, lng: 18.5877385 },
    { lat: 48.7567753, lng: 18.5878035 },
    { lat: 48.7566393, lng: 18.5878715 },
    { lat: 48.7565919, lng: 18.5878952 },
    { lat: 48.7565515, lng: 18.5879174 },
    { lat: 48.7565503, lng: 18.5879182 },
    { lat: 48.7564976, lng: 18.5879433 },
    { lat: 48.7564877, lng: 18.5879481 },
    { lat: 48.7564724, lng: 18.5879593 },
    { lat: 48.7564086, lng: 18.5880069 },
    { lat: 48.7561689, lng: 18.5881863 },
    { lat: 48.7561032, lng: 18.588138 },
    { lat: 48.7560307, lng: 18.5880286 },
    { lat: 48.7559041, lng: 18.5878555 },
    { lat: 48.7558308, lng: 18.5876902 },
    { lat: 48.7555437, lng: 18.5871963 },
    { lat: 48.7552551, lng: 18.5868037 },
    { lat: 48.7547862, lng: 18.5862276 },
    { lat: 48.7543871, lng: 18.5856046 },
    { lat: 48.7541444, lng: 18.5851206 },
    { lat: 48.7539518, lng: 18.584876 },
    { lat: 48.7538977, lng: 18.5847548 },
    { lat: 48.752971, lng: 18.582802 },
    { lat: 48.7525514, lng: 18.5815529 },
    { lat: 48.7523823, lng: 18.5810755 },
    { lat: 48.7522777, lng: 18.5808712 },
    { lat: 48.7519309, lng: 18.5797789 },
    { lat: 48.7517839, lng: 18.5793953 },
    { lat: 48.7514978, lng: 18.5788828 },
    { lat: 48.7516146, lng: 18.5786875 },
    { lat: 48.7516272, lng: 18.5786663 },
    { lat: 48.7516344, lng: 18.5786554 },
    { lat: 48.7516758, lng: 18.578587 },
    { lat: 48.7517174, lng: 18.5785203 },
    { lat: 48.7517288, lng: 18.5785046 },
    { lat: 48.7517457, lng: 18.57848 },
    { lat: 48.7515453, lng: 18.5780702 },
    { lat: 48.7517085, lng: 18.5770045 },
    { lat: 48.7517131, lng: 18.5767748 },
    { lat: 48.751775, lng: 18.5763378 },
    { lat: 48.751698, lng: 18.5760425 },
    { lat: 48.7517366, lng: 18.5757114 },
    { lat: 48.7517866, lng: 18.5756569 },
    { lat: 48.7517572, lng: 18.5754897 },
    { lat: 48.7517529, lng: 18.5754112 },
    { lat: 48.7517494, lng: 18.5753459 },
    { lat: 48.752047, lng: 18.5752979 },
    { lat: 48.7523093, lng: 18.574538 },
    { lat: 48.7525445, lng: 18.5739456 },
    { lat: 48.7525979, lng: 18.5737066 },
    { lat: 48.7523332, lng: 18.5726724 },
    { lat: 48.7521564, lng: 18.5717611 },
    { lat: 48.7520882, lng: 18.5715243 },
    { lat: 48.7520906, lng: 18.5714861 },
    { lat: 48.7521045, lng: 18.5713665 },
    { lat: 48.7521116, lng: 18.571288 },
    { lat: 48.75212, lng: 18.5711948 },
    { lat: 48.7520027, lng: 18.5707972 },
    { lat: 48.7517855, lng: 18.5702268 },
    { lat: 48.7514746, lng: 18.569632 },
    { lat: 48.7514029, lng: 18.5694947 },
    { lat: 48.7509596, lng: 18.5687866 },
    { lat: 48.7506539, lng: 18.5682458 },
    { lat: 48.7505888, lng: 18.5681041 },
    { lat: 48.7503896, lng: 18.5676785 },
    { lat: 48.749991, lng: 18.5670228 },
    { lat: 48.7495829, lng: 18.5665726 },
    { lat: 48.7494147, lng: 18.5662994 },
    { lat: 48.749093, lng: 18.565672 },
    { lat: 48.7486045, lng: 18.564319 },
    { lat: 48.7484649, lng: 18.5637852 },
    { lat: 48.7485276, lng: 18.5636724 },
    { lat: 48.7483152, lng: 18.5633963 },
    { lat: 48.7480214, lng: 18.5629918 },
    { lat: 48.7478008, lng: 18.5625013 },
    { lat: 48.7477015, lng: 18.5619582 },
    { lat: 48.7474744, lng: 18.5616833 },
    { lat: 48.7474358, lng: 18.5616348 },
    { lat: 48.7473937, lng: 18.5615861 },
    { lat: 48.747313, lng: 18.5617627 },
    { lat: 48.7469601, lng: 18.5616196 },
    { lat: 48.746899, lng: 18.5615553 },
    { lat: 48.7469075, lng: 18.5615253 },
    { lat: 48.7467294, lng: 18.5609449 },
    { lat: 48.7467757, lng: 18.5607397 },
    { lat: 48.7467644, lng: 18.5606757 },
    { lat: 48.7465919, lng: 18.5604723 },
    { lat: 48.746367, lng: 18.5603784 },
    { lat: 48.7463673, lng: 18.5603226 },
    { lat: 48.7463693, lng: 18.5602337 },
    { lat: 48.7463553, lng: 18.5602058 },
    { lat: 48.746329, lng: 18.5601568 },
    { lat: 48.74629, lng: 18.5600827 },
    { lat: 48.745924, lng: 18.5597815 },
    { lat: 48.7458245, lng: 18.5596945 },
    { lat: 48.7450524, lng: 18.5590831 },
    { lat: 48.7448344, lng: 18.5589111 },
    { lat: 48.7446014, lng: 18.5587507 },
    { lat: 48.7444593, lng: 18.5587337 },
    { lat: 48.7443302, lng: 18.5587011 },
    { lat: 48.7440849, lng: 18.5585465 },
    { lat: 48.7430982, lng: 18.5616889 },
    { lat: 48.7429082, lng: 18.562299 },
    { lat: 48.7428825, lng: 18.5623265 },
    { lat: 48.74286, lng: 18.5623472 },
    { lat: 48.7428128, lng: 18.5623916 },
    { lat: 48.7426943, lng: 18.5619986 },
    { lat: 48.7427165, lng: 18.5616381 },
    { lat: 48.7424698, lng: 18.5615597 },
    { lat: 48.7421783, lng: 18.5612589 },
    { lat: 48.7416631, lng: 18.5607257 },
    { lat: 48.7415152, lng: 18.5606397 },
    { lat: 48.7415039, lng: 18.5606179 },
    { lat: 48.7414755, lng: 18.560531 },
    { lat: 48.7414164, lng: 18.5603561 },
    { lat: 48.7413388, lng: 18.5601978 },
    { lat: 48.7413081, lng: 18.559569 },
    { lat: 48.7412748, lng: 18.5591418 },
    { lat: 48.7412864, lng: 18.5588995 },
    { lat: 48.7412822, lng: 18.5588225 },
    { lat: 48.741238, lng: 18.5586954 },
    { lat: 48.7411892, lng: 18.5586075 },
    { lat: 48.7410249, lng: 18.5585507 },
    { lat: 48.7409431, lng: 18.5585805 },
    { lat: 48.7407073, lng: 18.5587474 },
    { lat: 48.7405503, lng: 18.5585975 },
    { lat: 48.7405017, lng: 18.5584517 },
    { lat: 48.7404543, lng: 18.5583447 },
    { lat: 48.7403841, lng: 18.5582362 },
    { lat: 48.740061, lng: 18.5578149 },
    { lat: 48.7399322, lng: 18.5575197 },
    { lat: 48.7398983, lng: 18.557248 },
    { lat: 48.7398337, lng: 18.5570338 },
    { lat: 48.7397535, lng: 18.5567847 },
    { lat: 48.739684, lng: 18.5566446 },
    { lat: 48.739641, lng: 18.5565792 },
    { lat: 48.7394747, lng: 18.5565077 },
    { lat: 48.7394052, lng: 18.5565167 },
    { lat: 48.7393753, lng: 18.5565493 },
    { lat: 48.7392975, lng: 18.556797 },
    { lat: 48.739313, lng: 18.5569605 },
    { lat: 48.7393215, lng: 18.5570946 },
    { lat: 48.7392813, lng: 18.5574987 },
    { lat: 48.7391157, lng: 18.5576869 },
    { lat: 48.7390397, lng: 18.5576581 },
    { lat: 48.7389823, lng: 18.5574119 },
    { lat: 48.7388551, lng: 18.5569803 },
    { lat: 48.7387684, lng: 18.5568806 },
    { lat: 48.7386726, lng: 18.5566102 },
    { lat: 48.7386936, lng: 18.5560444 },
    { lat: 48.73863, lng: 18.555849 },
    { lat: 48.7385351, lng: 18.5556375 },
    { lat: 48.7384376, lng: 18.5554016 },
    { lat: 48.7382305, lng: 18.5553873 },
    { lat: 48.7377926, lng: 18.5554671 },
    { lat: 48.7376111, lng: 18.5556147 },
    { lat: 48.7373833, lng: 18.5555333 },
    { lat: 48.7372286, lng: 18.5553446 },
    { lat: 48.7371646, lng: 18.555081 },
    { lat: 48.7372179, lng: 18.5548567 },
    { lat: 48.7370083, lng: 18.5544018 },
    { lat: 48.7369567, lng: 18.5544293 },
    { lat: 48.7369145, lng: 18.5544628 },
    { lat: 48.7365484, lng: 18.5546977 },
    { lat: 48.7365301, lng: 18.5547135 },
    { lat: 48.7364955, lng: 18.5547425 },
    { lat: 48.7365752, lng: 18.554882 },
    { lat: 48.7365985, lng: 18.5549541 },
    { lat: 48.7366523, lng: 18.555135 },
    { lat: 48.7366145, lng: 18.5551865 },
    { lat: 48.7365951, lng: 18.5552036 },
    { lat: 48.7364736, lng: 18.555109 },
    { lat: 48.7364003, lng: 18.5554608 },
    { lat: 48.7364029, lng: 18.5554858 },
    { lat: 48.7365975, lng: 18.5558304 },
    { lat: 48.7368323, lng: 18.5556461 },
    { lat: 48.7369639, lng: 18.5561365 },
    { lat: 48.7370691, lng: 18.5562671 },
    { lat: 48.7368513, lng: 18.5565561 },
    { lat: 48.7368929, lng: 18.5567217 },
    { lat: 48.7367567, lng: 18.556972 },
    { lat: 48.7366926, lng: 18.557184 },
    { lat: 48.7367374, lng: 18.5573657 },
    { lat: 48.7367788, lng: 18.557526 },
    { lat: 48.7367458, lng: 18.557624 },
    { lat: 48.7366877, lng: 18.5577599 },
    { lat: 48.736679, lng: 18.5578918 },
    { lat: 48.7366573, lng: 18.5581521 },
    { lat: 48.7366556, lng: 18.5583085 },
    { lat: 48.7366595, lng: 18.5584241 },
    { lat: 48.7365216, lng: 18.558665 },
    { lat: 48.7362949, lng: 18.5588339 },
    { lat: 48.7362291, lng: 18.5590929 },
    { lat: 48.7362221, lng: 18.5592789 },
    { lat: 48.7360846, lng: 18.5595268 },
    { lat: 48.7361932, lng: 18.5598864 },
    { lat: 48.7361904, lng: 18.5600211 },
    { lat: 48.73639, lng: 18.5603318 },
    { lat: 48.7362491, lng: 18.5607931 },
    { lat: 48.736252, lng: 18.5608246 },
    { lat: 48.7363102, lng: 18.5609206 },
    { lat: 48.7363273, lng: 18.5610046 },
    { lat: 48.7363242, lng: 18.5610307 },
    { lat: 48.7362767, lng: 18.561136 },
    { lat: 48.7360038, lng: 18.5614253 },
    { lat: 48.7359852, lng: 18.5614176 },
    { lat: 48.7359499, lng: 18.5613674 },
    { lat: 48.7359365, lng: 18.5613683 },
    { lat: 48.7357526, lng: 18.5616173 },
    { lat: 48.7355276, lng: 18.5619226 },
    { lat: 48.735478, lng: 18.5620874 },
    { lat: 48.7354283, lng: 18.5622093 },
    { lat: 48.7353995, lng: 18.5622615 },
    { lat: 48.7353728, lng: 18.5623309 },
    { lat: 48.7353697, lng: 18.562357 },
    { lat: 48.7353759, lng: 18.5623881 },
    { lat: 48.7353926, lng: 18.5624038 },
    { lat: 48.7354347, lng: 18.562414 },
    { lat: 48.735448, lng: 18.5624281 },
    { lat: 48.7354543, lng: 18.5624605 },
    { lat: 48.7354034, lng: 18.5626848 },
    { lat: 48.7352151, lng: 18.5631044 },
    { lat: 48.7350789, lng: 18.5637524 },
    { lat: 48.7350557, lng: 18.5639907 },
    { lat: 48.7349514, lng: 18.5641681 },
    { lat: 48.7346846, lng: 18.5643209 },
    { lat: 48.7345769, lng: 18.5642714 },
    { lat: 48.734444, lng: 18.5643786 },
    { lat: 48.7343654, lng: 18.5643844 },
    { lat: 48.7343379, lng: 18.5644618 },
    { lat: 48.7344339, lng: 18.564754 },
    { lat: 48.7345332, lng: 18.565273 },
    { lat: 48.7346881, lng: 18.5653009 },
    { lat: 48.7347075, lng: 18.5653661 },
    { lat: 48.7346795, lng: 18.5654728 },
    { lat: 48.7346337, lng: 18.5656078 },
    { lat: 48.734694, lng: 18.5656404 },
    { lat: 48.7347079, lng: 18.5657076 },
    { lat: 48.734597, lng: 18.565929 },
    { lat: 48.7344733, lng: 18.5661023 },
    { lat: 48.7343356, lng: 18.5663084 },
    { lat: 48.7342405, lng: 18.5663412 },
    { lat: 48.733929, lng: 18.5666729 },
    { lat: 48.7337987, lng: 18.5668688 },
    { lat: 48.7336615, lng: 18.5670623 },
    { lat: 48.7336541, lng: 18.5670732 },
    { lat: 48.7336416, lng: 18.5670912 },
    { lat: 48.733581, lng: 18.5672202 },
    { lat: 48.7335623, lng: 18.5672722 },
    { lat: 48.7334557, lng: 18.5676335 },
    { lat: 48.7332309, lng: 18.5677546 },
    { lat: 48.7329247, lng: 18.5678269 },
    { lat: 48.732875, lng: 18.5679084 },
    { lat: 48.7328947, lng: 18.5683977 },
    { lat: 48.7326838, lng: 18.5688322 },
    { lat: 48.7326104, lng: 18.5691218 },
    { lat: 48.7325394, lng: 18.5691456 },
    { lat: 48.7323458, lng: 18.5690892 },
    { lat: 48.7322652, lng: 18.5688934 },
    { lat: 48.73222, lng: 18.5688701 },
    { lat: 48.7318719, lng: 18.5694199 },
    { lat: 48.7316548, lng: 18.5694533 },
    { lat: 48.7313997, lng: 18.5697607 },
    { lat: 48.7312902, lng: 18.5700912 },
    { lat: 48.7312868, lng: 18.5703814 },
    { lat: 48.7312632, lng: 18.570673 },
    { lat: 48.7313717, lng: 18.5710976 },
    { lat: 48.7313566, lng: 18.5713753 },
    { lat: 48.7311983, lng: 18.5718305 },
    { lat: 48.7308331, lng: 18.5720605 },
    { lat: 48.7306227, lng: 18.5724446 },
    { lat: 48.7304319, lng: 18.5725032 },
    { lat: 48.7302776, lng: 18.5727564 },
    { lat: 48.7302054, lng: 18.5728643 },
    { lat: 48.7300704, lng: 18.5729718 },
    { lat: 48.7299587, lng: 18.5729738 },
    { lat: 48.729848, lng: 18.5732384 },
    { lat: 48.7297768, lng: 18.5732986 },
    { lat: 48.7297587, lng: 18.5734434 },
    { lat: 48.7297032, lng: 18.5735232 },
    { lat: 48.7296519, lng: 18.5734924 },
    { lat: 48.7295595, lng: 18.5736189 },
    { lat: 48.7294941, lng: 18.573787 },
    { lat: 48.7294522, lng: 18.5738458 },
    { lat: 48.7292839, lng: 18.5739646 },
    { lat: 48.7292769, lng: 18.5740055 },
    { lat: 48.7291962, lng: 18.5740326 },
    { lat: 48.7291274, lng: 18.5740104 },
    { lat: 48.7291122, lng: 18.5740223 },
    { lat: 48.7291229, lng: 18.5741589 },
    { lat: 48.7290731, lng: 18.5741958 },
    { lat: 48.7291129, lng: 18.5743673 },
    { lat: 48.7290904, lng: 18.5744094 },
    { lat: 48.7289786, lng: 18.5744926 },
    { lat: 48.7287625, lng: 18.5745215 },
    { lat: 48.7287223, lng: 18.5744475 },
    { lat: 48.7287078, lng: 18.5744511 },
    { lat: 48.728694, lng: 18.57457 },
    { lat: 48.7286774, lng: 18.5746174 },
    { lat: 48.7286756, lng: 18.5747291 },
    { lat: 48.7286694, lng: 18.5747621 },
    { lat: 48.7286574, lng: 18.5747886 },
    { lat: 48.7285548, lng: 18.5748316 },
    { lat: 48.7285362, lng: 18.5749254 },
    { lat: 48.72851, lng: 18.5750222 },
    { lat: 48.728491, lng: 18.5751507 },
    { lat: 48.7284012, lng: 18.5751826 },
    { lat: 48.7283724, lng: 18.5752747 },
    { lat: 48.7284296, lng: 18.575392 },
    { lat: 48.7284093, lng: 18.575536 },
    { lat: 48.728387, lng: 18.5755441 },
    { lat: 48.7283776, lng: 18.5756796 },
    { lat: 48.7283415, lng: 18.5757466 },
    { lat: 48.7283598, lng: 18.5759362 },
    { lat: 48.7283376, lng: 18.5759611 },
    { lat: 48.7282835, lng: 18.5760885 },
    { lat: 48.7282902, lng: 18.5762342 },
    { lat: 48.7283465, lng: 18.5763787 },
    { lat: 48.7283606, lng: 18.5764695 },
    { lat: 48.7283681, lng: 18.5765689 },
    { lat: 48.7282856, lng: 18.5766299 },
    { lat: 48.7282969, lng: 18.5767936 },
    { lat: 48.7282806, lng: 18.5769506 },
    { lat: 48.7282858, lng: 18.5770908 },
    { lat: 48.7282498, lng: 18.5771731 },
    { lat: 48.7282075, lng: 18.5772895 },
    { lat: 48.7281654, lng: 18.5772582 },
    { lat: 48.7281459, lng: 18.5772142 },
    { lat: 48.728104, lng: 18.5771679 },
    { lat: 48.7280136, lng: 18.5772717 },
    { lat: 48.7279171, lng: 18.5773404 },
    { lat: 48.7278641, lng: 18.5774652 },
    { lat: 48.7278527, lng: 18.5774859 },
    { lat: 48.7278015, lng: 18.5775391 },
    { lat: 48.7277878, lng: 18.5776622 },
    { lat: 48.7277464, lng: 18.5777488 },
    { lat: 48.7277288, lng: 18.5778821 },
    { lat: 48.727743, lng: 18.5779558 },
    { lat: 48.7276836, lng: 18.5781946 },
    { lat: 48.7276117, lng: 18.5781774 },
    { lat: 48.727575, lng: 18.5782709 },
    { lat: 48.7274206, lng: 18.5783801 },
    { lat: 48.7274361, lng: 18.5784561 },
    { lat: 48.727482, lng: 18.578574 },
    { lat: 48.7274867, lng: 18.578617 },
    { lat: 48.7274423, lng: 18.5787361 },
    { lat: 48.7274294, lng: 18.5789345 },
    { lat: 48.7273832, lng: 18.5790414 },
    { lat: 48.7272608, lng: 18.5790508 },
    { lat: 48.7272112, lng: 18.5790945 },
    { lat: 48.7271797, lng: 18.5791548 },
    { lat: 48.7271713, lng: 18.5792309 },
    { lat: 48.7272685, lng: 18.5793367 },
    { lat: 48.7272387, lng: 18.5794976 },
    { lat: 48.7271009, lng: 18.5794715 },
    { lat: 48.7270637, lng: 18.5795579 },
    { lat: 48.727034, lng: 18.5798206 },
    { lat: 48.7271181, lng: 18.5801649 },
    { lat: 48.7271614, lng: 18.5802349 },
    { lat: 48.727252, lng: 18.580367 },
    { lat: 48.7272334, lng: 18.58046 },
    { lat: 48.7271534, lng: 18.580585 },
    { lat: 48.7271455, lng: 18.580627 },
    { lat: 48.7271143, lng: 18.5806963 },
    { lat: 48.7271129, lng: 18.580754 },
    { lat: 48.7271517, lng: 18.5808067 },
    { lat: 48.7271526, lng: 18.5808848 },
    { lat: 48.7271058, lng: 18.5810185 },
    { lat: 48.7270983, lng: 18.5811113 },
    { lat: 48.7271158, lng: 18.581182 },
    { lat: 48.7271107, lng: 18.5812983 },
    { lat: 48.7270968, lng: 18.5812893 },
    { lat: 48.727085, lng: 18.5812815 },
    { lat: 48.7255881, lng: 18.5800928 },
    { lat: 48.7255422, lng: 18.5800581 },
    { lat: 48.7254882, lng: 18.5800169 },
    { lat: 48.7254623, lng: 18.5799972 },
    { lat: 48.7254118, lng: 18.5801071 },
    { lat: 48.7253976, lng: 18.5801371 },
    { lat: 48.725324, lng: 18.580297 },
    { lat: 48.7252735, lng: 18.5804068 },
    { lat: 48.7247856, lng: 18.5814757 },
    { lat: 48.7242939, lng: 18.5830194 },
    { lat: 48.7241394, lng: 18.5830196 },
    { lat: 48.7239353, lng: 18.583166 },
    { lat: 48.7227138, lng: 18.5814377 },
    { lat: 48.7225917, lng: 18.5816411 },
    { lat: 48.7224394, lng: 18.5818918 },
    { lat: 48.7224278, lng: 18.582114 },
    { lat: 48.7222693, lng: 18.5823354 },
    { lat: 48.7221858, lng: 18.5824161 },
    { lat: 48.7219509, lng: 18.5826395 },
    { lat: 48.7218821, lng: 18.5826002 },
    { lat: 48.7218462, lng: 18.5826446 },
    { lat: 48.7217664, lng: 18.5826888 },
    { lat: 48.7217019, lng: 18.5828514 },
    { lat: 48.7217012, lng: 18.5829658 },
    { lat: 48.7216106, lng: 18.5829806 },
    { lat: 48.7215896, lng: 18.5830089 },
    { lat: 48.7215622, lng: 18.5830034 },
    { lat: 48.7215504, lng: 18.583057 },
    { lat: 48.7215379, lng: 18.5831132 },
    { lat: 48.7214259, lng: 18.5832549 },
    { lat: 48.721106, lng: 18.5836574 },
    { lat: 48.7210082, lng: 18.5837295 },
    { lat: 48.7208608, lng: 18.5839007 },
    { lat: 48.7207462, lng: 18.5840324 },
    { lat: 48.7206218, lng: 18.5841092 },
    { lat: 48.7204776, lng: 18.5841352 },
    { lat: 48.7204235, lng: 18.5841584 },
    { lat: 48.7203194, lng: 18.5842345 },
    { lat: 48.7203021, lng: 18.5842473 },
    { lat: 48.7202411, lng: 18.584372 },
    { lat: 48.7199747, lng: 18.5845339 },
    { lat: 48.7198331, lng: 18.5847522 },
    { lat: 48.7197352, lng: 18.584839 },
    { lat: 48.719494, lng: 18.5850099 },
    { lat: 48.7194386, lng: 18.585026 },
    { lat: 48.7193923, lng: 18.585071 },
    { lat: 48.7193312, lng: 18.585085 },
    { lat: 48.7192994, lng: 18.5851436 },
    { lat: 48.7192359, lng: 18.5851818 },
    { lat: 48.7191916, lng: 18.5851973 },
    { lat: 48.7191516, lng: 18.585209 },
    { lat: 48.7190887, lng: 18.5852958 },
    { lat: 48.7190705, lng: 18.5853134 },
    { lat: 48.7190739, lng: 18.5854373 },
    { lat: 48.7189075, lng: 18.58549 },
    { lat: 48.718853, lng: 18.5854817 },
    { lat: 48.7188327, lng: 18.5855442 },
    { lat: 48.718817, lng: 18.5855905 },
    { lat: 48.7187332, lng: 18.5856669 },
    { lat: 48.7186103, lng: 18.585815 },
    { lat: 48.7185, lng: 18.5858608 },
    { lat: 48.718457, lng: 18.5858782 },
    { lat: 48.71847, lng: 18.5859688 },
    { lat: 48.7184285, lng: 18.5860375 },
    { lat: 48.7183684, lng: 18.5861364 },
    { lat: 48.7183462, lng: 18.5864552 },
    { lat: 48.7182979, lng: 18.5865237 },
    { lat: 48.7181975, lng: 18.5866683 },
    { lat: 48.7180962, lng: 18.5872559 },
    { lat: 48.7181564, lng: 18.5876392 },
    { lat: 48.7178766, lng: 18.5880321 },
    { lat: 48.7177597, lng: 18.5882266 },
    { lat: 48.7176875, lng: 18.5882466 },
    { lat: 48.7173952, lng: 18.5886413 },
    { lat: 48.7173274, lng: 18.5888676 },
    { lat: 48.7172976, lng: 18.5889645 },
    { lat: 48.7172468, lng: 18.5890254 },
    { lat: 48.7170761, lng: 18.589209 },
    { lat: 48.7168507, lng: 18.5894369 },
    { lat: 48.7166921, lng: 18.589594 },
    { lat: 48.7166172, lng: 18.5896679 },
    { lat: 48.7165629, lng: 18.5897261 },
    { lat: 48.7165356, lng: 18.5898077 },
    { lat: 48.716522, lng: 18.5898479 },
    { lat: 48.7164791, lng: 18.5899739 },
    { lat: 48.7167732, lng: 18.5903812 },
    { lat: 48.7171262, lng: 18.5908809 },
    { lat: 48.7174814, lng: 18.5913835 },
    { lat: 48.717496, lng: 18.5914435 },
    { lat: 48.7174232, lng: 18.591535 },
    { lat: 48.7167183, lng: 18.5924122 },
    { lat: 48.7161614, lng: 18.5929188 },
    { lat: 48.7154752, lng: 18.5934333 },
    { lat: 48.7145304, lng: 18.5941489 },
    { lat: 48.713796, lng: 18.5946067 },
    { lat: 48.7134758, lng: 18.5948999 },
    { lat: 48.7133611, lng: 18.5950129 },
    { lat: 48.7132571, lng: 18.5951315 },
    { lat: 48.712997, lng: 18.5954526 },
    { lat: 48.7127578, lng: 18.5959325 },
    { lat: 48.7127372, lng: 18.5959071 },
    { lat: 48.712693, lng: 18.5958572 },
    { lat: 48.7121854, lng: 18.5970278 },
    { lat: 48.7122035, lng: 18.5970702 },
    { lat: 48.7122241, lng: 18.597118 },
    { lat: 48.7128339, lng: 18.5984038 },
    { lat: 48.7120266, lng: 18.5992852 },
    { lat: 48.711934, lng: 18.5993695 },
    { lat: 48.7122599, lng: 18.6000329 },
    { lat: 48.7138402, lng: 18.6033171 },
    { lat: 48.7141619, lng: 18.6039867 },
    { lat: 48.7141618, lng: 18.6040082 },
    { lat: 48.7141619, lng: 18.6040524 },
    { lat: 48.714016, lng: 18.6040029 },
    { lat: 48.7134152, lng: 18.6040913 },
    { lat: 48.7133288, lng: 18.6041416 },
    { lat: 48.7131105, lng: 18.6039456 },
    { lat: 48.7130934, lng: 18.6039613 },
    { lat: 48.7130649, lng: 18.6041037 },
    { lat: 48.7130812, lng: 18.6044057 },
    { lat: 48.7130571, lng: 18.6044808 },
    { lat: 48.7130553, lng: 18.6044864 },
    { lat: 48.7129033, lng: 18.604494 },
    { lat: 48.7127533, lng: 18.6042211 },
    { lat: 48.7126722, lng: 18.6041632 },
    { lat: 48.7125107, lng: 18.6046167 },
    { lat: 48.7123368, lng: 18.604636 },
    { lat: 48.7118626, lng: 18.6045641 },
    { lat: 48.7115639, lng: 18.6047966 },
    { lat: 48.7112501, lng: 18.6049606 },
    { lat: 48.7110998, lng: 18.604997 },
    { lat: 48.711106, lng: 18.6050666 },
    { lat: 48.7111182, lng: 18.6051272 },
    { lat: 48.7111843, lng: 18.6054327 },
    { lat: 48.7112005, lng: 18.6055042 },
    { lat: 48.7112109, lng: 18.605549 },
    { lat: 48.7112138, lng: 18.6055623 },
    { lat: 48.711579, lng: 18.6072712 },
    { lat: 48.711581, lng: 18.6072856 },
    { lat: 48.7128185, lng: 18.6067034 },
    { lat: 48.7131891, lng: 18.6065285 },
    { lat: 48.7132079, lng: 18.6066051 },
    { lat: 48.7136884, lng: 18.608676 },
    { lat: 48.7130116, lng: 18.6091126 },
    { lat: 48.709995, lng: 18.611049 },
    { lat: 48.7096685, lng: 18.6112492 },
    { lat: 48.7097478, lng: 18.6121533 },
    { lat: 48.7098531, lng: 18.6125775 },
    { lat: 48.7098561, lng: 18.6125928 },
    { lat: 48.7098594, lng: 18.6126107 },
    { lat: 48.7098613, lng: 18.612624 },
    { lat: 48.7099111, lng: 18.6129204 },
    { lat: 48.7101948, lng: 18.61358 },
    { lat: 48.710258, lng: 18.6136866 },
    { lat: 48.7123131, lng: 18.611724 },
    { lat: 48.7131236, lng: 18.6109392 },
    { lat: 48.7131471, lng: 18.6109159 },
    { lat: 48.7131912, lng: 18.6108744 },
    { lat: 48.713209, lng: 18.6108929 },
    { lat: 48.7132282, lng: 18.6109113 },
    { lat: 48.7132688, lng: 18.6109542 },
    { lat: 48.7132925, lng: 18.6109784 },
    { lat: 48.7133779, lng: 18.6110311 },
    { lat: 48.7134036, lng: 18.6110486 },
    { lat: 48.7138135, lng: 18.6113289 },
    { lat: 48.7138799, lng: 18.6113696 },
    { lat: 48.71511, lng: 18.6121954 },
    { lat: 48.7153921, lng: 18.6123867 },
    { lat: 48.716576, lng: 18.6131982 },
    { lat: 48.7166492, lng: 18.6132574 },
    { lat: 48.7177776, lng: 18.614294 },
    { lat: 48.717957, lng: 18.6144605 },
    { lat: 48.7184175, lng: 18.6148657 },
    { lat: 48.7185704, lng: 18.6150019 },
    { lat: 48.718672, lng: 18.6150898 },
    { lat: 48.7191224, lng: 18.6154915 },
    { lat: 48.719975, lng: 18.6162425 },
    { lat: 48.7202627, lng: 18.6164985 },
    { lat: 48.7203374, lng: 18.6165644 },
    { lat: 48.7215989, lng: 18.6176865 },
    { lat: 48.7222742, lng: 18.6182879 },
    { lat: 48.722574, lng: 18.6185532 },
    { lat: 48.7231955, lng: 18.6191229 },
    { lat: 48.7232274, lng: 18.619148 },
    { lat: 48.7232604, lng: 18.6191739 },
    { lat: 48.723284, lng: 18.6191939 },
    { lat: 48.7234302, lng: 18.6190777 },
    { lat: 48.7236012, lng: 18.6189445 },
    { lat: 48.7238488, lng: 18.61875 },
    { lat: 48.7239675, lng: 18.6184175 },
    { lat: 48.7239078, lng: 18.6182923 },
    { lat: 48.7240044, lng: 18.6178693 },
    { lat: 48.7241232, lng: 18.6177271 },
    { lat: 48.7241702, lng: 18.617512 },
    { lat: 48.7242642, lng: 18.6174133 },
    { lat: 48.7243155, lng: 18.6173173 },
    { lat: 48.7243833, lng: 18.61707 },
    { lat: 48.7244868, lng: 18.6169822 },
    { lat: 48.7246182, lng: 18.6168471 },
    { lat: 48.7247376, lng: 18.6167992 },
    { lat: 48.7250599, lng: 18.616482 },
    { lat: 48.7251085, lng: 18.616212 },
    { lat: 48.7252717, lng: 18.6160386 },
    { lat: 48.7253258, lng: 18.6160991 },
    { lat: 48.7254076, lng: 18.6160044 },
    { lat: 48.7254965, lng: 18.6157105 },
    { lat: 48.7256619, lng: 18.6157002 },
    { lat: 48.7257853, lng: 18.6155221 },
    { lat: 48.7258086, lng: 18.6153871 },
    { lat: 48.7259103, lng: 18.6151827 },
    { lat: 48.7261593, lng: 18.6149048 },
    { lat: 48.7262828, lng: 18.614432 },
    { lat: 48.7264477, lng: 18.6143746 },
    { lat: 48.726516, lng: 18.6142215 },
    { lat: 48.7266025, lng: 18.6139175 },
    { lat: 48.726718, lng: 18.6138247 },
    { lat: 48.7268985, lng: 18.6137836 },
    { lat: 48.726932, lng: 18.6137116 },
    { lat: 48.7269219, lng: 18.6134645 },
    { lat: 48.7269932, lng: 18.6133443 },
    { lat: 48.7271303, lng: 18.6132477 },
    { lat: 48.7272174, lng: 18.6129826 },
    { lat: 48.7273187, lng: 18.6128098 },
    { lat: 48.727388, lng: 18.6127973 },
    { lat: 48.7274907, lng: 18.6129051 },
    { lat: 48.7275506, lng: 18.6128445 },
    { lat: 48.7276287, lng: 18.612598 },
    { lat: 48.7276501, lng: 18.6121788 },
    { lat: 48.7277176, lng: 18.6120497 },
    { lat: 48.7278959, lng: 18.611907 },
    { lat: 48.7279674, lng: 18.6117253 },
    { lat: 48.7280201, lng: 18.611299 },
    { lat: 48.7281579, lng: 18.6110105 },
    { lat: 48.728255, lng: 18.6103655 },
    { lat: 48.7283758, lng: 18.6099698 },
    { lat: 48.7285932, lng: 18.6097762 },
    { lat: 48.7287492, lng: 18.6095319 },
    { lat: 48.7288113, lng: 18.6093034 },
    { lat: 48.7289563, lng: 18.6090848 },
    { lat: 48.728984, lng: 18.6090335 },
    { lat: 48.7290091, lng: 18.6088718 },
    { lat: 48.7290832, lng: 18.6087162 },
    { lat: 48.7291798, lng: 18.6085621 },
    { lat: 48.7292534, lng: 18.6084626 },
    { lat: 48.7293604, lng: 18.6082294 },
    { lat: 48.7294271, lng: 18.608129 },
    { lat: 48.7295398, lng: 18.6078789 },
    { lat: 48.7296297, lng: 18.6077442 },
    { lat: 48.7296524, lng: 18.607705 },
    { lat: 48.7298322, lng: 18.6074027 },
    { lat: 48.7300109, lng: 18.6071796 },
    { lat: 48.7301471, lng: 18.6070481 },
    { lat: 48.7303217, lng: 18.6068372 },
    { lat: 48.7303683, lng: 18.6066968 },
    { lat: 48.7304059, lng: 18.6065803 },
    { lat: 48.7304448, lng: 18.6064637 },
    { lat: 48.7304622, lng: 18.6064319 },
    { lat: 48.7305116, lng: 18.6063447 },
    { lat: 48.7305488, lng: 18.6062794 },
    { lat: 48.7305683, lng: 18.6062431 },
    { lat: 48.730687, lng: 18.6060208 },
    { lat: 48.7309065, lng: 18.6057173 },
    { lat: 48.7309208, lng: 18.6057326 },
    { lat: 48.7309543, lng: 18.6057664 },
    { lat: 48.7309854, lng: 18.6058001 },
    { lat: 48.731227, lng: 18.606055 },
    { lat: 48.7312389, lng: 18.6060696 },
    { lat: 48.7313014, lng: 18.606137 },
    { lat: 48.7313541, lng: 18.6061934 },
    { lat: 48.732039, lng: 18.6069332 },
    { lat: 48.7322957, lng: 18.607047 },
    { lat: 48.732585, lng: 18.6073104 },
    { lat: 48.7326363, lng: 18.607363 },
    { lat: 48.7329612, lng: 18.6077378 },
    { lat: 48.7330774, lng: 18.6078471 },
    { lat: 48.7334067, lng: 18.6081598 },
    { lat: 48.7335208, lng: 18.608268 },
    { lat: 48.7336982, lng: 18.6084408 },
    { lat: 48.7338172, lng: 18.608448 },
    { lat: 48.7341383, lng: 18.6084852 },
    { lat: 48.7341407, lng: 18.6084681 },
    { lat: 48.7342302, lng: 18.6084746 },
    { lat: 48.734357, lng: 18.6084818 },
    { lat: 48.7343672, lng: 18.6084831 },
    { lat: 48.734371, lng: 18.6082593 },
    { lat: 48.7343846, lng: 18.6082405 },
    { lat: 48.7344961, lng: 18.6081946 },
    { lat: 48.7345601, lng: 18.6080838 },
    { lat: 48.7347056, lng: 18.607939 },
    { lat: 48.7347139, lng: 18.607908 },
    { lat: 48.734721, lng: 18.6079121 },
    { lat: 48.7347658, lng: 18.6079666 },
    { lat: 48.7352637, lng: 18.607603 },
    { lat: 48.73571, lng: 18.6072828 },
    { lat: 48.7357704, lng: 18.6073565 },
    { lat: 48.7360316, lng: 18.6076409 },
    { lat: 48.7361098, lng: 18.6077281 },
    { lat: 48.7366978, lng: 18.6083849 },
    { lat: 48.7367233, lng: 18.6084804 },
    { lat: 48.7367721, lng: 18.6086537 },
    { lat: 48.7367949, lng: 18.6087227 },
    { lat: 48.7368242, lng: 18.6087445 },
    { lat: 48.7369023, lng: 18.6087903 },
    { lat: 48.7372164, lng: 18.6089874 },
    { lat: 48.7372831, lng: 18.6090317 },
    { lat: 48.7372946, lng: 18.6090385 },
    { lat: 48.7373543, lng: 18.6090754 },
    { lat: 48.737666, lng: 18.6092732 },
    { lat: 48.7378809, lng: 18.609409 },
    { lat: 48.7388473, lng: 18.6075632 },
    { lat: 48.7399107, lng: 18.6056133 },
    { lat: 48.7396564, lng: 18.6053566 },
    { lat: 48.7391387, lng: 18.604833 },
    { lat: 48.7393876, lng: 18.6046576 },
    { lat: 48.7394508, lng: 18.604598 },
    { lat: 48.7396864, lng: 18.6043209 },
    { lat: 48.739843, lng: 18.6040872 },
    { lat: 48.7405103, lng: 18.6030165 },
    { lat: 48.7405424, lng: 18.6030014 },
    { lat: 48.7407127, lng: 18.603043 },
    { lat: 48.7408366, lng: 18.6030831 },
    { lat: 48.741088, lng: 18.603205 },
    { lat: 48.7411249, lng: 18.6032412 },
    { lat: 48.7411961, lng: 18.6032674 },
    { lat: 48.7413805, lng: 18.6034845 },
    { lat: 48.7415092, lng: 18.6035508 },
    { lat: 48.7415976, lng: 18.6035992 },
    { lat: 48.7417006, lng: 18.6036465 },
    { lat: 48.7421611, lng: 18.6039078 },
    { lat: 48.7426582, lng: 18.6042153 },
    { lat: 48.7428473, lng: 18.6043328 },
    { lat: 48.7432711, lng: 18.6046632 },
    { lat: 48.7436455, lng: 18.6049526 },
    { lat: 48.7440737, lng: 18.6053015 },
    { lat: 48.744201, lng: 18.6054845 },
    { lat: 48.744296, lng: 18.6055932 },
    { lat: 48.7443698, lng: 18.6056238 },
    { lat: 48.7444572, lng: 18.6056369 },
    { lat: 48.7455437, lng: 18.6065183 },
    { lat: 48.7460406, lng: 18.6069088 },
    { lat: 48.7465816, lng: 18.6073617 },
    { lat: 48.74674, lng: 18.6075148 },
    { lat: 48.747054, lng: 18.6078149 },
    { lat: 48.7472345, lng: 18.6079631 },
    { lat: 48.7472941, lng: 18.6080033 },
    { lat: 48.7473894, lng: 18.6082235 },
    { lat: 48.7474544, lng: 18.6082789 },
  ],
  Kľačno: [
    { lat: 48.937493, lng: 18.720277 },
    { lat: 48.937884, lng: 18.719936 },
    { lat: 48.94029, lng: 18.717007 },
    { lat: 48.940836, lng: 18.716722 },
    { lat: 48.942099, lng: 18.716503 },
    { lat: 48.943046, lng: 18.716885 },
    { lat: 48.943485, lng: 18.716564 },
    { lat: 48.94388, lng: 18.715592 },
    { lat: 48.944627, lng: 18.714788 },
    { lat: 48.94479, lng: 18.714022 },
    { lat: 48.945894, lng: 18.713963 },
    { lat: 48.946424, lng: 18.713495 },
    { lat: 48.94688, lng: 18.71346 },
    { lat: 48.94772, lng: 18.712707 },
    { lat: 48.948024, lng: 18.712225 },
    { lat: 48.948402, lng: 18.711014 },
    { lat: 48.94859, lng: 18.710375 },
    { lat: 48.949349, lng: 18.710112 },
    { lat: 48.949715, lng: 18.709088 },
    { lat: 48.949986, lng: 18.708808 },
    { lat: 48.949882, lng: 18.708294 },
    { lat: 48.949898, lng: 18.706802 },
    { lat: 48.95067, lng: 18.704378 },
    { lat: 48.950709, lng: 18.704258 },
    { lat: 48.950811, lng: 18.703964 },
    { lat: 48.950834, lng: 18.703898 },
    { lat: 48.950966, lng: 18.703025 },
    { lat: 48.951399, lng: 18.703054 },
    { lat: 48.952016, lng: 18.702691 },
    { lat: 48.952502, lng: 18.702283 },
    { lat: 48.95313, lng: 18.70222 },
    { lat: 48.953476, lng: 18.702157 },
    { lat: 48.953632, lng: 18.702028 },
    { lat: 48.955245, lng: 18.70025 },
    { lat: 48.955304, lng: 18.700185 },
    { lat: 48.9555794, lng: 18.6998817 },
    { lat: 48.95614, lng: 18.699264 },
    { lat: 48.956308, lng: 18.699157 },
    { lat: 48.956611, lng: 18.69798 },
    { lat: 48.957009, lng: 18.696332 },
    { lat: 48.957103, lng: 18.696239 },
    { lat: 48.957328, lng: 18.69618 },
    { lat: 48.957687, lng: 18.695852 },
    { lat: 48.958013, lng: 18.695521 },
    { lat: 48.958558, lng: 18.695069 },
    { lat: 48.959175, lng: 18.694557 },
    { lat: 48.959138, lng: 18.693171 },
    { lat: 48.959501, lng: 18.69262 },
    { lat: 48.959471, lng: 18.692565 },
    { lat: 48.959561, lng: 18.692393 },
    { lat: 48.959295, lng: 18.691957 },
    { lat: 48.958903, lng: 18.691079 },
    { lat: 48.958798, lng: 18.690582 },
    { lat: 48.959067, lng: 18.68906 },
    { lat: 48.959241, lng: 18.688349 },
    { lat: 48.958393, lng: 18.686258 },
    { lat: 48.958349, lng: 18.685858 },
    { lat: 48.958319, lng: 18.685592 },
    { lat: 48.95844, lng: 18.684933 },
    { lat: 48.958594, lng: 18.684096 },
    { lat: 48.958533, lng: 18.683497 },
    { lat: 48.958382, lng: 18.683178 },
    { lat: 48.958124, lng: 18.682636 },
    { lat: 48.957696, lng: 18.681551 },
    { lat: 48.957286, lng: 18.680875 },
    { lat: 48.956516, lng: 18.678743 },
    { lat: 48.956259, lng: 18.677843 },
    { lat: 48.956453, lng: 18.675367 },
    { lat: 48.95655, lng: 18.674738 },
    { lat: 48.956757, lng: 18.673713 },
    { lat: 48.957343, lng: 18.672846 },
    { lat: 48.957416, lng: 18.672269 },
    { lat: 48.95726, lng: 18.671208 },
    { lat: 48.957134, lng: 18.67079 },
    { lat: 48.957247, lng: 18.669871 },
    { lat: 48.957166, lng: 18.669936 },
    { lat: 48.956108, lng: 18.665351 },
    { lat: 48.956734, lng: 18.66368 },
    { lat: 48.956862, lng: 18.662281 },
    { lat: 48.957377, lng: 18.660334 },
    { lat: 48.957468, lng: 18.659843 },
    { lat: 48.957499, lng: 18.65949 },
    { lat: 48.957305, lng: 18.658561 },
    { lat: 48.957371, lng: 18.658356 },
    { lat: 48.95738, lng: 18.658114 },
    { lat: 48.95733, lng: 18.65725 },
    { lat: 48.958573, lng: 18.65298 },
    { lat: 48.958783, lng: 18.651927 },
    { lat: 48.958908, lng: 18.649281 },
    { lat: 48.959053, lng: 18.648787 },
    { lat: 48.959338, lng: 18.648042 },
    { lat: 48.9593794, lng: 18.6478811 },
    { lat: 48.959731, lng: 18.646514 },
    { lat: 48.960316, lng: 18.642777 },
    { lat: 48.961189, lng: 18.640114 },
    { lat: 48.961855, lng: 18.638313 },
    { lat: 48.962792, lng: 18.637108 },
    { lat: 48.963701, lng: 18.635207 },
    { lat: 48.964796, lng: 18.633357 },
    { lat: 48.964883, lng: 18.633187 },
    { lat: 48.965034, lng: 18.631726 },
    { lat: 48.964881, lng: 18.628145 },
    { lat: 48.964879, lng: 18.626938 },
    { lat: 48.964766, lng: 18.626307 },
    { lat: 48.964704, lng: 18.62596 },
    { lat: 48.964587, lng: 18.625652 },
    { lat: 48.964109, lng: 18.624399 },
    { lat: 48.964097, lng: 18.624369 },
    { lat: 48.963985, lng: 18.624102 },
    { lat: 48.963867, lng: 18.623806 },
    { lat: 48.963267, lng: 18.622868 },
    { lat: 48.963201, lng: 18.62252 },
    { lat: 48.963196, lng: 18.622473 },
    { lat: 48.962866, lng: 18.621414 },
    { lat: 48.96269, lng: 18.619941 },
    { lat: 48.962479, lng: 18.618876 },
    { lat: 48.962522, lng: 18.618312 },
    { lat: 48.962838, lng: 18.617476 },
    { lat: 48.963222, lng: 18.615978 },
    { lat: 48.963565, lng: 18.61518 },
    { lat: 48.96369, lng: 18.613444 },
    { lat: 48.963748, lng: 18.613067 },
    { lat: 48.963765, lng: 18.61176 },
    { lat: 48.963727, lng: 18.611484 },
    { lat: 48.963703, lng: 18.611306 },
    { lat: 48.9636866, lng: 18.6111897 },
    { lat: 48.963669, lng: 18.611065 },
    { lat: 48.96338, lng: 18.609066 },
    { lat: 48.963338, lng: 18.607584 },
    { lat: 48.963392, lng: 18.607286 },
    { lat: 48.96344, lng: 18.606361 },
    { lat: 48.963574, lng: 18.605797 },
    { lat: 48.963677, lng: 18.605073 },
    { lat: 48.963619, lng: 18.604256 },
    { lat: 48.963773, lng: 18.603185 },
    { lat: 48.963602, lng: 18.603087 },
    { lat: 48.96343, lng: 18.602988 },
    { lat: 48.962663, lng: 18.60171 },
    { lat: 48.962623, lng: 18.601667 },
    { lat: 48.961763, lng: 18.600759 },
    { lat: 48.961313, lng: 18.600283 },
    { lat: 48.96128, lng: 18.600262 },
    { lat: 48.961023, lng: 18.600089 },
    { lat: 48.960256, lng: 18.599717 },
    { lat: 48.959259, lng: 18.600258 },
    { lat: 48.958484, lng: 18.600128 },
    { lat: 48.958454, lng: 18.600123 },
    { lat: 48.958018, lng: 18.600398 },
    { lat: 48.957807, lng: 18.60053 },
    { lat: 48.957176, lng: 18.601265 },
    { lat: 48.956529, lng: 18.601165 },
    { lat: 48.956418, lng: 18.601141 },
    { lat: 48.9557263, lng: 18.6012605 },
    { lat: 48.9551312, lng: 18.6015812 },
    { lat: 48.954578, lng: 18.6022028 },
    { lat: 48.9539225, lng: 18.6028087 },
    { lat: 48.9534314, lng: 18.603344 },
    { lat: 48.9527381, lng: 18.6044824 },
    { lat: 48.9525173, lng: 18.6050136 },
    { lat: 48.9523791, lng: 18.6051466 },
    { lat: 48.9522331, lng: 18.605178 },
    { lat: 48.9517984, lng: 18.6054464 },
    { lat: 48.9515861, lng: 18.6054393 },
    { lat: 48.9502245, lng: 18.6055408 },
    { lat: 48.9492467, lng: 18.6051215 },
    { lat: 48.9490198, lng: 18.6051388 },
    { lat: 48.9485617, lng: 18.6050111 },
    { lat: 48.9474837, lng: 18.605559 },
    { lat: 48.9474238, lng: 18.6055973 },
    { lat: 48.9470447, lng: 18.6064552 },
    { lat: 48.94696, lng: 18.6068543 },
    { lat: 48.9465879, lng: 18.6076305 },
    { lat: 48.9464459, lng: 18.6082649 },
    { lat: 48.9461293, lng: 18.6087339 },
    { lat: 48.9448627, lng: 18.6094684 },
    { lat: 48.9440301, lng: 18.6090648 },
    { lat: 48.9426269, lng: 18.6089962 },
    { lat: 48.9414816, lng: 18.6095782 },
    { lat: 48.9405879, lng: 18.6093608 },
    { lat: 48.9397511, lng: 18.6088563 },
    { lat: 48.9393462, lng: 18.6086671 },
    { lat: 48.9387146, lng: 18.6085411 },
    { lat: 48.9383394, lng: 18.6081696 },
    { lat: 48.9381238, lng: 18.6080624 },
    { lat: 48.9375385, lng: 18.6072638 },
    { lat: 48.9363247, lng: 18.6074492 },
    { lat: 48.9349047, lng: 18.6079101 },
    { lat: 48.9341888, lng: 18.6077469 },
    { lat: 48.9334514, lng: 18.607902 },
    { lat: 48.9325757, lng: 18.6087098 },
    { lat: 48.9322831, lng: 18.6087403 },
    { lat: 48.9321962, lng: 18.6087822 },
    { lat: 48.9313104, lng: 18.6091333 },
    { lat: 48.9303659, lng: 18.6086826 },
    { lat: 48.929816, lng: 18.608232 },
    { lat: 48.928979, lng: 18.6078897 },
    { lat: 48.9274413, lng: 18.6071069 },
    { lat: 48.9269754, lng: 18.607105 },
    { lat: 48.9267698, lng: 18.6072025 },
    { lat: 48.9262735, lng: 18.607642 },
    { lat: 48.9258981, lng: 18.6078168 },
    { lat: 48.9255989, lng: 18.6079758 },
    { lat: 48.9254553, lng: 18.6082016 },
    { lat: 48.9251055, lng: 18.6086415 },
    { lat: 48.9249383, lng: 18.6086575 },
    { lat: 48.9248105, lng: 18.608711 },
    { lat: 48.9247209, lng: 18.6088512 },
    { lat: 48.9246242, lng: 18.6091268 },
    { lat: 48.9242839, lng: 18.6094275 },
    { lat: 48.9239968, lng: 18.609605 },
    { lat: 48.9232172, lng: 18.6102315 },
    { lat: 48.9230773, lng: 18.6104174 },
    { lat: 48.9230062, lng: 18.6104194 },
    { lat: 48.9229568, lng: 18.6104208 },
    { lat: 48.9225728, lng: 18.6111486 },
    { lat: 48.9223868, lng: 18.6117175 },
    { lat: 48.9221341, lng: 18.611911 },
    { lat: 48.9220758, lng: 18.6119557 },
    { lat: 48.9218483, lng: 18.6120233 },
    { lat: 48.9217713, lng: 18.6121196 },
    { lat: 48.921672, lng: 18.6122696 },
    { lat: 48.9213207, lng: 18.6129725 },
    { lat: 48.9209765, lng: 18.6133884 },
    { lat: 48.9200759, lng: 18.6134832 },
    { lat: 48.9197613, lng: 18.6133991 },
    { lat: 48.91932, lng: 18.6134996 },
    { lat: 48.9190201, lng: 18.6134158 },
    { lat: 48.918718, lng: 18.6135248 },
    { lat: 48.9185156, lng: 18.6137037 },
    { lat: 48.9182496, lng: 18.6141059 },
    { lat: 48.9177025, lng: 18.6143984 },
    { lat: 48.9174649, lng: 18.6143216 },
    { lat: 48.9167356, lng: 18.6146039 },
    { lat: 48.9162916, lng: 18.6147997 },
    { lat: 48.9161897, lng: 18.6150461 },
    { lat: 48.916095, lng: 18.615065 },
    { lat: 48.915967, lng: 18.6151805 },
    { lat: 48.9159223, lng: 18.6152721 },
    { lat: 48.91578, lng: 18.6153119 },
    { lat: 48.915605, lng: 18.615288 },
    { lat: 48.9155364, lng: 18.6153348 },
    { lat: 48.9154649, lng: 18.6152239 },
    { lat: 48.9153948, lng: 18.6152598 },
    { lat: 48.9153528, lng: 18.6153177 },
    { lat: 48.9151898, lng: 18.6153292 },
    { lat: 48.9151416, lng: 18.6153954 },
    { lat: 48.9149593, lng: 18.615362 },
    { lat: 48.9148404, lng: 18.6153749 },
    { lat: 48.9145605, lng: 18.6152026 },
    { lat: 48.9145298, lng: 18.615154 },
    { lat: 48.9145065, lng: 18.6151622 },
    { lat: 48.9144335, lng: 18.6151878 },
    { lat: 48.9142845, lng: 18.6151851 },
    { lat: 48.9142255, lng: 18.6152659 },
    { lat: 48.9140345, lng: 18.6152783 },
    { lat: 48.9139053, lng: 18.6155403 },
    { lat: 48.9138233, lng: 18.6155282 },
    { lat: 48.9137296, lng: 18.6157549 },
    { lat: 48.9134964, lng: 18.6159487 },
    { lat: 48.9133474, lng: 18.6162826 },
    { lat: 48.913261, lng: 18.6164998 },
    { lat: 48.9131933, lng: 18.616602 },
    { lat: 48.9131534, lng: 18.6166623 },
    { lat: 48.9131342, lng: 18.6168507 },
    { lat: 48.9129305, lng: 18.6171728 },
    { lat: 48.9127811, lng: 18.6174362 },
    { lat: 48.9125247, lng: 18.6175166 },
    { lat: 48.9124507, lng: 18.6176531 },
    { lat: 48.9124376, lng: 18.6176772 },
    { lat: 48.912242, lng: 18.6177311 },
    { lat: 48.9122275, lng: 18.6177553 },
    { lat: 48.9121084, lng: 18.6179538 },
    { lat: 48.9115853, lng: 18.6183365 },
    { lat: 48.9114998, lng: 18.6183416 },
    { lat: 48.9114266, lng: 18.6184482 },
    { lat: 48.9113082, lng: 18.6185101 },
    { lat: 48.9112655, lng: 18.6185778 },
    { lat: 48.910956, lng: 18.6186942 },
    { lat: 48.9106668, lng: 18.6187708 },
    { lat: 48.9105202, lng: 18.6188749 },
    { lat: 48.9104199, lng: 18.6189178 },
    { lat: 48.910271, lng: 18.6190233 },
    { lat: 48.9101562, lng: 18.6190296 },
    { lat: 48.9100136, lng: 18.6191236 },
    { lat: 48.9099481, lng: 18.6191413 },
    { lat: 48.9098444, lng: 18.6191032 },
    { lat: 48.9097268, lng: 18.6191396 },
    { lat: 48.9094917, lng: 18.6191493 },
    { lat: 48.9092107, lng: 18.619146 },
    { lat: 48.9091222, lng: 18.6192003 },
    { lat: 48.9088724, lng: 18.6191486 },
    { lat: 48.9086675, lng: 18.6193242 },
    { lat: 48.9082957, lng: 18.6194156 },
    { lat: 48.9082182, lng: 18.6194646 },
    { lat: 48.9081434, lng: 18.6194982 },
    { lat: 48.9080304, lng: 18.6195982 },
    { lat: 48.907845, lng: 18.6195268 },
    { lat: 48.9077787, lng: 18.6195558 },
    { lat: 48.9077313, lng: 18.6195516 },
    { lat: 48.9076735, lng: 18.6195892 },
    { lat: 48.9073184, lng: 18.6196761 },
    { lat: 48.9072396, lng: 18.619679 },
    { lat: 48.9070251, lng: 18.6197363 },
    { lat: 48.9067744, lng: 18.6197551 },
    { lat: 48.906694, lng: 18.6197901 },
    { lat: 48.9065925, lng: 18.6197926 },
    { lat: 48.9064692, lng: 18.6198687 },
    { lat: 48.9063333, lng: 18.619855 },
    { lat: 48.9062297, lng: 18.619965 },
    { lat: 48.906113, lng: 18.6199776 },
    { lat: 48.9058103, lng: 18.6203452 },
    { lat: 48.9056883, lng: 18.6203842 },
    { lat: 48.9056337, lng: 18.6205243 },
    { lat: 48.9053012, lng: 18.6208863 },
    { lat: 48.9052063, lng: 18.6208847 },
    { lat: 48.9050893, lng: 18.6209945 },
    { lat: 48.9050229, lng: 18.6210995 },
    { lat: 48.9049592, lng: 18.621112 },
    { lat: 48.9048999, lng: 18.6212265 },
    { lat: 48.9048839, lng: 18.6213475 },
    { lat: 48.904837, lng: 18.62142 },
    { lat: 48.9047558, lng: 18.6214444 },
    { lat: 48.9046794, lng: 18.6217639 },
    { lat: 48.9046326, lng: 18.6219012 },
    { lat: 48.904617, lng: 18.6221344 },
    { lat: 48.9045702, lng: 18.6222316 },
    { lat: 48.9045523, lng: 18.6224432 },
    { lat: 48.9044626, lng: 18.6225564 },
    { lat: 48.9043522, lng: 18.6228561 },
    { lat: 48.9042167, lng: 18.6229324 },
    { lat: 48.9041981, lng: 18.6230307 },
    { lat: 48.9041435, lng: 18.6230852 },
    { lat: 48.9039297, lng: 18.6234518 },
    { lat: 48.9038685, lng: 18.6235934 },
    { lat: 48.9037362, lng: 18.6237578 },
    { lat: 48.9036006, lng: 18.6237931 },
    { lat: 48.903489, lng: 18.6239822 },
    { lat: 48.9033142, lng: 18.6241744 },
    { lat: 48.9029521, lng: 18.6242969 },
    { lat: 48.9028943, lng: 18.6244406 },
    { lat: 48.902736, lng: 18.6247487 },
    { lat: 48.9027638, lng: 18.6249722 },
    { lat: 48.9027235, lng: 18.6252754 },
    { lat: 48.9026157, lng: 18.6254723 },
    { lat: 48.9025649, lng: 18.6256804 },
    { lat: 48.9024102, lng: 18.6258457 },
    { lat: 48.9023652, lng: 18.6259545 },
    { lat: 48.9021149, lng: 18.6261657 },
    { lat: 48.9020209, lng: 18.626135 },
    { lat: 48.9018881, lng: 18.6260352 },
    { lat: 48.9018282, lng: 18.6260759 },
    { lat: 48.9017451, lng: 18.6259994 },
    { lat: 48.9016916, lng: 18.626011 },
    { lat: 48.9015947, lng: 18.6259484 },
    { lat: 48.9015159, lng: 18.6260176 },
    { lat: 48.9015176, lng: 18.6261322 },
    { lat: 48.9013829, lng: 18.6262911 },
    { lat: 48.9012978, lng: 18.6263217 },
    { lat: 48.9012407, lng: 18.6264341 },
    { lat: 48.9011336, lng: 18.6265441 },
    { lat: 48.9010838, lng: 18.6266649 },
    { lat: 48.9009558, lng: 18.6267416 },
    { lat: 48.9009403, lng: 18.6268705 },
    { lat: 48.9010061, lng: 18.6270043 },
    { lat: 48.9009906, lng: 18.6271794 },
    { lat: 48.9008443, lng: 18.6274168 },
    { lat: 48.9008341, lng: 18.6276238 },
    { lat: 48.9008004, lng: 18.627714 },
    { lat: 48.900776, lng: 18.6278457 },
    { lat: 48.9006036, lng: 18.628081 },
    { lat: 48.9002977, lng: 18.6280749 },
    { lat: 48.9001607, lng: 18.6281513 },
    { lat: 48.9000151, lng: 18.6281073 },
    { lat: 48.8997857, lng: 18.6281614 },
    { lat: 48.8994941, lng: 18.6284443 },
    { lat: 48.8994482, lng: 18.628537 },
    { lat: 48.8993907, lng: 18.6285348 },
    { lat: 48.8992495, lng: 18.6286357 },
    { lat: 48.899168, lng: 18.6287777 },
    { lat: 48.898963, lng: 18.628846 },
    { lat: 48.8988672, lng: 18.6289933 },
    { lat: 48.8988368, lng: 18.6291411 },
    { lat: 48.8987373, lng: 18.6292427 },
    { lat: 48.8986713, lng: 18.6292325 },
    { lat: 48.8985274, lng: 18.6292642 },
    { lat: 48.8984355, lng: 18.6293569 },
    { lat: 48.8983119, lng: 18.6292836 },
    { lat: 48.8980737, lng: 18.6291732 },
    { lat: 48.8979885, lng: 18.6292009 },
    { lat: 48.8979194, lng: 18.6293241 },
    { lat: 48.8975519, lng: 18.6293669 },
    { lat: 48.8973651, lng: 18.6293361 },
    { lat: 48.8972415, lng: 18.6293004 },
    { lat: 48.8971435, lng: 18.6293229 },
    { lat: 48.8970856, lng: 18.6293189 },
    { lat: 48.8970234, lng: 18.629275 },
    { lat: 48.8969711, lng: 18.6293262 },
    { lat: 48.8966602, lng: 18.6293993 },
    { lat: 48.8965647, lng: 18.6293623 },
    { lat: 48.8964463, lng: 18.6294475 },
    { lat: 48.8963682, lng: 18.62944 },
    { lat: 48.8963295, lng: 18.6294146 },
    { lat: 48.8962807, lng: 18.6294307 },
    { lat: 48.8961544, lng: 18.6292613 },
    { lat: 48.8960259, lng: 18.6292624 },
    { lat: 48.8959521, lng: 18.6293184 },
    { lat: 48.8959376, lng: 18.6294443 },
    { lat: 48.8957768, lng: 18.6294967 },
    { lat: 48.89569, lng: 18.6295619 },
    { lat: 48.8955542, lng: 18.6295764 },
    { lat: 48.8954391, lng: 18.6297215 },
    { lat: 48.8950922, lng: 18.6297499 },
    { lat: 48.8950262, lng: 18.6298458 },
    { lat: 48.8948873, lng: 18.6298812 },
    { lat: 48.8946891, lng: 18.6298476 },
    { lat: 48.8945222, lng: 18.6300807 },
    { lat: 48.8940822, lng: 18.6302019 },
    { lat: 48.8940983, lng: 18.6302282 },
    { lat: 48.8937944, lng: 18.6303883 },
    { lat: 48.893676, lng: 18.6303272 },
    { lat: 48.8935705, lng: 18.6303994 },
    { lat: 48.8935186, lng: 18.6305022 },
    { lat: 48.8934351, lng: 18.6305239 },
    { lat: 48.8933738, lng: 18.6306415 },
    { lat: 48.8931221, lng: 18.6307023 },
    { lat: 48.8929474, lng: 18.6306629 },
    { lat: 48.8928943, lng: 18.6307468 },
    { lat: 48.8928209, lng: 18.6307444 },
    { lat: 48.8927326, lng: 18.6307778 },
    { lat: 48.8926832, lng: 18.6306976 },
    { lat: 48.892536, lng: 18.6307293 },
    { lat: 48.8925113, lng: 18.6306474 },
    { lat: 48.8923911, lng: 18.630574 },
    { lat: 48.8922884, lng: 18.6305935 },
    { lat: 48.8922627, lng: 18.6306612 },
    { lat: 48.891994, lng: 18.6308025 },
    { lat: 48.8918609, lng: 18.630887 },
    { lat: 48.8918383, lng: 18.6309715 },
    { lat: 48.8917787, lng: 18.6309939 },
    { lat: 48.8917051, lng: 18.6310937 },
    { lat: 48.8913928, lng: 18.6312676 },
    { lat: 48.8913478, lng: 18.6313936 },
    { lat: 48.8908339, lng: 18.6324948 },
    { lat: 48.8906517, lng: 18.6325714 },
    { lat: 48.8906111, lng: 18.6327792 },
    { lat: 48.8906574, lng: 18.633181 },
    { lat: 48.8906578, lng: 18.6334444 },
    { lat: 48.8903728, lng: 18.6340182 },
    { lat: 48.8902337, lng: 18.6341568 },
    { lat: 48.8902015, lng: 18.634293 },
    { lat: 48.8899651, lng: 18.6345576 },
    { lat: 48.8899185, lng: 18.6348425 },
    { lat: 48.8897484, lng: 18.6352072 },
    { lat: 48.889744, lng: 18.6353984 },
    { lat: 48.8897133, lng: 18.6355398 },
    { lat: 48.8895551, lng: 18.6358995 },
    { lat: 48.8895492, lng: 18.6360556 },
    { lat: 48.8893318, lng: 18.6363774 },
    { lat: 48.8893156, lng: 18.6365173 },
    { lat: 48.8892109, lng: 18.6366273 },
    { lat: 48.8889911, lng: 18.6366279 },
    { lat: 48.8887034, lng: 18.6368604 },
    { lat: 48.8886637, lng: 18.6369792 },
    { lat: 48.8885825, lng: 18.6370036 },
    { lat: 48.8885345, lng: 18.6371174 },
    { lat: 48.8882636, lng: 18.6372177 },
    { lat: 48.8881012, lng: 18.6375136 },
    { lat: 48.8880142, lng: 18.6375297 },
    { lat: 48.8879516, lng: 18.6376066 },
    { lat: 48.8877844, lng: 18.6375773 },
    { lat: 48.8876899, lng: 18.6377115 },
    { lat: 48.8875184, lng: 18.637814 },
    { lat: 48.8874725, lng: 18.6379454 },
    { lat: 48.8870652, lng: 18.6382615 },
    { lat: 48.886903, lng: 18.6383484 },
    { lat: 48.8867953, lng: 18.6384871 },
    { lat: 48.8866676, lng: 18.6385881 },
    { lat: 48.8865795, lng: 18.6385832 },
    { lat: 48.8862517, lng: 18.6383798 },
    { lat: 48.8862137, lng: 18.6384705 },
    { lat: 48.8862617, lng: 18.6386284 },
    { lat: 48.8862786, lng: 18.6389449 },
    { lat: 48.8863952, lng: 18.63908 },
    { lat: 48.886502, lng: 18.639074 },
    { lat: 48.8869923, lng: 18.6388775 },
    { lat: 48.8872619, lng: 18.6388153 },
    { lat: 48.8876117, lng: 18.6390941 },
    { lat: 48.8876367, lng: 18.6392043 },
    { lat: 48.8876239, lng: 18.6393668 },
    { lat: 48.8876315, lng: 18.6395708 },
    { lat: 48.8876569, lng: 18.6399168 },
    { lat: 48.8877864, lng: 18.6402532 },
    { lat: 48.8879724, lng: 18.6404213 },
    { lat: 48.8884462, lng: 18.6402114 },
    { lat: 48.8884049, lng: 18.6404523 },
    { lat: 48.8884596, lng: 18.6407979 },
    { lat: 48.8886393, lng: 18.6414191 },
    { lat: 48.8885706, lng: 18.6421372 },
    { lat: 48.8885922, lng: 18.6425183 },
    { lat: 48.8887885, lng: 18.6432379 },
    { lat: 48.8888074, lng: 18.6434635 },
    { lat: 48.8887864, lng: 18.6438298 },
    { lat: 48.8887049, lng: 18.6440567 },
    { lat: 48.8885274, lng: 18.6443656 },
    { lat: 48.8883012, lng: 18.6448603 },
    { lat: 48.8881653, lng: 18.6452727 },
    { lat: 48.8881135, lng: 18.6454654 },
    { lat: 48.8880324, lng: 18.6458449 },
    { lat: 48.8880197, lng: 18.6461783 },
    { lat: 48.8880588, lng: 18.6462778 },
    { lat: 48.8880842, lng: 18.6467531 },
    { lat: 48.888443, lng: 18.6475989 },
    { lat: 48.8888355, lng: 18.6483187 },
    { lat: 48.8888553, lng: 18.6494466 },
    { lat: 48.8888798, lng: 18.6500117 },
    { lat: 48.8889879, lng: 18.6509833 },
    { lat: 48.8890292, lng: 18.6512921 },
    { lat: 48.8890893, lng: 18.6516969 },
    { lat: 48.8891841, lng: 18.6522899 },
    { lat: 48.8892694, lng: 18.653066 },
    { lat: 48.8897046, lng: 18.6530008 },
    { lat: 48.8898019, lng: 18.6536591 },
    { lat: 48.8898188, lng: 18.6541455 },
    { lat: 48.8901068, lng: 18.6549588 },
    { lat: 48.890301, lng: 18.655936 },
    { lat: 48.8903565, lng: 18.6562994 },
    { lat: 48.890404, lng: 18.656976 },
    { lat: 48.8903864, lng: 18.6570727 },
    { lat: 48.8904118, lng: 18.6573588 },
    { lat: 48.8904613, lng: 18.6576073 },
    { lat: 48.8905252, lng: 18.6578764 },
    { lat: 48.8906359, lng: 18.658175 },
    { lat: 48.8907112, lng: 18.6590826 },
    { lat: 48.8905156, lng: 18.6599388 },
    { lat: 48.8904579, lng: 18.6607576 },
    { lat: 48.8903865, lng: 18.6611351 },
    { lat: 48.8902676, lng: 18.6617823 },
    { lat: 48.8901281, lng: 18.6623169 },
    { lat: 48.8900713, lng: 18.6625192 },
    { lat: 48.8900253, lng: 18.6633108 },
    { lat: 48.88979, lng: 18.6639962 },
    { lat: 48.8896917, lng: 18.6644985 },
    { lat: 48.8896286, lng: 18.6648793 },
    { lat: 48.8895627, lng: 18.6654654 },
    { lat: 48.8894484, lng: 18.6660482 },
    { lat: 48.889434, lng: 18.6661428 },
    { lat: 48.8894527, lng: 18.6667469 },
    { lat: 48.8895415, lng: 18.6670385 },
    { lat: 48.8897997, lng: 18.6673954 },
    { lat: 48.8895878, lng: 18.6680987 },
    { lat: 48.8898836, lng: 18.6685671 },
    { lat: 48.8901143, lng: 18.6687951 },
    { lat: 48.8904372, lng: 18.6692225 },
    { lat: 48.8904675, lng: 18.6693037 },
    { lat: 48.8904244, lng: 18.6695323 },
    { lat: 48.8906133, lng: 18.6703919 },
    { lat: 48.8906472, lng: 18.670461 },
    { lat: 48.8906891, lng: 18.6705464 },
    { lat: 48.8907735, lng: 18.6706035 },
    { lat: 48.8908722, lng: 18.6705935 },
    { lat: 48.8909312, lng: 18.6705386 },
    { lat: 48.8910602, lng: 18.6704401 },
    { lat: 48.891134, lng: 18.6704275 },
    { lat: 48.8912057, lng: 18.6705047 },
    { lat: 48.8912668, lng: 18.6706137 },
    { lat: 48.8913498, lng: 18.6706472 },
    { lat: 48.8916173, lng: 18.6705887 },
    { lat: 48.8918354, lng: 18.6709563 },
    { lat: 48.8924876, lng: 18.6714309 },
    { lat: 48.8926795, lng: 18.6719633 },
    { lat: 48.8928371, lng: 18.672234 },
    { lat: 48.89297, lng: 18.6723779 },
    { lat: 48.8931483, lng: 18.6724199 },
    { lat: 48.8936397, lng: 18.67263 },
    { lat: 48.8937937, lng: 18.6734459 },
    { lat: 48.8945548, lng: 18.6742037 },
    { lat: 48.8950883, lng: 18.6754987 },
    { lat: 48.8958557, lng: 18.6763808 },
    { lat: 48.8961689, lng: 18.6764404 },
    { lat: 48.8965929, lng: 18.6769244 },
    { lat: 48.8984016, lng: 18.677851 },
    { lat: 48.8997303, lng: 18.67896 },
    { lat: 48.899887, lng: 18.6790681 },
    { lat: 48.900347, lng: 18.6797861 },
    { lat: 48.9004825, lng: 18.6801696 },
    { lat: 48.9003932, lng: 18.6807185 },
    { lat: 48.9003838, lng: 18.6811144 },
    { lat: 48.9004038, lng: 18.6813632 },
    { lat: 48.9008443, lng: 18.6825869 },
    { lat: 48.9011101, lng: 18.6828577 },
    { lat: 48.9023335, lng: 18.6830646 },
    { lat: 48.9024728, lng: 18.6831383 },
    { lat: 48.9027759, lng: 18.6835371 },
    { lat: 48.9032817, lng: 18.6835467 },
    { lat: 48.9036193, lng: 18.6836822 },
    { lat: 48.9038527, lng: 18.6838942 },
    { lat: 48.9045456, lng: 18.6858368 },
    { lat: 48.9046775, lng: 18.6867951 },
    { lat: 48.9053107, lng: 18.6875663 },
    { lat: 48.9053748, lng: 18.6876714 },
    { lat: 48.9054672, lng: 18.6878616 },
    { lat: 48.9055312, lng: 18.6881517 },
    { lat: 48.9063616, lng: 18.6893936 },
    { lat: 48.9064404, lng: 18.6895199 },
    { lat: 48.9066521, lng: 18.6899601 },
    { lat: 48.9071659, lng: 18.6903538 },
    { lat: 48.9073178, lng: 18.6907938 },
    { lat: 48.9080163, lng: 18.6913137 },
    { lat: 48.9085999, lng: 18.6924303 },
    { lat: 48.909602, lng: 18.693812 },
    { lat: 48.910784, lng: 18.693572 },
    { lat: 48.910973, lng: 18.693165 },
    { lat: 48.911276, lng: 18.693364 },
    { lat: 48.911782, lng: 18.693191 },
    { lat: 48.911939, lng: 18.693616 },
    { lat: 48.912448, lng: 18.694165 },
    { lat: 48.912901, lng: 18.694425 },
    { lat: 48.913139, lng: 18.69494 },
    { lat: 48.913894, lng: 18.694913 },
    { lat: 48.914074, lng: 18.695149 },
    { lat: 48.914272, lng: 18.695424 },
    { lat: 48.914492, lng: 18.695715 },
    { lat: 48.914683, lng: 18.695965 },
    { lat: 48.915469, lng: 18.696443 },
    { lat: 48.916236, lng: 18.696986 },
    { lat: 48.916818, lng: 18.697114 },
    { lat: 48.917274, lng: 18.697237 },
    { lat: 48.917674, lng: 18.697564 },
    { lat: 48.918175, lng: 18.697545 },
    { lat: 48.918432, lng: 18.697041 },
    { lat: 48.918634, lng: 18.696666 },
    { lat: 48.919439, lng: 18.697241 },
    { lat: 48.919963, lng: 18.696354 },
    { lat: 48.920552, lng: 18.695313 },
    { lat: 48.921073, lng: 18.695323 },
    { lat: 48.92153, lng: 18.695337 },
    { lat: 48.922118, lng: 18.696241 },
    { lat: 48.922789, lng: 18.696483 },
    { lat: 48.923477, lng: 18.696357 },
    { lat: 48.923814, lng: 18.695735 },
    { lat: 48.924098, lng: 18.695591 },
    { lat: 48.924329, lng: 18.695685 },
    { lat: 48.924527, lng: 18.695136 },
    { lat: 48.924939, lng: 18.694408 },
    { lat: 48.925346, lng: 18.693749 },
    { lat: 48.925612, lng: 18.693749 },
    { lat: 48.925874, lng: 18.693843 },
    { lat: 48.926414, lng: 18.694038 },
    { lat: 48.926651, lng: 18.694091 },
    { lat: 48.926784, lng: 18.694264 },
    { lat: 48.927097, lng: 18.694304 },
    { lat: 48.92736, lng: 18.694202 },
    { lat: 48.927928, lng: 18.694597 },
    { lat: 48.927837, lng: 18.694983 },
    { lat: 48.927398, lng: 18.696017 },
    { lat: 48.92724, lng: 18.69674 },
    { lat: 48.927364, lng: 18.697822 },
    { lat: 48.928035, lng: 18.69804 },
    { lat: 48.92839, lng: 18.698353 },
    { lat: 48.928516, lng: 18.698688 },
    { lat: 48.927987, lng: 18.699698 },
    { lat: 48.927394, lng: 18.700809 },
    { lat: 48.926861, lng: 18.701846 },
    { lat: 48.926369, lng: 18.702768 },
    { lat: 48.926381, lng: 18.702999 },
    { lat: 48.926647, lng: 18.702905 },
    { lat: 48.928648, lng: 18.703268 },
    { lat: 48.930242, lng: 18.703353 },
    { lat: 48.93064, lng: 18.703606 },
    { lat: 48.930698, lng: 18.703967 },
    { lat: 48.930569, lng: 18.704861 },
    { lat: 48.930174, lng: 18.705729 },
    { lat: 48.92957, lng: 18.70668 },
    { lat: 48.92961, lng: 18.707165 },
    { lat: 48.929048, lng: 18.707948 },
    { lat: 48.928483, lng: 18.708729 },
    { lat: 48.928944, lng: 18.709637 },
    { lat: 48.929235, lng: 18.710382 },
    { lat: 48.929167, lng: 18.710664 },
    { lat: 48.929689, lng: 18.710704 },
    { lat: 48.930199, lng: 18.710444 },
    { lat: 48.930421, lng: 18.710173 },
    { lat: 48.930735, lng: 18.709917 },
    { lat: 48.930811, lng: 18.709862 },
    { lat: 48.930914, lng: 18.70981 },
    { lat: 48.931021, lng: 18.709757 },
    { lat: 48.931145, lng: 18.709695 },
    { lat: 48.931603, lng: 18.710233 },
    { lat: 48.9326826, lng: 18.7114505 },
    { lat: 48.934758, lng: 18.713731 },
    { lat: 48.935957, lng: 18.713803 },
    { lat: 48.937378, lng: 18.717098 },
    { lat: 48.937419, lng: 18.717192 },
    { lat: 48.93611, lng: 18.718789 },
    { lat: 48.93604, lng: 18.71947 },
    { lat: 48.936203, lng: 18.720128 },
    { lat: 48.937493, lng: 18.720277 },
  ],
  Lazany: [
    { lat: 48.7925702, lng: 18.6248482 },
    { lat: 48.7925401, lng: 18.6249166 },
    { lat: 48.7924888, lng: 18.6250331 },
    { lat: 48.7924596, lng: 18.6250999 },
    { lat: 48.7923954, lng: 18.6252467 },
    { lat: 48.7923271, lng: 18.6254029 },
    { lat: 48.792294, lng: 18.6254781 },
    { lat: 48.7913408, lng: 18.6275975 },
    { lat: 48.7912472, lng: 18.6278932 },
    { lat: 48.7914523, lng: 18.6282677 },
    { lat: 48.7919336, lng: 18.6288706 },
    { lat: 48.7921989, lng: 18.6291293 },
    { lat: 48.7922755, lng: 18.629232 },
    { lat: 48.792475, lng: 18.6295237 },
    { lat: 48.7926057, lng: 18.6297727 },
    { lat: 48.7926652, lng: 18.6299557 },
    { lat: 48.792804, lng: 18.6302532 },
    { lat: 48.792875, lng: 18.6303355 },
    { lat: 48.7931015, lng: 18.630562 },
    { lat: 48.7931901, lng: 18.6306395 },
    { lat: 48.7934902, lng: 18.6308705 },
    { lat: 48.7936944, lng: 18.6310178 },
    { lat: 48.7944788, lng: 18.6315371 },
    { lat: 48.7954636, lng: 18.6324002 },
    { lat: 48.7953373, lng: 18.6328203 },
    { lat: 48.8019094, lng: 18.6344854 },
    { lat: 48.8023606, lng: 18.6346135 },
    { lat: 48.8027419, lng: 18.6350332 },
    { lat: 48.8029351, lng: 18.6342603 },
    { lat: 48.8028734, lng: 18.634036 },
    { lat: 48.8029961, lng: 18.6337583 },
    { lat: 48.8032421, lng: 18.6329592 },
    { lat: 48.8033021, lng: 18.632818 },
    { lat: 48.8032134, lng: 18.6325974 },
    { lat: 48.8031499, lng: 18.632377 },
    { lat: 48.8030773, lng: 18.6320993 },
    { lat: 48.8030397, lng: 18.6318996 },
    { lat: 48.8029921, lng: 18.6317495 },
    { lat: 48.8029132, lng: 18.6315599 },
    { lat: 48.8028188, lng: 18.6314399 },
    { lat: 48.8025932, lng: 18.6312526 },
    { lat: 48.8021831, lng: 18.6310923 },
    { lat: 48.8019563, lng: 18.6311509 },
    { lat: 48.801812, lng: 18.6311976 },
    { lat: 48.801655, lng: 18.6312756 },
    { lat: 48.8014336, lng: 18.6314399 },
    { lat: 48.8013385, lng: 18.6315167 },
    { lat: 48.8011061, lng: 18.6316175 },
    { lat: 48.8010334, lng: 18.6312934 },
    { lat: 48.8010434, lng: 18.630954 },
    { lat: 48.8010565, lng: 18.6308429 },
    { lat: 48.8010568, lng: 18.6307191 },
    { lat: 48.80097, lng: 18.6303012 },
    { lat: 48.8009385, lng: 18.6300922 },
    { lat: 48.8009191, lng: 18.6298574 },
    { lat: 48.8009218, lng: 18.6295075 },
    { lat: 48.8009301, lng: 18.6293673 },
    { lat: 48.8009632, lng: 18.6291032 },
    { lat: 48.8009565, lng: 18.628851 },
    { lat: 48.8008466, lng: 18.6284238 },
    { lat: 48.8007398, lng: 18.628262 },
    { lat: 48.8007013, lng: 18.6282356 },
    { lat: 48.8006326, lng: 18.6282372 },
    { lat: 48.8005426, lng: 18.6282862 },
    { lat: 48.8004958, lng: 18.6283603 },
    { lat: 48.8004413, lng: 18.628499 },
    { lat: 48.8001801, lng: 18.6290528 },
    { lat: 48.8000341, lng: 18.6292357 },
    { lat: 48.7998502, lng: 18.6293799 },
    { lat: 48.799781, lng: 18.6293723 },
    { lat: 48.7995, lng: 18.6294112 },
    { lat: 48.7992922, lng: 18.6294503 },
    { lat: 48.7991475, lng: 18.6294849 },
    { lat: 48.799098, lng: 18.6294874 },
    { lat: 48.7989849, lng: 18.6294402 },
    { lat: 48.7989375, lng: 18.6293945 },
    { lat: 48.7987244, lng: 18.6291223 },
    { lat: 48.7985525, lng: 18.6288601 },
    { lat: 48.7984864, lng: 18.6285756 },
    { lat: 48.798474, lng: 18.628473 },
    { lat: 48.7984703, lng: 18.6282975 },
    { lat: 48.7985313, lng: 18.6280031 },
    { lat: 48.7986117, lng: 18.6278018 },
    { lat: 48.7987301, lng: 18.6276531 },
    { lat: 48.7988377, lng: 18.6274953 },
    { lat: 48.7988783, lng: 18.6274098 },
    { lat: 48.7989269, lng: 18.6272013 },
    { lat: 48.7989408, lng: 18.6269757 },
    { lat: 48.7988982, lng: 18.6267502 },
    { lat: 48.7988659, lng: 18.6266944 },
    { lat: 48.7987734, lng: 18.626592 },
    { lat: 48.7986432, lng: 18.6263937 },
    { lat: 48.7985574, lng: 18.6263066 },
    { lat: 48.7983277, lng: 18.6261871 },
    { lat: 48.7981879, lng: 18.6261467 },
    { lat: 48.7982185, lng: 18.6260471 },
    { lat: 48.7983743, lng: 18.6257131 },
    { lat: 48.7984915, lng: 18.6254797 },
    { lat: 48.7985545, lng: 18.6252055 },
    { lat: 48.7986818, lng: 18.6249476 },
    { lat: 48.7987187, lng: 18.6247545 },
    { lat: 48.798807, lng: 18.6245687 },
    { lat: 48.7988491, lng: 18.6245993 },
    { lat: 48.7989018, lng: 18.624591 },
    { lat: 48.7997647, lng: 18.6252247 },
    { lat: 48.8001424, lng: 18.6254712 },
    { lat: 48.8003007, lng: 18.6255462 },
    { lat: 48.8005074, lng: 18.6256134 },
    { lat: 48.8006836, lng: 18.6256604 },
    { lat: 48.8009004, lng: 18.6256842 },
    { lat: 48.8023897, lng: 18.6269538 },
    { lat: 48.8026353, lng: 18.6271165 },
    { lat: 48.8030093, lng: 18.6267939 },
    { lat: 48.8032389, lng: 18.6264919 },
    { lat: 48.8033648, lng: 18.6262525 },
    { lat: 48.8034541, lng: 18.6261109 },
    { lat: 48.8037116, lng: 18.625823 },
    { lat: 48.8043086, lng: 18.6260991 },
    { lat: 48.8045338, lng: 18.6261777 },
    { lat: 48.8045561, lng: 18.6266558 },
    { lat: 48.8051639, lng: 18.626781 },
    { lat: 48.8052595, lng: 18.6268201 },
    { lat: 48.8058942, lng: 18.6271688 },
    { lat: 48.8062422, lng: 18.6273908 },
    { lat: 48.8065302, lng: 18.6275876 },
    { lat: 48.8067227, lng: 18.627726 },
    { lat: 48.8069541, lng: 18.6278589 },
    { lat: 48.8069643, lng: 18.6278991 },
    { lat: 48.8073139, lng: 18.6281298 },
    { lat: 48.8074574, lng: 18.6282006 },
    { lat: 48.8075042, lng: 18.6288382 },
    { lat: 48.8074463, lng: 18.6293155 },
    { lat: 48.8072441, lng: 18.6294362 },
    { lat: 48.8072469, lng: 18.6294656 },
    { lat: 48.8072436, lng: 18.6295096 },
    { lat: 48.8071859, lng: 18.6297157 },
    { lat: 48.8072141, lng: 18.6300121 },
    { lat: 48.8072473, lng: 18.6302978 },
    { lat: 48.8073062, lng: 18.6306366 },
    { lat: 48.8077492, lng: 18.630547 },
    { lat: 48.8077771, lng: 18.6307715 },
    { lat: 48.8080283, lng: 18.630683 },
    { lat: 48.808191, lng: 18.6306054 },
    { lat: 48.8087465, lng: 18.6304036 },
    { lat: 48.8093741, lng: 18.630266 },
    { lat: 48.8099445, lng: 18.6301801 },
    { lat: 48.8100765, lng: 18.6302601 },
    { lat: 48.810187, lng: 18.6304089 },
    { lat: 48.8103061, lng: 18.6305271 },
    { lat: 48.8103587, lng: 18.6306262 },
    { lat: 48.8105467, lng: 18.6307662 },
    { lat: 48.8106911, lng: 18.6308494 },
    { lat: 48.8111001, lng: 18.6310324 },
    { lat: 48.8111537, lng: 18.6317976 },
    { lat: 48.8112066, lng: 18.6321976 },
    { lat: 48.8112434, lng: 18.6325678 },
    { lat: 48.8112851, lng: 18.6330486 },
    { lat: 48.8113119, lng: 18.6333205 },
    { lat: 48.8115472, lng: 18.6334601 },
    { lat: 48.8117231, lng: 18.6335219 },
    { lat: 48.8119263, lng: 18.6335649 },
    { lat: 48.8120796, lng: 18.633629 },
    { lat: 48.8122704, lng: 18.6337802 },
    { lat: 48.8125324, lng: 18.6340794 },
    { lat: 48.8129016, lng: 18.6345071 },
    { lat: 48.8130716, lng: 18.6346937 },
    { lat: 48.8131515, lng: 18.6348138 },
    { lat: 48.8131644, lng: 18.6348022 },
    { lat: 48.8131934, lng: 18.6348409 },
    { lat: 48.8138057, lng: 18.6350258 },
    { lat: 48.8143005, lng: 18.6351242 },
    { lat: 48.8146216, lng: 18.6352028 },
    { lat: 48.8147229, lng: 18.6351786 },
    { lat: 48.8156794, lng: 18.635012 },
    { lat: 48.8162706, lng: 18.6348674 },
    { lat: 48.8164814, lng: 18.6361907 },
    { lat: 48.8165469, lng: 18.636173 },
    { lat: 48.8167527, lng: 18.636036 },
    { lat: 48.8171294, lng: 18.6358237 },
    { lat: 48.8173632, lng: 18.6358962 },
    { lat: 48.817443, lng: 18.6358904 },
    { lat: 48.8176825, lng: 18.6357507 },
    { lat: 48.817748, lng: 18.6357505 },
    { lat: 48.8179047, lng: 18.6356618 },
    { lat: 48.8179127, lng: 18.635561 },
    { lat: 48.817942, lng: 18.6354776 },
    { lat: 48.8180505, lng: 18.6354603 },
    { lat: 48.8181073, lng: 18.6354247 },
    { lat: 48.8182088, lng: 18.6351936 },
    { lat: 48.8182728, lng: 18.6357347 },
    { lat: 48.8185765, lng: 18.6356777 },
    { lat: 48.8188666, lng: 18.635579 },
    { lat: 48.8192714, lng: 18.6355125 },
    { lat: 48.8196356, lng: 18.6353847 },
    { lat: 48.8202036, lng: 18.6351649 },
    { lat: 48.8207867, lng: 18.6349547 },
    { lat: 48.8209957, lng: 18.6348585 },
    { lat: 48.8213693, lng: 18.6350069 },
    { lat: 48.8214224, lng: 18.6350727 },
    { lat: 48.8216615, lng: 18.635136 },
    { lat: 48.8217679, lng: 18.635187 },
    { lat: 48.8218415, lng: 18.6352546 },
    { lat: 48.8223735, lng: 18.6351831 },
    { lat: 48.8232188, lng: 18.6350885 },
    { lat: 48.8238858, lng: 18.6350369 },
    { lat: 48.8244645, lng: 18.6349522 },
    { lat: 48.8246104, lng: 18.6349396 },
    { lat: 48.8247509, lng: 18.6347434 },
    { lat: 48.8255221, lng: 18.635053 },
    { lat: 48.8264565, lng: 18.6349804 },
    { lat: 48.826525, lng: 18.6349097 },
    { lat: 48.8266054, lng: 18.6348536 },
    { lat: 48.8266275, lng: 18.6348019 },
    { lat: 48.8266836, lng: 18.6347771 },
    { lat: 48.826827, lng: 18.6346084 },
    { lat: 48.8268911, lng: 18.6345665 },
    { lat: 48.826933, lng: 18.6345059 },
    { lat: 48.8270174, lng: 18.6344397 },
    { lat: 48.8270617, lng: 18.634358 },
    { lat: 48.8271395, lng: 18.6342958 },
    { lat: 48.8272028, lng: 18.6341953 },
    { lat: 48.8272911, lng: 18.6339464 },
    { lat: 48.8272526, lng: 18.6338405 },
    { lat: 48.8272897, lng: 18.6338138 },
    { lat: 48.827352, lng: 18.6336321 },
    { lat: 48.8273936, lng: 18.6333983 },
    { lat: 48.8274653, lng: 18.6332008 },
    { lat: 48.8275007, lng: 18.6331428 },
    { lat: 48.827498, lng: 18.6330529 },
    { lat: 48.8276136, lng: 18.6328715 },
    { lat: 48.8276551, lng: 18.6327823 },
    { lat: 48.8280605, lng: 18.6327529 },
    { lat: 48.8281798, lng: 18.6328094 },
    { lat: 48.8284942, lng: 18.6330131 },
    { lat: 48.828564, lng: 18.6330321 },
    { lat: 48.8287337, lng: 18.6330012 },
    { lat: 48.8289738, lng: 18.6328961 },
    { lat: 48.8291562, lng: 18.6328017 },
    { lat: 48.8293779, lng: 18.6327104 },
    { lat: 48.8299691, lng: 18.6321247 },
    { lat: 48.8300618, lng: 18.6320027 },
    { lat: 48.830124, lng: 18.6319854 },
    { lat: 48.8302516, lng: 18.6320907 },
    { lat: 48.8303659, lng: 18.6322048 },
    { lat: 48.8304791, lng: 18.6322968 },
    { lat: 48.8306861, lng: 18.6324153 },
    { lat: 48.8307729, lng: 18.6324783 },
    { lat: 48.8309784, lng: 18.6325866 },
    { lat: 48.8311889, lng: 18.6327046 },
    { lat: 48.8316292, lng: 18.6328398 },
    { lat: 48.8316915, lng: 18.6328429 },
    { lat: 48.8318724, lng: 18.6328099 },
    { lat: 48.8320508, lng: 18.6327923 },
    { lat: 48.8324183, lng: 18.6327696 },
    { lat: 48.8329196, lng: 18.6328222 },
    { lat: 48.8330162, lng: 18.6328561 },
    { lat: 48.8331302, lng: 18.6329198 },
    { lat: 48.8332958, lng: 18.6328972 },
    { lat: 48.8333708, lng: 18.6319361 },
    { lat: 48.8337676, lng: 18.6319757 },
    { lat: 48.8339346, lng: 18.6319358 },
    { lat: 48.8342477, lng: 18.6317577 },
    { lat: 48.8342685, lng: 18.6309461 },
    { lat: 48.8344996, lng: 18.6308629 },
    { lat: 48.8345809, lng: 18.6308228 },
    { lat: 48.8347956, lng: 18.6308071 },
    { lat: 48.8355119, lng: 18.6309381 },
    { lat: 48.8355854, lng: 18.6311267 },
    { lat: 48.8356436, lng: 18.6311646 },
    { lat: 48.8358353, lng: 18.631204 },
    { lat: 48.8360548, lng: 18.6310929 },
    { lat: 48.836204, lng: 18.6306972 },
    { lat: 48.8362426, lng: 18.6305141 },
    { lat: 48.8364566, lng: 18.6305512 },
    { lat: 48.8366462, lng: 18.6304895 },
    { lat: 48.8367657, lng: 18.6304057 },
    { lat: 48.8368329, lng: 18.6303319 },
    { lat: 48.8370123, lng: 18.6301019 },
    { lat: 48.8370524, lng: 18.6300708 },
    { lat: 48.8371476, lng: 18.6300598 },
    { lat: 48.8371967, lng: 18.630028 },
    { lat: 48.8372722, lng: 18.6299421 },
    { lat: 48.8374853, lng: 18.6297726 },
    { lat: 48.8376434, lng: 18.6297166 },
    { lat: 48.8377653, lng: 18.6297153 },
    { lat: 48.8378587, lng: 18.6296901 },
    { lat: 48.8379857, lng: 18.6295771 },
    { lat: 48.8381138, lng: 18.6294214 },
    { lat: 48.8381994, lng: 18.6293786 },
    { lat: 48.8382811, lng: 18.6293636 },
    { lat: 48.8383958, lng: 18.6293176 },
    { lat: 48.8385569, lng: 18.6291656 },
    { lat: 48.8386288, lng: 18.6291629 },
    { lat: 48.8386925, lng: 18.6291915 },
    { lat: 48.8387813, lng: 18.6292082 },
    { lat: 48.8388632, lng: 18.6291791 },
    { lat: 48.8389807, lng: 18.6290791 },
    { lat: 48.8390286, lng: 18.6289794 },
    { lat: 48.8391074, lng: 18.6289593 },
    { lat: 48.8391801, lng: 18.6289894 },
    { lat: 48.8400558, lng: 18.628347 },
    { lat: 48.8406773, lng: 18.6281549 },
    { lat: 48.8413004, lng: 18.627453 },
    { lat: 48.8407009, lng: 18.625675 },
    { lat: 48.8413677, lng: 18.6250511 },
    { lat: 48.8425404, lng: 18.624139 },
    { lat: 48.842559, lng: 18.6241062 },
    { lat: 48.8422631, lng: 18.6233843 },
    { lat: 48.8420112, lng: 18.6226219 },
    { lat: 48.8417407, lng: 18.6220171 },
    { lat: 48.8416232, lng: 18.6217838 },
    { lat: 48.8415391, lng: 18.6216642 },
    { lat: 48.8410743, lng: 18.6209667 },
    { lat: 48.840912, lng: 18.6203211 },
    { lat: 48.8407601, lng: 18.6196141 },
    { lat: 48.8404517, lng: 18.6183638 },
    { lat: 48.8401011, lng: 18.6172312 },
    { lat: 48.8398548, lng: 18.6160077 },
    { lat: 48.8395374, lng: 18.6151603 },
    { lat: 48.8393271, lng: 18.614647 },
    { lat: 48.8392524, lng: 18.6142849 },
    { lat: 48.8391796, lng: 18.6139602 },
    { lat: 48.8389498, lng: 18.6135665 },
    { lat: 48.8389162, lng: 18.6132974 },
    { lat: 48.839042, lng: 18.6132253 },
    { lat: 48.8388211, lng: 18.6128315 },
    { lat: 48.838773, lng: 18.6124594 },
    { lat: 48.8386854, lng: 18.6118361 },
    { lat: 48.8385827, lng: 18.6108716 },
    { lat: 48.8382808, lng: 18.6100185 },
    { lat: 48.8381713, lng: 18.6095773 },
    { lat: 48.8377896, lng: 18.6085174 },
    { lat: 48.8379092, lng: 18.6081223 },
    { lat: 48.8379192, lng: 18.6079084 },
    { lat: 48.838099, lng: 18.6068271 },
    { lat: 48.8383175, lng: 18.6059645 },
    { lat: 48.8378837, lng: 18.6057603 },
    { lat: 48.8374795, lng: 18.6055664 },
    { lat: 48.8373798, lng: 18.6054928 },
    { lat: 48.8368908, lng: 18.6052725 },
    { lat: 48.8367408, lng: 18.6051865 },
    { lat: 48.8360787, lng: 18.6047903 },
    { lat: 48.8355005, lng: 18.6044637 },
    { lat: 48.8349293, lng: 18.6039897 },
    { lat: 48.8335814, lng: 18.60334 },
    { lat: 48.8335455, lng: 18.6033237 },
    { lat: 48.8326919, lng: 18.6027855 },
    { lat: 48.8320313, lng: 18.6024783 },
    { lat: 48.8316259, lng: 18.6023186 },
    { lat: 48.8313036, lng: 18.6022421 },
    { lat: 48.8309905, lng: 18.6021018 },
    { lat: 48.8305497, lng: 18.6019168 },
    { lat: 48.8298766, lng: 18.6016109 },
    { lat: 48.8295276, lng: 18.6014543 },
    { lat: 48.8293433, lng: 18.6013808 },
    { lat: 48.8289239, lng: 18.6012386 },
    { lat: 48.828851, lng: 18.6012054 },
    { lat: 48.8288406, lng: 18.601241 },
    { lat: 48.8286264, lng: 18.6011615 },
    { lat: 48.828363, lng: 18.6011064 },
    { lat: 48.8282008, lng: 18.6010265 },
    { lat: 48.8280659, lng: 18.6009086 },
    { lat: 48.8276491, lng: 18.6004579 },
    { lat: 48.827539, lng: 18.6003849 },
    { lat: 48.8275297, lng: 18.6004186 },
    { lat: 48.827627, lng: 18.6005527 },
    { lat: 48.8276461, lng: 18.6006144 },
    { lat: 48.8275874, lng: 18.6007169 },
    { lat: 48.8275555, lng: 18.6007967 },
    { lat: 48.8275454, lng: 18.6008989 },
    { lat: 48.8275844, lng: 18.6009345 },
    { lat: 48.8275624, lng: 18.6010697 },
    { lat: 48.8275699, lng: 18.6011869 },
    { lat: 48.8276073, lng: 18.6012972 },
    { lat: 48.8275733, lng: 18.6014378 },
    { lat: 48.8275265, lng: 18.6014904 },
    { lat: 48.8275254, lng: 18.6016198 },
    { lat: 48.8274454, lng: 18.6018727 },
    { lat: 48.8274919, lng: 18.6019586 },
    { lat: 48.8274911, lng: 18.6020053 },
    { lat: 48.8274172, lng: 18.6020978 },
    { lat: 48.827346, lng: 18.6020368 },
    { lat: 48.8273277, lng: 18.6021959 },
    { lat: 48.827356, lng: 18.6022822 },
    { lat: 48.8273381, lng: 18.6023274 },
    { lat: 48.8271905, lng: 18.6024795 },
    { lat: 48.8271629, lng: 18.6026558 },
    { lat: 48.8270706, lng: 18.6028473 },
    { lat: 48.8270835, lng: 18.6029642 },
    { lat: 48.827056, lng: 18.6031025 },
    { lat: 48.8270823, lng: 18.6031913 },
    { lat: 48.827008, lng: 18.6032165 },
    { lat: 48.8269546, lng: 18.6033348 },
    { lat: 48.826995, lng: 18.6034125 },
    { lat: 48.8270013, lng: 18.6034475 },
    { lat: 48.8269045, lng: 18.6036986 },
    { lat: 48.8268433, lng: 18.6039678 },
    { lat: 48.8268253, lng: 18.604071 },
    { lat: 48.8267899, lng: 18.6041685 },
    { lat: 48.8266876, lng: 18.6043855 },
    { lat: 48.8266627, lng: 18.6046327 },
    { lat: 48.8264696, lng: 18.604924 },
    { lat: 48.826298, lng: 18.605112 },
    { lat: 48.8261989, lng: 18.6051145 },
    { lat: 48.8261238, lng: 18.6051643 },
    { lat: 48.8261229, lng: 18.6052084 },
    { lat: 48.8259813, lng: 18.6051554 },
    { lat: 48.8256595, lng: 18.6057149 },
    { lat: 48.8255109, lng: 18.605992 },
    { lat: 48.8249022, lng: 18.6057487 },
    { lat: 48.8254303, lng: 18.6049357 },
    { lat: 48.8260684, lng: 18.602543 },
    { lat: 48.8264158, lng: 18.6012426 },
    { lat: 48.8263027, lng: 18.6011978 },
    { lat: 48.8253793, lng: 18.6008459 },
    { lat: 48.8252512, lng: 18.6007937 },
    { lat: 48.8249208, lng: 18.600671 },
    { lat: 48.8244669, lng: 18.6004941 },
    { lat: 48.8246713, lng: 18.5992833 },
    { lat: 48.8246744, lng: 18.5992575 },
    { lat: 48.824485, lng: 18.5992158 },
    { lat: 48.8229514, lng: 18.599603 },
    { lat: 48.8225507, lng: 18.5996364 },
    { lat: 48.8220757, lng: 18.5997384 },
    { lat: 48.8213927, lng: 18.599892 },
    { lat: 48.8196066, lng: 18.6003436 },
    { lat: 48.8195786, lng: 18.6004734 },
    { lat: 48.8195714, lng: 18.6005895 },
    { lat: 48.8195936, lng: 18.60077 },
    { lat: 48.8196767, lng: 18.6008464 },
    { lat: 48.8197162, lng: 18.6009138 },
    { lat: 48.8197498, lng: 18.6010324 },
    { lat: 48.8195746, lng: 18.601194 },
    { lat: 48.8194639, lng: 18.6011923 },
    { lat: 48.8193887, lng: 18.6012385 },
    { lat: 48.8193077, lng: 18.6013465 },
    { lat: 48.8192821, lng: 18.6013529 },
    { lat: 48.81923, lng: 18.60133 },
    { lat: 48.8191924, lng: 18.6012799 },
    { lat: 48.819139, lng: 18.6011663 },
    { lat: 48.8190331, lng: 18.6011926 },
    { lat: 48.8189647, lng: 18.6013889 },
    { lat: 48.8188435, lng: 18.6016308 },
    { lat: 48.8187631, lng: 18.6017485 },
    { lat: 48.8186391, lng: 18.6019603 },
    { lat: 48.8186395, lng: 18.6020286 },
    { lat: 48.8184987, lng: 18.6021799 },
    { lat: 48.8184196, lng: 18.6021542 },
    { lat: 48.8183562, lng: 18.6022368 },
    { lat: 48.8183438, lng: 18.6024012 },
    { lat: 48.8182907, lng: 18.6025252 },
    { lat: 48.8182301, lng: 18.6028437 },
    { lat: 48.8181631, lng: 18.6028992 },
    { lat: 48.8181307, lng: 18.6031795 },
    { lat: 48.8181721, lng: 18.6033609 },
    { lat: 48.8181334, lng: 18.6035024 },
    { lat: 48.8179614, lng: 18.6036346 },
    { lat: 48.8178848, lng: 18.6036379 },
    { lat: 48.8178043, lng: 18.6037545 },
    { lat: 48.8177051, lng: 18.6039436 },
    { lat: 48.8176886, lng: 18.6040587 },
    { lat: 48.8171073, lng: 18.6038814 },
    { lat: 48.8157962, lng: 18.6034991 },
    { lat: 48.8136517, lng: 18.6028425 },
    { lat: 48.8129848, lng: 18.6026457 },
    { lat: 48.812958, lng: 18.6026319 },
    { lat: 48.8127454, lng: 18.6057626 },
    { lat: 48.8127278, lng: 18.605768 },
    { lat: 48.8127221, lng: 18.6057676 },
    { lat: 48.8126543, lng: 18.605743 },
    { lat: 48.8120425, lng: 18.6054629 },
    { lat: 48.8115837, lng: 18.6052558 },
    { lat: 48.8111683, lng: 18.6049997 },
    { lat: 48.8106436, lng: 18.6047224 },
    { lat: 48.8099475, lng: 18.6042827 },
    { lat: 48.809701, lng: 18.6041038 },
    { lat: 48.8088304, lng: 18.6033602 },
    { lat: 48.8088196, lng: 18.6033796 },
    { lat: 48.8088148, lng: 18.6033953 },
    { lat: 48.8079815, lng: 18.6050107 },
    { lat: 48.8075798, lng: 18.6057839 },
    { lat: 48.8074792, lng: 18.6060317 },
    { lat: 48.8072092, lng: 18.6066701 },
    { lat: 48.8066932, lng: 18.6075833 },
    { lat: 48.8064025, lng: 18.6078203 },
    { lat: 48.8061757, lng: 18.608195 },
    { lat: 48.8061398, lng: 18.608471 },
    { lat: 48.8055636, lng: 18.6090827 },
    { lat: 48.8052483, lng: 18.6095918 },
    { lat: 48.8049571, lng: 18.6099465 },
    { lat: 48.80431, lng: 18.6104117 },
    { lat: 48.8037512, lng: 18.6105072 },
    { lat: 48.8035974, lng: 18.6107322 },
    { lat: 48.8034215, lng: 18.6105217 },
    { lat: 48.8031585, lng: 18.6106003 },
    { lat: 48.8018328, lng: 18.6118515 },
    { lat: 48.8013292, lng: 18.6122177 },
    { lat: 48.8007742, lng: 18.6129284 },
    { lat: 48.8003982, lng: 18.6136363 },
    { lat: 48.8002374, lng: 18.6143144 },
    { lat: 48.800065, lng: 18.6146522 },
    { lat: 48.7998867, lng: 18.6149443 },
    { lat: 48.7997037, lng: 18.6152542 },
    { lat: 48.799549, lng: 18.6156264 },
    { lat: 48.7991115, lng: 18.6160699 },
    { lat: 48.7986837, lng: 18.6166095 },
    { lat: 48.7981203, lng: 18.6174803 },
    { lat: 48.7974393, lng: 18.6175678 },
    { lat: 48.7973693, lng: 18.6177113 },
    { lat: 48.797087, lng: 18.6179375 },
    { lat: 48.7968098, lng: 18.6183215 },
    { lat: 48.7965624, lng: 18.6188138 },
    { lat: 48.7963259, lng: 18.6190755 },
    { lat: 48.7961162, lng: 18.6193288 },
    { lat: 48.7958638, lng: 18.6193742 },
    { lat: 48.7957513, lng: 18.6193777 },
    { lat: 48.7955869, lng: 18.6194892 },
    { lat: 48.7950586, lng: 18.6194802 },
    { lat: 48.7947371, lng: 18.6199408 },
    { lat: 48.7947048, lng: 18.6203283 },
    { lat: 48.7944476, lng: 18.6208279 },
    { lat: 48.794268, lng: 18.6211594 },
    { lat: 48.793892, lng: 18.6215506 },
    { lat: 48.7939473, lng: 18.6218056 },
    { lat: 48.7938655, lng: 18.6220639 },
    { lat: 48.7937665, lng: 18.6222371 },
    { lat: 48.793645, lng: 18.6225175 },
    { lat: 48.7935155, lng: 18.6227956 },
    { lat: 48.7932804, lng: 18.6233293 },
    { lat: 48.7932565, lng: 18.6233891 },
    { lat: 48.7930513, lng: 18.6237671 },
    { lat: 48.792899, lng: 18.624126 },
    { lat: 48.7925702, lng: 18.6248482 },
  ],
  LehotapodVtáčnikom: [
    { lat: 48.6858088, lng: 18.6847085 },
    { lat: 48.685716, lng: 18.6834734 },
    { lat: 48.6856646, lng: 18.6829128 },
    { lat: 48.6856298, lng: 18.6822603 },
    { lat: 48.6855455, lng: 18.6811231 },
    { lat: 48.6854737, lng: 18.6806457 },
    { lat: 48.6854654, lng: 18.680298 },
    { lat: 48.6854813, lng: 18.6800472 },
    { lat: 48.6854678, lng: 18.6797287 },
    { lat: 48.6854969, lng: 18.6794714 },
    { lat: 48.68547, lng: 18.679202 },
    { lat: 48.6855458, lng: 18.6787801 },
    { lat: 48.6854855, lng: 18.6783724 },
    { lat: 48.6853591, lng: 18.6780726 },
    { lat: 48.6853142, lng: 18.6779075 },
    { lat: 48.6852409, lng: 18.6777832 },
    { lat: 48.6850869, lng: 18.6775814 },
    { lat: 48.6847685, lng: 18.6769613 },
    { lat: 48.6847032, lng: 18.6767294 },
    { lat: 48.6847509, lng: 18.6764177 },
    { lat: 48.684923, lng: 18.6758174 },
    { lat: 48.6851579, lng: 18.6748744 },
    { lat: 48.6852316, lng: 18.6746705 },
    { lat: 48.6853052, lng: 18.6745084 },
    { lat: 48.685333, lng: 18.6742459 },
    { lat: 48.6853982, lng: 18.6739489 },
    { lat: 48.6854187, lng: 18.6736116 },
    { lat: 48.6854864, lng: 18.6733613 },
    { lat: 48.686139, lng: 18.6721057 },
    { lat: 48.6862164, lng: 18.6719921 },
    { lat: 48.6864952, lng: 18.6715312 },
    { lat: 48.6866921, lng: 18.671186 },
    { lat: 48.6868064, lng: 18.6710428 },
    { lat: 48.6869466, lng: 18.6708405 },
    { lat: 48.6871128, lng: 18.6705718 },
    { lat: 48.6876429, lng: 18.6702366 },
    { lat: 48.6882666, lng: 18.6694753 },
    { lat: 48.6885464, lng: 18.6686798 },
    { lat: 48.688838, lng: 18.6677408 },
    { lat: 48.6893707, lng: 18.6673035 },
    { lat: 48.6896847, lng: 18.6670796 },
    { lat: 48.6899565, lng: 18.6667223 },
    { lat: 48.6903881, lng: 18.6659986 },
    { lat: 48.6908548, lng: 18.665277 },
    { lat: 48.6910038, lng: 18.664899 },
    { lat: 48.6910271, lng: 18.6648486 },
    { lat: 48.6911853, lng: 18.664452 },
    { lat: 48.6914475, lng: 18.6637868 },
    { lat: 48.6919679, lng: 18.6627223 },
    { lat: 48.6924655, lng: 18.6618626 },
    { lat: 48.6926416, lng: 18.6616559 },
    { lat: 48.6929118, lng: 18.6610963 },
    { lat: 48.6931501, lng: 18.660491 },
    { lat: 48.6933783, lng: 18.6599959 },
    { lat: 48.6935107, lng: 18.659833 },
    { lat: 48.6940629, lng: 18.6589209 },
    { lat: 48.694333, lng: 18.6581482 },
    { lat: 48.6946434, lng: 18.6573938 },
    { lat: 48.69502, lng: 18.6571629 },
    { lat: 48.6953843, lng: 18.6565943 },
    { lat: 48.6957579, lng: 18.6560074 },
    { lat: 48.695768, lng: 18.6556913 },
    { lat: 48.6957682, lng: 18.6544956 },
    { lat: 48.6956864, lng: 18.6537049 },
    { lat: 48.6958212, lng: 18.6536102 },
    { lat: 48.695892, lng: 18.653457 },
    { lat: 48.6959146, lng: 18.6533332 },
    { lat: 48.6959198, lng: 18.6531783 },
    { lat: 48.6958838, lng: 18.6525465 },
    { lat: 48.6958009, lng: 18.6522643 },
    { lat: 48.6958042, lng: 18.6520079 },
    { lat: 48.695781, lng: 18.6518708 },
    { lat: 48.6956517, lng: 18.651541 },
    { lat: 48.6956135, lng: 18.6514589 },
    { lat: 48.6954413, lng: 18.6512129 },
    { lat: 48.6953528, lng: 18.6509103 },
    { lat: 48.6951108, lng: 18.6505404 },
    { lat: 48.6950105, lng: 18.6498697 },
    { lat: 48.6948982, lng: 18.6491002 },
    { lat: 48.6949262, lng: 18.6481914 },
    { lat: 48.6950697, lng: 18.6471238 },
    { lat: 48.6956, lng: 18.6466837 },
    { lat: 48.6957172, lng: 18.6466426 },
    { lat: 48.695997, lng: 18.6468319 },
    { lat: 48.6963908, lng: 18.6466262 },
    { lat: 48.6963495, lng: 18.6464646 },
    { lat: 48.6964606, lng: 18.646411 },
    { lat: 48.69655, lng: 18.6463115 },
    { lat: 48.6966223, lng: 18.6461688 },
    { lat: 48.6966259, lng: 18.6458057 },
    { lat: 48.6966277, lng: 18.6456183 },
    { lat: 48.6967471, lng: 18.6455301 },
    { lat: 48.6971675, lng: 18.645459 },
    { lat: 48.6975006, lng: 18.6451301 },
    { lat: 48.6978509, lng: 18.6451447 },
    { lat: 48.6983535, lng: 18.6448215 },
    { lat: 48.6987314, lng: 18.6445692 },
    { lat: 48.6991102, lng: 18.64444 },
    { lat: 48.699214, lng: 18.6443352 },
    { lat: 48.6996269, lng: 18.6441283 },
    { lat: 48.6997067, lng: 18.6440168 },
    { lat: 48.6997439, lng: 18.6439311 },
    { lat: 48.6997469, lng: 18.6438412 },
    { lat: 48.6998186, lng: 18.6436628 },
    { lat: 48.6998879, lng: 18.6435268 },
    { lat: 48.7001421, lng: 18.6433436 },
    { lat: 48.7002147, lng: 18.6431026 },
    { lat: 48.7002598, lng: 18.6427843 },
    { lat: 48.7003239, lng: 18.6424241 },
    { lat: 48.7004401, lng: 18.642217 },
    { lat: 48.700508, lng: 18.6419891 },
    { lat: 48.7005846, lng: 18.6416499 },
    { lat: 48.7007366, lng: 18.6412056 },
    { lat: 48.7008435, lng: 18.6409904 },
    { lat: 48.7009643, lng: 18.6408018 },
    { lat: 48.701109, lng: 18.6406801 },
    { lat: 48.7013035, lng: 18.6407552 },
    { lat: 48.701482, lng: 18.6407376 },
    { lat: 48.7015463, lng: 18.6407169 },
    { lat: 48.701627, lng: 18.6406652 },
    { lat: 48.7017343, lng: 18.6404811 },
    { lat: 48.7017989, lng: 18.6404435 },
    { lat: 48.7018727, lng: 18.6403698 },
    { lat: 48.7019018, lng: 18.6403259 },
    { lat: 48.701897, lng: 18.6402751 },
    { lat: 48.7021197, lng: 18.6402018 },
    { lat: 48.7021192, lng: 18.640214 },
    { lat: 48.7022595, lng: 18.6402439 },
    { lat: 48.7023633, lng: 18.6402444 },
    { lat: 48.702491, lng: 18.6402062 },
    { lat: 48.7026762, lng: 18.6400646 },
    { lat: 48.7028533, lng: 18.6398094 },
    { lat: 48.7029055, lng: 18.6397527 },
    { lat: 48.7031339, lng: 18.6394055 },
    { lat: 48.7032452, lng: 18.6391897 },
    { lat: 48.7032827, lng: 18.6389413 },
    { lat: 48.7033459, lng: 18.6387361 },
    { lat: 48.7035516, lng: 18.6385102 },
    { lat: 48.7037349, lng: 18.6381635 },
    { lat: 48.7037631, lng: 18.6381211 },
    { lat: 48.7038957, lng: 18.6379674 },
    { lat: 48.7039733, lng: 18.6377747 },
    { lat: 48.704254, lng: 18.6376857 },
    { lat: 48.7043502, lng: 18.6376289 },
    { lat: 48.7044285, lng: 18.6376825 },
    { lat: 48.7045733, lng: 18.6375415 },
    { lat: 48.7047559, lng: 18.6374156 },
    { lat: 48.7049656, lng: 18.6373503 },
    { lat: 48.7051575, lng: 18.6372671 },
    { lat: 48.7053795, lng: 18.6371799 },
    { lat: 48.7064286, lng: 18.6367842 },
    { lat: 48.7065243, lng: 18.6366328 },
    { lat: 48.7065597, lng: 18.6366406 },
    { lat: 48.7066016, lng: 18.6365201 },
    { lat: 48.7067226, lng: 18.6365453 },
    { lat: 48.7068285, lng: 18.6365234 },
    { lat: 48.7069349, lng: 18.636445 },
    { lat: 48.7071251, lng: 18.6363788 },
    { lat: 48.7072428, lng: 18.6361941 },
    { lat: 48.7072606, lng: 18.6361465 },
    { lat: 48.7072835, lng: 18.636027 },
    { lat: 48.7075808, lng: 18.635873 },
    { lat: 48.70765, lng: 18.6357531 },
    { lat: 48.7076895, lng: 18.6357536 },
    { lat: 48.7077389, lng: 18.635644 },
    { lat: 48.7078765, lng: 18.6356043 },
    { lat: 48.7079248, lng: 18.6356019 },
    { lat: 48.7079958, lng: 18.6356211 },
    { lat: 48.7081724, lng: 18.6356101 },
    { lat: 48.7081799, lng: 18.6356042 },
    { lat: 48.7085281, lng: 18.6348212 },
    { lat: 48.70867, lng: 18.6347963 },
    { lat: 48.7088214, lng: 18.6346549 },
    { lat: 48.7088307, lng: 18.6344484 },
    { lat: 48.7088764, lng: 18.6342925 },
    { lat: 48.7089039, lng: 18.634047 },
    { lat: 48.70909, lng: 18.6333992 },
    { lat: 48.7092136, lng: 18.6333051 },
    { lat: 48.7093083, lng: 18.632971 },
    { lat: 48.709225, lng: 18.632572 },
    { lat: 48.7093103, lng: 18.6319569 },
    { lat: 48.7092221, lng: 18.6314885 },
    { lat: 48.7092993, lng: 18.6313512 },
    { lat: 48.7092748, lng: 18.6310232 },
    { lat: 48.7093644, lng: 18.6306758 },
    { lat: 48.7092496, lng: 18.6304481 },
    { lat: 48.7092478, lng: 18.6301201 },
    { lat: 48.7092938, lng: 18.6297413 },
    { lat: 48.7092765, lng: 18.6295241 },
    { lat: 48.7092937, lng: 18.6291721 },
    { lat: 48.7093285, lng: 18.6287914 },
    { lat: 48.7093728, lng: 18.6287142 },
    { lat: 48.7092411, lng: 18.6284446 },
    { lat: 48.7093077, lng: 18.6282368 },
    { lat: 48.7093387, lng: 18.6281023 },
    { lat: 48.7093305, lng: 18.627988 },
    { lat: 48.7095064, lng: 18.6273604 },
    { lat: 48.7095209, lng: 18.6271686 },
    { lat: 48.7096036, lng: 18.6271127 },
    { lat: 48.70962, lng: 18.6269771 },
    { lat: 48.7096731, lng: 18.6269578 },
    { lat: 48.7096412, lng: 18.6267824 },
    { lat: 48.7096608, lng: 18.6265811 },
    { lat: 48.7097445, lng: 18.6265844 },
    { lat: 48.7098254, lng: 18.6263059 },
    { lat: 48.7098281, lng: 18.6261041 },
    { lat: 48.7099589, lng: 18.6258742 },
    { lat: 48.7100189, lng: 18.6258161 },
    { lat: 48.7100811, lng: 18.6257552 },
    { lat: 48.710041, lng: 18.6254495 },
    { lat: 48.7101169, lng: 18.6252884 },
    { lat: 48.7100191, lng: 18.625064 },
    { lat: 48.7099267, lng: 18.6250025 },
    { lat: 48.7100596, lng: 18.6243722 },
    { lat: 48.7102576, lng: 18.6239619 },
    { lat: 48.7102915, lng: 18.6237352 },
    { lat: 48.7102935, lng: 18.623501 },
    { lat: 48.7101863, lng: 18.6234374 },
    { lat: 48.7100718, lng: 18.6232765 },
    { lat: 48.7101469, lng: 18.6230612 },
    { lat: 48.7102519, lng: 18.6226615 },
    { lat: 48.7103214, lng: 18.6226976 },
    { lat: 48.7103472, lng: 18.6226098 },
    { lat: 48.7103566, lng: 18.6224898 },
    { lat: 48.7103991, lng: 18.6223178 },
    { lat: 48.7103716, lng: 18.6220804 },
    { lat: 48.710424, lng: 18.6218979 },
    { lat: 48.7105661, lng: 18.6217937 },
    { lat: 48.7105914, lng: 18.6216981 },
    { lat: 48.7106697, lng: 18.6216463 },
    { lat: 48.7107554, lng: 18.6214551 },
    { lat: 48.7107933, lng: 18.6211768 },
    { lat: 48.711018, lng: 18.6210524 },
    { lat: 48.7112528, lng: 18.6205143 },
    { lat: 48.7112048, lng: 18.6200177 },
    { lat: 48.7110585, lng: 18.6193772 },
    { lat: 48.7110845, lng: 18.6193147 },
    { lat: 48.711313, lng: 18.6190945 },
    { lat: 48.7112944, lng: 18.6189603 },
    { lat: 48.7113262, lng: 18.6187575 },
    { lat: 48.7112957, lng: 18.6186298 },
    { lat: 48.7112986, lng: 18.6185377 },
    { lat: 48.7112787, lng: 18.6184583 },
    { lat: 48.7112739, lng: 18.6184136 },
    { lat: 48.7111681, lng: 18.6182301 },
    { lat: 48.7111413, lng: 18.618127 },
    { lat: 48.7111409, lng: 18.6179967 },
    { lat: 48.7112201, lng: 18.6178134 },
    { lat: 48.7111806, lng: 18.6176633 },
    { lat: 48.7112309, lng: 18.6175535 },
    { lat: 48.7113644, lng: 18.6175571 },
    { lat: 48.7114202, lng: 18.6173827 },
    { lat: 48.7113676, lng: 18.6172213 },
    { lat: 48.7113891, lng: 18.6171591 },
    { lat: 48.7115692, lng: 18.6171505 },
    { lat: 48.7116032, lng: 18.617072 },
    { lat: 48.7115669, lng: 18.6169411 },
    { lat: 48.7116991, lng: 18.6166534 },
    { lat: 48.7117876, lng: 18.6161601 },
    { lat: 48.7117832, lng: 18.6159731 },
    { lat: 48.7118812, lng: 18.6158268 },
    { lat: 48.711881, lng: 18.61574 },
    { lat: 48.7117938, lng: 18.6156029 },
    { lat: 48.7118235, lng: 18.6155285 },
    { lat: 48.7119542, lng: 18.6154003 },
    { lat: 48.7118427, lng: 18.6150465 },
    { lat: 48.7118775, lng: 18.6149197 },
    { lat: 48.7119775, lng: 18.6147842 },
    { lat: 48.7118997, lng: 18.6146609 },
    { lat: 48.7119228, lng: 18.6146295 },
    { lat: 48.7120698, lng: 18.6145325 },
    { lat: 48.7121947, lng: 18.6143582 },
    { lat: 48.7121927, lng: 18.6143213 },
    { lat: 48.7121756, lng: 18.6142538 },
    { lat: 48.7122806, lng: 18.6139833 },
    { lat: 48.7123739, lng: 18.6138726 },
    { lat: 48.7125106, lng: 18.6134625 },
    { lat: 48.7126968, lng: 18.6132121 },
    { lat: 48.7128021, lng: 18.6129252 },
    { lat: 48.7127846, lng: 18.6127052 },
    { lat: 48.7128584, lng: 18.612609 },
    { lat: 48.7129592, lng: 18.6126994 },
    { lat: 48.7130218, lng: 18.6127128 },
    { lat: 48.7130303, lng: 18.6125757 },
    { lat: 48.7129166, lng: 18.6123922 },
    { lat: 48.712932, lng: 18.6122828 },
    { lat: 48.7129913, lng: 18.6121908 },
    { lat: 48.7130456, lng: 18.6119824 },
    { lat: 48.7131089, lng: 18.6118592 },
    { lat: 48.7132397, lng: 18.6116681 },
    { lat: 48.7132551, lng: 18.6115166 },
    { lat: 48.7133043, lng: 18.6113626 },
    { lat: 48.7132984, lng: 18.6113148 },
    { lat: 48.7132793, lng: 18.6112536 },
    { lat: 48.7132681, lng: 18.6111296 },
    { lat: 48.7132282, lng: 18.6109113 },
    { lat: 48.713209, lng: 18.6108929 },
    { lat: 48.7131912, lng: 18.6108744 },
    { lat: 48.7131471, lng: 18.6109159 },
    { lat: 48.7131236, lng: 18.6109392 },
    { lat: 48.7123131, lng: 18.611724 },
    { lat: 48.710258, lng: 18.6136866 },
    { lat: 48.7101948, lng: 18.61358 },
    { lat: 48.7099111, lng: 18.6129204 },
    { lat: 48.7098613, lng: 18.612624 },
    { lat: 48.7098594, lng: 18.6126107 },
    { lat: 48.7098561, lng: 18.6125928 },
    { lat: 48.7098531, lng: 18.6125775 },
    { lat: 48.7097478, lng: 18.6121533 },
    { lat: 48.7096685, lng: 18.6112492 },
    { lat: 48.709995, lng: 18.611049 },
    { lat: 48.7130116, lng: 18.6091126 },
    { lat: 48.7136884, lng: 18.608676 },
    { lat: 48.7132079, lng: 18.6066051 },
    { lat: 48.7131891, lng: 18.6065285 },
    { lat: 48.7128185, lng: 18.6067034 },
    { lat: 48.711581, lng: 18.6072856 },
    { lat: 48.711579, lng: 18.6072712 },
    { lat: 48.7112138, lng: 18.6055623 },
    { lat: 48.7112109, lng: 18.605549 },
    { lat: 48.7112005, lng: 18.6055042 },
    { lat: 48.7111843, lng: 18.6054327 },
    { lat: 48.7111182, lng: 18.6051272 },
    { lat: 48.711106, lng: 18.6050666 },
    { lat: 48.7110998, lng: 18.604997 },
    { lat: 48.7112501, lng: 18.6049606 },
    { lat: 48.7115639, lng: 18.6047966 },
    { lat: 48.7118626, lng: 18.6045641 },
    { lat: 48.7123368, lng: 18.604636 },
    { lat: 48.7125107, lng: 18.6046167 },
    { lat: 48.7126722, lng: 18.6041632 },
    { lat: 48.7127533, lng: 18.6042211 },
    { lat: 48.7129033, lng: 18.604494 },
    { lat: 48.7130553, lng: 18.6044864 },
    { lat: 48.7130571, lng: 18.6044808 },
    { lat: 48.7130812, lng: 18.6044057 },
    { lat: 48.7130649, lng: 18.6041037 },
    { lat: 48.7130934, lng: 18.6039613 },
    { lat: 48.7131105, lng: 18.6039456 },
    { lat: 48.7133288, lng: 18.6041416 },
    { lat: 48.7134152, lng: 18.6040913 },
    { lat: 48.714016, lng: 18.6040029 },
    { lat: 48.7141619, lng: 18.6040524 },
    { lat: 48.7141618, lng: 18.6040082 },
    { lat: 48.7141619, lng: 18.6039867 },
    { lat: 48.7138402, lng: 18.6033171 },
    { lat: 48.7122599, lng: 18.6000329 },
    { lat: 48.711934, lng: 18.5993695 },
    { lat: 48.7120266, lng: 18.5992852 },
    { lat: 48.7128339, lng: 18.5984038 },
    { lat: 48.7122241, lng: 18.597118 },
    { lat: 48.7122035, lng: 18.5970702 },
    { lat: 48.7121854, lng: 18.5970278 },
    { lat: 48.712693, lng: 18.5958572 },
    { lat: 48.7127372, lng: 18.5959071 },
    { lat: 48.7127578, lng: 18.5959325 },
    { lat: 48.712997, lng: 18.5954526 },
    { lat: 48.7132571, lng: 18.5951315 },
    { lat: 48.7133611, lng: 18.5950129 },
    { lat: 48.7134758, lng: 18.5948999 },
    { lat: 48.713796, lng: 18.5946067 },
    { lat: 48.7145304, lng: 18.5941489 },
    { lat: 48.7154752, lng: 18.5934333 },
    { lat: 48.7161614, lng: 18.5929188 },
    { lat: 48.7167183, lng: 18.5924122 },
    { lat: 48.7174232, lng: 18.591535 },
    { lat: 48.717496, lng: 18.5914435 },
    { lat: 48.7174814, lng: 18.5913835 },
    { lat: 48.7171262, lng: 18.5908809 },
    { lat: 48.7167732, lng: 18.5903812 },
    { lat: 48.7164791, lng: 18.5899739 },
    { lat: 48.7163809, lng: 18.5901593 },
    { lat: 48.7162936, lng: 18.5901929 },
    { lat: 48.7161638, lng: 18.5903754 },
    { lat: 48.7159902, lng: 18.5906923 },
    { lat: 48.7157837, lng: 18.5909385 },
    { lat: 48.7156278, lng: 18.5910796 },
    { lat: 48.7155876, lng: 18.5913378 },
    { lat: 48.7152394, lng: 18.5918648 },
    { lat: 48.7152224, lng: 18.5918647 },
    { lat: 48.7149501, lng: 18.5915848 },
    { lat: 48.7144534, lng: 18.5909928 },
    { lat: 48.714137, lng: 18.5906055 },
    { lat: 48.713918, lng: 18.5903322 },
    { lat: 48.7136678, lng: 18.5900673 },
    { lat: 48.7135311, lng: 18.5901449 },
    { lat: 48.7134394, lng: 18.5900366 },
    { lat: 48.7134171, lng: 18.589999 },
    { lat: 48.7133634, lng: 18.5898189 },
    { lat: 48.7133364, lng: 18.5897579 },
    { lat: 48.7132742, lng: 18.5896688 },
    { lat: 48.7134414, lng: 18.589571 },
    { lat: 48.7135809, lng: 18.5894805 },
    { lat: 48.7136854, lng: 18.5893705 },
    { lat: 48.7138317, lng: 18.5891738 },
    { lat: 48.713952, lng: 18.5890036 },
    { lat: 48.7140783, lng: 18.5888126 },
    { lat: 48.7141711, lng: 18.5885883 },
    { lat: 48.7142577, lng: 18.5883379 },
    { lat: 48.7143271, lng: 18.5880947 },
    { lat: 48.7143689, lng: 18.5878681 },
    { lat: 48.7143918, lng: 18.5876056 },
    { lat: 48.7144158, lng: 18.5871926 },
    { lat: 48.7144033, lng: 18.586983 },
    { lat: 48.7143629, lng: 18.5867573 },
    { lat: 48.7141383, lng: 18.5858782 },
    { lat: 48.7138743, lng: 18.5849229 },
    { lat: 48.7138181, lng: 18.5847142 },
    { lat: 48.7137016, lng: 18.5842082 },
    { lat: 48.7136577, lng: 18.5840039 },
    { lat: 48.7136097, lng: 18.5837206 },
    { lat: 48.7134348, lng: 18.5829472 },
    { lat: 48.713354, lng: 18.5827075 },
    { lat: 48.7132983, lng: 18.5824258 },
    { lat: 48.7132793, lng: 18.582141 },
    { lat: 48.7132324, lng: 18.5820254 },
    { lat: 48.7131412, lng: 18.5816295 },
    { lat: 48.713096, lng: 18.5813393 },
    { lat: 48.7130845, lng: 18.581084 },
    { lat: 48.713092, lng: 18.5807641 },
    { lat: 48.7130408, lng: 18.580504 },
    { lat: 48.71308, lng: 18.5799587 },
    { lat: 48.7130835, lng: 18.5797129 },
    { lat: 48.7130642, lng: 18.579021 },
    { lat: 48.7130764, lng: 18.5789588 },
    { lat: 48.7130717, lng: 18.5788726 },
    { lat: 48.7130689, lng: 18.5780272 },
    { lat: 48.7130394, lng: 18.5774833 },
    { lat: 48.7130443, lng: 18.5772198 },
    { lat: 48.7130601, lng: 18.5769496 },
    { lat: 48.7130631, lng: 18.576755 },
    { lat: 48.7130469, lng: 18.5763734 },
    { lat: 48.7131011, lng: 18.5760841 },
    { lat: 48.7131137, lng: 18.5757968 },
    { lat: 48.7131659, lng: 18.5755086 },
    { lat: 48.7132215, lng: 18.5753092 },
    { lat: 48.7133043, lng: 18.5749618 },
    { lat: 48.7133799, lng: 18.5746963 },
    { lat: 48.7134674, lng: 18.5744347 },
    { lat: 48.7135749, lng: 18.574195 },
    { lat: 48.7136888, lng: 18.5739637 },
    { lat: 48.7138175, lng: 18.5737381 },
    { lat: 48.7139489, lng: 18.5735379 },
    { lat: 48.7140852, lng: 18.5733496 },
    { lat: 48.7142404, lng: 18.5731735 },
    { lat: 48.714405, lng: 18.5730053 },
    { lat: 48.7145655, lng: 18.5728647 },
    { lat: 48.7147406, lng: 18.5727465 },
    { lat: 48.7149219, lng: 18.5726391 },
    { lat: 48.7151041, lng: 18.5725422 },
    { lat: 48.715287, lng: 18.5724631 },
    { lat: 48.7154722, lng: 18.5724048 },
    { lat: 48.7156425, lng: 18.5723233 },
    { lat: 48.7163611, lng: 18.5721368 },
    { lat: 48.7165761, lng: 18.5720659 },
    { lat: 48.7169489, lng: 18.5719543 },
    { lat: 48.7172988, lng: 18.5718331 },
    { lat: 48.7178104, lng: 18.5716782 },
    { lat: 48.7181932, lng: 18.5715593 },
    { lat: 48.7188639, lng: 18.5713666 },
    { lat: 48.7190732, lng: 18.5712942 },
    { lat: 48.7199394, lng: 18.5710229 },
    { lat: 48.7207818, lng: 18.569586 },
    { lat: 48.7210267, lng: 18.5691724 },
    { lat: 48.7211757, lng: 18.5689038 },
    { lat: 48.7202522, lng: 18.5679549 },
    { lat: 48.7196853, lng: 18.5673822 },
    { lat: 48.7191757, lng: 18.5668632 },
    { lat: 48.7181965, lng: 18.5658848 },
    { lat: 48.7177617, lng: 18.5654397 },
    { lat: 48.7174251, lng: 18.5650996 },
    { lat: 48.7170769, lng: 18.5646502 },
    { lat: 48.7157774, lng: 18.5638363 },
    { lat: 48.715614, lng: 18.563883 },
    { lat: 48.7154594, lng: 18.5639608 },
    { lat: 48.7152813, lng: 18.564134 },
    { lat: 48.7151333, lng: 18.5644206 },
    { lat: 48.7150144, lng: 18.5643278 },
    { lat: 48.7146252, lng: 18.563995 },
    { lat: 48.7144077, lng: 18.5637687 },
    { lat: 48.7141334, lng: 18.5634517 },
    { lat: 48.7138997, lng: 18.5631353 },
    { lat: 48.7136954, lng: 18.5626385 },
    { lat: 48.713623, lng: 18.5627382 },
    { lat: 48.713578, lng: 18.5629263 },
    { lat: 48.7135116, lng: 18.5630324 },
    { lat: 48.7133852, lng: 18.5631556 },
    { lat: 48.7132629, lng: 18.5632093 },
    { lat: 48.7131423, lng: 18.5631471 },
    { lat: 48.7129302, lng: 18.5633763 },
    { lat: 48.7128462, lng: 18.5634492 },
    { lat: 48.7127004, lng: 18.5639402 },
    { lat: 48.7125848, lng: 18.5642456 },
    { lat: 48.7124171, lng: 18.5644997 },
    { lat: 48.7122975, lng: 18.5646427 },
    { lat: 48.7122531, lng: 18.5647622 },
    { lat: 48.712129, lng: 18.5650087 },
    { lat: 48.7118785, lng: 18.5651922 },
    { lat: 48.7117987, lng: 18.5651986 },
    { lat: 48.7117338, lng: 18.5652306 },
    { lat: 48.7116425, lng: 18.565334 },
    { lat: 48.7114577, lng: 18.5654223 },
    { lat: 48.7113728, lng: 18.5655221 },
    { lat: 48.7113155, lng: 18.5657335 },
    { lat: 48.7111905, lng: 18.5661069 },
    { lat: 48.71114, lng: 18.5667178 },
    { lat: 48.711097, lng: 18.5669835 },
    { lat: 48.7108193, lng: 18.5674383 },
    { lat: 48.7106429, lng: 18.5675313 },
    { lat: 48.7105305, lng: 18.5678291 },
    { lat: 48.7104539, lng: 18.5681258 },
    { lat: 48.7104961, lng: 18.5681923 },
    { lat: 48.7104636, lng: 18.568261 },
    { lat: 48.710364, lng: 18.5682555 },
    { lat: 48.7103014, lng: 18.568225 },
    { lat: 48.7102802, lng: 18.5682255 },
    { lat: 48.7102578, lng: 18.5682701 },
    { lat: 48.7102772, lng: 18.5684023 },
    { lat: 48.7101642, lng: 18.5687495 },
    { lat: 48.7100706, lng: 18.5689583 },
    { lat: 48.7099605, lng: 18.5690727 },
    { lat: 48.7099032, lng: 18.569324 },
    { lat: 48.7099704, lng: 18.5694589 },
    { lat: 48.7099352, lng: 18.5697029 },
    { lat: 48.7096428, lng: 18.569951 },
    { lat: 48.7095896, lng: 18.5701115 },
    { lat: 48.7095388, lng: 18.5703392 },
    { lat: 48.7094302, lng: 18.570502 },
    { lat: 48.7092524, lng: 18.5706322 },
    { lat: 48.7091847, lng: 18.570757 },
    { lat: 48.709141, lng: 18.5709321 },
    { lat: 48.7092164, lng: 18.5711759 },
    { lat: 48.709178, lng: 18.5713 },
    { lat: 48.7091036, lng: 18.571361 },
    { lat: 48.7089676, lng: 18.571665 },
    { lat: 48.7089101, lng: 18.5719117 },
    { lat: 48.708828, lng: 18.5720412 },
    { lat: 48.7088028, lng: 18.5721164 },
    { lat: 48.7086174, lng: 18.5722537 },
    { lat: 48.7085853, lng: 18.5724334 },
    { lat: 48.7084641, lng: 18.5725695 },
    { lat: 48.7082532, lng: 18.5725292 },
    { lat: 48.7081579, lng: 18.5724978 },
    { lat: 48.7081197, lng: 18.5725614 },
    { lat: 48.7080619, lng: 18.5725797 },
    { lat: 48.7080403, lng: 18.5727395 },
    { lat: 48.7079633, lng: 18.5729233 },
    { lat: 48.7078647, lng: 18.5732069 },
    { lat: 48.7077732, lng: 18.5732665 },
    { lat: 48.7077591, lng: 18.5733386 },
    { lat: 48.7077059, lng: 18.5733148 },
    { lat: 48.7074701, lng: 18.573439 },
    { lat: 48.7073006, lng: 18.5738875 },
    { lat: 48.7072296, lng: 18.5740998 },
    { lat: 48.7069589, lng: 18.5745548 },
    { lat: 48.7065721, lng: 18.5750376 },
    { lat: 48.7063002, lng: 18.5748881 },
    { lat: 48.7066033, lng: 18.5740299 },
    { lat: 48.7065169, lng: 18.5737063 },
    { lat: 48.7065563, lng: 18.573495 },
    { lat: 48.7062143, lng: 18.573286 },
    { lat: 48.7061976, lng: 18.5732481 },
    { lat: 48.7060182, lng: 18.5732653 },
    { lat: 48.7058753, lng: 18.5733544 },
    { lat: 48.7057989, lng: 18.5733407 },
    { lat: 48.7055582, lng: 18.5734183 },
    { lat: 48.7054965, lng: 18.5734442 },
    { lat: 48.7052973, lng: 18.5734935 },
    { lat: 48.7052418, lng: 18.5736771 },
    { lat: 48.7050839, lng: 18.5737595 },
    { lat: 48.7049459, lng: 18.5736463 },
    { lat: 48.7048156, lng: 18.5739285 },
    { lat: 48.7046662, lng: 18.5739588 },
    { lat: 48.7045766, lng: 18.5740145 },
    { lat: 48.7045339, lng: 18.5739941 },
    { lat: 48.7043829, lng: 18.5740831 },
    { lat: 48.7042252, lng: 18.5743133 },
    { lat: 48.7040508, lng: 18.5742996 },
    { lat: 48.7035884, lng: 18.5739505 },
    { lat: 48.7033082, lng: 18.5737341 },
    { lat: 48.7032507, lng: 18.5737766 },
    { lat: 48.7029445, lng: 18.573827 },
    { lat: 48.7026623, lng: 18.573928 },
    { lat: 48.7023633, lng: 18.5745078 },
    { lat: 48.7021248, lng: 18.5749337 },
    { lat: 48.7019227, lng: 18.575017 },
    { lat: 48.7018231, lng: 18.57532 },
    { lat: 48.7017069, lng: 18.5755918 },
    { lat: 48.7015215, lng: 18.5757515 },
    { lat: 48.7013442, lng: 18.5759342 },
    { lat: 48.7010973, lng: 18.5762272 },
    { lat: 48.7010517, lng: 18.5763839 },
    { lat: 48.7009329, lng: 18.5766482 },
    { lat: 48.700923, lng: 18.5767133 },
    { lat: 48.7008268, lng: 18.5768959 },
    { lat: 48.7006193, lng: 18.5769187 },
    { lat: 48.7005044, lng: 18.5768576 },
    { lat: 48.7002916, lng: 18.5769682 },
    { lat: 48.700064, lng: 18.5769953 },
    { lat: 48.7000081, lng: 18.5769415 },
    { lat: 48.6999122, lng: 18.5769809 },
    { lat: 48.6998124, lng: 18.5768069 },
    { lat: 48.6997147, lng: 18.5767704 },
    { lat: 48.6995491, lng: 18.5770237 },
    { lat: 48.6994393, lng: 18.577017 },
    { lat: 48.6994421, lng: 18.576882 },
    { lat: 48.6992764, lng: 18.5769039 },
    { lat: 48.6992152, lng: 18.5767726 },
    { lat: 48.6990406, lng: 18.5768613 },
    { lat: 48.6989791, lng: 18.5767243 },
    { lat: 48.69889, lng: 18.5766868 },
    { lat: 48.6986679, lng: 18.5767918 },
    { lat: 48.6984333, lng: 18.5769983 },
    { lat: 48.6982139, lng: 18.5769062 },
    { lat: 48.6978264, lng: 18.5766439 },
    { lat: 48.6977124, lng: 18.5765373 },
    { lat: 48.6976524, lng: 18.5764108 },
    { lat: 48.6975831, lng: 18.5764015 },
    { lat: 48.6973999, lng: 18.5762268 },
    { lat: 48.6973372, lng: 18.5761306 },
    { lat: 48.6972692, lng: 18.5761044 },
    { lat: 48.6972159, lng: 18.5760557 },
    { lat: 48.6971123, lng: 18.5760185 },
    { lat: 48.6970117, lng: 18.5759506 },
    { lat: 48.6967946, lng: 18.5759436 },
    { lat: 48.6966846, lng: 18.5757847 },
    { lat: 48.6965487, lng: 18.5756505 },
    { lat: 48.6964372, lng: 18.5755686 },
    { lat: 48.6963805, lng: 18.5755874 },
    { lat: 48.6962594, lng: 18.5758074 },
    { lat: 48.6961703, lng: 18.5762226 },
    { lat: 48.6960672, lng: 18.576486 },
    { lat: 48.6955067, lng: 18.5765293 },
    { lat: 48.6955021, lng: 18.5765884 },
    { lat: 48.6955946, lng: 18.5769858 },
    { lat: 48.6954253, lng: 18.5774413 },
    { lat: 48.6952352, lng: 18.5773237 },
    { lat: 48.6944571, lng: 18.5765392 },
    { lat: 48.6941571, lng: 18.5762276 },
    { lat: 48.693512, lng: 18.5766462 },
    { lat: 48.6932697, lng: 18.5771675 },
    { lat: 48.6929401, lng: 18.5781815 },
    { lat: 48.6929523, lng: 18.5782835 },
    { lat: 48.6931338, lng: 18.5788859 },
    { lat: 48.6927794, lng: 18.5791928 },
    { lat: 48.6925776, lng: 18.5792771 },
    { lat: 48.6923817, lng: 18.5794916 },
    { lat: 48.6921255, lng: 18.5798235 },
    { lat: 48.6919421, lng: 18.5798919 },
    { lat: 48.6919127, lng: 18.5798916 },
    { lat: 48.6917604, lng: 18.579702 },
    { lat: 48.691686, lng: 18.5796208 },
    { lat: 48.6916376, lng: 18.5795379 },
    { lat: 48.6915172, lng: 18.5797328 },
    { lat: 48.6904232, lng: 18.5782187 },
    { lat: 48.6898966, lng: 18.5785709 },
    { lat: 48.6890979, lng: 18.5791113 },
    { lat: 48.6888243, lng: 18.57862 },
    { lat: 48.6884668, lng: 18.5781216 },
    { lat: 48.6880379, lng: 18.5776171 },
    { lat: 48.6878051, lng: 18.5773389 },
    { lat: 48.6876584, lng: 18.5768384 },
    { lat: 48.6875298, lng: 18.5765683 },
    { lat: 48.6874731, lng: 18.57646 },
    { lat: 48.6873772, lng: 18.5763355 },
    { lat: 48.6872703, lng: 18.5762167 },
    { lat: 48.6871777, lng: 18.5760868 },
    { lat: 48.6870376, lng: 18.5757921 },
    { lat: 48.6868389, lng: 18.5755404 },
    { lat: 48.6867912, lng: 18.5754503 },
    { lat: 48.686645, lng: 18.5755426 },
    { lat: 48.6864951, lng: 18.5754416 },
    { lat: 48.686239, lng: 18.5756047 },
    { lat: 48.6858773, lng: 18.5750065 },
    { lat: 48.6865242, lng: 18.5737527 },
    { lat: 48.6865112, lng: 18.5736993 },
    { lat: 48.686358, lng: 18.5739132 },
    { lat: 48.6860448, lng: 18.5741251 },
    { lat: 48.6857736, lng: 18.5740102 },
    { lat: 48.6856486, lng: 18.5740599 },
    { lat: 48.685585, lng: 18.5742381 },
    { lat: 48.6854147, lng: 18.574422 },
    { lat: 48.6852506, lng: 18.5744337 },
    { lat: 48.6850725, lng: 18.5747278 },
    { lat: 48.6853686, lng: 18.5752155 },
    { lat: 48.6849293, lng: 18.5762886 },
    { lat: 48.6848173, lng: 18.5765327 },
    { lat: 48.6847374, lng: 18.5766808 },
    { lat: 48.683892, lng: 18.5782286 },
    { lat: 48.683462, lng: 18.5789525 },
    { lat: 48.6830348, lng: 18.580271 },
    { lat: 48.6822625, lng: 18.5810051 },
    { lat: 48.6822115, lng: 18.5814994 },
    { lat: 48.6818843, lng: 18.5816844 },
    { lat: 48.6817635, lng: 18.5816808 },
    { lat: 48.6815023, lng: 18.5816056 },
    { lat: 48.6814434, lng: 18.5818545 },
    { lat: 48.6813534, lng: 18.5820641 },
    { lat: 48.6813071, lng: 18.5822744 },
    { lat: 48.6810849, lng: 18.5828537 },
    { lat: 48.6810744, lng: 18.5831173 },
    { lat: 48.6810232, lng: 18.5833417 },
    { lat: 48.6810467, lng: 18.5836262 },
    { lat: 48.6809353, lng: 18.5841753 },
    { lat: 48.6815164, lng: 18.5852586 },
    { lat: 48.6811077, lng: 18.5861043 },
    { lat: 48.6808068, lng: 18.5857785 },
    { lat: 48.6805953, lng: 18.586163 },
    { lat: 48.6804233, lng: 18.5863595 },
    { lat: 48.6801921, lng: 18.586695 },
    { lat: 48.6799052, lng: 18.5870406 },
    { lat: 48.6778517, lng: 18.5849458 },
    { lat: 48.6778635, lng: 18.5857267 },
    { lat: 48.6778179, lng: 18.58601 },
    { lat: 48.6777554, lng: 18.5861872 },
    { lat: 48.6775669, lng: 18.5865207 },
    { lat: 48.6774268, lng: 18.587225 },
    { lat: 48.6773861, lng: 18.5876166 },
    { lat: 48.6774147, lng: 18.587748 },
    { lat: 48.6774096, lng: 18.5879902 },
    { lat: 48.677341, lng: 18.5884512 },
    { lat: 48.6772289, lng: 18.5888388 },
    { lat: 48.6767886, lng: 18.5894149 },
    { lat: 48.67671, lng: 18.5895431 },
    { lat: 48.6765957, lng: 18.589746 },
    { lat: 48.6763466, lng: 18.5901451 },
    { lat: 48.6761804, lng: 18.5905357 },
    { lat: 48.6759004, lng: 18.590258 },
    { lat: 48.6758203, lng: 18.5903193 },
    { lat: 48.6758806, lng: 18.5904567 },
    { lat: 48.6755114, lng: 18.5915123 },
    { lat: 48.6753967, lng: 18.5917688 },
    { lat: 48.675268, lng: 18.5917668 },
    { lat: 48.675119, lng: 18.5918695 },
    { lat: 48.6749182, lng: 18.5922818 },
    { lat: 48.6748973, lng: 18.5924818 },
    { lat: 48.6744865, lng: 18.5932052 },
    { lat: 48.6741192, lng: 18.5933201 },
    { lat: 48.6739481, lng: 18.5936995 },
    { lat: 48.6738082, lng: 18.5938056 },
    { lat: 48.6737534, lng: 18.5939812 },
    { lat: 48.6735791, lng: 18.5941968 },
    { lat: 48.6734581, lng: 18.5942318 },
    { lat: 48.673389, lng: 18.5943969 },
    { lat: 48.6731929, lng: 18.5945432 },
    { lat: 48.6730438, lng: 18.5945384 },
    { lat: 48.6729232, lng: 18.5944762 },
    { lat: 48.6725477, lng: 18.5945443 },
    { lat: 48.672345, lng: 18.5945707 },
    { lat: 48.6721466, lng: 18.5946573 },
    { lat: 48.6719899, lng: 18.5946352 },
    { lat: 48.6719177, lng: 18.594743 },
    { lat: 48.671918, lng: 18.5948489 },
    { lat: 48.6718324, lng: 18.5949794 },
    { lat: 48.6718159, lng: 18.5951502 },
    { lat: 48.6718422, lng: 18.5952819 },
    { lat: 48.6717252, lng: 18.5956228 },
    { lat: 48.6716356, lng: 18.5957195 },
    { lat: 48.671541, lng: 18.5957822 },
    { lat: 48.6715297, lng: 18.5959892 },
    { lat: 48.6700966, lng: 18.5973346 },
    { lat: 48.6699038, lng: 18.5975022 },
    { lat: 48.668686, lng: 18.5986144 },
    { lat: 48.6669078, lng: 18.6001688 },
    { lat: 48.6655872, lng: 18.6013003 },
    { lat: 48.6639976, lng: 18.602649 },
    { lat: 48.6636755, lng: 18.6031179 },
    { lat: 48.6636281, lng: 18.6032211 },
    { lat: 48.6635005, lng: 18.6035323 },
    { lat: 48.6634635, lng: 18.6035989 },
    { lat: 48.663105, lng: 18.604042 },
    { lat: 48.6630607, lng: 18.6043678 },
    { lat: 48.6631812, lng: 18.60445 },
    { lat: 48.6631344, lng: 18.6046913 },
    { lat: 48.6629635, lng: 18.6049928 },
    { lat: 48.6627979, lng: 18.60531 },
    { lat: 48.6627995, lng: 18.6054443 },
    { lat: 48.6627801, lng: 18.605583 },
    { lat: 48.6627416, lng: 18.6057497 },
    { lat: 48.6626872, lng: 18.6058899 },
    { lat: 48.662576, lng: 18.6060848 },
    { lat: 48.6625023, lng: 18.6061859 },
    { lat: 48.6623333, lng: 18.6063734 },
    { lat: 48.6621904, lng: 18.6067342 },
    { lat: 48.6621514, lng: 18.6069527 },
    { lat: 48.6621162, lng: 18.6070969 },
    { lat: 48.6621184, lng: 18.6072222 },
    { lat: 48.6621023, lng: 18.6073826 },
    { lat: 48.6620632, lng: 18.6076397 },
    { lat: 48.661927, lng: 18.6075416 },
    { lat: 48.6617979, lng: 18.6073006 },
    { lat: 48.6616039, lng: 18.6075296 },
    { lat: 48.6613773, lng: 18.6077848 },
    { lat: 48.6613769, lng: 18.6080256 },
    { lat: 48.6613129, lng: 18.6083864 },
    { lat: 48.6612375, lng: 18.6085787 },
    { lat: 48.6611237, lng: 18.6093109 },
    { lat: 48.6610584, lng: 18.609503 },
    { lat: 48.6609933, lng: 18.6097597 },
    { lat: 48.6609206, lng: 18.610169 },
    { lat: 48.6607501, lng: 18.6105164 },
    { lat: 48.6606298, lng: 18.6107363 },
    { lat: 48.6605232, lng: 18.6110991 },
    { lat: 48.660477, lng: 18.6113074 },
    { lat: 48.6604702, lng: 18.6114353 },
    { lat: 48.6602917, lng: 18.6117409 },
    { lat: 48.6601064, lng: 18.6121757 },
    { lat: 48.6599617, lng: 18.6124197 },
    { lat: 48.6596649, lng: 18.6130852 },
    { lat: 48.6594644, lng: 18.6134053 },
    { lat: 48.6591992, lng: 18.6137583 },
    { lat: 48.6588255, lng: 18.6138766 },
    { lat: 48.6584455, lng: 18.6139223 },
    { lat: 48.6583247, lng: 18.6139175 },
    { lat: 48.6582337, lng: 18.613903 },
    { lat: 48.6578939, lng: 18.6137511 },
    { lat: 48.6574635, lng: 18.6137818 },
    { lat: 48.6568304, lng: 18.6138144 },
    { lat: 48.6563848, lng: 18.613857 },
    { lat: 48.6559951, lng: 18.6139499 },
    { lat: 48.6556705, lng: 18.6140625 },
    { lat: 48.6551839, lng: 18.6144927 },
    { lat: 48.6546633, lng: 18.6150422 },
    { lat: 48.6544763, lng: 18.6153616 },
    { lat: 48.6542176, lng: 18.6156866 },
    { lat: 48.6540453, lng: 18.6159632 },
    { lat: 48.6536274, lng: 18.6163524 },
    { lat: 48.6535135, lng: 18.6165191 },
    { lat: 48.6533689, lng: 18.6166613 },
    { lat: 48.6531912, lng: 18.6169026 },
    { lat: 48.6530663, lng: 18.6171152 },
    { lat: 48.6528727, lng: 18.6173508 },
    { lat: 48.6526549, lng: 18.6176038 },
    { lat: 48.6524312, lng: 18.6179908 },
    { lat: 48.652204, lng: 18.618424 },
    { lat: 48.651863, lng: 18.618793 },
    { lat: 48.651554, lng: 18.6189187 },
    { lat: 48.6510293, lng: 18.6191861 },
    { lat: 48.6506498, lng: 18.6194071 },
    { lat: 48.6502238, lng: 18.6195589 },
    { lat: 48.6500541, lng: 18.619593 },
    { lat: 48.6496206, lng: 18.619816 },
    { lat: 48.6493415, lng: 18.6199748 },
    { lat: 48.6488892, lng: 18.6203092 },
    { lat: 48.6486638, lng: 18.6204564 },
    { lat: 48.648486, lng: 18.6206123 },
    { lat: 48.6483205, lng: 18.6208485 },
    { lat: 48.6481075, lng: 18.6210576 },
    { lat: 48.6480244, lng: 18.6211687 },
    { lat: 48.6466035, lng: 18.6241884 },
    { lat: 48.6457792, lng: 18.6252607 },
    { lat: 48.6454194, lng: 18.6257239 },
    { lat: 48.6427878, lng: 18.6295108 },
    { lat: 48.6418799, lng: 18.6308301 },
    { lat: 48.6417953, lng: 18.6309581 },
    { lat: 48.6395035, lng: 18.6342275 },
    { lat: 48.6374392, lng: 18.6371174 },
    { lat: 48.6356395, lng: 18.6396206 },
    { lat: 48.6341542, lng: 18.641908 },
    { lat: 48.633151, lng: 18.643863 },
    { lat: 48.633209, lng: 18.645409 },
    { lat: 48.633712, lng: 18.645988 },
    { lat: 48.6345505, lng: 18.6466587 },
    { lat: 48.6349903, lng: 18.6467248 },
    { lat: 48.6354142, lng: 18.646929 },
    { lat: 48.6359046, lng: 18.647093 },
    { lat: 48.6364341, lng: 18.6472526 },
    { lat: 48.6370904, lng: 18.6474798 },
    { lat: 48.6378355, lng: 18.6476373 },
    { lat: 48.6383915, lng: 18.6477858 },
    { lat: 48.6390638, lng: 18.6481654 },
    { lat: 48.6401312, lng: 18.6484454 },
    { lat: 48.6408007, lng: 18.6489849 },
    { lat: 48.6418695, lng: 18.6497961 },
    { lat: 48.6426243, lng: 18.6500327 },
    { lat: 48.6432585, lng: 18.6499773 },
    { lat: 48.6438551, lng: 18.6500574 },
    { lat: 48.6444414, lng: 18.6499284 },
    { lat: 48.6447711, lng: 18.6499541 },
    { lat: 48.6449898, lng: 18.6498735 },
    { lat: 48.6451095, lng: 18.6498525 },
    { lat: 48.64529, lng: 18.649896 },
    { lat: 48.6457833, lng: 18.6497997 },
    { lat: 48.6461538, lng: 18.6496828 },
    { lat: 48.6462123, lng: 18.6496592 },
    { lat: 48.6463675, lng: 18.6495661 },
    { lat: 48.6465765, lng: 18.6493811 },
    { lat: 48.6468777, lng: 18.6492384 },
    { lat: 48.6472825, lng: 18.6487309 },
    { lat: 48.6477951, lng: 18.6482981 },
    { lat: 48.6479391, lng: 18.6483129 },
    { lat: 48.6485382, lng: 18.6482557 },
    { lat: 48.6492396, lng: 18.6479988 },
    { lat: 48.6503876, lng: 18.6478902 },
    { lat: 48.6508642, lng: 18.6478395 },
    { lat: 48.651062, lng: 18.6478481 },
    { lat: 48.6512254, lng: 18.6477839 },
    { lat: 48.6516355, lng: 18.6478147 },
    { lat: 48.6521153, lng: 18.6478898 },
    { lat: 48.6525318, lng: 18.6478511 },
    { lat: 48.6527578, lng: 18.6477557 },
    { lat: 48.6529274, lng: 18.6477838 },
    { lat: 48.6530506, lng: 18.6478294 },
    { lat: 48.6533045, lng: 18.6478304 },
    { lat: 48.6535228, lng: 18.6476974 },
    { lat: 48.6541179, lng: 18.6474374 },
    { lat: 48.6542183, lng: 18.64727 },
    { lat: 48.654267, lng: 18.6471071 },
    { lat: 48.6543166, lng: 18.6468948 },
    { lat: 48.6549518, lng: 18.6459549 },
    { lat: 48.6549796, lng: 18.6457989 },
    { lat: 48.6550716, lng: 18.6448227 },
    { lat: 48.6551627, lng: 18.6443357 },
    { lat: 48.6551613, lng: 18.6437205 },
    { lat: 48.6554057, lng: 18.6427934 },
    { lat: 48.6555169, lng: 18.6427199 },
    { lat: 48.6558196, lng: 18.6422433 },
    { lat: 48.6559701, lng: 18.642063 },
    { lat: 48.6560671, lng: 18.6419587 },
    { lat: 48.6563216, lng: 18.6417863 },
    { lat: 48.6564481, lng: 18.6416806 },
    { lat: 48.6565115, lng: 18.6415412 },
    { lat: 48.6565716, lng: 18.6414414 },
    { lat: 48.6568678, lng: 18.6410951 },
    { lat: 48.6570738, lng: 18.6408598 },
    { lat: 48.6571968, lng: 18.6406917 },
    { lat: 48.6572231, lng: 18.6404459 },
    { lat: 48.6572568, lng: 18.6402541 },
    { lat: 48.6574343, lng: 18.6400137 },
    { lat: 48.657514, lng: 18.6395823 },
    { lat: 48.6575292, lng: 18.6391969 },
    { lat: 48.6576894, lng: 18.6387745 },
    { lat: 48.6577677, lng: 18.638511 },
    { lat: 48.6577999, lng: 18.6383951 },
    { lat: 48.6578716, lng: 18.6381368 },
    { lat: 48.6579503, lng: 18.6378814 },
    { lat: 48.6582004, lng: 18.637138 },
    { lat: 48.6585112, lng: 18.636628 },
    { lat: 48.6587902, lng: 18.6361332 },
    { lat: 48.6590071, lng: 18.635864 },
    { lat: 48.6592531, lng: 18.6358457 },
    { lat: 48.6593106, lng: 18.6356585 },
    { lat: 48.6593716, lng: 18.635535 },
    { lat: 48.6596289, lng: 18.6351992 },
    { lat: 48.6598444, lng: 18.6350768 },
    { lat: 48.6600142, lng: 18.6350871 },
    { lat: 48.6602911, lng: 18.6349532 },
    { lat: 48.6603209, lng: 18.6348954 },
    { lat: 48.6603267, lng: 18.6348369 },
    { lat: 48.660392, lng: 18.6346273 },
    { lat: 48.6604252, lng: 18.6344441 },
    { lat: 48.6605008, lng: 18.6342775 },
    { lat: 48.6605733, lng: 18.6340967 },
    { lat: 48.660603, lng: 18.6338915 },
    { lat: 48.6606599, lng: 18.6337771 },
    { lat: 48.6606852, lng: 18.6332802 },
    { lat: 48.6607182, lng: 18.6332034 },
    { lat: 48.6608149, lng: 18.6330292 },
    { lat: 48.6608555, lng: 18.6329261 },
    { lat: 48.6610253, lng: 18.632748 },
    { lat: 48.6610963, lng: 18.6326019 },
    { lat: 48.6611143, lng: 18.6325144 },
    { lat: 48.6611369, lng: 18.6324553 },
    { lat: 48.6611598, lng: 18.6322912 },
    { lat: 48.6612293, lng: 18.6321596 },
    { lat: 48.6612742, lng: 18.6320503 },
    { lat: 48.6613585, lng: 18.6317945 },
    { lat: 48.6613952, lng: 18.6317604 },
    { lat: 48.6615619, lng: 18.6316515 },
    { lat: 48.6617673, lng: 18.6315075 },
    { lat: 48.661839, lng: 18.6313328 },
    { lat: 48.6619129, lng: 18.6312167 },
    { lat: 48.6620206, lng: 18.6310794 },
    { lat: 48.6622127, lng: 18.6309827 },
    { lat: 48.6623335, lng: 18.6308383 },
    { lat: 48.6623939, lng: 18.630598 },
    { lat: 48.6625015, lng: 18.6304806 },
    { lat: 48.6626158, lng: 18.6304055 },
    { lat: 48.6628391, lng: 18.6302155 },
    { lat: 48.6629187, lng: 18.6301839 },
    { lat: 48.6630765, lng: 18.6301607 },
    { lat: 48.663269, lng: 18.6301128 },
    { lat: 48.663354, lng: 18.630016 },
    { lat: 48.6634451, lng: 18.6299906 },
    { lat: 48.6635274, lng: 18.6297989 },
    { lat: 48.6636285, lng: 18.6296035 },
    { lat: 48.6638433, lng: 18.6294676 },
    { lat: 48.6639288, lng: 18.6293169 },
    { lat: 48.664152, lng: 18.6292702 },
    { lat: 48.6642965, lng: 18.6292104 },
    { lat: 48.6643719, lng: 18.6291448 },
    { lat: 48.6645158, lng: 18.6289894 },
    { lat: 48.664692, lng: 18.6289521 },
    { lat: 48.6647629, lng: 18.6289081 },
    { lat: 48.664813, lng: 18.6288342 },
    { lat: 48.6648444, lng: 18.6287672 },
    { lat: 48.6649124, lng: 18.6286882 },
    { lat: 48.6651018, lng: 18.6286228 },
    { lat: 48.6651398, lng: 18.628595 },
    { lat: 48.6651941, lng: 18.6284945 },
    { lat: 48.6652642, lng: 18.6284131 },
    { lat: 48.6655298, lng: 18.6283423 },
    { lat: 48.6656631, lng: 18.6281572 },
    { lat: 48.6657015, lng: 18.6281372 },
    { lat: 48.6658665, lng: 18.6280976 },
    { lat: 48.6659786, lng: 18.6279811 },
    { lat: 48.6660936, lng: 18.627835 },
    { lat: 48.6661985, lng: 18.6277939 },
    { lat: 48.6663266, lng: 18.6276805 },
    { lat: 48.6663983, lng: 18.6275232 },
    { lat: 48.6665252, lng: 18.6273253 },
    { lat: 48.6666916, lng: 18.6272121 },
    { lat: 48.6669001, lng: 18.6272479 },
    { lat: 48.6670637, lng: 18.6272917 },
    { lat: 48.6672826, lng: 18.6273362 },
    { lat: 48.6673096, lng: 18.6273551 },
    { lat: 48.6674045, lng: 18.6276303 },
    { lat: 48.6674167, lng: 18.627794 },
    { lat: 48.6675275, lng: 18.6279042 },
    { lat: 48.6675625, lng: 18.628032 },
    { lat: 48.6676481, lng: 18.6281335 },
    { lat: 48.6676466, lng: 18.6281494 },
    { lat: 48.6684711, lng: 18.6295084 },
    { lat: 48.6690685, lng: 18.6300082 },
    { lat: 48.6693628, lng: 18.6302761 },
    { lat: 48.6700367, lng: 18.6309153 },
    { lat: 48.6706639, lng: 18.6314847 },
    { lat: 48.670916, lng: 18.6315576 },
    { lat: 48.6711205, lng: 18.6313352 },
    { lat: 48.6713355, lng: 18.631135 },
    { lat: 48.6717652, lng: 18.6310306 },
    { lat: 48.6720405, lng: 18.6310554 },
    { lat: 48.6723512, lng: 18.6312746 },
    { lat: 48.6728417, lng: 18.6312308 },
    { lat: 48.672947, lng: 18.6312772 },
    { lat: 48.6729985, lng: 18.6311677 },
    { lat: 48.6731158, lng: 18.6312911 },
    { lat: 48.6734017, lng: 18.6311154 },
    { lat: 48.6735255, lng: 18.6311088 },
    { lat: 48.6735534, lng: 18.6310401 },
    { lat: 48.6736815, lng: 18.6309462 },
    { lat: 48.673957, lng: 18.6308703 },
    { lat: 48.6743242, lng: 18.630922 },
    { lat: 48.6746475, lng: 18.6311682 },
    { lat: 48.6748342, lng: 18.6325649 },
    { lat: 48.6748978, lng: 18.6326742 },
    { lat: 48.6749937, lng: 18.6327402 },
    { lat: 48.6752969, lng: 18.6328401 },
    { lat: 48.6754979, lng: 18.6329689 },
    { lat: 48.6756342, lng: 18.6329897 },
    { lat: 48.6757231, lng: 18.6329213 },
    { lat: 48.6757599, lng: 18.6328486 },
    { lat: 48.6758249, lng: 18.6323385 },
    { lat: 48.6758531, lng: 18.6319179 },
    { lat: 48.6758847, lng: 18.6316053 },
    { lat: 48.676151, lng: 18.6311915 },
    { lat: 48.6764451, lng: 18.6308275 },
    { lat: 48.6766864, lng: 18.6299892 },
    { lat: 48.6767389, lng: 18.6298532 },
    { lat: 48.6768589, lng: 18.6295044 },
    { lat: 48.6769445, lng: 18.6293551 },
    { lat: 48.6770072, lng: 18.6291597 },
    { lat: 48.6770115, lng: 18.6290892 },
    { lat: 48.6771919, lng: 18.628819 },
    { lat: 48.6773255, lng: 18.6285346 },
    { lat: 48.6773569, lng: 18.6283848 },
    { lat: 48.6773017, lng: 18.6283213 },
    { lat: 48.6775074, lng: 18.6281194 },
    { lat: 48.6777367, lng: 18.6280013 },
    { lat: 48.6779912, lng: 18.6279922 },
    { lat: 48.6781692, lng: 18.6282837 },
    { lat: 48.6783971, lng: 18.6285791 },
    { lat: 48.6785684, lng: 18.6280291 },
    { lat: 48.6787679, lng: 18.6276071 },
    { lat: 48.6787425, lng: 18.6274517 },
    { lat: 48.6786865, lng: 18.6273719 },
    { lat: 48.6786387, lng: 18.627135 },
    { lat: 48.678699, lng: 18.6268503 },
    { lat: 48.6787391, lng: 18.6266952 },
    { lat: 48.6788035, lng: 18.626486 },
    { lat: 48.6787695, lng: 18.6262528 },
    { lat: 48.6787147, lng: 18.6261567 },
    { lat: 48.6787214, lng: 18.6260535 },
    { lat: 48.6788046, lng: 18.6259433 },
    { lat: 48.6790607, lng: 18.6257541 },
    { lat: 48.6790785, lng: 18.6255218 },
    { lat: 48.6791456, lng: 18.6254254 },
    { lat: 48.6793012, lng: 18.6252771 },
    { lat: 48.6793863, lng: 18.6250571 },
    { lat: 48.6796468, lng: 18.6246586 },
    { lat: 48.6797296, lng: 18.624582 },
    { lat: 48.6798224, lng: 18.6243772 },
    { lat: 48.6798884, lng: 18.6243052 },
    { lat: 48.6799652, lng: 18.6241602 },
    { lat: 48.6801041, lng: 18.6239347 },
    { lat: 48.6802391, lng: 18.6236543 },
    { lat: 48.6803324, lng: 18.6235658 },
    { lat: 48.6804183, lng: 18.6234007 },
    { lat: 48.6806057, lng: 18.6230054 },
    { lat: 48.6806422, lng: 18.622826 },
    { lat: 48.6806627, lng: 18.62268 },
    { lat: 48.6806448, lng: 18.6225595 },
    { lat: 48.6806238, lng: 18.6220252 },
    { lat: 48.6806269, lng: 18.6213271 },
    { lat: 48.6806351, lng: 18.6210402 },
    { lat: 48.6806787, lng: 18.6207175 },
    { lat: 48.6807155, lng: 18.6205849 },
    { lat: 48.6807927, lng: 18.6199644 },
    { lat: 48.6807362, lng: 18.6197978 },
    { lat: 48.680851, lng: 18.6193936 },
    { lat: 48.6809145, lng: 18.6191295 },
    { lat: 48.6810548, lng: 18.6188421 },
    { lat: 48.6812019, lng: 18.6184528 },
    { lat: 48.6813935, lng: 18.6180131 },
    { lat: 48.6814195, lng: 18.6179082 },
    { lat: 48.6817039, lng: 18.6176819 },
    { lat: 48.6819751, lng: 18.6171871 },
    { lat: 48.6820528, lng: 18.6170597 },
    { lat: 48.6821894, lng: 18.6168531 },
    { lat: 48.6834853, lng: 18.6152 },
    { lat: 48.6842752, lng: 18.6141837 },
    { lat: 48.6845004, lng: 18.6138626 },
    { lat: 48.6849939, lng: 18.613139 },
    { lat: 48.6858059, lng: 18.6122613 },
    { lat: 48.6872431, lng: 18.6141481 },
    { lat: 48.6871333, lng: 18.6143704 },
    { lat: 48.6870806, lng: 18.6145443 },
    { lat: 48.68704, lng: 18.6147303 },
    { lat: 48.6869995, lng: 18.6150662 },
    { lat: 48.6872344, lng: 18.6151794 },
    { lat: 48.6878193, lng: 18.6148151 },
    { lat: 48.6879208, lng: 18.614939 },
    { lat: 48.6896799, lng: 18.6171301 },
    { lat: 48.6896947, lng: 18.6171501 },
    { lat: 48.6913493, lng: 18.6192846 },
    { lat: 48.6919206, lng: 18.6200315 },
    { lat: 48.6918663, lng: 18.6203177 },
    { lat: 48.6917831, lng: 18.6205336 },
    { lat: 48.6917991, lng: 18.6206855 },
    { lat: 48.6919198, lng: 18.6211058 },
    { lat: 48.6917621, lng: 18.6214636 },
    { lat: 48.6914996, lng: 18.6218879 },
    { lat: 48.6912593, lng: 18.6223497 },
    { lat: 48.6912014, lng: 18.6224236 },
    { lat: 48.6911273, lng: 18.6226405 },
    { lat: 48.6912074, lng: 18.6231636 },
    { lat: 48.6908985, lng: 18.623294 },
    { lat: 48.6907409, lng: 18.6234047 },
    { lat: 48.6905677, lng: 18.6233275 },
    { lat: 48.6905926, lng: 18.6235829 },
    { lat: 48.6903892, lng: 18.6243493 },
    { lat: 48.6904255, lng: 18.6245623 },
    { lat: 48.6904334, lng: 18.6248151 },
    { lat: 48.6904154, lng: 18.6250254 },
    { lat: 48.6904311, lng: 18.6250657 },
    { lat: 48.6904356, lng: 18.6251722 },
    { lat: 48.6904199, lng: 18.6252748 },
    { lat: 48.6903882, lng: 18.6253583 },
    { lat: 48.6903097, lng: 18.6254279 },
    { lat: 48.690299, lng: 18.6256891 },
    { lat: 48.6903151, lng: 18.625972 },
    { lat: 48.6904363, lng: 18.626147 },
    { lat: 48.6905127, lng: 18.6263504 },
    { lat: 48.690476, lng: 18.6267615 },
    { lat: 48.6904994, lng: 18.6268579 },
    { lat: 48.6906743, lng: 18.6274472 },
    { lat: 48.6900316, lng: 18.6282269 },
    { lat: 48.6902374, lng: 18.6283398 },
    { lat: 48.6902506, lng: 18.6286294 },
    { lat: 48.6903088, lng: 18.6288722 },
    { lat: 48.6903587, lng: 18.6291499 },
    { lat: 48.6904878, lng: 18.629199 },
    { lat: 48.6905466, lng: 18.6292018 },
    { lat: 48.6907928, lng: 18.6293997 },
    { lat: 48.6914856, lng: 18.6297816 },
    { lat: 48.6919457, lng: 18.630176 },
    { lat: 48.6922765, lng: 18.6302675 },
    { lat: 48.6923516, lng: 18.6308905 },
    { lat: 48.6923461, lng: 18.63125 },
    { lat: 48.6923728, lng: 18.6313017 },
    { lat: 48.6933962, lng: 18.6332414 },
    { lat: 48.6937069, lng: 18.6336921 },
    { lat: 48.6920177, lng: 18.6369118 },
    { lat: 48.6910462, lng: 18.6380587 },
    { lat: 48.6910348, lng: 18.639337 },
    { lat: 48.6910224, lng: 18.6408714 },
    { lat: 48.6909964, lng: 18.6438311 },
    { lat: 48.6904192, lng: 18.6451815 },
    { lat: 48.6900998, lng: 18.6463569 },
    { lat: 48.6902142, lng: 18.647331 },
    { lat: 48.6896909, lng: 18.6476695 },
    { lat: 48.6896739, lng: 18.6478144 },
    { lat: 48.6896801, lng: 18.648036 },
    { lat: 48.6897292, lng: 18.6481935 },
    { lat: 48.6897366, lng: 18.6482925 },
    { lat: 48.6896971, lng: 18.6484584 },
    { lat: 48.689708, lng: 18.6485345 },
    { lat: 48.6896939, lng: 18.648629 },
    { lat: 48.6896311, lng: 18.648701 },
    { lat: 48.6895822, lng: 18.6487144 },
    { lat: 48.6895013, lng: 18.6487839 },
    { lat: 48.6891572, lng: 18.6496438 },
    { lat: 48.6891285, lng: 18.6496773 },
    { lat: 48.6889766, lng: 18.649934 },
    { lat: 48.689091, lng: 18.6500917 },
    { lat: 48.6891112, lng: 18.6500893 },
    { lat: 48.6891839, lng: 18.6503081 },
    { lat: 48.6892196, lng: 18.650534 },
    { lat: 48.6892176, lng: 18.6506642 },
    { lat: 48.6891043, lng: 18.6508208 },
    { lat: 48.6889118, lng: 18.6509115 },
    { lat: 48.6888193, lng: 18.6510206 },
    { lat: 48.6886654, lng: 18.6514524 },
    { lat: 48.6886226, lng: 18.651685 },
    { lat: 48.6886203, lng: 18.6518934 },
    { lat: 48.6885535, lng: 18.6520572 },
    { lat: 48.688388, lng: 18.6522945 },
    { lat: 48.6881671, lng: 18.6523411 },
    { lat: 48.6880257, lng: 18.6522459 },
    { lat: 48.6878711, lng: 18.6522245 },
    { lat: 48.6877286, lng: 18.6520706 },
    { lat: 48.6875948, lng: 18.6520173 },
    { lat: 48.6874429, lng: 18.6520009 },
    { lat: 48.6872373, lng: 18.6520357 },
    { lat: 48.6868999, lng: 18.6519492 },
    { lat: 48.6866681, lng: 18.6520649 },
    { lat: 48.6864313, lng: 18.6520653 },
    { lat: 48.6862434, lng: 18.6521797 },
    { lat: 48.685792, lng: 18.6522149 },
    { lat: 48.6855326, lng: 18.6520895 },
    { lat: 48.685295, lng: 18.6520518 },
    { lat: 48.6851683, lng: 18.6520034 },
    { lat: 48.6849971, lng: 18.6519672 },
    { lat: 48.6848451, lng: 18.6519712 },
    { lat: 48.6846769, lng: 18.6520949 },
    { lat: 48.68458, lng: 18.6522228 },
    { lat: 48.6844709, lng: 18.6522918 },
    { lat: 48.6840683, lng: 18.652528 },
    { lat: 48.6838239, lng: 18.6527653 },
    { lat: 48.6836079, lng: 18.6530879 },
    { lat: 48.6835007, lng: 18.6532791 },
    { lat: 48.6834388, lng: 18.6534062 },
    { lat: 48.6831875, lng: 18.6537892 },
    { lat: 48.6830771, lng: 18.653983 },
    { lat: 48.6829184, lng: 18.6541573 },
    { lat: 48.6827819, lng: 18.6543235 },
    { lat: 48.6825038, lng: 18.6545838 },
    { lat: 48.6822856, lng: 18.654659 },
    { lat: 48.6820137, lng: 18.654846 },
    { lat: 48.681772, lng: 18.6551158 },
    { lat: 48.6816424, lng: 18.6552205 },
    { lat: 48.6814201, lng: 18.6555128 },
    { lat: 48.6811822, lng: 18.6557057 },
    { lat: 48.6810073, lng: 18.6557263 },
    { lat: 48.680846, lng: 18.6558092 },
    { lat: 48.6807217, lng: 18.6559336 },
    { lat: 48.6805905, lng: 18.6561138 },
    { lat: 48.6804, lng: 18.6562841 },
    { lat: 48.6802735, lng: 18.6563624 },
    { lat: 48.6802147, lng: 18.6564663 },
    { lat: 48.6801491, lng: 18.6565257 },
    { lat: 48.680037, lng: 18.6567314 },
    { lat: 48.6799246, lng: 18.656844 },
    { lat: 48.6797693, lng: 18.65693 },
    { lat: 48.6796144, lng: 18.6571588 },
    { lat: 48.6794826, lng: 18.6572213 },
    { lat: 48.6790566, lng: 18.6574625 },
    { lat: 48.6789137, lng: 18.6575742 },
    { lat: 48.6786191, lng: 18.6576958 },
    { lat: 48.6781811, lng: 18.6581493 },
    { lat: 48.6780693, lng: 18.6583386 },
    { lat: 48.6779945, lng: 18.6584383 },
    { lat: 48.6778379, lng: 18.6585856 },
    { lat: 48.6777632, lng: 18.6587089 },
    { lat: 48.6776274, lng: 18.6590146 },
    { lat: 48.6775747, lng: 18.6591027 },
    { lat: 48.6775179, lng: 18.6592428 },
    { lat: 48.6774832, lng: 18.6593755 },
    { lat: 48.6774564, lng: 18.6595504 },
    { lat: 48.6773693, lng: 18.6597345 },
    { lat: 48.6773178, lng: 18.6598668 },
    { lat: 48.677282, lng: 18.6600017 },
    { lat: 48.6772711, lng: 18.6601551 },
    { lat: 48.6771606, lng: 18.6606832 },
    { lat: 48.6770427, lng: 18.6611348 },
    { lat: 48.6770182, lng: 18.6613301 },
    { lat: 48.677053, lng: 18.6619179 },
    { lat: 48.6771257, lng: 18.6622659 },
    { lat: 48.6771669, lng: 18.6624001 },
    { lat: 48.6771872, lng: 18.6625057 },
    { lat: 48.6772647, lng: 18.662734 },
    { lat: 48.6773543, lng: 18.6629511 },
    { lat: 48.6775529, lng: 18.6633765 },
    { lat: 48.6777638, lng: 18.6636698 },
    { lat: 48.6780599, lng: 18.6640181 },
    { lat: 48.6784059, lng: 18.6644776 },
    { lat: 48.6785989, lng: 18.665028 },
    { lat: 48.6786583, lng: 18.6652773 },
    { lat: 48.6786797, lng: 18.6655927 },
    { lat: 48.6787155, lng: 18.6658153 },
    { lat: 48.6786895, lng: 18.6660062 },
    { lat: 48.6786973, lng: 18.6662144 },
    { lat: 48.6787465, lng: 18.6664168 },
    { lat: 48.6787257, lng: 18.6671685 },
    { lat: 48.6786805, lng: 18.6672525 },
    { lat: 48.6786096, lng: 18.6672954 },
    { lat: 48.6783349, lng: 18.6676215 },
    { lat: 48.6782574, lng: 18.6677762 },
    { lat: 48.678105, lng: 18.6680468 },
    { lat: 48.6776954, lng: 18.6684675 },
    { lat: 48.6776018, lng: 18.6686745 },
    { lat: 48.6774991, lng: 18.6687998 },
    { lat: 48.6773491, lng: 18.6688848 },
    { lat: 48.6771305, lng: 18.6690363 },
    { lat: 48.6770722, lng: 18.6691298 },
    { lat: 48.6770478, lng: 18.6692391 },
    { lat: 48.6769414, lng: 18.6696543 },
    { lat: 48.6768073, lng: 18.6702266 },
    { lat: 48.6767667, lng: 18.6706484 },
    { lat: 48.676612, lng: 18.6710705 },
    { lat: 48.6765007, lng: 18.6712871 },
    { lat: 48.6763039, lng: 18.6715495 },
    { lat: 48.6761582, lng: 18.671821 },
    { lat: 48.6759237, lng: 18.6720547 },
    { lat: 48.6757491, lng: 18.6721673 },
    { lat: 48.6753478, lng: 18.6726748 },
    { lat: 48.6751937, lng: 18.6728085 },
    { lat: 48.6749961, lng: 18.673082 },
    { lat: 48.6747721, lng: 18.6735296 },
    { lat: 48.6746261, lng: 18.6738586 },
    { lat: 48.6746028, lng: 18.6739703 },
    { lat: 48.6745566, lng: 18.674079 },
    { lat: 48.6745087, lng: 18.6742397 },
    { lat: 48.6744823, lng: 18.6743786 },
    { lat: 48.674417, lng: 18.6745264 },
    { lat: 48.6743365, lng: 18.6749177 },
    { lat: 48.6741956, lng: 18.675408 },
    { lat: 48.6741346, lng: 18.6757669 },
    { lat: 48.6749633, lng: 18.6754006 },
    { lat: 48.675729, lng: 18.6752938 },
    { lat: 48.675941, lng: 18.6752066 },
    { lat: 48.676649, lng: 18.6750319 },
    { lat: 48.6771521, lng: 18.6744867 },
    { lat: 48.6779373, lng: 18.6741527 },
    { lat: 48.6783312, lng: 18.6743499 },
    { lat: 48.6786712, lng: 18.6747992 },
    { lat: 48.6792331, lng: 18.6751872 },
    { lat: 48.6798697, lng: 18.6757022 },
    { lat: 48.680256, lng: 18.6761797 },
    { lat: 48.6805393, lng: 18.6759069 },
    { lat: 48.6808397, lng: 18.6760405 },
    { lat: 48.6817537, lng: 18.6759225 },
    { lat: 48.6825262, lng: 18.6765372 },
    { lat: 48.6827485, lng: 18.6765576 },
    { lat: 48.6832196, lng: 18.6769296 },
    { lat: 48.6834816, lng: 18.6771908 },
    { lat: 48.6839388, lng: 18.6779799 },
    { lat: 48.6841034, lng: 18.6781915 },
    { lat: 48.684427, lng: 18.679351 },
    { lat: 48.6845554, lng: 18.6799829 },
    { lat: 48.6849829, lng: 18.6808096 },
    { lat: 48.6851845, lng: 18.6812894 },
    { lat: 48.6854675, lng: 18.6825356 },
    { lat: 48.6853984, lng: 18.6836955 },
    { lat: 48.6856597, lng: 18.6844472 },
    { lat: 48.6858088, lng: 18.6847085 },
  ],
  Lipník: [
    { lat: 48.818129, lng: 18.753317 },
    { lat: 48.8177736, lng: 18.7522114 },
    { lat: 48.8176675, lng: 18.7514388 },
    { lat: 48.8174908, lng: 18.7511198 },
    { lat: 48.817441, lng: 18.7508824 },
    { lat: 48.8174272, lng: 18.7505989 },
    { lat: 48.8174239, lng: 18.7502171 },
    { lat: 48.8173821, lng: 18.7501266 },
    { lat: 48.8173276, lng: 18.7498627 },
    { lat: 48.8171731, lng: 18.7497349 },
    { lat: 48.8170667, lng: 18.7497056 },
    { lat: 48.8169653, lng: 18.7495797 },
    { lat: 48.8168556, lng: 18.7495074 },
    { lat: 48.8168079, lng: 18.7493507 },
    { lat: 48.8167322, lng: 18.7492632 },
    { lat: 48.8165737, lng: 18.7491653 },
    { lat: 48.816423, lng: 18.7488754 },
    { lat: 48.816283, lng: 18.7487606 },
    { lat: 48.8162026, lng: 18.7487327 },
    { lat: 48.8160576, lng: 18.748528 },
    { lat: 48.8158418, lng: 18.7483289 },
    { lat: 48.8155243, lng: 18.7482105 },
    { lat: 48.8152907, lng: 18.7475839 },
    { lat: 48.8151887, lng: 18.7473607 },
    { lat: 48.8151653, lng: 18.7472161 },
    { lat: 48.8150916, lng: 18.7471459 },
    { lat: 48.8150634, lng: 18.7470355 },
    { lat: 48.8151418, lng: 18.7466861 },
    { lat: 48.8150171, lng: 18.7465022 },
    { lat: 48.8149524, lng: 18.7463243 },
    { lat: 48.8146913, lng: 18.7463111 },
    { lat: 48.8145576, lng: 18.7461906 },
    { lat: 48.8144678, lng: 18.7460923 },
    { lat: 48.8143781, lng: 18.7460573 },
    { lat: 48.8142706, lng: 18.7461164 },
    { lat: 48.8141712, lng: 18.7459423 },
    { lat: 48.8140601, lng: 18.7459518 },
    { lat: 48.814033, lng: 18.7457826 },
    { lat: 48.8139061, lng: 18.7457457 },
    { lat: 48.8138887, lng: 18.7455864 },
    { lat: 48.8138156, lng: 18.745572 },
    { lat: 48.8137676, lng: 18.7453878 },
    { lat: 48.8135906, lng: 18.7449833 },
    { lat: 48.8135444, lng: 18.7447696 },
    { lat: 48.8135904, lng: 18.7443779 },
    { lat: 48.8133911, lng: 18.7439765 },
    { lat: 48.8134365, lng: 18.7436175 },
    { lat: 48.813391, lng: 18.743525 },
    { lat: 48.8133503, lng: 18.7429009 },
    { lat: 48.8133732, lng: 18.7427163 },
    { lat: 48.8133904, lng: 18.7419077 },
    { lat: 48.8131349, lng: 18.7406955 },
    { lat: 48.8129061, lng: 18.7402502 },
    { lat: 48.8127408, lng: 18.7394862 },
    { lat: 48.8123499, lng: 18.738705 },
    { lat: 48.8121502, lng: 18.7382962 },
    { lat: 48.8122422, lng: 18.7378146 },
    { lat: 48.8123634, lng: 18.7375888 },
    { lat: 48.8122064, lng: 18.7366024 },
    { lat: 48.8116128, lng: 18.7355663 },
    { lat: 48.8112584, lng: 18.7350481 },
    { lat: 48.8108629, lng: 18.7343907 },
    { lat: 48.8108278, lng: 18.734327 },
    { lat: 48.8108033, lng: 18.7338391 },
    { lat: 48.8110976, lng: 18.7331956 },
    { lat: 48.8107379, lng: 18.7312955 },
    { lat: 48.8107255, lng: 18.7278564 },
    { lat: 48.8101032, lng: 18.7254683 },
    { lat: 48.8100753, lng: 18.7254108 },
    { lat: 48.8084659, lng: 18.7228461 },
    { lat: 48.8061184, lng: 18.7190793 },
    { lat: 48.8058638, lng: 18.7186587 },
    { lat: 48.8046791, lng: 18.7173258 },
    { lat: 48.8043486, lng: 18.7171094 },
    { lat: 48.8036536, lng: 18.7166144 },
    { lat: 48.8031923, lng: 18.7163151 },
    { lat: 48.8028102, lng: 18.717449 },
    { lat: 48.8022629, lng: 18.7171644 },
    { lat: 48.8012533, lng: 18.717012 },
    { lat: 48.8000349, lng: 18.7168342 },
    { lat: 48.7986816, lng: 18.7160857 },
    { lat: 48.7965217, lng: 18.7155903 },
    { lat: 48.7950963, lng: 18.7145297 },
    { lat: 48.7934153, lng: 18.713193 },
    { lat: 48.7918322, lng: 18.7119847 },
    { lat: 48.7912092, lng: 18.7115102 },
    { lat: 48.7910296, lng: 18.7112927 },
    { lat: 48.7907638, lng: 18.711061 },
    { lat: 48.7900149, lng: 18.7102309 },
    { lat: 48.7897925, lng: 18.7099714 },
    { lat: 48.7894552, lng: 18.7095416 },
    { lat: 48.7891796, lng: 18.7092786 },
    { lat: 48.7889638, lng: 18.7089958 },
    { lat: 48.7888165, lng: 18.7087715 },
    { lat: 48.7886654, lng: 18.7086407 },
    { lat: 48.788551, lng: 18.7085698 },
    { lat: 48.7880713, lng: 18.7083516 },
    { lat: 48.7877643, lng: 18.7081099 },
    { lat: 48.7874234, lng: 18.7078276 },
    { lat: 48.7870648, lng: 18.7074863 },
    { lat: 48.7869718, lng: 18.7073095 },
    { lat: 48.7867206, lng: 18.7069723 },
    { lat: 48.7860664, lng: 18.7063325 },
    { lat: 48.7858495, lng: 18.7061993 },
    { lat: 48.7852381, lng: 18.7057886 },
    { lat: 48.7851219, lng: 18.7056611 },
    { lat: 48.7849181, lng: 18.7053919 },
    { lat: 48.7847768, lng: 18.7051741 },
    { lat: 48.7845908, lng: 18.7048129 },
    { lat: 48.7844813, lng: 18.7046649 },
    { lat: 48.784417, lng: 18.7044943 },
    { lat: 48.7835733, lng: 18.7043418 },
    { lat: 48.7828614, lng: 18.7041003 },
    { lat: 48.7822533, lng: 18.704099 },
    { lat: 48.7819085, lng: 18.7038906 },
    { lat: 48.7814447, lng: 18.7035446 },
    { lat: 48.7809312, lng: 18.7035805 },
    { lat: 48.7808355, lng: 18.7030732 },
    { lat: 48.7807145, lng: 18.702961 },
    { lat: 48.780613, lng: 18.7025757 },
    { lat: 48.7802075, lng: 18.7025452 },
    { lat: 48.7800124, lng: 18.702694 },
    { lat: 48.7799694, lng: 18.7027118 },
    { lat: 48.7798926, lng: 18.702454 },
    { lat: 48.7798281, lng: 18.7022594 },
    { lat: 48.779662, lng: 18.7025717 },
    { lat: 48.7796726, lng: 18.7030908 },
    { lat: 48.7796894, lng: 18.7032393 },
    { lat: 48.779772, lng: 18.7038382 },
    { lat: 48.7796456, lng: 18.7037713 },
    { lat: 48.7795878, lng: 18.7037866 },
    { lat: 48.7795471, lng: 18.703764 },
    { lat: 48.7794943, lng: 18.7037722 },
    { lat: 48.7794539, lng: 18.70386 },
    { lat: 48.7794006, lng: 18.7038765 },
    { lat: 48.7793228, lng: 18.7038298 },
    { lat: 48.7792832, lng: 18.7036816 },
    { lat: 48.7792035, lng: 18.703604 },
    { lat: 48.7790769, lng: 18.7035343 },
    { lat: 48.7790361, lng: 18.7036383 },
    { lat: 48.778892, lng: 18.704067 },
    { lat: 48.778843, lng: 18.7042035 },
    { lat: 48.7785588, lng: 18.7050794 },
    { lat: 48.7783929, lng: 18.7055425 },
    { lat: 48.7779843, lng: 18.7059833 },
    { lat: 48.7776596, lng: 18.7063667 },
    { lat: 48.7775129, lng: 18.7065551 },
    { lat: 48.777138, lng: 18.7067815 },
    { lat: 48.7769005, lng: 18.7070253 },
    { lat: 48.7767718, lng: 18.7072544 },
    { lat: 48.7763809, lng: 18.7074151 },
    { lat: 48.7762036, lng: 18.7075783 },
    { lat: 48.7760418, lng: 18.7081646 },
    { lat: 48.7760424, lng: 18.7082604 },
    { lat: 48.7761064, lng: 18.7084246 },
    { lat: 48.7762473, lng: 18.7089345 },
    { lat: 48.7764739, lng: 18.7096372 },
    { lat: 48.7764491, lng: 18.7099996 },
    { lat: 48.7763688, lng: 18.7103347 },
    { lat: 48.7762797, lng: 18.7115698 },
    { lat: 48.7767685, lng: 18.7117683 },
    { lat: 48.7769216, lng: 18.7118488 },
    { lat: 48.7772977, lng: 18.7120953 },
    { lat: 48.7774828, lng: 18.7122885 },
    { lat: 48.778024, lng: 18.7130964 },
    { lat: 48.7781501, lng: 18.7132599 },
    { lat: 48.778281, lng: 18.7134114 },
    { lat: 48.7786447, lng: 18.7139959 },
    { lat: 48.7786088, lng: 18.7154288 },
    { lat: 48.7791252, lng: 18.7156623 },
    { lat: 48.7791518, lng: 18.7157356 },
    { lat: 48.7791676, lng: 18.7160787 },
    { lat: 48.779266, lng: 18.7160857 },
    { lat: 48.7794528, lng: 18.7160788 },
    { lat: 48.7795389, lng: 18.7160841 },
    { lat: 48.7797809, lng: 18.7161156 },
    { lat: 48.7804641, lng: 18.7163031 },
    { lat: 48.7804785, lng: 18.7162588 },
    { lat: 48.780578, lng: 18.7163913 },
    { lat: 48.7806995, lng: 18.7162986 },
    { lat: 48.7809358, lng: 18.7164809 },
    { lat: 48.781089, lng: 18.7164784 },
    { lat: 48.7812257, lng: 18.7166314 },
    { lat: 48.7813042, lng: 18.7165797 },
    { lat: 48.7813686, lng: 18.716712 },
    { lat: 48.7816358, lng: 18.7169232 },
    { lat: 48.7816729, lng: 18.717134 },
    { lat: 48.7818129, lng: 18.717328 },
    { lat: 48.7820344, lng: 18.7177843 },
    { lat: 48.7822259, lng: 18.717907 },
    { lat: 48.7824162, lng: 18.7183724 },
    { lat: 48.7825141, lng: 18.7184725 },
    { lat: 48.7828894, lng: 18.7185747 },
    { lat: 48.7833103, lng: 18.7191338 },
    { lat: 48.7839823, lng: 18.7196628 },
    { lat: 48.7840408, lng: 18.7197058 },
    { lat: 48.7843457, lng: 18.7200554 },
    { lat: 48.784516, lng: 18.7203524 },
    { lat: 48.7847171, lng: 18.7206563 },
    { lat: 48.7850088, lng: 18.7209493 },
    { lat: 48.7851636, lng: 18.7212538 },
    { lat: 48.7852778, lng: 18.7216423 },
    { lat: 48.7854018, lng: 18.721897 },
    { lat: 48.7856235, lng: 18.722487 },
    { lat: 48.785771, lng: 18.7227384 },
    { lat: 48.7861165, lng: 18.7228722 },
    { lat: 48.7863123, lng: 18.7229944 },
    { lat: 48.7864662, lng: 18.7231925 },
    { lat: 48.7865595, lng: 18.7232731 },
    { lat: 48.7867027, lng: 18.7234471 },
    { lat: 48.7867737, lng: 18.723485 },
    { lat: 48.7868716, lng: 18.7235876 },
    { lat: 48.7869165, lng: 18.723692 },
    { lat: 48.7871045, lng: 18.7238133 },
    { lat: 48.7871972, lng: 18.7240303 },
    { lat: 48.7873733, lng: 18.7242057 },
    { lat: 48.7874747, lng: 18.724246 },
    { lat: 48.7875836, lng: 18.7245572 },
    { lat: 48.7878312, lng: 18.7248873 },
    { lat: 48.7879919, lng: 18.725179 },
    { lat: 48.7881891, lng: 18.7253451 },
    { lat: 48.78879, lng: 18.7264275 },
    { lat: 48.7889271, lng: 18.7264868 },
    { lat: 48.7890553, lng: 18.7266497 },
    { lat: 48.789259, lng: 18.7268107 },
    { lat: 48.7894059, lng: 18.7269646 },
    { lat: 48.7894637, lng: 18.727057 },
    { lat: 48.7895514, lng: 18.7270929 },
    { lat: 48.7895948, lng: 18.7275533 },
    { lat: 48.7897218, lng: 18.7276077 },
    { lat: 48.7897884, lng: 18.727737 },
    { lat: 48.7899822, lng: 18.7279485 },
    { lat: 48.7903329, lng: 18.7281404 },
    { lat: 48.790577, lng: 18.7285794 },
    { lat: 48.7908213, lng: 18.7287154 },
    { lat: 48.790874, lng: 18.7288818 },
    { lat: 48.7909779, lng: 18.7289926 },
    { lat: 48.7916387, lng: 18.7293783 },
    { lat: 48.792028, lng: 18.7296564 },
    { lat: 48.7922245, lng: 18.72979 },
    { lat: 48.7924393, lng: 18.7299732 },
    { lat: 48.7925907, lng: 18.7301495 },
    { lat: 48.7929476, lng: 18.7304372 },
    { lat: 48.7936241, lng: 18.7309026 },
    { lat: 48.7938468, lng: 18.7310656 },
    { lat: 48.793963, lng: 18.7311707 },
    { lat: 48.7945477, lng: 18.7317588 },
    { lat: 48.7948494, lng: 18.7320253 },
    { lat: 48.7952064, lng: 18.7324454 },
    { lat: 48.7955845, lng: 18.7329648 },
    { lat: 48.7958521, lng: 18.7333746 },
    { lat: 48.7960174, lng: 18.7336661 },
    { lat: 48.7961515, lng: 18.733857 },
    { lat: 48.7961289, lng: 18.7339388 },
    { lat: 48.7973468, lng: 18.7351788 },
    { lat: 48.7980025, lng: 18.7358096 },
    { lat: 48.7990766, lng: 18.7368456 },
    { lat: 48.7995268, lng: 18.7372762 },
    { lat: 48.8003033, lng: 18.7381218 },
    { lat: 48.8006197, lng: 18.738476 },
    { lat: 48.8019242, lng: 18.739925 },
    { lat: 48.8021633, lng: 18.740031 },
    { lat: 48.8023878, lng: 18.7400987 },
    { lat: 48.8028744, lng: 18.7403233 },
    { lat: 48.8032551, lng: 18.740508 },
    { lat: 48.803737, lng: 18.7408369 },
    { lat: 48.804114, lng: 18.7412686 },
    { lat: 48.8044129, lng: 18.7415277 },
    { lat: 48.8045879, lng: 18.7416608 },
    { lat: 48.8050308, lng: 18.7415676 },
    { lat: 48.8050921, lng: 18.74207 },
    { lat: 48.8054209, lng: 18.7423816 },
    { lat: 48.8063297, lng: 18.7432779 },
    { lat: 48.8070944, lng: 18.7444128 },
    { lat: 48.8081394, lng: 18.7455188 },
    { lat: 48.8088231, lng: 18.7466585 },
    { lat: 48.809833, lng: 18.7478068 },
    { lat: 48.8100383, lng: 18.7480028 },
    { lat: 48.8101793, lng: 18.7480699 },
    { lat: 48.810323, lng: 18.7482046 },
    { lat: 48.8106697, lng: 18.7486844 },
    { lat: 48.8110525, lng: 18.7492322 },
    { lat: 48.8112888, lng: 18.7493948 },
    { lat: 48.8122212, lng: 18.7497961 },
    { lat: 48.8136696, lng: 18.7500809 },
    { lat: 48.8141796, lng: 18.7501539 },
    { lat: 48.8148785, lng: 18.7509835 },
    { lat: 48.8151469, lng: 18.7513047 },
    { lat: 48.8165022, lng: 18.7526503 },
    { lat: 48.8171394, lng: 18.7534892 },
    { lat: 48.817567, lng: 18.754071 },
    { lat: 48.8178919, lng: 18.7536351 },
    { lat: 48.818129, lng: 18.753317 },
  ],
  Malinová: [
    { lat: 48.8760199, lng: 18.5761702 },
    { lat: 48.8760156, lng: 18.5761073 },
    { lat: 48.8758591, lng: 18.5759669 },
    { lat: 48.8757255, lng: 18.5759398 },
    { lat: 48.8756415, lng: 18.5758864 },
    { lat: 48.8755352, lng: 18.5757377 },
    { lat: 48.875291, lng: 18.5755193 },
    { lat: 48.8750914, lng: 18.5752052 },
    { lat: 48.8747428, lng: 18.5748479 },
    { lat: 48.874546, lng: 18.5743163 },
    { lat: 48.8744459, lng: 18.5739252 },
    { lat: 48.8743656, lng: 18.5735676 },
    { lat: 48.8743039, lng: 18.5730951 },
    { lat: 48.8742447, lng: 18.5728122 },
    { lat: 48.874181, lng: 18.5725711 },
    { lat: 48.8741639, lng: 18.5724238 },
    { lat: 48.8740843, lng: 18.5721191 },
    { lat: 48.8739936, lng: 18.5715883 },
    { lat: 48.87385, lng: 18.5711228 },
    { lat: 48.8737249, lng: 18.5708538 },
    { lat: 48.8736508, lng: 18.5706327 },
    { lat: 48.8734391, lng: 18.5700975 },
    { lat: 48.8733224, lng: 18.5699404 },
    { lat: 48.8732192, lng: 18.5697418 },
    { lat: 48.8730569, lng: 18.5695374 },
    { lat: 48.8728224, lng: 18.5692666 },
    { lat: 48.872386, lng: 18.5687053 },
    { lat: 48.8720758, lng: 18.5681456 },
    { lat: 48.8719703, lng: 18.5678201 },
    { lat: 48.8718757, lng: 18.5676359 },
    { lat: 48.8716274, lng: 18.5672139 },
    { lat: 48.8715402, lng: 18.5668747 },
    { lat: 48.871575, lng: 18.5661419 },
    { lat: 48.8717241, lng: 18.565482 },
    { lat: 48.8717624, lng: 18.5650634 },
    { lat: 48.8718246, lng: 18.564879 },
    { lat: 48.871877, lng: 18.5645547 },
    { lat: 48.8718825, lng: 18.5644043 },
    { lat: 48.8718934, lng: 18.5643341 },
    { lat: 48.8718762, lng: 18.5642872 },
    { lat: 48.8718899, lng: 18.5641486 },
    { lat: 48.8718629, lng: 18.5640029 },
    { lat: 48.8719446, lng: 18.5638885 },
    { lat: 48.8719418, lng: 18.5637931 },
    { lat: 48.8719279, lng: 18.5637243 },
    { lat: 48.8719827, lng: 18.5634878 },
    { lat: 48.8720225, lng: 18.5629515 },
    { lat: 48.8720785, lng: 18.5627335 },
    { lat: 48.8721785, lng: 18.5622061 },
    { lat: 48.8723053, lng: 18.5618202 },
    { lat: 48.8724316, lng: 18.5613634 },
    { lat: 48.8724725, lng: 18.5613071 },
    { lat: 48.8725518, lng: 18.5608941 },
    { lat: 48.8725511, lng: 18.5599053 },
    { lat: 48.8725076, lng: 18.5596828 },
    { lat: 48.8725102, lng: 18.5594206 },
    { lat: 48.8725081, lng: 18.5582782 },
    { lat: 48.8726017, lng: 18.557695 },
    { lat: 48.8727107, lng: 18.5573117 },
    { lat: 48.8727986, lng: 18.5570625 },
    { lat: 48.8728253, lng: 18.5567012 },
    { lat: 48.872802, lng: 18.5563752 },
    { lat: 48.8727314, lng: 18.5559049 },
    { lat: 48.8726488, lng: 18.5558167 },
    { lat: 48.8726092, lng: 18.5556095 },
    { lat: 48.8725844, lng: 18.5555667 },
    { lat: 48.8725838, lng: 18.5553898 },
    { lat: 48.872542, lng: 18.5553029 },
    { lat: 48.8724567, lng: 18.555268 },
    { lat: 48.8723935, lng: 18.555062 },
    { lat: 48.8723343, lng: 18.5547595 },
    { lat: 48.8722628, lng: 18.554753 },
    { lat: 48.8720512, lng: 18.5541584 },
    { lat: 48.8719071, lng: 18.5539334 },
    { lat: 48.8716436, lng: 18.5532977 },
    { lat: 48.8716247, lng: 18.5533241 },
    { lat: 48.8709628, lng: 18.5517065 },
    { lat: 48.8696265, lng: 18.5499054 },
    { lat: 48.8693448, lng: 18.549536 },
    { lat: 48.8685858, lng: 18.5488511 },
    { lat: 48.8679633, lng: 18.5485218 },
    { lat: 48.8678979, lng: 18.5484794 },
    { lat: 48.866719, lng: 18.5476671 },
    { lat: 48.8659887, lng: 18.5468235 },
    { lat: 48.8655613, lng: 18.5465175 },
    { lat: 48.864394, lng: 18.5452932 },
    { lat: 48.8625795, lng: 18.546838 },
    { lat: 48.8618242, lng: 18.5480722 },
    { lat: 48.8617923, lng: 18.5481059 },
    { lat: 48.8612659, lng: 18.5489206 },
    { lat: 48.8610972, lng: 18.549096 },
    { lat: 48.8608583, lng: 18.5493096 },
    { lat: 48.8604707, lng: 18.549546 },
    { lat: 48.8602093, lng: 18.5499465 },
    { lat: 48.8600194, lng: 18.5502919 },
    { lat: 48.8598433, lng: 18.5507237 },
    { lat: 48.8596818, lng: 18.5512188 },
    { lat: 48.8595998, lng: 18.5515801 },
    { lat: 48.8595254, lng: 18.5518691 },
    { lat: 48.8594368, lng: 18.5522355 },
    { lat: 48.8593658, lng: 18.5525463 },
    { lat: 48.8592952, lng: 18.5528886 },
    { lat: 48.8592733, lng: 18.5529641 },
    { lat: 48.8591533, lng: 18.5535787 },
    { lat: 48.8591107, lng: 18.5538523 },
    { lat: 48.8589774, lng: 18.5543045 },
    { lat: 48.8588557, lng: 18.555452 },
    { lat: 48.8586657, lng: 18.5557755 },
    { lat: 48.8583941, lng: 18.5561535 },
    { lat: 48.8582487, lng: 18.5565095 },
    { lat: 48.8581572, lng: 18.5566496 },
    { lat: 48.8578635, lng: 18.5570994 },
    { lat: 48.8575674, lng: 18.5577549 },
    { lat: 48.8574605, lng: 18.5583019 },
    { lat: 48.8573127, lng: 18.5588018 },
    { lat: 48.8571854, lng: 18.5589269 },
    { lat: 48.8571104, lng: 18.5592299 },
    { lat: 48.8568543, lng: 18.5599782 },
    { lat: 48.8567491, lng: 18.56041 },
    { lat: 48.8566403, lng: 18.5608563 },
    { lat: 48.8567015, lng: 18.5615059 },
    { lat: 48.8565861, lng: 18.5620637 },
    { lat: 48.8564512, lng: 18.5625475 },
    { lat: 48.8564173, lng: 18.5627573 },
    { lat: 48.8563464, lng: 18.563196 },
    { lat: 48.8560851, lng: 18.5641011 },
    { lat: 48.8558912, lng: 18.5648731 },
    { lat: 48.8558341, lng: 18.5653594 },
    { lat: 48.8558203, lng: 18.5657498 },
    { lat: 48.8558136, lng: 18.5661883 },
    { lat: 48.8557841, lng: 18.5666222 },
    { lat: 48.8556901, lng: 18.5676582 },
    { lat: 48.8555497, lng: 18.5685447 },
    { lat: 48.855364, lng: 18.5693618 },
    { lat: 48.8550274, lng: 18.5703965 },
    { lat: 48.8549223, lng: 18.5707857 },
    { lat: 48.854546, lng: 18.5721527 },
    { lat: 48.8544958, lng: 18.5724609 },
    { lat: 48.8544411, lng: 18.5727965 },
    { lat: 48.8543874, lng: 18.5732448 },
    { lat: 48.8542593, lng: 18.5738574 },
    { lat: 48.8542638, lng: 18.5745649 },
    { lat: 48.8542282, lng: 18.5750557 },
    { lat: 48.8542542, lng: 18.5754318 },
    { lat: 48.8542816, lng: 18.5756232 },
    { lat: 48.8542793, lng: 18.5758309 },
    { lat: 48.8543117, lng: 18.5760765 },
    { lat: 48.8542938, lng: 18.5765186 },
    { lat: 48.8542519, lng: 18.5768884 },
    { lat: 48.854241, lng: 18.5773561 },
    { lat: 48.8540535, lng: 18.5780635 },
    { lat: 48.8540148, lng: 18.5781469 },
    { lat: 48.8537969, lng: 18.5786164 },
    { lat: 48.8535945, lng: 18.5791265 },
    { lat: 48.8534231, lng: 18.5796893 },
    { lat: 48.8532347, lng: 18.5801475 },
    { lat: 48.8530499, lng: 18.5804684 },
    { lat: 48.852956, lng: 18.5808164 },
    { lat: 48.8527407, lng: 18.5812145 },
    { lat: 48.8526188, lng: 18.5813706 },
    { lat: 48.852553, lng: 18.5814549 },
    { lat: 48.8524506, lng: 18.5817122 },
    { lat: 48.8522282, lng: 18.5823776 },
    { lat: 48.8522386, lng: 18.5824201 },
    { lat: 48.8522215, lng: 18.5824415 },
    { lat: 48.8522115, lng: 18.5824843 },
    { lat: 48.8522586, lng: 18.5826457 },
    { lat: 48.8521777, lng: 18.5829036 },
    { lat: 48.8520997, lng: 18.582856 },
    { lat: 48.8520374, lng: 18.5830406 },
    { lat: 48.8519429, lng: 18.5832121 },
    { lat: 48.8519056, lng: 18.5834398 },
    { lat: 48.8519449, lng: 18.5836029 },
    { lat: 48.8519459, lng: 18.583683 },
    { lat: 48.8518938, lng: 18.5837428 },
    { lat: 48.8518916, lng: 18.5838294 },
    { lat: 48.8518978, lng: 18.5838387 },
    { lat: 48.8518619, lng: 18.5842382 },
    { lat: 48.8517154, lng: 18.5860159 },
    { lat: 48.8517119, lng: 18.5860769 },
    { lat: 48.8516306, lng: 18.588084 },
    { lat: 48.8515573, lng: 18.5891073 },
    { lat: 48.851197, lng: 18.590144 },
    { lat: 48.8506212, lng: 18.5913657 },
    { lat: 48.8503725, lng: 18.5912071 },
    { lat: 48.8491889, lng: 18.5933112 },
    { lat: 48.848007, lng: 18.595386 },
    { lat: 48.8467056, lng: 18.5973974 },
    { lat: 48.8451115, lng: 18.5986661 },
    { lat: 48.8438277, lng: 18.5995217 },
    { lat: 48.8428501, lng: 18.6002793 },
    { lat: 48.8422623, lng: 18.6009034 },
    { lat: 48.841338, lng: 18.6019208 },
    { lat: 48.8413029, lng: 18.6020862 },
    { lat: 48.8411764, lng: 18.6022476 },
    { lat: 48.8411611, lng: 18.6022792 },
    { lat: 48.840669, lng: 18.6028399 },
    { lat: 48.8404911, lng: 18.603119 },
    { lat: 48.8400902, lng: 18.6036755 },
    { lat: 48.8397117, lng: 18.6042691 },
    { lat: 48.8394187, lng: 18.6047976 },
    { lat: 48.8392096, lng: 18.6050633 },
    { lat: 48.8389566, lng: 18.6054727 },
    { lat: 48.8387483, lng: 18.605859 },
    { lat: 48.8385215, lng: 18.6060846 },
    { lat: 48.8384938, lng: 18.6060745 },
    { lat: 48.8383175, lng: 18.6059645 },
    { lat: 48.838099, lng: 18.6068271 },
    { lat: 48.8379192, lng: 18.6079084 },
    { lat: 48.8379092, lng: 18.6081223 },
    { lat: 48.8377896, lng: 18.6085174 },
    { lat: 48.8381713, lng: 18.6095773 },
    { lat: 48.8382808, lng: 18.6100185 },
    { lat: 48.8385827, lng: 18.6108716 },
    { lat: 48.8386854, lng: 18.6118361 },
    { lat: 48.838773, lng: 18.6124594 },
    { lat: 48.8388211, lng: 18.6128315 },
    { lat: 48.839042, lng: 18.6132253 },
    { lat: 48.8389162, lng: 18.6132974 },
    { lat: 48.8389498, lng: 18.6135665 },
    { lat: 48.8391796, lng: 18.6139602 },
    { lat: 48.8392524, lng: 18.6142849 },
    { lat: 48.8393271, lng: 18.614647 },
    { lat: 48.8395374, lng: 18.6151603 },
    { lat: 48.8398548, lng: 18.6160077 },
    { lat: 48.8401011, lng: 18.6172312 },
    { lat: 48.8404517, lng: 18.6183638 },
    { lat: 48.8407601, lng: 18.6196141 },
    { lat: 48.840912, lng: 18.6203211 },
    { lat: 48.8410743, lng: 18.6209667 },
    { lat: 48.8415391, lng: 18.6216642 },
    { lat: 48.8416232, lng: 18.6217838 },
    { lat: 48.8417407, lng: 18.6220171 },
    { lat: 48.8420112, lng: 18.6226219 },
    { lat: 48.8422631, lng: 18.6233843 },
    { lat: 48.842559, lng: 18.6241062 },
    { lat: 48.8428122, lng: 18.6244557 },
    { lat: 48.8429654, lng: 18.6245969 },
    { lat: 48.8434907, lng: 18.624993 },
    { lat: 48.8436932, lng: 18.625088 },
    { lat: 48.8438129, lng: 18.625219 },
    { lat: 48.8439051, lng: 18.6252327 },
    { lat: 48.8446292, lng: 18.6250848 },
    { lat: 48.8455429, lng: 18.6247509 },
    { lat: 48.8461606, lng: 18.6245774 },
    { lat: 48.8476329, lng: 18.6248394 },
    { lat: 48.8484672, lng: 18.6252716 },
    { lat: 48.8494919, lng: 18.6260418 },
    { lat: 48.8498647, lng: 18.6261355 },
    { lat: 48.8502151, lng: 18.626174 },
    { lat: 48.8504225, lng: 18.6261492 },
    { lat: 48.8506391, lng: 18.6259613 },
    { lat: 48.8511301, lng: 18.6255277 },
    { lat: 48.851336, lng: 18.6252022 },
    { lat: 48.851609, lng: 18.6248459 },
    { lat: 48.851768, lng: 18.624572 },
    { lat: 48.8520151, lng: 18.624302 },
    { lat: 48.8523123, lng: 18.6239334 },
    { lat: 48.8528194, lng: 18.6234207 },
    { lat: 48.8529743, lng: 18.6231943 },
    { lat: 48.8530765, lng: 18.6229356 },
    { lat: 48.8531053, lng: 18.6229668 },
    { lat: 48.8536476, lng: 18.6239515 },
    { lat: 48.8538826, lng: 18.6244021 },
    { lat: 48.8540853, lng: 18.6248363 },
    { lat: 48.8542371, lng: 18.6252298 },
    { lat: 48.8547128, lng: 18.626529 },
    { lat: 48.8550661, lng: 18.6274571 },
    { lat: 48.8552756, lng: 18.6272619 },
    { lat: 48.8555077, lng: 18.6269844 },
    { lat: 48.8557898, lng: 18.6267584 },
    { lat: 48.8559456, lng: 18.6266138 },
    { lat: 48.8560937, lng: 18.6264491 },
    { lat: 48.8563285, lng: 18.6262648 },
    { lat: 48.8565946, lng: 18.6259913 },
    { lat: 48.8568549, lng: 18.6254832 },
    { lat: 48.8568831, lng: 18.6255238 },
    { lat: 48.857067, lng: 18.6252282 },
    { lat: 48.8572472, lng: 18.6250377 },
    { lat: 48.857547, lng: 18.6244441 },
    { lat: 48.857672, lng: 18.6242281 },
    { lat: 48.8577352, lng: 18.6242737 },
    { lat: 48.8580795, lng: 18.6235425 },
    { lat: 48.8592113, lng: 18.6254046 },
    { lat: 48.8599398, lng: 18.6247722 },
    { lat: 48.8603203, lng: 18.62438 },
    { lat: 48.86042, lng: 18.6244716 },
    { lat: 48.8605481, lng: 18.6243784 },
    { lat: 48.8610981, lng: 18.6242211 },
    { lat: 48.8611698, lng: 18.6242362 },
    { lat: 48.8611994, lng: 18.6241574 },
    { lat: 48.8612253, lng: 18.624156 },
    { lat: 48.8612305, lng: 18.6240643 },
    { lat: 48.8618175, lng: 18.6224102 },
    { lat: 48.8626742, lng: 18.6208058 },
    { lat: 48.863033, lng: 18.6201154 },
    { lat: 48.863521, lng: 18.6190582 },
    { lat: 48.8637386, lng: 18.6185947 },
    { lat: 48.8645229, lng: 18.6159772 },
    { lat: 48.8649166, lng: 18.6147185 },
    { lat: 48.8651484, lng: 18.6139956 },
    { lat: 48.8657693, lng: 18.6126196 },
    { lat: 48.8658817, lng: 18.6123632 },
    { lat: 48.8660211, lng: 18.6120139 },
    { lat: 48.8662158, lng: 18.6115034 },
    { lat: 48.8663604, lng: 18.6111739 },
    { lat: 48.866512, lng: 18.6108066 },
    { lat: 48.8666768, lng: 18.6104298 },
    { lat: 48.8672221, lng: 18.6090982 },
    { lat: 48.8672964, lng: 18.6086945 },
    { lat: 48.867535, lng: 18.6082878 },
    { lat: 48.8675224, lng: 18.6078437 },
    { lat: 48.8672725, lng: 18.6076005 },
    { lat: 48.8671451, lng: 18.6072433 },
    { lat: 48.8671299, lng: 18.6069826 },
    { lat: 48.8671851, lng: 18.6067523 },
    { lat: 48.8673428, lng: 18.6059726 },
    { lat: 48.867476, lng: 18.6053238 },
    { lat: 48.8674209, lng: 18.6051386 },
    { lat: 48.8669507, lng: 18.6040747 },
    { lat: 48.8668927, lng: 18.6038744 },
    { lat: 48.8665084, lng: 18.6032732 },
    { lat: 48.8664047, lng: 18.6031459 },
    { lat: 48.8663622, lng: 18.6030724 },
    { lat: 48.8667051, lng: 18.6023779 },
    { lat: 48.8668062, lng: 18.6020566 },
    { lat: 48.8667689, lng: 18.6017417 },
    { lat: 48.8668378, lng: 18.601577 },
    { lat: 48.8668458, lng: 18.601476 },
    { lat: 48.8669425, lng: 18.6011341 },
    { lat: 48.8672297, lng: 18.6006422 },
    { lat: 48.8674067, lng: 18.6003258 },
    { lat: 48.8675595, lng: 18.5999422 },
    { lat: 48.8676566, lng: 18.5997525 },
    { lat: 48.8678679, lng: 18.5992176 },
    { lat: 48.8679064, lng: 18.5990111 },
    { lat: 48.8683065, lng: 18.5985439 },
    { lat: 48.8684856, lng: 18.5985787 },
    { lat: 48.8687828, lng: 18.5978386 },
    { lat: 48.8689893, lng: 18.5975655 },
    { lat: 48.8691319, lng: 18.5971376 },
    { lat: 48.8691602, lng: 18.5968461 },
    { lat: 48.8691429, lng: 18.5967762 },
    { lat: 48.8691319, lng: 18.5967597 },
    { lat: 48.8691285, lng: 18.5967357 },
    { lat: 48.8692824, lng: 18.596602 },
    { lat: 48.8693047, lng: 18.5964939 },
    { lat: 48.8694288, lng: 18.5962611 },
    { lat: 48.8694622, lng: 18.59621 },
    { lat: 48.8696247, lng: 18.5959187 },
    { lat: 48.8697388, lng: 18.5956094 },
    { lat: 48.8697768, lng: 18.5952897 },
    { lat: 48.8698802, lng: 18.5950931 },
    { lat: 48.8699938, lng: 18.5949827 },
    { lat: 48.8701055, lng: 18.594986 },
    { lat: 48.8702077, lng: 18.5950168 },
    { lat: 48.8703216, lng: 18.5949958 },
    { lat: 48.8704615, lng: 18.5948277 },
    { lat: 48.8707791, lng: 18.5946121 },
    { lat: 48.8708267, lng: 18.5946359 },
    { lat: 48.8709337, lng: 18.5945306 },
    { lat: 48.87112, lng: 18.5944695 },
    { lat: 48.8712144, lng: 18.5945273 },
    { lat: 48.8713604, lng: 18.5944311 },
    { lat: 48.8714239, lng: 18.594376 },
    { lat: 48.8715137, lng: 18.594323 },
    { lat: 48.8716354, lng: 18.5943233 },
    { lat: 48.8716967, lng: 18.5942057 },
    { lat: 48.8717782, lng: 18.5941272 },
    { lat: 48.8718544, lng: 18.5939927 },
    { lat: 48.871936, lng: 18.5939364 },
    { lat: 48.8720875, lng: 18.5936295 },
    { lat: 48.8720973, lng: 18.593561 },
    { lat: 48.8721676, lng: 18.593463 },
    { lat: 48.8722388, lng: 18.5934463 },
    { lat: 48.87258, lng: 18.5929927 },
    { lat: 48.8726905, lng: 18.5928243 },
    { lat: 48.8726905, lng: 18.5927408 },
    { lat: 48.8729915, lng: 18.592572 },
    { lat: 48.8731117, lng: 18.5922895 },
    { lat: 48.873149, lng: 18.5921662 },
    { lat: 48.8732868, lng: 18.5918776 },
    { lat: 48.8732879, lng: 18.5917922 },
    { lat: 48.8733901, lng: 18.5916412 },
    { lat: 48.8734712, lng: 18.5914925 },
    { lat: 48.8735088, lng: 18.5909321 },
    { lat: 48.8735898, lng: 18.5905921 },
    { lat: 48.8735358, lng: 18.590511 },
    { lat: 48.8735358, lng: 18.5903888 },
    { lat: 48.8736512, lng: 18.5899961 },
    { lat: 48.8737334, lng: 18.5899505 },
    { lat: 48.8737711, lng: 18.5898559 },
    { lat: 48.8737765, lng: 18.5896624 },
    { lat: 48.8738196, lng: 18.5894582 },
    { lat: 48.8738398, lng: 18.5892683 },
    { lat: 48.8739268, lng: 18.5889397 },
    { lat: 48.8739233, lng: 18.5887252 },
    { lat: 48.8740201, lng: 18.5885365 },
    { lat: 48.8737556, lng: 18.5883389 },
    { lat: 48.8737696, lng: 18.5881555 },
    { lat: 48.8737213, lng: 18.5878903 },
    { lat: 48.8737968, lng: 18.5875509 },
    { lat: 48.8737762, lng: 18.5875281 },
    { lat: 48.8738125, lng: 18.5873638 },
    { lat: 48.8739875, lng: 18.5871156 },
    { lat: 48.8740111, lng: 18.586988 },
    { lat: 48.8740247, lng: 18.5867373 },
    { lat: 48.8740499, lng: 18.5865779 },
    { lat: 48.8741379, lng: 18.5864344 },
    { lat: 48.8736751, lng: 18.5860483 },
    { lat: 48.8736274, lng: 18.5860226 },
    { lat: 48.8738201, lng: 18.5855989 },
    { lat: 48.8738834, lng: 18.5855642 },
    { lat: 48.8740248, lng: 18.585193 },
    { lat: 48.8740388, lng: 18.5850759 },
    { lat: 48.8740914, lng: 18.5849643 },
    { lat: 48.8743051, lng: 18.5844534 },
    { lat: 48.8743339, lng: 18.5843165 },
    { lat: 48.8743867, lng: 18.5841247 },
    { lat: 48.8744731, lng: 18.5840316 },
    { lat: 48.8745389, lng: 18.5838536 },
    { lat: 48.8745487, lng: 18.5837818 },
    { lat: 48.8746018, lng: 18.5836577 },
    { lat: 48.8746208, lng: 18.583488 },
    { lat: 48.8746657, lng: 18.5834004 },
    { lat: 48.8746937, lng: 18.5832088 },
    { lat: 48.8747087, lng: 18.5831944 },
    { lat: 48.8747328, lng: 18.5832022 },
    { lat: 48.875142, lng: 18.5834058 },
    { lat: 48.8752025, lng: 18.5832969 },
    { lat: 48.8751942, lng: 18.5832594 },
    { lat: 48.8751863, lng: 18.583224 },
    { lat: 48.8751687, lng: 18.5831907 },
    { lat: 48.8751216, lng: 18.5831335 },
    { lat: 48.8750878, lng: 18.5831144 },
    { lat: 48.8750619, lng: 18.5830126 },
    { lat: 48.875019, lng: 18.5824898 },
    { lat: 48.8750176, lng: 18.5822914 },
    { lat: 48.8750012, lng: 18.5822426 },
    { lat: 48.8750315, lng: 18.5817132 },
    { lat: 48.8749976, lng: 18.5814003 },
    { lat: 48.8749796, lng: 18.581318 },
    { lat: 48.8750159, lng: 18.5811364 },
    { lat: 48.8750337, lng: 18.5809837 },
    { lat: 48.8749784, lng: 18.5808175 },
    { lat: 48.8751304, lng: 18.5804762 },
    { lat: 48.8752559, lng: 18.5802761 },
    { lat: 48.8753624, lng: 18.5800106 },
    { lat: 48.8753527, lng: 18.5799143 },
    { lat: 48.8754195, lng: 18.5796466 },
    { lat: 48.8755228, lng: 18.5792662 },
    { lat: 48.8755544, lng: 18.5788663 },
    { lat: 48.8755509, lng: 18.5786564 },
    { lat: 48.8755158, lng: 18.5784884 },
    { lat: 48.8755334, lng: 18.5783329 },
    { lat: 48.8755663, lng: 18.5781292 },
    { lat: 48.8758235, lng: 18.5774992 },
    { lat: 48.8758718, lng: 18.5774137 },
    { lat: 48.8759183, lng: 18.5771273 },
    { lat: 48.8759684, lng: 18.5769702 },
    { lat: 48.8760232, lng: 18.5766907 },
    { lat: 48.8760381, lng: 18.57659 },
    { lat: 48.8760207, lng: 18.5764779 },
    { lat: 48.8760201, lng: 18.5762525 },
    { lat: 48.8760199, lng: 18.5761702 },
  ],
  MaláČausa: [
    { lat: 48.832006, lng: 18.729141 },
    { lat: 48.8318182, lng: 18.7288282 },
    { lat: 48.8313848, lng: 18.7286087 },
    { lat: 48.8314073, lng: 18.7283325 },
    { lat: 48.8307405, lng: 18.7281956 },
    { lat: 48.8301679, lng: 18.7281181 },
    { lat: 48.8300215, lng: 18.7277575 },
    { lat: 48.8299944, lng: 18.7275632 },
    { lat: 48.8298562, lng: 18.7268702 },
    { lat: 48.8295268, lng: 18.7267633 },
    { lat: 48.8291153, lng: 18.7269989 },
    { lat: 48.8290324, lng: 18.7270941 },
    { lat: 48.8288238, lng: 18.7273495 },
    { lat: 48.8284158, lng: 18.7268642 },
    { lat: 48.8283531, lng: 18.7267859 },
    { lat: 48.828307, lng: 18.7264915 },
    { lat: 48.8280818, lng: 18.7261158 },
    { lat: 48.8279155, lng: 18.7261252 },
    { lat: 48.8277476, lng: 18.7261892 },
    { lat: 48.8275916, lng: 18.7260144 },
    { lat: 48.8272387, lng: 18.7255035 },
    { lat: 48.8272498, lng: 18.7254126 },
    { lat: 48.8275173, lng: 18.7254808 },
    { lat: 48.8275472, lng: 18.7252138 },
    { lat: 48.8272436, lng: 18.7246109 },
    { lat: 48.8273166, lng: 18.7241142 },
    { lat: 48.8271034, lng: 18.7237109 },
    { lat: 48.8271167, lng: 18.7228091 },
    { lat: 48.8269454, lng: 18.7224058 },
    { lat: 48.8269215, lng: 18.7222089 },
    { lat: 48.826714, lng: 18.7219316 },
    { lat: 48.8264798, lng: 18.7217424 },
    { lat: 48.8263289, lng: 18.7217049 },
    { lat: 48.8259862, lng: 18.7215392 },
    { lat: 48.8262513, lng: 18.7210056 },
    { lat: 48.8265854, lng: 18.7209286 },
    { lat: 48.8267293, lng: 18.7207891 },
    { lat: 48.8267094, lng: 18.7206895 },
    { lat: 48.8257096, lng: 18.7202302 },
    { lat: 48.8256831, lng: 18.7206461 },
    { lat: 48.8251362, lng: 18.7200509 },
    { lat: 48.8247309, lng: 18.7192547 },
    { lat: 48.8242839, lng: 18.7185637 },
    { lat: 48.8240293, lng: 18.7182883 },
    { lat: 48.8240089, lng: 18.7173079 },
    { lat: 48.8240223, lng: 18.7165383 },
    { lat: 48.8232287, lng: 18.7155826 },
    { lat: 48.8223976, lng: 18.7121744 },
    { lat: 48.8225105, lng: 18.7100248 },
    { lat: 48.8225118, lng: 18.7099834 },
    { lat: 48.8227344, lng: 18.709674 },
    { lat: 48.8228205, lng: 18.7092975 },
    { lat: 48.8227187, lng: 18.7090906 },
    { lat: 48.8227093, lng: 18.7090715 },
    { lat: 48.8226754, lng: 18.7087521 },
    { lat: 48.8227958, lng: 18.7081821 },
    { lat: 48.8228016, lng: 18.7081548 },
    { lat: 48.8228724, lng: 18.7076792 },
    { lat: 48.823047, lng: 18.707206 },
    { lat: 48.8233227, lng: 18.7066417 },
    { lat: 48.8234282, lng: 18.7063132 },
    { lat: 48.8233402, lng: 18.7058654 },
    { lat: 48.8230253, lng: 18.705199 },
    { lat: 48.8225864, lng: 18.7043859 },
    { lat: 48.8223374, lng: 18.7040318 },
    { lat: 48.8220617, lng: 18.7033817 },
    { lat: 48.8218409, lng: 18.7026634 },
    { lat: 48.8215617, lng: 18.7012923 },
    { lat: 48.8213722, lng: 18.7007574 },
    { lat: 48.8213139, lng: 18.7001493 },
    { lat: 48.8212968, lng: 18.6995061 },
    { lat: 48.8213344, lng: 18.6984268 },
    { lat: 48.8213232, lng: 18.6981762 },
    { lat: 48.8212885, lng: 18.6979667 },
    { lat: 48.8209804, lng: 18.6972201 },
    { lat: 48.8208803, lng: 18.6968207 },
    { lat: 48.8207494, lng: 18.6964344 },
    { lat: 48.8207512, lng: 18.6963432 },
    { lat: 48.8206361, lng: 18.6960233 },
    { lat: 48.8206232, lng: 18.695886 },
    { lat: 48.8205339, lng: 18.6956063 },
    { lat: 48.8204518, lng: 18.6954617 },
    { lat: 48.8202723, lng: 18.6948186 },
    { lat: 48.8202154, lng: 18.6947031 },
    { lat: 48.8202283, lng: 18.6944167 },
    { lat: 48.8202068, lng: 18.6941176 },
    { lat: 48.8199779, lng: 18.6937146 },
    { lat: 48.8199175, lng: 18.6934274 },
    { lat: 48.8195982, lng: 18.6926385 },
    { lat: 48.8195232, lng: 18.6923925 },
    { lat: 48.8193217, lng: 18.6913405 },
    { lat: 48.8191506, lng: 18.6908539 },
    { lat: 48.8190949, lng: 18.6905314 },
    { lat: 48.8189091, lng: 18.6901744 },
    { lat: 48.8186755, lng: 18.6899802 },
    { lat: 48.8184749, lng: 18.6895777 },
    { lat: 48.8183438, lng: 18.6890211 },
    { lat: 48.8180648, lng: 18.6886546 },
    { lat: 48.8179219, lng: 18.688278 },
    { lat: 48.8176309, lng: 18.6880003 },
    { lat: 48.8174635, lng: 18.6874614 },
    { lat: 48.8173532, lng: 18.6869116 },
    { lat: 48.8171623, lng: 18.6859954 },
    { lat: 48.8172085, lng: 18.6855886 },
    { lat: 48.8171911, lng: 18.6854748 },
    { lat: 48.8171317, lng: 18.6853128 },
    { lat: 48.8165474, lng: 18.6844851 },
    { lat: 48.8141336, lng: 18.6830148 },
    { lat: 48.8131327, lng: 18.6822412 },
    { lat: 48.8122772, lng: 18.6816982 },
    { lat: 48.8101189, lng: 18.6779514 },
    { lat: 48.8092635, lng: 18.676734 },
    { lat: 48.8082211, lng: 18.6754776 },
    { lat: 48.80708, lng: 18.6744477 },
    { lat: 48.8057986, lng: 18.6733637 },
    { lat: 48.8049868, lng: 18.6724549 },
    { lat: 48.8039606, lng: 18.6712751 },
    { lat: 48.8020986, lng: 18.6693681 },
    { lat: 48.8011189, lng: 18.6689727 },
    { lat: 48.7989487, lng: 18.6676783 },
    { lat: 48.7988818, lng: 18.6677355 },
    { lat: 48.7975185, lng: 18.6692386 },
    { lat: 48.7964182, lng: 18.6700732 },
    { lat: 48.795567, lng: 18.672025 },
    { lat: 48.7943181, lng: 18.6737113 },
    { lat: 48.7967599, lng: 18.6805753 },
    { lat: 48.7953596, lng: 18.6825965 },
    { lat: 48.7935152, lng: 18.6852819 },
    { lat: 48.7928053, lng: 18.6861392 },
    { lat: 48.7914266, lng: 18.6878466 },
    { lat: 48.79062, lng: 18.6887982 },
    { lat: 48.7900311, lng: 18.6894526 },
    { lat: 48.790002, lng: 18.6894779 },
    { lat: 48.7878793, lng: 18.6926798 },
    { lat: 48.7858331, lng: 18.694796 },
    { lat: 48.7855876, lng: 18.6950551 },
    { lat: 48.7850579, lng: 18.695783 },
    { lat: 48.7849925, lng: 18.6958699 },
    { lat: 48.7849358, lng: 18.6956122 },
    { lat: 48.784885, lng: 18.6954828 },
    { lat: 48.7846445, lng: 18.6954538 },
    { lat: 48.7845548, lng: 18.6952108 },
    { lat: 48.7845667, lng: 18.6950734 },
    { lat: 48.7845484, lng: 18.6949383 },
    { lat: 48.784576, lng: 18.6946124 },
    { lat: 48.7845651, lng: 18.6944689 },
    { lat: 48.7844937, lng: 18.6943756 },
    { lat: 48.784488, lng: 18.6942286 },
    { lat: 48.7844629, lng: 18.6941784 },
    { lat: 48.7844256, lng: 18.6942022 },
    { lat: 48.7842951, lng: 18.6943347 },
    { lat: 48.7841141, lng: 18.6943042 },
    { lat: 48.7840638, lng: 18.6944383 },
    { lat: 48.783636, lng: 18.695001 },
    { lat: 48.7834703, lng: 18.6952361 },
    { lat: 48.7831806, lng: 18.6957091 },
    { lat: 48.783047, lng: 18.6959746 },
    { lat: 48.7833371, lng: 18.6962361 },
    { lat: 48.7836283, lng: 18.6965344 },
    { lat: 48.7837786, lng: 18.6967124 },
    { lat: 48.7839099, lng: 18.6968939 },
    { lat: 48.7835238, lng: 18.6978195 },
    { lat: 48.7840361, lng: 18.6984832 },
    { lat: 48.7836979, lng: 18.6994623 },
    { lat: 48.7839545, lng: 18.6997283 },
    { lat: 48.7838358, lng: 18.7000821 },
    { lat: 48.7840011, lng: 18.7002222 },
    { lat: 48.7838762, lng: 18.7006351 },
    { lat: 48.7845441, lng: 18.7013403 },
    { lat: 48.7845476, lng: 18.7015531 },
    { lat: 48.7850145, lng: 18.7018933 },
    { lat: 48.7851204, lng: 18.7020189 },
    { lat: 48.785255, lng: 18.7022403 },
    { lat: 48.7853124, lng: 18.7024311 },
    { lat: 48.7853354, lng: 18.7026282 },
    { lat: 48.7853465, lng: 18.7028829 },
    { lat: 48.7853308, lng: 18.703009 },
    { lat: 48.7852922, lng: 18.7031953 },
    { lat: 48.7851246, lng: 18.7035472 },
    { lat: 48.7850198, lng: 18.7036958 },
    { lat: 48.7847153, lng: 18.7039699 },
    { lat: 48.784417, lng: 18.7044943 },
    { lat: 48.7844813, lng: 18.7046649 },
    { lat: 48.7845908, lng: 18.7048129 },
    { lat: 48.7847768, lng: 18.7051741 },
    { lat: 48.7849181, lng: 18.7053919 },
    { lat: 48.7851219, lng: 18.7056611 },
    { lat: 48.7852381, lng: 18.7057886 },
    { lat: 48.7858495, lng: 18.7061993 },
    { lat: 48.7860664, lng: 18.7063325 },
    { lat: 48.7867206, lng: 18.7069723 },
    { lat: 48.7869718, lng: 18.7073095 },
    { lat: 48.7870648, lng: 18.7074863 },
    { lat: 48.7874234, lng: 18.7078276 },
    { lat: 48.7877643, lng: 18.7081099 },
    { lat: 48.7880713, lng: 18.7083516 },
    { lat: 48.788551, lng: 18.7085698 },
    { lat: 48.7886654, lng: 18.7086407 },
    { lat: 48.7888165, lng: 18.7087715 },
    { lat: 48.7889638, lng: 18.7089958 },
    { lat: 48.7891796, lng: 18.7092786 },
    { lat: 48.7894552, lng: 18.7095416 },
    { lat: 48.7897925, lng: 18.7099714 },
    { lat: 48.7900149, lng: 18.7102309 },
    { lat: 48.7907638, lng: 18.711061 },
    { lat: 48.7910296, lng: 18.7112927 },
    { lat: 48.7912092, lng: 18.7115102 },
    { lat: 48.7918322, lng: 18.7119847 },
    { lat: 48.7934153, lng: 18.713193 },
    { lat: 48.7950963, lng: 18.7145297 },
    { lat: 48.7965217, lng: 18.7155903 },
    { lat: 48.7986816, lng: 18.7160857 },
    { lat: 48.8000349, lng: 18.7168342 },
    { lat: 48.8012533, lng: 18.717012 },
    { lat: 48.8022629, lng: 18.7171644 },
    { lat: 48.8028102, lng: 18.717449 },
    { lat: 48.8031923, lng: 18.7163151 },
    { lat: 48.8036536, lng: 18.7166144 },
    { lat: 48.8043486, lng: 18.7171094 },
    { lat: 48.8046791, lng: 18.7173258 },
    { lat: 48.8058638, lng: 18.7186587 },
    { lat: 48.8061184, lng: 18.7190793 },
    { lat: 48.8084659, lng: 18.7228461 },
    { lat: 48.8100753, lng: 18.7254108 },
    { lat: 48.8101032, lng: 18.7254683 },
    { lat: 48.8107255, lng: 18.7278564 },
    { lat: 48.8107379, lng: 18.7312955 },
    { lat: 48.8110976, lng: 18.7331956 },
    { lat: 48.8108033, lng: 18.7338391 },
    { lat: 48.8108278, lng: 18.734327 },
    { lat: 48.8108629, lng: 18.7343907 },
    { lat: 48.8112584, lng: 18.7350481 },
    { lat: 48.8116128, lng: 18.7355663 },
    { lat: 48.8122064, lng: 18.7366024 },
    { lat: 48.8123634, lng: 18.7375888 },
    { lat: 48.8122422, lng: 18.7378146 },
    { lat: 48.8121502, lng: 18.7382962 },
    { lat: 48.8123499, lng: 18.738705 },
    { lat: 48.8127408, lng: 18.7394862 },
    { lat: 48.8129061, lng: 18.7402502 },
    { lat: 48.8131349, lng: 18.7406955 },
    { lat: 48.8133904, lng: 18.7419077 },
    { lat: 48.8133732, lng: 18.7427163 },
    { lat: 48.8133503, lng: 18.7429009 },
    { lat: 48.813391, lng: 18.743525 },
    { lat: 48.8134365, lng: 18.7436175 },
    { lat: 48.8133911, lng: 18.7439765 },
    { lat: 48.8135904, lng: 18.7443779 },
    { lat: 48.8135444, lng: 18.7447696 },
    { lat: 48.8135906, lng: 18.7449833 },
    { lat: 48.8137676, lng: 18.7453878 },
    { lat: 48.8138156, lng: 18.745572 },
    { lat: 48.8138887, lng: 18.7455864 },
    { lat: 48.8139061, lng: 18.7457457 },
    { lat: 48.814033, lng: 18.7457826 },
    { lat: 48.8140601, lng: 18.7459518 },
    { lat: 48.8141712, lng: 18.7459423 },
    { lat: 48.8142706, lng: 18.7461164 },
    { lat: 48.8143781, lng: 18.7460573 },
    { lat: 48.8144678, lng: 18.7460923 },
    { lat: 48.8145576, lng: 18.7461906 },
    { lat: 48.8146913, lng: 18.7463111 },
    { lat: 48.8149524, lng: 18.7463243 },
    { lat: 48.8150171, lng: 18.7465022 },
    { lat: 48.8151418, lng: 18.7466861 },
    { lat: 48.8150634, lng: 18.7470355 },
    { lat: 48.8150916, lng: 18.7471459 },
    { lat: 48.8151653, lng: 18.7472161 },
    { lat: 48.8151887, lng: 18.7473607 },
    { lat: 48.8152907, lng: 18.7475839 },
    { lat: 48.8155243, lng: 18.7482105 },
    { lat: 48.8158418, lng: 18.7483289 },
    { lat: 48.8160576, lng: 18.748528 },
    { lat: 48.8162026, lng: 18.7487327 },
    { lat: 48.816283, lng: 18.7487606 },
    { lat: 48.816423, lng: 18.7488754 },
    { lat: 48.8165737, lng: 18.7491653 },
    { lat: 48.8167322, lng: 18.7492632 },
    { lat: 48.8168079, lng: 18.7493507 },
    { lat: 48.8168556, lng: 18.7495074 },
    { lat: 48.8169653, lng: 18.7495797 },
    { lat: 48.8170667, lng: 18.7497056 },
    { lat: 48.8171731, lng: 18.7497349 },
    { lat: 48.8173276, lng: 18.7498627 },
    { lat: 48.8173821, lng: 18.7501266 },
    { lat: 48.8174239, lng: 18.7502171 },
    { lat: 48.8174272, lng: 18.7505989 },
    { lat: 48.817441, lng: 18.7508824 },
    { lat: 48.8174908, lng: 18.7511198 },
    { lat: 48.8176675, lng: 18.7514388 },
    { lat: 48.8177736, lng: 18.7522114 },
    { lat: 48.818129, lng: 18.753317 },
    { lat: 48.818796, lng: 18.752457 },
    { lat: 48.818868, lng: 18.752411 },
    { lat: 48.819264, lng: 18.752156 },
    { lat: 48.819575, lng: 18.751473 },
    { lat: 48.8199224, lng: 18.7511085 },
    { lat: 48.820173, lng: 18.750522 },
    { lat: 48.82022, lng: 18.75054 },
    { lat: 48.82066, lng: 18.750719 },
    { lat: 48.821114, lng: 18.751358 },
    { lat: 48.821182, lng: 18.751453 },
    { lat: 48.82135, lng: 18.751771 },
    { lat: 48.821363, lng: 18.751795 },
    { lat: 48.821438, lng: 18.751834 },
    { lat: 48.821985, lng: 18.752116 },
    { lat: 48.822747, lng: 18.752604 },
    { lat: 48.82304, lng: 18.752791 },
    { lat: 48.823862, lng: 18.753416 },
    { lat: 48.823908, lng: 18.753447 },
    { lat: 48.824442, lng: 18.753607 },
    { lat: 48.824942, lng: 18.753756 },
    { lat: 48.824969, lng: 18.753764 },
    { lat: 48.825196, lng: 18.753715 },
    { lat: 48.825585, lng: 18.753632 },
    { lat: 48.825783, lng: 18.75359 },
    { lat: 48.826026, lng: 18.753205 },
    { lat: 48.826035, lng: 18.753191 },
    { lat: 48.826417, lng: 18.753103 },
    { lat: 48.826575, lng: 18.75288 },
    { lat: 48.826645, lng: 18.752783 },
    { lat: 48.826805, lng: 18.752552 },
    { lat: 48.82689, lng: 18.752429 },
    { lat: 48.827103, lng: 18.751571 },
    { lat: 48.827351, lng: 18.751065 },
    { lat: 48.827412, lng: 18.750939 },
    { lat: 48.827687, lng: 18.750636 },
    { lat: 48.827863, lng: 18.750362 },
    { lat: 48.827915, lng: 18.750223 },
    { lat: 48.827977, lng: 18.75006 },
    { lat: 48.828032, lng: 18.749914 },
    { lat: 48.828058, lng: 18.749847 },
    { lat: 48.828453, lng: 18.748806 },
    { lat: 48.828337, lng: 18.7484 },
    { lat: 48.828203, lng: 18.747928 },
    { lat: 48.8280995, lng: 18.7475333 },
    { lat: 48.828051, lng: 18.747398 },
    { lat: 48.8280777, lng: 18.7471947 },
    { lat: 48.828147, lng: 18.746545 },
    { lat: 48.828216, lng: 18.746365 },
    { lat: 48.828298, lng: 18.746154 },
    { lat: 48.828336, lng: 18.746058 },
    { lat: 48.828349, lng: 18.746023 },
    { lat: 48.828363, lng: 18.745986 },
    { lat: 48.828433, lng: 18.745807 },
    { lat: 48.8284752, lng: 18.745753 },
    { lat: 48.82873, lng: 18.745427 },
    { lat: 48.8289433, lng: 18.74515 },
    { lat: 48.828979, lng: 18.745108 },
    { lat: 48.829059, lng: 18.744273 },
    { lat: 48.829181, lng: 18.743798 },
    { lat: 48.829183, lng: 18.742971 },
    { lat: 48.829285, lng: 18.742805 },
    { lat: 48.82937, lng: 18.742668 },
    { lat: 48.829569, lng: 18.742347 },
    { lat: 48.829974, lng: 18.741932 },
    { lat: 48.830543, lng: 18.741836 },
    { lat: 48.830624, lng: 18.741689 },
    { lat: 48.830823, lng: 18.74133 },
    { lat: 48.830922, lng: 18.741187 },
    { lat: 48.831041, lng: 18.741019 },
    { lat: 48.831076, lng: 18.740757 },
    { lat: 48.831142, lng: 18.740247 },
    { lat: 48.831161, lng: 18.740097 },
    { lat: 48.831189, lng: 18.740075 },
    { lat: 48.831196, lng: 18.740069 },
    { lat: 48.83125, lng: 18.740027 },
    { lat: 48.831328, lng: 18.739965 },
    { lat: 48.831828, lng: 18.739772 },
    { lat: 48.832034, lng: 18.739429 },
    { lat: 48.832124, lng: 18.73928 },
    { lat: 48.832082, lng: 18.738784 },
    { lat: 48.832071, lng: 18.738654 },
    { lat: 48.832012, lng: 18.73843 },
    { lat: 48.831965, lng: 18.738168 },
    { lat: 48.831882, lng: 18.737695 },
    { lat: 48.831851, lng: 18.737526 },
    { lat: 48.831824, lng: 18.737426 },
    { lat: 48.831774, lng: 18.737248 },
    { lat: 48.831713, lng: 18.737031 },
    { lat: 48.831654, lng: 18.736825 },
    { lat: 48.831649, lng: 18.736781 },
    { lat: 48.831616, lng: 18.736561 },
    { lat: 48.831546, lng: 18.736081 },
    { lat: 48.831538, lng: 18.73602 },
    { lat: 48.831544, lng: 18.735831 },
    { lat: 48.8315303, lng: 18.7354097 },
    { lat: 48.8314945, lng: 18.7350577 },
    { lat: 48.8314435, lng: 18.7346855 },
    { lat: 48.831408, lng: 18.734334 },
    { lat: 48.831396, lng: 18.73412 },
    { lat: 48.831388, lng: 18.733991 },
    { lat: 48.831374, lng: 18.733767 },
    { lat: 48.83139, lng: 18.733626 },
    { lat: 48.831442, lng: 18.733185 },
    { lat: 48.831718, lng: 18.732472 },
    { lat: 48.831761, lng: 18.731283 },
    { lat: 48.832006, lng: 18.729141 },
  ],
  Nevidzany: [
    { lat: 48.8437683, lng: 18.4700785 },
    { lat: 48.8437907, lng: 18.4702006 },
    { lat: 48.8436877, lng: 18.4703784 },
    { lat: 48.8435817, lng: 18.4704584 },
    { lat: 48.8435086, lng: 18.4704662 },
    { lat: 48.8434313, lng: 18.4706597 },
    { lat: 48.8433452, lng: 18.4706917 },
    { lat: 48.8432219, lng: 18.4706037 },
    { lat: 48.8431568, lng: 18.4706707 },
    { lat: 48.843104, lng: 18.4707773 },
    { lat: 48.8430617, lng: 18.470769 },
    { lat: 48.8430353, lng: 18.470739 },
    { lat: 48.8429221, lng: 18.4707533 },
    { lat: 48.8428203, lng: 18.4708285 },
    { lat: 48.8426824, lng: 18.4711131 },
    { lat: 48.84242, lng: 18.4712418 },
    { lat: 48.8423023, lng: 18.471256 },
    { lat: 48.8419237, lng: 18.4714513 },
    { lat: 48.8418704, lng: 18.4715254 },
    { lat: 48.8417913, lng: 18.4717287 },
    { lat: 48.8416603, lng: 18.4717675 },
    { lat: 48.8414673, lng: 18.4719065 },
    { lat: 48.8412843, lng: 18.4720484 },
    { lat: 48.8411535, lng: 18.4722311 },
    { lat: 48.840934, lng: 18.4727759 },
    { lat: 48.8409063, lng: 18.4727834 },
    { lat: 48.8408458, lng: 18.4726882 },
    { lat: 48.840838, lng: 18.4726892 },
    { lat: 48.8407957, lng: 18.4728643 },
    { lat: 48.8405908, lng: 18.4729131 },
    { lat: 48.8404893, lng: 18.4732002 },
    { lat: 48.8404327, lng: 18.4732364 },
    { lat: 48.8403694, lng: 18.4731935 },
    { lat: 48.8402369, lng: 18.4732049 },
    { lat: 48.8399744, lng: 18.4733354 },
    { lat: 48.8398484, lng: 18.4735246 },
    { lat: 48.8395881, lng: 18.4737168 },
    { lat: 48.8395621, lng: 18.4737778 },
    { lat: 48.8395443, lng: 18.4738029 },
    { lat: 48.8395013, lng: 18.4738195 },
    { lat: 48.8394244, lng: 18.4738138 },
    { lat: 48.8392183, lng: 18.4740081 },
    { lat: 48.8390372, lng: 18.4741581 },
    { lat: 48.8389218, lng: 18.4742783 },
    { lat: 48.8387339, lng: 18.4743041 },
    { lat: 48.8386961, lng: 18.474339 },
    { lat: 48.8383897, lng: 18.4748152 },
    { lat: 48.8383953, lng: 18.4749173 },
    { lat: 48.8384998, lng: 18.4765134 },
    { lat: 48.8385775, lng: 18.477536 },
    { lat: 48.8386533, lng: 18.4783597 },
    { lat: 48.8387016, lng: 18.4787267 },
    { lat: 48.8387075, lng: 18.4789162 },
    { lat: 48.8387832, lng: 18.4795322 },
    { lat: 48.8388625, lng: 18.4799731 },
    { lat: 48.8389244, lng: 18.4802954 },
    { lat: 48.8385235, lng: 18.4805963 },
    { lat: 48.8387178, lng: 18.4809714 },
    { lat: 48.838778, lng: 18.4811851 },
    { lat: 48.8388902, lng: 18.4813378 },
    { lat: 48.8389627, lng: 18.4816134 },
    { lat: 48.8390639, lng: 18.4818338 },
    { lat: 48.8390785, lng: 18.4819737 },
    { lat: 48.8390397, lng: 18.4820463 },
    { lat: 48.8385915, lng: 18.4822059 },
    { lat: 48.8386018, lng: 18.4823489 },
    { lat: 48.8385642, lng: 18.4827755 },
    { lat: 48.8384655, lng: 18.4834672 },
    { lat: 48.8383913, lng: 18.4840875 },
    { lat: 48.8383846, lng: 18.484189 },
    { lat: 48.8382856, lng: 18.4852227 },
    { lat: 48.8381707, lng: 18.486045 },
    { lat: 48.8380894, lng: 18.4865584 },
    { lat: 48.8379795, lng: 18.486964 },
    { lat: 48.8378857, lng: 18.4873299 },
    { lat: 48.8378815, lng: 18.4873971 },
    { lat: 48.8378216, lng: 18.488033 },
    { lat: 48.8378056, lng: 18.4887498 },
    { lat: 48.8378032, lng: 18.4889725 },
    { lat: 48.837814, lng: 18.4895784 },
    { lat: 48.8377973, lng: 18.4896883 },
    { lat: 48.8376071, lng: 18.4899396 },
    { lat: 48.8375421, lng: 18.4900724 },
    { lat: 48.8373953, lng: 18.490232 },
    { lat: 48.8374505, lng: 18.4903976 },
    { lat: 48.8375985, lng: 18.4905677 },
    { lat: 48.8376257, lng: 18.4906327 },
    { lat: 48.837658, lng: 18.490769 },
    { lat: 48.8377335, lng: 18.4909077 },
    { lat: 48.8377847, lng: 18.4910406 },
    { lat: 48.8379037, lng: 18.4911105 },
    { lat: 48.8379614, lng: 18.491197 },
    { lat: 48.8380675, lng: 18.4912009 },
    { lat: 48.8381359, lng: 18.4912768 },
    { lat: 48.8381351, lng: 18.491486 },
    { lat: 48.8381999, lng: 18.4914954 },
    { lat: 48.8382689, lng: 18.4914781 },
    { lat: 48.8384398, lng: 18.4915719 },
    { lat: 48.8385193, lng: 18.491747 },
    { lat: 48.8385303, lng: 18.4919032 },
    { lat: 48.8386599, lng: 18.4920248 },
    { lat: 48.8388497, lng: 18.4922591 },
    { lat: 48.8389485, lng: 18.4923319 },
    { lat: 48.8390023, lng: 18.4924508 },
    { lat: 48.8390018, lng: 18.4926227 },
    { lat: 48.8391094, lng: 18.4927141 },
    { lat: 48.8392864, lng: 18.4927731 },
    { lat: 48.8393217, lng: 18.4928443 },
    { lat: 48.8393293, lng: 18.4929386 },
    { lat: 48.8393916, lng: 18.492964 },
    { lat: 48.8394727, lng: 18.4929212 },
    { lat: 48.8395559, lng: 18.4929591 },
    { lat: 48.8396567, lng: 18.4931072 },
    { lat: 48.8396909, lng: 18.4931369 },
    { lat: 48.8397892, lng: 18.4931625 },
    { lat: 48.8398501, lng: 18.4932644 },
    { lat: 48.8398473, lng: 18.4934589 },
    { lat: 48.8399944, lng: 18.4936108 },
    { lat: 48.8401332, lng: 18.4936546 },
    { lat: 48.8401836, lng: 18.4938327 },
    { lat: 48.8402472, lng: 18.4939633 },
    { lat: 48.8403205, lng: 18.4941478 },
    { lat: 48.8404926, lng: 18.4942436 },
    { lat: 48.8407016, lng: 18.4945163 },
    { lat: 48.8408253, lng: 18.4946343 },
    { lat: 48.8409913, lng: 18.4947603 },
    { lat: 48.8410445, lng: 18.4948431 },
    { lat: 48.8411677, lng: 18.49493 },
    { lat: 48.8411657, lng: 18.4950796 },
    { lat: 48.8413781, lng: 18.4953956 },
    { lat: 48.8415562, lng: 18.4955569 },
    { lat: 48.8416351, lng: 18.4956813 },
    { lat: 48.8416996, lng: 18.4958884 },
    { lat: 48.8418144, lng: 18.4959252 },
    { lat: 48.8418764, lng: 18.4960477 },
    { lat: 48.8420059, lng: 18.4962284 },
    { lat: 48.8421146, lng: 18.4965693 },
    { lat: 48.842385, lng: 18.4970141 },
    { lat: 48.842428, lng: 18.4973066 },
    { lat: 48.842761, lng: 18.4981131 },
    { lat: 48.8428277, lng: 18.4985055 },
    { lat: 48.8430115, lng: 18.4990009 },
    { lat: 48.8430301, lng: 18.4992586 },
    { lat: 48.8431394, lng: 18.4995234 },
    { lat: 48.8431562, lng: 18.4997692 },
    { lat: 48.8433112, lng: 18.5001483 },
    { lat: 48.84344, lng: 18.5005242 },
    { lat: 48.8435519, lng: 18.5007719 },
    { lat: 48.8437569, lng: 18.5009757 },
    { lat: 48.8439525, lng: 18.5011897 },
    { lat: 48.8440102, lng: 18.5014582 },
    { lat: 48.8441626, lng: 18.5014401 },
    { lat: 48.8443078, lng: 18.5016611 },
    { lat: 48.8443847, lng: 18.5019343 },
    { lat: 48.8445535, lng: 18.502297 },
    { lat: 48.8445595, lng: 18.5025101 },
    { lat: 48.8446117, lng: 18.5026787 },
    { lat: 48.8446305, lng: 18.5030058 },
    { lat: 48.8446024, lng: 18.5033545 },
    { lat: 48.844573, lng: 18.5034789 },
    { lat: 48.8445762, lng: 18.5037242 },
    { lat: 48.8445964, lng: 18.5042127 },
    { lat: 48.8446608, lng: 18.5043399 },
    { lat: 48.8446687, lng: 18.5043823 },
    { lat: 48.8446713, lng: 18.5045327 },
    { lat: 48.8448058, lng: 18.5047662 },
    { lat: 48.8448821, lng: 18.5049199 },
    { lat: 48.8450569, lng: 18.5052521 },
    { lat: 48.8452905, lng: 18.5056024 },
    { lat: 48.8455334, lng: 18.5059601 },
    { lat: 48.8457603, lng: 18.5062747 },
    { lat: 48.8460282, lng: 18.5066135 },
    { lat: 48.8460891, lng: 18.5067599 },
    { lat: 48.846367, lng: 18.5074058 },
    { lat: 48.846412, lng: 18.5075241 },
    { lat: 48.8464401, lng: 18.5076269 },
    { lat: 48.8465805, lng: 18.5081934 },
    { lat: 48.8466193, lng: 18.5083669 },
    { lat: 48.8466688, lng: 18.5090057 },
    { lat: 48.8465906, lng: 18.5095982 },
    { lat: 48.8464537, lng: 18.5100434 },
    { lat: 48.84641, lng: 18.5104804 },
    { lat: 48.8464103, lng: 18.5105076 },
    { lat: 48.8465045, lng: 18.5113227 },
    { lat: 48.8465101, lng: 18.5113868 },
    { lat: 48.846606, lng: 18.5120245 },
    { lat: 48.8466151, lng: 18.5120667 },
    { lat: 48.8468119, lng: 18.5124055 },
    { lat: 48.8470401, lng: 18.5128059 },
    { lat: 48.8472979, lng: 18.513251 },
    { lat: 48.8474631, lng: 18.5135304 },
    { lat: 48.8477984, lng: 18.5141137 },
    { lat: 48.848586, lng: 18.51546 },
    { lat: 48.8484356, lng: 18.5166526 },
    { lat: 48.8482613, lng: 18.5180665 },
    { lat: 48.8481451, lng: 18.5190169 },
    { lat: 48.8484184, lng: 18.5198075 },
    { lat: 48.8489106, lng: 18.521264 },
    { lat: 48.8491213, lng: 18.5218773 },
    { lat: 48.8493661, lng: 18.5226031 },
    { lat: 48.849476, lng: 18.5229231 },
    { lat: 48.8496261, lng: 18.5232968 },
    { lat: 48.849855, lng: 18.5238752 },
    { lat: 48.8499361, lng: 18.5240704 },
    { lat: 48.8500657, lng: 18.524241 },
    { lat: 48.8511215, lng: 18.525337 },
    { lat: 48.8514338, lng: 18.5256672 },
    { lat: 48.8517554, lng: 18.525999 },
    { lat: 48.8520211, lng: 18.5267975 },
    { lat: 48.8523468, lng: 18.5277435 },
    { lat: 48.8528025, lng: 18.5291084 },
    { lat: 48.8529454, lng: 18.5295594 },
    { lat: 48.8538886, lng: 18.5301638 },
    { lat: 48.856183, lng: 18.5290056 },
    { lat: 48.8580587, lng: 18.527247 },
    { lat: 48.8595357, lng: 18.5268594 },
    { lat: 48.8618415, lng: 18.5276938 },
    { lat: 48.8641501, lng: 18.5281837 },
    { lat: 48.8649214, lng: 18.5281362 },
    { lat: 48.8656336, lng: 18.5284154 },
    { lat: 48.8674762, lng: 18.5290683 },
    { lat: 48.867557, lng: 18.5290191 },
    { lat: 48.8685289, lng: 18.5283686 },
    { lat: 48.8695389, lng: 18.5277176 },
    { lat: 48.8697381, lng: 18.5276644 },
    { lat: 48.86993, lng: 18.5275863 },
    { lat: 48.8703251, lng: 18.5273845 },
    { lat: 48.8708221, lng: 18.5270661 },
    { lat: 48.8711827, lng: 18.5268518 },
    { lat: 48.8715307, lng: 18.5265546 },
    { lat: 48.8718871, lng: 18.5263014 },
    { lat: 48.8728809, lng: 18.5253528 },
    { lat: 48.8735953, lng: 18.5250469 },
    { lat: 48.8743839, lng: 18.5232486 },
    { lat: 48.8747371, lng: 18.5224489 },
    { lat: 48.8749358, lng: 18.5219951 },
    { lat: 48.875488, lng: 18.5204753 },
    { lat: 48.8755609, lng: 18.5202813 },
    { lat: 48.875935, lng: 18.5192629 },
    { lat: 48.87608, lng: 18.5190437 },
    { lat: 48.8762321, lng: 18.5187326 },
    { lat: 48.8763601, lng: 18.5181428 },
    { lat: 48.8764279, lng: 18.5172137 },
    { lat: 48.8764416, lng: 18.5164512 },
    { lat: 48.8764634, lng: 18.5163969 },
    { lat: 48.8763553, lng: 18.5156547 },
    { lat: 48.8759298, lng: 18.5141414 },
    { lat: 48.8755902, lng: 18.512594 },
    { lat: 48.8752426, lng: 18.5111229 },
    { lat: 48.8743555, lng: 18.5099543 },
    { lat: 48.8726287, lng: 18.5092785 },
    { lat: 48.871186, lng: 18.5085969 },
    { lat: 48.8707923, lng: 18.5071106 },
    { lat: 48.8705514, lng: 18.5057348 },
    { lat: 48.8701917, lng: 18.5047499 },
    { lat: 48.8695628, lng: 18.5032502 },
    { lat: 48.8684581, lng: 18.500193 },
    { lat: 48.8680174, lng: 18.499108 },
    { lat: 48.8679529, lng: 18.4984436 },
    { lat: 48.867921, lng: 18.4977776 },
    { lat: 48.867982, lng: 18.4970182 },
    { lat: 48.8676913, lng: 18.4965273 },
    { lat: 48.8661333, lng: 18.4912953 },
    { lat: 48.8664566, lng: 18.487767 },
    { lat: 48.8663906, lng: 18.4874894 },
    { lat: 48.8660916, lng: 18.4868306 },
    { lat: 48.8658487, lng: 18.4865746 },
    { lat: 48.8656839, lng: 18.4864087 },
    { lat: 48.8654524, lng: 18.4860763 },
    { lat: 48.8653351, lng: 18.4858894 },
    { lat: 48.8652029, lng: 18.4857197 },
    { lat: 48.8650571, lng: 18.4856131 },
    { lat: 48.8649202, lng: 18.485625 },
    { lat: 48.8647787, lng: 18.4855761 },
    { lat: 48.864689, lng: 18.4854223 },
    { lat: 48.8646624, lng: 18.485287 },
    { lat: 48.8645345, lng: 18.4851576 },
    { lat: 48.8644412, lng: 18.4850376 },
    { lat: 48.8642808, lng: 18.4849095 },
    { lat: 48.864169, lng: 18.4848881 },
    { lat: 48.8639274, lng: 18.4847591 },
    { lat: 48.8638788, lng: 18.4847613 },
    { lat: 48.8636057, lng: 18.4845517 },
    { lat: 48.8634963, lng: 18.4844501 },
    { lat: 48.8633717, lng: 18.4843766 },
    { lat: 48.8633155, lng: 18.484278 },
    { lat: 48.863206, lng: 18.4842331 },
    { lat: 48.8630671, lng: 18.4841499 },
    { lat: 48.8629716, lng: 18.4839932 },
    { lat: 48.862947, lng: 18.4838734 },
    { lat: 48.862522, lng: 18.4834476 },
    { lat: 48.8624161, lng: 18.4832442 },
    { lat: 48.8622971, lng: 18.4830701 },
    { lat: 48.8622321, lng: 18.4828712 },
    { lat: 48.8621804, lng: 18.4826269 },
    { lat: 48.8620695, lng: 18.4823951 },
    { lat: 48.8619862, lng: 18.4821291 },
    { lat: 48.8619437, lng: 18.4818285 },
    { lat: 48.8618394, lng: 18.4816338 },
    { lat: 48.8618454, lng: 18.4814389 },
    { lat: 48.8617864, lng: 18.4812454 },
    { lat: 48.8616733, lng: 18.4808308 },
    { lat: 48.8615274, lng: 18.4805963 },
    { lat: 48.8614167, lng: 18.4804927 },
    { lat: 48.8612745, lng: 18.4804071 },
    { lat: 48.8607293, lng: 18.4802982 },
    { lat: 48.8605668, lng: 18.4803778 },
    { lat: 48.860476, lng: 18.4803688 },
    { lat: 48.8602195, lng: 18.4802786 },
    { lat: 48.8598693, lng: 18.4802676 },
    { lat: 48.8595929, lng: 18.4802269 },
    { lat: 48.8589054, lng: 18.4799321 },
    { lat: 48.858751, lng: 18.4798505 },
    { lat: 48.8573798, lng: 18.4778275 },
    { lat: 48.8567184, lng: 18.4763588 },
    { lat: 48.8567118, lng: 18.4757496 },
    { lat: 48.8564153, lng: 18.4747238 },
    { lat: 48.8556276, lng: 18.4729329 },
    { lat: 48.8559488, lng: 18.4722927 },
    { lat: 48.8559314, lng: 18.4696587 },
    { lat: 48.8560824, lng: 18.4680545 },
    { lat: 48.856566, lng: 18.4665107 },
    { lat: 48.8563559, lng: 18.4651337 },
    { lat: 48.855832, lng: 18.4639343 },
    { lat: 48.8557209, lng: 18.4641697 },
    { lat: 48.8556559, lng: 18.4644069 },
    { lat: 48.8556026, lng: 18.4646232 },
    { lat: 48.8555663, lng: 18.4648481 },
    { lat: 48.8555521, lng: 18.4650591 },
    { lat: 48.855575, lng: 18.4652725 },
    { lat: 48.8555362, lng: 18.4654873 },
    { lat: 48.8554886, lng: 18.4655672 },
    { lat: 48.855346, lng: 18.4656597 },
    { lat: 48.8552708, lng: 18.4656688 },
    { lat: 48.8551689, lng: 18.4656208 },
    { lat: 48.8550536, lng: 18.4655534 },
    { lat: 48.8549206, lng: 18.4655161 },
    { lat: 48.8546686, lng: 18.4655461 },
    { lat: 48.8546235, lng: 18.4655895 },
    { lat: 48.8544564, lng: 18.4656453 },
    { lat: 48.8542819, lng: 18.4656753 },
    { lat: 48.8540234, lng: 18.4656503 },
    { lat: 48.8537286, lng: 18.465646 },
    { lat: 48.8534604, lng: 18.4657286 },
    { lat: 48.853097, lng: 18.4658315 },
    { lat: 48.8527114, lng: 18.4660998 },
    { lat: 48.8524502, lng: 18.4663979 },
    { lat: 48.8524004, lng: 18.4665994 },
    { lat: 48.8523627, lng: 18.4668567 },
    { lat: 48.8523639, lng: 18.4672477 },
    { lat: 48.8522528, lng: 18.4674827 },
    { lat: 48.8520791, lng: 18.4676472 },
    { lat: 48.8519195, lng: 18.4677415 },
    { lat: 48.8515415, lng: 18.4678391 },
    { lat: 48.8515139, lng: 18.4678705 },
    { lat: 48.8512799, lng: 18.4679656 },
    { lat: 48.851068, lng: 18.4678255 },
    { lat: 48.8510484, lng: 18.4677964 },
    { lat: 48.851034, lng: 18.4677188 },
    { lat: 48.851032, lng: 18.4675635 },
    { lat: 48.8509579, lng: 18.4675267 },
    { lat: 48.8504044, lng: 18.4676744 },
    { lat: 48.8503222, lng: 18.4677399 },
    { lat: 48.8501063, lng: 18.4678768 },
    { lat: 48.8500206, lng: 18.4679385 },
    { lat: 48.8497666, lng: 18.4681583 },
    { lat: 48.849544, lng: 18.4684009 },
    { lat: 48.8494138, lng: 18.4685352 },
    { lat: 48.8493322, lng: 18.468711 },
    { lat: 48.8492471, lng: 18.4687669 },
    { lat: 48.8489655, lng: 18.4688941 },
    { lat: 48.8481314, lng: 18.4688047 },
    { lat: 48.8478343, lng: 18.4687534 },
    { lat: 48.8475625, lng: 18.4686727 },
    { lat: 48.8472097, lng: 18.4687406 },
    { lat: 48.8470071, lng: 18.4688718 },
    { lat: 48.8468163, lng: 18.4690353 },
    { lat: 48.8464859, lng: 18.4691998 },
    { lat: 48.8462396, lng: 18.4693158 },
    { lat: 48.8456462, lng: 18.4695598 },
    { lat: 48.8452781, lng: 18.4697607 },
    { lat: 48.8452611, lng: 18.4696744 },
    { lat: 48.8452256, lng: 18.4693766 },
    { lat: 48.8451786, lng: 18.4693266 },
    { lat: 48.8449805, lng: 18.4693957 },
    { lat: 48.8446129, lng: 18.4694797 },
    { lat: 48.8445055, lng: 18.4696387 },
    { lat: 48.8444709, lng: 18.4696069 },
    { lat: 48.8442719, lng: 18.4696582 },
    { lat: 48.8440976, lng: 18.469851 },
    { lat: 48.8437961, lng: 18.4700717 },
    { lat: 48.8437683, lng: 18.4700785 },
  ],
  NitrianskeRudno: [
    { lat: 48.773421, lng: 18.433476 },
    { lat: 48.773185, lng: 18.433719 },
    { lat: 48.772645, lng: 18.433953 },
    { lat: 48.772231, lng: 18.434118 },
    { lat: 48.772049, lng: 18.434137 },
    { lat: 48.771836, lng: 18.434119 },
    { lat: 48.771014, lng: 18.434484 },
    { lat: 48.770282, lng: 18.436091 },
    { lat: 48.77022, lng: 18.43624 },
    { lat: 48.770081, lng: 18.436576 },
    { lat: 48.770015, lng: 18.436806 },
    { lat: 48.769825, lng: 18.437459 },
    { lat: 48.769765, lng: 18.437695 },
    { lat: 48.769784, lng: 18.438225 },
    { lat: 48.769467, lng: 18.43865 },
    { lat: 48.769155, lng: 18.438956 },
    { lat: 48.768457, lng: 18.438916 },
    { lat: 48.767737, lng: 18.439118 },
    { lat: 48.76765, lng: 18.439276 },
    { lat: 48.7677306, lng: 18.4406632 },
    { lat: 48.7680676, lng: 18.441939 },
    { lat: 48.7681739, lng: 18.4431668 },
    { lat: 48.768553, lng: 18.4444141 },
    { lat: 48.7685651, lng: 18.4447137 },
    { lat: 48.7685087, lng: 18.4452451 },
    { lat: 48.7686506, lng: 18.4457092 },
    { lat: 48.7687075, lng: 18.4461644 },
    { lat: 48.7688649, lng: 18.4468908 },
    { lat: 48.7688347, lng: 18.447161 },
    { lat: 48.7689288, lng: 18.4479856 },
    { lat: 48.7690652, lng: 18.4482093 },
    { lat: 48.7692129, lng: 18.4488587 },
    { lat: 48.7692762, lng: 18.4497218 },
    { lat: 48.7693852, lng: 18.4497724 },
    { lat: 48.7697298, lng: 18.4496605 },
    { lat: 48.7699516, lng: 18.4494475 },
    { lat: 48.7703071, lng: 18.4490024 },
    { lat: 48.7709023, lng: 18.4492207 },
    { lat: 48.7712527, lng: 18.4493334 },
    { lat: 48.771531, lng: 18.4493672 },
    { lat: 48.7719892, lng: 18.4495535 },
    { lat: 48.7724137, lng: 18.4497229 },
    { lat: 48.7731366, lng: 18.4498425 },
    { lat: 48.773532, lng: 18.4500768 },
    { lat: 48.7743618, lng: 18.450661 },
    { lat: 48.774608, lng: 18.4512193 },
    { lat: 48.7748325, lng: 18.4515639 },
    { lat: 48.7749496, lng: 18.4519286 },
    { lat: 48.7750133, lng: 18.4520432 },
    { lat: 48.7750992, lng: 18.452125 },
    { lat: 48.7752408, lng: 18.4523012 },
    { lat: 48.7754724, lng: 18.4527534 },
    { lat: 48.7756384, lng: 18.4528779 },
    { lat: 48.7757787, lng: 18.453009 },
    { lat: 48.7758997, lng: 18.453179 },
    { lat: 48.776263, lng: 18.4534052 },
    { lat: 48.7764296, lng: 18.453564 },
    { lat: 48.7767969, lng: 18.4539611 },
    { lat: 48.7774163, lng: 18.4543123 },
    { lat: 48.7776894, lng: 18.4544985 },
    { lat: 48.7779757, lng: 18.4545553 },
    { lat: 48.7782176, lng: 18.4544607 },
    { lat: 48.7786462, lng: 18.4545213 },
    { lat: 48.7788702, lng: 18.4550646 },
    { lat: 48.7790952, lng: 18.4555004 },
    { lat: 48.7792158, lng: 18.4556211 },
    { lat: 48.7794393, lng: 18.455747 },
    { lat: 48.78021, lng: 18.4567552 },
    { lat: 48.7801414, lng: 18.4570614 },
    { lat: 48.7802691, lng: 18.4574563 },
    { lat: 48.7799782, lng: 18.4577663 },
    { lat: 48.779901, lng: 18.4604988 },
    { lat: 48.7800797, lng: 18.4611358 },
    { lat: 48.7802083, lng: 18.4612612 },
    { lat: 48.7802154, lng: 18.462842 },
    { lat: 48.7800604, lng: 18.4642036 },
    { lat: 48.7802695, lng: 18.4654799 },
    { lat: 48.7813034, lng: 18.4657994 },
    { lat: 48.7808328, lng: 18.4669847 },
    { lat: 48.781001, lng: 18.4678234 },
    { lat: 48.7814119, lng: 18.4691802 },
    { lat: 48.7815338, lng: 18.4700844 },
    { lat: 48.7815245, lng: 18.4704655 },
    { lat: 48.7816223, lng: 18.4707693 },
    { lat: 48.7819687, lng: 18.4720409 },
    { lat: 48.7823503, lng: 18.4728237 },
    { lat: 48.7825855, lng: 18.4724839 },
    { lat: 48.7829112, lng: 18.4721918 },
    { lat: 48.7833684, lng: 18.472098 },
    { lat: 48.7839239, lng: 18.4717371 },
    { lat: 48.7840541, lng: 18.4725434 },
    { lat: 48.7837058, lng: 18.4730835 },
    { lat: 48.7835426, lng: 18.4732954 },
    { lat: 48.783337, lng: 18.4735331 },
    { lat: 48.7831021, lng: 18.4740617 },
    { lat: 48.7829694, lng: 18.4744598 },
    { lat: 48.782908, lng: 18.4746764 },
    { lat: 48.7832333, lng: 18.4750557 },
    { lat: 48.7834759, lng: 18.4762631 },
    { lat: 48.7837392, lng: 18.4771357 },
    { lat: 48.7840252, lng: 18.4782089 },
    { lat: 48.7841755, lng: 18.4786042 },
    { lat: 48.784298, lng: 18.4789661 },
    { lat: 48.7843088, lng: 18.4791014 },
    { lat: 48.7842976, lng: 18.4792895 },
    { lat: 48.7843606, lng: 18.4793646 },
    { lat: 48.7845767, lng: 18.4791939 },
    { lat: 48.7847881, lng: 18.4791001 },
    { lat: 48.7849984, lng: 18.4791072 },
    { lat: 48.7849929, lng: 18.4792356 },
    { lat: 48.7849587, lng: 18.4794942 },
    { lat: 48.7847278, lng: 18.4797065 },
    { lat: 48.7848372, lng: 18.4801788 },
    { lat: 48.7846065, lng: 18.4803284 },
    { lat: 48.7843836, lng: 18.4804198 },
    { lat: 48.784449, lng: 18.4806237 },
    { lat: 48.7844249, lng: 18.4808657 },
    { lat: 48.7844514, lng: 18.4810165 },
    { lat: 48.7844623, lng: 18.4812362 },
    { lat: 48.7845882, lng: 18.4812676 },
    { lat: 48.7846775, lng: 18.481354 },
    { lat: 48.7847094, lng: 18.4814444 },
    { lat: 48.7848152, lng: 18.4823025 },
    { lat: 48.7849469, lng: 18.4832614 },
    { lat: 48.7851455, lng: 18.4841664 },
    { lat: 48.7855147, lng: 18.4858338 },
    { lat: 48.7856212, lng: 18.4862926 },
    { lat: 48.7856752, lng: 18.4864746 },
    { lat: 48.785862, lng: 18.4870406 },
    { lat: 48.7860178, lng: 18.4875566 },
    { lat: 48.7861039, lng: 18.4878505 },
    { lat: 48.7863272, lng: 18.4887528 },
    { lat: 48.7863614, lng: 18.4889063 },
    { lat: 48.786428, lng: 18.4890907 },
    { lat: 48.7865428, lng: 18.4894562 },
    { lat: 48.7866883, lng: 18.4898461 },
    { lat: 48.7868178, lng: 18.4898409 },
    { lat: 48.7876396, lng: 18.4893995 },
    { lat: 48.7875771, lng: 18.4891866 },
    { lat: 48.787856, lng: 18.4890696 },
    { lat: 48.788083, lng: 18.4889076 },
    { lat: 48.788133, lng: 18.4889139 },
    { lat: 48.7884339, lng: 18.488621 },
    { lat: 48.7885999, lng: 18.4884212 },
    { lat: 48.7887194, lng: 18.4882981 },
    { lat: 48.7888427, lng: 18.488219 },
    { lat: 48.7889414, lng: 18.4881908 },
    { lat: 48.7890436, lng: 18.4881794 },
    { lat: 48.7891821, lng: 18.4882178 },
    { lat: 48.7894857, lng: 18.4882411 },
    { lat: 48.7895972, lng: 18.4882181 },
    { lat: 48.7896178, lng: 18.4882839 },
    { lat: 48.7907469, lng: 18.487709 },
    { lat: 48.7915908, lng: 18.4871978 },
    { lat: 48.7920594, lng: 18.4869364 },
    { lat: 48.7922881, lng: 18.4867502 },
    { lat: 48.7925487, lng: 18.4867088 },
    { lat: 48.7930103, lng: 18.4868761 },
    { lat: 48.7932787, lng: 18.4871021 },
    { lat: 48.7934542, lng: 18.4871763 },
    { lat: 48.7939857, lng: 18.4875885 },
    { lat: 48.7943794, lng: 18.4877758 },
    { lat: 48.7946247, lng: 18.4877828 },
    { lat: 48.7948464, lng: 18.4877743 },
    { lat: 48.7953681, lng: 18.4876185 },
    { lat: 48.7959245, lng: 18.487502 },
    { lat: 48.7961152, lng: 18.4874189 },
    { lat: 48.7968479, lng: 18.4871425 },
    { lat: 48.797297, lng: 18.4871243 },
    { lat: 48.7976989, lng: 18.4874179 },
    { lat: 48.7979772, lng: 18.4874759 },
    { lat: 48.7983853, lng: 18.4873265 },
    { lat: 48.7987989, lng: 18.48699 },
    { lat: 48.7991264, lng: 18.4864243 },
    { lat: 48.7992622, lng: 18.4861209 },
    { lat: 48.7993485, lng: 18.4858839 },
    { lat: 48.7994784, lng: 18.4857448 },
    { lat: 48.7996923, lng: 18.485675 },
    { lat: 48.800024, lng: 18.4857195 },
    { lat: 48.8003532, lng: 18.4857947 },
    { lat: 48.800662, lng: 18.4858968 },
    { lat: 48.8010064, lng: 18.4860635 },
    { lat: 48.8015846, lng: 18.4863647 },
    { lat: 48.8020107, lng: 18.4865837 },
    { lat: 48.8023627, lng: 18.486752 },
    { lat: 48.8026087, lng: 18.4872663 },
    { lat: 48.8026204, lng: 18.4877866 },
    { lat: 48.8026002, lng: 18.488055 },
    { lat: 48.8025488, lng: 18.4884529 },
    { lat: 48.8032431, lng: 18.4879934 },
    { lat: 48.8032946, lng: 18.4879455 },
    { lat: 48.8033854, lng: 18.4878303 },
    { lat: 48.8034126, lng: 18.4877524 },
    { lat: 48.803395, lng: 18.4876569 },
    { lat: 48.8034565, lng: 18.487627 },
    { lat: 48.8035662, lng: 18.4875506 },
    { lat: 48.8038718, lng: 18.4874237 },
    { lat: 48.8043295, lng: 18.487335 },
    { lat: 48.8048216, lng: 18.4873003 },
    { lat: 48.805232, lng: 18.487215 },
    { lat: 48.8056281, lng: 18.4871583 },
    { lat: 48.8065691, lng: 18.487036 },
    { lat: 48.8070656, lng: 18.4870597 },
    { lat: 48.8073254, lng: 18.4871298 },
    { lat: 48.807568, lng: 18.4871089 },
    { lat: 48.8077735, lng: 18.4872995 },
    { lat: 48.8080307, lng: 18.4875238 },
    { lat: 48.8083493, lng: 18.4877829 },
    { lat: 48.8085867, lng: 18.4870308 },
    { lat: 48.8087375, lng: 18.4867373 },
    { lat: 48.8090437, lng: 18.4865803 },
    { lat: 48.8093205, lng: 18.486485 },
    { lat: 48.8096054, lng: 18.486292 },
    { lat: 48.8099283, lng: 18.4862367 },
    { lat: 48.8105471, lng: 18.4861831 },
    { lat: 48.81088, lng: 18.4861258 },
    { lat: 48.8112384, lng: 18.4859759 },
    { lat: 48.8116399, lng: 18.4858319 },
    { lat: 48.8120332, lng: 18.4856579 },
    { lat: 48.8123036, lng: 18.4855497 },
    { lat: 48.8126359, lng: 18.4854814 },
    { lat: 48.8129528, lng: 18.4854999 },
    { lat: 48.8134299, lng: 18.4854763 },
    { lat: 48.8138181, lng: 18.4853995 },
    { lat: 48.8142509, lng: 18.4853922 },
    { lat: 48.8145588, lng: 18.4852261 },
    { lat: 48.8147913, lng: 18.4850214 },
    { lat: 48.8148453, lng: 18.4854928 },
    { lat: 48.8151403, lng: 18.4853601 },
    { lat: 48.8153412, lng: 18.485217 },
    { lat: 48.8155928, lng: 18.4851378 },
    { lat: 48.8160534, lng: 18.4850017 },
    { lat: 48.8167336, lng: 18.4848366 },
    { lat: 48.8169645, lng: 18.4847882 },
    { lat: 48.8175961, lng: 18.484641 },
    { lat: 48.818038, lng: 18.484575 },
    { lat: 48.8182837, lng: 18.4843264 },
    { lat: 48.8184204, lng: 18.4839581 },
    { lat: 48.8187374, lng: 18.4837943 },
    { lat: 48.8186972, lng: 18.4832685 },
    { lat: 48.8188483, lng: 18.483 },
    { lat: 48.818563, lng: 18.4827542 },
    { lat: 48.8184411, lng: 18.4827119 },
    { lat: 48.8182231, lng: 18.4824402 },
    { lat: 48.8180168, lng: 18.4821146 },
    { lat: 48.8179353, lng: 18.4817412 },
    { lat: 48.8177143, lng: 18.4812887 },
    { lat: 48.8174023, lng: 18.4809278 },
    { lat: 48.8170933, lng: 18.4807044 },
    { lat: 48.8168652, lng: 18.4803719 },
    { lat: 48.8167029, lng: 18.4800252 },
    { lat: 48.8169518, lng: 18.4798753 },
    { lat: 48.8169249, lng: 18.4797774 },
    { lat: 48.816967, lng: 18.4796389 },
    { lat: 48.8171131, lng: 18.4793603 },
    { lat: 48.8172556, lng: 18.4790588 },
    { lat: 48.8172346, lng: 18.4788825 },
    { lat: 48.8171691, lng: 18.4784523 },
    { lat: 48.8171489, lng: 18.478287 },
    { lat: 48.8171153, lng: 18.4779261 },
    { lat: 48.8170317, lng: 18.4776353 },
    { lat: 48.8169696, lng: 18.4773854 },
    { lat: 48.8169002, lng: 18.4770265 },
    { lat: 48.8168682, lng: 18.4768956 },
    { lat: 48.8168682, lng: 18.4767707 },
    { lat: 48.8168326, lng: 18.4766402 },
    { lat: 48.8168222, lng: 18.4762657 },
    { lat: 48.816831, lng: 18.4758934 },
    { lat: 48.8168358, lng: 18.4754855 },
    { lat: 48.8168986, lng: 18.4751704 },
    { lat: 48.8169403, lng: 18.474862 },
    { lat: 48.8169473, lng: 18.4744999 },
    { lat: 48.817037, lng: 18.474262 },
    { lat: 48.8170588, lng: 18.4739615 },
    { lat: 48.8169167, lng: 18.4733688 },
    { lat: 48.8168227, lng: 18.4730731 },
    { lat: 48.8167166, lng: 18.4728288 },
    { lat: 48.8167313, lng: 18.4727053 },
    { lat: 48.8167017, lng: 18.4725966 },
    { lat: 48.816661, lng: 18.4724929 },
    { lat: 48.8166714, lng: 18.4723942 },
    { lat: 48.8166095, lng: 18.4722532 },
    { lat: 48.8165666, lng: 18.4721082 },
    { lat: 48.8165201, lng: 18.4719212 },
    { lat: 48.8165044, lng: 18.4719027 },
    { lat: 48.8164586, lng: 18.4718899 },
    { lat: 48.8164244, lng: 18.4716974 },
    { lat: 48.8163739, lng: 18.4714976 },
    { lat: 48.8162612, lng: 18.4712977 },
    { lat: 48.8161321, lng: 18.4710388 },
    { lat: 48.8160825, lng: 18.470919 },
    { lat: 48.8160815, lng: 18.4706346 },
    { lat: 48.815974, lng: 18.4703006 },
    { lat: 48.815922, lng: 18.4700301 },
    { lat: 48.8159424, lng: 18.4698905 },
    { lat: 48.8159, lng: 18.469777 },
    { lat: 48.8158353, lng: 18.4694591 },
    { lat: 48.8158355, lng: 18.469361 },
    { lat: 48.8158177, lng: 18.4691395 },
    { lat: 48.8157937, lng: 18.4691114 },
    { lat: 48.8157924, lng: 18.4689899 },
    { lat: 48.8157425, lng: 18.4687796 },
    { lat: 48.8156947, lng: 18.4684427 },
    { lat: 48.8156781, lng: 18.468141 },
    { lat: 48.8157034, lng: 18.4678242 },
    { lat: 48.8155277, lng: 18.4678059 },
    { lat: 48.8154757, lng: 18.467888 },
    { lat: 48.8154517, lng: 18.4680643 },
    { lat: 48.8153982, lng: 18.4682003 },
    { lat: 48.8153608, lng: 18.4681746 },
    { lat: 48.8151262, lng: 18.4682575 },
    { lat: 48.8149427, lng: 18.4682871 },
    { lat: 48.8131496, lng: 18.4680919 },
    { lat: 48.8123416, lng: 18.4680868 },
    { lat: 48.8118367, lng: 18.468031 },
    { lat: 48.8111196, lng: 18.4678524 },
    { lat: 48.8108228, lng: 18.4677904 },
    { lat: 48.8106874, lng: 18.4678512 },
    { lat: 48.8104902, lng: 18.4678965 },
    { lat: 48.8101211, lng: 18.4678525 },
    { lat: 48.8101188, lng: 18.4678918 },
    { lat: 48.8096947, lng: 18.4678702 },
    { lat: 48.8092473, lng: 18.4678805 },
    { lat: 48.8087988, lng: 18.4678487 },
    { lat: 48.808701, lng: 18.4679359 },
    { lat: 48.8086083, lng: 18.4681151 },
    { lat: 48.8085498, lng: 18.4683454 },
    { lat: 48.8080956, lng: 18.4702355 },
    { lat: 48.8078217, lng: 18.4713662 },
    { lat: 48.8075536, lng: 18.4724608 },
    { lat: 48.807239, lng: 18.4737541 },
    { lat: 48.8070714, lng: 18.4744602 },
    { lat: 48.8067678, lng: 18.4744792 },
    { lat: 48.8067698, lng: 18.4745322 },
    { lat: 48.8067139, lng: 18.4745432 },
    { lat: 48.8064124, lng: 18.4746618 },
    { lat: 48.8061046, lng: 18.4748083 },
    { lat: 48.8058754, lng: 18.4749084 },
    { lat: 48.8057601, lng: 18.4749444 },
    { lat: 48.8054697, lng: 18.4750784 },
    { lat: 48.8054076, lng: 18.4751184 },
    { lat: 48.8050064, lng: 18.4752889 },
    { lat: 48.8046709, lng: 18.4754425 },
    { lat: 48.8043887, lng: 18.4755639 },
    { lat: 48.8041118, lng: 18.4756786 },
    { lat: 48.8038332, lng: 18.4757796 },
    { lat: 48.803598, lng: 18.4758296 },
    { lat: 48.8036807, lng: 18.475349 },
    { lat: 48.8037502, lng: 18.4751341 },
    { lat: 48.8039188, lng: 18.4747729 },
    { lat: 48.8038267, lng: 18.474719 },
    { lat: 48.8036714, lng: 18.4747075 },
    { lat: 48.8034857, lng: 18.474489 },
    { lat: 48.803434, lng: 18.4743055 },
    { lat: 48.8035968, lng: 18.4740252 },
    { lat: 48.8036496, lng: 18.4736531 },
    { lat: 48.8036768, lng: 18.4731026 },
    { lat: 48.8037488, lng: 18.4724229 },
    { lat: 48.8038308, lng: 18.4721098 },
    { lat: 48.8038811, lng: 18.4716483 },
    { lat: 48.8027607, lng: 18.4714983 },
    { lat: 48.8025682, lng: 18.4715276 },
    { lat: 48.8026588, lng: 18.4710656 },
    { lat: 48.8027302, lng: 18.4707596 },
    { lat: 48.8028864, lng: 18.4702783 },
    { lat: 48.8030309, lng: 18.4698514 },
    { lat: 48.8031908, lng: 18.4693328 },
    { lat: 48.8034656, lng: 18.468528 },
    { lat: 48.8036489, lng: 18.4678439 },
    { lat: 48.8038993, lng: 18.4669395 },
    { lat: 48.8040448, lng: 18.4664259 },
    { lat: 48.8040892, lng: 18.4662113 },
    { lat: 48.8041222, lng: 18.4659688 },
    { lat: 48.8041298, lng: 18.46582 },
    { lat: 48.8040475, lng: 18.4658222 },
    { lat: 48.8040587, lng: 18.4655119 },
    { lat: 48.804061, lng: 18.4651241 },
    { lat: 48.8040814, lng: 18.4646538 },
    { lat: 48.8041074, lng: 18.4643692 },
    { lat: 48.8041374, lng: 18.4642201 },
    { lat: 48.8042059, lng: 18.4640086 },
    { lat: 48.8043681, lng: 18.463593 },
    { lat: 48.8042915, lng: 18.4635755 },
    { lat: 48.8039855, lng: 18.4635315 },
    { lat: 48.8038139, lng: 18.463526 },
    { lat: 48.8036099, lng: 18.463531 },
    { lat: 48.8030381, lng: 18.4635914 },
    { lat: 48.802726, lng: 18.4635979 },
    { lat: 48.802446, lng: 18.4636172 },
    { lat: 48.8021409, lng: 18.4636493 },
    { lat: 48.8021003, lng: 18.4635857 },
    { lat: 48.8021542, lng: 18.4634565 },
    { lat: 48.802133, lng: 18.4634237 },
    { lat: 48.8021716, lng: 18.4634042 },
    { lat: 48.8022318, lng: 18.4633125 },
    { lat: 48.8022359, lng: 18.4632633 },
    { lat: 48.8023268, lng: 18.4631101 },
    { lat: 48.8024269, lng: 18.4630059 },
    { lat: 48.8024694, lng: 18.4628924 },
    { lat: 48.8025683, lng: 18.4628044 },
    { lat: 48.8026614, lng: 18.4627955 },
    { lat: 48.8027505, lng: 18.4626526 },
    { lat: 48.8028932, lng: 18.4625186 },
    { lat: 48.8029415, lng: 18.4623705 },
    { lat: 48.8029146, lng: 18.4622719 },
    { lat: 48.802953, lng: 18.4621124 },
    { lat: 48.803004, lng: 18.4620727 },
    { lat: 48.8031118, lng: 18.4618623 },
    { lat: 48.8031626, lng: 18.4616351 },
    { lat: 48.8031968, lng: 18.4615817 },
    { lat: 48.8032248, lng: 18.4615152 },
    { lat: 48.8032605, lng: 18.4614677 },
    { lat: 48.8033216, lng: 18.461285 },
    { lat: 48.803393, lng: 18.4611704 },
    { lat: 48.8035353, lng: 18.4611099 },
    { lat: 48.8035835, lng: 18.4609853 },
    { lat: 48.8035586, lng: 18.4609402 },
    { lat: 48.8035804, lng: 18.4609074 },
    { lat: 48.8035781, lng: 18.4608661 },
    { lat: 48.8036334, lng: 18.4607833 },
    { lat: 48.8036837, lng: 18.4606542 },
    { lat: 48.8037334, lng: 18.4605531 },
    { lat: 48.8038987, lng: 18.4603171 },
    { lat: 48.8039684, lng: 18.4601498 },
    { lat: 48.8041709, lng: 18.4597073 },
    { lat: 48.8042945, lng: 18.4595342 },
    { lat: 48.8042836, lng: 18.4594407 },
    { lat: 48.8045113, lng: 18.4592531 },
    { lat: 48.8045547, lng: 18.4591617 },
    { lat: 48.8046926, lng: 18.4590423 },
    { lat: 48.804866, lng: 18.4587312 },
    { lat: 48.8049312, lng: 18.4586671 },
    { lat: 48.8049757, lng: 18.4584711 },
    { lat: 48.8050308, lng: 18.4583432 },
    { lat: 48.8050966, lng: 18.4582665 },
    { lat: 48.8051866, lng: 18.4581034 },
    { lat: 48.8052491, lng: 18.4580272 },
    { lat: 48.8053014, lng: 18.4578921 },
    { lat: 48.8055155, lng: 18.4576839 },
    { lat: 48.8056019, lng: 18.4575921 },
    { lat: 48.8056613, lng: 18.4574429 },
    { lat: 48.8057386, lng: 18.4570448 },
    { lat: 48.8057421, lng: 18.456762 },
    { lat: 48.8057041, lng: 18.4563768 },
    { lat: 48.8055825, lng: 18.4558904 },
    { lat: 48.8055582, lng: 18.4557385 },
    { lat: 48.8054854, lng: 18.4554213 },
    { lat: 48.8055109, lng: 18.4551884 },
    { lat: 48.8054947, lng: 18.454402 },
    { lat: 48.8055476, lng: 18.454239 },
    { lat: 48.8055391, lng: 18.4540003 },
    { lat: 48.8055886, lng: 18.4537141 },
    { lat: 48.8055952, lng: 18.4534871 },
    { lat: 48.8055839, lng: 18.4534241 },
    { lat: 48.8055791, lng: 18.4532931 },
    { lat: 48.8056081, lng: 18.4532074 },
    { lat: 48.8056223, lng: 18.4530578 },
    { lat: 48.8057131, lng: 18.4527185 },
    { lat: 48.805821, lng: 18.4522414 },
    { lat: 48.8059071, lng: 18.4517796 },
    { lat: 48.805934, lng: 18.4516127 },
    { lat: 48.8059997, lng: 18.4513721 },
    { lat: 48.8060364, lng: 18.4511771 },
    { lat: 48.8060986, lng: 18.4509752 },
    { lat: 48.8061609, lng: 18.4505332 },
    { lat: 48.8062071, lng: 18.4499397 },
    { lat: 48.8063604, lng: 18.4478328 },
    { lat: 48.8061437, lng: 18.446627 },
    { lat: 48.8066763, lng: 18.4454258 },
    { lat: 48.8066814, lng: 18.4418012 },
    { lat: 48.8062677, lng: 18.4398904 },
    { lat: 48.8061037, lng: 18.4394931 },
    { lat: 48.8057869, lng: 18.4387456 },
    { lat: 48.8057594, lng: 18.43727 },
    { lat: 48.8046034, lng: 18.4337885 },
    { lat: 48.8042766, lng: 18.4331429 },
    { lat: 48.8038898, lng: 18.4314982 },
    { lat: 48.8021826, lng: 18.429109 },
    { lat: 48.8017163, lng: 18.4272688 },
    { lat: 48.8019682, lng: 18.4251175 },
    { lat: 48.8017781, lng: 18.4238463 },
    { lat: 48.8013877, lng: 18.4223607 },
    { lat: 48.801419, lng: 18.422308 },
    { lat: 48.801316, lng: 18.422324 },
    { lat: 48.80104, lng: 18.422734 },
    { lat: 48.800553, lng: 18.422917 },
    { lat: 48.800259, lng: 18.423145 },
    { lat: 48.79983, lng: 18.423342 },
    { lat: 48.799579, lng: 18.423354 },
    { lat: 48.799353, lng: 18.42347 },
    { lat: 48.799087, lng: 18.423427 },
    { lat: 48.798733, lng: 18.42344 },
    { lat: 48.798498, lng: 18.423618 },
    { lat: 48.79833, lng: 18.423781 },
    { lat: 48.797858, lng: 18.423791 },
    { lat: 48.797397, lng: 18.424206 },
    { lat: 48.79708, lng: 18.424421 },
    { lat: 48.796862, lng: 18.424555 },
    { lat: 48.796635, lng: 18.42492 },
    { lat: 48.796333, lng: 18.425128 },
    { lat: 48.796026, lng: 18.425307 },
    { lat: 48.795777, lng: 18.425407 },
    { lat: 48.795321, lng: 18.425526 },
    { lat: 48.795043, lng: 18.425853 },
    { lat: 48.79459, lng: 18.426331 },
    { lat: 48.794227, lng: 18.426347 },
    { lat: 48.79398, lng: 18.426208 },
    { lat: 48.7934, lng: 18.426311 },
    { lat: 48.7934, lng: 18.426332 },
    { lat: 48.792865, lng: 18.426336 },
    { lat: 48.7925, lng: 18.426191 },
    { lat: 48.792503, lng: 18.426167 },
    { lat: 48.792121, lng: 18.426034 },
    { lat: 48.791687, lng: 18.425636 },
    { lat: 48.791484, lng: 18.425456 },
    { lat: 48.791253, lng: 18.425862 },
    { lat: 48.791262, lng: 18.425914 },
    { lat: 48.791267, lng: 18.425935 },
    { lat: 48.790972, lng: 18.426041 },
    { lat: 48.790718, lng: 18.426056 },
    { lat: 48.790417, lng: 18.426299 },
    { lat: 48.790073, lng: 18.426704 },
    { lat: 48.790069, lng: 18.426679 },
    { lat: 48.789355, lng: 18.427006 },
    { lat: 48.789316, lng: 18.427024 },
    { lat: 48.789255, lng: 18.427052 },
    { lat: 48.789069, lng: 18.427234 },
    { lat: 48.788483, lng: 18.427468 },
    { lat: 48.788183, lng: 18.427563 },
    { lat: 48.788, lng: 18.427883 },
    { lat: 48.787723, lng: 18.427923 },
    { lat: 48.787222, lng: 18.428064 },
    { lat: 48.786938, lng: 18.428257 },
    { lat: 48.786905, lng: 18.428437 },
    { lat: 48.786643, lng: 18.428871 },
    { lat: 48.786516, lng: 18.429 },
    { lat: 48.786252, lng: 18.429051 },
    { lat: 48.786137, lng: 18.4293 },
    { lat: 48.785684, lng: 18.430523 },
    { lat: 48.785205, lng: 18.431887 },
    { lat: 48.785191, lng: 18.432594 },
    { lat: 48.785192, lng: 18.433423 },
    { lat: 48.785194, lng: 18.435308 },
    { lat: 48.785025, lng: 18.435498 },
    { lat: 48.784473, lng: 18.436849 },
    { lat: 48.78385, lng: 18.437623 },
    { lat: 48.783403, lng: 18.438308 },
    { lat: 48.782957, lng: 18.438325 },
    { lat: 48.782529, lng: 18.438468 },
    { lat: 48.782112, lng: 18.43868 },
    { lat: 48.782047, lng: 18.438642 },
    { lat: 48.781866, lng: 18.438245 },
    { lat: 48.781724, lng: 18.437994 },
    { lat: 48.781393, lng: 18.437733 },
    { lat: 48.78123, lng: 18.437501 },
    { lat: 48.780832, lng: 18.437304 },
    { lat: 48.780263, lng: 18.436834 },
    { lat: 48.779917, lng: 18.436505 },
    { lat: 48.779517, lng: 18.436536 },
    { lat: 48.779274, lng: 18.436372 },
    { lat: 48.778979, lng: 18.43591 },
    { lat: 48.778758, lng: 18.435694 },
    { lat: 48.778518, lng: 18.435435 },
    { lat: 48.778351, lng: 18.435329 },
    { lat: 48.77818, lng: 18.435142 },
    { lat: 48.777882, lng: 18.435023 },
    { lat: 48.777546, lng: 18.434947 },
    { lat: 48.777243, lng: 18.434906 },
    { lat: 48.777028, lng: 18.434778 },
    { lat: 48.776646, lng: 18.43436 },
    { lat: 48.776395, lng: 18.434297 },
    { lat: 48.776201, lng: 18.434222 },
    { lat: 48.77572, lng: 18.433915 },
    { lat: 48.775455, lng: 18.433735 },
    { lat: 48.774849, lng: 18.433872 },
    { lat: 48.77474, lng: 18.433853 },
    { lat: 48.774113, lng: 18.433925 },
    { lat: 48.773805, lng: 18.433877 },
    { lat: 48.773502, lng: 18.433629 },
    { lat: 48.773421, lng: 18.433476 },
  ],
  NitrianskeSučany: [
    { lat: 48.745884, lng: 18.423367 },
    { lat: 48.7454785, lng: 18.4238668 },
    { lat: 48.7452013, lng: 18.424035 },
    { lat: 48.7450305, lng: 18.4241525 },
    { lat: 48.744944, lng: 18.424502 },
    { lat: 48.7440524, lng: 18.4244943 },
    { lat: 48.7436384, lng: 18.4255534 },
    { lat: 48.7432166, lng: 18.4267896 },
    { lat: 48.743175, lng: 18.4269411 },
    { lat: 48.7430813, lng: 18.4272841 },
    { lat: 48.7427726, lng: 18.4279416 },
    { lat: 48.7420517, lng: 18.428289 },
    { lat: 48.7416915, lng: 18.42875 },
    { lat: 48.7414923, lng: 18.4291442 },
    { lat: 48.7407849, lng: 18.4301396 },
    { lat: 48.7405952, lng: 18.4307245 },
    { lat: 48.7404187, lng: 18.4311664 },
    { lat: 48.7403531, lng: 18.4314239 },
    { lat: 48.7403525, lng: 18.4315798 },
    { lat: 48.7404129, lng: 18.4318541 },
    { lat: 48.7405271, lng: 18.4321821 },
    { lat: 48.7406014, lng: 18.4323199 },
    { lat: 48.7405721, lng: 18.4328111 },
    { lat: 48.7405143, lng: 18.43295 },
    { lat: 48.7401871, lng: 18.4330511 },
    { lat: 48.7399676, lng: 18.433159 },
    { lat: 48.7397699, lng: 18.4333592 },
    { lat: 48.7395646, lng: 18.4335836 },
    { lat: 48.7394736, lng: 18.4337542 },
    { lat: 48.7391417, lng: 18.434257 },
    { lat: 48.7389946, lng: 18.4345758 },
    { lat: 48.7388395, lng: 18.4349715 },
    { lat: 48.7387989, lng: 18.435275 },
    { lat: 48.738779, lng: 18.4355088 },
    { lat: 48.7386061, lng: 18.43607 },
    { lat: 48.7384967, lng: 18.4363566 },
    { lat: 48.7378667, lng: 18.4376491 },
    { lat: 48.7373448, lng: 18.4383116 },
    { lat: 48.7371513, lng: 18.4387068 },
    { lat: 48.7367318, lng: 18.4396859 },
    { lat: 48.7366746, lng: 18.4399168 },
    { lat: 48.7366266, lng: 18.4402328 },
    { lat: 48.7358731, lng: 18.4416816 },
    { lat: 48.7356451, lng: 18.4422465 },
    { lat: 48.7346994, lng: 18.4442381 },
    { lat: 48.7321709, lng: 18.4451087 },
    { lat: 48.7300803, lng: 18.4466131 },
    { lat: 48.7294016, lng: 18.4477795 },
    { lat: 48.729057, lng: 18.4487714 },
    { lat: 48.7289974, lng: 18.4490184 },
    { lat: 48.7289829, lng: 18.4491463 },
    { lat: 48.7288836, lng: 18.4494283 },
    { lat: 48.7287725, lng: 18.4496253 },
    { lat: 48.7287061, lng: 18.4498095 },
    { lat: 48.7285863, lng: 18.4500111 },
    { lat: 48.727813, lng: 18.4517322 },
    { lat: 48.7275686, lng: 18.4534546 },
    { lat: 48.7275096, lng: 18.4535392 },
    { lat: 48.7269207, lng: 18.4545291 },
    { lat: 48.7265961, lng: 18.4550853 },
    { lat: 48.7260659, lng: 18.4560655 },
    { lat: 48.7254699, lng: 18.4573263 },
    { lat: 48.7255336, lng: 18.4577649 },
    { lat: 48.7254682, lng: 18.4578846 },
    { lat: 48.725338, lng: 18.4580397 },
    { lat: 48.725207, lng: 18.4583435 },
    { lat: 48.725119, lng: 18.4584871 },
    { lat: 48.7251883, lng: 18.4586817 },
    { lat: 48.7252899, lng: 18.4587393 },
    { lat: 48.7253941, lng: 18.4588326 },
    { lat: 48.7253984, lng: 18.4592358 },
    { lat: 48.7253876, lng: 18.459529 },
    { lat: 48.7253916, lng: 18.4598673 },
    { lat: 48.7253882, lng: 18.4602364 },
    { lat: 48.7254231, lng: 18.4608058 },
    { lat: 48.7253881, lng: 18.4608689 },
    { lat: 48.7253151, lng: 18.4610574 },
    { lat: 48.7252606, lng: 18.4613169 },
    { lat: 48.7252188, lng: 18.4615398 },
    { lat: 48.7252265, lng: 18.4617178 },
    { lat: 48.7249987, lng: 18.4619034 },
    { lat: 48.7248848, lng: 18.4620067 },
    { lat: 48.7246979, lng: 18.4621582 },
    { lat: 48.7246584, lng: 18.4622189 },
    { lat: 48.7244854, lng: 18.462394 },
    { lat: 48.7244276, lng: 18.4625121 },
    { lat: 48.7242054, lng: 18.4628413 },
    { lat: 48.7240579, lng: 18.4631114 },
    { lat: 48.7243955, lng: 18.4635062 },
    { lat: 48.7242985, lng: 18.4641994 },
    { lat: 48.7242291, lng: 18.4643546 },
    { lat: 48.7241785, lng: 18.4645597 },
    { lat: 48.7241034, lng: 18.4646892 },
    { lat: 48.7240694, lng: 18.4648318 },
    { lat: 48.7240013, lng: 18.464969 },
    { lat: 48.7238822, lng: 18.4651201 },
    { lat: 48.7237526, lng: 18.4652047 },
    { lat: 48.7236992, lng: 18.465196 },
    { lat: 48.7235628, lng: 18.465299 },
    { lat: 48.723512, lng: 18.4653629 },
    { lat: 48.7234737, lng: 18.4654439 },
    { lat: 48.7234286, lng: 18.465467 },
    { lat: 48.7232879, lng: 18.465612 },
    { lat: 48.7232156, lng: 18.4656504 },
    { lat: 48.723163, lng: 18.4656995 },
    { lat: 48.7231071, lng: 18.4658134 },
    { lat: 48.7230583, lng: 18.4658892 },
    { lat: 48.7229279, lng: 18.4659378 },
    { lat: 48.7228714, lng: 18.4658946 },
    { lat: 48.7226018, lng: 18.4659357 },
    { lat: 48.7225069, lng: 18.4659116 },
    { lat: 48.7223898, lng: 18.4659181 },
    { lat: 48.7223069, lng: 18.4658861 },
    { lat: 48.7222149, lng: 18.4658762 },
    { lat: 48.7221018, lng: 18.465913 },
    { lat: 48.7220032, lng: 18.4659647 },
    { lat: 48.7219421, lng: 18.4659195 },
    { lat: 48.7216436, lng: 18.4660486 },
    { lat: 48.7214164, lng: 18.4662066 },
    { lat: 48.7212888, lng: 18.4662634 },
    { lat: 48.7212009, lng: 18.4662688 },
    { lat: 48.721094, lng: 18.4662504 },
    { lat: 48.7210192, lng: 18.4662063 },
    { lat: 48.7209126, lng: 18.4662572 },
    { lat: 48.7208311, lng: 18.4662493 },
    { lat: 48.7207454, lng: 18.466273 },
    { lat: 48.7206682, lng: 18.4662012 },
    { lat: 48.7202281, lng: 18.4660138 },
    { lat: 48.7201883, lng: 18.4660091 },
    { lat: 48.7201061, lng: 18.4661545 },
    { lat: 48.7199424, lng: 18.4661695 },
    { lat: 48.7197657, lng: 18.4662203 },
    { lat: 48.7197202, lng: 18.4662739 },
    { lat: 48.7196246, lng: 18.4662581 },
    { lat: 48.7195583, lng: 18.4662654 },
    { lat: 48.7194511, lng: 18.4662421 },
    { lat: 48.7193506, lng: 18.4662619 },
    { lat: 48.7192629, lng: 18.4662658 },
    { lat: 48.7191609, lng: 18.4662394 },
    { lat: 48.718882, lng: 18.4663167 },
    { lat: 48.7187247, lng: 18.4663883 },
    { lat: 48.7186249, lng: 18.4664602 },
    { lat: 48.7185866, lng: 18.4665015 },
    { lat: 48.718447, lng: 18.4665469 },
    { lat: 48.7183028, lng: 18.4666333 },
    { lat: 48.7182061, lng: 18.4666416 },
    { lat: 48.717923, lng: 18.4665994 },
    { lat: 48.7173222, lng: 18.466405 },
    { lat: 48.7171054, lng: 18.4663788 },
    { lat: 48.7169892, lng: 18.4663806 },
    { lat: 48.7168672, lng: 18.4663152 },
    { lat: 48.7165433, lng: 18.4662506 },
    { lat: 48.7160991, lng: 18.4662148 },
    { lat: 48.7159097, lng: 18.4662151 },
    { lat: 48.7158181, lng: 18.4662556 },
    { lat: 48.7156124, lng: 18.4662887 },
    { lat: 48.7154855, lng: 18.4662975 },
    { lat: 48.7152632, lng: 18.4663995 },
    { lat: 48.715053, lng: 18.4664756 },
    { lat: 48.714737, lng: 18.4665751 },
    { lat: 48.7146452, lng: 18.4665852 },
    { lat: 48.7144142, lng: 18.4666922 },
    { lat: 48.7141818, lng: 18.4667944 },
    { lat: 48.7141214, lng: 18.4668434 },
    { lat: 48.7139988, lng: 18.4668925 },
    { lat: 48.7140012, lng: 18.4669354 },
    { lat: 48.713953, lng: 18.4675148 },
    { lat: 48.7138165, lng: 18.4683743 },
    { lat: 48.7137885, lng: 18.4687843 },
    { lat: 48.7137798, lng: 18.4690372 },
    { lat: 48.7138217, lng: 18.4693681 },
    { lat: 48.7138682, lng: 18.4696591 },
    { lat: 48.7133959, lng: 18.46989 },
    { lat: 48.713441, lng: 18.4702155 },
    { lat: 48.713464, lng: 18.4706109 },
    { lat: 48.7134471, lng: 18.4707755 },
    { lat: 48.7133567, lng: 18.4708759 },
    { lat: 48.713291, lng: 18.4709927 },
    { lat: 48.7132889, lng: 18.4711594 },
    { lat: 48.7132596, lng: 18.4713868 },
    { lat: 48.7131763, lng: 18.4716769 },
    { lat: 48.7131793, lng: 18.4717694 },
    { lat: 48.7125779, lng: 18.4720748 },
    { lat: 48.7126624, lng: 18.4725221 },
    { lat: 48.7127786, lng: 18.4728933 },
    { lat: 48.7128599, lng: 18.4732192 },
    { lat: 48.7129583, lng: 18.4736747 },
    { lat: 48.7129477, lng: 18.4737911 },
    { lat: 48.7129792, lng: 18.4738696 },
    { lat: 48.7128048, lng: 18.4741234 },
    { lat: 48.7131749, lng: 18.4747605 },
    { lat: 48.7135256, lng: 18.4751071 },
    { lat: 48.713619, lng: 18.4752447 },
    { lat: 48.7137246, lng: 18.4753375 },
    { lat: 48.7138679, lng: 18.4754231 },
    { lat: 48.7139919, lng: 18.4754385 },
    { lat: 48.7141623, lng: 18.4755246 },
    { lat: 48.7143385, lng: 18.4756725 },
    { lat: 48.7144057, lng: 18.475828 },
    { lat: 48.7144761, lng: 18.4759384 },
    { lat: 48.7145959, lng: 18.4761855 },
    { lat: 48.7147068, lng: 18.4763756 },
    { lat: 48.7148389, lng: 18.4765036 },
    { lat: 48.7150543, lng: 18.4767273 },
    { lat: 48.7150805, lng: 18.4769376 },
    { lat: 48.7151962, lng: 18.477356 },
    { lat: 48.715379, lng: 18.4777041 },
    { lat: 48.7155891, lng: 18.4780814 },
    { lat: 48.7156648, lng: 18.4781636 },
    { lat: 48.7157941, lng: 18.4781763 },
    { lat: 48.7159152, lng: 18.4781643 },
    { lat: 48.7161749, lng: 18.4782709 },
    { lat: 48.7162885, lng: 18.4783035 },
    { lat: 48.7164049, lng: 18.4783485 },
    { lat: 48.7165785, lng: 18.478367 },
    { lat: 48.7167004, lng: 18.4788797 },
    { lat: 48.7167209, lng: 18.4790296 },
    { lat: 48.7168235, lng: 18.4793779 },
    { lat: 48.7176354, lng: 18.4790403 },
    { lat: 48.7175959, lng: 18.4792039 },
    { lat: 48.7178855, lng: 18.4797105 },
    { lat: 48.7179548, lng: 18.4797411 },
    { lat: 48.7184496, lng: 18.4797549 },
    { lat: 48.7185847, lng: 18.4797279 },
    { lat: 48.7186387, lng: 18.4799319 },
    { lat: 48.7186884, lng: 18.4802415 },
    { lat: 48.7186408, lng: 18.4803178 },
    { lat: 48.7186439, lng: 18.4803732 },
    { lat: 48.7186901, lng: 18.4804735 },
    { lat: 48.7187714, lng: 18.4805165 },
    { lat: 48.7188834, lng: 18.480606 },
    { lat: 48.7191103, lng: 18.4808968 },
    { lat: 48.7191382, lng: 18.480889 },
    { lat: 48.7193241, lng: 18.4810282 },
    { lat: 48.7193294, lng: 18.4810858 },
    { lat: 48.7194511, lng: 18.4811462 },
    { lat: 48.7194253, lng: 18.4812695 },
    { lat: 48.7194509, lng: 18.4817125 },
    { lat: 48.7194875, lng: 18.4820901 },
    { lat: 48.7194883, lng: 18.4822508 },
    { lat: 48.7196934, lng: 18.482429 },
    { lat: 48.7197661, lng: 18.4825216 },
    { lat: 48.7201226, lng: 18.4830545 },
    { lat: 48.7208067, lng: 18.48421 },
    { lat: 48.7200845, lng: 18.4857862 },
    { lat: 48.7197285, lng: 18.4864911 },
    { lat: 48.7196315, lng: 18.4867169 },
    { lat: 48.7194481, lng: 18.4870361 },
    { lat: 48.7193341, lng: 18.4871555 },
    { lat: 48.7192369, lng: 18.487172 },
    { lat: 48.7191403, lng: 18.4871398 },
    { lat: 48.7190409, lng: 18.4869526 },
    { lat: 48.7189709, lng: 18.4867278 },
    { lat: 48.7188323, lng: 18.4863618 },
    { lat: 48.7186837, lng: 18.4859976 },
    { lat: 48.7185161, lng: 18.4856964 },
    { lat: 48.7182043, lng: 18.4853833 },
    { lat: 48.7180779, lng: 18.4853192 },
    { lat: 48.7179739, lng: 18.4852554 },
    { lat: 48.7176793, lng: 18.4852117 },
    { lat: 48.7175382, lng: 18.4849626 },
    { lat: 48.7169118, lng: 18.4851603 },
    { lat: 48.7166426, lng: 18.4852677 },
    { lat: 48.716425, lng: 18.4853726 },
    { lat: 48.7161212, lng: 18.4855743 },
    { lat: 48.715993, lng: 18.4853365 },
    { lat: 48.7155559, lng: 18.4859204 },
    { lat: 48.71537, lng: 18.4862103 },
    { lat: 48.7149673, lng: 18.4867024 },
    { lat: 48.714832, lng: 18.4868901 },
    { lat: 48.7148034, lng: 18.4869026 },
    { lat: 48.714724, lng: 18.4870358 },
    { lat: 48.7144004, lng: 18.4875959 },
    { lat: 48.7143423, lng: 18.4877685 },
    { lat: 48.7143105, lng: 18.4879286 },
    { lat: 48.7142708, lng: 18.4882305 },
    { lat: 48.7141396, lng: 18.4886777 },
    { lat: 48.7140443, lng: 18.488874 },
    { lat: 48.7139759, lng: 18.4890019 },
    { lat: 48.7139031, lng: 18.4891132 },
    { lat: 48.7137419, lng: 18.4892817 },
    { lat: 48.7136469, lng: 18.4896865 },
    { lat: 48.7138636, lng: 18.4897915 },
    { lat: 48.7125933, lng: 18.4920063 },
    { lat: 48.7118539, lng: 18.4931849 },
    { lat: 48.7118375, lng: 18.4932356 },
    { lat: 48.7116485, lng: 18.4931043 },
    { lat: 48.7113532, lng: 18.4929834 },
    { lat: 48.7110497, lng: 18.4927158 },
    { lat: 48.7106845, lng: 18.4924323 },
    { lat: 48.7104433, lng: 18.4923572 },
    { lat: 48.7101104, lng: 18.4922482 },
    { lat: 48.7096659, lng: 18.492268 },
    { lat: 48.7094918, lng: 18.4922016 },
    { lat: 48.7092431, lng: 18.4922739 },
    { lat: 48.7090449, lng: 18.4924052 },
    { lat: 48.7087714, lng: 18.4925381 },
    { lat: 48.7084571, lng: 18.492975 },
    { lat: 48.7082832, lng: 18.4932819 },
    { lat: 48.7079737, lng: 18.4936625 },
    { lat: 48.7077223, lng: 18.4938933 },
    { lat: 48.7076193, lng: 18.4940709 },
    { lat: 48.7075438, lng: 18.494215 },
    { lat: 48.7072719, lng: 18.4947684 },
    { lat: 48.7070883, lng: 18.4949 },
    { lat: 48.7068611, lng: 18.4952811 },
    { lat: 48.7066058, lng: 18.4953338 },
    { lat: 48.7063283, lng: 18.4955822 },
    { lat: 48.7061568, lng: 18.4957044 },
    { lat: 48.7060755, lng: 18.4960925 },
    { lat: 48.7059785, lng: 18.4963585 },
    { lat: 48.7058145, lng: 18.4965355 },
    { lat: 48.705643, lng: 18.4967006 },
    { lat: 48.7053468, lng: 18.4970585 },
    { lat: 48.7050726, lng: 18.4973032 },
    { lat: 48.7046701, lng: 18.497553 },
    { lat: 48.7044206, lng: 18.4974864 },
    { lat: 48.7040681, lng: 18.4975599 },
    { lat: 48.7037112, lng: 18.4978607 },
    { lat: 48.703436, lng: 18.4980444 },
    { lat: 48.7031503, lng: 18.4982056 },
    { lat: 48.7029308, lng: 18.4984185 },
    { lat: 48.7027626, lng: 18.4987224 },
    { lat: 48.7024998, lng: 18.4992782 },
    { lat: 48.7023959, lng: 18.4995865 },
    { lat: 48.7023854, lng: 18.4995575 },
    { lat: 48.7018589, lng: 18.5013404 },
    { lat: 48.7018422, lng: 18.5013968 },
    { lat: 48.7021596, lng: 18.501857 },
    { lat: 48.7025475, lng: 18.5021398 },
    { lat: 48.703902, lng: 18.5034501 },
    { lat: 48.7044071, lng: 18.5042304 },
    { lat: 48.7049056, lng: 18.5044101 },
    { lat: 48.7050963, lng: 18.5041458 },
    { lat: 48.7073103, lng: 18.5051871 },
    { lat: 48.7079455, lng: 18.5054383 },
    { lat: 48.7083119, lng: 18.5055964 },
    { lat: 48.708666, lng: 18.505986 },
    { lat: 48.7089746, lng: 18.5062677 },
    { lat: 48.7092166, lng: 18.506461 },
    { lat: 48.7094111, lng: 18.5066967 },
    { lat: 48.7096004, lng: 18.5068394 },
    { lat: 48.7099147, lng: 18.5069315 },
    { lat: 48.7102171, lng: 18.5070365 },
    { lat: 48.7110959, lng: 18.5067907 },
    { lat: 48.7112855, lng: 18.5066916 },
    { lat: 48.7114865, lng: 18.5066932 },
    { lat: 48.7115832, lng: 18.506874 },
    { lat: 48.711696, lng: 18.5069738 },
    { lat: 48.7118064, lng: 18.507199 },
    { lat: 48.7119216, lng: 18.5073688 },
    { lat: 48.711941, lng: 18.5074339 },
    { lat: 48.7120044, lng: 18.5075807 },
    { lat: 48.7121161, lng: 18.5077863 },
    { lat: 48.7121885, lng: 18.5079548 },
    { lat: 48.7122371, lng: 18.5081423 },
    { lat: 48.7124193, lng: 18.5085442 },
    { lat: 48.7124564, lng: 18.5087449 },
    { lat: 48.712653, lng: 18.5090207 },
    { lat: 48.7128, lng: 18.5092741 },
    { lat: 48.7128106, lng: 18.509425 },
    { lat: 48.7128539, lng: 18.5094949 },
    { lat: 48.7129953, lng: 18.5096069 },
    { lat: 48.7133211, lng: 18.5099922 },
    { lat: 48.7132675, lng: 18.510281 },
    { lat: 48.7132209, lng: 18.510532 },
    { lat: 48.7130533, lng: 18.5109548 },
    { lat: 48.7137075, lng: 18.5117945 },
    { lat: 48.7132363, lng: 18.5125435 },
    { lat: 48.7134488, lng: 18.5128802 },
    { lat: 48.7136001, lng: 18.5131703 },
    { lat: 48.713857, lng: 18.5135578 },
    { lat: 48.713967, lng: 18.5136498 },
    { lat: 48.7140975, lng: 18.5132316 },
    { lat: 48.7142046, lng: 18.513045 },
    { lat: 48.7143201, lng: 18.5128284 },
    { lat: 48.7151052, lng: 18.5135678 },
    { lat: 48.7155502, lng: 18.5147317 },
    { lat: 48.7156432, lng: 18.5149665 },
    { lat: 48.7163419, lng: 18.5150777 },
    { lat: 48.7165028, lng: 18.5150519 },
    { lat: 48.7165723, lng: 18.5149607 },
    { lat: 48.7167481, lng: 18.5149387 },
    { lat: 48.7169097, lng: 18.5150657 },
    { lat: 48.7170989, lng: 18.5150806 },
    { lat: 48.717273, lng: 18.5151903 },
    { lat: 48.7174793, lng: 18.5150859 },
    { lat: 48.7176381, lng: 18.5150592 },
    { lat: 48.7177157, lng: 18.514997 },
    { lat: 48.7178659, lng: 18.5149368 },
    { lat: 48.7179646, lng: 18.5147626 },
    { lat: 48.7181417, lng: 18.5146565 },
    { lat: 48.7183372, lng: 18.5151401 },
    { lat: 48.7185126, lng: 18.5151871 },
    { lat: 48.718545, lng: 18.5152659 },
    { lat: 48.7188802, lng: 18.5155387 },
    { lat: 48.7195478, lng: 18.5162347 },
    { lat: 48.7193166, lng: 18.5167909 },
    { lat: 48.7199593, lng: 18.5173825 },
    { lat: 48.7201296, lng: 18.5169925 },
    { lat: 48.7202438, lng: 18.5170783 },
    { lat: 48.7204102, lng: 18.5166981 },
    { lat: 48.7206059, lng: 18.5169137 },
    { lat: 48.7209604, lng: 18.5172105 },
    { lat: 48.7212536, lng: 18.5175361 },
    { lat: 48.721466, lng: 18.5178336 },
    { lat: 48.7218387, lng: 18.5186044 },
    { lat: 48.7221964, lng: 18.5192849 },
    { lat: 48.7226074, lng: 18.5202427 },
    { lat: 48.7229182, lng: 18.5211199 },
    { lat: 48.7230315, lng: 18.5213976 },
    { lat: 48.7230529, lng: 18.5214375 },
    { lat: 48.7235214, lng: 18.5220602 },
    { lat: 48.724054, lng: 18.5222302 },
    { lat: 48.7251182, lng: 18.5219504 },
    { lat: 48.7255493, lng: 18.522222 },
    { lat: 48.7258386, lng: 18.5225203 },
    { lat: 48.7261508, lng: 18.522766 },
    { lat: 48.7264386, lng: 18.5228491 },
    { lat: 48.7267075, lng: 18.5229001 },
    { lat: 48.7270319, lng: 18.5228492 },
    { lat: 48.7274662, lng: 18.5227868 },
    { lat: 48.7278449, lng: 18.5227602 },
    { lat: 48.7280946, lng: 18.522729 },
    { lat: 48.7281819, lng: 18.5225477 },
    { lat: 48.7283194, lng: 18.5219899 },
    { lat: 48.7284152, lng: 18.5218214 },
    { lat: 48.7285704, lng: 18.5217334 },
    { lat: 48.7289982, lng: 18.5218197 },
    { lat: 48.7294638, lng: 18.5217958 },
    { lat: 48.7299704, lng: 18.5217375 },
    { lat: 48.7306194, lng: 18.5220291 },
    { lat: 48.7317414, lng: 18.5221665 },
    { lat: 48.7319827, lng: 18.522046 },
    { lat: 48.7319899, lng: 18.5214119 },
    { lat: 48.732011, lng: 18.5212013 },
    { lat: 48.7319536, lng: 18.5209558 },
    { lat: 48.7318554, lng: 18.5207062 },
    { lat: 48.731746, lng: 18.520479 },
    { lat: 48.7316206, lng: 18.5200231 },
    { lat: 48.7314661, lng: 18.5201063 },
    { lat: 48.7312637, lng: 18.5202385 },
    { lat: 48.7309858, lng: 18.5204601 },
    { lat: 48.730995, lng: 18.518607 },
    { lat: 48.7311372, lng: 18.5165064 },
    { lat: 48.7312297, lng: 18.5158024 },
    { lat: 48.7312743, lng: 18.5154041 },
    { lat: 48.7313312, lng: 18.5151222 },
    { lat: 48.7314089, lng: 18.5149363 },
    { lat: 48.731447, lng: 18.5148065 },
    { lat: 48.7315595, lng: 18.5138141 },
    { lat: 48.7317296, lng: 18.5120812 },
    { lat: 48.7318572, lng: 18.5104366 },
    { lat: 48.7321423, lng: 18.5084134 },
    { lat: 48.7325758, lng: 18.5062348 },
    { lat: 48.7329407, lng: 18.5041073 },
    { lat: 48.7331182, lng: 18.5029384 },
    { lat: 48.7332298, lng: 18.5029179 },
    { lat: 48.7335851, lng: 18.5004928 },
    { lat: 48.733625, lng: 18.5002327 },
    { lat: 48.7339003, lng: 18.4985251 },
    { lat: 48.7341555, lng: 18.4969472 },
    { lat: 48.7342359, lng: 18.4964618 },
    { lat: 48.7342471, lng: 18.4963782 },
    { lat: 48.7346966, lng: 18.4933283 },
    { lat: 48.7350505, lng: 18.491371 },
    { lat: 48.7353774, lng: 18.489744 },
    { lat: 48.7354917, lng: 18.4892565 },
    { lat: 48.735543, lng: 18.4889395 },
    { lat: 48.7355833, lng: 18.4887682 },
    { lat: 48.7357235, lng: 18.4883039 },
    { lat: 48.7357673, lng: 18.4880354 },
    { lat: 48.7359148, lng: 18.4872089 },
    { lat: 48.7362112, lng: 18.485933 },
    { lat: 48.7367135, lng: 18.4846041 },
    { lat: 48.736861, lng: 18.4844369 },
    { lat: 48.7369741, lng: 18.4842361 },
    { lat: 48.7371094, lng: 18.4839067 },
    { lat: 48.7372273, lng: 18.4834852 },
    { lat: 48.7374069, lng: 18.482754 },
    { lat: 48.7375791, lng: 18.4817581 },
    { lat: 48.7382074, lng: 18.480058 },
    { lat: 48.7384672, lng: 18.479204 },
    { lat: 48.7390068, lng: 18.4783688 },
    { lat: 48.7393212, lng: 18.4778318 },
    { lat: 48.7402664, lng: 18.476478 },
    { lat: 48.7408155, lng: 18.4757784 },
    { lat: 48.7408408, lng: 18.4757438 },
    { lat: 48.7410235, lng: 18.4753952 },
    { lat: 48.7411904, lng: 18.4751905 },
    { lat: 48.7420941, lng: 18.4743104 },
    { lat: 48.7426206, lng: 18.473689 },
    { lat: 48.7431051, lng: 18.4728635 },
    { lat: 48.7434148, lng: 18.4724442 },
    { lat: 48.7435954, lng: 18.4719936 },
    { lat: 48.744127, lng: 18.4707499 },
    { lat: 48.7443623, lng: 18.4704772 },
    { lat: 48.7445973, lng: 18.4698686 },
    { lat: 48.7452082, lng: 18.468571 },
    { lat: 48.745646, lng: 18.4680147 },
    { lat: 48.7461433, lng: 18.4673827 },
    { lat: 48.7462034, lng: 18.4672899 },
    { lat: 48.747666, lng: 18.4653663 },
    { lat: 48.7479448, lng: 18.4651061 },
    { lat: 48.7487246, lng: 18.4647275 },
    { lat: 48.7492393, lng: 18.4642018 },
    { lat: 48.7496781, lng: 18.4636899 },
    { lat: 48.7497016, lng: 18.4636498 },
    { lat: 48.7498486, lng: 18.4633871 },
    { lat: 48.7502661, lng: 18.4618369 },
    { lat: 48.7504764, lng: 18.4610039 },
    { lat: 48.7506604, lng: 18.460354 },
    { lat: 48.7508206, lng: 18.4597014 },
    { lat: 48.7510246, lng: 18.4589393 },
    { lat: 48.7511924, lng: 18.4582789 },
    { lat: 48.7514132, lng: 18.4574367 },
    { lat: 48.7516902, lng: 18.4564086 },
    { lat: 48.7517979, lng: 18.4559488 },
    { lat: 48.7521411, lng: 18.4551991 },
    { lat: 48.7525722, lng: 18.4543508 },
    { lat: 48.7526255, lng: 18.4542459 },
    { lat: 48.7528349, lng: 18.453932 },
    { lat: 48.7533954, lng: 18.4531712 },
    { lat: 48.7537757, lng: 18.4527038 },
    { lat: 48.7542837, lng: 18.4517541 },
    { lat: 48.7550288, lng: 18.450026 },
    { lat: 48.7559079, lng: 18.4488755 },
    { lat: 48.7567059, lng: 18.4477196 },
    { lat: 48.7572971, lng: 18.4468056 },
    { lat: 48.757707, lng: 18.4461985 },
    { lat: 48.7585623, lng: 18.4450999 },
    { lat: 48.7593577, lng: 18.4441043 },
    { lat: 48.7609654, lng: 18.4419253 },
    { lat: 48.7619927, lng: 18.440494 },
    { lat: 48.7622011, lng: 18.440445 },
    { lat: 48.7623682, lng: 18.4402869 },
    { lat: 48.7634886, lng: 18.4392514 },
    { lat: 48.7637267, lng: 18.4390253 },
    { lat: 48.7644718, lng: 18.4386917 },
    { lat: 48.7648854, lng: 18.4374989 },
    { lat: 48.7649561, lng: 18.4371499 },
    { lat: 48.7652446, lng: 18.4365548 },
    { lat: 48.765667, lng: 18.43519 },
    { lat: 48.765198, lng: 18.43467 },
    { lat: 48.765048, lng: 18.434666 },
    { lat: 48.764689, lng: 18.434588 },
    { lat: 48.764409, lng: 18.43436 },
    { lat: 48.764091, lng: 18.4342 },
    { lat: 48.763964, lng: 18.434037 },
    { lat: 48.763452, lng: 18.434093 },
    { lat: 48.763007, lng: 18.433589 },
    { lat: 48.762727, lng: 18.433356 },
    { lat: 48.762623, lng: 18.433287 },
    { lat: 48.762384, lng: 18.433008 },
    { lat: 48.761841, lng: 18.432657 },
    { lat: 48.761407, lng: 18.431791 },
    { lat: 48.760849, lng: 18.431753 },
    { lat: 48.760293, lng: 18.431723 },
    { lat: 48.759662, lng: 18.431751 },
    { lat: 48.759377, lng: 18.431796 },
    { lat: 48.759116, lng: 18.431854 },
    { lat: 48.758219, lng: 18.431922 },
    { lat: 48.757846, lng: 18.431818 },
    { lat: 48.757468, lng: 18.431536 },
    { lat: 48.757296, lng: 18.431424 },
    { lat: 48.756678, lng: 18.431334 },
    { lat: 48.755808, lng: 18.431451 },
    { lat: 48.755241, lng: 18.431499 },
    { lat: 48.754569, lng: 18.431784 },
    { lat: 48.75432, lng: 18.431754 },
    { lat: 48.753787, lng: 18.431585 },
    { lat: 48.75375, lng: 18.431566 },
    { lat: 48.753452, lng: 18.431414 },
    { lat: 48.75303, lng: 18.431394 },
    { lat: 48.752636, lng: 18.431262 },
    { lat: 48.752279, lng: 18.431434 },
    { lat: 48.751739, lng: 18.431911 },
    { lat: 48.751491, lng: 18.430974 },
    { lat: 48.751414, lng: 18.430235 },
    { lat: 48.751354, lng: 18.429588 },
    { lat: 48.7509406, lng: 18.4288849 },
    { lat: 48.750806, lng: 18.428656 },
    { lat: 48.750258, lng: 18.428075 },
    { lat: 48.749849, lng: 18.427699 },
    { lat: 48.749723, lng: 18.427279 },
    { lat: 48.749445, lng: 18.426732 },
    { lat: 48.749133, lng: 18.42643 },
    { lat: 48.748773, lng: 18.426409 },
    { lat: 48.748134, lng: 18.426369 },
    { lat: 48.747598, lng: 18.426569 },
    { lat: 48.74723, lng: 18.426405 },
    { lat: 48.7469, lng: 18.426438 },
    { lat: 48.746597, lng: 18.426077 },
    { lat: 48.746519, lng: 18.425646 },
    { lat: 48.746388, lng: 18.424995 },
    { lat: 48.746044, lng: 18.424143 },
    { lat: 48.745973, lng: 18.423705 },
    { lat: 48.745884, lng: 18.423367 },
  ],
  Nováky: [
    { lat: 48.7132675, lng: 18.510281 },
    { lat: 48.7127954, lng: 18.510407 },
    { lat: 48.7125885, lng: 18.5105168 },
    { lat: 48.7120203, lng: 18.5107301 },
    { lat: 48.7114318, lng: 18.5111502 },
    { lat: 48.711172, lng: 18.5114299 },
    { lat: 48.7107911, lng: 18.5118079 },
    { lat: 48.7106328, lng: 18.512007 },
    { lat: 48.7103881, lng: 18.5124634 },
    { lat: 48.7101071, lng: 18.5127278 },
    { lat: 48.7091653, lng: 18.5133056 },
    { lat: 48.7088194, lng: 18.513355 },
    { lat: 48.7085766, lng: 18.5134321 },
    { lat: 48.7080449, lng: 18.513814 },
    { lat: 48.7075593, lng: 18.5137447 },
    { lat: 48.7072805, lng: 18.5138021 },
    { lat: 48.7072056, lng: 18.5155032 },
    { lat: 48.7064589, lng: 18.5176873 },
    { lat: 48.7065261, lng: 18.5179489 },
    { lat: 48.7060817, lng: 18.5199047 },
    { lat: 48.7059231, lng: 18.5203063 },
    { lat: 48.7058031, lng: 18.520731 },
    { lat: 48.7054319, lng: 18.5205648 },
    { lat: 48.7050577, lng: 18.5219904 },
    { lat: 48.7048247, lng: 18.5219996 },
    { lat: 48.70482, lng: 18.5220794 },
    { lat: 48.7047862, lng: 18.5223686 },
    { lat: 48.7047816, lng: 18.5224528 },
    { lat: 48.7037219, lng: 18.5223401 },
    { lat: 48.7017262, lng: 18.5222697 },
    { lat: 48.7016966, lng: 18.522888 },
    { lat: 48.7013038, lng: 18.5234598 },
    { lat: 48.7012115, lng: 18.5233578 },
    { lat: 48.7011753, lng: 18.5233344 },
    { lat: 48.7010273, lng: 18.5233518 },
    { lat: 48.700874, lng: 18.5234585 },
    { lat: 48.7004714, lng: 18.5245107 },
    { lat: 48.7004235, lng: 18.5246035 },
    { lat: 48.7002808, lng: 18.5247995 },
    { lat: 48.700173, lng: 18.5249104 },
    { lat: 48.6998247, lng: 18.525146 },
    { lat: 48.6996, lng: 18.5253248 },
    { lat: 48.6994296, lng: 18.5255938 },
    { lat: 48.6988044, lng: 18.5267235 },
    { lat: 48.6991605, lng: 18.5273122 },
    { lat: 48.7002551, lng: 18.5290978 },
    { lat: 48.7002024, lng: 18.5297639 },
    { lat: 48.7001582, lng: 18.5303786 },
    { lat: 48.7000709, lng: 18.5316325 },
    { lat: 48.6999859, lng: 18.531626 },
    { lat: 48.6998392, lng: 18.5337682 },
    { lat: 48.6998327, lng: 18.533798 },
    { lat: 48.6998413, lng: 18.5340144 },
    { lat: 48.6998323, lng: 18.5341797 },
    { lat: 48.6998087, lng: 18.5343269 },
    { lat: 48.6997801, lng: 18.5346912 },
    { lat: 48.6998222, lng: 18.5346991 },
    { lat: 48.6996446, lng: 18.5352487 },
    { lat: 48.6995236, lng: 18.5355733 },
    { lat: 48.6994275, lng: 18.535731 },
    { lat: 48.6993683, lng: 18.536344 },
    { lat: 48.69928, lng: 18.5367485 },
    { lat: 48.6991978, lng: 18.537144 },
    { lat: 48.6991172, lng: 18.5376111 },
    { lat: 48.6990758, lng: 18.5379266 },
    { lat: 48.6989999, lng: 18.5384381 },
    { lat: 48.6988857, lng: 18.539901 },
    { lat: 48.6988295, lng: 18.5404446 },
    { lat: 48.6987306, lng: 18.5412343 },
    { lat: 48.6986928, lng: 18.5416593 },
    { lat: 48.6986245, lng: 18.5422448 },
    { lat: 48.6986046, lng: 18.5424394 },
    { lat: 48.6986045, lng: 18.5428326 },
    { lat: 48.6984203, lng: 18.5434254 },
    { lat: 48.6982462, lng: 18.5436052 },
    { lat: 48.6980136, lng: 18.5437038 },
    { lat: 48.6979249, lng: 18.5439376 },
    { lat: 48.69779, lng: 18.5441337 },
    { lat: 48.6976901, lng: 18.5443686 },
    { lat: 48.6975282, lng: 18.5445251 },
    { lat: 48.6975113, lng: 18.5447304 },
    { lat: 48.6973939, lng: 18.5450399 },
    { lat: 48.6973992, lng: 18.5451164 },
    { lat: 48.6973454, lng: 18.5453091 },
    { lat: 48.697363, lng: 18.5454276 },
    { lat: 48.6973348, lng: 18.5454657 },
    { lat: 48.6973581, lng: 18.5455414 },
    { lat: 48.6972916, lng: 18.5456871 },
    { lat: 48.6973064, lng: 18.5457124 },
    { lat: 48.6972924, lng: 18.5457242 },
    { lat: 48.6972294, lng: 18.5457256 },
    { lat: 48.6971791, lng: 18.5457965 },
    { lat: 48.6971863, lng: 18.5458666 },
    { lat: 48.6970758, lng: 18.5460732 },
    { lat: 48.697079, lng: 18.5461967 },
    { lat: 48.6970343, lng: 18.5462447 },
    { lat: 48.6969341, lng: 18.5462486 },
    { lat: 48.6968169, lng: 18.5462725 },
    { lat: 48.6967469, lng: 18.5463098 },
    { lat: 48.6967143, lng: 18.5463756 },
    { lat: 48.6966156, lng: 18.5464296 },
    { lat: 48.6965706, lng: 18.5464099 },
    { lat: 48.6964203, lng: 18.54661 },
    { lat: 48.6963551, lng: 18.5466377 },
    { lat: 48.6963123, lng: 18.5468023 },
    { lat: 48.6962381, lng: 18.5468665 },
    { lat: 48.6962038, lng: 18.5470654 },
    { lat: 48.696276, lng: 18.5472767 },
    { lat: 48.696268, lng: 18.5474591 },
    { lat: 48.6962124, lng: 18.5475577 },
    { lat: 48.6962866, lng: 18.5478009 },
    { lat: 48.6962702, lng: 18.5478733 },
    { lat: 48.696367, lng: 18.5480966 },
    { lat: 48.6962762, lng: 18.5483939 },
    { lat: 48.6963486, lng: 18.5488941 },
    { lat: 48.6964393, lng: 18.5489892 },
    { lat: 48.6964028, lng: 18.5493515 },
    { lat: 48.6964825, lng: 18.5497168 },
    { lat: 48.6966657, lng: 18.5500393 },
    { lat: 48.6968089, lng: 18.5502036 },
    { lat: 48.696885, lng: 18.5502335 },
    { lat: 48.6969329, lng: 18.550305 },
    { lat: 48.6969419, lng: 18.5504492 },
    { lat: 48.6969166, lng: 18.550523 },
    { lat: 48.6969344, lng: 18.550624 },
    { lat: 48.6969813, lng: 18.5511106 },
    { lat: 48.6968917, lng: 18.5515366 },
    { lat: 48.6969951, lng: 18.5517773 },
    { lat: 48.6969434, lng: 18.5518869 },
    { lat: 48.6969316, lng: 18.5520608 },
    { lat: 48.6970472, lng: 18.5523424 },
    { lat: 48.6970575, lng: 18.5524501 },
    { lat: 48.6972221, lng: 18.552756 },
    { lat: 48.6972098, lng: 18.5528414 },
    { lat: 48.6972393, lng: 18.5530541 },
    { lat: 48.6973231, lng: 18.5532033 },
    { lat: 48.6972404, lng: 18.5534646 },
    { lat: 48.6972882, lng: 18.5537007 },
    { lat: 48.6973725, lng: 18.5538631 },
    { lat: 48.6973961, lng: 18.5539844 },
    { lat: 48.697344, lng: 18.5543947 },
    { lat: 48.697287, lng: 18.5544911 },
    { lat: 48.6972791, lng: 18.5545934 },
    { lat: 48.6972408, lng: 18.5546757 },
    { lat: 48.6971554, lng: 18.5550348 },
    { lat: 48.6970812, lng: 18.5551211 },
    { lat: 48.6969274, lng: 18.5554652 },
    { lat: 48.6969096, lng: 18.555638 },
    { lat: 48.6967394, lng: 18.5559516 },
    { lat: 48.6966883, lng: 18.5561708 },
    { lat: 48.6965424, lng: 18.5563535 },
    { lat: 48.6964192, lng: 18.5564523 },
    { lat: 48.6962855, lng: 18.5564614 },
    { lat: 48.6961543, lng: 18.5565359 },
    { lat: 48.6959616, lng: 18.5567909 },
    { lat: 48.6958146, lng: 18.5568652 },
    { lat: 48.6957567, lng: 18.5570437 },
    { lat: 48.6957714, lng: 18.5571262 },
    { lat: 48.6956575, lng: 18.5573363 },
    { lat: 48.6956265, lng: 18.5573038 },
    { lat: 48.6953929, lng: 18.557407 },
    { lat: 48.6952695, lng: 18.5575413 },
    { lat: 48.6952242, lng: 18.5579782 },
    { lat: 48.6951226, lng: 18.5579961 },
    { lat: 48.6949802, lng: 18.5580945 },
    { lat: 48.6948025, lng: 18.5586646 },
    { lat: 48.6947439, lng: 18.5587904 },
    { lat: 48.694715, lng: 18.5589029 },
    { lat: 48.694605, lng: 18.5591569 },
    { lat: 48.6945175, lng: 18.5592906 },
    { lat: 48.6944287, lng: 18.5593612 },
    { lat: 48.6942515, lng: 18.559667 },
    { lat: 48.6942017, lng: 18.5598117 },
    { lat: 48.6941559, lng: 18.5598821 },
    { lat: 48.6940079, lng: 18.5599608 },
    { lat: 48.6939847, lng: 18.5600111 },
    { lat: 48.693902, lng: 18.560046 },
    { lat: 48.693876, lng: 18.5601071 },
    { lat: 48.6938284, lng: 18.5601458 },
    { lat: 48.6936862, lng: 18.5603288 },
    { lat: 48.6935855, lng: 18.5605516 },
    { lat: 48.693501, lng: 18.560636 },
    { lat: 48.693377, lng: 18.5608245 },
    { lat: 48.6932094, lng: 18.560981 },
    { lat: 48.6931097, lng: 18.5611772 },
    { lat: 48.6931402, lng: 18.5612209 },
    { lat: 48.6931179, lng: 18.5612662 },
    { lat: 48.6924741, lng: 18.5627456 },
    { lat: 48.6924496, lng: 18.5627911 },
    { lat: 48.6920629, lng: 18.5633339 },
    { lat: 48.6919795, lng: 18.5634396 },
    { lat: 48.6916889, lng: 18.5637801 },
    { lat: 48.6915638, lng: 18.5640488 },
    { lat: 48.6913633, lng: 18.5644696 },
    { lat: 48.6909717, lng: 18.5651536 },
    { lat: 48.690998, lng: 18.5659251 },
    { lat: 48.6909245, lng: 18.5661123 },
    { lat: 48.690857, lng: 18.566714 },
    { lat: 48.6907757, lng: 18.5668584 },
    { lat: 48.6906628, lng: 18.5669209 },
    { lat: 48.6903912, lng: 18.5670285 },
    { lat: 48.6902392, lng: 18.5669277 },
    { lat: 48.6901239, lng: 18.5667806 },
    { lat: 48.6900826, lng: 18.5667486 },
    { lat: 48.6900223, lng: 18.5667379 },
    { lat: 48.6896908, lng: 18.5666362 },
    { lat: 48.6894483, lng: 18.5666148 },
    { lat: 48.6893897, lng: 18.5666974 },
    { lat: 48.689336, lng: 18.566683 },
    { lat: 48.6891865, lng: 18.5667779 },
    { lat: 48.6890267, lng: 18.566807 },
    { lat: 48.6890654, lng: 18.5673509 },
    { lat: 48.6890111, lng: 18.5674348 },
    { lat: 48.6884697, lng: 18.5676193 },
    { lat: 48.6884112, lng: 18.5675159 },
    { lat: 48.6881868, lng: 18.5675794 },
    { lat: 48.6879631, lng: 18.567701 },
    { lat: 48.6878566, lng: 18.5679409 },
    { lat: 48.6877814, lng: 18.5683201 },
    { lat: 48.6875136, lng: 18.5687677 },
    { lat: 48.6872233, lng: 18.5692417 },
    { lat: 48.6871449, lng: 18.5694985 },
    { lat: 48.6869777, lng: 18.5697619 },
    { lat: 48.6867421, lng: 18.5701206 },
    { lat: 48.6864967, lng: 18.5703773 },
    { lat: 48.6860918, lng: 18.5707948 },
    { lat: 48.686126, lng: 18.5709234 },
    { lat: 48.6864545, lng: 18.5721981 },
    { lat: 48.6865011, lng: 18.5725775 },
    { lat: 48.6865789, lng: 18.5729302 },
    { lat: 48.6867635, lng: 18.5734836 },
    { lat: 48.6865112, lng: 18.5736993 },
    { lat: 48.6865242, lng: 18.5737527 },
    { lat: 48.6858773, lng: 18.5750065 },
    { lat: 48.686239, lng: 18.5756047 },
    { lat: 48.6864951, lng: 18.5754416 },
    { lat: 48.686645, lng: 18.5755426 },
    { lat: 48.6867912, lng: 18.5754503 },
    { lat: 48.6868389, lng: 18.5755404 },
    { lat: 48.6870376, lng: 18.5757921 },
    { lat: 48.6871777, lng: 18.5760868 },
    { lat: 48.6872703, lng: 18.5762167 },
    { lat: 48.6873772, lng: 18.5763355 },
    { lat: 48.6874731, lng: 18.57646 },
    { lat: 48.6875298, lng: 18.5765683 },
    { lat: 48.6876584, lng: 18.5768384 },
    { lat: 48.6878051, lng: 18.5773389 },
    { lat: 48.6880379, lng: 18.5776171 },
    { lat: 48.6884668, lng: 18.5781216 },
    { lat: 48.6888243, lng: 18.57862 },
    { lat: 48.6890979, lng: 18.5791113 },
    { lat: 48.6898966, lng: 18.5785709 },
    { lat: 48.6904232, lng: 18.5782187 },
    { lat: 48.6915172, lng: 18.5797328 },
    { lat: 48.6916376, lng: 18.5795379 },
    { lat: 48.691686, lng: 18.5796208 },
    { lat: 48.6917604, lng: 18.579702 },
    { lat: 48.6919127, lng: 18.5798916 },
    { lat: 48.6919421, lng: 18.5798919 },
    { lat: 48.6921255, lng: 18.5798235 },
    { lat: 48.6923817, lng: 18.5794916 },
    { lat: 48.6925776, lng: 18.5792771 },
    { lat: 48.6927794, lng: 18.5791928 },
    { lat: 48.6931338, lng: 18.5788859 },
    { lat: 48.6929523, lng: 18.5782835 },
    { lat: 48.6929401, lng: 18.5781815 },
    { lat: 48.6932697, lng: 18.5771675 },
    { lat: 48.693512, lng: 18.5766462 },
    { lat: 48.6941571, lng: 18.5762276 },
    { lat: 48.6944571, lng: 18.5765392 },
    { lat: 48.6952352, lng: 18.5773237 },
    { lat: 48.6954253, lng: 18.5774413 },
    { lat: 48.6955946, lng: 18.5769858 },
    { lat: 48.6955021, lng: 18.5765884 },
    { lat: 48.6955067, lng: 18.5765293 },
    { lat: 48.6960672, lng: 18.576486 },
    { lat: 48.6961703, lng: 18.5762226 },
    { lat: 48.6962594, lng: 18.5758074 },
    { lat: 48.6963805, lng: 18.5755874 },
    { lat: 48.6964372, lng: 18.5755686 },
    { lat: 48.6965487, lng: 18.5756505 },
    { lat: 48.6966846, lng: 18.5757847 },
    { lat: 48.6967946, lng: 18.5759436 },
    { lat: 48.6970117, lng: 18.5759506 },
    { lat: 48.6971123, lng: 18.5760185 },
    { lat: 48.6972159, lng: 18.5760557 },
    { lat: 48.6972692, lng: 18.5761044 },
    { lat: 48.6973372, lng: 18.5761306 },
    { lat: 48.6973999, lng: 18.5762268 },
    { lat: 48.6975831, lng: 18.5764015 },
    { lat: 48.6976524, lng: 18.5764108 },
    { lat: 48.6977124, lng: 18.5765373 },
    { lat: 48.6978264, lng: 18.5766439 },
    { lat: 48.6982139, lng: 18.5769062 },
    { lat: 48.6984333, lng: 18.5769983 },
    { lat: 48.6986679, lng: 18.5767918 },
    { lat: 48.69889, lng: 18.5766868 },
    { lat: 48.6989791, lng: 18.5767243 },
    { lat: 48.6990406, lng: 18.5768613 },
    { lat: 48.6992152, lng: 18.5767726 },
    { lat: 48.6992764, lng: 18.5769039 },
    { lat: 48.6994421, lng: 18.576882 },
    { lat: 48.6994393, lng: 18.577017 },
    { lat: 48.6995491, lng: 18.5770237 },
    { lat: 48.6997147, lng: 18.5767704 },
    { lat: 48.6998124, lng: 18.5768069 },
    { lat: 48.6999122, lng: 18.5769809 },
    { lat: 48.7000081, lng: 18.5769415 },
    { lat: 48.700064, lng: 18.5769953 },
    { lat: 48.7002916, lng: 18.5769682 },
    { lat: 48.7005044, lng: 18.5768576 },
    { lat: 48.7006193, lng: 18.5769187 },
    { lat: 48.7008268, lng: 18.5768959 },
    { lat: 48.700923, lng: 18.5767133 },
    { lat: 48.7009329, lng: 18.5766482 },
    { lat: 48.7010517, lng: 18.5763839 },
    { lat: 48.7010973, lng: 18.5762272 },
    { lat: 48.7013442, lng: 18.5759342 },
    { lat: 48.7015215, lng: 18.5757515 },
    { lat: 48.7017069, lng: 18.5755918 },
    { lat: 48.7018231, lng: 18.57532 },
    { lat: 48.7019227, lng: 18.575017 },
    { lat: 48.7021248, lng: 18.5749337 },
    { lat: 48.7023633, lng: 18.5745078 },
    { lat: 48.7026623, lng: 18.573928 },
    { lat: 48.7029445, lng: 18.573827 },
    { lat: 48.7032507, lng: 18.5737766 },
    { lat: 48.7033082, lng: 18.5737341 },
    { lat: 48.7035884, lng: 18.5739505 },
    { lat: 48.7040508, lng: 18.5742996 },
    { lat: 48.7042252, lng: 18.5743133 },
    { lat: 48.7043829, lng: 18.5740831 },
    { lat: 48.7045339, lng: 18.5739941 },
    { lat: 48.7045766, lng: 18.5740145 },
    { lat: 48.7046662, lng: 18.5739588 },
    { lat: 48.7048156, lng: 18.5739285 },
    { lat: 48.7049459, lng: 18.5736463 },
    { lat: 48.7050839, lng: 18.5737595 },
    { lat: 48.7052418, lng: 18.5736771 },
    { lat: 48.7052973, lng: 18.5734935 },
    { lat: 48.7054965, lng: 18.5734442 },
    { lat: 48.7055582, lng: 18.5734183 },
    { lat: 48.7057989, lng: 18.5733407 },
    { lat: 48.7058753, lng: 18.5733544 },
    { lat: 48.7060182, lng: 18.5732653 },
    { lat: 48.7061976, lng: 18.5732481 },
    { lat: 48.7062143, lng: 18.573286 },
    { lat: 48.7065563, lng: 18.573495 },
    { lat: 48.7065169, lng: 18.5737063 },
    { lat: 48.7066033, lng: 18.5740299 },
    { lat: 48.7063002, lng: 18.5748881 },
    { lat: 48.7065721, lng: 18.5750376 },
    { lat: 48.7069589, lng: 18.5745548 },
    { lat: 48.7072296, lng: 18.5740998 },
    { lat: 48.7073006, lng: 18.5738875 },
    { lat: 48.7074701, lng: 18.573439 },
    { lat: 48.7077059, lng: 18.5733148 },
    { lat: 48.7077591, lng: 18.5733386 },
    { lat: 48.7077732, lng: 18.5732665 },
    { lat: 48.7078647, lng: 18.5732069 },
    { lat: 48.7079633, lng: 18.5729233 },
    { lat: 48.7080403, lng: 18.5727395 },
    { lat: 48.7080619, lng: 18.5725797 },
    { lat: 48.7081197, lng: 18.5725614 },
    { lat: 48.7081579, lng: 18.5724978 },
    { lat: 48.7082532, lng: 18.5725292 },
    { lat: 48.7084641, lng: 18.5725695 },
    { lat: 48.7085853, lng: 18.5724334 },
    { lat: 48.7086174, lng: 18.5722537 },
    { lat: 48.7088028, lng: 18.5721164 },
    { lat: 48.708828, lng: 18.5720412 },
    { lat: 48.7089101, lng: 18.5719117 },
    { lat: 48.7089676, lng: 18.571665 },
    { lat: 48.7091036, lng: 18.571361 },
    { lat: 48.709178, lng: 18.5713 },
    { lat: 48.7092164, lng: 18.5711759 },
    { lat: 48.709141, lng: 18.5709321 },
    { lat: 48.7091847, lng: 18.570757 },
    { lat: 48.7092524, lng: 18.5706322 },
    { lat: 48.7094302, lng: 18.570502 },
    { lat: 48.7095388, lng: 18.5703392 },
    { lat: 48.7095896, lng: 18.5701115 },
    { lat: 48.7096428, lng: 18.569951 },
    { lat: 48.7099352, lng: 18.5697029 },
    { lat: 48.7099704, lng: 18.5694589 },
    { lat: 48.7099032, lng: 18.569324 },
    { lat: 48.7099605, lng: 18.5690727 },
    { lat: 48.7100706, lng: 18.5689583 },
    { lat: 48.7101642, lng: 18.5687495 },
    { lat: 48.7102772, lng: 18.5684023 },
    { lat: 48.7102578, lng: 18.5682701 },
    { lat: 48.7102802, lng: 18.5682255 },
    { lat: 48.7103014, lng: 18.568225 },
    { lat: 48.710364, lng: 18.5682555 },
    { lat: 48.7104636, lng: 18.568261 },
    { lat: 48.7104961, lng: 18.5681923 },
    { lat: 48.7104539, lng: 18.5681258 },
    { lat: 48.7105305, lng: 18.5678291 },
    { lat: 48.7106429, lng: 18.5675313 },
    { lat: 48.7108193, lng: 18.5674383 },
    { lat: 48.711097, lng: 18.5669835 },
    { lat: 48.71114, lng: 18.5667178 },
    { lat: 48.7111905, lng: 18.5661069 },
    { lat: 48.7113155, lng: 18.5657335 },
    { lat: 48.7113728, lng: 18.5655221 },
    { lat: 48.7114577, lng: 18.5654223 },
    { lat: 48.7116425, lng: 18.565334 },
    { lat: 48.7117338, lng: 18.5652306 },
    { lat: 48.7117987, lng: 18.5651986 },
    { lat: 48.7118785, lng: 18.5651922 },
    { lat: 48.712129, lng: 18.5650087 },
    { lat: 48.7122531, lng: 18.5647622 },
    { lat: 48.7122975, lng: 18.5646427 },
    { lat: 48.7124171, lng: 18.5644997 },
    { lat: 48.7125848, lng: 18.5642456 },
    { lat: 48.7127004, lng: 18.5639402 },
    { lat: 48.7128462, lng: 18.5634492 },
    { lat: 48.7129302, lng: 18.5633763 },
    { lat: 48.7131423, lng: 18.5631471 },
    { lat: 48.7132629, lng: 18.5632093 },
    { lat: 48.7133852, lng: 18.5631556 },
    { lat: 48.7135116, lng: 18.5630324 },
    { lat: 48.713578, lng: 18.5629263 },
    { lat: 48.713623, lng: 18.5627382 },
    { lat: 48.7136954, lng: 18.5626385 },
    { lat: 48.7138997, lng: 18.5631353 },
    { lat: 48.7141334, lng: 18.5634517 },
    { lat: 48.7144077, lng: 18.5637687 },
    { lat: 48.7146252, lng: 18.563995 },
    { lat: 48.7150144, lng: 18.5643278 },
    { lat: 48.7151333, lng: 18.5644206 },
    { lat: 48.7152813, lng: 18.564134 },
    { lat: 48.7154594, lng: 18.5639608 },
    { lat: 48.715614, lng: 18.563883 },
    { lat: 48.7157774, lng: 18.5638363 },
    { lat: 48.7170769, lng: 18.5646502 },
    { lat: 48.7174251, lng: 18.5650996 },
    { lat: 48.7177617, lng: 18.5654397 },
    { lat: 48.7181965, lng: 18.5658848 },
    { lat: 48.7191757, lng: 18.5668632 },
    { lat: 48.7196853, lng: 18.5673822 },
    { lat: 48.7202522, lng: 18.5679549 },
    { lat: 48.7211757, lng: 18.5689038 },
    { lat: 48.7210267, lng: 18.5691724 },
    { lat: 48.7207818, lng: 18.569586 },
    { lat: 48.7199394, lng: 18.5710229 },
    { lat: 48.7190732, lng: 18.5712942 },
    { lat: 48.7188639, lng: 18.5713666 },
    { lat: 48.7181932, lng: 18.5715593 },
    { lat: 48.7178104, lng: 18.5716782 },
    { lat: 48.7172988, lng: 18.5718331 },
    { lat: 48.7169489, lng: 18.5719543 },
    { lat: 48.7165761, lng: 18.5720659 },
    { lat: 48.7163611, lng: 18.5721368 },
    { lat: 48.7156425, lng: 18.5723233 },
    { lat: 48.7154722, lng: 18.5724048 },
    { lat: 48.715287, lng: 18.5724631 },
    { lat: 48.7151041, lng: 18.5725422 },
    { lat: 48.7149219, lng: 18.5726391 },
    { lat: 48.7147406, lng: 18.5727465 },
    { lat: 48.7145655, lng: 18.5728647 },
    { lat: 48.714405, lng: 18.5730053 },
    { lat: 48.7142404, lng: 18.5731735 },
    { lat: 48.7140852, lng: 18.5733496 },
    { lat: 48.7139489, lng: 18.5735379 },
    { lat: 48.7138175, lng: 18.5737381 },
    { lat: 48.7136888, lng: 18.5739637 },
    { lat: 48.7135749, lng: 18.574195 },
    { lat: 48.7134674, lng: 18.5744347 },
    { lat: 48.7133799, lng: 18.5746963 },
    { lat: 48.7133043, lng: 18.5749618 },
    { lat: 48.7132215, lng: 18.5753092 },
    { lat: 48.7131659, lng: 18.5755086 },
    { lat: 48.7131137, lng: 18.5757968 },
    { lat: 48.7131011, lng: 18.5760841 },
    { lat: 48.7130469, lng: 18.5763734 },
    { lat: 48.7130631, lng: 18.576755 },
    { lat: 48.7130601, lng: 18.5769496 },
    { lat: 48.7130443, lng: 18.5772198 },
    { lat: 48.7130394, lng: 18.5774833 },
    { lat: 48.7130689, lng: 18.5780272 },
    { lat: 48.7130717, lng: 18.5788726 },
    { lat: 48.7130764, lng: 18.5789588 },
    { lat: 48.7130642, lng: 18.579021 },
    { lat: 48.7130835, lng: 18.5797129 },
    { lat: 48.71308, lng: 18.5799587 },
    { lat: 48.7130408, lng: 18.580504 },
    { lat: 48.713092, lng: 18.5807641 },
    { lat: 48.7130845, lng: 18.581084 },
    { lat: 48.713096, lng: 18.5813393 },
    { lat: 48.7131412, lng: 18.5816295 },
    { lat: 48.7132324, lng: 18.5820254 },
    { lat: 48.7132793, lng: 18.582141 },
    { lat: 48.7132983, lng: 18.5824258 },
    { lat: 48.713354, lng: 18.5827075 },
    { lat: 48.7134348, lng: 18.5829472 },
    { lat: 48.7136097, lng: 18.5837206 },
    { lat: 48.7136577, lng: 18.5840039 },
    { lat: 48.7137016, lng: 18.5842082 },
    { lat: 48.7138181, lng: 18.5847142 },
    { lat: 48.7138743, lng: 18.5849229 },
    { lat: 48.7141383, lng: 18.5858782 },
    { lat: 48.7143629, lng: 18.5867573 },
    { lat: 48.7144033, lng: 18.586983 },
    { lat: 48.7144158, lng: 18.5871926 },
    { lat: 48.7143918, lng: 18.5876056 },
    { lat: 48.7143689, lng: 18.5878681 },
    { lat: 48.7143271, lng: 18.5880947 },
    { lat: 48.7142577, lng: 18.5883379 },
    { lat: 48.7141711, lng: 18.5885883 },
    { lat: 48.7140783, lng: 18.5888126 },
    { lat: 48.713952, lng: 18.5890036 },
    { lat: 48.7138317, lng: 18.5891738 },
    { lat: 48.7136854, lng: 18.5893705 },
    { lat: 48.7135809, lng: 18.5894805 },
    { lat: 48.7134414, lng: 18.589571 },
    { lat: 48.7132742, lng: 18.5896688 },
    { lat: 48.7133364, lng: 18.5897579 },
    { lat: 48.7133634, lng: 18.5898189 },
    { lat: 48.7134171, lng: 18.589999 },
    { lat: 48.7134394, lng: 18.5900366 },
    { lat: 48.7135311, lng: 18.5901449 },
    { lat: 48.7136678, lng: 18.5900673 },
    { lat: 48.713918, lng: 18.5903322 },
    { lat: 48.714137, lng: 18.5906055 },
    { lat: 48.7144534, lng: 18.5909928 },
    { lat: 48.7149501, lng: 18.5915848 },
    { lat: 48.7152224, lng: 18.5918647 },
    { lat: 48.7152394, lng: 18.5918648 },
    { lat: 48.7155876, lng: 18.5913378 },
    { lat: 48.7156278, lng: 18.5910796 },
    { lat: 48.7157837, lng: 18.5909385 },
    { lat: 48.7159902, lng: 18.5906923 },
    { lat: 48.7161638, lng: 18.5903754 },
    { lat: 48.7162936, lng: 18.5901929 },
    { lat: 48.7163809, lng: 18.5901593 },
    { lat: 48.7164791, lng: 18.5899739 },
    { lat: 48.716522, lng: 18.5898479 },
    { lat: 48.7165356, lng: 18.5898077 },
    { lat: 48.7165629, lng: 18.5897261 },
    { lat: 48.7166172, lng: 18.5896679 },
    { lat: 48.7166921, lng: 18.589594 },
    { lat: 48.7168507, lng: 18.5894369 },
    { lat: 48.7170761, lng: 18.589209 },
    { lat: 48.7172468, lng: 18.5890254 },
    { lat: 48.7172976, lng: 18.5889645 },
    { lat: 48.7173274, lng: 18.5888676 },
    { lat: 48.7173952, lng: 18.5886413 },
    { lat: 48.7176875, lng: 18.5882466 },
    { lat: 48.7177597, lng: 18.5882266 },
    { lat: 48.7178766, lng: 18.5880321 },
    { lat: 48.7181564, lng: 18.5876392 },
    { lat: 48.7180962, lng: 18.5872559 },
    { lat: 48.7181975, lng: 18.5866683 },
    { lat: 48.7182979, lng: 18.5865237 },
    { lat: 48.7183462, lng: 18.5864552 },
    { lat: 48.7183684, lng: 18.5861364 },
    { lat: 48.7184285, lng: 18.5860375 },
    { lat: 48.71847, lng: 18.5859688 },
    { lat: 48.718457, lng: 18.5858782 },
    { lat: 48.7185, lng: 18.5858608 },
    { lat: 48.7186103, lng: 18.585815 },
    { lat: 48.7187332, lng: 18.5856669 },
    { lat: 48.718817, lng: 18.5855905 },
    { lat: 48.7188327, lng: 18.5855442 },
    { lat: 48.718853, lng: 18.5854817 },
    { lat: 48.7189075, lng: 18.58549 },
    { lat: 48.7190739, lng: 18.5854373 },
    { lat: 48.7190705, lng: 18.5853134 },
    { lat: 48.7190887, lng: 18.5852958 },
    { lat: 48.7191516, lng: 18.585209 },
    { lat: 48.7191916, lng: 18.5851973 },
    { lat: 48.7192359, lng: 18.5851818 },
    { lat: 48.7192994, lng: 18.5851436 },
    { lat: 48.7193312, lng: 18.585085 },
    { lat: 48.7193923, lng: 18.585071 },
    { lat: 48.7194386, lng: 18.585026 },
    { lat: 48.719494, lng: 18.5850099 },
    { lat: 48.7197352, lng: 18.584839 },
    { lat: 48.7198331, lng: 18.5847522 },
    { lat: 48.7199747, lng: 18.5845339 },
    { lat: 48.7202411, lng: 18.584372 },
    { lat: 48.7203021, lng: 18.5842473 },
    { lat: 48.7203194, lng: 18.5842345 },
    { lat: 48.7204235, lng: 18.5841584 },
    { lat: 48.7204776, lng: 18.5841352 },
    { lat: 48.7206218, lng: 18.5841092 },
    { lat: 48.7207462, lng: 18.5840324 },
    { lat: 48.7208608, lng: 18.5839007 },
    { lat: 48.7210082, lng: 18.5837295 },
    { lat: 48.721106, lng: 18.5836574 },
    { lat: 48.7214259, lng: 18.5832549 },
    { lat: 48.7215379, lng: 18.5831132 },
    { lat: 48.7215504, lng: 18.583057 },
    { lat: 48.7215622, lng: 18.5830034 },
    { lat: 48.7215896, lng: 18.5830089 },
    { lat: 48.7216106, lng: 18.5829806 },
    { lat: 48.7217012, lng: 18.5829658 },
    { lat: 48.7217019, lng: 18.5828514 },
    { lat: 48.7217664, lng: 18.5826888 },
    { lat: 48.7218462, lng: 18.5826446 },
    { lat: 48.7218821, lng: 18.5826002 },
    { lat: 48.7219509, lng: 18.5826395 },
    { lat: 48.7221858, lng: 18.5824161 },
    { lat: 48.7222693, lng: 18.5823354 },
    { lat: 48.7224278, lng: 18.582114 },
    { lat: 48.7224394, lng: 18.5818918 },
    { lat: 48.7225917, lng: 18.5816411 },
    { lat: 48.7227138, lng: 18.5814377 },
    { lat: 48.7239353, lng: 18.583166 },
    { lat: 48.7241394, lng: 18.5830196 },
    { lat: 48.7242939, lng: 18.5830194 },
    { lat: 48.7247856, lng: 18.5814757 },
    { lat: 48.7252735, lng: 18.5804068 },
    { lat: 48.725324, lng: 18.580297 },
    { lat: 48.7253976, lng: 18.5801371 },
    { lat: 48.7254118, lng: 18.5801071 },
    { lat: 48.7254623, lng: 18.5799972 },
    { lat: 48.7254882, lng: 18.5800169 },
    { lat: 48.7255422, lng: 18.5800581 },
    { lat: 48.7255881, lng: 18.5800928 },
    { lat: 48.727085, lng: 18.5812815 },
    { lat: 48.7270968, lng: 18.5812893 },
    { lat: 48.7271107, lng: 18.5812983 },
    { lat: 48.7271158, lng: 18.581182 },
    { lat: 48.7270983, lng: 18.5811113 },
    { lat: 48.7271058, lng: 18.5810185 },
    { lat: 48.7271526, lng: 18.5808848 },
    { lat: 48.7271517, lng: 18.5808067 },
    { lat: 48.7271129, lng: 18.580754 },
    { lat: 48.7271143, lng: 18.5806963 },
    { lat: 48.7271455, lng: 18.580627 },
    { lat: 48.7271534, lng: 18.580585 },
    { lat: 48.7272334, lng: 18.58046 },
    { lat: 48.727252, lng: 18.580367 },
    { lat: 48.7271614, lng: 18.5802349 },
    { lat: 48.7271181, lng: 18.5801649 },
    { lat: 48.727034, lng: 18.5798206 },
    { lat: 48.7270637, lng: 18.5795579 },
    { lat: 48.7271009, lng: 18.5794715 },
    { lat: 48.7272387, lng: 18.5794976 },
    { lat: 48.7272685, lng: 18.5793367 },
    { lat: 48.7271713, lng: 18.5792309 },
    { lat: 48.7271797, lng: 18.5791548 },
    { lat: 48.7272112, lng: 18.5790945 },
    { lat: 48.7272608, lng: 18.5790508 },
    { lat: 48.7273832, lng: 18.5790414 },
    { lat: 48.7274294, lng: 18.5789345 },
    { lat: 48.7274423, lng: 18.5787361 },
    { lat: 48.7274867, lng: 18.578617 },
    { lat: 48.727482, lng: 18.578574 },
    { lat: 48.7274361, lng: 18.5784561 },
    { lat: 48.7274206, lng: 18.5783801 },
    { lat: 48.727575, lng: 18.5782709 },
    { lat: 48.7276117, lng: 18.5781774 },
    { lat: 48.7276836, lng: 18.5781946 },
    { lat: 48.727743, lng: 18.5779558 },
    { lat: 48.7277288, lng: 18.5778821 },
    { lat: 48.7277464, lng: 18.5777488 },
    { lat: 48.7277878, lng: 18.5776622 },
    { lat: 48.7278015, lng: 18.5775391 },
    { lat: 48.7278527, lng: 18.5774859 },
    { lat: 48.7278641, lng: 18.5774652 },
    { lat: 48.7279171, lng: 18.5773404 },
    { lat: 48.7280136, lng: 18.5772717 },
    { lat: 48.728104, lng: 18.5771679 },
    { lat: 48.7281459, lng: 18.5772142 },
    { lat: 48.7281654, lng: 18.5772582 },
    { lat: 48.7282075, lng: 18.5772895 },
    { lat: 48.7282498, lng: 18.5771731 },
    { lat: 48.7282858, lng: 18.5770908 },
    { lat: 48.7282806, lng: 18.5769506 },
    { lat: 48.7282969, lng: 18.5767936 },
    { lat: 48.7282856, lng: 18.5766299 },
    { lat: 48.7283681, lng: 18.5765689 },
    { lat: 48.7283606, lng: 18.5764695 },
    { lat: 48.7283465, lng: 18.5763787 },
    { lat: 48.7282902, lng: 18.5762342 },
    { lat: 48.7282835, lng: 18.5760885 },
    { lat: 48.7283376, lng: 18.5759611 },
    { lat: 48.7283598, lng: 18.5759362 },
    { lat: 48.7283415, lng: 18.5757466 },
    { lat: 48.7283776, lng: 18.5756796 },
    { lat: 48.728387, lng: 18.5755441 },
    { lat: 48.7284093, lng: 18.575536 },
    { lat: 48.7284296, lng: 18.575392 },
    { lat: 48.7283724, lng: 18.5752747 },
    { lat: 48.7284012, lng: 18.5751826 },
    { lat: 48.728491, lng: 18.5751507 },
    { lat: 48.72851, lng: 18.5750222 },
    { lat: 48.7285362, lng: 18.5749254 },
    { lat: 48.7285548, lng: 18.5748316 },
    { lat: 48.7286574, lng: 18.5747886 },
    { lat: 48.7286694, lng: 18.5747621 },
    { lat: 48.7286756, lng: 18.5747291 },
    { lat: 48.7286774, lng: 18.5746174 },
    { lat: 48.728694, lng: 18.57457 },
    { lat: 48.7287078, lng: 18.5744511 },
    { lat: 48.7287223, lng: 18.5744475 },
    { lat: 48.7287625, lng: 18.5745215 },
    { lat: 48.7289786, lng: 18.5744926 },
    { lat: 48.7290904, lng: 18.5744094 },
    { lat: 48.7291129, lng: 18.5743673 },
    { lat: 48.7290731, lng: 18.5741958 },
    { lat: 48.7291229, lng: 18.5741589 },
    { lat: 48.7291122, lng: 18.5740223 },
    { lat: 48.7291274, lng: 18.5740104 },
    { lat: 48.7291962, lng: 18.5740326 },
    { lat: 48.7292769, lng: 18.5740055 },
    { lat: 48.7292839, lng: 18.5739646 },
    { lat: 48.7294522, lng: 18.5738458 },
    { lat: 48.7294941, lng: 18.573787 },
    { lat: 48.7295595, lng: 18.5736189 },
    { lat: 48.7296519, lng: 18.5734924 },
    { lat: 48.7297032, lng: 18.5735232 },
    { lat: 48.7297587, lng: 18.5734434 },
    { lat: 48.7297768, lng: 18.5732986 },
    { lat: 48.729848, lng: 18.5732384 },
    { lat: 48.7299587, lng: 18.5729738 },
    { lat: 48.7300704, lng: 18.5729718 },
    { lat: 48.7302054, lng: 18.5728643 },
    { lat: 48.7302776, lng: 18.5727564 },
    { lat: 48.7304319, lng: 18.5725032 },
    { lat: 48.7306227, lng: 18.5724446 },
    { lat: 48.7308331, lng: 18.5720605 },
    { lat: 48.7311983, lng: 18.5718305 },
    { lat: 48.7313566, lng: 18.5713753 },
    { lat: 48.7313717, lng: 18.5710976 },
    { lat: 48.7312632, lng: 18.570673 },
    { lat: 48.7312868, lng: 18.5703814 },
    { lat: 48.7312902, lng: 18.5700912 },
    { lat: 48.7313997, lng: 18.5697607 },
    { lat: 48.7316548, lng: 18.5694533 },
    { lat: 48.7318719, lng: 18.5694199 },
    { lat: 48.73222, lng: 18.5688701 },
    { lat: 48.7322652, lng: 18.5688934 },
    { lat: 48.7323458, lng: 18.5690892 },
    { lat: 48.7325394, lng: 18.5691456 },
    { lat: 48.7326104, lng: 18.5691218 },
    { lat: 48.7326838, lng: 18.5688322 },
    { lat: 48.7328947, lng: 18.5683977 },
    { lat: 48.732875, lng: 18.5679084 },
    { lat: 48.7329247, lng: 18.5678269 },
    { lat: 48.7332309, lng: 18.5677546 },
    { lat: 48.7334557, lng: 18.5676335 },
    { lat: 48.7335623, lng: 18.5672722 },
    { lat: 48.733581, lng: 18.5672202 },
    { lat: 48.7336416, lng: 18.5670912 },
    { lat: 48.7336541, lng: 18.5670732 },
    { lat: 48.7336615, lng: 18.5670623 },
    { lat: 48.7337987, lng: 18.5668688 },
    { lat: 48.733929, lng: 18.5666729 },
    { lat: 48.7342405, lng: 18.5663412 },
    { lat: 48.7343356, lng: 18.5663084 },
    { lat: 48.7344733, lng: 18.5661023 },
    { lat: 48.734597, lng: 18.565929 },
    { lat: 48.7347079, lng: 18.5657076 },
    { lat: 48.734694, lng: 18.5656404 },
    { lat: 48.7346337, lng: 18.5656078 },
    { lat: 48.7346795, lng: 18.5654728 },
    { lat: 48.7347075, lng: 18.5653661 },
    { lat: 48.7346881, lng: 18.5653009 },
    { lat: 48.7345332, lng: 18.565273 },
    { lat: 48.7344339, lng: 18.564754 },
    { lat: 48.7343379, lng: 18.5644618 },
    { lat: 48.7343654, lng: 18.5643844 },
    { lat: 48.734444, lng: 18.5643786 },
    { lat: 48.7345769, lng: 18.5642714 },
    { lat: 48.7346846, lng: 18.5643209 },
    { lat: 48.7349514, lng: 18.5641681 },
    { lat: 48.7350557, lng: 18.5639907 },
    { lat: 48.7350789, lng: 18.5637524 },
    { lat: 48.7352151, lng: 18.5631044 },
    { lat: 48.7354034, lng: 18.5626848 },
    { lat: 48.7354543, lng: 18.5624605 },
    { lat: 48.735448, lng: 18.5624281 },
    { lat: 48.7354347, lng: 18.562414 },
    { lat: 48.7353926, lng: 18.5624038 },
    { lat: 48.7353759, lng: 18.5623881 },
    { lat: 48.7353697, lng: 18.562357 },
    { lat: 48.7353728, lng: 18.5623309 },
    { lat: 48.7353995, lng: 18.5622615 },
    { lat: 48.7354283, lng: 18.5622093 },
    { lat: 48.735478, lng: 18.5620874 },
    { lat: 48.7355276, lng: 18.5619226 },
    { lat: 48.7357526, lng: 18.5616173 },
    { lat: 48.7359365, lng: 18.5613683 },
    { lat: 48.7359499, lng: 18.5613674 },
    { lat: 48.7359852, lng: 18.5614176 },
    { lat: 48.7360038, lng: 18.5614253 },
    { lat: 48.7362767, lng: 18.561136 },
    { lat: 48.7363242, lng: 18.5610307 },
    { lat: 48.7363273, lng: 18.5610046 },
    { lat: 48.7363102, lng: 18.5609206 },
    { lat: 48.736252, lng: 18.5608246 },
    { lat: 48.7362491, lng: 18.5607931 },
    { lat: 48.73639, lng: 18.5603318 },
    { lat: 48.7361904, lng: 18.5600211 },
    { lat: 48.7361932, lng: 18.5598864 },
    { lat: 48.7360846, lng: 18.5595268 },
    { lat: 48.7362221, lng: 18.5592789 },
    { lat: 48.7362291, lng: 18.5590929 },
    { lat: 48.7362949, lng: 18.5588339 },
    { lat: 48.7365216, lng: 18.558665 },
    { lat: 48.7366595, lng: 18.5584241 },
    { lat: 48.7366556, lng: 18.5583085 },
    { lat: 48.7366573, lng: 18.5581521 },
    { lat: 48.736679, lng: 18.5578918 },
    { lat: 48.7366877, lng: 18.5577599 },
    { lat: 48.7367458, lng: 18.557624 },
    { lat: 48.7367788, lng: 18.557526 },
    { lat: 48.7367374, lng: 18.5573657 },
    { lat: 48.7366926, lng: 18.557184 },
    { lat: 48.7367567, lng: 18.556972 },
    { lat: 48.7368929, lng: 18.5567217 },
    { lat: 48.7368513, lng: 18.5565561 },
    { lat: 48.7370691, lng: 18.5562671 },
    { lat: 48.7369639, lng: 18.5561365 },
    { lat: 48.7368323, lng: 18.5556461 },
    { lat: 48.7365975, lng: 18.5558304 },
    { lat: 48.7364029, lng: 18.5554858 },
    { lat: 48.7364003, lng: 18.5554608 },
    { lat: 48.7364736, lng: 18.555109 },
    { lat: 48.7365951, lng: 18.5552036 },
    { lat: 48.7366145, lng: 18.5551865 },
    { lat: 48.7366523, lng: 18.555135 },
    { lat: 48.7365985, lng: 18.5549541 },
    { lat: 48.7365752, lng: 18.554882 },
    { lat: 48.7364955, lng: 18.5547425 },
    { lat: 48.7365301, lng: 18.5547135 },
    { lat: 48.7365484, lng: 18.5546977 },
    { lat: 48.7369145, lng: 18.5544628 },
    { lat: 48.7369567, lng: 18.5544293 },
    { lat: 48.7370083, lng: 18.5544018 },
    { lat: 48.7369859, lng: 18.5543417 },
    { lat: 48.7368881, lng: 18.5539994 },
    { lat: 48.7368596, lng: 18.5539493 },
    { lat: 48.7367501, lng: 18.5538861 },
    { lat: 48.7366723, lng: 18.5538643 },
    { lat: 48.7365803, lng: 18.5538547 },
    { lat: 48.7365776, lng: 18.5538436 },
    { lat: 48.7362311, lng: 18.5535711 },
    { lat: 48.7357181, lng: 18.5532043 },
    { lat: 48.7354853, lng: 18.5530904 },
    { lat: 48.7355472, lng: 18.5529654 },
    { lat: 48.7356429, lng: 18.552817 },
    { lat: 48.7363369, lng: 18.5507715 },
    { lat: 48.736968, lng: 18.5490167 },
    { lat: 48.7374951, lng: 18.5476957 },
    { lat: 48.7375317, lng: 18.5476272 },
    { lat: 48.7375911, lng: 18.5475151 },
    { lat: 48.7377336, lng: 18.547437 },
    { lat: 48.737733, lng: 18.547282 },
    { lat: 48.7374349, lng: 18.5472994 },
    { lat: 48.7371364, lng: 18.5472215 },
    { lat: 48.7369962, lng: 18.5471343 },
    { lat: 48.736841, lng: 18.5470174 },
    { lat: 48.7364185, lng: 18.5466734 },
    { lat: 48.7364294, lng: 18.5465659 },
    { lat: 48.7367395, lng: 18.5465609 },
    { lat: 48.736909, lng: 18.5465655 },
    { lat: 48.7371028, lng: 18.5464992 },
    { lat: 48.7372422, lng: 18.5463461 },
    { lat: 48.7374097, lng: 18.5460072 },
    { lat: 48.7375455, lng: 18.5458045 },
    { lat: 48.7378232, lng: 18.5453139 },
    { lat: 48.7378601, lng: 18.5452232 },
    { lat: 48.7380191, lng: 18.544623 },
    { lat: 48.7379962, lng: 18.5440591 },
    { lat: 48.7380064, lng: 18.5435011 },
    { lat: 48.7381854, lng: 18.5428288 },
    { lat: 48.7383261, lng: 18.5424314 },
    { lat: 48.738538, lng: 18.5419948 },
    { lat: 48.7387679, lng: 18.5414522 },
    { lat: 48.7389005, lng: 18.5413385 },
    { lat: 48.7391065, lng: 18.5409601 },
    { lat: 48.739323, lng: 18.5406691 },
    { lat: 48.7393951, lng: 18.5405 },
    { lat: 48.7394971, lng: 18.5404058 },
    { lat: 48.7399472, lng: 18.5399084 },
    { lat: 48.740064, lng: 18.5396537 },
    { lat: 48.7401061, lng: 18.5394329 },
    { lat: 48.7396495, lng: 18.5384238 },
    { lat: 48.7392525, lng: 18.537577 },
    { lat: 48.7392704, lng: 18.5375537 },
    { lat: 48.7389544, lng: 18.5368469 },
    { lat: 48.7384068, lng: 18.5356566 },
    { lat: 48.7383046, lng: 18.5354599 },
    { lat: 48.7383598, lng: 18.5353101 },
    { lat: 48.738752, lng: 18.5345847 },
    { lat: 48.7392911, lng: 18.5337734 },
    { lat: 48.7394763, lng: 18.5336531 },
    { lat: 48.7396295, lng: 18.533217 },
    { lat: 48.7398323, lng: 18.5329233 },
    { lat: 48.7401744, lng: 18.5327037 },
    { lat: 48.7402945, lng: 18.5325472 },
    { lat: 48.7404779, lng: 18.5318153 },
    { lat: 48.7407161, lng: 18.5313195 },
    { lat: 48.7411032, lng: 18.5307866 },
    { lat: 48.7415704, lng: 18.5299871 },
    { lat: 48.7416657, lng: 18.5298934 },
    { lat: 48.741482, lng: 18.5293598 },
    { lat: 48.7413459, lng: 18.528829 },
    { lat: 48.7412666, lng: 18.5280769 },
    { lat: 48.7411685, lng: 18.5277918 },
    { lat: 48.7409235, lng: 18.527643 },
    { lat: 48.7406885, lng: 18.5275509 },
    { lat: 48.7403809, lng: 18.5271647 },
    { lat: 48.7403458, lng: 18.5269983 },
    { lat: 48.7403357, lng: 18.5265446 },
    { lat: 48.7403477, lng: 18.5251336 },
    { lat: 48.7403041, lng: 18.523924 },
    { lat: 48.739494, lng: 18.5242089 },
    { lat: 48.7390726, lng: 18.5244656 },
    { lat: 48.7388432, lng: 18.5246826 },
    { lat: 48.7384053, lng: 18.5247019 },
    { lat: 48.7378262, lng: 18.5246291 },
    { lat: 48.7375076, lng: 18.5246643 },
    { lat: 48.7366352, lng: 18.5246729 },
    { lat: 48.7360239, lng: 18.5247371 },
    { lat: 48.7357849, lng: 18.5248666 },
    { lat: 48.7354704, lng: 18.5251382 },
    { lat: 48.7352554, lng: 18.5254548 },
    { lat: 48.7350732, lng: 18.5256941 },
    { lat: 48.7347876, lng: 18.5259563 },
    { lat: 48.7341773, lng: 18.526411 },
    { lat: 48.7338579, lng: 18.5267167 },
    { lat: 48.7335144, lng: 18.52691 },
    { lat: 48.7330117, lng: 18.5270018 },
    { lat: 48.7330094, lng: 18.5269609 },
    { lat: 48.7323955, lng: 18.5268514 },
    { lat: 48.7325879, lng: 18.5255196 },
    { lat: 48.7326307, lng: 18.5250651 },
    { lat: 48.7325906, lng: 18.5247475 },
    { lat: 48.7324582, lng: 18.5244095 },
    { lat: 48.7323834, lng: 18.5241298 },
    { lat: 48.732264, lng: 18.5235373 },
    { lat: 48.7321809, lng: 18.5229863 },
    { lat: 48.732145, lng: 18.5226617 },
    { lat: 48.732107, lng: 18.5221526 },
    { lat: 48.7319827, lng: 18.522046 },
    { lat: 48.7317414, lng: 18.5221665 },
    { lat: 48.7306194, lng: 18.5220291 },
    { lat: 48.7299704, lng: 18.5217375 },
    { lat: 48.7294638, lng: 18.5217958 },
    { lat: 48.7289982, lng: 18.5218197 },
    { lat: 48.7285704, lng: 18.5217334 },
    { lat: 48.7284152, lng: 18.5218214 },
    { lat: 48.7283194, lng: 18.5219899 },
    { lat: 48.7281819, lng: 18.5225477 },
    { lat: 48.7280946, lng: 18.522729 },
    { lat: 48.7278449, lng: 18.5227602 },
    { lat: 48.7274662, lng: 18.5227868 },
    { lat: 48.7270319, lng: 18.5228492 },
    { lat: 48.7267075, lng: 18.5229001 },
    { lat: 48.7264386, lng: 18.5228491 },
    { lat: 48.7261508, lng: 18.522766 },
    { lat: 48.7258386, lng: 18.5225203 },
    { lat: 48.7255493, lng: 18.522222 },
    { lat: 48.7251182, lng: 18.5219504 },
    { lat: 48.724054, lng: 18.5222302 },
    { lat: 48.7235214, lng: 18.5220602 },
    { lat: 48.7230529, lng: 18.5214375 },
    { lat: 48.7230315, lng: 18.5213976 },
    { lat: 48.7229182, lng: 18.5211199 },
    { lat: 48.7226074, lng: 18.5202427 },
    { lat: 48.7221964, lng: 18.5192849 },
    { lat: 48.7218387, lng: 18.5186044 },
    { lat: 48.721466, lng: 18.5178336 },
    { lat: 48.7212536, lng: 18.5175361 },
    { lat: 48.7209604, lng: 18.5172105 },
    { lat: 48.7206059, lng: 18.5169137 },
    { lat: 48.7204102, lng: 18.5166981 },
    { lat: 48.7202438, lng: 18.5170783 },
    { lat: 48.7201296, lng: 18.5169925 },
    { lat: 48.7199593, lng: 18.5173825 },
    { lat: 48.7193166, lng: 18.5167909 },
    { lat: 48.7195478, lng: 18.5162347 },
    { lat: 48.7188802, lng: 18.5155387 },
    { lat: 48.718545, lng: 18.5152659 },
    { lat: 48.7185126, lng: 18.5151871 },
    { lat: 48.7183372, lng: 18.5151401 },
    { lat: 48.7181417, lng: 18.5146565 },
    { lat: 48.7179646, lng: 18.5147626 },
    { lat: 48.7178659, lng: 18.5149368 },
    { lat: 48.7177157, lng: 18.514997 },
    { lat: 48.7176381, lng: 18.5150592 },
    { lat: 48.7174793, lng: 18.5150859 },
    { lat: 48.717273, lng: 18.5151903 },
    { lat: 48.7170989, lng: 18.5150806 },
    { lat: 48.7169097, lng: 18.5150657 },
    { lat: 48.7167481, lng: 18.5149387 },
    { lat: 48.7165723, lng: 18.5149607 },
    { lat: 48.7165028, lng: 18.5150519 },
    { lat: 48.7163419, lng: 18.5150777 },
    { lat: 48.7156432, lng: 18.5149665 },
    { lat: 48.7155502, lng: 18.5147317 },
    { lat: 48.7151052, lng: 18.5135678 },
    { lat: 48.7143201, lng: 18.5128284 },
    { lat: 48.7142046, lng: 18.513045 },
    { lat: 48.7140975, lng: 18.5132316 },
    { lat: 48.713967, lng: 18.5136498 },
    { lat: 48.713857, lng: 18.5135578 },
    { lat: 48.7136001, lng: 18.5131703 },
    { lat: 48.7134488, lng: 18.5128802 },
    { lat: 48.7132363, lng: 18.5125435 },
    { lat: 48.7137075, lng: 18.5117945 },
    { lat: 48.7130533, lng: 18.5109548 },
    { lat: 48.7132209, lng: 18.510532 },
    { lat: 48.7132675, lng: 18.510281 },
  ],
  OpatovcenadNitrou: [
    { lat: 48.7375317, lng: 18.5476272 },
    { lat: 48.7374951, lng: 18.5476957 },
    { lat: 48.736968, lng: 18.5490167 },
    { lat: 48.7363369, lng: 18.5507715 },
    { lat: 48.7356429, lng: 18.552817 },
    { lat: 48.7355472, lng: 18.5529654 },
    { lat: 48.7354853, lng: 18.5530904 },
    { lat: 48.7357181, lng: 18.5532043 },
    { lat: 48.7362311, lng: 18.5535711 },
    { lat: 48.7365776, lng: 18.5538436 },
    { lat: 48.7365803, lng: 18.5538547 },
    { lat: 48.7366723, lng: 18.5538643 },
    { lat: 48.7367501, lng: 18.5538861 },
    { lat: 48.7368596, lng: 18.5539493 },
    { lat: 48.7368881, lng: 18.5539994 },
    { lat: 48.7369859, lng: 18.5543417 },
    { lat: 48.7370083, lng: 18.5544018 },
    { lat: 48.7372179, lng: 18.5548567 },
    { lat: 48.7371646, lng: 18.555081 },
    { lat: 48.7372286, lng: 18.5553446 },
    { lat: 48.7373833, lng: 18.5555333 },
    { lat: 48.7376111, lng: 18.5556147 },
    { lat: 48.7377926, lng: 18.5554671 },
    { lat: 48.7382305, lng: 18.5553873 },
    { lat: 48.7384376, lng: 18.5554016 },
    { lat: 48.7385351, lng: 18.5556375 },
    { lat: 48.73863, lng: 18.555849 },
    { lat: 48.7386936, lng: 18.5560444 },
    { lat: 48.7386726, lng: 18.5566102 },
    { lat: 48.7387684, lng: 18.5568806 },
    { lat: 48.7388551, lng: 18.5569803 },
    { lat: 48.7389823, lng: 18.5574119 },
    { lat: 48.7390397, lng: 18.5576581 },
    { lat: 48.7391157, lng: 18.5576869 },
    { lat: 48.7392813, lng: 18.5574987 },
    { lat: 48.7393215, lng: 18.5570946 },
    { lat: 48.739313, lng: 18.5569605 },
    { lat: 48.7392975, lng: 18.556797 },
    { lat: 48.7393753, lng: 18.5565493 },
    { lat: 48.7394052, lng: 18.5565167 },
    { lat: 48.7394747, lng: 18.5565077 },
    { lat: 48.739641, lng: 18.5565792 },
    { lat: 48.739684, lng: 18.5566446 },
    { lat: 48.7397535, lng: 18.5567847 },
    { lat: 48.7398337, lng: 18.5570338 },
    { lat: 48.7398983, lng: 18.557248 },
    { lat: 48.7399322, lng: 18.5575197 },
    { lat: 48.740061, lng: 18.5578149 },
    { lat: 48.7403841, lng: 18.5582362 },
    { lat: 48.7404543, lng: 18.5583447 },
    { lat: 48.7405017, lng: 18.5584517 },
    { lat: 48.7405503, lng: 18.5585975 },
    { lat: 48.7407073, lng: 18.5587474 },
    { lat: 48.7409431, lng: 18.5585805 },
    { lat: 48.7410249, lng: 18.5585507 },
    { lat: 48.7411892, lng: 18.5586075 },
    { lat: 48.741238, lng: 18.5586954 },
    { lat: 48.7412822, lng: 18.5588225 },
    { lat: 48.7412864, lng: 18.5588995 },
    { lat: 48.7412748, lng: 18.5591418 },
    { lat: 48.7413081, lng: 18.559569 },
    { lat: 48.7413388, lng: 18.5601978 },
    { lat: 48.7414164, lng: 18.5603561 },
    { lat: 48.7414755, lng: 18.560531 },
    { lat: 48.7415039, lng: 18.5606179 },
    { lat: 48.7415152, lng: 18.5606397 },
    { lat: 48.7416631, lng: 18.5607257 },
    { lat: 48.7421783, lng: 18.5612589 },
    { lat: 48.7424698, lng: 18.5615597 },
    { lat: 48.7427165, lng: 18.5616381 },
    { lat: 48.7426943, lng: 18.5619986 },
    { lat: 48.7428128, lng: 18.5623916 },
    { lat: 48.74286, lng: 18.5623472 },
    { lat: 48.7428825, lng: 18.5623265 },
    { lat: 48.7429082, lng: 18.562299 },
    { lat: 48.7430982, lng: 18.5616889 },
    { lat: 48.7440849, lng: 18.5585465 },
    { lat: 48.7443302, lng: 18.5587011 },
    { lat: 48.7444593, lng: 18.5587337 },
    { lat: 48.7446014, lng: 18.5587507 },
    { lat: 48.7448344, lng: 18.5589111 },
    { lat: 48.7450524, lng: 18.5590831 },
    { lat: 48.7458245, lng: 18.5596945 },
    { lat: 48.745924, lng: 18.5597815 },
    { lat: 48.74629, lng: 18.5600827 },
    { lat: 48.746329, lng: 18.5601568 },
    { lat: 48.7463553, lng: 18.5602058 },
    { lat: 48.7463693, lng: 18.5602337 },
    { lat: 48.7463673, lng: 18.5603226 },
    { lat: 48.746367, lng: 18.5603784 },
    { lat: 48.7465919, lng: 18.5604723 },
    { lat: 48.7467644, lng: 18.5606757 },
    { lat: 48.7467757, lng: 18.5607397 },
    { lat: 48.7467294, lng: 18.5609449 },
    { lat: 48.7469075, lng: 18.5615253 },
    { lat: 48.746899, lng: 18.5615553 },
    { lat: 48.7469601, lng: 18.5616196 },
    { lat: 48.747313, lng: 18.5617627 },
    { lat: 48.7473937, lng: 18.5615861 },
    { lat: 48.7474358, lng: 18.5616348 },
    { lat: 48.7474744, lng: 18.5616833 },
    { lat: 48.7477015, lng: 18.5619582 },
    { lat: 48.7478008, lng: 18.5625013 },
    { lat: 48.7480214, lng: 18.5629918 },
    { lat: 48.7483152, lng: 18.5633963 },
    { lat: 48.7485276, lng: 18.5636724 },
    { lat: 48.7484649, lng: 18.5637852 },
    { lat: 48.7486045, lng: 18.564319 },
    { lat: 48.749093, lng: 18.565672 },
    { lat: 48.7494147, lng: 18.5662994 },
    { lat: 48.7495829, lng: 18.5665726 },
    { lat: 48.749991, lng: 18.5670228 },
    { lat: 48.7503896, lng: 18.5676785 },
    { lat: 48.7505888, lng: 18.5681041 },
    { lat: 48.7506539, lng: 18.5682458 },
    { lat: 48.7509596, lng: 18.5687866 },
    { lat: 48.7514029, lng: 18.5694947 },
    { lat: 48.7514746, lng: 18.569632 },
    { lat: 48.7517855, lng: 18.5702268 },
    { lat: 48.7520027, lng: 18.5707972 },
    { lat: 48.75212, lng: 18.5711948 },
    { lat: 48.7521116, lng: 18.571288 },
    { lat: 48.7521045, lng: 18.5713665 },
    { lat: 48.7520906, lng: 18.5714861 },
    { lat: 48.7520882, lng: 18.5715243 },
    { lat: 48.7521564, lng: 18.5717611 },
    { lat: 48.7523332, lng: 18.5726724 },
    { lat: 48.7525979, lng: 18.5737066 },
    { lat: 48.7525445, lng: 18.5739456 },
    { lat: 48.7523093, lng: 18.574538 },
    { lat: 48.752047, lng: 18.5752979 },
    { lat: 48.7517494, lng: 18.5753459 },
    { lat: 48.7517529, lng: 18.5754112 },
    { lat: 48.7517572, lng: 18.5754897 },
    { lat: 48.7517866, lng: 18.5756569 },
    { lat: 48.7517366, lng: 18.5757114 },
    { lat: 48.751698, lng: 18.5760425 },
    { lat: 48.751775, lng: 18.5763378 },
    { lat: 48.7517131, lng: 18.5767748 },
    { lat: 48.7517085, lng: 18.5770045 },
    { lat: 48.7515453, lng: 18.5780702 },
    { lat: 48.7517457, lng: 18.57848 },
    { lat: 48.7517288, lng: 18.5785046 },
    { lat: 48.7517174, lng: 18.5785203 },
    { lat: 48.7516758, lng: 18.578587 },
    { lat: 48.7516344, lng: 18.5786554 },
    { lat: 48.7516272, lng: 18.5786663 },
    { lat: 48.7516146, lng: 18.5786875 },
    { lat: 48.7514978, lng: 18.5788828 },
    { lat: 48.7517839, lng: 18.5793953 },
    { lat: 48.7519309, lng: 18.5797789 },
    { lat: 48.7522777, lng: 18.5808712 },
    { lat: 48.7523823, lng: 18.5810755 },
    { lat: 48.7525514, lng: 18.5815529 },
    { lat: 48.752971, lng: 18.582802 },
    { lat: 48.7538977, lng: 18.5847548 },
    { lat: 48.7539518, lng: 18.584876 },
    { lat: 48.7541444, lng: 18.5851206 },
    { lat: 48.7543871, lng: 18.5856046 },
    { lat: 48.7547862, lng: 18.5862276 },
    { lat: 48.7552551, lng: 18.5868037 },
    { lat: 48.7555437, lng: 18.5871963 },
    { lat: 48.7558308, lng: 18.5876902 },
    { lat: 48.7559041, lng: 18.5878555 },
    { lat: 48.7560307, lng: 18.5880286 },
    { lat: 48.7561032, lng: 18.588138 },
    { lat: 48.7561689, lng: 18.5881863 },
    { lat: 48.7564086, lng: 18.5880069 },
    { lat: 48.7564724, lng: 18.5879593 },
    { lat: 48.7564877, lng: 18.5879481 },
    { lat: 48.7564976, lng: 18.5879433 },
    { lat: 48.7565503, lng: 18.5879182 },
    { lat: 48.7565515, lng: 18.5879174 },
    { lat: 48.7565919, lng: 18.5878952 },
    { lat: 48.7566393, lng: 18.5878715 },
    { lat: 48.7567753, lng: 18.5878035 },
    { lat: 48.7569051, lng: 18.5877385 },
    { lat: 48.7569578, lng: 18.5877123 },
    { lat: 48.7571823, lng: 18.5880477 },
    { lat: 48.7572292, lng: 18.5880217 },
    { lat: 48.7573475, lng: 18.5879771 },
    { lat: 48.7597414, lng: 18.5847538 },
    { lat: 48.7615573, lng: 18.5830636 },
    { lat: 48.7615894, lng: 18.5830307 },
    { lat: 48.7616225, lng: 18.5829967 },
    { lat: 48.7619612, lng: 18.5826477 },
    { lat: 48.7631801, lng: 18.5813912 },
    { lat: 48.763928, lng: 18.5804541 },
    { lat: 48.7647614, lng: 18.5794091 },
    { lat: 48.7652345, lng: 18.5788154 },
    { lat: 48.7655813, lng: 18.5784496 },
    { lat: 48.7657691, lng: 18.578251 },
    { lat: 48.7657949, lng: 18.5782077 },
    { lat: 48.7660563, lng: 18.5785567 },
    { lat: 48.7666788, lng: 18.5778662 },
    { lat: 48.7667335, lng: 18.5777929 },
    { lat: 48.7668101, lng: 18.5777083 },
    { lat: 48.7668763, lng: 18.5776204 },
    { lat: 48.7668123, lng: 18.5775852 },
    { lat: 48.7677044, lng: 18.577146 },
    { lat: 48.7679409, lng: 18.5769086 },
    { lat: 48.7684541, lng: 18.5758886 },
    { lat: 48.7685924, lng: 18.5754842 },
    { lat: 48.7689527, lng: 18.5754113 },
    { lat: 48.7691305, lng: 18.5752591 },
    { lat: 48.7691452, lng: 18.5752165 },
    { lat: 48.768983, lng: 18.5747621 },
    { lat: 48.7689689, lng: 18.5746673 },
    { lat: 48.7689743, lng: 18.5746012 },
    { lat: 48.7692323, lng: 18.5741798 },
    { lat: 48.7692373, lng: 18.574146 },
    { lat: 48.7693395, lng: 18.5740349 },
    { lat: 48.7694112, lng: 18.5739839 },
    { lat: 48.7700453, lng: 18.573115 },
    { lat: 48.7706236, lng: 18.572316 },
    { lat: 48.7706747, lng: 18.5723254 },
    { lat: 48.7709397, lng: 18.5720121 },
    { lat: 48.7711273, lng: 18.5717665 },
    { lat: 48.7716299, lng: 18.5711742 },
    { lat: 48.7720539, lng: 18.5704675 },
    { lat: 48.7722985, lng: 18.5699834 },
    { lat: 48.7725074, lng: 18.5694901 },
    { lat: 48.7726599, lng: 18.5690761 },
    { lat: 48.7727325, lng: 18.5690249 },
    { lat: 48.7732736, lng: 18.5682468 },
    { lat: 48.7737048, lng: 18.5676297 },
    { lat: 48.7739236, lng: 18.5672961 },
    { lat: 48.7743702, lng: 18.5666327 },
    { lat: 48.7745567, lng: 18.5663274 },
    { lat: 48.7749466, lng: 18.5657143 },
    { lat: 48.7750001, lng: 18.5656419 },
    { lat: 48.7752838, lng: 18.5653216 },
    { lat: 48.7756758, lng: 18.5648527 },
    { lat: 48.7761812, lng: 18.5643973 },
    { lat: 48.7762306, lng: 18.5643486 },
    { lat: 48.7762744, lng: 18.5643278 },
    { lat: 48.7764924, lng: 18.5641641 },
    { lat: 48.7769718, lng: 18.563812 },
    { lat: 48.7775035, lng: 18.5631963 },
    { lat: 48.7778996, lng: 18.5627426 },
    { lat: 48.778419, lng: 18.562152 },
    { lat: 48.7786023, lng: 18.5619536 },
    { lat: 48.778906, lng: 18.5608182 },
    { lat: 48.7794161, lng: 18.5609658 },
    { lat: 48.7797351, lng: 18.5610045 },
    { lat: 48.7799734, lng: 18.5609481 },
    { lat: 48.7800947, lng: 18.5608364 },
    { lat: 48.7802515, lng: 18.5607107 },
    { lat: 48.7803449, lng: 18.5605593 },
    { lat: 48.7803028, lng: 18.5600967 },
    { lat: 48.7806235, lng: 18.5594567 },
    { lat: 48.7804013, lng: 18.5590644 },
    { lat: 48.7805061, lng: 18.5589348 },
    { lat: 48.7807536, lng: 18.5586136 },
    { lat: 48.7808384, lng: 18.5585788 },
    { lat: 48.7809072, lng: 18.5584515 },
    { lat: 48.7809543, lng: 18.5582571 },
    { lat: 48.7809373, lng: 18.5580922 },
    { lat: 48.7809684, lng: 18.5580218 },
    { lat: 48.7809301, lng: 18.5576468 },
    { lat: 48.7811039, lng: 18.5573394 },
    { lat: 48.7811269, lng: 18.5570758 },
    { lat: 48.78114, lng: 18.5570076 },
    { lat: 48.7810818, lng: 18.556806 },
    { lat: 48.7811089, lng: 18.5566607 },
    { lat: 48.7810296, lng: 18.5563229 },
    { lat: 48.780928, lng: 18.5561771 },
    { lat: 48.7807998, lng: 18.5557485 },
    { lat: 48.7807946, lng: 18.5554873 },
    { lat: 48.7808251, lng: 18.5552349 },
    { lat: 48.7807812, lng: 18.5551539 },
    { lat: 48.7807118, lng: 18.5547719 },
    { lat: 48.7806554, lng: 18.5546255 },
    { lat: 48.7805721, lng: 18.5542335 },
    { lat: 48.7798919, lng: 18.5532774 },
    { lat: 48.7796049, lng: 18.5528975 },
    { lat: 48.7794993, lng: 18.5526124 },
    { lat: 48.779415, lng: 18.5525564 },
    { lat: 48.7793339, lng: 18.5524352 },
    { lat: 48.7792523, lng: 18.5522812 },
    { lat: 48.7790428, lng: 18.551788 },
    { lat: 48.7788202, lng: 18.5512846 },
    { lat: 48.7787491, lng: 18.5507037 },
    { lat: 48.778621, lng: 18.5502773 },
    { lat: 48.7785717, lng: 18.550219 },
    { lat: 48.7783412, lng: 18.5498182 },
    { lat: 48.7782417, lng: 18.5496049 },
    { lat: 48.7782437, lng: 18.5494162 },
    { lat: 48.7781543, lng: 18.5493497 },
    { lat: 48.7780134, lng: 18.5491409 },
    { lat: 48.7779666, lng: 18.5489225 },
    { lat: 48.7777438, lng: 18.548956 },
    { lat: 48.7773854, lng: 18.5486279 },
    { lat: 48.7772686, lng: 18.5484097 },
    { lat: 48.7771532, lng: 18.5482581 },
    { lat: 48.777122, lng: 18.5482051 },
    { lat: 48.7771078, lng: 18.5481926 },
    { lat: 48.7770469, lng: 18.5482324 },
    { lat: 48.7768663, lng: 18.548272 },
    { lat: 48.776733, lng: 18.5482275 },
    { lat: 48.7764221, lng: 18.5480051 },
    { lat: 48.776336, lng: 18.5479164 },
    { lat: 48.7762469, lng: 18.5478732 },
    { lat: 48.7760418, lng: 18.5478569 },
    { lat: 48.7758913, lng: 18.547788 },
    { lat: 48.7758267, lng: 18.5477406 },
    { lat: 48.7755958, lng: 18.5476617 },
    { lat: 48.7754342, lng: 18.5475549 },
    { lat: 48.7753545, lng: 18.5474361 },
    { lat: 48.7753038, lng: 18.5472736 },
    { lat: 48.7752359, lng: 18.5471422 },
    { lat: 48.7751873, lng: 18.5471222 },
    { lat: 48.7751078, lng: 18.5470473 },
    { lat: 48.7749992, lng: 18.5468749 },
    { lat: 48.7749363, lng: 18.5468409 },
    { lat: 48.774877, lng: 18.5467882 },
    { lat: 48.7748109, lng: 18.5467546 },
    { lat: 48.7747533, lng: 18.5467532 },
    { lat: 48.7746645, lng: 18.5465708 },
    { lat: 48.7742687, lng: 18.5459313 },
    { lat: 48.7738646, lng: 18.5457189 },
    { lat: 48.7738335, lng: 18.5457088 },
    { lat: 48.7738024, lng: 18.5456747 },
    { lat: 48.773477, lng: 18.5456241 },
    { lat: 48.7729108, lng: 18.5452923 },
    { lat: 48.7728341, lng: 18.5453123 },
    { lat: 48.7726648, lng: 18.5453101 },
    { lat: 48.7725034, lng: 18.5453503 },
    { lat: 48.7724142, lng: 18.5452888 },
    { lat: 48.7723463, lng: 18.5452618 },
    { lat: 48.772308, lng: 18.5452219 },
    { lat: 48.7722735, lng: 18.545208 },
    { lat: 48.7722172, lng: 18.5452729 },
    { lat: 48.7721306, lng: 18.5452376 },
    { lat: 48.772039, lng: 18.5451685 },
    { lat: 48.7719671, lng: 18.5451335 },
    { lat: 48.7718988, lng: 18.545142 },
    { lat: 48.7717213, lng: 18.5450508 },
    { lat: 48.7716387, lng: 18.5449241 },
    { lat: 48.7715648, lng: 18.5449129 },
    { lat: 48.7713037, lng: 18.5450692 },
    { lat: 48.7712112, lng: 18.5450682 },
    { lat: 48.7711204, lng: 18.5451192 },
    { lat: 48.7710143, lng: 18.5451603 },
    { lat: 48.7708877, lng: 18.5450084 },
    { lat: 48.7707972, lng: 18.5449657 },
    { lat: 48.770783, lng: 18.5449739 },
    { lat: 48.7707137, lng: 18.5449214 },
    { lat: 48.7706761, lng: 18.544935 },
    { lat: 48.7705865, lng: 18.5450119 },
    { lat: 48.7703819, lng: 18.5451708 },
    { lat: 48.7702998, lng: 18.5451507 },
    { lat: 48.7701737, lng: 18.5451964 },
    { lat: 48.7701347, lng: 18.5452495 },
    { lat: 48.7699144, lng: 18.5453667 },
    { lat: 48.7698485, lng: 18.5453757 },
    { lat: 48.7697648, lng: 18.5454354 },
    { lat: 48.7696213, lng: 18.5453886 },
    { lat: 48.7693687, lng: 18.545493 },
    { lat: 48.7693106, lng: 18.5456068 },
    { lat: 48.7691187, lng: 18.5456911 },
    { lat: 48.7690417, lng: 18.5457433 },
    { lat: 48.7689571, lng: 18.5457048 },
    { lat: 48.7689389, lng: 18.5456796 },
    { lat: 48.7688798, lng: 18.5456308 },
    { lat: 48.7688464, lng: 18.5456375 },
    { lat: 48.7687517, lng: 18.5457004 },
    { lat: 48.7685495, lng: 18.54555 },
    { lat: 48.768437, lng: 18.5455376 },
    { lat: 48.7683384, lng: 18.5456324 },
    { lat: 48.7682313, lng: 18.5457763 },
    { lat: 48.7681596, lng: 18.5458474 },
    { lat: 48.7680333, lng: 18.5459948 },
    { lat: 48.7678229, lng: 18.5462098 },
    { lat: 48.76775, lng: 18.5463658 },
    { lat: 48.7675023, lng: 18.5466827 },
    { lat: 48.7673983, lng: 18.5467822 },
    { lat: 48.7673261, lng: 18.5468838 },
    { lat: 48.7672515, lng: 18.5471738 },
    { lat: 48.7672926, lng: 18.5475763 },
    { lat: 48.7672168, lng: 18.5476776 },
    { lat: 48.7670281, lng: 18.5476496 },
    { lat: 48.7669582, lng: 18.5477127 },
    { lat: 48.7668065, lng: 18.5478685 },
    { lat: 48.7665031, lng: 18.548119 },
    { lat: 48.7663851, lng: 18.548227 },
    { lat: 48.7662646, lng: 18.5483797 },
    { lat: 48.7660628, lng: 18.548649 },
    { lat: 48.7660125, lng: 18.5487815 },
    { lat: 48.7659627, lng: 18.5489418 },
    { lat: 48.765895, lng: 18.5491315 },
    { lat: 48.7658226, lng: 18.5493132 },
    { lat: 48.7656002, lng: 18.5495397 },
    { lat: 48.7655024, lng: 18.5496491 },
    { lat: 48.7653992, lng: 18.5497414 },
    { lat: 48.7652785, lng: 18.5498036 },
    { lat: 48.765147, lng: 18.5498328 },
    { lat: 48.7650173, lng: 18.5499788 },
    { lat: 48.7647431, lng: 18.5502474 },
    { lat: 48.7645665, lng: 18.5503159 },
    { lat: 48.7643787, lng: 18.5504526 },
    { lat: 48.7642553, lng: 18.5507955 },
    { lat: 48.7641845, lng: 18.5509455 },
    { lat: 48.7642501, lng: 18.5511991 },
    { lat: 48.7641382, lng: 18.5514029 },
    { lat: 48.7640316, lng: 18.5515155 },
    { lat: 48.7640517, lng: 18.5516972 },
    { lat: 48.7639577, lng: 18.5519391 },
    { lat: 48.7638792, lng: 18.5521984 },
    { lat: 48.7638628, lng: 18.552331 },
    { lat: 48.7639055, lng: 18.5528265 },
    { lat: 48.7638328, lng: 18.5532317 },
    { lat: 48.7634533, lng: 18.5534506 },
    { lat: 48.7634187, lng: 18.5538714 },
    { lat: 48.7633331, lng: 18.553832 },
    { lat: 48.7631827, lng: 18.5537209 },
    { lat: 48.7629956, lng: 18.5535358 },
    { lat: 48.7629243, lng: 18.5534292 },
    { lat: 48.7625028, lng: 18.5529793 },
    { lat: 48.7620449, lng: 18.5518403 },
    { lat: 48.7615632, lng: 18.5507401 },
    { lat: 48.7612414, lng: 18.5498006 },
    { lat: 48.7608427, lng: 18.5491838 },
    { lat: 48.7605475, lng: 18.5480941 },
    { lat: 48.7602352, lng: 18.5475366 },
    { lat: 48.7598248, lng: 18.5470025 },
    { lat: 48.7587467, lng: 18.5460721 },
    { lat: 48.7583011, lng: 18.5455924 },
    { lat: 48.7580318, lng: 18.5452703 },
    { lat: 48.7578999, lng: 18.5452273 },
    { lat: 48.7575884, lng: 18.5450188 },
    { lat: 48.7572673, lng: 18.5449426 },
    { lat: 48.7562244, lng: 18.5445377 },
    { lat: 48.7560315, lng: 18.5444942 },
    { lat: 48.7555123, lng: 18.5443014 },
    { lat: 48.7552719, lng: 18.5441959 },
    { lat: 48.7550159, lng: 18.544053 },
    { lat: 48.7546391, lng: 18.5438232 },
    { lat: 48.7542049, lng: 18.5434927 },
    { lat: 48.7536193, lng: 18.5429909 },
    { lat: 48.7533687, lng: 18.5428426 },
    { lat: 48.7532272, lng: 18.5427916 },
    { lat: 48.7531118, lng: 18.5427045 },
    { lat: 48.7527611, lng: 18.5425627 },
    { lat: 48.7527275, lng: 18.5425454 },
    { lat: 48.7523744, lng: 18.5425676 },
    { lat: 48.7513535, lng: 18.54248 },
    { lat: 48.7508612, lng: 18.5421596 },
    { lat: 48.7501842, lng: 18.5420942 },
    { lat: 48.7498369, lng: 18.5417443 },
    { lat: 48.7496833, lng: 18.5416161 },
    { lat: 48.7495194, lng: 18.5414057 },
    { lat: 48.749339, lng: 18.5411354 },
    { lat: 48.749329, lng: 18.5410962 },
    { lat: 48.749246, lng: 18.5405697 },
    { lat: 48.749135, lng: 18.5398122 },
    { lat: 48.748945, lng: 18.5395353 },
    { lat: 48.748899, lng: 18.539437 },
    { lat: 48.74866, lng: 18.5391723 },
    { lat: 48.7484509, lng: 18.5390622 },
    { lat: 48.7482377, lng: 18.5388311 },
    { lat: 48.7476052, lng: 18.5384842 },
    { lat: 48.7467524, lng: 18.537574 },
    { lat: 48.7466082, lng: 18.5375145 },
    { lat: 48.7463078, lng: 18.5374054 },
    { lat: 48.7460957, lng: 18.5373225 },
    { lat: 48.7459094, lng: 18.5371127 },
    { lat: 48.7455275, lng: 18.5366631 },
    { lat: 48.7454596, lng: 18.5365583 },
    { lat: 48.7453182, lng: 18.5366967 },
    { lat: 48.7452145, lng: 18.5368841 },
    { lat: 48.7449767, lng: 18.5370538 },
    { lat: 48.7449359, lng: 18.5371107 },
    { lat: 48.7448096, lng: 18.5371318 },
    { lat: 48.7447943, lng: 18.5372242 },
    { lat: 48.7448774, lng: 18.5373432 },
    { lat: 48.7448643, lng: 18.5375146 },
    { lat: 48.7448236, lng: 18.537574 },
    { lat: 48.7445583, lng: 18.5375903 },
    { lat: 48.7445059, lng: 18.5377262 },
    { lat: 48.7443442, lng: 18.5377203 },
    { lat: 48.7443118, lng: 18.5377858 },
    { lat: 48.7444115, lng: 18.5381665 },
    { lat: 48.7445604, lng: 18.5383134 },
    { lat: 48.7445531, lng: 18.5384483 },
    { lat: 48.7445268, lng: 18.5384823 },
    { lat: 48.7444713, lng: 18.5384993 },
    { lat: 48.7443025, lng: 18.5384867 },
    { lat: 48.7443025, lng: 18.5386293 },
    { lat: 48.7441474, lng: 18.5387855 },
    { lat: 48.7441462, lng: 18.5389933 },
    { lat: 48.7442025, lng: 18.5390496 },
    { lat: 48.7443362, lng: 18.5389393 },
    { lat: 48.7444063, lng: 18.538982 },
    { lat: 48.7443634, lng: 18.5391049 },
    { lat: 48.7442815, lng: 18.5393028 },
    { lat: 48.7440635, lng: 18.5394836 },
    { lat: 48.7439283, lng: 18.5395477 },
    { lat: 48.743824, lng: 18.5395388 },
    { lat: 48.7435924, lng: 18.5393447 },
    { lat: 48.7433966, lng: 18.5396032 },
    { lat: 48.7432413, lng: 18.5400829 },
    { lat: 48.7433301, lng: 18.5404081 },
    { lat: 48.7433544, lng: 18.5404201 },
    { lat: 48.7433885, lng: 18.5404244 },
    { lat: 48.743389, lng: 18.5405162 },
    { lat: 48.7432313, lng: 18.5409158 },
    { lat: 48.7430088, lng: 18.5412165 },
    { lat: 48.7429329, lng: 18.5414429 },
    { lat: 48.7427997, lng: 18.5415017 },
    { lat: 48.7426686, lng: 18.5414537 },
    { lat: 48.7425652, lng: 18.5414401 },
    { lat: 48.7425398, lng: 18.5413908 },
    { lat: 48.7425061, lng: 18.541167 },
    { lat: 48.7424558, lng: 18.541111 },
    { lat: 48.7423268, lng: 18.541329 },
    { lat: 48.7423762, lng: 18.5414494 },
    { lat: 48.7423189, lng: 18.5416613 },
    { lat: 48.7423235, lng: 18.5420792 },
    { lat: 48.7422993, lng: 18.5421298 },
    { lat: 48.7422103, lng: 18.5422577 },
    { lat: 48.7421768, lng: 18.5424499 },
    { lat: 48.7421619, lng: 18.5426319 },
    { lat: 48.7420714, lng: 18.54275 },
    { lat: 48.7419514, lng: 18.5429494 },
    { lat: 48.741709, lng: 18.5432812 },
    { lat: 48.7415318, lng: 18.5435702 },
    { lat: 48.741061, lng: 18.5438093 },
    { lat: 48.7409941, lng: 18.5438266 },
    { lat: 48.7406596, lng: 18.5440419 },
    { lat: 48.7403508, lng: 18.5443799 },
    { lat: 48.7400088, lng: 18.544787 },
    { lat: 48.7394352, lng: 18.5455002 },
    { lat: 48.7393655, lng: 18.5456093 },
    { lat: 48.7390637, lng: 18.5461779 },
    { lat: 48.7388555, lng: 18.5463957 },
    { lat: 48.7386874, lng: 18.5467651 },
    { lat: 48.738523, lng: 18.5470014 },
    { lat: 48.7384488, lng: 18.5471489 },
    { lat: 48.7380626, lng: 18.5475972 },
    { lat: 48.7380422, lng: 18.5477592 },
    { lat: 48.7379346, lng: 18.5478765 },
    { lat: 48.7378515, lng: 18.547904 },
    { lat: 48.7377742, lng: 18.5478518 },
    { lat: 48.7376782, lng: 18.5476855 },
    { lat: 48.7375788, lng: 18.5476181 },
    { lat: 48.7375317, lng: 18.5476272 },
  ],
  Oslany: [
    { lat: 48.637758, lng: 18.438864 },
    { lat: 48.637632, lng: 18.439006 },
    { lat: 48.637597, lng: 18.439046 },
    { lat: 48.637286, lng: 18.438823 },
    { lat: 48.63706, lng: 18.438669 },
    { lat: 48.637006, lng: 18.438631 },
    { lat: 48.636878, lng: 18.438621 },
    { lat: 48.636784, lng: 18.438697 },
    { lat: 48.636694, lng: 18.438951 },
    { lat: 48.636682, lng: 18.438985 },
    { lat: 48.636651, lng: 18.439077 },
    { lat: 48.636437, lng: 18.43913 },
    { lat: 48.636181, lng: 18.439295 },
    { lat: 48.636112, lng: 18.439374 },
    { lat: 48.636038, lng: 18.439557 },
    { lat: 48.636017, lng: 18.43964 },
    { lat: 48.635983, lng: 18.439713 },
    { lat: 48.635955, lng: 18.439758 },
    { lat: 48.635924, lng: 18.439805 },
    { lat: 48.635896, lng: 18.439853 },
    { lat: 48.63587, lng: 18.439903 },
    { lat: 48.635778, lng: 18.440121 },
    { lat: 48.635758, lng: 18.440192 },
    { lat: 48.635739, lng: 18.440251 },
    { lat: 48.635728, lng: 18.440291 },
    { lat: 48.635707, lng: 18.440351 },
    { lat: 48.63566, lng: 18.440479 },
    { lat: 48.635552, lng: 18.440517 },
    { lat: 48.635482, lng: 18.440548 },
    { lat: 48.635411, lng: 18.440576 },
    { lat: 48.635315, lng: 18.440617 },
    { lat: 48.635124, lng: 18.440681 },
    { lat: 48.634877, lng: 18.440751 },
    { lat: 48.634819, lng: 18.440808 },
    { lat: 48.634804, lng: 18.440842 },
    { lat: 48.634759, lng: 18.440954 },
    { lat: 48.634756, lng: 18.440964 },
    { lat: 48.634726, lng: 18.441044 },
    { lat: 48.634618, lng: 18.441301 },
    { lat: 48.63457, lng: 18.441414 },
    { lat: 48.634504, lng: 18.441562 },
    { lat: 48.634494, lng: 18.441604 },
    { lat: 48.634566, lng: 18.442022 },
    { lat: 48.634521, lng: 18.44226 },
    { lat: 48.634512, lng: 18.442325 },
    { lat: 48.634512, lng: 18.442345 },
    { lat: 48.634535, lng: 18.442573 },
    { lat: 48.634524, lng: 18.442701 },
    { lat: 48.634486, lng: 18.442832 },
    { lat: 48.634437, lng: 18.442949 },
    { lat: 48.634423, lng: 18.442978 },
    { lat: 48.63437, lng: 18.443057 },
    { lat: 48.634302, lng: 18.443164 },
    { lat: 48.63417, lng: 18.443396 },
    { lat: 48.634033, lng: 18.443747 },
    { lat: 48.633796, lng: 18.443842 },
    { lat: 48.633464, lng: 18.443868 },
    { lat: 48.63316, lng: 18.44389 },
    { lat: 48.63295, lng: 18.444244 },
    { lat: 48.632855, lng: 18.444444 },
    { lat: 48.632813, lng: 18.444544 },
    { lat: 48.632803, lng: 18.444567 },
    { lat: 48.632755, lng: 18.444586 },
    { lat: 48.63269, lng: 18.444611 },
    { lat: 48.63266, lng: 18.444715 },
    { lat: 48.63261, lng: 18.444911 },
    { lat: 48.632613, lng: 18.445168 },
    { lat: 48.63261, lng: 18.445193 },
    { lat: 48.632472, lng: 18.445439 },
    { lat: 48.632383, lng: 18.445495 },
    { lat: 48.63214, lng: 18.445647 },
    { lat: 48.631921, lng: 18.445773 },
    { lat: 48.63174, lng: 18.445648 },
    { lat: 48.631664, lng: 18.445827 },
    { lat: 48.631602, lng: 18.446017 },
    { lat: 48.631536, lng: 18.446216 },
    { lat: 48.63151, lng: 18.446301 },
    { lat: 48.631452, lng: 18.446385 },
    { lat: 48.631385, lng: 18.446415 },
    { lat: 48.631279, lng: 18.446415 },
    { lat: 48.631197, lng: 18.446404 },
    { lat: 48.63109, lng: 18.44652 },
    { lat: 48.631004, lng: 18.446673 },
    { lat: 48.630996, lng: 18.44669 },
    { lat: 48.630882, lng: 18.447033 },
    { lat: 48.630396, lng: 18.446481 },
    { lat: 48.630328, lng: 18.446598 },
    { lat: 48.630245, lng: 18.446702 },
    { lat: 48.630081, lng: 18.446975 },
    { lat: 48.629796, lng: 18.447461 },
    { lat: 48.62936, lng: 18.448208 },
    { lat: 48.629293, lng: 18.448322 },
    { lat: 48.629076, lng: 18.448687 },
    { lat: 48.628931, lng: 18.448904 },
    { lat: 48.628713, lng: 18.449276 },
    { lat: 48.628526, lng: 18.449589 },
    { lat: 48.6285, lng: 18.449638 },
    { lat: 48.628339, lng: 18.449922 },
    { lat: 48.628123, lng: 18.450282 },
    { lat: 48.628086, lng: 18.450353 },
    { lat: 48.6278, lng: 18.450823 },
    { lat: 48.627709, lng: 18.450972 },
    { lat: 48.627625, lng: 18.451112 },
    { lat: 48.627563, lng: 18.451212 },
    { lat: 48.627345, lng: 18.451574 },
    { lat: 48.626916, lng: 18.452299 },
    { lat: 48.626685, lng: 18.452679 },
    { lat: 48.626385, lng: 18.453181 },
    { lat: 48.626017, lng: 18.45379 },
    { lat: 48.625919, lng: 18.453959 },
    { lat: 48.625696, lng: 18.454337 },
    { lat: 48.625538, lng: 18.454602 },
    { lat: 48.625127, lng: 18.455301 },
    { lat: 48.624756, lng: 18.455956 },
    { lat: 48.62473, lng: 18.455944 },
    { lat: 48.62462, lng: 18.456189 },
    { lat: 48.624539, lng: 18.456402 },
    { lat: 48.624535, lng: 18.456412 },
    { lat: 48.62453, lng: 18.456448 },
    { lat: 48.624526, lng: 18.45648 },
    { lat: 48.624525, lng: 18.456485 },
    { lat: 48.624489, lng: 18.45656 },
    { lat: 48.624469, lng: 18.456572 },
    { lat: 48.624465, lng: 18.456575 },
    { lat: 48.624461, lng: 18.456577 },
    { lat: 48.624456, lng: 18.45658 },
    { lat: 48.624396, lng: 18.456673 },
    { lat: 48.624388, lng: 18.456738 },
    { lat: 48.624384, lng: 18.456777 },
    { lat: 48.624355, lng: 18.456812 },
    { lat: 48.624335, lng: 18.456837 },
    { lat: 48.624315, lng: 18.456901 },
    { lat: 48.624293, lng: 18.456973 },
    { lat: 48.624286, lng: 18.456997 },
    { lat: 48.624262, lng: 18.457018 },
    { lat: 48.624239, lng: 18.457039 },
    { lat: 48.624186, lng: 18.457178 },
    { lat: 48.62417, lng: 18.457222 },
    { lat: 48.624092, lng: 18.457439 },
    { lat: 48.624051, lng: 18.457487 },
    { lat: 48.62403, lng: 18.457511 },
    { lat: 48.624006, lng: 18.457584 },
    { lat: 48.623968, lng: 18.457706 },
    { lat: 48.623939, lng: 18.457734 },
    { lat: 48.623927, lng: 18.457745 },
    { lat: 48.623879, lng: 18.457867 },
    { lat: 48.623875, lng: 18.457878 },
    { lat: 48.623862, lng: 18.457905 },
    { lat: 48.62386, lng: 18.45791 },
    { lat: 48.623827, lng: 18.458015 },
    { lat: 48.623795, lng: 18.458056 },
    { lat: 48.623782, lng: 18.458072 },
    { lat: 48.623746, lng: 18.458164 },
    { lat: 48.62374, lng: 18.458184 },
    { lat: 48.623653, lng: 18.458412 },
    { lat: 48.623586, lng: 18.458538 },
    { lat: 48.623562, lng: 18.458581 },
    { lat: 48.62354, lng: 18.45864 },
    { lat: 48.6235, lng: 18.458754 },
    { lat: 48.623452, lng: 18.458869 },
    { lat: 48.623412, lng: 18.458955 },
    { lat: 48.623396, lng: 18.458972 },
    { lat: 48.623394, lng: 18.458975 },
    { lat: 48.623389, lng: 18.458987 },
    { lat: 48.623351, lng: 18.4591 },
    { lat: 48.623332, lng: 18.459133 },
    { lat: 48.623292, lng: 18.459226 },
    { lat: 48.623271, lng: 18.459283 },
    { lat: 48.62323, lng: 18.459353 },
    { lat: 48.623198, lng: 18.459407 },
    { lat: 48.623132, lng: 18.459571 },
    { lat: 48.62311, lng: 18.459601 },
    { lat: 48.623106, lng: 18.459623 },
    { lat: 48.623033, lng: 18.459788 },
    { lat: 48.623007, lng: 18.45981 },
    { lat: 48.622964, lng: 18.459897 },
    { lat: 48.622955, lng: 18.459951 },
    { lat: 48.622934, lng: 18.460017 },
    { lat: 48.622888, lng: 18.460117 },
    { lat: 48.622864, lng: 18.460151 },
    { lat: 48.622767, lng: 18.46038 },
    { lat: 48.622691, lng: 18.460556 },
    { lat: 48.622516, lng: 18.460948 },
    { lat: 48.622467, lng: 18.46098 },
    { lat: 48.622428, lng: 18.461128 },
    { lat: 48.622387, lng: 18.461235 },
    { lat: 48.622309, lng: 18.461388 },
    { lat: 48.622227, lng: 18.461494 },
    { lat: 48.622225, lng: 18.461531 },
    { lat: 48.622268, lng: 18.461601 },
    { lat: 48.622288, lng: 18.461634 },
    { lat: 48.622303, lng: 18.461679 },
    { lat: 48.622306, lng: 18.461689 },
    { lat: 48.622298, lng: 18.461694 },
    { lat: 48.622274, lng: 18.461712 },
    { lat: 48.622231, lng: 18.46172 },
    { lat: 48.622201, lng: 18.461725 },
    { lat: 48.622188, lng: 18.461757 },
    { lat: 48.622196, lng: 18.46184 },
    { lat: 48.622204, lng: 18.461921 },
    { lat: 48.622196, lng: 18.461944 },
    { lat: 48.622166, lng: 18.461943 },
    { lat: 48.622113, lng: 18.461941 },
    { lat: 48.622061, lng: 18.462053 },
    { lat: 48.622129, lng: 18.462095 },
    { lat: 48.622154, lng: 18.462111 },
    { lat: 48.622142, lng: 18.462148 },
    { lat: 48.622106, lng: 18.462207 },
    { lat: 48.622086, lng: 18.462241 },
    { lat: 48.622021, lng: 18.462416 },
    { lat: 48.621994, lng: 18.462513 },
    { lat: 48.622, lng: 18.462612 },
    { lat: 48.621953, lng: 18.462679 },
    { lat: 48.621952, lng: 18.462782 },
    { lat: 48.621925, lng: 18.462866 },
    { lat: 48.621871, lng: 18.462918 },
    { lat: 48.621848, lng: 18.462957 },
    { lat: 48.62184, lng: 18.462985 },
    { lat: 48.621844, lng: 18.463098 },
    { lat: 48.621779, lng: 18.463144 },
    { lat: 48.621769, lng: 18.463225 },
    { lat: 48.621778, lng: 18.46325 },
    { lat: 48.621864, lng: 18.463311 },
    { lat: 48.621857, lng: 18.463379 },
    { lat: 48.621816, lng: 18.463467 },
    { lat: 48.621839, lng: 18.463514 },
    { lat: 48.621853, lng: 18.463544 },
    { lat: 48.621841, lng: 18.463604 },
    { lat: 48.6218, lng: 18.463651 },
    { lat: 48.621807, lng: 18.463723 },
    { lat: 48.621809, lng: 18.463748 },
    { lat: 48.621738, lng: 18.463949 },
    { lat: 48.621727, lng: 18.463999 },
    { lat: 48.621712, lng: 18.464077 },
    { lat: 48.621711, lng: 18.464124 },
    { lat: 48.621704, lng: 18.464314 },
    { lat: 48.62167, lng: 18.464378 },
    { lat: 48.621574, lng: 18.464453 },
    { lat: 48.621588, lng: 18.464559 },
    { lat: 48.621581, lng: 18.464584 },
    { lat: 48.621546, lng: 18.464666 },
    { lat: 48.621529, lng: 18.464696 },
    { lat: 48.621465, lng: 18.46462 },
    { lat: 48.621403, lng: 18.464593 },
    { lat: 48.621372, lng: 18.464685 },
    { lat: 48.62133, lng: 18.464704 },
    { lat: 48.621323, lng: 18.464707 },
    { lat: 48.621275, lng: 18.464775 },
    { lat: 48.621226, lng: 18.464831 },
    { lat: 48.621187, lng: 18.464861 },
    { lat: 48.621164, lng: 18.464854 },
    { lat: 48.621118, lng: 18.464841 },
    { lat: 48.621069, lng: 18.464905 },
    { lat: 48.621017, lng: 18.464929 },
    { lat: 48.620971, lng: 18.465067 },
    { lat: 48.620937, lng: 18.465148 },
    { lat: 48.620938, lng: 18.465245 },
    { lat: 48.620927, lng: 18.465271 },
    { lat: 48.620888, lng: 18.465303 },
    { lat: 48.62087, lng: 18.465359 },
    { lat: 48.620842, lng: 18.465397 },
    { lat: 48.62083, lng: 18.465463 },
    { lat: 48.620827, lng: 18.46548 },
    { lat: 48.620845, lng: 18.465572 },
    { lat: 48.620836, lng: 18.465593 },
    { lat: 48.620738, lng: 18.46569 },
    { lat: 48.620661, lng: 18.465771 },
    { lat: 48.620633, lng: 18.465799 },
    { lat: 48.620619, lng: 18.465848 },
    { lat: 48.62059, lng: 18.46594 },
    { lat: 48.620568, lng: 18.465962 },
    { lat: 48.620547, lng: 18.465977 },
    { lat: 48.6205444, lng: 18.4660227 },
    { lat: 48.6206219, lng: 18.4660786 },
    { lat: 48.6205485, lng: 18.466362 },
    { lat: 48.6203888, lng: 18.4667618 },
    { lat: 48.6202638, lng: 18.4671137 },
    { lat: 48.6201163, lng: 18.4676689 },
    { lat: 48.6198692, lng: 18.4689928 },
    { lat: 48.6197765, lng: 18.4693346 },
    { lat: 48.6195381, lng: 18.4700855 },
    { lat: 48.6191758, lng: 18.4710409 },
    { lat: 48.6189896, lng: 18.4715899 },
    { lat: 48.619052, lng: 18.471659 },
    { lat: 48.6187165, lng: 18.4722442 },
    { lat: 48.6185218, lng: 18.4726812 },
    { lat: 48.6184238, lng: 18.4729488 },
    { lat: 48.6181395, lng: 18.473827 },
    { lat: 48.6177815, lng: 18.4748788 },
    { lat: 48.6176333, lng: 18.4751997 },
    { lat: 48.6174492, lng: 18.4759117 },
    { lat: 48.6173951, lng: 18.4763017 },
    { lat: 48.6172058, lng: 18.4767519 },
    { lat: 48.6169648, lng: 18.4772135 },
    { lat: 48.6168195, lng: 18.477399 },
    { lat: 48.6169859, lng: 18.4775521 },
    { lat: 48.6171022, lng: 18.4775745 },
    { lat: 48.6172453, lng: 18.4776518 },
    { lat: 48.6173597, lng: 18.4777415 },
    { lat: 48.6175779, lng: 18.4779772 },
    { lat: 48.6177612, lng: 18.4783129 },
    { lat: 48.6184505, lng: 18.4792129 },
    { lat: 48.6185886, lng: 18.4794073 },
    { lat: 48.6189509, lng: 18.4798218 },
    { lat: 48.6192084, lng: 18.4800711 },
    { lat: 48.6192487, lng: 18.4801456 },
    { lat: 48.619176, lng: 18.4803629 },
    { lat: 48.6191301, lng: 18.4804735 },
    { lat: 48.6190543, lng: 18.480611 },
    { lat: 48.6190588, lng: 18.480732 },
    { lat: 48.6191563, lng: 18.4808011 },
    { lat: 48.6191642, lng: 18.4808635 },
    { lat: 48.6191335, lng: 18.4809208 },
    { lat: 48.6191941, lng: 18.4809778 },
    { lat: 48.6199659, lng: 18.4826151 },
    { lat: 48.620004, lng: 18.4826317 },
    { lat: 48.6200745, lng: 18.4826197 },
    { lat: 48.6202911, lng: 18.483767 },
    { lat: 48.6202854, lng: 18.4839716 },
    { lat: 48.6201216, lng: 18.4843141 },
    { lat: 48.6195652, lng: 18.4852506 },
    { lat: 48.6193628, lng: 18.4854468 },
    { lat: 48.6191964, lng: 18.4855803 },
    { lat: 48.6191293, lng: 18.4855085 },
    { lat: 48.6188359, lng: 18.4859789 },
    { lat: 48.6184736, lng: 18.486487 },
    { lat: 48.6181466, lng: 18.4870004 },
    { lat: 48.6177149, lng: 18.4876211 },
    { lat: 48.617673, lng: 18.4875547 },
    { lat: 48.6174803, lng: 18.4878455 },
    { lat: 48.6172645, lng: 18.4882497 },
    { lat: 48.6169283, lng: 18.4889047 },
    { lat: 48.6170584, lng: 18.4890735 },
    { lat: 48.616805, lng: 18.4895544 },
    { lat: 48.6167201, lng: 18.4894467 },
    { lat: 48.6165636, lng: 18.489677 },
    { lat: 48.6165299, lng: 18.4897614 },
    { lat: 48.6165554, lng: 18.4897946 },
    { lat: 48.6164761, lng: 18.4899735 },
    { lat: 48.6161067, lng: 18.4906405 },
    { lat: 48.6157384, lng: 18.4911797 },
    { lat: 48.6156363, lng: 18.4913144 },
    { lat: 48.6153108, lng: 18.4916308 },
    { lat: 48.6149688, lng: 18.4921 },
    { lat: 48.6145027, lng: 18.4927491 },
    { lat: 48.6143785, lng: 18.4930138 },
    { lat: 48.6142774, lng: 18.4931655 },
    { lat: 48.6135705, lng: 18.494034 },
    { lat: 48.6133079, lng: 18.4944259 },
    { lat: 48.6128499, lng: 18.4950807 },
    { lat: 48.612483, lng: 18.4957533 },
    { lat: 48.6122065, lng: 18.496159 },
    { lat: 48.6118102, lng: 18.4968742 },
    { lat: 48.6118051, lng: 18.4969236 },
    { lat: 48.6116292, lng: 18.4973556 },
    { lat: 48.6115702, lng: 18.4976154 },
    { lat: 48.6112614, lng: 18.4982981 },
    { lat: 48.610728, lng: 18.4994906 },
    { lat: 48.6103168, lng: 18.5004842 },
    { lat: 48.6098981, lng: 18.5013593 },
    { lat: 48.6095141, lng: 18.5021534 },
    { lat: 48.6094843, lng: 18.502269 },
    { lat: 48.6088436, lng: 18.5036645 },
    { lat: 48.6086582, lng: 18.5039635 },
    { lat: 48.6083876, lng: 18.50446 },
    { lat: 48.6082846, lng: 18.5046582 },
    { lat: 48.6080002, lng: 18.505519 },
    { lat: 48.6075415, lng: 18.5067372 },
    { lat: 48.6075007, lng: 18.5067958 },
    { lat: 48.6072979, lng: 18.5071476 },
    { lat: 48.6071466, lng: 18.5075144 },
    { lat: 48.6069691, lng: 18.5078364 },
    { lat: 48.6068087, lng: 18.5081009 },
    { lat: 48.6066467, lng: 18.5084355 },
    { lat: 48.6063707, lng: 18.5088539 },
    { lat: 48.6062171, lng: 18.5091176 },
    { lat: 48.6060929, lng: 18.5094635 },
    { lat: 48.6056799, lng: 18.5102636 },
    { lat: 48.6055432, lng: 18.5104435 },
    { lat: 48.6052393, lng: 18.5107039 },
    { lat: 48.6050975, lng: 18.5109155 },
    { lat: 48.6048408, lng: 18.5115991 },
    { lat: 48.6046959, lng: 18.5118574 },
    { lat: 48.6044252, lng: 18.5123093 },
    { lat: 48.604263, lng: 18.5125405 },
    { lat: 48.603885, lng: 18.5131127 },
    { lat: 48.6036666, lng: 18.5134536 },
    { lat: 48.6035047, lng: 18.5136263 },
    { lat: 48.6034018, lng: 18.513806 },
    { lat: 48.6030424, lng: 18.5143284 },
    { lat: 48.6028111, lng: 18.5147575 },
    { lat: 48.6026008, lng: 18.5151199 },
    { lat: 48.6024274, lng: 18.5154327 },
    { lat: 48.6021871, lng: 18.5155988 },
    { lat: 48.6021417, lng: 18.5157142 },
    { lat: 48.6018021, lng: 18.5163753 },
    { lat: 48.6014497, lng: 18.5169001 },
    { lat: 48.6011871, lng: 18.5173965 },
    { lat: 48.6009307, lng: 18.5177813 },
    { lat: 48.6007484, lng: 18.5182627 },
    { lat: 48.6006305, lng: 18.5184802 },
    { lat: 48.5999763, lng: 18.5196099 },
    { lat: 48.5987599, lng: 18.5217223 },
    { lat: 48.5981657, lng: 18.5227964 },
    { lat: 48.5974907, lng: 18.524023 },
    { lat: 48.5966127, lng: 18.5256226 },
    { lat: 48.5963425, lng: 18.526105 },
    { lat: 48.5962041, lng: 18.5264173 },
    { lat: 48.596024, lng: 18.5273338 },
    { lat: 48.595871, lng: 18.5279194 },
    { lat: 48.5956219, lng: 18.5285366 },
    { lat: 48.595453, lng: 18.5288933 },
    { lat: 48.5952571, lng: 18.5292513 },
    { lat: 48.59506, lng: 18.5297098 },
    { lat: 48.595, lng: 18.5299749 },
    { lat: 48.5949534, lng: 18.5302377 },
    { lat: 48.5948634, lng: 18.5308604 },
    { lat: 48.5948292, lng: 18.531056 },
    { lat: 48.5946321, lng: 18.5320106 },
    { lat: 48.5945722, lng: 18.5327507 },
    { lat: 48.5944983, lng: 18.5334604 },
    { lat: 48.5943654, lng: 18.5341853 },
    { lat: 48.5943113, lng: 18.534744 },
    { lat: 48.5940219, lng: 18.5357453 },
    { lat: 48.5938045, lng: 18.5366572 },
    { lat: 48.5934146, lng: 18.5378605 },
    { lat: 48.5929397, lng: 18.5389707 },
    { lat: 48.5926294, lng: 18.5395259 },
    { lat: 48.5925435, lng: 18.5397108 },
    { lat: 48.5925238, lng: 18.5398042 },
    { lat: 48.5924078, lng: 18.5402419 },
    { lat: 48.592328, lng: 18.5406587 },
    { lat: 48.5922626, lng: 18.540803 },
    { lat: 48.5922574, lng: 18.5407901 },
    { lat: 48.5921881, lng: 18.5408835 },
    { lat: 48.5919368, lng: 18.5415703 },
    { lat: 48.5919025, lng: 18.5416049 },
    { lat: 48.591903, lng: 18.5416747 },
    { lat: 48.591835, lng: 18.5418534 },
    { lat: 48.5918186, lng: 18.541946 },
    { lat: 48.5918263, lng: 18.5420273 },
    { lat: 48.5918748, lng: 18.5421913 },
    { lat: 48.5920582, lng: 18.5425109 },
    { lat: 48.5920913, lng: 18.5427445 },
    { lat: 48.5921594, lng: 18.5429626 },
    { lat: 48.5921595, lng: 18.5431504 },
    { lat: 48.5921494, lng: 18.5433163 },
    { lat: 48.5921728, lng: 18.5433935 },
    { lat: 48.5921725, lng: 18.543488 },
    { lat: 48.5921421, lng: 18.5436753 },
    { lat: 48.5921174, lng: 18.5439449 },
    { lat: 48.5920729, lng: 18.5440822 },
    { lat: 48.5919883, lng: 18.5442477 },
    { lat: 48.5919744, lng: 18.5443442 },
    { lat: 48.5919918, lng: 18.5446011 },
    { lat: 48.5919699, lng: 18.5446933 },
    { lat: 48.5916974, lng: 18.5448508 },
    { lat: 48.59159, lng: 18.5449874 },
    { lat: 48.5914569, lng: 18.5452152 },
    { lat: 48.5914, lng: 18.5454111 },
    { lat: 48.5913863, lng: 18.5454951 },
    { lat: 48.5913324, lng: 18.5456205 },
    { lat: 48.591223, lng: 18.545664 },
    { lat: 48.5911713, lng: 18.5457299 },
    { lat: 48.5911143, lng: 18.5458806 },
    { lat: 48.591057, lng: 18.5461339 },
    { lat: 48.5910642, lng: 18.5464309 },
    { lat: 48.5910513, lng: 18.5464631 },
    { lat: 48.5909256, lng: 18.5466198 },
    { lat: 48.5908858, lng: 18.5466558 },
    { lat: 48.5908489, lng: 18.5467042 },
    { lat: 48.5907865, lng: 18.5469414 },
    { lat: 48.590749, lng: 18.5470604 },
    { lat: 48.5906974, lng: 18.5473141 },
    { lat: 48.5906839, lng: 18.5474618 },
    { lat: 48.5907026, lng: 18.5474912 },
    { lat: 48.5906678, lng: 18.5477642 },
    { lat: 48.5907034, lng: 18.5480259 },
    { lat: 48.5906251, lng: 18.5481597 },
    { lat: 48.5906018, lng: 18.5482306 },
    { lat: 48.5905948, lng: 18.5482878 },
    { lat: 48.5906213, lng: 18.54838 },
    { lat: 48.590667, lng: 18.5484716 },
    { lat: 48.5907901, lng: 18.5486394 },
    { lat: 48.5909167, lng: 18.5488724 },
    { lat: 48.5910146, lng: 18.5491375 },
    { lat: 48.5911429, lng: 18.5493169 },
    { lat: 48.5911586, lng: 18.5494624 },
    { lat: 48.5911273, lng: 18.5496127 },
    { lat: 48.5911173, lng: 18.5497586 },
    { lat: 48.5911921, lng: 18.5499108 },
    { lat: 48.5912808, lng: 18.5501506 },
    { lat: 48.5914135, lng: 18.5503301 },
    { lat: 48.5914242, lng: 18.5503612 },
    { lat: 48.5914323, lng: 18.5504447 },
    { lat: 48.591406, lng: 18.5506478 },
    { lat: 48.5914001, lng: 18.5507437 },
    { lat: 48.591418, lng: 18.5507632 },
    { lat: 48.5914871, lng: 18.5508084 },
    { lat: 48.5914914, lng: 18.550866 },
    { lat: 48.591485, lng: 18.5509776 },
    { lat: 48.5913182, lng: 18.5512905 },
    { lat: 48.5912986, lng: 18.5513431 },
    { lat: 48.5912833, lng: 18.5514141 },
    { lat: 48.5912756, lng: 18.551502 },
    { lat: 48.5912796, lng: 18.5515963 },
    { lat: 48.5913037, lng: 18.5517697 },
    { lat: 48.59127, lng: 18.5519367 },
    { lat: 48.5912508, lng: 18.5519787 },
    { lat: 48.5912175, lng: 18.552027 },
    { lat: 48.5911462, lng: 18.5520853 },
    { lat: 48.590974, lng: 18.5524648 },
    { lat: 48.5909477, lng: 18.5525414 },
    { lat: 48.5909288, lng: 18.55265 },
    { lat: 48.5908845, lng: 18.5527898 },
    { lat: 48.5908311, lng: 18.5528862 },
    { lat: 48.5906099, lng: 18.5529867 },
    { lat: 48.5904518, lng: 18.5533561 },
    { lat: 48.5903658, lng: 18.553494 },
    { lat: 48.5902856, lng: 18.5535367 },
    { lat: 48.5902036, lng: 18.5535405 },
    { lat: 48.5901263, lng: 18.5535707 },
    { lat: 48.589988, lng: 18.5537222 },
    { lat: 48.5897673, lng: 18.553854 },
    { lat: 48.589657, lng: 18.5538594 },
    { lat: 48.589578, lng: 18.5539202 },
    { lat: 48.5895638, lng: 18.5539921 },
    { lat: 48.5894885, lng: 18.5540592 },
    { lat: 48.5893258, lng: 18.5541146 },
    { lat: 48.5891327, lng: 18.5541314 },
    { lat: 48.5890884, lng: 18.5541473 },
    { lat: 48.5889803, lng: 18.5542323 },
    { lat: 48.5888644, lng: 18.5544262 },
    { lat: 48.5887735, lng: 18.5545159 },
    { lat: 48.5887209, lng: 18.5545252 },
    { lat: 48.5885769, lng: 18.554407 },
    { lat: 48.588311, lng: 18.5544339 },
    { lat: 48.5882166, lng: 18.5545414 },
    { lat: 48.5881775, lng: 18.554651 },
    { lat: 48.5880914, lng: 18.5548327 },
    { lat: 48.5880387, lng: 18.5548809 },
    { lat: 48.5880117, lng: 18.5548796 },
    { lat: 48.5879109, lng: 18.5548334 },
    { lat: 48.587842, lng: 18.5548925 },
    { lat: 48.5877812, lng: 18.5550988 },
    { lat: 48.5876762, lng: 18.5551639 },
    { lat: 48.5875954, lng: 18.5552508 },
    { lat: 48.5875026, lng: 18.5554512 },
    { lat: 48.5873604, lng: 18.5556576 },
    { lat: 48.5873504, lng: 18.5558256 },
    { lat: 48.5873078, lng: 18.5561009 },
    { lat: 48.5872756, lng: 18.5562129 },
    { lat: 48.5871671, lng: 18.5562915 },
    { lat: 48.5870614, lng: 18.5564428 },
    { lat: 48.5870134, lng: 18.5566195 },
    { lat: 48.5868895, lng: 18.5566843 },
    { lat: 48.5868643, lng: 18.5567152 },
    { lat: 48.5867748, lng: 18.556877 },
    { lat: 48.5866321, lng: 18.5570091 },
    { lat: 48.5866169, lng: 18.5570413 },
    { lat: 48.5866161, lng: 18.5576263 },
    { lat: 48.5864375, lng: 18.557931 },
    { lat: 48.5862636, lng: 18.5579885 },
    { lat: 48.5861692, lng: 18.5581598 },
    { lat: 48.586044, lng: 18.5585341 },
    { lat: 48.5858419, lng: 18.5588374 },
    { lat: 48.5857418, lng: 18.5589043 },
    { lat: 48.5856842, lng: 18.5589666 },
    { lat: 48.5856678, lng: 18.5590171 },
    { lat: 48.58553, lng: 18.5596769 },
    { lat: 48.5855128, lng: 18.559777 },
    { lat: 48.5854682, lng: 18.5601555 },
    { lat: 48.5854502, lng: 18.5602198 },
    { lat: 48.5853995, lng: 18.560283 },
    { lat: 48.5852241, lng: 18.5604384 },
    { lat: 48.5850608, lng: 18.5606303 },
    { lat: 48.5850343, lng: 18.5607041 },
    { lat: 48.5850023, lng: 18.5608406 },
    { lat: 48.5849402, lng: 18.5609028 },
    { lat: 48.584839, lng: 18.5612189 },
    { lat: 48.5847318, lng: 18.5614637 },
    { lat: 48.5847345, lng: 18.5617206 },
    { lat: 48.5846571, lng: 18.5619589 },
    { lat: 48.5844957, lng: 18.5623889 },
    { lat: 48.5842237, lng: 18.5626143 },
    { lat: 48.5842079, lng: 18.5626543 },
    { lat: 48.5842611, lng: 18.5630322 },
    { lat: 48.5843015, lng: 18.5633795 },
    { lat: 48.584276, lng: 18.5635319 },
    { lat: 48.5842568, lng: 18.5635731 },
    { lat: 48.5842895, lng: 18.5636802 },
    { lat: 48.5844087, lng: 18.5638151 },
    { lat: 48.584465, lng: 18.5638341 },
    { lat: 48.5845101, lng: 18.5641449 },
    { lat: 48.5844977, lng: 18.5641646 },
    { lat: 48.5845144, lng: 18.5644109 },
    { lat: 48.5844741, lng: 18.5645391 },
    { lat: 48.5845657, lng: 18.5648363 },
    { lat: 48.5845991, lng: 18.5651368 },
    { lat: 48.5847561, lng: 18.5657415 },
    { lat: 48.5848151, lng: 18.5657698 },
    { lat: 48.5848211, lng: 18.5657983 },
    { lat: 48.584763, lng: 18.5659117 },
    { lat: 48.5847454, lng: 18.5660229 },
    { lat: 48.5847236, lng: 18.5660769 },
    { lat: 48.5847182, lng: 18.5662036 },
    { lat: 48.5847668, lng: 18.5662714 },
    { lat: 48.5847388, lng: 18.5666047 },
    { lat: 48.5846862, lng: 18.5667972 },
    { lat: 48.5846759, lng: 18.5668159 },
    { lat: 48.5846302, lng: 18.5668273 },
    { lat: 48.5846625, lng: 18.5670039 },
    { lat: 48.5845697, lng: 18.5672274 },
    { lat: 48.584593, lng: 18.5673649 },
    { lat: 48.5845875, lng: 18.5674322 },
    { lat: 48.5845595, lng: 18.5675564 },
    { lat: 48.5844807, lng: 18.5676253 },
    { lat: 48.5844427, lng: 18.5680247 },
    { lat: 48.5844608, lng: 18.5683135 },
    { lat: 48.5844944, lng: 18.5685791 },
    { lat: 48.5844875, lng: 18.5687833 },
    { lat: 48.5844637, lng: 18.5689719 },
    { lat: 48.5844686, lng: 18.5689955 },
    { lat: 48.5845184, lng: 18.5690649 },
    { lat: 48.5844853, lng: 18.5693027 },
    { lat: 48.5844847, lng: 18.5694175 },
    { lat: 48.5845928, lng: 18.5696403 },
    { lat: 48.5847046, lng: 18.5700572 },
    { lat: 48.5847205, lng: 18.570228 },
    { lat: 48.5849393, lng: 18.5706619 },
    { lat: 48.5850279, lng: 18.5708826 },
    { lat: 48.5850516, lng: 18.5709017 },
    { lat: 48.5850943, lng: 18.5709214 },
    { lat: 48.5851254, lng: 18.5710533 },
    { lat: 48.5852119, lng: 18.5711537 },
    { lat: 48.5853181, lng: 18.5711328 },
    { lat: 48.5854022, lng: 18.571189 },
    { lat: 48.5855557, lng: 18.5713567 },
    { lat: 48.585643, lng: 18.5715315 },
    { lat: 48.5856892, lng: 18.571673 },
    { lat: 48.5856863, lng: 18.5717446 },
    { lat: 48.5859977, lng: 18.5720606 },
    { lat: 48.5860508, lng: 18.5721402 },
    { lat: 48.5861008, lng: 18.5722741 },
    { lat: 48.5861225, lng: 18.5724035 },
    { lat: 48.5861091, lng: 18.5725299 },
    { lat: 48.5861685, lng: 18.5726658 },
    { lat: 48.5861795, lng: 18.5729505 },
    { lat: 48.5863526, lng: 18.5731913 },
    { lat: 48.5865988, lng: 18.5735895 },
    { lat: 48.5866958, lng: 18.5736954 },
    { lat: 48.5867367, lng: 18.5739704 },
    { lat: 48.5868797, lng: 18.5742346 },
    { lat: 48.5869047, lng: 18.5744249 },
    { lat: 48.5870106, lng: 18.5747132 },
    { lat: 48.5872301, lng: 18.5750588 },
    { lat: 48.5873326, lng: 18.5753881 },
    { lat: 48.5874477, lng: 18.5755959 },
    { lat: 48.5874184, lng: 18.575762 },
    { lat: 48.5874635, lng: 18.5759481 },
    { lat: 48.5876481, lng: 18.5761918 },
    { lat: 48.5876926, lng: 18.5765319 },
    { lat: 48.5877549, lng: 18.5766389 },
    { lat: 48.58781, lng: 18.5768038 },
    { lat: 48.5878874, lng: 18.5768758 },
    { lat: 48.5879162, lng: 18.5770148 },
    { lat: 48.5879014, lng: 18.5775507 },
    { lat: 48.5878602, lng: 18.577746 },
    { lat: 48.5879367, lng: 18.5780743 },
    { lat: 48.5879376, lng: 18.5785481 },
    { lat: 48.5879937, lng: 18.5789965 },
    { lat: 48.5880387, lng: 18.5792859 },
    { lat: 48.588067, lng: 18.5795813 },
    { lat: 48.5880421, lng: 18.579848 },
    { lat: 48.5881129, lng: 18.580342 },
    { lat: 48.5881442, lng: 18.5807101 },
    { lat: 48.5881428, lng: 18.5807281 },
    { lat: 48.5881649, lng: 18.580947 },
    { lat: 48.588135, lng: 18.5811014 },
    { lat: 48.5881328, lng: 18.58131 },
    { lat: 48.5882404, lng: 18.5815066 },
    { lat: 48.5883254, lng: 18.5817833 },
    { lat: 48.5883261, lng: 18.5818352 },
    { lat: 48.5883577, lng: 18.5820251 },
    { lat: 48.5885002, lng: 18.5826563 },
    { lat: 48.5885212, lng: 18.5829783 },
    { lat: 48.5885133, lng: 18.5831018 },
    { lat: 48.5884893, lng: 18.5843019 },
    { lat: 48.5884601, lng: 18.5853872 },
    { lat: 48.5888148, lng: 18.5861871 },
    { lat: 48.5894565, lng: 18.5864529 },
    { lat: 48.5898106, lng: 18.5865353 },
    { lat: 48.5905169, lng: 18.5870817 },
    { lat: 48.5909636, lng: 18.5878479 },
    { lat: 48.591058, lng: 18.5889646 },
    { lat: 48.5910496, lng: 18.5900791 },
    { lat: 48.5908731, lng: 18.5916928 },
    { lat: 48.5910809, lng: 18.5930275 },
    { lat: 48.591271, lng: 18.5940573 },
    { lat: 48.5913286, lng: 18.5951419 },
    { lat: 48.5915999, lng: 18.5959664 },
    { lat: 48.5917794, lng: 18.5966751 },
    { lat: 48.5919686, lng: 18.5977759 },
    { lat: 48.5930747, lng: 18.5980364 },
    { lat: 48.5956366, lng: 18.5987529 },
    { lat: 48.59615, lng: 18.59813 },
    { lat: 48.5967872, lng: 18.5964241 },
    { lat: 48.5975183, lng: 18.5947399 },
    { lat: 48.5979122, lng: 18.5941415 },
    { lat: 48.598162, lng: 18.5931504 },
    { lat: 48.5988861, lng: 18.5919672 },
    { lat: 48.5991678, lng: 18.5914831 },
    { lat: 48.5994382, lng: 18.5910204 },
    { lat: 48.6000415, lng: 18.5904725 },
    { lat: 48.6006308, lng: 18.5897986 },
    { lat: 48.601368, lng: 18.588644 },
    { lat: 48.6016934, lng: 18.5882201 },
    { lat: 48.6022226, lng: 18.5877443 },
    { lat: 48.6025685, lng: 18.587342 },
    { lat: 48.6030347, lng: 18.5872029 },
    { lat: 48.6033867, lng: 18.5869963 },
    { lat: 48.6040004, lng: 18.5871466 },
    { lat: 48.6047123, lng: 18.5867922 },
    { lat: 48.6046705, lng: 18.5866273 },
    { lat: 48.6047327, lng: 18.5861499 },
    { lat: 48.6051365, lng: 18.5847398 },
    { lat: 48.6052491, lng: 18.584048 },
    { lat: 48.6052405, lng: 18.5835159 },
    { lat: 48.6053427, lng: 18.5831743 },
    { lat: 48.6064577, lng: 18.5805886 },
    { lat: 48.6068115, lng: 18.5797707 },
    { lat: 48.6071042, lng: 18.5793885 },
    { lat: 48.6073823, lng: 18.5790252 },
    { lat: 48.6076372, lng: 18.578071 },
    { lat: 48.607758, lng: 18.577531 },
    { lat: 48.6080662, lng: 18.5766646 },
    { lat: 48.6081864, lng: 18.576324 },
    { lat: 48.6085335, lng: 18.5752988 },
    { lat: 48.6074518, lng: 18.5742406 },
    { lat: 48.6099061, lng: 18.5728295 },
    { lat: 48.6107721, lng: 18.5723849 },
    { lat: 48.6112015, lng: 18.5714285 },
    { lat: 48.6110477, lng: 18.571295 },
    { lat: 48.6107245, lng: 18.5704535 },
    { lat: 48.610963, lng: 18.5697977 },
    { lat: 48.6105838, lng: 18.5687586 },
    { lat: 48.6104671, lng: 18.5676516 },
    { lat: 48.6097439, lng: 18.5657408 },
    { lat: 48.609452, lng: 18.5653118 },
    { lat: 48.60912, lng: 18.5642668 },
    { lat: 48.6089441, lng: 18.5635023 },
    { lat: 48.6089184, lng: 18.5629038 },
    { lat: 48.6091282, lng: 18.5621814 },
    { lat: 48.6091584, lng: 18.5612441 },
    { lat: 48.6091647, lng: 18.5600351 },
    { lat: 48.609438, lng: 18.5578455 },
    { lat: 48.6094448, lng: 18.5576183 },
    { lat: 48.6094555, lng: 18.5572555 },
    { lat: 48.6092162, lng: 18.5564034 },
    { lat: 48.6093205, lng: 18.5552744 },
    { lat: 48.6096141, lng: 18.5544546 },
    { lat: 48.6099731, lng: 18.5538996 },
    { lat: 48.6093403, lng: 18.5515625 },
    { lat: 48.6092635, lng: 18.55096 },
    { lat: 48.6090633, lng: 18.5504973 },
    { lat: 48.609065, lng: 18.5497653 },
    { lat: 48.6093422, lng: 18.5488886 },
    { lat: 48.6095044, lng: 18.5484924 },
    { lat: 48.6096068, lng: 18.548242 },
    { lat: 48.6099463, lng: 18.5477671 },
    { lat: 48.6102678, lng: 18.5467719 },
    { lat: 48.610973, lng: 18.5460338 },
    { lat: 48.6111807, lng: 18.5457888 },
    { lat: 48.6116767, lng: 18.5449559 },
    { lat: 48.6122147, lng: 18.5443144 },
    { lat: 48.6128401, lng: 18.5436001 },
    { lat: 48.6133246, lng: 18.5427043 },
    { lat: 48.6138101, lng: 18.541924 },
    { lat: 48.6141805, lng: 18.5415446 },
    { lat: 48.6145513, lng: 18.5411663 },
    { lat: 48.615427, lng: 18.5411694 },
    { lat: 48.6158732, lng: 18.5406673 },
    { lat: 48.6164207, lng: 18.5402399 },
    { lat: 48.6169682, lng: 18.5398167 },
    { lat: 48.6176941, lng: 18.5393329 },
    { lat: 48.6177966, lng: 18.5392432 },
    { lat: 48.6184401, lng: 18.5386797 },
    { lat: 48.6190933, lng: 18.5381629 },
    { lat: 48.619627, lng: 18.5375685 },
    { lat: 48.6199524, lng: 18.5370186 },
    { lat: 48.620403, lng: 18.5359992 },
    { lat: 48.6208448, lng: 18.5352094 },
    { lat: 48.6212363, lng: 18.53457 },
    { lat: 48.6215599, lng: 18.53374 },
    { lat: 48.6220698, lng: 18.5326091 },
    { lat: 48.6220901, lng: 18.5325648 },
    { lat: 48.6223864, lng: 18.5312633 },
    { lat: 48.6221489, lng: 18.5301798 },
    { lat: 48.622387, lng: 18.5288812 },
    { lat: 48.622672, lng: 18.5280727 },
    { lat: 48.6230336, lng: 18.5265548 },
    { lat: 48.6226161, lng: 18.5251557 },
    { lat: 48.6229158, lng: 18.5244869 },
    { lat: 48.6231237, lng: 18.5239194 },
    { lat: 48.6233223, lng: 18.5232599 },
    { lat: 48.6233424, lng: 18.523113 },
    { lat: 48.6237487, lng: 18.5224773 },
    { lat: 48.6239403, lng: 18.5218597 },
    { lat: 48.6240345, lng: 18.5213527 },
    { lat: 48.6240862, lng: 18.5207309 },
    { lat: 48.6237973, lng: 18.5190654 },
    { lat: 48.6238012, lng: 18.5184766 },
    { lat: 48.6238494, lng: 18.5182424 },
    { lat: 48.6240752, lng: 18.5175496 },
    { lat: 48.6244273, lng: 18.516768 },
    { lat: 48.6245453, lng: 18.5164324 },
    { lat: 48.6244745, lng: 18.5156543 },
    { lat: 48.6245444, lng: 18.5144418 },
    { lat: 48.6245445, lng: 18.5135789 },
    { lat: 48.6245268, lng: 18.5126188 },
    { lat: 48.6244525, lng: 18.5119024 },
    { lat: 48.6243988, lng: 18.5113158 },
    { lat: 48.624306, lng: 18.5102413 },
    { lat: 48.6242847, lng: 18.5095034 },
    { lat: 48.6243277, lng: 18.5087247 },
    { lat: 48.6243825, lng: 18.508285 },
    { lat: 48.6245211, lng: 18.5076059 },
    { lat: 48.6247069, lng: 18.5067579 },
    { lat: 48.6248367, lng: 18.5059373 },
    { lat: 48.6249451, lng: 18.5054652 },
    { lat: 48.6250811, lng: 18.5048434 },
    { lat: 48.6253834, lng: 18.5041631 },
    { lat: 48.6257349, lng: 18.5035394 },
    { lat: 48.6264011, lng: 18.5030802 },
    { lat: 48.6265207, lng: 18.5027918 },
    { lat: 48.6265236, lng: 18.5027846 },
    { lat: 48.6265813, lng: 18.5026437 },
    { lat: 48.6265614, lng: 18.5024059 },
    { lat: 48.6265234, lng: 18.5019606 },
    { lat: 48.6268095, lng: 18.5014802 },
    { lat: 48.6268534, lng: 18.5008642 },
    { lat: 48.627713, lng: 18.5004108 },
    { lat: 48.6280447, lng: 18.5001667 },
    { lat: 48.628309, lng: 18.5002734 },
    { lat: 48.6286145, lng: 18.4998811 },
    { lat: 48.6283023, lng: 18.4995582 },
    { lat: 48.6282544, lng: 18.499509 },
    { lat: 48.6280597, lng: 18.4992916 },
    { lat: 48.6282576, lng: 18.4990118 },
    { lat: 48.6284707, lng: 18.4988853 },
    { lat: 48.6287828, lng: 18.4988188 },
    { lat: 48.6294236, lng: 18.4984704 },
    { lat: 48.629763, lng: 18.4984683 },
    { lat: 48.6299593, lng: 18.498264 },
    { lat: 48.6302004, lng: 18.4981354 },
    { lat: 48.6302585, lng: 18.4981042 },
    { lat: 48.6302897, lng: 18.4980357 },
    { lat: 48.6307269, lng: 18.4970841 },
    { lat: 48.6313662, lng: 18.4952563 },
    { lat: 48.6314063, lng: 18.4949582 },
    { lat: 48.6348863, lng: 18.4868039 },
    { lat: 48.6352274, lng: 18.4863791 },
    { lat: 48.6353006, lng: 18.4859869 },
    { lat: 48.6348465, lng: 18.4854462 },
    { lat: 48.6347911, lng: 18.4853811 },
    { lat: 48.6347645, lng: 18.4853491 },
    { lat: 48.6355338, lng: 18.4839355 },
    { lat: 48.6361043, lng: 18.4828863 },
    { lat: 48.6361338, lng: 18.4828505 },
    { lat: 48.6361903, lng: 18.4827475 },
    { lat: 48.6362764, lng: 18.4825905 },
    { lat: 48.6363408, lng: 18.4824725 },
    { lat: 48.6364125, lng: 18.4823405 },
    { lat: 48.6364719, lng: 18.482231 },
    { lat: 48.6366018, lng: 18.4819893 },
    { lat: 48.6367329, lng: 18.4817471 },
    { lat: 48.6367503, lng: 18.4817153 },
    { lat: 48.636864, lng: 18.4815052 },
    { lat: 48.6369939, lng: 18.4812635 },
    { lat: 48.6371486, lng: 18.4809777 },
    { lat: 48.6373031, lng: 18.4806933 },
    { lat: 48.6373185, lng: 18.4806632 },
    { lat: 48.6374371, lng: 18.4804432 },
    { lat: 48.6375682, lng: 18.480201 },
    { lat: 48.6376368, lng: 18.4800743 },
    { lat: 48.6377089, lng: 18.4799714 },
    { lat: 48.6377685, lng: 18.4798858 },
    { lat: 48.6377993, lng: 18.4798092 },
    { lat: 48.6378101, lng: 18.4798 },
    { lat: 48.6378645, lng: 18.479745 },
    { lat: 48.6378809, lng: 18.479733 },
    { lat: 48.6379739, lng: 18.4796621 },
    { lat: 48.6381586, lng: 18.4795312 },
    { lat: 48.6381945, lng: 18.4795085 },
    { lat: 48.638226, lng: 18.4794885 },
    { lat: 48.6382598, lng: 18.4794671 },
    { lat: 48.638297, lng: 18.4794432 },
    { lat: 48.6383317, lng: 18.4794217 },
    { lat: 48.6383656, lng: 18.4794007 },
    { lat: 48.6384062, lng: 18.4793788 },
    { lat: 48.6384422, lng: 18.479359 },
    { lat: 48.6384872, lng: 18.4793344 },
    { lat: 48.6385453, lng: 18.4793032 },
    { lat: 48.6386179, lng: 18.4792909 },
    { lat: 48.6387333, lng: 18.4792368 },
    { lat: 48.6387875, lng: 18.4792154 },
    { lat: 48.6388515, lng: 18.4791902 },
    { lat: 48.6389087, lng: 18.4791845 },
    { lat: 48.6389705, lng: 18.4791778 },
    { lat: 48.6390261, lng: 18.4791879 },
    { lat: 48.6391354, lng: 18.4792231 },
    { lat: 48.6392387, lng: 18.4792579 },
    { lat: 48.639332, lng: 18.479269 },
    { lat: 48.6394053, lng: 18.4792698 },
    { lat: 48.6395002, lng: 18.4792721 },
    { lat: 48.6395914, lng: 18.4792714 },
    { lat: 48.63969, lng: 18.4792965 },
    { lat: 48.6397742, lng: 18.479273 },
    { lat: 48.6398544, lng: 18.4792305 },
    { lat: 48.6398957, lng: 18.4792029 },
    { lat: 48.639994, lng: 18.479208 },
    { lat: 48.6407268, lng: 18.4781148 },
    { lat: 48.640648, lng: 18.4780365 },
    { lat: 48.640851, lng: 18.477628 },
    { lat: 48.641047, lng: 18.4771307 },
    { lat: 48.6412268, lng: 18.4762781 },
    { lat: 48.6413163, lng: 18.4759591 },
    { lat: 48.6414919, lng: 18.4751744 },
    { lat: 48.641612, lng: 18.4746353 },
    { lat: 48.641917, lng: 18.4737612 },
    { lat: 48.6419325, lng: 18.4736969 },
    { lat: 48.64196, lng: 18.4735818 },
    { lat: 48.6419712, lng: 18.4735361 },
    { lat: 48.6421613, lng: 18.4730795 },
    { lat: 48.6428553, lng: 18.470775 },
    { lat: 48.6431021, lng: 18.470586 },
    { lat: 48.6432447, lng: 18.4704507 },
    { lat: 48.6434755, lng: 18.4701145 },
    { lat: 48.6436634, lng: 18.4698391 },
    { lat: 48.643918, lng: 18.4693872 },
    { lat: 48.6441173, lng: 18.4691352 },
    { lat: 48.6442345, lng: 18.4689882 },
    { lat: 48.6443073, lng: 18.4688766 },
    { lat: 48.6443222, lng: 18.4687991 },
    { lat: 48.6446265, lng: 18.4678558 },
    { lat: 48.6449139, lng: 18.4670749 },
    { lat: 48.6450437, lng: 18.4666701 },
    { lat: 48.64525, lng: 18.4654195 },
    { lat: 48.6455845, lng: 18.4642273 },
    { lat: 48.6456547, lng: 18.4640076 },
    { lat: 48.6458006, lng: 18.4636065 },
    { lat: 48.6459566, lng: 18.4632048 },
    { lat: 48.6463137, lng: 18.4628694 },
    { lat: 48.646635, lng: 18.4623324 },
    { lat: 48.6466664, lng: 18.4623106 },
    { lat: 48.6467744, lng: 18.4621429 },
    { lat: 48.6468233, lng: 18.4620657 },
    { lat: 48.647713, lng: 18.460415 },
    { lat: 48.64736, lng: 18.460532 },
    { lat: 48.646819, lng: 18.460521 },
    { lat: 48.646523, lng: 18.460513 },
    { lat: 48.646483, lng: 18.460512 },
    { lat: 48.646346, lng: 18.460508 },
    { lat: 48.646334, lng: 18.460509 },
    { lat: 48.646263, lng: 18.460483 },
    { lat: 48.646183, lng: 18.460454 },
    { lat: 48.646142, lng: 18.460439 },
    { lat: 48.646154, lng: 18.460341 },
    { lat: 48.646167, lng: 18.460213 },
    { lat: 48.646117, lng: 18.460015 },
    { lat: 48.646065, lng: 18.459952 },
    { lat: 48.6459, lng: 18.459748 },
    { lat: 48.645663, lng: 18.459444 },
    { lat: 48.645523, lng: 18.459262 },
    { lat: 48.64541, lng: 18.459139 },
    { lat: 48.645309, lng: 18.459027 },
    { lat: 48.645277, lng: 18.458991 },
    { lat: 48.64523, lng: 18.458962 },
    { lat: 48.645078, lng: 18.458865 },
    { lat: 48.644866, lng: 18.458766 },
    { lat: 48.644803, lng: 18.458722 },
    { lat: 48.644541, lng: 18.458546 },
    { lat: 48.644495, lng: 18.458515 },
    { lat: 48.644391, lng: 18.458446 },
    { lat: 48.644319, lng: 18.458406 },
    { lat: 48.644263, lng: 18.458389 },
    { lat: 48.644063, lng: 18.458318 },
    { lat: 48.643854, lng: 18.458243 },
    { lat: 48.643816, lng: 18.458094 },
    { lat: 48.64378, lng: 18.457952 },
    { lat: 48.643718, lng: 18.457802 },
    { lat: 48.643708, lng: 18.457775 },
    { lat: 48.643559, lng: 18.457405 },
    { lat: 48.643574, lng: 18.457387 },
    { lat: 48.643641, lng: 18.457303 },
    { lat: 48.644048, lng: 18.456803 },
    { lat: 48.644183, lng: 18.456636 },
    { lat: 48.644092, lng: 18.456448 },
    { lat: 48.643873, lng: 18.455977 },
    { lat: 48.643517, lng: 18.455206 },
    { lat: 48.643502, lng: 18.45519 },
    { lat: 48.6434, lng: 18.455077 },
    { lat: 48.643299, lng: 18.454966 },
    { lat: 48.64337, lng: 18.454682 },
    { lat: 48.643347, lng: 18.454519 },
    { lat: 48.643273, lng: 18.454215 },
    { lat: 48.643248, lng: 18.454147 },
    { lat: 48.643129, lng: 18.453822 },
    { lat: 48.643085, lng: 18.453729 },
    { lat: 48.642962, lng: 18.45349 },
    { lat: 48.642896, lng: 18.453372 },
    { lat: 48.64267, lng: 18.45307 },
    { lat: 48.642544, lng: 18.452952 },
    { lat: 48.642396, lng: 18.452763 },
    { lat: 48.642301, lng: 18.452596 },
    { lat: 48.642183, lng: 18.452384 },
    { lat: 48.642231, lng: 18.452307 },
    { lat: 48.642304, lng: 18.452188 },
    { lat: 48.642305, lng: 18.452138 },
    { lat: 48.642307, lng: 18.451915 },
    { lat: 48.642271, lng: 18.45175 },
    { lat: 48.642229, lng: 18.451588 },
    { lat: 48.642119, lng: 18.451299 },
    { lat: 48.642034, lng: 18.451205 },
    { lat: 48.641907, lng: 18.451146 },
    { lat: 48.641676, lng: 18.451202 },
    { lat: 48.641622, lng: 18.451218 },
    { lat: 48.641527, lng: 18.451248 },
    { lat: 48.641518, lng: 18.451251 },
    { lat: 48.641507, lng: 18.451265 },
    { lat: 48.641433, lng: 18.45135 },
    { lat: 48.641412, lng: 18.451324 },
    { lat: 48.641034, lng: 18.450889 },
    { lat: 48.641004, lng: 18.45089 },
    { lat: 48.640924, lng: 18.450894 },
    { lat: 48.640803, lng: 18.4509 },
    { lat: 48.640792, lng: 18.450896 },
    { lat: 48.64027, lng: 18.4507 },
    { lat: 48.640245, lng: 18.450642 },
    { lat: 48.6402, lng: 18.450548 },
    { lat: 48.640156, lng: 18.450483 },
    { lat: 48.640073, lng: 18.450326 },
    { lat: 48.63958, lng: 18.449385 },
    { lat: 48.639212, lng: 18.448868 },
    { lat: 48.638983, lng: 18.448557 },
    { lat: 48.638727, lng: 18.448074 },
    { lat: 48.638303, lng: 18.447302 },
    { lat: 48.637853, lng: 18.446508 },
    { lat: 48.637843, lng: 18.446488 },
    { lat: 48.63784, lng: 18.446324 },
    { lat: 48.637844, lng: 18.446132 },
    { lat: 48.637847, lng: 18.446072 },
    { lat: 48.637877, lng: 18.445848 },
    { lat: 48.637889, lng: 18.445761 },
    { lat: 48.637912, lng: 18.445598 },
    { lat: 48.637923, lng: 18.44559 },
    { lat: 48.638121, lng: 18.445424 },
    { lat: 48.638201, lng: 18.445339 },
    { lat: 48.638141, lng: 18.445235 },
    { lat: 48.638017, lng: 18.445018 },
    { lat: 48.637944, lng: 18.444819 },
    { lat: 48.637898, lng: 18.444506 },
    { lat: 48.637888, lng: 18.444304 },
    { lat: 48.637967, lng: 18.443902 },
    { lat: 48.638061, lng: 18.443611 },
    { lat: 48.638167, lng: 18.443402 },
    { lat: 48.638266, lng: 18.443215 },
    { lat: 48.63835, lng: 18.442879 },
    { lat: 48.638369, lng: 18.442764 },
    { lat: 48.638406, lng: 18.442521 },
    { lat: 48.638425, lng: 18.442406 },
    { lat: 48.638473, lng: 18.441964 },
    { lat: 48.638492, lng: 18.441682 },
    { lat: 48.638478, lng: 18.441389 },
    { lat: 48.638455, lng: 18.441137 },
    { lat: 48.638401, lng: 18.440758 },
    { lat: 48.638348, lng: 18.440358 },
    { lat: 48.638305, lng: 18.440141 },
    { lat: 48.638263, lng: 18.439987 },
    { lat: 48.638169, lng: 18.439723 },
    { lat: 48.638095, lng: 18.439552 },
    { lat: 48.638009, lng: 18.43934 },
    { lat: 48.637916, lng: 18.439249 },
    { lat: 48.637858, lng: 18.43912 },
    { lat: 48.637758, lng: 18.438864 },
  ],
  Podhradie: [
    { lat: 48.63992, lng: 18.656502 },
    { lat: 48.6418792, lng: 18.658915 },
    { lat: 48.6428539, lng: 18.658585 },
    { lat: 48.6429762, lng: 18.6585904 },
    { lat: 48.6440816, lng: 18.6586802 },
    { lat: 48.6451479, lng: 18.6579513 },
    { lat: 48.6456216, lng: 18.6576959 },
    { lat: 48.6465756, lng: 18.658158 },
    { lat: 48.64741, lng: 18.6583219 },
    { lat: 48.6484777, lng: 18.658886 },
    { lat: 48.6496171, lng: 18.6607826 },
    { lat: 48.6500894, lng: 18.6616852 },
    { lat: 48.6504476, lng: 18.6625607 },
    { lat: 48.6517315, lng: 18.6645489 },
    { lat: 48.6531953, lng: 18.6643362 },
    { lat: 48.6539147, lng: 18.6644597 },
    { lat: 48.6541605, lng: 18.6647497 },
    { lat: 48.6547821, lng: 18.6650688 },
    { lat: 48.6557767, lng: 18.6642669 },
    { lat: 48.6565122, lng: 18.664184 },
    { lat: 48.6570953, lng: 18.664038 },
    { lat: 48.6577289, lng: 18.664158 },
    { lat: 48.6583648, lng: 18.6646595 },
    { lat: 48.6586344, lng: 18.6649805 },
    { lat: 48.6588767, lng: 18.6651215 },
    { lat: 48.6598472, lng: 18.6659767 },
    { lat: 48.6600513, lng: 18.6663332 },
    { lat: 48.660293, lng: 18.6669712 },
    { lat: 48.6607089, lng: 18.6672405 },
    { lat: 48.6617558, lng: 18.6684273 },
    { lat: 48.6622533, lng: 18.6690409 },
    { lat: 48.6629837, lng: 18.6695378 },
    { lat: 48.6646199, lng: 18.6712861 },
    { lat: 48.6662357, lng: 18.6719745 },
    { lat: 48.6666147, lng: 18.6729024 },
    { lat: 48.6667658, lng: 18.6733674 },
    { lat: 48.6669512, lng: 18.674639 },
    { lat: 48.6670726, lng: 18.6745493 },
    { lat: 48.6675277, lng: 18.6748753 },
    { lat: 48.6680549, lng: 18.6753695 },
    { lat: 48.6684998, lng: 18.6754245 },
    { lat: 48.6690074, lng: 18.675635 },
    { lat: 48.6697192, lng: 18.6756165 },
    { lat: 48.6735659, lng: 18.6757612 },
    { lat: 48.6741346, lng: 18.6757669 },
    { lat: 48.6741956, lng: 18.675408 },
    { lat: 48.6743365, lng: 18.6749177 },
    { lat: 48.674417, lng: 18.6745264 },
    { lat: 48.6744823, lng: 18.6743786 },
    { lat: 48.6745087, lng: 18.6742397 },
    { lat: 48.6745566, lng: 18.674079 },
    { lat: 48.6746028, lng: 18.6739703 },
    { lat: 48.6746261, lng: 18.6738586 },
    { lat: 48.6747721, lng: 18.6735296 },
    { lat: 48.6749961, lng: 18.673082 },
    { lat: 48.6751937, lng: 18.6728085 },
    { lat: 48.6753478, lng: 18.6726748 },
    { lat: 48.6757491, lng: 18.6721673 },
    { lat: 48.6759237, lng: 18.6720547 },
    { lat: 48.6761582, lng: 18.671821 },
    { lat: 48.6763039, lng: 18.6715495 },
    { lat: 48.6765007, lng: 18.6712871 },
    { lat: 48.676612, lng: 18.6710705 },
    { lat: 48.6767667, lng: 18.6706484 },
    { lat: 48.6768073, lng: 18.6702266 },
    { lat: 48.6769414, lng: 18.6696543 },
    { lat: 48.6770478, lng: 18.6692391 },
    { lat: 48.6770722, lng: 18.6691298 },
    { lat: 48.6771305, lng: 18.6690363 },
    { lat: 48.6773491, lng: 18.6688848 },
    { lat: 48.6774991, lng: 18.6687998 },
    { lat: 48.6776018, lng: 18.6686745 },
    { lat: 48.6776954, lng: 18.6684675 },
    { lat: 48.678105, lng: 18.6680468 },
    { lat: 48.6782574, lng: 18.6677762 },
    { lat: 48.6783349, lng: 18.6676215 },
    { lat: 48.6786096, lng: 18.6672954 },
    { lat: 48.6786805, lng: 18.6672525 },
    { lat: 48.6787257, lng: 18.6671685 },
    { lat: 48.6787465, lng: 18.6664168 },
    { lat: 48.6786973, lng: 18.6662144 },
    { lat: 48.6786895, lng: 18.6660062 },
    { lat: 48.6787155, lng: 18.6658153 },
    { lat: 48.6786797, lng: 18.6655927 },
    { lat: 48.6786583, lng: 18.6652773 },
    { lat: 48.6785989, lng: 18.665028 },
    { lat: 48.6784059, lng: 18.6644776 },
    { lat: 48.6780599, lng: 18.6640181 },
    { lat: 48.6777638, lng: 18.6636698 },
    { lat: 48.6775529, lng: 18.6633765 },
    { lat: 48.6773543, lng: 18.6629511 },
    { lat: 48.6772647, lng: 18.662734 },
    { lat: 48.6771872, lng: 18.6625057 },
    { lat: 48.6771669, lng: 18.6624001 },
    { lat: 48.6771257, lng: 18.6622659 },
    { lat: 48.677053, lng: 18.6619179 },
    { lat: 48.6770182, lng: 18.6613301 },
    { lat: 48.6770427, lng: 18.6611348 },
    { lat: 48.6771606, lng: 18.6606832 },
    { lat: 48.6772711, lng: 18.6601551 },
    { lat: 48.677282, lng: 18.6600017 },
    { lat: 48.6773178, lng: 18.6598668 },
    { lat: 48.6773693, lng: 18.6597345 },
    { lat: 48.6774564, lng: 18.6595504 },
    { lat: 48.6774832, lng: 18.6593755 },
    { lat: 48.6775179, lng: 18.6592428 },
    { lat: 48.6775747, lng: 18.6591027 },
    { lat: 48.6776274, lng: 18.6590146 },
    { lat: 48.6777632, lng: 18.6587089 },
    { lat: 48.6778379, lng: 18.6585856 },
    { lat: 48.6779945, lng: 18.6584383 },
    { lat: 48.6780693, lng: 18.6583386 },
    { lat: 48.6781811, lng: 18.6581493 },
    { lat: 48.6786191, lng: 18.6576958 },
    { lat: 48.6789137, lng: 18.6575742 },
    { lat: 48.6790566, lng: 18.6574625 },
    { lat: 48.6794826, lng: 18.6572213 },
    { lat: 48.6796144, lng: 18.6571588 },
    { lat: 48.6797693, lng: 18.65693 },
    { lat: 48.6799246, lng: 18.656844 },
    { lat: 48.680037, lng: 18.6567314 },
    { lat: 48.6801491, lng: 18.6565257 },
    { lat: 48.6802147, lng: 18.6564663 },
    { lat: 48.6802735, lng: 18.6563624 },
    { lat: 48.6804, lng: 18.6562841 },
    { lat: 48.6805905, lng: 18.6561138 },
    { lat: 48.6807217, lng: 18.6559336 },
    { lat: 48.680846, lng: 18.6558092 },
    { lat: 48.6810073, lng: 18.6557263 },
    { lat: 48.6811822, lng: 18.6557057 },
    { lat: 48.6814201, lng: 18.6555128 },
    { lat: 48.6816424, lng: 18.6552205 },
    { lat: 48.681772, lng: 18.6551158 },
    { lat: 48.6820137, lng: 18.654846 },
    { lat: 48.6822856, lng: 18.654659 },
    { lat: 48.6825038, lng: 18.6545838 },
    { lat: 48.6827819, lng: 18.6543235 },
    { lat: 48.6829184, lng: 18.6541573 },
    { lat: 48.6830771, lng: 18.653983 },
    { lat: 48.6831875, lng: 18.6537892 },
    { lat: 48.6834388, lng: 18.6534062 },
    { lat: 48.6835007, lng: 18.6532791 },
    { lat: 48.6836079, lng: 18.6530879 },
    { lat: 48.6838239, lng: 18.6527653 },
    { lat: 48.6840683, lng: 18.652528 },
    { lat: 48.6844709, lng: 18.6522918 },
    { lat: 48.68458, lng: 18.6522228 },
    { lat: 48.6846769, lng: 18.6520949 },
    { lat: 48.6848451, lng: 18.6519712 },
    { lat: 48.6849971, lng: 18.6519672 },
    { lat: 48.6851683, lng: 18.6520034 },
    { lat: 48.685295, lng: 18.6520518 },
    { lat: 48.6855326, lng: 18.6520895 },
    { lat: 48.685792, lng: 18.6522149 },
    { lat: 48.6862434, lng: 18.6521797 },
    { lat: 48.6864313, lng: 18.6520653 },
    { lat: 48.6866681, lng: 18.6520649 },
    { lat: 48.6868999, lng: 18.6519492 },
    { lat: 48.6872373, lng: 18.6520357 },
    { lat: 48.6874429, lng: 18.6520009 },
    { lat: 48.6875948, lng: 18.6520173 },
    { lat: 48.6877286, lng: 18.6520706 },
    { lat: 48.6878711, lng: 18.6522245 },
    { lat: 48.6880257, lng: 18.6522459 },
    { lat: 48.6881671, lng: 18.6523411 },
    { lat: 48.688388, lng: 18.6522945 },
    { lat: 48.6885535, lng: 18.6520572 },
    { lat: 48.6886203, lng: 18.6518934 },
    { lat: 48.6886226, lng: 18.651685 },
    { lat: 48.6886654, lng: 18.6514524 },
    { lat: 48.6888193, lng: 18.6510206 },
    { lat: 48.6889118, lng: 18.6509115 },
    { lat: 48.6891043, lng: 18.6508208 },
    { lat: 48.6892176, lng: 18.6506642 },
    { lat: 48.6892196, lng: 18.650534 },
    { lat: 48.6891839, lng: 18.6503081 },
    { lat: 48.6891112, lng: 18.6500893 },
    { lat: 48.689091, lng: 18.6500917 },
    { lat: 48.6889766, lng: 18.649934 },
    { lat: 48.6891285, lng: 18.6496773 },
    { lat: 48.6891572, lng: 18.6496438 },
    { lat: 48.6895013, lng: 18.6487839 },
    { lat: 48.6895822, lng: 18.6487144 },
    { lat: 48.6896311, lng: 18.648701 },
    { lat: 48.6896939, lng: 18.648629 },
    { lat: 48.689708, lng: 18.6485345 },
    { lat: 48.6896971, lng: 18.6484584 },
    { lat: 48.6897366, lng: 18.6482925 },
    { lat: 48.6897292, lng: 18.6481935 },
    { lat: 48.6896801, lng: 18.648036 },
    { lat: 48.6896739, lng: 18.6478144 },
    { lat: 48.6896909, lng: 18.6476695 },
    { lat: 48.6902142, lng: 18.647331 },
    { lat: 48.6900998, lng: 18.6463569 },
    { lat: 48.6904192, lng: 18.6451815 },
    { lat: 48.6909964, lng: 18.6438311 },
    { lat: 48.6910224, lng: 18.6408714 },
    { lat: 48.6910348, lng: 18.639337 },
    { lat: 48.6910462, lng: 18.6380587 },
    { lat: 48.6920177, lng: 18.6369118 },
    { lat: 48.6937069, lng: 18.6336921 },
    { lat: 48.6933962, lng: 18.6332414 },
    { lat: 48.6923728, lng: 18.6313017 },
    { lat: 48.6923461, lng: 18.63125 },
    { lat: 48.6923516, lng: 18.6308905 },
    { lat: 48.6922765, lng: 18.6302675 },
    { lat: 48.6919457, lng: 18.630176 },
    { lat: 48.6914856, lng: 18.6297816 },
    { lat: 48.6907928, lng: 18.6293997 },
    { lat: 48.6905466, lng: 18.6292018 },
    { lat: 48.6904878, lng: 18.629199 },
    { lat: 48.6903587, lng: 18.6291499 },
    { lat: 48.6903088, lng: 18.6288722 },
    { lat: 48.6902506, lng: 18.6286294 },
    { lat: 48.6902374, lng: 18.6283398 },
    { lat: 48.6900316, lng: 18.6282269 },
    { lat: 48.6906743, lng: 18.6274472 },
    { lat: 48.6904994, lng: 18.6268579 },
    { lat: 48.690476, lng: 18.6267615 },
    { lat: 48.6905127, lng: 18.6263504 },
    { lat: 48.6904363, lng: 18.626147 },
    { lat: 48.6903151, lng: 18.625972 },
    { lat: 48.690299, lng: 18.6256891 },
    { lat: 48.6903097, lng: 18.6254279 },
    { lat: 48.6903882, lng: 18.6253583 },
    { lat: 48.6904199, lng: 18.6252748 },
    { lat: 48.6904356, lng: 18.6251722 },
    { lat: 48.6904311, lng: 18.6250657 },
    { lat: 48.6904154, lng: 18.6250254 },
    { lat: 48.6904334, lng: 18.6248151 },
    { lat: 48.6904255, lng: 18.6245623 },
    { lat: 48.6903892, lng: 18.6243493 },
    { lat: 48.6905926, lng: 18.6235829 },
    { lat: 48.6905677, lng: 18.6233275 },
    { lat: 48.6907409, lng: 18.6234047 },
    { lat: 48.6908985, lng: 18.623294 },
    { lat: 48.6912074, lng: 18.6231636 },
    { lat: 48.6911273, lng: 18.6226405 },
    { lat: 48.6912014, lng: 18.6224236 },
    { lat: 48.6912593, lng: 18.6223497 },
    { lat: 48.6914996, lng: 18.6218879 },
    { lat: 48.6917621, lng: 18.6214636 },
    { lat: 48.6919198, lng: 18.6211058 },
    { lat: 48.6917991, lng: 18.6206855 },
    { lat: 48.6917831, lng: 18.6205336 },
    { lat: 48.6918663, lng: 18.6203177 },
    { lat: 48.6919206, lng: 18.6200315 },
    { lat: 48.6913493, lng: 18.6192846 },
    { lat: 48.6896947, lng: 18.6171501 },
    { lat: 48.6896799, lng: 18.6171301 },
    { lat: 48.6879208, lng: 18.614939 },
    { lat: 48.6878193, lng: 18.6148151 },
    { lat: 48.6872344, lng: 18.6151794 },
    { lat: 48.6869995, lng: 18.6150662 },
    { lat: 48.68704, lng: 18.6147303 },
    { lat: 48.6870806, lng: 18.6145443 },
    { lat: 48.6871333, lng: 18.6143704 },
    { lat: 48.6872431, lng: 18.6141481 },
    { lat: 48.6858059, lng: 18.6122613 },
    { lat: 48.6849939, lng: 18.613139 },
    { lat: 48.6845004, lng: 18.6138626 },
    { lat: 48.6842752, lng: 18.6141837 },
    { lat: 48.6834853, lng: 18.6152 },
    { lat: 48.6821894, lng: 18.6168531 },
    { lat: 48.6820528, lng: 18.6170597 },
    { lat: 48.6819751, lng: 18.6171871 },
    { lat: 48.6817039, lng: 18.6176819 },
    { lat: 48.6814195, lng: 18.6179082 },
    { lat: 48.6813935, lng: 18.6180131 },
    { lat: 48.6812019, lng: 18.6184528 },
    { lat: 48.6810548, lng: 18.6188421 },
    { lat: 48.6809145, lng: 18.6191295 },
    { lat: 48.680851, lng: 18.6193936 },
    { lat: 48.6807362, lng: 18.6197978 },
    { lat: 48.6807927, lng: 18.6199644 },
    { lat: 48.6807155, lng: 18.6205849 },
    { lat: 48.6806787, lng: 18.6207175 },
    { lat: 48.6806351, lng: 18.6210402 },
    { lat: 48.6806269, lng: 18.6213271 },
    { lat: 48.6806238, lng: 18.6220252 },
    { lat: 48.6806448, lng: 18.6225595 },
    { lat: 48.6806627, lng: 18.62268 },
    { lat: 48.6806422, lng: 18.622826 },
    { lat: 48.6806057, lng: 18.6230054 },
    { lat: 48.6804183, lng: 18.6234007 },
    { lat: 48.6803324, lng: 18.6235658 },
    { lat: 48.6802391, lng: 18.6236543 },
    { lat: 48.6801041, lng: 18.6239347 },
    { lat: 48.6799652, lng: 18.6241602 },
    { lat: 48.6798884, lng: 18.6243052 },
    { lat: 48.6798224, lng: 18.6243772 },
    { lat: 48.6797296, lng: 18.624582 },
    { lat: 48.6796468, lng: 18.6246586 },
    { lat: 48.6793863, lng: 18.6250571 },
    { lat: 48.6793012, lng: 18.6252771 },
    { lat: 48.6791456, lng: 18.6254254 },
    { lat: 48.6790785, lng: 18.6255218 },
    { lat: 48.6790607, lng: 18.6257541 },
    { lat: 48.6788046, lng: 18.6259433 },
    { lat: 48.6787214, lng: 18.6260535 },
    { lat: 48.6787147, lng: 18.6261567 },
    { lat: 48.6787695, lng: 18.6262528 },
    { lat: 48.6788035, lng: 18.626486 },
    { lat: 48.6787391, lng: 18.6266952 },
    { lat: 48.678699, lng: 18.6268503 },
    { lat: 48.6786387, lng: 18.627135 },
    { lat: 48.6786865, lng: 18.6273719 },
    { lat: 48.6787425, lng: 18.6274517 },
    { lat: 48.6787679, lng: 18.6276071 },
    { lat: 48.6785684, lng: 18.6280291 },
    { lat: 48.6783971, lng: 18.6285791 },
    { lat: 48.6781692, lng: 18.6282837 },
    { lat: 48.6779912, lng: 18.6279922 },
    { lat: 48.6777367, lng: 18.6280013 },
    { lat: 48.6775074, lng: 18.6281194 },
    { lat: 48.6773017, lng: 18.6283213 },
    { lat: 48.6773569, lng: 18.6283848 },
    { lat: 48.6773255, lng: 18.6285346 },
    { lat: 48.6771919, lng: 18.628819 },
    { lat: 48.6770115, lng: 18.6290892 },
    { lat: 48.6770072, lng: 18.6291597 },
    { lat: 48.6769445, lng: 18.6293551 },
    { lat: 48.6768589, lng: 18.6295044 },
    { lat: 48.6767389, lng: 18.6298532 },
    { lat: 48.6766864, lng: 18.6299892 },
    { lat: 48.6764451, lng: 18.6308275 },
    { lat: 48.676151, lng: 18.6311915 },
    { lat: 48.6758847, lng: 18.6316053 },
    { lat: 48.6758531, lng: 18.6319179 },
    { lat: 48.6758249, lng: 18.6323385 },
    { lat: 48.6757599, lng: 18.6328486 },
    { lat: 48.6757231, lng: 18.6329213 },
    { lat: 48.6756342, lng: 18.6329897 },
    { lat: 48.6754979, lng: 18.6329689 },
    { lat: 48.6752969, lng: 18.6328401 },
    { lat: 48.6749937, lng: 18.6327402 },
    { lat: 48.6748978, lng: 18.6326742 },
    { lat: 48.6748342, lng: 18.6325649 },
    { lat: 48.6746475, lng: 18.6311682 },
    { lat: 48.6743242, lng: 18.630922 },
    { lat: 48.673957, lng: 18.6308703 },
    { lat: 48.6736815, lng: 18.6309462 },
    { lat: 48.6735534, lng: 18.6310401 },
    { lat: 48.6735255, lng: 18.6311088 },
    { lat: 48.6734017, lng: 18.6311154 },
    { lat: 48.6731158, lng: 18.6312911 },
    { lat: 48.6729985, lng: 18.6311677 },
    { lat: 48.672947, lng: 18.6312772 },
    { lat: 48.6728417, lng: 18.6312308 },
    { lat: 48.6723512, lng: 18.6312746 },
    { lat: 48.6720405, lng: 18.6310554 },
    { lat: 48.6717652, lng: 18.6310306 },
    { lat: 48.6713355, lng: 18.631135 },
    { lat: 48.6711205, lng: 18.6313352 },
    { lat: 48.670916, lng: 18.6315576 },
    { lat: 48.6706639, lng: 18.6314847 },
    { lat: 48.6700367, lng: 18.6309153 },
    { lat: 48.6693628, lng: 18.6302761 },
    { lat: 48.6690685, lng: 18.6300082 },
    { lat: 48.6684711, lng: 18.6295084 },
    { lat: 48.6676466, lng: 18.6281494 },
    { lat: 48.6676481, lng: 18.6281335 },
    { lat: 48.6675625, lng: 18.628032 },
    { lat: 48.6675275, lng: 18.6279042 },
    { lat: 48.6674167, lng: 18.627794 },
    { lat: 48.6674045, lng: 18.6276303 },
    { lat: 48.6673096, lng: 18.6273551 },
    { lat: 48.6672826, lng: 18.6273362 },
    { lat: 48.6670637, lng: 18.6272917 },
    { lat: 48.6669001, lng: 18.6272479 },
    { lat: 48.6666916, lng: 18.6272121 },
    { lat: 48.6665252, lng: 18.6273253 },
    { lat: 48.6663983, lng: 18.6275232 },
    { lat: 48.6663266, lng: 18.6276805 },
    { lat: 48.6661985, lng: 18.6277939 },
    { lat: 48.6660936, lng: 18.627835 },
    { lat: 48.6659786, lng: 18.6279811 },
    { lat: 48.6658665, lng: 18.6280976 },
    { lat: 48.6657015, lng: 18.6281372 },
    { lat: 48.6656631, lng: 18.6281572 },
    { lat: 48.6655298, lng: 18.6283423 },
    { lat: 48.6652642, lng: 18.6284131 },
    { lat: 48.6651941, lng: 18.6284945 },
    { lat: 48.6651398, lng: 18.628595 },
    { lat: 48.6651018, lng: 18.6286228 },
    { lat: 48.6649124, lng: 18.6286882 },
    { lat: 48.6648444, lng: 18.6287672 },
    { lat: 48.664813, lng: 18.6288342 },
    { lat: 48.6647629, lng: 18.6289081 },
    { lat: 48.664692, lng: 18.6289521 },
    { lat: 48.6645158, lng: 18.6289894 },
    { lat: 48.6643719, lng: 18.6291448 },
    { lat: 48.6642965, lng: 18.6292104 },
    { lat: 48.664152, lng: 18.6292702 },
    { lat: 48.6639288, lng: 18.6293169 },
    { lat: 48.6638433, lng: 18.6294676 },
    { lat: 48.6636285, lng: 18.6296035 },
    { lat: 48.6635274, lng: 18.6297989 },
    { lat: 48.6634451, lng: 18.6299906 },
    { lat: 48.663354, lng: 18.630016 },
    { lat: 48.663269, lng: 18.6301128 },
    { lat: 48.6630765, lng: 18.6301607 },
    { lat: 48.6629187, lng: 18.6301839 },
    { lat: 48.6628391, lng: 18.6302155 },
    { lat: 48.6626158, lng: 18.6304055 },
    { lat: 48.6625015, lng: 18.6304806 },
    { lat: 48.6623939, lng: 18.630598 },
    { lat: 48.6623335, lng: 18.6308383 },
    { lat: 48.6622127, lng: 18.6309827 },
    { lat: 48.6620206, lng: 18.6310794 },
    { lat: 48.6619129, lng: 18.6312167 },
    { lat: 48.661839, lng: 18.6313328 },
    { lat: 48.6617673, lng: 18.6315075 },
    { lat: 48.6615619, lng: 18.6316515 },
    { lat: 48.6613952, lng: 18.6317604 },
    { lat: 48.6613585, lng: 18.6317945 },
    { lat: 48.6612742, lng: 18.6320503 },
    { lat: 48.6612293, lng: 18.6321596 },
    { lat: 48.6611598, lng: 18.6322912 },
    { lat: 48.6611369, lng: 18.6324553 },
    { lat: 48.6611143, lng: 18.6325144 },
    { lat: 48.6610963, lng: 18.6326019 },
    { lat: 48.6610253, lng: 18.632748 },
    { lat: 48.6608555, lng: 18.6329261 },
    { lat: 48.6608149, lng: 18.6330292 },
    { lat: 48.6607182, lng: 18.6332034 },
    { lat: 48.6606852, lng: 18.6332802 },
    { lat: 48.6606599, lng: 18.6337771 },
    { lat: 48.660603, lng: 18.6338915 },
    { lat: 48.6605733, lng: 18.6340967 },
    { lat: 48.6605008, lng: 18.6342775 },
    { lat: 48.6604252, lng: 18.6344441 },
    { lat: 48.660392, lng: 18.6346273 },
    { lat: 48.6603267, lng: 18.6348369 },
    { lat: 48.6603209, lng: 18.6348954 },
    { lat: 48.6602911, lng: 18.6349532 },
    { lat: 48.6600142, lng: 18.6350871 },
    { lat: 48.6598444, lng: 18.6350768 },
    { lat: 48.6596289, lng: 18.6351992 },
    { lat: 48.6593716, lng: 18.635535 },
    { lat: 48.6593106, lng: 18.6356585 },
    { lat: 48.6592531, lng: 18.6358457 },
    { lat: 48.6590071, lng: 18.635864 },
    { lat: 48.6587902, lng: 18.6361332 },
    { lat: 48.6585112, lng: 18.636628 },
    { lat: 48.6582004, lng: 18.637138 },
    { lat: 48.6579503, lng: 18.6378814 },
    { lat: 48.6578716, lng: 18.6381368 },
    { lat: 48.6577999, lng: 18.6383951 },
    { lat: 48.6577677, lng: 18.638511 },
    { lat: 48.6576894, lng: 18.6387745 },
    { lat: 48.6575292, lng: 18.6391969 },
    { lat: 48.657514, lng: 18.6395823 },
    { lat: 48.6574343, lng: 18.6400137 },
    { lat: 48.6572568, lng: 18.6402541 },
    { lat: 48.6572231, lng: 18.6404459 },
    { lat: 48.6571968, lng: 18.6406917 },
    { lat: 48.6570738, lng: 18.6408598 },
    { lat: 48.6568678, lng: 18.6410951 },
    { lat: 48.6565716, lng: 18.6414414 },
    { lat: 48.6565115, lng: 18.6415412 },
    { lat: 48.6564481, lng: 18.6416806 },
    { lat: 48.6563216, lng: 18.6417863 },
    { lat: 48.6560671, lng: 18.6419587 },
    { lat: 48.6559701, lng: 18.642063 },
    { lat: 48.6558196, lng: 18.6422433 },
    { lat: 48.6555169, lng: 18.6427199 },
    { lat: 48.6554057, lng: 18.6427934 },
    { lat: 48.6551613, lng: 18.6437205 },
    { lat: 48.6551627, lng: 18.6443357 },
    { lat: 48.6550716, lng: 18.6448227 },
    { lat: 48.6549796, lng: 18.6457989 },
    { lat: 48.6549518, lng: 18.6459549 },
    { lat: 48.6543166, lng: 18.6468948 },
    { lat: 48.654267, lng: 18.6471071 },
    { lat: 48.6542183, lng: 18.64727 },
    { lat: 48.6541179, lng: 18.6474374 },
    { lat: 48.6535228, lng: 18.6476974 },
    { lat: 48.6533045, lng: 18.6478304 },
    { lat: 48.6530506, lng: 18.6478294 },
    { lat: 48.6529274, lng: 18.6477838 },
    { lat: 48.6527578, lng: 18.6477557 },
    { lat: 48.6525318, lng: 18.6478511 },
    { lat: 48.6521153, lng: 18.6478898 },
    { lat: 48.6516355, lng: 18.6478147 },
    { lat: 48.6512254, lng: 18.6477839 },
    { lat: 48.651062, lng: 18.6478481 },
    { lat: 48.6508642, lng: 18.6478395 },
    { lat: 48.6503876, lng: 18.6478902 },
    { lat: 48.6492396, lng: 18.6479988 },
    { lat: 48.6485382, lng: 18.6482557 },
    { lat: 48.6479391, lng: 18.6483129 },
    { lat: 48.6477951, lng: 18.6482981 },
    { lat: 48.6472825, lng: 18.6487309 },
    { lat: 48.6468777, lng: 18.6492384 },
    { lat: 48.6465765, lng: 18.6493811 },
    { lat: 48.6463675, lng: 18.6495661 },
    { lat: 48.6462123, lng: 18.6496592 },
    { lat: 48.6461538, lng: 18.6496828 },
    { lat: 48.6457833, lng: 18.6497997 },
    { lat: 48.64529, lng: 18.649896 },
    { lat: 48.6451095, lng: 18.6498525 },
    { lat: 48.6449898, lng: 18.6498735 },
    { lat: 48.6447711, lng: 18.6499541 },
    { lat: 48.6444414, lng: 18.6499284 },
    { lat: 48.6438551, lng: 18.6500574 },
    { lat: 48.6432585, lng: 18.6499773 },
    { lat: 48.6426243, lng: 18.6500327 },
    { lat: 48.6418695, lng: 18.6497961 },
    { lat: 48.6408007, lng: 18.6489849 },
    { lat: 48.6401312, lng: 18.6484454 },
    { lat: 48.6390638, lng: 18.6481654 },
    { lat: 48.6383915, lng: 18.6477858 },
    { lat: 48.6378355, lng: 18.6476373 },
    { lat: 48.6370904, lng: 18.6474798 },
    { lat: 48.6364341, lng: 18.6472526 },
    { lat: 48.6359046, lng: 18.647093 },
    { lat: 48.6354142, lng: 18.646929 },
    { lat: 48.6349903, lng: 18.6467248 },
    { lat: 48.6345505, lng: 18.6466587 },
    { lat: 48.633712, lng: 18.645988 },
    { lat: 48.633788, lng: 18.646734 },
    { lat: 48.63426, lng: 18.64814 },
    { lat: 48.634611, lng: 18.648376 },
    { lat: 48.634991, lng: 18.648568 },
    { lat: 48.63541, lng: 18.648959 },
    { lat: 48.635615, lng: 18.649084 },
    { lat: 48.636218, lng: 18.649561 },
    { lat: 48.636841, lng: 18.650248 },
    { lat: 48.636919, lng: 18.650361 },
    { lat: 48.638292, lng: 18.65282 },
    { lat: 48.638463, lng: 18.653487 },
    { lat: 48.638629, lng: 18.654814 },
    { lat: 48.639279, lng: 18.655825 },
    { lat: 48.63992, lng: 18.656502 },
  ],
  Poluvsie: [
    { lat: 48.842559, lng: 18.6241062 },
    { lat: 48.8425404, lng: 18.624139 },
    { lat: 48.8413677, lng: 18.6250511 },
    { lat: 48.8407009, lng: 18.625675 },
    { lat: 48.8413004, lng: 18.627453 },
    { lat: 48.8406773, lng: 18.6281549 },
    { lat: 48.8400558, lng: 18.628347 },
    { lat: 48.8391801, lng: 18.6289894 },
    { lat: 48.8392301, lng: 18.6293302 },
    { lat: 48.8392351, lng: 18.6295734 },
    { lat: 48.839209, lng: 18.6300123 },
    { lat: 48.8396927, lng: 18.630072 },
    { lat: 48.8397541, lng: 18.6305002 },
    { lat: 48.8398891, lng: 18.6306633 },
    { lat: 48.8398619, lng: 18.6309538 },
    { lat: 48.8399914, lng: 18.6311384 },
    { lat: 48.8399188, lng: 18.6320607 },
    { lat: 48.8408835, lng: 18.6318776 },
    { lat: 48.8408362, lng: 18.6327195 },
    { lat: 48.8407579, lng: 18.6334294 },
    { lat: 48.8406058, lng: 18.6346093 },
    { lat: 48.8403694, lng: 18.6353967 },
    { lat: 48.8402929, lng: 18.635945 },
    { lat: 48.8401695, lng: 18.6368628 },
    { lat: 48.8400561, lng: 18.6375258 },
    { lat: 48.8398458, lng: 18.63874 },
    { lat: 48.8397851, lng: 18.6391034 },
    { lat: 48.839636, lng: 18.6400261 },
    { lat: 48.8394495, lng: 18.6411962 },
    { lat: 48.8396763, lng: 18.6412421 },
    { lat: 48.8397933, lng: 18.6414461 },
    { lat: 48.8398529, lng: 18.6415885 },
    { lat: 48.8399459, lng: 18.6416211 },
    { lat: 48.8399875, lng: 18.6415991 },
    { lat: 48.8400112, lng: 18.6415343 },
    { lat: 48.839975, lng: 18.6414052 },
    { lat: 48.8399914, lng: 18.6412918 },
    { lat: 48.840016, lng: 18.6412655 },
    { lat: 48.8401808, lng: 18.6413287 },
    { lat: 48.8404847, lng: 18.6413207 },
    { lat: 48.8405146, lng: 18.641331 },
    { lat: 48.8405372, lng: 18.6413515 },
    { lat: 48.8405664, lng: 18.6414783 },
    { lat: 48.8406074, lng: 18.6415277 },
    { lat: 48.840658, lng: 18.6415633 },
    { lat: 48.8406797, lng: 18.6415714 },
    { lat: 48.8406916, lng: 18.6416663 },
    { lat: 48.8407113, lng: 18.6416993 },
    { lat: 48.84074, lng: 18.6417047 },
    { lat: 48.8407954, lng: 18.6416897 },
    { lat: 48.8408383, lng: 18.6416482 },
    { lat: 48.8408661, lng: 18.6415972 },
    { lat: 48.8409055, lng: 18.6414681 },
    { lat: 48.8409305, lng: 18.641348 },
    { lat: 48.8409328, lng: 18.6411568 },
    { lat: 48.8409422, lng: 18.6410812 },
    { lat: 48.840952, lng: 18.6410521 },
    { lat: 48.8409665, lng: 18.6410489 },
    { lat: 48.8410809, lng: 18.6411419 },
    { lat: 48.8412277, lng: 18.6413147 },
    { lat: 48.8413474, lng: 18.6414453 },
    { lat: 48.8414936, lng: 18.6415846 },
    { lat: 48.8415425, lng: 18.6416147 },
    { lat: 48.8415907, lng: 18.6415673 },
    { lat: 48.8416423, lng: 18.6414531 },
    { lat: 48.8417302, lng: 18.6411559 },
    { lat: 48.841755, lng: 18.6411163 },
    { lat: 48.8418972, lng: 18.6410534 },
    { lat: 48.8419761, lng: 18.6410305 },
    { lat: 48.8420098, lng: 18.6409894 },
    { lat: 48.8420128, lng: 18.6409787 },
    { lat: 48.8420073, lng: 18.6409425 },
    { lat: 48.8419946, lng: 18.6409122 },
    { lat: 48.8418989, lng: 18.6408548 },
    { lat: 48.8418904, lng: 18.6408419 },
    { lat: 48.841885, lng: 18.6408261 },
    { lat: 48.8418811, lng: 18.6407525 },
    { lat: 48.8419387, lng: 18.6406065 },
    { lat: 48.8419558, lng: 18.640589 },
    { lat: 48.8419787, lng: 18.6405765 },
    { lat: 48.8420063, lng: 18.6405835 },
    { lat: 48.8420295, lng: 18.6405975 },
    { lat: 48.8422203, lng: 18.6408329 },
    { lat: 48.842386, lng: 18.6412085 },
    { lat: 48.8424113, lng: 18.6413247 },
    { lat: 48.8424134, lng: 18.6413638 },
    { lat: 48.8424179, lng: 18.6413862 },
    { lat: 48.8425542, lng: 18.6416133 },
    { lat: 48.8427867, lng: 18.6416173 },
    { lat: 48.8429653, lng: 18.6417486 },
    { lat: 48.8429748, lng: 18.641824 },
    { lat: 48.8429182, lng: 18.6420082 },
    { lat: 48.8429307, lng: 18.6421177 },
    { lat: 48.8430191, lng: 18.6421885 },
    { lat: 48.8430848, lng: 18.6421718 },
    { lat: 48.8433434, lng: 18.6421769 },
    { lat: 48.8435259, lng: 18.6422791 },
    { lat: 48.8435624, lng: 18.6423513 },
    { lat: 48.8436151, lng: 18.6425142 },
    { lat: 48.8437289, lng: 18.6426585 },
    { lat: 48.8438294, lng: 18.642809 },
    { lat: 48.8438582, lng: 18.6428177 },
    { lat: 48.843908, lng: 18.6428033 },
    { lat: 48.8440839, lng: 18.6427165 },
    { lat: 48.8441517, lng: 18.6426756 },
    { lat: 48.8441919, lng: 18.6426284 },
    { lat: 48.8442939, lng: 18.64255 },
    { lat: 48.8443355, lng: 18.6424848 },
    { lat: 48.8444257, lng: 18.6424432 },
    { lat: 48.8444567, lng: 18.6424527 },
    { lat: 48.8444828, lng: 18.6424993 },
    { lat: 48.8445457, lng: 18.6427912 },
    { lat: 48.8446699, lng: 18.6427724 },
    { lat: 48.8447412, lng: 18.6426072 },
    { lat: 48.8447555, lng: 18.6425159 },
    { lat: 48.8447959, lng: 18.6423666 },
    { lat: 48.8449147, lng: 18.6422887 },
    { lat: 48.8451511, lng: 18.642343 },
    { lat: 48.8453103, lng: 18.6424918 },
    { lat: 48.8454305, lng: 18.6426324 },
    { lat: 48.8454718, lng: 18.6427939 },
    { lat: 48.8455311, lng: 18.6429531 },
    { lat: 48.8457298, lng: 18.6429992 },
    { lat: 48.845743, lng: 18.643013 },
    { lat: 48.8457774, lng: 18.6432351 },
    { lat: 48.8459245, lng: 18.6433703 },
    { lat: 48.8460182, lng: 18.643434 },
    { lat: 48.8460303, lng: 18.6434307 },
    { lat: 48.846049, lng: 18.6434008 },
    { lat: 48.8460971, lng: 18.6432427 },
    { lat: 48.8461027, lng: 18.6432023 },
    { lat: 48.8460998, lng: 18.6428743 },
    { lat: 48.8461131, lng: 18.6427599 },
    { lat: 48.8462063, lng: 18.642395 },
    { lat: 48.846223, lng: 18.6423729 },
    { lat: 48.8465552, lng: 18.6420868 },
    { lat: 48.8465706, lng: 18.6420802 },
    { lat: 48.8466234, lng: 18.6420777 },
    { lat: 48.8466551, lng: 18.6421218 },
    { lat: 48.8466705, lng: 18.6421578 },
    { lat: 48.8467292, lng: 18.642517 },
    { lat: 48.8466646, lng: 18.6427183 },
    { lat: 48.8466608, lng: 18.6427767 },
    { lat: 48.8466953, lng: 18.6429351 },
    { lat: 48.8467545, lng: 18.6430739 },
    { lat: 48.8467716, lng: 18.6431373 },
    { lat: 48.8467382, lng: 18.6433563 },
    { lat: 48.8467503, lng: 18.6433931 },
    { lat: 48.8467708, lng: 18.6434189 },
    { lat: 48.8467881, lng: 18.6434275 },
    { lat: 48.8468572, lng: 18.6434337 },
    { lat: 48.8469513, lng: 18.6432542 },
    { lat: 48.8469643, lng: 18.6432408 },
    { lat: 48.8469952, lng: 18.6432298 },
    { lat: 48.8470111, lng: 18.6432322 },
    { lat: 48.8471817, lng: 18.6433201 },
    { lat: 48.8473006, lng: 18.643395 },
    { lat: 48.8473892, lng: 18.6434264 },
    { lat: 48.847409, lng: 18.6434157 },
    { lat: 48.8474841, lng: 18.6433044 },
    { lat: 48.8475811, lng: 18.6432177 },
    { lat: 48.8476949, lng: 18.6432778 },
    { lat: 48.8478403, lng: 18.6433849 },
    { lat: 48.8480154, lng: 18.6433437 },
    { lat: 48.8481918, lng: 18.6433321 },
    { lat: 48.8482514, lng: 18.6432456 },
    { lat: 48.8483059, lng: 18.6432128 },
    { lat: 48.8483919, lng: 18.6432395 },
    { lat: 48.8484632, lng: 18.6433482 },
    { lat: 48.8485046, lng: 18.6436579 },
    { lat: 48.8485202, lng: 18.6437376 },
    { lat: 48.8485457, lng: 18.6437946 },
    { lat: 48.8486715, lng: 18.6439335 },
    { lat: 48.8487066, lng: 18.6439564 },
    { lat: 48.8487399, lng: 18.6439649 },
    { lat: 48.849012, lng: 18.6439934 },
    { lat: 48.8491673, lng: 18.6439238 },
    { lat: 48.8491985, lng: 18.6438971 },
    { lat: 48.8492874, lng: 18.6436591 },
    { lat: 48.8493964, lng: 18.6435899 },
    { lat: 48.8495945, lng: 18.6435186 },
    { lat: 48.8496775, lng: 18.643362 },
    { lat: 48.8496988, lng: 18.6433404 },
    { lat: 48.849852, lng: 18.6432514 },
    { lat: 48.8499878, lng: 18.6431967 },
    { lat: 48.8500017, lng: 18.6432007 },
    { lat: 48.8500304, lng: 18.643233 },
    { lat: 48.8500544, lng: 18.6432813 },
    { lat: 48.8500597, lng: 18.6433179 },
    { lat: 48.8500375, lng: 18.6436794 },
    { lat: 48.8500409, lng: 18.6437045 },
    { lat: 48.8500655, lng: 18.6437623 },
    { lat: 48.8501007, lng: 18.6437669 },
    { lat: 48.8501312, lng: 18.6437492 },
    { lat: 48.8504346, lng: 18.6433234 },
    { lat: 48.8505292, lng: 18.6433637 },
    { lat: 48.850606, lng: 18.643369 },
    { lat: 48.8507629, lng: 18.6434353 },
    { lat: 48.8508199, lng: 18.6434874 },
    { lat: 48.8509073, lng: 18.6434963 },
    { lat: 48.8509283, lng: 18.6434873 },
    { lat: 48.8509395, lng: 18.6434662 },
    { lat: 48.8509294, lng: 18.643277 },
    { lat: 48.8509922, lng: 18.6431872 },
    { lat: 48.8510416, lng: 18.6431615 },
    { lat: 48.8511863, lng: 18.6431666 },
    { lat: 48.8513054, lng: 18.6432243 },
    { lat: 48.851322, lng: 18.6432384 },
    { lat: 48.8515251, lng: 18.6435547 },
    { lat: 48.8515618, lng: 18.6435864 },
    { lat: 48.8516127, lng: 18.6436095 },
    { lat: 48.8517588, lng: 18.6434344 },
    { lat: 48.8517824, lng: 18.6434343 },
    { lat: 48.8519178, lng: 18.643494 },
    { lat: 48.8521747, lng: 18.6435566 },
    { lat: 48.8522219, lng: 18.6435959 },
    { lat: 48.8523533, lng: 18.6438778 },
    { lat: 48.8523833, lng: 18.6438906 },
    { lat: 48.8524166, lng: 18.6438811 },
    { lat: 48.8524338, lng: 18.6438672 },
    { lat: 48.8525349, lng: 18.6436685 },
    { lat: 48.8526232, lng: 18.6436978 },
    { lat: 48.8526398, lng: 18.6437115 },
    { lat: 48.8526732, lng: 18.6437887 },
    { lat: 48.8526958, lng: 18.6438708 },
    { lat: 48.8527376, lng: 18.6438119 },
    { lat: 48.8528385, lng: 18.6437358 },
    { lat: 48.8529478, lng: 18.6437342 },
    { lat: 48.8530872, lng: 18.6437027 },
    { lat: 48.8531371, lng: 18.6436468 },
    { lat: 48.8531724, lng: 18.6436522 },
    { lat: 48.8532086, lng: 18.6436355 },
    { lat: 48.8532298, lng: 18.6436072 },
    { lat: 48.8537967, lng: 18.6431378 },
    { lat: 48.8538189, lng: 18.6431115 },
    { lat: 48.8538427, lng: 18.6430688 },
    { lat: 48.8538621, lng: 18.6430088 },
    { lat: 48.8539708, lng: 18.6424915 },
    { lat: 48.8542012, lng: 18.6423489 },
    { lat: 48.8541647, lng: 18.6416042 },
    { lat: 48.8541461, lng: 18.6412967 },
    { lat: 48.8540924, lng: 18.6409065 },
    { lat: 48.8539498, lng: 18.6400541 },
    { lat: 48.8539183, lng: 18.6398223 },
    { lat: 48.8538387, lng: 18.6394309 },
    { lat: 48.8537293, lng: 18.6388209 },
    { lat: 48.8538238, lng: 18.6382237 },
    { lat: 48.8539018, lng: 18.6378273 },
    { lat: 48.8539283, lng: 18.6375673 },
    { lat: 48.8540701, lng: 18.6363568 },
    { lat: 48.8540658, lng: 18.6363001 },
    { lat: 48.8540344, lng: 18.636238 },
    { lat: 48.8540142, lng: 18.6360109 },
    { lat: 48.8540411, lng: 18.6357329 },
    { lat: 48.8540869, lng: 18.6357277 },
    { lat: 48.8541493, lng: 18.6357544 },
    { lat: 48.8543361, lng: 18.6357031 },
    { lat: 48.8543849, lng: 18.6357082 },
    { lat: 48.854669, lng: 18.6355991 },
    { lat: 48.85481, lng: 18.635518 },
    { lat: 48.8548806, lng: 18.6354241 },
    { lat: 48.854998, lng: 18.6352145 },
    { lat: 48.8553371, lng: 18.6352505 },
    { lat: 48.8554146, lng: 18.6352453 },
    { lat: 48.8554856, lng: 18.6352243 },
    { lat: 48.8556199, lng: 18.6351416 },
    { lat: 48.8556804, lng: 18.6350722 },
    { lat: 48.8561084, lng: 18.6345324 },
    { lat: 48.8563511, lng: 18.6341974 },
    { lat: 48.856213, lng: 18.6334537 },
    { lat: 48.8561969, lng: 18.6333826 },
    { lat: 48.8561939, lng: 18.6333274 },
    { lat: 48.8564712, lng: 18.6332407 },
    { lat: 48.856506, lng: 18.6332097 },
    { lat: 48.8566327, lng: 18.6330969 },
    { lat: 48.8566703, lng: 18.6331062 },
    { lat: 48.8567505, lng: 18.6330634 },
    { lat: 48.8564689, lng: 18.6317241 },
    { lat: 48.8565111, lng: 18.6316907 },
    { lat: 48.8564454, lng: 18.6314315 },
    { lat: 48.8562935, lng: 18.6307038 },
    { lat: 48.8562046, lng: 18.6302445 },
    { lat: 48.8560845, lng: 18.6297722 },
    { lat: 48.8559339, lng: 18.6292338 },
    { lat: 48.8558298, lng: 18.628892 },
    { lat: 48.8554493, lng: 18.6277455 },
    { lat: 48.8552756, lng: 18.6272619 },
    { lat: 48.8550661, lng: 18.6274571 },
    { lat: 48.8547128, lng: 18.626529 },
    { lat: 48.8542371, lng: 18.6252298 },
    { lat: 48.8540853, lng: 18.6248363 },
    { lat: 48.8538826, lng: 18.6244021 },
    { lat: 48.8536476, lng: 18.6239515 },
    { lat: 48.8531053, lng: 18.6229668 },
    { lat: 48.8530765, lng: 18.6229356 },
    { lat: 48.8529743, lng: 18.6231943 },
    { lat: 48.8528194, lng: 18.6234207 },
    { lat: 48.8523123, lng: 18.6239334 },
    { lat: 48.8520151, lng: 18.624302 },
    { lat: 48.851768, lng: 18.624572 },
    { lat: 48.851609, lng: 18.6248459 },
    { lat: 48.851336, lng: 18.6252022 },
    { lat: 48.8511301, lng: 18.6255277 },
    { lat: 48.8506391, lng: 18.6259613 },
    { lat: 48.8504225, lng: 18.6261492 },
    { lat: 48.8502151, lng: 18.626174 },
    { lat: 48.8498647, lng: 18.6261355 },
    { lat: 48.8494919, lng: 18.6260418 },
    { lat: 48.8484672, lng: 18.6252716 },
    { lat: 48.8476329, lng: 18.6248394 },
    { lat: 48.8461606, lng: 18.6245774 },
    { lat: 48.8455429, lng: 18.6247509 },
    { lat: 48.8446292, lng: 18.6250848 },
    { lat: 48.8439051, lng: 18.6252327 },
    { lat: 48.8438129, lng: 18.625219 },
    { lat: 48.8436932, lng: 18.625088 },
    { lat: 48.8434907, lng: 18.624993 },
    { lat: 48.8429654, lng: 18.6245969 },
    { lat: 48.8428122, lng: 18.6244557 },
    { lat: 48.842559, lng: 18.6241062 },
  ],
  Poruba: [
    { lat: 48.8196066, lng: 18.6003436 },
    { lat: 48.8213927, lng: 18.599892 },
    { lat: 48.8220757, lng: 18.5997384 },
    { lat: 48.8225507, lng: 18.5996364 },
    { lat: 48.8229514, lng: 18.599603 },
    { lat: 48.824485, lng: 18.5992158 },
    { lat: 48.8246744, lng: 18.5992575 },
    { lat: 48.8246713, lng: 18.5992833 },
    { lat: 48.8244669, lng: 18.6004941 },
    { lat: 48.8249208, lng: 18.600671 },
    { lat: 48.8252512, lng: 18.6007937 },
    { lat: 48.8253793, lng: 18.6008459 },
    { lat: 48.8263027, lng: 18.6011978 },
    { lat: 48.8264158, lng: 18.6012426 },
    { lat: 48.8260684, lng: 18.602543 },
    { lat: 48.8254303, lng: 18.6049357 },
    { lat: 48.8249022, lng: 18.6057487 },
    { lat: 48.8255109, lng: 18.605992 },
    { lat: 48.8256595, lng: 18.6057149 },
    { lat: 48.8259813, lng: 18.6051554 },
    { lat: 48.8261229, lng: 18.6052084 },
    { lat: 48.8261238, lng: 18.6051643 },
    { lat: 48.8261989, lng: 18.6051145 },
    { lat: 48.826298, lng: 18.605112 },
    { lat: 48.8264696, lng: 18.604924 },
    { lat: 48.8266627, lng: 18.6046327 },
    { lat: 48.8266876, lng: 18.6043855 },
    { lat: 48.8267899, lng: 18.6041685 },
    { lat: 48.8268253, lng: 18.604071 },
    { lat: 48.8268433, lng: 18.6039678 },
    { lat: 48.8269045, lng: 18.6036986 },
    { lat: 48.8270013, lng: 18.6034475 },
    { lat: 48.826995, lng: 18.6034125 },
    { lat: 48.8269546, lng: 18.6033348 },
    { lat: 48.827008, lng: 18.6032165 },
    { lat: 48.8270823, lng: 18.6031913 },
    { lat: 48.827056, lng: 18.6031025 },
    { lat: 48.8270835, lng: 18.6029642 },
    { lat: 48.8270706, lng: 18.6028473 },
    { lat: 48.8271629, lng: 18.6026558 },
    { lat: 48.8271905, lng: 18.6024795 },
    { lat: 48.8273381, lng: 18.6023274 },
    { lat: 48.827356, lng: 18.6022822 },
    { lat: 48.8273277, lng: 18.6021959 },
    { lat: 48.827346, lng: 18.6020368 },
    { lat: 48.8274172, lng: 18.6020978 },
    { lat: 48.8274911, lng: 18.6020053 },
    { lat: 48.8274919, lng: 18.6019586 },
    { lat: 48.8274454, lng: 18.6018727 },
    { lat: 48.8275254, lng: 18.6016198 },
    { lat: 48.8275265, lng: 18.6014904 },
    { lat: 48.8275733, lng: 18.6014378 },
    { lat: 48.8276073, lng: 18.6012972 },
    { lat: 48.8275699, lng: 18.6011869 },
    { lat: 48.8275624, lng: 18.6010697 },
    { lat: 48.8275844, lng: 18.6009345 },
    { lat: 48.8275454, lng: 18.6008989 },
    { lat: 48.8275555, lng: 18.6007967 },
    { lat: 48.8275874, lng: 18.6007169 },
    { lat: 48.8276461, lng: 18.6006144 },
    { lat: 48.827627, lng: 18.6005527 },
    { lat: 48.8275297, lng: 18.6004186 },
    { lat: 48.827539, lng: 18.6003849 },
    { lat: 48.8276491, lng: 18.6004579 },
    { lat: 48.8280659, lng: 18.6009086 },
    { lat: 48.8282008, lng: 18.6010265 },
    { lat: 48.828363, lng: 18.6011064 },
    { lat: 48.8286264, lng: 18.6011615 },
    { lat: 48.8288406, lng: 18.601241 },
    { lat: 48.828851, lng: 18.6012054 },
    { lat: 48.8289239, lng: 18.6012386 },
    { lat: 48.8293433, lng: 18.6013808 },
    { lat: 48.8295276, lng: 18.6014543 },
    { lat: 48.8298766, lng: 18.6016109 },
    { lat: 48.8305497, lng: 18.6019168 },
    { lat: 48.8309905, lng: 18.6021018 },
    { lat: 48.8313036, lng: 18.6022421 },
    { lat: 48.8316259, lng: 18.6023186 },
    { lat: 48.8320313, lng: 18.6024783 },
    { lat: 48.8326919, lng: 18.6027855 },
    { lat: 48.8335455, lng: 18.6033237 },
    { lat: 48.8335814, lng: 18.60334 },
    { lat: 48.8349293, lng: 18.6039897 },
    { lat: 48.8355005, lng: 18.6044637 },
    { lat: 48.8360787, lng: 18.6047903 },
    { lat: 48.8367408, lng: 18.6051865 },
    { lat: 48.8368908, lng: 18.6052725 },
    { lat: 48.8373798, lng: 18.6054928 },
    { lat: 48.8374795, lng: 18.6055664 },
    { lat: 48.8378837, lng: 18.6057603 },
    { lat: 48.8383175, lng: 18.6059645 },
    { lat: 48.8384938, lng: 18.6060745 },
    { lat: 48.8385215, lng: 18.6060846 },
    { lat: 48.8387483, lng: 18.605859 },
    { lat: 48.8389566, lng: 18.6054727 },
    { lat: 48.8392096, lng: 18.6050633 },
    { lat: 48.8394187, lng: 18.6047976 },
    { lat: 48.8397117, lng: 18.6042691 },
    { lat: 48.8400902, lng: 18.6036755 },
    { lat: 48.8404911, lng: 18.603119 },
    { lat: 48.840669, lng: 18.6028399 },
    { lat: 48.8411611, lng: 18.6022792 },
    { lat: 48.8411764, lng: 18.6022476 },
    { lat: 48.8413029, lng: 18.6020862 },
    { lat: 48.841338, lng: 18.6019208 },
    { lat: 48.8422623, lng: 18.6009034 },
    { lat: 48.8428501, lng: 18.6002793 },
    { lat: 48.8438277, lng: 18.5995217 },
    { lat: 48.8451115, lng: 18.5986661 },
    { lat: 48.8467056, lng: 18.5973974 },
    { lat: 48.848007, lng: 18.595386 },
    { lat: 48.8491889, lng: 18.5933112 },
    { lat: 48.8503725, lng: 18.5912071 },
    { lat: 48.8506212, lng: 18.5913657 },
    { lat: 48.851197, lng: 18.590144 },
    { lat: 48.8515573, lng: 18.5891073 },
    { lat: 48.8516306, lng: 18.588084 },
    { lat: 48.8517119, lng: 18.5860769 },
    { lat: 48.8517154, lng: 18.5860159 },
    { lat: 48.8518619, lng: 18.5842382 },
    { lat: 48.8518978, lng: 18.5838387 },
    { lat: 48.8518916, lng: 18.5838294 },
    { lat: 48.8518938, lng: 18.5837428 },
    { lat: 48.8519459, lng: 18.583683 },
    { lat: 48.8519449, lng: 18.5836029 },
    { lat: 48.8519056, lng: 18.5834398 },
    { lat: 48.8519429, lng: 18.5832121 },
    { lat: 48.8520374, lng: 18.5830406 },
    { lat: 48.8520997, lng: 18.582856 },
    { lat: 48.8521777, lng: 18.5829036 },
    { lat: 48.8522586, lng: 18.5826457 },
    { lat: 48.8522115, lng: 18.5824843 },
    { lat: 48.8522215, lng: 18.5824415 },
    { lat: 48.8522386, lng: 18.5824201 },
    { lat: 48.8522282, lng: 18.5823776 },
    { lat: 48.8524506, lng: 18.5817122 },
    { lat: 48.852553, lng: 18.5814549 },
    { lat: 48.8526188, lng: 18.5813706 },
    { lat: 48.8527407, lng: 18.5812145 },
    { lat: 48.852956, lng: 18.5808164 },
    { lat: 48.8530499, lng: 18.5804684 },
    { lat: 48.8532347, lng: 18.5801475 },
    { lat: 48.8534231, lng: 18.5796893 },
    { lat: 48.8535945, lng: 18.5791265 },
    { lat: 48.8537969, lng: 18.5786164 },
    { lat: 48.8540148, lng: 18.5781469 },
    { lat: 48.8540535, lng: 18.5780635 },
    { lat: 48.854241, lng: 18.5773561 },
    { lat: 48.8542519, lng: 18.5768884 },
    { lat: 48.8542938, lng: 18.5765186 },
    { lat: 48.8543117, lng: 18.5760765 },
    { lat: 48.8542793, lng: 18.5758309 },
    { lat: 48.8542816, lng: 18.5756232 },
    { lat: 48.8542542, lng: 18.5754318 },
    { lat: 48.8542282, lng: 18.5750557 },
    { lat: 48.8542638, lng: 18.5745649 },
    { lat: 48.8542593, lng: 18.5738574 },
    { lat: 48.8543874, lng: 18.5732448 },
    { lat: 48.8544411, lng: 18.5727965 },
    { lat: 48.8544958, lng: 18.5724609 },
    { lat: 48.854546, lng: 18.5721527 },
    { lat: 48.8549223, lng: 18.5707857 },
    { lat: 48.8550274, lng: 18.5703965 },
    { lat: 48.855364, lng: 18.5693618 },
    { lat: 48.8555497, lng: 18.5685447 },
    { lat: 48.8556901, lng: 18.5676582 },
    { lat: 48.8557841, lng: 18.5666222 },
    { lat: 48.8558136, lng: 18.5661883 },
    { lat: 48.8558203, lng: 18.5657498 },
    { lat: 48.8558341, lng: 18.5653594 },
    { lat: 48.8558912, lng: 18.5648731 },
    { lat: 48.8560851, lng: 18.5641011 },
    { lat: 48.8563464, lng: 18.563196 },
    { lat: 48.8564173, lng: 18.5627573 },
    { lat: 48.8564512, lng: 18.5625475 },
    { lat: 48.8565861, lng: 18.5620637 },
    { lat: 48.8567015, lng: 18.5615059 },
    { lat: 48.8566403, lng: 18.5608563 },
    { lat: 48.8567491, lng: 18.56041 },
    { lat: 48.8568543, lng: 18.5599782 },
    { lat: 48.8571104, lng: 18.5592299 },
    { lat: 48.8571854, lng: 18.5589269 },
    { lat: 48.8573127, lng: 18.5588018 },
    { lat: 48.8574605, lng: 18.5583019 },
    { lat: 48.8575674, lng: 18.5577549 },
    { lat: 48.8578635, lng: 18.5570994 },
    { lat: 48.8581572, lng: 18.5566496 },
    { lat: 48.8582487, lng: 18.5565095 },
    { lat: 48.8583941, lng: 18.5561535 },
    { lat: 48.8586657, lng: 18.5557755 },
    { lat: 48.8588557, lng: 18.555452 },
    { lat: 48.8589774, lng: 18.5543045 },
    { lat: 48.8591107, lng: 18.5538523 },
    { lat: 48.8591533, lng: 18.5535787 },
    { lat: 48.8592733, lng: 18.5529641 },
    { lat: 48.8592952, lng: 18.5528886 },
    { lat: 48.8593658, lng: 18.5525463 },
    { lat: 48.8594368, lng: 18.5522355 },
    { lat: 48.8595254, lng: 18.5518691 },
    { lat: 48.8595998, lng: 18.5515801 },
    { lat: 48.8596818, lng: 18.5512188 },
    { lat: 48.8598433, lng: 18.5507237 },
    { lat: 48.8600194, lng: 18.5502919 },
    { lat: 48.8602093, lng: 18.5499465 },
    { lat: 48.8604707, lng: 18.549546 },
    { lat: 48.8608583, lng: 18.5493096 },
    { lat: 48.8610972, lng: 18.549096 },
    { lat: 48.8612659, lng: 18.5489206 },
    { lat: 48.8617923, lng: 18.5481059 },
    { lat: 48.8618242, lng: 18.5480722 },
    { lat: 48.8625795, lng: 18.546838 },
    { lat: 48.864394, lng: 18.5452932 },
    { lat: 48.8646103, lng: 18.5434398 },
    { lat: 48.8647043, lng: 18.5431992 },
    { lat: 48.864765, lng: 18.5431124 },
    { lat: 48.8649009, lng: 18.5428934 },
    { lat: 48.865167, lng: 18.5423923 },
    { lat: 48.8658711, lng: 18.5410708 },
    { lat: 48.8660389, lng: 18.5407536 },
    { lat: 48.8666688, lng: 18.5375669 },
    { lat: 48.8675885, lng: 18.5338871 },
    { lat: 48.8676906, lng: 18.5321538 },
    { lat: 48.8674974, lng: 18.5307807 },
    { lat: 48.8674762, lng: 18.5290683 },
    { lat: 48.8656336, lng: 18.5284154 },
    { lat: 48.8649214, lng: 18.5281362 },
    { lat: 48.8641501, lng: 18.5281837 },
    { lat: 48.8618415, lng: 18.5276938 },
    { lat: 48.8595357, lng: 18.5268594 },
    { lat: 48.8580587, lng: 18.527247 },
    { lat: 48.856183, lng: 18.5290056 },
    { lat: 48.8538886, lng: 18.5301638 },
    { lat: 48.8529454, lng: 18.5295594 },
    { lat: 48.8506133, lng: 18.5326981 },
    { lat: 48.8503582, lng: 18.5329231 },
    { lat: 48.8501189, lng: 18.5331471 },
    { lat: 48.8498509, lng: 18.5333826 },
    { lat: 48.8496137, lng: 18.5335831 },
    { lat: 48.849338, lng: 18.533946 },
    { lat: 48.8487444, lng: 18.5347097 },
    { lat: 48.8486708, lng: 18.5348066 },
    { lat: 48.84838, lng: 18.5349364 },
    { lat: 48.8479114, lng: 18.5351336 },
    { lat: 48.8474666, lng: 18.535312 },
    { lat: 48.8469691, lng: 18.5355207 },
    { lat: 48.8464483, lng: 18.5357326 },
    { lat: 48.8458042, lng: 18.5363747 },
    { lat: 48.8452996, lng: 18.5368664 },
    { lat: 48.84466, lng: 18.5378183 },
    { lat: 48.8443993, lng: 18.5382093 },
    { lat: 48.8438224, lng: 18.5390639 },
    { lat: 48.8432018, lng: 18.5388986 },
    { lat: 48.8427718, lng: 18.5387707 },
    { lat: 48.8425858, lng: 18.5387116 },
    { lat: 48.8422855, lng: 18.538704 },
    { lat: 48.8418103, lng: 18.5386982 },
    { lat: 48.8408239, lng: 18.5386717 },
    { lat: 48.8395244, lng: 18.5390621 },
    { lat: 48.8381915, lng: 18.5397922 },
    { lat: 48.8369017, lng: 18.5410696 },
    { lat: 48.835636, lng: 18.541687 },
    { lat: 48.834834, lng: 18.5417531 },
    { lat: 48.8347597, lng: 18.5417717 },
    { lat: 48.8346584, lng: 18.5419877 },
    { lat: 48.8346287, lng: 18.5421013 },
    { lat: 48.8346052, lng: 18.5427914 },
    { lat: 48.8345492, lng: 18.5430694 },
    { lat: 48.8344982, lng: 18.543562 },
    { lat: 48.834257, lng: 18.5443114 },
    { lat: 48.8341878, lng: 18.5463769 },
    { lat: 48.8344047, lng: 18.5481297 },
    { lat: 48.8343122, lng: 18.5495633 },
    { lat: 48.834554, lng: 18.5495289 },
    { lat: 48.8345261, lng: 18.5496588 },
    { lat: 48.8345347, lng: 18.5502914 },
    { lat: 48.8345081, lng: 18.5509464 },
    { lat: 48.8344921, lng: 18.5512727 },
    { lat: 48.8344764, lng: 18.5513595 },
    { lat: 48.8344908, lng: 18.5514372 },
    { lat: 48.8344461, lng: 18.5517539 },
    { lat: 48.8342258, lng: 18.5525204 },
    { lat: 48.8342419, lng: 18.5527955 },
    { lat: 48.8341863, lng: 18.5533506 },
    { lat: 48.8342008, lng: 18.5538006 },
    { lat: 48.8342292, lng: 18.5539532 },
    { lat: 48.8341443, lng: 18.5542577 },
    { lat: 48.8340813, lng: 18.5544252 },
    { lat: 48.834106, lng: 18.554758 },
    { lat: 48.8340844, lng: 18.5550883 },
    { lat: 48.8340749, lng: 18.5556616 },
    { lat: 48.834059, lng: 18.5559471 },
    { lat: 48.8340835, lng: 18.5560676 },
    { lat: 48.834059, lng: 18.5562389 },
    { lat: 48.8340669, lng: 18.556887 },
    { lat: 48.834108, lng: 18.5571211 },
    { lat: 48.8341009, lng: 18.5573039 },
    { lat: 48.8341235, lng: 18.5574289 },
    { lat: 48.8340536, lng: 18.5577437 },
    { lat: 48.8340596, lng: 18.5580193 },
    { lat: 48.8340221, lng: 18.5583473 },
    { lat: 48.834027, lng: 18.5585812 },
    { lat: 48.8339438, lng: 18.5590438 },
    { lat: 48.8339454, lng: 18.5592204 },
    { lat: 48.8338928, lng: 18.5594976 },
    { lat: 48.8339299, lng: 18.5597243 },
    { lat: 48.833853, lng: 18.5599064 },
    { lat: 48.8338107, lng: 18.560206 },
    { lat: 48.8337826, lng: 18.5606033 },
    { lat: 48.8336573, lng: 18.5610385 },
    { lat: 48.8336755, lng: 18.5614179 },
    { lat: 48.8335874, lng: 18.5616247 },
    { lat: 48.8336591, lng: 18.5620705 },
    { lat: 48.8336489, lng: 18.5622368 },
    { lat: 48.8336327, lng: 18.5624995 },
    { lat: 48.833627, lng: 18.5625401 },
    { lat: 48.8335692, lng: 18.5629535 },
    { lat: 48.8335599, lng: 18.5631654 },
    { lat: 48.8335338, lng: 18.5637581 },
    { lat: 48.8335707, lng: 18.5639801 },
    { lat: 48.833565, lng: 18.564268 },
    { lat: 48.8334619, lng: 18.5646163 },
    { lat: 48.8334759, lng: 18.5648737 },
    { lat: 48.8334629, lng: 18.5650338 },
    { lat: 48.8334488, lng: 18.5652065 },
    { lat: 48.8333732, lng: 18.5656616 },
    { lat: 48.8332879, lng: 18.5660678 },
    { lat: 48.833184, lng: 18.5663571 },
    { lat: 48.8328062, lng: 18.5669784 },
    { lat: 48.8326438, lng: 18.5671267 },
    { lat: 48.8324366, lng: 18.5671335 },
    { lat: 48.8323443, lng: 18.567218 },
    { lat: 48.832128, lng: 18.567309 },
    { lat: 48.8319621, lng: 18.5675154 },
    { lat: 48.8317339, lng: 18.567673 },
    { lat: 48.8314813, lng: 18.5680105 },
    { lat: 48.8313923, lng: 18.5682166 },
    { lat: 48.8313752, lng: 18.5682561 },
    { lat: 48.8312639, lng: 18.5685221 },
    { lat: 48.8311923, lng: 18.5686934 },
    { lat: 48.8310954, lng: 18.5690248 },
    { lat: 48.8309714, lng: 18.5693191 },
    { lat: 48.8308294, lng: 18.569735 },
    { lat: 48.8308137, lng: 18.5697811 },
    { lat: 48.8306394, lng: 18.5701643 },
    { lat: 48.8306131, lng: 18.5702221 },
    { lat: 48.8305707, lng: 18.5704607 },
    { lat: 48.8305247, lng: 18.5706508 },
    { lat: 48.8304221, lng: 18.570737 },
    { lat: 48.8304009, lng: 18.5709079 },
    { lat: 48.8302449, lng: 18.5710876 },
    { lat: 48.8302299, lng: 18.5712918 },
    { lat: 48.830125, lng: 18.5715407 },
    { lat: 48.8301347, lng: 18.5716594 },
    { lat: 48.8300331, lng: 18.5720086 },
    { lat: 48.8298761, lng: 18.5722962 },
    { lat: 48.8296556, lng: 18.5726189 },
    { lat: 48.8294083, lng: 18.5730721 },
    { lat: 48.8292432, lng: 18.5734194 },
    { lat: 48.8290853, lng: 18.5735461 },
    { lat: 48.8289623, lng: 18.5737525 },
    { lat: 48.8287751, lng: 18.5742562 },
    { lat: 48.8287087, lng: 18.5746532 },
    { lat: 48.8286414, lng: 18.5748459 },
    { lat: 48.8283057, lng: 18.5752896 },
    { lat: 48.8282568, lng: 18.5754525 },
    { lat: 48.8280973, lng: 18.5756498 },
    { lat: 48.828044, lng: 18.5757911 },
    { lat: 48.8279921, lng: 18.5758326 },
    { lat: 48.8278186, lng: 18.5762515 },
    { lat: 48.8277703, lng: 18.5764465 },
    { lat: 48.8277213, lng: 18.5765818 },
    { lat: 48.8276727, lng: 18.5768706 },
    { lat: 48.8276056, lng: 18.5771732 },
    { lat: 48.8275036, lng: 18.5774382 },
    { lat: 48.8274962, lng: 18.5775469 },
    { lat: 48.8274936, lng: 18.5775852 },
    { lat: 48.8274199, lng: 18.5776626 },
    { lat: 48.827372, lng: 18.577713 },
    { lat: 48.8273514, lng: 18.5777918 },
    { lat: 48.8273261, lng: 18.5778884 },
    { lat: 48.8272106, lng: 18.5780661 },
    { lat: 48.8271815, lng: 18.5781108 },
    { lat: 48.8270037, lng: 18.5781836 },
    { lat: 48.8269632, lng: 18.5783537 },
    { lat: 48.8268814, lng: 18.5786132 },
    { lat: 48.8268121, lng: 18.5788331 },
    { lat: 48.8266991, lng: 18.5789764 },
    { lat: 48.8265921, lng: 18.5792496 },
    { lat: 48.8265401, lng: 18.5794548 },
    { lat: 48.8265047, lng: 18.5795944 },
    { lat: 48.8264651, lng: 18.5797215 },
    { lat: 48.8264297, lng: 18.5798349 },
    { lat: 48.8262527, lng: 18.5799389 },
    { lat: 48.8261291, lng: 18.5802592 },
    { lat: 48.8261909, lng: 18.5804223 },
    { lat: 48.8260849, lng: 18.5806126 },
    { lat: 48.8259, lng: 18.5806119 },
    { lat: 48.825689, lng: 18.5808002 },
    { lat: 48.8255188, lng: 18.5810938 },
    { lat: 48.8253857, lng: 18.5812366 },
    { lat: 48.8253553, lng: 18.5812992 },
    { lat: 48.8252455, lng: 18.5815257 },
    { lat: 48.8250514, lng: 18.5816289 },
    { lat: 48.8249335, lng: 18.5817605 },
    { lat: 48.8247862, lng: 18.5820124 },
    { lat: 48.8247444, lng: 18.582084 },
    { lat: 48.8245527, lng: 18.582145 },
    { lat: 48.8244738, lng: 18.5822943 },
    { lat: 48.8243806, lng: 18.5823406 },
    { lat: 48.8243406, lng: 18.5825098 },
    { lat: 48.8242557, lng: 18.5828687 },
    { lat: 48.8241197, lng: 18.5831068 },
    { lat: 48.8240726, lng: 18.5831216 },
    { lat: 48.8237992, lng: 18.5832073 },
    { lat: 48.8236798, lng: 18.5832795 },
    { lat: 48.8235937, lng: 18.5833316 },
    { lat: 48.8234641, lng: 18.5835184 },
    { lat: 48.8233511, lng: 18.5840382 },
    { lat: 48.8230467, lng: 18.5844153 },
    { lat: 48.8228794, lng: 18.5844954 },
    { lat: 48.8228001, lng: 18.5846334 },
    { lat: 48.8227233, lng: 18.5846941 },
    { lat: 48.8227051, lng: 18.5847733 },
    { lat: 48.8224998, lng: 18.5852347 },
    { lat: 48.8225139, lng: 18.5854731 },
    { lat: 48.8224997, lng: 18.5856302 },
    { lat: 48.822545, lng: 18.5856937 },
    { lat: 48.8225246, lng: 18.5858588 },
    { lat: 48.8224714, lng: 18.5859591 },
    { lat: 48.8225067, lng: 18.5861527 },
    { lat: 48.8224522, lng: 18.5864401 },
    { lat: 48.822379, lng: 18.5866292 },
    { lat: 48.8222087, lng: 18.5869413 },
    { lat: 48.822216, lng: 18.5871433 },
    { lat: 48.822139, lng: 18.5874725 },
    { lat: 48.8221792, lng: 18.5877739 },
    { lat: 48.8221447, lng: 18.5879321 },
    { lat: 48.8222024, lng: 18.5882696 },
    { lat: 48.8221588, lng: 18.5883992 },
    { lat: 48.8221678, lng: 18.5885477 },
    { lat: 48.8220974, lng: 18.5886617 },
    { lat: 48.8221004, lng: 18.5887841 },
    { lat: 48.8220709, lng: 18.5889674 },
    { lat: 48.8220201, lng: 18.5890467 },
    { lat: 48.8220004, lng: 18.5892475 },
    { lat: 48.821948, lng: 18.5893846 },
    { lat: 48.822018, lng: 18.589678 },
    { lat: 48.8220426, lng: 18.5898407 },
    { lat: 48.8220244, lng: 18.5903176 },
    { lat: 48.8220076, lng: 18.5904267 },
    { lat: 48.8219997, lng: 18.5906571 },
    { lat: 48.8220783, lng: 18.5909623 },
    { lat: 48.8221131, lng: 18.5911248 },
    { lat: 48.8222038, lng: 18.5911988 },
    { lat: 48.8223183, lng: 18.5914792 },
    { lat: 48.8223471, lng: 18.5917645 },
    { lat: 48.8224198, lng: 18.5918543 },
    { lat: 48.8224353, lng: 18.5920195 },
    { lat: 48.8225202, lng: 18.59213 },
    { lat: 48.8226385, lng: 18.5921665 },
    { lat: 48.8226655, lng: 18.5922895 },
    { lat: 48.8226671, lng: 18.5924257 },
    { lat: 48.822591, lng: 18.5927104 },
    { lat: 48.8224143, lng: 18.5928425 },
    { lat: 48.8223539, lng: 18.5931857 },
    { lat: 48.822187, lng: 18.593623 },
    { lat: 48.82202, lng: 18.5941695 },
    { lat: 48.8219038, lng: 18.5942712 },
    { lat: 48.8219266, lng: 18.5945891 },
    { lat: 48.8218169, lng: 18.5949599 },
    { lat: 48.8216388, lng: 18.5952131 },
    { lat: 48.8215284, lng: 18.5955271 },
    { lat: 48.8213765, lng: 18.5956214 },
    { lat: 48.8212976, lng: 18.5957439 },
    { lat: 48.8211429, lng: 18.5957849 },
    { lat: 48.8210203, lng: 18.5960012 },
    { lat: 48.8207356, lng: 18.5960335 },
    { lat: 48.8205879, lng: 18.5960135 },
    { lat: 48.8205346, lng: 18.5961329 },
    { lat: 48.8204147, lng: 18.5963549 },
    { lat: 48.8202335, lng: 18.5964278 },
    { lat: 48.8200368, lng: 18.5967932 },
    { lat: 48.8200175, lng: 18.5970232 },
    { lat: 48.8199105, lng: 18.5972577 },
    { lat: 48.8199181, lng: 18.5973548 },
    { lat: 48.8200429, lng: 18.5975559 },
    { lat: 48.8200848, lng: 18.5977501 },
    { lat: 48.8200637, lng: 18.5979016 },
    { lat: 48.8199441, lng: 18.5979858 },
    { lat: 48.8199683, lng: 18.5981628 },
    { lat: 48.8199596, lng: 18.5985444 },
    { lat: 48.8198454, lng: 18.5987894 },
    { lat: 48.8197316, lng: 18.5989802 },
    { lat: 48.8197034, lng: 18.5991895 },
    { lat: 48.8196134, lng: 18.5994219 },
    { lat: 48.8197147, lng: 18.5996259 },
    { lat: 48.8197042, lng: 18.5999754 },
    { lat: 48.8195786, lng: 18.6001792 },
    { lat: 48.8196066, lng: 18.6003436 },
  ],
  Pravenec: [
    { lat: 48.8527376, lng: 18.6438119 },
    { lat: 48.8526958, lng: 18.6438708 },
    { lat: 48.8526732, lng: 18.6437887 },
    { lat: 48.8526398, lng: 18.6437115 },
    { lat: 48.8526232, lng: 18.6436978 },
    { lat: 48.8525349, lng: 18.6436685 },
    { lat: 48.8524338, lng: 18.6438672 },
    { lat: 48.8524166, lng: 18.6438811 },
    { lat: 48.8523833, lng: 18.6438906 },
    { lat: 48.8523533, lng: 18.6438778 },
    { lat: 48.8522219, lng: 18.6435959 },
    { lat: 48.8521747, lng: 18.6435566 },
    { lat: 48.8519178, lng: 18.643494 },
    { lat: 48.8517824, lng: 18.6434343 },
    { lat: 48.8517588, lng: 18.6434344 },
    { lat: 48.8516127, lng: 18.6436095 },
    { lat: 48.8515618, lng: 18.6435864 },
    { lat: 48.8515251, lng: 18.6435547 },
    { lat: 48.851322, lng: 18.6432384 },
    { lat: 48.8513054, lng: 18.6432243 },
    { lat: 48.8511863, lng: 18.6431666 },
    { lat: 48.8510416, lng: 18.6431615 },
    { lat: 48.8509922, lng: 18.6431872 },
    { lat: 48.8509294, lng: 18.643277 },
    { lat: 48.8509395, lng: 18.6434662 },
    { lat: 48.8509283, lng: 18.6434873 },
    { lat: 48.8509073, lng: 18.6434963 },
    { lat: 48.8508199, lng: 18.6434874 },
    { lat: 48.8507629, lng: 18.6434353 },
    { lat: 48.850606, lng: 18.643369 },
    { lat: 48.8505292, lng: 18.6433637 },
    { lat: 48.8504346, lng: 18.6433234 },
    { lat: 48.8501312, lng: 18.6437492 },
    { lat: 48.8501007, lng: 18.6437669 },
    { lat: 48.8500655, lng: 18.6437623 },
    { lat: 48.8500409, lng: 18.6437045 },
    { lat: 48.8500375, lng: 18.6436794 },
    { lat: 48.8500597, lng: 18.6433179 },
    { lat: 48.8500544, lng: 18.6432813 },
    { lat: 48.8500304, lng: 18.643233 },
    { lat: 48.8500017, lng: 18.6432007 },
    { lat: 48.8499878, lng: 18.6431967 },
    { lat: 48.849852, lng: 18.6432514 },
    { lat: 48.8496988, lng: 18.6433404 },
    { lat: 48.8496775, lng: 18.643362 },
    { lat: 48.8495945, lng: 18.6435186 },
    { lat: 48.8493964, lng: 18.6435899 },
    { lat: 48.8492874, lng: 18.6436591 },
    { lat: 48.8491985, lng: 18.6438971 },
    { lat: 48.8491673, lng: 18.6439238 },
    { lat: 48.849012, lng: 18.6439934 },
    { lat: 48.8487399, lng: 18.6439649 },
    { lat: 48.8487066, lng: 18.6439564 },
    { lat: 48.8486715, lng: 18.6439335 },
    { lat: 48.8485457, lng: 18.6437946 },
    { lat: 48.8485202, lng: 18.6437376 },
    { lat: 48.8485046, lng: 18.6436579 },
    { lat: 48.8484632, lng: 18.6433482 },
    { lat: 48.8483919, lng: 18.6432395 },
    { lat: 48.8483059, lng: 18.6432128 },
    { lat: 48.8482514, lng: 18.6432456 },
    { lat: 48.8481918, lng: 18.6433321 },
    { lat: 48.8480154, lng: 18.6433437 },
    { lat: 48.8478403, lng: 18.6433849 },
    { lat: 48.8476949, lng: 18.6432778 },
    { lat: 48.8475811, lng: 18.6432177 },
    { lat: 48.8474841, lng: 18.6433044 },
    { lat: 48.847409, lng: 18.6434157 },
    { lat: 48.8473892, lng: 18.6434264 },
    { lat: 48.8473006, lng: 18.643395 },
    { lat: 48.8471817, lng: 18.6433201 },
    { lat: 48.8470111, lng: 18.6432322 },
    { lat: 48.8469952, lng: 18.6432298 },
    { lat: 48.8469643, lng: 18.6432408 },
    { lat: 48.8469513, lng: 18.6432542 },
    { lat: 48.8468572, lng: 18.6434337 },
    { lat: 48.8467881, lng: 18.6434275 },
    { lat: 48.8467708, lng: 18.6434189 },
    { lat: 48.8467503, lng: 18.6433931 },
    { lat: 48.8467382, lng: 18.6433563 },
    { lat: 48.8467716, lng: 18.6431373 },
    { lat: 48.8467545, lng: 18.6430739 },
    { lat: 48.8466953, lng: 18.6429351 },
    { lat: 48.8466608, lng: 18.6427767 },
    { lat: 48.8466646, lng: 18.6427183 },
    { lat: 48.8467292, lng: 18.642517 },
    { lat: 48.8466705, lng: 18.6421578 },
    { lat: 48.8466551, lng: 18.6421218 },
    { lat: 48.8466234, lng: 18.6420777 },
    { lat: 48.8465706, lng: 18.6420802 },
    { lat: 48.8465552, lng: 18.6420868 },
    { lat: 48.846223, lng: 18.6423729 },
    { lat: 48.8462063, lng: 18.642395 },
    { lat: 48.8461131, lng: 18.6427599 },
    { lat: 48.8460998, lng: 18.6428743 },
    { lat: 48.8461027, lng: 18.6432023 },
    { lat: 48.8460971, lng: 18.6432427 },
    { lat: 48.846049, lng: 18.6434008 },
    { lat: 48.8460303, lng: 18.6434307 },
    { lat: 48.8460182, lng: 18.643434 },
    { lat: 48.8459245, lng: 18.6433703 },
    { lat: 48.8457774, lng: 18.6432351 },
    { lat: 48.845743, lng: 18.643013 },
    { lat: 48.8457298, lng: 18.6429992 },
    { lat: 48.8455311, lng: 18.6429531 },
    { lat: 48.8454718, lng: 18.6427939 },
    { lat: 48.8454305, lng: 18.6426324 },
    { lat: 48.8453103, lng: 18.6424918 },
    { lat: 48.8451511, lng: 18.642343 },
    { lat: 48.8449147, lng: 18.6422887 },
    { lat: 48.8447959, lng: 18.6423666 },
    { lat: 48.8447555, lng: 18.6425159 },
    { lat: 48.8447412, lng: 18.6426072 },
    { lat: 48.8446699, lng: 18.6427724 },
    { lat: 48.8445457, lng: 18.6427912 },
    { lat: 48.8444828, lng: 18.6424993 },
    { lat: 48.8444567, lng: 18.6424527 },
    { lat: 48.8444257, lng: 18.6424432 },
    { lat: 48.8443355, lng: 18.6424848 },
    { lat: 48.8442939, lng: 18.64255 },
    { lat: 48.8441919, lng: 18.6426284 },
    { lat: 48.8441517, lng: 18.6426756 },
    { lat: 48.8440839, lng: 18.6427165 },
    { lat: 48.843908, lng: 18.6428033 },
    { lat: 48.8438582, lng: 18.6428177 },
    { lat: 48.8438294, lng: 18.642809 },
    { lat: 48.8437289, lng: 18.6426585 },
    { lat: 48.8436151, lng: 18.6425142 },
    { lat: 48.8435624, lng: 18.6423513 },
    { lat: 48.8435259, lng: 18.6422791 },
    { lat: 48.8433434, lng: 18.6421769 },
    { lat: 48.8430848, lng: 18.6421718 },
    { lat: 48.8430191, lng: 18.6421885 },
    { lat: 48.8429307, lng: 18.6421177 },
    { lat: 48.8429182, lng: 18.6420082 },
    { lat: 48.8429748, lng: 18.641824 },
    { lat: 48.8429653, lng: 18.6417486 },
    { lat: 48.8427867, lng: 18.6416173 },
    { lat: 48.8425542, lng: 18.6416133 },
    { lat: 48.8424179, lng: 18.6413862 },
    { lat: 48.8424134, lng: 18.6413638 },
    { lat: 48.8424113, lng: 18.6413247 },
    { lat: 48.842386, lng: 18.6412085 },
    { lat: 48.8422203, lng: 18.6408329 },
    { lat: 48.8420295, lng: 18.6405975 },
    { lat: 48.8420063, lng: 18.6405835 },
    { lat: 48.8419787, lng: 18.6405765 },
    { lat: 48.8419558, lng: 18.640589 },
    { lat: 48.8419387, lng: 18.6406065 },
    { lat: 48.8418811, lng: 18.6407525 },
    { lat: 48.841885, lng: 18.6408261 },
    { lat: 48.8418904, lng: 18.6408419 },
    { lat: 48.8418989, lng: 18.6408548 },
    { lat: 48.8419946, lng: 18.6409122 },
    { lat: 48.8420073, lng: 18.6409425 },
    { lat: 48.8420128, lng: 18.6409787 },
    { lat: 48.8420098, lng: 18.6409894 },
    { lat: 48.8419761, lng: 18.6410305 },
    { lat: 48.8418972, lng: 18.6410534 },
    { lat: 48.841755, lng: 18.6411163 },
    { lat: 48.8417302, lng: 18.6411559 },
    { lat: 48.8416423, lng: 18.6414531 },
    { lat: 48.8415907, lng: 18.6415673 },
    { lat: 48.8415425, lng: 18.6416147 },
    { lat: 48.8414936, lng: 18.6415846 },
    { lat: 48.8413474, lng: 18.6414453 },
    { lat: 48.8412277, lng: 18.6413147 },
    { lat: 48.8410809, lng: 18.6411419 },
    { lat: 48.8409665, lng: 18.6410489 },
    { lat: 48.840952, lng: 18.6410521 },
    { lat: 48.8409422, lng: 18.6410812 },
    { lat: 48.8409328, lng: 18.6411568 },
    { lat: 48.8409305, lng: 18.641348 },
    { lat: 48.8409055, lng: 18.6414681 },
    { lat: 48.8408661, lng: 18.6415972 },
    { lat: 48.8408383, lng: 18.6416482 },
    { lat: 48.8407954, lng: 18.6416897 },
    { lat: 48.84074, lng: 18.6417047 },
    { lat: 48.8407113, lng: 18.6416993 },
    { lat: 48.8406916, lng: 18.6416663 },
    { lat: 48.8406797, lng: 18.6415714 },
    { lat: 48.840658, lng: 18.6415633 },
    { lat: 48.8406074, lng: 18.6415277 },
    { lat: 48.8405664, lng: 18.6414783 },
    { lat: 48.8405372, lng: 18.6413515 },
    { lat: 48.8405146, lng: 18.641331 },
    { lat: 48.8404847, lng: 18.6413207 },
    { lat: 48.8401808, lng: 18.6413287 },
    { lat: 48.840016, lng: 18.6412655 },
    { lat: 48.8399914, lng: 18.6412918 },
    { lat: 48.839975, lng: 18.6414052 },
    { lat: 48.8400112, lng: 18.6415343 },
    { lat: 48.8399875, lng: 18.6415991 },
    { lat: 48.8399459, lng: 18.6416211 },
    { lat: 48.8398529, lng: 18.6415885 },
    { lat: 48.8397933, lng: 18.6414461 },
    { lat: 48.8396763, lng: 18.6412421 },
    { lat: 48.8394495, lng: 18.6411962 },
    { lat: 48.8393117, lng: 18.6411508 },
    { lat: 48.8391187, lng: 18.6411484 },
    { lat: 48.8390254, lng: 18.6411316 },
    { lat: 48.8389108, lng: 18.6410354 },
    { lat: 48.8389309, lng: 18.6408195 },
    { lat: 48.8389123, lng: 18.6407674 },
    { lat: 48.8388474, lng: 18.6407553 },
    { lat: 48.8387374, lng: 18.6408071 },
    { lat: 48.8386436, lng: 18.6407016 },
    { lat: 48.8383253, lng: 18.640508 },
    { lat: 48.8381262, lng: 18.640493 },
    { lat: 48.8380201, lng: 18.640554 },
    { lat: 48.8378676, lng: 18.6407214 },
    { lat: 48.8378017, lng: 18.6407309 },
    { lat: 48.8377636, lng: 18.6407162 },
    { lat: 48.8376491, lng: 18.6405771 },
    { lat: 48.8375515, lng: 18.6404816 },
    { lat: 48.8375219, lng: 18.6404806 },
    { lat: 48.8375086, lng: 18.6405052 },
    { lat: 48.8375023, lng: 18.6405324 },
    { lat: 48.8375172, lng: 18.6407676 },
    { lat: 48.8375053, lng: 18.6408414 },
    { lat: 48.8373853, lng: 18.6409381 },
    { lat: 48.8373242, lng: 18.6409764 },
    { lat: 48.8372107, lng: 18.6410058 },
    { lat: 48.8371727, lng: 18.6409721 },
    { lat: 48.8371228, lng: 18.6407771 },
    { lat: 48.8370738, lng: 18.6407219 },
    { lat: 48.836914, lng: 18.6406681 },
    { lat: 48.8368765, lng: 18.6406394 },
    { lat: 48.8368486, lng: 18.6404975 },
    { lat: 48.8367473, lng: 18.6404379 },
    { lat: 48.8366738, lng: 18.6404545 },
    { lat: 48.8365533, lng: 18.6405044 },
    { lat: 48.8364789, lng: 18.6406073 },
    { lat: 48.8363749, lng: 18.6405854 },
    { lat: 48.8362951, lng: 18.6405472 },
    { lat: 48.8362102, lng: 18.6403915 },
    { lat: 48.8361889, lng: 18.6403941 },
    { lat: 48.8361681, lng: 18.6404074 },
    { lat: 48.8360371, lng: 18.6406147 },
    { lat: 48.8360154, lng: 18.6406102 },
    { lat: 48.8359967, lng: 18.6405964 },
    { lat: 48.8359303, lng: 18.6403887 },
    { lat: 48.8359029, lng: 18.6403637 },
    { lat: 48.8358879, lng: 18.6403563 },
    { lat: 48.8358239, lng: 18.6403613 },
    { lat: 48.8357951, lng: 18.6403942 },
    { lat: 48.8357735, lng: 18.6404323 },
    { lat: 48.835751, lng: 18.6405138 },
    { lat: 48.8357198, lng: 18.6405456 },
    { lat: 48.8356713, lng: 18.6405673 },
    { lat: 48.8353849, lng: 18.6405058 },
    { lat: 48.8352596, lng: 18.6405004 },
    { lat: 48.8350524, lng: 18.640445 },
    { lat: 48.8349847, lng: 18.6403588 },
    { lat: 48.8349163, lng: 18.6403475 },
    { lat: 48.8349044, lng: 18.6403554 },
    { lat: 48.8347933, lng: 18.6405348 },
    { lat: 48.8346301, lng: 18.6405419 },
    { lat: 48.8344851, lng: 18.6405927 },
    { lat: 48.8343141, lng: 18.6405589 },
    { lat: 48.8342999, lng: 18.6405685 },
    { lat: 48.8342884, lng: 18.6405832 },
    { lat: 48.8342568, lng: 18.6406669 },
    { lat: 48.8342083, lng: 18.6409629 },
    { lat: 48.8339723, lng: 18.6411905 },
    { lat: 48.8338973, lng: 18.6411568 },
    { lat: 48.8338341, lng: 18.6411778 },
    { lat: 48.8335967, lng: 18.6413562 },
    { lat: 48.8335006, lng: 18.641353 },
    { lat: 48.8333657, lng: 18.641443 },
    { lat: 48.8333375, lng: 18.6414242 },
    { lat: 48.8333154, lng: 18.6411796 },
    { lat: 48.8332719, lng: 18.6409762 },
    { lat: 48.833263, lng: 18.6409594 },
    { lat: 48.83324, lng: 18.6409322 },
    { lat: 48.8331121, lng: 18.641027 },
    { lat: 48.8330615, lng: 18.6410268 },
    { lat: 48.8330139, lng: 18.6407516 },
    { lat: 48.832979, lng: 18.6407086 },
    { lat: 48.8329184, lng: 18.6407168 },
    { lat: 48.8328597, lng: 18.6407981 },
    { lat: 48.8327402, lng: 18.6407777 },
    { lat: 48.8326896, lng: 18.6407374 },
    { lat: 48.8326814, lng: 18.6407129 },
    { lat: 48.8326514, lng: 18.6405537 },
    { lat: 48.8326432, lng: 18.640462 },
    { lat: 48.8326297, lng: 18.6404436 },
    { lat: 48.8323588, lng: 18.6402049 },
    { lat: 48.8323256, lng: 18.6399641 },
    { lat: 48.8322714, lng: 18.6397337 },
    { lat: 48.8321956, lng: 18.6396421 },
    { lat: 48.832095, lng: 18.6397053 },
    { lat: 48.8318721, lng: 18.6396908 },
    { lat: 48.8318504, lng: 18.6397268 },
    { lat: 48.8318109, lng: 18.6399174 },
    { lat: 48.8317649, lng: 18.640024 },
    { lat: 48.8316177, lng: 18.6402053 },
    { lat: 48.8315649, lng: 18.6402086 },
    { lat: 48.831542, lng: 18.6399927 },
    { lat: 48.8315114, lng: 18.6398851 },
    { lat: 48.831431, lng: 18.6398352 },
    { lat: 48.8312825, lng: 18.6399891 },
    { lat: 48.8312251, lng: 18.6401 },
    { lat: 48.8311852, lng: 18.6402144 },
    { lat: 48.8310449, lng: 18.6403126 },
    { lat: 48.8310391, lng: 18.6403279 },
    { lat: 48.8310229, lng: 18.6404499 },
    { lat: 48.8310145, lng: 18.6407129 },
    { lat: 48.8309796, lng: 18.6407981 },
    { lat: 48.830892, lng: 18.6408916 },
    { lat: 48.830825, lng: 18.6409463 },
    { lat: 48.8307879, lng: 18.640885 },
    { lat: 48.830783, lng: 18.6407503 },
    { lat: 48.8307564, lng: 18.6406551 },
    { lat: 48.830744, lng: 18.6406355 },
    { lat: 48.8306482, lng: 18.6406108 },
    { lat: 48.8306184, lng: 18.6405618 },
    { lat: 48.8306139, lng: 18.6405409 },
    { lat: 48.8306159, lng: 18.6405152 },
    { lat: 48.8306393, lng: 18.640405 },
    { lat: 48.8306404, lng: 18.6403816 },
    { lat: 48.8306267, lng: 18.6403367 },
    { lat: 48.8306017, lng: 18.6403126 },
    { lat: 48.8304462, lng: 18.6403167 },
    { lat: 48.8303247, lng: 18.6404705 },
    { lat: 48.8302806, lng: 18.6404469 },
    { lat: 48.8302598, lng: 18.6402701 },
    { lat: 48.8302529, lng: 18.6402473 },
    { lat: 48.8302269, lng: 18.6402194 },
    { lat: 48.8300966, lng: 18.6403156 },
    { lat: 48.8299882, lng: 18.6403575 },
    { lat: 48.8298462, lng: 18.6404412 },
    { lat: 48.8297757, lng: 18.6404101 },
    { lat: 48.8296993, lng: 18.6402269 },
    { lat: 48.8296962, lng: 18.6401478 },
    { lat: 48.8297225, lng: 18.6399237 },
    { lat: 48.8297149, lng: 18.6399057 },
    { lat: 48.8295465, lng: 18.6396901 },
    { lat: 48.8294581, lng: 18.6397264 },
    { lat: 48.8294457, lng: 18.6397455 },
    { lat: 48.8294424, lng: 18.6397677 },
    { lat: 48.8294334, lng: 18.6400634 },
    { lat: 48.8293785, lng: 18.6400931 },
    { lat: 48.8291895, lng: 18.640098 },
    { lat: 48.8291527, lng: 18.640148 },
    { lat: 48.8291473, lng: 18.6401759 },
    { lat: 48.8291712, lng: 18.6403877 },
    { lat: 48.829051, lng: 18.6406513 },
    { lat: 48.8288763, lng: 18.6406537 },
    { lat: 48.828668, lng: 18.6408078 },
    { lat: 48.828685, lng: 18.6408734 },
    { lat: 48.828776, lng: 18.6410548 },
    { lat: 48.828978, lng: 18.641331 },
    { lat: 48.8292467, lng: 18.6416302 },
    { lat: 48.829362, lng: 18.641741 },
    { lat: 48.8296126, lng: 18.6419361 },
    { lat: 48.8296166, lng: 18.6420337 },
    { lat: 48.8297088, lng: 18.6420471 },
    { lat: 48.8298472, lng: 18.6423591 },
    { lat: 48.8299469, lng: 18.6425355 },
    { lat: 48.8301182, lng: 18.6427199 },
    { lat: 48.8302538, lng: 18.6428328 },
    { lat: 48.8304867, lng: 18.642989 },
    { lat: 48.8307591, lng: 18.6430844 },
    { lat: 48.8308071, lng: 18.6436634 },
    { lat: 48.8308074, lng: 18.6438599 },
    { lat: 48.8309994, lng: 18.6441843 },
    { lat: 48.8314646, lng: 18.6444866 },
    { lat: 48.831564, lng: 18.6445771 },
    { lat: 48.831929, lng: 18.6447783 },
    { lat: 48.8320142, lng: 18.644833 },
    { lat: 48.8320926, lng: 18.6449075 },
    { lat: 48.8321732, lng: 18.6450443 },
    { lat: 48.8322076, lng: 18.6451396 },
    { lat: 48.8322399, lng: 18.6453415 },
    { lat: 48.8323154, lng: 18.6453609 },
    { lat: 48.8325148, lng: 18.6454388 },
    { lat: 48.8326575, lng: 18.6454732 },
    { lat: 48.8326875, lng: 18.6456771 },
    { lat: 48.8327484, lng: 18.6459038 },
    { lat: 48.8328463, lng: 18.6461975 },
    { lat: 48.833072, lng: 18.6462192 },
    { lat: 48.833153, lng: 18.6467441 },
    { lat: 48.8334346, lng: 18.6470332 },
    { lat: 48.8336558, lng: 18.6472287 },
    { lat: 48.8337969, lng: 18.6472891 },
    { lat: 48.8338803, lng: 18.6475234 },
    { lat: 48.8339723, lng: 18.648043 },
    { lat: 48.8339936, lng: 18.6481832 },
    { lat: 48.8340331, lng: 18.6483133 },
    { lat: 48.8341067, lng: 18.6484657 },
    { lat: 48.8341535, lng: 18.6485406 },
    { lat: 48.8341629, lng: 18.6486933 },
    { lat: 48.8341733, lng: 18.6487418 },
    { lat: 48.8341493, lng: 18.6488826 },
    { lat: 48.8341868, lng: 18.6490126 },
    { lat: 48.8342359, lng: 18.6490692 },
    { lat: 48.8342726, lng: 18.6491861 },
    { lat: 48.8343312, lng: 18.6492688 },
    { lat: 48.8343902, lng: 18.6493822 },
    { lat: 48.834402, lng: 18.6494981 },
    { lat: 48.8343903, lng: 18.6495314 },
    { lat: 48.8345189, lng: 18.6496159 },
    { lat: 48.8346377, lng: 18.6498129 },
    { lat: 48.8346746, lng: 18.6498048 },
    { lat: 48.8347449, lng: 18.6498775 },
    { lat: 48.8347838, lng: 18.6498666 },
    { lat: 48.8348134, lng: 18.6498948 },
    { lat: 48.8348276, lng: 18.6499475 },
    { lat: 48.8348736, lng: 18.6499637 },
    { lat: 48.8349075, lng: 18.6500283 },
    { lat: 48.834955, lng: 18.6500311 },
    { lat: 48.8349933, lng: 18.6500711 },
    { lat: 48.8350226, lng: 18.6500725 },
    { lat: 48.8350427, lng: 18.6500897 },
    { lat: 48.8350381, lng: 18.6501723 },
    { lat: 48.8350324, lng: 18.6501927 },
    { lat: 48.8350388, lng: 18.650246 },
    { lat: 48.8350346, lng: 18.6503367 },
    { lat: 48.8350736, lng: 18.6505621 },
    { lat: 48.8350427, lng: 18.6506375 },
    { lat: 48.8350389, lng: 18.6506902 },
    { lat: 48.8350857, lng: 18.6507876 },
    { lat: 48.8350882, lng: 18.6510253 },
    { lat: 48.8350492, lng: 18.6510967 },
    { lat: 48.8350065, lng: 18.6511188 },
    { lat: 48.8350295, lng: 18.651276 },
    { lat: 48.8351252, lng: 18.6512922 },
    { lat: 48.8351712, lng: 18.6514817 },
    { lat: 48.8352195, lng: 18.6516476 },
    { lat: 48.8352516, lng: 18.6518226 },
    { lat: 48.8351988, lng: 18.6519311 },
    { lat: 48.8351987, lng: 18.6521431 },
    { lat: 48.8352199, lng: 18.6522422 },
    { lat: 48.8352922, lng: 18.6522695 },
    { lat: 48.8352734, lng: 18.6523154 },
    { lat: 48.8352903, lng: 18.6524863 },
    { lat: 48.8353073, lng: 18.6527599 },
    { lat: 48.8353607, lng: 18.6528521 },
    { lat: 48.8353308, lng: 18.6529245 },
    { lat: 48.8353337, lng: 18.6530459 },
    { lat: 48.8353166, lng: 18.6531679 },
    { lat: 48.8353382, lng: 18.6532988 },
    { lat: 48.8353206, lng: 18.6534334 },
    { lat: 48.8352884, lng: 18.6534861 },
    { lat: 48.8353126, lng: 18.6536854 },
    { lat: 48.8353175, lng: 18.6537947 },
    { lat: 48.8353883, lng: 18.6537935 },
    { lat: 48.8354138, lng: 18.6538684 },
    { lat: 48.8354494, lng: 18.6539249 },
    { lat: 48.8354195, lng: 18.654166 },
    { lat: 48.8354572, lng: 18.6545133 },
    { lat: 48.8355142, lng: 18.654672 },
    { lat: 48.8354894, lng: 18.6547753 },
    { lat: 48.835395, lng: 18.65491 },
    { lat: 48.8354286, lng: 18.6551146 },
    { lat: 48.8353851, lng: 18.6552521 },
    { lat: 48.8354077, lng: 18.6555071 },
    { lat: 48.8353807, lng: 18.6555712 },
    { lat: 48.8353441, lng: 18.6556043 },
    { lat: 48.8353442, lng: 18.6556892 },
    { lat: 48.8354023, lng: 18.6558091 },
    { lat: 48.8353822, lng: 18.655958 },
    { lat: 48.8354079, lng: 18.6560193 },
    { lat: 48.8354089, lng: 18.6562462 },
    { lat: 48.8353748, lng: 18.6562851 },
    { lat: 48.8353874, lng: 18.6563516 },
    { lat: 48.8354209, lng: 18.6563639 },
    { lat: 48.8354561, lng: 18.6563295 },
    { lat: 48.835538, lng: 18.6563854 },
    { lat: 48.835591, lng: 18.6564695 },
    { lat: 48.8356331, lng: 18.6566039 },
    { lat: 48.8356207, lng: 18.6566849 },
    { lat: 48.8357193, lng: 18.6568228 },
    { lat: 48.8356654, lng: 18.6568892 },
    { lat: 48.8356684, lng: 18.6569905 },
    { lat: 48.8357505, lng: 18.6570492 },
    { lat: 48.8357725, lng: 18.6571421 },
    { lat: 48.835779, lng: 18.6573475 },
    { lat: 48.8358454, lng: 18.6576 },
    { lat: 48.8359064, lng: 18.6576677 },
    { lat: 48.8358841, lng: 18.6577324 },
    { lat: 48.8359137, lng: 18.6578222 },
    { lat: 48.8358933, lng: 18.6579042 },
    { lat: 48.8359374, lng: 18.6579697 },
    { lat: 48.8360083, lng: 18.6580322 },
    { lat: 48.836075, lng: 18.658139 },
    { lat: 48.8362081, lng: 18.6582057 },
    { lat: 48.8361898, lng: 18.6582828 },
    { lat: 48.8364047, lng: 18.6589324 },
    { lat: 48.8364578, lng: 18.6590143 },
    { lat: 48.8365986, lng: 18.659097 },
    { lat: 48.8367132, lng: 18.6591105 },
    { lat: 48.8367751, lng: 18.6592339 },
    { lat: 48.8368481, lng: 18.6592515 },
    { lat: 48.8369983, lng: 18.6593999 },
    { lat: 48.8370079, lng: 18.6594553 },
    { lat: 48.8371063, lng: 18.6595228 },
    { lat: 48.8371496, lng: 18.659617 },
    { lat: 48.8373333, lng: 18.6597391 },
    { lat: 48.8375892, lng: 18.6597382 },
    { lat: 48.8378598, lng: 18.6599507 },
    { lat: 48.8380351, lng: 18.6600201 },
    { lat: 48.8381657, lng: 18.6601194 },
    { lat: 48.8381776, lng: 18.6602812 },
    { lat: 48.8382688, lng: 18.6605543 },
    { lat: 48.8383476, lng: 18.6605705 },
    { lat: 48.8385396, lng: 18.6608506 },
    { lat: 48.8387139, lng: 18.6608854 },
    { lat: 48.8389769, lng: 18.6610562 },
    { lat: 48.8390873, lng: 18.6610978 },
    { lat: 48.8391667, lng: 18.661106 },
    { lat: 48.8392901, lng: 18.6612223 },
    { lat: 48.8393171, lng: 18.6614313 },
    { lat: 48.8393785, lng: 18.6614206 },
    { lat: 48.8394074, lng: 18.6613923 },
    { lat: 48.83946, lng: 18.661426 },
    { lat: 48.8394835, lng: 18.6615284 },
    { lat: 48.839686, lng: 18.6616651 },
    { lat: 48.8397336, lng: 18.6617731 },
    { lat: 48.8398698, lng: 18.6618155 },
    { lat: 48.8399984, lng: 18.6618162 },
    { lat: 48.8401513, lng: 18.66193 },
    { lat: 48.8404448, lng: 18.6621078 },
    { lat: 48.8406723, lng: 18.6623087 },
    { lat: 48.8409032, lng: 18.6625583 },
    { lat: 48.8409631, lng: 18.6627325 },
    { lat: 48.8409495, lng: 18.6628759 },
    { lat: 48.8410416, lng: 18.663102 },
    { lat: 48.8410842, lng: 18.6632447 },
    { lat: 48.841118, lng: 18.6634579 },
    { lat: 48.8411528, lng: 18.6636001 },
    { lat: 48.8412478, lng: 18.6638176 },
    { lat: 48.8412755, lng: 18.6639148 },
    { lat: 48.8413277, lng: 18.6641945 },
    { lat: 48.8413408, lng: 18.6644629 },
    { lat: 48.8413152, lng: 18.6648903 },
    { lat: 48.8412771, lng: 18.6649823 },
    { lat: 48.8412812, lng: 18.6650588 },
    { lat: 48.8413441, lng: 18.6652416 },
    { lat: 48.8413321, lng: 18.6654199 },
    { lat: 48.8412946, lng: 18.6655631 },
    { lat: 48.841216, lng: 18.6656321 },
    { lat: 48.8411473, lng: 18.6657844 },
    { lat: 48.8411267, lng: 18.6661164 },
    { lat: 48.8411449, lng: 18.6664357 },
    { lat: 48.8411206, lng: 18.6664673 },
    { lat: 48.8410926, lng: 18.6666426 },
    { lat: 48.8411286, lng: 18.6668913 },
    { lat: 48.8410948, lng: 18.6670207 },
    { lat: 48.8411437, lng: 18.6671143 },
    { lat: 48.8411971, lng: 18.6673534 },
    { lat: 48.841203, lng: 18.6675693 },
    { lat: 48.8412315, lng: 18.6677452 },
    { lat: 48.8412518, lng: 18.6681487 },
    { lat: 48.8413091, lng: 18.6684618 },
    { lat: 48.841341, lng: 18.6687182 },
    { lat: 48.8414054, lng: 18.6689119 },
    { lat: 48.8414792, lng: 18.6690275 },
    { lat: 48.8416027, lng: 18.6692691 },
    { lat: 48.8416072, lng: 18.6693552 },
    { lat: 48.8417015, lng: 18.6696036 },
    { lat: 48.8417739, lng: 18.6697727 },
    { lat: 48.841865, lng: 18.6699176 },
    { lat: 48.842009, lng: 18.6700601 },
    { lat: 48.8423245, lng: 18.6703087 },
    { lat: 48.8426409, lng: 18.6707029 },
    { lat: 48.8427995, lng: 18.6710762 },
    { lat: 48.8432954, lng: 18.6721292 },
    { lat: 48.842715, lng: 18.6734927 },
    { lat: 48.8427218, lng: 18.6737901 },
    { lat: 48.8428371, lng: 18.6741119 },
    { lat: 48.8429, lng: 18.6742331 },
    { lat: 48.8433283, lng: 18.6747361 },
    { lat: 48.8436686, lng: 18.6749845 },
    { lat: 48.8438031, lng: 18.6750751 },
    { lat: 48.843887, lng: 18.6750832 },
    { lat: 48.8440923, lng: 18.6750413 },
    { lat: 48.8442055, lng: 18.6750503 },
    { lat: 48.8443251, lng: 18.6751124 },
    { lat: 48.8444338, lng: 18.6752276 },
    { lat: 48.8444514, lng: 18.6753286 },
    { lat: 48.8446611, lng: 18.6758361 },
    { lat: 48.8449051, lng: 18.6762027 },
    { lat: 48.8451103, lng: 18.6766478 },
    { lat: 48.8455455, lng: 18.677281 },
    { lat: 48.8457913, lng: 18.6780187 },
    { lat: 48.8457784, lng: 18.6780951 },
    { lat: 48.845931, lng: 18.6785248 },
    { lat: 48.8461118, lng: 18.6792765 },
    { lat: 48.8464449, lng: 18.6809148 },
    { lat: 48.8464575, lng: 18.6812578 },
    { lat: 48.8464411, lng: 18.6815674 },
    { lat: 48.8460568, lng: 18.6827357 },
    { lat: 48.8458897, lng: 18.6832037 },
    { lat: 48.8458012, lng: 18.6834057 },
    { lat: 48.8457036, lng: 18.6836668 },
    { lat: 48.8456828, lng: 18.6842108 },
    { lat: 48.8457538, lng: 18.6845892 },
    { lat: 48.8458766, lng: 18.6851006 },
    { lat: 48.8458993, lng: 18.6854201 },
    { lat: 48.845904, lng: 18.6856164 },
    { lat: 48.8459044, lng: 18.6860683 },
    { lat: 48.8459811, lng: 18.6867063 },
    { lat: 48.8460427, lng: 18.6868026 },
    { lat: 48.8463712, lng: 18.6873598 },
    { lat: 48.8464871, lng: 18.6875706 },
    { lat: 48.8466816, lng: 18.6879396 },
    { lat: 48.8468211, lng: 18.6882295 },
    { lat: 48.8470071, lng: 18.6886759 },
    { lat: 48.847045, lng: 18.6889617 },
    { lat: 48.8470166, lng: 18.689339 },
    { lat: 48.8469452, lng: 18.6898869 },
    { lat: 48.8468963, lng: 18.6903684 },
    { lat: 48.8467746, lng: 18.6907958 },
    { lat: 48.846662, lng: 18.6912427 },
    { lat: 48.8466629, lng: 18.6913049 },
    { lat: 48.8467032, lng: 18.6916416 },
    { lat: 48.8468352, lng: 18.6920835 },
    { lat: 48.8470787, lng: 18.6928481 },
    { lat: 48.8472897, lng: 18.6931901 },
    { lat: 48.8474461, lng: 18.6934203 },
    { lat: 48.8477036, lng: 18.6935969 },
    { lat: 48.847896, lng: 18.6940347 },
    { lat: 48.848124, lng: 18.6948667 },
    { lat: 48.8480733, lng: 18.6956517 },
    { lat: 48.8476748, lng: 18.6963899 },
    { lat: 48.8477003, lng: 18.6964652 },
    { lat: 48.8477883, lng: 18.6969572 },
    { lat: 48.8477647, lng: 18.697765 },
    { lat: 48.847707, lng: 18.698074 },
    { lat: 48.848013, lng: 18.698173 },
    { lat: 48.848444, lng: 18.698117 },
    { lat: 48.848992, lng: 18.697936 },
    { lat: 48.849172, lng: 18.697835 },
    { lat: 48.849442, lng: 18.697898 },
    { lat: 48.849628, lng: 18.697987 },
    { lat: 48.850023, lng: 18.698263 },
    { lat: 48.850454, lng: 18.698872 },
    { lat: 48.850781, lng: 18.699557 },
    { lat: 48.850887, lng: 18.699693 },
    { lat: 48.851387, lng: 18.699873 },
    { lat: 48.851495, lng: 18.700049 },
    { lat: 48.851811, lng: 18.700297 },
    { lat: 48.852622, lng: 18.700179 },
    { lat: 48.85283, lng: 18.700184 },
    { lat: 48.852984, lng: 18.700273 },
    { lat: 48.853212, lng: 18.70028 },
    { lat: 48.853252, lng: 18.700206 },
    { lat: 48.853282, lng: 18.70015 },
    { lat: 48.853343, lng: 18.700035 },
    { lat: 48.853895, lng: 18.699825 },
    { lat: 48.854495, lng: 18.699133 },
    { lat: 48.854686, lng: 18.698876 },
    { lat: 48.854952, lng: 18.698621 },
    { lat: 48.855078, lng: 18.698615 },
    { lat: 48.855682, lng: 18.698698 },
    { lat: 48.855859, lng: 18.698724 },
    { lat: 48.855953, lng: 18.698717 },
    { lat: 48.856017, lng: 18.698694 },
    { lat: 48.856253, lng: 18.698754 },
    { lat: 48.856314, lng: 18.698747 },
    { lat: 48.856411, lng: 18.698752 },
    { lat: 48.856965, lng: 18.698962 },
    { lat: 48.85759, lng: 18.699022 },
    { lat: 48.858122, lng: 18.698952 },
    { lat: 48.858786, lng: 18.699052 },
    { lat: 48.859308, lng: 18.698951 },
    { lat: 48.859368, lng: 18.69894 },
    { lat: 48.859464, lng: 18.698921 },
    { lat: 48.859826, lng: 18.699035 },
    { lat: 48.860095, lng: 18.699181 },
    { lat: 48.86023, lng: 18.699257 },
    { lat: 48.86047, lng: 18.699273 },
    { lat: 48.860576, lng: 18.699363 },
    { lat: 48.860695, lng: 18.699466 },
    { lat: 48.860838, lng: 18.699544 },
    { lat: 48.861132, lng: 18.699598 },
    { lat: 48.8617, lng: 18.699702 },
    { lat: 48.861957, lng: 18.69977 },
    { lat: 48.862109, lng: 18.69982 },
    { lat: 48.862436, lng: 18.699924 },
    { lat: 48.862768, lng: 18.699682 },
    { lat: 48.862955, lng: 18.699699 },
    { lat: 48.863067, lng: 18.699709 },
    { lat: 48.863457, lng: 18.699649 },
    { lat: 48.863552, lng: 18.699655 },
    { lat: 48.863824, lng: 18.699681 },
    { lat: 48.863976, lng: 18.69966 },
    { lat: 48.864049, lng: 18.699648 },
    { lat: 48.864124, lng: 18.699635 },
    { lat: 48.864402, lng: 18.699589 },
    { lat: 48.864531, lng: 18.699566 },
    { lat: 48.864801, lng: 18.699516 },
    { lat: 48.864985, lng: 18.699478 },
    { lat: 48.865527, lng: 18.699368 },
    { lat: 48.865922, lng: 18.699154 },
    { lat: 48.866157, lng: 18.699123 },
    { lat: 48.866308, lng: 18.699104 },
    { lat: 48.86678, lng: 18.699159 },
    { lat: 48.867092, lng: 18.699375 },
    { lat: 48.867398, lng: 18.699983 },
    { lat: 48.867567, lng: 18.700252 },
    { lat: 48.867766, lng: 18.700541 },
    { lat: 48.867882, lng: 18.700667 },
    { lat: 48.868012, lng: 18.700714 },
    { lat: 48.868153, lng: 18.700734 },
    { lat: 48.868356, lng: 18.700716 },
    { lat: 48.86852, lng: 18.700675 },
    { lat: 48.868669, lng: 18.70064 },
    { lat: 48.868751, lng: 18.700637 },
    { lat: 48.868991, lng: 18.700683 },
    { lat: 48.869085, lng: 18.700684 },
    { lat: 48.86936, lng: 18.700415 },
    { lat: 48.86945, lng: 18.700325 },
    { lat: 48.869652, lng: 18.700268 },
    { lat: 48.86978, lng: 18.700159 },
    { lat: 48.869956, lng: 18.700051 },
    { lat: 48.8702, lng: 18.699842 },
    { lat: 48.870722, lng: 18.699623 },
    { lat: 48.870907, lng: 18.699614 },
    { lat: 48.871067, lng: 18.699697 },
    { lat: 48.871093, lng: 18.699724 },
    { lat: 48.871182, lng: 18.699982 },
    { lat: 48.871192, lng: 18.69997 },
    { lat: 48.872036, lng: 18.699991 },
    { lat: 48.8719708, lng: 18.6994209 },
    { lat: 48.8719995, lng: 18.6993866 },
    { lat: 48.8719274, lng: 18.6991152 },
    { lat: 48.8718759, lng: 18.6989939 },
    { lat: 48.8717814, lng: 18.698956 },
    { lat: 48.8716689, lng: 18.6984055 },
    { lat: 48.8716346, lng: 18.6980973 },
    { lat: 48.8715354, lng: 18.6978224 },
    { lat: 48.8715091, lng: 18.6976465 },
    { lat: 48.8714958, lng: 18.6976277 },
    { lat: 48.8714443, lng: 18.6974903 },
    { lat: 48.8714224, lng: 18.6973532 },
    { lat: 48.8714051, lng: 18.6971518 },
    { lat: 48.8713475, lng: 18.6967694 },
    { lat: 48.8712252, lng: 18.6965662 },
    { lat: 48.8707414, lng: 18.6960125 },
    { lat: 48.8705055, lng: 18.6958603 },
    { lat: 48.8704271, lng: 18.6957227 },
    { lat: 48.8703046, lng: 18.69537 },
    { lat: 48.8702201, lng: 18.6952023 },
    { lat: 48.8701054, lng: 18.6948032 },
    { lat: 48.8700777, lng: 18.6944057 },
    { lat: 48.8701511, lng: 18.693834 },
    { lat: 48.8701637, lng: 18.6936268 },
    { lat: 48.8701438, lng: 18.6932101 },
    { lat: 48.8701671, lng: 18.6926692 },
    { lat: 48.8701738, lng: 18.6921579 },
    { lat: 48.8700846, lng: 18.6917507 },
    { lat: 48.8700812, lng: 18.6913481 },
    { lat: 48.8700671, lng: 18.6912517 },
    { lat: 48.8701136, lng: 18.6903873 },
    { lat: 48.8701738, lng: 18.6900113 },
    { lat: 48.870249, lng: 18.6892369 },
    { lat: 48.8702406, lng: 18.6890786 },
    { lat: 48.8701414, lng: 18.688382 },
    { lat: 48.8700965, lng: 18.6880429 },
    { lat: 48.8700278, lng: 18.6876669 },
    { lat: 48.8699985, lng: 18.6873677 },
    { lat: 48.869859, lng: 18.6868857 },
    { lat: 48.8698323, lng: 18.68653 },
    { lat: 48.869695, lng: 18.6862186 },
    { lat: 48.8692674, lng: 18.6856822 },
    { lat: 48.8691583, lng: 18.6855809 },
    { lat: 48.8688723, lng: 18.6853812 },
    { lat: 48.8688384, lng: 18.6854623 },
    { lat: 48.8687803, lng: 18.68541 },
    { lat: 48.8687591, lng: 18.6853721 },
    { lat: 48.8686928, lng: 18.6853329 },
    { lat: 48.8686376, lng: 18.6852683 },
    { lat: 48.8685009, lng: 18.6851815 },
    { lat: 48.8683617, lng: 18.6850226 },
    { lat: 48.8683845, lng: 18.6849843 },
    { lat: 48.8680155, lng: 18.6845554 },
    { lat: 48.8678029, lng: 18.6844402 },
    { lat: 48.867636, lng: 18.684294 },
    { lat: 48.867542, lng: 18.6842009 },
    { lat: 48.8673208, lng: 18.6839603 },
    { lat: 48.8672021, lng: 18.6838079 },
    { lat: 48.8670464, lng: 18.6835944 },
    { lat: 48.8669232, lng: 18.6834421 },
    { lat: 48.8666683, lng: 18.6831419 },
    { lat: 48.8665272, lng: 18.6829496 },
    { lat: 48.8662329, lng: 18.6825037 },
    { lat: 48.8661803, lng: 18.6823629 },
    { lat: 48.8661221, lng: 18.6819709 },
    { lat: 48.8660964, lng: 18.6816965 },
    { lat: 48.8660785, lng: 18.679981 },
    { lat: 48.866045, lng: 18.6799693 },
    { lat: 48.8660431, lng: 18.677999 },
    { lat: 48.8658574, lng: 18.6764187 },
    { lat: 48.8655653, lng: 18.6758222 },
    { lat: 48.8654843, lng: 18.6756995 },
    { lat: 48.86515, lng: 18.6753725 },
    { lat: 48.8650796, lng: 18.6752364 },
    { lat: 48.8649703, lng: 18.6749396 },
    { lat: 48.8649813, lng: 18.6747021 },
    { lat: 48.8650432, lng: 18.6746584 },
    { lat: 48.8650812, lng: 18.6744625 },
    { lat: 48.8650512, lng: 18.6744095 },
    { lat: 48.8650593, lng: 18.6743699 },
    { lat: 48.8650986, lng: 18.6741772 },
    { lat: 48.8651414, lng: 18.6739676 },
    { lat: 48.8651999, lng: 18.6736807 },
    { lat: 48.8652863, lng: 18.6735061 },
    { lat: 48.8653914, lng: 18.6733582 },
    { lat: 48.8654629, lng: 18.6731778 },
    { lat: 48.8654075, lng: 18.6731552 },
    { lat: 48.8654198, lng: 18.6730837 },
    { lat: 48.8654575, lng: 18.672864 },
    { lat: 48.8655069, lng: 18.6727752 },
    { lat: 48.8654442, lng: 18.6726589 },
    { lat: 48.865396, lng: 18.6725351 },
    { lat: 48.865459, lng: 18.6723634 },
    { lat: 48.8652908, lng: 18.6720845 },
    { lat: 48.8651676, lng: 18.6720387 },
    { lat: 48.8651387, lng: 18.6719122 },
    { lat: 48.8651325, lng: 18.6718853 },
    { lat: 48.8650791, lng: 18.6718381 },
    { lat: 48.8649934, lng: 18.6715271 },
    { lat: 48.8649242, lng: 18.6712757 },
    { lat: 48.8648797, lng: 18.6712298 },
    { lat: 48.8648167, lng: 18.6711649 },
    { lat: 48.8647419, lng: 18.6709677 },
    { lat: 48.8647061, lng: 18.6709172 },
    { lat: 48.8646639, lng: 18.6708576 },
    { lat: 48.8645088, lng: 18.6707401 },
    { lat: 48.864366, lng: 18.6706465 },
    { lat: 48.8641444, lng: 18.6706113 },
    { lat: 48.864118, lng: 18.6706883 },
    { lat: 48.86407, lng: 18.6707408 },
    { lat: 48.8640407, lng: 18.6706562 },
    { lat: 48.8639766, lng: 18.6706999 },
    { lat: 48.8639126, lng: 18.6705598 },
    { lat: 48.8638466, lng: 18.6705267 },
    { lat: 48.8638182, lng: 18.6704811 },
    { lat: 48.8638019, lng: 18.6704279 },
    { lat: 48.8637094, lng: 18.6704048 },
    { lat: 48.8636087, lng: 18.6701467 },
    { lat: 48.8635279, lng: 18.6700681 },
    { lat: 48.8634848, lng: 18.6699369 },
    { lat: 48.8633216, lng: 18.6697133 },
    { lat: 48.8632977, lng: 18.6695565 },
    { lat: 48.8632274, lng: 18.6695715 },
    { lat: 48.8631771, lng: 18.6694692 },
    { lat: 48.8631311, lng: 18.6693759 },
    { lat: 48.8630348, lng: 18.669364 },
    { lat: 48.8629829, lng: 18.6691967 },
    { lat: 48.8629167, lng: 18.6690769 },
    { lat: 48.8628558, lng: 18.6689049 },
    { lat: 48.8627458, lng: 18.6686805 },
    { lat: 48.8626867, lng: 18.6686716 },
    { lat: 48.8626427, lng: 18.6685022 },
    { lat: 48.8625232, lng: 18.6683789 },
    { lat: 48.8624752, lng: 18.6680918 },
    { lat: 48.8624126, lng: 18.668016 },
    { lat: 48.8623982, lng: 18.6679611 },
    { lat: 48.8623829, lng: 18.6679025 },
    { lat: 48.862326, lng: 18.6677884 },
    { lat: 48.8622523, lng: 18.6677394 },
    { lat: 48.8622594, lng: 18.6677037 },
    { lat: 48.8622728, lng: 18.6676369 },
    { lat: 48.8622477, lng: 18.6675698 },
    { lat: 48.8622542, lng: 18.6674773 },
    { lat: 48.8622199, lng: 18.6672157 },
    { lat: 48.8622363, lng: 18.6671046 },
    { lat: 48.8622676, lng: 18.6668929 },
    { lat: 48.8622904, lng: 18.6667382 },
    { lat: 48.8623271, lng: 18.6667238 },
    { lat: 48.8623677, lng: 18.666708 },
    { lat: 48.8623815, lng: 18.6666518 },
    { lat: 48.8624016, lng: 18.6666326 },
    { lat: 48.8624868, lng: 18.6665509 },
    { lat: 48.8624674, lng: 18.6664841 },
    { lat: 48.8625679, lng: 18.6663787 },
    { lat: 48.8626229, lng: 18.6662677 },
    { lat: 48.8626991, lng: 18.666176 },
    { lat: 48.862789, lng: 18.6661499 },
    { lat: 48.8628101, lng: 18.6661438 },
    { lat: 48.8629334, lng: 18.6658711 },
    { lat: 48.8629278, lng: 18.6657665 },
    { lat: 48.8629876, lng: 18.6656206 },
    { lat: 48.8630548, lng: 18.6656131 },
    { lat: 48.8631185, lng: 18.6655809 },
    { lat: 48.8633305, lng: 18.6653673 },
    { lat: 48.8634434, lng: 18.6652202 },
    { lat: 48.8634656, lng: 18.6652215 },
    { lat: 48.8635304, lng: 18.6652253 },
    { lat: 48.8635185, lng: 18.665086 },
    { lat: 48.8636455, lng: 18.6650163 },
    { lat: 48.8637039, lng: 18.6649064 },
    { lat: 48.8638283, lng: 18.6647833 },
    { lat: 48.8639397, lng: 18.6643338 },
    { lat: 48.8640211, lng: 18.6641884 },
    { lat: 48.8641255, lng: 18.6639263 },
    { lat: 48.8641034, lng: 18.6637453 },
    { lat: 48.8641283, lng: 18.6636634 },
    { lat: 48.8641306, lng: 18.6635557 },
    { lat: 48.864177, lng: 18.6634737 },
    { lat: 48.8642409, lng: 18.6632362 },
    { lat: 48.8642266, lng: 18.6630961 },
    { lat: 48.8640818, lng: 18.6628309 },
    { lat: 48.8640417, lng: 18.6627133 },
    { lat: 48.8639429, lng: 18.6625527 },
    { lat: 48.8638058, lng: 18.6622246 },
    { lat: 48.863564, lng: 18.6618777 },
    { lat: 48.8633865, lng: 18.6616788 },
    { lat: 48.8631194, lng: 18.6614282 },
    { lat: 48.8630908, lng: 18.6613131 },
    { lat: 48.8630101, lng: 18.6611783 },
    { lat: 48.8629006, lng: 18.6609195 },
    { lat: 48.8628, lng: 18.6608942 },
    { lat: 48.8626607, lng: 18.6611183 },
    { lat: 48.8625126, lng: 18.6611963 },
    { lat: 48.8622712, lng: 18.6610883 },
    { lat: 48.8621837, lng: 18.6609537 },
    { lat: 48.8621435, lng: 18.6608919 },
    { lat: 48.8620869, lng: 18.6606496 },
    { lat: 48.8620738, lng: 18.6605816 },
    { lat: 48.862096, lng: 18.6603304 },
    { lat: 48.8621006, lng: 18.6602778 },
    { lat: 48.8620673, lng: 18.6600792 },
    { lat: 48.8618172, lng: 18.6593612 },
    { lat: 48.8617729, lng: 18.6592341 },
    { lat: 48.8617648, lng: 18.6591842 },
    { lat: 48.8617673, lng: 18.6591297 },
    { lat: 48.8617788, lng: 18.658878 },
    { lat: 48.8617676, lng: 18.6588379 },
    { lat: 48.861754, lng: 18.6585601 },
    { lat: 48.8617219, lng: 18.6583796 },
    { lat: 48.8617081, lng: 18.6579959 },
    { lat: 48.8617581, lng: 18.6578357 },
    { lat: 48.8617689, lng: 18.6577624 },
    { lat: 48.8618251, lng: 18.6570184 },
    { lat: 48.8618301, lng: 18.6567749 },
    { lat: 48.8618492, lng: 18.6566228 },
    { lat: 48.8619231, lng: 18.6565905 },
    { lat: 48.8619567, lng: 18.6563747 },
    { lat: 48.8619831, lng: 18.6559402 },
    { lat: 48.8619878, lng: 18.6558955 },
    { lat: 48.8620103, lng: 18.6556802 },
    { lat: 48.8620144, lng: 18.6556412 },
    { lat: 48.8621346, lng: 18.6556128 },
    { lat: 48.8621599, lng: 18.6553243 },
    { lat: 48.8621288, lng: 18.6551397 },
    { lat: 48.8619923, lng: 18.6550364 },
    { lat: 48.8619894, lng: 18.655012 },
    { lat: 48.8619666, lng: 18.6548191 },
    { lat: 48.8619454, lng: 18.6546405 },
    { lat: 48.8619368, lng: 18.6545675 },
    { lat: 48.8619555, lng: 18.6544649 },
    { lat: 48.8620014, lng: 18.6542139 },
    { lat: 48.8619664, lng: 18.6540244 },
    { lat: 48.8619632, lng: 18.6539152 },
    { lat: 48.8619582, lng: 18.6537421 },
    { lat: 48.8618604, lng: 18.6536846 },
    { lat: 48.8618405, lng: 18.6536496 },
    { lat: 48.8617222, lng: 18.6534419 },
    { lat: 48.861704, lng: 18.6532249 },
    { lat: 48.8616433, lng: 18.6531471 },
    { lat: 48.8615473, lng: 18.6529737 },
    { lat: 48.8615649, lng: 18.6527538 },
    { lat: 48.8615596, lng: 18.6526369 },
    { lat: 48.8615264, lng: 18.6522674 },
    { lat: 48.8615139, lng: 18.6520547 },
    { lat: 48.8615, lng: 18.6519626 },
    { lat: 48.8614864, lng: 18.6519173 },
    { lat: 48.8613846, lng: 18.651807 },
    { lat: 48.8612537, lng: 18.6515501 },
    { lat: 48.861126, lng: 18.6513598 },
    { lat: 48.8611142, lng: 18.6513279 },
    { lat: 48.8610179, lng: 18.6509379 },
    { lat: 48.8609979, lng: 18.6506459 },
    { lat: 48.8610253, lng: 18.6504649 },
    { lat: 48.8609542, lng: 18.6503368 },
    { lat: 48.8608811, lng: 18.6501731 },
    { lat: 48.8607998, lng: 18.6501497 },
    { lat: 48.8607501, lng: 18.6500878 },
    { lat: 48.8607108, lng: 18.6498784 },
    { lat: 48.8606838, lng: 18.6496238 },
    { lat: 48.8606488, lng: 18.6495178 },
    { lat: 48.860644, lng: 18.6494066 },
    { lat: 48.8604419, lng: 18.6494046 },
    { lat: 48.8603834, lng: 18.6493409 },
    { lat: 48.8603589, lng: 18.6493686 },
    { lat: 48.8603132, lng: 18.6494028 },
    { lat: 48.860146, lng: 18.6494352 },
    { lat: 48.8600497, lng: 18.6494076 },
    { lat: 48.8599237, lng: 18.6492869 },
    { lat: 48.8599087, lng: 18.6492186 },
    { lat: 48.8598776, lng: 18.6491844 },
    { lat: 48.8598158, lng: 18.6492314 },
    { lat: 48.8597474, lng: 18.6493236 },
    { lat: 48.8594463, lng: 18.6493677 },
    { lat: 48.8593065, lng: 18.6493684 },
    { lat: 48.8592286, lng: 18.6492376 },
    { lat: 48.8591473, lng: 18.6489864 },
    { lat: 48.8590781, lng: 18.6489552 },
    { lat: 48.8590037, lng: 18.6489779 },
    { lat: 48.858799, lng: 18.6489246 },
    { lat: 48.8586553, lng: 18.649045 },
    { lat: 48.8585256, lng: 18.6490635 },
    { lat: 48.8584015, lng: 18.6490003 },
    { lat: 48.8583472, lng: 18.6488293 },
    { lat: 48.858339, lng: 18.648654 },
    { lat: 48.8582912, lng: 18.6485596 },
    { lat: 48.8579473, lng: 18.6484571 },
    { lat: 48.8578066, lng: 18.6483766 },
    { lat: 48.8575741, lng: 18.6483951 },
    { lat: 48.8571686, lng: 18.6483196 },
    { lat: 48.8571738, lng: 18.6483121 },
    { lat: 48.8568414, lng: 18.6482129 },
    { lat: 48.8566847, lng: 18.6481103 },
    { lat: 48.856505, lng: 18.6479544 },
    { lat: 48.8563047, lng: 18.6477308 },
    { lat: 48.8560749, lng: 18.647442 },
    { lat: 48.8557235, lng: 18.6471732 },
    { lat: 48.8554286, lng: 18.6469125 },
    { lat: 48.8552592, lng: 18.6466743 },
    { lat: 48.854872, lng: 18.6460145 },
    { lat: 48.8545678, lng: 18.6456196 },
    { lat: 48.8545269, lng: 18.6456153 },
    { lat: 48.8544536, lng: 18.6455312 },
    { lat: 48.8542881, lng: 18.645428 },
    { lat: 48.8540226, lng: 18.6453152 },
    { lat: 48.8536275, lng: 18.6452011 },
    { lat: 48.8534093, lng: 18.6451301 },
    { lat: 48.853158, lng: 18.6450091 },
    { lat: 48.8529445, lng: 18.6448298 },
    { lat: 48.8527985, lng: 18.644611 },
    { lat: 48.8526553, lng: 18.6442754 },
    { lat: 48.8527097, lng: 18.6441739 },
    { lat: 48.852809, lng: 18.6441126 },
    { lat: 48.8529023, lng: 18.6441072 },
    { lat: 48.8528815, lng: 18.6439257 },
    { lat: 48.8527376, lng: 18.6438119 },
  ],
  Radobica: [
    { lat: 48.568577, lng: 18.497889 },
    { lat: 48.568434, lng: 18.497909 },
    { lat: 48.568355, lng: 18.497945 },
    { lat: 48.568039, lng: 18.497957 },
    { lat: 48.567742, lng: 18.497936 },
    { lat: 48.567548, lng: 18.498167 },
    { lat: 48.567385, lng: 18.498384 },
    { lat: 48.566964, lng: 18.499068 },
    { lat: 48.566842, lng: 18.499151 },
    { lat: 48.566757, lng: 18.499034 },
    { lat: 48.566389, lng: 18.499434 },
    { lat: 48.566114, lng: 18.499842 },
    { lat: 48.565848, lng: 18.499756 },
    { lat: 48.565531, lng: 18.499653 },
    { lat: 48.565282, lng: 18.500037 },
    { lat: 48.565014, lng: 18.500341 },
    { lat: 48.56481, lng: 18.500603 },
    { lat: 48.564577, lng: 18.500322 },
    { lat: 48.56388, lng: 18.500082 },
    { lat: 48.563719, lng: 18.500891 },
    { lat: 48.563708, lng: 18.500943 },
    { lat: 48.563644, lng: 18.501267 },
    { lat: 48.5633, lng: 18.502673 },
    { lat: 48.563123, lng: 18.503397 },
    { lat: 48.56293, lng: 18.50435 },
    { lat: 48.562765, lng: 18.504736 },
    { lat: 48.562697, lng: 18.504894 },
    { lat: 48.56248, lng: 18.505376 },
    { lat: 48.562396, lng: 18.50577 },
    { lat: 48.56237, lng: 18.506001 },
    { lat: 48.562274, lng: 18.506122 },
    { lat: 48.562643, lng: 18.507191 },
    { lat: 48.562761, lng: 18.507628 },
    { lat: 48.562816, lng: 18.507835 },
    { lat: 48.562877, lng: 18.50849 },
    { lat: 48.562924, lng: 18.508797 },
    { lat: 48.562948, lng: 18.508945 },
    { lat: 48.563026, lng: 18.509242 },
    { lat: 48.563061, lng: 18.509833 },
    { lat: 48.563043, lng: 18.510197 },
    { lat: 48.562853, lng: 18.510597 },
    { lat: 48.56252, lng: 18.510946 },
    { lat: 48.562534, lng: 18.510981 },
    { lat: 48.562403, lng: 18.511134 },
    { lat: 48.562332, lng: 18.511911 },
    { lat: 48.562352, lng: 18.511928 },
    { lat: 48.562344, lng: 18.511952 },
    { lat: 48.562429, lng: 18.512041 },
    { lat: 48.562434, lng: 18.512191 },
    { lat: 48.56245, lng: 18.512266 },
    { lat: 48.562462, lng: 18.512325 },
    { lat: 48.562615, lng: 18.512483 },
    { lat: 48.562654, lng: 18.512467 },
    { lat: 48.562663, lng: 18.512518 },
    { lat: 48.562696, lng: 18.512562 },
    { lat: 48.562793, lng: 18.512647 },
    { lat: 48.562834, lng: 18.512714 },
    { lat: 48.562849, lng: 18.512916 },
    { lat: 48.562911, lng: 18.51298 },
    { lat: 48.562945, lng: 18.513005 },
    { lat: 48.563017, lng: 18.513048 },
    { lat: 48.56301, lng: 18.513064 },
    { lat: 48.562987, lng: 18.513114 },
    { lat: 48.563494, lng: 18.513743 },
    { lat: 48.563792, lng: 18.514491 },
    { lat: 48.564315, lng: 18.515216 },
    { lat: 48.564464, lng: 18.515341 },
    { lat: 48.564904, lng: 18.515708 },
    { lat: 48.565304, lng: 18.516017 },
    { lat: 48.565612, lng: 18.516296 },
    { lat: 48.565457, lng: 18.516662 },
    { lat: 48.565377, lng: 18.516935 },
    { lat: 48.565353, lng: 18.517011 },
    { lat: 48.565343, lng: 18.517159 },
    { lat: 48.565318, lng: 18.517468 },
    { lat: 48.565246, lng: 18.517447 },
    { lat: 48.565216, lng: 18.517439 },
    { lat: 48.565161, lng: 18.517423 },
    { lat: 48.565, lng: 18.517425 },
    { lat: 48.564109, lng: 18.517266 },
    { lat: 48.563963, lng: 18.517297 },
    { lat: 48.563698, lng: 18.517416 },
    { lat: 48.563475, lng: 18.517468 },
    { lat: 48.563349, lng: 18.517652 },
    { lat: 48.563323, lng: 18.518489 },
    { lat: 48.563318, lng: 18.518807 },
    { lat: 48.563317, lng: 18.518856 },
    { lat: 48.563316, lng: 18.518882 },
    { lat: 48.563339, lng: 18.51913 },
    { lat: 48.563343, lng: 18.519196 },
    { lat: 48.563311, lng: 18.519647 },
    { lat: 48.563242, lng: 18.520046 },
    { lat: 48.563221, lng: 18.520523 },
    { lat: 48.563215, lng: 18.521212 },
    { lat: 48.563198, lng: 18.521372 },
    { lat: 48.563188, lng: 18.521462 },
    { lat: 48.563177, lng: 18.521556 },
    { lat: 48.563143, lng: 18.521873 },
    { lat: 48.563063, lng: 18.522607 },
    { lat: 48.563064, lng: 18.522696 },
    { lat: 48.563066, lng: 18.522748 },
    { lat: 48.563076, lng: 18.52318 },
    { lat: 48.563001, lng: 18.523816 },
    { lat: 48.56295, lng: 18.524163 },
    { lat: 48.562875, lng: 18.524622 },
    { lat: 48.563479, lng: 18.524914 },
    { lat: 48.563481, lng: 18.524928 },
    { lat: 48.563489, lng: 18.525029 },
    { lat: 48.563494, lng: 18.525107 },
    { lat: 48.56351, lng: 18.525295 },
    { lat: 48.563508, lng: 18.525315 },
    { lat: 48.563471, lng: 18.525486 },
    { lat: 48.563448, lng: 18.525598 },
    { lat: 48.563376, lng: 18.525754 },
    { lat: 48.563142, lng: 18.526159 },
    { lat: 48.563, lng: 18.526492 },
    { lat: 48.562837, lng: 18.526849 },
    { lat: 48.562583, lng: 18.527493 },
    { lat: 48.562505, lng: 18.528044 },
    { lat: 48.56246, lng: 18.528369 },
    { lat: 48.562383, lng: 18.528753 },
    { lat: 48.562164, lng: 18.5287 },
    { lat: 48.56189, lng: 18.529135 },
    { lat: 48.561649, lng: 18.529636 },
    { lat: 48.561568, lng: 18.529847 },
    { lat: 48.561325, lng: 18.530773 },
    { lat: 48.561416, lng: 18.53093 },
    { lat: 48.561514, lng: 18.530943 },
    { lat: 48.561813, lng: 18.531193 },
    { lat: 48.561845, lng: 18.53124 },
    { lat: 48.561869, lng: 18.531274 },
    { lat: 48.562675, lng: 18.531997 },
    { lat: 48.56306, lng: 18.532321 },
    { lat: 48.563284, lng: 18.532535 },
    { lat: 48.563306, lng: 18.532556 },
    { lat: 48.563388, lng: 18.532644 },
    { lat: 48.563705, lng: 18.53314 },
    { lat: 48.563969, lng: 18.533509 },
    { lat: 48.56416, lng: 18.533773 },
    { lat: 48.564388, lng: 18.534046 },
    { lat: 48.56456, lng: 18.53423 },
    { lat: 48.564577, lng: 18.534248 },
    { lat: 48.564583, lng: 18.534254 },
    { lat: 48.564758, lng: 18.534441 },
    { lat: 48.56475, lng: 18.53523 },
    { lat: 48.564641, lng: 18.535188 },
    { lat: 48.564512, lng: 18.535139 },
    { lat: 48.564439, lng: 18.53493 },
    { lat: 48.564145, lng: 18.535006 },
    { lat: 48.564131, lng: 18.535004 },
    { lat: 48.564119, lng: 18.535002 },
    { lat: 48.564129, lng: 18.535161 },
    { lat: 48.564071, lng: 18.535229 },
    { lat: 48.564026, lng: 18.535311 },
    { lat: 48.56398, lng: 18.53532 },
    { lat: 48.563906, lng: 18.535341 },
    { lat: 48.563862, lng: 18.535432 },
    { lat: 48.563849, lng: 18.535533 },
    { lat: 48.563876, lng: 18.5357 },
    { lat: 48.563862, lng: 18.535741 },
    { lat: 48.563801, lng: 18.53574 },
    { lat: 48.563766, lng: 18.535784 },
    { lat: 48.563666, lng: 18.535867 },
    { lat: 48.563635, lng: 18.536002 },
    { lat: 48.563543, lng: 18.536063 },
    { lat: 48.563507, lng: 18.536117 },
    { lat: 48.563455, lng: 18.536149 },
    { lat: 48.563394, lng: 18.536149 },
    { lat: 48.563282, lng: 18.536131 },
    { lat: 48.56323, lng: 18.536162 },
    { lat: 48.56324, lng: 18.536179 },
    { lat: 48.563255, lng: 18.536207 },
    { lat: 48.563151, lng: 18.536712 },
    { lat: 48.563323, lng: 18.536972 },
    { lat: 48.563446, lng: 18.537158 },
    { lat: 48.563651, lng: 18.537568 },
    { lat: 48.563752, lng: 18.538129 },
    { lat: 48.563761, lng: 18.538918 },
    { lat: 48.564081, lng: 18.539465 },
    { lat: 48.564098, lng: 18.539498 },
    { lat: 48.564121, lng: 18.539536 },
    { lat: 48.564213, lng: 18.539684 },
    { lat: 48.564415, lng: 18.540027 },
    { lat: 48.564874, lng: 18.540071 },
    { lat: 48.564912, lng: 18.540836 },
    { lat: 48.564981, lng: 18.542358 },
    { lat: 48.564993, lng: 18.542912 },
    { lat: 48.565056, lng: 18.544126 },
    { lat: 48.56421, lng: 18.544102 },
    { lat: 48.563796, lng: 18.544118 },
    { lat: 48.563604, lng: 18.544161 },
    { lat: 48.562937, lng: 18.544308 },
    { lat: 48.563237, lng: 18.544827 },
    { lat: 48.563748, lng: 18.545708 },
    { lat: 48.563748, lng: 18.546599 },
    { lat: 48.563561, lng: 18.547446 },
    { lat: 48.56373, lng: 18.547714 },
    { lat: 48.564355, lng: 18.548799 },
    { lat: 48.56456, lng: 18.549104 },
    { lat: 48.564829, lng: 18.549414 },
    { lat: 48.565361, lng: 18.549973 },
    { lat: 48.565614, lng: 18.550347 },
    { lat: 48.565893, lng: 18.551402 },
    { lat: 48.566132, lng: 18.552061 },
    { lat: 48.566232, lng: 18.55227 },
    { lat: 48.566454, lng: 18.552739 },
    { lat: 48.566615, lng: 18.553065 },
    { lat: 48.566685, lng: 18.5532 },
    { lat: 48.566926, lng: 18.553475 },
    { lat: 48.566948, lng: 18.553472 },
    { lat: 48.5669368, lng: 18.5533462 },
    { lat: 48.5669055, lng: 18.5529925 },
    { lat: 48.5670907, lng: 18.5526009 },
    { lat: 48.5672484, lng: 18.552185 },
    { lat: 48.5673381, lng: 18.5518249 },
    { lat: 48.5674383, lng: 18.5512213 },
    { lat: 48.5675399, lng: 18.5508326 },
    { lat: 48.5675223, lng: 18.5505752 },
    { lat: 48.5675118, lng: 18.5504219 },
    { lat: 48.5676784, lng: 18.5499998 },
    { lat: 48.5680145, lng: 18.5495838 },
    { lat: 48.5680958, lng: 18.5492561 },
    { lat: 48.5682005, lng: 18.5489432 },
    { lat: 48.5683541, lng: 18.5487386 },
    { lat: 48.5684123, lng: 18.5483806 },
    { lat: 48.5685765, lng: 18.5480814 },
    { lat: 48.5686839, lng: 18.5475288 },
    { lat: 48.5691124, lng: 18.546456 },
    { lat: 48.5694216, lng: 18.5461452 },
    { lat: 48.5696049, lng: 18.5456195 },
    { lat: 48.5701415, lng: 18.5444392 },
    { lat: 48.5703014, lng: 18.5440607 },
    { lat: 48.5705742, lng: 18.5432946 },
    { lat: 48.5707933, lng: 18.5426591 },
    { lat: 48.5710778, lng: 18.5421091 },
    { lat: 48.571575, lng: 18.5411558 },
    { lat: 48.5721018, lng: 18.5401419 },
    { lat: 48.5723807, lng: 18.5396111 },
    { lat: 48.572642, lng: 18.539215 },
    { lat: 48.5728538, lng: 18.5388574 },
    { lat: 48.5731562, lng: 18.5384701 },
    { lat: 48.5738783, lng: 18.5380797 },
    { lat: 48.5744376, lng: 18.5375191 },
    { lat: 48.574737, lng: 18.5373618 },
    { lat: 48.5750615, lng: 18.5371502 },
    { lat: 48.5755693, lng: 18.536266 },
    { lat: 48.575922, lng: 18.5359108 },
    { lat: 48.5763378, lng: 18.5354285 },
    { lat: 48.5770011, lng: 18.5346646 },
    { lat: 48.5773681, lng: 18.5343857 },
    { lat: 48.5776722, lng: 18.5342713 },
    { lat: 48.578383, lng: 18.5340486 },
    { lat: 48.5788152, lng: 18.5337651 },
    { lat: 48.5791325, lng: 18.5333783 },
    { lat: 48.579609, lng: 18.5328292 },
    { lat: 48.5798965, lng: 18.5318804 },
    { lat: 48.5798815, lng: 18.531442 },
    { lat: 48.5800245, lng: 18.5308214 },
    { lat: 48.5802564, lng: 18.5301916 },
    { lat: 48.5804905, lng: 18.5297688 },
    { lat: 48.5808045, lng: 18.5292449 },
    { lat: 48.581257, lng: 18.5288965 },
    { lat: 48.5817188, lng: 18.5284507 },
    { lat: 48.5819662, lng: 18.5283148 },
    { lat: 48.582128, lng: 18.5282192 },
    { lat: 48.5827403, lng: 18.5277625 },
    { lat: 48.5836192, lng: 18.5273143 },
    { lat: 48.5838627, lng: 18.527187 },
    { lat: 48.5842518, lng: 18.5271874 },
    { lat: 48.584463, lng: 18.5271494 },
    { lat: 48.5851608, lng: 18.5269781 },
    { lat: 48.5855865, lng: 18.5268004 },
    { lat: 48.5860808, lng: 18.5266159 },
    { lat: 48.5866218, lng: 18.5261958 },
    { lat: 48.5868066, lng: 18.5259876 },
    { lat: 48.5872924, lng: 18.5247246 },
    { lat: 48.5874436, lng: 18.5241724 },
    { lat: 48.5877241, lng: 18.5234027 },
    { lat: 48.5881639, lng: 18.5235222 },
    { lat: 48.5884071, lng: 18.5235774 },
    { lat: 48.5886527, lng: 18.5236551 },
    { lat: 48.5892762, lng: 18.5235686 },
    { lat: 48.5897679, lng: 18.5234396 },
    { lat: 48.5899411, lng: 18.5234298 },
    { lat: 48.5902215, lng: 18.5234006 },
    { lat: 48.590582, lng: 18.5233311 },
    { lat: 48.5912074, lng: 18.5228053 },
    { lat: 48.5914195, lng: 18.522621 },
    { lat: 48.5916309, lng: 18.5224457 },
    { lat: 48.5918894, lng: 18.5222196 },
    { lat: 48.5919877, lng: 18.5221408 },
    { lat: 48.5921222, lng: 18.521758 },
    { lat: 48.5919242, lng: 18.5214777 },
    { lat: 48.5918531, lng: 18.521457 },
    { lat: 48.5916498, lng: 18.520898 },
    { lat: 48.5915152, lng: 18.5204558 },
    { lat: 48.5918147, lng: 18.5197279 },
    { lat: 48.5923542, lng: 18.5184573 },
    { lat: 48.5928901, lng: 18.5171836 },
    { lat: 48.5933433, lng: 18.5164179 },
    { lat: 48.5938465, lng: 18.5155976 },
    { lat: 48.5944331, lng: 18.5148816 },
    { lat: 48.5952838, lng: 18.5138774 },
    { lat: 48.5960203, lng: 18.5132991 },
    { lat: 48.5962299, lng: 18.5131083 },
    { lat: 48.5965172, lng: 18.5127749 },
    { lat: 48.5964216, lng: 18.5118557 },
    { lat: 48.596404, lng: 18.5117427 },
    { lat: 48.5963525, lng: 18.5111323 },
    { lat: 48.5960373, lng: 18.5109578 },
    { lat: 48.5957737, lng: 18.5100628 },
    { lat: 48.5954566, lng: 18.509091 },
    { lat: 48.5949645, lng: 18.5076127 },
    { lat: 48.5949184, lng: 18.5074706 },
    { lat: 48.594718, lng: 18.5068823 },
    { lat: 48.5948882, lng: 18.506654 },
    { lat: 48.5953304, lng: 18.5060774 },
    { lat: 48.5956823, lng: 18.5055855 },
    { lat: 48.5959789, lng: 18.5051932 },
    { lat: 48.5960884, lng: 18.5042522 },
    { lat: 48.5961815, lng: 18.5035246 },
    { lat: 48.5963665, lng: 18.5020283 },
    { lat: 48.5964348, lng: 18.5016278 },
    { lat: 48.5965443, lng: 18.5004591 },
    { lat: 48.5965059, lng: 18.5003766 },
    { lat: 48.5968974, lng: 18.4999089 },
    { lat: 48.5971031, lng: 18.4996904 },
    { lat: 48.5972701, lng: 18.4991563 },
    { lat: 48.5973907, lng: 18.4980863 },
    { lat: 48.5971222, lng: 18.4975333 },
    { lat: 48.5970468, lng: 18.4974134 },
    { lat: 48.5969233, lng: 18.4971791 },
    { lat: 48.596825, lng: 18.4968282 },
    { lat: 48.5965439, lng: 18.4966214 },
    { lat: 48.5964515, lng: 18.49654 },
    { lat: 48.5964265, lng: 18.4960648 },
    { lat: 48.5965084, lng: 18.49571 },
    { lat: 48.596635, lng: 18.4948934 },
    { lat: 48.5964369, lng: 18.4946342 },
    { lat: 48.5964326, lng: 18.494536 },
    { lat: 48.5964118, lng: 18.4943629 },
    { lat: 48.5964588, lng: 18.4941097 },
    { lat: 48.5967039, lng: 18.4933787 },
    { lat: 48.596586, lng: 18.4933497 },
    { lat: 48.596609, lng: 18.4924758 },
    { lat: 48.5963567, lng: 18.4923588 },
    { lat: 48.5964496, lng: 18.4910379 },
    { lat: 48.596639, lng: 18.4903987 },
    { lat: 48.5968721, lng: 18.4899415 },
    { lat: 48.5972051, lng: 18.4895787 },
    { lat: 48.5970671, lng: 18.4891601 },
    { lat: 48.5968948, lng: 18.4891268 },
    { lat: 48.5966881, lng: 18.4893263 },
    { lat: 48.5965561, lng: 18.4893892 },
    { lat: 48.5962672, lng: 18.4891171 },
    { lat: 48.5962346, lng: 18.4891397 },
    { lat: 48.595865, lng: 18.4894551 },
    { lat: 48.5953422, lng: 18.4892846 },
    { lat: 48.5950557, lng: 18.4891391 },
    { lat: 48.5947065, lng: 18.4890051 },
    { lat: 48.5946335, lng: 18.4890089 },
    { lat: 48.5944072, lng: 18.4894067 },
    { lat: 48.5939381, lng: 18.4890012 },
    { lat: 48.5932771, lng: 18.4882883 },
    { lat: 48.5928987, lng: 18.4880103 },
    { lat: 48.5920107, lng: 18.4879056 },
    { lat: 48.591991, lng: 18.4877107 },
    { lat: 48.5912618, lng: 18.4871728 },
    { lat: 48.5911041, lng: 18.4871159 },
    { lat: 48.5906061, lng: 18.486735 },
    { lat: 48.5903794, lng: 18.4863487 },
    { lat: 48.5901251, lng: 18.4861739 },
    { lat: 48.5899934, lng: 18.4860575 },
    { lat: 48.5895649, lng: 18.4862655 },
    { lat: 48.5894505, lng: 18.4862363 },
    { lat: 48.5893579, lng: 18.4858463 },
    { lat: 48.5893891, lng: 18.4855342 },
    { lat: 48.5895266, lng: 18.4852451 },
    { lat: 48.5889116, lng: 18.484544 },
    { lat: 48.5883728, lng: 18.4840624 },
    { lat: 48.5881181, lng: 18.4834516 },
    { lat: 48.5880306, lng: 18.4833614 },
    { lat: 48.5875103, lng: 18.4828636 },
    { lat: 48.5871234, lng: 18.4825391 },
    { lat: 48.5866568, lng: 18.482159 },
    { lat: 48.5861153, lng: 18.4816244 },
    { lat: 48.585627, lng: 18.4811804 },
    { lat: 48.5850342, lng: 18.4806593 },
    { lat: 48.584308, lng: 18.479933 },
    { lat: 48.583886, lng: 18.480701 },
    { lat: 48.583436, lng: 18.4815 },
    { lat: 48.582865, lng: 18.482516 },
    { lat: 48.582272, lng: 18.483522 },
    { lat: 48.581741, lng: 18.484422 },
    { lat: 48.581448, lng: 18.484854 },
    { lat: 48.581349, lng: 18.485102 },
    { lat: 48.580986, lng: 18.486022 },
    { lat: 48.580792, lng: 18.48656 },
    { lat: 48.580468, lng: 18.487461 },
    { lat: 48.579996, lng: 18.489047 },
    { lat: 48.579303, lng: 18.489889 },
    { lat: 48.578387, lng: 18.490996 },
    { lat: 48.578204, lng: 18.491097 },
    { lat: 48.577021, lng: 18.491752 },
    { lat: 48.57563, lng: 18.492377 },
    { lat: 48.574229, lng: 18.493546 },
    { lat: 48.573269, lng: 18.494361 },
    { lat: 48.572871, lng: 18.494565 },
    { lat: 48.572352, lng: 18.494744 },
    { lat: 48.571681, lng: 18.494938 },
    { lat: 48.5708307, lng: 18.4949499 },
    { lat: 48.570535, lng: 18.494954 },
    { lat: 48.570127, lng: 18.495841 },
    { lat: 48.569883, lng: 18.496407 },
    { lat: 48.569535, lng: 18.497045 },
    { lat: 48.569474, lng: 18.49716 },
    { lat: 48.569057, lng: 18.497973 },
    { lat: 48.568577, lng: 18.497889 },
  ],
  RudnianskaLehota: [
    { lat: 48.807528, lng: 18.408631 },
    { lat: 48.807311, lng: 18.408696 },
    { lat: 48.806933, lng: 18.409452 },
    { lat: 48.806779, lng: 18.409749 },
    { lat: 48.806431, lng: 18.409992 },
    { lat: 48.805916, lng: 18.410611 },
    { lat: 48.805304, lng: 18.411007 },
    { lat: 48.805007, lng: 18.411449 },
    { lat: 48.804687, lng: 18.411738 },
    { lat: 48.804526, lng: 18.411813 },
    { lat: 48.804248, lng: 18.411827 },
    { lat: 48.803977, lng: 18.412218 },
    { lat: 48.80369, lng: 18.412807 },
    { lat: 48.803348, lng: 18.413211 },
    { lat: 48.803041, lng: 18.413703 },
    { lat: 48.802804, lng: 18.414433 },
    { lat: 48.802428, lng: 18.415752 },
    { lat: 48.802311, lng: 18.416504 },
    { lat: 48.8021791, lng: 18.4169642 },
    { lat: 48.802077, lng: 18.4174 },
    { lat: 48.801887, lng: 18.418956 },
    { lat: 48.801796, lng: 18.419675 },
    { lat: 48.801751, lng: 18.420039 },
    { lat: 48.801738, lng: 18.420624 },
    { lat: 48.801419, lng: 18.422308 },
    { lat: 48.8013877, lng: 18.4223607 },
    { lat: 48.8017781, lng: 18.4238463 },
    { lat: 48.8019682, lng: 18.4251175 },
    { lat: 48.8017163, lng: 18.4272688 },
    { lat: 48.8021826, lng: 18.429109 },
    { lat: 48.8038898, lng: 18.4314982 },
    { lat: 48.8042766, lng: 18.4331429 },
    { lat: 48.8046034, lng: 18.4337885 },
    { lat: 48.8057594, lng: 18.43727 },
    { lat: 48.8057869, lng: 18.4387456 },
    { lat: 48.8061037, lng: 18.4394931 },
    { lat: 48.8062677, lng: 18.4398904 },
    { lat: 48.8066814, lng: 18.4418012 },
    { lat: 48.8066763, lng: 18.4454258 },
    { lat: 48.8061437, lng: 18.446627 },
    { lat: 48.8063604, lng: 18.4478328 },
    { lat: 48.8062071, lng: 18.4499397 },
    { lat: 48.8061609, lng: 18.4505332 },
    { lat: 48.8060986, lng: 18.4509752 },
    { lat: 48.8060364, lng: 18.4511771 },
    { lat: 48.8059997, lng: 18.4513721 },
    { lat: 48.805934, lng: 18.4516127 },
    { lat: 48.8059071, lng: 18.4517796 },
    { lat: 48.805821, lng: 18.4522414 },
    { lat: 48.8057131, lng: 18.4527185 },
    { lat: 48.8056223, lng: 18.4530578 },
    { lat: 48.8056081, lng: 18.4532074 },
    { lat: 48.8055791, lng: 18.4532931 },
    { lat: 48.8055839, lng: 18.4534241 },
    { lat: 48.8055952, lng: 18.4534871 },
    { lat: 48.8055886, lng: 18.4537141 },
    { lat: 48.8055391, lng: 18.4540003 },
    { lat: 48.8055476, lng: 18.454239 },
    { lat: 48.8054947, lng: 18.454402 },
    { lat: 48.8055109, lng: 18.4551884 },
    { lat: 48.8054854, lng: 18.4554213 },
    { lat: 48.8055582, lng: 18.4557385 },
    { lat: 48.8055825, lng: 18.4558904 },
    { lat: 48.8057041, lng: 18.4563768 },
    { lat: 48.8057421, lng: 18.456762 },
    { lat: 48.8057386, lng: 18.4570448 },
    { lat: 48.8056613, lng: 18.4574429 },
    { lat: 48.8056019, lng: 18.4575921 },
    { lat: 48.8055155, lng: 18.4576839 },
    { lat: 48.8053014, lng: 18.4578921 },
    { lat: 48.8052491, lng: 18.4580272 },
    { lat: 48.8051866, lng: 18.4581034 },
    { lat: 48.8050966, lng: 18.4582665 },
    { lat: 48.8050308, lng: 18.4583432 },
    { lat: 48.8049757, lng: 18.4584711 },
    { lat: 48.8049312, lng: 18.4586671 },
    { lat: 48.804866, lng: 18.4587312 },
    { lat: 48.8046926, lng: 18.4590423 },
    { lat: 48.8045547, lng: 18.4591617 },
    { lat: 48.8045113, lng: 18.4592531 },
    { lat: 48.8042836, lng: 18.4594407 },
    { lat: 48.8042945, lng: 18.4595342 },
    { lat: 48.8041709, lng: 18.4597073 },
    { lat: 48.8039684, lng: 18.4601498 },
    { lat: 48.8038987, lng: 18.4603171 },
    { lat: 48.8037334, lng: 18.4605531 },
    { lat: 48.8036837, lng: 18.4606542 },
    { lat: 48.8036334, lng: 18.4607833 },
    { lat: 48.8035781, lng: 18.4608661 },
    { lat: 48.8035804, lng: 18.4609074 },
    { lat: 48.8035586, lng: 18.4609402 },
    { lat: 48.8035835, lng: 18.4609853 },
    { lat: 48.8035353, lng: 18.4611099 },
    { lat: 48.803393, lng: 18.4611704 },
    { lat: 48.8033216, lng: 18.461285 },
    { lat: 48.8032605, lng: 18.4614677 },
    { lat: 48.8032248, lng: 18.4615152 },
    { lat: 48.8031968, lng: 18.4615817 },
    { lat: 48.8031626, lng: 18.4616351 },
    { lat: 48.8031118, lng: 18.4618623 },
    { lat: 48.803004, lng: 18.4620727 },
    { lat: 48.802953, lng: 18.4621124 },
    { lat: 48.8029146, lng: 18.4622719 },
    { lat: 48.8029415, lng: 18.4623705 },
    { lat: 48.8028932, lng: 18.4625186 },
    { lat: 48.8027505, lng: 18.4626526 },
    { lat: 48.8026614, lng: 18.4627955 },
    { lat: 48.8025683, lng: 18.4628044 },
    { lat: 48.8024694, lng: 18.4628924 },
    { lat: 48.8024269, lng: 18.4630059 },
    { lat: 48.8023268, lng: 18.4631101 },
    { lat: 48.8022359, lng: 18.4632633 },
    { lat: 48.8022318, lng: 18.4633125 },
    { lat: 48.8021716, lng: 18.4634042 },
    { lat: 48.802133, lng: 18.4634237 },
    { lat: 48.8021542, lng: 18.4634565 },
    { lat: 48.8021003, lng: 18.4635857 },
    { lat: 48.8021409, lng: 18.4636493 },
    { lat: 48.802446, lng: 18.4636172 },
    { lat: 48.802726, lng: 18.4635979 },
    { lat: 48.8030381, lng: 18.4635914 },
    { lat: 48.8036099, lng: 18.463531 },
    { lat: 48.8038139, lng: 18.463526 },
    { lat: 48.8039855, lng: 18.4635315 },
    { lat: 48.8042915, lng: 18.4635755 },
    { lat: 48.8043681, lng: 18.463593 },
    { lat: 48.8042059, lng: 18.4640086 },
    { lat: 48.8041374, lng: 18.4642201 },
    { lat: 48.8041074, lng: 18.4643692 },
    { lat: 48.8040814, lng: 18.4646538 },
    { lat: 48.804061, lng: 18.4651241 },
    { lat: 48.8040587, lng: 18.4655119 },
    { lat: 48.8040475, lng: 18.4658222 },
    { lat: 48.8041298, lng: 18.46582 },
    { lat: 48.8041222, lng: 18.4659688 },
    { lat: 48.8040892, lng: 18.4662113 },
    { lat: 48.8040448, lng: 18.4664259 },
    { lat: 48.8038993, lng: 18.4669395 },
    { lat: 48.8036489, lng: 18.4678439 },
    { lat: 48.8034656, lng: 18.468528 },
    { lat: 48.8031908, lng: 18.4693328 },
    { lat: 48.8030309, lng: 18.4698514 },
    { lat: 48.8028864, lng: 18.4702783 },
    { lat: 48.8027302, lng: 18.4707596 },
    { lat: 48.8026588, lng: 18.4710656 },
    { lat: 48.8025682, lng: 18.4715276 },
    { lat: 48.8027607, lng: 18.4714983 },
    { lat: 48.8038811, lng: 18.4716483 },
    { lat: 48.8038308, lng: 18.4721098 },
    { lat: 48.8037488, lng: 18.4724229 },
    { lat: 48.8036768, lng: 18.4731026 },
    { lat: 48.8036496, lng: 18.4736531 },
    { lat: 48.8035968, lng: 18.4740252 },
    { lat: 48.803434, lng: 18.4743055 },
    { lat: 48.8034857, lng: 18.474489 },
    { lat: 48.8036714, lng: 18.4747075 },
    { lat: 48.8038267, lng: 18.474719 },
    { lat: 48.8039188, lng: 18.4747729 },
    { lat: 48.8037502, lng: 18.4751341 },
    { lat: 48.8036807, lng: 18.475349 },
    { lat: 48.803598, lng: 18.4758296 },
    { lat: 48.8038332, lng: 18.4757796 },
    { lat: 48.8041118, lng: 18.4756786 },
    { lat: 48.8043887, lng: 18.4755639 },
    { lat: 48.8046709, lng: 18.4754425 },
    { lat: 48.8050064, lng: 18.4752889 },
    { lat: 48.8054076, lng: 18.4751184 },
    { lat: 48.8054697, lng: 18.4750784 },
    { lat: 48.8057601, lng: 18.4749444 },
    { lat: 48.8058754, lng: 18.4749084 },
    { lat: 48.8061046, lng: 18.4748083 },
    { lat: 48.8064124, lng: 18.4746618 },
    { lat: 48.8067139, lng: 18.4745432 },
    { lat: 48.8067698, lng: 18.4745322 },
    { lat: 48.8067678, lng: 18.4744792 },
    { lat: 48.8070714, lng: 18.4744602 },
    { lat: 48.807239, lng: 18.4737541 },
    { lat: 48.8075536, lng: 18.4724608 },
    { lat: 48.8078217, lng: 18.4713662 },
    { lat: 48.8080956, lng: 18.4702355 },
    { lat: 48.8085498, lng: 18.4683454 },
    { lat: 48.8086083, lng: 18.4681151 },
    { lat: 48.808701, lng: 18.4679359 },
    { lat: 48.8087988, lng: 18.4678487 },
    { lat: 48.8092473, lng: 18.4678805 },
    { lat: 48.8096947, lng: 18.4678702 },
    { lat: 48.8101188, lng: 18.4678918 },
    { lat: 48.8101211, lng: 18.4678525 },
    { lat: 48.8104902, lng: 18.4678965 },
    { lat: 48.8106874, lng: 18.4678512 },
    { lat: 48.8108228, lng: 18.4677904 },
    { lat: 48.8111196, lng: 18.4678524 },
    { lat: 48.8118367, lng: 18.468031 },
    { lat: 48.8123416, lng: 18.4680868 },
    { lat: 48.8131496, lng: 18.4680919 },
    { lat: 48.8149427, lng: 18.4682871 },
    { lat: 48.8151262, lng: 18.4682575 },
    { lat: 48.8153608, lng: 18.4681746 },
    { lat: 48.8153982, lng: 18.4682003 },
    { lat: 48.8154517, lng: 18.4680643 },
    { lat: 48.8154757, lng: 18.467888 },
    { lat: 48.8155277, lng: 18.4678059 },
    { lat: 48.8157034, lng: 18.4678242 },
    { lat: 48.8157147, lng: 18.4678009 },
    { lat: 48.8157706, lng: 18.4676103 },
    { lat: 48.8157938, lng: 18.4674559 },
    { lat: 48.8158202, lng: 18.4674422 },
    { lat: 48.8158345, lng: 18.4673162 },
    { lat: 48.8158667, lng: 18.4672212 },
    { lat: 48.8159194, lng: 18.4669296 },
    { lat: 48.8159952, lng: 18.4666663 },
    { lat: 48.816031, lng: 18.4665998 },
    { lat: 48.8160805, lng: 18.4664125 },
    { lat: 48.8160845, lng: 18.4662022 },
    { lat: 48.8161045, lng: 18.4660927 },
    { lat: 48.8161825, lng: 18.4659121 },
    { lat: 48.8161485, lng: 18.465703 },
    { lat: 48.816208, lng: 18.4655767 },
    { lat: 48.8163064, lng: 18.4649257 },
    { lat: 48.8163755, lng: 18.4646621 },
    { lat: 48.8165793, lng: 18.4641038 },
    { lat: 48.8167262, lng: 18.4634319 },
    { lat: 48.8168558, lng: 18.4630433 },
    { lat: 48.8170203, lng: 18.4627723 },
    { lat: 48.8172187, lng: 18.4621942 },
    { lat: 48.8174057, lng: 18.4615341 },
    { lat: 48.8174005, lng: 18.4610115 },
    { lat: 48.8174607, lng: 18.4604057 },
    { lat: 48.8175429, lng: 18.4600564 },
    { lat: 48.8178907, lng: 18.4590971 },
    { lat: 48.8182703, lng: 18.4580465 },
    { lat: 48.8182868, lng: 18.4577115 },
    { lat: 48.8182689, lng: 18.4574692 },
    { lat: 48.8182746, lng: 18.4573053 },
    { lat: 48.8183686, lng: 18.4571495 },
    { lat: 48.8185277, lng: 18.4570682 },
    { lat: 48.8187833, lng: 18.4566124 },
    { lat: 48.8188381, lng: 18.4566194 },
    { lat: 48.8196619, lng: 18.4550515 },
    { lat: 48.8200857, lng: 18.4539225 },
    { lat: 48.8201488, lng: 18.4536747 },
    { lat: 48.8202538, lng: 18.4533284 },
    { lat: 48.8204028, lng: 18.4530429 },
    { lat: 48.8204809, lng: 18.4527828 },
    { lat: 48.8205087, lng: 18.4526146 },
    { lat: 48.8206129, lng: 18.4524378 },
    { lat: 48.8212612, lng: 18.4520414 },
    { lat: 48.8214238, lng: 18.4519006 },
    { lat: 48.8217226, lng: 18.4517543 },
    { lat: 48.8221584, lng: 18.4516791 },
    { lat: 48.8222816, lng: 18.4516413 },
    { lat: 48.8226936, lng: 18.4514835 },
    { lat: 48.8227522, lng: 18.451465 },
    { lat: 48.8231693, lng: 18.4512149 },
    { lat: 48.8233669, lng: 18.4508681 },
    { lat: 48.8235583, lng: 18.4505746 },
    { lat: 48.8237626, lng: 18.4503959 },
    { lat: 48.8239874, lng: 18.4501734 },
    { lat: 48.8245229, lng: 18.4497819 },
    { lat: 48.8250899, lng: 18.4492837 },
    { lat: 48.8253817, lng: 18.4488691 },
    { lat: 48.8278694, lng: 18.4474809 },
    { lat: 48.8288885, lng: 18.446326 },
    { lat: 48.8289098, lng: 18.4463266 },
    { lat: 48.8290957, lng: 18.4458709 },
    { lat: 48.829501, lng: 18.4455527 },
    { lat: 48.8297777, lng: 18.4452083 },
    { lat: 48.8301376, lng: 18.4446367 },
    { lat: 48.8303208, lng: 18.4445043 },
    { lat: 48.8304826, lng: 18.4444494 },
    { lat: 48.8310436, lng: 18.4443747 },
    { lat: 48.8313268, lng: 18.443907 },
    { lat: 48.8317155, lng: 18.4434689 },
    { lat: 48.8321485, lng: 18.4434677 },
    { lat: 48.8325552, lng: 18.443375 },
    { lat: 48.8329141, lng: 18.4430731 },
    { lat: 48.8330696, lng: 18.4428836 },
    { lat: 48.8333333, lng: 18.4425741 },
    { lat: 48.8337599, lng: 18.4421881 },
    { lat: 48.833956, lng: 18.4416762 },
    { lat: 48.8340596, lng: 18.4412413 },
    { lat: 48.8341658, lng: 18.4409392 },
    { lat: 48.834163, lng: 18.4406234 },
    { lat: 48.8342482, lng: 18.4400002 },
    { lat: 48.8343453, lng: 18.4394512 },
    { lat: 48.8346217, lng: 18.438962 },
    { lat: 48.8351158, lng: 18.4382491 },
    { lat: 48.8352581, lng: 18.4380695 },
    { lat: 48.835496, lng: 18.4375366 },
    { lat: 48.835634, lng: 18.4372358 },
    { lat: 48.8359562, lng: 18.4362692 },
    { lat: 48.8359944, lng: 18.4360839 },
    { lat: 48.8359774, lng: 18.4356316 },
    { lat: 48.8360501, lng: 18.4349725 },
    { lat: 48.836085, lng: 18.4339066 },
    { lat: 48.83617, lng: 18.4328957 },
    { lat: 48.8363696, lng: 18.4320782 },
    { lat: 48.8365014, lng: 18.4294463 },
    { lat: 48.8365306, lng: 18.4288754 },
    { lat: 48.8365935, lng: 18.4284831 },
    { lat: 48.8367037, lng: 18.4281296 },
    { lat: 48.8369735, lng: 18.4274618 },
    { lat: 48.8372544, lng: 18.426831 },
    { lat: 48.8374835, lng: 18.4264853 },
    { lat: 48.837646, lng: 18.4260399 },
    { lat: 48.8377975, lng: 18.4257786 },
    { lat: 48.8381943, lng: 18.4251028 },
    { lat: 48.8386358, lng: 18.424175 },
    { lat: 48.8389456, lng: 18.4236156 },
    { lat: 48.8391624, lng: 18.4229288 },
    { lat: 48.840359, lng: 18.41975 },
    { lat: 48.839958, lng: 18.419191 },
    { lat: 48.839784, lng: 18.419029 },
    { lat: 48.839423, lng: 18.418608 },
    { lat: 48.839181, lng: 18.418253 },
    { lat: 48.839086, lng: 18.418114 },
    { lat: 48.838757, lng: 18.417728 },
    { lat: 48.838244, lng: 18.41745 },
    { lat: 48.837701, lng: 18.417263 },
    { lat: 48.837096, lng: 18.416833 },
    { lat: 48.836601, lng: 18.416597 },
    { lat: 48.836192, lng: 18.416499 },
    { lat: 48.835748, lng: 18.416358 },
    { lat: 48.835029, lng: 18.415679 },
    { lat: 48.834531, lng: 18.415029 },
    { lat: 48.834391, lng: 18.414774 },
    { lat: 48.834185, lng: 18.414446 },
    { lat: 48.834067, lng: 18.414187 },
    { lat: 48.833892, lng: 18.413888 },
    { lat: 48.833223, lng: 18.413738 },
    { lat: 48.832428, lng: 18.413561 },
    { lat: 48.830558, lng: 18.413263 },
    { lat: 48.828779, lng: 18.412754 },
    { lat: 48.828469, lng: 18.413234 },
    { lat: 48.8276, lng: 18.412376 },
    { lat: 48.827045, lng: 18.411846 },
    { lat: 48.826404, lng: 18.411433 },
    { lat: 48.826048, lng: 18.411195 },
    { lat: 48.825579, lng: 18.410833 },
    { lat: 48.82551, lng: 18.41078 },
    { lat: 48.825324, lng: 18.410635 },
    { lat: 48.824691, lng: 18.41016 },
    { lat: 48.824206, lng: 18.409658 },
    { lat: 48.823579, lng: 18.409678 },
    { lat: 48.82311, lng: 18.409687 },
    { lat: 48.822778, lng: 18.409756 },
    { lat: 48.82204, lng: 18.410393 },
    { lat: 48.821332, lng: 18.410361 },
    { lat: 48.819409, lng: 18.408828 },
    { lat: 48.818769, lng: 18.408307 },
    { lat: 48.818444, lng: 18.408045 },
    { lat: 48.818398, lng: 18.408007 },
    { lat: 48.818344, lng: 18.407964 },
    { lat: 48.817193, lng: 18.407218 },
    { lat: 48.817084, lng: 18.4073 },
    { lat: 48.816959, lng: 18.407358 },
    { lat: 48.816849, lng: 18.407517 },
    { lat: 48.816664, lng: 18.407692 },
    { lat: 48.816074, lng: 18.407848 },
    { lat: 48.815894, lng: 18.40798 },
    { lat: 48.815791, lng: 18.40809 },
    { lat: 48.815568, lng: 18.408173 },
    { lat: 48.8155, lng: 18.40825 },
    { lat: 48.815392, lng: 18.408374 },
    { lat: 48.815205, lng: 18.408633 },
    { lat: 48.815007, lng: 18.408899 },
    { lat: 48.814792, lng: 18.409144 },
    { lat: 48.814701, lng: 18.409185 },
    { lat: 48.814503, lng: 18.40935 },
    { lat: 48.814211, lng: 18.409667 },
    { lat: 48.813104, lng: 18.410526 },
    { lat: 48.812935, lng: 18.410763 },
    { lat: 48.812694, lng: 18.410937 },
    { lat: 48.812587, lng: 18.410975 },
    { lat: 48.812389, lng: 18.411046 },
    { lat: 48.8122, lng: 18.41098 },
    { lat: 48.811958, lng: 18.411133 },
    { lat: 48.811694, lng: 18.411298 },
    { lat: 48.810957, lng: 18.411557 },
    { lat: 48.810005, lng: 18.412067 },
    { lat: 48.809845, lng: 18.411954 },
    { lat: 48.809585, lng: 18.411886 },
    { lat: 48.809495, lng: 18.411749 },
    { lat: 48.809225, lng: 18.411528 },
    { lat: 48.809086, lng: 18.411259 },
    { lat: 48.808814, lng: 18.410998 },
    { lat: 48.80837, lng: 18.410176 },
    { lat: 48.80807, lng: 18.409562 },
    { lat: 48.807894, lng: 18.409226 },
    { lat: 48.807528, lng: 18.408631 },
  ],
  Ráztočno: [
    { lat: 48.734978, lng: 18.82382 },
    { lat: 48.735161, lng: 18.824015 },
    { lat: 48.735357, lng: 18.824224 },
    { lat: 48.735489, lng: 18.824698 },
    { lat: 48.735801, lng: 18.825324 },
    { lat: 48.735803, lng: 18.825334 },
    { lat: 48.735873, lng: 18.825789 },
    { lat: 48.736373, lng: 18.826438 },
    { lat: 48.736615, lng: 18.826383 },
    { lat: 48.736853, lng: 18.826328 },
    { lat: 48.737839, lng: 18.826252 },
    { lat: 48.738179, lng: 18.826382 },
    { lat: 48.738252, lng: 18.826409 },
    { lat: 48.73833, lng: 18.826456 },
    { lat: 48.738693, lng: 18.826674 },
    { lat: 48.738966, lng: 18.826757 },
    { lat: 48.739328, lng: 18.826868 },
    { lat: 48.740764, lng: 18.826279 },
    { lat: 48.74097, lng: 18.826282 },
    { lat: 48.741582, lng: 18.826291 },
    { lat: 48.741816, lng: 18.826186 },
    { lat: 48.741944, lng: 18.826128 },
    { lat: 48.74204, lng: 18.826089 },
    { lat: 48.742209, lng: 18.826019 },
    { lat: 48.74317, lng: 18.825816 },
    { lat: 48.743637, lng: 18.825389 },
    { lat: 48.743874, lng: 18.825015 },
    { lat: 48.743964, lng: 18.825363 },
    { lat: 48.744057, lng: 18.826348 },
    { lat: 48.74453, lng: 18.826703 },
    { lat: 48.744644, lng: 18.826697 },
    { lat: 48.744938, lng: 18.826685 },
    { lat: 48.745318, lng: 18.826269 },
    { lat: 48.745464, lng: 18.826157 },
    { lat: 48.74609, lng: 18.825861 },
    { lat: 48.746088, lng: 18.82586 },
    { lat: 48.746267, lng: 18.825524 },
    { lat: 48.746463, lng: 18.825324 },
    { lat: 48.746777, lng: 18.82509 },
    { lat: 48.746901, lng: 18.824715 },
    { lat: 48.746967, lng: 18.824675 },
    { lat: 48.747316, lng: 18.824531 },
    { lat: 48.747465, lng: 18.824615 },
    { lat: 48.747921, lng: 18.824776 },
    { lat: 48.748278, lng: 18.824698 },
    { lat: 48.748484, lng: 18.8247 },
    { lat: 48.748734, lng: 18.824632 },
    { lat: 48.748955, lng: 18.824817 },
    { lat: 48.749295, lng: 18.824678 },
    { lat: 48.749509, lng: 18.824633 },
    { lat: 48.749856, lng: 18.824348 },
    { lat: 48.749943, lng: 18.824238 },
    { lat: 48.75006, lng: 18.824159 },
    { lat: 48.750286, lng: 18.824165 },
    { lat: 48.750373, lng: 18.824699 },
    { lat: 48.7505, lng: 18.824788 },
    { lat: 48.75094, lng: 18.824814 },
    { lat: 48.75105, lng: 18.824713 },
    { lat: 48.75124, lng: 18.824629 },
    { lat: 48.751287, lng: 18.824472 },
    { lat: 48.751508, lng: 18.824405 },
    { lat: 48.751639, lng: 18.824285 },
    { lat: 48.751948, lng: 18.824303 },
    { lat: 48.752362, lng: 18.824392 },
    { lat: 48.75258, lng: 18.824922 },
    { lat: 48.752941, lng: 18.824815 },
    { lat: 48.752992, lng: 18.824739 },
    { lat: 48.753138, lng: 18.824747 },
    { lat: 48.753635, lng: 18.824343 },
    { lat: 48.753698, lng: 18.824082 },
    { lat: 48.75389, lng: 18.824001 },
    { lat: 48.754017, lng: 18.824034 },
    { lat: 48.754048, lng: 18.823986 },
    { lat: 48.754099, lng: 18.823908 },
    { lat: 48.754869, lng: 18.823282 },
    { lat: 48.755234, lng: 18.823318 },
    { lat: 48.755705, lng: 18.823078 },
    { lat: 48.755994, lng: 18.822665 },
    { lat: 48.755968, lng: 18.822633 },
    { lat: 48.756109, lng: 18.821964 },
    { lat: 48.756143, lng: 18.821618 },
    { lat: 48.756632, lng: 18.820734 },
    { lat: 48.756972, lng: 18.820021 },
    { lat: 48.757208, lng: 18.819819 },
    { lat: 48.7572149, lng: 18.8197816 },
    { lat: 48.757327, lng: 18.819174 },
    { lat: 48.757661, lng: 18.818685 },
    { lat: 48.757646, lng: 18.818311 },
    { lat: 48.757748, lng: 18.817909 },
    { lat: 48.757698, lng: 18.817446 },
    { lat: 48.757723, lng: 18.816949 },
    { lat: 48.757881, lng: 18.816909 },
    { lat: 48.758146, lng: 18.816217 },
    { lat: 48.75822, lng: 18.816011 },
    { lat: 48.758501, lng: 18.815517 },
    { lat: 48.75861, lng: 18.815271 },
    { lat: 48.758719, lng: 18.814768 },
    { lat: 48.758779, lng: 18.814448 },
    { lat: 48.75921, lng: 18.814512 },
    { lat: 48.759638, lng: 18.814339 },
    { lat: 48.759977, lng: 18.814119 },
    { lat: 48.760191, lng: 18.814193 },
    { lat: 48.760696, lng: 18.813902 },
    { lat: 48.760709, lng: 18.813944 },
    { lat: 48.760888, lng: 18.813793 },
    { lat: 48.76101, lng: 18.813811 },
    { lat: 48.761335, lng: 18.813542 },
    { lat: 48.76147, lng: 18.813323 },
    { lat: 48.761741, lng: 18.813032 },
    { lat: 48.761964, lng: 18.812356 },
    { lat: 48.762018, lng: 18.812119 },
    { lat: 48.761998, lng: 18.81177 },
    { lat: 48.761942, lng: 18.811675 },
    { lat: 48.761938, lng: 18.811623 },
    { lat: 48.761932, lng: 18.811557 },
    { lat: 48.762007, lng: 18.811484 },
    { lat: 48.762096, lng: 18.811301 },
    { lat: 48.762152, lng: 18.811334 },
    { lat: 48.762379, lng: 18.811597 },
    { lat: 48.762703, lng: 18.811807 },
    { lat: 48.762955, lng: 18.811671 },
    { lat: 48.763157, lng: 18.811475 },
    { lat: 48.76366, lng: 18.810881 },
    { lat: 48.76383, lng: 18.810831 },
    { lat: 48.764248, lng: 18.810526 },
    { lat: 48.764496, lng: 18.810287 },
    { lat: 48.76472, lng: 18.810221 },
    { lat: 48.764922, lng: 18.809997 },
    { lat: 48.764879, lng: 18.809827 },
    { lat: 48.764942, lng: 18.809691 },
    { lat: 48.765242, lng: 18.809432 },
    { lat: 48.765339, lng: 18.809356 },
    { lat: 48.76562, lng: 18.809172 },
    { lat: 48.765866, lng: 18.809155 },
    { lat: 48.765905, lng: 18.809267 },
    { lat: 48.766067, lng: 18.809174 },
    { lat: 48.766326, lng: 18.809299 },
    { lat: 48.766373, lng: 18.809406 },
    { lat: 48.766504, lng: 18.809579 },
    { lat: 48.766721, lng: 18.809377 },
    { lat: 48.766873, lng: 18.80937 },
    { lat: 48.767148, lng: 18.809391 },
    { lat: 48.767241, lng: 18.809244 },
    { lat: 48.767284, lng: 18.809297 },
    { lat: 48.767681, lng: 18.809516 },
    { lat: 48.767954, lng: 18.809687 },
    { lat: 48.768123, lng: 18.8098 },
    { lat: 48.768275, lng: 18.809792 },
    { lat: 48.768403, lng: 18.80978 },
    { lat: 48.768403, lng: 18.809808 },
    { lat: 48.768537, lng: 18.80982 },
    { lat: 48.768755, lng: 18.810004 },
    { lat: 48.769018, lng: 18.810149 },
    { lat: 48.769167, lng: 18.810144 },
    { lat: 48.769173, lng: 18.810111 },
    { lat: 48.769369, lng: 18.809558 },
    { lat: 48.769581, lng: 18.809289 },
    { lat: 48.770384, lng: 18.808637 },
    { lat: 48.770429, lng: 18.808433 },
    { lat: 48.771034, lng: 18.807685 },
    { lat: 48.771305, lng: 18.807187 },
    { lat: 48.771553, lng: 18.806794 },
    { lat: 48.771671, lng: 18.806505 },
    { lat: 48.771801, lng: 18.806338 },
    { lat: 48.771992, lng: 18.806216 },
    { lat: 48.772013, lng: 18.806202 },
    { lat: 48.772575, lng: 18.80549 },
    { lat: 48.773205, lng: 18.804733 },
    { lat: 48.773413, lng: 18.804399 },
    { lat: 48.773516, lng: 18.804275 },
    { lat: 48.773583, lng: 18.804181 },
    { lat: 48.773728, lng: 18.804088 },
    { lat: 48.773769, lng: 18.804062 },
    { lat: 48.773809, lng: 18.804036 },
    { lat: 48.774175, lng: 18.803806 },
    { lat: 48.774386, lng: 18.8034 },
    { lat: 48.774471, lng: 18.80311 },
    { lat: 48.77451, lng: 18.803123 },
    { lat: 48.774556, lng: 18.803005 },
    { lat: 48.77474, lng: 18.802602 },
    { lat: 48.774882, lng: 18.802429 },
    { lat: 48.775223, lng: 18.802045 },
    { lat: 48.775564, lng: 18.801579 },
    { lat: 48.776214, lng: 18.800904 },
    { lat: 48.776549, lng: 18.800324 },
    { lat: 48.776969, lng: 18.799685 },
    { lat: 48.777338, lng: 18.799242 },
    { lat: 48.777436, lng: 18.799125 },
    { lat: 48.777648, lng: 18.798775 },
    { lat: 48.778045, lng: 18.798014 },
    { lat: 48.778297, lng: 18.797548 },
    { lat: 48.778772, lng: 18.796895 },
    { lat: 48.778851, lng: 18.796801 },
    { lat: 48.779057, lng: 18.796418 },
    { lat: 48.779048, lng: 18.796388 },
    { lat: 48.779115, lng: 18.796308 },
    { lat: 48.779501, lng: 18.795881 },
    { lat: 48.779689, lng: 18.795681 },
    { lat: 48.779837, lng: 18.795479 },
    { lat: 48.780111, lng: 18.795154 },
    { lat: 48.780133, lng: 18.795096 },
    { lat: 48.78018, lng: 18.794918 },
    { lat: 48.780694, lng: 18.794579 },
    { lat: 48.781028, lng: 18.794423 },
    { lat: 48.781436, lng: 18.793875 },
    { lat: 48.782039, lng: 18.793772 },
    { lat: 48.782421, lng: 18.793663 },
    { lat: 48.782595, lng: 18.793513 },
    { lat: 48.782824, lng: 18.792709 },
    { lat: 48.783065, lng: 18.792051 },
    { lat: 48.783035, lng: 18.7914 },
    { lat: 48.783215, lng: 18.79073 },
    { lat: 48.784028, lng: 18.789246 },
    { lat: 48.784212, lng: 18.788106 },
    { lat: 48.784451, lng: 18.788028 },
    { lat: 48.784945, lng: 18.787879 },
    { lat: 48.785134, lng: 18.787645 },
    { lat: 48.78525, lng: 18.787524 },
    { lat: 48.785432, lng: 18.787205 },
    { lat: 48.785617, lng: 18.787003 },
    { lat: 48.785821, lng: 18.786664 },
    { lat: 48.785976, lng: 18.786281 },
    { lat: 48.78607, lng: 18.785833 },
    { lat: 48.786125, lng: 18.785232 },
    { lat: 48.786128, lng: 18.784979 },
    { lat: 48.786157, lng: 18.784871 },
    { lat: 48.786213, lng: 18.784763 },
    { lat: 48.786198, lng: 18.78474 },
    { lat: 48.78655, lng: 18.784225 },
    { lat: 48.786614, lng: 18.783907 },
    { lat: 48.786658, lng: 18.783844 },
    { lat: 48.786888, lng: 18.783595 },
    { lat: 48.787367, lng: 18.782734 },
    { lat: 48.787545, lng: 18.78236 },
    { lat: 48.787644, lng: 18.782173 },
    { lat: 48.787966, lng: 18.781758 },
    { lat: 48.788159, lng: 18.781346 },
    { lat: 48.788241, lng: 18.781211 },
    { lat: 48.788472, lng: 18.780719 },
    { lat: 48.78869, lng: 18.780338 },
    { lat: 48.78889, lng: 18.780073 },
    { lat: 48.789019, lng: 18.77995 },
    { lat: 48.789416, lng: 18.77947 },
    { lat: 48.78968, lng: 18.77902 },
    { lat: 48.789775, lng: 18.778791 },
    { lat: 48.7898432, lng: 18.7786069 },
    { lat: 48.7899055, lng: 18.7784416 },
    { lat: 48.7900034, lng: 18.7781676 },
    { lat: 48.790079, lng: 18.777906 },
    { lat: 48.790119, lng: 18.777523 },
    { lat: 48.790338, lng: 18.777162 },
    { lat: 48.79052, lng: 18.776938 },
    { lat: 48.790778, lng: 18.776719 },
    { lat: 48.791144, lng: 18.776548 },
    { lat: 48.791668, lng: 18.776439 },
    { lat: 48.791859, lng: 18.776377 },
    { lat: 48.791866, lng: 18.776401 },
    { lat: 48.792129, lng: 18.776426 },
    { lat: 48.7920904, lng: 18.7763806 },
    { lat: 48.7920542, lng: 18.7758431 },
    { lat: 48.7913862, lng: 18.7756826 },
    { lat: 48.791162, lng: 18.7753622 },
    { lat: 48.7909134, lng: 18.7754614 },
    { lat: 48.7904063, lng: 18.7750824 },
    { lat: 48.7902442, lng: 18.775345 },
    { lat: 48.7898961, lng: 18.7747084 },
    { lat: 48.7898129, lng: 18.7741934 },
    { lat: 48.789873, lng: 18.7730424 },
    { lat: 48.789607, lng: 18.772485 },
    { lat: 48.7896982, lng: 18.7720731 },
    { lat: 48.7885276, lng: 18.7717273 },
    { lat: 48.7864754, lng: 18.7717538 },
    { lat: 48.7864377, lng: 18.7715696 },
    { lat: 48.7864262, lng: 18.7712652 },
    { lat: 48.7864571, lng: 18.7709947 },
    { lat: 48.7863944, lng: 18.7700778 },
    { lat: 48.7864303, lng: 18.7695828 },
    { lat: 48.7864199, lng: 18.7690595 },
    { lat: 48.7864747, lng: 18.7685991 },
    { lat: 48.7863986, lng: 18.7677296 },
    { lat: 48.7863173, lng: 18.7673213 },
    { lat: 48.7862081, lng: 18.7671084 },
    { lat: 48.786179, lng: 18.7669413 },
    { lat: 48.7861416, lng: 18.7668489 },
    { lat: 48.7860896, lng: 18.7665887 },
    { lat: 48.7861253, lng: 18.766499 },
    { lat: 48.786084, lng: 18.7660988 },
    { lat: 48.7860573, lng: 18.7660816 },
    { lat: 48.7860199, lng: 18.765972 },
    { lat: 48.7860557, lng: 18.7658575 },
    { lat: 48.7861282, lng: 18.7653475 },
    { lat: 48.7861633, lng: 18.7651791 },
    { lat: 48.7861651, lng: 18.7651277 },
    { lat: 48.7862137, lng: 18.7649992 },
    { lat: 48.7862534, lng: 18.7649634 },
    { lat: 48.7862754, lng: 18.7648255 },
    { lat: 48.7863158, lng: 18.7647356 },
    { lat: 48.7863277, lng: 18.7645292 },
    { lat: 48.7863321, lng: 18.7642086 },
    { lat: 48.7863834, lng: 18.7639153 },
    { lat: 48.7864061, lng: 18.763727 },
    { lat: 48.7864239, lng: 18.7632928 },
    { lat: 48.7864609, lng: 18.7632233 },
    { lat: 48.7864347, lng: 18.762769 },
    { lat: 48.7864186, lng: 18.7626118 },
    { lat: 48.7864387, lng: 18.7624585 },
    { lat: 48.7864389, lng: 18.7623322 },
    { lat: 48.7864109, lng: 18.7622076 },
    { lat: 48.7863599, lng: 18.7621583 },
    { lat: 48.7862828, lng: 18.7618933 },
    { lat: 48.7861878, lng: 18.761762 },
    { lat: 48.7861516, lng: 18.7616487 },
    { lat: 48.7861031, lng: 18.7615673 },
    { lat: 48.7860972, lng: 18.7613652 },
    { lat: 48.7860387, lng: 18.76124 },
    { lat: 48.7860464, lng: 18.7611493 },
    { lat: 48.7860193, lng: 18.7610413 },
    { lat: 48.7860226, lng: 18.7608478 },
    { lat: 48.7860148, lng: 18.7607633 },
    { lat: 48.786033, lng: 18.7607049 },
    { lat: 48.7860422, lng: 18.760534 },
    { lat: 48.7860614, lng: 18.7604483 },
    { lat: 48.7860691, lng: 18.7603566 },
    { lat: 48.7860282, lng: 18.7600958 },
    { lat: 48.7860338, lng: 18.7598612 },
    { lat: 48.7859984, lng: 18.7595737 },
    { lat: 48.7859569, lng: 18.7595125 },
    { lat: 48.7859159, lng: 18.7593748 },
    { lat: 48.7859287, lng: 18.7591926 },
    { lat: 48.7858537, lng: 18.7589656 },
    { lat: 48.7858377, lng: 18.7587229 },
    { lat: 48.7858126, lng: 18.7586108 },
    { lat: 48.7857612, lng: 18.7584679 },
    { lat: 48.7857327, lng: 18.7583125 },
    { lat: 48.7856607, lng: 18.758103 },
    { lat: 48.7856129, lng: 18.758053 },
    { lat: 48.7855981, lng: 18.757896 },
    { lat: 48.7855177, lng: 18.757591 },
    { lat: 48.7854847, lng: 18.7574351 },
    { lat: 48.7854784, lng: 18.7573143 },
    { lat: 48.7854865, lng: 18.7572132 },
    { lat: 48.7854059, lng: 18.7571185 },
    { lat: 48.7852886, lng: 18.7569448 },
    { lat: 48.7851201, lng: 18.756634 },
    { lat: 48.7851409, lng: 18.7565406 },
    { lat: 48.785057, lng: 18.7563147 },
    { lat: 48.7850466, lng: 18.756203 },
    { lat: 48.7849963, lng: 18.7561282 },
    { lat: 48.784977, lng: 18.7559306 },
    { lat: 48.7849229, lng: 18.7557605 },
    { lat: 48.7849027, lng: 18.755587 },
    { lat: 48.7848168, lng: 18.7555233 },
    { lat: 48.7847641, lng: 18.7553959 },
    { lat: 48.7847027, lng: 18.7552813 },
    { lat: 48.7845395, lng: 18.7552016 },
    { lat: 48.7844057, lng: 18.75508 },
    { lat: 48.7842398, lng: 18.7550789 },
    { lat: 48.7841403, lng: 18.7549446 },
    { lat: 48.7840575, lng: 18.754916 },
    { lat: 48.7839522, lng: 18.7547774 },
    { lat: 48.7835765, lng: 18.7544543 },
    { lat: 48.7832693, lng: 18.7544019 },
    { lat: 48.78318, lng: 18.7542267 },
    { lat: 48.7828675, lng: 18.7539457 },
    { lat: 48.782821, lng: 18.7540228 },
    { lat: 48.7822879, lng: 18.7538171 },
    { lat: 48.7822333, lng: 18.7537854 },
    { lat: 48.7822187, lng: 18.7534664 },
    { lat: 48.7822061, lng: 18.7534607 },
    { lat: 48.7822198, lng: 18.753443 },
    { lat: 48.7821286, lng: 18.7533181 },
    { lat: 48.7819213, lng: 18.753214 },
    { lat: 48.7818246, lng: 18.7532399 },
    { lat: 48.7817796, lng: 18.7532865 },
    { lat: 48.7817438, lng: 18.7533798 },
    { lat: 48.7817431, lng: 18.7534296 },
    { lat: 48.7817049, lng: 18.7534982 },
    { lat: 48.781657, lng: 18.75344 },
    { lat: 48.7815287, lng: 18.7534908 },
    { lat: 48.7814354, lng: 18.7535175 },
    { lat: 48.7812725, lng: 18.7535306 },
    { lat: 48.780803, lng: 18.7537592 },
    { lat: 48.7807365, lng: 18.7538441 },
    { lat: 48.7805662, lng: 18.7538453 },
    { lat: 48.7804038, lng: 18.7539303 },
    { lat: 48.7803086, lng: 18.7538359 },
    { lat: 48.7801921, lng: 18.7539125 },
    { lat: 48.7802009, lng: 18.7540166 },
    { lat: 48.7801195, lng: 18.7540135 },
    { lat: 48.7800599, lng: 18.7541036 },
    { lat: 48.7797238, lng: 18.7542732 },
    { lat: 48.7792996, lng: 18.7543361 },
    { lat: 48.7792148, lng: 18.7542018 },
    { lat: 48.7790851, lng: 18.7541792 },
    { lat: 48.7789324, lng: 18.7541074 },
    { lat: 48.7788774, lng: 18.754197 },
    { lat: 48.7787728, lng: 18.7541356 },
    { lat: 48.778731, lng: 18.753944 },
    { lat: 48.7787036, lng: 18.7539172 },
    { lat: 48.7786459, lng: 18.7539335 },
    { lat: 48.7786277, lng: 18.7539972 },
    { lat: 48.7785253, lng: 18.754022 },
    { lat: 48.7782626, lng: 18.7540011 },
    { lat: 48.7782146, lng: 18.7538796 },
    { lat: 48.7780233, lng: 18.7539752 },
    { lat: 48.7779563, lng: 18.753925 },
    { lat: 48.7776446, lng: 18.7542164 },
    { lat: 48.7774639, lng: 18.7542085 },
    { lat: 48.7774098, lng: 18.7540824 },
    { lat: 48.7773842, lng: 18.7537679 },
    { lat: 48.7772254, lng: 18.7535568 },
    { lat: 48.7771981, lng: 18.7534467 },
    { lat: 48.7772175, lng: 18.753384 },
    { lat: 48.7771901, lng: 18.7533347 },
    { lat: 48.777114, lng: 18.7533028 },
    { lat: 48.777011, lng: 18.7533806 },
    { lat: 48.7768532, lng: 18.7531922 },
    { lat: 48.7766944, lng: 18.7529146 },
    { lat: 48.7766946, lng: 18.7528137 },
    { lat: 48.7766099, lng: 18.7527907 },
    { lat: 48.7765893, lng: 18.7526312 },
    { lat: 48.7763975, lng: 18.75231 },
    { lat: 48.7763623, lng: 18.7519205 },
    { lat: 48.7763015, lng: 18.7517073 },
    { lat: 48.7761738, lng: 18.7513994 },
    { lat: 48.7760805, lng: 18.751342 },
    { lat: 48.7760232, lng: 18.7512417 },
    { lat: 48.7760176, lng: 18.7511133 },
    { lat: 48.775988, lng: 18.7509777 },
    { lat: 48.7757605, lng: 18.7509193 },
    { lat: 48.7756456, lng: 18.7508374 },
    { lat: 48.775542, lng: 18.7505617 },
    { lat: 48.7754823, lng: 18.7503268 },
    { lat: 48.775376, lng: 18.750193 },
    { lat: 48.7753389, lng: 18.7500216 },
    { lat: 48.7754189, lng: 18.7497642 },
    { lat: 48.7754142, lng: 18.7496113 },
    { lat: 48.7753825, lng: 18.7494818 },
    { lat: 48.77524, lng: 18.7496016 },
    { lat: 48.7751483, lng: 18.7494457 },
    { lat: 48.7750463, lng: 18.7493471 },
    { lat: 48.7750117, lng: 18.7492072 },
    { lat: 48.7750341, lng: 18.7490983 },
    { lat: 48.7750166, lng: 18.7488501 },
    { lat: 48.7750766, lng: 18.7487696 },
    { lat: 48.7750461, lng: 18.7486793 },
    { lat: 48.774931, lng: 18.748657 },
    { lat: 48.7748018, lng: 18.7486454 },
    { lat: 48.7746714, lng: 18.7483529 },
    { lat: 48.7745573, lng: 18.7482423 },
    { lat: 48.7745626, lng: 18.7480879 },
    { lat: 48.7745357, lng: 18.7479621 },
    { lat: 48.7743186, lng: 18.7477805 },
    { lat: 48.7743298, lng: 18.7476916 },
    { lat: 48.7742331, lng: 18.7475924 },
    { lat: 48.7741309, lng: 18.7472357 },
    { lat: 48.7741341, lng: 18.746974 },
    { lat: 48.7741738, lng: 18.7468076 },
    { lat: 48.774165, lng: 18.746789 },
    { lat: 48.7741716, lng: 18.7467421 },
    { lat: 48.7741647, lng: 18.7466571 },
    { lat: 48.7741523, lng: 18.7466139 },
    { lat: 48.7740313, lng: 18.7463274 },
    { lat: 48.7739059, lng: 18.7461316 },
    { lat: 48.773858, lng: 18.7461016 },
    { lat: 48.7737561, lng: 18.7460052 },
    { lat: 48.77347, lng: 18.7457154 },
    { lat: 48.7729905, lng: 18.7457118 },
    { lat: 48.7728087, lng: 18.7457058 },
    { lat: 48.7725856, lng: 18.7457088 },
    { lat: 48.7724939, lng: 18.7460005 },
    { lat: 48.7724613, lng: 18.7463233 },
    { lat: 48.7724762, lng: 18.7470544 },
    { lat: 48.7723898, lng: 18.7470659 },
    { lat: 48.7723033, lng: 18.7471146 },
    { lat: 48.7719519, lng: 18.7472092 },
    { lat: 48.7717382, lng: 18.7471534 },
    { lat: 48.7712717, lng: 18.7471363 },
    { lat: 48.7711817, lng: 18.7470961 },
    { lat: 48.7708607, lng: 18.747104 },
    { lat: 48.770826, lng: 18.7469159 },
    { lat: 48.7706824, lng: 18.7465045 },
    { lat: 48.770622, lng: 18.7463852 },
    { lat: 48.7705861, lng: 18.7463456 },
    { lat: 48.7704832, lng: 18.7463205 },
    { lat: 48.7703543, lng: 18.746376 },
    { lat: 48.7701122, lng: 18.7465532 },
    { lat: 48.7699681, lng: 18.7464741 },
    { lat: 48.7698892, lng: 18.7463452 },
    { lat: 48.7699023, lng: 18.7460995 },
    { lat: 48.7696741, lng: 18.7459672 },
    { lat: 48.7692283, lng: 18.7450403 },
    { lat: 48.7690519, lng: 18.7444295 },
    { lat: 48.7688026, lng: 18.7445586 },
    { lat: 48.7686633, lng: 18.744525 },
    { lat: 48.7685301, lng: 18.7446072 },
    { lat: 48.7684288, lng: 18.7446312 },
    { lat: 48.7683708, lng: 18.74458 },
    { lat: 48.7682612, lng: 18.7445996 },
    { lat: 48.7681855, lng: 18.7446834 },
    { lat: 48.7680272, lng: 18.7446518 },
    { lat: 48.7679155, lng: 18.7446949 },
    { lat: 48.7678724, lng: 18.7446708 },
    { lat: 48.7677245, lng: 18.7446876 },
    { lat: 48.7677016, lng: 18.7446571 },
    { lat: 48.7675497, lng: 18.7447076 },
    { lat: 48.7674948, lng: 18.7447153 },
    { lat: 48.7673614, lng: 18.7446448 },
    { lat: 48.7671489, lng: 18.7446587 },
    { lat: 48.7668924, lng: 18.744454 },
    { lat: 48.7667843, lng: 18.7443269 },
    { lat: 48.7666664, lng: 18.7442335 },
    { lat: 48.7664626, lng: 18.7441501 },
    { lat: 48.7659937, lng: 18.7438356 },
    { lat: 48.7656523, lng: 18.7436484 },
    { lat: 48.7655808, lng: 18.7436198 },
    { lat: 48.7647681, lng: 18.7434762 },
    { lat: 48.7645953, lng: 18.743408 },
    { lat: 48.7640903, lng: 18.7430492 },
    { lat: 48.7638277, lng: 18.7428782 },
    { lat: 48.7636263, lng: 18.7427821 },
    { lat: 48.7633993, lng: 18.7435451 },
    { lat: 48.7632855, lng: 18.7438251 },
    { lat: 48.7630897, lng: 18.7442816 },
    { lat: 48.7629245, lng: 18.7446354 },
    { lat: 48.7627324, lng: 18.7449931 },
    { lat: 48.7625913, lng: 18.7452446 },
    { lat: 48.7623409, lng: 18.7456326 },
    { lat: 48.7621392, lng: 18.7459561 },
    { lat: 48.7622656, lng: 18.7461268 },
    { lat: 48.7623449, lng: 18.7461558 },
    { lat: 48.7624256, lng: 18.7461454 },
    { lat: 48.7625554, lng: 18.7462366 },
    { lat: 48.7626774, lng: 18.7462812 },
    { lat: 48.762745, lng: 18.746193 },
    { lat: 48.7628534, lng: 18.7463487 },
    { lat: 48.7628443, lng: 18.7464527 },
    { lat: 48.7628255, lng: 18.7468464 },
    { lat: 48.7627775, lng: 18.747926 },
    { lat: 48.7627742, lng: 18.7480332 },
    { lat: 48.7629254, lng: 18.7480554 },
    { lat: 48.7633884, lng: 18.7482591 },
    { lat: 48.7636362, lng: 18.7483833 },
    { lat: 48.7638014, lng: 18.7485 },
    { lat: 48.7638379, lng: 18.7485735 },
    { lat: 48.7638667, lng: 18.7488835 },
    { lat: 48.7639109, lng: 18.749512 },
    { lat: 48.7639375, lng: 18.7496936 },
    { lat: 48.7640794, lng: 18.7501179 },
    { lat: 48.7641157, lng: 18.7503795 },
    { lat: 48.7641341, lng: 18.7507065 },
    { lat: 48.7641001, lng: 18.750855 },
    { lat: 48.7637271, lng: 18.7508178 },
    { lat: 48.7633399, lng: 18.7508526 },
    { lat: 48.7630746, lng: 18.7508663 },
    { lat: 48.7626643, lng: 18.7509157 },
    { lat: 48.7624154, lng: 18.7509 },
    { lat: 48.7620854, lng: 18.7508218 },
    { lat: 48.7618975, lng: 18.7507218 },
    { lat: 48.7617212, lng: 18.7506733 },
    { lat: 48.7616152, lng: 18.7506525 },
    { lat: 48.76116, lng: 18.7506151 },
    { lat: 48.7609454, lng: 18.7505659 },
    { lat: 48.7608443, lng: 18.7505294 },
    { lat: 48.7607657, lng: 18.750604 },
    { lat: 48.7606919, lng: 18.7506161 },
    { lat: 48.7602039, lng: 18.7505758 },
    { lat: 48.7588645, lng: 18.750517 },
    { lat: 48.7580328, lng: 18.7504427 },
    { lat: 48.757379, lng: 18.7504489 },
    { lat: 48.7570258, lng: 18.7513446 },
    { lat: 48.7570022, lng: 18.751429 },
    { lat: 48.7568345, lng: 18.7521003 },
    { lat: 48.7570107, lng: 18.7522778 },
    { lat: 48.7572007, lng: 18.7525663 },
    { lat: 48.7569696, lng: 18.7534458 },
    { lat: 48.7565002, lng: 18.7544051 },
    { lat: 48.7558338, lng: 18.7565733 },
    { lat: 48.7568258, lng: 18.7573149 },
    { lat: 48.7566645, lng: 18.7584076 },
    { lat: 48.756893, lng: 18.7595992 },
    { lat: 48.7572958, lng: 18.7609543 },
    { lat: 48.7574451, lng: 18.7617134 },
    { lat: 48.7574904, lng: 18.7617219 },
    { lat: 48.7574531, lng: 18.7625977 },
    { lat: 48.7577134, lng: 18.7628302 },
    { lat: 48.7577569, lng: 18.7629468 },
    { lat: 48.7577352, lng: 18.7629662 },
    { lat: 48.7577508, lng: 18.763201 },
    { lat: 48.7577483, lng: 18.7636023 },
    { lat: 48.7577409, lng: 18.7640596 },
    { lat: 48.7577461, lng: 18.764158 },
    { lat: 48.7578069, lng: 18.7648026 },
    { lat: 48.7579236, lng: 18.7651571 },
    { lat: 48.7579613, lng: 18.7654041 },
    { lat: 48.7579729, lng: 18.7656415 },
    { lat: 48.7579733, lng: 18.7658026 },
    { lat: 48.7580002, lng: 18.7658827 },
    { lat: 48.7579721, lng: 18.7660987 },
    { lat: 48.7578122, lng: 18.7670286 },
    { lat: 48.7579216, lng: 18.7676091 },
    { lat: 48.7578939, lng: 18.768304 },
    { lat: 48.757714, lng: 18.7687213 },
    { lat: 48.7577747, lng: 18.7690414 },
    { lat: 48.7579241, lng: 18.7693312 },
    { lat: 48.7577405, lng: 18.7695055 },
    { lat: 48.7577493, lng: 18.7696563 },
    { lat: 48.758088, lng: 18.7702651 },
    { lat: 48.7579872, lng: 18.7711353 },
    { lat: 48.7579169, lng: 18.7716881 },
    { lat: 48.7578168, lng: 18.7719053 },
    { lat: 48.7577954, lng: 18.7722502 },
    { lat: 48.7578261, lng: 18.7724299 },
    { lat: 48.7577277, lng: 18.7727255 },
    { lat: 48.7578508, lng: 18.7730931 },
    { lat: 48.7579469, lng: 18.7741284 },
    { lat: 48.7578558, lng: 18.774347 },
    { lat: 48.7577673, lng: 18.7744259 },
    { lat: 48.757604, lng: 18.7745563 },
    { lat: 48.7573308, lng: 18.7748307 },
    { lat: 48.7573982, lng: 18.7750613 },
    { lat: 48.7575643, lng: 18.7751336 },
    { lat: 48.7575727, lng: 18.7753792 },
    { lat: 48.757458, lng: 18.7757114 },
    { lat: 48.7574304, lng: 18.7760857 },
    { lat: 48.7570889, lng: 18.7763461 },
    { lat: 48.7569298, lng: 18.7764306 },
    { lat: 48.7569769, lng: 18.7766841 },
    { lat: 48.7568759, lng: 18.7769085 },
    { lat: 48.7566828, lng: 18.776754 },
    { lat: 48.7565142, lng: 18.7768282 },
    { lat: 48.7564244, lng: 18.7769869 },
    { lat: 48.7562893, lng: 18.7773802 },
    { lat: 48.7562255, lng: 18.7775147 },
    { lat: 48.7562418, lng: 18.777591 },
    { lat: 48.7562948, lng: 18.777679 },
    { lat: 48.7563006, lng: 18.7777434 },
    { lat: 48.7562696, lng: 18.7777982 },
    { lat: 48.7561759, lng: 18.7778409 },
    { lat: 48.7560975, lng: 18.7777874 },
    { lat: 48.7560297, lng: 18.7778284 },
    { lat: 48.7558655, lng: 18.7780082 },
    { lat: 48.755884, lng: 18.7782722 },
    { lat: 48.755825, lng: 18.778463 },
    { lat: 48.7558852, lng: 18.7785794 },
    { lat: 48.755853, lng: 18.7788885 },
    { lat: 48.7558792, lng: 18.7789322 },
    { lat: 48.7559532, lng: 18.7789694 },
    { lat: 48.7559687, lng: 18.7789851 },
    { lat: 48.7559743, lng: 18.7790291 },
    { lat: 48.7559629, lng: 18.7791141 },
    { lat: 48.7558514, lng: 18.7793319 },
    { lat: 48.7557608, lng: 18.7794106 },
    { lat: 48.7556744, lng: 18.7795661 },
    { lat: 48.7557397, lng: 18.7796926 },
    { lat: 48.7557068, lng: 18.7798402 },
    { lat: 48.7555003, lng: 18.7800762 },
    { lat: 48.7551618, lng: 18.7801382 },
    { lat: 48.7550486, lng: 18.7802994 },
    { lat: 48.7550031, lng: 18.7805096 },
    { lat: 48.7549771, lng: 18.7808748 },
    { lat: 48.7548562, lng: 18.7810837 },
    { lat: 48.7545199, lng: 18.781124 },
    { lat: 48.7544878, lng: 18.7810688 },
    { lat: 48.7543095, lng: 18.7811074 },
    { lat: 48.7543037, lng: 18.7813632 },
    { lat: 48.7542523, lng: 18.7814373 },
    { lat: 48.7541268, lng: 18.7814763 },
    { lat: 48.753744, lng: 18.7817258 },
    { lat: 48.753669, lng: 18.7818177 },
    { lat: 48.7535879, lng: 18.7819532 },
    { lat: 48.753512, lng: 18.7823765 },
    { lat: 48.7534023, lng: 18.7824962 },
    { lat: 48.7533627, lng: 18.7830527 },
    { lat: 48.7532179, lng: 18.7835185 },
    { lat: 48.753117, lng: 18.7837036 },
    { lat: 48.7530666, lng: 18.784077 },
    { lat: 48.7529762, lng: 18.7842469 },
    { lat: 48.7529574, lng: 18.7843788 },
    { lat: 48.752951, lng: 18.7845586 },
    { lat: 48.7529709, lng: 18.7846192 },
    { lat: 48.752981, lng: 18.7848295 },
    { lat: 48.7529347, lng: 18.7848944 },
    { lat: 48.7527907, lng: 18.7850099 },
    { lat: 48.7526799, lng: 18.7853712 },
    { lat: 48.7525903, lng: 18.7855095 },
    { lat: 48.7525405, lng: 18.7858093 },
    { lat: 48.7524702, lng: 18.7858888 },
    { lat: 48.7523777, lng: 18.7858866 },
    { lat: 48.7522432, lng: 18.7859896 },
    { lat: 48.7518661, lng: 18.7863921 },
    { lat: 48.7518761, lng: 18.7865603 },
    { lat: 48.751836, lng: 18.7866558 },
    { lat: 48.7517686, lng: 18.7867039 },
    { lat: 48.7516811, lng: 18.7866905 },
    { lat: 48.7516885, lng: 18.7868754 },
    { lat: 48.751652, lng: 18.7869741 },
    { lat: 48.751442, lng: 18.7871429 },
    { lat: 48.7514827, lng: 18.7873364 },
    { lat: 48.7513533, lng: 18.7873883 },
    { lat: 48.7512223, lng: 18.7874912 },
    { lat: 48.7511911, lng: 18.7874553 },
    { lat: 48.7512132, lng: 18.78734 },
    { lat: 48.751199, lng: 18.7873039 },
    { lat: 48.7511764, lng: 18.7873055 },
    { lat: 48.7510084, lng: 18.7875179 },
    { lat: 48.7509231, lng: 18.7875266 },
    { lat: 48.7507896, lng: 18.787494 },
    { lat: 48.7508013, lng: 18.7876331 },
    { lat: 48.7506787, lng: 18.7878364 },
    { lat: 48.7505579, lng: 18.7877219 },
    { lat: 48.7504839, lng: 18.787839 },
    { lat: 48.7505115, lng: 18.7880869 },
    { lat: 48.7504411, lng: 18.7881647 },
    { lat: 48.7502506, lng: 18.7882311 },
    { lat: 48.7502216, lng: 18.7883228 },
    { lat: 48.7502866, lng: 18.7885705 },
    { lat: 48.7500676, lng: 18.7888933 },
    { lat: 48.7499884, lng: 18.7889282 },
    { lat: 48.7499869, lng: 18.7892707 },
    { lat: 48.7499467, lng: 18.7894685 },
    { lat: 48.7498796, lng: 18.7894815 },
    { lat: 48.7498531, lng: 18.7894672 },
    { lat: 48.7497906, lng: 18.7893288 },
    { lat: 48.749816, lng: 18.7892329 },
    { lat: 48.7497973, lng: 18.7891372 },
    { lat: 48.7496112, lng: 18.789158 },
    { lat: 48.7496099, lng: 18.7893033 },
    { lat: 48.7497258, lng: 18.7895581 },
    { lat: 48.7497142, lng: 18.7896178 },
    { lat: 48.7496128, lng: 18.7896849 },
    { lat: 48.7494912, lng: 18.7897313 },
    { lat: 48.7493436, lng: 18.789859 },
    { lat: 48.7492215, lng: 18.7900505 },
    { lat: 48.7491506, lng: 18.7902041 },
    { lat: 48.7490312, lng: 18.790312 },
    { lat: 48.7488802, lng: 18.7903375 },
    { lat: 48.7488752, lng: 18.7904139 },
    { lat: 48.7489361, lng: 18.790522 },
    { lat: 48.7489635, lng: 18.7907028 },
    { lat: 48.7490106, lng: 18.79078 },
    { lat: 48.7490128, lng: 18.7908441 },
    { lat: 48.7489883, lng: 18.7908738 },
    { lat: 48.7487476, lng: 18.7909466 },
    { lat: 48.7487011, lng: 18.7909207 },
    { lat: 48.7486364, lng: 18.7907862 },
    { lat: 48.7486051, lng: 18.7906181 },
    { lat: 48.7482915, lng: 18.7908528 },
    { lat: 48.7482677, lng: 18.7910258 },
    { lat: 48.7481368, lng: 18.7911926 },
    { lat: 48.747978, lng: 18.7914135 },
    { lat: 48.7479731, lng: 18.7915141 },
    { lat: 48.7480508, lng: 18.7916209 },
    { lat: 48.748047, lng: 18.7916761 },
    { lat: 48.7479973, lng: 18.791806 },
    { lat: 48.7479089, lng: 18.7918584 },
    { lat: 48.7478731, lng: 18.7919745 },
    { lat: 48.7478015, lng: 18.7920059 },
    { lat: 48.7478119, lng: 18.7923985 },
    { lat: 48.7476293, lng: 18.7928116 },
    { lat: 48.7475004, lng: 18.7933655 },
    { lat: 48.7473892, lng: 18.7934795 },
    { lat: 48.7470953, lng: 18.7935522 },
    { lat: 48.7469926, lng: 18.7936177 },
    { lat: 48.7468928, lng: 18.7937794 },
    { lat: 48.7468263, lng: 18.793777 },
    { lat: 48.7467199, lng: 18.7939437 },
    { lat: 48.7466404, lng: 18.7942358 },
    { lat: 48.7465593, lng: 18.7943023 },
    { lat: 48.7465128, lng: 18.7945112 },
    { lat: 48.7463513, lng: 18.7946581 },
    { lat: 48.7463012, lng: 18.7946498 },
    { lat: 48.7463267, lng: 18.7949873 },
    { lat: 48.7464226, lng: 18.795222 },
    { lat: 48.7463936, lng: 18.7953809 },
    { lat: 48.7462406, lng: 18.7956457 },
    { lat: 48.7461897, lng: 18.7957936 },
    { lat: 48.7460687, lng: 18.7958749 },
    { lat: 48.7460733, lng: 18.7960484 },
    { lat: 48.7461639, lng: 18.7961626 },
    { lat: 48.7461566, lng: 18.7962635 },
    { lat: 48.7460633, lng: 18.7963312 },
    { lat: 48.7459995, lng: 18.7962995 },
    { lat: 48.7459508, lng: 18.7963575 },
    { lat: 48.746004, lng: 18.7965344 },
    { lat: 48.7461076, lng: 18.796681 },
    { lat: 48.7461058, lng: 18.7968009 },
    { lat: 48.7460798, lng: 18.7969734 },
    { lat: 48.7461152, lng: 18.7970671 },
    { lat: 48.7460788, lng: 18.7972122 },
    { lat: 48.7461424, lng: 18.7973704 },
    { lat: 48.7461296, lng: 18.7977092 },
    { lat: 48.7460744, lng: 18.797946 },
    { lat: 48.7461489, lng: 18.7980546 },
    { lat: 48.7462022, lng: 18.7982969 },
    { lat: 48.7462017, lng: 18.7984832 },
    { lat: 48.7461623, lng: 18.7986379 },
    { lat: 48.7461771, lng: 18.7987484 },
    { lat: 48.7462956, lng: 18.7988557 },
    { lat: 48.7463323, lng: 18.7989339 },
    { lat: 48.7463273, lng: 18.7989873 },
    { lat: 48.7463905, lng: 18.799057 },
    { lat: 48.7464856, lng: 18.7993836 },
    { lat: 48.7465284, lng: 18.7995625 },
    { lat: 48.7465345, lng: 18.7995882 },
    { lat: 48.746602, lng: 18.7996713 },
    { lat: 48.7465889, lng: 18.799983 },
    { lat: 48.7466427, lng: 18.8003661 },
    { lat: 48.7467146, lng: 18.8005286 },
    { lat: 48.7466789, lng: 18.8006701 },
    { lat: 48.7465588, lng: 18.800872 },
    { lat: 48.7465912, lng: 18.8010179 },
    { lat: 48.7466182, lng: 18.8010411 },
    { lat: 48.7466041, lng: 18.8011546 },
    { lat: 48.7466323, lng: 18.8013117 },
    { lat: 48.7466927, lng: 18.8014081 },
    { lat: 48.7467538, lng: 18.8015602 },
    { lat: 48.7468438, lng: 18.8016859 },
    { lat: 48.746981, lng: 18.8017435 },
    { lat: 48.7470584, lng: 18.8018683 },
    { lat: 48.7470966, lng: 18.8019705 },
    { lat: 48.74715, lng: 18.8020438 },
    { lat: 48.7472109, lng: 18.8023278 },
    { lat: 48.7472243, lng: 18.8026067 },
    { lat: 48.7472007, lng: 18.80291 },
    { lat: 48.747156, lng: 18.803005 },
    { lat: 48.7471404, lng: 18.8036958 },
    { lat: 48.7471162, lng: 18.8037712 },
    { lat: 48.7469102, lng: 18.8038458 },
    { lat: 48.746814, lng: 18.8039895 },
    { lat: 48.7467197, lng: 18.8042341 },
    { lat: 48.7466454, lng: 18.8043037 },
    { lat: 48.746571, lng: 18.804772 },
    { lat: 48.7465728, lng: 18.8048065 },
    { lat: 48.7466117, lng: 18.8048118 },
    { lat: 48.746632, lng: 18.8048883 },
    { lat: 48.7466093, lng: 18.8049826 },
    { lat: 48.7465416, lng: 18.8050883 },
    { lat: 48.7464634, lng: 18.8051987 },
    { lat: 48.7463575, lng: 18.8052039 },
    { lat: 48.7463109, lng: 18.805285 },
    { lat: 48.7462153, lng: 18.8054883 },
    { lat: 48.7461268, lng: 18.8055788 },
    { lat: 48.7460335, lng: 18.8056593 },
    { lat: 48.7459603, lng: 18.8056946 },
    { lat: 48.745877, lng: 18.8057118 },
    { lat: 48.7458309, lng: 18.805712 },
    { lat: 48.7457752, lng: 18.8057206 },
    { lat: 48.7455645, lng: 18.8057608 },
    { lat: 48.7453885, lng: 18.805779 },
    { lat: 48.745337, lng: 18.805743 },
    { lat: 48.745082, lng: 18.8059377 },
    { lat: 48.7448433, lng: 18.8061584 },
    { lat: 48.7446558, lng: 18.8061294 },
    { lat: 48.7444724, lng: 18.8061377 },
    { lat: 48.7443179, lng: 18.8059903 },
    { lat: 48.7439379, lng: 18.8060121 },
    { lat: 48.7438814, lng: 18.8061603 },
    { lat: 48.7436383, lng: 18.8060567 },
    { lat: 48.7435312, lng: 18.806405 },
    { lat: 48.7434098, lng: 18.8065642 },
    { lat: 48.7432765, lng: 18.8067766 },
    { lat: 48.743227, lng: 18.8070155 },
    { lat: 48.743228, lng: 18.8072051 },
    { lat: 48.7431542, lng: 18.8074132 },
    { lat: 48.7428748, lng: 18.8077397 },
    { lat: 48.742848, lng: 18.807771 },
    { lat: 48.7428145, lng: 18.8078628 },
    { lat: 48.7428192, lng: 18.8082342 },
    { lat: 48.7426345, lng: 18.808452 },
    { lat: 48.7425414, lng: 18.8087243 },
    { lat: 48.7423554, lng: 18.8091763 },
    { lat: 48.7423228, lng: 18.8097443 },
    { lat: 48.7423161, lng: 18.810027 },
    { lat: 48.7422551, lng: 18.8102394 },
    { lat: 48.7421959, lng: 18.8105548 },
    { lat: 48.7419635, lng: 18.8111773 },
    { lat: 48.7419533, lng: 18.8112625 },
    { lat: 48.7421275, lng: 18.8115351 },
    { lat: 48.7421345, lng: 18.8116482 },
    { lat: 48.7422667, lng: 18.8118436 },
    { lat: 48.7422262, lng: 18.8120003 },
    { lat: 48.7422823, lng: 18.812188 },
    { lat: 48.7422404, lng: 18.8128982 },
    { lat: 48.7420403, lng: 18.813344 },
    { lat: 48.7419656, lng: 18.8133794 },
    { lat: 48.7417206, lng: 18.8138066 },
    { lat: 48.7416781, lng: 18.814116 },
    { lat: 48.7415916, lng: 18.8143596 },
    { lat: 48.7414988, lng: 18.8145293 },
    { lat: 48.7413951, lng: 18.8148564 },
    { lat: 48.7412159, lng: 18.8150089 },
    { lat: 48.7410509, lng: 18.8154976 },
    { lat: 48.7409614, lng: 18.8157484 },
    { lat: 48.7408245, lng: 18.8160462 },
    { lat: 48.7407597, lng: 18.816597 },
    { lat: 48.7407325, lng: 18.8166788 },
    { lat: 48.7404024, lng: 18.8169014 },
    { lat: 48.7402718, lng: 18.8171649 },
    { lat: 48.7401849, lng: 18.8174489 },
    { lat: 48.7402244, lng: 18.8176625 },
    { lat: 48.7402452, lng: 18.8177158 },
    { lat: 48.7402092, lng: 18.8180637 },
    { lat: 48.7401549, lng: 18.8181695 },
    { lat: 48.740066, lng: 18.8182601 },
    { lat: 48.7400122, lng: 18.8185066 },
    { lat: 48.7397656, lng: 18.8189886 },
    { lat: 48.7397685, lng: 18.8190844 },
    { lat: 48.7397308, lng: 18.819295 },
    { lat: 48.7396475, lng: 18.81958 },
    { lat: 48.7391895, lng: 18.8202233 },
    { lat: 48.739107, lng: 18.8203088 },
    { lat: 48.7390305, lng: 18.820528 },
    { lat: 48.7389435, lng: 18.8206991 },
    { lat: 48.7385664, lng: 18.8212709 },
    { lat: 48.7382506, lng: 18.82136 },
    { lat: 48.7378148, lng: 18.8213046 },
    { lat: 48.7376629, lng: 18.8213316 },
    { lat: 48.7375634, lng: 18.8213049 },
    { lat: 48.7371558, lng: 18.8217321 },
    { lat: 48.7363004, lng: 18.8223904 },
    { lat: 48.735653, lng: 18.8230811 },
    { lat: 48.734978, lng: 18.82382 },
  ],
  Sebedražie: [
    { lat: 48.7100189, lng: 18.6258161 },
    { lat: 48.7100413, lng: 18.6258555 },
    { lat: 48.7102347, lng: 18.6258428 },
    { lat: 48.7105544, lng: 18.6258542 },
    { lat: 48.7108163, lng: 18.625921 },
    { lat: 48.7113663, lng: 18.6262077 },
    { lat: 48.7115306, lng: 18.6263043 },
    { lat: 48.7118692, lng: 18.6264959 },
    { lat: 48.7120735, lng: 18.6266466 },
    { lat: 48.7125678, lng: 18.6269869 },
    { lat: 48.7130065, lng: 18.6273445 },
    { lat: 48.7130343, lng: 18.6273768 },
    { lat: 48.7139601, lng: 18.628152 },
    { lat: 48.7158259, lng: 18.629898 },
    { lat: 48.7169091, lng: 18.6313098 },
    { lat: 48.7173576, lng: 18.6318727 },
    { lat: 48.7174987, lng: 18.6320829 },
    { lat: 48.7181164, lng: 18.6330843 },
    { lat: 48.7183876, lng: 18.6335136 },
    { lat: 48.7186754, lng: 18.6341233 },
    { lat: 48.7193461, lng: 18.635209 },
    { lat: 48.7202682, lng: 18.6363779 },
    { lat: 48.7203736, lng: 18.6365136 },
    { lat: 48.7205867, lng: 18.6368028 },
    { lat: 48.7211188, lng: 18.6380196 },
    { lat: 48.7211967, lng: 18.6382072 },
    { lat: 48.7210515, lng: 18.6408436 },
    { lat: 48.7208171, lng: 18.6417306 },
    { lat: 48.72061, lng: 18.6427476 },
    { lat: 48.7204771, lng: 18.6434854 },
    { lat: 48.7207984, lng: 18.6440511 },
    { lat: 48.7207401, lng: 18.6444772 },
    { lat: 48.7207927, lng: 18.6469511 },
    { lat: 48.7208117, lng: 18.6477918 },
    { lat: 48.7218255, lng: 18.6504185 },
    { lat: 48.7221508, lng: 18.6520918 },
    { lat: 48.7221919, lng: 18.6537424 },
    { lat: 48.7221465, lng: 18.6540337 },
    { lat: 48.7219612, lng: 18.6547225 },
    { lat: 48.7219499, lng: 18.6550582 },
    { lat: 48.7217796, lng: 18.6564064 },
    { lat: 48.7218188, lng: 18.6572235 },
    { lat: 48.7219232, lng: 18.6576806 },
    { lat: 48.7221466, lng: 18.6576405 },
    { lat: 48.7221779, lng: 18.6576349 },
    { lat: 48.7228995, lng: 18.6575049 },
    { lat: 48.7233753, lng: 18.6575228 },
    { lat: 48.7238021, lng: 18.6576567 },
    { lat: 48.7246707, lng: 18.6577452 },
    { lat: 48.7249795, lng: 18.6576352 },
    { lat: 48.726393, lng: 18.6575783 },
    { lat: 48.727028, lng: 18.6576647 },
    { lat: 48.7273737, lng: 18.657357 },
    { lat: 48.7283811, lng: 18.656603 },
    { lat: 48.7287793, lng: 18.6560145 },
    { lat: 48.7292552, lng: 18.6557811 },
    { lat: 48.7297438, lng: 18.6555325 },
    { lat: 48.7300763, lng: 18.6555719 },
    { lat: 48.7308868, lng: 18.6552482 },
    { lat: 48.7316317, lng: 18.6546468 },
    { lat: 48.7319238, lng: 18.6543081 },
    { lat: 48.7320062, lng: 18.6542466 },
    { lat: 48.7322697, lng: 18.6542408 },
    { lat: 48.7323239, lng: 18.6542395 },
    { lat: 48.732326, lng: 18.6541103 },
    { lat: 48.7323301, lng: 18.6538288 },
    { lat: 48.7323671, lng: 18.6537616 },
    { lat: 48.7326785, lng: 18.6537635 },
    { lat: 48.732764, lng: 18.653803 },
    { lat: 48.7328091, lng: 18.6538236 },
    { lat: 48.7329427, lng: 18.6537274 },
    { lat: 48.732997, lng: 18.6536875 },
    { lat: 48.7330643, lng: 18.6536397 },
    { lat: 48.7332666, lng: 18.6536038 },
    { lat: 48.7333369, lng: 18.6535918 },
    { lat: 48.7335146, lng: 18.6535603 },
    { lat: 48.7337751, lng: 18.6533912 },
    { lat: 48.7338569, lng: 18.6533382 },
    { lat: 48.7341316, lng: 18.65316 },
    { lat: 48.7342841, lng: 18.6530608 },
    { lat: 48.7343211, lng: 18.653037 },
    { lat: 48.7345877, lng: 18.6523284 },
    { lat: 48.7346559, lng: 18.6521475 },
    { lat: 48.7346648, lng: 18.6521239 },
    { lat: 48.7347496, lng: 18.6517707 },
    { lat: 48.7347782, lng: 18.6516505 },
    { lat: 48.7349016, lng: 18.6511331 },
    { lat: 48.7349265, lng: 18.6510303 },
    { lat: 48.734993, lng: 18.6507537 },
    { lat: 48.735096, lng: 18.6505498 },
    { lat: 48.7350828, lng: 18.6500951 },
    { lat: 48.7350815, lng: 18.6500481 },
    { lat: 48.7351704, lng: 18.6499608 },
    { lat: 48.7352255, lng: 18.6483984 },
    { lat: 48.7352238, lng: 18.6481167 },
    { lat: 48.7352919, lng: 18.6479558 },
    { lat: 48.7354052, lng: 18.64769 },
    { lat: 48.7354375, lng: 18.6465466 },
    { lat: 48.7354483, lng: 18.6461378 },
    { lat: 48.7349975, lng: 18.6441667 },
    { lat: 48.7349787, lng: 18.6436687 },
    { lat: 48.7349761, lng: 18.643578 },
    { lat: 48.7349533, lng: 18.6429626 },
    { lat: 48.7349425, lng: 18.6427828 },
    { lat: 48.7349392, lng: 18.6427214 },
    { lat: 48.734937, lng: 18.6426802 },
    { lat: 48.7349616, lng: 18.6425071 },
    { lat: 48.7349738, lng: 18.6424201 },
    { lat: 48.7352162, lng: 18.6417242 },
    { lat: 48.7354819, lng: 18.6411049 },
    { lat: 48.735515, lng: 18.6410284 },
    { lat: 48.735557, lng: 18.6409292 },
    { lat: 48.7356752, lng: 18.6403781 },
    { lat: 48.7358081, lng: 18.6400992 },
    { lat: 48.7358135, lng: 18.6399688 },
    { lat: 48.7358175, lng: 18.6398772 },
    { lat: 48.7358235, lng: 18.6397566 },
    { lat: 48.7358455, lng: 18.6397262 },
    { lat: 48.7358866, lng: 18.6396711 },
    { lat: 48.7359812, lng: 18.6395438 },
    { lat: 48.7359985, lng: 18.6392338 },
    { lat: 48.7360184, lng: 18.6391658 },
    { lat: 48.7360557, lng: 18.6390387 },
    { lat: 48.7360786, lng: 18.6389609 },
    { lat: 48.7362863, lng: 18.6382508 },
    { lat: 48.7363919, lng: 18.6380546 },
    { lat: 48.7365061, lng: 18.6378472 },
    { lat: 48.7364735, lng: 18.6377446 },
    { lat: 48.7364809, lng: 18.6375472 },
    { lat: 48.7364935, lng: 18.6372139 },
    { lat: 48.7367973, lng: 18.6364856 },
    { lat: 48.7368182, lng: 18.6364349 },
    { lat: 48.7369721, lng: 18.6360661 },
    { lat: 48.7369855, lng: 18.6359587 },
    { lat: 48.7370218, lng: 18.6356687 },
    { lat: 48.7370692, lng: 18.635289 },
    { lat: 48.737067, lng: 18.6348503 },
    { lat: 48.7371147, lng: 18.6346261 },
    { lat: 48.7373057, lng: 18.6345494 },
    { lat: 48.7374787, lng: 18.6343874 },
    { lat: 48.7375005, lng: 18.6343122 },
    { lat: 48.7375255, lng: 18.6342734 },
    { lat: 48.7376547, lng: 18.6340735 },
    { lat: 48.7377734, lng: 18.6338909 },
    { lat: 48.7380305, lng: 18.6334914 },
    { lat: 48.7380441, lng: 18.6334716 },
    { lat: 48.7381149, lng: 18.6333617 },
    { lat: 48.738116, lng: 18.6333595 },
    { lat: 48.7381222, lng: 18.6333498 },
    { lat: 48.738179, lng: 18.6332946 },
    { lat: 48.7382212, lng: 18.6332201 },
    { lat: 48.7382621, lng: 18.6331604 },
    { lat: 48.738304, lng: 18.6331023 },
    { lat: 48.7384784, lng: 18.6328623 },
    { lat: 48.7386233, lng: 18.6327676 },
    { lat: 48.7386138, lng: 18.6326116 },
    { lat: 48.738675, lng: 18.6324694 },
    { lat: 48.7387433, lng: 18.6324192 },
    { lat: 48.7387308, lng: 18.632271 },
    { lat: 48.7389156, lng: 18.6320566 },
    { lat: 48.7388479, lng: 18.6318057 },
    { lat: 48.7390618, lng: 18.6315889 },
    { lat: 48.7391418, lng: 18.6313769 },
    { lat: 48.739271, lng: 18.6311164 },
    { lat: 48.7395108, lng: 18.6309185 },
    { lat: 48.7395775, lng: 18.6309219 },
    { lat: 48.739711, lng: 18.630761 },
    { lat: 48.7398737, lng: 18.6307028 },
    { lat: 48.7399051, lng: 18.6306169 },
    { lat: 48.7400104, lng: 18.63054 },
    { lat: 48.7399952, lng: 18.630382 },
    { lat: 48.740042, lng: 18.6302454 },
    { lat: 48.7403057, lng: 18.6301555 },
    { lat: 48.7405378, lng: 18.6299022 },
    { lat: 48.7405181, lng: 18.6296192 },
    { lat: 48.7406305, lng: 18.6294655 },
    { lat: 48.7407187, lng: 18.6291954 },
    { lat: 48.7407767, lng: 18.629204 },
    { lat: 48.7408245, lng: 18.629211 },
    { lat: 48.7408449, lng: 18.6292136 },
    { lat: 48.7408569, lng: 18.6291852 },
    { lat: 48.7409856, lng: 18.6288919 },
    { lat: 48.7410739, lng: 18.6286904 },
    { lat: 48.741212, lng: 18.6284062 },
    { lat: 48.7413597, lng: 18.6280475 },
    { lat: 48.741359, lng: 18.628034 },
    { lat: 48.741354, lng: 18.6279829 },
    { lat: 48.7413532, lng: 18.6279693 },
    { lat: 48.7414761, lng: 18.6279688 },
    { lat: 48.7415668, lng: 18.6279352 },
    { lat: 48.7416582, lng: 18.6278718 },
    { lat: 48.7417387, lng: 18.6277758 },
    { lat: 48.7417903, lng: 18.6276654 },
    { lat: 48.7418218, lng: 18.6275801 },
    { lat: 48.7418477, lng: 18.6274747 },
    { lat: 48.7418698, lng: 18.627425 },
    { lat: 48.7418979, lng: 18.6273989 },
    { lat: 48.7419746, lng: 18.6271909 },
    { lat: 48.7424606, lng: 18.6254275 },
    { lat: 48.7425712, lng: 18.6250507 },
    { lat: 48.7426017, lng: 18.6249678 },
    { lat: 48.742682, lng: 18.6247412 },
    { lat: 48.7427645, lng: 18.624366 },
    { lat: 48.7428469, lng: 18.6239898 },
    { lat: 48.7428622, lng: 18.6238766 },
    { lat: 48.7428863, lng: 18.6236938 },
    { lat: 48.7429022, lng: 18.6235715 },
    { lat: 48.7428908, lng: 18.6235269 },
    { lat: 48.7428626, lng: 18.6234648 },
    { lat: 48.7429564, lng: 18.6234463 },
    { lat: 48.7429533, lng: 18.6234301 },
    { lat: 48.7429416, lng: 18.6233604 },
    { lat: 48.7429383, lng: 18.6233397 },
    { lat: 48.7429389, lng: 18.6233091 },
    { lat: 48.7429415, lng: 18.6232734 },
    { lat: 48.7429523, lng: 18.6231604 },
    { lat: 48.7429722, lng: 18.6230467 },
    { lat: 48.7429928, lng: 18.6229491 },
    { lat: 48.7431069, lng: 18.6225743 },
    { lat: 48.7431596, lng: 18.6224016 },
    { lat: 48.7432711, lng: 18.6221035 },
    { lat: 48.7433369, lng: 18.6219231 },
    { lat: 48.7433973, lng: 18.6217677 },
    { lat: 48.743482, lng: 18.6216022 },
    { lat: 48.7436223, lng: 18.6212533 },
    { lat: 48.743789, lng: 18.6206414 },
    { lat: 48.7438119, lng: 18.6205426 },
    { lat: 48.7438413, lng: 18.620417 },
    { lat: 48.7438487, lng: 18.6203871 },
    { lat: 48.7438779, lng: 18.6202602 },
    { lat: 48.7438934, lng: 18.6201911 },
    { lat: 48.7439089, lng: 18.6201235 },
    { lat: 48.7440445, lng: 18.6197505 },
    { lat: 48.7440809, lng: 18.619652 },
    { lat: 48.7441044, lng: 18.6195852 },
    { lat: 48.7441556, lng: 18.6194468 },
    { lat: 48.7442057, lng: 18.619309 },
    { lat: 48.7442322, lng: 18.619235 },
    { lat: 48.7442656, lng: 18.6191438 },
    { lat: 48.7444768, lng: 18.618562 },
    { lat: 48.7446544, lng: 18.6176284 },
    { lat: 48.745015, lng: 18.6153605 },
    { lat: 48.7457725, lng: 18.6128806 },
    { lat: 48.7461319, lng: 18.6117013 },
    { lat: 48.7469209, lng: 18.6092832 },
    { lat: 48.7474544, lng: 18.6082789 },
    { lat: 48.7473894, lng: 18.6082235 },
    { lat: 48.7472941, lng: 18.6080033 },
    { lat: 48.7472345, lng: 18.6079631 },
    { lat: 48.747054, lng: 18.6078149 },
    { lat: 48.74674, lng: 18.6075148 },
    { lat: 48.7465816, lng: 18.6073617 },
    { lat: 48.7460406, lng: 18.6069088 },
    { lat: 48.7455437, lng: 18.6065183 },
    { lat: 48.7444572, lng: 18.6056369 },
    { lat: 48.7443698, lng: 18.6056238 },
    { lat: 48.744296, lng: 18.6055932 },
    { lat: 48.744201, lng: 18.6054845 },
    { lat: 48.7440737, lng: 18.6053015 },
    { lat: 48.7436455, lng: 18.6049526 },
    { lat: 48.7432711, lng: 18.6046632 },
    { lat: 48.7428473, lng: 18.6043328 },
    { lat: 48.7426582, lng: 18.6042153 },
    { lat: 48.7421611, lng: 18.6039078 },
    { lat: 48.7417006, lng: 18.6036465 },
    { lat: 48.7415976, lng: 18.6035992 },
    { lat: 48.7415092, lng: 18.6035508 },
    { lat: 48.7413805, lng: 18.6034845 },
    { lat: 48.7411961, lng: 18.6032674 },
    { lat: 48.7411249, lng: 18.6032412 },
    { lat: 48.741088, lng: 18.603205 },
    { lat: 48.7408366, lng: 18.6030831 },
    { lat: 48.7407127, lng: 18.603043 },
    { lat: 48.7405424, lng: 18.6030014 },
    { lat: 48.7405103, lng: 18.6030165 },
    { lat: 48.739843, lng: 18.6040872 },
    { lat: 48.7396864, lng: 18.6043209 },
    { lat: 48.7394508, lng: 18.604598 },
    { lat: 48.7393876, lng: 18.6046576 },
    { lat: 48.7391387, lng: 18.604833 },
    { lat: 48.7396564, lng: 18.6053566 },
    { lat: 48.7399107, lng: 18.6056133 },
    { lat: 48.7388473, lng: 18.6075632 },
    { lat: 48.7378809, lng: 18.609409 },
    { lat: 48.737666, lng: 18.6092732 },
    { lat: 48.7373543, lng: 18.6090754 },
    { lat: 48.7372946, lng: 18.6090385 },
    { lat: 48.7372831, lng: 18.6090317 },
    { lat: 48.7372164, lng: 18.6089874 },
    { lat: 48.7369023, lng: 18.6087903 },
    { lat: 48.7368242, lng: 18.6087445 },
    { lat: 48.7367949, lng: 18.6087227 },
    { lat: 48.7367721, lng: 18.6086537 },
    { lat: 48.7367233, lng: 18.6084804 },
    { lat: 48.7366978, lng: 18.6083849 },
    { lat: 48.7361098, lng: 18.6077281 },
    { lat: 48.7360316, lng: 18.6076409 },
    { lat: 48.7357704, lng: 18.6073565 },
    { lat: 48.73571, lng: 18.6072828 },
    { lat: 48.7352637, lng: 18.607603 },
    { lat: 48.7347658, lng: 18.6079666 },
    { lat: 48.734721, lng: 18.6079121 },
    { lat: 48.7347139, lng: 18.607908 },
    { lat: 48.7347056, lng: 18.607939 },
    { lat: 48.7345601, lng: 18.6080838 },
    { lat: 48.7344961, lng: 18.6081946 },
    { lat: 48.7343846, lng: 18.6082405 },
    { lat: 48.734371, lng: 18.6082593 },
    { lat: 48.7343672, lng: 18.6084831 },
    { lat: 48.734357, lng: 18.6084818 },
    { lat: 48.7342302, lng: 18.6084746 },
    { lat: 48.7341407, lng: 18.6084681 },
    { lat: 48.7341383, lng: 18.6084852 },
    { lat: 48.7338172, lng: 18.608448 },
    { lat: 48.7336982, lng: 18.6084408 },
    { lat: 48.7335208, lng: 18.608268 },
    { lat: 48.7334067, lng: 18.6081598 },
    { lat: 48.7330774, lng: 18.6078471 },
    { lat: 48.7329612, lng: 18.6077378 },
    { lat: 48.7326363, lng: 18.607363 },
    { lat: 48.732585, lng: 18.6073104 },
    { lat: 48.7322957, lng: 18.607047 },
    { lat: 48.732039, lng: 18.6069332 },
    { lat: 48.7313541, lng: 18.6061934 },
    { lat: 48.7313014, lng: 18.606137 },
    { lat: 48.7312389, lng: 18.6060696 },
    { lat: 48.731227, lng: 18.606055 },
    { lat: 48.7309854, lng: 18.6058001 },
    { lat: 48.7309543, lng: 18.6057664 },
    { lat: 48.7309208, lng: 18.6057326 },
    { lat: 48.7309065, lng: 18.6057173 },
    { lat: 48.730687, lng: 18.6060208 },
    { lat: 48.7305683, lng: 18.6062431 },
    { lat: 48.7305488, lng: 18.6062794 },
    { lat: 48.7305116, lng: 18.6063447 },
    { lat: 48.7304622, lng: 18.6064319 },
    { lat: 48.7304448, lng: 18.6064637 },
    { lat: 48.7304059, lng: 18.6065803 },
    { lat: 48.7303683, lng: 18.6066968 },
    { lat: 48.7303217, lng: 18.6068372 },
    { lat: 48.7301471, lng: 18.6070481 },
    { lat: 48.7300109, lng: 18.6071796 },
    { lat: 48.7298322, lng: 18.6074027 },
    { lat: 48.7296524, lng: 18.607705 },
    { lat: 48.7296297, lng: 18.6077442 },
    { lat: 48.7295398, lng: 18.6078789 },
    { lat: 48.7294271, lng: 18.608129 },
    { lat: 48.7293604, lng: 18.6082294 },
    { lat: 48.7292534, lng: 18.6084626 },
    { lat: 48.7291798, lng: 18.6085621 },
    { lat: 48.7290832, lng: 18.6087162 },
    { lat: 48.7290091, lng: 18.6088718 },
    { lat: 48.728984, lng: 18.6090335 },
    { lat: 48.7289563, lng: 18.6090848 },
    { lat: 48.7288113, lng: 18.6093034 },
    { lat: 48.7287492, lng: 18.6095319 },
    { lat: 48.7285932, lng: 18.6097762 },
    { lat: 48.7283758, lng: 18.6099698 },
    { lat: 48.728255, lng: 18.6103655 },
    { lat: 48.7281579, lng: 18.6110105 },
    { lat: 48.7280201, lng: 18.611299 },
    { lat: 48.7279674, lng: 18.6117253 },
    { lat: 48.7278959, lng: 18.611907 },
    { lat: 48.7277176, lng: 18.6120497 },
    { lat: 48.7276501, lng: 18.6121788 },
    { lat: 48.7276287, lng: 18.612598 },
    { lat: 48.7275506, lng: 18.6128445 },
    { lat: 48.7274907, lng: 18.6129051 },
    { lat: 48.727388, lng: 18.6127973 },
    { lat: 48.7273187, lng: 18.6128098 },
    { lat: 48.7272174, lng: 18.6129826 },
    { lat: 48.7271303, lng: 18.6132477 },
    { lat: 48.7269932, lng: 18.6133443 },
    { lat: 48.7269219, lng: 18.6134645 },
    { lat: 48.726932, lng: 18.6137116 },
    { lat: 48.7268985, lng: 18.6137836 },
    { lat: 48.726718, lng: 18.6138247 },
    { lat: 48.7266025, lng: 18.6139175 },
    { lat: 48.726516, lng: 18.6142215 },
    { lat: 48.7264477, lng: 18.6143746 },
    { lat: 48.7262828, lng: 18.614432 },
    { lat: 48.7261593, lng: 18.6149048 },
    { lat: 48.7259103, lng: 18.6151827 },
    { lat: 48.7258086, lng: 18.6153871 },
    { lat: 48.7257853, lng: 18.6155221 },
    { lat: 48.7256619, lng: 18.6157002 },
    { lat: 48.7254965, lng: 18.6157105 },
    { lat: 48.7254076, lng: 18.6160044 },
    { lat: 48.7253258, lng: 18.6160991 },
    { lat: 48.7252717, lng: 18.6160386 },
    { lat: 48.7251085, lng: 18.616212 },
    { lat: 48.7250599, lng: 18.616482 },
    { lat: 48.7247376, lng: 18.6167992 },
    { lat: 48.7246182, lng: 18.6168471 },
    { lat: 48.7244868, lng: 18.6169822 },
    { lat: 48.7243833, lng: 18.61707 },
    { lat: 48.7243155, lng: 18.6173173 },
    { lat: 48.7242642, lng: 18.6174133 },
    { lat: 48.7241702, lng: 18.617512 },
    { lat: 48.7241232, lng: 18.6177271 },
    { lat: 48.7240044, lng: 18.6178693 },
    { lat: 48.7239078, lng: 18.6182923 },
    { lat: 48.7239675, lng: 18.6184175 },
    { lat: 48.7238488, lng: 18.61875 },
    { lat: 48.7236012, lng: 18.6189445 },
    { lat: 48.7234302, lng: 18.6190777 },
    { lat: 48.723284, lng: 18.6191939 },
    { lat: 48.7232604, lng: 18.6191739 },
    { lat: 48.7232274, lng: 18.619148 },
    { lat: 48.7231955, lng: 18.6191229 },
    { lat: 48.722574, lng: 18.6185532 },
    { lat: 48.7222742, lng: 18.6182879 },
    { lat: 48.7215989, lng: 18.6176865 },
    { lat: 48.7203374, lng: 18.6165644 },
    { lat: 48.7202627, lng: 18.6164985 },
    { lat: 48.719975, lng: 18.6162425 },
    { lat: 48.7191224, lng: 18.6154915 },
    { lat: 48.718672, lng: 18.6150898 },
    { lat: 48.7185704, lng: 18.6150019 },
    { lat: 48.7184175, lng: 18.6148657 },
    { lat: 48.717957, lng: 18.6144605 },
    { lat: 48.7177776, lng: 18.614294 },
    { lat: 48.7166492, lng: 18.6132574 },
    { lat: 48.716576, lng: 18.6131982 },
    { lat: 48.7153921, lng: 18.6123867 },
    { lat: 48.71511, lng: 18.6121954 },
    { lat: 48.7138799, lng: 18.6113696 },
    { lat: 48.7138135, lng: 18.6113289 },
    { lat: 48.7134036, lng: 18.6110486 },
    { lat: 48.7133779, lng: 18.6110311 },
    { lat: 48.7132925, lng: 18.6109784 },
    { lat: 48.7132688, lng: 18.6109542 },
    { lat: 48.7132282, lng: 18.6109113 },
    { lat: 48.7132681, lng: 18.6111296 },
    { lat: 48.7132793, lng: 18.6112536 },
    { lat: 48.7132984, lng: 18.6113148 },
    { lat: 48.7133043, lng: 18.6113626 },
    { lat: 48.7132551, lng: 18.6115166 },
    { lat: 48.7132397, lng: 18.6116681 },
    { lat: 48.7131089, lng: 18.6118592 },
    { lat: 48.7130456, lng: 18.6119824 },
    { lat: 48.7129913, lng: 18.6121908 },
    { lat: 48.712932, lng: 18.6122828 },
    { lat: 48.7129166, lng: 18.6123922 },
    { lat: 48.7130303, lng: 18.6125757 },
    { lat: 48.7130218, lng: 18.6127128 },
    { lat: 48.7129592, lng: 18.6126994 },
    { lat: 48.7128584, lng: 18.612609 },
    { lat: 48.7127846, lng: 18.6127052 },
    { lat: 48.7128021, lng: 18.6129252 },
    { lat: 48.7126968, lng: 18.6132121 },
    { lat: 48.7125106, lng: 18.6134625 },
    { lat: 48.7123739, lng: 18.6138726 },
    { lat: 48.7122806, lng: 18.6139833 },
    { lat: 48.7121756, lng: 18.6142538 },
    { lat: 48.7121927, lng: 18.6143213 },
    { lat: 48.7121947, lng: 18.6143582 },
    { lat: 48.7120698, lng: 18.6145325 },
    { lat: 48.7119228, lng: 18.6146295 },
    { lat: 48.7118997, lng: 18.6146609 },
    { lat: 48.7119775, lng: 18.6147842 },
    { lat: 48.7118775, lng: 18.6149197 },
    { lat: 48.7118427, lng: 18.6150465 },
    { lat: 48.7119542, lng: 18.6154003 },
    { lat: 48.7118235, lng: 18.6155285 },
    { lat: 48.7117938, lng: 18.6156029 },
    { lat: 48.711881, lng: 18.61574 },
    { lat: 48.7118812, lng: 18.6158268 },
    { lat: 48.7117832, lng: 18.6159731 },
    { lat: 48.7117876, lng: 18.6161601 },
    { lat: 48.7116991, lng: 18.6166534 },
    { lat: 48.7115669, lng: 18.6169411 },
    { lat: 48.7116032, lng: 18.617072 },
    { lat: 48.7115692, lng: 18.6171505 },
    { lat: 48.7113891, lng: 18.6171591 },
    { lat: 48.7113676, lng: 18.6172213 },
    { lat: 48.7114202, lng: 18.6173827 },
    { lat: 48.7113644, lng: 18.6175571 },
    { lat: 48.7112309, lng: 18.6175535 },
    { lat: 48.7111806, lng: 18.6176633 },
    { lat: 48.7112201, lng: 18.6178134 },
    { lat: 48.7111409, lng: 18.6179967 },
    { lat: 48.7111413, lng: 18.618127 },
    { lat: 48.7111681, lng: 18.6182301 },
    { lat: 48.7112739, lng: 18.6184136 },
    { lat: 48.7112787, lng: 18.6184583 },
    { lat: 48.7112986, lng: 18.6185377 },
    { lat: 48.7112957, lng: 18.6186298 },
    { lat: 48.7113262, lng: 18.6187575 },
    { lat: 48.7112944, lng: 18.6189603 },
    { lat: 48.711313, lng: 18.6190945 },
    { lat: 48.7110845, lng: 18.6193147 },
    { lat: 48.7110585, lng: 18.6193772 },
    { lat: 48.7112048, lng: 18.6200177 },
    { lat: 48.7112528, lng: 18.6205143 },
    { lat: 48.711018, lng: 18.6210524 },
    { lat: 48.7107933, lng: 18.6211768 },
    { lat: 48.7107554, lng: 18.6214551 },
    { lat: 48.7106697, lng: 18.6216463 },
    { lat: 48.7105914, lng: 18.6216981 },
    { lat: 48.7105661, lng: 18.6217937 },
    { lat: 48.710424, lng: 18.6218979 },
    { lat: 48.7103716, lng: 18.6220804 },
    { lat: 48.7103991, lng: 18.6223178 },
    { lat: 48.7103566, lng: 18.6224898 },
    { lat: 48.7103472, lng: 18.6226098 },
    { lat: 48.7103214, lng: 18.6226976 },
    { lat: 48.7102519, lng: 18.6226615 },
    { lat: 48.7101469, lng: 18.6230612 },
    { lat: 48.7100718, lng: 18.6232765 },
    { lat: 48.7101863, lng: 18.6234374 },
    { lat: 48.7102935, lng: 18.623501 },
    { lat: 48.7102915, lng: 18.6237352 },
    { lat: 48.7102576, lng: 18.6239619 },
    { lat: 48.7100596, lng: 18.6243722 },
    { lat: 48.7099267, lng: 18.6250025 },
    { lat: 48.7100191, lng: 18.625064 },
    { lat: 48.7101169, lng: 18.6252884 },
    { lat: 48.710041, lng: 18.6254495 },
    { lat: 48.7100811, lng: 18.6257552 },
    { lat: 48.7100189, lng: 18.6258161 },
  ],
  Seč: [
    { lat: 48.8243727, lng: 18.4894195 },
    { lat: 48.824361, lng: 18.4893912 },
    { lat: 48.8242661, lng: 18.4889979 },
    { lat: 48.8241994, lng: 18.4886655 },
    { lat: 48.8241309, lng: 18.4886111 },
    { lat: 48.8239918, lng: 18.4887464 },
    { lat: 48.8239661, lng: 18.4887296 },
    { lat: 48.8239889, lng: 18.4885728 },
    { lat: 48.8240731, lng: 18.4884623 },
    { lat: 48.8241024, lng: 18.4883194 },
    { lat: 48.8240461, lng: 18.4881768 },
    { lat: 48.8240341, lng: 18.4881812 },
    { lat: 48.823904, lng: 18.4883172 },
    { lat: 48.8238143, lng: 18.4882901 },
    { lat: 48.8237498, lng: 18.4883248 },
    { lat: 48.8237067, lng: 18.4884233 },
    { lat: 48.8237067, lng: 18.4885911 },
    { lat: 48.8235908, lng: 18.4886154 },
    { lat: 48.8235169, lng: 18.4885649 },
    { lat: 48.823447, lng: 18.4886056 },
    { lat: 48.8233802, lng: 18.4887221 },
    { lat: 48.8233641, lng: 18.4888437 },
    { lat: 48.8232586, lng: 18.4890148 },
    { lat: 48.8232386, lng: 18.4891452 },
    { lat: 48.8230701, lng: 18.4894255 },
    { lat: 48.8230188, lng: 18.4895802 },
    { lat: 48.8227975, lng: 18.4895977 },
    { lat: 48.8227057, lng: 18.4897556 },
    { lat: 48.8226566, lng: 18.4898248 },
    { lat: 48.8225255, lng: 18.4898993 },
    { lat: 48.8224827, lng: 18.4899047 },
    { lat: 48.8222944, lng: 18.4898239 },
    { lat: 48.8222011, lng: 18.4897475 },
    { lat: 48.8220339, lng: 18.4896991 },
    { lat: 48.8219446, lng: 18.4897816 },
    { lat: 48.8218125, lng: 18.4899196 },
    { lat: 48.8217393, lng: 18.489985 },
    { lat: 48.8214548, lng: 18.490164 },
    { lat: 48.8209371, lng: 18.4905384 },
    { lat: 48.8205787, lng: 18.4904818 },
    { lat: 48.8197687, lng: 18.4902115 },
    { lat: 48.8198024, lng: 18.490601 },
    { lat: 48.8198961, lng: 18.4907671 },
    { lat: 48.8198553, lng: 18.4909666 },
    { lat: 48.8196313, lng: 18.4909779 },
    { lat: 48.8195195, lng: 18.4909738 },
    { lat: 48.8191149, lng: 18.4909187 },
    { lat: 48.8190182, lng: 18.4909259 },
    { lat: 48.8188637, lng: 18.4909235 },
    { lat: 48.8188069, lng: 18.4908977 },
    { lat: 48.8187849, lng: 18.4908285 },
    { lat: 48.8186895, lng: 18.490731 },
    { lat: 48.8185149, lng: 18.4904687 },
    { lat: 48.8184304, lng: 18.4904496 },
    { lat: 48.8183146, lng: 18.4902516 },
    { lat: 48.8182482, lng: 18.4902352 },
    { lat: 48.8182405, lng: 18.4904223 },
    { lat: 48.8182061, lng: 18.4905792 },
    { lat: 48.8182253, lng: 18.4906583 },
    { lat: 48.818175, lng: 18.4909353 },
    { lat: 48.8180315, lng: 18.4913224 },
    { lat: 48.817893, lng: 18.491468 },
    { lat: 48.817792, lng: 18.4918508 },
    { lat: 48.8176948, lng: 18.4922768 },
    { lat: 48.8175913, lng: 18.4926739 },
    { lat: 48.8175067, lng: 18.4929419 },
    { lat: 48.8172395, lng: 18.4932944 },
    { lat: 48.8167987, lng: 18.4937944 },
    { lat: 48.8164076, lng: 18.4942709 },
    { lat: 48.8159115, lng: 18.495018 },
    { lat: 48.8156295, lng: 18.4954271 },
    { lat: 48.8153888, lng: 18.4957699 },
    { lat: 48.8149137, lng: 18.4963611 },
    { lat: 48.8148192, lng: 18.4966339 },
    { lat: 48.8147042, lng: 18.4970453 },
    { lat: 48.8146226, lng: 18.4974328 },
    { lat: 48.8145658, lng: 18.4977721 },
    { lat: 48.814428, lng: 18.4983291 },
    { lat: 48.8143281, lng: 18.4988699 },
    { lat: 48.8142772, lng: 18.4990975 },
    { lat: 48.8139741, lng: 18.4989144 },
    { lat: 48.8136137, lng: 18.4987822 },
    { lat: 48.8135727, lng: 18.4988156 },
    { lat: 48.8133935, lng: 18.4987798 },
    { lat: 48.8133213, lng: 18.4987176 },
    { lat: 48.8132971, lng: 18.4986637 },
    { lat: 48.8131399, lng: 18.4985958 },
    { lat: 48.8130994, lng: 18.4988432 },
    { lat: 48.8130463, lng: 18.4992339 },
    { lat: 48.8129774, lng: 18.4996248 },
    { lat: 48.8129075, lng: 18.5002213 },
    { lat: 48.8128875, lng: 18.5005974 },
    { lat: 48.81278, lng: 18.5016213 },
    { lat: 48.8127317, lng: 18.5025525 },
    { lat: 48.8127294, lng: 18.5028602 },
    { lat: 48.8127172, lng: 18.5034831 },
    { lat: 48.8126864, lng: 18.5042593 },
    { lat: 48.8126951, lng: 18.5046469 },
    { lat: 48.8127122, lng: 18.5051025 },
    { lat: 48.8126623, lng: 18.5056939 },
    { lat: 48.8126329, lng: 18.5059206 },
    { lat: 48.8125777, lng: 18.5061899 },
    { lat: 48.8127334, lng: 18.5063754 },
    { lat: 48.8128432, lng: 18.5064847 },
    { lat: 48.8130172, lng: 18.5065542 },
    { lat: 48.8130984, lng: 18.5065529 },
    { lat: 48.8131952, lng: 18.5065902 },
    { lat: 48.8132775, lng: 18.5066725 },
    { lat: 48.8133497, lng: 18.5067948 },
    { lat: 48.8134881, lng: 18.5070563 },
    { lat: 48.8135735, lng: 18.5071751 },
    { lat: 48.8138272, lng: 18.5073795 },
    { lat: 48.8139917, lng: 18.5073965 },
    { lat: 48.8140549, lng: 18.5073377 },
    { lat: 48.8141775, lng: 18.5073481 },
    { lat: 48.8142714, lng: 18.507375 },
    { lat: 48.814492, lng: 18.5075316 },
    { lat: 48.8147169, lng: 18.5075828 },
    { lat: 48.8150612, lng: 18.5074198 },
    { lat: 48.815165, lng: 18.5073777 },
    { lat: 48.81527, lng: 18.5076076 },
    { lat: 48.815265, lng: 18.5077023 },
    { lat: 48.8152016, lng: 18.5078009 },
    { lat: 48.8152238, lng: 18.5080211 },
    { lat: 48.815301, lng: 18.5080926 },
    { lat: 48.8153822, lng: 18.5080516 },
    { lat: 48.8154215, lng: 18.5080045 },
    { lat: 48.8154722, lng: 18.5080031 },
    { lat: 48.8155071, lng: 18.5080227 },
    { lat: 48.8155321, lng: 18.5080482 },
    { lat: 48.8156192, lng: 18.5079897 },
    { lat: 48.8157455, lng: 18.5080118 },
    { lat: 48.8157723, lng: 18.5080274 },
    { lat: 48.815865, lng: 18.5081743 },
    { lat: 48.8159735, lng: 18.5080745 },
    { lat: 48.8160374, lng: 18.5080689 },
    { lat: 48.8161069, lng: 18.5081025 },
    { lat: 48.8161329, lng: 18.5081407 },
    { lat: 48.8161394, lng: 18.5082441 },
    { lat: 48.816332, lng: 18.508134 },
    { lat: 48.8163187, lng: 18.507995 },
    { lat: 48.8163392, lng: 18.5079556 },
    { lat: 48.8164635, lng: 18.5078985 },
    { lat: 48.8165123, lng: 18.5079224 },
    { lat: 48.8165397, lng: 18.5079515 },
    { lat: 48.8165436, lng: 18.5079797 },
    { lat: 48.8165309, lng: 18.5080364 },
    { lat: 48.8164948, lng: 18.5081204 },
    { lat: 48.8164935, lng: 18.5082605 },
    { lat: 48.8165206, lng: 18.5083247 },
    { lat: 48.8166602, lng: 18.5083792 },
    { lat: 48.8167097, lng: 18.5085236 },
    { lat: 48.8167494, lng: 18.5085656 },
    { lat: 48.8167772, lng: 18.5085814 },
    { lat: 48.8168357, lng: 18.5085959 },
    { lat: 48.8169656, lng: 18.5087457 },
    { lat: 48.8170257, lng: 18.5087081 },
    { lat: 48.8170829, lng: 18.508765 },
    { lat: 48.8171335, lng: 18.5088857 },
    { lat: 48.8171496, lng: 18.5089116 },
    { lat: 48.8173023, lng: 18.5089422 },
    { lat: 48.8174487, lng: 18.5089349 },
    { lat: 48.8175133, lng: 18.5089415 },
    { lat: 48.8175549, lng: 18.5089792 },
    { lat: 48.8176271, lng: 18.5092064 },
    { lat: 48.8177121, lng: 18.5092355 },
    { lat: 48.817745, lng: 18.5092786 },
    { lat: 48.8178095, lng: 18.5092651 },
    { lat: 48.8178685, lng: 18.5093322 },
    { lat: 48.8179408, lng: 18.5093546 },
    { lat: 48.818053, lng: 18.5095088 },
    { lat: 48.8181574, lng: 18.5094975 },
    { lat: 48.8182162, lng: 18.5094615 },
    { lat: 48.8183216, lng: 18.5094923 },
    { lat: 48.8185732, lng: 18.5096977 },
    { lat: 48.8186855, lng: 18.5096668 },
    { lat: 48.8188018, lng: 18.509606 },
    { lat: 48.8189797, lng: 18.5095397 },
    { lat: 48.8191101, lng: 18.509345 },
    { lat: 48.8191346, lng: 18.5093394 },
    { lat: 48.8191691, lng: 18.5093738 },
    { lat: 48.8192261, lng: 18.5095284 },
    { lat: 48.8192913, lng: 18.5096273 },
    { lat: 48.8193317, lng: 18.5096025 },
    { lat: 48.8194004, lng: 18.5097643 },
    { lat: 48.8194419, lng: 18.5098883 },
    { lat: 48.8194912, lng: 18.5099215 },
    { lat: 48.8197331, lng: 18.5098872 },
    { lat: 48.819787, lng: 18.5098622 },
    { lat: 48.8199213, lng: 18.5099495 },
    { lat: 48.8199471, lng: 18.5099456 },
    { lat: 48.8199754, lng: 18.5099266 },
    { lat: 48.8200665, lng: 18.5099023 },
    { lat: 48.8201513, lng: 18.5099225 },
    { lat: 48.8202335, lng: 18.5099827 },
    { lat: 48.8203192, lng: 18.510128 },
    { lat: 48.8204291, lng: 18.5102391 },
    { lat: 48.8205504, lng: 18.5104186 },
    { lat: 48.8205956, lng: 18.5104584 },
    { lat: 48.8207206, lng: 18.5105394 },
    { lat: 48.8208817, lng: 18.5110089 },
    { lat: 48.8209475, lng: 18.5111621 },
    { lat: 48.8209862, lng: 18.5112907 },
    { lat: 48.8210242, lng: 18.5114495 },
    { lat: 48.8210879, lng: 18.51152 },
    { lat: 48.8211741, lng: 18.5116745 },
    { lat: 48.8212358, lng: 18.5117699 },
    { lat: 48.8213252, lng: 18.5119831 },
    { lat: 48.8213612, lng: 18.5121866 },
    { lat: 48.8214241, lng: 18.512265 },
    { lat: 48.8214524, lng: 18.5123283 },
    { lat: 48.8214494, lng: 18.5125213 },
    { lat: 48.8214965, lng: 18.5128032 },
    { lat: 48.8216103, lng: 18.5134007 },
    { lat: 48.8217885, lng: 18.5143695 },
    { lat: 48.8218673, lng: 18.5147785 },
    { lat: 48.8219982, lng: 18.5155034 },
    { lat: 48.8222918, lng: 18.516088 },
    { lat: 48.8225458, lng: 18.5165896 },
    { lat: 48.8226615, lng: 18.5168028 },
    { lat: 48.8227653, lng: 18.5170332 },
    { lat: 48.8228596, lng: 18.5172107 },
    { lat: 48.8231141, lng: 18.517719 },
    { lat: 48.8233313, lng: 18.5181647 },
    { lat: 48.8235288, lng: 18.518557 },
    { lat: 48.8235365, lng: 18.5185866 },
    { lat: 48.8237028, lng: 18.5189168 },
    { lat: 48.8237975, lng: 18.5191011 },
    { lat: 48.8243122, lng: 18.5189829 },
    { lat: 48.8245853, lng: 18.5188807 },
    { lat: 48.8248559, lng: 18.5187534 },
    { lat: 48.8251488, lng: 18.5187457 },
    { lat: 48.8254115, lng: 18.5187264 },
    { lat: 48.8256082, lng: 18.5186709 },
    { lat: 48.8258095, lng: 18.5185992 },
    { lat: 48.8261515, lng: 18.5186029 },
    { lat: 48.8264149, lng: 18.5185312 },
    { lat: 48.8265236, lng: 18.5184958 },
    { lat: 48.8267384, lng: 18.5183994 },
    { lat: 48.8268396, lng: 18.5183957 },
    { lat: 48.8270544, lng: 18.5184465 },
    { lat: 48.8272387, lng: 18.5185365 },
    { lat: 48.8273788, lng: 18.5186872 },
    { lat: 48.8275887, lng: 18.5188514 },
    { lat: 48.8278507, lng: 18.5190445 },
    { lat: 48.8279714, lng: 18.5191063 },
    { lat: 48.8285137, lng: 18.5193291 },
    { lat: 48.8286345, lng: 18.5193737 },
    { lat: 48.8288526, lng: 18.5195655 },
    { lat: 48.8293021, lng: 18.5199467 },
    { lat: 48.8294367, lng: 18.5200566 },
    { lat: 48.8299206, lng: 18.5204689 },
    { lat: 48.8301441, lng: 18.5206178 },
    { lat: 48.8302897, lng: 18.5206833 },
    { lat: 48.8304979, lng: 18.5208381 },
    { lat: 48.8306998, lng: 18.5210863 },
    { lat: 48.8307574, lng: 18.5210673 },
    { lat: 48.8308127, lng: 18.5210675 },
    { lat: 48.8312024, lng: 18.5213467 },
    { lat: 48.8314207, lng: 18.521522 },
    { lat: 48.8314627, lng: 18.5215475 },
    { lat: 48.8318178, lng: 18.5217307 },
    { lat: 48.8322035, lng: 18.5218736 },
    { lat: 48.8322306, lng: 18.521876 },
    { lat: 48.8326193, lng: 18.5218259 },
    { lat: 48.8333742, lng: 18.5217242 },
    { lat: 48.8340965, lng: 18.5218976 },
    { lat: 48.8346213, lng: 18.5224005 },
    { lat: 48.8349432, lng: 18.5227164 },
    { lat: 48.8352542, lng: 18.5232486 },
    { lat: 48.8355531, lng: 18.5237658 },
    { lat: 48.8357556, lng: 18.5253073 },
    { lat: 48.8358013, lng: 18.5256689 },
    { lat: 48.8358391, lng: 18.5269224 },
    { lat: 48.8358557, lng: 18.5273088 },
    { lat: 48.8362009, lng: 18.5279696 },
    { lat: 48.8363514, lng: 18.5282512 },
    { lat: 48.8366573, lng: 18.5288327 },
    { lat: 48.8367211, lng: 18.5290091 },
    { lat: 48.8371053, lng: 18.5299751 },
    { lat: 48.8373564, lng: 18.5306097 },
    { lat: 48.837425, lng: 18.5307483 },
    { lat: 48.83787, lng: 18.5318796 },
    { lat: 48.8382201, lng: 18.532692 },
    { lat: 48.8385497, lng: 18.5334454 },
    { lat: 48.8388599, lng: 18.5341679 },
    { lat: 48.8401406, lng: 18.5370991 },
    { lat: 48.8404775, lng: 18.5378781 },
    { lat: 48.8408239, lng: 18.5386717 },
    { lat: 48.8418103, lng: 18.5386982 },
    { lat: 48.8422855, lng: 18.538704 },
    { lat: 48.8425858, lng: 18.5387116 },
    { lat: 48.8427718, lng: 18.5387707 },
    { lat: 48.8432018, lng: 18.5388986 },
    { lat: 48.8438224, lng: 18.5390639 },
    { lat: 48.8443993, lng: 18.5382093 },
    { lat: 48.84466, lng: 18.5378183 },
    { lat: 48.8452996, lng: 18.5368664 },
    { lat: 48.8458042, lng: 18.5363747 },
    { lat: 48.8464483, lng: 18.5357326 },
    { lat: 48.8469691, lng: 18.5355207 },
    { lat: 48.8474666, lng: 18.535312 },
    { lat: 48.8479114, lng: 18.5351336 },
    { lat: 48.84838, lng: 18.5349364 },
    { lat: 48.8486708, lng: 18.5348066 },
    { lat: 48.8487444, lng: 18.5347097 },
    { lat: 48.849338, lng: 18.533946 },
    { lat: 48.8496137, lng: 18.5335831 },
    { lat: 48.8498509, lng: 18.5333826 },
    { lat: 48.8501189, lng: 18.5331471 },
    { lat: 48.8503582, lng: 18.5329231 },
    { lat: 48.8506133, lng: 18.5326981 },
    { lat: 48.8529454, lng: 18.5295594 },
    { lat: 48.8528025, lng: 18.5291084 },
    { lat: 48.8523468, lng: 18.5277435 },
    { lat: 48.8520211, lng: 18.5267975 },
    { lat: 48.8517554, lng: 18.525999 },
    { lat: 48.8514338, lng: 18.5256672 },
    { lat: 48.8511215, lng: 18.525337 },
    { lat: 48.8500657, lng: 18.524241 },
    { lat: 48.8499361, lng: 18.5240704 },
    { lat: 48.849855, lng: 18.5238752 },
    { lat: 48.8496261, lng: 18.5232968 },
    { lat: 48.849476, lng: 18.5229231 },
    { lat: 48.8493661, lng: 18.5226031 },
    { lat: 48.8491213, lng: 18.5218773 },
    { lat: 48.8489106, lng: 18.521264 },
    { lat: 48.8484184, lng: 18.5198075 },
    { lat: 48.8481451, lng: 18.5190169 },
    { lat: 48.8482613, lng: 18.5180665 },
    { lat: 48.8484356, lng: 18.5166526 },
    { lat: 48.848586, lng: 18.51546 },
    { lat: 48.8477984, lng: 18.5141137 },
    { lat: 48.8474631, lng: 18.5135304 },
    { lat: 48.8472979, lng: 18.513251 },
    { lat: 48.8470401, lng: 18.5128059 },
    { lat: 48.8468119, lng: 18.5124055 },
    { lat: 48.8466151, lng: 18.5120667 },
    { lat: 48.846606, lng: 18.5120245 },
    { lat: 48.8465101, lng: 18.5113868 },
    { lat: 48.8465045, lng: 18.5113227 },
    { lat: 48.8464103, lng: 18.5105076 },
    { lat: 48.84641, lng: 18.5104804 },
    { lat: 48.8464537, lng: 18.5100434 },
    { lat: 48.8465906, lng: 18.5095982 },
    { lat: 48.8466688, lng: 18.5090057 },
    { lat: 48.8466193, lng: 18.5083669 },
    { lat: 48.8465805, lng: 18.5081934 },
    { lat: 48.8464401, lng: 18.5076269 },
    { lat: 48.846412, lng: 18.5075241 },
    { lat: 48.846367, lng: 18.5074058 },
    { lat: 48.8460891, lng: 18.5067599 },
    { lat: 48.8460282, lng: 18.5066135 },
    { lat: 48.8457603, lng: 18.5062747 },
    { lat: 48.8455334, lng: 18.5059601 },
    { lat: 48.8452905, lng: 18.5056024 },
    { lat: 48.8450569, lng: 18.5052521 },
    { lat: 48.8448821, lng: 18.5049199 },
    { lat: 48.8448058, lng: 18.5047662 },
    { lat: 48.8446713, lng: 18.5045327 },
    { lat: 48.8446687, lng: 18.5043823 },
    { lat: 48.8446608, lng: 18.5043399 },
    { lat: 48.8445964, lng: 18.5042127 },
    { lat: 48.8445762, lng: 18.5037242 },
    { lat: 48.844573, lng: 18.5034789 },
    { lat: 48.8446024, lng: 18.5033545 },
    { lat: 48.8446305, lng: 18.5030058 },
    { lat: 48.8446117, lng: 18.5026787 },
    { lat: 48.8445595, lng: 18.5025101 },
    { lat: 48.8445535, lng: 18.502297 },
    { lat: 48.8443847, lng: 18.5019343 },
    { lat: 48.8443078, lng: 18.5016611 },
    { lat: 48.8441626, lng: 18.5014401 },
    { lat: 48.8440102, lng: 18.5014582 },
    { lat: 48.8440076, lng: 18.501475 },
    { lat: 48.8440165, lng: 18.501698 },
    { lat: 48.8439793, lng: 18.501947 },
    { lat: 48.8439426, lng: 18.5020368 },
    { lat: 48.8439103, lng: 18.5022496 },
    { lat: 48.8438562, lng: 18.5025215 },
    { lat: 48.8437726, lng: 18.5028045 },
    { lat: 48.8437469, lng: 18.502957 },
    { lat: 48.8437613, lng: 18.5031762 },
    { lat: 48.8437188, lng: 18.5033685 },
    { lat: 48.8437329, lng: 18.5034824 },
    { lat: 48.8437867, lng: 18.5037227 },
    { lat: 48.8438107, lng: 18.5037903 },
    { lat: 48.8438162, lng: 18.5040761 },
    { lat: 48.8438407, lng: 18.5043004 },
    { lat: 48.8438243, lng: 18.5045968 },
    { lat: 48.8437728, lng: 18.5045614 },
    { lat: 48.8436334, lng: 18.5043039 },
    { lat: 48.8434359, lng: 18.5039734 },
    { lat: 48.8433262, lng: 18.5037397 },
    { lat: 48.8432362, lng: 18.5034552 },
    { lat: 48.8431867, lng: 18.5033161 },
    { lat: 48.8431334, lng: 18.5031007 },
    { lat: 48.8430597, lng: 18.5029299 },
    { lat: 48.8428942, lng: 18.5027311 },
    { lat: 48.8425489, lng: 18.5021084 },
    { lat: 48.8424214, lng: 18.5018827 },
    { lat: 48.8423315, lng: 18.5017432 },
    { lat: 48.842161, lng: 18.5013929 },
    { lat: 48.8420181, lng: 18.5011917 },
    { lat: 48.8418725, lng: 18.5010449 },
    { lat: 48.8416501, lng: 18.5008372 },
    { lat: 48.8414604, lng: 18.5006444 },
    { lat: 48.8412399, lng: 18.5004512 },
    { lat: 48.8411421, lng: 18.5008506 },
    { lat: 48.8409201, lng: 18.5016996 },
    { lat: 48.8406869, lng: 18.5017837 },
    { lat: 48.840547, lng: 18.5018668 },
    { lat: 48.8401315, lng: 18.5020866 },
    { lat: 48.8400546, lng: 18.5021464 },
    { lat: 48.8399649, lng: 18.5021577 },
    { lat: 48.8396768, lng: 18.5020463 },
    { lat: 48.8395249, lng: 18.5020092 },
    { lat: 48.8394701, lng: 18.5020397 },
    { lat: 48.8392676, lng: 18.5019452 },
    { lat: 48.8391198, lng: 18.5019885 },
    { lat: 48.8387517, lng: 18.5018145 },
    { lat: 48.8385523, lng: 18.5016942 },
    { lat: 48.8385013, lng: 18.5016698 },
    { lat: 48.8384333, lng: 18.5016844 },
    { lat: 48.8383533, lng: 18.5016479 },
    { lat: 48.8382701, lng: 18.5016301 },
    { lat: 48.8381571, lng: 18.5015658 },
    { lat: 48.8380816, lng: 18.501585 },
    { lat: 48.8378619, lng: 18.5015707 },
    { lat: 48.8377381, lng: 18.5015146 },
    { lat: 48.8375919, lng: 18.5014829 },
    { lat: 48.8374484, lng: 18.5013523 },
    { lat: 48.8373725, lng: 18.5013271 },
    { lat: 48.8372681, lng: 18.5013155 },
    { lat: 48.8372157, lng: 18.5013257 },
    { lat: 48.8371388, lng: 18.5013633 },
    { lat: 48.8368935, lng: 18.5011477 },
    { lat: 48.8367018, lng: 18.5011514 },
    { lat: 48.8365312, lng: 18.5011227 },
    { lat: 48.8364136, lng: 18.5010562 },
    { lat: 48.836332, lng: 18.5010503 },
    { lat: 48.8360602, lng: 18.500827 },
    { lat: 48.8359409, lng: 18.5007464 },
    { lat: 48.8358584, lng: 18.5005763 },
    { lat: 48.8357653, lng: 18.5005472 },
    { lat: 48.8357333, lng: 18.500476 },
    { lat: 48.8354536, lng: 18.5003973 },
    { lat: 48.835363, lng: 18.5003063 },
    { lat: 48.8352077, lng: 18.5003147 },
    { lat: 48.8350634, lng: 18.5001921 },
    { lat: 48.8349277, lng: 18.5001877 },
    { lat: 48.8348243, lng: 18.4999873 },
    { lat: 48.8346834, lng: 18.4995968 },
    { lat: 48.8346484, lng: 18.499516 },
    { lat: 48.8345832, lng: 18.4994175 },
    { lat: 48.8344676, lng: 18.4994077 },
    { lat: 48.8343981, lng: 18.4993122 },
    { lat: 48.8344061, lng: 18.4992739 },
    { lat: 48.834321, lng: 18.4991185 },
    { lat: 48.8341666, lng: 18.4989148 },
    { lat: 48.8340997, lng: 18.4988069 },
    { lat: 48.8340522, lng: 18.4986603 },
    { lat: 48.8339566, lng: 18.4985416 },
    { lat: 48.8338714, lng: 18.4984489 },
    { lat: 48.8337831, lng: 18.4984209 },
    { lat: 48.8336457, lng: 18.4983197 },
    { lat: 48.8336352, lng: 18.4982956 },
    { lat: 48.8336013, lng: 18.4981312 },
    { lat: 48.8335412, lng: 18.498104 },
    { lat: 48.8333876, lng: 18.4980571 },
    { lat: 48.8332246, lng: 18.4978001 },
    { lat: 48.8332084, lng: 18.4974656 },
    { lat: 48.8331723, lng: 18.497401 },
    { lat: 48.8332182, lng: 18.4972298 },
    { lat: 48.8331588, lng: 18.4970525 },
    { lat: 48.8330955, lng: 18.4970705 },
    { lat: 48.8330111, lng: 18.4968659 },
    { lat: 48.8328787, lng: 18.4967791 },
    { lat: 48.8327533, lng: 18.4965872 },
    { lat: 48.8327488, lng: 18.4965058 },
    { lat: 48.8328597, lng: 18.4963683 },
    { lat: 48.8328092, lng: 18.4962318 },
    { lat: 48.832635, lng: 18.496098 },
    { lat: 48.8326408, lng: 18.4959361 },
    { lat: 48.8326112, lng: 18.4957465 },
    { lat: 48.8323764, lng: 18.495515 },
    { lat: 48.8323192, lng: 18.4954219 },
    { lat: 48.8322767, lng: 18.4951806 },
    { lat: 48.8320586, lng: 18.4949875 },
    { lat: 48.8319894, lng: 18.4949797 },
    { lat: 48.8319444, lng: 18.4949016 },
    { lat: 48.8319105, lng: 18.4948779 },
    { lat: 48.8317779, lng: 18.4948262 },
    { lat: 48.8315653, lng: 18.4946514 },
    { lat: 48.8313929, lng: 18.4943866 },
    { lat: 48.8314564, lng: 18.4941878 },
    { lat: 48.8315845, lng: 18.4941341 },
    { lat: 48.8316046, lng: 18.4939876 },
    { lat: 48.8315968, lng: 18.4939503 },
    { lat: 48.8314225, lng: 18.4936124 },
    { lat: 48.8314273, lng: 18.4935523 },
    { lat: 48.8315094, lng: 18.4934686 },
    { lat: 48.8316105, lng: 18.49325 },
    { lat: 48.8315719, lng: 18.4931682 },
    { lat: 48.8314831, lng: 18.493173 },
    { lat: 48.8312329, lng: 18.4932793 },
    { lat: 48.8309165, lng: 18.4932471 },
    { lat: 48.8304829, lng: 18.4931373 },
    { lat: 48.8301701, lng: 18.4930721 },
    { lat: 48.8291098, lng: 18.4928661 },
    { lat: 48.8289484, lng: 18.4927808 },
    { lat: 48.8281388, lng: 18.4930136 },
    { lat: 48.827341, lng: 18.4932751 },
    { lat: 48.8272434, lng: 18.4934703 },
    { lat: 48.8272207, lng: 18.4933844 },
    { lat: 48.8269895, lng: 18.493305 },
    { lat: 48.8269257, lng: 18.4931286 },
    { lat: 48.8268282, lng: 18.493119 },
    { lat: 48.8266378, lng: 18.492978 },
    { lat: 48.8266366, lng: 18.4928739 },
    { lat: 48.8265053, lng: 18.4928042 },
    { lat: 48.8264015, lng: 18.4927635 },
    { lat: 48.8263403, lng: 18.492449 },
    { lat: 48.8262072, lng: 18.4922649 },
    { lat: 48.8261282, lng: 18.4922419 },
    { lat: 48.826087, lng: 18.4920459 },
    { lat: 48.8260054, lng: 18.4920204 },
    { lat: 48.8259656, lng: 18.4919713 },
    { lat: 48.8259454, lng: 18.4919341 },
    { lat: 48.8258085, lng: 18.4919685 },
    { lat: 48.8257394, lng: 18.491858 },
    { lat: 48.825599, lng: 18.4918725 },
    { lat: 48.8255538, lng: 18.4917024 },
    { lat: 48.8254742, lng: 18.4916905 },
    { lat: 48.8253763, lng: 18.4916341 },
    { lat: 48.8253085, lng: 18.4914887 },
    { lat: 48.8252382, lng: 18.4914227 },
    { lat: 48.8251548, lng: 18.4914826 },
    { lat: 48.8250904, lng: 18.4914202 },
    { lat: 48.8250771, lng: 18.4912994 },
    { lat: 48.8250041, lng: 18.4911404 },
    { lat: 48.8249366, lng: 18.4910211 },
    { lat: 48.8248161, lng: 18.4908404 },
    { lat: 48.8247966, lng: 18.490792 },
    { lat: 48.8248246, lng: 18.490626 },
    { lat: 48.8247237, lng: 18.4903505 },
    { lat: 48.8247129, lng: 18.4902956 },
    { lat: 48.8247185, lng: 18.4902126 },
    { lat: 48.8247448, lng: 18.4901141 },
    { lat: 48.8246193, lng: 18.4899852 },
    { lat: 48.8244903, lng: 18.4897544 },
    { lat: 48.8245054, lng: 18.4896204 },
    { lat: 48.8245052, lng: 18.4895725 },
    { lat: 48.8243727, lng: 18.4894195 },
  ],
  Temeš: [
    { lat: 48.855832, lng: 18.4639343 },
    { lat: 48.8563559, lng: 18.4651337 },
    { lat: 48.856566, lng: 18.4665107 },
    { lat: 48.8560824, lng: 18.4680545 },
    { lat: 48.8559314, lng: 18.4696587 },
    { lat: 48.8559488, lng: 18.4722927 },
    { lat: 48.8556276, lng: 18.4729329 },
    { lat: 48.8564153, lng: 18.4747238 },
    { lat: 48.8567118, lng: 18.4757496 },
    { lat: 48.8567184, lng: 18.4763588 },
    { lat: 48.8573798, lng: 18.4778275 },
    { lat: 48.858751, lng: 18.4798505 },
    { lat: 48.8589054, lng: 18.4799321 },
    { lat: 48.8595929, lng: 18.4802269 },
    { lat: 48.8598693, lng: 18.4802676 },
    { lat: 48.8602195, lng: 18.4802786 },
    { lat: 48.860476, lng: 18.4803688 },
    { lat: 48.8605668, lng: 18.4803778 },
    { lat: 48.8607293, lng: 18.4802982 },
    { lat: 48.8612745, lng: 18.4804071 },
    { lat: 48.8614167, lng: 18.4804927 },
    { lat: 48.8615274, lng: 18.4805963 },
    { lat: 48.8616733, lng: 18.4808308 },
    { lat: 48.8617864, lng: 18.4812454 },
    { lat: 48.8618454, lng: 18.4814389 },
    { lat: 48.8618394, lng: 18.4816338 },
    { lat: 48.8619437, lng: 18.4818285 },
    { lat: 48.8619862, lng: 18.4821291 },
    { lat: 48.8620695, lng: 18.4823951 },
    { lat: 48.8621804, lng: 18.4826269 },
    { lat: 48.8622321, lng: 18.4828712 },
    { lat: 48.8622971, lng: 18.4830701 },
    { lat: 48.8624161, lng: 18.4832442 },
    { lat: 48.862522, lng: 18.4834476 },
    { lat: 48.862947, lng: 18.4838734 },
    { lat: 48.8629716, lng: 18.4839932 },
    { lat: 48.8630671, lng: 18.4841499 },
    { lat: 48.863206, lng: 18.4842331 },
    { lat: 48.8633155, lng: 18.484278 },
    { lat: 48.8633717, lng: 18.4843766 },
    { lat: 48.8634963, lng: 18.4844501 },
    { lat: 48.8636057, lng: 18.4845517 },
    { lat: 48.8638788, lng: 18.4847613 },
    { lat: 48.8639274, lng: 18.4847591 },
    { lat: 48.864169, lng: 18.4848881 },
    { lat: 48.8642808, lng: 18.4849095 },
    { lat: 48.8644412, lng: 18.4850376 },
    { lat: 48.8645345, lng: 18.4851576 },
    { lat: 48.8646624, lng: 18.485287 },
    { lat: 48.864689, lng: 18.4854223 },
    { lat: 48.8647787, lng: 18.4855761 },
    { lat: 48.8649202, lng: 18.485625 },
    { lat: 48.8650571, lng: 18.4856131 },
    { lat: 48.8652029, lng: 18.4857197 },
    { lat: 48.8653351, lng: 18.4858894 },
    { lat: 48.8654524, lng: 18.4860763 },
    { lat: 48.8656839, lng: 18.4864087 },
    { lat: 48.8658487, lng: 18.4865746 },
    { lat: 48.8660916, lng: 18.4868306 },
    { lat: 48.8663906, lng: 18.4874894 },
    { lat: 48.8664566, lng: 18.487767 },
    { lat: 48.8661333, lng: 18.4912953 },
    { lat: 48.8676913, lng: 18.4965273 },
    { lat: 48.8681011, lng: 18.495896 },
    { lat: 48.8686109, lng: 18.4950096 },
    { lat: 48.8686958, lng: 18.4948287 },
    { lat: 48.8691836, lng: 18.4942836 },
    { lat: 48.8692366, lng: 18.4939315 },
    { lat: 48.8695425, lng: 18.4929912 },
    { lat: 48.8698275, lng: 18.4925887 },
    { lat: 48.8702762, lng: 18.4920507 },
    { lat: 48.8709515, lng: 18.4910749 },
    { lat: 48.8712746, lng: 18.4904268 },
    { lat: 48.8718653, lng: 18.4895945 },
    { lat: 48.872151, lng: 18.4894167 },
    { lat: 48.8726626, lng: 18.4889942 },
    { lat: 48.8728793, lng: 18.4889766 },
    { lat: 48.8719045, lng: 18.4862557 },
    { lat: 48.8718606, lng: 18.4855163 },
    { lat: 48.8717958, lng: 18.4847046 },
    { lat: 48.8718006, lng: 18.4843406 },
    { lat: 48.8717033, lng: 18.484092 },
    { lat: 48.8717466, lng: 18.4831112 },
    { lat: 48.8717334, lng: 18.4826463 },
    { lat: 48.8717328, lng: 18.4824669 },
    { lat: 48.8717458, lng: 18.48207 },
    { lat: 48.8717555, lng: 18.4815238 },
    { lat: 48.8717915, lng: 18.4813397 },
    { lat: 48.8717256, lng: 18.4808582 },
    { lat: 48.8717261, lng: 18.4807635 },
    { lat: 48.871647, lng: 18.4802711 },
    { lat: 48.8716774, lng: 18.4800835 },
    { lat: 48.8716954, lng: 18.4799163 },
    { lat: 48.871678, lng: 18.479806 },
    { lat: 48.8717144, lng: 18.4795646 },
    { lat: 48.8718247, lng: 18.4793138 },
    { lat: 48.8719311, lng: 18.4791545 },
    { lat: 48.8719444, lng: 18.4790909 },
    { lat: 48.8719856, lng: 18.4789972 },
    { lat: 48.872141, lng: 18.4788063 },
    { lat: 48.8723702, lng: 18.4786272 },
    { lat: 48.8724187, lng: 18.478563 },
    { lat: 48.8728033, lng: 18.4781726 },
    { lat: 48.8729016, lng: 18.478137 },
    { lat: 48.8729602, lng: 18.4780934 },
    { lat: 48.8729745, lng: 18.4780059 },
    { lat: 48.872918, lng: 18.4778801 },
    { lat: 48.8729528, lng: 18.4777137 },
    { lat: 48.8729726, lng: 18.477455 },
    { lat: 48.8731925, lng: 18.4772273 },
    { lat: 48.8731883, lng: 18.4770903 },
    { lat: 48.8733204, lng: 18.4769471 },
    { lat: 48.8735224, lng: 18.4767044 },
    { lat: 48.8736642, lng: 18.476493 },
    { lat: 48.8737486, lng: 18.4763469 },
    { lat: 48.873767, lng: 18.4762686 },
    { lat: 48.8737855, lng: 18.4760724 },
    { lat: 48.8738427, lng: 18.4760254 },
    { lat: 48.8738541, lng: 18.4759441 },
    { lat: 48.8739425, lng: 18.4758283 },
    { lat: 48.8739856, lng: 18.4757082 },
    { lat: 48.8740813, lng: 18.4756043 },
    { lat: 48.8741951, lng: 18.4752535 },
    { lat: 48.8743063, lng: 18.4751606 },
    { lat: 48.8744131, lng: 18.474949 },
    { lat: 48.8744961, lng: 18.4748811 },
    { lat: 48.8745728, lng: 18.4747973 },
    { lat: 48.8746961, lng: 18.4747233 },
    { lat: 48.8747995, lng: 18.4745902 },
    { lat: 48.8749508, lng: 18.4744715 },
    { lat: 48.8751373, lng: 18.4744142 },
    { lat: 48.8752265, lng: 18.4743352 },
    { lat: 48.875312, lng: 18.4743929 },
    { lat: 48.8753326, lng: 18.4742735 },
    { lat: 48.8754909, lng: 18.4741388 },
    { lat: 48.8756289, lng: 18.4740827 },
    { lat: 48.8758332, lng: 18.4739418 },
    { lat: 48.8759796, lng: 18.4738563 },
    { lat: 48.8762211, lng: 18.4737348 },
    { lat: 48.8763746, lng: 18.4735731 },
    { lat: 48.8764862, lng: 18.4731602 },
    { lat: 48.8765727, lng: 18.4730095 },
    { lat: 48.8766673, lng: 18.4727395 },
    { lat: 48.8767712, lng: 18.4725193 },
    { lat: 48.876875, lng: 18.4723345 },
    { lat: 48.8769382, lng: 18.4721455 },
    { lat: 48.8769558, lng: 18.4718132 },
    { lat: 48.8769759, lng: 18.4717444 },
    { lat: 48.8769859, lng: 18.4717453 },
    { lat: 48.8770229, lng: 18.4716363 },
    { lat: 48.8770376, lng: 18.4714954 },
    { lat: 48.8770649, lng: 18.4714371 },
    { lat: 48.8771608, lng: 18.4713329 },
    { lat: 48.877199, lng: 18.471145 },
    { lat: 48.8773492, lng: 18.4709235 },
    { lat: 48.8774138, lng: 18.4706627 },
    { lat: 48.8774188, lng: 18.4704435 },
    { lat: 48.8773831, lng: 18.4702666 },
    { lat: 48.8773692, lng: 18.4701394 },
    { lat: 48.8774081, lng: 18.4699004 },
    { lat: 48.8773965, lng: 18.4697327 },
    { lat: 48.8774302, lng: 18.4695454 },
    { lat: 48.8774763, lng: 18.4693578 },
    { lat: 48.8775091, lng: 18.4690731 },
    { lat: 48.8775379, lng: 18.4687139 },
    { lat: 48.8775476, lng: 18.468521 },
    { lat: 48.8775153, lng: 18.4683641 },
    { lat: 48.8774133, lng: 18.4680054 },
    { lat: 48.8773826, lng: 18.4678387 },
    { lat: 48.8773815, lng: 18.4677371 },
    { lat: 48.8773559, lng: 18.4675364 },
    { lat: 48.8773268, lng: 18.4674161 },
    { lat: 48.8773334, lng: 18.4672102 },
    { lat: 48.8774049, lng: 18.4670317 },
    { lat: 48.8773926, lng: 18.4667265 },
    { lat: 48.8774145, lng: 18.4665911 },
    { lat: 48.877407, lng: 18.4664379 },
    { lat: 48.8774526, lng: 18.4662781 },
    { lat: 48.8774924, lng: 18.4662236 },
    { lat: 48.8775552, lng: 18.4659902 },
    { lat: 48.8776339, lng: 18.4658448 },
    { lat: 48.8776636, lng: 18.4655841 },
    { lat: 48.8777111, lng: 18.4654204 },
    { lat: 48.8777411, lng: 18.465413 },
    { lat: 48.8778169, lng: 18.4653375 },
    { lat: 48.8778648, lng: 18.4652196 },
    { lat: 48.8778597, lng: 18.4650676 },
    { lat: 48.8779531, lng: 18.4648185 },
    { lat: 48.8780099, lng: 18.4645587 },
    { lat: 48.8780189, lng: 18.4643511 },
    { lat: 48.8780484, lng: 18.4642947 },
    { lat: 48.8780798, lng: 18.4641055 },
    { lat: 48.87812, lng: 18.4640362 },
    { lat: 48.8782178, lng: 18.4636407 },
    { lat: 48.8782377, lng: 18.4635938 },
    { lat: 48.8782557, lng: 18.4634912 },
    { lat: 48.8782309, lng: 18.4633083 },
    { lat: 48.8782309, lng: 18.4632434 },
    { lat: 48.8782739, lng: 18.4630412 },
    { lat: 48.8782571, lng: 18.4629627 },
    { lat: 48.878317, lng: 18.4625136 },
    { lat: 48.8783657, lng: 18.4621713 },
    { lat: 48.8783804, lng: 18.4621289 },
    { lat: 48.8784429, lng: 18.4620347 },
    { lat: 48.8785523, lng: 18.4618076 },
    { lat: 48.8785616, lng: 18.4616713 },
    { lat: 48.8784899, lng: 18.461537 },
    { lat: 48.8784922, lng: 18.4614145 },
    { lat: 48.8785188, lng: 18.4613445 },
    { lat: 48.8785542, lng: 18.4611676 },
    { lat: 48.8785786, lng: 18.4610946 },
    { lat: 48.8786562, lng: 18.4609497 },
    { lat: 48.8786391, lng: 18.4608024 },
    { lat: 48.8786795, lng: 18.4605325 },
    { lat: 48.8787026, lng: 18.4604794 },
    { lat: 48.8786937, lng: 18.4603998 },
    { lat: 48.8786691, lng: 18.460365 },
    { lat: 48.878667, lng: 18.4602839 },
    { lat: 48.8786105, lng: 18.4601799 },
    { lat: 48.8785902, lng: 18.4600786 },
    { lat: 48.8786373, lng: 18.4599927 },
    { lat: 48.8785581, lng: 18.459701 },
    { lat: 48.8785784, lng: 18.4596185 },
    { lat: 48.8785616, lng: 18.4595593 },
    { lat: 48.8785554, lng: 18.4594239 },
    { lat: 48.8785956, lng: 18.4593382 },
    { lat: 48.878562, lng: 18.4592614 },
    { lat: 48.878505, lng: 18.4585099 },
    { lat: 48.8786056, lng: 18.4581858 },
    { lat: 48.8785862, lng: 18.4580829 },
    { lat: 48.8784691, lng: 18.4580421 },
    { lat: 48.878357, lng: 18.4580366 },
    { lat: 48.8782684, lng: 18.4581059 },
    { lat: 48.8782088, lng: 18.4582127 },
    { lat: 48.8781769, lng: 18.4582483 },
    { lat: 48.8781517, lng: 18.4581977 },
    { lat: 48.8780927, lng: 18.4579887 },
    { lat: 48.8779797, lng: 18.457903 },
    { lat: 48.8779523, lng: 18.458019 },
    { lat: 48.8779342, lng: 18.4580385 },
    { lat: 48.877719, lng: 18.4577368 },
    { lat: 48.8776412, lng: 18.4577574 },
    { lat: 48.8776101, lng: 18.45779 },
    { lat: 48.8774762, lng: 18.4578127 },
    { lat: 48.8772561, lng: 18.4578947 },
    { lat: 48.8769852, lng: 18.4580139 },
    { lat: 48.8768193, lng: 18.4582159 },
    { lat: 48.8765685, lng: 18.4582294 },
    { lat: 48.8764573, lng: 18.4580954 },
    { lat: 48.8762982, lng: 18.4577074 },
    { lat: 48.8761462, lng: 18.4574467 },
    { lat: 48.8760988, lng: 18.4574861 },
    { lat: 48.8760478, lng: 18.4574624 },
    { lat: 48.8756245, lng: 18.4573128 },
    { lat: 48.8754889, lng: 18.4575762 },
    { lat: 48.8753291, lng: 18.45758 },
    { lat: 48.8753337, lng: 18.4577883 },
    { lat: 48.8752765, lng: 18.4580206 },
    { lat: 48.8752136, lng: 18.4581866 },
    { lat: 48.8750959, lng: 18.4582004 },
    { lat: 48.8749605, lng: 18.4582613 },
    { lat: 48.8748447, lng: 18.4581469 },
    { lat: 48.8747837, lng: 18.4581679 },
    { lat: 48.8746858, lng: 18.4582523 },
    { lat: 48.8745863, lng: 18.4582484 },
    { lat: 48.8746775, lng: 18.4577323 },
    { lat: 48.8746058, lng: 18.4576593 },
    { lat: 48.8746746, lng: 18.4573324 },
    { lat: 48.8746553, lng: 18.4572266 },
    { lat: 48.8746375, lng: 18.4570286 },
    { lat: 48.8745116, lng: 18.4569302 },
    { lat: 48.8743822, lng: 18.4569161 },
    { lat: 48.8742425, lng: 18.4570026 },
    { lat: 48.8741528, lng: 18.4571763 },
    { lat: 48.8740991, lng: 18.4573902 },
    { lat: 48.8740452, lng: 18.4576612 },
    { lat: 48.8740142, lng: 18.4578747 },
    { lat: 48.8739574, lng: 18.4579926 },
    { lat: 48.8738438, lng: 18.4580167 },
    { lat: 48.8734236, lng: 18.4578423 },
    { lat: 48.8733285, lng: 18.457734 },
    { lat: 48.8730547, lng: 18.4573938 },
    { lat: 48.8730053, lng: 18.4573384 },
    { lat: 48.8728347, lng: 18.4571891 },
    { lat: 48.8727791, lng: 18.4571843 },
    { lat: 48.872573, lng: 18.4574752 },
    { lat: 48.872496, lng: 18.4574896 },
    { lat: 48.8723561, lng: 18.4574092 },
    { lat: 48.8716596, lng: 18.4567251 },
    { lat: 48.8710678, lng: 18.4560744 },
    { lat: 48.8708237, lng: 18.4560075 },
    { lat: 48.87047, lng: 18.4561175 },
    { lat: 48.8703153, lng: 18.4561762 },
    { lat: 48.8701581, lng: 18.45621 },
    { lat: 48.8700673, lng: 18.4560556 },
    { lat: 48.8699122, lng: 18.4558234 },
    { lat: 48.8697816, lng: 18.4557869 },
    { lat: 48.869417, lng: 18.4556388 },
    { lat: 48.8687147, lng: 18.4550122 },
    { lat: 48.8684269, lng: 18.4548853 },
    { lat: 48.8680539, lng: 18.4548128 },
    { lat: 48.8679152, lng: 18.4548318 },
    { lat: 48.8678243, lng: 18.4549028 },
    { lat: 48.8677076, lng: 18.4550147 },
    { lat: 48.8676326, lng: 18.4551288 },
    { lat: 48.8675175, lng: 18.4553322 },
    { lat: 48.8673064, lng: 18.4557818 },
    { lat: 48.8672077, lng: 18.4558308 },
    { lat: 48.866847, lng: 18.4559166 },
    { lat: 48.8665403, lng: 18.4558379 },
    { lat: 48.8663141, lng: 18.4560357 },
    { lat: 48.8661984, lng: 18.4562259 },
    { lat: 48.8661333, lng: 18.4563972 },
    { lat: 48.8660359, lng: 18.4567563 },
    { lat: 48.8657915, lng: 18.4571732 },
    { lat: 48.8657581, lng: 18.4573042 },
    { lat: 48.8655186, lng: 18.4576681 },
    { lat: 48.8653933, lng: 18.457789 },
    { lat: 48.8652524, lng: 18.4577706 },
    { lat: 48.8651232, lng: 18.4578207 },
    { lat: 48.8650395, lng: 18.4578155 },
    { lat: 48.864789, lng: 18.4576487 },
    { lat: 48.8646219, lng: 18.4574441 },
    { lat: 48.8645242, lng: 18.4573913 },
    { lat: 48.8643172, lng: 18.4573802 },
    { lat: 48.8641992, lng: 18.457469 },
    { lat: 48.8641459, lng: 18.4575474 },
    { lat: 48.8641114, lng: 18.4576385 },
    { lat: 48.8640272, lng: 18.4579121 },
    { lat: 48.8640191, lng: 18.4580317 },
    { lat: 48.8640221, lng: 18.4584555 },
    { lat: 48.8640049, lng: 18.4585939 },
    { lat: 48.8639352, lng: 18.4588635 },
    { lat: 48.8638917, lng: 18.459075 },
    { lat: 48.8638019, lng: 18.4591871 },
    { lat: 48.8636964, lng: 18.4592961 },
    { lat: 48.8635723, lng: 18.4594634 },
    { lat: 48.8634506, lng: 18.4596708 },
    { lat: 48.8632824, lng: 18.4599948 },
    { lat: 48.8630346, lng: 18.460392 },
    { lat: 48.8629714, lng: 18.4605329 },
    { lat: 48.8628565, lng: 18.4606786 },
    { lat: 48.8628148, lng: 18.460681 },
    { lat: 48.8626743, lng: 18.4607912 },
    { lat: 48.8624321, lng: 18.4608788 },
    { lat: 48.8623034, lng: 18.4609406 },
    { lat: 48.8621979, lng: 18.4610342 },
    { lat: 48.8621421, lng: 18.4611229 },
    { lat: 48.862127, lng: 18.4611807 },
    { lat: 48.8621306, lng: 18.4612834 },
    { lat: 48.862193, lng: 18.4613909 },
    { lat: 48.862308, lng: 18.4614742 },
    { lat: 48.8624184, lng: 18.4615304 },
    { lat: 48.8625102, lng: 18.4616435 },
    { lat: 48.8625443, lng: 18.4617477 },
    { lat: 48.8625267, lng: 18.4617983 },
    { lat: 48.8624836, lng: 18.4618342 },
    { lat: 48.8622212, lng: 18.4619896 },
    { lat: 48.8620583, lng: 18.4622026 },
    { lat: 48.8618365, lng: 18.4624374 },
    { lat: 48.8616895, lng: 18.4625333 },
    { lat: 48.8614966, lng: 18.4626355 },
    { lat: 48.8613981, lng: 18.4627536 },
    { lat: 48.8612166, lng: 18.462878 },
    { lat: 48.861102, lng: 18.4629054 },
    { lat: 48.8610363, lng: 18.4628617 },
    { lat: 48.8610128, lng: 18.4627626 },
    { lat: 48.8609923, lng: 18.4625746 },
    { lat: 48.8610622, lng: 18.462225 },
    { lat: 48.8609799, lng: 18.462061 },
    { lat: 48.8608859, lng: 18.4620754 },
    { lat: 48.8608079, lng: 18.4621322 },
    { lat: 48.8607252, lng: 18.4622483 },
    { lat: 48.8606425, lng: 18.4623867 },
    { lat: 48.860605, lng: 18.4624656 },
    { lat: 48.8605228, lng: 18.4627114 },
    { lat: 48.8605099, lng: 18.4627861 },
    { lat: 48.8603059, lng: 18.4631597 },
    { lat: 48.8602173, lng: 18.4633705 },
    { lat: 48.8601191, lng: 18.463471 },
    { lat: 48.859942, lng: 18.4634103 },
    { lat: 48.8595979, lng: 18.4631017 },
    { lat: 48.8591845, lng: 18.462829 },
    { lat: 48.8590925, lng: 18.4627328 },
    { lat: 48.858956, lng: 18.4626544 },
    { lat: 48.8588246, lng: 18.462479 },
    { lat: 48.8586966, lng: 18.4624082 },
    { lat: 48.8583822, lng: 18.4625146 },
    { lat: 48.8581053, lng: 18.4626897 },
    { lat: 48.8578009, lng: 18.4629392 },
    { lat: 48.857511, lng: 18.4631857 },
    { lat: 48.8573479, lng: 18.4631945 },
    { lat: 48.8571412, lng: 18.463168 },
    { lat: 48.8569564, lng: 18.4631119 },
    { lat: 48.8568862, lng: 18.463043 },
    { lat: 48.8567392, lng: 18.4629545 },
    { lat: 48.8565294, lng: 18.463081 },
    { lat: 48.8562856, lng: 18.4633625 },
    { lat: 48.8561365, lng: 18.463505 },
    { lat: 48.855971, lng: 18.4636929 },
    { lat: 48.855832, lng: 18.4639343 },
  ],
  Tužina: [
    { lat: 48.937052, lng: 18.523407 },
    { lat: 48.9368771, lng: 18.5233224 },
    { lat: 48.9365046, lng: 18.5233142 },
    { lat: 48.9360577, lng: 18.5233932 },
    { lat: 48.9353827, lng: 18.5235288 },
    { lat: 48.9347902, lng: 18.5237955 },
    { lat: 48.9347685, lng: 18.5238312 },
    { lat: 48.9345405, lng: 18.5242835 },
    { lat: 48.9342601, lng: 18.5245005 },
    { lat: 48.9331582, lng: 18.5254115 },
    { lat: 48.9329868, lng: 18.5256027 },
    { lat: 48.9328399, lng: 18.5258267 },
    { lat: 48.9327295, lng: 18.5260945 },
    { lat: 48.9326659, lng: 18.5262722 },
    { lat: 48.9325266, lng: 18.5264934 },
    { lat: 48.9316006, lng: 18.5274285 },
    { lat: 48.9312899, lng: 18.527664 },
    { lat: 48.9311305, lng: 18.527802 },
    { lat: 48.9308689, lng: 18.5280558 },
    { lat: 48.9307609, lng: 18.5282473 },
    { lat: 48.9306649, lng: 18.5283669 },
    { lat: 48.9305308, lng: 18.5286614 },
    { lat: 48.9303547, lng: 18.5291961 },
    { lat: 48.9302462, lng: 18.5293776 },
    { lat: 48.9301046, lng: 18.5294728 },
    { lat: 48.9294748, lng: 18.5294663 },
    { lat: 48.9289508, lng: 18.5286678 },
    { lat: 48.9285447, lng: 18.5283536 },
    { lat: 48.9278213, lng: 18.5278312 },
    { lat: 48.9273964, lng: 18.5276706 },
    { lat: 48.9271757, lng: 18.5276551 },
    { lat: 48.9270637, lng: 18.5276905 },
    { lat: 48.9268894, lng: 18.5277224 },
    { lat: 48.9267648, lng: 18.5277968 },
    { lat: 48.9266554, lng: 18.5279411 },
    { lat: 48.926522, lng: 18.5281436 },
    { lat: 48.926225, lng: 18.5284879 },
    { lat: 48.9255247, lng: 18.5290932 },
    { lat: 48.9249318, lng: 18.5292855 },
    { lat: 48.9246593, lng: 18.5292354 },
    { lat: 48.9242635, lng: 18.5289023 },
    { lat: 48.9233791, lng: 18.5284471 },
    { lat: 48.9231311, lng: 18.5283455 },
    { lat: 48.9229397, lng: 18.528373 },
    { lat: 48.9226549, lng: 18.5283 },
    { lat: 48.9223632, lng: 18.5284123 },
    { lat: 48.9222884, lng: 18.5284051 },
    { lat: 48.9219301, lng: 18.5281638 },
    { lat: 48.9216123, lng: 18.5279178 },
    { lat: 48.9210327, lng: 18.5271343 },
    { lat: 48.9208538, lng: 18.526793 },
    { lat: 48.920636, lng: 18.5266026 },
    { lat: 48.9203853, lng: 18.5264526 },
    { lat: 48.9202895, lng: 18.5264513 },
    { lat: 48.9198767, lng: 18.5263926 },
    { lat: 48.9193663, lng: 18.5264218 },
    { lat: 48.9187934, lng: 18.5262357 },
    { lat: 48.918414, lng: 18.526605 },
    { lat: 48.9181985, lng: 18.5266671 },
    { lat: 48.9177678, lng: 18.5270213 },
    { lat: 48.9175072, lng: 18.5272673 },
    { lat: 48.9173224, lng: 18.5275638 },
    { lat: 48.917172, lng: 18.5279083 },
    { lat: 48.9162035, lng: 18.5280368 },
    { lat: 48.9155028, lng: 18.5285123 },
    { lat: 48.9154769, lng: 18.528802 },
    { lat: 48.915441, lng: 18.5292623 },
    { lat: 48.9148277, lng: 18.5302616 },
    { lat: 48.9143306, lng: 18.5305569 },
    { lat: 48.9142915, lng: 18.530565 },
    { lat: 48.9141715, lng: 18.530623 },
    { lat: 48.9140649, lng: 18.5306745 },
    { lat: 48.913578, lng: 18.531448 },
    { lat: 48.9135889, lng: 18.5316259 },
    { lat: 48.9133748, lng: 18.5316481 },
    { lat: 48.9132036, lng: 18.5310954 },
    { lat: 48.9129278, lng: 18.5314997 },
    { lat: 48.9129013, lng: 18.5315306 },
    { lat: 48.9121326, lng: 18.5328464 },
    { lat: 48.9116851, lng: 18.5333496 },
    { lat: 48.9115041, lng: 18.5337373 },
    { lat: 48.9113285, lng: 18.5342811 },
    { lat: 48.9110345, lng: 18.5345151 },
    { lat: 48.9102573, lng: 18.5355935 },
    { lat: 48.9100638, lng: 18.5371384 },
    { lat: 48.9100402, lng: 18.5373483 },
    { lat: 48.909679, lng: 18.5382168 },
    { lat: 48.9095845, lng: 18.5384491 },
    { lat: 48.9095229, lng: 18.5385798 },
    { lat: 48.9089451, lng: 18.5391178 },
    { lat: 48.9086929, lng: 18.5394359 },
    { lat: 48.9085413, lng: 18.5398858 },
    { lat: 48.9080952, lng: 18.5415154 },
    { lat: 48.9080094, lng: 18.5416774 },
    { lat: 48.9076959, lng: 18.5417399 },
    { lat: 48.9070863, lng: 18.5415669 },
    { lat: 48.906619, lng: 18.5416655 },
    { lat: 48.9064431, lng: 18.5414781 },
    { lat: 48.906138, lng: 18.541778 },
    { lat: 48.9058521, lng: 18.5423515 },
    { lat: 48.9052057, lng: 18.5427037 },
    { lat: 48.9048077, lng: 18.5436832 },
    { lat: 48.9047781, lng: 18.5442573 },
    { lat: 48.9047855, lng: 18.5448114 },
    { lat: 48.904888, lng: 18.5451188 },
    { lat: 48.9047237, lng: 18.5457065 },
    { lat: 48.9045773, lng: 18.5457962 },
    { lat: 48.9047096, lng: 18.5471113 },
    { lat: 48.9047448, lng: 18.5485905 },
    { lat: 48.9049154, lng: 18.5495143 },
    { lat: 48.9052098, lng: 18.5503449 },
    { lat: 48.9055838, lng: 18.5505471 },
    { lat: 48.9056525, lng: 18.5505843 },
    { lat: 48.9056872, lng: 18.5506614 },
    { lat: 48.9056109, lng: 18.5506892 },
    { lat: 48.9056346, lng: 18.5508989 },
    { lat: 48.905533, lng: 18.5509381 },
    { lat: 48.9054834, lng: 18.5510447 },
    { lat: 48.9055016, lng: 18.5510868 },
    { lat: 48.9054593, lng: 18.5512628 },
    { lat: 48.9054203, lng: 18.5513759 },
    { lat: 48.9053384, lng: 18.5514862 },
    { lat: 48.9051332, lng: 18.5516118 },
    { lat: 48.9050823, lng: 18.5517584 },
    { lat: 48.9047585, lng: 18.5517974 },
    { lat: 48.9046704, lng: 18.5520256 },
    { lat: 48.9046062, lng: 18.5520673 },
    { lat: 48.9044878, lng: 18.5522757 },
    { lat: 48.9043853, lng: 18.5523606 },
    { lat: 48.904268, lng: 18.5526742 },
    { lat: 48.9042666, lng: 18.5527748 },
    { lat: 48.9039932, lng: 18.5533073 },
    { lat: 48.9039968, lng: 18.5534345 },
    { lat: 48.9040222, lng: 18.5534671 },
    { lat: 48.9039898, lng: 18.5536372 },
    { lat: 48.9040478, lng: 18.5538573 },
    { lat: 48.9040095, lng: 18.5540829 },
    { lat: 48.904005, lng: 18.5540863 },
    { lat: 48.9039618, lng: 18.5540361 },
    { lat: 48.9039007, lng: 18.5540943 },
    { lat: 48.9039369, lng: 18.5544089 },
    { lat: 48.9038446, lng: 18.5545993 },
    { lat: 48.9037878, lng: 18.5546548 },
    { lat: 48.9036999, lng: 18.5548242 },
    { lat: 48.9036797, lng: 18.5550763 },
    { lat: 48.9036725, lng: 18.5551087 },
    { lat: 48.9035061, lng: 18.5552223 },
    { lat: 48.9035228, lng: 18.555491 },
    { lat: 48.903483, lng: 18.5557326 },
    { lat: 48.9033608, lng: 18.5557241 },
    { lat: 48.9032137, lng: 18.5559278 },
    { lat: 48.903173, lng: 18.5560057 },
    { lat: 48.9031317, lng: 18.5561205 },
    { lat: 48.9030296, lng: 18.5561064 },
    { lat: 48.9027233, lng: 18.5561371 },
    { lat: 48.9026229, lng: 18.5562604 },
    { lat: 48.9025688, lng: 18.5562938 },
    { lat: 48.9024623, lng: 18.5563596 },
    { lat: 48.9023543, lng: 18.5565287 },
    { lat: 48.9023115, lng: 18.5565333 },
    { lat: 48.9022026, lng: 18.5564996 },
    { lat: 48.9021568, lng: 18.5564447 },
    { lat: 48.9021331, lng: 18.5564261 },
    { lat: 48.9020672, lng: 18.5564351 },
    { lat: 48.9020319, lng: 18.5564502 },
    { lat: 48.9019259, lng: 18.5565355 },
    { lat: 48.9017694, lng: 18.5567493 },
    { lat: 48.9017142, lng: 18.556768 },
    { lat: 48.9015779, lng: 18.5566922 },
    { lat: 48.9014827, lng: 18.5567668 },
    { lat: 48.9014531, lng: 18.5568218 },
    { lat: 48.9014004, lng: 18.5568703 },
    { lat: 48.901306, lng: 18.556877 },
    { lat: 48.9012601, lng: 18.5568193 },
    { lat: 48.9009768, lng: 18.5567958 },
    { lat: 48.9008592, lng: 18.5568313 },
    { lat: 48.9006912, lng: 18.5568121 },
    { lat: 48.9005532, lng: 18.5568472 },
    { lat: 48.9004839, lng: 18.5567322 },
    { lat: 48.9004219, lng: 18.5566524 },
    { lat: 48.9002592, lng: 18.5566243 },
    { lat: 48.9001808, lng: 18.5565773 },
    { lat: 48.8999199, lng: 18.556421 },
    { lat: 48.8998698, lng: 18.5563688 },
    { lat: 48.8997614, lng: 18.5563669 },
    { lat: 48.8996043, lng: 18.5564249 },
    { lat: 48.899508, lng: 18.5563752 },
    { lat: 48.8994313, lng: 18.5563554 },
    { lat: 48.8992728, lng: 18.5562386 },
    { lat: 48.8991703, lng: 18.5562417 },
    { lat: 48.8989339, lng: 18.5563314 },
    { lat: 48.898839, lng: 18.5563945 },
    { lat: 48.898662, lng: 18.5564166 },
    { lat: 48.898615, lng: 18.5564004 },
    { lat: 48.8985367, lng: 18.5563734 },
    { lat: 48.8984777, lng: 18.5563883 },
    { lat: 48.8983717, lng: 18.5564506 },
    { lat: 48.8981841, lng: 18.5563776 },
    { lat: 48.8981366, lng: 18.5563179 },
    { lat: 48.8980078, lng: 18.5562916 },
    { lat: 48.897926, lng: 18.5561522 },
    { lat: 48.8978961, lng: 18.5561012 },
    { lat: 48.897627, lng: 18.5557157 },
    { lat: 48.8975978, lng: 18.5556006 },
    { lat: 48.8975537, lng: 18.555427 },
    { lat: 48.897533, lng: 18.5553131 },
    { lat: 48.8975137, lng: 18.5552075 },
    { lat: 48.8974653, lng: 18.5551039 },
    { lat: 48.8973551, lng: 18.5550284 },
    { lat: 48.8973099, lng: 18.5549454 },
    { lat: 48.8971518, lng: 18.5547934 },
    { lat: 48.8970891, lng: 18.5547604 },
    { lat: 48.8970622, lng: 18.5547842 },
    { lat: 48.8970522, lng: 18.5548306 },
    { lat: 48.8962778, lng: 18.556189 },
    { lat: 48.8959046, lng: 18.5558796 },
    { lat: 48.895477, lng: 18.5559404 },
    { lat: 48.8950082, lng: 18.5562383 },
    { lat: 48.8947364, lng: 18.5567619 },
    { lat: 48.8943504, lng: 18.5572377 },
    { lat: 48.8939218, lng: 18.5577377 },
    { lat: 48.8938375, lng: 18.5584286 },
    { lat: 48.8936947, lng: 18.5589988 },
    { lat: 48.8925922, lng: 18.5592577 },
    { lat: 48.8921247, lng: 18.5590019 },
    { lat: 48.8919069, lng: 18.5592037 },
    { lat: 48.8921123, lng: 18.5600634 },
    { lat: 48.8917384, lng: 18.5607176 },
    { lat: 48.8913966, lng: 18.5611985 },
    { lat: 48.8910061, lng: 18.5617945 },
    { lat: 48.8907971, lng: 18.5623912 },
    { lat: 48.8906966, lng: 18.5636223 },
    { lat: 48.8895019, lng: 18.5639514 },
    { lat: 48.8889254, lng: 18.5645705 },
    { lat: 48.8884641, lng: 18.5649082 },
    { lat: 48.8884503, lng: 18.5649619 },
    { lat: 48.8883678, lng: 18.5657929 },
    { lat: 48.8883158, lng: 18.5662937 },
    { lat: 48.8882627, lng: 18.5671261 },
    { lat: 48.8884228, lng: 18.5676029 },
    { lat: 48.8887011, lng: 18.5684098 },
    { lat: 48.8890273, lng: 18.5694147 },
    { lat: 48.8892155, lng: 18.5700587 },
    { lat: 48.8888113, lng: 18.5703018 },
    { lat: 48.8888629, lng: 18.5711728 },
    { lat: 48.8887817, lng: 18.5715478 },
    { lat: 48.8885008, lng: 18.572829 },
    { lat: 48.8884805, lng: 18.5729218 },
    { lat: 48.8876675, lng: 18.5732597 },
    { lat: 48.8866069, lng: 18.573149 },
    { lat: 48.8857509, lng: 18.5734507 },
    { lat: 48.8848558, lng: 18.5737663 },
    { lat: 48.8836303, lng: 18.5740895 },
    { lat: 48.8827545, lng: 18.5743944 },
    { lat: 48.8817342, lng: 18.5746094 },
    { lat: 48.8808828, lng: 18.5748431 },
    { lat: 48.8800609, lng: 18.5752226 },
    { lat: 48.8796249, lng: 18.5754202 },
    { lat: 48.8784743, lng: 18.5759838 },
    { lat: 48.8775672, lng: 18.5765832 },
    { lat: 48.8770137, lng: 18.5767988 },
    { lat: 48.8769581, lng: 18.5768097 },
    { lat: 48.8768545, lng: 18.5769788 },
    { lat: 48.8768786, lng: 18.5771757 },
    { lat: 48.8767967, lng: 18.577852 },
    { lat: 48.87672, lng: 18.5782475 },
    { lat: 48.8766347, lng: 18.5788611 },
    { lat: 48.8764443, lng: 18.5787006 },
    { lat: 48.8764367, lng: 18.5786833 },
    { lat: 48.8764111, lng: 18.5788567 },
    { lat: 48.8764245, lng: 18.5790216 },
    { lat: 48.876306, lng: 18.5790026 },
    { lat: 48.8762742, lng: 18.5792449 },
    { lat: 48.8761889, lng: 18.5794866 },
    { lat: 48.8758098, lng: 18.5802338 },
    { lat: 48.8755455, lng: 18.5807887 },
    { lat: 48.8753581, lng: 18.5814992 },
    { lat: 48.8752486, lng: 18.5821237 },
    { lat: 48.8751943, lng: 18.58285 },
    { lat: 48.8752225, lng: 18.5832682 },
    { lat: 48.875233, lng: 18.5835898 },
    { lat: 48.8752101, lng: 18.58377 },
    { lat: 48.8751649, lng: 18.5837913 },
    { lat: 48.8751186, lng: 18.5841009 },
    { lat: 48.8751101, lng: 18.5842356 },
    { lat: 48.8751063, lng: 18.5842949 },
    { lat: 48.8750667, lng: 18.5844053 },
    { lat: 48.8750393, lng: 18.5844815 },
    { lat: 48.875025, lng: 18.5845214 },
    { lat: 48.8749266, lng: 18.5846221 },
    { lat: 48.8749219, lng: 18.5847621 },
    { lat: 48.8749262, lng: 18.5849034 },
    { lat: 48.8749881, lng: 18.5852795 },
    { lat: 48.8749605, lng: 18.5854057 },
    { lat: 48.8749212, lng: 18.5855852 },
    { lat: 48.8749102, lng: 18.5856479 },
    { lat: 48.8748892, lng: 18.585768 },
    { lat: 48.8748912, lng: 18.5858321 },
    { lat: 48.8748973, lng: 18.5860258 },
    { lat: 48.8749015, lng: 18.5861613 },
    { lat: 48.8748399, lng: 18.586318 },
    { lat: 48.8748695, lng: 18.5865107 },
    { lat: 48.8748599, lng: 18.5867114 },
    { lat: 48.8748505, lng: 18.5869086 },
    { lat: 48.8748681, lng: 18.5869312 },
    { lat: 48.874962, lng: 18.5870517 },
    { lat: 48.8748282, lng: 18.587675 },
    { lat: 48.8747176, lng: 18.5881899 },
    { lat: 48.8746834, lng: 18.588765 },
    { lat: 48.8741449, lng: 18.5891366 },
    { lat: 48.8744682, lng: 18.5894821 },
    { lat: 48.8745333, lng: 18.5898742 },
    { lat: 48.874517, lng: 18.590159 },
    { lat: 48.8745607, lng: 18.5903205 },
    { lat: 48.8745248, lng: 18.5908463 },
    { lat: 48.8745503, lng: 18.5914207 },
    { lat: 48.8749838, lng: 18.5922456 },
    { lat: 48.8751414, lng: 18.5925729 },
    { lat: 48.875492, lng: 18.5926373 },
    { lat: 48.8756657, lng: 18.5929924 },
    { lat: 48.8758898, lng: 18.5930664 },
    { lat: 48.8760583, lng: 18.5929309 },
    { lat: 48.8762371, lng: 18.5936734 },
    { lat: 48.8765044, lng: 18.5944067 },
    { lat: 48.8765623, lng: 18.5947482 },
    { lat: 48.876632, lng: 18.5959374 },
    { lat: 48.8766556, lng: 18.595937 },
    { lat: 48.8766307, lng: 18.5961404 },
    { lat: 48.876844, lng: 18.598051 },
    { lat: 48.8767153, lng: 18.5993483 },
    { lat: 48.8763269, lng: 18.6007648 },
    { lat: 48.8763434, lng: 18.6009226 },
    { lat: 48.8759302, lng: 18.6022382 },
    { lat: 48.8756142, lng: 18.6031351 },
    { lat: 48.8755616, lng: 18.6036858 },
    { lat: 48.8755617, lng: 18.6047597 },
    { lat: 48.8754917, lng: 18.6053434 },
    { lat: 48.8754896, lng: 18.6057866 },
    { lat: 48.8756847, lng: 18.6064353 },
    { lat: 48.8760794, lng: 18.6076747 },
    { lat: 48.8763602, lng: 18.6084716 },
    { lat: 48.8766254, lng: 18.609082 },
    { lat: 48.8767536, lng: 18.6095155 },
    { lat: 48.8771464, lng: 18.6107601 },
    { lat: 48.8776094, lng: 18.6121308 },
    { lat: 48.8779411, lng: 18.6128513 },
    { lat: 48.8786668, lng: 18.6148948 },
    { lat: 48.8787194, lng: 18.6150338 },
    { lat: 48.8790081, lng: 18.6157041 },
    { lat: 48.8793263, lng: 18.6165258 },
    { lat: 48.8794715, lng: 18.6169401 },
    { lat: 48.879783, lng: 18.6175351 },
    { lat: 48.8798939, lng: 18.6179189 },
    { lat: 48.8800338, lng: 18.6180464 },
    { lat: 48.8801599, lng: 18.6182953 },
    { lat: 48.8802728, lng: 18.6184652 },
    { lat: 48.8804217, lng: 18.6187385 },
    { lat: 48.8805365, lng: 18.6190278 },
    { lat: 48.8806336, lng: 18.6193233 },
    { lat: 48.8807461, lng: 18.6197362 },
    { lat: 48.8808983, lng: 18.6202205 },
    { lat: 48.881033, lng: 18.6207149 },
    { lat: 48.8811317, lng: 18.6209554 },
    { lat: 48.8812142, lng: 18.6212326 },
    { lat: 48.8812635, lng: 18.6214164 },
    { lat: 48.8813069, lng: 18.6215519 },
    { lat: 48.8814515, lng: 18.6221006 },
    { lat: 48.8815605, lng: 18.622538 },
    { lat: 48.8817122, lng: 18.622798 },
    { lat: 48.8817203, lng: 18.6229708 },
    { lat: 48.8818182, lng: 18.6232623 },
    { lat: 48.8818792, lng: 18.62343 },
    { lat: 48.882051, lng: 18.6236722 },
    { lat: 48.8821168, lng: 18.6237845 },
    { lat: 48.8822186, lng: 18.6240837 },
    { lat: 48.8823659, lng: 18.6242644 },
    { lat: 48.8826182, lng: 18.6249941 },
    { lat: 48.8828308, lng: 18.6249227 },
    { lat: 48.882943, lng: 18.6250748 },
    { lat: 48.8831193, lng: 18.6252742 },
    { lat: 48.8832864, lng: 18.6255686 },
    { lat: 48.8833426, lng: 18.6257129 },
    { lat: 48.8835268, lng: 18.6259532 },
    { lat: 48.8836037, lng: 18.6261057 },
    { lat: 48.8837535, lng: 18.6261224 },
    { lat: 48.8839261, lng: 18.62652 },
    { lat: 48.884506, lng: 18.6278286 },
    { lat: 48.8850049, lng: 18.6282993 },
    { lat: 48.8856353, lng: 18.6300665 },
    { lat: 48.8860237, lng: 18.6312114 },
    { lat: 48.88613, lng: 18.6313864 },
    { lat: 48.8866651, lng: 18.632048 },
    { lat: 48.8870824, lng: 18.6324496 },
    { lat: 48.8871646, lng: 18.6325323 },
    { lat: 48.8873298, lng: 18.6327535 },
    { lat: 48.8876248, lng: 18.6331193 },
    { lat: 48.887788, lng: 18.6329459 },
    { lat: 48.8880456, lng: 18.6334793 },
    { lat: 48.8887068, lng: 18.6340968 },
    { lat: 48.8890019, lng: 18.6347439 },
    { lat: 48.8894769, lng: 18.6354632 },
    { lat: 48.8895267, lng: 18.6358685 },
    { lat: 48.8895551, lng: 18.6358995 },
    { lat: 48.8897133, lng: 18.6355398 },
    { lat: 48.889744, lng: 18.6353984 },
    { lat: 48.8897484, lng: 18.6352072 },
    { lat: 48.8899185, lng: 18.6348425 },
    { lat: 48.8899651, lng: 18.6345576 },
    { lat: 48.8902015, lng: 18.634293 },
    { lat: 48.8902337, lng: 18.6341568 },
    { lat: 48.8903728, lng: 18.6340182 },
    { lat: 48.8906578, lng: 18.6334444 },
    { lat: 48.8906574, lng: 18.633181 },
    { lat: 48.8906111, lng: 18.6327792 },
    { lat: 48.8906517, lng: 18.6325714 },
    { lat: 48.8908339, lng: 18.6324948 },
    { lat: 48.8913478, lng: 18.6313936 },
    { lat: 48.8913928, lng: 18.6312676 },
    { lat: 48.8917051, lng: 18.6310937 },
    { lat: 48.8917787, lng: 18.6309939 },
    { lat: 48.8918383, lng: 18.6309715 },
    { lat: 48.8918609, lng: 18.630887 },
    { lat: 48.891994, lng: 18.6308025 },
    { lat: 48.8922627, lng: 18.6306612 },
    { lat: 48.8922884, lng: 18.6305935 },
    { lat: 48.8923911, lng: 18.630574 },
    { lat: 48.8925113, lng: 18.6306474 },
    { lat: 48.892536, lng: 18.6307293 },
    { lat: 48.8926832, lng: 18.6306976 },
    { lat: 48.8927326, lng: 18.6307778 },
    { lat: 48.8928209, lng: 18.6307444 },
    { lat: 48.8928943, lng: 18.6307468 },
    { lat: 48.8929474, lng: 18.6306629 },
    { lat: 48.8931221, lng: 18.6307023 },
    { lat: 48.8933738, lng: 18.6306415 },
    { lat: 48.8934351, lng: 18.6305239 },
    { lat: 48.8935186, lng: 18.6305022 },
    { lat: 48.8935705, lng: 18.6303994 },
    { lat: 48.893676, lng: 18.6303272 },
    { lat: 48.8937944, lng: 18.6303883 },
    { lat: 48.8940983, lng: 18.6302282 },
    { lat: 48.8940822, lng: 18.6302019 },
    { lat: 48.8945222, lng: 18.6300807 },
    { lat: 48.8946891, lng: 18.6298476 },
    { lat: 48.8948873, lng: 18.6298812 },
    { lat: 48.8950262, lng: 18.6298458 },
    { lat: 48.8950922, lng: 18.6297499 },
    { lat: 48.8954391, lng: 18.6297215 },
    { lat: 48.8955542, lng: 18.6295764 },
    { lat: 48.89569, lng: 18.6295619 },
    { lat: 48.8957768, lng: 18.6294967 },
    { lat: 48.8959376, lng: 18.6294443 },
    { lat: 48.8959521, lng: 18.6293184 },
    { lat: 48.8960259, lng: 18.6292624 },
    { lat: 48.8961544, lng: 18.6292613 },
    { lat: 48.8962807, lng: 18.6294307 },
    { lat: 48.8963295, lng: 18.6294146 },
    { lat: 48.8963682, lng: 18.62944 },
    { lat: 48.8964463, lng: 18.6294475 },
    { lat: 48.8965647, lng: 18.6293623 },
    { lat: 48.8966602, lng: 18.6293993 },
    { lat: 48.8969711, lng: 18.6293262 },
    { lat: 48.8970234, lng: 18.629275 },
    { lat: 48.8970856, lng: 18.6293189 },
    { lat: 48.8971435, lng: 18.6293229 },
    { lat: 48.8972415, lng: 18.6293004 },
    { lat: 48.8973651, lng: 18.6293361 },
    { lat: 48.8975519, lng: 18.6293669 },
    { lat: 48.8979194, lng: 18.6293241 },
    { lat: 48.8979885, lng: 18.6292009 },
    { lat: 48.8980737, lng: 18.6291732 },
    { lat: 48.8983119, lng: 18.6292836 },
    { lat: 48.8984355, lng: 18.6293569 },
    { lat: 48.8985274, lng: 18.6292642 },
    { lat: 48.8986713, lng: 18.6292325 },
    { lat: 48.8987373, lng: 18.6292427 },
    { lat: 48.8988368, lng: 18.6291411 },
    { lat: 48.8988672, lng: 18.6289933 },
    { lat: 48.898963, lng: 18.628846 },
    { lat: 48.899168, lng: 18.6287777 },
    { lat: 48.8992495, lng: 18.6286357 },
    { lat: 48.8993907, lng: 18.6285348 },
    { lat: 48.8994482, lng: 18.628537 },
    { lat: 48.8994941, lng: 18.6284443 },
    { lat: 48.8997857, lng: 18.6281614 },
    { lat: 48.9000151, lng: 18.6281073 },
    { lat: 48.9001607, lng: 18.6281513 },
    { lat: 48.9002977, lng: 18.6280749 },
    { lat: 48.9006036, lng: 18.628081 },
    { lat: 48.900776, lng: 18.6278457 },
    { lat: 48.9008004, lng: 18.627714 },
    { lat: 48.9008341, lng: 18.6276238 },
    { lat: 48.9008443, lng: 18.6274168 },
    { lat: 48.9009906, lng: 18.6271794 },
    { lat: 48.9010061, lng: 18.6270043 },
    { lat: 48.9009403, lng: 18.6268705 },
    { lat: 48.9009558, lng: 18.6267416 },
    { lat: 48.9010838, lng: 18.6266649 },
    { lat: 48.9011336, lng: 18.6265441 },
    { lat: 48.9012407, lng: 18.6264341 },
    { lat: 48.9012978, lng: 18.6263217 },
    { lat: 48.9013829, lng: 18.6262911 },
    { lat: 48.9015176, lng: 18.6261322 },
    { lat: 48.9015159, lng: 18.6260176 },
    { lat: 48.9015947, lng: 18.6259484 },
    { lat: 48.9016916, lng: 18.626011 },
    { lat: 48.9017451, lng: 18.6259994 },
    { lat: 48.9018282, lng: 18.6260759 },
    { lat: 48.9018881, lng: 18.6260352 },
    { lat: 48.9020209, lng: 18.626135 },
    { lat: 48.9021149, lng: 18.6261657 },
    { lat: 48.9023652, lng: 18.6259545 },
    { lat: 48.9024102, lng: 18.6258457 },
    { lat: 48.9025649, lng: 18.6256804 },
    { lat: 48.9026157, lng: 18.6254723 },
    { lat: 48.9027235, lng: 18.6252754 },
    { lat: 48.9027638, lng: 18.6249722 },
    { lat: 48.902736, lng: 18.6247487 },
    { lat: 48.9028943, lng: 18.6244406 },
    { lat: 48.9029521, lng: 18.6242969 },
    { lat: 48.9033142, lng: 18.6241744 },
    { lat: 48.903489, lng: 18.6239822 },
    { lat: 48.9036006, lng: 18.6237931 },
    { lat: 48.9037362, lng: 18.6237578 },
    { lat: 48.9038685, lng: 18.6235934 },
    { lat: 48.9039297, lng: 18.6234518 },
    { lat: 48.9041435, lng: 18.6230852 },
    { lat: 48.9041981, lng: 18.6230307 },
    { lat: 48.9042167, lng: 18.6229324 },
    { lat: 48.9043522, lng: 18.6228561 },
    { lat: 48.9044626, lng: 18.6225564 },
    { lat: 48.9045523, lng: 18.6224432 },
    { lat: 48.9045702, lng: 18.6222316 },
    { lat: 48.904617, lng: 18.6221344 },
    { lat: 48.9046326, lng: 18.6219012 },
    { lat: 48.9046794, lng: 18.6217639 },
    { lat: 48.9047558, lng: 18.6214444 },
    { lat: 48.904837, lng: 18.62142 },
    { lat: 48.9048839, lng: 18.6213475 },
    { lat: 48.9048999, lng: 18.6212265 },
    { lat: 48.9049592, lng: 18.621112 },
    { lat: 48.9050229, lng: 18.6210995 },
    { lat: 48.9050893, lng: 18.6209945 },
    { lat: 48.9052063, lng: 18.6208847 },
    { lat: 48.9053012, lng: 18.6208863 },
    { lat: 48.9056337, lng: 18.6205243 },
    { lat: 48.9056883, lng: 18.6203842 },
    { lat: 48.9058103, lng: 18.6203452 },
    { lat: 48.906113, lng: 18.6199776 },
    { lat: 48.9062297, lng: 18.619965 },
    { lat: 48.9063333, lng: 18.619855 },
    { lat: 48.9064692, lng: 18.6198687 },
    { lat: 48.9065925, lng: 18.6197926 },
    { lat: 48.906694, lng: 18.6197901 },
    { lat: 48.9067744, lng: 18.6197551 },
    { lat: 48.9070251, lng: 18.6197363 },
    { lat: 48.9072396, lng: 18.619679 },
    { lat: 48.9073184, lng: 18.6196761 },
    { lat: 48.9076735, lng: 18.6195892 },
    { lat: 48.9077313, lng: 18.6195516 },
    { lat: 48.9077787, lng: 18.6195558 },
    { lat: 48.907845, lng: 18.6195268 },
    { lat: 48.9080304, lng: 18.6195982 },
    { lat: 48.9081434, lng: 18.6194982 },
    { lat: 48.9082182, lng: 18.6194646 },
    { lat: 48.9082957, lng: 18.6194156 },
    { lat: 48.9086675, lng: 18.6193242 },
    { lat: 48.9088724, lng: 18.6191486 },
    { lat: 48.9091222, lng: 18.6192003 },
    { lat: 48.9092107, lng: 18.619146 },
    { lat: 48.9094917, lng: 18.6191493 },
    { lat: 48.9097268, lng: 18.6191396 },
    { lat: 48.9098444, lng: 18.6191032 },
    { lat: 48.9099481, lng: 18.6191413 },
    { lat: 48.9100136, lng: 18.6191236 },
    { lat: 48.9101562, lng: 18.6190296 },
    { lat: 48.910271, lng: 18.6190233 },
    { lat: 48.9104199, lng: 18.6189178 },
    { lat: 48.9105202, lng: 18.6188749 },
    { lat: 48.9106668, lng: 18.6187708 },
    { lat: 48.910956, lng: 18.6186942 },
    { lat: 48.9112655, lng: 18.6185778 },
    { lat: 48.9113082, lng: 18.6185101 },
    { lat: 48.9114266, lng: 18.6184482 },
    { lat: 48.9114998, lng: 18.6183416 },
    { lat: 48.9115853, lng: 18.6183365 },
    { lat: 48.9121084, lng: 18.6179538 },
    { lat: 48.9122275, lng: 18.6177553 },
    { lat: 48.912242, lng: 18.6177311 },
    { lat: 48.9124376, lng: 18.6176772 },
    { lat: 48.9124507, lng: 18.6176531 },
    { lat: 48.9125247, lng: 18.6175166 },
    { lat: 48.9127811, lng: 18.6174362 },
    { lat: 48.9129305, lng: 18.6171728 },
    { lat: 48.9131342, lng: 18.6168507 },
    { lat: 48.9131534, lng: 18.6166623 },
    { lat: 48.9131933, lng: 18.616602 },
    { lat: 48.913261, lng: 18.6164998 },
    { lat: 48.9133474, lng: 18.6162826 },
    { lat: 48.9134964, lng: 18.6159487 },
    { lat: 48.9137296, lng: 18.6157549 },
    { lat: 48.9138233, lng: 18.6155282 },
    { lat: 48.9139053, lng: 18.6155403 },
    { lat: 48.9140345, lng: 18.6152783 },
    { lat: 48.9142255, lng: 18.6152659 },
    { lat: 48.9142845, lng: 18.6151851 },
    { lat: 48.9144335, lng: 18.6151878 },
    { lat: 48.9145065, lng: 18.6151622 },
    { lat: 48.9145298, lng: 18.615154 },
    { lat: 48.9145605, lng: 18.6152026 },
    { lat: 48.9148404, lng: 18.6153749 },
    { lat: 48.9149593, lng: 18.615362 },
    { lat: 48.9151416, lng: 18.6153954 },
    { lat: 48.9151898, lng: 18.6153292 },
    { lat: 48.9153528, lng: 18.6153177 },
    { lat: 48.9153948, lng: 18.6152598 },
    { lat: 48.9154649, lng: 18.6152239 },
    { lat: 48.9155364, lng: 18.6153348 },
    { lat: 48.915605, lng: 18.615288 },
    { lat: 48.91578, lng: 18.6153119 },
    { lat: 48.9159223, lng: 18.6152721 },
    { lat: 48.915967, lng: 18.6151805 },
    { lat: 48.916095, lng: 18.615065 },
    { lat: 48.9161897, lng: 18.6150461 },
    { lat: 48.9162916, lng: 18.6147997 },
    { lat: 48.9167356, lng: 18.6146039 },
    { lat: 48.9174649, lng: 18.6143216 },
    { lat: 48.9177025, lng: 18.6143984 },
    { lat: 48.9182496, lng: 18.6141059 },
    { lat: 48.9185156, lng: 18.6137037 },
    { lat: 48.918718, lng: 18.6135248 },
    { lat: 48.9190201, lng: 18.6134158 },
    { lat: 48.91932, lng: 18.6134996 },
    { lat: 48.9197613, lng: 18.6133991 },
    { lat: 48.9200759, lng: 18.6134832 },
    { lat: 48.9209765, lng: 18.6133884 },
    { lat: 48.9213207, lng: 18.6129725 },
    { lat: 48.921672, lng: 18.6122696 },
    { lat: 48.9217713, lng: 18.6121196 },
    { lat: 48.9218483, lng: 18.6120233 },
    { lat: 48.9220758, lng: 18.6119557 },
    { lat: 48.9221341, lng: 18.611911 },
    { lat: 48.9223868, lng: 18.6117175 },
    { lat: 48.9225728, lng: 18.6111486 },
    { lat: 48.9229568, lng: 18.6104208 },
    { lat: 48.9230062, lng: 18.6104194 },
    { lat: 48.9230773, lng: 18.6104174 },
    { lat: 48.9232172, lng: 18.6102315 },
    { lat: 48.9239968, lng: 18.609605 },
    { lat: 48.9242839, lng: 18.6094275 },
    { lat: 48.9246242, lng: 18.6091268 },
    { lat: 48.9247209, lng: 18.6088512 },
    { lat: 48.9248105, lng: 18.608711 },
    { lat: 48.9249383, lng: 18.6086575 },
    { lat: 48.9251055, lng: 18.6086415 },
    { lat: 48.9254553, lng: 18.6082016 },
    { lat: 48.9255989, lng: 18.6079758 },
    { lat: 48.9258981, lng: 18.6078168 },
    { lat: 48.9262735, lng: 18.607642 },
    { lat: 48.9267698, lng: 18.6072025 },
    { lat: 48.9269754, lng: 18.607105 },
    { lat: 48.9274413, lng: 18.6071069 },
    { lat: 48.928979, lng: 18.6078897 },
    { lat: 48.929816, lng: 18.608232 },
    { lat: 48.9303659, lng: 18.6086826 },
    { lat: 48.9313104, lng: 18.6091333 },
    { lat: 48.9321962, lng: 18.6087822 },
    { lat: 48.9322831, lng: 18.6087403 },
    { lat: 48.9325757, lng: 18.6087098 },
    { lat: 48.9334514, lng: 18.607902 },
    { lat: 48.9341888, lng: 18.6077469 },
    { lat: 48.9349047, lng: 18.6079101 },
    { lat: 48.9363247, lng: 18.6074492 },
    { lat: 48.9375385, lng: 18.6072638 },
    { lat: 48.9381238, lng: 18.6080624 },
    { lat: 48.9383394, lng: 18.6081696 },
    { lat: 48.9387146, lng: 18.6085411 },
    { lat: 48.9393462, lng: 18.6086671 },
    { lat: 48.9397511, lng: 18.6088563 },
    { lat: 48.9405879, lng: 18.6093608 },
    { lat: 48.9414816, lng: 18.6095782 },
    { lat: 48.9426269, lng: 18.6089962 },
    { lat: 48.9440301, lng: 18.6090648 },
    { lat: 48.9448627, lng: 18.6094684 },
    { lat: 48.9461293, lng: 18.6087339 },
    { lat: 48.9464459, lng: 18.6082649 },
    { lat: 48.9465879, lng: 18.6076305 },
    { lat: 48.94696, lng: 18.6068543 },
    { lat: 48.9470447, lng: 18.6064552 },
    { lat: 48.9474238, lng: 18.6055973 },
    { lat: 48.9474837, lng: 18.605559 },
    { lat: 48.9485617, lng: 18.6050111 },
    { lat: 48.9490198, lng: 18.6051388 },
    { lat: 48.9492467, lng: 18.6051215 },
    { lat: 48.9502245, lng: 18.6055408 },
    { lat: 48.9515861, lng: 18.6054393 },
    { lat: 48.9517984, lng: 18.6054464 },
    { lat: 48.9522331, lng: 18.605178 },
    { lat: 48.9523791, lng: 18.6051466 },
    { lat: 48.9525173, lng: 18.6050136 },
    { lat: 48.9527381, lng: 18.6044824 },
    { lat: 48.9534314, lng: 18.603344 },
    { lat: 48.9539225, lng: 18.6028087 },
    { lat: 48.954578, lng: 18.6022028 },
    { lat: 48.9551312, lng: 18.6015812 },
    { lat: 48.9557263, lng: 18.6012605 },
    { lat: 48.956418, lng: 18.601141 },
    { lat: 48.956502, lng: 18.600355 },
    { lat: 48.956648, lng: 18.597581 },
    { lat: 48.956826, lng: 18.596117 },
    { lat: 48.956796, lng: 18.595104 },
    { lat: 48.956906, lng: 18.59423 },
    { lat: 48.956982, lng: 18.593587 },
    { lat: 48.957019, lng: 18.593374 },
    { lat: 48.957074, lng: 18.593247 },
    { lat: 48.957282, lng: 18.59307 },
    { lat: 48.957626, lng: 18.592709 },
    { lat: 48.957835, lng: 18.592578 },
    { lat: 48.958292, lng: 18.5922 },
    { lat: 48.95888, lng: 18.591915 },
    { lat: 48.95925, lng: 18.591197 },
    { lat: 48.959794, lng: 18.590431 },
    { lat: 48.960523, lng: 18.589976 },
    { lat: 48.9613, lng: 18.589454 },
    { lat: 48.961752, lng: 18.588948 },
    { lat: 48.962707, lng: 18.5885197 },
    { lat: 48.962988, lng: 18.588465 },
    { lat: 48.963544, lng: 18.58773 },
    { lat: 48.964169, lng: 18.587074 },
    { lat: 48.964581, lng: 18.586815 },
    { lat: 48.964869, lng: 18.586762 },
    { lat: 48.965143, lng: 18.586485 },
    { lat: 48.965673, lng: 18.585903 },
    { lat: 48.965996, lng: 18.585404 },
    { lat: 48.966217, lng: 18.58505 },
    { lat: 48.96665, lng: 18.584663 },
    { lat: 48.967149, lng: 18.58445 },
    { lat: 48.96733, lng: 18.584465 },
    { lat: 48.967505, lng: 18.584456 },
    { lat: 48.96779, lng: 18.584763 },
    { lat: 48.968162, lng: 18.584956 },
    { lat: 48.968398, lng: 18.584945 },
    { lat: 48.968901, lng: 18.584555 },
    { lat: 48.969462, lng: 18.58404 },
    { lat: 48.969814, lng: 18.584037 },
    { lat: 48.970194, lng: 18.584034 },
    { lat: 48.970505, lng: 18.584032 },
    { lat: 48.971201, lng: 18.58408 },
    { lat: 48.971452, lng: 18.584113 },
    { lat: 48.97148, lng: 18.584045 },
    { lat: 48.971455, lng: 18.583649 },
    { lat: 48.971415, lng: 18.583212 },
    { lat: 48.971413, lng: 18.582933 },
    { lat: 48.971238, lng: 18.582172 },
    { lat: 48.970982, lng: 18.58084 },
    { lat: 48.970938, lng: 18.580266 },
    { lat: 48.971025, lng: 18.579633 },
    { lat: 48.97123, lng: 18.578799 },
    { lat: 48.9713, lng: 18.578041 },
    { lat: 48.971306, lng: 18.577951 },
    { lat: 48.971267, lng: 18.577743 },
    { lat: 48.971243, lng: 18.577496 },
    { lat: 48.971229, lng: 18.577441 },
    { lat: 48.971184, lng: 18.577292 },
    { lat: 48.970982, lng: 18.576654 },
    { lat: 48.970955, lng: 18.576592 },
    { lat: 48.970716, lng: 18.575979 },
    { lat: 48.970695, lng: 18.575929 },
    { lat: 48.970486, lng: 18.575354 },
    { lat: 48.970406, lng: 18.57515 },
    { lat: 48.970377, lng: 18.574988 },
    { lat: 48.97025, lng: 18.574618 },
    { lat: 48.970028, lng: 18.573932 },
    { lat: 48.969835, lng: 18.573528 },
    { lat: 48.969835, lng: 18.57363 },
    { lat: 48.969278, lng: 18.573385 },
    { lat: 48.968855, lng: 18.57309 },
    { lat: 48.96764, lng: 18.574287 },
    { lat: 48.966688, lng: 18.57394 },
    { lat: 48.966257, lng: 18.572927 },
    { lat: 48.965883, lng: 18.572317 },
    { lat: 48.965415, lng: 18.571152 },
    { lat: 48.965209, lng: 18.570186 },
    { lat: 48.964898, lng: 18.569844 },
    { lat: 48.964509, lng: 18.569402 },
    { lat: 48.963911, lng: 18.568578 },
    { lat: 48.963371, lng: 18.568181 },
    { lat: 48.962508, lng: 18.56702 },
    { lat: 48.961436, lng: 18.565454 },
    { lat: 48.961137, lng: 18.56516 },
    { lat: 48.960807, lng: 18.564823 },
    { lat: 48.960002, lng: 18.564216 },
    { lat: 48.959424, lng: 18.564086 },
    { lat: 48.959258, lng: 18.563719 },
    { lat: 48.958976, lng: 18.563167 },
    { lat: 48.958443, lng: 18.562398 },
    { lat: 48.958144, lng: 18.562126 },
    { lat: 48.957561, lng: 18.561585 },
    { lat: 48.957186, lng: 18.561557 },
    { lat: 48.9567072, lng: 18.5615032 },
    { lat: 48.956279, lng: 18.561291 },
    { lat: 48.955731, lng: 18.560705 },
    { lat: 48.955269, lng: 18.560024 },
    { lat: 48.954807, lng: 18.559746 },
    { lat: 48.954727, lng: 18.559712 },
    { lat: 48.9544969, lng: 18.5598324 },
    { lat: 48.9543697, lng: 18.5599156 },
    { lat: 48.954118, lng: 18.560059 },
    { lat: 48.95408, lng: 18.560083 },
    { lat: 48.954056, lng: 18.560099 },
    { lat: 48.954018, lng: 18.560126 },
    { lat: 48.952651, lng: 18.561184 },
    { lat: 48.952066, lng: 18.561481 },
    { lat: 48.951501, lng: 18.561993 },
    { lat: 48.951191, lng: 18.562344 },
    { lat: 48.95045, lng: 18.561415 },
    { lat: 48.949942, lng: 18.560743 },
    { lat: 48.949335, lng: 18.559806 },
    { lat: 48.948961, lng: 18.558668 },
    { lat: 48.948514, lng: 18.55752 },
    { lat: 48.948258, lng: 18.556843 },
    { lat: 48.948241, lng: 18.556778 },
    { lat: 48.948118, lng: 18.555923 },
    { lat: 48.947961, lng: 18.553697 },
    { lat: 48.947897, lng: 18.552819 },
    { lat: 48.947882, lng: 18.550475 },
    { lat: 48.947865, lng: 18.549856 },
    { lat: 48.947833, lng: 18.548667 },
    { lat: 48.947581, lng: 18.547081 },
    { lat: 48.94752, lng: 18.546624 },
    { lat: 48.9474172, lng: 18.5438526 },
    { lat: 48.947351, lng: 18.542549 },
    { lat: 48.947314, lng: 18.541556 },
    { lat: 48.947255, lng: 18.540757 },
    { lat: 48.947246, lng: 18.540645 },
    { lat: 48.947225, lng: 18.540362 },
    { lat: 48.947219, lng: 18.540286 },
    { lat: 48.94721, lng: 18.54016 },
    { lat: 48.947148, lng: 18.539755 },
    { lat: 48.946755, lng: 18.538535 },
    { lat: 48.946136, lng: 18.5378073 },
    { lat: 48.946083, lng: 18.537745 },
    { lat: 48.945509, lng: 18.536514 },
    { lat: 48.945573, lng: 18.536254 },
    { lat: 48.945305, lng: 18.535589 },
    { lat: 48.944905, lng: 18.534856 },
    { lat: 48.944099, lng: 18.533683 },
    { lat: 48.94399, lng: 18.533167 },
    { lat: 48.943404, lng: 18.532541 },
    { lat: 48.943246, lng: 18.533517 },
    { lat: 48.9431, lng: 18.5335 },
    { lat: 48.943045, lng: 18.533446 },
    { lat: 48.94301, lng: 18.533411 },
    { lat: 48.942798, lng: 18.533201 },
    { lat: 48.942624, lng: 18.533299 },
    { lat: 48.942406, lng: 18.532649 },
    { lat: 48.942059, lng: 18.532158 },
    { lat: 48.941916, lng: 18.532156 },
    { lat: 48.941799, lng: 18.531542 },
    { lat: 48.941235, lng: 18.530067 },
    { lat: 48.940896, lng: 18.529102 },
    { lat: 48.94051, lng: 18.52813 },
    { lat: 48.939924, lng: 18.528192 },
    { lat: 48.939762, lng: 18.52821 },
    { lat: 48.939579, lng: 18.527914 },
    { lat: 48.939255, lng: 18.527393 },
    { lat: 48.939146, lng: 18.527047 },
    { lat: 48.938956, lng: 18.526623 },
    { lat: 48.93838, lng: 18.526005 },
    { lat: 48.938002, lng: 18.525377 },
    { lat: 48.937756, lng: 18.524634 },
    { lat: 48.937513, lng: 18.52408 },
    { lat: 48.93735, lng: 18.523842 },
    { lat: 48.937052, lng: 18.523407 },
  ],
  ValaskáBelá: [
    { lat: 48.935538, lng: 18.384832 },
    { lat: 48.935796, lng: 18.38442 },
    { lat: 48.936185, lng: 18.384256 },
    { lat: 48.936487, lng: 18.384295 },
    { lat: 48.936803, lng: 18.384125 },
    { lat: 48.937057, lng: 18.383705 },
    { lat: 48.937481, lng: 18.382907 },
    { lat: 48.938052, lng: 18.381885 },
    { lat: 48.938245, lng: 18.38166 },
    { lat: 48.938431, lng: 18.381409 },
    { lat: 48.93869, lng: 18.381254 },
    { lat: 48.938985, lng: 18.380911 },
    { lat: 48.938723, lng: 18.380082 },
    { lat: 48.938632, lng: 18.379874 },
    { lat: 48.938428, lng: 18.379312 },
    { lat: 48.938394, lng: 18.37918 },
    { lat: 48.938206, lng: 18.378898 },
    { lat: 48.938044, lng: 18.378276 },
    { lat: 48.937879, lng: 18.378028 },
    { lat: 48.937904, lng: 18.376634 },
    { lat: 48.938347, lng: 18.374511 },
    { lat: 48.938362, lng: 18.374138 },
    { lat: 48.9384, lng: 18.373918 },
    { lat: 48.938432, lng: 18.373458 },
    { lat: 48.938451, lng: 18.373368 },
    { lat: 48.938512, lng: 18.372857 },
    { lat: 48.93862, lng: 18.372373 },
    { lat: 48.938374, lng: 18.371817 },
    { lat: 48.938242, lng: 18.371565 },
    { lat: 48.937758, lng: 18.370308 },
    { lat: 48.937554, lng: 18.370037 },
    { lat: 48.937361, lng: 18.369887 },
    { lat: 48.937241, lng: 18.369718 },
    { lat: 48.93695, lng: 18.369504 },
    { lat: 48.936716, lng: 18.369066 },
    { lat: 48.936562, lng: 18.368974 },
    { lat: 48.936156, lng: 18.368979 },
    { lat: 48.935831, lng: 18.368853 },
    { lat: 48.93492, lng: 18.368612 },
    { lat: 48.934642, lng: 18.368355 },
    { lat: 48.934511, lng: 18.368199 },
    { lat: 48.934374, lng: 18.368075 },
    { lat: 48.934019, lng: 18.367914 },
    { lat: 48.9338, lng: 18.367791 },
    { lat: 48.933425, lng: 18.367707 },
    { lat: 48.933145, lng: 18.367591 },
    { lat: 48.933019, lng: 18.367617 },
    { lat: 48.932917, lng: 18.367666 },
    { lat: 48.932681, lng: 18.367752 },
    { lat: 48.932293, lng: 18.367469 },
    { lat: 48.932274, lng: 18.367455 },
    { lat: 48.932024, lng: 18.366965 },
    { lat: 48.93182, lng: 18.366463 },
    { lat: 48.931333, lng: 18.365779 },
    { lat: 48.930971, lng: 18.36572 },
    { lat: 48.930722, lng: 18.365528 },
    { lat: 48.930348, lng: 18.365164 },
    { lat: 48.930096, lng: 18.364702 },
    { lat: 48.929967, lng: 18.364481 },
    { lat: 48.929859, lng: 18.364315 },
    { lat: 48.929765, lng: 18.364082 },
    { lat: 48.929608, lng: 18.363647 },
    { lat: 48.929437, lng: 18.363742 },
    { lat: 48.929143, lng: 18.362761 },
    { lat: 48.928999, lng: 18.362414 },
    { lat: 48.92856, lng: 18.361679 },
    { lat: 48.927817, lng: 18.360662 },
    { lat: 48.9276592, lng: 18.3603659 },
    { lat: 48.927253, lng: 18.359604 },
    { lat: 48.926941, lng: 18.358977 },
    { lat: 48.926377, lng: 18.3576 },
    { lat: 48.925958, lng: 18.356923 },
    { lat: 48.925776, lng: 18.356553 },
    { lat: 48.925602, lng: 18.356296 },
    { lat: 48.925559, lng: 18.356275 },
    { lat: 48.925349, lng: 18.355958 },
    { lat: 48.925571, lng: 18.355693 },
    { lat: 48.925822, lng: 18.354636 },
    { lat: 48.925921, lng: 18.354489 },
    { lat: 48.926119, lng: 18.354355 },
    { lat: 48.926158, lng: 18.353822 },
    { lat: 48.926057, lng: 18.352426 },
    { lat: 48.926146, lng: 18.352112 },
    { lat: 48.926283, lng: 18.351758 },
    { lat: 48.92656, lng: 18.351073 },
    { lat: 48.926729, lng: 18.350603 },
    { lat: 48.926983, lng: 18.349925 },
    { lat: 48.927139, lng: 18.349559 },
    { lat: 48.927382, lng: 18.349093 },
    { lat: 48.927517, lng: 18.348712 },
    { lat: 48.927798, lng: 18.348438 },
    { lat: 48.927908, lng: 18.348027 },
    { lat: 48.928328, lng: 18.347432 },
    { lat: 48.928555, lng: 18.347285 },
    { lat: 48.928914, lng: 18.346734 },
    { lat: 48.929306, lng: 18.345927 },
    { lat: 48.929577, lng: 18.344881 },
    { lat: 48.929716, lng: 18.344179 },
    { lat: 48.929914, lng: 18.343572 },
    { lat: 48.930191, lng: 18.343092 },
    { lat: 48.93031, lng: 18.341945 },
    { lat: 48.930271, lng: 18.341887 },
    { lat: 48.930263, lng: 18.341702 },
    { lat: 48.930376, lng: 18.341378 },
    { lat: 48.930389, lng: 18.341156 },
    { lat: 48.930567, lng: 18.340522 },
    { lat: 48.930665, lng: 18.339415 },
    { lat: 48.930739, lng: 18.33847 },
    { lat: 48.930729, lng: 18.338303 },
    { lat: 48.930717, lng: 18.338052 },
    { lat: 48.931099, lng: 18.336613 },
    { lat: 48.93113, lng: 18.336473 },
    { lat: 48.931254, lng: 18.336138 },
    { lat: 48.931343, lng: 18.335997 },
    { lat: 48.931592, lng: 18.335663 },
    { lat: 48.931725, lng: 18.335462 },
    { lat: 48.931445, lng: 18.334193 },
    { lat: 48.9312412, lng: 18.333984 },
    { lat: 48.931047, lng: 18.333531 },
    { lat: 48.931061, lng: 18.332277 },
    { lat: 48.930981, lng: 18.332077 },
    { lat: 48.930827, lng: 18.331871 },
    { lat: 48.930321, lng: 18.331204 },
    { lat: 48.930134, lng: 18.33086 },
    { lat: 48.92997, lng: 18.330531 },
    { lat: 48.92956, lng: 18.330258 },
    { lat: 48.929237, lng: 18.330029 },
    { lat: 48.928798, lng: 18.329982 },
    { lat: 48.928403, lng: 18.330056 },
    { lat: 48.927953, lng: 18.329857 },
    { lat: 48.927511, lng: 18.329829 },
    { lat: 48.927334, lng: 18.330017 },
    { lat: 48.92723, lng: 18.330074 },
    { lat: 48.927008, lng: 18.330097 },
    { lat: 48.926949, lng: 18.329945 },
    { lat: 48.92688, lng: 18.3298 },
    { lat: 48.926714, lng: 18.329765 },
    { lat: 48.92652, lng: 18.329924 },
    { lat: 48.926289, lng: 18.32997 },
    { lat: 48.926036, lng: 18.329879 },
    { lat: 48.925695, lng: 18.329788 },
    { lat: 48.92529, lng: 18.329453 },
    { lat: 48.924962, lng: 18.329387 },
    { lat: 48.924908, lng: 18.329376 },
    { lat: 48.924772, lng: 18.329349 },
    { lat: 48.924539, lng: 18.329007 },
    { lat: 48.924165, lng: 18.328458 },
    { lat: 48.923227, lng: 18.327083 },
    { lat: 48.922585, lng: 18.326911 },
    { lat: 48.922306, lng: 18.326881 },
    { lat: 48.92153, lng: 18.326915 },
    { lat: 48.921015, lng: 18.326938 },
    { lat: 48.920435, lng: 18.327014 },
    { lat: 48.919963, lng: 18.32697 },
    { lat: 48.919933, lng: 18.326967 },
    { lat: 48.919932, lng: 18.326966 },
    { lat: 48.919545, lng: 18.326868 },
    { lat: 48.918608, lng: 18.327178 },
    { lat: 48.918607, lng: 18.327178 },
    { lat: 48.91836, lng: 18.327161 },
    { lat: 48.917074, lng: 18.327071 },
    { lat: 48.916161, lng: 18.327308 },
    { lat: 48.914721, lng: 18.328239 },
    { lat: 48.913658, lng: 18.328655 },
    { lat: 48.913325, lng: 18.328779 },
    { lat: 48.912085, lng: 18.329678 },
    { lat: 48.911383, lng: 18.328543 },
    { lat: 48.911377, lng: 18.328534 },
    { lat: 48.911377, lng: 18.328533 },
    { lat: 48.911252, lng: 18.328403 },
    { lat: 48.910965, lng: 18.328103 },
    { lat: 48.910827, lng: 18.327896 },
    { lat: 48.910469, lng: 18.327367 },
    { lat: 48.909297, lng: 18.326296 },
    { lat: 48.909105, lng: 18.326121 },
    { lat: 48.908978, lng: 18.32595 },
    { lat: 48.90895, lng: 18.325912 },
    { lat: 48.907828, lng: 18.324418 },
    { lat: 48.905757, lng: 18.325743 },
    { lat: 48.905467, lng: 18.326043 },
    { lat: 48.905301, lng: 18.326217 },
    { lat: 48.905139, lng: 18.326393 },
    { lat: 48.904984, lng: 18.326708 },
    { lat: 48.90497, lng: 18.32674 },
    { lat: 48.904711, lng: 18.326911 },
    { lat: 48.904655, lng: 18.326947 },
    { lat: 48.904537, lng: 18.326978 },
    { lat: 48.90419, lng: 18.327092 },
    { lat: 48.903948, lng: 18.327248 },
    { lat: 48.90359, lng: 18.327209 },
    { lat: 48.903313, lng: 18.327137 },
    { lat: 48.902935, lng: 18.327336 },
    { lat: 48.902619, lng: 18.327308 },
    { lat: 48.902554, lng: 18.327303 },
    { lat: 48.902552, lng: 18.327303 },
    { lat: 48.90183, lng: 18.327384 },
    { lat: 48.90156, lng: 18.327823 },
    { lat: 48.901284, lng: 18.327892 },
    { lat: 48.900949, lng: 18.327985 },
    { lat: 48.900422, lng: 18.329122 },
    { lat: 48.899863, lng: 18.330262 },
    { lat: 48.899458, lng: 18.330901 },
    { lat: 48.899084, lng: 18.331493 },
    { lat: 48.898699, lng: 18.331975 },
    { lat: 48.898244, lng: 18.332183 },
    { lat: 48.897365, lng: 18.332221 },
    { lat: 48.897001, lng: 18.332263 },
    { lat: 48.896454, lng: 18.332001 },
    { lat: 48.896106, lng: 18.331987 },
    { lat: 48.895796, lng: 18.331999 },
    { lat: 48.895616, lng: 18.332008 },
    { lat: 48.895429, lng: 18.332111 },
    { lat: 48.895262, lng: 18.332517 },
    { lat: 48.894886, lng: 18.332954 },
    { lat: 48.894394, lng: 18.332967 },
    { lat: 48.893797, lng: 18.334126 },
    { lat: 48.893415, lng: 18.335048 },
    { lat: 48.89381, lng: 18.33646 },
    { lat: 48.894414, lng: 18.337159 },
    { lat: 48.894574, lng: 18.339283 },
    { lat: 48.8945221, lng: 18.3394052 },
    { lat: 48.894082, lng: 18.340441 },
    { lat: 48.893983, lng: 18.340672 },
    { lat: 48.893785, lng: 18.341646 },
    { lat: 48.893575, lng: 18.342157 },
    { lat: 48.893169, lng: 18.343141 },
    { lat: 48.892886, lng: 18.343719 },
    { lat: 48.89268, lng: 18.344796 },
    { lat: 48.8926, lng: 18.345895 },
    { lat: 48.892489, lng: 18.346248 },
    { lat: 48.892514, lng: 18.346432 },
    { lat: 48.892546, lng: 18.346867 },
    { lat: 48.892574, lng: 18.347173 },
    { lat: 48.892657, lng: 18.347462 },
    { lat: 48.892658, lng: 18.347784 },
    { lat: 48.892662, lng: 18.348261 },
    { lat: 48.892702, lng: 18.348673 },
    { lat: 48.892588, lng: 18.348844 },
    { lat: 48.892444, lng: 18.349059 },
    { lat: 48.892377, lng: 18.3492549 },
    { lat: 48.8922292, lng: 18.349687 },
    { lat: 48.891681, lng: 18.35129 },
    { lat: 48.891565, lng: 18.351314 },
    { lat: 48.891496, lng: 18.351351 },
    { lat: 48.891408, lng: 18.351345 },
    { lat: 48.891059, lng: 18.351246 },
    { lat: 48.890942, lng: 18.351201 },
    { lat: 48.890734, lng: 18.351171 },
    { lat: 48.890545, lng: 18.351215 },
    { lat: 48.890525, lng: 18.351225 },
    { lat: 48.890421, lng: 18.351183 },
    { lat: 48.890256, lng: 18.351206 },
    { lat: 48.890177, lng: 18.351205 },
    { lat: 48.890135, lng: 18.351221 },
    { lat: 48.889712, lng: 18.351359 },
    { lat: 48.889614, lng: 18.351426 },
    { lat: 48.889428, lng: 18.351541 },
    { lat: 48.889127, lng: 18.351653 },
    { lat: 48.889049, lng: 18.351644 },
    { lat: 48.888991, lng: 18.351576 },
    { lat: 48.888947, lng: 18.351496 },
    { lat: 48.888865, lng: 18.35144 },
    { lat: 48.888568, lng: 18.351481 },
    { lat: 48.888473, lng: 18.351453 },
    { lat: 48.88839, lng: 18.351405 },
    { lat: 48.888351, lng: 18.3513 },
    { lat: 48.887836, lng: 18.351034 },
    { lat: 48.887754, lng: 18.351207 },
    { lat: 48.887729, lng: 18.351345 },
    { lat: 48.887466, lng: 18.351554 },
    { lat: 48.88723, lng: 18.35159 },
    { lat: 48.887123, lng: 18.351744 },
    { lat: 48.887053, lng: 18.351774 },
    { lat: 48.886958, lng: 18.351765 },
    { lat: 48.886796, lng: 18.351829 },
    { lat: 48.884678, lng: 18.352029 },
    { lat: 48.884237, lng: 18.352186 },
    { lat: 48.88409, lng: 18.352179 },
    { lat: 48.883965, lng: 18.352247 },
    { lat: 48.883764, lng: 18.352262 },
    { lat: 48.883514, lng: 18.352198 },
    { lat: 48.883423, lng: 18.352254 },
    { lat: 48.883165, lng: 18.35249 },
    { lat: 48.882121, lng: 18.35329 },
    { lat: 48.881596, lng: 18.353903 },
    { lat: 48.881576, lng: 18.354113 },
    { lat: 48.881599, lng: 18.354284 },
    { lat: 48.88149, lng: 18.354805 },
    { lat: 48.881336, lng: 18.355267 },
    { lat: 48.880893, lng: 18.356133 },
    { lat: 48.880677, lng: 18.356665 },
    { lat: 48.879604, lng: 18.359348 },
    { lat: 48.879179, lng: 18.359829 },
    { lat: 48.8786464, lng: 18.3599876 },
    { lat: 48.878588, lng: 18.360005 },
    { lat: 48.878397, lng: 18.360393 },
    { lat: 48.8781181, lng: 18.3609404 },
    { lat: 48.877969, lng: 18.361233 },
    { lat: 48.877274, lng: 18.362793 },
    { lat: 48.877136, lng: 18.36301 },
    { lat: 48.876968, lng: 18.362911 },
    { lat: 48.876797, lng: 18.362854 },
    { lat: 48.876676, lng: 18.362873 },
    { lat: 48.876329, lng: 18.36281 },
    { lat: 48.876145, lng: 18.362795 },
    { lat: 48.87597, lng: 18.362838 },
    { lat: 48.875773, lng: 18.362975 },
    { lat: 48.875591, lng: 18.362983 },
    { lat: 48.87544, lng: 18.36302 },
    { lat: 48.875173, lng: 18.36295 },
    { lat: 48.874627, lng: 18.362887 },
    { lat: 48.874451, lng: 18.362936 },
    { lat: 48.873836, lng: 18.363563 },
    { lat: 48.873724, lng: 18.363654 },
    { lat: 48.873596, lng: 18.363683 },
    { lat: 48.873477, lng: 18.363759 },
    { lat: 48.873411, lng: 18.363774 },
    { lat: 48.873174, lng: 18.36375 },
    { lat: 48.873093, lng: 18.363747 },
    { lat: 48.873002, lng: 18.363772 },
    { lat: 48.872756, lng: 18.363741 },
    { lat: 48.872624, lng: 18.363777 },
    { lat: 48.872549, lng: 18.363778 },
    { lat: 48.872415, lng: 18.363739 },
    { lat: 48.872356, lng: 18.36369 },
    { lat: 48.872193, lng: 18.363747 },
    { lat: 48.87212, lng: 18.363809 },
    { lat: 48.871919, lng: 18.363801 },
    { lat: 48.871782, lng: 18.363755 },
    { lat: 48.871532, lng: 18.363696 },
    { lat: 48.870865, lng: 18.363583 },
    { lat: 48.870802, lng: 18.363575 },
    { lat: 48.870678, lng: 18.363585 },
    { lat: 48.870563, lng: 18.363553 },
    { lat: 48.870146, lng: 18.363274 },
    { lat: 48.869813, lng: 18.363136 },
    { lat: 48.869189, lng: 18.363122 },
    { lat: 48.868747, lng: 18.36325 },
    { lat: 48.867375, lng: 18.363976 },
    { lat: 48.867334, lng: 18.363997 },
    { lat: 48.86707, lng: 18.364137 },
    { lat: 48.865982, lng: 18.364536 },
    { lat: 48.865777, lng: 18.364532 },
    { lat: 48.86556, lng: 18.364648 },
    { lat: 48.865302, lng: 18.364716 },
    { lat: 48.864715, lng: 18.365097 },
    { lat: 48.864479, lng: 18.365414 },
    { lat: 48.864365, lng: 18.365529 },
    { lat: 48.864375, lng: 18.36555 },
    { lat: 48.864713, lng: 18.365859 },
    { lat: 48.86472, lng: 18.365873 },
    { lat: 48.864717, lng: 18.365995 },
    { lat: 48.864692, lng: 18.366684 },
    { lat: 48.864673, lng: 18.367411 },
    { lat: 48.86471, lng: 18.368686 },
    { lat: 48.864538, lng: 18.369533 },
    { lat: 48.864333, lng: 18.370361 },
    { lat: 48.864192, lng: 18.370954 },
    { lat: 48.864096, lng: 18.371355 },
    { lat: 48.863924, lng: 18.371462 },
    { lat: 48.863582, lng: 18.372156 },
    { lat: 48.863469, lng: 18.372399 },
    { lat: 48.86345, lng: 18.372433 },
    { lat: 48.863436, lng: 18.372523 },
    { lat: 48.863401, lng: 18.372738 },
    { lat: 48.863343, lng: 18.373131 },
    { lat: 48.863252, lng: 18.373712 },
    { lat: 48.863203, lng: 18.374027 },
    { lat: 48.863194, lng: 18.374218 },
    { lat: 48.863197, lng: 18.374251 },
    { lat: 48.863213, lng: 18.374491 },
    { lat: 48.863232, lng: 18.37475 },
    { lat: 48.863248, lng: 18.374968 },
    { lat: 48.863317, lng: 18.375882 },
    { lat: 48.863331, lng: 18.376109 },
    { lat: 48.863002, lng: 18.37752 },
    { lat: 48.862963, lng: 18.378696 },
    { lat: 48.864114, lng: 18.382429 },
    { lat: 48.863805, lng: 18.384813 },
    { lat: 48.863705, lng: 18.386135 },
    { lat: 48.863386, lng: 18.388544 },
    { lat: 48.862543, lng: 18.391137 },
    { lat: 48.862013, lng: 18.392607 },
    { lat: 48.86058, lng: 18.394651 },
    { lat: 48.8600172, lng: 18.3945316 },
    { lat: 48.859435, lng: 18.394408 },
    { lat: 48.858626, lng: 18.394658 },
    { lat: 48.858091, lng: 18.394775 },
    { lat: 48.85778, lng: 18.394965 },
    { lat: 48.857193, lng: 18.395369 },
    { lat: 48.856954, lng: 18.395702 },
    { lat: 48.856542, lng: 18.396156 },
    { lat: 48.856117, lng: 18.396789 },
    { lat: 48.855956, lng: 18.397581 },
    { lat: 48.855715, lng: 18.398436 },
    { lat: 48.855483, lng: 18.399773 },
    { lat: 48.855635, lng: 18.400902 },
    { lat: 48.855961, lng: 18.402045 },
    { lat: 48.856064, lng: 18.402728 },
    { lat: 48.855413, lng: 18.404355 },
    { lat: 48.855122, lng: 18.406632 },
    { lat: 48.855049, lng: 18.407118 },
    { lat: 48.855002, lng: 18.407902 },
    { lat: 48.855056, lng: 18.408499 },
    { lat: 48.855019, lng: 18.408824 },
    { lat: 48.854961, lng: 18.409031 },
    { lat: 48.854683, lng: 18.409325 },
    { lat: 48.854159, lng: 18.409638 },
    { lat: 48.853515, lng: 18.410216 },
    { lat: 48.852761, lng: 18.410696 },
    { lat: 48.85221, lng: 18.410865 },
    { lat: 48.851664, lng: 18.411154 },
    { lat: 48.85121, lng: 18.411428 },
    { lat: 48.850486, lng: 18.412046 },
    { lat: 48.850406, lng: 18.412155 },
    { lat: 48.850164, lng: 18.412568 },
    { lat: 48.849807, lng: 18.413576 },
    { lat: 48.849644, lng: 18.41413 },
    { lat: 48.849191, lng: 18.415463 },
    { lat: 48.849054, lng: 18.416037 },
    { lat: 48.848628, lng: 18.416783 },
    { lat: 48.84867, lng: 18.41742 },
    { lat: 48.8505589, lng: 18.4209184 },
    { lat: 48.8509389, lng: 18.422483 },
    { lat: 48.8509805, lng: 18.4232547 },
    { lat: 48.8511353, lng: 18.4236673 },
    { lat: 48.8513579, lng: 18.4240581 },
    { lat: 48.8514247, lng: 18.4243505 },
    { lat: 48.8515166, lng: 18.4257462 },
    { lat: 48.851438, lng: 18.4264655 },
    { lat: 48.8515562, lng: 18.4271905 },
    { lat: 48.8516014, lng: 18.428254 },
    { lat: 48.8516661, lng: 18.4287906 },
    { lat: 48.8518567, lng: 18.4295659 },
    { lat: 48.851942, lng: 18.430697 },
    { lat: 48.8519935, lng: 18.4311185 },
    { lat: 48.8521056, lng: 18.4315341 },
    { lat: 48.8523934, lng: 18.4322914 },
    { lat: 48.8527376, lng: 18.4331511 },
    { lat: 48.852768, lng: 18.4334313 },
    { lat: 48.8528841, lng: 18.4337181 },
    { lat: 48.8528472, lng: 18.4339664 },
    { lat: 48.8529868, lng: 18.434289 },
    { lat: 48.8531982, lng: 18.4349313 },
    { lat: 48.8533917, lng: 18.4352072 },
    { lat: 48.8534292, lng: 18.4355166 },
    { lat: 48.8537955, lng: 18.4364301 },
    { lat: 48.8538963, lng: 18.4368845 },
    { lat: 48.8538134, lng: 18.4381401 },
    { lat: 48.8538523, lng: 18.4391887 },
    { lat: 48.8539127, lng: 18.4419172 },
    { lat: 48.8556864, lng: 18.4445977 },
    { lat: 48.8557979, lng: 18.4447957 },
    { lat: 48.8567123, lng: 18.4468961 },
    { lat: 48.8570226, lng: 18.4463853 },
    { lat: 48.8578693, lng: 18.4459262 },
    { lat: 48.8589635, lng: 18.4467651 },
    { lat: 48.8595002, lng: 18.4468024 },
    { lat: 48.8624864, lng: 18.4460673 },
    { lat: 48.8645094, lng: 18.4468508 },
    { lat: 48.8654231, lng: 18.4477092 },
    { lat: 48.8671234, lng: 18.4489211 },
    { lat: 48.8694299, lng: 18.4494077 },
    { lat: 48.8695448, lng: 18.4498536 },
    { lat: 48.8695835, lng: 18.4501429 },
    { lat: 48.8697552, lng: 18.4508045 },
    { lat: 48.8698593, lng: 18.4513811 },
    { lat: 48.8699294, lng: 18.4516535 },
    { lat: 48.870352, lng: 18.4516655 },
    { lat: 48.8709803, lng: 18.4524832 },
    { lat: 48.8709034, lng: 18.4534393 },
    { lat: 48.8700895, lng: 18.4532073 },
    { lat: 48.8697711, lng: 18.4539787 },
    { lat: 48.8694397, lng: 18.4548197 },
    { lat: 48.8694335, lng: 18.4548746 },
    { lat: 48.8696358, lng: 18.4549418 },
    { lat: 48.8698162, lng: 18.4549823 },
    { lat: 48.8699815, lng: 18.4550306 },
    { lat: 48.8703383, lng: 18.4552204 },
    { lat: 48.8712657, lng: 18.4556541 },
    { lat: 48.8714273, lng: 18.4557426 },
    { lat: 48.8713289, lng: 18.4560854 },
    { lat: 48.8717227, lng: 18.4564756 },
    { lat: 48.8716913, lng: 18.4566229 },
    { lat: 48.8716596, lng: 18.4567251 },
    { lat: 48.8723561, lng: 18.4574092 },
    { lat: 48.872496, lng: 18.4574896 },
    { lat: 48.872573, lng: 18.4574752 },
    { lat: 48.8727791, lng: 18.4571843 },
    { lat: 48.8728347, lng: 18.4571891 },
    { lat: 48.8730053, lng: 18.4573384 },
    { lat: 48.8730547, lng: 18.4573938 },
    { lat: 48.8733285, lng: 18.457734 },
    { lat: 48.8734236, lng: 18.4578423 },
    { lat: 48.8738438, lng: 18.4580167 },
    { lat: 48.8739574, lng: 18.4579926 },
    { lat: 48.8740142, lng: 18.4578747 },
    { lat: 48.8740452, lng: 18.4576612 },
    { lat: 48.8740991, lng: 18.4573902 },
    { lat: 48.8741528, lng: 18.4571763 },
    { lat: 48.8742425, lng: 18.4570026 },
    { lat: 48.8743822, lng: 18.4569161 },
    { lat: 48.8745116, lng: 18.4569302 },
    { lat: 48.8746375, lng: 18.4570286 },
    { lat: 48.8746553, lng: 18.4572266 },
    { lat: 48.8746746, lng: 18.4573324 },
    { lat: 48.8746058, lng: 18.4576593 },
    { lat: 48.8746775, lng: 18.4577323 },
    { lat: 48.8745863, lng: 18.4582484 },
    { lat: 48.8746858, lng: 18.4582523 },
    { lat: 48.8747837, lng: 18.4581679 },
    { lat: 48.8748447, lng: 18.4581469 },
    { lat: 48.8749605, lng: 18.4582613 },
    { lat: 48.8750959, lng: 18.4582004 },
    { lat: 48.8752136, lng: 18.4581866 },
    { lat: 48.8752765, lng: 18.4580206 },
    { lat: 48.8753337, lng: 18.4577883 },
    { lat: 48.8753291, lng: 18.45758 },
    { lat: 48.8754889, lng: 18.4575762 },
    { lat: 48.8756245, lng: 18.4573128 },
    { lat: 48.8760478, lng: 18.4574624 },
    { lat: 48.8760988, lng: 18.4574861 },
    { lat: 48.8761462, lng: 18.4574467 },
    { lat: 48.8762982, lng: 18.4577074 },
    { lat: 48.8764573, lng: 18.4580954 },
    { lat: 48.8765685, lng: 18.4582294 },
    { lat: 48.8768193, lng: 18.4582159 },
    { lat: 48.8769852, lng: 18.4580139 },
    { lat: 48.8772561, lng: 18.4578947 },
    { lat: 48.8774762, lng: 18.4578127 },
    { lat: 48.8776101, lng: 18.45779 },
    { lat: 48.8776412, lng: 18.4577574 },
    { lat: 48.877719, lng: 18.4577368 },
    { lat: 48.8779342, lng: 18.4580385 },
    { lat: 48.8779523, lng: 18.458019 },
    { lat: 48.8779797, lng: 18.457903 },
    { lat: 48.8780927, lng: 18.4579887 },
    { lat: 48.8781517, lng: 18.4581977 },
    { lat: 48.8781769, lng: 18.4582483 },
    { lat: 48.8782088, lng: 18.4582127 },
    { lat: 48.8782684, lng: 18.4581059 },
    { lat: 48.878357, lng: 18.4580366 },
    { lat: 48.8784691, lng: 18.4580421 },
    { lat: 48.8785862, lng: 18.4580829 },
    { lat: 48.8786977, lng: 18.4580383 },
    { lat: 48.8789153, lng: 18.4579118 },
    { lat: 48.8793186, lng: 18.4578611 },
    { lat: 48.8793961, lng: 18.4578334 },
    { lat: 48.8795, lng: 18.4575926 },
    { lat: 48.8795015, lng: 18.4575373 },
    { lat: 48.8795251, lng: 18.4574941 },
    { lat: 48.8796664, lng: 18.4571279 },
    { lat: 48.8798865, lng: 18.456824 },
    { lat: 48.8801086, lng: 18.4563934 },
    { lat: 48.8802095, lng: 18.456281 },
    { lat: 48.8804415, lng: 18.4567197 },
    { lat: 48.8806992, lng: 18.4567724 },
    { lat: 48.8808252, lng: 18.4567471 },
    { lat: 48.8809275, lng: 18.4567589 },
    { lat: 48.8809921, lng: 18.4568066 },
    { lat: 48.8810944, lng: 18.456737 },
    { lat: 48.8812421, lng: 18.4565739 },
    { lat: 48.8812607, lng: 18.456522 },
    { lat: 48.881334, lng: 18.4564404 },
    { lat: 48.8813975, lng: 18.4564044 },
    { lat: 48.8817527, lng: 18.456382 },
    { lat: 48.8818998, lng: 18.4563916 },
    { lat: 48.8819541, lng: 18.4564106 },
    { lat: 48.8820789, lng: 18.456488 },
    { lat: 48.8822445, lng: 18.4566279 },
    { lat: 48.8824155, lng: 18.456579 },
    { lat: 48.8825683, lng: 18.4566263 },
    { lat: 48.8826973, lng: 18.4566946 },
    { lat: 48.8827622, lng: 18.4568523 },
    { lat: 48.8828743, lng: 18.4570023 },
    { lat: 48.8831814, lng: 18.4571716 },
    { lat: 48.8833446, lng: 18.4571631 },
    { lat: 48.8835499, lng: 18.4571837 },
    { lat: 48.8837323, lng: 18.457237 },
    { lat: 48.8840546, lng: 18.4570513 },
    { lat: 48.884182, lng: 18.4569861 },
    { lat: 48.8842591, lng: 18.4569097 },
    { lat: 48.8844255, lng: 18.4567158 },
    { lat: 48.8844982, lng: 18.4566461 },
    { lat: 48.8846121, lng: 18.4566019 },
    { lat: 48.8846807, lng: 18.4566423 },
    { lat: 48.8847543, lng: 18.4567681 },
    { lat: 48.8847393, lng: 18.4569245 },
    { lat: 48.8847067, lng: 18.4570713 },
    { lat: 48.8847553, lng: 18.4572361 },
    { lat: 48.8848171, lng: 18.4572939 },
    { lat: 48.8848881, lng: 18.4573121 },
    { lat: 48.8851106, lng: 18.457237 },
    { lat: 48.8852179, lng: 18.45697 },
    { lat: 48.8852245, lng: 18.4566003 },
    { lat: 48.8852822, lng: 18.4565016 },
    { lat: 48.8853735, lng: 18.4564599 },
    { lat: 48.8854712, lng: 18.4565511 },
    { lat: 48.8855131, lng: 18.4565566 },
    { lat: 48.8856231, lng: 18.4564853 },
    { lat: 48.8856812, lng: 18.4563299 },
    { lat: 48.8857203, lng: 18.4561984 },
    { lat: 48.8857757, lng: 18.4561229 },
    { lat: 48.8858305, lng: 18.4560712 },
    { lat: 48.8859187, lng: 18.4560554 },
    { lat: 48.886041, lng: 18.4563309 },
    { lat: 48.8860422, lng: 18.4563745 },
    { lat: 48.8860686, lng: 18.4564192 },
    { lat: 48.8860902, lng: 18.4564265 },
    { lat: 48.886166, lng: 18.4564069 },
    { lat: 48.8861774, lng: 18.456228 },
    { lat: 48.886226, lng: 18.4561885 },
    { lat: 48.8863092, lng: 18.4561837 },
    { lat: 48.8864549, lng: 18.4562445 },
    { lat: 48.8867766, lng: 18.4566181 },
    { lat: 48.8868981, lng: 18.4567163 },
    { lat: 48.8870114, lng: 18.4567647 },
    { lat: 48.8871122, lng: 18.4567544 },
    { lat: 48.8873273, lng: 18.4566852 },
    { lat: 48.887439, lng: 18.4566618 },
    { lat: 48.8876351, lng: 18.4566394 },
    { lat: 48.8877192, lng: 18.4565693 },
    { lat: 48.8877279, lng: 18.4564001 },
    { lat: 48.8877729, lng: 18.4562528 },
    { lat: 48.8879281, lng: 18.4559801 },
    { lat: 48.8880297, lng: 18.4558804 },
    { lat: 48.8881137, lng: 18.4558257 },
    { lat: 48.8882934, lng: 18.4557929 },
    { lat: 48.8884785, lng: 18.4557938 },
    { lat: 48.888582, lng: 18.4558488 },
    { lat: 48.888727, lng: 18.455862 },
    { lat: 48.8888127, lng: 18.4557791 },
    { lat: 48.8889653, lng: 18.4554343 },
    { lat: 48.8890326, lng: 18.455325 },
    { lat: 48.8893142, lng: 18.4551764 },
    { lat: 48.8895643, lng: 18.4551089 },
    { lat: 48.8898589, lng: 18.4549081 },
    { lat: 48.8900544, lng: 18.4549328 },
    { lat: 48.8901457, lng: 18.4548485 },
    { lat: 48.8901928, lng: 18.4547647 },
    { lat: 48.8901185, lng: 18.45442 },
    { lat: 48.8901501, lng: 18.4541944 },
    { lat: 48.8902758, lng: 18.4538935 },
    { lat: 48.8903486, lng: 18.4537437 },
    { lat: 48.8905428, lng: 18.4539521 },
    { lat: 48.890665, lng: 18.4540367 },
    { lat: 48.8907726, lng: 18.4540245 },
    { lat: 48.8909429, lng: 18.4537774 },
    { lat: 48.8910575, lng: 18.4536693 },
    { lat: 48.8912957, lng: 18.4537764 },
    { lat: 48.8914464, lng: 18.4538086 },
    { lat: 48.8916057, lng: 18.4538942 },
    { lat: 48.8917332, lng: 18.4538146 },
    { lat: 48.8917751, lng: 18.4537121 },
    { lat: 48.8918126, lng: 18.4532682 },
    { lat: 48.8918832, lng: 18.4531349 },
    { lat: 48.8919441, lng: 18.4531157 },
    { lat: 48.8920089, lng: 18.4531842 },
    { lat: 48.8920561, lng: 18.4531624 },
    { lat: 48.8920755, lng: 18.4529599 },
    { lat: 48.8920821, lng: 18.4528146 },
    { lat: 48.8922378, lng: 18.45216 },
    { lat: 48.8922246, lng: 18.4521262 },
    { lat: 48.892291, lng: 18.45204 },
    { lat: 48.8923244, lng: 18.4520938 },
    { lat: 48.892338, lng: 18.4521982 },
    { lat: 48.8924902, lng: 18.4522578 },
    { lat: 48.8925407, lng: 18.452213 },
    { lat: 48.8926136, lng: 18.452121 },
    { lat: 48.8926895, lng: 18.4519636 },
    { lat: 48.8927729, lng: 18.4517168 },
    { lat: 48.8928425, lng: 18.4514026 },
    { lat: 48.8929975, lng: 18.4509638 },
    { lat: 48.893123, lng: 18.4507439 },
    { lat: 48.8931399, lng: 18.4506988 },
    { lat: 48.8931799, lng: 18.4504044 },
    { lat: 48.8932435, lng: 18.4502296 },
    { lat: 48.8933999, lng: 18.4500334 },
    { lat: 48.8935589, lng: 18.449972 },
    { lat: 48.8937707, lng: 18.4498663 },
    { lat: 48.8938877, lng: 18.4496557 },
    { lat: 48.8938978, lng: 18.4496161 },
    { lat: 48.8939166, lng: 18.4494635 },
    { lat: 48.8938691, lng: 18.4490511 },
    { lat: 48.8938151, lng: 18.4489519 },
    { lat: 48.8937851, lng: 18.4488578 },
    { lat: 48.8937707, lng: 18.4487596 },
    { lat: 48.8937707, lng: 18.4486184 },
    { lat: 48.8937932, lng: 18.4485151 },
    { lat: 48.8938675, lng: 18.4483663 },
    { lat: 48.8941091, lng: 18.4482894 },
    { lat: 48.8942477, lng: 18.4483306 },
    { lat: 48.8943641, lng: 18.4484133 },
    { lat: 48.8944169, lng: 18.448573 },
    { lat: 48.8945027, lng: 18.4487198 },
    { lat: 48.8945727, lng: 18.4487801 },
    { lat: 48.8947949, lng: 18.4488391 },
    { lat: 48.8949833, lng: 18.44878 },
    { lat: 48.8951407, lng: 18.448954 },
    { lat: 48.8952483, lng: 18.4490634 },
    { lat: 48.895371, lng: 18.4491012 },
    { lat: 48.8955311, lng: 18.4493043 },
    { lat: 48.8955528, lng: 18.4493083 },
    { lat: 48.8956137, lng: 18.4494092 },
    { lat: 48.895692, lng: 18.4496244 },
    { lat: 48.8959402, lng: 18.4500314 },
    { lat: 48.8959505, lng: 18.4500566 },
    { lat: 48.8959952, lng: 18.4501295 },
    { lat: 48.8960974, lng: 18.4502255 },
    { lat: 48.8961768, lng: 18.4501907 },
    { lat: 48.896256, lng: 18.4502162 },
    { lat: 48.8964668, lng: 18.4504375 },
    { lat: 48.8965173, lng: 18.4505186 },
    { lat: 48.8965754, lng: 18.4505686 },
    { lat: 48.8966886, lng: 18.450636 },
    { lat: 48.8966767, lng: 18.4507899 },
    { lat: 48.8967311, lng: 18.45112 },
    { lat: 48.8967635, lng: 18.4512202 },
    { lat: 48.8968732, lng: 18.4514085 },
    { lat: 48.8969657, lng: 18.4512849 },
    { lat: 48.8969884, lng: 18.4513286 },
    { lat: 48.8970285, lng: 18.4513573 },
    { lat: 48.8970612, lng: 18.451522 },
    { lat: 48.8971355, lng: 18.4516854 },
    { lat: 48.8971242, lng: 18.4517646 },
    { lat: 48.8976349, lng: 18.4525764 },
    { lat: 48.8978634, lng: 18.4528365 },
    { lat: 48.8981045, lng: 18.4529737 },
    { lat: 48.8981837, lng: 18.4531196 },
    { lat: 48.8984075, lng: 18.4533329 },
    { lat: 48.898545, lng: 18.4538213 },
    { lat: 48.8987391, lng: 18.4540101 },
    { lat: 48.8989006, lng: 18.4539093 },
    { lat: 48.8991587, lng: 18.4542559 },
    { lat: 48.8991802, lng: 18.4543374 },
    { lat: 48.8991719, lng: 18.4543926 },
    { lat: 48.8991708, lng: 18.4544541 },
    { lat: 48.8991809, lng: 18.4545331 },
    { lat: 48.8990805, lng: 18.4547201 },
    { lat: 48.8991497, lng: 18.4551728 },
    { lat: 48.8993968, lng: 18.4553813 },
    { lat: 48.8996123, lng: 18.4556078 },
    { lat: 48.8998013, lng: 18.4557818 },
    { lat: 48.899876, lng: 18.4559093 },
    { lat: 48.89998, lng: 18.456056 },
    { lat: 48.9001118, lng: 18.4558303 },
    { lat: 48.9001178, lng: 18.4559561 },
    { lat: 48.9001647, lng: 18.45601 },
    { lat: 48.900224, lng: 18.4561244 },
    { lat: 48.9004237, lng: 18.4563738 },
    { lat: 48.9006161, lng: 18.4565667 },
    { lat: 48.9008396, lng: 18.4566725 },
    { lat: 48.9011755, lng: 18.4567923 },
    { lat: 48.9016261, lng: 18.4571278 },
    { lat: 48.9017072, lng: 18.4572058 },
    { lat: 48.9018541, lng: 18.4573141 },
    { lat: 48.902085, lng: 18.4575115 },
    { lat: 48.9025119, lng: 18.4579751 },
    { lat: 48.90267, lng: 18.4581803 },
    { lat: 48.9027019, lng: 18.4581895 },
    { lat: 48.9027997, lng: 18.4581786 },
    { lat: 48.9028473, lng: 18.4581033 },
    { lat: 48.902782, lng: 18.458003 },
    { lat: 48.902812, lng: 18.4579518 },
    { lat: 48.9028879, lng: 18.457896 },
    { lat: 48.9029262, lng: 18.4578961 },
    { lat: 48.9029321, lng: 18.4578405 },
    { lat: 48.9028849, lng: 18.4578035 },
    { lat: 48.9027862, lng: 18.4577704 },
    { lat: 48.9027533, lng: 18.457666 },
    { lat: 48.9028828, lng: 18.4574986 },
    { lat: 48.9029331, lng: 18.4575496 },
    { lat: 48.9030375, lng: 18.4574786 },
    { lat: 48.903082, lng: 18.457549 },
    { lat: 48.9031107, lng: 18.457786 },
    { lat: 48.9031697, lng: 18.4579144 },
    { lat: 48.9032885, lng: 18.4579585 },
    { lat: 48.9033093, lng: 18.4581359 },
    { lat: 48.9032506, lng: 18.4583179 },
    { lat: 48.9033132, lng: 18.4585104 },
    { lat: 48.9035129, lng: 18.4584923 },
    { lat: 48.9035897, lng: 18.4585357 },
    { lat: 48.9035927, lng: 18.4585894 },
    { lat: 48.9035784, lng: 18.4588021 },
    { lat: 48.9035831, lng: 18.4589047 },
    { lat: 48.9036544, lng: 18.4590162 },
    { lat: 48.9036774, lng: 18.4591033 },
    { lat: 48.9037081, lng: 18.4591485 },
    { lat: 48.9037859, lng: 18.4591494 },
    { lat: 48.9037838, lng: 18.4592537 },
    { lat: 48.9037337, lng: 18.4593055 },
    { lat: 48.9037667, lng: 18.4593548 },
    { lat: 48.9039237, lng: 18.4593779 },
    { lat: 48.9039958, lng: 18.4595402 },
    { lat: 48.9039483, lng: 18.4597004 },
    { lat: 48.9038399, lng: 18.4599035 },
    { lat: 48.9038802, lng: 18.4602268 },
    { lat: 48.904016, lng: 18.4603796 },
    { lat: 48.903987, lng: 18.4606088 },
    { lat: 48.9040737, lng: 18.4609768 },
    { lat: 48.9040927, lng: 18.46122 },
    { lat: 48.9041146, lng: 18.4613682 },
    { lat: 48.9040887, lng: 18.4616806 },
    { lat: 48.9041737, lng: 18.4618283 },
    { lat: 48.9042679, lng: 18.4619634 },
    { lat: 48.9042995, lng: 18.4620437 },
    { lat: 48.9044904, lng: 18.4621324 },
    { lat: 48.9045371, lng: 18.4621817 },
    { lat: 48.904667, lng: 18.462228 },
    { lat: 48.9046972, lng: 18.4623253 },
    { lat: 48.9046935, lng: 18.4624007 },
    { lat: 48.9050718, lng: 18.4626555 },
    { lat: 48.905175, lng: 18.4627866 },
    { lat: 48.9052264, lng: 18.4628396 },
    { lat: 48.9052505, lng: 18.4629305 },
    { lat: 48.9050597, lng: 18.4631701 },
    { lat: 48.9048898, lng: 18.4633433 },
    { lat: 48.90482, lng: 18.4634063 },
    { lat: 48.9046956, lng: 18.4635651 },
    { lat: 48.9046724, lng: 18.4636366 },
    { lat: 48.9045635, lng: 18.4637278 },
    { lat: 48.9044794, lng: 18.4639618 },
    { lat: 48.9044678, lng: 18.4640981 },
    { lat: 48.9044452, lng: 18.4641006 },
    { lat: 48.9043533, lng: 18.4643156 },
    { lat: 48.9043132, lng: 18.4644899 },
    { lat: 48.9039529, lng: 18.4659152 },
    { lat: 48.9038799, lng: 18.4661489 },
    { lat: 48.90378, lng: 18.4663444 },
    { lat: 48.903553, lng: 18.4667471 },
    { lat: 48.9034467, lng: 18.4668033 },
    { lat: 48.9033491, lng: 18.4669372 },
    { lat: 48.9032748, lng: 18.4670215 },
    { lat: 48.9030432, lng: 18.4673018 },
    { lat: 48.9029694, lng: 18.4674388 },
    { lat: 48.9027779, lng: 18.4676283 },
    { lat: 48.9026994, lng: 18.4677615 },
    { lat: 48.9025732, lng: 18.4679256 },
    { lat: 48.9022624, lng: 18.4688588 },
    { lat: 48.9021988, lng: 18.4705147 },
    { lat: 48.9018282, lng: 18.4715096 },
    { lat: 48.9018147, lng: 18.4722097 },
    { lat: 48.9018475, lng: 18.4728087 },
    { lat: 48.9022434, lng: 18.4743649 },
    { lat: 48.9025073, lng: 18.4744657 },
    { lat: 48.9024568, lng: 18.4752942 },
    { lat: 48.9022784, lng: 18.4762171 },
    { lat: 48.9021476, lng: 18.4773046 },
    { lat: 48.9021025, lng: 18.4782103 },
    { lat: 48.9020946, lng: 18.4788075 },
    { lat: 48.9022574, lng: 18.4790821 },
    { lat: 48.9025856, lng: 18.4793475 },
    { lat: 48.9030066, lng: 18.479848 },
    { lat: 48.9031358, lng: 18.4802469 },
    { lat: 48.9031268, lng: 18.4804533 },
    { lat: 48.9031203, lng: 18.4806013 },
    { lat: 48.902996, lng: 18.4812351 },
    { lat: 48.9028276, lng: 18.4820735 },
    { lat: 48.903121, lng: 18.4829207 },
    { lat: 48.9038212, lng: 18.4840256 },
    { lat: 48.9040858, lng: 18.4845523 },
    { lat: 48.9044179, lng: 18.4851777 },
    { lat: 48.9047566, lng: 18.4857976 },
    { lat: 48.9049792, lng: 18.4862199 },
    { lat: 48.9050645, lng: 18.4863755 },
    { lat: 48.9052461, lng: 18.4868901 },
    { lat: 48.9053287, lng: 18.4873057 },
    { lat: 48.905324, lng: 18.4875688 },
    { lat: 48.9054227, lng: 18.4879296 },
    { lat: 48.9055388, lng: 18.4885686 },
    { lat: 48.9060159, lng: 18.4890797 },
    { lat: 48.9059376, lng: 18.4893413 },
    { lat: 48.9058876, lng: 18.4899923 },
    { lat: 48.9061472, lng: 18.4905928 },
    { lat: 48.9064277, lng: 18.4912251 },
    { lat: 48.9064811, lng: 18.4913331 },
    { lat: 48.9067021, lng: 18.4916445 },
    { lat: 48.9067709, lng: 18.4920307 },
    { lat: 48.9068308, lng: 18.4923666 },
    { lat: 48.9068379, lng: 18.4928645 },
    { lat: 48.9070596, lng: 18.4933073 },
    { lat: 48.9069621, lng: 18.4940827 },
    { lat: 48.9070031, lng: 18.4943396 },
    { lat: 48.9070382, lng: 18.4943427 },
    { lat: 48.9071324, lng: 18.4949067 },
    { lat: 48.9073896, lng: 18.4958142 },
    { lat: 48.907383, lng: 18.4960424 },
    { lat: 48.9074257, lng: 18.4962066 },
    { lat: 48.9076096, lng: 18.496702 },
    { lat: 48.9076155, lng: 18.4969741 },
    { lat: 48.9077323, lng: 18.4975417 },
    { lat: 48.9077782, lng: 18.4978056 },
    { lat: 48.9077723, lng: 18.4978506 },
    { lat: 48.9077535, lng: 18.4979948 },
    { lat: 48.9078026, lng: 18.4983734 },
    { lat: 48.9082211, lng: 18.4985828 },
    { lat: 48.9091031, lng: 18.4986848 },
    { lat: 48.9094228, lng: 18.4985481 },
    { lat: 48.9099116, lng: 18.4986171 },
    { lat: 48.9103168, lng: 18.4990545 },
    { lat: 48.9106669, lng: 18.500154 },
    { lat: 48.9106178, lng: 18.5008035 },
    { lat: 48.9106926, lng: 18.5010394 },
    { lat: 48.9108821, lng: 18.5013053 },
    { lat: 48.9107858, lng: 18.5024319 },
    { lat: 48.9109761, lng: 18.5039113 },
    { lat: 48.911035, lng: 18.5043116 },
    { lat: 48.9118351, lng: 18.5051842 },
    { lat: 48.9125857, lng: 18.5053982 },
    { lat: 48.9132531, lng: 18.5052279 },
    { lat: 48.9134161, lng: 18.5055244 },
    { lat: 48.9137822, lng: 18.5065722 },
    { lat: 48.9138781, lng: 18.5070851 },
    { lat: 48.9139334, lng: 18.5077484 },
    { lat: 48.9140002, lng: 18.508188 },
    { lat: 48.9140144, lng: 18.5086951 },
    { lat: 48.9138094, lng: 18.5097981 },
    { lat: 48.9134833, lng: 18.510661 },
    { lat: 48.9133217, lng: 18.5118146 },
    { lat: 48.9133722, lng: 18.5119126 },
    { lat: 48.9134194, lng: 18.5121182 },
    { lat: 48.9135527, lng: 18.5126997 },
    { lat: 48.9138433, lng: 18.5131261 },
    { lat: 48.9139991, lng: 18.5134767 },
    { lat: 48.9145364, lng: 18.514271 },
    { lat: 48.9145525, lng: 18.5142973 },
    { lat: 48.9147358, lng: 18.5147025 },
    { lat: 48.9149124, lng: 18.5147775 },
    { lat: 48.9153702, lng: 18.5154125 },
    { lat: 48.9154481, lng: 18.5154974 },
    { lat: 48.9159479, lng: 18.516224 },
    { lat: 48.9159714, lng: 18.5163433 },
    { lat: 48.9162645, lng: 18.5167764 },
    { lat: 48.9163596, lng: 18.516843 },
    { lat: 48.9169278, lng: 18.5170245 },
    { lat: 48.9169958, lng: 18.5175058 },
    { lat: 48.9173566, lng: 18.5184963 },
    { lat: 48.9174677, lng: 18.5199144 },
    { lat: 48.9175361, lng: 18.520385 },
    { lat: 48.917692, lng: 18.5210942 },
    { lat: 48.9176856, lng: 18.5214321 },
    { lat: 48.9177379, lng: 18.5223487 },
    { lat: 48.9178824, lng: 18.5229919 },
    { lat: 48.9181575, lng: 18.5238601 },
    { lat: 48.9184799, lng: 18.5239822 },
    { lat: 48.9190037, lng: 18.5246334 },
    { lat: 48.9192956, lng: 18.5258908 },
    { lat: 48.9192679, lng: 18.5261475 },
    { lat: 48.9193663, lng: 18.5264218 },
    { lat: 48.9198767, lng: 18.5263926 },
    { lat: 48.9202895, lng: 18.5264513 },
    { lat: 48.9203853, lng: 18.5264526 },
    { lat: 48.920636, lng: 18.5266026 },
    { lat: 48.9208538, lng: 18.526793 },
    { lat: 48.9210327, lng: 18.5271343 },
    { lat: 48.9216123, lng: 18.5279178 },
    { lat: 48.9219301, lng: 18.5281638 },
    { lat: 48.9222884, lng: 18.5284051 },
    { lat: 48.9223632, lng: 18.5284123 },
    { lat: 48.9226549, lng: 18.5283 },
    { lat: 48.9229397, lng: 18.528373 },
    { lat: 48.9231311, lng: 18.5283455 },
    { lat: 48.9233791, lng: 18.5284471 },
    { lat: 48.9242635, lng: 18.5289023 },
    { lat: 48.9246593, lng: 18.5292354 },
    { lat: 48.9249318, lng: 18.5292855 },
    { lat: 48.9255247, lng: 18.5290932 },
    { lat: 48.926225, lng: 18.5284879 },
    { lat: 48.926522, lng: 18.5281436 },
    { lat: 48.9266554, lng: 18.5279411 },
    { lat: 48.9267648, lng: 18.5277968 },
    { lat: 48.9268894, lng: 18.5277224 },
    { lat: 48.9270637, lng: 18.5276905 },
    { lat: 48.9271757, lng: 18.5276551 },
    { lat: 48.9273964, lng: 18.5276706 },
    { lat: 48.9278213, lng: 18.5278312 },
    { lat: 48.9285447, lng: 18.5283536 },
    { lat: 48.9289508, lng: 18.5286678 },
    { lat: 48.9294748, lng: 18.5294663 },
    { lat: 48.9301046, lng: 18.5294728 },
    { lat: 48.9302462, lng: 18.5293776 },
    { lat: 48.9303547, lng: 18.5291961 },
    { lat: 48.9305308, lng: 18.5286614 },
    { lat: 48.9306649, lng: 18.5283669 },
    { lat: 48.9307609, lng: 18.5282473 },
    { lat: 48.9308689, lng: 18.5280558 },
    { lat: 48.9311305, lng: 18.527802 },
    { lat: 48.9312899, lng: 18.527664 },
    { lat: 48.9316006, lng: 18.5274285 },
    { lat: 48.9325266, lng: 18.5264934 },
    { lat: 48.9326659, lng: 18.5262722 },
    { lat: 48.9327295, lng: 18.5260945 },
    { lat: 48.9328399, lng: 18.5258267 },
    { lat: 48.9329868, lng: 18.5256027 },
    { lat: 48.9331582, lng: 18.5254115 },
    { lat: 48.9342601, lng: 18.5245005 },
    { lat: 48.9345405, lng: 18.5242835 },
    { lat: 48.9347685, lng: 18.5238312 },
    { lat: 48.9347902, lng: 18.5237955 },
    { lat: 48.9353827, lng: 18.5235288 },
    { lat: 48.9360577, lng: 18.5233932 },
    { lat: 48.9365046, lng: 18.5233142 },
    { lat: 48.9368771, lng: 18.5233224 },
    { lat: 48.937052, lng: 18.523407 },
    { lat: 48.937164, lng: 18.523016 },
    { lat: 48.937227, lng: 18.522829 },
    { lat: 48.937227, lng: 18.522822 },
    { lat: 48.937189, lng: 18.522144 },
    { lat: 48.93719, lng: 18.52155 },
    { lat: 48.937174, lng: 18.520605 },
    { lat: 48.937151, lng: 18.519445 },
    { lat: 48.937112, lng: 18.518873 },
    { lat: 48.936984, lng: 18.518452 },
    { lat: 48.936839, lng: 18.518122 },
    { lat: 48.93673, lng: 18.517875 },
    { lat: 48.936716, lng: 18.517843 },
    { lat: 48.936703, lng: 18.517826 },
    { lat: 48.936468, lng: 18.517538 },
    { lat: 48.9367, lng: 18.516585 },
    { lat: 48.936754, lng: 18.516458 },
    { lat: 48.936777, lng: 18.516095 },
    { lat: 48.936776, lng: 18.515729 },
    { lat: 48.936884, lng: 18.515313 },
    { lat: 48.936878, lng: 18.514441 },
    { lat: 48.936869, lng: 18.514178 },
    { lat: 48.936878, lng: 18.513757 },
    { lat: 48.936835, lng: 18.513327 },
    { lat: 48.93686, lng: 18.513074 },
    { lat: 48.936881, lng: 18.512572 },
    { lat: 48.936929, lng: 18.512005 },
    { lat: 48.937055, lng: 18.511504 },
    { lat: 48.937291, lng: 18.510731 },
    { lat: 48.937304, lng: 18.510687 },
    { lat: 48.937526, lng: 18.510073 },
    { lat: 48.937587, lng: 18.5099 },
    { lat: 48.937784, lng: 18.509445 },
    { lat: 48.937787, lng: 18.509432 },
    { lat: 48.937847, lng: 18.509225 },
    { lat: 48.937875, lng: 18.509091 },
    { lat: 48.937876, lng: 18.509085 },
    { lat: 48.937873, lng: 18.509053 },
    { lat: 48.937853, lng: 18.508794 },
    { lat: 48.93809, lng: 18.508631 },
    { lat: 48.938201, lng: 18.508554 },
    { lat: 48.938649, lng: 18.508212 },
    { lat: 48.938885, lng: 18.508036 },
    { lat: 48.938899, lng: 18.508025 },
    { lat: 48.939004, lng: 18.507869 },
    { lat: 48.939096, lng: 18.507727 },
    { lat: 48.939306, lng: 18.507486 },
    { lat: 48.939406, lng: 18.507372 },
    { lat: 48.939433, lng: 18.507329 },
    { lat: 48.939645, lng: 18.506987 },
    { lat: 48.939762, lng: 18.506784 },
    { lat: 48.939871, lng: 18.506514 },
    { lat: 48.939908, lng: 18.506231 },
    { lat: 48.939935, lng: 18.505913 },
    { lat: 48.939927, lng: 18.505571 },
    { lat: 48.939907, lng: 18.505306 },
    { lat: 48.940015, lng: 18.504779 },
    { lat: 48.940135, lng: 18.504237 },
    { lat: 48.939535, lng: 18.504511 },
    { lat: 48.939234, lng: 18.504649 },
    { lat: 48.939166, lng: 18.504786 },
    { lat: 48.938959, lng: 18.505197 },
    { lat: 48.938653, lng: 18.505927 },
    { lat: 48.938332, lng: 18.506231 },
    { lat: 48.93829, lng: 18.506271 },
    { lat: 48.937663, lng: 18.506971 },
    { lat: 48.93729, lng: 18.507167 },
    { lat: 48.936743, lng: 18.507129 },
    { lat: 48.93673, lng: 18.507128 },
    { lat: 48.936623, lng: 18.507167 },
    { lat: 48.936563, lng: 18.507202 },
    { lat: 48.936541, lng: 18.507224 },
    { lat: 48.936475, lng: 18.50729 },
    { lat: 48.936395, lng: 18.507386 },
    { lat: 48.936292, lng: 18.507493 },
    { lat: 48.935997, lng: 18.507673 },
    { lat: 48.934733, lng: 18.507192 },
    { lat: 48.933874, lng: 18.507125 },
    { lat: 48.9338243, lng: 18.5071124 },
    { lat: 48.933783, lng: 18.507102 },
    { lat: 48.933701, lng: 18.5070983 },
    { lat: 48.933423, lng: 18.507074 },
    { lat: 48.932991, lng: 18.50596 },
    { lat: 48.932991, lng: 18.505959 },
    { lat: 48.932988, lng: 18.505957 },
    { lat: 48.93261, lng: 18.505588 },
    { lat: 48.932237, lng: 18.504855 },
    { lat: 48.932233, lng: 18.504847 },
    { lat: 48.932065, lng: 18.504717 },
    { lat: 48.931874, lng: 18.50457 },
    { lat: 48.93122, lng: 18.504393 },
    { lat: 48.931031, lng: 18.504381 },
    { lat: 48.930832, lng: 18.50439 },
    { lat: 48.930159, lng: 18.504183 },
    { lat: 48.9299473, lng: 18.5040452 },
    { lat: 48.929626, lng: 18.503836 },
    { lat: 48.929122, lng: 18.503353 },
    { lat: 48.928979, lng: 18.502904 },
    { lat: 48.928926, lng: 18.502738 },
    { lat: 48.928371, lng: 18.501774 },
    { lat: 48.928273, lng: 18.501604 },
    { lat: 48.9281638, lng: 18.5007434 },
    { lat: 48.9280531, lng: 18.4998713 },
    { lat: 48.928023, lng: 18.499634 },
    { lat: 48.9278201, lng: 18.4985012 },
    { lat: 48.927684, lng: 18.497741 },
    { lat: 48.9275189, lng: 18.4972717 },
    { lat: 48.927283, lng: 18.496601 },
    { lat: 48.926296, lng: 18.495429 },
    { lat: 48.926153, lng: 18.495269 },
    { lat: 48.924859, lng: 18.494256 },
    { lat: 48.923336, lng: 18.493529 },
    { lat: 48.923245, lng: 18.493485 },
    { lat: 48.923065, lng: 18.493519 },
    { lat: 48.921962, lng: 18.49387 },
    { lat: 48.921781, lng: 18.493905 },
    { lat: 48.9211, lng: 18.494079 },
    { lat: 48.92038, lng: 18.494262 },
    { lat: 48.920378, lng: 18.494263 },
    { lat: 48.919068, lng: 18.494479 },
    { lat: 48.918757, lng: 18.494471 },
    { lat: 48.918723, lng: 18.494471 },
    { lat: 48.917988, lng: 18.494454 },
    { lat: 48.916787, lng: 18.493986 },
    { lat: 48.916754, lng: 18.493973 },
    { lat: 48.916754, lng: 18.493967 },
    { lat: 48.91672, lng: 18.493744 },
    { lat: 48.91672, lng: 18.493742 },
    { lat: 48.916359, lng: 18.493241 },
    { lat: 48.916295, lng: 18.49315 },
    { lat: 48.9153, lng: 18.491995 },
    { lat: 48.914232, lng: 18.490583 },
    { lat: 48.91422, lng: 18.490029 },
    { lat: 48.914216, lng: 18.489823 },
    { lat: 48.914215, lng: 18.48982 },
    { lat: 48.914132, lng: 18.4898 },
    { lat: 48.913684, lng: 18.489689 },
    { lat: 48.913171, lng: 18.488952 },
    { lat: 48.91255, lng: 18.487973 },
    { lat: 48.912539, lng: 18.487956 },
    { lat: 48.912529, lng: 18.487927 },
    { lat: 48.912476, lng: 18.487769 },
    { lat: 48.912464, lng: 18.487723 },
    { lat: 48.912394, lng: 18.487354 },
    { lat: 48.912393, lng: 18.487354 },
    { lat: 48.912169, lng: 18.487329 },
    { lat: 48.912077, lng: 18.487319 },
    { lat: 48.911867, lng: 18.487702 },
    { lat: 48.911857, lng: 18.487674 },
    { lat: 48.91181, lng: 18.48751 },
    { lat: 48.91157, lng: 18.487092 },
    { lat: 48.911555, lng: 18.487065 },
    { lat: 48.910932, lng: 18.486375 },
    { lat: 48.91064, lng: 18.48596 },
    { lat: 48.910503, lng: 18.485726 },
    { lat: 48.910069, lng: 18.484095 },
    { lat: 48.909865, lng: 18.483267 },
    { lat: 48.909864, lng: 18.483261 },
    { lat: 48.909393, lng: 18.481172 },
    { lat: 48.909221, lng: 18.480413 },
    { lat: 48.909199, lng: 18.480316 },
    { lat: 48.909245, lng: 18.480136 },
    { lat: 48.909616, lng: 18.478574 },
    { lat: 48.909648, lng: 18.478257 },
    { lat: 48.909658, lng: 18.478166 },
    { lat: 48.909547, lng: 18.477089 },
    { lat: 48.909498, lng: 18.476838 },
    { lat: 48.90934, lng: 18.476019 },
    { lat: 48.909298, lng: 18.475725 },
    { lat: 48.908987, lng: 18.473548 },
    { lat: 48.908991, lng: 18.472678 },
    { lat: 48.908982, lng: 18.472455 },
    { lat: 48.908963, lng: 18.471988 },
    { lat: 48.908922, lng: 18.471685 },
    { lat: 48.908903, lng: 18.471545 },
    { lat: 48.908873, lng: 18.471383 },
    { lat: 48.908787, lng: 18.470914 },
    { lat: 48.90866, lng: 18.470224 },
    { lat: 48.908404, lng: 18.468071 },
    { lat: 48.908202, lng: 18.467352 },
    { lat: 48.908004, lng: 18.466643 },
    { lat: 48.9077, lng: 18.465632 },
    { lat: 48.907622, lng: 18.465452 },
    { lat: 48.907592, lng: 18.465387 },
    { lat: 48.907428, lng: 18.465014 },
    { lat: 48.907359, lng: 18.464857 },
    { lat: 48.907172, lng: 18.464383 },
    { lat: 48.906687, lng: 18.463423 },
    { lat: 48.906681, lng: 18.463383 },
    { lat: 48.906697, lng: 18.463302 },
    { lat: 48.906697, lng: 18.463301 },
    { lat: 48.906699, lng: 18.463298 },
    { lat: 48.906837, lng: 18.463084 },
    { lat: 48.906942, lng: 18.462965 },
    { lat: 48.906977, lng: 18.462926 },
    { lat: 48.907451, lng: 18.462633 },
    { lat: 48.907468, lng: 18.462623 },
    { lat: 48.907645, lng: 18.46244 },
    { lat: 48.907799, lng: 18.462281 },
    { lat: 48.907787, lng: 18.462249 },
    { lat: 48.907734, lng: 18.462051 },
    { lat: 48.907717, lng: 18.461988 },
    { lat: 48.907305, lng: 18.461396 },
    { lat: 48.907123, lng: 18.461376 },
    { lat: 48.906784, lng: 18.461401 },
    { lat: 48.906486, lng: 18.461416 },
    { lat: 48.906316, lng: 18.461415 },
    { lat: 48.90617, lng: 18.461369 },
    { lat: 48.905358, lng: 18.46134 },
    { lat: 48.905207, lng: 18.461249 },
    { lat: 48.904906, lng: 18.460787 },
    { lat: 48.904648, lng: 18.460253 },
    { lat: 48.904324, lng: 18.459677 },
    { lat: 48.903812, lng: 18.458649 },
    { lat: 48.903548, lng: 18.457902 },
    { lat: 48.903396, lng: 18.457532 },
    { lat: 48.903275, lng: 18.457336 },
    { lat: 48.903149, lng: 18.457226 },
    { lat: 48.902884, lng: 18.45702 },
    { lat: 48.902592, lng: 18.45666 },
    { lat: 48.902089, lng: 18.456355 },
    { lat: 48.901021, lng: 18.455422 },
    { lat: 48.900863, lng: 18.455331 },
    { lat: 48.900821, lng: 18.455306 },
    { lat: 48.900771, lng: 18.455277 },
    { lat: 48.900224, lng: 18.454793 },
    { lat: 48.899798, lng: 18.454547 },
    { lat: 48.899288, lng: 18.454323 },
    { lat: 48.897892, lng: 18.45241 },
    { lat: 48.897033, lng: 18.450767 },
    { lat: 48.896663, lng: 18.450174 },
    { lat: 48.8965068, lng: 18.4498821 },
    { lat: 48.896389, lng: 18.449662 },
    { lat: 48.896114, lng: 18.448972 },
    { lat: 48.896087, lng: 18.448745 },
    { lat: 48.896014, lng: 18.447972 },
    { lat: 48.896016, lng: 18.447877 },
    { lat: 48.896435, lng: 18.447333 },
    { lat: 48.896524, lng: 18.447218 },
    { lat: 48.896887, lng: 18.446723 },
    { lat: 48.897148, lng: 18.446488 },
    { lat: 48.897251, lng: 18.446395 },
    { lat: 48.897452, lng: 18.446215 },
    { lat: 48.897561, lng: 18.446064 },
    { lat: 48.897942, lng: 18.445538 },
    { lat: 48.898209, lng: 18.444943 },
    { lat: 48.898284, lng: 18.444775 },
    { lat: 48.89829, lng: 18.444754 },
    { lat: 48.898495, lng: 18.443972 },
    { lat: 48.898587, lng: 18.443713 },
    { lat: 48.898707, lng: 18.443443 },
    { lat: 48.89884, lng: 18.443189 },
    { lat: 48.89896, lng: 18.443025 },
    { lat: 48.898993, lng: 18.44298 },
    { lat: 48.899128, lng: 18.442794 },
    { lat: 48.899272, lng: 18.442652 },
    { lat: 48.899318, lng: 18.442606 },
    { lat: 48.899551, lng: 18.442337 },
    { lat: 48.899716, lng: 18.442146 },
    { lat: 48.899904, lng: 18.441338 },
    { lat: 48.900265, lng: 18.440998 },
    { lat: 48.900494, lng: 18.440442 },
    { lat: 48.900857, lng: 18.439703 },
    { lat: 48.901119, lng: 18.439353 },
    { lat: 48.901335, lng: 18.439117 },
    { lat: 48.901316, lng: 18.438866 },
    { lat: 48.901316, lng: 18.43886 },
    { lat: 48.901038, lng: 18.438336 },
    { lat: 48.90103, lng: 18.438323 },
    { lat: 48.901588, lng: 18.438126 },
    { lat: 48.902264, lng: 18.437701 },
    { lat: 48.903516, lng: 18.437728 },
    { lat: 48.904206, lng: 18.43721 },
    { lat: 48.904511, lng: 18.436846 },
    { lat: 48.904753, lng: 18.436785 },
    { lat: 48.904952, lng: 18.436735 },
    { lat: 48.905644, lng: 18.436562 },
    { lat: 48.905803, lng: 18.436522 },
    { lat: 48.906622, lng: 18.436316 },
    { lat: 48.907595, lng: 18.435838 },
    { lat: 48.909065, lng: 18.435804 },
    { lat: 48.909755, lng: 18.435605 },
    { lat: 48.909984, lng: 18.435538 },
    { lat: 48.910631, lng: 18.435332 },
    { lat: 48.910714, lng: 18.435235 },
    { lat: 48.912093, lng: 18.43478 },
    { lat: 48.912626, lng: 18.434327 },
    { lat: 48.912633, lng: 18.434117 },
    { lat: 48.912636, lng: 18.434011 },
    { lat: 48.912639, lng: 18.433886 },
    { lat: 48.91262, lng: 18.43378 },
    { lat: 48.912618, lng: 18.433776 },
    { lat: 48.912609, lng: 18.433722 },
    { lat: 48.912596, lng: 18.43366 },
    { lat: 48.912586, lng: 18.433587 },
    { lat: 48.912572, lng: 18.433488 },
    { lat: 48.912387, lng: 18.432198 },
    { lat: 48.912363, lng: 18.432031 },
    { lat: 48.912373, lng: 18.43187 },
    { lat: 48.912581, lng: 18.428656 },
    { lat: 48.912472, lng: 18.426105 },
    { lat: 48.912442, lng: 18.42499 },
    { lat: 48.912618, lng: 18.424289 },
    { lat: 48.912878, lng: 18.423531 },
    { lat: 48.912975, lng: 18.422309 },
    { lat: 48.913024, lng: 18.421958 },
    { lat: 48.913484, lng: 18.420544 },
    { lat: 48.914295, lng: 18.41846 },
    { lat: 48.9149, lng: 18.416382 },
    { lat: 48.914911, lng: 18.416349 },
    { lat: 48.914912, lng: 18.416259 },
    { lat: 48.914919, lng: 18.415747 },
    { lat: 48.915234, lng: 18.414622 },
    { lat: 48.915922, lng: 18.413503 },
    { lat: 48.916451, lng: 18.412485 },
    { lat: 48.916913, lng: 18.410912 },
    { lat: 48.916929, lng: 18.410856 },
    { lat: 48.917405, lng: 18.409461 },
    { lat: 48.917406, lng: 18.409435 },
    { lat: 48.917477, lng: 18.407707 },
    { lat: 48.917598, lng: 18.406864 },
    { lat: 48.917748, lng: 18.406492 },
    { lat: 48.917871, lng: 18.406188 },
    { lat: 48.918113, lng: 18.40559 },
    { lat: 48.918225, lng: 18.405498 },
    { lat: 48.918956, lng: 18.404896 },
    { lat: 48.91947, lng: 18.404475 },
    { lat: 48.919592, lng: 18.404449 },
    { lat: 48.919987, lng: 18.404368 },
    { lat: 48.920066, lng: 18.404351 },
    { lat: 48.920346, lng: 18.404294 },
    { lat: 48.920934, lng: 18.404109 },
    { lat: 48.921505, lng: 18.40393 },
    { lat: 48.921572, lng: 18.403909 },
    { lat: 48.921604, lng: 18.403887 },
    { lat: 48.921938, lng: 18.403657 },
    { lat: 48.921942, lng: 18.403654 },
    { lat: 48.922334, lng: 18.403254 },
    { lat: 48.922642, lng: 18.402819 },
    { lat: 48.922845, lng: 18.402532 },
    { lat: 48.923091, lng: 18.402472 },
    { lat: 48.92338, lng: 18.402401 },
    { lat: 48.923418, lng: 18.402392 },
    { lat: 48.924193, lng: 18.402539 },
    { lat: 48.924291, lng: 18.402558 },
    { lat: 48.924603, lng: 18.40268 },
    { lat: 48.924665, lng: 18.402704 },
    { lat: 48.924962, lng: 18.40282 },
    { lat: 48.925099, lng: 18.402842 },
    { lat: 48.925601, lng: 18.402923 },
    { lat: 48.926227, lng: 18.40318 },
    { lat: 48.92685, lng: 18.403669 },
    { lat: 48.927287, lng: 18.403745 },
    { lat: 48.928042, lng: 18.403172 },
    { lat: 48.928045, lng: 18.403171 },
    { lat: 48.928651, lng: 18.402945 },
    { lat: 48.929111, lng: 18.402257 },
    { lat: 48.929131, lng: 18.402227 },
    { lat: 48.929251, lng: 18.402047 },
    { lat: 48.93031, lng: 18.400615 },
    { lat: 48.931039, lng: 18.399537 },
    { lat: 48.931521, lng: 18.398826 },
    { lat: 48.932179, lng: 18.398039 },
    { lat: 48.932661, lng: 18.39705 },
    { lat: 48.932943, lng: 18.396348 },
    { lat: 48.93337, lng: 18.395685 },
    { lat: 48.933375, lng: 18.395676 },
    { lat: 48.933283, lng: 18.39544 },
    { lat: 48.93327, lng: 18.395409 },
    { lat: 48.93271, lng: 18.393977 },
    { lat: 48.932703, lng: 18.39396 },
    { lat: 48.932689, lng: 18.393083 },
    { lat: 48.932685, lng: 18.392757 },
    { lat: 48.932378, lng: 18.391562 },
    { lat: 48.932327, lng: 18.391364 },
    { lat: 48.932221, lng: 18.390953 },
    { lat: 48.932178, lng: 18.390785 },
    { lat: 48.932175, lng: 18.390772 },
    { lat: 48.932173, lng: 18.390037 },
    { lat: 48.932187, lng: 18.389936 },
    { lat: 48.932204, lng: 18.389818 },
    { lat: 48.932265, lng: 18.389429 },
    { lat: 48.932379, lng: 18.388825 },
    { lat: 48.932504, lng: 18.38816 },
    { lat: 48.933174, lng: 18.387042 },
    { lat: 48.933635, lng: 18.386271 },
    { lat: 48.933773, lng: 18.386167 },
    { lat: 48.935119, lng: 18.385149 },
    { lat: 48.935538, lng: 18.384832 },
  ],
  VeľkáČausa: [
    { lat: 48.7735873, lng: 18.6748013 },
    { lat: 48.7734457, lng: 18.6751488 },
    { lat: 48.7733618, lng: 18.676411 },
    { lat: 48.7734355, lng: 18.6764186 },
    { lat: 48.7730482, lng: 18.6777209 },
    { lat: 48.7734462, lng: 18.6778869 },
    { lat: 48.7737122, lng: 18.6779474 },
    { lat: 48.7737005, lng: 18.6783843 },
    { lat: 48.7738649, lng: 18.6804771 },
    { lat: 48.7727194, lng: 18.6825825 },
    { lat: 48.7721404, lng: 18.6836327 },
    { lat: 48.7726268, lng: 18.6839993 },
    { lat: 48.7729148, lng: 18.6850447 },
    { lat: 48.7719767, lng: 18.6870421 },
    { lat: 48.7713904, lng: 18.6880617 },
    { lat: 48.7709925, lng: 18.6876001 },
    { lat: 48.7699235, lng: 18.6865434 },
    { lat: 48.7693133, lng: 18.6868576 },
    { lat: 48.7685521, lng: 18.6872628 },
    { lat: 48.76793, lng: 18.6877157 },
    { lat: 48.767525, lng: 18.6880106 },
    { lat: 48.7669553, lng: 18.6888575 },
    { lat: 48.7662305, lng: 18.6896913 },
    { lat: 48.7664508, lng: 18.690227 },
    { lat: 48.7666911, lng: 18.690759 },
    { lat: 48.7663128, lng: 18.6913456 },
    { lat: 48.7657815, lng: 18.6917667 },
    { lat: 48.7653774, lng: 18.6923964 },
    { lat: 48.7647597, lng: 18.6929334 },
    { lat: 48.764634, lng: 18.6928373 },
    { lat: 48.7639588, lng: 18.693227 },
    { lat: 48.7642228, lng: 18.6939925 },
    { lat: 48.764259, lng: 18.6940992 },
    { lat: 48.764277, lng: 18.694185 },
    { lat: 48.764342, lng: 18.6945156 },
    { lat: 48.7641777, lng: 18.6950116 },
    { lat: 48.7638913, lng: 18.6954593 },
    { lat: 48.7633986, lng: 18.695906 },
    { lat: 48.7626787, lng: 18.6963658 },
    { lat: 48.7621534, lng: 18.6966059 },
    { lat: 48.7618006, lng: 18.6969054 },
    { lat: 48.7615039, lng: 18.6971802 },
    { lat: 48.7607181, lng: 18.6973969 },
    { lat: 48.7602029, lng: 18.6974438 },
    { lat: 48.7595544, lng: 18.697188 },
    { lat: 48.758711, lng: 18.6972126 },
    { lat: 48.7586738, lng: 18.6972137 },
    { lat: 48.7586232, lng: 18.6972156 },
    { lat: 48.757988, lng: 18.6975721 },
    { lat: 48.7579793, lng: 18.6975771 },
    { lat: 48.7576857, lng: 18.6977414 },
    { lat: 48.7576243, lng: 18.6977757 },
    { lat: 48.7576135, lng: 18.6977822 },
    { lat: 48.7574995, lng: 18.6978459 },
    { lat: 48.7573889, lng: 18.697908 },
    { lat: 48.757332, lng: 18.6979395 },
    { lat: 48.7558911, lng: 18.6990938 },
    { lat: 48.7549496, lng: 18.7002237 },
    { lat: 48.7523935, lng: 18.7032939 },
    { lat: 48.7525172, lng: 18.703348 },
    { lat: 48.7529956, lng: 18.7037103 },
    { lat: 48.7536389, lng: 18.7054442 },
    { lat: 48.7543354, lng: 18.7072214 },
    { lat: 48.7553091, lng: 18.7077567 },
    { lat: 48.7555449, lng: 18.7079979 },
    { lat: 48.755597, lng: 18.7081689 },
    { lat: 48.7563203, lng: 18.708475 },
    { lat: 48.7575014, lng: 18.7084998 },
    { lat: 48.7576841, lng: 18.7087146 },
    { lat: 48.7580405, lng: 18.7088447 },
    { lat: 48.758739, lng: 18.7085977 },
    { lat: 48.7591706, lng: 18.7087377 },
    { lat: 48.7596963, lng: 18.7087225 },
    { lat: 48.7602198, lng: 18.7084682 },
    { lat: 48.76071, lng: 18.7081624 },
    { lat: 48.7610955, lng: 18.7082036 },
    { lat: 48.7613033, lng: 18.7082724 },
    { lat: 48.7614731, lng: 18.7083901 },
    { lat: 48.7617429, lng: 18.708588 },
    { lat: 48.7621137, lng: 18.708963 },
    { lat: 48.7622997, lng: 18.7091113 },
    { lat: 48.7625975, lng: 18.7093251 },
    { lat: 48.7632383, lng: 18.7095873 },
    { lat: 48.7635649, lng: 18.7096621 },
    { lat: 48.7640484, lng: 18.7098043 },
    { lat: 48.7643929, lng: 18.7100485 },
    { lat: 48.7645191, lng: 18.7101119 },
    { lat: 48.7647238, lng: 18.7101879 },
    { lat: 48.7648901, lng: 18.7102574 },
    { lat: 48.765034, lng: 18.7103561 },
    { lat: 48.7652339, lng: 18.7105107 },
    { lat: 48.7654379, lng: 18.7106378 },
    { lat: 48.7656835, lng: 18.7107189 },
    { lat: 48.76584, lng: 18.7106931 },
    { lat: 48.7662579, lng: 18.7106807 },
    { lat: 48.7664892, lng: 18.7106612 },
    { lat: 48.7666508, lng: 18.7107099 },
    { lat: 48.766789, lng: 18.7106817 },
    { lat: 48.7669646, lng: 18.7106336 },
    { lat: 48.7669633, lng: 18.710672 },
    { lat: 48.767654, lng: 18.7108939 },
    { lat: 48.7702281, lng: 18.7116754 },
    { lat: 48.7702962, lng: 18.7116857 },
    { lat: 48.772072, lng: 18.7120385 },
    { lat: 48.7725681, lng: 18.7122058 },
    { lat: 48.7736329, lng: 18.7125697 },
    { lat: 48.7748005, lng: 18.7127471 },
    { lat: 48.7753316, lng: 18.7128951 },
    { lat: 48.7759832, lng: 18.7133381 },
    { lat: 48.7761219, lng: 18.7134225 },
    { lat: 48.7761945, lng: 18.712558 },
    { lat: 48.7762258, lng: 18.7122128 },
    { lat: 48.7762534, lng: 18.7120728 },
    { lat: 48.7762517, lng: 18.7118263 },
    { lat: 48.7762797, lng: 18.7115698 },
    { lat: 48.7763688, lng: 18.7103347 },
    { lat: 48.7764491, lng: 18.7099996 },
    { lat: 48.7764739, lng: 18.7096372 },
    { lat: 48.7762473, lng: 18.7089345 },
    { lat: 48.7761064, lng: 18.7084246 },
    { lat: 48.7760424, lng: 18.7082604 },
    { lat: 48.7760418, lng: 18.7081646 },
    { lat: 48.7762036, lng: 18.7075783 },
    { lat: 48.7763809, lng: 18.7074151 },
    { lat: 48.7767718, lng: 18.7072544 },
    { lat: 48.7769005, lng: 18.7070253 },
    { lat: 48.777138, lng: 18.7067815 },
    { lat: 48.7775129, lng: 18.7065551 },
    { lat: 48.7776596, lng: 18.7063667 },
    { lat: 48.7779843, lng: 18.7059833 },
    { lat: 48.7783929, lng: 18.7055425 },
    { lat: 48.7785588, lng: 18.7050794 },
    { lat: 48.778843, lng: 18.7042035 },
    { lat: 48.778892, lng: 18.704067 },
    { lat: 48.7790361, lng: 18.7036383 },
    { lat: 48.7790769, lng: 18.7035343 },
    { lat: 48.7792035, lng: 18.703604 },
    { lat: 48.7792832, lng: 18.7036816 },
    { lat: 48.7793228, lng: 18.7038298 },
    { lat: 48.7794006, lng: 18.7038765 },
    { lat: 48.7794539, lng: 18.70386 },
    { lat: 48.7794943, lng: 18.7037722 },
    { lat: 48.7795471, lng: 18.703764 },
    { lat: 48.7795878, lng: 18.7037866 },
    { lat: 48.7796456, lng: 18.7037713 },
    { lat: 48.779772, lng: 18.7038382 },
    { lat: 48.7796894, lng: 18.7032393 },
    { lat: 48.7796726, lng: 18.7030908 },
    { lat: 48.779662, lng: 18.7025717 },
    { lat: 48.7798281, lng: 18.7022594 },
    { lat: 48.7798926, lng: 18.702454 },
    { lat: 48.7799694, lng: 18.7027118 },
    { lat: 48.7800124, lng: 18.702694 },
    { lat: 48.7802075, lng: 18.7025452 },
    { lat: 48.780613, lng: 18.7025757 },
    { lat: 48.7807145, lng: 18.702961 },
    { lat: 48.7808355, lng: 18.7030732 },
    { lat: 48.7809312, lng: 18.7035805 },
    { lat: 48.7814447, lng: 18.7035446 },
    { lat: 48.7819085, lng: 18.7038906 },
    { lat: 48.7822533, lng: 18.704099 },
    { lat: 48.7828614, lng: 18.7041003 },
    { lat: 48.7835733, lng: 18.7043418 },
    { lat: 48.784417, lng: 18.7044943 },
    { lat: 48.7847153, lng: 18.7039699 },
    { lat: 48.7850198, lng: 18.7036958 },
    { lat: 48.7851246, lng: 18.7035472 },
    { lat: 48.7852922, lng: 18.7031953 },
    { lat: 48.7853308, lng: 18.703009 },
    { lat: 48.7853465, lng: 18.7028829 },
    { lat: 48.7853354, lng: 18.7026282 },
    { lat: 48.7853124, lng: 18.7024311 },
    { lat: 48.785255, lng: 18.7022403 },
    { lat: 48.7851204, lng: 18.7020189 },
    { lat: 48.7850145, lng: 18.7018933 },
    { lat: 48.7845476, lng: 18.7015531 },
    { lat: 48.7845441, lng: 18.7013403 },
    { lat: 48.7838762, lng: 18.7006351 },
    { lat: 48.7840011, lng: 18.7002222 },
    { lat: 48.7838358, lng: 18.7000821 },
    { lat: 48.7839545, lng: 18.6997283 },
    { lat: 48.7836979, lng: 18.6994623 },
    { lat: 48.7840361, lng: 18.6984832 },
    { lat: 48.7835238, lng: 18.6978195 },
    { lat: 48.7839099, lng: 18.6968939 },
    { lat: 48.7837786, lng: 18.6967124 },
    { lat: 48.7836283, lng: 18.6965344 },
    { lat: 48.7833371, lng: 18.6962361 },
    { lat: 48.783047, lng: 18.6959746 },
    { lat: 48.7831806, lng: 18.6957091 },
    { lat: 48.7834703, lng: 18.6952361 },
    { lat: 48.783636, lng: 18.695001 },
    { lat: 48.7840638, lng: 18.6944383 },
    { lat: 48.7841141, lng: 18.6943042 },
    { lat: 48.7842951, lng: 18.6943347 },
    { lat: 48.7844256, lng: 18.6942022 },
    { lat: 48.7844629, lng: 18.6941784 },
    { lat: 48.784488, lng: 18.6942286 },
    { lat: 48.7844937, lng: 18.6943756 },
    { lat: 48.7845651, lng: 18.6944689 },
    { lat: 48.784576, lng: 18.6946124 },
    { lat: 48.7845484, lng: 18.6949383 },
    { lat: 48.7845667, lng: 18.6950734 },
    { lat: 48.7845548, lng: 18.6952108 },
    { lat: 48.7846445, lng: 18.6954538 },
    { lat: 48.784885, lng: 18.6954828 },
    { lat: 48.7849358, lng: 18.6956122 },
    { lat: 48.7849925, lng: 18.6958699 },
    { lat: 48.7850579, lng: 18.695783 },
    { lat: 48.7855876, lng: 18.6950551 },
    { lat: 48.7858331, lng: 18.694796 },
    { lat: 48.7878793, lng: 18.6926798 },
    { lat: 48.790002, lng: 18.6894779 },
    { lat: 48.7900311, lng: 18.6894526 },
    { lat: 48.79062, lng: 18.6887982 },
    { lat: 48.7914266, lng: 18.6878466 },
    { lat: 48.7928053, lng: 18.6861392 },
    { lat: 48.7935152, lng: 18.6852819 },
    { lat: 48.7953596, lng: 18.6825965 },
    { lat: 48.7967599, lng: 18.6805753 },
    { lat: 48.7943181, lng: 18.6737113 },
    { lat: 48.7925582, lng: 18.6723455 },
    { lat: 48.7925158, lng: 18.6723128 },
    { lat: 48.7918111, lng: 18.6717668 },
    { lat: 48.7914532, lng: 18.6710175 },
    { lat: 48.7908172, lng: 18.6704914 },
    { lat: 48.79077, lng: 18.6704516 },
    { lat: 48.7902414, lng: 18.6700138 },
    { lat: 48.7888722, lng: 18.6685762 },
    { lat: 48.7879611, lng: 18.6665708 },
    { lat: 48.7865531, lng: 18.6646379 },
    { lat: 48.7859986, lng: 18.6644137 },
    { lat: 48.78591, lng: 18.6643777 },
    { lat: 48.7852529, lng: 18.6641125 },
    { lat: 48.7847246, lng: 18.6642086 },
    { lat: 48.7844751, lng: 18.6642899 },
    { lat: 48.7842099, lng: 18.6646245 },
    { lat: 48.784001, lng: 18.665001 },
    { lat: 48.7838441, lng: 18.6653773 },
    { lat: 48.7839792, lng: 18.6659877 },
    { lat: 48.7840552, lng: 18.6662263 },
    { lat: 48.7840809, lng: 18.6663079 },
    { lat: 48.7841006, lng: 18.6663602 },
    { lat: 48.7841268, lng: 18.6664282 },
    { lat: 48.7841411, lng: 18.666464 },
    { lat: 48.7844205, lng: 18.6667512 },
    { lat: 48.7845573, lng: 18.6669056 },
    { lat: 48.7846605, lng: 18.6670661 },
    { lat: 48.7847857, lng: 18.6672988 },
    { lat: 48.7848495, lng: 18.6673529 },
    { lat: 48.7848225, lng: 18.6673959 },
    { lat: 48.7848151, lng: 18.667407 },
    { lat: 48.7845823, lng: 18.6677784 },
    { lat: 48.7844596, lng: 18.6679078 },
    { lat: 48.784081, lng: 18.6682319 },
    { lat: 48.7837589, lng: 18.6684512 },
    { lat: 48.7833902, lng: 18.6686439 },
    { lat: 48.7820788, lng: 18.6691126 },
    { lat: 48.7813973, lng: 18.6693826 },
    { lat: 48.7809367, lng: 18.6695222 },
    { lat: 48.7805529, lng: 18.6697717 },
    { lat: 48.7799441, lng: 18.6699864 },
    { lat: 48.7794635, lng: 18.6698777 },
    { lat: 48.7759973, lng: 18.6691295 },
    { lat: 48.775923, lng: 18.669555 },
    { lat: 48.7758542, lng: 18.6697649 },
    { lat: 48.7758095, lng: 18.6698998 },
    { lat: 48.7756522, lng: 18.6703763 },
    { lat: 48.7746154, lng: 18.6724005 },
    { lat: 48.7745085, lng: 18.6726817 },
    { lat: 48.774372, lng: 18.672702 },
    { lat: 48.7742355, lng: 18.6729335 },
    { lat: 48.7739587, lng: 18.6727975 },
    { lat: 48.7737767, lng: 18.6730646 },
    { lat: 48.7736477, lng: 18.6730339 },
    { lat: 48.7736496, lng: 18.6737487 },
    { lat: 48.7737683, lng: 18.6742173 },
    { lat: 48.7738246, lng: 18.6743867 },
    { lat: 48.77383, lng: 18.6745298 },
    { lat: 48.773672, lng: 18.6747382 },
    { lat: 48.7735873, lng: 18.6748013 },
  ],
  ZemianskeKostoľany: [
    { lat: 48.7018422, lng: 18.5013968 },
    { lat: 48.7016115, lng: 18.5021243 },
    { lat: 48.7013744, lng: 18.5019986 },
    { lat: 48.7009381, lng: 18.5017554 },
    { lat: 48.699955, lng: 18.5011912 },
    { lat: 48.6993366, lng: 18.5009398 },
    { lat: 48.6982453, lng: 18.5005202 },
    { lat: 48.6979212, lng: 18.5002483 },
    { lat: 48.6972979, lng: 18.4998433 },
    { lat: 48.6968133, lng: 18.499483 },
    { lat: 48.6962482, lng: 18.4988079 },
    { lat: 48.6956521, lng: 18.4983438 },
    { lat: 48.695349, lng: 18.4981661 },
    { lat: 48.6949084, lng: 18.4977836 },
    { lat: 48.6940238, lng: 18.4971664 },
    { lat: 48.6933104, lng: 18.4965814 },
    { lat: 48.6931404, lng: 18.4964652 },
    { lat: 48.6928509, lng: 18.4961876 },
    { lat: 48.6923867, lng: 18.4957831 },
    { lat: 48.6921217, lng: 18.4955386 },
    { lat: 48.6917291, lng: 18.4952725 },
    { lat: 48.6915335, lng: 18.4949553 },
    { lat: 48.6913767, lng: 18.4948558 },
    { lat: 48.6911216, lng: 18.4945628 },
    { lat: 48.690981, lng: 18.4943445 },
    { lat: 48.690678, lng: 18.4940254 },
    { lat: 48.6903913, lng: 18.4936304 },
    { lat: 48.6902079, lng: 18.4930847 },
    { lat: 48.6899956, lng: 18.4927928 },
    { lat: 48.689812, lng: 18.4924506 },
    { lat: 48.6896808, lng: 18.4922389 },
    { lat: 48.6895095, lng: 18.491832 },
    { lat: 48.6894442, lng: 18.4915688 },
    { lat: 48.6892668, lng: 18.4909863 },
    { lat: 48.6890547, lng: 18.4904524 },
    { lat: 48.6888626, lng: 18.4900152 },
    { lat: 48.6885176, lng: 18.4896736 },
    { lat: 48.6883216, lng: 18.4894504 },
    { lat: 48.6881199, lng: 18.4892508 },
    { lat: 48.6878386, lng: 18.4889366 },
    { lat: 48.6874573, lng: 18.4886282 },
    { lat: 48.6867111, lng: 18.4879807 },
    { lat: 48.6863161, lng: 18.4873444 },
    { lat: 48.6862432, lng: 18.4871879 },
    { lat: 48.6860806, lng: 18.4869788 },
    { lat: 48.686045, lng: 18.4869072 },
    { lat: 48.6857901, lng: 18.4865787 },
    { lat: 48.6851804, lng: 18.4856681 },
    { lat: 48.6841478, lng: 18.4843683 },
    { lat: 48.6836819, lng: 18.4836518 },
    { lat: 48.6832718, lng: 18.4827173 },
    { lat: 48.6828217, lng: 18.4829827 },
    { lat: 48.6825037, lng: 18.4834792 },
    { lat: 48.681848, lng: 18.4840849 },
    { lat: 48.6824077, lng: 18.4848268 },
    { lat: 48.6824008, lng: 18.4850921 },
    { lat: 48.6823351, lng: 18.485437 },
    { lat: 48.6822667, lng: 18.4855855 },
    { lat: 48.6822, lng: 18.4856657 },
    { lat: 48.6820758, lng: 18.4858872 },
    { lat: 48.6818962, lng: 18.4864163 },
    { lat: 48.6817956, lng: 18.4865989 },
    { lat: 48.6816052, lng: 18.4870519 },
    { lat: 48.6815685, lng: 18.4871671 },
    { lat: 48.6815403, lng: 18.4875566 },
    { lat: 48.6815496, lng: 18.4877857 },
    { lat: 48.6813834, lng: 18.4882324 },
    { lat: 48.6812145, lng: 18.4891192 },
    { lat: 48.681188, lng: 18.4893125 },
    { lat: 48.6812476, lng: 18.4894114 },
    { lat: 48.6813456, lng: 18.4899203 },
    { lat: 48.6813591, lng: 18.4902091 },
    { lat: 48.6814211, lng: 18.4904116 },
    { lat: 48.6814465, lng: 18.4905651 },
    { lat: 48.6814728, lng: 18.4908196 },
    { lat: 48.6814634, lng: 18.4910785 },
    { lat: 48.681552, lng: 18.4916617 },
    { lat: 48.6816002, lng: 18.4920487 },
    { lat: 48.6815966, lng: 18.4923101 },
    { lat: 48.6815459, lng: 18.4928234 },
    { lat: 48.6804579, lng: 18.4953186 },
    { lat: 48.6801677, lng: 18.4968721 },
    { lat: 48.6803407, lng: 18.4978642 },
    { lat: 48.6803859, lng: 18.4984814 },
    { lat: 48.6802892, lng: 18.4990397 },
    { lat: 48.6801906, lng: 18.5004902 },
    { lat: 48.6798321, lng: 18.502221 },
    { lat: 48.6796613, lng: 18.5029949 },
    { lat: 48.6793494, lng: 18.5037025 },
    { lat: 48.6791054, lng: 18.5043342 },
    { lat: 48.6789478, lng: 18.5047501 },
    { lat: 48.6785504, lng: 18.5054024 },
    { lat: 48.6782414, lng: 18.5059193 },
    { lat: 48.6779741, lng: 18.5055666 },
    { lat: 48.6774389, lng: 18.5065873 },
    { lat: 48.678002, lng: 18.5074973 },
    { lat: 48.67799, lng: 18.5076866 },
    { lat: 48.6776221, lng: 18.5083619 },
    { lat: 48.6778905, lng: 18.5086699 },
    { lat: 48.6781068, lng: 18.5092215 },
    { lat: 48.6783284, lng: 18.5096421 },
    { lat: 48.6783686, lng: 18.5102292 },
    { lat: 48.6784692, lng: 18.5108738 },
    { lat: 48.6782112, lng: 18.5113925 },
    { lat: 48.6777188, lng: 18.5124924 },
    { lat: 48.6775061, lng: 18.5137173 },
    { lat: 48.6772233, lng: 18.5136601 },
    { lat: 48.676258, lng: 18.5153945 },
    { lat: 48.6760974, lng: 18.5157158 },
    { lat: 48.6757502, lng: 18.5161318 },
    { lat: 48.6749419, lng: 18.5166771 },
    { lat: 48.6748824, lng: 18.5169287 },
    { lat: 48.6745183, lng: 18.5180442 },
    { lat: 48.6745382, lng: 18.5180523 },
    { lat: 48.6752784, lng: 18.518763 },
    { lat: 48.6746486, lng: 18.5196633 },
    { lat: 48.6744565, lng: 18.5198803 },
    { lat: 48.6743265, lng: 18.5200629 },
    { lat: 48.6741026, lng: 18.5203635 },
    { lat: 48.6737205, lng: 18.5206975 },
    { lat: 48.673308, lng: 18.5209906 },
    { lat: 48.6725632, lng: 18.5216646 },
    { lat: 48.6722816, lng: 18.5222725 },
    { lat: 48.6720729, lng: 18.5226405 },
    { lat: 48.671776, lng: 18.523175 },
    { lat: 48.6715803, lng: 18.5235121 },
    { lat: 48.6710006, lng: 18.5246672 },
    { lat: 48.6704701, lng: 18.5256334 },
    { lat: 48.6703154, lng: 18.5259182 },
    { lat: 48.6696457, lng: 18.5277608 },
    { lat: 48.6695026, lng: 18.529351 },
    { lat: 48.669359, lng: 18.5303339 },
    { lat: 48.6691736, lng: 18.5309469 },
    { lat: 48.6687641, lng: 18.5314801 },
    { lat: 48.6683681, lng: 18.5320372 },
    { lat: 48.667837, lng: 18.5329506 },
    { lat: 48.6722714, lng: 18.5341926 },
    { lat: 48.6730093, lng: 18.5350015 },
    { lat: 48.6736413, lng: 18.535709 },
    { lat: 48.6740443, lng: 18.5361685 },
    { lat: 48.6746794, lng: 18.5370798 },
    { lat: 48.675367, lng: 18.5380635 },
    { lat: 48.6757003, lng: 18.5385485 },
    { lat: 48.6767963, lng: 18.5401285 },
    { lat: 48.6769859, lng: 18.5404236 },
    { lat: 48.6774482, lng: 18.5411199 },
    { lat: 48.67758, lng: 18.5413259 },
    { lat: 48.6780984, lng: 18.5421023 },
    { lat: 48.678583, lng: 18.5428562 },
    { lat: 48.6789204, lng: 18.5424908 },
    { lat: 48.6794388, lng: 18.541902 },
    { lat: 48.679569, lng: 18.5417669 },
    { lat: 48.6803031, lng: 18.5409128 },
    { lat: 48.680396, lng: 18.5408166 },
    { lat: 48.6807427, lng: 18.5410926 },
    { lat: 48.6808681, lng: 18.5412005 },
    { lat: 48.6814841, lng: 18.541741 },
    { lat: 48.6814722, lng: 18.5420602 },
    { lat: 48.681359, lng: 18.5423205 },
    { lat: 48.6820482, lng: 18.5442234 },
    { lat: 48.682134, lng: 18.5443266 },
    { lat: 48.6821471, lng: 18.5443625 },
    { lat: 48.6821894, lng: 18.544601 },
    { lat: 48.6821925, lng: 18.5446588 },
    { lat: 48.6822994, lng: 18.545127 },
    { lat: 48.6823246, lng: 18.5451949 },
    { lat: 48.6826183, lng: 18.5457824 },
    { lat: 48.682634, lng: 18.5459667 },
    { lat: 48.6827642, lng: 18.5461819 },
    { lat: 48.6828987, lng: 18.5466852 },
    { lat: 48.6829868, lng: 18.54737 },
    { lat: 48.6832541, lng: 18.5479718 },
    { lat: 48.6835054, lng: 18.548793 },
    { lat: 48.6836642, lng: 18.5492255 },
    { lat: 48.6837836, lng: 18.5494084 },
    { lat: 48.6838493, lng: 18.5495374 },
    { lat: 48.6840262, lng: 18.5499262 },
    { lat: 48.6842343, lng: 18.5503912 },
    { lat: 48.6846813, lng: 18.5514337 },
    { lat: 48.6848315, lng: 18.5514968 },
    { lat: 48.6849256, lng: 18.5516532 },
    { lat: 48.6852654, lng: 18.5520733 },
    { lat: 48.6857953, lng: 18.5523969 },
    { lat: 48.6860983, lng: 18.5524311 },
    { lat: 48.6862484, lng: 18.5524528 },
    { lat: 48.6873477, lng: 18.552817 },
    { lat: 48.6884434, lng: 18.5531968 },
    { lat: 48.6886787, lng: 18.5532743 },
    { lat: 48.6886628, lng: 18.553498 },
    { lat: 48.6885654, lng: 18.5538021 },
    { lat: 48.6885249, lng: 18.5544472 },
    { lat: 48.6884972, lng: 18.554768 },
    { lat: 48.688432, lng: 18.5553712 },
    { lat: 48.6883859, lng: 18.5555804 },
    { lat: 48.6883421, lng: 18.5557083 },
    { lat: 48.6883643, lng: 18.5557427 },
    { lat: 48.6921707, lng: 18.5623087 },
    { lat: 48.6924496, lng: 18.5627911 },
    { lat: 48.6924741, lng: 18.5627456 },
    { lat: 48.6931179, lng: 18.5612662 },
    { lat: 48.6931402, lng: 18.5612209 },
    { lat: 48.6931097, lng: 18.5611772 },
    { lat: 48.6932094, lng: 18.560981 },
    { lat: 48.693377, lng: 18.5608245 },
    { lat: 48.693501, lng: 18.560636 },
    { lat: 48.6935855, lng: 18.5605516 },
    { lat: 48.6936862, lng: 18.5603288 },
    { lat: 48.6938284, lng: 18.5601458 },
    { lat: 48.693876, lng: 18.5601071 },
    { lat: 48.693902, lng: 18.560046 },
    { lat: 48.6939847, lng: 18.5600111 },
    { lat: 48.6940079, lng: 18.5599608 },
    { lat: 48.6941559, lng: 18.5598821 },
    { lat: 48.6942017, lng: 18.5598117 },
    { lat: 48.6942515, lng: 18.559667 },
    { lat: 48.6944287, lng: 18.5593612 },
    { lat: 48.6945175, lng: 18.5592906 },
    { lat: 48.694605, lng: 18.5591569 },
    { lat: 48.694715, lng: 18.5589029 },
    { lat: 48.6947439, lng: 18.5587904 },
    { lat: 48.6948025, lng: 18.5586646 },
    { lat: 48.6949802, lng: 18.5580945 },
    { lat: 48.6951226, lng: 18.5579961 },
    { lat: 48.6952242, lng: 18.5579782 },
    { lat: 48.6952695, lng: 18.5575413 },
    { lat: 48.6953929, lng: 18.557407 },
    { lat: 48.6956265, lng: 18.5573038 },
    { lat: 48.6956575, lng: 18.5573363 },
    { lat: 48.6957714, lng: 18.5571262 },
    { lat: 48.6957567, lng: 18.5570437 },
    { lat: 48.6958146, lng: 18.5568652 },
    { lat: 48.6959616, lng: 18.5567909 },
    { lat: 48.6961543, lng: 18.5565359 },
    { lat: 48.6962855, lng: 18.5564614 },
    { lat: 48.6964192, lng: 18.5564523 },
    { lat: 48.6965424, lng: 18.5563535 },
    { lat: 48.6966883, lng: 18.5561708 },
    { lat: 48.6967394, lng: 18.5559516 },
    { lat: 48.6969096, lng: 18.555638 },
    { lat: 48.6969274, lng: 18.5554652 },
    { lat: 48.6970812, lng: 18.5551211 },
    { lat: 48.6971554, lng: 18.5550348 },
    { lat: 48.6972408, lng: 18.5546757 },
    { lat: 48.6972791, lng: 18.5545934 },
    { lat: 48.697287, lng: 18.5544911 },
    { lat: 48.697344, lng: 18.5543947 },
    { lat: 48.6973961, lng: 18.5539844 },
    { lat: 48.6973725, lng: 18.5538631 },
    { lat: 48.6972882, lng: 18.5537007 },
    { lat: 48.6972404, lng: 18.5534646 },
    { lat: 48.6973231, lng: 18.5532033 },
    { lat: 48.6972393, lng: 18.5530541 },
    { lat: 48.6972098, lng: 18.5528414 },
    { lat: 48.6972221, lng: 18.552756 },
    { lat: 48.6970575, lng: 18.5524501 },
    { lat: 48.6970472, lng: 18.5523424 },
    { lat: 48.6969316, lng: 18.5520608 },
    { lat: 48.6969434, lng: 18.5518869 },
    { lat: 48.6969951, lng: 18.5517773 },
    { lat: 48.6968917, lng: 18.5515366 },
    { lat: 48.6969813, lng: 18.5511106 },
    { lat: 48.6969344, lng: 18.550624 },
    { lat: 48.6969166, lng: 18.550523 },
    { lat: 48.6969419, lng: 18.5504492 },
    { lat: 48.6969329, lng: 18.550305 },
    { lat: 48.696885, lng: 18.5502335 },
    { lat: 48.6968089, lng: 18.5502036 },
    { lat: 48.6966657, lng: 18.5500393 },
    { lat: 48.6964825, lng: 18.5497168 },
    { lat: 48.6964028, lng: 18.5493515 },
    { lat: 48.6964393, lng: 18.5489892 },
    { lat: 48.6963486, lng: 18.5488941 },
    { lat: 48.6962762, lng: 18.5483939 },
    { lat: 48.696367, lng: 18.5480966 },
    { lat: 48.6962702, lng: 18.5478733 },
    { lat: 48.6962866, lng: 18.5478009 },
    { lat: 48.6962124, lng: 18.5475577 },
    { lat: 48.696268, lng: 18.5474591 },
    { lat: 48.696276, lng: 18.5472767 },
    { lat: 48.6962038, lng: 18.5470654 },
    { lat: 48.6962381, lng: 18.5468665 },
    { lat: 48.6963123, lng: 18.5468023 },
    { lat: 48.6963551, lng: 18.5466377 },
    { lat: 48.6964203, lng: 18.54661 },
    { lat: 48.6965706, lng: 18.5464099 },
    { lat: 48.6966156, lng: 18.5464296 },
    { lat: 48.6967143, lng: 18.5463756 },
    { lat: 48.6967469, lng: 18.5463098 },
    { lat: 48.6968169, lng: 18.5462725 },
    { lat: 48.6969341, lng: 18.5462486 },
    { lat: 48.6970343, lng: 18.5462447 },
    { lat: 48.697079, lng: 18.5461967 },
    { lat: 48.6970758, lng: 18.5460732 },
    { lat: 48.6971863, lng: 18.5458666 },
    { lat: 48.6971791, lng: 18.5457965 },
    { lat: 48.6972294, lng: 18.5457256 },
    { lat: 48.6972924, lng: 18.5457242 },
    { lat: 48.6973064, lng: 18.5457124 },
    { lat: 48.6972916, lng: 18.5456871 },
    { lat: 48.6973581, lng: 18.5455414 },
    { lat: 48.6973348, lng: 18.5454657 },
    { lat: 48.697363, lng: 18.5454276 },
    { lat: 48.6973454, lng: 18.5453091 },
    { lat: 48.6973992, lng: 18.5451164 },
    { lat: 48.6973939, lng: 18.5450399 },
    { lat: 48.6975113, lng: 18.5447304 },
    { lat: 48.6975282, lng: 18.5445251 },
    { lat: 48.6976901, lng: 18.5443686 },
    { lat: 48.69779, lng: 18.5441337 },
    { lat: 48.6979249, lng: 18.5439376 },
    { lat: 48.6980136, lng: 18.5437038 },
    { lat: 48.6982462, lng: 18.5436052 },
    { lat: 48.6984203, lng: 18.5434254 },
    { lat: 48.6986045, lng: 18.5428326 },
    { lat: 48.6986046, lng: 18.5424394 },
    { lat: 48.6986245, lng: 18.5422448 },
    { lat: 48.6986928, lng: 18.5416593 },
    { lat: 48.6987306, lng: 18.5412343 },
    { lat: 48.6988295, lng: 18.5404446 },
    { lat: 48.6988857, lng: 18.539901 },
    { lat: 48.6989999, lng: 18.5384381 },
    { lat: 48.6990758, lng: 18.5379266 },
    { lat: 48.6991172, lng: 18.5376111 },
    { lat: 48.6991978, lng: 18.537144 },
    { lat: 48.69928, lng: 18.5367485 },
    { lat: 48.6993683, lng: 18.536344 },
    { lat: 48.6994275, lng: 18.535731 },
    { lat: 48.6995236, lng: 18.5355733 },
    { lat: 48.6996446, lng: 18.5352487 },
    { lat: 48.6998222, lng: 18.5346991 },
    { lat: 48.6997801, lng: 18.5346912 },
    { lat: 48.6998087, lng: 18.5343269 },
    { lat: 48.6998323, lng: 18.5341797 },
    { lat: 48.6998413, lng: 18.5340144 },
    { lat: 48.6998327, lng: 18.533798 },
    { lat: 48.6998392, lng: 18.5337682 },
    { lat: 48.6999859, lng: 18.531626 },
    { lat: 48.7000709, lng: 18.5316325 },
    { lat: 48.7001582, lng: 18.5303786 },
    { lat: 48.7002024, lng: 18.5297639 },
    { lat: 48.7002551, lng: 18.5290978 },
    { lat: 48.6991605, lng: 18.5273122 },
    { lat: 48.6988044, lng: 18.5267235 },
    { lat: 48.6994296, lng: 18.5255938 },
    { lat: 48.6996, lng: 18.5253248 },
    { lat: 48.6998247, lng: 18.525146 },
    { lat: 48.700173, lng: 18.5249104 },
    { lat: 48.7002808, lng: 18.5247995 },
    { lat: 48.7004235, lng: 18.5246035 },
    { lat: 48.7004714, lng: 18.5245107 },
    { lat: 48.700874, lng: 18.5234585 },
    { lat: 48.7010273, lng: 18.5233518 },
    { lat: 48.7011753, lng: 18.5233344 },
    { lat: 48.7012115, lng: 18.5233578 },
    { lat: 48.7013038, lng: 18.5234598 },
    { lat: 48.7016966, lng: 18.522888 },
    { lat: 48.7017262, lng: 18.5222697 },
    { lat: 48.7037219, lng: 18.5223401 },
    { lat: 48.7047816, lng: 18.5224528 },
    { lat: 48.7047862, lng: 18.5223686 },
    { lat: 48.70482, lng: 18.5220794 },
    { lat: 48.7048247, lng: 18.5219996 },
    { lat: 48.7050577, lng: 18.5219904 },
    { lat: 48.7054319, lng: 18.5205648 },
    { lat: 48.7058031, lng: 18.520731 },
    { lat: 48.7059231, lng: 18.5203063 },
    { lat: 48.7060817, lng: 18.5199047 },
    { lat: 48.7065261, lng: 18.5179489 },
    { lat: 48.7064589, lng: 18.5176873 },
    { lat: 48.7072056, lng: 18.5155032 },
    { lat: 48.7072805, lng: 18.5138021 },
    { lat: 48.7075593, lng: 18.5137447 },
    { lat: 48.7080449, lng: 18.513814 },
    { lat: 48.7085766, lng: 18.5134321 },
    { lat: 48.7088194, lng: 18.513355 },
    { lat: 48.7091653, lng: 18.5133056 },
    { lat: 48.7101071, lng: 18.5127278 },
    { lat: 48.7103881, lng: 18.5124634 },
    { lat: 48.7106328, lng: 18.512007 },
    { lat: 48.7107911, lng: 18.5118079 },
    { lat: 48.711172, lng: 18.5114299 },
    { lat: 48.7114318, lng: 18.5111502 },
    { lat: 48.7120203, lng: 18.5107301 },
    { lat: 48.7125885, lng: 18.5105168 },
    { lat: 48.7127954, lng: 18.510407 },
    { lat: 48.7132675, lng: 18.510281 },
    { lat: 48.7133211, lng: 18.5099922 },
    { lat: 48.7129953, lng: 18.5096069 },
    { lat: 48.7128539, lng: 18.5094949 },
    { lat: 48.7128106, lng: 18.509425 },
    { lat: 48.7128, lng: 18.5092741 },
    { lat: 48.712653, lng: 18.5090207 },
    { lat: 48.7124564, lng: 18.5087449 },
    { lat: 48.7124193, lng: 18.5085442 },
    { lat: 48.7122371, lng: 18.5081423 },
    { lat: 48.7121885, lng: 18.5079548 },
    { lat: 48.7121161, lng: 18.5077863 },
    { lat: 48.7120044, lng: 18.5075807 },
    { lat: 48.711941, lng: 18.5074339 },
    { lat: 48.7119216, lng: 18.5073688 },
    { lat: 48.7118064, lng: 18.507199 },
    { lat: 48.711696, lng: 18.5069738 },
    { lat: 48.7115832, lng: 18.506874 },
    { lat: 48.7114865, lng: 18.5066932 },
    { lat: 48.7112855, lng: 18.5066916 },
    { lat: 48.7110959, lng: 18.5067907 },
    { lat: 48.7102171, lng: 18.5070365 },
    { lat: 48.7099147, lng: 18.5069315 },
    { lat: 48.7096004, lng: 18.5068394 },
    { lat: 48.7094111, lng: 18.5066967 },
    { lat: 48.7092166, lng: 18.506461 },
    { lat: 48.7089746, lng: 18.5062677 },
    { lat: 48.708666, lng: 18.505986 },
    { lat: 48.7083119, lng: 18.5055964 },
    { lat: 48.7079455, lng: 18.5054383 },
    { lat: 48.7073103, lng: 18.5051871 },
    { lat: 48.7050963, lng: 18.5041458 },
    { lat: 48.7049056, lng: 18.5044101 },
    { lat: 48.7044071, lng: 18.5042304 },
    { lat: 48.703902, lng: 18.5034501 },
    { lat: 48.7025475, lng: 18.5021398 },
    { lat: 48.7021596, lng: 18.501857 },
    { lat: 48.7018422, lng: 18.5013968 },
  ],
  Čavoj: [
    { lat: 48.8676913, lng: 18.4965273 },
    { lat: 48.867982, lng: 18.4970182 },
    { lat: 48.867921, lng: 18.4977776 },
    { lat: 48.8679529, lng: 18.4984436 },
    { lat: 48.8680174, lng: 18.499108 },
    { lat: 48.8684581, lng: 18.500193 },
    { lat: 48.8695628, lng: 18.5032502 },
    { lat: 48.8701917, lng: 18.5047499 },
    { lat: 48.8705514, lng: 18.5057348 },
    { lat: 48.8707923, lng: 18.5071106 },
    { lat: 48.871186, lng: 18.5085969 },
    { lat: 48.8726287, lng: 18.5092785 },
    { lat: 48.8743555, lng: 18.5099543 },
    { lat: 48.8752426, lng: 18.5111229 },
    { lat: 48.8755902, lng: 18.512594 },
    { lat: 48.8759298, lng: 18.5141414 },
    { lat: 48.8763553, lng: 18.5156547 },
    { lat: 48.8764634, lng: 18.5163969 },
    { lat: 48.8764416, lng: 18.5164512 },
    { lat: 48.8768345, lng: 18.5162704 },
    { lat: 48.8772154, lng: 18.5159134 },
    { lat: 48.8775, lng: 18.5153842 },
    { lat: 48.8778371, lng: 18.5151742 },
    { lat: 48.8783373, lng: 18.5149756 },
    { lat: 48.8787231, lng: 18.5146857 },
    { lat: 48.8795327, lng: 18.5147035 },
    { lat: 48.8803319, lng: 18.514319 },
    { lat: 48.8810284, lng: 18.5138761 },
    { lat: 48.8814109, lng: 18.5140252 },
    { lat: 48.8817, lng: 18.5140068 },
    { lat: 48.8828671, lng: 18.5142696 },
    { lat: 48.8833628, lng: 18.5143232 },
    { lat: 48.8836268, lng: 18.514218 },
    { lat: 48.8837644, lng: 18.5141584 },
    { lat: 48.8842716, lng: 18.5140238 },
    { lat: 48.8847978, lng: 18.5140366 },
    { lat: 48.8850803, lng: 18.5138779 },
    { lat: 48.8854481, lng: 18.5136959 },
    { lat: 48.8857835, lng: 18.5134527 },
    { lat: 48.8859981, lng: 18.5133964 },
    { lat: 48.8861271, lng: 18.5133426 },
    { lat: 48.8863366, lng: 18.5131116 },
    { lat: 48.8864153, lng: 18.5129372 },
    { lat: 48.8864092, lng: 18.5125577 },
    { lat: 48.8866718, lng: 18.512038 },
    { lat: 48.8868903, lng: 18.5116403 },
    { lat: 48.8870762, lng: 18.5113879 },
    { lat: 48.8871149, lng: 18.5112702 },
    { lat: 48.887245, lng: 18.5104699 },
    { lat: 48.8874053, lng: 18.5094197 },
    { lat: 48.8875439, lng: 18.5090438 },
    { lat: 48.8877352, lng: 18.5086872 },
    { lat: 48.8877389, lng: 18.5086717 },
    { lat: 48.888026, lng: 18.5081474 },
    { lat: 48.8882455, lng: 18.5079944 },
    { lat: 48.8885122, lng: 18.508087 },
    { lat: 48.8885981, lng: 18.5081092 },
    { lat: 48.8891813, lng: 18.5079212 },
    { lat: 48.8892974, lng: 18.5079002 },
    { lat: 48.8894861, lng: 18.5079274 },
    { lat: 48.8896017, lng: 18.5078968 },
    { lat: 48.8898681, lng: 18.5077543 },
    { lat: 48.8903699, lng: 18.5075041 },
    { lat: 48.8904398, lng: 18.5074631 },
    { lat: 48.8906758, lng: 18.5073435 },
    { lat: 48.8910494, lng: 18.5073073 },
    { lat: 48.8916923, lng: 18.5072201 },
    { lat: 48.8918392, lng: 18.5072482 },
    { lat: 48.891995, lng: 18.5072928 },
    { lat: 48.8923651, lng: 18.507603 },
    { lat: 48.892652, lng: 18.5080053 },
    { lat: 48.8928916, lng: 18.5082591 },
    { lat: 48.8931624, lng: 18.508447 },
    { lat: 48.8934346, lng: 18.5085978 },
    { lat: 48.8937251, lng: 18.5086925 },
    { lat: 48.8938337, lng: 18.5086992 },
    { lat: 48.8940032, lng: 18.5086823 },
    { lat: 48.8941827, lng: 18.5086009 },
    { lat: 48.8942381, lng: 18.5085043 },
    { lat: 48.8944106, lng: 18.5078628 },
    { lat: 48.8944507, lng: 18.5077875 },
    { lat: 48.8947207, lng: 18.5074027 },
    { lat: 48.894857, lng: 18.5072766 },
    { lat: 48.8949169, lng: 18.5072396 },
    { lat: 48.8950298, lng: 18.5071986 },
    { lat: 48.8950761, lng: 18.5071974 },
    { lat: 48.8953219, lng: 18.5072583 },
    { lat: 48.8954674, lng: 18.5073217 },
    { lat: 48.8957145, lng: 18.5073842 },
    { lat: 48.895844, lng: 18.507378 },
    { lat: 48.8961361, lng: 18.5073994 },
    { lat: 48.8962386, lng: 18.5074367 },
    { lat: 48.8963125, lng: 18.5074919 },
    { lat: 48.8966322, lng: 18.5079131 },
    { lat: 48.8967109, lng: 18.5079907 },
    { lat: 48.8968354, lng: 18.5079987 },
    { lat: 48.8970207, lng: 18.5079808 },
    { lat: 48.8974853, lng: 18.5079192 },
    { lat: 48.8975788, lng: 18.5079548 },
    { lat: 48.8976365, lng: 18.5080031 },
    { lat: 48.8977728, lng: 18.5081617 },
    { lat: 48.897857, lng: 18.5083411 },
    { lat: 48.8979083, lng: 18.5085394 },
    { lat: 48.8980146, lng: 18.5086027 },
    { lat: 48.8984797, lng: 18.5091258 },
    { lat: 48.8987304, lng: 18.5094449 },
    { lat: 48.8989952, lng: 18.5097271 },
    { lat: 48.8993046, lng: 18.5100435 },
    { lat: 48.8997532, lng: 18.5108222 },
    { lat: 48.9000053, lng: 18.5112451 },
    { lat: 48.9001811, lng: 18.5113865 },
    { lat: 48.9003536, lng: 18.5115334 },
    { lat: 48.9006431, lng: 18.5115659 },
    { lat: 48.9009855, lng: 18.5116602 },
    { lat: 48.9011854, lng: 18.5116634 },
    { lat: 48.9014082, lng: 18.511653 },
    { lat: 48.9016362, lng: 18.5116774 },
    { lat: 48.901808, lng: 18.5117239 },
    { lat: 48.9019239, lng: 18.5117201 },
    { lat: 48.902134, lng: 18.5117024 },
    { lat: 48.9025422, lng: 18.5115994 },
    { lat: 48.902947, lng: 18.5114489 },
    { lat: 48.9033343, lng: 18.5115 },
    { lat: 48.9037722, lng: 18.511649 },
    { lat: 48.9038624, lng: 18.5117079 },
    { lat: 48.9041284, lng: 18.511809 },
    { lat: 48.9043516, lng: 18.5119713 },
    { lat: 48.9045767, lng: 18.5120463 },
    { lat: 48.9047389, lng: 18.5120432 },
    { lat: 48.90487, lng: 18.5120891 },
    { lat: 48.905115, lng: 18.5121121 },
    { lat: 48.9056111, lng: 18.511987 },
    { lat: 48.9061317, lng: 18.5117685 },
    { lat: 48.9065473, lng: 18.511427 },
    { lat: 48.9070888, lng: 18.5115092 },
    { lat: 48.9073996, lng: 18.5115817 },
    { lat: 48.9077027, lng: 18.5116401 },
    { lat: 48.9080111, lng: 18.5117734 },
    { lat: 48.908464, lng: 18.511946 },
    { lat: 48.9086456, lng: 18.5120093 },
    { lat: 48.9093898, lng: 18.512396 },
    { lat: 48.909657, lng: 18.5125801 },
    { lat: 48.9099778, lng: 18.513083 },
    { lat: 48.9106479, lng: 18.5141184 },
    { lat: 48.9111246, lng: 18.5148163 },
    { lat: 48.9113746, lng: 18.5150355 },
    { lat: 48.9120934, lng: 18.5156414 },
    { lat: 48.9128766, lng: 18.5163126 },
    { lat: 48.9133861, lng: 18.5169246 },
    { lat: 48.9146878, lng: 18.5173386 },
    { lat: 48.9149122, lng: 18.517522 },
    { lat: 48.9152894, lng: 18.5176558 },
    { lat: 48.9154816, lng: 18.5180771 },
    { lat: 48.9155845, lng: 18.5184339 },
    { lat: 48.9156639, lng: 18.5189415 },
    { lat: 48.9159452, lng: 18.5194836 },
    { lat: 48.9162351, lng: 18.5197538 },
    { lat: 48.916561, lng: 18.5202911 },
    { lat: 48.9167384, lng: 18.5205883 },
    { lat: 48.9169443, lng: 18.5209922 },
    { lat: 48.9170768, lng: 18.5212678 },
    { lat: 48.9173478, lng: 18.5220433 },
    { lat: 48.917576, lng: 18.5228948 },
    { lat: 48.9177183, lng: 18.523416 },
    { lat: 48.9178713, lng: 18.5241765 },
    { lat: 48.9179866, lng: 18.5248237 },
    { lat: 48.9180679, lng: 18.5250524 },
    { lat: 48.9183552, lng: 18.5254198 },
    { lat: 48.9185773, lng: 18.525874 },
    { lat: 48.9187934, lng: 18.5262357 },
    { lat: 48.9193663, lng: 18.5264218 },
    { lat: 48.9192679, lng: 18.5261475 },
    { lat: 48.9192956, lng: 18.5258908 },
    { lat: 48.9190037, lng: 18.5246334 },
    { lat: 48.9184799, lng: 18.5239822 },
    { lat: 48.9181575, lng: 18.5238601 },
    { lat: 48.9178824, lng: 18.5229919 },
    { lat: 48.9177379, lng: 18.5223487 },
    { lat: 48.9176856, lng: 18.5214321 },
    { lat: 48.917692, lng: 18.5210942 },
    { lat: 48.9175361, lng: 18.520385 },
    { lat: 48.9174677, lng: 18.5199144 },
    { lat: 48.9173566, lng: 18.5184963 },
    { lat: 48.9169958, lng: 18.5175058 },
    { lat: 48.9169278, lng: 18.5170245 },
    { lat: 48.9163596, lng: 18.516843 },
    { lat: 48.9162645, lng: 18.5167764 },
    { lat: 48.9159714, lng: 18.5163433 },
    { lat: 48.9159479, lng: 18.516224 },
    { lat: 48.9154481, lng: 18.5154974 },
    { lat: 48.9153702, lng: 18.5154125 },
    { lat: 48.9149124, lng: 18.5147775 },
    { lat: 48.9147358, lng: 18.5147025 },
    { lat: 48.9145525, lng: 18.5142973 },
    { lat: 48.9145364, lng: 18.514271 },
    { lat: 48.9139991, lng: 18.5134767 },
    { lat: 48.9138433, lng: 18.5131261 },
    { lat: 48.9135527, lng: 18.5126997 },
    { lat: 48.9134194, lng: 18.5121182 },
    { lat: 48.9133722, lng: 18.5119126 },
    { lat: 48.9133217, lng: 18.5118146 },
    { lat: 48.9134833, lng: 18.510661 },
    { lat: 48.9138094, lng: 18.5097981 },
    { lat: 48.9140144, lng: 18.5086951 },
    { lat: 48.9140002, lng: 18.508188 },
    { lat: 48.9139334, lng: 18.5077484 },
    { lat: 48.9138781, lng: 18.5070851 },
    { lat: 48.9137822, lng: 18.5065722 },
    { lat: 48.9134161, lng: 18.5055244 },
    { lat: 48.9132531, lng: 18.5052279 },
    { lat: 48.9125857, lng: 18.5053982 },
    { lat: 48.9118351, lng: 18.5051842 },
    { lat: 48.911035, lng: 18.5043116 },
    { lat: 48.9109761, lng: 18.5039113 },
    { lat: 48.9107858, lng: 18.5024319 },
    { lat: 48.9108821, lng: 18.5013053 },
    { lat: 48.9106926, lng: 18.5010394 },
    { lat: 48.9106178, lng: 18.5008035 },
    { lat: 48.9106669, lng: 18.500154 },
    { lat: 48.9103168, lng: 18.4990545 },
    { lat: 48.9099116, lng: 18.4986171 },
    { lat: 48.9094228, lng: 18.4985481 },
    { lat: 48.9091031, lng: 18.4986848 },
    { lat: 48.9082211, lng: 18.4985828 },
    { lat: 48.9078026, lng: 18.4983734 },
    { lat: 48.9077535, lng: 18.4979948 },
    { lat: 48.9077723, lng: 18.4978506 },
    { lat: 48.9077782, lng: 18.4978056 },
    { lat: 48.9077323, lng: 18.4975417 },
    { lat: 48.9076155, lng: 18.4969741 },
    { lat: 48.9076096, lng: 18.496702 },
    { lat: 48.9074257, lng: 18.4962066 },
    { lat: 48.907383, lng: 18.4960424 },
    { lat: 48.9073896, lng: 18.4958142 },
    { lat: 48.9071324, lng: 18.4949067 },
    { lat: 48.9070382, lng: 18.4943427 },
    { lat: 48.9070031, lng: 18.4943396 },
    { lat: 48.9069621, lng: 18.4940827 },
    { lat: 48.9070596, lng: 18.4933073 },
    { lat: 48.9068379, lng: 18.4928645 },
    { lat: 48.9068308, lng: 18.4923666 },
    { lat: 48.9067709, lng: 18.4920307 },
    { lat: 48.9067021, lng: 18.4916445 },
    { lat: 48.9064811, lng: 18.4913331 },
    { lat: 48.9064277, lng: 18.4912251 },
    { lat: 48.9061472, lng: 18.4905928 },
    { lat: 48.9058876, lng: 18.4899923 },
    { lat: 48.9059376, lng: 18.4893413 },
    { lat: 48.9060159, lng: 18.4890797 },
    { lat: 48.9055388, lng: 18.4885686 },
    { lat: 48.9054227, lng: 18.4879296 },
    { lat: 48.905324, lng: 18.4875688 },
    { lat: 48.9053287, lng: 18.4873057 },
    { lat: 48.9052461, lng: 18.4868901 },
    { lat: 48.9050645, lng: 18.4863755 },
    { lat: 48.9049792, lng: 18.4862199 },
    { lat: 48.9047566, lng: 18.4857976 },
    { lat: 48.9044179, lng: 18.4851777 },
    { lat: 48.9040858, lng: 18.4845523 },
    { lat: 48.9038212, lng: 18.4840256 },
    { lat: 48.903121, lng: 18.4829207 },
    { lat: 48.9028276, lng: 18.4820735 },
    { lat: 48.902996, lng: 18.4812351 },
    { lat: 48.9031203, lng: 18.4806013 },
    { lat: 48.9031268, lng: 18.4804533 },
    { lat: 48.9031358, lng: 18.4802469 },
    { lat: 48.9030066, lng: 18.479848 },
    { lat: 48.9025856, lng: 18.4793475 },
    { lat: 48.9022574, lng: 18.4790821 },
    { lat: 48.9020946, lng: 18.4788075 },
    { lat: 48.9021025, lng: 18.4782103 },
    { lat: 48.9021476, lng: 18.4773046 },
    { lat: 48.9022784, lng: 18.4762171 },
    { lat: 48.9024568, lng: 18.4752942 },
    { lat: 48.9025073, lng: 18.4744657 },
    { lat: 48.9022434, lng: 18.4743649 },
    { lat: 48.9018475, lng: 18.4728087 },
    { lat: 48.9018147, lng: 18.4722097 },
    { lat: 48.9018282, lng: 18.4715096 },
    { lat: 48.9021988, lng: 18.4705147 },
    { lat: 48.9022624, lng: 18.4688588 },
    { lat: 48.9025732, lng: 18.4679256 },
    { lat: 48.9026994, lng: 18.4677615 },
    { lat: 48.9027779, lng: 18.4676283 },
    { lat: 48.9029694, lng: 18.4674388 },
    { lat: 48.9030432, lng: 18.4673018 },
    { lat: 48.9032748, lng: 18.4670215 },
    { lat: 48.9033491, lng: 18.4669372 },
    { lat: 48.9034467, lng: 18.4668033 },
    { lat: 48.903553, lng: 18.4667471 },
    { lat: 48.90378, lng: 18.4663444 },
    { lat: 48.9038799, lng: 18.4661489 },
    { lat: 48.9039529, lng: 18.4659152 },
    { lat: 48.9043132, lng: 18.4644899 },
    { lat: 48.9043533, lng: 18.4643156 },
    { lat: 48.9044452, lng: 18.4641006 },
    { lat: 48.9044678, lng: 18.4640981 },
    { lat: 48.9044794, lng: 18.4639618 },
    { lat: 48.9045635, lng: 18.4637278 },
    { lat: 48.9046724, lng: 18.4636366 },
    { lat: 48.9046956, lng: 18.4635651 },
    { lat: 48.90482, lng: 18.4634063 },
    { lat: 48.9048898, lng: 18.4633433 },
    { lat: 48.9050597, lng: 18.4631701 },
    { lat: 48.9052505, lng: 18.4629305 },
    { lat: 48.9052264, lng: 18.4628396 },
    { lat: 48.905175, lng: 18.4627866 },
    { lat: 48.9050718, lng: 18.4626555 },
    { lat: 48.9046935, lng: 18.4624007 },
    { lat: 48.9046972, lng: 18.4623253 },
    { lat: 48.904667, lng: 18.462228 },
    { lat: 48.9045371, lng: 18.4621817 },
    { lat: 48.9044904, lng: 18.4621324 },
    { lat: 48.9042995, lng: 18.4620437 },
    { lat: 48.9042679, lng: 18.4619634 },
    { lat: 48.9041737, lng: 18.4618283 },
    { lat: 48.9040887, lng: 18.4616806 },
    { lat: 48.9041146, lng: 18.4613682 },
    { lat: 48.9040927, lng: 18.46122 },
    { lat: 48.9040737, lng: 18.4609768 },
    { lat: 48.903987, lng: 18.4606088 },
    { lat: 48.904016, lng: 18.4603796 },
    { lat: 48.9038802, lng: 18.4602268 },
    { lat: 48.9038399, lng: 18.4599035 },
    { lat: 48.9039483, lng: 18.4597004 },
    { lat: 48.9039958, lng: 18.4595402 },
    { lat: 48.9039237, lng: 18.4593779 },
    { lat: 48.9037667, lng: 18.4593548 },
    { lat: 48.9037337, lng: 18.4593055 },
    { lat: 48.9037838, lng: 18.4592537 },
    { lat: 48.9037859, lng: 18.4591494 },
    { lat: 48.9037081, lng: 18.4591485 },
    { lat: 48.9036774, lng: 18.4591033 },
    { lat: 48.9036544, lng: 18.4590162 },
    { lat: 48.9035831, lng: 18.4589047 },
    { lat: 48.9035784, lng: 18.4588021 },
    { lat: 48.9035927, lng: 18.4585894 },
    { lat: 48.9035897, lng: 18.4585357 },
    { lat: 48.9035129, lng: 18.4584923 },
    { lat: 48.9033132, lng: 18.4585104 },
    { lat: 48.9032506, lng: 18.4583179 },
    { lat: 48.9033093, lng: 18.4581359 },
    { lat: 48.9032885, lng: 18.4579585 },
    { lat: 48.9031697, lng: 18.4579144 },
    { lat: 48.9031107, lng: 18.457786 },
    { lat: 48.903082, lng: 18.457549 },
    { lat: 48.9030375, lng: 18.4574786 },
    { lat: 48.9029331, lng: 18.4575496 },
    { lat: 48.9028828, lng: 18.4574986 },
    { lat: 48.9027533, lng: 18.457666 },
    { lat: 48.9027862, lng: 18.4577704 },
    { lat: 48.9028849, lng: 18.4578035 },
    { lat: 48.9029321, lng: 18.4578405 },
    { lat: 48.9029262, lng: 18.4578961 },
    { lat: 48.9028879, lng: 18.457896 },
    { lat: 48.902812, lng: 18.4579518 },
    { lat: 48.902782, lng: 18.458003 },
    { lat: 48.9028473, lng: 18.4581033 },
    { lat: 48.9027997, lng: 18.4581786 },
    { lat: 48.9027019, lng: 18.4581895 },
    { lat: 48.90267, lng: 18.4581803 },
    { lat: 48.9025119, lng: 18.4579751 },
    { lat: 48.902085, lng: 18.4575115 },
    { lat: 48.9018541, lng: 18.4573141 },
    { lat: 48.9017072, lng: 18.4572058 },
    { lat: 48.9016261, lng: 18.4571278 },
    { lat: 48.9011755, lng: 18.4567923 },
    { lat: 48.9008396, lng: 18.4566725 },
    { lat: 48.9006161, lng: 18.4565667 },
    { lat: 48.9004237, lng: 18.4563738 },
    { lat: 48.900224, lng: 18.4561244 },
    { lat: 48.9001647, lng: 18.45601 },
    { lat: 48.9001178, lng: 18.4559561 },
    { lat: 48.9001118, lng: 18.4558303 },
    { lat: 48.89998, lng: 18.456056 },
    { lat: 48.899876, lng: 18.4559093 },
    { lat: 48.8998013, lng: 18.4557818 },
    { lat: 48.8996123, lng: 18.4556078 },
    { lat: 48.8993968, lng: 18.4553813 },
    { lat: 48.8991497, lng: 18.4551728 },
    { lat: 48.8990805, lng: 18.4547201 },
    { lat: 48.8991809, lng: 18.4545331 },
    { lat: 48.8991708, lng: 18.4544541 },
    { lat: 48.8991719, lng: 18.4543926 },
    { lat: 48.8991802, lng: 18.4543374 },
    { lat: 48.8991587, lng: 18.4542559 },
    { lat: 48.8989006, lng: 18.4539093 },
    { lat: 48.8987391, lng: 18.4540101 },
    { lat: 48.898545, lng: 18.4538213 },
    { lat: 48.8984075, lng: 18.4533329 },
    { lat: 48.8981837, lng: 18.4531196 },
    { lat: 48.8981045, lng: 18.4529737 },
    { lat: 48.8978634, lng: 18.4528365 },
    { lat: 48.8976349, lng: 18.4525764 },
    { lat: 48.8971242, lng: 18.4517646 },
    { lat: 48.8971355, lng: 18.4516854 },
    { lat: 48.8970612, lng: 18.451522 },
    { lat: 48.8970285, lng: 18.4513573 },
    { lat: 48.8969884, lng: 18.4513286 },
    { lat: 48.8969657, lng: 18.4512849 },
    { lat: 48.8968732, lng: 18.4514085 },
    { lat: 48.8967635, lng: 18.4512202 },
    { lat: 48.8967311, lng: 18.45112 },
    { lat: 48.8966767, lng: 18.4507899 },
    { lat: 48.8966886, lng: 18.450636 },
    { lat: 48.8965754, lng: 18.4505686 },
    { lat: 48.8965173, lng: 18.4505186 },
    { lat: 48.8964668, lng: 18.4504375 },
    { lat: 48.896256, lng: 18.4502162 },
    { lat: 48.8961768, lng: 18.4501907 },
    { lat: 48.8960974, lng: 18.4502255 },
    { lat: 48.8959952, lng: 18.4501295 },
    { lat: 48.8959505, lng: 18.4500566 },
    { lat: 48.8959402, lng: 18.4500314 },
    { lat: 48.895692, lng: 18.4496244 },
    { lat: 48.8956137, lng: 18.4494092 },
    { lat: 48.8955528, lng: 18.4493083 },
    { lat: 48.8955311, lng: 18.4493043 },
    { lat: 48.895371, lng: 18.4491012 },
    { lat: 48.8952483, lng: 18.4490634 },
    { lat: 48.8951407, lng: 18.448954 },
    { lat: 48.8949833, lng: 18.44878 },
    { lat: 48.8947949, lng: 18.4488391 },
    { lat: 48.8945727, lng: 18.4487801 },
    { lat: 48.8945027, lng: 18.4487198 },
    { lat: 48.8944169, lng: 18.448573 },
    { lat: 48.8943641, lng: 18.4484133 },
    { lat: 48.8942477, lng: 18.4483306 },
    { lat: 48.8941091, lng: 18.4482894 },
    { lat: 48.8938675, lng: 18.4483663 },
    { lat: 48.8937932, lng: 18.4485151 },
    { lat: 48.8937707, lng: 18.4486184 },
    { lat: 48.8937707, lng: 18.4487596 },
    { lat: 48.8937851, lng: 18.4488578 },
    { lat: 48.8938151, lng: 18.4489519 },
    { lat: 48.8938691, lng: 18.4490511 },
    { lat: 48.8939166, lng: 18.4494635 },
    { lat: 48.8938978, lng: 18.4496161 },
    { lat: 48.8938877, lng: 18.4496557 },
    { lat: 48.8937707, lng: 18.4498663 },
    { lat: 48.8935589, lng: 18.449972 },
    { lat: 48.8933999, lng: 18.4500334 },
    { lat: 48.8932435, lng: 18.4502296 },
    { lat: 48.8931799, lng: 18.4504044 },
    { lat: 48.8931399, lng: 18.4506988 },
    { lat: 48.893123, lng: 18.4507439 },
    { lat: 48.8929975, lng: 18.4509638 },
    { lat: 48.8928425, lng: 18.4514026 },
    { lat: 48.8927729, lng: 18.4517168 },
    { lat: 48.8926895, lng: 18.4519636 },
    { lat: 48.8926136, lng: 18.452121 },
    { lat: 48.8925407, lng: 18.452213 },
    { lat: 48.8924902, lng: 18.4522578 },
    { lat: 48.892338, lng: 18.4521982 },
    { lat: 48.8923244, lng: 18.4520938 },
    { lat: 48.892291, lng: 18.45204 },
    { lat: 48.8922246, lng: 18.4521262 },
    { lat: 48.8922378, lng: 18.45216 },
    { lat: 48.8920821, lng: 18.4528146 },
    { lat: 48.8920755, lng: 18.4529599 },
    { lat: 48.8920561, lng: 18.4531624 },
    { lat: 48.8920089, lng: 18.4531842 },
    { lat: 48.8919441, lng: 18.4531157 },
    { lat: 48.8918832, lng: 18.4531349 },
    { lat: 48.8918126, lng: 18.4532682 },
    { lat: 48.8917751, lng: 18.4537121 },
    { lat: 48.8917332, lng: 18.4538146 },
    { lat: 48.8916057, lng: 18.4538942 },
    { lat: 48.8914464, lng: 18.4538086 },
    { lat: 48.8912957, lng: 18.4537764 },
    { lat: 48.8910575, lng: 18.4536693 },
    { lat: 48.8909429, lng: 18.4537774 },
    { lat: 48.8907726, lng: 18.4540245 },
    { lat: 48.890665, lng: 18.4540367 },
    { lat: 48.8905428, lng: 18.4539521 },
    { lat: 48.8903486, lng: 18.4537437 },
    { lat: 48.8902758, lng: 18.4538935 },
    { lat: 48.8901501, lng: 18.4541944 },
    { lat: 48.8901185, lng: 18.45442 },
    { lat: 48.8901928, lng: 18.4547647 },
    { lat: 48.8901457, lng: 18.4548485 },
    { lat: 48.8900544, lng: 18.4549328 },
    { lat: 48.8898589, lng: 18.4549081 },
    { lat: 48.8895643, lng: 18.4551089 },
    { lat: 48.8893142, lng: 18.4551764 },
    { lat: 48.8890326, lng: 18.455325 },
    { lat: 48.8889653, lng: 18.4554343 },
    { lat: 48.8888127, lng: 18.4557791 },
    { lat: 48.888727, lng: 18.455862 },
    { lat: 48.888582, lng: 18.4558488 },
    { lat: 48.8884785, lng: 18.4557938 },
    { lat: 48.8882934, lng: 18.4557929 },
    { lat: 48.8881137, lng: 18.4558257 },
    { lat: 48.8880297, lng: 18.4558804 },
    { lat: 48.8879281, lng: 18.4559801 },
    { lat: 48.8877729, lng: 18.4562528 },
    { lat: 48.8877279, lng: 18.4564001 },
    { lat: 48.8877192, lng: 18.4565693 },
    { lat: 48.8876351, lng: 18.4566394 },
    { lat: 48.887439, lng: 18.4566618 },
    { lat: 48.8873273, lng: 18.4566852 },
    { lat: 48.8871122, lng: 18.4567544 },
    { lat: 48.8870114, lng: 18.4567647 },
    { lat: 48.8868981, lng: 18.4567163 },
    { lat: 48.8867766, lng: 18.4566181 },
    { lat: 48.8864549, lng: 18.4562445 },
    { lat: 48.8863092, lng: 18.4561837 },
    { lat: 48.886226, lng: 18.4561885 },
    { lat: 48.8861774, lng: 18.456228 },
    { lat: 48.886166, lng: 18.4564069 },
    { lat: 48.8860902, lng: 18.4564265 },
    { lat: 48.8860686, lng: 18.4564192 },
    { lat: 48.8860422, lng: 18.4563745 },
    { lat: 48.886041, lng: 18.4563309 },
    { lat: 48.8859187, lng: 18.4560554 },
    { lat: 48.8858305, lng: 18.4560712 },
    { lat: 48.8857757, lng: 18.4561229 },
    { lat: 48.8857203, lng: 18.4561984 },
    { lat: 48.8856812, lng: 18.4563299 },
    { lat: 48.8856231, lng: 18.4564853 },
    { lat: 48.8855131, lng: 18.4565566 },
    { lat: 48.8854712, lng: 18.4565511 },
    { lat: 48.8853735, lng: 18.4564599 },
    { lat: 48.8852822, lng: 18.4565016 },
    { lat: 48.8852245, lng: 18.4566003 },
    { lat: 48.8852179, lng: 18.45697 },
    { lat: 48.8851106, lng: 18.457237 },
    { lat: 48.8848881, lng: 18.4573121 },
    { lat: 48.8848171, lng: 18.4572939 },
    { lat: 48.8847553, lng: 18.4572361 },
    { lat: 48.8847067, lng: 18.4570713 },
    { lat: 48.8847393, lng: 18.4569245 },
    { lat: 48.8847543, lng: 18.4567681 },
    { lat: 48.8846807, lng: 18.4566423 },
    { lat: 48.8846121, lng: 18.4566019 },
    { lat: 48.8844982, lng: 18.4566461 },
    { lat: 48.8844255, lng: 18.4567158 },
    { lat: 48.8842591, lng: 18.4569097 },
    { lat: 48.884182, lng: 18.4569861 },
    { lat: 48.8840546, lng: 18.4570513 },
    { lat: 48.8837323, lng: 18.457237 },
    { lat: 48.8835499, lng: 18.4571837 },
    { lat: 48.8833446, lng: 18.4571631 },
    { lat: 48.8831814, lng: 18.4571716 },
    { lat: 48.8828743, lng: 18.4570023 },
    { lat: 48.8827622, lng: 18.4568523 },
    { lat: 48.8826973, lng: 18.4566946 },
    { lat: 48.8825683, lng: 18.4566263 },
    { lat: 48.8824155, lng: 18.456579 },
    { lat: 48.8822445, lng: 18.4566279 },
    { lat: 48.8820789, lng: 18.456488 },
    { lat: 48.8819541, lng: 18.4564106 },
    { lat: 48.8818998, lng: 18.4563916 },
    { lat: 48.8817527, lng: 18.456382 },
    { lat: 48.8813975, lng: 18.4564044 },
    { lat: 48.881334, lng: 18.4564404 },
    { lat: 48.8812607, lng: 18.456522 },
    { lat: 48.8812421, lng: 18.4565739 },
    { lat: 48.8810944, lng: 18.456737 },
    { lat: 48.8809921, lng: 18.4568066 },
    { lat: 48.8809275, lng: 18.4567589 },
    { lat: 48.8808252, lng: 18.4567471 },
    { lat: 48.8806992, lng: 18.4567724 },
    { lat: 48.8804415, lng: 18.4567197 },
    { lat: 48.8802095, lng: 18.456281 },
    { lat: 48.8801086, lng: 18.4563934 },
    { lat: 48.8798865, lng: 18.456824 },
    { lat: 48.8796664, lng: 18.4571279 },
    { lat: 48.8795251, lng: 18.4574941 },
    { lat: 48.8795015, lng: 18.4575373 },
    { lat: 48.8795, lng: 18.4575926 },
    { lat: 48.8793961, lng: 18.4578334 },
    { lat: 48.8793186, lng: 18.4578611 },
    { lat: 48.8789153, lng: 18.4579118 },
    { lat: 48.8786977, lng: 18.4580383 },
    { lat: 48.8785862, lng: 18.4580829 },
    { lat: 48.8786056, lng: 18.4581858 },
    { lat: 48.878505, lng: 18.4585099 },
    { lat: 48.878562, lng: 18.4592614 },
    { lat: 48.8785956, lng: 18.4593382 },
    { lat: 48.8785554, lng: 18.4594239 },
    { lat: 48.8785616, lng: 18.4595593 },
    { lat: 48.8785784, lng: 18.4596185 },
    { lat: 48.8785581, lng: 18.459701 },
    { lat: 48.8786373, lng: 18.4599927 },
    { lat: 48.8785902, lng: 18.4600786 },
    { lat: 48.8786105, lng: 18.4601799 },
    { lat: 48.878667, lng: 18.4602839 },
    { lat: 48.8786691, lng: 18.460365 },
    { lat: 48.8786937, lng: 18.4603998 },
    { lat: 48.8787026, lng: 18.4604794 },
    { lat: 48.8786795, lng: 18.4605325 },
    { lat: 48.8786391, lng: 18.4608024 },
    { lat: 48.8786562, lng: 18.4609497 },
    { lat: 48.8785786, lng: 18.4610946 },
    { lat: 48.8785542, lng: 18.4611676 },
    { lat: 48.8785188, lng: 18.4613445 },
    { lat: 48.8784922, lng: 18.4614145 },
    { lat: 48.8784899, lng: 18.461537 },
    { lat: 48.8785616, lng: 18.4616713 },
    { lat: 48.8785523, lng: 18.4618076 },
    { lat: 48.8784429, lng: 18.4620347 },
    { lat: 48.8783804, lng: 18.4621289 },
    { lat: 48.8783657, lng: 18.4621713 },
    { lat: 48.878317, lng: 18.4625136 },
    { lat: 48.8782571, lng: 18.4629627 },
    { lat: 48.8782739, lng: 18.4630412 },
    { lat: 48.8782309, lng: 18.4632434 },
    { lat: 48.8782309, lng: 18.4633083 },
    { lat: 48.8782557, lng: 18.4634912 },
    { lat: 48.8782377, lng: 18.4635938 },
    { lat: 48.8782178, lng: 18.4636407 },
    { lat: 48.87812, lng: 18.4640362 },
    { lat: 48.8780798, lng: 18.4641055 },
    { lat: 48.8780484, lng: 18.4642947 },
    { lat: 48.8780189, lng: 18.4643511 },
    { lat: 48.8780099, lng: 18.4645587 },
    { lat: 48.8779531, lng: 18.4648185 },
    { lat: 48.8778597, lng: 18.4650676 },
    { lat: 48.8778648, lng: 18.4652196 },
    { lat: 48.8778169, lng: 18.4653375 },
    { lat: 48.8777411, lng: 18.465413 },
    { lat: 48.8777111, lng: 18.4654204 },
    { lat: 48.8776636, lng: 18.4655841 },
    { lat: 48.8776339, lng: 18.4658448 },
    { lat: 48.8775552, lng: 18.4659902 },
    { lat: 48.8774924, lng: 18.4662236 },
    { lat: 48.8774526, lng: 18.4662781 },
    { lat: 48.877407, lng: 18.4664379 },
    { lat: 48.8774145, lng: 18.4665911 },
    { lat: 48.8773926, lng: 18.4667265 },
    { lat: 48.8774049, lng: 18.4670317 },
    { lat: 48.8773334, lng: 18.4672102 },
    { lat: 48.8773268, lng: 18.4674161 },
    { lat: 48.8773559, lng: 18.4675364 },
    { lat: 48.8773815, lng: 18.4677371 },
    { lat: 48.8773826, lng: 18.4678387 },
    { lat: 48.8774133, lng: 18.4680054 },
    { lat: 48.8775153, lng: 18.4683641 },
    { lat: 48.8775476, lng: 18.468521 },
    { lat: 48.8775379, lng: 18.4687139 },
    { lat: 48.8775091, lng: 18.4690731 },
    { lat: 48.8774763, lng: 18.4693578 },
    { lat: 48.8774302, lng: 18.4695454 },
    { lat: 48.8773965, lng: 18.4697327 },
    { lat: 48.8774081, lng: 18.4699004 },
    { lat: 48.8773692, lng: 18.4701394 },
    { lat: 48.8773831, lng: 18.4702666 },
    { lat: 48.8774188, lng: 18.4704435 },
    { lat: 48.8774138, lng: 18.4706627 },
    { lat: 48.8773492, lng: 18.4709235 },
    { lat: 48.877199, lng: 18.471145 },
    { lat: 48.8771608, lng: 18.4713329 },
    { lat: 48.8770649, lng: 18.4714371 },
    { lat: 48.8770376, lng: 18.4714954 },
    { lat: 48.8770229, lng: 18.4716363 },
    { lat: 48.8769859, lng: 18.4717453 },
    { lat: 48.8769759, lng: 18.4717444 },
    { lat: 48.8769558, lng: 18.4718132 },
    { lat: 48.8769382, lng: 18.4721455 },
    { lat: 48.876875, lng: 18.4723345 },
    { lat: 48.8767712, lng: 18.4725193 },
    { lat: 48.8766673, lng: 18.4727395 },
    { lat: 48.8765727, lng: 18.4730095 },
    { lat: 48.8764862, lng: 18.4731602 },
    { lat: 48.8763746, lng: 18.4735731 },
    { lat: 48.8762211, lng: 18.4737348 },
    { lat: 48.8759796, lng: 18.4738563 },
    { lat: 48.8758332, lng: 18.4739418 },
    { lat: 48.8756289, lng: 18.4740827 },
    { lat: 48.8754909, lng: 18.4741388 },
    { lat: 48.8753326, lng: 18.4742735 },
    { lat: 48.875312, lng: 18.4743929 },
    { lat: 48.8752265, lng: 18.4743352 },
    { lat: 48.8751373, lng: 18.4744142 },
    { lat: 48.8749508, lng: 18.4744715 },
    { lat: 48.8747995, lng: 18.4745902 },
    { lat: 48.8746961, lng: 18.4747233 },
    { lat: 48.8745728, lng: 18.4747973 },
    { lat: 48.8744961, lng: 18.4748811 },
    { lat: 48.8744131, lng: 18.474949 },
    { lat: 48.8743063, lng: 18.4751606 },
    { lat: 48.8741951, lng: 18.4752535 },
    { lat: 48.8740813, lng: 18.4756043 },
    { lat: 48.8739856, lng: 18.4757082 },
    { lat: 48.8739425, lng: 18.4758283 },
    { lat: 48.8738541, lng: 18.4759441 },
    { lat: 48.8738427, lng: 18.4760254 },
    { lat: 48.8737855, lng: 18.4760724 },
    { lat: 48.873767, lng: 18.4762686 },
    { lat: 48.8737486, lng: 18.4763469 },
    { lat: 48.8736642, lng: 18.476493 },
    { lat: 48.8735224, lng: 18.4767044 },
    { lat: 48.8733204, lng: 18.4769471 },
    { lat: 48.8731883, lng: 18.4770903 },
    { lat: 48.8731925, lng: 18.4772273 },
    { lat: 48.8729726, lng: 18.477455 },
    { lat: 48.8729528, lng: 18.4777137 },
    { lat: 48.872918, lng: 18.4778801 },
    { lat: 48.8729745, lng: 18.4780059 },
    { lat: 48.8729602, lng: 18.4780934 },
    { lat: 48.8729016, lng: 18.478137 },
    { lat: 48.8728033, lng: 18.4781726 },
    { lat: 48.8724187, lng: 18.478563 },
    { lat: 48.8723702, lng: 18.4786272 },
    { lat: 48.872141, lng: 18.4788063 },
    { lat: 48.8719856, lng: 18.4789972 },
    { lat: 48.8719444, lng: 18.4790909 },
    { lat: 48.8719311, lng: 18.4791545 },
    { lat: 48.8718247, lng: 18.4793138 },
    { lat: 48.8717144, lng: 18.4795646 },
    { lat: 48.871678, lng: 18.479806 },
    { lat: 48.8716954, lng: 18.4799163 },
    { lat: 48.8716774, lng: 18.4800835 },
    { lat: 48.871647, lng: 18.4802711 },
    { lat: 48.8717261, lng: 18.4807635 },
    { lat: 48.8717256, lng: 18.4808582 },
    { lat: 48.8717915, lng: 18.4813397 },
    { lat: 48.8717555, lng: 18.4815238 },
    { lat: 48.8717458, lng: 18.48207 },
    { lat: 48.8717328, lng: 18.4824669 },
    { lat: 48.8717334, lng: 18.4826463 },
    { lat: 48.8717466, lng: 18.4831112 },
    { lat: 48.8717033, lng: 18.484092 },
    { lat: 48.8718006, lng: 18.4843406 },
    { lat: 48.8717958, lng: 18.4847046 },
    { lat: 48.8718606, lng: 18.4855163 },
    { lat: 48.8719045, lng: 18.4862557 },
    { lat: 48.8728793, lng: 18.4889766 },
    { lat: 48.8726626, lng: 18.4889942 },
    { lat: 48.872151, lng: 18.4894167 },
    { lat: 48.8718653, lng: 18.4895945 },
    { lat: 48.8712746, lng: 18.4904268 },
    { lat: 48.8709515, lng: 18.4910749 },
    { lat: 48.8702762, lng: 18.4920507 },
    { lat: 48.8698275, lng: 18.4925887 },
    { lat: 48.8695425, lng: 18.4929912 },
    { lat: 48.8692366, lng: 18.4939315 },
    { lat: 48.8691836, lng: 18.4942836 },
    { lat: 48.8686958, lng: 18.4948287 },
    { lat: 48.8686109, lng: 18.4950096 },
    { lat: 48.8681011, lng: 18.495896 },
    { lat: 48.8676913, lng: 18.4965273 },
  ],
  Čereňany: [
    { lat: 48.6595991, lng: 18.4907846 },
    { lat: 48.6595658, lng: 18.4907102 },
    { lat: 48.6589272, lng: 18.4901984 },
    { lat: 48.6585599, lng: 18.489898 },
    { lat: 48.6575058, lng: 18.4888452 },
    { lat: 48.6562178, lng: 18.4877002 },
    { lat: 48.6557296, lng: 18.4872958 },
    { lat: 48.6555919, lng: 18.4871943 },
    { lat: 48.6556547, lng: 18.486896 },
    { lat: 48.6553891, lng: 18.4865643 },
    { lat: 48.6553802, lng: 18.486484 },
    { lat: 48.6555368, lng: 18.4861725 },
    { lat: 48.6556546, lng: 18.485852 },
    { lat: 48.6555852, lng: 18.4856991 },
    { lat: 48.6555513, lng: 18.4855135 },
    { lat: 48.6555415, lng: 18.4853777 },
    { lat: 48.6555299, lng: 18.4849407 },
    { lat: 48.6555644, lng: 18.4846263 },
    { lat: 48.6557245, lng: 18.4837003 },
    { lat: 48.6559733, lng: 18.4822783 },
    { lat: 48.6560118, lng: 18.4819345 },
    { lat: 48.6562615, lng: 18.4804871 },
    { lat: 48.6564574, lng: 18.4794788 },
    { lat: 48.6566871, lng: 18.4782414 },
    { lat: 48.6567113, lng: 18.4778841 },
    { lat: 48.6567333, lng: 18.4776694 },
    { lat: 48.6558342, lng: 18.4767505 },
    { lat: 48.6554679, lng: 18.4761011 },
    { lat: 48.6553016, lng: 18.474864 },
    { lat: 48.6554035, lng: 18.4748098 },
    { lat: 48.6554046, lng: 18.4747914 },
    { lat: 48.6553953, lng: 18.4747641 },
    { lat: 48.6554721, lng: 18.4746863 },
    { lat: 48.6564264, lng: 18.4736401 },
    { lat: 48.6581232, lng: 18.471765 },
    { lat: 48.658097, lng: 18.4717187 },
    { lat: 48.6572981, lng: 18.4701997 },
    { lat: 48.6562267, lng: 18.4681658 },
    { lat: 48.6560419, lng: 18.4680852 },
    { lat: 48.6559035, lng: 18.4679717 },
    { lat: 48.6557098, lng: 18.467833 },
    { lat: 48.6555164, lng: 18.4677214 },
    { lat: 48.6553887, lng: 18.4676561 },
    { lat: 48.6547058, lng: 18.4674638 },
    { lat: 48.6545182, lng: 18.4674189 },
    { lat: 48.654416, lng: 18.4673661 },
    { lat: 48.654202, lng: 18.4672913 },
    { lat: 48.6539347, lng: 18.467229 },
    { lat: 48.6536994, lng: 18.4671979 },
    { lat: 48.6535315, lng: 18.4671399 },
    { lat: 48.6533987, lng: 18.4670207 },
    { lat: 48.6532968, lng: 18.4668537 },
    { lat: 48.6532353, lng: 18.4666566 },
    { lat: 48.6531499, lng: 18.4662367 },
    { lat: 48.653043, lng: 18.465828 },
    { lat: 48.652786, lng: 18.465511 },
    { lat: 48.652253, lng: 18.465417 },
    { lat: 48.651576, lng: 18.465016 },
    { lat: 48.651277, lng: 18.464659 },
    { lat: 48.651132, lng: 18.46429 },
    { lat: 48.651067, lng: 18.463557 },
    { lat: 48.650864, lng: 18.462997 },
    { lat: 48.65063, lng: 18.462667 },
    { lat: 48.650213, lng: 18.462408 },
    { lat: 48.649947, lng: 18.462195 },
    { lat: 48.649619, lng: 18.461772 },
    { lat: 48.649499, lng: 18.461649 },
    { lat: 48.64919, lng: 18.461137 },
    { lat: 48.649009, lng: 18.46088 },
    { lat: 48.648631, lng: 18.460752 },
    { lat: 48.647975, lng: 18.460444 },
    { lat: 48.647713, lng: 18.460415 },
    { lat: 48.6468233, lng: 18.4620657 },
    { lat: 48.6467744, lng: 18.4621429 },
    { lat: 48.6466664, lng: 18.4623106 },
    { lat: 48.646635, lng: 18.4623324 },
    { lat: 48.6463137, lng: 18.4628694 },
    { lat: 48.6459566, lng: 18.4632048 },
    { lat: 48.6458006, lng: 18.4636065 },
    { lat: 48.6456547, lng: 18.4640076 },
    { lat: 48.6455845, lng: 18.4642273 },
    { lat: 48.64525, lng: 18.4654195 },
    { lat: 48.6450437, lng: 18.4666701 },
    { lat: 48.6449139, lng: 18.4670749 },
    { lat: 48.6446265, lng: 18.4678558 },
    { lat: 48.6443222, lng: 18.4687991 },
    { lat: 48.6443073, lng: 18.4688766 },
    { lat: 48.6442345, lng: 18.4689882 },
    { lat: 48.6441173, lng: 18.4691352 },
    { lat: 48.643918, lng: 18.4693872 },
    { lat: 48.6436634, lng: 18.4698391 },
    { lat: 48.6434755, lng: 18.4701145 },
    { lat: 48.6432447, lng: 18.4704507 },
    { lat: 48.6431021, lng: 18.470586 },
    { lat: 48.6428553, lng: 18.470775 },
    { lat: 48.6421613, lng: 18.4730795 },
    { lat: 48.6419712, lng: 18.4735361 },
    { lat: 48.64196, lng: 18.4735818 },
    { lat: 48.6419325, lng: 18.4736969 },
    { lat: 48.641917, lng: 18.4737612 },
    { lat: 48.641612, lng: 18.4746353 },
    { lat: 48.6414919, lng: 18.4751744 },
    { lat: 48.6413163, lng: 18.4759591 },
    { lat: 48.6412268, lng: 18.4762781 },
    { lat: 48.641047, lng: 18.4771307 },
    { lat: 48.640851, lng: 18.477628 },
    { lat: 48.640648, lng: 18.4780365 },
    { lat: 48.6407268, lng: 18.4781148 },
    { lat: 48.639994, lng: 18.479208 },
    { lat: 48.6398957, lng: 18.4792029 },
    { lat: 48.6398544, lng: 18.4792305 },
    { lat: 48.6397742, lng: 18.479273 },
    { lat: 48.63969, lng: 18.4792965 },
    { lat: 48.6395914, lng: 18.4792714 },
    { lat: 48.6395002, lng: 18.4792721 },
    { lat: 48.6394053, lng: 18.4792698 },
    { lat: 48.639332, lng: 18.479269 },
    { lat: 48.6392387, lng: 18.4792579 },
    { lat: 48.6391354, lng: 18.4792231 },
    { lat: 48.6390261, lng: 18.4791879 },
    { lat: 48.6389705, lng: 18.4791778 },
    { lat: 48.6389087, lng: 18.4791845 },
    { lat: 48.6388515, lng: 18.4791902 },
    { lat: 48.6387875, lng: 18.4792154 },
    { lat: 48.6387333, lng: 18.4792368 },
    { lat: 48.6386179, lng: 18.4792909 },
    { lat: 48.6385453, lng: 18.4793032 },
    { lat: 48.6384872, lng: 18.4793344 },
    { lat: 48.6384422, lng: 18.479359 },
    { lat: 48.6384062, lng: 18.4793788 },
    { lat: 48.6383656, lng: 18.4794007 },
    { lat: 48.6383317, lng: 18.4794217 },
    { lat: 48.638297, lng: 18.4794432 },
    { lat: 48.6382598, lng: 18.4794671 },
    { lat: 48.638226, lng: 18.4794885 },
    { lat: 48.6381945, lng: 18.4795085 },
    { lat: 48.6381586, lng: 18.4795312 },
    { lat: 48.6379739, lng: 18.4796621 },
    { lat: 48.6378809, lng: 18.479733 },
    { lat: 48.6378645, lng: 18.479745 },
    { lat: 48.6378101, lng: 18.4798 },
    { lat: 48.6377993, lng: 18.4798092 },
    { lat: 48.6377685, lng: 18.4798858 },
    { lat: 48.6377089, lng: 18.4799714 },
    { lat: 48.6376368, lng: 18.4800743 },
    { lat: 48.6375682, lng: 18.480201 },
    { lat: 48.6374371, lng: 18.4804432 },
    { lat: 48.6373185, lng: 18.4806632 },
    { lat: 48.6373031, lng: 18.4806933 },
    { lat: 48.6371486, lng: 18.4809777 },
    { lat: 48.6369939, lng: 18.4812635 },
    { lat: 48.636864, lng: 18.4815052 },
    { lat: 48.6367503, lng: 18.4817153 },
    { lat: 48.6367329, lng: 18.4817471 },
    { lat: 48.6366018, lng: 18.4819893 },
    { lat: 48.6364719, lng: 18.482231 },
    { lat: 48.6364125, lng: 18.4823405 },
    { lat: 48.6363408, lng: 18.4824725 },
    { lat: 48.6362764, lng: 18.4825905 },
    { lat: 48.6361903, lng: 18.4827475 },
    { lat: 48.6361338, lng: 18.4828505 },
    { lat: 48.6361043, lng: 18.4828863 },
    { lat: 48.6355338, lng: 18.4839355 },
    { lat: 48.6347645, lng: 18.4853491 },
    { lat: 48.6347911, lng: 18.4853811 },
    { lat: 48.6348465, lng: 18.4854462 },
    { lat: 48.6353006, lng: 18.4859869 },
    { lat: 48.6352274, lng: 18.4863791 },
    { lat: 48.6348863, lng: 18.4868039 },
    { lat: 48.6314063, lng: 18.4949582 },
    { lat: 48.6313662, lng: 18.4952563 },
    { lat: 48.6307269, lng: 18.4970841 },
    { lat: 48.6302897, lng: 18.4980357 },
    { lat: 48.6302585, lng: 18.4981042 },
    { lat: 48.6302004, lng: 18.4981354 },
    { lat: 48.6299593, lng: 18.498264 },
    { lat: 48.629763, lng: 18.4984683 },
    { lat: 48.6294236, lng: 18.4984704 },
    { lat: 48.6287828, lng: 18.4988188 },
    { lat: 48.6284707, lng: 18.4988853 },
    { lat: 48.6282576, lng: 18.4990118 },
    { lat: 48.6280597, lng: 18.4992916 },
    { lat: 48.6282544, lng: 18.499509 },
    { lat: 48.6283023, lng: 18.4995582 },
    { lat: 48.6286145, lng: 18.4998811 },
    { lat: 48.628309, lng: 18.5002734 },
    { lat: 48.6280447, lng: 18.5001667 },
    { lat: 48.627713, lng: 18.5004108 },
    { lat: 48.6268534, lng: 18.5008642 },
    { lat: 48.6268095, lng: 18.5014802 },
    { lat: 48.6265234, lng: 18.5019606 },
    { lat: 48.6265614, lng: 18.5024059 },
    { lat: 48.6265813, lng: 18.5026437 },
    { lat: 48.6265236, lng: 18.5027846 },
    { lat: 48.6265207, lng: 18.5027918 },
    { lat: 48.6264011, lng: 18.5030802 },
    { lat: 48.6257349, lng: 18.5035394 },
    { lat: 48.6253834, lng: 18.5041631 },
    { lat: 48.6250811, lng: 18.5048434 },
    { lat: 48.6249451, lng: 18.5054652 },
    { lat: 48.6248367, lng: 18.5059373 },
    { lat: 48.6247069, lng: 18.5067579 },
    { lat: 48.6245211, lng: 18.5076059 },
    { lat: 48.6243825, lng: 18.508285 },
    { lat: 48.6243277, lng: 18.5087247 },
    { lat: 48.6242847, lng: 18.5095034 },
    { lat: 48.624306, lng: 18.5102413 },
    { lat: 48.6243988, lng: 18.5113158 },
    { lat: 48.6244525, lng: 18.5119024 },
    { lat: 48.6245268, lng: 18.5126188 },
    { lat: 48.6245445, lng: 18.5135789 },
    { lat: 48.6245444, lng: 18.5144418 },
    { lat: 48.6244745, lng: 18.5156543 },
    { lat: 48.6245453, lng: 18.5164324 },
    { lat: 48.6244273, lng: 18.516768 },
    { lat: 48.6240752, lng: 18.5175496 },
    { lat: 48.6238494, lng: 18.5182424 },
    { lat: 48.6238012, lng: 18.5184766 },
    { lat: 48.6237973, lng: 18.5190654 },
    { lat: 48.6240862, lng: 18.5207309 },
    { lat: 48.6240345, lng: 18.5213527 },
    { lat: 48.6239403, lng: 18.5218597 },
    { lat: 48.6237487, lng: 18.5224773 },
    { lat: 48.6233424, lng: 18.523113 },
    { lat: 48.6233223, lng: 18.5232599 },
    { lat: 48.6231237, lng: 18.5239194 },
    { lat: 48.6229158, lng: 18.5244869 },
    { lat: 48.6226161, lng: 18.5251557 },
    { lat: 48.6230336, lng: 18.5265548 },
    { lat: 48.622672, lng: 18.5280727 },
    { lat: 48.622387, lng: 18.5288812 },
    { lat: 48.6221489, lng: 18.5301798 },
    { lat: 48.6223864, lng: 18.5312633 },
    { lat: 48.6220901, lng: 18.5325648 },
    { lat: 48.6220698, lng: 18.5326091 },
    { lat: 48.6215599, lng: 18.53374 },
    { lat: 48.6212363, lng: 18.53457 },
    { lat: 48.6208448, lng: 18.5352094 },
    { lat: 48.620403, lng: 18.5359992 },
    { lat: 48.6199524, lng: 18.5370186 },
    { lat: 48.619627, lng: 18.5375685 },
    { lat: 48.6190933, lng: 18.5381629 },
    { lat: 48.6184401, lng: 18.5386797 },
    { lat: 48.6177966, lng: 18.5392432 },
    { lat: 48.6176941, lng: 18.5393329 },
    { lat: 48.6169682, lng: 18.5398167 },
    { lat: 48.6164207, lng: 18.5402399 },
    { lat: 48.6158732, lng: 18.5406673 },
    { lat: 48.615427, lng: 18.5411694 },
    { lat: 48.6145513, lng: 18.5411663 },
    { lat: 48.6141805, lng: 18.5415446 },
    { lat: 48.6138101, lng: 18.541924 },
    { lat: 48.6133246, lng: 18.5427043 },
    { lat: 48.6128401, lng: 18.5436001 },
    { lat: 48.6122147, lng: 18.5443144 },
    { lat: 48.6116767, lng: 18.5449559 },
    { lat: 48.6111807, lng: 18.5457888 },
    { lat: 48.610973, lng: 18.5460338 },
    { lat: 48.6102678, lng: 18.5467719 },
    { lat: 48.6099463, lng: 18.5477671 },
    { lat: 48.6096068, lng: 18.548242 },
    { lat: 48.6095044, lng: 18.5484924 },
    { lat: 48.6093422, lng: 18.5488886 },
    { lat: 48.609065, lng: 18.5497653 },
    { lat: 48.6090633, lng: 18.5504973 },
    { lat: 48.6092635, lng: 18.55096 },
    { lat: 48.6093403, lng: 18.5515625 },
    { lat: 48.6099731, lng: 18.5538996 },
    { lat: 48.6096141, lng: 18.5544546 },
    { lat: 48.6093205, lng: 18.5552744 },
    { lat: 48.6092162, lng: 18.5564034 },
    { lat: 48.6094555, lng: 18.5572555 },
    { lat: 48.6094448, lng: 18.5576183 },
    { lat: 48.609438, lng: 18.5578455 },
    { lat: 48.6091647, lng: 18.5600351 },
    { lat: 48.6091584, lng: 18.5612441 },
    { lat: 48.6091282, lng: 18.5621814 },
    { lat: 48.6089184, lng: 18.5629038 },
    { lat: 48.6089441, lng: 18.5635023 },
    { lat: 48.60912, lng: 18.5642668 },
    { lat: 48.609452, lng: 18.5653118 },
    { lat: 48.6097439, lng: 18.5657408 },
    { lat: 48.6104671, lng: 18.5676516 },
    { lat: 48.6105838, lng: 18.5687586 },
    { lat: 48.610963, lng: 18.5697977 },
    { lat: 48.6107245, lng: 18.5704535 },
    { lat: 48.6110477, lng: 18.571295 },
    { lat: 48.6112015, lng: 18.5714285 },
    { lat: 48.6107721, lng: 18.5723849 },
    { lat: 48.6099061, lng: 18.5728295 },
    { lat: 48.6074518, lng: 18.5742406 },
    { lat: 48.6085335, lng: 18.5752988 },
    { lat: 48.6081864, lng: 18.576324 },
    { lat: 48.6080662, lng: 18.5766646 },
    { lat: 48.607758, lng: 18.577531 },
    { lat: 48.6076372, lng: 18.578071 },
    { lat: 48.6073823, lng: 18.5790252 },
    { lat: 48.6071042, lng: 18.5793885 },
    { lat: 48.6068115, lng: 18.5797707 },
    { lat: 48.6064577, lng: 18.5805886 },
    { lat: 48.6053427, lng: 18.5831743 },
    { lat: 48.6052405, lng: 18.5835159 },
    { lat: 48.6052491, lng: 18.584048 },
    { lat: 48.6051365, lng: 18.5847398 },
    { lat: 48.6047327, lng: 18.5861499 },
    { lat: 48.6046705, lng: 18.5866273 },
    { lat: 48.6047123, lng: 18.5867922 },
    { lat: 48.6040004, lng: 18.5871466 },
    { lat: 48.6033867, lng: 18.5869963 },
    { lat: 48.6030347, lng: 18.5872029 },
    { lat: 48.6025685, lng: 18.587342 },
    { lat: 48.6022226, lng: 18.5877443 },
    { lat: 48.6016934, lng: 18.5882201 },
    { lat: 48.601368, lng: 18.588644 },
    { lat: 48.6006308, lng: 18.5897986 },
    { lat: 48.6000415, lng: 18.5904725 },
    { lat: 48.5994382, lng: 18.5910204 },
    { lat: 48.5991678, lng: 18.5914831 },
    { lat: 48.5988861, lng: 18.5919672 },
    { lat: 48.598162, lng: 18.5931504 },
    { lat: 48.5979122, lng: 18.5941415 },
    { lat: 48.5975183, lng: 18.5947399 },
    { lat: 48.5967872, lng: 18.5964241 },
    { lat: 48.59615, lng: 18.59813 },
    { lat: 48.5956366, lng: 18.5987529 },
    { lat: 48.5951992, lng: 18.5991125 },
    { lat: 48.594691, lng: 18.5997241 },
    { lat: 48.5942294, lng: 18.6003268 },
    { lat: 48.594139, lng: 18.6009258 },
    { lat: 48.5941309, lng: 18.6009821 },
    { lat: 48.5940918, lng: 18.601243 },
    { lat: 48.593121, lng: 18.602439 },
    { lat: 48.593723, lng: 18.603833 },
    { lat: 48.59621, lng: 18.605514 },
    { lat: 48.596789, lng: 18.605917 },
    { lat: 48.597118, lng: 18.606314 },
    { lat: 48.597636, lng: 18.607387 },
    { lat: 48.598997, lng: 18.609239 },
    { lat: 48.599847, lng: 18.609858 },
    { lat: 48.6024097, lng: 18.6021515 },
    { lat: 48.6036213, lng: 18.5985078 },
    { lat: 48.6044314, lng: 18.5962391 },
    { lat: 48.6047524, lng: 18.5953371 },
    { lat: 48.6048715, lng: 18.5949974 },
    { lat: 48.6053241, lng: 18.5936044 },
    { lat: 48.6064419, lng: 18.5902776 },
    { lat: 48.6067662, lng: 18.5893103 },
    { lat: 48.6072585, lng: 18.5882997 },
    { lat: 48.6083559, lng: 18.5858673 },
    { lat: 48.6100666, lng: 18.5822195 },
    { lat: 48.6107873, lng: 18.5806834 },
    { lat: 48.6117814, lng: 18.5785713 },
    { lat: 48.6130801, lng: 18.5758156 },
    { lat: 48.6150769, lng: 18.5716497 },
    { lat: 48.6161207, lng: 18.5694805 },
    { lat: 48.617464, lng: 18.5668043 },
    { lat: 48.6175328, lng: 18.5663662 },
    { lat: 48.6177583, lng: 18.5660367 },
    { lat: 48.6179793, lng: 18.5661369 },
    { lat: 48.6183899, lng: 18.5657624 },
    { lat: 48.6188197, lng: 18.5654743 },
    { lat: 48.6190464, lng: 18.5651617 },
    { lat: 48.6194772, lng: 18.5644777 },
    { lat: 48.6197872, lng: 18.5640194 },
    { lat: 48.6204028, lng: 18.5630198 },
    { lat: 48.6207998, lng: 18.5625824 },
    { lat: 48.6209671, lng: 18.5624034 },
    { lat: 48.6213708, lng: 18.5618221 },
    { lat: 48.6219314, lng: 18.5608478 },
    { lat: 48.6226344, lng: 18.559982 },
    { lat: 48.6228705, lng: 18.5598244 },
    { lat: 48.6232647, lng: 18.5588814 },
    { lat: 48.6236429, lng: 18.5584306 },
    { lat: 48.6237942, lng: 18.5581622 },
    { lat: 48.6238823, lng: 18.5576493 },
    { lat: 48.6237992, lng: 18.5567447 },
    { lat: 48.6237919, lng: 18.5562379 },
    { lat: 48.6239139, lng: 18.5545032 },
    { lat: 48.623822, lng: 18.5545117 },
    { lat: 48.6237071, lng: 18.5542261 },
    { lat: 48.6237412, lng: 18.5540233 },
    { lat: 48.6238566, lng: 18.5539676 },
    { lat: 48.6241985, lng: 18.5531034 },
    { lat: 48.6247317, lng: 18.5519569 },
    { lat: 48.6249869, lng: 18.5512574 },
    { lat: 48.6258106, lng: 18.5496316 },
    { lat: 48.6259956, lng: 18.5493188 },
    { lat: 48.6265548, lng: 18.5483651 },
    { lat: 48.626743, lng: 18.5481135 },
    { lat: 48.6280167, lng: 18.5469769 },
    { lat: 48.6282499, lng: 18.5469105 },
    { lat: 48.6285732, lng: 18.5465541 },
    { lat: 48.6303207, lng: 18.5439122 },
    { lat: 48.6323192, lng: 18.5420688 },
    { lat: 48.6325475, lng: 18.5426531 },
    { lat: 48.6328643, lng: 18.5432502 },
    { lat: 48.6331933, lng: 18.5430778 },
    { lat: 48.6333621, lng: 18.5428449 },
    { lat: 48.6334349, lng: 18.5420906 },
    { lat: 48.6335964, lng: 18.540317 },
    { lat: 48.6349213, lng: 18.5396687 },
    { lat: 48.635345, lng: 18.5396634 },
    { lat: 48.6355224, lng: 18.5394185 },
    { lat: 48.6358252, lng: 18.5388466 },
    { lat: 48.6361708, lng: 18.5384245 },
    { lat: 48.6359985, lng: 18.5376845 },
    { lat: 48.6356841, lng: 18.5374408 },
    { lat: 48.6355382, lng: 18.5375403 },
    { lat: 48.6355832, lng: 18.5367751 },
    { lat: 48.6360717, lng: 18.5360731 },
    { lat: 48.6365001, lng: 18.5357812 },
    { lat: 48.6369233, lng: 18.535001 },
    { lat: 48.6373903, lng: 18.5340088 },
    { lat: 48.6377355, lng: 18.533596 },
    { lat: 48.6380855, lng: 18.5331063 },
    { lat: 48.6384469, lng: 18.5332613 },
    { lat: 48.6387568, lng: 18.5328857 },
    { lat: 48.6388224, lng: 18.5325166 },
    { lat: 48.6393972, lng: 18.5317635 },
    { lat: 48.6401129, lng: 18.5310106 },
    { lat: 48.6420137, lng: 18.5296129 },
    { lat: 48.6421041, lng: 18.5293684 },
    { lat: 48.642404, lng: 18.5291766 },
    { lat: 48.6429666, lng: 18.5289053 },
    { lat: 48.6435234, lng: 18.5287086 },
    { lat: 48.6437072, lng: 18.5286297 },
    { lat: 48.6441538, lng: 18.5285833 },
    { lat: 48.6444801, lng: 18.5284471 },
    { lat: 48.6449145, lng: 18.5283851 },
    { lat: 48.6454432, lng: 18.5279664 },
    { lat: 48.6460231, lng: 18.5275988 },
    { lat: 48.6461519, lng: 18.5273359 },
    { lat: 48.6461487, lng: 18.5268837 },
    { lat: 48.6461929, lng: 18.5265781 },
    { lat: 48.6462668, lng: 18.5263645 },
    { lat: 48.6467571, lng: 18.5259025 },
    { lat: 48.6469845, lng: 18.5254625 },
    { lat: 48.6472515, lng: 18.5251024 },
    { lat: 48.6476107, lng: 18.5245789 },
    { lat: 48.6480486, lng: 18.5239204 },
    { lat: 48.6488577, lng: 18.5230433 },
    { lat: 48.6489634, lng: 18.5230968 },
    { lat: 48.6491195, lng: 18.5233524 },
    { lat: 48.6493779, lng: 18.5233544 },
    { lat: 48.6498393, lng: 18.5226326 },
    { lat: 48.6495264, lng: 18.5218861 },
    { lat: 48.6497524, lng: 18.5209243 },
    { lat: 48.6496135, lng: 18.5203052 },
    { lat: 48.6499978, lng: 18.519537 },
    { lat: 48.6501434, lng: 18.5191646 },
    { lat: 48.6502251, lng: 18.5183746 },
    { lat: 48.6506002, lng: 18.5180113 },
    { lat: 48.6508367, lng: 18.5175734 },
    { lat: 48.6511968, lng: 18.5170018 },
    { lat: 48.65129, lng: 18.516007 },
    { lat: 48.6514075, lng: 18.5153081 },
    { lat: 48.6514372, lng: 18.5152341 },
    { lat: 48.6517355, lng: 18.5148711 },
    { lat: 48.6518986, lng: 18.5148632 },
    { lat: 48.6526492, lng: 18.5148516 },
    { lat: 48.6526965, lng: 18.5148913 },
    { lat: 48.6530441, lng: 18.5134373 },
    { lat: 48.6544064, lng: 18.5075255 },
    { lat: 48.6546984, lng: 18.5063527 },
    { lat: 48.6550754, lng: 18.504629 },
    { lat: 48.6551401, lng: 18.5042495 },
    { lat: 48.6556005, lng: 18.502935 },
    { lat: 48.6558293, lng: 18.502359 },
    { lat: 48.6563336, lng: 18.5006954 },
    { lat: 48.656569, lng: 18.4999926 },
    { lat: 48.6568614, lng: 18.4991115 },
    { lat: 48.6571305, lng: 18.4983442 },
    { lat: 48.6572299, lng: 18.4979978 },
    { lat: 48.6571264, lng: 18.4978395 },
    { lat: 48.6571456, lng: 18.4977964 },
    { lat: 48.6572559, lng: 18.4979157 },
    { lat: 48.6575132, lng: 18.4971609 },
    { lat: 48.6577179, lng: 18.4965762 },
    { lat: 48.6579561, lng: 18.4958606 },
    { lat: 48.6582925, lng: 18.4948383 },
    { lat: 48.6583382, lng: 18.4947077 },
    { lat: 48.6588033, lng: 18.4932782 },
    { lat: 48.6593397, lng: 18.4915864 },
    { lat: 48.6595991, lng: 18.4907846 },
  ],
  Liešťany: [
    { lat: 48.840359, lng: 18.41975 },
    { lat: 48.8391624, lng: 18.4229288 },
    { lat: 48.8389456, lng: 18.4236156 },
    { lat: 48.8386358, lng: 18.424175 },
    { lat: 48.8381943, lng: 18.4251028 },
    { lat: 48.8377975, lng: 18.4257786 },
    { lat: 48.837646, lng: 18.4260399 },
    { lat: 48.8374835, lng: 18.4264853 },
    { lat: 48.8372544, lng: 18.426831 },
    { lat: 48.8369735, lng: 18.4274618 },
    { lat: 48.8367037, lng: 18.4281296 },
    { lat: 48.8365935, lng: 18.4284831 },
    { lat: 48.8365306, lng: 18.4288754 },
    { lat: 48.8365014, lng: 18.4294463 },
    { lat: 48.8363696, lng: 18.4320782 },
    { lat: 48.83617, lng: 18.4328957 },
    { lat: 48.836085, lng: 18.4339066 },
    { lat: 48.8360501, lng: 18.4349725 },
    { lat: 48.8359774, lng: 18.4356316 },
    { lat: 48.8359944, lng: 18.4360839 },
    { lat: 48.8359562, lng: 18.4362692 },
    { lat: 48.835634, lng: 18.4372358 },
    { lat: 48.835496, lng: 18.4375366 },
    { lat: 48.8352581, lng: 18.4380695 },
    { lat: 48.8351158, lng: 18.4382491 },
    { lat: 48.8346217, lng: 18.438962 },
    { lat: 48.8343453, lng: 18.4394512 },
    { lat: 48.8342482, lng: 18.4400002 },
    { lat: 48.834163, lng: 18.4406234 },
    { lat: 48.8341658, lng: 18.4409392 },
    { lat: 48.8340596, lng: 18.4412413 },
    { lat: 48.833956, lng: 18.4416762 },
    { lat: 48.8337599, lng: 18.4421881 },
    { lat: 48.8333333, lng: 18.4425741 },
    { lat: 48.8330696, lng: 18.4428836 },
    { lat: 48.8329141, lng: 18.4430731 },
    { lat: 48.8325552, lng: 18.443375 },
    { lat: 48.8321485, lng: 18.4434677 },
    { lat: 48.8317155, lng: 18.4434689 },
    { lat: 48.8313268, lng: 18.443907 },
    { lat: 48.8310436, lng: 18.4443747 },
    { lat: 48.8304826, lng: 18.4444494 },
    { lat: 48.8303208, lng: 18.4445043 },
    { lat: 48.8301376, lng: 18.4446367 },
    { lat: 48.8297777, lng: 18.4452083 },
    { lat: 48.829501, lng: 18.4455527 },
    { lat: 48.8290957, lng: 18.4458709 },
    { lat: 48.8289098, lng: 18.4463266 },
    { lat: 48.8288885, lng: 18.446326 },
    { lat: 48.8278694, lng: 18.4474809 },
    { lat: 48.8253817, lng: 18.4488691 },
    { lat: 48.8250899, lng: 18.4492837 },
    { lat: 48.8245229, lng: 18.4497819 },
    { lat: 48.8239874, lng: 18.4501734 },
    { lat: 48.8237626, lng: 18.4503959 },
    { lat: 48.8235583, lng: 18.4505746 },
    { lat: 48.8233669, lng: 18.4508681 },
    { lat: 48.8231693, lng: 18.4512149 },
    { lat: 48.8227522, lng: 18.451465 },
    { lat: 48.8226936, lng: 18.4514835 },
    { lat: 48.8222816, lng: 18.4516413 },
    { lat: 48.8221584, lng: 18.4516791 },
    { lat: 48.8217226, lng: 18.4517543 },
    { lat: 48.8214238, lng: 18.4519006 },
    { lat: 48.8212612, lng: 18.4520414 },
    { lat: 48.8206129, lng: 18.4524378 },
    { lat: 48.8205087, lng: 18.4526146 },
    { lat: 48.8204809, lng: 18.4527828 },
    { lat: 48.8204028, lng: 18.4530429 },
    { lat: 48.8202538, lng: 18.4533284 },
    { lat: 48.8201488, lng: 18.4536747 },
    { lat: 48.8200857, lng: 18.4539225 },
    { lat: 48.8196619, lng: 18.4550515 },
    { lat: 48.8188381, lng: 18.4566194 },
    { lat: 48.8187833, lng: 18.4566124 },
    { lat: 48.8185277, lng: 18.4570682 },
    { lat: 48.8183686, lng: 18.4571495 },
    { lat: 48.8182746, lng: 18.4573053 },
    { lat: 48.8182689, lng: 18.4574692 },
    { lat: 48.8182868, lng: 18.4577115 },
    { lat: 48.8182703, lng: 18.4580465 },
    { lat: 48.8178907, lng: 18.4590971 },
    { lat: 48.8175429, lng: 18.4600564 },
    { lat: 48.8174607, lng: 18.4604057 },
    { lat: 48.8174005, lng: 18.4610115 },
    { lat: 48.8174057, lng: 18.4615341 },
    { lat: 48.8172187, lng: 18.4621942 },
    { lat: 48.8170203, lng: 18.4627723 },
    { lat: 48.8168558, lng: 18.4630433 },
    { lat: 48.8167262, lng: 18.4634319 },
    { lat: 48.8165793, lng: 18.4641038 },
    { lat: 48.8163755, lng: 18.4646621 },
    { lat: 48.8163064, lng: 18.4649257 },
    { lat: 48.816208, lng: 18.4655767 },
    { lat: 48.8161485, lng: 18.465703 },
    { lat: 48.8161825, lng: 18.4659121 },
    { lat: 48.8161045, lng: 18.4660927 },
    { lat: 48.8160845, lng: 18.4662022 },
    { lat: 48.8160805, lng: 18.4664125 },
    { lat: 48.816031, lng: 18.4665998 },
    { lat: 48.8159952, lng: 18.4666663 },
    { lat: 48.8159194, lng: 18.4669296 },
    { lat: 48.8158667, lng: 18.4672212 },
    { lat: 48.8158345, lng: 18.4673162 },
    { lat: 48.8158202, lng: 18.4674422 },
    { lat: 48.8157938, lng: 18.4674559 },
    { lat: 48.8157706, lng: 18.4676103 },
    { lat: 48.8157147, lng: 18.4678009 },
    { lat: 48.8157034, lng: 18.4678242 },
    { lat: 48.8156781, lng: 18.468141 },
    { lat: 48.8156947, lng: 18.4684427 },
    { lat: 48.8157425, lng: 18.4687796 },
    { lat: 48.8157924, lng: 18.4689899 },
    { lat: 48.8157937, lng: 18.4691114 },
    { lat: 48.8158177, lng: 18.4691395 },
    { lat: 48.8158355, lng: 18.469361 },
    { lat: 48.8158353, lng: 18.4694591 },
    { lat: 48.8159, lng: 18.469777 },
    { lat: 48.8159424, lng: 18.4698905 },
    { lat: 48.815922, lng: 18.4700301 },
    { lat: 48.815974, lng: 18.4703006 },
    { lat: 48.8160815, lng: 18.4706346 },
    { lat: 48.8160825, lng: 18.470919 },
    { lat: 48.8161321, lng: 18.4710388 },
    { lat: 48.8162612, lng: 18.4712977 },
    { lat: 48.8163739, lng: 18.4714976 },
    { lat: 48.8164244, lng: 18.4716974 },
    { lat: 48.8164586, lng: 18.4718899 },
    { lat: 48.8165044, lng: 18.4719027 },
    { lat: 48.8165201, lng: 18.4719212 },
    { lat: 48.8165666, lng: 18.4721082 },
    { lat: 48.8166095, lng: 18.4722532 },
    { lat: 48.8166714, lng: 18.4723942 },
    { lat: 48.816661, lng: 18.4724929 },
    { lat: 48.8167017, lng: 18.4725966 },
    { lat: 48.8167313, lng: 18.4727053 },
    { lat: 48.8167166, lng: 18.4728288 },
    { lat: 48.8168227, lng: 18.4730731 },
    { lat: 48.8169167, lng: 18.4733688 },
    { lat: 48.8170588, lng: 18.4739615 },
    { lat: 48.817037, lng: 18.474262 },
    { lat: 48.8169473, lng: 18.4744999 },
    { lat: 48.8169403, lng: 18.474862 },
    { lat: 48.8168986, lng: 18.4751704 },
    { lat: 48.8168358, lng: 18.4754855 },
    { lat: 48.816831, lng: 18.4758934 },
    { lat: 48.8168222, lng: 18.4762657 },
    { lat: 48.8168326, lng: 18.4766402 },
    { lat: 48.8168682, lng: 18.4767707 },
    { lat: 48.8168682, lng: 18.4768956 },
    { lat: 48.8169002, lng: 18.4770265 },
    { lat: 48.8169696, lng: 18.4773854 },
    { lat: 48.8170317, lng: 18.4776353 },
    { lat: 48.8171153, lng: 18.4779261 },
    { lat: 48.8171489, lng: 18.478287 },
    { lat: 48.8171691, lng: 18.4784523 },
    { lat: 48.8172346, lng: 18.4788825 },
    { lat: 48.8172556, lng: 18.4790588 },
    { lat: 48.8171131, lng: 18.4793603 },
    { lat: 48.816967, lng: 18.4796389 },
    { lat: 48.8169249, lng: 18.4797774 },
    { lat: 48.8169518, lng: 18.4798753 },
    { lat: 48.8173167, lng: 18.4798433 },
    { lat: 48.8174982, lng: 18.4803935 },
    { lat: 48.8177418, lng: 18.4809505 },
    { lat: 48.8178362, lng: 18.481249 },
    { lat: 48.8180065, lng: 18.4815568 },
    { lat: 48.8181031, lng: 18.481797 },
    { lat: 48.8181468, lng: 18.4820589 },
    { lat: 48.8185863, lng: 18.4819266 },
    { lat: 48.8188289, lng: 18.4817646 },
    { lat: 48.8195071, lng: 18.4815027 },
    { lat: 48.8195066, lng: 18.4815958 },
    { lat: 48.8195307, lng: 18.4820138 },
    { lat: 48.8195822, lng: 18.4831796 },
    { lat: 48.819628, lng: 18.4841335 },
    { lat: 48.8196483, lng: 18.4842362 },
    { lat: 48.8196541, lng: 18.4843425 },
    { lat: 48.8197198, lng: 18.4848404 },
    { lat: 48.8197583, lng: 18.4850689 },
    { lat: 48.8198009, lng: 18.4852082 },
    { lat: 48.8199262, lng: 18.4854966 },
    { lat: 48.8199253, lng: 18.4862639 },
    { lat: 48.8198656, lng: 18.4865102 },
    { lat: 48.8198014, lng: 18.4866328 },
    { lat: 48.8197274, lng: 18.4866843 },
    { lat: 48.8195427, lng: 18.4869982 },
    { lat: 48.8195369, lng: 18.4874253 },
    { lat: 48.8195213, lng: 18.4875729 },
    { lat: 48.8194835, lng: 18.487689 },
    { lat: 48.8194893, lng: 18.4878326 },
    { lat: 48.8193816, lng: 18.4881758 },
    { lat: 48.8193993, lng: 18.488558 },
    { lat: 48.8194721, lng: 18.4890843 },
    { lat: 48.81955, lng: 18.4895553 },
    { lat: 48.819615, lng: 18.4897322 },
    { lat: 48.8196589, lng: 18.4899804 },
    { lat: 48.8197687, lng: 18.4902115 },
    { lat: 48.8205787, lng: 18.4904818 },
    { lat: 48.8209371, lng: 18.4905384 },
    { lat: 48.8214548, lng: 18.490164 },
    { lat: 48.8217393, lng: 18.489985 },
    { lat: 48.8218125, lng: 18.4899196 },
    { lat: 48.8219446, lng: 18.4897816 },
    { lat: 48.8220339, lng: 18.4896991 },
    { lat: 48.8222011, lng: 18.4897475 },
    { lat: 48.8222944, lng: 18.4898239 },
    { lat: 48.8224827, lng: 18.4899047 },
    { lat: 48.8225255, lng: 18.4898993 },
    { lat: 48.8226566, lng: 18.4898248 },
    { lat: 48.8227057, lng: 18.4897556 },
    { lat: 48.8227975, lng: 18.4895977 },
    { lat: 48.8230188, lng: 18.4895802 },
    { lat: 48.8230701, lng: 18.4894255 },
    { lat: 48.8232386, lng: 18.4891452 },
    { lat: 48.8232586, lng: 18.4890148 },
    { lat: 48.8233641, lng: 18.4888437 },
    { lat: 48.8233802, lng: 18.4887221 },
    { lat: 48.823447, lng: 18.4886056 },
    { lat: 48.8235169, lng: 18.4885649 },
    { lat: 48.8235908, lng: 18.4886154 },
    { lat: 48.8237067, lng: 18.4885911 },
    { lat: 48.8237067, lng: 18.4884233 },
    { lat: 48.8237498, lng: 18.4883248 },
    { lat: 48.8238143, lng: 18.4882901 },
    { lat: 48.823904, lng: 18.4883172 },
    { lat: 48.8240341, lng: 18.4881812 },
    { lat: 48.8240461, lng: 18.4881768 },
    { lat: 48.8241024, lng: 18.4883194 },
    { lat: 48.8240731, lng: 18.4884623 },
    { lat: 48.8239889, lng: 18.4885728 },
    { lat: 48.8239661, lng: 18.4887296 },
    { lat: 48.8239918, lng: 18.4887464 },
    { lat: 48.8241309, lng: 18.4886111 },
    { lat: 48.8241994, lng: 18.4886655 },
    { lat: 48.8242661, lng: 18.4889979 },
    { lat: 48.824361, lng: 18.4893912 },
    { lat: 48.8243727, lng: 18.4894195 },
    { lat: 48.8245052, lng: 18.4895725 },
    { lat: 48.8245054, lng: 18.4896204 },
    { lat: 48.8244903, lng: 18.4897544 },
    { lat: 48.8246193, lng: 18.4899852 },
    { lat: 48.8247448, lng: 18.4901141 },
    { lat: 48.8247185, lng: 18.4902126 },
    { lat: 48.8247129, lng: 18.4902956 },
    { lat: 48.8247237, lng: 18.4903505 },
    { lat: 48.8248246, lng: 18.490626 },
    { lat: 48.8247966, lng: 18.490792 },
    { lat: 48.8248161, lng: 18.4908404 },
    { lat: 48.8249366, lng: 18.4910211 },
    { lat: 48.8250041, lng: 18.4911404 },
    { lat: 48.8250771, lng: 18.4912994 },
    { lat: 48.8250904, lng: 18.4914202 },
    { lat: 48.8251548, lng: 18.4914826 },
    { lat: 48.8252382, lng: 18.4914227 },
    { lat: 48.8253085, lng: 18.4914887 },
    { lat: 48.8253763, lng: 18.4916341 },
    { lat: 48.8254742, lng: 18.4916905 },
    { lat: 48.8255538, lng: 18.4917024 },
    { lat: 48.825599, lng: 18.4918725 },
    { lat: 48.8257394, lng: 18.491858 },
    { lat: 48.8258085, lng: 18.4919685 },
    { lat: 48.8259454, lng: 18.4919341 },
    { lat: 48.8259656, lng: 18.4919713 },
    { lat: 48.8260054, lng: 18.4920204 },
    { lat: 48.826087, lng: 18.4920459 },
    { lat: 48.8261282, lng: 18.4922419 },
    { lat: 48.8262072, lng: 18.4922649 },
    { lat: 48.8263403, lng: 18.492449 },
    { lat: 48.8264015, lng: 18.4927635 },
    { lat: 48.8265053, lng: 18.4928042 },
    { lat: 48.8266366, lng: 18.4928739 },
    { lat: 48.8266378, lng: 18.492978 },
    { lat: 48.8268282, lng: 18.493119 },
    { lat: 48.8269257, lng: 18.4931286 },
    { lat: 48.8269895, lng: 18.493305 },
    { lat: 48.8272207, lng: 18.4933844 },
    { lat: 48.8272434, lng: 18.4934703 },
    { lat: 48.827341, lng: 18.4932751 },
    { lat: 48.8281388, lng: 18.4930136 },
    { lat: 48.8289484, lng: 18.4927808 },
    { lat: 48.8291098, lng: 18.4928661 },
    { lat: 48.8301701, lng: 18.4930721 },
    { lat: 48.8304829, lng: 18.4931373 },
    { lat: 48.8309165, lng: 18.4932471 },
    { lat: 48.8312329, lng: 18.4932793 },
    { lat: 48.8314831, lng: 18.493173 },
    { lat: 48.8315719, lng: 18.4931682 },
    { lat: 48.8316105, lng: 18.49325 },
    { lat: 48.8315094, lng: 18.4934686 },
    { lat: 48.8314273, lng: 18.4935523 },
    { lat: 48.8314225, lng: 18.4936124 },
    { lat: 48.8315968, lng: 18.4939503 },
    { lat: 48.8316046, lng: 18.4939876 },
    { lat: 48.8315845, lng: 18.4941341 },
    { lat: 48.8314564, lng: 18.4941878 },
    { lat: 48.8313929, lng: 18.4943866 },
    { lat: 48.8315653, lng: 18.4946514 },
    { lat: 48.8317779, lng: 18.4948262 },
    { lat: 48.8319105, lng: 18.4948779 },
    { lat: 48.8319444, lng: 18.4949016 },
    { lat: 48.8319894, lng: 18.4949797 },
    { lat: 48.8320586, lng: 18.4949875 },
    { lat: 48.8322767, lng: 18.4951806 },
    { lat: 48.8323192, lng: 18.4954219 },
    { lat: 48.8323764, lng: 18.495515 },
    { lat: 48.8326112, lng: 18.4957465 },
    { lat: 48.8326408, lng: 18.4959361 },
    { lat: 48.832635, lng: 18.496098 },
    { lat: 48.8328092, lng: 18.4962318 },
    { lat: 48.8328597, lng: 18.4963683 },
    { lat: 48.8327488, lng: 18.4965058 },
    { lat: 48.8327533, lng: 18.4965872 },
    { lat: 48.8328787, lng: 18.4967791 },
    { lat: 48.8330111, lng: 18.4968659 },
    { lat: 48.8330955, lng: 18.4970705 },
    { lat: 48.8331588, lng: 18.4970525 },
    { lat: 48.8332182, lng: 18.4972298 },
    { lat: 48.8331723, lng: 18.497401 },
    { lat: 48.8332084, lng: 18.4974656 },
    { lat: 48.8332246, lng: 18.4978001 },
    { lat: 48.8333876, lng: 18.4980571 },
    { lat: 48.8335412, lng: 18.498104 },
    { lat: 48.8336013, lng: 18.4981312 },
    { lat: 48.8336352, lng: 18.4982956 },
    { lat: 48.8336457, lng: 18.4983197 },
    { lat: 48.8337831, lng: 18.4984209 },
    { lat: 48.8338714, lng: 18.4984489 },
    { lat: 48.8339566, lng: 18.4985416 },
    { lat: 48.8340522, lng: 18.4986603 },
    { lat: 48.8340997, lng: 18.4988069 },
    { lat: 48.8341666, lng: 18.4989148 },
    { lat: 48.834321, lng: 18.4991185 },
    { lat: 48.8344061, lng: 18.4992739 },
    { lat: 48.8343981, lng: 18.4993122 },
    { lat: 48.8344676, lng: 18.4994077 },
    { lat: 48.8345832, lng: 18.4994175 },
    { lat: 48.8346484, lng: 18.499516 },
    { lat: 48.8346834, lng: 18.4995968 },
    { lat: 48.8348243, lng: 18.4999873 },
    { lat: 48.8349277, lng: 18.5001877 },
    { lat: 48.8350634, lng: 18.5001921 },
    { lat: 48.8352077, lng: 18.5003147 },
    { lat: 48.835363, lng: 18.5003063 },
    { lat: 48.8354536, lng: 18.5003973 },
    { lat: 48.8357333, lng: 18.500476 },
    { lat: 48.8357653, lng: 18.5005472 },
    { lat: 48.8358584, lng: 18.5005763 },
    { lat: 48.8359409, lng: 18.5007464 },
    { lat: 48.8360602, lng: 18.500827 },
    { lat: 48.836332, lng: 18.5010503 },
    { lat: 48.8364136, lng: 18.5010562 },
    { lat: 48.8365312, lng: 18.5011227 },
    { lat: 48.8367018, lng: 18.5011514 },
    { lat: 48.8368935, lng: 18.5011477 },
    { lat: 48.8371388, lng: 18.5013633 },
    { lat: 48.8372157, lng: 18.5013257 },
    { lat: 48.8372681, lng: 18.5013155 },
    { lat: 48.8373725, lng: 18.5013271 },
    { lat: 48.8374484, lng: 18.5013523 },
    { lat: 48.8375919, lng: 18.5014829 },
    { lat: 48.8377381, lng: 18.5015146 },
    { lat: 48.8378619, lng: 18.5015707 },
    { lat: 48.8380816, lng: 18.501585 },
    { lat: 48.8381571, lng: 18.5015658 },
    { lat: 48.8382701, lng: 18.5016301 },
    { lat: 48.8383533, lng: 18.5016479 },
    { lat: 48.8384333, lng: 18.5016844 },
    { lat: 48.8385013, lng: 18.5016698 },
    { lat: 48.8385523, lng: 18.5016942 },
    { lat: 48.8387517, lng: 18.5018145 },
    { lat: 48.8391198, lng: 18.5019885 },
    { lat: 48.8392676, lng: 18.5019452 },
    { lat: 48.8394701, lng: 18.5020397 },
    { lat: 48.8395249, lng: 18.5020092 },
    { lat: 48.8396768, lng: 18.5020463 },
    { lat: 48.8399649, lng: 18.5021577 },
    { lat: 48.8400546, lng: 18.5021464 },
    { lat: 48.8401315, lng: 18.5020866 },
    { lat: 48.840547, lng: 18.5018668 },
    { lat: 48.8406869, lng: 18.5017837 },
    { lat: 48.8409201, lng: 18.5016996 },
    { lat: 48.8411421, lng: 18.5008506 },
    { lat: 48.8412399, lng: 18.5004512 },
    { lat: 48.8414604, lng: 18.5006444 },
    { lat: 48.8416501, lng: 18.5008372 },
    { lat: 48.8418725, lng: 18.5010449 },
    { lat: 48.8420181, lng: 18.5011917 },
    { lat: 48.842161, lng: 18.5013929 },
    { lat: 48.8423315, lng: 18.5017432 },
    { lat: 48.8424214, lng: 18.5018827 },
    { lat: 48.8425489, lng: 18.5021084 },
    { lat: 48.8428942, lng: 18.5027311 },
    { lat: 48.8430597, lng: 18.5029299 },
    { lat: 48.8431334, lng: 18.5031007 },
    { lat: 48.8431867, lng: 18.5033161 },
    { lat: 48.8432362, lng: 18.5034552 },
    { lat: 48.8433262, lng: 18.5037397 },
    { lat: 48.8434359, lng: 18.5039734 },
    { lat: 48.8436334, lng: 18.5043039 },
    { lat: 48.8437728, lng: 18.5045614 },
    { lat: 48.8438243, lng: 18.5045968 },
    { lat: 48.8438407, lng: 18.5043004 },
    { lat: 48.8438162, lng: 18.5040761 },
    { lat: 48.8438107, lng: 18.5037903 },
    { lat: 48.8437867, lng: 18.5037227 },
    { lat: 48.8437329, lng: 18.5034824 },
    { lat: 48.8437188, lng: 18.5033685 },
    { lat: 48.8437613, lng: 18.5031762 },
    { lat: 48.8437469, lng: 18.502957 },
    { lat: 48.8437726, lng: 18.5028045 },
    { lat: 48.8438562, lng: 18.5025215 },
    { lat: 48.8439103, lng: 18.5022496 },
    { lat: 48.8439426, lng: 18.5020368 },
    { lat: 48.8439793, lng: 18.501947 },
    { lat: 48.8440165, lng: 18.501698 },
    { lat: 48.8440076, lng: 18.501475 },
    { lat: 48.8440102, lng: 18.5014582 },
    { lat: 48.8439525, lng: 18.5011897 },
    { lat: 48.8437569, lng: 18.5009757 },
    { lat: 48.8435519, lng: 18.5007719 },
    { lat: 48.84344, lng: 18.5005242 },
    { lat: 48.8433112, lng: 18.5001483 },
    { lat: 48.8431562, lng: 18.4997692 },
    { lat: 48.8431394, lng: 18.4995234 },
    { lat: 48.8430301, lng: 18.4992586 },
    { lat: 48.8430115, lng: 18.4990009 },
    { lat: 48.8428277, lng: 18.4985055 },
    { lat: 48.842761, lng: 18.4981131 },
    { lat: 48.842428, lng: 18.4973066 },
    { lat: 48.842385, lng: 18.4970141 },
    { lat: 48.8421146, lng: 18.4965693 },
    { lat: 48.8420059, lng: 18.4962284 },
    { lat: 48.8418764, lng: 18.4960477 },
    { lat: 48.8418144, lng: 18.4959252 },
    { lat: 48.8416996, lng: 18.4958884 },
    { lat: 48.8416351, lng: 18.4956813 },
    { lat: 48.8415562, lng: 18.4955569 },
    { lat: 48.8413781, lng: 18.4953956 },
    { lat: 48.8411657, lng: 18.4950796 },
    { lat: 48.8411677, lng: 18.49493 },
    { lat: 48.8410445, lng: 18.4948431 },
    { lat: 48.8409913, lng: 18.4947603 },
    { lat: 48.8408253, lng: 18.4946343 },
    { lat: 48.8407016, lng: 18.4945163 },
    { lat: 48.8404926, lng: 18.4942436 },
    { lat: 48.8403205, lng: 18.4941478 },
    { lat: 48.8402472, lng: 18.4939633 },
    { lat: 48.8401836, lng: 18.4938327 },
    { lat: 48.8401332, lng: 18.4936546 },
    { lat: 48.8399944, lng: 18.4936108 },
    { lat: 48.8398473, lng: 18.4934589 },
    { lat: 48.8398501, lng: 18.4932644 },
    { lat: 48.8397892, lng: 18.4931625 },
    { lat: 48.8396909, lng: 18.4931369 },
    { lat: 48.8396567, lng: 18.4931072 },
    { lat: 48.8395559, lng: 18.4929591 },
    { lat: 48.8394727, lng: 18.4929212 },
    { lat: 48.8393916, lng: 18.492964 },
    { lat: 48.8393293, lng: 18.4929386 },
    { lat: 48.8393217, lng: 18.4928443 },
    { lat: 48.8392864, lng: 18.4927731 },
    { lat: 48.8391094, lng: 18.4927141 },
    { lat: 48.8390018, lng: 18.4926227 },
    { lat: 48.8390023, lng: 18.4924508 },
    { lat: 48.8389485, lng: 18.4923319 },
    { lat: 48.8388497, lng: 18.4922591 },
    { lat: 48.8386599, lng: 18.4920248 },
    { lat: 48.8385303, lng: 18.4919032 },
    { lat: 48.8385193, lng: 18.491747 },
    { lat: 48.8384398, lng: 18.4915719 },
    { lat: 48.8382689, lng: 18.4914781 },
    { lat: 48.8381999, lng: 18.4914954 },
    { lat: 48.8381351, lng: 18.491486 },
    { lat: 48.8381359, lng: 18.4912768 },
    { lat: 48.8380675, lng: 18.4912009 },
    { lat: 48.8379614, lng: 18.491197 },
    { lat: 48.8379037, lng: 18.4911105 },
    { lat: 48.8377847, lng: 18.4910406 },
    { lat: 48.8377335, lng: 18.4909077 },
    { lat: 48.837658, lng: 18.490769 },
    { lat: 48.8376257, lng: 18.4906327 },
    { lat: 48.8375985, lng: 18.4905677 },
    { lat: 48.8374505, lng: 18.4903976 },
    { lat: 48.8373953, lng: 18.490232 },
    { lat: 48.8375421, lng: 18.4900724 },
    { lat: 48.8376071, lng: 18.4899396 },
    { lat: 48.8377973, lng: 18.4896883 },
    { lat: 48.837814, lng: 18.4895784 },
    { lat: 48.8378032, lng: 18.4889725 },
    { lat: 48.8378056, lng: 18.4887498 },
    { lat: 48.8378216, lng: 18.488033 },
    { lat: 48.8378815, lng: 18.4873971 },
    { lat: 48.8378857, lng: 18.4873299 },
    { lat: 48.8379795, lng: 18.486964 },
    { lat: 48.8380894, lng: 18.4865584 },
    { lat: 48.8381707, lng: 18.486045 },
    { lat: 48.8382856, lng: 18.4852227 },
    { lat: 48.8383846, lng: 18.484189 },
    { lat: 48.8383913, lng: 18.4840875 },
    { lat: 48.8384655, lng: 18.4834672 },
    { lat: 48.8385642, lng: 18.4827755 },
    { lat: 48.8386018, lng: 18.4823489 },
    { lat: 48.8385915, lng: 18.4822059 },
    { lat: 48.8390397, lng: 18.4820463 },
    { lat: 48.8390785, lng: 18.4819737 },
    { lat: 48.8390639, lng: 18.4818338 },
    { lat: 48.8389627, lng: 18.4816134 },
    { lat: 48.8388902, lng: 18.4813378 },
    { lat: 48.838778, lng: 18.4811851 },
    { lat: 48.8387178, lng: 18.4809714 },
    { lat: 48.8385235, lng: 18.4805963 },
    { lat: 48.8389244, lng: 18.4802954 },
    { lat: 48.8388625, lng: 18.4799731 },
    { lat: 48.8387832, lng: 18.4795322 },
    { lat: 48.8387075, lng: 18.4789162 },
    { lat: 48.8387016, lng: 18.4787267 },
    { lat: 48.8386533, lng: 18.4783597 },
    { lat: 48.8385775, lng: 18.477536 },
    { lat: 48.8384998, lng: 18.4765134 },
    { lat: 48.8383953, lng: 18.4749173 },
    { lat: 48.8383897, lng: 18.4748152 },
    { lat: 48.8386961, lng: 18.474339 },
    { lat: 48.8387339, lng: 18.4743041 },
    { lat: 48.8389218, lng: 18.4742783 },
    { lat: 48.8390372, lng: 18.4741581 },
    { lat: 48.8392183, lng: 18.4740081 },
    { lat: 48.8394244, lng: 18.4738138 },
    { lat: 48.8395013, lng: 18.4738195 },
    { lat: 48.8395443, lng: 18.4738029 },
    { lat: 48.8395621, lng: 18.4737778 },
    { lat: 48.8395881, lng: 18.4737168 },
    { lat: 48.8398484, lng: 18.4735246 },
    { lat: 48.8399744, lng: 18.4733354 },
    { lat: 48.8402369, lng: 18.4732049 },
    { lat: 48.8403694, lng: 18.4731935 },
    { lat: 48.8404327, lng: 18.4732364 },
    { lat: 48.8404893, lng: 18.4732002 },
    { lat: 48.8405908, lng: 18.4729131 },
    { lat: 48.8407957, lng: 18.4728643 },
    { lat: 48.840838, lng: 18.4726892 },
    { lat: 48.8408458, lng: 18.4726882 },
    { lat: 48.8409063, lng: 18.4727834 },
    { lat: 48.840934, lng: 18.4727759 },
    { lat: 48.8411535, lng: 18.4722311 },
    { lat: 48.8412843, lng: 18.4720484 },
    { lat: 48.8414673, lng: 18.4719065 },
    { lat: 48.8416603, lng: 18.4717675 },
    { lat: 48.8417913, lng: 18.4717287 },
    { lat: 48.8418704, lng: 18.4715254 },
    { lat: 48.8419237, lng: 18.4714513 },
    { lat: 48.8423023, lng: 18.471256 },
    { lat: 48.84242, lng: 18.4712418 },
    { lat: 48.8426824, lng: 18.4711131 },
    { lat: 48.8428203, lng: 18.4708285 },
    { lat: 48.8429221, lng: 18.4707533 },
    { lat: 48.8430353, lng: 18.470739 },
    { lat: 48.8430617, lng: 18.470769 },
    { lat: 48.843104, lng: 18.4707773 },
    { lat: 48.8431568, lng: 18.4706707 },
    { lat: 48.8432219, lng: 18.4706037 },
    { lat: 48.8433452, lng: 18.4706917 },
    { lat: 48.8434313, lng: 18.4706597 },
    { lat: 48.8435086, lng: 18.4704662 },
    { lat: 48.8435817, lng: 18.4704584 },
    { lat: 48.8436877, lng: 18.4703784 },
    { lat: 48.8437907, lng: 18.4702006 },
    { lat: 48.8437683, lng: 18.4700785 },
    { lat: 48.8437961, lng: 18.4700717 },
    { lat: 48.8440976, lng: 18.469851 },
    { lat: 48.8442719, lng: 18.4696582 },
    { lat: 48.8444709, lng: 18.4696069 },
    { lat: 48.8445055, lng: 18.4696387 },
    { lat: 48.8446129, lng: 18.4694797 },
    { lat: 48.8449805, lng: 18.4693957 },
    { lat: 48.8451786, lng: 18.4693266 },
    { lat: 48.8452256, lng: 18.4693766 },
    { lat: 48.8452611, lng: 18.4696744 },
    { lat: 48.8452781, lng: 18.4697607 },
    { lat: 48.8456462, lng: 18.4695598 },
    { lat: 48.8462396, lng: 18.4693158 },
    { lat: 48.8464859, lng: 18.4691998 },
    { lat: 48.8468163, lng: 18.4690353 },
    { lat: 48.8470071, lng: 18.4688718 },
    { lat: 48.8472097, lng: 18.4687406 },
    { lat: 48.8475625, lng: 18.4686727 },
    { lat: 48.8478343, lng: 18.4687534 },
    { lat: 48.8481314, lng: 18.4688047 },
    { lat: 48.8489655, lng: 18.4688941 },
    { lat: 48.8492471, lng: 18.4687669 },
    { lat: 48.8493322, lng: 18.468711 },
    { lat: 48.8494138, lng: 18.4685352 },
    { lat: 48.849544, lng: 18.4684009 },
    { lat: 48.8497666, lng: 18.4681583 },
    { lat: 48.8500206, lng: 18.4679385 },
    { lat: 48.8501063, lng: 18.4678768 },
    { lat: 48.8503222, lng: 18.4677399 },
    { lat: 48.8504044, lng: 18.4676744 },
    { lat: 48.8509579, lng: 18.4675267 },
    { lat: 48.851032, lng: 18.4675635 },
    { lat: 48.851034, lng: 18.4677188 },
    { lat: 48.8510484, lng: 18.4677964 },
    { lat: 48.851068, lng: 18.4678255 },
    { lat: 48.8512799, lng: 18.4679656 },
    { lat: 48.8515139, lng: 18.4678705 },
    { lat: 48.8515415, lng: 18.4678391 },
    { lat: 48.8519195, lng: 18.4677415 },
    { lat: 48.8520791, lng: 18.4676472 },
    { lat: 48.8522528, lng: 18.4674827 },
    { lat: 48.8523639, lng: 18.4672477 },
    { lat: 48.8523627, lng: 18.4668567 },
    { lat: 48.8524004, lng: 18.4665994 },
    { lat: 48.8524502, lng: 18.4663979 },
    { lat: 48.8527114, lng: 18.4660998 },
    { lat: 48.853097, lng: 18.4658315 },
    { lat: 48.8534604, lng: 18.4657286 },
    { lat: 48.8537286, lng: 18.465646 },
    { lat: 48.8540234, lng: 18.4656503 },
    { lat: 48.8542819, lng: 18.4656753 },
    { lat: 48.8544564, lng: 18.4656453 },
    { lat: 48.8546235, lng: 18.4655895 },
    { lat: 48.8546686, lng: 18.4655461 },
    { lat: 48.8549206, lng: 18.4655161 },
    { lat: 48.8550536, lng: 18.4655534 },
    { lat: 48.8551689, lng: 18.4656208 },
    { lat: 48.8552708, lng: 18.4656688 },
    { lat: 48.855346, lng: 18.4656597 },
    { lat: 48.8554886, lng: 18.4655672 },
    { lat: 48.8555362, lng: 18.4654873 },
    { lat: 48.855575, lng: 18.4652725 },
    { lat: 48.8555521, lng: 18.4650591 },
    { lat: 48.8555663, lng: 18.4648481 },
    { lat: 48.8556026, lng: 18.4646232 },
    { lat: 48.8556559, lng: 18.4644069 },
    { lat: 48.8557209, lng: 18.4641697 },
    { lat: 48.855832, lng: 18.4639343 },
    { lat: 48.855971, lng: 18.4636929 },
    { lat: 48.8561365, lng: 18.463505 },
    { lat: 48.8562856, lng: 18.4633625 },
    { lat: 48.8565294, lng: 18.463081 },
    { lat: 48.8567392, lng: 18.4629545 },
    { lat: 48.8568862, lng: 18.463043 },
    { lat: 48.8569564, lng: 18.4631119 },
    { lat: 48.8571412, lng: 18.463168 },
    { lat: 48.8573479, lng: 18.4631945 },
    { lat: 48.857511, lng: 18.4631857 },
    { lat: 48.8578009, lng: 18.4629392 },
    { lat: 48.8581053, lng: 18.4626897 },
    { lat: 48.8583822, lng: 18.4625146 },
    { lat: 48.8586966, lng: 18.4624082 },
    { lat: 48.8588246, lng: 18.462479 },
    { lat: 48.858956, lng: 18.4626544 },
    { lat: 48.8590925, lng: 18.4627328 },
    { lat: 48.8591845, lng: 18.462829 },
    { lat: 48.8595979, lng: 18.4631017 },
    { lat: 48.859942, lng: 18.4634103 },
    { lat: 48.8601191, lng: 18.463471 },
    { lat: 48.8602173, lng: 18.4633705 },
    { lat: 48.8603059, lng: 18.4631597 },
    { lat: 48.8605099, lng: 18.4627861 },
    { lat: 48.8605228, lng: 18.4627114 },
    { lat: 48.860605, lng: 18.4624656 },
    { lat: 48.8606425, lng: 18.4623867 },
    { lat: 48.8607252, lng: 18.4622483 },
    { lat: 48.8608079, lng: 18.4621322 },
    { lat: 48.8608859, lng: 18.4620754 },
    { lat: 48.8609799, lng: 18.462061 },
    { lat: 48.8610622, lng: 18.462225 },
    { lat: 48.8609923, lng: 18.4625746 },
    { lat: 48.8610128, lng: 18.4627626 },
    { lat: 48.8610363, lng: 18.4628617 },
    { lat: 48.861102, lng: 18.4629054 },
    { lat: 48.8612166, lng: 18.462878 },
    { lat: 48.8613981, lng: 18.4627536 },
    { lat: 48.8614966, lng: 18.4626355 },
    { lat: 48.8616895, lng: 18.4625333 },
    { lat: 48.8618365, lng: 18.4624374 },
    { lat: 48.8620583, lng: 18.4622026 },
    { lat: 48.8622212, lng: 18.4619896 },
    { lat: 48.8624836, lng: 18.4618342 },
    { lat: 48.8625267, lng: 18.4617983 },
    { lat: 48.8625443, lng: 18.4617477 },
    { lat: 48.8625102, lng: 18.4616435 },
    { lat: 48.8624184, lng: 18.4615304 },
    { lat: 48.862308, lng: 18.4614742 },
    { lat: 48.862193, lng: 18.4613909 },
    { lat: 48.8621306, lng: 18.4612834 },
    { lat: 48.862127, lng: 18.4611807 },
    { lat: 48.8621421, lng: 18.4611229 },
    { lat: 48.8621979, lng: 18.4610342 },
    { lat: 48.8623034, lng: 18.4609406 },
    { lat: 48.8624321, lng: 18.4608788 },
    { lat: 48.8626743, lng: 18.4607912 },
    { lat: 48.8628148, lng: 18.460681 },
    { lat: 48.8628565, lng: 18.4606786 },
    { lat: 48.8629714, lng: 18.4605329 },
    { lat: 48.8630346, lng: 18.460392 },
    { lat: 48.8632824, lng: 18.4599948 },
    { lat: 48.8634506, lng: 18.4596708 },
    { lat: 48.8635723, lng: 18.4594634 },
    { lat: 48.8636964, lng: 18.4592961 },
    { lat: 48.8638019, lng: 18.4591871 },
    { lat: 48.8638917, lng: 18.459075 },
    { lat: 48.8639352, lng: 18.4588635 },
    { lat: 48.8640049, lng: 18.4585939 },
    { lat: 48.8640221, lng: 18.4584555 },
    { lat: 48.8640191, lng: 18.4580317 },
    { lat: 48.8640272, lng: 18.4579121 },
    { lat: 48.8641114, lng: 18.4576385 },
    { lat: 48.8641459, lng: 18.4575474 },
    { lat: 48.8641992, lng: 18.457469 },
    { lat: 48.8643172, lng: 18.4573802 },
    { lat: 48.8645242, lng: 18.4573913 },
    { lat: 48.8646219, lng: 18.4574441 },
    { lat: 48.864789, lng: 18.4576487 },
    { lat: 48.8650395, lng: 18.4578155 },
    { lat: 48.8651232, lng: 18.4578207 },
    { lat: 48.8652524, lng: 18.4577706 },
    { lat: 48.8653933, lng: 18.457789 },
    { lat: 48.8655186, lng: 18.4576681 },
    { lat: 48.8657581, lng: 18.4573042 },
    { lat: 48.8657915, lng: 18.4571732 },
    { lat: 48.8660359, lng: 18.4567563 },
    { lat: 48.8661333, lng: 18.4563972 },
    { lat: 48.8661984, lng: 18.4562259 },
    { lat: 48.8663141, lng: 18.4560357 },
    { lat: 48.8665403, lng: 18.4558379 },
    { lat: 48.866847, lng: 18.4559166 },
    { lat: 48.8672077, lng: 18.4558308 },
    { lat: 48.8673064, lng: 18.4557818 },
    { lat: 48.8675175, lng: 18.4553322 },
    { lat: 48.8676326, lng: 18.4551288 },
    { lat: 48.8677076, lng: 18.4550147 },
    { lat: 48.8678243, lng: 18.4549028 },
    { lat: 48.8679152, lng: 18.4548318 },
    { lat: 48.8680539, lng: 18.4548128 },
    { lat: 48.8684269, lng: 18.4548853 },
    { lat: 48.8687147, lng: 18.4550122 },
    { lat: 48.869417, lng: 18.4556388 },
    { lat: 48.8697816, lng: 18.4557869 },
    { lat: 48.8699122, lng: 18.4558234 },
    { lat: 48.8700673, lng: 18.4560556 },
    { lat: 48.8701581, lng: 18.45621 },
    { lat: 48.8703153, lng: 18.4561762 },
    { lat: 48.87047, lng: 18.4561175 },
    { lat: 48.8708237, lng: 18.4560075 },
    { lat: 48.8710678, lng: 18.4560744 },
    { lat: 48.8716596, lng: 18.4567251 },
    { lat: 48.8716913, lng: 18.4566229 },
    { lat: 48.8717227, lng: 18.4564756 },
    { lat: 48.8713289, lng: 18.4560854 },
    { lat: 48.8714273, lng: 18.4557426 },
    { lat: 48.8712657, lng: 18.4556541 },
    { lat: 48.8703383, lng: 18.4552204 },
    { lat: 48.8699815, lng: 18.4550306 },
    { lat: 48.8698162, lng: 18.4549823 },
    { lat: 48.8696358, lng: 18.4549418 },
    { lat: 48.8694335, lng: 18.4548746 },
    { lat: 48.8694397, lng: 18.4548197 },
    { lat: 48.8697711, lng: 18.4539787 },
    { lat: 48.8700895, lng: 18.4532073 },
    { lat: 48.8709034, lng: 18.4534393 },
    { lat: 48.8709803, lng: 18.4524832 },
    { lat: 48.870352, lng: 18.4516655 },
    { lat: 48.8699294, lng: 18.4516535 },
    { lat: 48.8698593, lng: 18.4513811 },
    { lat: 48.8697552, lng: 18.4508045 },
    { lat: 48.8695835, lng: 18.4501429 },
    { lat: 48.8695448, lng: 18.4498536 },
    { lat: 48.8694299, lng: 18.4494077 },
    { lat: 48.8671234, lng: 18.4489211 },
    { lat: 48.8654231, lng: 18.4477092 },
    { lat: 48.8645094, lng: 18.4468508 },
    { lat: 48.8624864, lng: 18.4460673 },
    { lat: 48.8595002, lng: 18.4468024 },
    { lat: 48.8589635, lng: 18.4467651 },
    { lat: 48.8578693, lng: 18.4459262 },
    { lat: 48.8570226, lng: 18.4463853 },
    { lat: 48.8567123, lng: 18.4468961 },
    { lat: 48.8557979, lng: 18.4447957 },
    { lat: 48.8556864, lng: 18.4445977 },
    { lat: 48.8539127, lng: 18.4419172 },
    { lat: 48.8538523, lng: 18.4391887 },
    { lat: 48.8538134, lng: 18.4381401 },
    { lat: 48.8538963, lng: 18.4368845 },
    { lat: 48.8537955, lng: 18.4364301 },
    { lat: 48.8534292, lng: 18.4355166 },
    { lat: 48.8533917, lng: 18.4352072 },
    { lat: 48.8531982, lng: 18.4349313 },
    { lat: 48.8529868, lng: 18.434289 },
    { lat: 48.8528472, lng: 18.4339664 },
    { lat: 48.8528841, lng: 18.4337181 },
    { lat: 48.852768, lng: 18.4334313 },
    { lat: 48.8527376, lng: 18.4331511 },
    { lat: 48.8523934, lng: 18.4322914 },
    { lat: 48.8521056, lng: 18.4315341 },
    { lat: 48.8519935, lng: 18.4311185 },
    { lat: 48.851942, lng: 18.430697 },
    { lat: 48.8518567, lng: 18.4295659 },
    { lat: 48.8516661, lng: 18.4287906 },
    { lat: 48.8516014, lng: 18.428254 },
    { lat: 48.8515562, lng: 18.4271905 },
    { lat: 48.851438, lng: 18.4264655 },
    { lat: 48.8515166, lng: 18.4257462 },
    { lat: 48.8514247, lng: 18.4243505 },
    { lat: 48.8513579, lng: 18.4240581 },
    { lat: 48.8511353, lng: 18.4236673 },
    { lat: 48.8509805, lng: 18.4232547 },
    { lat: 48.8509389, lng: 18.422483 },
    { lat: 48.8505589, lng: 18.4209184 },
    { lat: 48.84867, lng: 18.41742 },
    { lat: 48.848594, lng: 18.417307 },
    { lat: 48.848083, lng: 18.417362 },
    { lat: 48.847912, lng: 18.417635 },
    { lat: 48.847682, lng: 18.417762 },
    { lat: 48.847257, lng: 18.417808 },
    { lat: 48.846887, lng: 18.417904 },
    { lat: 48.846359, lng: 18.41784 },
    { lat: 48.846249, lng: 18.417823 },
    { lat: 48.845604, lng: 18.417449 },
    { lat: 48.845095, lng: 18.417207 },
    { lat: 48.844764, lng: 18.417293 },
    { lat: 48.844451, lng: 18.417495 },
    { lat: 48.843958, lng: 18.41787 },
    { lat: 48.843335, lng: 18.418236 },
    { lat: 48.84302, lng: 18.418509 },
    { lat: 48.842356, lng: 18.418772 },
    { lat: 48.841904, lng: 18.419032 },
    { lat: 48.841549, lng: 18.419192 },
    { lat: 48.841232, lng: 18.41931 },
    { lat: 48.841043, lng: 18.419551 },
    { lat: 48.840359, lng: 18.41975 },
  ],
  NedožeryBrezany: [
    { lat: 48.832006, lng: 18.729141 },
    { lat: 48.832769, lng: 18.727684 },
    { lat: 48.833377, lng: 18.726794 },
    { lat: 48.833749, lng: 18.72615 },
    { lat: 48.833829, lng: 18.725997 },
    { lat: 48.83398, lng: 18.725545 },
    { lat: 48.8340485, lng: 18.7254252 },
    { lat: 48.83406, lng: 18.725405 },
    { lat: 48.8341207, lng: 18.7253842 },
    { lat: 48.834133, lng: 18.72538 },
    { lat: 48.834297, lng: 18.725314 },
    { lat: 48.834463, lng: 18.725315 },
    { lat: 48.834601, lng: 18.725366 },
    { lat: 48.83527, lng: 18.725492 },
    { lat: 48.836062, lng: 18.724922 },
    { lat: 48.836318, lng: 18.724926 },
    { lat: 48.836501, lng: 18.724758 },
    { lat: 48.837243, lng: 18.723634 },
    { lat: 48.838033, lng: 18.722927 },
    { lat: 48.83851, lng: 18.721235 },
    { lat: 48.839187, lng: 18.720451 },
    { lat: 48.839452, lng: 18.71973 },
    { lat: 48.839916, lng: 18.718654 },
    { lat: 48.840143, lng: 18.717473 },
    { lat: 48.84009, lng: 18.716274 },
    { lat: 48.840633, lng: 18.714687 },
    { lat: 48.841399, lng: 18.713218 },
    { lat: 48.841622, lng: 18.712265 },
    { lat: 48.842551, lng: 18.710183 },
    { lat: 48.842816, lng: 18.709634 },
    { lat: 48.842985, lng: 18.708922 },
    { lat: 48.843068, lng: 18.708483 },
    { lat: 48.843438, lng: 18.708153 },
    { lat: 48.843709, lng: 18.707848 },
    { lat: 48.843745, lng: 18.707807 },
    { lat: 48.844252, lng: 18.707056 },
    { lat: 48.84446, lng: 18.70624 },
    { lat: 48.845204, lng: 18.704342 },
    { lat: 48.845665, lng: 18.703085 },
    { lat: 48.845831, lng: 18.702843 },
    { lat: 48.845913, lng: 18.702309 },
    { lat: 48.845938, lng: 18.702152 },
    { lat: 48.845999, lng: 18.701754 },
    { lat: 48.845988, lng: 18.700124 },
    { lat: 48.846, lng: 18.700065 },
    { lat: 48.846027, lng: 18.699759 },
    { lat: 48.846154, lng: 18.699503 },
    { lat: 48.846187, lng: 18.699515 },
    { lat: 48.846342, lng: 18.699569 },
    { lat: 48.846402, lng: 18.699531 },
    { lat: 48.84695, lng: 18.699184 },
    { lat: 48.846994, lng: 18.699157 },
    { lat: 48.847071, lng: 18.69904 },
    { lat: 48.847439, lng: 18.698481 },
    { lat: 48.847484, lng: 18.698412 },
    { lat: 48.847707, lng: 18.698074 },
    { lat: 48.8477647, lng: 18.697765 },
    { lat: 48.8477883, lng: 18.6969572 },
    { lat: 48.8477003, lng: 18.6964652 },
    { lat: 48.8476748, lng: 18.6963899 },
    { lat: 48.8480733, lng: 18.6956517 },
    { lat: 48.848124, lng: 18.6948667 },
    { lat: 48.847896, lng: 18.6940347 },
    { lat: 48.8477036, lng: 18.6935969 },
    { lat: 48.8474461, lng: 18.6934203 },
    { lat: 48.8472897, lng: 18.6931901 },
    { lat: 48.8470787, lng: 18.6928481 },
    { lat: 48.8468352, lng: 18.6920835 },
    { lat: 48.8467032, lng: 18.6916416 },
    { lat: 48.8466629, lng: 18.6913049 },
    { lat: 48.846662, lng: 18.6912427 },
    { lat: 48.8467746, lng: 18.6907958 },
    { lat: 48.8468963, lng: 18.6903684 },
    { lat: 48.8469452, lng: 18.6898869 },
    { lat: 48.8470166, lng: 18.689339 },
    { lat: 48.847045, lng: 18.6889617 },
    { lat: 48.8470071, lng: 18.6886759 },
    { lat: 48.8468211, lng: 18.6882295 },
    { lat: 48.8466816, lng: 18.6879396 },
    { lat: 48.8464871, lng: 18.6875706 },
    { lat: 48.8463712, lng: 18.6873598 },
    { lat: 48.8460427, lng: 18.6868026 },
    { lat: 48.8459811, lng: 18.6867063 },
    { lat: 48.8459044, lng: 18.6860683 },
    { lat: 48.845904, lng: 18.6856164 },
    { lat: 48.8458993, lng: 18.6854201 },
    { lat: 48.8458766, lng: 18.6851006 },
    { lat: 48.8457538, lng: 18.6845892 },
    { lat: 48.8456828, lng: 18.6842108 },
    { lat: 48.8457036, lng: 18.6836668 },
    { lat: 48.8458012, lng: 18.6834057 },
    { lat: 48.8458897, lng: 18.6832037 },
    { lat: 48.8460568, lng: 18.6827357 },
    { lat: 48.8464411, lng: 18.6815674 },
    { lat: 48.8464575, lng: 18.6812578 },
    { lat: 48.8464449, lng: 18.6809148 },
    { lat: 48.8461118, lng: 18.6792765 },
    { lat: 48.845931, lng: 18.6785248 },
    { lat: 48.8457784, lng: 18.6780951 },
    { lat: 48.8457913, lng: 18.6780187 },
    { lat: 48.8455455, lng: 18.677281 },
    { lat: 48.8451103, lng: 18.6766478 },
    { lat: 48.8449051, lng: 18.6762027 },
    { lat: 48.8446611, lng: 18.6758361 },
    { lat: 48.8444514, lng: 18.6753286 },
    { lat: 48.8444338, lng: 18.6752276 },
    { lat: 48.8443251, lng: 18.6751124 },
    { lat: 48.8442055, lng: 18.6750503 },
    { lat: 48.8440923, lng: 18.6750413 },
    { lat: 48.843887, lng: 18.6750832 },
    { lat: 48.8438031, lng: 18.6750751 },
    { lat: 48.8436686, lng: 18.6749845 },
    { lat: 48.8433283, lng: 18.6747361 },
    { lat: 48.8429, lng: 18.6742331 },
    { lat: 48.8428371, lng: 18.6741119 },
    { lat: 48.8427218, lng: 18.6737901 },
    { lat: 48.842715, lng: 18.6734927 },
    { lat: 48.8432954, lng: 18.6721292 },
    { lat: 48.8427995, lng: 18.6710762 },
    { lat: 48.8426409, lng: 18.6707029 },
    { lat: 48.8423245, lng: 18.6703087 },
    { lat: 48.842009, lng: 18.6700601 },
    { lat: 48.841865, lng: 18.6699176 },
    { lat: 48.8417739, lng: 18.6697727 },
    { lat: 48.8417015, lng: 18.6696036 },
    { lat: 48.8416072, lng: 18.6693552 },
    { lat: 48.8416027, lng: 18.6692691 },
    { lat: 48.8414792, lng: 18.6690275 },
    { lat: 48.8414054, lng: 18.6689119 },
    { lat: 48.841341, lng: 18.6687182 },
    { lat: 48.8413091, lng: 18.6684618 },
    { lat: 48.8412518, lng: 18.6681487 },
    { lat: 48.8412315, lng: 18.6677452 },
    { lat: 48.841203, lng: 18.6675693 },
    { lat: 48.8411971, lng: 18.6673534 },
    { lat: 48.8411437, lng: 18.6671143 },
    { lat: 48.8410948, lng: 18.6670207 },
    { lat: 48.8411286, lng: 18.6668913 },
    { lat: 48.8410926, lng: 18.6666426 },
    { lat: 48.8411206, lng: 18.6664673 },
    { lat: 48.8411449, lng: 18.6664357 },
    { lat: 48.8411267, lng: 18.6661164 },
    { lat: 48.8411473, lng: 18.6657844 },
    { lat: 48.841216, lng: 18.6656321 },
    { lat: 48.8412946, lng: 18.6655631 },
    { lat: 48.8413321, lng: 18.6654199 },
    { lat: 48.8413441, lng: 18.6652416 },
    { lat: 48.8412812, lng: 18.6650588 },
    { lat: 48.8412771, lng: 18.6649823 },
    { lat: 48.8413152, lng: 18.6648903 },
    { lat: 48.8413408, lng: 18.6644629 },
    { lat: 48.8413277, lng: 18.6641945 },
    { lat: 48.8412755, lng: 18.6639148 },
    { lat: 48.8412478, lng: 18.6638176 },
    { lat: 48.8411528, lng: 18.6636001 },
    { lat: 48.841118, lng: 18.6634579 },
    { lat: 48.8410842, lng: 18.6632447 },
    { lat: 48.8410416, lng: 18.663102 },
    { lat: 48.8409495, lng: 18.6628759 },
    { lat: 48.8409631, lng: 18.6627325 },
    { lat: 48.8409032, lng: 18.6625583 },
    { lat: 48.8406723, lng: 18.6623087 },
    { lat: 48.8404448, lng: 18.6621078 },
    { lat: 48.8401513, lng: 18.66193 },
    { lat: 48.8399984, lng: 18.6618162 },
    { lat: 48.8398698, lng: 18.6618155 },
    { lat: 48.8397336, lng: 18.6617731 },
    { lat: 48.839686, lng: 18.6616651 },
    { lat: 48.8394835, lng: 18.6615284 },
    { lat: 48.83946, lng: 18.661426 },
    { lat: 48.8394074, lng: 18.6613923 },
    { lat: 48.8393785, lng: 18.6614206 },
    { lat: 48.8393171, lng: 18.6614313 },
    { lat: 48.8392901, lng: 18.6612223 },
    { lat: 48.8391667, lng: 18.661106 },
    { lat: 48.8390873, lng: 18.6610978 },
    { lat: 48.8389769, lng: 18.6610562 },
    { lat: 48.8387139, lng: 18.6608854 },
    { lat: 48.8385396, lng: 18.6608506 },
    { lat: 48.8383476, lng: 18.6605705 },
    { lat: 48.8382688, lng: 18.6605543 },
    { lat: 48.8381776, lng: 18.6602812 },
    { lat: 48.8381657, lng: 18.6601194 },
    { lat: 48.8380351, lng: 18.6600201 },
    { lat: 48.8378598, lng: 18.6599507 },
    { lat: 48.8375892, lng: 18.6597382 },
    { lat: 48.8373333, lng: 18.6597391 },
    { lat: 48.8371496, lng: 18.659617 },
    { lat: 48.8371063, lng: 18.6595228 },
    { lat: 48.8370079, lng: 18.6594553 },
    { lat: 48.8369983, lng: 18.6593999 },
    { lat: 48.8368481, lng: 18.6592515 },
    { lat: 48.8367751, lng: 18.6592339 },
    { lat: 48.8367132, lng: 18.6591105 },
    { lat: 48.8365986, lng: 18.659097 },
    { lat: 48.8364578, lng: 18.6590143 },
    { lat: 48.8364047, lng: 18.6589324 },
    { lat: 48.8361898, lng: 18.6582828 },
    { lat: 48.8362081, lng: 18.6582057 },
    { lat: 48.836075, lng: 18.658139 },
    { lat: 48.8360083, lng: 18.6580322 },
    { lat: 48.8359374, lng: 18.6579697 },
    { lat: 48.8358933, lng: 18.6579042 },
    { lat: 48.8359137, lng: 18.6578222 },
    { lat: 48.8358841, lng: 18.6577324 },
    { lat: 48.8359064, lng: 18.6576677 },
    { lat: 48.8358454, lng: 18.6576 },
    { lat: 48.835779, lng: 18.6573475 },
    { lat: 48.8357725, lng: 18.6571421 },
    { lat: 48.8357505, lng: 18.6570492 },
    { lat: 48.8356684, lng: 18.6569905 },
    { lat: 48.8356654, lng: 18.6568892 },
    { lat: 48.8357193, lng: 18.6568228 },
    { lat: 48.8356207, lng: 18.6566849 },
    { lat: 48.8356331, lng: 18.6566039 },
    { lat: 48.835591, lng: 18.6564695 },
    { lat: 48.835538, lng: 18.6563854 },
    { lat: 48.8354561, lng: 18.6563295 },
    { lat: 48.8354209, lng: 18.6563639 },
    { lat: 48.8353874, lng: 18.6563516 },
    { lat: 48.8353748, lng: 18.6562851 },
    { lat: 48.8354089, lng: 18.6562462 },
    { lat: 48.8354079, lng: 18.6560193 },
    { lat: 48.8353822, lng: 18.655958 },
    { lat: 48.8354023, lng: 18.6558091 },
    { lat: 48.8353442, lng: 18.6556892 },
    { lat: 48.8353441, lng: 18.6556043 },
    { lat: 48.8353807, lng: 18.6555712 },
    { lat: 48.8354077, lng: 18.6555071 },
    { lat: 48.8353851, lng: 18.6552521 },
    { lat: 48.8354286, lng: 18.6551146 },
    { lat: 48.835395, lng: 18.65491 },
    { lat: 48.8354894, lng: 18.6547753 },
    { lat: 48.8355142, lng: 18.654672 },
    { lat: 48.8354572, lng: 18.6545133 },
    { lat: 48.8354195, lng: 18.654166 },
    { lat: 48.8354494, lng: 18.6539249 },
    { lat: 48.8354138, lng: 18.6538684 },
    { lat: 48.8353883, lng: 18.6537935 },
    { lat: 48.8353175, lng: 18.6537947 },
    { lat: 48.8353126, lng: 18.6536854 },
    { lat: 48.8352884, lng: 18.6534861 },
    { lat: 48.8353206, lng: 18.6534334 },
    { lat: 48.8353382, lng: 18.6532988 },
    { lat: 48.8353166, lng: 18.6531679 },
    { lat: 48.8353337, lng: 18.6530459 },
    { lat: 48.8353308, lng: 18.6529245 },
    { lat: 48.8353607, lng: 18.6528521 },
    { lat: 48.8353073, lng: 18.6527599 },
    { lat: 48.8352903, lng: 18.6524863 },
    { lat: 48.8352734, lng: 18.6523154 },
    { lat: 48.8352922, lng: 18.6522695 },
    { lat: 48.8352199, lng: 18.6522422 },
    { lat: 48.8351987, lng: 18.6521431 },
    { lat: 48.8351988, lng: 18.6519311 },
    { lat: 48.8352516, lng: 18.6518226 },
    { lat: 48.8352195, lng: 18.6516476 },
    { lat: 48.8351712, lng: 18.6514817 },
    { lat: 48.8351252, lng: 18.6512922 },
    { lat: 48.8350295, lng: 18.651276 },
    { lat: 48.8350065, lng: 18.6511188 },
    { lat: 48.8350492, lng: 18.6510967 },
    { lat: 48.8350882, lng: 18.6510253 },
    { lat: 48.8350857, lng: 18.6507876 },
    { lat: 48.8350389, lng: 18.6506902 },
    { lat: 48.8350427, lng: 18.6506375 },
    { lat: 48.8350736, lng: 18.6505621 },
    { lat: 48.8350346, lng: 18.6503367 },
    { lat: 48.8350388, lng: 18.650246 },
    { lat: 48.8350324, lng: 18.6501927 },
    { lat: 48.8350381, lng: 18.6501723 },
    { lat: 48.8350427, lng: 18.6500897 },
    { lat: 48.8350226, lng: 18.6500725 },
    { lat: 48.8349933, lng: 18.6500711 },
    { lat: 48.834955, lng: 18.6500311 },
    { lat: 48.8349075, lng: 18.6500283 },
    { lat: 48.8348736, lng: 18.6499637 },
    { lat: 48.8348276, lng: 18.6499475 },
    { lat: 48.8348134, lng: 18.6498948 },
    { lat: 48.8347838, lng: 18.6498666 },
    { lat: 48.8347449, lng: 18.6498775 },
    { lat: 48.8346746, lng: 18.6498048 },
    { lat: 48.8346377, lng: 18.6498129 },
    { lat: 48.8345189, lng: 18.6496159 },
    { lat: 48.8343903, lng: 18.6495314 },
    { lat: 48.834402, lng: 18.6494981 },
    { lat: 48.8343902, lng: 18.6493822 },
    { lat: 48.8343312, lng: 18.6492688 },
    { lat: 48.8342726, lng: 18.6491861 },
    { lat: 48.8342359, lng: 18.6490692 },
    { lat: 48.8341868, lng: 18.6490126 },
    { lat: 48.8341493, lng: 18.6488826 },
    { lat: 48.8341733, lng: 18.6487418 },
    { lat: 48.8341629, lng: 18.6486933 },
    { lat: 48.8341535, lng: 18.6485406 },
    { lat: 48.8341067, lng: 18.6484657 },
    { lat: 48.8340331, lng: 18.6483133 },
    { lat: 48.8339936, lng: 18.6481832 },
    { lat: 48.8339723, lng: 18.648043 },
    { lat: 48.8338803, lng: 18.6475234 },
    { lat: 48.8337969, lng: 18.6472891 },
    { lat: 48.8336558, lng: 18.6472287 },
    { lat: 48.8334346, lng: 18.6470332 },
    { lat: 48.833153, lng: 18.6467441 },
    { lat: 48.833072, lng: 18.6462192 },
    { lat: 48.8328463, lng: 18.6461975 },
    { lat: 48.8327484, lng: 18.6459038 },
    { lat: 48.8326875, lng: 18.6456771 },
    { lat: 48.8326575, lng: 18.6454732 },
    { lat: 48.8325148, lng: 18.6454388 },
    { lat: 48.8323154, lng: 18.6453609 },
    { lat: 48.8322399, lng: 18.6453415 },
    { lat: 48.8322076, lng: 18.6451396 },
    { lat: 48.8321732, lng: 18.6450443 },
    { lat: 48.8320926, lng: 18.6449075 },
    { lat: 48.8320142, lng: 18.644833 },
    { lat: 48.831929, lng: 18.6447783 },
    { lat: 48.831564, lng: 18.6445771 },
    { lat: 48.8314646, lng: 18.6444866 },
    { lat: 48.8309994, lng: 18.6441843 },
    { lat: 48.8308074, lng: 18.6438599 },
    { lat: 48.8308071, lng: 18.6436634 },
    { lat: 48.8307591, lng: 18.6430844 },
    { lat: 48.8304867, lng: 18.642989 },
    { lat: 48.8302538, lng: 18.6428328 },
    { lat: 48.8301182, lng: 18.6427199 },
    { lat: 48.8299469, lng: 18.6425355 },
    { lat: 48.8298472, lng: 18.6423591 },
    { lat: 48.8297088, lng: 18.6420471 },
    { lat: 48.8296166, lng: 18.6420337 },
    { lat: 48.8296126, lng: 18.6419361 },
    { lat: 48.829362, lng: 18.641741 },
    { lat: 48.8292467, lng: 18.6416302 },
    { lat: 48.828978, lng: 18.641331 },
    { lat: 48.828776, lng: 18.6410548 },
    { lat: 48.828685, lng: 18.6408734 },
    { lat: 48.828668, lng: 18.6408078 },
    { lat: 48.8288763, lng: 18.6406537 },
    { lat: 48.829051, lng: 18.6406513 },
    { lat: 48.8291712, lng: 18.6403877 },
    { lat: 48.8291473, lng: 18.6401759 },
    { lat: 48.8291527, lng: 18.640148 },
    { lat: 48.8291895, lng: 18.640098 },
    { lat: 48.8293785, lng: 18.6400931 },
    { lat: 48.8294334, lng: 18.6400634 },
    { lat: 48.8294424, lng: 18.6397677 },
    { lat: 48.8294457, lng: 18.6397455 },
    { lat: 48.8294581, lng: 18.6397264 },
    { lat: 48.8295465, lng: 18.6396901 },
    { lat: 48.8297149, lng: 18.6399057 },
    { lat: 48.8297225, lng: 18.6399237 },
    { lat: 48.8296962, lng: 18.6401478 },
    { lat: 48.8296993, lng: 18.6402269 },
    { lat: 48.8297757, lng: 18.6404101 },
    { lat: 48.8298462, lng: 18.6404412 },
    { lat: 48.8299882, lng: 18.6403575 },
    { lat: 48.8300966, lng: 18.6403156 },
    { lat: 48.8302269, lng: 18.6402194 },
    { lat: 48.8302529, lng: 18.6402473 },
    { lat: 48.8302598, lng: 18.6402701 },
    { lat: 48.8302806, lng: 18.6404469 },
    { lat: 48.8303247, lng: 18.6404705 },
    { lat: 48.8304462, lng: 18.6403167 },
    { lat: 48.8306017, lng: 18.6403126 },
    { lat: 48.8306267, lng: 18.6403367 },
    { lat: 48.8306404, lng: 18.6403816 },
    { lat: 48.8306393, lng: 18.640405 },
    { lat: 48.8306159, lng: 18.6405152 },
    { lat: 48.8306139, lng: 18.6405409 },
    { lat: 48.8306184, lng: 18.6405618 },
    { lat: 48.8306482, lng: 18.6406108 },
    { lat: 48.830744, lng: 18.6406355 },
    { lat: 48.8307564, lng: 18.6406551 },
    { lat: 48.830783, lng: 18.6407503 },
    { lat: 48.8307879, lng: 18.640885 },
    { lat: 48.830825, lng: 18.6409463 },
    { lat: 48.830892, lng: 18.6408916 },
    { lat: 48.8309796, lng: 18.6407981 },
    { lat: 48.8310145, lng: 18.6407129 },
    { lat: 48.8310229, lng: 18.6404499 },
    { lat: 48.8310391, lng: 18.6403279 },
    { lat: 48.8310449, lng: 18.6403126 },
    { lat: 48.8311852, lng: 18.6402144 },
    { lat: 48.8312251, lng: 18.6401 },
    { lat: 48.8312825, lng: 18.6399891 },
    { lat: 48.831431, lng: 18.6398352 },
    { lat: 48.8315114, lng: 18.6398851 },
    { lat: 48.831542, lng: 18.6399927 },
    { lat: 48.8315649, lng: 18.6402086 },
    { lat: 48.8316177, lng: 18.6402053 },
    { lat: 48.8317649, lng: 18.640024 },
    { lat: 48.8318109, lng: 18.6399174 },
    { lat: 48.8318504, lng: 18.6397268 },
    { lat: 48.8318721, lng: 18.6396908 },
    { lat: 48.832095, lng: 18.6397053 },
    { lat: 48.8321956, lng: 18.6396421 },
    { lat: 48.8322714, lng: 18.6397337 },
    { lat: 48.8323256, lng: 18.6399641 },
    { lat: 48.8323588, lng: 18.6402049 },
    { lat: 48.8326297, lng: 18.6404436 },
    { lat: 48.8326432, lng: 18.640462 },
    { lat: 48.8326514, lng: 18.6405537 },
    { lat: 48.8326814, lng: 18.6407129 },
    { lat: 48.8326896, lng: 18.6407374 },
    { lat: 48.8327402, lng: 18.6407777 },
    { lat: 48.8328597, lng: 18.6407981 },
    { lat: 48.8329184, lng: 18.6407168 },
    { lat: 48.832979, lng: 18.6407086 },
    { lat: 48.8330139, lng: 18.6407516 },
    { lat: 48.8330615, lng: 18.6410268 },
    { lat: 48.8331121, lng: 18.641027 },
    { lat: 48.83324, lng: 18.6409322 },
    { lat: 48.833263, lng: 18.6409594 },
    { lat: 48.8332719, lng: 18.6409762 },
    { lat: 48.8333154, lng: 18.6411796 },
    { lat: 48.8333375, lng: 18.6414242 },
    { lat: 48.8333657, lng: 18.641443 },
    { lat: 48.8335006, lng: 18.641353 },
    { lat: 48.8335967, lng: 18.6413562 },
    { lat: 48.8338341, lng: 18.6411778 },
    { lat: 48.8338973, lng: 18.6411568 },
    { lat: 48.8339723, lng: 18.6411905 },
    { lat: 48.8342083, lng: 18.6409629 },
    { lat: 48.8342568, lng: 18.6406669 },
    { lat: 48.8342884, lng: 18.6405832 },
    { lat: 48.8342999, lng: 18.6405685 },
    { lat: 48.8343141, lng: 18.6405589 },
    { lat: 48.8344851, lng: 18.6405927 },
    { lat: 48.8346301, lng: 18.6405419 },
    { lat: 48.8347933, lng: 18.6405348 },
    { lat: 48.8349044, lng: 18.6403554 },
    { lat: 48.8349163, lng: 18.6403475 },
    { lat: 48.8349847, lng: 18.6403588 },
    { lat: 48.8350524, lng: 18.640445 },
    { lat: 48.8352596, lng: 18.6405004 },
    { lat: 48.8353849, lng: 18.6405058 },
    { lat: 48.8356713, lng: 18.6405673 },
    { lat: 48.8357198, lng: 18.6405456 },
    { lat: 48.835751, lng: 18.6405138 },
    { lat: 48.8357735, lng: 18.6404323 },
    { lat: 48.8357951, lng: 18.6403942 },
    { lat: 48.8358239, lng: 18.6403613 },
    { lat: 48.8358879, lng: 18.6403563 },
    { lat: 48.8359029, lng: 18.6403637 },
    { lat: 48.8359303, lng: 18.6403887 },
    { lat: 48.8359967, lng: 18.6405964 },
    { lat: 48.8360154, lng: 18.6406102 },
    { lat: 48.8360371, lng: 18.6406147 },
    { lat: 48.8361681, lng: 18.6404074 },
    { lat: 48.8361889, lng: 18.6403941 },
    { lat: 48.8362102, lng: 18.6403915 },
    { lat: 48.8362951, lng: 18.6405472 },
    { lat: 48.8363749, lng: 18.6405854 },
    { lat: 48.8364789, lng: 18.6406073 },
    { lat: 48.8365533, lng: 18.6405044 },
    { lat: 48.8366738, lng: 18.6404545 },
    { lat: 48.8367473, lng: 18.6404379 },
    { lat: 48.8368486, lng: 18.6404975 },
    { lat: 48.8368765, lng: 18.6406394 },
    { lat: 48.836914, lng: 18.6406681 },
    { lat: 48.8370738, lng: 18.6407219 },
    { lat: 48.8371228, lng: 18.6407771 },
    { lat: 48.8371727, lng: 18.6409721 },
    { lat: 48.8372107, lng: 18.6410058 },
    { lat: 48.8373242, lng: 18.6409764 },
    { lat: 48.8373853, lng: 18.6409381 },
    { lat: 48.8375053, lng: 18.6408414 },
    { lat: 48.8375172, lng: 18.6407676 },
    { lat: 48.8375023, lng: 18.6405324 },
    { lat: 48.8375086, lng: 18.6405052 },
    { lat: 48.8375219, lng: 18.6404806 },
    { lat: 48.8375515, lng: 18.6404816 },
    { lat: 48.8376491, lng: 18.6405771 },
    { lat: 48.8377636, lng: 18.6407162 },
    { lat: 48.8378017, lng: 18.6407309 },
    { lat: 48.8378676, lng: 18.6407214 },
    { lat: 48.8380201, lng: 18.640554 },
    { lat: 48.8381262, lng: 18.640493 },
    { lat: 48.8383253, lng: 18.640508 },
    { lat: 48.8386436, lng: 18.6407016 },
    { lat: 48.8387374, lng: 18.6408071 },
    { lat: 48.8388474, lng: 18.6407553 },
    { lat: 48.8389123, lng: 18.6407674 },
    { lat: 48.8389309, lng: 18.6408195 },
    { lat: 48.8389108, lng: 18.6410354 },
    { lat: 48.8390254, lng: 18.6411316 },
    { lat: 48.8391187, lng: 18.6411484 },
    { lat: 48.8393117, lng: 18.6411508 },
    { lat: 48.8394495, lng: 18.6411962 },
    { lat: 48.839636, lng: 18.6400261 },
    { lat: 48.8397851, lng: 18.6391034 },
    { lat: 48.8398458, lng: 18.63874 },
    { lat: 48.8400561, lng: 18.6375258 },
    { lat: 48.8401695, lng: 18.6368628 },
    { lat: 48.8402929, lng: 18.635945 },
    { lat: 48.8403694, lng: 18.6353967 },
    { lat: 48.8406058, lng: 18.6346093 },
    { lat: 48.8407579, lng: 18.6334294 },
    { lat: 48.8408362, lng: 18.6327195 },
    { lat: 48.8408835, lng: 18.6318776 },
    { lat: 48.8399188, lng: 18.6320607 },
    { lat: 48.8399914, lng: 18.6311384 },
    { lat: 48.8398619, lng: 18.6309538 },
    { lat: 48.8398891, lng: 18.6306633 },
    { lat: 48.8397541, lng: 18.6305002 },
    { lat: 48.8396927, lng: 18.630072 },
    { lat: 48.839209, lng: 18.6300123 },
    { lat: 48.8392351, lng: 18.6295734 },
    { lat: 48.8392301, lng: 18.6293302 },
    { lat: 48.8391801, lng: 18.6289894 },
    { lat: 48.8391074, lng: 18.6289593 },
    { lat: 48.8390286, lng: 18.6289794 },
    { lat: 48.8389807, lng: 18.6290791 },
    { lat: 48.8388632, lng: 18.6291791 },
    { lat: 48.8387813, lng: 18.6292082 },
    { lat: 48.8386925, lng: 18.6291915 },
    { lat: 48.8386288, lng: 18.6291629 },
    { lat: 48.8385569, lng: 18.6291656 },
    { lat: 48.8383958, lng: 18.6293176 },
    { lat: 48.8382811, lng: 18.6293636 },
    { lat: 48.8381994, lng: 18.6293786 },
    { lat: 48.8381138, lng: 18.6294214 },
    { lat: 48.8379857, lng: 18.6295771 },
    { lat: 48.8378587, lng: 18.6296901 },
    { lat: 48.8377653, lng: 18.6297153 },
    { lat: 48.8376434, lng: 18.6297166 },
    { lat: 48.8374853, lng: 18.6297726 },
    { lat: 48.8372722, lng: 18.6299421 },
    { lat: 48.8371967, lng: 18.630028 },
    { lat: 48.8371476, lng: 18.6300598 },
    { lat: 48.8370524, lng: 18.6300708 },
    { lat: 48.8370123, lng: 18.6301019 },
    { lat: 48.8368329, lng: 18.6303319 },
    { lat: 48.8367657, lng: 18.6304057 },
    { lat: 48.8366462, lng: 18.6304895 },
    { lat: 48.8364566, lng: 18.6305512 },
    { lat: 48.8362426, lng: 18.6305141 },
    { lat: 48.836204, lng: 18.6306972 },
    { lat: 48.8360548, lng: 18.6310929 },
    { lat: 48.8358353, lng: 18.631204 },
    { lat: 48.8356436, lng: 18.6311646 },
    { lat: 48.8355854, lng: 18.6311267 },
    { lat: 48.8355119, lng: 18.6309381 },
    { lat: 48.8347956, lng: 18.6308071 },
    { lat: 48.8345809, lng: 18.6308228 },
    { lat: 48.8344996, lng: 18.6308629 },
    { lat: 48.8342685, lng: 18.6309461 },
    { lat: 48.8342477, lng: 18.6317577 },
    { lat: 48.8339346, lng: 18.6319358 },
    { lat: 48.8337676, lng: 18.6319757 },
    { lat: 48.8333708, lng: 18.6319361 },
    { lat: 48.8332958, lng: 18.6328972 },
    { lat: 48.8331302, lng: 18.6329198 },
    { lat: 48.8330162, lng: 18.6328561 },
    { lat: 48.8329196, lng: 18.6328222 },
    { lat: 48.8324183, lng: 18.6327696 },
    { lat: 48.8320508, lng: 18.6327923 },
    { lat: 48.8318724, lng: 18.6328099 },
    { lat: 48.8316915, lng: 18.6328429 },
    { lat: 48.8316292, lng: 18.6328398 },
    { lat: 48.8311889, lng: 18.6327046 },
    { lat: 48.8309784, lng: 18.6325866 },
    { lat: 48.8307729, lng: 18.6324783 },
    { lat: 48.8306861, lng: 18.6324153 },
    { lat: 48.8304791, lng: 18.6322968 },
    { lat: 48.8303659, lng: 18.6322048 },
    { lat: 48.8302516, lng: 18.6320907 },
    { lat: 48.830124, lng: 18.6319854 },
    { lat: 48.8300618, lng: 18.6320027 },
    { lat: 48.8299691, lng: 18.6321247 },
    { lat: 48.8293779, lng: 18.6327104 },
    { lat: 48.8291562, lng: 18.6328017 },
    { lat: 48.8289738, lng: 18.6328961 },
    { lat: 48.8287337, lng: 18.6330012 },
    { lat: 48.828564, lng: 18.6330321 },
    { lat: 48.8284942, lng: 18.6330131 },
    { lat: 48.8281798, lng: 18.6328094 },
    { lat: 48.8280605, lng: 18.6327529 },
    { lat: 48.8276551, lng: 18.6327823 },
    { lat: 48.8276136, lng: 18.6328715 },
    { lat: 48.827498, lng: 18.6330529 },
    { lat: 48.8275007, lng: 18.6331428 },
    { lat: 48.8274653, lng: 18.6332008 },
    { lat: 48.8273936, lng: 18.6333983 },
    { lat: 48.827352, lng: 18.6336321 },
    { lat: 48.8272897, lng: 18.6338138 },
    { lat: 48.8272526, lng: 18.6338405 },
    { lat: 48.8272911, lng: 18.6339464 },
    { lat: 48.8272028, lng: 18.6341953 },
    { lat: 48.8271395, lng: 18.6342958 },
    { lat: 48.8270617, lng: 18.634358 },
    { lat: 48.8270174, lng: 18.6344397 },
    { lat: 48.826933, lng: 18.6345059 },
    { lat: 48.8268911, lng: 18.6345665 },
    { lat: 48.826827, lng: 18.6346084 },
    { lat: 48.8266836, lng: 18.6347771 },
    { lat: 48.8266275, lng: 18.6348019 },
    { lat: 48.8266054, lng: 18.6348536 },
    { lat: 48.826525, lng: 18.6349097 },
    { lat: 48.8264565, lng: 18.6349804 },
    { lat: 48.8255221, lng: 18.635053 },
    { lat: 48.8247509, lng: 18.6347434 },
    { lat: 48.8246104, lng: 18.6349396 },
    { lat: 48.8244645, lng: 18.6349522 },
    { lat: 48.8238858, lng: 18.6350369 },
    { lat: 48.8232188, lng: 18.6350885 },
    { lat: 48.8223735, lng: 18.6351831 },
    { lat: 48.8218415, lng: 18.6352546 },
    { lat: 48.8217679, lng: 18.635187 },
    { lat: 48.8216615, lng: 18.635136 },
    { lat: 48.8214224, lng: 18.6350727 },
    { lat: 48.8213693, lng: 18.6350069 },
    { lat: 48.8209957, lng: 18.6348585 },
    { lat: 48.8207867, lng: 18.6349547 },
    { lat: 48.8202036, lng: 18.6351649 },
    { lat: 48.8196356, lng: 18.6353847 },
    { lat: 48.8192714, lng: 18.6355125 },
    { lat: 48.8188666, lng: 18.635579 },
    { lat: 48.8185765, lng: 18.6356777 },
    { lat: 48.8182728, lng: 18.6357347 },
    { lat: 48.8182088, lng: 18.6351936 },
    { lat: 48.8181073, lng: 18.6354247 },
    { lat: 48.8180505, lng: 18.6354603 },
    { lat: 48.817942, lng: 18.6354776 },
    { lat: 48.8179127, lng: 18.635561 },
    { lat: 48.8179047, lng: 18.6356618 },
    { lat: 48.817748, lng: 18.6357505 },
    { lat: 48.8176825, lng: 18.6357507 },
    { lat: 48.817443, lng: 18.6358904 },
    { lat: 48.8173632, lng: 18.6358962 },
    { lat: 48.8171294, lng: 18.6358237 },
    { lat: 48.8167527, lng: 18.636036 },
    { lat: 48.8165469, lng: 18.636173 },
    { lat: 48.8164814, lng: 18.6361907 },
    { lat: 48.8162706, lng: 18.6348674 },
    { lat: 48.8156794, lng: 18.635012 },
    { lat: 48.8147229, lng: 18.6351786 },
    { lat: 48.8146216, lng: 18.6352028 },
    { lat: 48.8143005, lng: 18.6351242 },
    { lat: 48.8138057, lng: 18.6350258 },
    { lat: 48.8131934, lng: 18.6348409 },
    { lat: 48.8131644, lng: 18.6348022 },
    { lat: 48.8131515, lng: 18.6348138 },
    { lat: 48.8130716, lng: 18.6346937 },
    { lat: 48.8129016, lng: 18.6345071 },
    { lat: 48.8125324, lng: 18.6340794 },
    { lat: 48.8122704, lng: 18.6337802 },
    { lat: 48.8120796, lng: 18.633629 },
    { lat: 48.8119263, lng: 18.6335649 },
    { lat: 48.8117231, lng: 18.6335219 },
    { lat: 48.8115472, lng: 18.6334601 },
    { lat: 48.8113119, lng: 18.6333205 },
    { lat: 48.8112851, lng: 18.6330486 },
    { lat: 48.8112434, lng: 18.6325678 },
    { lat: 48.8112066, lng: 18.6321976 },
    { lat: 48.8111537, lng: 18.6317976 },
    { lat: 48.8111001, lng: 18.6310324 },
    { lat: 48.8106911, lng: 18.6308494 },
    { lat: 48.8105467, lng: 18.6307662 },
    { lat: 48.8103587, lng: 18.6306262 },
    { lat: 48.8103061, lng: 18.6305271 },
    { lat: 48.810187, lng: 18.6304089 },
    { lat: 48.8100765, lng: 18.6302601 },
    { lat: 48.8099445, lng: 18.6301801 },
    { lat: 48.8093741, lng: 18.630266 },
    { lat: 48.8087465, lng: 18.6304036 },
    { lat: 48.808191, lng: 18.6306054 },
    { lat: 48.8080283, lng: 18.630683 },
    { lat: 48.8077771, lng: 18.6307715 },
    { lat: 48.8077492, lng: 18.630547 },
    { lat: 48.8073062, lng: 18.6306366 },
    { lat: 48.8072473, lng: 18.6302978 },
    { lat: 48.8072141, lng: 18.6300121 },
    { lat: 48.8071859, lng: 18.6297157 },
    { lat: 48.8072436, lng: 18.6295096 },
    { lat: 48.8072469, lng: 18.6294656 },
    { lat: 48.8072441, lng: 18.6294362 },
    { lat: 48.8074463, lng: 18.6293155 },
    { lat: 48.8075042, lng: 18.6288382 },
    { lat: 48.8074574, lng: 18.6282006 },
    { lat: 48.8073139, lng: 18.6281298 },
    { lat: 48.8069643, lng: 18.6278991 },
    { lat: 48.8069541, lng: 18.6278589 },
    { lat: 48.8067227, lng: 18.627726 },
    { lat: 48.8065302, lng: 18.6275876 },
    { lat: 48.8062422, lng: 18.6273908 },
    { lat: 48.8058942, lng: 18.6271688 },
    { lat: 48.8052595, lng: 18.6268201 },
    { lat: 48.8051639, lng: 18.626781 },
    { lat: 48.8045561, lng: 18.6266558 },
    { lat: 48.8045338, lng: 18.6261777 },
    { lat: 48.8043086, lng: 18.6260991 },
    { lat: 48.8037116, lng: 18.625823 },
    { lat: 48.8034541, lng: 18.6261109 },
    { lat: 48.8033648, lng: 18.6262525 },
    { lat: 48.8032389, lng: 18.6264919 },
    { lat: 48.8030093, lng: 18.6267939 },
    { lat: 48.8026353, lng: 18.6271165 },
    { lat: 48.8023897, lng: 18.6269538 },
    { lat: 48.8009004, lng: 18.6256842 },
    { lat: 48.8006836, lng: 18.6256604 },
    { lat: 48.8005074, lng: 18.6256134 },
    { lat: 48.8003007, lng: 18.6255462 },
    { lat: 48.8001424, lng: 18.6254712 },
    { lat: 48.7997647, lng: 18.6252247 },
    { lat: 48.7989018, lng: 18.624591 },
    { lat: 48.7988491, lng: 18.6245993 },
    { lat: 48.798807, lng: 18.6245687 },
    { lat: 48.7987187, lng: 18.6247545 },
    { lat: 48.7986818, lng: 18.6249476 },
    { lat: 48.7985545, lng: 18.6252055 },
    { lat: 48.7984915, lng: 18.6254797 },
    { lat: 48.7983743, lng: 18.6257131 },
    { lat: 48.7982185, lng: 18.6260471 },
    { lat: 48.7981879, lng: 18.6261467 },
    { lat: 48.7983277, lng: 18.6261871 },
    { lat: 48.7985574, lng: 18.6263066 },
    { lat: 48.7986432, lng: 18.6263937 },
    { lat: 48.7987734, lng: 18.626592 },
    { lat: 48.7988659, lng: 18.6266944 },
    { lat: 48.7988982, lng: 18.6267502 },
    { lat: 48.7989408, lng: 18.6269757 },
    { lat: 48.7989269, lng: 18.6272013 },
    { lat: 48.7988783, lng: 18.6274098 },
    { lat: 48.7988377, lng: 18.6274953 },
    { lat: 48.7987301, lng: 18.6276531 },
    { lat: 48.7986117, lng: 18.6278018 },
    { lat: 48.7985313, lng: 18.6280031 },
    { lat: 48.7984703, lng: 18.6282975 },
    { lat: 48.798474, lng: 18.628473 },
    { lat: 48.7984864, lng: 18.6285756 },
    { lat: 48.7985525, lng: 18.6288601 },
    { lat: 48.7987244, lng: 18.6291223 },
    { lat: 48.7989375, lng: 18.6293945 },
    { lat: 48.7989849, lng: 18.6294402 },
    { lat: 48.799098, lng: 18.6294874 },
    { lat: 48.7991475, lng: 18.6294849 },
    { lat: 48.7992922, lng: 18.6294503 },
    { lat: 48.7995, lng: 18.6294112 },
    { lat: 48.799781, lng: 18.6293723 },
    { lat: 48.7998502, lng: 18.6293799 },
    { lat: 48.8000341, lng: 18.6292357 },
    { lat: 48.8001801, lng: 18.6290528 },
    { lat: 48.8004413, lng: 18.628499 },
    { lat: 48.8004958, lng: 18.6283603 },
    { lat: 48.8005426, lng: 18.6282862 },
    { lat: 48.8006326, lng: 18.6282372 },
    { lat: 48.8007013, lng: 18.6282356 },
    { lat: 48.8007398, lng: 18.628262 },
    { lat: 48.8008466, lng: 18.6284238 },
    { lat: 48.8009565, lng: 18.628851 },
    { lat: 48.8009632, lng: 18.6291032 },
    { lat: 48.8009301, lng: 18.6293673 },
    { lat: 48.8009218, lng: 18.6295075 },
    { lat: 48.8009191, lng: 18.6298574 },
    { lat: 48.8009385, lng: 18.6300922 },
    { lat: 48.80097, lng: 18.6303012 },
    { lat: 48.8010568, lng: 18.6307191 },
    { lat: 48.8010565, lng: 18.6308429 },
    { lat: 48.8010434, lng: 18.630954 },
    { lat: 48.8010334, lng: 18.6312934 },
    { lat: 48.8011061, lng: 18.6316175 },
    { lat: 48.8013385, lng: 18.6315167 },
    { lat: 48.8014336, lng: 18.6314399 },
    { lat: 48.801655, lng: 18.6312756 },
    { lat: 48.801812, lng: 18.6311976 },
    { lat: 48.8019563, lng: 18.6311509 },
    { lat: 48.8021831, lng: 18.6310923 },
    { lat: 48.8025932, lng: 18.6312526 },
    { lat: 48.8028188, lng: 18.6314399 },
    { lat: 48.8029132, lng: 18.6315599 },
    { lat: 48.8029921, lng: 18.6317495 },
    { lat: 48.8030397, lng: 18.6318996 },
    { lat: 48.8030773, lng: 18.6320993 },
    { lat: 48.8031499, lng: 18.632377 },
    { lat: 48.8032134, lng: 18.6325974 },
    { lat: 48.8033021, lng: 18.632818 },
    { lat: 48.8032421, lng: 18.6329592 },
    { lat: 48.8029961, lng: 18.6337583 },
    { lat: 48.8028734, lng: 18.634036 },
    { lat: 48.8029351, lng: 18.6342603 },
    { lat: 48.8027419, lng: 18.6350332 },
    { lat: 48.8023606, lng: 18.6346135 },
    { lat: 48.8019094, lng: 18.6344854 },
    { lat: 48.7953373, lng: 18.6328203 },
    { lat: 48.7954636, lng: 18.6324002 },
    { lat: 48.7944788, lng: 18.6315371 },
    { lat: 48.7936944, lng: 18.6310178 },
    { lat: 48.7934859, lng: 18.6319059 },
    { lat: 48.7934858, lng: 18.6319649 },
    { lat: 48.7934857, lng: 18.6320682 },
    { lat: 48.7934856, lng: 18.6321095 },
    { lat: 48.793454, lng: 18.6322364 },
    { lat: 48.7934226, lng: 18.6323648 },
    { lat: 48.7933577, lng: 18.6326258 },
    { lat: 48.7929276, lng: 18.6347003 },
    { lat: 48.7924991, lng: 18.6363857 },
    { lat: 48.7924777, lng: 18.6364686 },
    { lat: 48.7929676, lng: 18.6365408 },
    { lat: 48.793089, lng: 18.6365971 },
    { lat: 48.7940453, lng: 18.6370348 },
    { lat: 48.7946471, lng: 18.6373642 },
    { lat: 48.7952181, lng: 18.637999 },
    { lat: 48.7956677, lng: 18.6384564 },
    { lat: 48.7966015, lng: 18.6394655 },
    { lat: 48.7967015, lng: 18.6396273 },
    { lat: 48.796732, lng: 18.6396922 },
    { lat: 48.7967529, lng: 18.6399344 },
    { lat: 48.7964698, lng: 18.6412793 },
    { lat: 48.7964404, lng: 18.6412577 },
    { lat: 48.7963772, lng: 18.6414686 },
    { lat: 48.7962592, lng: 18.6418566 },
    { lat: 48.7961197, lng: 18.6422211 },
    { lat: 48.7956753, lng: 18.6432511 },
    { lat: 48.7953232, lng: 18.6440699 },
    { lat: 48.7949544, lng: 18.6449316 },
    { lat: 48.7947357, lng: 18.6454385 },
    { lat: 48.7944019, lng: 18.6462617 },
    { lat: 48.794443, lng: 18.6466514 },
    { lat: 48.794452, lng: 18.6467351 },
    { lat: 48.7944681, lng: 18.6468873 },
    { lat: 48.7943526, lng: 18.6471969 },
    { lat: 48.7942419, lng: 18.6474877 },
    { lat: 48.7941899, lng: 18.6485172 },
    { lat: 48.7942656, lng: 18.6500185 },
    { lat: 48.7944487, lng: 18.6504455 },
    { lat: 48.7947675, lng: 18.6509887 },
    { lat: 48.7950384, lng: 18.6520254 },
    { lat: 48.7956068, lng: 18.6525109 },
    { lat: 48.7956512, lng: 18.6532539 },
    { lat: 48.7961666, lng: 18.6536787 },
    { lat: 48.7960367, lng: 18.6549604 },
    { lat: 48.7960374, lng: 18.6552907 },
    { lat: 48.7959845, lng: 18.6555046 },
    { lat: 48.7960378, lng: 18.6556158 },
    { lat: 48.796057, lng: 18.6556574 },
    { lat: 48.7961673, lng: 18.655716 },
    { lat: 48.7962938, lng: 18.6556561 },
    { lat: 48.7964223, lng: 18.6557604 },
    { lat: 48.7964241, lng: 18.6557938 },
    { lat: 48.7963755, lng: 18.6558364 },
    { lat: 48.7963543, lng: 18.6558805 },
    { lat: 48.7964117, lng: 18.6560268 },
    { lat: 48.7964942, lng: 18.6560925 },
    { lat: 48.7964522, lng: 18.6562787 },
    { lat: 48.7964699, lng: 18.6564194 },
    { lat: 48.7965335, lng: 18.6564902 },
    { lat: 48.796498, lng: 18.6566063 },
    { lat: 48.7965351, lng: 18.656752 },
    { lat: 48.7965823, lng: 18.6567718 },
    { lat: 48.796645, lng: 18.6567416 },
    { lat: 48.7967016, lng: 18.6568717 },
    { lat: 48.7967312, lng: 18.6570042 },
    { lat: 48.7966242, lng: 18.6571759 },
    { lat: 48.7966661, lng: 18.6573053 },
    { lat: 48.7967741, lng: 18.6573228 },
    { lat: 48.7969148, lng: 18.6576751 },
    { lat: 48.7968865, lng: 18.6580517 },
    { lat: 48.7969021, lng: 18.6581342 },
    { lat: 48.796981, lng: 18.6581328 },
    { lat: 48.7970396, lng: 18.6582594 },
    { lat: 48.7970332, lng: 18.6583507 },
    { lat: 48.7969902, lng: 18.6585806 },
    { lat: 48.7972168, lng: 18.6587253 },
    { lat: 48.7973715, lng: 18.6589833 },
    { lat: 48.7974126, lng: 18.6593086 },
    { lat: 48.7976756, lng: 18.6597552 },
    { lat: 48.7979179, lng: 18.6599846 },
    { lat: 48.797944, lng: 18.6602209 },
    { lat: 48.7982448, lng: 18.6603843 },
    { lat: 48.7983428, lng: 18.660613 },
    { lat: 48.7983762, lng: 18.6607964 },
    { lat: 48.7984247, lng: 18.6609654 },
    { lat: 48.7984027, lng: 18.6613112 },
    { lat: 48.7986115, lng: 18.6614641 },
    { lat: 48.7987034, lng: 18.6618342 },
    { lat: 48.7986278, lng: 18.661981 },
    { lat: 48.7986812, lng: 18.6622627 },
    { lat: 48.7985544, lng: 18.6624851 },
    { lat: 48.798565, lng: 18.6627053 },
    { lat: 48.7984905, lng: 18.6629145 },
    { lat: 48.79857, lng: 18.6631148 },
    { lat: 48.7987244, lng: 18.663219 },
    { lat: 48.7988843, lng: 18.6636799 },
    { lat: 48.7988927, lng: 18.6637949 },
    { lat: 48.7990215, lng: 18.6642229 },
    { lat: 48.7990898, lng: 18.6646373 },
    { lat: 48.7990153, lng: 18.664887 },
    { lat: 48.7990276, lng: 18.6650954 },
    { lat: 48.7990044, lng: 18.6654458 },
    { lat: 48.7991416, lng: 18.6655212 },
    { lat: 48.7992816, lng: 18.6660095 },
    { lat: 48.7993362, lng: 18.666147 },
    { lat: 48.7992824, lng: 18.6664477 },
    { lat: 48.7992412, lng: 18.6664583 },
    { lat: 48.7991669, lng: 18.6664409 },
    { lat: 48.7991096, lng: 18.6665904 },
    { lat: 48.7990623, lng: 18.6669325 },
    { lat: 48.7989107, lng: 18.6673453 },
    { lat: 48.7989664, lng: 18.6675012 },
    { lat: 48.7989487, lng: 18.6676783 },
    { lat: 48.8011189, lng: 18.6689727 },
    { lat: 48.8020986, lng: 18.6693681 },
    { lat: 48.8039606, lng: 18.6712751 },
    { lat: 48.8049868, lng: 18.6724549 },
    { lat: 48.8057986, lng: 18.6733637 },
    { lat: 48.80708, lng: 18.6744477 },
    { lat: 48.8082211, lng: 18.6754776 },
    { lat: 48.8092635, lng: 18.676734 },
    { lat: 48.8101189, lng: 18.6779514 },
    { lat: 48.8122772, lng: 18.6816982 },
    { lat: 48.8131327, lng: 18.6822412 },
    { lat: 48.8141336, lng: 18.6830148 },
    { lat: 48.8165474, lng: 18.6844851 },
    { lat: 48.8171317, lng: 18.6853128 },
    { lat: 48.8171911, lng: 18.6854748 },
    { lat: 48.8172085, lng: 18.6855886 },
    { lat: 48.8171623, lng: 18.6859954 },
    { lat: 48.8173532, lng: 18.6869116 },
    { lat: 48.8174635, lng: 18.6874614 },
    { lat: 48.8176309, lng: 18.6880003 },
    { lat: 48.8179219, lng: 18.688278 },
    { lat: 48.8180648, lng: 18.6886546 },
    { lat: 48.8183438, lng: 18.6890211 },
    { lat: 48.8184749, lng: 18.6895777 },
    { lat: 48.8186755, lng: 18.6899802 },
    { lat: 48.8189091, lng: 18.6901744 },
    { lat: 48.8190949, lng: 18.6905314 },
    { lat: 48.8191506, lng: 18.6908539 },
    { lat: 48.8193217, lng: 18.6913405 },
    { lat: 48.8195232, lng: 18.6923925 },
    { lat: 48.8195982, lng: 18.6926385 },
    { lat: 48.8199175, lng: 18.6934274 },
    { lat: 48.8199779, lng: 18.6937146 },
    { lat: 48.8202068, lng: 18.6941176 },
    { lat: 48.8202283, lng: 18.6944167 },
    { lat: 48.8202154, lng: 18.6947031 },
    { lat: 48.8202723, lng: 18.6948186 },
    { lat: 48.8204518, lng: 18.6954617 },
    { lat: 48.8205339, lng: 18.6956063 },
    { lat: 48.8206232, lng: 18.695886 },
    { lat: 48.8206361, lng: 18.6960233 },
    { lat: 48.8207512, lng: 18.6963432 },
    { lat: 48.8207494, lng: 18.6964344 },
    { lat: 48.8208803, lng: 18.6968207 },
    { lat: 48.8209804, lng: 18.6972201 },
    { lat: 48.8212885, lng: 18.6979667 },
    { lat: 48.8213232, lng: 18.6981762 },
    { lat: 48.8213344, lng: 18.6984268 },
    { lat: 48.8212968, lng: 18.6995061 },
    { lat: 48.8213139, lng: 18.7001493 },
    { lat: 48.8213722, lng: 18.7007574 },
    { lat: 48.8215617, lng: 18.7012923 },
    { lat: 48.8218409, lng: 18.7026634 },
    { lat: 48.8220617, lng: 18.7033817 },
    { lat: 48.8223374, lng: 18.7040318 },
    { lat: 48.8225864, lng: 18.7043859 },
    { lat: 48.8230253, lng: 18.705199 },
    { lat: 48.8233402, lng: 18.7058654 },
    { lat: 48.8234282, lng: 18.7063132 },
    { lat: 48.8233227, lng: 18.7066417 },
    { lat: 48.823047, lng: 18.707206 },
    { lat: 48.8228724, lng: 18.7076792 },
    { lat: 48.8228016, lng: 18.7081548 },
    { lat: 48.8227958, lng: 18.7081821 },
    { lat: 48.8226754, lng: 18.7087521 },
    { lat: 48.8227093, lng: 18.7090715 },
    { lat: 48.8227187, lng: 18.7090906 },
    { lat: 48.8228205, lng: 18.7092975 },
    { lat: 48.8227344, lng: 18.709674 },
    { lat: 48.8225118, lng: 18.7099834 },
    { lat: 48.8225105, lng: 18.7100248 },
    { lat: 48.8223976, lng: 18.7121744 },
    { lat: 48.8232287, lng: 18.7155826 },
    { lat: 48.8240223, lng: 18.7165383 },
    { lat: 48.8240089, lng: 18.7173079 },
    { lat: 48.8240293, lng: 18.7182883 },
    { lat: 48.8242839, lng: 18.7185637 },
    { lat: 48.8247309, lng: 18.7192547 },
    { lat: 48.8251362, lng: 18.7200509 },
    { lat: 48.8256831, lng: 18.7206461 },
    { lat: 48.8257096, lng: 18.7202302 },
    { lat: 48.8267094, lng: 18.7206895 },
    { lat: 48.8267293, lng: 18.7207891 },
    { lat: 48.8265854, lng: 18.7209286 },
    { lat: 48.8262513, lng: 18.7210056 },
    { lat: 48.8259862, lng: 18.7215392 },
    { lat: 48.8263289, lng: 18.7217049 },
    { lat: 48.8264798, lng: 18.7217424 },
    { lat: 48.826714, lng: 18.7219316 },
    { lat: 48.8269215, lng: 18.7222089 },
    { lat: 48.8269454, lng: 18.7224058 },
    { lat: 48.8271167, lng: 18.7228091 },
    { lat: 48.8271034, lng: 18.7237109 },
    { lat: 48.8273166, lng: 18.7241142 },
    { lat: 48.8272436, lng: 18.7246109 },
    { lat: 48.8275472, lng: 18.7252138 },
    { lat: 48.8275173, lng: 18.7254808 },
    { lat: 48.8272498, lng: 18.7254126 },
    { lat: 48.8272387, lng: 18.7255035 },
    { lat: 48.8275916, lng: 18.7260144 },
    { lat: 48.8277476, lng: 18.7261892 },
    { lat: 48.8279155, lng: 18.7261252 },
    { lat: 48.8280818, lng: 18.7261158 },
    { lat: 48.828307, lng: 18.7264915 },
    { lat: 48.8283531, lng: 18.7267859 },
    { lat: 48.8284158, lng: 18.7268642 },
    { lat: 48.8288238, lng: 18.7273495 },
    { lat: 48.8290324, lng: 18.7270941 },
    { lat: 48.8291153, lng: 18.7269989 },
    { lat: 48.8295268, lng: 18.7267633 },
    { lat: 48.8298562, lng: 18.7268702 },
    { lat: 48.8299944, lng: 18.7275632 },
    { lat: 48.8300215, lng: 18.7277575 },
    { lat: 48.8301679, lng: 18.7281181 },
    { lat: 48.8307405, lng: 18.7281956 },
    { lat: 48.8314073, lng: 18.7283325 },
    { lat: 48.8313848, lng: 18.7286087 },
    { lat: 48.8318182, lng: 18.7288282 },
    { lat: 48.832006, lng: 18.729141 },
  ],
  Handlová: [
    { lat: 48.637261, lng: 18.716982 },
    { lat: 48.637263, lng: 18.716979 },
    { lat: 48.637735, lng: 18.71656 },
    { lat: 48.637833, lng: 18.716474 },
    { lat: 48.637858, lng: 18.716463 },
    { lat: 48.638153, lng: 18.716333 },
    { lat: 48.638357, lng: 18.716355 },
    { lat: 48.638473, lng: 18.716367 },
    { lat: 48.63851, lng: 18.716371 },
    { lat: 48.6386, lng: 18.716423 },
    { lat: 48.638685, lng: 18.716478 },
    { lat: 48.639165, lng: 18.71689 },
    { lat: 48.639303, lng: 18.716891 },
    { lat: 48.639615, lng: 18.716893 },
    { lat: 48.639796, lng: 18.716929 },
    { lat: 48.639996, lng: 18.716969 },
    { lat: 48.640422, lng: 18.716976 },
    { lat: 48.640576, lng: 18.716978 },
    { lat: 48.641002, lng: 18.71687 },
    { lat: 48.641608, lng: 18.716563 },
    { lat: 48.641673, lng: 18.71654 },
    { lat: 48.641893, lng: 18.716461 },
    { lat: 48.642428, lng: 18.716453 },
    { lat: 48.642717, lng: 18.716367 },
    { lat: 48.6428, lng: 18.716373 },
    { lat: 48.64324, lng: 18.716408 },
    { lat: 48.643474, lng: 18.71626 },
    { lat: 48.643779, lng: 18.716215 },
    { lat: 48.643972, lng: 18.716077 },
    { lat: 48.644068, lng: 18.716007 },
    { lat: 48.644306, lng: 18.715927 },
    { lat: 48.64442, lng: 18.715888 },
    { lat: 48.644589, lng: 18.715767 },
    { lat: 48.644732, lng: 18.715595 },
    { lat: 48.644949, lng: 18.715333 },
    { lat: 48.645561, lng: 18.714807 },
    { lat: 48.645712, lng: 18.714498 },
    { lat: 48.645959, lng: 18.714323 },
    { lat: 48.646034, lng: 18.714364 },
    { lat: 48.646179, lng: 18.714445 },
    { lat: 48.646418, lng: 18.714265 },
    { lat: 48.646447, lng: 18.714139 },
    { lat: 48.646473, lng: 18.714019 },
    { lat: 48.64657, lng: 18.713912 },
    { lat: 48.647008, lng: 18.713852 },
    { lat: 48.647101, lng: 18.713884 },
    { lat: 48.647224, lng: 18.713979 },
    { lat: 48.647279, lng: 18.714021 },
    { lat: 48.647314, lng: 18.714029 },
    { lat: 48.647368, lng: 18.714042 },
    { lat: 48.647656, lng: 18.713894 },
    { lat: 48.647793, lng: 18.713885 },
    { lat: 48.648033, lng: 18.714067 },
    { lat: 48.648277, lng: 18.714173 },
    { lat: 48.648438, lng: 18.71436 },
    { lat: 48.648559, lng: 18.714491 },
    { lat: 48.648581, lng: 18.714552 },
    { lat: 48.648616, lng: 18.714645 },
    { lat: 48.648664, lng: 18.714771 },
    { lat: 48.648834, lng: 18.715012 },
    { lat: 48.64895, lng: 18.715314 },
    { lat: 48.649021, lng: 18.715963 },
    { lat: 48.649116, lng: 18.716831 },
    { lat: 48.649172, lng: 18.717259 },
    { lat: 48.649214, lng: 18.717575 },
    { lat: 48.649285, lng: 18.717781 },
    { lat: 48.649361, lng: 18.718005 },
    { lat: 48.649487, lng: 18.718235 },
    { lat: 48.649719, lng: 18.718659 },
    { lat: 48.649825, lng: 18.718859 },
    { lat: 48.650019, lng: 18.71927 },
    { lat: 48.65003, lng: 18.719293 },
    { lat: 48.650052, lng: 18.719333 },
    { lat: 48.650243, lng: 18.7197 },
    { lat: 48.650748, lng: 18.720447 },
    { lat: 48.651427, lng: 18.72117 },
    { lat: 48.651964, lng: 18.721851 },
    { lat: 48.652467, lng: 18.722749 },
    { lat: 48.652497, lng: 18.722802 },
    { lat: 48.652779, lng: 18.723208 },
    { lat: 48.652979, lng: 18.7234 },
    { lat: 48.653379, lng: 18.7237 },
    { lat: 48.653418, lng: 18.723728 },
    { lat: 48.653404, lng: 18.723795 },
    { lat: 48.653343, lng: 18.724082 },
    { lat: 48.653646, lng: 18.724853 },
    { lat: 48.653664, lng: 18.72494 },
    { lat: 48.653787, lng: 18.725541 },
    { lat: 48.653829, lng: 18.725746 },
    { lat: 48.653861, lng: 18.726511 },
    { lat: 48.653854, lng: 18.726738 },
    { lat: 48.653847, lng: 18.726977 },
    { lat: 48.653899, lng: 18.727152 },
    { lat: 48.654131, lng: 18.727929 },
    { lat: 48.653953, lng: 18.728592 },
    { lat: 48.654343, lng: 18.729312 },
    { lat: 48.654343, lng: 18.729311 },
    { lat: 48.654431, lng: 18.729165 },
    { lat: 48.654788, lng: 18.729095 },
    { lat: 48.654851, lng: 18.729223 },
    { lat: 48.654856, lng: 18.729232 },
    { lat: 48.654862, lng: 18.729679 },
    { lat: 48.655215, lng: 18.73086 },
    { lat: 48.655277, lng: 18.731295 },
    { lat: 48.655338, lng: 18.731735 },
    { lat: 48.655364, lng: 18.73181 },
    { lat: 48.655565, lng: 18.732402 },
    { lat: 48.655569, lng: 18.732425 },
    { lat: 48.655608, lng: 18.73266 },
    { lat: 48.655642, lng: 18.732861 },
    { lat: 48.65573, lng: 18.733068 },
    { lat: 48.655858, lng: 18.733882 },
    { lat: 48.655904, lng: 18.734183 },
    { lat: 48.65592, lng: 18.734286 },
    { lat: 48.655956, lng: 18.734264 },
    { lat: 48.656018, lng: 18.734488 },
    { lat: 48.656149, lng: 18.734837 },
    { lat: 48.656324, lng: 18.735037 },
    { lat: 48.656593, lng: 18.735201 },
    { lat: 48.656624, lng: 18.735151 },
    { lat: 48.656651, lng: 18.735084 },
    { lat: 48.656785, lng: 18.734954 },
    { lat: 48.656814, lng: 18.734926 },
    { lat: 48.656933, lng: 18.734811 },
    { lat: 48.656992, lng: 18.734755 },
    { lat: 48.65706, lng: 18.734712 },
    { lat: 48.657102, lng: 18.734686 },
    { lat: 48.65723, lng: 18.734603 },
    { lat: 48.657459, lng: 18.734861 },
    { lat: 48.657709, lng: 18.735141 },
    { lat: 48.657764, lng: 18.735204 },
    { lat: 48.65779, lng: 18.735233 },
    { lat: 48.657826, lng: 18.735286 },
    { lat: 48.657847, lng: 18.735317 },
    { lat: 48.657871, lng: 18.735352 },
    { lat: 48.657907, lng: 18.735406 },
    { lat: 48.658258, lng: 18.735925 },
    { lat: 48.658746, lng: 18.736976 },
    { lat: 48.66016, lng: 18.739199 },
    { lat: 48.661078, lng: 18.740125 },
    { lat: 48.662036, lng: 18.741352 },
    { lat: 48.663267, lng: 18.741851 },
    { lat: 48.663477, lng: 18.741943 },
    { lat: 48.663646, lng: 18.742017 },
    { lat: 48.66407, lng: 18.742203 },
    { lat: 48.664491, lng: 18.742032 },
    { lat: 48.664557, lng: 18.741926 },
    { lat: 48.664698, lng: 18.741698 },
    { lat: 48.664889, lng: 18.741544 },
    { lat: 48.665099, lng: 18.741377 },
    { lat: 48.665568, lng: 18.741875 },
    { lat: 48.665612, lng: 18.741946 },
    { lat: 48.665984, lng: 18.742541 },
    { lat: 48.666064, lng: 18.74267 },
    { lat: 48.666594, lng: 18.743463 },
    { lat: 48.666722, lng: 18.743566 },
    { lat: 48.666941, lng: 18.743881 },
    { lat: 48.667007, lng: 18.744102 },
    { lat: 48.667006, lng: 18.744462 },
    { lat: 48.667092, lng: 18.744662 },
    { lat: 48.667223, lng: 18.745201 },
    { lat: 48.667554, lng: 18.74564 },
    { lat: 48.668169, lng: 18.746444 },
    { lat: 48.668572, lng: 18.746768 },
    { lat: 48.668703, lng: 18.74692 },
    { lat: 48.669014, lng: 18.746942 },
    { lat: 48.669196, lng: 18.746866 },
    { lat: 48.669572, lng: 18.746932 },
    { lat: 48.66983, lng: 18.746817 },
    { lat: 48.67033, lng: 18.746691 },
    { lat: 48.670449, lng: 18.746837 },
    { lat: 48.671484, lng: 18.746926 },
    { lat: 48.671475, lng: 18.746805 },
    { lat: 48.671629, lng: 18.746821 },
    { lat: 48.672205, lng: 18.747203 },
    { lat: 48.672422, lng: 18.74744 },
    { lat: 48.672704, lng: 18.747546 },
    { lat: 48.673158, lng: 18.747585 },
    { lat: 48.673435, lng: 18.747492 },
    { lat: 48.673736, lng: 18.747411 },
    { lat: 48.674091, lng: 18.74724 },
    { lat: 48.674464, lng: 18.747363 },
    { lat: 48.674607, lng: 18.747444 },
    { lat: 48.67504, lng: 18.747586 },
    { lat: 48.675375, lng: 18.747947 },
    { lat: 48.676263, lng: 18.7487 },
    { lat: 48.676324, lng: 18.748707 },
    { lat: 48.676516, lng: 18.74879 },
    { lat: 48.67689, lng: 18.748723 },
    { lat: 48.677094, lng: 18.748708 },
    { lat: 48.67736, lng: 18.748807 },
    { lat: 48.677755, lng: 18.748773 },
    { lat: 48.678518, lng: 18.748786 },
    { lat: 48.6788, lng: 18.748766 },
    { lat: 48.679004, lng: 18.74886 },
    { lat: 48.679345, lng: 18.748905 },
    { lat: 48.679761, lng: 18.748848 },
    { lat: 48.680099, lng: 18.748987 },
    { lat: 48.680338, lng: 18.749188 },
    { lat: 48.680609, lng: 18.749546 },
    { lat: 48.68107, lng: 18.750336 },
    { lat: 48.681322, lng: 18.750688 },
    { lat: 48.681558, lng: 18.750955 },
    { lat: 48.681671, lng: 18.751112 },
    { lat: 48.682471, lng: 18.751614 },
    { lat: 48.68302, lng: 18.751615 },
    { lat: 48.683305, lng: 18.751711 },
    { lat: 48.683518, lng: 18.751733 },
    { lat: 48.683874, lng: 18.751693 },
    { lat: 48.684025, lng: 18.751668 },
    { lat: 48.68438, lng: 18.751393 },
    { lat: 48.684967, lng: 18.750759 },
    { lat: 48.685149, lng: 18.750466 },
    { lat: 48.685252, lng: 18.750098 },
    { lat: 48.685282, lng: 18.750021 },
    { lat: 48.686096, lng: 18.749991 },
    { lat: 48.686588, lng: 18.750026 },
    { lat: 48.686867, lng: 18.750117 },
    { lat: 48.687187, lng: 18.750456 },
    { lat: 48.687275, lng: 18.750632 },
    { lat: 48.687215, lng: 18.751136 },
    { lat: 48.687716, lng: 18.751768 },
    { lat: 48.688163, lng: 18.752153 },
    { lat: 48.688782, lng: 18.752509 },
    { lat: 48.689268, lng: 18.75301 },
    { lat: 48.689403, lng: 18.753174 },
    { lat: 48.690148, lng: 18.752972 },
    { lat: 48.690532, lng: 18.753435 },
    { lat: 48.691187, lng: 18.753958 },
    { lat: 48.691349, lng: 18.754157 },
    { lat: 48.691781, lng: 18.754474 },
    { lat: 48.691853, lng: 18.75515 },
    { lat: 48.692042, lng: 18.755702 },
    { lat: 48.692138, lng: 18.755888 },
    { lat: 48.692562, lng: 18.756457 },
    { lat: 48.692644, lng: 18.757036 },
    { lat: 48.69254, lng: 18.757392 },
    { lat: 48.692451, lng: 18.757715 },
    { lat: 48.692413, lng: 18.758111 },
    { lat: 48.692584, lng: 18.758698 },
    { lat: 48.692536, lng: 18.758742 },
    { lat: 48.692651, lng: 18.759107 },
    { lat: 48.692953, lng: 18.759639 },
    { lat: 48.69339, lng: 18.760185 },
    { lat: 48.694164, lng: 18.760672 },
    { lat: 48.694864, lng: 18.761297 },
    { lat: 48.695536, lng: 18.761947 },
    { lat: 48.696341, lng: 18.761985 },
    { lat: 48.696846, lng: 18.762396 },
    { lat: 48.69718, lng: 18.762776 },
    { lat: 48.697279, lng: 18.763367 },
    { lat: 48.697253, lng: 18.764199 },
    { lat: 48.697243, lng: 18.766321 },
    { lat: 48.697104, lng: 18.767017 },
    { lat: 48.697002, lng: 18.767674 },
    { lat: 48.696959, lng: 18.768532 },
    { lat: 48.697029, lng: 18.769825 },
    { lat: 48.696874, lng: 18.770559 },
    { lat: 48.696783, lng: 18.771321 },
    { lat: 48.696552, lng: 18.771574 },
    { lat: 48.696164, lng: 18.771686 },
    { lat: 48.696025, lng: 18.771926 },
    { lat: 48.696, lng: 18.772923 },
    { lat: 48.695995, lng: 18.773657 },
    { lat: 48.696048, lng: 18.775142 },
    { lat: 48.695254, lng: 18.776763 },
    { lat: 48.695127, lng: 18.777009 },
    { lat: 48.694902, lng: 18.776992 },
    { lat: 48.694652, lng: 18.777508 },
    { lat: 48.694367, lng: 18.777807 },
    { lat: 48.694123, lng: 18.778275 },
    { lat: 48.693992, lng: 18.778875 },
    { lat: 48.693868, lng: 18.779728 },
    { lat: 48.693826, lng: 18.780564 },
    { lat: 48.693834, lng: 18.780573 },
    { lat: 48.694039, lng: 18.781199 },
    { lat: 48.694247, lng: 18.78168 },
    { lat: 48.694556, lng: 18.782043 },
    { lat: 48.694651, lng: 18.782287 },
    { lat: 48.694852, lng: 18.783178 },
    { lat: 48.69532, lng: 18.783769 },
    { lat: 48.695346, lng: 18.783979 },
    { lat: 48.695493, lng: 18.784354 },
    { lat: 48.695744, lng: 18.784848 },
    { lat: 48.695996, lng: 18.785184 },
    { lat: 48.696089, lng: 18.785345 },
    { lat: 48.696245, lng: 18.785842 },
    { lat: 48.696263, lng: 18.786054 },
    { lat: 48.696423, lng: 18.786463 },
    { lat: 48.696482, lng: 18.78672 },
    { lat: 48.696989, lng: 18.78767 },
    { lat: 48.697414, lng: 18.788485 },
    { lat: 48.697734, lng: 18.788956 },
    { lat: 48.697826, lng: 18.789335 },
    { lat: 48.698285, lng: 18.790622 },
    { lat: 48.698317, lng: 18.791535 },
    { lat: 48.69839, lng: 18.7917 },
    { lat: 48.698377, lng: 18.79171 },
    { lat: 48.698412, lng: 18.791846 },
    { lat: 48.698963, lng: 18.79247 },
    { lat: 48.699224, lng: 18.792788 },
    { lat: 48.699676, lng: 18.793286 },
    { lat: 48.699921, lng: 18.793731 },
    { lat: 48.70018, lng: 18.794163 },
    { lat: 48.700331, lng: 18.794494 },
    { lat: 48.700406, lng: 18.795028 },
    { lat: 48.700476, lng: 18.795278 },
    { lat: 48.700615, lng: 18.796142 },
    { lat: 48.700687, lng: 18.796202 },
    { lat: 48.701168, lng: 18.796443 },
    { lat: 48.701287, lng: 18.796546 },
    { lat: 48.701599, lng: 18.796604 },
    { lat: 48.701808, lng: 18.796666 },
    { lat: 48.701956, lng: 18.796715 },
    { lat: 48.702253, lng: 18.796863 },
    { lat: 48.702986, lng: 18.797317 },
    { lat: 48.703251, lng: 18.797497 },
    { lat: 48.70354, lng: 18.797782 },
    { lat: 48.703998, lng: 18.798058 },
    { lat: 48.704089, lng: 18.79814 },
    { lat: 48.704329, lng: 18.798649 },
    { lat: 48.704566, lng: 18.798941 },
    { lat: 48.70501, lng: 18.799325 },
    { lat: 48.705269, lng: 18.799492 },
    { lat: 48.705542, lng: 18.799475 },
    { lat: 48.705953, lng: 18.799736 },
    { lat: 48.706623, lng: 18.800212 },
    { lat: 48.706808, lng: 18.800264 },
    { lat: 48.707152, lng: 18.800339 },
    { lat: 48.707556, lng: 18.800401 },
    { lat: 48.707919, lng: 18.800633 },
    { lat: 48.708391, lng: 18.800909 },
    { lat: 48.708587, lng: 18.801226 },
    { lat: 48.708776, lng: 18.801456 },
    { lat: 48.709207, lng: 18.801753 },
    { lat: 48.709218, lng: 18.801737 },
    { lat: 48.709802, lng: 18.802503 },
    { lat: 48.710024, lng: 18.803061 },
    { lat: 48.71042, lng: 18.804351 },
    { lat: 48.710491, lng: 18.804836 },
    { lat: 48.710596, lng: 18.805176 },
    { lat: 48.710709, lng: 18.805391 },
    { lat: 48.711241, lng: 18.805629 },
    { lat: 48.711502, lng: 18.806055 },
    { lat: 48.711662, lng: 18.806459 },
    { lat: 48.711806, lng: 18.806625 },
    { lat: 48.71199, lng: 18.806719 },
    { lat: 48.712116, lng: 18.806912 },
    { lat: 48.712378, lng: 18.807027 },
    { lat: 48.712562, lng: 18.807038 },
    { lat: 48.713106, lng: 18.807052 },
    { lat: 48.713609, lng: 18.807022 },
    { lat: 48.713803, lng: 18.806958 },
    { lat: 48.714122, lng: 18.807118 },
    { lat: 48.714851, lng: 18.807486 },
    { lat: 48.715116, lng: 18.807616 },
    { lat: 48.715436, lng: 18.807703 },
    { lat: 48.715787, lng: 18.807995 },
    { lat: 48.716078, lng: 18.808212 },
    { lat: 48.716493, lng: 18.808845 },
    { lat: 48.71669, lng: 18.809318 },
    { lat: 48.717156, lng: 18.810149 },
    { lat: 48.717555, lng: 18.810708 },
    { lat: 48.717727, lng: 18.810824 },
    { lat: 48.717884, lng: 18.810805 },
    { lat: 48.717974, lng: 18.810706 },
    { lat: 48.718241, lng: 18.810773 },
    { lat: 48.718653, lng: 18.811132 },
    { lat: 48.719208, lng: 18.811222 },
    { lat: 48.719681, lng: 18.811456 },
    { lat: 48.719875, lng: 18.811503 },
    { lat: 48.72046, lng: 18.811614 },
    { lat: 48.721018, lng: 18.811694 },
    { lat: 48.721545, lng: 18.811879 },
    { lat: 48.722021, lng: 18.811922 },
    { lat: 48.722761, lng: 18.812518 },
    { lat: 48.723228, lng: 18.813196 },
    { lat: 48.723479, lng: 18.813809 },
    { lat: 48.723785, lng: 18.814123 },
    { lat: 48.724349, lng: 18.814765 },
    { lat: 48.726284, lng: 18.816784 },
    { lat: 48.727019, lng: 18.817345 },
    { lat: 48.727233, lng: 18.817365 },
    { lat: 48.72792, lng: 18.817252 },
    { lat: 48.728026, lng: 18.817235 },
    { lat: 48.728076, lng: 18.817226 },
    { lat: 48.728329, lng: 18.817145 },
    { lat: 48.729293, lng: 18.817564 },
    { lat: 48.729707, lng: 18.817561 },
    { lat: 48.730208, lng: 18.818079 },
    { lat: 48.731046, lng: 18.819135 },
    { lat: 48.731609, lng: 18.820036 },
    { lat: 48.732354, lng: 18.820012 },
    { lat: 48.732646, lng: 18.820234 },
    { lat: 48.733148, lng: 18.820721 },
    { lat: 48.733446, lng: 18.821065 },
    { lat: 48.73438, lng: 18.821645 },
    { lat: 48.734468, lng: 18.822454 },
    { lat: 48.73457, lng: 18.822685 },
    { lat: 48.734736, lng: 18.823176 },
    { lat: 48.734978, lng: 18.82382 },
    { lat: 48.735653, lng: 18.8230811 },
    { lat: 48.7363004, lng: 18.8223904 },
    { lat: 48.7371558, lng: 18.8217321 },
    { lat: 48.7375634, lng: 18.8213049 },
    { lat: 48.7376629, lng: 18.8213316 },
    { lat: 48.7378148, lng: 18.8213046 },
    { lat: 48.7382506, lng: 18.82136 },
    { lat: 48.7385664, lng: 18.8212709 },
    { lat: 48.7389435, lng: 18.8206991 },
    { lat: 48.7390305, lng: 18.820528 },
    { lat: 48.739107, lng: 18.8203088 },
    { lat: 48.7391895, lng: 18.8202233 },
    { lat: 48.7396475, lng: 18.81958 },
    { lat: 48.7397308, lng: 18.819295 },
    { lat: 48.7397685, lng: 18.8190844 },
    { lat: 48.7397656, lng: 18.8189886 },
    { lat: 48.7400122, lng: 18.8185066 },
    { lat: 48.740066, lng: 18.8182601 },
    { lat: 48.7401549, lng: 18.8181695 },
    { lat: 48.7402092, lng: 18.8180637 },
    { lat: 48.7402452, lng: 18.8177158 },
    { lat: 48.7402244, lng: 18.8176625 },
    { lat: 48.7401849, lng: 18.8174489 },
    { lat: 48.7402718, lng: 18.8171649 },
    { lat: 48.7404024, lng: 18.8169014 },
    { lat: 48.7407325, lng: 18.8166788 },
    { lat: 48.7407597, lng: 18.816597 },
    { lat: 48.7408245, lng: 18.8160462 },
    { lat: 48.7409614, lng: 18.8157484 },
    { lat: 48.7410509, lng: 18.8154976 },
    { lat: 48.7412159, lng: 18.8150089 },
    { lat: 48.7413951, lng: 18.8148564 },
    { lat: 48.7414988, lng: 18.8145293 },
    { lat: 48.7415916, lng: 18.8143596 },
    { lat: 48.7416781, lng: 18.814116 },
    { lat: 48.7417206, lng: 18.8138066 },
    { lat: 48.7419656, lng: 18.8133794 },
    { lat: 48.7420403, lng: 18.813344 },
    { lat: 48.7422404, lng: 18.8128982 },
    { lat: 48.7422823, lng: 18.812188 },
    { lat: 48.7422262, lng: 18.8120003 },
    { lat: 48.7422667, lng: 18.8118436 },
    { lat: 48.7421345, lng: 18.8116482 },
    { lat: 48.7421275, lng: 18.8115351 },
    { lat: 48.7419533, lng: 18.8112625 },
    { lat: 48.7419635, lng: 18.8111773 },
    { lat: 48.7421959, lng: 18.8105548 },
    { lat: 48.7422551, lng: 18.8102394 },
    { lat: 48.7423161, lng: 18.810027 },
    { lat: 48.7423228, lng: 18.8097443 },
    { lat: 48.7423554, lng: 18.8091763 },
    { lat: 48.7425414, lng: 18.8087243 },
    { lat: 48.7426345, lng: 18.808452 },
    { lat: 48.7428192, lng: 18.8082342 },
    { lat: 48.7428145, lng: 18.8078628 },
    { lat: 48.742848, lng: 18.807771 },
    { lat: 48.7428748, lng: 18.8077397 },
    { lat: 48.7431542, lng: 18.8074132 },
    { lat: 48.743228, lng: 18.8072051 },
    { lat: 48.743227, lng: 18.8070155 },
    { lat: 48.7432765, lng: 18.8067766 },
    { lat: 48.7434098, lng: 18.8065642 },
    { lat: 48.7435312, lng: 18.806405 },
    { lat: 48.7436383, lng: 18.8060567 },
    { lat: 48.7438814, lng: 18.8061603 },
    { lat: 48.7439379, lng: 18.8060121 },
    { lat: 48.7443179, lng: 18.8059903 },
    { lat: 48.7444724, lng: 18.8061377 },
    { lat: 48.7446558, lng: 18.8061294 },
    { lat: 48.7448433, lng: 18.8061584 },
    { lat: 48.745082, lng: 18.8059377 },
    { lat: 48.745337, lng: 18.805743 },
    { lat: 48.7453885, lng: 18.805779 },
    { lat: 48.7455645, lng: 18.8057608 },
    { lat: 48.7457752, lng: 18.8057206 },
    { lat: 48.7458309, lng: 18.805712 },
    { lat: 48.745877, lng: 18.8057118 },
    { lat: 48.7459603, lng: 18.8056946 },
    { lat: 48.7460335, lng: 18.8056593 },
    { lat: 48.7461268, lng: 18.8055788 },
    { lat: 48.7462153, lng: 18.8054883 },
    { lat: 48.7463109, lng: 18.805285 },
    { lat: 48.7463575, lng: 18.8052039 },
    { lat: 48.7464634, lng: 18.8051987 },
    { lat: 48.7465416, lng: 18.8050883 },
    { lat: 48.7466093, lng: 18.8049826 },
    { lat: 48.746632, lng: 18.8048883 },
    { lat: 48.7466117, lng: 18.8048118 },
    { lat: 48.7465728, lng: 18.8048065 },
    { lat: 48.746571, lng: 18.804772 },
    { lat: 48.7466454, lng: 18.8043037 },
    { lat: 48.7467197, lng: 18.8042341 },
    { lat: 48.746814, lng: 18.8039895 },
    { lat: 48.7469102, lng: 18.8038458 },
    { lat: 48.7471162, lng: 18.8037712 },
    { lat: 48.7471404, lng: 18.8036958 },
    { lat: 48.747156, lng: 18.803005 },
    { lat: 48.7472007, lng: 18.80291 },
    { lat: 48.7472243, lng: 18.8026067 },
    { lat: 48.7472109, lng: 18.8023278 },
    { lat: 48.74715, lng: 18.8020438 },
    { lat: 48.7470966, lng: 18.8019705 },
    { lat: 48.7470584, lng: 18.8018683 },
    { lat: 48.746981, lng: 18.8017435 },
    { lat: 48.7468438, lng: 18.8016859 },
    { lat: 48.7467538, lng: 18.8015602 },
    { lat: 48.7466927, lng: 18.8014081 },
    { lat: 48.7466323, lng: 18.8013117 },
    { lat: 48.7466041, lng: 18.8011546 },
    { lat: 48.7466182, lng: 18.8010411 },
    { lat: 48.7465912, lng: 18.8010179 },
    { lat: 48.7465588, lng: 18.800872 },
    { lat: 48.7466789, lng: 18.8006701 },
    { lat: 48.7467146, lng: 18.8005286 },
    { lat: 48.7466427, lng: 18.8003661 },
    { lat: 48.7465889, lng: 18.799983 },
    { lat: 48.746602, lng: 18.7996713 },
    { lat: 48.7465345, lng: 18.7995882 },
    { lat: 48.7465284, lng: 18.7995625 },
    { lat: 48.7464856, lng: 18.7993836 },
    { lat: 48.7463905, lng: 18.799057 },
    { lat: 48.7463273, lng: 18.7989873 },
    { lat: 48.7463323, lng: 18.7989339 },
    { lat: 48.7462956, lng: 18.7988557 },
    { lat: 48.7461771, lng: 18.7987484 },
    { lat: 48.7461623, lng: 18.7986379 },
    { lat: 48.7462017, lng: 18.7984832 },
    { lat: 48.7462022, lng: 18.7982969 },
    { lat: 48.7461489, lng: 18.7980546 },
    { lat: 48.7460744, lng: 18.797946 },
    { lat: 48.7461296, lng: 18.7977092 },
    { lat: 48.7461424, lng: 18.7973704 },
    { lat: 48.7460788, lng: 18.7972122 },
    { lat: 48.7461152, lng: 18.7970671 },
    { lat: 48.7460798, lng: 18.7969734 },
    { lat: 48.7461058, lng: 18.7968009 },
    { lat: 48.7461076, lng: 18.796681 },
    { lat: 48.746004, lng: 18.7965344 },
    { lat: 48.7459508, lng: 18.7963575 },
    { lat: 48.7459995, lng: 18.7962995 },
    { lat: 48.7460633, lng: 18.7963312 },
    { lat: 48.7461566, lng: 18.7962635 },
    { lat: 48.7461639, lng: 18.7961626 },
    { lat: 48.7460733, lng: 18.7960484 },
    { lat: 48.7460687, lng: 18.7958749 },
    { lat: 48.7461897, lng: 18.7957936 },
    { lat: 48.7462406, lng: 18.7956457 },
    { lat: 48.7463936, lng: 18.7953809 },
    { lat: 48.7464226, lng: 18.795222 },
    { lat: 48.7463267, lng: 18.7949873 },
    { lat: 48.7463012, lng: 18.7946498 },
    { lat: 48.7463513, lng: 18.7946581 },
    { lat: 48.7465128, lng: 18.7945112 },
    { lat: 48.7465593, lng: 18.7943023 },
    { lat: 48.7466404, lng: 18.7942358 },
    { lat: 48.7467199, lng: 18.7939437 },
    { lat: 48.7468263, lng: 18.793777 },
    { lat: 48.7468928, lng: 18.7937794 },
    { lat: 48.7469926, lng: 18.7936177 },
    { lat: 48.7470953, lng: 18.7935522 },
    { lat: 48.7473892, lng: 18.7934795 },
    { lat: 48.7475004, lng: 18.7933655 },
    { lat: 48.7476293, lng: 18.7928116 },
    { lat: 48.7478119, lng: 18.7923985 },
    { lat: 48.7478015, lng: 18.7920059 },
    { lat: 48.7478731, lng: 18.7919745 },
    { lat: 48.7479089, lng: 18.7918584 },
    { lat: 48.7479973, lng: 18.791806 },
    { lat: 48.748047, lng: 18.7916761 },
    { lat: 48.7480508, lng: 18.7916209 },
    { lat: 48.7479731, lng: 18.7915141 },
    { lat: 48.747978, lng: 18.7914135 },
    { lat: 48.7481368, lng: 18.7911926 },
    { lat: 48.7482677, lng: 18.7910258 },
    { lat: 48.7482915, lng: 18.7908528 },
    { lat: 48.7486051, lng: 18.7906181 },
    { lat: 48.7486364, lng: 18.7907862 },
    { lat: 48.7487011, lng: 18.7909207 },
    { lat: 48.7487476, lng: 18.7909466 },
    { lat: 48.7489883, lng: 18.7908738 },
    { lat: 48.7490128, lng: 18.7908441 },
    { lat: 48.7490106, lng: 18.79078 },
    { lat: 48.7489635, lng: 18.7907028 },
    { lat: 48.7489361, lng: 18.790522 },
    { lat: 48.7488752, lng: 18.7904139 },
    { lat: 48.7488802, lng: 18.7903375 },
    { lat: 48.7490312, lng: 18.790312 },
    { lat: 48.7491506, lng: 18.7902041 },
    { lat: 48.7492215, lng: 18.7900505 },
    { lat: 48.7493436, lng: 18.789859 },
    { lat: 48.7494912, lng: 18.7897313 },
    { lat: 48.7496128, lng: 18.7896849 },
    { lat: 48.7497142, lng: 18.7896178 },
    { lat: 48.7497258, lng: 18.7895581 },
    { lat: 48.7496099, lng: 18.7893033 },
    { lat: 48.7496112, lng: 18.789158 },
    { lat: 48.7497973, lng: 18.7891372 },
    { lat: 48.749816, lng: 18.7892329 },
    { lat: 48.7497906, lng: 18.7893288 },
    { lat: 48.7498531, lng: 18.7894672 },
    { lat: 48.7498796, lng: 18.7894815 },
    { lat: 48.7499467, lng: 18.7894685 },
    { lat: 48.7499869, lng: 18.7892707 },
    { lat: 48.7499884, lng: 18.7889282 },
    { lat: 48.7500676, lng: 18.7888933 },
    { lat: 48.7502866, lng: 18.7885705 },
    { lat: 48.7502216, lng: 18.7883228 },
    { lat: 48.7502506, lng: 18.7882311 },
    { lat: 48.7504411, lng: 18.7881647 },
    { lat: 48.7505115, lng: 18.7880869 },
    { lat: 48.7504839, lng: 18.787839 },
    { lat: 48.7505579, lng: 18.7877219 },
    { lat: 48.7506787, lng: 18.7878364 },
    { lat: 48.7508013, lng: 18.7876331 },
    { lat: 48.7507896, lng: 18.787494 },
    { lat: 48.7509231, lng: 18.7875266 },
    { lat: 48.7510084, lng: 18.7875179 },
    { lat: 48.7511764, lng: 18.7873055 },
    { lat: 48.751199, lng: 18.7873039 },
    { lat: 48.7512132, lng: 18.78734 },
    { lat: 48.7511911, lng: 18.7874553 },
    { lat: 48.7512223, lng: 18.7874912 },
    { lat: 48.7513533, lng: 18.7873883 },
    { lat: 48.7514827, lng: 18.7873364 },
    { lat: 48.751442, lng: 18.7871429 },
    { lat: 48.751652, lng: 18.7869741 },
    { lat: 48.7516885, lng: 18.7868754 },
    { lat: 48.7516811, lng: 18.7866905 },
    { lat: 48.7517686, lng: 18.7867039 },
    { lat: 48.751836, lng: 18.7866558 },
    { lat: 48.7518761, lng: 18.7865603 },
    { lat: 48.7518661, lng: 18.7863921 },
    { lat: 48.7522432, lng: 18.7859896 },
    { lat: 48.7523777, lng: 18.7858866 },
    { lat: 48.7524702, lng: 18.7858888 },
    { lat: 48.7525405, lng: 18.7858093 },
    { lat: 48.7525903, lng: 18.7855095 },
    { lat: 48.7526799, lng: 18.7853712 },
    { lat: 48.7527907, lng: 18.7850099 },
    { lat: 48.7529347, lng: 18.7848944 },
    { lat: 48.752981, lng: 18.7848295 },
    { lat: 48.7529709, lng: 18.7846192 },
    { lat: 48.752951, lng: 18.7845586 },
    { lat: 48.7529574, lng: 18.7843788 },
    { lat: 48.7529762, lng: 18.7842469 },
    { lat: 48.7530666, lng: 18.784077 },
    { lat: 48.753117, lng: 18.7837036 },
    { lat: 48.7532179, lng: 18.7835185 },
    { lat: 48.7533627, lng: 18.7830527 },
    { lat: 48.7534023, lng: 18.7824962 },
    { lat: 48.753512, lng: 18.7823765 },
    { lat: 48.7535879, lng: 18.7819532 },
    { lat: 48.753669, lng: 18.7818177 },
    { lat: 48.753744, lng: 18.7817258 },
    { lat: 48.7541268, lng: 18.7814763 },
    { lat: 48.7542523, lng: 18.7814373 },
    { lat: 48.7543037, lng: 18.7813632 },
    { lat: 48.7543095, lng: 18.7811074 },
    { lat: 48.7544878, lng: 18.7810688 },
    { lat: 48.7545199, lng: 18.781124 },
    { lat: 48.7548562, lng: 18.7810837 },
    { lat: 48.7549771, lng: 18.7808748 },
    { lat: 48.7550031, lng: 18.7805096 },
    { lat: 48.7550486, lng: 18.7802994 },
    { lat: 48.7551618, lng: 18.7801382 },
    { lat: 48.7555003, lng: 18.7800762 },
    { lat: 48.7557068, lng: 18.7798402 },
    { lat: 48.7557397, lng: 18.7796926 },
    { lat: 48.7556744, lng: 18.7795661 },
    { lat: 48.7557608, lng: 18.7794106 },
    { lat: 48.7558514, lng: 18.7793319 },
    { lat: 48.7559629, lng: 18.7791141 },
    { lat: 48.7559743, lng: 18.7790291 },
    { lat: 48.7559687, lng: 18.7789851 },
    { lat: 48.7559532, lng: 18.7789694 },
    { lat: 48.7558792, lng: 18.7789322 },
    { lat: 48.755853, lng: 18.7788885 },
    { lat: 48.7558852, lng: 18.7785794 },
    { lat: 48.755825, lng: 18.778463 },
    { lat: 48.755884, lng: 18.7782722 },
    { lat: 48.7558655, lng: 18.7780082 },
    { lat: 48.7560297, lng: 18.7778284 },
    { lat: 48.7560975, lng: 18.7777874 },
    { lat: 48.7561759, lng: 18.7778409 },
    { lat: 48.7562696, lng: 18.7777982 },
    { lat: 48.7563006, lng: 18.7777434 },
    { lat: 48.7562948, lng: 18.777679 },
    { lat: 48.7562418, lng: 18.777591 },
    { lat: 48.7562255, lng: 18.7775147 },
    { lat: 48.7562893, lng: 18.7773802 },
    { lat: 48.7564244, lng: 18.7769869 },
    { lat: 48.7565142, lng: 18.7768282 },
    { lat: 48.7566828, lng: 18.776754 },
    { lat: 48.7568759, lng: 18.7769085 },
    { lat: 48.7569769, lng: 18.7766841 },
    { lat: 48.7569298, lng: 18.7764306 },
    { lat: 48.7570889, lng: 18.7763461 },
    { lat: 48.7574304, lng: 18.7760857 },
    { lat: 48.757458, lng: 18.7757114 },
    { lat: 48.7575727, lng: 18.7753792 },
    { lat: 48.7575643, lng: 18.7751336 },
    { lat: 48.7573982, lng: 18.7750613 },
    { lat: 48.7573308, lng: 18.7748307 },
    { lat: 48.757604, lng: 18.7745563 },
    { lat: 48.7577673, lng: 18.7744259 },
    { lat: 48.7578558, lng: 18.774347 },
    { lat: 48.7579469, lng: 18.7741284 },
    { lat: 48.7578508, lng: 18.7730931 },
    { lat: 48.7577277, lng: 18.7727255 },
    { lat: 48.7578261, lng: 18.7724299 },
    { lat: 48.7577954, lng: 18.7722502 },
    { lat: 48.7578168, lng: 18.7719053 },
    { lat: 48.7579169, lng: 18.7716881 },
    { lat: 48.7579872, lng: 18.7711353 },
    { lat: 48.758088, lng: 18.7702651 },
    { lat: 48.7577493, lng: 18.7696563 },
    { lat: 48.7577405, lng: 18.7695055 },
    { lat: 48.7579241, lng: 18.7693312 },
    { lat: 48.7577747, lng: 18.7690414 },
    { lat: 48.757714, lng: 18.7687213 },
    { lat: 48.7578939, lng: 18.768304 },
    { lat: 48.7579216, lng: 18.7676091 },
    { lat: 48.7578122, lng: 18.7670286 },
    { lat: 48.7579721, lng: 18.7660987 },
    { lat: 48.7580002, lng: 18.7658827 },
    { lat: 48.7579733, lng: 18.7658026 },
    { lat: 48.7579729, lng: 18.7656415 },
    { lat: 48.7579613, lng: 18.7654041 },
    { lat: 48.7579236, lng: 18.7651571 },
    { lat: 48.7578069, lng: 18.7648026 },
    { lat: 48.7577461, lng: 18.764158 },
    { lat: 48.7577409, lng: 18.7640596 },
    { lat: 48.7577483, lng: 18.7636023 },
    { lat: 48.7577508, lng: 18.763201 },
    { lat: 48.7577352, lng: 18.7629662 },
    { lat: 48.7577569, lng: 18.7629468 },
    { lat: 48.7577134, lng: 18.7628302 },
    { lat: 48.7574531, lng: 18.7625977 },
    { lat: 48.7574904, lng: 18.7617219 },
    { lat: 48.7574451, lng: 18.7617134 },
    { lat: 48.7572958, lng: 18.7609543 },
    { lat: 48.756893, lng: 18.7595992 },
    { lat: 48.7566645, lng: 18.7584076 },
    { lat: 48.7568258, lng: 18.7573149 },
    { lat: 48.7558338, lng: 18.7565733 },
    { lat: 48.7565002, lng: 18.7544051 },
    { lat: 48.7569696, lng: 18.7534458 },
    { lat: 48.7572007, lng: 18.7525663 },
    { lat: 48.7570107, lng: 18.7522778 },
    { lat: 48.7568345, lng: 18.7521003 },
    { lat: 48.7570022, lng: 18.751429 },
    { lat: 48.7570258, lng: 18.7513446 },
    { lat: 48.757379, lng: 18.7504489 },
    { lat: 48.7580328, lng: 18.7504427 },
    { lat: 48.7588645, lng: 18.750517 },
    { lat: 48.7602039, lng: 18.7505758 },
    { lat: 48.7606919, lng: 18.7506161 },
    { lat: 48.7607657, lng: 18.750604 },
    { lat: 48.7608443, lng: 18.7505294 },
    { lat: 48.7609454, lng: 18.7505659 },
    { lat: 48.76116, lng: 18.7506151 },
    { lat: 48.7616152, lng: 18.7506525 },
    { lat: 48.7617212, lng: 18.7506733 },
    { lat: 48.7618975, lng: 18.7507218 },
    { lat: 48.7620854, lng: 18.7508218 },
    { lat: 48.7624154, lng: 18.7509 },
    { lat: 48.7626643, lng: 18.7509157 },
    { lat: 48.7630746, lng: 18.7508663 },
    { lat: 48.7633399, lng: 18.7508526 },
    { lat: 48.7637271, lng: 18.7508178 },
    { lat: 48.7641001, lng: 18.750855 },
    { lat: 48.7641341, lng: 18.7507065 },
    { lat: 48.7641157, lng: 18.7503795 },
    { lat: 48.7640794, lng: 18.7501179 },
    { lat: 48.7639375, lng: 18.7496936 },
    { lat: 48.7639109, lng: 18.749512 },
    { lat: 48.7638667, lng: 18.7488835 },
    { lat: 48.7638379, lng: 18.7485735 },
    { lat: 48.7638014, lng: 18.7485 },
    { lat: 48.7636362, lng: 18.7483833 },
    { lat: 48.7633884, lng: 18.7482591 },
    { lat: 48.7629254, lng: 18.7480554 },
    { lat: 48.7627742, lng: 18.7480332 },
    { lat: 48.7627775, lng: 18.747926 },
    { lat: 48.7628255, lng: 18.7468464 },
    { lat: 48.7628443, lng: 18.7464527 },
    { lat: 48.7628534, lng: 18.7463487 },
    { lat: 48.762745, lng: 18.746193 },
    { lat: 48.7626774, lng: 18.7462812 },
    { lat: 48.7625554, lng: 18.7462366 },
    { lat: 48.7624256, lng: 18.7461454 },
    { lat: 48.7623449, lng: 18.7461558 },
    { lat: 48.7622656, lng: 18.7461268 },
    { lat: 48.7621392, lng: 18.7459561 },
    { lat: 48.7623409, lng: 18.7456326 },
    { lat: 48.7625913, lng: 18.7452446 },
    { lat: 48.7627324, lng: 18.7449931 },
    { lat: 48.7629245, lng: 18.7446354 },
    { lat: 48.7630897, lng: 18.7442816 },
    { lat: 48.7632855, lng: 18.7438251 },
    { lat: 48.7633993, lng: 18.7435451 },
    { lat: 48.7636263, lng: 18.7427821 },
    { lat: 48.7636847, lng: 18.7425623 },
    { lat: 48.7637002, lng: 18.7424489 },
    { lat: 48.7637013, lng: 18.7423627 },
    { lat: 48.7636856, lng: 18.7422802 },
    { lat: 48.7635893, lng: 18.7421874 },
    { lat: 48.7635239, lng: 18.7420359 },
    { lat: 48.7632934, lng: 18.7408737 },
    { lat: 48.7631351, lng: 18.7405654 },
    { lat: 48.7629703, lng: 18.7401788 },
    { lat: 48.7626279, lng: 18.7395478 },
    { lat: 48.7624643, lng: 18.7392637 },
    { lat: 48.7622128, lng: 18.7381509 },
    { lat: 48.7619181, lng: 18.7367982 },
    { lat: 48.7612129, lng: 18.7361759 },
    { lat: 48.760216, lng: 18.7353626 },
    { lat: 48.7586049, lng: 18.734496 },
    { lat: 48.7586692, lng: 18.7336429 },
    { lat: 48.7587057, lng: 18.7332242 },
    { lat: 48.7587085, lng: 18.7329094 },
    { lat: 48.7586715, lng: 18.7328538 },
    { lat: 48.7586337, lng: 18.7329245 },
    { lat: 48.7585722, lng: 18.7328304 },
    { lat: 48.7585439, lng: 18.7328259 },
    { lat: 48.7584048, lng: 18.7328649 },
    { lat: 48.7582355, lng: 18.7328811 },
    { lat: 48.7580826, lng: 18.7327641 },
    { lat: 48.7578122, lng: 18.7326816 },
    { lat: 48.7576738, lng: 18.7325815 },
    { lat: 48.7575568, lng: 18.7323712 },
    { lat: 48.757492, lng: 18.7323836 },
    { lat: 48.757244, lng: 18.7321934 },
    { lat: 48.7571113, lng: 18.7321362 },
    { lat: 48.7570444, lng: 18.7320649 },
    { lat: 48.7570721, lng: 18.7319501 },
    { lat: 48.7571191, lng: 18.7318768 },
    { lat: 48.7570577, lng: 18.7317609 },
    { lat: 48.7568471, lng: 18.7314872 },
    { lat: 48.7568116, lng: 18.7312006 },
    { lat: 48.7568741, lng: 18.7310426 },
    { lat: 48.7567847, lng: 18.7307135 },
    { lat: 48.7569022, lng: 18.7304201 },
    { lat: 48.7569098, lng: 18.730263 },
    { lat: 48.7569374, lng: 18.7302532 },
    { lat: 48.7569569, lng: 18.7302169 },
    { lat: 48.7569529, lng: 18.7299476 },
    { lat: 48.7568723, lng: 18.7297457 },
    { lat: 48.7568101, lng: 18.729508 },
    { lat: 48.7567227, lng: 18.7294335 },
    { lat: 48.7566627, lng: 18.7290669 },
    { lat: 48.7567127, lng: 18.7289662 },
    { lat: 48.7567106, lng: 18.7289278 },
    { lat: 48.7566579, lng: 18.7288688 },
    { lat: 48.7566158, lng: 18.7287574 },
    { lat: 48.756518, lng: 18.7286763 },
    { lat: 48.7565483, lng: 18.7284814 },
    { lat: 48.7565988, lng: 18.7283895 },
    { lat: 48.7565998, lng: 18.7283472 },
    { lat: 48.756529, lng: 18.7281595 },
    { lat: 48.7564913, lng: 18.7280247 },
    { lat: 48.7564569, lng: 18.7278419 },
    { lat: 48.7564735, lng: 18.7275583 },
    { lat: 48.7565236, lng: 18.7273292 },
    { lat: 48.7565173, lng: 18.7272774 },
    { lat: 48.7563692, lng: 18.7272078 },
    { lat: 48.7563608, lng: 18.7271326 },
    { lat: 48.7563878, lng: 18.7271079 },
    { lat: 48.7563936, lng: 18.7270468 },
    { lat: 48.7563101, lng: 18.7266838 },
    { lat: 48.7563385, lng: 18.7266049 },
    { lat: 48.7563355, lng: 18.7265027 },
    { lat: 48.7563229, lng: 18.7264564 },
    { lat: 48.7563, lng: 18.7264713 },
    { lat: 48.7562322, lng: 18.7265549 },
    { lat: 48.7561282, lng: 18.7264209 },
    { lat: 48.756146, lng: 18.7263741 },
    { lat: 48.7561461, lng: 18.726205 },
    { lat: 48.7560887, lng: 18.7261026 },
    { lat: 48.7561579, lng: 18.7259827 },
    { lat: 48.7562061, lng: 18.725954 },
    { lat: 48.7561942, lng: 18.7257517 },
    { lat: 48.7561171, lng: 18.7256796 },
    { lat: 48.7560378, lng: 18.7255433 },
    { lat: 48.7559548, lng: 18.7255105 },
    { lat: 48.7559633, lng: 18.7253718 },
    { lat: 48.755958, lng: 18.7251466 },
    { lat: 48.7559004, lng: 18.724928 },
    { lat: 48.7558655, lng: 18.7249083 },
    { lat: 48.7557709, lng: 18.7249105 },
    { lat: 48.7557362, lng: 18.7249836 },
    { lat: 48.7556724, lng: 18.7249921 },
    { lat: 48.7556058, lng: 18.7248404 },
    { lat: 48.7554997, lng: 18.7246888 },
    { lat: 48.7554231, lng: 18.7247745 },
    { lat: 48.7554324, lng: 18.7248878 },
    { lat: 48.7554095, lng: 18.7249002 },
    { lat: 48.7553563, lng: 18.7249006 },
    { lat: 48.7552904, lng: 18.7249328 },
    { lat: 48.7552134, lng: 18.7249053 },
    { lat: 48.7551961, lng: 18.7248788 },
    { lat: 48.7551616, lng: 18.7247583 },
    { lat: 48.7551773, lng: 18.7246931 },
    { lat: 48.7550949, lng: 18.7245419 },
    { lat: 48.7550685, lng: 18.7244271 },
    { lat: 48.7549908, lng: 18.7243829 },
    { lat: 48.7549132, lng: 18.7243905 },
    { lat: 48.7548026, lng: 18.7243453 },
    { lat: 48.754708, lng: 18.7243668 },
    { lat: 48.7546658, lng: 18.7242078 },
    { lat: 48.7546852, lng: 18.7240879 },
    { lat: 48.7546795, lng: 18.7240432 },
    { lat: 48.7545944, lng: 18.7239881 },
    { lat: 48.7545294, lng: 18.7240188 },
    { lat: 48.7544428, lng: 18.7240228 },
    { lat: 48.7543849, lng: 18.7241249 },
    { lat: 48.7543184, lng: 18.7241673 },
    { lat: 48.7542749, lng: 18.7241136 },
    { lat: 48.7542428, lng: 18.7239945 },
    { lat: 48.7541806, lng: 18.7239259 },
    { lat: 48.7538715, lng: 18.723712 },
    { lat: 48.7537651, lng: 18.7235766 },
    { lat: 48.7536633, lng: 18.7235957 },
    { lat: 48.753565, lng: 18.723698 },
    { lat: 48.7534316, lng: 18.723692 },
    { lat: 48.7532421, lng: 18.7237717 },
    { lat: 48.7531212, lng: 18.7239393 },
    { lat: 48.7530152, lng: 18.7239829 },
    { lat: 48.7528463, lng: 18.7239022 },
    { lat: 48.7527125, lng: 18.7239959 },
    { lat: 48.7524919, lng: 18.723726 },
    { lat: 48.7523222, lng: 18.7239091 },
    { lat: 48.7522378, lng: 18.7238904 },
    { lat: 48.7520549, lng: 18.7237159 },
    { lat: 48.7519804, lng: 18.7237153 },
    { lat: 48.7518774, lng: 18.7236438 },
    { lat: 48.7518154, lng: 18.7234947 },
    { lat: 48.7515697, lng: 18.723032 },
    { lat: 48.7513782, lng: 18.7229047 },
    { lat: 48.7512309, lng: 18.7229129 },
    { lat: 48.7511825, lng: 18.7227029 },
    { lat: 48.751044, lng: 18.7226235 },
    { lat: 48.7509245, lng: 18.7224317 },
    { lat: 48.7508493, lng: 18.7223951 },
    { lat: 48.7507358, lng: 18.7221894 },
    { lat: 48.7508015, lng: 18.7217236 },
    { lat: 48.7508203, lng: 18.721379 },
    { lat: 48.7508844, lng: 18.7211207 },
    { lat: 48.7508533, lng: 18.7208286 },
    { lat: 48.7508448, lng: 18.7206266 },
    { lat: 48.7508011, lng: 18.7205876 },
    { lat: 48.7507798, lng: 18.7204608 },
    { lat: 48.7506564, lng: 18.7203046 },
    { lat: 48.7506634, lng: 18.7201593 },
    { lat: 48.7506392, lng: 18.7200425 },
    { lat: 48.7505578, lng: 18.7198083 },
    { lat: 48.7505202, lng: 18.7196685 },
    { lat: 48.750473, lng: 18.7195902 },
    { lat: 48.7503228, lng: 18.719444 },
    { lat: 48.7500415, lng: 18.7194066 },
    { lat: 48.7500148, lng: 18.7192815 },
    { lat: 48.7500082, lng: 18.7191572 },
    { lat: 48.7498347, lng: 18.7190539 },
    { lat: 48.7497537, lng: 18.7190379 },
    { lat: 48.749671, lng: 18.7188632 },
    { lat: 48.7495546, lng: 18.7187279 },
    { lat: 48.7495376, lng: 18.7185981 },
    { lat: 48.749491, lng: 18.7183782 },
    { lat: 48.7494282, lng: 18.7182818 },
    { lat: 48.7493448, lng: 18.7182365 },
    { lat: 48.7492276, lng: 18.7179838 },
    { lat: 48.7489249, lng: 18.7176577 },
    { lat: 48.7488406, lng: 18.7174499 },
    { lat: 48.7488343, lng: 18.7173699 },
    { lat: 48.7488725, lng: 18.717306 },
    { lat: 48.7488762, lng: 18.7172462 },
    { lat: 48.7488337, lng: 18.7171277 },
    { lat: 48.7488452, lng: 18.7169601 },
    { lat: 48.7487509, lng: 18.7168854 },
    { lat: 48.7486949, lng: 18.7168043 },
    { lat: 48.7486501, lng: 18.7166185 },
    { lat: 48.7485409, lng: 18.7164767 },
    { lat: 48.7483727, lng: 18.7164277 },
    { lat: 48.7483531, lng: 18.7163114 },
    { lat: 48.7482348, lng: 18.7160191 },
    { lat: 48.7481347, lng: 18.7158747 },
    { lat: 48.7477338, lng: 18.715056 },
    { lat: 48.7475398, lng: 18.7147136 },
    { lat: 48.7474513, lng: 18.7145149 },
    { lat: 48.7473705, lng: 18.7142434 },
    { lat: 48.7472522, lng: 18.714137 },
    { lat: 48.7472313, lng: 18.7139805 },
    { lat: 48.7471366, lng: 18.713873 },
    { lat: 48.7470208, lng: 18.7135168 },
    { lat: 48.7469631, lng: 18.7134094 },
    { lat: 48.7468699, lng: 18.7127968 },
    { lat: 48.746725, lng: 18.7124255 },
    { lat: 48.7465693, lng: 18.7123425 },
    { lat: 48.7464216, lng: 18.7120428 },
    { lat: 48.7463499, lng: 18.7117113 },
    { lat: 48.7463306, lng: 18.7114949 },
    { lat: 48.7461239, lng: 18.7105565 },
    { lat: 48.7459836, lng: 18.7101647 },
    { lat: 48.7461191, lng: 18.7095923 },
    { lat: 48.7460618, lng: 18.7091933 },
    { lat: 48.7459785, lng: 18.7089822 },
    { lat: 48.7458073, lng: 18.7087303 },
    { lat: 48.7456967, lng: 18.7083249 },
    { lat: 48.745684, lng: 18.7081292 },
    { lat: 48.7455798, lng: 18.7079742 },
    { lat: 48.745515, lng: 18.7076841 },
    { lat: 48.7452957, lng: 18.7073358 },
    { lat: 48.744966, lng: 18.7071571 },
    { lat: 48.7448285, lng: 18.7070112 },
    { lat: 48.7447042, lng: 18.7069855 },
    { lat: 48.7445418, lng: 18.7068369 },
    { lat: 48.7445687, lng: 18.7067297 },
    { lat: 48.7447737, lng: 18.7060234 },
    { lat: 48.74538, lng: 18.704311 },
    { lat: 48.7460427, lng: 18.7030989 },
    { lat: 48.745187, lng: 18.7026788 },
    { lat: 48.7424444, lng: 18.7013644 },
    { lat: 48.7405877, lng: 18.7014418 },
    { lat: 48.739725, lng: 18.7009123 },
    { lat: 48.7389601, lng: 18.7003331 },
    { lat: 48.7374996, lng: 18.6995061 },
    { lat: 48.7359883, lng: 18.6986549 },
    { lat: 48.7353535, lng: 18.6979133 },
    { lat: 48.7351404, lng: 18.698044 },
    { lat: 48.7346363, lng: 18.6983617 },
    { lat: 48.7339623, lng: 18.6978609 },
    { lat: 48.7336432, lng: 18.6976917 },
    { lat: 48.7332345, lng: 18.6974495 },
    { lat: 48.7331181, lng: 18.6974018 },
    { lat: 48.7325139, lng: 18.6971533 },
    { lat: 48.731864, lng: 18.6965127 },
    { lat: 48.7314389, lng: 18.6961732 },
    { lat: 48.730994, lng: 18.6957606 },
    { lat: 48.7309639, lng: 18.6956603 },
    { lat: 48.7307215, lng: 18.6956194 },
    { lat: 48.7306828, lng: 18.6956757 },
    { lat: 48.730628, lng: 18.6958643 },
    { lat: 48.7294469, lng: 18.6964219 },
    { lat: 48.7291089, lng: 18.6966425 },
    { lat: 48.7282904, lng: 18.6971757 },
    { lat: 48.7276785, lng: 18.6975258 },
    { lat: 48.7276147, lng: 18.6975548 },
    { lat: 48.7271578, lng: 18.6977651 },
    { lat: 48.7267651, lng: 18.6980737 },
    { lat: 48.7261904, lng: 18.698805 },
    { lat: 48.7260824, lng: 18.6989818 },
    { lat: 48.725646, lng: 18.7001053 },
    { lat: 48.7246446, lng: 18.7013792 },
    { lat: 48.7233531, lng: 18.7022927 },
    { lat: 48.7228576, lng: 18.7017117 },
    { lat: 48.7221839, lng: 18.7017663 },
    { lat: 48.7205367, lng: 18.7028595 },
    { lat: 48.7201409, lng: 18.7036436 },
    { lat: 48.718279, lng: 18.7030719 },
    { lat: 48.7173671, lng: 18.7028075 },
    { lat: 48.7171439, lng: 18.702596 },
    { lat: 48.7153537, lng: 18.7026085 },
    { lat: 48.7148822, lng: 18.7041792 },
    { lat: 48.7131629, lng: 18.7062079 },
    { lat: 48.7123595, lng: 18.7090047 },
    { lat: 48.7117765, lng: 18.7115361 },
    { lat: 48.7122469, lng: 18.71394 },
    { lat: 48.7083084, lng: 18.7090863 },
    { lat: 48.7074931, lng: 18.7074129 },
    { lat: 48.7068134, lng: 18.7066334 },
    { lat: 48.7056245, lng: 18.7052674 },
    { lat: 48.7045539, lng: 18.7033731 },
    { lat: 48.7034443, lng: 18.7010815 },
    { lat: 48.7026162, lng: 18.6989122 },
    { lat: 48.7010035, lng: 18.696668 },
    { lat: 48.7006368, lng: 18.6957107 },
    { lat: 48.699043, lng: 18.6950934 },
    { lat: 48.6974243, lng: 18.6934977 },
    { lat: 48.6968555, lng: 18.692047 },
    { lat: 48.6964025, lng: 18.689894 },
    { lat: 48.6954078, lng: 18.6900852 },
    { lat: 48.6948597, lng: 18.6893047 },
    { lat: 48.6942816, lng: 18.6893749 },
    { lat: 48.6929255, lng: 18.6892399 },
    { lat: 48.6924677, lng: 18.6894522 },
    { lat: 48.6907217, lng: 18.6896169 },
    { lat: 48.690193, lng: 18.689347 },
    { lat: 48.6899724, lng: 18.68919 },
    { lat: 48.6897412, lng: 18.6886594 },
    { lat: 48.6893899, lng: 18.6882456 },
    { lat: 48.6890712, lng: 18.6879366 },
    { lat: 48.6886659, lng: 18.6874408 },
    { lat: 48.6882894, lng: 18.687064 },
    { lat: 48.6877382, lng: 18.686627 },
    { lat: 48.6867712, lng: 18.6861723 },
    { lat: 48.6860811, lng: 18.6871079 },
    { lat: 48.685847, lng: 18.6872862 },
    { lat: 48.6858088, lng: 18.6847085 },
    { lat: 48.6856597, lng: 18.6844472 },
    { lat: 48.6853984, lng: 18.6836955 },
    { lat: 48.6854675, lng: 18.6825356 },
    { lat: 48.6851845, lng: 18.6812894 },
    { lat: 48.6849829, lng: 18.6808096 },
    { lat: 48.6845554, lng: 18.6799829 },
    { lat: 48.684427, lng: 18.679351 },
    { lat: 48.6841034, lng: 18.6781915 },
    { lat: 48.6839388, lng: 18.6779799 },
    { lat: 48.6834816, lng: 18.6771908 },
    { lat: 48.6832196, lng: 18.6769296 },
    { lat: 48.6827485, lng: 18.6765576 },
    { lat: 48.6825262, lng: 18.6765372 },
    { lat: 48.6817537, lng: 18.6759225 },
    { lat: 48.6808397, lng: 18.6760405 },
    { lat: 48.6805393, lng: 18.6759069 },
    { lat: 48.680256, lng: 18.6761797 },
    { lat: 48.6798697, lng: 18.6757022 },
    { lat: 48.6792331, lng: 18.6751872 },
    { lat: 48.6786712, lng: 18.6747992 },
    { lat: 48.6783312, lng: 18.6743499 },
    { lat: 48.6779373, lng: 18.6741527 },
    { lat: 48.6771521, lng: 18.6744867 },
    { lat: 48.676649, lng: 18.6750319 },
    { lat: 48.675941, lng: 18.6752066 },
    { lat: 48.675729, lng: 18.6752938 },
    { lat: 48.6749633, lng: 18.6754006 },
    { lat: 48.6741346, lng: 18.6757669 },
    { lat: 48.6735659, lng: 18.6757612 },
    { lat: 48.6697192, lng: 18.6756165 },
    { lat: 48.6690074, lng: 18.675635 },
    { lat: 48.6684998, lng: 18.6754245 },
    { lat: 48.6680549, lng: 18.6753695 },
    { lat: 48.6675277, lng: 18.6748753 },
    { lat: 48.6670726, lng: 18.6745493 },
    { lat: 48.6669512, lng: 18.674639 },
    { lat: 48.6667658, lng: 18.6733674 },
    { lat: 48.6666147, lng: 18.6729024 },
    { lat: 48.6662357, lng: 18.6719745 },
    { lat: 48.6646199, lng: 18.6712861 },
    { lat: 48.6629837, lng: 18.6695378 },
    { lat: 48.6622533, lng: 18.6690409 },
    { lat: 48.6617558, lng: 18.6684273 },
    { lat: 48.6607089, lng: 18.6672405 },
    { lat: 48.660293, lng: 18.6669712 },
    { lat: 48.6600513, lng: 18.6663332 },
    { lat: 48.6598472, lng: 18.6659767 },
    { lat: 48.6588767, lng: 18.6651215 },
    { lat: 48.6586344, lng: 18.6649805 },
    { lat: 48.6583648, lng: 18.6646595 },
    { lat: 48.6577289, lng: 18.664158 },
    { lat: 48.6570953, lng: 18.664038 },
    { lat: 48.6565122, lng: 18.664184 },
    { lat: 48.6557767, lng: 18.6642669 },
    { lat: 48.6547821, lng: 18.6650688 },
    { lat: 48.6541605, lng: 18.6647497 },
    { lat: 48.6539147, lng: 18.6644597 },
    { lat: 48.6531953, lng: 18.6643362 },
    { lat: 48.6517315, lng: 18.6645489 },
    { lat: 48.6504476, lng: 18.6625607 },
    { lat: 48.6500894, lng: 18.6616852 },
    { lat: 48.6496171, lng: 18.6607826 },
    { lat: 48.6484777, lng: 18.658886 },
    { lat: 48.64741, lng: 18.6583219 },
    { lat: 48.6465756, lng: 18.658158 },
    { lat: 48.6456216, lng: 18.6576959 },
    { lat: 48.6451479, lng: 18.6579513 },
    { lat: 48.6440816, lng: 18.6586802 },
    { lat: 48.6429762, lng: 18.6585904 },
    { lat: 48.6428539, lng: 18.658585 },
    { lat: 48.6418792, lng: 18.658915 },
    { lat: 48.63992, lng: 18.656502 },
    { lat: 48.639755, lng: 18.656724 },
    { lat: 48.639698, lng: 18.657015 },
    { lat: 48.63929, lng: 18.659277 },
    { lat: 48.639223, lng: 18.659468 },
    { lat: 48.638974, lng: 18.659881 },
    { lat: 48.638676, lng: 18.660419 },
    { lat: 48.638268, lng: 18.661477 },
    { lat: 48.638146, lng: 18.661632 },
    { lat: 48.638145, lng: 18.661633 },
    { lat: 48.638094, lng: 18.661701 },
    { lat: 48.637932, lng: 18.661909 },
    { lat: 48.637517, lng: 18.662682 },
    { lat: 48.637189, lng: 18.663286 },
    { lat: 48.63699, lng: 18.663858 },
    { lat: 48.636774, lng: 18.664477 },
    { lat: 48.636151, lng: 18.665655 },
    { lat: 48.635625, lng: 18.666228 },
    { lat: 48.635481, lng: 18.6664 },
    { lat: 48.635073, lng: 18.667068 },
    { lat: 48.63481, lng: 18.668792 },
    { lat: 48.63469, lng: 18.669338 },
    { lat: 48.634288, lng: 18.670688 },
    { lat: 48.634286, lng: 18.670693 },
    { lat: 48.634258, lng: 18.670804 },
    { lat: 48.634092, lng: 18.671459 },
    { lat: 48.634129, lng: 18.671874 },
    { lat: 48.634079, lng: 18.6721 },
    { lat: 48.633904, lng: 18.672749 },
    { lat: 48.633921, lng: 18.673245 },
    { lat: 48.633753, lng: 18.674089 },
    { lat: 48.6336462, lng: 18.6744174 },
    { lat: 48.633578, lng: 18.675038 },
    { lat: 48.633485, lng: 18.675596 },
    { lat: 48.633211, lng: 18.676393 },
    { lat: 48.632988, lng: 18.677329 },
    { lat: 48.632678, lng: 18.678054 },
    { lat: 48.63248, lng: 18.679468 },
    { lat: 48.632251, lng: 18.680243 },
    { lat: 48.632171, lng: 18.681076 },
    { lat: 48.632088, lng: 18.681719 },
    { lat: 48.631879, lng: 18.682793 },
    { lat: 48.631879, lng: 18.682941 },
    { lat: 48.631904, lng: 18.683327 },
    { lat: 48.631999, lng: 18.683701 },
    { lat: 48.632073, lng: 18.683992 },
    { lat: 48.632109, lng: 18.684682 },
    { lat: 48.632197, lng: 18.684853 },
    { lat: 48.63236, lng: 18.685173 },
    { lat: 48.632368, lng: 18.685243 },
    { lat: 48.632464, lng: 18.686165 },
    { lat: 48.632228, lng: 18.6865169 },
    { lat: 48.631857, lng: 18.686912 },
    { lat: 48.631655, lng: 18.68788 },
    { lat: 48.631495, lng: 18.688653 },
    { lat: 48.631596, lng: 18.689718 },
    { lat: 48.63132, lng: 18.690603 },
    { lat: 48.631507, lng: 18.691007 },
    { lat: 48.631758, lng: 18.691553 },
    { lat: 48.631953, lng: 18.692443 },
    { lat: 48.632132, lng: 18.69263 },
    { lat: 48.632403, lng: 18.693432 },
    { lat: 48.632557, lng: 18.694136 },
    { lat: 48.632617, lng: 18.694454 },
    { lat: 48.632761, lng: 18.6947 },
    { lat: 48.632831, lng: 18.695017 },
    { lat: 48.632923, lng: 18.695409 },
    { lat: 48.632945, lng: 18.696092 },
    { lat: 48.633003, lng: 18.696507 },
    { lat: 48.633103, lng: 18.697067 },
    { lat: 48.632941, lng: 18.698254 },
    { lat: 48.632991, lng: 18.698931 },
    { lat: 48.632671, lng: 18.699508 },
    { lat: 48.632555, lng: 18.69968 },
    { lat: 48.6318, lng: 18.700273 },
    { lat: 48.63148, lng: 18.700486 },
    { lat: 48.631136, lng: 18.701001 },
    { lat: 48.630837, lng: 18.701277 },
    { lat: 48.630755, lng: 18.701344 },
    { lat: 48.630749, lng: 18.701362 },
    { lat: 48.630738, lng: 18.701391 },
    { lat: 48.630727, lng: 18.70142 },
    { lat: 48.630465, lng: 18.702142 },
    { lat: 48.630412, lng: 18.702787 },
    { lat: 48.630183, lng: 18.703486 },
    { lat: 48.630158, lng: 18.703565 },
    { lat: 48.629898, lng: 18.703995 },
    { lat: 48.629665, lng: 18.704378 },
    { lat: 48.628937, lng: 18.705103 },
    { lat: 48.628636, lng: 18.705632 },
    { lat: 48.628381, lng: 18.70615 },
    { lat: 48.628241, lng: 18.706623 },
    { lat: 48.628201, lng: 18.706975 },
    { lat: 48.628185, lng: 18.707096 },
    { lat: 48.628097, lng: 18.707792 },
    { lat: 48.628241, lng: 18.708411 },
    { lat: 48.628215, lng: 18.709262 },
    { lat: 48.628195, lng: 18.710066 },
    { lat: 48.628118, lng: 18.710955 },
    { lat: 48.628074, lng: 18.711736 },
    { lat: 48.628074, lng: 18.711737 },
    { lat: 48.627959, lng: 18.712034 },
    { lat: 48.627867, lng: 18.712272 },
    { lat: 48.627767, lng: 18.712735 },
    { lat: 48.627596, lng: 18.713646 },
    { lat: 48.626957, lng: 18.713987 },
    { lat: 48.626683, lng: 18.714192 },
    { lat: 48.626543, lng: 18.714305 },
    { lat: 48.626429, lng: 18.714462 },
    { lat: 48.626355, lng: 18.714672 },
    { lat: 48.626356, lng: 18.714827 },
    { lat: 48.626392, lng: 18.715084 },
    { lat: 48.626397, lng: 18.715117 },
    { lat: 48.626536, lng: 18.715422 },
    { lat: 48.6266, lng: 18.715634 },
    { lat: 48.62662, lng: 18.715877 },
    { lat: 48.626595, lng: 18.71608 },
    { lat: 48.626462, lng: 18.716378 },
    { lat: 48.626472, lng: 18.716405 },
    { lat: 48.626793, lng: 18.717252 },
    { lat: 48.627278, lng: 18.71753 },
    { lat: 48.627668, lng: 18.717884 },
    { lat: 48.627867, lng: 18.718255 },
    { lat: 48.627953, lng: 18.718351 },
    { lat: 48.62813, lng: 18.718373 },
    { lat: 48.628725, lng: 18.718389 },
    { lat: 48.629253, lng: 18.718203 },
    { lat: 48.629366, lng: 18.718203 },
    { lat: 48.629523, lng: 18.718367 },
    { lat: 48.629786, lng: 18.718457 },
    { lat: 48.630263, lng: 18.718661 },
    { lat: 48.630579, lng: 18.719071 },
    { lat: 48.631649, lng: 18.719534 },
    { lat: 48.632159, lng: 18.719586 },
    { lat: 48.632603, lng: 18.719752 },
    { lat: 48.632963, lng: 18.719763 },
    { lat: 48.633256, lng: 18.71985 },
    { lat: 48.633849, lng: 18.719754 },
    { lat: 48.634126, lng: 18.719581 },
    { lat: 48.634431, lng: 18.719163 },
    { lat: 48.634928, lng: 18.718545 },
    { lat: 48.635108, lng: 18.718374 },
    { lat: 48.635662, lng: 18.71806 },
    { lat: 48.635809, lng: 18.718076 },
    { lat: 48.63639, lng: 18.717764 },
    { lat: 48.637052, lng: 18.717194 },
    { lat: 48.637261, lng: 18.716982 },
  ],
  DiviackaNováVes: [
    { lat: 48.761166, lng: 18.5297457 },
    { lat: 48.7612097, lng: 18.5296762 },
    { lat: 48.7614323, lng: 18.5291464 },
    { lat: 48.7613286, lng: 18.5277461 },
    { lat: 48.7611105, lng: 18.5273651 },
    { lat: 48.7612327, lng: 18.5265046 },
    { lat: 48.7610554, lng: 18.5262318 },
    { lat: 48.7609709, lng: 18.5260078 },
    { lat: 48.7608904, lng: 18.5249242 },
    { lat: 48.7613709, lng: 18.5241791 },
    { lat: 48.761713, lng: 18.5233802 },
    { lat: 48.7615091, lng: 18.5225201 },
    { lat: 48.7612268, lng: 18.5217917 },
    { lat: 48.7610362, lng: 18.5206143 },
    { lat: 48.7609161, lng: 18.5192642 },
    { lat: 48.7608253, lng: 18.5184683 },
    { lat: 48.7607199, lng: 18.5182141 },
    { lat: 48.760607, lng: 18.5178392 },
    { lat: 48.7605592, lng: 18.5175012 },
    { lat: 48.7605383, lng: 18.5172225 },
    { lat: 48.7605191, lng: 18.5165817 },
    { lat: 48.7604502, lng: 18.5164799 },
    { lat: 48.7603752, lng: 18.5161381 },
    { lat: 48.7603538, lng: 18.5158509 },
    { lat: 48.760206, lng: 18.5153567 },
    { lat: 48.7599589, lng: 18.51424 },
    { lat: 48.7599628, lng: 18.5137332 },
    { lat: 48.7599929, lng: 18.5135014 },
    { lat: 48.7598727, lng: 18.5124796 },
    { lat: 48.7598235, lng: 18.5118694 },
    { lat: 48.7598226, lng: 18.5117065 },
    { lat: 48.7598446, lng: 18.5115557 },
    { lat: 48.7596604, lng: 18.5111351 },
    { lat: 48.7595485, lng: 18.5102224 },
    { lat: 48.759486, lng: 18.5100522 },
    { lat: 48.7594318, lng: 18.5097197 },
    { lat: 48.7594647, lng: 18.5096856 },
    { lat: 48.7593893, lng: 18.5091938 },
    { lat: 48.7593596, lng: 18.5088364 },
    { lat: 48.7592882, lng: 18.5083362 },
    { lat: 48.7591315, lng: 18.5073698 },
    { lat: 48.7591096, lng: 18.5071323 },
    { lat: 48.7591318, lng: 18.5068775 },
    { lat: 48.7591077, lng: 18.5068741 },
    { lat: 48.7590627, lng: 18.5060524 },
    { lat: 48.7589819, lng: 18.5054197 },
    { lat: 48.7588511, lng: 18.5048441 },
    { lat: 48.7588685, lng: 18.5047515 },
    { lat: 48.7586814, lng: 18.5047109 },
    { lat: 48.7585065, lng: 18.5046499 },
    { lat: 48.7583822, lng: 18.5046201 },
    { lat: 48.7582072, lng: 18.5045155 },
    { lat: 48.7580507, lng: 18.5043949 },
    { lat: 48.7580203, lng: 18.5043962 },
    { lat: 48.7578956, lng: 18.5045102 },
    { lat: 48.757804, lng: 18.5046718 },
    { lat: 48.7578455, lng: 18.5049544 },
    { lat: 48.7578816, lng: 18.5050965 },
    { lat: 48.7579342, lng: 18.5051507 },
    { lat: 48.757971, lng: 18.5051239 },
    { lat: 48.7580193, lng: 18.5051207 },
    { lat: 48.7580639, lng: 18.505153 },
    { lat: 48.7581202, lng: 18.5052539 },
    { lat: 48.7581248, lng: 18.5053366 },
    { lat: 48.7580517, lng: 18.5055253 },
    { lat: 48.7579949, lng: 18.5057669 },
    { lat: 48.7578765, lng: 18.5058279 },
    { lat: 48.757713, lng: 18.5057679 },
    { lat: 48.7576211, lng: 18.5057169 },
    { lat: 48.7576038, lng: 18.5056472 },
    { lat: 48.7576047, lng: 18.5055423 },
    { lat: 48.7576483, lng: 18.5053084 },
    { lat: 48.7575415, lng: 18.5047995 },
    { lat: 48.7574862, lng: 18.5045933 },
    { lat: 48.7573965, lng: 18.5044784 },
    { lat: 48.7572571, lng: 18.5044876 },
    { lat: 48.7571204, lng: 18.5045856 },
    { lat: 48.7569853, lng: 18.5049414 },
    { lat: 48.7568814, lng: 18.5050649 },
    { lat: 48.75685, lng: 18.5050864 },
    { lat: 48.7566913, lng: 18.5051538 },
    { lat: 48.7565216, lng: 18.5054135 },
    { lat: 48.7561509, lng: 18.5054028 },
    { lat: 48.7559629, lng: 18.5054707 },
    { lat: 48.7559392, lng: 18.5057164 },
    { lat: 48.755805, lng: 18.5057161 },
    { lat: 48.755592, lng: 18.5055948 },
    { lat: 48.7556198, lng: 18.5054254 },
    { lat: 48.755624, lng: 18.505214 },
    { lat: 48.7557076, lng: 18.5048667 },
    { lat: 48.7557816, lng: 18.5046726 },
    { lat: 48.7558576, lng: 18.5045584 },
    { lat: 48.7559014, lng: 18.5044306 },
    { lat: 48.755929, lng: 18.5042963 },
    { lat: 48.7559359, lng: 18.5040298 },
    { lat: 48.7559038, lng: 18.5035898 },
    { lat: 48.7558265, lng: 18.5017888 },
    { lat: 48.7557517, lng: 18.5006302 },
    { lat: 48.7555325, lng: 18.500538 },
    { lat: 48.7553078, lng: 18.5004764 },
    { lat: 48.7551734, lng: 18.50043 },
    { lat: 48.7550335, lng: 18.5002674 },
    { lat: 48.7549838, lng: 18.5001628 },
    { lat: 48.754922, lng: 18.5002084 },
    { lat: 48.754673, lng: 18.5000692 },
    { lat: 48.754636, lng: 18.4997421 },
    { lat: 48.7547155, lng: 18.4998347 },
    { lat: 48.7546959, lng: 18.4994389 },
    { lat: 48.754679, lng: 18.4993338 },
    { lat: 48.7546596, lng: 18.4992672 },
    { lat: 48.7545565, lng: 18.4991168 },
    { lat: 48.7540045, lng: 18.4989265 },
    { lat: 48.7537528, lng: 18.4989441 },
    { lat: 48.753613, lng: 18.498843 },
    { lat: 48.753545, lng: 18.4987325 },
    { lat: 48.7535182, lng: 18.4984681 },
    { lat: 48.7535137, lng: 18.4978738 },
    { lat: 48.7536844, lng: 18.4973487 },
    { lat: 48.7536892, lng: 18.4972294 },
    { lat: 48.7537316, lng: 18.497178 },
    { lat: 48.7536924, lng: 18.496977 },
    { lat: 48.7536665, lng: 18.4969195 },
    { lat: 48.7537962, lng: 18.4961768 },
    { lat: 48.7538691, lng: 18.4960668 },
    { lat: 48.7554946, lng: 18.4954488 },
    { lat: 48.7551873, lng: 18.4912903 },
    { lat: 48.7549574, lng: 18.4911923 },
    { lat: 48.7547404, lng: 18.4911245 },
    { lat: 48.7545266, lng: 18.4909497 },
    { lat: 48.7544674, lng: 18.4908739 },
    { lat: 48.7539616, lng: 18.490251 },
    { lat: 48.7538945, lng: 18.490179 },
    { lat: 48.7537749, lng: 18.4901579 },
    { lat: 48.7532007, lng: 18.490446 },
    { lat: 48.752972, lng: 18.4905548 },
    { lat: 48.7529123, lng: 18.4901444 },
    { lat: 48.7528458, lng: 18.4895915 },
    { lat: 48.7529329, lng: 18.4892063 },
    { lat: 48.7529084, lng: 18.4889838 },
    { lat: 48.7530994, lng: 18.4883564 },
    { lat: 48.7531218, lng: 18.4878606 },
    { lat: 48.7531677, lng: 18.487754 },
    { lat: 48.7532033, lng: 18.4874982 },
    { lat: 48.7532957, lng: 18.4871888 },
    { lat: 48.7533816, lng: 18.4868406 },
    { lat: 48.753427, lng: 18.486127 },
    { lat: 48.7534282, lng: 18.4858009 },
    { lat: 48.7535304, lng: 18.4853218 },
    { lat: 48.7535123, lng: 18.4851979 },
    { lat: 48.7537296, lng: 18.4844091 },
    { lat: 48.7537385, lng: 18.4841403 },
    { lat: 48.7539016, lng: 18.4837237 },
    { lat: 48.7540548, lng: 18.483595 },
    { lat: 48.7541239, lng: 18.4833343 },
    { lat: 48.7542397, lng: 18.4830795 },
    { lat: 48.7542776, lng: 18.4829692 },
    { lat: 48.7543726, lng: 18.4828486 },
    { lat: 48.7544181, lng: 18.4828171 },
    { lat: 48.7544409, lng: 18.4826559 },
    { lat: 48.7545222, lng: 18.4825946 },
    { lat: 48.754645, lng: 18.482533 },
    { lat: 48.754926, lng: 18.4821873 },
    { lat: 48.7551193, lng: 18.4819242 },
    { lat: 48.7552739, lng: 18.4816402 },
    { lat: 48.7554148, lng: 18.4812675 },
    { lat: 48.7555901, lng: 18.4809465 },
    { lat: 48.7556487, lng: 18.4805177 },
    { lat: 48.7557583, lng: 18.4803358 },
    { lat: 48.7559467, lng: 18.4799278 },
    { lat: 48.7556273, lng: 18.4796985 },
    { lat: 48.7558354, lng: 18.4791324 },
    { lat: 48.7558739, lng: 18.4788494 },
    { lat: 48.7561373, lng: 18.4785113 },
    { lat: 48.756321, lng: 18.478121 },
    { lat: 48.7564147, lng: 18.4778515 },
    { lat: 48.7564134, lng: 18.4775231 },
    { lat: 48.7564951, lng: 18.4772422 },
    { lat: 48.7565134, lng: 18.4769824 },
    { lat: 48.7565609, lng: 18.4767205 },
    { lat: 48.7567307, lng: 18.4761543 },
    { lat: 48.7568792, lng: 18.4768424 },
    { lat: 48.7569258, lng: 18.4767746 },
    { lat: 48.7569674, lng: 18.4766875 },
    { lat: 48.7570475, lng: 18.4764007 },
    { lat: 48.7570956, lng: 18.4763114 },
    { lat: 48.7571546, lng: 18.4760748 },
    { lat: 48.7572732, lng: 18.4757892 },
    { lat: 48.7575764, lng: 18.4754367 },
    { lat: 48.7575081, lng: 18.4754064 },
    { lat: 48.7574888, lng: 18.4753816 },
    { lat: 48.7576617, lng: 18.4749977 },
    { lat: 48.7577456, lng: 18.4746575 },
    { lat: 48.7578503, lng: 18.4743661 },
    { lat: 48.7578555, lng: 18.474215 },
    { lat: 48.7579517, lng: 18.47385 },
    { lat: 48.7580618, lng: 18.4735959 },
    { lat: 48.7581997, lng: 18.4730912 },
    { lat: 48.7582589, lng: 18.4728134 },
    { lat: 48.7583546, lng: 18.4725039 },
    { lat: 48.7584138, lng: 18.4721032 },
    { lat: 48.7584475, lng: 18.4719377 },
    { lat: 48.7584894, lng: 18.4719399 },
    { lat: 48.7584018, lng: 18.4716613 },
    { lat: 48.7578152, lng: 18.4709855 },
    { lat: 48.7574146, lng: 18.4705716 },
    { lat: 48.7572939, lng: 18.4703505 },
    { lat: 48.7571191, lng: 18.4700433 },
    { lat: 48.7569875, lng: 18.4698639 },
    { lat: 48.7568427, lng: 18.4696298 },
    { lat: 48.756808, lng: 18.4694729 },
    { lat: 48.7567938, lng: 18.4694597 },
    { lat: 48.7568069, lng: 18.4694316 },
    { lat: 48.7567155, lng: 18.4691664 },
    { lat: 48.7567132, lng: 18.4688378 },
    { lat: 48.7568168, lng: 18.4687525 },
    { lat: 48.7570092, lng: 18.4686195 },
    { lat: 48.7571163, lng: 18.4685778 },
    { lat: 48.7568444, lng: 18.4679246 },
    { lat: 48.7566364, lng: 18.4679376 },
    { lat: 48.7564619, lng: 18.4673496 },
    { lat: 48.7563813, lng: 18.4674013 },
    { lat: 48.7562322, lng: 18.4670669 },
    { lat: 48.7565563, lng: 18.4666477 },
    { lat: 48.7570971, lng: 18.4662267 },
    { lat: 48.7571767, lng: 18.4660711 },
    { lat: 48.7575486, lng: 18.4655537 },
    { lat: 48.7576274, lng: 18.4643846 },
    { lat: 48.7574409, lng: 18.4632745 },
    { lat: 48.7581956, lng: 18.4611914 },
    { lat: 48.7585934, lng: 18.4601579 },
    { lat: 48.7591481, lng: 18.4578865 },
    { lat: 48.7594757, lng: 18.4570757 },
    { lat: 48.7597728, lng: 18.4563474 },
    { lat: 48.7605305, lng: 18.4544832 },
    { lat: 48.7608239, lng: 18.453666 },
    { lat: 48.7608738, lng: 18.4531412 },
    { lat: 48.7610543, lng: 18.4525481 },
    { lat: 48.7613336, lng: 18.4513782 },
    { lat: 48.7616025, lng: 18.450529 },
    { lat: 48.7617222, lng: 18.4500394 },
    { lat: 48.7617931, lng: 18.4496289 },
    { lat: 48.7620035, lng: 18.4490895 },
    { lat: 48.7620791, lng: 18.4485579 },
    { lat: 48.7622805, lng: 18.4471819 },
    { lat: 48.7623273, lng: 18.446969 },
    { lat: 48.7625137, lng: 18.4463586 },
    { lat: 48.762672, lng: 18.4459381 },
    { lat: 48.7628524, lng: 18.4452427 },
    { lat: 48.7629034, lng: 18.4443494 },
    { lat: 48.7629021, lng: 18.4439616 },
    { lat: 48.7629377, lng: 18.4437469 },
    { lat: 48.7629834, lng: 18.4429237 },
    { lat: 48.7631913, lng: 18.4425402 },
    { lat: 48.7635105, lng: 18.442376 },
    { lat: 48.7637366, lng: 18.4420184 },
    { lat: 48.7638703, lng: 18.4418849 },
    { lat: 48.7642737, lng: 18.4416346 },
    { lat: 48.7648531, lng: 18.4410746 },
    { lat: 48.7651032, lng: 18.4405197 },
    { lat: 48.7653772, lng: 18.4402719 },
    { lat: 48.7654761, lng: 18.4400613 },
    { lat: 48.7661822, lng: 18.43954 },
    { lat: 48.76606, lng: 18.438671 },
    { lat: 48.765963, lng: 18.437301 },
    { lat: 48.765922, lng: 18.436645 },
    { lat: 48.765798, lng: 18.43559 },
    { lat: 48.765667, lng: 18.43519 },
    { lat: 48.7652446, lng: 18.4365548 },
    { lat: 48.7649561, lng: 18.4371499 },
    { lat: 48.7648854, lng: 18.4374989 },
    { lat: 48.7644718, lng: 18.4386917 },
    { lat: 48.7637267, lng: 18.4390253 },
    { lat: 48.7634886, lng: 18.4392514 },
    { lat: 48.7623682, lng: 18.4402869 },
    { lat: 48.7622011, lng: 18.440445 },
    { lat: 48.7619927, lng: 18.440494 },
    { lat: 48.7609654, lng: 18.4419253 },
    { lat: 48.7593577, lng: 18.4441043 },
    { lat: 48.7585623, lng: 18.4450999 },
    { lat: 48.757707, lng: 18.4461985 },
    { lat: 48.7572971, lng: 18.4468056 },
    { lat: 48.7567059, lng: 18.4477196 },
    { lat: 48.7559079, lng: 18.4488755 },
    { lat: 48.7550288, lng: 18.450026 },
    { lat: 48.7542837, lng: 18.4517541 },
    { lat: 48.7537757, lng: 18.4527038 },
    { lat: 48.7533954, lng: 18.4531712 },
    { lat: 48.7528349, lng: 18.453932 },
    { lat: 48.7526255, lng: 18.4542459 },
    { lat: 48.7525722, lng: 18.4543508 },
    { lat: 48.7521411, lng: 18.4551991 },
    { lat: 48.7517979, lng: 18.4559488 },
    { lat: 48.7516902, lng: 18.4564086 },
    { lat: 48.7514132, lng: 18.4574367 },
    { lat: 48.7511924, lng: 18.4582789 },
    { lat: 48.7510246, lng: 18.4589393 },
    { lat: 48.7508206, lng: 18.4597014 },
    { lat: 48.7506604, lng: 18.460354 },
    { lat: 48.7504764, lng: 18.4610039 },
    { lat: 48.7502661, lng: 18.4618369 },
    { lat: 48.7498486, lng: 18.4633871 },
    { lat: 48.7497016, lng: 18.4636498 },
    { lat: 48.7496781, lng: 18.4636899 },
    { lat: 48.7492393, lng: 18.4642018 },
    { lat: 48.7487246, lng: 18.4647275 },
    { lat: 48.7479448, lng: 18.4651061 },
    { lat: 48.747666, lng: 18.4653663 },
    { lat: 48.7462034, lng: 18.4672899 },
    { lat: 48.7461433, lng: 18.4673827 },
    { lat: 48.745646, lng: 18.4680147 },
    { lat: 48.7452082, lng: 18.468571 },
    { lat: 48.7445973, lng: 18.4698686 },
    { lat: 48.7443623, lng: 18.4704772 },
    { lat: 48.744127, lng: 18.4707499 },
    { lat: 48.7435954, lng: 18.4719936 },
    { lat: 48.7434148, lng: 18.4724442 },
    { lat: 48.7431051, lng: 18.4728635 },
    { lat: 48.7426206, lng: 18.473689 },
    { lat: 48.7420941, lng: 18.4743104 },
    { lat: 48.7411904, lng: 18.4751905 },
    { lat: 48.7410235, lng: 18.4753952 },
    { lat: 48.7408408, lng: 18.4757438 },
    { lat: 48.7408155, lng: 18.4757784 },
    { lat: 48.7402664, lng: 18.476478 },
    { lat: 48.7393212, lng: 18.4778318 },
    { lat: 48.7390068, lng: 18.4783688 },
    { lat: 48.7384672, lng: 18.479204 },
    { lat: 48.7382074, lng: 18.480058 },
    { lat: 48.7375791, lng: 18.4817581 },
    { lat: 48.7374069, lng: 18.482754 },
    { lat: 48.7372273, lng: 18.4834852 },
    { lat: 48.7371094, lng: 18.4839067 },
    { lat: 48.7369741, lng: 18.4842361 },
    { lat: 48.736861, lng: 18.4844369 },
    { lat: 48.7367135, lng: 18.4846041 },
    { lat: 48.7362112, lng: 18.485933 },
    { lat: 48.7359148, lng: 18.4872089 },
    { lat: 48.7357673, lng: 18.4880354 },
    { lat: 48.7357235, lng: 18.4883039 },
    { lat: 48.7355833, lng: 18.4887682 },
    { lat: 48.735543, lng: 18.4889395 },
    { lat: 48.7354917, lng: 18.4892565 },
    { lat: 48.7353774, lng: 18.489744 },
    { lat: 48.7350505, lng: 18.491371 },
    { lat: 48.7346966, lng: 18.4933283 },
    { lat: 48.7342471, lng: 18.4963782 },
    { lat: 48.7342359, lng: 18.4964618 },
    { lat: 48.7341555, lng: 18.4969472 },
    { lat: 48.7339003, lng: 18.4985251 },
    { lat: 48.733625, lng: 18.5002327 },
    { lat: 48.7335851, lng: 18.5004928 },
    { lat: 48.7332298, lng: 18.5029179 },
    { lat: 48.7331182, lng: 18.5029384 },
    { lat: 48.7329407, lng: 18.5041073 },
    { lat: 48.7325758, lng: 18.5062348 },
    { lat: 48.7321423, lng: 18.5084134 },
    { lat: 48.7318572, lng: 18.5104366 },
    { lat: 48.7317296, lng: 18.5120812 },
    { lat: 48.7315595, lng: 18.5138141 },
    { lat: 48.731447, lng: 18.5148065 },
    { lat: 48.7314089, lng: 18.5149363 },
    { lat: 48.7313312, lng: 18.5151222 },
    { lat: 48.7312743, lng: 18.5154041 },
    { lat: 48.7312297, lng: 18.5158024 },
    { lat: 48.7311372, lng: 18.5165064 },
    { lat: 48.730995, lng: 18.518607 },
    { lat: 48.7309858, lng: 18.5204601 },
    { lat: 48.7312637, lng: 18.5202385 },
    { lat: 48.7314661, lng: 18.5201063 },
    { lat: 48.7316206, lng: 18.5200231 },
    { lat: 48.731746, lng: 18.520479 },
    { lat: 48.7318554, lng: 18.5207062 },
    { lat: 48.7319536, lng: 18.5209558 },
    { lat: 48.732011, lng: 18.5212013 },
    { lat: 48.7319899, lng: 18.5214119 },
    { lat: 48.7319827, lng: 18.522046 },
    { lat: 48.732107, lng: 18.5221526 },
    { lat: 48.732145, lng: 18.5226617 },
    { lat: 48.7321809, lng: 18.5229863 },
    { lat: 48.732264, lng: 18.5235373 },
    { lat: 48.7323834, lng: 18.5241298 },
    { lat: 48.7324582, lng: 18.5244095 },
    { lat: 48.7325906, lng: 18.5247475 },
    { lat: 48.7326307, lng: 18.5250651 },
    { lat: 48.7325879, lng: 18.5255196 },
    { lat: 48.7323955, lng: 18.5268514 },
    { lat: 48.7330094, lng: 18.5269609 },
    { lat: 48.7330117, lng: 18.5270018 },
    { lat: 48.7335144, lng: 18.52691 },
    { lat: 48.7338579, lng: 18.5267167 },
    { lat: 48.7341773, lng: 18.526411 },
    { lat: 48.7347876, lng: 18.5259563 },
    { lat: 48.7350732, lng: 18.5256941 },
    { lat: 48.7352554, lng: 18.5254548 },
    { lat: 48.7354704, lng: 18.5251382 },
    { lat: 48.7357849, lng: 18.5248666 },
    { lat: 48.7360239, lng: 18.5247371 },
    { lat: 48.7366352, lng: 18.5246729 },
    { lat: 48.7375076, lng: 18.5246643 },
    { lat: 48.7378262, lng: 18.5246291 },
    { lat: 48.7384053, lng: 18.5247019 },
    { lat: 48.7388432, lng: 18.5246826 },
    { lat: 48.7390726, lng: 18.5244656 },
    { lat: 48.739494, lng: 18.5242089 },
    { lat: 48.7403041, lng: 18.523924 },
    { lat: 48.7403477, lng: 18.5251336 },
    { lat: 48.7403357, lng: 18.5265446 },
    { lat: 48.7403458, lng: 18.5269983 },
    { lat: 48.7403809, lng: 18.5271647 },
    { lat: 48.7406885, lng: 18.5275509 },
    { lat: 48.7409235, lng: 18.527643 },
    { lat: 48.7411685, lng: 18.5277918 },
    { lat: 48.7412666, lng: 18.5280769 },
    { lat: 48.7413459, lng: 18.528829 },
    { lat: 48.741482, lng: 18.5293598 },
    { lat: 48.7416657, lng: 18.5298934 },
    { lat: 48.7415704, lng: 18.5299871 },
    { lat: 48.7411032, lng: 18.5307866 },
    { lat: 48.7407161, lng: 18.5313195 },
    { lat: 48.7404779, lng: 18.5318153 },
    { lat: 48.7402945, lng: 18.5325472 },
    { lat: 48.7401744, lng: 18.5327037 },
    { lat: 48.7398323, lng: 18.5329233 },
    { lat: 48.7396295, lng: 18.533217 },
    { lat: 48.7394763, lng: 18.5336531 },
    { lat: 48.7392911, lng: 18.5337734 },
    { lat: 48.738752, lng: 18.5345847 },
    { lat: 48.7383598, lng: 18.5353101 },
    { lat: 48.7383046, lng: 18.5354599 },
    { lat: 48.7384068, lng: 18.5356566 },
    { lat: 48.7389544, lng: 18.5368469 },
    { lat: 48.7392704, lng: 18.5375537 },
    { lat: 48.7392525, lng: 18.537577 },
    { lat: 48.7396495, lng: 18.5384238 },
    { lat: 48.7401061, lng: 18.5394329 },
    { lat: 48.740064, lng: 18.5396537 },
    { lat: 48.7399472, lng: 18.5399084 },
    { lat: 48.7394971, lng: 18.5404058 },
    { lat: 48.7393951, lng: 18.5405 },
    { lat: 48.739323, lng: 18.5406691 },
    { lat: 48.7391065, lng: 18.5409601 },
    { lat: 48.7389005, lng: 18.5413385 },
    { lat: 48.7387679, lng: 18.5414522 },
    { lat: 48.738538, lng: 18.5419948 },
    { lat: 48.7383261, lng: 18.5424314 },
    { lat: 48.7381854, lng: 18.5428288 },
    { lat: 48.7380064, lng: 18.5435011 },
    { lat: 48.7379962, lng: 18.5440591 },
    { lat: 48.7380191, lng: 18.544623 },
    { lat: 48.7378601, lng: 18.5452232 },
    { lat: 48.7378232, lng: 18.5453139 },
    { lat: 48.7375455, lng: 18.5458045 },
    { lat: 48.7374097, lng: 18.5460072 },
    { lat: 48.7372422, lng: 18.5463461 },
    { lat: 48.7371028, lng: 18.5464992 },
    { lat: 48.736909, lng: 18.5465655 },
    { lat: 48.7367395, lng: 18.5465609 },
    { lat: 48.7364294, lng: 18.5465659 },
    { lat: 48.7364185, lng: 18.5466734 },
    { lat: 48.736841, lng: 18.5470174 },
    { lat: 48.7369962, lng: 18.5471343 },
    { lat: 48.7371364, lng: 18.5472215 },
    { lat: 48.7374349, lng: 18.5472994 },
    { lat: 48.737733, lng: 18.547282 },
    { lat: 48.7377336, lng: 18.547437 },
    { lat: 48.7375911, lng: 18.5475151 },
    { lat: 48.7375317, lng: 18.5476272 },
    { lat: 48.7375788, lng: 18.5476181 },
    { lat: 48.7376782, lng: 18.5476855 },
    { lat: 48.7377742, lng: 18.5478518 },
    { lat: 48.7378515, lng: 18.547904 },
    { lat: 48.7379346, lng: 18.5478765 },
    { lat: 48.7380422, lng: 18.5477592 },
    { lat: 48.7380626, lng: 18.5475972 },
    { lat: 48.7384488, lng: 18.5471489 },
    { lat: 48.738523, lng: 18.5470014 },
    { lat: 48.7386874, lng: 18.5467651 },
    { lat: 48.7388555, lng: 18.5463957 },
    { lat: 48.7390637, lng: 18.5461779 },
    { lat: 48.7393655, lng: 18.5456093 },
    { lat: 48.7394352, lng: 18.5455002 },
    { lat: 48.7400088, lng: 18.544787 },
    { lat: 48.7403508, lng: 18.5443799 },
    { lat: 48.7406596, lng: 18.5440419 },
    { lat: 48.7409941, lng: 18.5438266 },
    { lat: 48.741061, lng: 18.5438093 },
    { lat: 48.7415318, lng: 18.5435702 },
    { lat: 48.741709, lng: 18.5432812 },
    { lat: 48.7419514, lng: 18.5429494 },
    { lat: 48.7420714, lng: 18.54275 },
    { lat: 48.7421619, lng: 18.5426319 },
    { lat: 48.7421768, lng: 18.5424499 },
    { lat: 48.7422103, lng: 18.5422577 },
    { lat: 48.7422993, lng: 18.5421298 },
    { lat: 48.7423235, lng: 18.5420792 },
    { lat: 48.7423189, lng: 18.5416613 },
    { lat: 48.7423762, lng: 18.5414494 },
    { lat: 48.7423268, lng: 18.541329 },
    { lat: 48.7424558, lng: 18.541111 },
    { lat: 48.7425061, lng: 18.541167 },
    { lat: 48.7425398, lng: 18.5413908 },
    { lat: 48.7425652, lng: 18.5414401 },
    { lat: 48.7426686, lng: 18.5414537 },
    { lat: 48.7427997, lng: 18.5415017 },
    { lat: 48.7429329, lng: 18.5414429 },
    { lat: 48.7430088, lng: 18.5412165 },
    { lat: 48.7432313, lng: 18.5409158 },
    { lat: 48.743389, lng: 18.5405162 },
    { lat: 48.7433885, lng: 18.5404244 },
    { lat: 48.7433544, lng: 18.5404201 },
    { lat: 48.7433301, lng: 18.5404081 },
    { lat: 48.7432413, lng: 18.5400829 },
    { lat: 48.7433966, lng: 18.5396032 },
    { lat: 48.7435924, lng: 18.5393447 },
    { lat: 48.743824, lng: 18.5395388 },
    { lat: 48.7439283, lng: 18.5395477 },
    { lat: 48.7440635, lng: 18.5394836 },
    { lat: 48.7442815, lng: 18.5393028 },
    { lat: 48.7443634, lng: 18.5391049 },
    { lat: 48.7444063, lng: 18.538982 },
    { lat: 48.7443362, lng: 18.5389393 },
    { lat: 48.7442025, lng: 18.5390496 },
    { lat: 48.7441462, lng: 18.5389933 },
    { lat: 48.7441474, lng: 18.5387855 },
    { lat: 48.7443025, lng: 18.5386293 },
    { lat: 48.7443025, lng: 18.5384867 },
    { lat: 48.7444713, lng: 18.5384993 },
    { lat: 48.7445268, lng: 18.5384823 },
    { lat: 48.7445531, lng: 18.5384483 },
    { lat: 48.7445604, lng: 18.5383134 },
    { lat: 48.7444115, lng: 18.5381665 },
    { lat: 48.7443118, lng: 18.5377858 },
    { lat: 48.7443442, lng: 18.5377203 },
    { lat: 48.7445059, lng: 18.5377262 },
    { lat: 48.7445583, lng: 18.5375903 },
    { lat: 48.7448236, lng: 18.537574 },
    { lat: 48.7448643, lng: 18.5375146 },
    { lat: 48.7448774, lng: 18.5373432 },
    { lat: 48.7447943, lng: 18.5372242 },
    { lat: 48.7448096, lng: 18.5371318 },
    { lat: 48.7449359, lng: 18.5371107 },
    { lat: 48.7449767, lng: 18.5370538 },
    { lat: 48.7452145, lng: 18.5368841 },
    { lat: 48.7453182, lng: 18.5366967 },
    { lat: 48.7454596, lng: 18.5365583 },
    { lat: 48.7455835, lng: 18.5360786 },
    { lat: 48.7456734, lng: 18.5360109 },
    { lat: 48.7457459, lng: 18.5356673 },
    { lat: 48.7457831, lng: 18.5356426 },
    { lat: 48.7459356, lng: 18.535708 },
    { lat: 48.7460705, lng: 18.5357029 },
    { lat: 48.7461639, lng: 18.5356566 },
    { lat: 48.7462261, lng: 18.5354916 },
    { lat: 48.7462842, lng: 18.5354608 },
    { lat: 48.7463405, lng: 18.5355435 },
    { lat: 48.7464438, lng: 18.5355141 },
    { lat: 48.7467244, lng: 18.5355313 },
    { lat: 48.7468108, lng: 18.5353336 },
    { lat: 48.74681, lng: 18.5352815 },
    { lat: 48.7468494, lng: 18.5353188 },
    { lat: 48.747011, lng: 18.5352611 },
    { lat: 48.7473609, lng: 18.5354519 },
    { lat: 48.7474689, lng: 18.5353437 },
    { lat: 48.7475153, lng: 18.535181 },
    { lat: 48.7475854, lng: 18.535084 },
    { lat: 48.7477601, lng: 18.5346247 },
    { lat: 48.7477958, lng: 18.5343472 },
    { lat: 48.7480809, lng: 18.5341572 },
    { lat: 48.7484422, lng: 18.53406 },
    { lat: 48.7485101, lng: 18.5340225 },
    { lat: 48.7486039, lng: 18.5340033 },
    { lat: 48.7487447, lng: 18.5340201 },
    { lat: 48.7488339, lng: 18.5340043 },
    { lat: 48.7488893, lng: 18.5338802 },
    { lat: 48.7489341, lng: 18.5334835 },
    { lat: 48.7489997, lng: 18.5333399 },
    { lat: 48.7490219, lng: 18.533335 },
    { lat: 48.7490581, lng: 18.5333569 },
    { lat: 48.7492212, lng: 18.5336393 },
    { lat: 48.7493331, lng: 18.5335578 },
    { lat: 48.7494497, lng: 18.5335243 },
    { lat: 48.7496532, lng: 18.5334506 },
    { lat: 48.7497228, lng: 18.5333615 },
    { lat: 48.7497495, lng: 18.5330672 },
    { lat: 48.7497821, lng: 18.5329409 },
    { lat: 48.7498196, lng: 18.5328615 },
    { lat: 48.7499201, lng: 18.5328229 },
    { lat: 48.749998, lng: 18.5326177 },
    { lat: 48.7499954, lng: 18.5324664 },
    { lat: 48.7500326, lng: 18.5323857 },
    { lat: 48.7501261, lng: 18.5323836 },
    { lat: 48.7501804, lng: 18.5323651 },
    { lat: 48.7502525, lng: 18.5324258 },
    { lat: 48.7502582, lng: 18.5324444 },
    { lat: 48.7502523, lng: 18.532522 },
    { lat: 48.75031, lng: 18.5325476 },
    { lat: 48.7503502, lng: 18.5324962 },
    { lat: 48.7503891, lng: 18.5323859 },
    { lat: 48.7503671, lng: 18.532291 },
    { lat: 48.7502766, lng: 18.5321613 },
    { lat: 48.7502603, lng: 18.5321521 },
    { lat: 48.7501976, lng: 18.5320391 },
    { lat: 48.7501872, lng: 18.5317844 },
    { lat: 48.75024, lng: 18.5316774 },
    { lat: 48.7503538, lng: 18.5315921 },
    { lat: 48.7503967, lng: 18.5315949 },
    { lat: 48.7504679, lng: 18.5315139 },
    { lat: 48.7504641, lng: 18.5314428 },
    { lat: 48.7503999, lng: 18.5313018 },
    { lat: 48.7503629, lng: 18.5310966 },
    { lat: 48.7504043, lng: 18.5310099 },
    { lat: 48.7506464, lng: 18.5308986 },
    { lat: 48.7507131, lng: 18.5308398 },
    { lat: 48.7508191, lng: 18.530837 },
    { lat: 48.7508732, lng: 18.5307973 },
    { lat: 48.7508918, lng: 18.5307053 },
    { lat: 48.7509447, lng: 18.5306601 },
    { lat: 48.7510087, lng: 18.530656 },
    { lat: 48.7511907, lng: 18.5307845 },
    { lat: 48.7512513, lng: 18.5307609 },
    { lat: 48.7513442, lng: 18.5305394 },
    { lat: 48.7514553, lng: 18.5304884 },
    { lat: 48.7516061, lng: 18.5303148 },
    { lat: 48.7517047, lng: 18.5302428 },
    { lat: 48.7517683, lng: 18.5302516 },
    { lat: 48.751932, lng: 18.5300239 },
    { lat: 48.7521618, lng: 18.5298347 },
    { lat: 48.7523291, lng: 18.5297991 },
    { lat: 48.7524992, lng: 18.529858 },
    { lat: 48.7528605, lng: 18.5299244 },
    { lat: 48.7529098, lng: 18.5300448 },
    { lat: 48.7529023, lng: 18.5301762 },
    { lat: 48.75291, lng: 18.530191 },
    { lat: 48.7529983, lng: 18.5301542 },
    { lat: 48.7530501, lng: 18.5301545 },
    { lat: 48.7532778, lng: 18.5302741 },
    { lat: 48.7535102, lng: 18.5301103 },
    { lat: 48.7535709, lng: 18.5300445 },
    { lat: 48.7536276, lng: 18.5300692 },
    { lat: 48.7536377, lng: 18.5300897 },
    { lat: 48.753822, lng: 18.5302015 },
    { lat: 48.7539647, lng: 18.5300489 },
    { lat: 48.7541225, lng: 18.5297347 },
    { lat: 48.7541656, lng: 18.5297193 },
    { lat: 48.7542279, lng: 18.5298041 },
    { lat: 48.7542445, lng: 18.5298817 },
    { lat: 48.7542753, lng: 18.5299697 },
    { lat: 48.7543135, lng: 18.5300297 },
    { lat: 48.7544041, lng: 18.5300784 },
    { lat: 48.7544473, lng: 18.5301256 },
    { lat: 48.754516, lng: 18.5301241 },
    { lat: 48.7545368, lng: 18.5300497 },
    { lat: 48.7545625, lng: 18.5300268 },
    { lat: 48.7545931, lng: 18.5300512 },
    { lat: 48.754802, lng: 18.5300548 },
    { lat: 48.7548469, lng: 18.5300067 },
    { lat: 48.7548768, lng: 18.5298957 },
    { lat: 48.7553128, lng: 18.529699 },
    { lat: 48.7553972, lng: 18.529716 },
    { lat: 48.7554624, lng: 18.5298151 },
    { lat: 48.7557162, lng: 18.5299793 },
    { lat: 48.7558322, lng: 18.5300392 },
    { lat: 48.755918, lng: 18.5301421 },
    { lat: 48.7561825, lng: 18.5300087 },
    { lat: 48.7563194, lng: 18.5301389 },
    { lat: 48.7563778, lng: 18.5303811 },
    { lat: 48.7564338, lng: 18.5304166 },
    { lat: 48.7565452, lng: 18.5302479 },
    { lat: 48.7565857, lng: 18.5302028 },
    { lat: 48.7566651, lng: 18.530194 },
    { lat: 48.7567869, lng: 18.5302499 },
    { lat: 48.7568832, lng: 18.5302608 },
    { lat: 48.7569678, lng: 18.5301348 },
    { lat: 48.7569838, lng: 18.5300387 },
    { lat: 48.7570458, lng: 18.5299538 },
    { lat: 48.7571163, lng: 18.5299625 },
    { lat: 48.757193, lng: 18.5300695 },
    { lat: 48.7572082, lng: 18.5301391 },
    { lat: 48.7573742, lng: 18.53037 },
    { lat: 48.7574745, lng: 18.5303464 },
    { lat: 48.7575651, lng: 18.5304173 },
    { lat: 48.7576076, lng: 18.5305328 },
    { lat: 48.7576704, lng: 18.5305486 },
    { lat: 48.7577163, lng: 18.5304971 },
    { lat: 48.7577563, lng: 18.5303645 },
    { lat: 48.7578024, lng: 18.5303419 },
    { lat: 48.7580334, lng: 18.5304171 },
    { lat: 48.7582256, lng: 18.5303874 },
    { lat: 48.7582636, lng: 18.5301099 },
    { lat: 48.7583437, lng: 18.5300277 },
    { lat: 48.7584998, lng: 18.530015 },
    { lat: 48.7585889, lng: 18.5300156 },
    { lat: 48.7586295, lng: 18.5299105 },
    { lat: 48.7587228, lng: 18.529823 },
    { lat: 48.7588292, lng: 18.5296245 },
    { lat: 48.7590316, lng: 18.5296332 },
    { lat: 48.7590323, lng: 18.5295431 },
    { lat: 48.7590557, lng: 18.5295394 },
    { lat: 48.7590513, lng: 18.5294745 },
    { lat: 48.7593378, lng: 18.5294588 },
    { lat: 48.7593814, lng: 18.5294076 },
    { lat: 48.759375, lng: 18.5293133 },
    { lat: 48.759412, lng: 18.5292239 },
    { lat: 48.7596058, lng: 18.5292201 },
    { lat: 48.7596741, lng: 18.5292924 },
    { lat: 48.7597041, lng: 18.5294098 },
    { lat: 48.759787, lng: 18.5294853 },
    { lat: 48.7598773, lng: 18.5295291 },
    { lat: 48.7599748, lng: 18.5294543 },
    { lat: 48.7600826, lng: 18.5294065 },
    { lat: 48.7602377, lng: 18.5291866 },
    { lat: 48.7602518, lng: 18.5291369 },
    { lat: 48.760301, lng: 18.5290857 },
    { lat: 48.7604423, lng: 18.5291982 },
    { lat: 48.760443, lng: 18.5292267 },
    { lat: 48.7604304, lng: 18.5293091 },
    { lat: 48.760392, lng: 18.5294111 },
    { lat: 48.7603729, lng: 18.5295751 },
    { lat: 48.7604398, lng: 18.5296665 },
    { lat: 48.7606234, lng: 18.5297877 },
    { lat: 48.7607634, lng: 18.5299132 },
    { lat: 48.7608493, lng: 18.5299164 },
    { lat: 48.7610198, lng: 18.5296706 },
    { lat: 48.761062, lng: 18.5296768 },
    { lat: 48.761166, lng: 18.5297457 },
  ],
  Šútovce: [
    { lat: 48.7848752, lng: 18.5277607 },
    { lat: 48.7847118, lng: 18.5282185 },
    { lat: 48.7847579, lng: 18.5284006 },
    { lat: 48.7847668, lng: 18.5296825 },
    { lat: 48.7845464, lng: 18.5307892 },
    { lat: 48.7845007, lng: 18.5310263 },
    { lat: 48.7842021, lng: 18.5309506 },
    { lat: 48.78414, lng: 18.5313815 },
    { lat: 48.7838517, lng: 18.5320112 },
    { lat: 48.7837743, lng: 18.5325167 },
    { lat: 48.7837347, lng: 18.5331132 },
    { lat: 48.7839366, lng: 18.5332772 },
    { lat: 48.7841449, lng: 18.5333739 },
    { lat: 48.7842413, lng: 18.5345669 },
    { lat: 48.7837876, lng: 18.5350975 },
    { lat: 48.7837503, lng: 18.5357796 },
    { lat: 48.7836951, lng: 18.536857 },
    { lat: 48.7832557, lng: 18.5380464 },
    { lat: 48.7830701, lng: 18.5389258 },
    { lat: 48.7833039, lng: 18.5389954 },
    { lat: 48.7834672, lng: 18.5389911 },
    { lat: 48.7836846, lng: 18.5390888 },
    { lat: 48.7838431, lng: 18.5392429 },
    { lat: 48.7842336, lng: 18.53954 },
    { lat: 48.7844093, lng: 18.5395929 },
    { lat: 48.784706, lng: 18.5395112 },
    { lat: 48.7850123, lng: 18.5395241 },
    { lat: 48.7852199, lng: 18.5394216 },
    { lat: 48.7855174, lng: 18.5396235 },
    { lat: 48.7865754, lng: 18.5394979 },
    { lat: 48.7881375, lng: 18.5399519 },
    { lat: 48.789151, lng: 18.5409334 },
    { lat: 48.7899656, lng: 18.5428668 },
    { lat: 48.7901815, lng: 18.543394 },
    { lat: 48.790879, lng: 18.5440229 },
    { lat: 48.7915914, lng: 18.544471 },
    { lat: 48.7917799, lng: 18.5445946 },
    { lat: 48.7919264, lng: 18.544758 },
    { lat: 48.7923433, lng: 18.5452885 },
    { lat: 48.7927454, lng: 18.5457964 },
    { lat: 48.7931433, lng: 18.5460645 },
    { lat: 48.7934589, lng: 18.546243 },
    { lat: 48.7938933, lng: 18.5465992 },
    { lat: 48.794003, lng: 18.5467086 },
    { lat: 48.79408, lng: 18.5468149 },
    { lat: 48.7948559, lng: 18.5471046 },
    { lat: 48.7955739, lng: 18.5473257 },
    { lat: 48.7965556, lng: 18.5476015 },
    { lat: 48.797468, lng: 18.5486571 },
    { lat: 48.7986303, lng: 18.5494114 },
    { lat: 48.7996267, lng: 18.5494625 },
    { lat: 48.7996909, lng: 18.5494595 },
    { lat: 48.8001119, lng: 18.5498182 },
    { lat: 48.8007985, lng: 18.5499559 },
    { lat: 48.8011018, lng: 18.5499128 },
    { lat: 48.8011552, lng: 18.5498557 },
    { lat: 48.8011719, lng: 18.5499384 },
    { lat: 48.8014931, lng: 18.5499105 },
    { lat: 48.8018081, lng: 18.5498141 },
    { lat: 48.8019909, lng: 18.5496675 },
    { lat: 48.8025416, lng: 18.5496547 },
    { lat: 48.802794, lng: 18.54921 },
    { lat: 48.803308, lng: 18.5486264 },
    { lat: 48.8035507, lng: 18.548482 },
    { lat: 48.803975, lng: 18.5482381 },
    { lat: 48.8043087, lng: 18.5477771 },
    { lat: 48.8047162, lng: 18.5472028 },
    { lat: 48.804971, lng: 18.5469524 },
    { lat: 48.805186, lng: 18.5467975 },
    { lat: 48.8054537, lng: 18.5466829 },
    { lat: 48.8057509, lng: 18.5465242 },
    { lat: 48.806109, lng: 18.5462026 },
    { lat: 48.8064101, lng: 18.5459547 },
    { lat: 48.8066606, lng: 18.5457882 },
    { lat: 48.8074864, lng: 18.5456473 },
    { lat: 48.8077748, lng: 18.5458668 },
    { lat: 48.8080572, lng: 18.5458957 },
    { lat: 48.8082717, lng: 18.545962 },
    { lat: 48.8086495, lng: 18.546087 },
    { lat: 48.8088997, lng: 18.5463324 },
    { lat: 48.8090101, lng: 18.5463888 },
    { lat: 48.8092202, lng: 18.5465405 },
    { lat: 48.8096154, lng: 18.5464239 },
    { lat: 48.8096387, lng: 18.5464382 },
    { lat: 48.8098991, lng: 18.5466637 },
    { lat: 48.810109, lng: 18.54679 },
    { lat: 48.8103598, lng: 18.5467759 },
    { lat: 48.8111895, lng: 18.546704 },
    { lat: 48.8116143, lng: 18.5469445 },
    { lat: 48.8118922, lng: 18.5471174 },
    { lat: 48.8122716, lng: 18.5472913 },
    { lat: 48.8126528, lng: 18.547747 },
    { lat: 48.8127992, lng: 18.5478049 },
    { lat: 48.8129438, lng: 18.5479952 },
    { lat: 48.8135189, lng: 18.5483648 },
    { lat: 48.8139778, lng: 18.5488416 },
    { lat: 48.8143885, lng: 18.5490105 },
    { lat: 48.8146293, lng: 18.5491652 },
    { lat: 48.8151845, lng: 18.5492964 },
    { lat: 48.8153665, lng: 18.5496735 },
    { lat: 48.8154682, lng: 18.5497406 },
    { lat: 48.8158004, lng: 18.5497082 },
    { lat: 48.8161127, lng: 18.5496872 },
    { lat: 48.8163204, lng: 18.549691 },
    { lat: 48.8165295, lng: 18.5496764 },
    { lat: 48.8169191, lng: 18.5495158 },
    { lat: 48.817197, lng: 18.5493795 },
    { lat: 48.8171935, lng: 18.5492944 },
    { lat: 48.8172081, lng: 18.5492929 },
    { lat: 48.8181291, lng: 18.5491166 },
    { lat: 48.8185154, lng: 18.5489178 },
    { lat: 48.819194, lng: 18.5478757 },
    { lat: 48.8197687, lng: 18.5464762 },
    { lat: 48.8208131, lng: 18.5454575 },
    { lat: 48.8230329, lng: 18.54303 },
    { lat: 48.8230744, lng: 18.5428817 },
    { lat: 48.8230849, lng: 18.5425736 },
    { lat: 48.8231316, lng: 18.5424597 },
    { lat: 48.8231999, lng: 18.542182 },
    { lat: 48.8232775, lng: 18.5419267 },
    { lat: 48.8234213, lng: 18.5416886 },
    { lat: 48.8236849, lng: 18.5414556 },
    { lat: 48.8236737, lng: 18.5414359 },
    { lat: 48.8233924, lng: 18.540928 },
    { lat: 48.8224266, lng: 18.5395372 },
    { lat: 48.822432, lng: 18.5394897 },
    { lat: 48.8220948, lng: 18.5387038 },
    { lat: 48.8209832, lng: 18.5372247 },
    { lat: 48.8195307, lng: 18.5356343 },
    { lat: 48.8185842, lng: 18.5340369 },
    { lat: 48.8176251, lng: 18.5324763 },
    { lat: 48.8170367, lng: 18.5322141 },
    { lat: 48.8161716, lng: 18.5318455 },
    { lat: 48.8149951, lng: 18.5313497 },
    { lat: 48.8148495, lng: 18.5312237 },
    { lat: 48.8147837, lng: 18.531214 },
    { lat: 48.8146657, lng: 18.5311221 },
    { lat: 48.8145983, lng: 18.5310421 },
    { lat: 48.814363, lng: 18.5307405 },
    { lat: 48.8142758, lng: 18.5306508 },
    { lat: 48.814237, lng: 18.5305809 },
    { lat: 48.8141314, lng: 18.5303163 },
    { lat: 48.8140614, lng: 18.530174 },
    { lat: 48.8139854, lng: 18.5298321 },
    { lat: 48.8139422, lng: 18.5297645 },
    { lat: 48.8138978, lng: 18.5297171 },
    { lat: 48.8138037, lng: 18.5296472 },
    { lat: 48.8137643, lng: 18.5295849 },
    { lat: 48.8136882, lng: 18.529433 },
    { lat: 48.8136545, lng: 18.5292053 },
    { lat: 48.8136458, lng: 18.5290056 },
    { lat: 48.8136572, lng: 18.528881 },
    { lat: 48.8136139, lng: 18.5285636 },
    { lat: 48.8134577, lng: 18.528309 },
    { lat: 48.813185, lng: 18.5278771 },
    { lat: 48.8130851, lng: 18.5276398 },
    { lat: 48.8129858, lng: 18.5275352 },
    { lat: 48.812834, lng: 18.5274608 },
    { lat: 48.8126774, lng: 18.5273411 },
    { lat: 48.812381, lng: 18.5271767 },
    { lat: 48.8120653, lng: 18.5268096 },
    { lat: 48.8120248, lng: 18.5267921 },
    { lat: 48.8118157, lng: 18.5267628 },
    { lat: 48.8117902, lng: 18.5267503 },
    { lat: 48.8117212, lng: 18.5266426 },
    { lat: 48.8116497, lng: 18.5264704 },
    { lat: 48.8116303, lng: 18.5262159 },
    { lat: 48.8115913, lng: 18.5261234 },
    { lat: 48.8114895, lng: 18.5259916 },
    { lat: 48.8113788, lng: 18.5258043 },
    { lat: 48.8112907, lng: 18.5257015 },
    { lat: 48.8111525, lng: 18.5254371 },
    { lat: 48.8110712, lng: 18.5253124 },
    { lat: 48.8109757, lng: 18.5252377 },
    { lat: 48.8108631, lng: 18.5250979 },
    { lat: 48.8106557, lng: 18.5249532 },
    { lat: 48.8105469, lng: 18.5248433 },
    { lat: 48.8103794, lng: 18.5246889 },
    { lat: 48.8103646, lng: 18.5246865 },
    { lat: 48.8103302, lng: 18.5246539 },
    { lat: 48.8098114, lng: 18.5240958 },
    { lat: 48.8097986, lng: 18.5240694 },
    { lat: 48.8096989, lng: 18.52404 },
    { lat: 48.809523, lng: 18.5238359 },
    { lat: 48.8093214, lng: 18.5237587 },
    { lat: 48.809127, lng: 18.5236471 },
    { lat: 48.8089579, lng: 18.5235892 },
    { lat: 48.8085272, lng: 18.5234892 },
    { lat: 48.8084517, lng: 18.5234263 },
    { lat: 48.8083894, lng: 18.5234237 },
    { lat: 48.8081921, lng: 18.5233436 },
    { lat: 48.8079421, lng: 18.5233061 },
    { lat: 48.8078184, lng: 18.523171 },
    { lat: 48.8077549, lng: 18.5231857 },
    { lat: 48.8076774, lng: 18.5230855 },
    { lat: 48.8075011, lng: 18.5230207 },
    { lat: 48.8073773, lng: 18.5230655 },
    { lat: 48.8072317, lng: 18.5229831 },
    { lat: 48.8070962, lng: 18.523043 },
    { lat: 48.8070542, lng: 18.5229327 },
    { lat: 48.8070145, lng: 18.5228675 },
    { lat: 48.8070061, lng: 18.5228775 },
    { lat: 48.8064969, lng: 18.5229323 },
    { lat: 48.8064605, lng: 18.5229251 },
    { lat: 48.8062926, lng: 18.5228448 },
    { lat: 48.8061428, lng: 18.5227243 },
    { lat: 48.8058635, lng: 18.5227743 },
    { lat: 48.8055966, lng: 18.5228693 },
    { lat: 48.8055437, lng: 18.5227864 },
    { lat: 48.8053649, lng: 18.5227941 },
    { lat: 48.8053029, lng: 18.5229014 },
    { lat: 48.8052471, lng: 18.5229087 },
    { lat: 48.8051772, lng: 18.5228287 },
    { lat: 48.8050229, lng: 18.5228112 },
    { lat: 48.8049668, lng: 18.5228586 },
    { lat: 48.8048489, lng: 18.5228508 },
    { lat: 48.8046533, lng: 18.5228832 },
    { lat: 48.8043868, lng: 18.5228328 },
    { lat: 48.8042335, lng: 18.5228176 },
    { lat: 48.804148, lng: 18.52278 },
    { lat: 48.8039967, lng: 18.5227578 },
    { lat: 48.8035886, lng: 18.5226772 },
    { lat: 48.8034296, lng: 18.5227822 },
    { lat: 48.8033107, lng: 18.522752 },
    { lat: 48.8031381, lng: 18.5228992 },
    { lat: 48.8029095, lng: 18.5230491 },
    { lat: 48.8027854, lng: 18.5229666 },
    { lat: 48.8027439, lng: 18.5229088 },
    { lat: 48.8026917, lng: 18.5228613 },
    { lat: 48.8025832, lng: 18.5227962 },
    { lat: 48.8024474, lng: 18.5228511 },
    { lat: 48.802409, lng: 18.5228484 },
    { lat: 48.8022919, lng: 18.5227907 },
    { lat: 48.8020583, lng: 18.5225806 },
    { lat: 48.801992, lng: 18.5225005 },
    { lat: 48.8018047, lng: 18.5222527 },
    { lat: 48.8017274, lng: 18.5221178 },
    { lat: 48.8016357, lng: 18.5219279 },
    { lat: 48.8015695, lng: 18.5218503 },
    { lat: 48.8014562, lng: 18.5218401 },
    { lat: 48.8014048, lng: 18.5217904 },
    { lat: 48.8013793, lng: 18.52163 },
    { lat: 48.8013201, lng: 18.5215602 },
    { lat: 48.8011831, lng: 18.5216124 },
    { lat: 48.8011704, lng: 18.5215875 },
    { lat: 48.8011651, lng: 18.5214472 },
    { lat: 48.801216, lng: 18.521307 },
    { lat: 48.8011733, lng: 18.521225 },
    { lat: 48.8011025, lng: 18.5212321 },
    { lat: 48.8010579, lng: 18.5212198 },
    { lat: 48.8010565, lng: 18.5209874 },
    { lat: 48.8008217, lng: 18.5208597 },
    { lat: 48.8008398, lng: 18.5207798 },
    { lat: 48.8006557, lng: 18.5206289 },
    { lat: 48.8004648, lng: 18.5204414 },
    { lat: 48.800298, lng: 18.5202991 },
    { lat: 48.8002735, lng: 18.5201791 },
    { lat: 48.8002426, lng: 18.5201285 },
    { lat: 48.8001049, lng: 18.5201687 },
    { lat: 48.8000532, lng: 18.5201111 },
    { lat: 48.8000308, lng: 18.5200284 },
    { lat: 48.7998367, lng: 18.5197808 },
    { lat: 48.7996657, lng: 18.5196884 },
    { lat: 48.7996064, lng: 18.5195505 },
    { lat: 48.7994979, lng: 18.5195083 },
    { lat: 48.7994584, lng: 18.5194632 },
    { lat: 48.7994275, lng: 18.5193955 },
    { lat: 48.7994649, lng: 18.5192927 },
    { lat: 48.7993827, lng: 18.5192154 },
    { lat: 48.799258, lng: 18.5192052 },
    { lat: 48.7991274, lng: 18.5190648 },
    { lat: 48.7989023, lng: 18.5189671 },
    { lat: 48.7988488, lng: 18.5188146 },
    { lat: 48.7987968, lng: 18.5187721 },
    { lat: 48.7987091, lng: 18.518612 },
    { lat: 48.7985411, lng: 18.5186119 },
    { lat: 48.7984211, lng: 18.518462 },
    { lat: 48.7984095, lng: 18.5183318 },
    { lat: 48.7982658, lng: 18.5181992 },
    { lat: 48.7981788, lng: 18.518199 },
    { lat: 48.7980839, lng: 18.518174 },
    { lat: 48.7980446, lng: 18.5181338 },
    { lat: 48.7980216, lng: 18.5181464 },
    { lat: 48.7979903, lng: 18.5181313 },
    { lat: 48.7978526, lng: 18.5179861 },
    { lat: 48.7977779, lng: 18.517939 },
    { lat: 48.797701, lng: 18.5179586 },
    { lat: 48.7976927, lng: 18.5178459 },
    { lat: 48.7976633, lng: 18.5177236 },
    { lat: 48.7976003, lng: 18.5176209 },
    { lat: 48.797507, lng: 18.5176258 },
    { lat: 48.7974735, lng: 18.5175932 },
    { lat: 48.797367, lng: 18.5176033 },
    { lat: 48.7972747, lng: 18.5175454 },
    { lat: 48.7971455, lng: 18.5174951 },
    { lat: 48.7970882, lng: 18.517333 },
    { lat: 48.7970563, lng: 18.5171828 },
    { lat: 48.7969239, lng: 18.5170899 },
    { lat: 48.7968806, lng: 18.5171468 },
    { lat: 48.7967932, lng: 18.51722 },
    { lat: 48.7967029, lng: 18.5171773 },
    { lat: 48.7966346, lng: 18.5170825 },
    { lat: 48.7964892, lng: 18.5172069 },
    { lat: 48.7963755, lng: 18.517277 },
    { lat: 48.796336, lng: 18.5172118 },
    { lat: 48.7962736, lng: 18.5171642 },
    { lat: 48.7961291, lng: 18.517184 },
    { lat: 48.796038, lng: 18.5170659 },
    { lat: 48.7957709, lng: 18.5169445 },
    { lat: 48.7957602, lng: 18.5169941 },
    { lat: 48.7956833, lng: 18.5170535 },
    { lat: 48.795641, lng: 18.5170019 },
    { lat: 48.7954998, lng: 18.5170185 },
    { lat: 48.7954246, lng: 18.5170909 },
    { lat: 48.7952402, lng: 18.517159 },
    { lat: 48.7950815, lng: 18.5170458 },
    { lat: 48.7949625, lng: 18.5168883 },
    { lat: 48.7949443, lng: 18.5168881 },
    { lat: 48.7949261, lng: 18.5169436 },
    { lat: 48.794603, lng: 18.516958 },
    { lat: 48.7945023, lng: 18.517138 },
    { lat: 48.7943513, lng: 18.5171391 },
    { lat: 48.7942644, lng: 18.5171807 },
    { lat: 48.7942103, lng: 18.5172834 },
    { lat: 48.7939925, lng: 18.5172616 },
    { lat: 48.7938785, lng: 18.5173027 },
    { lat: 48.7938128, lng: 18.5174637 },
    { lat: 48.7936244, lng: 18.5174626 },
    { lat: 48.7935399, lng: 18.5175261 },
    { lat: 48.7934852, lng: 18.5175376 },
    { lat: 48.7933755, lng: 18.5176365 },
    { lat: 48.7933459, lng: 18.5177121 },
    { lat: 48.7932064, lng: 18.5178194 },
    { lat: 48.7931224, lng: 18.5178313 },
    { lat: 48.7928582, lng: 18.5179921 },
    { lat: 48.7927497, lng: 18.5179274 },
    { lat: 48.7926991, lng: 18.5178496 },
    { lat: 48.7926046, lng: 18.5178718 },
    { lat: 48.7924964, lng: 18.5178339 },
    { lat: 48.7924098, lng: 18.5179023 },
    { lat: 48.7921666, lng: 18.5179285 },
    { lat: 48.7921277, lng: 18.517938 },
    { lat: 48.7920115, lng: 18.5179182 },
    { lat: 48.7919368, lng: 18.517953 },
    { lat: 48.7918823, lng: 18.5179075 },
    { lat: 48.7918181, lng: 18.5179313 },
    { lat: 48.7917888, lng: 18.5179314 },
    { lat: 48.7917177, lng: 18.5178452 },
    { lat: 48.7916826, lng: 18.5178263 },
    { lat: 48.7916531, lng: 18.5177785 },
    { lat: 48.7915479, lng: 18.5175677 },
    { lat: 48.7913815, lng: 18.5176619 },
    { lat: 48.7911967, lng: 18.517602 },
    { lat: 48.7911763, lng: 18.5176182 },
    { lat: 48.7911213, lng: 18.5177274 },
    { lat: 48.7911042, lng: 18.5178086 },
    { lat: 48.7910877, lng: 18.5178349 },
    { lat: 48.7910145, lng: 18.5178201 },
    { lat: 48.7907868, lng: 18.5179223 },
    { lat: 48.7907836, lng: 18.51801 },
    { lat: 48.7908186, lng: 18.518114 },
    { lat: 48.7908131, lng: 18.5181569 },
    { lat: 48.790687, lng: 18.5183484 },
    { lat: 48.7906002, lng: 18.5183703 },
    { lat: 48.7905829, lng: 18.5183425 },
    { lat: 48.7905834, lng: 18.5182057 },
    { lat: 48.7905384, lng: 18.5181856 },
    { lat: 48.7904667, lng: 18.51822 },
    { lat: 48.7904714, lng: 18.5183053 },
    { lat: 48.7903792, lng: 18.5184531 },
    { lat: 48.7902817, lng: 18.5183633 },
    { lat: 48.7902151, lng: 18.518465 },
    { lat: 48.7901231, lng: 18.5184937 },
    { lat: 48.7901338, lng: 18.5185851 },
    { lat: 48.7899379, lng: 18.5185946 },
    { lat: 48.789931, lng: 18.5184033 },
    { lat: 48.7899138, lng: 18.518379 },
    { lat: 48.7898727, lng: 18.5183716 },
    { lat: 48.7898516, lng: 18.5183789 },
    { lat: 48.7898007, lng: 18.5185377 },
    { lat: 48.7897055, lng: 18.5184906 },
    { lat: 48.7895327, lng: 18.5185083 },
    { lat: 48.7894755, lng: 18.5186381 },
    { lat: 48.7893986, lng: 18.5187146 },
    { lat: 48.7893395, lng: 18.5186881 },
    { lat: 48.7893265, lng: 18.5185931 },
    { lat: 48.7890887, lng: 18.5187001 },
    { lat: 48.7889226, lng: 18.5187356 },
    { lat: 48.7887457, lng: 18.5187395 },
    { lat: 48.7885346, lng: 18.5186947 },
    { lat: 48.7884538, lng: 18.5186401 },
    { lat: 48.7881778, lng: 18.5186292 },
    { lat: 48.7881725, lng: 18.5184056 },
    { lat: 48.7880568, lng: 18.5184161 },
    { lat: 48.7877448, lng: 18.5182563 },
    { lat: 48.7876451, lng: 18.5182094 },
    { lat: 48.7875964, lng: 18.5182874 },
    { lat: 48.78753, lng: 18.5184577 },
    { lat: 48.7875379, lng: 18.5185798 },
    { lat: 48.7875017, lng: 18.5186401 },
    { lat: 48.787482, lng: 18.5187556 },
    { lat: 48.7874121, lng: 18.5188553 },
    { lat: 48.787356, lng: 18.5188626 },
    { lat: 48.7865436, lng: 18.5190008 },
    { lat: 48.7863648, lng: 18.5191763 },
    { lat: 48.7863228, lng: 18.5193597 },
    { lat: 48.7862972, lng: 18.5193352 },
    { lat: 48.7862298, lng: 18.5194146 },
    { lat: 48.7862037, lng: 18.51943 },
    { lat: 48.786097, lng: 18.5194591 },
    { lat: 48.785968, lng: 18.5195157 },
    { lat: 48.7859023, lng: 18.5195067 },
    { lat: 48.7858169, lng: 18.5195146 },
    { lat: 48.7857238, lng: 18.5194858 },
    { lat: 48.7856422, lng: 18.5194756 },
    { lat: 48.7855597, lng: 18.5195357 },
    { lat: 48.7855312, lng: 18.5196759 },
    { lat: 48.7853747, lng: 18.5198872 },
    { lat: 48.7853073, lng: 18.5201381 },
    { lat: 48.7852814, lng: 18.5201607 },
    { lat: 48.7851998, lng: 18.5201727 },
    { lat: 48.7851379, lng: 18.5202176 },
    { lat: 48.7849563, lng: 18.5204664 },
    { lat: 48.7849661, lng: 18.5205421 },
    { lat: 48.7849474, lng: 18.5206553 },
    { lat: 48.7849383, lng: 18.520674 },
    { lat: 48.7848533, lng: 18.5207272 },
    { lat: 48.7847659, lng: 18.5208404 },
    { lat: 48.7846947, lng: 18.5208404 },
    { lat: 48.7845465, lng: 18.5209959 },
    { lat: 48.7845009, lng: 18.5210914 },
    { lat: 48.7844908, lng: 18.5212969 },
    { lat: 48.7845113, lng: 18.5213645 },
    { lat: 48.7844047, lng: 18.5215592 },
    { lat: 48.7843315, lng: 18.5216037 },
    { lat: 48.7842238, lng: 18.5216966 },
    { lat: 48.784053, lng: 18.5219351 },
    { lat: 48.7839677, lng: 18.5220055 },
    { lat: 48.7839079, lng: 18.5220266 },
    { lat: 48.7838765, lng: 18.5220115 },
    { lat: 48.7837955, lng: 18.521929 },
    { lat: 48.7837647, lng: 18.5220506 },
    { lat: 48.7836795, lng: 18.5221607 },
    { lat: 48.7836548, lng: 18.5223054 },
    { lat: 48.7837066, lng: 18.5224484 },
    { lat: 48.7836886, lng: 18.5225354 },
    { lat: 48.7835985, lng: 18.5225557 },
    { lat: 48.7836196, lng: 18.5226943 },
    { lat: 48.783607, lng: 18.5227535 },
    { lat: 48.7834649, lng: 18.5228567 },
    { lat: 48.7834155, lng: 18.5228407 },
    { lat: 48.7834094, lng: 18.522771 },
    { lat: 48.7832967, lng: 18.5226478 },
    { lat: 48.7832671, lng: 18.5226844 },
    { lat: 48.7832813, lng: 18.5228028 },
    { lat: 48.7831544, lng: 18.5230372 },
    { lat: 48.7831758, lng: 18.5230989 },
    { lat: 48.7832867, lng: 18.5231265 },
    { lat: 48.7832942, lng: 18.5231599 },
    { lat: 48.7832195, lng: 18.5232376 },
    { lat: 48.783196, lng: 18.5235524 },
    { lat: 48.7831268, lng: 18.5237092 },
    { lat: 48.7831015, lng: 18.5237424 },
    { lat: 48.7830862, lng: 18.5237536 },
    { lat: 48.7830202, lng: 18.5237601 },
    { lat: 48.7829939, lng: 18.5238807 },
    { lat: 48.7829155, lng: 18.5239953 },
    { lat: 48.7828243, lng: 18.5242628 },
    { lat: 48.7827175, lng: 18.5246402 },
    { lat: 48.7828188, lng: 18.5247005 },
    { lat: 48.7828807, lng: 18.5247164 },
    { lat: 48.7829931, lng: 18.5248147 },
    { lat: 48.7830854, lng: 18.524811 },
    { lat: 48.7832172, lng: 18.5247888 },
    { lat: 48.7832673, lng: 18.5247961 },
    { lat: 48.7833, lng: 18.5248382 },
    { lat: 48.7833339, lng: 18.5249205 },
    { lat: 48.7833433, lng: 18.5250724 },
    { lat: 48.7833722, lng: 18.5251464 },
    { lat: 48.783482, lng: 18.5251967 },
    { lat: 48.7836057, lng: 18.5251888 },
    { lat: 48.7836205, lng: 18.5253947 },
    { lat: 48.7837674, lng: 18.5255277 },
    { lat: 48.7837501, lng: 18.5258299 },
    { lat: 48.783683, lng: 18.5259206 },
    { lat: 48.7836876, lng: 18.5260051 },
    { lat: 48.7837325, lng: 18.526105 },
    { lat: 48.7838105, lng: 18.5261085 },
    { lat: 48.7839622, lng: 18.5263467 },
    { lat: 48.7840161, lng: 18.5264466 },
    { lat: 48.7841234, lng: 18.5264893 },
    { lat: 48.7842531, lng: 18.5264881 },
    { lat: 48.7843007, lng: 18.5266811 },
    { lat: 48.7844505, lng: 18.5266749 },
    { lat: 48.7844701, lng: 18.5267042 },
    { lat: 48.7844793, lng: 18.5267471 },
    { lat: 48.7844709, lng: 18.5268833 },
    { lat: 48.7843873, lng: 18.5271921 },
    { lat: 48.7843878, lng: 18.5272622 },
    { lat: 48.7844424, lng: 18.5272929 },
    { lat: 48.7845489, lng: 18.5272785 },
    { lat: 48.7846289, lng: 18.5273586 },
    { lat: 48.7847071, lng: 18.5275524 },
    { lat: 48.7848349, lng: 18.5276845 },
    { lat: 48.7848752, lng: 18.5277607 },
  ],
  ChrenovecBrusno: [
    { lat: 48.7523935, lng: 18.7032939 },
    { lat: 48.7517463, lng: 18.7024045 },
    { lat: 48.7514212, lng: 18.7020167 },
    { lat: 48.7497445, lng: 18.7018096 },
    { lat: 48.7495328, lng: 18.7014972 },
    { lat: 48.7494515, lng: 18.7014748 },
    { lat: 48.7493805, lng: 18.7014553 },
    { lat: 48.7491803, lng: 18.7014003 },
    { lat: 48.7482202, lng: 18.7013932 },
    { lat: 48.7466139, lng: 18.7023001 },
    { lat: 48.7460427, lng: 18.7030989 },
    { lat: 48.74538, lng: 18.704311 },
    { lat: 48.7447737, lng: 18.7060234 },
    { lat: 48.7445687, lng: 18.7067297 },
    { lat: 48.7445418, lng: 18.7068369 },
    { lat: 48.7447042, lng: 18.7069855 },
    { lat: 48.7448285, lng: 18.7070112 },
    { lat: 48.744966, lng: 18.7071571 },
    { lat: 48.7452957, lng: 18.7073358 },
    { lat: 48.745515, lng: 18.7076841 },
    { lat: 48.7455798, lng: 18.7079742 },
    { lat: 48.745684, lng: 18.7081292 },
    { lat: 48.7456967, lng: 18.7083249 },
    { lat: 48.7458073, lng: 18.7087303 },
    { lat: 48.7459785, lng: 18.7089822 },
    { lat: 48.7460618, lng: 18.7091933 },
    { lat: 48.7461191, lng: 18.7095923 },
    { lat: 48.7459836, lng: 18.7101647 },
    { lat: 48.7461239, lng: 18.7105565 },
    { lat: 48.7463306, lng: 18.7114949 },
    { lat: 48.7463499, lng: 18.7117113 },
    { lat: 48.7464216, lng: 18.7120428 },
    { lat: 48.7465693, lng: 18.7123425 },
    { lat: 48.746725, lng: 18.7124255 },
    { lat: 48.7468699, lng: 18.7127968 },
    { lat: 48.7469631, lng: 18.7134094 },
    { lat: 48.7470208, lng: 18.7135168 },
    { lat: 48.7471366, lng: 18.713873 },
    { lat: 48.7472313, lng: 18.7139805 },
    { lat: 48.7472522, lng: 18.714137 },
    { lat: 48.7473705, lng: 18.7142434 },
    { lat: 48.7474513, lng: 18.7145149 },
    { lat: 48.7475398, lng: 18.7147136 },
    { lat: 48.7477338, lng: 18.715056 },
    { lat: 48.7481347, lng: 18.7158747 },
    { lat: 48.7482348, lng: 18.7160191 },
    { lat: 48.7483531, lng: 18.7163114 },
    { lat: 48.7483727, lng: 18.7164277 },
    { lat: 48.7485409, lng: 18.7164767 },
    { lat: 48.7486501, lng: 18.7166185 },
    { lat: 48.7486949, lng: 18.7168043 },
    { lat: 48.7487509, lng: 18.7168854 },
    { lat: 48.7488452, lng: 18.7169601 },
    { lat: 48.7488337, lng: 18.7171277 },
    { lat: 48.7488762, lng: 18.7172462 },
    { lat: 48.7488725, lng: 18.717306 },
    { lat: 48.7488343, lng: 18.7173699 },
    { lat: 48.7488406, lng: 18.7174499 },
    { lat: 48.7489249, lng: 18.7176577 },
    { lat: 48.7492276, lng: 18.7179838 },
    { lat: 48.7493448, lng: 18.7182365 },
    { lat: 48.7494282, lng: 18.7182818 },
    { lat: 48.749491, lng: 18.7183782 },
    { lat: 48.7495376, lng: 18.7185981 },
    { lat: 48.7495546, lng: 18.7187279 },
    { lat: 48.749671, lng: 18.7188632 },
    { lat: 48.7497537, lng: 18.7190379 },
    { lat: 48.7498347, lng: 18.7190539 },
    { lat: 48.7500082, lng: 18.7191572 },
    { lat: 48.7500148, lng: 18.7192815 },
    { lat: 48.7500415, lng: 18.7194066 },
    { lat: 48.7503228, lng: 18.719444 },
    { lat: 48.750473, lng: 18.7195902 },
    { lat: 48.7505202, lng: 18.7196685 },
    { lat: 48.7505578, lng: 18.7198083 },
    { lat: 48.7506392, lng: 18.7200425 },
    { lat: 48.7506634, lng: 18.7201593 },
    { lat: 48.7506564, lng: 18.7203046 },
    { lat: 48.7507798, lng: 18.7204608 },
    { lat: 48.7508011, lng: 18.7205876 },
    { lat: 48.7508448, lng: 18.7206266 },
    { lat: 48.7508533, lng: 18.7208286 },
    { lat: 48.7508844, lng: 18.7211207 },
    { lat: 48.7508203, lng: 18.721379 },
    { lat: 48.7508015, lng: 18.7217236 },
    { lat: 48.7507358, lng: 18.7221894 },
    { lat: 48.7508493, lng: 18.7223951 },
    { lat: 48.7509245, lng: 18.7224317 },
    { lat: 48.751044, lng: 18.7226235 },
    { lat: 48.7511825, lng: 18.7227029 },
    { lat: 48.7512309, lng: 18.7229129 },
    { lat: 48.7513782, lng: 18.7229047 },
    { lat: 48.7515697, lng: 18.723032 },
    { lat: 48.7518154, lng: 18.7234947 },
    { lat: 48.7518774, lng: 18.7236438 },
    { lat: 48.7519804, lng: 18.7237153 },
    { lat: 48.7520549, lng: 18.7237159 },
    { lat: 48.7522378, lng: 18.7238904 },
    { lat: 48.7523222, lng: 18.7239091 },
    { lat: 48.7524919, lng: 18.723726 },
    { lat: 48.7527125, lng: 18.7239959 },
    { lat: 48.7528463, lng: 18.7239022 },
    { lat: 48.7530152, lng: 18.7239829 },
    { lat: 48.7531212, lng: 18.7239393 },
    { lat: 48.7532421, lng: 18.7237717 },
    { lat: 48.7534316, lng: 18.723692 },
    { lat: 48.753565, lng: 18.723698 },
    { lat: 48.7536633, lng: 18.7235957 },
    { lat: 48.7537651, lng: 18.7235766 },
    { lat: 48.7538715, lng: 18.723712 },
    { lat: 48.7541806, lng: 18.7239259 },
    { lat: 48.7543758, lng: 18.7234398 },
    { lat: 48.7544235, lng: 18.7232782 },
    { lat: 48.7544804, lng: 18.7232399 },
    { lat: 48.7545248, lng: 18.7231248 },
    { lat: 48.7550989, lng: 18.72308 },
    { lat: 48.7555927, lng: 18.7233962 },
    { lat: 48.755769, lng: 18.7236187 },
    { lat: 48.7559038, lng: 18.7238858 },
    { lat: 48.7570094, lng: 18.7268587 },
    { lat: 48.7573096, lng: 18.7278218 },
    { lat: 48.7575964, lng: 18.728661 },
    { lat: 48.7578061, lng: 18.7289791 },
    { lat: 48.7579624, lng: 18.7291815 },
    { lat: 48.7581717, lng: 18.729391 },
    { lat: 48.7585948, lng: 18.7297548 },
    { lat: 48.759118, lng: 18.7301791 },
    { lat: 48.7593031, lng: 18.7303333 },
    { lat: 48.7595568, lng: 18.7305018 },
    { lat: 48.7599936, lng: 18.7307675 },
    { lat: 48.7603373, lng: 18.7309114 },
    { lat: 48.7604677, lng: 18.7309868 },
    { lat: 48.7608862, lng: 18.7311614 },
    { lat: 48.7615585, lng: 18.7315056 },
    { lat: 48.7620175, lng: 18.7318462 },
    { lat: 48.7627634, lng: 18.7323265 },
    { lat: 48.7629197, lng: 18.7325515 },
    { lat: 48.7634762, lng: 18.7330969 },
    { lat: 48.7638908, lng: 18.7334314 },
    { lat: 48.7643763, lng: 18.7336749 },
    { lat: 48.7646885, lng: 18.7337608 },
    { lat: 48.7648314, lng: 18.733839 },
    { lat: 48.7651079, lng: 18.7339686 },
    { lat: 48.7653374, lng: 18.7341493 },
    { lat: 48.7655684, lng: 18.7343402 },
    { lat: 48.7662134, lng: 18.7347663 },
    { lat: 48.7664253, lng: 18.7348726 },
    { lat: 48.7669032, lng: 18.7349493 },
    { lat: 48.7669796, lng: 18.7349687 },
    { lat: 48.7672815, lng: 18.7350888 },
    { lat: 48.7674518, lng: 18.7351973 },
    { lat: 48.7676713, lng: 18.7352966 },
    { lat: 48.7678561, lng: 18.7353147 },
    { lat: 48.7684063, lng: 18.7356562 },
    { lat: 48.7685881, lng: 18.7357232 },
    { lat: 48.7687672, lng: 18.7358661 },
    { lat: 48.7690383, lng: 18.7357475 },
    { lat: 48.7693356, lng: 18.735545 },
    { lat: 48.7693875, lng: 18.7354633 },
    { lat: 48.7698497, lng: 18.7352874 },
    { lat: 48.7698925, lng: 18.7352035 },
    { lat: 48.7701517, lng: 18.7351354 },
    { lat: 48.7703546, lng: 18.735088 },
    { lat: 48.7704999, lng: 18.7351055 },
    { lat: 48.7707319, lng: 18.7353785 },
    { lat: 48.7709834, lng: 18.7354293 },
    { lat: 48.7711844, lng: 18.7355155 },
    { lat: 48.7713376, lng: 18.7355345 },
    { lat: 48.7713891, lng: 18.7355923 },
    { lat: 48.7714325, lng: 18.7355831 },
    { lat: 48.7715783, lng: 18.7358247 },
    { lat: 48.771656, lng: 18.7359047 },
    { lat: 48.7717352, lng: 18.7360614 },
    { lat: 48.7717984, lng: 18.7362114 },
    { lat: 48.7724033, lng: 18.7365592 },
    { lat: 48.7727303, lng: 18.7367721 },
    { lat: 48.773114, lng: 18.7369289 },
    { lat: 48.7736796, lng: 18.7366618 },
    { lat: 48.7738196, lng: 18.7364296 },
    { lat: 48.774283, lng: 18.7365778 },
    { lat: 48.774337, lng: 18.7365981 },
    { lat: 48.7744205, lng: 18.7363217 },
    { lat: 48.7745153, lng: 18.7359784 },
    { lat: 48.7745774, lng: 18.7356817 },
    { lat: 48.7746467, lng: 18.7351595 },
    { lat: 48.7747079, lng: 18.7346079 },
    { lat: 48.7748726, lng: 18.7347157 },
    { lat: 48.774969, lng: 18.7348313 },
    { lat: 48.7750004, lng: 18.7349155 },
    { lat: 48.7750914, lng: 18.7349742 },
    { lat: 48.7751533, lng: 18.7349914 },
    { lat: 48.7751945, lng: 18.7350469 },
    { lat: 48.775262, lng: 18.7350852 },
    { lat: 48.775439, lng: 18.7351308 },
    { lat: 48.7754892, lng: 18.7351421 },
    { lat: 48.7755382, lng: 18.7351948 },
    { lat: 48.7756149, lng: 18.7352521 },
    { lat: 48.7756732, lng: 18.7352259 },
    { lat: 48.7757303, lng: 18.7352642 },
    { lat: 48.7757629, lng: 18.7351967 },
    { lat: 48.7758334, lng: 18.735161 },
    { lat: 48.7758848, lng: 18.7352184 },
    { lat: 48.7760359, lng: 18.7352409 },
    { lat: 48.7760674, lng: 18.7352782 },
    { lat: 48.776091, lng: 18.7354488 },
    { lat: 48.776075, lng: 18.7356602 },
    { lat: 48.7761165, lng: 18.7357843 },
    { lat: 48.7761918, lng: 18.7358453 },
    { lat: 48.7762878, lng: 18.7357379 },
    { lat: 48.776342, lng: 18.7355933 },
    { lat: 48.7763752, lng: 18.7355997 },
    { lat: 48.7764349, lng: 18.7358982 },
    { lat: 48.7764849, lng: 18.7359275 },
    { lat: 48.7765495, lng: 18.7358475 },
    { lat: 48.7765824, lng: 18.7356383 },
    { lat: 48.7766336, lng: 18.7356021 },
    { lat: 48.776811, lng: 18.7356929 },
    { lat: 48.7769167, lng: 18.7357091 },
    { lat: 48.777103, lng: 18.7360363 },
    { lat: 48.7771924, lng: 18.7360831 },
    { lat: 48.7772489, lng: 18.7361292 },
    { lat: 48.7773763, lng: 18.7364063 },
    { lat: 48.7774482, lng: 18.7363983 },
    { lat: 48.7775366, lng: 18.7364523 },
    { lat: 48.7776057, lng: 18.7364375 },
    { lat: 48.7776693, lng: 18.7364874 },
    { lat: 48.7777089, lng: 18.7366593 },
    { lat: 48.777762, lng: 18.7366407 },
    { lat: 48.7778131, lng: 18.7366675 },
    { lat: 48.7779448, lng: 18.7368804 },
    { lat: 48.7780898, lng: 18.7370395 },
    { lat: 48.7782074, lng: 18.7372177 },
    { lat: 48.7782534, lng: 18.7372161 },
    { lat: 48.7783267, lng: 18.7373198 },
    { lat: 48.7783981, lng: 18.7373466 },
    { lat: 48.7784006, lng: 18.7373935 },
    { lat: 48.7785004, lng: 18.7374279 },
    { lat: 48.7785702, lng: 18.7374245 },
    { lat: 48.7786437, lng: 18.7374732 },
    { lat: 48.7786774, lng: 18.7376419 },
    { lat: 48.77875, lng: 18.7377328 },
    { lat: 48.7788583, lng: 18.7377781 },
    { lat: 48.7789491, lng: 18.7379187 },
    { lat: 48.7790698, lng: 18.7379395 },
    { lat: 48.7791913, lng: 18.7381462 },
    { lat: 48.7792732, lng: 18.7384184 },
    { lat: 48.7794013, lng: 18.7383865 },
    { lat: 48.7795021, lng: 18.7385467 },
    { lat: 48.7796865, lng: 18.7386649 },
    { lat: 48.7797032, lng: 18.7387663 },
    { lat: 48.7799242, lng: 18.7388072 },
    { lat: 48.7799573, lng: 18.7387946 },
    { lat: 48.7800944, lng: 18.7390863 },
    { lat: 48.7803278, lng: 18.7389995 },
    { lat: 48.7803531, lng: 18.7392646 },
    { lat: 48.7806716, lng: 18.7394425 },
    { lat: 48.7807567, lng: 18.7396889 },
    { lat: 48.7809704, lng: 18.7400003 },
    { lat: 48.7810873, lng: 18.7401196 },
    { lat: 48.7811405, lng: 18.7404032 },
    { lat: 48.781421, lng: 18.740738 },
    { lat: 48.7814882, lng: 18.7410263 },
    { lat: 48.7815896, lng: 18.7411124 },
    { lat: 48.7817063, lng: 18.7414202 },
    { lat: 48.7818302, lng: 18.7414846 },
    { lat: 48.7819001, lng: 18.7417401 },
    { lat: 48.7819887, lng: 18.7419236 },
    { lat: 48.7822278, lng: 18.7421593 },
    { lat: 48.7825126, lng: 18.7420227 },
    { lat: 48.782718, lng: 18.7421974 },
    { lat: 48.7828322, lng: 18.7425835 },
    { lat: 48.7830029, lng: 18.742658 },
    { lat: 48.7829983, lng: 18.7428252 },
    { lat: 48.7830759, lng: 18.7429084 },
    { lat: 48.7831747, lng: 18.7431769 },
    { lat: 48.7832125, lng: 18.7433608 },
    { lat: 48.7832245, lng: 18.7436744 },
    { lat: 48.7831757, lng: 18.7438376 },
    { lat: 48.783296, lng: 18.7440044 },
    { lat: 48.7832933, lng: 18.7441467 },
    { lat: 48.7833908, lng: 18.7443509 },
    { lat: 48.7834125, lng: 18.7445872 },
    { lat: 48.7835546, lng: 18.7446508 },
    { lat: 48.7836729, lng: 18.7448025 },
    { lat: 48.7837251, lng: 18.7451917 },
    { lat: 48.7838303, lng: 18.74535 },
    { lat: 48.7839383, lng: 18.7453459 },
    { lat: 48.7839952, lng: 18.745417 },
    { lat: 48.7840685, lng: 18.7453323 },
    { lat: 48.784214, lng: 18.7453759 },
    { lat: 48.7845178, lng: 18.7453207 },
    { lat: 48.7847351, lng: 18.745632 },
    { lat: 48.785138, lng: 18.7457669 },
    { lat: 48.7853462, lng: 18.7459556 },
    { lat: 48.7855422, lng: 18.7460336 },
    { lat: 48.7858604, lng: 18.7463178 },
    { lat: 48.7862912, lng: 18.7465744 },
    { lat: 48.7864877, lng: 18.7467685 },
    { lat: 48.7867384, lng: 18.7469264 },
    { lat: 48.7872269, lng: 18.7471872 },
    { lat: 48.78751, lng: 18.7474013 },
    { lat: 48.7876018, lng: 18.7473902 },
    { lat: 48.7878823, lng: 18.7476421 },
    { lat: 48.7880755, lng: 18.7478642 },
    { lat: 48.7881315, lng: 18.7480716 },
    { lat: 48.7882133, lng: 18.748296 },
    { lat: 48.7884586, lng: 18.7484134 },
    { lat: 48.788735, lng: 18.7484166 },
    { lat: 48.7889053, lng: 18.7484815 },
    { lat: 48.7891159, lng: 18.748474 },
    { lat: 48.7892859, lng: 18.7485333 },
    { lat: 48.7896267, lng: 18.7486689 },
    { lat: 48.7898175, lng: 18.7487789 },
    { lat: 48.7903493, lng: 18.7491751 },
    { lat: 48.7907486, lng: 18.7493498 },
    { lat: 48.7908805, lng: 18.7493496 },
    { lat: 48.7909506, lng: 18.7494606 },
    { lat: 48.7911501, lng: 18.7495879 },
    { lat: 48.7912992, lng: 18.7496096 },
    { lat: 48.7913988, lng: 18.7496394 },
    { lat: 48.7914619, lng: 18.7497459 },
    { lat: 48.7915614, lng: 18.749774 },
    { lat: 48.7916389, lng: 18.7498501 },
    { lat: 48.791807, lng: 18.749851 },
    { lat: 48.792092, lng: 18.750062 },
    { lat: 48.7924533, lng: 18.7501786 },
    { lat: 48.7925839, lng: 18.7502799 },
    { lat: 48.7928399, lng: 18.7506006 },
    { lat: 48.7929096, lng: 18.7506415 },
    { lat: 48.7929822, lng: 18.7507372 },
    { lat: 48.7931812, lng: 18.7509157 },
    { lat: 48.7934175, lng: 18.7511004 },
    { lat: 48.7936055, lng: 18.7513717 },
    { lat: 48.793938, lng: 18.7515845 },
    { lat: 48.794206, lng: 18.7516603 },
    { lat: 48.7942752, lng: 18.751692 },
    { lat: 48.7950066, lng: 18.7517011 },
    { lat: 48.7951857, lng: 18.7515933 },
    { lat: 48.7953341, lng: 18.7515604 },
    { lat: 48.7954746, lng: 18.7515943 },
    { lat: 48.7957222, lng: 18.7517369 },
    { lat: 48.7959744, lng: 18.7518152 },
    { lat: 48.7961693, lng: 18.7520467 },
    { lat: 48.7964252, lng: 18.7524069 },
    { lat: 48.7965966, lng: 18.7526863 },
    { lat: 48.7967806, lng: 18.7529045 },
    { lat: 48.7968369, lng: 18.7530319 },
    { lat: 48.7969828, lng: 18.7532983 },
    { lat: 48.7970947, lng: 18.7536246 },
    { lat: 48.7972318, lng: 18.7538277 },
    { lat: 48.7973123, lng: 18.7541131 },
    { lat: 48.797616, lng: 18.7545963 },
    { lat: 48.7977604, lng: 18.7549588 },
    { lat: 48.7982508, lng: 18.7555593 },
    { lat: 48.7984961, lng: 18.7558059 },
    { lat: 48.7986806, lng: 18.7564397 },
    { lat: 48.7987464, lng: 18.7566199 },
    { lat: 48.7987587, lng: 18.7567687 },
    { lat: 48.7989183, lng: 18.7571222 },
    { lat: 48.7989106, lng: 18.757425 },
    { lat: 48.7990617, lng: 18.7581323 },
    { lat: 48.7992977, lng: 18.7585041 },
    { lat: 48.7992066, lng: 18.7591311 },
    { lat: 48.799302, lng: 18.7594458 },
    { lat: 48.8000164, lng: 18.7597565 },
    { lat: 48.801075, lng: 18.7605248 },
    { lat: 48.8013747, lng: 18.7606022 },
    { lat: 48.8019281, lng: 18.7611777 },
    { lat: 48.8022933, lng: 18.7618817 },
    { lat: 48.8026328, lng: 18.7619926 },
    { lat: 48.8033277, lng: 18.7621672 },
    { lat: 48.8045008, lng: 18.7623252 },
    { lat: 48.8049201, lng: 18.7625334 },
    { lat: 48.8051951, lng: 18.7635417 },
    { lat: 48.8054061, lng: 18.7643599 },
    { lat: 48.8060839, lng: 18.7647266 },
    { lat: 48.8066164, lng: 18.7653981 },
    { lat: 48.8065253, lng: 18.7660051 },
    { lat: 48.8065948, lng: 18.7663391 },
    { lat: 48.8065596, lng: 18.7664868 },
    { lat: 48.8065924, lng: 18.7666159 },
    { lat: 48.8065931, lng: 18.76676 },
    { lat: 48.806559, lng: 18.7668407 },
    { lat: 48.806582, lng: 18.7669327 },
    { lat: 48.8065466, lng: 18.7672887 },
    { lat: 48.8064658, lng: 18.7674694 },
    { lat: 48.8064446, lng: 18.7676905 },
    { lat: 48.8063385, lng: 18.7680961 },
    { lat: 48.8062675, lng: 18.7685057 },
    { lat: 48.8062685, lng: 18.7687635 },
    { lat: 48.8063142, lng: 18.7688811 },
    { lat: 48.80636, lng: 18.7690426 },
    { lat: 48.806479, lng: 18.7693342 },
    { lat: 48.806525, lng: 18.7693959 },
    { lat: 48.8065352, lng: 18.769482 },
    { lat: 48.806639, lng: 18.769677 },
    { lat: 48.806647, lng: 18.769704 },
    { lat: 48.806676, lng: 18.769691 },
    { lat: 48.806738, lng: 18.76966 },
    { lat: 48.806873, lng: 18.769596 },
    { lat: 48.807105, lng: 18.769658 },
    { lat: 48.807434, lng: 18.769798 },
    { lat: 48.808584, lng: 18.769483 },
    { lat: 48.808794, lng: 18.769742 },
    { lat: 48.809602, lng: 18.770134 },
    { lat: 48.809938, lng: 18.770794 },
    { lat: 48.810052, lng: 18.77102 },
    { lat: 48.810218, lng: 18.77131 },
    { lat: 48.810503, lng: 18.772159 },
    { lat: 48.811055, lng: 18.772208 },
    { lat: 48.811466, lng: 18.773025 },
    { lat: 48.811712, lng: 18.773318 },
    { lat: 48.811893, lng: 18.7742 },
    { lat: 48.812205, lng: 18.7748 },
    { lat: 48.812447, lng: 18.775762 },
    { lat: 48.812606, lng: 18.776768 },
    { lat: 48.812717, lng: 18.777723 },
    { lat: 48.812717, lng: 18.777728 },
    { lat: 48.812747, lng: 18.777958 },
    { lat: 48.813134, lng: 18.777463 },
    { lat: 48.813186, lng: 18.776747 },
    { lat: 48.813258, lng: 18.775752 },
    { lat: 48.813268, lng: 18.775547 },
    { lat: 48.813311, lng: 18.77468 },
    { lat: 48.813328, lng: 18.774322 },
    { lat: 48.813318, lng: 18.774205 },
    { lat: 48.813191, lng: 18.772882 },
    { lat: 48.813142, lng: 18.772362 },
    { lat: 48.813137, lng: 18.772226 },
    { lat: 48.813101, lng: 18.770961 },
    { lat: 48.813098, lng: 18.770825 },
    { lat: 48.813224, lng: 18.769724 },
    { lat: 48.813224, lng: 18.769723 },
    { lat: 48.813269, lng: 18.769326 },
    { lat: 48.81348, lng: 18.768943 },
    { lat: 48.813815, lng: 18.767897 },
    { lat: 48.81383, lng: 18.767848 },
    { lat: 48.813846, lng: 18.767619 },
    { lat: 48.813901, lng: 18.766858 },
    { lat: 48.813806, lng: 18.766199 },
    { lat: 48.813736, lng: 18.765716 },
    { lat: 48.813823, lng: 18.765115 },
    { lat: 48.81384, lng: 18.764999 },
    { lat: 48.814191, lng: 18.764393 },
    { lat: 48.814695, lng: 18.763525 },
    { lat: 48.8147, lng: 18.763511 },
    { lat: 48.8147, lng: 18.76351 },
    { lat: 48.815209, lng: 18.761848 },
    { lat: 48.815281, lng: 18.761782 },
    { lat: 48.815281, lng: 18.761781 },
    { lat: 48.815605, lng: 18.761482 },
    { lat: 48.815875, lng: 18.761232 },
    { lat: 48.816227, lng: 18.760864 },
    { lat: 48.817126, lng: 18.759607 },
    { lat: 48.817534, lng: 18.758279 },
    { lat: 48.817773, lng: 18.75646 },
    { lat: 48.818054, lng: 18.755311 },
    { lat: 48.818056, lng: 18.755305 },
    { lat: 48.817525, lng: 18.754454 },
    { lat: 48.817522, lng: 18.754449 },
    { lat: 48.817567, lng: 18.754074 },
    { lat: 48.817567, lng: 18.754071 },
    { lat: 48.8171394, lng: 18.7534892 },
    { lat: 48.8165022, lng: 18.7526503 },
    { lat: 48.8151469, lng: 18.7513047 },
    { lat: 48.8148785, lng: 18.7509835 },
    { lat: 48.8141796, lng: 18.7501539 },
    { lat: 48.8136696, lng: 18.7500809 },
    { lat: 48.8122212, lng: 18.7497961 },
    { lat: 48.8112888, lng: 18.7493948 },
    { lat: 48.8110525, lng: 18.7492322 },
    { lat: 48.8106697, lng: 18.7486844 },
    { lat: 48.810323, lng: 18.7482046 },
    { lat: 48.8101793, lng: 18.7480699 },
    { lat: 48.8100383, lng: 18.7480028 },
    { lat: 48.809833, lng: 18.7478068 },
    { lat: 48.8088231, lng: 18.7466585 },
    { lat: 48.8081394, lng: 18.7455188 },
    { lat: 48.8070944, lng: 18.7444128 },
    { lat: 48.8063297, lng: 18.7432779 },
    { lat: 48.8054209, lng: 18.7423816 },
    { lat: 48.8050921, lng: 18.74207 },
    { lat: 48.8050308, lng: 18.7415676 },
    { lat: 48.8045879, lng: 18.7416608 },
    { lat: 48.8044129, lng: 18.7415277 },
    { lat: 48.804114, lng: 18.7412686 },
    { lat: 48.803737, lng: 18.7408369 },
    { lat: 48.8032551, lng: 18.740508 },
    { lat: 48.8028744, lng: 18.7403233 },
    { lat: 48.8023878, lng: 18.7400987 },
    { lat: 48.8021633, lng: 18.740031 },
    { lat: 48.8019242, lng: 18.739925 },
    { lat: 48.8006197, lng: 18.738476 },
    { lat: 48.8003033, lng: 18.7381218 },
    { lat: 48.7995268, lng: 18.7372762 },
    { lat: 48.7990766, lng: 18.7368456 },
    { lat: 48.7980025, lng: 18.7358096 },
    { lat: 48.7973468, lng: 18.7351788 },
    { lat: 48.7961289, lng: 18.7339388 },
    { lat: 48.7961515, lng: 18.733857 },
    { lat: 48.7960174, lng: 18.7336661 },
    { lat: 48.7958521, lng: 18.7333746 },
    { lat: 48.7955845, lng: 18.7329648 },
    { lat: 48.7952064, lng: 18.7324454 },
    { lat: 48.7948494, lng: 18.7320253 },
    { lat: 48.7945477, lng: 18.7317588 },
    { lat: 48.793963, lng: 18.7311707 },
    { lat: 48.7938468, lng: 18.7310656 },
    { lat: 48.7936241, lng: 18.7309026 },
    { lat: 48.7929476, lng: 18.7304372 },
    { lat: 48.7925907, lng: 18.7301495 },
    { lat: 48.7924393, lng: 18.7299732 },
    { lat: 48.7922245, lng: 18.72979 },
    { lat: 48.792028, lng: 18.7296564 },
    { lat: 48.7916387, lng: 18.7293783 },
    { lat: 48.7909779, lng: 18.7289926 },
    { lat: 48.790874, lng: 18.7288818 },
    { lat: 48.7908213, lng: 18.7287154 },
    { lat: 48.790577, lng: 18.7285794 },
    { lat: 48.7903329, lng: 18.7281404 },
    { lat: 48.7899822, lng: 18.7279485 },
    { lat: 48.7897884, lng: 18.727737 },
    { lat: 48.7897218, lng: 18.7276077 },
    { lat: 48.7895948, lng: 18.7275533 },
    { lat: 48.7895514, lng: 18.7270929 },
    { lat: 48.7894637, lng: 18.727057 },
    { lat: 48.7894059, lng: 18.7269646 },
    { lat: 48.789259, lng: 18.7268107 },
    { lat: 48.7890553, lng: 18.7266497 },
    { lat: 48.7889271, lng: 18.7264868 },
    { lat: 48.78879, lng: 18.7264275 },
    { lat: 48.7881891, lng: 18.7253451 },
    { lat: 48.7879919, lng: 18.725179 },
    { lat: 48.7878312, lng: 18.7248873 },
    { lat: 48.7875836, lng: 18.7245572 },
    { lat: 48.7874747, lng: 18.724246 },
    { lat: 48.7873733, lng: 18.7242057 },
    { lat: 48.7871972, lng: 18.7240303 },
    { lat: 48.7871045, lng: 18.7238133 },
    { lat: 48.7869165, lng: 18.723692 },
    { lat: 48.7868716, lng: 18.7235876 },
    { lat: 48.7867737, lng: 18.723485 },
    { lat: 48.7867027, lng: 18.7234471 },
    { lat: 48.7865595, lng: 18.7232731 },
    { lat: 48.7864662, lng: 18.7231925 },
    { lat: 48.7863123, lng: 18.7229944 },
    { lat: 48.7861165, lng: 18.7228722 },
    { lat: 48.785771, lng: 18.7227384 },
    { lat: 48.7856235, lng: 18.722487 },
    { lat: 48.7854018, lng: 18.721897 },
    { lat: 48.7852778, lng: 18.7216423 },
    { lat: 48.7851636, lng: 18.7212538 },
    { lat: 48.7850088, lng: 18.7209493 },
    { lat: 48.7847171, lng: 18.7206563 },
    { lat: 48.784516, lng: 18.7203524 },
    { lat: 48.7843457, lng: 18.7200554 },
    { lat: 48.7840408, lng: 18.7197058 },
    { lat: 48.7839823, lng: 18.7196628 },
    { lat: 48.7833103, lng: 18.7191338 },
    { lat: 48.7828894, lng: 18.7185747 },
    { lat: 48.7825141, lng: 18.7184725 },
    { lat: 48.7824162, lng: 18.7183724 },
    { lat: 48.7822259, lng: 18.717907 },
    { lat: 48.7820344, lng: 18.7177843 },
    { lat: 48.7818129, lng: 18.717328 },
    { lat: 48.7816729, lng: 18.717134 },
    { lat: 48.7816358, lng: 18.7169232 },
    { lat: 48.7813686, lng: 18.716712 },
    { lat: 48.7813042, lng: 18.7165797 },
    { lat: 48.7812257, lng: 18.7166314 },
    { lat: 48.781089, lng: 18.7164784 },
    { lat: 48.7809358, lng: 18.7164809 },
    { lat: 48.7806995, lng: 18.7162986 },
    { lat: 48.780578, lng: 18.7163913 },
    { lat: 48.7804785, lng: 18.7162588 },
    { lat: 48.7804641, lng: 18.7163031 },
    { lat: 48.7797809, lng: 18.7161156 },
    { lat: 48.7795389, lng: 18.7160841 },
    { lat: 48.7794528, lng: 18.7160788 },
    { lat: 48.779266, lng: 18.7160857 },
    { lat: 48.7791676, lng: 18.7160787 },
    { lat: 48.7791518, lng: 18.7157356 },
    { lat: 48.7791252, lng: 18.7156623 },
    { lat: 48.7786088, lng: 18.7154288 },
    { lat: 48.7786447, lng: 18.7139959 },
    { lat: 48.778281, lng: 18.7134114 },
    { lat: 48.7781501, lng: 18.7132599 },
    { lat: 48.778024, lng: 18.7130964 },
    { lat: 48.7774828, lng: 18.7122885 },
    { lat: 48.7772977, lng: 18.7120953 },
    { lat: 48.7769216, lng: 18.7118488 },
    { lat: 48.7767685, lng: 18.7117683 },
    { lat: 48.7762797, lng: 18.7115698 },
    { lat: 48.7762517, lng: 18.7118263 },
    { lat: 48.7762534, lng: 18.7120728 },
    { lat: 48.7762258, lng: 18.7122128 },
    { lat: 48.7761945, lng: 18.712558 },
    { lat: 48.7761219, lng: 18.7134225 },
    { lat: 48.7759832, lng: 18.7133381 },
    { lat: 48.7753316, lng: 18.7128951 },
    { lat: 48.7748005, lng: 18.7127471 },
    { lat: 48.7736329, lng: 18.7125697 },
    { lat: 48.7725681, lng: 18.7122058 },
    { lat: 48.772072, lng: 18.7120385 },
    { lat: 48.7702962, lng: 18.7116857 },
    { lat: 48.7702281, lng: 18.7116754 },
    { lat: 48.767654, lng: 18.7108939 },
    { lat: 48.7669633, lng: 18.710672 },
    { lat: 48.7669646, lng: 18.7106336 },
    { lat: 48.766789, lng: 18.7106817 },
    { lat: 48.7666508, lng: 18.7107099 },
    { lat: 48.7664892, lng: 18.7106612 },
    { lat: 48.7662579, lng: 18.7106807 },
    { lat: 48.76584, lng: 18.7106931 },
    { lat: 48.7656835, lng: 18.7107189 },
    { lat: 48.7654379, lng: 18.7106378 },
    { lat: 48.7652339, lng: 18.7105107 },
    { lat: 48.765034, lng: 18.7103561 },
    { lat: 48.7648901, lng: 18.7102574 },
    { lat: 48.7647238, lng: 18.7101879 },
    { lat: 48.7645191, lng: 18.7101119 },
    { lat: 48.7643929, lng: 18.7100485 },
    { lat: 48.7640484, lng: 18.7098043 },
    { lat: 48.7635649, lng: 18.7096621 },
    { lat: 48.7632383, lng: 18.7095873 },
    { lat: 48.7625975, lng: 18.7093251 },
    { lat: 48.7622997, lng: 18.7091113 },
    { lat: 48.7621137, lng: 18.708963 },
    { lat: 48.7617429, lng: 18.708588 },
    { lat: 48.7614731, lng: 18.7083901 },
    { lat: 48.7613033, lng: 18.7082724 },
    { lat: 48.7610955, lng: 18.7082036 },
    { lat: 48.76071, lng: 18.7081624 },
    { lat: 48.7602198, lng: 18.7084682 },
    { lat: 48.7596963, lng: 18.7087225 },
    { lat: 48.7591706, lng: 18.7087377 },
    { lat: 48.758739, lng: 18.7085977 },
    { lat: 48.7580405, lng: 18.7088447 },
    { lat: 48.7576841, lng: 18.7087146 },
    { lat: 48.7575014, lng: 18.7084998 },
    { lat: 48.7563203, lng: 18.708475 },
    { lat: 48.755597, lng: 18.7081689 },
    { lat: 48.7555449, lng: 18.7079979 },
    { lat: 48.7553091, lng: 18.7077567 },
    { lat: 48.7543354, lng: 18.7072214 },
    { lat: 48.7536389, lng: 18.7054442 },
    { lat: 48.7529956, lng: 18.7037103 },
    { lat: 48.7525172, lng: 18.703348 },
    { lat: 48.7523935, lng: 18.7032939 },
  ],
  Bojnice: [
    { lat: 48.8172081, lng: 18.5492929 },
    { lat: 48.8171935, lng: 18.5492944 },
    { lat: 48.817197, lng: 18.5493795 },
    { lat: 48.8169191, lng: 18.5495158 },
    { lat: 48.8165295, lng: 18.5496764 },
    { lat: 48.8163204, lng: 18.549691 },
    { lat: 48.8161127, lng: 18.5496872 },
    { lat: 48.8158004, lng: 18.5497082 },
    { lat: 48.8154682, lng: 18.5497406 },
    { lat: 48.8153665, lng: 18.5496735 },
    { lat: 48.8151845, lng: 18.5492964 },
    { lat: 48.8146293, lng: 18.5491652 },
    { lat: 48.8143885, lng: 18.5490105 },
    { lat: 48.8139778, lng: 18.5488416 },
    { lat: 48.8135189, lng: 18.5483648 },
    { lat: 48.8129438, lng: 18.5479952 },
    { lat: 48.8127992, lng: 18.5478049 },
    { lat: 48.8126528, lng: 18.547747 },
    { lat: 48.8122716, lng: 18.5472913 },
    { lat: 48.8118922, lng: 18.5471174 },
    { lat: 48.8116143, lng: 18.5469445 },
    { lat: 48.8111895, lng: 18.546704 },
    { lat: 48.8103598, lng: 18.5467759 },
    { lat: 48.810109, lng: 18.54679 },
    { lat: 48.8098991, lng: 18.5466637 },
    { lat: 48.8096387, lng: 18.5464382 },
    { lat: 48.8096154, lng: 18.5464239 },
    { lat: 48.8092202, lng: 18.5465405 },
    { lat: 48.8090101, lng: 18.5463888 },
    { lat: 48.8088997, lng: 18.5463324 },
    { lat: 48.8086495, lng: 18.546087 },
    { lat: 48.8082717, lng: 18.545962 },
    { lat: 48.8080572, lng: 18.5458957 },
    { lat: 48.8077748, lng: 18.5458668 },
    { lat: 48.8074864, lng: 18.5456473 },
    { lat: 48.8066606, lng: 18.5457882 },
    { lat: 48.8064101, lng: 18.5459547 },
    { lat: 48.806109, lng: 18.5462026 },
    { lat: 48.8057509, lng: 18.5465242 },
    { lat: 48.8054537, lng: 18.5466829 },
    { lat: 48.805186, lng: 18.5467975 },
    { lat: 48.804971, lng: 18.5469524 },
    { lat: 48.8047162, lng: 18.5472028 },
    { lat: 48.8043087, lng: 18.5477771 },
    { lat: 48.803975, lng: 18.5482381 },
    { lat: 48.8035507, lng: 18.548482 },
    { lat: 48.803308, lng: 18.5486264 },
    { lat: 48.802794, lng: 18.54921 },
    { lat: 48.8025416, lng: 18.5496547 },
    { lat: 48.8019909, lng: 18.5496675 },
    { lat: 48.8018081, lng: 18.5498141 },
    { lat: 48.8014931, lng: 18.5499105 },
    { lat: 48.8011719, lng: 18.5499384 },
    { lat: 48.8011552, lng: 18.5498557 },
    { lat: 48.8011018, lng: 18.5499128 },
    { lat: 48.8007985, lng: 18.5499559 },
    { lat: 48.8001119, lng: 18.5498182 },
    { lat: 48.7996909, lng: 18.5494595 },
    { lat: 48.7996267, lng: 18.5494625 },
    { lat: 48.7986303, lng: 18.5494114 },
    { lat: 48.797468, lng: 18.5486571 },
    { lat: 48.7965556, lng: 18.5476015 },
    { lat: 48.7955739, lng: 18.5473257 },
    { lat: 48.7948559, lng: 18.5471046 },
    { lat: 48.79408, lng: 18.5468149 },
    { lat: 48.794003, lng: 18.5467086 },
    { lat: 48.7938933, lng: 18.5465992 },
    { lat: 48.7934589, lng: 18.546243 },
    { lat: 48.7931433, lng: 18.5460645 },
    { lat: 48.7927454, lng: 18.5457964 },
    { lat: 48.7923433, lng: 18.5452885 },
    { lat: 48.7919264, lng: 18.544758 },
    { lat: 48.7917799, lng: 18.5445946 },
    { lat: 48.7915914, lng: 18.544471 },
    { lat: 48.790879, lng: 18.5440229 },
    { lat: 48.7901815, lng: 18.543394 },
    { lat: 48.7899656, lng: 18.5428668 },
    { lat: 48.789151, lng: 18.5409334 },
    { lat: 48.7881375, lng: 18.5399519 },
    { lat: 48.7865754, lng: 18.5394979 },
    { lat: 48.7855174, lng: 18.5396235 },
    { lat: 48.7847213, lng: 18.54004 },
    { lat: 48.7846237, lng: 18.5408166 },
    { lat: 48.7843776, lng: 18.5412068 },
    { lat: 48.7838894, lng: 18.5413352 },
    { lat: 48.7835712, lng: 18.541273 },
    { lat: 48.7821359, lng: 18.5408071 },
    { lat: 48.782078, lng: 18.5407764 },
    { lat: 48.7819088, lng: 18.5415676 },
    { lat: 48.7817404, lng: 18.5422653 },
    { lat: 48.7816916, lng: 18.5431487 },
    { lat: 48.7816882, lng: 18.5441853 },
    { lat: 48.7816895, lng: 18.5448303 },
    { lat: 48.7816949, lng: 18.5451798 },
    { lat: 48.7815461, lng: 18.5452009 },
    { lat: 48.7814688, lng: 18.5465385 },
    { lat: 48.7813822, lng: 18.5469359 },
    { lat: 48.7813049, lng: 18.548565 },
    { lat: 48.7812805, lng: 18.5489028 },
    { lat: 48.7812354, lng: 18.5491116 },
    { lat: 48.7811251, lng: 18.5495095 },
    { lat: 48.781497, lng: 18.5498151 },
    { lat: 48.7814644, lng: 18.5500033 },
    { lat: 48.7814345, lng: 18.5501176 },
    { lat: 48.7813459, lng: 18.5505009 },
    { lat: 48.7811637, lng: 18.5510723 },
    { lat: 48.7810616, lng: 18.5514755 },
    { lat: 48.7809826, lng: 18.5516409 },
    { lat: 48.7809604, lng: 18.5518093 },
    { lat: 48.7809113, lng: 18.5519899 },
    { lat: 48.7808169, lng: 18.5522016 },
    { lat: 48.780467, lng: 18.5529641 },
    { lat: 48.780325, lng: 18.5532342 },
    { lat: 48.780296, lng: 18.5533069 },
    { lat: 48.7799901, lng: 18.5529873 },
    { lat: 48.7798919, lng: 18.5532774 },
    { lat: 48.7805721, lng: 18.5542335 },
    { lat: 48.7806554, lng: 18.5546255 },
    { lat: 48.7807118, lng: 18.5547719 },
    { lat: 48.7807812, lng: 18.5551539 },
    { lat: 48.7808251, lng: 18.5552349 },
    { lat: 48.7807946, lng: 18.5554873 },
    { lat: 48.7807998, lng: 18.5557485 },
    { lat: 48.780928, lng: 18.5561771 },
    { lat: 48.7810296, lng: 18.5563229 },
    { lat: 48.7811089, lng: 18.5566607 },
    { lat: 48.7810818, lng: 18.556806 },
    { lat: 48.78114, lng: 18.5570076 },
    { lat: 48.7811269, lng: 18.5570758 },
    { lat: 48.7811039, lng: 18.5573394 },
    { lat: 48.7809301, lng: 18.5576468 },
    { lat: 48.7809684, lng: 18.5580218 },
    { lat: 48.7809373, lng: 18.5580922 },
    { lat: 48.7809543, lng: 18.5582571 },
    { lat: 48.7809072, lng: 18.5584515 },
    { lat: 48.7808384, lng: 18.5585788 },
    { lat: 48.7807536, lng: 18.5586136 },
    { lat: 48.7805061, lng: 18.5589348 },
    { lat: 48.7804013, lng: 18.5590644 },
    { lat: 48.7806235, lng: 18.5594567 },
    { lat: 48.7803028, lng: 18.5600967 },
    { lat: 48.7803449, lng: 18.5605593 },
    { lat: 48.7802515, lng: 18.5607107 },
    { lat: 48.7800947, lng: 18.5608364 },
    { lat: 48.7799734, lng: 18.5609481 },
    { lat: 48.7797351, lng: 18.5610045 },
    { lat: 48.7794161, lng: 18.5609658 },
    { lat: 48.778906, lng: 18.5608182 },
    { lat: 48.7786023, lng: 18.5619536 },
    { lat: 48.778419, lng: 18.562152 },
    { lat: 48.7778996, lng: 18.5627426 },
    { lat: 48.7775035, lng: 18.5631963 },
    { lat: 48.7769718, lng: 18.563812 },
    { lat: 48.7764924, lng: 18.5641641 },
    { lat: 48.7762744, lng: 18.5643278 },
    { lat: 48.7762306, lng: 18.5643486 },
    { lat: 48.7761812, lng: 18.5643973 },
    { lat: 48.7756758, lng: 18.5648527 },
    { lat: 48.7752838, lng: 18.5653216 },
    { lat: 48.7750001, lng: 18.5656419 },
    { lat: 48.7749466, lng: 18.5657143 },
    { lat: 48.7745567, lng: 18.5663274 },
    { lat: 48.7743702, lng: 18.5666327 },
    { lat: 48.7739236, lng: 18.5672961 },
    { lat: 48.7737048, lng: 18.5676297 },
    { lat: 48.7732736, lng: 18.5682468 },
    { lat: 48.7727325, lng: 18.5690249 },
    { lat: 48.7726599, lng: 18.5690761 },
    { lat: 48.7725074, lng: 18.5694901 },
    { lat: 48.7722985, lng: 18.5699834 },
    { lat: 48.7720539, lng: 18.5704675 },
    { lat: 48.7716299, lng: 18.5711742 },
    { lat: 48.7711273, lng: 18.5717665 },
    { lat: 48.7709397, lng: 18.5720121 },
    { lat: 48.7706747, lng: 18.5723254 },
    { lat: 48.7706236, lng: 18.572316 },
    { lat: 48.7700453, lng: 18.573115 },
    { lat: 48.7694112, lng: 18.5739839 },
    { lat: 48.7693395, lng: 18.5740349 },
    { lat: 48.7692373, lng: 18.574146 },
    { lat: 48.7692323, lng: 18.5741798 },
    { lat: 48.7689743, lng: 18.5746012 },
    { lat: 48.7689689, lng: 18.5746673 },
    { lat: 48.768983, lng: 18.5747621 },
    { lat: 48.7691452, lng: 18.5752165 },
    { lat: 48.7691305, lng: 18.5752591 },
    { lat: 48.7689527, lng: 18.5754113 },
    { lat: 48.7685924, lng: 18.5754842 },
    { lat: 48.7684541, lng: 18.5758886 },
    { lat: 48.7679409, lng: 18.5769086 },
    { lat: 48.7677044, lng: 18.577146 },
    { lat: 48.7668123, lng: 18.5775852 },
    { lat: 48.7668763, lng: 18.5776204 },
    { lat: 48.7673726, lng: 18.5782643 },
    { lat: 48.7678578, lng: 18.5787049 },
    { lat: 48.7690022, lng: 18.5792201 },
    { lat: 48.770379, lng: 18.5796059 },
    { lat: 48.7708648, lng: 18.580101 },
    { lat: 48.7712144, lng: 18.5807035 },
    { lat: 48.7716313, lng: 18.5815086 },
    { lat: 48.7718225, lng: 18.5818103 },
    { lat: 48.7720868, lng: 18.5820481 },
    { lat: 48.7724519, lng: 18.5822494 },
    { lat: 48.7724868, lng: 18.5822691 },
    { lat: 48.7725077, lng: 18.5822804 },
    { lat: 48.7732254, lng: 18.582349 },
    { lat: 48.7733445, lng: 18.5823608 },
    { lat: 48.7735033, lng: 18.5825873 },
    { lat: 48.773518, lng: 18.5826084 },
    { lat: 48.7737045, lng: 18.5831558 },
    { lat: 48.7740537, lng: 18.5837528 },
    { lat: 48.7740849, lng: 18.5841824 },
    { lat: 48.7740851, lng: 18.5841866 },
    { lat: 48.7740232, lng: 18.5846501 },
    { lat: 48.7738729, lng: 18.5851861 },
    { lat: 48.7736397, lng: 18.5856932 },
    { lat: 48.7735529, lng: 18.5858817 },
    { lat: 48.7734751, lng: 18.5859443 },
    { lat: 48.7732406, lng: 18.5861335 },
    { lat: 48.7729515, lng: 18.5861269 },
    { lat: 48.7727872, lng: 18.5863831 },
    { lat: 48.7727732, lng: 18.5865842 },
    { lat: 48.7727602, lng: 18.5867612 },
    { lat: 48.7727314, lng: 18.5868952 },
    { lat: 48.7728018, lng: 18.5868644 },
    { lat: 48.7728531, lng: 18.5871446 },
    { lat: 48.7728934, lng: 18.5872836 },
    { lat: 48.7733393, lng: 18.5885843 },
    { lat: 48.7734462, lng: 18.5889142 },
    { lat: 48.7733323, lng: 18.5889775 },
    { lat: 48.7732995, lng: 18.5889955 },
    { lat: 48.7732272, lng: 18.5890355 },
    { lat: 48.7733908, lng: 18.5895188 },
    { lat: 48.7737088, lng: 18.5903087 },
    { lat: 48.7737067, lng: 18.5903544 },
    { lat: 48.7737219, lng: 18.5907807 },
    { lat: 48.7738553, lng: 18.5912453 },
    { lat: 48.7741406, lng: 18.5917464 },
    { lat: 48.7743273, lng: 18.5919247 },
    { lat: 48.774369, lng: 18.5919642 },
    { lat: 48.7745003, lng: 18.5921196 },
    { lat: 48.7745401, lng: 18.5921673 },
    { lat: 48.774703, lng: 18.5928248 },
    { lat: 48.774918, lng: 18.5933782 },
    { lat: 48.7750457, lng: 18.5936567 },
    { lat: 48.7751243, lng: 18.5938982 },
    { lat: 48.7751199, lng: 18.5941108 },
    { lat: 48.7750975, lng: 18.5942795 },
    { lat: 48.7751278, lng: 18.5943625 },
    { lat: 48.7752198, lng: 18.5944158 },
    { lat: 48.7755503, lng: 18.5942906 },
    { lat: 48.7756008, lng: 18.5942851 },
    { lat: 48.7756254, lng: 18.5942826 },
    { lat: 48.7761703, lng: 18.594222 },
    { lat: 48.7765106, lng: 18.5942153 },
    { lat: 48.7767499, lng: 18.5943011 },
    { lat: 48.7770427, lng: 18.5945874 },
    { lat: 48.7771431, lng: 18.5948786 },
    { lat: 48.7772006, lng: 18.5952325 },
    { lat: 48.7772115, lng: 18.5958533 },
    { lat: 48.7771984, lng: 18.5961116 },
    { lat: 48.7771525, lng: 18.5963687 },
    { lat: 48.777085, lng: 18.5965591 },
    { lat: 48.7770329, lng: 18.5966399 },
    { lat: 48.7769163, lng: 18.5968215 },
    { lat: 48.7768259, lng: 18.5970467 },
    { lat: 48.7764074, lng: 18.5976945 },
    { lat: 48.7763354, lng: 18.5979691 },
    { lat: 48.7763045, lng: 18.598317 },
    { lat: 48.7763678, lng: 18.5986324 },
    { lat: 48.7764606, lng: 18.5988259 },
    { lat: 48.7770412, lng: 18.5996364 },
    { lat: 48.7771584, lng: 18.5997814 },
    { lat: 48.7772739, lng: 18.599976 },
    { lat: 48.7782288, lng: 18.6017897 },
    { lat: 48.7791519, lng: 18.6035552 },
    { lat: 48.7797357, lng: 18.6044666 },
    { lat: 48.7797528, lng: 18.6044913 },
    { lat: 48.7800467, lng: 18.6050882 },
    { lat: 48.7802684, lng: 18.6057064 },
    { lat: 48.7805356, lng: 18.6071506 },
    { lat: 48.7807799, lng: 18.6090272 },
    { lat: 48.7811753, lng: 18.6116131 },
    { lat: 48.7814378, lng: 18.6125701 },
    { lat: 48.7817749, lng: 18.6132814 },
    { lat: 48.7820326, lng: 18.6136895 },
    { lat: 48.7824813, lng: 18.6140647 },
    { lat: 48.782752, lng: 18.614231 },
    { lat: 48.7831553, lng: 18.6143909 },
    { lat: 48.7835631, lng: 18.6145066 },
    { lat: 48.7836989, lng: 18.6144931 },
    { lat: 48.7841647, lng: 18.6144516 },
    { lat: 48.784471, lng: 18.6144228 },
    { lat: 48.7851902, lng: 18.6142271 },
    { lat: 48.7854161, lng: 18.6141277 },
    { lat: 48.7856674, lng: 18.6140829 },
    { lat: 48.7859514, lng: 18.6141187 },
    { lat: 48.7862331, lng: 18.6142829 },
    { lat: 48.7864409, lng: 18.6144322 },
    { lat: 48.7866411, lng: 18.6146334 },
    { lat: 48.7868231, lng: 18.6149339 },
    { lat: 48.786942, lng: 18.6152573 },
    { lat: 48.7870277, lng: 18.6156972 },
    { lat: 48.7870775, lng: 18.6160559 },
    { lat: 48.7871933, lng: 18.6165733 },
    { lat: 48.7872131, lng: 18.6165009 },
    { lat: 48.7872559, lng: 18.6164179 },
    { lat: 48.7872804, lng: 18.6163694 },
    { lat: 48.7872848, lng: 18.616493 },
    { lat: 48.7877836, lng: 18.617337 },
    { lat: 48.7880955, lng: 18.617914 },
    { lat: 48.7882275, lng: 18.6183774 },
    { lat: 48.7885787, lng: 18.6189302 },
    { lat: 48.7890507, lng: 18.6196735 },
    { lat: 48.7894294, lng: 18.6205926 },
    { lat: 48.7898847, lng: 18.621278 },
    { lat: 48.7904786, lng: 18.6215851 },
    { lat: 48.7909868, lng: 18.6219532 },
    { lat: 48.7913537, lng: 18.6224629 },
    { lat: 48.7915611, lng: 18.6230472 },
    { lat: 48.7915774, lng: 18.6231625 },
    { lat: 48.7915962, lng: 18.6235333 },
    { lat: 48.7916429, lng: 18.6236252 },
    { lat: 48.7918272, lng: 18.6239913 },
    { lat: 48.7919333, lng: 18.6242015 },
    { lat: 48.7919916, lng: 18.6242603 },
    { lat: 48.7919953, lng: 18.6242647 },
    { lat: 48.7921218, lng: 18.6243933 },
    { lat: 48.7923126, lng: 18.6245866 },
    { lat: 48.7924152, lng: 18.6246909 },
    { lat: 48.7925702, lng: 18.6248482 },
    { lat: 48.792899, lng: 18.624126 },
    { lat: 48.7930513, lng: 18.6237671 },
    { lat: 48.7932565, lng: 18.6233891 },
    { lat: 48.7932804, lng: 18.6233293 },
    { lat: 48.7935155, lng: 18.6227956 },
    { lat: 48.793645, lng: 18.6225175 },
    { lat: 48.7937665, lng: 18.6222371 },
    { lat: 48.7938655, lng: 18.6220639 },
    { lat: 48.7939473, lng: 18.6218056 },
    { lat: 48.793892, lng: 18.6215506 },
    { lat: 48.794268, lng: 18.6211594 },
    { lat: 48.7944476, lng: 18.6208279 },
    { lat: 48.7947048, lng: 18.6203283 },
    { lat: 48.7947371, lng: 18.6199408 },
    { lat: 48.7950586, lng: 18.6194802 },
    { lat: 48.7955869, lng: 18.6194892 },
    { lat: 48.7957513, lng: 18.6193777 },
    { lat: 48.7958638, lng: 18.6193742 },
    { lat: 48.7961162, lng: 18.6193288 },
    { lat: 48.7963259, lng: 18.6190755 },
    { lat: 48.7965624, lng: 18.6188138 },
    { lat: 48.7968098, lng: 18.6183215 },
    { lat: 48.797087, lng: 18.6179375 },
    { lat: 48.7973693, lng: 18.6177113 },
    { lat: 48.7974393, lng: 18.6175678 },
    { lat: 48.7981203, lng: 18.6174803 },
    { lat: 48.7986837, lng: 18.6166095 },
    { lat: 48.7991115, lng: 18.6160699 },
    { lat: 48.799549, lng: 18.6156264 },
    { lat: 48.7997037, lng: 18.6152542 },
    { lat: 48.7998867, lng: 18.6149443 },
    { lat: 48.800065, lng: 18.6146522 },
    { lat: 48.8002374, lng: 18.6143144 },
    { lat: 48.8003982, lng: 18.6136363 },
    { lat: 48.8007742, lng: 18.6129284 },
    { lat: 48.8013292, lng: 18.6122177 },
    { lat: 48.8018328, lng: 18.6118515 },
    { lat: 48.8031585, lng: 18.6106003 },
    { lat: 48.8034215, lng: 18.6105217 },
    { lat: 48.8035974, lng: 18.6107322 },
    { lat: 48.8037512, lng: 18.6105072 },
    { lat: 48.80431, lng: 18.6104117 },
    { lat: 48.8049571, lng: 18.6099465 },
    { lat: 48.8052483, lng: 18.6095918 },
    { lat: 48.8055636, lng: 18.6090827 },
    { lat: 48.8061398, lng: 18.608471 },
    { lat: 48.8061757, lng: 18.608195 },
    { lat: 48.8064025, lng: 18.6078203 },
    { lat: 48.8066932, lng: 18.6075833 },
    { lat: 48.8072092, lng: 18.6066701 },
    { lat: 48.8074792, lng: 18.6060317 },
    { lat: 48.8075798, lng: 18.6057839 },
    { lat: 48.8079815, lng: 18.6050107 },
    { lat: 48.8088148, lng: 18.6033953 },
    { lat: 48.8088196, lng: 18.6033796 },
    { lat: 48.8088304, lng: 18.6033602 },
    { lat: 48.8088472, lng: 18.6033297 },
    { lat: 48.8081546, lng: 18.6026127 },
    { lat: 48.8076101, lng: 18.6020559 },
    { lat: 48.8066627, lng: 18.6010948 },
    { lat: 48.8065898, lng: 18.6010391 },
    { lat: 48.8061052, lng: 18.6006867 },
    { lat: 48.8057519, lng: 18.6003733 },
    { lat: 48.8057369, lng: 18.6004048 },
    { lat: 48.8045264, lng: 18.5989779 },
    { lat: 48.8044895, lng: 18.5989424 },
    { lat: 48.8038655, lng: 18.5982671 },
    { lat: 48.8029864, lng: 18.5975289 },
    { lat: 48.8027096, lng: 18.5973087 },
    { lat: 48.8026809, lng: 18.5972804 },
    { lat: 48.8027513, lng: 18.5971228 },
    { lat: 48.8028032, lng: 18.597081 },
    { lat: 48.802966, lng: 18.596984 },
    { lat: 48.8030402, lng: 18.5969573 },
    { lat: 48.8031179, lng: 18.5968904 },
    { lat: 48.8032021, lng: 18.5968002 },
    { lat: 48.8034008, lng: 18.5965144 },
    { lat: 48.8035109, lng: 18.5963155 },
    { lat: 48.8035774, lng: 18.5962296 },
    { lat: 48.8038868, lng: 18.5956522 },
    { lat: 48.804167, lng: 18.5954311 },
    { lat: 48.8042665, lng: 18.5952903 },
    { lat: 48.8043171, lng: 18.5950977 },
    { lat: 48.8044592, lng: 18.5948897 },
    { lat: 48.8045005, lng: 18.5949233 },
    { lat: 48.8045016, lng: 18.594882 },
    { lat: 48.8045031, lng: 18.594826 },
    { lat: 48.8045076, lng: 18.5947381 },
    { lat: 48.8044769, lng: 18.594485 },
    { lat: 48.804502, lng: 18.5943026 },
    { lat: 48.8045472, lng: 18.5941979 },
    { lat: 48.8045983, lng: 18.594124 },
    { lat: 48.8046839, lng: 18.5940383 },
    { lat: 48.804737, lng: 18.5938921 },
    { lat: 48.8047188, lng: 18.5937863 },
    { lat: 48.8047718, lng: 18.5937 },
    { lat: 48.8049955, lng: 18.5936455 },
    { lat: 48.8050407, lng: 18.593646 },
    { lat: 48.805122, lng: 18.5937118 },
    { lat: 48.8053318, lng: 18.5937538 },
    { lat: 48.8053545, lng: 18.5938201 },
    { lat: 48.805619, lng: 18.5937025 },
    { lat: 48.8057143, lng: 18.5936729 },
    { lat: 48.8057676, lng: 18.5935965 },
    { lat: 48.8058253, lng: 18.5935961 },
    { lat: 48.8058298, lng: 18.593596 },
    { lat: 48.8058593, lng: 18.5935569 },
    { lat: 48.8058683, lng: 18.5935568 },
    { lat: 48.8058863, lng: 18.5935575 },
    { lat: 48.8059057, lng: 18.5936023 },
    { lat: 48.805916, lng: 18.5936257 },
    { lat: 48.8060119, lng: 18.5935641 },
    { lat: 48.8060434, lng: 18.5935649 },
    { lat: 48.8061002, lng: 18.5934884 },
    { lat: 48.8061304, lng: 18.593377 },
    { lat: 48.8061482, lng: 18.5933122 },
    { lat: 48.8061819, lng: 18.5932894 },
    { lat: 48.8061949, lng: 18.5932592 },
    { lat: 48.8062561, lng: 18.5931138 },
    { lat: 48.8062641, lng: 18.593096 },
    { lat: 48.8063271, lng: 18.593032 },
    { lat: 48.8063967, lng: 18.5928982 },
    { lat: 48.8064323, lng: 18.5928494 },
    { lat: 48.8065213, lng: 18.5927017 },
    { lat: 48.8065447, lng: 18.5927164 },
    { lat: 48.8067158, lng: 18.5924397 },
    { lat: 48.8068379, lng: 18.592319 },
    { lat: 48.8068808, lng: 18.5920897 },
    { lat: 48.8069294, lng: 18.5920268 },
    { lat: 48.8069916, lng: 18.5920102 },
    { lat: 48.807065, lng: 18.5920118 },
    { lat: 48.8071721, lng: 18.5919463 },
    { lat: 48.8072244, lng: 18.5919964 },
    { lat: 48.8072386, lng: 18.5920093 },
    { lat: 48.8073628, lng: 18.5919689 },
    { lat: 48.8073654, lng: 18.5918494 },
    { lat: 48.8074671, lng: 18.5917502 },
    { lat: 48.8075581, lng: 18.5915533 },
    { lat: 48.8075574, lng: 18.5915226 },
    { lat: 48.807561, lng: 18.5913364 },
    { lat: 48.8075899, lng: 18.5912237 },
    { lat: 48.8075714, lng: 18.5911559 },
    { lat: 48.8075813, lng: 18.5911081 },
    { lat: 48.807621, lng: 18.5910295 },
    { lat: 48.80765, lng: 18.5910023 },
    { lat: 48.8077464, lng: 18.5909925 },
    { lat: 48.8077779, lng: 18.590989 },
    { lat: 48.8077853, lng: 18.5909376 },
    { lat: 48.8078092, lng: 18.590755 },
    { lat: 48.8078234, lng: 18.5907017 },
    { lat: 48.8078801, lng: 18.5906274 },
    { lat: 48.8078895, lng: 18.5906306 },
    { lat: 48.8079353, lng: 18.5906467 },
    { lat: 48.8079658, lng: 18.5906254 },
    { lat: 48.8080087, lng: 18.5905843 },
    { lat: 48.8080195, lng: 18.5905747 },
    { lat: 48.8080243, lng: 18.5905616 },
    { lat: 48.8080676, lng: 18.5904622 },
    { lat: 48.8081682, lng: 18.5902597 },
    { lat: 48.8083109, lng: 18.5901017 },
    { lat: 48.808378, lng: 18.5899639 },
    { lat: 48.8083918, lng: 18.5899483 },
    { lat: 48.808449, lng: 18.5898839 },
    { lat: 48.8084957, lng: 18.5898474 },
    { lat: 48.8085344, lng: 18.5898322 },
    { lat: 48.8085959, lng: 18.5898457 },
    { lat: 48.8086073, lng: 18.5898475 },
    { lat: 48.808616, lng: 18.5898418 },
    { lat: 48.8087044, lng: 18.5897843 },
    { lat: 48.8087601, lng: 18.5896922 },
    { lat: 48.8088042, lng: 18.5896292 },
    { lat: 48.8088806, lng: 18.5895592 },
    { lat: 48.8089181, lng: 18.5894626 },
    { lat: 48.8089284, lng: 18.5894259 },
    { lat: 48.8089821, lng: 18.5892472 },
    { lat: 48.8090187, lng: 18.5891328 },
    { lat: 48.8090336, lng: 18.589093 },
    { lat: 48.8091243, lng: 18.5890395 },
    { lat: 48.8092378, lng: 18.5889704 },
    { lat: 48.8093007, lng: 18.588944 },
    { lat: 48.8094067, lng: 18.5889641 },
    { lat: 48.8094275, lng: 18.5889111 },
    { lat: 48.8094915, lng: 18.5888452 },
    { lat: 48.8095472, lng: 18.588853 },
    { lat: 48.8095663, lng: 18.5887035 },
    { lat: 48.8095374, lng: 18.5886059 },
    { lat: 48.8095904, lng: 18.5885241 },
    { lat: 48.8096595, lng: 18.5885081 },
    { lat: 48.809677, lng: 18.5883502 },
    { lat: 48.8098047, lng: 18.5882503 },
    { lat: 48.8098825, lng: 18.5882522 },
    { lat: 48.8099641, lng: 18.5880728 },
    { lat: 48.810039, lng: 18.587993 },
    { lat: 48.8101306, lng: 18.5877695 },
    { lat: 48.8102601, lng: 18.5876633 },
    { lat: 48.8103704, lng: 18.58747 },
    { lat: 48.8104408, lng: 18.5873721 },
    { lat: 48.8105099, lng: 18.5873997 },
    { lat: 48.8105885, lng: 18.5873538 },
    { lat: 48.8107284, lng: 18.5873752 },
    { lat: 48.8108318, lng: 18.5872432 },
    { lat: 48.810932, lng: 18.5871775 },
    { lat: 48.8110013, lng: 18.5871653 },
    { lat: 48.8110263, lng: 18.5870853 },
    { lat: 48.8111218, lng: 18.5869539 },
    { lat: 48.8111586, lng: 18.5868599 },
    { lat: 48.8111863, lng: 18.5867702 },
    { lat: 48.811209, lng: 18.586543 },
    { lat: 48.8113726, lng: 18.5862497 },
    { lat: 48.8114052, lng: 18.5861444 },
    { lat: 48.811453, lng: 18.5859624 },
    { lat: 48.8114498, lng: 18.5858411 },
    { lat: 48.8115212, lng: 18.585741 },
    { lat: 48.8115078, lng: 18.585494 },
    { lat: 48.8115096, lng: 18.5850282 },
    { lat: 48.8115398, lng: 18.5850219 },
    { lat: 48.8115898, lng: 18.5846532 },
    { lat: 48.8115626, lng: 18.5846752 },
    { lat: 48.8115489, lng: 18.5846698 },
    { lat: 48.8113644, lng: 18.5845935 },
    { lat: 48.8112462, lng: 18.5840764 },
    { lat: 48.8111383, lng: 18.5838958 },
    { lat: 48.8110686, lng: 18.5837126 },
    { lat: 48.8109162, lng: 18.5831671 },
    { lat: 48.810746, lng: 18.5825008 },
    { lat: 48.8106642, lng: 18.5822823 },
    { lat: 48.8105785, lng: 18.5820964 },
    { lat: 48.8103758, lng: 18.5817873 },
    { lat: 48.8102069, lng: 18.5815671 },
    { lat: 48.8101829, lng: 18.581643 },
    { lat: 48.8097423, lng: 18.5812972 },
    { lat: 48.8097282, lng: 18.5812857 },
    { lat: 48.8096976, lng: 18.5812616 },
    { lat: 48.8097724, lng: 18.5810412 },
    { lat: 48.809785, lng: 18.5810024 },
    { lat: 48.8097969, lng: 18.5809684 },
    { lat: 48.8097856, lng: 18.580948 },
    { lat: 48.8097075, lng: 18.5807977 },
    { lat: 48.8091094, lng: 18.5795621 },
    { lat: 48.8088911, lng: 18.5791143 },
    { lat: 48.8089202, lng: 18.579129 },
    { lat: 48.8092829, lng: 18.5795363 },
    { lat: 48.809604, lng: 18.579762 },
    { lat: 48.8097777, lng: 18.579884 },
    { lat: 48.8097891, lng: 18.5798894 },
    { lat: 48.8100221, lng: 18.5800018 },
    { lat: 48.8101053, lng: 18.5800413 },
    { lat: 48.8101458, lng: 18.5800616 },
    { lat: 48.8104454, lng: 18.5803033 },
    { lat: 48.8104585, lng: 18.5803134 },
    { lat: 48.8104735, lng: 18.5802382 },
    { lat: 48.8104758, lng: 18.5802186 },
    { lat: 48.810488, lng: 18.5801527 },
    { lat: 48.8104923, lng: 18.5801271 },
    { lat: 48.8105122, lng: 18.5800967 },
    { lat: 48.8105487, lng: 18.580041 },
    { lat: 48.8106072, lng: 18.579935 },
    { lat: 48.8106673, lng: 18.5797343 },
    { lat: 48.8106947, lng: 18.5796783 },
    { lat: 48.8107816, lng: 18.5794711 },
    { lat: 48.8108596, lng: 18.5793483 },
    { lat: 48.8109413, lng: 18.5792115 },
    { lat: 48.8110019, lng: 18.5790608 },
    { lat: 48.8111112, lng: 18.5788039 },
    { lat: 48.811159, lng: 18.5786885 },
    { lat: 48.8111587, lng: 18.5786427 },
    { lat: 48.8111632, lng: 18.5785774 },
    { lat: 48.811172, lng: 18.5785523 },
    { lat: 48.811234, lng: 18.5783879 },
    { lat: 48.8114093, lng: 18.5782291 },
    { lat: 48.8114667, lng: 18.5782277 },
    { lat: 48.8114996, lng: 18.5781249 },
    { lat: 48.811581, lng: 18.5780038 },
    { lat: 48.8116595, lng: 18.5779948 },
    { lat: 48.8117589, lng: 18.5778533 },
    { lat: 48.8117523, lng: 18.577731 },
    { lat: 48.8117798, lng: 18.5776546 },
    { lat: 48.8117728, lng: 18.5776308 },
    { lat: 48.8117653, lng: 18.5775537 },
    { lat: 48.811809, lng: 18.577404 },
    { lat: 48.8118217, lng: 18.5772625 },
    { lat: 48.8118875, lng: 18.5771864 },
    { lat: 48.8119095, lng: 18.5770899 },
    { lat: 48.8119153, lng: 18.5769263 },
    { lat: 48.8119721, lng: 18.5768323 },
    { lat: 48.8119802, lng: 18.5767322 },
    { lat: 48.8120649, lng: 18.5766286 },
    { lat: 48.8120812, lng: 18.5765772 },
    { lat: 48.8120758, lng: 18.5765167 },
    { lat: 48.8121075, lng: 18.5764988 },
    { lat: 48.812189, lng: 18.5764848 },
    { lat: 48.8122588, lng: 18.5764163 },
    { lat: 48.8123014, lng: 18.5762693 },
    { lat: 48.8124132, lng: 18.5761249 },
    { lat: 48.8125674, lng: 18.5758037 },
    { lat: 48.8126448, lng: 18.5756931 },
    { lat: 48.8126553, lng: 18.5755104 },
    { lat: 48.8127069, lng: 18.5753816 },
    { lat: 48.8127206, lng: 18.5753023 },
    { lat: 48.8127766, lng: 18.575106 },
    { lat: 48.8127938, lng: 18.5749654 },
    { lat: 48.8128766, lng: 18.5748703 },
    { lat: 48.8129342, lng: 18.5747465 },
    { lat: 48.8129985, lng: 18.5747259 },
    { lat: 48.8130641, lng: 18.5746262 },
    { lat: 48.8132386, lng: 18.5742667 },
    { lat: 48.8133661, lng: 18.5741195 },
    { lat: 48.8134583, lng: 18.5739499 },
    { lat: 48.8135274, lng: 18.5737459 },
    { lat: 48.8135343, lng: 18.5737078 },
    { lat: 48.8135137, lng: 18.5735139 },
    { lat: 48.8135205, lng: 18.5733266 },
    { lat: 48.8135666, lng: 18.5731566 },
    { lat: 48.8135918, lng: 18.5729749 },
    { lat: 48.8136218, lng: 18.5728825 },
    { lat: 48.8136353, lng: 18.5727781 },
    { lat: 48.8136225, lng: 18.5726254 },
    { lat: 48.8136681, lng: 18.5725517 },
    { lat: 48.8137485, lng: 18.5722859 },
    { lat: 48.8137201, lng: 18.572201 },
    { lat: 48.8137791, lng: 18.5720215 },
    { lat: 48.8138448, lng: 18.571877 },
    { lat: 48.8139305, lng: 18.5717537 },
    { lat: 48.8139373, lng: 18.5716508 },
    { lat: 48.8139879, lng: 18.5714989 },
    { lat: 48.8140531, lng: 18.5714775 },
    { lat: 48.814166, lng: 18.5713741 },
    { lat: 48.8142178, lng: 18.571227 },
    { lat: 48.8142066, lng: 18.5711639 },
    { lat: 48.8143229, lng: 18.5708542 },
    { lat: 48.8144215, lng: 18.5706376 },
    { lat: 48.8144013, lng: 18.5704702 },
    { lat: 48.8144009, lng: 18.5704004 },
    { lat: 48.8144696, lng: 18.5702341 },
    { lat: 48.8145999, lng: 18.5701381 },
    { lat: 48.8146452, lng: 18.5699145 },
    { lat: 48.8147013, lng: 18.5697644 },
    { lat: 48.8146987, lng: 18.5696498 },
    { lat: 48.8147629, lng: 18.5695649 },
    { lat: 48.814741, lng: 18.5693704 },
    { lat: 48.8148934, lng: 18.569121 },
    { lat: 48.8149133, lng: 18.5689084 },
    { lat: 48.814915, lng: 18.5688134 },
    { lat: 48.8148748, lng: 18.5686555 },
    { lat: 48.814877, lng: 18.5685704 },
    { lat: 48.8148439, lng: 18.5684615 },
    { lat: 48.814869, lng: 18.5683832 },
    { lat: 48.8149185, lng: 18.5683366 },
    { lat: 48.8149183, lng: 18.5682067 },
    { lat: 48.8149456, lng: 18.5681529 },
    { lat: 48.8149694, lng: 18.5678185 },
    { lat: 48.8149985, lng: 18.5677326 },
    { lat: 48.8150526, lng: 18.5676893 },
    { lat: 48.8150917, lng: 18.5676197 },
    { lat: 48.8151065, lng: 18.5674754 },
    { lat: 48.815076, lng: 18.5673701 },
    { lat: 48.815092, lng: 18.5670023 },
    { lat: 48.8151109, lng: 18.5668271 },
    { lat: 48.8151617, lng: 18.5665402 },
    { lat: 48.8151999, lng: 18.5663683 },
    { lat: 48.8152468, lng: 18.5662562 },
    { lat: 48.8152481, lng: 18.5661154 },
    { lat: 48.8153157, lng: 18.5658221 },
    { lat: 48.8153361, lng: 18.5656803 },
    { lat: 48.81532, lng: 18.5654433 },
    { lat: 48.8153382, lng: 18.5652169 },
    { lat: 48.8153735, lng: 18.5650801 },
    { lat: 48.8154143, lng: 18.5647683 },
    { lat: 48.8154083, lng: 18.5645522 },
    { lat: 48.8155445, lng: 18.5637608 },
    { lat: 48.8155765, lng: 18.5633524 },
    { lat: 48.8155827, lng: 18.5630088 },
    { lat: 48.8156098, lng: 18.5628422 },
    { lat: 48.8156278, lng: 18.5625279 },
    { lat: 48.8156674, lng: 18.5623443 },
    { lat: 48.8156606, lng: 18.5620739 },
    { lat: 48.8157086, lng: 18.5618353 },
    { lat: 48.81579, lng: 18.5616101 },
    { lat: 48.8158641, lng: 18.5614999 },
    { lat: 48.8159164, lng: 18.5613173 },
    { lat: 48.8160488, lng: 18.5609956 },
    { lat: 48.8161565, lng: 18.5608594 },
    { lat: 48.8162528, lng: 18.5607207 },
    { lat: 48.8162903, lng: 18.5606423 },
    { lat: 48.8164038, lng: 18.5603634 },
    { lat: 48.8164698, lng: 18.5601243 },
    { lat: 48.816497, lng: 18.5599423 },
    { lat: 48.8167321, lng: 18.5594922 },
    { lat: 48.8167695, lng: 18.5593494 },
    { lat: 48.8168211, lng: 18.5592417 },
    { lat: 48.8168546, lng: 18.5590711 },
    { lat: 48.8169525, lng: 18.5588141 },
    { lat: 48.8170274, lng: 18.5587629 },
    { lat: 48.8170388, lng: 18.5586799 },
    { lat: 48.8170207, lng: 18.5585783 },
    { lat: 48.8170093, lng: 18.5584065 },
    { lat: 48.8170167, lng: 18.558169 },
    { lat: 48.8169795, lng: 18.5580666 },
    { lat: 48.8169601, lng: 18.5579363 },
    { lat: 48.8169639, lng: 18.5576745 },
    { lat: 48.8169971, lng: 18.5576031 },
    { lat: 48.8169891, lng: 18.5574559 },
    { lat: 48.8170791, lng: 18.5572622 },
    { lat: 48.8170928, lng: 18.557015 },
    { lat: 48.8171312, lng: 18.5567034 },
    { lat: 48.8172045, lng: 18.5564764 },
    { lat: 48.8171838, lng: 18.5561779 },
    { lat: 48.8172431, lng: 18.5560027 },
    { lat: 48.8172564, lng: 18.5557093 },
    { lat: 48.8171619, lng: 18.5556277 },
    { lat: 48.8171327, lng: 18.5554849 },
    { lat: 48.8171166, lng: 18.5551662 },
    { lat: 48.817058, lng: 18.5549624 },
    { lat: 48.8170308, lng: 18.5547925 },
    { lat: 48.8168788, lng: 18.5543218 },
    { lat: 48.8167745, lng: 18.553833 },
    { lat: 48.8165677, lng: 18.5533896 },
    { lat: 48.8165634, lng: 18.5533715 },
    { lat: 48.816575, lng: 18.5530447 },
    { lat: 48.8167975, lng: 18.5521369 },
    { lat: 48.8170004, lng: 18.551326 },
    { lat: 48.8171576, lng: 18.5506686 },
    { lat: 48.8171817, lng: 18.5501778 },
    { lat: 48.8172127, lng: 18.5493768 },
    { lat: 48.8172081, lng: 18.5492929 },
  ],
  NitrianskePravno: [
    { lat: 48.907691, lng: 18.697866 },
    { lat: 48.907843, lng: 18.697038 },
    { lat: 48.908184, lng: 18.69594 },
    { lat: 48.908103, lng: 18.695617 },
    { lat: 48.908146, lng: 18.695244 },
    { lat: 48.908286, lng: 18.695254 },
    { lat: 48.908644, lng: 18.694059 },
    { lat: 48.908638, lng: 18.693977 },
    { lat: 48.908901, lng: 18.693642 },
    { lat: 48.909602, lng: 18.693812 },
    { lat: 48.9085999, lng: 18.6924303 },
    { lat: 48.9080163, lng: 18.6913137 },
    { lat: 48.9073178, lng: 18.6907938 },
    { lat: 48.9071659, lng: 18.6903538 },
    { lat: 48.9066521, lng: 18.6899601 },
    { lat: 48.9064404, lng: 18.6895199 },
    { lat: 48.9063616, lng: 18.6893936 },
    { lat: 48.9055312, lng: 18.6881517 },
    { lat: 48.9054672, lng: 18.6878616 },
    { lat: 48.9053748, lng: 18.6876714 },
    { lat: 48.9053107, lng: 18.6875663 },
    { lat: 48.9046775, lng: 18.6867951 },
    { lat: 48.9045456, lng: 18.6858368 },
    { lat: 48.9038527, lng: 18.6838942 },
    { lat: 48.9036193, lng: 18.6836822 },
    { lat: 48.9032817, lng: 18.6835467 },
    { lat: 48.9027759, lng: 18.6835371 },
    { lat: 48.9024728, lng: 18.6831383 },
    { lat: 48.9023335, lng: 18.6830646 },
    { lat: 48.9011101, lng: 18.6828577 },
    { lat: 48.9008443, lng: 18.6825869 },
    { lat: 48.9004038, lng: 18.6813632 },
    { lat: 48.9003838, lng: 18.6811144 },
    { lat: 48.9003932, lng: 18.6807185 },
    { lat: 48.9004825, lng: 18.6801696 },
    { lat: 48.900347, lng: 18.6797861 },
    { lat: 48.899887, lng: 18.6790681 },
    { lat: 48.8997303, lng: 18.67896 },
    { lat: 48.8984016, lng: 18.677851 },
    { lat: 48.8965929, lng: 18.6769244 },
    { lat: 48.8961689, lng: 18.6764404 },
    { lat: 48.8958557, lng: 18.6763808 },
    { lat: 48.8950883, lng: 18.6754987 },
    { lat: 48.8945548, lng: 18.6742037 },
    { lat: 48.8937937, lng: 18.6734459 },
    { lat: 48.8936397, lng: 18.67263 },
    { lat: 48.8931483, lng: 18.6724199 },
    { lat: 48.89297, lng: 18.6723779 },
    { lat: 48.8928371, lng: 18.672234 },
    { lat: 48.8926795, lng: 18.6719633 },
    { lat: 48.8924876, lng: 18.6714309 },
    { lat: 48.8918354, lng: 18.6709563 },
    { lat: 48.8916173, lng: 18.6705887 },
    { lat: 48.8913498, lng: 18.6706472 },
    { lat: 48.8912668, lng: 18.6706137 },
    { lat: 48.8912057, lng: 18.6705047 },
    { lat: 48.891134, lng: 18.6704275 },
    { lat: 48.8910602, lng: 18.6704401 },
    { lat: 48.8909312, lng: 18.6705386 },
    { lat: 48.8908722, lng: 18.6705935 },
    { lat: 48.8907735, lng: 18.6706035 },
    { lat: 48.8906891, lng: 18.6705464 },
    { lat: 48.8906472, lng: 18.670461 },
    { lat: 48.8906133, lng: 18.6703919 },
    { lat: 48.8904244, lng: 18.6695323 },
    { lat: 48.8904675, lng: 18.6693037 },
    { lat: 48.8904372, lng: 18.6692225 },
    { lat: 48.8901143, lng: 18.6687951 },
    { lat: 48.8898836, lng: 18.6685671 },
    { lat: 48.8895878, lng: 18.6680987 },
    { lat: 48.8897997, lng: 18.6673954 },
    { lat: 48.8895415, lng: 18.6670385 },
    { lat: 48.8894527, lng: 18.6667469 },
    { lat: 48.889434, lng: 18.6661428 },
    { lat: 48.8894484, lng: 18.6660482 },
    { lat: 48.8895627, lng: 18.6654654 },
    { lat: 48.8896286, lng: 18.6648793 },
    { lat: 48.8896917, lng: 18.6644985 },
    { lat: 48.88979, lng: 18.6639962 },
    { lat: 48.8900253, lng: 18.6633108 },
    { lat: 48.8900713, lng: 18.6625192 },
    { lat: 48.8901281, lng: 18.6623169 },
    { lat: 48.8902676, lng: 18.6617823 },
    { lat: 48.8903865, lng: 18.6611351 },
    { lat: 48.8904579, lng: 18.6607576 },
    { lat: 48.8905156, lng: 18.6599388 },
    { lat: 48.8907112, lng: 18.6590826 },
    { lat: 48.8906359, lng: 18.658175 },
    { lat: 48.8905252, lng: 18.6578764 },
    { lat: 48.8904613, lng: 18.6576073 },
    { lat: 48.8904118, lng: 18.6573588 },
    { lat: 48.8903864, lng: 18.6570727 },
    { lat: 48.890404, lng: 18.656976 },
    { lat: 48.8903565, lng: 18.6562994 },
    { lat: 48.890301, lng: 18.655936 },
    { lat: 48.8901068, lng: 18.6549588 },
    { lat: 48.8898188, lng: 18.6541455 },
    { lat: 48.8898019, lng: 18.6536591 },
    { lat: 48.8897046, lng: 18.6530008 },
    { lat: 48.8892694, lng: 18.653066 },
    { lat: 48.8891841, lng: 18.6522899 },
    { lat: 48.8890893, lng: 18.6516969 },
    { lat: 48.8890292, lng: 18.6512921 },
    { lat: 48.8889879, lng: 18.6509833 },
    { lat: 48.8888798, lng: 18.6500117 },
    { lat: 48.8888553, lng: 18.6494466 },
    { lat: 48.8888355, lng: 18.6483187 },
    { lat: 48.888443, lng: 18.6475989 },
    { lat: 48.8880842, lng: 18.6467531 },
    { lat: 48.8880588, lng: 18.6462778 },
    { lat: 48.8880197, lng: 18.6461783 },
    { lat: 48.8880324, lng: 18.6458449 },
    { lat: 48.8881135, lng: 18.6454654 },
    { lat: 48.8881653, lng: 18.6452727 },
    { lat: 48.8883012, lng: 18.6448603 },
    { lat: 48.8885274, lng: 18.6443656 },
    { lat: 48.8887049, lng: 18.6440567 },
    { lat: 48.8887864, lng: 18.6438298 },
    { lat: 48.8888074, lng: 18.6434635 },
    { lat: 48.8887885, lng: 18.6432379 },
    { lat: 48.8885922, lng: 18.6425183 },
    { lat: 48.8885706, lng: 18.6421372 },
    { lat: 48.8886393, lng: 18.6414191 },
    { lat: 48.8884596, lng: 18.6407979 },
    { lat: 48.8884049, lng: 18.6404523 },
    { lat: 48.8884462, lng: 18.6402114 },
    { lat: 48.8879724, lng: 18.6404213 },
    { lat: 48.8877864, lng: 18.6402532 },
    { lat: 48.8876569, lng: 18.6399168 },
    { lat: 48.8876315, lng: 18.6395708 },
    { lat: 48.8876239, lng: 18.6393668 },
    { lat: 48.8876367, lng: 18.6392043 },
    { lat: 48.8876117, lng: 18.6390941 },
    { lat: 48.8872619, lng: 18.6388153 },
    { lat: 48.8869923, lng: 18.6388775 },
    { lat: 48.886502, lng: 18.639074 },
    { lat: 48.8863952, lng: 18.63908 },
    { lat: 48.8862786, lng: 18.6389449 },
    { lat: 48.8862617, lng: 18.6386284 },
    { lat: 48.8862137, lng: 18.6384705 },
    { lat: 48.8862517, lng: 18.6383798 },
    { lat: 48.8865795, lng: 18.6385832 },
    { lat: 48.8866676, lng: 18.6385881 },
    { lat: 48.8867953, lng: 18.6384871 },
    { lat: 48.886903, lng: 18.6383484 },
    { lat: 48.8870652, lng: 18.6382615 },
    { lat: 48.8874725, lng: 18.6379454 },
    { lat: 48.8875184, lng: 18.637814 },
    { lat: 48.8876899, lng: 18.6377115 },
    { lat: 48.8877844, lng: 18.6375773 },
    { lat: 48.8879516, lng: 18.6376066 },
    { lat: 48.8880142, lng: 18.6375297 },
    { lat: 48.8881012, lng: 18.6375136 },
    { lat: 48.8882636, lng: 18.6372177 },
    { lat: 48.8885345, lng: 18.6371174 },
    { lat: 48.8885825, lng: 18.6370036 },
    { lat: 48.8886637, lng: 18.6369792 },
    { lat: 48.8887034, lng: 18.6368604 },
    { lat: 48.8889911, lng: 18.6366279 },
    { lat: 48.8892109, lng: 18.6366273 },
    { lat: 48.8893156, lng: 18.6365173 },
    { lat: 48.8893318, lng: 18.6363774 },
    { lat: 48.8895492, lng: 18.6360556 },
    { lat: 48.8895551, lng: 18.6358995 },
    { lat: 48.8895267, lng: 18.6358685 },
    { lat: 48.8894769, lng: 18.6354632 },
    { lat: 48.8890019, lng: 18.6347439 },
    { lat: 48.8887068, lng: 18.6340968 },
    { lat: 48.8880456, lng: 18.6334793 },
    { lat: 48.887788, lng: 18.6329459 },
    { lat: 48.8876248, lng: 18.6331193 },
    { lat: 48.8873298, lng: 18.6327535 },
    { lat: 48.8871646, lng: 18.6325323 },
    { lat: 48.8870824, lng: 18.6324496 },
    { lat: 48.8866651, lng: 18.632048 },
    { lat: 48.88613, lng: 18.6313864 },
    { lat: 48.8860237, lng: 18.6312114 },
    { lat: 48.8856353, lng: 18.6300665 },
    { lat: 48.8850049, lng: 18.6282993 },
    { lat: 48.884506, lng: 18.6278286 },
    { lat: 48.8839261, lng: 18.62652 },
    { lat: 48.8837535, lng: 18.6261224 },
    { lat: 48.8836037, lng: 18.6261057 },
    { lat: 48.8835268, lng: 18.6259532 },
    { lat: 48.8833426, lng: 18.6257129 },
    { lat: 48.8832864, lng: 18.6255686 },
    { lat: 48.8831193, lng: 18.6252742 },
    { lat: 48.882943, lng: 18.6250748 },
    { lat: 48.8828308, lng: 18.6249227 },
    { lat: 48.8826182, lng: 18.6249941 },
    { lat: 48.8823659, lng: 18.6242644 },
    { lat: 48.8822186, lng: 18.6240837 },
    { lat: 48.8821168, lng: 18.6237845 },
    { lat: 48.882051, lng: 18.6236722 },
    { lat: 48.8818792, lng: 18.62343 },
    { lat: 48.8818182, lng: 18.6232623 },
    { lat: 48.8817203, lng: 18.6229708 },
    { lat: 48.8817122, lng: 18.622798 },
    { lat: 48.8815605, lng: 18.622538 },
    { lat: 48.8814515, lng: 18.6221006 },
    { lat: 48.8813069, lng: 18.6215519 },
    { lat: 48.8812635, lng: 18.6214164 },
    { lat: 48.8812142, lng: 18.6212326 },
    { lat: 48.8811317, lng: 18.6209554 },
    { lat: 48.881033, lng: 18.6207149 },
    { lat: 48.8808983, lng: 18.6202205 },
    { lat: 48.8807461, lng: 18.6197362 },
    { lat: 48.8806336, lng: 18.6193233 },
    { lat: 48.8805365, lng: 18.6190278 },
    { lat: 48.8804217, lng: 18.6187385 },
    { lat: 48.8802728, lng: 18.6184652 },
    { lat: 48.8801599, lng: 18.6182953 },
    { lat: 48.8800338, lng: 18.6180464 },
    { lat: 48.8798939, lng: 18.6179189 },
    { lat: 48.879783, lng: 18.6175351 },
    { lat: 48.8794715, lng: 18.6169401 },
    { lat: 48.8793263, lng: 18.6165258 },
    { lat: 48.8790081, lng: 18.6157041 },
    { lat: 48.8787194, lng: 18.6150338 },
    { lat: 48.8786668, lng: 18.6148948 },
    { lat: 48.8779411, lng: 18.6128513 },
    { lat: 48.8776094, lng: 18.6121308 },
    { lat: 48.8771464, lng: 18.6107601 },
    { lat: 48.8767536, lng: 18.6095155 },
    { lat: 48.8766254, lng: 18.609082 },
    { lat: 48.8763602, lng: 18.6084716 },
    { lat: 48.8760794, lng: 18.6076747 },
    { lat: 48.8756847, lng: 18.6064353 },
    { lat: 48.8754896, lng: 18.6057866 },
    { lat: 48.8754917, lng: 18.6053434 },
    { lat: 48.8755617, lng: 18.6047597 },
    { lat: 48.8755616, lng: 18.6036858 },
    { lat: 48.8756142, lng: 18.6031351 },
    { lat: 48.8759302, lng: 18.6022382 },
    { lat: 48.8763434, lng: 18.6009226 },
    { lat: 48.8763269, lng: 18.6007648 },
    { lat: 48.8767153, lng: 18.5993483 },
    { lat: 48.876844, lng: 18.598051 },
    { lat: 48.8766307, lng: 18.5961404 },
    { lat: 48.8766556, lng: 18.595937 },
    { lat: 48.876632, lng: 18.5959374 },
    { lat: 48.8765623, lng: 18.5947482 },
    { lat: 48.8765044, lng: 18.5944067 },
    { lat: 48.8762371, lng: 18.5936734 },
    { lat: 48.8760583, lng: 18.5929309 },
    { lat: 48.8758898, lng: 18.5930664 },
    { lat: 48.8756657, lng: 18.5929924 },
    { lat: 48.875492, lng: 18.5926373 },
    { lat: 48.8751414, lng: 18.5925729 },
    { lat: 48.8749838, lng: 18.5922456 },
    { lat: 48.8745503, lng: 18.5914207 },
    { lat: 48.8745248, lng: 18.5908463 },
    { lat: 48.8745607, lng: 18.5903205 },
    { lat: 48.874517, lng: 18.590159 },
    { lat: 48.8745333, lng: 18.5898742 },
    { lat: 48.8744682, lng: 18.5894821 },
    { lat: 48.8741449, lng: 18.5891366 },
    { lat: 48.8746834, lng: 18.588765 },
    { lat: 48.8747176, lng: 18.5881899 },
    { lat: 48.8748282, lng: 18.587675 },
    { lat: 48.874962, lng: 18.5870517 },
    { lat: 48.8748681, lng: 18.5869312 },
    { lat: 48.8748505, lng: 18.5869086 },
    { lat: 48.8748599, lng: 18.5867114 },
    { lat: 48.8748695, lng: 18.5865107 },
    { lat: 48.8748399, lng: 18.586318 },
    { lat: 48.8749015, lng: 18.5861613 },
    { lat: 48.8748973, lng: 18.5860258 },
    { lat: 48.8748912, lng: 18.5858321 },
    { lat: 48.8748892, lng: 18.585768 },
    { lat: 48.8749102, lng: 18.5856479 },
    { lat: 48.8749212, lng: 18.5855852 },
    { lat: 48.8749605, lng: 18.5854057 },
    { lat: 48.8749881, lng: 18.5852795 },
    { lat: 48.8749262, lng: 18.5849034 },
    { lat: 48.8749219, lng: 18.5847621 },
    { lat: 48.8749266, lng: 18.5846221 },
    { lat: 48.875025, lng: 18.5845214 },
    { lat: 48.8750393, lng: 18.5844815 },
    { lat: 48.8750667, lng: 18.5844053 },
    { lat: 48.8751063, lng: 18.5842949 },
    { lat: 48.8751101, lng: 18.5842356 },
    { lat: 48.8751186, lng: 18.5841009 },
    { lat: 48.8751649, lng: 18.5837913 },
    { lat: 48.8752101, lng: 18.58377 },
    { lat: 48.875233, lng: 18.5835898 },
    { lat: 48.8752225, lng: 18.5832682 },
    { lat: 48.8751943, lng: 18.58285 },
    { lat: 48.8752486, lng: 18.5821237 },
    { lat: 48.8753581, lng: 18.5814992 },
    { lat: 48.8755455, lng: 18.5807887 },
    { lat: 48.8758098, lng: 18.5802338 },
    { lat: 48.8761889, lng: 18.5794866 },
    { lat: 48.8762742, lng: 18.5792449 },
    { lat: 48.876306, lng: 18.5790026 },
    { lat: 48.8764245, lng: 18.5790216 },
    { lat: 48.8764111, lng: 18.5788567 },
    { lat: 48.8764367, lng: 18.5786833 },
    { lat: 48.8764443, lng: 18.5787006 },
    { lat: 48.8766347, lng: 18.5788611 },
    { lat: 48.87672, lng: 18.5782475 },
    { lat: 48.8767967, lng: 18.577852 },
    { lat: 48.8768786, lng: 18.5771757 },
    { lat: 48.8768545, lng: 18.5769788 },
    { lat: 48.8769581, lng: 18.5768097 },
    { lat: 48.8770137, lng: 18.5767988 },
    { lat: 48.8775672, lng: 18.5765832 },
    { lat: 48.8784743, lng: 18.5759838 },
    { lat: 48.8796249, lng: 18.5754202 },
    { lat: 48.8800609, lng: 18.5752226 },
    { lat: 48.8808828, lng: 18.5748431 },
    { lat: 48.8817342, lng: 18.5746094 },
    { lat: 48.8827545, lng: 18.5743944 },
    { lat: 48.8836303, lng: 18.5740895 },
    { lat: 48.8848558, lng: 18.5737663 },
    { lat: 48.8857509, lng: 18.5734507 },
    { lat: 48.8866069, lng: 18.573149 },
    { lat: 48.8876675, lng: 18.5732597 },
    { lat: 48.8884805, lng: 18.5729218 },
    { lat: 48.8885008, lng: 18.572829 },
    { lat: 48.8887817, lng: 18.5715478 },
    { lat: 48.8888629, lng: 18.5711728 },
    { lat: 48.8888113, lng: 18.5703018 },
    { lat: 48.8892155, lng: 18.5700587 },
    { lat: 48.8890273, lng: 18.5694147 },
    { lat: 48.8887011, lng: 18.5684098 },
    { lat: 48.8884228, lng: 18.5676029 },
    { lat: 48.8882627, lng: 18.5671261 },
    { lat: 48.8883158, lng: 18.5662937 },
    { lat: 48.8883678, lng: 18.5657929 },
    { lat: 48.8884503, lng: 18.5649619 },
    { lat: 48.8884641, lng: 18.5649082 },
    { lat: 48.8889254, lng: 18.5645705 },
    { lat: 48.8895019, lng: 18.5639514 },
    { lat: 48.8906966, lng: 18.5636223 },
    { lat: 48.8907971, lng: 18.5623912 },
    { lat: 48.8910061, lng: 18.5617945 },
    { lat: 48.8913966, lng: 18.5611985 },
    { lat: 48.8917384, lng: 18.5607176 },
    { lat: 48.8921123, lng: 18.5600634 },
    { lat: 48.8919069, lng: 18.5592037 },
    { lat: 48.8921247, lng: 18.5590019 },
    { lat: 48.8925922, lng: 18.5592577 },
    { lat: 48.8936947, lng: 18.5589988 },
    { lat: 48.8938375, lng: 18.5584286 },
    { lat: 48.8939218, lng: 18.5577377 },
    { lat: 48.8943504, lng: 18.5572377 },
    { lat: 48.8947364, lng: 18.5567619 },
    { lat: 48.8950082, lng: 18.5562383 },
    { lat: 48.895477, lng: 18.5559404 },
    { lat: 48.8959046, lng: 18.5558796 },
    { lat: 48.8962778, lng: 18.556189 },
    { lat: 48.8970522, lng: 18.5548306 },
    { lat: 48.8970622, lng: 18.5547842 },
    { lat: 48.8970891, lng: 18.5547604 },
    { lat: 48.8971518, lng: 18.5547934 },
    { lat: 48.8973099, lng: 18.5549454 },
    { lat: 48.8973551, lng: 18.5550284 },
    { lat: 48.8974653, lng: 18.5551039 },
    { lat: 48.8975137, lng: 18.5552075 },
    { lat: 48.897533, lng: 18.5553131 },
    { lat: 48.8975537, lng: 18.555427 },
    { lat: 48.8975978, lng: 18.5556006 },
    { lat: 48.897627, lng: 18.5557157 },
    { lat: 48.8978961, lng: 18.5561012 },
    { lat: 48.897926, lng: 18.5561522 },
    { lat: 48.8980078, lng: 18.5562916 },
    { lat: 48.8981366, lng: 18.5563179 },
    { lat: 48.8981841, lng: 18.5563776 },
    { lat: 48.8983717, lng: 18.5564506 },
    { lat: 48.8984777, lng: 18.5563883 },
    { lat: 48.8985367, lng: 18.5563734 },
    { lat: 48.898615, lng: 18.5564004 },
    { lat: 48.898662, lng: 18.5564166 },
    { lat: 48.898839, lng: 18.5563945 },
    { lat: 48.8989339, lng: 18.5563314 },
    { lat: 48.8991703, lng: 18.5562417 },
    { lat: 48.8992728, lng: 18.5562386 },
    { lat: 48.8994313, lng: 18.5563554 },
    { lat: 48.899508, lng: 18.5563752 },
    { lat: 48.8996043, lng: 18.5564249 },
    { lat: 48.8997614, lng: 18.5563669 },
    { lat: 48.8998698, lng: 18.5563688 },
    { lat: 48.8999199, lng: 18.556421 },
    { lat: 48.9001808, lng: 18.5565773 },
    { lat: 48.9002592, lng: 18.5566243 },
    { lat: 48.9004219, lng: 18.5566524 },
    { lat: 48.9004839, lng: 18.5567322 },
    { lat: 48.9005532, lng: 18.5568472 },
    { lat: 48.9006912, lng: 18.5568121 },
    { lat: 48.9008592, lng: 18.5568313 },
    { lat: 48.9009768, lng: 18.5567958 },
    { lat: 48.9012601, lng: 18.5568193 },
    { lat: 48.901306, lng: 18.556877 },
    { lat: 48.9014004, lng: 18.5568703 },
    { lat: 48.9014531, lng: 18.5568218 },
    { lat: 48.9014827, lng: 18.5567668 },
    { lat: 48.9015779, lng: 18.5566922 },
    { lat: 48.9017142, lng: 18.556768 },
    { lat: 48.9017694, lng: 18.5567493 },
    { lat: 48.9019259, lng: 18.5565355 },
    { lat: 48.9020319, lng: 18.5564502 },
    { lat: 48.9020672, lng: 18.5564351 },
    { lat: 48.9021331, lng: 18.5564261 },
    { lat: 48.9021568, lng: 18.5564447 },
    { lat: 48.9022026, lng: 18.5564996 },
    { lat: 48.9023115, lng: 18.5565333 },
    { lat: 48.9023543, lng: 18.5565287 },
    { lat: 48.9024623, lng: 18.5563596 },
    { lat: 48.9025688, lng: 18.5562938 },
    { lat: 48.9026229, lng: 18.5562604 },
    { lat: 48.9027233, lng: 18.5561371 },
    { lat: 48.9030296, lng: 18.5561064 },
    { lat: 48.9031317, lng: 18.5561205 },
    { lat: 48.903173, lng: 18.5560057 },
    { lat: 48.9032137, lng: 18.5559278 },
    { lat: 48.9033608, lng: 18.5557241 },
    { lat: 48.903483, lng: 18.5557326 },
    { lat: 48.9035228, lng: 18.555491 },
    { lat: 48.9035061, lng: 18.5552223 },
    { lat: 48.9036725, lng: 18.5551087 },
    { lat: 48.9036797, lng: 18.5550763 },
    { lat: 48.9036999, lng: 18.5548242 },
    { lat: 48.9037878, lng: 18.5546548 },
    { lat: 48.9038446, lng: 18.5545993 },
    { lat: 48.9039369, lng: 18.5544089 },
    { lat: 48.9039007, lng: 18.5540943 },
    { lat: 48.9039618, lng: 18.5540361 },
    { lat: 48.904005, lng: 18.5540863 },
    { lat: 48.9040095, lng: 18.5540829 },
    { lat: 48.9040478, lng: 18.5538573 },
    { lat: 48.9039898, lng: 18.5536372 },
    { lat: 48.9040222, lng: 18.5534671 },
    { lat: 48.9039968, lng: 18.5534345 },
    { lat: 48.9039932, lng: 18.5533073 },
    { lat: 48.9042666, lng: 18.5527748 },
    { lat: 48.904268, lng: 18.5526742 },
    { lat: 48.9043853, lng: 18.5523606 },
    { lat: 48.9044878, lng: 18.5522757 },
    { lat: 48.9046062, lng: 18.5520673 },
    { lat: 48.9046704, lng: 18.5520256 },
    { lat: 48.9047585, lng: 18.5517974 },
    { lat: 48.9050823, lng: 18.5517584 },
    { lat: 48.9051332, lng: 18.5516118 },
    { lat: 48.9053384, lng: 18.5514862 },
    { lat: 48.9054203, lng: 18.5513759 },
    { lat: 48.9054593, lng: 18.5512628 },
    { lat: 48.9055016, lng: 18.5510868 },
    { lat: 48.9054834, lng: 18.5510447 },
    { lat: 48.905533, lng: 18.5509381 },
    { lat: 48.9056346, lng: 18.5508989 },
    { lat: 48.9056109, lng: 18.5506892 },
    { lat: 48.9056872, lng: 18.5506614 },
    { lat: 48.9056525, lng: 18.5505843 },
    { lat: 48.9055838, lng: 18.5505471 },
    { lat: 48.9052098, lng: 18.5503449 },
    { lat: 48.9049154, lng: 18.5495143 },
    { lat: 48.9047448, lng: 18.5485905 },
    { lat: 48.9047096, lng: 18.5471113 },
    { lat: 48.9045773, lng: 18.5457962 },
    { lat: 48.9047237, lng: 18.5457065 },
    { lat: 48.904888, lng: 18.5451188 },
    { lat: 48.9047855, lng: 18.5448114 },
    { lat: 48.9047781, lng: 18.5442573 },
    { lat: 48.9048077, lng: 18.5436832 },
    { lat: 48.9052057, lng: 18.5427037 },
    { lat: 48.9058521, lng: 18.5423515 },
    { lat: 48.906138, lng: 18.541778 },
    { lat: 48.9064431, lng: 18.5414781 },
    { lat: 48.906619, lng: 18.5416655 },
    { lat: 48.9070863, lng: 18.5415669 },
    { lat: 48.9076959, lng: 18.5417399 },
    { lat: 48.9080094, lng: 18.5416774 },
    { lat: 48.9080952, lng: 18.5415154 },
    { lat: 48.9085413, lng: 18.5398858 },
    { lat: 48.9086929, lng: 18.5394359 },
    { lat: 48.9089451, lng: 18.5391178 },
    { lat: 48.9095229, lng: 18.5385798 },
    { lat: 48.9095845, lng: 18.5384491 },
    { lat: 48.909679, lng: 18.5382168 },
    { lat: 48.9100402, lng: 18.5373483 },
    { lat: 48.9100638, lng: 18.5371384 },
    { lat: 48.9102573, lng: 18.5355935 },
    { lat: 48.9110345, lng: 18.5345151 },
    { lat: 48.9113285, lng: 18.5342811 },
    { lat: 48.9115041, lng: 18.5337373 },
    { lat: 48.9116851, lng: 18.5333496 },
    { lat: 48.9121326, lng: 18.5328464 },
    { lat: 48.9129013, lng: 18.5315306 },
    { lat: 48.9129278, lng: 18.5314997 },
    { lat: 48.9132036, lng: 18.5310954 },
    { lat: 48.9133748, lng: 18.5316481 },
    { lat: 48.9135889, lng: 18.5316259 },
    { lat: 48.913578, lng: 18.531448 },
    { lat: 48.9140649, lng: 18.5306745 },
    { lat: 48.9141715, lng: 18.530623 },
    { lat: 48.9142915, lng: 18.530565 },
    { lat: 48.9143306, lng: 18.5305569 },
    { lat: 48.9148277, lng: 18.5302616 },
    { lat: 48.915441, lng: 18.5292623 },
    { lat: 48.9154769, lng: 18.528802 },
    { lat: 48.9155028, lng: 18.5285123 },
    { lat: 48.9162035, lng: 18.5280368 },
    { lat: 48.917172, lng: 18.5279083 },
    { lat: 48.9173224, lng: 18.5275638 },
    { lat: 48.9175072, lng: 18.5272673 },
    { lat: 48.9177678, lng: 18.5270213 },
    { lat: 48.9181985, lng: 18.5266671 },
    { lat: 48.918414, lng: 18.526605 },
    { lat: 48.9187934, lng: 18.5262357 },
    { lat: 48.9185773, lng: 18.525874 },
    { lat: 48.9183552, lng: 18.5254198 },
    { lat: 48.9180679, lng: 18.5250524 },
    { lat: 48.9179866, lng: 18.5248237 },
    { lat: 48.9178713, lng: 18.5241765 },
    { lat: 48.9177183, lng: 18.523416 },
    { lat: 48.917576, lng: 18.5228948 },
    { lat: 48.9173478, lng: 18.5220433 },
    { lat: 48.9170768, lng: 18.5212678 },
    { lat: 48.9169443, lng: 18.5209922 },
    { lat: 48.9167384, lng: 18.5205883 },
    { lat: 48.916561, lng: 18.5202911 },
    { lat: 48.9162351, lng: 18.5197538 },
    { lat: 48.9159452, lng: 18.5194836 },
    { lat: 48.9156639, lng: 18.5189415 },
    { lat: 48.9155845, lng: 18.5184339 },
    { lat: 48.9154816, lng: 18.5180771 },
    { lat: 48.9152894, lng: 18.5176558 },
    { lat: 48.9149122, lng: 18.517522 },
    { lat: 48.9146878, lng: 18.5173386 },
    { lat: 48.9133861, lng: 18.5169246 },
    { lat: 48.9128766, lng: 18.5163126 },
    { lat: 48.9120934, lng: 18.5156414 },
    { lat: 48.9113746, lng: 18.5150355 },
    { lat: 48.9111246, lng: 18.5148163 },
    { lat: 48.9106479, lng: 18.5141184 },
    { lat: 48.9099778, lng: 18.513083 },
    { lat: 48.909657, lng: 18.5125801 },
    { lat: 48.9093898, lng: 18.512396 },
    { lat: 48.9086456, lng: 18.5120093 },
    { lat: 48.908464, lng: 18.511946 },
    { lat: 48.9080111, lng: 18.5117734 },
    { lat: 48.9077027, lng: 18.5116401 },
    { lat: 48.9073996, lng: 18.5115817 },
    { lat: 48.9070888, lng: 18.5115092 },
    { lat: 48.9065473, lng: 18.511427 },
    { lat: 48.9061317, lng: 18.5117685 },
    { lat: 48.9056111, lng: 18.511987 },
    { lat: 48.905115, lng: 18.5121121 },
    { lat: 48.90487, lng: 18.5120891 },
    { lat: 48.9047389, lng: 18.5120432 },
    { lat: 48.9045767, lng: 18.5120463 },
    { lat: 48.9043516, lng: 18.5119713 },
    { lat: 48.9041284, lng: 18.511809 },
    { lat: 48.9038624, lng: 18.5117079 },
    { lat: 48.9037722, lng: 18.511649 },
    { lat: 48.9033343, lng: 18.5115 },
    { lat: 48.902947, lng: 18.5114489 },
    { lat: 48.9025422, lng: 18.5115994 },
    { lat: 48.902134, lng: 18.5117024 },
    { lat: 48.9019239, lng: 18.5117201 },
    { lat: 48.901808, lng: 18.5117239 },
    { lat: 48.9016362, lng: 18.5116774 },
    { lat: 48.9014082, lng: 18.511653 },
    { lat: 48.9011854, lng: 18.5116634 },
    { lat: 48.9009855, lng: 18.5116602 },
    { lat: 48.9006431, lng: 18.5115659 },
    { lat: 48.9003536, lng: 18.5115334 },
    { lat: 48.9001811, lng: 18.5113865 },
    { lat: 48.9000053, lng: 18.5112451 },
    { lat: 48.8997532, lng: 18.5108222 },
    { lat: 48.8993046, lng: 18.5100435 },
    { lat: 48.8989952, lng: 18.5097271 },
    { lat: 48.8987304, lng: 18.5094449 },
    { lat: 48.8984797, lng: 18.5091258 },
    { lat: 48.8980146, lng: 18.5086027 },
    { lat: 48.8979083, lng: 18.5085394 },
    { lat: 48.897857, lng: 18.5083411 },
    { lat: 48.8977728, lng: 18.5081617 },
    { lat: 48.8976365, lng: 18.5080031 },
    { lat: 48.8975788, lng: 18.5079548 },
    { lat: 48.8974853, lng: 18.5079192 },
    { lat: 48.8970207, lng: 18.5079808 },
    { lat: 48.8968354, lng: 18.5079987 },
    { lat: 48.8967109, lng: 18.5079907 },
    { lat: 48.8966322, lng: 18.5079131 },
    { lat: 48.8963125, lng: 18.5074919 },
    { lat: 48.8962386, lng: 18.5074367 },
    { lat: 48.8961361, lng: 18.5073994 },
    { lat: 48.895844, lng: 18.507378 },
    { lat: 48.8957145, lng: 18.5073842 },
    { lat: 48.8954674, lng: 18.5073217 },
    { lat: 48.8953219, lng: 18.5072583 },
    { lat: 48.8950761, lng: 18.5071974 },
    { lat: 48.8950298, lng: 18.5071986 },
    { lat: 48.8949169, lng: 18.5072396 },
    { lat: 48.894857, lng: 18.5072766 },
    { lat: 48.8947207, lng: 18.5074027 },
    { lat: 48.8944507, lng: 18.5077875 },
    { lat: 48.8944106, lng: 18.5078628 },
    { lat: 48.8942381, lng: 18.5085043 },
    { lat: 48.8941827, lng: 18.5086009 },
    { lat: 48.8940032, lng: 18.5086823 },
    { lat: 48.8938337, lng: 18.5086992 },
    { lat: 48.8937251, lng: 18.5086925 },
    { lat: 48.8934346, lng: 18.5085978 },
    { lat: 48.8931624, lng: 18.508447 },
    { lat: 48.8928916, lng: 18.5082591 },
    { lat: 48.892652, lng: 18.5080053 },
    { lat: 48.8923651, lng: 18.507603 },
    { lat: 48.891995, lng: 18.5072928 },
    { lat: 48.8918392, lng: 18.5072482 },
    { lat: 48.8916923, lng: 18.5072201 },
    { lat: 48.8910494, lng: 18.5073073 },
    { lat: 48.8910316, lng: 18.5073715 },
    { lat: 48.8910413, lng: 18.5074442 },
    { lat: 48.8913503, lng: 18.5076319 },
    { lat: 48.8914938, lng: 18.507759 },
    { lat: 48.8916883, lng: 18.5080568 },
    { lat: 48.8917692, lng: 18.5082395 },
    { lat: 48.891863, lng: 18.5084683 },
    { lat: 48.8919639, lng: 18.5086015 },
    { lat: 48.8924683, lng: 18.5091429 },
    { lat: 48.8924998, lng: 18.5092436 },
    { lat: 48.8923812, lng: 18.5098403 },
    { lat: 48.8923206, lng: 18.5098862 },
    { lat: 48.8922639, lng: 18.5106239 },
    { lat: 48.8920659, lng: 18.510676 },
    { lat: 48.8918864, lng: 18.5105882 },
    { lat: 48.8914181, lng: 18.510172 },
    { lat: 48.891298, lng: 18.5102674 },
    { lat: 48.8909616, lng: 18.5107993 },
    { lat: 48.8907842, lng: 18.5108793 },
    { lat: 48.8905476, lng: 18.5113589 },
    { lat: 48.8904797, lng: 18.5116835 },
    { lat: 48.8906212, lng: 18.511798 },
    { lat: 48.8907197, lng: 18.5118445 },
    { lat: 48.8907759, lng: 18.5119034 },
    { lat: 48.8909231, lng: 18.5119555 },
    { lat: 48.8910024, lng: 18.5120653 },
    { lat: 48.8910562, lng: 18.5120599 },
    { lat: 48.8911382, lng: 18.5121152 },
    { lat: 48.8911878, lng: 18.5121975 },
    { lat: 48.8913266, lng: 18.5123192 },
    { lat: 48.8913571, lng: 18.5123182 },
    { lat: 48.8914479, lng: 18.5124531 },
    { lat: 48.8916099, lng: 18.5125295 },
    { lat: 48.8916376, lng: 18.5126038 },
    { lat: 48.8916797, lng: 18.5126513 },
    { lat: 48.8917617, lng: 18.5126858 },
    { lat: 48.8918483, lng: 18.5128047 },
    { lat: 48.8918859, lng: 18.5128358 },
    { lat: 48.8918971, lng: 18.5128935 },
    { lat: 48.8921039, lng: 18.5131095 },
    { lat: 48.8921892, lng: 18.5132049 },
    { lat: 48.8922326, lng: 18.5132152 },
    { lat: 48.8922599, lng: 18.5132609 },
    { lat: 48.8923598, lng: 18.5133323 },
    { lat: 48.8924257, lng: 18.5134659 },
    { lat: 48.8924856, lng: 18.5135068 },
    { lat: 48.8925507, lng: 18.5135219 },
    { lat: 48.8926288, lng: 18.5135706 },
    { lat: 48.8923385, lng: 18.5139952 },
    { lat: 48.8922131, lng: 18.5142178 },
    { lat: 48.8923207, lng: 18.5143538 },
    { lat: 48.8924384, lng: 18.5144612 },
    { lat: 48.8925752, lng: 18.5146111 },
    { lat: 48.8930695, lng: 18.5150728 },
    { lat: 48.8933736, lng: 18.5154777 },
    { lat: 48.8937541, lng: 18.516044 },
    { lat: 48.8939186, lng: 18.5164338 },
    { lat: 48.8940299, lng: 18.5168791 },
    { lat: 48.8941309, lng: 18.5172241 },
    { lat: 48.8942467, lng: 18.5177133 },
    { lat: 48.8943181, lng: 18.5179251 },
    { lat: 48.8944927, lng: 18.5183356 },
    { lat: 48.8945591, lng: 18.5186004 },
    { lat: 48.8945967, lng: 18.5189162 },
    { lat: 48.894688, lng: 18.5194123 },
    { lat: 48.894781, lng: 18.5197946 },
    { lat: 48.894874, lng: 18.5202794 },
    { lat: 48.8949935, lng: 18.5206936 },
    { lat: 48.8950623, lng: 18.5210011 },
    { lat: 48.8952123, lng: 18.5214376 },
    { lat: 48.8954215, lng: 18.5218419 },
    { lat: 48.895532, lng: 18.5221682 },
    { lat: 48.8956152, lng: 18.5226192 },
    { lat: 48.895612, lng: 18.5230164 },
    { lat: 48.8956965, lng: 18.523222 },
    { lat: 48.8958956, lng: 18.523524 },
    { lat: 48.8960444, lng: 18.5238735 },
    { lat: 48.8962517, lng: 18.5243458 },
    { lat: 48.8964246, lng: 18.5246597 },
    { lat: 48.8966315, lng: 18.5250446 },
    { lat: 48.8969966, lng: 18.5255764 },
    { lat: 48.897079, lng: 18.5257439 },
    { lat: 48.8971663, lng: 18.5259803 },
    { lat: 48.8972184, lng: 18.526229 },
    { lat: 48.8972434, lng: 18.5264618 },
    { lat: 48.8972626, lng: 18.5267924 },
    { lat: 48.8971902, lng: 18.5274729 },
    { lat: 48.8966699, lng: 18.5282748 },
    { lat: 48.8964511, lng: 18.5285248 },
    { lat: 48.8963511, lng: 18.5286754 },
    { lat: 48.896237, lng: 18.5288596 },
    { lat: 48.8960258, lng: 18.5291251 },
    { lat: 48.8954763, lng: 18.5300124 },
    { lat: 48.8952979, lng: 18.5304226 },
    { lat: 48.8947075, lng: 18.5316558 },
    { lat: 48.8945333, lng: 18.5319597 },
    { lat: 48.8944078, lng: 18.5325769 },
    { lat: 48.8943168, lng: 18.5331633 },
    { lat: 48.8942398, lng: 18.5340085 },
    { lat: 48.8943633, lng: 18.5340404 },
    { lat: 48.8945684, lng: 18.5347614 },
    { lat: 48.8944736, lng: 18.5348647 },
    { lat: 48.8945735, lng: 18.5350189 },
    { lat: 48.8949747, lng: 18.5355555 },
    { lat: 48.8950704, lng: 18.5356948 },
    { lat: 48.8951078, lng: 18.5358038 },
    { lat: 48.8951451, lng: 18.5359687 },
    { lat: 48.8951693, lng: 18.5361653 },
    { lat: 48.8952045, lng: 18.5365219 },
    { lat: 48.895254, lng: 18.5369147 },
    { lat: 48.895255, lng: 18.5375333 },
    { lat: 48.8952652, lng: 18.5376607 },
    { lat: 48.8954378, lng: 18.5376011 },
    { lat: 48.8955563, lng: 18.538532 },
    { lat: 48.8953563, lng: 18.5386104 },
    { lat: 48.8953529, lng: 18.5389826 },
    { lat: 48.8953839, lng: 18.5395347 },
    { lat: 48.8953854, lng: 18.5395406 },
    { lat: 48.8953941, lng: 18.5398664 },
    { lat: 48.8953917, lng: 18.5399883 },
    { lat: 48.8952181, lng: 18.5399903 },
    { lat: 48.8951969, lng: 18.5400348 },
    { lat: 48.8952416, lng: 18.5401748 },
    { lat: 48.8952762, lng: 18.5403325 },
    { lat: 48.8952454, lng: 18.5404321 },
    { lat: 48.8950913, lng: 18.5405395 },
    { lat: 48.8950306, lng: 18.5407751 },
    { lat: 48.8950275, lng: 18.5408852 },
    { lat: 48.8949114, lng: 18.5412366 },
    { lat: 48.8952282, lng: 18.5413817 },
    { lat: 48.8953111, lng: 18.540953 },
    { lat: 48.8953632, lng: 18.5409378 },
    { lat: 48.8953935, lng: 18.5409946 },
    { lat: 48.8955654, lng: 18.5410276 },
    { lat: 48.8957777, lng: 18.5410495 },
    { lat: 48.8957521, lng: 18.541223 },
    { lat: 48.895556, lng: 18.5412891 },
    { lat: 48.8955309, lng: 18.5413062 },
    { lat: 48.8954928, lng: 18.5413095 },
    { lat: 48.895452, lng: 18.5413275 },
    { lat: 48.8954121, lng: 18.5415074 },
    { lat: 48.8953924, lng: 18.5414974 },
    { lat: 48.8953468, lng: 18.5416547 },
    { lat: 48.8952643, lng: 18.5416109 },
    { lat: 48.8952197, lng: 18.5418244 },
    { lat: 48.8951838, lng: 18.5420386 },
    { lat: 48.8950486, lng: 18.5424734 },
    { lat: 48.8946717, lng: 18.5435716 },
    { lat: 48.8945418, lng: 18.5439875 },
    { lat: 48.8945038, lng: 18.5440532 },
    { lat: 48.8944123, lng: 18.5441739 },
    { lat: 48.8942927, lng: 18.5443014 },
    { lat: 48.8943271, lng: 18.5443506 },
    { lat: 48.8940855, lng: 18.5448464 },
    { lat: 48.8940041, lng: 18.5447583 },
    { lat: 48.8938922, lng: 18.5450472 },
    { lat: 48.8935366, lng: 18.5457247 },
    { lat: 48.8934606, lng: 18.5458442 },
    { lat: 48.8933709, lng: 18.5459558 },
    { lat: 48.8932062, lng: 18.5460821 },
    { lat: 48.8931003, lng: 18.5461262 },
    { lat: 48.8928084, lng: 18.5462734 },
    { lat: 48.8926589, lng: 18.5463673 },
    { lat: 48.892192, lng: 18.5466784 },
    { lat: 48.8920578, lng: 18.5467844 },
    { lat: 48.891937, lng: 18.5469059 },
    { lat: 48.891779, lng: 18.5470919 },
    { lat: 48.8916034, lng: 18.5473085 },
    { lat: 48.8915163, lng: 18.5474463 },
    { lat: 48.8914102, lng: 18.5476344 },
    { lat: 48.8912771, lng: 18.5479246 },
    { lat: 48.8911754, lng: 18.5482115 },
    { lat: 48.8910445, lng: 18.5484178 },
    { lat: 48.8910938, lng: 18.548498 },
    { lat: 48.8910489, lng: 18.5485635 },
    { lat: 48.8910228, lng: 18.548932 },
    { lat: 48.8909367, lng: 18.5494263 },
    { lat: 48.8910369, lng: 18.5495482 },
    { lat: 48.8914925, lng: 18.5496899 },
    { lat: 48.8918761, lng: 18.5497782 },
    { lat: 48.8920782, lng: 18.5498003 },
    { lat: 48.8921124, lng: 18.5495143 },
    { lat: 48.8925015, lng: 18.5495987 },
    { lat: 48.8925934, lng: 18.5496285 },
    { lat: 48.8929432, lng: 18.5498375 },
    { lat: 48.8931374, lng: 18.5499664 },
    { lat: 48.8936225, lng: 18.5502811 },
    { lat: 48.8936317, lng: 18.5503033 },
    { lat: 48.893555, lng: 18.5506362 },
    { lat: 48.8937252, lng: 18.5507411 },
    { lat: 48.8939586, lng: 18.5509041 },
    { lat: 48.8941938, lng: 18.551063 },
    { lat: 48.894296, lng: 18.551156 },
    { lat: 48.8943912, lng: 18.55129 },
    { lat: 48.8945886, lng: 18.551417 },
    { lat: 48.8947378, lng: 18.5515439 },
    { lat: 48.8949623, lng: 18.5518575 },
    { lat: 48.8950147, lng: 18.5519076 },
    { lat: 48.8951183, lng: 18.551614 },
    { lat: 48.8952856, lng: 18.551748 },
    { lat: 48.8956367, lng: 18.5525419 },
    { lat: 48.8955094, lng: 18.5528138 },
    { lat: 48.8954075, lng: 18.5528917 },
    { lat: 48.8953845, lng: 18.5527393 },
    { lat: 48.8953139, lng: 18.5524951 },
    { lat: 48.89525, lng: 18.5523597 },
    { lat: 48.8951554, lng: 18.552237 },
    { lat: 48.8950407, lng: 18.5523653 },
    { lat: 48.8946783, lng: 18.5520499 },
    { lat: 48.8947093, lng: 18.5519339 },
    { lat: 48.8945973, lng: 18.5518471 },
    { lat: 48.8944995, lng: 18.5521009 },
    { lat: 48.8944085, lng: 18.551856 },
    { lat: 48.8943666, lng: 18.551668 },
    { lat: 48.8943036, lng: 18.5516483 },
    { lat: 48.8943095, lng: 18.5516135 },
    { lat: 48.8942201, lng: 18.5515856 },
    { lat: 48.8940146, lng: 18.5514793 },
    { lat: 48.8939943, lng: 18.5512707 },
    { lat: 48.8935113, lng: 18.5508725 },
    { lat: 48.8934164, lng: 18.5508915 },
    { lat: 48.8933181, lng: 18.5508453 },
    { lat: 48.8932704, lng: 18.5507538 },
    { lat: 48.8931712, lng: 18.5506557 },
    { lat: 48.8930623, lng: 18.5505173 },
    { lat: 48.8929908, lng: 18.5505707 },
    { lat: 48.8928817, lng: 18.5504947 },
    { lat: 48.8927773, lng: 18.5504615 },
    { lat: 48.8927161, lng: 18.5503742 },
    { lat: 48.8926088, lng: 18.55037 },
    { lat: 48.8924041, lng: 18.5503167 },
    { lat: 48.8923145, lng: 18.550187 },
    { lat: 48.8922074, lng: 18.5501251 },
    { lat: 48.8920511, lng: 18.5500055 },
    { lat: 48.8916758, lng: 18.5499498 },
    { lat: 48.8911282, lng: 18.549816 },
    { lat: 48.891012, lng: 18.549795 },
    { lat: 48.8908555, lng: 18.5499655 },
    { lat: 48.8902662, lng: 18.550125 },
    { lat: 48.8902131, lng: 18.5501621 },
    { lat: 48.8901886, lng: 18.5499397 },
    { lat: 48.8900141, lng: 18.5501138 },
    { lat: 48.8898684, lng: 18.5502772 },
    { lat: 48.8897421, lng: 18.5504016 },
    { lat: 48.8894869, lng: 18.5505821 },
    { lat: 48.8891558, lng: 18.5507561 },
    { lat: 48.8891972, lng: 18.5509194 },
    { lat: 48.8890288, lng: 18.5510995 },
    { lat: 48.888872, lng: 18.5512453 },
    { lat: 48.8888003, lng: 18.5510044 },
    { lat: 48.8885896, lng: 18.5511554 },
    { lat: 48.8883306, lng: 18.5513483 },
    { lat: 48.8882152, lng: 18.5514258 },
    { lat: 48.8880621, lng: 18.5515743 },
    { lat: 48.8879267, lng: 18.5517195 },
    { lat: 48.8877829, lng: 18.5518575 },
    { lat: 48.88764, lng: 18.5520068 },
    { lat: 48.8875133, lng: 18.5521237 },
    { lat: 48.8873795, lng: 18.5522361 },
    { lat: 48.8871964, lng: 18.5523776 },
    { lat: 48.8865613, lng: 18.5528536 },
    { lat: 48.8864047, lng: 18.5530419 },
    { lat: 48.8863169, lng: 18.553193 },
    { lat: 48.8862857, lng: 18.5531567 },
    { lat: 48.8862415, lng: 18.5532795 },
    { lat: 48.8860706, lng: 18.5538502 },
    { lat: 48.8858262, lng: 18.5543169 },
    { lat: 48.8853974, lng: 18.554895 },
    { lat: 48.8853182, lng: 18.5550135 },
    { lat: 48.8851155, lng: 18.5552886 },
    { lat: 48.8850723, lng: 18.5553678 },
    { lat: 48.8849789, lng: 18.5554995 },
    { lat: 48.8848877, lng: 18.5556037 },
    { lat: 48.8847043, lng: 18.5557789 },
    { lat: 48.8845323, lng: 18.5558494 },
    { lat: 48.8845552, lng: 18.5559182 },
    { lat: 48.884366, lng: 18.5561959 },
    { lat: 48.8843263, lng: 18.5563582 },
    { lat: 48.8844103, lng: 18.5565993 },
    { lat: 48.8844332, lng: 18.5566857 },
    { lat: 48.8845692, lng: 18.5570533 },
    { lat: 48.884388, lng: 18.5571641 },
    { lat: 48.8843441, lng: 18.5571659 },
    { lat: 48.884249, lng: 18.5572212 },
    { lat: 48.8839099, lng: 18.557396 },
    { lat: 48.8838744, lng: 18.5573044 },
    { lat: 48.8838077, lng: 18.5573421 },
    { lat: 48.8837237, lng: 18.5570616 },
    { lat: 48.8836997, lng: 18.5570757 },
    { lat: 48.8836451, lng: 18.5569015 },
    { lat: 48.8836117, lng: 18.5567444 },
    { lat: 48.8835725, lng: 18.5566224 },
    { lat: 48.8835877, lng: 18.5566108 },
    { lat: 48.8835627, lng: 18.5565021 },
    { lat: 48.8833543, lng: 18.5566349 },
    { lat: 48.8830299, lng: 18.5568495 },
    { lat: 48.8826982, lng: 18.5571191 },
    { lat: 48.8821432, lng: 18.5576202 },
    { lat: 48.8819879, lng: 18.55779 },
    { lat: 48.8817014, lng: 18.558021 },
    { lat: 48.8813194, lng: 18.558298 },
    { lat: 48.8812118, lng: 18.5583723 },
    { lat: 48.8809847, lng: 18.5585945 },
    { lat: 48.8808832, lng: 18.5587193 },
    { lat: 48.8807225, lng: 18.5589597 },
    { lat: 48.8805237, lng: 18.5592461 },
    { lat: 48.8804313, lng: 18.5594135 },
    { lat: 48.8803584, lng: 18.5595674 },
    { lat: 48.8802331, lng: 18.5597944 },
    { lat: 48.8802803, lng: 18.5598548 },
    { lat: 48.8803389, lng: 18.5598103 },
    { lat: 48.8804543, lng: 18.5595235 },
    { lat: 48.8804915, lng: 18.5593992 },
    { lat: 48.8805661, lng: 18.5593186 },
    { lat: 48.8806854, lng: 18.559627 },
    { lat: 48.8805533, lng: 18.5597925 },
    { lat: 48.880496, lng: 18.5599405 },
    { lat: 48.8804618, lng: 18.5600594 },
    { lat: 48.8805595, lng: 18.5601347 },
    { lat: 48.8804727, lng: 18.5606565 },
    { lat: 48.8804716, lng: 18.5607807 },
    { lat: 48.8805875, lng: 18.5607551 },
    { lat: 48.8806816, lng: 18.5607656 },
    { lat: 48.8807093, lng: 18.5607364 },
    { lat: 48.8809299, lng: 18.5607458 },
    { lat: 48.8812251, lng: 18.5608026 },
    { lat: 48.88128, lng: 18.5607946 },
    { lat: 48.8815385, lng: 18.5608628 },
    { lat: 48.8815632, lng: 18.5610267 },
    { lat: 48.8815314, lng: 18.5612127 },
    { lat: 48.8815071, lng: 18.5613039 },
    { lat: 48.8812888, lng: 18.561415 },
    { lat: 48.8812879, lng: 18.5615036 },
    { lat: 48.8811906, lng: 18.5615841 },
    { lat: 48.8811567, lng: 18.5617034 },
    { lat: 48.8810759, lng: 18.5618568 },
    { lat: 48.8810316, lng: 18.5621049 },
    { lat: 48.8808654, lng: 18.5619941 },
    { lat: 48.880822, lng: 18.5621042 },
    { lat: 48.8806844, lng: 18.5623793 },
    { lat: 48.8806387, lng: 18.5623463 },
    { lat: 48.8806198, lng: 18.5623508 },
    { lat: 48.8806038, lng: 18.562742 },
    { lat: 48.8806815, lng: 18.5627843 },
    { lat: 48.8806783, lng: 18.5629728 },
    { lat: 48.8803793, lng: 18.5629509 },
    { lat: 48.8803329, lng: 18.5631771 },
    { lat: 48.8802368, lng: 18.5633406 },
    { lat: 48.8801462, lng: 18.5634788 },
    { lat: 48.880104, lng: 18.5635105 },
    { lat: 48.880132, lng: 18.5636142 },
    { lat: 48.8801053, lng: 18.563849 },
    { lat: 48.8799683, lng: 18.5638819 },
    { lat: 48.8798995, lng: 18.5639635 },
    { lat: 48.880138, lng: 18.5640557 },
    { lat: 48.8800883, lng: 18.564159 },
    { lat: 48.880117, lng: 18.5641873 },
    { lat: 48.8804155, lng: 18.564764 },
    { lat: 48.8803182, lng: 18.5648438 },
    { lat: 48.8803705, lng: 18.5648936 },
    { lat: 48.8803861, lng: 18.5649927 },
    { lat: 48.880328, lng: 18.5651071 },
    { lat: 48.8801376, lng: 18.5650921 },
    { lat: 48.8801138, lng: 18.5652115 },
    { lat: 48.8799773, lng: 18.5654012 },
    { lat: 48.8799686, lng: 18.5654898 },
    { lat: 48.8799084, lng: 18.5655653 },
    { lat: 48.8795203, lng: 18.5659569 },
    { lat: 48.8795376, lng: 18.5660283 },
    { lat: 48.8794352, lng: 18.5662191 },
    { lat: 48.8793146, lng: 18.5663889 },
    { lat: 48.8791503, lng: 18.5663757 },
    { lat: 48.8790635, lng: 18.5664176 },
    { lat: 48.8791919, lng: 18.566645 },
    { lat: 48.8792454, lng: 18.566696 },
    { lat: 48.8792671, lng: 18.5667431 },
    { lat: 48.8792351, lng: 18.5670019 },
    { lat: 48.8791626, lng: 18.5673532 },
    { lat: 48.8789581, lng: 18.5673474 },
    { lat: 48.8787475, lng: 18.5673296 },
    { lat: 48.8787406, lng: 18.5674118 },
    { lat: 48.8788002, lng: 18.5677226 },
    { lat: 48.8787476, lng: 18.5678567 },
    { lat: 48.8785538, lng: 18.5679833 },
    { lat: 48.8783388, lng: 18.5680759 },
    { lat: 48.8782472, lng: 18.5681314 },
    { lat: 48.8781949, lng: 18.568187 },
    { lat: 48.8783001, lng: 18.5682782 },
    { lat: 48.8781868, lng: 18.5684736 },
    { lat: 48.8778549, lng: 18.5691144 },
    { lat: 48.8778272, lng: 18.569149 },
    { lat: 48.8776867, lng: 18.5690289 },
    { lat: 48.8775859, lng: 18.5692704 },
    { lat: 48.877534, lng: 18.5692299 },
    { lat: 48.877373, lng: 18.5696133 },
    { lat: 48.8772867, lng: 18.5698301 },
    { lat: 48.8771496, lng: 18.5702155 },
    { lat: 48.8769959, lng: 18.5701049 },
    { lat: 48.8769911, lng: 18.5697887 },
    { lat: 48.876997, lng: 18.5696877 },
    { lat: 48.8769593, lng: 18.5696375 },
    { lat: 48.8765849, lng: 18.5708257 },
    { lat: 48.8764616, lng: 18.5714036 },
    { lat: 48.8764374, lng: 18.5715589 },
    { lat: 48.8764014, lng: 18.5718766 },
    { lat: 48.8766286, lng: 18.5720533 },
    { lat: 48.8766052, lng: 18.5722243 },
    { lat: 48.8763998, lng: 18.5721803 },
    { lat: 48.8763262, lng: 18.5722177 },
    { lat: 48.8762103, lng: 18.5722207 },
    { lat: 48.8761242, lng: 18.5722361 },
    { lat: 48.8761383, lng: 18.572393 },
    { lat: 48.8761211, lng: 18.5724891 },
    { lat: 48.8760875, lng: 18.5725986 },
    { lat: 48.875988, lng: 18.5726995 },
    { lat: 48.8759517, lng: 18.5728193 },
    { lat: 48.8759744, lng: 18.5729276 },
    { lat: 48.8760174, lng: 18.5729925 },
    { lat: 48.875999, lng: 18.5732173 },
    { lat: 48.8759579, lng: 18.5734349 },
    { lat: 48.8759129, lng: 18.5735659 },
    { lat: 48.8759037, lng: 18.5740375 },
    { lat: 48.8759707, lng: 18.5743811 },
    { lat: 48.8759706, lng: 18.5745276 },
    { lat: 48.8760516, lng: 18.5748153 },
    { lat: 48.8760991, lng: 18.5750244 },
    { lat: 48.8760669, lng: 18.5753914 },
    { lat: 48.8760676, lng: 18.5756099 },
    { lat: 48.8760834, lng: 18.5758412 },
    { lat: 48.8760617, lng: 18.576003 },
    { lat: 48.8760199, lng: 18.5761702 },
    { lat: 48.8760201, lng: 18.5762525 },
    { lat: 48.8760207, lng: 18.5764779 },
    { lat: 48.8760381, lng: 18.57659 },
    { lat: 48.8760232, lng: 18.5766907 },
    { lat: 48.8759684, lng: 18.5769702 },
    { lat: 48.8759183, lng: 18.5771273 },
    { lat: 48.8758718, lng: 18.5774137 },
    { lat: 48.8758235, lng: 18.5774992 },
    { lat: 48.8755663, lng: 18.5781292 },
    { lat: 48.8755334, lng: 18.5783329 },
    { lat: 48.8755158, lng: 18.5784884 },
    { lat: 48.8755509, lng: 18.5786564 },
    { lat: 48.8755544, lng: 18.5788663 },
    { lat: 48.8755228, lng: 18.5792662 },
    { lat: 48.8754195, lng: 18.5796466 },
    { lat: 48.8753527, lng: 18.5799143 },
    { lat: 48.8753624, lng: 18.5800106 },
    { lat: 48.8752559, lng: 18.5802761 },
    { lat: 48.8751304, lng: 18.5804762 },
    { lat: 48.8749784, lng: 18.5808175 },
    { lat: 48.8750337, lng: 18.5809837 },
    { lat: 48.8750159, lng: 18.5811364 },
    { lat: 48.8749796, lng: 18.581318 },
    { lat: 48.8749976, lng: 18.5814003 },
    { lat: 48.8750315, lng: 18.5817132 },
    { lat: 48.8750012, lng: 18.5822426 },
    { lat: 48.8750176, lng: 18.5822914 },
    { lat: 48.875019, lng: 18.5824898 },
    { lat: 48.8750619, lng: 18.5830126 },
    { lat: 48.8750878, lng: 18.5831144 },
    { lat: 48.8751216, lng: 18.5831335 },
    { lat: 48.8751687, lng: 18.5831907 },
    { lat: 48.8751863, lng: 18.583224 },
    { lat: 48.8751942, lng: 18.5832594 },
    { lat: 48.8752025, lng: 18.5832969 },
    { lat: 48.875142, lng: 18.5834058 },
    { lat: 48.8747328, lng: 18.5832022 },
    { lat: 48.8747087, lng: 18.5831944 },
    { lat: 48.8746937, lng: 18.5832088 },
    { lat: 48.8746657, lng: 18.5834004 },
    { lat: 48.8746208, lng: 18.583488 },
    { lat: 48.8746018, lng: 18.5836577 },
    { lat: 48.8745487, lng: 18.5837818 },
    { lat: 48.8745389, lng: 18.5838536 },
    { lat: 48.8744731, lng: 18.5840316 },
    { lat: 48.8743867, lng: 18.5841247 },
    { lat: 48.8743339, lng: 18.5843165 },
    { lat: 48.8743051, lng: 18.5844534 },
    { lat: 48.8740914, lng: 18.5849643 },
    { lat: 48.8740388, lng: 18.5850759 },
    { lat: 48.8740248, lng: 18.585193 },
    { lat: 48.8738834, lng: 18.5855642 },
    { lat: 48.8738201, lng: 18.5855989 },
    { lat: 48.8736274, lng: 18.5860226 },
    { lat: 48.8736751, lng: 18.5860483 },
    { lat: 48.8741379, lng: 18.5864344 },
    { lat: 48.8740499, lng: 18.5865779 },
    { lat: 48.8740247, lng: 18.5867373 },
    { lat: 48.8740111, lng: 18.586988 },
    { lat: 48.8739875, lng: 18.5871156 },
    { lat: 48.8738125, lng: 18.5873638 },
    { lat: 48.8737762, lng: 18.5875281 },
    { lat: 48.8737968, lng: 18.5875509 },
    { lat: 48.8737213, lng: 18.5878903 },
    { lat: 48.8737696, lng: 18.5881555 },
    { lat: 48.8737556, lng: 18.5883389 },
    { lat: 48.8740201, lng: 18.5885365 },
    { lat: 48.8739233, lng: 18.5887252 },
    { lat: 48.8739268, lng: 18.5889397 },
    { lat: 48.8738398, lng: 18.5892683 },
    { lat: 48.8738196, lng: 18.5894582 },
    { lat: 48.8737765, lng: 18.5896624 },
    { lat: 48.8737711, lng: 18.5898559 },
    { lat: 48.8737334, lng: 18.5899505 },
    { lat: 48.8736512, lng: 18.5899961 },
    { lat: 48.8735358, lng: 18.5903888 },
    { lat: 48.8735358, lng: 18.590511 },
    { lat: 48.8735898, lng: 18.5905921 },
    { lat: 48.8735088, lng: 18.5909321 },
    { lat: 48.8734712, lng: 18.5914925 },
    { lat: 48.8733901, lng: 18.5916412 },
    { lat: 48.8732879, lng: 18.5917922 },
    { lat: 48.8732868, lng: 18.5918776 },
    { lat: 48.873149, lng: 18.5921662 },
    { lat: 48.8731117, lng: 18.5922895 },
    { lat: 48.8729915, lng: 18.592572 },
    { lat: 48.8726905, lng: 18.5927408 },
    { lat: 48.8726905, lng: 18.5928243 },
    { lat: 48.87258, lng: 18.5929927 },
    { lat: 48.8722388, lng: 18.5934463 },
    { lat: 48.8721676, lng: 18.593463 },
    { lat: 48.8720973, lng: 18.593561 },
    { lat: 48.8720875, lng: 18.5936295 },
    { lat: 48.871936, lng: 18.5939364 },
    { lat: 48.8718544, lng: 18.5939927 },
    { lat: 48.8717782, lng: 18.5941272 },
    { lat: 48.8716967, lng: 18.5942057 },
    { lat: 48.8716354, lng: 18.5943233 },
    { lat: 48.8715137, lng: 18.594323 },
    { lat: 48.8714239, lng: 18.594376 },
    { lat: 48.8713604, lng: 18.5944311 },
    { lat: 48.8712144, lng: 18.5945273 },
    { lat: 48.87112, lng: 18.5944695 },
    { lat: 48.8709337, lng: 18.5945306 },
    { lat: 48.8708267, lng: 18.5946359 },
    { lat: 48.8707791, lng: 18.5946121 },
    { lat: 48.8704615, lng: 18.5948277 },
    { lat: 48.8703216, lng: 18.5949958 },
    { lat: 48.8702077, lng: 18.5950168 },
    { lat: 48.8701055, lng: 18.594986 },
    { lat: 48.8699938, lng: 18.5949827 },
    { lat: 48.8698802, lng: 18.5950931 },
    { lat: 48.8697768, lng: 18.5952897 },
    { lat: 48.8697388, lng: 18.5956094 },
    { lat: 48.8696247, lng: 18.5959187 },
    { lat: 48.8694622, lng: 18.59621 },
    { lat: 48.8694288, lng: 18.5962611 },
    { lat: 48.8693047, lng: 18.5964939 },
    { lat: 48.8692824, lng: 18.596602 },
    { lat: 48.8691285, lng: 18.5967357 },
    { lat: 48.8691319, lng: 18.5967597 },
    { lat: 48.8691429, lng: 18.5967762 },
    { lat: 48.8691602, lng: 18.5968461 },
    { lat: 48.8691319, lng: 18.5971376 },
    { lat: 48.8689893, lng: 18.5975655 },
    { lat: 48.8687828, lng: 18.5978386 },
    { lat: 48.8684856, lng: 18.5985787 },
    { lat: 48.8683065, lng: 18.5985439 },
    { lat: 48.8679064, lng: 18.5990111 },
    { lat: 48.8678679, lng: 18.5992176 },
    { lat: 48.8676566, lng: 18.5997525 },
    { lat: 48.8675595, lng: 18.5999422 },
    { lat: 48.8674067, lng: 18.6003258 },
    { lat: 48.8672297, lng: 18.6006422 },
    { lat: 48.8669425, lng: 18.6011341 },
    { lat: 48.8668458, lng: 18.601476 },
    { lat: 48.8668378, lng: 18.601577 },
    { lat: 48.8667689, lng: 18.6017417 },
    { lat: 48.8668062, lng: 18.6020566 },
    { lat: 48.8667051, lng: 18.6023779 },
    { lat: 48.8663622, lng: 18.6030724 },
    { lat: 48.8664047, lng: 18.6031459 },
    { lat: 48.8665084, lng: 18.6032732 },
    { lat: 48.8668927, lng: 18.6038744 },
    { lat: 48.8669507, lng: 18.6040747 },
    { lat: 48.8674209, lng: 18.6051386 },
    { lat: 48.867476, lng: 18.6053238 },
    { lat: 48.8673428, lng: 18.6059726 },
    { lat: 48.8671851, lng: 18.6067523 },
    { lat: 48.8671299, lng: 18.6069826 },
    { lat: 48.8671451, lng: 18.6072433 },
    { lat: 48.8672725, lng: 18.6076005 },
    { lat: 48.8675224, lng: 18.6078437 },
    { lat: 48.867535, lng: 18.6082878 },
    { lat: 48.8672964, lng: 18.6086945 },
    { lat: 48.8672221, lng: 18.6090982 },
    { lat: 48.8666768, lng: 18.6104298 },
    { lat: 48.866512, lng: 18.6108066 },
    { lat: 48.8663604, lng: 18.6111739 },
    { lat: 48.8662158, lng: 18.6115034 },
    { lat: 48.8660211, lng: 18.6120139 },
    { lat: 48.8658817, lng: 18.6123632 },
    { lat: 48.8657693, lng: 18.6126196 },
    { lat: 48.8651484, lng: 18.6139956 },
    { lat: 48.8649166, lng: 18.6147185 },
    { lat: 48.8645229, lng: 18.6159772 },
    { lat: 48.8637386, lng: 18.6185947 },
    { lat: 48.863521, lng: 18.6190582 },
    { lat: 48.863033, lng: 18.6201154 },
    { lat: 48.8626742, lng: 18.6208058 },
    { lat: 48.8618175, lng: 18.6224102 },
    { lat: 48.8612305, lng: 18.6240643 },
    { lat: 48.8612253, lng: 18.624156 },
    { lat: 48.8611994, lng: 18.6241574 },
    { lat: 48.8611698, lng: 18.6242362 },
    { lat: 48.8610981, lng: 18.6242211 },
    { lat: 48.8605481, lng: 18.6243784 },
    { lat: 48.86042, lng: 18.6244716 },
    { lat: 48.8603203, lng: 18.62438 },
    { lat: 48.8599398, lng: 18.6247722 },
    { lat: 48.8592113, lng: 18.6254046 },
    { lat: 48.8580795, lng: 18.6235425 },
    { lat: 48.8577352, lng: 18.6242737 },
    { lat: 48.857672, lng: 18.6242281 },
    { lat: 48.857547, lng: 18.6244441 },
    { lat: 48.8572472, lng: 18.6250377 },
    { lat: 48.857067, lng: 18.6252282 },
    { lat: 48.8568831, lng: 18.6255238 },
    { lat: 48.8568549, lng: 18.6254832 },
    { lat: 48.8565946, lng: 18.6259913 },
    { lat: 48.8563285, lng: 18.6262648 },
    { lat: 48.8560937, lng: 18.6264491 },
    { lat: 48.8559456, lng: 18.6266138 },
    { lat: 48.8557898, lng: 18.6267584 },
    { lat: 48.8555077, lng: 18.6269844 },
    { lat: 48.8552756, lng: 18.6272619 },
    { lat: 48.8554493, lng: 18.6277455 },
    { lat: 48.8558298, lng: 18.628892 },
    { lat: 48.8559339, lng: 18.6292338 },
    { lat: 48.8560845, lng: 18.6297722 },
    { lat: 48.8562046, lng: 18.6302445 },
    { lat: 48.8562935, lng: 18.6307038 },
    { lat: 48.8564454, lng: 18.6314315 },
    { lat: 48.8565111, lng: 18.6316907 },
    { lat: 48.8564689, lng: 18.6317241 },
    { lat: 48.8567505, lng: 18.6330634 },
    { lat: 48.8566703, lng: 18.6331062 },
    { lat: 48.8566327, lng: 18.6330969 },
    { lat: 48.856506, lng: 18.6332097 },
    { lat: 48.8564712, lng: 18.6332407 },
    { lat: 48.8561939, lng: 18.6333274 },
    { lat: 48.8561969, lng: 18.6333826 },
    { lat: 48.856213, lng: 18.6334537 },
    { lat: 48.8563511, lng: 18.6341974 },
    { lat: 48.8561084, lng: 18.6345324 },
    { lat: 48.8556804, lng: 18.6350722 },
    { lat: 48.8556199, lng: 18.6351416 },
    { lat: 48.8554856, lng: 18.6352243 },
    { lat: 48.8554146, lng: 18.6352453 },
    { lat: 48.8553371, lng: 18.6352505 },
    { lat: 48.854998, lng: 18.6352145 },
    { lat: 48.8548806, lng: 18.6354241 },
    { lat: 48.85481, lng: 18.635518 },
    { lat: 48.854669, lng: 18.6355991 },
    { lat: 48.8543849, lng: 18.6357082 },
    { lat: 48.8543361, lng: 18.6357031 },
    { lat: 48.8541493, lng: 18.6357544 },
    { lat: 48.8540869, lng: 18.6357277 },
    { lat: 48.8540411, lng: 18.6357329 },
    { lat: 48.8540142, lng: 18.6360109 },
    { lat: 48.8540344, lng: 18.636238 },
    { lat: 48.8540658, lng: 18.6363001 },
    { lat: 48.8540701, lng: 18.6363568 },
    { lat: 48.8539283, lng: 18.6375673 },
    { lat: 48.8539018, lng: 18.6378273 },
    { lat: 48.8538238, lng: 18.6382237 },
    { lat: 48.8537293, lng: 18.6388209 },
    { lat: 48.8538387, lng: 18.6394309 },
    { lat: 48.8539183, lng: 18.6398223 },
    { lat: 48.8539498, lng: 18.6400541 },
    { lat: 48.8540924, lng: 18.6409065 },
    { lat: 48.8541461, lng: 18.6412967 },
    { lat: 48.8541647, lng: 18.6416042 },
    { lat: 48.8542012, lng: 18.6423489 },
    { lat: 48.8539708, lng: 18.6424915 },
    { lat: 48.8538621, lng: 18.6430088 },
    { lat: 48.8538427, lng: 18.6430688 },
    { lat: 48.8538189, lng: 18.6431115 },
    { lat: 48.8537967, lng: 18.6431378 },
    { lat: 48.8532298, lng: 18.6436072 },
    { lat: 48.8532086, lng: 18.6436355 },
    { lat: 48.8531724, lng: 18.6436522 },
    { lat: 48.8531371, lng: 18.6436468 },
    { lat: 48.8530872, lng: 18.6437027 },
    { lat: 48.8529478, lng: 18.6437342 },
    { lat: 48.8528385, lng: 18.6437358 },
    { lat: 48.8527376, lng: 18.6438119 },
    { lat: 48.8528815, lng: 18.6439257 },
    { lat: 48.8529023, lng: 18.6441072 },
    { lat: 48.852809, lng: 18.6441126 },
    { lat: 48.8527097, lng: 18.6441739 },
    { lat: 48.8526553, lng: 18.6442754 },
    { lat: 48.8527985, lng: 18.644611 },
    { lat: 48.8529445, lng: 18.6448298 },
    { lat: 48.853158, lng: 18.6450091 },
    { lat: 48.8534093, lng: 18.6451301 },
    { lat: 48.8536275, lng: 18.6452011 },
    { lat: 48.8540226, lng: 18.6453152 },
    { lat: 48.8542881, lng: 18.645428 },
    { lat: 48.8544536, lng: 18.6455312 },
    { lat: 48.8545269, lng: 18.6456153 },
    { lat: 48.8545678, lng: 18.6456196 },
    { lat: 48.854872, lng: 18.6460145 },
    { lat: 48.8552592, lng: 18.6466743 },
    { lat: 48.8554286, lng: 18.6469125 },
    { lat: 48.8557235, lng: 18.6471732 },
    { lat: 48.8560749, lng: 18.647442 },
    { lat: 48.8563047, lng: 18.6477308 },
    { lat: 48.856505, lng: 18.6479544 },
    { lat: 48.8566847, lng: 18.6481103 },
    { lat: 48.8568414, lng: 18.6482129 },
    { lat: 48.8571738, lng: 18.6483121 },
    { lat: 48.8571686, lng: 18.6483196 },
    { lat: 48.8575741, lng: 18.6483951 },
    { lat: 48.8578066, lng: 18.6483766 },
    { lat: 48.8579473, lng: 18.6484571 },
    { lat: 48.8582912, lng: 18.6485596 },
    { lat: 48.858339, lng: 18.648654 },
    { lat: 48.8583472, lng: 18.6488293 },
    { lat: 48.8584015, lng: 18.6490003 },
    { lat: 48.8585256, lng: 18.6490635 },
    { lat: 48.8586553, lng: 18.649045 },
    { lat: 48.858799, lng: 18.6489246 },
    { lat: 48.8590037, lng: 18.6489779 },
    { lat: 48.8590781, lng: 18.6489552 },
    { lat: 48.8591473, lng: 18.6489864 },
    { lat: 48.8592286, lng: 18.6492376 },
    { lat: 48.8593065, lng: 18.6493684 },
    { lat: 48.8594463, lng: 18.6493677 },
    { lat: 48.8597474, lng: 18.6493236 },
    { lat: 48.8598158, lng: 18.6492314 },
    { lat: 48.8598776, lng: 18.6491844 },
    { lat: 48.8599087, lng: 18.6492186 },
    { lat: 48.8599237, lng: 18.6492869 },
    { lat: 48.8600497, lng: 18.6494076 },
    { lat: 48.860146, lng: 18.6494352 },
    { lat: 48.8603132, lng: 18.6494028 },
    { lat: 48.8603589, lng: 18.6493686 },
    { lat: 48.8603834, lng: 18.6493409 },
    { lat: 48.8604419, lng: 18.6494046 },
    { lat: 48.860644, lng: 18.6494066 },
    { lat: 48.8606488, lng: 18.6495178 },
    { lat: 48.8606838, lng: 18.6496238 },
    { lat: 48.8607108, lng: 18.6498784 },
    { lat: 48.8607501, lng: 18.6500878 },
    { lat: 48.8607998, lng: 18.6501497 },
    { lat: 48.8608811, lng: 18.6501731 },
    { lat: 48.8609542, lng: 18.6503368 },
    { lat: 48.8610253, lng: 18.6504649 },
    { lat: 48.8609979, lng: 18.6506459 },
    { lat: 48.8610179, lng: 18.6509379 },
    { lat: 48.8611142, lng: 18.6513279 },
    { lat: 48.861126, lng: 18.6513598 },
    { lat: 48.8612537, lng: 18.6515501 },
    { lat: 48.8613846, lng: 18.651807 },
    { lat: 48.8614864, lng: 18.6519173 },
    { lat: 48.8615, lng: 18.6519626 },
    { lat: 48.8615139, lng: 18.6520547 },
    { lat: 48.8615264, lng: 18.6522674 },
    { lat: 48.8615596, lng: 18.6526369 },
    { lat: 48.8615649, lng: 18.6527538 },
    { lat: 48.8615473, lng: 18.6529737 },
    { lat: 48.8616433, lng: 18.6531471 },
    { lat: 48.861704, lng: 18.6532249 },
    { lat: 48.8617222, lng: 18.6534419 },
    { lat: 48.8618405, lng: 18.6536496 },
    { lat: 48.8618604, lng: 18.6536846 },
    { lat: 48.8619582, lng: 18.6537421 },
    { lat: 48.8619632, lng: 18.6539152 },
    { lat: 48.8619664, lng: 18.6540244 },
    { lat: 48.8620014, lng: 18.6542139 },
    { lat: 48.8619555, lng: 18.6544649 },
    { lat: 48.8619368, lng: 18.6545675 },
    { lat: 48.8619454, lng: 18.6546405 },
    { lat: 48.8619666, lng: 18.6548191 },
    { lat: 48.8619894, lng: 18.655012 },
    { lat: 48.8619923, lng: 18.6550364 },
    { lat: 48.8621288, lng: 18.6551397 },
    { lat: 48.8621599, lng: 18.6553243 },
    { lat: 48.8621346, lng: 18.6556128 },
    { lat: 48.8620144, lng: 18.6556412 },
    { lat: 48.8620103, lng: 18.6556802 },
    { lat: 48.8619878, lng: 18.6558955 },
    { lat: 48.8619831, lng: 18.6559402 },
    { lat: 48.8619567, lng: 18.6563747 },
    { lat: 48.8619231, lng: 18.6565905 },
    { lat: 48.8618492, lng: 18.6566228 },
    { lat: 48.8618301, lng: 18.6567749 },
    { lat: 48.8618251, lng: 18.6570184 },
    { lat: 48.8617689, lng: 18.6577624 },
    { lat: 48.8617581, lng: 18.6578357 },
    { lat: 48.8617081, lng: 18.6579959 },
    { lat: 48.8617219, lng: 18.6583796 },
    { lat: 48.861754, lng: 18.6585601 },
    { lat: 48.8617676, lng: 18.6588379 },
    { lat: 48.8617788, lng: 18.658878 },
    { lat: 48.8617673, lng: 18.6591297 },
    { lat: 48.8617648, lng: 18.6591842 },
    { lat: 48.8617729, lng: 18.6592341 },
    { lat: 48.8618172, lng: 18.6593612 },
    { lat: 48.8620673, lng: 18.6600792 },
    { lat: 48.8621006, lng: 18.6602778 },
    { lat: 48.862096, lng: 18.6603304 },
    { lat: 48.8620738, lng: 18.6605816 },
    { lat: 48.8620869, lng: 18.6606496 },
    { lat: 48.8621435, lng: 18.6608919 },
    { lat: 48.8621837, lng: 18.6609537 },
    { lat: 48.8622712, lng: 18.6610883 },
    { lat: 48.8625126, lng: 18.6611963 },
    { lat: 48.8626607, lng: 18.6611183 },
    { lat: 48.8628, lng: 18.6608942 },
    { lat: 48.8629006, lng: 18.6609195 },
    { lat: 48.8630101, lng: 18.6611783 },
    { lat: 48.8630908, lng: 18.6613131 },
    { lat: 48.8631194, lng: 18.6614282 },
    { lat: 48.8633865, lng: 18.6616788 },
    { lat: 48.863564, lng: 18.6618777 },
    { lat: 48.8638058, lng: 18.6622246 },
    { lat: 48.8639429, lng: 18.6625527 },
    { lat: 48.8640417, lng: 18.6627133 },
    { lat: 48.8640818, lng: 18.6628309 },
    { lat: 48.8642266, lng: 18.6630961 },
    { lat: 48.8642409, lng: 18.6632362 },
    { lat: 48.864177, lng: 18.6634737 },
    { lat: 48.8641306, lng: 18.6635557 },
    { lat: 48.8641283, lng: 18.6636634 },
    { lat: 48.8641034, lng: 18.6637453 },
    { lat: 48.8641255, lng: 18.6639263 },
    { lat: 48.8640211, lng: 18.6641884 },
    { lat: 48.8639397, lng: 18.6643338 },
    { lat: 48.8638283, lng: 18.6647833 },
    { lat: 48.8637039, lng: 18.6649064 },
    { lat: 48.8636455, lng: 18.6650163 },
    { lat: 48.8635185, lng: 18.665086 },
    { lat: 48.8635304, lng: 18.6652253 },
    { lat: 48.8634656, lng: 18.6652215 },
    { lat: 48.8634434, lng: 18.6652202 },
    { lat: 48.8633305, lng: 18.6653673 },
    { lat: 48.8631185, lng: 18.6655809 },
    { lat: 48.8630548, lng: 18.6656131 },
    { lat: 48.8629876, lng: 18.6656206 },
    { lat: 48.8629278, lng: 18.6657665 },
    { lat: 48.8629334, lng: 18.6658711 },
    { lat: 48.8628101, lng: 18.6661438 },
    { lat: 48.862789, lng: 18.6661499 },
    { lat: 48.8626991, lng: 18.666176 },
    { lat: 48.8626229, lng: 18.6662677 },
    { lat: 48.8625679, lng: 18.6663787 },
    { lat: 48.8624674, lng: 18.6664841 },
    { lat: 48.8624868, lng: 18.6665509 },
    { lat: 48.8624016, lng: 18.6666326 },
    { lat: 48.8623815, lng: 18.6666518 },
    { lat: 48.8623677, lng: 18.666708 },
    { lat: 48.8623271, lng: 18.6667238 },
    { lat: 48.8622904, lng: 18.6667382 },
    { lat: 48.8622676, lng: 18.6668929 },
    { lat: 48.8622363, lng: 18.6671046 },
    { lat: 48.8622199, lng: 18.6672157 },
    { lat: 48.8622542, lng: 18.6674773 },
    { lat: 48.8622477, lng: 18.6675698 },
    { lat: 48.8622728, lng: 18.6676369 },
    { lat: 48.8622594, lng: 18.6677037 },
    { lat: 48.8622523, lng: 18.6677394 },
    { lat: 48.862326, lng: 18.6677884 },
    { lat: 48.8623829, lng: 18.6679025 },
    { lat: 48.8623982, lng: 18.6679611 },
    { lat: 48.8624126, lng: 18.668016 },
    { lat: 48.8624752, lng: 18.6680918 },
    { lat: 48.8625232, lng: 18.6683789 },
    { lat: 48.8626427, lng: 18.6685022 },
    { lat: 48.8626867, lng: 18.6686716 },
    { lat: 48.8627458, lng: 18.6686805 },
    { lat: 48.8628558, lng: 18.6689049 },
    { lat: 48.8629167, lng: 18.6690769 },
    { lat: 48.8629829, lng: 18.6691967 },
    { lat: 48.8630348, lng: 18.669364 },
    { lat: 48.8631311, lng: 18.6693759 },
    { lat: 48.8631771, lng: 18.6694692 },
    { lat: 48.8632274, lng: 18.6695715 },
    { lat: 48.8632977, lng: 18.6695565 },
    { lat: 48.8633216, lng: 18.6697133 },
    { lat: 48.8634848, lng: 18.6699369 },
    { lat: 48.8635279, lng: 18.6700681 },
    { lat: 48.8636087, lng: 18.6701467 },
    { lat: 48.8637094, lng: 18.6704048 },
    { lat: 48.8638019, lng: 18.6704279 },
    { lat: 48.8638182, lng: 18.6704811 },
    { lat: 48.8638466, lng: 18.6705267 },
    { lat: 48.8639126, lng: 18.6705598 },
    { lat: 48.8639766, lng: 18.6706999 },
    { lat: 48.8640407, lng: 18.6706562 },
    { lat: 48.86407, lng: 18.6707408 },
    { lat: 48.864118, lng: 18.6706883 },
    { lat: 48.8641444, lng: 18.6706113 },
    { lat: 48.864366, lng: 18.6706465 },
    { lat: 48.8645088, lng: 18.6707401 },
    { lat: 48.8646639, lng: 18.6708576 },
    { lat: 48.8647061, lng: 18.6709172 },
    { lat: 48.8647419, lng: 18.6709677 },
    { lat: 48.8648167, lng: 18.6711649 },
    { lat: 48.8648797, lng: 18.6712298 },
    { lat: 48.8649242, lng: 18.6712757 },
    { lat: 48.8649934, lng: 18.6715271 },
    { lat: 48.8650791, lng: 18.6718381 },
    { lat: 48.8651325, lng: 18.6718853 },
    { lat: 48.8651387, lng: 18.6719122 },
    { lat: 48.8651676, lng: 18.6720387 },
    { lat: 48.8652908, lng: 18.6720845 },
    { lat: 48.865459, lng: 18.6723634 },
    { lat: 48.865396, lng: 18.6725351 },
    { lat: 48.8654442, lng: 18.6726589 },
    { lat: 48.8655069, lng: 18.6727752 },
    { lat: 48.8654575, lng: 18.672864 },
    { lat: 48.8654198, lng: 18.6730837 },
    { lat: 48.8654075, lng: 18.6731552 },
    { lat: 48.8654629, lng: 18.6731778 },
    { lat: 48.8653914, lng: 18.6733582 },
    { lat: 48.8652863, lng: 18.6735061 },
    { lat: 48.8651999, lng: 18.6736807 },
    { lat: 48.8651414, lng: 18.6739676 },
    { lat: 48.8650986, lng: 18.6741772 },
    { lat: 48.8650593, lng: 18.6743699 },
    { lat: 48.8650512, lng: 18.6744095 },
    { lat: 48.8650812, lng: 18.6744625 },
    { lat: 48.8650432, lng: 18.6746584 },
    { lat: 48.8649813, lng: 18.6747021 },
    { lat: 48.8649703, lng: 18.6749396 },
    { lat: 48.8650796, lng: 18.6752364 },
    { lat: 48.86515, lng: 18.6753725 },
    { lat: 48.8654843, lng: 18.6756995 },
    { lat: 48.8655653, lng: 18.6758222 },
    { lat: 48.8658574, lng: 18.6764187 },
    { lat: 48.8660431, lng: 18.677999 },
    { lat: 48.866045, lng: 18.6799693 },
    { lat: 48.8660785, lng: 18.679981 },
    { lat: 48.8660964, lng: 18.6816965 },
    { lat: 48.8661221, lng: 18.6819709 },
    { lat: 48.8661803, lng: 18.6823629 },
    { lat: 48.8662329, lng: 18.6825037 },
    { lat: 48.8665272, lng: 18.6829496 },
    { lat: 48.8666683, lng: 18.6831419 },
    { lat: 48.8669232, lng: 18.6834421 },
    { lat: 48.8670464, lng: 18.6835944 },
    { lat: 48.8672021, lng: 18.6838079 },
    { lat: 48.8673208, lng: 18.6839603 },
    { lat: 48.867542, lng: 18.6842009 },
    { lat: 48.867636, lng: 18.684294 },
    { lat: 48.8678029, lng: 18.6844402 },
    { lat: 48.8680155, lng: 18.6845554 },
    { lat: 48.8683845, lng: 18.6849843 },
    { lat: 48.8683617, lng: 18.6850226 },
    { lat: 48.8685009, lng: 18.6851815 },
    { lat: 48.8686376, lng: 18.6852683 },
    { lat: 48.8686928, lng: 18.6853329 },
    { lat: 48.8687591, lng: 18.6853721 },
    { lat: 48.8687803, lng: 18.68541 },
    { lat: 48.8688384, lng: 18.6854623 },
    { lat: 48.8688723, lng: 18.6853812 },
    { lat: 48.8691583, lng: 18.6855809 },
    { lat: 48.8692674, lng: 18.6856822 },
    { lat: 48.869695, lng: 18.6862186 },
    { lat: 48.8698323, lng: 18.68653 },
    { lat: 48.869859, lng: 18.6868857 },
    { lat: 48.8699985, lng: 18.6873677 },
    { lat: 48.8700278, lng: 18.6876669 },
    { lat: 48.8700965, lng: 18.6880429 },
    { lat: 48.8701414, lng: 18.688382 },
    { lat: 48.8702406, lng: 18.6890786 },
    { lat: 48.870249, lng: 18.6892369 },
    { lat: 48.8701738, lng: 18.6900113 },
    { lat: 48.8701136, lng: 18.6903873 },
    { lat: 48.8700671, lng: 18.6912517 },
    { lat: 48.8700812, lng: 18.6913481 },
    { lat: 48.8700846, lng: 18.6917507 },
    { lat: 48.8701738, lng: 18.6921579 },
    { lat: 48.8701671, lng: 18.6926692 },
    { lat: 48.8701438, lng: 18.6932101 },
    { lat: 48.8701637, lng: 18.6936268 },
    { lat: 48.8701511, lng: 18.693834 },
    { lat: 48.8700777, lng: 18.6944057 },
    { lat: 48.8701054, lng: 18.6948032 },
    { lat: 48.8702201, lng: 18.6952023 },
    { lat: 48.8703046, lng: 18.69537 },
    { lat: 48.8704271, lng: 18.6957227 },
    { lat: 48.8705055, lng: 18.6958603 },
    { lat: 48.8707414, lng: 18.6960125 },
    { lat: 48.8712252, lng: 18.6965662 },
    { lat: 48.8713475, lng: 18.6967694 },
    { lat: 48.8714051, lng: 18.6971518 },
    { lat: 48.8714224, lng: 18.6973532 },
    { lat: 48.8714443, lng: 18.6974903 },
    { lat: 48.8714958, lng: 18.6976277 },
    { lat: 48.8715091, lng: 18.6976465 },
    { lat: 48.8715354, lng: 18.6978224 },
    { lat: 48.8716346, lng: 18.6980973 },
    { lat: 48.8716689, lng: 18.6984055 },
    { lat: 48.8717814, lng: 18.698956 },
    { lat: 48.8718759, lng: 18.6989939 },
    { lat: 48.8719274, lng: 18.6991152 },
    { lat: 48.8719995, lng: 18.6993866 },
    { lat: 48.8719708, lng: 18.6994209 },
    { lat: 48.872036, lng: 18.699991 },
    { lat: 48.872883, lng: 18.700103 },
    { lat: 48.8742, lng: 18.699987 },
    { lat: 48.874803, lng: 18.699962 },
    { lat: 48.87585, lng: 18.699967 },
    { lat: 48.876348, lng: 18.698358 },
    { lat: 48.8764941, lng: 18.6979569 },
    { lat: 48.876757, lng: 18.697613 },
    { lat: 48.877021, lng: 18.69746 },
    { lat: 48.877042, lng: 18.697454 },
    { lat: 48.877366, lng: 18.697364 },
    { lat: 48.878425, lng: 18.69659 },
    { lat: 48.878932, lng: 18.696151 },
    { lat: 48.879084, lng: 18.696265 },
    { lat: 48.879331, lng: 18.696137 },
    { lat: 48.880484, lng: 18.695408 },
    { lat: 48.88054, lng: 18.69542 },
    { lat: 48.880702, lng: 18.695423 },
    { lat: 48.881114, lng: 18.695675 },
    { lat: 48.881156, lng: 18.695696 },
    { lat: 48.881197, lng: 18.695715 },
    { lat: 48.881361, lng: 18.695791 },
    { lat: 48.881597, lng: 18.695804 },
    { lat: 48.881721, lng: 18.695868 },
    { lat: 48.8818, lng: 18.69623 },
    { lat: 48.882022, lng: 18.696838 },
    { lat: 48.882091, lng: 18.697159 },
    { lat: 48.882142, lng: 18.697555 },
    { lat: 48.882187, lng: 18.697924 },
    { lat: 48.882143, lng: 18.698441 },
    { lat: 48.882485, lng: 18.698669 },
    { lat: 48.882549, lng: 18.698613 },
    { lat: 48.882739, lng: 18.698754 },
    { lat: 48.882819, lng: 18.698819 },
    { lat: 48.882831, lng: 18.699263 },
    { lat: 48.882768, lng: 18.699704 },
    { lat: 48.882714, lng: 18.699874 },
    { lat: 48.883448, lng: 18.700284 },
    { lat: 48.884101, lng: 18.700799 },
    { lat: 48.8842, lng: 18.700864 },
    { lat: 48.884489, lng: 18.701024 },
    { lat: 48.885402, lng: 18.70152 },
    { lat: 48.885572, lng: 18.701636 },
    { lat: 48.885649, lng: 18.701688 },
    { lat: 48.885665, lng: 18.7017 },
    { lat: 48.886216, lng: 18.70231 },
    { lat: 48.886453, lng: 18.702318 },
    { lat: 48.886793, lng: 18.70348 },
    { lat: 48.886806, lng: 18.703529 },
    { lat: 48.88682, lng: 18.703577 },
    { lat: 48.886845, lng: 18.703668 },
    { lat: 48.886873, lng: 18.703769 },
    { lat: 48.887086, lng: 18.704106 },
    { lat: 48.887221, lng: 18.704016 },
    { lat: 48.887491, lng: 18.703894 },
    { lat: 48.887649, lng: 18.703732 },
    { lat: 48.887863, lng: 18.703609 },
    { lat: 48.888048, lng: 18.703445 },
    { lat: 48.888245, lng: 18.703211 },
    { lat: 48.888395, lng: 18.703189 },
    { lat: 48.888751, lng: 18.703176 },
    { lat: 48.888981, lng: 18.703056 },
    { lat: 48.889257, lng: 18.702962 },
    { lat: 48.889801, lng: 18.702792 },
    { lat: 48.889801, lng: 18.702802 },
    { lat: 48.889847, lng: 18.702814 },
    { lat: 48.890046, lng: 18.703002 },
    { lat: 48.890134, lng: 18.70316 },
    { lat: 48.890237, lng: 18.703421 },
    { lat: 48.890396, lng: 18.703808 },
    { lat: 48.890561, lng: 18.704145 },
    { lat: 48.890757, lng: 18.704533 },
    { lat: 48.890881, lng: 18.70467 },
    { lat: 48.890959, lng: 18.704795 },
    { lat: 48.891004, lng: 18.704929 },
    { lat: 48.891057, lng: 18.705021 },
    { lat: 48.891235, lng: 18.705079 },
    { lat: 48.891401, lng: 18.705169 },
    { lat: 48.891787, lng: 18.705333 },
    { lat: 48.892043, lng: 18.705287 },
    { lat: 48.892093, lng: 18.705283 },
    { lat: 48.892575, lng: 18.70632 },
    { lat: 48.892731, lng: 18.706639 },
    { lat: 48.893051, lng: 18.706648 },
    { lat: 48.893728, lng: 18.70729 },
    { lat: 48.894445, lng: 18.70724 },
    { lat: 48.895251, lng: 18.706989 },
    { lat: 48.895623, lng: 18.707077 },
    { lat: 48.895694, lng: 18.707328 },
    { lat: 48.895808, lng: 18.707418 },
    { lat: 48.896279, lng: 18.707709 },
    { lat: 48.896547, lng: 18.707724 },
    { lat: 48.897036, lng: 18.707858 },
    { lat: 48.897488, lng: 18.707939 },
    { lat: 48.897931, lng: 18.707825 },
    { lat: 48.898479, lng: 18.707493 },
    { lat: 48.89888, lng: 18.707042 },
    { lat: 48.8993, lng: 18.706634 },
    { lat: 48.899703, lng: 18.706341 },
    { lat: 48.899921, lng: 18.705595 },
    { lat: 48.900221, lng: 18.704832 },
    { lat: 48.900532, lng: 18.704068 },
    { lat: 48.90098, lng: 18.703892 },
    { lat: 48.901233, lng: 18.703709 },
    { lat: 48.901789, lng: 18.703299 },
    { lat: 48.902587, lng: 18.702725 },
    { lat: 48.902918, lng: 18.702434 },
    { lat: 48.90335, lng: 18.701744 },
    { lat: 48.903571, lng: 18.701008 },
    { lat: 48.903889, lng: 18.700292 },
    { lat: 48.904816, lng: 18.699669 },
    { lat: 48.905701, lng: 18.698916 },
    { lat: 48.906621, lng: 18.698475 },
    { lat: 48.907691, lng: 18.697866 },
  ],
  DiviakynadNitricou: [
    { lat: 48.76765, lng: 18.439276 },
    { lat: 48.767126, lng: 18.439382 },
    { lat: 48.76695, lng: 18.439521 },
    { lat: 48.7661822, lng: 18.43954 },
    { lat: 48.7654761, lng: 18.4400613 },
    { lat: 48.7653772, lng: 18.4402719 },
    { lat: 48.7651032, lng: 18.4405197 },
    { lat: 48.7648531, lng: 18.4410746 },
    { lat: 48.7642737, lng: 18.4416346 },
    { lat: 48.7638703, lng: 18.4418849 },
    { lat: 48.7637366, lng: 18.4420184 },
    { lat: 48.7635105, lng: 18.442376 },
    { lat: 48.7631913, lng: 18.4425402 },
    { lat: 48.7629834, lng: 18.4429237 },
    { lat: 48.7629377, lng: 18.4437469 },
    { lat: 48.7629021, lng: 18.4439616 },
    { lat: 48.7629034, lng: 18.4443494 },
    { lat: 48.7628524, lng: 18.4452427 },
    { lat: 48.762672, lng: 18.4459381 },
    { lat: 48.7625137, lng: 18.4463586 },
    { lat: 48.7623273, lng: 18.446969 },
    { lat: 48.7622805, lng: 18.4471819 },
    { lat: 48.7620791, lng: 18.4485579 },
    { lat: 48.7620035, lng: 18.4490895 },
    { lat: 48.7617931, lng: 18.4496289 },
    { lat: 48.7617222, lng: 18.4500394 },
    { lat: 48.7616025, lng: 18.450529 },
    { lat: 48.7613336, lng: 18.4513782 },
    { lat: 48.7610543, lng: 18.4525481 },
    { lat: 48.7608738, lng: 18.4531412 },
    { lat: 48.7608239, lng: 18.453666 },
    { lat: 48.7605305, lng: 18.4544832 },
    { lat: 48.7597728, lng: 18.4563474 },
    { lat: 48.7594757, lng: 18.4570757 },
    { lat: 48.7591481, lng: 18.4578865 },
    { lat: 48.7585934, lng: 18.4601579 },
    { lat: 48.7581956, lng: 18.4611914 },
    { lat: 48.7574409, lng: 18.4632745 },
    { lat: 48.7576274, lng: 18.4643846 },
    { lat: 48.7575486, lng: 18.4655537 },
    { lat: 48.7571767, lng: 18.4660711 },
    { lat: 48.7570971, lng: 18.4662267 },
    { lat: 48.7565563, lng: 18.4666477 },
    { lat: 48.7562322, lng: 18.4670669 },
    { lat: 48.7563813, lng: 18.4674013 },
    { lat: 48.7564619, lng: 18.4673496 },
    { lat: 48.7566364, lng: 18.4679376 },
    { lat: 48.7568444, lng: 18.4679246 },
    { lat: 48.7571163, lng: 18.4685778 },
    { lat: 48.7570092, lng: 18.4686195 },
    { lat: 48.7568168, lng: 18.4687525 },
    { lat: 48.7567132, lng: 18.4688378 },
    { lat: 48.7567155, lng: 18.4691664 },
    { lat: 48.7568069, lng: 18.4694316 },
    { lat: 48.7567938, lng: 18.4694597 },
    { lat: 48.756808, lng: 18.4694729 },
    { lat: 48.7568427, lng: 18.4696298 },
    { lat: 48.7569875, lng: 18.4698639 },
    { lat: 48.7571191, lng: 18.4700433 },
    { lat: 48.7572939, lng: 18.4703505 },
    { lat: 48.7574146, lng: 18.4705716 },
    { lat: 48.7578152, lng: 18.4709855 },
    { lat: 48.7584018, lng: 18.4716613 },
    { lat: 48.7584894, lng: 18.4719399 },
    { lat: 48.7584475, lng: 18.4719377 },
    { lat: 48.7584138, lng: 18.4721032 },
    { lat: 48.7583546, lng: 18.4725039 },
    { lat: 48.7582589, lng: 18.4728134 },
    { lat: 48.7581997, lng: 18.4730912 },
    { lat: 48.7580618, lng: 18.4735959 },
    { lat: 48.7579517, lng: 18.47385 },
    { lat: 48.7578555, lng: 18.474215 },
    { lat: 48.7578503, lng: 18.4743661 },
    { lat: 48.7577456, lng: 18.4746575 },
    { lat: 48.7576617, lng: 18.4749977 },
    { lat: 48.7574888, lng: 18.4753816 },
    { lat: 48.7575081, lng: 18.4754064 },
    { lat: 48.7575764, lng: 18.4754367 },
    { lat: 48.7572732, lng: 18.4757892 },
    { lat: 48.7571546, lng: 18.4760748 },
    { lat: 48.7570956, lng: 18.4763114 },
    { lat: 48.7570475, lng: 18.4764007 },
    { lat: 48.7569674, lng: 18.4766875 },
    { lat: 48.7569258, lng: 18.4767746 },
    { lat: 48.7568792, lng: 18.4768424 },
    { lat: 48.7567307, lng: 18.4761543 },
    { lat: 48.7565609, lng: 18.4767205 },
    { lat: 48.7565134, lng: 18.4769824 },
    { lat: 48.7564951, lng: 18.4772422 },
    { lat: 48.7564134, lng: 18.4775231 },
    { lat: 48.7564147, lng: 18.4778515 },
    { lat: 48.756321, lng: 18.478121 },
    { lat: 48.7561373, lng: 18.4785113 },
    { lat: 48.7558739, lng: 18.4788494 },
    { lat: 48.7558354, lng: 18.4791324 },
    { lat: 48.7556273, lng: 18.4796985 },
    { lat: 48.7559467, lng: 18.4799278 },
    { lat: 48.7557583, lng: 18.4803358 },
    { lat: 48.7556487, lng: 18.4805177 },
    { lat: 48.7555901, lng: 18.4809465 },
    { lat: 48.7554148, lng: 18.4812675 },
    { lat: 48.7552739, lng: 18.4816402 },
    { lat: 48.7551193, lng: 18.4819242 },
    { lat: 48.754926, lng: 18.4821873 },
    { lat: 48.754645, lng: 18.482533 },
    { lat: 48.7545222, lng: 18.4825946 },
    { lat: 48.7544409, lng: 18.4826559 },
    { lat: 48.7544181, lng: 18.4828171 },
    { lat: 48.7543726, lng: 18.4828486 },
    { lat: 48.7542776, lng: 18.4829692 },
    { lat: 48.7542397, lng: 18.4830795 },
    { lat: 48.7541239, lng: 18.4833343 },
    { lat: 48.7540548, lng: 18.483595 },
    { lat: 48.7539016, lng: 18.4837237 },
    { lat: 48.7537385, lng: 18.4841403 },
    { lat: 48.7537296, lng: 18.4844091 },
    { lat: 48.7535123, lng: 18.4851979 },
    { lat: 48.7535304, lng: 18.4853218 },
    { lat: 48.7534282, lng: 18.4858009 },
    { lat: 48.753427, lng: 18.486127 },
    { lat: 48.7533816, lng: 18.4868406 },
    { lat: 48.7532957, lng: 18.4871888 },
    { lat: 48.7532033, lng: 18.4874982 },
    { lat: 48.7531677, lng: 18.487754 },
    { lat: 48.7531218, lng: 18.4878606 },
    { lat: 48.7530994, lng: 18.4883564 },
    { lat: 48.7529084, lng: 18.4889838 },
    { lat: 48.7529329, lng: 18.4892063 },
    { lat: 48.7528458, lng: 18.4895915 },
    { lat: 48.7529123, lng: 18.4901444 },
    { lat: 48.752972, lng: 18.4905548 },
    { lat: 48.7532007, lng: 18.490446 },
    { lat: 48.7537749, lng: 18.4901579 },
    { lat: 48.7538945, lng: 18.490179 },
    { lat: 48.7539616, lng: 18.490251 },
    { lat: 48.7544674, lng: 18.4908739 },
    { lat: 48.7545266, lng: 18.4909497 },
    { lat: 48.7547404, lng: 18.4911245 },
    { lat: 48.7549574, lng: 18.4911923 },
    { lat: 48.7551873, lng: 18.4912903 },
    { lat: 48.7554946, lng: 18.4954488 },
    { lat: 48.7538691, lng: 18.4960668 },
    { lat: 48.7537962, lng: 18.4961768 },
    { lat: 48.7536665, lng: 18.4969195 },
    { lat: 48.7536924, lng: 18.496977 },
    { lat: 48.7537316, lng: 18.497178 },
    { lat: 48.7536892, lng: 18.4972294 },
    { lat: 48.7536844, lng: 18.4973487 },
    { lat: 48.7535137, lng: 18.4978738 },
    { lat: 48.7535182, lng: 18.4984681 },
    { lat: 48.753545, lng: 18.4987325 },
    { lat: 48.753613, lng: 18.498843 },
    { lat: 48.7537528, lng: 18.4989441 },
    { lat: 48.7540045, lng: 18.4989265 },
    { lat: 48.7545565, lng: 18.4991168 },
    { lat: 48.7546596, lng: 18.4992672 },
    { lat: 48.754679, lng: 18.4993338 },
    { lat: 48.7546959, lng: 18.4994389 },
    { lat: 48.7547155, lng: 18.4998347 },
    { lat: 48.754636, lng: 18.4997421 },
    { lat: 48.754673, lng: 18.5000692 },
    { lat: 48.754922, lng: 18.5002084 },
    { lat: 48.7549838, lng: 18.5001628 },
    { lat: 48.7550335, lng: 18.5002674 },
    { lat: 48.7551734, lng: 18.50043 },
    { lat: 48.7553078, lng: 18.5004764 },
    { lat: 48.7555325, lng: 18.500538 },
    { lat: 48.7557517, lng: 18.5006302 },
    { lat: 48.7558265, lng: 18.5017888 },
    { lat: 48.7559038, lng: 18.5035898 },
    { lat: 48.7559359, lng: 18.5040298 },
    { lat: 48.755929, lng: 18.5042963 },
    { lat: 48.7559014, lng: 18.5044306 },
    { lat: 48.7558576, lng: 18.5045584 },
    { lat: 48.7557816, lng: 18.5046726 },
    { lat: 48.7557076, lng: 18.5048667 },
    { lat: 48.755624, lng: 18.505214 },
    { lat: 48.7556198, lng: 18.5054254 },
    { lat: 48.755592, lng: 18.5055948 },
    { lat: 48.755805, lng: 18.5057161 },
    { lat: 48.7559392, lng: 18.5057164 },
    { lat: 48.7559629, lng: 18.5054707 },
    { lat: 48.7561509, lng: 18.5054028 },
    { lat: 48.7565216, lng: 18.5054135 },
    { lat: 48.7566913, lng: 18.5051538 },
    { lat: 48.75685, lng: 18.5050864 },
    { lat: 48.7568814, lng: 18.5050649 },
    { lat: 48.7569853, lng: 18.5049414 },
    { lat: 48.7571204, lng: 18.5045856 },
    { lat: 48.7572571, lng: 18.5044876 },
    { lat: 48.7573965, lng: 18.5044784 },
    { lat: 48.7574862, lng: 18.5045933 },
    { lat: 48.7575415, lng: 18.5047995 },
    { lat: 48.7576483, lng: 18.5053084 },
    { lat: 48.7576047, lng: 18.5055423 },
    { lat: 48.7576038, lng: 18.5056472 },
    { lat: 48.7576211, lng: 18.5057169 },
    { lat: 48.757713, lng: 18.5057679 },
    { lat: 48.7578765, lng: 18.5058279 },
    { lat: 48.7579949, lng: 18.5057669 },
    { lat: 48.7580517, lng: 18.5055253 },
    { lat: 48.7581248, lng: 18.5053366 },
    { lat: 48.7581202, lng: 18.5052539 },
    { lat: 48.7580639, lng: 18.505153 },
    { lat: 48.7580193, lng: 18.5051207 },
    { lat: 48.757971, lng: 18.5051239 },
    { lat: 48.7579342, lng: 18.5051507 },
    { lat: 48.7578816, lng: 18.5050965 },
    { lat: 48.7578455, lng: 18.5049544 },
    { lat: 48.757804, lng: 18.5046718 },
    { lat: 48.7578956, lng: 18.5045102 },
    { lat: 48.7580203, lng: 18.5043962 },
    { lat: 48.7580507, lng: 18.5043949 },
    { lat: 48.7582072, lng: 18.5045155 },
    { lat: 48.7583822, lng: 18.5046201 },
    { lat: 48.7585065, lng: 18.5046499 },
    { lat: 48.7586814, lng: 18.5047109 },
    { lat: 48.7588685, lng: 18.5047515 },
    { lat: 48.7588511, lng: 18.5048441 },
    { lat: 48.7589819, lng: 18.5054197 },
    { lat: 48.7590627, lng: 18.5060524 },
    { lat: 48.7591077, lng: 18.5068741 },
    { lat: 48.7591318, lng: 18.5068775 },
    { lat: 48.7591096, lng: 18.5071323 },
    { lat: 48.7591315, lng: 18.5073698 },
    { lat: 48.7592882, lng: 18.5083362 },
    { lat: 48.7593596, lng: 18.5088364 },
    { lat: 48.7593893, lng: 18.5091938 },
    { lat: 48.7594647, lng: 18.5096856 },
    { lat: 48.7594318, lng: 18.5097197 },
    { lat: 48.759486, lng: 18.5100522 },
    { lat: 48.7595485, lng: 18.5102224 },
    { lat: 48.7596604, lng: 18.5111351 },
    { lat: 48.7598446, lng: 18.5115557 },
    { lat: 48.7598226, lng: 18.5117065 },
    { lat: 48.7598235, lng: 18.5118694 },
    { lat: 48.7598727, lng: 18.5124796 },
    { lat: 48.7599929, lng: 18.5135014 },
    { lat: 48.7599628, lng: 18.5137332 },
    { lat: 48.7599589, lng: 18.51424 },
    { lat: 48.760206, lng: 18.5153567 },
    { lat: 48.7603538, lng: 18.5158509 },
    { lat: 48.7603752, lng: 18.5161381 },
    { lat: 48.7604502, lng: 18.5164799 },
    { lat: 48.7605191, lng: 18.5165817 },
    { lat: 48.7605383, lng: 18.5172225 },
    { lat: 48.7605592, lng: 18.5175012 },
    { lat: 48.760607, lng: 18.5178392 },
    { lat: 48.7607199, lng: 18.5182141 },
    { lat: 48.7608253, lng: 18.5184683 },
    { lat: 48.7609161, lng: 18.5192642 },
    { lat: 48.7610362, lng: 18.5206143 },
    { lat: 48.7612268, lng: 18.5217917 },
    { lat: 48.7615091, lng: 18.5225201 },
    { lat: 48.761713, lng: 18.5233802 },
    { lat: 48.7613709, lng: 18.5241791 },
    { lat: 48.7608904, lng: 18.5249242 },
    { lat: 48.7609709, lng: 18.5260078 },
    { lat: 48.7610554, lng: 18.5262318 },
    { lat: 48.7612327, lng: 18.5265046 },
    { lat: 48.7611105, lng: 18.5273651 },
    { lat: 48.7613286, lng: 18.5277461 },
    { lat: 48.7614323, lng: 18.5291464 },
    { lat: 48.7612097, lng: 18.5296762 },
    { lat: 48.761166, lng: 18.5297457 },
    { lat: 48.7614177, lng: 18.5304085 },
    { lat: 48.7615603, lng: 18.5305005 },
    { lat: 48.7615866, lng: 18.5303027 },
    { lat: 48.7616323, lng: 18.5301872 },
    { lat: 48.7617096, lng: 18.5302173 },
    { lat: 48.7617321, lng: 18.5303013 },
    { lat: 48.7618585, lng: 18.5303642 },
    { lat: 48.7619203, lng: 18.5300891 },
    { lat: 48.7619949, lng: 18.5300912 },
    { lat: 48.7621212, lng: 18.5302803 },
    { lat: 48.7622698, lng: 18.530088 },
    { lat: 48.7623873, lng: 18.5301091 },
    { lat: 48.7625, lng: 18.5301701 },
    { lat: 48.76263, lng: 18.5303648 },
    { lat: 48.7628397, lng: 18.5304215 },
    { lat: 48.7631443, lng: 18.5304625 },
    { lat: 48.7636587, lng: 18.5304247 },
    { lat: 48.7638534, lng: 18.5299818 },
    { lat: 48.7637747, lng: 18.5297188 },
    { lat: 48.7638758, lng: 18.529669 },
    { lat: 48.7639929, lng: 18.5296844 },
    { lat: 48.7640734, lng: 18.5296732 },
    { lat: 48.7641294, lng: 18.5297081 },
    { lat: 48.7642491, lng: 18.529957 },
    { lat: 48.7642806, lng: 18.53004 },
    { lat: 48.7643384, lng: 18.530125 },
    { lat: 48.7643856, lng: 18.5301234 },
    { lat: 48.7643906, lng: 18.529924 },
    { lat: 48.7643973, lng: 18.5298824 },
    { lat: 48.7645038, lng: 18.5296209 },
    { lat: 48.7646051, lng: 18.5297238 },
    { lat: 48.7647328, lng: 18.5297243 },
    { lat: 48.7649789, lng: 18.5295219 },
    { lat: 48.7650747, lng: 18.5295006 },
    { lat: 48.7651635, lng: 18.529556 },
    { lat: 48.7652842, lng: 18.5294938 },
    { lat: 48.7653577, lng: 18.529476 },
    { lat: 48.7654645, lng: 18.5293068 },
    { lat: 48.7655989, lng: 18.5292657 },
    { lat: 48.7656809, lng: 18.5291796 },
    { lat: 48.7656817, lng: 18.5290079 },
    { lat: 48.76574, lng: 18.5289409 },
    { lat: 48.7659894, lng: 18.5289419 },
    { lat: 48.7660911, lng: 18.5290469 },
    { lat: 48.7661666, lng: 18.5289445 },
    { lat: 48.7662603, lng: 18.528882 },
    { lat: 48.7665307, lng: 18.5289812 },
    { lat: 48.7666611, lng: 18.5289707 },
    { lat: 48.7667767, lng: 18.5291043 },
    { lat: 48.7668998, lng: 18.5292956 },
    { lat: 48.7669534, lng: 18.5293236 },
    { lat: 48.7670472, lng: 18.5292893 },
    { lat: 48.7670892, lng: 18.5292909 },
    { lat: 48.7671016, lng: 18.5292721 },
    { lat: 48.7671884, lng: 18.5292899 },
    { lat: 48.7672111, lng: 18.5292517 },
    { lat: 48.7673473, lng: 18.5293695 },
    { lat: 48.7673927, lng: 18.5294385 },
    { lat: 48.7674998, lng: 18.5294559 },
    { lat: 48.7675354, lng: 18.5295337 },
    { lat: 48.7676748, lng: 18.529748 },
    { lat: 48.7677318, lng: 18.5296969 },
    { lat: 48.7678856, lng: 18.5298297 },
    { lat: 48.7679775, lng: 18.529821 },
    { lat: 48.7681317, lng: 18.5298354 },
    { lat: 48.7682209, lng: 18.5299794 },
    { lat: 48.7683554, lng: 18.5300874 },
    { lat: 48.768544, lng: 18.5300313 },
    { lat: 48.7686674, lng: 18.5300399 },
    { lat: 48.7687439, lng: 18.5301984 },
    { lat: 48.7688505, lng: 18.5302112 },
    { lat: 48.7688517, lng: 18.530338 },
    { lat: 48.7689208, lng: 18.5303833 },
    { lat: 48.76898, lng: 18.530372 },
    { lat: 48.7690771, lng: 18.5304357 },
    { lat: 48.7691354, lng: 18.5304305 },
    { lat: 48.769289, lng: 18.5305376 },
    { lat: 48.769392, lng: 18.5305841 },
    { lat: 48.7695102, lng: 18.5304753 },
    { lat: 48.769659, lng: 18.5306609 },
    { lat: 48.7697025, lng: 18.5307774 },
    { lat: 48.7699398, lng: 18.5309283 },
    { lat: 48.769956, lng: 18.5309964 },
    { lat: 48.7700257, lng: 18.5310345 },
    { lat: 48.770194, lng: 18.5310396 },
    { lat: 48.7702959, lng: 18.5311689 },
    { lat: 48.7704047, lng: 18.5311792 },
    { lat: 48.7704933, lng: 18.5311095 },
    { lat: 48.7705031, lng: 18.5314534 },
    { lat: 48.7706559, lng: 18.5315244 },
    { lat: 48.7707629, lng: 18.5316669 },
    { lat: 48.7708643, lng: 18.5316007 },
    { lat: 48.7709532, lng: 18.5316192 },
    { lat: 48.7709764, lng: 18.5318187 },
    { lat: 48.7710443, lng: 18.5317806 },
    { lat: 48.7712061, lng: 18.5317879 },
    { lat: 48.7713153, lng: 18.5318483 },
    { lat: 48.7713814, lng: 18.5319416 },
    { lat: 48.7713907, lng: 18.532239 },
    { lat: 48.7715335, lng: 18.5323739 },
    { lat: 48.7715792, lng: 18.5326142 },
    { lat: 48.7715804, lng: 18.5327571 },
    { lat: 48.7716738, lng: 18.5326925 },
    { lat: 48.7717452, lng: 18.5328005 },
    { lat: 48.7718882, lng: 18.5327369 },
    { lat: 48.7718952, lng: 18.5329649 },
    { lat: 48.7720142, lng: 18.5332043 },
    { lat: 48.7720431, lng: 18.5333831 },
    { lat: 48.7720709, lng: 18.5334983 },
    { lat: 48.7723101, lng: 18.5335411 },
    { lat: 48.7724874, lng: 18.5335858 },
    { lat: 48.7725422, lng: 18.5335764 },
    { lat: 48.7725992, lng: 18.5335078 },
    { lat: 48.7727157, lng: 18.5334507 },
    { lat: 48.7728118, lng: 18.5335564 },
    { lat: 48.7728916, lng: 18.5335947 },
    { lat: 48.7729578, lng: 18.5335864 },
    { lat: 48.7730141, lng: 18.5336481 },
    { lat: 48.7730848, lng: 18.5336396 },
    { lat: 48.7731725, lng: 18.5335528 },
    { lat: 48.7731982, lng: 18.5335915 },
    { lat: 48.7732209, lng: 18.5337982 },
    { lat: 48.7732409, lng: 18.5338143 },
    { lat: 48.7733273, lng: 18.533786 },
    { lat: 48.7734159, lng: 18.5338804 },
    { lat: 48.7735139, lng: 18.5342083 },
    { lat: 48.7736499, lng: 18.534324 },
    { lat: 48.773694, lng: 18.5342212 },
    { lat: 48.7737909, lng: 18.5340743 },
    { lat: 48.7739404, lng: 18.5341075 },
    { lat: 48.7740367, lng: 18.5342596 },
    { lat: 48.7741771, lng: 18.5342911 },
    { lat: 48.7742951, lng: 18.5342613 },
    { lat: 48.7743713, lng: 18.534167 },
    { lat: 48.7745751, lng: 18.5341198 },
    { lat: 48.7747159, lng: 18.5342403 },
    { lat: 48.775066, lng: 18.5343106 },
    { lat: 48.7752879, lng: 18.5344485 },
    { lat: 48.7753953, lng: 18.5346011 },
    { lat: 48.7754213, lng: 18.53487 },
    { lat: 48.7754659, lng: 18.5349427 },
    { lat: 48.7758655, lng: 18.5350316 },
    { lat: 48.7760666, lng: 18.5350351 },
    { lat: 48.7761195, lng: 18.534991 },
    { lat: 48.7762549, lng: 18.5347655 },
    { lat: 48.7763177, lng: 18.5346994 },
    { lat: 48.7764138, lng: 18.5346631 },
    { lat: 48.7765726, lng: 18.5347177 },
    { lat: 48.7767027, lng: 18.5350144 },
    { lat: 48.776961, lng: 18.5349921 },
    { lat: 48.7770496, lng: 18.5350698 },
    { lat: 48.7772111, lng: 18.5350707 },
    { lat: 48.7772625, lng: 18.5351848 },
    { lat: 48.7774005, lng: 18.5354004 },
    { lat: 48.7774711, lng: 18.5354953 },
    { lat: 48.7776413, lng: 18.5355123 },
    { lat: 48.7777491, lng: 18.5355869 },
    { lat: 48.7778199, lng: 18.5357068 },
    { lat: 48.7779065, lng: 18.5357646 },
    { lat: 48.7779617, lng: 18.5360521 },
    { lat: 48.7780859, lng: 18.5360552 },
    { lat: 48.778112, lng: 18.5361832 },
    { lat: 48.7782198, lng: 18.5362785 },
    { lat: 48.7783405, lng: 18.5362162 },
    { lat: 48.7784859, lng: 18.5365031 },
    { lat: 48.7788077, lng: 18.5363442 },
    { lat: 48.7789705, lng: 18.5364509 },
    { lat: 48.77903, lng: 18.5363862 },
    { lat: 48.7791313, lng: 18.5366314 },
    { lat: 48.7793698, lng: 18.5367218 },
    { lat: 48.7795015, lng: 18.5366743 },
    { lat: 48.7796774, lng: 18.5367371 },
    { lat: 48.7797931, lng: 18.5366254 },
    { lat: 48.7798604, lng: 18.5365945 },
    { lat: 48.7799232, lng: 18.5366313 },
    { lat: 48.7799653, lng: 18.5365767 },
    { lat: 48.7800325, lng: 18.5366288 },
    { lat: 48.7800463, lng: 18.5365702 },
    { lat: 48.7801479, lng: 18.5365961 },
    { lat: 48.7801807, lng: 18.536743 },
    { lat: 48.780306, lng: 18.5368472 },
    { lat: 48.7807346, lng: 18.5372593 },
    { lat: 48.7808665, lng: 18.537382 },
    { lat: 48.7809235, lng: 18.5374951 },
    { lat: 48.7810416, lng: 18.5374678 },
    { lat: 48.7810804, lng: 18.5375803 },
    { lat: 48.7811146, lng: 18.5375671 },
    { lat: 48.7812048, lng: 18.5376474 },
    { lat: 48.7812492, lng: 18.5375549 },
    { lat: 48.7812911, lng: 18.537618 },
    { lat: 48.7812862, lng: 18.5377785 },
    { lat: 48.7813981, lng: 18.5381111 },
    { lat: 48.7816341, lng: 18.5383249 },
    { lat: 48.7817011, lng: 18.5384135 },
    { lat: 48.781896, lng: 18.5384939 },
    { lat: 48.7821394, lng: 18.5384883 },
    { lat: 48.7822632, lng: 18.5385655 },
    { lat: 48.7824328, lng: 18.5387593 },
    { lat: 48.7826483, lng: 18.5389065 },
    { lat: 48.7828322, lng: 18.5389494 },
    { lat: 48.7830701, lng: 18.5389258 },
    { lat: 48.7832557, lng: 18.5380464 },
    { lat: 48.7836951, lng: 18.536857 },
    { lat: 48.7837503, lng: 18.5357796 },
    { lat: 48.7837876, lng: 18.5350975 },
    { lat: 48.7842413, lng: 18.5345669 },
    { lat: 48.7841449, lng: 18.5333739 },
    { lat: 48.7839366, lng: 18.5332772 },
    { lat: 48.7837347, lng: 18.5331132 },
    { lat: 48.7837743, lng: 18.5325167 },
    { lat: 48.7838517, lng: 18.5320112 },
    { lat: 48.78414, lng: 18.5313815 },
    { lat: 48.7842021, lng: 18.5309506 },
    { lat: 48.7845007, lng: 18.5310263 },
    { lat: 48.7845464, lng: 18.5307892 },
    { lat: 48.7847668, lng: 18.5296825 },
    { lat: 48.7847579, lng: 18.5284006 },
    { lat: 48.7847118, lng: 18.5282185 },
    { lat: 48.7848752, lng: 18.5277607 },
    { lat: 48.7848349, lng: 18.5276845 },
    { lat: 48.7847071, lng: 18.5275524 },
    { lat: 48.7846289, lng: 18.5273586 },
    { lat: 48.7845489, lng: 18.5272785 },
    { lat: 48.7844424, lng: 18.5272929 },
    { lat: 48.7843878, lng: 18.5272622 },
    { lat: 48.7843873, lng: 18.5271921 },
    { lat: 48.7844709, lng: 18.5268833 },
    { lat: 48.7844793, lng: 18.5267471 },
    { lat: 48.7844701, lng: 18.5267042 },
    { lat: 48.7844505, lng: 18.5266749 },
    { lat: 48.7843007, lng: 18.5266811 },
    { lat: 48.7842531, lng: 18.5264881 },
    { lat: 48.7841234, lng: 18.5264893 },
    { lat: 48.7840161, lng: 18.5264466 },
    { lat: 48.7839622, lng: 18.5263467 },
    { lat: 48.7838105, lng: 18.5261085 },
    { lat: 48.7837325, lng: 18.526105 },
    { lat: 48.7836876, lng: 18.5260051 },
    { lat: 48.783683, lng: 18.5259206 },
    { lat: 48.7837501, lng: 18.5258299 },
    { lat: 48.7837674, lng: 18.5255277 },
    { lat: 48.7836205, lng: 18.5253947 },
    { lat: 48.7836057, lng: 18.5251888 },
    { lat: 48.783482, lng: 18.5251967 },
    { lat: 48.7833722, lng: 18.5251464 },
    { lat: 48.7833433, lng: 18.5250724 },
    { lat: 48.7833339, lng: 18.5249205 },
    { lat: 48.7833, lng: 18.5248382 },
    { lat: 48.7832673, lng: 18.5247961 },
    { lat: 48.7832172, lng: 18.5247888 },
    { lat: 48.7830854, lng: 18.524811 },
    { lat: 48.7829931, lng: 18.5248147 },
    { lat: 48.7828807, lng: 18.5247164 },
    { lat: 48.7828188, lng: 18.5247005 },
    { lat: 48.7827175, lng: 18.5246402 },
    { lat: 48.7828243, lng: 18.5242628 },
    { lat: 48.7829155, lng: 18.5239953 },
    { lat: 48.7829939, lng: 18.5238807 },
    { lat: 48.7830202, lng: 18.5237601 },
    { lat: 48.7830862, lng: 18.5237536 },
    { lat: 48.7831015, lng: 18.5237424 },
    { lat: 48.7831268, lng: 18.5237092 },
    { lat: 48.783196, lng: 18.5235524 },
    { lat: 48.7832195, lng: 18.5232376 },
    { lat: 48.7832942, lng: 18.5231599 },
    { lat: 48.7832867, lng: 18.5231265 },
    { lat: 48.7831758, lng: 18.5230989 },
    { lat: 48.7831544, lng: 18.5230372 },
    { lat: 48.7832813, lng: 18.5228028 },
    { lat: 48.7832671, lng: 18.5226844 },
    { lat: 48.7832967, lng: 18.5226478 },
    { lat: 48.7834094, lng: 18.522771 },
    { lat: 48.7834155, lng: 18.5228407 },
    { lat: 48.7834649, lng: 18.5228567 },
    { lat: 48.783607, lng: 18.5227535 },
    { lat: 48.7836196, lng: 18.5226943 },
    { lat: 48.7835985, lng: 18.5225557 },
    { lat: 48.7836886, lng: 18.5225354 },
    { lat: 48.7837066, lng: 18.5224484 },
    { lat: 48.7836548, lng: 18.5223054 },
    { lat: 48.7836795, lng: 18.5221607 },
    { lat: 48.7837647, lng: 18.5220506 },
    { lat: 48.7837955, lng: 18.521929 },
    { lat: 48.7838765, lng: 18.5220115 },
    { lat: 48.7839079, lng: 18.5220266 },
    { lat: 48.7839677, lng: 18.5220055 },
    { lat: 48.784053, lng: 18.5219351 },
    { lat: 48.7842238, lng: 18.5216966 },
    { lat: 48.7843315, lng: 18.5216037 },
    { lat: 48.7844047, lng: 18.5215592 },
    { lat: 48.7845113, lng: 18.5213645 },
    { lat: 48.7844908, lng: 18.5212969 },
    { lat: 48.7845009, lng: 18.5210914 },
    { lat: 48.7845465, lng: 18.5209959 },
    { lat: 48.7846947, lng: 18.5208404 },
    { lat: 48.7847659, lng: 18.5208404 },
    { lat: 48.7848533, lng: 18.5207272 },
    { lat: 48.7849383, lng: 18.520674 },
    { lat: 48.7849474, lng: 18.5206553 },
    { lat: 48.7849661, lng: 18.5205421 },
    { lat: 48.7849563, lng: 18.5204664 },
    { lat: 48.7851379, lng: 18.5202176 },
    { lat: 48.7851998, lng: 18.5201727 },
    { lat: 48.7852814, lng: 18.5201607 },
    { lat: 48.7853073, lng: 18.5201381 },
    { lat: 48.7853747, lng: 18.5198872 },
    { lat: 48.7855312, lng: 18.5196759 },
    { lat: 48.7855597, lng: 18.5195357 },
    { lat: 48.7856422, lng: 18.5194756 },
    { lat: 48.7857238, lng: 18.5194858 },
    { lat: 48.7858169, lng: 18.5195146 },
    { lat: 48.7859023, lng: 18.5195067 },
    { lat: 48.785968, lng: 18.5195157 },
    { lat: 48.786097, lng: 18.5194591 },
    { lat: 48.7862037, lng: 18.51943 },
    { lat: 48.7862298, lng: 18.5194146 },
    { lat: 48.7862972, lng: 18.5193352 },
    { lat: 48.7863228, lng: 18.5193597 },
    { lat: 48.7863648, lng: 18.5191763 },
    { lat: 48.7865436, lng: 18.5190008 },
    { lat: 48.787356, lng: 18.5188626 },
    { lat: 48.7874121, lng: 18.5188553 },
    { lat: 48.787482, lng: 18.5187556 },
    { lat: 48.7875017, lng: 18.5186401 },
    { lat: 48.7875379, lng: 18.5185798 },
    { lat: 48.78753, lng: 18.5184577 },
    { lat: 48.7875964, lng: 18.5182874 },
    { lat: 48.7876451, lng: 18.5182094 },
    { lat: 48.7877448, lng: 18.5182563 },
    { lat: 48.7880568, lng: 18.5184161 },
    { lat: 48.7881725, lng: 18.5184056 },
    { lat: 48.7881778, lng: 18.5186292 },
    { lat: 48.7884538, lng: 18.5186401 },
    { lat: 48.7885346, lng: 18.5186947 },
    { lat: 48.7887457, lng: 18.5187395 },
    { lat: 48.7889226, lng: 18.5187356 },
    { lat: 48.7890887, lng: 18.5187001 },
    { lat: 48.7893265, lng: 18.5185931 },
    { lat: 48.7893395, lng: 18.5186881 },
    { lat: 48.7893986, lng: 18.5187146 },
    { lat: 48.7894755, lng: 18.5186381 },
    { lat: 48.7895327, lng: 18.5185083 },
    { lat: 48.7897055, lng: 18.5184906 },
    { lat: 48.7898007, lng: 18.5185377 },
    { lat: 48.7898516, lng: 18.5183789 },
    { lat: 48.7898727, lng: 18.5183716 },
    { lat: 48.7899138, lng: 18.518379 },
    { lat: 48.789931, lng: 18.5184033 },
    { lat: 48.7899379, lng: 18.5185946 },
    { lat: 48.7901338, lng: 18.5185851 },
    { lat: 48.7901231, lng: 18.5184937 },
    { lat: 48.7902151, lng: 18.518465 },
    { lat: 48.7902817, lng: 18.5183633 },
    { lat: 48.7903792, lng: 18.5184531 },
    { lat: 48.7904714, lng: 18.5183053 },
    { lat: 48.7904667, lng: 18.51822 },
    { lat: 48.7905384, lng: 18.5181856 },
    { lat: 48.7905834, lng: 18.5182057 },
    { lat: 48.7905829, lng: 18.5183425 },
    { lat: 48.7906002, lng: 18.5183703 },
    { lat: 48.790687, lng: 18.5183484 },
    { lat: 48.7908131, lng: 18.5181569 },
    { lat: 48.7908186, lng: 18.518114 },
    { lat: 48.7907836, lng: 18.51801 },
    { lat: 48.7907868, lng: 18.5179223 },
    { lat: 48.7910145, lng: 18.5178201 },
    { lat: 48.7910877, lng: 18.5178349 },
    { lat: 48.7911042, lng: 18.5178086 },
    { lat: 48.7911213, lng: 18.5177274 },
    { lat: 48.7911763, lng: 18.5176182 },
    { lat: 48.7911967, lng: 18.517602 },
    { lat: 48.7913815, lng: 18.5176619 },
    { lat: 48.7915479, lng: 18.5175677 },
    { lat: 48.7916531, lng: 18.5177785 },
    { lat: 48.7916836, lng: 18.517697 },
    { lat: 48.7916302, lng: 18.517361 },
    { lat: 48.7916086, lng: 18.5169652 },
    { lat: 48.7915584, lng: 18.5162102 },
    { lat: 48.7914854, lng: 18.5153296 },
    { lat: 48.7914448, lng: 18.5142976 },
    { lat: 48.7914988, lng: 18.5133059 },
    { lat: 48.7914954, lng: 18.5125419 },
    { lat: 48.7915759, lng: 18.5115376 },
    { lat: 48.7916556, lng: 18.5111423 },
    { lat: 48.7919561, lng: 18.5100582 },
    { lat: 48.7921054, lng: 18.5094049 },
    { lat: 48.7923104, lng: 18.5085542 },
    { lat: 48.7924825, lng: 18.507865 },
    { lat: 48.7928487, lng: 18.5068074 },
    { lat: 48.7932378, lng: 18.5061253 },
    { lat: 48.7934839, lng: 18.5058802 },
    { lat: 48.7939797, lng: 18.5055192 },
    { lat: 48.7942332, lng: 18.5053093 },
    { lat: 48.7944731, lng: 18.5051203 },
    { lat: 48.7946176, lng: 18.5050779 },
    { lat: 48.7947148, lng: 18.5050614 },
    { lat: 48.794878, lng: 18.5050166 },
    { lat: 48.7951314, lng: 18.5046148 },
    { lat: 48.795434, lng: 18.504145 },
    { lat: 48.7956095, lng: 18.5034783 },
    { lat: 48.7956096, lng: 18.5033588 },
    { lat: 48.7955276, lng: 18.5029916 },
    { lat: 48.7954938, lng: 18.5026435 },
    { lat: 48.7954912, lng: 18.5024738 },
    { lat: 48.7955168, lng: 18.5024466 },
    { lat: 48.7955386, lng: 18.5023903 },
    { lat: 48.7955498, lng: 18.5023277 },
    { lat: 48.7955494, lng: 18.5022762 },
    { lat: 48.7954889, lng: 18.5022047 },
    { lat: 48.7954656, lng: 18.5020076 },
    { lat: 48.7954382, lng: 18.5018948 },
    { lat: 48.7954547, lng: 18.5018255 },
    { lat: 48.7954324, lng: 18.5015217 },
    { lat: 48.7954664, lng: 18.5013195 },
    { lat: 48.7954649, lng: 18.5012693 },
    { lat: 48.7953541, lng: 18.5010202 },
    { lat: 48.7953525, lng: 18.5009474 },
    { lat: 48.7953867, lng: 18.500873 },
    { lat: 48.7953752, lng: 18.5006852 },
    { lat: 48.7953828, lng: 18.5004531 },
    { lat: 48.7954112, lng: 18.500354 },
    { lat: 48.7953668, lng: 18.5001603 },
    { lat: 48.7952514, lng: 18.4999702 },
    { lat: 48.7951953, lng: 18.4998309 },
    { lat: 48.7950768, lng: 18.4994808 },
    { lat: 48.7949885, lng: 18.499435 },
    { lat: 48.7949672, lng: 18.4993958 },
    { lat: 48.7949675, lng: 18.4993582 },
    { lat: 48.7950668, lng: 18.4992366 },
    { lat: 48.7951014, lng: 18.4991053 },
    { lat: 48.7951015, lng: 18.4990681 },
    { lat: 48.7949686, lng: 18.4989252 },
    { lat: 48.795006, lng: 18.4986618 },
    { lat: 48.7948112, lng: 18.4985031 },
    { lat: 48.7948089, lng: 18.4984615 },
    { lat: 48.7948679, lng: 18.4983257 },
    { lat: 48.7950089, lng: 18.4983659 },
    { lat: 48.7951894, lng: 18.4979937 },
    { lat: 48.7951684, lng: 18.4978572 },
    { lat: 48.7950184, lng: 18.4977164 },
    { lat: 48.7947459, lng: 18.4974362 },
    { lat: 48.7946505, lng: 18.4973823 },
    { lat: 48.7945998, lng: 18.497467 },
    { lat: 48.7945656, lng: 18.4974799 },
    { lat: 48.7945509, lng: 18.4974363 },
    { lat: 48.7945602, lng: 18.4970713 },
    { lat: 48.7945754, lng: 18.4970436 },
    { lat: 48.7946404, lng: 18.4971167 },
    { lat: 48.7946701, lng: 18.4971241 },
    { lat: 48.7946741, lng: 18.4969082 },
    { lat: 48.7945307, lng: 18.4966797 },
    { lat: 48.7944358, lng: 18.4965914 },
    { lat: 48.7943849, lng: 18.4965302 },
    { lat: 48.7942092, lng: 18.4964127 },
    { lat: 48.7942009, lng: 18.4963816 },
    { lat: 48.7942018, lng: 18.4963586 },
    { lat: 48.7942085, lng: 18.4963345 },
    { lat: 48.7943633, lng: 18.4960549 },
    { lat: 48.7943474, lng: 18.4959675 },
    { lat: 48.7943491, lng: 18.4957963 },
    { lat: 48.7942456, lng: 18.4957363 },
    { lat: 48.7942192, lng: 18.4957063 },
    { lat: 48.7942185, lng: 18.495491 },
    { lat: 48.7943459, lng: 18.4952632 },
    { lat: 48.7943805, lng: 18.494968 },
    { lat: 48.7944718, lng: 18.4947823 },
    { lat: 48.794494, lng: 18.49467 },
    { lat: 48.7946356, lng: 18.4946021 },
    { lat: 48.7947167, lng: 18.4945987 },
    { lat: 48.7947662, lng: 18.494556 },
    { lat: 48.7947748, lng: 18.4945278 },
    { lat: 48.7947708, lng: 18.4943733 },
    { lat: 48.7946732, lng: 18.4943824 },
    { lat: 48.7946006, lng: 18.4943729 },
    { lat: 48.7945337, lng: 18.4943463 },
    { lat: 48.7944502, lng: 18.4944487 },
    { lat: 48.7943987, lng: 18.4945793 },
    { lat: 48.7943751, lng: 18.4945801 },
    { lat: 48.7943185, lng: 18.4945171 },
    { lat: 48.794219, lng: 18.4944495 },
    { lat: 48.7941675, lng: 18.4943125 },
    { lat: 48.7941934, lng: 18.4942116 },
    { lat: 48.7942851, lng: 18.4940129 },
    { lat: 48.7942626, lng: 18.4939506 },
    { lat: 48.7942094, lng: 18.4939241 },
    { lat: 48.794124, lng: 18.493791 },
    { lat: 48.7938966, lng: 18.4936517 },
    { lat: 48.7938227, lng: 18.4936399 },
    { lat: 48.7937291, lng: 18.4935376 },
    { lat: 48.7937104, lng: 18.493468 },
    { lat: 48.7937062, lng: 18.4932889 },
    { lat: 48.7935764, lng: 18.4931199 },
    { lat: 48.7934918, lng: 18.4930379 },
    { lat: 48.7934437, lng: 18.4929624 },
    { lat: 48.7934202, lng: 18.4928655 },
    { lat: 48.7934384, lng: 18.492806 },
    { lat: 48.7935041, lng: 18.4927709 },
    { lat: 48.7935471, lng: 18.4926284 },
    { lat: 48.7935614, lng: 18.4924971 },
    { lat: 48.7935146, lng: 18.4924451 },
    { lat: 48.7935372, lng: 18.4923657 },
    { lat: 48.7939896, lng: 18.4910687 },
    { lat: 48.7939922, lng: 18.4898459 },
    { lat: 48.7943549, lng: 18.488435 },
    { lat: 48.7944061, lng: 18.4881789 },
    { lat: 48.7943794, lng: 18.4877758 },
    { lat: 48.7939857, lng: 18.4875885 },
    { lat: 48.7934542, lng: 18.4871763 },
    { lat: 48.7932787, lng: 18.4871021 },
    { lat: 48.7930103, lng: 18.4868761 },
    { lat: 48.7925487, lng: 18.4867088 },
    { lat: 48.7922881, lng: 18.4867502 },
    { lat: 48.7920594, lng: 18.4869364 },
    { lat: 48.7915908, lng: 18.4871978 },
    { lat: 48.7907469, lng: 18.487709 },
    { lat: 48.7896178, lng: 18.4882839 },
    { lat: 48.7895972, lng: 18.4882181 },
    { lat: 48.7894857, lng: 18.4882411 },
    { lat: 48.7891821, lng: 18.4882178 },
    { lat: 48.7890436, lng: 18.4881794 },
    { lat: 48.7889414, lng: 18.4881908 },
    { lat: 48.7888427, lng: 18.488219 },
    { lat: 48.7887194, lng: 18.4882981 },
    { lat: 48.7885999, lng: 18.4884212 },
    { lat: 48.7884339, lng: 18.488621 },
    { lat: 48.788133, lng: 18.4889139 },
    { lat: 48.788083, lng: 18.4889076 },
    { lat: 48.787856, lng: 18.4890696 },
    { lat: 48.7875771, lng: 18.4891866 },
    { lat: 48.7876396, lng: 18.4893995 },
    { lat: 48.7868178, lng: 18.4898409 },
    { lat: 48.7866883, lng: 18.4898461 },
    { lat: 48.7865428, lng: 18.4894562 },
    { lat: 48.786428, lng: 18.4890907 },
    { lat: 48.7863614, lng: 18.4889063 },
    { lat: 48.7863272, lng: 18.4887528 },
    { lat: 48.7861039, lng: 18.4878505 },
    { lat: 48.7860178, lng: 18.4875566 },
    { lat: 48.785862, lng: 18.4870406 },
    { lat: 48.7856752, lng: 18.4864746 },
    { lat: 48.7856212, lng: 18.4862926 },
    { lat: 48.7855147, lng: 18.4858338 },
    { lat: 48.7851455, lng: 18.4841664 },
    { lat: 48.7849469, lng: 18.4832614 },
    { lat: 48.7848152, lng: 18.4823025 },
    { lat: 48.7847094, lng: 18.4814444 },
    { lat: 48.7846775, lng: 18.481354 },
    { lat: 48.7845882, lng: 18.4812676 },
    { lat: 48.7844623, lng: 18.4812362 },
    { lat: 48.7844514, lng: 18.4810165 },
    { lat: 48.7844249, lng: 18.4808657 },
    { lat: 48.784449, lng: 18.4806237 },
    { lat: 48.7843836, lng: 18.4804198 },
    { lat: 48.7846065, lng: 18.4803284 },
    { lat: 48.7848372, lng: 18.4801788 },
    { lat: 48.7847278, lng: 18.4797065 },
    { lat: 48.7849587, lng: 18.4794942 },
    { lat: 48.7849929, lng: 18.4792356 },
    { lat: 48.7849984, lng: 18.4791072 },
    { lat: 48.7847881, lng: 18.4791001 },
    { lat: 48.7845767, lng: 18.4791939 },
    { lat: 48.7843606, lng: 18.4793646 },
    { lat: 48.7842976, lng: 18.4792895 },
    { lat: 48.7843088, lng: 18.4791014 },
    { lat: 48.784298, lng: 18.4789661 },
    { lat: 48.7841755, lng: 18.4786042 },
    { lat: 48.7840252, lng: 18.4782089 },
    { lat: 48.7837392, lng: 18.4771357 },
    { lat: 48.7834759, lng: 18.4762631 },
    { lat: 48.7832333, lng: 18.4750557 },
    { lat: 48.782908, lng: 18.4746764 },
    { lat: 48.7829694, lng: 18.4744598 },
    { lat: 48.7831021, lng: 18.4740617 },
    { lat: 48.783337, lng: 18.4735331 },
    { lat: 48.7835426, lng: 18.4732954 },
    { lat: 48.7837058, lng: 18.4730835 },
    { lat: 48.7840541, lng: 18.4725434 },
    { lat: 48.7839239, lng: 18.4717371 },
    { lat: 48.7833684, lng: 18.472098 },
    { lat: 48.7829112, lng: 18.4721918 },
    { lat: 48.7825855, lng: 18.4724839 },
    { lat: 48.7823503, lng: 18.4728237 },
    { lat: 48.7819687, lng: 18.4720409 },
    { lat: 48.7816223, lng: 18.4707693 },
    { lat: 48.7815245, lng: 18.4704655 },
    { lat: 48.7815338, lng: 18.4700844 },
    { lat: 48.7814119, lng: 18.4691802 },
    { lat: 48.781001, lng: 18.4678234 },
    { lat: 48.7808328, lng: 18.4669847 },
    { lat: 48.7813034, lng: 18.4657994 },
    { lat: 48.7802695, lng: 18.4654799 },
    { lat: 48.7800604, lng: 18.4642036 },
    { lat: 48.7802154, lng: 18.462842 },
    { lat: 48.7802083, lng: 18.4612612 },
    { lat: 48.7800797, lng: 18.4611358 },
    { lat: 48.779901, lng: 18.4604988 },
    { lat: 48.7799782, lng: 18.4577663 },
    { lat: 48.7802691, lng: 18.4574563 },
    { lat: 48.7801414, lng: 18.4570614 },
    { lat: 48.78021, lng: 18.4567552 },
    { lat: 48.7794393, lng: 18.455747 },
    { lat: 48.7792158, lng: 18.4556211 },
    { lat: 48.7790952, lng: 18.4555004 },
    { lat: 48.7788702, lng: 18.4550646 },
    { lat: 48.7786462, lng: 18.4545213 },
    { lat: 48.7782176, lng: 18.4544607 },
    { lat: 48.7779757, lng: 18.4545553 },
    { lat: 48.7776894, lng: 18.4544985 },
    { lat: 48.7774163, lng: 18.4543123 },
    { lat: 48.7767969, lng: 18.4539611 },
    { lat: 48.7764296, lng: 18.453564 },
    { lat: 48.776263, lng: 18.4534052 },
    { lat: 48.7758997, lng: 18.453179 },
    { lat: 48.7757787, lng: 18.453009 },
    { lat: 48.7756384, lng: 18.4528779 },
    { lat: 48.7754724, lng: 18.4527534 },
    { lat: 48.7752408, lng: 18.4523012 },
    { lat: 48.7750992, lng: 18.452125 },
    { lat: 48.7750133, lng: 18.4520432 },
    { lat: 48.7749496, lng: 18.4519286 },
    { lat: 48.7748325, lng: 18.4515639 },
    { lat: 48.774608, lng: 18.4512193 },
    { lat: 48.7743618, lng: 18.450661 },
    { lat: 48.773532, lng: 18.4500768 },
    { lat: 48.7731366, lng: 18.4498425 },
    { lat: 48.7724137, lng: 18.4497229 },
    { lat: 48.7719892, lng: 18.4495535 },
    { lat: 48.771531, lng: 18.4493672 },
    { lat: 48.7712527, lng: 18.4493334 },
    { lat: 48.7709023, lng: 18.4492207 },
    { lat: 48.7703071, lng: 18.4490024 },
    { lat: 48.7699516, lng: 18.4494475 },
    { lat: 48.7697298, lng: 18.4496605 },
    { lat: 48.7693852, lng: 18.4497724 },
    { lat: 48.7692762, lng: 18.4497218 },
    { lat: 48.7692129, lng: 18.4488587 },
    { lat: 48.7690652, lng: 18.4482093 },
    { lat: 48.7689288, lng: 18.4479856 },
    { lat: 48.7688347, lng: 18.447161 },
    { lat: 48.7688649, lng: 18.4468908 },
    { lat: 48.7687075, lng: 18.4461644 },
    { lat: 48.7686506, lng: 18.4457092 },
    { lat: 48.7685087, lng: 18.4452451 },
    { lat: 48.7685651, lng: 18.4447137 },
    { lat: 48.768553, lng: 18.4444141 },
    { lat: 48.7681739, lng: 18.4431668 },
    { lat: 48.7680676, lng: 18.441939 },
    { lat: 48.7677306, lng: 18.4406632 },
    { lat: 48.76765, lng: 18.439276 },
  ],
  Nitrica: [
    { lat: 48.678641, lng: 18.457184 },
    { lat: 48.6782748, lng: 18.4579731 },
    { lat: 48.6781754, lng: 18.4585829 },
    { lat: 48.6779728, lng: 18.4608351 },
    { lat: 48.6773278, lng: 18.4625738 },
    { lat: 48.676965, lng: 18.463277 },
    { lat: 48.6764891, lng: 18.4642172 },
    { lat: 48.6759376, lng: 18.4649138 },
    { lat: 48.6757415, lng: 18.465143 },
    { lat: 48.6755099, lng: 18.4654221 },
    { lat: 48.6754714, lng: 18.4655047 },
    { lat: 48.6754738, lng: 18.4655258 },
    { lat: 48.6755616, lng: 18.4669325 },
    { lat: 48.6756982, lng: 18.4673419 },
    { lat: 48.6759662, lng: 18.4678839 },
    { lat: 48.6762651, lng: 18.4680447 },
    { lat: 48.6763156, lng: 18.4680437 },
    { lat: 48.676615, lng: 18.4682127 },
    { lat: 48.6766546, lng: 18.468258 },
    { lat: 48.6768467, lng: 18.4686925 },
    { lat: 48.6769732, lng: 18.4689422 },
    { lat: 48.6771417, lng: 18.4691944 },
    { lat: 48.6772936, lng: 18.469594 },
    { lat: 48.6773726, lng: 18.46984 },
    { lat: 48.677456, lng: 18.4702071 },
    { lat: 48.6776507, lng: 18.4707113 },
    { lat: 48.6781256, lng: 18.4714857 },
    { lat: 48.6782909, lng: 18.4719679 },
    { lat: 48.6785396, lng: 18.4726098 },
    { lat: 48.6786798, lng: 18.4729238 },
    { lat: 48.6788558, lng: 18.4732736 },
    { lat: 48.6789351, lng: 18.4733982 },
    { lat: 48.6791636, lng: 18.4734926 },
    { lat: 48.6795484, lng: 18.4737994 },
    { lat: 48.6801186, lng: 18.4740786 },
    { lat: 48.6806819, lng: 18.4753296 },
    { lat: 48.6807603, lng: 18.4755853 },
    { lat: 48.680864, lng: 18.47579 },
    { lat: 48.6811135, lng: 18.4763455 },
    { lat: 48.6814643, lng: 18.4770403 },
    { lat: 48.6816838, lng: 18.4778909 },
    { lat: 48.6818025, lng: 18.4781402 },
    { lat: 48.6819508, lng: 18.4784392 },
    { lat: 48.6820399, lng: 18.4786654 },
    { lat: 48.6821414, lng: 18.47895 },
    { lat: 48.6822605, lng: 18.479206 },
    { lat: 48.6824151, lng: 18.4795546 },
    { lat: 48.6825518, lng: 18.4798859 },
    { lat: 48.6826159, lng: 18.4803765 },
    { lat: 48.6826167, lng: 18.4808801 },
    { lat: 48.6825618, lng: 18.4816035 },
    { lat: 48.6828263, lng: 18.4820229 },
    { lat: 48.6829357, lng: 18.482147 },
    { lat: 48.6829736, lng: 18.4822389 },
    { lat: 48.6830775, lng: 18.4823662 },
    { lat: 48.6832718, lng: 18.4827173 },
    { lat: 48.6836819, lng: 18.4836518 },
    { lat: 48.6841478, lng: 18.4843683 },
    { lat: 48.6851804, lng: 18.4856681 },
    { lat: 48.6857901, lng: 18.4865787 },
    { lat: 48.686045, lng: 18.4869072 },
    { lat: 48.6860806, lng: 18.4869788 },
    { lat: 48.6862432, lng: 18.4871879 },
    { lat: 48.6863161, lng: 18.4873444 },
    { lat: 48.6867111, lng: 18.4879807 },
    { lat: 48.6874573, lng: 18.4886282 },
    { lat: 48.6878386, lng: 18.4889366 },
    { lat: 48.6881199, lng: 18.4892508 },
    { lat: 48.6883216, lng: 18.4894504 },
    { lat: 48.6885176, lng: 18.4896736 },
    { lat: 48.6888626, lng: 18.4900152 },
    { lat: 48.6890547, lng: 18.4904524 },
    { lat: 48.6892668, lng: 18.4909863 },
    { lat: 48.6894442, lng: 18.4915688 },
    { lat: 48.6895095, lng: 18.491832 },
    { lat: 48.6896808, lng: 18.4922389 },
    { lat: 48.689812, lng: 18.4924506 },
    { lat: 48.6899956, lng: 18.4927928 },
    { lat: 48.6902079, lng: 18.4930847 },
    { lat: 48.6903913, lng: 18.4936304 },
    { lat: 48.690678, lng: 18.4940254 },
    { lat: 48.690981, lng: 18.4943445 },
    { lat: 48.6911216, lng: 18.4945628 },
    { lat: 48.6913767, lng: 18.4948558 },
    { lat: 48.6915335, lng: 18.4949553 },
    { lat: 48.6917291, lng: 18.4952725 },
    { lat: 48.6921217, lng: 18.4955386 },
    { lat: 48.6923867, lng: 18.4957831 },
    { lat: 48.6928509, lng: 18.4961876 },
    { lat: 48.6931404, lng: 18.4964652 },
    { lat: 48.6933104, lng: 18.4965814 },
    { lat: 48.6940238, lng: 18.4971664 },
    { lat: 48.6949084, lng: 18.4977836 },
    { lat: 48.695349, lng: 18.4981661 },
    { lat: 48.6956521, lng: 18.4983438 },
    { lat: 48.6962482, lng: 18.4988079 },
    { lat: 48.6968133, lng: 18.499483 },
    { lat: 48.6972979, lng: 18.4998433 },
    { lat: 48.6979212, lng: 18.5002483 },
    { lat: 48.6982453, lng: 18.5005202 },
    { lat: 48.6993366, lng: 18.5009398 },
    { lat: 48.699955, lng: 18.5011912 },
    { lat: 48.7009381, lng: 18.5017554 },
    { lat: 48.7013744, lng: 18.5019986 },
    { lat: 48.7016115, lng: 18.5021243 },
    { lat: 48.7018422, lng: 18.5013968 },
    { lat: 48.7018589, lng: 18.5013404 },
    { lat: 48.7023854, lng: 18.4995575 },
    { lat: 48.7023959, lng: 18.4995865 },
    { lat: 48.7024998, lng: 18.4992782 },
    { lat: 48.7027626, lng: 18.4987224 },
    { lat: 48.7029308, lng: 18.4984185 },
    { lat: 48.7031503, lng: 18.4982056 },
    { lat: 48.703436, lng: 18.4980444 },
    { lat: 48.7037112, lng: 18.4978607 },
    { lat: 48.7040681, lng: 18.4975599 },
    { lat: 48.7044206, lng: 18.4974864 },
    { lat: 48.7046701, lng: 18.497553 },
    { lat: 48.7050726, lng: 18.4973032 },
    { lat: 48.7053468, lng: 18.4970585 },
    { lat: 48.705643, lng: 18.4967006 },
    { lat: 48.7058145, lng: 18.4965355 },
    { lat: 48.7059785, lng: 18.4963585 },
    { lat: 48.7060755, lng: 18.4960925 },
    { lat: 48.7061568, lng: 18.4957044 },
    { lat: 48.7063283, lng: 18.4955822 },
    { lat: 48.7066058, lng: 18.4953338 },
    { lat: 48.7068611, lng: 18.4952811 },
    { lat: 48.7070883, lng: 18.4949 },
    { lat: 48.7072719, lng: 18.4947684 },
    { lat: 48.7075438, lng: 18.494215 },
    { lat: 48.7076193, lng: 18.4940709 },
    { lat: 48.7077223, lng: 18.4938933 },
    { lat: 48.7079737, lng: 18.4936625 },
    { lat: 48.7082832, lng: 18.4932819 },
    { lat: 48.7084571, lng: 18.492975 },
    { lat: 48.7087714, lng: 18.4925381 },
    { lat: 48.7090449, lng: 18.4924052 },
    { lat: 48.7092431, lng: 18.4922739 },
    { lat: 48.7094918, lng: 18.4922016 },
    { lat: 48.7096659, lng: 18.492268 },
    { lat: 48.7101104, lng: 18.4922482 },
    { lat: 48.7104433, lng: 18.4923572 },
    { lat: 48.7106845, lng: 18.4924323 },
    { lat: 48.7110497, lng: 18.4927158 },
    { lat: 48.7113532, lng: 18.4929834 },
    { lat: 48.7116485, lng: 18.4931043 },
    { lat: 48.7118375, lng: 18.4932356 },
    { lat: 48.7118539, lng: 18.4931849 },
    { lat: 48.7125933, lng: 18.4920063 },
    { lat: 48.7138636, lng: 18.4897915 },
    { lat: 48.7136469, lng: 18.4896865 },
    { lat: 48.7137419, lng: 18.4892817 },
    { lat: 48.7139031, lng: 18.4891132 },
    { lat: 48.7139759, lng: 18.4890019 },
    { lat: 48.7140443, lng: 18.488874 },
    { lat: 48.7141396, lng: 18.4886777 },
    { lat: 48.7142708, lng: 18.4882305 },
    { lat: 48.7143105, lng: 18.4879286 },
    { lat: 48.7143423, lng: 18.4877685 },
    { lat: 48.7144004, lng: 18.4875959 },
    { lat: 48.714724, lng: 18.4870358 },
    { lat: 48.7148034, lng: 18.4869026 },
    { lat: 48.714832, lng: 18.4868901 },
    { lat: 48.7149673, lng: 18.4867024 },
    { lat: 48.71537, lng: 18.4862103 },
    { lat: 48.7155559, lng: 18.4859204 },
    { lat: 48.715993, lng: 18.4853365 },
    { lat: 48.7161212, lng: 18.4855743 },
    { lat: 48.716425, lng: 18.4853726 },
    { lat: 48.7166426, lng: 18.4852677 },
    { lat: 48.7169118, lng: 18.4851603 },
    { lat: 48.7175382, lng: 18.4849626 },
    { lat: 48.7176793, lng: 18.4852117 },
    { lat: 48.7179739, lng: 18.4852554 },
    { lat: 48.7180779, lng: 18.4853192 },
    { lat: 48.7182043, lng: 18.4853833 },
    { lat: 48.7185161, lng: 18.4856964 },
    { lat: 48.7186837, lng: 18.4859976 },
    { lat: 48.7188323, lng: 18.4863618 },
    { lat: 48.7189709, lng: 18.4867278 },
    { lat: 48.7190409, lng: 18.4869526 },
    { lat: 48.7191403, lng: 18.4871398 },
    { lat: 48.7192369, lng: 18.487172 },
    { lat: 48.7193341, lng: 18.4871555 },
    { lat: 48.7194481, lng: 18.4870361 },
    { lat: 48.7196315, lng: 18.4867169 },
    { lat: 48.7197285, lng: 18.4864911 },
    { lat: 48.7200845, lng: 18.4857862 },
    { lat: 48.7208067, lng: 18.48421 },
    { lat: 48.7201226, lng: 18.4830545 },
    { lat: 48.7197661, lng: 18.4825216 },
    { lat: 48.7196934, lng: 18.482429 },
    { lat: 48.7194883, lng: 18.4822508 },
    { lat: 48.7194875, lng: 18.4820901 },
    { lat: 48.7194509, lng: 18.4817125 },
    { lat: 48.7194253, lng: 18.4812695 },
    { lat: 48.7194511, lng: 18.4811462 },
    { lat: 48.7193294, lng: 18.4810858 },
    { lat: 48.7193241, lng: 18.4810282 },
    { lat: 48.7191382, lng: 18.480889 },
    { lat: 48.7191103, lng: 18.4808968 },
    { lat: 48.7188834, lng: 18.480606 },
    { lat: 48.7187714, lng: 18.4805165 },
    { lat: 48.7186901, lng: 18.4804735 },
    { lat: 48.7186439, lng: 18.4803732 },
    { lat: 48.7186408, lng: 18.4803178 },
    { lat: 48.7186884, lng: 18.4802415 },
    { lat: 48.7186387, lng: 18.4799319 },
    { lat: 48.7185847, lng: 18.4797279 },
    { lat: 48.7184496, lng: 18.4797549 },
    { lat: 48.7179548, lng: 18.4797411 },
    { lat: 48.7178855, lng: 18.4797105 },
    { lat: 48.7175959, lng: 18.4792039 },
    { lat: 48.7176354, lng: 18.4790403 },
    { lat: 48.7168235, lng: 18.4793779 },
    { lat: 48.7167209, lng: 18.4790296 },
    { lat: 48.7167004, lng: 18.4788797 },
    { lat: 48.7165785, lng: 18.478367 },
    { lat: 48.7164049, lng: 18.4783485 },
    { lat: 48.7162885, lng: 18.4783035 },
    { lat: 48.7161749, lng: 18.4782709 },
    { lat: 48.7159152, lng: 18.4781643 },
    { lat: 48.7157941, lng: 18.4781763 },
    { lat: 48.7156648, lng: 18.4781636 },
    { lat: 48.7155891, lng: 18.4780814 },
    { lat: 48.715379, lng: 18.4777041 },
    { lat: 48.7151962, lng: 18.477356 },
    { lat: 48.7150805, lng: 18.4769376 },
    { lat: 48.7150543, lng: 18.4767273 },
    { lat: 48.7148389, lng: 18.4765036 },
    { lat: 48.7147068, lng: 18.4763756 },
    { lat: 48.7145959, lng: 18.4761855 },
    { lat: 48.7144761, lng: 18.4759384 },
    { lat: 48.7144057, lng: 18.475828 },
    { lat: 48.7143385, lng: 18.4756725 },
    { lat: 48.7141623, lng: 18.4755246 },
    { lat: 48.7139919, lng: 18.4754385 },
    { lat: 48.7138679, lng: 18.4754231 },
    { lat: 48.7137246, lng: 18.4753375 },
    { lat: 48.713619, lng: 18.4752447 },
    { lat: 48.7135256, lng: 18.4751071 },
    { lat: 48.7131749, lng: 18.4747605 },
    { lat: 48.7128048, lng: 18.4741234 },
    { lat: 48.7129792, lng: 18.4738696 },
    { lat: 48.7129477, lng: 18.4737911 },
    { lat: 48.7129583, lng: 18.4736747 },
    { lat: 48.7128599, lng: 18.4732192 },
    { lat: 48.7127786, lng: 18.4728933 },
    { lat: 48.7126624, lng: 18.4725221 },
    { lat: 48.7125779, lng: 18.4720748 },
    { lat: 48.7131793, lng: 18.4717694 },
    { lat: 48.7131763, lng: 18.4716769 },
    { lat: 48.7132596, lng: 18.4713868 },
    { lat: 48.7132889, lng: 18.4711594 },
    { lat: 48.713291, lng: 18.4709927 },
    { lat: 48.7133567, lng: 18.4708759 },
    { lat: 48.7134471, lng: 18.4707755 },
    { lat: 48.713464, lng: 18.4706109 },
    { lat: 48.713441, lng: 18.4702155 },
    { lat: 48.7133959, lng: 18.46989 },
    { lat: 48.7138682, lng: 18.4696591 },
    { lat: 48.7138217, lng: 18.4693681 },
    { lat: 48.7137798, lng: 18.4690372 },
    { lat: 48.7137885, lng: 18.4687843 },
    { lat: 48.7138165, lng: 18.4683743 },
    { lat: 48.713953, lng: 18.4675148 },
    { lat: 48.7140012, lng: 18.4669354 },
    { lat: 48.7139988, lng: 18.4668925 },
    { lat: 48.7141214, lng: 18.4668434 },
    { lat: 48.7141818, lng: 18.4667944 },
    { lat: 48.7144142, lng: 18.4666922 },
    { lat: 48.7146452, lng: 18.4665852 },
    { lat: 48.714737, lng: 18.4665751 },
    { lat: 48.715053, lng: 18.4664756 },
    { lat: 48.7152632, lng: 18.4663995 },
    { lat: 48.7154855, lng: 18.4662975 },
    { lat: 48.7156124, lng: 18.4662887 },
    { lat: 48.7158181, lng: 18.4662556 },
    { lat: 48.7159097, lng: 18.4662151 },
    { lat: 48.7160991, lng: 18.4662148 },
    { lat: 48.7165433, lng: 18.4662506 },
    { lat: 48.7168672, lng: 18.4663152 },
    { lat: 48.7169892, lng: 18.4663806 },
    { lat: 48.7171054, lng: 18.4663788 },
    { lat: 48.7173222, lng: 18.466405 },
    { lat: 48.717923, lng: 18.4665994 },
    { lat: 48.7182061, lng: 18.4666416 },
    { lat: 48.7183028, lng: 18.4666333 },
    { lat: 48.718447, lng: 18.4665469 },
    { lat: 48.7185866, lng: 18.4665015 },
    { lat: 48.7186249, lng: 18.4664602 },
    { lat: 48.7187247, lng: 18.4663883 },
    { lat: 48.718882, lng: 18.4663167 },
    { lat: 48.7191609, lng: 18.4662394 },
    { lat: 48.7192629, lng: 18.4662658 },
    { lat: 48.7193506, lng: 18.4662619 },
    { lat: 48.7194511, lng: 18.4662421 },
    { lat: 48.7195583, lng: 18.4662654 },
    { lat: 48.7196246, lng: 18.4662581 },
    { lat: 48.7197202, lng: 18.4662739 },
    { lat: 48.7197657, lng: 18.4662203 },
    { lat: 48.7199424, lng: 18.4661695 },
    { lat: 48.7201061, lng: 18.4661545 },
    { lat: 48.7201883, lng: 18.4660091 },
    { lat: 48.7202281, lng: 18.4660138 },
    { lat: 48.7206682, lng: 18.4662012 },
    { lat: 48.7207454, lng: 18.466273 },
    { lat: 48.7208311, lng: 18.4662493 },
    { lat: 48.7209126, lng: 18.4662572 },
    { lat: 48.7210192, lng: 18.4662063 },
    { lat: 48.721094, lng: 18.4662504 },
    { lat: 48.7212009, lng: 18.4662688 },
    { lat: 48.7212888, lng: 18.4662634 },
    { lat: 48.7214164, lng: 18.4662066 },
    { lat: 48.7216436, lng: 18.4660486 },
    { lat: 48.7219421, lng: 18.4659195 },
    { lat: 48.7220032, lng: 18.4659647 },
    { lat: 48.7221018, lng: 18.465913 },
    { lat: 48.7222149, lng: 18.4658762 },
    { lat: 48.7223069, lng: 18.4658861 },
    { lat: 48.7223898, lng: 18.4659181 },
    { lat: 48.7225069, lng: 18.4659116 },
    { lat: 48.7226018, lng: 18.4659357 },
    { lat: 48.7228714, lng: 18.4658946 },
    { lat: 48.7229279, lng: 18.4659378 },
    { lat: 48.7230583, lng: 18.4658892 },
    { lat: 48.7231071, lng: 18.4658134 },
    { lat: 48.723163, lng: 18.4656995 },
    { lat: 48.7232156, lng: 18.4656504 },
    { lat: 48.7232879, lng: 18.465612 },
    { lat: 48.7234286, lng: 18.465467 },
    { lat: 48.7234737, lng: 18.4654439 },
    { lat: 48.723512, lng: 18.4653629 },
    { lat: 48.7235628, lng: 18.465299 },
    { lat: 48.7236992, lng: 18.465196 },
    { lat: 48.7237526, lng: 18.4652047 },
    { lat: 48.7238822, lng: 18.4651201 },
    { lat: 48.7240013, lng: 18.464969 },
    { lat: 48.7240694, lng: 18.4648318 },
    { lat: 48.7241034, lng: 18.4646892 },
    { lat: 48.7241785, lng: 18.4645597 },
    { lat: 48.7242291, lng: 18.4643546 },
    { lat: 48.7242985, lng: 18.4641994 },
    { lat: 48.7243955, lng: 18.4635062 },
    { lat: 48.7240579, lng: 18.4631114 },
    { lat: 48.7242054, lng: 18.4628413 },
    { lat: 48.7244276, lng: 18.4625121 },
    { lat: 48.7244854, lng: 18.462394 },
    { lat: 48.7246584, lng: 18.4622189 },
    { lat: 48.7246979, lng: 18.4621582 },
    { lat: 48.7248848, lng: 18.4620067 },
    { lat: 48.7249987, lng: 18.4619034 },
    { lat: 48.7252265, lng: 18.4617178 },
    { lat: 48.7252188, lng: 18.4615398 },
    { lat: 48.7252606, lng: 18.4613169 },
    { lat: 48.7253151, lng: 18.4610574 },
    { lat: 48.7253881, lng: 18.4608689 },
    { lat: 48.7254231, lng: 18.4608058 },
    { lat: 48.7253882, lng: 18.4602364 },
    { lat: 48.7253916, lng: 18.4598673 },
    { lat: 48.7253876, lng: 18.459529 },
    { lat: 48.7253984, lng: 18.4592358 },
    { lat: 48.7253941, lng: 18.4588326 },
    { lat: 48.7252899, lng: 18.4587393 },
    { lat: 48.7251883, lng: 18.4586817 },
    { lat: 48.725119, lng: 18.4584871 },
    { lat: 48.725207, lng: 18.4583435 },
    { lat: 48.725338, lng: 18.4580397 },
    { lat: 48.7254682, lng: 18.4578846 },
    { lat: 48.7255336, lng: 18.4577649 },
    { lat: 48.7254699, lng: 18.4573263 },
    { lat: 48.7260659, lng: 18.4560655 },
    { lat: 48.7265961, lng: 18.4550853 },
    { lat: 48.7269207, lng: 18.4545291 },
    { lat: 48.7275096, lng: 18.4535392 },
    { lat: 48.7275686, lng: 18.4534546 },
    { lat: 48.727813, lng: 18.4517322 },
    { lat: 48.7285863, lng: 18.4500111 },
    { lat: 48.7287061, lng: 18.4498095 },
    { lat: 48.7287725, lng: 18.4496253 },
    { lat: 48.7288836, lng: 18.4494283 },
    { lat: 48.7289829, lng: 18.4491463 },
    { lat: 48.7289974, lng: 18.4490184 },
    { lat: 48.729057, lng: 18.4487714 },
    { lat: 48.7294016, lng: 18.4477795 },
    { lat: 48.7300803, lng: 18.4466131 },
    { lat: 48.7321709, lng: 18.4451087 },
    { lat: 48.7346994, lng: 18.4442381 },
    { lat: 48.7341713, lng: 18.4435209 },
    { lat: 48.7318497, lng: 18.4405084 },
    { lat: 48.7310778, lng: 18.4392396 },
    { lat: 48.7307138, lng: 18.4385743 },
    { lat: 48.7304022, lng: 18.4380416 },
    { lat: 48.7295086, lng: 18.4385703 },
    { lat: 48.7291919, lng: 18.4387795 },
    { lat: 48.7286951, lng: 18.4394638 },
    { lat: 48.7283469, lng: 18.4396519 },
    { lat: 48.7276521, lng: 18.4402226 },
    { lat: 48.7277169, lng: 18.4403984 },
    { lat: 48.7275815, lng: 18.4405781 },
    { lat: 48.7273809, lng: 18.4406242 },
    { lat: 48.7271169, lng: 18.4406037 },
    { lat: 48.7269806, lng: 18.4405666 },
    { lat: 48.7268543, lng: 18.4404454 },
    { lat: 48.7267664, lng: 18.4403447 },
    { lat: 48.7263811, lng: 18.439786 },
    { lat: 48.726227, lng: 18.4395908 },
    { lat: 48.7260739, lng: 18.4392286 },
    { lat: 48.725888, lng: 18.4386636 },
    { lat: 48.7257808, lng: 18.4384396 },
    { lat: 48.7254658, lng: 18.4380278 },
    { lat: 48.7253629, lng: 18.43784 },
    { lat: 48.7252475, lng: 18.4376892 },
    { lat: 48.725177, lng: 18.4376592 },
    { lat: 48.7252791, lng: 18.4373258 },
    { lat: 48.7255086, lng: 18.436864 },
    { lat: 48.7256095, lng: 18.4364875 },
    { lat: 48.7256741, lng: 18.4363485 },
    { lat: 48.7253943, lng: 18.4358219 },
    { lat: 48.7247329, lng: 18.4368111 },
    { lat: 48.7244836, lng: 18.4370992 },
    { lat: 48.7243432, lng: 18.4373517 },
    { lat: 48.7243281, lng: 18.4374241 },
    { lat: 48.7242241, lng: 18.4375437 },
    { lat: 48.7242248, lng: 18.4376161 },
    { lat: 48.7241539, lng: 18.4377823 },
    { lat: 48.7240719, lng: 18.437911 },
    { lat: 48.7240202, lng: 18.4381591 },
    { lat: 48.7239797, lng: 18.4383853 },
    { lat: 48.7239137, lng: 18.4385802 },
    { lat: 48.7236843, lng: 18.4390001 },
    { lat: 48.7236486, lng: 18.4390086 },
    { lat: 48.723631, lng: 18.4390969 },
    { lat: 48.7235079, lng: 18.4392379 },
    { lat: 48.72342, lng: 18.4392984 },
    { lat: 48.7226324, lng: 18.4413317 },
    { lat: 48.7226749, lng: 18.4424875 },
    { lat: 48.7216364, lng: 18.4433324 },
    { lat: 48.721282, lng: 18.4425547 },
    { lat: 48.7198807, lng: 18.4427563 },
    { lat: 48.7193729, lng: 18.4424058 },
    { lat: 48.7190336, lng: 18.4421644 },
    { lat: 48.7183032, lng: 18.4421582 },
    { lat: 48.7179467, lng: 18.4422176 },
    { lat: 48.7178247, lng: 18.4417475 },
    { lat: 48.7158653, lng: 18.4411981 },
    { lat: 48.7144438, lng: 18.4408303 },
    { lat: 48.7142078, lng: 18.4408066 },
    { lat: 48.7130711, lng: 18.4408132 },
    { lat: 48.7124351, lng: 18.4405344 },
    { lat: 48.7123005, lng: 18.440546 },
    { lat: 48.7123168, lng: 18.4402078 },
    { lat: 48.7122191, lng: 18.43918 },
    { lat: 48.7120863, lng: 18.4389425 },
    { lat: 48.7119473, lng: 18.4388129 },
    { lat: 48.7117235, lng: 18.4387459 },
    { lat: 48.7113853, lng: 18.4386879 },
    { lat: 48.7110698, lng: 18.4386139 },
    { lat: 48.7108137, lng: 18.4385317 },
    { lat: 48.710643, lng: 18.4384022 },
    { lat: 48.7103784, lng: 18.4380267 },
    { lat: 48.7102221, lng: 18.4376289 },
    { lat: 48.7101288, lng: 18.43731 },
    { lat: 48.7100262, lng: 18.4368618 },
    { lat: 48.7100059, lng: 18.436679 },
    { lat: 48.7099623, lng: 18.4361787 },
    { lat: 48.7098528, lng: 18.4345498 },
    { lat: 48.7098272, lng: 18.4343959 },
    { lat: 48.7097253, lng: 18.4345492 },
    { lat: 48.7096104, lng: 18.434877 },
    { lat: 48.7095513, lng: 18.435196 },
    { lat: 48.70947, lng: 18.4354992 },
    { lat: 48.7094244, lng: 18.4355717 },
    { lat: 48.7092929, lng: 18.4356612 },
    { lat: 48.7092027, lng: 18.4357645 },
    { lat: 48.7091581, lng: 18.435774 },
    { lat: 48.7090839, lng: 18.4357199 },
    { lat: 48.7090081, lng: 18.4355509 },
    { lat: 48.7089923, lng: 18.4353893 },
    { lat: 48.7088332, lng: 18.4346557 },
    { lat: 48.7086229, lng: 18.4340787 },
    { lat: 48.7084584, lng: 18.4338788 },
    { lat: 48.7079837, lng: 18.4331703 },
    { lat: 48.7072783, lng: 18.4322299 },
    { lat: 48.70724, lng: 18.4321705 },
    { lat: 48.707308, lng: 18.4312825 },
    { lat: 48.7072625, lng: 18.4312725 },
    { lat: 48.7066985, lng: 18.4315579 },
    { lat: 48.7060088, lng: 18.4319175 },
    { lat: 48.7044569, lng: 18.4325956 },
    { lat: 48.7043128, lng: 18.4316484 },
    { lat: 48.7041852, lng: 18.4314824 },
    { lat: 48.7043412, lng: 18.4313255 },
    { lat: 48.7044615, lng: 18.4310742 },
    { lat: 48.7044935, lng: 18.430799 },
    { lat: 48.7041945, lng: 18.4309401 },
    { lat: 48.70351, lng: 18.431248 },
    { lat: 48.7035009, lng: 18.4311645 },
    { lat: 48.7031214, lng: 18.4313208 },
    { lat: 48.7029808, lng: 18.430615 },
    { lat: 48.702859, lng: 18.4299886 },
    { lat: 48.7027697, lng: 18.4296357 },
    { lat: 48.7026452, lng: 18.429285 },
    { lat: 48.7026122, lng: 18.4288142 },
    { lat: 48.7025394, lng: 18.4283966 },
    { lat: 48.7025239, lng: 18.4281172 },
    { lat: 48.7023445, lng: 18.4271213 },
    { lat: 48.7022383, lng: 18.4262055 },
    { lat: 48.7022433, lng: 18.425947 },
    { lat: 48.7022657, lng: 18.425762 },
    { lat: 48.7022746, lng: 18.4255573 },
    { lat: 48.7023922, lng: 18.4250142 },
    { lat: 48.7024082, lng: 18.4249161 },
    { lat: 48.7023975, lng: 18.4248256 },
    { lat: 48.7024555, lng: 18.4244268 },
    { lat: 48.7024882, lng: 18.4242866 },
    { lat: 48.7024528, lng: 18.4239943 },
    { lat: 48.702362, lng: 18.4237605 },
    { lat: 48.7022856, lng: 18.4232798 },
    { lat: 48.7023438, lng: 18.4228467 },
    { lat: 48.7024037, lng: 18.4226441 },
    { lat: 48.7024455, lng: 18.4219156 },
    { lat: 48.7024716, lng: 18.4217126 },
    { lat: 48.7025051, lng: 18.4212009 },
    { lat: 48.7025241, lng: 18.4210163 },
    { lat: 48.7025121, lng: 18.420656 },
    { lat: 48.7024689, lng: 18.4201847 },
    { lat: 48.7023859, lng: 18.4198485 },
    { lat: 48.7023559, lng: 18.4194119 },
    { lat: 48.7023162, lng: 18.4191823 },
    { lat: 48.7022564, lng: 18.4190413 },
    { lat: 48.702205, lng: 18.4189475 },
    { lat: 48.7018835, lng: 18.4186236 },
    { lat: 48.7017699, lng: 18.4184677 },
    { lat: 48.701562, lng: 18.418198 },
    { lat: 48.7015281, lng: 18.4180734 },
    { lat: 48.701517, lng: 18.4176312 },
    { lat: 48.7015742, lng: 18.417444 },
    { lat: 48.7016453, lng: 18.4172821 },
    { lat: 48.7017689, lng: 18.4170896 },
    { lat: 48.7019469, lng: 18.4168645 },
    { lat: 48.702335, lng: 18.4165995 },
    { lat: 48.7024914, lng: 18.4163896 },
    { lat: 48.7026101, lng: 18.4162506 },
    { lat: 48.7026796, lng: 18.4161439 },
    { lat: 48.7026574, lng: 18.4160892 },
    { lat: 48.7030481, lng: 18.4155492 },
    { lat: 48.7031798, lng: 18.4153996 },
    { lat: 48.7032538, lng: 18.4152306 },
    { lat: 48.7033583, lng: 18.4151209 },
    { lat: 48.7033755, lng: 18.4151441 },
    { lat: 48.7034944, lng: 18.4150722 },
    { lat: 48.7035327, lng: 18.4149916 },
    { lat: 48.7035302, lng: 18.4148658 },
    { lat: 48.7035686, lng: 18.4148249 },
    { lat: 48.7034413, lng: 18.4145464 },
    { lat: 48.703402, lng: 18.4143046 },
    { lat: 48.7034171, lng: 18.4139294 },
    { lat: 48.7033787, lng: 18.4136857 },
    { lat: 48.7032444, lng: 18.413401 },
    { lat: 48.7032149, lng: 18.4132151 },
    { lat: 48.7032661, lng: 18.4129793 },
    { lat: 48.7032489, lng: 18.4129129 },
    { lat: 48.7031518, lng: 18.4129095 },
    { lat: 48.7030017, lng: 18.4127482 },
    { lat: 48.7028851, lng: 18.4125398 },
    { lat: 48.7027683, lng: 18.4123654 },
    { lat: 48.7027304, lng: 18.4123712 },
    { lat: 48.702421, lng: 18.4122873 },
    { lat: 48.7022724, lng: 18.4122134 },
    { lat: 48.7021808, lng: 18.4121268 },
    { lat: 48.7018118, lng: 18.4120633 },
    { lat: 48.7015787, lng: 18.4119901 },
    { lat: 48.7009162, lng: 18.4121082 },
    { lat: 48.700651, lng: 18.4121258 },
    { lat: 48.6995495, lng: 18.4122594 },
    { lat: 48.6992888, lng: 18.4123207 },
    { lat: 48.6978895, lng: 18.4119671 },
    { lat: 48.6972474, lng: 18.4120466 },
    { lat: 48.6966642, lng: 18.41231 },
    { lat: 48.6961729, lng: 18.412521 },
    { lat: 48.6951978, lng: 18.4125145 },
    { lat: 48.6948757, lng: 18.4124065 },
    { lat: 48.694365, lng: 18.4116404 },
    { lat: 48.6939555, lng: 18.4111558 },
    { lat: 48.6934653, lng: 18.4107206 },
    { lat: 48.692288, lng: 18.40954 },
    { lat: 48.692019, lng: 18.409322 },
    { lat: 48.691982, lng: 18.409323 },
    { lat: 48.691952, lng: 18.409369 },
    { lat: 48.691743, lng: 18.409452 },
    { lat: 48.691505, lng: 18.409307 },
    { lat: 48.691307, lng: 18.409241 },
    { lat: 48.691121, lng: 18.409173 },
    { lat: 48.690876, lng: 18.409084 },
    { lat: 48.690657, lng: 18.409068 },
    { lat: 48.690315, lng: 18.409033 },
    { lat: 48.69012, lng: 18.408966 },
    { lat: 48.689939, lng: 18.408759 },
    { lat: 48.689888, lng: 18.40864 },
    { lat: 48.689737, lng: 18.408348 },
    { lat: 48.689609, lng: 18.40807 },
    { lat: 48.689519, lng: 18.407784 },
    { lat: 48.689007, lng: 18.40679 },
    { lat: 48.688508, lng: 18.407295 },
    { lat: 48.688411, lng: 18.407393 },
    { lat: 48.688401, lng: 18.407364 },
    { lat: 48.68789, lng: 18.407693 },
    { lat: 48.687866, lng: 18.407709 },
    { lat: 48.687508, lng: 18.407939 },
    { lat: 48.687387, lng: 18.408034 },
    { lat: 48.687393, lng: 18.408052 },
    { lat: 48.687201, lng: 18.408169 },
    { lat: 48.686441, lng: 18.40859 },
    { lat: 48.685878, lng: 18.408855 },
    { lat: 48.685768, lng: 18.408902 },
    { lat: 48.685693, lng: 18.408935 },
    { lat: 48.68565, lng: 18.408954 },
    { lat: 48.685614, lng: 18.40897 },
    { lat: 48.685574, lng: 18.408993 },
    { lat: 48.685388, lng: 18.409101 },
    { lat: 48.685244, lng: 18.40915 },
    { lat: 48.685138, lng: 18.409151 },
    { lat: 48.684938, lng: 18.409188 },
    { lat: 48.68472, lng: 18.409596 },
    { lat: 48.684529, lng: 18.409864 },
    { lat: 48.684462, lng: 18.409915 },
    { lat: 48.684328, lng: 18.410043 },
    { lat: 48.684254, lng: 18.410114 },
    { lat: 48.684173, lng: 18.410258 },
    { lat: 48.684161, lng: 18.410281 },
    { lat: 48.684144, lng: 18.410309 },
    { lat: 48.684006, lng: 18.410555 },
    { lat: 48.683914, lng: 18.410718 },
    { lat: 48.683568, lng: 18.411105 },
    { lat: 48.683097, lng: 18.411729 },
    { lat: 48.682692, lng: 18.411788 },
    { lat: 48.682212, lng: 18.412082 },
    { lat: 48.681642, lng: 18.41228 },
    { lat: 48.681592, lng: 18.412297 },
    { lat: 48.68156, lng: 18.412323 },
    { lat: 48.68118, lng: 18.412634 },
    { lat: 48.681109, lng: 18.412694 },
    { lat: 48.68101, lng: 18.412776 },
    { lat: 48.680465, lng: 18.413802 },
    { lat: 48.68008, lng: 18.41451 },
    { lat: 48.67908, lng: 18.4164 },
    { lat: 48.678903, lng: 18.4166 },
    { lat: 48.6784, lng: 18.416832 },
    { lat: 48.678049, lng: 18.417052 },
    { lat: 48.677995, lng: 18.41702 },
    { lat: 48.678137, lng: 18.417343 },
    { lat: 48.678234, lng: 18.417893 },
    { lat: 48.678335, lng: 18.418212 },
    { lat: 48.678319, lng: 18.41822 },
    { lat: 48.678302, lng: 18.418229 },
    { lat: 48.678303, lng: 18.418855 },
    { lat: 48.678146, lng: 18.419702 },
    { lat: 48.677841, lng: 18.421274 },
    { lat: 48.677834, lng: 18.42155 },
    { lat: 48.677819, lng: 18.422146 },
    { lat: 48.677987, lng: 18.422939 },
    { lat: 48.678229, lng: 18.424155 },
    { lat: 48.678248, lng: 18.424817 },
    { lat: 48.678175, lng: 18.425616 },
    { lat: 48.677854, lng: 18.426301 },
    { lat: 48.677398, lng: 18.426813 },
    { lat: 48.677379, lng: 18.42718 },
    { lat: 48.677396, lng: 18.427409 },
    { lat: 48.677539, lng: 18.42787 },
    { lat: 48.677607, lng: 18.428302 },
    { lat: 48.677633, lng: 18.428398 },
    { lat: 48.677693, lng: 18.428522 },
    { lat: 48.677815, lng: 18.429046 },
    { lat: 48.677856, lng: 18.429202 },
    { lat: 48.678022, lng: 18.429612 },
    { lat: 48.678266, lng: 18.430222 },
    { lat: 48.67843, lng: 18.430366 },
    { lat: 48.678554, lng: 18.430425 },
    { lat: 48.678859, lng: 18.430442 },
    { lat: 48.678985, lng: 18.430398 },
    { lat: 48.679388, lng: 18.430309 },
    { lat: 48.679736, lng: 18.430441 },
    { lat: 48.679847, lng: 18.431079 },
    { lat: 48.679974, lng: 18.431565 },
    { lat: 48.680148, lng: 18.43206 },
    { lat: 48.680288, lng: 18.432353 },
    { lat: 48.680491, lng: 18.433064 },
    { lat: 48.680503, lng: 18.433238 },
    { lat: 48.680481, lng: 18.433761 },
    { lat: 48.680476, lng: 18.434424 },
    { lat: 48.680397, lng: 18.435133 },
    { lat: 48.680335, lng: 18.43552 },
    { lat: 48.680302, lng: 18.435733 },
    { lat: 48.680216, lng: 18.436286 },
    { lat: 48.680406, lng: 18.436505 },
    { lat: 48.680212, lng: 18.437258 },
    { lat: 48.680128, lng: 18.437798 },
    { lat: 48.680102, lng: 18.437928 },
    { lat: 48.680102, lng: 18.438064 },
    { lat: 48.680125, lng: 18.438316 },
    { lat: 48.680223, lng: 18.43916 },
    { lat: 48.680212, lng: 18.43989 },
    { lat: 48.680283, lng: 18.440127 },
    { lat: 48.680688, lng: 18.440017 },
    { lat: 48.68105, lng: 18.439888 },
    { lat: 48.681584, lng: 18.439773 },
    { lat: 48.682069, lng: 18.439764 },
    { lat: 48.682531, lng: 18.439815 },
    { lat: 48.683061, lng: 18.43992 },
    { lat: 48.683566, lng: 18.439925 },
    { lat: 48.683785, lng: 18.440054 },
    { lat: 48.683797, lng: 18.440148 },
    { lat: 48.683705, lng: 18.440404 },
    { lat: 48.683432, lng: 18.440774 },
    { lat: 48.683332, lng: 18.441098 },
    { lat: 48.683396, lng: 18.4415 },
    { lat: 48.683654, lng: 18.442265 },
    { lat: 48.683681, lng: 18.442674 },
    { lat: 48.683896, lng: 18.444531 },
    { lat: 48.684131, lng: 18.445419 },
    { lat: 48.684226, lng: 18.446383 },
    { lat: 48.683399, lng: 18.447775 },
    { lat: 48.683449, lng: 18.447998 },
    { lat: 48.683694, lng: 18.448963 },
    { lat: 48.683704, lng: 18.449001 },
    { lat: 48.683828, lng: 18.449503 },
    { lat: 48.683671, lng: 18.451109 },
    { lat: 48.68305, lng: 18.452262 },
    { lat: 48.683025, lng: 18.452307 },
    { lat: 48.682543, lng: 18.453205 },
    { lat: 48.680782, lng: 18.455329 },
    { lat: 48.679996, lng: 18.455809 },
    { lat: 48.679372, lng: 18.456408 },
    { lat: 48.678858, lng: 18.456953 },
    { lat: 48.678834, lng: 18.456977 },
    { lat: 48.678641, lng: 18.457184 },
  ],
  Bystričany: [
    { lat: 48.678641, lng: 18.457184 },
    { lat: 48.677887, lng: 18.457389 },
    { lat: 48.677439, lng: 18.457396 },
    { lat: 48.6768, lng: 18.457552 },
    { lat: 48.67603, lng: 18.45773 },
    { lat: 48.675571, lng: 18.457691 },
    { lat: 48.675076, lng: 18.457681 },
    { lat: 48.674417, lng: 18.457668 },
    { lat: 48.673606, lng: 18.457735 },
    { lat: 48.672911, lng: 18.457877 },
    { lat: 48.672107, lng: 18.457581 },
    { lat: 48.671194, lng: 18.457038 },
    { lat: 48.670554, lng: 18.456151 },
    { lat: 48.669924, lng: 18.456 },
    { lat: 48.669488, lng: 18.456084 },
    { lat: 48.668871, lng: 18.456141 },
    { lat: 48.668318, lng: 18.456355 },
    { lat: 48.667839, lng: 18.456412 },
    { lat: 48.667555, lng: 18.456571 },
    { lat: 48.666441, lng: 18.458179 },
    { lat: 48.665625, lng: 18.459143 },
    { lat: 48.665303, lng: 18.459341 },
    { lat: 48.664861, lng: 18.45928 },
    { lat: 48.664411, lng: 18.459021 },
    { lat: 48.664145, lng: 18.458799 },
    { lat: 48.663363, lng: 18.458041 },
    { lat: 48.662912, lng: 18.457695 },
    { lat: 48.662593, lng: 18.457322 },
    { lat: 48.662026, lng: 18.457196 },
    { lat: 48.660895, lng: 18.456936 },
    { lat: 48.660799, lng: 18.456966 },
    { lat: 48.660033, lng: 18.456837 },
    { lat: 48.659621, lng: 18.457225 },
    { lat: 48.659272, lng: 18.457501 },
    { lat: 48.658592, lng: 18.45816 },
    { lat: 48.657782, lng: 18.459273 },
    { lat: 48.657487, lng: 18.459558 },
    { lat: 48.657018, lng: 18.460029 },
    { lat: 48.656735, lng: 18.460357 },
    { lat: 48.656271, lng: 18.460569 },
    { lat: 48.655993, lng: 18.460629 },
    { lat: 48.655226, lng: 18.461092 },
    { lat: 48.654874, lng: 18.461272 },
    { lat: 48.654851, lng: 18.461274 },
    { lat: 48.654351, lng: 18.461329 },
    { lat: 48.654048, lng: 18.461722 },
    { lat: 48.65393, lng: 18.46196 },
    { lat: 48.653511, lng: 18.46365 },
    { lat: 48.653517, lng: 18.464585 },
    { lat: 48.653336, lng: 18.465321 },
    { lat: 48.653043, lng: 18.465828 },
    { lat: 48.6531499, lng: 18.4662367 },
    { lat: 48.6532353, lng: 18.4666566 },
    { lat: 48.6532968, lng: 18.4668537 },
    { lat: 48.6533987, lng: 18.4670207 },
    { lat: 48.6535315, lng: 18.4671399 },
    { lat: 48.6536994, lng: 18.4671979 },
    { lat: 48.6539347, lng: 18.467229 },
    { lat: 48.654202, lng: 18.4672913 },
    { lat: 48.654416, lng: 18.4673661 },
    { lat: 48.6545182, lng: 18.4674189 },
    { lat: 48.6547058, lng: 18.4674638 },
    { lat: 48.6553887, lng: 18.4676561 },
    { lat: 48.6555164, lng: 18.4677214 },
    { lat: 48.6557098, lng: 18.467833 },
    { lat: 48.6559035, lng: 18.4679717 },
    { lat: 48.6560419, lng: 18.4680852 },
    { lat: 48.6562267, lng: 18.4681658 },
    { lat: 48.6572981, lng: 18.4701997 },
    { lat: 48.658097, lng: 18.4717187 },
    { lat: 48.6581232, lng: 18.471765 },
    { lat: 48.6564264, lng: 18.4736401 },
    { lat: 48.6554721, lng: 18.4746863 },
    { lat: 48.6553953, lng: 18.4747641 },
    { lat: 48.6554046, lng: 18.4747914 },
    { lat: 48.6554035, lng: 18.4748098 },
    { lat: 48.6553016, lng: 18.474864 },
    { lat: 48.6554679, lng: 18.4761011 },
    { lat: 48.6558342, lng: 18.4767505 },
    { lat: 48.6567333, lng: 18.4776694 },
    { lat: 48.6567113, lng: 18.4778841 },
    { lat: 48.6566871, lng: 18.4782414 },
    { lat: 48.6564574, lng: 18.4794788 },
    { lat: 48.6562615, lng: 18.4804871 },
    { lat: 48.6560118, lng: 18.4819345 },
    { lat: 48.6559733, lng: 18.4822783 },
    { lat: 48.6557245, lng: 18.4837003 },
    { lat: 48.6555644, lng: 18.4846263 },
    { lat: 48.6555299, lng: 18.4849407 },
    { lat: 48.6555415, lng: 18.4853777 },
    { lat: 48.6555513, lng: 18.4855135 },
    { lat: 48.6555852, lng: 18.4856991 },
    { lat: 48.6556546, lng: 18.485852 },
    { lat: 48.6555368, lng: 18.4861725 },
    { lat: 48.6553802, lng: 18.486484 },
    { lat: 48.6553891, lng: 18.4865643 },
    { lat: 48.6556547, lng: 18.486896 },
    { lat: 48.6555919, lng: 18.4871943 },
    { lat: 48.6557296, lng: 18.4872958 },
    { lat: 48.6562178, lng: 18.4877002 },
    { lat: 48.6575058, lng: 18.4888452 },
    { lat: 48.6585599, lng: 18.489898 },
    { lat: 48.6589272, lng: 18.4901984 },
    { lat: 48.6595658, lng: 18.4907102 },
    { lat: 48.6595991, lng: 18.4907846 },
    { lat: 48.6593397, lng: 18.4915864 },
    { lat: 48.6588033, lng: 18.4932782 },
    { lat: 48.6583382, lng: 18.4947077 },
    { lat: 48.6582925, lng: 18.4948383 },
    { lat: 48.6579561, lng: 18.4958606 },
    { lat: 48.6577179, lng: 18.4965762 },
    { lat: 48.6575132, lng: 18.4971609 },
    { lat: 48.6572559, lng: 18.4979157 },
    { lat: 48.6571456, lng: 18.4977964 },
    { lat: 48.6571264, lng: 18.4978395 },
    { lat: 48.6572299, lng: 18.4979978 },
    { lat: 48.6571305, lng: 18.4983442 },
    { lat: 48.6568614, lng: 18.4991115 },
    { lat: 48.656569, lng: 18.4999926 },
    { lat: 48.6563336, lng: 18.5006954 },
    { lat: 48.6558293, lng: 18.502359 },
    { lat: 48.6556005, lng: 18.502935 },
    { lat: 48.6551401, lng: 18.5042495 },
    { lat: 48.6550754, lng: 18.504629 },
    { lat: 48.6546984, lng: 18.5063527 },
    { lat: 48.6544064, lng: 18.5075255 },
    { lat: 48.6530441, lng: 18.5134373 },
    { lat: 48.6526965, lng: 18.5148913 },
    { lat: 48.6526492, lng: 18.5148516 },
    { lat: 48.6518986, lng: 18.5148632 },
    { lat: 48.6517355, lng: 18.5148711 },
    { lat: 48.6514372, lng: 18.5152341 },
    { lat: 48.6514075, lng: 18.5153081 },
    { lat: 48.65129, lng: 18.516007 },
    { lat: 48.6511968, lng: 18.5170018 },
    { lat: 48.6508367, lng: 18.5175734 },
    { lat: 48.6506002, lng: 18.5180113 },
    { lat: 48.6502251, lng: 18.5183746 },
    { lat: 48.6501434, lng: 18.5191646 },
    { lat: 48.6499978, lng: 18.519537 },
    { lat: 48.6496135, lng: 18.5203052 },
    { lat: 48.6497524, lng: 18.5209243 },
    { lat: 48.6495264, lng: 18.5218861 },
    { lat: 48.6498393, lng: 18.5226326 },
    { lat: 48.6493779, lng: 18.5233544 },
    { lat: 48.6491195, lng: 18.5233524 },
    { lat: 48.6489634, lng: 18.5230968 },
    { lat: 48.6488577, lng: 18.5230433 },
    { lat: 48.6480486, lng: 18.5239204 },
    { lat: 48.6476107, lng: 18.5245789 },
    { lat: 48.6472515, lng: 18.5251024 },
    { lat: 48.6469845, lng: 18.5254625 },
    { lat: 48.6467571, lng: 18.5259025 },
    { lat: 48.6462668, lng: 18.5263645 },
    { lat: 48.6461929, lng: 18.5265781 },
    { lat: 48.6461487, lng: 18.5268837 },
    { lat: 48.6461519, lng: 18.5273359 },
    { lat: 48.6460231, lng: 18.5275988 },
    { lat: 48.6454432, lng: 18.5279664 },
    { lat: 48.6449145, lng: 18.5283851 },
    { lat: 48.6444801, lng: 18.5284471 },
    { lat: 48.6441538, lng: 18.5285833 },
    { lat: 48.6437072, lng: 18.5286297 },
    { lat: 48.6435234, lng: 18.5287086 },
    { lat: 48.6429666, lng: 18.5289053 },
    { lat: 48.642404, lng: 18.5291766 },
    { lat: 48.6421041, lng: 18.5293684 },
    { lat: 48.6420137, lng: 18.5296129 },
    { lat: 48.6401129, lng: 18.5310106 },
    { lat: 48.6393972, lng: 18.5317635 },
    { lat: 48.6388224, lng: 18.5325166 },
    { lat: 48.6387568, lng: 18.5328857 },
    { lat: 48.6384469, lng: 18.5332613 },
    { lat: 48.6380855, lng: 18.5331063 },
    { lat: 48.6377355, lng: 18.533596 },
    { lat: 48.6373903, lng: 18.5340088 },
    { lat: 48.6369233, lng: 18.535001 },
    { lat: 48.6365001, lng: 18.5357812 },
    { lat: 48.6360717, lng: 18.5360731 },
    { lat: 48.6355832, lng: 18.5367751 },
    { lat: 48.6355382, lng: 18.5375403 },
    { lat: 48.6356841, lng: 18.5374408 },
    { lat: 48.6359985, lng: 18.5376845 },
    { lat: 48.6361708, lng: 18.5384245 },
    { lat: 48.6358252, lng: 18.5388466 },
    { lat: 48.6355224, lng: 18.5394185 },
    { lat: 48.635345, lng: 18.5396634 },
    { lat: 48.6349213, lng: 18.5396687 },
    { lat: 48.6335964, lng: 18.540317 },
    { lat: 48.6334349, lng: 18.5420906 },
    { lat: 48.6333621, lng: 18.5428449 },
    { lat: 48.6331933, lng: 18.5430778 },
    { lat: 48.6328643, lng: 18.5432502 },
    { lat: 48.6325475, lng: 18.5426531 },
    { lat: 48.6323192, lng: 18.5420688 },
    { lat: 48.6303207, lng: 18.5439122 },
    { lat: 48.6285732, lng: 18.5465541 },
    { lat: 48.6282499, lng: 18.5469105 },
    { lat: 48.6280167, lng: 18.5469769 },
    { lat: 48.626743, lng: 18.5481135 },
    { lat: 48.6265548, lng: 18.5483651 },
    { lat: 48.6259956, lng: 18.5493188 },
    { lat: 48.6258106, lng: 18.5496316 },
    { lat: 48.6249869, lng: 18.5512574 },
    { lat: 48.6247317, lng: 18.5519569 },
    { lat: 48.6241985, lng: 18.5531034 },
    { lat: 48.6238566, lng: 18.5539676 },
    { lat: 48.6237412, lng: 18.5540233 },
    { lat: 48.6237071, lng: 18.5542261 },
    { lat: 48.623822, lng: 18.5545117 },
    { lat: 48.6239139, lng: 18.5545032 },
    { lat: 48.6237919, lng: 18.5562379 },
    { lat: 48.6237992, lng: 18.5567447 },
    { lat: 48.6238823, lng: 18.5576493 },
    { lat: 48.6237942, lng: 18.5581622 },
    { lat: 48.6236429, lng: 18.5584306 },
    { lat: 48.6232647, lng: 18.5588814 },
    { lat: 48.6228705, lng: 18.5598244 },
    { lat: 48.6226344, lng: 18.559982 },
    { lat: 48.6219314, lng: 18.5608478 },
    { lat: 48.6213708, lng: 18.5618221 },
    { lat: 48.6209671, lng: 18.5624034 },
    { lat: 48.6207998, lng: 18.5625824 },
    { lat: 48.6204028, lng: 18.5630198 },
    { lat: 48.6197872, lng: 18.5640194 },
    { lat: 48.6194772, lng: 18.5644777 },
    { lat: 48.6190464, lng: 18.5651617 },
    { lat: 48.6188197, lng: 18.5654743 },
    { lat: 48.6183899, lng: 18.5657624 },
    { lat: 48.6179793, lng: 18.5661369 },
    { lat: 48.6177583, lng: 18.5660367 },
    { lat: 48.6175328, lng: 18.5663662 },
    { lat: 48.617464, lng: 18.5668043 },
    { lat: 48.6161207, lng: 18.5694805 },
    { lat: 48.6150769, lng: 18.5716497 },
    { lat: 48.6130801, lng: 18.5758156 },
    { lat: 48.6117814, lng: 18.5785713 },
    { lat: 48.6107873, lng: 18.5806834 },
    { lat: 48.6100666, lng: 18.5822195 },
    { lat: 48.6083559, lng: 18.5858673 },
    { lat: 48.6072585, lng: 18.5882997 },
    { lat: 48.6067662, lng: 18.5893103 },
    { lat: 48.6064419, lng: 18.5902776 },
    { lat: 48.6053241, lng: 18.5936044 },
    { lat: 48.6048715, lng: 18.5949974 },
    { lat: 48.6047524, lng: 18.5953371 },
    { lat: 48.6044314, lng: 18.5962391 },
    { lat: 48.6036213, lng: 18.5985078 },
    { lat: 48.6024097, lng: 18.6021515 },
    { lat: 48.599847, lng: 18.609858 },
    { lat: 48.600022, lng: 18.609931 },
    { lat: 48.600816, lng: 18.610516 },
    { lat: 48.60164, lng: 18.612222 },
    { lat: 48.602689, lng: 18.614875 },
    { lat: 48.604733, lng: 18.61747 },
    { lat: 48.605093, lng: 18.618142 },
    { lat: 48.605785, lng: 18.61932 },
    { lat: 48.606417, lng: 18.620127 },
    { lat: 48.607039, lng: 18.620821 },
    { lat: 48.607485, lng: 18.621284 },
    { lat: 48.608679, lng: 18.621108 },
    { lat: 48.60994, lng: 18.622374 },
    { lat: 48.611554, lng: 18.623992 },
    { lat: 48.613785, lng: 18.62633 },
    { lat: 48.615089, lng: 18.627431 },
    { lat: 48.616864, lng: 18.629343 },
    { lat: 48.6178, lng: 18.630731 },
    { lat: 48.61895, lng: 18.631642 },
    { lat: 48.619774, lng: 18.632561 },
    { lat: 48.62102, lng: 18.633714 },
    { lat: 48.6219064, lng: 18.6335733 },
    { lat: 48.622482, lng: 18.633482 },
    { lat: 48.62311, lng: 18.63348 },
    { lat: 48.623457, lng: 18.633776 },
    { lat: 48.62383, lng: 18.634051 },
    { lat: 48.6238483, lng: 18.6340676 },
    { lat: 48.6239897, lng: 18.6341954 },
    { lat: 48.6244119, lng: 18.63458 },
    { lat: 48.624167, lng: 18.6336925 },
    { lat: 48.6242896, lng: 18.6329099 },
    { lat: 48.6244757, lng: 18.6324468 },
    { lat: 48.6246014, lng: 18.6318474 },
    { lat: 48.6247812, lng: 18.6307284 },
    { lat: 48.6249426, lng: 18.6301646 },
    { lat: 48.6258898, lng: 18.6286488 },
    { lat: 48.6260311, lng: 18.6265429 },
    { lat: 48.6263523, lng: 18.6257616 },
    { lat: 48.6268301, lng: 18.6250463 },
    { lat: 48.627133, lng: 18.6222502 },
    { lat: 48.6275546, lng: 18.6206372 },
    { lat: 48.6279359, lng: 18.6199058 },
    { lat: 48.6285261, lng: 18.6188237 },
    { lat: 48.6287796, lng: 18.6172101 },
    { lat: 48.6287557, lng: 18.6160133 },
    { lat: 48.628719, lng: 18.6144365 },
    { lat: 48.6287169, lng: 18.6134551 },
    { lat: 48.6286368, lng: 18.6124317 },
    { lat: 48.6285631, lng: 18.6111707 },
    { lat: 48.6284962, lng: 18.6095217 },
    { lat: 48.6284542, lng: 18.6093657 },
    { lat: 48.6282012, lng: 18.6080857 },
    { lat: 48.6282775, lng: 18.607661 },
    { lat: 48.6278953, lng: 18.6060945 },
    { lat: 48.6278039, lng: 18.60572 },
    { lat: 48.6277387, lng: 18.6053384 },
    { lat: 48.6276076, lng: 18.6045715 },
    { lat: 48.6274136, lng: 18.6030479 },
    { lat: 48.6273558, lng: 18.6028126 },
    { lat: 48.6272516, lng: 18.6024956 },
    { lat: 48.6270897, lng: 18.6020156 },
    { lat: 48.6267553, lng: 18.6010239 },
    { lat: 48.6266808, lng: 18.6008031 },
    { lat: 48.6262688, lng: 18.599986 },
    { lat: 48.6257832, lng: 18.5992012 },
    { lat: 48.625517, lng: 18.5985582 },
    { lat: 48.6254096, lng: 18.5982988 },
    { lat: 48.6249453, lng: 18.5972246 },
    { lat: 48.6246692, lng: 18.5965859 },
    { lat: 48.6240816, lng: 18.595813 },
    { lat: 48.6238691, lng: 18.5954906 },
    { lat: 48.6232239, lng: 18.5945122 },
    { lat: 48.6234912, lng: 18.5943249 },
    { lat: 48.6241341, lng: 18.5942022 },
    { lat: 48.6247657, lng: 18.5939892 },
    { lat: 48.6262732, lng: 18.593378 },
    { lat: 48.6272582, lng: 18.5929439 },
    { lat: 48.6279022, lng: 18.5926945 },
    { lat: 48.6294195, lng: 18.5921162 },
    { lat: 48.6299626, lng: 18.5918963 },
    { lat: 48.6313397, lng: 18.5913346 },
    { lat: 48.6328851, lng: 18.5907129 },
    { lat: 48.6339649, lng: 18.5903654 },
    { lat: 48.6349384, lng: 18.5889687 },
    { lat: 48.6353398, lng: 18.5885116 },
    { lat: 48.6356577, lng: 18.5879892 },
    { lat: 48.636216, lng: 18.5876107 },
    { lat: 48.6365448, lng: 18.5869136 },
    { lat: 48.6377771, lng: 18.5851977 },
    { lat: 48.6385893, lng: 18.5840796 },
    { lat: 48.6389178, lng: 18.5833768 },
    { lat: 48.6392946, lng: 18.5828769 },
    { lat: 48.6396078, lng: 18.5825369 },
    { lat: 48.6399518, lng: 18.5824345 },
    { lat: 48.6403772, lng: 18.5821241 },
    { lat: 48.6416059, lng: 18.5817369 },
    { lat: 48.642672, lng: 18.5802841 },
    { lat: 48.643514, lng: 18.5798591 },
    { lat: 48.6445678, lng: 18.5796568 },
    { lat: 48.6451566, lng: 18.5792612 },
    { lat: 48.6459601, lng: 18.5787264 },
    { lat: 48.6464028, lng: 18.5784263 },
    { lat: 48.6469796, lng: 18.5780393 },
    { lat: 48.647534, lng: 18.5776765 },
    { lat: 48.6477633, lng: 18.5778258 },
    { lat: 48.6490534, lng: 18.5775732 },
    { lat: 48.6496559, lng: 18.5774523 },
    { lat: 48.6496914, lng: 18.5774615 },
    { lat: 48.6497493, lng: 18.5771351 },
    { lat: 48.64988, lng: 18.5765896 },
    { lat: 48.6499513, lng: 18.576366 },
    { lat: 48.6500471, lng: 18.5761801 },
    { lat: 48.6502393, lng: 18.5758759 },
    { lat: 48.6503969, lng: 18.5755572 },
    { lat: 48.6505629, lng: 18.5752723 },
    { lat: 48.6506625, lng: 18.5750466 },
    { lat: 48.6506974, lng: 18.5749453 },
    { lat: 48.6507279, lng: 18.5747589 },
    { lat: 48.6507379, lng: 18.5745893 },
    { lat: 48.6507374, lng: 18.5744113 },
    { lat: 48.6507056, lng: 18.5739126 },
    { lat: 48.6506747, lng: 18.5735308 },
    { lat: 48.6506799, lng: 18.5732302 },
    { lat: 48.6506562, lng: 18.573003 },
    { lat: 48.6507289, lng: 18.5725967 },
    { lat: 48.6507054, lng: 18.5719992 },
    { lat: 48.6507177, lng: 18.5715157 },
    { lat: 48.6507022, lng: 18.5711089 },
    { lat: 48.6507254, lng: 18.5707664 },
    { lat: 48.6507224, lng: 18.5706704 },
    { lat: 48.6506636, lng: 18.5697941 },
    { lat: 48.6507115, lng: 18.5693494 },
    { lat: 48.6506949, lng: 18.5690654 },
    { lat: 48.6507194, lng: 18.5687894 },
    { lat: 48.6507169, lng: 18.5684516 },
    { lat: 48.6507021, lng: 18.5680325 },
    { lat: 48.6506242, lng: 18.5677639 },
    { lat: 48.6506945, lng: 18.5676228 },
    { lat: 48.6507362, lng: 18.5675595 },
    { lat: 48.6508937, lng: 18.5673656 },
    { lat: 48.6510198, lng: 18.5671969 },
    { lat: 48.651223, lng: 18.5667826 },
    { lat: 48.6514347, lng: 18.5667777 },
    { lat: 48.6514984, lng: 18.5667438 },
    { lat: 48.6517861, lng: 18.566563 },
    { lat: 48.6519629, lng: 18.5664936 },
    { lat: 48.6520285, lng: 18.5663739 },
    { lat: 48.6521795, lng: 18.5664511 },
    { lat: 48.6522997, lng: 18.5663592 },
    { lat: 48.6525283, lng: 18.5661029 },
    { lat: 48.6528176, lng: 18.5660713 },
    { lat: 48.653069, lng: 18.565697 },
    { lat: 48.6531408, lng: 18.5654819 },
    { lat: 48.6532134, lng: 18.5652393 },
    { lat: 48.6532245, lng: 18.5649054 },
    { lat: 48.6533198, lng: 18.5647709 },
    { lat: 48.6534986, lng: 18.5645721 },
    { lat: 48.6536213, lng: 18.5644032 },
    { lat: 48.6538367, lng: 18.5642134 },
    { lat: 48.6538272, lng: 18.5641661 },
    { lat: 48.6545496, lng: 18.5620202 },
    { lat: 48.6563052, lng: 18.5593331 },
    { lat: 48.6573653, lng: 18.5568016 },
    { lat: 48.6580723, lng: 18.5558429 },
    { lat: 48.6587212, lng: 18.5541908 },
    { lat: 48.6595852, lng: 18.5529293 },
    { lat: 48.6607197, lng: 18.5515364 },
    { lat: 48.6610602, lng: 18.5514731 },
    { lat: 48.6621424, lng: 18.548709 },
    { lat: 48.6625722, lng: 18.5477343 },
    { lat: 48.6631267, lng: 18.5474184 },
    { lat: 48.6635945, lng: 18.5469382 },
    { lat: 48.6645697, lng: 18.5456237 },
    { lat: 48.6643901, lng: 18.5427247 },
    { lat: 48.6656391, lng: 18.5403534 },
    { lat: 48.6667266, lng: 18.5383724 },
    { lat: 48.6658815, lng: 18.5367148 },
    { lat: 48.6657768, lng: 18.5364495 },
    { lat: 48.6659423, lng: 18.5360528 },
    { lat: 48.6660789, lng: 18.5358274 },
    { lat: 48.6662113, lng: 18.5356892 },
    { lat: 48.6665479, lng: 18.5354303 },
    { lat: 48.6666888, lng: 18.5352226 },
    { lat: 48.6670272, lng: 18.5345806 },
    { lat: 48.6677555, lng: 18.5331106 },
    { lat: 48.667837, lng: 18.5329506 },
    { lat: 48.6683681, lng: 18.5320372 },
    { lat: 48.6687641, lng: 18.5314801 },
    { lat: 48.6691736, lng: 18.5309469 },
    { lat: 48.669359, lng: 18.5303339 },
    { lat: 48.6695026, lng: 18.529351 },
    { lat: 48.6696457, lng: 18.5277608 },
    { lat: 48.6703154, lng: 18.5259182 },
    { lat: 48.6704701, lng: 18.5256334 },
    { lat: 48.6710006, lng: 18.5246672 },
    { lat: 48.6715803, lng: 18.5235121 },
    { lat: 48.671776, lng: 18.523175 },
    { lat: 48.6720729, lng: 18.5226405 },
    { lat: 48.6722816, lng: 18.5222725 },
    { lat: 48.6725632, lng: 18.5216646 },
    { lat: 48.673308, lng: 18.5209906 },
    { lat: 48.6737205, lng: 18.5206975 },
    { lat: 48.6741026, lng: 18.5203635 },
    { lat: 48.6743265, lng: 18.5200629 },
    { lat: 48.6744565, lng: 18.5198803 },
    { lat: 48.6746486, lng: 18.5196633 },
    { lat: 48.6752784, lng: 18.518763 },
    { lat: 48.6745382, lng: 18.5180523 },
    { lat: 48.6745183, lng: 18.5180442 },
    { lat: 48.6748824, lng: 18.5169287 },
    { lat: 48.6749419, lng: 18.5166771 },
    { lat: 48.6757502, lng: 18.5161318 },
    { lat: 48.6760974, lng: 18.5157158 },
    { lat: 48.676258, lng: 18.5153945 },
    { lat: 48.6772233, lng: 18.5136601 },
    { lat: 48.6775061, lng: 18.5137173 },
    { lat: 48.6777188, lng: 18.5124924 },
    { lat: 48.6782112, lng: 18.5113925 },
    { lat: 48.6784692, lng: 18.5108738 },
    { lat: 48.6783686, lng: 18.5102292 },
    { lat: 48.6783284, lng: 18.5096421 },
    { lat: 48.6781068, lng: 18.5092215 },
    { lat: 48.6778905, lng: 18.5086699 },
    { lat: 48.6776221, lng: 18.5083619 },
    { lat: 48.67799, lng: 18.5076866 },
    { lat: 48.678002, lng: 18.5074973 },
    { lat: 48.6774389, lng: 18.5065873 },
    { lat: 48.6779741, lng: 18.5055666 },
    { lat: 48.6782414, lng: 18.5059193 },
    { lat: 48.6785504, lng: 18.5054024 },
    { lat: 48.6789478, lng: 18.5047501 },
    { lat: 48.6791054, lng: 18.5043342 },
    { lat: 48.6793494, lng: 18.5037025 },
    { lat: 48.6796613, lng: 18.5029949 },
    { lat: 48.6798321, lng: 18.502221 },
    { lat: 48.6801906, lng: 18.5004902 },
    { lat: 48.6802892, lng: 18.4990397 },
    { lat: 48.6803859, lng: 18.4984814 },
    { lat: 48.6803407, lng: 18.4978642 },
    { lat: 48.6801677, lng: 18.4968721 },
    { lat: 48.6804579, lng: 18.4953186 },
    { lat: 48.6815459, lng: 18.4928234 },
    { lat: 48.6815966, lng: 18.4923101 },
    { lat: 48.6816002, lng: 18.4920487 },
    { lat: 48.681552, lng: 18.4916617 },
    { lat: 48.6814634, lng: 18.4910785 },
    { lat: 48.6814728, lng: 18.4908196 },
    { lat: 48.6814465, lng: 18.4905651 },
    { lat: 48.6814211, lng: 18.4904116 },
    { lat: 48.6813591, lng: 18.4902091 },
    { lat: 48.6813456, lng: 18.4899203 },
    { lat: 48.6812476, lng: 18.4894114 },
    { lat: 48.681188, lng: 18.4893125 },
    { lat: 48.6812145, lng: 18.4891192 },
    { lat: 48.6813834, lng: 18.4882324 },
    { lat: 48.6815496, lng: 18.4877857 },
    { lat: 48.6815403, lng: 18.4875566 },
    { lat: 48.6815685, lng: 18.4871671 },
    { lat: 48.6816052, lng: 18.4870519 },
    { lat: 48.6817956, lng: 18.4865989 },
    { lat: 48.6818962, lng: 18.4864163 },
    { lat: 48.6820758, lng: 18.4858872 },
    { lat: 48.6822, lng: 18.4856657 },
    { lat: 48.6822667, lng: 18.4855855 },
    { lat: 48.6823351, lng: 18.485437 },
    { lat: 48.6824008, lng: 18.4850921 },
    { lat: 48.6824077, lng: 18.4848268 },
    { lat: 48.681848, lng: 18.4840849 },
    { lat: 48.6825037, lng: 18.4834792 },
    { lat: 48.6828217, lng: 18.4829827 },
    { lat: 48.6832718, lng: 18.4827173 },
    { lat: 48.6830775, lng: 18.4823662 },
    { lat: 48.6829736, lng: 18.4822389 },
    { lat: 48.6829357, lng: 18.482147 },
    { lat: 48.6828263, lng: 18.4820229 },
    { lat: 48.6825618, lng: 18.4816035 },
    { lat: 48.6826167, lng: 18.4808801 },
    { lat: 48.6826159, lng: 18.4803765 },
    { lat: 48.6825518, lng: 18.4798859 },
    { lat: 48.6824151, lng: 18.4795546 },
    { lat: 48.6822605, lng: 18.479206 },
    { lat: 48.6821414, lng: 18.47895 },
    { lat: 48.6820399, lng: 18.4786654 },
    { lat: 48.6819508, lng: 18.4784392 },
    { lat: 48.6818025, lng: 18.4781402 },
    { lat: 48.6816838, lng: 18.4778909 },
    { lat: 48.6814643, lng: 18.4770403 },
    { lat: 48.6811135, lng: 18.4763455 },
    { lat: 48.680864, lng: 18.47579 },
    { lat: 48.6807603, lng: 18.4755853 },
    { lat: 48.6806819, lng: 18.4753296 },
    { lat: 48.6801186, lng: 18.4740786 },
    { lat: 48.6795484, lng: 18.4737994 },
    { lat: 48.6791636, lng: 18.4734926 },
    { lat: 48.6789351, lng: 18.4733982 },
    { lat: 48.6788558, lng: 18.4732736 },
    { lat: 48.6786798, lng: 18.4729238 },
    { lat: 48.6785396, lng: 18.4726098 },
    { lat: 48.6782909, lng: 18.4719679 },
    { lat: 48.6781256, lng: 18.4714857 },
    { lat: 48.6776507, lng: 18.4707113 },
    { lat: 48.677456, lng: 18.4702071 },
    { lat: 48.6773726, lng: 18.46984 },
    { lat: 48.6772936, lng: 18.469594 },
    { lat: 48.6771417, lng: 18.4691944 },
    { lat: 48.6769732, lng: 18.4689422 },
    { lat: 48.6768467, lng: 18.4686925 },
    { lat: 48.6766546, lng: 18.468258 },
    { lat: 48.676615, lng: 18.4682127 },
    { lat: 48.6763156, lng: 18.4680437 },
    { lat: 48.6762651, lng: 18.4680447 },
    { lat: 48.6759662, lng: 18.4678839 },
    { lat: 48.6756982, lng: 18.4673419 },
    { lat: 48.6755616, lng: 18.4669325 },
    { lat: 48.6754738, lng: 18.4655258 },
    { lat: 48.6754714, lng: 18.4655047 },
    { lat: 48.6755099, lng: 18.4654221 },
    { lat: 48.6757415, lng: 18.465143 },
    { lat: 48.6759376, lng: 18.4649138 },
    { lat: 48.6764891, lng: 18.4642172 },
    { lat: 48.676965, lng: 18.463277 },
    { lat: 48.6773278, lng: 18.4625738 },
    { lat: 48.6779728, lng: 18.4608351 },
    { lat: 48.6781754, lng: 18.4585829 },
    { lat: 48.6782748, lng: 18.4579731 },
    { lat: 48.678641, lng: 18.457184 },
  ],
};

export default UnitsPrievidza;
