import ovb from "../Images/imgs/ovb.png";

export const Mock = [
  {
    name: "Item 1",
    price: "100000",
    img: ovb,
  },
  {
    name: "Item 2",
    price: "158000",
    img: ovb,
  },
  {
    name: "Item 3",
    price: "79000",
    img: ovb,
  },
];
