// import AkcenLogo from "../../../Images/logo/akcen_logo_v3_black_trans_edit.png";
// import Akcenlogo from "../../../Images/logo/akcen_logo_v5.png";
import AkcenLOGO from "../../../Images/logo/akcen_logo_v6.png";
import { useMediaQuery } from "usehooks-ts";
import clsx from "clsx";

export default function PropertiesNav({
  setNavSmall,
  navSmall,
  setForSale,
  forSale,
  setGallery,
  setHistory,
  setHistoryMenu,
  viewSale,
  setSubMenu,
  subMenu,
  setViewSale,
  setVisibilityOfMarkers,
  setCurrentItem,
  subjects,
  setSubjects,
  setFilterSale,
  optSubMenu,
  setOptSubMenu,
}) {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 640px)");

  return (
    <>
      <div className={`transition-all duration-500 ease-linear min-h-max flex flex-col justify-center w-14 sm:w-auto`}>
        <button
          onClick={() => {
            if (window.location.pathname.includes("share")) {
              window.history.replaceState(null, "", "/");
            }
            setNavSmall({
              ...navSmall,
              properties: !navSmall.properties,
              subjects: false,
              helper: false,
              options: false,
              login: false,
            });
            setForSale({ ...forSale, menu: false });
            setGallery(false);
            setHistory(false);
            setHistoryMenu(false);
            setSubMenu({ ...subMenu, lv: false, interest: false, owner: true, rating: false, share: false });
            setViewSale({ ...viewSale, view: false });
            setVisibilityOfMarkers("");
            setCurrentItem(null);
            setSubjects({ ...subjects, ROffice: false, RBroker: false, FInstitution: false, FBroker: false });
            setOptSubMenu({ ...optSubMenu, aboutUs: false, contact: false, privacy: false, helper: false });
            setFilterSale([]);
          }}
          className={`disabled:opacity-60 px-3 py-2 flex items-center justify-center text-xs leading-snug text-black sm:flex-col hover:opacity-75 gap-2`}
        >
          <div
            className={clsx("flex flex-col justify-center items-center", {
              "w-16": !isSmallDevice,
            })}
          >
            <img src={AkcenLOGO} alt="logo" className="w-8 h-[22px] object-contain" />
            {isSmallDevice ? null : <p className="w-full overflow-hidden text-ellipsis">Reality</p>}
          </div>
        </button>
      </div>
    </>
  );
}
