const UnitsNámestovo = {
  Babín: [
    { lat: 49.295773, lng: 19.379657 },
    { lat: 49.295172, lng: 19.381231 },
    { lat: 49.294682, lng: 19.382573 },
    { lat: 49.294387, lng: 19.383208 },
    { lat: 49.294397, lng: 19.383228 },
    { lat: 49.294651, lng: 19.384092 },
    { lat: 49.294771, lng: 19.384493 },
    { lat: 49.295073, lng: 19.385465 },
    { lat: 49.295121, lng: 19.385582 },
    { lat: 49.295247, lng: 19.385894 },
    { lat: 49.295305, lng: 19.386096 },
    { lat: 49.295943, lng: 19.388307 },
    { lat: 49.296249, lng: 19.389452 },
    { lat: 49.296345, lng: 19.389985 },
    { lat: 49.296433, lng: 19.390476 },
    { lat: 49.296627, lng: 19.391168 },
    { lat: 49.29692, lng: 19.392215 },
    { lat: 49.296969, lng: 19.392387 },
    { lat: 49.297349, lng: 19.392855 },
    { lat: 49.297717, lng: 19.393126 },
    { lat: 49.29797, lng: 19.393145 },
    { lat: 49.298087, lng: 19.393207 },
    { lat: 49.298378, lng: 19.393358 },
    { lat: 49.29869, lng: 19.393701 },
    { lat: 49.298854, lng: 19.39388 },
    { lat: 49.299283, lng: 19.394265 },
    { lat: 49.29973, lng: 19.394834 },
    { lat: 49.300184, lng: 19.395394 },
    { lat: 49.300456, lng: 19.395728 },
    { lat: 49.3006, lng: 19.396186 },
    { lat: 49.301505, lng: 19.397728 },
    { lat: 49.302103, lng: 19.398556 },
    { lat: 49.302203, lng: 19.398678 },
    { lat: 49.302337, lng: 19.39884 },
    { lat: 49.303044, lng: 19.3997 },
    { lat: 49.303567, lng: 19.400184 },
    { lat: 49.304587, lng: 19.400911 },
    { lat: 49.305406, lng: 19.40159 },
    { lat: 49.305519, lng: 19.401686 },
    { lat: 49.306459, lng: 19.402401 },
    { lat: 49.307426, lng: 19.403359 },
    { lat: 49.307752, lng: 19.403715 },
    { lat: 49.308411, lng: 19.404434 },
    { lat: 49.308921, lng: 19.405882 },
    { lat: 49.309596, lng: 19.406912 },
    { lat: 49.309974, lng: 19.407326 },
    { lat: 49.310422, lng: 19.408181 },
    { lat: 49.310476, lng: 19.408285 },
    { lat: 49.310742, lng: 19.40878 },
    { lat: 49.311138, lng: 19.409648 },
    { lat: 49.311596, lng: 19.410252 },
    { lat: 49.312627, lng: 19.411196 },
    { lat: 49.313775, lng: 19.412717 },
    { lat: 49.314186, lng: 19.413373 },
    { lat: 49.314757, lng: 19.414234 },
    { lat: 49.3152423, lng: 19.4150276 },
    { lat: 49.315393, lng: 19.415274 },
    { lat: 49.316375, lng: 19.416014 },
    { lat: 49.31658, lng: 19.416259 },
    { lat: 49.316847, lng: 19.416559 },
    { lat: 49.3172, lng: 19.416878 },
    { lat: 49.317512, lng: 19.417257 },
    { lat: 49.317808, lng: 19.4177 },
    { lat: 49.318138, lng: 19.418236 },
    { lat: 49.31844, lng: 19.41883 },
    { lat: 49.318727, lng: 19.419782 },
    { lat: 49.319135, lng: 19.420215 },
    { lat: 49.3206094, lng: 19.419837 },
    { lat: 49.3227102, lng: 19.4209814 },
    { lat: 49.3237633, lng: 19.4220438 },
    { lat: 49.324847, lng: 19.4229881 },
    { lat: 49.3253589, lng: 19.4236985 },
    { lat: 49.325666, lng: 19.4243083 },
    { lat: 49.326356, lng: 19.425429 },
    { lat: 49.3282232, lng: 19.4223467 },
    { lat: 49.3282893, lng: 19.422238 },
    { lat: 49.3283867, lng: 19.4220786 },
    { lat: 49.3286907, lng: 19.4207578 },
    { lat: 49.3290974, lng: 19.4199753 },
    { lat: 49.3297858, lng: 19.4191699 },
    { lat: 49.3299061, lng: 19.4185351 },
    { lat: 49.3297466, lng: 19.4180589 },
    { lat: 49.3294889, lng: 19.4175521 },
    { lat: 49.3291333, lng: 19.417026 },
    { lat: 49.3289718, lng: 19.416046 },
    { lat: 49.3292436, lng: 19.4154663 },
    { lat: 49.3296084, lng: 19.4150214 },
    { lat: 49.3298456, lng: 19.4144748 },
    { lat: 49.330023, lng: 19.4140982 },
    { lat: 49.3303967, lng: 19.4135306 },
    { lat: 49.3307748, lng: 19.4128151 },
    { lat: 49.330651, lng: 19.412766 },
    { lat: 49.3309348, lng: 19.4119863 },
    { lat: 49.3312064, lng: 19.4114268 },
    { lat: 49.3313934, lng: 19.4107443 },
    { lat: 49.3315563, lng: 19.4100315 },
    { lat: 49.3315713, lng: 19.4095493 },
    { lat: 49.3315397, lng: 19.4087976 },
    { lat: 49.3315107, lng: 19.4086342 },
    { lat: 49.3314449, lng: 19.4082591 },
    { lat: 49.3314608, lng: 19.4076042 },
    { lat: 49.3313882, lng: 19.4070376 },
    { lat: 49.331508, lng: 19.4065094 },
    { lat: 49.3315231, lng: 19.4060558 },
    { lat: 49.3317947, lng: 19.4054778 },
    { lat: 49.3317686, lng: 19.4050833 },
    { lat: 49.331918, lng: 19.4045352 },
    { lat: 49.3320274, lng: 19.4039333 },
    { lat: 49.331938, lng: 19.4037043 },
    { lat: 49.3318753, lng: 19.4035438 },
    { lat: 49.3320186, lng: 19.4032348 },
    { lat: 49.3320643, lng: 19.4029563 },
    { lat: 49.3321433, lng: 19.4025229 },
    { lat: 49.3323874, lng: 19.4018597 },
    { lat: 49.3324559, lng: 19.4012706 },
    { lat: 49.3325832, lng: 19.4009562 },
    { lat: 49.3330661, lng: 19.4003364 },
    { lat: 49.3329656, lng: 19.3996265 },
    { lat: 49.3331842, lng: 19.3991157 },
    { lat: 49.3334521, lng: 19.3986003 },
    { lat: 49.3335585, lng: 19.3980297 },
    { lat: 49.3338688, lng: 19.3979425 },
    { lat: 49.3342994, lng: 19.3979918 },
    { lat: 49.3349635, lng: 19.397787 },
    { lat: 49.3351286, lng: 19.3973347 },
    { lat: 49.3353579, lng: 19.3972751 },
    { lat: 49.3355403, lng: 19.3970543 },
    { lat: 49.3358569, lng: 19.3967619 },
    { lat: 49.3361808, lng: 19.3966757 },
    { lat: 49.3360067, lng: 19.3963481 },
    { lat: 49.3360346, lng: 19.3961001 },
    { lat: 49.3364575, lng: 19.3959121 },
    { lat: 49.3368699, lng: 19.3956255 },
    { lat: 49.3370734, lng: 19.3952749 },
    { lat: 49.3372989, lng: 19.3954917 },
    { lat: 49.3375575, lng: 19.3951673 },
    { lat: 49.3374624, lng: 19.3948452 },
    { lat: 49.3377296, lng: 19.3946029 },
    { lat: 49.3378039, lng: 19.394916 },
    { lat: 49.3379958, lng: 19.3948493 },
    { lat: 49.338217, lng: 19.3949293 },
    { lat: 49.3383625, lng: 19.3947877 },
    { lat: 49.3382716, lng: 19.3946263 },
    { lat: 49.3387517, lng: 19.3941433 },
    { lat: 49.3390034, lng: 19.3940574 },
    { lat: 49.3393052, lng: 19.393932 },
    { lat: 49.3394013, lng: 19.3942316 },
    { lat: 49.3396373, lng: 19.3938781 },
    { lat: 49.3399083, lng: 19.3939823 },
    { lat: 49.3401319, lng: 19.3938507 },
    { lat: 49.3402148, lng: 19.3935005 },
    { lat: 49.340428, lng: 19.3931203 },
    { lat: 49.3406582, lng: 19.39286 },
    { lat: 49.3409494, lng: 19.392481 },
    { lat: 49.3407829, lng: 19.3922212 },
    { lat: 49.3410696, lng: 19.3920588 },
    { lat: 49.3410702, lng: 19.3918535 },
    { lat: 49.3412137, lng: 19.3914515 },
    { lat: 49.3412892, lng: 19.3908955 },
    { lat: 49.3412661, lng: 19.3903035 },
    { lat: 49.341229, lng: 19.3903036 },
    { lat: 49.3409591, lng: 19.3897588 },
    { lat: 49.3410288, lng: 19.3894404 },
    { lat: 49.3409168, lng: 19.3893063 },
    { lat: 49.3407677, lng: 19.3889586 },
    { lat: 49.340755, lng: 19.3886153 },
    { lat: 49.3408741, lng: 19.388582 },
    { lat: 49.3409212, lng: 19.3883615 },
    { lat: 49.3408667, lng: 19.3882291 },
    { lat: 49.3410282, lng: 19.3879007 },
    { lat: 49.3411381, lng: 19.3877452 },
    { lat: 49.3410286, lng: 19.3876202 },
    { lat: 49.3412845, lng: 19.3872577 },
    { lat: 49.3412804, lng: 19.3871186 },
    { lat: 49.3414305, lng: 19.3869093 },
    { lat: 49.3413806, lng: 19.3867807 },
    { lat: 49.3415585, lng: 19.3864371 },
    { lat: 49.3416924, lng: 19.3862404 },
    { lat: 49.3418201, lng: 19.3860053 },
    { lat: 49.3421449, lng: 19.3859128 },
    { lat: 49.3423006, lng: 19.3858243 },
    { lat: 49.3425436, lng: 19.3851137 },
    { lat: 49.3426677, lng: 19.3845595 },
    { lat: 49.3427476, lng: 19.3843889 },
    { lat: 49.3427895, lng: 19.3842683 },
    { lat: 49.3427971, lng: 19.38424 },
    { lat: 49.3428209, lng: 19.3841471 },
    { lat: 49.3429844, lng: 19.383783 },
    { lat: 49.3431159, lng: 19.3836559 },
    { lat: 49.3433254, lng: 19.3833631 },
    { lat: 49.3435911, lng: 19.3829485 },
    { lat: 49.3435377, lng: 19.3825991 },
    { lat: 49.3435396, lng: 19.3822299 },
    { lat: 49.3437054, lng: 19.3819914 },
    { lat: 49.3437682, lng: 19.3815256 },
    { lat: 49.3437137, lng: 19.381297 },
    { lat: 49.3437784, lng: 19.3812632 },
    { lat: 49.3438018, lng: 19.3812537 },
    { lat: 49.3439981, lng: 19.381164 },
    { lat: 49.3442072, lng: 19.381177 },
    { lat: 49.3444743, lng: 19.3810806 },
    { lat: 49.3448314, lng: 19.3810525 },
    { lat: 49.3449222, lng: 19.3807755 },
    { lat: 49.3449157, lng: 19.3805196 },
    { lat: 49.3450116, lng: 19.3798922 },
    { lat: 49.3451988, lng: 19.3795563 },
    { lat: 49.345286, lng: 19.3795185 },
    { lat: 49.3452555, lng: 19.3792993 },
    { lat: 49.344959, lng: 19.3791737 },
    { lat: 49.3446307, lng: 19.3793353 },
    { lat: 49.3447013, lng: 19.3791118 },
    { lat: 49.3447048, lng: 19.3790648 },
    { lat: 49.3446379, lng: 19.3790082 },
    { lat: 49.3445922, lng: 19.3789691 },
    { lat: 49.3444855, lng: 19.3788784 },
    { lat: 49.3445422, lng: 19.3784538 },
    { lat: 49.3445492, lng: 19.3784057 },
    { lat: 49.3445265, lng: 19.3782838 },
    { lat: 49.3444581, lng: 19.3779064 },
    { lat: 49.3444648, lng: 19.3775385 },
    { lat: 49.3447872, lng: 19.3771339 },
    { lat: 49.3450197, lng: 19.3766061 },
    { lat: 49.3449575, lng: 19.3762649 },
    { lat: 49.345077, lng: 19.376048 },
    { lat: 49.3451626, lng: 19.3757119 },
    { lat: 49.3452978, lng: 19.3755158 },
    { lat: 49.3454122, lng: 19.3753113 },
    { lat: 49.3454527, lng: 19.3752386 },
    { lat: 49.3455953, lng: 19.3745754 },
    { lat: 49.3458805, lng: 19.3739266 },
    { lat: 49.3459763, lng: 19.3732514 },
    { lat: 49.3460727, lng: 19.3729229 },
    { lat: 49.3461397, lng: 19.3724258 },
    { lat: 49.3461709, lng: 19.3721546 },
    { lat: 49.3460859, lng: 19.3717851 },
    { lat: 49.3460266, lng: 19.3714761 },
    { lat: 49.3461028, lng: 19.3706936 },
    { lat: 49.3461964, lng: 19.3702609 },
    { lat: 49.3462149, lng: 19.3702227 },
    { lat: 49.3463149, lng: 19.3700238 },
    { lat: 49.3464156, lng: 19.3696716 },
    { lat: 49.3463206, lng: 19.3693264 },
    { lat: 49.3464942, lng: 19.3686738 },
    { lat: 49.3466473, lng: 19.3681648 },
    { lat: 49.3467737, lng: 19.3675203 },
    { lat: 49.346744, lng: 19.3674614 },
    { lat: 49.346672, lng: 19.3672929 },
    { lat: 49.3466796, lng: 19.3667348 },
    { lat: 49.3466682, lng: 19.3663229 },
    { lat: 49.3466683, lng: 19.3662745 },
    { lat: 49.3468132, lng: 19.3659738 },
    { lat: 49.3469819, lng: 19.3657544 },
    { lat: 49.3474568, lng: 19.3654718 },
    { lat: 49.3479232, lng: 19.364867 },
    { lat: 49.3479807, lng: 19.3648181 },
    { lat: 49.348283, lng: 19.3646336 },
    { lat: 49.3486904, lng: 19.3642615 },
    { lat: 49.3490611, lng: 19.364261 },
    { lat: 49.3495093, lng: 19.3642307 },
    { lat: 49.3500744, lng: 19.3640232 },
    { lat: 49.3505696, lng: 19.3637019 },
    { lat: 49.3511569, lng: 19.3632685 },
    { lat: 49.3519292, lng: 19.3626996 },
    { lat: 49.3522724, lng: 19.3624241 },
    { lat: 49.352648, lng: 19.3622612 },
    { lat: 49.3530545, lng: 19.3621125 },
    { lat: 49.3533401, lng: 19.3616909 },
    { lat: 49.3533636, lng: 19.3613236 },
    { lat: 49.353382, lng: 19.3610204 },
    { lat: 49.3529015, lng: 19.360935 },
    { lat: 49.3525134, lng: 19.36071 },
    { lat: 49.3520192, lng: 19.3612959 },
    { lat: 49.3515884, lng: 19.3615318 },
    { lat: 49.3513252, lng: 19.3616391 },
    { lat: 49.3509283, lng: 19.3613424 },
    { lat: 49.3505147, lng: 19.3609814 },
    { lat: 49.3501071, lng: 19.3605067 },
    { lat: 49.349933, lng: 19.3605655 },
    { lat: 49.3494986, lng: 19.3606026 },
    { lat: 49.3490898, lng: 19.3604642 },
    { lat: 49.3487303, lng: 19.3604367 },
    { lat: 49.3484043, lng: 19.3600456 },
    { lat: 49.3482412, lng: 19.3597898 },
    { lat: 49.3481464, lng: 19.3596394 },
    { lat: 49.3478262, lng: 19.3597309 },
    { lat: 49.3475619, lng: 19.3598668 },
    { lat: 49.3473119, lng: 19.360085 },
    { lat: 49.3469791, lng: 19.3601985 },
    { lat: 49.3468108, lng: 19.360235 },
    { lat: 49.3464774, lng: 19.3599014 },
    { lat: 49.3462769, lng: 19.359978 },
    { lat: 49.3461033, lng: 19.3596388 },
    { lat: 49.345702, lng: 19.3593434 },
    { lat: 49.3454599, lng: 19.3594224 },
    { lat: 49.3452033, lng: 19.3593594 },
    { lat: 49.3450946, lng: 19.3595134 },
    { lat: 49.3447414, lng: 19.3594783 },
    { lat: 49.3444631, lng: 19.3595764 },
    { lat: 49.3443068, lng: 19.3595574 },
    { lat: 49.3442116, lng: 19.3595458 },
    { lat: 49.3439463, lng: 19.3594888 },
    { lat: 49.3436831, lng: 19.3591877 },
    { lat: 49.3434547, lng: 19.3587632 },
    { lat: 49.3431847, lng: 19.358285 },
    { lat: 49.3431157, lng: 19.3581646 },
    { lat: 49.3429562, lng: 19.3580762 },
    { lat: 49.3428143, lng: 19.3581514 },
    { lat: 49.342528, lng: 19.3579826 },
    { lat: 49.3423853, lng: 19.3580358 },
    { lat: 49.3423245, lng: 19.3581863 },
    { lat: 49.3422458, lng: 19.3582094 },
    { lat: 49.3422346, lng: 19.3582128 },
    { lat: 49.3420835, lng: 19.3582582 },
    { lat: 49.3419299, lng: 19.3583459 },
    { lat: 49.3419069, lng: 19.3583589 },
    { lat: 49.3418506, lng: 19.3584128 },
    { lat: 49.3418497, lng: 19.3584132 },
    { lat: 49.3416323, lng: 19.3584923 },
    { lat: 49.3415242, lng: 19.3583954 },
    { lat: 49.3414015, lng: 19.358252 },
    { lat: 49.341303, lng: 19.3582205 },
    { lat: 49.3412172, lng: 19.3581938 },
    { lat: 49.3411944, lng: 19.3581367 },
    { lat: 49.3411343, lng: 19.3579857 },
    { lat: 49.3413776, lng: 19.3576204 },
    { lat: 49.3413958, lng: 19.3573622 },
    { lat: 49.3412885, lng: 19.3572601 },
    { lat: 49.3413361, lng: 19.3570269 },
    { lat: 49.3413946, lng: 19.3570006 },
    { lat: 49.3414467, lng: 19.3569772 },
    { lat: 49.3415773, lng: 19.3567861 },
    { lat: 49.3415784, lng: 19.3567835 },
    { lat: 49.3416879, lng: 19.3567632 },
    { lat: 49.3417153, lng: 19.3567735 },
    { lat: 49.341727, lng: 19.3567553 },
    { lat: 49.3417365, lng: 19.3567408 },
    { lat: 49.3417613, lng: 19.3566008 },
    { lat: 49.3417646, lng: 19.3565759 },
    { lat: 49.3417801, lng: 19.3564928 },
    { lat: 49.3417699, lng: 19.3563509 },
    { lat: 49.341769, lng: 19.3563314 },
    { lat: 49.3417505, lng: 19.356057 },
    { lat: 49.3417499, lng: 19.3560448 },
    { lat: 49.3416748, lng: 19.3559085 },
    { lat: 49.3415406, lng: 19.3556637 },
    { lat: 49.3414285, lng: 19.3554595 },
    { lat: 49.341324, lng: 19.3552234 },
    { lat: 49.3413173, lng: 19.3552025 },
    { lat: 49.3412819, lng: 19.3550965 },
    { lat: 49.3412567, lng: 19.3550179 },
    { lat: 49.3412224, lng: 19.3549108 },
    { lat: 49.3410947, lng: 19.3545186 },
    { lat: 49.3410061, lng: 19.3543306 },
    { lat: 49.3405268, lng: 19.3533117 },
    { lat: 49.3403201, lng: 19.3528718 },
    { lat: 49.3403064, lng: 19.3528426 },
    { lat: 49.3402793, lng: 19.3527227 },
    { lat: 49.3402711, lng: 19.3526892 },
    { lat: 49.3401778, lng: 19.3523338 },
    { lat: 49.340167, lng: 19.3523212 },
    { lat: 49.3401528, lng: 19.3523061 },
    { lat: 49.340116, lng: 19.352265 },
    { lat: 49.3399306, lng: 19.3520573 },
    { lat: 49.3399213, lng: 19.3515716 },
    { lat: 49.3399172, lng: 19.3513895 },
    { lat: 49.339925, lng: 19.3513634 },
    { lat: 49.340056, lng: 19.3508897 },
    { lat: 49.3401071, lng: 19.3507301 },
    { lat: 49.3403995, lng: 19.3498036 },
    { lat: 49.3404625, lng: 19.3493898 },
    { lat: 49.3404185, lng: 19.3484066 },
    { lat: 49.3402257, lng: 19.3471047 },
    { lat: 49.3401457, lng: 19.3466018 },
    { lat: 49.3399499, lng: 19.3448283 },
    { lat: 49.3400764, lng: 19.3444245 },
    { lat: 49.3401831, lng: 19.3438201 },
    { lat: 49.3402774, lng: 19.3432839 },
    { lat: 49.3402422, lng: 19.342986 },
    { lat: 49.3402574, lng: 19.3429266 },
    { lat: 49.34039, lng: 19.3424169 },
    { lat: 49.3405906, lng: 19.3421267 },
    { lat: 49.3405617, lng: 19.3419456 },
    { lat: 49.3406112, lng: 19.3417977 },
    { lat: 49.3409439, lng: 19.3414684 },
    { lat: 49.3413559, lng: 19.3405721 },
    { lat: 49.3415053, lng: 19.3402485 },
    { lat: 49.3423074, lng: 19.3394985 },
    { lat: 49.3428284, lng: 19.3392688 },
    { lat: 49.3431637, lng: 19.3390166 },
    { lat: 49.3433345, lng: 19.3386917 },
    { lat: 49.3435666, lng: 19.3385516 },
    { lat: 49.343733, lng: 19.3383732 },
    { lat: 49.3439637, lng: 19.3381262 },
    { lat: 49.3439888, lng: 19.3381589 },
    { lat: 49.3442335, lng: 19.337587 },
    { lat: 49.3444225, lng: 19.3371469 },
    { lat: 49.3447957, lng: 19.3365995 },
    { lat: 49.3448599, lng: 19.3365043 },
    { lat: 49.3451218, lng: 19.3363439 },
    { lat: 49.3455917, lng: 19.3358664 },
    { lat: 49.3459853, lng: 19.3354169 },
    { lat: 49.3460207, lng: 19.3353761 },
    { lat: 49.3464582, lng: 19.3349745 },
    { lat: 49.3468618, lng: 19.3344764 },
    { lat: 49.3471507, lng: 19.3338356 },
    { lat: 49.3471116, lng: 19.333772 },
    { lat: 49.3470771, lng: 19.3337129 },
    { lat: 49.3471537, lng: 19.3333278 },
    { lat: 49.3472111, lng: 19.3329416 },
    { lat: 49.3472852, lng: 19.3325525 },
    { lat: 49.3474307, lng: 19.3320265 },
    { lat: 49.3474172, lng: 19.331981 },
    { lat: 49.3474118, lng: 19.3319614 },
    { lat: 49.3474076, lng: 19.3314403 },
    { lat: 49.3474405, lng: 19.3306281 },
    { lat: 49.3475183, lng: 19.329863 },
    { lat: 49.3473291, lng: 19.3289073 },
    { lat: 49.34731, lng: 19.3288118 },
    { lat: 49.3471567, lng: 19.3285504 },
    { lat: 49.3470845, lng: 19.3284503 },
    { lat: 49.3469208, lng: 19.3282984 },
    { lat: 49.346903, lng: 19.3282816 },
    { lat: 49.3467993, lng: 19.328186 },
    { lat: 49.3463765, lng: 19.3291377 },
    { lat: 49.3463559, lng: 19.3291826 },
    { lat: 49.3460594, lng: 19.3306895 },
    { lat: 49.3457939, lng: 19.3318735 },
    { lat: 49.3456625, lng: 19.3327692 },
    { lat: 49.345402, lng: 19.3334817 },
    { lat: 49.345134, lng: 19.3339648 },
    { lat: 49.3445814, lng: 19.3341749 },
    { lat: 49.3442561, lng: 19.3343993 },
    { lat: 49.3438948, lng: 19.334549 },
    { lat: 49.3436779, lng: 19.3346563 },
    { lat: 49.3434037, lng: 19.3346757 },
    { lat: 49.3430067, lng: 19.3349565 },
    { lat: 49.3426468, lng: 19.3351377 },
    { lat: 49.3423731, lng: 19.3355491 },
    { lat: 49.3421595, lng: 19.3359677 },
    { lat: 49.3418977, lng: 19.3364367 },
    { lat: 49.3415116, lng: 19.3370238 },
    { lat: 49.340997, lng: 19.3373207 },
    { lat: 49.3408114, lng: 19.337305 },
    { lat: 49.3405447, lng: 19.3372888 },
    { lat: 49.3403653, lng: 19.3375698 },
    { lat: 49.3402429, lng: 19.3377459 },
    { lat: 49.3401003, lng: 19.3380221 },
    { lat: 49.3398537, lng: 19.3381219 },
    { lat: 49.3397017, lng: 19.3383395 },
    { lat: 49.3392935, lng: 19.3385774 },
    { lat: 49.3390838, lng: 19.3388571 },
    { lat: 49.3389429, lng: 19.3388093 },
    { lat: 49.3389007, lng: 19.3391385 },
    { lat: 49.3386926, lng: 19.3390519 },
    { lat: 49.3381581, lng: 19.3392534 },
    { lat: 49.3376187, lng: 19.3389054 },
    { lat: 49.3374545, lng: 19.3390297 },
    { lat: 49.3372667, lng: 19.338994 },
    { lat: 49.337293, lng: 19.3392155 },
    { lat: 49.3370396, lng: 19.3397934 },
    { lat: 49.336853, lng: 19.339948 },
    { lat: 49.3366502, lng: 19.3400697 },
    { lat: 49.3366576, lng: 19.3406868 },
    { lat: 49.3367716, lng: 19.3411418 },
    { lat: 49.3368715, lng: 19.3416617 },
    { lat: 49.3368583, lng: 19.3420093 },
    { lat: 49.3366196, lng: 19.3423011 },
    { lat: 49.3365767, lng: 19.342514 },
    { lat: 49.3367744, lng: 19.3430022 },
    { lat: 49.3367796, lng: 19.3434974 },
    { lat: 49.3367823, lng: 19.3438205 },
    { lat: 49.3367593, lng: 19.3441013 },
    { lat: 49.3369253, lng: 19.3440097 },
    { lat: 49.3368207, lng: 19.3442575 },
    { lat: 49.3367999, lng: 19.3446041 },
    { lat: 49.3368671, lng: 19.3448833 },
    { lat: 49.3367323, lng: 19.3451558 },
    { lat: 49.3365537, lng: 19.3451187 },
    { lat: 49.3365298, lng: 19.3453551 },
    { lat: 49.3363806, lng: 19.345683 },
    { lat: 49.3364434, lng: 19.3457722 },
    { lat: 49.336548, lng: 19.3461499 },
    { lat: 49.3364716, lng: 19.3464188 },
    { lat: 49.3365197, lng: 19.3468012 },
    { lat: 49.3365424, lng: 19.347043 },
    { lat: 49.3363825, lng: 19.3471871 },
    { lat: 49.3363635, lng: 19.3475534 },
    { lat: 49.3362792, lng: 19.3478196 },
    { lat: 49.3363461, lng: 19.3478773 },
    { lat: 49.3363212, lng: 19.3480209 },
    { lat: 49.3361109, lng: 19.3479071 },
    { lat: 49.3360282, lng: 19.3481178 },
    { lat: 49.3361569, lng: 19.3483602 },
    { lat: 49.3362502, lng: 19.3485975 },
    { lat: 49.3362234, lng: 19.3488212 },
    { lat: 49.3361023, lng: 19.3488768 },
    { lat: 49.3361248, lng: 19.3490944 },
    { lat: 49.3360054, lng: 19.3491671 },
    { lat: 49.3360369, lng: 19.3494061 },
    { lat: 49.3360056, lng: 19.3497254 },
    { lat: 49.3360197, lng: 19.3500512 },
    { lat: 49.3360215, lng: 19.350352 },
    { lat: 49.3360218, lng: 19.3504095 },
    { lat: 49.3360419, lng: 19.350958 },
    { lat: 49.3360939, lng: 19.3511756 },
    { lat: 49.3361335, lng: 19.3513457 },
    { lat: 49.3362243, lng: 19.3516256 },
    { lat: 49.336204, lng: 19.3520101 },
    { lat: 49.3360713, lng: 19.3522111 },
    { lat: 49.3360972, lng: 19.3526875 },
    { lat: 49.3360878, lng: 19.3529725 },
    { lat: 49.3359939, lng: 19.353059 },
    { lat: 49.3359436, lng: 19.3529945 },
    { lat: 49.3359339, lng: 19.3529825 },
    { lat: 49.335892, lng: 19.3530966 },
    { lat: 49.3358272, lng: 19.3532771 },
    { lat: 49.3358469, lng: 19.3533144 },
    { lat: 49.335872, lng: 19.3533647 },
    { lat: 49.3360406, lng: 19.3536939 },
    { lat: 49.3358601, lng: 19.3539536 },
    { lat: 49.3355777, lng: 19.3542303 },
    { lat: 49.3355423, lng: 19.354241 },
    { lat: 49.3354955, lng: 19.3542559 },
    { lat: 49.3353167, lng: 19.3543106 },
    { lat: 49.3352683, lng: 19.3545269 },
    { lat: 49.3352243, lng: 19.3546018 },
    { lat: 49.3351545, lng: 19.3547201 },
    { lat: 49.3350804, lng: 19.3548467 },
    { lat: 49.3350583, lng: 19.3548827 },
    { lat: 49.3350515, lng: 19.3548799 },
    { lat: 49.3348908, lng: 19.3548169 },
    { lat: 49.3346123, lng: 19.3551018 },
    { lat: 49.3346592, lng: 19.3553113 },
    { lat: 49.3345525, lng: 19.3553884 },
    { lat: 49.3346206, lng: 19.3555215 },
    { lat: 49.334465, lng: 19.3556379 },
    { lat: 49.3344384, lng: 19.3559368 },
    { lat: 49.3343173, lng: 19.3559967 },
    { lat: 49.3343995, lng: 19.3562355 },
    { lat: 49.33445, lng: 19.3562795 },
    { lat: 49.3344746, lng: 19.3563007 },
    { lat: 49.3345208, lng: 19.3563245 },
    { lat: 49.3345397, lng: 19.3563456 },
    { lat: 49.3346435, lng: 19.3564679 },
    { lat: 49.3344619, lng: 19.3567259 },
    { lat: 49.3343432, lng: 19.3571313 },
    { lat: 49.3343867, lng: 19.3571229 },
    { lat: 49.3343857, lng: 19.357509 },
    { lat: 49.334105, lng: 19.3575318 },
    { lat: 49.334146, lng: 19.3583099 },
    { lat: 49.3341162, lng: 19.3587782 },
    { lat: 49.3342378, lng: 19.3589214 },
    { lat: 49.3342288, lng: 19.3593531 },
    { lat: 49.3340089, lng: 19.3594989 },
    { lat: 49.3339783, lng: 19.3597588 },
    { lat: 49.3341283, lng: 19.359931 },
    { lat: 49.333968, lng: 19.3604324 },
    { lat: 49.3340352, lng: 19.3606361 },
    { lat: 49.3338441, lng: 19.3609587 },
    { lat: 49.3336945, lng: 19.3611575 },
    { lat: 49.3336235, lng: 19.3610631 },
    { lat: 49.3334626, lng: 19.3612368 },
    { lat: 49.3335559, lng: 19.3613028 },
    { lat: 49.333573, lng: 19.3617652 },
    { lat: 49.333537, lng: 19.3621768 },
    { lat: 49.333403, lng: 19.3625632 },
    { lat: 49.3335662, lng: 19.3626798 },
    { lat: 49.3335225, lng: 19.3629975 },
    { lat: 49.3333879, lng: 19.3629869 },
    { lat: 49.3334748, lng: 19.3631342 },
    { lat: 49.3336122, lng: 19.3631597 },
    { lat: 49.33367, lng: 19.3634312 },
    { lat: 49.3335578, lng: 19.3637275 },
    { lat: 49.3333248, lng: 19.363543 },
    { lat: 49.333019, lng: 19.3637236 },
    { lat: 49.3330453, lng: 19.363974 },
    { lat: 49.3332154, lng: 19.3643839 },
    { lat: 49.3331069, lng: 19.364644 },
    { lat: 49.332929, lng: 19.3648402 },
    { lat: 49.3328214, lng: 19.3649983 },
    { lat: 49.332918, lng: 19.3651084 },
    { lat: 49.3326899, lng: 19.3652663 },
    { lat: 49.3325812, lng: 19.3652078 },
    { lat: 49.3322401, lng: 19.3645893 },
    { lat: 49.3321202, lng: 19.3642176 },
    { lat: 49.3319684, lng: 19.3642989 },
    { lat: 49.3319292, lng: 19.3643245 },
    { lat: 49.3316963, lng: 19.3644797 },
    { lat: 49.331438, lng: 19.3646655 },
    { lat: 49.3314313, lng: 19.3646695 },
    { lat: 49.3314072, lng: 19.3646848 },
    { lat: 49.3311948, lng: 19.3648142 },
    { lat: 49.3308782, lng: 19.3650068 },
    { lat: 49.3306576, lng: 19.3651574 },
    { lat: 49.3304939, lng: 19.365176 },
    { lat: 49.3301331, lng: 19.3650291 },
    { lat: 49.33009, lng: 19.3650208 },
    { lat: 49.3300123, lng: 19.3649762 },
    { lat: 49.3297379, lng: 19.364845 },
    { lat: 49.3291122, lng: 19.3645464 },
    { lat: 49.3287375, lng: 19.3643859 },
    { lat: 49.3284856, lng: 19.3643776 },
    { lat: 49.3283597, lng: 19.3644066 },
    { lat: 49.3283263, lng: 19.3644142 },
    { lat: 49.328254, lng: 19.3644348 },
    { lat: 49.3282274, lng: 19.364446 },
    { lat: 49.3281369, lng: 19.3644845 },
    { lat: 49.3280306, lng: 19.3644795 },
    { lat: 49.327705, lng: 19.3641668 },
    { lat: 49.3272173, lng: 19.363615 },
    { lat: 49.327101, lng: 19.3635411 },
    { lat: 49.3263811, lng: 19.3634464 },
    { lat: 49.3258797, lng: 19.3634237 },
    { lat: 49.3252992, lng: 19.3633976 },
    { lat: 49.3249983, lng: 19.3634659 },
    { lat: 49.3246574, lng: 19.3636441 },
    { lat: 49.3246375, lng: 19.3636546 },
    { lat: 49.3246176, lng: 19.3636622 },
    { lat: 49.3239802, lng: 19.363913 },
    { lat: 49.3234769, lng: 19.3640938 },
    { lat: 49.323099, lng: 19.364323 },
    { lat: 49.3226994, lng: 19.3642612 },
    { lat: 49.3223863, lng: 19.3641213 },
    { lat: 49.3216227, lng: 19.3645163 },
    { lat: 49.3213844, lng: 19.3646453 },
    { lat: 49.3210004, lng: 19.3651342 },
    { lat: 49.3205234, lng: 19.3653659 },
    { lat: 49.3201887, lng: 19.3652226 },
    { lat: 49.3198454, lng: 19.3653292 },
    { lat: 49.3197152, lng: 19.3653391 },
    { lat: 49.3191687, lng: 19.3657503 },
    { lat: 49.3187309, lng: 19.3660486 },
    { lat: 49.3184801, lng: 19.366396 },
    { lat: 49.3174511, lng: 19.3669731 },
    { lat: 49.3171541, lng: 19.3671511 },
    { lat: 49.3169101, lng: 19.3672616 },
    { lat: 49.3162254, lng: 19.3669803 },
    { lat: 49.3157087, lng: 19.3667009 },
    { lat: 49.3152574, lng: 19.3663266 },
    { lat: 49.3146659, lng: 19.3663068 },
    { lat: 49.3140061, lng: 19.366367 },
    { lat: 49.3136024, lng: 19.3661908 },
    { lat: 49.3131275, lng: 19.3661348 },
    { lat: 49.3129038, lng: 19.3661242 },
    { lat: 49.3125733, lng: 19.3661159 },
    { lat: 49.3120856, lng: 19.3660923 },
    { lat: 49.3113794, lng: 19.3663671 },
    { lat: 49.3107766, lng: 19.3664645 },
    { lat: 49.3105079, lng: 19.3665516 },
    { lat: 49.3101286, lng: 19.3667304 },
    { lat: 49.3096138, lng: 19.3670478 },
    { lat: 49.3088837, lng: 19.3671453 },
    { lat: 49.3084692, lng: 19.3670021 },
    { lat: 49.3079251, lng: 19.3667441 },
    { lat: 49.3072854, lng: 19.3664345 },
    { lat: 49.3065623, lng: 19.3660849 },
    { lat: 49.3057014, lng: 19.3656013 },
    { lat: 49.3051573, lng: 19.3651462 },
    { lat: 49.3043943, lng: 19.3646146 },
    { lat: 49.3037029, lng: 19.3640497 },
    { lat: 49.303075, lng: 19.363637 },
    { lat: 49.303224, lng: 19.364209 },
    { lat: 49.303613, lng: 19.364968 },
    { lat: 49.304, lng: 19.365642 },
    { lat: 49.303682, lng: 19.365979 },
    { lat: 49.303516, lng: 19.366155 },
    { lat: 49.303453, lng: 19.36628 },
    { lat: 49.303045, lng: 19.367081 },
    { lat: 49.302974, lng: 19.367206 },
    { lat: 49.3026, lng: 19.367862 },
    { lat: 49.302299, lng: 19.368567 },
    { lat: 49.302175, lng: 19.368857 },
    { lat: 49.301749, lng: 19.369865 },
    { lat: 49.301277, lng: 19.370855 },
    { lat: 49.300847, lng: 19.371589 },
    { lat: 49.300527, lng: 19.372161 },
    { lat: 49.300384, lng: 19.372417 },
    { lat: 49.299978, lng: 19.373366 },
    { lat: 49.299685, lng: 19.374081 },
    { lat: 49.299358, lng: 19.374787 },
    { lat: 49.299304, lng: 19.374905 },
    { lat: 49.298999, lng: 19.375608 },
    { lat: 49.298906, lng: 19.375835 },
    { lat: 49.298861, lng: 19.375944 },
    { lat: 49.298899, lng: 19.376463 },
    { lat: 49.299044, lng: 19.376868 },
    { lat: 49.299134, lng: 19.377184 },
    { lat: 49.299046, lng: 19.377426 },
    { lat: 49.298863, lng: 19.377842 },
    { lat: 49.298668, lng: 19.378277 },
    { lat: 49.298666, lng: 19.378297 },
    { lat: 49.298634, lng: 19.378621 },
    { lat: 49.298594, lng: 19.379028 },
    { lat: 49.29859, lng: 19.379118 },
    { lat: 49.298572, lng: 19.379523 },
    { lat: 49.298556, lng: 19.379597 },
    { lat: 49.298437, lng: 19.380137 },
    { lat: 49.298297, lng: 19.380462 },
    { lat: 49.298217, lng: 19.380638 },
    { lat: 49.297183, lng: 19.380303 },
    { lat: 49.295773, lng: 19.379657 },
  ],
  Beňadovo: [
    { lat: 49.4287252, lng: 19.3211309 },
    { lat: 49.4281009, lng: 19.3225669 },
    { lat: 49.4277745, lng: 19.3230077 },
    { lat: 49.4273301, lng: 19.3241038 },
    { lat: 49.4269721, lng: 19.3247099 },
    { lat: 49.4262695, lng: 19.3252508 },
    { lat: 49.4262313, lng: 19.3252809 },
    { lat: 49.4258849, lng: 19.3255532 },
    { lat: 49.425788, lng: 19.3259846 },
    { lat: 49.4258161, lng: 19.3264152 },
    { lat: 49.4258124, lng: 19.3265539 },
    { lat: 49.4257245, lng: 19.3268177 },
    { lat: 49.425642, lng: 19.3268811 },
    { lat: 49.4252775, lng: 19.3270654 },
    { lat: 49.4251794, lng: 19.3270629 },
    { lat: 49.4251003, lng: 19.3269366 },
    { lat: 49.4249432, lng: 19.3267606 },
    { lat: 49.4248448, lng: 19.3267067 },
    { lat: 49.4242431, lng: 19.3265852 },
    { lat: 49.4236871, lng: 19.3266477 },
    { lat: 49.422848, lng: 19.3266093 },
    { lat: 49.422499, lng: 19.3258703 },
    { lat: 49.4221694, lng: 19.3252645 },
    { lat: 49.422056, lng: 19.325247 },
    { lat: 49.4219359, lng: 19.3254015 },
    { lat: 49.421775, lng: 19.3262666 },
    { lat: 49.4217405, lng: 19.3266838 },
    { lat: 49.4213782, lng: 19.3272715 },
    { lat: 49.4211964, lng: 19.3274313 },
    { lat: 49.4211155, lng: 19.327438 },
    { lat: 49.4209799, lng: 19.3272619 },
    { lat: 49.4209155, lng: 19.3267803 },
    { lat: 49.4206704, lng: 19.3263339 },
    { lat: 49.4206271, lng: 19.3263731 },
    { lat: 49.4207499, lng: 19.3267088 },
    { lat: 49.420957, lng: 19.3276637 },
    { lat: 49.4209662, lng: 19.3277141 },
    { lat: 49.4216378, lng: 19.3307877 },
    { lat: 49.4216932, lng: 19.3311276 },
    { lat: 49.421696, lng: 19.3311606 },
    { lat: 49.4217084, lng: 19.3312541 },
    { lat: 49.4217121, lng: 19.3312884 },
    { lat: 49.4219919, lng: 19.33331 },
    { lat: 49.4220039, lng: 19.3341188 },
    { lat: 49.4220637, lng: 19.3344315 },
    { lat: 49.422158, lng: 19.3354545 },
    { lat: 49.4222318, lng: 19.3358757 },
    { lat: 49.4220778, lng: 19.3376179 },
    { lat: 49.4220688, lng: 19.3387956 },
    { lat: 49.4220592, lng: 19.3392155 },
    { lat: 49.4221582, lng: 19.3399825 },
    { lat: 49.4221654, lng: 19.340251 },
    { lat: 49.4221753, lng: 19.3403223 },
    { lat: 49.4221902, lng: 19.3406142 },
    { lat: 49.4223228, lng: 19.3417855 },
    { lat: 49.422334, lng: 19.3421907 },
    { lat: 49.4220912, lng: 19.343838 },
    { lat: 49.4232768, lng: 19.3473321 },
    { lat: 49.4236685, lng: 19.3491087 },
    { lat: 49.4240123, lng: 19.3508906 },
    { lat: 49.4240766, lng: 19.3526982 },
    { lat: 49.4244021, lng: 19.3531569 },
    { lat: 49.4244258, lng: 19.3532035 },
    { lat: 49.4245237, lng: 19.3533922 },
    { lat: 49.4248158, lng: 19.3537373 },
    { lat: 49.424874, lng: 19.3538004 },
    { lat: 49.4259224, lng: 19.3549921 },
    { lat: 49.4268959, lng: 19.3574018 },
    { lat: 49.4277434, lng: 19.3592361 },
    { lat: 49.4283705, lng: 19.3603076 },
    { lat: 49.428752, lng: 19.3612432 },
    { lat: 49.4292893, lng: 19.3618663 },
    { lat: 49.4289785, lng: 19.3626407 },
    { lat: 49.4289161, lng: 19.3635044 },
    { lat: 49.4289173, lng: 19.3637518 },
    { lat: 49.4289222, lng: 19.3647426 },
    { lat: 49.4289184, lng: 19.3648324 },
    { lat: 49.4288695, lng: 19.3657142 },
    { lat: 49.428328, lng: 19.3676365 },
    { lat: 49.4276219, lng: 19.3697715 },
    { lat: 49.4273724, lng: 19.3710206 },
    { lat: 49.426806, lng: 19.3719247 },
    { lat: 49.4264523, lng: 19.3726237 },
    { lat: 49.426449, lng: 19.3726295 },
    { lat: 49.4259665, lng: 19.3735648 },
    { lat: 49.4256375, lng: 19.3740251 },
    { lat: 49.4254251, lng: 19.3750058 },
    { lat: 49.4251761, lng: 19.3751044 },
    { lat: 49.4249122, lng: 19.3753193 },
    { lat: 49.4245852, lng: 19.3755531 },
    { lat: 49.4239555, lng: 19.3761168 },
    { lat: 49.423598, lng: 19.3764709 },
    { lat: 49.4233414, lng: 19.3767462 },
    { lat: 49.4230587, lng: 19.3770149 },
    { lat: 49.4227962, lng: 19.3772337 },
    { lat: 49.4228622, lng: 19.3778501 },
    { lat: 49.4230183, lng: 19.3785924 },
    { lat: 49.4230285, lng: 19.3786384 },
    { lat: 49.4232613, lng: 19.3797434 },
    { lat: 49.423419, lng: 19.3808804 },
    { lat: 49.424008, lng: 19.3827157 },
    { lat: 49.4242222, lng: 19.382575 },
    { lat: 49.4244339, lng: 19.3832476 },
    { lat: 49.4250169, lng: 19.3843252 },
    { lat: 49.4252647, lng: 19.3845906 },
    { lat: 49.4254243, lng: 19.3848961 },
    { lat: 49.425557, lng: 19.385232 },
    { lat: 49.4254529, lng: 19.3854413 },
    { lat: 49.425129, lng: 19.3856443 },
    { lat: 49.4248935, lng: 19.3858872 },
    { lat: 49.4248075, lng: 19.3859057 },
    { lat: 49.4243259, lng: 19.386503 },
    { lat: 49.4238411, lng: 19.3869075 },
    { lat: 49.4237439, lng: 19.3872406 },
    { lat: 49.4238931, lng: 19.3879808 },
    { lat: 49.4238585, lng: 19.3882286 },
    { lat: 49.4239057, lng: 19.3895128 },
    { lat: 49.4238007, lng: 19.39036 },
    { lat: 49.4238338, lng: 19.3908747 },
    { lat: 49.4239065, lng: 19.3908172 },
    { lat: 49.4242519, lng: 19.3907046 },
    { lat: 49.4245958, lng: 19.3903717 },
    { lat: 49.4249215, lng: 19.3899865 },
    { lat: 49.4252773, lng: 19.3899049 },
    { lat: 49.425784, lng: 19.3899253 },
    { lat: 49.4259792, lng: 19.3895616 },
    { lat: 49.426268, lng: 19.3890642 },
    { lat: 49.4264236, lng: 19.3884086 },
    { lat: 49.4266686, lng: 19.3877408 },
    { lat: 49.4269557, lng: 19.3871306 },
    { lat: 49.4271737, lng: 19.3869949 },
    { lat: 49.427389, lng: 19.386974 },
    { lat: 49.4278548, lng: 19.3868594 },
    { lat: 49.4281839, lng: 19.3864987 },
    { lat: 49.4277023, lng: 19.3852756 },
    { lat: 49.4274515, lng: 19.3848983 },
    { lat: 49.4271688, lng: 19.3842211 },
    { lat: 49.4269318, lng: 19.3836581 },
    { lat: 49.4269543, lng: 19.3836306 },
    { lat: 49.4268397, lng: 19.3832265 },
    { lat: 49.4271677, lng: 19.3822564 },
    { lat: 49.4270043, lng: 19.3818706 },
    { lat: 49.4270134, lng: 19.3808538 },
    { lat: 49.4271646, lng: 19.3803982 },
    { lat: 49.4273388, lng: 19.3797833 },
    { lat: 49.4275061, lng: 19.3792633 },
    { lat: 49.4271503, lng: 19.378589 },
    { lat: 49.4269372, lng: 19.3784429 },
    { lat: 49.4271317, lng: 19.3781176 },
    { lat: 49.4271954, lng: 19.3779857 },
    { lat: 49.4271356, lng: 19.3777396 },
    { lat: 49.4272528, lng: 19.377645 },
    { lat: 49.4274356, lng: 19.3774331 },
    { lat: 49.4275799, lng: 19.377337 },
    { lat: 49.4277649, lng: 19.3769547 },
    { lat: 49.427868, lng: 19.3767961 },
    { lat: 49.4278859, lng: 19.3766472 },
    { lat: 49.4279188, lng: 19.3765328 },
    { lat: 49.4279244, lng: 19.3763888 },
    { lat: 49.4280069, lng: 19.3762488 },
    { lat: 49.4280404, lng: 19.3762422 },
    { lat: 49.4280578, lng: 19.3762254 },
    { lat: 49.4280638, lng: 19.3762136 },
    { lat: 49.4281291, lng: 19.376088 },
    { lat: 49.4282287, lng: 19.3760011 },
    { lat: 49.4282796, lng: 19.3759083 },
    { lat: 49.4282869, lng: 19.3758985 },
    { lat: 49.4284007, lng: 19.3758223 },
    { lat: 49.4284295, lng: 19.3758105 },
    { lat: 49.4292681, lng: 19.3757426 },
    { lat: 49.4294259, lng: 19.3756015 },
    { lat: 49.4294387, lng: 19.3755368 },
    { lat: 49.4295439, lng: 19.3754742 },
    { lat: 49.4297289, lng: 19.375476 },
    { lat: 49.4297333, lng: 19.3754759 },
    { lat: 49.4298425, lng: 19.375427 },
    { lat: 49.4299092, lng: 19.3754795 },
    { lat: 49.4299104, lng: 19.3754808 },
    { lat: 49.4299249, lng: 19.375477 },
    { lat: 49.429931, lng: 19.3754608 },
    { lat: 49.4299348, lng: 19.375447 },
    { lat: 49.4300418, lng: 19.3754925 },
    { lat: 49.4300521, lng: 19.3754968 },
    { lat: 49.4300547, lng: 19.3755052 },
    { lat: 49.430068, lng: 19.3755479 },
    { lat: 49.4300731, lng: 19.3755618 },
    { lat: 49.430383, lng: 19.3757574 },
    { lat: 49.4306761, lng: 19.3758079 },
    { lat: 49.4309661, lng: 19.3761041 },
    { lat: 49.4311665, lng: 19.3761999 },
    { lat: 49.4312617, lng: 19.3761381 },
    { lat: 49.4312671, lng: 19.3761338 },
    { lat: 49.4312537, lng: 19.3761143 },
    { lat: 49.4312717, lng: 19.3759433 },
    { lat: 49.4315636, lng: 19.3755786 },
    { lat: 49.4317529, lng: 19.3755565 },
    { lat: 49.4322939, lng: 19.3754607 },
    { lat: 49.4327817, lng: 19.3754812 },
    { lat: 49.4332028, lng: 19.3754539 },
    { lat: 49.4336695, lng: 19.3746599 },
    { lat: 49.4344669, lng: 19.3738742 },
    { lat: 49.4349868, lng: 19.3733296 },
    { lat: 49.4355874, lng: 19.3722877 },
    { lat: 49.4357478, lng: 19.3711086 },
    { lat: 49.4359108, lng: 19.3704528 },
    { lat: 49.4359311, lng: 19.3703757 },
    { lat: 49.4360985, lng: 19.3696401 },
    { lat: 49.4358496, lng: 19.3681716 },
    { lat: 49.4357976, lng: 19.367728 },
    { lat: 49.4363801, lng: 19.3673173 },
    { lat: 49.436934, lng: 19.3668724 },
    { lat: 49.4374076, lng: 19.366298 },
    { lat: 49.4375139, lng: 19.3661622 },
    { lat: 49.4384706, lng: 19.3668655 },
    { lat: 49.4385439, lng: 19.3668165 },
    { lat: 49.4386172, lng: 19.3667668 },
    { lat: 49.4386149, lng: 19.3650772 },
    { lat: 49.4379753, lng: 19.3634188 },
    { lat: 49.4377296, lng: 19.3617045 },
    { lat: 49.4377951, lng: 19.3600415 },
    { lat: 49.4379089, lng: 19.3595116 },
    { lat: 49.4380175, lng: 19.3587722 },
    { lat: 49.4377671, lng: 19.3581889 },
    { lat: 49.4380765, lng: 19.3578613 },
    { lat: 49.4382947, lng: 19.3582859 },
    { lat: 49.4386574, lng: 19.3585246 },
    { lat: 49.4387061, lng: 19.3586771 },
    { lat: 49.4395585, lng: 19.3583988 },
    { lat: 49.439964, lng: 19.3582026 },
    { lat: 49.4400847, lng: 19.3580575 },
    { lat: 49.4407409, lng: 19.3587908 },
    { lat: 49.4405822, lng: 19.3593267 },
    { lat: 49.4408612, lng: 19.3598965 },
    { lat: 49.4416949, lng: 19.3605219 },
    { lat: 49.442361, lng: 19.3610326 },
    { lat: 49.4425329, lng: 19.3617676 },
    { lat: 49.4426796, lng: 19.3614097 },
    { lat: 49.4429256, lng: 19.3610332 },
    { lat: 49.4430418, lng: 19.3605537 },
    { lat: 49.4430886, lng: 19.3598153 },
    { lat: 49.4431781, lng: 19.3593445 },
    { lat: 49.443304, lng: 19.3587353 },
    { lat: 49.4431376, lng: 19.3578964 },
    { lat: 49.4432878, lng: 19.3573252 },
    { lat: 49.4438094, lng: 19.3547167 },
    { lat: 49.4441192, lng: 19.353778 },
    { lat: 49.4443574, lng: 19.351805 },
    { lat: 49.4450061, lng: 19.3502355 },
    { lat: 49.4454957, lng: 19.349042 },
    { lat: 49.4455229, lng: 19.3489978 },
    { lat: 49.4466668, lng: 19.3461829 },
    { lat: 49.4467552, lng: 19.3459976 },
    { lat: 49.4466433, lng: 19.3456803 },
    { lat: 49.4464666, lng: 19.3450838 },
    { lat: 49.446313, lng: 19.3444966 },
    { lat: 49.4462148, lng: 19.3440837 },
    { lat: 49.4462233, lng: 19.3437358 },
    { lat: 49.4461294, lng: 19.3435347 },
    { lat: 49.4460298, lng: 19.3432389 },
    { lat: 49.4458822, lng: 19.3429499 },
    { lat: 49.4458905, lng: 19.3424774 },
    { lat: 49.445592, lng: 19.3421184 },
    { lat: 49.4453419, lng: 19.3421185 },
    { lat: 49.4452964, lng: 19.3417711 },
    { lat: 49.4450761, lng: 19.341589 },
    { lat: 49.445175, lng: 19.3413731 },
    { lat: 49.4451488, lng: 19.3411 },
    { lat: 49.4446966, lng: 19.3409239 },
    { lat: 49.4447586, lng: 19.3407302 },
    { lat: 49.4447382, lng: 19.3404916 },
    { lat: 49.4444928, lng: 19.3401561 },
    { lat: 49.4441794, lng: 19.3400092 },
    { lat: 49.4440181, lng: 19.339646 },
    { lat: 49.4437548, lng: 19.3396758 },
    { lat: 49.4435476, lng: 19.3394415 },
    { lat: 49.4432904, lng: 19.3395557 },
    { lat: 49.4429625, lng: 19.3396291 },
    { lat: 49.4426964, lng: 19.3399112 },
    { lat: 49.4423549, lng: 19.3397442 },
    { lat: 49.4421091, lng: 19.3398604 },
    { lat: 49.4418306, lng: 19.3398588 },
    { lat: 49.441597, lng: 19.3396803 },
    { lat: 49.441289, lng: 19.339795 },
    { lat: 49.4408727, lng: 19.3399499 },
    { lat: 49.4407461, lng: 19.3390788 },
    { lat: 49.4403693, lng: 19.3364704 },
    { lat: 49.4388692, lng: 19.3346124 },
    { lat: 49.4374986, lng: 19.332206 },
    { lat: 49.4367292, lng: 19.3313933 },
    { lat: 49.4358591, lng: 19.3303335 },
    { lat: 49.4339063, lng: 19.3269041 },
    { lat: 49.4330678, lng: 19.3266375 },
    { lat: 49.4305086, lng: 19.3233529 },
    { lat: 49.4296499, lng: 19.3222498 },
    { lat: 49.4296091, lng: 19.3221967 },
    { lat: 49.4292938, lng: 19.3217924 },
    { lat: 49.4290719, lng: 19.3215071 },
    { lat: 49.4287347, lng: 19.3212377 },
    { lat: 49.4287252, lng: 19.3211309 },
  ],
  Bobrov: [
    { lat: 49.424906, lng: 19.576403 },
    { lat: 49.42715, lng: 19.579175 },
    { lat: 49.427921, lng: 19.580268 },
    { lat: 49.427993, lng: 19.58071 },
    { lat: 49.430149, lng: 19.584374 },
    { lat: 49.430257, lng: 19.58461 },
    { lat: 49.430508, lng: 19.584775 },
    { lat: 49.430977, lng: 19.585781 },
    { lat: 49.430974, lng: 19.586295 },
    { lat: 49.431032, lng: 19.58656 },
    { lat: 49.431467, lng: 19.587154 },
    { lat: 49.431939, lng: 19.587198 },
    { lat: 49.432392, lng: 19.587588 },
    { lat: 49.432556, lng: 19.587969 },
    { lat: 49.432554, lng: 19.588329 },
    { lat: 49.432749, lng: 19.588572 },
    { lat: 49.432859, lng: 19.588851 },
    { lat: 49.432928, lng: 19.589221 },
    { lat: 49.432979, lng: 19.589939 },
    { lat: 49.433198, lng: 19.590066 },
    { lat: 49.433363, lng: 19.59034 },
    { lat: 49.433382, lng: 19.590633 },
    { lat: 49.433556, lng: 19.591108 },
    { lat: 49.433602, lng: 19.591552 },
    { lat: 49.434331, lng: 19.591592 },
    { lat: 49.43454, lng: 19.591731 },
    { lat: 49.434661, lng: 19.591944 },
    { lat: 49.434619, lng: 19.592935 },
    { lat: 49.434667, lng: 19.593061 },
    { lat: 49.43515, lng: 19.593876 },
    { lat: 49.436375, lng: 19.595933 },
    { lat: 49.4364, lng: 19.596003 },
    { lat: 49.436618, lng: 19.59598 },
    { lat: 49.436654, lng: 19.596157 },
    { lat: 49.436703, lng: 19.596392 },
    { lat: 49.436761, lng: 19.596598 },
    { lat: 49.436978, lng: 19.59687 },
    { lat: 49.437082, lng: 19.596881 },
    { lat: 49.437126, lng: 19.596814 },
    { lat: 49.437123, lng: 19.596351 },
    { lat: 49.437154, lng: 19.596299 },
    { lat: 49.437219, lng: 19.596327 },
    { lat: 49.437283, lng: 19.596485 },
    { lat: 49.437346, lng: 19.59651 },
    { lat: 49.437435, lng: 19.596339 },
    { lat: 49.437487, lng: 19.596335 },
    { lat: 49.437511, lng: 19.596443 },
    { lat: 49.437468, lng: 19.596491 },
    { lat: 49.437477, lng: 19.596615 },
    { lat: 49.437543, lng: 19.596643 },
    { lat: 49.437607, lng: 19.596572 },
    { lat: 49.437713, lng: 19.596558 },
    { lat: 49.43777, lng: 19.596618 },
    { lat: 49.43786, lng: 19.596585 },
    { lat: 49.437982, lng: 19.596438 },
    { lat: 49.43811, lng: 19.596327 },
    { lat: 49.438136, lng: 19.59621 },
    { lat: 49.438167, lng: 19.596163 },
    { lat: 49.43822, lng: 19.596172 },
    { lat: 49.438271, lng: 19.596215 },
    { lat: 49.43836, lng: 19.596496 },
    { lat: 49.438316, lng: 19.596576 },
    { lat: 49.438302, lng: 19.596696 },
    { lat: 49.438369, lng: 19.596793 },
    { lat: 49.438506, lng: 19.596816 },
    { lat: 49.438601, lng: 19.596866 },
    { lat: 49.438619, lng: 19.596843 },
    { lat: 49.438647, lng: 19.596725 },
    { lat: 49.438613, lng: 19.596608 },
    { lat: 49.438656, lng: 19.59655 },
    { lat: 49.438865, lng: 19.596628 },
    { lat: 49.438889, lng: 19.596607 },
    { lat: 49.438866, lng: 19.596484 },
    { lat: 49.438733, lng: 19.596397 },
    { lat: 49.438687, lng: 19.59622 },
    { lat: 49.438687, lng: 19.596166 },
    { lat: 49.438698, lng: 19.595972 },
    { lat: 49.438881, lng: 19.595831 },
    { lat: 49.438976, lng: 19.595721 },
    { lat: 49.439036, lng: 19.595678 },
    { lat: 49.43905, lng: 19.59568 },
    { lat: 49.439207, lng: 19.595806 },
    { lat: 49.439311, lng: 19.595813 },
    { lat: 49.439427, lng: 19.595855 },
    { lat: 49.439494, lng: 19.595827 },
    { lat: 49.439809, lng: 19.595897 },
    { lat: 49.439826, lng: 19.595881 },
    { lat: 49.439832, lng: 19.595845 },
    { lat: 49.439787, lng: 19.595745 },
    { lat: 49.439672, lng: 19.595631 },
    { lat: 49.439613, lng: 19.595616 },
    { lat: 49.439529, lng: 19.595537 },
    { lat: 49.439535, lng: 19.595411 },
    { lat: 49.439658, lng: 19.595307 },
    { lat: 49.439834, lng: 19.595302 },
    { lat: 49.439944, lng: 19.595232 },
    { lat: 49.44011, lng: 19.594892 },
    { lat: 49.440127, lng: 19.59489 },
    { lat: 49.440315, lng: 19.594962 },
    { lat: 49.44037, lng: 19.594956 },
    { lat: 49.440398, lng: 19.594937 },
    { lat: 49.440407, lng: 19.594902 },
    { lat: 49.440446, lng: 19.594707 },
    { lat: 49.440376, lng: 19.59462 },
    { lat: 49.440361, lng: 19.594452 },
    { lat: 49.440357, lng: 19.594402 },
    { lat: 49.440389, lng: 19.594279 },
    { lat: 49.440419, lng: 19.594238 },
    { lat: 49.440439, lng: 19.594224 },
    { lat: 49.44051, lng: 19.594206 },
    { lat: 49.440535, lng: 19.594077 },
    { lat: 49.440591, lng: 19.594022 },
    { lat: 49.440656, lng: 19.593883 },
    { lat: 49.440933, lng: 19.593893 },
    { lat: 49.441096, lng: 19.593848 },
    { lat: 49.441129, lng: 19.593698 },
    { lat: 49.440995, lng: 19.593256 },
    { lat: 49.440992, lng: 19.593225 },
    { lat: 49.441, lng: 19.593095 },
    { lat: 49.441084, lng: 19.593027 },
    { lat: 49.441279, lng: 19.592641 },
    { lat: 49.441242, lng: 19.592481 },
    { lat: 49.44119, lng: 19.592341 },
    { lat: 49.441102, lng: 19.591974 },
    { lat: 49.441127, lng: 19.591703 },
    { lat: 49.441311, lng: 19.591534 },
    { lat: 49.441556, lng: 19.591359 },
    { lat: 49.441562, lng: 19.590916 },
    { lat: 49.441632, lng: 19.590573 },
    { lat: 49.441703, lng: 19.590461 },
    { lat: 49.441803, lng: 19.590382 },
    { lat: 49.44205, lng: 19.590297 },
    { lat: 49.442159, lng: 19.590245 },
    { lat: 49.442257, lng: 19.590174 },
    { lat: 49.442269, lng: 19.590169 },
    { lat: 49.442336, lng: 19.590173 },
    { lat: 49.442379, lng: 19.590121 },
    { lat: 49.442391, lng: 19.589927 },
    { lat: 49.442452, lng: 19.589855 },
    { lat: 49.442528, lng: 19.589966 },
    { lat: 49.442499, lng: 19.590113 },
    { lat: 49.442666, lng: 19.590124 },
    { lat: 49.442664, lng: 19.590219 },
    { lat: 49.442633, lng: 19.590259 },
    { lat: 49.442671, lng: 19.590348 },
    { lat: 49.442768, lng: 19.590374 },
    { lat: 49.442883, lng: 19.590336 },
    { lat: 49.443037, lng: 19.590117 },
    { lat: 49.443203, lng: 19.59002 },
    { lat: 49.443266, lng: 19.590095 },
    { lat: 49.443113, lng: 19.590259 },
    { lat: 49.443126, lng: 19.590339 },
    { lat: 49.443216, lng: 19.590381 },
    { lat: 49.443351, lng: 19.590275 },
    { lat: 49.443477, lng: 19.590264 },
    { lat: 49.44349, lng: 19.590248 },
    { lat: 49.443552, lng: 19.590095 },
    { lat: 49.443729, lng: 19.590004 },
    { lat: 49.443751, lng: 19.58989 },
    { lat: 49.443661, lng: 19.589798 },
    { lat: 49.443661, lng: 19.58972 },
    { lat: 49.44391, lng: 19.589666 },
    { lat: 49.443986, lng: 19.589703 },
    { lat: 49.44404, lng: 19.58987 },
    { lat: 49.444218, lng: 19.589953 },
    { lat: 49.444253, lng: 19.589904 },
    { lat: 49.444199, lng: 19.58978 },
    { lat: 49.444162, lng: 19.589441 },
    { lat: 49.44424, lng: 19.589377 },
    { lat: 49.44439, lng: 19.589694 },
    { lat: 49.444436, lng: 19.589685 },
    { lat: 49.444591, lng: 19.589401 },
    { lat: 49.444715, lng: 19.589448 },
    { lat: 49.444725, lng: 19.589468 },
    { lat: 49.444728, lng: 19.589558 },
    { lat: 49.444751, lng: 19.589618 },
    { lat: 49.444794, lng: 19.589655 },
    { lat: 49.445037, lng: 19.58979 },
    { lat: 49.445105, lng: 19.589693 },
    { lat: 49.445127, lng: 19.589581 },
    { lat: 49.445102, lng: 19.589525 },
    { lat: 49.444936, lng: 19.589589 },
    { lat: 49.444867, lng: 19.589415 },
    { lat: 49.445127, lng: 19.589351 },
    { lat: 49.445206, lng: 19.589249 },
    { lat: 49.445218, lng: 19.58925 },
    { lat: 49.445223, lng: 19.58926 },
    { lat: 49.44528, lng: 19.589375 },
    { lat: 49.445353, lng: 19.589394 },
    { lat: 49.445357, lng: 19.589575 },
    { lat: 49.445394, lng: 19.589598 },
    { lat: 49.445445, lng: 19.589534 },
    { lat: 49.445476, lng: 19.589357 },
    { lat: 49.445577, lng: 19.589178 },
    { lat: 49.445588, lng: 19.589169 },
    { lat: 49.445598, lng: 19.589178 },
    { lat: 49.445676, lng: 19.589413 },
    { lat: 49.445738, lng: 19.589482 },
    { lat: 49.44587, lng: 19.589261 },
    { lat: 49.445792, lng: 19.588883 },
    { lat: 49.445823, lng: 19.588793 },
    { lat: 49.446, lng: 19.588919 },
    { lat: 49.446033, lng: 19.589021 },
    { lat: 49.446135, lng: 19.588924 },
    { lat: 49.446466, lng: 19.58894 },
    { lat: 49.446654, lng: 19.588698 },
    { lat: 49.446643, lng: 19.588585 },
    { lat: 49.446579, lng: 19.58845 },
    { lat: 49.446587, lng: 19.588429 },
    { lat: 49.446724, lng: 19.588398 },
    { lat: 49.446796, lng: 19.588256 },
    { lat: 49.446735, lng: 19.588121 },
    { lat: 49.446588, lng: 19.587933 },
    { lat: 49.446582, lng: 19.587491 },
    { lat: 49.446655, lng: 19.587315 },
    { lat: 49.446908, lng: 19.587056 },
    { lat: 49.447232, lng: 19.586957 },
    { lat: 49.447227, lng: 19.58685 },
    { lat: 49.447071, lng: 19.586743 },
    { lat: 49.447081, lng: 19.586711 },
    { lat: 49.447118, lng: 19.586634 },
    { lat: 49.44729, lng: 19.586582 },
    { lat: 49.447415, lng: 19.586715 },
    { lat: 49.447484, lng: 19.586706 },
    { lat: 49.44754, lng: 19.58638 },
    { lat: 49.447627, lng: 19.58629 },
    { lat: 49.447682, lng: 19.586371 },
    { lat: 49.447686, lng: 19.586461 },
    { lat: 49.447741, lng: 19.586525 },
    { lat: 49.447836, lng: 19.586494 },
    { lat: 49.447788, lng: 19.586275 },
    { lat: 49.447693, lng: 19.586062 },
    { lat: 49.447787, lng: 19.585954 },
    { lat: 49.447921, lng: 19.585938 },
    { lat: 49.448089, lng: 19.58603 },
    { lat: 49.448262, lng: 19.585879 },
    { lat: 49.448379, lng: 19.585866 },
    { lat: 49.448456, lng: 19.585985 },
    { lat: 49.448539, lng: 19.585929 },
    { lat: 49.448536, lng: 19.585773 },
    { lat: 49.448558, lng: 19.585708 },
    { lat: 49.448589, lng: 19.585703 },
    { lat: 49.448672, lng: 19.585809 },
    { lat: 49.448824, lng: 19.585838 },
    { lat: 49.448909, lng: 19.585966 },
    { lat: 49.449052, lng: 19.585932 },
    { lat: 49.449047, lng: 19.585767 },
    { lat: 49.448955, lng: 19.585507 },
    { lat: 49.449001, lng: 19.585307 },
    { lat: 49.449046, lng: 19.585339 },
    { lat: 49.449051, lng: 19.585497 },
    { lat: 49.449138, lng: 19.585533 },
    { lat: 49.449175, lng: 19.585577 },
    { lat: 49.449221, lng: 19.585501 },
    { lat: 49.449153, lng: 19.585209 },
    { lat: 49.449163, lng: 19.585174 },
    { lat: 49.449217, lng: 19.585143 },
    { lat: 49.449316, lng: 19.585265 },
    { lat: 49.449407, lng: 19.585059 },
    { lat: 49.449411, lng: 19.58491 },
    { lat: 49.449365, lng: 19.584781 },
    { lat: 49.449431, lng: 19.584658 },
    { lat: 49.449396, lng: 19.584546 },
    { lat: 49.449762, lng: 19.583999 },
    { lat: 49.449776, lng: 19.583883 },
    { lat: 49.44988, lng: 19.583852 },
    { lat: 49.449895, lng: 19.583875 },
    { lat: 49.449843, lng: 19.584162 },
    { lat: 49.44995, lng: 19.584364 },
    { lat: 49.449993, lng: 19.584392 },
    { lat: 49.450093, lng: 19.584317 },
    { lat: 49.450142, lng: 19.584202 },
    { lat: 49.450186, lng: 19.584204 },
    { lat: 49.450261, lng: 19.584167 },
    { lat: 49.450278, lng: 19.58405 },
    { lat: 49.450276, lng: 19.583942 },
    { lat: 49.450241, lng: 19.583687 },
    { lat: 49.450249, lng: 19.583405 },
    { lat: 49.450377, lng: 19.583387 },
    { lat: 49.450419, lng: 19.583548 },
    { lat: 49.450612, lng: 19.583459 },
    { lat: 49.450664, lng: 19.583358 },
    { lat: 49.450662, lng: 19.583254 },
    { lat: 49.450842, lng: 19.583155 },
    { lat: 49.450945, lng: 19.583046 },
    { lat: 49.451079, lng: 19.583151 },
    { lat: 49.451159, lng: 19.583122 },
    { lat: 49.451217, lng: 19.583165 },
    { lat: 49.451288, lng: 19.583061 },
    { lat: 49.451377, lng: 19.583049 },
    { lat: 49.451465, lng: 19.583072 },
    { lat: 49.451543, lng: 19.583005 },
    { lat: 49.451562, lng: 19.58293 },
    { lat: 49.451558, lng: 19.582773 },
    { lat: 49.451745, lng: 19.582731 },
    { lat: 49.4518, lng: 19.58281 },
    { lat: 49.451772, lng: 19.582939 },
    { lat: 49.451824, lng: 19.583046 },
    { lat: 49.451938, lng: 19.58312 },
    { lat: 49.451994, lng: 19.583012 },
    { lat: 49.452079, lng: 19.582973 },
    { lat: 49.452064, lng: 19.582914 },
    { lat: 49.452095, lng: 19.582856 },
    { lat: 49.452226, lng: 19.582833 },
    { lat: 49.452241, lng: 19.58277 },
    { lat: 49.452307, lng: 19.582748 },
    { lat: 49.452437, lng: 19.582797 },
    { lat: 49.452497, lng: 19.582888 },
    { lat: 49.45248, lng: 19.582946 },
    { lat: 49.452602, lng: 19.58302 },
    { lat: 49.452755, lng: 19.583055 },
    { lat: 49.452826, lng: 19.582948 },
    { lat: 49.452863, lng: 19.582924 },
    { lat: 49.452871, lng: 19.582912 },
    { lat: 49.452883, lng: 19.582843 },
    { lat: 49.452807, lng: 19.582701 },
    { lat: 49.452844, lng: 19.582639 },
    { lat: 49.452959, lng: 19.582777 },
    { lat: 49.453001, lng: 19.582731 },
    { lat: 49.453043, lng: 19.582595 },
    { lat: 49.453115, lng: 19.582681 },
    { lat: 49.453156, lng: 19.582649 },
    { lat: 49.45314, lng: 19.582454 },
    { lat: 49.453091, lng: 19.582351 },
    { lat: 49.453104, lng: 19.58217 },
    { lat: 49.45311, lng: 19.582147 },
    { lat: 49.453172, lng: 19.582039 },
    { lat: 49.453163, lng: 19.58195 },
    { lat: 49.453173, lng: 19.581935 },
    { lat: 49.453245, lng: 19.582032 },
    { lat: 49.453259, lng: 19.582117 },
    { lat: 49.453332, lng: 19.582137 },
    { lat: 49.453375, lng: 19.582098 },
    { lat: 49.453428, lng: 19.582096 },
    { lat: 49.453489, lng: 19.582031 },
    { lat: 49.453588, lng: 19.582098 },
    { lat: 49.453638, lng: 19.581992 },
    { lat: 49.453782, lng: 19.581974 },
    { lat: 49.453816, lng: 19.581914 },
    { lat: 49.453854, lng: 19.581882 },
    { lat: 49.453852, lng: 19.581849 },
    { lat: 49.45379, lng: 19.581854 },
    { lat: 49.453788, lng: 19.581806 },
    { lat: 49.453906, lng: 19.581684 },
    { lat: 49.453908, lng: 19.581622 },
    { lat: 49.453919, lng: 19.58162 },
    { lat: 49.45396, lng: 19.581674 },
    { lat: 49.453965, lng: 19.581845 },
    { lat: 49.453981, lng: 19.581839 },
    { lat: 49.454039, lng: 19.581764 },
    { lat: 49.4541, lng: 19.581849 },
    { lat: 49.454136, lng: 19.581844 },
    { lat: 49.454143, lng: 19.581871 },
    { lat: 49.454107, lng: 19.582048 },
    { lat: 49.454129, lng: 19.582039 },
    { lat: 49.454235, lng: 19.581957 },
    { lat: 49.454306, lng: 19.582027 },
    { lat: 49.454325, lng: 19.582116 },
    { lat: 49.454397, lng: 19.582179 },
    { lat: 49.454448, lng: 19.582129 },
    { lat: 49.454437, lng: 19.582036 },
    { lat: 49.454405, lng: 19.581991 },
    { lat: 49.454392, lng: 19.581882 },
    { lat: 49.454497, lng: 19.581758 },
    { lat: 49.454632, lng: 19.581749 },
    { lat: 49.454664, lng: 19.581837 },
    { lat: 49.454625, lng: 19.581861 },
    { lat: 49.454649, lng: 19.581912 },
    { lat: 49.454704, lng: 19.581893 },
    { lat: 49.454765, lng: 19.582007 },
    { lat: 49.454846, lng: 19.582042 },
    { lat: 49.454883, lng: 19.58201 },
    { lat: 49.454869, lng: 19.581946 },
    { lat: 49.454944, lng: 19.58195 },
    { lat: 49.455005, lng: 19.581766 },
    { lat: 49.455081, lng: 19.581837 },
    { lat: 49.455134, lng: 19.581812 },
    { lat: 49.45521, lng: 19.58172 },
    { lat: 49.45522, lng: 19.581637 },
    { lat: 49.455357, lng: 19.581509 },
    { lat: 49.4554092, lng: 19.5814677 },
    { lat: 49.455496, lng: 19.5814108 },
    { lat: 49.455516, lng: 19.5814078 },
    { lat: 49.455572, lng: 19.5814228 },
    { lat: 49.455587, lng: 19.5814308 },
    { lat: 49.455619, lng: 19.5814598 },
    { lat: 49.455642, lng: 19.5814688 },
    { lat: 49.45568, lng: 19.5814758 },
    { lat: 49.455725, lng: 19.5815108 },
    { lat: 49.455753, lng: 19.5814898 },
    { lat: 49.455791, lng: 19.5814478 },
    { lat: 49.455807, lng: 19.5813148 },
    { lat: 49.455817, lng: 19.5812788 },
    { lat: 49.455834, lng: 19.5812518 },
    { lat: 49.455835, lng: 19.5812198 },
    { lat: 49.455801, lng: 19.5811958 },
    { lat: 49.45581, lng: 19.5811188 },
    { lat: 49.455833, lng: 19.5811048 },
    { lat: 49.45587, lng: 19.5811658 },
    { lat: 49.455885, lng: 19.5811518 },
    { lat: 49.4559, lng: 19.5811038 },
    { lat: 49.455953, lng: 19.5810628 },
    { lat: 49.455997, lng: 19.5810548 },
    { lat: 49.456009, lng: 19.5810618 },
    { lat: 49.456051, lng: 19.5811268 },
    { lat: 49.456094, lng: 19.5811448 },
    { lat: 49.456151, lng: 19.5811368 },
    { lat: 49.45617, lng: 19.5811118 },
    { lat: 49.456166, lng: 19.5810808 },
    { lat: 49.456168, lng: 19.5810568 },
    { lat: 49.456191, lng: 19.5810288 },
    { lat: 49.456243, lng: 19.5810098 },
    { lat: 49.456295, lng: 19.5810268 },
    { lat: 49.456334, lng: 19.5810088 },
    { lat: 49.456357, lng: 19.5809848 },
    { lat: 49.456377, lng: 19.5809848 },
    { lat: 49.456392, lng: 19.5809948 },
    { lat: 49.456404, lng: 19.5810518 },
    { lat: 49.456421, lng: 19.5810768 },
    { lat: 49.456437, lng: 19.5810638 },
    { lat: 49.456459, lng: 19.5810108 },
    { lat: 49.456493, lng: 19.5810068 },
    { lat: 49.456516, lng: 19.5810578 },
    { lat: 49.456523, lng: 19.5811068 },
    { lat: 49.456478, lng: 19.5811808 },
    { lat: 49.456447, lng: 19.5812738 },
    { lat: 49.456442, lng: 19.5812938 },
    { lat: 49.456441, lng: 19.5813248 },
    { lat: 49.456454, lng: 19.5813548 },
    { lat: 49.456475, lng: 19.5813678 },
    { lat: 49.456492, lng: 19.5813698 },
    { lat: 49.456505, lng: 19.5813658 },
    { lat: 49.456545, lng: 19.5813358 },
    { lat: 49.456568, lng: 19.5813148 },
    { lat: 49.456578, lng: 19.5813118 },
    { lat: 49.456602, lng: 19.5813358 },
    { lat: 49.456609, lng: 19.5813508 },
    { lat: 49.456623, lng: 19.5814148 },
    { lat: 49.456638, lng: 19.5814458 },
    { lat: 49.456649, lng: 19.5814638 },
    { lat: 49.456669, lng: 19.5814828 },
    { lat: 49.456684, lng: 19.5814878 },
    { lat: 49.4567, lng: 19.5814878 },
    { lat: 49.456752, lng: 19.5814698 },
    { lat: 49.45677, lng: 19.5814498 },
    { lat: 49.456788, lng: 19.5813878 },
    { lat: 49.45679, lng: 19.5813668 },
    { lat: 49.45679, lng: 19.5813258 },
    { lat: 49.456812, lng: 19.5812538 },
    { lat: 49.456845, lng: 19.5812198 },
    { lat: 49.456899, lng: 19.5811478 },
    { lat: 49.456981, lng: 19.5811988 },
    { lat: 49.456989, lng: 19.5812238 },
    { lat: 49.45698, lng: 19.5812728 },
    { lat: 49.456969, lng: 19.5813008 },
    { lat: 49.456997, lng: 19.5813368 },
    { lat: 49.457036, lng: 19.5813708 },
    { lat: 49.457078, lng: 19.5814248 },
    { lat: 49.457103, lng: 19.5814848 },
    { lat: 49.457116, lng: 19.5814938 },
    { lat: 49.457157, lng: 19.5814908 },
    { lat: 49.457168, lng: 19.5814798 },
    { lat: 49.457206, lng: 19.5814238 },
    { lat: 49.457254, lng: 19.5814108 },
    { lat: 49.457279, lng: 19.5814208 },
    { lat: 49.457345, lng: 19.5814708 },
    { lat: 49.457377, lng: 19.5814408 },
    { lat: 49.457389, lng: 19.5814518 },
    { lat: 49.457395, lng: 19.5814688 },
    { lat: 49.457401, lng: 19.5815048 },
    { lat: 49.457402, lng: 19.5815388 },
    { lat: 49.457389, lng: 19.5815598 },
    { lat: 49.457387, lng: 19.5815788 },
    { lat: 49.457396, lng: 19.5815998 },
    { lat: 49.457417, lng: 19.5816278 },
    { lat: 49.45747, lng: 19.5816668 },
    { lat: 49.457468, lng: 19.5816878 },
    { lat: 49.457463, lng: 19.5816998 },
    { lat: 49.457457, lng: 19.5817118 },
    { lat: 49.457431, lng: 19.5817408 },
    { lat: 49.457425, lng: 19.5817948 },
    { lat: 49.457432, lng: 19.5818428 },
    { lat: 49.457473, lng: 19.5819198 },
    { lat: 49.457516, lng: 19.5819318 },
    { lat: 49.457533, lng: 19.5819588 },
    { lat: 49.457551, lng: 19.5819588 },
    { lat: 49.457563, lng: 19.5818988 },
    { lat: 49.457565, lng: 19.5818428 },
    { lat: 49.457586, lng: 19.5818178 },
    { lat: 49.457618, lng: 19.5818278 },
    { lat: 49.457655, lng: 19.5818748 },
    { lat: 49.457714, lng: 19.5819718 },
    { lat: 49.457722, lng: 19.5819988 },
    { lat: 49.457734, lng: 19.5820068 },
    { lat: 49.457754, lng: 19.5820048 },
    { lat: 49.457804, lng: 19.5820148 },
    { lat: 49.457854, lng: 19.5820438 },
    { lat: 49.457877, lng: 19.5820188 },
    { lat: 49.457948, lng: 19.5818758 },
    { lat: 49.457936, lng: 19.5817898 },
    { lat: 49.457974, lng: 19.5816488 },
    { lat: 49.457981, lng: 19.5815768 },
    { lat: 49.457994, lng: 19.5815508 },
    { lat: 49.458013, lng: 19.5815528 },
    { lat: 49.458094, lng: 19.5817198 },
    { lat: 49.458101, lng: 19.5817578 },
    { lat: 49.4581, lng: 19.5817778 },
    { lat: 49.458075, lng: 19.5818178 },
    { lat: 49.458079, lng: 19.5818348 },
    { lat: 49.458113, lng: 19.5818768 },
    { lat: 49.458161, lng: 19.5818708 },
    { lat: 49.458212, lng: 19.5817748 },
    { lat: 49.458265, lng: 19.5817478 },
    { lat: 49.45828, lng: 19.5817278 },
    { lat: 49.458273, lng: 19.5816808 },
    { lat: 49.458254, lng: 19.5816408 },
    { lat: 49.458249, lng: 19.5815898 },
    { lat: 49.458262, lng: 19.5815658 },
    { lat: 49.458317, lng: 19.5815108 },
    { lat: 49.458325, lng: 19.5814768 },
    { lat: 49.458323, lng: 19.5814318 },
    { lat: 49.458314, lng: 19.5814108 },
    { lat: 49.458285, lng: 19.5813668 },
    { lat: 49.458247, lng: 19.5813388 },
    { lat: 49.45823, lng: 19.5812748 },
    { lat: 49.458229, lng: 19.5811958 },
    { lat: 49.458261, lng: 19.5811268 },
    { lat: 49.458275, lng: 19.5811118 },
    { lat: 49.458294, lng: 19.5811018 },
    { lat: 49.458318, lng: 19.5811088 },
    { lat: 49.458369, lng: 19.5811688 },
    { lat: 49.458406, lng: 19.5811698 },
    { lat: 49.458416, lng: 19.5811888 },
    { lat: 49.458426, lng: 19.5812358 },
    { lat: 49.458436, lng: 19.5812658 },
    { lat: 49.458452, lng: 19.5812598 },
    { lat: 49.458476, lng: 19.5812178 },
    { lat: 49.458492, lng: 19.5811328 },
    { lat: 49.458529, lng: 19.5810828 },
    { lat: 49.458565, lng: 19.5810458 },
    { lat: 49.458624, lng: 19.5809328 },
    { lat: 49.458704, lng: 19.5809018 },
    { lat: 49.458758, lng: 19.5808448 },
    { lat: 49.45881, lng: 19.5807478 },
    { lat: 49.458838, lng: 19.5807498 },
    { lat: 49.45891, lng: 19.5806768 },
    { lat: 49.45893, lng: 19.5806338 },
    { lat: 49.458963, lng: 19.5806328 },
    { lat: 49.459005, lng: 19.5806528 },
    { lat: 49.459103, lng: 19.5806658 },
    { lat: 49.459148, lng: 19.5806608 },
    { lat: 49.459209, lng: 19.5806338 },
    { lat: 49.459229, lng: 19.5806318 },
    { lat: 49.459237, lng: 19.5806408 },
    { lat: 49.45924, lng: 19.5806948 },
    { lat: 49.459231, lng: 19.5807138 },
    { lat: 49.459195, lng: 19.5807328 },
    { lat: 49.45919, lng: 19.5807778 },
    { lat: 49.459197, lng: 19.5808528 },
    { lat: 49.459186, lng: 19.5809088 },
    { lat: 49.459173, lng: 19.5809488 },
    { lat: 49.459191, lng: 19.5809718 },
    { lat: 49.459207, lng: 19.5809778 },
    { lat: 49.459229, lng: 19.5809738 },
    { lat: 49.459334, lng: 19.5808558 },
    { lat: 49.459357, lng: 19.5808458 },
    { lat: 49.45939, lng: 19.5808808 },
    { lat: 49.459417, lng: 19.5809648 },
    { lat: 49.459448, lng: 19.5809768 },
    { lat: 49.45948, lng: 19.5809358 },
    { lat: 49.459518, lng: 19.5809758 },
    { lat: 49.459527, lng: 19.5809998 },
    { lat: 49.459525, lng: 19.5810588 },
    { lat: 49.45949, lng: 19.5811588 },
    { lat: 49.459441, lng: 19.5811908 },
    { lat: 49.459438, lng: 19.5812188 },
    { lat: 49.459451, lng: 19.5812388 },
    { lat: 49.459485, lng: 19.5812648 },
    { lat: 49.459541, lng: 19.5812628 },
    { lat: 49.459594, lng: 19.5812848 },
    { lat: 49.459646, lng: 19.5812528 },
    { lat: 49.459677, lng: 19.5812158 },
    { lat: 49.459767, lng: 19.5811378 },
    { lat: 49.459811, lng: 19.5810488 },
    { lat: 49.459837, lng: 19.5810078 },
    { lat: 49.459845, lng: 19.5810058 },
    { lat: 49.459845, lng: 19.5809758 },
    { lat: 49.459816, lng: 19.5809448 },
    { lat: 49.459805, lng: 19.5808798 },
    { lat: 49.459815, lng: 19.5808318 },
    { lat: 49.459852, lng: 19.5808228 },
    { lat: 49.459905, lng: 19.5808368 },
    { lat: 49.459929, lng: 19.5808688 },
    { lat: 49.459942, lng: 19.5809338 },
    { lat: 49.459948, lng: 19.5810348 },
    { lat: 49.459963, lng: 19.5810488 },
    { lat: 49.46, lng: 19.5810228 },
    { lat: 49.460042, lng: 19.5809828 },
    { lat: 49.460075, lng: 19.5809948 },
    { lat: 49.460109, lng: 19.5810198 },
    { lat: 49.460143, lng: 19.5810048 },
    { lat: 49.460147, lng: 19.5809688 },
    { lat: 49.460144, lng: 19.5809328 },
    { lat: 49.460146, lng: 19.5809158 },
    { lat: 49.460181, lng: 19.5808908 },
    { lat: 49.460199, lng: 19.5808848 },
    { lat: 49.460256, lng: 19.5808988 },
    { lat: 49.460279, lng: 19.5808768 },
    { lat: 49.46039, lng: 19.5806368 },
    { lat: 49.46052, lng: 19.5805448 },
    { lat: 49.460543, lng: 19.5805618 },
    { lat: 49.460552, lng: 19.5806198 },
    { lat: 49.460527, lng: 19.5806618 },
    { lat: 49.460513, lng: 19.5806668 },
    { lat: 49.460502, lng: 19.5807118 },
    { lat: 49.460526, lng: 19.5807688 },
    { lat: 49.460566, lng: 19.5807678 },
    { lat: 49.460606, lng: 19.5807398 },
    { lat: 49.460669, lng: 19.5806098 },
    { lat: 49.460689, lng: 19.5805908 },
    { lat: 49.460754, lng: 19.5806208 },
    { lat: 49.460901, lng: 19.5806148 },
    { lat: 49.460965, lng: 19.5806548 },
    { lat: 49.46102, lng: 19.5807258 },
    { lat: 49.461065, lng: 19.5807148 },
    { lat: 49.461112, lng: 19.5806378 },
    { lat: 49.461219, lng: 19.5805818 },
    { lat: 49.461245, lng: 19.5805148 },
    { lat: 49.461239, lng: 19.5804788 },
    { lat: 49.461209, lng: 19.5804228 },
    { lat: 49.461216, lng: 19.5803978 },
    { lat: 49.46125, lng: 19.5803588 },
    { lat: 49.461269, lng: 19.5802478 },
    { lat: 49.461297, lng: 19.5801708 },
    { lat: 49.461297, lng: 19.5800618 },
    { lat: 49.461321, lng: 19.5800268 },
    { lat: 49.461371, lng: 19.5800148 },
    { lat: 49.461402, lng: 19.5800468 },
    { lat: 49.461432, lng: 19.5801098 },
    { lat: 49.461475, lng: 19.5801378 },
    { lat: 49.461503, lng: 19.5801148 },
    { lat: 49.461522, lng: 19.5800578 },
    { lat: 49.461475, lng: 19.5798758 },
    { lat: 49.461488, lng: 19.5797828 },
    { lat: 49.461554, lng: 19.5797258 },
    { lat: 49.461587, lng: 19.5797098 },
    { lat: 49.461606, lng: 19.5797128 },
    { lat: 49.46165, lng: 19.5798088 },
    { lat: 49.461693, lng: 19.5798008 },
    { lat: 49.461747, lng: 19.5799158 },
    { lat: 49.461783, lng: 19.5799338 },
    { lat: 49.461883, lng: 19.5799378 },
    { lat: 49.461926, lng: 19.5799168 },
    { lat: 49.461928, lng: 19.5798888 },
    { lat: 49.461911, lng: 19.5797568 },
    { lat: 49.461847, lng: 19.5797668 },
    { lat: 49.461841, lng: 19.5797388 },
    { lat: 49.461847, lng: 19.5796898 },
    { lat: 49.461857, lng: 19.5796508 },
    { lat: 49.461871, lng: 19.5796328 },
    { lat: 49.461929, lng: 19.5796768 },
    { lat: 49.462014, lng: 19.5796908 },
    { lat: 49.462168, lng: 19.5796188 },
    { lat: 49.462169, lng: 19.5795908 },
    { lat: 49.462152, lng: 19.5795438 },
    { lat: 49.462096, lng: 19.5795038 },
    { lat: 49.462075, lng: 19.5794348 },
    { lat: 49.462105, lng: 19.5792998 },
    { lat: 49.46214, lng: 19.5791918 },
    { lat: 49.462167, lng: 19.5791468 },
    { lat: 49.462169, lng: 19.5790768 },
    { lat: 49.462178, lng: 19.5790618 },
    { lat: 49.462277, lng: 19.5789178 },
    { lat: 49.462304, lng: 19.5789328 },
    { lat: 49.462312, lng: 19.5789258 },
    { lat: 49.462311, lng: 19.5788808 },
    { lat: 49.462326, lng: 19.5788158 },
    { lat: 49.462356, lng: 19.5787378 },
    { lat: 49.462386, lng: 19.5786308 },
    { lat: 49.462416, lng: 19.5786088 },
    { lat: 49.462431, lng: 19.5786228 },
    { lat: 49.462434, lng: 19.5786928 },
    { lat: 49.462458, lng: 19.5787228 },
    { lat: 49.462478, lng: 19.5787138 },
    { lat: 49.462504, lng: 19.5784578 },
    { lat: 49.462606, lng: 19.5782848 },
    { lat: 49.462631, lng: 19.5782208 },
    { lat: 49.462664, lng: 19.5782338 },
    { lat: 49.462687, lng: 19.5782548 },
    { lat: 49.462719, lng: 19.5783358 },
    { lat: 49.462733, lng: 19.5783398 },
    { lat: 49.462766, lng: 19.5782928 },
    { lat: 49.462808, lng: 19.5782038 },
    { lat: 49.462826, lng: 19.5781998 },
    { lat: 49.462849, lng: 19.5782208 },
    { lat: 49.462908, lng: 19.5781748 },
    { lat: 49.462893, lng: 19.5780718 },
    { lat: 49.462913, lng: 19.5780108 },
    { lat: 49.462945, lng: 19.5779538 },
    { lat: 49.463049, lng: 19.5778778 },
    { lat: 49.463144, lng: 19.5777988 },
    { lat: 49.463153, lng: 19.5777888 },
    { lat: 49.463205, lng: 19.5777358 },
    { lat: 49.463241, lng: 19.5777078 },
    { lat: 49.463256, lng: 19.5776908 },
    { lat: 49.463296, lng: 19.5776078 },
    { lat: 49.463299, lng: 19.5775768 },
    { lat: 49.463299, lng: 19.5775618 },
    { lat: 49.463288, lng: 19.5775528 },
    { lat: 49.463269, lng: 19.5775558 },
    { lat: 49.463231, lng: 19.5775738 },
    { lat: 49.463221, lng: 19.5775728 },
    { lat: 49.463213, lng: 19.5775478 },
    { lat: 49.463214, lng: 19.5775228 },
    { lat: 49.463228, lng: 19.5774708 },
    { lat: 49.46327, lng: 19.5773648 },
    { lat: 49.463341, lng: 19.5772628 },
    { lat: 49.463334, lng: 19.5771768 },
    { lat: 49.463338, lng: 19.5771678 },
    { lat: 49.463355, lng: 19.5771488 },
    { lat: 49.463401, lng: 19.5771198 },
    { lat: 49.463411, lng: 19.5771048 },
    { lat: 49.463454, lng: 19.5770188 },
    { lat: 49.463457, lng: 19.5769888 },
    { lat: 49.463456, lng: 19.5769698 },
    { lat: 49.463455, lng: 19.5769588 },
    { lat: 49.463418, lng: 19.5768828 },
    { lat: 49.463405, lng: 19.5768288 },
    { lat: 49.4634, lng: 19.5768158 },
    { lat: 49.463342, lng: 19.5767788 },
    { lat: 49.463315, lng: 19.5767558 },
    { lat: 49.463287, lng: 19.5767398 },
    { lat: 49.463278, lng: 19.5767358 },
    { lat: 49.463236, lng: 19.5767258 },
    { lat: 49.463221, lng: 19.5767228 },
    { lat: 49.463175, lng: 19.5767398 },
    { lat: 49.463147, lng: 19.5767108 },
    { lat: 49.463147, lng: 19.5766528 },
    { lat: 49.46315, lng: 19.5766258 },
    { lat: 49.463153, lng: 19.5766138 },
    { lat: 49.463166, lng: 19.5765738 },
    { lat: 49.463168, lng: 19.5764908 },
    { lat: 49.463172, lng: 19.5764618 },
    { lat: 49.463182, lng: 19.5764268 },
    { lat: 49.463204, lng: 19.5764138 },
    { lat: 49.463247, lng: 19.5764178 },
    { lat: 49.463291, lng: 19.5764498 },
    { lat: 49.463301, lng: 19.5764888 },
    { lat: 49.463318, lng: 19.5765268 },
    { lat: 49.463325, lng: 19.5765438 },
    { lat: 49.463341, lng: 19.5765498 },
    { lat: 49.46335, lng: 19.5765368 },
    { lat: 49.463366, lng: 19.5765128 },
    { lat: 49.463377, lng: 19.5764808 },
    { lat: 49.463376, lng: 19.5764088 },
    { lat: 49.463373, lng: 19.5763198 },
    { lat: 49.463399, lng: 19.5761848 },
    { lat: 49.46343, lng: 19.5761038 },
    { lat: 49.463441, lng: 19.5760368 },
    { lat: 49.463458, lng: 19.5760128 },
    { lat: 49.463504, lng: 19.5760218 },
    { lat: 49.46358, lng: 19.5760158 },
    { lat: 49.463601, lng: 19.5760048 },
    { lat: 49.463605, lng: 19.5760008 },
    { lat: 49.463643, lng: 19.5759428 },
    { lat: 49.463651, lng: 19.5759288 },
    { lat: 49.463735, lng: 19.5758158 },
    { lat: 49.463787, lng: 19.5756998 },
    { lat: 49.463804, lng: 19.5756808 },
    { lat: 49.463875, lng: 19.5756278 },
    { lat: 49.463886, lng: 19.5756158 },
    { lat: 49.463909, lng: 19.5755758 },
    { lat: 49.46392, lng: 19.5755418 },
    { lat: 49.463925, lng: 19.5754988 },
    { lat: 49.463925, lng: 19.5754918 },
    { lat: 49.463958, lng: 19.5754348 },
    { lat: 49.464006, lng: 19.5753798 },
    { lat: 49.464021, lng: 19.5753558 },
    { lat: 49.464028, lng: 19.5753428 },
    { lat: 49.464024, lng: 19.5753068 },
    { lat: 49.464016, lng: 19.5752948 },
    { lat: 49.46398, lng: 19.5752388 },
    { lat: 49.463933, lng: 19.5752058 },
    { lat: 49.463879, lng: 19.5751748 },
    { lat: 49.463879, lng: 19.5751548 },
    { lat: 49.463902, lng: 19.5751268 },
    { lat: 49.463946, lng: 19.5750958 },
    { lat: 49.463972, lng: 19.5750718 },
    { lat: 49.463988, lng: 19.5750638 },
    { lat: 49.463997, lng: 19.5750628 },
    { lat: 49.464015, lng: 19.5751008 },
    { lat: 49.464014, lng: 19.5751258 },
    { lat: 49.463996, lng: 19.5751528 },
    { lat: 49.463997, lng: 19.5751828 },
    { lat: 49.464019, lng: 19.5751998 },
    { lat: 49.46406, lng: 19.5751968 },
    { lat: 49.464091, lng: 19.5751798 },
    { lat: 49.464122, lng: 19.5751008 },
    { lat: 49.464162, lng: 19.5750828 },
    { lat: 49.464195, lng: 19.5750898 },
    { lat: 49.464213, lng: 19.5750598 },
    { lat: 49.464207, lng: 19.5750188 },
    { lat: 49.464189, lng: 19.5749338 },
    { lat: 49.464187, lng: 19.5748828 },
    { lat: 49.464197, lng: 19.5748478 },
    { lat: 49.464227, lng: 19.5748548 },
    { lat: 49.464243, lng: 19.5748868 },
    { lat: 49.464281, lng: 19.5749788 },
    { lat: 49.464298, lng: 19.5750318 },
    { lat: 49.464318, lng: 19.5750848 },
    { lat: 49.464328, lng: 19.5751038 },
    { lat: 49.464354, lng: 19.5751288 },
    { lat: 49.464369, lng: 19.5751248 },
    { lat: 49.46438, lng: 19.5751178 },
    { lat: 49.46442, lng: 19.5750658 },
    { lat: 49.464431, lng: 19.5750478 },
    { lat: 49.464448, lng: 19.5749778 },
    { lat: 49.464445, lng: 19.5748358 },
    { lat: 49.46444, lng: 19.5747758 },
    { lat: 49.464428, lng: 19.5747318 },
    { lat: 49.464452, lng: 19.5746948 },
    { lat: 49.464499, lng: 19.5746878 },
    { lat: 49.46455, lng: 19.5747148 },
    { lat: 49.46458, lng: 19.5747208 },
    { lat: 49.4646, lng: 19.5747178 },
    { lat: 49.464629, lng: 19.5747098 },
    { lat: 49.46465, lng: 19.5746608 },
    { lat: 49.46463, lng: 19.5745998 },
    { lat: 49.464611, lng: 19.5745748 },
    { lat: 49.464595, lng: 19.5745678 },
    { lat: 49.464583, lng: 19.5745508 },
    { lat: 49.464587, lng: 19.5744638 },
    { lat: 49.464592, lng: 19.5744548 },
    { lat: 49.464626, lng: 19.5744558 },
    { lat: 49.464669, lng: 19.5744798 },
    { lat: 49.464696, lng: 19.5744878 },
    { lat: 49.464747, lng: 19.5744698 },
    { lat: 49.464762, lng: 19.5744408 },
    { lat: 49.464804, lng: 19.5744188 },
    { lat: 49.464848, lng: 19.5743748 },
    { lat: 49.464953, lng: 19.5743548 },
    { lat: 49.464964, lng: 19.5743488 },
    { lat: 49.464987, lng: 19.5742698 },
    { lat: 49.465068, lng: 19.5741858 },
    { lat: 49.465094, lng: 19.5741358 },
    { lat: 49.465124, lng: 19.5740598 },
    { lat: 49.465139, lng: 19.5740388 },
    { lat: 49.465162, lng: 19.5740208 },
    { lat: 49.465177, lng: 19.5740158 },
    { lat: 49.465219, lng: 19.5740148 },
    { lat: 49.465337, lng: 19.5739598 },
    { lat: 49.46537, lng: 19.5739378 },
    { lat: 49.465435, lng: 19.5738858 },
    { lat: 49.465436, lng: 19.5738248 },
    { lat: 49.465433, lng: 19.5737658 },
    { lat: 49.465458, lng: 19.5737358 },
    { lat: 49.465478, lng: 19.5736828 },
    { lat: 49.465498, lng: 19.5736868 },
    { lat: 49.46552, lng: 19.5737088 },
    { lat: 49.465542, lng: 19.5737468 },
    { lat: 49.465595, lng: 19.5737258 },
    { lat: 49.46561, lng: 19.5736798 },
    { lat: 49.465577, lng: 19.5736368 },
    { lat: 49.465516, lng: 19.5735928 },
    { lat: 49.465489, lng: 19.5735568 },
    { lat: 49.465475, lng: 19.5735038 },
    { lat: 49.465488, lng: 19.5734568 },
    { lat: 49.465503, lng: 19.5734488 },
    { lat: 49.465537, lng: 19.5734388 },
    { lat: 49.465564, lng: 19.5734758 },
    { lat: 49.465577, lng: 19.5734768 },
    { lat: 49.465618, lng: 19.5734688 },
    { lat: 49.465642, lng: 19.5734818 },
    { lat: 49.465653, lng: 19.5734758 },
    { lat: 49.465674, lng: 19.5734508 },
    { lat: 49.465712, lng: 19.5733608 },
    { lat: 49.465719, lng: 19.5733288 },
    { lat: 49.465719, lng: 19.5732868 },
    { lat: 49.465755, lng: 19.5732328 },
    { lat: 49.465802, lng: 19.5731488 },
    { lat: 49.465852, lng: 19.5730928 },
    { lat: 49.465903, lng: 19.5730348 },
    { lat: 49.465917, lng: 19.5730308 },
    { lat: 49.465951, lng: 19.5731428 },
    { lat: 49.465954, lng: 19.5731678 },
    { lat: 49.465947, lng: 19.5731938 },
    { lat: 49.46596, lng: 19.5732238 },
    { lat: 49.466039, lng: 19.5731968 },
    { lat: 49.466078, lng: 19.5732218 },
    { lat: 49.466141, lng: 19.5732098 },
    { lat: 49.466179, lng: 19.5731658 },
    { lat: 49.466195, lng: 19.5731358 },
    { lat: 49.46622, lng: 19.5730448 },
    { lat: 49.466222, lng: 19.5729668 },
    { lat: 49.466228, lng: 19.5729398 },
    { lat: 49.46624, lng: 19.5728998 },
    { lat: 49.466307, lng: 19.5728678 },
    { lat: 49.466347, lng: 19.5728568 },
    { lat: 49.466368, lng: 19.5728638 },
    { lat: 49.466388, lng: 19.5728768 },
    { lat: 49.46642, lng: 19.5728858 },
    { lat: 49.466507, lng: 19.5728828 },
    { lat: 49.466529, lng: 19.5728048 },
    { lat: 49.466561, lng: 19.5727718 },
    { lat: 49.466591, lng: 19.5728068 },
    { lat: 49.466616, lng: 19.5728548 },
    { lat: 49.466623, lng: 19.5729078 },
    { lat: 49.466619, lng: 19.5729428 },
    { lat: 49.466538, lng: 19.5729998 },
    { lat: 49.466533, lng: 19.5730198 },
    { lat: 49.466546, lng: 19.5730728 },
    { lat: 49.466634, lng: 19.5731108 },
    { lat: 49.466653, lng: 19.5731258 },
    { lat: 49.466673, lng: 19.5731558 },
    { lat: 49.466733, lng: 19.5731328 },
    { lat: 49.466792, lng: 19.5731218 },
    { lat: 49.466841, lng: 19.5731038 },
    { lat: 49.46687, lng: 19.5730648 },
    { lat: 49.466872, lng: 19.5730428 },
    { lat: 49.466861, lng: 19.5730108 },
    { lat: 49.466856, lng: 19.5729868 },
    { lat: 49.466873, lng: 19.5729668 },
    { lat: 49.46692, lng: 19.5729628 },
    { lat: 49.46695, lng: 19.5729768 },
    { lat: 49.467027, lng: 19.5730438 },
    { lat: 49.467077, lng: 19.5730358 },
    { lat: 49.467115, lng: 19.5729798 },
    { lat: 49.467148, lng: 19.5728408 },
    { lat: 49.467224, lng: 19.5728138 },
    { lat: 49.467296, lng: 19.5728138 },
    { lat: 49.467328, lng: 19.5728038 },
    { lat: 49.467493, lng: 19.5726768 },
    { lat: 49.467502, lng: 19.5726348 },
    { lat: 49.467473, lng: 19.5725118 },
    { lat: 49.467387, lng: 19.5723118 },
    { lat: 49.467348, lng: 19.5722698 },
    { lat: 49.467326, lng: 19.5722378 },
    { lat: 49.467336, lng: 19.5721768 },
    { lat: 49.467362, lng: 19.5721398 },
    { lat: 49.467392, lng: 19.5721218 },
    { lat: 49.467448, lng: 19.5721408 },
    { lat: 49.467551, lng: 19.5720058 },
    { lat: 49.46757, lng: 19.5719678 },
    { lat: 49.467584, lng: 19.5719648 },
    { lat: 49.467631, lng: 19.5720168 },
    { lat: 49.467678, lng: 19.5720428 },
    { lat: 49.467714, lng: 19.5720848 },
    { lat: 49.467737, lng: 19.5720718 },
    { lat: 49.467741, lng: 19.5720518 },
    { lat: 49.467728, lng: 19.5719788 },
    { lat: 49.467738, lng: 19.5719518 },
    { lat: 49.467775, lng: 19.5719448 },
    { lat: 49.46779, lng: 19.5719348 },
    { lat: 49.467853, lng: 19.5718298 },
    { lat: 49.467892, lng: 19.5718158 },
    { lat: 49.467962, lng: 19.5718238 },
    { lat: 49.467995, lng: 19.5717228 },
    { lat: 49.468026, lng: 19.5715918 },
    { lat: 49.468058, lng: 19.5715928 },
    { lat: 49.468168, lng: 19.5715698 },
    { lat: 49.468226, lng: 19.5715038 },
    { lat: 49.468228, lng: 19.5713968 },
    { lat: 49.468255, lng: 19.5713698 },
    { lat: 49.468294, lng: 19.5714178 },
    { lat: 49.468295, lng: 19.5715008 },
    { lat: 49.468319, lng: 19.5716488 },
    { lat: 49.468339, lng: 19.5716898 },
    { lat: 49.468358, lng: 19.5717018 },
    { lat: 49.46838, lng: 19.5716928 },
    { lat: 49.468417, lng: 19.5716938 },
    { lat: 49.46846, lng: 19.5716538 },
    { lat: 49.468461, lng: 19.5715368 },
    { lat: 49.468515, lng: 19.5714458 },
    { lat: 49.468606, lng: 19.5713438 },
    { lat: 49.468654, lng: 19.5713338 },
    { lat: 49.468678, lng: 19.5712968 },
    { lat: 49.468678, lng: 19.5712688 },
    { lat: 49.468615, lng: 19.5712338 },
    { lat: 49.468611, lng: 19.5711758 },
    { lat: 49.468618, lng: 19.5711198 },
    { lat: 49.468596, lng: 19.5710008 },
    { lat: 49.468597, lng: 19.5709288 },
    { lat: 49.46863, lng: 19.5708308 },
    { lat: 49.46866, lng: 19.5708078 },
    { lat: 49.468695, lng: 19.5708038 },
    { lat: 49.46871, lng: 19.5708448 },
    { lat: 49.468689, lng: 19.5708928 },
    { lat: 49.468684, lng: 19.5709218 },
    { lat: 49.4687, lng: 19.5709548 },
    { lat: 49.468772, lng: 19.5710008 },
    { lat: 49.468815, lng: 19.5709808 },
    { lat: 49.468832, lng: 19.5709238 },
    { lat: 49.468809, lng: 19.5708118 },
    { lat: 49.46876, lng: 19.5706498 },
    { lat: 49.468784, lng: 19.5706048 },
    { lat: 49.468819, lng: 19.5705708 },
    { lat: 49.468852, lng: 19.5705858 },
    { lat: 49.468886, lng: 19.5706148 },
    { lat: 49.468935, lng: 19.5706098 },
    { lat: 49.46897, lng: 19.5704578 },
    { lat: 49.468991, lng: 19.5704388 },
    { lat: 49.469039, lng: 19.5704548 },
    { lat: 49.469056, lng: 19.5704468 },
    { lat: 49.469118, lng: 19.5703678 },
    { lat: 49.469154, lng: 19.5702978 },
    { lat: 49.469183, lng: 19.5702818 },
    { lat: 49.469216, lng: 19.5703908 },
    { lat: 49.4693, lng: 19.5705318 },
    { lat: 49.469275, lng: 19.5705748 },
    { lat: 49.469276, lng: 19.5706228 },
    { lat: 49.46929, lng: 19.5706268 },
    { lat: 49.469381, lng: 19.5705438 },
    { lat: 49.469395, lng: 19.5705428 },
    { lat: 49.469463, lng: 19.5704568 },
    { lat: 49.469512, lng: 19.5703668 },
    { lat: 49.469558, lng: 19.5702558 },
    { lat: 49.46957, lng: 19.5701818 },
    { lat: 49.46957, lng: 19.5701078 },
    { lat: 49.469624, lng: 19.5699968 },
    { lat: 49.469698, lng: 19.5699618 },
    { lat: 49.469776, lng: 19.5699578 },
    { lat: 49.46986, lng: 19.5699818 },
    { lat: 49.46993, lng: 19.5700318 },
    { lat: 49.469984, lng: 19.5700908 },
    { lat: 49.470015, lng: 19.5700738 },
    { lat: 49.470046, lng: 19.5700408 },
    { lat: 49.47005, lng: 19.5699488 },
    { lat: 49.470024, lng: 19.5698488 },
    { lat: 49.47002, lng: 19.5696618 },
    { lat: 49.469968, lng: 19.5696038 },
    { lat: 49.469948, lng: 19.5695548 },
    { lat: 49.469931, lng: 19.5694808 },
    { lat: 49.469953, lng: 19.5693918 },
    { lat: 49.470005, lng: 19.5693048 },
    { lat: 49.470052, lng: 19.5692858 },
    { lat: 49.470088, lng: 19.5693068 },
    { lat: 49.47012, lng: 19.5692988 },
    { lat: 49.470136, lng: 19.5693178 },
    { lat: 49.470156, lng: 19.5693768 },
    { lat: 49.470181, lng: 19.5693908 },
    { lat: 49.470238, lng: 19.5693228 },
    { lat: 49.47022, lng: 19.5692158 },
    { lat: 49.470147, lng: 19.5691448 },
    { lat: 49.470111, lng: 19.5691518 },
    { lat: 49.470079, lng: 19.5691448 },
    { lat: 49.47006, lng: 19.5690858 },
    { lat: 49.470068, lng: 19.5690238 },
    { lat: 49.470082, lng: 19.5690128 },
    { lat: 49.470116, lng: 19.5690258 },
    { lat: 49.470164, lng: 19.5690658 },
    { lat: 49.470237, lng: 19.5690778 },
    { lat: 49.470255, lng: 19.5690608 },
    { lat: 49.470251, lng: 19.5689068 },
    { lat: 49.470215, lng: 19.5688988 },
    { lat: 49.470178, lng: 19.5689038 },
    { lat: 49.470162, lng: 19.5689188 },
    { lat: 49.470147, lng: 19.5688918 },
    { lat: 49.470158, lng: 19.5688568 },
    { lat: 49.470194, lng: 19.5687918 },
    { lat: 49.470216, lng: 19.5686688 },
    { lat: 49.470251, lng: 19.5686338 },
    { lat: 49.470269, lng: 19.5686498 },
    { lat: 49.47031, lng: 19.5687758 },
    { lat: 49.47033, lng: 19.5687878 },
    { lat: 49.470356, lng: 19.5687818 },
    { lat: 49.470384, lng: 19.5687418 },
    { lat: 49.470404, lng: 19.5686388 },
    { lat: 49.470395, lng: 19.5686058 },
    { lat: 49.470399, lng: 19.5685538 },
    { lat: 49.470435, lng: 19.5683888 },
    { lat: 49.470488, lng: 19.5683138 },
    { lat: 49.470475, lng: 19.5681788 },
    { lat: 49.470486, lng: 19.5681038 },
    { lat: 49.47051, lng: 19.5680958 },
    { lat: 49.470535, lng: 19.5680988 },
    { lat: 49.47057, lng: 19.5680468 },
    { lat: 49.470585, lng: 19.5679738 },
    { lat: 49.470613, lng: 19.5679238 },
    { lat: 49.470677, lng: 19.5678688 },
    { lat: 49.470687, lng: 19.5678178 },
    { lat: 49.470771, lng: 19.5677308 },
    { lat: 49.470782, lng: 19.5677068 },
    { lat: 49.470781, lng: 19.5676598 },
    { lat: 49.470747, lng: 19.5676328 },
    { lat: 49.470733, lng: 19.5676368 },
    { lat: 49.470675, lng: 19.5677168 },
    { lat: 49.470649, lng: 19.5677118 },
    { lat: 49.470648, lng: 19.5676518 },
    { lat: 49.470684, lng: 19.5675458 },
    { lat: 49.470739, lng: 19.5674738 },
    { lat: 49.470789, lng: 19.5674528 },
    { lat: 49.470808, lng: 19.5674718 },
    { lat: 49.470812, lng: 19.5675198 },
    { lat: 49.470799, lng: 19.5675458 },
    { lat: 49.470803, lng: 19.5675808 },
    { lat: 49.470866, lng: 19.5676088 },
    { lat: 49.470891, lng: 19.5676008 },
    { lat: 49.470876, lng: 19.5674108 },
    { lat: 49.470908, lng: 19.5673338 },
    { lat: 49.470916, lng: 19.5671488 },
    { lat: 49.470962, lng: 19.5671318 },
    { lat: 49.471013, lng: 19.5670678 },
    { lat: 49.471071, lng: 19.5671078 },
    { lat: 49.471119, lng: 19.5670708 },
    { lat: 49.471124, lng: 19.5669638 },
    { lat: 49.47114, lng: 19.5669128 },
    { lat: 49.471153, lng: 19.5669078 },
    { lat: 49.471226, lng: 19.5669438 },
    { lat: 49.471289, lng: 19.5670198 },
    { lat: 49.4713, lng: 19.5670208 },
    { lat: 49.471289, lng: 19.5671028 },
    { lat: 49.471832, lng: 19.5671998 },
    { lat: 49.472271, lng: 19.5672368 },
    { lat: 49.472406, lng: 19.5671178 },
    { lat: 49.472948, lng: 19.5673978 },
    { lat: 49.473529, lng: 19.5680778 },
    { lat: 49.473754, lng: 19.5678538 },
    { lat: 49.473965, lng: 19.5679898 },
    { lat: 49.474346, lng: 19.5687848 },
    { lat: 49.474488, lng: 19.5690548 },
    { lat: 49.4749, lng: 19.5685348 },
    { lat: 49.47566, lng: 19.5675968 },
    { lat: 49.476132, lng: 19.5673028 },
    { lat: 49.476727, lng: 19.5671938 },
    { lat: 49.477547, lng: 19.5664388 },
    { lat: 49.478065, lng: 19.5660398 },
    { lat: 49.478419, lng: 19.5660428 },
    { lat: 49.478943, lng: 19.5659448 },
    { lat: 49.479506, lng: 19.5659178 },
    { lat: 49.479908, lng: 19.5657888 },
    { lat: 49.480522, lng: 19.5656288 },
    { lat: 49.480736, lng: 19.5653948 },
    { lat: 49.481134, lng: 19.5641918 },
    { lat: 49.481258, lng: 19.5640138 },
    { lat: 49.481555, lng: 19.5634798 },
    { lat: 49.481894, lng: 19.5627998 },
    { lat: 49.482229, lng: 19.5620208 },
    { lat: 49.482955, lng: 19.5615898 },
    { lat: 49.483573, lng: 19.5613208 },
    { lat: 49.484238, lng: 19.5610978 },
    { lat: 49.484807, lng: 19.5606878 },
    { lat: 49.485343, lng: 19.5601048 },
    { lat: 49.486228, lng: 19.5593808 },
    { lat: 49.487255, lng: 19.5587638 },
    { lat: 49.487958, lng: 19.5583738 },
    { lat: 49.487666, lng: 19.5581148 },
    { lat: 49.4869, lng: 19.5574368 },
    { lat: 49.486855, lng: 19.5573958 },
    { lat: 49.4869, lng: 19.5573348 },
    { lat: 49.48692, lng: 19.5573598 },
    { lat: 49.486925, lng: 19.5573768 },
    { lat: 49.486974, lng: 19.5573888 },
    { lat: 49.487021, lng: 19.5573538 },
    { lat: 49.48705, lng: 19.5573548 },
    { lat: 49.487075, lng: 19.5573498 },
    { lat: 49.487119, lng: 19.5572788 },
    { lat: 49.487156, lng: 19.5572818 },
    { lat: 49.487169, lng: 19.5572788 },
    { lat: 49.487183, lng: 19.5572688 },
    { lat: 49.487204, lng: 19.5571768 },
    { lat: 49.487229, lng: 19.5571108 },
    { lat: 49.487266, lng: 19.5570858 },
    { lat: 49.487273, lng: 19.5570298 },
    { lat: 49.487298, lng: 19.5570218 },
    { lat: 49.487347, lng: 19.5570878 },
    { lat: 49.487418, lng: 19.5570928 },
    { lat: 49.487455, lng: 19.5570498 },
    { lat: 49.487514, lng: 19.5568748 },
    { lat: 49.487515, lng: 19.5568358 },
    { lat: 49.48749, lng: 19.5568298 },
    { lat: 49.48746, lng: 19.5568428 },
    { lat: 49.487441, lng: 19.5568678 },
    { lat: 49.487428, lng: 19.5568598 },
    { lat: 49.487436, lng: 19.5568338 },
    { lat: 49.487429, lng: 19.5567868 },
    { lat: 49.487408, lng: 19.5567618 },
    { lat: 49.487426, lng: 19.5566858 },
    { lat: 49.487518, lng: 19.5566128 },
    { lat: 49.487547, lng: 19.5566448 },
    { lat: 49.487565, lng: 19.5566738 },
    { lat: 49.487595, lng: 19.5566518 },
    { lat: 49.487627, lng: 19.5566488 },
    { lat: 49.487612, lng: 19.5565548 },
    { lat: 49.487637, lng: 19.5565268 },
    { lat: 49.487631, lng: 19.5564278 },
    { lat: 49.487686, lng: 19.5563888 },
    { lat: 49.487701, lng: 19.5564158 },
    { lat: 49.487733, lng: 19.5563698 },
    { lat: 49.487736, lng: 19.5563008 },
    { lat: 49.487752, lng: 19.5562608 },
    { lat: 49.487768, lng: 19.5562688 },
    { lat: 49.487773, lng: 19.5562018 },
    { lat: 49.487809, lng: 19.5561778 },
    { lat: 49.487843, lng: 19.5561878 },
    { lat: 49.487864, lng: 19.5562208 },
    { lat: 49.487921, lng: 19.5561808 },
    { lat: 49.487947, lng: 19.5560978 },
    { lat: 49.487942, lng: 19.5560568 },
    { lat: 49.487975, lng: 19.5559278 },
    { lat: 49.487962, lng: 19.5559188 },
    { lat: 49.487969, lng: 19.5558908 },
    { lat: 49.488012, lng: 19.5558748 },
    { lat: 49.488098, lng: 19.5558748 },
    { lat: 49.48811, lng: 19.5558418 },
    { lat: 49.488092, lng: 19.5558288 },
    { lat: 49.488098, lng: 19.5557608 },
    { lat: 49.488141, lng: 19.5556938 },
    { lat: 49.488189, lng: 19.5556578 },
    { lat: 49.488211, lng: 19.5556548 },
    { lat: 49.488223, lng: 19.5556238 },
    { lat: 49.488218, lng: 19.5556068 },
    { lat: 49.488335, lng: 19.5554908 },
    { lat: 49.488372, lng: 19.5555018 },
    { lat: 49.48842, lng: 19.5555398 },
    { lat: 49.488458, lng: 19.5555278 },
    { lat: 49.488473, lng: 19.5554938 },
    { lat: 49.48848, lng: 19.5554448 },
    { lat: 49.488535, lng: 19.5553748 },
    { lat: 49.488517, lng: 19.5553158 },
    { lat: 49.488528, lng: 19.5552878 },
    { lat: 49.488584, lng: 19.5552838 },
    { lat: 49.488613, lng: 19.5551998 },
    { lat: 49.488638, lng: 19.5551928 },
    { lat: 49.488676, lng: 19.5551388 },
    { lat: 49.488716, lng: 19.5551248 },
    { lat: 49.488729, lng: 19.5550638 },
    { lat: 49.488765, lng: 19.5550308 },
    { lat: 49.488799, lng: 19.5550568 },
    { lat: 49.488848, lng: 19.5550678 },
    { lat: 49.488868, lng: 19.5550358 },
    { lat: 49.488888, lng: 19.5549098 },
    { lat: 49.488937, lng: 19.5549178 },
    { lat: 49.488965, lng: 19.5548148 },
    { lat: 49.488944, lng: 19.5547608 },
    { lat: 49.488971, lng: 19.5547098 },
    { lat: 49.489014, lng: 19.5546948 },
    { lat: 49.489061, lng: 19.5546568 },
    { lat: 49.489085, lng: 19.5546708 },
    { lat: 49.489188, lng: 19.5546678 },
    { lat: 49.4892092, lng: 19.5546342 },
    { lat: 49.489244, lng: 19.5546338 },
    { lat: 49.489283, lng: 19.5545968 },
    { lat: 49.4893135, lng: 19.5546106 },
    { lat: 49.489381, lng: 19.5545458 },
    { lat: 49.489442, lng: 19.5545168 },
    { lat: 49.489483, lng: 19.5544468 },
    { lat: 49.489464, lng: 19.5543828 },
    { lat: 49.489492, lng: 19.5542958 },
    { lat: 49.489557, lng: 19.5542788 },
    { lat: 49.489607, lng: 19.5542038 },
    { lat: 49.489641, lng: 19.5542038 },
    { lat: 49.489661, lng: 19.5542268 },
    { lat: 49.489677, lng: 19.5542218 },
    { lat: 49.48968, lng: 19.5541708 },
    { lat: 49.489663, lng: 19.5541348 },
    { lat: 49.489677, lng: 19.5540508 },
    { lat: 49.489715, lng: 19.5540738 },
    { lat: 49.489711, lng: 19.5540138 },
    { lat: 49.489736, lng: 19.5539408 },
    { lat: 49.489746, lng: 19.5538708 },
    { lat: 49.4899145, lng: 19.5537049 },
    { lat: 49.4899404, lng: 19.5536443 },
    { lat: 49.4898039, lng: 19.5535118 },
    { lat: 49.4897146, lng: 19.5534071 },
    { lat: 49.4896364, lng: 19.5533211 },
    { lat: 49.4895554, lng: 19.5532214 },
    { lat: 49.4894692, lng: 19.5531308 },
    { lat: 49.4893639, lng: 19.5530191 },
    { lat: 49.4892456, lng: 19.5528472 },
    { lat: 49.4891225, lng: 19.5526649 },
    { lat: 49.4890064, lng: 19.5525149 },
    { lat: 49.4888919, lng: 19.5523734 },
    { lat: 49.4887998, lng: 19.5522512 },
    { lat: 49.4886124, lng: 19.5520163 },
    { lat: 49.4884773, lng: 19.5518462 },
    { lat: 49.4884104, lng: 19.5517612 },
    { lat: 49.4883111, lng: 19.5516311 },
    { lat: 49.4882046, lng: 19.5514949 },
    { lat: 49.4881841, lng: 19.5514641 },
    { lat: 49.4880656, lng: 19.5513056 },
    { lat: 49.4880238, lng: 19.5512789 },
    { lat: 49.4878911, lng: 19.5511835 },
    { lat: 49.4878804, lng: 19.5511187 },
    { lat: 49.4878432, lng: 19.5508965 },
    { lat: 49.4877936, lng: 19.5505963 },
    { lat: 49.4877541, lng: 19.5503435 },
    { lat: 49.4877199, lng: 19.5501594 },
    { lat: 49.487343, lng: 19.5501686 },
    { lat: 49.4864822, lng: 19.5496681 },
    { lat: 49.4856487, lng: 19.5491455 },
    { lat: 49.4845621, lng: 19.5484791 },
    { lat: 49.4840914, lng: 19.548187 },
    { lat: 49.48403, lng: 19.5481489 },
    { lat: 49.4835814, lng: 19.5478715 },
    { lat: 49.4834899, lng: 19.5477634 },
    { lat: 49.4834613, lng: 19.5477298 },
    { lat: 49.4835952, lng: 19.5476261 },
    { lat: 49.483584, lng: 19.5476038 },
    { lat: 49.4835767, lng: 19.5475879 },
    { lat: 49.4834873, lng: 19.5474057 },
    { lat: 49.483235, lng: 19.5464522 },
    { lat: 49.482784, lng: 19.5458468 },
    { lat: 49.4821991, lng: 19.5450164 },
    { lat: 49.4813016, lng: 19.5435503 },
    { lat: 49.4812792, lng: 19.5435034 },
    { lat: 49.4812382, lng: 19.5434175 },
    { lat: 49.4812182, lng: 19.5433754 },
    { lat: 49.4812123, lng: 19.5433662 },
    { lat: 49.4810324, lng: 19.5430984 },
    { lat: 49.4806607, lng: 19.5425287 },
    { lat: 49.4802132, lng: 19.5418722 },
    { lat: 49.4801415, lng: 19.5417581 },
    { lat: 49.4801234, lng: 19.5417281 },
    { lat: 49.4800564, lng: 19.5416204 },
    { lat: 49.4792563, lng: 19.5403618 },
    { lat: 49.4785523, lng: 19.5393405 },
    { lat: 49.478079, lng: 19.5379379 },
    { lat: 49.4780594, lng: 19.5378806 },
    { lat: 49.4780394, lng: 19.5378084 },
    { lat: 49.4771624, lng: 19.538302 },
    { lat: 49.4769081, lng: 19.5385315 },
    { lat: 49.4766101, lng: 19.5386922 },
    { lat: 49.4754187, lng: 19.5397042 },
    { lat: 49.4748141, lng: 19.5400119 },
    { lat: 49.4743165, lng: 19.5404426 },
    { lat: 49.473922, lng: 19.5406418 },
    { lat: 49.4738297, lng: 19.5407153 },
    { lat: 49.4735914, lng: 19.5409551 },
    { lat: 49.4732079, lng: 19.5412458 },
    { lat: 49.4728023, lng: 19.541616 },
    { lat: 49.4720107, lng: 19.542004 },
    { lat: 49.4715374, lng: 19.5423242 },
    { lat: 49.4712176, lng: 19.5427294 },
    { lat: 49.4709426, lng: 19.5429032 },
    { lat: 49.4704677, lng: 19.543313 },
    { lat: 49.4699805, lng: 19.5436041 },
    { lat: 49.4697349, lng: 19.5436537 },
    { lat: 49.4695382, lng: 19.543684 },
    { lat: 49.4692763, lng: 19.5437973 },
    { lat: 49.4691127, lng: 19.5439715 },
    { lat: 49.4688874, lng: 19.5441686 },
    { lat: 49.4686107, lng: 19.5443309 },
    { lat: 49.4683247, lng: 19.5445841 },
    { lat: 49.4680464, lng: 19.5448838 },
    { lat: 49.4677313, lng: 19.5451621 },
    { lat: 49.4675278, lng: 19.5452986 },
    { lat: 49.467249, lng: 19.545435 },
    { lat: 49.4669734, lng: 19.5456222 },
    { lat: 49.4668421, lng: 19.5457571 },
    { lat: 49.4667845, lng: 19.5458577 },
    { lat: 49.4667466, lng: 19.5459389 },
    { lat: 49.4665568, lng: 19.5461018 },
    { lat: 49.4664326, lng: 19.5462468 },
    { lat: 49.4662859, lng: 19.5463663 },
    { lat: 49.4659452, lng: 19.5466803 },
    { lat: 49.4656837, lng: 19.5468022 },
    { lat: 49.4648272, lng: 19.5469945 },
    { lat: 49.4645918, lng: 19.5471727 },
    { lat: 49.4645482, lng: 19.5472052 },
    { lat: 49.4645251, lng: 19.5471964 },
    { lat: 49.4645093, lng: 19.5471908 },
    { lat: 49.4641823, lng: 19.5471125 },
    { lat: 49.4640409, lng: 19.5471235 },
    { lat: 49.4627968, lng: 19.5459384 },
    { lat: 49.4617676, lng: 19.5449048 },
    { lat: 49.4613377, lng: 19.5444108 },
    { lat: 49.4609392, lng: 19.5438714 },
    { lat: 49.460617, lng: 19.5433706 },
    { lat: 49.4605308, lng: 19.5432808 },
    { lat: 49.4604929, lng: 19.5432407 },
    { lat: 49.4604293, lng: 19.5432797 },
    { lat: 49.4603736, lng: 19.5430929 },
    { lat: 49.4602986, lng: 19.5429784 },
    { lat: 49.4602382, lng: 19.5429899 },
    { lat: 49.4598744, lng: 19.5423414 },
    { lat: 49.4595818, lng: 19.5416738 },
    { lat: 49.4593729, lng: 19.5416902 },
    { lat: 49.4592798, lng: 19.5416979 },
    { lat: 49.4588946, lng: 19.5416736 },
    { lat: 49.4584144, lng: 19.5416949 },
    { lat: 49.4580209, lng: 19.5415599 },
    { lat: 49.4579957, lng: 19.5415514 },
    { lat: 49.4578673, lng: 19.5415031 },
    { lat: 49.457849, lng: 19.5414963 },
    { lat: 49.4578375, lng: 19.5414921 },
    { lat: 49.4577148, lng: 19.5414468 },
    { lat: 49.4575928, lng: 19.541337 },
    { lat: 49.4574381, lng: 19.5411785 },
    { lat: 49.4572646, lng: 19.5409803 },
    { lat: 49.4571397, lng: 19.5408085 },
    { lat: 49.456487, lng: 19.539699 },
    { lat: 49.4562942, lng: 19.5393986 },
    { lat: 49.4559963, lng: 19.539063 },
    { lat: 49.4559796, lng: 19.5390441 },
    { lat: 49.4550726, lng: 19.5381405 },
    { lat: 49.4550394, lng: 19.5381049 },
    { lat: 49.4547859, lng: 19.5378284 },
    { lat: 49.4544994, lng: 19.5374749 },
    { lat: 49.4539182, lng: 19.5366288 },
    { lat: 49.4535446, lng: 19.536239 },
    { lat: 49.4532679, lng: 19.5360246 },
    { lat: 49.4527755, lng: 19.535571 },
    { lat: 49.4520893, lng: 19.5348216 },
    { lat: 49.4511694, lng: 19.5338608 },
    { lat: 49.4507377, lng: 19.5335808 },
    { lat: 49.4506657, lng: 19.5335341 },
    { lat: 49.4499397, lng: 19.5331004 },
    { lat: 49.4497139, lng: 19.5329665 },
    { lat: 49.4494929, lng: 19.5328357 },
    { lat: 49.4492869, lng: 19.5327443 },
    { lat: 49.4490586, lng: 19.5327486 },
    { lat: 49.4489245, lng: 19.5327227 },
    { lat: 49.4487495, lng: 19.5325432 },
    { lat: 49.4485499, lng: 19.5323381 },
    { lat: 49.4484085, lng: 19.5322485 },
    { lat: 49.4482852, lng: 19.5321175 },
    { lat: 49.4472897, lng: 19.5308553 },
    { lat: 49.447201, lng: 19.5306377 },
    { lat: 49.4470614, lng: 19.5302173 },
    { lat: 49.446786, lng: 19.5295818 },
    { lat: 49.446267, lng: 19.5280938 },
    { lat: 49.4460191, lng: 19.527472 },
    { lat: 49.4459683, lng: 19.5273453 },
    { lat: 49.445735, lng: 19.5266705 },
    { lat: 49.445587, lng: 19.526385 },
    { lat: 49.445496, lng: 19.5262669 },
    { lat: 49.4452384, lng: 19.5260524 },
    { lat: 49.4447658, lng: 19.5256147 },
    { lat: 49.4445419, lng: 19.5253007 },
    { lat: 49.4443852, lng: 19.5251975 },
    { lat: 49.4439403, lng: 19.5246529 },
    { lat: 49.4437382, lng: 19.524594 },
    { lat: 49.4433989, lng: 19.5245411 },
    { lat: 49.4429565, lng: 19.5245208 },
    { lat: 49.4425457, lng: 19.5244581 },
    { lat: 49.4425129, lng: 19.5244533 },
    { lat: 49.4420308, lng: 19.5243797 },
    { lat: 49.4419848, lng: 19.5245845 },
    { lat: 49.4412998, lng: 19.5247139 },
    { lat: 49.4409611, lng: 19.5248716 },
    { lat: 49.4404227, lng: 19.5251972 },
    { lat: 49.4402045, lng: 19.5252526 },
    { lat: 49.4398083, lng: 19.5251366 },
    { lat: 49.4395649, lng: 19.5251842 },
    { lat: 49.4393778, lng: 19.5252863 },
    { lat: 49.4391919, lng: 19.5255357 },
    { lat: 49.4390813, lng: 19.5256789 },
    { lat: 49.4388969, lng: 19.52586 },
    { lat: 49.4387739, lng: 19.5260066 },
    { lat: 49.4387492, lng: 19.5260353 },
    { lat: 49.4386991, lng: 19.5260953 },
    { lat: 49.4385262, lng: 19.5256362 },
    { lat: 49.4385109, lng: 19.5254492 },
    { lat: 49.4384699, lng: 19.5252157 },
    { lat: 49.4384342, lng: 19.5251196 },
    { lat: 49.4383253, lng: 19.5248332 },
    { lat: 49.4383002, lng: 19.5244753 },
    { lat: 49.4382246, lng: 19.5243766 },
    { lat: 49.4381399, lng: 19.5240949 },
    { lat: 49.4381392, lng: 19.5240801 },
    { lat: 49.4381343, lng: 19.5239984 },
    { lat: 49.4380552, lng: 19.5239395 },
    { lat: 49.4380115, lng: 19.5238978 },
    { lat: 49.4379018, lng: 19.52379 },
    { lat: 49.4377862, lng: 19.5237763 },
    { lat: 49.4377113, lng: 19.5236872 },
    { lat: 49.4376806, lng: 19.5235551 },
    { lat: 49.4376458, lng: 19.5234864 },
    { lat: 49.4376199, lng: 19.5234359 },
    { lat: 49.4376074, lng: 19.5234094 },
    { lat: 49.4375913, lng: 19.5233774 },
    { lat: 49.4375136, lng: 19.5232775 },
    { lat: 49.4374768, lng: 19.5233093 },
    { lat: 49.4374538, lng: 19.5233287 },
    { lat: 49.4374452, lng: 19.5233358 },
    { lat: 49.4373715, lng: 19.5233987 },
    { lat: 49.4373366, lng: 19.5232996 },
    { lat: 49.4373411, lng: 19.5232748 },
    { lat: 49.4373437, lng: 19.5232583 },
    { lat: 49.4373494, lng: 19.523233 },
    { lat: 49.4373546, lng: 19.5232017 },
    { lat: 49.4373254, lng: 19.5231252 },
    { lat: 49.4373068, lng: 19.5230178 },
    { lat: 49.4372312, lng: 19.522989 },
    { lat: 49.4371785, lng: 19.5228693 },
    { lat: 49.4370177, lng: 19.522952 },
    { lat: 49.4369296, lng: 19.5229972 },
    { lat: 49.4369045, lng: 19.5230107 },
    { lat: 49.436902, lng: 19.5229817 },
    { lat: 49.4368941, lng: 19.52288 },
    { lat: 49.4367748, lng: 19.5229101 },
    { lat: 49.4367236, lng: 19.5229228 },
    { lat: 49.4366767, lng: 19.5229347 },
    { lat: 49.4366599, lng: 19.5228865 },
    { lat: 49.4366416, lng: 19.5228313 },
    { lat: 49.4366195, lng: 19.5227663 },
    { lat: 49.4365577, lng: 19.5227678 },
    { lat: 49.4365115, lng: 19.5227691 },
    { lat: 49.4364609, lng: 19.5226934 },
    { lat: 49.436421, lng: 19.5226354 },
    { lat: 49.4364427, lng: 19.5224951 },
    { lat: 49.4364198, lng: 19.5224375 },
    { lat: 49.4363771, lng: 19.5224865 },
    { lat: 49.4362974, lng: 19.522419 },
    { lat: 49.4362429, lng: 19.5224371 },
    { lat: 49.4362274, lng: 19.5224424 },
    { lat: 49.4361125, lng: 19.5223677 },
    { lat: 49.4360987, lng: 19.5222602 },
    { lat: 49.4360233, lng: 19.5221126 },
    { lat: 49.4357122, lng: 19.5223593 },
    { lat: 49.4355401, lng: 19.5224899 },
    { lat: 49.4352286, lng: 19.5227276 },
    { lat: 49.4351328, lng: 19.5228004 },
    { lat: 49.4344107, lng: 19.5233508 },
    { lat: 49.4340665, lng: 19.5235395 },
    { lat: 49.434022, lng: 19.5235258 },
    { lat: 49.4338794, lng: 19.5234834 },
    { lat: 49.4337731, lng: 19.5234517 },
    { lat: 49.4336692, lng: 19.5234207 },
    { lat: 49.4333562, lng: 19.5233277 },
    { lat: 49.4329363, lng: 19.5231107 },
    { lat: 49.4327265, lng: 19.5230016 },
    { lat: 49.4326975, lng: 19.522987 },
    { lat: 49.4326353, lng: 19.5229549 },
    { lat: 49.432543, lng: 19.5229064 },
    { lat: 49.431889, lng: 19.5232711 },
    { lat: 49.4309447, lng: 19.5233409 },
    { lat: 49.4301533, lng: 19.5229803 },
    { lat: 49.4295525, lng: 19.522725 },
    { lat: 49.4289095, lng: 19.5224105 },
    { lat: 49.4288335, lng: 19.5223733 },
    { lat: 49.4286123, lng: 19.5222651 },
    { lat: 49.4277144, lng: 19.521273 },
    { lat: 49.4267196, lng: 19.5193585 },
    { lat: 49.4264416, lng: 19.5186929 },
    { lat: 49.426415, lng: 19.518628 },
    { lat: 49.4262394, lng: 19.5182073 },
    { lat: 49.4261889, lng: 19.5180885 },
    { lat: 49.4259473, lng: 19.5175087 },
    { lat: 49.4257909, lng: 19.517136 },
    { lat: 49.4254311, lng: 19.5175992 },
    { lat: 49.4248044, lng: 19.5184666 },
    { lat: 49.4241708, lng: 19.519452 },
    { lat: 49.4233642, lng: 19.5202206 },
    { lat: 49.4228364, lng: 19.5210574 },
    { lat: 49.4220616, lng: 19.5222572 },
    { lat: 49.4215766, lng: 19.5236621 },
    { lat: 49.4210806, lng: 19.5249761 },
    { lat: 49.4207029, lng: 19.5261943 },
    { lat: 49.4199873, lng: 19.5276334 },
    { lat: 49.4185011, lng: 19.5295473 },
    { lat: 49.4178302, lng: 19.5294812 },
    { lat: 49.4177428, lng: 19.5293442 },
    { lat: 49.41755, lng: 19.5294407 },
    { lat: 49.4174722, lng: 19.529339 },
    { lat: 49.4175246, lng: 19.5292538 },
    { lat: 49.4175799, lng: 19.5292298 },
    { lat: 49.4175608, lng: 19.5291577 },
    { lat: 49.4173276, lng: 19.5291318 },
    { lat: 49.4172665, lng: 19.5291492 },
    { lat: 49.4171933, lng: 19.5289764 },
    { lat: 49.4172395, lng: 19.5288032 },
    { lat: 49.4171003, lng: 19.5288614 },
    { lat: 49.4171111, lng: 19.528557 },
    { lat: 49.4170035, lng: 19.5285174 },
    { lat: 49.416931, lng: 19.5283862 },
    { lat: 49.4168737, lng: 19.528414 },
    { lat: 49.4168432, lng: 19.528334 },
    { lat: 49.4167635, lng: 19.5283425 },
    { lat: 49.4167023, lng: 19.5282838 },
    { lat: 49.4166422, lng: 19.5280051 },
    { lat: 49.4165004, lng: 19.5279803 },
    { lat: 49.4164172, lng: 19.5280344 },
    { lat: 49.4163523, lng: 19.5279671 },
    { lat: 49.4162592, lng: 19.5280024 },
    { lat: 49.4161873, lng: 19.527858 },
    { lat: 49.4161601, lng: 19.5276536 },
    { lat: 49.4161737, lng: 19.5276105 },
    { lat: 49.4161315, lng: 19.5275648 },
    { lat: 49.4160659, lng: 19.5274939 },
    { lat: 49.4159286, lng: 19.5280162 },
    { lat: 49.4154391, lng: 19.5296471 },
    { lat: 49.4154234, lng: 19.5297037 },
    { lat: 49.4152071, lng: 19.5304993 },
    { lat: 49.4150424, lng: 19.5311653 },
    { lat: 49.4148964, lng: 19.5318007 },
    { lat: 49.414628, lng: 19.5329654 },
    { lat: 49.4153739, lng: 19.5346861 },
    { lat: 49.4157631, lng: 19.5359417 },
    { lat: 49.4156074, lng: 19.5360918 },
    { lat: 49.4153053, lng: 19.5378062 },
    { lat: 49.4153134, lng: 19.5383365 },
    { lat: 49.4153293, lng: 19.5393101 },
    { lat: 49.4152949, lng: 19.5403954 },
    { lat: 49.4152617, lng: 19.5414313 },
    { lat: 49.4151443, lng: 19.5425764 },
    { lat: 49.4151295, lng: 19.5427191 },
    { lat: 49.4150196, lng: 19.543804 },
    { lat: 49.414955, lng: 19.544645 },
    { lat: 49.4148716, lng: 19.5457206 },
    { lat: 49.4145269, lng: 19.5478928 },
    { lat: 49.4142382, lng: 19.5495394 },
    { lat: 49.4134955, lng: 19.5511842 },
    { lat: 49.4129656, lng: 19.5527477 },
    { lat: 49.4124942, lng: 19.5537869 },
    { lat: 49.4123202, lng: 19.5548789 },
    { lat: 49.4125854, lng: 19.5552619 },
    { lat: 49.4128033, lng: 19.5555771 },
    { lat: 49.413559, lng: 19.557087 },
    { lat: 49.413622, lng: 19.557217 },
    { lat: 49.413745, lng: 19.557478 },
    { lat: 49.413755, lng: 19.557497 },
    { lat: 49.413984, lng: 19.558289 },
    { lat: 49.413995, lng: 19.558324 },
    { lat: 49.414346, lng: 19.559108 },
    { lat: 49.414493, lng: 19.5594 },
    { lat: 49.414621, lng: 19.559624 },
    { lat: 49.414874, lng: 19.559816 },
    { lat: 49.414918, lng: 19.559849 },
    { lat: 49.414954, lng: 19.559881 },
    { lat: 49.415278, lng: 19.560173 },
    { lat: 49.415285, lng: 19.560178 },
    { lat: 49.415391, lng: 19.560266 },
    { lat: 49.415501, lng: 19.560358 },
    { lat: 49.415889, lng: 19.560932 },
    { lat: 49.416482, lng: 19.562414 },
    { lat: 49.416841, lng: 19.563313 },
    { lat: 49.41685, lng: 19.563349 },
    { lat: 49.417166, lng: 19.564502 },
    { lat: 49.417663, lng: 19.565192 },
    { lat: 49.418179, lng: 19.565906 },
    { lat: 49.417904, lng: 19.566547 },
    { lat: 49.417826, lng: 19.566736 },
    { lat: 49.417614, lng: 19.567326 },
    { lat: 49.417608, lng: 19.567426 },
    { lat: 49.417601, lng: 19.567785 },
    { lat: 49.417582, lng: 19.568613 },
    { lat: 49.417736, lng: 19.569775 },
    { lat: 49.417859, lng: 19.569779 },
    { lat: 49.418422, lng: 19.569759 },
    { lat: 49.418563, lng: 19.569624 },
    { lat: 49.418796, lng: 19.569645 },
    { lat: 49.419144, lng: 19.569758 },
    { lat: 49.419232, lng: 19.569821 },
    { lat: 49.420046, lng: 19.570236 },
    { lat: 49.420247, lng: 19.570381 },
    { lat: 49.420407, lng: 19.570438 },
    { lat: 49.420769, lng: 19.570726 },
    { lat: 49.420863, lng: 19.570811 },
    { lat: 49.421063, lng: 19.570995 },
    { lat: 49.42122, lng: 19.571167 },
    { lat: 49.42127, lng: 19.571229 },
    { lat: 49.421556, lng: 19.571592 },
    { lat: 49.421802, lng: 19.572044 },
    { lat: 49.422948, lng: 19.573524 },
    { lat: 49.42317, lng: 19.573838 },
    { lat: 49.424906, lng: 19.576403 },
  ],
  Breza: [
    { lat: 49.4227962, lng: 19.3772337 },
    { lat: 49.4230587, lng: 19.3770149 },
    { lat: 49.4233414, lng: 19.3767462 },
    { lat: 49.423598, lng: 19.3764709 },
    { lat: 49.4239555, lng: 19.3761168 },
    { lat: 49.4245852, lng: 19.3755531 },
    { lat: 49.4249122, lng: 19.3753193 },
    { lat: 49.4251761, lng: 19.3751044 },
    { lat: 49.4254251, lng: 19.3750058 },
    { lat: 49.4256375, lng: 19.3740251 },
    { lat: 49.4259665, lng: 19.3735648 },
    { lat: 49.426449, lng: 19.3726295 },
    { lat: 49.4264523, lng: 19.3726237 },
    { lat: 49.426806, lng: 19.3719247 },
    { lat: 49.4273724, lng: 19.3710206 },
    { lat: 49.4276219, lng: 19.3697715 },
    { lat: 49.428328, lng: 19.3676365 },
    { lat: 49.4288695, lng: 19.3657142 },
    { lat: 49.4289184, lng: 19.3648324 },
    { lat: 49.4289222, lng: 19.3647426 },
    { lat: 49.4289173, lng: 19.3637518 },
    { lat: 49.4289161, lng: 19.3635044 },
    { lat: 49.4289785, lng: 19.3626407 },
    { lat: 49.4292893, lng: 19.3618663 },
    { lat: 49.428752, lng: 19.3612432 },
    { lat: 49.4283705, lng: 19.3603076 },
    { lat: 49.4277434, lng: 19.3592361 },
    { lat: 49.4268959, lng: 19.3574018 },
    { lat: 49.4259224, lng: 19.3549921 },
    { lat: 49.424874, lng: 19.3538004 },
    { lat: 49.4248158, lng: 19.3537373 },
    { lat: 49.4245237, lng: 19.3533922 },
    { lat: 49.4244258, lng: 19.3532035 },
    { lat: 49.4244021, lng: 19.3531569 },
    { lat: 49.4240766, lng: 19.3526982 },
    { lat: 49.4240123, lng: 19.3508906 },
    { lat: 49.4236685, lng: 19.3491087 },
    { lat: 49.4232768, lng: 19.3473321 },
    { lat: 49.4220912, lng: 19.343838 },
    { lat: 49.422334, lng: 19.3421907 },
    { lat: 49.4223228, lng: 19.3417855 },
    { lat: 49.4221902, lng: 19.3406142 },
    { lat: 49.4221753, lng: 19.3403223 },
    { lat: 49.4221654, lng: 19.340251 },
    { lat: 49.4221582, lng: 19.3399825 },
    { lat: 49.4220592, lng: 19.3392155 },
    { lat: 49.4220688, lng: 19.3387956 },
    { lat: 49.4220778, lng: 19.3376179 },
    { lat: 49.4222318, lng: 19.3358757 },
    { lat: 49.422158, lng: 19.3354545 },
    { lat: 49.4220637, lng: 19.3344315 },
    { lat: 49.4220039, lng: 19.3341188 },
    { lat: 49.4219919, lng: 19.33331 },
    { lat: 49.4217121, lng: 19.3312884 },
    { lat: 49.4217084, lng: 19.3312541 },
    { lat: 49.421696, lng: 19.3311606 },
    { lat: 49.4216932, lng: 19.3311276 },
    { lat: 49.4216378, lng: 19.3307877 },
    { lat: 49.4209662, lng: 19.3277141 },
    { lat: 49.420957, lng: 19.3276637 },
    { lat: 49.4207499, lng: 19.3267088 },
    { lat: 49.4206271, lng: 19.3263731 },
    { lat: 49.4206704, lng: 19.3263339 },
    { lat: 49.4204388, lng: 19.3259374 },
    { lat: 49.42017, lng: 19.3258303 },
    { lat: 49.4199015, lng: 19.3258708 },
    { lat: 49.419819, lng: 19.3259338 },
    { lat: 49.4195751, lng: 19.32612 },
    { lat: 49.4189174, lng: 19.3273046 },
    { lat: 49.41827, lng: 19.3280808 },
    { lat: 49.418156, lng: 19.3286544 },
    { lat: 49.4180886, lng: 19.329161 },
    { lat: 49.4178199, lng: 19.3301103 },
    { lat: 49.417241, lng: 19.3315319 },
    { lat: 49.4170754, lng: 19.331726 },
    { lat: 49.4168248, lng: 19.3317204 },
    { lat: 49.4166094, lng: 19.3317127 },
    { lat: 49.4163656, lng: 19.3315861 },
    { lat: 49.4162262, lng: 19.331473 },
    { lat: 49.4158831, lng: 19.330956 },
    { lat: 49.4154385, lng: 19.330558 },
    { lat: 49.4152387, lng: 19.3304834 },
    { lat: 49.4148426, lng: 19.3305148 },
    { lat: 49.4139502, lng: 19.330036 },
    { lat: 49.4136707, lng: 19.329892 },
    { lat: 49.4133234, lng: 19.329622 },
    { lat: 49.4126293, lng: 19.3292172 },
    { lat: 49.4119632, lng: 19.3288322 },
    { lat: 49.4116906, lng: 19.3289046 },
    { lat: 49.411301, lng: 19.3292476 },
    { lat: 49.4106503, lng: 19.3302947 },
    { lat: 49.4104721, lng: 19.3310812 },
    { lat: 49.4100531, lng: 19.3317068 },
    { lat: 49.41004, lng: 19.3320307 },
    { lat: 49.4101956, lng: 19.3326805 },
    { lat: 49.4101969, lng: 19.332828 },
    { lat: 49.4101456, lng: 19.3329129 },
    { lat: 49.4096979, lng: 19.3330944 },
    { lat: 49.4095282, lng: 19.3328189 },
    { lat: 49.4094361, lng: 19.3322453 },
    { lat: 49.4091976, lng: 19.3317769 },
    { lat: 49.4086747, lng: 19.3318016 },
    { lat: 49.4084917, lng: 19.3321725 },
    { lat: 49.4081341, lng: 19.3324084 },
    { lat: 49.4080709, lng: 19.332525 },
    { lat: 49.4078221, lng: 19.3324808 },
    { lat: 49.407346, lng: 19.3318476 },
    { lat: 49.4071186, lng: 19.3312991 },
    { lat: 49.4070862, lng: 19.3311373 },
    { lat: 49.4068065, lng: 19.3310132 },
    { lat: 49.406476, lng: 19.3311973 },
    { lat: 49.406179, lng: 19.3316408 },
    { lat: 49.4059338, lng: 19.3318383 },
    { lat: 49.4054752, lng: 19.3317437 },
    { lat: 49.404758, lng: 19.3315409 },
    { lat: 49.4045494, lng: 19.3313718 },
    { lat: 49.4041665, lng: 19.3310178 },
    { lat: 49.403854, lng: 19.3308399 },
    { lat: 49.4036969, lng: 19.3308536 },
    { lat: 49.4034214, lng: 19.3310113 },
    { lat: 49.4032747, lng: 19.3316788 },
    { lat: 49.4031037, lng: 19.3318533 },
    { lat: 49.4030025, lng: 19.3319317 },
    { lat: 49.4028336, lng: 19.33198 },
    { lat: 49.4023783, lng: 19.3323617 },
    { lat: 49.4020638, lng: 19.3323623 },
    { lat: 49.4015269, lng: 19.332157 },
    { lat: 49.401102, lng: 19.3323721 },
    { lat: 49.4008547, lng: 19.332361 },
    { lat: 49.4007229, lng: 19.3322884 },
    { lat: 49.4006308, lng: 19.3321994 },
    { lat: 49.4006295, lng: 19.332075 },
    { lat: 49.4007111, lng: 19.3319922 },
    { lat: 49.4006846, lng: 19.331836 },
    { lat: 49.4005293, lng: 19.3314558 },
    { lat: 49.4003064, lng: 19.3314854 },
    { lat: 49.400161, lng: 19.3316277 },
    { lat: 49.3999327, lng: 19.3320198 },
    { lat: 49.3996878, lng: 19.3324426 },
    { lat: 49.3995347, lng: 19.3327124 },
    { lat: 49.3993525, lng: 19.3334384 },
    { lat: 49.3992688, lng: 19.3336028 },
    { lat: 49.3990245, lng: 19.333916 },
    { lat: 49.3989823, lng: 19.3339568 },
    { lat: 49.3986537, lng: 19.334277 },
    { lat: 49.3982365, lng: 19.3344887 },
    { lat: 49.397288, lng: 19.3345202 },
    { lat: 49.3964432, lng: 19.3343358 },
    { lat: 49.3961228, lng: 19.3341345 },
    { lat: 49.3957992, lng: 19.3336962 },
    { lat: 49.3956152, lng: 19.3336647 },
    { lat: 49.3954279, lng: 19.3339502 },
    { lat: 49.394854, lng: 19.3348076 },
    { lat: 49.3939777, lng: 19.3360665 },
    { lat: 49.3935951, lng: 19.336507 },
    { lat: 49.3931689, lng: 19.3368151 },
    { lat: 49.3929437, lng: 19.3369618 },
    { lat: 49.392652, lng: 19.3371557 },
    { lat: 49.3923906, lng: 19.3373277 },
    { lat: 49.3920768, lng: 19.3378658 },
    { lat: 49.3918285, lng: 19.3395149 },
    { lat: 49.3917421, lng: 19.3405822 },
    { lat: 49.39191, lng: 19.3418067 },
    { lat: 49.3920619, lng: 19.3428608 },
    { lat: 49.3922322, lng: 19.3438738 },
    { lat: 49.3922756, lng: 19.3443419 },
    { lat: 49.3922665, lng: 19.3446515 },
    { lat: 49.3921877, lng: 19.3450859 },
    { lat: 49.3919559, lng: 19.3457192 },
    { lat: 49.3911923, lng: 19.34657 },
    { lat: 49.390399, lng: 19.3476058 },
    { lat: 49.3898769, lng: 19.3483182 },
    { lat: 49.3895881, lng: 19.3485739 },
    { lat: 49.3886373, lng: 19.3493982 },
    { lat: 49.3876972, lng: 19.3504062 },
    { lat: 49.3858463, lng: 19.3522698 },
    { lat: 49.3837868, lng: 19.3543493 },
    { lat: 49.3831623, lng: 19.3550171 },
    { lat: 49.3829288, lng: 19.3553263 },
    { lat: 49.3826478, lng: 19.3558201 },
    { lat: 49.382207, lng: 19.3572147 },
    { lat: 49.3818401, lng: 19.359131 },
    { lat: 49.3817046, lng: 19.3589791 },
    { lat: 49.3816824, lng: 19.3589638 },
    { lat: 49.3816616, lng: 19.3589491 },
    { lat: 49.3815762, lng: 19.3590268 },
    { lat: 49.3812094, lng: 19.3592826 },
    { lat: 49.3811337, lng: 19.3593954 },
    { lat: 49.380993, lng: 19.3594973 },
    { lat: 49.3807575, lng: 19.3596663 },
    { lat: 49.3804687, lng: 19.3597327 },
    { lat: 49.3799189, lng: 19.3605286 },
    { lat: 49.379882, lng: 19.3605826 },
    { lat: 49.3798187, lng: 19.3606727 },
    { lat: 49.3798093, lng: 19.3606889 },
    { lat: 49.3795399, lng: 19.3611691 },
    { lat: 49.3792362, lng: 19.3616197 },
    { lat: 49.3790228, lng: 19.3618728 },
    { lat: 49.3788578, lng: 19.3620811 },
    { lat: 49.3788524, lng: 19.3620878 },
    { lat: 49.3787545, lng: 19.3622114 },
    { lat: 49.3786464, lng: 19.3620196 },
    { lat: 49.3781594, lng: 19.3612192 },
    { lat: 49.3774606, lng: 19.3603222 },
    { lat: 49.3772867, lng: 19.3599764 },
    { lat: 49.3773457, lng: 19.3596028 },
    { lat: 49.3777036, lng: 19.3589134 },
    { lat: 49.3775785, lng: 19.3587477 },
    { lat: 49.3775074, lng: 19.3586459 },
    { lat: 49.3773612, lng: 19.3584384 },
    { lat: 49.3773238, lng: 19.3583854 },
    { lat: 49.3773092, lng: 19.3583621 },
    { lat: 49.3772559, lng: 19.3582822 },
    { lat: 49.3771764, lng: 19.3582439 },
    { lat: 49.3768813, lng: 19.3581027 },
    { lat: 49.3763493, lng: 19.357786 },
    { lat: 49.3758005, lng: 19.357643 },
    { lat: 49.3752303, lng: 19.3579529 },
    { lat: 49.3747319, lng: 19.3583066 },
    { lat: 49.3737492, lng: 19.3588136 },
    { lat: 49.3732374, lng: 19.358856 },
    { lat: 49.3727394, lng: 19.3588813 },
    { lat: 49.37222, lng: 19.3591979 },
    { lat: 49.3719203, lng: 19.3595348 },
    { lat: 49.3716867, lng: 19.3600327 },
    { lat: 49.3714051, lng: 19.3608533 },
    { lat: 49.3709702, lng: 19.3612897 },
    { lat: 49.3703734, lng: 19.3616863 },
    { lat: 49.3698016, lng: 19.3620365 },
    { lat: 49.3692018, lng: 19.3624164 },
    { lat: 49.3685685, lng: 19.3626077 },
    { lat: 49.3679675, lng: 19.3627679 },
    { lat: 49.3677166, lng: 19.3630691 },
    { lat: 49.36769, lng: 19.3630999 },
    { lat: 49.3674696, lng: 19.3633503 },
    { lat: 49.3673717, lng: 19.3634287 },
    { lat: 49.3674842, lng: 19.3648724 },
    { lat: 49.3677159, lng: 19.3657788 },
    { lat: 49.3678222, lng: 19.3665127 },
    { lat: 49.3678301, lng: 19.367354 },
    { lat: 49.3682029, lng: 19.368293 },
    { lat: 49.3682858, lng: 19.368912 },
    { lat: 49.3685229, lng: 19.3696454 },
    { lat: 49.3686817, lng: 19.3703664 },
    { lat: 49.368841, lng: 19.3709564 },
    { lat: 49.3688698, lng: 19.3716453 },
    { lat: 49.3689965, lng: 19.3723058 },
    { lat: 49.3686046, lng: 19.3725312 },
    { lat: 49.3687385, lng: 19.3735193 },
    { lat: 49.3685927, lng: 19.3745227 },
    { lat: 49.3690273, lng: 19.3757721 },
    { lat: 49.3694609, lng: 19.3757425 },
    { lat: 49.3697087, lng: 19.3774824 },
    { lat: 49.3699223, lng: 19.3783171 },
    { lat: 49.3702842, lng: 19.3791411 },
    { lat: 49.3706464, lng: 19.38046 },
    { lat: 49.3709554, lng: 19.3814575 },
    { lat: 49.3713642, lng: 19.3823971 },
    { lat: 49.371568, lng: 19.3826873 },
    { lat: 49.3717561, lng: 19.3829734 },
    { lat: 49.3717958, lng: 19.3830041 },
    { lat: 49.3721847, lng: 19.383248 },
    { lat: 49.3727303, lng: 19.3835208 },
    { lat: 49.3732732, lng: 19.3836329 },
    { lat: 49.3739758, lng: 19.3841579 },
    { lat: 49.3740941, lng: 19.3842756 },
    { lat: 49.3741821, lng: 19.3844005 },
    { lat: 49.3745803, lng: 19.3838294 },
    { lat: 49.3749011, lng: 19.3837521 },
    { lat: 49.3751897, lng: 19.383798 },
    { lat: 49.375535, lng: 19.3838818 },
    { lat: 49.3757551, lng: 19.3840335 },
    { lat: 49.3760189, lng: 19.3841121 },
    { lat: 49.3762651, lng: 19.3841257 },
    { lat: 49.3767254, lng: 19.3842342 },
    { lat: 49.3769294, lng: 19.3843299 },
    { lat: 49.3771804, lng: 19.3844927 },
    { lat: 49.378526, lng: 19.3856383 },
    { lat: 49.3791209, lng: 19.3862392 },
    { lat: 49.3795901, lng: 19.3866151 },
    { lat: 49.3804687, lng: 19.3873355 },
    { lat: 49.381029, lng: 19.3877521 },
    { lat: 49.3814419, lng: 19.3881266 },
    { lat: 49.3817006, lng: 19.3884083 },
    { lat: 49.38156, lng: 19.3886801 },
    { lat: 49.381552, lng: 19.3887978 },
    { lat: 49.3815087, lng: 19.3888813 },
    { lat: 49.3816656, lng: 19.389134 },
    { lat: 49.3816472, lng: 19.3896829 },
    { lat: 49.381776, lng: 19.3904681 },
    { lat: 49.3817857, lng: 19.3907922 },
    { lat: 49.3819836, lng: 19.391367 },
    { lat: 49.3824422, lng: 19.3929908 },
    { lat: 49.3825954, lng: 19.3941087 },
    { lat: 49.3826727, lng: 19.3949476 },
    { lat: 49.3835372, lng: 19.3963385 },
    { lat: 49.3840299, lng: 19.3972172 },
    { lat: 49.3847542, lng: 19.3990317 },
    { lat: 49.3851686, lng: 19.4000692 },
    { lat: 49.3862283, lng: 19.4015655 },
    { lat: 49.3865951, lng: 19.4015994 },
    { lat: 49.3871402, lng: 19.4013534 },
    { lat: 49.3881114, lng: 19.4013739 },
    { lat: 49.3888237, lng: 19.4022285 },
    { lat: 49.3893328, lng: 19.4031964 },
    { lat: 49.3895035, lng: 19.4036705 },
    { lat: 49.3895429, lng: 19.4039849 },
    { lat: 49.3894406, lng: 19.404187 },
    { lat: 49.3897966, lng: 19.4048424 },
    { lat: 49.389982, lng: 19.4055833 },
    { lat: 49.3903381, lng: 19.4064861 },
    { lat: 49.3907693, lng: 19.4073537 },
    { lat: 49.3907742, lng: 19.4073821 },
    { lat: 49.3920481, lng: 19.4068139 },
    { lat: 49.3931571, lng: 19.4063333 },
    { lat: 49.3931734, lng: 19.4062708 },
    { lat: 49.3933845, lng: 19.4060373 },
    { lat: 49.3934674, lng: 19.4059766 },
    { lat: 49.3935771, lng: 19.4059143 },
    { lat: 49.3936869, lng: 19.4058984 },
    { lat: 49.3938837, lng: 19.4058184 },
    { lat: 49.3941868, lng: 19.405695 },
    { lat: 49.3957932, lng: 19.4047657 },
    { lat: 49.3964747, lng: 19.4044459 },
    { lat: 49.3969048, lng: 19.4042638 },
    { lat: 49.3971719, lng: 19.4040271 },
    { lat: 49.3972067, lng: 19.4039966 },
    { lat: 49.3976419, lng: 19.4036113 },
    { lat: 49.3979643, lng: 19.4034185 },
    { lat: 49.3988281, lng: 19.4031668 },
    { lat: 49.3999181, lng: 19.4029599 },
    { lat: 49.4008212, lng: 19.4035534 },
    { lat: 49.4017169, lng: 19.4024559 },
    { lat: 49.4018757, lng: 19.4022609 },
    { lat: 49.4021251, lng: 19.4019559 },
    { lat: 49.4025227, lng: 19.4017831 },
    { lat: 49.4025575, lng: 19.4016049 },
    { lat: 49.4030851, lng: 19.40144 },
    { lat: 49.4038624, lng: 19.4007327 },
    { lat: 49.405471, lng: 19.3988523 },
    { lat: 49.4058356, lng: 19.3986494 },
    { lat: 49.4061595, lng: 19.3981991 },
    { lat: 49.4061615, lng: 19.3980443 },
    { lat: 49.4066597, lng: 19.3971528 },
    { lat: 49.4066732, lng: 19.3971285 },
    { lat: 49.406692, lng: 19.3970964 },
    { lat: 49.4067577, lng: 19.3969793 },
    { lat: 49.4067753, lng: 19.3969473 },
    { lat: 49.40682, lng: 19.3967709 },
    { lat: 49.4071691, lng: 19.3961308 },
    { lat: 49.407991, lng: 19.3947574 },
    { lat: 49.4089104, lng: 19.3925743 },
    { lat: 49.4093089, lng: 19.3918606 },
    { lat: 49.4098318, lng: 19.3905012 },
    { lat: 49.4099273, lng: 19.3892565 },
    { lat: 49.410094, lng: 19.3885059 },
    { lat: 49.4102458, lng: 19.3875285 },
    { lat: 49.4113157, lng: 19.3858177 },
    { lat: 49.4121224, lng: 19.3847735 },
    { lat: 49.4134774, lng: 19.3839375 },
    { lat: 49.4149965, lng: 19.3829247 },
    { lat: 49.4158899, lng: 19.3821728 },
    { lat: 49.4160347, lng: 19.3819394 },
    { lat: 49.4164666, lng: 19.3818749 },
    { lat: 49.4168329, lng: 19.3816828 },
    { lat: 49.4169616, lng: 19.3815215 },
    { lat: 49.4173261, lng: 19.3803922 },
    { lat: 49.4175787, lng: 19.3792596 },
    { lat: 49.4176433, lng: 19.3783709 },
    { lat: 49.4178233, lng: 19.3771786 },
    { lat: 49.4179712, lng: 19.3763401 },
    { lat: 49.4187505, lng: 19.3731827 },
    { lat: 49.4198661, lng: 19.3722889 },
    { lat: 49.4204032, lng: 19.3719883 },
    { lat: 49.4209565, lng: 19.3715089 },
    { lat: 49.4215402, lng: 19.3711685 },
    { lat: 49.421778, lng: 19.3710487 },
    { lat: 49.4218403, lng: 19.3716337 },
    { lat: 49.4221149, lng: 19.3725212 },
    { lat: 49.4220918, lng: 19.3729472 },
    { lat: 49.42232, lng: 19.3739481 },
    { lat: 49.4223294, lng: 19.3745606 },
    { lat: 49.4225182, lng: 19.3760305 },
    { lat: 49.4226237, lng: 19.3763338 },
    { lat: 49.422638, lng: 19.3767866 },
    { lat: 49.4227962, lng: 19.3772337 },
  ],
  Klin: [
    { lat: 49.4638683, lng: 19.4286336 },
    { lat: 49.4638671, lng: 19.4287555 },
    { lat: 49.4639096, lng: 19.4288414 },
    { lat: 49.4638398, lng: 19.4290185 },
    { lat: 49.4638796, lng: 19.429124 },
    { lat: 49.4639482, lng: 19.4291933 },
    { lat: 49.4639839, lng: 19.4293065 },
    { lat: 49.4639816, lng: 19.4294784 },
    { lat: 49.4639996, lng: 19.4295496 },
    { lat: 49.4640564, lng: 19.4297597 },
    { lat: 49.4639959, lng: 19.4299634 },
    { lat: 49.4640029, lng: 19.4301343 },
    { lat: 49.4639042, lng: 19.4301954 },
    { lat: 49.4637782, lng: 19.430347 },
    { lat: 49.4637955, lng: 19.4306516 },
    { lat: 49.4638524, lng: 19.4306357 },
    { lat: 49.4638607, lng: 19.4307174 },
    { lat: 49.4639183, lng: 19.4307698 },
    { lat: 49.4639707, lng: 19.4309536 },
    { lat: 49.4639543, lng: 19.4310119 },
    { lat: 49.4638833, lng: 19.4310395 },
    { lat: 49.4639013, lng: 19.4312817 },
    { lat: 49.4638939, lng: 19.4316136 },
    { lat: 49.4639403, lng: 19.4315912 },
    { lat: 49.4639662, lng: 19.4316858 },
    { lat: 49.4639524, lng: 19.4318282 },
    { lat: 49.4639829, lng: 19.4318553 },
    { lat: 49.4640614, lng: 19.431774 },
    { lat: 49.4641166, lng: 19.4317992 },
    { lat: 49.4641214, lng: 19.4320722 },
    { lat: 49.4641359, lng: 19.4322441 },
    { lat: 49.4640636, lng: 19.43229 },
    { lat: 49.4640414, lng: 19.4323927 },
    { lat: 49.4640949, lng: 19.4324764 },
    { lat: 49.4640919, lng: 19.4325608 },
    { lat: 49.4640007, lng: 19.4326112 },
    { lat: 49.4639979, lng: 19.432647 },
    { lat: 49.464067, lng: 19.4327026 },
    { lat: 49.4640974, lng: 19.4327525 },
    { lat: 49.4640314, lng: 19.4328111 },
    { lat: 49.4639143, lng: 19.4328626 },
    { lat: 49.4640101, lng: 19.4330091 },
    { lat: 49.4639354, lng: 19.4331512 },
    { lat: 49.4639779, lng: 19.4333147 },
    { lat: 49.4638755, lng: 19.4333174 },
    { lat: 49.4637963, lng: 19.4333821 },
    { lat: 49.4637907, lng: 19.433507 },
    { lat: 49.4638168, lng: 19.4336317 },
    { lat: 49.4638921, lng: 19.4336771 },
    { lat: 49.4639181, lng: 19.4338265 },
    { lat: 49.4640535, lng: 19.4338803 },
    { lat: 49.4640663, lng: 19.4340825 },
    { lat: 49.4639477, lng: 19.4341 },
    { lat: 49.4638807, lng: 19.4341634 },
    { lat: 49.4638903, lng: 19.4342225 },
    { lat: 49.4639885, lng: 19.4342539 },
    { lat: 49.4640082, lng: 19.4343119 },
    { lat: 49.4639543, lng: 19.4344398 },
    { lat: 49.4639142, lng: 19.4345999 },
    { lat: 49.4639979, lng: 19.4348285 },
    { lat: 49.463913, lng: 19.4350344 },
    { lat: 49.4638472, lng: 19.435273 },
    { lat: 49.4639696, lng: 19.4355104 },
    { lat: 49.4639304, lng: 19.4356331 },
    { lat: 49.4639942, lng: 19.4357483 },
    { lat: 49.4639318, lng: 19.4358123 },
    { lat: 49.4640093, lng: 19.4360759 },
    { lat: 49.4639512, lng: 19.4363314 },
    { lat: 49.4640364, lng: 19.4363994 },
    { lat: 49.4640734, lng: 19.4364693 },
    { lat: 49.4640646, lng: 19.4365705 },
    { lat: 49.4640241, lng: 19.4366952 },
    { lat: 49.464006, lng: 19.4368417 },
    { lat: 49.4641211, lng: 19.4368702 },
    { lat: 49.4641782, lng: 19.4370524 },
    { lat: 49.4643563, lng: 19.4373729 },
    { lat: 49.4643585, lng: 19.4375447 },
    { lat: 49.4644276, lng: 19.4377991 },
    { lat: 49.4645939, lng: 19.4382267 },
    { lat: 49.4647547, lng: 19.4384172 },
    { lat: 49.4647982, lng: 19.4386299 },
    { lat: 49.4648605, lng: 19.4387836 },
    { lat: 49.4649457, lng: 19.4387765 },
    { lat: 49.4649773, lng: 19.4389716 },
    { lat: 49.4648551, lng: 19.4390812 },
    { lat: 49.4649528, lng: 19.4392489 },
    { lat: 49.4650339, lng: 19.439272 },
    { lat: 49.4650215, lng: 19.4393936 },
    { lat: 49.4651211, lng: 19.4395987 },
    { lat: 49.4651203, lng: 19.4397035 },
    { lat: 49.465076, lng: 19.4397731 },
    { lat: 49.4650701, lng: 19.4398658 },
    { lat: 49.465019, lng: 19.4398528 },
    { lat: 49.4650106, lng: 19.4399686 },
    { lat: 49.4649314, lng: 19.4401289 },
    { lat: 49.4648881, lng: 19.4401423 },
    { lat: 49.4648671, lng: 19.4402978 },
    { lat: 49.4649163, lng: 19.4403414 },
    { lat: 49.4649209, lng: 19.4404416 },
    { lat: 49.4648818, lng: 19.4405194 },
    { lat: 49.4648875, lng: 19.440818 },
    { lat: 49.4648182, lng: 19.4409537 },
    { lat: 49.4647841, lng: 19.4411693 },
    { lat: 49.4648042, lng: 19.4413338 },
    { lat: 49.4647797, lng: 19.4414621 },
    { lat: 49.4646854, lng: 19.4414459 },
    { lat: 49.4646467, lng: 19.4414877 },
    { lat: 49.464604, lng: 19.4416358 },
    { lat: 49.4646342, lng: 19.4417803 },
    { lat: 49.4645863, lng: 19.4418877 },
    { lat: 49.4645987, lng: 19.4419638 },
    { lat: 49.4645377, lng: 19.4420798 },
    { lat: 49.4645133, lng: 19.4422107 },
    { lat: 49.4645965, lng: 19.4423578 },
    { lat: 49.4645608, lng: 19.4424392 },
    { lat: 49.4644967, lng: 19.4424607 },
    { lat: 49.4644334, lng: 19.442461 },
    { lat: 49.4644588, lng: 19.4426191 },
    { lat: 49.4644345, lng: 19.4428046 },
    { lat: 49.4644273, lng: 19.4429644 },
    { lat: 49.4643587, lng: 19.4430657 },
    { lat: 49.4644601, lng: 19.4433601 },
    { lat: 49.4644431, lng: 19.4435296 },
    { lat: 49.4644736, lng: 19.4436042 },
    { lat: 49.4644477, lng: 19.4436567 },
    { lat: 49.4644317, lng: 19.4437707 },
    { lat: 49.4644521, lng: 19.4438239 },
    { lat: 49.46443, lng: 19.4439299 },
    { lat: 49.4644718, lng: 19.4440569 },
    { lat: 49.4644736, lng: 19.444195 },
    { lat: 49.4644243, lng: 19.4442971 },
    { lat: 49.4644035, lng: 19.4444098 },
    { lat: 49.4643102, lng: 19.4444616 },
    { lat: 49.4642914, lng: 19.4446433 },
    { lat: 49.4642424, lng: 19.4447526 },
    { lat: 49.4642422, lng: 19.4447953 },
    { lat: 49.4640105, lng: 19.4448809 },
    { lat: 49.4639137, lng: 19.4449522 },
    { lat: 49.4637579, lng: 19.4451172 },
    { lat: 49.4636491, lng: 19.4452517 },
    { lat: 49.4635465, lng: 19.4453259 },
    { lat: 49.4635045, lng: 19.4454916 },
    { lat: 49.4634375, lng: 19.4455539 },
    { lat: 49.4631883, lng: 19.4455729 },
    { lat: 49.4631116, lng: 19.4456703 },
    { lat: 49.4629224, lng: 19.4456961 },
    { lat: 49.4629366, lng: 19.4457584 },
    { lat: 49.4608173, lng: 19.4468415 },
    { lat: 49.4607653, lng: 19.4468602 },
    { lat: 49.4607365, lng: 19.4468713 },
    { lat: 49.4599442, lng: 19.4471604 },
    { lat: 49.4561825, lng: 19.4485455 },
    { lat: 49.4560899, lng: 19.4485856 },
    { lat: 49.4556829, lng: 19.4487045 },
    { lat: 49.4551812, lng: 19.4487732 },
    { lat: 49.4548801, lng: 19.4489621 },
    { lat: 49.453962, lng: 19.4492627 },
    { lat: 49.4532226, lng: 19.4495261 },
    { lat: 49.4526271, lng: 19.4499566 },
    { lat: 49.4521203, lng: 19.4504274 },
    { lat: 49.4520436, lng: 19.4505712 },
    { lat: 49.4516518, lng: 19.4509195 },
    { lat: 49.450738, lng: 19.4512423 },
    { lat: 49.4494792, lng: 19.4513572 },
    { lat: 49.4485898, lng: 19.451815 },
    { lat: 49.4485468, lng: 19.4518612 },
    { lat: 49.4484752, lng: 19.451869 },
    { lat: 49.4484572, lng: 19.4518691 },
    { lat: 49.4483978, lng: 19.4518748 },
    { lat: 49.4473543, lng: 19.4524375 },
    { lat: 49.447092, lng: 19.4525151 },
    { lat: 49.4463862, lng: 19.4526054 },
    { lat: 49.4461099, lng: 19.4530158 },
    { lat: 49.4458892, lng: 19.4532387 },
    { lat: 49.4452623, lng: 19.4539437 },
    { lat: 49.4452505, lng: 19.4539797 },
    { lat: 49.4447533, lng: 19.4544639 },
    { lat: 49.4445647, lng: 19.4547268 },
    { lat: 49.4443336, lng: 19.455141 },
    { lat: 49.4441666, lng: 19.4555765 },
    { lat: 49.4440057, lng: 19.4558524 },
    { lat: 49.442291, lng: 19.458485 },
    { lat: 49.439729, lng: 19.4622507 },
    { lat: 49.4397046, lng: 19.4623123 },
    { lat: 49.4396533, lng: 19.4626912 },
    { lat: 49.4396263, lng: 19.4630597 },
    { lat: 49.439543, lng: 19.4632834 },
    { lat: 49.4396011, lng: 19.4636392 },
    { lat: 49.4394612, lng: 19.4643016 },
    { lat: 49.4391692, lng: 19.4647885 },
    { lat: 49.4390359, lng: 19.4652019 },
    { lat: 49.4388734, lng: 19.4654895 },
    { lat: 49.4386031, lng: 19.4658879 },
    { lat: 49.4385353, lng: 19.4660787 },
    { lat: 49.4380554, lng: 19.4663023 },
    { lat: 49.4377063, lng: 19.4666752 },
    { lat: 49.4371759, lng: 19.4668271 },
    { lat: 49.4369298, lng: 19.4669177 },
    { lat: 49.4367532, lng: 19.4670474 },
    { lat: 49.4366055, lng: 19.4674149 },
    { lat: 49.4363213, lng: 19.4676297 },
    { lat: 49.4359819, lng: 19.4676471 },
    { lat: 49.4357508, lng: 19.467891 },
    { lat: 49.4354732, lng: 19.4678596 },
    { lat: 49.4352708, lng: 19.4682091 },
    { lat: 49.435236, lng: 19.4683887 },
    { lat: 49.4349277, lng: 19.4684458 },
    { lat: 49.4346976, lng: 19.4687117 },
    { lat: 49.434196, lng: 19.4693702 },
    { lat: 49.4337496, lng: 19.4695626 },
    { lat: 49.4334997, lng: 19.4698636 },
    { lat: 49.4332392, lng: 19.4702025 },
    { lat: 49.4331047, lng: 19.4705653 },
    { lat: 49.4331623, lng: 19.4709109 },
    { lat: 49.4327446, lng: 19.4710181 },
    { lat: 49.4324931, lng: 19.4712805 },
    { lat: 49.4323756, lng: 19.4715458 },
    { lat: 49.4322213, lng: 19.4717702 },
    { lat: 49.4320531, lng: 19.4717636 },
    { lat: 49.4318514, lng: 19.4715659 },
    { lat: 49.4317256, lng: 19.4716953 },
    { lat: 49.4315189, lng: 19.4717085 },
    { lat: 49.4311709, lng: 19.471689 },
    { lat: 49.4308023, lng: 19.4720277 },
    { lat: 49.4305258, lng: 19.4720422 },
    { lat: 49.430395, lng: 19.4718213 },
    { lat: 49.4302282, lng: 19.4714979 },
    { lat: 49.4299947, lng: 19.4714683 },
    { lat: 49.4297299, lng: 19.4716145 },
    { lat: 49.4296213, lng: 19.4719508 },
    { lat: 49.4293959, lng: 19.4719982 },
    { lat: 49.4293041, lng: 19.472015 },
    { lat: 49.4291284, lng: 19.4719611 },
    { lat: 49.4291024, lng: 19.4718879 },
    { lat: 49.4288753, lng: 19.4717765 },
    { lat: 49.4285549, lng: 19.4721103 },
    { lat: 49.4274013, lng: 19.4735468 },
    { lat: 49.4270527, lng: 19.4738283 },
    { lat: 49.4266891, lng: 19.4741323 },
    { lat: 49.4263943, lng: 19.4744597 },
    { lat: 49.4260854, lng: 19.4748031 },
    { lat: 49.4260021, lng: 19.4748044 },
    { lat: 49.4258815, lng: 19.4748062 },
    { lat: 49.4257409, lng: 19.4748082 },
    { lat: 49.4256773, lng: 19.4748223 },
    { lat: 49.4252614, lng: 19.4751663 },
    { lat: 49.4248287, lng: 19.4755089 },
    { lat: 49.4247993, lng: 19.4755319 },
    { lat: 49.4245649, lng: 19.4756042 },
    { lat: 49.4244983, lng: 19.4756247 },
    { lat: 49.4241831, lng: 19.4753607 },
    { lat: 49.4241809, lng: 19.4753591 },
    { lat: 49.4240762, lng: 19.4752379 },
    { lat: 49.4238952, lng: 19.4750286 },
    { lat: 49.4238504, lng: 19.4750566 },
    { lat: 49.4236051, lng: 19.475211 },
    { lat: 49.4234954, lng: 19.4752804 },
    { lat: 49.4234701, lng: 19.47529 },
    { lat: 49.4232861, lng: 19.4753604 },
    { lat: 49.4232573, lng: 19.4753711 },
    { lat: 49.4230968, lng: 19.4754328 },
    { lat: 49.4230252, lng: 19.475468 },
    { lat: 49.4229174, lng: 19.4755212 },
    { lat: 49.4229095, lng: 19.475525 },
    { lat: 49.4228049, lng: 19.4755764 },
    { lat: 49.4227839, lng: 19.4755866 },
    { lat: 49.422665, lng: 19.475645 },
    { lat: 49.4226516, lng: 19.4756522 },
    { lat: 49.4224151, lng: 19.4757525 },
    { lat: 49.4220197, lng: 19.4760209 },
    { lat: 49.421781, lng: 19.4763219 },
    { lat: 49.4216109, lng: 19.4767951 },
    { lat: 49.4215421, lng: 19.4772368 },
    { lat: 49.4215498, lng: 19.4775241 },
    { lat: 49.4215566, lng: 19.4777702 },
    { lat: 49.421583, lng: 19.4783023 },
    { lat: 49.4216278, lng: 19.4788378 },
    { lat: 49.4216444, lng: 19.479177 },
    { lat: 49.4217119, lng: 19.4794973 },
    { lat: 49.4218841, lng: 19.4800105 },
    { lat: 49.4219133, lng: 19.4803287 },
    { lat: 49.4218367, lng: 19.4806001 },
    { lat: 49.4216563, lng: 19.4808684 },
    { lat: 49.4216082, lng: 19.4810245 },
    { lat: 49.4218, lng: 19.4813988 },
    { lat: 49.4220632, lng: 19.4817871 },
    { lat: 49.4222188, lng: 19.482107 },
    { lat: 49.4221612, lng: 19.4825276 },
    { lat: 49.4219304, lng: 19.4830471 },
    { lat: 49.4221422, lng: 19.4834439 },
    { lat: 49.4220772, lng: 19.4838931 },
    { lat: 49.42189, lng: 19.4840131 },
    { lat: 49.421782, lng: 19.4845126 },
    { lat: 49.4216087, lng: 19.4848645 },
    { lat: 49.4217113, lng: 19.4852096 },
    { lat: 49.4215334, lng: 19.4854075 },
    { lat: 49.4214297, lng: 19.4862724 },
    { lat: 49.4214402, lng: 19.4864551 },
    { lat: 49.4213153, lng: 19.4869006 },
    { lat: 49.4210883, lng: 19.4875333 },
    { lat: 49.4210969, lng: 19.4877952 },
    { lat: 49.4212501, lng: 19.4880169 },
    { lat: 49.4212384, lng: 19.4881086 },
    { lat: 49.4212008, lng: 19.4884174 },
    { lat: 49.4211372, lng: 19.4887058 },
    { lat: 49.4211672, lng: 19.4888455 },
    { lat: 49.4211859, lng: 19.4889355 },
    { lat: 49.4211872, lng: 19.4889632 },
    { lat: 49.4211894, lng: 19.4890324 },
    { lat: 49.4211908, lng: 19.489062 },
    { lat: 49.4212351, lng: 19.4891477 },
    { lat: 49.421361, lng: 19.4894871 },
    { lat: 49.4214238, lng: 19.489653 },
    { lat: 49.4214894, lng: 19.489832 },
    { lat: 49.4216496, lng: 19.4900316 },
    { lat: 49.4222814, lng: 19.4907935 },
    { lat: 49.423362, lng: 19.4921041 },
    { lat: 49.4246702, lng: 19.4936181 },
    { lat: 49.4249415, lng: 19.4939351 },
    { lat: 49.4249581, lng: 19.4939514 },
    { lat: 49.4253353, lng: 19.4944431 },
    { lat: 49.425442, lng: 19.4945569 },
    { lat: 49.4258847, lng: 19.4950293 },
    { lat: 49.4264036, lng: 19.4955822 },
    { lat: 49.4266389, lng: 19.4958752 },
    { lat: 49.4266603, lng: 19.4959008 },
    { lat: 49.426883, lng: 19.4961394 },
    { lat: 49.4276448, lng: 19.4969363 },
    { lat: 49.4279821, lng: 19.4972941 },
    { lat: 49.4280178, lng: 19.4973358 },
    { lat: 49.4280535, lng: 19.497378 },
    { lat: 49.4288245, lng: 19.4983003 },
    { lat: 49.4297111, lng: 19.4994116 },
    { lat: 49.429809, lng: 19.4995347 },
    { lat: 49.429827, lng: 19.4995563 },
    { lat: 49.4298568, lng: 19.4995944 },
    { lat: 49.4301954, lng: 19.5000252 },
    { lat: 49.4303101, lng: 19.5001707 },
    { lat: 49.4304264, lng: 19.5004015 },
    { lat: 49.430508, lng: 19.5005615 },
    { lat: 49.4305254, lng: 19.5005962 },
    { lat: 49.4311009, lng: 19.5015533 },
    { lat: 49.4312897, lng: 19.5017709 },
    { lat: 49.4315294, lng: 19.5020117 },
    { lat: 49.4315516, lng: 19.5020264 },
    { lat: 49.4315655, lng: 19.5020361 },
    { lat: 49.4316107, lng: 19.5020653 },
    { lat: 49.431737, lng: 19.5021419 },
    { lat: 49.4324073, lng: 19.5025583 },
    { lat: 49.432518, lng: 19.5026666 },
    { lat: 49.432612, lng: 19.5027771 },
    { lat: 49.4326395, lng: 19.5028093 },
    { lat: 49.4326786, lng: 19.5028543 },
    { lat: 49.4328646, lng: 19.5030299 },
    { lat: 49.433079, lng: 19.5032087 },
    { lat: 49.4332246, lng: 19.5033162 },
    { lat: 49.4333302, lng: 19.5033845 },
    { lat: 49.4334171, lng: 19.5034364 },
    { lat: 49.4335411, lng: 19.503484 },
    { lat: 49.43372, lng: 19.5035561 },
    { lat: 49.4337986, lng: 19.5035705 },
    { lat: 49.4341914, lng: 19.5037884 },
    { lat: 49.4342631, lng: 19.5038289 },
    { lat: 49.4343336, lng: 19.5038681 },
    { lat: 49.4343843, lng: 19.5038924 },
    { lat: 49.4344524, lng: 19.5039249 },
    { lat: 49.4344755, lng: 19.5039409 },
    { lat: 49.4345102, lng: 19.5040078 },
    { lat: 49.4345398, lng: 19.5040662 },
    { lat: 49.4346405, lng: 19.504147 },
    { lat: 49.4347019, lng: 19.504164 },
    { lat: 49.4347365, lng: 19.5041809 },
    { lat: 49.4347584, lng: 19.5041909 },
    { lat: 49.4347792, lng: 19.504201 },
    { lat: 49.4348084, lng: 19.5042007 },
    { lat: 49.4348264, lng: 19.504201 },
    { lat: 49.4348975, lng: 19.5042014 },
    { lat: 49.435141, lng: 19.504276 },
    { lat: 49.4354061, lng: 19.5044113 },
    { lat: 49.435763, lng: 19.5044043 },
    { lat: 49.4362535, lng: 19.5044898 },
    { lat: 49.4366489, lng: 19.5043378 },
    { lat: 49.4368217, lng: 19.5042712 },
    { lat: 49.436825, lng: 19.5042249 },
    { lat: 49.4368385, lng: 19.5041467 },
    { lat: 49.4368584, lng: 19.5040392 },
    { lat: 49.4368762, lng: 19.5039378 },
    { lat: 49.4380864, lng: 19.504337 },
    { lat: 49.4385178, lng: 19.5042815 },
    { lat: 49.4385558, lng: 19.5042767 },
    { lat: 49.4399174, lng: 19.504662 },
    { lat: 49.441059, lng: 19.5044187 },
    { lat: 49.4412403, lng: 19.5043931 },
    { lat: 49.4423996, lng: 19.5037717 },
    { lat: 49.4424823, lng: 19.5037311 },
    { lat: 49.4432249, lng: 19.5033642 },
    { lat: 49.443234, lng: 19.5033668 },
    { lat: 49.4432352, lng: 19.5033671 },
    { lat: 49.4432188, lng: 19.5032566 },
    { lat: 49.4432244, lng: 19.5032295 },
    { lat: 49.4432625, lng: 19.5030242 },
    { lat: 49.4437726, lng: 19.501286 },
    { lat: 49.4438451, lng: 19.5009701 },
    { lat: 49.4439068, lng: 19.5004935 },
    { lat: 49.4439565, lng: 19.5002738 },
    { lat: 49.4440451, lng: 19.500067 },
    { lat: 49.4443136, lng: 19.499607 },
    { lat: 49.4444118, lng: 19.49946 },
    { lat: 49.4444529, lng: 19.4993984 },
    { lat: 49.4445939, lng: 19.4992277 },
    { lat: 49.4452428, lng: 19.4985832 },
    { lat: 49.4453617, lng: 19.4984195 },
    { lat: 49.4454798, lng: 19.498094 },
    { lat: 49.4459503, lng: 19.496822 },
    { lat: 49.446048, lng: 19.4966439 },
    { lat: 49.4462141, lng: 19.4964548 },
    { lat: 49.4462944, lng: 19.4963112 },
    { lat: 49.4463359, lng: 19.496159 },
    { lat: 49.4464485, lng: 19.4956389 },
    { lat: 49.4464618, lng: 19.4956306 },
    { lat: 49.4464868, lng: 19.4956149 },
    { lat: 49.4465121, lng: 19.4955999 },
    { lat: 49.4476462, lng: 19.4948984 },
    { lat: 49.4481862, lng: 19.4945574 },
    { lat: 49.4500377, lng: 19.4935512 },
    { lat: 49.4504237, lng: 19.4932929 },
    { lat: 49.4515845, lng: 19.4921405 },
    { lat: 49.453114, lng: 19.4904216 },
    { lat: 49.4533016, lng: 19.490211 },
    { lat: 49.453809, lng: 19.4887355 },
    { lat: 49.4539415, lng: 19.4887257 },
    { lat: 49.454193, lng: 19.4887074 },
    { lat: 49.4542496, lng: 19.4882189 },
    { lat: 49.4549477, lng: 19.4868703 },
    { lat: 49.4560231, lng: 19.4857464 },
    { lat: 49.4562317, lng: 19.4854067 },
    { lat: 49.4564117, lng: 19.4852759 },
    { lat: 49.456713, lng: 19.4850639 },
    { lat: 49.457002, lng: 19.4848772 },
    { lat: 49.4571321, lng: 19.4847422 },
    { lat: 49.4579781, lng: 19.4837761 },
    { lat: 49.458115, lng: 19.4837136 },
    { lat: 49.4582496, lng: 19.4836013 },
    { lat: 49.4585052, lng: 19.4833247 },
    { lat: 49.4588587, lng: 19.4829992 },
    { lat: 49.458876, lng: 19.4829068 },
    { lat: 49.4590336, lng: 19.4826363 },
    { lat: 49.4592639, lng: 19.4823755 },
    { lat: 49.4593157, lng: 19.4822728 },
    { lat: 49.4596915, lng: 19.4818417 },
    { lat: 49.4601019, lng: 19.4813782 },
    { lat: 49.4602823, lng: 19.4811357 },
    { lat: 49.4606493, lng: 19.4806367 },
    { lat: 49.4608435, lng: 19.480375 },
    { lat: 49.4609377, lng: 19.4802149 },
    { lat: 49.4611014, lng: 19.4800245 },
    { lat: 49.4612449, lng: 19.4797139 },
    { lat: 49.4614423, lng: 19.4795946 },
    { lat: 49.461632, lng: 19.4793811 },
    { lat: 49.4622434, lng: 19.4787069 },
    { lat: 49.4625442, lng: 19.4785558 },
    { lat: 49.4626969, lng: 19.4783485 },
    { lat: 49.4629487, lng: 19.4779429 },
    { lat: 49.463047, lng: 19.4777476 },
    { lat: 49.4631304, lng: 19.4776242 },
    { lat: 49.4632394, lng: 19.4773749 },
    { lat: 49.4633292, lng: 19.4773667 },
    { lat: 49.4636283, lng: 19.4770803 },
    { lat: 49.4643189, lng: 19.4767087 },
    { lat: 49.4647388, lng: 19.4763078 },
    { lat: 49.4653165, lng: 19.475981 },
    { lat: 49.4652051, lng: 19.4750743 },
    { lat: 49.4651651, lng: 19.474741 },
    { lat: 49.4650966, lng: 19.4744256 },
    { lat: 49.4650154, lng: 19.4740996 },
    { lat: 49.4647929, lng: 19.4735965 },
    { lat: 49.4646134, lng: 19.4731177 },
    { lat: 49.4643731, lng: 19.4723705 },
    { lat: 49.4643192, lng: 19.472105 },
    { lat: 49.464315, lng: 19.4720848 },
    { lat: 49.4643051, lng: 19.4720456 },
    { lat: 49.464305, lng: 19.4720424 },
    { lat: 49.4642859, lng: 19.4719456 },
    { lat: 49.4641613, lng: 19.4715341 },
    { lat: 49.4640039, lng: 19.4711721 },
    { lat: 49.4636565, lng: 19.4705705 },
    { lat: 49.4633987, lng: 19.4701842 },
    { lat: 49.4631941, lng: 19.4696038 },
    { lat: 49.4629256, lng: 19.4684098 },
    { lat: 49.4629148, lng: 19.4682708 },
    { lat: 49.4627875, lng: 19.4681972 },
    { lat: 49.4629398, lng: 19.4670217 },
    { lat: 49.4629276, lng: 19.4663098 },
    { lat: 49.4628612, lng: 19.4661391 },
    { lat: 49.4627709, lng: 19.4658887 },
    { lat: 49.4627237, lng: 19.4656211 },
    { lat: 49.462705, lng: 19.4652387 },
    { lat: 49.4626576, lng: 19.4648936 },
    { lat: 49.4626443, lng: 19.4646023 },
    { lat: 49.4625075, lng: 19.4640995 },
    { lat: 49.4624153, lng: 19.4625761 },
    { lat: 49.4623742, lng: 19.4622915 },
    { lat: 49.4622492, lng: 19.4617007 },
    { lat: 49.4620953, lng: 19.4611001 },
    { lat: 49.4618556, lng: 19.4593605 },
    { lat: 49.4618204, lng: 19.458788 },
    { lat: 49.4617434, lng: 19.4581135 },
    { lat: 49.4617908, lng: 19.4576476 },
    { lat: 49.4618003, lng: 19.4575861 },
    { lat: 49.4618596, lng: 19.4571852 },
    { lat: 49.4620777, lng: 19.4565093 },
    { lat: 49.4624038, lng: 19.4558048 },
    { lat: 49.4625391, lng: 19.4555884 },
    { lat: 49.4627998, lng: 19.4552767 },
    { lat: 49.4629307, lng: 19.4549894 },
    { lat: 49.4629733, lng: 19.454812 },
    { lat: 49.463135, lng: 19.4544548 },
    { lat: 49.4633181, lng: 19.4534585 },
    { lat: 49.4635757, lng: 19.4528359 },
    { lat: 49.4635953, lng: 19.4525541 },
    { lat: 49.4635932, lng: 19.4523584 },
    { lat: 49.4637053, lng: 19.4516823 },
    { lat: 49.4637218, lng: 19.4514516 },
    { lat: 49.4637596, lng: 19.4510972 },
    { lat: 49.4637765, lng: 19.450924 },
    { lat: 49.4639244, lng: 19.4501438 },
    { lat: 49.4640224, lng: 19.4501955 },
    { lat: 49.4640827, lng: 19.4499158 },
    { lat: 49.4641647, lng: 19.4496842 },
    { lat: 49.4642661, lng: 19.4495888 },
    { lat: 49.464319, lng: 19.4496617 },
    { lat: 49.4643998, lng: 19.4494056 },
    { lat: 49.4646266, lng: 19.4489504 },
    { lat: 49.4650976, lng: 19.4481891 },
    { lat: 49.4651687, lng: 19.4480916 },
    { lat: 49.4654532, lng: 19.4477838 },
    { lat: 49.4655783, lng: 19.4477369 },
    { lat: 49.4656254, lng: 19.4478068 },
    { lat: 49.4657773, lng: 19.447581 },
    { lat: 49.4659531, lng: 19.4474359 },
    { lat: 49.46603, lng: 19.44749 },
    { lat: 49.4663378, lng: 19.4470785 },
    { lat: 49.466561, lng: 19.4469301 },
    { lat: 49.4665783, lng: 19.4470398 },
    { lat: 49.4668724, lng: 19.4469189 },
    { lat: 49.4669921, lng: 19.4468987 },
    { lat: 49.4671472, lng: 19.4468432 },
    { lat: 49.467404, lng: 19.4468158 },
    { lat: 49.4677412, lng: 19.4466972 },
    { lat: 49.4679128, lng: 19.4467072 },
    { lat: 49.4680008, lng: 19.4466582 },
    { lat: 49.4680621, lng: 19.4466215 },
    { lat: 49.4681177, lng: 19.44648 },
    { lat: 49.4683902, lng: 19.4462796 },
    { lat: 49.4686031, lng: 19.4460823 },
    { lat: 49.4685991, lng: 19.4459969 },
    { lat: 49.4688944, lng: 19.4457284 },
    { lat: 49.4695449, lng: 19.4420128 },
    { lat: 49.4698619, lng: 19.4414036 },
    { lat: 49.4699146, lng: 19.4411783 },
    { lat: 49.4699849, lng: 19.4398878 },
    { lat: 49.4700561, lng: 19.4391095 },
    { lat: 49.470142, lng: 19.4388216 },
    { lat: 49.4701872, lng: 19.4385776 },
    { lat: 49.4702387, lng: 19.4381308 },
    { lat: 49.4702808, lng: 19.4378715 },
    { lat: 49.4704091, lng: 19.4374255 },
    { lat: 49.470441, lng: 19.4372379 },
    { lat: 49.4704357, lng: 19.4369262 },
    { lat: 49.4702942, lng: 19.4363496 },
    { lat: 49.4702535, lng: 19.4360254 },
    { lat: 49.4703053, lng: 19.4356578 },
    { lat: 49.4703609, lng: 19.4353971 },
    { lat: 49.4704688, lng: 19.4350021 },
    { lat: 49.4704981, lng: 19.4349011 },
    { lat: 49.4705488, lng: 19.4345598 },
    { lat: 49.4706232, lng: 19.4341706 },
    { lat: 49.4707183, lng: 19.4338364 },
    { lat: 49.470887, lng: 19.4334395 },
    { lat: 49.4710195, lng: 19.4331121 },
    { lat: 49.4711052, lng: 19.4329369 },
    { lat: 49.4711431, lng: 19.4328564 },
    { lat: 49.4712939, lng: 19.4326093 },
    { lat: 49.4714596, lng: 19.4324141 },
    { lat: 49.4720273, lng: 19.4318207 },
    { lat: 49.4725711, lng: 19.4312738 },
    { lat: 49.4726306, lng: 19.4312224 },
    { lat: 49.4730059, lng: 19.4307317 },
    { lat: 49.4730722, lng: 19.4305829 },
    { lat: 49.4732316, lng: 19.4301783 },
    { lat: 49.4734073, lng: 19.4294665 },
    { lat: 49.4734422, lng: 19.4292703 },
    { lat: 49.4735169, lng: 19.4288358 },
    { lat: 49.4735137, lng: 19.4287209 },
    { lat: 49.4736382, lng: 19.4280204 },
    { lat: 49.4738459, lng: 19.4271722 },
    { lat: 49.4738516, lng: 19.426661 },
    { lat: 49.473898, lng: 19.426106 },
    { lat: 49.473903, lng: 19.4260395 },
    { lat: 49.4736823, lng: 19.4259479 },
    { lat: 49.4733352, lng: 19.4259689 },
    { lat: 49.4728538, lng: 19.426236 },
    { lat: 49.4727116, lng: 19.4263978 },
    { lat: 49.4725726, lng: 19.4266599 },
    { lat: 49.4722006, lng: 19.4268978 },
    { lat: 49.4721327, lng: 19.4269421 },
    { lat: 49.4719314, lng: 19.4269483 },
    { lat: 49.4717808, lng: 19.4269857 },
    { lat: 49.4716907, lng: 19.4268842 },
    { lat: 49.4714817, lng: 19.4268732 },
    { lat: 49.4713515, lng: 19.4269587 },
    { lat: 49.4712601, lng: 19.4269078 },
    { lat: 49.4711343, lng: 19.4270352 },
    { lat: 49.471058, lng: 19.4270463 },
    { lat: 49.4709866, lng: 19.4271552 },
    { lat: 49.4709168, lng: 19.4271573 },
    { lat: 49.4707832, lng: 19.4272928 },
    { lat: 49.4707345, lng: 19.4274079 },
    { lat: 49.4706912, lng: 19.427397 },
    { lat: 49.4705071, lng: 19.4276325 },
    { lat: 49.4704466, lng: 19.427713 },
    { lat: 49.4703276, lng: 19.4276991 },
    { lat: 49.470184, lng: 19.4278567 },
    { lat: 49.4701056, lng: 19.4277956 },
    { lat: 49.4700601, lng: 19.4278582 },
    { lat: 49.4700297, lng: 19.4279564 },
    { lat: 49.4699342, lng: 19.4279385 },
    { lat: 49.4698106, lng: 19.4280957 },
    { lat: 49.4698014, lng: 19.4281873 },
    { lat: 49.4697217, lng: 19.4281945 },
    { lat: 49.4695885, lng: 19.4283567 },
    { lat: 49.469435, lng: 19.4285991 },
    { lat: 49.4693421, lng: 19.4286874 },
    { lat: 49.4691814, lng: 19.4287929 },
    { lat: 49.469156, lng: 19.4288518 },
    { lat: 49.4691077, lng: 19.4289038 },
    { lat: 49.4689074, lng: 19.4288624 },
    { lat: 49.4688716, lng: 19.4289649 },
    { lat: 49.4688189, lng: 19.4289452 },
    { lat: 49.4687901, lng: 19.4289092 },
    { lat: 49.4686362, lng: 19.4289908 },
    { lat: 49.4684777, lng: 19.4288525 },
    { lat: 49.4684217, lng: 19.4290296 },
    { lat: 49.4683388, lng: 19.428994 },
    { lat: 49.4682634, lng: 19.4289964 },
    { lat: 49.4682333, lng: 19.4289015 },
    { lat: 49.4682414, lng: 19.428735 },
    { lat: 49.4680376, lng: 19.4288151 },
    { lat: 49.4679412, lng: 19.4288694 },
    { lat: 49.4678279, lng: 19.4287636 },
    { lat: 49.4677401, lng: 19.4286666 },
    { lat: 49.4676677, lng: 19.4286585 },
    { lat: 49.4676501, lng: 19.4288155 },
    { lat: 49.4675824, lng: 19.4287638 },
    { lat: 49.467519, lng: 19.4286859 },
    { lat: 49.4675147, lng: 19.4286171 },
    { lat: 49.4674449, lng: 19.4284957 },
    { lat: 49.4673537, lng: 19.4284944 },
    { lat: 49.4673624, lng: 19.4283395 },
    { lat: 49.4671401, lng: 19.4282346 },
    { lat: 49.4671077, lng: 19.4282432 },
    { lat: 49.4671015, lng: 19.4283761 },
    { lat: 49.4670058, lng: 19.4282811 },
    { lat: 49.4669814, lng: 19.4281928 },
    { lat: 49.4669143, lng: 19.4281776 },
    { lat: 49.4667425, lng: 19.4280905 },
    { lat: 49.4666764, lng: 19.4281487 },
    { lat: 49.4665627, lng: 19.4280056 },
    { lat: 49.4664999, lng: 19.4280381 },
    { lat: 49.466425, lng: 19.4279492 },
    { lat: 49.4662367, lng: 19.4279004 },
    { lat: 49.4662394, lng: 19.4279599 },
    { lat: 49.4661624, lng: 19.4280715 },
    { lat: 49.4660933, lng: 19.4280616 },
    { lat: 49.466074, lng: 19.4281347 },
    { lat: 49.4659985, lng: 19.428132 },
    { lat: 49.4659623, lng: 19.4280831 },
    { lat: 49.4658652, lng: 19.4281483 },
    { lat: 49.4658315, lng: 19.4280795 },
    { lat: 49.4657191, lng: 19.4281083 },
    { lat: 49.4657022, lng: 19.4279888 },
    { lat: 49.4655835, lng: 19.4278564 },
    { lat: 49.4654482, lng: 19.4278309 },
    { lat: 49.4654128, lng: 19.427847 },
    { lat: 49.4653814, lng: 19.4279236 },
    { lat: 49.4653143, lng: 19.4280044 },
    { lat: 49.465289, lng: 19.4279923 },
    { lat: 49.4652403, lng: 19.4280125 },
    { lat: 49.4651708, lng: 19.4279494 },
    { lat: 49.4651017, lng: 19.4278438 },
    { lat: 49.4648912, lng: 19.4279427 },
    { lat: 49.4648431, lng: 19.4279751 },
    { lat: 49.4648077, lng: 19.4280869 },
    { lat: 49.464681, lng: 19.4281249 },
    { lat: 49.4646426, lng: 19.4281747 },
    { lat: 49.4645226, lng: 19.4282348 },
    { lat: 49.4645276, lng: 19.4281734 },
    { lat: 49.4645355, lng: 19.4280733 },
    { lat: 49.4644585, lng: 19.4281132 },
    { lat: 49.4643982, lng: 19.4281012 },
    { lat: 49.4643857, lng: 19.4281729 },
    { lat: 49.464316, lng: 19.4282482 },
    { lat: 49.4642766, lng: 19.4283271 },
    { lat: 49.4641637, lng: 19.4283418 },
    { lat: 49.4641656, lng: 19.428434 },
    { lat: 49.4640997, lng: 19.4283966 },
    { lat: 49.464035, lng: 19.4283862 },
    { lat: 49.4640419, lng: 19.4284652 },
    { lat: 49.4640021, lng: 19.4285793 },
    { lat: 49.4638683, lng: 19.4286336 },
  ],
  Krušetnica: [
    { lat: 49.3673717, lng: 19.3634287 },
    { lat: 49.3674696, lng: 19.3633503 },
    { lat: 49.36769, lng: 19.3630999 },
    { lat: 49.3677166, lng: 19.3630691 },
    { lat: 49.3679675, lng: 19.3627679 },
    { lat: 49.3685685, lng: 19.3626077 },
    { lat: 49.3692018, lng: 19.3624164 },
    { lat: 49.3698016, lng: 19.3620365 },
    { lat: 49.3703734, lng: 19.3616863 },
    { lat: 49.3709702, lng: 19.3612897 },
    { lat: 49.3714051, lng: 19.3608533 },
    { lat: 49.3716867, lng: 19.3600327 },
    { lat: 49.3719203, lng: 19.3595348 },
    { lat: 49.37222, lng: 19.3591979 },
    { lat: 49.3727394, lng: 19.3588813 },
    { lat: 49.3732374, lng: 19.358856 },
    { lat: 49.3737492, lng: 19.3588136 },
    { lat: 49.3747319, lng: 19.3583066 },
    { lat: 49.3752303, lng: 19.3579529 },
    { lat: 49.3758005, lng: 19.357643 },
    { lat: 49.3763493, lng: 19.357786 },
    { lat: 49.3768813, lng: 19.3581027 },
    { lat: 49.3771764, lng: 19.3582439 },
    { lat: 49.3772559, lng: 19.3582822 },
    { lat: 49.3773092, lng: 19.3583621 },
    { lat: 49.3773238, lng: 19.3583854 },
    { lat: 49.3773612, lng: 19.3584384 },
    { lat: 49.3775074, lng: 19.3586459 },
    { lat: 49.3775785, lng: 19.3587477 },
    { lat: 49.3777036, lng: 19.3589134 },
    { lat: 49.3773457, lng: 19.3596028 },
    { lat: 49.3772867, lng: 19.3599764 },
    { lat: 49.3774606, lng: 19.3603222 },
    { lat: 49.3781594, lng: 19.3612192 },
    { lat: 49.3786464, lng: 19.3620196 },
    { lat: 49.3787545, lng: 19.3622114 },
    { lat: 49.3788524, lng: 19.3620878 },
    { lat: 49.3788578, lng: 19.3620811 },
    { lat: 49.3790228, lng: 19.3618728 },
    { lat: 49.3792362, lng: 19.3616197 },
    { lat: 49.3795399, lng: 19.3611691 },
    { lat: 49.3798093, lng: 19.3606889 },
    { lat: 49.3798187, lng: 19.3606727 },
    { lat: 49.379882, lng: 19.3605826 },
    { lat: 49.3799189, lng: 19.3605286 },
    { lat: 49.3804687, lng: 19.3597327 },
    { lat: 49.3807575, lng: 19.3596663 },
    { lat: 49.380993, lng: 19.3594973 },
    { lat: 49.3811337, lng: 19.3593954 },
    { lat: 49.3812094, lng: 19.3592826 },
    { lat: 49.3815762, lng: 19.3590268 },
    { lat: 49.3816616, lng: 19.3589491 },
    { lat: 49.3816824, lng: 19.3589638 },
    { lat: 49.3817046, lng: 19.3589791 },
    { lat: 49.3818401, lng: 19.359131 },
    { lat: 49.382207, lng: 19.3572147 },
    { lat: 49.3826478, lng: 19.3558201 },
    { lat: 49.3829288, lng: 19.3553263 },
    { lat: 49.3831623, lng: 19.3550171 },
    { lat: 49.3837868, lng: 19.3543493 },
    { lat: 49.3858463, lng: 19.3522698 },
    { lat: 49.3876972, lng: 19.3504062 },
    { lat: 49.3886373, lng: 19.3493982 },
    { lat: 49.3895881, lng: 19.3485739 },
    { lat: 49.3898769, lng: 19.3483182 },
    { lat: 49.390399, lng: 19.3476058 },
    { lat: 49.3911923, lng: 19.34657 },
    { lat: 49.3919559, lng: 19.3457192 },
    { lat: 49.3921877, lng: 19.3450859 },
    { lat: 49.3922665, lng: 19.3446515 },
    { lat: 49.3922756, lng: 19.3443419 },
    { lat: 49.3922322, lng: 19.3438738 },
    { lat: 49.3920619, lng: 19.3428608 },
    { lat: 49.39191, lng: 19.3418067 },
    { lat: 49.3917421, lng: 19.3405822 },
    { lat: 49.3918285, lng: 19.3395149 },
    { lat: 49.3920768, lng: 19.3378658 },
    { lat: 49.3923906, lng: 19.3373277 },
    { lat: 49.392652, lng: 19.3371557 },
    { lat: 49.3929437, lng: 19.3369618 },
    { lat: 49.3931689, lng: 19.3368151 },
    { lat: 49.3935951, lng: 19.336507 },
    { lat: 49.3939777, lng: 19.3360665 },
    { lat: 49.394854, lng: 19.3348076 },
    { lat: 49.3954279, lng: 19.3339502 },
    { lat: 49.3956152, lng: 19.3336647 },
    { lat: 49.3957992, lng: 19.3336962 },
    { lat: 49.3961228, lng: 19.3341345 },
    { lat: 49.3964432, lng: 19.3343358 },
    { lat: 49.397288, lng: 19.3345202 },
    { lat: 49.3982365, lng: 19.3344887 },
    { lat: 49.3986537, lng: 19.334277 },
    { lat: 49.3989823, lng: 19.3339568 },
    { lat: 49.3990245, lng: 19.333916 },
    { lat: 49.3992688, lng: 19.3336028 },
    { lat: 49.3993525, lng: 19.3334384 },
    { lat: 49.3995347, lng: 19.3327124 },
    { lat: 49.3996878, lng: 19.3324426 },
    { lat: 49.3999327, lng: 19.3320198 },
    { lat: 49.400161, lng: 19.3316277 },
    { lat: 49.4003064, lng: 19.3314854 },
    { lat: 49.4005293, lng: 19.3314558 },
    { lat: 49.4006846, lng: 19.331836 },
    { lat: 49.4007111, lng: 19.3319922 },
    { lat: 49.4006295, lng: 19.332075 },
    { lat: 49.4006308, lng: 19.3321994 },
    { lat: 49.4007229, lng: 19.3322884 },
    { lat: 49.4008547, lng: 19.332361 },
    { lat: 49.401102, lng: 19.3323721 },
    { lat: 49.4015269, lng: 19.332157 },
    { lat: 49.4020638, lng: 19.3323623 },
    { lat: 49.4023783, lng: 19.3323617 },
    { lat: 49.4028336, lng: 19.33198 },
    { lat: 49.4030025, lng: 19.3319317 },
    { lat: 49.4031037, lng: 19.3318533 },
    { lat: 49.4032747, lng: 19.3316788 },
    { lat: 49.4034214, lng: 19.3310113 },
    { lat: 49.4036969, lng: 19.3308536 },
    { lat: 49.403854, lng: 19.3308399 },
    { lat: 49.4041665, lng: 19.3310178 },
    { lat: 49.4045494, lng: 19.3313718 },
    { lat: 49.404758, lng: 19.3315409 },
    { lat: 49.4054752, lng: 19.3317437 },
    { lat: 49.4059338, lng: 19.3318383 },
    { lat: 49.406179, lng: 19.3316408 },
    { lat: 49.406476, lng: 19.3311973 },
    { lat: 49.4068065, lng: 19.3310132 },
    { lat: 49.4070862, lng: 19.3311373 },
    { lat: 49.4071186, lng: 19.3312991 },
    { lat: 49.407346, lng: 19.3318476 },
    { lat: 49.4078221, lng: 19.3324808 },
    { lat: 49.4080709, lng: 19.332525 },
    { lat: 49.4081341, lng: 19.3324084 },
    { lat: 49.4084917, lng: 19.3321725 },
    { lat: 49.4086747, lng: 19.3318016 },
    { lat: 49.4091976, lng: 19.3317769 },
    { lat: 49.4094361, lng: 19.3322453 },
    { lat: 49.4095282, lng: 19.3328189 },
    { lat: 49.4096979, lng: 19.3330944 },
    { lat: 49.4101456, lng: 19.3329129 },
    { lat: 49.4101969, lng: 19.332828 },
    { lat: 49.4101956, lng: 19.3326805 },
    { lat: 49.41004, lng: 19.3320307 },
    { lat: 49.4100531, lng: 19.3317068 },
    { lat: 49.4104721, lng: 19.3310812 },
    { lat: 49.4106503, lng: 19.3302947 },
    { lat: 49.411301, lng: 19.3292476 },
    { lat: 49.4116906, lng: 19.3289046 },
    { lat: 49.4119632, lng: 19.3288322 },
    { lat: 49.4126293, lng: 19.3292172 },
    { lat: 49.4133234, lng: 19.329622 },
    { lat: 49.4136707, lng: 19.329892 },
    { lat: 49.4139502, lng: 19.330036 },
    { lat: 49.4148426, lng: 19.3305148 },
    { lat: 49.4152387, lng: 19.3304834 },
    { lat: 49.4154385, lng: 19.330558 },
    { lat: 49.4158831, lng: 19.330956 },
    { lat: 49.4162262, lng: 19.331473 },
    { lat: 49.4163656, lng: 19.3315861 },
    { lat: 49.4166094, lng: 19.3317127 },
    { lat: 49.4168248, lng: 19.3317204 },
    { lat: 49.4170754, lng: 19.331726 },
    { lat: 49.417241, lng: 19.3315319 },
    { lat: 49.4178199, lng: 19.3301103 },
    { lat: 49.4180886, lng: 19.329161 },
    { lat: 49.418156, lng: 19.3286544 },
    { lat: 49.41827, lng: 19.3280808 },
    { lat: 49.4189174, lng: 19.3273046 },
    { lat: 49.4195751, lng: 19.32612 },
    { lat: 49.419819, lng: 19.3259338 },
    { lat: 49.4199015, lng: 19.3258708 },
    { lat: 49.42017, lng: 19.3258303 },
    { lat: 49.4204388, lng: 19.3259374 },
    { lat: 49.4206704, lng: 19.3263339 },
    { lat: 49.4209155, lng: 19.3267803 },
    { lat: 49.4209799, lng: 19.3272619 },
    { lat: 49.4211155, lng: 19.327438 },
    { lat: 49.4211964, lng: 19.3274313 },
    { lat: 49.4213782, lng: 19.3272715 },
    { lat: 49.4217405, lng: 19.3266838 },
    { lat: 49.421775, lng: 19.3262666 },
    { lat: 49.4219359, lng: 19.3254015 },
    { lat: 49.422056, lng: 19.325247 },
    { lat: 49.4221694, lng: 19.3252645 },
    { lat: 49.422499, lng: 19.3258703 },
    { lat: 49.422848, lng: 19.3266093 },
    { lat: 49.4236871, lng: 19.3266477 },
    { lat: 49.4242431, lng: 19.3265852 },
    { lat: 49.4248448, lng: 19.3267067 },
    { lat: 49.4249432, lng: 19.3267606 },
    { lat: 49.4251003, lng: 19.3269366 },
    { lat: 49.4251794, lng: 19.3270629 },
    { lat: 49.4252775, lng: 19.3270654 },
    { lat: 49.425642, lng: 19.3268811 },
    { lat: 49.4257245, lng: 19.3268177 },
    { lat: 49.4258124, lng: 19.3265539 },
    { lat: 49.4258161, lng: 19.3264152 },
    { lat: 49.425788, lng: 19.3259846 },
    { lat: 49.4258849, lng: 19.3255532 },
    { lat: 49.4262313, lng: 19.3252809 },
    { lat: 49.4262695, lng: 19.3252508 },
    { lat: 49.4269721, lng: 19.3247099 },
    { lat: 49.4273301, lng: 19.3241038 },
    { lat: 49.4277745, lng: 19.3230077 },
    { lat: 49.4281009, lng: 19.3225669 },
    { lat: 49.4287252, lng: 19.3211309 },
    { lat: 49.4287822, lng: 19.3209705 },
    { lat: 49.4293879, lng: 19.3192655 },
    { lat: 49.4296586, lng: 19.3186245 },
    { lat: 49.429779, lng: 19.3183834 },
    { lat: 49.4298547, lng: 19.3182212 },
    { lat: 49.429905, lng: 19.3181841 },
    { lat: 49.4299552, lng: 19.318166 },
    { lat: 49.4298331, lng: 19.317952 },
    { lat: 49.4298085, lng: 19.3179062 },
    { lat: 49.4293682, lng: 19.3174115 },
    { lat: 49.4291616, lng: 19.3173558 },
    { lat: 49.4288703, lng: 19.3168164 },
    { lat: 49.4284404, lng: 19.3163462 },
    { lat: 49.4279601, lng: 19.3155305 },
    { lat: 49.4279261, lng: 19.3152834 },
    { lat: 49.4278033, lng: 19.3151941 },
    { lat: 49.4276546, lng: 19.3150498 },
    { lat: 49.4272882, lng: 19.3144956 },
    { lat: 49.4272464, lng: 19.3143739 },
    { lat: 49.427225, lng: 19.3141814 },
    { lat: 49.4271138, lng: 19.3139954 },
    { lat: 49.4269927, lng: 19.3137963 },
    { lat: 49.4269223, lng: 19.313379 },
    { lat: 49.4271983, lng: 19.3124405 },
    { lat: 49.4269717, lng: 19.310574 },
    { lat: 49.4267783, lng: 19.3103924 },
    { lat: 49.4265885, lng: 19.3092361 },
    { lat: 49.4264479, lng: 19.3080678 },
    { lat: 49.4263171, lng: 19.3070165 },
    { lat: 49.4262403, lng: 19.3064404 },
    { lat: 49.4263053, lng: 19.3056657 },
    { lat: 49.4262873, lng: 19.3054038 },
    { lat: 49.426286, lng: 19.3052359 },
    { lat: 49.4264015, lng: 19.3043594 },
    { lat: 49.4263185, lng: 19.3038215 },
    { lat: 49.4256479, lng: 19.3029568 },
    { lat: 49.425565, lng: 19.3024675 },
    { lat: 49.4235769, lng: 19.3031753 },
    { lat: 49.4234488, lng: 19.3031829 },
    { lat: 49.4231509, lng: 19.3032008 },
    { lat: 49.4227766, lng: 19.30334 },
    { lat: 49.4226891, lng: 19.3033729 },
    { lat: 49.4226524, lng: 19.3033867 },
    { lat: 49.4224389, lng: 19.3034466 },
    { lat: 49.4223189, lng: 19.3034802 },
    { lat: 49.4223122, lng: 19.3034831 },
    { lat: 49.422002, lng: 19.3036188 },
    { lat: 49.4217724, lng: 19.3036743 },
    { lat: 49.4214003, lng: 19.3037637 },
    { lat: 49.4205302, lng: 19.3041414 },
    { lat: 49.4205124, lng: 19.3041503 },
    { lat: 49.4204839, lng: 19.3041654 },
    { lat: 49.4201033, lng: 19.3043632 },
    { lat: 49.4197012, lng: 19.3046786 },
    { lat: 49.4192707, lng: 19.3049127 },
    { lat: 49.4192202, lng: 19.3049447 },
    { lat: 49.4191416, lng: 19.3049954 },
    { lat: 49.4183879, lng: 19.3054771 },
    { lat: 49.4178042, lng: 19.3057887 },
    { lat: 49.4175536, lng: 19.3058773 },
    { lat: 49.417506, lng: 19.3058945 },
    { lat: 49.4173423, lng: 19.3059119 },
    { lat: 49.4168325, lng: 19.3058287 },
    { lat: 49.4166981, lng: 19.3058729 },
    { lat: 49.4166363, lng: 19.3059496 },
    { lat: 49.4166183, lng: 19.3059719 },
    { lat: 49.4161339, lng: 19.3065728 },
    { lat: 49.4158407, lng: 19.3067848 },
    { lat: 49.4154875, lng: 19.3070599 },
    { lat: 49.4154353, lng: 19.3071001 },
    { lat: 49.4154114, lng: 19.307119 },
    { lat: 49.414793, lng: 19.3076012 },
    { lat: 49.4142392, lng: 19.3077791 },
    { lat: 49.4141867, lng: 19.3077926 },
    { lat: 49.4140865, lng: 19.3078178 },
    { lat: 49.4137302, lng: 19.3079079 },
    { lat: 49.4135675, lng: 19.3079227 },
    { lat: 49.4134296, lng: 19.3079173 },
    { lat: 49.4132618, lng: 19.3079196 },
    { lat: 49.4130865, lng: 19.3078576 },
    { lat: 49.413046, lng: 19.3078353 },
    { lat: 49.4126852, lng: 19.3076397 },
    { lat: 49.4123908, lng: 19.3074667 },
    { lat: 49.4120952, lng: 19.3073116 },
    { lat: 49.412072, lng: 19.3073001 },
    { lat: 49.4120177, lng: 19.3072713 },
    { lat: 49.4118092, lng: 19.3072213 },
    { lat: 49.4115127, lng: 19.3071492 },
    { lat: 49.4112825, lng: 19.3070929 },
    { lat: 49.4111867, lng: 19.3070699 },
    { lat: 49.4106557, lng: 19.3070169 },
    { lat: 49.410478, lng: 19.3069989 },
    { lat: 49.4101618, lng: 19.3070835 },
    { lat: 49.4101028, lng: 19.3070998 },
    { lat: 49.4099927, lng: 19.307129 },
    { lat: 49.4098955, lng: 19.3071966 },
    { lat: 49.4097461, lng: 19.3073026 },
    { lat: 49.4097397, lng: 19.3073069 },
    { lat: 49.4097386, lng: 19.3073081 },
    { lat: 49.4090899, lng: 19.3078432 },
    { lat: 49.4090738, lng: 19.3078569 },
    { lat: 49.4089116, lng: 19.3080037 },
    { lat: 49.4085322, lng: 19.3083711 },
    { lat: 49.4085351, lng: 19.3084066 },
    { lat: 49.4085366, lng: 19.3084144 },
    { lat: 49.408463, lng: 19.3084801 },
    { lat: 49.4083117, lng: 19.308617 },
    { lat: 49.4082854, lng: 19.3086344 },
    { lat: 49.4077409, lng: 19.3089863 },
    { lat: 49.4075421, lng: 19.3091669 },
    { lat: 49.40719, lng: 19.3088975 },
    { lat: 49.4070729, lng: 19.3088475 },
    { lat: 49.4068141, lng: 19.3087379 },
    { lat: 49.4067404, lng: 19.3087512 },
    { lat: 49.406186, lng: 19.3092095 },
    { lat: 49.4061258, lng: 19.3092698 },
    { lat: 49.4058957, lng: 19.309503 },
    { lat: 49.4054928, lng: 19.3099927 },
    { lat: 49.4054182, lng: 19.3100816 },
    { lat: 49.4053776, lng: 19.3101288 },
    { lat: 49.4048293, lng: 19.3108094 },
    { lat: 49.404642, lng: 19.3109731 },
    { lat: 49.4044632, lng: 19.3108821 },
    { lat: 49.4041771, lng: 19.3110292 },
    { lat: 49.404054, lng: 19.3110931 },
    { lat: 49.4038613, lng: 19.3111926 },
    { lat: 49.4036607, lng: 19.311166 },
    { lat: 49.4035722, lng: 19.3111523 },
    { lat: 49.4034188, lng: 19.3111291 },
    { lat: 49.4031839, lng: 19.3110943 },
    { lat: 49.4029831, lng: 19.3110644 },
    { lat: 49.4029067, lng: 19.31088 },
    { lat: 49.4028878, lng: 19.310835 },
    { lat: 49.4028485, lng: 19.3108141 },
    { lat: 49.4027156, lng: 19.3107437 },
    { lat: 49.4025664, lng: 19.3106644 },
    { lat: 49.4022585, lng: 19.3106803 },
    { lat: 49.4018559, lng: 19.3109402 },
    { lat: 49.401636, lng: 19.3111751 },
    { lat: 49.4011036, lng: 19.3119979 },
    { lat: 49.4007868, lng: 19.312571 },
    { lat: 49.4006941, lng: 19.3129945 },
    { lat: 49.4005191, lng: 19.3134647 },
    { lat: 49.4005327, lng: 19.313563 },
    { lat: 49.4003117, lng: 19.3139902 },
    { lat: 49.4002749, lng: 19.314046 },
    { lat: 49.3997559, lng: 19.3148495 },
    { lat: 49.3992415, lng: 19.3154589 },
    { lat: 49.3991235, lng: 19.3155588 },
    { lat: 49.3987277, lng: 19.3158909 },
    { lat: 49.3985642, lng: 19.3160291 },
    { lat: 49.3983442, lng: 19.3162152 },
    { lat: 49.3975245, lng: 19.3169954 },
    { lat: 49.3974762, lng: 19.3170243 },
    { lat: 49.3974149, lng: 19.3170607 },
    { lat: 49.3970914, lng: 19.3172524 },
    { lat: 49.3967683, lng: 19.3172592 },
    { lat: 49.3967426, lng: 19.3172599 },
    { lat: 49.3962471, lng: 19.3173197 },
    { lat: 49.3961199, lng: 19.3172951 },
    { lat: 49.3960267, lng: 19.3172771 },
    { lat: 49.3959185, lng: 19.3172558 },
    { lat: 49.3959077, lng: 19.3172183 },
    { lat: 49.3959049, lng: 19.3172074 },
    { lat: 49.3958864, lng: 19.3171269 },
    { lat: 49.395761, lng: 19.3171158 },
    { lat: 49.3953387, lng: 19.317075 },
    { lat: 49.3953342, lng: 19.3170744 },
    { lat: 49.395324, lng: 19.3170737 },
    { lat: 49.3949776, lng: 19.3170628 },
    { lat: 49.3949167, lng: 19.3169935 },
    { lat: 49.3949112, lng: 19.316997 },
    { lat: 49.3945159, lng: 19.317192 },
    { lat: 49.3943825, lng: 19.3172773 },
    { lat: 49.3942119, lng: 19.3173862 },
    { lat: 49.3938851, lng: 19.3175063 },
    { lat: 49.3938519, lng: 19.3175193 },
    { lat: 49.3932923, lng: 19.3177447 },
    { lat: 49.393185, lng: 19.3177881 },
    { lat: 49.3928448, lng: 19.3178398 },
    { lat: 49.3926053, lng: 19.3180203 },
    { lat: 49.3923976, lng: 19.3181772 },
    { lat: 49.3919871, lng: 19.3184352 },
    { lat: 49.3916928, lng: 19.3187149 },
    { lat: 49.3913837, lng: 19.3189954 },
    { lat: 49.3911478, lng: 19.3191115 },
    { lat: 49.3908838, lng: 19.3192716 },
    { lat: 49.3906884, lng: 19.3191652 },
    { lat: 49.3905496, lng: 19.3192304 },
    { lat: 49.3904073, lng: 19.3192979 },
    { lat: 49.3902551, lng: 19.3193697 },
    { lat: 49.3901647, lng: 19.3194122 },
    { lat: 49.389889, lng: 19.3192727 },
    { lat: 49.389714, lng: 19.3191021 },
    { lat: 49.3894335, lng: 19.3185503 },
    { lat: 49.3892783, lng: 19.3181746 },
    { lat: 49.3892683, lng: 19.3181565 },
    { lat: 49.3889345, lng: 19.3175018 },
    { lat: 49.3886575, lng: 19.3171504 },
    { lat: 49.3885023, lng: 19.3169396 },
    { lat: 49.3883785, lng: 19.3168025 },
    { lat: 49.3881326, lng: 19.3165312 },
    { lat: 49.3877136, lng: 19.3161084 },
    { lat: 49.3873157, lng: 19.3154564 },
    { lat: 49.3871034, lng: 19.3151839 },
    { lat: 49.3867163, lng: 19.3147677 },
    { lat: 49.3862803, lng: 19.3143149 },
    { lat: 49.386006, lng: 19.3140157 },
    { lat: 49.385792, lng: 19.3138994 },
    { lat: 49.3855813, lng: 19.3133516 },
    { lat: 49.3854202, lng: 19.3130655 },
    { lat: 49.385195, lng: 19.3126173 },
    { lat: 49.3849515, lng: 19.3119704 },
    { lat: 49.3846648, lng: 19.3111936 },
    { lat: 49.3845772, lng: 19.3106524 },
    { lat: 49.3845089, lng: 19.3102306 },
    { lat: 49.3844187, lng: 19.3100344 },
    { lat: 49.3842409, lng: 19.3097315 },
    { lat: 49.3840792, lng: 19.3094567 },
    { lat: 49.3839771, lng: 19.3091548 },
    { lat: 49.3839418, lng: 19.3090516 },
    { lat: 49.3838652, lng: 19.3087686 },
    { lat: 49.3838577, lng: 19.3087506 },
    { lat: 49.3838349, lng: 19.3086974 },
    { lat: 49.3838221, lng: 19.3086674 },
    { lat: 49.3837993, lng: 19.3086135 },
    { lat: 49.3837872, lng: 19.3085931 },
    { lat: 49.3837431, lng: 19.3085184 },
    { lat: 49.3837015, lng: 19.3084471 },
    { lat: 49.3836405, lng: 19.3083254 },
    { lat: 49.3835581, lng: 19.3082318 },
    { lat: 49.383292, lng: 19.3081325 },
    { lat: 49.3829519, lng: 19.3081115 },
    { lat: 49.3827609, lng: 19.3079308 },
    { lat: 49.3827455, lng: 19.3079163 },
    { lat: 49.3826667, lng: 19.3078476 },
    { lat: 49.382507, lng: 19.3077101 },
    { lat: 49.3824706, lng: 19.3076787 },
    { lat: 49.3824123, lng: 19.3076602 },
    { lat: 49.3823207, lng: 19.3076305 },
    { lat: 49.382181, lng: 19.3074876 },
    { lat: 49.3818997, lng: 19.3075719 },
    { lat: 49.3817574, lng: 19.3076083 },
    { lat: 49.3817474, lng: 19.3076108 },
    { lat: 49.3817278, lng: 19.3076011 },
    { lat: 49.3816815, lng: 19.3075765 },
    { lat: 49.3814438, lng: 19.307482 },
    { lat: 49.3813265, lng: 19.3073641 },
    { lat: 49.3811327, lng: 19.3071707 },
    { lat: 49.3809137, lng: 19.3070676 },
    { lat: 49.3807075, lng: 19.3068514 },
    { lat: 49.3802858, lng: 19.3065881 },
    { lat: 49.3801893, lng: 19.3065955 },
    { lat: 49.380159, lng: 19.3065978 },
    { lat: 49.3799909, lng: 19.3065448 },
    { lat: 49.3799348, lng: 19.3064819 },
    { lat: 49.3798446, lng: 19.306382 },
    { lat: 49.3797592, lng: 19.3063632 },
    { lat: 49.3795881, lng: 19.3062491 },
    { lat: 49.3794604, lng: 19.3062654 },
    { lat: 49.3793541, lng: 19.3062558 },
    { lat: 49.3793326, lng: 19.3062546 },
    { lat: 49.3792481, lng: 19.3063012 },
    { lat: 49.3790851, lng: 19.3062404 },
    { lat: 49.3788933, lng: 19.3062837 },
    { lat: 49.3787061, lng: 19.306159 },
    { lat: 49.3785717, lng: 19.306205 },
    { lat: 49.3784794, lng: 19.3061805 },
    { lat: 49.3783706, lng: 19.3062161 },
    { lat: 49.3781563, lng: 19.3062336 },
    { lat: 49.3778885, lng: 19.3060528 },
    { lat: 49.377757, lng: 19.3059194 },
    { lat: 49.3774672, lng: 19.3058427 },
    { lat: 49.3773517, lng: 19.3057564 },
    { lat: 49.3772572, lng: 19.3057368 },
    { lat: 49.3772272, lng: 19.3057202 },
    { lat: 49.3761122, lng: 19.3055667 },
    { lat: 49.374379, lng: 19.3053282 },
    { lat: 49.3743835, lng: 19.3053787 },
    { lat: 49.3743934, lng: 19.3054677 },
    { lat: 49.3743995, lng: 19.3055285 },
    { lat: 49.3744026, lng: 19.305569 },
    { lat: 49.3744145, lng: 19.3056505 },
    { lat: 49.3744249, lng: 19.3057296 },
    { lat: 49.3744285, lng: 19.3057571 },
    { lat: 49.3744255, lng: 19.3058377 },
    { lat: 49.3744235, lng: 19.305916 },
    { lat: 49.3744219, lng: 19.3059517 },
    { lat: 49.3744226, lng: 19.3059921 },
    { lat: 49.374416, lng: 19.3060876 },
    { lat: 49.3744139, lng: 19.3061431 },
    { lat: 49.3744146, lng: 19.3061586 },
    { lat: 49.3744124, lng: 19.3062265 },
    { lat: 49.3744108, lng: 19.3062679 },
    { lat: 49.3744088, lng: 19.3062949 },
    { lat: 49.3744076, lng: 19.3063402 },
    { lat: 49.3744059, lng: 19.306378 },
    { lat: 49.3744042, lng: 19.3064386 },
    { lat: 49.374399, lng: 19.3065419 },
    { lat: 49.3743949, lng: 19.3066204 },
    { lat: 49.3743901, lng: 19.3067334 },
    { lat: 49.374388, lng: 19.3068093 },
    { lat: 49.3743835, lng: 19.3068799 },
    { lat: 49.3743808, lng: 19.3069431 },
    { lat: 49.3743781, lng: 19.307006 },
    { lat: 49.3743753, lng: 19.3070667 },
    { lat: 49.3743741, lng: 19.3071348 },
    { lat: 49.3743737, lng: 19.3072463 },
    { lat: 49.3743756, lng: 19.3073119 },
    { lat: 49.3743764, lng: 19.3074485 },
    { lat: 49.3743781, lng: 19.3074863 },
    { lat: 49.3743729, lng: 19.3075646 },
    { lat: 49.3743643, lng: 19.3076928 },
    { lat: 49.374349, lng: 19.3077987 },
    { lat: 49.3743324, lng: 19.3078989 },
    { lat: 49.3743176, lng: 19.3079689 },
    { lat: 49.3743106, lng: 19.3080116 },
    { lat: 49.3742986, lng: 19.3080668 },
    { lat: 49.3742934, lng: 19.3081021 },
    { lat: 49.3742852, lng: 19.3081649 },
    { lat: 49.3742769, lng: 19.3082276 },
    { lat: 49.3742571, lng: 19.3083336 },
    { lat: 49.3742345, lng: 19.3084515 },
    { lat: 49.3741974, lng: 19.3085941 },
    { lat: 49.3741608, lng: 19.3087298 },
    { lat: 49.3741327, lng: 19.3088501 },
    { lat: 49.3741148, lng: 19.3089707 },
    { lat: 49.3740949, lng: 19.3091446 },
    { lat: 49.3740672, lng: 19.3092783 },
    { lat: 49.374041, lng: 19.3093886 },
    { lat: 49.3740266, lng: 19.3095146 },
    { lat: 49.3740122, lng: 19.3096152 },
    { lat: 49.373997, lng: 19.3097236 },
    { lat: 49.3739876, lng: 19.3098093 },
    { lat: 49.3739753, lng: 19.3099535 },
    { lat: 49.3739786, lng: 19.3100041 },
    { lat: 49.3739806, lng: 19.3100469 },
    { lat: 49.373982, lng: 19.3101205 },
    { lat: 49.3739844, lng: 19.3101713 },
    { lat: 49.3739864, lng: 19.3102394 },
    { lat: 49.3739876, lng: 19.3102653 },
    { lat: 49.3739921, lng: 19.3103595 },
    { lat: 49.373997, lng: 19.3104888 },
    { lat: 49.3739966, lng: 19.3105496 },
    { lat: 49.3739928, lng: 19.3106151 },
    { lat: 49.3739904, lng: 19.310704 },
    { lat: 49.3739856, lng: 19.3108174 },
    { lat: 49.3739853, lng: 19.310906 },
    { lat: 49.373985, lng: 19.3109748 },
    { lat: 49.3739772, lng: 19.3110704 },
    { lat: 49.3739651, lng: 19.311174 },
    { lat: 49.3739536, lng: 19.3113102 },
    { lat: 49.3739436, lng: 19.3114086 },
    { lat: 49.3739371, lng: 19.3114867 },
    { lat: 49.3739354, lng: 19.3115704 },
    { lat: 49.3739329, lng: 19.3116387 },
    { lat: 49.3739347, lng: 19.3117705 },
    { lat: 49.3739362, lng: 19.3118973 },
    { lat: 49.3739382, lng: 19.3120113 },
    { lat: 49.3739351, lng: 19.3121607 },
    { lat: 49.373933, lng: 19.3122618 },
    { lat: 49.3739277, lng: 19.312338 },
    { lat: 49.3739265, lng: 19.3124343 },
    { lat: 49.3739227, lng: 19.3125175 },
    { lat: 49.373916, lng: 19.3126416 },
    { lat: 49.3739077, lng: 19.3127499 },
    { lat: 49.3739055, lng: 19.3128485 },
    { lat: 49.373904, lng: 19.3129575 },
    { lat: 49.373902, lng: 19.3130865 },
    { lat: 49.3739009, lng: 19.3132284 },
    { lat: 49.3738989, lng: 19.3133758 },
    { lat: 49.3738992, lng: 19.3134817 },
    { lat: 49.3738509, lng: 19.3135786 },
    { lat: 49.3735924, lng: 19.313951 },
    { lat: 49.3734639, lng: 19.3141153 },
    { lat: 49.3733128, lng: 19.3143577 },
    { lat: 49.3731577, lng: 19.3146479 },
    { lat: 49.3729095, lng: 19.3150991 },
    { lat: 49.3728895, lng: 19.3151303 },
    { lat: 49.372571, lng: 19.3156378 },
    { lat: 49.372553, lng: 19.315736 },
    { lat: 49.3725007, lng: 19.3159898 },
    { lat: 49.372461, lng: 19.3161754 },
    { lat: 49.372438, lng: 19.3162835 },
    { lat: 49.3724132, lng: 19.3164041 },
    { lat: 49.3722673, lng: 19.3165146 },
    { lat: 49.3721173, lng: 19.3166281 },
    { lat: 49.3718596, lng: 19.3168027 },
    { lat: 49.3715266, lng: 19.3170073 },
    { lat: 49.371149, lng: 19.3173416 },
    { lat: 49.371013, lng: 19.3175135 },
    { lat: 49.3709161, lng: 19.3176363 },
    { lat: 49.3706703, lng: 19.3178219 },
    { lat: 49.3704456, lng: 19.3180054 },
    { lat: 49.3703254, lng: 19.318061 },
    { lat: 49.3700282, lng: 19.3182573 },
    { lat: 49.369931, lng: 19.3183245 },
    { lat: 49.3698252, lng: 19.3183998 },
    { lat: 49.3699401, lng: 19.3191638 },
    { lat: 49.3700182, lng: 19.3199106 },
    { lat: 49.3698099, lng: 19.3200602 },
    { lat: 49.3697706, lng: 19.3201325 },
    { lat: 49.3697625, lng: 19.3201323 },
    { lat: 49.3697165, lng: 19.3201327 },
    { lat: 49.369601, lng: 19.3200265 },
    { lat: 49.36957, lng: 19.3200151 },
    { lat: 49.3695203, lng: 19.3200386 },
    { lat: 49.3694695, lng: 19.3200569 },
    { lat: 49.3694123, lng: 19.3199916 },
    { lat: 49.3693707, lng: 19.3199441 },
    { lat: 49.3693318, lng: 19.3199322 },
    { lat: 49.369231, lng: 19.3198015 },
    { lat: 49.3691656, lng: 19.3197068 },
    { lat: 49.3691357, lng: 19.3196475 },
    { lat: 49.3691062, lng: 19.319568 },
    { lat: 49.3690833, lng: 19.3194689 },
    { lat: 49.3690686, lng: 19.3193722 },
    { lat: 49.3690525, lng: 19.3192449 },
    { lat: 49.369047, lng: 19.3191992 },
    { lat: 49.3690413, lng: 19.3191033 },
    { lat: 49.3690344, lng: 19.3189055 },
    { lat: 49.3690184, lng: 19.3188516 },
    { lat: 49.3689419, lng: 19.3188318 },
    { lat: 49.3689087, lng: 19.318825 },
    { lat: 49.3688593, lng: 19.3188742 },
    { lat: 49.3688, lng: 19.3188139 },
    { lat: 49.3687709, lng: 19.3187911 },
    { lat: 49.3687405, lng: 19.3187684 },
    { lat: 49.3686536, lng: 19.3186489 },
    { lat: 49.3685811, lng: 19.3186132 },
    { lat: 49.3684523, lng: 19.3186314 },
    { lat: 49.3683561, lng: 19.3187186 },
    { lat: 49.3683184, lng: 19.3187047 },
    { lat: 49.3682858, lng: 19.3186147 },
    { lat: 49.3682318, lng: 19.3185418 },
    { lat: 49.3681815, lng: 19.3184566 },
    { lat: 49.3680943, lng: 19.3183748 },
    { lat: 49.3680662, lng: 19.3183992 },
    { lat: 49.3680593, lng: 19.3184697 },
    { lat: 49.3680317, lng: 19.3184789 },
    { lat: 49.3679278, lng: 19.3183335 },
    { lat: 49.3678571, lng: 19.3182675 },
    { lat: 49.3678074, lng: 19.3183139 },
    { lat: 49.3678026, lng: 19.3183791 },
    { lat: 49.367782, lng: 19.3183966 },
    { lat: 49.3677242, lng: 19.3183889 },
    { lat: 49.367685, lng: 19.3184431 },
    { lat: 49.3677273, lng: 19.3185032 },
    { lat: 49.3676797, lng: 19.3185211 },
    { lat: 49.3676479, lng: 19.3185886 },
    { lat: 49.3676199, lng: 19.3185443 },
    { lat: 49.3675976, lng: 19.3185482 },
    { lat: 49.3675794, lng: 19.3185933 },
    { lat: 49.3675564, lng: 19.3186052 },
    { lat: 49.3675473, lng: 19.3186022 },
    { lat: 49.3674522, lng: 19.3185683 },
    { lat: 49.367397, lng: 19.3185964 },
    { lat: 49.3673758, lng: 19.3186212 },
    { lat: 49.3673433, lng: 19.3186729 },
    { lat: 49.3672731, lng: 19.3186929 },
    { lat: 49.3671974, lng: 19.3188289 },
    { lat: 49.3671212, lng: 19.3188163 },
    { lat: 49.3670695, lng: 19.3189862 },
    { lat: 49.3670894, lng: 19.3190477 },
    { lat: 49.3670676, lng: 19.3190671 },
    { lat: 49.366951, lng: 19.3190774 },
    { lat: 49.3669308, lng: 19.3191046 },
    { lat: 49.3668985, lng: 19.3191364 },
    { lat: 49.3667885, lng: 19.3193828 },
    { lat: 49.3667063, lng: 19.3194324 },
    { lat: 49.366668, lng: 19.3193878 },
    { lat: 49.3665749, lng: 19.3194451 },
    { lat: 49.3665325, lng: 19.3194787 },
    { lat: 49.3664687, lng: 19.319532 },
    { lat: 49.3664148, lng: 19.3195355 },
    { lat: 49.3663854, lng: 19.3195141 },
    { lat: 49.366333, lng: 19.3195242 },
    { lat: 49.366272, lng: 19.3194713 },
    { lat: 49.3662498, lng: 19.3194579 },
    { lat: 49.3662338, lng: 19.3194726 },
    { lat: 49.3661679, lng: 19.3195385 },
    { lat: 49.3661147, lng: 19.3195766 },
    { lat: 49.3660805, lng: 19.319593 },
    { lat: 49.3660094, lng: 19.3195925 },
    { lat: 49.3659441, lng: 19.3195954 },
    { lat: 49.3658753, lng: 19.3195929 },
    { lat: 49.3658079, lng: 19.3195949 },
    { lat: 49.3657712, lng: 19.3196796 },
    { lat: 49.3657154, lng: 19.3196673 },
    { lat: 49.3656458, lng: 19.3195788 },
    { lat: 49.3655753, lng: 19.3195403 },
    { lat: 49.3655288, lng: 19.3195563 },
    { lat: 49.3655022, lng: 19.3195679 },
    { lat: 49.3654361, lng: 19.319573 },
    { lat: 49.3654002, lng: 19.3195289 },
    { lat: 49.3653768, lng: 19.3195376 },
    { lat: 49.3653408, lng: 19.3196551 },
    { lat: 49.3652746, lng: 19.3197084 },
    { lat: 49.3652182, lng: 19.3197338 },
    { lat: 49.3652011, lng: 19.3197534 },
    { lat: 49.3651699, lng: 19.3197626 },
    { lat: 49.3651384, lng: 19.3197842 },
    { lat: 49.3650547, lng: 19.3198032 },
    { lat: 49.3650119, lng: 19.3198773 },
    { lat: 49.3649588, lng: 19.3198984 },
    { lat: 49.3649288, lng: 19.3200033 },
    { lat: 49.3649555, lng: 19.320184 },
    { lat: 49.3649567, lng: 19.3202348 },
    { lat: 49.3649417, lng: 19.3202513 },
    { lat: 49.3648735, lng: 19.3202364 },
    { lat: 49.3647719, lng: 19.3203514 },
    { lat: 49.3647591, lng: 19.3206059 },
    { lat: 49.3646128, lng: 19.3206131 },
    { lat: 49.3645838, lng: 19.3206904 },
    { lat: 49.3645369, lng: 19.3206757 },
    { lat: 49.3645033, lng: 19.3207051 },
    { lat: 49.3644722, lng: 19.3207869 },
    { lat: 49.364438, lng: 19.3209679 },
    { lat: 49.3644106, lng: 19.3210092 },
    { lat: 49.36437, lng: 19.3210586 },
    { lat: 49.364346, lng: 19.3210956 },
    { lat: 49.3643625, lng: 19.32123 },
    { lat: 49.3643013, lng: 19.3213442 },
    { lat: 49.3641925, lng: 19.3216914 },
    { lat: 49.3641384, lng: 19.3218561 },
    { lat: 49.364037, lng: 19.3219555 },
    { lat: 49.3639941, lng: 19.3220022 },
    { lat: 49.363843, lng: 19.321948 },
    { lat: 49.3638048, lng: 19.3219742 },
    { lat: 49.3636902, lng: 19.3220581 },
    { lat: 49.3636062, lng: 19.3221383 },
    { lat: 49.3635463, lng: 19.3221362 },
    { lat: 49.3634759, lng: 19.3221711 },
    { lat: 49.3633853, lng: 19.3221876 },
    { lat: 49.3633449, lng: 19.3222871 },
    { lat: 49.3632647, lng: 19.3223039 },
    { lat: 49.36322, lng: 19.3223125 },
    { lat: 49.3631986, lng: 19.3222888 },
    { lat: 49.3631455, lng: 19.3223348 },
    { lat: 49.363093, lng: 19.3223681 },
    { lat: 49.3630014, lng: 19.3223594 },
    { lat: 49.3629407, lng: 19.3223393 },
    { lat: 49.362878, lng: 19.3222758 },
    { lat: 49.362759, lng: 19.3223822 },
    { lat: 49.3627011, lng: 19.3224204 },
    { lat: 49.3626516, lng: 19.3225423 },
    { lat: 49.362569, lng: 19.3224885 },
    { lat: 49.3625391, lng: 19.3225001 },
    { lat: 49.3624133, lng: 19.3226264 },
    { lat: 49.3622634, lng: 19.3226281 },
    { lat: 49.3621828, lng: 19.3226374 },
    { lat: 49.3621131, lng: 19.3226418 },
    { lat: 49.3620692, lng: 19.3226423 },
    { lat: 49.3620351, lng: 19.3226595 },
    { lat: 49.36198, lng: 19.3226872 },
    { lat: 49.3618676, lng: 19.3227636 },
    { lat: 49.3618299, lng: 19.3227288 },
    { lat: 49.3617425, lng: 19.3226191 },
    { lat: 49.3616996, lng: 19.3226198 },
    { lat: 49.361658, lng: 19.3226689 },
    { lat: 49.3615836, lng: 19.3226938 },
    { lat: 49.3615408, lng: 19.3227375 },
    { lat: 49.3613729, lng: 19.3226924 },
    { lat: 49.361221, lng: 19.3226709 },
    { lat: 49.3611037, lng: 19.322674 },
    { lat: 49.3610818, lng: 19.3226833 },
    { lat: 49.3609843, lng: 19.3227194 },
    { lat: 49.3609486, lng: 19.322728 },
    { lat: 49.3608925, lng: 19.3226601 },
    { lat: 49.36085, lng: 19.3226659 },
    { lat: 49.3607963, lng: 19.3226993 },
    { lat: 49.3607177, lng: 19.3227311 },
    { lat: 49.3606395, lng: 19.3226982 },
    { lat: 49.3608373, lng: 19.3250097 },
    { lat: 49.3608379, lng: 19.3250223 },
    { lat: 49.3608399, lng: 19.3250654 },
    { lat: 49.3609664, lng: 19.3274619 },
    { lat: 49.3608252, lng: 19.3299026 },
    { lat: 49.3607749, lng: 19.3306056 },
    { lat: 49.3606896, lng: 19.3313536 },
    { lat: 49.3606848, lng: 19.3313968 },
    { lat: 49.3606735, lng: 19.3314942 },
    { lat: 49.360659, lng: 19.3323342 },
    { lat: 49.3606636, lng: 19.3325781 },
    { lat: 49.3606702, lng: 19.3328359 },
    { lat: 49.360736, lng: 19.3330842 },
    { lat: 49.360739, lng: 19.33344 },
    { lat: 49.3606016, lng: 19.3342285 },
    { lat: 49.3606023, lng: 19.3348473 },
    { lat: 49.3607461, lng: 19.3350726 },
    { lat: 49.3607456, lng: 19.3352795 },
    { lat: 49.3607444, lng: 19.3353035 },
    { lat: 49.3610078, lng: 19.3351563 },
    { lat: 49.3611421, lng: 19.3351585 },
    { lat: 49.3614233, lng: 19.335651 },
    { lat: 49.3613768, lng: 19.3357158 },
    { lat: 49.3610343, lng: 19.3358862 },
    { lat: 49.3609585, lng: 19.3360015 },
    { lat: 49.3608522, lng: 19.3360158 },
    { lat: 49.3607817, lng: 19.3361436 },
    { lat: 49.3607062, lng: 19.3361938 },
    { lat: 49.3605763, lng: 19.3363795 },
    { lat: 49.3605504, lng: 19.3364966 },
    { lat: 49.3604323, lng: 19.3366018 },
    { lat: 49.360329, lng: 19.3366301 },
    { lat: 49.3602978, lng: 19.3367605 },
    { lat: 49.3603414, lng: 19.336969 },
    { lat: 49.3601849, lng: 19.3371178 },
    { lat: 49.360174, lng: 19.3372173 },
    { lat: 49.3602413, lng: 19.3373578 },
    { lat: 49.3602263, lng: 19.3376873 },
    { lat: 49.3603397, lng: 19.3377934 },
    { lat: 49.3603239, lng: 19.3379141 },
    { lat: 49.3602778, lng: 19.3380385 },
    { lat: 49.3602972, lng: 19.3381401 },
    { lat: 49.3600253, lng: 19.3382731 },
    { lat: 49.359996, lng: 19.3383233 },
    { lat: 49.3600228, lng: 19.3384364 },
    { lat: 49.3599912, lng: 19.3384843 },
    { lat: 49.3598161, lng: 19.3384508 },
    { lat: 49.3596993, lng: 19.3386528 },
    { lat: 49.3596043, lng: 19.3386918 },
    { lat: 49.3593726, lng: 19.3386818 },
    { lat: 49.3593014, lng: 19.3386976 },
    { lat: 49.3591238, lng: 19.3388549 },
    { lat: 49.3590923, lng: 19.3389726 },
    { lat: 49.3590553, lng: 19.3389789 },
    { lat: 49.3590227, lng: 19.3388324 },
    { lat: 49.3587329, lng: 19.3389279 },
    { lat: 49.3587153, lng: 19.3389338 },
    { lat: 49.35866, lng: 19.3392707 },
    { lat: 49.3586448, lng: 19.3395967 },
    { lat: 49.3586006, lng: 19.3398774 },
    { lat: 49.3585909, lng: 19.3401485 },
    { lat: 49.3585421, lng: 19.3404319 },
    { lat: 49.3585291, lng: 19.340588 },
    { lat: 49.3585406, lng: 19.34059 },
    { lat: 49.3587779, lng: 19.3411832 },
    { lat: 49.3593961, lng: 19.3423259 },
    { lat: 49.3594979, lng: 19.3425042 },
    { lat: 49.3599011, lng: 19.3433611 },
    { lat: 49.3601681, lng: 19.3432399 },
    { lat: 49.3601901, lng: 19.3432296 },
    { lat: 49.3602907, lng: 19.3431842 },
    { lat: 49.3605818, lng: 19.342906 },
    { lat: 49.360986, lng: 19.342538 },
    { lat: 49.3613683, lng: 19.3422562 },
    { lat: 49.3614005, lng: 19.3423691 },
    { lat: 49.3616214, lng: 19.3431366 },
    { lat: 49.3616945, lng: 19.343275 },
    { lat: 49.3618637, lng: 19.3437368 },
    { lat: 49.3620391, lng: 19.3442342 },
    { lat: 49.3623681, lng: 19.3449753 },
    { lat: 49.3626972, lng: 19.34577 },
    { lat: 49.3627366, lng: 19.3457237 },
    { lat: 49.3627528, lng: 19.3457809 },
    { lat: 49.3629597, lng: 19.3464928 },
    { lat: 49.3630291, lng: 19.3466233 },
    { lat: 49.3632829, lng: 19.3474234 },
    { lat: 49.3634978, lng: 19.3487389 },
    { lat: 49.3638239, lng: 19.3495448 },
    { lat: 49.3641484, lng: 19.3506965 },
    { lat: 49.3645353, lng: 19.3516476 },
    { lat: 49.3648926, lng: 19.3533062 },
    { lat: 49.3650732, lng: 19.3535073 },
    { lat: 49.3651316, lng: 19.353984 },
    { lat: 49.3653091, lng: 19.3551782 },
    { lat: 49.3655621, lng: 19.3563454 },
    { lat: 49.3656708, lng: 19.356933 },
    { lat: 49.3657737, lng: 19.3576897 },
    { lat: 49.365799, lng: 19.3589972 },
    { lat: 49.365724, lng: 19.3591298 },
    { lat: 49.3658086, lng: 19.3598798 },
    { lat: 49.3664307, lng: 19.3610391 },
    { lat: 49.3667576, lng: 19.3617633 },
    { lat: 49.3670878, lng: 19.3619828 },
    { lat: 49.3672723, lng: 19.3624586 },
    { lat: 49.3673717, lng: 19.3634287 },
  ],
  Lokca: [
    { lat: 49.368841, lng: 19.3709564 },
    { lat: 49.3681482, lng: 19.3715637 },
    { lat: 49.367697, lng: 19.3722274 },
    { lat: 49.367753, lng: 19.3725593 },
    { lat: 49.3672968, lng: 19.3731201 },
    { lat: 49.3671404, lng: 19.3740205 },
    { lat: 49.3673906, lng: 19.3752286 },
    { lat: 49.3671838, lng: 19.377028 },
    { lat: 49.3667296, lng: 19.3776579 },
    { lat: 49.36617, lng: 19.3785355 },
    { lat: 49.3656479, lng: 19.3792802 },
    { lat: 49.365272, lng: 19.3798419 },
    { lat: 49.3652438, lng: 19.3799165 },
    { lat: 49.3650146, lng: 19.3802427 },
    { lat: 49.3648684, lng: 19.3793078 },
    { lat: 49.3648217, lng: 19.3785949 },
    { lat: 49.3646609, lng: 19.3780228 },
    { lat: 49.3644996, lng: 19.376831 },
    { lat: 49.3644933, lng: 19.375708 },
    { lat: 49.3644791, lng: 19.3743599 },
    { lat: 49.3638546, lng: 19.3752287 },
    { lat: 49.3629361, lng: 19.3767987 },
    { lat: 49.3625588, lng: 19.377383 },
    { lat: 49.362086, lng: 19.3781147 },
    { lat: 49.3615332, lng: 19.3789715 },
    { lat: 49.3624311, lng: 19.3805921 },
    { lat: 49.3631837, lng: 19.3816274 },
    { lat: 49.3623568, lng: 19.382504 },
    { lat: 49.3623419, lng: 19.3825194 },
    { lat: 49.3608982, lng: 19.3850355 },
    { lat: 49.3599019, lng: 19.3862906 },
    { lat: 49.3587367, lng: 19.3881492 },
    { lat: 49.3583028, lng: 19.3897709 },
    { lat: 49.3576728, lng: 19.3912999 },
    { lat: 49.3569061, lng: 19.392743 },
    { lat: 49.3567118, lng: 19.3931691 },
    { lat: 49.3565615, lng: 19.3935737 },
    { lat: 49.3563617, lng: 19.3943661 },
    { lat: 49.3563294, lng: 19.3944943 },
    { lat: 49.3559925, lng: 19.3941797 },
    { lat: 49.3557745, lng: 19.3936601 },
    { lat: 49.3555777, lng: 19.3936172 },
    { lat: 49.3553341, lng: 19.3935682 },
    { lat: 49.3551122, lng: 19.3933964 },
    { lat: 49.3549233, lng: 19.3934767 },
    { lat: 49.3548498, lng: 19.3937149 },
    { lat: 49.354579, lng: 19.3937321 },
    { lat: 49.354483, lng: 19.394044 },
    { lat: 49.3544683, lng: 19.394093 },
    { lat: 49.354324, lng: 19.3942844 },
    { lat: 49.354124, lng: 19.3944168 },
    { lat: 49.3540904, lng: 19.3948824 },
    { lat: 49.3539505, lng: 19.3950241 },
    { lat: 49.3539894, lng: 19.3954945 },
    { lat: 49.3540371, lng: 19.3959679 },
    { lat: 49.3540327, lng: 19.3960399 },
    { lat: 49.3540279, lng: 19.3961098 },
    { lat: 49.3540066, lng: 19.3961845 },
    { lat: 49.3539757, lng: 19.3962934 },
    { lat: 49.3539485, lng: 19.3963871 },
    { lat: 49.3538584, lng: 19.3966553 },
    { lat: 49.353749, lng: 19.3967485 },
    { lat: 49.3537624, lng: 19.3969676 },
    { lat: 49.3535706, lng: 19.3972303 },
    { lat: 49.3533919, lng: 19.3972161 },
    { lat: 49.3531832, lng: 19.3973277 },
    { lat: 49.3531006, lng: 19.3976143 },
    { lat: 49.352839, lng: 19.3977046 },
    { lat: 49.3526425, lng: 19.397868 },
    { lat: 49.3527021, lng: 19.3981294 },
    { lat: 49.352512, lng: 19.3980879 },
    { lat: 49.3522862, lng: 19.3982997 },
    { lat: 49.3520091, lng: 19.3984437 },
    { lat: 49.3518287, lng: 19.3985913 },
    { lat: 49.3516552, lng: 19.3986384 },
    { lat: 49.3516315, lng: 19.3991972 },
    { lat: 49.3514525, lng: 19.3994198 },
    { lat: 49.3514073, lng: 19.399754 },
    { lat: 49.3511939, lng: 19.3998199 },
    { lat: 49.3509509, lng: 19.4002421 },
    { lat: 49.3506555, lng: 19.4007206 },
    { lat: 49.3506374, lng: 19.4011787 },
    { lat: 49.3504162, lng: 19.4017109 },
    { lat: 49.3504791, lng: 19.4019209 },
    { lat: 49.3503986, lng: 19.4021295 },
    { lat: 49.3504102, lng: 19.40267 },
    { lat: 49.3503078, lng: 19.4030632 },
    { lat: 49.350365, lng: 19.4034466 },
    { lat: 49.3501849, lng: 19.4038645 },
    { lat: 49.3500611, lng: 19.4043778 },
    { lat: 49.3499306, lng: 19.4050097 },
    { lat: 49.3500299, lng: 19.4053541 },
    { lat: 49.3500292, lng: 19.4060467 },
    { lat: 49.3497329, lng: 19.4062864 },
    { lat: 49.3494792, lng: 19.4066768 },
    { lat: 49.3491233, lng: 19.4070928 },
    { lat: 49.3483657, lng: 19.4071812 },
    { lat: 49.3480932, lng: 19.4072072 },
    { lat: 49.3477264, lng: 19.4069554 },
    { lat: 49.3473448, lng: 19.4067927 },
    { lat: 49.3470547, lng: 19.4068032 },
    { lat: 49.3465288, lng: 19.4073475 },
    { lat: 49.3464233, lng: 19.4073788 },
    { lat: 49.3458979, lng: 19.4072773 },
    { lat: 49.3456403, lng: 19.4073323 },
    { lat: 49.345593, lng: 19.4074332 },
    { lat: 49.3452195, lng: 19.4081984 },
    { lat: 49.3451876, lng: 19.4082644 },
    { lat: 49.3451156, lng: 19.4084124 },
    { lat: 49.3450029, lng: 19.4086545 },
    { lat: 49.3448366, lng: 19.4090752 },
    { lat: 49.3446458, lng: 19.4096766 },
    { lat: 49.3444898, lng: 19.4101775 },
    { lat: 49.3443907, lng: 19.4103736 },
    { lat: 49.3443256, lng: 19.4105032 },
    { lat: 49.3442622, lng: 19.4106177 },
    { lat: 49.3442196, lng: 19.4105692 },
    { lat: 49.3443079, lng: 19.4099724 },
    { lat: 49.344148, lng: 19.4097581 },
    { lat: 49.3433555, lng: 19.4101831 },
    { lat: 49.3422668, lng: 19.4109601 },
    { lat: 49.3414922, lng: 19.4115587 },
    { lat: 49.3406455, lng: 19.4123741 },
    { lat: 49.3400923, lng: 19.4131033 },
    { lat: 49.3393349, lng: 19.414073 },
    { lat: 49.3390784, lng: 19.4144495 },
    { lat: 49.3390764, lng: 19.4144529 },
    { lat: 49.3386704, lng: 19.4153942 },
    { lat: 49.3385172, lng: 19.4156655 },
    { lat: 49.3383323, lng: 19.4159559 },
    { lat: 49.3359867, lng: 19.4198577 },
    { lat: 49.3354776, lng: 19.4205665 },
    { lat: 49.3351309, lng: 19.4208421 },
    { lat: 49.3351102, lng: 19.4208584 },
    { lat: 49.334276, lng: 19.4215316 },
    { lat: 49.3335052, lng: 19.4224081 },
    { lat: 49.3333986, lng: 19.4225393 },
    { lat: 49.3327715, lng: 19.4233048 },
    { lat: 49.3318108, lng: 19.4238015 },
    { lat: 49.3317351, lng: 19.4238 },
    { lat: 49.3315049, lng: 19.4237938 },
    { lat: 49.3314551, lng: 19.4237887 },
    { lat: 49.3309496, lng: 19.4237745 },
    { lat: 49.3305016, lng: 19.4238302 },
    { lat: 49.3301438, lng: 19.4238895 },
    { lat: 49.3297037, lng: 19.4235372 },
    { lat: 49.3291146, lng: 19.4230768 },
    { lat: 49.328571, lng: 19.4224322 },
    { lat: 49.3283867, lng: 19.4220786 },
    { lat: 49.3282893, lng: 19.422238 },
    { lat: 49.3282232, lng: 19.4223467 },
    { lat: 49.326356, lng: 19.425429 },
    { lat: 49.326783, lng: 19.426051 },
    { lat: 49.327204, lng: 19.42686 },
    { lat: 49.327406, lng: 19.427249 },
    { lat: 49.3286123, lng: 19.4284885 },
    { lat: 49.3297042, lng: 19.4296602 },
    { lat: 49.330276, lng: 19.430513 },
    { lat: 49.3312842, lng: 19.4321228 },
    { lat: 49.3323048, lng: 19.4340509 },
    { lat: 49.3328356, lng: 19.4358413 },
    { lat: 49.334568, lng: 19.439378 },
    { lat: 49.334709, lng: 19.438679 },
    { lat: 49.334847, lng: 19.438035 },
    { lat: 49.335086, lng: 19.436897 },
    { lat: 49.335371, lng: 19.43555 },
    { lat: 49.335872, lng: 19.433542 },
    { lat: 49.336369, lng: 19.434713 },
    { lat: 49.336918, lng: 19.435286 },
    { lat: 49.337776, lng: 19.435804 },
    { lat: 49.33916, lng: 19.436406 },
    { lat: 49.33979, lng: 19.436942 },
    { lat: 49.340377, lng: 19.43744 },
    { lat: 49.34096, lng: 19.438186 },
    { lat: 49.341399, lng: 19.438686 },
    { lat: 49.341923, lng: 19.439318 },
    { lat: 49.342239, lng: 19.43984 },
    { lat: 49.342991, lng: 19.440633 },
    { lat: 49.343397, lng: 19.440992 },
    { lat: 49.344035, lng: 19.441886 },
    { lat: 49.344165, lng: 19.442099 },
    { lat: 49.344422, lng: 19.442499 },
    { lat: 49.344665, lng: 19.443189 },
    { lat: 49.344892, lng: 19.443553 },
    { lat: 49.345243, lng: 19.444115 },
    { lat: 49.345788, lng: 19.444571 },
    { lat: 49.346256, lng: 19.444881 },
    { lat: 49.346355, lng: 19.444918 },
    { lat: 49.346688, lng: 19.44519 },
    { lat: 49.347077, lng: 19.445984 },
    { lat: 49.347501, lng: 19.446794 },
    { lat: 49.347644, lng: 19.447444 },
    { lat: 49.347736, lng: 19.448118 },
    { lat: 49.347767, lng: 19.448849 },
    { lat: 49.347821, lng: 19.449688 },
    { lat: 49.347993, lng: 19.450848 },
    { lat: 49.348031, lng: 19.451301 },
    { lat: 49.348161, lng: 19.452148 },
    { lat: 49.348619, lng: 19.453037 },
    { lat: 49.348938, lng: 19.453899 },
    { lat: 49.349355, lng: 19.454678 },
    { lat: 49.349811, lng: 19.45522 },
    { lat: 49.350539, lng: 19.455944 },
    { lat: 49.350739, lng: 19.456255 },
    { lat: 49.350958, lng: 19.456685 },
    { lat: 49.351236, lng: 19.457306 },
    { lat: 49.351313, lng: 19.458073 },
    { lat: 49.351338, lng: 19.458408 },
    { lat: 49.351629, lng: 19.459627 },
    { lat: 49.351715, lng: 19.460605 },
    { lat: 49.351669, lng: 19.462086 },
    { lat: 49.352, lng: 19.46415 },
    { lat: 49.3521965, lng: 19.4637874 },
    { lat: 49.3525955, lng: 19.463031 },
    { lat: 49.3534109, lng: 19.4615515 },
    { lat: 49.353528, lng: 19.4614019 },
    { lat: 49.3536607, lng: 19.4612727 },
    { lat: 49.3537982, lng: 19.4610034 },
    { lat: 49.3540178, lng: 19.4607828 },
    { lat: 49.3541186, lng: 19.4606682 },
    { lat: 49.3541608, lng: 19.4606308 },
    { lat: 49.3542597, lng: 19.4605728 },
    { lat: 49.3543298, lng: 19.4605581 },
    { lat: 49.3544786, lng: 19.4603111 },
    { lat: 49.3546536, lng: 19.4599984 },
    { lat: 49.3547251, lng: 19.4599166 },
    { lat: 49.3547482, lng: 19.4598021 },
    { lat: 49.3548344, lng: 19.4595445 },
    { lat: 49.3549048, lng: 19.459289 },
    { lat: 49.3550245, lng: 19.4590473 },
    { lat: 49.3552936, lng: 19.4586504 },
    { lat: 49.3554086, lng: 19.4584811 },
    { lat: 49.3555065, lng: 19.458381 },
    { lat: 49.3556164, lng: 19.4581971 },
    { lat: 49.3556523, lng: 19.4580942 },
    { lat: 49.3557732, lng: 19.4579055 },
    { lat: 49.3558351, lng: 19.4578074 },
    { lat: 49.356027, lng: 19.4575695 },
    { lat: 49.3562378, lng: 19.4571536 },
    { lat: 49.3563414, lng: 19.456931 },
    { lat: 49.3564739, lng: 19.4568249 },
    { lat: 49.3565793, lng: 19.4566917 },
    { lat: 49.3566468, lng: 19.4565178 },
    { lat: 49.356802, lng: 19.4564357 },
    { lat: 49.3569353, lng: 19.4562482 },
    { lat: 49.3570753, lng: 19.4561818 },
    { lat: 49.357199, lng: 19.4560785 },
    { lat: 49.3573997, lng: 19.455883 },
    { lat: 49.3576412, lng: 19.4556976 },
    { lat: 49.3578871, lng: 19.4554322 },
    { lat: 49.3579096, lng: 19.4553828 },
    { lat: 49.3579902, lng: 19.4551991 },
    { lat: 49.3580555, lng: 19.4549763 },
    { lat: 49.3581009, lng: 19.4545933 },
    { lat: 49.3581243, lng: 19.4543671 },
    { lat: 49.3581277, lng: 19.4540703 },
    { lat: 49.3582352, lng: 19.4535892 },
    { lat: 49.3582785, lng: 19.4532584 },
    { lat: 49.3583597, lng: 19.4528203 },
    { lat: 49.3583883, lng: 19.4527532 },
    { lat: 49.3584327, lng: 19.4524722 },
    { lat: 49.3585197, lng: 19.4520324 },
    { lat: 49.358709, lng: 19.4516664 },
    { lat: 49.358741, lng: 19.4513824 },
    { lat: 49.3587687, lng: 19.4512956 },
    { lat: 49.3588718, lng: 19.4510173 },
    { lat: 49.3590388, lng: 19.4509263 },
    { lat: 49.3592034, lng: 19.4507528 },
    { lat: 49.359326, lng: 19.4506044 },
    { lat: 49.3593905, lng: 19.4505584 },
    { lat: 49.359437, lng: 19.4504457 },
    { lat: 49.359507, lng: 19.450372 },
    { lat: 49.3596434, lng: 19.4502781 },
    { lat: 49.359665, lng: 19.4501362 },
    { lat: 49.3597064, lng: 19.4500295 },
    { lat: 49.3597807, lng: 19.4499557 },
    { lat: 49.3598154, lng: 19.4498272 },
    { lat: 49.3599557, lng: 19.4496451 },
    { lat: 49.3600214, lng: 19.4495024 },
    { lat: 49.3601092, lng: 19.4492808 },
    { lat: 49.3600843, lng: 19.4491808 },
    { lat: 49.3601089, lng: 19.4490321 },
    { lat: 49.3602801, lng: 19.4488828 },
    { lat: 49.3603446, lng: 19.4486185 },
    { lat: 49.3602998, lng: 19.4485008 },
    { lat: 49.3603223, lng: 19.4484517 },
    { lat: 49.3603078, lng: 19.448407 },
    { lat: 49.3603487, lng: 19.4482659 },
    { lat: 49.3604009, lng: 19.4482513 },
    { lat: 49.3604336, lng: 19.4482011 },
    { lat: 49.360532, lng: 19.4480622 },
    { lat: 49.3605494, lng: 19.4478282 },
    { lat: 49.360505, lng: 19.4476237 },
    { lat: 49.3605067, lng: 19.4473886 },
    { lat: 49.3605321, lng: 19.4469401 },
    { lat: 49.3606108, lng: 19.4467617 },
    { lat: 49.3607065, lng: 19.4465894 },
    { lat: 49.3607547, lng: 19.4464143 },
    { lat: 49.3607021, lng: 19.4463242 },
    { lat: 49.3606303, lng: 19.4461339 },
    { lat: 49.3606428, lng: 19.445989 },
    { lat: 49.3606555, lng: 19.4458709 },
    { lat: 49.3606924, lng: 19.4457693 },
    { lat: 49.3607445, lng: 19.4456042 },
    { lat: 49.3607765, lng: 19.4454688 },
    { lat: 49.360842, lng: 19.4453009 },
    { lat: 49.3608342, lng: 19.4450082 },
    { lat: 49.3609502, lng: 19.4448811 },
    { lat: 49.3610506, lng: 19.4448883 },
    { lat: 49.3611332, lng: 19.4448198 },
    { lat: 49.3613129, lng: 19.4447332 },
    { lat: 49.3614149, lng: 19.4446723 },
    { lat: 49.3616351, lng: 19.4445368 },
    { lat: 49.3617769, lng: 19.4444564 },
    { lat: 49.3620068, lng: 19.4443112 },
    { lat: 49.3621059, lng: 19.4441881 },
    { lat: 49.3621528, lng: 19.4439607 },
    { lat: 49.3622667, lng: 19.4439166 },
    { lat: 49.3623733, lng: 19.443903 },
    { lat: 49.3624495, lng: 19.4438441 },
    { lat: 49.3624844, lng: 19.4438176 },
    { lat: 49.3625729, lng: 19.4436374 },
    { lat: 49.362675, lng: 19.4435537 },
    { lat: 49.362821, lng: 19.4435709 },
    { lat: 49.3629113, lng: 19.443402 },
    { lat: 49.3630096, lng: 19.4435068 },
    { lat: 49.3631325, lng: 19.4434577 },
    { lat: 49.3634332, lng: 19.4431378 },
    { lat: 49.3635237, lng: 19.4430731 },
    { lat: 49.3635944, lng: 19.4429415 },
    { lat: 49.3636386, lng: 19.4429736 },
    { lat: 49.3636864, lng: 19.4429345 },
    { lat: 49.3637712, lng: 19.4428895 },
    { lat: 49.3638202, lng: 19.4428763 },
    { lat: 49.3639313, lng: 19.442818 },
    { lat: 49.3640465, lng: 19.4425312 },
    { lat: 49.3641752, lng: 19.4424142 },
    { lat: 49.3643187, lng: 19.4426431 },
    { lat: 49.3643602, lng: 19.4426878 },
    { lat: 49.3644547, lng: 19.4427094 },
    { lat: 49.3645195, lng: 19.4427993 },
    { lat: 49.3645633, lng: 19.4428423 },
    { lat: 49.3647139, lng: 19.4427852 },
    { lat: 49.3648004, lng: 19.4427773 },
    { lat: 49.3649584, lng: 19.4427371 },
    { lat: 49.3650669, lng: 19.4426687 },
    { lat: 49.3650917, lng: 19.4426475 },
    { lat: 49.36518, lng: 19.4424583 },
    { lat: 49.3652796, lng: 19.4423427 },
    { lat: 49.3653366, lng: 19.4421862 },
    { lat: 49.3653807, lng: 19.4419504 },
    { lat: 49.3653726, lng: 19.4418924 },
    { lat: 49.3652286, lng: 19.4418248 },
    { lat: 49.3652074, lng: 19.4417356 },
    { lat: 49.3652619, lng: 19.4415431 },
    { lat: 49.365387, lng: 19.4412303 },
    { lat: 49.3654755, lng: 19.441045 },
    { lat: 49.3654696, lng: 19.4409159 },
    { lat: 49.3654671, lng: 19.4408619 },
    { lat: 49.3655307, lng: 19.4407253 },
    { lat: 49.3652768, lng: 19.4403496 },
    { lat: 49.3652896, lng: 19.440239 },
    { lat: 49.3653131, lng: 19.4399892 },
    { lat: 49.3654123, lng: 19.4397702 },
    { lat: 49.3653953, lng: 19.4396466 },
    { lat: 49.3653376, lng: 19.4395943 },
    { lat: 49.365285, lng: 19.4396003 },
    { lat: 49.3651967, lng: 19.4396406 },
    { lat: 49.365159, lng: 19.4395554 },
    { lat: 49.3651492, lng: 19.4393955 },
    { lat: 49.3651298, lng: 19.4392646 },
    { lat: 49.3651218, lng: 19.4390923 },
    { lat: 49.3651997, lng: 19.4389743 },
    { lat: 49.3651821, lng: 19.438832 },
    { lat: 49.3650898, lng: 19.4386167 },
    { lat: 49.3650733, lng: 19.4383835 },
    { lat: 49.3651443, lng: 19.4381343 },
    { lat: 49.3652109, lng: 19.4377721 },
    { lat: 49.3652588, lng: 19.4375923 },
    { lat: 49.3653002, lng: 19.4375311 },
    { lat: 49.3654047, lng: 19.4374262 },
    { lat: 49.3653915, lng: 19.4372884 },
    { lat: 49.365398, lng: 19.4371847 },
    { lat: 49.3655109, lng: 19.4369245 },
    { lat: 49.3656586, lng: 19.4367787 },
    { lat: 49.3657799, lng: 19.4367204 },
    { lat: 49.3660043, lng: 19.4368205 },
    { lat: 49.366081, lng: 19.4367009 },
    { lat: 49.3661983, lng: 19.4365317 },
    { lat: 49.3662919, lng: 19.4364872 },
    { lat: 49.3664071, lng: 19.43664 },
    { lat: 49.3664918, lng: 19.4366189 },
    { lat: 49.3666278, lng: 19.4362437 },
    { lat: 49.3666581, lng: 19.4360214 },
    { lat: 49.366785, lng: 19.4358648 },
    { lat: 49.366915, lng: 19.43573 },
    { lat: 49.3671683, lng: 19.4356011 },
    { lat: 49.3672194, lng: 19.4355616 },
    { lat: 49.367417, lng: 19.4353959 },
    { lat: 49.3676486, lng: 19.4352418 },
    { lat: 49.3677312, lng: 19.4352257 },
    { lat: 49.3678068, lng: 19.4351763 },
    { lat: 49.3679172, lng: 19.4349066 },
    { lat: 49.3680182, lng: 19.4349481 },
    { lat: 49.3681091, lng: 19.4350138 },
    { lat: 49.3681539, lng: 19.4351301 },
    { lat: 49.3682217, lng: 19.4351365 },
    { lat: 49.3682885, lng: 19.4350902 },
    { lat: 49.3684508, lng: 19.4352144 },
    { lat: 49.3684818, lng: 19.4352779 },
    { lat: 49.3685076, lng: 19.4352473 },
    { lat: 49.3685768, lng: 19.4351867 },
    { lat: 49.3686362, lng: 19.4351076 },
    { lat: 49.3687583, lng: 19.4351128 },
    { lat: 49.3687176, lng: 19.4352347 },
    { lat: 49.3687596, lng: 19.4353376 },
    { lat: 49.368865, lng: 19.4353753 },
    { lat: 49.3689231, lng: 19.435022 },
    { lat: 49.3689879, lng: 19.4350348 },
    { lat: 49.3691927, lng: 19.4352973 },
    { lat: 49.3693021, lng: 19.4355183 },
    { lat: 49.3693875, lng: 19.435517 },
    { lat: 49.3695587, lng: 19.4354146 },
    { lat: 49.3696963, lng: 19.4354185 },
    { lat: 49.3700366, lng: 19.4354667 },
    { lat: 49.3700532, lng: 19.4354638 },
    { lat: 49.3701294, lng: 19.4355026 },
    { lat: 49.3702252, lng: 19.435476 },
    { lat: 49.3703956, lng: 19.4352894 },
    { lat: 49.3704427, lng: 19.4352825 },
    { lat: 49.370534, lng: 19.4353309 },
    { lat: 49.3705066, lng: 19.4355208 },
    { lat: 49.370597, lng: 19.4357008 },
    { lat: 49.3707414, lng: 19.4356056 },
    { lat: 49.3708931, lng: 19.4356174 },
    { lat: 49.3711905, lng: 19.4359852 },
    { lat: 49.3713745, lng: 19.4359197 },
    { lat: 49.3716451, lng: 19.4364119 },
    { lat: 49.3717438, lng: 19.4365495 },
    { lat: 49.3717969, lng: 19.4365755 },
    { lat: 49.3719151, lng: 19.4366962 },
    { lat: 49.3720255, lng: 19.4368694 },
    { lat: 49.3721758, lng: 19.4369999 },
    { lat: 49.3722798, lng: 19.4371749 },
    { lat: 49.3722865, lng: 19.4372248 },
    { lat: 49.3723495, lng: 19.4374895 },
    { lat: 49.3724658, lng: 19.4377897 },
    { lat: 49.372556, lng: 19.4378657 },
    { lat: 49.3726432, lng: 19.4378736 },
    { lat: 49.3727689, lng: 19.4379363 },
    { lat: 49.3728223, lng: 19.4379486 },
    { lat: 49.3729558, lng: 19.4378586 },
    { lat: 49.3730104, lng: 19.4378943 },
    { lat: 49.3731073, lng: 19.4380914 },
    { lat: 49.3731478, lng: 19.4381149 },
    { lat: 49.3731841, lng: 19.4381706 },
    { lat: 49.3733003, lng: 19.438196 },
    { lat: 49.3734772, lng: 19.4382221 },
    { lat: 49.3734989, lng: 19.4382747 },
    { lat: 49.3734451, lng: 19.4383315 },
    { lat: 49.3734155, lng: 19.4384732 },
    { lat: 49.3734673, lng: 19.4385468 },
    { lat: 49.3734821, lng: 19.4385499 },
    { lat: 49.373544, lng: 19.4385446 },
    { lat: 49.3737297, lng: 19.438368 },
    { lat: 49.3738616, lng: 19.4381986 },
    { lat: 49.3740806, lng: 19.4382321 },
    { lat: 49.3741538, lng: 19.4381855 },
    { lat: 49.3742059, lng: 19.4380916 },
    { lat: 49.3742996, lng: 19.4379973 },
    { lat: 49.3743587, lng: 19.4378875 },
    { lat: 49.3744245, lng: 19.437726 },
    { lat: 49.3746838, lng: 19.4376813 },
    { lat: 49.3748537, lng: 19.4376995 },
    { lat: 49.3752011, lng: 19.4378546 },
    { lat: 49.3753053, lng: 19.4378882 },
    { lat: 49.3754172, lng: 19.4378711 },
    { lat: 49.3757351, lng: 19.4379705 },
    { lat: 49.3758759, lng: 19.4381213 },
    { lat: 49.3759117, lng: 19.4383817 },
    { lat: 49.375967, lng: 19.4384593 },
    { lat: 49.3760841, lng: 19.4386028 },
    { lat: 49.3763183, lng: 19.4384777 },
    { lat: 49.3765839, lng: 19.4384928 },
    { lat: 49.376699, lng: 19.4384236 },
    { lat: 49.3767798, lng: 19.4384398 },
    { lat: 49.3768432, lng: 19.4385208 },
    { lat: 49.3768853, lng: 19.4385311 },
    { lat: 49.376918, lng: 19.4385297 },
    { lat: 49.3769761, lng: 19.4384743 },
    { lat: 49.3770014, lng: 19.4384328 },
    { lat: 49.3770538, lng: 19.438352 },
    { lat: 49.3771967, lng: 19.4383933 },
    { lat: 49.3773026, lng: 19.4383959 },
    { lat: 49.3773308, lng: 19.4384175 },
    { lat: 49.3773881, lng: 19.4384171 },
    { lat: 49.3775017, lng: 19.4384847 },
    { lat: 49.3775335, lng: 19.4383648 },
    { lat: 49.3775181, lng: 19.4382298 },
    { lat: 49.377483, lng: 19.4381494 },
    { lat: 49.3774567, lng: 19.4380708 },
    { lat: 49.3774832, lng: 19.4378879 },
    { lat: 49.3774892, lng: 19.4377256 },
    { lat: 49.3775007, lng: 19.4375099 },
    { lat: 49.3774991, lng: 19.4375003 },
    { lat: 49.3775388, lng: 19.4374812 },
    { lat: 49.377583, lng: 19.4374598 },
    { lat: 49.3776344, lng: 19.4374278 },
    { lat: 49.3776674, lng: 19.437387 },
    { lat: 49.3777681, lng: 19.4373223 },
    { lat: 49.3778686, lng: 19.4372995 },
    { lat: 49.3779221, lng: 19.4373382 },
    { lat: 49.3779878, lng: 19.4373188 },
    { lat: 49.3780158, lng: 19.4372398 },
    { lat: 49.3779796, lng: 19.4371667 },
    { lat: 49.3779356, lng: 19.4369953 },
    { lat: 49.3779416, lng: 19.4369068 },
    { lat: 49.3780057, lng: 19.4369491 },
    { lat: 49.3781265, lng: 19.4369588 },
    { lat: 49.3781717, lng: 19.4369803 },
    { lat: 49.3783695, lng: 19.4367535 },
    { lat: 49.3784127, lng: 19.436712 },
    { lat: 49.3784585, lng: 19.4365786 },
    { lat: 49.3785791, lng: 19.4364347 },
    { lat: 49.3786558, lng: 19.4363628 },
    { lat: 49.3788139, lng: 19.4362213 },
    { lat: 49.3788742, lng: 19.4361164 },
    { lat: 49.3789292, lng: 19.4360851 },
    { lat: 49.3790256, lng: 19.4360968 },
    { lat: 49.3790712, lng: 19.4360354 },
    { lat: 49.3791745, lng: 19.4360279 },
    { lat: 49.379334, lng: 19.4360421 },
    { lat: 49.3794794, lng: 19.4359953 },
    { lat: 49.3796181, lng: 19.4360226 },
    { lat: 49.3796884, lng: 19.436032 },
    { lat: 49.379739, lng: 19.4361035 },
    { lat: 49.3799179, lng: 19.4361225 },
    { lat: 49.3799711, lng: 19.4359566 },
    { lat: 49.3799882, lng: 19.4358144 },
    { lat: 49.380051, lng: 19.435783 },
    { lat: 49.3801123, lng: 19.4358889 },
    { lat: 49.3802295, lng: 19.4359174 },
    { lat: 49.3803965, lng: 19.4359431 },
    { lat: 49.3804793, lng: 19.4357364 },
    { lat: 49.3803896, lng: 19.4356976 },
    { lat: 49.3803786, lng: 19.4356058 },
    { lat: 49.380465, lng: 19.4355238 },
    { lat: 49.3804986, lng: 19.4354236 },
    { lat: 49.3805517, lng: 19.4352801 },
    { lat: 49.380679, lng: 19.4352551 },
    { lat: 49.3807543, lng: 19.4352495 },
    { lat: 49.3808498, lng: 19.4352663 },
    { lat: 49.380885, lng: 19.4352466 },
    { lat: 49.3809866, lng: 19.4352013 },
    { lat: 49.3810295, lng: 19.4350567 },
    { lat: 49.380968, lng: 19.4349219 },
    { lat: 49.3809081, lng: 19.4348658 },
    { lat: 49.3808975, lng: 19.4348561 },
    { lat: 49.3808723, lng: 19.4347041 },
    { lat: 49.3808614, lng: 19.4346629 },
    { lat: 49.3808907, lng: 19.4345147 },
    { lat: 49.3809459, lng: 19.4344169 },
    { lat: 49.3809604, lng: 19.4343926 },
    { lat: 49.3810114, lng: 19.4342757 },
    { lat: 49.3810612, lng: 19.4341097 },
    { lat: 49.3811079, lng: 19.4339958 },
    { lat: 49.3811393, lng: 19.4339721 },
    { lat: 49.3813254, lng: 19.4338287 },
    { lat: 49.3814751, lng: 19.4337514 },
    { lat: 49.3822265, lng: 19.4339439 },
    { lat: 49.3824779, lng: 19.4338697 },
    { lat: 49.3826704, lng: 19.433697 },
    { lat: 49.3824758, lng: 19.4337025 },
    { lat: 49.3822083, lng: 19.4337437 },
    { lat: 49.3815181, lng: 19.4335319 },
    { lat: 49.3814259, lng: 19.4334627 },
    { lat: 49.3810121, lng: 19.433118 },
    { lat: 49.3804997, lng: 19.432291 },
    { lat: 49.3802737, lng: 19.4314725 },
    { lat: 49.3802412, lng: 19.4313516 },
    { lat: 49.3801768, lng: 19.4304921 },
    { lat: 49.3796482, lng: 19.4282666 },
    { lat: 49.3794779, lng: 19.4271416 },
    { lat: 49.379643, lng: 19.4270083 },
    { lat: 49.3798735, lng: 19.4269237 },
    { lat: 49.3801011, lng: 19.4270462 },
    { lat: 49.3806199, lng: 19.4265908 },
    { lat: 49.3809661, lng: 19.426209 },
    { lat: 49.3821344, lng: 19.4248898 },
    { lat: 49.3825027, lng: 19.4243457 },
    { lat: 49.3823943, lng: 19.4240952 },
    { lat: 49.3830342, lng: 19.4228744 },
    { lat: 49.3834092, lng: 19.4221911 },
    { lat: 49.3841082, lng: 19.4216614 },
    { lat: 49.3844994, lng: 19.4203983 },
    { lat: 49.3850072, lng: 19.419927 },
    { lat: 49.3851404, lng: 19.4192467 },
    { lat: 49.3857418, lng: 19.4192415 },
    { lat: 49.3862554, lng: 19.4193811 },
    { lat: 49.386743, lng: 19.4197438 },
    { lat: 49.3870596, lng: 19.418768 },
    { lat: 49.3871864, lng: 19.4178026 },
    { lat: 49.3874746, lng: 19.4173116 },
    { lat: 49.3880227, lng: 19.4166901 },
    { lat: 49.3881791, lng: 19.4169509 },
    { lat: 49.3889108, lng: 19.4160367 },
    { lat: 49.3890129, lng: 19.416171 },
    { lat: 49.3893756, lng: 19.4154111 },
    { lat: 49.3896554, lng: 19.4149828 },
    { lat: 49.3899802, lng: 19.4148423 },
    { lat: 49.3905908, lng: 19.4151334 },
    { lat: 49.3909478, lng: 19.4154198 },
    { lat: 49.3912114, lng: 19.4152986 },
    { lat: 49.3915682, lng: 19.4150707 },
    { lat: 49.3920258, lng: 19.4147794 },
    { lat: 49.3923177, lng: 19.4145864 },
    { lat: 49.3927226, lng: 19.414401 },
    { lat: 49.3931332, lng: 19.4143611 },
    { lat: 49.3937132, lng: 19.4142099 },
    { lat: 49.3941906, lng: 19.4144452 },
    { lat: 49.3946775, lng: 19.4148373 },
    { lat: 49.3951173, lng: 19.4147241 },
    { lat: 49.3954474, lng: 19.4143829 },
    { lat: 49.3957306, lng: 19.4141956 },
    { lat: 49.3962207, lng: 19.4141423 },
    { lat: 49.3968662, lng: 19.4142122 },
    { lat: 49.3978034, lng: 19.413719 },
    { lat: 49.3984531, lng: 19.4131767 },
    { lat: 49.3988937, lng: 19.4125685 },
    { lat: 49.3991378, lng: 19.4124352 },
    { lat: 49.3995613, lng: 19.4122141 },
    { lat: 49.4001569, lng: 19.4119092 },
    { lat: 49.4006139, lng: 19.411586 },
    { lat: 49.4010564, lng: 19.411454 },
    { lat: 49.4016275, lng: 19.4113294 },
    { lat: 49.4024836, lng: 19.410935 },
    { lat: 49.4028956, lng: 19.4107053 },
    { lat: 49.4032089, lng: 19.4106582 },
    { lat: 49.4040191, lng: 19.4101968 },
    { lat: 49.4046342, lng: 19.4099516 },
    { lat: 49.4049401, lng: 19.4098684 },
    { lat: 49.4052931, lng: 19.4097515 },
    { lat: 49.4058094, lng: 19.4093929 },
    { lat: 49.4062798, lng: 19.409159 },
    { lat: 49.406459, lng: 19.4087252 },
    { lat: 49.4066828, lng: 19.40852 },
    { lat: 49.406832, lng: 19.4083106 },
    { lat: 49.4075022, lng: 19.4069665 },
    { lat: 49.4079914, lng: 19.405873 },
    { lat: 49.4086488, lng: 19.4048647 },
    { lat: 49.4091937, lng: 19.4042937 },
    { lat: 49.4095215, lng: 19.4039537 },
    { lat: 49.4098306, lng: 19.4036881 },
    { lat: 49.4101165, lng: 19.4032962 },
    { lat: 49.4105639, lng: 19.4031267 },
    { lat: 49.4109112, lng: 19.4029886 },
    { lat: 49.4112161, lng: 19.4027354 },
    { lat: 49.4119578, lng: 19.4025943 },
    { lat: 49.4122459, lng: 19.4024413 },
    { lat: 49.4126083, lng: 19.4022372 },
    { lat: 49.4129696, lng: 19.4020336 },
    { lat: 49.4140042, lng: 19.4013542 },
    { lat: 49.4141449, lng: 19.4009882 },
    { lat: 49.4142149, lng: 19.4003332 },
    { lat: 49.4147612, lng: 19.3994804 },
    { lat: 49.4149218, lng: 19.3991307 },
    { lat: 49.4151651, lng: 19.3988103 },
    { lat: 49.4156883, lng: 19.3984276 },
    { lat: 49.416207, lng: 19.3974652 },
    { lat: 49.4168442, lng: 19.3967251 },
    { lat: 49.4171872, lng: 19.3965658 },
    { lat: 49.4175073, lng: 19.3961509 },
    { lat: 49.4177945, lng: 19.3959339 },
    { lat: 49.4182556, lng: 19.3957667 },
    { lat: 49.4185231, lng: 19.3951447 },
    { lat: 49.4187501, lng: 19.3949115 },
    { lat: 49.4191811, lng: 19.394415 },
    { lat: 49.4196638, lng: 19.3941365 },
    { lat: 49.4200067, lng: 19.39395 },
    { lat: 49.4206015, lng: 19.3934573 },
    { lat: 49.4210461, lng: 19.3931569 },
    { lat: 49.4214922, lng: 19.3928879 },
    { lat: 49.4218587, lng: 19.39231 },
    { lat: 49.4225189, lng: 19.3919933 },
    { lat: 49.4232253, lng: 19.3916017 },
    { lat: 49.4234691, lng: 19.3914397 },
    { lat: 49.4237664, lng: 19.3909277 },
    { lat: 49.4238338, lng: 19.3908747 },
    { lat: 49.4238007, lng: 19.39036 },
    { lat: 49.4239057, lng: 19.3895128 },
    { lat: 49.4238585, lng: 19.3882286 },
    { lat: 49.4238931, lng: 19.3879808 },
    { lat: 49.4237439, lng: 19.3872406 },
    { lat: 49.4238411, lng: 19.3869075 },
    { lat: 49.4243259, lng: 19.386503 },
    { lat: 49.4248075, lng: 19.3859057 },
    { lat: 49.4248935, lng: 19.3858872 },
    { lat: 49.425129, lng: 19.3856443 },
    { lat: 49.4254529, lng: 19.3854413 },
    { lat: 49.425557, lng: 19.385232 },
    { lat: 49.4254243, lng: 19.3848961 },
    { lat: 49.4252647, lng: 19.3845906 },
    { lat: 49.4250169, lng: 19.3843252 },
    { lat: 49.4244339, lng: 19.3832476 },
    { lat: 49.4242222, lng: 19.382575 },
    { lat: 49.424008, lng: 19.3827157 },
    { lat: 49.423419, lng: 19.3808804 },
    { lat: 49.4232613, lng: 19.3797434 },
    { lat: 49.4230285, lng: 19.3786384 },
    { lat: 49.4230183, lng: 19.3785924 },
    { lat: 49.4228622, lng: 19.3778501 },
    { lat: 49.4227962, lng: 19.3772337 },
    { lat: 49.422638, lng: 19.3767866 },
    { lat: 49.4226237, lng: 19.3763338 },
    { lat: 49.4225182, lng: 19.3760305 },
    { lat: 49.4223294, lng: 19.3745606 },
    { lat: 49.42232, lng: 19.3739481 },
    { lat: 49.4220918, lng: 19.3729472 },
    { lat: 49.4221149, lng: 19.3725212 },
    { lat: 49.4218403, lng: 19.3716337 },
    { lat: 49.421778, lng: 19.3710487 },
    { lat: 49.4215402, lng: 19.3711685 },
    { lat: 49.4209565, lng: 19.3715089 },
    { lat: 49.4204032, lng: 19.3719883 },
    { lat: 49.4198661, lng: 19.3722889 },
    { lat: 49.4187505, lng: 19.3731827 },
    { lat: 49.4179712, lng: 19.3763401 },
    { lat: 49.4178233, lng: 19.3771786 },
    { lat: 49.4176433, lng: 19.3783709 },
    { lat: 49.4175787, lng: 19.3792596 },
    { lat: 49.4173261, lng: 19.3803922 },
    { lat: 49.4169616, lng: 19.3815215 },
    { lat: 49.4168329, lng: 19.3816828 },
    { lat: 49.4164666, lng: 19.3818749 },
    { lat: 49.4160347, lng: 19.3819394 },
    { lat: 49.4158899, lng: 19.3821728 },
    { lat: 49.4149965, lng: 19.3829247 },
    { lat: 49.4134774, lng: 19.3839375 },
    { lat: 49.4121224, lng: 19.3847735 },
    { lat: 49.4113157, lng: 19.3858177 },
    { lat: 49.4102458, lng: 19.3875285 },
    { lat: 49.410094, lng: 19.3885059 },
    { lat: 49.4099273, lng: 19.3892565 },
    { lat: 49.4098318, lng: 19.3905012 },
    { lat: 49.4093089, lng: 19.3918606 },
    { lat: 49.4089104, lng: 19.3925743 },
    { lat: 49.407991, lng: 19.3947574 },
    { lat: 49.4071691, lng: 19.3961308 },
    { lat: 49.40682, lng: 19.3967709 },
    { lat: 49.4067753, lng: 19.3969473 },
    { lat: 49.4067577, lng: 19.3969793 },
    { lat: 49.406692, lng: 19.3970964 },
    { lat: 49.4066732, lng: 19.3971285 },
    { lat: 49.4066597, lng: 19.3971528 },
    { lat: 49.4061615, lng: 19.3980443 },
    { lat: 49.4061595, lng: 19.3981991 },
    { lat: 49.4058356, lng: 19.3986494 },
    { lat: 49.405471, lng: 19.3988523 },
    { lat: 49.4038624, lng: 19.4007327 },
    { lat: 49.4030851, lng: 19.40144 },
    { lat: 49.4025575, lng: 19.4016049 },
    { lat: 49.4025227, lng: 19.4017831 },
    { lat: 49.4021251, lng: 19.4019559 },
    { lat: 49.4018757, lng: 19.4022609 },
    { lat: 49.4017169, lng: 19.4024559 },
    { lat: 49.4008212, lng: 19.4035534 },
    { lat: 49.3999181, lng: 19.4029599 },
    { lat: 49.3988281, lng: 19.4031668 },
    { lat: 49.3979643, lng: 19.4034185 },
    { lat: 49.3976419, lng: 19.4036113 },
    { lat: 49.3972067, lng: 19.4039966 },
    { lat: 49.3971719, lng: 19.4040271 },
    { lat: 49.3969048, lng: 19.4042638 },
    { lat: 49.3964747, lng: 19.4044459 },
    { lat: 49.3957932, lng: 19.4047657 },
    { lat: 49.3941868, lng: 19.405695 },
    { lat: 49.3938837, lng: 19.4058184 },
    { lat: 49.3936869, lng: 19.4058984 },
    { lat: 49.3935771, lng: 19.4059143 },
    { lat: 49.3934674, lng: 19.4059766 },
    { lat: 49.3933845, lng: 19.4060373 },
    { lat: 49.3931734, lng: 19.4062708 },
    { lat: 49.3931571, lng: 19.4063333 },
    { lat: 49.3920481, lng: 19.4068139 },
    { lat: 49.3907742, lng: 19.4073821 },
    { lat: 49.3907693, lng: 19.4073537 },
    { lat: 49.3903381, lng: 19.4064861 },
    { lat: 49.389982, lng: 19.4055833 },
    { lat: 49.3897966, lng: 19.4048424 },
    { lat: 49.3894406, lng: 19.404187 },
    { lat: 49.3895429, lng: 19.4039849 },
    { lat: 49.3895035, lng: 19.4036705 },
    { lat: 49.3893328, lng: 19.4031964 },
    { lat: 49.3888237, lng: 19.4022285 },
    { lat: 49.3881114, lng: 19.4013739 },
    { lat: 49.3871402, lng: 19.4013534 },
    { lat: 49.3865951, lng: 19.4015994 },
    { lat: 49.3862283, lng: 19.4015655 },
    { lat: 49.3851686, lng: 19.4000692 },
    { lat: 49.3847542, lng: 19.3990317 },
    { lat: 49.3840299, lng: 19.3972172 },
    { lat: 49.3835372, lng: 19.3963385 },
    { lat: 49.3826727, lng: 19.3949476 },
    { lat: 49.3825954, lng: 19.3941087 },
    { lat: 49.3824422, lng: 19.3929908 },
    { lat: 49.3819836, lng: 19.391367 },
    { lat: 49.3817857, lng: 19.3907922 },
    { lat: 49.381776, lng: 19.3904681 },
    { lat: 49.3816472, lng: 19.3896829 },
    { lat: 49.3816656, lng: 19.389134 },
    { lat: 49.3815087, lng: 19.3888813 },
    { lat: 49.381552, lng: 19.3887978 },
    { lat: 49.38156, lng: 19.3886801 },
    { lat: 49.3817006, lng: 19.3884083 },
    { lat: 49.3814419, lng: 19.3881266 },
    { lat: 49.381029, lng: 19.3877521 },
    { lat: 49.3804687, lng: 19.3873355 },
    { lat: 49.3795901, lng: 19.3866151 },
    { lat: 49.3791209, lng: 19.3862392 },
    { lat: 49.378526, lng: 19.3856383 },
    { lat: 49.3771804, lng: 19.3844927 },
    { lat: 49.3769294, lng: 19.3843299 },
    { lat: 49.3767254, lng: 19.3842342 },
    { lat: 49.3762651, lng: 19.3841257 },
    { lat: 49.3760189, lng: 19.3841121 },
    { lat: 49.3757551, lng: 19.3840335 },
    { lat: 49.375535, lng: 19.3838818 },
    { lat: 49.3751897, lng: 19.383798 },
    { lat: 49.3749011, lng: 19.3837521 },
    { lat: 49.3745803, lng: 19.3838294 },
    { lat: 49.3741821, lng: 19.3844005 },
    { lat: 49.3740941, lng: 19.3842756 },
    { lat: 49.3739758, lng: 19.3841579 },
    { lat: 49.3732732, lng: 19.3836329 },
    { lat: 49.3727303, lng: 19.3835208 },
    { lat: 49.3721847, lng: 19.383248 },
    { lat: 49.3717958, lng: 19.3830041 },
    { lat: 49.3717561, lng: 19.3829734 },
    { lat: 49.371568, lng: 19.3826873 },
    { lat: 49.3713642, lng: 19.3823971 },
    { lat: 49.3709554, lng: 19.3814575 },
    { lat: 49.3706464, lng: 19.38046 },
    { lat: 49.3702842, lng: 19.3791411 },
    { lat: 49.3699223, lng: 19.3783171 },
    { lat: 49.3697087, lng: 19.3774824 },
    { lat: 49.3694609, lng: 19.3757425 },
    { lat: 49.3690273, lng: 19.3757721 },
    { lat: 49.3685927, lng: 19.3745227 },
    { lat: 49.3687385, lng: 19.3735193 },
    { lat: 49.3686046, lng: 19.3725312 },
    { lat: 49.3689965, lng: 19.3723058 },
    { lat: 49.3688698, lng: 19.3716453 },
    { lat: 49.368841, lng: 19.3709564 },
  ],
  Lomná: [
    { lat: 49.316762, lng: 19.241458 },
    { lat: 49.3166934, lng: 19.2429643 },
    { lat: 49.3168582, lng: 19.2438436 },
    { lat: 49.3169246, lng: 19.2441949 },
    { lat: 49.3169848, lng: 19.2444862 },
    { lat: 49.3171061, lng: 19.2451171 },
    { lat: 49.3172662, lng: 19.2463162 },
    { lat: 49.3175668, lng: 19.2480126 },
    { lat: 49.3176801, lng: 19.2486397 },
    { lat: 49.3180888, lng: 19.251061 },
    { lat: 49.3183192, lng: 19.2519923 },
    { lat: 49.3186102, lng: 19.253153 },
    { lat: 49.3188729, lng: 19.254362 },
    { lat: 49.3190778, lng: 19.2552997 },
    { lat: 49.3191001, lng: 19.2559815 },
    { lat: 49.3187371, lng: 19.2571592 },
    { lat: 49.3185763, lng: 19.257546 },
    { lat: 49.3184358, lng: 19.2578828 },
    { lat: 49.3181345, lng: 19.2585381 },
    { lat: 49.3178655, lng: 19.2593008 },
    { lat: 49.3175005, lng: 19.2608876 },
    { lat: 49.3173697, lng: 19.2618598 },
    { lat: 49.3172528, lng: 19.2629747 },
    { lat: 49.3171717, lng: 19.263518 },
    { lat: 49.317194, lng: 19.264035 },
    { lat: 49.3172889, lng: 19.2643975 },
    { lat: 49.3182045, lng: 19.266441 },
    { lat: 49.3186781, lng: 19.267421 },
    { lat: 49.3190018, lng: 19.2679207 },
    { lat: 49.3186131, lng: 19.2714746 },
    { lat: 49.3190856, lng: 19.2715279 },
    { lat: 49.3191388, lng: 19.2715342 },
    { lat: 49.319362, lng: 19.2715592 },
    { lat: 49.3196131, lng: 19.2716744 },
    { lat: 49.3198569, lng: 19.271726 },
    { lat: 49.3199987, lng: 19.2717485 },
    { lat: 49.3201126, lng: 19.2717958 },
    { lat: 49.3202847, lng: 19.2719081 },
    { lat: 49.3204252, lng: 19.2719485 },
    { lat: 49.3208429, lng: 19.2721556 },
    { lat: 49.3209585, lng: 19.2721417 },
    { lat: 49.3210708, lng: 19.272179 },
    { lat: 49.3211994, lng: 19.2721835 },
    { lat: 49.3216262, lng: 19.2723683 },
    { lat: 49.3219865, lng: 19.2725049 },
    { lat: 49.3223508, lng: 19.272771 },
    { lat: 49.3223649, lng: 19.2727898 },
    { lat: 49.322579, lng: 19.2729234 },
    { lat: 49.3228276, lng: 19.2729853 },
    { lat: 49.323102, lng: 19.2729949 },
    { lat: 49.3231983, lng: 19.2730543 },
    { lat: 49.3234877, lng: 19.2731203 },
    { lat: 49.3235888, lng: 19.273091 },
    { lat: 49.3236396, lng: 19.2730474 },
    { lat: 49.3238033, lng: 19.2730733 },
    { lat: 49.3238902, lng: 19.2731225 },
    { lat: 49.3239613, lng: 19.2731728 },
    { lat: 49.3240093, lng: 19.2732577 },
    { lat: 49.3240774, lng: 19.2732946 },
    { lat: 49.3241709, lng: 19.2732954 },
    { lat: 49.3241817, lng: 19.273283 },
    { lat: 49.3241934, lng: 19.2732672 },
    { lat: 49.3242272, lng: 19.2731735 },
    { lat: 49.3243301, lng: 19.2731563 },
    { lat: 49.3243728, lng: 19.2730612 },
    { lat: 49.3244167, lng: 19.2730112 },
    { lat: 49.3244469, lng: 19.2730053 },
    { lat: 49.3245755, lng: 19.273009 },
    { lat: 49.3247926, lng: 19.2728805 },
    { lat: 49.3248489, lng: 19.2728825 },
    { lat: 49.3251284, lng: 19.27307 },
    { lat: 49.3252354, lng: 19.2732579 },
    { lat: 49.3253451, lng: 19.2735051 },
    { lat: 49.3255132, lng: 19.2735525 },
    { lat: 49.3258392, lng: 19.2738931 },
    { lat: 49.3259287, lng: 19.2741425 },
    { lat: 49.3260452, lng: 19.274411 },
    { lat: 49.326007, lng: 19.2746006 },
    { lat: 49.3261029, lng: 19.2749353 },
    { lat: 49.3262839, lng: 19.275072 },
    { lat: 49.3265266, lng: 19.2753131 },
    { lat: 49.3265663, lng: 19.2753441 },
    { lat: 49.3266341, lng: 19.2753745 },
    { lat: 49.3266666, lng: 19.2753929 },
    { lat: 49.326695, lng: 19.2754183 },
    { lat: 49.3268103, lng: 19.2756425 },
    { lat: 49.3268371, lng: 19.2756847 },
    { lat: 49.3269174, lng: 19.2757859 },
    { lat: 49.32703, lng: 19.2760166 },
    { lat: 49.3270779, lng: 19.2761962 },
    { lat: 49.3272277, lng: 19.2764115 },
    { lat: 49.3273995, lng: 19.2765138 },
    { lat: 49.3275397, lng: 19.2765452 },
    { lat: 49.3275922, lng: 19.2765628 },
    { lat: 49.3277608, lng: 19.2766724 },
    { lat: 49.3279584, lng: 19.2768683 },
    { lat: 49.3282354, lng: 19.2772421 },
    { lat: 49.3284365, lng: 19.2773983 },
    { lat: 49.3284643, lng: 19.2774129 },
    { lat: 49.3285248, lng: 19.277383 },
    { lat: 49.3285843, lng: 19.2773789 },
    { lat: 49.3287007, lng: 19.2773819 },
    { lat: 49.3289932, lng: 19.277276 },
    { lat: 49.329114, lng: 19.2771903 },
    { lat: 49.329549, lng: 19.2771152 },
    { lat: 49.3297202, lng: 19.27721 },
    { lat: 49.3300905, lng: 19.2772685 },
    { lat: 49.3303685, lng: 19.2774038 },
    { lat: 49.3305366, lng: 19.2774578 },
    { lat: 49.330735, lng: 19.2774595 },
    { lat: 49.3310179, lng: 19.2776734 },
    { lat: 49.3312437, lng: 19.2778225 },
    { lat: 49.3314056, lng: 19.2779591 },
    { lat: 49.3316283, lng: 19.2779944 },
    { lat: 49.3317859, lng: 19.2781355 },
    { lat: 49.3318078, lng: 19.2782881 },
    { lat: 49.3318243, lng: 19.2783039 },
    { lat: 49.3320257, lng: 19.2782729 },
    { lat: 49.3320879, lng: 19.2783248 },
    { lat: 49.332291, lng: 19.2783796 },
    { lat: 49.3323806, lng: 19.2783418 },
    { lat: 49.3324048, lng: 19.2783543 },
    { lat: 49.3325325, lng: 19.2784767 },
    { lat: 49.3327867, lng: 19.2785879 },
    { lat: 49.332958, lng: 19.2785855 },
    { lat: 49.3330364, lng: 19.2786505 },
    { lat: 49.3332766, lng: 19.2784872 },
    { lat: 49.3333058, lng: 19.2784839 },
    { lat: 49.3334532, lng: 19.278551 },
    { lat: 49.3336265, lng: 19.2785903 },
    { lat: 49.333935, lng: 19.2785853 },
    { lat: 49.333974, lng: 19.2785531 },
    { lat: 49.3339959, lng: 19.2784654 },
    { lat: 49.3340306, lng: 19.2784604 },
    { lat: 49.3340599, lng: 19.2785367 },
    { lat: 49.3340804, lng: 19.2785402 },
    { lat: 49.3341302, lng: 19.2784956 },
    { lat: 49.3341759, lng: 19.278462 },
    { lat: 49.3342186, lng: 19.2785309 },
    { lat: 49.3342458, lng: 19.2785807 },
    { lat: 49.3342648, lng: 19.2785988 },
    { lat: 49.3342947, lng: 19.2785929 },
    { lat: 49.3344026, lng: 19.2784877 },
    { lat: 49.3344757, lng: 19.2783658 },
    { lat: 49.3345607, lng: 19.2783299 },
    { lat: 49.3347755, lng: 19.2784383 },
    { lat: 49.334853, lng: 19.2784575 },
    { lat: 49.3348875, lng: 19.2783995 },
    { lat: 49.3350516, lng: 19.2783689 },
    { lat: 49.3351937, lng: 19.2784424 },
    { lat: 49.3352969, lng: 19.2786203 },
    { lat: 49.3353433, lng: 19.2786733 },
    { lat: 49.3354822, lng: 19.2786553 },
    { lat: 49.3356954, lng: 19.278684 },
    { lat: 49.3358395, lng: 19.2786539 },
    { lat: 49.3360018, lng: 19.2787992 },
    { lat: 49.3360303, lng: 19.278804 },
    { lat: 49.3363076, lng: 19.2787092 },
    { lat: 49.3365674, lng: 19.278651 },
    { lat: 49.336717, lng: 19.2785986 },
    { lat: 49.3367524, lng: 19.2785383 },
    { lat: 49.3367719, lng: 19.2785463 },
    { lat: 49.3368482, lng: 19.278631 },
    { lat: 49.3368739, lng: 19.2786314 },
    { lat: 49.3369127, lng: 19.2785935 },
    { lat: 49.336988, lng: 19.2783972 },
    { lat: 49.3370393, lng: 19.2782907 },
    { lat: 49.3370644, lng: 19.2782767 },
    { lat: 49.3370827, lng: 19.2782782 },
    { lat: 49.3370965, lng: 19.2782865 },
    { lat: 49.3371325, lng: 19.2784718 },
    { lat: 49.337154, lng: 19.2785016 },
    { lat: 49.3372864, lng: 19.2784851 },
    { lat: 49.3374655, lng: 19.2784124 },
    { lat: 49.3375174, lng: 19.2784171 },
    { lat: 49.3375591, lng: 19.2785343 },
    { lat: 49.3375705, lng: 19.2785594 },
    { lat: 49.3376189, lng: 19.2785608 },
    { lat: 49.337679, lng: 19.2785675 },
    { lat: 49.3377395, lng: 19.2786335 },
    { lat: 49.3377801, lng: 19.2786781 },
    { lat: 49.3378092, lng: 19.2786994 },
    { lat: 49.3378983, lng: 19.2786993 },
    { lat: 49.3379416, lng: 19.2786616 },
    { lat: 49.3379556, lng: 19.2786239 },
    { lat: 49.337973, lng: 19.2785674 },
    { lat: 49.3379826, lng: 19.2785056 },
    { lat: 49.3379979, lng: 19.2784699 },
    { lat: 49.338015, lng: 19.2784525 },
    { lat: 49.338329, lng: 19.2784653 },
    { lat: 49.338381, lng: 19.2784461 },
    { lat: 49.3384208, lng: 19.2783849 },
    { lat: 49.338439, lng: 19.2782911 },
    { lat: 49.3384752, lng: 19.2782477 },
    { lat: 49.3385145, lng: 19.2781996 },
    { lat: 49.3385383, lng: 19.2781041 },
    { lat: 49.3385502, lng: 19.2780717 },
    { lat: 49.3385695, lng: 19.2780497 },
    { lat: 49.3385955, lng: 19.2780302 },
    { lat: 49.338617, lng: 19.278031 },
    { lat: 49.3386394, lng: 19.2780498 },
    { lat: 49.3386788, lng: 19.2781246 },
    { lat: 49.3387064, lng: 19.2782032 },
    { lat: 49.3387238, lng: 19.2782356 },
    { lat: 49.3387485, lng: 19.2782592 },
    { lat: 49.3387899, lng: 19.2782745 },
    { lat: 49.338856, lng: 19.2782704 },
    { lat: 49.3389325, lng: 19.2782901 },
    { lat: 49.339022, lng: 19.2782997 },
    { lat: 49.3390885, lng: 19.2783013 },
    { lat: 49.3391831, lng: 19.2782752 },
    { lat: 49.3392065, lng: 19.2782712 },
    { lat: 49.3392746, lng: 19.278277 },
    { lat: 49.3392941, lng: 19.2782864 },
    { lat: 49.3393862, lng: 19.2783741 },
    { lat: 49.3394444, lng: 19.2784616 },
    { lat: 49.3395486, lng: 19.2786878 },
    { lat: 49.3395899, lng: 19.2787949 },
    { lat: 49.3396284, lng: 19.2789456 },
    { lat: 49.3396386, lng: 19.2789908 },
    { lat: 49.3396594, lng: 19.279026 },
    { lat: 49.3396981, lng: 19.2790604 },
    { lat: 49.3397689, lng: 19.2791241 },
    { lat: 49.3397929, lng: 19.2791568 },
    { lat: 49.3399632, lng: 19.2794404 },
    { lat: 49.3399979, lng: 19.2794864 },
    { lat: 49.3400999, lng: 19.2795 },
    { lat: 49.3401231, lng: 19.2795108 },
    { lat: 49.3401486, lng: 19.2795517 },
    { lat: 49.340168, lng: 19.2795824 },
    { lat: 49.3401913, lng: 19.2797381 },
    { lat: 49.3401679, lng: 19.2798123 },
    { lat: 49.3400892, lng: 19.2798409 },
    { lat: 49.3400642, lng: 19.2798863 },
    { lat: 49.3400631, lng: 19.2799331 },
    { lat: 49.340076, lng: 19.2800162 },
    { lat: 49.340124, lng: 19.2801475 },
    { lat: 49.3401765, lng: 19.2802334 },
    { lat: 49.3402476, lng: 19.2803076 },
    { lat: 49.3402708, lng: 19.2803198 },
    { lat: 49.3405054, lng: 19.2803711 },
    { lat: 49.3405647, lng: 19.2804107 },
    { lat: 49.3405957, lng: 19.2804485 },
    { lat: 49.3406141, lng: 19.2805274 },
    { lat: 49.3406326, lng: 19.2805579 },
    { lat: 49.340754, lng: 19.2806734 },
    { lat: 49.3408043, lng: 19.2807329 },
    { lat: 49.3408249, lng: 19.2807888 },
    { lat: 49.3408338, lng: 19.2808319 },
    { lat: 49.3408275, lng: 19.2809124 },
    { lat: 49.3408084, lng: 19.2809897 },
    { lat: 49.3407744, lng: 19.2810788 },
    { lat: 49.3407038, lng: 19.2811336 },
    { lat: 49.3406818, lng: 19.2811682 },
    { lat: 49.340678, lng: 19.2812077 },
    { lat: 49.3406868, lng: 19.2812483 },
    { lat: 49.3407119, lng: 19.2812795 },
    { lat: 49.340733, lng: 19.2812948 },
    { lat: 49.3408116, lng: 19.2813652 },
    { lat: 49.3408478, lng: 19.2813919 },
    { lat: 49.3411288, lng: 19.2815656 },
    { lat: 49.3411952, lng: 19.2816103 },
    { lat: 49.3412338, lng: 19.2816186 },
    { lat: 49.3415805, lng: 19.2815826 },
    { lat: 49.3416377, lng: 19.2815987 },
    { lat: 49.3416608, lng: 19.2816381 },
    { lat: 49.3417084, lng: 19.2817614 },
    { lat: 49.3416919, lng: 19.2818406 },
    { lat: 49.3415966, lng: 19.2819715 },
    { lat: 49.3415624, lng: 19.2820588 },
    { lat: 49.3415594, lng: 19.2821842 },
    { lat: 49.3415835, lng: 19.2822386 },
    { lat: 49.3416073, lng: 19.2822678 },
    { lat: 49.3417302, lng: 19.2822896 },
    { lat: 49.3419102, lng: 19.2823809 },
    { lat: 49.3420373, lng: 19.2824719 },
    { lat: 49.3421156, lng: 19.2825532 },
    { lat: 49.3421262, lng: 19.2826084 },
    { lat: 49.3421076, lng: 19.2826679 },
    { lat: 49.3420568, lng: 19.2827632 },
    { lat: 49.342029, lng: 19.2828436 },
    { lat: 49.3419997, lng: 19.2829835 },
    { lat: 49.3419711, lng: 19.282978 },
    { lat: 49.3418698, lng: 19.2829336 },
    { lat: 49.341799, lng: 19.2828638 },
    { lat: 49.3417828, lng: 19.2828316 },
    { lat: 49.3417099, lng: 19.2827179 },
    { lat: 49.3416655, lng: 19.2827326 },
    { lat: 49.3415616, lng: 19.2828233 },
    { lat: 49.3415432, lng: 19.2828181 },
    { lat: 49.3414516, lng: 19.2827383 },
    { lat: 49.3413683, lng: 19.2826912 },
    { lat: 49.341358, lng: 19.282667 },
    { lat: 49.3413683, lng: 19.282646 },
    { lat: 49.3413794, lng: 19.2826412 },
    { lat: 49.3414248, lng: 19.2826213 },
    { lat: 49.3414226, lng: 19.282555 },
    { lat: 49.3412994, lng: 19.2824289 },
    { lat: 49.341212, lng: 19.2824118 },
    { lat: 49.3411082, lng: 19.2823406 },
    { lat: 49.3410307, lng: 19.2823438 },
    { lat: 49.3409476, lng: 19.282374 },
    { lat: 49.3408948, lng: 19.2824015 },
    { lat: 49.3408079, lng: 19.282447 },
    { lat: 49.3407316, lng: 19.2823608 },
    { lat: 49.3406871, lng: 19.2823744 },
    { lat: 49.3406356, lng: 19.282284 },
    { lat: 49.3405611, lng: 19.2823757 },
    { lat: 49.3404076, lng: 19.2824662 },
    { lat: 49.340313, lng: 19.2824235 },
    { lat: 49.3402932, lng: 19.2824077 },
    { lat: 49.340209, lng: 19.2822707 },
    { lat: 49.3401619, lng: 19.2822293 },
    { lat: 49.3400889, lng: 19.2823025 },
    { lat: 49.3400456, lng: 19.282321 },
    { lat: 49.3398253, lng: 19.2822118 },
    { lat: 49.3397584, lng: 19.2822055 },
    { lat: 49.3397089, lng: 19.2823476 },
    { lat: 49.3396849, lng: 19.2823885 },
    { lat: 49.3395562, lng: 19.2823598 },
    { lat: 49.3393904, lng: 19.2824028 },
    { lat: 49.3392846, lng: 19.2824345 },
    { lat: 49.3392721, lng: 19.2824536 },
    { lat: 49.3392747, lng: 19.2825089 },
    { lat: 49.339344, lng: 19.282829 },
    { lat: 49.3393291, lng: 19.2828693 },
    { lat: 49.3393118, lng: 19.2828875 },
    { lat: 49.3392983, lng: 19.2829104 },
    { lat: 49.3393066, lng: 19.2829918 },
    { lat: 49.3392671, lng: 19.2830349 },
    { lat: 49.3391548, lng: 19.2830922 },
    { lat: 49.3391466, lng: 19.2831336 },
    { lat: 49.3391375, lng: 19.2831805 },
    { lat: 49.3391569, lng: 19.2832998 },
    { lat: 49.3390884, lng: 19.2834985 },
    { lat: 49.3390165, lng: 19.283526 },
    { lat: 49.3389822, lng: 19.2836603 },
    { lat: 49.3389116, lng: 19.2837603 },
    { lat: 49.3388909, lng: 19.2839202 },
    { lat: 49.3388372, lng: 19.284143 },
    { lat: 49.338848, lng: 19.2842003 },
    { lat: 49.3388387, lng: 19.2843643 },
    { lat: 49.3388421, lng: 19.284386 },
    { lat: 49.3388584, lng: 19.2845147 },
    { lat: 49.3389547, lng: 19.2848827 },
    { lat: 49.3389586, lng: 19.2849195 },
    { lat: 49.3389311, lng: 19.2849815 },
    { lat: 49.3389474, lng: 19.2850367 },
    { lat: 49.3389894, lng: 19.2851868 },
    { lat: 49.3390025, lng: 19.2854433 },
    { lat: 49.3389182, lng: 19.2855615 },
    { lat: 49.3388943, lng: 19.2855818 },
    { lat: 49.338885, lng: 19.2855999 },
    { lat: 49.3388933, lng: 19.2856347 },
    { lat: 49.3389484, lng: 19.2857016 },
    { lat: 49.3389411, lng: 19.2859051 },
    { lat: 49.3388523, lng: 19.286099 },
    { lat: 49.338851, lng: 19.2862166 },
    { lat: 49.3388008, lng: 19.2864372 },
    { lat: 49.3387586, lng: 19.2865 },
    { lat: 49.3386889, lng: 19.2866932 },
    { lat: 49.3385506, lng: 19.286869 },
    { lat: 49.3385294, lng: 19.2869443 },
    { lat: 49.3385274, lng: 19.287092 },
    { lat: 49.338453, lng: 19.287161 },
    { lat: 49.3384075, lng: 19.2872477 },
    { lat: 49.3384297, lng: 19.2873609 },
    { lat: 49.3384117, lng: 19.2874052 },
    { lat: 49.338389, lng: 19.2874301 },
    { lat: 49.3383557, lng: 19.2875093 },
    { lat: 49.3383383, lng: 19.2875947 },
    { lat: 49.3383309, lng: 19.2876982 },
    { lat: 49.338314, lng: 19.2877947 },
    { lat: 49.3381481, lng: 19.2881467 },
    { lat: 49.3380728, lng: 19.2883867 },
    { lat: 49.3380516, lng: 19.2884429 },
    { lat: 49.3379517, lng: 19.2886614 },
    { lat: 49.3378873, lng: 19.2888724 },
    { lat: 49.3378068, lng: 19.2890765 },
    { lat: 49.3377553, lng: 19.2892513 },
    { lat: 49.337701, lng: 19.2894113 },
    { lat: 49.3375309, lng: 19.2897233 },
    { lat: 49.337479, lng: 19.2898432 },
    { lat: 49.3374302, lng: 19.2899556 },
    { lat: 49.33741, lng: 19.2901219 },
    { lat: 49.3373266, lng: 19.2902656 },
    { lat: 49.3372833, lng: 19.2903488 },
    { lat: 49.3372756, lng: 19.290376 },
    { lat: 49.3372447, lng: 19.290536 },
    { lat: 49.3371708, lng: 19.2906609 },
    { lat: 49.3371657, lng: 19.2907923 },
    { lat: 49.3371489, lng: 19.2909101 },
    { lat: 49.3370903, lng: 19.2911996 },
    { lat: 49.3370527, lng: 19.2912637 },
    { lat: 49.3370567, lng: 19.291392 },
    { lat: 49.3370768, lng: 19.2915585 },
    { lat: 49.3370787, lng: 19.2916428 },
    { lat: 49.3370889, lng: 19.2919073 },
    { lat: 49.3370842, lng: 19.2920484 },
    { lat: 49.3370831, lng: 19.2920951 },
    { lat: 49.3370208, lng: 19.2924884 },
    { lat: 49.3370222, lng: 19.2925692 },
    { lat: 49.337009, lng: 19.2926935 },
    { lat: 49.3370015, lng: 19.292845 },
    { lat: 49.3369592, lng: 19.2932122 },
    { lat: 49.3369051, lng: 19.2932818 },
    { lat: 49.3368173, lng: 19.2933822 },
    { lat: 49.3367888, lng: 19.2934945 },
    { lat: 49.3366855, lng: 19.293597 },
    { lat: 49.3366334, lng: 19.2936642 },
    { lat: 49.3366372, lng: 19.2937892 },
    { lat: 49.3366073, lng: 19.2938261 },
    { lat: 49.3365778, lng: 19.293845 },
    { lat: 49.3364961, lng: 19.2938558 },
    { lat: 49.336434, lng: 19.2938999 },
    { lat: 49.336373, lng: 19.2939699 },
    { lat: 49.3363044, lng: 19.2939725 },
    { lat: 49.3362464, lng: 19.2940085 },
    { lat: 49.3362436, lng: 19.2939998 },
    { lat: 49.3361799, lng: 19.2940354 },
    { lat: 49.3361521, lng: 19.2941122 },
    { lat: 49.336124, lng: 19.2942805 },
    { lat: 49.3361073, lng: 19.2943832 },
    { lat: 49.3361102, lng: 19.2944873 },
    { lat: 49.3361054, lng: 19.2946006 },
    { lat: 49.3360889, lng: 19.2948272 },
    { lat: 49.3360708, lng: 19.2949882 },
    { lat: 49.3360658, lng: 19.295147 },
    { lat: 49.3360517, lng: 19.2953484 },
    { lat: 49.3360283, lng: 19.2955219 },
    { lat: 49.3359866, lng: 19.2955934 },
    { lat: 49.3359284, lng: 19.2957401 },
    { lat: 49.3358387, lng: 19.295802 },
    { lat: 49.3358227, lng: 19.295867 },
    { lat: 49.3357716, lng: 19.2959562 },
    { lat: 49.3357177, lng: 19.2960547 },
    { lat: 49.3357121, lng: 19.2961001 },
    { lat: 49.3357039, lng: 19.2961426 },
    { lat: 49.3357168, lng: 19.2962944 },
    { lat: 49.33571, lng: 19.2965596 },
    { lat: 49.3356981, lng: 19.2966372 },
    { lat: 49.3356591, lng: 19.2966961 },
    { lat: 49.335597, lng: 19.2967872 },
    { lat: 49.3355558, lng: 19.2969442 },
    { lat: 49.3355394, lng: 19.2970017 },
    { lat: 49.3355193, lng: 19.2970769 },
    { lat: 49.3355127, lng: 19.2972204 },
    { lat: 49.3354076, lng: 19.2974311 },
    { lat: 49.3353874, lng: 19.2975765 },
    { lat: 49.3353983, lng: 19.2978521 },
    { lat: 49.3353852, lng: 19.2980306 },
    { lat: 49.3353673, lng: 19.2981764 },
    { lat: 49.3353881, lng: 19.2982807 },
    { lat: 49.3353812, lng: 19.2985153 },
    { lat: 49.3353642, lng: 19.2986584 },
    { lat: 49.3353125, lng: 19.298709 },
    { lat: 49.3352203, lng: 19.2988771 },
    { lat: 49.3351649, lng: 19.2989682 },
    { lat: 49.3350741, lng: 19.2990758 },
    { lat: 49.3350514, lng: 19.2991657 },
    { lat: 49.3350285, lng: 19.2993009 },
    { lat: 49.3350623, lng: 19.2996105 },
    { lat: 49.3350594, lng: 19.3001676 },
    { lat: 49.3349786, lng: 19.3004873 },
    { lat: 49.3349306, lng: 19.3006144 },
    { lat: 49.3348484, lng: 19.3008104 },
    { lat: 49.3348398, lng: 19.3010068 },
    { lat: 49.3347708, lng: 19.3012663 },
    { lat: 49.3347544, lng: 19.3013942 },
    { lat: 49.334682, lng: 19.3016942 },
    { lat: 49.3346661, lng: 19.301863 },
    { lat: 49.3346508, lng: 19.30199 },
    { lat: 49.3346483, lng: 19.3020106 },
    { lat: 49.3345889, lng: 19.3021823 },
    { lat: 49.3345429, lng: 19.3022333 },
    { lat: 49.3345202, lng: 19.3023283 },
    { lat: 49.3348695, lng: 19.3030643 },
    { lat: 49.3348283, lng: 19.3031206 },
    { lat: 49.3349338, lng: 19.3033037 },
    { lat: 49.3349938, lng: 19.3034802 },
    { lat: 49.3351075, lng: 19.3038811 },
    { lat: 49.3350077, lng: 19.3039629 },
    { lat: 49.3349947, lng: 19.303973 },
    { lat: 49.3350783, lng: 19.3042641 },
    { lat: 49.3351134, lng: 19.304387 },
    { lat: 49.3351507, lng: 19.3045816 },
    { lat: 49.3353435, lng: 19.3049387 },
    { lat: 49.3355108, lng: 19.3052595 },
    { lat: 49.3356124, lng: 19.3054571 },
    { lat: 49.3356916, lng: 19.3056279 },
    { lat: 49.3357493, lng: 19.3057744 },
    { lat: 49.3358005, lng: 19.3059042 },
    { lat: 49.3358067, lng: 19.3059202 },
    { lat: 49.3358233, lng: 19.305961 },
    { lat: 49.3358867, lng: 19.3060598 },
    { lat: 49.3359426, lng: 19.3061476 },
    { lat: 49.3359921, lng: 19.3062151 },
    { lat: 49.3360937, lng: 19.3063419 },
    { lat: 49.3361397, lng: 19.3064059 },
    { lat: 49.3361793, lng: 19.3064586 },
    { lat: 49.3362698, lng: 19.3066303 },
    { lat: 49.3363957, lng: 19.306865 },
    { lat: 49.3364274, lng: 19.3069175 },
    { lat: 49.336474, lng: 19.3069944 },
    { lat: 49.3365552, lng: 19.3071137 },
    { lat: 49.3366827, lng: 19.3073081 },
    { lat: 49.3367107, lng: 19.3073549 },
    { lat: 49.3367694, lng: 19.3074741 },
    { lat: 49.3368622, lng: 19.3076735 },
    { lat: 49.3369742, lng: 19.3078736 },
    { lat: 49.3370857, lng: 19.3080398 },
    { lat: 49.3371183, lng: 19.3080864 },
    { lat: 49.337156, lng: 19.3081447 },
    { lat: 49.3373411, lng: 19.3083888 },
    { lat: 49.3373748, lng: 19.308436 },
    { lat: 49.3374978, lng: 19.308632 },
    { lat: 49.3376052, lng: 19.3088247 },
    { lat: 49.337691, lng: 19.3090663 },
    { lat: 49.3377786, lng: 19.3092518 },
    { lat: 49.3378801, lng: 19.3094216 },
    { lat: 49.337956, lng: 19.3095274 },
    { lat: 49.3380128, lng: 19.3096314 },
    { lat: 49.3380853, lng: 19.3098066 },
    { lat: 49.3381157, lng: 19.3098795 },
    { lat: 49.3381715, lng: 19.3100106 },
    { lat: 49.3382459, lng: 19.3101307 },
    { lat: 49.3383036, lng: 19.3102269 },
    { lat: 49.3384275, lng: 19.3104174 },
    { lat: 49.3385356, lng: 19.3105862 },
    { lat: 49.3386062, lng: 19.3106962 },
    { lat: 49.3386863, lng: 19.3107921 },
    { lat: 49.3389744, lng: 19.3111675 },
    { lat: 49.3392056, lng: 19.3115265 },
    { lat: 49.3393572, lng: 19.311878 },
    { lat: 49.3394998, lng: 19.3120577 },
    { lat: 49.3395722, lng: 19.3122051 },
    { lat: 49.3397132, lng: 19.3125397 },
    { lat: 49.3397736, lng: 19.3126997 },
    { lat: 49.3399191, lng: 19.3129398 },
    { lat: 49.340027, lng: 19.3131256 },
    { lat: 49.3401342, lng: 19.3133212 },
    { lat: 49.3401953, lng: 19.3134185 },
    { lat: 49.3402853, lng: 19.313535 },
    { lat: 49.3404263, lng: 19.3136806 },
    { lat: 49.3405152, lng: 19.3137984 },
    { lat: 49.340579, lng: 19.3139088 },
    { lat: 49.3406337, lng: 19.3140177 },
    { lat: 49.3406835, lng: 19.3141166 },
    { lat: 49.3407842, lng: 19.3144398 },
    { lat: 49.3408442, lng: 19.3146341 },
    { lat: 49.3408623, lng: 19.3147535 },
    { lat: 49.3408648, lng: 19.3147623 },
    { lat: 49.3408939, lng: 19.3148968 },
    { lat: 49.3409626, lng: 19.3149929 },
    { lat: 49.3410177, lng: 19.3150891 },
    { lat: 49.3411274, lng: 19.3153129 },
    { lat: 49.3411883, lng: 19.3154078 },
    { lat: 49.341541, lng: 19.3160999 },
    { lat: 49.3416665, lng: 19.3163239 },
    { lat: 49.3418476, lng: 19.3164835 },
    { lat: 49.3419644, lng: 19.3165974 },
    { lat: 49.3421445, lng: 19.3168056 },
    { lat: 49.342347, lng: 19.3170124 },
    { lat: 49.342347, lng: 19.3170138 },
    { lat: 49.3423704, lng: 19.3170795 },
    { lat: 49.3423728, lng: 19.317084 },
    { lat: 49.3423456, lng: 19.317199 },
    { lat: 49.3422597, lng: 19.3173144 },
    { lat: 49.3420704, lng: 19.3174817 },
    { lat: 49.3419255, lng: 19.3176787 },
    { lat: 49.3419094, lng: 19.3179841 },
    { lat: 49.341912, lng: 19.3180196 },
    { lat: 49.3419553, lng: 19.3182674 },
    { lat: 49.3420005, lng: 19.3184146 },
    { lat: 49.3420254, lng: 19.318556 },
    { lat: 49.3420318, lng: 19.3189337 },
    { lat: 49.3420243, lng: 19.3190585 },
    { lat: 49.3420259, lng: 19.3190941 },
    { lat: 49.3420316, lng: 19.3192886 },
    { lat: 49.3420351, lng: 19.3193641 },
    { lat: 49.3420356, lng: 19.3194186 },
    { lat: 49.3420381, lng: 19.3195929 },
    { lat: 49.342041, lng: 19.3198449 },
    { lat: 49.3420448, lng: 19.3199005 },
    { lat: 49.3420551, lng: 19.3199781 },
    { lat: 49.3420731, lng: 19.3201196 },
    { lat: 49.3421102, lng: 19.3202604 },
    { lat: 49.3421102, lng: 19.3203562 },
    { lat: 49.3421533, lng: 19.3205788 },
    { lat: 49.342161, lng: 19.3206213 },
    { lat: 49.3421753, lng: 19.3206848 },
    { lat: 49.3422132, lng: 19.3208678 },
    { lat: 49.3422262, lng: 19.3209285 },
    { lat: 49.3422308, lng: 19.3209551 },
    { lat: 49.3422943, lng: 19.3211303 },
    { lat: 49.3423345, lng: 19.3212418 },
    { lat: 49.3424127, lng: 19.3213994 },
    { lat: 49.3425478, lng: 19.3216549 },
    { lat: 49.3426638, lng: 19.3218961 },
    { lat: 49.3427036, lng: 19.3220457 },
    { lat: 49.342763, lng: 19.3222069 },
    { lat: 49.3428313, lng: 19.3223399 },
    { lat: 49.3429811, lng: 19.3225319 },
    { lat: 49.3430387, lng: 19.3226062 },
    { lat: 49.3432091, lng: 19.3228252 },
    { lat: 49.3433207, lng: 19.3229683 },
    { lat: 49.3433601, lng: 19.3230188 },
    { lat: 49.3436448, lng: 19.3233915 },
    { lat: 49.3439479, lng: 19.3237712 },
    { lat: 49.3441392, lng: 19.3240074 },
    { lat: 49.344226, lng: 19.3241258 },
    { lat: 49.3442421, lng: 19.3241572 },
    { lat: 49.3442707, lng: 19.3242123 },
    { lat: 49.3443869, lng: 19.3247434 },
    { lat: 49.34441, lng: 19.3248533 },
    { lat: 49.3444396, lng: 19.3250271 },
    { lat: 49.3444428, lng: 19.325069 },
    { lat: 49.3444511, lng: 19.3251754 },
    { lat: 49.344486, lng: 19.3254152 },
    { lat: 49.3444961, lng: 19.3254849 },
    { lat: 49.3445178, lng: 19.3256336 },
    { lat: 49.3445225, lng: 19.3256627 },
    { lat: 49.3445655, lng: 19.3259554 },
    { lat: 49.3446403, lng: 19.3263194 },
    { lat: 49.3447971, lng: 19.3266603 },
    { lat: 49.3449552, lng: 19.3269042 },
    { lat: 49.344989, lng: 19.3269561 },
    { lat: 49.3451148, lng: 19.3271338 },
    { lat: 49.3451966, lng: 19.3272477 },
    { lat: 49.3452661, lng: 19.3273351 },
    { lat: 49.3453548, lng: 19.3274475 },
    { lat: 49.3454098, lng: 19.3274927 },
    { lat: 49.3455435, lng: 19.3276034 },
    { lat: 49.3456537, lng: 19.3276675 },
    { lat: 49.3457303, lng: 19.3277137 },
    { lat: 49.3459028, lng: 19.327742 },
    { lat: 49.3460613, lng: 19.3277831 },
    { lat: 49.3462722, lng: 19.3278534 },
    { lat: 49.3465044, lng: 19.3279319 },
    { lat: 49.346703, lng: 19.3281016 },
    { lat: 49.3467993, lng: 19.328186 },
    { lat: 49.346903, lng: 19.3282816 },
    { lat: 49.3469208, lng: 19.3282984 },
    { lat: 49.3470845, lng: 19.3284503 },
    { lat: 49.3471567, lng: 19.3285504 },
    { lat: 49.34731, lng: 19.3288118 },
    { lat: 49.3473291, lng: 19.3289073 },
    { lat: 49.3473543, lng: 19.3288712 },
    { lat: 49.3474685, lng: 19.3287322 },
    { lat: 49.3475867, lng: 19.3285655 },
    { lat: 49.3478267, lng: 19.3283005 },
    { lat: 49.3481494, lng: 19.3278775 },
    { lat: 49.3483303, lng: 19.3277213 },
    { lat: 49.3484845, lng: 19.3276472 },
    { lat: 49.3486132, lng: 19.3275068 },
    { lat: 49.3486352, lng: 19.3275424 },
    { lat: 49.3486924, lng: 19.3276333 },
    { lat: 49.3487586, lng: 19.3275801 },
    { lat: 49.3488294, lng: 19.3275282 },
    { lat: 49.3488877, lng: 19.3274726 },
    { lat: 49.3489674, lng: 19.3274201 },
    { lat: 49.3491241, lng: 19.3273739 },
    { lat: 49.3492557, lng: 19.3273189 },
    { lat: 49.3493187, lng: 19.3272682 },
    { lat: 49.3493853, lng: 19.3272237 },
    { lat: 49.3495517, lng: 19.3272176 },
    { lat: 49.3496893, lng: 19.3271959 },
    { lat: 49.3497941, lng: 19.3271952 },
    { lat: 49.349844, lng: 19.3271518 },
    { lat: 49.3498966, lng: 19.3271189 },
    { lat: 49.3499901, lng: 19.3270749 },
    { lat: 49.3500571, lng: 19.3270578 },
    { lat: 49.350161, lng: 19.3270622 },
    { lat: 49.3502993, lng: 19.3271308 },
    { lat: 49.3504068, lng: 19.3271179 },
    { lat: 49.3504935, lng: 19.3271138 },
    { lat: 49.3505413, lng: 19.3270503 },
    { lat: 49.3506255, lng: 19.3269478 },
    { lat: 49.3507413, lng: 19.3268696 },
    { lat: 49.3508714, lng: 19.3267388 },
    { lat: 49.3509832, lng: 19.3266246 },
    { lat: 49.3510734, lng: 19.3265756 },
    { lat: 49.3511148, lng: 19.3265016 },
    { lat: 49.3512178, lng: 19.3263674 },
    { lat: 49.3513493, lng: 19.3263099 },
    { lat: 49.3513632, lng: 19.3262444 },
    { lat: 49.351422, lng: 19.3262494 },
    { lat: 49.3514559, lng: 19.326203 },
    { lat: 49.3515219, lng: 19.3261708 },
    { lat: 49.3515633, lng: 19.326142 },
    { lat: 49.3515979, lng: 19.3261335 },
    { lat: 49.351716, lng: 19.3260352 },
    { lat: 49.3517818, lng: 19.3259669 },
    { lat: 49.3518717, lng: 19.3259154 },
    { lat: 49.3520424, lng: 19.3258091 },
    { lat: 49.352169, lng: 19.3257893 },
    { lat: 49.3521979, lng: 19.3257623 },
    { lat: 49.3522397, lng: 19.3257367 },
    { lat: 49.3523051, lng: 19.3256915 },
    { lat: 49.3523517, lng: 19.325673 },
    { lat: 49.352481, lng: 19.3256458 },
    { lat: 49.352592, lng: 19.3256306 },
    { lat: 49.3526166, lng: 19.3256265 },
    { lat: 49.3527019, lng: 19.3255491 },
    { lat: 49.3528178, lng: 19.3254962 },
    { lat: 49.3528852, lng: 19.3254685 },
    { lat: 49.352982, lng: 19.3255128 },
    { lat: 49.353147, lng: 19.3256486 },
    { lat: 49.3531881, lng: 19.3256328 },
    { lat: 49.3532432, lng: 19.3256553 },
    { lat: 49.3533251, lng: 19.3256944 },
    { lat: 49.3534519, lng: 19.3256139 },
    { lat: 49.3535606, lng: 19.3256207 },
    { lat: 49.353642, lng: 19.3256551 },
    { lat: 49.3536838, lng: 19.3256035 },
    { lat: 49.3537622, lng: 19.3256194 },
    { lat: 49.3538113, lng: 19.3255381 },
    { lat: 49.3538587, lng: 19.3255379 },
    { lat: 49.3539081, lng: 19.3255824 },
    { lat: 49.3539932, lng: 19.3255256 },
    { lat: 49.3540358, lng: 19.3254009 },
    { lat: 49.3541413, lng: 19.3254413 },
    { lat: 49.3541892, lng: 19.3254053 },
    { lat: 49.3542379, lng: 19.32543 },
    { lat: 49.3543016, lng: 19.3254453 },
    { lat: 49.3543435, lng: 19.3254469 },
    { lat: 49.3543991, lng: 19.3254342 },
    { lat: 49.3545295, lng: 19.325546 },
    { lat: 49.3546326, lng: 19.3255831 },
    { lat: 49.3548507, lng: 19.3255977 },
    { lat: 49.3549139, lng: 19.3255495 },
    { lat: 49.3550632, lng: 19.3255862 },
    { lat: 49.355106, lng: 19.3255374 },
    { lat: 49.3551465, lng: 19.3255593 },
    { lat: 49.355273, lng: 19.3256382 },
    { lat: 49.3553593, lng: 19.3256266 },
    { lat: 49.3554043, lng: 19.3256737 },
    { lat: 49.3554468, lng: 19.3257843 },
    { lat: 49.35551, lng: 19.3258377 },
    { lat: 49.3555642, lng: 19.3258397 },
    { lat: 49.3557035, lng: 19.3259259 },
    { lat: 49.3558002, lng: 19.3259174 },
    { lat: 49.355909, lng: 19.3259122 },
    { lat: 49.3559724, lng: 19.3259147 },
    { lat: 49.3560467, lng: 19.325913 },
    { lat: 49.3561368, lng: 19.3259146 },
    { lat: 49.3561878, lng: 19.3258938 },
    { lat: 49.3563451, lng: 19.3258352 },
    { lat: 49.3563943, lng: 19.3257814 },
    { lat: 49.3564674, lng: 19.3257795 },
    { lat: 49.3565236, lng: 19.3257743 },
    { lat: 49.3565925, lng: 19.325684 },
    { lat: 49.3566275, lng: 19.3256349 },
    { lat: 49.3567314, lng: 19.3255685 },
    { lat: 49.3568542, lng: 19.3254223 },
    { lat: 49.3569712, lng: 19.3253262 },
    { lat: 49.3569574, lng: 19.3252475 },
    { lat: 49.356978, lng: 19.3251089 },
    { lat: 49.3569909, lng: 19.3250717 },
    { lat: 49.3570709, lng: 19.3249053 },
    { lat: 49.3571217, lng: 19.3248093 },
    { lat: 49.357109, lng: 19.3247811 },
    { lat: 49.3571333, lng: 19.324701 },
    { lat: 49.3572577, lng: 19.3245647 },
    { lat: 49.3573088, lng: 19.3244506 },
    { lat: 49.3573242, lng: 19.3244208 },
    { lat: 49.357405, lng: 19.3243667 },
    { lat: 49.3574624, lng: 19.3242931 },
    { lat: 49.3575044, lng: 19.3242772 },
    { lat: 49.3575333, lng: 19.324243 },
    { lat: 49.3575965, lng: 19.3241724 },
    { lat: 49.3576593, lng: 19.3240714 },
    { lat: 49.3577616, lng: 19.3240175 },
    { lat: 49.3578663, lng: 19.3239434 },
    { lat: 49.3580373, lng: 19.3238649 },
    { lat: 49.35816, lng: 19.323817 },
    { lat: 49.3582489, lng: 19.3237884 },
    { lat: 49.3582772, lng: 19.323769 },
    { lat: 49.3582777, lng: 19.3237285 },
    { lat: 49.3583051, lng: 19.3237146 },
    { lat: 49.3583666, lng: 19.3236818 },
    { lat: 49.3584226, lng: 19.3236264 },
    { lat: 49.3585398, lng: 19.3236486 },
    { lat: 49.3585889, lng: 19.3236381 },
    { lat: 49.3586599, lng: 19.3236162 },
    { lat: 49.3587593, lng: 19.3235698 },
    { lat: 49.3588611, lng: 19.323531 },
    { lat: 49.3589041, lng: 19.3234873 },
    { lat: 49.3589257, lng: 19.3235134 },
    { lat: 49.3589979, lng: 19.3234483 },
    { lat: 49.3591228, lng: 19.3233471 },
    { lat: 49.3592055, lng: 19.3232823 },
    { lat: 49.3592657, lng: 19.3232218 },
    { lat: 49.3593673, lng: 19.3231527 },
    { lat: 49.3594448, lng: 19.3231738 },
    { lat: 49.3594563, lng: 19.3231516 },
    { lat: 49.359507, lng: 19.3230097 },
    { lat: 49.359519, lng: 19.3229744 },
    { lat: 49.3595997, lng: 19.3229427 },
    { lat: 49.3596265, lng: 19.3229159 },
    { lat: 49.3597278, lng: 19.3227912 },
    { lat: 49.359845, lng: 19.3227457 },
    { lat: 49.3600572, lng: 19.3227292 },
    { lat: 49.3600821, lng: 19.3227329 },
    { lat: 49.3602958, lng: 19.3228431 },
    { lat: 49.3603645, lng: 19.3227925 },
    { lat: 49.3603855, lng: 19.3227808 },
    { lat: 49.360402, lng: 19.3227515 },
    { lat: 49.3603955, lng: 19.322733 },
    { lat: 49.3604878, lng: 19.3226813 },
    { lat: 49.36057, lng: 19.3226823 },
    { lat: 49.3606395, lng: 19.3226982 },
    { lat: 49.3607177, lng: 19.3227311 },
    { lat: 49.3607963, lng: 19.3226993 },
    { lat: 49.36085, lng: 19.3226659 },
    { lat: 49.3608925, lng: 19.3226601 },
    { lat: 49.3609486, lng: 19.322728 },
    { lat: 49.3609843, lng: 19.3227194 },
    { lat: 49.3610818, lng: 19.3226833 },
    { lat: 49.3611037, lng: 19.322674 },
    { lat: 49.361221, lng: 19.3226709 },
    { lat: 49.3613729, lng: 19.3226924 },
    { lat: 49.3615408, lng: 19.3227375 },
    { lat: 49.3615836, lng: 19.3226938 },
    { lat: 49.361658, lng: 19.3226689 },
    { lat: 49.3616996, lng: 19.3226198 },
    { lat: 49.3617425, lng: 19.3226191 },
    { lat: 49.3618299, lng: 19.3227288 },
    { lat: 49.3618676, lng: 19.3227636 },
    { lat: 49.36198, lng: 19.3226872 },
    { lat: 49.3620351, lng: 19.3226595 },
    { lat: 49.3620692, lng: 19.3226423 },
    { lat: 49.3621131, lng: 19.3226418 },
    { lat: 49.3621828, lng: 19.3226374 },
    { lat: 49.3622634, lng: 19.3226281 },
    { lat: 49.3624133, lng: 19.3226264 },
    { lat: 49.3625391, lng: 19.3225001 },
    { lat: 49.362569, lng: 19.3224885 },
    { lat: 49.3626516, lng: 19.3225423 },
    { lat: 49.3627011, lng: 19.3224204 },
    { lat: 49.362759, lng: 19.3223822 },
    { lat: 49.362878, lng: 19.3222758 },
    { lat: 49.3629407, lng: 19.3223393 },
    { lat: 49.3630014, lng: 19.3223594 },
    { lat: 49.363093, lng: 19.3223681 },
    { lat: 49.3631455, lng: 19.3223348 },
    { lat: 49.3631986, lng: 19.3222888 },
    { lat: 49.36322, lng: 19.3223125 },
    { lat: 49.3632647, lng: 19.3223039 },
    { lat: 49.3633449, lng: 19.3222871 },
    { lat: 49.3633853, lng: 19.3221876 },
    { lat: 49.3634759, lng: 19.3221711 },
    { lat: 49.3635463, lng: 19.3221362 },
    { lat: 49.3636062, lng: 19.3221383 },
    { lat: 49.3636902, lng: 19.3220581 },
    { lat: 49.3638048, lng: 19.3219742 },
    { lat: 49.363843, lng: 19.321948 },
    { lat: 49.3639941, lng: 19.3220022 },
    { lat: 49.364037, lng: 19.3219555 },
    { lat: 49.3641384, lng: 19.3218561 },
    { lat: 49.3641925, lng: 19.3216914 },
    { lat: 49.3643013, lng: 19.3213442 },
    { lat: 49.3643625, lng: 19.32123 },
    { lat: 49.364346, lng: 19.3210956 },
    { lat: 49.36437, lng: 19.3210586 },
    { lat: 49.3644106, lng: 19.3210092 },
    { lat: 49.364438, lng: 19.3209679 },
    { lat: 49.3644722, lng: 19.3207869 },
    { lat: 49.3645033, lng: 19.3207051 },
    { lat: 49.3645369, lng: 19.3206757 },
    { lat: 49.3645838, lng: 19.3206904 },
    { lat: 49.3646128, lng: 19.3206131 },
    { lat: 49.3647591, lng: 19.3206059 },
    { lat: 49.3647719, lng: 19.3203514 },
    { lat: 49.3648735, lng: 19.3202364 },
    { lat: 49.3649417, lng: 19.3202513 },
    { lat: 49.3649567, lng: 19.3202348 },
    { lat: 49.3649555, lng: 19.320184 },
    { lat: 49.3649288, lng: 19.3200033 },
    { lat: 49.3649588, lng: 19.3198984 },
    { lat: 49.3650119, lng: 19.3198773 },
    { lat: 49.3650547, lng: 19.3198032 },
    { lat: 49.3651384, lng: 19.3197842 },
    { lat: 49.3651699, lng: 19.3197626 },
    { lat: 49.3652011, lng: 19.3197534 },
    { lat: 49.3652182, lng: 19.3197338 },
    { lat: 49.3652746, lng: 19.3197084 },
    { lat: 49.3653408, lng: 19.3196551 },
    { lat: 49.3653768, lng: 19.3195376 },
    { lat: 49.3654002, lng: 19.3195289 },
    { lat: 49.3654361, lng: 19.319573 },
    { lat: 49.3655022, lng: 19.3195679 },
    { lat: 49.3655288, lng: 19.3195563 },
    { lat: 49.3655753, lng: 19.3195403 },
    { lat: 49.3656458, lng: 19.3195788 },
    { lat: 49.3657154, lng: 19.3196673 },
    { lat: 49.3657712, lng: 19.3196796 },
    { lat: 49.3658079, lng: 19.3195949 },
    { lat: 49.3658753, lng: 19.3195929 },
    { lat: 49.3659441, lng: 19.3195954 },
    { lat: 49.3660094, lng: 19.3195925 },
    { lat: 49.3660805, lng: 19.319593 },
    { lat: 49.3661147, lng: 19.3195766 },
    { lat: 49.3661679, lng: 19.3195385 },
    { lat: 49.3662338, lng: 19.3194726 },
    { lat: 49.3662498, lng: 19.3194579 },
    { lat: 49.366272, lng: 19.3194713 },
    { lat: 49.366333, lng: 19.3195242 },
    { lat: 49.3663854, lng: 19.3195141 },
    { lat: 49.3664148, lng: 19.3195355 },
    { lat: 49.3664687, lng: 19.319532 },
    { lat: 49.3665325, lng: 19.3194787 },
    { lat: 49.3665749, lng: 19.3194451 },
    { lat: 49.366668, lng: 19.3193878 },
    { lat: 49.3667063, lng: 19.3194324 },
    { lat: 49.3667885, lng: 19.3193828 },
    { lat: 49.3668985, lng: 19.3191364 },
    { lat: 49.3669308, lng: 19.3191046 },
    { lat: 49.366951, lng: 19.3190774 },
    { lat: 49.3670676, lng: 19.3190671 },
    { lat: 49.3670894, lng: 19.3190477 },
    { lat: 49.3670695, lng: 19.3189862 },
    { lat: 49.3671212, lng: 19.3188163 },
    { lat: 49.3671974, lng: 19.3188289 },
    { lat: 49.3672731, lng: 19.3186929 },
    { lat: 49.3673433, lng: 19.3186729 },
    { lat: 49.3673758, lng: 19.3186212 },
    { lat: 49.367397, lng: 19.3185964 },
    { lat: 49.3674522, lng: 19.3185683 },
    { lat: 49.3675473, lng: 19.3186022 },
    { lat: 49.3675564, lng: 19.3186052 },
    { lat: 49.3675794, lng: 19.3185933 },
    { lat: 49.3675976, lng: 19.3185482 },
    { lat: 49.3676199, lng: 19.3185443 },
    { lat: 49.3676479, lng: 19.3185886 },
    { lat: 49.3676797, lng: 19.3185211 },
    { lat: 49.3677273, lng: 19.3185032 },
    { lat: 49.367685, lng: 19.3184431 },
    { lat: 49.3677242, lng: 19.3183889 },
    { lat: 49.367782, lng: 19.3183966 },
    { lat: 49.3678026, lng: 19.3183791 },
    { lat: 49.3678074, lng: 19.3183139 },
    { lat: 49.3678571, lng: 19.3182675 },
    { lat: 49.3679278, lng: 19.3183335 },
    { lat: 49.3680317, lng: 19.3184789 },
    { lat: 49.3680593, lng: 19.3184697 },
    { lat: 49.3680662, lng: 19.3183992 },
    { lat: 49.3680943, lng: 19.3183748 },
    { lat: 49.3681815, lng: 19.3184566 },
    { lat: 49.3682318, lng: 19.3185418 },
    { lat: 49.3682858, lng: 19.3186147 },
    { lat: 49.3683184, lng: 19.3187047 },
    { lat: 49.3683561, lng: 19.3187186 },
    { lat: 49.3684523, lng: 19.3186314 },
    { lat: 49.3685811, lng: 19.3186132 },
    { lat: 49.3686536, lng: 19.3186489 },
    { lat: 49.3687405, lng: 19.3187684 },
    { lat: 49.3687709, lng: 19.3187911 },
    { lat: 49.3688, lng: 19.3188139 },
    { lat: 49.3688593, lng: 19.3188742 },
    { lat: 49.3689087, lng: 19.318825 },
    { lat: 49.3689419, lng: 19.3188318 },
    { lat: 49.3690184, lng: 19.3188516 },
    { lat: 49.3690344, lng: 19.3189055 },
    { lat: 49.3690413, lng: 19.3191033 },
    { lat: 49.369047, lng: 19.3191992 },
    { lat: 49.3690525, lng: 19.3192449 },
    { lat: 49.3690686, lng: 19.3193722 },
    { lat: 49.3690833, lng: 19.3194689 },
    { lat: 49.3691062, lng: 19.319568 },
    { lat: 49.3691357, lng: 19.3196475 },
    { lat: 49.3691656, lng: 19.3197068 },
    { lat: 49.369231, lng: 19.3198015 },
    { lat: 49.3693318, lng: 19.3199322 },
    { lat: 49.3693707, lng: 19.3199441 },
    { lat: 49.3694123, lng: 19.3199916 },
    { lat: 49.3694695, lng: 19.3200569 },
    { lat: 49.3695203, lng: 19.3200386 },
    { lat: 49.36957, lng: 19.3200151 },
    { lat: 49.369601, lng: 19.3200265 },
    { lat: 49.3697165, lng: 19.3201327 },
    { lat: 49.3697625, lng: 19.3201323 },
    { lat: 49.3697706, lng: 19.3201325 },
    { lat: 49.3698099, lng: 19.3200602 },
    { lat: 49.3700182, lng: 19.3199106 },
    { lat: 49.3699401, lng: 19.3191638 },
    { lat: 49.3698252, lng: 19.3183998 },
    { lat: 49.369931, lng: 19.3183245 },
    { lat: 49.3700282, lng: 19.3182573 },
    { lat: 49.3703254, lng: 19.318061 },
    { lat: 49.3704456, lng: 19.3180054 },
    { lat: 49.3706703, lng: 19.3178219 },
    { lat: 49.3709161, lng: 19.3176363 },
    { lat: 49.371013, lng: 19.3175135 },
    { lat: 49.371149, lng: 19.3173416 },
    { lat: 49.3715266, lng: 19.3170073 },
    { lat: 49.3718596, lng: 19.3168027 },
    { lat: 49.3721173, lng: 19.3166281 },
    { lat: 49.3722673, lng: 19.3165146 },
    { lat: 49.3724132, lng: 19.3164041 },
    { lat: 49.372438, lng: 19.3162835 },
    { lat: 49.372461, lng: 19.3161754 },
    { lat: 49.3725007, lng: 19.3159898 },
    { lat: 49.372553, lng: 19.315736 },
    { lat: 49.372571, lng: 19.3156378 },
    { lat: 49.3728895, lng: 19.3151303 },
    { lat: 49.3729095, lng: 19.3150991 },
    { lat: 49.3731577, lng: 19.3146479 },
    { lat: 49.3733128, lng: 19.3143577 },
    { lat: 49.3734639, lng: 19.3141153 },
    { lat: 49.3735924, lng: 19.313951 },
    { lat: 49.3738509, lng: 19.3135786 },
    { lat: 49.3738992, lng: 19.3134817 },
    { lat: 49.3738989, lng: 19.3133758 },
    { lat: 49.3739009, lng: 19.3132284 },
    { lat: 49.373902, lng: 19.3130865 },
    { lat: 49.373904, lng: 19.3129575 },
    { lat: 49.3739055, lng: 19.3128485 },
    { lat: 49.3739077, lng: 19.3127499 },
    { lat: 49.373916, lng: 19.3126416 },
    { lat: 49.3739227, lng: 19.3125175 },
    { lat: 49.3739265, lng: 19.3124343 },
    { lat: 49.3739277, lng: 19.312338 },
    { lat: 49.373933, lng: 19.3122618 },
    { lat: 49.3739351, lng: 19.3121607 },
    { lat: 49.3739382, lng: 19.3120113 },
    { lat: 49.3739362, lng: 19.3118973 },
    { lat: 49.3739347, lng: 19.3117705 },
    { lat: 49.3739329, lng: 19.3116387 },
    { lat: 49.3739354, lng: 19.3115704 },
    { lat: 49.3739371, lng: 19.3114867 },
    { lat: 49.3739436, lng: 19.3114086 },
    { lat: 49.3739536, lng: 19.3113102 },
    { lat: 49.3739651, lng: 19.311174 },
    { lat: 49.3739772, lng: 19.3110704 },
    { lat: 49.373985, lng: 19.3109748 },
    { lat: 49.3739853, lng: 19.310906 },
    { lat: 49.3739856, lng: 19.3108174 },
    { lat: 49.3739904, lng: 19.310704 },
    { lat: 49.3739928, lng: 19.3106151 },
    { lat: 49.3739966, lng: 19.3105496 },
    { lat: 49.373997, lng: 19.3104888 },
    { lat: 49.3739921, lng: 19.3103595 },
    { lat: 49.3739876, lng: 19.3102653 },
    { lat: 49.3739864, lng: 19.3102394 },
    { lat: 49.3739844, lng: 19.3101713 },
    { lat: 49.373982, lng: 19.3101205 },
    { lat: 49.3739806, lng: 19.3100469 },
    { lat: 49.3739786, lng: 19.3100041 },
    { lat: 49.3739753, lng: 19.3099535 },
    { lat: 49.3739876, lng: 19.3098093 },
    { lat: 49.373997, lng: 19.3097236 },
    { lat: 49.3740122, lng: 19.3096152 },
    { lat: 49.3740266, lng: 19.3095146 },
    { lat: 49.374041, lng: 19.3093886 },
    { lat: 49.3740672, lng: 19.3092783 },
    { lat: 49.3740949, lng: 19.3091446 },
    { lat: 49.3741148, lng: 19.3089707 },
    { lat: 49.3741327, lng: 19.3088501 },
    { lat: 49.3741608, lng: 19.3087298 },
    { lat: 49.3741974, lng: 19.3085941 },
    { lat: 49.3742345, lng: 19.3084515 },
    { lat: 49.3742571, lng: 19.3083336 },
    { lat: 49.3742769, lng: 19.3082276 },
    { lat: 49.3742852, lng: 19.3081649 },
    { lat: 49.3742934, lng: 19.3081021 },
    { lat: 49.3742986, lng: 19.3080668 },
    { lat: 49.3743106, lng: 19.3080116 },
    { lat: 49.3743176, lng: 19.3079689 },
    { lat: 49.3743324, lng: 19.3078989 },
    { lat: 49.374349, lng: 19.3077987 },
    { lat: 49.3743643, lng: 19.3076928 },
    { lat: 49.3743729, lng: 19.3075646 },
    { lat: 49.3743781, lng: 19.3074863 },
    { lat: 49.3743764, lng: 19.3074485 },
    { lat: 49.3743756, lng: 19.3073119 },
    { lat: 49.3743737, lng: 19.3072463 },
    { lat: 49.3743741, lng: 19.3071348 },
    { lat: 49.3743753, lng: 19.3070667 },
    { lat: 49.3743781, lng: 19.307006 },
    { lat: 49.3743808, lng: 19.3069431 },
    { lat: 49.3743835, lng: 19.3068799 },
    { lat: 49.374388, lng: 19.3068093 },
    { lat: 49.3743901, lng: 19.3067334 },
    { lat: 49.3743949, lng: 19.3066204 },
    { lat: 49.374399, lng: 19.3065419 },
    { lat: 49.3744042, lng: 19.3064386 },
    { lat: 49.3744059, lng: 19.306378 },
    { lat: 49.3744076, lng: 19.3063402 },
    { lat: 49.3744088, lng: 19.3062949 },
    { lat: 49.3744108, lng: 19.3062679 },
    { lat: 49.3744124, lng: 19.3062265 },
    { lat: 49.3744146, lng: 19.3061586 },
    { lat: 49.3744139, lng: 19.3061431 },
    { lat: 49.374416, lng: 19.3060876 },
    { lat: 49.3744226, lng: 19.3059921 },
    { lat: 49.3744219, lng: 19.3059517 },
    { lat: 49.3744235, lng: 19.305916 },
    { lat: 49.3744255, lng: 19.3058377 },
    { lat: 49.3744285, lng: 19.3057571 },
    { lat: 49.3744249, lng: 19.3057296 },
    { lat: 49.3744145, lng: 19.3056505 },
    { lat: 49.3744026, lng: 19.305569 },
    { lat: 49.3743995, lng: 19.3055285 },
    { lat: 49.3743934, lng: 19.3054677 },
    { lat: 49.3743835, lng: 19.3053787 },
    { lat: 49.374379, lng: 19.3053282 },
    { lat: 49.3740145, lng: 19.3042699 },
    { lat: 49.3738089, lng: 19.3036884 },
    { lat: 49.3737495, lng: 19.303525 },
    { lat: 49.3733482, lng: 19.302448 },
    { lat: 49.3733255, lng: 19.3023728 },
    { lat: 49.3733113, lng: 19.3023378 },
    { lat: 49.3732797, lng: 19.3022423 },
    { lat: 49.3736382, lng: 19.3004305 },
    { lat: 49.3737772, lng: 19.299558 },
    { lat: 49.3737502, lng: 19.2990312 },
    { lat: 49.3737851, lng: 19.2981019 },
    { lat: 49.3738829, lng: 19.2970488 },
    { lat: 49.3738218, lng: 19.2964708 },
    { lat: 49.3738224, lng: 19.2962498 },
    { lat: 49.3739761, lng: 19.2958049 },
    { lat: 49.3738615, lng: 19.2938814 },
    { lat: 49.3737114, lng: 19.2928301 },
    { lat: 49.3736726, lng: 19.2925588 },
    { lat: 49.3734, lng: 19.2913201 },
    { lat: 49.3731529, lng: 19.2904362 },
    { lat: 49.3730873, lng: 19.2892837 },
    { lat: 49.3730403, lng: 19.2885801 },
    { lat: 49.373027, lng: 19.2875618 },
    { lat: 49.3730891, lng: 19.2870645 },
    { lat: 49.3731514, lng: 19.2858573 },
    { lat: 49.3731918, lng: 19.2853338 },
    { lat: 49.3732076, lng: 19.2852384 },
    { lat: 49.3732193, lng: 19.2851029 },
    { lat: 49.3732845, lng: 19.2851221 },
    { lat: 49.3735363, lng: 19.2851834 },
    { lat: 49.3738924, lng: 19.2852028 },
    { lat: 49.3743715, lng: 19.2851839 },
    { lat: 49.3746135, lng: 19.2851769 },
    { lat: 49.3747912, lng: 19.2850989 },
    { lat: 49.3749341, lng: 19.2850013 },
    { lat: 49.3750397, lng: 19.284876 },
    { lat: 49.3750692, lng: 19.2847375 },
    { lat: 49.3751562, lng: 19.284505 },
    { lat: 49.3752662, lng: 19.2837568 },
    { lat: 49.3752709, lng: 19.2836673 },
    { lat: 49.3752832, lng: 19.2835922 },
    { lat: 49.3753146, lng: 19.2833981 },
    { lat: 49.3753392, lng: 19.2832041 },
    { lat: 49.3754014, lng: 19.2828536 },
    { lat: 49.3754059, lng: 19.2828039 },
    { lat: 49.3754157, lng: 19.2827012 },
    { lat: 49.375449, lng: 19.2823571 },
    { lat: 49.3754724, lng: 19.2819737 },
    { lat: 49.3754877, lng: 19.2817988 },
    { lat: 49.3754965, lng: 19.2815573 },
    { lat: 49.375622, lng: 19.2810659 },
    { lat: 49.3757461, lng: 19.2807845 },
    { lat: 49.3758179, lng: 19.2806554 },
    { lat: 49.3758222, lng: 19.2806513 },
    { lat: 49.3758506, lng: 19.2805638 },
    { lat: 49.3758562, lng: 19.2805611 },
    { lat: 49.375893, lng: 19.2805302 },
    { lat: 49.3762108, lng: 19.2799827 },
    { lat: 49.3763126, lng: 19.2798025 },
    { lat: 49.376369, lng: 19.2796577 },
    { lat: 49.3764297, lng: 19.2795135 },
    { lat: 49.3764704, lng: 19.2794211 },
    { lat: 49.3765589, lng: 19.2790319 },
    { lat: 49.3766112, lng: 19.2787805 },
    { lat: 49.3765923, lng: 19.2785967 },
    { lat: 49.3765646, lng: 19.2783213 },
    { lat: 49.3765927, lng: 19.2780581 },
    { lat: 49.3765957, lng: 19.277907 },
    { lat: 49.376566, lng: 19.2775403 },
    { lat: 49.3765619, lng: 19.2774767 },
    { lat: 49.3765576, lng: 19.2773861 },
    { lat: 49.3765315, lng: 19.2767922 },
    { lat: 49.3764698, lng: 19.2764436 },
    { lat: 49.3763852, lng: 19.2760584 },
    { lat: 49.3763639, lng: 19.2759425 },
    { lat: 49.376306, lng: 19.2757421 },
    { lat: 49.3762112, lng: 19.2755769 },
    { lat: 49.3760896, lng: 19.2753636 },
    { lat: 49.3760209, lng: 19.2752451 },
    { lat: 49.3758551, lng: 19.2750556 },
    { lat: 49.3757457, lng: 19.2749332 },
    { lat: 49.3756017, lng: 19.2746523 },
    { lat: 49.3755531, lng: 19.2747214 },
    { lat: 49.3755238, lng: 19.2747655 },
    { lat: 49.375513, lng: 19.2747534 },
    { lat: 49.3751747, lng: 19.2752218 },
    { lat: 49.3750625, lng: 19.2754509 },
    { lat: 49.3749307, lng: 19.275476 },
    { lat: 49.3749174, lng: 19.2755235 },
    { lat: 49.3749436, lng: 19.2756525 },
    { lat: 49.3749574, lng: 19.2758235 },
    { lat: 49.3749015, lng: 19.2758793 },
    { lat: 49.3748384, lng: 19.2758787 },
    { lat: 49.3747281, lng: 19.2757395 },
    { lat: 49.3746349, lng: 19.2757451 },
    { lat: 49.374489, lng: 19.2758315 },
    { lat: 49.3743463, lng: 19.2757907 },
    { lat: 49.3741663, lng: 19.2759185 },
    { lat: 49.3739086, lng: 19.2757327 },
    { lat: 49.3737602, lng: 19.2757413 },
    { lat: 49.3737401, lng: 19.2757439 },
    { lat: 49.3735764, lng: 19.2756449 },
    { lat: 49.3735399, lng: 19.2757557 },
    { lat: 49.37338, lng: 19.2757113 },
    { lat: 49.3733164, lng: 19.2756044 },
    { lat: 49.3732526, lng: 19.2755638 },
    { lat: 49.3731228, lng: 19.2753461 },
    { lat: 49.3728713, lng: 19.2751329 },
    { lat: 49.3727183, lng: 19.2749944 },
    { lat: 49.3725746, lng: 19.2749124 },
    { lat: 49.3723956, lng: 19.2749377 },
    { lat: 49.3723063, lng: 19.2749057 },
    { lat: 49.3722749, lng: 19.274817 },
    { lat: 49.3721938, lng: 19.27477 },
    { lat: 49.3721437, lng: 19.2747835 },
    { lat: 49.3720942, lng: 19.2749055 },
    { lat: 49.3719765, lng: 19.2749378 },
    { lat: 49.3718345, lng: 19.2747735 },
    { lat: 49.3717618, lng: 19.2747628 },
    { lat: 49.3713697, lng: 19.274528 },
    { lat: 49.371147, lng: 19.2742743 },
    { lat: 49.3710094, lng: 19.2741761 },
    { lat: 49.37092, lng: 19.2740295 },
    { lat: 49.3708611, lng: 19.2738082 },
    { lat: 49.3707618, lng: 19.2737798 },
    { lat: 49.3706698, lng: 19.2738382 },
    { lat: 49.370639, lng: 19.2738496 },
    { lat: 49.3704843, lng: 19.2736104 },
    { lat: 49.3702121, lng: 19.2733337 },
    { lat: 49.3701846, lng: 19.2732073 },
    { lat: 49.3699023, lng: 19.2731462 },
    { lat: 49.3697268, lng: 19.272752 },
    { lat: 49.3694472, lng: 19.2726042 },
    { lat: 49.369342, lng: 19.2724571 },
    { lat: 49.3693001, lng: 19.272405 },
    { lat: 49.3691426, lng: 19.2722913 },
    { lat: 49.3689759, lng: 19.272196 },
    { lat: 49.368656, lng: 19.2719401 },
    { lat: 49.3684793, lng: 19.2718998 },
    { lat: 49.3683917, lng: 19.2717844 },
    { lat: 49.3683288, lng: 19.2718135 },
    { lat: 49.3682851, lng: 19.2716039 },
    { lat: 49.3681237, lng: 19.2714816 },
    { lat: 49.3681078, lng: 19.2714302 },
    { lat: 49.3679585, lng: 19.2713011 },
    { lat: 49.3678751, lng: 19.2712757 },
    { lat: 49.3678114, lng: 19.2712409 },
    { lat: 49.3677795, lng: 19.271162 },
    { lat: 49.3677382, lng: 19.2711463 },
    { lat: 49.3676889, lng: 19.2711529 },
    { lat: 49.3675308, lng: 19.2710523 },
    { lat: 49.3673381, lng: 19.270838 },
    { lat: 49.367272, lng: 19.2707983 },
    { lat: 49.3671666, lng: 19.270762 },
    { lat: 49.3670698, lng: 19.2707616 },
    { lat: 49.3669596, lng: 19.2708389 },
    { lat: 49.366865, lng: 19.2708415 },
    { lat: 49.3667678, lng: 19.2707182 },
    { lat: 49.3667639, lng: 19.2706611 },
    { lat: 49.3667451, lng: 19.2706195 },
    { lat: 49.3666956, lng: 19.2705964 },
    { lat: 49.3666402, lng: 19.2706431 },
    { lat: 49.3665691, lng: 19.2706376 },
    { lat: 49.3665059, lng: 19.2704953 },
    { lat: 49.3664032, lng: 19.2704695 },
    { lat: 49.3662434, lng: 19.2703073 },
    { lat: 49.3661755, lng: 19.2703253 },
    { lat: 49.3660984, lng: 19.2703133 },
    { lat: 49.3660685, lng: 19.2702993 },
    { lat: 49.3660159, lng: 19.2702375 },
    { lat: 49.365922, lng: 19.2702339 },
    { lat: 49.3657806, lng: 19.270007 },
    { lat: 49.3656914, lng: 19.2699088 },
    { lat: 49.3656235, lng: 19.2699005 },
    { lat: 49.3655946, lng: 19.2698133 },
    { lat: 49.3655777, lng: 19.2697913 },
    { lat: 49.3655301, lng: 19.269788 },
    { lat: 49.3652665, lng: 19.2695053 },
    { lat: 49.3651949, lng: 19.2694691 },
    { lat: 49.3650916, lng: 19.2694763 },
    { lat: 49.3649893, lng: 19.2693619 },
    { lat: 49.3649789, lng: 19.2693815 },
    { lat: 49.3649273, lng: 19.269483 },
    { lat: 49.3649087, lng: 19.2695177 },
    { lat: 49.3648869, lng: 19.2695577 },
    { lat: 49.364858, lng: 19.269609 },
    { lat: 49.3648278, lng: 19.2696637 },
    { lat: 49.3648009, lng: 19.2697155 },
    { lat: 49.3647674, lng: 19.2697699 },
    { lat: 49.3647227, lng: 19.269849 },
    { lat: 49.3646983, lng: 19.2699009 },
    { lat: 49.3646701, lng: 19.2699478 },
    { lat: 49.3646424, lng: 19.2700076 },
    { lat: 49.3645932, lng: 19.2701093 },
    { lat: 49.3645658, lng: 19.2701738 },
    { lat: 49.3645278, lng: 19.2702482 },
    { lat: 49.364495, lng: 19.2703198 },
    { lat: 49.3644537, lng: 19.2703993 },
    { lat: 49.364416, lng: 19.2704863 },
    { lat: 49.3643863, lng: 19.2705456 },
    { lat: 49.3643545, lng: 19.2706128 },
    { lat: 49.3643146, lng: 19.2706972 },
    { lat: 49.364254, lng: 19.2708233 },
    { lat: 49.364145, lng: 19.2710643 },
    { lat: 49.3641003, lng: 19.2711662 },
    { lat: 49.3640473, lng: 19.2712831 },
    { lat: 49.3639951, lng: 19.271397 },
    { lat: 49.3639444, lng: 19.2715136 },
    { lat: 49.363894, lng: 19.2716154 },
    { lat: 49.363848, lng: 19.2717171 },
    { lat: 49.3637971, lng: 19.2718088 },
    { lat: 49.3636097, lng: 19.2722032 },
    { lat: 49.3635535, lng: 19.2723476 },
    { lat: 49.3634398, lng: 19.2726387 },
    { lat: 49.3634124, lng: 19.2727723 },
    { lat: 49.3633455, lng: 19.2730932 },
    { lat: 49.3632126, lng: 19.2733784 },
    { lat: 49.3631325, lng: 19.2735468 },
    { lat: 49.3630343, lng: 19.2737555 },
    { lat: 49.3629883, lng: 19.2738572 },
    { lat: 49.3628541, lng: 19.2741375 },
    { lat: 49.3627518, lng: 19.2743557 },
    { lat: 49.362388, lng: 19.2751197 },
    { lat: 49.362097, lng: 19.27571 },
    { lat: 49.361802, lng: 19.2763047 },
    { lat: 49.361705, lng: 19.2763058 },
    { lat: 49.3616072, lng: 19.2763347 },
    { lat: 49.3610354, lng: 19.2765126 },
    { lat: 49.3606127, lng: 19.2766764 },
    { lat: 49.3604473, lng: 19.2767559 },
    { lat: 49.3604332, lng: 19.2767423 },
    { lat: 49.3603723, lng: 19.2767169 },
    { lat: 49.3602784, lng: 19.2766847 },
    { lat: 49.3602023, lng: 19.2766487 },
    { lat: 49.3601326, lng: 19.276608 },
    { lat: 49.3600783, lng: 19.2765775 },
    { lat: 49.3599878, lng: 19.2765211 },
    { lat: 49.3598894, lng: 19.2764684 },
    { lat: 49.3598566, lng: 19.2764442 },
    { lat: 49.35977, lng: 19.2764965 },
    { lat: 49.359621, lng: 19.2765865 },
    { lat: 49.3595039, lng: 19.2766114 },
    { lat: 49.3594469, lng: 19.2766192 },
    { lat: 49.3594005, lng: 19.2765919 },
    { lat: 49.3593118, lng: 19.2765298 },
    { lat: 49.3592182, lng: 19.276452 },
    { lat: 49.3591293, lng: 19.2763852 },
    { lat: 49.3590616, lng: 19.2763367 },
    { lat: 49.3589905, lng: 19.2762882 },
    { lat: 49.3589148, lng: 19.2762341 },
    { lat: 49.3588468, lng: 19.2761806 },
    { lat: 49.358776, lng: 19.2761375 },
    { lat: 49.3587024, lng: 19.2760835 },
    { lat: 49.35862, lng: 19.276029 },
    { lat: 49.3585558, lng: 19.2759859 },
    { lat: 49.3584918, lng: 19.2759399 },
    { lat: 49.3584148, lng: 19.2758859 },
    { lat: 49.3582984, lng: 19.2758103 },
    { lat: 49.3581388, lng: 19.2756972 },
    { lat: 49.3580456, lng: 19.2756096 },
    { lat: 49.3579631, lng: 19.2755324 },
    { lat: 49.357881, lng: 19.2754656 },
    { lat: 49.3577323, lng: 19.2753278 },
    { lat: 49.3576145, lng: 19.275219 },
    { lat: 49.3574958, lng: 19.2751404 },
    { lat: 49.3574182, lng: 19.2751194 },
    { lat: 49.3572137, lng: 19.2750836 },
    { lat: 49.3565318, lng: 19.2749625 },
    { lat: 49.3564686, lng: 19.2749825 },
    { lat: 49.3564442, lng: 19.2749918 },
    { lat: 49.3560758, lng: 19.2751405 },
    { lat: 49.355804, lng: 19.2752553 },
    { lat: 49.3556915, lng: 19.2753037 },
    { lat: 49.3556203, lng: 19.2753257 },
    { lat: 49.3555467, lng: 19.2753429 },
    { lat: 49.3554429, lng: 19.2753588 },
    { lat: 49.3553478, lng: 19.2753755 },
    { lat: 49.3552652, lng: 19.2753468 },
    { lat: 49.3552187, lng: 19.2753371 },
    { lat: 49.3551307, lng: 19.2753133 },
    { lat: 49.3550385, lng: 19.2752726 },
    { lat: 49.3549304, lng: 19.2752242 },
    { lat: 49.354883, lng: 19.2751995 },
    { lat: 49.3548127, lng: 19.2751668 },
    { lat: 49.3547416, lng: 19.2751411 },
    { lat: 49.3546526, lng: 19.2751167 },
    { lat: 49.3546048, lng: 19.275105 },
    { lat: 49.3545739, lng: 19.2751218 },
    { lat: 49.3544831, lng: 19.2751529 },
    { lat: 49.3544285, lng: 19.2751684 },
    { lat: 49.3543673, lng: 19.2751861 },
    { lat: 49.3543089, lng: 19.2751893 },
    { lat: 49.354253, lng: 19.2751966 },
    { lat: 49.3541958, lng: 19.2752048 },
    { lat: 49.3541589, lng: 19.2752085 },
    { lat: 49.354125, lng: 19.2752119 },
    { lat: 49.3540324, lng: 19.2752306 },
    { lat: 49.3539588, lng: 19.275246 },
    { lat: 49.353895, lng: 19.2752556 },
    { lat: 49.3538279, lng: 19.2753108 },
    { lat: 49.3536221, lng: 19.2754873 },
    { lat: 49.3535156, lng: 19.275617 },
    { lat: 49.3534696, lng: 19.2756728 },
    { lat: 49.3534045, lng: 19.2757487 },
    { lat: 49.3532853, lng: 19.2758932 },
    { lat: 49.3532083, lng: 19.2759831 },
    { lat: 49.353136, lng: 19.2760714 },
    { lat: 49.3530394, lng: 19.2761761 },
    { lat: 49.3529777, lng: 19.2762293 },
    { lat: 49.3529161, lng: 19.2762849 },
    { lat: 49.352805, lng: 19.2764119 },
    { lat: 49.3527189, lng: 19.276522 },
    { lat: 49.3526241, lng: 19.2766344 },
    { lat: 49.3524834, lng: 19.2768052 },
    { lat: 49.3524213, lng: 19.2768739 },
    { lat: 49.3523573, lng: 19.2769493 },
    { lat: 49.3522709, lng: 19.2770544 },
    { lat: 49.3522455, lng: 19.2770865 },
    { lat: 49.3522073, lng: 19.2771377 },
    { lat: 49.3521467, lng: 19.2772135 },
    { lat: 49.3520478, lng: 19.2773388 },
    { lat: 49.351998, lng: 19.2774047 },
    { lat: 49.3519487, lng: 19.2774611 },
    { lat: 49.3518967, lng: 19.2775295 },
    { lat: 49.3518147, lng: 19.2776275 },
    { lat: 49.3517349, lng: 19.2777278 },
    { lat: 49.3516703, lng: 19.2778135 },
    { lat: 49.3515966, lng: 19.2779218 },
    { lat: 49.3515142, lng: 19.2780369 },
    { lat: 49.3514793, lng: 19.2780888 },
    { lat: 49.3514225, lng: 19.2781747 },
    { lat: 49.351366, lng: 19.2782634 },
    { lat: 49.3513134, lng: 19.2783448 },
    { lat: 49.3512727, lng: 19.2784115 },
    { lat: 49.3512378, lng: 19.2784631 },
    { lat: 49.3512035, lng: 19.2785222 },
    { lat: 49.351178, lng: 19.2785565 },
    { lat: 49.3504871, lng: 19.2788108 },
    { lat: 49.3504154, lng: 19.2786547 },
    { lat: 49.3494475, lng: 19.2764002 },
    { lat: 49.3485606, lng: 19.272492 },
    { lat: 49.3482691, lng: 19.2711245 },
    { lat: 49.348216, lng: 19.2679658 },
    { lat: 49.3481993, lng: 19.267026 },
    { lat: 49.3477574, lng: 19.2639667 },
    { lat: 49.3474576, lng: 19.2618856 },
    { lat: 49.3474311, lng: 19.2601188 },
    { lat: 49.3474649, lng: 19.2584397 },
    { lat: 49.3474253, lng: 19.2560476 },
    { lat: 49.3473744, lng: 19.254435 },
    { lat: 49.3473122, lng: 19.2542894 },
    { lat: 49.3473032, lng: 19.2542698 },
    { lat: 49.3472867, lng: 19.2542312 },
    { lat: 49.3467593, lng: 19.2525313 },
    { lat: 49.3458736, lng: 19.2510684 },
    { lat: 49.3450369, lng: 19.2506515 },
    { lat: 49.3421145, lng: 19.2496333 },
    { lat: 49.3410674, lng: 19.2492523 },
    { lat: 49.3397093, lng: 19.2498173 },
    { lat: 49.3382062, lng: 19.2487655 },
    { lat: 49.3372937, lng: 19.2471878 },
    { lat: 49.3342266, lng: 19.2454551 },
    { lat: 49.3340146, lng: 19.2452523 },
    { lat: 49.3325936, lng: 19.2434985 },
    { lat: 49.3319203, lng: 19.2426838 },
    { lat: 49.33078, lng: 19.2412717 },
    { lat: 49.3296614, lng: 19.2400151 },
    { lat: 49.3284693, lng: 19.2399719 },
    { lat: 49.3283232, lng: 19.2399586 },
    { lat: 49.3281031, lng: 19.2398789 },
    { lat: 49.3266308, lng: 19.2391126 },
    { lat: 49.3230908, lng: 19.2382643 },
    { lat: 49.322865, lng: 19.2382093 },
    { lat: 49.3210091, lng: 19.238217 },
    { lat: 49.320123, lng: 19.238209 },
    { lat: 49.319694, lng: 19.239041 },
    { lat: 49.319159, lng: 19.239824 },
    { lat: 49.318369, lng: 19.239897 },
    { lat: 49.317034, lng: 19.241249 },
    { lat: 49.316762, lng: 19.241458 },
  ],
  Mútne: [
    { lat: 49.4843337, lng: 19.2276977 },
    { lat: 49.4827827, lng: 19.2287142 },
    { lat: 49.4810685, lng: 19.2293282 },
    { lat: 49.4805175, lng: 19.2297505 },
    { lat: 49.4803706, lng: 19.2297666 },
    { lat: 49.480177, lng: 19.2300084 },
    { lat: 49.4799122, lng: 19.2303144 },
    { lat: 49.4798257, lng: 19.2304854 },
    { lat: 49.479563, lng: 19.2307222 },
    { lat: 49.4794611, lng: 19.2309465 },
    { lat: 49.4793612, lng: 19.2310008 },
    { lat: 49.4792774, lng: 19.2311585 },
    { lat: 49.4791019, lng: 19.2311884 },
    { lat: 49.4789142, lng: 19.2312689 },
    { lat: 49.4787684, lng: 19.2312121 },
    { lat: 49.4785899, lng: 19.2313224 },
    { lat: 49.4783441, lng: 19.2315345 },
    { lat: 49.4782405, lng: 19.2315125 },
    { lat: 49.4781564, lng: 19.231353 },
    { lat: 49.4780114, lng: 19.2314128 },
    { lat: 49.4778781, lng: 19.2316373 },
    { lat: 49.4778537, lng: 19.2317879 },
    { lat: 49.4774534, lng: 19.2322095 },
    { lat: 49.4774228, lng: 19.2323256 },
    { lat: 49.4772075, lng: 19.2325593 },
    { lat: 49.47706, lng: 19.2327487 },
    { lat: 49.4769117, lng: 19.2328285 },
    { lat: 49.4768763, lng: 19.2329165 },
    { lat: 49.4765534, lng: 19.2330931 },
    { lat: 49.4764632, lng: 19.2331134 },
    { lat: 49.4764133, lng: 19.233227 },
    { lat: 49.4762472, lng: 19.2331442 },
    { lat: 49.4760397, lng: 19.233286 },
    { lat: 49.4758448, lng: 19.2332141 },
    { lat: 49.4754349, lng: 19.2334333 },
    { lat: 49.4752266, lng: 19.2336753 },
    { lat: 49.4749372, lng: 19.2336328 },
    { lat: 49.4748009, lng: 19.2337268 },
    { lat: 49.4747294, lng: 19.2338123 },
    { lat: 49.4746463, lng: 19.2340554 },
    { lat: 49.4745424, lng: 19.2342621 },
    { lat: 49.4742617, lng: 19.2346118 },
    { lat: 49.4742664, lng: 19.2347584 },
    { lat: 49.4741277, lng: 19.2348515 },
    { lat: 49.4738318, lng: 19.2353363 },
    { lat: 49.4733641, lng: 19.2356411 },
    { lat: 49.4733441, lng: 19.2357651 },
    { lat: 49.4731588, lng: 19.2360153 },
    { lat: 49.473153, lng: 19.2361312 },
    { lat: 49.4730616, lng: 19.2363625 },
    { lat: 49.4730139, lng: 19.2365219 },
    { lat: 49.4729715, lng: 19.2367676 },
    { lat: 49.4728907, lng: 19.2371974 },
    { lat: 49.4726985, lng: 19.2373759 },
    { lat: 49.4725975, lng: 19.2377623 },
    { lat: 49.4726017, lng: 19.2380391 },
    { lat: 49.4724424, lng: 19.2382204 },
    { lat: 49.4724074, lng: 19.2384066 },
    { lat: 49.4722209, lng: 19.238487 },
    { lat: 49.4721015, lng: 19.238583 },
    { lat: 49.4719432, lng: 19.2386203 },
    { lat: 49.471794, lng: 19.2390625 },
    { lat: 49.47157, lng: 19.2392533 },
    { lat: 49.4713174, lng: 19.2394882 },
    { lat: 49.4711999, lng: 19.2395307 },
    { lat: 49.4710617, lng: 19.2394893 },
    { lat: 49.4709424, lng: 19.2396821 },
    { lat: 49.4707482, lng: 19.2396554 },
    { lat: 49.470567, lng: 19.2397025 },
    { lat: 49.4703816, lng: 19.2397614 },
    { lat: 49.4702227, lng: 19.2400934 },
    { lat: 49.4701499, lng: 19.2401034 },
    { lat: 49.4700613, lng: 19.2402996 },
    { lat: 49.470038, lng: 19.2404989 },
    { lat: 49.4699498, lng: 19.2407295 },
    { lat: 49.4700053, lng: 19.2408545 },
    { lat: 49.4699199, lng: 19.2412815 },
    { lat: 49.4698348, lng: 19.2414592 },
    { lat: 49.469638, lng: 19.2414914 },
    { lat: 49.4693664, lng: 19.2418233 },
    { lat: 49.4692113, lng: 19.2419284 },
    { lat: 49.4691098, lng: 19.2420431 },
    { lat: 49.4689406, lng: 19.2424918 },
    { lat: 49.4689751, lng: 19.2426706 },
    { lat: 49.4687631, lng: 19.242785 },
    { lat: 49.4687244, lng: 19.2428495 },
    { lat: 49.4686308, lng: 19.2429614 },
    { lat: 49.4684458, lng: 19.2433865 },
    { lat: 49.4682349, lng: 19.2435932 },
    { lat: 49.4681777, lng: 19.2437663 },
    { lat: 49.4681079, lng: 19.2442651 },
    { lat: 49.4681269, lng: 19.2444253 },
    { lat: 49.4680831, lng: 19.2445722 },
    { lat: 49.4679158, lng: 19.2446334 },
    { lat: 49.4677798, lng: 19.2448066 },
    { lat: 49.4676813, lng: 19.2448227 },
    { lat: 49.4674488, lng: 19.245192 },
    { lat: 49.4675142, lng: 19.2456861 },
    { lat: 49.4672259, lng: 19.2458797 },
    { lat: 49.4671133, lng: 19.2465017 },
    { lat: 49.4670023, lng: 19.2466548 },
    { lat: 49.4669477, lng: 19.2469964 },
    { lat: 49.4668934, lng: 19.2470862 },
    { lat: 49.466915, lng: 19.247186 },
    { lat: 49.4668921, lng: 19.2472262 },
    { lat: 49.4668204, lng: 19.2472132 },
    { lat: 49.466782, lng: 19.2472795 },
    { lat: 49.4668341, lng: 19.2475961 },
    { lat: 49.4669031, lng: 19.2478348 },
    { lat: 49.4668814, lng: 19.2480437 },
    { lat: 49.4667464, lng: 19.248427 },
    { lat: 49.4666791, lng: 19.2483632 },
    { lat: 49.4665043, lng: 19.2485002 },
    { lat: 49.466338, lng: 19.2487721 },
    { lat: 49.4661954, lng: 19.2488768 },
    { lat: 49.4660424, lng: 19.2489077 },
    { lat: 49.465961, lng: 19.2488034 },
    { lat: 49.4658848, lng: 19.2487656 },
    { lat: 49.4657698, lng: 19.2489792 },
    { lat: 49.465804, lng: 19.2490823 },
    { lat: 49.4657975, lng: 19.2493736 },
    { lat: 49.4657695, lng: 19.2494256 },
    { lat: 49.4656301, lng: 19.2494536 },
    { lat: 49.465478, lng: 19.2493384 },
    { lat: 49.465443, lng: 19.2493814 },
    { lat: 49.4653245, lng: 19.2499248 },
    { lat: 49.4651948, lng: 19.2501813 },
    { lat: 49.4648235, lng: 19.2503816 },
    { lat: 49.4645639, lng: 19.2507288 },
    { lat: 49.464538, lng: 19.2510118 },
    { lat: 49.4644041, lng: 19.2512815 },
    { lat: 49.4638416, lng: 19.2515141 },
    { lat: 49.4637066, lng: 19.2516353 },
    { lat: 49.4635876, lng: 19.2518331 },
    { lat: 49.4632258, lng: 19.2521649 },
    { lat: 49.4629225, lng: 19.2522583 },
    { lat: 49.4629073, lng: 19.2523654 },
    { lat: 49.4627111, lng: 19.252812 },
    { lat: 49.462536, lng: 19.2529686 },
    { lat: 49.4622838, lng: 19.2538279 },
    { lat: 49.4621061, lng: 19.2540492 },
    { lat: 49.4620546, lng: 19.2542669 },
    { lat: 49.461977, lng: 19.2543637 },
    { lat: 49.4618803, lng: 19.2544151 },
    { lat: 49.4617809, lng: 19.2543175 },
    { lat: 49.4617474, lng: 19.2543223 },
    { lat: 49.4616702, lng: 19.254502 },
    { lat: 49.4617171, lng: 19.2546587 },
    { lat: 49.4616815, lng: 19.254788 },
    { lat: 49.4615133, lng: 19.254896 },
    { lat: 49.4614054, lng: 19.2549772 },
    { lat: 49.461407, lng: 19.2550093 },
    { lat: 49.4614691, lng: 19.2550853 },
    { lat: 49.4614809, lng: 19.255309 },
    { lat: 49.4614359, lng: 19.2555484 },
    { lat: 49.461346, lng: 19.255744 },
    { lat: 49.4614123, lng: 19.255976 },
    { lat: 49.4614295, lng: 19.2561487 },
    { lat: 49.4613913, lng: 19.2562896 },
    { lat: 49.4612991, lng: 19.2563416 },
    { lat: 49.4610853, lng: 19.2561115 },
    { lat: 49.4610129, lng: 19.2560345 },
    { lat: 49.4608996, lng: 19.2560247 },
    { lat: 49.4604448, lng: 19.2569085 },
    { lat: 49.4604748, lng: 19.2573957 },
    { lat: 49.4604378, lng: 19.2575922 },
    { lat: 49.4603691, lng: 19.2577365 },
    { lat: 49.4603828, lng: 19.2581443 },
    { lat: 49.4603087, lng: 19.258383 },
    { lat: 49.4601803, lng: 19.2585278 },
    { lat: 49.4601181, lng: 19.2586662 },
    { lat: 49.4601791, lng: 19.2592641 },
    { lat: 49.4601502, lng: 19.2596039 },
    { lat: 49.4601138, lng: 19.2597634 },
    { lat: 49.4600835, lng: 19.2598352 },
    { lat: 49.459693, lng: 19.2599408 },
    { lat: 49.459563, lng: 19.2599347 },
    { lat: 49.4595347, lng: 19.2599788 },
    { lat: 49.4595486, lng: 19.2601526 },
    { lat: 49.4595935, lng: 19.260219 },
    { lat: 49.459695, lng: 19.2602927 },
    { lat: 49.4597358, lng: 19.2604653 },
    { lat: 49.4597284, lng: 19.2604988 },
    { lat: 49.4596428, lng: 19.2605196 },
    { lat: 49.4596307, lng: 19.2605546 },
    { lat: 49.4596404, lng: 19.2606387 },
    { lat: 49.4599216, lng: 19.2624273 },
    { lat: 49.4599259, lng: 19.2625167 },
    { lat: 49.4606468, lng: 19.268349 },
    { lat: 49.4606728, lng: 19.2685189 },
    { lat: 49.4609793, lng: 19.2715811 },
    { lat: 49.4609422, lng: 19.2716548 },
    { lat: 49.4598579, lng: 19.2736234 },
    { lat: 49.4596487, lng: 19.274013 },
    { lat: 49.4592358, lng: 19.2757643 },
    { lat: 49.4591301, lng: 19.2761238 },
    { lat: 49.4587879, lng: 19.2776531 },
    { lat: 49.4579496, lng: 19.2782957 },
    { lat: 49.4553145, lng: 19.2792885 },
    { lat: 49.4535015, lng: 19.2821232 },
    { lat: 49.4529726, lng: 19.2828779 },
    { lat: 49.4521711, lng: 19.2834909 },
    { lat: 49.4512815, lng: 19.284004 },
    { lat: 49.4495225, lng: 19.2845088 },
    { lat: 49.4481901, lng: 19.284451 },
    { lat: 49.4473602, lng: 19.2860605 },
    { lat: 49.4460898, lng: 19.2867897 },
    { lat: 49.4460158, lng: 19.2881065 },
    { lat: 49.4456314, lng: 19.2884172 },
    { lat: 49.4449063, lng: 19.2890234 },
    { lat: 49.4442627, lng: 19.2895951 },
    { lat: 49.4441342, lng: 19.2896883 },
    { lat: 49.4435781, lng: 19.2899628 },
    { lat: 49.4429619, lng: 19.2899661 },
    { lat: 49.4421728, lng: 19.2899126 },
    { lat: 49.4418144, lng: 19.2898394 },
    { lat: 49.4416998, lng: 19.2898735 },
    { lat: 49.4412409, lng: 19.2896066 },
    { lat: 49.4400507, lng: 19.2902952 },
    { lat: 49.4392734, lng: 19.2919637 },
    { lat: 49.4392001, lng: 19.2922529 },
    { lat: 49.4371591, lng: 19.2957987 },
    { lat: 49.4365167, lng: 19.2968956 },
    { lat: 49.4372615, lng: 19.2995406 },
    { lat: 49.4373844, lng: 19.2998782 },
    { lat: 49.4375457, lng: 19.3003082 },
    { lat: 49.4384469, lng: 19.3030725 },
    { lat: 49.4391463, lng: 19.3053107 },
    { lat: 49.439437, lng: 19.3062428 },
    { lat: 49.4400768, lng: 19.3082924 },
    { lat: 49.4401261, lng: 19.308526 },
    { lat: 49.4398443, lng: 19.3084054 },
    { lat: 49.4397507, lng: 19.3083325 },
    { lat: 49.4394684, lng: 19.3086756 },
    { lat: 49.4392196, lng: 19.3089425 },
    { lat: 49.4389135, lng: 19.309193 },
    { lat: 49.4383892, lng: 19.3095719 },
    { lat: 49.4375702, lng: 19.3102659 },
    { lat: 49.4371056, lng: 19.3106697 },
    { lat: 49.4368423, lng: 19.3109147 },
    { lat: 49.435924, lng: 19.3118025 },
    { lat: 49.4356872, lng: 19.31206 },
    { lat: 49.4355404, lng: 19.3122472 },
    { lat: 49.4353063, lng: 19.3126113 },
    { lat: 49.4349234, lng: 19.3135966 },
    { lat: 49.4347814, lng: 19.3141717 },
    { lat: 49.4346842, lng: 19.314789 },
    { lat: 49.4345461, lng: 19.3153032 },
    { lat: 49.4344703, lng: 19.3154386 },
    { lat: 49.4341695, lng: 19.3157294 },
    { lat: 49.4341489, lng: 19.3157425 },
    { lat: 49.4339433, lng: 19.3158753 },
    { lat: 49.4338174, lng: 19.3159794 },
    { lat: 49.4337134, lng: 19.316093 },
    { lat: 49.4336193, lng: 19.3162016 },
    { lat: 49.4334777, lng: 19.3163027 },
    { lat: 49.4331909, lng: 19.3163149 },
    { lat: 49.4330784, lng: 19.316248 },
    { lat: 49.4328283, lng: 19.315725 },
    { lat: 49.432497, lng: 19.3156997 },
    { lat: 49.4322525, lng: 19.3161048 },
    { lat: 49.4321221, lng: 19.3168057 },
    { lat: 49.4320155, lng: 19.3172259 },
    { lat: 49.4319548, lng: 19.3173445 },
    { lat: 49.43181, lng: 19.3175053 },
    { lat: 49.4314748, lng: 19.3177788 },
    { lat: 49.43099, lng: 19.3180142 },
    { lat: 49.4308056, lng: 19.3180693 },
    { lat: 49.4305629, lng: 19.3180857 },
    { lat: 49.4302968, lng: 19.3180818 },
    { lat: 49.4299552, lng: 19.318166 },
    { lat: 49.429905, lng: 19.3181841 },
    { lat: 49.4298547, lng: 19.3182212 },
    { lat: 49.429779, lng: 19.3183834 },
    { lat: 49.4296586, lng: 19.3186245 },
    { lat: 49.4293879, lng: 19.3192655 },
    { lat: 49.4287822, lng: 19.3209705 },
    { lat: 49.4287252, lng: 19.3211309 },
    { lat: 49.4287347, lng: 19.3212377 },
    { lat: 49.4290719, lng: 19.3215071 },
    { lat: 49.4292938, lng: 19.3217924 },
    { lat: 49.4296091, lng: 19.3221967 },
    { lat: 49.4296499, lng: 19.3222498 },
    { lat: 49.4305086, lng: 19.3233529 },
    { lat: 49.4330678, lng: 19.3266375 },
    { lat: 49.4339063, lng: 19.3269041 },
    { lat: 49.4358591, lng: 19.3303335 },
    { lat: 49.4367292, lng: 19.3313933 },
    { lat: 49.4374986, lng: 19.332206 },
    { lat: 49.4388692, lng: 19.3346124 },
    { lat: 49.4403693, lng: 19.3364704 },
    { lat: 49.4407461, lng: 19.3390788 },
    { lat: 49.4408727, lng: 19.3399499 },
    { lat: 49.441289, lng: 19.339795 },
    { lat: 49.441597, lng: 19.3396803 },
    { lat: 49.4418306, lng: 19.3398588 },
    { lat: 49.4421091, lng: 19.3398604 },
    { lat: 49.4423549, lng: 19.3397442 },
    { lat: 49.4426964, lng: 19.3399112 },
    { lat: 49.4429625, lng: 19.3396291 },
    { lat: 49.4432904, lng: 19.3395557 },
    { lat: 49.4435476, lng: 19.3394415 },
    { lat: 49.4437548, lng: 19.3396758 },
    { lat: 49.4440181, lng: 19.339646 },
    { lat: 49.4441794, lng: 19.3400092 },
    { lat: 49.4444928, lng: 19.3401561 },
    { lat: 49.4447382, lng: 19.3404916 },
    { lat: 49.4447586, lng: 19.3407302 },
    { lat: 49.4446966, lng: 19.3409239 },
    { lat: 49.4451488, lng: 19.3411 },
    { lat: 49.445175, lng: 19.3413731 },
    { lat: 49.4450761, lng: 19.341589 },
    { lat: 49.4452964, lng: 19.3417711 },
    { lat: 49.4453419, lng: 19.3421185 },
    { lat: 49.445592, lng: 19.3421184 },
    { lat: 49.4458905, lng: 19.3424774 },
    { lat: 49.4458822, lng: 19.3429499 },
    { lat: 49.4460298, lng: 19.3432389 },
    { lat: 49.4461294, lng: 19.3435347 },
    { lat: 49.4462233, lng: 19.3437358 },
    { lat: 49.4462148, lng: 19.3440837 },
    { lat: 49.446313, lng: 19.3444966 },
    { lat: 49.4464666, lng: 19.3450838 },
    { lat: 49.4466433, lng: 19.3456803 },
    { lat: 49.4467552, lng: 19.3459976 },
    { lat: 49.4466668, lng: 19.3461829 },
    { lat: 49.4455229, lng: 19.3489978 },
    { lat: 49.4454957, lng: 19.349042 },
    { lat: 49.4450061, lng: 19.3502355 },
    { lat: 49.4451526, lng: 19.3505001 },
    { lat: 49.4455908, lng: 19.3512935 },
    { lat: 49.445798, lng: 19.3517444 },
    { lat: 49.4461652, lng: 19.3523222 },
    { lat: 49.4462384, lng: 19.3526858 },
    { lat: 49.4484531, lng: 19.3536103 },
    { lat: 49.4487507, lng: 19.3537351 },
    { lat: 49.4495801, lng: 19.3538779 },
    { lat: 49.4504642, lng: 19.3542081 },
    { lat: 49.4518255, lng: 19.3544007 },
    { lat: 49.4530513, lng: 19.3546608 },
    { lat: 49.4539847, lng: 19.3548422 },
    { lat: 49.4547201, lng: 19.3547827 },
    { lat: 49.4557675, lng: 19.35458 },
    { lat: 49.4566541, lng: 19.3543814 },
    { lat: 49.457604, lng: 19.354452 },
    { lat: 49.4589051, lng: 19.3543977 },
    { lat: 49.4611249, lng: 19.3545138 },
    { lat: 49.4614494, lng: 19.3547581 },
    { lat: 49.4615024, lng: 19.3547556 },
    { lat: 49.4617868, lng: 19.3548134 },
    { lat: 49.4619363, lng: 19.3550252 },
    { lat: 49.4626222, lng: 19.3548991 },
    { lat: 49.4627008, lng: 19.3548941 },
    { lat: 49.4625625, lng: 19.3541503 },
    { lat: 49.4624135, lng: 19.3539994 },
    { lat: 49.4625142, lng: 19.3533797 },
    { lat: 49.4626816, lng: 19.3532236 },
    { lat: 49.4627622, lng: 19.3530441 },
    { lat: 49.4625817, lng: 19.352676 },
    { lat: 49.4624802, lng: 19.3522691 },
    { lat: 49.4626736, lng: 19.3519707 },
    { lat: 49.4631335, lng: 19.351802 },
    { lat: 49.4634837, lng: 19.3510495 },
    { lat: 49.4637675, lng: 19.350661 },
    { lat: 49.4638108, lng: 19.3505766 },
    { lat: 49.4639738, lng: 19.3503333 },
    { lat: 49.4641178, lng: 19.3501283 },
    { lat: 49.4644363, lng: 19.3499502 },
    { lat: 49.464786, lng: 19.3495705 },
    { lat: 49.464871, lng: 19.3491274 },
    { lat: 49.4648375, lng: 19.3485518 },
    { lat: 49.4648656, lng: 19.3483843 },
    { lat: 49.4649229, lng: 19.3481617 },
    { lat: 49.4647573, lng: 19.3477039 },
    { lat: 49.4648009, lng: 19.3475766 },
    { lat: 49.4652643, lng: 19.3480187 },
    { lat: 49.4653946, lng: 19.348083 },
    { lat: 49.4656656, lng: 19.3474412 },
    { lat: 49.4660225, lng: 19.3472922 },
    { lat: 49.4662159, lng: 19.3470655 },
    { lat: 49.4662552, lng: 19.3466539 },
    { lat: 49.4665643, lng: 19.3454055 },
    { lat: 49.4670387, lng: 19.3449025 },
    { lat: 49.4675138, lng: 19.3445535 },
    { lat: 49.4675655, lng: 19.3442873 },
    { lat: 49.4674547, lng: 19.343922 },
    { lat: 49.4674584, lng: 19.3435665 },
    { lat: 49.4676949, lng: 19.342839 },
    { lat: 49.4678134, lng: 19.3426064 },
    { lat: 49.4679131, lng: 19.3423487 },
    { lat: 49.4677552, lng: 19.3418668 },
    { lat: 49.4677712, lng: 19.3413154 },
    { lat: 49.4678827, lng: 19.3411948 },
    { lat: 49.4682801, lng: 19.3412604 },
    { lat: 49.4683329, lng: 19.3411832 },
    { lat: 49.4684506, lng: 19.3412666 },
    { lat: 49.4685225, lng: 19.3414797 },
    { lat: 49.4686362, lng: 19.3417918 },
    { lat: 49.468841, lng: 19.3417827 },
    { lat: 49.4696245, lng: 19.3416731 },
    { lat: 49.4700683, lng: 19.3413318 },
    { lat: 49.4703764, lng: 19.3412196 },
    { lat: 49.470879, lng: 19.3421349 },
    { lat: 49.4715727, lng: 19.3426552 },
    { lat: 49.4720437, lng: 19.3428693 },
    { lat: 49.4724507, lng: 19.3430458 },
    { lat: 49.4731472, lng: 19.3427618 },
    { lat: 49.4736407, lng: 19.3428767 },
    { lat: 49.4740991, lng: 19.3428483 },
    { lat: 49.4744907, lng: 19.342841 },
    { lat: 49.4746319, lng: 19.3423417 },
    { lat: 49.4748235, lng: 19.3424387 },
    { lat: 49.4751887, lng: 19.3426079 },
    { lat: 49.4756221, lng: 19.342429 },
    { lat: 49.476075, lng: 19.3419261 },
    { lat: 49.4764281, lng: 19.3415687 },
    { lat: 49.4768142, lng: 19.3414884 },
    { lat: 49.4773118, lng: 19.3414292 },
    { lat: 49.4777712, lng: 19.3415953 },
    { lat: 49.4785206, lng: 19.3411336 },
    { lat: 49.4790369, lng: 19.3409502 },
    { lat: 49.4798404, lng: 19.3414092 },
    { lat: 49.4810822, lng: 19.341359 },
    { lat: 49.4811727, lng: 19.3408598 },
    { lat: 49.482921, lng: 19.3411885 },
    { lat: 49.4838348, lng: 19.3407754 },
    { lat: 49.4839028, lng: 19.3407414 },
    { lat: 49.4842686, lng: 19.3405605 },
    { lat: 49.4848101, lng: 19.3400919 },
    { lat: 49.4851411, lng: 19.3399438 },
    { lat: 49.4854759, lng: 19.339804 },
    { lat: 49.4856895, lng: 19.339721 },
    { lat: 49.4862758, lng: 19.3394378 },
    { lat: 49.4868859, lng: 19.3388475 },
    { lat: 49.4875463, lng: 19.338775 },
    { lat: 49.4875449, lng: 19.3387197 },
    { lat: 49.4888861, lng: 19.3388222 },
    { lat: 49.490112, lng: 19.3396171 },
    { lat: 49.4903685, lng: 19.3397044 },
    { lat: 49.4905039, lng: 19.3397573 },
    { lat: 49.4906512, lng: 19.338667 },
    { lat: 49.4908521, lng: 19.3371837 },
    { lat: 49.4907863, lng: 19.3365443 },
    { lat: 49.4907615, lng: 19.3363027 },
    { lat: 49.4906512, lng: 19.3352239 },
    { lat: 49.4901837, lng: 19.3337864 },
    { lat: 49.4902176, lng: 19.3316231 },
    { lat: 49.4902196, lng: 19.331475 },
    { lat: 49.4902263, lng: 19.3312103 },
    { lat: 49.4903118, lng: 19.3312056 },
    { lat: 49.4903726, lng: 19.3312025 },
    { lat: 49.4904075, lng: 19.3312008 },
    { lat: 49.4904569, lng: 19.3311979 },
    { lat: 49.4904772, lng: 19.3311971 },
    { lat: 49.4907116, lng: 19.3310291 },
    { lat: 49.4907301, lng: 19.3310159 },
    { lat: 49.4907611, lng: 19.3310519 },
    { lat: 49.4910018, lng: 19.3313313 },
    { lat: 49.4910223, lng: 19.3313359 },
    { lat: 49.4911966, lng: 19.3313765 },
    { lat: 49.4911987, lng: 19.3313773 },
    { lat: 49.4912718, lng: 19.3313943 },
    { lat: 49.4913787, lng: 19.3314191 },
    { lat: 49.4913971, lng: 19.3314232 },
    { lat: 49.4914553, lng: 19.3314668 },
    { lat: 49.4915803, lng: 19.3315595 },
    { lat: 49.4915836, lng: 19.3315581 },
    { lat: 49.4916213, lng: 19.331542 },
    { lat: 49.4920221, lng: 19.3313665 },
    { lat: 49.4924824, lng: 19.3315966 },
    { lat: 49.492652, lng: 19.3314154 },
    { lat: 49.4930131, lng: 19.3314988 },
    { lat: 49.4931019, lng: 19.3315194 },
    { lat: 49.4931217, lng: 19.3315082 },
    { lat: 49.4932523, lng: 19.3314321 },
    { lat: 49.4932872, lng: 19.331412 },
    { lat: 49.4933477, lng: 19.3313774 },
    { lat: 49.4934143, lng: 19.3313327 },
    { lat: 49.4934635, lng: 19.3312997 },
    { lat: 49.4937355, lng: 19.3311174 },
    { lat: 49.4939701, lng: 19.3310244 },
    { lat: 49.4941283, lng: 19.3309134 },
    { lat: 49.4942342, lng: 19.3306998 },
    { lat: 49.4945586, lng: 19.3302851 },
    { lat: 49.4946833, lng: 19.3300136 },
    { lat: 49.4950169, lng: 19.329847 },
    { lat: 49.4953475, lng: 19.3292333 },
    { lat: 49.4956192, lng: 19.3289938 },
    { lat: 49.4958902, lng: 19.3287405 },
    { lat: 49.4961189, lng: 19.3284513 },
    { lat: 49.4962981, lng: 19.3278515 },
    { lat: 49.4963707, lng: 19.3276477 },
    { lat: 49.496748, lng: 19.3272097 },
    { lat: 49.4967587, lng: 19.3271966 },
    { lat: 49.4967596, lng: 19.3271961 },
    { lat: 49.4968328, lng: 19.3271202 },
    { lat: 49.4968865, lng: 19.3270657 },
    { lat: 49.4971133, lng: 19.3268337 },
    { lat: 49.497569, lng: 19.3263598 },
    { lat: 49.4980409, lng: 19.3259687 },
    { lat: 49.4985447, lng: 19.3256153 },
    { lat: 49.4988797, lng: 19.3254528 },
    { lat: 49.4988984, lng: 19.3254435 },
    { lat: 49.4989412, lng: 19.3254203 },
    { lat: 49.4991049, lng: 19.3253308 },
    { lat: 49.4991203, lng: 19.3253222 },
    { lat: 49.4993082, lng: 19.3252195 },
    { lat: 49.4993521, lng: 19.3251697 },
    { lat: 49.4993714, lng: 19.3251479 },
    { lat: 49.4994163, lng: 19.3250969 },
    { lat: 49.4994216, lng: 19.3250905 },
    { lat: 49.4994946, lng: 19.3250096 },
    { lat: 49.4995244, lng: 19.3249751 },
    { lat: 49.499612, lng: 19.3248763 },
    { lat: 49.4998459, lng: 19.324597 },
    { lat: 49.5001908, lng: 19.3241923 },
    { lat: 49.5005224, lng: 19.3239112 },
    { lat: 49.5007866, lng: 19.323559 },
    { lat: 49.5009065, lng: 19.3232118 },
    { lat: 49.5011858, lng: 19.3231356 },
    { lat: 49.5015457, lng: 19.3230947 },
    { lat: 49.5018098, lng: 19.3229621 },
    { lat: 49.5022148, lng: 19.3226606 },
    { lat: 49.5026434, lng: 19.3223554 },
    { lat: 49.5031017, lng: 19.3219936 },
    { lat: 49.5033341, lng: 19.3218268 },
    { lat: 49.5035615, lng: 19.3216316 },
    { lat: 49.5038666, lng: 19.3213854 },
    { lat: 49.5042974, lng: 19.320916 },
    { lat: 49.5047215, lng: 19.3205645 },
    { lat: 49.5050451, lng: 19.3202799 },
    { lat: 49.5053341, lng: 19.319952 },
    { lat: 49.5055954, lng: 19.3197835 },
    { lat: 49.5058207, lng: 19.3194674 },
    { lat: 49.506072, lng: 19.3191825 },
    { lat: 49.5063232, lng: 19.3188457 },
    { lat: 49.5064972, lng: 19.3187837 },
    { lat: 49.5066823, lng: 19.3187182 },
    { lat: 49.5072829, lng: 19.3177811 },
    { lat: 49.5126445, lng: 19.3131317 },
    { lat: 49.5153058, lng: 19.3108238 },
    { lat: 49.5158448, lng: 19.310356 },
    { lat: 49.5162325, lng: 19.3104512 },
    { lat: 49.5176711, lng: 19.3108048 },
    { lat: 49.5177052, lng: 19.3108126 },
    { lat: 49.5192444, lng: 19.311191 },
    { lat: 49.5222859, lng: 19.3119381 },
    { lat: 49.5232276, lng: 19.3121692 },
    { lat: 49.5280439, lng: 19.3133522 },
    { lat: 49.5299839, lng: 19.3168439 },
    { lat: 49.5310326, lng: 19.3187327 },
    { lat: 49.5310826, lng: 19.3187944 },
    { lat: 49.5341922, lng: 19.3186662 },
    { lat: 49.5365851, lng: 19.3155709 },
    { lat: 49.5365858, lng: 19.3149686 },
    { lat: 49.5365934, lng: 19.31346 },
    { lat: 49.5370152, lng: 19.3103556 },
    { lat: 49.5368188, lng: 19.3084722 },
    { lat: 49.5363766, lng: 19.3067284 },
    { lat: 49.5356938, lng: 19.305217 },
    { lat: 49.5352057, lng: 19.3041827 },
    { lat: 49.5341807, lng: 19.3017818 },
    { lat: 49.5335825, lng: 19.2996847 },
    { lat: 49.533441, lng: 19.2979627 },
    { lat: 49.5336458, lng: 19.2958933 },
    { lat: 49.5333692, lng: 19.29432 },
    { lat: 49.5331991, lng: 19.2928313 },
    { lat: 49.5334797, lng: 19.2911444 },
    { lat: 49.5345324, lng: 19.2885893 },
    { lat: 49.5351937, lng: 19.285492 },
    { lat: 49.5353299, lng: 19.2840627 },
    { lat: 49.5353499, lng: 19.2825839 },
    { lat: 49.5353183, lng: 19.2815735 },
    { lat: 49.5349392, lng: 19.2807932 },
    { lat: 49.5344185, lng: 19.2798863 },
    { lat: 49.5339577, lng: 19.2787112 },
    { lat: 49.5338985, lng: 19.278616 },
    { lat: 49.5333221, lng: 19.2775207 },
    { lat: 49.5326101, lng: 19.2765498 },
    { lat: 49.5319036, lng: 19.2747666 },
    { lat: 49.5316797, lng: 19.2718041 },
    { lat: 49.5319211, lng: 19.2693077 },
    { lat: 49.532186, lng: 19.2678897 },
    { lat: 49.5324266, lng: 19.2663723 },
    { lat: 49.5323833, lng: 19.2643406 },
    { lat: 49.531802, lng: 19.2626526 },
    { lat: 49.5312291, lng: 19.2606883 },
    { lat: 49.5303861, lng: 19.2607944 },
    { lat: 49.5298376, lng: 19.2614976 },
    { lat: 49.5288051, lng: 19.2618216 },
    { lat: 49.5274916, lng: 19.2611948 },
    { lat: 49.5272866, lng: 19.2610254 },
    { lat: 49.5266071, lng: 19.2594504 },
    { lat: 49.5265228, lng: 19.2590026 },
    { lat: 49.5263054, lng: 19.2586116 },
    { lat: 49.526109, lng: 19.2576723 },
    { lat: 49.5254884, lng: 19.2565825 },
    { lat: 49.5254836, lng: 19.2565717 },
    { lat: 49.5249304, lng: 19.2552768 },
    { lat: 49.5238731, lng: 19.254174 },
    { lat: 49.5234495, lng: 19.2537342 },
    { lat: 49.522512, lng: 19.2528316 },
    { lat: 49.5220283, lng: 19.2523655 },
    { lat: 49.5218886, lng: 19.2522234 },
    { lat: 49.521047, lng: 19.2513547 },
    { lat: 49.5207199, lng: 19.2510183 },
    { lat: 49.5204656, lng: 19.2506185 },
    { lat: 49.5202567, lng: 19.2502888 },
    { lat: 49.5200811, lng: 19.2497839 },
    { lat: 49.5199711, lng: 19.2490042 },
    { lat: 49.5194726, lng: 19.247742 },
    { lat: 49.5191173, lng: 19.2470785 },
    { lat: 49.5189118, lng: 19.2460199 },
    { lat: 49.5187281, lng: 19.2450839 },
    { lat: 49.5177704, lng: 19.2434573 },
    { lat: 49.5177622, lng: 19.2434464 },
    { lat: 49.5164062, lng: 19.2420821 },
    { lat: 49.5161882, lng: 19.2418719 },
    { lat: 49.5156933, lng: 19.2413972 },
    { lat: 49.5148594, lng: 19.2406208 },
    { lat: 49.5145455, lng: 19.2399818 },
    { lat: 49.5144646, lng: 19.2398149 },
    { lat: 49.5143547, lng: 19.2395903 },
    { lat: 49.5143477, lng: 19.2395771 },
    { lat: 49.514056, lng: 19.2390485 },
    { lat: 49.5138101, lng: 19.2386068 },
    { lat: 49.5136732, lng: 19.2383748 },
    { lat: 49.5115431, lng: 19.2347981 },
    { lat: 49.5108613, lng: 19.2336509 },
    { lat: 49.5104924, lng: 19.2332716 },
    { lat: 49.5102175, lng: 19.2332631 },
    { lat: 49.5096985, lng: 19.2335617 },
    { lat: 49.5087036, lng: 19.2341319 },
    { lat: 49.5084462, lng: 19.23428 },
    { lat: 49.5082135, lng: 19.2344842 },
    { lat: 49.5077096, lng: 19.2348221 },
    { lat: 49.5071357, lng: 19.2347868 },
    { lat: 49.5063767, lng: 19.2347117 },
    { lat: 49.5056081, lng: 19.2339678 },
    { lat: 49.5050901, lng: 19.2337619 },
    { lat: 49.5037183, lng: 19.2340428 },
    { lat: 49.5030102, lng: 19.2360206 },
    { lat: 49.502429, lng: 19.2368359 },
    { lat: 49.5016992, lng: 19.2370299 },
    { lat: 49.50057, lng: 19.236602 },
    { lat: 49.5005396, lng: 19.2365925 },
    { lat: 49.4998582, lng: 19.236308 },
    { lat: 49.4997409, lng: 19.2362697 },
    { lat: 49.499624, lng: 19.23623 },
    { lat: 49.4986625, lng: 19.235916 },
    { lat: 49.4976234, lng: 19.2360427 },
    { lat: 49.496432, lng: 19.235719 },
    { lat: 49.4956578, lng: 19.2355148 },
    { lat: 49.4950343, lng: 19.2348994 },
    { lat: 49.49466, lng: 19.2337544 },
    { lat: 49.4941283, lng: 19.2326834 },
    { lat: 49.493678, lng: 19.2314207 },
    { lat: 49.493661, lng: 19.231377 },
    { lat: 49.4931725, lng: 19.2300463 },
    { lat: 49.492552, lng: 19.228936 },
    { lat: 49.4919351, lng: 19.2278291 },
    { lat: 49.4914186, lng: 19.2283722 },
    { lat: 49.4903538, lng: 19.2289165 },
    { lat: 49.4895184, lng: 19.2296666 },
    { lat: 49.4886558, lng: 19.2306681 },
    { lat: 49.4878567, lng: 19.231546 },
    { lat: 49.4871795, lng: 19.2316726 },
    { lat: 49.4868292, lng: 19.2315404 },
    { lat: 49.4866304, lng: 19.2313575 },
    { lat: 49.486376, lng: 19.230985 },
    { lat: 49.485996, lng: 19.230422 },
    { lat: 49.485476, lng: 19.229643 },
    { lat: 49.484922, lng: 19.228832 },
    { lat: 49.4849157, lng: 19.2288175 },
    { lat: 49.484643, lng: 19.228293 },
    { lat: 49.4843397, lng: 19.2277084 },
    { lat: 49.4843337, lng: 19.2276977 },
  ],
  Novoť: [
    { lat: 49.4843337, lng: 19.2276977 },
    { lat: 49.484129, lng: 19.2272507 },
    { lat: 49.4832937, lng: 19.2268109 },
    { lat: 49.4829842, lng: 19.2265697 },
    { lat: 49.4825671, lng: 19.2265335 },
    { lat: 49.4821748, lng: 19.226227 },
    { lat: 49.4821623, lng: 19.2262153 },
    { lat: 49.481594, lng: 19.225875 },
    { lat: 49.481181, lng: 19.225621 },
    { lat: 49.4811243, lng: 19.2255899 },
    { lat: 49.4805822, lng: 19.2252804 },
    { lat: 49.4798228, lng: 19.2253202 },
    { lat: 49.4793516, lng: 19.2254614 },
    { lat: 49.4793215, lng: 19.2254468 },
    { lat: 49.478936, lng: 19.225259 },
    { lat: 49.478551, lng: 19.225072 },
    { lat: 49.478029, lng: 19.224151 },
    { lat: 49.477488, lng: 19.2232787 },
    { lat: 49.477322, lng: 19.223011 },
    { lat: 49.476877, lng: 19.222695 },
    { lat: 49.476077, lng: 19.221793 },
    { lat: 49.475736, lng: 19.221455 },
    { lat: 49.475299, lng: 19.22112 },
    { lat: 49.474952, lng: 19.220926 },
    { lat: 49.474492, lng: 19.220794 },
    { lat: 49.473967, lng: 19.220601 },
    { lat: 49.473491, lng: 19.220465 },
    { lat: 49.472846, lng: 19.22038 },
    { lat: 49.472439, lng: 19.220387 },
    { lat: 49.472228, lng: 19.22032 },
    { lat: 49.470965, lng: 19.220239 },
    { lat: 49.469937, lng: 19.22008 },
    { lat: 49.469307, lng: 19.219982 },
    { lat: 49.469012, lng: 19.220292 },
    { lat: 49.468676, lng: 19.220651 },
    { lat: 49.468159, lng: 19.22205 },
    { lat: 49.467985, lng: 19.222985 },
    { lat: 49.467695, lng: 19.224441 },
    { lat: 49.467298, lng: 19.225895 },
    { lat: 49.466946, lng: 19.227127 },
    { lat: 49.466421, lng: 19.227283 },
    { lat: 49.465768, lng: 19.227477 },
    { lat: 49.46532, lng: 19.227611 },
    { lat: 49.46469, lng: 19.22783 },
    { lat: 49.463736, lng: 19.228263 },
    { lat: 49.462485, lng: 19.22842 },
    { lat: 49.461664, lng: 19.228056 },
    { lat: 49.461004, lng: 19.227382 },
    { lat: 49.460393, lng: 19.226771 },
    { lat: 49.459199, lng: 19.226617 },
    { lat: 49.458443, lng: 19.226644 },
    { lat: 49.4574406, lng: 19.2266638 },
    { lat: 49.457422, lng: 19.226664 },
    { lat: 49.457302, lng: 19.226626 },
    { lat: 49.456833, lng: 19.226368 },
    { lat: 49.455801, lng: 19.225801 },
    { lat: 49.454314, lng: 19.225311 },
    { lat: 49.453572, lng: 19.224494 },
    { lat: 49.453414, lng: 19.224206 },
    { lat: 49.452926, lng: 19.223313 },
    { lat: 49.45215, lng: 19.222348 },
    { lat: 49.451673, lng: 19.221014 },
    { lat: 49.451426, lng: 19.220373 },
    { lat: 49.45089, lng: 19.219596 },
    { lat: 49.449897, lng: 19.219587 },
    { lat: 49.448612, lng: 19.219007 },
    { lat: 49.44877, lng: 19.218023 },
    { lat: 49.448862, lng: 19.217229 },
    { lat: 49.449138, lng: 19.216437 },
    { lat: 49.449614, lng: 19.215632 },
    { lat: 49.449765, lng: 19.214928 },
    { lat: 49.449909, lng: 19.214177 },
    { lat: 49.450092, lng: 19.21371 },
    { lat: 49.450179, lng: 19.213172 },
    { lat: 49.450535, lng: 19.212444 },
    { lat: 49.450744, lng: 19.211967 },
    { lat: 49.450833, lng: 19.211238 },
    { lat: 49.451316, lng: 19.210138 },
    { lat: 49.451828, lng: 19.208989 },
    { lat: 49.451781, lng: 19.207851 },
    { lat: 49.451735, lng: 19.207516 },
    { lat: 49.451486, lng: 19.207113 },
    { lat: 49.450772, lng: 19.205974 },
    { lat: 49.450413, lng: 19.205385 },
    { lat: 49.449968, lng: 19.204639 },
    { lat: 49.449644, lng: 19.203259 },
    { lat: 49.449454, lng: 19.202862 },
    { lat: 49.449151, lng: 19.202388 },
    { lat: 49.448922, lng: 19.201748 },
    { lat: 49.448822, lng: 19.200926 },
    { lat: 49.448659, lng: 19.200156 },
    { lat: 49.448189, lng: 19.199292 },
    { lat: 49.448161, lng: 19.198581 },
    { lat: 49.44785, lng: 19.198138 },
    { lat: 49.447117, lng: 19.197141 },
    { lat: 49.446855, lng: 19.196515 },
    { lat: 49.44658, lng: 19.196354 },
    { lat: 49.446402, lng: 19.1963 },
    { lat: 49.445967, lng: 19.19618 },
    { lat: 49.444786, lng: 19.195183 },
    { lat: 49.44386, lng: 19.194876 },
    { lat: 49.4435506, lng: 19.1947087 },
    { lat: 49.442974, lng: 19.194397 },
    { lat: 49.441987, lng: 19.192948 },
    { lat: 49.441057, lng: 19.192049 },
    { lat: 49.440348, lng: 19.191193 },
    { lat: 49.439574, lng: 19.190552 },
    { lat: 49.438953, lng: 19.19026 },
    { lat: 49.438145, lng: 19.189683 },
    { lat: 49.437828, lng: 19.189313 },
    { lat: 49.437485, lng: 19.188911 },
    { lat: 49.436583, lng: 19.188558 },
    { lat: 49.435992, lng: 19.188031 },
    { lat: 49.435568, lng: 19.187312 },
    { lat: 49.4347198, lng: 19.1857359 },
    { lat: 49.433747, lng: 19.185456 },
    { lat: 49.433365, lng: 19.185881 },
    { lat: 49.432941, lng: 19.186016 },
    { lat: 49.431951, lng: 19.186326 },
    { lat: 49.431572, lng: 19.187095 },
    { lat: 49.431425, lng: 19.187387 },
    { lat: 49.431222, lng: 19.187658 },
    { lat: 49.430672, lng: 19.18837 },
    { lat: 49.430337, lng: 19.188764 },
    { lat: 49.430015, lng: 19.189389 },
    { lat: 49.429383, lng: 19.190172 },
    { lat: 49.428611, lng: 19.191198 },
    { lat: 49.427918, lng: 19.191637 },
    { lat: 49.427459, lng: 19.192226 },
    { lat: 49.42726, lng: 19.192469 },
    { lat: 49.4272628, lng: 19.1929571 },
    { lat: 49.4272422, lng: 19.1931373 },
    { lat: 49.4271816, lng: 19.193517 },
    { lat: 49.4271655, lng: 19.1936492 },
    { lat: 49.4270727, lng: 19.1943896 },
    { lat: 49.4270023, lng: 19.1947086 },
    { lat: 49.4269598, lng: 19.1949248 },
    { lat: 49.4269144, lng: 19.1952239 },
    { lat: 49.4268707, lng: 19.1955135 },
    { lat: 49.4267718, lng: 19.1960094 },
    { lat: 49.4266399, lng: 19.1965906 },
    { lat: 49.4265596, lng: 19.1969397 },
    { lat: 49.4265448, lng: 19.1973345 },
    { lat: 49.4265339, lng: 19.1976236 },
    { lat: 49.4265101, lng: 19.1982295 },
    { lat: 49.4264938, lng: 19.1986415 },
    { lat: 49.4264433, lng: 19.1999586 },
    { lat: 49.4261067, lng: 19.2008326 },
    { lat: 49.4259752, lng: 19.2011718 },
    { lat: 49.4259021, lng: 19.2013618 },
    { lat: 49.4258773, lng: 19.2014287 },
    { lat: 49.4256903, lng: 19.2016222 },
    { lat: 49.4253898, lng: 19.2019338 },
    { lat: 49.4249136, lng: 19.2024839 },
    { lat: 49.4245663, lng: 19.2027411 },
    { lat: 49.424562, lng: 19.2027441 },
    { lat: 49.4245271, lng: 19.2028147 },
    { lat: 49.4245089, lng: 19.2028327 },
    { lat: 49.4244541, lng: 19.2028182 },
    { lat: 49.4244378, lng: 19.2027599 },
    { lat: 49.4244236, lng: 19.2027485 },
    { lat: 49.4244162, lng: 19.2027584 },
    { lat: 49.4243967, lng: 19.2028175 },
    { lat: 49.4243855, lng: 19.2028462 },
    { lat: 49.4243544, lng: 19.2029022 },
    { lat: 49.4243358, lng: 19.2030314 },
    { lat: 49.4242524, lng: 19.2030542 },
    { lat: 49.4242449, lng: 19.203084 },
    { lat: 49.4242402, lng: 19.2031736 },
    { lat: 49.4241859, lng: 19.203263 },
    { lat: 49.424137, lng: 19.2032323 },
    { lat: 49.4240762, lng: 19.2032555 },
    { lat: 49.4240251, lng: 19.2033673 },
    { lat: 49.4240202, lng: 19.2034519 },
    { lat: 49.4240171, lng: 19.2035286 },
    { lat: 49.424028, lng: 19.2035441 },
    { lat: 49.4240704, lng: 19.2035378 },
    { lat: 49.4240855, lng: 19.2035669 },
    { lat: 49.4240853, lng: 19.2036129 },
    { lat: 49.424077, lng: 19.2036261 },
    { lat: 49.4240455, lng: 19.2036482 },
    { lat: 49.4240364, lng: 19.2036959 },
    { lat: 49.423989, lng: 19.2037411 },
    { lat: 49.4239641, lng: 19.2037381 },
    { lat: 49.423941, lng: 19.2037052 },
    { lat: 49.42392, lng: 19.2036916 },
    { lat: 49.4238975, lng: 19.2036898 },
    { lat: 49.4238862, lng: 19.2037154 },
    { lat: 49.4239105, lng: 19.2037973 },
    { lat: 49.4238892, lng: 19.2038215 },
    { lat: 49.4238106, lng: 19.2037826 },
    { lat: 49.4237895, lng: 19.2037422 },
    { lat: 49.4237175, lng: 19.2037427 },
    { lat: 49.4236497, lng: 19.2037866 },
    { lat: 49.4236117, lng: 19.2037474 },
    { lat: 49.4235816, lng: 19.2037559 },
    { lat: 49.4235698, lng: 19.2037659 },
    { lat: 49.4235526, lng: 19.2038044 },
    { lat: 49.4234661, lng: 19.2039072 },
    { lat: 49.4234289, lng: 19.2039795 },
    { lat: 49.4233871, lng: 19.2041156 },
    { lat: 49.4233704, lng: 19.2042102 },
    { lat: 49.4233349, lng: 19.204224 },
    { lat: 49.4232799, lng: 19.204181 },
    { lat: 49.4232085, lng: 19.2041984 },
    { lat: 49.4230848, lng: 19.2041548 },
    { lat: 49.4230319, lng: 19.2042296 },
    { lat: 49.4230655, lng: 19.2043181 },
    { lat: 49.4230265, lng: 19.2043728 },
    { lat: 49.4230314, lng: 19.2044744 },
    { lat: 49.4229845, lng: 19.2045304 },
    { lat: 49.4229427, lng: 19.20453 },
    { lat: 49.4229283, lng: 19.2045099 },
    { lat: 49.4229306, lng: 19.2044217 },
    { lat: 49.4229062, lng: 19.2043792 },
    { lat: 49.4228028, lng: 19.2045042 },
    { lat: 49.4227912, lng: 19.2044993 },
    { lat: 49.4227616, lng: 19.2044672 },
    { lat: 49.4227331, lng: 19.2044827 },
    { lat: 49.422745, lng: 19.2045444 },
    { lat: 49.4227336, lng: 19.2046112 },
    { lat: 49.4227488, lng: 19.204692 },
    { lat: 49.4227253, lng: 19.2047196 },
    { lat: 49.4226771, lng: 19.2047233 },
    { lat: 49.4225871, lng: 19.2047519 },
    { lat: 49.4224947, lng: 19.2047514 },
    { lat: 49.422432, lng: 19.2046648 },
    { lat: 49.4223585, lng: 19.2046589 },
    { lat: 49.4223244, lng: 19.2047258 },
    { lat: 49.4222435, lng: 19.2048272 },
    { lat: 49.4222211, lng: 19.2048518 },
    { lat: 49.422131, lng: 19.2048301 },
    { lat: 49.4221039, lng: 19.204827 },
    { lat: 49.422086, lng: 19.2047824 },
    { lat: 49.4220637, lng: 19.2047918 },
    { lat: 49.4220333, lng: 19.2048368 },
    { lat: 49.4220419, lng: 19.2048981 },
    { lat: 49.422037, lng: 19.204983 },
    { lat: 49.4220789, lng: 19.2050576 },
    { lat: 49.422103, lng: 19.2051381 },
    { lat: 49.422095, lng: 19.2051575 },
    { lat: 49.4220904, lng: 19.2051551 },
    { lat: 49.4220634, lng: 19.2052067 },
    { lat: 49.4220358, lng: 19.2052431 },
    { lat: 49.4220216, lng: 19.2052262 },
    { lat: 49.4220157, lng: 19.2051972 },
    { lat: 49.4220003, lng: 19.2051334 },
    { lat: 49.4219485, lng: 19.2051603 },
    { lat: 49.4218939, lng: 19.2051943 },
    { lat: 49.4219251, lng: 19.2054004 },
    { lat: 49.421898, lng: 19.2054223 },
    { lat: 49.4217365, lng: 19.2053934 },
    { lat: 49.4217101, lng: 19.205404 },
    { lat: 49.4217001, lng: 19.2054077 },
    { lat: 49.4216996, lng: 19.2054222 },
    { lat: 49.4216866, lng: 19.2054566 },
    { lat: 49.4216802, lng: 19.2054653 },
    { lat: 49.4216715, lng: 19.2054464 },
    { lat: 49.4216376, lng: 19.2053503 },
    { lat: 49.4216474, lng: 19.20525 },
    { lat: 49.4215961, lng: 19.2052373 },
    { lat: 49.4215504, lng: 19.2052711 },
    { lat: 49.4215679, lng: 19.2054223 },
    { lat: 49.4215424, lng: 19.2055015 },
    { lat: 49.4214644, lng: 19.2055458 },
    { lat: 49.4214118, lng: 19.2054131 },
    { lat: 49.421352, lng: 19.2054133 },
    { lat: 49.421304, lng: 19.20554 },
    { lat: 49.4213126, lng: 19.2056252 },
    { lat: 49.4212053, lng: 19.2056666 },
    { lat: 49.4211987, lng: 19.2057405 },
    { lat: 49.4209995, lng: 19.2058622 },
    { lat: 49.4209401, lng: 19.2058012 },
    { lat: 49.4209223, lng: 19.205591 },
    { lat: 49.4209015, lng: 19.2055127 },
    { lat: 49.4207909, lng: 19.2055067 },
    { lat: 49.4207248, lng: 19.2055416 },
    { lat: 49.4207077, lng: 19.2056779 },
    { lat: 49.4205865, lng: 19.2056847 },
    { lat: 49.4204887, lng: 19.2056461 },
    { lat: 49.4204508, lng: 19.2058198 },
    { lat: 49.4203817, lng: 19.2059746 },
    { lat: 49.4202935, lng: 19.2061105 },
    { lat: 49.4202661, lng: 19.2062186 },
    { lat: 49.4201552, lng: 19.2062354 },
    { lat: 49.4200614, lng: 19.2063221 },
    { lat: 49.4199961, lng: 19.2063689 },
    { lat: 49.4199546, lng: 19.2064007 },
    { lat: 49.419934, lng: 19.2064139 },
    { lat: 49.4199348, lng: 19.2065492 },
    { lat: 49.4199289, lng: 19.2066628 },
    { lat: 49.4199061, lng: 19.206703 },
    { lat: 49.4198265, lng: 19.2066878 },
    { lat: 49.4197876, lng: 19.2067001 },
    { lat: 49.4197303, lng: 19.2068199 },
    { lat: 49.4196531, lng: 19.206973 },
    { lat: 49.4195565, lng: 19.2071208 },
    { lat: 49.4194846, lng: 19.2071969 },
    { lat: 49.4194463, lng: 19.2073823 },
    { lat: 49.41939, lng: 19.2075276 },
    { lat: 49.4192903, lng: 19.2077492 },
    { lat: 49.4191779, lng: 19.2078726 },
    { lat: 49.4191095, lng: 19.2080451 },
    { lat: 49.4191812, lng: 19.2084576 },
    { lat: 49.4191739, lng: 19.2086593 },
    { lat: 49.4189269, lng: 19.2090289 },
    { lat: 49.4186727, lng: 19.2095083 },
    { lat: 49.4185091, lng: 19.2098109 },
    { lat: 49.4182939, lng: 19.2099325 },
    { lat: 49.4181448, lng: 19.2101329 },
    { lat: 49.418058, lng: 19.2103222 },
    { lat: 49.4179514, lng: 19.2104272 },
    { lat: 49.4175825, lng: 19.2108406 },
    { lat: 49.4174206, lng: 19.2108983 },
    { lat: 49.417382, lng: 19.211219 },
    { lat: 49.4172899, lng: 19.211506 },
    { lat: 49.4172987, lng: 19.2117131 },
    { lat: 49.4173176, lng: 19.2118518 },
    { lat: 49.4172443, lng: 19.2118937 },
    { lat: 49.4172087, lng: 19.2119303 },
    { lat: 49.4171218, lng: 19.2120183 },
    { lat: 49.4170795, lng: 19.2119398 },
    { lat: 49.4170554, lng: 19.211953 },
    { lat: 49.4169567, lng: 19.2121716 },
    { lat: 49.4168315, lng: 19.2122881 },
    { lat: 49.4167266, lng: 19.2126167 },
    { lat: 49.4167023, lng: 19.2130263 },
    { lat: 49.4165438, lng: 19.2133399 },
    { lat: 49.4164097, lng: 19.2134542 },
    { lat: 49.4163778, lng: 19.2134733 },
    { lat: 49.4161725, lng: 19.2135616 },
    { lat: 49.4160532, lng: 19.2137513 },
    { lat: 49.4160268, lng: 19.2137919 },
    { lat: 49.4160291, lng: 19.2143574 },
    { lat: 49.4159525, lng: 19.2147096 },
    { lat: 49.415929, lng: 19.2152052 },
    { lat: 49.4157869, lng: 19.2153458 },
    { lat: 49.4157592, lng: 19.215708 },
    { lat: 49.4157443, lng: 19.2160763 },
    { lat: 49.4155889, lng: 19.2165043 },
    { lat: 49.415604, lng: 19.2170767 },
    { lat: 49.4153958, lng: 19.2175323 },
    { lat: 49.4153884, lng: 19.2179194 },
    { lat: 49.4152451, lng: 19.2181718 },
    { lat: 49.4152072, lng: 19.218584 },
    { lat: 49.415045, lng: 19.2188199 },
    { lat: 49.4149385, lng: 19.2192557 },
    { lat: 49.4148756, lng: 19.2195632 },
    { lat: 49.4147361, lng: 19.220132 },
    { lat: 49.4146511, lng: 19.2204282 },
    { lat: 49.4145603, lng: 19.2209083 },
    { lat: 49.4143575, lng: 19.2212894 },
    { lat: 49.4143343, lng: 19.2218404 },
    { lat: 49.4142606, lng: 19.2222295 },
    { lat: 49.4140616, lng: 19.2228038 },
    { lat: 49.4139526, lng: 19.2233051 },
    { lat: 49.4139485, lng: 19.2234547 },
    { lat: 49.4140518, lng: 19.223704 },
    { lat: 49.4140607, lng: 19.223871 },
    { lat: 49.4139716, lng: 19.2240316 },
    { lat: 49.413658, lng: 19.224258 },
    { lat: 49.4133809, lng: 19.2247365 },
    { lat: 49.4132609, lng: 19.225075 },
    { lat: 49.413177, lng: 19.2253013 },
    { lat: 49.4131432, lng: 19.2255132 },
    { lat: 49.4130692, lng: 19.2257086 },
    { lat: 49.412817, lng: 19.2259755 },
    { lat: 49.4126216, lng: 19.2262483 },
    { lat: 49.4124217, lng: 19.2266374 },
    { lat: 49.4122675, lng: 19.227021 },
    { lat: 49.4122266, lng: 19.2272236 },
    { lat: 49.4122886, lng: 19.2274631 },
    { lat: 49.4123412, lng: 19.2276889 },
    { lat: 49.4122201, lng: 19.2278914 },
    { lat: 49.4121362, lng: 19.2281122 },
    { lat: 49.4121725, lng: 19.228334 },
    { lat: 49.4121522, lng: 19.2285451 },
    { lat: 49.4120922, lng: 19.2289594 },
    { lat: 49.412124, lng: 19.2292501 },
    { lat: 49.4122106, lng: 19.2295549 },
    { lat: 49.4123224, lng: 19.2295825 },
    { lat: 49.4124402, lng: 19.2295714 },
    { lat: 49.4125358, lng: 19.2296139 },
    { lat: 49.412612, lng: 19.229791 },
    { lat: 49.4126773, lng: 19.230168 },
    { lat: 49.4127245, lng: 19.2305189 },
    { lat: 49.4126196, lng: 19.2307981 },
    { lat: 49.4125751, lng: 19.231236 },
    { lat: 49.412665, lng: 19.2315104 },
    { lat: 49.4127435, lng: 19.2317893 },
    { lat: 49.4127451, lng: 19.2321048 },
    { lat: 49.4129384, lng: 19.2324229 },
    { lat: 49.4130073, lng: 19.2328271 },
    { lat: 49.4129449, lng: 19.233103 },
    { lat: 49.412926, lng: 19.2334399 },
    { lat: 49.413054, lng: 19.2337101 },
    { lat: 49.4131943, lng: 19.2340269 },
    { lat: 49.4132389, lng: 19.2342796 },
    { lat: 49.4133215, lng: 19.2346146 },
    { lat: 49.4134041, lng: 19.2349292 },
    { lat: 49.4134356, lng: 19.2352124 },
    { lat: 49.413553, lng: 19.2354254 },
    { lat: 49.4136192, lng: 19.2356804 },
    { lat: 49.4134786, lng: 19.2358504 },
    { lat: 49.4134439, lng: 19.2360446 },
    { lat: 49.4134526, lng: 19.2364147 },
    { lat: 49.4134528, lng: 19.2366807 },
    { lat: 49.413535, lng: 19.2372187 },
    { lat: 49.4135458, lng: 19.2378958 },
    { lat: 49.4135647, lng: 19.238336 },
    { lat: 49.413546, lng: 19.2385625 },
    { lat: 49.4134314, lng: 19.2387346 },
    { lat: 49.413412, lng: 19.238892 },
    { lat: 49.4134122, lng: 19.2390636 },
    { lat: 49.4134629, lng: 19.2392255 },
    { lat: 49.4134225, lng: 19.2394443 },
    { lat: 49.4134547, lng: 19.2397172 },
    { lat: 49.4134736, lng: 19.2398501 },
    { lat: 49.4133797, lng: 19.2400112 },
    { lat: 49.4133201, lng: 19.2402021 },
    { lat: 49.4133111, lng: 19.2403699 },
    { lat: 49.4132277, lng: 19.2405071 },
    { lat: 49.4131672, lng: 19.2406105 },
    { lat: 49.4131775, lng: 19.2408019 },
    { lat: 49.4130668, lng: 19.2411531 },
    { lat: 49.4129953, lng: 19.2413772 },
    { lat: 49.4129631, lng: 19.2415299 },
    { lat: 49.4127949, lng: 19.2416414 },
    { lat: 49.4126765, lng: 19.2418288 },
    { lat: 49.4125727, lng: 19.2422997 },
    { lat: 49.4125165, lng: 19.242588 },
    { lat: 49.4124796, lng: 19.2428049 },
    { lat: 49.4124672, lng: 19.2430144 },
    { lat: 49.4124062, lng: 19.2432761 },
    { lat: 49.4123727, lng: 19.2435129 },
    { lat: 49.4123563, lng: 19.2437399 },
    { lat: 49.4123225, lng: 19.2440947 },
    { lat: 49.4122917, lng: 19.2444142 },
    { lat: 49.4122953, lng: 19.2447771 },
    { lat: 49.4122742, lng: 19.2449735 },
    { lat: 49.4123042, lng: 19.2451956 },
    { lat: 49.4123042, lng: 19.2454095 },
    { lat: 49.4122599, lng: 19.2456642 },
    { lat: 49.4122298, lng: 19.2458594 },
    { lat: 49.4122082, lng: 19.2460895 },
    { lat: 49.4121693, lng: 19.2462694 },
    { lat: 49.4121514, lng: 19.2464582 },
    { lat: 49.4121129, lng: 19.2466463 },
    { lat: 49.4120779, lng: 19.2468348 },
    { lat: 49.4120115, lng: 19.2469769 },
    { lat: 49.4119452, lng: 19.2472153 },
    { lat: 49.4118393, lng: 19.2473827 },
    { lat: 49.4117508, lng: 19.2476062 },
    { lat: 49.4116839, lng: 19.2477874 },
    { lat: 49.4116039, lng: 19.2480718 },
    { lat: 49.4115061, lng: 19.2482891 },
    { lat: 49.411388, lng: 19.2484116 },
    { lat: 49.4112834, lng: 19.2485619 },
    { lat: 49.4110276, lng: 19.2487491 },
    { lat: 49.4108838, lng: 19.2488777 },
    { lat: 49.4106481, lng: 19.2490156 },
    { lat: 49.4105023, lng: 19.249029 },
    { lat: 49.4103523, lng: 19.2490976 },
    { lat: 49.4101555, lng: 19.2490863 },
    { lat: 49.4100068, lng: 19.2491565 },
    { lat: 49.4098526, lng: 19.2493008 },
    { lat: 49.4095862, lng: 19.2494573 },
    { lat: 49.4094154, lng: 19.2495854 },
    { lat: 49.4092247, lng: 19.2496769 },
    { lat: 49.4090646, lng: 19.2498599 },
    { lat: 49.4089271, lng: 19.2501463 },
    { lat: 49.4088433, lng: 19.2503504 },
    { lat: 49.4087678, lng: 19.2506111 },
    { lat: 49.4086973, lng: 19.2508608 },
    { lat: 49.4086216, lng: 19.2511595 },
    { lat: 49.4084697, lng: 19.2517338 },
    { lat: 49.40834, lng: 19.2522029 },
    { lat: 49.4082293, lng: 19.2526018 },
    { lat: 49.4081789, lng: 19.2529172 },
    { lat: 49.4081641, lng: 19.2530756 },
    { lat: 49.4081189, lng: 19.2535754 },
    { lat: 49.4082102, lng: 19.2539752 },
    { lat: 49.4081934, lng: 19.2541681 },
    { lat: 49.4081125, lng: 19.2542698 },
    { lat: 49.4074944, lng: 19.2548225 },
    { lat: 49.4071404, lng: 19.2552496 },
    { lat: 49.4069873, lng: 19.255346 },
    { lat: 49.4068491, lng: 19.2554494 },
    { lat: 49.4068712, lng: 19.2554868 },
    { lat: 49.4069442, lng: 19.2555969 },
    { lat: 49.406987, lng: 19.2556685 },
    { lat: 49.406993, lng: 19.2556791 },
    { lat: 49.4070354, lng: 19.2557872 },
    { lat: 49.4071008, lng: 19.2559547 },
    { lat: 49.4071595, lng: 19.2560965 },
    { lat: 49.4071734, lng: 19.2561304 },
    { lat: 49.4073764, lng: 19.2566289 },
    { lat: 49.4074785, lng: 19.2568784 },
    { lat: 49.4075183, lng: 19.2573147 },
    { lat: 49.4075195, lng: 19.2574829 },
    { lat: 49.4076158, lng: 19.2584851 },
    { lat: 49.4077068, lng: 19.259072 },
    { lat: 49.4078989, lng: 19.2598623 },
    { lat: 49.4080373, lng: 19.2604784 },
    { lat: 49.4085237, lng: 19.2617475 },
    { lat: 49.4088739, lng: 19.2628813 },
    { lat: 49.4089336, lng: 19.2630426 },
    { lat: 49.4091405, lng: 19.263625 },
    { lat: 49.4091671, lng: 19.2637378 },
    { lat: 49.409547, lng: 19.2653265 },
    { lat: 49.4099959, lng: 19.2664233 },
    { lat: 49.4100116, lng: 19.2665406 },
    { lat: 49.4101146, lng: 19.2667206 },
    { lat: 49.4102196, lng: 19.2667679 },
    { lat: 49.4105204, lng: 19.2675732 },
    { lat: 49.4105366, lng: 19.2677249 },
    { lat: 49.4107242, lng: 19.2680688 },
    { lat: 49.4107231, lng: 19.2681858 },
    { lat: 49.4108961, lng: 19.2685309 },
    { lat: 49.4109339, lng: 19.2687106 },
    { lat: 49.4109596, lng: 19.268918 },
    { lat: 49.411609, lng: 19.2710866 },
    { lat: 49.4109195, lng: 19.2718033 },
    { lat: 49.4108638, lng: 19.2720973 },
    { lat: 49.4108487, lng: 19.272163 },
    { lat: 49.4106845, lng: 19.2728588 },
    { lat: 49.410586, lng: 19.2732744 },
    { lat: 49.410502, lng: 19.2739865 },
    { lat: 49.4105245, lng: 19.2740192 },
    { lat: 49.4104914, lng: 19.2741778 },
    { lat: 49.4104617, lng: 19.274433 },
    { lat: 49.4104034, lng: 19.2747928 },
    { lat: 49.4103894, lng: 19.2748797 },
    { lat: 49.4103007, lng: 19.2754347 },
    { lat: 49.4104208, lng: 19.275637 },
    { lat: 49.4103942, lng: 19.2758379 },
    { lat: 49.4106207, lng: 19.2761427 },
    { lat: 49.410623, lng: 19.2761457 },
    { lat: 49.4110352, lng: 19.2766159 },
    { lat: 49.4111382, lng: 19.2767673 },
    { lat: 49.4114015, lng: 19.2771147 },
    { lat: 49.411476, lng: 19.2771868 },
    { lat: 49.411958, lng: 19.2778446 },
    { lat: 49.412515, lng: 19.2785915 },
    { lat: 49.413354, lng: 19.2793877 },
    { lat: 49.4133818, lng: 19.2794262 },
    { lat: 49.4134155, lng: 19.2794739 },
    { lat: 49.4139027, lng: 19.2801674 },
    { lat: 49.414327, lng: 19.2807728 },
    { lat: 49.4145951, lng: 19.2811544 },
    { lat: 49.415204, lng: 19.2814247 },
    { lat: 49.4163009, lng: 19.2819103 },
    { lat: 49.4165811, lng: 19.2820663 },
    { lat: 49.4167969, lng: 19.2822707 },
    { lat: 49.4168632, lng: 19.2823842 },
    { lat: 49.4169747, lng: 19.282573 },
    { lat: 49.4169906, lng: 19.2825995 },
    { lat: 49.4170016, lng: 19.2826193 },
    { lat: 49.4177087, lng: 19.2838415 },
    { lat: 49.418062, lng: 19.284495 },
    { lat: 49.4187236, lng: 19.2855207 },
    { lat: 49.4190572, lng: 19.2859972 },
    { lat: 49.4191496, lng: 19.2862092 },
    { lat: 49.4203871, lng: 19.2889494 },
    { lat: 49.4207052, lng: 19.2896054 },
    { lat: 49.4208407, lng: 19.2899176 },
    { lat: 49.4209179, lng: 19.2900968 },
    { lat: 49.4209384, lng: 19.290146 },
    { lat: 49.4210052, lng: 19.2903004 },
    { lat: 49.4210369, lng: 19.2903721 },
    { lat: 49.4211193, lng: 19.2905653 },
    { lat: 49.421657, lng: 19.291833 },
    { lat: 49.4216595, lng: 19.2918363 },
    { lat: 49.4217429, lng: 19.2919831 },
    { lat: 49.4219625, lng: 19.2923576 },
    { lat: 49.4220775, lng: 19.2926467 },
    { lat: 49.4227644, lng: 19.2937542 },
    { lat: 49.423402, lng: 19.2948476 },
    { lat: 49.4239494, lng: 19.2957479 },
    { lat: 49.4246694, lng: 19.2968677 },
    { lat: 49.4250115, lng: 19.2978817 },
    { lat: 49.4253257, lng: 19.3005993 },
    { lat: 49.4254429, lng: 19.3015311 },
    { lat: 49.425565, lng: 19.3024675 },
    { lat: 49.4256479, lng: 19.3029568 },
    { lat: 49.4263185, lng: 19.3038215 },
    { lat: 49.4264015, lng: 19.3043594 },
    { lat: 49.426286, lng: 19.3052359 },
    { lat: 49.4262873, lng: 19.3054038 },
    { lat: 49.4263053, lng: 19.3056657 },
    { lat: 49.4262403, lng: 19.3064404 },
    { lat: 49.4263171, lng: 19.3070165 },
    { lat: 49.4264479, lng: 19.3080678 },
    { lat: 49.4265885, lng: 19.3092361 },
    { lat: 49.4267783, lng: 19.3103924 },
    { lat: 49.4269717, lng: 19.310574 },
    { lat: 49.4271983, lng: 19.3124405 },
    { lat: 49.4269223, lng: 19.313379 },
    { lat: 49.4269927, lng: 19.3137963 },
    { lat: 49.4271138, lng: 19.3139954 },
    { lat: 49.427225, lng: 19.3141814 },
    { lat: 49.4272464, lng: 19.3143739 },
    { lat: 49.4272882, lng: 19.3144956 },
    { lat: 49.4276546, lng: 19.3150498 },
    { lat: 49.4278033, lng: 19.3151941 },
    { lat: 49.4279261, lng: 19.3152834 },
    { lat: 49.4279601, lng: 19.3155305 },
    { lat: 49.4284404, lng: 19.3163462 },
    { lat: 49.4288703, lng: 19.3168164 },
    { lat: 49.4291616, lng: 19.3173558 },
    { lat: 49.4293682, lng: 19.3174115 },
    { lat: 49.4298085, lng: 19.3179062 },
    { lat: 49.4298331, lng: 19.317952 },
    { lat: 49.4299552, lng: 19.318166 },
    { lat: 49.4302968, lng: 19.3180818 },
    { lat: 49.4305629, lng: 19.3180857 },
    { lat: 49.4308056, lng: 19.3180693 },
    { lat: 49.43099, lng: 19.3180142 },
    { lat: 49.4314748, lng: 19.3177788 },
    { lat: 49.43181, lng: 19.3175053 },
    { lat: 49.4319548, lng: 19.3173445 },
    { lat: 49.4320155, lng: 19.3172259 },
    { lat: 49.4321221, lng: 19.3168057 },
    { lat: 49.4322525, lng: 19.3161048 },
    { lat: 49.432497, lng: 19.3156997 },
    { lat: 49.4328283, lng: 19.315725 },
    { lat: 49.4330784, lng: 19.316248 },
    { lat: 49.4331909, lng: 19.3163149 },
    { lat: 49.4334777, lng: 19.3163027 },
    { lat: 49.4336193, lng: 19.3162016 },
    { lat: 49.4337134, lng: 19.316093 },
    { lat: 49.4338174, lng: 19.3159794 },
    { lat: 49.4339433, lng: 19.3158753 },
    { lat: 49.4341489, lng: 19.3157425 },
    { lat: 49.4341695, lng: 19.3157294 },
    { lat: 49.4344703, lng: 19.3154386 },
    { lat: 49.4345461, lng: 19.3153032 },
    { lat: 49.4346842, lng: 19.314789 },
    { lat: 49.4347814, lng: 19.3141717 },
    { lat: 49.4349234, lng: 19.3135966 },
    { lat: 49.4353063, lng: 19.3126113 },
    { lat: 49.4355404, lng: 19.3122472 },
    { lat: 49.4356872, lng: 19.31206 },
    { lat: 49.435924, lng: 19.3118025 },
    { lat: 49.4368423, lng: 19.3109147 },
    { lat: 49.4371056, lng: 19.3106697 },
    { lat: 49.4375702, lng: 19.3102659 },
    { lat: 49.4383892, lng: 19.3095719 },
    { lat: 49.4389135, lng: 19.309193 },
    { lat: 49.4392196, lng: 19.3089425 },
    { lat: 49.4394684, lng: 19.3086756 },
    { lat: 49.4397507, lng: 19.3083325 },
    { lat: 49.4398443, lng: 19.3084054 },
    { lat: 49.4401261, lng: 19.308526 },
    { lat: 49.4400768, lng: 19.3082924 },
    { lat: 49.439437, lng: 19.3062428 },
    { lat: 49.4391463, lng: 19.3053107 },
    { lat: 49.4384469, lng: 19.3030725 },
    { lat: 49.4375457, lng: 19.3003082 },
    { lat: 49.4373844, lng: 19.2998782 },
    { lat: 49.4372615, lng: 19.2995406 },
    { lat: 49.4365167, lng: 19.2968956 },
    { lat: 49.4371591, lng: 19.2957987 },
    { lat: 49.4392001, lng: 19.2922529 },
    { lat: 49.4392734, lng: 19.2919637 },
    { lat: 49.4400507, lng: 19.2902952 },
    { lat: 49.4412409, lng: 19.2896066 },
    { lat: 49.4416998, lng: 19.2898735 },
    { lat: 49.4418144, lng: 19.2898394 },
    { lat: 49.4421728, lng: 19.2899126 },
    { lat: 49.4429619, lng: 19.2899661 },
    { lat: 49.4435781, lng: 19.2899628 },
    { lat: 49.4441342, lng: 19.2896883 },
    { lat: 49.4442627, lng: 19.2895951 },
    { lat: 49.4449063, lng: 19.2890234 },
    { lat: 49.4456314, lng: 19.2884172 },
    { lat: 49.4460158, lng: 19.2881065 },
    { lat: 49.4460898, lng: 19.2867897 },
    { lat: 49.4473602, lng: 19.2860605 },
    { lat: 49.4481901, lng: 19.284451 },
    { lat: 49.4495225, lng: 19.2845088 },
    { lat: 49.4512815, lng: 19.284004 },
    { lat: 49.4521711, lng: 19.2834909 },
    { lat: 49.4529726, lng: 19.2828779 },
    { lat: 49.4535015, lng: 19.2821232 },
    { lat: 49.4553145, lng: 19.2792885 },
    { lat: 49.4579496, lng: 19.2782957 },
    { lat: 49.4587879, lng: 19.2776531 },
    { lat: 49.4591301, lng: 19.2761238 },
    { lat: 49.4592358, lng: 19.2757643 },
    { lat: 49.4596487, lng: 19.274013 },
    { lat: 49.4598579, lng: 19.2736234 },
    { lat: 49.4609422, lng: 19.2716548 },
    { lat: 49.4609793, lng: 19.2715811 },
    { lat: 49.4606728, lng: 19.2685189 },
    { lat: 49.4606468, lng: 19.268349 },
    { lat: 49.4599259, lng: 19.2625167 },
    { lat: 49.4599216, lng: 19.2624273 },
    { lat: 49.4596404, lng: 19.2606387 },
    { lat: 49.4596307, lng: 19.2605546 },
    { lat: 49.4596428, lng: 19.2605196 },
    { lat: 49.4597284, lng: 19.2604988 },
    { lat: 49.4597358, lng: 19.2604653 },
    { lat: 49.459695, lng: 19.2602927 },
    { lat: 49.4595935, lng: 19.260219 },
    { lat: 49.4595486, lng: 19.2601526 },
    { lat: 49.4595347, lng: 19.2599788 },
    { lat: 49.459563, lng: 19.2599347 },
    { lat: 49.459693, lng: 19.2599408 },
    { lat: 49.4600835, lng: 19.2598352 },
    { lat: 49.4601138, lng: 19.2597634 },
    { lat: 49.4601502, lng: 19.2596039 },
    { lat: 49.4601791, lng: 19.2592641 },
    { lat: 49.4601181, lng: 19.2586662 },
    { lat: 49.4601803, lng: 19.2585278 },
    { lat: 49.4603087, lng: 19.258383 },
    { lat: 49.4603828, lng: 19.2581443 },
    { lat: 49.4603691, lng: 19.2577365 },
    { lat: 49.4604378, lng: 19.2575922 },
    { lat: 49.4604748, lng: 19.2573957 },
    { lat: 49.4604448, lng: 19.2569085 },
    { lat: 49.4608996, lng: 19.2560247 },
    { lat: 49.4610129, lng: 19.2560345 },
    { lat: 49.4610853, lng: 19.2561115 },
    { lat: 49.4612991, lng: 19.2563416 },
    { lat: 49.4613913, lng: 19.2562896 },
    { lat: 49.4614295, lng: 19.2561487 },
    { lat: 49.4614123, lng: 19.255976 },
    { lat: 49.461346, lng: 19.255744 },
    { lat: 49.4614359, lng: 19.2555484 },
    { lat: 49.4614809, lng: 19.255309 },
    { lat: 49.4614691, lng: 19.2550853 },
    { lat: 49.461407, lng: 19.2550093 },
    { lat: 49.4614054, lng: 19.2549772 },
    { lat: 49.4615133, lng: 19.254896 },
    { lat: 49.4616815, lng: 19.254788 },
    { lat: 49.4617171, lng: 19.2546587 },
    { lat: 49.4616702, lng: 19.254502 },
    { lat: 49.4617474, lng: 19.2543223 },
    { lat: 49.4617809, lng: 19.2543175 },
    { lat: 49.4618803, lng: 19.2544151 },
    { lat: 49.461977, lng: 19.2543637 },
    { lat: 49.4620546, lng: 19.2542669 },
    { lat: 49.4621061, lng: 19.2540492 },
    { lat: 49.4622838, lng: 19.2538279 },
    { lat: 49.462536, lng: 19.2529686 },
    { lat: 49.4627111, lng: 19.252812 },
    { lat: 49.4629073, lng: 19.2523654 },
    { lat: 49.4629225, lng: 19.2522583 },
    { lat: 49.4632258, lng: 19.2521649 },
    { lat: 49.4635876, lng: 19.2518331 },
    { lat: 49.4637066, lng: 19.2516353 },
    { lat: 49.4638416, lng: 19.2515141 },
    { lat: 49.4644041, lng: 19.2512815 },
    { lat: 49.464538, lng: 19.2510118 },
    { lat: 49.4645639, lng: 19.2507288 },
    { lat: 49.4648235, lng: 19.2503816 },
    { lat: 49.4651948, lng: 19.2501813 },
    { lat: 49.4653245, lng: 19.2499248 },
    { lat: 49.465443, lng: 19.2493814 },
    { lat: 49.465478, lng: 19.2493384 },
    { lat: 49.4656301, lng: 19.2494536 },
    { lat: 49.4657695, lng: 19.2494256 },
    { lat: 49.4657975, lng: 19.2493736 },
    { lat: 49.465804, lng: 19.2490823 },
    { lat: 49.4657698, lng: 19.2489792 },
    { lat: 49.4658848, lng: 19.2487656 },
    { lat: 49.465961, lng: 19.2488034 },
    { lat: 49.4660424, lng: 19.2489077 },
    { lat: 49.4661954, lng: 19.2488768 },
    { lat: 49.466338, lng: 19.2487721 },
    { lat: 49.4665043, lng: 19.2485002 },
    { lat: 49.4666791, lng: 19.2483632 },
    { lat: 49.4667464, lng: 19.248427 },
    { lat: 49.4668814, lng: 19.2480437 },
    { lat: 49.4669031, lng: 19.2478348 },
    { lat: 49.4668341, lng: 19.2475961 },
    { lat: 49.466782, lng: 19.2472795 },
    { lat: 49.4668204, lng: 19.2472132 },
    { lat: 49.4668921, lng: 19.2472262 },
    { lat: 49.466915, lng: 19.247186 },
    { lat: 49.4668934, lng: 19.2470862 },
    { lat: 49.4669477, lng: 19.2469964 },
    { lat: 49.4670023, lng: 19.2466548 },
    { lat: 49.4671133, lng: 19.2465017 },
    { lat: 49.4672259, lng: 19.2458797 },
    { lat: 49.4675142, lng: 19.2456861 },
    { lat: 49.4674488, lng: 19.245192 },
    { lat: 49.4676813, lng: 19.2448227 },
    { lat: 49.4677798, lng: 19.2448066 },
    { lat: 49.4679158, lng: 19.2446334 },
    { lat: 49.4680831, lng: 19.2445722 },
    { lat: 49.4681269, lng: 19.2444253 },
    { lat: 49.4681079, lng: 19.2442651 },
    { lat: 49.4681777, lng: 19.2437663 },
    { lat: 49.4682349, lng: 19.2435932 },
    { lat: 49.4684458, lng: 19.2433865 },
    { lat: 49.4686308, lng: 19.2429614 },
    { lat: 49.4687244, lng: 19.2428495 },
    { lat: 49.4687631, lng: 19.242785 },
    { lat: 49.4689751, lng: 19.2426706 },
    { lat: 49.4689406, lng: 19.2424918 },
    { lat: 49.4691098, lng: 19.2420431 },
    { lat: 49.4692113, lng: 19.2419284 },
    { lat: 49.4693664, lng: 19.2418233 },
    { lat: 49.469638, lng: 19.2414914 },
    { lat: 49.4698348, lng: 19.2414592 },
    { lat: 49.4699199, lng: 19.2412815 },
    { lat: 49.4700053, lng: 19.2408545 },
    { lat: 49.4699498, lng: 19.2407295 },
    { lat: 49.470038, lng: 19.2404989 },
    { lat: 49.4700613, lng: 19.2402996 },
    { lat: 49.4701499, lng: 19.2401034 },
    { lat: 49.4702227, lng: 19.2400934 },
    { lat: 49.4703816, lng: 19.2397614 },
    { lat: 49.470567, lng: 19.2397025 },
    { lat: 49.4707482, lng: 19.2396554 },
    { lat: 49.4709424, lng: 19.2396821 },
    { lat: 49.4710617, lng: 19.2394893 },
    { lat: 49.4711999, lng: 19.2395307 },
    { lat: 49.4713174, lng: 19.2394882 },
    { lat: 49.47157, lng: 19.2392533 },
    { lat: 49.471794, lng: 19.2390625 },
    { lat: 49.4719432, lng: 19.2386203 },
    { lat: 49.4721015, lng: 19.238583 },
    { lat: 49.4722209, lng: 19.238487 },
    { lat: 49.4724074, lng: 19.2384066 },
    { lat: 49.4724424, lng: 19.2382204 },
    { lat: 49.4726017, lng: 19.2380391 },
    { lat: 49.4725975, lng: 19.2377623 },
    { lat: 49.4726985, lng: 19.2373759 },
    { lat: 49.4728907, lng: 19.2371974 },
    { lat: 49.4729715, lng: 19.2367676 },
    { lat: 49.4730139, lng: 19.2365219 },
    { lat: 49.4730616, lng: 19.2363625 },
    { lat: 49.473153, lng: 19.2361312 },
    { lat: 49.4731588, lng: 19.2360153 },
    { lat: 49.4733441, lng: 19.2357651 },
    { lat: 49.4733641, lng: 19.2356411 },
    { lat: 49.4738318, lng: 19.2353363 },
    { lat: 49.4741277, lng: 19.2348515 },
    { lat: 49.4742664, lng: 19.2347584 },
    { lat: 49.4742617, lng: 19.2346118 },
    { lat: 49.4745424, lng: 19.2342621 },
    { lat: 49.4746463, lng: 19.2340554 },
    { lat: 49.4747294, lng: 19.2338123 },
    { lat: 49.4748009, lng: 19.2337268 },
    { lat: 49.4749372, lng: 19.2336328 },
    { lat: 49.4752266, lng: 19.2336753 },
    { lat: 49.4754349, lng: 19.2334333 },
    { lat: 49.4758448, lng: 19.2332141 },
    { lat: 49.4760397, lng: 19.233286 },
    { lat: 49.4762472, lng: 19.2331442 },
    { lat: 49.4764133, lng: 19.233227 },
    { lat: 49.4764632, lng: 19.2331134 },
    { lat: 49.4765534, lng: 19.2330931 },
    { lat: 49.4768763, lng: 19.2329165 },
    { lat: 49.4769117, lng: 19.2328285 },
    { lat: 49.47706, lng: 19.2327487 },
    { lat: 49.4772075, lng: 19.2325593 },
    { lat: 49.4774228, lng: 19.2323256 },
    { lat: 49.4774534, lng: 19.2322095 },
    { lat: 49.4778537, lng: 19.2317879 },
    { lat: 49.4778781, lng: 19.2316373 },
    { lat: 49.4780114, lng: 19.2314128 },
    { lat: 49.4781564, lng: 19.231353 },
    { lat: 49.4782405, lng: 19.2315125 },
    { lat: 49.4783441, lng: 19.2315345 },
    { lat: 49.4785899, lng: 19.2313224 },
    { lat: 49.4787684, lng: 19.2312121 },
    { lat: 49.4789142, lng: 19.2312689 },
    { lat: 49.4791019, lng: 19.2311884 },
    { lat: 49.4792774, lng: 19.2311585 },
    { lat: 49.4793612, lng: 19.2310008 },
    { lat: 49.4794611, lng: 19.2309465 },
    { lat: 49.479563, lng: 19.2307222 },
    { lat: 49.4798257, lng: 19.2304854 },
    { lat: 49.4799122, lng: 19.2303144 },
    { lat: 49.480177, lng: 19.2300084 },
    { lat: 49.4803706, lng: 19.2297666 },
    { lat: 49.4805175, lng: 19.2297505 },
    { lat: 49.4810685, lng: 19.2293282 },
    { lat: 49.4827827, lng: 19.2287142 },
    { lat: 49.4843337, lng: 19.2276977 },
  ],
  OravskáJasenica: [
    { lat: 49.3822265, lng: 19.4339439 },
    { lat: 49.3823709, lng: 19.4339709 },
    { lat: 49.3823969, lng: 19.4339736 },
    { lat: 49.382528, lng: 19.4340076 },
    { lat: 49.3825681, lng: 19.434018 },
    { lat: 49.3826554, lng: 19.4340527 },
    { lat: 49.3827391, lng: 19.4340866 },
    { lat: 49.3828829, lng: 19.4339799 },
    { lat: 49.3828687, lng: 19.4341627 },
    { lat: 49.3828641, lng: 19.4342326 },
    { lat: 49.3828436, lng: 19.4344225 },
    { lat: 49.3829075, lng: 19.4347599 },
    { lat: 49.3829436, lng: 19.4353484 },
    { lat: 49.3829889, lng: 19.435618 },
    { lat: 49.3830474, lng: 19.435935 },
    { lat: 49.3831479, lng: 19.4362348 },
    { lat: 49.3832486, lng: 19.436536 },
    { lat: 49.3833337, lng: 19.4367481 },
    { lat: 49.3833716, lng: 19.4373234 },
    { lat: 49.3832799, lng: 19.438193 },
    { lat: 49.3832086, lng: 19.4384817 },
    { lat: 49.3832154, lng: 19.4386032 },
    { lat: 49.3831877, lng: 19.4386174 },
    { lat: 49.3831772, lng: 19.438752 },
    { lat: 49.3830871, lng: 19.4395386 },
    { lat: 49.3829417, lng: 19.4396324 },
    { lat: 49.3828271, lng: 19.4404972 },
    { lat: 49.3827915, lng: 19.4407756 },
    { lat: 49.3828005, lng: 19.4415352 },
    { lat: 49.3828389, lng: 19.4424192 },
    { lat: 49.3828542, lng: 19.4429731 },
    { lat: 49.3829046, lng: 19.4437253 },
    { lat: 49.3829398, lng: 19.4438528 },
    { lat: 49.3831551, lng: 19.4440277 },
    { lat: 49.3832664, lng: 19.4441166 },
    { lat: 49.3834155, lng: 19.4444206 },
    { lat: 49.3836774, lng: 19.4447728 },
    { lat: 49.38396, lng: 19.4451607 },
    { lat: 49.3843332, lng: 19.4453132 },
    { lat: 49.3846031, lng: 19.4453749 },
    { lat: 49.384624, lng: 19.4453874 },
    { lat: 49.3847602, lng: 19.4454852 },
    { lat: 49.3848936, lng: 19.4455667 },
    { lat: 49.3849051, lng: 19.4455701 },
    { lat: 49.3850489, lng: 19.445658 },
    { lat: 49.3851979, lng: 19.4457407 },
    { lat: 49.385294, lng: 19.4458428 },
    { lat: 49.3853232, lng: 19.4458928 },
    { lat: 49.3853507, lng: 19.445951 },
    { lat: 49.3854317, lng: 19.4461169 },
    { lat: 49.385542, lng: 19.4463387 },
    { lat: 49.3855995, lng: 19.446458 },
    { lat: 49.3856318, lng: 19.4465243 },
    { lat: 49.385697, lng: 19.4466699 },
    { lat: 49.3857046, lng: 19.4466853 },
    { lat: 49.3857319, lng: 19.4467392 },
    { lat: 49.3857713, lng: 19.4468174 },
    { lat: 49.3858507, lng: 19.4469716 },
    { lat: 49.3859386, lng: 19.4471216 },
    { lat: 49.3859573, lng: 19.4471593 },
    { lat: 49.3860121, lng: 19.4472721 },
    { lat: 49.3860893, lng: 19.4474315 },
    { lat: 49.3862701, lng: 19.44779 },
    { lat: 49.3862801, lng: 19.4478063 },
    { lat: 49.3863868, lng: 19.4479962 },
    { lat: 49.386422, lng: 19.4480543 },
    { lat: 49.3864479, lng: 19.4481235 },
    { lat: 49.3865588, lng: 19.4484059 },
    { lat: 49.3865716, lng: 19.4484363 },
    { lat: 49.3865856, lng: 19.4485003 },
    { lat: 49.3866466, lng: 19.4486239 },
    { lat: 49.3871151, lng: 19.4498953 },
    { lat: 49.3871561, lng: 19.4500012 },
    { lat: 49.3872283, lng: 19.4502459 },
    { lat: 49.3877819, lng: 19.4507747 },
    { lat: 49.3879517, lng: 19.4509347 },
    { lat: 49.3880361, lng: 19.4510077 },
    { lat: 49.3881681, lng: 19.451183 },
    { lat: 49.3883165, lng: 19.4513706 },
    { lat: 49.3884468, lng: 19.4515352 },
    { lat: 49.3886319, lng: 19.4518835 },
    { lat: 49.3887616, lng: 19.4520612 },
    { lat: 49.388847, lng: 19.4522744 },
    { lat: 49.388944, lng: 19.4524235 },
    { lat: 49.3889905, lng: 19.4524792 },
    { lat: 49.3890895, lng: 19.4523789 },
    { lat: 49.3892218, lng: 19.4521903 },
    { lat: 49.3892428, lng: 19.4521594 },
    { lat: 49.3894379, lng: 19.4524596 },
    { lat: 49.3895422, lng: 19.4522989 },
    { lat: 49.3896011, lng: 19.452208 },
    { lat: 49.3897324, lng: 19.4521732 },
    { lat: 49.3900428, lng: 19.452109 },
    { lat: 49.3901136, lng: 19.4520301 },
    { lat: 49.3901789, lng: 19.4519576 },
    { lat: 49.3902411, lng: 19.4518883 },
    { lat: 49.3902588, lng: 19.4519077 },
    { lat: 49.3906173, lng: 19.4518369 },
    { lat: 49.3908529, lng: 19.4517446 },
    { lat: 49.3909423, lng: 19.4516763 },
    { lat: 49.3910523, lng: 19.4515921 },
    { lat: 49.3910794, lng: 19.4515715 },
    { lat: 49.3910755, lng: 19.4515585 },
    { lat: 49.3912545, lng: 19.4513591 },
    { lat: 49.391492, lng: 19.4510864 },
    { lat: 49.3915318, lng: 19.4510687 },
    { lat: 49.3915725, lng: 19.4510499 },
    { lat: 49.3915948, lng: 19.4510403 },
    { lat: 49.3922085, lng: 19.4507654 },
    { lat: 49.3928995, lng: 19.4505255 },
    { lat: 49.3941467, lng: 19.450425 },
    { lat: 49.3945891, lng: 19.4511532 },
    { lat: 49.3947902, lng: 19.4514821 },
    { lat: 49.3951551, lng: 19.452091 },
    { lat: 49.3956968, lng: 19.4530686 },
    { lat: 49.3963372, lng: 19.4541297 },
    { lat: 49.3963493, lng: 19.4541512 },
    { lat: 49.3969538, lng: 19.454625 },
    { lat: 49.3973559, lng: 19.4553627 },
    { lat: 49.3977027, lng: 19.4560188 },
    { lat: 49.3981514, lng: 19.4566644 },
    { lat: 49.3983244, lng: 19.4565046 },
    { lat: 49.3989695, lng: 19.4547768 },
    { lat: 49.3996411, lng: 19.4532305 },
    { lat: 49.4001618, lng: 19.4527898 },
    { lat: 49.4005744, lng: 19.4523564 },
    { lat: 49.4007813, lng: 19.4519517 },
    { lat: 49.4009721, lng: 19.4513964 },
    { lat: 49.4011838, lng: 19.4507832 },
    { lat: 49.401971, lng: 19.4494215 },
    { lat: 49.4020831, lng: 19.4491167 },
    { lat: 49.4024473, lng: 19.44856 },
    { lat: 49.403394, lng: 19.447561 },
    { lat: 49.4034431, lng: 19.4474012 },
    { lat: 49.4036652, lng: 19.4471131 },
    { lat: 49.4040941, lng: 19.4470284 },
    { lat: 49.4043305, lng: 19.4469523 },
    { lat: 49.4046621, lng: 19.4468362 },
    { lat: 49.4050257, lng: 19.4469243 },
    { lat: 49.4057823, lng: 19.4470604 },
    { lat: 49.4062536, lng: 19.4467718 },
    { lat: 49.4068271, lng: 19.4463057 },
    { lat: 49.4074296, lng: 19.4459795 },
    { lat: 49.407834, lng: 19.4461019 },
    { lat: 49.4079764, lng: 19.4461328 },
    { lat: 49.4086501, lng: 19.446135 },
    { lat: 49.4089811, lng: 19.4460786 },
    { lat: 49.4090347, lng: 19.4460692 },
    { lat: 49.4095187, lng: 19.4459866 },
    { lat: 49.4095614, lng: 19.4459794 },
    { lat: 49.4097685, lng: 19.4459752 },
    { lat: 49.4098562, lng: 19.4459733 },
    { lat: 49.4101192, lng: 19.4458845 },
    { lat: 49.4105342, lng: 19.4457448 },
    { lat: 49.4109869, lng: 19.4455921 },
    { lat: 49.411231, lng: 19.44551 },
    { lat: 49.4118353, lng: 19.445177 },
    { lat: 49.4121567, lng: 19.4449639 },
    { lat: 49.4123744, lng: 19.4448195 },
    { lat: 49.4126482, lng: 19.4446224 },
    { lat: 49.4129811, lng: 19.4444116 },
    { lat: 49.4139746, lng: 19.4439425 },
    { lat: 49.4145548, lng: 19.4435747 },
    { lat: 49.4150541, lng: 19.4437745 },
    { lat: 49.4157103, lng: 19.4440774 },
    { lat: 49.4164614, lng: 19.444116 },
    { lat: 49.4166018, lng: 19.4441074 },
    { lat: 49.4172242, lng: 19.4444126 },
    { lat: 49.4175888, lng: 19.4441054 },
    { lat: 49.4183311, lng: 19.4436119 },
    { lat: 49.4187483, lng: 19.4435451 },
    { lat: 49.419088, lng: 19.4434103 },
    { lat: 49.4197785, lng: 19.4433303 },
    { lat: 49.4202472, lng: 19.4439412 },
    { lat: 49.420858, lng: 19.4439212 },
    { lat: 49.4209869, lng: 19.4439304 },
    { lat: 49.4211136, lng: 19.4439403 },
    { lat: 49.4215086, lng: 19.4439812 },
    { lat: 49.4217034, lng: 19.4440007 },
    { lat: 49.4220462, lng: 19.4439861 },
    { lat: 49.4224729, lng: 19.4439755 },
    { lat: 49.4225078, lng: 19.4439742 },
    { lat: 49.422953, lng: 19.4433412 },
    { lat: 49.4230176, lng: 19.4431797 },
    { lat: 49.4231406, lng: 19.4428609 },
    { lat: 49.4233355, lng: 19.4423502 },
    { lat: 49.4236414, lng: 19.4414549 },
    { lat: 49.4239948, lng: 19.4408336 },
    { lat: 49.4240241, lng: 19.4407816 },
    { lat: 49.4241418, lng: 19.4405738 },
    { lat: 49.4248725, lng: 19.4400492 },
    { lat: 49.4257192, lng: 19.4396916 },
    { lat: 49.4262318, lng: 19.4393719 },
    { lat: 49.4273208, lng: 19.4387478 },
    { lat: 49.4280871, lng: 19.4383818 },
    { lat: 49.4289677, lng: 19.4376625 },
    { lat: 49.4293812, lng: 19.4372934 },
    { lat: 49.4297855, lng: 19.4368757 },
    { lat: 49.4302755, lng: 19.4363079 },
    { lat: 49.4304947, lng: 19.4360793 },
    { lat: 49.4312499, lng: 19.4359111 },
    { lat: 49.4317959, lng: 19.4356062 },
    { lat: 49.4328757, lng: 19.4345419 },
    { lat: 49.4329254, lng: 19.4344927 },
    { lat: 49.433305, lng: 19.4341483 },
    { lat: 49.4336775, lng: 19.4336228 },
    { lat: 49.4342486, lng: 19.4328123 },
    { lat: 49.4347211, lng: 19.4322604 },
    { lat: 49.435256, lng: 19.4320309 },
    { lat: 49.4353627, lng: 19.4320016 },
    { lat: 49.4358453, lng: 19.431668 },
    { lat: 49.4363469, lng: 19.4313769 },
    { lat: 49.4365151, lng: 19.431333 },
    { lat: 49.4370316, lng: 19.4311978 },
    { lat: 49.4372405, lng: 19.4310636 },
    { lat: 49.4373466, lng: 19.4307726 },
    { lat: 49.4374995, lng: 19.4304943 },
    { lat: 49.4375781, lng: 19.4303196 },
    { lat: 49.4377161, lng: 19.4300146 },
    { lat: 49.4379861, lng: 19.4294184 },
    { lat: 49.4381074, lng: 19.4292112 },
    { lat: 49.4383395, lng: 19.4289666 },
    { lat: 49.4387597, lng: 19.4285239 },
    { lat: 49.4390831, lng: 19.4281829 },
    { lat: 49.4391987, lng: 19.4280737 },
    { lat: 49.4397252, lng: 19.4274735 },
    { lat: 49.4402359, lng: 19.4270655 },
    { lat: 49.4415133, lng: 19.4264208 },
    { lat: 49.4415943, lng: 19.4263957 },
    { lat: 49.4416388, lng: 19.4263819 },
    { lat: 49.4417766, lng: 19.4263387 },
    { lat: 49.4422339, lng: 19.4259947 },
    { lat: 49.4425835, lng: 19.425779 },
    { lat: 49.442927, lng: 19.4254101 },
    { lat: 49.4433466, lng: 19.4246161 },
    { lat: 49.4437731, lng: 19.4237218 },
    { lat: 49.4440237, lng: 19.4232434 },
    { lat: 49.444172, lng: 19.4231372 },
    { lat: 49.4442078, lng: 19.423109 },
    { lat: 49.4446302, lng: 19.4228122 },
    { lat: 49.445589, lng: 19.4222747 },
    { lat: 49.4462577, lng: 19.4215985 },
    { lat: 49.4464251, lng: 19.4214652 },
    { lat: 49.4467951, lng: 19.4214048 },
    { lat: 49.447202, lng: 19.4212849 },
    { lat: 49.4475181, lng: 19.4210518 },
    { lat: 49.4478286, lng: 19.4208229 },
    { lat: 49.4483765, lng: 19.4205602 },
    { lat: 49.4488444, lng: 19.4203027 },
    { lat: 49.4502828, lng: 19.4195095 },
    { lat: 49.4503892, lng: 19.4194925 },
    { lat: 49.4510294, lng: 19.4194498 },
    { lat: 49.4512589, lng: 19.4194694 },
    { lat: 49.4515072, lng: 19.4192574 },
    { lat: 49.4520966, lng: 19.4190898 },
    { lat: 49.4525262, lng: 19.4188012 },
    { lat: 49.452572, lng: 19.4187705 },
    { lat: 49.452924, lng: 19.4185345 },
    { lat: 49.4539454, lng: 19.4179368 },
    { lat: 49.4542245, lng: 19.4177316 },
    { lat: 49.4543764, lng: 19.417656 },
    { lat: 49.4542127, lng: 19.4169911 },
    { lat: 49.4540607, lng: 19.4164803 },
    { lat: 49.4538519, lng: 19.4157677 },
    { lat: 49.4535898, lng: 19.4148013 },
    { lat: 49.4535009, lng: 19.4144818 },
    { lat: 49.4532983, lng: 19.4140525 },
    { lat: 49.4525204, lng: 19.413261 },
    { lat: 49.4519925, lng: 19.412711 },
    { lat: 49.451691, lng: 19.4124034 },
    { lat: 49.4504689, lng: 19.4109996 },
    { lat: 49.4503958, lng: 19.4107367 },
    { lat: 49.4501475, lng: 19.4098699 },
    { lat: 49.4500858, lng: 19.4096068 },
    { lat: 49.4498835, lng: 19.4091088 },
    { lat: 49.4497429, lng: 19.4087742 },
    { lat: 49.4496383, lng: 19.4082174 },
    { lat: 49.4494063, lng: 19.4076121 },
    { lat: 49.4489402, lng: 19.4065449 },
    { lat: 49.4484619, lng: 19.4055605 },
    { lat: 49.4481756, lng: 19.4048993 },
    { lat: 49.448, lng: 19.4044929 },
    { lat: 49.4477565, lng: 19.4030994 },
    { lat: 49.4475631, lng: 19.4023285 },
    { lat: 49.4474129, lng: 19.401814 },
    { lat: 49.4471736, lng: 19.4009028 },
    { lat: 49.4471188, lng: 19.4003779 },
    { lat: 49.4471458, lng: 19.3997173 },
    { lat: 49.4472254, lng: 19.3992752 },
    { lat: 49.4472942, lng: 19.398471 },
    { lat: 49.4473206, lng: 19.3977058 },
    { lat: 49.4472867, lng: 19.3969736 },
    { lat: 49.4471246, lng: 19.3959527 },
    { lat: 49.4465863, lng: 19.3948696 },
    { lat: 49.4462451, lng: 19.3942887 },
    { lat: 49.4458004, lng: 19.3941029 },
    { lat: 49.4457557, lng: 19.3940857 },
    { lat: 49.4454676, lng: 19.3939476 },
    { lat: 49.4451562, lng: 19.3938411 },
    { lat: 49.4448023, lng: 19.3938627 },
    { lat: 49.4445581, lng: 19.3938716 },
    { lat: 49.444464, lng: 19.393762 },
    { lat: 49.4444056, lng: 19.3936941 },
    { lat: 49.4443519, lng: 19.3936319 },
    { lat: 49.4442872, lng: 19.3934221 },
    { lat: 49.4442091, lng: 19.3934646 },
    { lat: 49.444203, lng: 19.3933824 },
    { lat: 49.4441959, lng: 19.3933006 },
    { lat: 49.4441549, lng: 19.3931207 },
    { lat: 49.4439997, lng: 19.3930826 },
    { lat: 49.4438672, lng: 19.3928222 },
    { lat: 49.4437741, lng: 19.3924199 },
    { lat: 49.4437181, lng: 19.3921831 },
    { lat: 49.4437108, lng: 19.3921491 },
    { lat: 49.4436198, lng: 19.3919834 },
    { lat: 49.443605, lng: 19.3919553 },
    { lat: 49.4434872, lng: 19.3917723 },
    { lat: 49.4433422, lng: 19.3917045 },
    { lat: 49.4432567, lng: 19.3916645 },
    { lat: 49.443232, lng: 19.3915195 },
    { lat: 49.4432226, lng: 19.3914637 },
    { lat: 49.4431824, lng: 19.3912287 },
    { lat: 49.4431868, lng: 19.391175 },
    { lat: 49.4432517, lng: 19.3910672 },
    { lat: 49.4432705, lng: 19.3910355 },
    { lat: 49.4432894, lng: 19.3910041 },
    { lat: 49.4433071, lng: 19.3909754 },
    { lat: 49.443329, lng: 19.3909386 },
    { lat: 49.4433321, lng: 19.3909332 },
    { lat: 49.4432466, lng: 19.3907144 },
    { lat: 49.4431468, lng: 19.3905326 },
    { lat: 49.4431309, lng: 19.3905047 },
    { lat: 49.4431198, lng: 19.3904838 },
    { lat: 49.4430389, lng: 19.3904144 },
    { lat: 49.442864, lng: 19.390264 },
    { lat: 49.4427536, lng: 19.3901696 },
    { lat: 49.4426808, lng: 19.3901066 },
    { lat: 49.4425876, lng: 19.3900161 },
    { lat: 49.4425334, lng: 19.3899634 },
    { lat: 49.4425038, lng: 19.3899355 },
    { lat: 49.4425028, lng: 19.3899341 },
    { lat: 49.4424829, lng: 19.3898465 },
    { lat: 49.4425043, lng: 19.3896522 },
    { lat: 49.4426828, lng: 19.3892493 },
    { lat: 49.4427031, lng: 19.3892033 },
    { lat: 49.4427063, lng: 19.3891967 },
    { lat: 49.442712, lng: 19.3891009 },
    { lat: 49.4427236, lng: 19.3889178 },
    { lat: 49.4426161, lng: 19.388784 },
    { lat: 49.4425121, lng: 19.3886542 },
    { lat: 49.4424832, lng: 19.3886175 },
    { lat: 49.4424343, lng: 19.3885555 },
    { lat: 49.4424415, lng: 19.3884207 },
    { lat: 49.4425247, lng: 19.3882947 },
    { lat: 49.4426402, lng: 19.3882089 },
    { lat: 49.4426586, lng: 19.3881953 },
    { lat: 49.4426641, lng: 19.3881708 },
    { lat: 49.4427166, lng: 19.3879387 },
    { lat: 49.4427041, lng: 19.3879126 },
    { lat: 49.4426828, lng: 19.3878671 },
    { lat: 49.4426654, lng: 19.3878321 },
    { lat: 49.4426803, lng: 19.3876896 },
    { lat: 49.4427119, lng: 19.3876177 },
    { lat: 49.4427322, lng: 19.387572 },
    { lat: 49.4427444, lng: 19.3875454 },
    { lat: 49.4427719, lng: 19.3874819 },
    { lat: 49.4427862, lng: 19.3874499 },
    { lat: 49.4427984, lng: 19.3874221 },
    { lat: 49.442788, lng: 19.3873661 },
    { lat: 49.4426726, lng: 19.3871136 },
    { lat: 49.4426672, lng: 19.3868995 },
    { lat: 49.4426657, lng: 19.3868924 },
    { lat: 49.4426595, lng: 19.3868311 },
    { lat: 49.4426332, lng: 19.3866059 },
    { lat: 49.4426438, lng: 19.3865638 },
    { lat: 49.4428035, lng: 19.3864135 },
    { lat: 49.4428456, lng: 19.3863737 },
    { lat: 49.4428908, lng: 19.3863311 },
    { lat: 49.4428827, lng: 19.3861307 },
    { lat: 49.4427784, lng: 19.385847 },
    { lat: 49.4427551, lng: 19.3857822 },
    { lat: 49.4427369, lng: 19.385732 },
    { lat: 49.4427334, lng: 19.3856806 },
    { lat: 49.4427534, lng: 19.3856248 },
    { lat: 49.4427902, lng: 19.3855209 },
    { lat: 49.4428061, lng: 19.3854775 },
    { lat: 49.4427993, lng: 19.3853066 },
    { lat: 49.4427937, lng: 19.3851591 },
    { lat: 49.4428167, lng: 19.3849538 },
    { lat: 49.4428374, lng: 19.3847683 },
    { lat: 49.4427375, lng: 19.3845098 },
    { lat: 49.44273, lng: 19.38449 },
    { lat: 49.4427298, lng: 19.384486 },
    { lat: 49.4427247, lng: 19.3842802 },
    { lat: 49.4426197, lng: 19.3842044 },
    { lat: 49.442607, lng: 19.3841953 },
    { lat: 49.4425672, lng: 19.3841674 },
    { lat: 49.4425312, lng: 19.3841406 },
    { lat: 49.4425129, lng: 19.3840854 },
    { lat: 49.4426156, lng: 19.3838989 },
    { lat: 49.4427734, lng: 19.3832439 },
    { lat: 49.4427915, lng: 19.383171 },
    { lat: 49.4427811, lng: 19.3828263 },
    { lat: 49.4427769, lng: 19.3827152 },
    { lat: 49.4428292, lng: 19.3825556 },
    { lat: 49.4428579, lng: 19.3824663 },
    { lat: 49.4427976, lng: 19.3820938 },
    { lat: 49.4427846, lng: 19.3820072 },
    { lat: 49.4428362, lng: 19.3816384 },
    { lat: 49.4428367, lng: 19.3814558 },
    { lat: 49.442836, lng: 19.3813661 },
    { lat: 49.442837, lng: 19.3813146 },
    { lat: 49.4426944, lng: 19.3810361 },
    { lat: 49.442667, lng: 19.3809812 },
    { lat: 49.4426485, lng: 19.3809441 },
    { lat: 49.4426767, lng: 19.380727 },
    { lat: 49.4426972, lng: 19.3806856 },
    { lat: 49.4428232, lng: 19.3806777 },
    { lat: 49.4428583, lng: 19.3806097 },
    { lat: 49.4428118, lng: 19.380143 },
    { lat: 49.4428551, lng: 19.3797385 },
    { lat: 49.4428656, lng: 19.3794114 },
    { lat: 49.442867, lng: 19.3793664 },
    { lat: 49.4425587, lng: 19.3788185 },
    { lat: 49.4425304, lng: 19.3787695 },
    { lat: 49.4425183, lng: 19.378727 },
    { lat: 49.4424964, lng: 19.3783264 },
    { lat: 49.4424949, lng: 19.3782943 },
    { lat: 49.4423077, lng: 19.3779501 },
    { lat: 49.4423017, lng: 19.3779436 },
    { lat: 49.4422101, lng: 19.3778424 },
    { lat: 49.4421401, lng: 19.3777635 },
    { lat: 49.4420847, lng: 19.3776197 },
    { lat: 49.442036, lng: 19.3774907 },
    { lat: 49.442031, lng: 19.3774536 },
    { lat: 49.4420321, lng: 19.3770917 },
    { lat: 49.4420314, lng: 19.3770266 },
    { lat: 49.4420041, lng: 19.3769977 },
    { lat: 49.4419448, lng: 19.3769365 },
    { lat: 49.4419662, lng: 19.3764978 },
    { lat: 49.4419627, lng: 19.3764478 },
    { lat: 49.4418595, lng: 19.3762136 },
    { lat: 49.4418227, lng: 19.3761289 },
    { lat: 49.4417272, lng: 19.3760344 },
    { lat: 49.4416999, lng: 19.3760077 },
    { lat: 49.4416339, lng: 19.3759425 },
    { lat: 49.4416007, lng: 19.3759092 },
    { lat: 49.441549, lng: 19.3757414 },
    { lat: 49.4415087, lng: 19.3753833 },
    { lat: 49.4415045, lng: 19.3753443 },
    { lat: 49.4414979, lng: 19.3753201 },
    { lat: 49.4414934, lng: 19.3752988 },
    { lat: 49.441404, lng: 19.3752652 },
    { lat: 49.4413638, lng: 19.3752493 },
    { lat: 49.4413362, lng: 19.3752389 },
    { lat: 49.4413071, lng: 19.3751918 },
    { lat: 49.4412916, lng: 19.3748846 },
    { lat: 49.4411871, lng: 19.3745984 },
    { lat: 49.4411715, lng: 19.3745563 },
    { lat: 49.4411271, lng: 19.3745213 },
    { lat: 49.4410685, lng: 19.3744748 },
    { lat: 49.441064, lng: 19.3744225 },
    { lat: 49.4410853, lng: 19.3742771 },
    { lat: 49.4410798, lng: 19.3738457 },
    { lat: 49.4409922, lng: 19.3738242 },
    { lat: 49.4409328, lng: 19.3734928 },
    { lat: 49.4408407, lng: 19.3731622 },
    { lat: 49.4407074, lng: 19.3725507 },
    { lat: 49.4407311, lng: 19.3724301 },
    { lat: 49.4407131, lng: 19.3723785 },
    { lat: 49.4406922, lng: 19.3723206 },
    { lat: 49.4405312, lng: 19.3722499 },
    { lat: 49.4405162, lng: 19.3722432 },
    { lat: 49.440493, lng: 19.3722049 },
    { lat: 49.4404222, lng: 19.3718668 },
    { lat: 49.4404518, lng: 19.3717578 },
    { lat: 49.4404498, lng: 19.3716893 },
    { lat: 49.4403503, lng: 19.3715086 },
    { lat: 49.4403169, lng: 19.3714464 },
    { lat: 49.4402266, lng: 19.3713476 },
    { lat: 49.4401875, lng: 19.3713041 },
    { lat: 49.440179, lng: 19.3711989 },
    { lat: 49.4403222, lng: 19.3710786 },
    { lat: 49.4403133, lng: 19.3709322 },
    { lat: 49.4402852, lng: 19.3704269 },
    { lat: 49.4400379, lng: 19.3700546 },
    { lat: 49.4399043, lng: 19.3699432 },
    { lat: 49.4398243, lng: 19.3696515 },
    { lat: 49.4396696, lng: 19.3690876 },
    { lat: 49.4395633, lng: 19.3686977 },
    { lat: 49.4395539, lng: 19.3686872 },
    { lat: 49.4393076, lng: 19.3684281 },
    { lat: 49.4393152, lng: 19.3680844 },
    { lat: 49.4390549, lng: 19.367699 },
    { lat: 49.4389677, lng: 19.3674729 },
    { lat: 49.4387605, lng: 19.3674764 },
    { lat: 49.4387101, lng: 19.3670677 },
    { lat: 49.4385439, lng: 19.3668165 },
    { lat: 49.4384706, lng: 19.3668655 },
    { lat: 49.4375139, lng: 19.3661622 },
    { lat: 49.4374076, lng: 19.366298 },
    { lat: 49.436934, lng: 19.3668724 },
    { lat: 49.4363801, lng: 19.3673173 },
    { lat: 49.4357976, lng: 19.367728 },
    { lat: 49.4358496, lng: 19.3681716 },
    { lat: 49.4360985, lng: 19.3696401 },
    { lat: 49.4359311, lng: 19.3703757 },
    { lat: 49.4359108, lng: 19.3704528 },
    { lat: 49.4357478, lng: 19.3711086 },
    { lat: 49.4355874, lng: 19.3722877 },
    { lat: 49.4349868, lng: 19.3733296 },
    { lat: 49.4344669, lng: 19.3738742 },
    { lat: 49.4336695, lng: 19.3746599 },
    { lat: 49.4332028, lng: 19.3754539 },
    { lat: 49.4327817, lng: 19.3754812 },
    { lat: 49.4322939, lng: 19.3754607 },
    { lat: 49.4317529, lng: 19.3755565 },
    { lat: 49.4315636, lng: 19.3755786 },
    { lat: 49.4312717, lng: 19.3759433 },
    { lat: 49.4312537, lng: 19.3761143 },
    { lat: 49.4312671, lng: 19.3761338 },
    { lat: 49.4312617, lng: 19.3761381 },
    { lat: 49.4311665, lng: 19.3761999 },
    { lat: 49.4309661, lng: 19.3761041 },
    { lat: 49.4306761, lng: 19.3758079 },
    { lat: 49.430383, lng: 19.3757574 },
    { lat: 49.4300731, lng: 19.3755618 },
    { lat: 49.430068, lng: 19.3755479 },
    { lat: 49.4300547, lng: 19.3755052 },
    { lat: 49.4300521, lng: 19.3754968 },
    { lat: 49.4300418, lng: 19.3754925 },
    { lat: 49.4299348, lng: 19.375447 },
    { lat: 49.429931, lng: 19.3754608 },
    { lat: 49.4299249, lng: 19.375477 },
    { lat: 49.4299104, lng: 19.3754808 },
    { lat: 49.4299092, lng: 19.3754795 },
    { lat: 49.4298425, lng: 19.375427 },
    { lat: 49.4297333, lng: 19.3754759 },
    { lat: 49.4297289, lng: 19.375476 },
    { lat: 49.4295439, lng: 19.3754742 },
    { lat: 49.4294387, lng: 19.3755368 },
    { lat: 49.4294259, lng: 19.3756015 },
    { lat: 49.4292681, lng: 19.3757426 },
    { lat: 49.4284295, lng: 19.3758105 },
    { lat: 49.4284007, lng: 19.3758223 },
    { lat: 49.4282869, lng: 19.3758985 },
    { lat: 49.4282796, lng: 19.3759083 },
    { lat: 49.4282287, lng: 19.3760011 },
    { lat: 49.4281291, lng: 19.376088 },
    { lat: 49.4280638, lng: 19.3762136 },
    { lat: 49.4280578, lng: 19.3762254 },
    { lat: 49.4280404, lng: 19.3762422 },
    { lat: 49.4280069, lng: 19.3762488 },
    { lat: 49.4279244, lng: 19.3763888 },
    { lat: 49.4279188, lng: 19.3765328 },
    { lat: 49.4278859, lng: 19.3766472 },
    { lat: 49.427868, lng: 19.3767961 },
    { lat: 49.4277649, lng: 19.3769547 },
    { lat: 49.4275799, lng: 19.377337 },
    { lat: 49.4274356, lng: 19.3774331 },
    { lat: 49.4272528, lng: 19.377645 },
    { lat: 49.4271356, lng: 19.3777396 },
    { lat: 49.4271954, lng: 19.3779857 },
    { lat: 49.4271317, lng: 19.3781176 },
    { lat: 49.4269372, lng: 19.3784429 },
    { lat: 49.4271503, lng: 19.378589 },
    { lat: 49.4275061, lng: 19.3792633 },
    { lat: 49.4273388, lng: 19.3797833 },
    { lat: 49.4271646, lng: 19.3803982 },
    { lat: 49.4270134, lng: 19.3808538 },
    { lat: 49.4270043, lng: 19.3818706 },
    { lat: 49.4271677, lng: 19.3822564 },
    { lat: 49.4268397, lng: 19.3832265 },
    { lat: 49.4269543, lng: 19.3836306 },
    { lat: 49.4269318, lng: 19.3836581 },
    { lat: 49.4271688, lng: 19.3842211 },
    { lat: 49.4274515, lng: 19.3848983 },
    { lat: 49.4277023, lng: 19.3852756 },
    { lat: 49.4281839, lng: 19.3864987 },
    { lat: 49.4278548, lng: 19.3868594 },
    { lat: 49.427389, lng: 19.386974 },
    { lat: 49.4271737, lng: 19.3869949 },
    { lat: 49.4269557, lng: 19.3871306 },
    { lat: 49.4266686, lng: 19.3877408 },
    { lat: 49.4264236, lng: 19.3884086 },
    { lat: 49.426268, lng: 19.3890642 },
    { lat: 49.4259792, lng: 19.3895616 },
    { lat: 49.425784, lng: 19.3899253 },
    { lat: 49.4252773, lng: 19.3899049 },
    { lat: 49.4249215, lng: 19.3899865 },
    { lat: 49.4245958, lng: 19.3903717 },
    { lat: 49.4242519, lng: 19.3907046 },
    { lat: 49.4239065, lng: 19.3908172 },
    { lat: 49.4238338, lng: 19.3908747 },
    { lat: 49.4237664, lng: 19.3909277 },
    { lat: 49.4234691, lng: 19.3914397 },
    { lat: 49.4232253, lng: 19.3916017 },
    { lat: 49.4225189, lng: 19.3919933 },
    { lat: 49.4218587, lng: 19.39231 },
    { lat: 49.4214922, lng: 19.3928879 },
    { lat: 49.4210461, lng: 19.3931569 },
    { lat: 49.4206015, lng: 19.3934573 },
    { lat: 49.4200067, lng: 19.39395 },
    { lat: 49.4196638, lng: 19.3941365 },
    { lat: 49.4191811, lng: 19.394415 },
    { lat: 49.4187501, lng: 19.3949115 },
    { lat: 49.4185231, lng: 19.3951447 },
    { lat: 49.4182556, lng: 19.3957667 },
    { lat: 49.4177945, lng: 19.3959339 },
    { lat: 49.4175073, lng: 19.3961509 },
    { lat: 49.4171872, lng: 19.3965658 },
    { lat: 49.4168442, lng: 19.3967251 },
    { lat: 49.416207, lng: 19.3974652 },
    { lat: 49.4156883, lng: 19.3984276 },
    { lat: 49.4151651, lng: 19.3988103 },
    { lat: 49.4149218, lng: 19.3991307 },
    { lat: 49.4147612, lng: 19.3994804 },
    { lat: 49.4142149, lng: 19.4003332 },
    { lat: 49.4141449, lng: 19.4009882 },
    { lat: 49.4140042, lng: 19.4013542 },
    { lat: 49.4129696, lng: 19.4020336 },
    { lat: 49.4126083, lng: 19.4022372 },
    { lat: 49.4122459, lng: 19.4024413 },
    { lat: 49.4119578, lng: 19.4025943 },
    { lat: 49.4112161, lng: 19.4027354 },
    { lat: 49.4109112, lng: 19.4029886 },
    { lat: 49.4105639, lng: 19.4031267 },
    { lat: 49.4101165, lng: 19.4032962 },
    { lat: 49.4098306, lng: 19.4036881 },
    { lat: 49.4095215, lng: 19.4039537 },
    { lat: 49.4091937, lng: 19.4042937 },
    { lat: 49.4086488, lng: 19.4048647 },
    { lat: 49.4079914, lng: 19.405873 },
    { lat: 49.4075022, lng: 19.4069665 },
    { lat: 49.406832, lng: 19.4083106 },
    { lat: 49.4066828, lng: 19.40852 },
    { lat: 49.406459, lng: 19.4087252 },
    { lat: 49.4062798, lng: 19.409159 },
    { lat: 49.4058094, lng: 19.4093929 },
    { lat: 49.4052931, lng: 19.4097515 },
    { lat: 49.4049401, lng: 19.4098684 },
    { lat: 49.4046342, lng: 19.4099516 },
    { lat: 49.4040191, lng: 19.4101968 },
    { lat: 49.4032089, lng: 19.4106582 },
    { lat: 49.4028956, lng: 19.4107053 },
    { lat: 49.4024836, lng: 19.410935 },
    { lat: 49.4016275, lng: 19.4113294 },
    { lat: 49.4010564, lng: 19.411454 },
    { lat: 49.4006139, lng: 19.411586 },
    { lat: 49.4001569, lng: 19.4119092 },
    { lat: 49.3995613, lng: 19.4122141 },
    { lat: 49.3991378, lng: 19.4124352 },
    { lat: 49.3988937, lng: 19.4125685 },
    { lat: 49.3984531, lng: 19.4131767 },
    { lat: 49.3978034, lng: 19.413719 },
    { lat: 49.3968662, lng: 19.4142122 },
    { lat: 49.3962207, lng: 19.4141423 },
    { lat: 49.3957306, lng: 19.4141956 },
    { lat: 49.3954474, lng: 19.4143829 },
    { lat: 49.3951173, lng: 19.4147241 },
    { lat: 49.3946775, lng: 19.4148373 },
    { lat: 49.3941906, lng: 19.4144452 },
    { lat: 49.3937132, lng: 19.4142099 },
    { lat: 49.3931332, lng: 19.4143611 },
    { lat: 49.3927226, lng: 19.414401 },
    { lat: 49.3923177, lng: 19.4145864 },
    { lat: 49.3920258, lng: 19.4147794 },
    { lat: 49.3915682, lng: 19.4150707 },
    { lat: 49.3912114, lng: 19.4152986 },
    { lat: 49.3909478, lng: 19.4154198 },
    { lat: 49.3905908, lng: 19.4151334 },
    { lat: 49.3899802, lng: 19.4148423 },
    { lat: 49.3896554, lng: 19.4149828 },
    { lat: 49.3893756, lng: 19.4154111 },
    { lat: 49.3890129, lng: 19.416171 },
    { lat: 49.3889108, lng: 19.4160367 },
    { lat: 49.3881791, lng: 19.4169509 },
    { lat: 49.3880227, lng: 19.4166901 },
    { lat: 49.3874746, lng: 19.4173116 },
    { lat: 49.3871864, lng: 19.4178026 },
    { lat: 49.3870596, lng: 19.418768 },
    { lat: 49.386743, lng: 19.4197438 },
    { lat: 49.3862554, lng: 19.4193811 },
    { lat: 49.3857418, lng: 19.4192415 },
    { lat: 49.3851404, lng: 19.4192467 },
    { lat: 49.3850072, lng: 19.419927 },
    { lat: 49.3844994, lng: 19.4203983 },
    { lat: 49.3841082, lng: 19.4216614 },
    { lat: 49.3834092, lng: 19.4221911 },
    { lat: 49.3830342, lng: 19.4228744 },
    { lat: 49.3823943, lng: 19.4240952 },
    { lat: 49.3825027, lng: 19.4243457 },
    { lat: 49.3821344, lng: 19.4248898 },
    { lat: 49.3809661, lng: 19.426209 },
    { lat: 49.3806199, lng: 19.4265908 },
    { lat: 49.3801011, lng: 19.4270462 },
    { lat: 49.3798735, lng: 19.4269237 },
    { lat: 49.379643, lng: 19.4270083 },
    { lat: 49.3794779, lng: 19.4271416 },
    { lat: 49.3796482, lng: 19.4282666 },
    { lat: 49.3801768, lng: 19.4304921 },
    { lat: 49.3802412, lng: 19.4313516 },
    { lat: 49.3802737, lng: 19.4314725 },
    { lat: 49.3804997, lng: 19.432291 },
    { lat: 49.3810121, lng: 19.433118 },
    { lat: 49.3814259, lng: 19.4334627 },
    { lat: 49.3815181, lng: 19.4335319 },
    { lat: 49.3822083, lng: 19.4337437 },
    { lat: 49.3824758, lng: 19.4337025 },
    { lat: 49.3826704, lng: 19.433697 },
    { lat: 49.3824779, lng: 19.4338697 },
    { lat: 49.3822265, lng: 19.4339439 },
  ],
  OravskáLesná: [
    { lat: 49.36671, lng: 19.116593 },
    { lat: 49.366569, lng: 19.116819 },
    { lat: 49.366321, lng: 19.117147 },
    { lat: 49.366113, lng: 19.117825 },
    { lat: 49.366463, lng: 19.118602 },
    { lat: 49.366443, lng: 19.118663 },
    { lat: 49.366269, lng: 19.119168 },
    { lat: 49.366161, lng: 19.119678 },
    { lat: 49.36618, lng: 19.119722 },
    { lat: 49.366318, lng: 19.120032 },
    { lat: 49.366505, lng: 19.120452 },
    { lat: 49.366552, lng: 19.120691 },
    { lat: 49.366656, lng: 19.120942 },
    { lat: 49.366771, lng: 19.121185 },
    { lat: 49.366866, lng: 19.121357 },
    { lat: 49.367227, lng: 19.121925 },
    { lat: 49.367563, lng: 19.122186 },
    { lat: 49.367585, lng: 19.12223 },
    { lat: 49.367669, lng: 19.122404 },
    { lat: 49.367836, lng: 19.122748 },
    { lat: 49.36785, lng: 19.122778 },
    { lat: 49.367867, lng: 19.122876 },
    { lat: 49.367983, lng: 19.123547 },
    { lat: 49.367957, lng: 19.123691 },
    { lat: 49.367938, lng: 19.123793 },
    { lat: 49.367944, lng: 19.123842 },
    { lat: 49.367946, lng: 19.123852 },
    { lat: 49.367897, lng: 19.12419 },
    { lat: 49.367931, lng: 19.124608 },
    { lat: 49.36795, lng: 19.12484 },
    { lat: 49.367954, lng: 19.125102 },
    { lat: 49.368149, lng: 19.125756 },
    { lat: 49.368167, lng: 19.12578 },
    { lat: 49.368199, lng: 19.126162 },
    { lat: 49.368235, lng: 19.126595 },
    { lat: 49.368265, lng: 19.126855 },
    { lat: 49.36834, lng: 19.127287 },
    { lat: 49.368499, lng: 19.12774 },
    { lat: 49.368605, lng: 19.128312 },
    { lat: 49.368737, lng: 19.128298 },
    { lat: 49.368968, lng: 19.128506 },
    { lat: 49.368958, lng: 19.128825 },
    { lat: 49.36902, lng: 19.128926 },
    { lat: 49.369064, lng: 19.129181 },
    { lat: 49.36892, lng: 19.129712 },
    { lat: 49.368844, lng: 19.129926 },
    { lat: 49.368528, lng: 19.130302 },
    { lat: 49.368426, lng: 19.13042 },
    { lat: 49.368192, lng: 19.13101 },
    { lat: 49.367776, lng: 19.130964 },
    { lat: 49.367602, lng: 19.131068 },
    { lat: 49.367323, lng: 19.131188 },
    { lat: 49.367041, lng: 19.131285 },
    { lat: 49.366632, lng: 19.131629 },
    { lat: 49.366517, lng: 19.131725 },
    { lat: 49.366495, lng: 19.131826 },
    { lat: 49.366484, lng: 19.131878 },
    { lat: 49.366478, lng: 19.131902 },
    { lat: 49.3664, lng: 19.131766 },
    { lat: 49.366234, lng: 19.131669 },
    { lat: 49.365649, lng: 19.131679 },
    { lat: 49.36561, lng: 19.131679 },
    { lat: 49.365473, lng: 19.131814 },
    { lat: 49.365356, lng: 19.132004 },
    { lat: 49.365145, lng: 19.132427 },
    { lat: 49.365049, lng: 19.132729 },
    { lat: 49.365042, lng: 19.133144 },
    { lat: 49.365028, lng: 19.133358 },
    { lat: 49.364842, lng: 19.133933 },
    { lat: 49.364808, lng: 19.134162 },
    { lat: 49.364832, lng: 19.134496 },
    { lat: 49.364775, lng: 19.134967 },
    { lat: 49.364574, lng: 19.135851 },
    { lat: 49.364618, lng: 19.13618 },
    { lat: 49.364602, lng: 19.136335 },
    { lat: 49.364483, lng: 19.136624 },
    { lat: 49.364473, lng: 19.13702 },
    { lat: 49.364496, lng: 19.137307 },
    { lat: 49.364566, lng: 19.137665 },
    { lat: 49.364592, lng: 19.137924 },
    { lat: 49.364619, lng: 19.138182 },
    { lat: 49.364679, lng: 19.138458 },
    { lat: 49.364839, lng: 19.138799 },
    { lat: 49.364703, lng: 19.139552 },
    { lat: 49.364575, lng: 19.139844 },
    { lat: 49.364475, lng: 19.140032 },
    { lat: 49.364197, lng: 19.140413 },
    { lat: 49.364078, lng: 19.140675 },
    { lat: 49.363956, lng: 19.141091 },
    { lat: 49.36377, lng: 19.141581 },
    { lat: 49.36353, lng: 19.142097 },
    { lat: 49.363109, lng: 19.142815 },
    { lat: 49.362784, lng: 19.143062 },
    { lat: 49.361735, lng: 19.143816 },
    { lat: 49.361013, lng: 19.144534 },
    { lat: 49.360849, lng: 19.144728 },
    { lat: 49.360648, lng: 19.145018 },
    { lat: 49.360245, lng: 19.145763 },
    { lat: 49.360133, lng: 19.145894 },
    { lat: 49.360013, lng: 19.146272 },
    { lat: 49.359978, lng: 19.147116 },
    { lat: 49.35986, lng: 19.147838 },
    { lat: 49.359859, lng: 19.147841 },
    { lat: 49.359812, lng: 19.147985 },
    { lat: 49.359663, lng: 19.14823 },
    { lat: 49.359571, lng: 19.14838 },
    { lat: 49.359497, lng: 19.148595 },
    { lat: 49.359495, lng: 19.148768 },
    { lat: 49.359452, lng: 19.148993 },
    { lat: 49.359448, lng: 19.14901 },
    { lat: 49.359417, lng: 19.149107 },
    { lat: 49.359376, lng: 19.14923 },
    { lat: 49.359281, lng: 19.149535 },
    { lat: 49.359235, lng: 19.14964 },
    { lat: 49.359221, lng: 19.149673 },
    { lat: 49.359203, lng: 19.149716 },
    { lat: 49.359191, lng: 19.149742 },
    { lat: 49.359, lng: 19.150091 },
    { lat: 49.358905, lng: 19.150266 },
    { lat: 49.358708, lng: 19.150537 },
    { lat: 49.35863, lng: 19.150644 },
    { lat: 49.358539, lng: 19.150828 },
    { lat: 49.35838, lng: 19.151147 },
    { lat: 49.358308, lng: 19.151235 },
    { lat: 49.358282, lng: 19.151268 },
    { lat: 49.358056, lng: 19.151547 },
    { lat: 49.358016, lng: 19.151591 },
    { lat: 49.356954, lng: 19.152714 },
    { lat: 49.356444, lng: 19.153024 },
    { lat: 49.356299, lng: 19.153147 },
    { lat: 49.356191, lng: 19.153336 },
    { lat: 49.356021, lng: 19.153456 },
    { lat: 49.355733, lng: 19.153463 },
    { lat: 49.355661, lng: 19.153427 },
    { lat: 49.355092, lng: 19.153447 },
    { lat: 49.354659, lng: 19.153579 },
    { lat: 49.354612, lng: 19.153612 },
    { lat: 49.354447, lng: 19.15363 },
    { lat: 49.35388, lng: 19.153831 },
    { lat: 49.35378, lng: 19.153857 },
    { lat: 49.353168, lng: 19.154018 },
    { lat: 49.352794, lng: 19.154033 },
    { lat: 49.352136, lng: 19.154156 },
    { lat: 49.351994, lng: 19.154182 },
    { lat: 49.351648, lng: 19.154292 },
    { lat: 49.351086, lng: 19.154702 },
    { lat: 49.350948, lng: 19.154803 },
    { lat: 49.350858, lng: 19.154833 },
    { lat: 49.350422, lng: 19.154754 },
    { lat: 49.350265, lng: 19.154591 },
    { lat: 49.349764, lng: 19.154293 },
    { lat: 49.349631, lng: 19.154162 },
    { lat: 49.349456, lng: 19.154222 },
    { lat: 49.349419, lng: 19.154235 },
    { lat: 49.349213, lng: 19.154021 },
    { lat: 49.349084, lng: 19.153844 },
    { lat: 49.348813, lng: 19.153403 },
    { lat: 49.348445, lng: 19.1528 },
    { lat: 49.348095, lng: 19.152227 },
    { lat: 49.347867, lng: 19.151818 },
    { lat: 49.347281, lng: 19.150891 },
    { lat: 49.34703, lng: 19.150544 },
    { lat: 49.346825, lng: 19.150365 },
    { lat: 49.345788, lng: 19.149663 },
    { lat: 49.345692, lng: 19.149536 },
    { lat: 49.345391, lng: 19.149236 },
    { lat: 49.345038, lng: 19.148835 },
    { lat: 49.344937, lng: 19.148675 },
    { lat: 49.344696, lng: 19.148606 },
    { lat: 49.344518, lng: 19.148671 },
    { lat: 49.344326, lng: 19.148844 },
    { lat: 49.344111, lng: 19.148765 },
    { lat: 49.343916, lng: 19.148521 },
    { lat: 49.343663, lng: 19.148264 },
    { lat: 49.343629, lng: 19.148228 },
    { lat: 49.343452, lng: 19.148049 },
    { lat: 49.343023, lng: 19.147502 },
    { lat: 49.342914, lng: 19.147486 },
    { lat: 49.342776, lng: 19.147466 },
    { lat: 49.342714, lng: 19.147457 },
    { lat: 49.342604, lng: 19.147301 },
    { lat: 49.342443, lng: 19.14734 },
    { lat: 49.342428, lng: 19.147328 },
    { lat: 49.342284, lng: 19.147207 },
    { lat: 49.342112, lng: 19.147132 },
    { lat: 49.341933, lng: 19.147106 },
    { lat: 49.341835, lng: 19.147091 },
    { lat: 49.341513, lng: 19.147043 },
    { lat: 49.341318, lng: 19.147014 },
    { lat: 49.34108, lng: 19.146911 },
    { lat: 49.340679, lng: 19.146828 },
    { lat: 49.340334, lng: 19.146756 },
    { lat: 49.340233, lng: 19.146781 },
    { lat: 49.339952, lng: 19.146851 },
    { lat: 49.339788, lng: 19.146891 },
    { lat: 49.339739, lng: 19.146903 },
    { lat: 49.339612, lng: 19.146951 },
    { lat: 49.339583, lng: 19.146972 },
    { lat: 49.339334, lng: 19.14716 },
    { lat: 49.339329, lng: 19.147163 },
    { lat: 49.33931, lng: 19.147175 },
    { lat: 49.339302, lng: 19.14718 },
    { lat: 49.339061, lng: 19.147331 },
    { lat: 49.339044, lng: 19.147336 },
    { lat: 49.338986, lng: 19.147354 },
    { lat: 49.33886, lng: 19.147395 },
    { lat: 49.33876, lng: 19.147427 },
    { lat: 49.338702, lng: 19.14739 },
    { lat: 49.338572, lng: 19.147308 },
    { lat: 49.338528, lng: 19.147296 },
    { lat: 49.33813, lng: 19.147187 },
    { lat: 49.338123, lng: 19.147182 },
    { lat: 49.337851, lng: 19.146999 },
    { lat: 49.337404, lng: 19.146546 },
    { lat: 49.337309, lng: 19.146485 },
    { lat: 49.33726, lng: 19.146455 },
    { lat: 49.337197, lng: 19.146415 },
    { lat: 49.33715, lng: 19.146385 },
    { lat: 49.337091, lng: 19.146348 },
    { lat: 49.337044, lng: 19.146318 },
    { lat: 49.336795, lng: 19.146161 },
    { lat: 49.336605, lng: 19.145928 },
    { lat: 49.336514, lng: 19.145891 },
    { lat: 49.33649, lng: 19.145881 },
    { lat: 49.336344, lng: 19.14582 },
    { lat: 49.336102, lng: 19.14572 },
    { lat: 49.335883, lng: 19.145629 },
    { lat: 49.335824, lng: 19.145604 },
    { lat: 49.335725, lng: 19.145597 },
    { lat: 49.335589, lng: 19.145586 },
    { lat: 49.335513, lng: 19.145581 },
    { lat: 49.335509, lng: 19.145546 },
    { lat: 49.335227, lng: 19.145572 },
    { lat: 49.335088, lng: 19.145555 },
    { lat: 49.334793, lng: 19.145688 },
    { lat: 49.334519, lng: 19.145692 },
    { lat: 49.334385, lng: 19.145743 },
    { lat: 49.333879, lng: 19.146161 },
    { lat: 49.333803, lng: 19.146182 },
    { lat: 49.333364, lng: 19.146303 },
    { lat: 49.333338, lng: 19.146323 },
    { lat: 49.332702, lng: 19.146833 },
    { lat: 49.332452, lng: 19.147241 },
    { lat: 49.332209, lng: 19.147635 },
    { lat: 49.332174, lng: 19.147701 },
    { lat: 49.332102, lng: 19.14784 },
    { lat: 49.332091, lng: 19.147862 },
    { lat: 49.33209, lng: 19.147863 },
    { lat: 49.331837, lng: 19.14835 },
    { lat: 49.331557, lng: 19.148888 },
    { lat: 49.331467, lng: 19.14906 },
    { lat: 49.330923, lng: 19.149874 },
    { lat: 49.330725, lng: 19.150642 },
    { lat: 49.3307, lng: 19.15074 },
    { lat: 49.330678, lng: 19.150822 },
    { lat: 49.330636, lng: 19.150985 },
    { lat: 49.330441, lng: 19.151744 },
    { lat: 49.330358, lng: 19.15207 },
    { lat: 49.330307, lng: 19.152464 },
    { lat: 49.330305, lng: 19.152702 },
    { lat: 49.330304, lng: 19.152858 },
    { lat: 49.3303, lng: 19.153398 },
    { lat: 49.330297, lng: 19.153502 },
    { lat: 49.330296, lng: 19.153735 },
    { lat: 49.330287, lng: 19.15442 },
    { lat: 49.330285, lng: 19.15473 },
    { lat: 49.330282, lng: 19.154984 },
    { lat: 49.330204, lng: 19.155434 },
    { lat: 49.330173, lng: 19.155512 },
    { lat: 49.329795, lng: 19.156399 },
    { lat: 49.329696, lng: 19.156634 },
    { lat: 49.329569, lng: 19.15699 },
    { lat: 49.329431, lng: 19.157375 },
    { lat: 49.329469, lng: 19.157384 },
    { lat: 49.33005, lng: 19.157524 },
    { lat: 49.33009, lng: 19.157534 },
    { lat: 49.330274, lng: 19.157578 },
    { lat: 49.330553, lng: 19.157622 },
    { lat: 49.330823, lng: 19.157841 },
    { lat: 49.330898, lng: 19.158018 },
    { lat: 49.330939, lng: 19.158204 },
    { lat: 49.331055, lng: 19.158363 },
    { lat: 49.331125, lng: 19.158483 },
    { lat: 49.33133, lng: 19.159035 },
    { lat: 49.331387, lng: 19.159123 },
    { lat: 49.33144, lng: 19.159313 },
    { lat: 49.331448, lng: 19.159489 },
    { lat: 49.3317, lng: 19.159941 },
    { lat: 49.332084, lng: 19.1605 },
    { lat: 49.332277, lng: 19.160633 },
    { lat: 49.332402, lng: 19.160747 },
    { lat: 49.332636, lng: 19.160802 },
    { lat: 49.332835, lng: 19.160846 },
    { lat: 49.332852, lng: 19.160849 },
    { lat: 49.332898, lng: 19.16086 },
    { lat: 49.332973, lng: 19.160871 },
    { lat: 49.332964, lng: 19.160897 },
    { lat: 49.332956, lng: 19.16092 },
    { lat: 49.332816, lng: 19.161001 },
    { lat: 49.332637, lng: 19.161284 },
    { lat: 49.332497, lng: 19.161507 },
    { lat: 49.332198, lng: 19.161779 },
    { lat: 49.33185, lng: 19.162701 },
    { lat: 49.331709, lng: 19.163557 },
    { lat: 49.331698, lng: 19.16362 },
    { lat: 49.331627, lng: 19.16405 },
    { lat: 49.331608, lng: 19.164177 },
    { lat: 49.331334, lng: 19.164215 },
    { lat: 49.331059, lng: 19.164057 },
    { lat: 49.330771, lng: 19.164044 },
    { lat: 49.330604, lng: 19.164133 },
    { lat: 49.330475, lng: 19.164258 },
    { lat: 49.330447, lng: 19.164215 },
    { lat: 49.330421, lng: 19.164053 },
    { lat: 49.330387, lng: 19.164011 },
    { lat: 49.330001, lng: 19.163979 },
    { lat: 49.329643, lng: 19.163764 },
    { lat: 49.329402, lng: 19.163619 },
    { lat: 49.329296, lng: 19.163619 },
    { lat: 49.329226, lng: 19.163709 },
    { lat: 49.329154, lng: 19.163818 },
    { lat: 49.329075, lng: 19.163821 },
    { lat: 49.329065, lng: 19.163821 },
    { lat: 49.328718, lng: 19.163544 },
    { lat: 49.328578, lng: 19.163518 },
    { lat: 49.32851, lng: 19.163582 },
    { lat: 49.328495, lng: 19.163755 },
    { lat: 49.328435, lng: 19.163923 },
    { lat: 49.328345, lng: 19.163961 },
    { lat: 49.328134, lng: 19.163691 },
    { lat: 49.328091, lng: 19.163697 },
    { lat: 49.328099, lng: 19.163827 },
    { lat: 49.328071, lng: 19.163932 },
    { lat: 49.328041, lng: 19.163948 },
    { lat: 49.327895, lng: 19.163844 },
    { lat: 49.327678, lng: 19.163686 },
    { lat: 49.327597, lng: 19.163648 },
    { lat: 49.327465, lng: 19.163686 },
    { lat: 49.327305, lng: 19.163662 },
    { lat: 49.327235, lng: 19.163781 },
    { lat: 49.326966, lng: 19.163728 },
    { lat: 49.326872, lng: 19.163871 },
    { lat: 49.326691, lng: 19.163845 },
    { lat: 49.326535, lng: 19.163857 },
    { lat: 49.326441, lng: 19.163953 },
    { lat: 49.326352, lng: 19.164006 },
    { lat: 49.326245, lng: 19.164033 },
    { lat: 49.326045, lng: 19.163982 },
    { lat: 49.32596, lng: 19.164006 },
    { lat: 49.325701, lng: 19.164212 },
    { lat: 49.32547, lng: 19.164256 },
    { lat: 49.325298, lng: 19.164427 },
    { lat: 49.325206, lng: 19.164481 },
    { lat: 49.325068, lng: 19.164547 },
    { lat: 49.324751, lng: 19.164529 },
    { lat: 49.3245, lng: 19.164634 },
    { lat: 49.324224, lng: 19.164574 },
    { lat: 49.324109, lng: 19.16459 },
    { lat: 49.323971, lng: 19.164723 },
    { lat: 49.323933, lng: 19.164837 },
    { lat: 49.323898, lng: 19.164932 },
    { lat: 49.323836, lng: 19.165073 },
    { lat: 49.323713, lng: 19.165081 },
    { lat: 49.323635, lng: 19.165008 },
    { lat: 49.323564, lng: 19.164942 },
    { lat: 49.323549, lng: 19.164929 },
    { lat: 49.323381, lng: 19.164952 },
    { lat: 49.323267, lng: 19.165097 },
    { lat: 49.32318, lng: 19.165211 },
    { lat: 49.323103, lng: 19.16526 },
    { lat: 49.322691, lng: 19.165271 },
    { lat: 49.322428, lng: 19.165184 },
    { lat: 49.322367, lng: 19.165194 },
    { lat: 49.322161, lng: 19.165222 },
    { lat: 49.321438, lng: 19.165383 },
    { lat: 49.32094, lng: 19.165424 },
    { lat: 49.320484, lng: 19.165409 },
    { lat: 49.320041, lng: 19.165481 },
    { lat: 49.319417, lng: 19.165663 },
    { lat: 49.319623, lng: 19.167232 },
    { lat: 49.319851, lng: 19.168434 },
    { lat: 49.319747, lng: 19.169324 },
    { lat: 49.319622, lng: 19.170243 },
    { lat: 49.319394, lng: 19.171303 },
    { lat: 49.318897, lng: 19.172418 },
    { lat: 49.318623, lng: 19.172639 },
    { lat: 49.318106, lng: 19.173085 },
    { lat: 49.317632, lng: 19.1734 },
    { lat: 49.317354, lng: 19.17365 },
    { lat: 49.316898, lng: 19.177341 },
    { lat: 49.316981, lng: 19.17936 },
    { lat: 49.316941, lng: 19.180648 },
    { lat: 49.317176, lng: 19.183339 },
    { lat: 49.317018, lng: 19.186856 },
    { lat: 49.317014, lng: 19.187053 },
    { lat: 49.317021, lng: 19.187161 },
    { lat: 49.316884, lng: 19.189618 },
    { lat: 49.317455, lng: 19.193081 },
    { lat: 49.317197, lng: 19.197775 },
    { lat: 49.317436, lng: 19.198216 },
    { lat: 49.318136, lng: 19.199129 },
    { lat: 49.318334, lng: 19.199533 },
    { lat: 49.319345, lng: 19.201016 },
    { lat: 49.319802, lng: 19.201801 },
    { lat: 49.320051, lng: 19.202185 },
    { lat: 49.320294, lng: 19.202618 },
    { lat: 49.321372, lng: 19.202609 },
    { lat: 49.321703, lng: 19.203579 },
    { lat: 49.321733, lng: 19.203767 },
    { lat: 49.321816, lng: 19.20406 },
    { lat: 49.321903, lng: 19.204802 },
    { lat: 49.321863, lng: 19.205773 },
    { lat: 49.321964, lng: 19.206349 },
    { lat: 49.321814, lng: 19.206958 },
    { lat: 49.321819, lng: 19.207976 },
    { lat: 49.321906, lng: 19.208698 },
    { lat: 49.322502, lng: 19.21035 },
    { lat: 49.322604, lng: 19.210594 },
    { lat: 49.322721, lng: 19.21098 },
    { lat: 49.32302, lng: 19.211464 },
    { lat: 49.323424, lng: 19.212156 },
    { lat: 49.323683, lng: 19.212612 },
    { lat: 49.323945, lng: 19.21346 },
    { lat: 49.324106, lng: 19.214294 },
    { lat: 49.324138, lng: 19.214936 },
    { lat: 49.324134, lng: 19.215693 },
    { lat: 49.323712, lng: 19.216564 },
    { lat: 49.323233, lng: 19.217331 },
    { lat: 49.322495, lng: 19.217956 },
    { lat: 49.321725, lng: 19.219159 },
    { lat: 49.321254, lng: 19.220856 },
    { lat: 49.321216, lng: 19.22103 },
    { lat: 49.321115, lng: 19.221581 },
    { lat: 49.320995, lng: 19.221977 },
    { lat: 49.320938, lng: 19.22215 },
    { lat: 49.320743, lng: 19.222972 },
    { lat: 49.320597, lng: 19.223504 },
    { lat: 49.320496, lng: 19.22459 },
    { lat: 49.320368, lng: 19.22578 },
    { lat: 49.320391, lng: 19.228046 },
    { lat: 49.3208, lng: 19.22897 },
    { lat: 49.321117, lng: 19.229736 },
    { lat: 49.321454, lng: 19.230577 },
    { lat: 49.321021, lng: 19.231318 },
    { lat: 49.320516, lng: 19.232395 },
    { lat: 49.320167, lng: 19.233406 },
    { lat: 49.320172, lng: 19.234823 },
    { lat: 49.320279, lng: 19.236665 },
    { lat: 49.320123, lng: 19.238209 },
    { lat: 49.3210091, lng: 19.238217 },
    { lat: 49.322865, lng: 19.2382093 },
    { lat: 49.3230908, lng: 19.2382643 },
    { lat: 49.3266308, lng: 19.2391126 },
    { lat: 49.3281031, lng: 19.2398789 },
    { lat: 49.3283232, lng: 19.2399586 },
    { lat: 49.3284693, lng: 19.2399719 },
    { lat: 49.3296614, lng: 19.2400151 },
    { lat: 49.33078, lng: 19.2412717 },
    { lat: 49.3319203, lng: 19.2426838 },
    { lat: 49.3325936, lng: 19.2434985 },
    { lat: 49.3340146, lng: 19.2452523 },
    { lat: 49.3342266, lng: 19.2454551 },
    { lat: 49.3372937, lng: 19.2471878 },
    { lat: 49.3382062, lng: 19.2487655 },
    { lat: 49.3397093, lng: 19.2498173 },
    { lat: 49.3410674, lng: 19.2492523 },
    { lat: 49.3421145, lng: 19.2496333 },
    { lat: 49.3450369, lng: 19.2506515 },
    { lat: 49.3458736, lng: 19.2510684 },
    { lat: 49.3467593, lng: 19.2525313 },
    { lat: 49.3472867, lng: 19.2542312 },
    { lat: 49.3473032, lng: 19.2542698 },
    { lat: 49.3473122, lng: 19.2542894 },
    { lat: 49.3473744, lng: 19.254435 },
    { lat: 49.3474253, lng: 19.2560476 },
    { lat: 49.3474649, lng: 19.2584397 },
    { lat: 49.3474311, lng: 19.2601188 },
    { lat: 49.3474576, lng: 19.2618856 },
    { lat: 49.3477574, lng: 19.2639667 },
    { lat: 49.3481993, lng: 19.267026 },
    { lat: 49.348216, lng: 19.2679658 },
    { lat: 49.3482691, lng: 19.2711245 },
    { lat: 49.3485606, lng: 19.272492 },
    { lat: 49.3494475, lng: 19.2764002 },
    { lat: 49.3504154, lng: 19.2786547 },
    { lat: 49.3504871, lng: 19.2788108 },
    { lat: 49.351178, lng: 19.2785565 },
    { lat: 49.3512035, lng: 19.2785222 },
    { lat: 49.3512378, lng: 19.2784631 },
    { lat: 49.3512727, lng: 19.2784115 },
    { lat: 49.3513134, lng: 19.2783448 },
    { lat: 49.351366, lng: 19.2782634 },
    { lat: 49.3514225, lng: 19.2781747 },
    { lat: 49.3514793, lng: 19.2780888 },
    { lat: 49.3515142, lng: 19.2780369 },
    { lat: 49.3515966, lng: 19.2779218 },
    { lat: 49.3516703, lng: 19.2778135 },
    { lat: 49.3517349, lng: 19.2777278 },
    { lat: 49.3518147, lng: 19.2776275 },
    { lat: 49.3518967, lng: 19.2775295 },
    { lat: 49.3519487, lng: 19.2774611 },
    { lat: 49.351998, lng: 19.2774047 },
    { lat: 49.3520478, lng: 19.2773388 },
    { lat: 49.3521467, lng: 19.2772135 },
    { lat: 49.3522073, lng: 19.2771377 },
    { lat: 49.3522455, lng: 19.2770865 },
    { lat: 49.3522709, lng: 19.2770544 },
    { lat: 49.3523573, lng: 19.2769493 },
    { lat: 49.3524213, lng: 19.2768739 },
    { lat: 49.3524834, lng: 19.2768052 },
    { lat: 49.3526241, lng: 19.2766344 },
    { lat: 49.3527189, lng: 19.276522 },
    { lat: 49.352805, lng: 19.2764119 },
    { lat: 49.3529161, lng: 19.2762849 },
    { lat: 49.3529777, lng: 19.2762293 },
    { lat: 49.3530394, lng: 19.2761761 },
    { lat: 49.353136, lng: 19.2760714 },
    { lat: 49.3532083, lng: 19.2759831 },
    { lat: 49.3532853, lng: 19.2758932 },
    { lat: 49.3534045, lng: 19.2757487 },
    { lat: 49.3534696, lng: 19.2756728 },
    { lat: 49.3535156, lng: 19.275617 },
    { lat: 49.3536221, lng: 19.2754873 },
    { lat: 49.3538279, lng: 19.2753108 },
    { lat: 49.353895, lng: 19.2752556 },
    { lat: 49.3539588, lng: 19.275246 },
    { lat: 49.3540324, lng: 19.2752306 },
    { lat: 49.354125, lng: 19.2752119 },
    { lat: 49.3541589, lng: 19.2752085 },
    { lat: 49.3541958, lng: 19.2752048 },
    { lat: 49.354253, lng: 19.2751966 },
    { lat: 49.3543089, lng: 19.2751893 },
    { lat: 49.3543673, lng: 19.2751861 },
    { lat: 49.3544285, lng: 19.2751684 },
    { lat: 49.3544831, lng: 19.2751529 },
    { lat: 49.3545739, lng: 19.2751218 },
    { lat: 49.3546048, lng: 19.275105 },
    { lat: 49.3546526, lng: 19.2751167 },
    { lat: 49.3547416, lng: 19.2751411 },
    { lat: 49.3548127, lng: 19.2751668 },
    { lat: 49.354883, lng: 19.2751995 },
    { lat: 49.3549304, lng: 19.2752242 },
    { lat: 49.3550385, lng: 19.2752726 },
    { lat: 49.3551307, lng: 19.2753133 },
    { lat: 49.3552187, lng: 19.2753371 },
    { lat: 49.3552652, lng: 19.2753468 },
    { lat: 49.3553478, lng: 19.2753755 },
    { lat: 49.3554429, lng: 19.2753588 },
    { lat: 49.3555467, lng: 19.2753429 },
    { lat: 49.3556203, lng: 19.2753257 },
    { lat: 49.3556915, lng: 19.2753037 },
    { lat: 49.355804, lng: 19.2752553 },
    { lat: 49.3560758, lng: 19.2751405 },
    { lat: 49.3564442, lng: 19.2749918 },
    { lat: 49.3564686, lng: 19.2749825 },
    { lat: 49.3565318, lng: 19.2749625 },
    { lat: 49.3572137, lng: 19.2750836 },
    { lat: 49.3574182, lng: 19.2751194 },
    { lat: 49.3574958, lng: 19.2751404 },
    { lat: 49.3576145, lng: 19.275219 },
    { lat: 49.3577323, lng: 19.2753278 },
    { lat: 49.357881, lng: 19.2754656 },
    { lat: 49.3579631, lng: 19.2755324 },
    { lat: 49.3580456, lng: 19.2756096 },
    { lat: 49.3581388, lng: 19.2756972 },
    { lat: 49.3582984, lng: 19.2758103 },
    { lat: 49.3584148, lng: 19.2758859 },
    { lat: 49.3584918, lng: 19.2759399 },
    { lat: 49.3585558, lng: 19.2759859 },
    { lat: 49.35862, lng: 19.276029 },
    { lat: 49.3587024, lng: 19.2760835 },
    { lat: 49.358776, lng: 19.2761375 },
    { lat: 49.3588468, lng: 19.2761806 },
    { lat: 49.3589148, lng: 19.2762341 },
    { lat: 49.3589905, lng: 19.2762882 },
    { lat: 49.3590616, lng: 19.2763367 },
    { lat: 49.3591293, lng: 19.2763852 },
    { lat: 49.3592182, lng: 19.276452 },
    { lat: 49.3593118, lng: 19.2765298 },
    { lat: 49.3594005, lng: 19.2765919 },
    { lat: 49.3594469, lng: 19.2766192 },
    { lat: 49.3595039, lng: 19.2766114 },
    { lat: 49.359621, lng: 19.2765865 },
    { lat: 49.35977, lng: 19.2764965 },
    { lat: 49.3598566, lng: 19.2764442 },
    { lat: 49.3598894, lng: 19.2764684 },
    { lat: 49.3599878, lng: 19.2765211 },
    { lat: 49.3600783, lng: 19.2765775 },
    { lat: 49.3601326, lng: 19.276608 },
    { lat: 49.3602023, lng: 19.2766487 },
    { lat: 49.3602784, lng: 19.2766847 },
    { lat: 49.3603723, lng: 19.2767169 },
    { lat: 49.3604332, lng: 19.2767423 },
    { lat: 49.3604473, lng: 19.2767559 },
    { lat: 49.3606127, lng: 19.2766764 },
    { lat: 49.3610354, lng: 19.2765126 },
    { lat: 49.3616072, lng: 19.2763347 },
    { lat: 49.361705, lng: 19.2763058 },
    { lat: 49.361802, lng: 19.2763047 },
    { lat: 49.362097, lng: 19.27571 },
    { lat: 49.362388, lng: 19.2751197 },
    { lat: 49.3627518, lng: 19.2743557 },
    { lat: 49.3628541, lng: 19.2741375 },
    { lat: 49.3629883, lng: 19.2738572 },
    { lat: 49.3630343, lng: 19.2737555 },
    { lat: 49.3631325, lng: 19.2735468 },
    { lat: 49.3632126, lng: 19.2733784 },
    { lat: 49.3633455, lng: 19.2730932 },
    { lat: 49.3634124, lng: 19.2727723 },
    { lat: 49.3634398, lng: 19.2726387 },
    { lat: 49.3635535, lng: 19.2723476 },
    { lat: 49.3636097, lng: 19.2722032 },
    { lat: 49.3637971, lng: 19.2718088 },
    { lat: 49.363848, lng: 19.2717171 },
    { lat: 49.363894, lng: 19.2716154 },
    { lat: 49.3639444, lng: 19.2715136 },
    { lat: 49.3639951, lng: 19.271397 },
    { lat: 49.3640473, lng: 19.2712831 },
    { lat: 49.3641003, lng: 19.2711662 },
    { lat: 49.364145, lng: 19.2710643 },
    { lat: 49.364254, lng: 19.2708233 },
    { lat: 49.3643146, lng: 19.2706972 },
    { lat: 49.3643545, lng: 19.2706128 },
    { lat: 49.3643863, lng: 19.2705456 },
    { lat: 49.364416, lng: 19.2704863 },
    { lat: 49.3644537, lng: 19.2703993 },
    { lat: 49.364495, lng: 19.2703198 },
    { lat: 49.3645278, lng: 19.2702482 },
    { lat: 49.3645658, lng: 19.2701738 },
    { lat: 49.3645932, lng: 19.2701093 },
    { lat: 49.3646424, lng: 19.2700076 },
    { lat: 49.3646701, lng: 19.2699478 },
    { lat: 49.3646983, lng: 19.2699009 },
    { lat: 49.3647227, lng: 19.269849 },
    { lat: 49.3647674, lng: 19.2697699 },
    { lat: 49.3648009, lng: 19.2697155 },
    { lat: 49.3648278, lng: 19.2696637 },
    { lat: 49.364858, lng: 19.269609 },
    { lat: 49.3648869, lng: 19.2695577 },
    { lat: 49.3649087, lng: 19.2695177 },
    { lat: 49.3649273, lng: 19.269483 },
    { lat: 49.3649789, lng: 19.2693815 },
    { lat: 49.3649893, lng: 19.2693619 },
    { lat: 49.3650916, lng: 19.2694763 },
    { lat: 49.3651949, lng: 19.2694691 },
    { lat: 49.3652665, lng: 19.2695053 },
    { lat: 49.3655301, lng: 19.269788 },
    { lat: 49.3655777, lng: 19.2697913 },
    { lat: 49.3655946, lng: 19.2698133 },
    { lat: 49.3656235, lng: 19.2699005 },
    { lat: 49.3656914, lng: 19.2699088 },
    { lat: 49.3657806, lng: 19.270007 },
    { lat: 49.365922, lng: 19.2702339 },
    { lat: 49.3660159, lng: 19.2702375 },
    { lat: 49.3660685, lng: 19.2702993 },
    { lat: 49.3660984, lng: 19.2703133 },
    { lat: 49.3661755, lng: 19.2703253 },
    { lat: 49.3662434, lng: 19.2703073 },
    { lat: 49.3664032, lng: 19.2704695 },
    { lat: 49.3665059, lng: 19.2704953 },
    { lat: 49.3665691, lng: 19.2706376 },
    { lat: 49.3666402, lng: 19.2706431 },
    { lat: 49.3666956, lng: 19.2705964 },
    { lat: 49.3667451, lng: 19.2706195 },
    { lat: 49.3667639, lng: 19.2706611 },
    { lat: 49.3667678, lng: 19.2707182 },
    { lat: 49.366865, lng: 19.2708415 },
    { lat: 49.3669596, lng: 19.2708389 },
    { lat: 49.3670698, lng: 19.2707616 },
    { lat: 49.3671666, lng: 19.270762 },
    { lat: 49.367272, lng: 19.2707983 },
    { lat: 49.3673381, lng: 19.270838 },
    { lat: 49.3675308, lng: 19.2710523 },
    { lat: 49.3676889, lng: 19.2711529 },
    { lat: 49.3677382, lng: 19.2711463 },
    { lat: 49.3677795, lng: 19.271162 },
    { lat: 49.3678114, lng: 19.2712409 },
    { lat: 49.3678751, lng: 19.2712757 },
    { lat: 49.3679585, lng: 19.2713011 },
    { lat: 49.3681078, lng: 19.2714302 },
    { lat: 49.3681237, lng: 19.2714816 },
    { lat: 49.3682851, lng: 19.2716039 },
    { lat: 49.3683288, lng: 19.2718135 },
    { lat: 49.3683917, lng: 19.2717844 },
    { lat: 49.3684793, lng: 19.2718998 },
    { lat: 49.368656, lng: 19.2719401 },
    { lat: 49.3689759, lng: 19.272196 },
    { lat: 49.3691426, lng: 19.2722913 },
    { lat: 49.3693001, lng: 19.272405 },
    { lat: 49.369342, lng: 19.2724571 },
    { lat: 49.3694472, lng: 19.2726042 },
    { lat: 49.3697268, lng: 19.272752 },
    { lat: 49.3699023, lng: 19.2731462 },
    { lat: 49.3701846, lng: 19.2732073 },
    { lat: 49.3702121, lng: 19.2733337 },
    { lat: 49.3704843, lng: 19.2736104 },
    { lat: 49.370639, lng: 19.2738496 },
    { lat: 49.3706698, lng: 19.2738382 },
    { lat: 49.3707618, lng: 19.2737798 },
    { lat: 49.3708611, lng: 19.2738082 },
    { lat: 49.37092, lng: 19.2740295 },
    { lat: 49.3710094, lng: 19.2741761 },
    { lat: 49.371147, lng: 19.2742743 },
    { lat: 49.3713697, lng: 19.274528 },
    { lat: 49.3717618, lng: 19.2747628 },
    { lat: 49.3718345, lng: 19.2747735 },
    { lat: 49.3719765, lng: 19.2749378 },
    { lat: 49.3720942, lng: 19.2749055 },
    { lat: 49.3721437, lng: 19.2747835 },
    { lat: 49.3721938, lng: 19.27477 },
    { lat: 49.3722749, lng: 19.274817 },
    { lat: 49.3723063, lng: 19.2749057 },
    { lat: 49.3723956, lng: 19.2749377 },
    { lat: 49.3725746, lng: 19.2749124 },
    { lat: 49.3727183, lng: 19.2749944 },
    { lat: 49.3728713, lng: 19.2751329 },
    { lat: 49.3731228, lng: 19.2753461 },
    { lat: 49.3732526, lng: 19.2755638 },
    { lat: 49.3733164, lng: 19.2756044 },
    { lat: 49.37338, lng: 19.2757113 },
    { lat: 49.3735399, lng: 19.2757557 },
    { lat: 49.3735764, lng: 19.2756449 },
    { lat: 49.3737401, lng: 19.2757439 },
    { lat: 49.3737602, lng: 19.2757413 },
    { lat: 49.3739086, lng: 19.2757327 },
    { lat: 49.3741663, lng: 19.2759185 },
    { lat: 49.3743463, lng: 19.2757907 },
    { lat: 49.374489, lng: 19.2758315 },
    { lat: 49.3746349, lng: 19.2757451 },
    { lat: 49.3747281, lng: 19.2757395 },
    { lat: 49.3748384, lng: 19.2758787 },
    { lat: 49.3749015, lng: 19.2758793 },
    { lat: 49.3749574, lng: 19.2758235 },
    { lat: 49.3749436, lng: 19.2756525 },
    { lat: 49.3749174, lng: 19.2755235 },
    { lat: 49.3749307, lng: 19.275476 },
    { lat: 49.3750625, lng: 19.2754509 },
    { lat: 49.3751747, lng: 19.2752218 },
    { lat: 49.375513, lng: 19.2747534 },
    { lat: 49.3755238, lng: 19.2747655 },
    { lat: 49.3755531, lng: 19.2747214 },
    { lat: 49.3756017, lng: 19.2746523 },
    { lat: 49.3753063, lng: 19.2740809 },
    { lat: 49.3752466, lng: 19.2739114 },
    { lat: 49.3750487, lng: 19.273072 },
    { lat: 49.3749326, lng: 19.2725963 },
    { lat: 49.3748179, lng: 19.2720301 },
    { lat: 49.3746625, lng: 19.2712745 },
    { lat: 49.3746185, lng: 19.2710339 },
    { lat: 49.3744638, lng: 19.2703426 },
    { lat: 49.3743481, lng: 19.2697827 },
    { lat: 49.3742659, lng: 19.2687637 },
    { lat: 49.3743943, lng: 19.2684282 },
    { lat: 49.3745216, lng: 19.2675254 },
    { lat: 49.3745837, lng: 19.2671272 },
    { lat: 49.3746492, lng: 19.2665127 },
    { lat: 49.3746089, lng: 19.266094 },
    { lat: 49.3745621, lng: 19.2659629 },
    { lat: 49.3744864, lng: 19.2657221 },
    { lat: 49.3744724, lng: 19.2650463 },
    { lat: 49.3744258, lng: 19.2648255 },
    { lat: 49.3742362, lng: 19.2642246 },
    { lat: 49.3740518, lng: 19.263383 },
    { lat: 49.3739447, lng: 19.2627892 },
    { lat: 49.3738871, lng: 19.2624083 },
    { lat: 49.3738426, lng: 19.2621123 },
    { lat: 49.3737994, lng: 19.2616568 },
    { lat: 49.373794, lng: 19.2610907 },
    { lat: 49.3738427, lng: 19.2604581 },
    { lat: 49.373945, lng: 19.2597643 },
    { lat: 49.3741429, lng: 19.2586673 },
    { lat: 49.3742346, lng: 19.2582285 },
    { lat: 49.3744114, lng: 19.2576316 },
    { lat: 49.3745697, lng: 19.2569332 },
    { lat: 49.3746205, lng: 19.2566766 },
    { lat: 49.3747044, lng: 19.2561178 },
    { lat: 49.3748626, lng: 19.255017 },
    { lat: 49.3751843, lng: 19.2532288 },
    { lat: 49.3751839, lng: 19.2531963 },
    { lat: 49.3752529, lng: 19.2528945 },
    { lat: 49.3753323, lng: 19.2525044 },
    { lat: 49.3753311, lng: 19.2524789 },
    { lat: 49.375401, lng: 19.2520534 },
    { lat: 49.3753752, lng: 19.2515608 },
    { lat: 49.3753818, lng: 19.2512244 },
    { lat: 49.3753579, lng: 19.2508433 },
    { lat: 49.3753671, lng: 19.2504655 },
    { lat: 49.375438, lng: 19.2500666 },
    { lat: 49.375599, lng: 19.2499411 },
    { lat: 49.3757653, lng: 19.2499066 },
    { lat: 49.3759469, lng: 19.2499362 },
    { lat: 49.376105, lng: 19.2499203 },
    { lat: 49.376285, lng: 19.24991 },
    { lat: 49.3764511, lng: 19.2498288 },
    { lat: 49.3766576, lng: 19.249643 },
    { lat: 49.3767858, lng: 19.2493548 },
    { lat: 49.3768311, lng: 19.2490521 },
    { lat: 49.3768503, lng: 19.2487914 },
    { lat: 49.3768489, lng: 19.2487163 },
    { lat: 49.3767628, lng: 19.2483516 },
    { lat: 49.3766968, lng: 19.2479141 },
    { lat: 49.3766198, lng: 19.2475527 },
    { lat: 49.3765709, lng: 19.2472602 },
    { lat: 49.3765107, lng: 19.2468951 },
    { lat: 49.3764793, lng: 19.2465677 },
    { lat: 49.376473, lng: 19.2462226 },
    { lat: 49.3764965, lng: 19.2457712 },
    { lat: 49.3765419, lng: 19.2455194 },
    { lat: 49.3766505, lng: 19.2452223 },
    { lat: 49.3767881, lng: 19.244988 },
    { lat: 49.376956, lng: 19.2446998 },
    { lat: 49.377101, lng: 19.2444575 },
    { lat: 49.3772785, lng: 19.2441642 },
    { lat: 49.3773982, lng: 19.2439555 },
    { lat: 49.377449, lng: 19.2438377 },
    { lat: 49.3775706, lng: 19.2436251 },
    { lat: 49.3777511, lng: 19.2433948 },
    { lat: 49.3777839, lng: 19.2433484 },
    { lat: 49.3780801, lng: 19.2430132 },
    { lat: 49.3783581, lng: 19.242722 },
    { lat: 49.3785775, lng: 19.2425007 },
    { lat: 49.3787019, lng: 19.2423222 },
    { lat: 49.3789262, lng: 19.241969 },
    { lat: 49.3789944, lng: 19.2417465 },
    { lat: 49.3790385, lng: 19.2413491 },
    { lat: 49.3791179, lng: 19.2408599 },
    { lat: 49.3792243, lng: 19.2403536 },
    { lat: 49.3793386, lng: 19.2400076 },
    { lat: 49.379533, lng: 19.2397377 },
    { lat: 49.3799347, lng: 19.2393968 },
    { lat: 49.3801766, lng: 19.2392449 },
    { lat: 49.3804459, lng: 19.2391521 },
    { lat: 49.3808282, lng: 19.2389878 },
    { lat: 49.3808325, lng: 19.2389851 },
    { lat: 49.3812149, lng: 19.2387574 },
    { lat: 49.3813884, lng: 19.2385577 },
    { lat: 49.3816163, lng: 19.2381748 },
    { lat: 49.3818034, lng: 19.2378204 },
    { lat: 49.3819708, lng: 19.2374273 },
    { lat: 49.3821135, lng: 19.2370967 },
    { lat: 49.3821678, lng: 19.2369306 },
    { lat: 49.382314, lng: 19.2365 },
    { lat: 49.3824416, lng: 19.2360325 },
    { lat: 49.3825288, lng: 19.2356632 },
    { lat: 49.3825746, lng: 19.2353961 },
    { lat: 49.3826696, lng: 19.2354528 },
    { lat: 49.3827061, lng: 19.2354721 },
    { lat: 49.3827113, lng: 19.2354749 },
    { lat: 49.382768, lng: 19.2355054 },
    { lat: 49.3827831, lng: 19.2355142 },
    { lat: 49.3828103, lng: 19.2353777 },
    { lat: 49.382894, lng: 19.2344896 },
    { lat: 49.3828956, lng: 19.2343799 },
    { lat: 49.3829005, lng: 19.2341514 },
    { lat: 49.3829206, lng: 19.23351 },
    { lat: 49.3830275, lng: 19.2330585 },
    { lat: 49.383131, lng: 19.2327752 },
    { lat: 49.3835596, lng: 19.2310161 },
    { lat: 49.3836519, lng: 19.2303058 },
    { lat: 49.3836852, lng: 19.2293287 },
    { lat: 49.3836683, lng: 19.2291425 },
    { lat: 49.3835481, lng: 19.2279243 },
    { lat: 49.383464, lng: 19.2270395 },
    { lat: 49.3834169, lng: 19.2265991 },
    { lat: 49.3833534, lng: 19.2261914 },
    { lat: 49.3831317, lng: 19.225512 },
    { lat: 49.3829173, lng: 19.225157 },
    { lat: 49.3828672, lng: 19.2250729 },
    { lat: 49.3825592, lng: 19.2245494 },
    { lat: 49.382808, lng: 19.2242852 },
    { lat: 49.3826393, lng: 19.2240314 },
    { lat: 49.3825675, lng: 19.2234506 },
    { lat: 49.382554, lng: 19.2229801 },
    { lat: 49.382563, lng: 19.2222759 },
    { lat: 49.3825371, lng: 19.222018 },
    { lat: 49.3825225, lng: 19.2214325 },
    { lat: 49.3825156, lng: 19.2208629 },
    { lat: 49.3825135, lng: 19.2205831 },
    { lat: 49.3825108, lng: 19.2202716 },
    { lat: 49.3824854, lng: 19.2199044 },
    { lat: 49.3824863, lng: 19.2197115 },
    { lat: 49.3825038, lng: 19.2194643 },
    { lat: 49.3825055, lng: 19.2194319 },
    { lat: 49.38253, lng: 19.2190956 },
    { lat: 49.3825465, lng: 19.2188738 },
    { lat: 49.3825464, lng: 19.218871 },
    { lat: 49.3824378, lng: 19.2188673 },
    { lat: 49.382334, lng: 19.2188634 },
    { lat: 49.3825952, lng: 19.2181988 },
    { lat: 49.3827513, lng: 19.2178604 },
    { lat: 49.3834433, lng: 19.2168952 },
    { lat: 49.3838206, lng: 19.2163705 },
    { lat: 49.3840617, lng: 19.2160652 },
    { lat: 49.3843398, lng: 19.2158266 },
    { lat: 49.3845677, lng: 19.2158079 },
    { lat: 49.3848836, lng: 19.2159087 },
    { lat: 49.3851266, lng: 19.2160416 },
    { lat: 49.3853663, lng: 19.2161021 },
    { lat: 49.3857578, lng: 19.2156417 },
    { lat: 49.3861202, lng: 19.2153491 },
    { lat: 49.3863856, lng: 19.215197 },
    { lat: 49.3865968, lng: 19.2151381 },
    { lat: 49.3867624, lng: 19.2150909 },
    { lat: 49.3869864, lng: 19.2150146 },
    { lat: 49.3870918, lng: 19.2149311 },
    { lat: 49.3872631, lng: 19.2148098 },
    { lat: 49.3873747, lng: 19.2146956 },
    { lat: 49.3875842, lng: 19.2144843 },
    { lat: 49.3877846, lng: 19.2141955 },
    { lat: 49.3878343, lng: 19.2141501 },
    { lat: 49.3879228, lng: 19.2136709 },
    { lat: 49.3880162, lng: 19.2132443 },
    { lat: 49.388057, lng: 19.2131305 },
    { lat: 49.3881461, lng: 19.2128755 },
    { lat: 49.3883396, lng: 19.2125629 },
    { lat: 49.3883955, lng: 19.2123409 },
    { lat: 49.3882931, lng: 19.2120414 },
    { lat: 49.3882425, lng: 19.2116911 },
    { lat: 49.3882752, lng: 19.2111961 },
    { lat: 49.3884164, lng: 19.2106661 },
    { lat: 49.3884589, lng: 19.2101639 },
    { lat: 49.3883562, lng: 19.2096237 },
    { lat: 49.3883781, lng: 19.2092331 },
    { lat: 49.3886779, lng: 19.2085069 },
    { lat: 49.3887213, lng: 19.2079058 },
    { lat: 49.3888708, lng: 19.2071932 },
    { lat: 49.3888705, lng: 19.2071191 },
    { lat: 49.3888466, lng: 19.2069517 },
    { lat: 49.3889313, lng: 19.2062765 },
    { lat: 49.3889646, lng: 19.2057695 },
    { lat: 49.3890286, lng: 19.2054852 },
    { lat: 49.3891304, lng: 19.2050226 },
    { lat: 49.3891545, lng: 19.204822 },
    { lat: 49.3891106, lng: 19.2046362 },
    { lat: 49.3889558, lng: 19.2043921 },
    { lat: 49.3887643, lng: 19.2041565 },
    { lat: 49.3886627, lng: 19.2038261 },
    { lat: 49.3885656, lng: 19.2028885 },
    { lat: 49.388545, lng: 19.2026466 },
    { lat: 49.3886362, lng: 19.2021016 },
    { lat: 49.3886904, lng: 19.2018925 },
    { lat: 49.3888592, lng: 19.2015378 },
    { lat: 49.388884, lng: 19.2011153 },
    { lat: 49.3889373, lng: 19.2010065 },
    { lat: 49.3892675, lng: 19.2008206 },
    { lat: 49.3893982, lng: 19.2005822 },
    { lat: 49.3896691, lng: 19.2002644 },
    { lat: 49.3896761, lng: 19.2000596 },
    { lat: 49.3895992, lng: 19.1998913 },
    { lat: 49.3895084, lng: 19.1996671 },
    { lat: 49.3895773, lng: 19.1992006 },
    { lat: 49.3896961, lng: 19.1991156 },
    { lat: 49.3899055, lng: 19.1990854 },
    { lat: 49.3900433, lng: 19.1989552 },
    { lat: 49.3901134, lng: 19.1987924 },
    { lat: 49.3901639, lng: 19.1985555 },
    { lat: 49.390203, lng: 19.1979588 },
    { lat: 49.3902609, lng: 19.1976419 },
    { lat: 49.3903896, lng: 19.1969009 },
    { lat: 49.390368, lng: 19.1965455 },
    { lat: 49.3903642, lng: 19.1962304 },
    { lat: 49.3904109, lng: 19.1959339 },
    { lat: 49.3904244, lng: 19.1956321 },
    { lat: 49.3903619, lng: 19.1952014 },
    { lat: 49.3902181, lng: 19.1943186 },
    { lat: 49.390155, lng: 19.1942259 },
    { lat: 49.3897048, lng: 19.1938853 },
    { lat: 49.3896336, lng: 19.1938394 },
    { lat: 49.3893882, lng: 19.1935141 },
    { lat: 49.389159, lng: 19.1931301 },
    { lat: 49.3890504, lng: 19.1928663 },
    { lat: 49.3888563, lng: 19.1923463 },
    { lat: 49.3886281, lng: 19.19182 },
    { lat: 49.3882751, lng: 19.1915133 },
    { lat: 49.3881873, lng: 19.1913963 },
    { lat: 49.3881505, lng: 19.1912405 },
    { lat: 49.3881576, lng: 19.1911283 },
    { lat: 49.3883514, lng: 19.1908962 },
    { lat: 49.388285, lng: 19.190708 },
    { lat: 49.3881908, lng: 19.1903706 },
    { lat: 49.3881202, lng: 19.1900287 },
    { lat: 49.3880939, lng: 19.1896439 },
    { lat: 49.3879697, lng: 19.1894792 },
    { lat: 49.3878689, lng: 19.1894208 },
    { lat: 49.3878344, lng: 19.1894301 },
    { lat: 49.3877789, lng: 19.1895373 },
    { lat: 49.3877836, lng: 19.189777 },
    { lat: 49.3877015, lng: 19.1898276 },
    { lat: 49.3876184, lng: 19.1897168 },
    { lat: 49.3874797, lng: 19.1893668 },
    { lat: 49.3874227, lng: 19.189023 },
    { lat: 49.3873765, lng: 19.1889555 },
    { lat: 49.3873905, lng: 19.1887752 },
    { lat: 49.387545, lng: 19.1885219 },
    { lat: 49.3875669, lng: 19.1884376 },
    { lat: 49.3873068, lng: 19.1880684 },
    { lat: 49.3872344, lng: 19.1879225 },
    { lat: 49.3872439, lng: 19.187747 },
    { lat: 49.3872222, lng: 19.1875277 },
    { lat: 49.3871259, lng: 19.187378 },
    { lat: 49.3868477, lng: 19.1873386 },
    { lat: 49.386753, lng: 19.1871036 },
    { lat: 49.3866732, lng: 19.1868493 },
    { lat: 49.3866217, lng: 19.1866232 },
    { lat: 49.3865505, lng: 19.1862923 },
    { lat: 49.3864168, lng: 19.1859015 },
    { lat: 49.3864042, lng: 19.1858509 },
    { lat: 49.386314, lng: 19.1856404 },
    { lat: 49.3861116, lng: 19.1853695 },
    { lat: 49.3859245, lng: 19.1850402 },
    { lat: 49.3858257, lng: 19.1848886 },
    { lat: 49.3857083, lng: 19.1848389 },
    { lat: 49.3855961, lng: 19.1848726 },
    { lat: 49.3855425, lng: 19.1848334 },
    { lat: 49.3854753, lng: 19.1846797 },
    { lat: 49.3854635, lng: 19.1844828 },
    { lat: 49.3854119, lng: 19.1842509 },
    { lat: 49.3853528, lng: 19.1841278 },
    { lat: 49.3853087, lng: 19.1838632 },
    { lat: 49.3853701, lng: 19.1835745 },
    { lat: 49.3854483, lng: 19.1833504 },
    { lat: 49.3855322, lng: 19.1831962 },
    { lat: 49.3856436, lng: 19.1831206 },
    { lat: 49.3856616, lng: 19.1830524 },
    { lat: 49.3856251, lng: 19.1828792 },
    { lat: 49.3855326, lng: 19.1828507 },
    { lat: 49.385479, lng: 19.182792 },
    { lat: 49.3854608, lng: 19.1827437 },
    { lat: 49.3854318, lng: 19.1823042 },
    { lat: 49.3854604, lng: 19.1821031 },
    { lat: 49.3856034, lng: 19.1816806 },
    { lat: 49.3858106, lng: 19.1812317 },
    { lat: 49.3859377, lng: 19.1809004 },
    { lat: 49.3861387, lng: 19.1805575 },
    { lat: 49.386402, lng: 19.1804101 },
    { lat: 49.3865063, lng: 19.1802811 },
    { lat: 49.3866105, lng: 19.1801311 },
    { lat: 49.3866901, lng: 19.1799354 },
    { lat: 49.3866944, lng: 19.1798817 },
    { lat: 49.3866683, lng: 19.1796944 },
    { lat: 49.3866885, lng: 19.1793893 },
    { lat: 49.3866519, lng: 19.1791207 },
    { lat: 49.3868538, lng: 19.1790992 },
    { lat: 49.3870404, lng: 19.1788549 },
    { lat: 49.3871601, lng: 19.1786308 },
    { lat: 49.3872148, lng: 19.1782465 },
    { lat: 49.3872973, lng: 19.1780371 },
    { lat: 49.3873179, lng: 19.1779754 },
    { lat: 49.3873621, lng: 19.1778156 },
    { lat: 49.3874049, lng: 19.1778165 },
    { lat: 49.3874356, lng: 19.1780547 },
    { lat: 49.3875113, lng: 19.178269 },
    { lat: 49.3875653, lng: 19.1785005 },
    { lat: 49.3877284, lng: 19.178494 },
    { lat: 49.3878349, lng: 19.1784609 },
    { lat: 49.3878747, lng: 19.1782813 },
    { lat: 49.3878619, lng: 19.178247 },
    { lat: 49.3877384, lng: 19.1780949 },
    { lat: 49.3877658, lng: 19.1780071 },
    { lat: 49.3879413, lng: 19.17728 },
    { lat: 49.3879651, lng: 19.1770237 },
    { lat: 49.3879458, lng: 19.1766496 },
    { lat: 49.3879927, lng: 19.1764992 },
    { lat: 49.3880337, lng: 19.1764392 },
    { lat: 49.3880643, lng: 19.1764672 },
    { lat: 49.3881111, lng: 19.1766873 },
    { lat: 49.3881802, lng: 19.1770666 },
    { lat: 49.3881963, lng: 19.1772391 },
    { lat: 49.3881982, lng: 19.1772787 },
    { lat: 49.3882338, lng: 19.1773166 },
    { lat: 49.3883883, lng: 19.1768729 },
    { lat: 49.3883149, lng: 19.1762858 },
    { lat: 49.3883224, lng: 19.1759517 },
    { lat: 49.3882552, lng: 19.1751931 },
    { lat: 49.3881534, lng: 19.1747419 },
    { lat: 49.3879772, lng: 19.1742876 },
    { lat: 49.3879536, lng: 19.1742197 },
    { lat: 49.3879236, lng: 19.174044 },
    { lat: 49.3878592, lng: 19.1734591 },
    { lat: 49.3878419, lng: 19.1732874 },
    { lat: 49.3878958, lng: 19.1729068 },
    { lat: 49.3878336, lng: 19.1725764 },
    { lat: 49.3877565, lng: 19.1723803 },
    { lat: 49.3877158, lng: 19.1722366 },
    { lat: 49.3877817, lng: 19.1719672 },
    { lat: 49.3877643, lng: 19.1719102 },
    { lat: 49.3876319, lng: 19.1717183 },
    { lat: 49.3875006, lng: 19.1716341 },
    { lat: 49.3873488, lng: 19.1715728 },
    { lat: 49.3872762, lng: 19.1715159 },
    { lat: 49.387259, lng: 19.1714824 },
    { lat: 49.3872542, lng: 19.1714323 },
    { lat: 49.3872747, lng: 19.1713196 },
    { lat: 49.3873133, lng: 19.1711159 },
    { lat: 49.3873572, lng: 19.1709977 },
    { lat: 49.3874184, lng: 19.1708667 },
    { lat: 49.3874291, lng: 19.1707128 },
    { lat: 49.3873629, lng: 19.1704175 },
    { lat: 49.3872481, lng: 19.1702362 },
    { lat: 49.3870722, lng: 19.1700474 },
    { lat: 49.3869636, lng: 19.1699697 },
    { lat: 49.3868529, lng: 19.169869 },
    { lat: 49.3866818, lng: 19.1695491 },
    { lat: 49.3865585, lng: 19.1694462 },
    { lat: 49.3864156, lng: 19.1693821 },
    { lat: 49.3862197, lng: 19.1692231 },
    { lat: 49.3861707, lng: 19.1691386 },
    { lat: 49.3861632, lng: 19.1691015 },
    { lat: 49.3861821, lng: 19.1690512 },
    { lat: 49.3863205, lng: 19.1689545 },
    { lat: 49.3863998, lng: 19.1689834 },
    { lat: 49.3864527, lng: 19.1689575 },
    { lat: 49.3864802, lng: 19.1688784 },
    { lat: 49.3864951, lng: 19.1687661 },
    { lat: 49.3864817, lng: 19.1686746 },
    { lat: 49.3864424, lng: 19.1686291 },
    { lat: 49.3863594, lng: 19.168568 },
    { lat: 49.3863252, lng: 19.1684218 },
    { lat: 49.3863424, lng: 19.1681908 },
    { lat: 49.386298, lng: 19.1678605 },
    { lat: 49.3861882, lng: 19.16764 },
    { lat: 49.3861091, lng: 19.1673765 },
    { lat: 49.3860564, lng: 19.167244 },
    { lat: 49.3860199, lng: 19.1671396 },
    { lat: 49.3860032, lng: 19.1671028 },
    { lat: 49.3859908, lng: 19.1671002 },
    { lat: 49.3859403, lng: 19.1671298 },
    { lat: 49.3859141, lng: 19.1672623 },
    { lat: 49.3858266, lng: 19.1672256 },
    { lat: 49.3857892, lng: 19.1670779 },
    { lat: 49.3857127, lng: 19.1669903 },
    { lat: 49.3855836, lng: 19.1669087 },
    { lat: 49.3853988, lng: 19.1666533 },
    { lat: 49.385264, lng: 19.1664972 },
    { lat: 49.3852111, lng: 19.1664044 },
    { lat: 49.3851425, lng: 19.1661774 },
    { lat: 49.3850673, lng: 19.1660883 },
    { lat: 49.385079, lng: 19.1660273 },
    { lat: 49.3851336, lng: 19.1659932 },
    { lat: 49.3852786, lng: 19.1659362 },
    { lat: 49.3854117, lng: 19.1659385 },
    { lat: 49.3855671, lng: 19.1659371 },
    { lat: 49.3856087, lng: 19.1659573 },
    { lat: 49.3856761, lng: 19.1659302 },
    { lat: 49.385686, lng: 19.1658773 },
    { lat: 49.3856678, lng: 19.1657357 },
    { lat: 49.385637, lng: 19.1655866 },
    { lat: 49.3856401, lng: 19.165397 },
    { lat: 49.3856091, lng: 19.1652938 },
    { lat: 49.38556, lng: 19.1652791 },
    { lat: 49.385485, lng: 19.1652421 },
    { lat: 49.3854007, lng: 19.1650812 },
    { lat: 49.3853395, lng: 19.1645202 },
    { lat: 49.3853237, lng: 19.1642858 },
    { lat: 49.3852594, lng: 19.1640265 },
    { lat: 49.3851878, lng: 19.1639235 },
    { lat: 49.3850822, lng: 19.1637901 },
    { lat: 49.3850172, lng: 19.1635649 },
    { lat: 49.3849618, lng: 19.1633964 },
    { lat: 49.3851617, lng: 19.163267 },
    { lat: 49.3851851, lng: 19.163239 },
    { lat: 49.3852008, lng: 19.1632115 },
    { lat: 49.3851788, lng: 19.163133 },
    { lat: 49.3849826, lng: 19.1630124 },
    { lat: 49.3848938, lng: 19.1628319 },
    { lat: 49.3848619, lng: 19.1623572 },
    { lat: 49.3848617, lng: 19.1620266 },
    { lat: 49.384822, lng: 19.1615836 },
    { lat: 49.3848085, lng: 19.1611872 },
    { lat: 49.3847503, lng: 19.1609406 },
    { lat: 49.3847125, lng: 19.1608335 },
    { lat: 49.3847189, lng: 19.1606392 },
    { lat: 49.384771, lng: 19.1604574 },
    { lat: 49.3848021, lng: 19.1602647 },
    { lat: 49.3848383, lng: 19.1601488 },
    { lat: 49.3849125, lng: 19.1600539 },
    { lat: 49.3849775, lng: 19.1600208 },
    { lat: 49.3849883, lng: 19.1600149 },
    { lat: 49.3851086, lng: 19.1601228 },
    { lat: 49.3851711, lng: 19.160111 },
    { lat: 49.3852166, lng: 19.1600707 },
    { lat: 49.3852585, lng: 19.1600052 },
    { lat: 49.3853146, lng: 19.1598371 },
    { lat: 49.3853216, lng: 19.1596796 },
    { lat: 49.3853489, lng: 19.1593357 },
    { lat: 49.3853412, lng: 19.1591087 },
    { lat: 49.3853805, lng: 19.1589888 },
    { lat: 49.385423, lng: 19.1589348 },
    { lat: 49.3854664, lng: 19.158923 },
    { lat: 49.3854862, lng: 19.1589124 },
    { lat: 49.3855027, lng: 19.1589076 },
    { lat: 49.385524, lng: 19.1589038 },
    { lat: 49.3855939, lng: 19.1589277 },
    { lat: 49.3856925, lng: 19.1588665 },
    { lat: 49.3857646, lng: 19.1587263 },
    { lat: 49.3858717, lng: 19.1585871 },
    { lat: 49.3859123, lng: 19.1585195 },
    { lat: 49.385962, lng: 19.1584046 },
    { lat: 49.3860251, lng: 19.1581721 },
    { lat: 49.3860538, lng: 19.1580223 },
    { lat: 49.3860557, lng: 19.157852 },
    { lat: 49.3860167, lng: 19.157676 },
    { lat: 49.3859913, lng: 19.1575917 },
    { lat: 49.3860155, lng: 19.1575111 },
    { lat: 49.386096, lng: 19.1574028 },
    { lat: 49.3861305, lng: 19.157209 },
    { lat: 49.3861676, lng: 19.1570427 },
    { lat: 49.3861851, lng: 19.1569654 },
    { lat: 49.3862615, lng: 19.1569329 },
    { lat: 49.3863848, lng: 19.1569196 },
    { lat: 49.3865347, lng: 19.1568978 },
    { lat: 49.3865781, lng: 19.1568137 },
    { lat: 49.3865564, lng: 19.1567669 },
    { lat: 49.3864868, lng: 19.1565831 },
    { lat: 49.3864444, lng: 19.1564783 },
    { lat: 49.3864739, lng: 19.1564095 },
    { lat: 49.3864803, lng: 19.1563317 },
    { lat: 49.3864923, lng: 19.1561172 },
    { lat: 49.3864867, lng: 19.1560925 },
    { lat: 49.386468, lng: 19.1560588 },
    { lat: 49.3864544, lng: 19.1559139 },
    { lat: 49.3864911, lng: 19.1557646 },
    { lat: 49.3865222, lng: 19.1556866 },
    { lat: 49.3864749, lng: 19.1555472 },
    { lat: 49.3864145, lng: 19.1554669 },
    { lat: 49.3863627, lng: 19.1552547 },
    { lat: 49.3863437, lng: 19.1551414 },
    { lat: 49.3863142, lng: 19.1550688 },
    { lat: 49.3863289, lng: 19.1550252 },
    { lat: 49.3863683, lng: 19.1549769 },
    { lat: 49.3863883, lng: 19.1549942 },
    { lat: 49.3864583, lng: 19.155157 },
    { lat: 49.3864982, lng: 19.1551669 },
    { lat: 49.3866278, lng: 19.1547712 },
    { lat: 49.3866356, lng: 19.1547475 },
    { lat: 49.3865989, lng: 19.1547108 },
    { lat: 49.3865745, lng: 19.1546948 },
    { lat: 49.3865818, lng: 19.1546596 },
    { lat: 49.3866349, lng: 19.1545967 },
    { lat: 49.3866839, lng: 19.154602 },
    { lat: 49.3867507, lng: 19.1546552 },
    { lat: 49.3868248, lng: 19.1547227 },
    { lat: 49.3868451, lng: 19.1546991 },
    { lat: 49.3868766, lng: 19.1544654 },
    { lat: 49.3869681, lng: 19.1543504 },
    { lat: 49.3869979, lng: 19.1542247 },
    { lat: 49.3870403, lng: 19.1540965 },
    { lat: 49.3870439, lng: 19.1540777 },
    { lat: 49.3870341, lng: 19.1540173 },
    { lat: 49.3868649, lng: 19.1539906 },
    { lat: 49.3868436, lng: 19.1539706 },
    { lat: 49.3868437, lng: 19.1539044 },
    { lat: 49.3868576, lng: 19.1538641 },
    { lat: 49.3869307, lng: 19.1537461 },
    { lat: 49.3869976, lng: 19.1536166 },
    { lat: 49.3870787, lng: 19.1534529 },
    { lat: 49.3871225, lng: 19.1533759 },
    { lat: 49.3872062, lng: 19.1534326 },
    { lat: 49.3873058, lng: 19.1533681 },
    { lat: 49.3873535, lng: 19.1534003 },
    { lat: 49.3874356, lng: 19.1534181 },
    { lat: 49.3875237, lng: 19.1533997 },
    { lat: 49.3875837, lng: 19.1533799 },
    { lat: 49.3876836, lng: 19.1531413 },
    { lat: 49.3877627, lng: 19.1529601 },
    { lat: 49.387796, lng: 19.1528781 },
    { lat: 49.3878795, lng: 19.1527887 },
    { lat: 49.3879531, lng: 19.1527275 },
    { lat: 49.3880559, lng: 19.1527299 },
    { lat: 49.3880935, lng: 19.1527842 },
    { lat: 49.3881837, lng: 19.1527392 },
    { lat: 49.3882868, lng: 19.1526345 },
    { lat: 49.3884494, lng: 19.152477 },
    { lat: 49.3886436, lng: 19.1524151 },
    { lat: 49.3887648, lng: 19.1523565 },
    { lat: 49.3888375, lng: 19.1522787 },
    { lat: 49.3890228, lng: 19.1523101 },
    { lat: 49.3892336, lng: 19.1522394 },
    { lat: 49.3892868, lng: 19.1523379 },
    { lat: 49.3894564, lng: 19.1525578 },
    { lat: 49.3895723, lng: 19.1526683 },
    { lat: 49.3897396, lng: 19.1526359 },
    { lat: 49.3897597, lng: 19.1526528 },
    { lat: 49.389769, lng: 19.152825 },
    { lat: 49.3897783, lng: 19.1528996 },
    { lat: 49.389842, lng: 19.1530041 },
    { lat: 49.3899181, lng: 19.1530584 },
    { lat: 49.39008, lng: 19.1531015 },
    { lat: 49.390303, lng: 19.153096 },
    { lat: 49.3904426, lng: 19.153185 },
    { lat: 49.3905118, lng: 19.1531938 },
    { lat: 49.3906883, lng: 19.1530445 },
    { lat: 49.3908234, lng: 19.1530219 },
    { lat: 49.3909212, lng: 19.1530372 },
    { lat: 49.391055, lng: 19.1530346 },
    { lat: 49.3912436, lng: 19.1528767 },
    { lat: 49.3915078, lng: 19.1528155 },
    { lat: 49.3916901, lng: 19.1529033 },
    { lat: 49.3918125, lng: 19.1530797 },
    { lat: 49.3918821, lng: 19.1530975 },
    { lat: 49.3919059, lng: 19.1531016 },
    { lat: 49.3919766, lng: 19.1530249 },
    { lat: 49.392078, lng: 19.1529529 },
    { lat: 49.3922636, lng: 19.1529477 },
    { lat: 49.3923375, lng: 19.1529628 },
    { lat: 49.3924219, lng: 19.1530281 },
    { lat: 49.3924694, lng: 19.1530082 },
    { lat: 49.392485, lng: 19.1529796 },
    { lat: 49.3925088, lng: 19.1528897 },
    { lat: 49.3925523, lng: 19.1528334 },
    { lat: 49.3925614, lng: 19.1527426 },
    { lat: 49.3928096, lng: 19.1527234 },
    { lat: 49.3928749, lng: 19.1527702 },
    { lat: 49.3930624, lng: 19.1526867 },
    { lat: 49.393135, lng: 19.152508 },
    { lat: 49.3932457, lng: 19.152376 },
    { lat: 49.3932883, lng: 19.152416 },
    { lat: 49.3933199, lng: 19.1525564 },
    { lat: 49.3934084, lng: 19.1525206 },
    { lat: 49.3935332, lng: 19.1525368 },
    { lat: 49.3936189, lng: 19.1524243 },
    { lat: 49.3937054, lng: 19.1522303 },
    { lat: 49.393805, lng: 19.1521954 },
    { lat: 49.3938828, lng: 19.1522166 },
    { lat: 49.3939099, lng: 19.1523373 },
    { lat: 49.3939904, lng: 19.1524142 },
    { lat: 49.3942078, lng: 19.1524126 },
    { lat: 49.3942246, lng: 19.1524317 },
    { lat: 49.3942329, lng: 19.1525111 },
    { lat: 49.3942458, lng: 19.1525244 },
    { lat: 49.3943311, lng: 19.1524471 },
    { lat: 49.3943991, lng: 19.1523152 },
    { lat: 49.3944016, lng: 19.1522255 },
    { lat: 49.3944045, lng: 19.152193 },
    { lat: 49.3944535, lng: 19.1521809 },
    { lat: 49.3945326, lng: 19.1521357 },
    { lat: 49.3946539, lng: 19.1521534 },
    { lat: 49.3946791, lng: 19.1521379 },
    { lat: 49.3947068, lng: 19.1520808 },
    { lat: 49.3947448, lng: 19.1518901 },
    { lat: 49.3948298, lng: 19.1517339 },
    { lat: 49.3949416, lng: 19.1515992 },
    { lat: 49.3950243, lng: 19.1516582 },
    { lat: 49.3951403, lng: 19.1517951 },
    { lat: 49.3951554, lng: 19.1518499 },
    { lat: 49.3952414, lng: 19.1519034 },
    { lat: 49.3953358, lng: 19.151897 },
    { lat: 49.3954339, lng: 19.1518058 },
    { lat: 49.3955058, lng: 19.151798 },
    { lat: 49.3955556, lng: 19.1517829 },
    { lat: 49.3956299, lng: 19.1516645 },
    { lat: 49.395722, lng: 19.1515176 },
    { lat: 49.3958386, lng: 19.1513172 },
    { lat: 49.3959235, lng: 19.1512801 },
    { lat: 49.3960508, lng: 19.15128 },
    { lat: 49.3960621, lng: 19.1513479 },
    { lat: 49.3961704, lng: 19.151443 },
    { lat: 49.3963178, lng: 19.1514143 },
    { lat: 49.3964293, lng: 19.1513227 },
    { lat: 49.3965154, lng: 19.1513541 },
    { lat: 49.3965355, lng: 19.15135 },
    { lat: 49.396601, lng: 19.1513755 },
    { lat: 49.3967594, lng: 19.1512959 },
    { lat: 49.3968584, lng: 19.1511768 },
    { lat: 49.3969324, lng: 19.1511677 },
    { lat: 49.3970578, lng: 19.1511975 },
    { lat: 49.3971499, lng: 19.1512837 },
    { lat: 49.3971974, lng: 19.1512656 },
    { lat: 49.3973255, lng: 19.1511403 },
    { lat: 49.3973734, lng: 19.1511066 },
    { lat: 49.3973648, lng: 19.1510645 },
    { lat: 49.3974095, lng: 19.1510124 },
    { lat: 49.397406, lng: 19.1508727 },
    { lat: 49.3975145, lng: 19.1507135 },
    { lat: 49.3976002, lng: 19.1505271 },
    { lat: 49.3977277, lng: 19.1503711 },
    { lat: 49.3978228, lng: 19.1502618 },
    { lat: 49.3979673, lng: 19.1502237 },
    { lat: 49.3981887, lng: 19.1502053 },
    { lat: 49.3982842, lng: 19.149994 },
    { lat: 49.3983356, lng: 19.1499631 },
    { lat: 49.3983804, lng: 19.1498835 },
    { lat: 49.3984858, lng: 19.1498255 },
    { lat: 49.3985396, lng: 19.1497278 },
    { lat: 49.3985873, lng: 19.1495281 },
    { lat: 49.3986965, lng: 19.1492464 },
    { lat: 49.3988983, lng: 19.1490345 },
    { lat: 49.3990854, lng: 19.1487588 },
    { lat: 49.3994136, lng: 19.1485083 },
    { lat: 49.3996877, lng: 19.1483757 },
    { lat: 49.3999443, lng: 19.1481785 },
    { lat: 49.4001812, lng: 19.1480227 },
    { lat: 49.4005794, lng: 19.1478673 },
    { lat: 49.4007795, lng: 19.1476694 },
    { lat: 49.4010553, lng: 19.1472442 },
    { lat: 49.4011725, lng: 19.1470796 },
    { lat: 49.4012227, lng: 19.1469819 },
    { lat: 49.4012677, lng: 19.1468136 },
    { lat: 49.4012639, lng: 19.1466671 },
    { lat: 49.401282, lng: 19.1464613 },
    { lat: 49.4013086, lng: 19.1462896 },
    { lat: 49.4014168, lng: 19.1461231 },
    { lat: 49.4014472, lng: 19.145778 },
    { lat: 49.401478, lng: 19.1457832 },
    { lat: 49.4016725, lng: 19.145845 },
    { lat: 49.4017174, lng: 19.1458402 },
    { lat: 49.4018894, lng: 19.1458315 },
    { lat: 49.4021473, lng: 19.145755 },
    { lat: 49.4023826, lng: 19.1457759 },
    { lat: 49.403459, lng: 19.1457296 },
    { lat: 49.4035398, lng: 19.1457689 },
    { lat: 49.4037626, lng: 19.1458774 },
    { lat: 49.4043092, lng: 19.1460409 },
    { lat: 49.4047531, lng: 19.1463399 },
    { lat: 49.405233, lng: 19.147101 },
    { lat: 49.405508, lng: 19.146853 },
    { lat: 49.406152, lng: 19.146245 },
    { lat: 49.406265, lng: 19.146219 },
    { lat: 49.406625, lng: 19.146128 },
    { lat: 49.406943, lng: 19.146039 },
    { lat: 49.407352, lng: 19.145648 },
    { lat: 49.407972, lng: 19.145043 },
    { lat: 49.408617, lng: 19.143171 },
    { lat: 49.409143, lng: 19.141401 },
    { lat: 49.409566, lng: 19.139989 },
    { lat: 49.409799, lng: 19.138511 },
    { lat: 49.409956, lng: 19.137516 },
    { lat: 49.409749, lng: 19.137172 },
    { lat: 49.40959, lng: 19.136909 },
    { lat: 49.409085, lng: 19.135894 },
    { lat: 49.408916, lng: 19.13501 },
    { lat: 49.408506, lng: 19.134471 },
    { lat: 49.408261, lng: 19.13414 },
    { lat: 49.408074, lng: 19.132561 },
    { lat: 49.406801, lng: 19.133114 },
    { lat: 49.40619, lng: 19.132696 },
    { lat: 49.40574, lng: 19.132387 },
    { lat: 49.405121, lng: 19.131079 },
    { lat: 49.405011, lng: 19.130284 },
    { lat: 49.404652, lng: 19.129742 },
    { lat: 49.404264, lng: 19.128431 },
    { lat: 49.403872, lng: 19.127707 },
    { lat: 49.403602, lng: 19.1272 },
    { lat: 49.403476, lng: 19.126473 },
    { lat: 49.403056, lng: 19.124282 },
    { lat: 49.402618, lng: 19.122358 },
    { lat: 49.402676, lng: 19.121495 },
    { lat: 49.402675, lng: 19.121496 },
    { lat: 49.402197, lng: 19.121959 },
    { lat: 49.401762, lng: 19.122166 },
    { lat: 49.400346, lng: 19.122959 },
    { lat: 49.399844, lng: 19.122909 },
    { lat: 49.399231, lng: 19.123765 },
    { lat: 49.398226, lng: 19.123712 },
    { lat: 49.396829, lng: 19.123638 },
    { lat: 49.3968, lng: 19.123637 },
    { lat: 49.396705, lng: 19.123632 },
    { lat: 49.396222, lng: 19.123984 },
    { lat: 49.396155, lng: 19.124034 },
    { lat: 49.39581, lng: 19.123949 },
    { lat: 49.395767, lng: 19.12406 },
    { lat: 49.395702, lng: 19.124129 },
    { lat: 49.395489, lng: 19.12435 },
    { lat: 49.395089, lng: 19.124544 },
    { lat: 49.394821, lng: 19.124561 },
    { lat: 49.39391, lng: 19.123991 },
    { lat: 49.393449, lng: 19.124836 },
    { lat: 49.393314, lng: 19.125503 },
    { lat: 49.392911, lng: 19.126241 },
    { lat: 49.3926, lng: 19.12647 },
    { lat: 49.391816, lng: 19.127046 },
    { lat: 49.391354, lng: 19.12714 },
    { lat: 49.390255, lng: 19.126746 },
    { lat: 49.390225, lng: 19.126716 },
    { lat: 49.389737, lng: 19.1262638 },
    { lat: 49.389359, lng: 19.126312 },
    { lat: 49.389124, lng: 19.126545 },
    { lat: 49.389049, lng: 19.126503 },
    { lat: 49.389005, lng: 19.12648 },
    { lat: 49.388991, lng: 19.126472 },
    { lat: 49.388388, lng: 19.12614 },
    { lat: 49.38803, lng: 19.125741 },
    { lat: 49.388016, lng: 19.125725 },
    { lat: 49.387926, lng: 19.125624 },
    { lat: 49.387246, lng: 19.124867 },
    { lat: 49.387124, lng: 19.124628 },
    { lat: 49.387041, lng: 19.124464 },
    { lat: 49.386951, lng: 19.12429 },
    { lat: 49.386888, lng: 19.123987 },
    { lat: 49.386228, lng: 19.12387 },
    { lat: 49.385634, lng: 19.123935 },
    { lat: 49.385325, lng: 19.123728 },
    { lat: 49.384959, lng: 19.123484 },
    { lat: 49.384608, lng: 19.123248 },
    { lat: 49.384099, lng: 19.123098 },
    { lat: 49.383851, lng: 19.123145 },
    { lat: 49.383089, lng: 19.12294 },
    { lat: 49.382726, lng: 19.123011 },
    { lat: 49.382328, lng: 19.123089 },
    { lat: 49.381824, lng: 19.122708 },
    { lat: 49.381693, lng: 19.123029 },
    { lat: 49.38146, lng: 19.122817 },
    { lat: 49.381169, lng: 19.123094 },
    { lat: 49.381081, lng: 19.123091 },
    { lat: 49.380192, lng: 19.123055 },
    { lat: 49.379804, lng: 19.12278 },
    { lat: 49.379561, lng: 19.122608 },
    { lat: 49.379498, lng: 19.12247 },
    { lat: 49.379485, lng: 19.122441 },
    { lat: 49.3794228, lng: 19.1223027 },
    { lat: 49.379266, lng: 19.121954 },
    { lat: 49.379205, lng: 19.121816 },
    { lat: 49.379074, lng: 19.121668 },
    { lat: 49.378854, lng: 19.121422 },
    { lat: 49.378635, lng: 19.121176 },
    { lat: 49.3785, lng: 19.121024 },
    { lat: 49.378296, lng: 19.120796 },
    { lat: 49.378149, lng: 19.120631 },
    { lat: 49.378119, lng: 19.120598 },
    { lat: 49.377965, lng: 19.120432 },
    { lat: 49.377624, lng: 19.120071 },
    { lat: 49.3775, lng: 19.119939 },
    { lat: 49.377388, lng: 19.119819 },
    { lat: 49.37735, lng: 19.11978 },
    { lat: 49.377235, lng: 19.119656 },
    { lat: 49.376987, lng: 19.119361 },
    { lat: 49.376764, lng: 19.119095 },
    { lat: 49.376731, lng: 19.119085 },
    { lat: 49.376587, lng: 19.119041 },
    { lat: 49.376517, lng: 19.119019 },
    { lat: 49.376447, lng: 19.118915 },
    { lat: 49.376204, lng: 19.118562 },
    { lat: 49.376135, lng: 19.118462 },
    { lat: 49.375989, lng: 19.118135 },
    { lat: 49.375795, lng: 19.117702 },
    { lat: 49.375659, lng: 19.117398 },
    { lat: 49.375582, lng: 19.117142 },
    { lat: 49.375378, lng: 19.116449 },
    { lat: 49.375156, lng: 19.1157 },
    { lat: 49.375085, lng: 19.115459 },
    { lat: 49.374989, lng: 19.115326 },
    { lat: 49.37482, lng: 19.115095 },
    { lat: 49.374684, lng: 19.11491 },
    { lat: 49.374674, lng: 19.114905 },
    { lat: 49.374477, lng: 19.114818 },
    { lat: 49.374241, lng: 19.114714 },
    { lat: 49.373772, lng: 19.114506 },
    { lat: 49.373664, lng: 19.114458 },
    { lat: 49.37299, lng: 19.113626 },
    { lat: 49.37255, lng: 19.113251 },
    { lat: 49.372479, lng: 19.113125 },
    { lat: 49.372478, lng: 19.113123 },
    { lat: 49.372304, lng: 19.112792 },
    { lat: 49.372171, lng: 19.11245 },
    { lat: 49.372143, lng: 19.112376 },
    { lat: 49.371961, lng: 19.111876 },
    { lat: 49.371933, lng: 19.111799 },
    { lat: 49.371775, lng: 19.111576 },
    { lat: 49.371511, lng: 19.1112 },
    { lat: 49.371065, lng: 19.111704 },
    { lat: 49.37089, lng: 19.112356 },
    { lat: 49.369596, lng: 19.112896 },
    { lat: 49.369478, lng: 19.113034 },
    { lat: 49.369283, lng: 19.113261 },
    { lat: 49.369207, lng: 19.11335 },
    { lat: 49.368388, lng: 19.113534 },
    { lat: 49.368344, lng: 19.113532 },
    { lat: 49.368012, lng: 19.113519 },
    { lat: 49.367466, lng: 19.114574 },
    { lat: 49.367187, lng: 19.114861 },
    { lat: 49.36677, lng: 19.115142 },
    { lat: 49.367044, lng: 19.115684 },
    { lat: 49.36671, lng: 19.116593 },
  ],
  OravskáPolhora: [
    { lat: 49.5730216, lng: 19.529331 },
    { lat: 49.5725923, lng: 19.5271412 },
    { lat: 49.5721546, lng: 19.5258315 },
    { lat: 49.572147, lng: 19.5237551 },
    { lat: 49.5722347, lng: 19.5220086 },
    { lat: 49.5728745, lng: 19.5199744 },
    { lat: 49.5734062, lng: 19.5182163 },
    { lat: 49.5740223, lng: 19.5172078 },
    { lat: 49.5745287, lng: 19.5166729 },
    { lat: 49.5748318, lng: 19.5163221 },
    { lat: 49.5755026, lng: 19.5156266 },
    { lat: 49.5765097, lng: 19.5153366 },
    { lat: 49.5774707, lng: 19.5148807 },
    { lat: 49.579977, lng: 19.511925 },
    { lat: 49.5803486, lng: 19.5114695 },
    { lat: 49.5814392, lng: 19.5102073 },
    { lat: 49.5819286, lng: 19.5069181 },
    { lat: 49.5812183, lng: 19.4996348 },
    { lat: 49.5812325, lng: 19.4995605 },
    { lat: 49.581283, lng: 19.4995545 },
    { lat: 49.58126, lng: 19.4993484 },
    { lat: 49.58126, lng: 19.499276 },
    { lat: 49.5812965, lng: 19.4991164 },
    { lat: 49.5813852, lng: 19.4986604 },
    { lat: 49.5814278, lng: 19.4982809 },
    { lat: 49.5814713, lng: 19.4980368 },
    { lat: 49.5814791, lng: 19.4979201 },
    { lat: 49.5815156, lng: 19.4977605 },
    { lat: 49.5815261, lng: 19.4975902 },
    { lat: 49.5815758, lng: 19.4972272 },
    { lat: 49.5816669, lng: 19.4970015 },
    { lat: 49.5819721, lng: 19.4964462 },
    { lat: 49.5820661, lng: 19.496288 },
    { lat: 49.5821252, lng: 19.4961217 },
    { lat: 49.5822478, lng: 19.4958747 },
    { lat: 49.5831147, lng: 19.494448 },
    { lat: 49.5835181, lng: 19.493874 },
    { lat: 49.5836764, lng: 19.4936433 },
    { lat: 49.5837981, lng: 19.4934046 },
    { lat: 49.5839668, lng: 19.4931123 },
    { lat: 49.5841233, lng: 19.4929191 },
    { lat: 49.584252, lng: 19.4927207 },
    { lat: 49.5843485, lng: 19.4925145 },
    { lat: 49.5844154, lng: 19.492211 },
    { lat: 49.584478, lng: 19.4918194 },
    { lat: 49.5847111, lng: 19.4902852 },
    { lat: 49.5848015, lng: 19.4897032 },
    { lat: 49.5848815, lng: 19.4892982 },
    { lat: 49.5849389, lng: 19.4888717 },
    { lat: 49.5850171, lng: 19.4884211 },
    { lat: 49.5851527, lng: 19.4873214 },
    { lat: 49.5853162, lng: 19.48616 },
    { lat: 49.5854171, lng: 19.485358 },
    { lat: 49.585464, lng: 19.4850308 },
    { lat: 49.585497, lng: 19.4847035 },
    { lat: 49.5855631, lng: 19.4844836 },
    { lat: 49.5856518, lng: 19.4841027 },
    { lat: 49.5857579, lng: 19.4837084 },
    { lat: 49.58585, lng: 19.4833678 },
    { lat: 49.5859544, lng: 19.4829896 },
    { lat: 49.5860483, lng: 19.4826382 },
    { lat: 49.5863943, lng: 19.4816995 },
    { lat: 49.5868864, lng: 19.4804925 },
    { lat: 49.5875159, lng: 19.4793659 },
    { lat: 49.5880283, lng: 19.478408 },
    { lat: 49.5887018, lng: 19.4778022 },
    { lat: 49.5890686, lng: 19.4775367 },
    { lat: 49.589246, lng: 19.4774294 },
    { lat: 49.5897485, lng: 19.4771773 },
    { lat: 49.5899745, lng: 19.4770512 },
    { lat: 49.5901345, lng: 19.4769734 },
    { lat: 49.5903118, lng: 19.4768795 },
    { lat: 49.5904875, lng: 19.4767562 },
    { lat: 49.5906909, lng: 19.476622 },
    { lat: 49.5910069, lng: 19.4763961 },
    { lat: 49.5915968, lng: 19.4757825 },
    { lat: 49.5921463, lng: 19.4746686 },
    { lat: 49.5922992, lng: 19.4743422 },
    { lat: 49.5924226, lng: 19.4739747 },
    { lat: 49.5925604, lng: 19.473678 },
    { lat: 49.592666, lng: 19.4735616 },
    { lat: 49.5928677, lng: 19.4734892 },
    { lat: 49.5930885, lng: 19.4734409 },
    { lat: 49.5932923, lng: 19.4733642 },
    { lat: 49.593631, lng: 19.4731915 },
    { lat: 49.5939484, lng: 19.4729834 },
    { lat: 49.5942517, lng: 19.4723895 },
    { lat: 49.5946359, lng: 19.4716787 },
    { lat: 49.5947767, lng: 19.4713864 },
    { lat: 49.5949158, lng: 19.4710323 },
    { lat: 49.5951088, lng: 19.4706112 },
    { lat: 49.5952235, lng: 19.4703054 },
    { lat: 49.5955034, lng: 19.4693693 },
    { lat: 49.595596, lng: 19.4691381 },
    { lat: 49.5957816, lng: 19.4689375 },
    { lat: 49.5960806, lng: 19.4687015 },
    { lat: 49.5962823, lng: 19.4685566 },
    { lat: 49.5964127, lng: 19.4684976 },
    { lat: 49.5965101, lng: 19.4685003 },
    { lat: 49.5966248, lng: 19.4685781 },
    { lat: 49.5973004, lng: 19.4691882 },
    { lat: 49.5978572, lng: 19.4695722 },
    { lat: 49.5983351, lng: 19.4699017 },
    { lat: 49.5987612, lng: 19.4706228 },
    { lat: 49.6000517, lng: 19.4716767 },
    { lat: 49.6005735, lng: 19.4718653 },
    { lat: 49.6010375, lng: 19.4725189 },
    { lat: 49.6015662, lng: 19.4736792 },
    { lat: 49.6030327, lng: 19.474505 },
    { lat: 49.6033766, lng: 19.4746987 },
    { lat: 49.6046527, lng: 19.4746169 },
    { lat: 49.6049802, lng: 19.4743625 },
    { lat: 49.6070629, lng: 19.4727445 },
    { lat: 49.6079068, lng: 19.4713922 },
    { lat: 49.6087687, lng: 19.4706495 },
    { lat: 49.6098834, lng: 19.4703541 },
    { lat: 49.6111271, lng: 19.4700627 },
    { lat: 49.6117973, lng: 19.4693192 },
    { lat: 49.6122654, lng: 19.4688 },
    { lat: 49.6132837, lng: 19.4679672 },
    { lat: 49.6137628, lng: 19.4673762 },
    { lat: 49.6134468, lng: 19.4660101 },
    { lat: 49.613344, lng: 19.464555 },
    { lat: 49.6129254, lng: 19.461253 },
    { lat: 49.612712, lng: 19.4606508 },
    { lat: 49.611193, lng: 19.4567395 },
    { lat: 49.61114, lng: 19.456405 },
    { lat: 49.6109081, lng: 19.4549414 },
    { lat: 49.6118189, lng: 19.4521194 },
    { lat: 49.612256, lng: 19.4513312 },
    { lat: 49.6126605, lng: 19.4506019 },
    { lat: 49.6138162, lng: 19.4485179 },
    { lat: 49.6135855, lng: 19.4477007 },
    { lat: 49.613502, lng: 19.447405 },
    { lat: 49.6133339, lng: 19.4464249 },
    { lat: 49.6133009, lng: 19.4462324 },
    { lat: 49.6127842, lng: 19.4457116 },
    { lat: 49.6120469, lng: 19.4449684 },
    { lat: 49.61126, lng: 19.444581 },
    { lat: 49.6099749, lng: 19.4434585 },
    { lat: 49.6091588, lng: 19.4430503 },
    { lat: 49.6080443, lng: 19.443156 },
    { lat: 49.607928, lng: 19.443167 },
    { lat: 49.6078325, lng: 19.4431224 },
    { lat: 49.605694, lng: 19.4421227 },
    { lat: 49.6055722, lng: 19.4420658 },
    { lat: 49.6043699, lng: 19.4421216 },
    { lat: 49.603055, lng: 19.444371 },
    { lat: 49.6026662, lng: 19.444578 },
    { lat: 49.6018803, lng: 19.4449875 },
    { lat: 49.6016067, lng: 19.4451311 },
    { lat: 49.6005146, lng: 19.4445995 },
    { lat: 49.5996346, lng: 19.4437277 },
    { lat: 49.598501, lng: 19.442018 },
    { lat: 49.598015, lng: 19.440871 },
    { lat: 49.597831, lng: 19.4396606 },
    { lat: 49.5968427, lng: 19.4378117 },
    { lat: 49.5966903, lng: 19.4355097 },
    { lat: 49.5962605, lng: 19.4339214 },
    { lat: 49.5962058, lng: 19.4330177 },
    { lat: 49.5956813, lng: 19.4318363 },
    { lat: 49.5954091, lng: 19.4306445 },
    { lat: 49.5957997, lng: 19.4290891 },
    { lat: 49.5953873, lng: 19.4270005 },
    { lat: 49.595517, lng: 19.426088 },
    { lat: 49.595474, lng: 19.424581 },
    { lat: 49.5955224, lng: 19.4244193 },
    { lat: 49.595747, lng: 19.423669 },
    { lat: 49.5957609, lng: 19.4227294 },
    { lat: 49.5951543, lng: 19.4209676 },
    { lat: 49.5947324, lng: 19.4187244 },
    { lat: 49.5943927, lng: 19.4177909 },
    { lat: 49.5940751, lng: 19.416389 },
    { lat: 49.5927663, lng: 19.4140649 },
    { lat: 49.5926888, lng: 19.4126275 },
    { lat: 49.5925378, lng: 19.4114305 },
    { lat: 49.5921592, lng: 19.4099851 },
    { lat: 49.5921387, lng: 19.4099636 },
    { lat: 49.5917947, lng: 19.4095969 },
    { lat: 49.5913755, lng: 19.4094642 },
    { lat: 49.5909163, lng: 19.4093157 },
    { lat: 49.5898211, lng: 19.4087809 },
    { lat: 49.5886945, lng: 19.4082611 },
    { lat: 49.5879444, lng: 19.4077109 },
    { lat: 49.5868439, lng: 19.4071054 },
    { lat: 49.5859549, lng: 19.4068361 },
    { lat: 49.584353, lng: 19.4079177 },
    { lat: 49.5838383, lng: 19.4080093 },
    { lat: 49.58268, lng: 19.407751 },
    { lat: 49.5826623, lng: 19.4077497 },
    { lat: 49.581784, lng: 19.407692 },
    { lat: 49.5808269, lng: 19.4076416 },
    { lat: 49.5801153, lng: 19.4070974 },
    { lat: 49.5795951, lng: 19.4060269 },
    { lat: 49.5789924, lng: 19.4050417 },
    { lat: 49.5782415, lng: 19.4044189 },
    { lat: 49.5773481, lng: 19.4036954 },
    { lat: 49.5766432, lng: 19.4028659 },
    { lat: 49.575656, lng: 19.401878 },
    { lat: 49.5748557, lng: 19.4009219 },
    { lat: 49.5741342, lng: 19.4000179 },
    { lat: 49.5739901, lng: 19.3995185 },
    { lat: 49.5738773, lng: 19.398289 },
    { lat: 49.5741107, lng: 19.3968733 },
    { lat: 49.5742678, lng: 19.3961088 },
    { lat: 49.5739188, lng: 19.3950767 },
    { lat: 49.5728507, lng: 19.3938121 },
    { lat: 49.5724421, lng: 19.392864 },
    { lat: 49.5720719, lng: 19.3918038 },
    { lat: 49.5717614, lng: 19.3898847 },
    { lat: 49.5715925, lng: 19.3876005 },
    { lat: 49.5718712, lng: 19.3869933 },
    { lat: 49.5719834, lng: 19.3855823 },
    { lat: 49.5720849, lng: 19.3840749 },
    { lat: 49.5718229, lng: 19.3831819 },
    { lat: 49.5714487, lng: 19.3821459 },
    { lat: 49.5709071, lng: 19.3807605 },
    { lat: 49.5706596, lng: 19.3795658 },
    { lat: 49.5705032, lng: 19.3775166 },
    { lat: 49.5700294, lng: 19.3772515 },
    { lat: 49.5696322, lng: 19.3764537 },
    { lat: 49.569181, lng: 19.3757525 },
    { lat: 49.5689503, lng: 19.3745474 },
    { lat: 49.5686078, lng: 19.3737813 },
    { lat: 49.5680041, lng: 19.372901 },
    { lat: 49.5677401, lng: 19.3721804 },
    { lat: 49.5676571, lng: 19.3719525 },
    { lat: 49.5674421, lng: 19.3713627 },
    { lat: 49.5666793, lng: 19.3712475 },
    { lat: 49.5660606, lng: 19.3711596 },
    { lat: 49.5651059, lng: 19.3715392 },
    { lat: 49.5643795, lng: 19.371415 },
    { lat: 49.562635, lng: 19.3722085 },
    { lat: 49.559446, lng: 19.3736393 },
    { lat: 49.5594415, lng: 19.3736365 },
    { lat: 49.5590245, lng: 19.3735931 },
    { lat: 49.5578504, lng: 19.3734541 },
    { lat: 49.5566672, lng: 19.3723942 },
    { lat: 49.556403, lng: 19.3721441 },
    { lat: 49.5563987, lng: 19.3721394 },
    { lat: 49.5556969, lng: 19.3714236 },
    { lat: 49.5556189, lng: 19.3712597 },
    { lat: 49.5552806, lng: 19.3705732 },
    { lat: 49.5545068, lng: 19.3690969 },
    { lat: 49.5537032, lng: 19.3665935 },
    { lat: 49.5525754, lng: 19.3664162 },
    { lat: 49.5508109, lng: 19.3670508 },
    { lat: 49.5508046, lng: 19.3670551 },
    { lat: 49.5498208, lng: 19.3675009 },
    { lat: 49.5498115, lng: 19.3675035 },
    { lat: 49.5488388, lng: 19.36779 },
    { lat: 49.5478184, lng: 19.3680901 },
    { lat: 49.5473482, lng: 19.3688012 },
    { lat: 49.5462592, lng: 19.3687937 },
    { lat: 49.5450334, lng: 19.3685743 },
    { lat: 49.5449497, lng: 19.3685117 },
    { lat: 49.5449156, lng: 19.3684876 },
    { lat: 49.5449053, lng: 19.3684817 },
    { lat: 49.5437046, lng: 19.3678427 },
    { lat: 49.5436727, lng: 19.3678292 },
    { lat: 49.54367, lng: 19.367823 },
    { lat: 49.543153, lng: 19.3676914 },
    { lat: 49.5414658, lng: 19.3673055 },
    { lat: 49.5400766, lng: 19.366669 },
    { lat: 49.5389461, lng: 19.3661482 },
    { lat: 49.5389339, lng: 19.3661381 },
    { lat: 49.5389108, lng: 19.366121 },
    { lat: 49.537776, lng: 19.3649207 },
    { lat: 49.537774, lng: 19.364915 },
    { lat: 49.5355099, lng: 19.3662925 },
    { lat: 49.5354133, lng: 19.3662748 },
    { lat: 49.5349068, lng: 19.3658023 },
    { lat: 49.5345027, lng: 19.3653743 },
    { lat: 49.5342046, lng: 19.3648284 },
    { lat: 49.5338392, lng: 19.3643647 },
    { lat: 49.5337313, lng: 19.3640758 },
    { lat: 49.533456, lng: 19.3637059 },
    { lat: 49.5332546, lng: 19.3632539 },
    { lat: 49.5331213, lng: 19.3631959 },
    { lat: 49.5329038, lng: 19.3628799 },
    { lat: 49.5326854, lng: 19.3627875 },
    { lat: 49.5325791, lng: 19.3628539 },
    { lat: 49.5325242, lng: 19.3628868 },
    { lat: 49.5324707, lng: 19.3629188 },
    { lat: 49.5323142, lng: 19.3628479 },
    { lat: 49.5314811, lng: 19.3630348 },
    { lat: 49.5314518, lng: 19.3629376 },
    { lat: 49.5313254, lng: 19.3628844 },
    { lat: 49.5309431, lng: 19.3625139 },
    { lat: 49.5308147, lng: 19.3623488 },
    { lat: 49.5296601, lng: 19.3637319 },
    { lat: 49.5287208, lng: 19.3649535 },
    { lat: 49.5274394, lng: 19.3667657 },
    { lat: 49.5243758, lng: 19.3713433 },
    { lat: 49.5243435, lng: 19.3713295 },
    { lat: 49.5240822, lng: 19.3714981 },
    { lat: 49.5240804, lng: 19.3716068 },
    { lat: 49.5240774, lng: 19.3717616 },
    { lat: 49.5240209, lng: 19.37212 },
    { lat: 49.5239099, lng: 19.3722312 },
    { lat: 49.5239146, lng: 19.3724752 },
    { lat: 49.5240815, lng: 19.373302 },
    { lat: 49.5241332, lng: 19.3736169 },
    { lat: 49.5242997, lng: 19.3735878 },
    { lat: 49.5247604, lng: 19.3745285 },
    { lat: 49.5247255, lng: 19.374894 },
    { lat: 49.5248127, lng: 19.375217 },
    { lat: 49.5247822, lng: 19.3755316 },
    { lat: 49.5250182, lng: 19.3757622 },
    { lat: 49.525129, lng: 19.3765465 },
    { lat: 49.5252838, lng: 19.3768694 },
    { lat: 49.525393, lng: 19.3769191 },
    { lat: 49.5253855, lng: 19.3770915 },
    { lat: 49.5257732, lng: 19.3775543 },
    { lat: 49.5258233, lng: 19.3777385 },
    { lat: 49.5257171, lng: 19.3779485 },
    { lat: 49.5258651, lng: 19.3783498 },
    { lat: 49.5257729, lng: 19.3787125 },
    { lat: 49.5260499, lng: 19.3791257 },
    { lat: 49.5259411, lng: 19.379253 },
    { lat: 49.525909, lng: 19.3793671 },
    { lat: 49.5261797, lng: 19.3797858 },
    { lat: 49.526074, lng: 19.3803015 },
    { lat: 49.5258566, lng: 19.3807345 },
    { lat: 49.5259002, lng: 19.380947 },
    { lat: 49.5258848, lng: 19.3811032 },
    { lat: 49.5257797, lng: 19.3811441 },
    { lat: 49.5255435, lng: 19.3811783 },
    { lat: 49.5256704, lng: 19.3816269 },
    { lat: 49.5256468, lng: 19.3820455 },
    { lat: 49.5255329, lng: 19.3822625 },
    { lat: 49.5253399, lng: 19.3824483 },
    { lat: 49.5253733, lng: 19.3828527 },
    { lat: 49.5252848, lng: 19.3831276 },
    { lat: 49.5250381, lng: 19.3833276 },
    { lat: 49.5250945, lng: 19.3837139 },
    { lat: 49.5251713, lng: 19.383745 },
    { lat: 49.5254918, lng: 19.3838028 },
    { lat: 49.5254952, lng: 19.384386 },
    { lat: 49.5255307, lng: 19.3848859 },
    { lat: 49.5254923, lng: 19.3858303 },
    { lat: 49.5255458, lng: 19.3862045 },
    { lat: 49.525575, lng: 19.3867897 },
    { lat: 49.5255482, lng: 19.3868691 },
    { lat: 49.5261204, lng: 19.3876154 },
    { lat: 49.5261353, lng: 19.3876957 },
    { lat: 49.5278386, lng: 19.3900964 },
    { lat: 49.5280474, lng: 19.3902723 },
    { lat: 49.5282946, lng: 19.3903799 },
    { lat: 49.5287334, lng: 19.3905133 },
    { lat: 49.5296642, lng: 19.390439 },
    { lat: 49.5301265, lng: 19.3902307 },
    { lat: 49.5301355, lng: 19.3910557 },
    { lat: 49.5304393, lng: 19.3913867 },
    { lat: 49.5304161, lng: 19.3919098 },
    { lat: 49.5303832, lng: 19.3920962 },
    { lat: 49.5303749, lng: 19.3921874 },
    { lat: 49.5304688, lng: 19.3923643 },
    { lat: 49.5303588, lng: 19.3928602 },
    { lat: 49.5305815, lng: 19.3931921 },
    { lat: 49.5304676, lng: 19.3936032 },
    { lat: 49.5306305, lng: 19.39374 },
    { lat: 49.5307825, lng: 19.3941779 },
    { lat: 49.530819, lng: 19.3942112 },
    { lat: 49.5308762, lng: 19.394254 },
    { lat: 49.5309449, lng: 19.3942802 },
    { lat: 49.5308811, lng: 19.394482 },
    { lat: 49.5311345, lng: 19.3948917 },
    { lat: 49.5310981, lng: 19.3949581 },
    { lat: 49.5310694, lng: 19.39502 },
    { lat: 49.5310217, lng: 19.3951065 },
    { lat: 49.5310967, lng: 19.3953406 },
    { lat: 49.5311346, lng: 19.3957213 },
    { lat: 49.5312774, lng: 19.3955726 },
    { lat: 49.5314089, lng: 19.3956384 },
    { lat: 49.5315023, lng: 19.3962688 },
    { lat: 49.5316528, lng: 19.3961124 },
    { lat: 49.5317856, lng: 19.396227 },
    { lat: 49.5318334, lng: 19.3963821 },
    { lat: 49.5319864, lng: 19.396648 },
    { lat: 49.5321594, lng: 19.3967847 },
    { lat: 49.5323482, lng: 19.3970373 },
    { lat: 49.5323681, lng: 19.3970794 },
    { lat: 49.532414, lng: 19.3970965 },
    { lat: 49.5329219, lng: 19.3972869 },
    { lat: 49.5329757, lng: 19.3973076 },
    { lat: 49.532993, lng: 19.397314 },
    { lat: 49.5329542, lng: 19.3976233 },
    { lat: 49.5329446, lng: 19.3977071 },
    { lat: 49.5329909, lng: 19.3982175 },
    { lat: 49.5330666, lng: 19.3983224 },
    { lat: 49.5332662, lng: 19.3985959 },
    { lat: 49.533361, lng: 19.3986951 },
    { lat: 49.5334546, lng: 19.3987937 },
    { lat: 49.5335268, lng: 19.39887 },
    { lat: 49.5337327, lng: 19.3988396 },
    { lat: 49.5339612, lng: 19.3988063 },
    { lat: 49.5344002, lng: 19.3988247 },
    { lat: 49.5346611, lng: 19.3987375 },
    { lat: 49.5348195, lng: 19.3987064 },
    { lat: 49.5349213, lng: 19.3986859 },
    { lat: 49.5350259, lng: 19.398538 },
    { lat: 49.5353147, lng: 19.3981317 },
    { lat: 49.5353528, lng: 19.3980775 },
    { lat: 49.5354544, lng: 19.3980842 },
    { lat: 49.5355008, lng: 19.3980871 },
    { lat: 49.5357265, lng: 19.3984376 },
    { lat: 49.5358468, lng: 19.3986243 },
    { lat: 49.5359644, lng: 19.3986619 },
    { lat: 49.5360069, lng: 19.3986754 },
    { lat: 49.5362582, lng: 19.3985297 },
    { lat: 49.536288, lng: 19.3986128 },
    { lat: 49.5363268, lng: 19.398721 },
    { lat: 49.5363291, lng: 19.3987255 },
    { lat: 49.5363265, lng: 19.3987414 },
    { lat: 49.5363126, lng: 19.3988546 },
    { lat: 49.5363013, lng: 19.3989513 },
    { lat: 49.5362852, lng: 19.3990884 },
    { lat: 49.5369001, lng: 19.3991108 },
    { lat: 49.5369548, lng: 19.399242 },
    { lat: 49.5369649, lng: 19.3992659 },
    { lat: 49.5369363, lng: 19.3993322 },
    { lat: 49.5367672, lng: 19.3997199 },
    { lat: 49.5367916, lng: 19.3998791 },
    { lat: 49.5368222, lng: 19.4000804 },
    { lat: 49.5368498, lng: 19.4002646 },
    { lat: 49.5370039, lng: 19.4003378 },
    { lat: 49.5370732, lng: 19.400394 },
    { lat: 49.53736, lng: 19.400453 },
    { lat: 49.5373897, lng: 19.4006098 },
    { lat: 49.5374034, lng: 19.400687 },
    { lat: 49.5373163, lng: 19.4008246 },
    { lat: 49.5372386, lng: 19.4009477 },
    { lat: 49.5372376, lng: 19.4009507 },
    { lat: 49.5372473, lng: 19.4009623 },
    { lat: 49.5376106, lng: 19.4013889 },
    { lat: 49.5380426, lng: 19.4015921 },
    { lat: 49.5381287, lng: 19.4016327 },
    { lat: 49.5382092, lng: 19.4018101 },
    { lat: 49.5384386, lng: 19.4023144 },
    { lat: 49.538624, lng: 19.4026925 },
    { lat: 49.5386869, lng: 19.40315 },
    { lat: 49.5386229, lng: 19.403441 },
    { lat: 49.5382464, lng: 19.405151 },
    { lat: 49.5382166, lng: 19.405118 },
    { lat: 49.5382153, lng: 19.4051098 },
    { lat: 49.5381833, lng: 19.4049087 },
    { lat: 49.5380979, lng: 19.4048396 },
    { lat: 49.5380228, lng: 19.4048231 },
    { lat: 49.5376865, lng: 19.4050314 },
    { lat: 49.5375893, lng: 19.4050279 },
    { lat: 49.5375094, lng: 19.4049785 },
    { lat: 49.5374276, lng: 19.4050121 },
    { lat: 49.5373353, lng: 19.4052587 },
    { lat: 49.5371764, lng: 19.4053287 },
    { lat: 49.5371066, lng: 19.4053062 },
    { lat: 49.5370153, lng: 19.4053078 },
    { lat: 49.5368906, lng: 19.4051694 },
    { lat: 49.5367198, lng: 19.4052529 },
    { lat: 49.5365195, lng: 19.4052098 },
    { lat: 49.5361779, lng: 19.4052779 },
    { lat: 49.5361657, lng: 19.40528 },
    { lat: 49.5358244, lng: 19.4048935 },
    { lat: 49.535413, lng: 19.4047947 },
    { lat: 49.535184, lng: 19.4047638 },
    { lat: 49.5350352, lng: 19.4046857 },
    { lat: 49.5350103, lng: 19.4046833 },
    { lat: 49.5348008, lng: 19.4046616 },
    { lat: 49.5343436, lng: 19.4049345 },
    { lat: 49.5341678, lng: 19.4051311 },
    { lat: 49.5341108, lng: 19.4051656 },
    { lat: 49.5338381, lng: 19.4050691 },
    { lat: 49.5337657, lng: 19.405061 },
    { lat: 49.5336308, lng: 19.4050946 },
    { lat: 49.5333468, lng: 19.4051873 },
    { lat: 49.5330954, lng: 19.4053046 },
    { lat: 49.5326731, lng: 19.4054095 },
    { lat: 49.5324799, lng: 19.4054944 },
    { lat: 49.5318916, lng: 19.4058796 },
    { lat: 49.5316827, lng: 19.4059466 },
    { lat: 49.5313605, lng: 19.4059955 },
    { lat: 49.5310573, lng: 19.4064348 },
    { lat: 49.5309354, lng: 19.4066923 },
    { lat: 49.53025, lng: 19.4074449 },
    { lat: 49.5301649, lng: 19.4074578 },
    { lat: 49.5295784, lng: 19.4076883 },
    { lat: 49.5294913, lng: 19.407729 },
    { lat: 49.5294316, lng: 19.4077561 },
    { lat: 49.5293079, lng: 19.4078788 },
    { lat: 49.5291895, lng: 19.4079269 },
    { lat: 49.5288536, lng: 19.4082671 },
    { lat: 49.5288195, lng: 19.4083557 },
    { lat: 49.5286298, lng: 19.4085947 },
    { lat: 49.5283648, lng: 19.4087564 },
    { lat: 49.5282051, lng: 19.408912 },
    { lat: 49.5280011, lng: 19.4091794 },
    { lat: 49.5279904, lng: 19.4091925 },
    { lat: 49.5277476, lng: 19.4093492 },
    { lat: 49.5274598, lng: 19.4097792 },
    { lat: 49.5273754, lng: 19.4099045 },
    { lat: 49.5272252, lng: 19.4099705 },
    { lat: 49.5271357, lng: 19.4099803 },
    { lat: 49.526385, lng: 19.4100755 },
    { lat: 49.5259609, lng: 19.4100636 },
    { lat: 49.5258678, lng: 19.4100716 },
    { lat: 49.5257395, lng: 19.41005 },
    { lat: 49.5256506, lng: 19.4100514 },
    { lat: 49.5255584, lng: 19.4100799 },
    { lat: 49.5252305, lng: 19.4101787 },
    { lat: 49.5252249, lng: 19.4101801 },
    { lat: 49.5245668, lng: 19.410131 },
    { lat: 49.5234243, lng: 19.410423 },
    { lat: 49.5233264, lng: 19.4109625 },
    { lat: 49.5230921, lng: 19.4117939 },
    { lat: 49.5229901, lng: 19.4123156 },
    { lat: 49.5229928, lng: 19.4124514 },
    { lat: 49.5226926, lng: 19.4126077 },
    { lat: 49.5227252, lng: 19.4138308 },
    { lat: 49.5225543, lng: 19.4142777 },
    { lat: 49.5225323, lng: 19.4148502 },
    { lat: 49.5223935, lng: 19.4153328 },
    { lat: 49.5223589, lng: 19.415436 },
    { lat: 49.5221948, lng: 19.4159122 },
    { lat: 49.5218927, lng: 19.4166175 },
    { lat: 49.5216978, lng: 19.4170594 },
    { lat: 49.5213687, lng: 19.4175224 },
    { lat: 49.5213103, lng: 19.417746 },
    { lat: 49.5211934, lng: 19.4178977 },
    { lat: 49.521162, lng: 19.4180008 },
    { lat: 49.520978, lng: 19.4182609 },
    { lat: 49.5209491, lng: 19.4183413 },
    { lat: 49.5207947, lng: 19.4186112 },
    { lat: 49.5207666, lng: 19.4186368 },
    { lat: 49.5206608, lng: 19.4188829 },
    { lat: 49.5204911, lng: 19.419115 },
    { lat: 49.5203616, lng: 19.4191893 },
    { lat: 49.5200621, lng: 19.4191667 },
    { lat: 49.5200581, lng: 19.419227 },
    { lat: 49.5191298, lng: 19.4192547 },
    { lat: 49.519, lng: 19.4191734 },
    { lat: 49.5185117, lng: 19.419336 },
    { lat: 49.5181522, lng: 19.4199485 },
    { lat: 49.5180485, lng: 19.4200707 },
    { lat: 49.5180329, lng: 19.4202686 },
    { lat: 49.5179555, lng: 19.4204239 },
    { lat: 49.51806, lng: 19.4204887 },
    { lat: 49.5180715, lng: 19.4209596 },
    { lat: 49.5179138, lng: 19.4213742 },
    { lat: 49.5178962, lng: 19.4220688 },
    { lat: 49.5179401, lng: 19.4223867 },
    { lat: 49.5177093, lng: 19.4229518 },
    { lat: 49.5176919, lng: 19.4231118 },
    { lat: 49.5175828, lng: 19.4233108 },
    { lat: 49.5175683, lng: 19.4235604 },
    { lat: 49.5173583, lng: 19.4240405 },
    { lat: 49.5173715, lng: 19.4242048 },
    { lat: 49.5174263, lng: 19.4241941 },
    { lat: 49.5170507, lng: 19.424801 },
    { lat: 49.5169436, lng: 19.4252373 },
    { lat: 49.5167445, lng: 19.4253191 },
    { lat: 49.5166258, lng: 19.4254832 },
    { lat: 49.516363, lng: 19.4256945 },
    { lat: 49.5163695, lng: 19.4257174 },
    { lat: 49.5162166, lng: 19.4259189 },
    { lat: 49.5161029, lng: 19.4261427 },
    { lat: 49.5156696, lng: 19.4267145 },
    { lat: 49.5152383, lng: 19.4273321 },
    { lat: 49.5147747, lng: 19.4277853 },
    { lat: 49.5146941, lng: 19.4278444 },
    { lat: 49.5144577, lng: 19.4280956 },
    { lat: 49.5143048, lng: 19.4282964 },
    { lat: 49.5142251, lng: 19.428448 },
    { lat: 49.5141663, lng: 19.42852 },
    { lat: 49.5140197, lng: 19.4287614 },
    { lat: 49.5139388, lng: 19.4287883 },
    { lat: 49.5137909, lng: 19.4291046 },
    { lat: 49.513467, lng: 19.4292642 },
    { lat: 49.5133698, lng: 19.429353 },
    { lat: 49.5132879, lng: 19.4294823 },
    { lat: 49.513004, lng: 19.4298503 },
    { lat: 49.5128563, lng: 19.4300216 },
    { lat: 49.5127167, lng: 19.4301477 },
    { lat: 49.5126668, lng: 19.430214 },
    { lat: 49.5126565, lng: 19.4302815 },
    { lat: 49.5126077, lng: 19.4303712 },
    { lat: 49.5125686, lng: 19.4303777 },
    { lat: 49.5124841, lng: 19.4304753 },
    { lat: 49.5124572, lng: 19.4305058 },
    { lat: 49.5121652, lng: 19.430846 },
    { lat: 49.512021, lng: 19.4311619 },
    { lat: 49.5119892, lng: 19.4312078 },
    { lat: 49.5119422, lng: 19.4313122 },
    { lat: 49.5118429, lng: 19.4315293 },
    { lat: 49.5115605, lng: 19.4319284 },
    { lat: 49.5114934, lng: 19.43199 },
    { lat: 49.5113405, lng: 19.4321661 },
    { lat: 49.5110159, lng: 19.4325578 },
    { lat: 49.5108708, lng: 19.4329546 },
    { lat: 49.5108463, lng: 19.4330849 },
    { lat: 49.510719, lng: 19.4333297 },
    { lat: 49.5106344, lng: 19.4336463 },
    { lat: 49.510584, lng: 19.4337529 },
    { lat: 49.5105379, lng: 19.4338029 },
    { lat: 49.510221, lng: 19.4343612 },
    { lat: 49.5098838, lng: 19.4347193 },
    { lat: 49.5097849, lng: 19.4348737 },
    { lat: 49.5097242, lng: 19.4349702 },
    { lat: 49.5096449, lng: 19.4351104 },
    { lat: 49.5096044, lng: 19.4351836 },
    { lat: 49.50934, lng: 19.4356572 },
    { lat: 49.5090411, lng: 19.4361914 },
    { lat: 49.5089839, lng: 19.4362933 },
    { lat: 49.5088508, lng: 19.4363915 },
    { lat: 49.5088311, lng: 19.4364063 },
    { lat: 49.5087812, lng: 19.4367369 },
    { lat: 49.5081494, lng: 19.4373851 },
    { lat: 49.5081097, lng: 19.4374257 },
    { lat: 49.5081052, lng: 19.4376967 },
    { lat: 49.5076801, lng: 19.4382304 },
    { lat: 49.5076768, lng: 19.43823 },
    { lat: 49.5073945, lng: 19.4381906 },
    { lat: 49.5073742, lng: 19.4384615 },
    { lat: 49.5073475, lng: 19.4388106 },
    { lat: 49.5071291, lng: 19.4397011 },
    { lat: 49.5071188, lng: 19.4396961 },
    { lat: 49.5070669, lng: 19.4396676 },
    { lat: 49.5069895, lng: 19.4396267 },
    { lat: 49.5067551, lng: 19.4401154 },
    { lat: 49.5067585, lng: 19.4402162 },
    { lat: 49.5067763, lng: 19.4406283 },
    { lat: 49.5067772, lng: 19.4406482 },
    { lat: 49.5067591, lng: 19.4406723 },
    { lat: 49.5067251, lng: 19.4407169 },
    { lat: 49.5066805, lng: 19.440775 },
    { lat: 49.5066295, lng: 19.4408418 },
    { lat: 49.5066126, lng: 19.4408632 },
    { lat: 49.5065848, lng: 19.4409725 },
    { lat: 49.5065714, lng: 19.4410254 },
    { lat: 49.5065341, lng: 19.4411705 },
    { lat: 49.5065314, lng: 19.4411831 },
    { lat: 49.506301, lng: 19.4415648 },
    { lat: 49.5063001, lng: 19.44157 },
    { lat: 49.5062761, lng: 19.4418079 },
    { lat: 49.5062547, lng: 19.442027 },
    { lat: 49.5062494, lng: 19.4420337 },
    { lat: 49.5060288, lng: 19.4423368 },
    { lat: 49.5059409, lng: 19.4424579 },
    { lat: 49.5058552, lng: 19.442576 },
    { lat: 49.5056551, lng: 19.4427332 },
    { lat: 49.505407, lng: 19.4429283 },
    { lat: 49.505308, lng: 19.4430057 },
    { lat: 49.5052352, lng: 19.4430861 },
    { lat: 49.505084, lng: 19.4432519 },
    { lat: 49.5050726, lng: 19.4432984 },
    { lat: 49.5050431, lng: 19.4434172 },
    { lat: 49.5050128, lng: 19.4435423 },
    { lat: 49.5050052, lng: 19.4435736 },
    { lat: 49.5049902, lng: 19.4435448 },
    { lat: 49.5048995, lng: 19.4433592 },
    { lat: 49.5048734, lng: 19.4433044 },
    { lat: 49.5045662, lng: 19.4435606 },
    { lat: 49.5045749, lng: 19.4436524 },
    { lat: 49.5045892, lng: 19.4437897 },
    { lat: 49.504586, lng: 19.4437923 },
    { lat: 49.50421, lng: 19.4440722 },
    { lat: 49.5041872, lng: 19.4440888 },
    { lat: 49.5038958, lng: 19.444468 },
    { lat: 49.5038692, lng: 19.4445021 },
    { lat: 49.5038556, lng: 19.4444713 },
    { lat: 49.5037608, lng: 19.4442738 },
    { lat: 49.5036793, lng: 19.4443646 },
    { lat: 49.5035454, lng: 19.4445136 },
    { lat: 49.5031787, lng: 19.4449205 },
    { lat: 49.5031369, lng: 19.4449663 },
    { lat: 49.5030958, lng: 19.4449091 },
    { lat: 49.5030923, lng: 19.4449034 },
    { lat: 49.5024775, lng: 19.4439573 },
    { lat: 49.5024606, lng: 19.4439298 },
    { lat: 49.5024533, lng: 19.4439194 },
    { lat: 49.5015869, lng: 19.4444865 },
    { lat: 49.5011732, lng: 19.4447996 },
    { lat: 49.5007943, lng: 19.4449638 },
    { lat: 49.500716, lng: 19.4450223 },
    { lat: 49.5003649, lng: 19.4455011 },
    { lat: 49.5001048, lng: 19.4458022 },
    { lat: 49.4998916, lng: 19.4461149 },
    { lat: 49.4995899, lng: 19.4463889 },
    { lat: 49.4991758, lng: 19.4466708 },
    { lat: 49.4992975, lng: 19.4471111 },
    { lat: 49.4993737, lng: 19.4474708 },
    { lat: 49.4994306, lng: 19.4478051 },
    { lat: 49.4994963, lng: 19.4483515 },
    { lat: 49.4995755, lng: 19.4487765 },
    { lat: 49.4996848, lng: 19.4492653 },
    { lat: 49.4998471, lng: 19.4499329 },
    { lat: 49.4999773, lng: 19.4506348 },
    { lat: 49.5001265, lng: 19.4515564 },
    { lat: 49.5002119, lng: 19.4520649 },
    { lat: 49.5002276, lng: 19.4524079 },
    { lat: 49.5002444, lng: 19.4527023 },
    { lat: 49.5002409, lng: 19.4527704 },
    { lat: 49.5002419, lng: 19.4527921 },
    { lat: 49.5002427, lng: 19.452809 },
    { lat: 49.5002397, lng: 19.4528924 },
    { lat: 49.5002397, lng: 19.4529659 },
    { lat: 49.5002383, lng: 19.4529867 },
    { lat: 49.5001205, lng: 19.4543001 },
    { lat: 49.5001198, lng: 19.45431 },
    { lat: 49.5001248, lng: 19.4549808 },
    { lat: 49.5001349, lng: 19.4550838 },
    { lat: 49.5001835, lng: 19.4560471 },
    { lat: 49.5001911, lng: 19.4563102 },
    { lat: 49.5001801, lng: 19.4563393 },
    { lat: 49.5001878, lng: 19.4564799 },
    { lat: 49.5002485, lng: 19.4573158 },
    { lat: 49.5002736, lng: 19.4574483 },
    { lat: 49.5002813, lng: 19.457492 },
    { lat: 49.5002834, lng: 19.457805 },
    { lat: 49.5002878, lng: 19.4580006 },
    { lat: 49.500306, lng: 19.4585732 },
    { lat: 49.5003089, lng: 19.4586092 },
    { lat: 49.5003265, lng: 19.4589491 },
    { lat: 49.5003279, lng: 19.4594423 },
    { lat: 49.5003294, lng: 19.4596709 },
    { lat: 49.5003299, lng: 19.4602048 },
    { lat: 49.5003305, lng: 19.4608308 },
    { lat: 49.5003402, lng: 19.4611665 },
    { lat: 49.5003462, lng: 19.4613953 },
    { lat: 49.5003496, lng: 19.461445 },
    { lat: 49.5003522, lng: 19.4615527 },
    { lat: 49.5003594, lng: 19.4618546 },
    { lat: 49.5003638, lng: 19.4619303 },
    { lat: 49.5003648, lng: 19.461998 },
    { lat: 49.5003665, lng: 19.4621076 },
    { lat: 49.5003729, lng: 19.4621291 },
    { lat: 49.5003842, lng: 19.462175 },
    { lat: 49.5004274, lng: 19.4623338 },
    { lat: 49.5004749, lng: 19.4625103 },
    { lat: 49.5004951, lng: 19.4626074 },
    { lat: 49.5005452, lng: 19.4628398 },
    { lat: 49.5005781, lng: 19.4630179 },
    { lat: 49.5006051, lng: 19.4631647 },
    { lat: 49.500609, lng: 19.4631763 },
    { lat: 49.5006578, lng: 19.4633294 },
    { lat: 49.5007167, lng: 19.4635076 },
    { lat: 49.5007428, lng: 19.4635885 },
    { lat: 49.5008235, lng: 19.4637531 },
    { lat: 49.500974, lng: 19.4640602 },
    { lat: 49.5009993, lng: 19.4641231 },
    { lat: 49.5011646, lng: 19.4645316 },
    { lat: 49.5011887, lng: 19.4645685 },
    { lat: 49.5011913, lng: 19.4645722 },
    { lat: 49.5012261, lng: 19.4646475 },
    { lat: 49.5012643, lng: 19.464718 },
    { lat: 49.5012815, lng: 19.4647492 },
    { lat: 49.5013135, lng: 19.4648084 },
    { lat: 49.5013554, lng: 19.4648873 },
    { lat: 49.5013714, lng: 19.4649171 },
    { lat: 49.5014009, lng: 19.4649687 },
    { lat: 49.501423, lng: 19.4650087 },
    { lat: 49.501439, lng: 19.4650382 },
    { lat: 49.501466, lng: 19.4650874 },
    { lat: 49.5015484, lng: 19.465237 },
    { lat: 49.5016431, lng: 19.4654131 },
    { lat: 49.5017186, lng: 19.465588 },
    { lat: 49.5018041, lng: 19.4657793 },
    { lat: 49.5019421, lng: 19.466041 },
    { lat: 49.5019582, lng: 19.466073 },
    { lat: 49.502024, lng: 19.4661783 },
    { lat: 49.5020923, lng: 19.4662942 },
    { lat: 49.5021046, lng: 19.4663161 },
    { lat: 49.5021622, lng: 19.4664193 },
    { lat: 49.50225, lng: 19.4665621 },
    { lat: 49.5023325, lng: 19.4666943 },
    { lat: 49.5023448, lng: 19.4667143 },
    { lat: 49.5023766, lng: 19.46677 },
    { lat: 49.502515, lng: 19.4670143 },
    { lat: 49.5025911, lng: 19.4671496 },
    { lat: 49.5026775, lng: 19.4673879 },
    { lat: 49.5027138, lng: 19.4674891 },
    { lat: 49.5027702, lng: 19.467619 },
    { lat: 49.5029542, lng: 19.4680447 },
    { lat: 49.5031494, lng: 19.4684917 },
    { lat: 49.5032457, lng: 19.4687246 },
    { lat: 49.503304, lng: 19.4688677 },
    { lat: 49.5035241, lng: 19.4694182 },
    { lat: 49.503669, lng: 19.4698039 },
    { lat: 49.5037619, lng: 19.4700393 },
    { lat: 49.5038402, lng: 19.4702234 },
    { lat: 49.5039151, lng: 19.470406 },
    { lat: 49.5040288, lng: 19.4706773 },
    { lat: 49.5041241, lng: 19.4708624 },
    { lat: 49.504201, lng: 19.4709955 },
    { lat: 49.5042611, lng: 19.4711022 },
    { lat: 49.5043028, lng: 19.4711771 },
    { lat: 49.5043338, lng: 19.4712357 },
    { lat: 49.5044422, lng: 19.4714369 },
    { lat: 49.5044975, lng: 19.4715419 },
    { lat: 49.5046292, lng: 19.4717854 },
    { lat: 49.5047329, lng: 19.4719831 },
    { lat: 49.5048096, lng: 19.4721311 },
    { lat: 49.5048722, lng: 19.4722451 },
    { lat: 49.5049302, lng: 19.4723588 },
    { lat: 49.5049798, lng: 19.4724575 },
    { lat: 49.5051448, lng: 19.4727864 },
    { lat: 49.505161, lng: 19.4728219 },
    { lat: 49.5052263, lng: 19.4729683 },
    { lat: 49.505296, lng: 19.4731163 },
    { lat: 49.505312, lng: 19.473145 },
    { lat: 49.5053673, lng: 19.4732457 },
    { lat: 49.5055161, lng: 19.4734689 },
    { lat: 49.5061843, lng: 19.4743603 },
    { lat: 49.5067085, lng: 19.4749561 },
    { lat: 49.5068004, lng: 19.4750198 },
    { lat: 49.5068228, lng: 19.4750457 },
    { lat: 49.5068764, lng: 19.4751074 },
    { lat: 49.5072664, lng: 19.4756038 },
    { lat: 49.5076027, lng: 19.4760882 },
    { lat: 49.5080368, lng: 19.4766601 },
    { lat: 49.5083323, lng: 19.4771141 },
    { lat: 49.5084208, lng: 19.4772724 },
    { lat: 49.5086269, lng: 19.4776415 },
    { lat: 49.5088055, lng: 19.4779801 },
    { lat: 49.5089642, lng: 19.478244 },
    { lat: 49.5092256, lng: 19.4786387 },
    { lat: 49.5092666, lng: 19.478701 },
    { lat: 49.509292, lng: 19.4787396 },
    { lat: 49.509309, lng: 19.4787664 },
    { lat: 49.5093261, lng: 19.4787939 },
    { lat: 49.5093357, lng: 19.4788074 },
    { lat: 49.5093645, lng: 19.4788485 },
    { lat: 49.5094915, lng: 19.4790343 },
    { lat: 49.5094963, lng: 19.4790421 },
    { lat: 49.5096476, lng: 19.4793007 },
    { lat: 49.5097114, lng: 19.4794102 },
    { lat: 49.509802, lng: 19.4795491 },
    { lat: 49.5100465, lng: 19.4799191 },
    { lat: 49.510174, lng: 19.4800439 },
    { lat: 49.5102169, lng: 19.4801455 },
    { lat: 49.5103022, lng: 19.4802346 },
    { lat: 49.5103276, lng: 19.4803011 },
    { lat: 49.510361, lng: 19.4803646 },
    { lat: 49.5103935, lng: 19.4803836 },
    { lat: 49.5104195, lng: 19.4804098 },
    { lat: 49.5105528, lng: 19.4805688 },
    { lat: 49.5105515, lng: 19.4806657 },
    { lat: 49.5106243, lng: 19.4808276 },
    { lat: 49.510696, lng: 19.480966 },
    { lat: 49.5108119, lng: 19.4811882 },
    { lat: 49.5109215, lng: 19.4813653 },
    { lat: 49.5110928, lng: 19.4816373 },
    { lat: 49.5111474, lng: 19.4817286 },
    { lat: 49.5112281, lng: 19.4818407 },
    { lat: 49.5113659, lng: 19.4820456 },
    { lat: 49.5115692, lng: 19.4823258 },
    { lat: 49.5117988, lng: 19.4826445 },
    { lat: 49.5120296, lng: 19.4829637 },
    { lat: 49.5120702, lng: 19.4830123 },
    { lat: 49.512142, lng: 19.4831047 },
    { lat: 49.5121756, lng: 19.4831475 },
    { lat: 49.5123639, lng: 19.4833745 },
    { lat: 49.5125684, lng: 19.483614 },
    { lat: 49.5127302, lng: 19.4838229 },
    { lat: 49.5128931, lng: 19.4840349 },
    { lat: 49.5129613, lng: 19.4841236 },
    { lat: 49.5130004, lng: 19.4841673 },
    { lat: 49.5131229, lng: 19.4843079 },
    { lat: 49.5133045, lng: 19.4844842 },
    { lat: 49.5134585, lng: 19.4846511 },
    { lat: 49.5135955, lng: 19.4847669 },
    { lat: 49.5136426, lng: 19.4847862 },
    { lat: 49.5138068, lng: 19.4849273 },
    { lat: 49.5140405, lng: 19.4851611 },
    { lat: 49.5140618, lng: 19.4851828 },
    { lat: 49.5142898, lng: 19.4854879 },
    { lat: 49.5144545, lng: 19.4857403 },
    { lat: 49.5144812, lng: 19.4857817 },
    { lat: 49.5146283, lng: 19.485969 },
    { lat: 49.514623, lng: 19.4859754 },
    { lat: 49.5146208, lng: 19.4859796 },
    { lat: 49.5146472, lng: 19.4860155 },
    { lat: 49.5146648, lng: 19.4860292 },
    { lat: 49.5146968, lng: 19.486041 },
    { lat: 49.5147586, lng: 19.4860605 },
    { lat: 49.5148147, lng: 19.4860809 },
    { lat: 49.514911, lng: 19.4861149 },
    { lat: 49.5149796, lng: 19.4861405 },
    { lat: 49.5150448, lng: 19.4861607 },
    { lat: 49.5150744, lng: 19.4861695 },
    { lat: 49.5151649, lng: 19.4862022 },
    { lat: 49.5151823, lng: 19.486209 },
    { lat: 49.5152332, lng: 19.486218 },
    { lat: 49.5152621, lng: 19.4861848 },
    { lat: 49.5152898, lng: 19.4861485 },
    { lat: 49.5153035, lng: 19.4861296 },
    { lat: 49.5153427, lng: 19.4860464 },
    { lat: 49.5153752, lng: 19.4859725 },
    { lat: 49.5154058, lng: 19.4859497 },
    { lat: 49.5154783, lng: 19.4858821 },
    { lat: 49.5155207, lng: 19.4858753 },
    { lat: 49.5156359, lng: 19.4858543 },
    { lat: 49.5157017, lng: 19.4857177 },
    { lat: 49.515714, lng: 19.4856921 },
    { lat: 49.5157679, lng: 19.4856098 },
    { lat: 49.5157956, lng: 19.4855757 },
    { lat: 49.5158352, lng: 19.4855076 },
    { lat: 49.5158768, lng: 19.4854767 },
    { lat: 49.515953, lng: 19.4854202 },
    { lat: 49.5160064, lng: 19.4853837 },
    { lat: 49.5160827, lng: 19.4853273 },
    { lat: 49.5161288, lng: 19.485298 },
    { lat: 49.5161976, lng: 19.4851764 },
    { lat: 49.5162171, lng: 19.4851326 },
    { lat: 49.5162704, lng: 19.4849971 },
    { lat: 49.5163302, lng: 19.4848481 },
    { lat: 49.5164424, lng: 19.4848383 },
    { lat: 49.5164735, lng: 19.484729 },
    { lat: 49.5164682, lng: 19.4845385 },
    { lat: 49.5165338, lng: 19.4845181 },
    { lat: 49.5166357, lng: 19.4844785 },
    { lat: 49.5167102, lng: 19.4844556 },
    { lat: 49.5167683, lng: 19.4844715 },
    { lat: 49.5168481, lng: 19.4844934 },
    { lat: 49.5168641, lng: 19.4844978 },
    { lat: 49.5169657, lng: 19.484525 },
    { lat: 49.5170111, lng: 19.4845379 },
    { lat: 49.5170536, lng: 19.4845529 },
    { lat: 49.5171026, lng: 19.484565 },
    { lat: 49.5172714, lng: 19.4846129 },
    { lat: 49.5172981, lng: 19.484577 },
    { lat: 49.5173671, lng: 19.484484 },
    { lat: 49.5173814, lng: 19.4844249 },
    { lat: 49.5174211, lng: 19.4842871 },
    { lat: 49.5174472, lng: 19.4841915 },
    { lat: 49.5174628, lng: 19.4841594 },
    { lat: 49.5175097, lng: 19.4841014 },
    { lat: 49.5175841, lng: 19.4840035 },
    { lat: 49.5176584, lng: 19.4839291 },
    { lat: 49.5177812, lng: 19.4838064 },
    { lat: 49.5178205, lng: 19.4837761 },
    { lat: 49.5179503, lng: 19.4838321 },
    { lat: 49.5180277, lng: 19.4838985 },
    { lat: 49.5181397, lng: 19.4839851 },
    { lat: 49.5182213, lng: 19.483972 },
    { lat: 49.5183077, lng: 19.4839627 },
    { lat: 49.518356, lng: 19.4839597 },
    { lat: 49.5184004, lng: 19.4838915 },
    { lat: 49.5185078, lng: 19.4837305 },
    { lat: 49.5185198, lng: 19.4836216 },
    { lat: 49.5185376, lng: 19.4834416 },
    { lat: 49.5186014, lng: 19.4833093 },
    { lat: 49.5186536, lng: 19.4832928 },
    { lat: 49.5186934, lng: 19.4832751 },
    { lat: 49.5187719, lng: 19.4832471 },
    { lat: 49.5188447, lng: 19.4832131 },
    { lat: 49.5189092, lng: 19.4831928 },
    { lat: 49.5189555, lng: 19.4831682 },
    { lat: 49.5190488, lng: 19.4831647 },
    { lat: 49.5191869, lng: 19.4831542 },
    { lat: 49.5192912, lng: 19.4830422 },
    { lat: 49.5193809, lng: 19.4829398 },
    { lat: 49.5194608, lng: 19.4828851 },
    { lat: 49.5195955, lng: 19.4827985 },
    { lat: 49.5197073, lng: 19.4827292 },
    { lat: 49.5197442, lng: 19.4827009 },
    { lat: 49.5197556, lng: 19.4827283 },
    { lat: 49.5197737, lng: 19.4827579 },
    { lat: 49.5200879, lng: 19.482429 },
    { lat: 49.5202693, lng: 19.4822336 },
    { lat: 49.5203909, lng: 19.4820842 },
    { lat: 49.5206438, lng: 19.4817952 },
    { lat: 49.5209097, lng: 19.4815019 },
    { lat: 49.5209958, lng: 19.4814114 },
    { lat: 49.521117, lng: 19.4814767 },
    { lat: 49.5212757, lng: 19.4815695 },
    { lat: 49.5213033, lng: 19.4815861 },
    { lat: 49.5213681, lng: 19.4815984 },
    { lat: 49.5215371, lng: 19.4816227 },
    { lat: 49.5215903, lng: 19.481626 },
    { lat: 49.5217668, lng: 19.4816183 },
    { lat: 49.5218169, lng: 19.4816078 },
    { lat: 49.521858, lng: 19.4815679 },
    { lat: 49.5219765, lng: 19.4813241 },
    { lat: 49.5220988, lng: 19.4810912 },
    { lat: 49.5223257, lng: 19.4808322 },
    { lat: 49.5224038, lng: 19.4807643 },
    { lat: 49.5224642, lng: 19.4807067 },
    { lat: 49.5225011, lng: 19.4806719 },
    { lat: 49.5225387, lng: 19.4806301 },
    { lat: 49.5225967, lng: 19.4805713 },
    { lat: 49.5226641, lng: 19.4804919 },
    { lat: 49.5227859, lng: 19.4803004 },
    { lat: 49.5228188, lng: 19.4802555 },
    { lat: 49.5228567, lng: 19.4801963 },
    { lat: 49.5229022, lng: 19.4801323 },
    { lat: 49.5229574, lng: 19.4800534 },
    { lat: 49.5229808, lng: 19.4800223 },
    { lat: 49.5231067, lng: 19.479822 },
    { lat: 49.5231277, lng: 19.4797845 },
    { lat: 49.5231567, lng: 19.4797332 },
    { lat: 49.5232457, lng: 19.4795365 },
    { lat: 49.5232988, lng: 19.4794122 },
    { lat: 49.5233466, lng: 19.4792993 },
    { lat: 49.52338, lng: 19.4792192 },
    { lat: 49.5234205, lng: 19.4791906 },
    { lat: 49.5235299, lng: 19.479095 },
    { lat: 49.5235861, lng: 19.4790433 },
    { lat: 49.5235978, lng: 19.4790047 },
    { lat: 49.5235981, lng: 19.4789582 },
    { lat: 49.5236044, lng: 19.4789006 },
    { lat: 49.5236109, lng: 19.4788205 },
    { lat: 49.5236191, lng: 19.4786528 },
    { lat: 49.5236272, lng: 19.4784365 },
    { lat: 49.5236331, lng: 19.4782708 },
    { lat: 49.5236806, lng: 19.4781493 },
    { lat: 49.523686, lng: 19.4781226 },
    { lat: 49.523694, lng: 19.4780953 },
    { lat: 49.5237526, lng: 19.4779504 },
    { lat: 49.5239022, lng: 19.4776703 },
    { lat: 49.5239486, lng: 19.4775797 },
    { lat: 49.5240669, lng: 19.4773581 },
    { lat: 49.5241633, lng: 19.4771457 },
    { lat: 49.5242447, lng: 19.4770314 },
    { lat: 49.5243895, lng: 19.4768465 },
    { lat: 49.5245272, lng: 19.4768273 },
    { lat: 49.5245685, lng: 19.4768164 },
    { lat: 49.5246255, lng: 19.4768081 },
    { lat: 49.5247646, lng: 19.4767953 },
    { lat: 49.5248887, lng: 19.4767246 },
    { lat: 49.5250654, lng: 19.476521 },
    { lat: 49.5251371, lng: 19.4763639 },
    { lat: 49.5251942, lng: 19.4762344 },
    { lat: 49.5252223, lng: 19.4761345 },
    { lat: 49.5252578, lng: 19.4759969 },
    { lat: 49.525298, lng: 19.4758631 },
    { lat: 49.5253482, lng: 19.4757079 },
    { lat: 49.52541, lng: 19.4755301 },
    { lat: 49.525441, lng: 19.4753704 },
    { lat: 49.5254522, lng: 19.4753213 },
    { lat: 49.5255005, lng: 19.4752146 },
    { lat: 49.5256068, lng: 19.474979 },
    { lat: 49.5256856, lng: 19.4749771 },
    { lat: 49.5257254, lng: 19.4749862 },
    { lat: 49.5257721, lng: 19.4750207 },
    { lat: 49.5258121, lng: 19.4750599 },
    { lat: 49.5260587, lng: 19.475502 },
    { lat: 49.5261116, lng: 19.4755757 },
    { lat: 49.52615, lng: 19.475626 },
    { lat: 49.5260925, lng: 19.4757732 },
    { lat: 49.5261608, lng: 19.475961 },
    { lat: 49.526247, lng: 19.476194 },
    { lat: 49.5263226, lng: 19.4763966 },
    { lat: 49.5263908, lng: 19.4765833 },
    { lat: 49.5264757, lng: 19.4768132 },
    { lat: 49.5265652, lng: 19.4770476 },
    { lat: 49.5266516, lng: 19.4772846 },
    { lat: 49.5267387, lng: 19.4775168 },
    { lat: 49.5268084, lng: 19.4777117 },
    { lat: 49.5268363, lng: 19.4777526 },
    { lat: 49.5268869, lng: 19.4778262 },
    { lat: 49.5270052, lng: 19.4779981 },
    { lat: 49.5270916, lng: 19.4781382 },
    { lat: 49.5271526, lng: 19.4782441 },
    { lat: 49.5272525, lng: 19.4783849 },
    { lat: 49.5273861, lng: 19.4785704 },
    { lat: 49.5275288, lng: 19.4787839 },
    { lat: 49.5276967, lng: 19.4790296 },
    { lat: 49.5278188, lng: 19.4792119 },
    { lat: 49.5279253, lng: 19.479372 },
    { lat: 49.5280618, lng: 19.4795746 },
    { lat: 49.5281801, lng: 19.4797534 },
    { lat: 49.5283011, lng: 19.4799319 },
    { lat: 49.5284443, lng: 19.4801559 },
    { lat: 49.5285978, lng: 19.4803856 },
    { lat: 49.5287613, lng: 19.4806365 },
    { lat: 49.5289163, lng: 19.4808744 },
    { lat: 49.529041, lng: 19.4810659 },
    { lat: 49.5291719, lng: 19.4812644 },
    { lat: 49.5292908, lng: 19.48145 },
    { lat: 49.5294469, lng: 19.4816904 },
    { lat: 49.5295193, lng: 19.4817969 },
    { lat: 49.5296407, lng: 19.4819841 },
    { lat: 49.5297458, lng: 19.4821418 },
    { lat: 49.529862, lng: 19.4823183 },
    { lat: 49.5299818, lng: 19.4825013 },
    { lat: 49.5301113, lng: 19.4826959 },
    { lat: 49.5302895, lng: 19.4829713 },
    { lat: 49.5304569, lng: 19.4832348 },
    { lat: 49.5305675, lng: 19.483414 },
    { lat: 49.5306742, lng: 19.4835788 },
    { lat: 49.5308017, lng: 19.4837824 },
    { lat: 49.530922, lng: 19.4839715 },
    { lat: 49.5310396, lng: 19.4841526 },
    { lat: 49.5311362, lng: 19.4842942 },
    { lat: 49.5312608, lng: 19.484485 },
    { lat: 49.5313782, lng: 19.4846639 },
    { lat: 49.5315137, lng: 19.4848692 },
    { lat: 49.5316105, lng: 19.4850165 },
    { lat: 49.5317223, lng: 19.4851688 },
    { lat: 49.5321422, lng: 19.4858049 },
    { lat: 49.5324664, lng: 19.4862935 },
    { lat: 49.5328424, lng: 19.4868574 },
    { lat: 49.5332067, lng: 19.4874121 },
    { lat: 49.5335794, lng: 19.4879768 },
    { lat: 49.5339169, lng: 19.4884836 },
    { lat: 49.534246, lng: 19.4889807 },
    { lat: 49.5344321, lng: 19.4892593 },
    { lat: 49.5345365, lng: 19.4906399 },
    { lat: 49.5345748, lng: 19.491135 },
    { lat: 49.5345868, lng: 19.4912751 },
    { lat: 49.5346003, lng: 19.4914474 },
    { lat: 49.5346127, lng: 19.4915938 },
    { lat: 49.5346244, lng: 19.4917525 },
    { lat: 49.5346318, lng: 19.4918703 },
    { lat: 49.5345744, lng: 19.4919407 },
    { lat: 49.5345089, lng: 19.4920181 },
    { lat: 49.5344361, lng: 19.4920981 },
    { lat: 49.5343528, lng: 19.492197 },
    { lat: 49.5342648, lng: 19.4922971 },
    { lat: 49.534174, lng: 19.4924037 },
    { lat: 49.5340885, lng: 19.4925072 },
    { lat: 49.5339997, lng: 19.492611 },
    { lat: 49.5339129, lng: 19.492711 },
    { lat: 49.5338937, lng: 19.4927337 },
    { lat: 49.5338566, lng: 19.4927849 },
    { lat: 49.5338827, lng: 19.4930596 },
    { lat: 49.5339409, lng: 19.493202 },
    { lat: 49.5340306, lng: 19.4933879 },
    { lat: 49.5341088, lng: 19.493525 },
    { lat: 49.5343296, lng: 19.4938963 },
    { lat: 49.5343761, lng: 19.4939788 },
    { lat: 49.5344017, lng: 19.4940435 },
    { lat: 49.534413, lng: 19.4940934 },
    { lat: 49.5344181, lng: 19.4941332 },
    { lat: 49.5343816, lng: 19.4944686 },
    { lat: 49.5344067, lng: 19.4945018 },
    { lat: 49.5345852, lng: 19.4945636 },
    { lat: 49.5346693, lng: 19.4948255 },
    { lat: 49.5348183, lng: 19.4953029 },
    { lat: 49.5350601, lng: 19.4957162 },
    { lat: 49.5351255, lng: 19.49599 },
    { lat: 49.535151, lng: 19.4960533 },
    { lat: 49.5352545, lng: 19.4962021 },
    { lat: 49.5352652, lng: 19.4962384 },
    { lat: 49.5353104, lng: 19.4964399 },
    { lat: 49.5353296, lng: 19.4964886 },
    { lat: 49.5354175, lng: 19.4965888 },
    { lat: 49.5354444, lng: 19.4966127 },
    { lat: 49.535521, lng: 19.49661 },
    { lat: 49.535566, lng: 19.4966813 },
    { lat: 49.5356069, lng: 19.4969889 },
    { lat: 49.535657, lng: 19.4972973 },
    { lat: 49.5356774, lng: 19.4974235 },
    { lat: 49.5356907, lng: 19.4974943 },
    { lat: 49.5357591, lng: 19.4977111 },
    { lat: 49.5358398, lng: 19.4977043 },
    { lat: 49.5360899, lng: 19.4976746 },
    { lat: 49.5362921, lng: 19.4976152 },
    { lat: 49.5363843, lng: 19.4975868 },
    { lat: 49.536517, lng: 19.4976546 },
    { lat: 49.5365507, lng: 19.4977003 },
    { lat: 49.5366161, lng: 19.4978054 },
    { lat: 49.5366409, lng: 19.4978771 },
    { lat: 49.5366447, lng: 19.4980087 },
    { lat: 49.5366552, lng: 19.4982184 },
    { lat: 49.5368735, lng: 19.4983103 },
    { lat: 49.5371615, lng: 19.4985758 },
    { lat: 49.537395, lng: 19.4987329 },
    { lat: 49.5375048, lng: 19.4988151 },
    { lat: 49.5378201, lng: 19.4995058 },
    { lat: 49.5383757, lng: 19.4995587 },
    { lat: 49.5386791, lng: 19.4995911 },
    { lat: 49.5389712, lng: 19.4993545 },
    { lat: 49.5394753, lng: 19.4993857 },
    { lat: 49.5398322, lng: 19.4993083 },
    { lat: 49.5399686, lng: 19.4993572 },
    { lat: 49.540057, lng: 19.4993963 },
    { lat: 49.5405264, lng: 19.4992626 },
    { lat: 49.5407542, lng: 19.4991961 },
    { lat: 49.5407709, lng: 19.4991631 },
    { lat: 49.5409438, lng: 19.4987056 },
    { lat: 49.5410721, lng: 19.498481 },
    { lat: 49.5412404, lng: 19.4985102 },
    { lat: 49.5412902, lng: 19.4985164 },
    { lat: 49.5412781, lng: 19.4985518 },
    { lat: 49.5412431, lng: 19.4986492 },
    { lat: 49.5411992, lng: 19.4987725 },
    { lat: 49.5412194, lng: 19.4989438 },
    { lat: 49.541226, lng: 19.4990156 },
    { lat: 49.5412361, lng: 19.4990875 },
    { lat: 49.5412442, lng: 19.4991675 },
    { lat: 49.5412552, lng: 19.4992588 },
    { lat: 49.5411955, lng: 19.4993839 },
    { lat: 49.5411564, lng: 19.4994693 },
    { lat: 49.5411053, lng: 19.4995807 },
    { lat: 49.5410519, lng: 19.4996942 },
    { lat: 49.5409977, lng: 19.4998165 },
    { lat: 49.5409641, lng: 19.499897 },
    { lat: 49.5409585, lng: 19.4999723 },
    { lat: 49.5409533, lng: 19.5001071 },
    { lat: 49.5409482, lng: 19.5002176 },
    { lat: 49.5409427, lng: 19.5002954 },
    { lat: 49.540928, lng: 19.5004196 },
    { lat: 49.54092, lng: 19.5005134 },
    { lat: 49.5409064, lng: 19.5006596 },
    { lat: 49.5408935, lng: 19.5007709 },
    { lat: 49.5408757, lng: 19.5009048 },
    { lat: 49.5408622, lng: 19.5010296 },
    { lat: 49.5408516, lng: 19.5011429 },
    { lat: 49.5408336, lng: 19.5013142 },
    { lat: 49.5408233, lng: 19.5014096 },
    { lat: 49.5408146, lng: 19.501494 },
    { lat: 49.54081, lng: 19.5015892 },
    { lat: 49.5408066, lng: 19.5016403 },
    { lat: 49.5407985, lng: 19.5018103 },
    { lat: 49.5407825, lng: 19.5019263 },
    { lat: 49.5407599, lng: 19.5020742 },
    { lat: 49.5407433, lng: 19.5021815 },
    { lat: 49.5407151, lng: 19.5023028 },
    { lat: 49.5406841, lng: 19.5024164 },
    { lat: 49.5406575, lng: 19.5025266 },
    { lat: 49.5406301, lng: 19.502642 },
    { lat: 49.5405968, lng: 19.5027755 },
    { lat: 49.5405772, lng: 19.5029162 },
    { lat: 49.5405528, lng: 19.5030508 },
    { lat: 49.5405319, lng: 19.5031826 },
    { lat: 49.5405052, lng: 19.5033393 },
    { lat: 49.5404787, lng: 19.5034985 },
    { lat: 49.5404609, lng: 19.5036059 },
    { lat: 49.540442, lng: 19.5037088 },
    { lat: 49.5404222, lng: 19.5038208 },
    { lat: 49.5404015, lng: 19.5039355 },
    { lat: 49.5403845, lng: 19.5040339 },
    { lat: 49.5403646, lng: 19.5041437 },
    { lat: 49.540341, lng: 19.5042892 },
    { lat: 49.5403396, lng: 19.5043358 },
    { lat: 49.540326, lng: 19.504458 },
    { lat: 49.5403156, lng: 19.5045781 },
    { lat: 49.5403089, lng: 19.5046732 },
    { lat: 49.5402953, lng: 19.504802 },
    { lat: 49.5402832, lng: 19.5049556 },
    { lat: 49.5402733, lng: 19.5050815 },
    { lat: 49.540259, lng: 19.5052147 },
    { lat: 49.5402457, lng: 19.5053681 },
    { lat: 49.5402283, lng: 19.5055568 },
    { lat: 49.5402145, lng: 19.5057013 },
    { lat: 49.5402007, lng: 19.5058696 },
    { lat: 49.540163, lng: 19.5060079 },
    { lat: 49.5401328, lng: 19.5061323 },
    { lat: 49.540119, lng: 19.5061776 },
    { lat: 49.5401337, lng: 19.5063299 },
    { lat: 49.5401415, lng: 19.5064502 },
    { lat: 49.5401307, lng: 19.5065856 },
    { lat: 49.54012, lng: 19.5067206 },
    { lat: 49.5401099, lng: 19.5068472 },
    { lat: 49.5400967, lng: 19.5070028 },
    { lat: 49.5400674, lng: 19.5071268 },
    { lat: 49.5400404, lng: 19.5072508 },
    { lat: 49.5400133, lng: 19.5073763 },
    { lat: 49.5399859, lng: 19.5074914 },
    { lat: 49.5399597, lng: 19.5076113 },
    { lat: 49.5399341, lng: 19.5077389 },
    { lat: 49.5399042, lng: 19.5078756 },
    { lat: 49.5398764, lng: 19.5080084 },
    { lat: 49.539749, lng: 19.5086025 },
    { lat: 49.5396803, lng: 19.5089262 },
    { lat: 49.5396659, lng: 19.5092071 },
    { lat: 49.5396652, lng: 19.5093171 },
    { lat: 49.5396483, lng: 19.510212 },
    { lat: 49.5395982, lng: 19.5111958 },
    { lat: 49.539443, lng: 19.5123185 },
    { lat: 49.5393906, lng: 19.5126803 },
    { lat: 49.5393785, lng: 19.5127146 },
    { lat: 49.5393386, lng: 19.5128244 },
    { lat: 49.539153, lng: 19.5133547 },
    { lat: 49.5388473, lng: 19.5139703 },
    { lat: 49.5386097, lng: 19.5144176 },
    { lat: 49.5385846, lng: 19.5144624 },
    { lat: 49.538418, lng: 19.5151901 },
    { lat: 49.538291, lng: 19.5156894 },
    { lat: 49.5382288, lng: 19.515938 },
    { lat: 49.5380059, lng: 19.5173363 },
    { lat: 49.5379836, lng: 19.5174378 },
    { lat: 49.5379758, lng: 19.5174629 },
    { lat: 49.5380728, lng: 19.517791 },
    { lat: 49.5381623, lng: 19.5178761 },
    { lat: 49.538362, lng: 19.518059 },
    { lat: 49.5384075, lng: 19.5183183 },
    { lat: 49.5386541, lng: 19.5185404 },
    { lat: 49.538694, lng: 19.5188217 },
    { lat: 49.5386784, lng: 19.519103 },
    { lat: 49.5388077, lng: 19.5193421 },
    { lat: 49.5390817, lng: 19.5194975 },
    { lat: 49.5390911, lng: 19.519532 },
    { lat: 49.5391414, lng: 19.5197 },
    { lat: 49.5393329, lng: 19.5197478 },
    { lat: 49.5394628, lng: 19.5199538 },
    { lat: 49.5395429, lng: 19.5200795 },
    { lat: 49.5398807, lng: 19.5209518 },
    { lat: 49.5399622, lng: 19.5210109 },
    { lat: 49.5400412, lng: 19.5210598 },
    { lat: 49.5402069, lng: 19.5210661 },
    { lat: 49.5402433, lng: 19.5210709 },
    { lat: 49.5403643, lng: 19.5211309 },
    { lat: 49.5403841, lng: 19.5213443 },
    { lat: 49.5403823, lng: 19.5214058 },
    { lat: 49.5403811, lng: 19.5215028 },
    { lat: 49.5404494, lng: 19.5214937 },
    { lat: 49.540552, lng: 19.5216696 },
    { lat: 49.5407199, lng: 19.5218428 },
    { lat: 49.5409886, lng: 19.5225288 },
    { lat: 49.5412558, lng: 19.5234062 },
    { lat: 49.5413697, lng: 19.5237329 },
    { lat: 49.5415918, lng: 19.5243383 },
    { lat: 49.5416258, lng: 19.5246666 },
    { lat: 49.5416623, lng: 19.5250001 },
    { lat: 49.541689, lng: 19.5251935 },
    { lat: 49.5417209, lng: 19.5254248 },
    { lat: 49.5417441, lng: 19.5255874 },
    { lat: 49.5417575, lng: 19.5256128 },
    { lat: 49.5418807, lng: 19.5259204 },
    { lat: 49.5419724, lng: 19.5261526 },
    { lat: 49.5420559, lng: 19.5263548 },
    { lat: 49.5421191, lng: 19.526511 },
    { lat: 49.5421713, lng: 19.5266418 },
    { lat: 49.5422425, lng: 19.5268233 },
    { lat: 49.5423396, lng: 19.5270763 },
    { lat: 49.5424138, lng: 19.5272712 },
    { lat: 49.5424788, lng: 19.5274398 },
    { lat: 49.5425569, lng: 19.5276749 },
    { lat: 49.542591, lng: 19.5277823 },
    { lat: 49.5426694, lng: 19.5280181 },
    { lat: 49.5427477, lng: 19.5282561 },
    { lat: 49.5428272, lng: 19.5284962 },
    { lat: 49.5427376, lng: 19.5286813 },
    { lat: 49.5426799, lng: 19.5287996 },
    { lat: 49.542684, lng: 19.529015 },
    { lat: 49.5426845, lng: 19.5290788 },
    { lat: 49.5426835, lng: 19.5291311 },
    { lat: 49.5426802, lng: 19.5291826 },
    { lat: 49.5426626, lng: 19.5292166 },
    { lat: 49.5480214, lng: 19.5295662 },
    { lat: 49.5492832, lng: 19.5299312 },
    { lat: 49.5547682, lng: 19.5286414 },
    { lat: 49.5573917, lng: 19.5281425 },
    { lat: 49.5576214, lng: 19.5280988 },
    { lat: 49.5611735, lng: 19.527408 },
    { lat: 49.5613311, lng: 19.5280218 },
    { lat: 49.5633279, lng: 19.5283613 },
    { lat: 49.5645695, lng: 19.5285795 },
    { lat: 49.5691645, lng: 19.5293611 },
    { lat: 49.5705244, lng: 19.5296046 },
    { lat: 49.5728433, lng: 19.5293499 },
    { lat: 49.5730216, lng: 19.529331 },
  ],
  OravskéVeselé: [
    { lat: 49.4450061, lng: 19.3502355 },
    { lat: 49.4443574, lng: 19.351805 },
    { lat: 49.4441192, lng: 19.353778 },
    { lat: 49.4438094, lng: 19.3547167 },
    { lat: 49.4432878, lng: 19.3573252 },
    { lat: 49.4431376, lng: 19.3578964 },
    { lat: 49.443304, lng: 19.3587353 },
    { lat: 49.4431781, lng: 19.3593445 },
    { lat: 49.4430886, lng: 19.3598153 },
    { lat: 49.4430418, lng: 19.3605537 },
    { lat: 49.4429256, lng: 19.3610332 },
    { lat: 49.4426796, lng: 19.3614097 },
    { lat: 49.4425329, lng: 19.3617676 },
    { lat: 49.442361, lng: 19.3610326 },
    { lat: 49.4416949, lng: 19.3605219 },
    { lat: 49.4408612, lng: 19.3598965 },
    { lat: 49.4405822, lng: 19.3593267 },
    { lat: 49.4407409, lng: 19.3587908 },
    { lat: 49.4400847, lng: 19.3580575 },
    { lat: 49.439964, lng: 19.3582026 },
    { lat: 49.4395585, lng: 19.3583988 },
    { lat: 49.4387061, lng: 19.3586771 },
    { lat: 49.4386574, lng: 19.3585246 },
    { lat: 49.4382947, lng: 19.3582859 },
    { lat: 49.4380765, lng: 19.3578613 },
    { lat: 49.4377671, lng: 19.3581889 },
    { lat: 49.4380175, lng: 19.3587722 },
    { lat: 49.4379089, lng: 19.3595116 },
    { lat: 49.4377951, lng: 19.3600415 },
    { lat: 49.4377296, lng: 19.3617045 },
    { lat: 49.4379753, lng: 19.3634188 },
    { lat: 49.4386149, lng: 19.3650772 },
    { lat: 49.4386172, lng: 19.3667668 },
    { lat: 49.4385439, lng: 19.3668165 },
    { lat: 49.4387101, lng: 19.3670677 },
    { lat: 49.4387605, lng: 19.3674764 },
    { lat: 49.4389677, lng: 19.3674729 },
    { lat: 49.4390549, lng: 19.367699 },
    { lat: 49.4393152, lng: 19.3680844 },
    { lat: 49.4393076, lng: 19.3684281 },
    { lat: 49.4395539, lng: 19.3686872 },
    { lat: 49.4395633, lng: 19.3686977 },
    { lat: 49.4396696, lng: 19.3690876 },
    { lat: 49.4398243, lng: 19.3696515 },
    { lat: 49.4399043, lng: 19.3699432 },
    { lat: 49.4400379, lng: 19.3700546 },
    { lat: 49.4402852, lng: 19.3704269 },
    { lat: 49.4403133, lng: 19.3709322 },
    { lat: 49.4403222, lng: 19.3710786 },
    { lat: 49.440179, lng: 19.3711989 },
    { lat: 49.4401875, lng: 19.3713041 },
    { lat: 49.4402266, lng: 19.3713476 },
    { lat: 49.4403169, lng: 19.3714464 },
    { lat: 49.4403503, lng: 19.3715086 },
    { lat: 49.4404498, lng: 19.3716893 },
    { lat: 49.4404518, lng: 19.3717578 },
    { lat: 49.4404222, lng: 19.3718668 },
    { lat: 49.440493, lng: 19.3722049 },
    { lat: 49.4405162, lng: 19.3722432 },
    { lat: 49.4405312, lng: 19.3722499 },
    { lat: 49.4406922, lng: 19.3723206 },
    { lat: 49.4407131, lng: 19.3723785 },
    { lat: 49.4407311, lng: 19.3724301 },
    { lat: 49.4407074, lng: 19.3725507 },
    { lat: 49.4408407, lng: 19.3731622 },
    { lat: 49.4409328, lng: 19.3734928 },
    { lat: 49.4409922, lng: 19.3738242 },
    { lat: 49.4410798, lng: 19.3738457 },
    { lat: 49.4410853, lng: 19.3742771 },
    { lat: 49.441064, lng: 19.3744225 },
    { lat: 49.4410685, lng: 19.3744748 },
    { lat: 49.4411271, lng: 19.3745213 },
    { lat: 49.4411715, lng: 19.3745563 },
    { lat: 49.4411871, lng: 19.3745984 },
    { lat: 49.4412916, lng: 19.3748846 },
    { lat: 49.4413071, lng: 19.3751918 },
    { lat: 49.4413362, lng: 19.3752389 },
    { lat: 49.4413638, lng: 19.3752493 },
    { lat: 49.441404, lng: 19.3752652 },
    { lat: 49.4414934, lng: 19.3752988 },
    { lat: 49.4414979, lng: 19.3753201 },
    { lat: 49.4415045, lng: 19.3753443 },
    { lat: 49.4415087, lng: 19.3753833 },
    { lat: 49.441549, lng: 19.3757414 },
    { lat: 49.4416007, lng: 19.3759092 },
    { lat: 49.4416339, lng: 19.3759425 },
    { lat: 49.4416999, lng: 19.3760077 },
    { lat: 49.4417272, lng: 19.3760344 },
    { lat: 49.4418227, lng: 19.3761289 },
    { lat: 49.4418595, lng: 19.3762136 },
    { lat: 49.4419627, lng: 19.3764478 },
    { lat: 49.4419662, lng: 19.3764978 },
    { lat: 49.4419448, lng: 19.3769365 },
    { lat: 49.4420041, lng: 19.3769977 },
    { lat: 49.4420314, lng: 19.3770266 },
    { lat: 49.4420321, lng: 19.3770917 },
    { lat: 49.442031, lng: 19.3774536 },
    { lat: 49.442036, lng: 19.3774907 },
    { lat: 49.4420847, lng: 19.3776197 },
    { lat: 49.4421401, lng: 19.3777635 },
    { lat: 49.4422101, lng: 19.3778424 },
    { lat: 49.4423017, lng: 19.3779436 },
    { lat: 49.4423077, lng: 19.3779501 },
    { lat: 49.4424949, lng: 19.3782943 },
    { lat: 49.4424964, lng: 19.3783264 },
    { lat: 49.4425183, lng: 19.378727 },
    { lat: 49.4425304, lng: 19.3787695 },
    { lat: 49.4425587, lng: 19.3788185 },
    { lat: 49.442867, lng: 19.3793664 },
    { lat: 49.4428656, lng: 19.3794114 },
    { lat: 49.4428551, lng: 19.3797385 },
    { lat: 49.4428118, lng: 19.380143 },
    { lat: 49.4428583, lng: 19.3806097 },
    { lat: 49.4428232, lng: 19.3806777 },
    { lat: 49.4426972, lng: 19.3806856 },
    { lat: 49.4426767, lng: 19.380727 },
    { lat: 49.4426485, lng: 19.3809441 },
    { lat: 49.442667, lng: 19.3809812 },
    { lat: 49.4426944, lng: 19.3810361 },
    { lat: 49.442837, lng: 19.3813146 },
    { lat: 49.442836, lng: 19.3813661 },
    { lat: 49.4428367, lng: 19.3814558 },
    { lat: 49.4428362, lng: 19.3816384 },
    { lat: 49.4427846, lng: 19.3820072 },
    { lat: 49.4427976, lng: 19.3820938 },
    { lat: 49.4428579, lng: 19.3824663 },
    { lat: 49.4428292, lng: 19.3825556 },
    { lat: 49.4427769, lng: 19.3827152 },
    { lat: 49.4427811, lng: 19.3828263 },
    { lat: 49.4427915, lng: 19.383171 },
    { lat: 49.4427734, lng: 19.3832439 },
    { lat: 49.4426156, lng: 19.3838989 },
    { lat: 49.4425129, lng: 19.3840854 },
    { lat: 49.4425312, lng: 19.3841406 },
    { lat: 49.4425672, lng: 19.3841674 },
    { lat: 49.442607, lng: 19.3841953 },
    { lat: 49.4426197, lng: 19.3842044 },
    { lat: 49.4427247, lng: 19.3842802 },
    { lat: 49.4427298, lng: 19.384486 },
    { lat: 49.44273, lng: 19.38449 },
    { lat: 49.4427375, lng: 19.3845098 },
    { lat: 49.4428374, lng: 19.3847683 },
    { lat: 49.4428167, lng: 19.3849538 },
    { lat: 49.4427937, lng: 19.3851591 },
    { lat: 49.4427993, lng: 19.3853066 },
    { lat: 49.4428061, lng: 19.3854775 },
    { lat: 49.4427902, lng: 19.3855209 },
    { lat: 49.4427534, lng: 19.3856248 },
    { lat: 49.4427334, lng: 19.3856806 },
    { lat: 49.4427369, lng: 19.385732 },
    { lat: 49.4427551, lng: 19.3857822 },
    { lat: 49.4427784, lng: 19.385847 },
    { lat: 49.4428827, lng: 19.3861307 },
    { lat: 49.4428908, lng: 19.3863311 },
    { lat: 49.4428456, lng: 19.3863737 },
    { lat: 49.4428035, lng: 19.3864135 },
    { lat: 49.4426438, lng: 19.3865638 },
    { lat: 49.4426332, lng: 19.3866059 },
    { lat: 49.4426595, lng: 19.3868311 },
    { lat: 49.4426657, lng: 19.3868924 },
    { lat: 49.4426672, lng: 19.3868995 },
    { lat: 49.4426726, lng: 19.3871136 },
    { lat: 49.442788, lng: 19.3873661 },
    { lat: 49.4427984, lng: 19.3874221 },
    { lat: 49.4427862, lng: 19.3874499 },
    { lat: 49.4427719, lng: 19.3874819 },
    { lat: 49.4427444, lng: 19.3875454 },
    { lat: 49.4427322, lng: 19.387572 },
    { lat: 49.4427119, lng: 19.3876177 },
    { lat: 49.4426803, lng: 19.3876896 },
    { lat: 49.4426654, lng: 19.3878321 },
    { lat: 49.4426828, lng: 19.3878671 },
    { lat: 49.4427041, lng: 19.3879126 },
    { lat: 49.4427166, lng: 19.3879387 },
    { lat: 49.4426641, lng: 19.3881708 },
    { lat: 49.4426586, lng: 19.3881953 },
    { lat: 49.4426402, lng: 19.3882089 },
    { lat: 49.4425247, lng: 19.3882947 },
    { lat: 49.4424415, lng: 19.3884207 },
    { lat: 49.4424343, lng: 19.3885555 },
    { lat: 49.4424832, lng: 19.3886175 },
    { lat: 49.4425121, lng: 19.3886542 },
    { lat: 49.4426161, lng: 19.388784 },
    { lat: 49.4427236, lng: 19.3889178 },
    { lat: 49.442712, lng: 19.3891009 },
    { lat: 49.4427063, lng: 19.3891967 },
    { lat: 49.4427031, lng: 19.3892033 },
    { lat: 49.4426828, lng: 19.3892493 },
    { lat: 49.4425043, lng: 19.3896522 },
    { lat: 49.4424829, lng: 19.3898465 },
    { lat: 49.4425028, lng: 19.3899341 },
    { lat: 49.4425038, lng: 19.3899355 },
    { lat: 49.4425334, lng: 19.3899634 },
    { lat: 49.4425876, lng: 19.3900161 },
    { lat: 49.4426808, lng: 19.3901066 },
    { lat: 49.4427536, lng: 19.3901696 },
    { lat: 49.442864, lng: 19.390264 },
    { lat: 49.4430389, lng: 19.3904144 },
    { lat: 49.4431198, lng: 19.3904838 },
    { lat: 49.4431309, lng: 19.3905047 },
    { lat: 49.4431468, lng: 19.3905326 },
    { lat: 49.4432466, lng: 19.3907144 },
    { lat: 49.4433321, lng: 19.3909332 },
    { lat: 49.443329, lng: 19.3909386 },
    { lat: 49.4433071, lng: 19.3909754 },
    { lat: 49.4432894, lng: 19.3910041 },
    { lat: 49.4432705, lng: 19.3910355 },
    { lat: 49.4432517, lng: 19.3910672 },
    { lat: 49.4431868, lng: 19.391175 },
    { lat: 49.4431824, lng: 19.3912287 },
    { lat: 49.4432226, lng: 19.3914637 },
    { lat: 49.443232, lng: 19.3915195 },
    { lat: 49.4432567, lng: 19.3916645 },
    { lat: 49.4433422, lng: 19.3917045 },
    { lat: 49.4434872, lng: 19.3917723 },
    { lat: 49.443605, lng: 19.3919553 },
    { lat: 49.4436198, lng: 19.3919834 },
    { lat: 49.4437108, lng: 19.3921491 },
    { lat: 49.4437181, lng: 19.3921831 },
    { lat: 49.4437741, lng: 19.3924199 },
    { lat: 49.4438672, lng: 19.3928222 },
    { lat: 49.4439997, lng: 19.3930826 },
    { lat: 49.4441549, lng: 19.3931207 },
    { lat: 49.4441959, lng: 19.3933006 },
    { lat: 49.444203, lng: 19.3933824 },
    { lat: 49.4442091, lng: 19.3934646 },
    { lat: 49.4442872, lng: 19.3934221 },
    { lat: 49.4443519, lng: 19.3936319 },
    { lat: 49.4444056, lng: 19.3936941 },
    { lat: 49.444464, lng: 19.393762 },
    { lat: 49.4445581, lng: 19.3938716 },
    { lat: 49.4448023, lng: 19.3938627 },
    { lat: 49.4451562, lng: 19.3938411 },
    { lat: 49.4454676, lng: 19.3939476 },
    { lat: 49.4457557, lng: 19.3940857 },
    { lat: 49.4458004, lng: 19.3941029 },
    { lat: 49.4462451, lng: 19.3942887 },
    { lat: 49.4465863, lng: 19.3948696 },
    { lat: 49.4471246, lng: 19.3959527 },
    { lat: 49.4472867, lng: 19.3969736 },
    { lat: 49.4473206, lng: 19.3977058 },
    { lat: 49.4472942, lng: 19.398471 },
    { lat: 49.4472254, lng: 19.3992752 },
    { lat: 49.4471458, lng: 19.3997173 },
    { lat: 49.4471188, lng: 19.4003779 },
    { lat: 49.4471736, lng: 19.4009028 },
    { lat: 49.4474129, lng: 19.401814 },
    { lat: 49.4475631, lng: 19.4023285 },
    { lat: 49.4477565, lng: 19.4030994 },
    { lat: 49.448, lng: 19.4044929 },
    { lat: 49.4481756, lng: 19.4048993 },
    { lat: 49.4484619, lng: 19.4055605 },
    { lat: 49.4489402, lng: 19.4065449 },
    { lat: 49.4494063, lng: 19.4076121 },
    { lat: 49.4496383, lng: 19.4082174 },
    { lat: 49.4497429, lng: 19.4087742 },
    { lat: 49.4498835, lng: 19.4091088 },
    { lat: 49.4500858, lng: 19.4096068 },
    { lat: 49.4501475, lng: 19.4098699 },
    { lat: 49.4503958, lng: 19.4107367 },
    { lat: 49.4504689, lng: 19.4109996 },
    { lat: 49.451691, lng: 19.4124034 },
    { lat: 49.4519925, lng: 19.412711 },
    { lat: 49.4525204, lng: 19.413261 },
    { lat: 49.4532983, lng: 19.4140525 },
    { lat: 49.4535009, lng: 19.4144818 },
    { lat: 49.4535898, lng: 19.4148013 },
    { lat: 49.4538519, lng: 19.4157677 },
    { lat: 49.4540607, lng: 19.4164803 },
    { lat: 49.4542127, lng: 19.4169911 },
    { lat: 49.4543764, lng: 19.417656 },
    { lat: 49.4545646, lng: 19.4181426 },
    { lat: 49.4547957, lng: 19.4191748 },
    { lat: 49.4548177, lng: 19.4194523 },
    { lat: 49.4549491, lng: 19.4204232 },
    { lat: 49.4551472, lng: 19.420904 },
    { lat: 49.4561084, lng: 19.4208377 },
    { lat: 49.4567565, lng: 19.4207659 },
    { lat: 49.457111, lng: 19.4208089 },
    { lat: 49.4575196, lng: 19.4211133 },
    { lat: 49.4576544, lng: 19.421178 },
    { lat: 49.458254, lng: 19.4216229 },
    { lat: 49.4582726, lng: 19.4216361 },
    { lat: 49.4586515, lng: 19.4218344 },
    { lat: 49.4587338, lng: 19.4218878 },
    { lat: 49.4587839, lng: 19.4219204 },
    { lat: 49.4602359, lng: 19.4228653 },
    { lat: 49.4605045, lng: 19.4230231 },
    { lat: 49.4613978, lng: 19.4239005 },
    { lat: 49.4621614, lng: 19.4245519 },
    { lat: 49.462448, lng: 19.424561 },
    { lat: 49.4624841, lng: 19.424514 },
    { lat: 49.462533, lng: 19.4244489 },
    { lat: 49.4625771, lng: 19.42433 },
    { lat: 49.4626438, lng: 19.4244347 },
    { lat: 49.4627213, lng: 19.4246726 },
    { lat: 49.4626759, lng: 19.4248866 },
    { lat: 49.4627228, lng: 19.42495 },
    { lat: 49.462647, lng: 19.4250426 },
    { lat: 49.4626684, lng: 19.4251884 },
    { lat: 49.4626282, lng: 19.4254905 },
    { lat: 49.4627379, lng: 19.4255178 },
    { lat: 49.4627687, lng: 19.4255782 },
    { lat: 49.4627624, lng: 19.4257111 },
    { lat: 49.4626682, lng: 19.4258187 },
    { lat: 49.4626033, lng: 19.4261235 },
    { lat: 49.462676, lng: 19.4263058 },
    { lat: 49.4627573, lng: 19.4262369 },
    { lat: 49.4628034, lng: 19.426258 },
    { lat: 49.4628701, lng: 19.4265586 },
    { lat: 49.4629471, lng: 19.4265133 },
    { lat: 49.4629665, lng: 19.4267191 },
    { lat: 49.4631064, lng: 19.4267674 },
    { lat: 49.4631902, lng: 19.4267057 },
    { lat: 49.4632403, lng: 19.4268148 },
    { lat: 49.4632857, lng: 19.4268464 },
    { lat: 49.4633858, lng: 19.426841 },
    { lat: 49.4632981, lng: 19.4269668 },
    { lat: 49.4634345, lng: 19.4271143 },
    { lat: 49.4634455, lng: 19.4270341 },
    { lat: 49.463506, lng: 19.4270316 },
    { lat: 49.4635591, lng: 19.4271544 },
    { lat: 49.4635352, lng: 19.4272247 },
    { lat: 49.4635984, lng: 19.4273958 },
    { lat: 49.4635975, lng: 19.4275266 },
    { lat: 49.4636226, lng: 19.4276757 },
    { lat: 49.4635113, lng: 19.4277298 },
    { lat: 49.4636041, lng: 19.4279602 },
    { lat: 49.4636352, lng: 19.4279029 },
    { lat: 49.4636892, lng: 19.4279481 },
    { lat: 49.4636709, lng: 19.4280892 },
    { lat: 49.4636226, lng: 19.4280712 },
    { lat: 49.4636115, lng: 19.428317 },
    { lat: 49.4636867, lng: 19.4283338 },
    { lat: 49.4637448, lng: 19.4284467 },
    { lat: 49.4638126, lng: 19.4284484 },
    { lat: 49.4638683, lng: 19.4286336 },
    { lat: 49.4640021, lng: 19.4285793 },
    { lat: 49.4640419, lng: 19.4284652 },
    { lat: 49.464035, lng: 19.4283862 },
    { lat: 49.4640997, lng: 19.4283966 },
    { lat: 49.4641656, lng: 19.428434 },
    { lat: 49.4641637, lng: 19.4283418 },
    { lat: 49.4642766, lng: 19.4283271 },
    { lat: 49.464316, lng: 19.4282482 },
    { lat: 49.4643857, lng: 19.4281729 },
    { lat: 49.4643982, lng: 19.4281012 },
    { lat: 49.4644585, lng: 19.4281132 },
    { lat: 49.4645355, lng: 19.4280733 },
    { lat: 49.4645276, lng: 19.4281734 },
    { lat: 49.4645226, lng: 19.4282348 },
    { lat: 49.4646426, lng: 19.4281747 },
    { lat: 49.464681, lng: 19.4281249 },
    { lat: 49.4648077, lng: 19.4280869 },
    { lat: 49.4648431, lng: 19.4279751 },
    { lat: 49.4648912, lng: 19.4279427 },
    { lat: 49.4651017, lng: 19.4278438 },
    { lat: 49.4651708, lng: 19.4279494 },
    { lat: 49.4652403, lng: 19.4280125 },
    { lat: 49.465289, lng: 19.4279923 },
    { lat: 49.4653143, lng: 19.4280044 },
    { lat: 49.4653814, lng: 19.4279236 },
    { lat: 49.4654128, lng: 19.427847 },
    { lat: 49.4654482, lng: 19.4278309 },
    { lat: 49.4655835, lng: 19.4278564 },
    { lat: 49.4657022, lng: 19.4279888 },
    { lat: 49.4657191, lng: 19.4281083 },
    { lat: 49.4658315, lng: 19.4280795 },
    { lat: 49.4658652, lng: 19.4281483 },
    { lat: 49.4659623, lng: 19.4280831 },
    { lat: 49.4659985, lng: 19.428132 },
    { lat: 49.466074, lng: 19.4281347 },
    { lat: 49.4660933, lng: 19.4280616 },
    { lat: 49.4661624, lng: 19.4280715 },
    { lat: 49.4662394, lng: 19.4279599 },
    { lat: 49.4662367, lng: 19.4279004 },
    { lat: 49.466425, lng: 19.4279492 },
    { lat: 49.4664999, lng: 19.4280381 },
    { lat: 49.4665627, lng: 19.4280056 },
    { lat: 49.4666764, lng: 19.4281487 },
    { lat: 49.4667425, lng: 19.4280905 },
    { lat: 49.4669143, lng: 19.4281776 },
    { lat: 49.4669814, lng: 19.4281928 },
    { lat: 49.4670058, lng: 19.4282811 },
    { lat: 49.4671015, lng: 19.4283761 },
    { lat: 49.4671077, lng: 19.4282432 },
    { lat: 49.4671401, lng: 19.4282346 },
    { lat: 49.4673624, lng: 19.4283395 },
    { lat: 49.4673537, lng: 19.4284944 },
    { lat: 49.4674449, lng: 19.4284957 },
    { lat: 49.4675147, lng: 19.4286171 },
    { lat: 49.467519, lng: 19.4286859 },
    { lat: 49.4675824, lng: 19.4287638 },
    { lat: 49.4676501, lng: 19.4288155 },
    { lat: 49.4676677, lng: 19.4286585 },
    { lat: 49.4677401, lng: 19.4286666 },
    { lat: 49.4678279, lng: 19.4287636 },
    { lat: 49.4679412, lng: 19.4288694 },
    { lat: 49.4680376, lng: 19.4288151 },
    { lat: 49.4682414, lng: 19.428735 },
    { lat: 49.4682333, lng: 19.4289015 },
    { lat: 49.4682634, lng: 19.4289964 },
    { lat: 49.4683388, lng: 19.428994 },
    { lat: 49.4684217, lng: 19.4290296 },
    { lat: 49.4684777, lng: 19.4288525 },
    { lat: 49.4686362, lng: 19.4289908 },
    { lat: 49.4687901, lng: 19.4289092 },
    { lat: 49.4688189, lng: 19.4289452 },
    { lat: 49.4688716, lng: 19.4289649 },
    { lat: 49.4689074, lng: 19.4288624 },
    { lat: 49.4691077, lng: 19.4289038 },
    { lat: 49.469156, lng: 19.4288518 },
    { lat: 49.4691814, lng: 19.4287929 },
    { lat: 49.4693421, lng: 19.4286874 },
    { lat: 49.469435, lng: 19.4285991 },
    { lat: 49.4695885, lng: 19.4283567 },
    { lat: 49.4697217, lng: 19.4281945 },
    { lat: 49.4698014, lng: 19.4281873 },
    { lat: 49.4698106, lng: 19.4280957 },
    { lat: 49.4699342, lng: 19.4279385 },
    { lat: 49.4700297, lng: 19.4279564 },
    { lat: 49.4700601, lng: 19.4278582 },
    { lat: 49.4701056, lng: 19.4277956 },
    { lat: 49.470184, lng: 19.4278567 },
    { lat: 49.4703276, lng: 19.4276991 },
    { lat: 49.4704466, lng: 19.427713 },
    { lat: 49.4705071, lng: 19.4276325 },
    { lat: 49.4706912, lng: 19.427397 },
    { lat: 49.4707345, lng: 19.4274079 },
    { lat: 49.4707832, lng: 19.4272928 },
    { lat: 49.4709168, lng: 19.4271573 },
    { lat: 49.4709866, lng: 19.4271552 },
    { lat: 49.471058, lng: 19.4270463 },
    { lat: 49.4711343, lng: 19.4270352 },
    { lat: 49.4712601, lng: 19.4269078 },
    { lat: 49.4713515, lng: 19.4269587 },
    { lat: 49.4714817, lng: 19.4268732 },
    { lat: 49.4716907, lng: 19.4268842 },
    { lat: 49.4717808, lng: 19.4269857 },
    { lat: 49.4719314, lng: 19.4269483 },
    { lat: 49.4721327, lng: 19.4269421 },
    { lat: 49.4722006, lng: 19.4268978 },
    { lat: 49.4725726, lng: 19.4266599 },
    { lat: 49.4727116, lng: 19.4263978 },
    { lat: 49.4728538, lng: 19.426236 },
    { lat: 49.4733352, lng: 19.4259689 },
    { lat: 49.4736823, lng: 19.4259479 },
    { lat: 49.473903, lng: 19.4260395 },
    { lat: 49.4742487, lng: 19.4248886 },
    { lat: 49.4743576, lng: 19.4243398 },
    { lat: 49.4743443, lng: 19.4237093 },
    { lat: 49.4743067, lng: 19.4229696 },
    { lat: 49.4743009, lng: 19.4224042 },
    { lat: 49.4743257, lng: 19.4218153 },
    { lat: 49.4745002, lng: 19.4212268 },
    { lat: 49.4746581, lng: 19.4208408 },
    { lat: 49.4748865, lng: 19.4206381 },
    { lat: 49.4750054, lng: 19.4195008 },
    { lat: 49.4749978, lng: 19.4192893 },
    { lat: 49.4750201, lng: 19.4190876 },
    { lat: 49.4751026, lng: 19.4187772 },
    { lat: 49.4753106, lng: 19.4181564 },
    { lat: 49.4753317, lng: 19.4180776 },
    { lat: 49.4753772, lng: 19.4178926 },
    { lat: 49.4763613, lng: 19.4158243 },
    { lat: 49.477959, lng: 19.4141867 },
    { lat: 49.4780937, lng: 19.4140633 },
    { lat: 49.4785482, lng: 19.4136468 },
    { lat: 49.4786123, lng: 19.4136098 },
    { lat: 49.4789337, lng: 19.4134241 },
    { lat: 49.4797586, lng: 19.4129476 },
    { lat: 49.4799802, lng: 19.4128196 },
    { lat: 49.4800563, lng: 19.4128123 },
    { lat: 49.4805389, lng: 19.4127657 },
    { lat: 49.4807175, lng: 19.412889 },
    { lat: 49.481179, lng: 19.4132075 },
    { lat: 49.4815118, lng: 19.4134372 },
    { lat: 49.4819852, lng: 19.4138118 },
    { lat: 49.4821386, lng: 19.4139332 },
    { lat: 49.4822341, lng: 19.4139529 },
    { lat: 49.4824709, lng: 19.4140018 },
    { lat: 49.4825304, lng: 19.4139855 },
    { lat: 49.4828635, lng: 19.4138939 },
    { lat: 49.4831164, lng: 19.4137398 },
    { lat: 49.4834631, lng: 19.4135286 },
    { lat: 49.4835764, lng: 19.4135156 },
    { lat: 49.4843944, lng: 19.4134215 },
    { lat: 49.4844039, lng: 19.413336 },
    { lat: 49.4843979, lng: 19.4133041 },
    { lat: 49.4847099, lng: 19.4132031 },
    { lat: 49.4848039, lng: 19.4131646 },
    { lat: 49.4867933, lng: 19.4124476 },
    { lat: 49.4883974, lng: 19.4117563 },
    { lat: 49.4891103, lng: 19.4115033 },
    { lat: 49.489425, lng: 19.4112918 },
    { lat: 49.4896206, lng: 19.4111364 },
    { lat: 49.4897603, lng: 19.4109918 },
    { lat: 49.4910685, lng: 19.4098193 },
    { lat: 49.4917819, lng: 19.4084314 },
    { lat: 49.4923345, lng: 19.4066865 },
    { lat: 49.4943773, lng: 19.4031317 },
    { lat: 49.495269, lng: 19.4016048 },
    { lat: 49.4955782, lng: 19.4010299 },
    { lat: 49.4957424, lng: 19.4006865 },
    { lat: 49.4958591, lng: 19.4001151 },
    { lat: 49.4958898, lng: 19.3999023 },
    { lat: 49.4960485, lng: 19.3991411 },
    { lat: 49.4965357, lng: 19.3980063 },
    { lat: 49.4967877, lng: 19.3974876 },
    { lat: 49.4968884, lng: 19.3973022 },
    { lat: 49.4971883, lng: 19.3968693 },
    { lat: 49.4975195, lng: 19.3961843 },
    { lat: 49.497863, lng: 19.3955715 },
    { lat: 49.498728, lng: 19.394449 },
    { lat: 49.4994958, lng: 19.3934831 },
    { lat: 49.4996564, lng: 19.3930817 },
    { lat: 49.5006543, lng: 19.3909128 },
    { lat: 49.5009552, lng: 19.3902578 },
    { lat: 49.5014384, lng: 19.3894766 },
    { lat: 49.5022642, lng: 19.3884496 },
    { lat: 49.5042362, lng: 19.3872192 },
    { lat: 49.5058631, lng: 19.3862436 },
    { lat: 49.5062407, lng: 19.3860261 },
    { lat: 49.5065969, lng: 19.3844756 },
    { lat: 49.5070162, lng: 19.3826538 },
    { lat: 49.5076314, lng: 19.3812481 },
    { lat: 49.5080886, lng: 19.3803692 },
    { lat: 49.5083877, lng: 19.3795554 },
    { lat: 49.5082906, lng: 19.3786277 },
    { lat: 49.5083786, lng: 19.3783624 },
    { lat: 49.5086146, lng: 19.3779631 },
    { lat: 49.5089742, lng: 19.3768758 },
    { lat: 49.5092242, lng: 19.3764576 },
    { lat: 49.5094495, lng: 19.3760471 },
    { lat: 49.5094752, lng: 19.3754826 },
    { lat: 49.5096169, lng: 19.3750156 },
    { lat: 49.5095014, lng: 19.3742777 },
    { lat: 49.509598, lng: 19.3740303 },
    { lat: 49.5095352, lng: 19.3735731 },
    { lat: 49.5090648, lng: 19.3728596 },
    { lat: 49.5087471, lng: 19.3720604 },
    { lat: 49.5077787, lng: 19.3710586 },
    { lat: 49.5070805, lng: 19.3703364 },
    { lat: 49.5062566, lng: 19.3696795 },
    { lat: 49.507643, lng: 19.3627912 },
    { lat: 49.5077738, lng: 19.3623345 },
    { lat: 49.5077924, lng: 19.3622722 },
    { lat: 49.5078369, lng: 19.3621169 },
    { lat: 49.5081869, lng: 19.3608978 },
    { lat: 49.5083443, lng: 19.3606227 },
    { lat: 49.5084527, lng: 19.3604346 },
    { lat: 49.5085538, lng: 19.3602585 },
    { lat: 49.5086883, lng: 19.3600232 },
    { lat: 49.508733, lng: 19.359944 },
    { lat: 49.508835, lng: 19.3597667 },
    { lat: 49.5090998, lng: 19.3593046 },
    { lat: 49.5091172, lng: 19.3590025 },
    { lat: 49.5096559, lng: 19.3586148 },
    { lat: 49.5098855, lng: 19.3579586 },
    { lat: 49.5103559, lng: 19.3575355 },
    { lat: 49.5103579, lng: 19.3575324 },
    { lat: 49.5111269, lng: 19.3561141 },
    { lat: 49.5111641, lng: 19.3560455 },
    { lat: 49.5112624, lng: 19.3554193 },
    { lat: 49.5112798, lng: 19.3553119 },
    { lat: 49.5112886, lng: 19.3552841 },
    { lat: 49.5115537, lng: 19.354517 },
    { lat: 49.5115607, lng: 19.3544959 },
    { lat: 49.511721, lng: 19.3533661 },
    { lat: 49.5118827, lng: 19.3527525 },
    { lat: 49.5127809, lng: 19.3493544 },
    { lat: 49.5128192, lng: 19.3492541 },
    { lat: 49.5129389, lng: 19.3489446 },
    { lat: 49.5130773, lng: 19.3485827 },
    { lat: 49.5130657, lng: 19.3483349 },
    { lat: 49.5130114, lng: 19.347146 },
    { lat: 49.5132587, lng: 19.3458078 },
    { lat: 49.5132686, lng: 19.3457534 },
    { lat: 49.5131441, lng: 19.3444181 },
    { lat: 49.5174352, lng: 19.3395509 },
    { lat: 49.5182439, lng: 19.3386322 },
    { lat: 49.5195439, lng: 19.3371573 },
    { lat: 49.5195472, lng: 19.3371529 },
    { lat: 49.5217578, lng: 19.3346445 },
    { lat: 49.5235447, lng: 19.3263687 },
    { lat: 49.5310826, lng: 19.3187944 },
    { lat: 49.5310326, lng: 19.3187327 },
    { lat: 49.5299839, lng: 19.3168439 },
    { lat: 49.5280439, lng: 19.3133522 },
    { lat: 49.5232276, lng: 19.3121692 },
    { lat: 49.5222859, lng: 19.3119381 },
    { lat: 49.5192444, lng: 19.311191 },
    { lat: 49.5177052, lng: 19.3108126 },
    { lat: 49.5176711, lng: 19.3108048 },
    { lat: 49.5162325, lng: 19.3104512 },
    { lat: 49.5158448, lng: 19.310356 },
    { lat: 49.5153058, lng: 19.3108238 },
    { lat: 49.5126445, lng: 19.3131317 },
    { lat: 49.5072829, lng: 19.3177811 },
    { lat: 49.5066823, lng: 19.3187182 },
    { lat: 49.5064972, lng: 19.3187837 },
    { lat: 49.5063232, lng: 19.3188457 },
    { lat: 49.506072, lng: 19.3191825 },
    { lat: 49.5058207, lng: 19.3194674 },
    { lat: 49.5055954, lng: 19.3197835 },
    { lat: 49.5053341, lng: 19.319952 },
    { lat: 49.5050451, lng: 19.3202799 },
    { lat: 49.5047215, lng: 19.3205645 },
    { lat: 49.5042974, lng: 19.320916 },
    { lat: 49.5038666, lng: 19.3213854 },
    { lat: 49.5035615, lng: 19.3216316 },
    { lat: 49.5033341, lng: 19.3218268 },
    { lat: 49.5031017, lng: 19.3219936 },
    { lat: 49.5026434, lng: 19.3223554 },
    { lat: 49.5022148, lng: 19.3226606 },
    { lat: 49.5018098, lng: 19.3229621 },
    { lat: 49.5015457, lng: 19.3230947 },
    { lat: 49.5011858, lng: 19.3231356 },
    { lat: 49.5009065, lng: 19.3232118 },
    { lat: 49.5007866, lng: 19.323559 },
    { lat: 49.5005224, lng: 19.3239112 },
    { lat: 49.5001908, lng: 19.3241923 },
    { lat: 49.4998459, lng: 19.324597 },
    { lat: 49.499612, lng: 19.3248763 },
    { lat: 49.4995244, lng: 19.3249751 },
    { lat: 49.4994946, lng: 19.3250096 },
    { lat: 49.4994216, lng: 19.3250905 },
    { lat: 49.4994163, lng: 19.3250969 },
    { lat: 49.4993714, lng: 19.3251479 },
    { lat: 49.4993521, lng: 19.3251697 },
    { lat: 49.4993082, lng: 19.3252195 },
    { lat: 49.4991203, lng: 19.3253222 },
    { lat: 49.4991049, lng: 19.3253308 },
    { lat: 49.4989412, lng: 19.3254203 },
    { lat: 49.4988984, lng: 19.3254435 },
    { lat: 49.4988797, lng: 19.3254528 },
    { lat: 49.4985447, lng: 19.3256153 },
    { lat: 49.4980409, lng: 19.3259687 },
    { lat: 49.497569, lng: 19.3263598 },
    { lat: 49.4971133, lng: 19.3268337 },
    { lat: 49.4968865, lng: 19.3270657 },
    { lat: 49.4968328, lng: 19.3271202 },
    { lat: 49.4967596, lng: 19.3271961 },
    { lat: 49.4967587, lng: 19.3271966 },
    { lat: 49.496748, lng: 19.3272097 },
    { lat: 49.4963707, lng: 19.3276477 },
    { lat: 49.4962981, lng: 19.3278515 },
    { lat: 49.4961189, lng: 19.3284513 },
    { lat: 49.4958902, lng: 19.3287405 },
    { lat: 49.4956192, lng: 19.3289938 },
    { lat: 49.4953475, lng: 19.3292333 },
    { lat: 49.4950169, lng: 19.329847 },
    { lat: 49.4946833, lng: 19.3300136 },
    { lat: 49.4945586, lng: 19.3302851 },
    { lat: 49.4942342, lng: 19.3306998 },
    { lat: 49.4941283, lng: 19.3309134 },
    { lat: 49.4939701, lng: 19.3310244 },
    { lat: 49.4937355, lng: 19.3311174 },
    { lat: 49.4934635, lng: 19.3312997 },
    { lat: 49.4934143, lng: 19.3313327 },
    { lat: 49.4933477, lng: 19.3313774 },
    { lat: 49.4932872, lng: 19.331412 },
    { lat: 49.4932523, lng: 19.3314321 },
    { lat: 49.4931217, lng: 19.3315082 },
    { lat: 49.4931019, lng: 19.3315194 },
    { lat: 49.4930131, lng: 19.3314988 },
    { lat: 49.492652, lng: 19.3314154 },
    { lat: 49.4924824, lng: 19.3315966 },
    { lat: 49.4920221, lng: 19.3313665 },
    { lat: 49.4916213, lng: 19.331542 },
    { lat: 49.4915836, lng: 19.3315581 },
    { lat: 49.4915803, lng: 19.3315595 },
    { lat: 49.4914553, lng: 19.3314668 },
    { lat: 49.4913971, lng: 19.3314232 },
    { lat: 49.4913787, lng: 19.3314191 },
    { lat: 49.4912718, lng: 19.3313943 },
    { lat: 49.4911987, lng: 19.3313773 },
    { lat: 49.4911966, lng: 19.3313765 },
    { lat: 49.4910223, lng: 19.3313359 },
    { lat: 49.4910018, lng: 19.3313313 },
    { lat: 49.4907611, lng: 19.3310519 },
    { lat: 49.4907301, lng: 19.3310159 },
    { lat: 49.4907116, lng: 19.3310291 },
    { lat: 49.4904772, lng: 19.3311971 },
    { lat: 49.4904569, lng: 19.3311979 },
    { lat: 49.4904075, lng: 19.3312008 },
    { lat: 49.4903726, lng: 19.3312025 },
    { lat: 49.4903118, lng: 19.3312056 },
    { lat: 49.4902263, lng: 19.3312103 },
    { lat: 49.4902196, lng: 19.331475 },
    { lat: 49.4902176, lng: 19.3316231 },
    { lat: 49.4901837, lng: 19.3337864 },
    { lat: 49.4906512, lng: 19.3352239 },
    { lat: 49.4907615, lng: 19.3363027 },
    { lat: 49.4907863, lng: 19.3365443 },
    { lat: 49.4908521, lng: 19.3371837 },
    { lat: 49.4906512, lng: 19.338667 },
    { lat: 49.4905039, lng: 19.3397573 },
    { lat: 49.4903685, lng: 19.3397044 },
    { lat: 49.490112, lng: 19.3396171 },
    { lat: 49.4888861, lng: 19.3388222 },
    { lat: 49.4875449, lng: 19.3387197 },
    { lat: 49.4875463, lng: 19.338775 },
    { lat: 49.4868859, lng: 19.3388475 },
    { lat: 49.4862758, lng: 19.3394378 },
    { lat: 49.4856895, lng: 19.339721 },
    { lat: 49.4854759, lng: 19.339804 },
    { lat: 49.4851411, lng: 19.3399438 },
    { lat: 49.4848101, lng: 19.3400919 },
    { lat: 49.4842686, lng: 19.3405605 },
    { lat: 49.4839028, lng: 19.3407414 },
    { lat: 49.4838348, lng: 19.3407754 },
    { lat: 49.482921, lng: 19.3411885 },
    { lat: 49.4811727, lng: 19.3408598 },
    { lat: 49.4810822, lng: 19.341359 },
    { lat: 49.4798404, lng: 19.3414092 },
    { lat: 49.4790369, lng: 19.3409502 },
    { lat: 49.4785206, lng: 19.3411336 },
    { lat: 49.4777712, lng: 19.3415953 },
    { lat: 49.4773118, lng: 19.3414292 },
    { lat: 49.4768142, lng: 19.3414884 },
    { lat: 49.4764281, lng: 19.3415687 },
    { lat: 49.476075, lng: 19.3419261 },
    { lat: 49.4756221, lng: 19.342429 },
    { lat: 49.4751887, lng: 19.3426079 },
    { lat: 49.4748235, lng: 19.3424387 },
    { lat: 49.4746319, lng: 19.3423417 },
    { lat: 49.4744907, lng: 19.342841 },
    { lat: 49.4740991, lng: 19.3428483 },
    { lat: 49.4736407, lng: 19.3428767 },
    { lat: 49.4731472, lng: 19.3427618 },
    { lat: 49.4724507, lng: 19.3430458 },
    { lat: 49.4720437, lng: 19.3428693 },
    { lat: 49.4715727, lng: 19.3426552 },
    { lat: 49.470879, lng: 19.3421349 },
    { lat: 49.4703764, lng: 19.3412196 },
    { lat: 49.4700683, lng: 19.3413318 },
    { lat: 49.4696245, lng: 19.3416731 },
    { lat: 49.468841, lng: 19.3417827 },
    { lat: 49.4686362, lng: 19.3417918 },
    { lat: 49.4685225, lng: 19.3414797 },
    { lat: 49.4684506, lng: 19.3412666 },
    { lat: 49.4683329, lng: 19.3411832 },
    { lat: 49.4682801, lng: 19.3412604 },
    { lat: 49.4678827, lng: 19.3411948 },
    { lat: 49.4677712, lng: 19.3413154 },
    { lat: 49.4677552, lng: 19.3418668 },
    { lat: 49.4679131, lng: 19.3423487 },
    { lat: 49.4678134, lng: 19.3426064 },
    { lat: 49.4676949, lng: 19.342839 },
    { lat: 49.4674584, lng: 19.3435665 },
    { lat: 49.4674547, lng: 19.343922 },
    { lat: 49.4675655, lng: 19.3442873 },
    { lat: 49.4675138, lng: 19.3445535 },
    { lat: 49.4670387, lng: 19.3449025 },
    { lat: 49.4665643, lng: 19.3454055 },
    { lat: 49.4662552, lng: 19.3466539 },
    { lat: 49.4662159, lng: 19.3470655 },
    { lat: 49.4660225, lng: 19.3472922 },
    { lat: 49.4656656, lng: 19.3474412 },
    { lat: 49.4653946, lng: 19.348083 },
    { lat: 49.4652643, lng: 19.3480187 },
    { lat: 49.4648009, lng: 19.3475766 },
    { lat: 49.4647573, lng: 19.3477039 },
    { lat: 49.4649229, lng: 19.3481617 },
    { lat: 49.4648656, lng: 19.3483843 },
    { lat: 49.4648375, lng: 19.3485518 },
    { lat: 49.464871, lng: 19.3491274 },
    { lat: 49.464786, lng: 19.3495705 },
    { lat: 49.4644363, lng: 19.3499502 },
    { lat: 49.4641178, lng: 19.3501283 },
    { lat: 49.4639738, lng: 19.3503333 },
    { lat: 49.4638108, lng: 19.3505766 },
    { lat: 49.4637675, lng: 19.350661 },
    { lat: 49.4634837, lng: 19.3510495 },
    { lat: 49.4631335, lng: 19.351802 },
    { lat: 49.4626736, lng: 19.3519707 },
    { lat: 49.4624802, lng: 19.3522691 },
    { lat: 49.4625817, lng: 19.352676 },
    { lat: 49.4627622, lng: 19.3530441 },
    { lat: 49.4626816, lng: 19.3532236 },
    { lat: 49.4625142, lng: 19.3533797 },
    { lat: 49.4624135, lng: 19.3539994 },
    { lat: 49.4625625, lng: 19.3541503 },
    { lat: 49.4627008, lng: 19.3548941 },
    { lat: 49.4626222, lng: 19.3548991 },
    { lat: 49.4619363, lng: 19.3550252 },
    { lat: 49.4617868, lng: 19.3548134 },
    { lat: 49.4615024, lng: 19.3547556 },
    { lat: 49.4614494, lng: 19.3547581 },
    { lat: 49.4611249, lng: 19.3545138 },
    { lat: 49.4589051, lng: 19.3543977 },
    { lat: 49.457604, lng: 19.354452 },
    { lat: 49.4566541, lng: 19.3543814 },
    { lat: 49.4557675, lng: 19.35458 },
    { lat: 49.4547201, lng: 19.3547827 },
    { lat: 49.4539847, lng: 19.3548422 },
    { lat: 49.4530513, lng: 19.3546608 },
    { lat: 49.4518255, lng: 19.3544007 },
    { lat: 49.4504642, lng: 19.3542081 },
    { lat: 49.4495801, lng: 19.3538779 },
    { lat: 49.4487507, lng: 19.3537351 },
    { lat: 49.4484531, lng: 19.3536103 },
    { lat: 49.4462384, lng: 19.3526858 },
    { lat: 49.4461652, lng: 19.3523222 },
    { lat: 49.445798, lng: 19.3517444 },
    { lat: 49.4455908, lng: 19.3512935 },
    { lat: 49.4451526, lng: 19.3505001 },
    { lat: 49.4450061, lng: 19.3502355 },
  ],
  Rabča: [
    { lat: 49.4843944, lng: 19.4134215 },
    { lat: 49.4835764, lng: 19.4135156 },
    { lat: 49.4834631, lng: 19.4135286 },
    { lat: 49.4831164, lng: 19.4137398 },
    { lat: 49.4828635, lng: 19.4138939 },
    { lat: 49.4825304, lng: 19.4139855 },
    { lat: 49.4824709, lng: 19.4140018 },
    { lat: 49.4822341, lng: 19.4139529 },
    { lat: 49.4821386, lng: 19.4139332 },
    { lat: 49.4819852, lng: 19.4138118 },
    { lat: 49.4815118, lng: 19.4134372 },
    { lat: 49.481179, lng: 19.4132075 },
    { lat: 49.4807175, lng: 19.412889 },
    { lat: 49.4805389, lng: 19.4127657 },
    { lat: 49.4800563, lng: 19.4128123 },
    { lat: 49.4799802, lng: 19.4128196 },
    { lat: 49.4797586, lng: 19.4129476 },
    { lat: 49.4789337, lng: 19.4134241 },
    { lat: 49.4786123, lng: 19.4136098 },
    { lat: 49.4785482, lng: 19.4136468 },
    { lat: 49.4780937, lng: 19.4140633 },
    { lat: 49.477959, lng: 19.4141867 },
    { lat: 49.4763613, lng: 19.4158243 },
    { lat: 49.4753772, lng: 19.4178926 },
    { lat: 49.4753317, lng: 19.4180776 },
    { lat: 49.4753106, lng: 19.4181564 },
    { lat: 49.4751026, lng: 19.4187772 },
    { lat: 49.4750201, lng: 19.4190876 },
    { lat: 49.4749978, lng: 19.4192893 },
    { lat: 49.4750054, lng: 19.4195008 },
    { lat: 49.4748865, lng: 19.4206381 },
    { lat: 49.4746581, lng: 19.4208408 },
    { lat: 49.4745002, lng: 19.4212268 },
    { lat: 49.4743257, lng: 19.4218153 },
    { lat: 49.4743009, lng: 19.4224042 },
    { lat: 49.4743067, lng: 19.4229696 },
    { lat: 49.4743443, lng: 19.4237093 },
    { lat: 49.4743576, lng: 19.4243398 },
    { lat: 49.4742487, lng: 19.4248886 },
    { lat: 49.473903, lng: 19.4260395 },
    { lat: 49.473898, lng: 19.426106 },
    { lat: 49.4738516, lng: 19.426661 },
    { lat: 49.4738459, lng: 19.4271722 },
    { lat: 49.4736382, lng: 19.4280204 },
    { lat: 49.4735137, lng: 19.4287209 },
    { lat: 49.4735169, lng: 19.4288358 },
    { lat: 49.4734422, lng: 19.4292703 },
    { lat: 49.4734073, lng: 19.4294665 },
    { lat: 49.4732316, lng: 19.4301783 },
    { lat: 49.4730722, lng: 19.4305829 },
    { lat: 49.4730059, lng: 19.4307317 },
    { lat: 49.4726306, lng: 19.4312224 },
    { lat: 49.4725711, lng: 19.4312738 },
    { lat: 49.4720273, lng: 19.4318207 },
    { lat: 49.4714596, lng: 19.4324141 },
    { lat: 49.4712939, lng: 19.4326093 },
    { lat: 49.4711431, lng: 19.4328564 },
    { lat: 49.4711052, lng: 19.4329369 },
    { lat: 49.4710195, lng: 19.4331121 },
    { lat: 49.470887, lng: 19.4334395 },
    { lat: 49.4707183, lng: 19.4338364 },
    { lat: 49.4706232, lng: 19.4341706 },
    { lat: 49.4705488, lng: 19.4345598 },
    { lat: 49.4704981, lng: 19.4349011 },
    { lat: 49.4704688, lng: 19.4350021 },
    { lat: 49.4703609, lng: 19.4353971 },
    { lat: 49.4703053, lng: 19.4356578 },
    { lat: 49.4702535, lng: 19.4360254 },
    { lat: 49.4702942, lng: 19.4363496 },
    { lat: 49.4704357, lng: 19.4369262 },
    { lat: 49.470441, lng: 19.4372379 },
    { lat: 49.4704091, lng: 19.4374255 },
    { lat: 49.4702808, lng: 19.4378715 },
    { lat: 49.4702387, lng: 19.4381308 },
    { lat: 49.4701872, lng: 19.4385776 },
    { lat: 49.470142, lng: 19.4388216 },
    { lat: 49.4700561, lng: 19.4391095 },
    { lat: 49.4699849, lng: 19.4398878 },
    { lat: 49.4699146, lng: 19.4411783 },
    { lat: 49.4698619, lng: 19.4414036 },
    { lat: 49.4695449, lng: 19.4420128 },
    { lat: 49.4688944, lng: 19.4457284 },
    { lat: 49.4685991, lng: 19.4459969 },
    { lat: 49.4686031, lng: 19.4460823 },
    { lat: 49.4683902, lng: 19.4462796 },
    { lat: 49.4681177, lng: 19.44648 },
    { lat: 49.4680621, lng: 19.4466215 },
    { lat: 49.4680008, lng: 19.4466582 },
    { lat: 49.4679128, lng: 19.4467072 },
    { lat: 49.4677412, lng: 19.4466972 },
    { lat: 49.467404, lng: 19.4468158 },
    { lat: 49.4671472, lng: 19.4468432 },
    { lat: 49.4669921, lng: 19.4468987 },
    { lat: 49.4668724, lng: 19.4469189 },
    { lat: 49.4665783, lng: 19.4470398 },
    { lat: 49.466561, lng: 19.4469301 },
    { lat: 49.4663378, lng: 19.4470785 },
    { lat: 49.46603, lng: 19.44749 },
    { lat: 49.4659531, lng: 19.4474359 },
    { lat: 49.4657773, lng: 19.447581 },
    { lat: 49.4656254, lng: 19.4478068 },
    { lat: 49.4655783, lng: 19.4477369 },
    { lat: 49.4654532, lng: 19.4477838 },
    { lat: 49.4651687, lng: 19.4480916 },
    { lat: 49.4650976, lng: 19.4481891 },
    { lat: 49.4646266, lng: 19.4489504 },
    { lat: 49.4643998, lng: 19.4494056 },
    { lat: 49.464319, lng: 19.4496617 },
    { lat: 49.4642661, lng: 19.4495888 },
    { lat: 49.4641647, lng: 19.4496842 },
    { lat: 49.4640827, lng: 19.4499158 },
    { lat: 49.4640224, lng: 19.4501955 },
    { lat: 49.4639244, lng: 19.4501438 },
    { lat: 49.4637765, lng: 19.450924 },
    { lat: 49.4637596, lng: 19.4510972 },
    { lat: 49.4637218, lng: 19.4514516 },
    { lat: 49.4637053, lng: 19.4516823 },
    { lat: 49.4635932, lng: 19.4523584 },
    { lat: 49.4635953, lng: 19.4525541 },
    { lat: 49.4635757, lng: 19.4528359 },
    { lat: 49.4633181, lng: 19.4534585 },
    { lat: 49.463135, lng: 19.4544548 },
    { lat: 49.4629733, lng: 19.454812 },
    { lat: 49.4629307, lng: 19.4549894 },
    { lat: 49.4627998, lng: 19.4552767 },
    { lat: 49.4625391, lng: 19.4555884 },
    { lat: 49.4624038, lng: 19.4558048 },
    { lat: 49.4620777, lng: 19.4565093 },
    { lat: 49.4618596, lng: 19.4571852 },
    { lat: 49.4618003, lng: 19.4575861 },
    { lat: 49.4617908, lng: 19.4576476 },
    { lat: 49.4617434, lng: 19.4581135 },
    { lat: 49.4618204, lng: 19.458788 },
    { lat: 49.4618556, lng: 19.4593605 },
    { lat: 49.4620953, lng: 19.4611001 },
    { lat: 49.4622492, lng: 19.4617007 },
    { lat: 49.4623742, lng: 19.4622915 },
    { lat: 49.4624153, lng: 19.4625761 },
    { lat: 49.4625075, lng: 19.4640995 },
    { lat: 49.4626443, lng: 19.4646023 },
    { lat: 49.4626576, lng: 19.4648936 },
    { lat: 49.462705, lng: 19.4652387 },
    { lat: 49.4627237, lng: 19.4656211 },
    { lat: 49.4627709, lng: 19.4658887 },
    { lat: 49.4628612, lng: 19.4661391 },
    { lat: 49.4629276, lng: 19.4663098 },
    { lat: 49.4629398, lng: 19.4670217 },
    { lat: 49.4627875, lng: 19.4681972 },
    { lat: 49.4629148, lng: 19.4682708 },
    { lat: 49.4629256, lng: 19.4684098 },
    { lat: 49.4631941, lng: 19.4696038 },
    { lat: 49.4633987, lng: 19.4701842 },
    { lat: 49.4636565, lng: 19.4705705 },
    { lat: 49.4640039, lng: 19.4711721 },
    { lat: 49.4641613, lng: 19.4715341 },
    { lat: 49.4642859, lng: 19.4719456 },
    { lat: 49.464305, lng: 19.4720424 },
    { lat: 49.4643051, lng: 19.4720456 },
    { lat: 49.464315, lng: 19.4720848 },
    { lat: 49.4643192, lng: 19.472105 },
    { lat: 49.4643731, lng: 19.4723705 },
    { lat: 49.4646134, lng: 19.4731177 },
    { lat: 49.4647929, lng: 19.4735965 },
    { lat: 49.4650154, lng: 19.4740996 },
    { lat: 49.4650966, lng: 19.4744256 },
    { lat: 49.4651651, lng: 19.474741 },
    { lat: 49.4652051, lng: 19.4750743 },
    { lat: 49.4653165, lng: 19.475981 },
    { lat: 49.4653077, lng: 19.4764571 },
    { lat: 49.4653571, lng: 19.4771412 },
    { lat: 49.4654015, lng: 19.4775486 },
    { lat: 49.4655329, lng: 19.4794874 },
    { lat: 49.4655178, lng: 19.4803406 },
    { lat: 49.4654977, lng: 19.4807655 },
    { lat: 49.4653023, lng: 19.481745 },
    { lat: 49.4652013, lng: 19.4823937 },
    { lat: 49.4649202, lng: 19.4831455 },
    { lat: 49.4645623, lng: 19.4840927 },
    { lat: 49.4643658, lng: 19.4847753 },
    { lat: 49.4642457, lng: 19.4850348 },
    { lat: 49.4641573, lng: 19.4851736 },
    { lat: 49.4641272, lng: 19.4851298 },
    { lat: 49.4640708, lng: 19.4852749 },
    { lat: 49.4639204, lng: 19.4857554 },
    { lat: 49.4638925, lng: 19.4863822 },
    { lat: 49.4637377, lng: 19.487384 },
    { lat: 49.4637005, lng: 19.4880545 },
    { lat: 49.4635657, lng: 19.4891488 },
    { lat: 49.4634985, lng: 19.4894295 },
    { lat: 49.4632462, lng: 19.4902255 },
    { lat: 49.4634531, lng: 19.4913773 },
    { lat: 49.4634912, lng: 19.4916445 },
    { lat: 49.46353, lng: 19.4925492 },
    { lat: 49.4632406, lng: 19.4930214 },
    { lat: 49.4632417, lng: 19.4933404 },
    { lat: 49.4632172, lng: 19.493498 },
    { lat: 49.4632047, lng: 19.493716 },
    { lat: 49.4632388, lng: 19.4940973 },
    { lat: 49.463236, lng: 19.4941342 },
    { lat: 49.4630306, lng: 19.4962766 },
    { lat: 49.4629554, lng: 19.4964817 },
    { lat: 49.4629053, lng: 19.4965682 },
    { lat: 49.4629485, lng: 19.4969736 },
    { lat: 49.4628943, lng: 19.4974163 },
    { lat: 49.4629223, lng: 19.4975143 },
    { lat: 49.4629163, lng: 19.4976041 },
    { lat: 49.4629402, lng: 19.4980061 },
    { lat: 49.4629837, lng: 19.4982192 },
    { lat: 49.4630738, lng: 19.4984667 },
    { lat: 49.463662, lng: 19.5004532 },
    { lat: 49.4636754, lng: 19.5004471 },
    { lat: 49.4638239, lng: 19.5009397 },
    { lat: 49.4638479, lng: 19.5011201 },
    { lat: 49.4640305, lng: 19.5014968 },
    { lat: 49.4641962, lng: 19.5017917 },
    { lat: 49.4643107, lng: 19.501958 },
    { lat: 49.4646438, lng: 19.5025447 },
    { lat: 49.4648291, lng: 19.5029016 },
    { lat: 49.4651988, lng: 19.5038006 },
    { lat: 49.4654071, lng: 19.5043688 },
    { lat: 49.4657075, lng: 19.5049793 },
    { lat: 49.4658201, lng: 19.5051256 },
    { lat: 49.4659626, lng: 19.5057098 },
    { lat: 49.4660565, lng: 19.5059436 },
    { lat: 49.4661404, lng: 19.5060288 },
    { lat: 49.4662244, lng: 19.5062882 },
    { lat: 49.4662704, lng: 19.506359 },
    { lat: 49.4663641, lng: 19.5066613 },
    { lat: 49.4665298, lng: 19.5069609 },
    { lat: 49.4666957, lng: 19.5072884 },
    { lat: 49.4668548, lng: 19.5076923 },
    { lat: 49.4670249, lng: 19.5079647 },
    { lat: 49.4671673, lng: 19.5083733 },
    { lat: 49.467304, lng: 19.5088502 },
    { lat: 49.4674023, lng: 19.5091817 },
    { lat: 49.4675625, lng: 19.5096319 },
    { lat: 49.4676324, lng: 19.5100319 },
    { lat: 49.4676606, lng: 19.5101325 },
    { lat: 49.4677902, lng: 19.5104038 },
    { lat: 49.4678681, lng: 19.5106324 },
    { lat: 49.4679629, lng: 19.5109852 },
    { lat: 49.4679926, lng: 19.5110668 },
    { lat: 49.4681943, lng: 19.5115412 },
    { lat: 49.4683819, lng: 19.5118269 },
    { lat: 49.4684049, lng: 19.5119592 },
    { lat: 49.4687277, lng: 19.5125699 },
    { lat: 49.4688088, lng: 19.512791 },
    { lat: 49.469006, lng: 19.5131412 },
    { lat: 49.4692619, lng: 19.5134939 },
    { lat: 49.4694289, lng: 19.5137735 },
    { lat: 49.4695804, lng: 19.513933 },
    { lat: 49.4702482, lng: 19.5145715 },
    { lat: 49.4703459, lng: 19.5146386 },
    { lat: 49.4708182, lng: 19.515016 },
    { lat: 49.4709716, lng: 19.5151721 },
    { lat: 49.47171, lng: 19.5154769 },
    { lat: 49.4717061, lng: 19.5154962 },
    { lat: 49.4719883, lng: 19.5157048 },
    { lat: 49.4734466, lng: 19.5157887 },
    { lat: 49.4741972, lng: 19.516318 },
    { lat: 49.474854, lng: 19.5167874 },
    { lat: 49.4753182, lng: 19.5170857 },
    { lat: 49.4755634, lng: 19.517203 },
    { lat: 49.4756078, lng: 19.5171881 },
    { lat: 49.4774618, lng: 19.5178233 },
    { lat: 49.4776647, lng: 19.517926 },
    { lat: 49.4778986, lng: 19.517967 },
    { lat: 49.4781531, lng: 19.5179409 },
    { lat: 49.4787287, lng: 19.5182592 },
    { lat: 49.478791, lng: 19.51832 },
    { lat: 49.4788284, lng: 19.5182968 },
    { lat: 49.480204, lng: 19.5184211 },
    { lat: 49.4826904, lng: 19.5182491 },
    { lat: 49.482932, lng: 19.5185067 },
    { lat: 49.4832465, lng: 19.5183604 },
    { lat: 49.4833321, lng: 19.5182593 },
    { lat: 49.483503, lng: 19.5182077 },
    { lat: 49.4839634, lng: 19.5179484 },
    { lat: 49.4840221, lng: 19.517924 },
    { lat: 49.4842905, lng: 19.5177313 },
    { lat: 49.4846172, lng: 19.5175326 },
    { lat: 49.4848441, lng: 19.5174175 },
    { lat: 49.4850837, lng: 19.5173354 },
    { lat: 49.4853876, lng: 19.5174026 },
    { lat: 49.4855775, lng: 19.5174689 },
    { lat: 49.485701, lng: 19.5174848 },
    { lat: 49.4860659, lng: 19.517431 },
    { lat: 49.4859698, lng: 19.5173996 },
    { lat: 49.4856702, lng: 19.5173518 },
    { lat: 49.4856664, lng: 19.5172678 },
    { lat: 49.4857638, lng: 19.5171512 },
    { lat: 49.4859283, lng: 19.5170775 },
    { lat: 49.486279, lng: 19.5166614 },
    { lat: 49.4866564, lng: 19.5155916 },
    { lat: 49.4867885, lng: 19.515376 },
    { lat: 49.4867862, lng: 19.5153722 },
    { lat: 49.4868522, lng: 19.515192 },
    { lat: 49.4869052, lng: 19.5150896 },
    { lat: 49.4870108, lng: 19.5148101 },
    { lat: 49.4872432, lng: 19.5144693 },
    { lat: 49.4874745, lng: 19.514104 },
    { lat: 49.4875467, lng: 19.5139096 },
    { lat: 49.4876654, lng: 19.5138198 },
    { lat: 49.4878405, lng: 19.5135311 },
    { lat: 49.4878848, lng: 19.5134351 },
    { lat: 49.4879746, lng: 19.5131301 },
    { lat: 49.4882043, lng: 19.5129784 },
    { lat: 49.4884228, lng: 19.5126539 },
    { lat: 49.4886785, lng: 19.5123566 },
    { lat: 49.4888319, lng: 19.5121837 },
    { lat: 49.4889063, lng: 19.5121123 },
    { lat: 49.4889479, lng: 19.5120089 },
    { lat: 49.4890569, lng: 19.5119288 },
    { lat: 49.4891428, lng: 19.511663 },
    { lat: 49.4892395, lng: 19.5114831 },
    { lat: 49.4892716, lng: 19.5114467 },
    { lat: 49.4892972, lng: 19.5110442 },
    { lat: 49.4893104, lng: 19.5108866 },
    { lat: 49.4893484, lng: 19.510705 },
    { lat: 49.4892762, lng: 19.5104554 },
    { lat: 49.4894107, lng: 19.5101928 },
    { lat: 49.4894956, lng: 19.5101286 },
    { lat: 49.4896132, lng: 19.5097123 },
    { lat: 49.4897851, lng: 19.5091576 },
    { lat: 49.4898332, lng: 19.5090249 },
    { lat: 49.4898975, lng: 19.5085523 },
    { lat: 49.489899, lng: 19.5079143 },
    { lat: 49.489845, lng: 19.5074743 },
    { lat: 49.4899997, lng: 19.5072824 },
    { lat: 49.4900587, lng: 19.506964 },
    { lat: 49.4898963, lng: 19.5064403 },
    { lat: 49.489976, lng: 19.5058911 },
    { lat: 49.4900105, lng: 19.5055333 },
    { lat: 49.4900466, lng: 19.5053605 },
    { lat: 49.4901383, lng: 19.5050981 },
    { lat: 49.4901408, lng: 19.5050758 },
    { lat: 49.4901018, lng: 19.5048603 },
    { lat: 49.4900996, lng: 19.5046141 },
    { lat: 49.4900802, lng: 19.5042143 },
    { lat: 49.4900917, lng: 19.5039474 },
    { lat: 49.4900861, lng: 19.5037715 },
    { lat: 49.4901889, lng: 19.5035807 },
    { lat: 49.4902634, lng: 19.5034582 },
    { lat: 49.4902955, lng: 19.503246 },
    { lat: 49.4903548, lng: 19.5030378 },
    { lat: 49.4904344, lng: 19.5028115 },
    { lat: 49.490526, lng: 19.5026194 },
    { lat: 49.49061, lng: 19.5025557 },
    { lat: 49.4908157, lng: 19.5022238 },
    { lat: 49.4909221, lng: 19.5020602 },
    { lat: 49.4910214, lng: 19.5020895 },
    { lat: 49.4911893, lng: 19.5019372 },
    { lat: 49.4914, lng: 19.501746 },
    { lat: 49.491486, lng: 19.5017259 },
    { lat: 49.4916083, lng: 19.5019654 },
    { lat: 49.4918385, lng: 19.5016748 },
    { lat: 49.4918786, lng: 19.5016176 },
    { lat: 49.4919594, lng: 19.5015346 },
    { lat: 49.4920591, lng: 19.5013239 },
    { lat: 49.4922825, lng: 19.5011061 },
    { lat: 49.4924947, lng: 19.5008924 },
    { lat: 49.4927091, lng: 19.5003062 },
    { lat: 49.492716, lng: 19.5002819 },
    { lat: 49.4928443, lng: 19.5001058 },
    { lat: 49.4929062, lng: 19.5000046 },
    { lat: 49.493087, lng: 19.4997431 },
    { lat: 49.4931565, lng: 19.4996349 },
    { lat: 49.4933597, lng: 19.4993475 },
    { lat: 49.4936229, lng: 19.4991388 },
    { lat: 49.4937785, lng: 19.4989946 },
    { lat: 49.4940266, lng: 19.498827 },
    { lat: 49.494092, lng: 19.4987044 },
    { lat: 49.4940556, lng: 19.4985977 },
    { lat: 49.4942004, lng: 19.4985319 },
    { lat: 49.4943274, lng: 19.4984284 },
    { lat: 49.4944055, lng: 19.4983849 },
    { lat: 49.4944816, lng: 19.498326 },
    { lat: 49.4952826, lng: 19.4978313 },
    { lat: 49.4957555, lng: 19.4975259 },
    { lat: 49.4961176, lng: 19.4973117 },
    { lat: 49.4965818, lng: 19.4970409 },
    { lat: 49.497107, lng: 19.4967229 },
    { lat: 49.4971759, lng: 19.4967025 },
    { lat: 49.497474, lng: 19.496569 },
    { lat: 49.4977505, lng: 19.4964782 },
    { lat: 49.4978669, lng: 19.4965139 },
    { lat: 49.4979212, lng: 19.4965193 },
    { lat: 49.4981301, lng: 19.4962795 },
    { lat: 49.4983064, lng: 19.4960188 },
    { lat: 49.4983571, lng: 19.4959956 },
    { lat: 49.4983771, lng: 19.495963 },
    { lat: 49.4985536, lng: 19.4958056 },
    { lat: 49.4987449, lng: 19.4956775 },
    { lat: 49.4989256, lng: 19.4955392 },
    { lat: 49.499288, lng: 19.495305 },
    { lat: 49.4994573, lng: 19.4952383 },
    { lat: 49.4995932, lng: 19.4951538 },
    { lat: 49.4996912, lng: 19.4951038 },
    { lat: 49.4998077, lng: 19.495017 },
    { lat: 49.5001145, lng: 19.4948739 },
    { lat: 49.5002222, lng: 19.4948189 },
    { lat: 49.5003368, lng: 19.4947315 },
    { lat: 49.5006526, lng: 19.4945436 },
    { lat: 49.5008715, lng: 19.4944259 },
    { lat: 49.5009848, lng: 19.4943939 },
    { lat: 49.5012468, lng: 19.4943045 },
    { lat: 49.5014971, lng: 19.4942378 },
    { lat: 49.5015976, lng: 19.4942162 },
    { lat: 49.5016608, lng: 19.4941452 },
    { lat: 49.5019172, lng: 19.494083 },
    { lat: 49.5022294, lng: 19.4940386 },
    { lat: 49.50251, lng: 19.4940172 },
    { lat: 49.5029865, lng: 19.4937642 },
    { lat: 49.5033503, lng: 19.4935668 },
    { lat: 49.5035978, lng: 19.493479 },
    { lat: 49.5037992, lng: 19.4934023 },
    { lat: 49.5040611, lng: 19.4933413 },
    { lat: 49.504492, lng: 19.4931762 },
    { lat: 49.5047284, lng: 19.4930743 },
    { lat: 49.5048279, lng: 19.493055 },
    { lat: 49.5049353, lng: 19.4930152 },
    { lat: 49.5051276, lng: 19.4929079 },
    { lat: 49.5052166, lng: 19.492881 },
    { lat: 49.5054171, lng: 19.4927326 },
    { lat: 49.5055793, lng: 19.4926597 },
    { lat: 49.5055886, lng: 19.492641 },
    { lat: 49.505835, lng: 19.4924851 },
    { lat: 49.5060413, lng: 19.4923376 },
    { lat: 49.5063356, lng: 19.4921962 },
    { lat: 49.5067687, lng: 19.4919587 },
    { lat: 49.5068826, lng: 19.4918892 },
    { lat: 49.5071934, lng: 19.4917348 },
    { lat: 49.5073722, lng: 19.4916543 },
    { lat: 49.5075044, lng: 19.4915611 },
    { lat: 49.507616, lng: 19.4915908 },
    { lat: 49.5078037, lng: 19.4915025 },
    { lat: 49.5081495, lng: 19.4912305 },
    { lat: 49.5083073, lng: 19.4911559 },
    { lat: 49.5085833, lng: 19.4909839 },
    { lat: 49.5086892, lng: 19.4909087 },
    { lat: 49.508923, lng: 19.4907255 },
    { lat: 49.509161, lng: 19.490559 },
    { lat: 49.5092706, lng: 19.4904881 },
    { lat: 49.5096173, lng: 19.4902823 },
    { lat: 49.5098613, lng: 19.4901499 },
    { lat: 49.5102373, lng: 19.4899207 },
    { lat: 49.5104425, lng: 19.4897754 },
    { lat: 49.5109925, lng: 19.4894329 },
    { lat: 49.5111043, lng: 19.4893418 },
    { lat: 49.5112513, lng: 19.4891788 },
    { lat: 49.5114726, lng: 19.488968 },
    { lat: 49.5115415, lng: 19.4889211 },
    { lat: 49.5119253, lng: 19.4885652 },
    { lat: 49.5121727, lng: 19.488357 },
    { lat: 49.5123798, lng: 19.4881567 },
    { lat: 49.5127266, lng: 19.4878556 },
    { lat: 49.5128342, lng: 19.4877189 },
    { lat: 49.5129063, lng: 19.487643 },
    { lat: 49.5133143, lng: 19.4872762 },
    { lat: 49.5133795, lng: 19.4871988 },
    { lat: 49.5134107, lng: 19.4871643 },
    { lat: 49.5135455, lng: 19.4869348 },
    { lat: 49.5136679, lng: 19.4868499 },
    { lat: 49.5137667, lng: 19.4867693 },
    { lat: 49.5143279, lng: 19.4863247 },
    { lat: 49.5144392, lng: 19.486295 },
    { lat: 49.5145546, lng: 19.4860868 },
    { lat: 49.5146208, lng: 19.4859796 },
    { lat: 49.514623, lng: 19.4859754 },
    { lat: 49.5146283, lng: 19.485969 },
    { lat: 49.5144812, lng: 19.4857817 },
    { lat: 49.5144545, lng: 19.4857403 },
    { lat: 49.5142898, lng: 19.4854879 },
    { lat: 49.5140618, lng: 19.4851828 },
    { lat: 49.5140405, lng: 19.4851611 },
    { lat: 49.5138068, lng: 19.4849273 },
    { lat: 49.5136426, lng: 19.4847862 },
    { lat: 49.5135955, lng: 19.4847669 },
    { lat: 49.5134585, lng: 19.4846511 },
    { lat: 49.5133045, lng: 19.4844842 },
    { lat: 49.5131229, lng: 19.4843079 },
    { lat: 49.5130004, lng: 19.4841673 },
    { lat: 49.5129613, lng: 19.4841236 },
    { lat: 49.5128931, lng: 19.4840349 },
    { lat: 49.5127302, lng: 19.4838229 },
    { lat: 49.5125684, lng: 19.483614 },
    { lat: 49.5123639, lng: 19.4833745 },
    { lat: 49.5121756, lng: 19.4831475 },
    { lat: 49.512142, lng: 19.4831047 },
    { lat: 49.5120702, lng: 19.4830123 },
    { lat: 49.5120296, lng: 19.4829637 },
    { lat: 49.5117988, lng: 19.4826445 },
    { lat: 49.5115692, lng: 19.4823258 },
    { lat: 49.5113659, lng: 19.4820456 },
    { lat: 49.5112281, lng: 19.4818407 },
    { lat: 49.5111474, lng: 19.4817286 },
    { lat: 49.5110928, lng: 19.4816373 },
    { lat: 49.5109215, lng: 19.4813653 },
    { lat: 49.5108119, lng: 19.4811882 },
    { lat: 49.510696, lng: 19.480966 },
    { lat: 49.5106243, lng: 19.4808276 },
    { lat: 49.5105515, lng: 19.4806657 },
    { lat: 49.5105528, lng: 19.4805688 },
    { lat: 49.5104195, lng: 19.4804098 },
    { lat: 49.5103935, lng: 19.4803836 },
    { lat: 49.510361, lng: 19.4803646 },
    { lat: 49.5103276, lng: 19.4803011 },
    { lat: 49.5103022, lng: 19.4802346 },
    { lat: 49.5102169, lng: 19.4801455 },
    { lat: 49.510174, lng: 19.4800439 },
    { lat: 49.5100465, lng: 19.4799191 },
    { lat: 49.509802, lng: 19.4795491 },
    { lat: 49.5097114, lng: 19.4794102 },
    { lat: 49.5096476, lng: 19.4793007 },
    { lat: 49.5094963, lng: 19.4790421 },
    { lat: 49.5094915, lng: 19.4790343 },
    { lat: 49.5093645, lng: 19.4788485 },
    { lat: 49.5093357, lng: 19.4788074 },
    { lat: 49.5093261, lng: 19.4787939 },
    { lat: 49.509309, lng: 19.4787664 },
    { lat: 49.509292, lng: 19.4787396 },
    { lat: 49.5092666, lng: 19.478701 },
    { lat: 49.5092256, lng: 19.4786387 },
    { lat: 49.5089642, lng: 19.478244 },
    { lat: 49.5088055, lng: 19.4779801 },
    { lat: 49.5086269, lng: 19.4776415 },
    { lat: 49.5084208, lng: 19.4772724 },
    { lat: 49.5083323, lng: 19.4771141 },
    { lat: 49.5080368, lng: 19.4766601 },
    { lat: 49.5076027, lng: 19.4760882 },
    { lat: 49.5072664, lng: 19.4756038 },
    { lat: 49.5068764, lng: 19.4751074 },
    { lat: 49.5068228, lng: 19.4750457 },
    { lat: 49.5068004, lng: 19.4750198 },
    { lat: 49.5067085, lng: 19.4749561 },
    { lat: 49.5061843, lng: 19.4743603 },
    { lat: 49.5055161, lng: 19.4734689 },
    { lat: 49.5053673, lng: 19.4732457 },
    { lat: 49.505312, lng: 19.473145 },
    { lat: 49.505296, lng: 19.4731163 },
    { lat: 49.5052263, lng: 19.4729683 },
    { lat: 49.505161, lng: 19.4728219 },
    { lat: 49.5051448, lng: 19.4727864 },
    { lat: 49.5049798, lng: 19.4724575 },
    { lat: 49.5049302, lng: 19.4723588 },
    { lat: 49.5048722, lng: 19.4722451 },
    { lat: 49.5048096, lng: 19.4721311 },
    { lat: 49.5047329, lng: 19.4719831 },
    { lat: 49.5046292, lng: 19.4717854 },
    { lat: 49.5044975, lng: 19.4715419 },
    { lat: 49.5044422, lng: 19.4714369 },
    { lat: 49.5043338, lng: 19.4712357 },
    { lat: 49.5043028, lng: 19.4711771 },
    { lat: 49.5042611, lng: 19.4711022 },
    { lat: 49.504201, lng: 19.4709955 },
    { lat: 49.5041241, lng: 19.4708624 },
    { lat: 49.5040288, lng: 19.4706773 },
    { lat: 49.5039151, lng: 19.470406 },
    { lat: 49.5038402, lng: 19.4702234 },
    { lat: 49.5037619, lng: 19.4700393 },
    { lat: 49.503669, lng: 19.4698039 },
    { lat: 49.5035241, lng: 19.4694182 },
    { lat: 49.503304, lng: 19.4688677 },
    { lat: 49.5032457, lng: 19.4687246 },
    { lat: 49.5031494, lng: 19.4684917 },
    { lat: 49.5029542, lng: 19.4680447 },
    { lat: 49.5027702, lng: 19.467619 },
    { lat: 49.5027138, lng: 19.4674891 },
    { lat: 49.5026775, lng: 19.4673879 },
    { lat: 49.5025911, lng: 19.4671496 },
    { lat: 49.502515, lng: 19.4670143 },
    { lat: 49.5023766, lng: 19.46677 },
    { lat: 49.5023448, lng: 19.4667143 },
    { lat: 49.5023325, lng: 19.4666943 },
    { lat: 49.50225, lng: 19.4665621 },
    { lat: 49.5021622, lng: 19.4664193 },
    { lat: 49.5021046, lng: 19.4663161 },
    { lat: 49.5020923, lng: 19.4662942 },
    { lat: 49.502024, lng: 19.4661783 },
    { lat: 49.5019582, lng: 19.466073 },
    { lat: 49.5019421, lng: 19.466041 },
    { lat: 49.5018041, lng: 19.4657793 },
    { lat: 49.5017186, lng: 19.465588 },
    { lat: 49.5016431, lng: 19.4654131 },
    { lat: 49.5015484, lng: 19.465237 },
    { lat: 49.501466, lng: 19.4650874 },
    { lat: 49.501439, lng: 19.4650382 },
    { lat: 49.501423, lng: 19.4650087 },
    { lat: 49.5014009, lng: 19.4649687 },
    { lat: 49.5013714, lng: 19.4649171 },
    { lat: 49.5013554, lng: 19.4648873 },
    { lat: 49.5013135, lng: 19.4648084 },
    { lat: 49.5012815, lng: 19.4647492 },
    { lat: 49.5012643, lng: 19.464718 },
    { lat: 49.5012261, lng: 19.4646475 },
    { lat: 49.5011913, lng: 19.4645722 },
    { lat: 49.5011887, lng: 19.4645685 },
    { lat: 49.5011646, lng: 19.4645316 },
    { lat: 49.5009993, lng: 19.4641231 },
    { lat: 49.500974, lng: 19.4640602 },
    { lat: 49.5008235, lng: 19.4637531 },
    { lat: 49.5007428, lng: 19.4635885 },
    { lat: 49.5007167, lng: 19.4635076 },
    { lat: 49.5006578, lng: 19.4633294 },
    { lat: 49.500609, lng: 19.4631763 },
    { lat: 49.5006051, lng: 19.4631647 },
    { lat: 49.5005781, lng: 19.4630179 },
    { lat: 49.5005452, lng: 19.4628398 },
    { lat: 49.5004951, lng: 19.4626074 },
    { lat: 49.5004749, lng: 19.4625103 },
    { lat: 49.5004274, lng: 19.4623338 },
    { lat: 49.5003842, lng: 19.462175 },
    { lat: 49.5003729, lng: 19.4621291 },
    { lat: 49.5003665, lng: 19.4621076 },
    { lat: 49.5003648, lng: 19.461998 },
    { lat: 49.5003638, lng: 19.4619303 },
    { lat: 49.5003594, lng: 19.4618546 },
    { lat: 49.5003522, lng: 19.4615527 },
    { lat: 49.5003496, lng: 19.461445 },
    { lat: 49.5003462, lng: 19.4613953 },
    { lat: 49.5003402, lng: 19.4611665 },
    { lat: 49.5003305, lng: 19.4608308 },
    { lat: 49.5003299, lng: 19.4602048 },
    { lat: 49.5003294, lng: 19.4596709 },
    { lat: 49.5003279, lng: 19.4594423 },
    { lat: 49.5003265, lng: 19.4589491 },
    { lat: 49.5003089, lng: 19.4586092 },
    { lat: 49.500306, lng: 19.4585732 },
    { lat: 49.5002878, lng: 19.4580006 },
    { lat: 49.5002834, lng: 19.457805 },
    { lat: 49.5002813, lng: 19.457492 },
    { lat: 49.5002736, lng: 19.4574483 },
    { lat: 49.5002485, lng: 19.4573158 },
    { lat: 49.5001878, lng: 19.4564799 },
    { lat: 49.5001801, lng: 19.4563393 },
    { lat: 49.5001911, lng: 19.4563102 },
    { lat: 49.5001835, lng: 19.4560471 },
    { lat: 49.5001349, lng: 19.4550838 },
    { lat: 49.5001248, lng: 19.4549808 },
    { lat: 49.5001198, lng: 19.45431 },
    { lat: 49.5001205, lng: 19.4543001 },
    { lat: 49.5002383, lng: 19.4529867 },
    { lat: 49.5002397, lng: 19.4529659 },
    { lat: 49.5002397, lng: 19.4528924 },
    { lat: 49.5002427, lng: 19.452809 },
    { lat: 49.5002419, lng: 19.4527921 },
    { lat: 49.5002409, lng: 19.4527704 },
    { lat: 49.5002444, lng: 19.4527023 },
    { lat: 49.5002276, lng: 19.4524079 },
    { lat: 49.5002119, lng: 19.4520649 },
    { lat: 49.5001265, lng: 19.4515564 },
    { lat: 49.4999773, lng: 19.4506348 },
    { lat: 49.4998471, lng: 19.4499329 },
    { lat: 49.4996848, lng: 19.4492653 },
    { lat: 49.4995755, lng: 19.4487765 },
    { lat: 49.4994963, lng: 19.4483515 },
    { lat: 49.4994306, lng: 19.4478051 },
    { lat: 49.4993737, lng: 19.4474708 },
    { lat: 49.4992975, lng: 19.4471111 },
    { lat: 49.4991758, lng: 19.4466708 },
    { lat: 49.4991131, lng: 19.4465304 },
    { lat: 49.4990548, lng: 19.4464378 },
    { lat: 49.4987847, lng: 19.4460769 },
    { lat: 49.4986059, lng: 19.4458646 },
    { lat: 49.4985719, lng: 19.4458339 },
    { lat: 49.4983466, lng: 19.4456172 },
    { lat: 49.4979307, lng: 19.4451956 },
    { lat: 49.4979119, lng: 19.4451774 },
    { lat: 49.4978997, lng: 19.4451787 },
    { lat: 49.4978569, lng: 19.4451833 },
    { lat: 49.4977147, lng: 19.445028 },
    { lat: 49.4973493, lng: 19.4446274 },
    { lat: 49.4967882, lng: 19.4440396 },
    { lat: 49.4966188, lng: 19.4438811 },
    { lat: 49.4965964, lng: 19.4438625 },
    { lat: 49.4965555, lng: 19.4438289 },
    { lat: 49.4965342, lng: 19.4438087 },
    { lat: 49.496322, lng: 19.4436036 },
    { lat: 49.4962985, lng: 19.4435797 },
    { lat: 49.4962711, lng: 19.4436461 },
    { lat: 49.4962048, lng: 19.4436011 },
    { lat: 49.4960233, lng: 19.443479 },
    { lat: 49.4958021, lng: 19.4433213 },
    { lat: 49.4956576, lng: 19.4432184 },
    { lat: 49.4953997, lng: 19.4430713 },
    { lat: 49.4953775, lng: 19.4430566 },
    { lat: 49.4953403, lng: 19.4430324 },
    { lat: 49.4952904, lng: 19.4429997 },
    { lat: 49.4952753, lng: 19.4429898 },
    { lat: 49.4950607, lng: 19.4426806 },
    { lat: 49.4947814, lng: 19.4422447 },
    { lat: 49.4945801, lng: 19.4419084 },
    { lat: 49.4942643, lng: 19.4413651 },
    { lat: 49.4938794, lng: 19.4407816 },
    { lat: 49.4935304, lng: 19.4403249 },
    { lat: 49.4932867, lng: 19.4399472 },
    { lat: 49.4929056, lng: 19.4394531 },
    { lat: 49.4922157, lng: 19.4384419 },
    { lat: 49.4916676, lng: 19.437253 },
    { lat: 49.4915835, lng: 19.4369623 },
    { lat: 49.4915487, lng: 19.4368425 },
    { lat: 49.4915152, lng: 19.4367298 },
    { lat: 49.4912393, lng: 19.4360435 },
    { lat: 49.4911267, lng: 19.4358038 },
    { lat: 49.4909163, lng: 19.4352427 },
    { lat: 49.4908295, lng: 19.4349723 },
    { lat: 49.4908267, lng: 19.4349639 },
    { lat: 49.4906755, lng: 19.4344682 },
    { lat: 49.4905146, lng: 19.4338854 },
    { lat: 49.4903178, lng: 19.4332554 },
    { lat: 49.4900213, lng: 19.4320759 },
    { lat: 49.4898446, lng: 19.4312956 },
    { lat: 49.489527, lng: 19.4300992 },
    { lat: 49.4894354, lng: 19.4297238 },
    { lat: 49.4893191, lng: 19.429229 },
    { lat: 49.4892008, lng: 19.4288138 },
    { lat: 49.4891981, lng: 19.4288047 },
    { lat: 49.4890796, lng: 19.4283852 },
    { lat: 49.489078, lng: 19.4283774 },
    { lat: 49.4889854, lng: 19.4280275 },
    { lat: 49.4886909, lng: 19.4269635 },
    { lat: 49.4884199, lng: 19.4250934 },
    { lat: 49.4884177, lng: 19.4250705 },
    { lat: 49.4882653, lng: 19.4235474 },
    { lat: 49.4882546, lng: 19.4235095 },
    { lat: 49.4881731, lng: 19.4232327 },
    { lat: 49.4876074, lng: 19.4221815 },
    { lat: 49.4872891, lng: 19.4214593 },
    { lat: 49.4872785, lng: 19.4214228 },
    { lat: 49.4871679, lng: 19.4210061 },
    { lat: 49.4870011, lng: 19.4203236 },
    { lat: 49.486749, lng: 19.4195918 },
    { lat: 49.4866878, lng: 19.4191965 },
    { lat: 49.4864015, lng: 19.4173351 },
    { lat: 49.4863688, lng: 19.4171889 },
    { lat: 49.4860682, lng: 19.416366 },
    { lat: 49.4858684, lng: 19.4159191 },
    { lat: 49.4856657, lng: 19.4155265 },
    { lat: 49.4854489, lng: 19.4150804 },
    { lat: 49.4852859, lng: 19.414772 },
    { lat: 49.4851887, lng: 19.4145955 },
    { lat: 49.4848907, lng: 19.4140677 },
    { lat: 49.4846121, lng: 19.4135957 },
    { lat: 49.4843944, lng: 19.4134215 },
  ],
  Rabčice: [
    { lat: 49.5426626, lng: 19.5292166 },
    { lat: 49.5426802, lng: 19.5291826 },
    { lat: 49.5426835, lng: 19.5291311 },
    { lat: 49.5426845, lng: 19.5290788 },
    { lat: 49.542684, lng: 19.529015 },
    { lat: 49.5426799, lng: 19.5287996 },
    { lat: 49.5427376, lng: 19.5286813 },
    { lat: 49.5428272, lng: 19.5284962 },
    { lat: 49.5427477, lng: 19.5282561 },
    { lat: 49.5426694, lng: 19.5280181 },
    { lat: 49.542591, lng: 19.5277823 },
    { lat: 49.5425569, lng: 19.5276749 },
    { lat: 49.5424788, lng: 19.5274398 },
    { lat: 49.5424138, lng: 19.5272712 },
    { lat: 49.5423396, lng: 19.5270763 },
    { lat: 49.5422425, lng: 19.5268233 },
    { lat: 49.5421713, lng: 19.5266418 },
    { lat: 49.5421191, lng: 19.526511 },
    { lat: 49.5420559, lng: 19.5263548 },
    { lat: 49.5419724, lng: 19.5261526 },
    { lat: 49.5418807, lng: 19.5259204 },
    { lat: 49.5417575, lng: 19.5256128 },
    { lat: 49.5417441, lng: 19.5255874 },
    { lat: 49.5417209, lng: 19.5254248 },
    { lat: 49.541689, lng: 19.5251935 },
    { lat: 49.5416623, lng: 19.5250001 },
    { lat: 49.5416258, lng: 19.5246666 },
    { lat: 49.5415918, lng: 19.5243383 },
    { lat: 49.5413697, lng: 19.5237329 },
    { lat: 49.5412558, lng: 19.5234062 },
    { lat: 49.5409886, lng: 19.5225288 },
    { lat: 49.5407199, lng: 19.5218428 },
    { lat: 49.540552, lng: 19.5216696 },
    { lat: 49.5404494, lng: 19.5214937 },
    { lat: 49.5403811, lng: 19.5215028 },
    { lat: 49.5403823, lng: 19.5214058 },
    { lat: 49.5403841, lng: 19.5213443 },
    { lat: 49.5403643, lng: 19.5211309 },
    { lat: 49.5402433, lng: 19.5210709 },
    { lat: 49.5402069, lng: 19.5210661 },
    { lat: 49.5400412, lng: 19.5210598 },
    { lat: 49.5399622, lng: 19.5210109 },
    { lat: 49.5398807, lng: 19.5209518 },
    { lat: 49.5395429, lng: 19.5200795 },
    { lat: 49.5394628, lng: 19.5199538 },
    { lat: 49.5393329, lng: 19.5197478 },
    { lat: 49.5391414, lng: 19.5197 },
    { lat: 49.5390911, lng: 19.519532 },
    { lat: 49.5390817, lng: 19.5194975 },
    { lat: 49.5388077, lng: 19.5193421 },
    { lat: 49.5386784, lng: 19.519103 },
    { lat: 49.538694, lng: 19.5188217 },
    { lat: 49.5386541, lng: 19.5185404 },
    { lat: 49.5384075, lng: 19.5183183 },
    { lat: 49.538362, lng: 19.518059 },
    { lat: 49.5381623, lng: 19.5178761 },
    { lat: 49.5380728, lng: 19.517791 },
    { lat: 49.5379758, lng: 19.5174629 },
    { lat: 49.5379836, lng: 19.5174378 },
    { lat: 49.5380059, lng: 19.5173363 },
    { lat: 49.5382288, lng: 19.515938 },
    { lat: 49.538291, lng: 19.5156894 },
    { lat: 49.538418, lng: 19.5151901 },
    { lat: 49.5385846, lng: 19.5144624 },
    { lat: 49.5386097, lng: 19.5144176 },
    { lat: 49.5388473, lng: 19.5139703 },
    { lat: 49.539153, lng: 19.5133547 },
    { lat: 49.5393386, lng: 19.5128244 },
    { lat: 49.5393785, lng: 19.5127146 },
    { lat: 49.5393906, lng: 19.5126803 },
    { lat: 49.539443, lng: 19.5123185 },
    { lat: 49.5395982, lng: 19.5111958 },
    { lat: 49.5396483, lng: 19.510212 },
    { lat: 49.5396652, lng: 19.5093171 },
    { lat: 49.5396659, lng: 19.5092071 },
    { lat: 49.5396803, lng: 19.5089262 },
    { lat: 49.539749, lng: 19.5086025 },
    { lat: 49.5398764, lng: 19.5080084 },
    { lat: 49.5399042, lng: 19.5078756 },
    { lat: 49.5399341, lng: 19.5077389 },
    { lat: 49.5399597, lng: 19.5076113 },
    { lat: 49.5399859, lng: 19.5074914 },
    { lat: 49.5400133, lng: 19.5073763 },
    { lat: 49.5400404, lng: 19.5072508 },
    { lat: 49.5400674, lng: 19.5071268 },
    { lat: 49.5400967, lng: 19.5070028 },
    { lat: 49.5401099, lng: 19.5068472 },
    { lat: 49.54012, lng: 19.5067206 },
    { lat: 49.5401307, lng: 19.5065856 },
    { lat: 49.5401415, lng: 19.5064502 },
    { lat: 49.5401337, lng: 19.5063299 },
    { lat: 49.540119, lng: 19.5061776 },
    { lat: 49.5401328, lng: 19.5061323 },
    { lat: 49.540163, lng: 19.5060079 },
    { lat: 49.5402007, lng: 19.5058696 },
    { lat: 49.5402145, lng: 19.5057013 },
    { lat: 49.5402283, lng: 19.5055568 },
    { lat: 49.5402457, lng: 19.5053681 },
    { lat: 49.540259, lng: 19.5052147 },
    { lat: 49.5402733, lng: 19.5050815 },
    { lat: 49.5402832, lng: 19.5049556 },
    { lat: 49.5402953, lng: 19.504802 },
    { lat: 49.5403089, lng: 19.5046732 },
    { lat: 49.5403156, lng: 19.5045781 },
    { lat: 49.540326, lng: 19.504458 },
    { lat: 49.5403396, lng: 19.5043358 },
    { lat: 49.540341, lng: 19.5042892 },
    { lat: 49.5403646, lng: 19.5041437 },
    { lat: 49.5403845, lng: 19.5040339 },
    { lat: 49.5404015, lng: 19.5039355 },
    { lat: 49.5404222, lng: 19.5038208 },
    { lat: 49.540442, lng: 19.5037088 },
    { lat: 49.5404609, lng: 19.5036059 },
    { lat: 49.5404787, lng: 19.5034985 },
    { lat: 49.5405052, lng: 19.5033393 },
    { lat: 49.5405319, lng: 19.5031826 },
    { lat: 49.5405528, lng: 19.5030508 },
    { lat: 49.5405772, lng: 19.5029162 },
    { lat: 49.5405968, lng: 19.5027755 },
    { lat: 49.5406301, lng: 19.502642 },
    { lat: 49.5406575, lng: 19.5025266 },
    { lat: 49.5406841, lng: 19.5024164 },
    { lat: 49.5407151, lng: 19.5023028 },
    { lat: 49.5407433, lng: 19.5021815 },
    { lat: 49.5407599, lng: 19.5020742 },
    { lat: 49.5407825, lng: 19.5019263 },
    { lat: 49.5407985, lng: 19.5018103 },
    { lat: 49.5408066, lng: 19.5016403 },
    { lat: 49.54081, lng: 19.5015892 },
    { lat: 49.5408146, lng: 19.501494 },
    { lat: 49.5408233, lng: 19.5014096 },
    { lat: 49.5408336, lng: 19.5013142 },
    { lat: 49.5408516, lng: 19.5011429 },
    { lat: 49.5408622, lng: 19.5010296 },
    { lat: 49.5408757, lng: 19.5009048 },
    { lat: 49.5408935, lng: 19.5007709 },
    { lat: 49.5409064, lng: 19.5006596 },
    { lat: 49.54092, lng: 19.5005134 },
    { lat: 49.540928, lng: 19.5004196 },
    { lat: 49.5409427, lng: 19.5002954 },
    { lat: 49.5409482, lng: 19.5002176 },
    { lat: 49.5409533, lng: 19.5001071 },
    { lat: 49.5409585, lng: 19.4999723 },
    { lat: 49.5409641, lng: 19.499897 },
    { lat: 49.5409977, lng: 19.4998165 },
    { lat: 49.5410519, lng: 19.4996942 },
    { lat: 49.5411053, lng: 19.4995807 },
    { lat: 49.5411564, lng: 19.4994693 },
    { lat: 49.5411955, lng: 19.4993839 },
    { lat: 49.5412552, lng: 19.4992588 },
    { lat: 49.5412442, lng: 19.4991675 },
    { lat: 49.5412361, lng: 19.4990875 },
    { lat: 49.541226, lng: 19.4990156 },
    { lat: 49.5412194, lng: 19.4989438 },
    { lat: 49.5411992, lng: 19.4987725 },
    { lat: 49.5412431, lng: 19.4986492 },
    { lat: 49.5412781, lng: 19.4985518 },
    { lat: 49.5412902, lng: 19.4985164 },
    { lat: 49.5412404, lng: 19.4985102 },
    { lat: 49.5410721, lng: 19.498481 },
    { lat: 49.5409438, lng: 19.4987056 },
    { lat: 49.5407709, lng: 19.4991631 },
    { lat: 49.5407542, lng: 19.4991961 },
    { lat: 49.5405264, lng: 19.4992626 },
    { lat: 49.540057, lng: 19.4993963 },
    { lat: 49.5399686, lng: 19.4993572 },
    { lat: 49.5398322, lng: 19.4993083 },
    { lat: 49.5394753, lng: 19.4993857 },
    { lat: 49.5389712, lng: 19.4993545 },
    { lat: 49.5386791, lng: 19.4995911 },
    { lat: 49.5383757, lng: 19.4995587 },
    { lat: 49.5378201, lng: 19.4995058 },
    { lat: 49.5375048, lng: 19.4988151 },
    { lat: 49.537395, lng: 19.4987329 },
    { lat: 49.5371615, lng: 19.4985758 },
    { lat: 49.5368735, lng: 19.4983103 },
    { lat: 49.5366552, lng: 19.4982184 },
    { lat: 49.5366447, lng: 19.4980087 },
    { lat: 49.5366409, lng: 19.4978771 },
    { lat: 49.5366161, lng: 19.4978054 },
    { lat: 49.5365507, lng: 19.4977003 },
    { lat: 49.536517, lng: 19.4976546 },
    { lat: 49.5363843, lng: 19.4975868 },
    { lat: 49.5362921, lng: 19.4976152 },
    { lat: 49.5360899, lng: 19.4976746 },
    { lat: 49.5358398, lng: 19.4977043 },
    { lat: 49.5357591, lng: 19.4977111 },
    { lat: 49.5356907, lng: 19.4974943 },
    { lat: 49.5356774, lng: 19.4974235 },
    { lat: 49.535657, lng: 19.4972973 },
    { lat: 49.5356069, lng: 19.4969889 },
    { lat: 49.535566, lng: 19.4966813 },
    { lat: 49.535521, lng: 19.49661 },
    { lat: 49.5354444, lng: 19.4966127 },
    { lat: 49.5354175, lng: 19.4965888 },
    { lat: 49.5353296, lng: 19.4964886 },
    { lat: 49.5353104, lng: 19.4964399 },
    { lat: 49.5352652, lng: 19.4962384 },
    { lat: 49.5352545, lng: 19.4962021 },
    { lat: 49.535151, lng: 19.4960533 },
    { lat: 49.5351255, lng: 19.49599 },
    { lat: 49.5350601, lng: 19.4957162 },
    { lat: 49.5348183, lng: 19.4953029 },
    { lat: 49.5346693, lng: 19.4948255 },
    { lat: 49.5345852, lng: 19.4945636 },
    { lat: 49.5344067, lng: 19.4945018 },
    { lat: 49.5343816, lng: 19.4944686 },
    { lat: 49.5344181, lng: 19.4941332 },
    { lat: 49.534413, lng: 19.4940934 },
    { lat: 49.5344017, lng: 19.4940435 },
    { lat: 49.5343761, lng: 19.4939788 },
    { lat: 49.5343296, lng: 19.4938963 },
    { lat: 49.5341088, lng: 19.493525 },
    { lat: 49.5340306, lng: 19.4933879 },
    { lat: 49.5339409, lng: 19.493202 },
    { lat: 49.5338827, lng: 19.4930596 },
    { lat: 49.5338566, lng: 19.4927849 },
    { lat: 49.5338937, lng: 19.4927337 },
    { lat: 49.5339129, lng: 19.492711 },
    { lat: 49.5339997, lng: 19.492611 },
    { lat: 49.5340885, lng: 19.4925072 },
    { lat: 49.534174, lng: 19.4924037 },
    { lat: 49.5342648, lng: 19.4922971 },
    { lat: 49.5343528, lng: 19.492197 },
    { lat: 49.5344361, lng: 19.4920981 },
    { lat: 49.5345089, lng: 19.4920181 },
    { lat: 49.5345744, lng: 19.4919407 },
    { lat: 49.5346318, lng: 19.4918703 },
    { lat: 49.5346244, lng: 19.4917525 },
    { lat: 49.5346127, lng: 19.4915938 },
    { lat: 49.5346003, lng: 19.4914474 },
    { lat: 49.5345868, lng: 19.4912751 },
    { lat: 49.5345748, lng: 19.491135 },
    { lat: 49.5345365, lng: 19.4906399 },
    { lat: 49.5344321, lng: 19.4892593 },
    { lat: 49.534246, lng: 19.4889807 },
    { lat: 49.5339169, lng: 19.4884836 },
    { lat: 49.5335794, lng: 19.4879768 },
    { lat: 49.5332067, lng: 19.4874121 },
    { lat: 49.5328424, lng: 19.4868574 },
    { lat: 49.5324664, lng: 19.4862935 },
    { lat: 49.5321422, lng: 19.4858049 },
    { lat: 49.5317223, lng: 19.4851688 },
    { lat: 49.5316105, lng: 19.4850165 },
    { lat: 49.5315137, lng: 19.4848692 },
    { lat: 49.5313782, lng: 19.4846639 },
    { lat: 49.5312608, lng: 19.484485 },
    { lat: 49.5311362, lng: 19.4842942 },
    { lat: 49.5310396, lng: 19.4841526 },
    { lat: 49.530922, lng: 19.4839715 },
    { lat: 49.5308017, lng: 19.4837824 },
    { lat: 49.5306742, lng: 19.4835788 },
    { lat: 49.5305675, lng: 19.483414 },
    { lat: 49.5304569, lng: 19.4832348 },
    { lat: 49.5302895, lng: 19.4829713 },
    { lat: 49.5301113, lng: 19.4826959 },
    { lat: 49.5299818, lng: 19.4825013 },
    { lat: 49.529862, lng: 19.4823183 },
    { lat: 49.5297458, lng: 19.4821418 },
    { lat: 49.5296407, lng: 19.4819841 },
    { lat: 49.5295193, lng: 19.4817969 },
    { lat: 49.5294469, lng: 19.4816904 },
    { lat: 49.5292908, lng: 19.48145 },
    { lat: 49.5291719, lng: 19.4812644 },
    { lat: 49.529041, lng: 19.4810659 },
    { lat: 49.5289163, lng: 19.4808744 },
    { lat: 49.5287613, lng: 19.4806365 },
    { lat: 49.5285978, lng: 19.4803856 },
    { lat: 49.5284443, lng: 19.4801559 },
    { lat: 49.5283011, lng: 19.4799319 },
    { lat: 49.5281801, lng: 19.4797534 },
    { lat: 49.5280618, lng: 19.4795746 },
    { lat: 49.5279253, lng: 19.479372 },
    { lat: 49.5278188, lng: 19.4792119 },
    { lat: 49.5276967, lng: 19.4790296 },
    { lat: 49.5275288, lng: 19.4787839 },
    { lat: 49.5273861, lng: 19.4785704 },
    { lat: 49.5272525, lng: 19.4783849 },
    { lat: 49.5271526, lng: 19.4782441 },
    { lat: 49.5270916, lng: 19.4781382 },
    { lat: 49.5270052, lng: 19.4779981 },
    { lat: 49.5268869, lng: 19.4778262 },
    { lat: 49.5268363, lng: 19.4777526 },
    { lat: 49.5268084, lng: 19.4777117 },
    { lat: 49.5267387, lng: 19.4775168 },
    { lat: 49.5266516, lng: 19.4772846 },
    { lat: 49.5265652, lng: 19.4770476 },
    { lat: 49.5264757, lng: 19.4768132 },
    { lat: 49.5263908, lng: 19.4765833 },
    { lat: 49.5263226, lng: 19.4763966 },
    { lat: 49.526247, lng: 19.476194 },
    { lat: 49.5261608, lng: 19.475961 },
    { lat: 49.5260925, lng: 19.4757732 },
    { lat: 49.52615, lng: 19.475626 },
    { lat: 49.5261116, lng: 19.4755757 },
    { lat: 49.5260587, lng: 19.475502 },
    { lat: 49.5258121, lng: 19.4750599 },
    { lat: 49.5257721, lng: 19.4750207 },
    { lat: 49.5257254, lng: 19.4749862 },
    { lat: 49.5256856, lng: 19.4749771 },
    { lat: 49.5256068, lng: 19.474979 },
    { lat: 49.5255005, lng: 19.4752146 },
    { lat: 49.5254522, lng: 19.4753213 },
    { lat: 49.525441, lng: 19.4753704 },
    { lat: 49.52541, lng: 19.4755301 },
    { lat: 49.5253482, lng: 19.4757079 },
    { lat: 49.525298, lng: 19.4758631 },
    { lat: 49.5252578, lng: 19.4759969 },
    { lat: 49.5252223, lng: 19.4761345 },
    { lat: 49.5251942, lng: 19.4762344 },
    { lat: 49.5251371, lng: 19.4763639 },
    { lat: 49.5250654, lng: 19.476521 },
    { lat: 49.5248887, lng: 19.4767246 },
    { lat: 49.5247646, lng: 19.4767953 },
    { lat: 49.5246255, lng: 19.4768081 },
    { lat: 49.5245685, lng: 19.4768164 },
    { lat: 49.5245272, lng: 19.4768273 },
    { lat: 49.5243895, lng: 19.4768465 },
    { lat: 49.5242447, lng: 19.4770314 },
    { lat: 49.5241633, lng: 19.4771457 },
    { lat: 49.5240669, lng: 19.4773581 },
    { lat: 49.5239486, lng: 19.4775797 },
    { lat: 49.5239022, lng: 19.4776703 },
    { lat: 49.5237526, lng: 19.4779504 },
    { lat: 49.523694, lng: 19.4780953 },
    { lat: 49.523686, lng: 19.4781226 },
    { lat: 49.5236806, lng: 19.4781493 },
    { lat: 49.5236331, lng: 19.4782708 },
    { lat: 49.5236272, lng: 19.4784365 },
    { lat: 49.5236191, lng: 19.4786528 },
    { lat: 49.5236109, lng: 19.4788205 },
    { lat: 49.5236044, lng: 19.4789006 },
    { lat: 49.5235981, lng: 19.4789582 },
    { lat: 49.5235978, lng: 19.4790047 },
    { lat: 49.5235861, lng: 19.4790433 },
    { lat: 49.5235299, lng: 19.479095 },
    { lat: 49.5234205, lng: 19.4791906 },
    { lat: 49.52338, lng: 19.4792192 },
    { lat: 49.5233466, lng: 19.4792993 },
    { lat: 49.5232988, lng: 19.4794122 },
    { lat: 49.5232457, lng: 19.4795365 },
    { lat: 49.5231567, lng: 19.4797332 },
    { lat: 49.5231277, lng: 19.4797845 },
    { lat: 49.5231067, lng: 19.479822 },
    { lat: 49.5229808, lng: 19.4800223 },
    { lat: 49.5229574, lng: 19.4800534 },
    { lat: 49.5229022, lng: 19.4801323 },
    { lat: 49.5228567, lng: 19.4801963 },
    { lat: 49.5228188, lng: 19.4802555 },
    { lat: 49.5227859, lng: 19.4803004 },
    { lat: 49.5226641, lng: 19.4804919 },
    { lat: 49.5225967, lng: 19.4805713 },
    { lat: 49.5225387, lng: 19.4806301 },
    { lat: 49.5225011, lng: 19.4806719 },
    { lat: 49.5224642, lng: 19.4807067 },
    { lat: 49.5224038, lng: 19.4807643 },
    { lat: 49.5223257, lng: 19.4808322 },
    { lat: 49.5220988, lng: 19.4810912 },
    { lat: 49.5219765, lng: 19.4813241 },
    { lat: 49.521858, lng: 19.4815679 },
    { lat: 49.5218169, lng: 19.4816078 },
    { lat: 49.5217668, lng: 19.4816183 },
    { lat: 49.5215903, lng: 19.481626 },
    { lat: 49.5215371, lng: 19.4816227 },
    { lat: 49.5213681, lng: 19.4815984 },
    { lat: 49.5213033, lng: 19.4815861 },
    { lat: 49.5212757, lng: 19.4815695 },
    { lat: 49.521117, lng: 19.4814767 },
    { lat: 49.5209958, lng: 19.4814114 },
    { lat: 49.5209097, lng: 19.4815019 },
    { lat: 49.5206438, lng: 19.4817952 },
    { lat: 49.5203909, lng: 19.4820842 },
    { lat: 49.5202693, lng: 19.4822336 },
    { lat: 49.5200879, lng: 19.482429 },
    { lat: 49.5197737, lng: 19.4827579 },
    { lat: 49.5197556, lng: 19.4827283 },
    { lat: 49.5197442, lng: 19.4827009 },
    { lat: 49.5197073, lng: 19.4827292 },
    { lat: 49.5195955, lng: 19.4827985 },
    { lat: 49.5194608, lng: 19.4828851 },
    { lat: 49.5193809, lng: 19.4829398 },
    { lat: 49.5192912, lng: 19.4830422 },
    { lat: 49.5191869, lng: 19.4831542 },
    { lat: 49.5190488, lng: 19.4831647 },
    { lat: 49.5189555, lng: 19.4831682 },
    { lat: 49.5189092, lng: 19.4831928 },
    { lat: 49.5188447, lng: 19.4832131 },
    { lat: 49.5187719, lng: 19.4832471 },
    { lat: 49.5186934, lng: 19.4832751 },
    { lat: 49.5186536, lng: 19.4832928 },
    { lat: 49.5186014, lng: 19.4833093 },
    { lat: 49.5185376, lng: 19.4834416 },
    { lat: 49.5185198, lng: 19.4836216 },
    { lat: 49.5185078, lng: 19.4837305 },
    { lat: 49.5184004, lng: 19.4838915 },
    { lat: 49.518356, lng: 19.4839597 },
    { lat: 49.5183077, lng: 19.4839627 },
    { lat: 49.5182213, lng: 19.483972 },
    { lat: 49.5181397, lng: 19.4839851 },
    { lat: 49.5180277, lng: 19.4838985 },
    { lat: 49.5179503, lng: 19.4838321 },
    { lat: 49.5178205, lng: 19.4837761 },
    { lat: 49.5177812, lng: 19.4838064 },
    { lat: 49.5176584, lng: 19.4839291 },
    { lat: 49.5175841, lng: 19.4840035 },
    { lat: 49.5175097, lng: 19.4841014 },
    { lat: 49.5174628, lng: 19.4841594 },
    { lat: 49.5174472, lng: 19.4841915 },
    { lat: 49.5174211, lng: 19.4842871 },
    { lat: 49.5173814, lng: 19.4844249 },
    { lat: 49.5173671, lng: 19.484484 },
    { lat: 49.5172981, lng: 19.484577 },
    { lat: 49.5172714, lng: 19.4846129 },
    { lat: 49.5171026, lng: 19.484565 },
    { lat: 49.5170536, lng: 19.4845529 },
    { lat: 49.5170111, lng: 19.4845379 },
    { lat: 49.5169657, lng: 19.484525 },
    { lat: 49.5168641, lng: 19.4844978 },
    { lat: 49.5168481, lng: 19.4844934 },
    { lat: 49.5167683, lng: 19.4844715 },
    { lat: 49.5167102, lng: 19.4844556 },
    { lat: 49.5166357, lng: 19.4844785 },
    { lat: 49.5165338, lng: 19.4845181 },
    { lat: 49.5164682, lng: 19.4845385 },
    { lat: 49.5164735, lng: 19.484729 },
    { lat: 49.5164424, lng: 19.4848383 },
    { lat: 49.5163302, lng: 19.4848481 },
    { lat: 49.5162704, lng: 19.4849971 },
    { lat: 49.5162171, lng: 19.4851326 },
    { lat: 49.5161976, lng: 19.4851764 },
    { lat: 49.5161288, lng: 19.485298 },
    { lat: 49.5160827, lng: 19.4853273 },
    { lat: 49.5160064, lng: 19.4853837 },
    { lat: 49.515953, lng: 19.4854202 },
    { lat: 49.5158768, lng: 19.4854767 },
    { lat: 49.5158352, lng: 19.4855076 },
    { lat: 49.5157956, lng: 19.4855757 },
    { lat: 49.5157679, lng: 19.4856098 },
    { lat: 49.515714, lng: 19.4856921 },
    { lat: 49.5157017, lng: 19.4857177 },
    { lat: 49.5156359, lng: 19.4858543 },
    { lat: 49.5155207, lng: 19.4858753 },
    { lat: 49.5154783, lng: 19.4858821 },
    { lat: 49.5154058, lng: 19.4859497 },
    { lat: 49.5153752, lng: 19.4859725 },
    { lat: 49.5153427, lng: 19.4860464 },
    { lat: 49.5153035, lng: 19.4861296 },
    { lat: 49.5152898, lng: 19.4861485 },
    { lat: 49.5152621, lng: 19.4861848 },
    { lat: 49.5152332, lng: 19.486218 },
    { lat: 49.5151823, lng: 19.486209 },
    { lat: 49.5151649, lng: 19.4862022 },
    { lat: 49.5150744, lng: 19.4861695 },
    { lat: 49.5150448, lng: 19.4861607 },
    { lat: 49.5149796, lng: 19.4861405 },
    { lat: 49.514911, lng: 19.4861149 },
    { lat: 49.5148147, lng: 19.4860809 },
    { lat: 49.5147586, lng: 19.4860605 },
    { lat: 49.5146968, lng: 19.486041 },
    { lat: 49.5146648, lng: 19.4860292 },
    { lat: 49.5146472, lng: 19.4860155 },
    { lat: 49.5146208, lng: 19.4859796 },
    { lat: 49.5145546, lng: 19.4860868 },
    { lat: 49.5144392, lng: 19.486295 },
    { lat: 49.5143279, lng: 19.4863247 },
    { lat: 49.5137667, lng: 19.4867693 },
    { lat: 49.5136679, lng: 19.4868499 },
    { lat: 49.5135455, lng: 19.4869348 },
    { lat: 49.5134107, lng: 19.4871643 },
    { lat: 49.5133795, lng: 19.4871988 },
    { lat: 49.5133143, lng: 19.4872762 },
    { lat: 49.5129063, lng: 19.487643 },
    { lat: 49.5128342, lng: 19.4877189 },
    { lat: 49.5127266, lng: 19.4878556 },
    { lat: 49.5123798, lng: 19.4881567 },
    { lat: 49.5121727, lng: 19.488357 },
    { lat: 49.5119253, lng: 19.4885652 },
    { lat: 49.5115415, lng: 19.4889211 },
    { lat: 49.5114726, lng: 19.488968 },
    { lat: 49.5112513, lng: 19.4891788 },
    { lat: 49.5111043, lng: 19.4893418 },
    { lat: 49.5109925, lng: 19.4894329 },
    { lat: 49.5104425, lng: 19.4897754 },
    { lat: 49.5102373, lng: 19.4899207 },
    { lat: 49.5098613, lng: 19.4901499 },
    { lat: 49.5096173, lng: 19.4902823 },
    { lat: 49.5092706, lng: 19.4904881 },
    { lat: 49.509161, lng: 19.490559 },
    { lat: 49.508923, lng: 19.4907255 },
    { lat: 49.5086892, lng: 19.4909087 },
    { lat: 49.5085833, lng: 19.4909839 },
    { lat: 49.5083073, lng: 19.4911559 },
    { lat: 49.5081495, lng: 19.4912305 },
    { lat: 49.5078037, lng: 19.4915025 },
    { lat: 49.507616, lng: 19.4915908 },
    { lat: 49.5075044, lng: 19.4915611 },
    { lat: 49.5073722, lng: 19.4916543 },
    { lat: 49.5071934, lng: 19.4917348 },
    { lat: 49.5068826, lng: 19.4918892 },
    { lat: 49.5067687, lng: 19.4919587 },
    { lat: 49.5063356, lng: 19.4921962 },
    { lat: 49.5060413, lng: 19.4923376 },
    { lat: 49.505835, lng: 19.4924851 },
    { lat: 49.5055886, lng: 19.492641 },
    { lat: 49.5055793, lng: 19.4926597 },
    { lat: 49.5054171, lng: 19.4927326 },
    { lat: 49.5052166, lng: 19.492881 },
    { lat: 49.5051276, lng: 19.4929079 },
    { lat: 49.5049353, lng: 19.4930152 },
    { lat: 49.5048279, lng: 19.493055 },
    { lat: 49.5047284, lng: 19.4930743 },
    { lat: 49.504492, lng: 19.4931762 },
    { lat: 49.5040611, lng: 19.4933413 },
    { lat: 49.5037992, lng: 19.4934023 },
    { lat: 49.5035978, lng: 19.493479 },
    { lat: 49.5033503, lng: 19.4935668 },
    { lat: 49.5029865, lng: 19.4937642 },
    { lat: 49.50251, lng: 19.4940172 },
    { lat: 49.5022294, lng: 19.4940386 },
    { lat: 49.5019172, lng: 19.494083 },
    { lat: 49.5016608, lng: 19.4941452 },
    { lat: 49.5015976, lng: 19.4942162 },
    { lat: 49.5014971, lng: 19.4942378 },
    { lat: 49.5012468, lng: 19.4943045 },
    { lat: 49.5009848, lng: 19.4943939 },
    { lat: 49.5008715, lng: 19.4944259 },
    { lat: 49.5006526, lng: 19.4945436 },
    { lat: 49.5003368, lng: 19.4947315 },
    { lat: 49.5002222, lng: 19.4948189 },
    { lat: 49.5001145, lng: 19.4948739 },
    { lat: 49.4998077, lng: 19.495017 },
    { lat: 49.4996912, lng: 19.4951038 },
    { lat: 49.4995932, lng: 19.4951538 },
    { lat: 49.4994573, lng: 19.4952383 },
    { lat: 49.499288, lng: 19.495305 },
    { lat: 49.4989256, lng: 19.4955392 },
    { lat: 49.4987449, lng: 19.4956775 },
    { lat: 49.4985536, lng: 19.4958056 },
    { lat: 49.4983771, lng: 19.495963 },
    { lat: 49.4983571, lng: 19.4959956 },
    { lat: 49.4983064, lng: 19.4960188 },
    { lat: 49.4981301, lng: 19.4962795 },
    { lat: 49.4979212, lng: 19.4965193 },
    { lat: 49.4978669, lng: 19.4965139 },
    { lat: 49.4977505, lng: 19.4964782 },
    { lat: 49.497474, lng: 19.496569 },
    { lat: 49.4971759, lng: 19.4967025 },
    { lat: 49.497107, lng: 19.4967229 },
    { lat: 49.4965818, lng: 19.4970409 },
    { lat: 49.4961176, lng: 19.4973117 },
    { lat: 49.4957555, lng: 19.4975259 },
    { lat: 49.4952826, lng: 19.4978313 },
    { lat: 49.4944816, lng: 19.498326 },
    { lat: 49.4944055, lng: 19.4983849 },
    { lat: 49.4943274, lng: 19.4984284 },
    { lat: 49.4942004, lng: 19.4985319 },
    { lat: 49.4940556, lng: 19.4985977 },
    { lat: 49.494092, lng: 19.4987044 },
    { lat: 49.4940266, lng: 19.498827 },
    { lat: 49.4937785, lng: 19.4989946 },
    { lat: 49.4936229, lng: 19.4991388 },
    { lat: 49.4933597, lng: 19.4993475 },
    { lat: 49.4931565, lng: 19.4996349 },
    { lat: 49.493087, lng: 19.4997431 },
    { lat: 49.4929062, lng: 19.5000046 },
    { lat: 49.4928443, lng: 19.5001058 },
    { lat: 49.492716, lng: 19.5002819 },
    { lat: 49.4927091, lng: 19.5003062 },
    { lat: 49.4924947, lng: 19.5008924 },
    { lat: 49.4922825, lng: 19.5011061 },
    { lat: 49.4920591, lng: 19.5013239 },
    { lat: 49.4919594, lng: 19.5015346 },
    { lat: 49.4918786, lng: 19.5016176 },
    { lat: 49.4918385, lng: 19.5016748 },
    { lat: 49.4916083, lng: 19.5019654 },
    { lat: 49.491486, lng: 19.5017259 },
    { lat: 49.4914, lng: 19.501746 },
    { lat: 49.4911893, lng: 19.5019372 },
    { lat: 49.4910214, lng: 19.5020895 },
    { lat: 49.4909221, lng: 19.5020602 },
    { lat: 49.4908157, lng: 19.5022238 },
    { lat: 49.49061, lng: 19.5025557 },
    { lat: 49.490526, lng: 19.5026194 },
    { lat: 49.4904344, lng: 19.5028115 },
    { lat: 49.4903548, lng: 19.5030378 },
    { lat: 49.4902955, lng: 19.503246 },
    { lat: 49.4902634, lng: 19.5034582 },
    { lat: 49.4901889, lng: 19.5035807 },
    { lat: 49.4900861, lng: 19.5037715 },
    { lat: 49.4900917, lng: 19.5039474 },
    { lat: 49.4900802, lng: 19.5042143 },
    { lat: 49.4900996, lng: 19.5046141 },
    { lat: 49.4901018, lng: 19.5048603 },
    { lat: 49.4901408, lng: 19.5050758 },
    { lat: 49.4901383, lng: 19.5050981 },
    { lat: 49.4900466, lng: 19.5053605 },
    { lat: 49.4900105, lng: 19.5055333 },
    { lat: 49.489976, lng: 19.5058911 },
    { lat: 49.4898963, lng: 19.5064403 },
    { lat: 49.4900587, lng: 19.506964 },
    { lat: 49.4899997, lng: 19.5072824 },
    { lat: 49.489845, lng: 19.5074743 },
    { lat: 49.489899, lng: 19.5079143 },
    { lat: 49.4898975, lng: 19.5085523 },
    { lat: 49.4898332, lng: 19.5090249 },
    { lat: 49.4897851, lng: 19.5091576 },
    { lat: 49.4896132, lng: 19.5097123 },
    { lat: 49.4894956, lng: 19.5101286 },
    { lat: 49.4894107, lng: 19.5101928 },
    { lat: 49.4892762, lng: 19.5104554 },
    { lat: 49.4893484, lng: 19.510705 },
    { lat: 49.4893104, lng: 19.5108866 },
    { lat: 49.4892972, lng: 19.5110442 },
    { lat: 49.4892716, lng: 19.5114467 },
    { lat: 49.4892395, lng: 19.5114831 },
    { lat: 49.4891428, lng: 19.511663 },
    { lat: 49.4890569, lng: 19.5119288 },
    { lat: 49.4889479, lng: 19.5120089 },
    { lat: 49.4889063, lng: 19.5121123 },
    { lat: 49.4888319, lng: 19.5121837 },
    { lat: 49.4886785, lng: 19.5123566 },
    { lat: 49.4884228, lng: 19.5126539 },
    { lat: 49.4882043, lng: 19.5129784 },
    { lat: 49.4879746, lng: 19.5131301 },
    { lat: 49.4878848, lng: 19.5134351 },
    { lat: 49.4878405, lng: 19.5135311 },
    { lat: 49.4876654, lng: 19.5138198 },
    { lat: 49.4875467, lng: 19.5139096 },
    { lat: 49.4874745, lng: 19.514104 },
    { lat: 49.4872432, lng: 19.5144693 },
    { lat: 49.4870108, lng: 19.5148101 },
    { lat: 49.4869052, lng: 19.5150896 },
    { lat: 49.4868522, lng: 19.515192 },
    { lat: 49.4867862, lng: 19.5153722 },
    { lat: 49.4867885, lng: 19.515376 },
    { lat: 49.4869938, lng: 19.5156295 },
    { lat: 49.4873153, lng: 19.5159145 },
    { lat: 49.4875244, lng: 19.5160751 },
    { lat: 49.4881823, lng: 19.5165732 },
    { lat: 49.4882665, lng: 19.516665 },
    { lat: 49.4883213, lng: 19.5167812 },
    { lat: 49.4884073, lng: 19.5169402 },
    { lat: 49.4885755, lng: 19.517197 },
    { lat: 49.4886748, lng: 19.5174237 },
    { lat: 49.4889851, lng: 19.5180578 },
    { lat: 49.4891685, lng: 19.5189225 },
    { lat: 49.4891614, lng: 19.5189889 },
    { lat: 49.4891297, lng: 19.5196084 },
    { lat: 49.4893998, lng: 19.5201989 },
    { lat: 49.4894251, lng: 19.5203093 },
    { lat: 49.4889063, lng: 19.523062 },
    { lat: 49.4889302, lng: 19.5231167 },
    { lat: 49.4893031, lng: 19.5246643 },
    { lat: 49.489649, lng: 19.5261583 },
    { lat: 49.4896662, lng: 19.5264398 },
    { lat: 49.489955, lng: 19.5270476 },
    { lat: 49.4900614, lng: 19.527307 },
    { lat: 49.4903348, lng: 19.5279458 },
    { lat: 49.490758, lng: 19.5290815 },
    { lat: 49.4907242, lng: 19.5295305 },
    { lat: 49.4907496, lng: 19.5299732 },
    { lat: 49.4906759, lng: 19.5314101 },
    { lat: 49.4908228, lng: 19.532693 },
    { lat: 49.4907981, lng: 19.5327945 },
    { lat: 49.4907698, lng: 19.5330404 },
    { lat: 49.4908625, lng: 19.5334451 },
    { lat: 49.4909286, lng: 19.5336135 },
    { lat: 49.4913471, lng: 19.5342717 },
    { lat: 49.4913914, lng: 19.5352535 },
    { lat: 49.4914785, lng: 19.5359847 },
    { lat: 49.4914037, lng: 19.5362532 },
    { lat: 49.491338, lng: 19.5371416 },
    { lat: 49.4914331, lng: 19.5388258 },
    { lat: 49.4914369, lng: 19.5396332 },
    { lat: 49.4909729, lng: 19.540914 },
    { lat: 49.4909766, lng: 19.5416192 },
    { lat: 49.4910419, lng: 19.5425459 },
    { lat: 49.4909335, lng: 19.5435928 },
    { lat: 49.4907624, lng: 19.5447196 },
    { lat: 49.4904503, lng: 19.5451909 },
    { lat: 49.4903599, lng: 19.5453128 },
    { lat: 49.4898449, lng: 19.5456563 },
    { lat: 49.4891798, lng: 19.5462259 },
    { lat: 49.4889485, lng: 19.5467926 },
    { lat: 49.4883342, lng: 19.5496159 },
    { lat: 49.4877457, lng: 19.5500045 },
    { lat: 49.4877199, lng: 19.5501594 },
    { lat: 49.4877541, lng: 19.5503435 },
    { lat: 49.4877936, lng: 19.5505963 },
    { lat: 49.4878432, lng: 19.5508965 },
    { lat: 49.4878804, lng: 19.5511187 },
    { lat: 49.4878911, lng: 19.5511835 },
    { lat: 49.4880238, lng: 19.5512789 },
    { lat: 49.4880656, lng: 19.5513056 },
    { lat: 49.4881841, lng: 19.5514641 },
    { lat: 49.4882046, lng: 19.5514949 },
    { lat: 49.4883111, lng: 19.5516311 },
    { lat: 49.4884104, lng: 19.5517612 },
    { lat: 49.4884773, lng: 19.5518462 },
    { lat: 49.4886124, lng: 19.5520163 },
    { lat: 49.4887998, lng: 19.5522512 },
    { lat: 49.4888919, lng: 19.5523734 },
    { lat: 49.4890064, lng: 19.5525149 },
    { lat: 49.4891225, lng: 19.5526649 },
    { lat: 49.4892456, lng: 19.5528472 },
    { lat: 49.4893639, lng: 19.5530191 },
    { lat: 49.4894692, lng: 19.5531308 },
    { lat: 49.4895554, lng: 19.5532214 },
    { lat: 49.4896364, lng: 19.5533211 },
    { lat: 49.4897146, lng: 19.5534071 },
    { lat: 49.4898039, lng: 19.5535118 },
    { lat: 49.4899404, lng: 19.5536443 },
    { lat: 49.4906305, lng: 19.5537927 },
    { lat: 49.4912565, lng: 19.5538117 },
    { lat: 49.4918055, lng: 19.5540567 },
    { lat: 49.4921225, lng: 19.5540927 },
    { lat: 49.4927265, lng: 19.5541207 },
    { lat: 49.4931425, lng: 19.5541257 },
    { lat: 49.4940335, lng: 19.5543287 },
    { lat: 49.4947655, lng: 19.5544667 },
    { lat: 49.4957035, lng: 19.5543997 },
    { lat: 49.4967145, lng: 19.5542927 },
    { lat: 49.4974845, lng: 19.5542887 },
    { lat: 49.4979885, lng: 19.5543347 },
    { lat: 49.4986965, lng: 19.5543877 },
    { lat: 49.4996425, lng: 19.5540727 },
    { lat: 49.5005985, lng: 19.5540467 },
    { lat: 49.5013395, lng: 19.5539077 },
    { lat: 49.5020335, lng: 19.5535777 },
    { lat: 49.5027405, lng: 19.5533687 },
    { lat: 49.5031805, lng: 19.5533707 },
    { lat: 49.5038255, lng: 19.5527017 },
    { lat: 49.5047405, lng: 19.5523577 },
    { lat: 49.5056555, lng: 19.5519327 },
    { lat: 49.5066965, lng: 19.5518437 },
    { lat: 49.5076205, lng: 19.5516137 },
    { lat: 49.5087055, lng: 19.5508877 },
    { lat: 49.5093965, lng: 19.5502817 },
    { lat: 49.5101715, lng: 19.5496287 },
    { lat: 49.5105615, lng: 19.5494517 },
    { lat: 49.5108525, lng: 19.5490897 },
    { lat: 49.5113615, lng: 19.5489067 },
    { lat: 49.5121525, lng: 19.5485937 },
    { lat: 49.5127175, lng: 19.5484567 },
    { lat: 49.5132305, lng: 19.5480877 },
    { lat: 49.5135425, lng: 19.5474377 },
    { lat: 49.5137885, lng: 19.5467987 },
    { lat: 49.5142515, lng: 19.5462197 },
    { lat: 49.5147015, lng: 19.5458047 },
    { lat: 49.5152675, lng: 19.5452957 },
    { lat: 49.5158055, lng: 19.5447477 },
    { lat: 49.5166175, lng: 19.5441037 },
    { lat: 49.5174835, lng: 19.5434627 },
    { lat: 49.5182535, lng: 19.5429227 },
    { lat: 49.5190665, lng: 19.5424377 },
    { lat: 49.5198985, lng: 19.5422467 },
    { lat: 49.5208695, lng: 19.5420577 },
    { lat: 49.5214815, lng: 19.5420017 },
    { lat: 49.5219625, lng: 19.5419537 },
    { lat: 49.5225745, lng: 19.5417967 },
    { lat: 49.5232535, lng: 19.5415187 },
    { lat: 49.5239875, lng: 19.5407527 },
    { lat: 49.524735, lng: 19.5400459 },
    { lat: 49.5248916, lng: 19.5397174 },
    { lat: 49.5251357, lng: 19.5386583 },
    { lat: 49.5254782, lng: 19.5375168 },
    { lat: 49.5257153, lng: 19.537238 },
    { lat: 49.5260743, lng: 19.5361497 },
    { lat: 49.5263114, lng: 19.5350931 },
    { lat: 49.526937, lng: 19.5351106 },
    { lat: 49.5284139, lng: 19.5353008 },
    { lat: 49.529201, lng: 19.5355166 },
    { lat: 49.5298159, lng: 19.5357852 },
    { lat: 49.5304966, lng: 19.5355812 },
    { lat: 49.5310192, lng: 19.5348881 },
    { lat: 49.5316547, lng: 19.5343139 },
    { lat: 49.5322545, lng: 19.5335187 },
    { lat: 49.5328399, lng: 19.5330156 },
    { lat: 49.5333786, lng: 19.532431 },
    { lat: 49.5343659, lng: 19.5317045 },
    { lat: 49.5357702, lng: 19.530449 },
    { lat: 49.536813, lng: 19.5301083 },
    { lat: 49.5384332, lng: 19.5293468 },
    { lat: 49.5387546, lng: 19.5292309 },
    { lat: 49.53909, lng: 19.5292049 },
    { lat: 49.5391583, lng: 19.5291964 },
    { lat: 49.5401967, lng: 19.5291962 },
    { lat: 49.5405635, lng: 19.5291654 },
    { lat: 49.5411093, lng: 19.5291164 },
    { lat: 49.5426626, lng: 19.5292166 },
  ],
  Sihelné: [
    { lat: 49.5096169, lng: 19.3750156 },
    { lat: 49.5094752, lng: 19.3754826 },
    { lat: 49.5094495, lng: 19.3760471 },
    { lat: 49.5092242, lng: 19.3764576 },
    { lat: 49.5089742, lng: 19.3768758 },
    { lat: 49.5086146, lng: 19.3779631 },
    { lat: 49.5083786, lng: 19.3783624 },
    { lat: 49.5082906, lng: 19.3786277 },
    { lat: 49.5083877, lng: 19.3795554 },
    { lat: 49.5080886, lng: 19.3803692 },
    { lat: 49.5076314, lng: 19.3812481 },
    { lat: 49.5070162, lng: 19.3826538 },
    { lat: 49.5065969, lng: 19.3844756 },
    { lat: 49.5062407, lng: 19.3860261 },
    { lat: 49.5058631, lng: 19.3862436 },
    { lat: 49.5042362, lng: 19.3872192 },
    { lat: 49.5022642, lng: 19.3884496 },
    { lat: 49.5014384, lng: 19.3894766 },
    { lat: 49.5009552, lng: 19.3902578 },
    { lat: 49.5006543, lng: 19.3909128 },
    { lat: 49.4996564, lng: 19.3930817 },
    { lat: 49.4994958, lng: 19.3934831 },
    { lat: 49.498728, lng: 19.394449 },
    { lat: 49.497863, lng: 19.3955715 },
    { lat: 49.4975195, lng: 19.3961843 },
    { lat: 49.4971883, lng: 19.3968693 },
    { lat: 49.4968884, lng: 19.3973022 },
    { lat: 49.4967877, lng: 19.3974876 },
    { lat: 49.4965357, lng: 19.3980063 },
    { lat: 49.4960485, lng: 19.3991411 },
    { lat: 49.4958898, lng: 19.3999023 },
    { lat: 49.4958591, lng: 19.4001151 },
    { lat: 49.4957424, lng: 19.4006865 },
    { lat: 49.4955782, lng: 19.4010299 },
    { lat: 49.495269, lng: 19.4016048 },
    { lat: 49.4943773, lng: 19.4031317 },
    { lat: 49.4923345, lng: 19.4066865 },
    { lat: 49.4917819, lng: 19.4084314 },
    { lat: 49.4910685, lng: 19.4098193 },
    { lat: 49.4897603, lng: 19.4109918 },
    { lat: 49.4896206, lng: 19.4111364 },
    { lat: 49.489425, lng: 19.4112918 },
    { lat: 49.4891103, lng: 19.4115033 },
    { lat: 49.4883974, lng: 19.4117563 },
    { lat: 49.4867933, lng: 19.4124476 },
    { lat: 49.4848039, lng: 19.4131646 },
    { lat: 49.4847099, lng: 19.4132031 },
    { lat: 49.4843979, lng: 19.4133041 },
    { lat: 49.4844039, lng: 19.413336 },
    { lat: 49.4843944, lng: 19.4134215 },
    { lat: 49.4846121, lng: 19.4135957 },
    { lat: 49.4848907, lng: 19.4140677 },
    { lat: 49.4851887, lng: 19.4145955 },
    { lat: 49.4852859, lng: 19.414772 },
    { lat: 49.4854489, lng: 19.4150804 },
    { lat: 49.4856657, lng: 19.4155265 },
    { lat: 49.4858684, lng: 19.4159191 },
    { lat: 49.4860682, lng: 19.416366 },
    { lat: 49.4863688, lng: 19.4171889 },
    { lat: 49.4864015, lng: 19.4173351 },
    { lat: 49.4866878, lng: 19.4191965 },
    { lat: 49.486749, lng: 19.4195918 },
    { lat: 49.4870011, lng: 19.4203236 },
    { lat: 49.4871679, lng: 19.4210061 },
    { lat: 49.4872785, lng: 19.4214228 },
    { lat: 49.4872891, lng: 19.4214593 },
    { lat: 49.4876074, lng: 19.4221815 },
    { lat: 49.4881731, lng: 19.4232327 },
    { lat: 49.4882546, lng: 19.4235095 },
    { lat: 49.4882653, lng: 19.4235474 },
    { lat: 49.4884177, lng: 19.4250705 },
    { lat: 49.4884199, lng: 19.4250934 },
    { lat: 49.4886909, lng: 19.4269635 },
    { lat: 49.4889854, lng: 19.4280275 },
    { lat: 49.489078, lng: 19.4283774 },
    { lat: 49.4890796, lng: 19.4283852 },
    { lat: 49.4891981, lng: 19.4288047 },
    { lat: 49.4892008, lng: 19.4288138 },
    { lat: 49.4893191, lng: 19.429229 },
    { lat: 49.4894354, lng: 19.4297238 },
    { lat: 49.489527, lng: 19.4300992 },
    { lat: 49.4898446, lng: 19.4312956 },
    { lat: 49.4900213, lng: 19.4320759 },
    { lat: 49.4903178, lng: 19.4332554 },
    { lat: 49.4905146, lng: 19.4338854 },
    { lat: 49.4906755, lng: 19.4344682 },
    { lat: 49.4908267, lng: 19.4349639 },
    { lat: 49.4908295, lng: 19.4349723 },
    { lat: 49.4909163, lng: 19.4352427 },
    { lat: 49.4911267, lng: 19.4358038 },
    { lat: 49.4912393, lng: 19.4360435 },
    { lat: 49.4915152, lng: 19.4367298 },
    { lat: 49.4915487, lng: 19.4368425 },
    { lat: 49.4915835, lng: 19.4369623 },
    { lat: 49.4916676, lng: 19.437253 },
    { lat: 49.4922157, lng: 19.4384419 },
    { lat: 49.4929056, lng: 19.4394531 },
    { lat: 49.4932867, lng: 19.4399472 },
    { lat: 49.4935304, lng: 19.4403249 },
    { lat: 49.4938794, lng: 19.4407816 },
    { lat: 49.4942643, lng: 19.4413651 },
    { lat: 49.4945801, lng: 19.4419084 },
    { lat: 49.4947814, lng: 19.4422447 },
    { lat: 49.4950607, lng: 19.4426806 },
    { lat: 49.4952753, lng: 19.4429898 },
    { lat: 49.4952904, lng: 19.4429997 },
    { lat: 49.4953403, lng: 19.4430324 },
    { lat: 49.4953775, lng: 19.4430566 },
    { lat: 49.4953997, lng: 19.4430713 },
    { lat: 49.4956576, lng: 19.4432184 },
    { lat: 49.4958021, lng: 19.4433213 },
    { lat: 49.4960233, lng: 19.443479 },
    { lat: 49.4962048, lng: 19.4436011 },
    { lat: 49.4962711, lng: 19.4436461 },
    { lat: 49.4962985, lng: 19.4435797 },
    { lat: 49.496322, lng: 19.4436036 },
    { lat: 49.4965342, lng: 19.4438087 },
    { lat: 49.4965555, lng: 19.4438289 },
    { lat: 49.4965964, lng: 19.4438625 },
    { lat: 49.4966188, lng: 19.4438811 },
    { lat: 49.4967882, lng: 19.4440396 },
    { lat: 49.4973493, lng: 19.4446274 },
    { lat: 49.4977147, lng: 19.445028 },
    { lat: 49.4978569, lng: 19.4451833 },
    { lat: 49.4978997, lng: 19.4451787 },
    { lat: 49.4979119, lng: 19.4451774 },
    { lat: 49.4979307, lng: 19.4451956 },
    { lat: 49.4983466, lng: 19.4456172 },
    { lat: 49.4985719, lng: 19.4458339 },
    { lat: 49.4986059, lng: 19.4458646 },
    { lat: 49.4987847, lng: 19.4460769 },
    { lat: 49.4990548, lng: 19.4464378 },
    { lat: 49.4991131, lng: 19.4465304 },
    { lat: 49.4991758, lng: 19.4466708 },
    { lat: 49.4995899, lng: 19.4463889 },
    { lat: 49.4998916, lng: 19.4461149 },
    { lat: 49.5001048, lng: 19.4458022 },
    { lat: 49.5003649, lng: 19.4455011 },
    { lat: 49.500716, lng: 19.4450223 },
    { lat: 49.5007943, lng: 19.4449638 },
    { lat: 49.5011732, lng: 19.4447996 },
    { lat: 49.5015869, lng: 19.4444865 },
    { lat: 49.5024533, lng: 19.4439194 },
    { lat: 49.5024606, lng: 19.4439298 },
    { lat: 49.5024775, lng: 19.4439573 },
    { lat: 49.5030923, lng: 19.4449034 },
    { lat: 49.5030958, lng: 19.4449091 },
    { lat: 49.5031369, lng: 19.4449663 },
    { lat: 49.5031787, lng: 19.4449205 },
    { lat: 49.5035454, lng: 19.4445136 },
    { lat: 49.5036793, lng: 19.4443646 },
    { lat: 49.5037608, lng: 19.4442738 },
    { lat: 49.5038556, lng: 19.4444713 },
    { lat: 49.5038692, lng: 19.4445021 },
    { lat: 49.5038958, lng: 19.444468 },
    { lat: 49.5041872, lng: 19.4440888 },
    { lat: 49.50421, lng: 19.4440722 },
    { lat: 49.504586, lng: 19.4437923 },
    { lat: 49.5045892, lng: 19.4437897 },
    { lat: 49.5045749, lng: 19.4436524 },
    { lat: 49.5045662, lng: 19.4435606 },
    { lat: 49.5048734, lng: 19.4433044 },
    { lat: 49.5048995, lng: 19.4433592 },
    { lat: 49.5049902, lng: 19.4435448 },
    { lat: 49.5050052, lng: 19.4435736 },
    { lat: 49.5050128, lng: 19.4435423 },
    { lat: 49.5050431, lng: 19.4434172 },
    { lat: 49.5050726, lng: 19.4432984 },
    { lat: 49.505084, lng: 19.4432519 },
    { lat: 49.5052352, lng: 19.4430861 },
    { lat: 49.505308, lng: 19.4430057 },
    { lat: 49.505407, lng: 19.4429283 },
    { lat: 49.5056551, lng: 19.4427332 },
    { lat: 49.5058552, lng: 19.442576 },
    { lat: 49.5059409, lng: 19.4424579 },
    { lat: 49.5060288, lng: 19.4423368 },
    { lat: 49.5062494, lng: 19.4420337 },
    { lat: 49.5062547, lng: 19.442027 },
    { lat: 49.5062761, lng: 19.4418079 },
    { lat: 49.5063001, lng: 19.44157 },
    { lat: 49.506301, lng: 19.4415648 },
    { lat: 49.5065314, lng: 19.4411831 },
    { lat: 49.5065341, lng: 19.4411705 },
    { lat: 49.5065714, lng: 19.4410254 },
    { lat: 49.5065848, lng: 19.4409725 },
    { lat: 49.5066126, lng: 19.4408632 },
    { lat: 49.5066295, lng: 19.4408418 },
    { lat: 49.5066805, lng: 19.440775 },
    { lat: 49.5067251, lng: 19.4407169 },
    { lat: 49.5067591, lng: 19.4406723 },
    { lat: 49.5067772, lng: 19.4406482 },
    { lat: 49.5067763, lng: 19.4406283 },
    { lat: 49.5067585, lng: 19.4402162 },
    { lat: 49.5067551, lng: 19.4401154 },
    { lat: 49.5069895, lng: 19.4396267 },
    { lat: 49.5070669, lng: 19.4396676 },
    { lat: 49.5071188, lng: 19.4396961 },
    { lat: 49.5071291, lng: 19.4397011 },
    { lat: 49.5073475, lng: 19.4388106 },
    { lat: 49.5073742, lng: 19.4384615 },
    { lat: 49.5073945, lng: 19.4381906 },
    { lat: 49.5076768, lng: 19.43823 },
    { lat: 49.5076801, lng: 19.4382304 },
    { lat: 49.5081052, lng: 19.4376967 },
    { lat: 49.5081097, lng: 19.4374257 },
    { lat: 49.5081494, lng: 19.4373851 },
    { lat: 49.5087812, lng: 19.4367369 },
    { lat: 49.5088311, lng: 19.4364063 },
    { lat: 49.5088508, lng: 19.4363915 },
    { lat: 49.5089839, lng: 19.4362933 },
    { lat: 49.5090411, lng: 19.4361914 },
    { lat: 49.50934, lng: 19.4356572 },
    { lat: 49.5096044, lng: 19.4351836 },
    { lat: 49.5096449, lng: 19.4351104 },
    { lat: 49.5097242, lng: 19.4349702 },
    { lat: 49.5097849, lng: 19.4348737 },
    { lat: 49.5098838, lng: 19.4347193 },
    { lat: 49.510221, lng: 19.4343612 },
    { lat: 49.5105379, lng: 19.4338029 },
    { lat: 49.510584, lng: 19.4337529 },
    { lat: 49.5106344, lng: 19.4336463 },
    { lat: 49.510719, lng: 19.4333297 },
    { lat: 49.5108463, lng: 19.4330849 },
    { lat: 49.5108708, lng: 19.4329546 },
    { lat: 49.5110159, lng: 19.4325578 },
    { lat: 49.5113405, lng: 19.4321661 },
    { lat: 49.5114934, lng: 19.43199 },
    { lat: 49.5115605, lng: 19.4319284 },
    { lat: 49.5118429, lng: 19.4315293 },
    { lat: 49.5119422, lng: 19.4313122 },
    { lat: 49.5119892, lng: 19.4312078 },
    { lat: 49.512021, lng: 19.4311619 },
    { lat: 49.5121652, lng: 19.430846 },
    { lat: 49.5124572, lng: 19.4305058 },
    { lat: 49.5124841, lng: 19.4304753 },
    { lat: 49.5125686, lng: 19.4303777 },
    { lat: 49.5126077, lng: 19.4303712 },
    { lat: 49.5126565, lng: 19.4302815 },
    { lat: 49.5126668, lng: 19.430214 },
    { lat: 49.5127167, lng: 19.4301477 },
    { lat: 49.5128563, lng: 19.4300216 },
    { lat: 49.513004, lng: 19.4298503 },
    { lat: 49.5132879, lng: 19.4294823 },
    { lat: 49.5133698, lng: 19.429353 },
    { lat: 49.513467, lng: 19.4292642 },
    { lat: 49.5137909, lng: 19.4291046 },
    { lat: 49.5139388, lng: 19.4287883 },
    { lat: 49.5140197, lng: 19.4287614 },
    { lat: 49.5141663, lng: 19.42852 },
    { lat: 49.5142251, lng: 19.428448 },
    { lat: 49.5143048, lng: 19.4282964 },
    { lat: 49.5144577, lng: 19.4280956 },
    { lat: 49.5146941, lng: 19.4278444 },
    { lat: 49.5147747, lng: 19.4277853 },
    { lat: 49.5152383, lng: 19.4273321 },
    { lat: 49.5156696, lng: 19.4267145 },
    { lat: 49.5161029, lng: 19.4261427 },
    { lat: 49.5162166, lng: 19.4259189 },
    { lat: 49.5163695, lng: 19.4257174 },
    { lat: 49.516363, lng: 19.4256945 },
    { lat: 49.5166258, lng: 19.4254832 },
    { lat: 49.5167445, lng: 19.4253191 },
    { lat: 49.5169436, lng: 19.4252373 },
    { lat: 49.5170507, lng: 19.424801 },
    { lat: 49.5174263, lng: 19.4241941 },
    { lat: 49.5173715, lng: 19.4242048 },
    { lat: 49.5173583, lng: 19.4240405 },
    { lat: 49.5175683, lng: 19.4235604 },
    { lat: 49.5175828, lng: 19.4233108 },
    { lat: 49.5176919, lng: 19.4231118 },
    { lat: 49.5177093, lng: 19.4229518 },
    { lat: 49.5179401, lng: 19.4223867 },
    { lat: 49.5178962, lng: 19.4220688 },
    { lat: 49.5179138, lng: 19.4213742 },
    { lat: 49.5180715, lng: 19.4209596 },
    { lat: 49.51806, lng: 19.4204887 },
    { lat: 49.5179555, lng: 19.4204239 },
    { lat: 49.5180329, lng: 19.4202686 },
    { lat: 49.5180485, lng: 19.4200707 },
    { lat: 49.5181522, lng: 19.4199485 },
    { lat: 49.5185117, lng: 19.419336 },
    { lat: 49.519, lng: 19.4191734 },
    { lat: 49.5191298, lng: 19.4192547 },
    { lat: 49.5200581, lng: 19.419227 },
    { lat: 49.5200621, lng: 19.4191667 },
    { lat: 49.5203616, lng: 19.4191893 },
    { lat: 49.5204911, lng: 19.419115 },
    { lat: 49.5206608, lng: 19.4188829 },
    { lat: 49.5207666, lng: 19.4186368 },
    { lat: 49.5207947, lng: 19.4186112 },
    { lat: 49.5209491, lng: 19.4183413 },
    { lat: 49.520978, lng: 19.4182609 },
    { lat: 49.521162, lng: 19.4180008 },
    { lat: 49.5211934, lng: 19.4178977 },
    { lat: 49.5213103, lng: 19.417746 },
    { lat: 49.5213687, lng: 19.4175224 },
    { lat: 49.5216978, lng: 19.4170594 },
    { lat: 49.5218927, lng: 19.4166175 },
    { lat: 49.5221948, lng: 19.4159122 },
    { lat: 49.5223589, lng: 19.415436 },
    { lat: 49.5223935, lng: 19.4153328 },
    { lat: 49.5225323, lng: 19.4148502 },
    { lat: 49.5225543, lng: 19.4142777 },
    { lat: 49.5227252, lng: 19.4138308 },
    { lat: 49.5226926, lng: 19.4126077 },
    { lat: 49.5229928, lng: 19.4124514 },
    { lat: 49.5229901, lng: 19.4123156 },
    { lat: 49.5230921, lng: 19.4117939 },
    { lat: 49.5233264, lng: 19.4109625 },
    { lat: 49.5234243, lng: 19.410423 },
    { lat: 49.5245668, lng: 19.410131 },
    { lat: 49.5252249, lng: 19.4101801 },
    { lat: 49.5252305, lng: 19.4101787 },
    { lat: 49.5255584, lng: 19.4100799 },
    { lat: 49.5256506, lng: 19.4100514 },
    { lat: 49.5257395, lng: 19.41005 },
    { lat: 49.5258678, lng: 19.4100716 },
    { lat: 49.5259609, lng: 19.4100636 },
    { lat: 49.526385, lng: 19.4100755 },
    { lat: 49.5271357, lng: 19.4099803 },
    { lat: 49.5272252, lng: 19.4099705 },
    { lat: 49.5273754, lng: 19.4099045 },
    { lat: 49.5274598, lng: 19.4097792 },
    { lat: 49.5277476, lng: 19.4093492 },
    { lat: 49.5279904, lng: 19.4091925 },
    { lat: 49.5280011, lng: 19.4091794 },
    { lat: 49.5282051, lng: 19.408912 },
    { lat: 49.5283648, lng: 19.4087564 },
    { lat: 49.5286298, lng: 19.4085947 },
    { lat: 49.5288195, lng: 19.4083557 },
    { lat: 49.5288536, lng: 19.4082671 },
    { lat: 49.5291895, lng: 19.4079269 },
    { lat: 49.5293079, lng: 19.4078788 },
    { lat: 49.5294316, lng: 19.4077561 },
    { lat: 49.5294913, lng: 19.407729 },
    { lat: 49.5295784, lng: 19.4076883 },
    { lat: 49.5301649, lng: 19.4074578 },
    { lat: 49.53025, lng: 19.4074449 },
    { lat: 49.5309354, lng: 19.4066923 },
    { lat: 49.5310573, lng: 19.4064348 },
    { lat: 49.5313605, lng: 19.4059955 },
    { lat: 49.5316827, lng: 19.4059466 },
    { lat: 49.5318916, lng: 19.4058796 },
    { lat: 49.5324799, lng: 19.4054944 },
    { lat: 49.5326731, lng: 19.4054095 },
    { lat: 49.5330954, lng: 19.4053046 },
    { lat: 49.5333468, lng: 19.4051873 },
    { lat: 49.5336308, lng: 19.4050946 },
    { lat: 49.5337657, lng: 19.405061 },
    { lat: 49.5338381, lng: 19.4050691 },
    { lat: 49.5341108, lng: 19.4051656 },
    { lat: 49.5341678, lng: 19.4051311 },
    { lat: 49.5343436, lng: 19.4049345 },
    { lat: 49.5348008, lng: 19.4046616 },
    { lat: 49.5350103, lng: 19.4046833 },
    { lat: 49.5350352, lng: 19.4046857 },
    { lat: 49.535184, lng: 19.4047638 },
    { lat: 49.535413, lng: 19.4047947 },
    { lat: 49.5358244, lng: 19.4048935 },
    { lat: 49.5361657, lng: 19.40528 },
    { lat: 49.5361779, lng: 19.4052779 },
    { lat: 49.5365195, lng: 19.4052098 },
    { lat: 49.5367198, lng: 19.4052529 },
    { lat: 49.5368906, lng: 19.4051694 },
    { lat: 49.5370153, lng: 19.4053078 },
    { lat: 49.5371066, lng: 19.4053062 },
    { lat: 49.5371764, lng: 19.4053287 },
    { lat: 49.5373353, lng: 19.4052587 },
    { lat: 49.5374276, lng: 19.4050121 },
    { lat: 49.5375094, lng: 19.4049785 },
    { lat: 49.5375893, lng: 19.4050279 },
    { lat: 49.5376865, lng: 19.4050314 },
    { lat: 49.5380228, lng: 19.4048231 },
    { lat: 49.5380979, lng: 19.4048396 },
    { lat: 49.5381833, lng: 19.4049087 },
    { lat: 49.5382153, lng: 19.4051098 },
    { lat: 49.5382166, lng: 19.405118 },
    { lat: 49.5382464, lng: 19.405151 },
    { lat: 49.5386229, lng: 19.403441 },
    { lat: 49.5386869, lng: 19.40315 },
    { lat: 49.538624, lng: 19.4026925 },
    { lat: 49.5384386, lng: 19.4023144 },
    { lat: 49.5382092, lng: 19.4018101 },
    { lat: 49.5381287, lng: 19.4016327 },
    { lat: 49.5380426, lng: 19.4015921 },
    { lat: 49.5376106, lng: 19.4013889 },
    { lat: 49.5372473, lng: 19.4009623 },
    { lat: 49.5372376, lng: 19.4009507 },
    { lat: 49.5372386, lng: 19.4009477 },
    { lat: 49.5373163, lng: 19.4008246 },
    { lat: 49.5374034, lng: 19.400687 },
    { lat: 49.5373897, lng: 19.4006098 },
    { lat: 49.53736, lng: 19.400453 },
    { lat: 49.5370732, lng: 19.400394 },
    { lat: 49.5370039, lng: 19.4003378 },
    { lat: 49.5368498, lng: 19.4002646 },
    { lat: 49.5368222, lng: 19.4000804 },
    { lat: 49.5367916, lng: 19.3998791 },
    { lat: 49.5367672, lng: 19.3997199 },
    { lat: 49.5369363, lng: 19.3993322 },
    { lat: 49.5369649, lng: 19.3992659 },
    { lat: 49.5369548, lng: 19.399242 },
    { lat: 49.5369001, lng: 19.3991108 },
    { lat: 49.5362852, lng: 19.3990884 },
    { lat: 49.5363013, lng: 19.3989513 },
    { lat: 49.5363126, lng: 19.3988546 },
    { lat: 49.5363265, lng: 19.3987414 },
    { lat: 49.5363291, lng: 19.3987255 },
    { lat: 49.5363268, lng: 19.398721 },
    { lat: 49.536288, lng: 19.3986128 },
    { lat: 49.5362582, lng: 19.3985297 },
    { lat: 49.5360069, lng: 19.3986754 },
    { lat: 49.5359644, lng: 19.3986619 },
    { lat: 49.5358468, lng: 19.3986243 },
    { lat: 49.5357265, lng: 19.3984376 },
    { lat: 49.5355008, lng: 19.3980871 },
    { lat: 49.5354544, lng: 19.3980842 },
    { lat: 49.5353528, lng: 19.3980775 },
    { lat: 49.5353147, lng: 19.3981317 },
    { lat: 49.5350259, lng: 19.398538 },
    { lat: 49.5349213, lng: 19.3986859 },
    { lat: 49.5348195, lng: 19.3987064 },
    { lat: 49.5346611, lng: 19.3987375 },
    { lat: 49.5344002, lng: 19.3988247 },
    { lat: 49.5339612, lng: 19.3988063 },
    { lat: 49.5337327, lng: 19.3988396 },
    { lat: 49.5335268, lng: 19.39887 },
    { lat: 49.5334546, lng: 19.3987937 },
    { lat: 49.533361, lng: 19.3986951 },
    { lat: 49.5332662, lng: 19.3985959 },
    { lat: 49.5330666, lng: 19.3983224 },
    { lat: 49.5329909, lng: 19.3982175 },
    { lat: 49.5329446, lng: 19.3977071 },
    { lat: 49.5329542, lng: 19.3976233 },
    { lat: 49.532993, lng: 19.397314 },
    { lat: 49.5329757, lng: 19.3973076 },
    { lat: 49.5329219, lng: 19.3972869 },
    { lat: 49.532414, lng: 19.3970965 },
    { lat: 49.5323681, lng: 19.3970794 },
    { lat: 49.5323482, lng: 19.3970373 },
    { lat: 49.5321594, lng: 19.3967847 },
    { lat: 49.5319864, lng: 19.396648 },
    { lat: 49.5318334, lng: 19.3963821 },
    { lat: 49.5317856, lng: 19.396227 },
    { lat: 49.5316528, lng: 19.3961124 },
    { lat: 49.5315023, lng: 19.3962688 },
    { lat: 49.5314089, lng: 19.3956384 },
    { lat: 49.5312774, lng: 19.3955726 },
    { lat: 49.5311346, lng: 19.3957213 },
    { lat: 49.5310967, lng: 19.3953406 },
    { lat: 49.5310217, lng: 19.3951065 },
    { lat: 49.5310694, lng: 19.39502 },
    { lat: 49.5310981, lng: 19.3949581 },
    { lat: 49.5311345, lng: 19.3948917 },
    { lat: 49.5308811, lng: 19.394482 },
    { lat: 49.5309449, lng: 19.3942802 },
    { lat: 49.5308762, lng: 19.394254 },
    { lat: 49.530819, lng: 19.3942112 },
    { lat: 49.5307825, lng: 19.3941779 },
    { lat: 49.5306305, lng: 19.39374 },
    { lat: 49.5304676, lng: 19.3936032 },
    { lat: 49.5305815, lng: 19.3931921 },
    { lat: 49.5303588, lng: 19.3928602 },
    { lat: 49.5304688, lng: 19.3923643 },
    { lat: 49.5303749, lng: 19.3921874 },
    { lat: 49.5303832, lng: 19.3920962 },
    { lat: 49.5304161, lng: 19.3919098 },
    { lat: 49.5304393, lng: 19.3913867 },
    { lat: 49.5301355, lng: 19.3910557 },
    { lat: 49.5301265, lng: 19.3902307 },
    { lat: 49.5296642, lng: 19.390439 },
    { lat: 49.5287334, lng: 19.3905133 },
    { lat: 49.5282946, lng: 19.3903799 },
    { lat: 49.5280474, lng: 19.3902723 },
    { lat: 49.5278386, lng: 19.3900964 },
    { lat: 49.5261353, lng: 19.3876957 },
    { lat: 49.5261204, lng: 19.3876154 },
    { lat: 49.5255482, lng: 19.3868691 },
    { lat: 49.525575, lng: 19.3867897 },
    { lat: 49.5255458, lng: 19.3862045 },
    { lat: 49.5254923, lng: 19.3858303 },
    { lat: 49.5255307, lng: 19.3848859 },
    { lat: 49.5254952, lng: 19.384386 },
    { lat: 49.5254918, lng: 19.3838028 },
    { lat: 49.5251713, lng: 19.383745 },
    { lat: 49.5250945, lng: 19.3837139 },
    { lat: 49.5250381, lng: 19.3833276 },
    { lat: 49.5252848, lng: 19.3831276 },
    { lat: 49.5253733, lng: 19.3828527 },
    { lat: 49.5253399, lng: 19.3824483 },
    { lat: 49.5255329, lng: 19.3822625 },
    { lat: 49.5256468, lng: 19.3820455 },
    { lat: 49.5256704, lng: 19.3816269 },
    { lat: 49.5255435, lng: 19.3811783 },
    { lat: 49.5238448, lng: 19.3812621 },
    { lat: 49.5238154, lng: 19.3812591 },
    { lat: 49.5232879, lng: 19.3813038 },
    { lat: 49.5232525, lng: 19.3813139 },
    { lat: 49.5221193, lng: 19.381784 },
    { lat: 49.5214314, lng: 19.382062 },
    { lat: 49.5212858, lng: 19.3820584 },
    { lat: 49.5212548, lng: 19.3820672 },
    { lat: 49.5209585, lng: 19.3821708 },
    { lat: 49.5203655, lng: 19.3824272 },
    { lat: 49.5194899, lng: 19.3829126 },
    { lat: 49.5193668, lng: 19.3829514 },
    { lat: 49.5179934, lng: 19.3831707 },
    { lat: 49.5176529, lng: 19.3828758 },
    { lat: 49.5168653, lng: 19.3828775 },
    { lat: 49.5162558, lng: 19.3827328 },
    { lat: 49.5157751, lng: 19.3830585 },
    { lat: 49.5149358, lng: 19.3831576 },
    { lat: 49.5147688, lng: 19.3832524 },
    { lat: 49.5147188, lng: 19.383264 },
    { lat: 49.5138863, lng: 19.3837062 },
    { lat: 49.5130446, lng: 19.3846998 },
    { lat: 49.5129072, lng: 19.3839975 },
    { lat: 49.5126309, lng: 19.3832391 },
    { lat: 49.511786, lng: 19.3818836 },
    { lat: 49.5111475, lng: 19.3805093 },
    { lat: 49.5106524, lng: 19.3791671 },
    { lat: 49.5102206, lng: 19.3775094 },
    { lat: 49.5101817, lng: 19.3772309 },
    { lat: 49.5101573, lng: 19.3771378 },
    { lat: 49.510131, lng: 19.3770352 },
    { lat: 49.5100831, lng: 19.3768522 },
    { lat: 49.5096865, lng: 19.3751059 },
    { lat: 49.5096169, lng: 19.3750156 },
  ],
  Vasiľov: [
    { lat: 49.3673717, lng: 19.3634287 },
    { lat: 49.3672723, lng: 19.3624586 },
    { lat: 49.3670878, lng: 19.3619828 },
    { lat: 49.3667576, lng: 19.3617633 },
    { lat: 49.3664307, lng: 19.3610391 },
    { lat: 49.3658086, lng: 19.3598798 },
    { lat: 49.365724, lng: 19.3591298 },
    { lat: 49.365799, lng: 19.3589972 },
    { lat: 49.3657737, lng: 19.3576897 },
    { lat: 49.3656708, lng: 19.356933 },
    { lat: 49.3655621, lng: 19.3563454 },
    { lat: 49.3653091, lng: 19.3551782 },
    { lat: 49.3651316, lng: 19.353984 },
    { lat: 49.3650732, lng: 19.3535073 },
    { lat: 49.3648926, lng: 19.3533062 },
    { lat: 49.3645353, lng: 19.3516476 },
    { lat: 49.3641484, lng: 19.3506965 },
    { lat: 49.3638239, lng: 19.3495448 },
    { lat: 49.3634978, lng: 19.3487389 },
    { lat: 49.3632829, lng: 19.3474234 },
    { lat: 49.3630291, lng: 19.3466233 },
    { lat: 49.3629597, lng: 19.3464928 },
    { lat: 49.3627528, lng: 19.3457809 },
    { lat: 49.3627366, lng: 19.3457237 },
    { lat: 49.3626972, lng: 19.34577 },
    { lat: 49.3625437, lng: 19.3459347 },
    { lat: 49.3622413, lng: 19.3459278 },
    { lat: 49.3618227, lng: 19.3462251 },
    { lat: 49.3616476, lng: 19.3466768 },
    { lat: 49.3612859, lng: 19.3469406 },
    { lat: 49.3612446, lng: 19.3469709 },
    { lat: 49.3610861, lng: 19.3470743 },
    { lat: 49.3609056, lng: 19.3470981 },
    { lat: 49.3606812, lng: 19.34748 },
    { lat: 49.3603356, lng: 19.3474109 },
    { lat: 49.3598179, lng: 19.3472332 },
    { lat: 49.3595011, lng: 19.3471602 },
    { lat: 49.3592969, lng: 19.347371 },
    { lat: 49.3585354, lng: 19.347519 },
    { lat: 49.3585209, lng: 19.3475213 },
    { lat: 49.3583159, lng: 19.3476462 },
    { lat: 49.3581654, lng: 19.3479215 },
    { lat: 49.3581692, lng: 19.3479772 },
    { lat: 49.3581708, lng: 19.3480117 },
    { lat: 49.3581939, lng: 19.348409 },
    { lat: 49.3579165, lng: 19.3490305 },
    { lat: 49.3578551, lng: 19.3496211 },
    { lat: 49.3578252, lng: 19.3500159 },
    { lat: 49.3577294, lng: 19.3505716 },
    { lat: 49.357409, lng: 19.3514733 },
    { lat: 49.3569716, lng: 19.3522186 },
    { lat: 49.3567065, lng: 19.3526179 },
    { lat: 49.3564583, lng: 19.3533331 },
    { lat: 49.3560821, lng: 19.3544487 },
    { lat: 49.3558805, lng: 19.3551258 },
    { lat: 49.3557331, lng: 19.355634 },
    { lat: 49.3555274, lng: 19.3559114 },
    { lat: 49.3554801, lng: 19.3560297 },
    { lat: 49.3554203, lng: 19.3561758 },
    { lat: 49.3553467, lng: 19.3563578 },
    { lat: 49.3552244, lng: 19.3567782 },
    { lat: 49.3550003, lng: 19.3572424 },
    { lat: 49.3549071, lng: 19.3578237 },
    { lat: 49.3547987, lng: 19.3582766 },
    { lat: 49.3547912, lng: 19.3583093 },
    { lat: 49.3547572, lng: 19.3584504 },
    { lat: 49.3543315, lng: 19.3588643 },
    { lat: 49.353816, lng: 19.3594804 },
    { lat: 49.3537008, lng: 19.3597395 },
    { lat: 49.3534598, lng: 19.3605138 },
    { lat: 49.353415, lng: 19.3608068 },
    { lat: 49.353382, lng: 19.3610204 },
    { lat: 49.3533636, lng: 19.3613236 },
    { lat: 49.3533401, lng: 19.3616909 },
    { lat: 49.3530545, lng: 19.3621125 },
    { lat: 49.352648, lng: 19.3622612 },
    { lat: 49.3522724, lng: 19.3624241 },
    { lat: 49.3519292, lng: 19.3626996 },
    { lat: 49.3511569, lng: 19.3632685 },
    { lat: 49.3505696, lng: 19.3637019 },
    { lat: 49.3500744, lng: 19.3640232 },
    { lat: 49.3495093, lng: 19.3642307 },
    { lat: 49.3490611, lng: 19.364261 },
    { lat: 49.3486904, lng: 19.3642615 },
    { lat: 49.348283, lng: 19.3646336 },
    { lat: 49.3479807, lng: 19.3648181 },
    { lat: 49.3479232, lng: 19.364867 },
    { lat: 49.3474568, lng: 19.3654718 },
    { lat: 49.3469819, lng: 19.3657544 },
    { lat: 49.3468132, lng: 19.3659738 },
    { lat: 49.3466683, lng: 19.3662745 },
    { lat: 49.3466682, lng: 19.3663229 },
    { lat: 49.3466796, lng: 19.3667348 },
    { lat: 49.346672, lng: 19.3672929 },
    { lat: 49.346744, lng: 19.3674614 },
    { lat: 49.3467737, lng: 19.3675203 },
    { lat: 49.3466473, lng: 19.3681648 },
    { lat: 49.3464942, lng: 19.3686738 },
    { lat: 49.3463206, lng: 19.3693264 },
    { lat: 49.3464156, lng: 19.3696716 },
    { lat: 49.3463149, lng: 19.3700238 },
    { lat: 49.3462149, lng: 19.3702227 },
    { lat: 49.3461964, lng: 19.3702609 },
    { lat: 49.3461028, lng: 19.3706936 },
    { lat: 49.3460266, lng: 19.3714761 },
    { lat: 49.3460859, lng: 19.3717851 },
    { lat: 49.3461709, lng: 19.3721546 },
    { lat: 49.3461397, lng: 19.3724258 },
    { lat: 49.3460727, lng: 19.3729229 },
    { lat: 49.3459763, lng: 19.3732514 },
    { lat: 49.3458805, lng: 19.3739266 },
    { lat: 49.3455953, lng: 19.3745754 },
    { lat: 49.3454527, lng: 19.3752386 },
    { lat: 49.3454122, lng: 19.3753113 },
    { lat: 49.3452978, lng: 19.3755158 },
    { lat: 49.3451626, lng: 19.3757119 },
    { lat: 49.345077, lng: 19.376048 },
    { lat: 49.3449575, lng: 19.3762649 },
    { lat: 49.3450197, lng: 19.3766061 },
    { lat: 49.3447872, lng: 19.3771339 },
    { lat: 49.3444648, lng: 19.3775385 },
    { lat: 49.3444581, lng: 19.3779064 },
    { lat: 49.3445265, lng: 19.3782838 },
    { lat: 49.3445492, lng: 19.3784057 },
    { lat: 49.3445422, lng: 19.3784538 },
    { lat: 49.3444855, lng: 19.3788784 },
    { lat: 49.3445922, lng: 19.3789691 },
    { lat: 49.3446379, lng: 19.3790082 },
    { lat: 49.3447048, lng: 19.3790648 },
    { lat: 49.3447013, lng: 19.3791118 },
    { lat: 49.3446307, lng: 19.3793353 },
    { lat: 49.344959, lng: 19.3791737 },
    { lat: 49.3452555, lng: 19.3792993 },
    { lat: 49.345286, lng: 19.3795185 },
    { lat: 49.3451988, lng: 19.3795563 },
    { lat: 49.3450116, lng: 19.3798922 },
    { lat: 49.3449157, lng: 19.3805196 },
    { lat: 49.3449222, lng: 19.3807755 },
    { lat: 49.3448314, lng: 19.3810525 },
    { lat: 49.3444743, lng: 19.3810806 },
    { lat: 49.3442072, lng: 19.381177 },
    { lat: 49.3439981, lng: 19.381164 },
    { lat: 49.3438018, lng: 19.3812537 },
    { lat: 49.3437784, lng: 19.3812632 },
    { lat: 49.3437137, lng: 19.381297 },
    { lat: 49.3437682, lng: 19.3815256 },
    { lat: 49.3437054, lng: 19.3819914 },
    { lat: 49.3435396, lng: 19.3822299 },
    { lat: 49.3435377, lng: 19.3825991 },
    { lat: 49.3435911, lng: 19.3829485 },
    { lat: 49.3433254, lng: 19.3833631 },
    { lat: 49.3431159, lng: 19.3836559 },
    { lat: 49.3429844, lng: 19.383783 },
    { lat: 49.3428209, lng: 19.3841471 },
    { lat: 49.3427971, lng: 19.38424 },
    { lat: 49.3427895, lng: 19.3842683 },
    { lat: 49.3427476, lng: 19.3843889 },
    { lat: 49.3426677, lng: 19.3845595 },
    { lat: 49.3425436, lng: 19.3851137 },
    { lat: 49.3423006, lng: 19.3858243 },
    { lat: 49.3421449, lng: 19.3859128 },
    { lat: 49.3418201, lng: 19.3860053 },
    { lat: 49.3416924, lng: 19.3862404 },
    { lat: 49.3415585, lng: 19.3864371 },
    { lat: 49.3413806, lng: 19.3867807 },
    { lat: 49.3414305, lng: 19.3869093 },
    { lat: 49.3412804, lng: 19.3871186 },
    { lat: 49.3412845, lng: 19.3872577 },
    { lat: 49.3410286, lng: 19.3876202 },
    { lat: 49.3411381, lng: 19.3877452 },
    { lat: 49.3410282, lng: 19.3879007 },
    { lat: 49.3408667, lng: 19.3882291 },
    { lat: 49.3409212, lng: 19.3883615 },
    { lat: 49.3408741, lng: 19.388582 },
    { lat: 49.340755, lng: 19.3886153 },
    { lat: 49.3407677, lng: 19.3889586 },
    { lat: 49.3409168, lng: 19.3893063 },
    { lat: 49.3410288, lng: 19.3894404 },
    { lat: 49.3409591, lng: 19.3897588 },
    { lat: 49.341229, lng: 19.3903036 },
    { lat: 49.3412661, lng: 19.3903035 },
    { lat: 49.3412892, lng: 19.3908955 },
    { lat: 49.3412137, lng: 19.3914515 },
    { lat: 49.3410702, lng: 19.3918535 },
    { lat: 49.3410696, lng: 19.3920588 },
    { lat: 49.3407829, lng: 19.3922212 },
    { lat: 49.3409494, lng: 19.392481 },
    { lat: 49.3406582, lng: 19.39286 },
    { lat: 49.340428, lng: 19.3931203 },
    { lat: 49.3402148, lng: 19.3935005 },
    { lat: 49.3401319, lng: 19.3938507 },
    { lat: 49.3399083, lng: 19.3939823 },
    { lat: 49.3396373, lng: 19.3938781 },
    { lat: 49.3394013, lng: 19.3942316 },
    { lat: 49.3393052, lng: 19.393932 },
    { lat: 49.3390034, lng: 19.3940574 },
    { lat: 49.3387517, lng: 19.3941433 },
    { lat: 49.3382716, lng: 19.3946263 },
    { lat: 49.3383625, lng: 19.3947877 },
    { lat: 49.338217, lng: 19.3949293 },
    { lat: 49.3379958, lng: 19.3948493 },
    { lat: 49.3378039, lng: 19.394916 },
    { lat: 49.3377296, lng: 19.3946029 },
    { lat: 49.3374624, lng: 19.3948452 },
    { lat: 49.3375575, lng: 19.3951673 },
    { lat: 49.3372989, lng: 19.3954917 },
    { lat: 49.3370734, lng: 19.3952749 },
    { lat: 49.3368699, lng: 19.3956255 },
    { lat: 49.3364575, lng: 19.3959121 },
    { lat: 49.3360346, lng: 19.3961001 },
    { lat: 49.3360067, lng: 19.3963481 },
    { lat: 49.3361808, lng: 19.3966757 },
    { lat: 49.3358569, lng: 19.3967619 },
    { lat: 49.3355403, lng: 19.3970543 },
    { lat: 49.3353579, lng: 19.3972751 },
    { lat: 49.3351286, lng: 19.3973347 },
    { lat: 49.3349635, lng: 19.397787 },
    { lat: 49.3342994, lng: 19.3979918 },
    { lat: 49.3338688, lng: 19.3979425 },
    { lat: 49.3335585, lng: 19.3980297 },
    { lat: 49.3334521, lng: 19.3986003 },
    { lat: 49.3331842, lng: 19.3991157 },
    { lat: 49.3329656, lng: 19.3996265 },
    { lat: 49.3330661, lng: 19.4003364 },
    { lat: 49.3325832, lng: 19.4009562 },
    { lat: 49.3324559, lng: 19.4012706 },
    { lat: 49.3323874, lng: 19.4018597 },
    { lat: 49.3321433, lng: 19.4025229 },
    { lat: 49.3320643, lng: 19.4029563 },
    { lat: 49.3320186, lng: 19.4032348 },
    { lat: 49.3318753, lng: 19.4035438 },
    { lat: 49.331938, lng: 19.4037043 },
    { lat: 49.3320274, lng: 19.4039333 },
    { lat: 49.331918, lng: 19.4045352 },
    { lat: 49.3317686, lng: 19.4050833 },
    { lat: 49.3317947, lng: 19.4054778 },
    { lat: 49.3315231, lng: 19.4060558 },
    { lat: 49.331508, lng: 19.4065094 },
    { lat: 49.3313882, lng: 19.4070376 },
    { lat: 49.3314608, lng: 19.4076042 },
    { lat: 49.3314449, lng: 19.4082591 },
    { lat: 49.3315107, lng: 19.4086342 },
    { lat: 49.3315397, lng: 19.4087976 },
    { lat: 49.3315713, lng: 19.4095493 },
    { lat: 49.3315563, lng: 19.4100315 },
    { lat: 49.3313934, lng: 19.4107443 },
    { lat: 49.3312064, lng: 19.4114268 },
    { lat: 49.3309348, lng: 19.4119863 },
    { lat: 49.330651, lng: 19.412766 },
    { lat: 49.3307748, lng: 19.4128151 },
    { lat: 49.3303967, lng: 19.4135306 },
    { lat: 49.330023, lng: 19.4140982 },
    { lat: 49.3298456, lng: 19.4144748 },
    { lat: 49.3296084, lng: 19.4150214 },
    { lat: 49.3292436, lng: 19.4154663 },
    { lat: 49.3289718, lng: 19.416046 },
    { lat: 49.3291333, lng: 19.417026 },
    { lat: 49.3294889, lng: 19.4175521 },
    { lat: 49.3297466, lng: 19.4180589 },
    { lat: 49.3299061, lng: 19.4185351 },
    { lat: 49.3297858, lng: 19.4191699 },
    { lat: 49.3290974, lng: 19.4199753 },
    { lat: 49.3286907, lng: 19.4207578 },
    { lat: 49.3283867, lng: 19.4220786 },
    { lat: 49.328571, lng: 19.4224322 },
    { lat: 49.3291146, lng: 19.4230768 },
    { lat: 49.3297037, lng: 19.4235372 },
    { lat: 49.3301438, lng: 19.4238895 },
    { lat: 49.3305016, lng: 19.4238302 },
    { lat: 49.3309496, lng: 19.4237745 },
    { lat: 49.3314551, lng: 19.4237887 },
    { lat: 49.3315049, lng: 19.4237938 },
    { lat: 49.3317351, lng: 19.4238 },
    { lat: 49.3318108, lng: 19.4238015 },
    { lat: 49.3327715, lng: 19.4233048 },
    { lat: 49.3333986, lng: 19.4225393 },
    { lat: 49.3335052, lng: 19.4224081 },
    { lat: 49.334276, lng: 19.4215316 },
    { lat: 49.3351102, lng: 19.4208584 },
    { lat: 49.3351309, lng: 19.4208421 },
    { lat: 49.3354776, lng: 19.4205665 },
    { lat: 49.3359867, lng: 19.4198577 },
    { lat: 49.3383323, lng: 19.4159559 },
    { lat: 49.3385172, lng: 19.4156655 },
    { lat: 49.3386704, lng: 19.4153942 },
    { lat: 49.3390764, lng: 19.4144529 },
    { lat: 49.3390784, lng: 19.4144495 },
    { lat: 49.3393349, lng: 19.414073 },
    { lat: 49.3400923, lng: 19.4131033 },
    { lat: 49.3406455, lng: 19.4123741 },
    { lat: 49.3414922, lng: 19.4115587 },
    { lat: 49.3422668, lng: 19.4109601 },
    { lat: 49.3433555, lng: 19.4101831 },
    { lat: 49.344148, lng: 19.4097581 },
    { lat: 49.3443079, lng: 19.4099724 },
    { lat: 49.3442196, lng: 19.4105692 },
    { lat: 49.3442622, lng: 19.4106177 },
    { lat: 49.3443256, lng: 19.4105032 },
    { lat: 49.3443907, lng: 19.4103736 },
    { lat: 49.3444898, lng: 19.4101775 },
    { lat: 49.3446458, lng: 19.4096766 },
    { lat: 49.3448366, lng: 19.4090752 },
    { lat: 49.3450029, lng: 19.4086545 },
    { lat: 49.3451156, lng: 19.4084124 },
    { lat: 49.3451876, lng: 19.4082644 },
    { lat: 49.3452195, lng: 19.4081984 },
    { lat: 49.345593, lng: 19.4074332 },
    { lat: 49.3456403, lng: 19.4073323 },
    { lat: 49.3458979, lng: 19.4072773 },
    { lat: 49.3464233, lng: 19.4073788 },
    { lat: 49.3465288, lng: 19.4073475 },
    { lat: 49.3470547, lng: 19.4068032 },
    { lat: 49.3473448, lng: 19.4067927 },
    { lat: 49.3477264, lng: 19.4069554 },
    { lat: 49.3480932, lng: 19.4072072 },
    { lat: 49.3483657, lng: 19.4071812 },
    { lat: 49.3491233, lng: 19.4070928 },
    { lat: 49.3494792, lng: 19.4066768 },
    { lat: 49.3497329, lng: 19.4062864 },
    { lat: 49.3500292, lng: 19.4060467 },
    { lat: 49.3500299, lng: 19.4053541 },
    { lat: 49.3499306, lng: 19.4050097 },
    { lat: 49.3500611, lng: 19.4043778 },
    { lat: 49.3501849, lng: 19.4038645 },
    { lat: 49.350365, lng: 19.4034466 },
    { lat: 49.3503078, lng: 19.4030632 },
    { lat: 49.3504102, lng: 19.40267 },
    { lat: 49.3503986, lng: 19.4021295 },
    { lat: 49.3504791, lng: 19.4019209 },
    { lat: 49.3504162, lng: 19.4017109 },
    { lat: 49.3506374, lng: 19.4011787 },
    { lat: 49.3506555, lng: 19.4007206 },
    { lat: 49.3509509, lng: 19.4002421 },
    { lat: 49.3511939, lng: 19.3998199 },
    { lat: 49.3514073, lng: 19.399754 },
    { lat: 49.3514525, lng: 19.3994198 },
    { lat: 49.3516315, lng: 19.3991972 },
    { lat: 49.3516552, lng: 19.3986384 },
    { lat: 49.3518287, lng: 19.3985913 },
    { lat: 49.3520091, lng: 19.3984437 },
    { lat: 49.3522862, lng: 19.3982997 },
    { lat: 49.352512, lng: 19.3980879 },
    { lat: 49.3527021, lng: 19.3981294 },
    { lat: 49.3526425, lng: 19.397868 },
    { lat: 49.352839, lng: 19.3977046 },
    { lat: 49.3531006, lng: 19.3976143 },
    { lat: 49.3531832, lng: 19.3973277 },
    { lat: 49.3533919, lng: 19.3972161 },
    { lat: 49.3535706, lng: 19.3972303 },
    { lat: 49.3537624, lng: 19.3969676 },
    { lat: 49.353749, lng: 19.3967485 },
    { lat: 49.3538584, lng: 19.3966553 },
    { lat: 49.3539485, lng: 19.3963871 },
    { lat: 49.3539757, lng: 19.3962934 },
    { lat: 49.3540066, lng: 19.3961845 },
    { lat: 49.3540279, lng: 19.3961098 },
    { lat: 49.3540327, lng: 19.3960399 },
    { lat: 49.3540371, lng: 19.3959679 },
    { lat: 49.3539894, lng: 19.3954945 },
    { lat: 49.3539505, lng: 19.3950241 },
    { lat: 49.3540904, lng: 19.3948824 },
    { lat: 49.354124, lng: 19.3944168 },
    { lat: 49.354324, lng: 19.3942844 },
    { lat: 49.3544683, lng: 19.394093 },
    { lat: 49.354483, lng: 19.394044 },
    { lat: 49.354579, lng: 19.3937321 },
    { lat: 49.3548498, lng: 19.3937149 },
    { lat: 49.3549233, lng: 19.3934767 },
    { lat: 49.3551122, lng: 19.3933964 },
    { lat: 49.3553341, lng: 19.3935682 },
    { lat: 49.3555777, lng: 19.3936172 },
    { lat: 49.3557745, lng: 19.3936601 },
    { lat: 49.3559925, lng: 19.3941797 },
    { lat: 49.3563294, lng: 19.3944943 },
    { lat: 49.3563617, lng: 19.3943661 },
    { lat: 49.3565615, lng: 19.3935737 },
    { lat: 49.3567118, lng: 19.3931691 },
    { lat: 49.3569061, lng: 19.392743 },
    { lat: 49.3576728, lng: 19.3912999 },
    { lat: 49.3583028, lng: 19.3897709 },
    { lat: 49.3587367, lng: 19.3881492 },
    { lat: 49.3599019, lng: 19.3862906 },
    { lat: 49.3608982, lng: 19.3850355 },
    { lat: 49.3623419, lng: 19.3825194 },
    { lat: 49.3623568, lng: 19.382504 },
    { lat: 49.3631837, lng: 19.3816274 },
    { lat: 49.3624311, lng: 19.3805921 },
    { lat: 49.3615332, lng: 19.3789715 },
    { lat: 49.362086, lng: 19.3781147 },
    { lat: 49.3625588, lng: 19.377383 },
    { lat: 49.3629361, lng: 19.3767987 },
    { lat: 49.3638546, lng: 19.3752287 },
    { lat: 49.3644791, lng: 19.3743599 },
    { lat: 49.3644933, lng: 19.375708 },
    { lat: 49.3644996, lng: 19.376831 },
    { lat: 49.3646609, lng: 19.3780228 },
    { lat: 49.3648217, lng: 19.3785949 },
    { lat: 49.3648684, lng: 19.3793078 },
    { lat: 49.3650146, lng: 19.3802427 },
    { lat: 49.3652438, lng: 19.3799165 },
    { lat: 49.365272, lng: 19.3798419 },
    { lat: 49.3656479, lng: 19.3792802 },
    { lat: 49.36617, lng: 19.3785355 },
    { lat: 49.3667296, lng: 19.3776579 },
    { lat: 49.3671838, lng: 19.377028 },
    { lat: 49.3673906, lng: 19.3752286 },
    { lat: 49.3671404, lng: 19.3740205 },
    { lat: 49.3672968, lng: 19.3731201 },
    { lat: 49.367753, lng: 19.3725593 },
    { lat: 49.367697, lng: 19.3722274 },
    { lat: 49.3681482, lng: 19.3715637 },
    { lat: 49.368841, lng: 19.3709564 },
    { lat: 49.3686817, lng: 19.3703664 },
    { lat: 49.3685229, lng: 19.3696454 },
    { lat: 49.3682858, lng: 19.368912 },
    { lat: 49.3682029, lng: 19.368293 },
    { lat: 49.3678301, lng: 19.367354 },
    { lat: 49.3678222, lng: 19.3665127 },
    { lat: 49.3677159, lng: 19.3657788 },
    { lat: 49.3674842, lng: 19.3648724 },
    { lat: 49.3673717, lng: 19.3634287 },
  ],
  Vavrečka: [
    { lat: 49.358879, lng: 19.482154 },
    { lat: 49.3594, lng: 19.483486 },
    { lat: 49.359832, lng: 19.48483 },
    { lat: 49.359937, lng: 19.48516 },
    { lat: 49.36027, lng: 19.485542 },
    { lat: 49.360659, lng: 19.485181 },
    { lat: 49.36166, lng: 19.48518 },
    { lat: 49.362508, lng: 19.485223 },
    { lat: 49.363206, lng: 19.485297 },
    { lat: 49.364696, lng: 19.485835 },
    { lat: 49.365819, lng: 19.487104 },
    { lat: 49.366017, lng: 19.487485 },
    { lat: 49.366071, lng: 19.487583 },
    { lat: 49.366595, lng: 19.488247 },
    { lat: 49.366918, lng: 19.48908 },
    { lat: 49.366937, lng: 19.489293 },
    { lat: 49.367067, lng: 19.489533 },
    { lat: 49.367278, lng: 19.48998 },
    { lat: 49.367485, lng: 19.490345 },
    { lat: 49.367719, lng: 19.490725 },
    { lat: 49.367757, lng: 19.490836 },
    { lat: 49.367783, lng: 19.490915 },
    { lat: 49.367854, lng: 19.491129 },
    { lat: 49.3682, lng: 19.492158 },
    { lat: 49.368394, lng: 19.493249 },
    { lat: 49.368561, lng: 19.494439 },
    { lat: 49.3686956, lng: 19.4956315 },
    { lat: 49.368717, lng: 19.496669 },
    { lat: 49.368743, lng: 19.497112 },
    { lat: 49.368744, lng: 19.497122 },
    { lat: 49.368769, lng: 19.497315 },
    { lat: 49.368809, lng: 19.497467 },
    { lat: 49.368812, lng: 19.497476 },
    { lat: 49.368866, lng: 19.49765 },
    { lat: 49.36887, lng: 19.497663 },
    { lat: 49.368936, lng: 19.497835 },
    { lat: 49.369296, lng: 19.498363 },
    { lat: 49.369549, lng: 19.498666 },
    { lat: 49.370713, lng: 19.49846 },
    { lat: 49.370749, lng: 19.498456 },
    { lat: 49.371648, lng: 19.498691 },
    { lat: 49.373007, lng: 19.498669 },
    { lat: 49.374233, lng: 19.49815 },
    { lat: 49.374255, lng: 19.498144 },
    { lat: 49.374819, lng: 19.498152 },
    { lat: 49.375273, lng: 19.498332 },
    { lat: 49.375372, lng: 19.498336 },
    { lat: 49.375655, lng: 19.498339 },
    { lat: 49.375735, lng: 19.498324 },
    { lat: 49.375822, lng: 19.498315 },
    { lat: 49.375952, lng: 19.498301 },
    { lat: 49.37624, lng: 19.498379 },
    { lat: 49.376271, lng: 19.498377 },
    { lat: 49.37647, lng: 19.498506 },
    { lat: 49.377177, lng: 19.498776 },
    { lat: 49.378643, lng: 19.499744 },
    { lat: 49.378693, lng: 19.499776 },
    { lat: 49.37879, lng: 19.49984 },
    { lat: 49.379205, lng: 19.500155 },
    { lat: 49.379605, lng: 19.500271 },
    { lat: 49.379792, lng: 19.500389 },
    { lat: 49.380109, lng: 19.500604 },
    { lat: 49.380542, lng: 19.501403 },
    { lat: 49.380674, lng: 19.501958 },
    { lat: 49.380786, lng: 19.502214 },
    { lat: 49.381962, lng: 19.503671 },
    { lat: 49.3821561, lng: 19.5025343 },
    { lat: 49.3819647, lng: 19.5018934 },
    { lat: 49.3819296, lng: 19.5010755 },
    { lat: 49.3822981, lng: 19.4997881 },
    { lat: 49.3827077, lng: 19.4991079 },
    { lat: 49.3828589, lng: 19.4987673 },
    { lat: 49.3830739, lng: 19.4985371 },
    { lat: 49.3834685, lng: 19.4979015 },
    { lat: 49.3836519, lng: 19.4975022 },
    { lat: 49.3838044, lng: 19.4973614 },
    { lat: 49.384, lng: 19.4971058 },
    { lat: 49.3841835, lng: 19.4965824 },
    { lat: 49.3843384, lng: 19.4963243 },
    { lat: 49.3844687, lng: 19.4962398 },
    { lat: 49.3846499, lng: 19.4958903 },
    { lat: 49.3848329, lng: 19.4958501 },
    { lat: 49.3850626, lng: 19.4955011 },
    { lat: 49.3853285, lng: 19.4953002 },
    { lat: 49.3854062, lng: 19.4951053 },
    { lat: 49.3855009, lng: 19.4950333 },
    { lat: 49.3855067, lng: 19.4948598 },
    { lat: 49.3856925, lng: 19.4945846 },
    { lat: 49.385837, lng: 19.4941963 },
    { lat: 49.3860341, lng: 19.4938844 },
    { lat: 49.3860429, lng: 19.4938704 },
    { lat: 49.3861091, lng: 19.4934424 },
    { lat: 49.386253, lng: 19.4931648 },
    { lat: 49.3864115, lng: 19.4930581 },
    { lat: 49.386537, lng: 19.4928443 },
    { lat: 49.387007, lng: 19.4927485 },
    { lat: 49.387345, lng: 19.4929492 },
    { lat: 49.3876115, lng: 19.493107 },
    { lat: 49.3879856, lng: 19.4934569 },
    { lat: 49.3888573, lng: 19.4932504 },
    { lat: 49.3894905, lng: 19.4928058 },
    { lat: 49.389925, lng: 19.4922522 },
    { lat: 49.3901682, lng: 19.4920779 },
    { lat: 49.3903556, lng: 19.4916618 },
    { lat: 49.3904181, lng: 19.4913017 },
    { lat: 49.3904825, lng: 19.4908872 },
    { lat: 49.390439, lng: 19.489912 },
    { lat: 49.3904184, lng: 19.4895586 },
    { lat: 49.3905973, lng: 19.4892588 },
    { lat: 49.3907598, lng: 19.4891608 },
    { lat: 49.3910082, lng: 19.4887526 },
    { lat: 49.3911405, lng: 19.4888107 },
    { lat: 49.3913114, lng: 19.4886561 },
    { lat: 49.3915387, lng: 19.4881094 },
    { lat: 49.3920178, lng: 19.4878885 },
    { lat: 49.3925497, lng: 19.4879201 },
    { lat: 49.3929069, lng: 19.4878454 },
    { lat: 49.3932765, lng: 19.4877244 },
    { lat: 49.3935611, lng: 19.4875138 },
    { lat: 49.3939686, lng: 19.4871109 },
    { lat: 49.3942075, lng: 19.487285 },
    { lat: 49.3944547, lng: 19.4872972 },
    { lat: 49.3944633, lng: 19.4869418 },
    { lat: 49.3946506, lng: 19.4866483 },
    { lat: 49.3949961, lng: 19.4865668 },
    { lat: 49.395234, lng: 19.4864469 },
    { lat: 49.395366, lng: 19.4860797 },
    { lat: 49.3956506, lng: 19.4861964 },
    { lat: 49.395848, lng: 19.4862744 },
    { lat: 49.3959678, lng: 19.4861111 },
    { lat: 49.3962981, lng: 19.4864092 },
    { lat: 49.3964503, lng: 19.4863625 },
    { lat: 49.3965073, lng: 19.4863268 },
    { lat: 49.3965938, lng: 19.486273 },
    { lat: 49.3966289, lng: 19.4862511 },
    { lat: 49.3972593, lng: 19.4859405 },
    { lat: 49.3974929, lng: 19.4855548 },
    { lat: 49.3974858, lng: 19.4854962 },
    { lat: 49.3963227, lng: 19.4830959 },
    { lat: 49.3961682, lng: 19.4774201 },
    { lat: 49.3964713, lng: 19.474418 },
    { lat: 49.3965264, lng: 19.4738734 },
    { lat: 49.3965334, lng: 19.4738054 },
    { lat: 49.3965707, lng: 19.4735102 },
    { lat: 49.397448, lng: 19.4667326 },
    { lat: 49.3968489, lng: 19.4638853 },
    { lat: 49.3968069, lng: 19.4636839 },
    { lat: 49.3967666, lng: 19.4634893 },
    { lat: 49.3967635, lng: 19.4634748 },
    { lat: 49.3967609, lng: 19.4634646 },
    { lat: 49.3966798, lng: 19.4631217 },
    { lat: 49.3966783, lng: 19.4631143 },
    { lat: 49.396582, lng: 19.4627072 },
    { lat: 49.3965413, lng: 19.4625354 },
    { lat: 49.3964366, lng: 19.4620952 },
    { lat: 49.3964269, lng: 19.4620539 },
    { lat: 49.3964142, lng: 19.4619981 },
    { lat: 49.3956144, lng: 19.4586133 },
    { lat: 49.395595, lng: 19.45853 },
    { lat: 49.3958717, lng: 19.4580057 },
    { lat: 49.3959145, lng: 19.4577855 },
    { lat: 49.3959165, lng: 19.4577784 },
    { lat: 49.3940083, lng: 19.4570311 },
    { lat: 49.393246, lng: 19.456597 },
    { lat: 49.3926938, lng: 19.4561805 },
    { lat: 49.3921892, lng: 19.4558156 },
    { lat: 49.3914402, lng: 19.4553552 },
    { lat: 49.3910321, lng: 19.4549051 },
    { lat: 49.3904344, lng: 19.4539371 },
    { lat: 49.3895774, lng: 19.4526728 },
    { lat: 49.3894379, lng: 19.4524596 },
    { lat: 49.3892428, lng: 19.4521594 },
    { lat: 49.3892218, lng: 19.4521903 },
    { lat: 49.3890895, lng: 19.4523789 },
    { lat: 49.3889905, lng: 19.4524792 },
    { lat: 49.3891141, lng: 19.452841 },
    { lat: 49.3891562, lng: 19.4528741 },
    { lat: 49.3888713, lng: 19.4531496 },
    { lat: 49.3886575, lng: 19.4532743 },
    { lat: 49.3884582, lng: 19.4535718 },
    { lat: 49.3883418, lng: 19.4536397 },
    { lat: 49.3882186, lng: 19.4538511 },
    { lat: 49.388144, lng: 19.4538979 },
    { lat: 49.3880282, lng: 19.4538547 },
    { lat: 49.3878913, lng: 19.4538654 },
    { lat: 49.3877395, lng: 19.4539686 },
    { lat: 49.3876848, lng: 19.4540787 },
    { lat: 49.387703, lng: 19.4542268 },
    { lat: 49.3876024, lng: 19.4544424 },
    { lat: 49.3874324, lng: 19.4545193 },
    { lat: 49.3872653, lng: 19.4544913 },
    { lat: 49.3871409, lng: 19.4546758 },
    { lat: 49.3870764, lng: 19.4546455 },
    { lat: 49.3869557, lng: 19.4544437 },
    { lat: 49.3868931, lng: 19.4544067 },
    { lat: 49.386825, lng: 19.4544708 },
    { lat: 49.3866769, lng: 19.4546553 },
    { lat: 49.3865424, lng: 19.4547689 },
    { lat: 49.3863462, lng: 19.4550367 },
    { lat: 49.3861472, lng: 19.4552907 },
    { lat: 49.3860165, lng: 19.4553384 },
    { lat: 49.3859308, lng: 19.455386 },
    { lat: 49.3858959, lng: 19.4554632 },
    { lat: 49.385877, lng: 19.4556109 },
    { lat: 49.3857919, lng: 19.4556762 },
    { lat: 49.3856835, lng: 19.4556459 },
    { lat: 49.3855217, lng: 19.4555042 },
    { lat: 49.3854309, lng: 19.4555176 },
    { lat: 49.3853459, lng: 19.4555795 },
    { lat: 49.385264, lng: 19.4558318 },
    { lat: 49.3853573, lng: 19.4560029 },
    { lat: 49.3853128, lng: 19.456114 },
    { lat: 49.3851736, lng: 19.4561196 },
    { lat: 49.3850604, lng: 19.4561586 },
    { lat: 49.3849879, lng: 19.4561537 },
    { lat: 49.3848676, lng: 19.4559859 },
    { lat: 49.3847174, lng: 19.455955 },
    { lat: 49.3846074, lng: 19.4558888 },
    { lat: 49.3845205, lng: 19.4559354 },
    { lat: 49.3844613, lng: 19.456141 },
    { lat: 49.384408, lng: 19.4563544 },
    { lat: 49.3842662, lng: 19.4563089 },
    { lat: 49.3841517, lng: 19.4562948 },
    { lat: 49.3839402, lng: 19.4563516 },
    { lat: 49.383802, lng: 19.4564598 },
    { lat: 49.3835886, lng: 19.4566448 },
    { lat: 49.3835398, lng: 19.4566866 },
    { lat: 49.3832789, lng: 19.4567946 },
    { lat: 49.3831066, lng: 19.4569195 },
    { lat: 49.3829508, lng: 19.4571113 },
    { lat: 49.3827845, lng: 19.4572894 },
    { lat: 49.3826817, lng: 19.4574578 },
    { lat: 49.3825395, lng: 19.4575028 },
    { lat: 49.3822857, lng: 19.4576939 },
    { lat: 49.3821083, lng: 19.4578497 },
    { lat: 49.3819414, lng: 19.4578511 },
    { lat: 49.3817881, lng: 19.4579218 },
    { lat: 49.3815854, lng: 19.4580945 },
    { lat: 49.3814509, lng: 19.4580391 },
    { lat: 49.3813409, lng: 19.4580445 },
    { lat: 49.3812953, lng: 19.4582819 },
    { lat: 49.3812625, lng: 19.4583784 },
    { lat: 49.3810018, lng: 19.4584141 },
    { lat: 49.3808726, lng: 19.4586193 },
    { lat: 49.3807831, lng: 19.4586846 },
    { lat: 49.3806599, lng: 19.4587474 },
    { lat: 49.3805193, lng: 19.4588034 },
    { lat: 49.3804171, lng: 19.4589576 },
    { lat: 49.3803127, lng: 19.459065 },
    { lat: 49.3799927, lng: 19.4593353 },
    { lat: 49.3798762, lng: 19.4594013 },
    { lat: 49.3795168, lng: 19.4596679 },
    { lat: 49.3792251, lng: 19.4598699 },
    { lat: 49.3788648, lng: 19.4599727 },
    { lat: 49.3786946, lng: 19.4599697 },
    { lat: 49.3785578, lng: 19.46023 },
    { lat: 49.3784348, lng: 19.4603022 },
    { lat: 49.3780434, lng: 19.460319 },
    { lat: 49.3779865, lng: 19.4603757 },
    { lat: 49.3779414, lng: 19.4606232 },
    { lat: 49.3779216, lng: 19.4608535 },
    { lat: 49.3775836, lng: 19.4609557 },
    { lat: 49.3773104, lng: 19.4609898 },
    { lat: 49.3770225, lng: 19.4611991 },
    { lat: 49.3768136, lng: 19.4614125 },
    { lat: 49.3765864, lng: 19.4618115 },
    { lat: 49.3767345, lng: 19.4620711 },
    { lat: 49.3767431, lng: 19.4623035 },
    { lat: 49.3766096, lng: 19.4626881 },
    { lat: 49.3762707, lng: 19.4629122 },
    { lat: 49.3761326, lng: 19.4631187 },
    { lat: 49.3757665, lng: 19.4633935 },
    { lat: 49.3754687, lng: 19.4639994 },
    { lat: 49.3753879, lng: 19.4640822 },
    { lat: 49.3752429, lng: 19.4644319 },
    { lat: 49.3752597, lng: 19.4648243 },
    { lat: 49.3752011, lng: 19.4650443 },
    { lat: 49.3749607, lng: 19.4653767 },
    { lat: 49.3749223, lng: 19.4657457 },
    { lat: 49.3749036, lng: 19.4658786 },
    { lat: 49.3747871, lng: 19.4659888 },
    { lat: 49.3745455, lng: 19.4659576 },
    { lat: 49.3742834, lng: 19.4663119 },
    { lat: 49.3739447, lng: 19.4663455 },
    { lat: 49.3738031, lng: 19.4664757 },
    { lat: 49.3736033, lng: 19.4663706 },
    { lat: 49.373491, lng: 19.4663537 },
    { lat: 49.3734187, lng: 19.466398 },
    { lat: 49.3733258, lng: 19.4664377 },
    { lat: 49.3731415, lng: 19.4664687 },
    { lat: 49.373094, lng: 19.4665862 },
    { lat: 49.3729685, lng: 19.4667292 },
    { lat: 49.3726655, lng: 19.4668044 },
    { lat: 49.372643, lng: 19.4668802 },
    { lat: 49.3726578, lng: 19.467028 },
    { lat: 49.3726369, lng: 19.4671383 },
    { lat: 49.3724454, lng: 19.4673863 },
    { lat: 49.3722497, lng: 19.4674168 },
    { lat: 49.3721602, lng: 19.4675555 },
    { lat: 49.3719989, lng: 19.467674 },
    { lat: 49.3718243, lng: 19.4677481 },
    { lat: 49.3716834, lng: 19.4677965 },
    { lat: 49.3716069, lng: 19.4678481 },
    { lat: 49.3715189, lng: 19.4679411 },
    { lat: 49.3713152, lng: 19.4680715 },
    { lat: 49.3710624, lng: 19.4680881 },
    { lat: 49.3710029, lng: 19.4680927 },
    { lat: 49.3709702, lng: 19.4680189 },
    { lat: 49.3709411, lng: 19.4679974 },
    { lat: 49.3708954, lng: 19.4679832 },
    { lat: 49.3708439, lng: 19.4679428 },
    { lat: 49.3707979, lng: 19.4679231 },
    { lat: 49.3707497, lng: 19.4679008 },
    { lat: 49.3706731, lng: 19.4679544 },
    { lat: 49.3706099, lng: 19.4679986 },
    { lat: 49.3705934, lng: 19.4680098 },
    { lat: 49.3705901, lng: 19.4680105 },
    { lat: 49.3704305, lng: 19.4680411 },
    { lat: 49.3703643, lng: 19.4679762 },
    { lat: 49.3702261, lng: 19.4678092 },
    { lat: 49.3700154, lng: 19.4677628 },
    { lat: 49.3697919, lng: 19.4677785 },
    { lat: 49.3696536, lng: 19.4677084 },
    { lat: 49.3695959, lng: 19.4676795 },
    { lat: 49.3695601, lng: 19.467661 },
    { lat: 49.3693099, lng: 19.4677348 },
    { lat: 49.3692844, lng: 19.4677408 },
    { lat: 49.3690088, lng: 19.4677289 },
    { lat: 49.3689855, lng: 19.4677364 },
    { lat: 49.3688502, lng: 19.4676367 },
    { lat: 49.3686747, lng: 19.4677398 },
    { lat: 49.3686045, lng: 19.4678049 },
    { lat: 49.3685816, lng: 19.4678258 },
    { lat: 49.3684813, lng: 19.4679193 },
    { lat: 49.3684622, lng: 19.4678993 },
    { lat: 49.3684176, lng: 19.4678061 },
    { lat: 49.368366, lng: 19.4677618 },
    { lat: 49.3683415, lng: 19.4677218 },
    { lat: 49.3682935, lng: 19.4677562 },
    { lat: 49.3682923, lng: 19.4677571 },
    { lat: 49.3682214, lng: 19.467807 },
    { lat: 49.3681603, lng: 19.4678497 },
    { lat: 49.3681538, lng: 19.4678555 },
    { lat: 49.368075, lng: 19.4678845 },
    { lat: 49.3678868, lng: 19.4679047 },
    { lat: 49.367649, lng: 19.4680529 },
    { lat: 49.3675378, lng: 19.4682069 },
    { lat: 49.3674357, lng: 19.4683907 },
    { lat: 49.3673475, lng: 19.4683817 },
    { lat: 49.367327, lng: 19.4683984 },
    { lat: 49.3673181, lng: 19.4684058 },
    { lat: 49.3673004, lng: 19.4684367 },
    { lat: 49.3672221, lng: 19.4685731 },
    { lat: 49.3672213, lng: 19.468575 },
    { lat: 49.3672146, lng: 19.468605 },
    { lat: 49.3672047, lng: 19.4686556 },
    { lat: 49.3671939, lng: 19.4687409 },
    { lat: 49.3672396, lng: 19.4689996 },
    { lat: 49.3671975, lng: 19.4690406 },
    { lat: 49.3671307, lng: 19.4691071 },
    { lat: 49.367109, lng: 19.4691286 },
    { lat: 49.3670778, lng: 19.4691591 },
    { lat: 49.3671185, lng: 19.4692595 },
    { lat: 49.3670415, lng: 19.4693759 },
    { lat: 49.3670236, lng: 19.4694024 },
    { lat: 49.3669439, lng: 19.4694573 },
    { lat: 49.3669255, lng: 19.4695493 },
    { lat: 49.366876, lng: 19.4695976 },
    { lat: 49.3667067, lng: 19.4697163 },
    { lat: 49.3666533, lng: 19.4698807 },
    { lat: 49.3665764, lng: 19.4702176 },
    { lat: 49.3665348, lng: 19.4702937 },
    { lat: 49.3665185, lng: 19.4703811 },
    { lat: 49.3664996, lng: 19.4706286 },
    { lat: 49.3664987, lng: 19.4706855 },
    { lat: 49.3663821, lng: 19.4707685 },
    { lat: 49.3663451, lng: 19.4708994 },
    { lat: 49.3663453, lng: 19.4712695 },
    { lat: 49.3662237, lng: 19.4713979 },
    { lat: 49.36617, lng: 19.4716025 },
    { lat: 49.3662728, lng: 19.4716566 },
    { lat: 49.3663325, lng: 19.4718309 },
    { lat: 49.3662552, lng: 19.4720616 },
    { lat: 49.3662737, lng: 19.4722432 },
    { lat: 49.3662321, lng: 19.4724173 },
    { lat: 49.3661982, lng: 19.4726819 },
    { lat: 49.3660325, lng: 19.4728074 },
    { lat: 49.3661155, lng: 19.4730689 },
    { lat: 49.3660868, lng: 19.4732307 },
    { lat: 49.3660086, lng: 19.4733165 },
    { lat: 49.3659021, lng: 19.4736258 },
    { lat: 49.365926, lng: 19.473876 },
    { lat: 49.3657063, lng: 19.4740998 },
    { lat: 49.3655335, lng: 19.474161 },
    { lat: 49.3654399, lng: 19.4742633 },
    { lat: 49.3653089, lng: 19.4742072 },
    { lat: 49.364982, lng: 19.4744986 },
    { lat: 49.3649508, lng: 19.4746003 },
    { lat: 49.3648467, lng: 19.4746682 },
    { lat: 49.3647839, lng: 19.4749204 },
    { lat: 49.3647176, lng: 19.4749467 },
    { lat: 49.3646196, lng: 19.4750237 },
    { lat: 49.3644924, lng: 19.4750525 },
    { lat: 49.3644336, lng: 19.4750921 },
    { lat: 49.3643835, lng: 19.4751788 },
    { lat: 49.3643495, lng: 19.4751796 },
    { lat: 49.3642192, lng: 19.4752362 },
    { lat: 49.3640925, lng: 19.4755434 },
    { lat: 49.3640587, lng: 19.4755947 },
    { lat: 49.3639129, lng: 19.4758034 },
    { lat: 49.3639393, lng: 19.4758891 },
    { lat: 49.3638777, lng: 19.4759988 },
    { lat: 49.3638249, lng: 19.4760988 },
    { lat: 49.3638041, lng: 19.4762109 },
    { lat: 49.3637261, lng: 19.4763747 },
    { lat: 49.3637307, lng: 19.4765022 },
    { lat: 49.3636719, lng: 19.4767171 },
    { lat: 49.3636648, lng: 19.476835 },
    { lat: 49.3635888, lng: 19.4769238 },
    { lat: 49.3635599, lng: 19.4770762 },
    { lat: 49.3635081, lng: 19.477179 },
    { lat: 49.3634131, lng: 19.4772445 },
    { lat: 49.3633315, lng: 19.477255 },
    { lat: 49.3631729, lng: 19.4774582 },
    { lat: 49.3631359, lng: 19.4775381 },
    { lat: 49.3630681, lng: 19.4776354 },
    { lat: 49.3629045, lng: 19.4778257 },
    { lat: 49.3628828, lng: 19.4778928 },
    { lat: 49.3628282, lng: 19.4780063 },
    { lat: 49.3627522, lng: 19.478043 },
    { lat: 49.3626203, lng: 19.4781631 },
    { lat: 49.3624354, lng: 19.4785076 },
    { lat: 49.3623917, lng: 19.4785386 },
    { lat: 49.3622379, lng: 19.4785721 },
    { lat: 49.3621196, lng: 19.4786705 },
    { lat: 49.3618342, lng: 19.4788637 },
    { lat: 49.3617527, lng: 19.4788765 },
    { lat: 49.3616355, lng: 19.4790502 },
    { lat: 49.3615392, lng: 19.479087 },
    { lat: 49.3614942, lng: 19.4792599 },
    { lat: 49.361444, lng: 19.4792985 },
    { lat: 49.3613925, lng: 19.4794002 },
    { lat: 49.3612168, lng: 19.4794306 },
    { lat: 49.3611685, lng: 19.4796046 },
    { lat: 49.3611049, lng: 19.4797419 },
    { lat: 49.3609586, lng: 19.4799712 },
    { lat: 49.3603484, lng: 19.4804935 },
    { lat: 49.3598096, lng: 19.4810107 },
    { lat: 49.3592604, lng: 19.4816881 },
    { lat: 49.358879, lng: 19.482154 },
  ],
  Zubrohlava: [
    { lat: 49.4867885, lng: 19.515376 },
    { lat: 49.4866564, lng: 19.5155916 },
    { lat: 49.486279, lng: 19.5166614 },
    { lat: 49.4859283, lng: 19.5170775 },
    { lat: 49.4857638, lng: 19.5171512 },
    { lat: 49.4856664, lng: 19.5172678 },
    { lat: 49.4856702, lng: 19.5173518 },
    { lat: 49.4859698, lng: 19.5173996 },
    { lat: 49.4860659, lng: 19.517431 },
    { lat: 49.485701, lng: 19.5174848 },
    { lat: 49.4855775, lng: 19.5174689 },
    { lat: 49.4853876, lng: 19.5174026 },
    { lat: 49.4850837, lng: 19.5173354 },
    { lat: 49.4848441, lng: 19.5174175 },
    { lat: 49.4846172, lng: 19.5175326 },
    { lat: 49.4842905, lng: 19.5177313 },
    { lat: 49.4840221, lng: 19.517924 },
    { lat: 49.4839634, lng: 19.5179484 },
    { lat: 49.483503, lng: 19.5182077 },
    { lat: 49.4833321, lng: 19.5182593 },
    { lat: 49.4832465, lng: 19.5183604 },
    { lat: 49.482932, lng: 19.5185067 },
    { lat: 49.4826904, lng: 19.5182491 },
    { lat: 49.480204, lng: 19.5184211 },
    { lat: 49.4788284, lng: 19.5182968 },
    { lat: 49.478791, lng: 19.51832 },
    { lat: 49.4787287, lng: 19.5182592 },
    { lat: 49.4781531, lng: 19.5179409 },
    { lat: 49.4778986, lng: 19.517967 },
    { lat: 49.4776647, lng: 19.517926 },
    { lat: 49.4774618, lng: 19.5178233 },
    { lat: 49.4756078, lng: 19.5171881 },
    { lat: 49.4755634, lng: 19.517203 },
    { lat: 49.4753182, lng: 19.5170857 },
    { lat: 49.474854, lng: 19.5167874 },
    { lat: 49.4741972, lng: 19.516318 },
    { lat: 49.4734466, lng: 19.5157887 },
    { lat: 49.4719883, lng: 19.5157048 },
    { lat: 49.4717061, lng: 19.5154962 },
    { lat: 49.47171, lng: 19.5154769 },
    { lat: 49.4709716, lng: 19.5151721 },
    { lat: 49.4708182, lng: 19.515016 },
    { lat: 49.4703459, lng: 19.5146386 },
    { lat: 49.4702482, lng: 19.5145715 },
    { lat: 49.4695804, lng: 19.513933 },
    { lat: 49.4694289, lng: 19.5137735 },
    { lat: 49.4692619, lng: 19.5134939 },
    { lat: 49.469006, lng: 19.5131412 },
    { lat: 49.4688088, lng: 19.512791 },
    { lat: 49.4687277, lng: 19.5125699 },
    { lat: 49.4684049, lng: 19.5119592 },
    { lat: 49.4683819, lng: 19.5118269 },
    { lat: 49.4681943, lng: 19.5115412 },
    { lat: 49.4679926, lng: 19.5110668 },
    { lat: 49.4679629, lng: 19.5109852 },
    { lat: 49.4678681, lng: 19.5106324 },
    { lat: 49.4677902, lng: 19.5104038 },
    { lat: 49.4676606, lng: 19.5101325 },
    { lat: 49.4676324, lng: 19.5100319 },
    { lat: 49.4675625, lng: 19.5096319 },
    { lat: 49.4674023, lng: 19.5091817 },
    { lat: 49.467304, lng: 19.5088502 },
    { lat: 49.4671673, lng: 19.5083733 },
    { lat: 49.4670249, lng: 19.5079647 },
    { lat: 49.4668548, lng: 19.5076923 },
    { lat: 49.4666957, lng: 19.5072884 },
    { lat: 49.4665298, lng: 19.5069609 },
    { lat: 49.4663641, lng: 19.5066613 },
    { lat: 49.4662704, lng: 19.506359 },
    { lat: 49.4662244, lng: 19.5062882 },
    { lat: 49.4661404, lng: 19.5060288 },
    { lat: 49.4660565, lng: 19.5059436 },
    { lat: 49.4659626, lng: 19.5057098 },
    { lat: 49.4658201, lng: 19.5051256 },
    { lat: 49.4657075, lng: 19.5049793 },
    { lat: 49.4654071, lng: 19.5043688 },
    { lat: 49.4651988, lng: 19.5038006 },
    { lat: 49.4648291, lng: 19.5029016 },
    { lat: 49.4646438, lng: 19.5025447 },
    { lat: 49.4643107, lng: 19.501958 },
    { lat: 49.4641962, lng: 19.5017917 },
    { lat: 49.4640305, lng: 19.5014968 },
    { lat: 49.4638479, lng: 19.5011201 },
    { lat: 49.4638239, lng: 19.5009397 },
    { lat: 49.4636754, lng: 19.5004471 },
    { lat: 49.463662, lng: 19.5004532 },
    { lat: 49.4630738, lng: 19.4984667 },
    { lat: 49.4629837, lng: 19.4982192 },
    { lat: 49.4629402, lng: 19.4980061 },
    { lat: 49.4629163, lng: 19.4976041 },
    { lat: 49.4629223, lng: 19.4975143 },
    { lat: 49.4628943, lng: 19.4974163 },
    { lat: 49.4629485, lng: 19.4969736 },
    { lat: 49.4629053, lng: 19.4965682 },
    { lat: 49.4629554, lng: 19.4964817 },
    { lat: 49.4630306, lng: 19.4962766 },
    { lat: 49.463236, lng: 19.4941342 },
    { lat: 49.4632388, lng: 19.4940973 },
    { lat: 49.4632047, lng: 19.493716 },
    { lat: 49.4632172, lng: 19.493498 },
    { lat: 49.4632417, lng: 19.4933404 },
    { lat: 49.4632406, lng: 19.4930214 },
    { lat: 49.46353, lng: 19.4925492 },
    { lat: 49.4634912, lng: 19.4916445 },
    { lat: 49.4634531, lng: 19.4913773 },
    { lat: 49.4632462, lng: 19.4902255 },
    { lat: 49.4634985, lng: 19.4894295 },
    { lat: 49.4635657, lng: 19.4891488 },
    { lat: 49.4637005, lng: 19.4880545 },
    { lat: 49.4637377, lng: 19.487384 },
    { lat: 49.4638925, lng: 19.4863822 },
    { lat: 49.4639204, lng: 19.4857554 },
    { lat: 49.4640708, lng: 19.4852749 },
    { lat: 49.4641272, lng: 19.4851298 },
    { lat: 49.4641573, lng: 19.4851736 },
    { lat: 49.4642457, lng: 19.4850348 },
    { lat: 49.4643658, lng: 19.4847753 },
    { lat: 49.4645623, lng: 19.4840927 },
    { lat: 49.4649202, lng: 19.4831455 },
    { lat: 49.4652013, lng: 19.4823937 },
    { lat: 49.4653023, lng: 19.481745 },
    { lat: 49.4654977, lng: 19.4807655 },
    { lat: 49.4655178, lng: 19.4803406 },
    { lat: 49.4655329, lng: 19.4794874 },
    { lat: 49.4654015, lng: 19.4775486 },
    { lat: 49.4653571, lng: 19.4771412 },
    { lat: 49.4653077, lng: 19.4764571 },
    { lat: 49.4653165, lng: 19.475981 },
    { lat: 49.4647388, lng: 19.4763078 },
    { lat: 49.4643189, lng: 19.4767087 },
    { lat: 49.4636283, lng: 19.4770803 },
    { lat: 49.4633292, lng: 19.4773667 },
    { lat: 49.4632394, lng: 19.4773749 },
    { lat: 49.4631304, lng: 19.4776242 },
    { lat: 49.463047, lng: 19.4777476 },
    { lat: 49.4629487, lng: 19.4779429 },
    { lat: 49.4626969, lng: 19.4783485 },
    { lat: 49.4625442, lng: 19.4785558 },
    { lat: 49.4622434, lng: 19.4787069 },
    { lat: 49.461632, lng: 19.4793811 },
    { lat: 49.4614423, lng: 19.4795946 },
    { lat: 49.4612449, lng: 19.4797139 },
    { lat: 49.4611014, lng: 19.4800245 },
    { lat: 49.4609377, lng: 19.4802149 },
    { lat: 49.4608435, lng: 19.480375 },
    { lat: 49.4606493, lng: 19.4806367 },
    { lat: 49.4602823, lng: 19.4811357 },
    { lat: 49.4601019, lng: 19.4813782 },
    { lat: 49.4596915, lng: 19.4818417 },
    { lat: 49.4593157, lng: 19.4822728 },
    { lat: 49.4592639, lng: 19.4823755 },
    { lat: 49.4590336, lng: 19.4826363 },
    { lat: 49.458876, lng: 19.4829068 },
    { lat: 49.4588587, lng: 19.4829992 },
    { lat: 49.4585052, lng: 19.4833247 },
    { lat: 49.4582496, lng: 19.4836013 },
    { lat: 49.458115, lng: 19.4837136 },
    { lat: 49.4579781, lng: 19.4837761 },
    { lat: 49.4571321, lng: 19.4847422 },
    { lat: 49.457002, lng: 19.4848772 },
    { lat: 49.456713, lng: 19.4850639 },
    { lat: 49.4564117, lng: 19.4852759 },
    { lat: 49.4562317, lng: 19.4854067 },
    { lat: 49.4560231, lng: 19.4857464 },
    { lat: 49.4549477, lng: 19.4868703 },
    { lat: 49.4542496, lng: 19.4882189 },
    { lat: 49.454193, lng: 19.4887074 },
    { lat: 49.4539415, lng: 19.4887257 },
    { lat: 49.453809, lng: 19.4887355 },
    { lat: 49.4533016, lng: 19.490211 },
    { lat: 49.453114, lng: 19.4904216 },
    { lat: 49.4515845, lng: 19.4921405 },
    { lat: 49.4504237, lng: 19.4932929 },
    { lat: 49.4500377, lng: 19.4935512 },
    { lat: 49.4481862, lng: 19.4945574 },
    { lat: 49.4476462, lng: 19.4948984 },
    { lat: 49.4465121, lng: 19.4955999 },
    { lat: 49.4464868, lng: 19.4956149 },
    { lat: 49.4464618, lng: 19.4956306 },
    { lat: 49.4464485, lng: 19.4956389 },
    { lat: 49.4463359, lng: 19.496159 },
    { lat: 49.4462944, lng: 19.4963112 },
    { lat: 49.4462141, lng: 19.4964548 },
    { lat: 49.446048, lng: 19.4966439 },
    { lat: 49.4459503, lng: 19.496822 },
    { lat: 49.4454798, lng: 19.498094 },
    { lat: 49.4453617, lng: 19.4984195 },
    { lat: 49.4452428, lng: 19.4985832 },
    { lat: 49.4445939, lng: 19.4992277 },
    { lat: 49.4444529, lng: 19.4993984 },
    { lat: 49.4444118, lng: 19.49946 },
    { lat: 49.4443136, lng: 19.499607 },
    { lat: 49.4440451, lng: 19.500067 },
    { lat: 49.4439565, lng: 19.5002738 },
    { lat: 49.4439068, lng: 19.5004935 },
    { lat: 49.4438451, lng: 19.5009701 },
    { lat: 49.4437726, lng: 19.501286 },
    { lat: 49.4432625, lng: 19.5030242 },
    { lat: 49.4432244, lng: 19.5032295 },
    { lat: 49.4432188, lng: 19.5032566 },
    { lat: 49.4432352, lng: 19.5033671 },
    { lat: 49.443234, lng: 19.5033668 },
    { lat: 49.4432249, lng: 19.5033642 },
    { lat: 49.4424823, lng: 19.5037311 },
    { lat: 49.4423996, lng: 19.5037717 },
    { lat: 49.4412403, lng: 19.5043931 },
    { lat: 49.441059, lng: 19.5044187 },
    { lat: 49.4399174, lng: 19.504662 },
    { lat: 49.4385558, lng: 19.5042767 },
    { lat: 49.4385178, lng: 19.5042815 },
    { lat: 49.4380864, lng: 19.504337 },
    { lat: 49.4368762, lng: 19.5039378 },
    { lat: 49.4368584, lng: 19.5040392 },
    { lat: 49.4368385, lng: 19.5041467 },
    { lat: 49.436825, lng: 19.5042249 },
    { lat: 49.4368217, lng: 19.5042712 },
    { lat: 49.4367941, lng: 19.5047347 },
    { lat: 49.4367931, lng: 19.5047594 },
    { lat: 49.4367881, lng: 19.5048465 },
    { lat: 49.4367743, lng: 19.5050883 },
    { lat: 49.4367687, lng: 19.5051671 },
    { lat: 49.4367672, lng: 19.5052078 },
    { lat: 49.4367657, lng: 19.5052203 },
    { lat: 49.4367594, lng: 19.5053303 },
    { lat: 49.4367366, lng: 19.5066643 },
    { lat: 49.4367303, lng: 19.5068011 },
    { lat: 49.4367257, lng: 19.5068961 },
    { lat: 49.4366322, lng: 19.5068709 },
    { lat: 49.4366214, lng: 19.5070064 },
    { lat: 49.4366176, lng: 19.5070683 },
    { lat: 49.4366222, lng: 19.5072753 },
    { lat: 49.4366265, lng: 19.5074891 },
    { lat: 49.4366358, lng: 19.5079432 },
    { lat: 49.4366394, lng: 19.5082969 },
    { lat: 49.4366466, lng: 19.5086028 },
    { lat: 49.4366347, lng: 19.5089114 },
    { lat: 49.4366248, lng: 19.5091409 },
    { lat: 49.4365869, lng: 19.5091739 },
    { lat: 49.4366222, lng: 19.5095318 },
    { lat: 49.4365679, lng: 19.5109147 },
    { lat: 49.436562, lng: 19.5109831 },
    { lat: 49.4365632, lng: 19.5111853 },
    { lat: 49.4365303, lng: 19.512667 },
    { lat: 49.436524, lng: 19.5129544 },
    { lat: 49.4365282, lng: 19.5131925 },
    { lat: 49.4365294, lng: 19.5132184 },
    { lat: 49.4365303, lng: 19.5132904 },
    { lat: 49.4365313, lng: 19.5133344 },
    { lat: 49.436531, lng: 19.5133537 },
    { lat: 49.4365313, lng: 19.5133601 },
    { lat: 49.4365317, lng: 19.5133938 },
    { lat: 49.4365422, lng: 19.5141732 },
    { lat: 49.4365099, lng: 19.5144543 },
    { lat: 49.4364594, lng: 19.5148818 },
    { lat: 49.4362736, lng: 19.5159497 },
    { lat: 49.4362689, lng: 19.5159719 },
    { lat: 49.4362699, lng: 19.5159921 },
    { lat: 49.4365907, lng: 19.5158883 },
    { lat: 49.4365893, lng: 19.5160279 },
    { lat: 49.4365322, lng: 19.5172058 },
    { lat: 49.4365227, lng: 19.517247 },
    { lat: 49.4364916, lng: 19.5179509 },
    { lat: 49.4364353, lng: 19.518851 },
    { lat: 49.436386, lng: 19.519029 },
    { lat: 49.4363679, lng: 19.5192492 },
    { lat: 49.4363556, lng: 19.519379 },
    { lat: 49.4363415, lng: 19.5195355 },
    { lat: 49.4363261, lng: 19.5196964 },
    { lat: 49.4363074, lng: 19.5199044 },
    { lat: 49.4362958, lng: 19.5200704 },
    { lat: 49.4362627, lng: 19.5209073 },
    { lat: 49.436232, lng: 19.5212719 },
    { lat: 49.4362431, lng: 19.5213938 },
    { lat: 49.436253, lng: 19.5214861 },
    { lat: 49.4362652, lng: 19.5216104 },
    { lat: 49.4362978, lng: 19.5219541 },
    { lat: 49.4360749, lng: 19.5220829 },
    { lat: 49.4360539, lng: 19.5220949 },
    { lat: 49.4360233, lng: 19.5221126 },
    { lat: 49.4360987, lng: 19.5222602 },
    { lat: 49.4361125, lng: 19.5223677 },
    { lat: 49.4362274, lng: 19.5224424 },
    { lat: 49.4362429, lng: 19.5224371 },
    { lat: 49.4362974, lng: 19.522419 },
    { lat: 49.4363771, lng: 19.5224865 },
    { lat: 49.4364198, lng: 19.5224375 },
    { lat: 49.4364427, lng: 19.5224951 },
    { lat: 49.436421, lng: 19.5226354 },
    { lat: 49.4364609, lng: 19.5226934 },
    { lat: 49.4365115, lng: 19.5227691 },
    { lat: 49.4365577, lng: 19.5227678 },
    { lat: 49.4366195, lng: 19.5227663 },
    { lat: 49.4366416, lng: 19.5228313 },
    { lat: 49.4366599, lng: 19.5228865 },
    { lat: 49.4366767, lng: 19.5229347 },
    { lat: 49.4367236, lng: 19.5229228 },
    { lat: 49.4367748, lng: 19.5229101 },
    { lat: 49.4368941, lng: 19.52288 },
    { lat: 49.436902, lng: 19.5229817 },
    { lat: 49.4369045, lng: 19.5230107 },
    { lat: 49.4369296, lng: 19.5229972 },
    { lat: 49.4370177, lng: 19.522952 },
    { lat: 49.4371785, lng: 19.5228693 },
    { lat: 49.4372312, lng: 19.522989 },
    { lat: 49.4373068, lng: 19.5230178 },
    { lat: 49.4373254, lng: 19.5231252 },
    { lat: 49.4373546, lng: 19.5232017 },
    { lat: 49.4373494, lng: 19.523233 },
    { lat: 49.4373437, lng: 19.5232583 },
    { lat: 49.4373411, lng: 19.5232748 },
    { lat: 49.4373366, lng: 19.5232996 },
    { lat: 49.4373715, lng: 19.5233987 },
    { lat: 49.4374452, lng: 19.5233358 },
    { lat: 49.4374538, lng: 19.5233287 },
    { lat: 49.4374768, lng: 19.5233093 },
    { lat: 49.4375136, lng: 19.5232775 },
    { lat: 49.4375913, lng: 19.5233774 },
    { lat: 49.4376074, lng: 19.5234094 },
    { lat: 49.4376199, lng: 19.5234359 },
    { lat: 49.4376458, lng: 19.5234864 },
    { lat: 49.4376806, lng: 19.5235551 },
    { lat: 49.4377113, lng: 19.5236872 },
    { lat: 49.4377862, lng: 19.5237763 },
    { lat: 49.4379018, lng: 19.52379 },
    { lat: 49.4380115, lng: 19.5238978 },
    { lat: 49.4380552, lng: 19.5239395 },
    { lat: 49.4381343, lng: 19.5239984 },
    { lat: 49.4381392, lng: 19.5240801 },
    { lat: 49.4381399, lng: 19.5240949 },
    { lat: 49.4382246, lng: 19.5243766 },
    { lat: 49.4383002, lng: 19.5244753 },
    { lat: 49.4383253, lng: 19.5248332 },
    { lat: 49.4384342, lng: 19.5251196 },
    { lat: 49.4384699, lng: 19.5252157 },
    { lat: 49.4385109, lng: 19.5254492 },
    { lat: 49.4385262, lng: 19.5256362 },
    { lat: 49.4386991, lng: 19.5260953 },
    { lat: 49.4387492, lng: 19.5260353 },
    { lat: 49.4387739, lng: 19.5260066 },
    { lat: 49.4388969, lng: 19.52586 },
    { lat: 49.4390813, lng: 19.5256789 },
    { lat: 49.4391919, lng: 19.5255357 },
    { lat: 49.4393778, lng: 19.5252863 },
    { lat: 49.4395649, lng: 19.5251842 },
    { lat: 49.4398083, lng: 19.5251366 },
    { lat: 49.4402045, lng: 19.5252526 },
    { lat: 49.4404227, lng: 19.5251972 },
    { lat: 49.4409611, lng: 19.5248716 },
    { lat: 49.4412998, lng: 19.5247139 },
    { lat: 49.4419848, lng: 19.5245845 },
    { lat: 49.4420308, lng: 19.5243797 },
    { lat: 49.4425129, lng: 19.5244533 },
    { lat: 49.4425457, lng: 19.5244581 },
    { lat: 49.4429565, lng: 19.5245208 },
    { lat: 49.4433989, lng: 19.5245411 },
    { lat: 49.4437382, lng: 19.524594 },
    { lat: 49.4439403, lng: 19.5246529 },
    { lat: 49.4443852, lng: 19.5251975 },
    { lat: 49.4445419, lng: 19.5253007 },
    { lat: 49.4447658, lng: 19.5256147 },
    { lat: 49.4452384, lng: 19.5260524 },
    { lat: 49.445496, lng: 19.5262669 },
    { lat: 49.445587, lng: 19.526385 },
    { lat: 49.445735, lng: 19.5266705 },
    { lat: 49.4459683, lng: 19.5273453 },
    { lat: 49.4460191, lng: 19.527472 },
    { lat: 49.446267, lng: 19.5280938 },
    { lat: 49.446786, lng: 19.5295818 },
    { lat: 49.4470614, lng: 19.5302173 },
    { lat: 49.447201, lng: 19.5306377 },
    { lat: 49.4472897, lng: 19.5308553 },
    { lat: 49.4482852, lng: 19.5321175 },
    { lat: 49.4484085, lng: 19.5322485 },
    { lat: 49.4485499, lng: 19.5323381 },
    { lat: 49.4487495, lng: 19.5325432 },
    { lat: 49.4489245, lng: 19.5327227 },
    { lat: 49.4490586, lng: 19.5327486 },
    { lat: 49.4492869, lng: 19.5327443 },
    { lat: 49.4494929, lng: 19.5328357 },
    { lat: 49.4497139, lng: 19.5329665 },
    { lat: 49.4499397, lng: 19.5331004 },
    { lat: 49.4506657, lng: 19.5335341 },
    { lat: 49.4507377, lng: 19.5335808 },
    { lat: 49.4511694, lng: 19.5338608 },
    { lat: 49.4520893, lng: 19.5348216 },
    { lat: 49.4527755, lng: 19.535571 },
    { lat: 49.4532679, lng: 19.5360246 },
    { lat: 49.4535446, lng: 19.536239 },
    { lat: 49.4539182, lng: 19.5366288 },
    { lat: 49.4544994, lng: 19.5374749 },
    { lat: 49.4547859, lng: 19.5378284 },
    { lat: 49.4550394, lng: 19.5381049 },
    { lat: 49.4550726, lng: 19.5381405 },
    { lat: 49.4559796, lng: 19.5390441 },
    { lat: 49.4559963, lng: 19.539063 },
    { lat: 49.4562942, lng: 19.5393986 },
    { lat: 49.456487, lng: 19.539699 },
    { lat: 49.4571397, lng: 19.5408085 },
    { lat: 49.4572646, lng: 19.5409803 },
    { lat: 49.4574381, lng: 19.5411785 },
    { lat: 49.4575928, lng: 19.541337 },
    { lat: 49.4577148, lng: 19.5414468 },
    { lat: 49.4578375, lng: 19.5414921 },
    { lat: 49.457849, lng: 19.5414963 },
    { lat: 49.4578673, lng: 19.5415031 },
    { lat: 49.4579957, lng: 19.5415514 },
    { lat: 49.4580209, lng: 19.5415599 },
    { lat: 49.4584144, lng: 19.5416949 },
    { lat: 49.4588946, lng: 19.5416736 },
    { lat: 49.4592798, lng: 19.5416979 },
    { lat: 49.4593729, lng: 19.5416902 },
    { lat: 49.4595818, lng: 19.5416738 },
    { lat: 49.4598744, lng: 19.5423414 },
    { lat: 49.4602382, lng: 19.5429899 },
    { lat: 49.4602986, lng: 19.5429784 },
    { lat: 49.4603736, lng: 19.5430929 },
    { lat: 49.4604293, lng: 19.5432797 },
    { lat: 49.4604929, lng: 19.5432407 },
    { lat: 49.4605308, lng: 19.5432808 },
    { lat: 49.460617, lng: 19.5433706 },
    { lat: 49.4609392, lng: 19.5438714 },
    { lat: 49.4613377, lng: 19.5444108 },
    { lat: 49.4617676, lng: 19.5449048 },
    { lat: 49.4627968, lng: 19.5459384 },
    { lat: 49.4640409, lng: 19.5471235 },
    { lat: 49.4641823, lng: 19.5471125 },
    { lat: 49.4645093, lng: 19.5471908 },
    { lat: 49.4645251, lng: 19.5471964 },
    { lat: 49.4645482, lng: 19.5472052 },
    { lat: 49.4645918, lng: 19.5471727 },
    { lat: 49.4648272, lng: 19.5469945 },
    { lat: 49.4656837, lng: 19.5468022 },
    { lat: 49.4659452, lng: 19.5466803 },
    { lat: 49.4662859, lng: 19.5463663 },
    { lat: 49.4664326, lng: 19.5462468 },
    { lat: 49.4665568, lng: 19.5461018 },
    { lat: 49.4667466, lng: 19.5459389 },
    { lat: 49.4667845, lng: 19.5458577 },
    { lat: 49.4668421, lng: 19.5457571 },
    { lat: 49.4669734, lng: 19.5456222 },
    { lat: 49.467249, lng: 19.545435 },
    { lat: 49.4675278, lng: 19.5452986 },
    { lat: 49.4677313, lng: 19.5451621 },
    { lat: 49.4680464, lng: 19.5448838 },
    { lat: 49.4683247, lng: 19.5445841 },
    { lat: 49.4686107, lng: 19.5443309 },
    { lat: 49.4688874, lng: 19.5441686 },
    { lat: 49.4691127, lng: 19.5439715 },
    { lat: 49.4692763, lng: 19.5437973 },
    { lat: 49.4695382, lng: 19.543684 },
    { lat: 49.4697349, lng: 19.5436537 },
    { lat: 49.4699805, lng: 19.5436041 },
    { lat: 49.4704677, lng: 19.543313 },
    { lat: 49.4709426, lng: 19.5429032 },
    { lat: 49.4712176, lng: 19.5427294 },
    { lat: 49.4715374, lng: 19.5423242 },
    { lat: 49.4720107, lng: 19.542004 },
    { lat: 49.4728023, lng: 19.541616 },
    { lat: 49.4732079, lng: 19.5412458 },
    { lat: 49.4735914, lng: 19.5409551 },
    { lat: 49.4738297, lng: 19.5407153 },
    { lat: 49.473922, lng: 19.5406418 },
    { lat: 49.4743165, lng: 19.5404426 },
    { lat: 49.4748141, lng: 19.5400119 },
    { lat: 49.4754187, lng: 19.5397042 },
    { lat: 49.4766101, lng: 19.5386922 },
    { lat: 49.4769081, lng: 19.5385315 },
    { lat: 49.4771624, lng: 19.538302 },
    { lat: 49.4780394, lng: 19.5378084 },
    { lat: 49.4780594, lng: 19.5378806 },
    { lat: 49.478079, lng: 19.5379379 },
    { lat: 49.4785523, lng: 19.5393405 },
    { lat: 49.4792563, lng: 19.5403618 },
    { lat: 49.4800564, lng: 19.5416204 },
    { lat: 49.4801234, lng: 19.5417281 },
    { lat: 49.4801415, lng: 19.5417581 },
    { lat: 49.4802132, lng: 19.5418722 },
    { lat: 49.4806607, lng: 19.5425287 },
    { lat: 49.4810324, lng: 19.5430984 },
    { lat: 49.4812123, lng: 19.5433662 },
    { lat: 49.4812182, lng: 19.5433754 },
    { lat: 49.4812382, lng: 19.5434175 },
    { lat: 49.4812792, lng: 19.5435034 },
    { lat: 49.4813016, lng: 19.5435503 },
    { lat: 49.4821991, lng: 19.5450164 },
    { lat: 49.482784, lng: 19.5458468 },
    { lat: 49.483235, lng: 19.5464522 },
    { lat: 49.4834873, lng: 19.5474057 },
    { lat: 49.4835767, lng: 19.5475879 },
    { lat: 49.483584, lng: 19.5476038 },
    { lat: 49.4835952, lng: 19.5476261 },
    { lat: 49.4834613, lng: 19.5477298 },
    { lat: 49.4834899, lng: 19.5477634 },
    { lat: 49.4835814, lng: 19.5478715 },
    { lat: 49.48403, lng: 19.5481489 },
    { lat: 49.4840914, lng: 19.548187 },
    { lat: 49.4845621, lng: 19.5484791 },
    { lat: 49.4856487, lng: 19.5491455 },
    { lat: 49.4864822, lng: 19.5496681 },
    { lat: 49.487343, lng: 19.5501686 },
    { lat: 49.4877199, lng: 19.5501594 },
    { lat: 49.4877457, lng: 19.5500045 },
    { lat: 49.4883342, lng: 19.5496159 },
    { lat: 49.4889485, lng: 19.5467926 },
    { lat: 49.4891798, lng: 19.5462259 },
    { lat: 49.4898449, lng: 19.5456563 },
    { lat: 49.4903599, lng: 19.5453128 },
    { lat: 49.4904503, lng: 19.5451909 },
    { lat: 49.4907624, lng: 19.5447196 },
    { lat: 49.4909335, lng: 19.5435928 },
    { lat: 49.4910419, lng: 19.5425459 },
    { lat: 49.4909766, lng: 19.5416192 },
    { lat: 49.4909729, lng: 19.540914 },
    { lat: 49.4914369, lng: 19.5396332 },
    { lat: 49.4914331, lng: 19.5388258 },
    { lat: 49.491338, lng: 19.5371416 },
    { lat: 49.4914037, lng: 19.5362532 },
    { lat: 49.4914785, lng: 19.5359847 },
    { lat: 49.4913914, lng: 19.5352535 },
    { lat: 49.4913471, lng: 19.5342717 },
    { lat: 49.4909286, lng: 19.5336135 },
    { lat: 49.4908625, lng: 19.5334451 },
    { lat: 49.4907698, lng: 19.5330404 },
    { lat: 49.4907981, lng: 19.5327945 },
    { lat: 49.4908228, lng: 19.532693 },
    { lat: 49.4906759, lng: 19.5314101 },
    { lat: 49.4907496, lng: 19.5299732 },
    { lat: 49.4907242, lng: 19.5295305 },
    { lat: 49.490758, lng: 19.5290815 },
    { lat: 49.4903348, lng: 19.5279458 },
    { lat: 49.4900614, lng: 19.527307 },
    { lat: 49.489955, lng: 19.5270476 },
    { lat: 49.4896662, lng: 19.5264398 },
    { lat: 49.489649, lng: 19.5261583 },
    { lat: 49.4893031, lng: 19.5246643 },
    { lat: 49.4889302, lng: 19.5231167 },
    { lat: 49.4889063, lng: 19.523062 },
    { lat: 49.4894251, lng: 19.5203093 },
    { lat: 49.4893998, lng: 19.5201989 },
    { lat: 49.4891297, lng: 19.5196084 },
    { lat: 49.4891614, lng: 19.5189889 },
    { lat: 49.4891685, lng: 19.5189225 },
    { lat: 49.4889851, lng: 19.5180578 },
    { lat: 49.4886748, lng: 19.5174237 },
    { lat: 49.4885755, lng: 19.517197 },
    { lat: 49.4884073, lng: 19.5169402 },
    { lat: 49.4883213, lng: 19.5167812 },
    { lat: 49.4882665, lng: 19.516665 },
    { lat: 49.4881823, lng: 19.5165732 },
    { lat: 49.4875244, lng: 19.5160751 },
    { lat: 49.4873153, lng: 19.5159145 },
    { lat: 49.4869938, lng: 19.5156295 },
    { lat: 49.4867885, lng: 19.515376 },
  ],
  Zákamenné: [
    { lat: 49.405233, lng: 19.147101 },
    { lat: 49.4047531, lng: 19.1463399 },
    { lat: 49.4043092, lng: 19.1460409 },
    { lat: 49.4037626, lng: 19.1458774 },
    { lat: 49.4035398, lng: 19.1457689 },
    { lat: 49.403459, lng: 19.1457296 },
    { lat: 49.4023826, lng: 19.1457759 },
    { lat: 49.4021473, lng: 19.145755 },
    { lat: 49.4018894, lng: 19.1458315 },
    { lat: 49.4017174, lng: 19.1458402 },
    { lat: 49.4016725, lng: 19.145845 },
    { lat: 49.401478, lng: 19.1457832 },
    { lat: 49.4014472, lng: 19.145778 },
    { lat: 49.4014168, lng: 19.1461231 },
    { lat: 49.4013086, lng: 19.1462896 },
    { lat: 49.401282, lng: 19.1464613 },
    { lat: 49.4012639, lng: 19.1466671 },
    { lat: 49.4012677, lng: 19.1468136 },
    { lat: 49.4012227, lng: 19.1469819 },
    { lat: 49.4011725, lng: 19.1470796 },
    { lat: 49.4010553, lng: 19.1472442 },
    { lat: 49.4007795, lng: 19.1476694 },
    { lat: 49.4005794, lng: 19.1478673 },
    { lat: 49.4001812, lng: 19.1480227 },
    { lat: 49.3999443, lng: 19.1481785 },
    { lat: 49.3996877, lng: 19.1483757 },
    { lat: 49.3994136, lng: 19.1485083 },
    { lat: 49.3990854, lng: 19.1487588 },
    { lat: 49.3988983, lng: 19.1490345 },
    { lat: 49.3986965, lng: 19.1492464 },
    { lat: 49.3985873, lng: 19.1495281 },
    { lat: 49.3985396, lng: 19.1497278 },
    { lat: 49.3984858, lng: 19.1498255 },
    { lat: 49.3983804, lng: 19.1498835 },
    { lat: 49.3983356, lng: 19.1499631 },
    { lat: 49.3982842, lng: 19.149994 },
    { lat: 49.3981887, lng: 19.1502053 },
    { lat: 49.3979673, lng: 19.1502237 },
    { lat: 49.3978228, lng: 19.1502618 },
    { lat: 49.3977277, lng: 19.1503711 },
    { lat: 49.3976002, lng: 19.1505271 },
    { lat: 49.3975145, lng: 19.1507135 },
    { lat: 49.397406, lng: 19.1508727 },
    { lat: 49.3974095, lng: 19.1510124 },
    { lat: 49.3973648, lng: 19.1510645 },
    { lat: 49.3973734, lng: 19.1511066 },
    { lat: 49.3973255, lng: 19.1511403 },
    { lat: 49.3971974, lng: 19.1512656 },
    { lat: 49.3971499, lng: 19.1512837 },
    { lat: 49.3970578, lng: 19.1511975 },
    { lat: 49.3969324, lng: 19.1511677 },
    { lat: 49.3968584, lng: 19.1511768 },
    { lat: 49.3967594, lng: 19.1512959 },
    { lat: 49.396601, lng: 19.1513755 },
    { lat: 49.3965355, lng: 19.15135 },
    { lat: 49.3965154, lng: 19.1513541 },
    { lat: 49.3964293, lng: 19.1513227 },
    { lat: 49.3963178, lng: 19.1514143 },
    { lat: 49.3961704, lng: 19.151443 },
    { lat: 49.3960621, lng: 19.1513479 },
    { lat: 49.3960508, lng: 19.15128 },
    { lat: 49.3959235, lng: 19.1512801 },
    { lat: 49.3958386, lng: 19.1513172 },
    { lat: 49.395722, lng: 19.1515176 },
    { lat: 49.3956299, lng: 19.1516645 },
    { lat: 49.3955556, lng: 19.1517829 },
    { lat: 49.3955058, lng: 19.151798 },
    { lat: 49.3954339, lng: 19.1518058 },
    { lat: 49.3953358, lng: 19.151897 },
    { lat: 49.3952414, lng: 19.1519034 },
    { lat: 49.3951554, lng: 19.1518499 },
    { lat: 49.3951403, lng: 19.1517951 },
    { lat: 49.3950243, lng: 19.1516582 },
    { lat: 49.3949416, lng: 19.1515992 },
    { lat: 49.3948298, lng: 19.1517339 },
    { lat: 49.3947448, lng: 19.1518901 },
    { lat: 49.3947068, lng: 19.1520808 },
    { lat: 49.3946791, lng: 19.1521379 },
    { lat: 49.3946539, lng: 19.1521534 },
    { lat: 49.3945326, lng: 19.1521357 },
    { lat: 49.3944535, lng: 19.1521809 },
    { lat: 49.3944045, lng: 19.152193 },
    { lat: 49.3944016, lng: 19.1522255 },
    { lat: 49.3943991, lng: 19.1523152 },
    { lat: 49.3943311, lng: 19.1524471 },
    { lat: 49.3942458, lng: 19.1525244 },
    { lat: 49.3942329, lng: 19.1525111 },
    { lat: 49.3942246, lng: 19.1524317 },
    { lat: 49.3942078, lng: 19.1524126 },
    { lat: 49.3939904, lng: 19.1524142 },
    { lat: 49.3939099, lng: 19.1523373 },
    { lat: 49.3938828, lng: 19.1522166 },
    { lat: 49.393805, lng: 19.1521954 },
    { lat: 49.3937054, lng: 19.1522303 },
    { lat: 49.3936189, lng: 19.1524243 },
    { lat: 49.3935332, lng: 19.1525368 },
    { lat: 49.3934084, lng: 19.1525206 },
    { lat: 49.3933199, lng: 19.1525564 },
    { lat: 49.3932883, lng: 19.152416 },
    { lat: 49.3932457, lng: 19.152376 },
    { lat: 49.393135, lng: 19.152508 },
    { lat: 49.3930624, lng: 19.1526867 },
    { lat: 49.3928749, lng: 19.1527702 },
    { lat: 49.3928096, lng: 19.1527234 },
    { lat: 49.3925614, lng: 19.1527426 },
    { lat: 49.3925523, lng: 19.1528334 },
    { lat: 49.3925088, lng: 19.1528897 },
    { lat: 49.392485, lng: 19.1529796 },
    { lat: 49.3924694, lng: 19.1530082 },
    { lat: 49.3924219, lng: 19.1530281 },
    { lat: 49.3923375, lng: 19.1529628 },
    { lat: 49.3922636, lng: 19.1529477 },
    { lat: 49.392078, lng: 19.1529529 },
    { lat: 49.3919766, lng: 19.1530249 },
    { lat: 49.3919059, lng: 19.1531016 },
    { lat: 49.3918821, lng: 19.1530975 },
    { lat: 49.3918125, lng: 19.1530797 },
    { lat: 49.3916901, lng: 19.1529033 },
    { lat: 49.3915078, lng: 19.1528155 },
    { lat: 49.3912436, lng: 19.1528767 },
    { lat: 49.391055, lng: 19.1530346 },
    { lat: 49.3909212, lng: 19.1530372 },
    { lat: 49.3908234, lng: 19.1530219 },
    { lat: 49.3906883, lng: 19.1530445 },
    { lat: 49.3905118, lng: 19.1531938 },
    { lat: 49.3904426, lng: 19.153185 },
    { lat: 49.390303, lng: 19.153096 },
    { lat: 49.39008, lng: 19.1531015 },
    { lat: 49.3899181, lng: 19.1530584 },
    { lat: 49.389842, lng: 19.1530041 },
    { lat: 49.3897783, lng: 19.1528996 },
    { lat: 49.389769, lng: 19.152825 },
    { lat: 49.3897597, lng: 19.1526528 },
    { lat: 49.3897396, lng: 19.1526359 },
    { lat: 49.3895723, lng: 19.1526683 },
    { lat: 49.3894564, lng: 19.1525578 },
    { lat: 49.3892868, lng: 19.1523379 },
    { lat: 49.3892336, lng: 19.1522394 },
    { lat: 49.3890228, lng: 19.1523101 },
    { lat: 49.3888375, lng: 19.1522787 },
    { lat: 49.3887648, lng: 19.1523565 },
    { lat: 49.3886436, lng: 19.1524151 },
    { lat: 49.3884494, lng: 19.152477 },
    { lat: 49.3882868, lng: 19.1526345 },
    { lat: 49.3881837, lng: 19.1527392 },
    { lat: 49.3880935, lng: 19.1527842 },
    { lat: 49.3880559, lng: 19.1527299 },
    { lat: 49.3879531, lng: 19.1527275 },
    { lat: 49.3878795, lng: 19.1527887 },
    { lat: 49.387796, lng: 19.1528781 },
    { lat: 49.3877627, lng: 19.1529601 },
    { lat: 49.3876836, lng: 19.1531413 },
    { lat: 49.3875837, lng: 19.1533799 },
    { lat: 49.3875237, lng: 19.1533997 },
    { lat: 49.3874356, lng: 19.1534181 },
    { lat: 49.3873535, lng: 19.1534003 },
    { lat: 49.3873058, lng: 19.1533681 },
    { lat: 49.3872062, lng: 19.1534326 },
    { lat: 49.3871225, lng: 19.1533759 },
    { lat: 49.3870787, lng: 19.1534529 },
    { lat: 49.3869976, lng: 19.1536166 },
    { lat: 49.3869307, lng: 19.1537461 },
    { lat: 49.3868576, lng: 19.1538641 },
    { lat: 49.3868437, lng: 19.1539044 },
    { lat: 49.3868436, lng: 19.1539706 },
    { lat: 49.3868649, lng: 19.1539906 },
    { lat: 49.3870341, lng: 19.1540173 },
    { lat: 49.3870439, lng: 19.1540777 },
    { lat: 49.3870403, lng: 19.1540965 },
    { lat: 49.3869979, lng: 19.1542247 },
    { lat: 49.3869681, lng: 19.1543504 },
    { lat: 49.3868766, lng: 19.1544654 },
    { lat: 49.3868451, lng: 19.1546991 },
    { lat: 49.3868248, lng: 19.1547227 },
    { lat: 49.3867507, lng: 19.1546552 },
    { lat: 49.3866839, lng: 19.154602 },
    { lat: 49.3866349, lng: 19.1545967 },
    { lat: 49.3865818, lng: 19.1546596 },
    { lat: 49.3865745, lng: 19.1546948 },
    { lat: 49.3865989, lng: 19.1547108 },
    { lat: 49.3866356, lng: 19.1547475 },
    { lat: 49.3866278, lng: 19.1547712 },
    { lat: 49.3864982, lng: 19.1551669 },
    { lat: 49.3864583, lng: 19.155157 },
    { lat: 49.3863883, lng: 19.1549942 },
    { lat: 49.3863683, lng: 19.1549769 },
    { lat: 49.3863289, lng: 19.1550252 },
    { lat: 49.3863142, lng: 19.1550688 },
    { lat: 49.3863437, lng: 19.1551414 },
    { lat: 49.3863627, lng: 19.1552547 },
    { lat: 49.3864145, lng: 19.1554669 },
    { lat: 49.3864749, lng: 19.1555472 },
    { lat: 49.3865222, lng: 19.1556866 },
    { lat: 49.3864911, lng: 19.1557646 },
    { lat: 49.3864544, lng: 19.1559139 },
    { lat: 49.386468, lng: 19.1560588 },
    { lat: 49.3864867, lng: 19.1560925 },
    { lat: 49.3864923, lng: 19.1561172 },
    { lat: 49.3864803, lng: 19.1563317 },
    { lat: 49.3864739, lng: 19.1564095 },
    { lat: 49.3864444, lng: 19.1564783 },
    { lat: 49.3864868, lng: 19.1565831 },
    { lat: 49.3865564, lng: 19.1567669 },
    { lat: 49.3865781, lng: 19.1568137 },
    { lat: 49.3865347, lng: 19.1568978 },
    { lat: 49.3863848, lng: 19.1569196 },
    { lat: 49.3862615, lng: 19.1569329 },
    { lat: 49.3861851, lng: 19.1569654 },
    { lat: 49.3861676, lng: 19.1570427 },
    { lat: 49.3861305, lng: 19.157209 },
    { lat: 49.386096, lng: 19.1574028 },
    { lat: 49.3860155, lng: 19.1575111 },
    { lat: 49.3859913, lng: 19.1575917 },
    { lat: 49.3860167, lng: 19.157676 },
    { lat: 49.3860557, lng: 19.157852 },
    { lat: 49.3860538, lng: 19.1580223 },
    { lat: 49.3860251, lng: 19.1581721 },
    { lat: 49.385962, lng: 19.1584046 },
    { lat: 49.3859123, lng: 19.1585195 },
    { lat: 49.3858717, lng: 19.1585871 },
    { lat: 49.3857646, lng: 19.1587263 },
    { lat: 49.3856925, lng: 19.1588665 },
    { lat: 49.3855939, lng: 19.1589277 },
    { lat: 49.385524, lng: 19.1589038 },
    { lat: 49.3855027, lng: 19.1589076 },
    { lat: 49.3854862, lng: 19.1589124 },
    { lat: 49.3854664, lng: 19.158923 },
    { lat: 49.385423, lng: 19.1589348 },
    { lat: 49.3853805, lng: 19.1589888 },
    { lat: 49.3853412, lng: 19.1591087 },
    { lat: 49.3853489, lng: 19.1593357 },
    { lat: 49.3853216, lng: 19.1596796 },
    { lat: 49.3853146, lng: 19.1598371 },
    { lat: 49.3852585, lng: 19.1600052 },
    { lat: 49.3852166, lng: 19.1600707 },
    { lat: 49.3851711, lng: 19.160111 },
    { lat: 49.3851086, lng: 19.1601228 },
    { lat: 49.3849883, lng: 19.1600149 },
    { lat: 49.3849775, lng: 19.1600208 },
    { lat: 49.3849125, lng: 19.1600539 },
    { lat: 49.3848383, lng: 19.1601488 },
    { lat: 49.3848021, lng: 19.1602647 },
    { lat: 49.384771, lng: 19.1604574 },
    { lat: 49.3847189, lng: 19.1606392 },
    { lat: 49.3847125, lng: 19.1608335 },
    { lat: 49.3847503, lng: 19.1609406 },
    { lat: 49.3848085, lng: 19.1611872 },
    { lat: 49.384822, lng: 19.1615836 },
    { lat: 49.3848617, lng: 19.1620266 },
    { lat: 49.3848619, lng: 19.1623572 },
    { lat: 49.3848938, lng: 19.1628319 },
    { lat: 49.3849826, lng: 19.1630124 },
    { lat: 49.3851788, lng: 19.163133 },
    { lat: 49.3852008, lng: 19.1632115 },
    { lat: 49.3851851, lng: 19.163239 },
    { lat: 49.3851617, lng: 19.163267 },
    { lat: 49.3849618, lng: 19.1633964 },
    { lat: 49.3850172, lng: 19.1635649 },
    { lat: 49.3850822, lng: 19.1637901 },
    { lat: 49.3851878, lng: 19.1639235 },
    { lat: 49.3852594, lng: 19.1640265 },
    { lat: 49.3853237, lng: 19.1642858 },
    { lat: 49.3853395, lng: 19.1645202 },
    { lat: 49.3854007, lng: 19.1650812 },
    { lat: 49.385485, lng: 19.1652421 },
    { lat: 49.38556, lng: 19.1652791 },
    { lat: 49.3856091, lng: 19.1652938 },
    { lat: 49.3856401, lng: 19.165397 },
    { lat: 49.385637, lng: 19.1655866 },
    { lat: 49.3856678, lng: 19.1657357 },
    { lat: 49.385686, lng: 19.1658773 },
    { lat: 49.3856761, lng: 19.1659302 },
    { lat: 49.3856087, lng: 19.1659573 },
    { lat: 49.3855671, lng: 19.1659371 },
    { lat: 49.3854117, lng: 19.1659385 },
    { lat: 49.3852786, lng: 19.1659362 },
    { lat: 49.3851336, lng: 19.1659932 },
    { lat: 49.385079, lng: 19.1660273 },
    { lat: 49.3850673, lng: 19.1660883 },
    { lat: 49.3851425, lng: 19.1661774 },
    { lat: 49.3852111, lng: 19.1664044 },
    { lat: 49.385264, lng: 19.1664972 },
    { lat: 49.3853988, lng: 19.1666533 },
    { lat: 49.3855836, lng: 19.1669087 },
    { lat: 49.3857127, lng: 19.1669903 },
    { lat: 49.3857892, lng: 19.1670779 },
    { lat: 49.3858266, lng: 19.1672256 },
    { lat: 49.3859141, lng: 19.1672623 },
    { lat: 49.3859403, lng: 19.1671298 },
    { lat: 49.3859908, lng: 19.1671002 },
    { lat: 49.3860032, lng: 19.1671028 },
    { lat: 49.3860199, lng: 19.1671396 },
    { lat: 49.3860564, lng: 19.167244 },
    { lat: 49.3861091, lng: 19.1673765 },
    { lat: 49.3861882, lng: 19.16764 },
    { lat: 49.386298, lng: 19.1678605 },
    { lat: 49.3863424, lng: 19.1681908 },
    { lat: 49.3863252, lng: 19.1684218 },
    { lat: 49.3863594, lng: 19.168568 },
    { lat: 49.3864424, lng: 19.1686291 },
    { lat: 49.3864817, lng: 19.1686746 },
    { lat: 49.3864951, lng: 19.1687661 },
    { lat: 49.3864802, lng: 19.1688784 },
    { lat: 49.3864527, lng: 19.1689575 },
    { lat: 49.3863998, lng: 19.1689834 },
    { lat: 49.3863205, lng: 19.1689545 },
    { lat: 49.3861821, lng: 19.1690512 },
    { lat: 49.3861632, lng: 19.1691015 },
    { lat: 49.3861707, lng: 19.1691386 },
    { lat: 49.3862197, lng: 19.1692231 },
    { lat: 49.3864156, lng: 19.1693821 },
    { lat: 49.3865585, lng: 19.1694462 },
    { lat: 49.3866818, lng: 19.1695491 },
    { lat: 49.3868529, lng: 19.169869 },
    { lat: 49.3869636, lng: 19.1699697 },
    { lat: 49.3870722, lng: 19.1700474 },
    { lat: 49.3872481, lng: 19.1702362 },
    { lat: 49.3873629, lng: 19.1704175 },
    { lat: 49.3874291, lng: 19.1707128 },
    { lat: 49.3874184, lng: 19.1708667 },
    { lat: 49.3873572, lng: 19.1709977 },
    { lat: 49.3873133, lng: 19.1711159 },
    { lat: 49.3872747, lng: 19.1713196 },
    { lat: 49.3872542, lng: 19.1714323 },
    { lat: 49.387259, lng: 19.1714824 },
    { lat: 49.3872762, lng: 19.1715159 },
    { lat: 49.3873488, lng: 19.1715728 },
    { lat: 49.3875006, lng: 19.1716341 },
    { lat: 49.3876319, lng: 19.1717183 },
    { lat: 49.3877643, lng: 19.1719102 },
    { lat: 49.3877817, lng: 19.1719672 },
    { lat: 49.3877158, lng: 19.1722366 },
    { lat: 49.3877565, lng: 19.1723803 },
    { lat: 49.3878336, lng: 19.1725764 },
    { lat: 49.3878958, lng: 19.1729068 },
    { lat: 49.3878419, lng: 19.1732874 },
    { lat: 49.3878592, lng: 19.1734591 },
    { lat: 49.3879236, lng: 19.174044 },
    { lat: 49.3879536, lng: 19.1742197 },
    { lat: 49.3879772, lng: 19.1742876 },
    { lat: 49.3881534, lng: 19.1747419 },
    { lat: 49.3882552, lng: 19.1751931 },
    { lat: 49.3883224, lng: 19.1759517 },
    { lat: 49.3883149, lng: 19.1762858 },
    { lat: 49.3883883, lng: 19.1768729 },
    { lat: 49.3882338, lng: 19.1773166 },
    { lat: 49.3881982, lng: 19.1772787 },
    { lat: 49.3881963, lng: 19.1772391 },
    { lat: 49.3881802, lng: 19.1770666 },
    { lat: 49.3881111, lng: 19.1766873 },
    { lat: 49.3880643, lng: 19.1764672 },
    { lat: 49.3880337, lng: 19.1764392 },
    { lat: 49.3879927, lng: 19.1764992 },
    { lat: 49.3879458, lng: 19.1766496 },
    { lat: 49.3879651, lng: 19.1770237 },
    { lat: 49.3879413, lng: 19.17728 },
    { lat: 49.3877658, lng: 19.1780071 },
    { lat: 49.3877384, lng: 19.1780949 },
    { lat: 49.3878619, lng: 19.178247 },
    { lat: 49.3878747, lng: 19.1782813 },
    { lat: 49.3878349, lng: 19.1784609 },
    { lat: 49.3877284, lng: 19.178494 },
    { lat: 49.3875653, lng: 19.1785005 },
    { lat: 49.3875113, lng: 19.178269 },
    { lat: 49.3874356, lng: 19.1780547 },
    { lat: 49.3874049, lng: 19.1778165 },
    { lat: 49.3873621, lng: 19.1778156 },
    { lat: 49.3873179, lng: 19.1779754 },
    { lat: 49.3872973, lng: 19.1780371 },
    { lat: 49.3872148, lng: 19.1782465 },
    { lat: 49.3871601, lng: 19.1786308 },
    { lat: 49.3870404, lng: 19.1788549 },
    { lat: 49.3868538, lng: 19.1790992 },
    { lat: 49.3866519, lng: 19.1791207 },
    { lat: 49.3866885, lng: 19.1793893 },
    { lat: 49.3866683, lng: 19.1796944 },
    { lat: 49.3866944, lng: 19.1798817 },
    { lat: 49.3866901, lng: 19.1799354 },
    { lat: 49.3866105, lng: 19.1801311 },
    { lat: 49.3865063, lng: 19.1802811 },
    { lat: 49.386402, lng: 19.1804101 },
    { lat: 49.3861387, lng: 19.1805575 },
    { lat: 49.3859377, lng: 19.1809004 },
    { lat: 49.3858106, lng: 19.1812317 },
    { lat: 49.3856034, lng: 19.1816806 },
    { lat: 49.3854604, lng: 19.1821031 },
    { lat: 49.3854318, lng: 19.1823042 },
    { lat: 49.3854608, lng: 19.1827437 },
    { lat: 49.385479, lng: 19.182792 },
    { lat: 49.3855326, lng: 19.1828507 },
    { lat: 49.3856251, lng: 19.1828792 },
    { lat: 49.3856616, lng: 19.1830524 },
    { lat: 49.3856436, lng: 19.1831206 },
    { lat: 49.3855322, lng: 19.1831962 },
    { lat: 49.3854483, lng: 19.1833504 },
    { lat: 49.3853701, lng: 19.1835745 },
    { lat: 49.3853087, lng: 19.1838632 },
    { lat: 49.3853528, lng: 19.1841278 },
    { lat: 49.3854119, lng: 19.1842509 },
    { lat: 49.3854635, lng: 19.1844828 },
    { lat: 49.3854753, lng: 19.1846797 },
    { lat: 49.3855425, lng: 19.1848334 },
    { lat: 49.3855961, lng: 19.1848726 },
    { lat: 49.3857083, lng: 19.1848389 },
    { lat: 49.3858257, lng: 19.1848886 },
    { lat: 49.3859245, lng: 19.1850402 },
    { lat: 49.3861116, lng: 19.1853695 },
    { lat: 49.386314, lng: 19.1856404 },
    { lat: 49.3864042, lng: 19.1858509 },
    { lat: 49.3864168, lng: 19.1859015 },
    { lat: 49.3865505, lng: 19.1862923 },
    { lat: 49.3866217, lng: 19.1866232 },
    { lat: 49.3866732, lng: 19.1868493 },
    { lat: 49.386753, lng: 19.1871036 },
    { lat: 49.3868477, lng: 19.1873386 },
    { lat: 49.3871259, lng: 19.187378 },
    { lat: 49.3872222, lng: 19.1875277 },
    { lat: 49.3872439, lng: 19.187747 },
    { lat: 49.3872344, lng: 19.1879225 },
    { lat: 49.3873068, lng: 19.1880684 },
    { lat: 49.3875669, lng: 19.1884376 },
    { lat: 49.387545, lng: 19.1885219 },
    { lat: 49.3873905, lng: 19.1887752 },
    { lat: 49.3873765, lng: 19.1889555 },
    { lat: 49.3874227, lng: 19.189023 },
    { lat: 49.3874797, lng: 19.1893668 },
    { lat: 49.3876184, lng: 19.1897168 },
    { lat: 49.3877015, lng: 19.1898276 },
    { lat: 49.3877836, lng: 19.189777 },
    { lat: 49.3877789, lng: 19.1895373 },
    { lat: 49.3878344, lng: 19.1894301 },
    { lat: 49.3878689, lng: 19.1894208 },
    { lat: 49.3879697, lng: 19.1894792 },
    { lat: 49.3880939, lng: 19.1896439 },
    { lat: 49.3881202, lng: 19.1900287 },
    { lat: 49.3881908, lng: 19.1903706 },
    { lat: 49.388285, lng: 19.190708 },
    { lat: 49.3883514, lng: 19.1908962 },
    { lat: 49.3881576, lng: 19.1911283 },
    { lat: 49.3881505, lng: 19.1912405 },
    { lat: 49.3881873, lng: 19.1913963 },
    { lat: 49.3882751, lng: 19.1915133 },
    { lat: 49.3886281, lng: 19.19182 },
    { lat: 49.3888563, lng: 19.1923463 },
    { lat: 49.3890504, lng: 19.1928663 },
    { lat: 49.389159, lng: 19.1931301 },
    { lat: 49.3893882, lng: 19.1935141 },
    { lat: 49.3896336, lng: 19.1938394 },
    { lat: 49.3897048, lng: 19.1938853 },
    { lat: 49.390155, lng: 19.1942259 },
    { lat: 49.3902181, lng: 19.1943186 },
    { lat: 49.3903619, lng: 19.1952014 },
    { lat: 49.3904244, lng: 19.1956321 },
    { lat: 49.3904109, lng: 19.1959339 },
    { lat: 49.3903642, lng: 19.1962304 },
    { lat: 49.390368, lng: 19.1965455 },
    { lat: 49.3903896, lng: 19.1969009 },
    { lat: 49.3902609, lng: 19.1976419 },
    { lat: 49.390203, lng: 19.1979588 },
    { lat: 49.3901639, lng: 19.1985555 },
    { lat: 49.3901134, lng: 19.1987924 },
    { lat: 49.3900433, lng: 19.1989552 },
    { lat: 49.3899055, lng: 19.1990854 },
    { lat: 49.3896961, lng: 19.1991156 },
    { lat: 49.3895773, lng: 19.1992006 },
    { lat: 49.3895084, lng: 19.1996671 },
    { lat: 49.3895992, lng: 19.1998913 },
    { lat: 49.3896761, lng: 19.2000596 },
    { lat: 49.3896691, lng: 19.2002644 },
    { lat: 49.3893982, lng: 19.2005822 },
    { lat: 49.3892675, lng: 19.2008206 },
    { lat: 49.3889373, lng: 19.2010065 },
    { lat: 49.388884, lng: 19.2011153 },
    { lat: 49.3888592, lng: 19.2015378 },
    { lat: 49.3886904, lng: 19.2018925 },
    { lat: 49.3886362, lng: 19.2021016 },
    { lat: 49.388545, lng: 19.2026466 },
    { lat: 49.3885656, lng: 19.2028885 },
    { lat: 49.3886627, lng: 19.2038261 },
    { lat: 49.3887643, lng: 19.2041565 },
    { lat: 49.3889558, lng: 19.2043921 },
    { lat: 49.3891106, lng: 19.2046362 },
    { lat: 49.3891545, lng: 19.204822 },
    { lat: 49.3891304, lng: 19.2050226 },
    { lat: 49.3890286, lng: 19.2054852 },
    { lat: 49.3889646, lng: 19.2057695 },
    { lat: 49.3889313, lng: 19.2062765 },
    { lat: 49.3888466, lng: 19.2069517 },
    { lat: 49.3888705, lng: 19.2071191 },
    { lat: 49.3888708, lng: 19.2071932 },
    { lat: 49.3887213, lng: 19.2079058 },
    { lat: 49.3886779, lng: 19.2085069 },
    { lat: 49.3883781, lng: 19.2092331 },
    { lat: 49.3883562, lng: 19.2096237 },
    { lat: 49.3884589, lng: 19.2101639 },
    { lat: 49.3884164, lng: 19.2106661 },
    { lat: 49.3882752, lng: 19.2111961 },
    { lat: 49.3882425, lng: 19.2116911 },
    { lat: 49.3882931, lng: 19.2120414 },
    { lat: 49.3883955, lng: 19.2123409 },
    { lat: 49.3883396, lng: 19.2125629 },
    { lat: 49.3881461, lng: 19.2128755 },
    { lat: 49.388057, lng: 19.2131305 },
    { lat: 49.3880162, lng: 19.2132443 },
    { lat: 49.3879228, lng: 19.2136709 },
    { lat: 49.3878343, lng: 19.2141501 },
    { lat: 49.3877846, lng: 19.2141955 },
    { lat: 49.3875842, lng: 19.2144843 },
    { lat: 49.3873747, lng: 19.2146956 },
    { lat: 49.3872631, lng: 19.2148098 },
    { lat: 49.3870918, lng: 19.2149311 },
    { lat: 49.3869864, lng: 19.2150146 },
    { lat: 49.3867624, lng: 19.2150909 },
    { lat: 49.3865968, lng: 19.2151381 },
    { lat: 49.3863856, lng: 19.215197 },
    { lat: 49.3861202, lng: 19.2153491 },
    { lat: 49.3857578, lng: 19.2156417 },
    { lat: 49.3853663, lng: 19.2161021 },
    { lat: 49.3851266, lng: 19.2160416 },
    { lat: 49.3848836, lng: 19.2159087 },
    { lat: 49.3845677, lng: 19.2158079 },
    { lat: 49.3843398, lng: 19.2158266 },
    { lat: 49.3840617, lng: 19.2160652 },
    { lat: 49.3838206, lng: 19.2163705 },
    { lat: 49.3834433, lng: 19.2168952 },
    { lat: 49.3827513, lng: 19.2178604 },
    { lat: 49.3825952, lng: 19.2181988 },
    { lat: 49.382334, lng: 19.2188634 },
    { lat: 49.3824378, lng: 19.2188673 },
    { lat: 49.3825464, lng: 19.218871 },
    { lat: 49.3825465, lng: 19.2188738 },
    { lat: 49.38253, lng: 19.2190956 },
    { lat: 49.3825055, lng: 19.2194319 },
    { lat: 49.3825038, lng: 19.2194643 },
    { lat: 49.3824863, lng: 19.2197115 },
    { lat: 49.3824854, lng: 19.2199044 },
    { lat: 49.3825108, lng: 19.2202716 },
    { lat: 49.3825135, lng: 19.2205831 },
    { lat: 49.3825156, lng: 19.2208629 },
    { lat: 49.3825225, lng: 19.2214325 },
    { lat: 49.3825371, lng: 19.222018 },
    { lat: 49.382563, lng: 19.2222759 },
    { lat: 49.382554, lng: 19.2229801 },
    { lat: 49.3825675, lng: 19.2234506 },
    { lat: 49.3826393, lng: 19.2240314 },
    { lat: 49.382808, lng: 19.2242852 },
    { lat: 49.3825592, lng: 19.2245494 },
    { lat: 49.3828672, lng: 19.2250729 },
    { lat: 49.3829173, lng: 19.225157 },
    { lat: 49.3831317, lng: 19.225512 },
    { lat: 49.3833534, lng: 19.2261914 },
    { lat: 49.3834169, lng: 19.2265991 },
    { lat: 49.383464, lng: 19.2270395 },
    { lat: 49.3835481, lng: 19.2279243 },
    { lat: 49.3836683, lng: 19.2291425 },
    { lat: 49.3836852, lng: 19.2293287 },
    { lat: 49.3836519, lng: 19.2303058 },
    { lat: 49.3835596, lng: 19.2310161 },
    { lat: 49.383131, lng: 19.2327752 },
    { lat: 49.3830275, lng: 19.2330585 },
    { lat: 49.3829206, lng: 19.23351 },
    { lat: 49.3829005, lng: 19.2341514 },
    { lat: 49.3828956, lng: 19.2343799 },
    { lat: 49.382894, lng: 19.2344896 },
    { lat: 49.3828103, lng: 19.2353777 },
    { lat: 49.3827831, lng: 19.2355142 },
    { lat: 49.382768, lng: 19.2355054 },
    { lat: 49.3827113, lng: 19.2354749 },
    { lat: 49.3827061, lng: 19.2354721 },
    { lat: 49.3826696, lng: 19.2354528 },
    { lat: 49.3825746, lng: 19.2353961 },
    { lat: 49.3825288, lng: 19.2356632 },
    { lat: 49.3824416, lng: 19.2360325 },
    { lat: 49.382314, lng: 19.2365 },
    { lat: 49.3821678, lng: 19.2369306 },
    { lat: 49.3821135, lng: 19.2370967 },
    { lat: 49.3819708, lng: 19.2374273 },
    { lat: 49.3818034, lng: 19.2378204 },
    { lat: 49.3816163, lng: 19.2381748 },
    { lat: 49.3813884, lng: 19.2385577 },
    { lat: 49.3812149, lng: 19.2387574 },
    { lat: 49.3808325, lng: 19.2389851 },
    { lat: 49.3808282, lng: 19.2389878 },
    { lat: 49.3804459, lng: 19.2391521 },
    { lat: 49.3801766, lng: 19.2392449 },
    { lat: 49.3799347, lng: 19.2393968 },
    { lat: 49.379533, lng: 19.2397377 },
    { lat: 49.3793386, lng: 19.2400076 },
    { lat: 49.3792243, lng: 19.2403536 },
    { lat: 49.3791179, lng: 19.2408599 },
    { lat: 49.3790385, lng: 19.2413491 },
    { lat: 49.3789944, lng: 19.2417465 },
    { lat: 49.3789262, lng: 19.241969 },
    { lat: 49.3787019, lng: 19.2423222 },
    { lat: 49.3785775, lng: 19.2425007 },
    { lat: 49.3783581, lng: 19.242722 },
    { lat: 49.3780801, lng: 19.2430132 },
    { lat: 49.3777839, lng: 19.2433484 },
    { lat: 49.3777511, lng: 19.2433948 },
    { lat: 49.3775706, lng: 19.2436251 },
    { lat: 49.377449, lng: 19.2438377 },
    { lat: 49.3773982, lng: 19.2439555 },
    { lat: 49.3772785, lng: 19.2441642 },
    { lat: 49.377101, lng: 19.2444575 },
    { lat: 49.376956, lng: 19.2446998 },
    { lat: 49.3767881, lng: 19.244988 },
    { lat: 49.3766505, lng: 19.2452223 },
    { lat: 49.3765419, lng: 19.2455194 },
    { lat: 49.3764965, lng: 19.2457712 },
    { lat: 49.376473, lng: 19.2462226 },
    { lat: 49.3764793, lng: 19.2465677 },
    { lat: 49.3765107, lng: 19.2468951 },
    { lat: 49.3765709, lng: 19.2472602 },
    { lat: 49.3766198, lng: 19.2475527 },
    { lat: 49.3766968, lng: 19.2479141 },
    { lat: 49.3767628, lng: 19.2483516 },
    { lat: 49.3768489, lng: 19.2487163 },
    { lat: 49.3768503, lng: 19.2487914 },
    { lat: 49.3768311, lng: 19.2490521 },
    { lat: 49.3767858, lng: 19.2493548 },
    { lat: 49.3766576, lng: 19.249643 },
    { lat: 49.3764511, lng: 19.2498288 },
    { lat: 49.376285, lng: 19.24991 },
    { lat: 49.376105, lng: 19.2499203 },
    { lat: 49.3759469, lng: 19.2499362 },
    { lat: 49.3757653, lng: 19.2499066 },
    { lat: 49.375599, lng: 19.2499411 },
    { lat: 49.375438, lng: 19.2500666 },
    { lat: 49.3753671, lng: 19.2504655 },
    { lat: 49.3753579, lng: 19.2508433 },
    { lat: 49.3753818, lng: 19.2512244 },
    { lat: 49.3753752, lng: 19.2515608 },
    { lat: 49.375401, lng: 19.2520534 },
    { lat: 49.3753311, lng: 19.2524789 },
    { lat: 49.3753323, lng: 19.2525044 },
    { lat: 49.3752529, lng: 19.2528945 },
    { lat: 49.3751839, lng: 19.2531963 },
    { lat: 49.3751843, lng: 19.2532288 },
    { lat: 49.3748626, lng: 19.255017 },
    { lat: 49.3747044, lng: 19.2561178 },
    { lat: 49.3746205, lng: 19.2566766 },
    { lat: 49.3745697, lng: 19.2569332 },
    { lat: 49.3744114, lng: 19.2576316 },
    { lat: 49.3742346, lng: 19.2582285 },
    { lat: 49.3741429, lng: 19.2586673 },
    { lat: 49.373945, lng: 19.2597643 },
    { lat: 49.3738427, lng: 19.2604581 },
    { lat: 49.373794, lng: 19.2610907 },
    { lat: 49.3737994, lng: 19.2616568 },
    { lat: 49.3738426, lng: 19.2621123 },
    { lat: 49.3738871, lng: 19.2624083 },
    { lat: 49.3739447, lng: 19.2627892 },
    { lat: 49.3740518, lng: 19.263383 },
    { lat: 49.3742362, lng: 19.2642246 },
    { lat: 49.3744258, lng: 19.2648255 },
    { lat: 49.3744724, lng: 19.2650463 },
    { lat: 49.3744864, lng: 19.2657221 },
    { lat: 49.3745621, lng: 19.2659629 },
    { lat: 49.3746089, lng: 19.266094 },
    { lat: 49.3746492, lng: 19.2665127 },
    { lat: 49.3745837, lng: 19.2671272 },
    { lat: 49.3745216, lng: 19.2675254 },
    { lat: 49.3743943, lng: 19.2684282 },
    { lat: 49.3742659, lng: 19.2687637 },
    { lat: 49.3743481, lng: 19.2697827 },
    { lat: 49.3744638, lng: 19.2703426 },
    { lat: 49.3746185, lng: 19.2710339 },
    { lat: 49.3746625, lng: 19.2712745 },
    { lat: 49.3748179, lng: 19.2720301 },
    { lat: 49.3749326, lng: 19.2725963 },
    { lat: 49.3750487, lng: 19.273072 },
    { lat: 49.3752466, lng: 19.2739114 },
    { lat: 49.3753063, lng: 19.2740809 },
    { lat: 49.3756017, lng: 19.2746523 },
    { lat: 49.3757457, lng: 19.2749332 },
    { lat: 49.3758551, lng: 19.2750556 },
    { lat: 49.3760209, lng: 19.2752451 },
    { lat: 49.3760896, lng: 19.2753636 },
    { lat: 49.3762112, lng: 19.2755769 },
    { lat: 49.376306, lng: 19.2757421 },
    { lat: 49.3763639, lng: 19.2759425 },
    { lat: 49.3763852, lng: 19.2760584 },
    { lat: 49.3764698, lng: 19.2764436 },
    { lat: 49.3765315, lng: 19.2767922 },
    { lat: 49.3765576, lng: 19.2773861 },
    { lat: 49.3765619, lng: 19.2774767 },
    { lat: 49.376566, lng: 19.2775403 },
    { lat: 49.3765957, lng: 19.277907 },
    { lat: 49.3765927, lng: 19.2780581 },
    { lat: 49.3765646, lng: 19.2783213 },
    { lat: 49.3765923, lng: 19.2785967 },
    { lat: 49.3766112, lng: 19.2787805 },
    { lat: 49.3765589, lng: 19.2790319 },
    { lat: 49.3764704, lng: 19.2794211 },
    { lat: 49.3764297, lng: 19.2795135 },
    { lat: 49.376369, lng: 19.2796577 },
    { lat: 49.3763126, lng: 19.2798025 },
    { lat: 49.3762108, lng: 19.2799827 },
    { lat: 49.375893, lng: 19.2805302 },
    { lat: 49.3758562, lng: 19.2805611 },
    { lat: 49.3758506, lng: 19.2805638 },
    { lat: 49.3758222, lng: 19.2806513 },
    { lat: 49.3758179, lng: 19.2806554 },
    { lat: 49.3757461, lng: 19.2807845 },
    { lat: 49.375622, lng: 19.2810659 },
    { lat: 49.3754965, lng: 19.2815573 },
    { lat: 49.3754877, lng: 19.2817988 },
    { lat: 49.3754724, lng: 19.2819737 },
    { lat: 49.375449, lng: 19.2823571 },
    { lat: 49.3754157, lng: 19.2827012 },
    { lat: 49.3754059, lng: 19.2828039 },
    { lat: 49.3754014, lng: 19.2828536 },
    { lat: 49.3753392, lng: 19.2832041 },
    { lat: 49.3753146, lng: 19.2833981 },
    { lat: 49.3752832, lng: 19.2835922 },
    { lat: 49.3752709, lng: 19.2836673 },
    { lat: 49.3752662, lng: 19.2837568 },
    { lat: 49.3751562, lng: 19.284505 },
    { lat: 49.3750692, lng: 19.2847375 },
    { lat: 49.3750397, lng: 19.284876 },
    { lat: 49.3749341, lng: 19.2850013 },
    { lat: 49.3747912, lng: 19.2850989 },
    { lat: 49.3746135, lng: 19.2851769 },
    { lat: 49.3743715, lng: 19.2851839 },
    { lat: 49.3738924, lng: 19.2852028 },
    { lat: 49.3735363, lng: 19.2851834 },
    { lat: 49.3732845, lng: 19.2851221 },
    { lat: 49.3732193, lng: 19.2851029 },
    { lat: 49.3732076, lng: 19.2852384 },
    { lat: 49.3731918, lng: 19.2853338 },
    { lat: 49.3731514, lng: 19.2858573 },
    { lat: 49.3730891, lng: 19.2870645 },
    { lat: 49.373027, lng: 19.2875618 },
    { lat: 49.3730403, lng: 19.2885801 },
    { lat: 49.3730873, lng: 19.2892837 },
    { lat: 49.3731529, lng: 19.2904362 },
    { lat: 49.3734, lng: 19.2913201 },
    { lat: 49.3736726, lng: 19.2925588 },
    { lat: 49.3737114, lng: 19.2928301 },
    { lat: 49.3738615, lng: 19.2938814 },
    { lat: 49.3739761, lng: 19.2958049 },
    { lat: 49.3738224, lng: 19.2962498 },
    { lat: 49.3738218, lng: 19.2964708 },
    { lat: 49.3738829, lng: 19.2970488 },
    { lat: 49.3737851, lng: 19.2981019 },
    { lat: 49.3737502, lng: 19.2990312 },
    { lat: 49.3737772, lng: 19.299558 },
    { lat: 49.3736382, lng: 19.3004305 },
    { lat: 49.3732797, lng: 19.3022423 },
    { lat: 49.3733113, lng: 19.3023378 },
    { lat: 49.3733255, lng: 19.3023728 },
    { lat: 49.3733482, lng: 19.302448 },
    { lat: 49.3737495, lng: 19.303525 },
    { lat: 49.3738089, lng: 19.3036884 },
    { lat: 49.3740145, lng: 19.3042699 },
    { lat: 49.374379, lng: 19.3053282 },
    { lat: 49.3761122, lng: 19.3055667 },
    { lat: 49.3772272, lng: 19.3057202 },
    { lat: 49.3772572, lng: 19.3057368 },
    { lat: 49.3773517, lng: 19.3057564 },
    { lat: 49.3774672, lng: 19.3058427 },
    { lat: 49.377757, lng: 19.3059194 },
    { lat: 49.3778885, lng: 19.3060528 },
    { lat: 49.3781563, lng: 19.3062336 },
    { lat: 49.3783706, lng: 19.3062161 },
    { lat: 49.3784794, lng: 19.3061805 },
    { lat: 49.3785717, lng: 19.306205 },
    { lat: 49.3787061, lng: 19.306159 },
    { lat: 49.3788933, lng: 19.3062837 },
    { lat: 49.3790851, lng: 19.3062404 },
    { lat: 49.3792481, lng: 19.3063012 },
    { lat: 49.3793326, lng: 19.3062546 },
    { lat: 49.3793541, lng: 19.3062558 },
    { lat: 49.3794604, lng: 19.3062654 },
    { lat: 49.3795881, lng: 19.3062491 },
    { lat: 49.3797592, lng: 19.3063632 },
    { lat: 49.3798446, lng: 19.306382 },
    { lat: 49.3799348, lng: 19.3064819 },
    { lat: 49.3799909, lng: 19.3065448 },
    { lat: 49.380159, lng: 19.3065978 },
    { lat: 49.3801893, lng: 19.3065955 },
    { lat: 49.3802858, lng: 19.3065881 },
    { lat: 49.3807075, lng: 19.3068514 },
    { lat: 49.3809137, lng: 19.3070676 },
    { lat: 49.3811327, lng: 19.3071707 },
    { lat: 49.3813265, lng: 19.3073641 },
    { lat: 49.3814438, lng: 19.307482 },
    { lat: 49.3816815, lng: 19.3075765 },
    { lat: 49.3817278, lng: 19.3076011 },
    { lat: 49.3817474, lng: 19.3076108 },
    { lat: 49.3817574, lng: 19.3076083 },
    { lat: 49.3818997, lng: 19.3075719 },
    { lat: 49.382181, lng: 19.3074876 },
    { lat: 49.3823207, lng: 19.3076305 },
    { lat: 49.3824123, lng: 19.3076602 },
    { lat: 49.3824706, lng: 19.3076787 },
    { lat: 49.382507, lng: 19.3077101 },
    { lat: 49.3826667, lng: 19.3078476 },
    { lat: 49.3827455, lng: 19.3079163 },
    { lat: 49.3827609, lng: 19.3079308 },
    { lat: 49.3829519, lng: 19.3081115 },
    { lat: 49.383292, lng: 19.3081325 },
    { lat: 49.3835581, lng: 19.3082318 },
    { lat: 49.3836405, lng: 19.3083254 },
    { lat: 49.3837015, lng: 19.3084471 },
    { lat: 49.3837431, lng: 19.3085184 },
    { lat: 49.3837872, lng: 19.3085931 },
    { lat: 49.3837993, lng: 19.3086135 },
    { lat: 49.3838221, lng: 19.3086674 },
    { lat: 49.3838349, lng: 19.3086974 },
    { lat: 49.3838577, lng: 19.3087506 },
    { lat: 49.3838652, lng: 19.3087686 },
    { lat: 49.3839418, lng: 19.3090516 },
    { lat: 49.3839771, lng: 19.3091548 },
    { lat: 49.3840792, lng: 19.3094567 },
    { lat: 49.3842409, lng: 19.3097315 },
    { lat: 49.3844187, lng: 19.3100344 },
    { lat: 49.3845089, lng: 19.3102306 },
    { lat: 49.3845772, lng: 19.3106524 },
    { lat: 49.3846648, lng: 19.3111936 },
    { lat: 49.3849515, lng: 19.3119704 },
    { lat: 49.385195, lng: 19.3126173 },
    { lat: 49.3854202, lng: 19.3130655 },
    { lat: 49.3855813, lng: 19.3133516 },
    { lat: 49.385792, lng: 19.3138994 },
    { lat: 49.386006, lng: 19.3140157 },
    { lat: 49.3862803, lng: 19.3143149 },
    { lat: 49.3867163, lng: 19.3147677 },
    { lat: 49.3871034, lng: 19.3151839 },
    { lat: 49.3873157, lng: 19.3154564 },
    { lat: 49.3877136, lng: 19.3161084 },
    { lat: 49.3881326, lng: 19.3165312 },
    { lat: 49.3883785, lng: 19.3168025 },
    { lat: 49.3885023, lng: 19.3169396 },
    { lat: 49.3886575, lng: 19.3171504 },
    { lat: 49.3889345, lng: 19.3175018 },
    { lat: 49.3892683, lng: 19.3181565 },
    { lat: 49.3892783, lng: 19.3181746 },
    { lat: 49.3894335, lng: 19.3185503 },
    { lat: 49.389714, lng: 19.3191021 },
    { lat: 49.389889, lng: 19.3192727 },
    { lat: 49.3901647, lng: 19.3194122 },
    { lat: 49.3902551, lng: 19.3193697 },
    { lat: 49.3904073, lng: 19.3192979 },
    { lat: 49.3905496, lng: 19.3192304 },
    { lat: 49.3906884, lng: 19.3191652 },
    { lat: 49.3908838, lng: 19.3192716 },
    { lat: 49.3911478, lng: 19.3191115 },
    { lat: 49.3913837, lng: 19.3189954 },
    { lat: 49.3916928, lng: 19.3187149 },
    { lat: 49.3919871, lng: 19.3184352 },
    { lat: 49.3923976, lng: 19.3181772 },
    { lat: 49.3926053, lng: 19.3180203 },
    { lat: 49.3928448, lng: 19.3178398 },
    { lat: 49.393185, lng: 19.3177881 },
    { lat: 49.3932923, lng: 19.3177447 },
    { lat: 49.3938519, lng: 19.3175193 },
    { lat: 49.3938851, lng: 19.3175063 },
    { lat: 49.3942119, lng: 19.3173862 },
    { lat: 49.3943825, lng: 19.3172773 },
    { lat: 49.3945159, lng: 19.317192 },
    { lat: 49.3949112, lng: 19.316997 },
    { lat: 49.3949167, lng: 19.3169935 },
    { lat: 49.3949776, lng: 19.3170628 },
    { lat: 49.395324, lng: 19.3170737 },
    { lat: 49.3953342, lng: 19.3170744 },
    { lat: 49.3953387, lng: 19.317075 },
    { lat: 49.395761, lng: 19.3171158 },
    { lat: 49.3958864, lng: 19.3171269 },
    { lat: 49.3959049, lng: 19.3172074 },
    { lat: 49.3959077, lng: 19.3172183 },
    { lat: 49.3959185, lng: 19.3172558 },
    { lat: 49.3960267, lng: 19.3172771 },
    { lat: 49.3961199, lng: 19.3172951 },
    { lat: 49.3962471, lng: 19.3173197 },
    { lat: 49.3967426, lng: 19.3172599 },
    { lat: 49.3967683, lng: 19.3172592 },
    { lat: 49.3970914, lng: 19.3172524 },
    { lat: 49.3974149, lng: 19.3170607 },
    { lat: 49.3974762, lng: 19.3170243 },
    { lat: 49.3975245, lng: 19.3169954 },
    { lat: 49.3983442, lng: 19.3162152 },
    { lat: 49.3985642, lng: 19.3160291 },
    { lat: 49.3987277, lng: 19.3158909 },
    { lat: 49.3991235, lng: 19.3155588 },
    { lat: 49.3992415, lng: 19.3154589 },
    { lat: 49.3997559, lng: 19.3148495 },
    { lat: 49.4002749, lng: 19.314046 },
    { lat: 49.4003117, lng: 19.3139902 },
    { lat: 49.4005327, lng: 19.313563 },
    { lat: 49.4005191, lng: 19.3134647 },
    { lat: 49.4006941, lng: 19.3129945 },
    { lat: 49.4007868, lng: 19.312571 },
    { lat: 49.4011036, lng: 19.3119979 },
    { lat: 49.401636, lng: 19.3111751 },
    { lat: 49.4018559, lng: 19.3109402 },
    { lat: 49.4022585, lng: 19.3106803 },
    { lat: 49.4025664, lng: 19.3106644 },
    { lat: 49.4027156, lng: 19.3107437 },
    { lat: 49.4028485, lng: 19.3108141 },
    { lat: 49.4028878, lng: 19.310835 },
    { lat: 49.4029067, lng: 19.31088 },
    { lat: 49.4029831, lng: 19.3110644 },
    { lat: 49.4031839, lng: 19.3110943 },
    { lat: 49.4034188, lng: 19.3111291 },
    { lat: 49.4035722, lng: 19.3111523 },
    { lat: 49.4036607, lng: 19.311166 },
    { lat: 49.4038613, lng: 19.3111926 },
    { lat: 49.404054, lng: 19.3110931 },
    { lat: 49.4041771, lng: 19.3110292 },
    { lat: 49.4044632, lng: 19.3108821 },
    { lat: 49.404642, lng: 19.3109731 },
    { lat: 49.4048293, lng: 19.3108094 },
    { lat: 49.4053776, lng: 19.3101288 },
    { lat: 49.4054182, lng: 19.3100816 },
    { lat: 49.4054928, lng: 19.3099927 },
    { lat: 49.4058957, lng: 19.309503 },
    { lat: 49.4061258, lng: 19.3092698 },
    { lat: 49.406186, lng: 19.3092095 },
    { lat: 49.4067404, lng: 19.3087512 },
    { lat: 49.4068141, lng: 19.3087379 },
    { lat: 49.4070729, lng: 19.3088475 },
    { lat: 49.40719, lng: 19.3088975 },
    { lat: 49.4075421, lng: 19.3091669 },
    { lat: 49.4077409, lng: 19.3089863 },
    { lat: 49.4082854, lng: 19.3086344 },
    { lat: 49.4083117, lng: 19.308617 },
    { lat: 49.408463, lng: 19.3084801 },
    { lat: 49.4085366, lng: 19.3084144 },
    { lat: 49.4085351, lng: 19.3084066 },
    { lat: 49.4085322, lng: 19.3083711 },
    { lat: 49.4089116, lng: 19.3080037 },
    { lat: 49.4090738, lng: 19.3078569 },
    { lat: 49.4090899, lng: 19.3078432 },
    { lat: 49.4097386, lng: 19.3073081 },
    { lat: 49.4097397, lng: 19.3073069 },
    { lat: 49.4097461, lng: 19.3073026 },
    { lat: 49.4098955, lng: 19.3071966 },
    { lat: 49.4099927, lng: 19.307129 },
    { lat: 49.4101028, lng: 19.3070998 },
    { lat: 49.4101618, lng: 19.3070835 },
    { lat: 49.410478, lng: 19.3069989 },
    { lat: 49.4106557, lng: 19.3070169 },
    { lat: 49.4111867, lng: 19.3070699 },
    { lat: 49.4112825, lng: 19.3070929 },
    { lat: 49.4115127, lng: 19.3071492 },
    { lat: 49.4118092, lng: 19.3072213 },
    { lat: 49.4120177, lng: 19.3072713 },
    { lat: 49.412072, lng: 19.3073001 },
    { lat: 49.4120952, lng: 19.3073116 },
    { lat: 49.4123908, lng: 19.3074667 },
    { lat: 49.4126852, lng: 19.3076397 },
    { lat: 49.413046, lng: 19.3078353 },
    { lat: 49.4130865, lng: 19.3078576 },
    { lat: 49.4132618, lng: 19.3079196 },
    { lat: 49.4134296, lng: 19.3079173 },
    { lat: 49.4135675, lng: 19.3079227 },
    { lat: 49.4137302, lng: 19.3079079 },
    { lat: 49.4140865, lng: 19.3078178 },
    { lat: 49.4141867, lng: 19.3077926 },
    { lat: 49.4142392, lng: 19.3077791 },
    { lat: 49.414793, lng: 19.3076012 },
    { lat: 49.4154114, lng: 19.307119 },
    { lat: 49.4154353, lng: 19.3071001 },
    { lat: 49.4154875, lng: 19.3070599 },
    { lat: 49.4158407, lng: 19.3067848 },
    { lat: 49.4161339, lng: 19.3065728 },
    { lat: 49.4166183, lng: 19.3059719 },
    { lat: 49.4166363, lng: 19.3059496 },
    { lat: 49.4166981, lng: 19.3058729 },
    { lat: 49.4168325, lng: 19.3058287 },
    { lat: 49.4173423, lng: 19.3059119 },
    { lat: 49.417506, lng: 19.3058945 },
    { lat: 49.4175536, lng: 19.3058773 },
    { lat: 49.4178042, lng: 19.3057887 },
    { lat: 49.4183879, lng: 19.3054771 },
    { lat: 49.4191416, lng: 19.3049954 },
    { lat: 49.4192202, lng: 19.3049447 },
    { lat: 49.4192707, lng: 19.3049127 },
    { lat: 49.4197012, lng: 19.3046786 },
    { lat: 49.4201033, lng: 19.3043632 },
    { lat: 49.4204839, lng: 19.3041654 },
    { lat: 49.4205124, lng: 19.3041503 },
    { lat: 49.4205302, lng: 19.3041414 },
    { lat: 49.4214003, lng: 19.3037637 },
    { lat: 49.4217724, lng: 19.3036743 },
    { lat: 49.422002, lng: 19.3036188 },
    { lat: 49.4223122, lng: 19.3034831 },
    { lat: 49.4223189, lng: 19.3034802 },
    { lat: 49.4224389, lng: 19.3034466 },
    { lat: 49.4226524, lng: 19.3033867 },
    { lat: 49.4226891, lng: 19.3033729 },
    { lat: 49.4227766, lng: 19.30334 },
    { lat: 49.4231509, lng: 19.3032008 },
    { lat: 49.4234488, lng: 19.3031829 },
    { lat: 49.4235769, lng: 19.3031753 },
    { lat: 49.425565, lng: 19.3024675 },
    { lat: 49.4254429, lng: 19.3015311 },
    { lat: 49.4253257, lng: 19.3005993 },
    { lat: 49.4250115, lng: 19.2978817 },
    { lat: 49.4246694, lng: 19.2968677 },
    { lat: 49.4239494, lng: 19.2957479 },
    { lat: 49.423402, lng: 19.2948476 },
    { lat: 49.4227644, lng: 19.2937542 },
    { lat: 49.4220775, lng: 19.2926467 },
    { lat: 49.4219625, lng: 19.2923576 },
    { lat: 49.4217429, lng: 19.2919831 },
    { lat: 49.4216595, lng: 19.2918363 },
    { lat: 49.421657, lng: 19.291833 },
    { lat: 49.4211193, lng: 19.2905653 },
    { lat: 49.4210369, lng: 19.2903721 },
    { lat: 49.4210052, lng: 19.2903004 },
    { lat: 49.4209384, lng: 19.290146 },
    { lat: 49.4209179, lng: 19.2900968 },
    { lat: 49.4208407, lng: 19.2899176 },
    { lat: 49.4207052, lng: 19.2896054 },
    { lat: 49.4203871, lng: 19.2889494 },
    { lat: 49.4191496, lng: 19.2862092 },
    { lat: 49.4190572, lng: 19.2859972 },
    { lat: 49.4187236, lng: 19.2855207 },
    { lat: 49.418062, lng: 19.284495 },
    { lat: 49.4177087, lng: 19.2838415 },
    { lat: 49.4170016, lng: 19.2826193 },
    { lat: 49.4169906, lng: 19.2825995 },
    { lat: 49.4169747, lng: 19.282573 },
    { lat: 49.4168632, lng: 19.2823842 },
    { lat: 49.4167969, lng: 19.2822707 },
    { lat: 49.4165811, lng: 19.2820663 },
    { lat: 49.4163009, lng: 19.2819103 },
    { lat: 49.415204, lng: 19.2814247 },
    { lat: 49.4145951, lng: 19.2811544 },
    { lat: 49.414327, lng: 19.2807728 },
    { lat: 49.4139027, lng: 19.2801674 },
    { lat: 49.4134155, lng: 19.2794739 },
    { lat: 49.4133818, lng: 19.2794262 },
    { lat: 49.413354, lng: 19.2793877 },
    { lat: 49.412515, lng: 19.2785915 },
    { lat: 49.411958, lng: 19.2778446 },
    { lat: 49.411476, lng: 19.2771868 },
    { lat: 49.4114015, lng: 19.2771147 },
    { lat: 49.4111382, lng: 19.2767673 },
    { lat: 49.4110352, lng: 19.2766159 },
    { lat: 49.410623, lng: 19.2761457 },
    { lat: 49.4106207, lng: 19.2761427 },
    { lat: 49.4103942, lng: 19.2758379 },
    { lat: 49.4104208, lng: 19.275637 },
    { lat: 49.4103007, lng: 19.2754347 },
    { lat: 49.4103894, lng: 19.2748797 },
    { lat: 49.4104034, lng: 19.2747928 },
    { lat: 49.4104617, lng: 19.274433 },
    { lat: 49.4104914, lng: 19.2741778 },
    { lat: 49.4105245, lng: 19.2740192 },
    { lat: 49.410502, lng: 19.2739865 },
    { lat: 49.410586, lng: 19.2732744 },
    { lat: 49.4106845, lng: 19.2728588 },
    { lat: 49.4108487, lng: 19.272163 },
    { lat: 49.4108638, lng: 19.2720973 },
    { lat: 49.4109195, lng: 19.2718033 },
    { lat: 49.411609, lng: 19.2710866 },
    { lat: 49.4109596, lng: 19.268918 },
    { lat: 49.4109339, lng: 19.2687106 },
    { lat: 49.4108961, lng: 19.2685309 },
    { lat: 49.4107231, lng: 19.2681858 },
    { lat: 49.4107242, lng: 19.2680688 },
    { lat: 49.4105366, lng: 19.2677249 },
    { lat: 49.4105204, lng: 19.2675732 },
    { lat: 49.4102196, lng: 19.2667679 },
    { lat: 49.4101146, lng: 19.2667206 },
    { lat: 49.4100116, lng: 19.2665406 },
    { lat: 49.4099959, lng: 19.2664233 },
    { lat: 49.409547, lng: 19.2653265 },
    { lat: 49.4091671, lng: 19.2637378 },
    { lat: 49.4091405, lng: 19.263625 },
    { lat: 49.4089336, lng: 19.2630426 },
    { lat: 49.4088739, lng: 19.2628813 },
    { lat: 49.4085237, lng: 19.2617475 },
    { lat: 49.4080373, lng: 19.2604784 },
    { lat: 49.4078989, lng: 19.2598623 },
    { lat: 49.4077068, lng: 19.259072 },
    { lat: 49.4076158, lng: 19.2584851 },
    { lat: 49.4075195, lng: 19.2574829 },
    { lat: 49.4075183, lng: 19.2573147 },
    { lat: 49.4074785, lng: 19.2568784 },
    { lat: 49.4073764, lng: 19.2566289 },
    { lat: 49.4071734, lng: 19.2561304 },
    { lat: 49.4071595, lng: 19.2560965 },
    { lat: 49.4071008, lng: 19.2559547 },
    { lat: 49.4070354, lng: 19.2557872 },
    { lat: 49.406993, lng: 19.2556791 },
    { lat: 49.406987, lng: 19.2556685 },
    { lat: 49.4069442, lng: 19.2555969 },
    { lat: 49.4068712, lng: 19.2554868 },
    { lat: 49.4068491, lng: 19.2554494 },
    { lat: 49.4069873, lng: 19.255346 },
    { lat: 49.4071404, lng: 19.2552496 },
    { lat: 49.4074944, lng: 19.2548225 },
    { lat: 49.4081125, lng: 19.2542698 },
    { lat: 49.4081934, lng: 19.2541681 },
    { lat: 49.4082102, lng: 19.2539752 },
    { lat: 49.4081189, lng: 19.2535754 },
    { lat: 49.4081641, lng: 19.2530756 },
    { lat: 49.4081789, lng: 19.2529172 },
    { lat: 49.4082293, lng: 19.2526018 },
    { lat: 49.40834, lng: 19.2522029 },
    { lat: 49.4084697, lng: 19.2517338 },
    { lat: 49.4086216, lng: 19.2511595 },
    { lat: 49.4086973, lng: 19.2508608 },
    { lat: 49.4087678, lng: 19.2506111 },
    { lat: 49.4088433, lng: 19.2503504 },
    { lat: 49.4089271, lng: 19.2501463 },
    { lat: 49.4090646, lng: 19.2498599 },
    { lat: 49.4092247, lng: 19.2496769 },
    { lat: 49.4094154, lng: 19.2495854 },
    { lat: 49.4095862, lng: 19.2494573 },
    { lat: 49.4098526, lng: 19.2493008 },
    { lat: 49.4100068, lng: 19.2491565 },
    { lat: 49.4101555, lng: 19.2490863 },
    { lat: 49.4103523, lng: 19.2490976 },
    { lat: 49.4105023, lng: 19.249029 },
    { lat: 49.4106481, lng: 19.2490156 },
    { lat: 49.4108838, lng: 19.2488777 },
    { lat: 49.4110276, lng: 19.2487491 },
    { lat: 49.4112834, lng: 19.2485619 },
    { lat: 49.411388, lng: 19.2484116 },
    { lat: 49.4115061, lng: 19.2482891 },
    { lat: 49.4116039, lng: 19.2480718 },
    { lat: 49.4116839, lng: 19.2477874 },
    { lat: 49.4117508, lng: 19.2476062 },
    { lat: 49.4118393, lng: 19.2473827 },
    { lat: 49.4119452, lng: 19.2472153 },
    { lat: 49.4120115, lng: 19.2469769 },
    { lat: 49.4120779, lng: 19.2468348 },
    { lat: 49.4121129, lng: 19.2466463 },
    { lat: 49.4121514, lng: 19.2464582 },
    { lat: 49.4121693, lng: 19.2462694 },
    { lat: 49.4122082, lng: 19.2460895 },
    { lat: 49.4122298, lng: 19.2458594 },
    { lat: 49.4122599, lng: 19.2456642 },
    { lat: 49.4123042, lng: 19.2454095 },
    { lat: 49.4123042, lng: 19.2451956 },
    { lat: 49.4122742, lng: 19.2449735 },
    { lat: 49.4122953, lng: 19.2447771 },
    { lat: 49.4122917, lng: 19.2444142 },
    { lat: 49.4123225, lng: 19.2440947 },
    { lat: 49.4123563, lng: 19.2437399 },
    { lat: 49.4123727, lng: 19.2435129 },
    { lat: 49.4124062, lng: 19.2432761 },
    { lat: 49.4124672, lng: 19.2430144 },
    { lat: 49.4124796, lng: 19.2428049 },
    { lat: 49.4125165, lng: 19.242588 },
    { lat: 49.4125727, lng: 19.2422997 },
    { lat: 49.4126765, lng: 19.2418288 },
    { lat: 49.4127949, lng: 19.2416414 },
    { lat: 49.4129631, lng: 19.2415299 },
    { lat: 49.4129953, lng: 19.2413772 },
    { lat: 49.4130668, lng: 19.2411531 },
    { lat: 49.4131775, lng: 19.2408019 },
    { lat: 49.4131672, lng: 19.2406105 },
    { lat: 49.4132277, lng: 19.2405071 },
    { lat: 49.4133111, lng: 19.2403699 },
    { lat: 49.4133201, lng: 19.2402021 },
    { lat: 49.4133797, lng: 19.2400112 },
    { lat: 49.4134736, lng: 19.2398501 },
    { lat: 49.4134547, lng: 19.2397172 },
    { lat: 49.4134225, lng: 19.2394443 },
    { lat: 49.4134629, lng: 19.2392255 },
    { lat: 49.4134122, lng: 19.2390636 },
    { lat: 49.413412, lng: 19.238892 },
    { lat: 49.4134314, lng: 19.2387346 },
    { lat: 49.413546, lng: 19.2385625 },
    { lat: 49.4135647, lng: 19.238336 },
    { lat: 49.4135458, lng: 19.2378958 },
    { lat: 49.413535, lng: 19.2372187 },
    { lat: 49.4134528, lng: 19.2366807 },
    { lat: 49.4134526, lng: 19.2364147 },
    { lat: 49.4134439, lng: 19.2360446 },
    { lat: 49.4134786, lng: 19.2358504 },
    { lat: 49.4136192, lng: 19.2356804 },
    { lat: 49.413553, lng: 19.2354254 },
    { lat: 49.4134356, lng: 19.2352124 },
    { lat: 49.4134041, lng: 19.2349292 },
    { lat: 49.4133215, lng: 19.2346146 },
    { lat: 49.4132389, lng: 19.2342796 },
    { lat: 49.4131943, lng: 19.2340269 },
    { lat: 49.413054, lng: 19.2337101 },
    { lat: 49.412926, lng: 19.2334399 },
    { lat: 49.4129449, lng: 19.233103 },
    { lat: 49.4130073, lng: 19.2328271 },
    { lat: 49.4129384, lng: 19.2324229 },
    { lat: 49.4127451, lng: 19.2321048 },
    { lat: 49.4127435, lng: 19.2317893 },
    { lat: 49.412665, lng: 19.2315104 },
    { lat: 49.4125751, lng: 19.231236 },
    { lat: 49.4126196, lng: 19.2307981 },
    { lat: 49.4127245, lng: 19.2305189 },
    { lat: 49.4126773, lng: 19.230168 },
    { lat: 49.412612, lng: 19.229791 },
    { lat: 49.4125358, lng: 19.2296139 },
    { lat: 49.4124402, lng: 19.2295714 },
    { lat: 49.4123224, lng: 19.2295825 },
    { lat: 49.4122106, lng: 19.2295549 },
    { lat: 49.412124, lng: 19.2292501 },
    { lat: 49.4120922, lng: 19.2289594 },
    { lat: 49.4121522, lng: 19.2285451 },
    { lat: 49.4121725, lng: 19.228334 },
    { lat: 49.4121362, lng: 19.2281122 },
    { lat: 49.4122201, lng: 19.2278914 },
    { lat: 49.4123412, lng: 19.2276889 },
    { lat: 49.4122886, lng: 19.2274631 },
    { lat: 49.4122266, lng: 19.2272236 },
    { lat: 49.4122675, lng: 19.227021 },
    { lat: 49.4124217, lng: 19.2266374 },
    { lat: 49.4126216, lng: 19.2262483 },
    { lat: 49.412817, lng: 19.2259755 },
    { lat: 49.4130692, lng: 19.2257086 },
    { lat: 49.4131432, lng: 19.2255132 },
    { lat: 49.413177, lng: 19.2253013 },
    { lat: 49.4132609, lng: 19.225075 },
    { lat: 49.4133809, lng: 19.2247365 },
    { lat: 49.413658, lng: 19.224258 },
    { lat: 49.4139716, lng: 19.2240316 },
    { lat: 49.4140607, lng: 19.223871 },
    { lat: 49.4140518, lng: 19.223704 },
    { lat: 49.4139485, lng: 19.2234547 },
    { lat: 49.4139526, lng: 19.2233051 },
    { lat: 49.4140616, lng: 19.2228038 },
    { lat: 49.4142606, lng: 19.2222295 },
    { lat: 49.4143343, lng: 19.2218404 },
    { lat: 49.4143575, lng: 19.2212894 },
    { lat: 49.4145603, lng: 19.2209083 },
    { lat: 49.4146511, lng: 19.2204282 },
    { lat: 49.4147361, lng: 19.220132 },
    { lat: 49.4148756, lng: 19.2195632 },
    { lat: 49.4149385, lng: 19.2192557 },
    { lat: 49.415045, lng: 19.2188199 },
    { lat: 49.4152072, lng: 19.218584 },
    { lat: 49.4152451, lng: 19.2181718 },
    { lat: 49.4153884, lng: 19.2179194 },
    { lat: 49.4153958, lng: 19.2175323 },
    { lat: 49.415604, lng: 19.2170767 },
    { lat: 49.4155889, lng: 19.2165043 },
    { lat: 49.4157443, lng: 19.2160763 },
    { lat: 49.4157592, lng: 19.215708 },
    { lat: 49.4157869, lng: 19.2153458 },
    { lat: 49.415929, lng: 19.2152052 },
    { lat: 49.4159525, lng: 19.2147096 },
    { lat: 49.4160291, lng: 19.2143574 },
    { lat: 49.4160268, lng: 19.2137919 },
    { lat: 49.4160532, lng: 19.2137513 },
    { lat: 49.4161725, lng: 19.2135616 },
    { lat: 49.4163778, lng: 19.2134733 },
    { lat: 49.4164097, lng: 19.2134542 },
    { lat: 49.4165438, lng: 19.2133399 },
    { lat: 49.4167023, lng: 19.2130263 },
    { lat: 49.4167266, lng: 19.2126167 },
    { lat: 49.4168315, lng: 19.2122881 },
    { lat: 49.4169567, lng: 19.2121716 },
    { lat: 49.4170554, lng: 19.211953 },
    { lat: 49.4170795, lng: 19.2119398 },
    { lat: 49.4171218, lng: 19.2120183 },
    { lat: 49.4172087, lng: 19.2119303 },
    { lat: 49.4172443, lng: 19.2118937 },
    { lat: 49.4173176, lng: 19.2118518 },
    { lat: 49.4172987, lng: 19.2117131 },
    { lat: 49.4172899, lng: 19.211506 },
    { lat: 49.417382, lng: 19.211219 },
    { lat: 49.4174206, lng: 19.2108983 },
    { lat: 49.4175825, lng: 19.2108406 },
    { lat: 49.4179514, lng: 19.2104272 },
    { lat: 49.418058, lng: 19.2103222 },
    { lat: 49.4181448, lng: 19.2101329 },
    { lat: 49.4182939, lng: 19.2099325 },
    { lat: 49.4185091, lng: 19.2098109 },
    { lat: 49.4186727, lng: 19.2095083 },
    { lat: 49.4189269, lng: 19.2090289 },
    { lat: 49.4191739, lng: 19.2086593 },
    { lat: 49.4191812, lng: 19.2084576 },
    { lat: 49.4191095, lng: 19.2080451 },
    { lat: 49.4191779, lng: 19.2078726 },
    { lat: 49.4192903, lng: 19.2077492 },
    { lat: 49.41939, lng: 19.2075276 },
    { lat: 49.4194463, lng: 19.2073823 },
    { lat: 49.4194846, lng: 19.2071969 },
    { lat: 49.4195565, lng: 19.2071208 },
    { lat: 49.4196531, lng: 19.206973 },
    { lat: 49.4197303, lng: 19.2068199 },
    { lat: 49.4197876, lng: 19.2067001 },
    { lat: 49.4198265, lng: 19.2066878 },
    { lat: 49.4199061, lng: 19.206703 },
    { lat: 49.4199289, lng: 19.2066628 },
    { lat: 49.4199348, lng: 19.2065492 },
    { lat: 49.419934, lng: 19.2064139 },
    { lat: 49.4199546, lng: 19.2064007 },
    { lat: 49.4199961, lng: 19.2063689 },
    { lat: 49.4200614, lng: 19.2063221 },
    { lat: 49.4201552, lng: 19.2062354 },
    { lat: 49.4202661, lng: 19.2062186 },
    { lat: 49.4202935, lng: 19.2061105 },
    { lat: 49.4203817, lng: 19.2059746 },
    { lat: 49.4204508, lng: 19.2058198 },
    { lat: 49.4204887, lng: 19.2056461 },
    { lat: 49.4205865, lng: 19.2056847 },
    { lat: 49.4207077, lng: 19.2056779 },
    { lat: 49.4207248, lng: 19.2055416 },
    { lat: 49.4207909, lng: 19.2055067 },
    { lat: 49.4209015, lng: 19.2055127 },
    { lat: 49.4209223, lng: 19.205591 },
    { lat: 49.4209401, lng: 19.2058012 },
    { lat: 49.4209995, lng: 19.2058622 },
    { lat: 49.4211987, lng: 19.2057405 },
    { lat: 49.4212053, lng: 19.2056666 },
    { lat: 49.4213126, lng: 19.2056252 },
    { lat: 49.421304, lng: 19.20554 },
    { lat: 49.421352, lng: 19.2054133 },
    { lat: 49.4214118, lng: 19.2054131 },
    { lat: 49.4214644, lng: 19.2055458 },
    { lat: 49.4215424, lng: 19.2055015 },
    { lat: 49.4215679, lng: 19.2054223 },
    { lat: 49.4215504, lng: 19.2052711 },
    { lat: 49.4215961, lng: 19.2052373 },
    { lat: 49.4216474, lng: 19.20525 },
    { lat: 49.4216376, lng: 19.2053503 },
    { lat: 49.4216715, lng: 19.2054464 },
    { lat: 49.4216802, lng: 19.2054653 },
    { lat: 49.4216866, lng: 19.2054566 },
    { lat: 49.4216996, lng: 19.2054222 },
    { lat: 49.4217001, lng: 19.2054077 },
    { lat: 49.4217101, lng: 19.205404 },
    { lat: 49.4217365, lng: 19.2053934 },
    { lat: 49.421898, lng: 19.2054223 },
    { lat: 49.4219251, lng: 19.2054004 },
    { lat: 49.4218939, lng: 19.2051943 },
    { lat: 49.4219485, lng: 19.2051603 },
    { lat: 49.4220003, lng: 19.2051334 },
    { lat: 49.4220157, lng: 19.2051972 },
    { lat: 49.4220216, lng: 19.2052262 },
    { lat: 49.4220358, lng: 19.2052431 },
    { lat: 49.4220634, lng: 19.2052067 },
    { lat: 49.4220904, lng: 19.2051551 },
    { lat: 49.422095, lng: 19.2051575 },
    { lat: 49.422103, lng: 19.2051381 },
    { lat: 49.4220789, lng: 19.2050576 },
    { lat: 49.422037, lng: 19.204983 },
    { lat: 49.4220419, lng: 19.2048981 },
    { lat: 49.4220333, lng: 19.2048368 },
    { lat: 49.4220637, lng: 19.2047918 },
    { lat: 49.422086, lng: 19.2047824 },
    { lat: 49.4221039, lng: 19.204827 },
    { lat: 49.422131, lng: 19.2048301 },
    { lat: 49.4222211, lng: 19.2048518 },
    { lat: 49.4222435, lng: 19.2048272 },
    { lat: 49.4223244, lng: 19.2047258 },
    { lat: 49.4223585, lng: 19.2046589 },
    { lat: 49.422432, lng: 19.2046648 },
    { lat: 49.4224947, lng: 19.2047514 },
    { lat: 49.4225871, lng: 19.2047519 },
    { lat: 49.4226771, lng: 19.2047233 },
    { lat: 49.4227253, lng: 19.2047196 },
    { lat: 49.4227488, lng: 19.204692 },
    { lat: 49.4227336, lng: 19.2046112 },
    { lat: 49.422745, lng: 19.2045444 },
    { lat: 49.4227331, lng: 19.2044827 },
    { lat: 49.4227616, lng: 19.2044672 },
    { lat: 49.4227912, lng: 19.2044993 },
    { lat: 49.4228028, lng: 19.2045042 },
    { lat: 49.4229062, lng: 19.2043792 },
    { lat: 49.4229306, lng: 19.2044217 },
    { lat: 49.4229283, lng: 19.2045099 },
    { lat: 49.4229427, lng: 19.20453 },
    { lat: 49.4229845, lng: 19.2045304 },
    { lat: 49.4230314, lng: 19.2044744 },
    { lat: 49.4230265, lng: 19.2043728 },
    { lat: 49.4230655, lng: 19.2043181 },
    { lat: 49.4230319, lng: 19.2042296 },
    { lat: 49.4230848, lng: 19.2041548 },
    { lat: 49.4232085, lng: 19.2041984 },
    { lat: 49.4232799, lng: 19.204181 },
    { lat: 49.4233349, lng: 19.204224 },
    { lat: 49.4233704, lng: 19.2042102 },
    { lat: 49.4233871, lng: 19.2041156 },
    { lat: 49.4234289, lng: 19.2039795 },
    { lat: 49.4234661, lng: 19.2039072 },
    { lat: 49.4235526, lng: 19.2038044 },
    { lat: 49.4235698, lng: 19.2037659 },
    { lat: 49.4235816, lng: 19.2037559 },
    { lat: 49.4236117, lng: 19.2037474 },
    { lat: 49.4236497, lng: 19.2037866 },
    { lat: 49.4237175, lng: 19.2037427 },
    { lat: 49.4237895, lng: 19.2037422 },
    { lat: 49.4238106, lng: 19.2037826 },
    { lat: 49.4238892, lng: 19.2038215 },
    { lat: 49.4239105, lng: 19.2037973 },
    { lat: 49.4238862, lng: 19.2037154 },
    { lat: 49.4238975, lng: 19.2036898 },
    { lat: 49.42392, lng: 19.2036916 },
    { lat: 49.423941, lng: 19.2037052 },
    { lat: 49.4239641, lng: 19.2037381 },
    { lat: 49.423989, lng: 19.2037411 },
    { lat: 49.4240364, lng: 19.2036959 },
    { lat: 49.4240455, lng: 19.2036482 },
    { lat: 49.424077, lng: 19.2036261 },
    { lat: 49.4240853, lng: 19.2036129 },
    { lat: 49.4240855, lng: 19.2035669 },
    { lat: 49.4240704, lng: 19.2035378 },
    { lat: 49.424028, lng: 19.2035441 },
    { lat: 49.4240171, lng: 19.2035286 },
    { lat: 49.4240202, lng: 19.2034519 },
    { lat: 49.4240251, lng: 19.2033673 },
    { lat: 49.4240762, lng: 19.2032555 },
    { lat: 49.424137, lng: 19.2032323 },
    { lat: 49.4241859, lng: 19.203263 },
    { lat: 49.4242402, lng: 19.2031736 },
    { lat: 49.4242449, lng: 19.203084 },
    { lat: 49.4242524, lng: 19.2030542 },
    { lat: 49.4243358, lng: 19.2030314 },
    { lat: 49.4243544, lng: 19.2029022 },
    { lat: 49.4243855, lng: 19.2028462 },
    { lat: 49.4243967, lng: 19.2028175 },
    { lat: 49.4244162, lng: 19.2027584 },
    { lat: 49.4244236, lng: 19.2027485 },
    { lat: 49.4244378, lng: 19.2027599 },
    { lat: 49.4244541, lng: 19.2028182 },
    { lat: 49.4245089, lng: 19.2028327 },
    { lat: 49.4245271, lng: 19.2028147 },
    { lat: 49.424562, lng: 19.2027441 },
    { lat: 49.4245663, lng: 19.2027411 },
    { lat: 49.4249136, lng: 19.2024839 },
    { lat: 49.4253898, lng: 19.2019338 },
    { lat: 49.4256903, lng: 19.2016222 },
    { lat: 49.4258773, lng: 19.2014287 },
    { lat: 49.4259021, lng: 19.2013618 },
    { lat: 49.4259752, lng: 19.2011718 },
    { lat: 49.4261067, lng: 19.2008326 },
    { lat: 49.4264433, lng: 19.1999586 },
    { lat: 49.4264938, lng: 19.1986415 },
    { lat: 49.4265101, lng: 19.1982295 },
    { lat: 49.4265339, lng: 19.1976236 },
    { lat: 49.4265448, lng: 19.1973345 },
    { lat: 49.4265596, lng: 19.1969397 },
    { lat: 49.4266399, lng: 19.1965906 },
    { lat: 49.4267718, lng: 19.1960094 },
    { lat: 49.4268707, lng: 19.1955135 },
    { lat: 49.4269144, lng: 19.1952239 },
    { lat: 49.4269598, lng: 19.1949248 },
    { lat: 49.4270023, lng: 19.1947086 },
    { lat: 49.4270727, lng: 19.1943896 },
    { lat: 49.4271655, lng: 19.1936492 },
    { lat: 49.4271816, lng: 19.193517 },
    { lat: 49.4272422, lng: 19.1931373 },
    { lat: 49.4272628, lng: 19.1929571 },
    { lat: 49.42726, lng: 19.192469 },
    { lat: 49.426641, lng: 19.192941 },
    { lat: 49.42601, lng: 19.193406 },
    { lat: 49.425363, lng: 19.192855 },
    { lat: 49.424696, lng: 19.192149 },
    { lat: 49.424191, lng: 19.191627 },
    { lat: 49.423485, lng: 19.191139 },
    { lat: 49.422367, lng: 19.191029 },
    { lat: 49.421745, lng: 19.190953 },
    { lat: 49.42104, lng: 19.190998 },
    { lat: 49.420242, lng: 19.191527 },
    { lat: 49.419381, lng: 19.192479 },
    { lat: 49.41836, lng: 19.193492 },
    { lat: 49.418072, lng: 19.193922 },
    { lat: 49.417851, lng: 19.194153 },
    { lat: 49.417569, lng: 19.194443 },
    { lat: 49.417287, lng: 19.194728 },
    { lat: 49.416908, lng: 19.195284 },
    { lat: 49.416479, lng: 19.195928 },
    { lat: 49.415872, lng: 19.196317 },
    { lat: 49.4155827, lng: 19.1964944 },
    { lat: 49.415308, lng: 19.196652 },
    { lat: 49.414719, lng: 19.197245 },
    { lat: 49.414564, lng: 19.197397 },
    { lat: 49.414024, lng: 19.197327 },
    { lat: 49.413714, lng: 19.197168 },
    { lat: 49.413476, lng: 19.196273 },
    { lat: 49.413275, lng: 19.195469 },
    { lat: 49.412772, lng: 19.194534 },
    { lat: 49.412108, lng: 19.19309 },
    { lat: 49.411086, lng: 19.191606 },
    { lat: 49.410897, lng: 19.190983 },
    { lat: 49.410542, lng: 19.189557 },
    { lat: 49.410519, lng: 19.189343 },
    { lat: 49.410471, lng: 19.188949 },
    { lat: 49.409706, lng: 19.187584 },
    { lat: 49.409665, lng: 19.187451 },
    { lat: 49.409835, lng: 19.187105 },
    { lat: 49.410251, lng: 19.186184 },
    { lat: 49.410661, lng: 19.184835 },
    { lat: 49.411099, lng: 19.184066 },
    { lat: 49.4117, lng: 19.182759 },
    { lat: 49.412161, lng: 19.181407 },
    { lat: 49.413045, lng: 19.179994 },
    { lat: 49.413694, lng: 19.178385 },
    { lat: 49.413994, lng: 19.177278 },
    { lat: 49.413888, lng: 19.176639 },
    { lat: 49.413744, lng: 19.1756 },
    { lat: 49.413466, lng: 19.17448 },
    { lat: 49.4131, lng: 19.172936 },
    { lat: 49.412785, lng: 19.172134 },
    { lat: 49.412413, lng: 19.171193 },
    { lat: 49.41176, lng: 19.16969 },
    { lat: 49.410967, lng: 19.168616 },
    { lat: 49.410432, lng: 19.167897 },
    { lat: 49.40988, lng: 19.167068 },
    { lat: 49.409314, lng: 19.166047 },
    { lat: 49.409101, lng: 19.165655 },
    { lat: 49.408904, lng: 19.165214 },
    { lat: 49.408227, lng: 19.163897 },
    { lat: 49.407554, lng: 19.162581 },
    { lat: 49.407175, lng: 19.161083 },
    { lat: 49.406846, lng: 19.160258 },
    { lat: 49.406593, lng: 19.159768 },
    { lat: 49.405943, lng: 19.158178 },
    { lat: 49.405574, lng: 19.157087 },
    { lat: 49.404787, lng: 19.1556 },
    { lat: 49.404116, lng: 19.154056 },
    { lat: 49.403772, lng: 19.153381 },
    { lat: 49.403715, lng: 19.152152 },
    { lat: 49.403645, lng: 19.150658 },
    { lat: 49.404098, lng: 19.149835 },
    { lat: 49.404634, lng: 19.148872 },
    { lat: 49.405233, lng: 19.147101 },
  ],
  Ťapešovo: [
    { lat: 49.352, lng: 19.46415 },
    { lat: 49.351767, lng: 19.464908 },
    { lat: 49.352266, lng: 19.465862 },
    { lat: 49.352461, lng: 19.4661 },
    { lat: 49.353159, lng: 19.466697 },
    { lat: 49.353483, lng: 19.466973 },
    { lat: 49.353623, lng: 19.467094 },
    { lat: 49.353701, lng: 19.467159 },
    { lat: 49.353732, lng: 19.467204 },
    { lat: 49.354002, lng: 19.467462 },
    { lat: 49.354368, lng: 19.467812 },
    { lat: 49.354963, lng: 19.468379 },
    { lat: 49.355644, lng: 19.469241 },
    { lat: 49.356162, lng: 19.469898 },
    { lat: 49.356708, lng: 19.470534 },
    { lat: 49.356861, lng: 19.47077 },
    { lat: 49.357139, lng: 19.4712 },
    { lat: 49.357675, lng: 19.47179 },
    { lat: 49.35775, lng: 19.471872 },
    { lat: 49.35816, lng: 19.472325 },
    { lat: 49.359, lng: 19.473691 },
    { lat: 49.359049, lng: 19.474393 },
    { lat: 49.358978, lng: 19.475671 },
    { lat: 49.358987, lng: 19.475985 },
    { lat: 49.359184, lng: 19.476871 },
    { lat: 49.358902, lng: 19.477884 },
    { lat: 49.358728, lng: 19.478937 },
    { lat: 49.358664, lng: 19.479376 },
    { lat: 49.358637, lng: 19.48036 },
    { lat: 49.358876, lng: 19.482121 },
    { lat: 49.358879, lng: 19.482154 },
    { lat: 49.3592604, lng: 19.4816881 },
    { lat: 49.3598096, lng: 19.4810107 },
    { lat: 49.3603484, lng: 19.4804935 },
    { lat: 49.3609586, lng: 19.4799712 },
    { lat: 49.3611049, lng: 19.4797419 },
    { lat: 49.3611685, lng: 19.4796046 },
    { lat: 49.3612168, lng: 19.4794306 },
    { lat: 49.3613925, lng: 19.4794002 },
    { lat: 49.361444, lng: 19.4792985 },
    { lat: 49.3614942, lng: 19.4792599 },
    { lat: 49.3615392, lng: 19.479087 },
    { lat: 49.3616355, lng: 19.4790502 },
    { lat: 49.3617527, lng: 19.4788765 },
    { lat: 49.3618342, lng: 19.4788637 },
    { lat: 49.3621196, lng: 19.4786705 },
    { lat: 49.3622379, lng: 19.4785721 },
    { lat: 49.3623917, lng: 19.4785386 },
    { lat: 49.3624354, lng: 19.4785076 },
    { lat: 49.3626203, lng: 19.4781631 },
    { lat: 49.3627522, lng: 19.478043 },
    { lat: 49.3628282, lng: 19.4780063 },
    { lat: 49.3628828, lng: 19.4778928 },
    { lat: 49.3629045, lng: 19.4778257 },
    { lat: 49.3630681, lng: 19.4776354 },
    { lat: 49.3631359, lng: 19.4775381 },
    { lat: 49.3631729, lng: 19.4774582 },
    { lat: 49.3633315, lng: 19.477255 },
    { lat: 49.3634131, lng: 19.4772445 },
    { lat: 49.3635081, lng: 19.477179 },
    { lat: 49.3635599, lng: 19.4770762 },
    { lat: 49.3635888, lng: 19.4769238 },
    { lat: 49.3636648, lng: 19.476835 },
    { lat: 49.3636719, lng: 19.4767171 },
    { lat: 49.3637307, lng: 19.4765022 },
    { lat: 49.3637261, lng: 19.4763747 },
    { lat: 49.3638041, lng: 19.4762109 },
    { lat: 49.3638249, lng: 19.4760988 },
    { lat: 49.3638777, lng: 19.4759988 },
    { lat: 49.3639393, lng: 19.4758891 },
    { lat: 49.3639129, lng: 19.4758034 },
    { lat: 49.3640587, lng: 19.4755947 },
    { lat: 49.3640925, lng: 19.4755434 },
    { lat: 49.3642192, lng: 19.4752362 },
    { lat: 49.3643495, lng: 19.4751796 },
    { lat: 49.3643835, lng: 19.4751788 },
    { lat: 49.3644336, lng: 19.4750921 },
    { lat: 49.3644924, lng: 19.4750525 },
    { lat: 49.3646196, lng: 19.4750237 },
    { lat: 49.3647176, lng: 19.4749467 },
    { lat: 49.3647839, lng: 19.4749204 },
    { lat: 49.3648467, lng: 19.4746682 },
    { lat: 49.3649508, lng: 19.4746003 },
    { lat: 49.364982, lng: 19.4744986 },
    { lat: 49.3653089, lng: 19.4742072 },
    { lat: 49.3654399, lng: 19.4742633 },
    { lat: 49.3655335, lng: 19.474161 },
    { lat: 49.3657063, lng: 19.4740998 },
    { lat: 49.365926, lng: 19.473876 },
    { lat: 49.3659021, lng: 19.4736258 },
    { lat: 49.3660086, lng: 19.4733165 },
    { lat: 49.3660868, lng: 19.4732307 },
    { lat: 49.3661155, lng: 19.4730689 },
    { lat: 49.3660325, lng: 19.4728074 },
    { lat: 49.3661982, lng: 19.4726819 },
    { lat: 49.3662321, lng: 19.4724173 },
    { lat: 49.3662737, lng: 19.4722432 },
    { lat: 49.3662552, lng: 19.4720616 },
    { lat: 49.3663325, lng: 19.4718309 },
    { lat: 49.3662728, lng: 19.4716566 },
    { lat: 49.36617, lng: 19.4716025 },
    { lat: 49.3662237, lng: 19.4713979 },
    { lat: 49.3663453, lng: 19.4712695 },
    { lat: 49.3663451, lng: 19.4708994 },
    { lat: 49.3663821, lng: 19.4707685 },
    { lat: 49.3664987, lng: 19.4706855 },
    { lat: 49.3664996, lng: 19.4706286 },
    { lat: 49.3665185, lng: 19.4703811 },
    { lat: 49.3665348, lng: 19.4702937 },
    { lat: 49.3665764, lng: 19.4702176 },
    { lat: 49.3666533, lng: 19.4698807 },
    { lat: 49.3667067, lng: 19.4697163 },
    { lat: 49.366876, lng: 19.4695976 },
    { lat: 49.3669255, lng: 19.4695493 },
    { lat: 49.3669439, lng: 19.4694573 },
    { lat: 49.3670236, lng: 19.4694024 },
    { lat: 49.3670415, lng: 19.4693759 },
    { lat: 49.3671185, lng: 19.4692595 },
    { lat: 49.3670778, lng: 19.4691591 },
    { lat: 49.367109, lng: 19.4691286 },
    { lat: 49.3671307, lng: 19.4691071 },
    { lat: 49.3671975, lng: 19.4690406 },
    { lat: 49.3672396, lng: 19.4689996 },
    { lat: 49.3671939, lng: 19.4687409 },
    { lat: 49.3672047, lng: 19.4686556 },
    { lat: 49.3672146, lng: 19.468605 },
    { lat: 49.3672213, lng: 19.468575 },
    { lat: 49.3672221, lng: 19.4685731 },
    { lat: 49.3673004, lng: 19.4684367 },
    { lat: 49.3673181, lng: 19.4684058 },
    { lat: 49.367327, lng: 19.4683984 },
    { lat: 49.3673475, lng: 19.4683817 },
    { lat: 49.3674357, lng: 19.4683907 },
    { lat: 49.3675378, lng: 19.4682069 },
    { lat: 49.367649, lng: 19.4680529 },
    { lat: 49.3678868, lng: 19.4679047 },
    { lat: 49.368075, lng: 19.4678845 },
    { lat: 49.3681538, lng: 19.4678555 },
    { lat: 49.3681603, lng: 19.4678497 },
    { lat: 49.3682214, lng: 19.467807 },
    { lat: 49.3682923, lng: 19.4677571 },
    { lat: 49.3682935, lng: 19.4677562 },
    { lat: 49.3683415, lng: 19.4677218 },
    { lat: 49.368366, lng: 19.4677618 },
    { lat: 49.3684176, lng: 19.4678061 },
    { lat: 49.3684622, lng: 19.4678993 },
    { lat: 49.3684813, lng: 19.4679193 },
    { lat: 49.3685816, lng: 19.4678258 },
    { lat: 49.3686045, lng: 19.4678049 },
    { lat: 49.3686747, lng: 19.4677398 },
    { lat: 49.3688502, lng: 19.4676367 },
    { lat: 49.3689855, lng: 19.4677364 },
    { lat: 49.3690088, lng: 19.4677289 },
    { lat: 49.3692844, lng: 19.4677408 },
    { lat: 49.3693099, lng: 19.4677348 },
    { lat: 49.3695601, lng: 19.467661 },
    { lat: 49.3695959, lng: 19.4676795 },
    { lat: 49.3696536, lng: 19.4677084 },
    { lat: 49.3697919, lng: 19.4677785 },
    { lat: 49.3700154, lng: 19.4677628 },
    { lat: 49.3702261, lng: 19.4678092 },
    { lat: 49.3703643, lng: 19.4679762 },
    { lat: 49.3704305, lng: 19.4680411 },
    { lat: 49.3705901, lng: 19.4680105 },
    { lat: 49.3705934, lng: 19.4680098 },
    { lat: 49.3706099, lng: 19.4679986 },
    { lat: 49.3706731, lng: 19.4679544 },
    { lat: 49.3707497, lng: 19.4679008 },
    { lat: 49.3707979, lng: 19.4679231 },
    { lat: 49.3708439, lng: 19.4679428 },
    { lat: 49.3708954, lng: 19.4679832 },
    { lat: 49.3709411, lng: 19.4679974 },
    { lat: 49.3709702, lng: 19.4680189 },
    { lat: 49.3710029, lng: 19.4680927 },
    { lat: 49.3710624, lng: 19.4680881 },
    { lat: 49.3713152, lng: 19.4680715 },
    { lat: 49.3715189, lng: 19.4679411 },
    { lat: 49.3716069, lng: 19.4678481 },
    { lat: 49.3716834, lng: 19.4677965 },
    { lat: 49.3718243, lng: 19.4677481 },
    { lat: 49.3719989, lng: 19.467674 },
    { lat: 49.3721602, lng: 19.4675555 },
    { lat: 49.3722497, lng: 19.4674168 },
    { lat: 49.3724454, lng: 19.4673863 },
    { lat: 49.3726369, lng: 19.4671383 },
    { lat: 49.3726578, lng: 19.467028 },
    { lat: 49.372643, lng: 19.4668802 },
    { lat: 49.3726655, lng: 19.4668044 },
    { lat: 49.3729685, lng: 19.4667292 },
    { lat: 49.373094, lng: 19.4665862 },
    { lat: 49.3731415, lng: 19.4664687 },
    { lat: 49.3733258, lng: 19.4664377 },
    { lat: 49.3734187, lng: 19.466398 },
    { lat: 49.373491, lng: 19.4663537 },
    { lat: 49.3736033, lng: 19.4663706 },
    { lat: 49.3738031, lng: 19.4664757 },
    { lat: 49.3739447, lng: 19.4663455 },
    { lat: 49.3742834, lng: 19.4663119 },
    { lat: 49.3745455, lng: 19.4659576 },
    { lat: 49.3747871, lng: 19.4659888 },
    { lat: 49.3749036, lng: 19.4658786 },
    { lat: 49.3749223, lng: 19.4657457 },
    { lat: 49.3749607, lng: 19.4653767 },
    { lat: 49.3752011, lng: 19.4650443 },
    { lat: 49.3752597, lng: 19.4648243 },
    { lat: 49.3752429, lng: 19.4644319 },
    { lat: 49.3753879, lng: 19.4640822 },
    { lat: 49.3754687, lng: 19.4639994 },
    { lat: 49.3757665, lng: 19.4633935 },
    { lat: 49.3761326, lng: 19.4631187 },
    { lat: 49.3762707, lng: 19.4629122 },
    { lat: 49.3766096, lng: 19.4626881 },
    { lat: 49.3767431, lng: 19.4623035 },
    { lat: 49.3767345, lng: 19.4620711 },
    { lat: 49.3765864, lng: 19.4618115 },
    { lat: 49.3768136, lng: 19.4614125 },
    { lat: 49.3770225, lng: 19.4611991 },
    { lat: 49.3773104, lng: 19.4609898 },
    { lat: 49.3775836, lng: 19.4609557 },
    { lat: 49.3779216, lng: 19.4608535 },
    { lat: 49.3779414, lng: 19.4606232 },
    { lat: 49.3779865, lng: 19.4603757 },
    { lat: 49.3780434, lng: 19.460319 },
    { lat: 49.3784348, lng: 19.4603022 },
    { lat: 49.3785578, lng: 19.46023 },
    { lat: 49.3786946, lng: 19.4599697 },
    { lat: 49.3788648, lng: 19.4599727 },
    { lat: 49.3792251, lng: 19.4598699 },
    { lat: 49.3795168, lng: 19.4596679 },
    { lat: 49.3798762, lng: 19.4594013 },
    { lat: 49.3799927, lng: 19.4593353 },
    { lat: 49.3803127, lng: 19.459065 },
    { lat: 49.3804171, lng: 19.4589576 },
    { lat: 49.3805193, lng: 19.4588034 },
    { lat: 49.3806599, lng: 19.4587474 },
    { lat: 49.3807831, lng: 19.4586846 },
    { lat: 49.3808726, lng: 19.4586193 },
    { lat: 49.3810018, lng: 19.4584141 },
    { lat: 49.3812625, lng: 19.4583784 },
    { lat: 49.3812953, lng: 19.4582819 },
    { lat: 49.3813409, lng: 19.4580445 },
    { lat: 49.3814509, lng: 19.4580391 },
    { lat: 49.3815854, lng: 19.4580945 },
    { lat: 49.3817881, lng: 19.4579218 },
    { lat: 49.3819414, lng: 19.4578511 },
    { lat: 49.3821083, lng: 19.4578497 },
    { lat: 49.3822857, lng: 19.4576939 },
    { lat: 49.3825395, lng: 19.4575028 },
    { lat: 49.3826817, lng: 19.4574578 },
    { lat: 49.3827845, lng: 19.4572894 },
    { lat: 49.3829508, lng: 19.4571113 },
    { lat: 49.3831066, lng: 19.4569195 },
    { lat: 49.3832789, lng: 19.4567946 },
    { lat: 49.3835398, lng: 19.4566866 },
    { lat: 49.3835886, lng: 19.4566448 },
    { lat: 49.383802, lng: 19.4564598 },
    { lat: 49.3839402, lng: 19.4563516 },
    { lat: 49.3841517, lng: 19.4562948 },
    { lat: 49.3842662, lng: 19.4563089 },
    { lat: 49.384408, lng: 19.4563544 },
    { lat: 49.3844613, lng: 19.456141 },
    { lat: 49.3845205, lng: 19.4559354 },
    { lat: 49.3846074, lng: 19.4558888 },
    { lat: 49.3847174, lng: 19.455955 },
    { lat: 49.3848676, lng: 19.4559859 },
    { lat: 49.3849879, lng: 19.4561537 },
    { lat: 49.3850604, lng: 19.4561586 },
    { lat: 49.3851736, lng: 19.4561196 },
    { lat: 49.3853128, lng: 19.456114 },
    { lat: 49.3853573, lng: 19.4560029 },
    { lat: 49.385264, lng: 19.4558318 },
    { lat: 49.3853459, lng: 19.4555795 },
    { lat: 49.3854309, lng: 19.4555176 },
    { lat: 49.3855217, lng: 19.4555042 },
    { lat: 49.3856835, lng: 19.4556459 },
    { lat: 49.3857919, lng: 19.4556762 },
    { lat: 49.385877, lng: 19.4556109 },
    { lat: 49.3858959, lng: 19.4554632 },
    { lat: 49.3859308, lng: 19.455386 },
    { lat: 49.3860165, lng: 19.4553384 },
    { lat: 49.3861472, lng: 19.4552907 },
    { lat: 49.3863462, lng: 19.4550367 },
    { lat: 49.3865424, lng: 19.4547689 },
    { lat: 49.3866769, lng: 19.4546553 },
    { lat: 49.386825, lng: 19.4544708 },
    { lat: 49.3868931, lng: 19.4544067 },
    { lat: 49.3869557, lng: 19.4544437 },
    { lat: 49.3870764, lng: 19.4546455 },
    { lat: 49.3871409, lng: 19.4546758 },
    { lat: 49.3872653, lng: 19.4544913 },
    { lat: 49.3874324, lng: 19.4545193 },
    { lat: 49.3876024, lng: 19.4544424 },
    { lat: 49.387703, lng: 19.4542268 },
    { lat: 49.3876848, lng: 19.4540787 },
    { lat: 49.3877395, lng: 19.4539686 },
    { lat: 49.3878913, lng: 19.4538654 },
    { lat: 49.3880282, lng: 19.4538547 },
    { lat: 49.388144, lng: 19.4538979 },
    { lat: 49.3882186, lng: 19.4538511 },
    { lat: 49.3883418, lng: 19.4536397 },
    { lat: 49.3884582, lng: 19.4535718 },
    { lat: 49.3886575, lng: 19.4532743 },
    { lat: 49.3888713, lng: 19.4531496 },
    { lat: 49.3891562, lng: 19.4528741 },
    { lat: 49.3891141, lng: 19.452841 },
    { lat: 49.3889905, lng: 19.4524792 },
    { lat: 49.388944, lng: 19.4524235 },
    { lat: 49.388847, lng: 19.4522744 },
    { lat: 49.3887616, lng: 19.4520612 },
    { lat: 49.3886319, lng: 19.4518835 },
    { lat: 49.3884468, lng: 19.4515352 },
    { lat: 49.3883165, lng: 19.4513706 },
    { lat: 49.3881681, lng: 19.451183 },
    { lat: 49.3880361, lng: 19.4510077 },
    { lat: 49.3879517, lng: 19.4509347 },
    { lat: 49.3877819, lng: 19.4507747 },
    { lat: 49.3872283, lng: 19.4502459 },
    { lat: 49.3871561, lng: 19.4500012 },
    { lat: 49.3871151, lng: 19.4498953 },
    { lat: 49.3866466, lng: 19.4486239 },
    { lat: 49.3865856, lng: 19.4485003 },
    { lat: 49.3865716, lng: 19.4484363 },
    { lat: 49.3865588, lng: 19.4484059 },
    { lat: 49.3864479, lng: 19.4481235 },
    { lat: 49.386422, lng: 19.4480543 },
    { lat: 49.3863868, lng: 19.4479962 },
    { lat: 49.3862801, lng: 19.4478063 },
    { lat: 49.3862701, lng: 19.44779 },
    { lat: 49.3860893, lng: 19.4474315 },
    { lat: 49.3860121, lng: 19.4472721 },
    { lat: 49.3859573, lng: 19.4471593 },
    { lat: 49.3859386, lng: 19.4471216 },
    { lat: 49.3858507, lng: 19.4469716 },
    { lat: 49.3857713, lng: 19.4468174 },
    { lat: 49.3857319, lng: 19.4467392 },
    { lat: 49.3857046, lng: 19.4466853 },
    { lat: 49.385697, lng: 19.4466699 },
    { lat: 49.3856318, lng: 19.4465243 },
    { lat: 49.3855995, lng: 19.446458 },
    { lat: 49.385542, lng: 19.4463387 },
    { lat: 49.3854317, lng: 19.4461169 },
    { lat: 49.3853507, lng: 19.445951 },
    { lat: 49.3853232, lng: 19.4458928 },
    { lat: 49.385294, lng: 19.4458428 },
    { lat: 49.3851979, lng: 19.4457407 },
    { lat: 49.3850489, lng: 19.445658 },
    { lat: 49.3849051, lng: 19.4455701 },
    { lat: 49.3848936, lng: 19.4455667 },
    { lat: 49.3847602, lng: 19.4454852 },
    { lat: 49.384624, lng: 19.4453874 },
    { lat: 49.3846031, lng: 19.4453749 },
    { lat: 49.3843332, lng: 19.4453132 },
    { lat: 49.38396, lng: 19.4451607 },
    { lat: 49.3836774, lng: 19.4447728 },
    { lat: 49.3834155, lng: 19.4444206 },
    { lat: 49.3832664, lng: 19.4441166 },
    { lat: 49.3831551, lng: 19.4440277 },
    { lat: 49.3829398, lng: 19.4438528 },
    { lat: 49.3829046, lng: 19.4437253 },
    { lat: 49.3828542, lng: 19.4429731 },
    { lat: 49.3828389, lng: 19.4424192 },
    { lat: 49.3828005, lng: 19.4415352 },
    { lat: 49.3827915, lng: 19.4407756 },
    { lat: 49.3828271, lng: 19.4404972 },
    { lat: 49.3829417, lng: 19.4396324 },
    { lat: 49.3830871, lng: 19.4395386 },
    { lat: 49.3831772, lng: 19.438752 },
    { lat: 49.3831877, lng: 19.4386174 },
    { lat: 49.3832154, lng: 19.4386032 },
    { lat: 49.3832086, lng: 19.4384817 },
    { lat: 49.3832799, lng: 19.438193 },
    { lat: 49.3833716, lng: 19.4373234 },
    { lat: 49.3833337, lng: 19.4367481 },
    { lat: 49.3832486, lng: 19.436536 },
    { lat: 49.3831479, lng: 19.4362348 },
    { lat: 49.3830474, lng: 19.435935 },
    { lat: 49.3829889, lng: 19.435618 },
    { lat: 49.3829436, lng: 19.4353484 },
    { lat: 49.3829075, lng: 19.4347599 },
    { lat: 49.3828436, lng: 19.4344225 },
    { lat: 49.3828641, lng: 19.4342326 },
    { lat: 49.3828687, lng: 19.4341627 },
    { lat: 49.3828829, lng: 19.4339799 },
    { lat: 49.3827391, lng: 19.4340866 },
    { lat: 49.3826554, lng: 19.4340527 },
    { lat: 49.3825681, lng: 19.434018 },
    { lat: 49.382528, lng: 19.4340076 },
    { lat: 49.3823969, lng: 19.4339736 },
    { lat: 49.3823709, lng: 19.4339709 },
    { lat: 49.3822265, lng: 19.4339439 },
    { lat: 49.3814751, lng: 19.4337514 },
    { lat: 49.3813254, lng: 19.4338287 },
    { lat: 49.3811393, lng: 19.4339721 },
    { lat: 49.3811079, lng: 19.4339958 },
    { lat: 49.3810612, lng: 19.4341097 },
    { lat: 49.3810114, lng: 19.4342757 },
    { lat: 49.3809604, lng: 19.4343926 },
    { lat: 49.3809459, lng: 19.4344169 },
    { lat: 49.3808907, lng: 19.4345147 },
    { lat: 49.3808614, lng: 19.4346629 },
    { lat: 49.3808723, lng: 19.4347041 },
    { lat: 49.3808975, lng: 19.4348561 },
    { lat: 49.3809081, lng: 19.4348658 },
    { lat: 49.380968, lng: 19.4349219 },
    { lat: 49.3810295, lng: 19.4350567 },
    { lat: 49.3809866, lng: 19.4352013 },
    { lat: 49.380885, lng: 19.4352466 },
    { lat: 49.3808498, lng: 19.4352663 },
    { lat: 49.3807543, lng: 19.4352495 },
    { lat: 49.380679, lng: 19.4352551 },
    { lat: 49.3805517, lng: 19.4352801 },
    { lat: 49.3804986, lng: 19.4354236 },
    { lat: 49.380465, lng: 19.4355238 },
    { lat: 49.3803786, lng: 19.4356058 },
    { lat: 49.3803896, lng: 19.4356976 },
    { lat: 49.3804793, lng: 19.4357364 },
    { lat: 49.3803965, lng: 19.4359431 },
    { lat: 49.3802295, lng: 19.4359174 },
    { lat: 49.3801123, lng: 19.4358889 },
    { lat: 49.380051, lng: 19.435783 },
    { lat: 49.3799882, lng: 19.4358144 },
    { lat: 49.3799711, lng: 19.4359566 },
    { lat: 49.3799179, lng: 19.4361225 },
    { lat: 49.379739, lng: 19.4361035 },
    { lat: 49.3796884, lng: 19.436032 },
    { lat: 49.3796181, lng: 19.4360226 },
    { lat: 49.3794794, lng: 19.4359953 },
    { lat: 49.379334, lng: 19.4360421 },
    { lat: 49.3791745, lng: 19.4360279 },
    { lat: 49.3790712, lng: 19.4360354 },
    { lat: 49.3790256, lng: 19.4360968 },
    { lat: 49.3789292, lng: 19.4360851 },
    { lat: 49.3788742, lng: 19.4361164 },
    { lat: 49.3788139, lng: 19.4362213 },
    { lat: 49.3786558, lng: 19.4363628 },
    { lat: 49.3785791, lng: 19.4364347 },
    { lat: 49.3784585, lng: 19.4365786 },
    { lat: 49.3784127, lng: 19.436712 },
    { lat: 49.3783695, lng: 19.4367535 },
    { lat: 49.3781717, lng: 19.4369803 },
    { lat: 49.3781265, lng: 19.4369588 },
    { lat: 49.3780057, lng: 19.4369491 },
    { lat: 49.3779416, lng: 19.4369068 },
    { lat: 49.3779356, lng: 19.4369953 },
    { lat: 49.3779796, lng: 19.4371667 },
    { lat: 49.3780158, lng: 19.4372398 },
    { lat: 49.3779878, lng: 19.4373188 },
    { lat: 49.3779221, lng: 19.4373382 },
    { lat: 49.3778686, lng: 19.4372995 },
    { lat: 49.3777681, lng: 19.4373223 },
    { lat: 49.3776674, lng: 19.437387 },
    { lat: 49.3776344, lng: 19.4374278 },
    { lat: 49.377583, lng: 19.4374598 },
    { lat: 49.3775388, lng: 19.4374812 },
    { lat: 49.3774991, lng: 19.4375003 },
    { lat: 49.3775007, lng: 19.4375099 },
    { lat: 49.3774892, lng: 19.4377256 },
    { lat: 49.3774832, lng: 19.4378879 },
    { lat: 49.3774567, lng: 19.4380708 },
    { lat: 49.377483, lng: 19.4381494 },
    { lat: 49.3775181, lng: 19.4382298 },
    { lat: 49.3775335, lng: 19.4383648 },
    { lat: 49.3775017, lng: 19.4384847 },
    { lat: 49.3773881, lng: 19.4384171 },
    { lat: 49.3773308, lng: 19.4384175 },
    { lat: 49.3773026, lng: 19.4383959 },
    { lat: 49.3771967, lng: 19.4383933 },
    { lat: 49.3770538, lng: 19.438352 },
    { lat: 49.3770014, lng: 19.4384328 },
    { lat: 49.3769761, lng: 19.4384743 },
    { lat: 49.376918, lng: 19.4385297 },
    { lat: 49.3768853, lng: 19.4385311 },
    { lat: 49.3768432, lng: 19.4385208 },
    { lat: 49.3767798, lng: 19.4384398 },
    { lat: 49.376699, lng: 19.4384236 },
    { lat: 49.3765839, lng: 19.4384928 },
    { lat: 49.3763183, lng: 19.4384777 },
    { lat: 49.3760841, lng: 19.4386028 },
    { lat: 49.375967, lng: 19.4384593 },
    { lat: 49.3759117, lng: 19.4383817 },
    { lat: 49.3758759, lng: 19.4381213 },
    { lat: 49.3757351, lng: 19.4379705 },
    { lat: 49.3754172, lng: 19.4378711 },
    { lat: 49.3753053, lng: 19.4378882 },
    { lat: 49.3752011, lng: 19.4378546 },
    { lat: 49.3748537, lng: 19.4376995 },
    { lat: 49.3746838, lng: 19.4376813 },
    { lat: 49.3744245, lng: 19.437726 },
    { lat: 49.3743587, lng: 19.4378875 },
    { lat: 49.3742996, lng: 19.4379973 },
    { lat: 49.3742059, lng: 19.4380916 },
    { lat: 49.3741538, lng: 19.4381855 },
    { lat: 49.3740806, lng: 19.4382321 },
    { lat: 49.3738616, lng: 19.4381986 },
    { lat: 49.3737297, lng: 19.438368 },
    { lat: 49.373544, lng: 19.4385446 },
    { lat: 49.3734821, lng: 19.4385499 },
    { lat: 49.3734673, lng: 19.4385468 },
    { lat: 49.3734155, lng: 19.4384732 },
    { lat: 49.3734451, lng: 19.4383315 },
    { lat: 49.3734989, lng: 19.4382747 },
    { lat: 49.3734772, lng: 19.4382221 },
    { lat: 49.3733003, lng: 19.438196 },
    { lat: 49.3731841, lng: 19.4381706 },
    { lat: 49.3731478, lng: 19.4381149 },
    { lat: 49.3731073, lng: 19.4380914 },
    { lat: 49.3730104, lng: 19.4378943 },
    { lat: 49.3729558, lng: 19.4378586 },
    { lat: 49.3728223, lng: 19.4379486 },
    { lat: 49.3727689, lng: 19.4379363 },
    { lat: 49.3726432, lng: 19.4378736 },
    { lat: 49.372556, lng: 19.4378657 },
    { lat: 49.3724658, lng: 19.4377897 },
    { lat: 49.3723495, lng: 19.4374895 },
    { lat: 49.3722865, lng: 19.4372248 },
    { lat: 49.3722798, lng: 19.4371749 },
    { lat: 49.3721758, lng: 19.4369999 },
    { lat: 49.3720255, lng: 19.4368694 },
    { lat: 49.3719151, lng: 19.4366962 },
    { lat: 49.3717969, lng: 19.4365755 },
    { lat: 49.3717438, lng: 19.4365495 },
    { lat: 49.3716451, lng: 19.4364119 },
    { lat: 49.3713745, lng: 19.4359197 },
    { lat: 49.3711905, lng: 19.4359852 },
    { lat: 49.3708931, lng: 19.4356174 },
    { lat: 49.3707414, lng: 19.4356056 },
    { lat: 49.370597, lng: 19.4357008 },
    { lat: 49.3705066, lng: 19.4355208 },
    { lat: 49.370534, lng: 19.4353309 },
    { lat: 49.3704427, lng: 19.4352825 },
    { lat: 49.3703956, lng: 19.4352894 },
    { lat: 49.3702252, lng: 19.435476 },
    { lat: 49.3701294, lng: 19.4355026 },
    { lat: 49.3700532, lng: 19.4354638 },
    { lat: 49.3700366, lng: 19.4354667 },
    { lat: 49.3696963, lng: 19.4354185 },
    { lat: 49.3695587, lng: 19.4354146 },
    { lat: 49.3693875, lng: 19.435517 },
    { lat: 49.3693021, lng: 19.4355183 },
    { lat: 49.3691927, lng: 19.4352973 },
    { lat: 49.3689879, lng: 19.4350348 },
    { lat: 49.3689231, lng: 19.435022 },
    { lat: 49.368865, lng: 19.4353753 },
    { lat: 49.3687596, lng: 19.4353376 },
    { lat: 49.3687176, lng: 19.4352347 },
    { lat: 49.3687583, lng: 19.4351128 },
    { lat: 49.3686362, lng: 19.4351076 },
    { lat: 49.3685768, lng: 19.4351867 },
    { lat: 49.3685076, lng: 19.4352473 },
    { lat: 49.3684818, lng: 19.4352779 },
    { lat: 49.3684508, lng: 19.4352144 },
    { lat: 49.3682885, lng: 19.4350902 },
    { lat: 49.3682217, lng: 19.4351365 },
    { lat: 49.3681539, lng: 19.4351301 },
    { lat: 49.3681091, lng: 19.4350138 },
    { lat: 49.3680182, lng: 19.4349481 },
    { lat: 49.3679172, lng: 19.4349066 },
    { lat: 49.3678068, lng: 19.4351763 },
    { lat: 49.3677312, lng: 19.4352257 },
    { lat: 49.3676486, lng: 19.4352418 },
    { lat: 49.367417, lng: 19.4353959 },
    { lat: 49.3672194, lng: 19.4355616 },
    { lat: 49.3671683, lng: 19.4356011 },
    { lat: 49.366915, lng: 19.43573 },
    { lat: 49.366785, lng: 19.4358648 },
    { lat: 49.3666581, lng: 19.4360214 },
    { lat: 49.3666278, lng: 19.4362437 },
    { lat: 49.3664918, lng: 19.4366189 },
    { lat: 49.3664071, lng: 19.43664 },
    { lat: 49.3662919, lng: 19.4364872 },
    { lat: 49.3661983, lng: 19.4365317 },
    { lat: 49.366081, lng: 19.4367009 },
    { lat: 49.3660043, lng: 19.4368205 },
    { lat: 49.3657799, lng: 19.4367204 },
    { lat: 49.3656586, lng: 19.4367787 },
    { lat: 49.3655109, lng: 19.4369245 },
    { lat: 49.365398, lng: 19.4371847 },
    { lat: 49.3653915, lng: 19.4372884 },
    { lat: 49.3654047, lng: 19.4374262 },
    { lat: 49.3653002, lng: 19.4375311 },
    { lat: 49.3652588, lng: 19.4375923 },
    { lat: 49.3652109, lng: 19.4377721 },
    { lat: 49.3651443, lng: 19.4381343 },
    { lat: 49.3650733, lng: 19.4383835 },
    { lat: 49.3650898, lng: 19.4386167 },
    { lat: 49.3651821, lng: 19.438832 },
    { lat: 49.3651997, lng: 19.4389743 },
    { lat: 49.3651218, lng: 19.4390923 },
    { lat: 49.3651298, lng: 19.4392646 },
    { lat: 49.3651492, lng: 19.4393955 },
    { lat: 49.365159, lng: 19.4395554 },
    { lat: 49.3651967, lng: 19.4396406 },
    { lat: 49.365285, lng: 19.4396003 },
    { lat: 49.3653376, lng: 19.4395943 },
    { lat: 49.3653953, lng: 19.4396466 },
    { lat: 49.3654123, lng: 19.4397702 },
    { lat: 49.3653131, lng: 19.4399892 },
    { lat: 49.3652896, lng: 19.440239 },
    { lat: 49.3652768, lng: 19.4403496 },
    { lat: 49.3655307, lng: 19.4407253 },
    { lat: 49.3654671, lng: 19.4408619 },
    { lat: 49.3654696, lng: 19.4409159 },
    { lat: 49.3654755, lng: 19.441045 },
    { lat: 49.365387, lng: 19.4412303 },
    { lat: 49.3652619, lng: 19.4415431 },
    { lat: 49.3652074, lng: 19.4417356 },
    { lat: 49.3652286, lng: 19.4418248 },
    { lat: 49.3653726, lng: 19.4418924 },
    { lat: 49.3653807, lng: 19.4419504 },
    { lat: 49.3653366, lng: 19.4421862 },
    { lat: 49.3652796, lng: 19.4423427 },
    { lat: 49.36518, lng: 19.4424583 },
    { lat: 49.3650917, lng: 19.4426475 },
    { lat: 49.3650669, lng: 19.4426687 },
    { lat: 49.3649584, lng: 19.4427371 },
    { lat: 49.3648004, lng: 19.4427773 },
    { lat: 49.3647139, lng: 19.4427852 },
    { lat: 49.3645633, lng: 19.4428423 },
    { lat: 49.3645195, lng: 19.4427993 },
    { lat: 49.3644547, lng: 19.4427094 },
    { lat: 49.3643602, lng: 19.4426878 },
    { lat: 49.3643187, lng: 19.4426431 },
    { lat: 49.3641752, lng: 19.4424142 },
    { lat: 49.3640465, lng: 19.4425312 },
    { lat: 49.3639313, lng: 19.442818 },
    { lat: 49.3638202, lng: 19.4428763 },
    { lat: 49.3637712, lng: 19.4428895 },
    { lat: 49.3636864, lng: 19.4429345 },
    { lat: 49.3636386, lng: 19.4429736 },
    { lat: 49.3635944, lng: 19.4429415 },
    { lat: 49.3635237, lng: 19.4430731 },
    { lat: 49.3634332, lng: 19.4431378 },
    { lat: 49.3631325, lng: 19.4434577 },
    { lat: 49.3630096, lng: 19.4435068 },
    { lat: 49.3629113, lng: 19.443402 },
    { lat: 49.362821, lng: 19.4435709 },
    { lat: 49.362675, lng: 19.4435537 },
    { lat: 49.3625729, lng: 19.4436374 },
    { lat: 49.3624844, lng: 19.4438176 },
    { lat: 49.3624495, lng: 19.4438441 },
    { lat: 49.3623733, lng: 19.443903 },
    { lat: 49.3622667, lng: 19.4439166 },
    { lat: 49.3621528, lng: 19.4439607 },
    { lat: 49.3621059, lng: 19.4441881 },
    { lat: 49.3620068, lng: 19.4443112 },
    { lat: 49.3617769, lng: 19.4444564 },
    { lat: 49.3616351, lng: 19.4445368 },
    { lat: 49.3614149, lng: 19.4446723 },
    { lat: 49.3613129, lng: 19.4447332 },
    { lat: 49.3611332, lng: 19.4448198 },
    { lat: 49.3610506, lng: 19.4448883 },
    { lat: 49.3609502, lng: 19.4448811 },
    { lat: 49.3608342, lng: 19.4450082 },
    { lat: 49.360842, lng: 19.4453009 },
    { lat: 49.3607765, lng: 19.4454688 },
    { lat: 49.3607445, lng: 19.4456042 },
    { lat: 49.3606924, lng: 19.4457693 },
    { lat: 49.3606555, lng: 19.4458709 },
    { lat: 49.3606428, lng: 19.445989 },
    { lat: 49.3606303, lng: 19.4461339 },
    { lat: 49.3607021, lng: 19.4463242 },
    { lat: 49.3607547, lng: 19.4464143 },
    { lat: 49.3607065, lng: 19.4465894 },
    { lat: 49.3606108, lng: 19.4467617 },
    { lat: 49.3605321, lng: 19.4469401 },
    { lat: 49.3605067, lng: 19.4473886 },
    { lat: 49.360505, lng: 19.4476237 },
    { lat: 49.3605494, lng: 19.4478282 },
    { lat: 49.360532, lng: 19.4480622 },
    { lat: 49.3604336, lng: 19.4482011 },
    { lat: 49.3604009, lng: 19.4482513 },
    { lat: 49.3603487, lng: 19.4482659 },
    { lat: 49.3603078, lng: 19.448407 },
    { lat: 49.3603223, lng: 19.4484517 },
    { lat: 49.3602998, lng: 19.4485008 },
    { lat: 49.3603446, lng: 19.4486185 },
    { lat: 49.3602801, lng: 19.4488828 },
    { lat: 49.3601089, lng: 19.4490321 },
    { lat: 49.3600843, lng: 19.4491808 },
    { lat: 49.3601092, lng: 19.4492808 },
    { lat: 49.3600214, lng: 19.4495024 },
    { lat: 49.3599557, lng: 19.4496451 },
    { lat: 49.3598154, lng: 19.4498272 },
    { lat: 49.3597807, lng: 19.4499557 },
    { lat: 49.3597064, lng: 19.4500295 },
    { lat: 49.359665, lng: 19.4501362 },
    { lat: 49.3596434, lng: 19.4502781 },
    { lat: 49.359507, lng: 19.450372 },
    { lat: 49.359437, lng: 19.4504457 },
    { lat: 49.3593905, lng: 19.4505584 },
    { lat: 49.359326, lng: 19.4506044 },
    { lat: 49.3592034, lng: 19.4507528 },
    { lat: 49.3590388, lng: 19.4509263 },
    { lat: 49.3588718, lng: 19.4510173 },
    { lat: 49.3587687, lng: 19.4512956 },
    { lat: 49.358741, lng: 19.4513824 },
    { lat: 49.358709, lng: 19.4516664 },
    { lat: 49.3585197, lng: 19.4520324 },
    { lat: 49.3584327, lng: 19.4524722 },
    { lat: 49.3583883, lng: 19.4527532 },
    { lat: 49.3583597, lng: 19.4528203 },
    { lat: 49.3582785, lng: 19.4532584 },
    { lat: 49.3582352, lng: 19.4535892 },
    { lat: 49.3581277, lng: 19.4540703 },
    { lat: 49.3581243, lng: 19.4543671 },
    { lat: 49.3581009, lng: 19.4545933 },
    { lat: 49.3580555, lng: 19.4549763 },
    { lat: 49.3579902, lng: 19.4551991 },
    { lat: 49.3579096, lng: 19.4553828 },
    { lat: 49.3578871, lng: 19.4554322 },
    { lat: 49.3576412, lng: 19.4556976 },
    { lat: 49.3573997, lng: 19.455883 },
    { lat: 49.357199, lng: 19.4560785 },
    { lat: 49.3570753, lng: 19.4561818 },
    { lat: 49.3569353, lng: 19.4562482 },
    { lat: 49.356802, lng: 19.4564357 },
    { lat: 49.3566468, lng: 19.4565178 },
    { lat: 49.3565793, lng: 19.4566917 },
    { lat: 49.3564739, lng: 19.4568249 },
    { lat: 49.3563414, lng: 19.456931 },
    { lat: 49.3562378, lng: 19.4571536 },
    { lat: 49.356027, lng: 19.4575695 },
    { lat: 49.3558351, lng: 19.4578074 },
    { lat: 49.3557732, lng: 19.4579055 },
    { lat: 49.3556523, lng: 19.4580942 },
    { lat: 49.3556164, lng: 19.4581971 },
    { lat: 49.3555065, lng: 19.458381 },
    { lat: 49.3554086, lng: 19.4584811 },
    { lat: 49.3552936, lng: 19.4586504 },
    { lat: 49.3550245, lng: 19.4590473 },
    { lat: 49.3549048, lng: 19.459289 },
    { lat: 49.3548344, lng: 19.4595445 },
    { lat: 49.3547482, lng: 19.4598021 },
    { lat: 49.3547251, lng: 19.4599166 },
    { lat: 49.3546536, lng: 19.4599984 },
    { lat: 49.3544786, lng: 19.4603111 },
    { lat: 49.3543298, lng: 19.4605581 },
    { lat: 49.3542597, lng: 19.4605728 },
    { lat: 49.3541608, lng: 19.4606308 },
    { lat: 49.3541186, lng: 19.4606682 },
    { lat: 49.3540178, lng: 19.4607828 },
    { lat: 49.3537982, lng: 19.4610034 },
    { lat: 49.3536607, lng: 19.4612727 },
    { lat: 49.353528, lng: 19.4614019 },
    { lat: 49.3534109, lng: 19.4615515 },
    { lat: 49.3525955, lng: 19.463031 },
    { lat: 49.3521965, lng: 19.4637874 },
    { lat: 49.352, lng: 19.46415 },
  ],
  Námestovo: [
    { lat: 49.5310826, lng: 19.3187944 },
    { lat: 49.5235447, lng: 19.3263687 },
    { lat: 49.5217578, lng: 19.3346445 },
    { lat: 49.5195472, lng: 19.3371529 },
    { lat: 49.5195439, lng: 19.3371573 },
    { lat: 49.5182439, lng: 19.3386322 },
    { lat: 49.5174352, lng: 19.3395509 },
    { lat: 49.5131441, lng: 19.3444181 },
    { lat: 49.5132686, lng: 19.3457534 },
    { lat: 49.5132587, lng: 19.3458078 },
    { lat: 49.5130114, lng: 19.347146 },
    { lat: 49.5130657, lng: 19.3483349 },
    { lat: 49.5130773, lng: 19.3485827 },
    { lat: 49.5129389, lng: 19.3489446 },
    { lat: 49.5128192, lng: 19.3492541 },
    { lat: 49.5127809, lng: 19.3493544 },
    { lat: 49.5118827, lng: 19.3527525 },
    { lat: 49.511721, lng: 19.3533661 },
    { lat: 49.5115607, lng: 19.3544959 },
    { lat: 49.5115537, lng: 19.354517 },
    { lat: 49.5112886, lng: 19.3552841 },
    { lat: 49.5112798, lng: 19.3553119 },
    { lat: 49.5112624, lng: 19.3554193 },
    { lat: 49.5111641, lng: 19.3560455 },
    { lat: 49.5111269, lng: 19.3561141 },
    { lat: 49.5103579, lng: 19.3575324 },
    { lat: 49.5103559, lng: 19.3575355 },
    { lat: 49.5098855, lng: 19.3579586 },
    { lat: 49.5096559, lng: 19.3586148 },
    { lat: 49.5091172, lng: 19.3590025 },
    { lat: 49.5090998, lng: 19.3593046 },
    { lat: 49.508835, lng: 19.3597667 },
    { lat: 49.508733, lng: 19.359944 },
    { lat: 49.5086883, lng: 19.3600232 },
    { lat: 49.5085538, lng: 19.3602585 },
    { lat: 49.5084527, lng: 19.3604346 },
    { lat: 49.5083443, lng: 19.3606227 },
    { lat: 49.5081869, lng: 19.3608978 },
    { lat: 49.5078369, lng: 19.3621169 },
    { lat: 49.5077924, lng: 19.3622722 },
    { lat: 49.5077738, lng: 19.3623345 },
    { lat: 49.507643, lng: 19.3627912 },
    { lat: 49.5062566, lng: 19.3696795 },
    { lat: 49.5070805, lng: 19.3703364 },
    { lat: 49.5077787, lng: 19.3710586 },
    { lat: 49.5087471, lng: 19.3720604 },
    { lat: 49.5090648, lng: 19.3728596 },
    { lat: 49.5095352, lng: 19.3735731 },
    { lat: 49.509598, lng: 19.3740303 },
    { lat: 49.5095014, lng: 19.3742777 },
    { lat: 49.5096169, lng: 19.3750156 },
    { lat: 49.5096865, lng: 19.3751059 },
    { lat: 49.5100831, lng: 19.3768522 },
    { lat: 49.510131, lng: 19.3770352 },
    { lat: 49.5101573, lng: 19.3771378 },
    { lat: 49.5101817, lng: 19.3772309 },
    { lat: 49.5102206, lng: 19.3775094 },
    { lat: 49.5106524, lng: 19.3791671 },
    { lat: 49.5111475, lng: 19.3805093 },
    { lat: 49.511786, lng: 19.3818836 },
    { lat: 49.5126309, lng: 19.3832391 },
    { lat: 49.5129072, lng: 19.3839975 },
    { lat: 49.5130446, lng: 19.3846998 },
    { lat: 49.5138863, lng: 19.3837062 },
    { lat: 49.5147188, lng: 19.383264 },
    { lat: 49.5147688, lng: 19.3832524 },
    { lat: 49.5149358, lng: 19.3831576 },
    { lat: 49.5157751, lng: 19.3830585 },
    { lat: 49.5162558, lng: 19.3827328 },
    { lat: 49.5168653, lng: 19.3828775 },
    { lat: 49.5176529, lng: 19.3828758 },
    { lat: 49.5179934, lng: 19.3831707 },
    { lat: 49.5193668, lng: 19.3829514 },
    { lat: 49.5194899, lng: 19.3829126 },
    { lat: 49.5203655, lng: 19.3824272 },
    { lat: 49.5209585, lng: 19.3821708 },
    { lat: 49.5212548, lng: 19.3820672 },
    { lat: 49.5212858, lng: 19.3820584 },
    { lat: 49.5214314, lng: 19.382062 },
    { lat: 49.5221193, lng: 19.381784 },
    { lat: 49.5232525, lng: 19.3813139 },
    { lat: 49.5232879, lng: 19.3813038 },
    { lat: 49.5238154, lng: 19.3812591 },
    { lat: 49.5238448, lng: 19.3812621 },
    { lat: 49.5255435, lng: 19.3811783 },
    { lat: 49.5257797, lng: 19.3811441 },
    { lat: 49.5258848, lng: 19.3811032 },
    { lat: 49.5259002, lng: 19.380947 },
    { lat: 49.5258566, lng: 19.3807345 },
    { lat: 49.526074, lng: 19.3803015 },
    { lat: 49.5261797, lng: 19.3797858 },
    { lat: 49.525909, lng: 19.3793671 },
    { lat: 49.5259411, lng: 19.379253 },
    { lat: 49.5260499, lng: 19.3791257 },
    { lat: 49.5257729, lng: 19.3787125 },
    { lat: 49.5258651, lng: 19.3783498 },
    { lat: 49.5257171, lng: 19.3779485 },
    { lat: 49.5258233, lng: 19.3777385 },
    { lat: 49.5257732, lng: 19.3775543 },
    { lat: 49.5253855, lng: 19.3770915 },
    { lat: 49.525393, lng: 19.3769191 },
    { lat: 49.5252838, lng: 19.3768694 },
    { lat: 49.525129, lng: 19.3765465 },
    { lat: 49.5250182, lng: 19.3757622 },
    { lat: 49.5247822, lng: 19.3755316 },
    { lat: 49.5248127, lng: 19.375217 },
    { lat: 49.5247255, lng: 19.374894 },
    { lat: 49.5247604, lng: 19.3745285 },
    { lat: 49.5242997, lng: 19.3735878 },
    { lat: 49.5241332, lng: 19.3736169 },
    { lat: 49.5240815, lng: 19.373302 },
    { lat: 49.5239146, lng: 19.3724752 },
    { lat: 49.5239099, lng: 19.3722312 },
    { lat: 49.5240209, lng: 19.37212 },
    { lat: 49.5240774, lng: 19.3717616 },
    { lat: 49.5240804, lng: 19.3716068 },
    { lat: 49.5240822, lng: 19.3714981 },
    { lat: 49.5243435, lng: 19.3713295 },
    { lat: 49.5243758, lng: 19.3713433 },
    { lat: 49.5274394, lng: 19.3667657 },
    { lat: 49.5287208, lng: 19.3649535 },
    { lat: 49.5296601, lng: 19.3637319 },
    { lat: 49.5308147, lng: 19.3623488 },
    { lat: 49.5309431, lng: 19.3625139 },
    { lat: 49.5313254, lng: 19.3628844 },
    { lat: 49.5314518, lng: 19.3629376 },
    { lat: 49.5314811, lng: 19.3630348 },
    { lat: 49.5323142, lng: 19.3628479 },
    { lat: 49.5324707, lng: 19.3629188 },
    { lat: 49.5325242, lng: 19.3628868 },
    { lat: 49.5325791, lng: 19.3628539 },
    { lat: 49.5326854, lng: 19.3627875 },
    { lat: 49.5329038, lng: 19.3628799 },
    { lat: 49.5331213, lng: 19.3631959 },
    { lat: 49.5332546, lng: 19.3632539 },
    { lat: 49.533456, lng: 19.3637059 },
    { lat: 49.5337313, lng: 19.3640758 },
    { lat: 49.5338392, lng: 19.3643647 },
    { lat: 49.5342046, lng: 19.3648284 },
    { lat: 49.5345027, lng: 19.3653743 },
    { lat: 49.5349068, lng: 19.3658023 },
    { lat: 49.5354133, lng: 19.3662748 },
    { lat: 49.5355099, lng: 19.3662925 },
    { lat: 49.537774, lng: 19.364915 },
    { lat: 49.5369398, lng: 19.3637161 },
    { lat: 49.5360882, lng: 19.3622997 },
    { lat: 49.5355952, lng: 19.3600039 },
    { lat: 49.535574, lng: 19.355852 },
    { lat: 49.5355793, lng: 19.3557344 },
    { lat: 49.5357432, lng: 19.3520638 },
    { lat: 49.536102, lng: 19.3501563 },
    { lat: 49.535999, lng: 19.3484783 },
    { lat: 49.5359066, lng: 19.3468956 },
    { lat: 49.5349907, lng: 19.3440948 },
    { lat: 49.5348958, lng: 19.3436296 },
    { lat: 49.5341163, lng: 19.3398083 },
    { lat: 49.5340986, lng: 19.3374913 },
    { lat: 49.5339104, lng: 19.3358282 },
    { lat: 49.5335902, lng: 19.3329996 },
    { lat: 49.5321058, lng: 19.3313307 },
    { lat: 49.531766, lng: 19.3295583 },
    { lat: 49.5310826, lng: 19.3187944 },
  ],
  NámestovoExt: [
    { lat: 49.4360233, lng: 19.5221126 },
    { lat: 49.4360539, lng: 19.5220949 },
    { lat: 49.4360749, lng: 19.5220829 },
    { lat: 49.4362978, lng: 19.5219541 },
    { lat: 49.4362652, lng: 19.5216104 },
    { lat: 49.436253, lng: 19.5214861 },
    { lat: 49.4362431, lng: 19.5213938 },
    { lat: 49.436232, lng: 19.5212719 },
    { lat: 49.4362627, lng: 19.5209073 },
    { lat: 49.4362958, lng: 19.5200704 },
    { lat: 49.4363074, lng: 19.5199044 },
    { lat: 49.4363261, lng: 19.5196964 },
    { lat: 49.4363415, lng: 19.5195355 },
    { lat: 49.4363556, lng: 19.519379 },
    { lat: 49.4363679, lng: 19.5192492 },
    { lat: 49.436386, lng: 19.519029 },
    { lat: 49.4364353, lng: 19.518851 },
    { lat: 49.4364916, lng: 19.5179509 },
    { lat: 49.4365227, lng: 19.517247 },
    { lat: 49.4365322, lng: 19.5172058 },
    { lat: 49.4365893, lng: 19.5160279 },
    { lat: 49.4365907, lng: 19.5158883 },
    { lat: 49.4362699, lng: 19.5159921 },
    { lat: 49.4362689, lng: 19.5159719 },
    { lat: 49.4362736, lng: 19.5159497 },
    { lat: 49.4364594, lng: 19.5148818 },
    { lat: 49.4365099, lng: 19.5144543 },
    { lat: 49.4365422, lng: 19.5141732 },
    { lat: 49.4365317, lng: 19.5133938 },
    { lat: 49.4365313, lng: 19.5133601 },
    { lat: 49.436531, lng: 19.5133537 },
    { lat: 49.4365313, lng: 19.5133344 },
    { lat: 49.4365303, lng: 19.5132904 },
    { lat: 49.4365294, lng: 19.5132184 },
    { lat: 49.4365282, lng: 19.5131925 },
    { lat: 49.436524, lng: 19.5129544 },
    { lat: 49.4365303, lng: 19.512667 },
    { lat: 49.4365632, lng: 19.5111853 },
    { lat: 49.436562, lng: 19.5109831 },
    { lat: 49.4365679, lng: 19.5109147 },
    { lat: 49.4366222, lng: 19.5095318 },
    { lat: 49.4365869, lng: 19.5091739 },
    { lat: 49.4366248, lng: 19.5091409 },
    { lat: 49.4366347, lng: 19.5089114 },
    { lat: 49.4366466, lng: 19.5086028 },
    { lat: 49.4366394, lng: 19.5082969 },
    { lat: 49.4366358, lng: 19.5079432 },
    { lat: 49.4366265, lng: 19.5074891 },
    { lat: 49.4366222, lng: 19.5072753 },
    { lat: 49.4366176, lng: 19.5070683 },
    { lat: 49.4366214, lng: 19.5070064 },
    { lat: 49.4366322, lng: 19.5068709 },
    { lat: 49.4367257, lng: 19.5068961 },
    { lat: 49.4367303, lng: 19.5068011 },
    { lat: 49.4367366, lng: 19.5066643 },
    { lat: 49.4367594, lng: 19.5053303 },
    { lat: 49.4367657, lng: 19.5052203 },
    { lat: 49.4367672, lng: 19.5052078 },
    { lat: 49.4367687, lng: 19.5051671 },
    { lat: 49.4367743, lng: 19.5050883 },
    { lat: 49.4367881, lng: 19.5048465 },
    { lat: 49.4367931, lng: 19.5047594 },
    { lat: 49.4367941, lng: 19.5047347 },
    { lat: 49.4368217, lng: 19.5042712 },
    { lat: 49.4366489, lng: 19.5043378 },
    { lat: 49.4362535, lng: 19.5044898 },
    { lat: 49.435763, lng: 19.5044043 },
    { lat: 49.4354061, lng: 19.5044113 },
    { lat: 49.435141, lng: 19.504276 },
    { lat: 49.4348975, lng: 19.5042014 },
    { lat: 49.4348264, lng: 19.504201 },
    { lat: 49.4348084, lng: 19.5042007 },
    { lat: 49.4347792, lng: 19.504201 },
    { lat: 49.4347584, lng: 19.5041909 },
    { lat: 49.4347365, lng: 19.5041809 },
    { lat: 49.4347019, lng: 19.504164 },
    { lat: 49.4346405, lng: 19.504147 },
    { lat: 49.4345398, lng: 19.5040662 },
    { lat: 49.4345102, lng: 19.5040078 },
    { lat: 49.4344755, lng: 19.5039409 },
    { lat: 49.4344524, lng: 19.5039249 },
    { lat: 49.4343843, lng: 19.5038924 },
    { lat: 49.4343336, lng: 19.5038681 },
    { lat: 49.4342631, lng: 19.5038289 },
    { lat: 49.4341914, lng: 19.5037884 },
    { lat: 49.4337986, lng: 19.5035705 },
    { lat: 49.43372, lng: 19.5035561 },
    { lat: 49.4335411, lng: 19.503484 },
    { lat: 49.4334171, lng: 19.5034364 },
    { lat: 49.4333302, lng: 19.5033845 },
    { lat: 49.4332246, lng: 19.5033162 },
    { lat: 49.433079, lng: 19.5032087 },
    { lat: 49.4328646, lng: 19.5030299 },
    { lat: 49.4326786, lng: 19.5028543 },
    { lat: 49.4326395, lng: 19.5028093 },
    { lat: 49.432612, lng: 19.5027771 },
    { lat: 49.432518, lng: 19.5026666 },
    { lat: 49.4324073, lng: 19.5025583 },
    { lat: 49.431737, lng: 19.5021419 },
    { lat: 49.4316107, lng: 19.5020653 },
    { lat: 49.4315655, lng: 19.5020361 },
    { lat: 49.4315516, lng: 19.5020264 },
    { lat: 49.4315294, lng: 19.5020117 },
    { lat: 49.4312897, lng: 19.5017709 },
    { lat: 49.4311009, lng: 19.5015533 },
    { lat: 49.4305254, lng: 19.5005962 },
    { lat: 49.430508, lng: 19.5005615 },
    { lat: 49.4304264, lng: 19.5004015 },
    { lat: 49.4303101, lng: 19.5001707 },
    { lat: 49.4301954, lng: 19.5000252 },
    { lat: 49.4298568, lng: 19.4995944 },
    { lat: 49.429827, lng: 19.4995563 },
    { lat: 49.429809, lng: 19.4995347 },
    { lat: 49.4297111, lng: 19.4994116 },
    { lat: 49.4288245, lng: 19.4983003 },
    { lat: 49.4280535, lng: 19.497378 },
    { lat: 49.4280178, lng: 19.4973358 },
    { lat: 49.4279821, lng: 19.4972941 },
    { lat: 49.4276448, lng: 19.4969363 },
    { lat: 49.426883, lng: 19.4961394 },
    { lat: 49.4266603, lng: 19.4959008 },
    { lat: 49.4266389, lng: 19.4958752 },
    { lat: 49.4264036, lng: 19.4955822 },
    { lat: 49.4258847, lng: 19.4950293 },
    { lat: 49.425442, lng: 19.4945569 },
    { lat: 49.4253353, lng: 19.4944431 },
    { lat: 49.4249581, lng: 19.4939514 },
    { lat: 49.4249415, lng: 19.4939351 },
    { lat: 49.4246702, lng: 19.4936181 },
    { lat: 49.423362, lng: 19.4921041 },
    { lat: 49.4222814, lng: 19.4907935 },
    { lat: 49.4216496, lng: 19.4900316 },
    { lat: 49.4214894, lng: 19.489832 },
    { lat: 49.4214238, lng: 19.489653 },
    { lat: 49.421361, lng: 19.4894871 },
    { lat: 49.4212351, lng: 19.4891477 },
    { lat: 49.4211908, lng: 19.489062 },
    { lat: 49.4211894, lng: 19.4890324 },
    { lat: 49.4211872, lng: 19.4889632 },
    { lat: 49.4211859, lng: 19.4889355 },
    { lat: 49.4211672, lng: 19.4888455 },
    { lat: 49.4211372, lng: 19.4887058 },
    { lat: 49.4212008, lng: 19.4884174 },
    { lat: 49.4212384, lng: 19.4881086 },
    { lat: 49.4212501, lng: 19.4880169 },
    { lat: 49.4210969, lng: 19.4877952 },
    { lat: 49.4210883, lng: 19.4875333 },
    { lat: 49.4213153, lng: 19.4869006 },
    { lat: 49.4214402, lng: 19.4864551 },
    { lat: 49.4214297, lng: 19.4862724 },
    { lat: 49.4215334, lng: 19.4854075 },
    { lat: 49.4217113, lng: 19.4852096 },
    { lat: 49.4216087, lng: 19.4848645 },
    { lat: 49.421782, lng: 19.4845126 },
    { lat: 49.42189, lng: 19.4840131 },
    { lat: 49.4220772, lng: 19.4838931 },
    { lat: 49.4221422, lng: 19.4834439 },
    { lat: 49.4219304, lng: 19.4830471 },
    { lat: 49.4221612, lng: 19.4825276 },
    { lat: 49.4222188, lng: 19.482107 },
    { lat: 49.4220632, lng: 19.4817871 },
    { lat: 49.4218, lng: 19.4813988 },
    { lat: 49.4216082, lng: 19.4810245 },
    { lat: 49.4216563, lng: 19.4808684 },
    { lat: 49.4218367, lng: 19.4806001 },
    { lat: 49.4219133, lng: 19.4803287 },
    { lat: 49.4218841, lng: 19.4800105 },
    { lat: 49.4217119, lng: 19.4794973 },
    { lat: 49.4216444, lng: 19.479177 },
    { lat: 49.4216278, lng: 19.4788378 },
    { lat: 49.421583, lng: 19.4783023 },
    { lat: 49.4215566, lng: 19.4777702 },
    { lat: 49.4215498, lng: 19.4775241 },
    { lat: 49.4215421, lng: 19.4772368 },
    { lat: 49.4216109, lng: 19.4767951 },
    { lat: 49.421781, lng: 19.4763219 },
    { lat: 49.4220197, lng: 19.4760209 },
    { lat: 49.4224151, lng: 19.4757525 },
    { lat: 49.4226516, lng: 19.4756522 },
    { lat: 49.422665, lng: 19.475645 },
    { lat: 49.4227839, lng: 19.4755866 },
    { lat: 49.4228049, lng: 19.4755764 },
    { lat: 49.4229095, lng: 19.475525 },
    { lat: 49.4229174, lng: 19.4755212 },
    { lat: 49.4230252, lng: 19.475468 },
    { lat: 49.4230968, lng: 19.4754328 },
    { lat: 49.4232573, lng: 19.4753711 },
    { lat: 49.4232861, lng: 19.4753604 },
    { lat: 49.4234701, lng: 19.47529 },
    { lat: 49.4234954, lng: 19.4752804 },
    { lat: 49.4236051, lng: 19.475211 },
    { lat: 49.4238504, lng: 19.4750566 },
    { lat: 49.4238952, lng: 19.4750286 },
    { lat: 49.4240762, lng: 19.4752379 },
    { lat: 49.4241809, lng: 19.4753591 },
    { lat: 49.4241831, lng: 19.4753607 },
    { lat: 49.4244983, lng: 19.4756247 },
    { lat: 49.4245649, lng: 19.4756042 },
    { lat: 49.4247993, lng: 19.4755319 },
    { lat: 49.4248287, lng: 19.4755089 },
    { lat: 49.4252614, lng: 19.4751663 },
    { lat: 49.4256773, lng: 19.4748223 },
    { lat: 49.4257409, lng: 19.4748082 },
    { lat: 49.4258815, lng: 19.4748062 },
    { lat: 49.4260021, lng: 19.4748044 },
    { lat: 49.4260854, lng: 19.4748031 },
    { lat: 49.4263943, lng: 19.4744597 },
    { lat: 49.4266891, lng: 19.4741323 },
    { lat: 49.4270527, lng: 19.4738283 },
    { lat: 49.4274013, lng: 19.4735468 },
    { lat: 49.4285549, lng: 19.4721103 },
    { lat: 49.4288753, lng: 19.4717765 },
    { lat: 49.4291024, lng: 19.4718879 },
    { lat: 49.4291284, lng: 19.4719611 },
    { lat: 49.4293041, lng: 19.472015 },
    { lat: 49.4293959, lng: 19.4719982 },
    { lat: 49.4296213, lng: 19.4719508 },
    { lat: 49.4297299, lng: 19.4716145 },
    { lat: 49.4299947, lng: 19.4714683 },
    { lat: 49.4302282, lng: 19.4714979 },
    { lat: 49.430395, lng: 19.4718213 },
    { lat: 49.4305258, lng: 19.4720422 },
    { lat: 49.4308023, lng: 19.4720277 },
    { lat: 49.4311709, lng: 19.471689 },
    { lat: 49.4315189, lng: 19.4717085 },
    { lat: 49.4317256, lng: 19.4716953 },
    { lat: 49.4318514, lng: 19.4715659 },
    { lat: 49.4320531, lng: 19.4717636 },
    { lat: 49.4322213, lng: 19.4717702 },
    { lat: 49.4323756, lng: 19.4715458 },
    { lat: 49.4324931, lng: 19.4712805 },
    { lat: 49.4327446, lng: 19.4710181 },
    { lat: 49.4331623, lng: 19.4709109 },
    { lat: 49.4331047, lng: 19.4705653 },
    { lat: 49.4332392, lng: 19.4702025 },
    { lat: 49.4334997, lng: 19.4698636 },
    { lat: 49.4337496, lng: 19.4695626 },
    { lat: 49.434196, lng: 19.4693702 },
    { lat: 49.4346976, lng: 19.4687117 },
    { lat: 49.4349277, lng: 19.4684458 },
    { lat: 49.435236, lng: 19.4683887 },
    { lat: 49.4352708, lng: 19.4682091 },
    { lat: 49.4354732, lng: 19.4678596 },
    { lat: 49.4357508, lng: 19.467891 },
    { lat: 49.4359819, lng: 19.4676471 },
    { lat: 49.4363213, lng: 19.4676297 },
    { lat: 49.4366055, lng: 19.4674149 },
    { lat: 49.4367532, lng: 19.4670474 },
    { lat: 49.4369298, lng: 19.4669177 },
    { lat: 49.4371759, lng: 19.4668271 },
    { lat: 49.4377063, lng: 19.4666752 },
    { lat: 49.4380554, lng: 19.4663023 },
    { lat: 49.4385353, lng: 19.4660787 },
    { lat: 49.4386031, lng: 19.4658879 },
    { lat: 49.4388734, lng: 19.4654895 },
    { lat: 49.4390359, lng: 19.4652019 },
    { lat: 49.4391692, lng: 19.4647885 },
    { lat: 49.4394612, lng: 19.4643016 },
    { lat: 49.4396011, lng: 19.4636392 },
    { lat: 49.439543, lng: 19.4632834 },
    { lat: 49.4396263, lng: 19.4630597 },
    { lat: 49.4396533, lng: 19.4626912 },
    { lat: 49.4397046, lng: 19.4623123 },
    { lat: 49.439729, lng: 19.4622507 },
    { lat: 49.442291, lng: 19.458485 },
    { lat: 49.4440057, lng: 19.4558524 },
    { lat: 49.4441666, lng: 19.4555765 },
    { lat: 49.4443336, lng: 19.455141 },
    { lat: 49.4445647, lng: 19.4547268 },
    { lat: 49.4447533, lng: 19.4544639 },
    { lat: 49.4452505, lng: 19.4539797 },
    { lat: 49.4452623, lng: 19.4539437 },
    { lat: 49.4458892, lng: 19.4532387 },
    { lat: 49.4461099, lng: 19.4530158 },
    { lat: 49.4463862, lng: 19.4526054 },
    { lat: 49.447092, lng: 19.4525151 },
    { lat: 49.4473543, lng: 19.4524375 },
    { lat: 49.4483978, lng: 19.4518748 },
    { lat: 49.4484572, lng: 19.4518691 },
    { lat: 49.4484752, lng: 19.451869 },
    { lat: 49.4485468, lng: 19.4518612 },
    { lat: 49.4485898, lng: 19.451815 },
    { lat: 49.4494792, lng: 19.4513572 },
    { lat: 49.450738, lng: 19.4512423 },
    { lat: 49.4516518, lng: 19.4509195 },
    { lat: 49.4520436, lng: 19.4505712 },
    { lat: 49.4521203, lng: 19.4504274 },
    { lat: 49.4526271, lng: 19.4499566 },
    { lat: 49.4532226, lng: 19.4495261 },
    { lat: 49.453962, lng: 19.4492627 },
    { lat: 49.4548801, lng: 19.4489621 },
    { lat: 49.4551812, lng: 19.4487732 },
    { lat: 49.4556829, lng: 19.4487045 },
    { lat: 49.4560899, lng: 19.4485856 },
    { lat: 49.4561825, lng: 19.4485455 },
    { lat: 49.4599442, lng: 19.4471604 },
    { lat: 49.4607365, lng: 19.4468713 },
    { lat: 49.4607653, lng: 19.4468602 },
    { lat: 49.4608173, lng: 19.4468415 },
    { lat: 49.4629366, lng: 19.4457584 },
    { lat: 49.4629224, lng: 19.4456961 },
    { lat: 49.4631116, lng: 19.4456703 },
    { lat: 49.4631883, lng: 19.4455729 },
    { lat: 49.4634375, lng: 19.4455539 },
    { lat: 49.4635045, lng: 19.4454916 },
    { lat: 49.4635465, lng: 19.4453259 },
    { lat: 49.4636491, lng: 19.4452517 },
    { lat: 49.4637579, lng: 19.4451172 },
    { lat: 49.4639137, lng: 19.4449522 },
    { lat: 49.4640105, lng: 19.4448809 },
    { lat: 49.4642422, lng: 19.4447953 },
    { lat: 49.4642424, lng: 19.4447526 },
    { lat: 49.4642914, lng: 19.4446433 },
    { lat: 49.4643102, lng: 19.4444616 },
    { lat: 49.4644035, lng: 19.4444098 },
    { lat: 49.4644243, lng: 19.4442971 },
    { lat: 49.4644736, lng: 19.444195 },
    { lat: 49.4644718, lng: 19.4440569 },
    { lat: 49.46443, lng: 19.4439299 },
    { lat: 49.4644521, lng: 19.4438239 },
    { lat: 49.4644317, lng: 19.4437707 },
    { lat: 49.4644477, lng: 19.4436567 },
    { lat: 49.4644736, lng: 19.4436042 },
    { lat: 49.4644431, lng: 19.4435296 },
    { lat: 49.4644601, lng: 19.4433601 },
    { lat: 49.4643587, lng: 19.4430657 },
    { lat: 49.4644273, lng: 19.4429644 },
    { lat: 49.4644345, lng: 19.4428046 },
    { lat: 49.4644588, lng: 19.4426191 },
    { lat: 49.4644334, lng: 19.442461 },
    { lat: 49.4644967, lng: 19.4424607 },
    { lat: 49.4645608, lng: 19.4424392 },
    { lat: 49.4645965, lng: 19.4423578 },
    { lat: 49.4645133, lng: 19.4422107 },
    { lat: 49.4645377, lng: 19.4420798 },
    { lat: 49.4645987, lng: 19.4419638 },
    { lat: 49.4645863, lng: 19.4418877 },
    { lat: 49.4646342, lng: 19.4417803 },
    { lat: 49.464604, lng: 19.4416358 },
    { lat: 49.4646467, lng: 19.4414877 },
    { lat: 49.4646854, lng: 19.4414459 },
    { lat: 49.4647797, lng: 19.4414621 },
    { lat: 49.4648042, lng: 19.4413338 },
    { lat: 49.4647841, lng: 19.4411693 },
    { lat: 49.4648182, lng: 19.4409537 },
    { lat: 49.4648875, lng: 19.440818 },
    { lat: 49.4648818, lng: 19.4405194 },
    { lat: 49.4649209, lng: 19.4404416 },
    { lat: 49.4649163, lng: 19.4403414 },
    { lat: 49.4648671, lng: 19.4402978 },
    { lat: 49.4648881, lng: 19.4401423 },
    { lat: 49.4649314, lng: 19.4401289 },
    { lat: 49.4650106, lng: 19.4399686 },
    { lat: 49.465019, lng: 19.4398528 },
    { lat: 49.4650701, lng: 19.4398658 },
    { lat: 49.465076, lng: 19.4397731 },
    { lat: 49.4651203, lng: 19.4397035 },
    { lat: 49.4651211, lng: 19.4395987 },
    { lat: 49.4650215, lng: 19.4393936 },
    { lat: 49.4650339, lng: 19.439272 },
    { lat: 49.4649528, lng: 19.4392489 },
    { lat: 49.4648551, lng: 19.4390812 },
    { lat: 49.4649773, lng: 19.4389716 },
    { lat: 49.4649457, lng: 19.4387765 },
    { lat: 49.4648605, lng: 19.4387836 },
    { lat: 49.4647982, lng: 19.4386299 },
    { lat: 49.4647547, lng: 19.4384172 },
    { lat: 49.4645939, lng: 19.4382267 },
    { lat: 49.4644276, lng: 19.4377991 },
    { lat: 49.4643585, lng: 19.4375447 },
    { lat: 49.4643563, lng: 19.4373729 },
    { lat: 49.4641782, lng: 19.4370524 },
    { lat: 49.4641211, lng: 19.4368702 },
    { lat: 49.464006, lng: 19.4368417 },
    { lat: 49.4640241, lng: 19.4366952 },
    { lat: 49.4640646, lng: 19.4365705 },
    { lat: 49.4640734, lng: 19.4364693 },
    { lat: 49.4640364, lng: 19.4363994 },
    { lat: 49.4639512, lng: 19.4363314 },
    { lat: 49.4640093, lng: 19.4360759 },
    { lat: 49.4639318, lng: 19.4358123 },
    { lat: 49.4639942, lng: 19.4357483 },
    { lat: 49.4639304, lng: 19.4356331 },
    { lat: 49.4639696, lng: 19.4355104 },
    { lat: 49.4638472, lng: 19.435273 },
    { lat: 49.463913, lng: 19.4350344 },
    { lat: 49.4639979, lng: 19.4348285 },
    { lat: 49.4639142, lng: 19.4345999 },
    { lat: 49.4639543, lng: 19.4344398 },
    { lat: 49.4640082, lng: 19.4343119 },
    { lat: 49.4639885, lng: 19.4342539 },
    { lat: 49.4638903, lng: 19.4342225 },
    { lat: 49.4638807, lng: 19.4341634 },
    { lat: 49.4639477, lng: 19.4341 },
    { lat: 49.4640663, lng: 19.4340825 },
    { lat: 49.4640535, lng: 19.4338803 },
    { lat: 49.4639181, lng: 19.4338265 },
    { lat: 49.4638921, lng: 19.4336771 },
    { lat: 49.4638168, lng: 19.4336317 },
    { lat: 49.4637907, lng: 19.433507 },
    { lat: 49.4637963, lng: 19.4333821 },
    { lat: 49.4638755, lng: 19.4333174 },
    { lat: 49.4639779, lng: 19.4333147 },
    { lat: 49.4639354, lng: 19.4331512 },
    { lat: 49.4640101, lng: 19.4330091 },
    { lat: 49.4639143, lng: 19.4328626 },
    { lat: 49.4640314, lng: 19.4328111 },
    { lat: 49.4640974, lng: 19.4327525 },
    { lat: 49.464067, lng: 19.4327026 },
    { lat: 49.4639979, lng: 19.432647 },
    { lat: 49.4640007, lng: 19.4326112 },
    { lat: 49.4640919, lng: 19.4325608 },
    { lat: 49.4640949, lng: 19.4324764 },
    { lat: 49.4640414, lng: 19.4323927 },
    { lat: 49.4640636, lng: 19.43229 },
    { lat: 49.4641359, lng: 19.4322441 },
    { lat: 49.4641214, lng: 19.4320722 },
    { lat: 49.4641166, lng: 19.4317992 },
    { lat: 49.4640614, lng: 19.431774 },
    { lat: 49.4639829, lng: 19.4318553 },
    { lat: 49.4639524, lng: 19.4318282 },
    { lat: 49.4639662, lng: 19.4316858 },
    { lat: 49.4639403, lng: 19.4315912 },
    { lat: 49.4638939, lng: 19.4316136 },
    { lat: 49.4639013, lng: 19.4312817 },
    { lat: 49.4638833, lng: 19.4310395 },
    { lat: 49.4639543, lng: 19.4310119 },
    { lat: 49.4639707, lng: 19.4309536 },
    { lat: 49.4639183, lng: 19.4307698 },
    { lat: 49.4638607, lng: 19.4307174 },
    { lat: 49.4638524, lng: 19.4306357 },
    { lat: 49.4637955, lng: 19.4306516 },
    { lat: 49.4637782, lng: 19.430347 },
    { lat: 49.4639042, lng: 19.4301954 },
    { lat: 49.4640029, lng: 19.4301343 },
    { lat: 49.4639959, lng: 19.4299634 },
    { lat: 49.4640564, lng: 19.4297597 },
    { lat: 49.4639996, lng: 19.4295496 },
    { lat: 49.4639816, lng: 19.4294784 },
    { lat: 49.4639839, lng: 19.4293065 },
    { lat: 49.4639482, lng: 19.4291933 },
    { lat: 49.4638796, lng: 19.429124 },
    { lat: 49.4638398, lng: 19.4290185 },
    { lat: 49.4639096, lng: 19.4288414 },
    { lat: 49.4638671, lng: 19.4287555 },
    { lat: 49.4638683, lng: 19.4286336 },
    { lat: 49.4638126, lng: 19.4284484 },
    { lat: 49.4637448, lng: 19.4284467 },
    { lat: 49.4636867, lng: 19.4283338 },
    { lat: 49.4636115, lng: 19.428317 },
    { lat: 49.4636226, lng: 19.4280712 },
    { lat: 49.4636709, lng: 19.4280892 },
    { lat: 49.4636892, lng: 19.4279481 },
    { lat: 49.4636352, lng: 19.4279029 },
    { lat: 49.4636041, lng: 19.4279602 },
    { lat: 49.4635113, lng: 19.4277298 },
    { lat: 49.4636226, lng: 19.4276757 },
    { lat: 49.4635975, lng: 19.4275266 },
    { lat: 49.4635984, lng: 19.4273958 },
    { lat: 49.4635352, lng: 19.4272247 },
    { lat: 49.4635591, lng: 19.4271544 },
    { lat: 49.463506, lng: 19.4270316 },
    { lat: 49.4634455, lng: 19.4270341 },
    { lat: 49.4634345, lng: 19.4271143 },
    { lat: 49.4632981, lng: 19.4269668 },
    { lat: 49.4633858, lng: 19.426841 },
    { lat: 49.4632857, lng: 19.4268464 },
    { lat: 49.4632403, lng: 19.4268148 },
    { lat: 49.4631902, lng: 19.4267057 },
    { lat: 49.4631064, lng: 19.4267674 },
    { lat: 49.4629665, lng: 19.4267191 },
    { lat: 49.4629471, lng: 19.4265133 },
    { lat: 49.4628701, lng: 19.4265586 },
    { lat: 49.4628034, lng: 19.426258 },
    { lat: 49.4627573, lng: 19.4262369 },
    { lat: 49.462676, lng: 19.4263058 },
    { lat: 49.4626033, lng: 19.4261235 },
    { lat: 49.4626682, lng: 19.4258187 },
    { lat: 49.4627624, lng: 19.4257111 },
    { lat: 49.4627687, lng: 19.4255782 },
    { lat: 49.4627379, lng: 19.4255178 },
    { lat: 49.4626282, lng: 19.4254905 },
    { lat: 49.4626684, lng: 19.4251884 },
    { lat: 49.462647, lng: 19.4250426 },
    { lat: 49.4627228, lng: 19.42495 },
    { lat: 49.4626759, lng: 19.4248866 },
    { lat: 49.4627213, lng: 19.4246726 },
    { lat: 49.4626438, lng: 19.4244347 },
    { lat: 49.4625771, lng: 19.42433 },
    { lat: 49.462533, lng: 19.4244489 },
    { lat: 49.4624841, lng: 19.424514 },
    { lat: 49.462448, lng: 19.424561 },
    { lat: 49.4621614, lng: 19.4245519 },
    { lat: 49.4613978, lng: 19.4239005 },
    { lat: 49.4605045, lng: 19.4230231 },
    { lat: 49.4602359, lng: 19.4228653 },
    { lat: 49.4587839, lng: 19.4219204 },
    { lat: 49.4587338, lng: 19.4218878 },
    { lat: 49.4586515, lng: 19.4218344 },
    { lat: 49.4582726, lng: 19.4216361 },
    { lat: 49.458254, lng: 19.4216229 },
    { lat: 49.4576544, lng: 19.421178 },
    { lat: 49.4575196, lng: 19.4211133 },
    { lat: 49.457111, lng: 19.4208089 },
    { lat: 49.4567565, lng: 19.4207659 },
    { lat: 49.4561084, lng: 19.4208377 },
    { lat: 49.4551472, lng: 19.420904 },
    { lat: 49.4549491, lng: 19.4204232 },
    { lat: 49.4548177, lng: 19.4194523 },
    { lat: 49.4547957, lng: 19.4191748 },
    { lat: 49.4545646, lng: 19.4181426 },
    { lat: 49.4543764, lng: 19.417656 },
    { lat: 49.4542245, lng: 19.4177316 },
    { lat: 49.4539454, lng: 19.4179368 },
    { lat: 49.452924, lng: 19.4185345 },
    { lat: 49.452572, lng: 19.4187705 },
    { lat: 49.4525262, lng: 19.4188012 },
    { lat: 49.4520966, lng: 19.4190898 },
    { lat: 49.4515072, lng: 19.4192574 },
    { lat: 49.4512589, lng: 19.4194694 },
    { lat: 49.4510294, lng: 19.4194498 },
    { lat: 49.4503892, lng: 19.4194925 },
    { lat: 49.4502828, lng: 19.4195095 },
    { lat: 49.4488444, lng: 19.4203027 },
    { lat: 49.4483765, lng: 19.4205602 },
    { lat: 49.4478286, lng: 19.4208229 },
    { lat: 49.4475181, lng: 19.4210518 },
    { lat: 49.447202, lng: 19.4212849 },
    { lat: 49.4467951, lng: 19.4214048 },
    { lat: 49.4464251, lng: 19.4214652 },
    { lat: 49.4462577, lng: 19.4215985 },
    { lat: 49.445589, lng: 19.4222747 },
    { lat: 49.4446302, lng: 19.4228122 },
    { lat: 49.4442078, lng: 19.423109 },
    { lat: 49.444172, lng: 19.4231372 },
    { lat: 49.4440237, lng: 19.4232434 },
    { lat: 49.4437731, lng: 19.4237218 },
    { lat: 49.4433466, lng: 19.4246161 },
    { lat: 49.442927, lng: 19.4254101 },
    { lat: 49.4425835, lng: 19.425779 },
    { lat: 49.4422339, lng: 19.4259947 },
    { lat: 49.4417766, lng: 19.4263387 },
    { lat: 49.4416388, lng: 19.4263819 },
    { lat: 49.4415943, lng: 19.4263957 },
    { lat: 49.4415133, lng: 19.4264208 },
    { lat: 49.4402359, lng: 19.4270655 },
    { lat: 49.4397252, lng: 19.4274735 },
    { lat: 49.4391987, lng: 19.4280737 },
    { lat: 49.4390831, lng: 19.4281829 },
    { lat: 49.4387597, lng: 19.4285239 },
    { lat: 49.4383395, lng: 19.4289666 },
    { lat: 49.4381074, lng: 19.4292112 },
    { lat: 49.4379861, lng: 19.4294184 },
    { lat: 49.4377161, lng: 19.4300146 },
    { lat: 49.4375781, lng: 19.4303196 },
    { lat: 49.4374995, lng: 19.4304943 },
    { lat: 49.4373466, lng: 19.4307726 },
    { lat: 49.4372405, lng: 19.4310636 },
    { lat: 49.4370316, lng: 19.4311978 },
    { lat: 49.4365151, lng: 19.431333 },
    { lat: 49.4363469, lng: 19.4313769 },
    { lat: 49.4358453, lng: 19.431668 },
    { lat: 49.4353627, lng: 19.4320016 },
    { lat: 49.435256, lng: 19.4320309 },
    { lat: 49.4347211, lng: 19.4322604 },
    { lat: 49.4342486, lng: 19.4328123 },
    { lat: 49.4336775, lng: 19.4336228 },
    { lat: 49.433305, lng: 19.4341483 },
    { lat: 49.4329254, lng: 19.4344927 },
    { lat: 49.4328757, lng: 19.4345419 },
    { lat: 49.4317959, lng: 19.4356062 },
    { lat: 49.4312499, lng: 19.4359111 },
    { lat: 49.4304947, lng: 19.4360793 },
    { lat: 49.4302755, lng: 19.4363079 },
    { lat: 49.4297855, lng: 19.4368757 },
    { lat: 49.4293812, lng: 19.4372934 },
    { lat: 49.4289677, lng: 19.4376625 },
    { lat: 49.4280871, lng: 19.4383818 },
    { lat: 49.4273208, lng: 19.4387478 },
    { lat: 49.4262318, lng: 19.4393719 },
    { lat: 49.4257192, lng: 19.4396916 },
    { lat: 49.4248725, lng: 19.4400492 },
    { lat: 49.4241418, lng: 19.4405738 },
    { lat: 49.4240241, lng: 19.4407816 },
    { lat: 49.4239948, lng: 19.4408336 },
    { lat: 49.4236414, lng: 19.4414549 },
    { lat: 49.4233355, lng: 19.4423502 },
    { lat: 49.4231406, lng: 19.4428609 },
    { lat: 49.4230176, lng: 19.4431797 },
    { lat: 49.422953, lng: 19.4433412 },
    { lat: 49.4225078, lng: 19.4439742 },
    { lat: 49.4224729, lng: 19.4439755 },
    { lat: 49.4220462, lng: 19.4439861 },
    { lat: 49.4217034, lng: 19.4440007 },
    { lat: 49.4215086, lng: 19.4439812 },
    { lat: 49.4211136, lng: 19.4439403 },
    { lat: 49.4209869, lng: 19.4439304 },
    { lat: 49.420858, lng: 19.4439212 },
    { lat: 49.4202472, lng: 19.4439412 },
    { lat: 49.4197785, lng: 19.4433303 },
    { lat: 49.419088, lng: 19.4434103 },
    { lat: 49.4187483, lng: 19.4435451 },
    { lat: 49.4183311, lng: 19.4436119 },
    { lat: 49.4175888, lng: 19.4441054 },
    { lat: 49.4172242, lng: 19.4444126 },
    { lat: 49.4166018, lng: 19.4441074 },
    { lat: 49.4164614, lng: 19.444116 },
    { lat: 49.4157103, lng: 19.4440774 },
    { lat: 49.4150541, lng: 19.4437745 },
    { lat: 49.4145548, lng: 19.4435747 },
    { lat: 49.4139746, lng: 19.4439425 },
    { lat: 49.4129811, lng: 19.4444116 },
    { lat: 49.4126482, lng: 19.4446224 },
    { lat: 49.4123744, lng: 19.4448195 },
    { lat: 49.4121567, lng: 19.4449639 },
    { lat: 49.4118353, lng: 19.445177 },
    { lat: 49.411231, lng: 19.44551 },
    { lat: 49.4109869, lng: 19.4455921 },
    { lat: 49.4105342, lng: 19.4457448 },
    { lat: 49.4101192, lng: 19.4458845 },
    { lat: 49.4098562, lng: 19.4459733 },
    { lat: 49.4097685, lng: 19.4459752 },
    { lat: 49.4095614, lng: 19.4459794 },
    { lat: 49.4095187, lng: 19.4459866 },
    { lat: 49.4090347, lng: 19.4460692 },
    { lat: 49.4089811, lng: 19.4460786 },
    { lat: 49.4086501, lng: 19.446135 },
    { lat: 49.4079764, lng: 19.4461328 },
    { lat: 49.407834, lng: 19.4461019 },
    { lat: 49.4074296, lng: 19.4459795 },
    { lat: 49.4068271, lng: 19.4463057 },
    { lat: 49.4062536, lng: 19.4467718 },
    { lat: 49.4057823, lng: 19.4470604 },
    { lat: 49.4050257, lng: 19.4469243 },
    { lat: 49.4046621, lng: 19.4468362 },
    { lat: 49.4043305, lng: 19.4469523 },
    { lat: 49.4040941, lng: 19.4470284 },
    { lat: 49.4036652, lng: 19.4471131 },
    { lat: 49.4034431, lng: 19.4474012 },
    { lat: 49.403394, lng: 19.447561 },
    { lat: 49.4024473, lng: 19.44856 },
    { lat: 49.4020831, lng: 19.4491167 },
    { lat: 49.401971, lng: 19.4494215 },
    { lat: 49.4011838, lng: 19.4507832 },
    { lat: 49.4009721, lng: 19.4513964 },
    { lat: 49.4007813, lng: 19.4519517 },
    { lat: 49.4005744, lng: 19.4523564 },
    { lat: 49.4001618, lng: 19.4527898 },
    { lat: 49.3996411, lng: 19.4532305 },
    { lat: 49.3989695, lng: 19.4547768 },
    { lat: 49.3983244, lng: 19.4565046 },
    { lat: 49.3981514, lng: 19.4566644 },
    { lat: 49.3977027, lng: 19.4560188 },
    { lat: 49.3973559, lng: 19.4553627 },
    { lat: 49.3969538, lng: 19.454625 },
    { lat: 49.3963493, lng: 19.4541512 },
    { lat: 49.3963372, lng: 19.4541297 },
    { lat: 49.3956968, lng: 19.4530686 },
    { lat: 49.3951551, lng: 19.452091 },
    { lat: 49.3947902, lng: 19.4514821 },
    { lat: 49.3945891, lng: 19.4511532 },
    { lat: 49.3941467, lng: 19.450425 },
    { lat: 49.3928995, lng: 19.4505255 },
    { lat: 49.3922085, lng: 19.4507654 },
    { lat: 49.3915948, lng: 19.4510403 },
    { lat: 49.3915725, lng: 19.4510499 },
    { lat: 49.3915318, lng: 19.4510687 },
    { lat: 49.391492, lng: 19.4510864 },
    { lat: 49.3912545, lng: 19.4513591 },
    { lat: 49.3910755, lng: 19.4515585 },
    { lat: 49.3910794, lng: 19.4515715 },
    { lat: 49.3910523, lng: 19.4515921 },
    { lat: 49.3909423, lng: 19.4516763 },
    { lat: 49.3908529, lng: 19.4517446 },
    { lat: 49.3906173, lng: 19.4518369 },
    { lat: 49.3902588, lng: 19.4519077 },
    { lat: 49.3902411, lng: 19.4518883 },
    { lat: 49.3901789, lng: 19.4519576 },
    { lat: 49.3901136, lng: 19.4520301 },
    { lat: 49.3900428, lng: 19.452109 },
    { lat: 49.3897324, lng: 19.4521732 },
    { lat: 49.3896011, lng: 19.452208 },
    { lat: 49.3895422, lng: 19.4522989 },
    { lat: 49.3894379, lng: 19.4524596 },
    { lat: 49.3895774, lng: 19.4526728 },
    { lat: 49.3904344, lng: 19.4539371 },
    { lat: 49.3910321, lng: 19.4549051 },
    { lat: 49.3914402, lng: 19.4553552 },
    { lat: 49.3921892, lng: 19.4558156 },
    { lat: 49.3926938, lng: 19.4561805 },
    { lat: 49.393246, lng: 19.456597 },
    { lat: 49.3940083, lng: 19.4570311 },
    { lat: 49.3959165, lng: 19.4577784 },
    { lat: 49.3959145, lng: 19.4577855 },
    { lat: 49.3958717, lng: 19.4580057 },
    { lat: 49.395595, lng: 19.45853 },
    { lat: 49.3956144, lng: 19.4586133 },
    { lat: 49.3964142, lng: 19.4619981 },
    { lat: 49.3964269, lng: 19.4620539 },
    { lat: 49.3964366, lng: 19.4620952 },
    { lat: 49.3965413, lng: 19.4625354 },
    { lat: 49.396582, lng: 19.4627072 },
    { lat: 49.3966783, lng: 19.4631143 },
    { lat: 49.3966798, lng: 19.4631217 },
    { lat: 49.3967609, lng: 19.4634646 },
    { lat: 49.3967635, lng: 19.4634748 },
    { lat: 49.3967666, lng: 19.4634893 },
    { lat: 49.3968069, lng: 19.4636839 },
    { lat: 49.3968489, lng: 19.4638853 },
    { lat: 49.397448, lng: 19.4667326 },
    { lat: 49.3965707, lng: 19.4735102 },
    { lat: 49.3965334, lng: 19.4738054 },
    { lat: 49.3965264, lng: 19.4738734 },
    { lat: 49.3964713, lng: 19.474418 },
    { lat: 49.3961682, lng: 19.4774201 },
    { lat: 49.3963227, lng: 19.4830959 },
    { lat: 49.3974858, lng: 19.4854962 },
    { lat: 49.3974929, lng: 19.4855548 },
    { lat: 49.3972593, lng: 19.4859405 },
    { lat: 49.3966289, lng: 19.4862511 },
    { lat: 49.3965938, lng: 19.486273 },
    { lat: 49.3965073, lng: 19.4863268 },
    { lat: 49.3964503, lng: 19.4863625 },
    { lat: 49.3962981, lng: 19.4864092 },
    { lat: 49.3959678, lng: 19.4861111 },
    { lat: 49.395848, lng: 19.4862744 },
    { lat: 49.3956506, lng: 19.4861964 },
    { lat: 49.395366, lng: 19.4860797 },
    { lat: 49.395234, lng: 19.4864469 },
    { lat: 49.3949961, lng: 19.4865668 },
    { lat: 49.3946506, lng: 19.4866483 },
    { lat: 49.3944633, lng: 19.4869418 },
    { lat: 49.3944547, lng: 19.4872972 },
    { lat: 49.3942075, lng: 19.487285 },
    { lat: 49.3939686, lng: 19.4871109 },
    { lat: 49.3935611, lng: 19.4875138 },
    { lat: 49.3932765, lng: 19.4877244 },
    { lat: 49.3929069, lng: 19.4878454 },
    { lat: 49.3925497, lng: 19.4879201 },
    { lat: 49.3920178, lng: 19.4878885 },
    { lat: 49.3915387, lng: 19.4881094 },
    { lat: 49.3913114, lng: 19.4886561 },
    { lat: 49.3911405, lng: 19.4888107 },
    { lat: 49.3910082, lng: 19.4887526 },
    { lat: 49.3907598, lng: 19.4891608 },
    { lat: 49.3905973, lng: 19.4892588 },
    { lat: 49.3904184, lng: 19.4895586 },
    { lat: 49.390439, lng: 19.489912 },
    { lat: 49.3904825, lng: 19.4908872 },
    { lat: 49.3904181, lng: 19.4913017 },
    { lat: 49.3903556, lng: 19.4916618 },
    { lat: 49.3901682, lng: 19.4920779 },
    { lat: 49.389925, lng: 19.4922522 },
    { lat: 49.3894905, lng: 19.4928058 },
    { lat: 49.3888573, lng: 19.4932504 },
    { lat: 49.3879856, lng: 19.4934569 },
    { lat: 49.3876115, lng: 19.493107 },
    { lat: 49.387345, lng: 19.4929492 },
    { lat: 49.387007, lng: 19.4927485 },
    { lat: 49.386537, lng: 19.4928443 },
    { lat: 49.3864115, lng: 19.4930581 },
    { lat: 49.386253, lng: 19.4931648 },
    { lat: 49.3861091, lng: 19.4934424 },
    { lat: 49.3860429, lng: 19.4938704 },
    { lat: 49.3860341, lng: 19.4938844 },
    { lat: 49.385837, lng: 19.4941963 },
    { lat: 49.3856925, lng: 19.4945846 },
    { lat: 49.3855067, lng: 19.4948598 },
    { lat: 49.3855009, lng: 19.4950333 },
    { lat: 49.3854062, lng: 19.4951053 },
    { lat: 49.3853285, lng: 19.4953002 },
    { lat: 49.3850626, lng: 19.4955011 },
    { lat: 49.3848329, lng: 19.4958501 },
    { lat: 49.3846499, lng: 19.4958903 },
    { lat: 49.3844687, lng: 19.4962398 },
    { lat: 49.3843384, lng: 19.4963243 },
    { lat: 49.3841835, lng: 19.4965824 },
    { lat: 49.384, lng: 19.4971058 },
    { lat: 49.3838044, lng: 19.4973614 },
    { lat: 49.3836519, lng: 19.4975022 },
    { lat: 49.3834685, lng: 19.4979015 },
    { lat: 49.3830739, lng: 19.4985371 },
    { lat: 49.3828589, lng: 19.4987673 },
    { lat: 49.3827077, lng: 19.4991079 },
    { lat: 49.3822981, lng: 19.4997881 },
    { lat: 49.3819296, lng: 19.5010755 },
    { lat: 49.3819647, lng: 19.5018934 },
    { lat: 49.3821561, lng: 19.5025343 },
    { lat: 49.381962, lng: 19.503671 },
    { lat: 49.382035, lng: 19.505043 },
    { lat: 49.382115, lng: 19.505802 },
    { lat: 49.382124, lng: 19.505887 },
    { lat: 49.382307, lng: 19.507624 },
    { lat: 49.38253, lng: 19.508552 },
    { lat: 49.382766, lng: 19.508531 },
    { lat: 49.382941, lng: 19.508603 },
    { lat: 49.383158, lng: 19.508794 },
    { lat: 49.38349, lng: 19.508888 },
    { lat: 49.384135, lng: 19.50942 },
    { lat: 49.384308, lng: 19.509725 },
    { lat: 49.384448, lng: 19.509847 },
    { lat: 49.384705, lng: 19.510301 },
    { lat: 49.385025, lng: 19.510647 },
    { lat: 49.385166, lng: 19.511078 },
    { lat: 49.385658, lng: 19.511464 },
    { lat: 49.385916, lng: 19.511815 },
    { lat: 49.386125, lng: 19.51233 },
    { lat: 49.386443, lng: 19.512866 },
    { lat: 49.386803, lng: 19.513585 },
    { lat: 49.387001, lng: 19.513851 },
    { lat: 49.387345, lng: 19.514188 },
    { lat: 49.387624, lng: 19.514539 },
    { lat: 49.387829, lng: 19.514864 },
    { lat: 49.388051, lng: 19.515428 },
    { lat: 49.388454, lng: 19.516444 },
    { lat: 49.388904, lng: 19.518081 },
    { lat: 49.389506, lng: 19.520711 },
    { lat: 49.390185, lng: 19.522826 },
    { lat: 49.390376, lng: 19.52329 },
    { lat: 49.390424, lng: 19.523405 },
    { lat: 49.390457, lng: 19.523487 },
    { lat: 49.39049, lng: 19.523564 },
    { lat: 49.391033, lng: 19.524886 },
    { lat: 49.391428, lng: 19.525904 },
    { lat: 49.391685, lng: 19.526588 },
    { lat: 49.391708, lng: 19.526654 },
    { lat: 49.391731, lng: 19.526723 },
    { lat: 49.391939, lng: 19.527338 },
    { lat: 49.392049, lng: 19.527615 },
    { lat: 49.392524, lng: 19.528864 },
    { lat: 49.392943, lng: 19.52813 },
    { lat: 49.393274, lng: 19.527382 },
    { lat: 49.393593, lng: 19.526809 },
    { lat: 49.393784, lng: 19.526397 },
    { lat: 49.39396, lng: 19.52593 },
    { lat: 49.394102, lng: 19.525642 },
    { lat: 49.39431, lng: 19.525307 },
    { lat: 49.394974, lng: 19.524511 },
    { lat: 49.395326, lng: 19.525238 },
    { lat: 49.395533, lng: 19.525284 },
    { lat: 49.395883, lng: 19.525009 },
    { lat: 49.396059, lng: 19.524982 },
    { lat: 49.396192, lng: 19.525143 },
    { lat: 49.396808, lng: 19.525802 },
    { lat: 49.3977, lng: 19.52618 },
    { lat: 49.397759, lng: 19.526321 },
    { lat: 49.398104, lng: 19.527344 },
    { lat: 49.398295, lng: 19.527914 },
    { lat: 49.398483, lng: 19.528477 },
    { lat: 49.398691, lng: 19.529093 },
    { lat: 49.398808, lng: 19.529471 },
    { lat: 49.398976, lng: 19.530046 },
    { lat: 49.399249, lng: 19.530969 },
    { lat: 49.399374, lng: 19.531423 },
    { lat: 49.399609, lng: 19.532325 },
    { lat: 49.40004, lng: 19.534006 },
    { lat: 49.400108, lng: 19.534312 },
    { lat: 49.400184, lng: 19.534507 },
    { lat: 49.400389, lng: 19.535084 },
    { lat: 49.400536, lng: 19.535534 },
    { lat: 49.4012, lng: 19.537758 },
    { lat: 49.402038, lng: 19.538482 },
    { lat: 49.402247, lng: 19.538851 },
    { lat: 49.402372, lng: 19.53902 },
    { lat: 49.403223, lng: 19.540269 },
    { lat: 49.403265, lng: 19.540344 },
    { lat: 49.403627, lng: 19.541126 },
    { lat: 49.403793, lng: 19.541503 },
    { lat: 49.404068, lng: 19.542151 },
    { lat: 49.404103, lng: 19.542257 },
    { lat: 49.404281, lng: 19.54281 },
    { lat: 49.404401, lng: 19.543156 },
    { lat: 49.404576, lng: 19.543695 },
    { lat: 49.404892, lng: 19.544532 },
    { lat: 49.405653, lng: 19.546788 },
    { lat: 49.405667, lng: 19.546847 },
    { lat: 49.40644, lng: 19.548091 },
    { lat: 49.407517, lng: 19.549446 },
    { lat: 49.407912, lng: 19.550345 },
    { lat: 49.408253, lng: 19.5509 },
    { lat: 49.409157, lng: 19.552352 },
    { lat: 49.409616, lng: 19.552922 },
    { lat: 49.410923, lng: 19.554463 },
    { lat: 49.411009, lng: 19.554565 },
    { lat: 49.411076, lng: 19.554642 },
    { lat: 49.411544, lng: 19.555196 },
    { lat: 49.412318, lng: 19.555897 },
    { lat: 49.413559, lng: 19.557087 },
    { lat: 49.4128033, lng: 19.5555771 },
    { lat: 49.4125854, lng: 19.5552619 },
    { lat: 49.4123202, lng: 19.5548789 },
    { lat: 49.4124942, lng: 19.5537869 },
    { lat: 49.4129656, lng: 19.5527477 },
    { lat: 49.4134955, lng: 19.5511842 },
    { lat: 49.4142382, lng: 19.5495394 },
    { lat: 49.4145269, lng: 19.5478928 },
    { lat: 49.4148716, lng: 19.5457206 },
    { lat: 49.414955, lng: 19.544645 },
    { lat: 49.4150196, lng: 19.543804 },
    { lat: 49.4151295, lng: 19.5427191 },
    { lat: 49.4151443, lng: 19.5425764 },
    { lat: 49.4152617, lng: 19.5414313 },
    { lat: 49.4152949, lng: 19.5403954 },
    { lat: 49.4153293, lng: 19.5393101 },
    { lat: 49.4153134, lng: 19.5383365 },
    { lat: 49.4153053, lng: 19.5378062 },
    { lat: 49.4156074, lng: 19.5360918 },
    { lat: 49.4157631, lng: 19.5359417 },
    { lat: 49.4153739, lng: 19.5346861 },
    { lat: 49.414628, lng: 19.5329654 },
    { lat: 49.4148964, lng: 19.5318007 },
    { lat: 49.4150424, lng: 19.5311653 },
    { lat: 49.4152071, lng: 19.5304993 },
    { lat: 49.4154234, lng: 19.5297037 },
    { lat: 49.4154391, lng: 19.5296471 },
    { lat: 49.4159286, lng: 19.5280162 },
    { lat: 49.4160659, lng: 19.5274939 },
    { lat: 49.4161315, lng: 19.5275648 },
    { lat: 49.4161737, lng: 19.5276105 },
    { lat: 49.4161601, lng: 19.5276536 },
    { lat: 49.4161873, lng: 19.527858 },
    { lat: 49.4162592, lng: 19.5280024 },
    { lat: 49.4163523, lng: 19.5279671 },
    { lat: 49.4164172, lng: 19.5280344 },
    { lat: 49.4165004, lng: 19.5279803 },
    { lat: 49.4166422, lng: 19.5280051 },
    { lat: 49.4167023, lng: 19.5282838 },
    { lat: 49.4167635, lng: 19.5283425 },
    { lat: 49.4168432, lng: 19.528334 },
    { lat: 49.4168737, lng: 19.528414 },
    { lat: 49.416931, lng: 19.5283862 },
    { lat: 49.4170035, lng: 19.5285174 },
    { lat: 49.4171111, lng: 19.528557 },
    { lat: 49.4171003, lng: 19.5288614 },
    { lat: 49.4172395, lng: 19.5288032 },
    { lat: 49.4171933, lng: 19.5289764 },
    { lat: 49.4172665, lng: 19.5291492 },
    { lat: 49.4173276, lng: 19.5291318 },
    { lat: 49.4175608, lng: 19.5291577 },
    { lat: 49.4175799, lng: 19.5292298 },
    { lat: 49.4175246, lng: 19.5292538 },
    { lat: 49.4174722, lng: 19.529339 },
    { lat: 49.41755, lng: 19.5294407 },
    { lat: 49.4177428, lng: 19.5293442 },
    { lat: 49.4178302, lng: 19.5294812 },
    { lat: 49.4185011, lng: 19.5295473 },
    { lat: 49.4199873, lng: 19.5276334 },
    { lat: 49.4207029, lng: 19.5261943 },
    { lat: 49.4210806, lng: 19.5249761 },
    { lat: 49.4215766, lng: 19.5236621 },
    { lat: 49.4220616, lng: 19.5222572 },
    { lat: 49.4228364, lng: 19.5210574 },
    { lat: 49.4233642, lng: 19.5202206 },
    { lat: 49.4241708, lng: 19.519452 },
    { lat: 49.4248044, lng: 19.5184666 },
    { lat: 49.4254311, lng: 19.5175992 },
    { lat: 49.4257909, lng: 19.517136 },
    { lat: 49.4259473, lng: 19.5175087 },
    { lat: 49.4261889, lng: 19.5180885 },
    { lat: 49.4262394, lng: 19.5182073 },
    { lat: 49.426415, lng: 19.518628 },
    { lat: 49.4264416, lng: 19.5186929 },
    { lat: 49.4267196, lng: 19.5193585 },
    { lat: 49.4277144, lng: 19.521273 },
    { lat: 49.4286123, lng: 19.5222651 },
    { lat: 49.4288335, lng: 19.5223733 },
    { lat: 49.4289095, lng: 19.5224105 },
    { lat: 49.4295525, lng: 19.522725 },
    { lat: 49.4301533, lng: 19.5229803 },
    { lat: 49.4309447, lng: 19.5233409 },
    { lat: 49.431889, lng: 19.5232711 },
    { lat: 49.432543, lng: 19.5229064 },
    { lat: 49.4326353, lng: 19.5229549 },
    { lat: 49.4326975, lng: 19.522987 },
    { lat: 49.4327265, lng: 19.5230016 },
    { lat: 49.4329363, lng: 19.5231107 },
    { lat: 49.4333562, lng: 19.5233277 },
    { lat: 49.4336692, lng: 19.5234207 },
    { lat: 49.4337731, lng: 19.5234517 },
    { lat: 49.4338794, lng: 19.5234834 },
    { lat: 49.434022, lng: 19.5235258 },
    { lat: 49.4340665, lng: 19.5235395 },
    { lat: 49.4344107, lng: 19.5233508 },
    { lat: 49.4351328, lng: 19.5228004 },
    { lat: 49.4352286, lng: 19.5227276 },
    { lat: 49.4355401, lng: 19.5224899 },
    { lat: 49.4357122, lng: 19.5223593 },
    { lat: 49.4360233, lng: 19.5221126 },
  ],
  Hruštín: [
    { lat: 49.3626972, lng: 19.34577 },
    { lat: 49.3623681, lng: 19.3449753 },
    { lat: 49.3620391, lng: 19.3442342 },
    { lat: 49.3618637, lng: 19.3437368 },
    { lat: 49.3616945, lng: 19.343275 },
    { lat: 49.3616214, lng: 19.3431366 },
    { lat: 49.3614005, lng: 19.3423691 },
    { lat: 49.3613683, lng: 19.3422562 },
    { lat: 49.360986, lng: 19.342538 },
    { lat: 49.3605818, lng: 19.342906 },
    { lat: 49.3602907, lng: 19.3431842 },
    { lat: 49.3601901, lng: 19.3432296 },
    { lat: 49.3601681, lng: 19.3432399 },
    { lat: 49.3599011, lng: 19.3433611 },
    { lat: 49.3594979, lng: 19.3425042 },
    { lat: 49.3593961, lng: 19.3423259 },
    { lat: 49.3587779, lng: 19.3411832 },
    { lat: 49.3585406, lng: 19.34059 },
    { lat: 49.3585291, lng: 19.340588 },
    { lat: 49.3585421, lng: 19.3404319 },
    { lat: 49.3585909, lng: 19.3401485 },
    { lat: 49.3586006, lng: 19.3398774 },
    { lat: 49.3586448, lng: 19.3395967 },
    { lat: 49.35866, lng: 19.3392707 },
    { lat: 49.3587153, lng: 19.3389338 },
    { lat: 49.3587329, lng: 19.3389279 },
    { lat: 49.3590227, lng: 19.3388324 },
    { lat: 49.3590553, lng: 19.3389789 },
    { lat: 49.3590923, lng: 19.3389726 },
    { lat: 49.3591238, lng: 19.3388549 },
    { lat: 49.3593014, lng: 19.3386976 },
    { lat: 49.3593726, lng: 19.3386818 },
    { lat: 49.3596043, lng: 19.3386918 },
    { lat: 49.3596993, lng: 19.3386528 },
    { lat: 49.3598161, lng: 19.3384508 },
    { lat: 49.3599912, lng: 19.3384843 },
    { lat: 49.3600228, lng: 19.3384364 },
    { lat: 49.359996, lng: 19.3383233 },
    { lat: 49.3600253, lng: 19.3382731 },
    { lat: 49.3602972, lng: 19.3381401 },
    { lat: 49.3602778, lng: 19.3380385 },
    { lat: 49.3603239, lng: 19.3379141 },
    { lat: 49.3603397, lng: 19.3377934 },
    { lat: 49.3602263, lng: 19.3376873 },
    { lat: 49.3602413, lng: 19.3373578 },
    { lat: 49.360174, lng: 19.3372173 },
    { lat: 49.3601849, lng: 19.3371178 },
    { lat: 49.3603414, lng: 19.336969 },
    { lat: 49.3602978, lng: 19.3367605 },
    { lat: 49.360329, lng: 19.3366301 },
    { lat: 49.3604323, lng: 19.3366018 },
    { lat: 49.3605504, lng: 19.3364966 },
    { lat: 49.3605763, lng: 19.3363795 },
    { lat: 49.3607062, lng: 19.3361938 },
    { lat: 49.3607817, lng: 19.3361436 },
    { lat: 49.3608522, lng: 19.3360158 },
    { lat: 49.3609585, lng: 19.3360015 },
    { lat: 49.3610343, lng: 19.3358862 },
    { lat: 49.3613768, lng: 19.3357158 },
    { lat: 49.3614233, lng: 19.335651 },
    { lat: 49.3611421, lng: 19.3351585 },
    { lat: 49.3610078, lng: 19.3351563 },
    { lat: 49.3607444, lng: 19.3353035 },
    { lat: 49.3607456, lng: 19.3352795 },
    { lat: 49.3607461, lng: 19.3350726 },
    { lat: 49.3606023, lng: 19.3348473 },
    { lat: 49.3606016, lng: 19.3342285 },
    { lat: 49.360739, lng: 19.33344 },
    { lat: 49.360736, lng: 19.3330842 },
    { lat: 49.3606702, lng: 19.3328359 },
    { lat: 49.3606636, lng: 19.3325781 },
    { lat: 49.360659, lng: 19.3323342 },
    { lat: 49.3606735, lng: 19.3314942 },
    { lat: 49.3606848, lng: 19.3313968 },
    { lat: 49.3606896, lng: 19.3313536 },
    { lat: 49.3607749, lng: 19.3306056 },
    { lat: 49.3608252, lng: 19.3299026 },
    { lat: 49.3609664, lng: 19.3274619 },
    { lat: 49.3608399, lng: 19.3250654 },
    { lat: 49.3608379, lng: 19.3250223 },
    { lat: 49.3608373, lng: 19.3250097 },
    { lat: 49.3606395, lng: 19.3226982 },
    { lat: 49.36057, lng: 19.3226823 },
    { lat: 49.3604878, lng: 19.3226813 },
    { lat: 49.3603955, lng: 19.322733 },
    { lat: 49.360402, lng: 19.3227515 },
    { lat: 49.3603855, lng: 19.3227808 },
    { lat: 49.3603645, lng: 19.3227925 },
    { lat: 49.3602958, lng: 19.3228431 },
    { lat: 49.3600821, lng: 19.3227329 },
    { lat: 49.3600572, lng: 19.3227292 },
    { lat: 49.359845, lng: 19.3227457 },
    { lat: 49.3597278, lng: 19.3227912 },
    { lat: 49.3596265, lng: 19.3229159 },
    { lat: 49.3595997, lng: 19.3229427 },
    { lat: 49.359519, lng: 19.3229744 },
    { lat: 49.359507, lng: 19.3230097 },
    { lat: 49.3594563, lng: 19.3231516 },
    { lat: 49.3594448, lng: 19.3231738 },
    { lat: 49.3593673, lng: 19.3231527 },
    { lat: 49.3592657, lng: 19.3232218 },
    { lat: 49.3592055, lng: 19.3232823 },
    { lat: 49.3591228, lng: 19.3233471 },
    { lat: 49.3589979, lng: 19.3234483 },
    { lat: 49.3589257, lng: 19.3235134 },
    { lat: 49.3589041, lng: 19.3234873 },
    { lat: 49.3588611, lng: 19.323531 },
    { lat: 49.3587593, lng: 19.3235698 },
    { lat: 49.3586599, lng: 19.3236162 },
    { lat: 49.3585889, lng: 19.3236381 },
    { lat: 49.3585398, lng: 19.3236486 },
    { lat: 49.3584226, lng: 19.3236264 },
    { lat: 49.3583666, lng: 19.3236818 },
    { lat: 49.3583051, lng: 19.3237146 },
    { lat: 49.3582777, lng: 19.3237285 },
    { lat: 49.3582772, lng: 19.323769 },
    { lat: 49.3582489, lng: 19.3237884 },
    { lat: 49.35816, lng: 19.323817 },
    { lat: 49.3580373, lng: 19.3238649 },
    { lat: 49.3578663, lng: 19.3239434 },
    { lat: 49.3577616, lng: 19.3240175 },
    { lat: 49.3576593, lng: 19.3240714 },
    { lat: 49.3575965, lng: 19.3241724 },
    { lat: 49.3575333, lng: 19.324243 },
    { lat: 49.3575044, lng: 19.3242772 },
    { lat: 49.3574624, lng: 19.3242931 },
    { lat: 49.357405, lng: 19.3243667 },
    { lat: 49.3573242, lng: 19.3244208 },
    { lat: 49.3573088, lng: 19.3244506 },
    { lat: 49.3572577, lng: 19.3245647 },
    { lat: 49.3571333, lng: 19.324701 },
    { lat: 49.357109, lng: 19.3247811 },
    { lat: 49.3571217, lng: 19.3248093 },
    { lat: 49.3570709, lng: 19.3249053 },
    { lat: 49.3569909, lng: 19.3250717 },
    { lat: 49.356978, lng: 19.3251089 },
    { lat: 49.3569574, lng: 19.3252475 },
    { lat: 49.3569712, lng: 19.3253262 },
    { lat: 49.3568542, lng: 19.3254223 },
    { lat: 49.3567314, lng: 19.3255685 },
    { lat: 49.3566275, lng: 19.3256349 },
    { lat: 49.3565925, lng: 19.325684 },
    { lat: 49.3565236, lng: 19.3257743 },
    { lat: 49.3564674, lng: 19.3257795 },
    { lat: 49.3563943, lng: 19.3257814 },
    { lat: 49.3563451, lng: 19.3258352 },
    { lat: 49.3561878, lng: 19.3258938 },
    { lat: 49.3561368, lng: 19.3259146 },
    { lat: 49.3560467, lng: 19.325913 },
    { lat: 49.3559724, lng: 19.3259147 },
    { lat: 49.355909, lng: 19.3259122 },
    { lat: 49.3558002, lng: 19.3259174 },
    { lat: 49.3557035, lng: 19.3259259 },
    { lat: 49.3555642, lng: 19.3258397 },
    { lat: 49.35551, lng: 19.3258377 },
    { lat: 49.3554468, lng: 19.3257843 },
    { lat: 49.3554043, lng: 19.3256737 },
    { lat: 49.3553593, lng: 19.3256266 },
    { lat: 49.355273, lng: 19.3256382 },
    { lat: 49.3551465, lng: 19.3255593 },
    { lat: 49.355106, lng: 19.3255374 },
    { lat: 49.3550632, lng: 19.3255862 },
    { lat: 49.3549139, lng: 19.3255495 },
    { lat: 49.3548507, lng: 19.3255977 },
    { lat: 49.3546326, lng: 19.3255831 },
    { lat: 49.3545295, lng: 19.325546 },
    { lat: 49.3543991, lng: 19.3254342 },
    { lat: 49.3543435, lng: 19.3254469 },
    { lat: 49.3543016, lng: 19.3254453 },
    { lat: 49.3542379, lng: 19.32543 },
    { lat: 49.3541892, lng: 19.3254053 },
    { lat: 49.3541413, lng: 19.3254413 },
    { lat: 49.3540358, lng: 19.3254009 },
    { lat: 49.3539932, lng: 19.3255256 },
    { lat: 49.3539081, lng: 19.3255824 },
    { lat: 49.3538587, lng: 19.3255379 },
    { lat: 49.3538113, lng: 19.3255381 },
    { lat: 49.3537622, lng: 19.3256194 },
    { lat: 49.3536838, lng: 19.3256035 },
    { lat: 49.353642, lng: 19.3256551 },
    { lat: 49.3535606, lng: 19.3256207 },
    { lat: 49.3534519, lng: 19.3256139 },
    { lat: 49.3533251, lng: 19.3256944 },
    { lat: 49.3532432, lng: 19.3256553 },
    { lat: 49.3531881, lng: 19.3256328 },
    { lat: 49.353147, lng: 19.3256486 },
    { lat: 49.352982, lng: 19.3255128 },
    { lat: 49.3528852, lng: 19.3254685 },
    { lat: 49.3528178, lng: 19.3254962 },
    { lat: 49.3527019, lng: 19.3255491 },
    { lat: 49.3526166, lng: 19.3256265 },
    { lat: 49.352592, lng: 19.3256306 },
    { lat: 49.352481, lng: 19.3256458 },
    { lat: 49.3523517, lng: 19.325673 },
    { lat: 49.3523051, lng: 19.3256915 },
    { lat: 49.3522397, lng: 19.3257367 },
    { lat: 49.3521979, lng: 19.3257623 },
    { lat: 49.352169, lng: 19.3257893 },
    { lat: 49.3520424, lng: 19.3258091 },
    { lat: 49.3518717, lng: 19.3259154 },
    { lat: 49.3517818, lng: 19.3259669 },
    { lat: 49.351716, lng: 19.3260352 },
    { lat: 49.3515979, lng: 19.3261335 },
    { lat: 49.3515633, lng: 19.326142 },
    { lat: 49.3515219, lng: 19.3261708 },
    { lat: 49.3514559, lng: 19.326203 },
    { lat: 49.351422, lng: 19.3262494 },
    { lat: 49.3513632, lng: 19.3262444 },
    { lat: 49.3513493, lng: 19.3263099 },
    { lat: 49.3512178, lng: 19.3263674 },
    { lat: 49.3511148, lng: 19.3265016 },
    { lat: 49.3510734, lng: 19.3265756 },
    { lat: 49.3509832, lng: 19.3266246 },
    { lat: 49.3508714, lng: 19.3267388 },
    { lat: 49.3507413, lng: 19.3268696 },
    { lat: 49.3506255, lng: 19.3269478 },
    { lat: 49.3505413, lng: 19.3270503 },
    { lat: 49.3504935, lng: 19.3271138 },
    { lat: 49.3504068, lng: 19.3271179 },
    { lat: 49.3502993, lng: 19.3271308 },
    { lat: 49.350161, lng: 19.3270622 },
    { lat: 49.3500571, lng: 19.3270578 },
    { lat: 49.3499901, lng: 19.3270749 },
    { lat: 49.3498966, lng: 19.3271189 },
    { lat: 49.349844, lng: 19.3271518 },
    { lat: 49.3497941, lng: 19.3271952 },
    { lat: 49.3496893, lng: 19.3271959 },
    { lat: 49.3495517, lng: 19.3272176 },
    { lat: 49.3493853, lng: 19.3272237 },
    { lat: 49.3493187, lng: 19.3272682 },
    { lat: 49.3492557, lng: 19.3273189 },
    { lat: 49.3491241, lng: 19.3273739 },
    { lat: 49.3489674, lng: 19.3274201 },
    { lat: 49.3488877, lng: 19.3274726 },
    { lat: 49.3488294, lng: 19.3275282 },
    { lat: 49.3487586, lng: 19.3275801 },
    { lat: 49.3486924, lng: 19.3276333 },
    { lat: 49.3486352, lng: 19.3275424 },
    { lat: 49.3486132, lng: 19.3275068 },
    { lat: 49.3484845, lng: 19.3276472 },
    { lat: 49.3483303, lng: 19.3277213 },
    { lat: 49.3481494, lng: 19.3278775 },
    { lat: 49.3478267, lng: 19.3283005 },
    { lat: 49.3475867, lng: 19.3285655 },
    { lat: 49.3474685, lng: 19.3287322 },
    { lat: 49.3473543, lng: 19.3288712 },
    { lat: 49.3473291, lng: 19.3289073 },
    { lat: 49.3475183, lng: 19.329863 },
    { lat: 49.3474405, lng: 19.3306281 },
    { lat: 49.3474076, lng: 19.3314403 },
    { lat: 49.3474118, lng: 19.3319614 },
    { lat: 49.3474172, lng: 19.331981 },
    { lat: 49.3474307, lng: 19.3320265 },
    { lat: 49.3472852, lng: 19.3325525 },
    { lat: 49.3472111, lng: 19.3329416 },
    { lat: 49.3471537, lng: 19.3333278 },
    { lat: 49.3470771, lng: 19.3337129 },
    { lat: 49.3471116, lng: 19.333772 },
    { lat: 49.3471507, lng: 19.3338356 },
    { lat: 49.3468618, lng: 19.3344764 },
    { lat: 49.3464582, lng: 19.3349745 },
    { lat: 49.3460207, lng: 19.3353761 },
    { lat: 49.3459853, lng: 19.3354169 },
    { lat: 49.3455917, lng: 19.3358664 },
    { lat: 49.3451218, lng: 19.3363439 },
    { lat: 49.3448599, lng: 19.3365043 },
    { lat: 49.3447957, lng: 19.3365995 },
    { lat: 49.3444225, lng: 19.3371469 },
    { lat: 49.3442335, lng: 19.337587 },
    { lat: 49.3439888, lng: 19.3381589 },
    { lat: 49.3439637, lng: 19.3381262 },
    { lat: 49.343733, lng: 19.3383732 },
    { lat: 49.3435666, lng: 19.3385516 },
    { lat: 49.3433345, lng: 19.3386917 },
    { lat: 49.3431637, lng: 19.3390166 },
    { lat: 49.3428284, lng: 19.3392688 },
    { lat: 49.3423074, lng: 19.3394985 },
    { lat: 49.3415053, lng: 19.3402485 },
    { lat: 49.3413559, lng: 19.3405721 },
    { lat: 49.3409439, lng: 19.3414684 },
    { lat: 49.3406112, lng: 19.3417977 },
    { lat: 49.3405617, lng: 19.3419456 },
    { lat: 49.3405906, lng: 19.3421267 },
    { lat: 49.34039, lng: 19.3424169 },
    { lat: 49.3402574, lng: 19.3429266 },
    { lat: 49.3402422, lng: 19.342986 },
    { lat: 49.3402774, lng: 19.3432839 },
    { lat: 49.3401831, lng: 19.3438201 },
    { lat: 49.3400764, lng: 19.3444245 },
    { lat: 49.3399499, lng: 19.3448283 },
    { lat: 49.3401457, lng: 19.3466018 },
    { lat: 49.3402257, lng: 19.3471047 },
    { lat: 49.3404185, lng: 19.3484066 },
    { lat: 49.3404625, lng: 19.3493898 },
    { lat: 49.3403995, lng: 19.3498036 },
    { lat: 49.3401071, lng: 19.3507301 },
    { lat: 49.340056, lng: 19.3508897 },
    { lat: 49.339925, lng: 19.3513634 },
    { lat: 49.3399172, lng: 19.3513895 },
    { lat: 49.3399213, lng: 19.3515716 },
    { lat: 49.3399306, lng: 19.3520573 },
    { lat: 49.340116, lng: 19.352265 },
    { lat: 49.3401528, lng: 19.3523061 },
    { lat: 49.340167, lng: 19.3523212 },
    { lat: 49.3401778, lng: 19.3523338 },
    { lat: 49.3402711, lng: 19.3526892 },
    { lat: 49.3402793, lng: 19.3527227 },
    { lat: 49.3403064, lng: 19.3528426 },
    { lat: 49.3403201, lng: 19.3528718 },
    { lat: 49.3405268, lng: 19.3533117 },
    { lat: 49.3410061, lng: 19.3543306 },
    { lat: 49.3410947, lng: 19.3545186 },
    { lat: 49.3412224, lng: 19.3549108 },
    { lat: 49.3412567, lng: 19.3550179 },
    { lat: 49.3412819, lng: 19.3550965 },
    { lat: 49.3413173, lng: 19.3552025 },
    { lat: 49.341324, lng: 19.3552234 },
    { lat: 49.3414285, lng: 19.3554595 },
    { lat: 49.3415406, lng: 19.3556637 },
    { lat: 49.3416748, lng: 19.3559085 },
    { lat: 49.3417499, lng: 19.3560448 },
    { lat: 49.3417505, lng: 19.356057 },
    { lat: 49.341769, lng: 19.3563314 },
    { lat: 49.3417699, lng: 19.3563509 },
    { lat: 49.3417801, lng: 19.3564928 },
    { lat: 49.3417646, lng: 19.3565759 },
    { lat: 49.3417613, lng: 19.3566008 },
    { lat: 49.3417365, lng: 19.3567408 },
    { lat: 49.341727, lng: 19.3567553 },
    { lat: 49.3417153, lng: 19.3567735 },
    { lat: 49.3416879, lng: 19.3567632 },
    { lat: 49.3415784, lng: 19.3567835 },
    { lat: 49.3415773, lng: 19.3567861 },
    { lat: 49.3414467, lng: 19.3569772 },
    { lat: 49.3413946, lng: 19.3570006 },
    { lat: 49.3413361, lng: 19.3570269 },
    { lat: 49.3412885, lng: 19.3572601 },
    { lat: 49.3413958, lng: 19.3573622 },
    { lat: 49.3413776, lng: 19.3576204 },
    { lat: 49.3411343, lng: 19.3579857 },
    { lat: 49.3411944, lng: 19.3581367 },
    { lat: 49.3412172, lng: 19.3581938 },
    { lat: 49.341303, lng: 19.3582205 },
    { lat: 49.3414015, lng: 19.358252 },
    { lat: 49.3415242, lng: 19.3583954 },
    { lat: 49.3416323, lng: 19.3584923 },
    { lat: 49.3418497, lng: 19.3584132 },
    { lat: 49.3418506, lng: 19.3584128 },
    { lat: 49.3419069, lng: 19.3583589 },
    { lat: 49.3419299, lng: 19.3583459 },
    { lat: 49.3420835, lng: 19.3582582 },
    { lat: 49.3422346, lng: 19.3582128 },
    { lat: 49.3422458, lng: 19.3582094 },
    { lat: 49.3423245, lng: 19.3581863 },
    { lat: 49.3423853, lng: 19.3580358 },
    { lat: 49.342528, lng: 19.3579826 },
    { lat: 49.3428143, lng: 19.3581514 },
    { lat: 49.3429562, lng: 19.3580762 },
    { lat: 49.3431157, lng: 19.3581646 },
    { lat: 49.3431847, lng: 19.358285 },
    { lat: 49.3434547, lng: 19.3587632 },
    { lat: 49.3436831, lng: 19.3591877 },
    { lat: 49.3439463, lng: 19.3594888 },
    { lat: 49.3442116, lng: 19.3595458 },
    { lat: 49.3443068, lng: 19.3595574 },
    { lat: 49.3444631, lng: 19.3595764 },
    { lat: 49.3447414, lng: 19.3594783 },
    { lat: 49.3450946, lng: 19.3595134 },
    { lat: 49.3452033, lng: 19.3593594 },
    { lat: 49.3454599, lng: 19.3594224 },
    { lat: 49.345702, lng: 19.3593434 },
    { lat: 49.3461033, lng: 19.3596388 },
    { lat: 49.3462769, lng: 19.359978 },
    { lat: 49.3464774, lng: 19.3599014 },
    { lat: 49.3468108, lng: 19.360235 },
    { lat: 49.3469791, lng: 19.3601985 },
    { lat: 49.3473119, lng: 19.360085 },
    { lat: 49.3475619, lng: 19.3598668 },
    { lat: 49.3478262, lng: 19.3597309 },
    { lat: 49.3481464, lng: 19.3596394 },
    { lat: 49.3482412, lng: 19.3597898 },
    { lat: 49.3484043, lng: 19.3600456 },
    { lat: 49.3487303, lng: 19.3604367 },
    { lat: 49.3490898, lng: 19.3604642 },
    { lat: 49.3494986, lng: 19.3606026 },
    { lat: 49.349933, lng: 19.3605655 },
    { lat: 49.3501071, lng: 19.3605067 },
    { lat: 49.3505147, lng: 19.3609814 },
    { lat: 49.3509283, lng: 19.3613424 },
    { lat: 49.3513252, lng: 19.3616391 },
    { lat: 49.3515884, lng: 19.3615318 },
    { lat: 49.3520192, lng: 19.3612959 },
    { lat: 49.3525134, lng: 19.36071 },
    { lat: 49.3529015, lng: 19.360935 },
    { lat: 49.353382, lng: 19.3610204 },
    { lat: 49.353415, lng: 19.3608068 },
    { lat: 49.3534598, lng: 19.3605138 },
    { lat: 49.3537008, lng: 19.3597395 },
    { lat: 49.353816, lng: 19.3594804 },
    { lat: 49.3543315, lng: 19.3588643 },
    { lat: 49.3547572, lng: 19.3584504 },
    { lat: 49.3547912, lng: 19.3583093 },
    { lat: 49.3547987, lng: 19.3582766 },
    { lat: 49.3549071, lng: 19.3578237 },
    { lat: 49.3550003, lng: 19.3572424 },
    { lat: 49.3552244, lng: 19.3567782 },
    { lat: 49.3553467, lng: 19.3563578 },
    { lat: 49.3554203, lng: 19.3561758 },
    { lat: 49.3554801, lng: 19.3560297 },
    { lat: 49.3555274, lng: 19.3559114 },
    { lat: 49.3557331, lng: 19.355634 },
    { lat: 49.3558805, lng: 19.3551258 },
    { lat: 49.3560821, lng: 19.3544487 },
    { lat: 49.3564583, lng: 19.3533331 },
    { lat: 49.3567065, lng: 19.3526179 },
    { lat: 49.3569716, lng: 19.3522186 },
    { lat: 49.357409, lng: 19.3514733 },
    { lat: 49.3577294, lng: 19.3505716 },
    { lat: 49.3578252, lng: 19.3500159 },
    { lat: 49.3578551, lng: 19.3496211 },
    { lat: 49.3579165, lng: 19.3490305 },
    { lat: 49.3581939, lng: 19.348409 },
    { lat: 49.3581708, lng: 19.3480117 },
    { lat: 49.3581692, lng: 19.3479772 },
    { lat: 49.3581654, lng: 19.3479215 },
    { lat: 49.3583159, lng: 19.3476462 },
    { lat: 49.3585209, lng: 19.3475213 },
    { lat: 49.3585354, lng: 19.347519 },
    { lat: 49.3592969, lng: 19.347371 },
    { lat: 49.3595011, lng: 19.3471602 },
    { lat: 49.3598179, lng: 19.3472332 },
    { lat: 49.3603356, lng: 19.3474109 },
    { lat: 49.3606812, lng: 19.34748 },
    { lat: 49.3609056, lng: 19.3470981 },
    { lat: 49.3610861, lng: 19.3470743 },
    { lat: 49.3612446, lng: 19.3469709 },
    { lat: 49.3612859, lng: 19.3469406 },
    { lat: 49.3616476, lng: 19.3466768 },
    { lat: 49.3618227, lng: 19.3462251 },
    { lat: 49.3622413, lng: 19.3459278 },
    { lat: 49.3625437, lng: 19.3459347 },
    { lat: 49.3626972, lng: 19.34577 },
  ],
  HruštínExt: [
    { lat: 49.303075, lng: 19.363637 },
    { lat: 49.3037029, lng: 19.3640497 },
    { lat: 49.3043943, lng: 19.3646146 },
    { lat: 49.3051573, lng: 19.3651462 },
    { lat: 49.3057014, lng: 19.3656013 },
    { lat: 49.3065623, lng: 19.3660849 },
    { lat: 49.3072854, lng: 19.3664345 },
    { lat: 49.3079251, lng: 19.3667441 },
    { lat: 49.3084692, lng: 19.3670021 },
    { lat: 49.3088837, lng: 19.3671453 },
    { lat: 49.3096138, lng: 19.3670478 },
    { lat: 49.3101286, lng: 19.3667304 },
    { lat: 49.3105079, lng: 19.3665516 },
    { lat: 49.3107766, lng: 19.3664645 },
    { lat: 49.3113794, lng: 19.3663671 },
    { lat: 49.3120856, lng: 19.3660923 },
    { lat: 49.3125733, lng: 19.3661159 },
    { lat: 49.3129038, lng: 19.3661242 },
    { lat: 49.3131275, lng: 19.3661348 },
    { lat: 49.3136024, lng: 19.3661908 },
    { lat: 49.3140061, lng: 19.366367 },
    { lat: 49.3146659, lng: 19.3663068 },
    { lat: 49.3152574, lng: 19.3663266 },
    { lat: 49.3157087, lng: 19.3667009 },
    { lat: 49.3162254, lng: 19.3669803 },
    { lat: 49.3169101, lng: 19.3672616 },
    { lat: 49.3171541, lng: 19.3671511 },
    { lat: 49.3174511, lng: 19.3669731 },
    { lat: 49.3184801, lng: 19.366396 },
    { lat: 49.3187309, lng: 19.3660486 },
    { lat: 49.3191687, lng: 19.3657503 },
    { lat: 49.3197152, lng: 19.3653391 },
    { lat: 49.3198454, lng: 19.3653292 },
    { lat: 49.3201887, lng: 19.3652226 },
    { lat: 49.3205234, lng: 19.3653659 },
    { lat: 49.3210004, lng: 19.3651342 },
    { lat: 49.3213844, lng: 19.3646453 },
    { lat: 49.3216227, lng: 19.3645163 },
    { lat: 49.3223863, lng: 19.3641213 },
    { lat: 49.3226994, lng: 19.3642612 },
    { lat: 49.323099, lng: 19.364323 },
    { lat: 49.3234769, lng: 19.3640938 },
    { lat: 49.3239802, lng: 19.363913 },
    { lat: 49.3246176, lng: 19.3636622 },
    { lat: 49.3246375, lng: 19.3636546 },
    { lat: 49.3246574, lng: 19.3636441 },
    { lat: 49.3249983, lng: 19.3634659 },
    { lat: 49.3252992, lng: 19.3633976 },
    { lat: 49.3258797, lng: 19.3634237 },
    { lat: 49.3263811, lng: 19.3634464 },
    { lat: 49.327101, lng: 19.3635411 },
    { lat: 49.3272173, lng: 19.363615 },
    { lat: 49.327705, lng: 19.3641668 },
    { lat: 49.3280306, lng: 19.3644795 },
    { lat: 49.3281369, lng: 19.3644845 },
    { lat: 49.3282274, lng: 19.364446 },
    { lat: 49.328254, lng: 19.3644348 },
    { lat: 49.3283263, lng: 19.3644142 },
    { lat: 49.3283597, lng: 19.3644066 },
    { lat: 49.3284856, lng: 19.3643776 },
    { lat: 49.3287375, lng: 19.3643859 },
    { lat: 49.3291122, lng: 19.3645464 },
    { lat: 49.3297379, lng: 19.364845 },
    { lat: 49.3300123, lng: 19.3649762 },
    { lat: 49.33009, lng: 19.3650208 },
    { lat: 49.3301331, lng: 19.3650291 },
    { lat: 49.3304939, lng: 19.365176 },
    { lat: 49.3306576, lng: 19.3651574 },
    { lat: 49.3308782, lng: 19.3650068 },
    { lat: 49.3311948, lng: 19.3648142 },
    { lat: 49.3314072, lng: 19.3646848 },
    { lat: 49.3314313, lng: 19.3646695 },
    { lat: 49.331438, lng: 19.3646655 },
    { lat: 49.3316963, lng: 19.3644797 },
    { lat: 49.3319292, lng: 19.3643245 },
    { lat: 49.3319684, lng: 19.3642989 },
    { lat: 49.3321202, lng: 19.3642176 },
    { lat: 49.3322401, lng: 19.3645893 },
    { lat: 49.3325812, lng: 19.3652078 },
    { lat: 49.3326899, lng: 19.3652663 },
    { lat: 49.332918, lng: 19.3651084 },
    { lat: 49.3328214, lng: 19.3649983 },
    { lat: 49.332929, lng: 19.3648402 },
    { lat: 49.3331069, lng: 19.364644 },
    { lat: 49.3332154, lng: 19.3643839 },
    { lat: 49.3330453, lng: 19.363974 },
    { lat: 49.333019, lng: 19.3637236 },
    { lat: 49.3333248, lng: 19.363543 },
    { lat: 49.3335578, lng: 19.3637275 },
    { lat: 49.33367, lng: 19.3634312 },
    { lat: 49.3336122, lng: 19.3631597 },
    { lat: 49.3334748, lng: 19.3631342 },
    { lat: 49.3333879, lng: 19.3629869 },
    { lat: 49.3335225, lng: 19.3629975 },
    { lat: 49.3335662, lng: 19.3626798 },
    { lat: 49.333403, lng: 19.3625632 },
    { lat: 49.333537, lng: 19.3621768 },
    { lat: 49.333573, lng: 19.3617652 },
    { lat: 49.3335559, lng: 19.3613028 },
    { lat: 49.3334626, lng: 19.3612368 },
    { lat: 49.3336235, lng: 19.3610631 },
    { lat: 49.3336945, lng: 19.3611575 },
    { lat: 49.3338441, lng: 19.3609587 },
    { lat: 49.3340352, lng: 19.3606361 },
    { lat: 49.333968, lng: 19.3604324 },
    { lat: 49.3341283, lng: 19.359931 },
    { lat: 49.3339783, lng: 19.3597588 },
    { lat: 49.3340089, lng: 19.3594989 },
    { lat: 49.3342288, lng: 19.3593531 },
    { lat: 49.3342378, lng: 19.3589214 },
    { lat: 49.3341162, lng: 19.3587782 },
    { lat: 49.334146, lng: 19.3583099 },
    { lat: 49.334105, lng: 19.3575318 },
    { lat: 49.3343857, lng: 19.357509 },
    { lat: 49.3343867, lng: 19.3571229 },
    { lat: 49.3343432, lng: 19.3571313 },
    { lat: 49.3344619, lng: 19.3567259 },
    { lat: 49.3346435, lng: 19.3564679 },
    { lat: 49.3345397, lng: 19.3563456 },
    { lat: 49.3345208, lng: 19.3563245 },
    { lat: 49.3344746, lng: 19.3563007 },
    { lat: 49.33445, lng: 19.3562795 },
    { lat: 49.3343995, lng: 19.3562355 },
    { lat: 49.3343173, lng: 19.3559967 },
    { lat: 49.3344384, lng: 19.3559368 },
    { lat: 49.334465, lng: 19.3556379 },
    { lat: 49.3346206, lng: 19.3555215 },
    { lat: 49.3345525, lng: 19.3553884 },
    { lat: 49.3346592, lng: 19.3553113 },
    { lat: 49.3346123, lng: 19.3551018 },
    { lat: 49.3348908, lng: 19.3548169 },
    { lat: 49.3350515, lng: 19.3548799 },
    { lat: 49.3350583, lng: 19.3548827 },
    { lat: 49.3350804, lng: 19.3548467 },
    { lat: 49.3351545, lng: 19.3547201 },
    { lat: 49.3352243, lng: 19.3546018 },
    { lat: 49.3352683, lng: 19.3545269 },
    { lat: 49.3353167, lng: 19.3543106 },
    { lat: 49.3354955, lng: 19.3542559 },
    { lat: 49.3355423, lng: 19.354241 },
    { lat: 49.3355777, lng: 19.3542303 },
    { lat: 49.3358601, lng: 19.3539536 },
    { lat: 49.3360406, lng: 19.3536939 },
    { lat: 49.335872, lng: 19.3533647 },
    { lat: 49.3358469, lng: 19.3533144 },
    { lat: 49.3358272, lng: 19.3532771 },
    { lat: 49.335892, lng: 19.3530966 },
    { lat: 49.3359339, lng: 19.3529825 },
    { lat: 49.3359436, lng: 19.3529945 },
    { lat: 49.3359939, lng: 19.353059 },
    { lat: 49.3360878, lng: 19.3529725 },
    { lat: 49.3360972, lng: 19.3526875 },
    { lat: 49.3360713, lng: 19.3522111 },
    { lat: 49.336204, lng: 19.3520101 },
    { lat: 49.3362243, lng: 19.3516256 },
    { lat: 49.3361335, lng: 19.3513457 },
    { lat: 49.3360939, lng: 19.3511756 },
    { lat: 49.3360419, lng: 19.350958 },
    { lat: 49.3360218, lng: 19.3504095 },
    { lat: 49.3360215, lng: 19.350352 },
    { lat: 49.3360197, lng: 19.3500512 },
    { lat: 49.3360056, lng: 19.3497254 },
    { lat: 49.3360369, lng: 19.3494061 },
    { lat: 49.3360054, lng: 19.3491671 },
    { lat: 49.3361248, lng: 19.3490944 },
    { lat: 49.3361023, lng: 19.3488768 },
    { lat: 49.3362234, lng: 19.3488212 },
    { lat: 49.3362502, lng: 19.3485975 },
    { lat: 49.3361569, lng: 19.3483602 },
    { lat: 49.3360282, lng: 19.3481178 },
    { lat: 49.3361109, lng: 19.3479071 },
    { lat: 49.3363212, lng: 19.3480209 },
    { lat: 49.3363461, lng: 19.3478773 },
    { lat: 49.3362792, lng: 19.3478196 },
    { lat: 49.3363635, lng: 19.3475534 },
    { lat: 49.3363825, lng: 19.3471871 },
    { lat: 49.3365424, lng: 19.347043 },
    { lat: 49.3365197, lng: 19.3468012 },
    { lat: 49.3364716, lng: 19.3464188 },
    { lat: 49.336548, lng: 19.3461499 },
    { lat: 49.3364434, lng: 19.3457722 },
    { lat: 49.3363806, lng: 19.345683 },
    { lat: 49.3365298, lng: 19.3453551 },
    { lat: 49.3365537, lng: 19.3451187 },
    { lat: 49.3367323, lng: 19.3451558 },
    { lat: 49.3368671, lng: 19.3448833 },
    { lat: 49.3367999, lng: 19.3446041 },
    { lat: 49.3368207, lng: 19.3442575 },
    { lat: 49.3369253, lng: 19.3440097 },
    { lat: 49.3367593, lng: 19.3441013 },
    { lat: 49.3367823, lng: 19.3438205 },
    { lat: 49.3367796, lng: 19.3434974 },
    { lat: 49.3367744, lng: 19.3430022 },
    { lat: 49.3365767, lng: 19.342514 },
    { lat: 49.3366196, lng: 19.3423011 },
    { lat: 49.3368583, lng: 19.3420093 },
    { lat: 49.3368715, lng: 19.3416617 },
    { lat: 49.3367716, lng: 19.3411418 },
    { lat: 49.3366576, lng: 19.3406868 },
    { lat: 49.3366502, lng: 19.3400697 },
    { lat: 49.336853, lng: 19.339948 },
    { lat: 49.3370396, lng: 19.3397934 },
    { lat: 49.337293, lng: 19.3392155 },
    { lat: 49.3372667, lng: 19.338994 },
    { lat: 49.3374545, lng: 19.3390297 },
    { lat: 49.3376187, lng: 19.3389054 },
    { lat: 49.3381581, lng: 19.3392534 },
    { lat: 49.3386926, lng: 19.3390519 },
    { lat: 49.3389007, lng: 19.3391385 },
    { lat: 49.3389429, lng: 19.3388093 },
    { lat: 49.3390838, lng: 19.3388571 },
    { lat: 49.3392935, lng: 19.3385774 },
    { lat: 49.3397017, lng: 19.3383395 },
    { lat: 49.3398537, lng: 19.3381219 },
    { lat: 49.3401003, lng: 19.3380221 },
    { lat: 49.3402429, lng: 19.3377459 },
    { lat: 49.3403653, lng: 19.3375698 },
    { lat: 49.3405447, lng: 19.3372888 },
    { lat: 49.3408114, lng: 19.337305 },
    { lat: 49.340997, lng: 19.3373207 },
    { lat: 49.3415116, lng: 19.3370238 },
    { lat: 49.3418977, lng: 19.3364367 },
    { lat: 49.3421595, lng: 19.3359677 },
    { lat: 49.3423731, lng: 19.3355491 },
    { lat: 49.3426468, lng: 19.3351377 },
    { lat: 49.3430067, lng: 19.3349565 },
    { lat: 49.3434037, lng: 19.3346757 },
    { lat: 49.3436779, lng: 19.3346563 },
    { lat: 49.3438948, lng: 19.334549 },
    { lat: 49.3442561, lng: 19.3343993 },
    { lat: 49.3445814, lng: 19.3341749 },
    { lat: 49.345134, lng: 19.3339648 },
    { lat: 49.345402, lng: 19.3334817 },
    { lat: 49.3456625, lng: 19.3327692 },
    { lat: 49.3457939, lng: 19.3318735 },
    { lat: 49.3460594, lng: 19.3306895 },
    { lat: 49.3463559, lng: 19.3291826 },
    { lat: 49.3463765, lng: 19.3291377 },
    { lat: 49.3467993, lng: 19.328186 },
    { lat: 49.346703, lng: 19.3281016 },
    { lat: 49.3465044, lng: 19.3279319 },
    { lat: 49.3462722, lng: 19.3278534 },
    { lat: 49.3460613, lng: 19.3277831 },
    { lat: 49.3459028, lng: 19.327742 },
    { lat: 49.3457303, lng: 19.3277137 },
    { lat: 49.3456537, lng: 19.3276675 },
    { lat: 49.3455435, lng: 19.3276034 },
    { lat: 49.3454098, lng: 19.3274927 },
    { lat: 49.3453548, lng: 19.3274475 },
    { lat: 49.3452661, lng: 19.3273351 },
    { lat: 49.3451966, lng: 19.3272477 },
    { lat: 49.3451148, lng: 19.3271338 },
    { lat: 49.344989, lng: 19.3269561 },
    { lat: 49.3449552, lng: 19.3269042 },
    { lat: 49.3447971, lng: 19.3266603 },
    { lat: 49.3446403, lng: 19.3263194 },
    { lat: 49.3445655, lng: 19.3259554 },
    { lat: 49.3445225, lng: 19.3256627 },
    { lat: 49.3445178, lng: 19.3256336 },
    { lat: 49.3444961, lng: 19.3254849 },
    { lat: 49.344486, lng: 19.3254152 },
    { lat: 49.3444511, lng: 19.3251754 },
    { lat: 49.3444428, lng: 19.325069 },
    { lat: 49.3444396, lng: 19.3250271 },
    { lat: 49.34441, lng: 19.3248533 },
    { lat: 49.3443869, lng: 19.3247434 },
    { lat: 49.3442707, lng: 19.3242123 },
    { lat: 49.3442421, lng: 19.3241572 },
    { lat: 49.344226, lng: 19.3241258 },
    { lat: 49.3441392, lng: 19.3240074 },
    { lat: 49.3439479, lng: 19.3237712 },
    { lat: 49.3436448, lng: 19.3233915 },
    { lat: 49.3433601, lng: 19.3230188 },
    { lat: 49.3433207, lng: 19.3229683 },
    { lat: 49.3432091, lng: 19.3228252 },
    { lat: 49.3430387, lng: 19.3226062 },
    { lat: 49.3429811, lng: 19.3225319 },
    { lat: 49.3428313, lng: 19.3223399 },
    { lat: 49.342763, lng: 19.3222069 },
    { lat: 49.3427036, lng: 19.3220457 },
    { lat: 49.3426638, lng: 19.3218961 },
    { lat: 49.3425478, lng: 19.3216549 },
    { lat: 49.3424127, lng: 19.3213994 },
    { lat: 49.3423345, lng: 19.3212418 },
    { lat: 49.3422943, lng: 19.3211303 },
    { lat: 49.3422308, lng: 19.3209551 },
    { lat: 49.3422262, lng: 19.3209285 },
    { lat: 49.3422132, lng: 19.3208678 },
    { lat: 49.3421753, lng: 19.3206848 },
    { lat: 49.342161, lng: 19.3206213 },
    { lat: 49.3421533, lng: 19.3205788 },
    { lat: 49.3421102, lng: 19.3203562 },
    { lat: 49.3421102, lng: 19.3202604 },
    { lat: 49.3420731, lng: 19.3201196 },
    { lat: 49.3420551, lng: 19.3199781 },
    { lat: 49.3420448, lng: 19.3199005 },
    { lat: 49.342041, lng: 19.3198449 },
    { lat: 49.3420381, lng: 19.3195929 },
    { lat: 49.3420356, lng: 19.3194186 },
    { lat: 49.3420351, lng: 19.3193641 },
    { lat: 49.3420316, lng: 19.3192886 },
    { lat: 49.3420259, lng: 19.3190941 },
    { lat: 49.3420243, lng: 19.3190585 },
    { lat: 49.3420318, lng: 19.3189337 },
    { lat: 49.3420254, lng: 19.318556 },
    { lat: 49.3420005, lng: 19.3184146 },
    { lat: 49.3419553, lng: 19.3182674 },
    { lat: 49.341912, lng: 19.3180196 },
    { lat: 49.3419094, lng: 19.3179841 },
    { lat: 49.3419255, lng: 19.3176787 },
    { lat: 49.3420704, lng: 19.3174817 },
    { lat: 49.3422597, lng: 19.3173144 },
    { lat: 49.3423456, lng: 19.317199 },
    { lat: 49.3423728, lng: 19.317084 },
    { lat: 49.3423704, lng: 19.3170795 },
    { lat: 49.342347, lng: 19.3170138 },
    { lat: 49.342347, lng: 19.3170124 },
    { lat: 49.3421445, lng: 19.3168056 },
    { lat: 49.3419644, lng: 19.3165974 },
    { lat: 49.3418476, lng: 19.3164835 },
    { lat: 49.3416665, lng: 19.3163239 },
    { lat: 49.341541, lng: 19.3160999 },
    { lat: 49.3411883, lng: 19.3154078 },
    { lat: 49.3411274, lng: 19.3153129 },
    { lat: 49.3410177, lng: 19.3150891 },
    { lat: 49.3409626, lng: 19.3149929 },
    { lat: 49.3408939, lng: 19.3148968 },
    { lat: 49.3408648, lng: 19.3147623 },
    { lat: 49.3408623, lng: 19.3147535 },
    { lat: 49.3408442, lng: 19.3146341 },
    { lat: 49.3407842, lng: 19.3144398 },
    { lat: 49.3406835, lng: 19.3141166 },
    { lat: 49.3406337, lng: 19.3140177 },
    { lat: 49.340579, lng: 19.3139088 },
    { lat: 49.3405152, lng: 19.3137984 },
    { lat: 49.3404263, lng: 19.3136806 },
    { lat: 49.3402853, lng: 19.313535 },
    { lat: 49.3401953, lng: 19.3134185 },
    { lat: 49.3401342, lng: 19.3133212 },
    { lat: 49.340027, lng: 19.3131256 },
    { lat: 49.3399191, lng: 19.3129398 },
    { lat: 49.3397736, lng: 19.3126997 },
    { lat: 49.3397132, lng: 19.3125397 },
    { lat: 49.3395722, lng: 19.3122051 },
    { lat: 49.3394998, lng: 19.3120577 },
    { lat: 49.3393572, lng: 19.311878 },
    { lat: 49.3392056, lng: 19.3115265 },
    { lat: 49.3389744, lng: 19.3111675 },
    { lat: 49.3386863, lng: 19.3107921 },
    { lat: 49.3386062, lng: 19.3106962 },
    { lat: 49.3385356, lng: 19.3105862 },
    { lat: 49.3384275, lng: 19.3104174 },
    { lat: 49.3383036, lng: 19.3102269 },
    { lat: 49.3382459, lng: 19.3101307 },
    { lat: 49.3381715, lng: 19.3100106 },
    { lat: 49.3381157, lng: 19.3098795 },
    { lat: 49.3380853, lng: 19.3098066 },
    { lat: 49.3380128, lng: 19.3096314 },
    { lat: 49.337956, lng: 19.3095274 },
    { lat: 49.3378801, lng: 19.3094216 },
    { lat: 49.3377786, lng: 19.3092518 },
    { lat: 49.337691, lng: 19.3090663 },
    { lat: 49.3376052, lng: 19.3088247 },
    { lat: 49.3374978, lng: 19.308632 },
    { lat: 49.3373748, lng: 19.308436 },
    { lat: 49.3373411, lng: 19.3083888 },
    { lat: 49.337156, lng: 19.3081447 },
    { lat: 49.3371183, lng: 19.3080864 },
    { lat: 49.3370857, lng: 19.3080398 },
    { lat: 49.3369742, lng: 19.3078736 },
    { lat: 49.3368622, lng: 19.3076735 },
    { lat: 49.3367694, lng: 19.3074741 },
    { lat: 49.3367107, lng: 19.3073549 },
    { lat: 49.3366827, lng: 19.3073081 },
    { lat: 49.3365552, lng: 19.3071137 },
    { lat: 49.336474, lng: 19.3069944 },
    { lat: 49.3364274, lng: 19.3069175 },
    { lat: 49.3363957, lng: 19.306865 },
    { lat: 49.3362698, lng: 19.3066303 },
    { lat: 49.3361793, lng: 19.3064586 },
    { lat: 49.3361397, lng: 19.3064059 },
    { lat: 49.3360937, lng: 19.3063419 },
    { lat: 49.3359921, lng: 19.3062151 },
    { lat: 49.3359426, lng: 19.3061476 },
    { lat: 49.3358867, lng: 19.3060598 },
    { lat: 49.3358233, lng: 19.305961 },
    { lat: 49.3358067, lng: 19.3059202 },
    { lat: 49.3358005, lng: 19.3059042 },
    { lat: 49.3357493, lng: 19.3057744 },
    { lat: 49.3356916, lng: 19.3056279 },
    { lat: 49.3356124, lng: 19.3054571 },
    { lat: 49.3355108, lng: 19.3052595 },
    { lat: 49.3353435, lng: 19.3049387 },
    { lat: 49.3351507, lng: 19.3045816 },
    { lat: 49.3351134, lng: 19.304387 },
    { lat: 49.3350783, lng: 19.3042641 },
    { lat: 49.3349947, lng: 19.303973 },
    { lat: 49.3350077, lng: 19.3039629 },
    { lat: 49.3351075, lng: 19.3038811 },
    { lat: 49.3349938, lng: 19.3034802 },
    { lat: 49.3349338, lng: 19.3033037 },
    { lat: 49.3348283, lng: 19.3031206 },
    { lat: 49.3348695, lng: 19.3030643 },
    { lat: 49.3345202, lng: 19.3023283 },
    { lat: 49.3345429, lng: 19.3022333 },
    { lat: 49.3345889, lng: 19.3021823 },
    { lat: 49.3346483, lng: 19.3020106 },
    { lat: 49.3346508, lng: 19.30199 },
    { lat: 49.3346661, lng: 19.301863 },
    { lat: 49.334682, lng: 19.3016942 },
    { lat: 49.3347544, lng: 19.3013942 },
    { lat: 49.3347708, lng: 19.3012663 },
    { lat: 49.3348398, lng: 19.3010068 },
    { lat: 49.3348484, lng: 19.3008104 },
    { lat: 49.3349306, lng: 19.3006144 },
    { lat: 49.3349786, lng: 19.3004873 },
    { lat: 49.3350594, lng: 19.3001676 },
    { lat: 49.3350623, lng: 19.2996105 },
    { lat: 49.3350285, lng: 19.2993009 },
    { lat: 49.3350514, lng: 19.2991657 },
    { lat: 49.3350741, lng: 19.2990758 },
    { lat: 49.3351649, lng: 19.2989682 },
    { lat: 49.3352203, lng: 19.2988771 },
    { lat: 49.3353125, lng: 19.298709 },
    { lat: 49.3353642, lng: 19.2986584 },
    { lat: 49.3353812, lng: 19.2985153 },
    { lat: 49.3353881, lng: 19.2982807 },
    { lat: 49.3353673, lng: 19.2981764 },
    { lat: 49.3353852, lng: 19.2980306 },
    { lat: 49.3353983, lng: 19.2978521 },
    { lat: 49.3353874, lng: 19.2975765 },
    { lat: 49.3354076, lng: 19.2974311 },
    { lat: 49.3355127, lng: 19.2972204 },
    { lat: 49.3355193, lng: 19.2970769 },
    { lat: 49.3355394, lng: 19.2970017 },
    { lat: 49.3355558, lng: 19.2969442 },
    { lat: 49.335597, lng: 19.2967872 },
    { lat: 49.3356591, lng: 19.2966961 },
    { lat: 49.3356981, lng: 19.2966372 },
    { lat: 49.33571, lng: 19.2965596 },
    { lat: 49.3357168, lng: 19.2962944 },
    { lat: 49.3357039, lng: 19.2961426 },
    { lat: 49.3357121, lng: 19.2961001 },
    { lat: 49.3357177, lng: 19.2960547 },
    { lat: 49.3357716, lng: 19.2959562 },
    { lat: 49.3358227, lng: 19.295867 },
    { lat: 49.3358387, lng: 19.295802 },
    { lat: 49.3359284, lng: 19.2957401 },
    { lat: 49.3359866, lng: 19.2955934 },
    { lat: 49.3360283, lng: 19.2955219 },
    { lat: 49.3360517, lng: 19.2953484 },
    { lat: 49.3360658, lng: 19.295147 },
    { lat: 49.3360708, lng: 19.2949882 },
    { lat: 49.3360889, lng: 19.2948272 },
    { lat: 49.3361054, lng: 19.2946006 },
    { lat: 49.3361102, lng: 19.2944873 },
    { lat: 49.3361073, lng: 19.2943832 },
    { lat: 49.336124, lng: 19.2942805 },
    { lat: 49.3361521, lng: 19.2941122 },
    { lat: 49.3361799, lng: 19.2940354 },
    { lat: 49.3362436, lng: 19.2939998 },
    { lat: 49.3362464, lng: 19.2940085 },
    { lat: 49.3363044, lng: 19.2939725 },
    { lat: 49.336373, lng: 19.2939699 },
    { lat: 49.336434, lng: 19.2938999 },
    { lat: 49.3364961, lng: 19.2938558 },
    { lat: 49.3365778, lng: 19.293845 },
    { lat: 49.3366073, lng: 19.2938261 },
    { lat: 49.3366372, lng: 19.2937892 },
    { lat: 49.3366334, lng: 19.2936642 },
    { lat: 49.3366855, lng: 19.293597 },
    { lat: 49.3367888, lng: 19.2934945 },
    { lat: 49.3368173, lng: 19.2933822 },
    { lat: 49.3369051, lng: 19.2932818 },
    { lat: 49.3369592, lng: 19.2932122 },
    { lat: 49.3370015, lng: 19.292845 },
    { lat: 49.337009, lng: 19.2926935 },
    { lat: 49.3370222, lng: 19.2925692 },
    { lat: 49.3370208, lng: 19.2924884 },
    { lat: 49.3370831, lng: 19.2920951 },
    { lat: 49.3370842, lng: 19.2920484 },
    { lat: 49.3370889, lng: 19.2919073 },
    { lat: 49.3370787, lng: 19.2916428 },
    { lat: 49.3370768, lng: 19.2915585 },
    { lat: 49.3370567, lng: 19.291392 },
    { lat: 49.3370527, lng: 19.2912637 },
    { lat: 49.3370903, lng: 19.2911996 },
    { lat: 49.3371489, lng: 19.2909101 },
    { lat: 49.3371657, lng: 19.2907923 },
    { lat: 49.3371708, lng: 19.2906609 },
    { lat: 49.3372447, lng: 19.290536 },
    { lat: 49.3372756, lng: 19.290376 },
    { lat: 49.3372833, lng: 19.2903488 },
    { lat: 49.3373266, lng: 19.2902656 },
    { lat: 49.33741, lng: 19.2901219 },
    { lat: 49.3374302, lng: 19.2899556 },
    { lat: 49.337479, lng: 19.2898432 },
    { lat: 49.3375309, lng: 19.2897233 },
    { lat: 49.337701, lng: 19.2894113 },
    { lat: 49.3377553, lng: 19.2892513 },
    { lat: 49.3378068, lng: 19.2890765 },
    { lat: 49.3378873, lng: 19.2888724 },
    { lat: 49.3379517, lng: 19.2886614 },
    { lat: 49.3380516, lng: 19.2884429 },
    { lat: 49.3380728, lng: 19.2883867 },
    { lat: 49.3381481, lng: 19.2881467 },
    { lat: 49.338314, lng: 19.2877947 },
    { lat: 49.3383309, lng: 19.2876982 },
    { lat: 49.3383383, lng: 19.2875947 },
    { lat: 49.3383557, lng: 19.2875093 },
    { lat: 49.338389, lng: 19.2874301 },
    { lat: 49.3384117, lng: 19.2874052 },
    { lat: 49.3384297, lng: 19.2873609 },
    { lat: 49.3384075, lng: 19.2872477 },
    { lat: 49.338453, lng: 19.287161 },
    { lat: 49.3385274, lng: 19.287092 },
    { lat: 49.3385294, lng: 19.2869443 },
    { lat: 49.3385506, lng: 19.286869 },
    { lat: 49.3386889, lng: 19.2866932 },
    { lat: 49.3387586, lng: 19.2865 },
    { lat: 49.3388008, lng: 19.2864372 },
    { lat: 49.338851, lng: 19.2862166 },
    { lat: 49.3388523, lng: 19.286099 },
    { lat: 49.3389411, lng: 19.2859051 },
    { lat: 49.3389484, lng: 19.2857016 },
    { lat: 49.3388933, lng: 19.2856347 },
    { lat: 49.338885, lng: 19.2855999 },
    { lat: 49.3388943, lng: 19.2855818 },
    { lat: 49.3389182, lng: 19.2855615 },
    { lat: 49.3390025, lng: 19.2854433 },
    { lat: 49.3389894, lng: 19.2851868 },
    { lat: 49.3389474, lng: 19.2850367 },
    { lat: 49.3389311, lng: 19.2849815 },
    { lat: 49.3389586, lng: 19.2849195 },
    { lat: 49.3389547, lng: 19.2848827 },
    { lat: 49.3388584, lng: 19.2845147 },
    { lat: 49.3388421, lng: 19.284386 },
    { lat: 49.3388387, lng: 19.2843643 },
    { lat: 49.338848, lng: 19.2842003 },
    { lat: 49.3388372, lng: 19.284143 },
    { lat: 49.3388909, lng: 19.2839202 },
    { lat: 49.3389116, lng: 19.2837603 },
    { lat: 49.3389822, lng: 19.2836603 },
    { lat: 49.3390165, lng: 19.283526 },
    { lat: 49.3390884, lng: 19.2834985 },
    { lat: 49.3391569, lng: 19.2832998 },
    { lat: 49.3391375, lng: 19.2831805 },
    { lat: 49.3391466, lng: 19.2831336 },
    { lat: 49.3391548, lng: 19.2830922 },
    { lat: 49.3392671, lng: 19.2830349 },
    { lat: 49.3393066, lng: 19.2829918 },
    { lat: 49.3392983, lng: 19.2829104 },
    { lat: 49.3393118, lng: 19.2828875 },
    { lat: 49.3393291, lng: 19.2828693 },
    { lat: 49.339344, lng: 19.282829 },
    { lat: 49.3392747, lng: 19.2825089 },
    { lat: 49.3392721, lng: 19.2824536 },
    { lat: 49.3392846, lng: 19.2824345 },
    { lat: 49.3393904, lng: 19.2824028 },
    { lat: 49.3395562, lng: 19.2823598 },
    { lat: 49.3396849, lng: 19.2823885 },
    { lat: 49.3397089, lng: 19.2823476 },
    { lat: 49.3397584, lng: 19.2822055 },
    { lat: 49.3398253, lng: 19.2822118 },
    { lat: 49.3400456, lng: 19.282321 },
    { lat: 49.3400889, lng: 19.2823025 },
    { lat: 49.3401619, lng: 19.2822293 },
    { lat: 49.340209, lng: 19.2822707 },
    { lat: 49.3402932, lng: 19.2824077 },
    { lat: 49.340313, lng: 19.2824235 },
    { lat: 49.3404076, lng: 19.2824662 },
    { lat: 49.3405611, lng: 19.2823757 },
    { lat: 49.3406356, lng: 19.282284 },
    { lat: 49.3406871, lng: 19.2823744 },
    { lat: 49.3407316, lng: 19.2823608 },
    { lat: 49.3408079, lng: 19.282447 },
    { lat: 49.3408948, lng: 19.2824015 },
    { lat: 49.3409476, lng: 19.282374 },
    { lat: 49.3410307, lng: 19.2823438 },
    { lat: 49.3411082, lng: 19.2823406 },
    { lat: 49.341212, lng: 19.2824118 },
    { lat: 49.3412994, lng: 19.2824289 },
    { lat: 49.3414226, lng: 19.282555 },
    { lat: 49.3414248, lng: 19.2826213 },
    { lat: 49.3413794, lng: 19.2826412 },
    { lat: 49.3413683, lng: 19.282646 },
    { lat: 49.341358, lng: 19.282667 },
    { lat: 49.3413683, lng: 19.2826912 },
    { lat: 49.3414516, lng: 19.2827383 },
    { lat: 49.3415432, lng: 19.2828181 },
    { lat: 49.3415616, lng: 19.2828233 },
    { lat: 49.3416655, lng: 19.2827326 },
    { lat: 49.3417099, lng: 19.2827179 },
    { lat: 49.3417828, lng: 19.2828316 },
    { lat: 49.341799, lng: 19.2828638 },
    { lat: 49.3418698, lng: 19.2829336 },
    { lat: 49.3419711, lng: 19.282978 },
    { lat: 49.3419997, lng: 19.2829835 },
    { lat: 49.342029, lng: 19.2828436 },
    { lat: 49.3420568, lng: 19.2827632 },
    { lat: 49.3421076, lng: 19.2826679 },
    { lat: 49.3421262, lng: 19.2826084 },
    { lat: 49.3421156, lng: 19.2825532 },
    { lat: 49.3420373, lng: 19.2824719 },
    { lat: 49.3419102, lng: 19.2823809 },
    { lat: 49.3417302, lng: 19.2822896 },
    { lat: 49.3416073, lng: 19.2822678 },
    { lat: 49.3415835, lng: 19.2822386 },
    { lat: 49.3415594, lng: 19.2821842 },
    { lat: 49.3415624, lng: 19.2820588 },
    { lat: 49.3415966, lng: 19.2819715 },
    { lat: 49.3416919, lng: 19.2818406 },
    { lat: 49.3417084, lng: 19.2817614 },
    { lat: 49.3416608, lng: 19.2816381 },
    { lat: 49.3416377, lng: 19.2815987 },
    { lat: 49.3415805, lng: 19.2815826 },
    { lat: 49.3412338, lng: 19.2816186 },
    { lat: 49.3411952, lng: 19.2816103 },
    { lat: 49.3411288, lng: 19.2815656 },
    { lat: 49.3408478, lng: 19.2813919 },
    { lat: 49.3408116, lng: 19.2813652 },
    { lat: 49.340733, lng: 19.2812948 },
    { lat: 49.3407119, lng: 19.2812795 },
    { lat: 49.3406868, lng: 19.2812483 },
    { lat: 49.340678, lng: 19.2812077 },
    { lat: 49.3406818, lng: 19.2811682 },
    { lat: 49.3407038, lng: 19.2811336 },
    { lat: 49.3407744, lng: 19.2810788 },
    { lat: 49.3408084, lng: 19.2809897 },
    { lat: 49.3408275, lng: 19.2809124 },
    { lat: 49.3408338, lng: 19.2808319 },
    { lat: 49.3408249, lng: 19.2807888 },
    { lat: 49.3408043, lng: 19.2807329 },
    { lat: 49.340754, lng: 19.2806734 },
    { lat: 49.3406326, lng: 19.2805579 },
    { lat: 49.3406141, lng: 19.2805274 },
    { lat: 49.3405957, lng: 19.2804485 },
    { lat: 49.3405647, lng: 19.2804107 },
    { lat: 49.3405054, lng: 19.2803711 },
    { lat: 49.3402708, lng: 19.2803198 },
    { lat: 49.3402476, lng: 19.2803076 },
    { lat: 49.3401765, lng: 19.2802334 },
    { lat: 49.340124, lng: 19.2801475 },
    { lat: 49.340076, lng: 19.2800162 },
    { lat: 49.3400631, lng: 19.2799331 },
    { lat: 49.3400642, lng: 19.2798863 },
    { lat: 49.3400892, lng: 19.2798409 },
    { lat: 49.3401679, lng: 19.2798123 },
    { lat: 49.3401913, lng: 19.2797381 },
    { lat: 49.340168, lng: 19.2795824 },
    { lat: 49.3401486, lng: 19.2795517 },
    { lat: 49.3401231, lng: 19.2795108 },
    { lat: 49.3400999, lng: 19.2795 },
    { lat: 49.3399979, lng: 19.2794864 },
    { lat: 49.3399632, lng: 19.2794404 },
    { lat: 49.3397929, lng: 19.2791568 },
    { lat: 49.3397689, lng: 19.2791241 },
    { lat: 49.3396981, lng: 19.2790604 },
    { lat: 49.3396594, lng: 19.279026 },
    { lat: 49.3396386, lng: 19.2789908 },
    { lat: 49.3396284, lng: 19.2789456 },
    { lat: 49.3395899, lng: 19.2787949 },
    { lat: 49.3395486, lng: 19.2786878 },
    { lat: 49.3394444, lng: 19.2784616 },
    { lat: 49.3393862, lng: 19.2783741 },
    { lat: 49.3392941, lng: 19.2782864 },
    { lat: 49.3392746, lng: 19.278277 },
    { lat: 49.3392065, lng: 19.2782712 },
    { lat: 49.3391831, lng: 19.2782752 },
    { lat: 49.3390885, lng: 19.2783013 },
    { lat: 49.339022, lng: 19.2782997 },
    { lat: 49.3389325, lng: 19.2782901 },
    { lat: 49.338856, lng: 19.2782704 },
    { lat: 49.3387899, lng: 19.2782745 },
    { lat: 49.3387485, lng: 19.2782592 },
    { lat: 49.3387238, lng: 19.2782356 },
    { lat: 49.3387064, lng: 19.2782032 },
    { lat: 49.3386788, lng: 19.2781246 },
    { lat: 49.3386394, lng: 19.2780498 },
    { lat: 49.338617, lng: 19.278031 },
    { lat: 49.3385955, lng: 19.2780302 },
    { lat: 49.3385695, lng: 19.2780497 },
    { lat: 49.3385502, lng: 19.2780717 },
    { lat: 49.3385383, lng: 19.2781041 },
    { lat: 49.3385145, lng: 19.2781996 },
    { lat: 49.3384752, lng: 19.2782477 },
    { lat: 49.338439, lng: 19.2782911 },
    { lat: 49.3384208, lng: 19.2783849 },
    { lat: 49.338381, lng: 19.2784461 },
    { lat: 49.338329, lng: 19.2784653 },
    { lat: 49.338015, lng: 19.2784525 },
    { lat: 49.3379979, lng: 19.2784699 },
    { lat: 49.3379826, lng: 19.2785056 },
    { lat: 49.337973, lng: 19.2785674 },
    { lat: 49.3379556, lng: 19.2786239 },
    { lat: 49.3379416, lng: 19.2786616 },
    { lat: 49.3378983, lng: 19.2786993 },
    { lat: 49.3378092, lng: 19.2786994 },
    { lat: 49.3377801, lng: 19.2786781 },
    { lat: 49.3377395, lng: 19.2786335 },
    { lat: 49.337679, lng: 19.2785675 },
    { lat: 49.3376189, lng: 19.2785608 },
    { lat: 49.3375705, lng: 19.2785594 },
    { lat: 49.3375591, lng: 19.2785343 },
    { lat: 49.3375174, lng: 19.2784171 },
    { lat: 49.3374655, lng: 19.2784124 },
    { lat: 49.3372864, lng: 19.2784851 },
    { lat: 49.337154, lng: 19.2785016 },
    { lat: 49.3371325, lng: 19.2784718 },
    { lat: 49.3370965, lng: 19.2782865 },
    { lat: 49.3370827, lng: 19.2782782 },
    { lat: 49.3370644, lng: 19.2782767 },
    { lat: 49.3370393, lng: 19.2782907 },
    { lat: 49.336988, lng: 19.2783972 },
    { lat: 49.3369127, lng: 19.2785935 },
    { lat: 49.3368739, lng: 19.2786314 },
    { lat: 49.3368482, lng: 19.278631 },
    { lat: 49.3367719, lng: 19.2785463 },
    { lat: 49.3367524, lng: 19.2785383 },
    { lat: 49.336717, lng: 19.2785986 },
    { lat: 49.3365674, lng: 19.278651 },
    { lat: 49.3363076, lng: 19.2787092 },
    { lat: 49.3360303, lng: 19.278804 },
    { lat: 49.3360018, lng: 19.2787992 },
    { lat: 49.3358395, lng: 19.2786539 },
    { lat: 49.3356954, lng: 19.278684 },
    { lat: 49.3354822, lng: 19.2786553 },
    { lat: 49.3353433, lng: 19.2786733 },
    { lat: 49.3352969, lng: 19.2786203 },
    { lat: 49.3351937, lng: 19.2784424 },
    { lat: 49.3350516, lng: 19.2783689 },
    { lat: 49.3348875, lng: 19.2783995 },
    { lat: 49.334853, lng: 19.2784575 },
    { lat: 49.3347755, lng: 19.2784383 },
    { lat: 49.3345607, lng: 19.2783299 },
    { lat: 49.3344757, lng: 19.2783658 },
    { lat: 49.3344026, lng: 19.2784877 },
    { lat: 49.3342947, lng: 19.2785929 },
    { lat: 49.3342648, lng: 19.2785988 },
    { lat: 49.3342458, lng: 19.2785807 },
    { lat: 49.3342186, lng: 19.2785309 },
    { lat: 49.3341759, lng: 19.278462 },
    { lat: 49.3341302, lng: 19.2784956 },
    { lat: 49.3340804, lng: 19.2785402 },
    { lat: 49.3340599, lng: 19.2785367 },
    { lat: 49.3340306, lng: 19.2784604 },
    { lat: 49.3339959, lng: 19.2784654 },
    { lat: 49.333974, lng: 19.2785531 },
    { lat: 49.333935, lng: 19.2785853 },
    { lat: 49.3336265, lng: 19.2785903 },
    { lat: 49.3334532, lng: 19.278551 },
    { lat: 49.3333058, lng: 19.2784839 },
    { lat: 49.3332766, lng: 19.2784872 },
    { lat: 49.3330364, lng: 19.2786505 },
    { lat: 49.332958, lng: 19.2785855 },
    { lat: 49.3327867, lng: 19.2785879 },
    { lat: 49.3325325, lng: 19.2784767 },
    { lat: 49.3324048, lng: 19.2783543 },
    { lat: 49.3323806, lng: 19.2783418 },
    { lat: 49.332291, lng: 19.2783796 },
    { lat: 49.3320879, lng: 19.2783248 },
    { lat: 49.3320257, lng: 19.2782729 },
    { lat: 49.3318243, lng: 19.2783039 },
    { lat: 49.3318078, lng: 19.2782881 },
    { lat: 49.3317859, lng: 19.2781355 },
    { lat: 49.3316283, lng: 19.2779944 },
    { lat: 49.3314056, lng: 19.2779591 },
    { lat: 49.3312437, lng: 19.2778225 },
    { lat: 49.3310179, lng: 19.2776734 },
    { lat: 49.330735, lng: 19.2774595 },
    { lat: 49.3305366, lng: 19.2774578 },
    { lat: 49.3303685, lng: 19.2774038 },
    { lat: 49.3300905, lng: 19.2772685 },
    { lat: 49.3297202, lng: 19.27721 },
    { lat: 49.329549, lng: 19.2771152 },
    { lat: 49.329114, lng: 19.2771903 },
    { lat: 49.3289932, lng: 19.277276 },
    { lat: 49.3287007, lng: 19.2773819 },
    { lat: 49.3285843, lng: 19.2773789 },
    { lat: 49.3285248, lng: 19.277383 },
    { lat: 49.3284643, lng: 19.2774129 },
    { lat: 49.3284365, lng: 19.2773983 },
    { lat: 49.3282354, lng: 19.2772421 },
    { lat: 49.3279584, lng: 19.2768683 },
    { lat: 49.3277608, lng: 19.2766724 },
    { lat: 49.3275922, lng: 19.2765628 },
    { lat: 49.3275397, lng: 19.2765452 },
    { lat: 49.3273995, lng: 19.2765138 },
    { lat: 49.3272277, lng: 19.2764115 },
    { lat: 49.3270779, lng: 19.2761962 },
    { lat: 49.32703, lng: 19.2760166 },
    { lat: 49.3269174, lng: 19.2757859 },
    { lat: 49.3268371, lng: 19.2756847 },
    { lat: 49.3268103, lng: 19.2756425 },
    { lat: 49.326695, lng: 19.2754183 },
    { lat: 49.3266666, lng: 19.2753929 },
    { lat: 49.3266341, lng: 19.2753745 },
    { lat: 49.3265663, lng: 19.2753441 },
    { lat: 49.3265266, lng: 19.2753131 },
    { lat: 49.3262839, lng: 19.275072 },
    { lat: 49.3261029, lng: 19.2749353 },
    { lat: 49.326007, lng: 19.2746006 },
    { lat: 49.3260452, lng: 19.274411 },
    { lat: 49.3259287, lng: 19.2741425 },
    { lat: 49.3258392, lng: 19.2738931 },
    { lat: 49.3255132, lng: 19.2735525 },
    { lat: 49.3253451, lng: 19.2735051 },
    { lat: 49.3252354, lng: 19.2732579 },
    { lat: 49.3251284, lng: 19.27307 },
    { lat: 49.3248489, lng: 19.2728825 },
    { lat: 49.3247926, lng: 19.2728805 },
    { lat: 49.3245755, lng: 19.273009 },
    { lat: 49.3244469, lng: 19.2730053 },
    { lat: 49.3244167, lng: 19.2730112 },
    { lat: 49.3243728, lng: 19.2730612 },
    { lat: 49.3243301, lng: 19.2731563 },
    { lat: 49.3242272, lng: 19.2731735 },
    { lat: 49.3241934, lng: 19.2732672 },
    { lat: 49.3241817, lng: 19.273283 },
    { lat: 49.3241709, lng: 19.2732954 },
    { lat: 49.3240774, lng: 19.2732946 },
    { lat: 49.3240093, lng: 19.2732577 },
    { lat: 49.3239613, lng: 19.2731728 },
    { lat: 49.3238902, lng: 19.2731225 },
    { lat: 49.3238033, lng: 19.2730733 },
    { lat: 49.3236396, lng: 19.2730474 },
    { lat: 49.3235888, lng: 19.273091 },
    { lat: 49.3234877, lng: 19.2731203 },
    { lat: 49.3231983, lng: 19.2730543 },
    { lat: 49.323102, lng: 19.2729949 },
    { lat: 49.3228276, lng: 19.2729853 },
    { lat: 49.322579, lng: 19.2729234 },
    { lat: 49.3223649, lng: 19.2727898 },
    { lat: 49.3223508, lng: 19.272771 },
    { lat: 49.3219865, lng: 19.2725049 },
    { lat: 49.3216262, lng: 19.2723683 },
    { lat: 49.3211994, lng: 19.2721835 },
    { lat: 49.3210708, lng: 19.272179 },
    { lat: 49.3209585, lng: 19.2721417 },
    { lat: 49.3208429, lng: 19.2721556 },
    { lat: 49.3204252, lng: 19.2719485 },
    { lat: 49.3202847, lng: 19.2719081 },
    { lat: 49.3201126, lng: 19.2717958 },
    { lat: 49.3199987, lng: 19.2717485 },
    { lat: 49.3198569, lng: 19.271726 },
    { lat: 49.3196131, lng: 19.2716744 },
    { lat: 49.319362, lng: 19.2715592 },
    { lat: 49.3191388, lng: 19.2715342 },
    { lat: 49.3190856, lng: 19.2715279 },
    { lat: 49.3186131, lng: 19.2714746 },
    { lat: 49.3190018, lng: 19.2679207 },
    { lat: 49.3186781, lng: 19.267421 },
    { lat: 49.3182045, lng: 19.266441 },
    { lat: 49.3172889, lng: 19.2643975 },
    { lat: 49.317194, lng: 19.264035 },
    { lat: 49.3171717, lng: 19.263518 },
    { lat: 49.3172528, lng: 19.2629747 },
    { lat: 49.3173697, lng: 19.2618598 },
    { lat: 49.3175005, lng: 19.2608876 },
    { lat: 49.3178655, lng: 19.2593008 },
    { lat: 49.3181345, lng: 19.2585381 },
    { lat: 49.3184358, lng: 19.2578828 },
    { lat: 49.3185763, lng: 19.257546 },
    { lat: 49.3187371, lng: 19.2571592 },
    { lat: 49.3191001, lng: 19.2559815 },
    { lat: 49.3190778, lng: 19.2552997 },
    { lat: 49.3188729, lng: 19.254362 },
    { lat: 49.3186102, lng: 19.253153 },
    { lat: 49.3183192, lng: 19.2519923 },
    { lat: 49.3180888, lng: 19.251061 },
    { lat: 49.3176801, lng: 19.2486397 },
    { lat: 49.3175668, lng: 19.2480126 },
    { lat: 49.3172662, lng: 19.2463162 },
    { lat: 49.3171061, lng: 19.2451171 },
    { lat: 49.3169848, lng: 19.2444862 },
    { lat: 49.3169246, lng: 19.2441949 },
    { lat: 49.3168582, lng: 19.2438436 },
    { lat: 49.3166934, lng: 19.2429643 },
    { lat: 49.316762, lng: 19.241458 },
    { lat: 49.316184, lng: 19.241594 },
    { lat: 49.315461, lng: 19.241567 },
    { lat: 49.313532, lng: 19.240705 },
    { lat: 49.31246, lng: 19.239755 },
    { lat: 49.312073, lng: 19.239545 },
    { lat: 49.31172, lng: 19.239518 },
    { lat: 49.310782, lng: 19.240698 },
    { lat: 49.309945, lng: 19.241303 },
    { lat: 49.309912, lng: 19.241328 },
    { lat: 49.309427, lng: 19.240597 },
    { lat: 49.309012, lng: 19.240371 },
    { lat: 49.308418, lng: 19.240172 },
    { lat: 49.307334, lng: 19.24051 },
    { lat: 49.306543, lng: 19.240709 },
    { lat: 49.30573, lng: 19.240135 },
    { lat: 49.305179, lng: 19.239967 },
    { lat: 49.304901, lng: 19.239915 },
    { lat: 49.304428, lng: 19.239999 },
    { lat: 49.30384, lng: 19.239998 },
    { lat: 49.303348, lng: 19.240083 },
    { lat: 49.302325, lng: 19.240631 },
    { lat: 49.301724, lng: 19.240722 },
    { lat: 49.301376, lng: 19.240424 },
    { lat: 49.301309, lng: 19.240386 },
    { lat: 49.300576, lng: 19.239959 },
    { lat: 49.300122, lng: 19.239551 },
    { lat: 49.29989, lng: 19.239469 },
    { lat: 49.299304, lng: 19.239664 },
    { lat: 49.298974, lng: 19.23954 },
    { lat: 49.298726, lng: 19.239672 },
    { lat: 49.298431, lng: 19.240077 },
    { lat: 49.297665, lng: 19.242503 },
    { lat: 49.296413, lng: 19.243429 },
    { lat: 49.29597, lng: 19.243514 },
    { lat: 49.295454, lng: 19.2439437 },
    { lat: 49.295108, lng: 19.244073 },
    { lat: 49.294834, lng: 19.244193 },
    { lat: 49.294335, lng: 19.244475 },
    { lat: 49.291436, lng: 19.246857 },
    { lat: 49.290493, lng: 19.247593 },
    { lat: 49.290148, lng: 19.247772 },
    { lat: 49.289803, lng: 19.247886 },
    { lat: 49.289568, lng: 19.247855 },
    { lat: 49.28893, lng: 19.24752 },
    { lat: 49.288023, lng: 19.246756 },
    { lat: 49.28736, lng: 19.246316 },
    { lat: 49.286083, lng: 19.245933 },
    { lat: 49.284928, lng: 19.245677 },
    { lat: 49.282749, lng: 19.245864 },
    { lat: 49.28093, lng: 19.245713 },
    { lat: 49.2793777, lng: 19.2457541 },
    { lat: 49.279551, lng: 19.246148 },
    { lat: 49.280048, lng: 19.247376 },
    { lat: 49.280347, lng: 19.247987 },
    { lat: 49.280516, lng: 19.248571 },
    { lat: 49.280755, lng: 19.249062 },
    { lat: 49.280947, lng: 19.249739 },
    { lat: 49.281563, lng: 19.252155 },
    { lat: 49.281779, lng: 19.252826 },
    { lat: 49.282164, lng: 19.25373 },
    { lat: 49.282333, lng: 19.254221 },
    { lat: 49.282341, lng: 19.255602 },
    { lat: 49.282566, lng: 19.256396 },
    { lat: 49.283211, lng: 19.256333 },
    { lat: 49.283372, lng: 19.256388 },
    { lat: 49.283538, lng: 19.256542 },
    { lat: 49.283666, lng: 19.256733 },
    { lat: 49.283841, lng: 19.256792 },
    { lat: 49.284332, lng: 19.256554 },
    { lat: 49.284571, lng: 19.256224 },
    { lat: 49.285166, lng: 19.255633 },
    { lat: 49.285359, lng: 19.2555 },
    { lat: 49.285623, lng: 19.25549 },
    { lat: 49.285935, lng: 19.255604 },
    { lat: 49.286982, lng: 19.255759 },
    { lat: 49.287187, lng: 19.255887 },
    { lat: 49.287431, lng: 19.256004 },
    { lat: 49.287612, lng: 19.256283 },
    { lat: 49.287676, lng: 19.25653 },
    { lat: 49.287843, lng: 19.256718 },
    { lat: 49.288099, lng: 19.256857 },
    { lat: 49.288405, lng: 19.257182 },
    { lat: 49.288685, lng: 19.257396 },
    { lat: 49.288934, lng: 19.257823 },
    { lat: 49.288979, lng: 19.258013 },
    { lat: 49.28925, lng: 19.258381 },
    { lat: 49.289514, lng: 19.25889 },
    { lat: 49.289583, lng: 19.259262 },
    { lat: 49.289664, lng: 19.259635 },
    { lat: 49.289772, lng: 19.259846 },
    { lat: 49.289996, lng: 19.260183 },
    { lat: 49.290362, lng: 19.260622 },
    { lat: 49.290899, lng: 19.261 },
    { lat: 49.291171, lng: 19.261245 },
    { lat: 49.291487, lng: 19.261373 },
    { lat: 49.291678, lng: 19.26157 },
    { lat: 49.292169, lng: 19.262365 },
    { lat: 49.292411, lng: 19.262523 },
    { lat: 49.292558, lng: 19.262678 },
    { lat: 49.292697, lng: 19.262747 },
    { lat: 49.292964, lng: 19.263132 },
    { lat: 49.293135, lng: 19.263227 },
    { lat: 49.293238, lng: 19.263416 },
    { lat: 49.293394, lng: 19.263782 },
    { lat: 49.293459, lng: 19.264011 },
    { lat: 49.293642, lng: 19.264342 },
    { lat: 49.293926, lng: 19.264422 },
    { lat: 49.294047, lng: 19.264589 },
    { lat: 49.294133, lng: 19.26475 },
    { lat: 49.294239, lng: 19.264898 },
    { lat: 49.294382, lng: 19.26498 },
    { lat: 49.294698, lng: 19.265171 },
    { lat: 49.294931, lng: 19.265513 },
    { lat: 49.294985, lng: 19.265673 },
    { lat: 49.295058, lng: 19.265759 },
    { lat: 49.295204, lng: 19.265778 },
    { lat: 49.295299, lng: 19.266002 },
    { lat: 49.295398, lng: 19.266303 },
    { lat: 49.295666, lng: 19.266725 },
    { lat: 49.295977, lng: 19.266933 },
    { lat: 49.29615, lng: 19.267436 },
    { lat: 49.296249, lng: 19.267573 },
    { lat: 49.296493, lng: 19.267588 },
    { lat: 49.296801, lng: 19.267959 },
    { lat: 49.297274, lng: 19.268406 },
    { lat: 49.297709, lng: 19.268358 },
    { lat: 49.297858, lng: 19.268422 },
    { lat: 49.298173, lng: 19.268202 },
    { lat: 49.298312, lng: 19.268123 },
    { lat: 49.298541, lng: 19.268264 },
    { lat: 49.298816, lng: 19.268576 },
    { lat: 49.299293, lng: 19.268843 },
    { lat: 49.299631, lng: 19.269292 },
    { lat: 49.299888, lng: 19.269458 },
    { lat: 49.300117, lng: 19.26969 },
    { lat: 49.300325, lng: 19.270125 },
    { lat: 49.300489, lng: 19.270758 },
    { lat: 49.300455, lng: 19.271777 },
    { lat: 49.300513, lng: 19.272222 },
    { lat: 49.300479, lng: 19.273116 },
    { lat: 49.300552, lng: 19.273563 },
    { lat: 49.300526, lng: 19.27415 },
    { lat: 49.300633, lng: 19.274647 },
    { lat: 49.30067, lng: 19.275954 },
    { lat: 49.300813, lng: 19.276534 },
    { lat: 49.300926, lng: 19.276798 },
    { lat: 49.301094, lng: 19.277109 },
    { lat: 49.301164, lng: 19.277452 },
    { lat: 49.30158, lng: 19.278139 },
    { lat: 49.3017117, lng: 19.2783991 },
    { lat: 49.301944, lng: 19.278858 },
    { lat: 49.302244, lng: 19.279531 },
    { lat: 49.302257, lng: 19.280078 },
    { lat: 49.302493, lng: 19.280297 },
    { lat: 49.302669, lng: 19.28075 },
    { lat: 49.303082, lng: 19.281356 },
    { lat: 49.30358, lng: 19.281564 },
    { lat: 49.303817, lng: 19.281834 },
    { lat: 49.3041, lng: 19.282598 },
    { lat: 49.304163, lng: 19.283205 },
    { lat: 49.304364, lng: 19.283636 },
    { lat: 49.304441, lng: 19.284298 },
    { lat: 49.304728, lng: 19.284746 },
    { lat: 49.304983, lng: 19.28532 },
    { lat: 49.304972, lng: 19.28605 },
    { lat: 49.305112, lng: 19.287014 },
    { lat: 49.305166, lng: 19.287766 },
    { lat: 49.305182, lng: 19.289278 },
    { lat: 49.305433, lng: 19.289948 },
    { lat: 49.305445, lng: 19.290391 },
    { lat: 49.305225, lng: 19.291165 },
    { lat: 49.305071, lng: 19.291604 },
    { lat: 49.305316, lng: 19.292242 },
    { lat: 49.305317, lng: 19.292249 },
    { lat: 49.305319, lng: 19.292249 },
    { lat: 49.305445, lng: 19.292578 },
    { lat: 49.305624, lng: 19.29303 },
    { lat: 49.305753, lng: 19.293239 },
    { lat: 49.305765, lng: 19.29376 },
    { lat: 49.305808, lng: 19.294521 },
    { lat: 49.305898, lng: 19.295022 },
    { lat: 49.306025, lng: 19.295186 },
    { lat: 49.306056, lng: 19.295188 },
    { lat: 49.306121, lng: 19.295173 },
    { lat: 49.306228, lng: 19.295368 },
    { lat: 49.306265, lng: 19.29586 },
    { lat: 49.306167, lng: 19.296579 },
    { lat: 49.306204, lng: 19.297012 },
    { lat: 49.306228, lng: 19.297047 },
    { lat: 49.306494, lng: 19.297433 },
    { lat: 49.306542, lng: 19.297503 },
    { lat: 49.306688, lng: 19.297711 },
    { lat: 49.306822, lng: 19.297905 },
    { lat: 49.306967, lng: 19.298392 },
    { lat: 49.306984, lng: 19.298572 },
    { lat: 49.307018, lng: 19.29891 },
    { lat: 49.307181, lng: 19.29908 },
    { lat: 49.30726, lng: 19.299164 },
    { lat: 49.307376, lng: 19.299287 },
    { lat: 49.307387, lng: 19.299298 },
    { lat: 49.307388, lng: 19.299387 },
    { lat: 49.307388, lng: 19.299412 },
    { lat: 49.307387, lng: 19.299471 },
    { lat: 49.307387, lng: 19.299803 },
    { lat: 49.307413, lng: 19.299906 },
    { lat: 49.307502, lng: 19.300262 },
    { lat: 49.30755, lng: 19.300452 },
    { lat: 49.30761, lng: 19.300635 },
    { lat: 49.307694, lng: 19.300891 },
    { lat: 49.307723, lng: 19.300986 },
    { lat: 49.307763, lng: 19.301118 },
    { lat: 49.307717, lng: 19.301264 },
    { lat: 49.307705, lng: 19.301299 },
    { lat: 49.307683, lng: 19.301299 },
    { lat: 49.307652, lng: 19.301397 },
    { lat: 49.307642, lng: 19.301501 },
    { lat: 49.307672, lng: 19.301776 },
    { lat: 49.307678, lng: 19.301785 },
    { lat: 49.30779, lng: 19.30194 },
    { lat: 49.308119, lng: 19.302175 },
    { lat: 49.308171, lng: 19.302211 },
    { lat: 49.308317, lng: 19.302498 },
    { lat: 49.308492, lng: 19.303036 },
    { lat: 49.308505, lng: 19.30309 },
    { lat: 49.308509, lng: 19.303106 },
    { lat: 49.308561, lng: 19.303331 },
    { lat: 49.308586, lng: 19.303437 },
    { lat: 49.308572, lng: 19.303599 },
    { lat: 49.308474, lng: 19.303633 },
    { lat: 49.308377, lng: 19.303673 },
    { lat: 49.308341, lng: 19.303688 },
    { lat: 49.308263, lng: 19.303643 },
    { lat: 49.308109, lng: 19.303555 },
    { lat: 49.308039, lng: 19.303514 },
    { lat: 49.308005, lng: 19.303494 },
    { lat: 49.307746, lng: 19.303336 },
    { lat: 49.307704, lng: 19.30331 },
    { lat: 49.307656, lng: 19.30333 },
    { lat: 49.307365, lng: 19.303444 },
    { lat: 49.307252, lng: 19.303541 },
    { lat: 49.307225, lng: 19.303563 },
    { lat: 49.30718, lng: 19.303601 },
    { lat: 49.307, lng: 19.303754 },
    { lat: 49.306988, lng: 19.303755 },
    { lat: 49.30696, lng: 19.30376 },
    { lat: 49.306911, lng: 19.303767 },
    { lat: 49.306871, lng: 19.303773 },
    { lat: 49.30665, lng: 19.303807 },
    { lat: 49.306359, lng: 19.303795 },
    { lat: 49.30609, lng: 19.303726 },
    { lat: 49.305954, lng: 19.303691 },
    { lat: 49.30556, lng: 19.303725 },
    { lat: 49.305499, lng: 19.303728 },
    { lat: 49.305301, lng: 19.303737 },
    { lat: 49.305022, lng: 19.303678 },
    { lat: 49.304861, lng: 19.303781 },
    { lat: 49.304662, lng: 19.303909 },
    { lat: 49.304294, lng: 19.303982 },
    { lat: 49.303671, lng: 19.304218 },
    { lat: 49.303639, lng: 19.30423 },
    { lat: 49.303637, lng: 19.304231 },
    { lat: 49.303323, lng: 19.304183 },
    { lat: 49.303627, lng: 19.304606 },
    { lat: 49.303783, lng: 19.304823 },
    { lat: 49.304001, lng: 19.305472 },
    { lat: 49.304283, lng: 19.306488 },
    { lat: 49.304391, lng: 19.307118 },
    { lat: 49.304552, lng: 19.307879 },
    { lat: 49.30436, lng: 19.308148 },
    { lat: 49.30407, lng: 19.308303 },
    { lat: 49.30406, lng: 19.30835 },
    { lat: 49.303725, lng: 19.309962 },
    { lat: 49.303632, lng: 19.31009 },
    { lat: 49.303658, lng: 19.310336 },
    { lat: 49.303988, lng: 19.313381 },
    { lat: 49.304029, lng: 19.314213 },
    { lat: 49.304151, lng: 19.315993 },
    { lat: 49.304155, lng: 19.316061 },
    { lat: 49.303829, lng: 19.317089 },
    { lat: 49.303765, lng: 19.317896 },
    { lat: 49.303912, lng: 19.31914 },
    { lat: 49.304055, lng: 19.320555 },
    { lat: 49.304163, lng: 19.32151 },
    { lat: 49.30429, lng: 19.321838 },
    { lat: 49.304392, lng: 19.322363 },
    { lat: 49.304477, lng: 19.322653 },
    { lat: 49.304674, lng: 19.323261 },
    { lat: 49.304978, lng: 19.323937 },
    { lat: 49.305223, lng: 19.324602 },
    { lat: 49.305439, lng: 19.325659 },
    { lat: 49.305469, lng: 19.325774 },
    { lat: 49.305658, lng: 19.326508 },
    { lat: 49.305713, lng: 19.327452 },
    { lat: 49.305909, lng: 19.328294 },
    { lat: 49.305919, lng: 19.328336 },
    { lat: 49.306042, lng: 19.328662 },
    { lat: 49.30605, lng: 19.328685 },
    { lat: 49.306056, lng: 19.328698 },
    { lat: 49.306199, lng: 19.329083 },
    { lat: 49.306475, lng: 19.329701 },
    { lat: 49.306638, lng: 19.330182 },
    { lat: 49.30674, lng: 19.33077 },
    { lat: 49.306767, lng: 19.330925 },
    { lat: 49.306776, lng: 19.331118 },
    { lat: 49.306815, lng: 19.33187 },
    { lat: 49.306826, lng: 19.33211 },
    { lat: 49.306846, lng: 19.332572 },
    { lat: 49.306868, lng: 19.333122 },
    { lat: 49.306919, lng: 19.333262 },
    { lat: 49.307103, lng: 19.333768 },
    { lat: 49.307116, lng: 19.334011 },
    { lat: 49.307148, lng: 19.3347 },
    { lat: 49.307217, lng: 19.335519 },
    { lat: 49.307234, lng: 19.336012 },
    { lat: 49.307433, lng: 19.336996 },
    { lat: 49.307659, lng: 19.337385 },
    { lat: 49.307792, lng: 19.338018 },
    { lat: 49.307752, lng: 19.338409 },
    { lat: 49.307793, lng: 19.339187 },
    { lat: 49.307794, lng: 19.339211 },
    { lat: 49.307767, lng: 19.339619 },
    { lat: 49.307603, lng: 19.339854 },
    { lat: 49.307526, lng: 19.339964 },
    { lat: 49.307054, lng: 19.341399 },
    { lat: 49.307039, lng: 19.341567 },
    { lat: 49.307037, lng: 19.341601 },
    { lat: 49.307028, lng: 19.341707 },
    { lat: 49.306963, lng: 19.342459 },
    { lat: 49.306921, lng: 19.34295 },
    { lat: 49.306861, lng: 19.343807 },
    { lat: 49.306844, lng: 19.344056 },
    { lat: 49.306447, lng: 19.345252 },
    { lat: 49.306352, lng: 19.34629 },
    { lat: 49.30602, lng: 19.347216 },
    { lat: 49.305549, lng: 19.347544 },
    { lat: 49.305457, lng: 19.348091 },
    { lat: 49.305093, lng: 19.349372 },
    { lat: 49.305036, lng: 19.349685 },
    { lat: 49.304995, lng: 19.349901 },
    { lat: 49.304917, lng: 19.350325 },
    { lat: 49.304838, lng: 19.350755 },
    { lat: 49.304766, lng: 19.351145 },
    { lat: 49.304762, lng: 19.351169 },
    { lat: 49.304106, lng: 19.352354 },
    { lat: 49.303888, lng: 19.352749 },
    { lat: 49.303063, lng: 19.353819 },
    { lat: 49.303062, lng: 19.353833 },
    { lat: 49.303063, lng: 19.353905 },
    { lat: 49.303064, lng: 19.353993 },
    { lat: 49.303064, lng: 19.35403 },
    { lat: 49.303064, lng: 19.35407 },
    { lat: 49.303065, lng: 19.3541 },
    { lat: 49.301672, lng: 19.356158 },
    { lat: 49.300726, lng: 19.357891 },
    { lat: 49.301484, lng: 19.359885 },
    { lat: 49.302348, lng: 19.361691 },
    { lat: 49.303075, lng: 19.363637 },
  ],
};

export default UnitsNámestovo;
