import clsx from "clsx";
import logo from "../Images/aboutUs/0_logo.png";
import otc from "../Images/aboutUs/2_1.png";
import rs from "../Images/aboutUs/2_2.png";
import rk from "../Images/aboutUs/2_3.png";
import vn from "../Images/aboutUs/2_4.png";
import cm from "../Images/aboutUs/2_5.png";
import srs from "../Images/aboutUs/2_6.png";
import si from "../Images/aboutUs/1_1.png";
import bk from "../Images/aboutUs/1_2.png";
import il from "../Images/aboutUs/1_3.png";
import us from "../Images/aboutUs/1_4.png";
import oo from "../Images/aboutUs/1_5.png";
import ocenenie from "../Images/aboutUs/3_1.png";
import kontrola from "../Images/aboutUs/3_2.png";
import lokator from "../Images/aboutUs/3_3.png";
import vypocet from "../Images/aboutUs/3_4.png";
import hodnotenie from "../Images/aboutUs/3_5.png";
import zaujemci from "../Images/aboutUs/4_1.png";
import realitneKancelarie from "../Images/aboutUs/4_2.png";
import finInst from "../Images/aboutUs/4_3.png";
import urady from "../Images/aboutUs/4_4.png";
import pravneSubjekty from "../Images/aboutUs/4_5.png";
import registre from "../Images/aboutUs/4_6.png";
import myslienka from "../Images/aboutUs/5_1.png";
import vyvoj from "../Images/aboutUs/5_2.png";
import live from "../Images/aboutUs/5_3.png";
import jednotka from "../Images/aboutUs/5_4.png";
import zhranicie from "../Images/aboutUs/5_5.png";

export default function AboutUs({ size }) {
  return (
    <div className="w-full h-screen bg-sky-100 flex flex-col justify-start items-center p-2">
      <div className={clsx("w-full h-auto flex flex-col justify-center items-start gap-3", {})}>
        <div className="w-full flex items-center justify-center flex-col">
          <h2 className="text-2xl uppercase text-[#232278] font-semibold">Realitný kompas</h2>
          <img src={logo} alt="" className="w-24 h-24" />
          <p className="bg-gradient-to-r from-blue-800 via-green-500 to-yellow-500 text-transparent bg-clip-text">
            Zorientuj sa vo svete realít
          </p>
        </div>
        <div className="w-full flex items-start justify-center flex-col gap-1">
          {/* Pre bežného používateľa */}
          <div className="w-full flex items-center justify-center">
            <h2 className="text-xl uppercase text-[#232278] font-semibold">Pre bežného používateľa</h2>
          </div>
          <div className="w-full flex flex-row items-center gap-1 pt-2">
            <img src={otc} alt="" className="w-24 h-24" />
            <div className="w-full flex flex-col">
              <h6 className="text-blue2 font-semibold">Okamžitá trhová cena</h6>
              <p className="w-5/6  text-sm">
                Zobrazenie aktuálnej trhovej ceny nehnuteľnosti pohodlným vyhľadaním a označením na mape
              </p>
            </div>
          </div>
          <div className="w-full flex flex-row items-center gap-1 pt-2">
            <img src={rs} alt="" className="w-24 h-24" />
            <div className="w-full flex flex-col">
              <h6 className="text-blue1 font-semibold">Realitné subjekty</h6>
              <p className="w-5/6  text-sm">
                Všetky subjekty vstupujúce do realitných vzťahov (realitné kancelárie, makléri, finanční
                sprostredkovatelia, znalci, geodeti, stavebné firmy, úrady, banky, poisťovne, registre a iné).
              </p>
            </div>
          </div>
          <div className="w-full flex flex-row items-center gap-1 pt-2">
            <img src={rk} alt="" className="w-24 h-24" />
            <div className="w-full flex flex-col">
              <h6 className="text-lightblue1 font-semibold">Realitná kalkulačka</h6>
              <p className="w-5/6  text-sm">
                Výpočet aktuálnej trhovej ceny na základe voliteľných vstupných parametrov.
              </p>
            </div>
          </div>
          <div className="w-full flex flex-row items-center gap-1 pt-2">
            <img src={vn} alt="" className="w-24 h-24" />
            <div className="w-full flex flex-col">
              <h6 className="text-skyblue font-semibold">Vyhľadávač nehnuteľností</h6>
              <p className="w-5/6  text-sm">
                Zobrazenie aktuálnej trhovej ceny nehnuteľnosti pohodlným vyhľadaním a označením na mape
              </p>
            </div>
          </div>
          <div className="w-full flex flex-row items-center gap-1 pt-2">
            <img src={cm} alt="" className="w-24 h-24" />
            <div className="w-full flex flex-col">
              <h6 className="text-green1 font-semibold">Cenová mapa</h6>
              <p className="w-5/6  text-sm">
                Mapa rozdelená do vrstiev s rovnakou aktuálnou trhovou cenou za meter štvorcový alebo celú nehnuteľnost
              </p>
            </div>
          </div>
          <div className="w-full flex flex-row items-center gap-1 mb-4 pt-2">
            <img src={srs} alt="" className="w-24 h-24" />
            <div className="w-full flex flex-col">
              <h6 className="text-lightgreen font-semibold">Smart realitné služby</h6>
              <p className="w-5/6  text-sm">
                Predaj, prenájom, kúpa, návrh ceny, komunikácia, dokumenty a iné. U nás nájdete všetko, čo potrebujete
                ak nehnuteľnosť hľadáte, predávate, chcete poistiť, oceniť, alebo vykonať akúkoľvek inú operáciu.
                Samozrejme k dispozícií je mobilná aplikácia s konfigurovateľnými notifikaciami.
              </p>
            </div>
          </div>
          {/* Pre bežného používateľa */}
          {/* PRE REALITNÝ SUBJEKT */}
          <div className="w-full flex items-center justify-center">
            <h2 className="text-xl uppercase text-[#232278] font-semibold">Pre realitný subjekt</h2>
          </div>
          <div className="w-full flex items-start justify-center flex-col gap-1">
            <div className="w-full flex flex-row items-center gap-1 pt-2">
              <img src={si} alt="" className="w-24 h-24" />
              <div className="w-full flex flex-col">
                <h6 className="text-blue1 font-semibold">Smart inzercia</h6>
                <p className="w-5/6  text-sm">
                  Zadajte adresu vášho inzerátu z iného portálu a v momente máte inzerát aj u nás. Alebo si vytvorte
                  nový za pár sekúnd.
                </p>
              </div>
            </div>
            <div className="w-full flex flex-row items-center gap-1 pt-2">
              <img src={bk} alt="" className="w-24 h-24" />
              <div className="w-full flex flex-col">
                <h6 className="text-lightblue1 font-semibold">Bezpečná komunikácia</h6>
                <p className="w-5/6  text-sm">
                  Overenie záujemcov a realitných subjektov, zabezpečený prenos informacií. Rezervácia obhliadok, rýchla
                  výmena otázok/odpovedí, negociácia ceny, poistenie, ocenenie, dokumentácia a iné.
                </p>
              </div>
            </div>
            <div className="w-full flex flex-row items-center gap-1 pt-2">
              <img src={il} alt="" className="w-24 h-24" />
              <div className="w-full flex flex-col">
                <h6 className="text-skyblue font-semibold">Intuitívne leady</h6>
                <p className="w-5/6  text-sm">
                  Vyhodnotenie vážnosti záujmu existujúcich kúpno/predajných ponúk. Návrh nových ponúk a potenciálnych
                  záujemcov.
                </p>
              </div>
            </div>
            <div className="w-full flex flex-row items-center gap-1 pt-2">
              <img src={us} alt="" className="w-24 h-24" />
              <div className="w-full flex flex-col">
                <h6 className="text-green1 font-semibold">Užitočné štatistiky</h6>
                <p className="w-5/6  text-sm">
                  Vývoj ceny konkrétnej nehnuteľnosti, analýza aktuálnej trhovej ceny, prognóza vývoja. Detailný
                  monitoring aktivít užívateľov (systém vám povie, kto má reálny záujem o vašu službu).
                </p>
              </div>
            </div>
            <div className="w-full flex flex-row items-center gap-1 mb-4 pt-2">
              <img src={oo} alt="" className="w-24 h-24" />
              <div className="w-full flex flex-col">
                <h6 className="text-lightgreen font-semibold">Online obchodovanie</h6>
                <p className="w-5/6  text-sm">
                  Kúpa, predaj, poistenie (nie len nehnuteľnosti), znalecký posudok, geometrický plán, výpis z katastra
                  a iné. Kampane, akcie, zľavy. Integrácia ponúkaných služieb.
                </p>
              </div>
            </div>
          </div>
          {/* PRE REALITNÝ SUBJEKT */}
          {/* PRE SPRÁVCU */}
          <div className="w-full flex items-center justify-center">
            <h2 className="text-xl uppercase text-[#232278] font-semibold">Pre správcu</h2>
          </div>
          <div className="w-full flex items-start justify-center flex-col gap-1 pt-2">
            <div className="w-full flex flex-row items-center gap-1">
              <img src={ocenenie} alt="" className="w-24 h-24" />
              <div className="w-full flex flex-col">
                <h6 className="text-blue1 font-semibold">Ocenenie</h6>
                <p className="w-5/6  text-sm">
                  Periodický zber údajov z rôznych zdrojov - realitné portály, štatistický úrad, vlastná databáza. 50k+
                  nehnuteľností zahrnutých vo výpočtoch.
                </p>
              </div>
            </div>
            <div className="w-full flex flex-row items-center gap-1 pt-2">
              <img src={kontrola} alt="" className="w-24 h-24" />
              <div className="w-full flex flex-col">
                <h6 className="text-lightblue1 font-semibold">Kontrola</h6>
                <p className="w-5/6  text-sm">
                  Zber katastrálnych a právnych informácií o nehnuteľnosti, vlastníkoch, ťarchách a iných dôležitých
                  vzťahoch.
                </p>
              </div>
            </div>
            <div className="w-full flex flex-row items-center gap-1 pt-2">
              <img src={lokator} alt="" className="w-24 h-24" />
              <div className="w-full flex flex-col">
                <h6 className="text-skyblue font-semibold">Lokátor</h6>
                <p className="w-5/6  text-sm">
                  Uživateľsky dokonalé prostredie pre prácu pomocou google maps rozhrania. Navyše rozširené pre realitné
                  potreby.
                </p>
              </div>
            </div>
            <div className="w-full flex flex-row items-center gap-1 pt-2">
              <img src={vypocet} alt="" className="w-24 h-24" />
              <div className="w-full flex flex-col">
                <h6 className="text-green1 font-semibold">Výpočet</h6>
                <p className="w-5/6  text-sm">
                  Algoritmus na výpočet aktuálnej trhovej ceny nehnuteľnosti simuluje ľudský mozog a používa neobmedzené
                  množstvo informácií.
                </p>
              </div>
            </div>
            <div className="w-full flex flex-row items-center gap-1 mb-1 pt-2">
              <img src={hodnotenie} alt="" className="w-24 h-24" />
              <div className="w-full flex flex-col">
                <h6 className="text-lightgreen font-semibold">Hodnotenie</h6>
                <p className="w-5/6  text-sm">
                  Systém vďaka hodnoteniu každého realitného subjektu ako aj samotnej nehnuteľnosti dokáže intuitívne
                  ponúknuť to najlepšie.
                </p>
              </div>
            </div>
          </div>
          {/* PRE SPRÁVCU */}
          {/* INTEGRÁCIA */}
          <div className="w-full flex items-center justify-center">
            <h2 className="text-xl uppercase text-[#232278] font-semibold">Integrácia</h2>
          </div>
          <div className="w-full flex items-start justify-center flex-col gap-1">
            <div className="w-full flex flex-row items-center gap-1">
              <img src={zaujemci} alt="" className="w-24 h-24" />
              <div className="w-full flex flex-col">
                <h6 className="text-blue2 font-semibold">Záujemci</h6>
                <p className="w-5/6  text-sm">
                  Záujemci o predaj/kúpu alebo inú službu/informáciu súvisiacu s realitami
                </p>
              </div>
            </div>
            <div className="w-full flex flex-row items-center gap-1">
              <img src={realitneKancelarie} alt="" className="w-24 h-24" />
              <div className="w-full flex flex-col">
                <h6 className="text-blue1 font-semibold">Realitné kancelárie</h6>
                <p className="w-5/6  text-sm">Realitné kancelárie, makléri, združenia, únie, akadémie</p>
              </div>
            </div>
            <div className="w-full flex flex-row items-center gap-1">
              <img src={finInst} alt="" className="w-24 h-24" />
              <div className="w-full flex flex-col">
                <h6 className="text-lightblue1 font-semibold">Finančné inštitúcie</h6>
                <p className="w-5/6  text-sm">
                  Banky, poisťovne, nebankové inštitúcie, finanční sprostredkovatelia, makléri
                </p>
              </div>
            </div>
            <div className="w-full flex flex-row items-center gap-1">
              <img src={urady} alt="" className="w-24 h-24" />
              <div className="w-full flex flex-col">
                <h6 className="text-skyblue font-semibold">Úrady</h6>
                <p className="w-5/6  text-sm">Stavebné úrady, životné prostredie stavebné firmy, likvidácia odpadu</p>
              </div>
            </div>
            <div className="w-full flex flex-row items-center gap-1">
              <img src={pravneSubjekty} alt="" className="w-24 h-24" />
              <div className="w-full flex flex-col">
                <h6 className="text-green1 font-semibold">Právne subjekty</h6>
                <p className="w-5/6  text-sm">Znalci, advokáti, notári, exekútori, geodeti</p>
              </div>
            </div>
            <div className="w-full flex flex-row items-center gap-1 mb-1">
              <img src={registre} alt="" className="w-24 h-24" />
              <div className="w-full flex flex-col">
                <h6 className="text-lightgreen font-semibold">Registre</h6>
                <p className="w-5/6  text-sm">Kataster, úverový register</p>
              </div>
            </div>
          </div>
          {/* INTEGRÁCIA */}
          {/* CESTA A CIELE */}
          <div className="w-full flex items-center justify-center">
            <h2 className="text-xl uppercase text-[#232278] font-semibold">Cesta a ciele</h2>
          </div>
          <div className="w-full flex items-start justify-center flex-col gap-1">
            <div className="w-full flex flex-row items-center gap-1">
              <img src={myslienka} alt="" className="w-24 h-24" />
              <div className="w-full flex flex-col">
                <h6 className="text-blue1 font-semibold">Myšlienka - 2022</h6>
                <p className="w-5/6  text-sm">
                  Koncom roka 2022 sme definovali myšlienku RK ako jedinečnú na Slovensku a vo svete.
                </p>
              </div>
            </div>
            <div className="w-full flex flex-row items-center gap-1">
              <img src={vyvoj} alt="" className="w-24 h-24" />
              <div className="w-full flex flex-col">
                <h6 className="text-lightblue1 font-semibold">Vývoj - 2023</h6>
                <p className="w-5/6  text-sm">
                  Začiatkom roka 2023 sme sa pustili do zberu požiadaviek a analýzy. Nasledoval agilný vývoj.
                </p>
              </div>
            </div>
            <div className="w-full flex flex-row items-center gap-1">
              <img src={live} alt="" className="w-24 h-24" />
              <div className="w-full flex flex-col">
                <h6 className="text-skyblue font-semibold">Live - 2024</h6>
                <p className="w-5/6  text-sm">
                  Po takmer 2 rokoch od zrodu myšlienky cez unikátnu analýzu a náročný vývoj ideme do produkcie.
                </p>
              </div>
            </div>
            <div className="w-full flex flex-row items-center gap-1">
              <img src={jednotka} alt="" className="w-24 h-24" />
              <div className="w-full flex flex-col">
                <h6 className="text-green1 font-semibold">Jednotka na trhu - 2028</h6>
                <p className="w-5/6  text-sm">
                  Do roku 2028 sa plánujeme stať najnavštevovanejším realitným portálom na Slovensku.
                </p>
              </div>
            </div>
            <div className="w-full flex flex-row items-center gap-1 mb-2">
              <img src={zhranicie} alt="" className="w-24 h-24" />
              <div className="w-full flex flex-col">
                <h6 className="text-lightgreen font-semibold">Zahraničie - 2030</h6>
                <p className="w-5/6  text-sm">
                  Pokračujeme Českom a následne ďalšími štátmi na základe vyhodnotenia stavu konkrétneho trhu.
                </p>
              </div>
            </div>
          </div>
          {/* CESTA A CIELE */}
        </div>
      </div>
    </div>
  );
}
