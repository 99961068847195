import { useEffect, useState } from "react";
import { averagePriceByCodeService } from "../../../../services/averagePriceByCodeService";
import { averagePriceService } from "../../../../services/averagePriceService";

export default function LandCalc({ PMResult, setPMResult, regDisUni }) {
  const [landArea, setLandArea] = useState({ area: "10", areaS: "" });
  const [response, setResponse] = useState();
  const [specify, setSpecify] = useState(false);
  const lMin = 10;
  const lMax = 10000;
  const prevObec = "";

  const getAveragePrice = async (parent) => {
    console.log("PMResult = ", PMResult);
    if (PMResult?.data?.obec && PMResult?.data?.parent && PMResult.region) {
      const request = await averagePriceByCodeService.getAveragePrice(
        decodeURIComponent("601"),
        null,
        null,
        decodeURIComponent(landArea.area),
        decodeURIComponent(PMResult?.data?.code),
      );
      const resp = await request.json();
      setResponse(resp);
      console.log("resp = ", resp);
      setLandArea({ ...landArea, areaS: landArea.area });
      return;
    }
    // if (PMResult?.data?.parent && PMResult.region) {
    if (regDisUni.district) {
      const request = await averagePriceService.getDistrictAvgPrice(
        decodeURIComponent(parent),
        decodeURIComponent("601"),
        null,
        null,
        decodeURIComponent(landArea.area),
      );
      const response = await request.json();
      setPMResult({
        ...PMResult,
        active: true,
        data: {
          ...PMResult.data,
          avgPozemokM2: response.avgPrice,
          obec: "",
          parent: PMResult?.data?.obec,
        },
      });
      console.log("response district = ", response);
      return;
    }
    // if (PMResult.region) {
    if (regDisUni.region) {
      console.log("PMResult land");
      const request = await averagePriceService.getRegionAvgPrice(
        decodeURIComponent(parent),
        decodeURIComponent("601"),
        null,
        null,
        decodeURIComponent(landArea.area),
      );
      const response = await request.json();
      // setPMResult({ ...PMResult, active: true, data: response });
      setPMResult({
        ...PMResult,
        active: true,
        data: {
          ...PMResult.data,
          avgPozemokM2: response.avgPrice,
          obec: "",
          parent: "",
        },
      });
      console.log("response regions = ", response);
      return;
    } else {
      console.log("something went wrong");
    }
  };

  useEffect(() => {
    if (PMResult?.data?.obec !== prevObec) {
      setResponse();
      setSpecify(false);
    }
  }, [PMResult?.data?.obec]);

  const calculation = () => {
    if (!specify || response?.avgPrice) {
      setSpecify(true);
      setResponse();
    } else {
      getAveragePrice();
    }
  };

  return PMResult.pozemok ? (
    <div className="flex flex-col">
      <div className="font-medium mb-1">
        Priemerná trhová cena za m²:{" "}
        {response?.avgPrice
          ? response?.avgPrice
          : PMResult?.data?.avgPozemokM2
            ? PMResult?.data?.avgPozemokM2
            : PMResult?.data?.avgPrice}{" "}
        €
      </div>
      {specify ? (
        <div>
          <div className="flex">
            <p className="">Plocha pozemku: </p>
            <input
              name="landArea"
              value={landArea.area}
              onChange={(event) => setLandArea({ ...landArea, area: event.target.value })}
              min={10}
              className="flex-1 h-8 ml-2 p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-sky-500"
              type="number"
            />
          </div>
          <input
            className="w-full"
            onChange={(event) => setLandArea({ ...landArea, area: event.target.value })}
            type={"range"}
            min={lMin}
            max={lMax}
            step={1}
            value={landArea.area}
            list={"tick-list"}
          />
          <div className="mb-2 flex justify-between">
            <span className="mb-2 -mt-1.5">{lMin} m²</span>
            <span className="mb-2 -mt-1.5">{lMax} m²</span>
          </div>
        </div>
      ) : null}
      {response?.avgPrice ? (
        <p className="w-full mb-2">
          {`Priemerná trhová cena bola vypočítaná na základe`} {response?.countOfObjects}
          {` podobných nehnuteľností s presnosťou`} {response?.relevancy} {`%.`}
        </p>
      ) : null}
      {!specify ? (
        <div className="flex justify-center mb-1">
          <button
            className="bg-sky-200 w-auto p-2 rounded-xl disabled:bg-gray-200"
            onClick={() => calculation()}
            disabled={PMResult.region === ""}
          >
            Upresniť kalkuláciu
          </button>
        </div>
      ) : (
        <div className="flex justify-end gap-2 mb-1">
          <button
            disabled={landArea.area === "10" || landArea.area === landArea.areaS}
            className="disabled:opacity-60 disabled:bg-slate-300 disabled:cursor-not-allowed bg-sky-200 w-20 p-2 rounded-xl "
            onClick={() => getAveragePrice(PMResult?.parent ? PMResult?.parent : "Region" + PMResult.region)}
          >
            Použiť
          </button>
          <button
            className="bg-sky-200 w-20 p-2 rounded-xl"
            onClick={() => {
              setResponse();
              setSpecify(false);
              setLandArea({ ...landArea, area: "10" });
            }}
          >
            Zrušiť
          </button>
        </div>
      )}
    </div>
  ) : null;
}
