const UnitsRevúca = {
  MuránskaHuta: [
    { lat: 48.784546, lng: 20.109957 },
    { lat: 48.7844466, lng: 20.109128 },
    { lat: 48.7841888, lng: 20.1087442 },
    { lat: 48.7840085, lng: 20.1082725 },
    { lat: 48.7840776, lng: 20.1059913 },
    { lat: 48.7838662, lng: 20.1048432 },
    { lat: 48.7838817, lng: 20.1043978 },
    { lat: 48.7838075, lng: 20.1041533 },
    { lat: 48.7836849, lng: 20.1033012 },
    { lat: 48.783328, lng: 20.1022539 },
    { lat: 48.7829149, lng: 20.1014607 },
    { lat: 48.7824937, lng: 20.1009676 },
    { lat: 48.7822525, lng: 20.100522 },
    { lat: 48.7820484, lng: 20.0996932 },
    { lat: 48.781986, lng: 20.098773 },
    { lat: 48.7817856, lng: 20.0974218 },
    { lat: 48.7813313, lng: 20.0960897 },
    { lat: 48.7810253, lng: 20.095412 },
    { lat: 48.7808623, lng: 20.0952308 },
    { lat: 48.7805183, lng: 20.0941142 },
    { lat: 48.7804262, lng: 20.0941471 },
    { lat: 48.7801259, lng: 20.0940531 },
    { lat: 48.7795222, lng: 20.0942447 },
    { lat: 48.7787046, lng: 20.0942717 },
    { lat: 48.778395, lng: 20.0944696 },
    { lat: 48.7783587, lng: 20.0946851 },
    { lat: 48.7780221, lng: 20.0941649 },
    { lat: 48.7777045, lng: 20.0923791 },
    { lat: 48.7772544, lng: 20.0914236 },
    { lat: 48.7771133, lng: 20.09125 },
    { lat: 48.7766446, lng: 20.0915504 },
    { lat: 48.7765905, lng: 20.091359 },
    { lat: 48.7763622, lng: 20.0911444 },
    { lat: 48.7760358, lng: 20.0911222 },
    { lat: 48.7752078, lng: 20.0913698 },
    { lat: 48.7735378, lng: 20.0984219 },
    { lat: 48.7728851, lng: 20.0988465 },
    { lat: 48.7728835, lng: 20.0992741 },
    { lat: 48.7727941, lng: 20.0994824 },
    { lat: 48.77301, lng: 20.1003878 },
    { lat: 48.7729883, lng: 20.1007629 },
    { lat: 48.7731365, lng: 20.1010815 },
    { lat: 48.7730599, lng: 20.1013026 },
    { lat: 48.7728557, lng: 20.1014059 },
    { lat: 48.7727446, lng: 20.1015513 },
    { lat: 48.7727039, lng: 20.1017158 },
    { lat: 48.772765, lng: 20.1021071 },
    { lat: 48.772634, lng: 20.1021819 },
    { lat: 48.7725363, lng: 20.1032337 },
    { lat: 48.7724349, lng: 20.1032367 },
    { lat: 48.7723541, lng: 20.1029622 },
    { lat: 48.7721797, lng: 20.1027207 },
    { lat: 48.7721529, lng: 20.10259 },
    { lat: 48.7720215, lng: 20.1024873 },
    { lat: 48.7719336, lng: 20.1025427 },
    { lat: 48.7719222, lng: 20.1027905 },
    { lat: 48.7717003, lng: 20.1030887 },
    { lat: 48.7717409, lng: 20.1033373 },
    { lat: 48.7715073, lng: 20.1035248 },
    { lat: 48.7718273, lng: 20.1040198 },
    { lat: 48.7718182, lng: 20.1043227 },
    { lat: 48.7714005, lng: 20.1043011 },
    { lat: 48.7712562, lng: 20.104351 },
    { lat: 48.7706505, lng: 20.1048511 },
    { lat: 48.7702857, lng: 20.104691 },
    { lat: 48.7698713, lng: 20.1043344 },
    { lat: 48.7698213, lng: 20.1041031 },
    { lat: 48.7690965, lng: 20.1037874 },
    { lat: 48.7689348, lng: 20.103629 },
    { lat: 48.7687942, lng: 20.1036886 },
    { lat: 48.7686311, lng: 20.1036128 },
    { lat: 48.7684143, lng: 20.1032978 },
    { lat: 48.7681127, lng: 20.1029986 },
    { lat: 48.7679534, lng: 20.10271 },
    { lat: 48.7679287, lng: 20.102552 },
    { lat: 48.7676491, lng: 20.1022936 },
    { lat: 48.7674934, lng: 20.1019597 },
    { lat: 48.7675258, lng: 20.1015925 },
    { lat: 48.7673247, lng: 20.1014931 },
    { lat: 48.7671973, lng: 20.1015757 },
    { lat: 48.7670513, lng: 20.1014748 },
    { lat: 48.7669596, lng: 20.10306 },
    { lat: 48.7668788, lng: 20.1032357 },
    { lat: 48.7668544, lng: 20.1035454 },
    { lat: 48.7667116, lng: 20.1035502 },
    { lat: 48.7666619, lng: 20.1036569 },
    { lat: 48.7667707, lng: 20.1039242 },
    { lat: 48.7668189, lng: 20.1039479 },
    { lat: 48.7669439, lng: 20.1044714 },
    { lat: 48.7669944, lng: 20.1045173 },
    { lat: 48.7670101, lng: 20.1050393 },
    { lat: 48.7669238, lng: 20.1054358 },
    { lat: 48.7673099, lng: 20.1059313 },
    { lat: 48.7678299, lng: 20.1061691 },
    { lat: 48.7678735, lng: 20.1062996 },
    { lat: 48.7677828, lng: 20.1065587 },
    { lat: 48.7678816, lng: 20.1069101 },
    { lat: 48.7678026, lng: 20.1070153 },
    { lat: 48.768041, lng: 20.1073401 },
    { lat: 48.7680963, lng: 20.1075609 },
    { lat: 48.7683456, lng: 20.1080132 },
    { lat: 48.7681561, lng: 20.1083076 },
    { lat: 48.7681051, lng: 20.1085502 },
    { lat: 48.7674781, lng: 20.1092767 },
    { lat: 48.7671554, lng: 20.1087653 },
    { lat: 48.7668992, lng: 20.1080637 },
    { lat: 48.7668625, lng: 20.1078508 },
    { lat: 48.7665126, lng: 20.1075307 },
    { lat: 48.7661331, lng: 20.1069824 },
    { lat: 48.7661263, lng: 20.1068977 },
    { lat: 48.7652026, lng: 20.1069494 },
    { lat: 48.7645101, lng: 20.1072551 },
    { lat: 48.7642972, lng: 20.1077537 },
    { lat: 48.7633649, lng: 20.1090586 },
    { lat: 48.7627135, lng: 20.109792 },
    { lat: 48.7620862, lng: 20.1103436 },
    { lat: 48.7623065, lng: 20.1107496 },
    { lat: 48.7618513, lng: 20.1111562 },
    { lat: 48.7617918, lng: 20.1111343 },
    { lat: 48.7615279, lng: 20.1115666 },
    { lat: 48.7612625, lng: 20.1116617 },
    { lat: 48.7611623, lng: 20.111549 },
    { lat: 48.760763, lng: 20.1115898 },
    { lat: 48.7605468, lng: 20.111479 },
    { lat: 48.7603595, lng: 20.111529 },
    { lat: 48.7601523, lng: 20.1117254 },
    { lat: 48.760147, lng: 20.112227 },
    { lat: 48.7598513, lng: 20.1126559 },
    { lat: 48.7594753, lng: 20.1130197 },
    { lat: 48.759243, lng: 20.1128212 },
    { lat: 48.7576424, lng: 20.1142798 },
    { lat: 48.7577042, lng: 20.1150501 },
    { lat: 48.7578349, lng: 20.1155759 },
    { lat: 48.7578933, lng: 20.1168159 },
    { lat: 48.75797, lng: 20.1188602 },
    { lat: 48.7578813, lng: 20.1195867 },
    { lat: 48.7584957, lng: 20.1210644 },
    { lat: 48.7585062, lng: 20.1210798 },
    { lat: 48.7587092, lng: 20.121444 },
    { lat: 48.758748, lng: 20.1217013 },
    { lat: 48.7590636, lng: 20.1223469 },
    { lat: 48.7591685, lng: 20.1228202 },
    { lat: 48.7593184, lng: 20.1227102 },
    { lat: 48.759329, lng: 20.1224725 },
    { lat: 48.7600221, lng: 20.1229288 },
    { lat: 48.7607482, lng: 20.1234998 },
    { lat: 48.7615078, lng: 20.1242609 },
    { lat: 48.7616555, lng: 20.1247348 },
    { lat: 48.7617325, lng: 20.1254323 },
    { lat: 48.7614302, lng: 20.1260074 },
    { lat: 48.7612126, lng: 20.1260847 },
    { lat: 48.7610781, lng: 20.126265 },
    { lat: 48.7613745, lng: 20.1265762 },
    { lat: 48.7623992, lng: 20.1270685 },
    { lat: 48.7623448, lng: 20.1272803 },
    { lat: 48.7619562, lng: 20.1278341 },
    { lat: 48.7616396, lng: 20.1279724 },
    { lat: 48.7613526, lng: 20.1282258 },
    { lat: 48.7605697, lng: 20.1281383 },
    { lat: 48.7592514, lng: 20.1284005 },
    { lat: 48.7592309, lng: 20.1287843 },
    { lat: 48.7633965, lng: 20.1301548 },
    { lat: 48.7635237, lng: 20.1306821 },
    { lat: 48.7638303, lng: 20.130881 },
    { lat: 48.7642276, lng: 20.1313196 },
    { lat: 48.7636182, lng: 20.1321713 },
    { lat: 48.7626309, lng: 20.1332119 },
    { lat: 48.7625484, lng: 20.1340906 },
    { lat: 48.7629025, lng: 20.1362097 },
    { lat: 48.7629989, lng: 20.1365088 },
    { lat: 48.7637162, lng: 20.1364455 },
    { lat: 48.7650205, lng: 20.1359192 },
    { lat: 48.7672067, lng: 20.1345069 },
    { lat: 48.7679266, lng: 20.1344036 },
    { lat: 48.7684932, lng: 20.1341296 },
    { lat: 48.7686725, lng: 20.134575 },
    { lat: 48.7683447, lng: 20.1347454 },
    { lat: 48.7691725, lng: 20.1396802 },
    { lat: 48.7721427, lng: 20.1405768 },
    { lat: 48.772349, lng: 20.1407762 },
    { lat: 48.773697, lng: 20.1412746 },
    { lat: 48.7743763, lng: 20.1413573 },
    { lat: 48.7756521, lng: 20.1469422 },
    { lat: 48.7779024, lng: 20.1464977 },
    { lat: 48.7791342, lng: 20.1459589 },
    { lat: 48.7799392, lng: 20.1452876 },
    { lat: 48.7802771, lng: 20.1456469 },
    { lat: 48.7812145, lng: 20.1460732 },
    { lat: 48.7816848, lng: 20.1464268 },
    { lat: 48.781947, lng: 20.147318 },
    { lat: 48.782017, lng: 20.146323 },
    { lat: 48.782065, lng: 20.145653 },
    { lat: 48.782365, lng: 20.145032 },
    { lat: 48.782743, lng: 20.144251 },
    { lat: 48.783134, lng: 20.143444 },
    { lat: 48.783254, lng: 20.143198 },
    { lat: 48.783461, lng: 20.143029 },
    { lat: 48.783828, lng: 20.142722 },
    { lat: 48.784281, lng: 20.142352 },
    { lat: 48.784675, lng: 20.142027 },
    { lat: 48.78494, lng: 20.141808 },
    { lat: 48.785361, lng: 20.141455 },
    { lat: 48.785444, lng: 20.141389 },
    { lat: 48.785508, lng: 20.14121 },
    { lat: 48.785675, lng: 20.140747 },
    { lat: 48.785915, lng: 20.140476 },
    { lat: 48.786015, lng: 20.140403 },
    { lat: 48.786319, lng: 20.140153 },
    { lat: 48.786666, lng: 20.139878 },
    { lat: 48.786899, lng: 20.139759 },
    { lat: 48.787442, lng: 20.139476 },
    { lat: 48.787699, lng: 20.139343 },
    { lat: 48.787965, lng: 20.1391 },
    { lat: 48.78816, lng: 20.138923 },
    { lat: 48.788338, lng: 20.1384 },
    { lat: 48.788487, lng: 20.13797 },
    { lat: 48.78887, lng: 20.137251 },
    { lat: 48.788954, lng: 20.137095 },
    { lat: 48.78904, lng: 20.136981 },
    { lat: 48.789452, lng: 20.136395 },
    { lat: 48.789622, lng: 20.136153 },
    { lat: 48.789901, lng: 20.135536 },
    { lat: 48.790187, lng: 20.134898 },
    { lat: 48.790241, lng: 20.134781 },
    { lat: 48.790273, lng: 20.134342 },
    { lat: 48.790341, lng: 20.13385 },
    { lat: 48.790392, lng: 20.1335 },
    { lat: 48.790446, lng: 20.132928 },
    { lat: 48.790427, lng: 20.132787 },
    { lat: 48.790346, lng: 20.132438 },
    { lat: 48.790195, lng: 20.132208 },
    { lat: 48.79015, lng: 20.132097 },
    { lat: 48.790131, lng: 20.131796 },
    { lat: 48.790115, lng: 20.131758 },
    { lat: 48.790104, lng: 20.131746 },
    { lat: 48.790084, lng: 20.131712 },
    { lat: 48.790119, lng: 20.131685 },
    { lat: 48.790149, lng: 20.131651 },
    { lat: 48.790294, lng: 20.13131 },
    { lat: 48.790351, lng: 20.131183 },
    { lat: 48.790387, lng: 20.131092 },
    { lat: 48.79033, lng: 20.131055 },
    { lat: 48.790137, lng: 20.130898 },
    { lat: 48.789806, lng: 20.129644 },
    { lat: 48.789627, lng: 20.129138 },
    { lat: 48.789462, lng: 20.128674 },
    { lat: 48.789276, lng: 20.128423 },
    { lat: 48.789021, lng: 20.127572 },
    { lat: 48.788859, lng: 20.127031 },
    { lat: 48.788639, lng: 20.126496 },
    { lat: 48.788519, lng: 20.126122 },
    { lat: 48.78838, lng: 20.125697 },
    { lat: 48.788218, lng: 20.125339 },
    { lat: 48.788005, lng: 20.124874 },
    { lat: 48.78795, lng: 20.12474 },
    { lat: 48.787717, lng: 20.124169 },
    { lat: 48.787572, lng: 20.123969 },
    { lat: 48.787357, lng: 20.123317 },
    { lat: 48.787025, lng: 20.122841 },
    { lat: 48.786892, lng: 20.122443 },
    { lat: 48.786611, lng: 20.121919 },
    { lat: 48.786371, lng: 20.121471 },
    { lat: 48.786181, lng: 20.121054 },
    { lat: 48.785978, lng: 20.120674 },
    { lat: 48.785777, lng: 20.120103 },
    { lat: 48.785506, lng: 20.119206 },
    { lat: 48.785229, lng: 20.118734 },
    { lat: 48.785061, lng: 20.118159 },
    { lat: 48.784869, lng: 20.117759 },
    { lat: 48.784715, lng: 20.117434 },
    { lat: 48.784503, lng: 20.117085 },
    { lat: 48.784363, lng: 20.116847 },
    { lat: 48.784334, lng: 20.116666 },
    { lat: 48.784179, lng: 20.115603 },
    { lat: 48.784155, lng: 20.115453 },
    { lat: 48.784227, lng: 20.115162 },
    { lat: 48.784458, lng: 20.114185 },
    { lat: 48.784584, lng: 20.113656 },
    { lat: 48.784489, lng: 20.112853 },
    { lat: 48.784352, lng: 20.111736 },
    { lat: 48.784268, lng: 20.111008 },
    { lat: 48.784214, lng: 20.110611 },
    { lat: 48.784418, lng: 20.110183 },
    { lat: 48.784498, lng: 20.110015 },
    { lat: 48.784518, lng: 20.109994 },
    { lat: 48.784546, lng: 20.109957 },
  ],
  Hrlica: [
    { lat: 48.635678, lng: 20.0639652 },
    { lat: 48.635679, lng: 20.0637433 },
    { lat: 48.6356701, lng: 20.0636654 },
    { lat: 48.635556, lng: 20.0631893 },
    { lat: 48.6357185, lng: 20.0630886 },
    { lat: 48.6359212, lng: 20.0625977 },
    { lat: 48.6362337, lng: 20.0624479 },
    { lat: 48.6366232, lng: 20.0623585 },
    { lat: 48.6374429, lng: 20.0618099 },
    { lat: 48.6379532, lng: 20.0616205 },
    { lat: 48.6379436, lng: 20.0615779 },
    { lat: 48.638121, lng: 20.0613978 },
    { lat: 48.6382204, lng: 20.0611594 },
    { lat: 48.6382369, lng: 20.0603366 },
    { lat: 48.6381821, lng: 20.0598275 },
    { lat: 48.6380185, lng: 20.0597111 },
    { lat: 48.6380021, lng: 20.0595831 },
    { lat: 48.637738, lng: 20.0592984 },
    { lat: 48.6374422, lng: 20.0586002 },
    { lat: 48.6373731, lng: 20.0579685 },
    { lat: 48.6371694, lng: 20.0570219 },
    { lat: 48.6372511, lng: 20.056543 },
    { lat: 48.6371817, lng: 20.0560934 },
    { lat: 48.6370784, lng: 20.055992 },
    { lat: 48.6369535, lng: 20.0557224 },
    { lat: 48.6369252, lng: 20.055474 },
    { lat: 48.6368132, lng: 20.0553778 },
    { lat: 48.636622, lng: 20.0545461 },
    { lat: 48.6366518, lng: 20.0542277 },
    { lat: 48.6365242, lng: 20.0537857 },
    { lat: 48.6364168, lng: 20.0535868 },
    { lat: 48.6363053, lng: 20.0530706 },
    { lat: 48.6361668, lng: 20.0528779 },
    { lat: 48.6360855, lng: 20.0525504 },
    { lat: 48.6361234, lng: 20.0520746 },
    { lat: 48.6363208, lng: 20.0517302 },
    { lat: 48.636388, lng: 20.0513111 },
    { lat: 48.636821, lng: 20.0505349 },
    { lat: 48.6369575, lng: 20.0500526 },
    { lat: 48.63705, lng: 20.0496461 },
    { lat: 48.6370671, lng: 20.049246 },
    { lat: 48.6370357, lng: 20.049115 },
    { lat: 48.6370821, lng: 20.0488728 },
    { lat: 48.6378246, lng: 20.0462186 },
    { lat: 48.6391953, lng: 20.0426799 },
    { lat: 48.6395804, lng: 20.036443 },
    { lat: 48.6400994, lng: 20.0345394 },
    { lat: 48.64012, lng: 20.0342437 },
    { lat: 48.6399568, lng: 20.0314595 },
    { lat: 48.6401525, lng: 20.0302368 },
    { lat: 48.6402709, lng: 20.0291279 },
    { lat: 48.6396904, lng: 20.0260855 },
    { lat: 48.6395692, lng: 20.0252626 },
    { lat: 48.6389886, lng: 20.0223563 },
    { lat: 48.6390914, lng: 20.0221446 },
    { lat: 48.6389588, lng: 20.0220215 },
    { lat: 48.6388729, lng: 20.0217658 },
    { lat: 48.6386048, lng: 20.0216317 },
    { lat: 48.638516, lng: 20.0215005 },
    { lat: 48.638084, lng: 20.020524 },
    { lat: 48.637805, lng: 20.020715 },
    { lat: 48.637792, lng: 20.020724 },
    { lat: 48.637489, lng: 20.02093 },
    { lat: 48.637123, lng: 20.021177 },
    { lat: 48.636774, lng: 20.021412 },
    { lat: 48.6364, lng: 20.021661 },
    { lat: 48.636109, lng: 20.02186 },
    { lat: 48.636012, lng: 20.022186 },
    { lat: 48.63591, lng: 20.022537 },
    { lat: 48.6359, lng: 20.022539 },
    { lat: 48.63594, lng: 20.022827 },
    { lat: 48.635894, lng: 20.023214 },
    { lat: 48.635859, lng: 20.023526 },
    { lat: 48.635811, lng: 20.023907 },
    { lat: 48.635717, lng: 20.0243 },
    { lat: 48.635723, lng: 20.024308 },
    { lat: 48.635733, lng: 20.024622 },
    { lat: 48.635732, lng: 20.024676 },
    { lat: 48.635717, lng: 20.025531 },
    { lat: 48.635714, lng: 20.025688 },
    { lat: 48.63576, lng: 20.026775 },
    { lat: 48.635649, lng: 20.027039 },
    { lat: 48.635415, lng: 20.027283 },
    { lat: 48.635267, lng: 20.027436 },
    { lat: 48.635072, lng: 20.027639 },
    { lat: 48.634979, lng: 20.027738 },
    { lat: 48.634775, lng: 20.027955 },
    { lat: 48.634123, lng: 20.028198 },
    { lat: 48.633869, lng: 20.028295 },
    { lat: 48.63381, lng: 20.028318 },
    { lat: 48.633768, lng: 20.028341 },
    { lat: 48.633504, lng: 20.028486 },
    { lat: 48.633246, lng: 20.028629 },
    { lat: 48.633027, lng: 20.028751 },
    { lat: 48.632997, lng: 20.028769 },
    { lat: 48.632874, lng: 20.028796 },
    { lat: 48.63266, lng: 20.028846 },
    { lat: 48.632585, lng: 20.028865 },
    { lat: 48.632502, lng: 20.028898 },
    { lat: 48.63242, lng: 20.028934 },
    { lat: 48.632335, lng: 20.02899 },
    { lat: 48.63213, lng: 20.029135 },
    { lat: 48.631997, lng: 20.029227 },
    { lat: 48.631835, lng: 20.029359 },
    { lat: 48.631649, lng: 20.02951 },
    { lat: 48.631501, lng: 20.029636 },
    { lat: 48.631466, lng: 20.029682 },
    { lat: 48.631348, lng: 20.029854 },
    { lat: 48.631271, lng: 20.029965 },
    { lat: 48.631094, lng: 20.030021 },
    { lat: 48.630909, lng: 20.030078 },
    { lat: 48.630766, lng: 20.030234 },
    { lat: 48.630602, lng: 20.030409 },
    { lat: 48.630488, lng: 20.030581 },
    { lat: 48.630333, lng: 20.030824 },
    { lat: 48.6303, lng: 20.03088 },
    { lat: 48.630223, lng: 20.030997 },
    { lat: 48.630103, lng: 20.031169 },
    { lat: 48.629905, lng: 20.031442 },
    { lat: 48.629856, lng: 20.031535 },
    { lat: 48.629823, lng: 20.031603 },
    { lat: 48.629752, lng: 20.031722 },
    { lat: 48.62971, lng: 20.031791 },
    { lat: 48.629616, lng: 20.031906 },
    { lat: 48.629548, lng: 20.031986 },
    { lat: 48.629465, lng: 20.032059 },
    { lat: 48.629402, lng: 20.032111 },
    { lat: 48.629361, lng: 20.032147 },
    { lat: 48.629347, lng: 20.032191 },
    { lat: 48.629321, lng: 20.032258 },
    { lat: 48.629298, lng: 20.032284 },
    { lat: 48.629242, lng: 20.032355 },
    { lat: 48.629232, lng: 20.032373 },
    { lat: 48.629147, lng: 20.032514 },
    { lat: 48.62913, lng: 20.032533 },
    { lat: 48.628765, lng: 20.032934 },
    { lat: 48.628663, lng: 20.033092 },
    { lat: 48.628639, lng: 20.033129 },
    { lat: 48.628581, lng: 20.033223 },
    { lat: 48.628488, lng: 20.033262 },
    { lat: 48.628317, lng: 20.033413 },
    { lat: 48.628307, lng: 20.033421 },
    { lat: 48.628218, lng: 20.033501 },
    { lat: 48.628001, lng: 20.033628 },
    { lat: 48.627992, lng: 20.033612 },
    { lat: 48.627859, lng: 20.03371 },
    { lat: 48.62778, lng: 20.033774 },
    { lat: 48.627731, lng: 20.033795 },
    { lat: 48.627626, lng: 20.033823 },
    { lat: 48.627597, lng: 20.033852 },
    { lat: 48.62755, lng: 20.033892 },
    { lat: 48.627334, lng: 20.034026 },
    { lat: 48.627092, lng: 20.034179 },
    { lat: 48.626912, lng: 20.034299 },
    { lat: 48.626757, lng: 20.034363 },
    { lat: 48.626649, lng: 20.034406 },
    { lat: 48.626525, lng: 20.034419 },
    { lat: 48.626294, lng: 20.034424 },
    { lat: 48.626173, lng: 20.03447 },
    { lat: 48.625953, lng: 20.03474 },
    { lat: 48.625757, lng: 20.03512 },
    { lat: 48.625659, lng: 20.03532 },
    { lat: 48.62544, lng: 20.035706 },
    { lat: 48.62542, lng: 20.036056 },
    { lat: 48.625435, lng: 20.036282 },
    { lat: 48.625379, lng: 20.036564 },
    { lat: 48.625315, lng: 20.036641 },
    { lat: 48.625235, lng: 20.036715 },
    { lat: 48.625082, lng: 20.036781 },
    { lat: 48.624917, lng: 20.036807 },
    { lat: 48.624848, lng: 20.036755 },
    { lat: 48.624742, lng: 20.036656 },
    { lat: 48.624632, lng: 20.036557 },
    { lat: 48.624447, lng: 20.036407 },
    { lat: 48.624323, lng: 20.036312 },
    { lat: 48.624314, lng: 20.036308 },
    { lat: 48.624211, lng: 20.036251 },
    { lat: 48.624158, lng: 20.036228 },
    { lat: 48.624123, lng: 20.03622 },
    { lat: 48.624052, lng: 20.036202 },
    { lat: 48.624008, lng: 20.036209 },
    { lat: 48.623899, lng: 20.036226 },
    { lat: 48.623718, lng: 20.036383 },
    { lat: 48.623536, lng: 20.036558 },
    { lat: 48.623416, lng: 20.036735 },
    { lat: 48.62329, lng: 20.03694 },
    { lat: 48.6228089, lng: 20.037753 },
    { lat: 48.6225693, lng: 20.038519 },
    { lat: 48.6220336, lng: 20.0393173 },
    { lat: 48.6218713, lng: 20.0394502 },
    { lat: 48.6201008, lng: 20.0400533 },
    { lat: 48.6200741, lng: 20.0401722 },
    { lat: 48.6194335, lng: 20.0404734 },
    { lat: 48.6188931, lng: 20.0412657 },
    { lat: 48.6185027, lng: 20.0420676 },
    { lat: 48.6178315, lng: 20.043939 },
    { lat: 48.6177587, lng: 20.04466 },
    { lat: 48.6177817, lng: 20.045158 },
    { lat: 48.6176648, lng: 20.0459526 },
    { lat: 48.6176762, lng: 20.0465218 },
    { lat: 48.6171766, lng: 20.0471616 },
    { lat: 48.6170194, lng: 20.0474456 },
    { lat: 48.6169036, lng: 20.0480175 },
    { lat: 48.6169894, lng: 20.0485395 },
    { lat: 48.61711, lng: 20.0485715 },
    { lat: 48.6174482, lng: 20.0494203 },
    { lat: 48.617548, lng: 20.049816 },
    { lat: 48.6176368, lng: 20.0504901 },
    { lat: 48.6179792, lng: 20.0517116 },
    { lat: 48.6180154, lng: 20.0547066 },
    { lat: 48.6189869, lng: 20.0568717 },
    { lat: 48.6196103, lng: 20.057918 },
    { lat: 48.6199951, lng: 20.058781 },
    { lat: 48.6202854, lng: 20.0589901 },
    { lat: 48.6205604, lng: 20.059374 },
    { lat: 48.6207292, lng: 20.0599395 },
    { lat: 48.6211007, lng: 20.0605378 },
    { lat: 48.6218612, lng: 20.0613324 },
    { lat: 48.6223471, lng: 20.0620205 },
    { lat: 48.6225744, lng: 20.0626431 },
    { lat: 48.62273, lng: 20.0628368 },
    { lat: 48.6227404, lng: 20.0634709 },
    { lat: 48.6224067, lng: 20.0648769 },
    { lat: 48.6224573, lng: 20.0657245 },
    { lat: 48.6225517, lng: 20.0661535 },
    { lat: 48.6225217, lng: 20.066518 },
    { lat: 48.6219515, lng: 20.0677653 },
    { lat: 48.6219079, lng: 20.068019 },
    { lat: 48.6219247, lng: 20.0686436 },
    { lat: 48.6218723, lng: 20.0689569 },
    { lat: 48.6218727, lng: 20.0693495 },
    { lat: 48.6220218, lng: 20.0696623 },
    { lat: 48.622129, lng: 20.0700471 },
    { lat: 48.6220499, lng: 20.0703675 },
    { lat: 48.6220839, lng: 20.0706515 },
    { lat: 48.622117, lng: 20.0709283 },
    { lat: 48.621903, lng: 20.0721321 },
    { lat: 48.621925, lng: 20.0722298 },
    { lat: 48.6220798, lng: 20.0724011 },
    { lat: 48.6222194, lng: 20.0724043 },
    { lat: 48.6222383, lng: 20.0724204 },
    { lat: 48.6224175, lng: 20.072291 },
    { lat: 48.6227, lng: 20.0719573 },
    { lat: 48.6228333, lng: 20.0717215 },
    { lat: 48.6228643, lng: 20.0715687 },
    { lat: 48.6233094, lng: 20.0722048 },
    { lat: 48.6234724, lng: 20.0719773 },
    { lat: 48.6241288, lng: 20.0706062 },
    { lat: 48.6244621, lng: 20.0702449 },
    { lat: 48.6252585, lng: 20.0696759 },
    { lat: 48.6258313, lng: 20.0690916 },
    { lat: 48.6266653, lng: 20.0680633 },
    { lat: 48.6266383, lng: 20.0679822 },
    { lat: 48.6274271, lng: 20.0674227 },
    { lat: 48.6280887, lng: 20.0664515 },
    { lat: 48.6283395, lng: 20.0663564 },
    { lat: 48.6287091, lng: 20.0664427 },
    { lat: 48.6297921, lng: 20.065826 },
    { lat: 48.6308809, lng: 20.0661171 },
    { lat: 48.631005, lng: 20.0663686 },
    { lat: 48.6311527, lng: 20.0664818 },
    { lat: 48.6315293, lng: 20.0664898 },
    { lat: 48.6316659, lng: 20.0663631 },
    { lat: 48.6316902, lng: 20.0664088 },
    { lat: 48.6320725, lng: 20.0662023 },
    { lat: 48.6322277, lng: 20.0661938 },
    { lat: 48.6322452, lng: 20.0661245 },
    { lat: 48.6324091, lng: 20.066196 },
    { lat: 48.6326721, lng: 20.0661296 },
    { lat: 48.6331056, lng: 20.0655792 },
    { lat: 48.6331793, lng: 20.0657507 },
    { lat: 48.6334715, lng: 20.0657101 },
    { lat: 48.6340338, lng: 20.0651416 },
    { lat: 48.6344524, lng: 20.0648066 },
    { lat: 48.6349143, lng: 20.064865 },
    { lat: 48.6352789, lng: 20.0648001 },
    { lat: 48.6353043, lng: 20.0641873 },
    { lat: 48.6354899, lng: 20.0641247 },
    { lat: 48.635678, lng: 20.0639652 },
  ],
  Prihradzany: [
    { lat: 48.5941095, lng: 20.229445 },
    { lat: 48.5940824, lng: 20.2294457 },
    { lat: 48.5936294, lng: 20.229299 },
    { lat: 48.593352, lng: 20.2294042 },
    { lat: 48.5929961, lng: 20.2294275 },
    { lat: 48.5926786, lng: 20.229377 },
    { lat: 48.5925691, lng: 20.22923 },
    { lat: 48.5921556, lng: 20.2293355 },
    { lat: 48.5906981, lng: 20.2292784 },
    { lat: 48.5898573, lng: 20.228582 },
    { lat: 48.5891545, lng: 20.2282448 },
    { lat: 48.5888929, lng: 20.2281537 },
    { lat: 48.5881091, lng: 20.2280942 },
    { lat: 48.5878452, lng: 20.2281459 },
    { lat: 48.587421, lng: 20.2284074 },
    { lat: 48.5870648, lng: 20.2289089 },
    { lat: 48.5868076, lng: 20.2295733 },
    { lat: 48.5864736, lng: 20.2298661 },
    { lat: 48.5852837, lng: 20.2305883 },
    { lat: 48.5851135, lng: 20.2307535 },
    { lat: 48.5849036, lng: 20.2307676 },
    { lat: 48.584589, lng: 20.2305912 },
    { lat: 48.5843939, lng: 20.2301059 },
    { lat: 48.5843535, lng: 20.2295145 },
    { lat: 48.584239, lng: 20.2291588 },
    { lat: 48.5823404, lng: 20.2295021 },
    { lat: 48.5819967, lng: 20.2296136 },
    { lat: 48.5817614, lng: 20.2298658 },
    { lat: 48.5812596, lng: 20.2299056 },
    { lat: 48.5811339, lng: 20.2299732 },
    { lat: 48.5805756, lng: 20.2297567 },
    { lat: 48.580123, lng: 20.2293076 },
    { lat: 48.5797275, lng: 20.2291333 },
    { lat: 48.5794205, lng: 20.2292283 },
    { lat: 48.5787964, lng: 20.2290829 },
    { lat: 48.577033, lng: 20.2295971 },
    { lat: 48.5767032, lng: 20.2308465 },
    { lat: 48.576447, lng: 20.2312282 },
    { lat: 48.5763038, lng: 20.2315901 },
    { lat: 48.5759967, lng: 20.2320216 },
    { lat: 48.5756688, lng: 20.2326933 },
    { lat: 48.5755375, lng: 20.2330149 },
    { lat: 48.5753846, lng: 20.2336721 },
    { lat: 48.5748769, lng: 20.2345824 },
    { lat: 48.5747754, lng: 20.2349194 },
    { lat: 48.5742502, lng: 20.236125 },
    { lat: 48.5740187, lng: 20.2370123 },
    { lat: 48.5734747, lng: 20.2376042 },
    { lat: 48.5733144, lng: 20.2377087 },
    { lat: 48.5729136, lng: 20.2378794 },
    { lat: 48.5725328, lng: 20.2379345 },
    { lat: 48.5719271, lng: 20.2382215 },
    { lat: 48.5716011, lng: 20.2385447 },
    { lat: 48.5714299, lng: 20.2389991 },
    { lat: 48.5711409, lng: 20.2394943 },
    { lat: 48.570826, lng: 20.2414527 },
    { lat: 48.5700153, lng: 20.2439171 },
    { lat: 48.5695488, lng: 20.2457792 },
    { lat: 48.5693554, lng: 20.2460684 },
    { lat: 48.5690753, lng: 20.2467867 },
    { lat: 48.5688422, lng: 20.2471794 },
    { lat: 48.5687975, lng: 20.2474195 },
    { lat: 48.5690136, lng: 20.248464 },
    { lat: 48.5691366, lng: 20.2487252 },
    { lat: 48.5694499, lng: 20.2497787 },
    { lat: 48.5695334, lng: 20.25029 },
    { lat: 48.569709, lng: 20.2508001 },
    { lat: 48.5698964, lng: 20.2521437 },
    { lat: 48.5698863, lng: 20.2523686 },
    { lat: 48.5700827, lng: 20.2528331 },
    { lat: 48.5703251, lng: 20.253179 },
    { lat: 48.5703821, lng: 20.2535685 },
    { lat: 48.5705697, lng: 20.2539783 },
    { lat: 48.5709353, lng: 20.2545912 },
    { lat: 48.571423, lng: 20.2560383 },
    { lat: 48.5715191, lng: 20.2563084 },
    { lat: 48.5716938, lng: 20.2564513 },
    { lat: 48.572269, lng: 20.2570132 },
    { lat: 48.5727676, lng: 20.2580277 },
    { lat: 48.5731173, lng: 20.2585805 },
    { lat: 48.5734904, lng: 20.2600369 },
    { lat: 48.5750639, lng: 20.2602128 },
    { lat: 48.5753346, lng: 20.2603076 },
    { lat: 48.5763768, lng: 20.2610295 },
    { lat: 48.5769744, lng: 20.261782 },
    { lat: 48.5774873, lng: 20.2620819 },
    { lat: 48.5774318, lng: 20.2625553 },
    { lat: 48.5780211, lng: 20.2654645 },
    { lat: 48.5782957, lng: 20.2658004 },
    { lat: 48.5786068, lng: 20.2660057 },
    { lat: 48.5790427, lng: 20.2660344 },
    { lat: 48.5791078, lng: 20.2660268 },
    { lat: 48.5791054, lng: 20.2656578 },
    { lat: 48.5789886, lng: 20.2633882 },
    { lat: 48.5789154, lng: 20.2625074 },
    { lat: 48.5786818, lng: 20.2607092 },
    { lat: 48.5786967, lng: 20.2604842 },
    { lat: 48.5805661, lng: 20.2600587 },
    { lat: 48.5815439, lng: 20.2599535 },
    { lat: 48.5818353, lng: 20.2585814 },
    { lat: 48.5803386, lng: 20.2588901 },
    { lat: 48.5787606, lng: 20.2593688 },
    { lat: 48.5787698, lng: 20.2567652 },
    { lat: 48.5787082, lng: 20.2551283 },
    { lat: 48.5787284, lng: 20.254699 },
    { lat: 48.5793869, lng: 20.2537853 },
    { lat: 48.5799664, lng: 20.2532909 },
    { lat: 48.5805414, lng: 20.2532749 },
    { lat: 48.5807797, lng: 20.2532072 },
    { lat: 48.5814101, lng: 20.2527744 },
    { lat: 48.582077, lng: 20.2526676 },
    { lat: 48.5827102, lng: 20.2529897 },
    { lat: 48.5827988, lng: 20.2530941 },
    { lat: 48.5843998, lng: 20.2532769 },
    { lat: 48.5845267, lng: 20.2542031 },
    { lat: 48.5845907, lng: 20.2542268 },
    { lat: 48.5880783, lng: 20.2547343 },
    { lat: 48.5888871, lng: 20.255074 },
    { lat: 48.5899506, lng: 20.255029 },
    { lat: 48.5905719, lng: 20.2551053 },
    { lat: 48.5905168, lng: 20.2549342 },
    { lat: 48.5902813, lng: 20.253854 },
    { lat: 48.5902597, lng: 20.2533989 },
    { lat: 48.5901711, lng: 20.2530397 },
    { lat: 48.5899943, lng: 20.2526121 },
    { lat: 48.5898673, lng: 20.2525036 },
    { lat: 48.58972, lng: 20.251831 },
    { lat: 48.590192, lng: 20.2497945 },
    { lat: 48.5907345, lng: 20.2486818 },
    { lat: 48.5912732, lng: 20.2482041 },
    { lat: 48.5913669, lng: 20.2480459 },
    { lat: 48.5915309, lng: 20.2471011 },
    { lat: 48.5920667, lng: 20.2458165 },
    { lat: 48.5923654, lng: 20.2453992 },
    { lat: 48.592453, lng: 20.2450561 },
    { lat: 48.59224, lng: 20.2442557 },
    { lat: 48.5922529, lng: 20.2438999 },
    { lat: 48.5921597, lng: 20.2433113 },
    { lat: 48.5921592, lng: 20.2429922 },
    { lat: 48.5924556, lng: 20.2416392 },
    { lat: 48.5925492, lng: 20.2414112 },
    { lat: 48.5927784, lng: 20.2411172 },
    { lat: 48.5928962, lng: 20.240791 },
    { lat: 48.5927276, lng: 20.2403807 },
    { lat: 48.592688, lng: 20.2397539 },
    { lat: 48.5924676, lng: 20.2391961 },
    { lat: 48.5924192, lng: 20.2389123 },
    { lat: 48.5924321, lng: 20.2381338 },
    { lat: 48.592508, lng: 20.2374965 },
    { lat: 48.5924457, lng: 20.2363011 },
    { lat: 48.5924766, lng: 20.2356917 },
    { lat: 48.5928052, lng: 20.2349191 },
    { lat: 48.5934609, lng: 20.2344459 },
    { lat: 48.5935996, lng: 20.2342256 },
    { lat: 48.5941442, lng: 20.232603 },
    { lat: 48.5944421, lng: 20.232138 },
    { lat: 48.594871, lng: 20.2316541 },
    { lat: 48.5951104, lng: 20.2313008 },
    { lat: 48.5952744, lng: 20.230892 },
    { lat: 48.5952626, lng: 20.2308814 },
    { lat: 48.5941095, lng: 20.229445 },
  ],
  Chyžné: [
    { lat: 48.6547849, lng: 20.204432 },
    { lat: 48.6550345, lng: 20.2045934 },
    { lat: 48.6551297, lng: 20.2047497 },
    { lat: 48.6553596, lng: 20.2048922 },
    { lat: 48.6557417, lng: 20.2052933 },
    { lat: 48.6558898, lng: 20.2056428 },
    { lat: 48.6574506, lng: 20.2079729 },
    { lat: 48.6575311, lng: 20.2086342 },
    { lat: 48.657963, lng: 20.2089589 },
    { lat: 48.65794, lng: 20.2096745 },
    { lat: 48.6580015, lng: 20.2098905 },
    { lat: 48.6583634, lng: 20.2102932 },
    { lat: 48.6583356, lng: 20.2110082 },
    { lat: 48.6585415, lng: 20.21117 },
    { lat: 48.6587138, lng: 20.2111123 },
    { lat: 48.6587719, lng: 20.2112124 },
    { lat: 48.6589768, lng: 20.2112151 },
    { lat: 48.6592967, lng: 20.211016 },
    { lat: 48.6594348, lng: 20.2110636 },
    { lat: 48.6594649, lng: 20.2111987 },
    { lat: 48.6595849, lng: 20.2113531 },
    { lat: 48.6596285, lng: 20.2113174 },
    { lat: 48.6597333, lng: 20.2113713 },
    { lat: 48.6598103, lng: 20.2112421 },
    { lat: 48.6599561, lng: 20.2112829 },
    { lat: 48.6600308, lng: 20.2114614 },
    { lat: 48.6603494, lng: 20.2116575 },
    { lat: 48.6605017, lng: 20.2118602 },
    { lat: 48.660545, lng: 20.2120087 },
    { lat: 48.6606994, lng: 20.2120406 },
    { lat: 48.6607551, lng: 20.212193 },
    { lat: 48.6610737, lng: 20.2120806 },
    { lat: 48.661113, lng: 20.2119067 },
    { lat: 48.6612191, lng: 20.2117998 },
    { lat: 48.6612353, lng: 20.2120384 },
    { lat: 48.6613305, lng: 20.2121348 },
    { lat: 48.6614234, lng: 20.2120095 },
    { lat: 48.6615242, lng: 20.2121631 },
    { lat: 48.661644, lng: 20.2120892 },
    { lat: 48.6618201, lng: 20.2122441 },
    { lat: 48.6618842, lng: 20.2122119 },
    { lat: 48.662051, lng: 20.2124646 },
    { lat: 48.6620004, lng: 20.2125522 },
    { lat: 48.6623152, lng: 20.2125399 },
    { lat: 48.66254, lng: 20.2124464 },
    { lat: 48.6626169, lng: 20.2123699 },
    { lat: 48.6627496, lng: 20.2119991 },
    { lat: 48.6628376, lng: 20.2120591 },
    { lat: 48.662875, lng: 20.2121722 },
    { lat: 48.6628219, lng: 20.2122037 },
    { lat: 48.662851, lng: 20.2123333 },
    { lat: 48.6630696, lng: 20.212252 },
    { lat: 48.6630829, lng: 20.2123324 },
    { lat: 48.6631352, lng: 20.2123462 },
    { lat: 48.6631538, lng: 20.2122469 },
    { lat: 48.6632957, lng: 20.2120522 },
    { lat: 48.663424, lng: 20.2121029 },
    { lat: 48.6634542, lng: 20.2120449 },
    { lat: 48.663614, lng: 20.2121219 },
    { lat: 48.6636073, lng: 20.2122077 },
    { lat: 48.6636942, lng: 20.2122436 },
    { lat: 48.6637879, lng: 20.212193 },
    { lat: 48.6638266, lng: 20.2122867 },
    { lat: 48.6639978, lng: 20.2122879 },
    { lat: 48.664046, lng: 20.2122247 },
    { lat: 48.6640375, lng: 20.2120958 },
    { lat: 48.6642836, lng: 20.2120812 },
    { lat: 48.6643873, lng: 20.2121679 },
    { lat: 48.6645018, lng: 20.2120723 },
    { lat: 48.6648173, lng: 20.2123019 },
    { lat: 48.6648671, lng: 20.2122257 },
    { lat: 48.6649856, lng: 20.2122031 },
    { lat: 48.665236, lng: 20.2123813 },
    { lat: 48.6656289, lng: 20.2121256 },
    { lat: 48.6657972, lng: 20.2122211 },
    { lat: 48.6660399, lng: 20.2122335 },
    { lat: 48.6662019, lng: 20.2123442 },
    { lat: 48.6663754, lng: 20.2127691 },
    { lat: 48.6665138, lng: 20.2128188 },
    { lat: 48.6677968, lng: 20.2127688 },
    { lat: 48.6682556, lng: 20.2124958 },
    { lat: 48.6684572, lng: 20.2124973 },
    { lat: 48.6686989, lng: 20.2128224 },
    { lat: 48.6687815, lng: 20.2128362 },
    { lat: 48.6688365, lng: 20.212688 },
    { lat: 48.6689518, lng: 20.2127024 },
    { lat: 48.6689823, lng: 20.2128179 },
    { lat: 48.6690982, lng: 20.2128989 },
    { lat: 48.669381, lng: 20.2129312 },
    { lat: 48.6697569, lng: 20.2131186 },
    { lat: 48.6705139, lng: 20.2141969 },
    { lat: 48.6705058, lng: 20.2143598 },
    { lat: 48.6709202, lng: 20.2148604 },
    { lat: 48.6718077, lng: 20.2152884 },
    { lat: 48.6719344, lng: 20.2152459 },
    { lat: 48.6721798, lng: 20.2153821 },
    { lat: 48.6724443, lng: 20.2153804 },
    { lat: 48.6726208, lng: 20.2152849 },
    { lat: 48.673122, lng: 20.215231 },
    { lat: 48.6733188, lng: 20.2153071 },
    { lat: 48.6735618, lng: 20.2155238 },
    { lat: 48.6736359, lng: 20.2154632 },
    { lat: 48.6738148, lng: 20.2155456 },
    { lat: 48.6739668, lng: 20.215458 },
    { lat: 48.6740425, lng: 20.2154924 },
    { lat: 48.6743383, lng: 20.2152591 },
    { lat: 48.6744777, lng: 20.2152222 },
    { lat: 48.6748918, lng: 20.215579 },
    { lat: 48.6750331, lng: 20.2156185 },
    { lat: 48.6753711, lng: 20.2154833 },
    { lat: 48.6757204, lng: 20.215543 },
    { lat: 48.6775038, lng: 20.2165751 },
    { lat: 48.6780501, lng: 20.2170239 },
    { lat: 48.678312, lng: 20.2173521 },
    { lat: 48.6796783, lng: 20.2185135 },
    { lat: 48.680111, lng: 20.2194499 },
    { lat: 48.6805249, lng: 20.2199992 },
    { lat: 48.6815184, lng: 20.221072 },
    { lat: 48.6826871, lng: 20.2226465 },
    { lat: 48.6829178, lng: 20.2230389 },
    { lat: 48.6842085, lng: 20.2235734 },
    { lat: 48.6846307, lng: 20.2240514 },
    { lat: 48.6852803, lng: 20.2246406 },
    { lat: 48.6858696, lng: 20.2247877 },
    { lat: 48.6861661, lng: 20.2249393 },
    { lat: 48.6868576, lng: 20.2257575 },
    { lat: 48.6874076, lng: 20.2260191 },
    { lat: 48.687577, lng: 20.2260004 },
    { lat: 48.6884092, lng: 20.2253815 },
    { lat: 48.688945, lng: 20.2248088 },
    { lat: 48.6892839, lng: 20.2247206 },
    { lat: 48.6894872, lng: 20.2248183 },
    { lat: 48.6898784, lng: 20.225224 },
    { lat: 48.6903717, lng: 20.2259894 },
    { lat: 48.6906648, lng: 20.2261973 },
    { lat: 48.6914736, lng: 20.2263986 },
    { lat: 48.6917968, lng: 20.2263125 },
    { lat: 48.6920315, lng: 20.2255052 },
    { lat: 48.6921847, lng: 20.2253326 },
    { lat: 48.6922669, lng: 20.2253604 },
    { lat: 48.6928604, lng: 20.2258141 },
    { lat: 48.6934262, lng: 20.2267586 },
    { lat: 48.6939742, lng: 20.2272257 },
    { lat: 48.6943849, lng: 20.227245 },
    { lat: 48.6948583, lng: 20.227027 },
    { lat: 48.69543, lng: 20.2270755 },
    { lat: 48.6956884, lng: 20.2274549 },
    { lat: 48.6961006, lng: 20.2284113 },
    { lat: 48.6979791, lng: 20.2307329 },
    { lat: 48.6981905, lng: 20.2305843 },
    { lat: 48.7008787, lng: 20.2336376 },
    { lat: 48.703752, lng: 20.233868 },
    { lat: 48.705858, lng: 20.235374 },
    { lat: 48.706601, lng: 20.234145 },
    { lat: 48.707697, lng: 20.233168 },
    { lat: 48.709033, lng: 20.230624 },
    { lat: 48.709706, lng: 20.229228 },
    { lat: 48.709893, lng: 20.228949 },
    { lat: 48.710481, lng: 20.228068 },
    { lat: 48.710637, lng: 20.227713 },
    { lat: 48.710655, lng: 20.227584 },
    { lat: 48.710675, lng: 20.227442 },
    { lat: 48.710731, lng: 20.227059 },
    { lat: 48.71112, lng: 20.226211 },
    { lat: 48.711226, lng: 20.225864 },
    { lat: 48.711395, lng: 20.225493 },
    { lat: 48.711645, lng: 20.225005 },
    { lat: 48.711896, lng: 20.224549 },
    { lat: 48.712197, lng: 20.224189 },
    { lat: 48.713049, lng: 20.223416 },
    { lat: 48.713412, lng: 20.223116 },
    { lat: 48.713639, lng: 20.22294 },
    { lat: 48.713797, lng: 20.222721 },
    { lat: 48.713999, lng: 20.222411 },
    { lat: 48.71414, lng: 20.222161 },
    { lat: 48.714214, lng: 20.221934 },
    { lat: 48.714776, lng: 20.221142 },
    { lat: 48.71493, lng: 20.22093 },
    { lat: 48.715702, lng: 20.219786 },
    { lat: 48.716031, lng: 20.219239 },
    { lat: 48.716169, lng: 20.219011 },
    { lat: 48.716498, lng: 20.218133 },
    { lat: 48.716763, lng: 20.217729 },
    { lat: 48.71695, lng: 20.217445 },
    { lat: 48.717063, lng: 20.217272 },
    { lat: 48.717313, lng: 20.216954 },
    { lat: 48.717408, lng: 20.216901 },
    { lat: 48.717484, lng: 20.216857 },
    { lat: 48.717607, lng: 20.216787 },
    { lat: 48.71798, lng: 20.216684 },
    { lat: 48.718081, lng: 20.216626 },
    { lat: 48.718502, lng: 20.216383 },
    { lat: 48.718645, lng: 20.216288 },
    { lat: 48.718953, lng: 20.21577 },
    { lat: 48.718992, lng: 20.215719 },
    { lat: 48.719084, lng: 20.2156 },
    { lat: 48.719329, lng: 20.215283 },
    { lat: 48.719547, lng: 20.214592 },
    { lat: 48.719766, lng: 20.214116 },
    { lat: 48.719815, lng: 20.213864 },
    { lat: 48.719815, lng: 20.21363 },
    { lat: 48.720199, lng: 20.212948 },
    { lat: 48.720509, lng: 20.212535 },
    { lat: 48.720739, lng: 20.211961 },
    { lat: 48.721198, lng: 20.211243 },
    { lat: 48.721395, lng: 20.210872 },
    { lat: 48.722031, lng: 20.209728 },
    { lat: 48.72204, lng: 20.209134 },
    { lat: 48.722013, lng: 20.208519 },
    { lat: 48.722059, lng: 20.207954 },
    { lat: 48.722083, lng: 20.207723 },
    { lat: 48.722063, lng: 20.207539 },
    { lat: 48.722097, lng: 20.207311 },
    { lat: 48.722, lng: 20.206397 },
    { lat: 48.721953, lng: 20.205853 },
    { lat: 48.72189, lng: 20.205387 },
    { lat: 48.721833, lng: 20.204851 },
    { lat: 48.721847, lng: 20.204125 },
    { lat: 48.721799, lng: 20.203882 },
    { lat: 48.721736, lng: 20.202638 },
    { lat: 48.721586, lng: 20.202044 },
    { lat: 48.72118, lng: 20.201068 },
    { lat: 48.721043, lng: 20.200623 },
    { lat: 48.720871, lng: 20.200359 },
    { lat: 48.720397, lng: 20.199141 },
    { lat: 48.720237, lng: 20.198267 },
    { lat: 48.720231, lng: 20.198036 },
    { lat: 48.720187, lng: 20.196615 },
    { lat: 48.720108, lng: 20.195253 },
    { lat: 48.719993, lng: 20.194167 },
    { lat: 48.719987, lng: 20.194124 },
    { lat: 48.719937, lng: 20.193644 },
    { lat: 48.719845, lng: 20.192792 },
    { lat: 48.719843, lng: 20.192776 },
    { lat: 48.719834, lng: 20.19269 },
    { lat: 48.720113, lng: 20.190903 },
    { lat: 48.7216812, lng: 20.1895729 },
    { lat: 48.7222811, lng: 20.1884981 },
    { lat: 48.7228393, lng: 20.1862432 },
    { lat: 48.7232647, lng: 20.1849503 },
    { lat: 48.7235497, lng: 20.1843884 },
    { lat: 48.7238202, lng: 20.1832742 },
    { lat: 48.7238679, lng: 20.1811292 },
    { lat: 48.723676, lng: 20.1802779 },
    { lat: 48.7233631, lng: 20.1796392 },
    { lat: 48.7227301, lng: 20.1779906 },
    { lat: 48.7224036, lng: 20.1779047 },
    { lat: 48.722151, lng: 20.1779261 },
    { lat: 48.7220551, lng: 20.1778654 },
    { lat: 48.7217552, lng: 20.177441 },
    { lat: 48.7209977, lng: 20.1768608 },
    { lat: 48.7204558, lng: 20.1763581 },
    { lat: 48.7189625, lng: 20.1773105 },
    { lat: 48.7182215, lng: 20.177283 },
    { lat: 48.7176863, lng: 20.1775598 },
    { lat: 48.7170325, lng: 20.1775411 },
    { lat: 48.7162755, lng: 20.1777073 },
    { lat: 48.7158206, lng: 20.177569 },
    { lat: 48.7156759, lng: 20.1776098 },
    { lat: 48.7151943, lng: 20.1775635 },
    { lat: 48.7145609, lng: 20.1776132 },
    { lat: 48.71433, lng: 20.1777514 },
    { lat: 48.7138621, lng: 20.1776824 },
    { lat: 48.7133396, lng: 20.1774327 },
    { lat: 48.7128801, lng: 20.1774089 },
    { lat: 48.7126357, lng: 20.1773226 },
    { lat: 48.7119977, lng: 20.1774508 },
    { lat: 48.7116297, lng: 20.1776533 },
    { lat: 48.710991, lng: 20.1778165 },
    { lat: 48.7102706, lng: 20.1776306 },
    { lat: 48.7096707, lng: 20.1776665 },
    { lat: 48.7091832, lng: 20.177364 },
    { lat: 48.7088487, lng: 20.1768591 },
    { lat: 48.7084331, lng: 20.1764969 },
    { lat: 48.7081652, lng: 20.1764195 },
    { lat: 48.7064524, lng: 20.1752751 },
    { lat: 48.7061358, lng: 20.1749958 },
    { lat: 48.7054439, lng: 20.1746522 },
    { lat: 48.7037519, lng: 20.1736076 },
    { lat: 48.7029662, lng: 20.1727828 },
    { lat: 48.7025847, lng: 20.1720895 },
    { lat: 48.7021655, lng: 20.1715543 },
    { lat: 48.7020494, lng: 20.1712983 },
    { lat: 48.7016364, lng: 20.1707477 },
    { lat: 48.7014709, lng: 20.1706641 },
    { lat: 48.7002044, lng: 20.1708499 },
    { lat: 48.6998883, lng: 20.1710237 },
    { lat: 48.6995103, lng: 20.1710913 },
    { lat: 48.6991785, lng: 20.171324 },
    { lat: 48.6988106, lng: 20.1715008 },
    { lat: 48.6982437, lng: 20.1720233 },
    { lat: 48.6975813, lng: 20.1727737 },
    { lat: 48.6969115, lng: 20.1732582 },
    { lat: 48.6968598, lng: 20.1739304 },
    { lat: 48.6966696, lng: 20.1750203 },
    { lat: 48.6965679, lng: 20.1752392 },
    { lat: 48.6961673, lng: 20.1767289 },
    { lat: 48.696226, lng: 20.1774607 },
    { lat: 48.6960195, lng: 20.1796284 },
    { lat: 48.6949916, lng: 20.1821881 },
    { lat: 48.6946776, lng: 20.1828055 },
    { lat: 48.6946471, lng: 20.1834208 },
    { lat: 48.6945401, lng: 20.1840651 },
    { lat: 48.6941598, lng: 20.1844897 },
    { lat: 48.6938736, lng: 20.1850778 },
    { lat: 48.6934519, lng: 20.1855963 },
    { lat: 48.6925628, lng: 20.1860636 },
    { lat: 48.692029, lng: 20.1865122 },
    { lat: 48.6913837, lng: 20.1864573 },
    { lat: 48.6907445, lng: 20.1865833 },
    { lat: 48.6903383, lng: 20.1867575 },
    { lat: 48.6899038, lng: 20.1870992 },
    { lat: 48.6891582, lng: 20.187996 },
    { lat: 48.6883891, lng: 20.1886714 },
    { lat: 48.6876084, lng: 20.1899004 },
    { lat: 48.6873766, lng: 20.1902161 },
    { lat: 48.6870649, lng: 20.1904991 },
    { lat: 48.6867175, lng: 20.1911337 },
    { lat: 48.6862654, lng: 20.191682 },
    { lat: 48.6861053, lng: 20.191984 },
    { lat: 48.6855657, lng: 20.1926297 },
    { lat: 48.6850931, lng: 20.1935363 },
    { lat: 48.6848689, lng: 20.1937835 },
    { lat: 48.6841556, lng: 20.1937176 },
    { lat: 48.6836144, lng: 20.1933694 },
    { lat: 48.6834154, lng: 20.1933203 },
    { lat: 48.6833543, lng: 20.1931444 },
    { lat: 48.683023, lng: 20.1929467 },
    { lat: 48.6817331, lng: 20.1928784 },
    { lat: 48.6804725, lng: 20.1930416 },
    { lat: 48.6800515, lng: 20.1929369 },
    { lat: 48.6793546, lng: 20.1926308 },
    { lat: 48.6787758, lng: 20.1936977 },
    { lat: 48.6785223, lng: 20.1938919 },
    { lat: 48.6775367, lng: 20.1942541 },
    { lat: 48.6767659, lng: 20.1943314 },
    { lat: 48.6758078, lng: 20.193885 },
    { lat: 48.6753764, lng: 20.193603 },
    { lat: 48.6752501, lng: 20.1936035 },
    { lat: 48.6752301, lng: 20.1936638 },
    { lat: 48.6745492, lng: 20.1931691 },
    { lat: 48.6741715, lng: 20.1938077 },
    { lat: 48.6740887, lng: 20.193625 },
    { lat: 48.6737825, lng: 20.1934589 },
    { lat: 48.6734048, lng: 20.1929172 },
    { lat: 48.6719431, lng: 20.1912918 },
    { lat: 48.6706182, lng: 20.1902184 },
    { lat: 48.6701322, lng: 20.1897291 },
    { lat: 48.6701049, lng: 20.1897794 },
    { lat: 48.6696737, lng: 20.1893321 },
    { lat: 48.6688976, lng: 20.1886896 },
    { lat: 48.6681676, lng: 20.1878446 },
    { lat: 48.6675808, lng: 20.1873073 },
    { lat: 48.6670388, lng: 20.1869116 },
    { lat: 48.6662314, lng: 20.186324 },
    { lat: 48.6646662, lng: 20.1915709 },
    { lat: 48.6646861, lng: 20.1918135 },
    { lat: 48.6641938, lng: 20.1917549 },
    { lat: 48.6633274, lng: 20.1918008 },
    { lat: 48.6621382, lng: 20.190962 },
    { lat: 48.6616922, lng: 20.1908532 },
    { lat: 48.6613386, lng: 20.1923947 },
    { lat: 48.6612803, lng: 20.1924895 },
    { lat: 48.6607746, lng: 20.1919811 },
    { lat: 48.6602088, lng: 20.1937098 },
    { lat: 48.6591692, lng: 20.1963425 },
    { lat: 48.6587019, lng: 20.1983367 },
    { lat: 48.6583612, lng: 20.1987444 },
    { lat: 48.6575634, lng: 20.1992114 },
    { lat: 48.6567426, lng: 20.2005667 },
    { lat: 48.6552083, lng: 20.2033073 },
    { lat: 48.6549063, lng: 20.2040918 },
    { lat: 48.6547849, lng: 20.204432 },
  ],
  Polina: [
    { lat: 48.4992492, lng: 20.2493901 },
    { lat: 48.4994306, lng: 20.2495896 },
    { lat: 48.4999645, lng: 20.2499268 },
    { lat: 48.5005847, lng: 20.2500641 },
    { lat: 48.5015978, lng: 20.2496531 },
    { lat: 48.5025143, lng: 20.2491334 },
    { lat: 48.5031847, lng: 20.248836 },
    { lat: 48.5050916, lng: 20.2482127 },
    { lat: 48.5052014, lng: 20.2468458 },
    { lat: 48.5053156, lng: 20.2465126 },
    { lat: 48.5052318, lng: 20.2459676 },
    { lat: 48.5051569, lng: 20.2457822 },
    { lat: 48.5052496, lng: 20.2456797 },
    { lat: 48.5053115, lng: 20.2454213 },
    { lat: 48.5052037, lng: 20.2454054 },
    { lat: 48.5049847, lng: 20.2447419 },
    { lat: 48.5052381, lng: 20.244291 },
    { lat: 48.5051884, lng: 20.2440565 },
    { lat: 48.5052072, lng: 20.2439653 },
    { lat: 48.5052867, lng: 20.2439823 },
    { lat: 48.5054411, lng: 20.2438181 },
    { lat: 48.5054596, lng: 20.2436595 },
    { lat: 48.5053461, lng: 20.2434342 },
    { lat: 48.505448, lng: 20.2432254 },
    { lat: 48.5055827, lng: 20.2431849 },
    { lat: 48.5056151, lng: 20.2427828 },
    { lat: 48.5057815, lng: 20.2427763 },
    { lat: 48.5059653, lng: 20.2425267 },
    { lat: 48.5059676, lng: 20.2423894 },
    { lat: 48.5058662, lng: 20.2422447 },
    { lat: 48.5059275, lng: 20.2417221 },
    { lat: 48.5061044, lng: 20.2414124 },
    { lat: 48.5062552, lng: 20.2412979 },
    { lat: 48.5062152, lng: 20.2408871 },
    { lat: 48.5063084, lng: 20.240852 },
    { lat: 48.5064118, lng: 20.2410117 },
    { lat: 48.506489, lng: 20.2410008 },
    { lat: 48.5065733, lng: 20.2407709 },
    { lat: 48.506717, lng: 20.2407583 },
    { lat: 48.5068302, lng: 20.2406319 },
    { lat: 48.5069224, lng: 20.2404052 },
    { lat: 48.5068393, lng: 20.2403285 },
    { lat: 48.5069325, lng: 20.2399248 },
    { lat: 48.5070589, lng: 20.2399584 },
    { lat: 48.5070528, lng: 20.2401128 },
    { lat: 48.5071847, lng: 20.2401765 },
    { lat: 48.5072348, lng: 20.2400477 },
    { lat: 48.5072132, lng: 20.2399268 },
    { lat: 48.5072865, lng: 20.2397361 },
    { lat: 48.5074805, lng: 20.2395169 },
    { lat: 48.5074031, lng: 20.2392685 },
    { lat: 48.507432, lng: 20.2391775 },
    { lat: 48.5075993, lng: 20.2391041 },
    { lat: 48.5076366, lng: 20.2389093 },
    { lat: 48.507915, lng: 20.2387186 },
    { lat: 48.5080102, lng: 20.2385367 },
    { lat: 48.5082034, lng: 20.2384695 },
    { lat: 48.5082809, lng: 20.238319 },
    { lat: 48.5083764, lng: 20.2383463 },
    { lat: 48.5086192, lng: 20.238162 },
    { lat: 48.5088935, lng: 20.238091 },
    { lat: 48.5089793, lng: 20.2379011 },
    { lat: 48.5088584, lng: 20.2378925 },
    { lat: 48.5088239, lng: 20.2378157 },
    { lat: 48.5089043, lng: 20.2375459 },
    { lat: 48.5089524, lng: 20.237511 },
    { lat: 48.5089902, lng: 20.2376127 },
    { lat: 48.5091156, lng: 20.2372828 },
    { lat: 48.5092353, lng: 20.2373434 },
    { lat: 48.5092566, lng: 20.2371127 },
    { lat: 48.5091579, lng: 20.2369881 },
    { lat: 48.5091486, lng: 20.2366661 },
    { lat: 48.5092691, lng: 20.2365802 },
    { lat: 48.5092857, lng: 20.2364363 },
    { lat: 48.5093881, lng: 20.2362324 },
    { lat: 48.5094959, lng: 20.2361978 },
    { lat: 48.5096414, lng: 20.2359214 },
    { lat: 48.5096532, lng: 20.2357673 },
    { lat: 48.5095897, lng: 20.2354427 },
    { lat: 48.5096122, lng: 20.2353018 },
    { lat: 48.5097018, lng: 20.2353487 },
    { lat: 48.509847, lng: 20.2355977 },
    { lat: 48.509973, lng: 20.2353974 },
    { lat: 48.5099757, lng: 20.2352977 },
    { lat: 48.5099121, lng: 20.2352001 },
    { lat: 48.5099322, lng: 20.2351063 },
    { lat: 48.5101418, lng: 20.2351143 },
    { lat: 48.5101994, lng: 20.2350601 },
    { lat: 48.5101803, lng: 20.2349194 },
    { lat: 48.5102775, lng: 20.2348424 },
    { lat: 48.5103948, lng: 20.2349305 },
    { lat: 48.5105318, lng: 20.2349217 },
    { lat: 48.5104187, lng: 20.2334138 },
    { lat: 48.5102205, lng: 20.2322337 },
    { lat: 48.5097806, lng: 20.2313422 },
    { lat: 48.5093876, lng: 20.2308681 },
    { lat: 48.5084962, lng: 20.2295859 },
    { lat: 48.508118, lng: 20.2294469 },
    { lat: 48.5078614, lng: 20.2292261 },
    { lat: 48.5075074, lng: 20.2285439 },
    { lat: 48.5072857, lng: 20.2284059 },
    { lat: 48.5066076, lng: 20.2277002 },
    { lat: 48.506985, lng: 20.2267752 },
    { lat: 48.5073076, lng: 20.2249577 },
    { lat: 48.5073843, lng: 20.2244801 },
    { lat: 48.5073518, lng: 20.2232446 },
    { lat: 48.4961334, lng: 20.2142855 },
    { lat: 48.4950176, lng: 20.2135641 },
    { lat: 48.4950578, lng: 20.2131673 },
    { lat: 48.4953329, lng: 20.211771 },
    { lat: 48.4954509, lng: 20.2106708 },
    { lat: 48.4964878, lng: 20.2084179 },
    { lat: 48.4959285, lng: 20.2080902 },
    { lat: 48.4957923, lng: 20.2084307 },
    { lat: 48.4896784, lng: 20.2008282 },
    { lat: 48.4896964, lng: 20.2005482 },
    { lat: 48.4898807, lng: 20.2000592 },
    { lat: 48.4897606, lng: 20.1998727 },
    { lat: 48.4895887, lng: 20.1997996 },
    { lat: 48.4889418, lng: 20.1997886 },
    { lat: 48.4888146, lng: 20.1997079 },
    { lat: 48.4888513, lng: 20.1996098 },
    { lat: 48.4888185, lng: 20.1994689 },
    { lat: 48.4887023, lng: 20.1993812 },
    { lat: 48.4887712, lng: 20.1990522 },
    { lat: 48.488659, lng: 20.1988682 },
    { lat: 48.4887493, lng: 20.1976695 },
    { lat: 48.4889834, lng: 20.1964914 },
    { lat: 48.488934, lng: 20.1964362 },
    { lat: 48.4892479, lng: 20.195564 },
    { lat: 48.4894286, lng: 20.1946247 },
    { lat: 48.4873232, lng: 20.1921844 },
    { lat: 48.4850719, lng: 20.1893949 },
    { lat: 48.484378, lng: 20.188434 },
    { lat: 48.484327, lng: 20.188488 },
    { lat: 48.484212, lng: 20.18869 },
    { lat: 48.484204, lng: 20.188717 },
    { lat: 48.484184, lng: 20.188853 },
    { lat: 48.484111, lng: 20.189153 },
    { lat: 48.484047, lng: 20.189411 },
    { lat: 48.484036, lng: 20.18947 },
    { lat: 48.483978, lng: 20.189843 },
    { lat: 48.483961, lng: 20.19008 },
    { lat: 48.483949, lng: 20.190162 },
    { lat: 48.483929, lng: 20.190304 },
    { lat: 48.483911, lng: 20.190437 },
    { lat: 48.483908, lng: 20.190456 },
    { lat: 48.483875, lng: 20.190717 },
    { lat: 48.483871, lng: 20.191241 },
    { lat: 48.483871, lng: 20.191285 },
    { lat: 48.483839, lng: 20.19152 },
    { lat: 48.483813, lng: 20.191805 },
    { lat: 48.483742, lng: 20.192041 },
    { lat: 48.48367, lng: 20.192179 },
    { lat: 48.48354, lng: 20.192504 },
    { lat: 48.483462, lng: 20.192738 },
    { lat: 48.483439, lng: 20.192805 },
    { lat: 48.483393, lng: 20.192944 },
    { lat: 48.483299, lng: 20.193166 },
    { lat: 48.483278, lng: 20.193272 },
    { lat: 48.48325, lng: 20.193462 },
    { lat: 48.483227, lng: 20.193565 },
    { lat: 48.483139, lng: 20.193913 },
    { lat: 48.483039, lng: 20.194298 },
    { lat: 48.482962, lng: 20.194605 },
    { lat: 48.482946, lng: 20.194656 },
    { lat: 48.482938, lng: 20.19468 },
    { lat: 48.482911, lng: 20.194757 },
    { lat: 48.482835, lng: 20.194979 },
    { lat: 48.482821, lng: 20.195028 },
    { lat: 48.482691, lng: 20.195507 },
    { lat: 48.482624, lng: 20.195753 },
    { lat: 48.482568, lng: 20.1961 },
    { lat: 48.482552, lng: 20.196185 },
    { lat: 48.482473, lng: 20.196393 },
    { lat: 48.482472, lng: 20.196396 },
    { lat: 48.482289, lng: 20.196564 },
    { lat: 48.482245, lng: 20.1967 },
    { lat: 48.48221, lng: 20.196813 },
    { lat: 48.482044, lng: 20.197135 },
    { lat: 48.481928, lng: 20.197504 },
    { lat: 48.481909, lng: 20.197566 },
    { lat: 48.481703, lng: 20.197976 },
    { lat: 48.481563, lng: 20.198252 },
    { lat: 48.481535, lng: 20.198349 },
    { lat: 48.48153, lng: 20.198368 },
    { lat: 48.481518, lng: 20.198451 },
    { lat: 48.481446, lng: 20.198611 },
    { lat: 48.481419, lng: 20.19866 },
    { lat: 48.48133, lng: 20.198804 },
    { lat: 48.481319, lng: 20.198822 },
    { lat: 48.481226, lng: 20.198969 },
    { lat: 48.481188, lng: 20.199004 },
    { lat: 48.481051, lng: 20.199145 },
    { lat: 48.480917, lng: 20.199275 },
    { lat: 48.480859, lng: 20.199326 },
    { lat: 48.480782, lng: 20.199374 },
    { lat: 48.480757, lng: 20.199387 },
    { lat: 48.480714, lng: 20.199426 },
    { lat: 48.480614, lng: 20.199548 },
    { lat: 48.480522, lng: 20.199773 },
    { lat: 48.480385, lng: 20.199981 },
    { lat: 48.480241, lng: 20.20016 },
    { lat: 48.480168, lng: 20.20032 },
    { lat: 48.480134, lng: 20.200751 },
    { lat: 48.480129, lng: 20.200782 },
    { lat: 48.480049, lng: 20.201293 },
    { lat: 48.480015, lng: 20.201657 },
    { lat: 48.47995, lng: 20.201938 },
    { lat: 48.479927, lng: 20.202068 },
    { lat: 48.479842, lng: 20.202536 },
    { lat: 48.479762, lng: 20.202726 },
    { lat: 48.479719, lng: 20.202831 },
    { lat: 48.479649, lng: 20.202897 },
    { lat: 48.479638, lng: 20.202914 },
    { lat: 48.479581, lng: 20.203008 },
    { lat: 48.479553, lng: 20.203257 },
    { lat: 48.479588, lng: 20.203539 },
    { lat: 48.479575, lng: 20.20354 },
    { lat: 48.479622, lng: 20.20373 },
    { lat: 48.479676, lng: 20.203941 },
    { lat: 48.479698, lng: 20.204826 },
    { lat: 48.479621, lng: 20.20505 },
    { lat: 48.479502, lng: 20.205394 },
    { lat: 48.479439, lng: 20.205567 },
    { lat: 48.479384, lng: 20.205724 },
    { lat: 48.479336, lng: 20.205817 },
    { lat: 48.479155, lng: 20.20613 },
    { lat: 48.478907, lng: 20.206554 },
    { lat: 48.478629, lng: 20.207339 },
    { lat: 48.47839, lng: 20.208008 },
    { lat: 48.478299, lng: 20.208336 },
    { lat: 48.478135, lng: 20.208748 },
    { lat: 48.478021, lng: 20.20903 },
    { lat: 48.477755, lng: 20.209629 },
    { lat: 48.477648, lng: 20.20987 },
    { lat: 48.477611, lng: 20.209946 },
    { lat: 48.477601, lng: 20.209943 },
    { lat: 48.4776802, lng: 20.2112228 },
    { lat: 48.4776467, lng: 20.2115926 },
    { lat: 48.4775189, lng: 20.2120319 },
    { lat: 48.4774165, lng: 20.2126787 },
    { lat: 48.4773066, lng: 20.2145402 },
    { lat: 48.4774333, lng: 20.2157093 },
    { lat: 48.4775563, lng: 20.2163556 },
    { lat: 48.4775804, lng: 20.2169867 },
    { lat: 48.4779737, lng: 20.2193264 },
    { lat: 48.4778901, lng: 20.2204962 },
    { lat: 48.4777669, lng: 20.2213341 },
    { lat: 48.4779109, lng: 20.2225346 },
    { lat: 48.4780437, lng: 20.223234 },
    { lat: 48.4786658, lng: 20.2254104 },
    { lat: 48.4791003, lng: 20.2265289 },
    { lat: 48.4791571, lng: 20.22674 },
    { lat: 48.4791944, lng: 20.2272485 },
    { lat: 48.4794338, lng: 20.2277742 },
    { lat: 48.4801502, lng: 20.2289727 },
    { lat: 48.4803059, lng: 20.22902 },
    { lat: 48.4807328, lng: 20.2298106 },
    { lat: 48.4812133, lng: 20.2317157 },
    { lat: 48.4812894, lng: 20.2323506 },
    { lat: 48.4815935, lng: 20.2327729 },
    { lat: 48.4825861, lng: 20.2348692 },
    { lat: 48.4815323, lng: 20.2366826 },
    { lat: 48.4811803, lng: 20.2383044 },
    { lat: 48.4809985, lng: 20.2387401 },
    { lat: 48.4807538, lng: 20.2391619 },
    { lat: 48.4808597, lng: 20.239301 },
    { lat: 48.4811091, lng: 20.2397073 },
    { lat: 48.4814182, lng: 20.2398062 },
    { lat: 48.4816765, lng: 20.2400988 },
    { lat: 48.4816937, lng: 20.2403287 },
    { lat: 48.4819256, lng: 20.2409485 },
    { lat: 48.4819491, lng: 20.2413702 },
    { lat: 48.4822729, lng: 20.2418927 },
    { lat: 48.4825122, lng: 20.2420152 },
    { lat: 48.4827504, lng: 20.2420051 },
    { lat: 48.4834236, lng: 20.242529 },
    { lat: 48.48357, lng: 20.2427036 },
    { lat: 48.4837208, lng: 20.2430102 },
    { lat: 48.4838904, lng: 20.2431962 },
    { lat: 48.4839005, lng: 20.2433089 },
    { lat: 48.4840791, lng: 20.2435196 },
    { lat: 48.4847079, lng: 20.2448847 },
    { lat: 48.4853985, lng: 20.2456258 },
    { lat: 48.4859956, lng: 20.2460292 },
    { lat: 48.4864054, lng: 20.2453724 },
    { lat: 48.4863694, lng: 20.2449798 },
    { lat: 48.4865449, lng: 20.2446334 },
    { lat: 48.4870473, lng: 20.2444965 },
    { lat: 48.4872304, lng: 20.2443127 },
    { lat: 48.4876099, lng: 20.2441397 },
    { lat: 48.4876941, lng: 20.2441917 },
    { lat: 48.4878268, lng: 20.2441293 },
    { lat: 48.4879951, lng: 20.244171 },
    { lat: 48.4880363, lng: 20.2443575 },
    { lat: 48.4883365, lng: 20.2445705 },
    { lat: 48.4885213, lng: 20.244601 },
    { lat: 48.4887138, lng: 20.2445389 },
    { lat: 48.4887147, lng: 20.2446812 },
    { lat: 48.4891988, lng: 20.2447277 },
    { lat: 48.4902564, lng: 20.2450434 },
    { lat: 48.4908673, lng: 20.2450821 },
    { lat: 48.4915379, lng: 20.2452935 },
    { lat: 48.491807, lng: 20.2453186 },
    { lat: 48.4918876, lng: 20.2454761 },
    { lat: 48.4930551, lng: 20.245805 },
    { lat: 48.493431, lng: 20.2457697 },
    { lat: 48.493732, lng: 20.2456066 },
    { lat: 48.4940821, lng: 20.2455427 },
    { lat: 48.4954058, lng: 20.2456949 },
    { lat: 48.4959857, lng: 20.2459743 },
    { lat: 48.4962593, lng: 20.2462851 },
    { lat: 48.4969073, lng: 20.2466098 },
    { lat: 48.4973142, lng: 20.2467276 },
    { lat: 48.4979475, lng: 20.2471566 },
    { lat: 48.4983517, lng: 20.2476937 },
    { lat: 48.4989757, lng: 20.2482631 },
    { lat: 48.4992492, lng: 20.2493901 },
  ],
  Rákoš: [
    { lat: 48.5956628, lng: 20.1158982 },
    { lat: 48.5956267, lng: 20.1159488 },
    { lat: 48.5955041, lng: 20.1161207 },
    { lat: 48.5950032, lng: 20.1173653 },
    { lat: 48.5946805, lng: 20.1178479 },
    { lat: 48.5945851, lng: 20.1181079 },
    { lat: 48.5945881, lng: 20.1184802 },
    { lat: 48.5926927, lng: 20.119365 },
    { lat: 48.5916547, lng: 20.1196842 },
    { lat: 48.5910571, lng: 20.1199731 },
    { lat: 48.590704, lng: 20.1199041 },
    { lat: 48.5901911, lng: 20.1199208 },
    { lat: 48.5899144, lng: 20.1201486 },
    { lat: 48.5896758, lng: 20.1202372 },
    { lat: 48.5892474, lng: 20.1203112 },
    { lat: 48.5888659, lng: 20.1202324 },
    { lat: 48.5886797, lng: 20.1204994 },
    { lat: 48.5881203, lng: 20.1210594 },
    { lat: 48.5878716, lng: 20.1210377 },
    { lat: 48.5874308, lng: 20.120837 },
    { lat: 48.587139, lng: 20.1209157 },
    { lat: 48.5869478, lng: 20.1208993 },
    { lat: 48.5867897, lng: 20.1204714 },
    { lat: 48.5866206, lng: 20.1201926 },
    { lat: 48.5861385, lng: 20.1196976 },
    { lat: 48.5855079, lng: 20.1199184 },
    { lat: 48.5854268, lng: 20.1201985 },
    { lat: 48.5848518, lng: 20.1216702 },
    { lat: 48.5846058, lng: 20.1225984 },
    { lat: 48.5845487, lng: 20.1250601 },
    { lat: 48.5849797, lng: 20.127199 },
    { lat: 48.5849004, lng: 20.1277944 },
    { lat: 48.5844894, lng: 20.1290726 },
    { lat: 48.5844167, lng: 20.1299694 },
    { lat: 48.5846423, lng: 20.1321829 },
    { lat: 48.5848638, lng: 20.1329212 },
    { lat: 48.5848546, lng: 20.1335531 },
    { lat: 48.5847638, lng: 20.1339194 },
    { lat: 48.5844117, lng: 20.1344824 },
    { lat: 48.5839482, lng: 20.1355779 },
    { lat: 48.5839323, lng: 20.1358487 },
    { lat: 48.5840031, lng: 20.136729 },
    { lat: 48.5841692, lng: 20.1379042 },
    { lat: 48.5841096, lng: 20.1386543 },
    { lat: 48.5842269, lng: 20.1392115 },
    { lat: 48.5850901, lng: 20.1405757 },
    { lat: 48.5854616, lng: 20.1414068 },
    { lat: 48.5858959, lng: 20.1419757 },
    { lat: 48.586257, lng: 20.1423241 },
    { lat: 48.5863947, lng: 20.1426628 },
    { lat: 48.5864228, lng: 20.1429399 },
    { lat: 48.5863814, lng: 20.1429437 },
    { lat: 48.586351, lng: 20.1438867 },
    { lat: 48.5864021, lng: 20.1442314 },
    { lat: 48.5864629, lng: 20.1444495 },
    { lat: 48.5867708, lng: 20.1450678 },
    { lat: 48.5870903, lng: 20.1466979 },
    { lat: 48.5874422, lng: 20.1471499 },
    { lat: 48.587433, lng: 20.1473141 },
    { lat: 48.5874671, lng: 20.1473746 },
    { lat: 48.5882691, lng: 20.1475727 },
    { lat: 48.5880912, lng: 20.1478229 },
    { lat: 48.5879019, lng: 20.1490951 },
    { lat: 48.5878767, lng: 20.1496962 },
    { lat: 48.5880437, lng: 20.1504538 },
    { lat: 48.5879601, lng: 20.1509767 },
    { lat: 48.5877731, lng: 20.1516944 },
    { lat: 48.586957, lng: 20.153452 },
    { lat: 48.5863531, lng: 20.1545294 },
    { lat: 48.5857312, lng: 20.155554 },
    { lat: 48.5850946, lng: 20.1561277 },
    { lat: 48.5849123, lng: 20.1564113 },
    { lat: 48.5848645, lng: 20.1571752 },
    { lat: 48.5848233, lng: 20.1573288 },
    { lat: 48.5846615, lng: 20.1575308 },
    { lat: 48.5846421, lng: 20.1576624 },
    { lat: 48.5847402, lng: 20.1579177 },
    { lat: 48.585019, lng: 20.1581802 },
    { lat: 48.5851505, lng: 20.1583965 },
    { lat: 48.5852271, lng: 20.1589006 },
    { lat: 48.5855472, lng: 20.1598754 },
    { lat: 48.5858061, lng: 20.1602653 },
    { lat: 48.5859862, lng: 20.1608129 },
    { lat: 48.5864158, lng: 20.1618 },
    { lat: 48.5866337, lng: 20.1625925 },
    { lat: 48.5867589, lng: 20.1637386 },
    { lat: 48.5870157, lng: 20.164128 },
    { lat: 48.5874729, lng: 20.1642926 },
    { lat: 48.5889705, lng: 20.1641157 },
    { lat: 48.5895503, lng: 20.1653912 },
    { lat: 48.589571, lng: 20.1658724 },
    { lat: 48.5894203, lng: 20.1661559 },
    { lat: 48.5895451, lng: 20.1665689 },
    { lat: 48.5899506, lng: 20.1661242 },
    { lat: 48.5900615, lng: 20.1658614 },
    { lat: 48.5902117, lng: 20.1658989 },
    { lat: 48.5903434, lng: 20.1657316 },
    { lat: 48.5905186, lng: 20.1658543 },
    { lat: 48.5907093, lng: 20.1657787 },
    { lat: 48.590812, lng: 20.1659792 },
    { lat: 48.591136, lng: 20.1655812 },
    { lat: 48.591449, lng: 20.1653033 },
    { lat: 48.5923461, lng: 20.1670702 },
    { lat: 48.5928901, lng: 20.1677937 },
    { lat: 48.5937205, lng: 20.1686371 },
    { lat: 48.5938197, lng: 20.1688599 },
    { lat: 48.5939703, lng: 20.1696897 },
    { lat: 48.5945604, lng: 20.1734521 },
    { lat: 48.5950939, lng: 20.1746691 },
    { lat: 48.5951479, lng: 20.1744987 },
    { lat: 48.5952389, lng: 20.1742697 },
    { lat: 48.5962106, lng: 20.1747388 },
    { lat: 48.5963002, lng: 20.174865 },
    { lat: 48.5964727, lng: 20.1749218 },
    { lat: 48.5967098, lng: 20.1753576 },
    { lat: 48.5968989, lng: 20.1755765 },
    { lat: 48.5972957, lng: 20.1753622 },
    { lat: 48.5974211, lng: 20.1753752 },
    { lat: 48.5975719, lng: 20.1751475 },
    { lat: 48.5975972, lng: 20.1749367 },
    { lat: 48.5978055, lng: 20.1745141 },
    { lat: 48.5978609, lng: 20.174272 },
    { lat: 48.5981686, lng: 20.174078 },
    { lat: 48.5981808, lng: 20.173937 },
    { lat: 48.5982711, lng: 20.1738298 },
    { lat: 48.5982589, lng: 20.1737498 },
    { lat: 48.5983275, lng: 20.1735848 },
    { lat: 48.5985795, lng: 20.173377 },
    { lat: 48.5986271, lng: 20.1730549 },
    { lat: 48.5987357, lng: 20.1729282 },
    { lat: 48.5989775, lng: 20.1729714 },
    { lat: 48.5990328, lng: 20.1730596 },
    { lat: 48.5991705, lng: 20.1729292 },
    { lat: 48.5992998, lng: 20.1726138 },
    { lat: 48.5994333, lng: 20.1724876 },
    { lat: 48.5995875, lng: 20.1724891 },
    { lat: 48.5999234, lng: 20.1727437 },
    { lat: 48.6000048, lng: 20.1726661 },
    { lat: 48.6006978, lng: 20.1729309 },
    { lat: 48.6010893, lng: 20.1727487 },
    { lat: 48.6013263, lng: 20.1727899 },
    { lat: 48.6014049, lng: 20.1728959 },
    { lat: 48.6016039, lng: 20.172974 },
    { lat: 48.6016668, lng: 20.1726316 },
    { lat: 48.6016794, lng: 20.1721365 },
    { lat: 48.6022274, lng: 20.1711211 },
    { lat: 48.6022979, lng: 20.1706919 },
    { lat: 48.6022423, lng: 20.1704846 },
    { lat: 48.6021633, lng: 20.1704235 },
    { lat: 48.6022365, lng: 20.1698976 },
    { lat: 48.602137, lng: 20.1695502 },
    { lat: 48.6020527, lng: 20.1695013 },
    { lat: 48.6019525, lng: 20.169276 },
    { lat: 48.6019399, lng: 20.1690768 },
    { lat: 48.6021043, lng: 20.1688803 },
    { lat: 48.6022724, lng: 20.1681072 },
    { lat: 48.6022081, lng: 20.1679394 },
    { lat: 48.602272, lng: 20.1677642 },
    { lat: 48.6024126, lng: 20.1676221 },
    { lat: 48.6023326, lng: 20.1673942 },
    { lat: 48.6024182, lng: 20.167371 },
    { lat: 48.6024159, lng: 20.1671197 },
    { lat: 48.6025728, lng: 20.1669035 },
    { lat: 48.6025807, lng: 20.1666846 },
    { lat: 48.6027822, lng: 20.1666255 },
    { lat: 48.6029306, lng: 20.1664766 },
    { lat: 48.603035, lng: 20.1660777 },
    { lat: 48.6030465, lng: 20.1655852 },
    { lat: 48.6031572, lng: 20.1655921 },
    { lat: 48.6033611, lng: 20.1653993 },
    { lat: 48.6034485, lng: 20.1652191 },
    { lat: 48.6035229, lng: 20.1652499 },
    { lat: 48.6038828, lng: 20.1651268 },
    { lat: 48.6040093, lng: 20.1649123 },
    { lat: 48.604134, lng: 20.1648197 },
    { lat: 48.6041664, lng: 20.1646762 },
    { lat: 48.6043602, lng: 20.1645676 },
    { lat: 48.6044328, lng: 20.1644118 },
    { lat: 48.6044291, lng: 20.1642403 },
    { lat: 48.6045236, lng: 20.1640373 },
    { lat: 48.6047401, lng: 20.163964 },
    { lat: 48.6048262, lng: 20.1637539 },
    { lat: 48.6049914, lng: 20.1637419 },
    { lat: 48.6050998, lng: 20.1635864 },
    { lat: 48.6051322, lng: 20.1634108 },
    { lat: 48.6054973, lng: 20.1630259 },
    { lat: 48.6055967, lng: 20.162836 },
    { lat: 48.6057382, lng: 20.1628806 },
    { lat: 48.6058094, lng: 20.1627499 },
    { lat: 48.6058627, lng: 20.1629001 },
    { lat: 48.6058787, lng: 20.163067 },
    { lat: 48.6057714, lng: 20.163757 },
    { lat: 48.6060114, lng: 20.1648477 },
    { lat: 48.6064072, lng: 20.1654138 },
    { lat: 48.6069801, lng: 20.1659053 },
    { lat: 48.6071201, lng: 20.1661938 },
    { lat: 48.6073352, lng: 20.1664164 },
    { lat: 48.6075396, lng: 20.1667923 },
    { lat: 48.6076468, lng: 20.1671301 },
    { lat: 48.6078666, lng: 20.1674644 },
    { lat: 48.6081978, lng: 20.1677759 },
    { lat: 48.6085349, lng: 20.1683457 },
    { lat: 48.6090611, lng: 20.1688216 },
    { lat: 48.6091267, lng: 20.168634 },
    { lat: 48.6096328, lng: 20.1678654 },
    { lat: 48.6095397, lng: 20.1673443 },
    { lat: 48.6095561, lng: 20.167078 },
    { lat: 48.6097305, lng: 20.1660736 },
    { lat: 48.6097772, lng: 20.1659223 },
    { lat: 48.6099233, lng: 20.1657181 },
    { lat: 48.6103434, lng: 20.1653841 },
    { lat: 48.6104148, lng: 20.1652537 },
    { lat: 48.6110531, lng: 20.1654448 },
    { lat: 48.6115561, lng: 20.1652935 },
    { lat: 48.6117949, lng: 20.1652922 },
    { lat: 48.6119617, lng: 20.1651281 },
    { lat: 48.6119922, lng: 20.1649665 },
    { lat: 48.6123583, lng: 20.1644353 },
    { lat: 48.6126828, lng: 20.1637409 },
    { lat: 48.6127663, lng: 20.1634139 },
    { lat: 48.6127723, lng: 20.1628094 },
    { lat: 48.6126921, lng: 20.1620818 },
    { lat: 48.6127207, lng: 20.1614527 },
    { lat: 48.6127436, lng: 20.1610975 },
    { lat: 48.6129498, lng: 20.1604912 },
    { lat: 48.6135189, lng: 20.1597462 },
    { lat: 48.6138909, lng: 20.1590303 },
    { lat: 48.6147584, lng: 20.1580676 },
    { lat: 48.6149777, lng: 20.1573629 },
    { lat: 48.6150559, lng: 20.1567894 },
    { lat: 48.6153521, lng: 20.1563175 },
    { lat: 48.616084, lng: 20.1560105 },
    { lat: 48.6161864, lng: 20.1557579 },
    { lat: 48.6162975, lng: 20.155606 },
    { lat: 48.6163577, lng: 20.1552865 },
    { lat: 48.6163581, lng: 20.1544044 },
    { lat: 48.6162597, lng: 20.1538699 },
    { lat: 48.6162527, lng: 20.1534993 },
    { lat: 48.6164313, lng: 20.152934 },
    { lat: 48.6168157, lng: 20.1522222 },
    { lat: 48.6169762, lng: 20.1520119 },
    { lat: 48.6172282, lng: 20.1513433 },
    { lat: 48.6172722, lng: 20.1508683 },
    { lat: 48.6172553, lng: 20.1501498 },
    { lat: 48.6170272, lng: 20.1489937 },
    { lat: 48.6173337, lng: 20.148829 },
    { lat: 48.6176597, lng: 20.1485127 },
    { lat: 48.6188383, lng: 20.1467796 },
    { lat: 48.6198013, lng: 20.1460591 },
    { lat: 48.6200062, lng: 20.1457468 },
    { lat: 48.6206232, lng: 20.1453285 },
    { lat: 48.621075, lng: 20.1449179 },
    { lat: 48.6215322, lng: 20.1443609 },
    { lat: 48.6219436, lng: 20.1436721 },
    { lat: 48.6225048, lng: 20.143073 },
    { lat: 48.6224236, lng: 20.1425437 },
    { lat: 48.62225, lng: 20.1419296 },
    { lat: 48.6221373, lng: 20.141735 },
    { lat: 48.6220233, lng: 20.1413707 },
    { lat: 48.6215471, lng: 20.1396319 },
    { lat: 48.6216321, lng: 20.1389469 },
    { lat: 48.6215705, lng: 20.138378 },
    { lat: 48.6216453, lng: 20.1378033 },
    { lat: 48.6207954, lng: 20.1354947 },
    { lat: 48.6198645, lng: 20.133739 },
    { lat: 48.6189043, lng: 20.1320623 },
    { lat: 48.6188496, lng: 20.1316584 },
    { lat: 48.6190186, lng: 20.1313579 },
    { lat: 48.6195086, lng: 20.1308586 },
    { lat: 48.6197801, lng: 20.1303935 },
    { lat: 48.6203163, lng: 20.1301188 },
    { lat: 48.6206923, lng: 20.1296439 },
    { lat: 48.6207762, lng: 20.1292431 },
    { lat: 48.6211793, lng: 20.1282752 },
    { lat: 48.6212214, lng: 20.1278138 },
    { lat: 48.621118, lng: 20.1266632 },
    { lat: 48.6210955, lng: 20.1266614 },
    { lat: 48.6207471, lng: 20.1263178 },
    { lat: 48.6204276, lng: 20.1264702 },
    { lat: 48.6198577, lng: 20.1256684 },
    { lat: 48.6195815, lng: 20.1250831 },
    { lat: 48.6195299, lng: 20.1244546 },
    { lat: 48.6194577, lng: 20.1241712 },
    { lat: 48.6193988, lng: 20.1241112 },
    { lat: 48.6189656, lng: 20.1241183 },
    { lat: 48.6187305, lng: 20.1244011 },
    { lat: 48.6186172, lng: 20.12447 },
    { lat: 48.6185217, lng: 20.1244219 },
    { lat: 48.6180972, lng: 20.1236244 },
    { lat: 48.617982, lng: 20.1236126 },
    { lat: 48.6176895, lng: 20.1237331 },
    { lat: 48.617552, lng: 20.1237284 },
    { lat: 48.6174251, lng: 20.1236038 },
    { lat: 48.6172447, lng: 20.1232667 },
    { lat: 48.6170037, lng: 20.1229648 },
    { lat: 48.6167508, lng: 20.1221771 },
    { lat: 48.6164443, lng: 20.1217342 },
    { lat: 48.6159609, lng: 20.1217841 },
    { lat: 48.6157071, lng: 20.1216906 },
    { lat: 48.6150127, lng: 20.1211805 },
    { lat: 48.6148732, lng: 20.1211796 },
    { lat: 48.6146688, lng: 20.1210587 },
    { lat: 48.6143812, lng: 20.120333 },
    { lat: 48.6142066, lng: 20.1193703 },
    { lat: 48.6141483, lng: 20.1189322 },
    { lat: 48.614217, lng: 20.1180253 },
    { lat: 48.6141913, lng: 20.1176735 },
    { lat: 48.6140972, lng: 20.117489 },
    { lat: 48.6138525, lng: 20.1175377 },
    { lat: 48.6133192, lng: 20.1179895 },
    { lat: 48.6124441, lng: 20.1183798 },
    { lat: 48.6116685, lng: 20.1184999 },
    { lat: 48.6108691, lng: 20.1188693 },
    { lat: 48.610689, lng: 20.119007 },
    { lat: 48.6102672, lng: 20.1189668 },
    { lat: 48.609605, lng: 20.1191871 },
    { lat: 48.6093828, lng: 20.1193184 },
    { lat: 48.6087923, lng: 20.1192847 },
    { lat: 48.6080754, lng: 20.1189391 },
    { lat: 48.6077845, lng: 20.1189302 },
    { lat: 48.6076078, lng: 20.1187705 },
    { lat: 48.6072867, lng: 20.1186587 },
    { lat: 48.607008, lng: 20.1186194 },
    { lat: 48.6066144, lng: 20.1186852 },
    { lat: 48.6059552, lng: 20.1185703 },
    { lat: 48.6056781, lng: 20.1186786 },
    { lat: 48.6055687, lng: 20.1186478 },
    { lat: 48.6053685, lng: 20.1184333 },
    { lat: 48.6049796, lng: 20.1178221 },
    { lat: 48.6045674, lng: 20.1174914 },
    { lat: 48.6043014, lng: 20.1174833 },
    { lat: 48.6040819, lng: 20.117571 },
    { lat: 48.6028948, lng: 20.1184265 },
    { lat: 48.6022651, lng: 20.117353 },
    { lat: 48.60206, lng: 20.1171564 },
    { lat: 48.6015194, lng: 20.1168843 },
    { lat: 48.6010503, lng: 20.1169503 },
    { lat: 48.6002413, lng: 20.1173842 },
    { lat: 48.5997571, lng: 20.1175537 },
    { lat: 48.5992187, lng: 20.1176052 },
    { lat: 48.5989089, lng: 20.1177169 },
    { lat: 48.5985025, lng: 20.1176667 },
    { lat: 48.5980207, lng: 20.1176991 },
    { lat: 48.5973365, lng: 20.1173004 },
    { lat: 48.5971996, lng: 20.1171217 },
    { lat: 48.597037, lng: 20.1170543 },
    { lat: 48.596806, lng: 20.1166391 },
    { lat: 48.5957541, lng: 20.1160637 },
    { lat: 48.5956628, lng: 20.1158982 },
  ],
  MuránskaLehota: [
    { lat: 48.6942523, lng: 20.0113486 },
    { lat: 48.6943955, lng: 20.0117592 },
    { lat: 48.6946318, lng: 20.0124006 },
    { lat: 48.6949539, lng: 20.0141577 },
    { lat: 48.6951328, lng: 20.0146384 },
    { lat: 48.6953627, lng: 20.0154758 },
    { lat: 48.6959513, lng: 20.0165226 },
    { lat: 48.6961388, lng: 20.0169665 },
    { lat: 48.6963227, lng: 20.0177038 },
    { lat: 48.6966508, lng: 20.0200594 },
    { lat: 48.6958752, lng: 20.0211828 },
    { lat: 48.6956277, lng: 20.0214074 },
    { lat: 48.6955234, lng: 20.0223889 },
    { lat: 48.6952512, lng: 20.0232669 },
    { lat: 48.6950506, lng: 20.0236407 },
    { lat: 48.6950736, lng: 20.0241719 },
    { lat: 48.6947905, lng: 20.0247821 },
    { lat: 48.6946548, lng: 20.0254742 },
    { lat: 48.6946764, lng: 20.0258283 },
    { lat: 48.6945797, lng: 20.0264835 },
    { lat: 48.6946208, lng: 20.0271203 },
    { lat: 48.6945548, lng: 20.0272132 },
    { lat: 48.6946438, lng: 20.0273777 },
    { lat: 48.6949435, lng: 20.0276192 },
    { lat: 48.6952342, lng: 20.0281389 },
    { lat: 48.6954106, lng: 20.0283457 },
    { lat: 48.6954047, lng: 20.0284482 },
    { lat: 48.6959773, lng: 20.0295947 },
    { lat: 48.6964542, lng: 20.030227 },
    { lat: 48.6970277, lng: 20.030829 },
    { lat: 48.6971909, lng: 20.0309041 },
    { lat: 48.6977182, lng: 20.0315062 },
    { lat: 48.6981775, lng: 20.0323131 },
    { lat: 48.6984366, lng: 20.0324186 },
    { lat: 48.6985955, lng: 20.0327213 },
    { lat: 48.6987046, lng: 20.0330978 },
    { lat: 48.6994084, lng: 20.0338815 },
    { lat: 48.6995948, lng: 20.0344886 },
    { lat: 48.6996504, lng: 20.0345528 },
    { lat: 48.6998168, lng: 20.0346269 },
    { lat: 48.6999623, lng: 20.0347946 },
    { lat: 48.7008504, lng: 20.0354846 },
    { lat: 48.7013763, lng: 20.0357026 },
    { lat: 48.7015607, lng: 20.0357201 },
    { lat: 48.70172, lng: 20.0358387 },
    { lat: 48.7026061, lng: 20.0370235 },
    { lat: 48.7038344, lng: 20.0391284 },
    { lat: 48.7041592, lng: 20.0394643 },
    { lat: 48.7047812, lng: 20.0396864 },
    { lat: 48.7053614, lng: 20.0399892 },
    { lat: 48.7056533, lng: 20.0402893 },
    { lat: 48.7064929, lng: 20.0406263 },
    { lat: 48.7068466, lng: 20.0405208 },
    { lat: 48.7079928, lng: 20.0408856 },
    { lat: 48.7087645, lng: 20.0408086 },
    { lat: 48.7103376, lng: 20.0413142 },
    { lat: 48.7106315, lng: 20.041316 },
    { lat: 48.7109114, lng: 20.0411889 },
    { lat: 48.7114687, lng: 20.0419472 },
    { lat: 48.711845, lng: 20.0421359 },
    { lat: 48.7131577, lng: 20.0433471 },
    { lat: 48.7142037, lng: 20.0446659 },
    { lat: 48.714613, lng: 20.0456016 },
    { lat: 48.7149469, lng: 20.0462156 },
    { lat: 48.7152064, lng: 20.0471191 },
    { lat: 48.7152797, lng: 20.0477196 },
    { lat: 48.7155627, lng: 20.0484098 },
    { lat: 48.7159078, lng: 20.048827 },
    { lat: 48.7161532, lng: 20.0492603 },
    { lat: 48.716326, lng: 20.0493228 },
    { lat: 48.7165077, lng: 20.0495504 },
    { lat: 48.7167141, lng: 20.0499411 },
    { lat: 48.7175054, lng: 20.0526777 },
    { lat: 48.7176531, lng: 20.0537146 },
    { lat: 48.7180983, lng: 20.0556861 },
    { lat: 48.7186713, lng: 20.0569586 },
    { lat: 48.7191204, lng: 20.0576892 },
    { lat: 48.7196137, lng: 20.0587795 },
    { lat: 48.7201582, lng: 20.0596606 },
    { lat: 48.7204099, lng: 20.0598627 },
    { lat: 48.7208204, lng: 20.060343 },
    { lat: 48.7210026, lng: 20.0604689 },
    { lat: 48.7211858, lng: 20.0607901 },
    { lat: 48.7214217, lng: 20.0605817 },
    { lat: 48.7216199, lng: 20.0607728 },
    { lat: 48.7220504, lng: 20.0608884 },
    { lat: 48.7221916, lng: 20.0607874 },
    { lat: 48.7224365, lng: 20.0609615 },
    { lat: 48.7227314, lng: 20.061283 },
    { lat: 48.7230345, lng: 20.0614802 },
    { lat: 48.7234049, lng: 20.062127 },
    { lat: 48.7234846, lng: 20.0619879 },
    { lat: 48.7242917, lng: 20.0624655 },
    { lat: 48.7243745, lng: 20.0627274 },
    { lat: 48.7250147, lng: 20.0635543 },
    { lat: 48.7257498, lng: 20.0642845 },
    { lat: 48.7259497, lng: 20.0639688 },
    { lat: 48.726291, lng: 20.0633972 },
    { lat: 48.7265469, lng: 20.06231 },
    { lat: 48.7269447, lng: 20.0609732 },
    { lat: 48.7274657, lng: 20.0597639 },
    { lat: 48.7278714, lng: 20.0591905 },
    { lat: 48.7280331, lng: 20.0590432 },
    { lat: 48.7283364, lng: 20.0589683 },
    { lat: 48.7288182, lng: 20.0589938 },
    { lat: 48.7289587, lng: 20.0590919 },
    { lat: 48.7291641, lng: 20.0591053 },
    { lat: 48.7295212, lng: 20.0590463 },
    { lat: 48.7296675, lng: 20.0589376 },
    { lat: 48.7297844, lng: 20.0581385 },
    { lat: 48.7301642, lng: 20.0572485 },
    { lat: 48.7303612, lng: 20.0564792 },
    { lat: 48.7305793, lng: 20.0552174 },
    { lat: 48.7309026, lng: 20.0544797 },
    { lat: 48.7309614, lng: 20.0541841 },
    { lat: 48.7312219, lng: 20.0537824 },
    { lat: 48.7314026, lng: 20.0531963 },
    { lat: 48.7314258, lng: 20.0525576 },
    { lat: 48.7309758, lng: 20.051129 },
    { lat: 48.7310326, lng: 20.050733 },
    { lat: 48.731637, lng: 20.0499309 },
    { lat: 48.7317064, lng: 20.0497541 },
    { lat: 48.7316804, lng: 20.0496738 },
    { lat: 48.7317601, lng: 20.0493641 },
    { lat: 48.7319963, lng: 20.0486481 },
    { lat: 48.7321042, lng: 20.0481252 },
    { lat: 48.7321135, lng: 20.0470714 },
    { lat: 48.7322826, lng: 20.0457984 },
    { lat: 48.7325911, lng: 20.0445147 },
    { lat: 48.7328244, lng: 20.044026 },
    { lat: 48.7330007, lng: 20.0430627 },
    { lat: 48.7328812, lng: 20.0421895 },
    { lat: 48.7326841, lng: 20.0415673 },
    { lat: 48.7325087, lng: 20.0407879 },
    { lat: 48.7324715, lng: 20.0403729 },
    { lat: 48.732144, lng: 20.0391579 },
    { lat: 48.7320841, lng: 20.0382302 },
    { lat: 48.7319084, lng: 20.0379322 },
    { lat: 48.731446, lng: 20.0374308 },
    { lat: 48.7313706, lng: 20.0372132 },
    { lat: 48.7313639, lng: 20.0366697 },
    { lat: 48.7313104, lng: 20.0362505 },
    { lat: 48.730903, lng: 20.0351456 },
    { lat: 48.7307528, lng: 20.0343478 },
    { lat: 48.7304152, lng: 20.0342207 },
    { lat: 48.7298388, lng: 20.0337152 },
    { lat: 48.7294891, lng: 20.0327797 },
    { lat: 48.7289702, lng: 20.0310775 },
    { lat: 48.7284005, lng: 20.0315447 },
    { lat: 48.728032, lng: 20.0307819 },
    { lat: 48.7273376, lng: 20.0297924 },
    { lat: 48.7272981, lng: 20.0298194 },
    { lat: 48.7264704, lng: 20.0282465 },
    { lat: 48.7266306, lng: 20.0277125 },
    { lat: 48.7264304, lng: 20.0273127 },
    { lat: 48.7260405, lng: 20.0270129 },
    { lat: 48.7258423, lng: 20.0269875 },
    { lat: 48.7249559, lng: 20.0272343 },
    { lat: 48.7242406, lng: 20.0267946 },
    { lat: 48.7231009, lng: 20.025798 },
    { lat: 48.7228599, lng: 20.0256889 },
    { lat: 48.7224738, lng: 20.0258608 },
    { lat: 48.7221552, lng: 20.0258404 },
    { lat: 48.7213103, lng: 20.0255346 },
    { lat: 48.7211009, lng: 20.0251874 },
    { lat: 48.7208692, lng: 20.0249244 },
    { lat: 48.7205513, lng: 20.024 },
    { lat: 48.7202276, lng: 20.0234978 },
    { lat: 48.7198957, lng: 20.023214 },
    { lat: 48.7193069, lng: 20.0230007 },
    { lat: 48.7184213, lng: 20.0225076 },
    { lat: 48.7182771, lng: 20.0223708 },
    { lat: 48.7177354, lng: 20.021538 },
    { lat: 48.7174139, lng: 20.0207375 },
    { lat: 48.7170368, lng: 20.0201495 },
    { lat: 48.716231, lng: 20.0194363 },
    { lat: 48.7154318, lng: 20.0190158 },
    { lat: 48.7151673, lng: 20.018782 },
    { lat: 48.7148451, lng: 20.0184521 },
    { lat: 48.7145093, lng: 20.0178245 },
    { lat: 48.7141533, lng: 20.0175298 },
    { lat: 48.7122075, lng: 20.0163814 },
    { lat: 48.7118251, lng: 20.0161249 },
    { lat: 48.7116455, lng: 20.0159234 },
    { lat: 48.7109049, lng: 20.0160199 },
    { lat: 48.7100721, lng: 20.0156273 },
    { lat: 48.7097824, lng: 20.0155696 },
    { lat: 48.7096241, lng: 20.0156389 },
    { lat: 48.7094309, lng: 20.0155413 },
    { lat: 48.7080371, lng: 20.0160741 },
    { lat: 48.7069812, lng: 20.0168172 },
    { lat: 48.7066671, lng: 20.0171994 },
    { lat: 48.7062353, lng: 20.0175158 },
    { lat: 48.7056582, lng: 20.0177525 },
    { lat: 48.7049557, lng: 20.0180012 },
    { lat: 48.7048654, lng: 20.0175965 },
    { lat: 48.704448, lng: 20.0174408 },
    { lat: 48.7041414, lng: 20.0174913 },
    { lat: 48.7040081, lng: 20.0176966 },
    { lat: 48.7038225, lng: 20.017599 },
    { lat: 48.7037531, lng: 20.0177169 },
    { lat: 48.7036404, lng: 20.0188498 },
    { lat: 48.7035625, lng: 20.0191992 },
    { lat: 48.7034368, lng: 20.0194233 },
    { lat: 48.7032817, lng: 20.01957 },
    { lat: 48.7029962, lng: 20.0195833 },
    { lat: 48.7024729, lng: 20.0203501 },
    { lat: 48.7020232, lng: 20.0208864 },
    { lat: 48.7009551, lng: 20.0202569 },
    { lat: 48.7008009, lng: 20.0205828 },
    { lat: 48.6999779, lng: 20.0207038 },
    { lat: 48.6997901, lng: 20.0202531 },
    { lat: 48.6995328, lng: 20.0193747 },
    { lat: 48.6993656, lng: 20.019038 },
    { lat: 48.6991468, lng: 20.0187311 },
    { lat: 48.6986137, lng: 20.0182419 },
    { lat: 48.6984293, lng: 20.0179232 },
    { lat: 48.6983267, lng: 20.0175169 },
    { lat: 48.698351, lng: 20.0170678 },
    { lat: 48.6987919, lng: 20.0156459 },
    { lat: 48.6988489, lng: 20.0152868 },
    { lat: 48.6988497, lng: 20.014877 },
    { lat: 48.6987592, lng: 20.0142163 },
    { lat: 48.6986497, lng: 20.0139112 },
    { lat: 48.6983368, lng: 20.0135109 },
    { lat: 48.6981574, lng: 20.0133693 },
    { lat: 48.6979274, lng: 20.0126378 },
    { lat: 48.697872, lng: 20.0124199 },
    { lat: 48.6977932, lng: 20.0108813 },
    { lat: 48.6978997, lng: 20.0101124 },
    { lat: 48.6983107, lng: 20.0095131 },
    { lat: 48.6987235, lng: 20.0087432 },
    { lat: 48.6987079, lng: 20.0085849 },
    { lat: 48.6985648, lng: 20.0085329 },
    { lat: 48.6984113, lng: 20.0086335 },
    { lat: 48.6978958, lng: 20.0085874 },
    { lat: 48.6977817, lng: 20.0087398 },
    { lat: 48.6976754, lng: 20.0086747 },
    { lat: 48.6976506, lng: 20.0087313 },
    { lat: 48.6971957, lng: 20.0088152 },
    { lat: 48.6967759, lng: 20.0093384 },
    { lat: 48.6963881, lng: 20.0096552 },
    { lat: 48.6959693, lng: 20.0103094 },
    { lat: 48.6951596, lng: 20.0110929 },
    { lat: 48.6950465, lng: 20.011058 },
    { lat: 48.6949663, lng: 20.0108895 },
    { lat: 48.6948788, lng: 20.0108968 },
    { lat: 48.6948716, lng: 20.0108047 },
    { lat: 48.694569, lng: 20.0105444 },
    { lat: 48.694394, lng: 20.0105917 },
    { lat: 48.6943261, lng: 20.010719 },
    { lat: 48.6942342, lng: 20.0107003 },
    { lat: 48.6941298, lng: 20.0108953 },
    { lat: 48.6942523, lng: 20.0113486 },
  ],
  Sása: [
    { lat: 48.5804215, lng: 20.1005091 },
    { lat: 48.5802759, lng: 20.1005337 },
    { lat: 48.5801255, lng: 20.1004346 },
    { lat: 48.5798475, lng: 20.1004691 },
    { lat: 48.5797044, lng: 20.1003519 },
    { lat: 48.5792842, lng: 20.1003 },
    { lat: 48.5788936, lng: 20.1000013 },
    { lat: 48.5789519, lng: 20.0994211 },
    { lat: 48.5786446, lng: 20.0993394 },
    { lat: 48.5785657, lng: 20.0994525 },
    { lat: 48.5784806, lng: 20.0994618 },
    { lat: 48.5784214, lng: 20.099314 },
    { lat: 48.5782498, lng: 20.0993023 },
    { lat: 48.5777139, lng: 20.0993615 },
    { lat: 48.5777159, lng: 20.0995203 },
    { lat: 48.5770986, lng: 20.0997354 },
    { lat: 48.5762968, lng: 20.1003451 },
    { lat: 48.5762613, lng: 20.1002791 },
    { lat: 48.5759359, lng: 20.1006381 },
    { lat: 48.5756897, lng: 20.1008161 },
    { lat: 48.5752045, lng: 20.1007945 },
    { lat: 48.575261, lng: 20.1005203 },
    { lat: 48.5745543, lng: 20.1003472 },
    { lat: 48.573976, lng: 20.100315 },
    { lat: 48.573897, lng: 20.100568 },
    { lat: 48.573855, lng: 20.1007 },
    { lat: 48.573837, lng: 20.100758 },
    { lat: 48.57369, lng: 20.100796 },
    { lat: 48.573442, lng: 20.100818 },
    { lat: 48.573316, lng: 20.100803 },
    { lat: 48.57282, lng: 20.101105 },
    { lat: 48.572647, lng: 20.101296 },
    { lat: 48.572557, lng: 20.101311 },
    { lat: 48.572451, lng: 20.101029 },
    { lat: 48.572068, lng: 20.101352 },
    { lat: 48.571689, lng: 20.101704 },
    { lat: 48.570937, lng: 20.10192 },
    { lat: 48.570618, lng: 20.101955 },
    { lat: 48.570519, lng: 20.101481 },
    { lat: 48.570269, lng: 20.101561 },
    { lat: 48.569769, lng: 20.101647 },
    { lat: 48.569681, lng: 20.101729 },
    { lat: 48.569346, lng: 20.10184 },
    { lat: 48.569308, lng: 20.101852 },
    { lat: 48.568825, lng: 20.102313 },
    { lat: 48.56875, lng: 20.10235 },
    { lat: 48.568642, lng: 20.102464 },
    { lat: 48.568568, lng: 20.102871 },
    { lat: 48.56852, lng: 20.102819 },
    { lat: 48.568444, lng: 20.102888 },
    { lat: 48.568384, lng: 20.102971 },
    { lat: 48.568325, lng: 20.103047 },
    { lat: 48.568275, lng: 20.103139 },
    { lat: 48.56816, lng: 20.103348 },
    { lat: 48.568064, lng: 20.103362 },
    { lat: 48.567962, lng: 20.103338 },
    { lat: 48.567751, lng: 20.103396 },
    { lat: 48.567678, lng: 20.103443 },
    { lat: 48.567632, lng: 20.103664 },
    { lat: 48.567478, lng: 20.103759 },
    { lat: 48.567322, lng: 20.103932 },
    { lat: 48.567166, lng: 20.103932 },
    { lat: 48.567134, lng: 20.10416 },
    { lat: 48.567004, lng: 20.104343 },
    { lat: 48.566918, lng: 20.104385 },
    { lat: 48.566829, lng: 20.10439 },
    { lat: 48.566735, lng: 20.104366 },
    { lat: 48.566669, lng: 20.104308 },
    { lat: 48.566645, lng: 20.104343 },
    { lat: 48.56633, lng: 20.104615 },
    { lat: 48.566272, lng: 20.104585 },
    { lat: 48.566245, lng: 20.104574 },
    { lat: 48.566194, lng: 20.104689 },
    { lat: 48.566127, lng: 20.104763 },
    { lat: 48.566037, lng: 20.104786 },
    { lat: 48.565947, lng: 20.104775 },
    { lat: 48.56585, lng: 20.104764 },
    { lat: 48.565755, lng: 20.104813 },
    { lat: 48.565668, lng: 20.105019 },
    { lat: 48.565567, lng: 20.105116 },
    { lat: 48.565493, lng: 20.105185 },
    { lat: 48.565421, lng: 20.105242 },
    { lat: 48.56543, lng: 20.105284 },
    { lat: 48.565481, lng: 20.105587 },
    { lat: 48.565169, lng: 20.105635 },
    { lat: 48.565107, lng: 20.105175 },
    { lat: 48.565098, lng: 20.105124 },
    { lat: 48.565019, lng: 20.105156 },
    { lat: 48.564949, lng: 20.105207 },
    { lat: 48.564911, lng: 20.105236 },
    { lat: 48.564717, lng: 20.105186 },
    { lat: 48.564666, lng: 20.105196 },
    { lat: 48.564627, lng: 20.105239 },
    { lat: 48.564527, lng: 20.105475 },
    { lat: 48.564418, lng: 20.105645 },
    { lat: 48.564248, lng: 20.105756 },
    { lat: 48.563817, lng: 20.10566 },
    { lat: 48.563702, lng: 20.105815 },
    { lat: 48.563672, lng: 20.106123 },
    { lat: 48.563603, lng: 20.106231 },
    { lat: 48.563483, lng: 20.106245 },
    { lat: 48.563425, lng: 20.106269 },
    { lat: 48.563399, lng: 20.106278 },
    { lat: 48.563372, lng: 20.106299 },
    { lat: 48.563324, lng: 20.106336 },
    { lat: 48.563247, lng: 20.106392 },
    { lat: 48.563266, lng: 20.10645 },
    { lat: 48.563145, lng: 20.106811 },
    { lat: 48.563097, lng: 20.106843 },
    { lat: 48.563024, lng: 20.106781 },
    { lat: 48.562992, lng: 20.106716 },
    { lat: 48.562918, lng: 20.106733 },
    { lat: 48.562837, lng: 20.106779 },
    { lat: 48.562731, lng: 20.106937 },
    { lat: 48.562465, lng: 20.107257 },
    { lat: 48.562176, lng: 20.107477 },
    { lat: 48.562073, lng: 20.107661 },
    { lat: 48.561702, lng: 20.108017 },
    { lat: 48.561349, lng: 20.108619 },
    { lat: 48.561305, lng: 20.10888 },
    { lat: 48.561091, lng: 20.109147 },
    { lat: 48.560959, lng: 20.109416 },
    { lat: 48.560901, lng: 20.109481 },
    { lat: 48.560789, lng: 20.109425 },
    { lat: 48.560784, lng: 20.109504 },
    { lat: 48.560592, lng: 20.109426 },
    { lat: 48.5606, lng: 20.109486 },
    { lat: 48.560684, lng: 20.109612 },
    { lat: 48.560702, lng: 20.109641 },
    { lat: 48.560591, lng: 20.109802 },
    { lat: 48.560408, lng: 20.110062 },
    { lat: 48.56024, lng: 20.110342 },
    { lat: 48.560107, lng: 20.110469 },
    { lat: 48.55994, lng: 20.110642 },
    { lat: 48.559865, lng: 20.110605 },
    { lat: 48.559794, lng: 20.110611 },
    { lat: 48.559759, lng: 20.110584 },
    { lat: 48.55957, lng: 20.109978 },
    { lat: 48.559548, lng: 20.109906 },
    { lat: 48.559425, lng: 20.109512 },
    { lat: 48.559388, lng: 20.109428 },
    { lat: 48.559346, lng: 20.109506 },
    { lat: 48.559367, lng: 20.109857 },
    { lat: 48.55927, lng: 20.109968 },
    { lat: 48.559224, lng: 20.109976 },
    { lat: 48.558979, lng: 20.109902 },
    { lat: 48.558666, lng: 20.109978 },
    { lat: 48.558678, lng: 20.110048 },
    { lat: 48.558726, lng: 20.110218 },
    { lat: 48.558849, lng: 20.110243 },
    { lat: 48.55882, lng: 20.110502 },
    { lat: 48.558789, lng: 20.110651 },
    { lat: 48.558779, lng: 20.110702 },
    { lat: 48.558726, lng: 20.110726 },
    { lat: 48.558677, lng: 20.110749 },
    { lat: 48.558551, lng: 20.110705 },
    { lat: 48.558416, lng: 20.110702 },
    { lat: 48.558247, lng: 20.110637 },
    { lat: 48.558125, lng: 20.110657 },
    { lat: 48.558062, lng: 20.110406 },
    { lat: 48.5581, lng: 20.110292 },
    { lat: 48.55813, lng: 20.110182 },
    { lat: 48.557995, lng: 20.109666 },
    { lat: 48.55762, lng: 20.109873 },
    { lat: 48.55745, lng: 20.109951 },
    { lat: 48.557302, lng: 20.110036 },
    { lat: 48.557106, lng: 20.110111 },
    { lat: 48.556886, lng: 20.110254 },
    { lat: 48.556742, lng: 20.110281 },
    { lat: 48.556704, lng: 20.110336 },
    { lat: 48.556668, lng: 20.110382 },
    { lat: 48.556654, lng: 20.110517 },
    { lat: 48.556666, lng: 20.110815 },
    { lat: 48.556667, lng: 20.110847 },
    { lat: 48.55667, lng: 20.110908 },
    { lat: 48.556344, lng: 20.111058 },
    { lat: 48.556237, lng: 20.11123 },
    { lat: 48.556269, lng: 20.111272 },
    { lat: 48.556142, lng: 20.111461 },
    { lat: 48.555865, lng: 20.111873 },
    { lat: 48.555989, lng: 20.112363 },
    { lat: 48.555962, lng: 20.112414 },
    { lat: 48.555791, lng: 20.112673 },
    { lat: 48.555617, lng: 20.112868 },
    { lat: 48.555401, lng: 20.112774 },
    { lat: 48.555328, lng: 20.11318 },
    { lat: 48.555245, lng: 20.11348 },
    { lat: 48.555229, lng: 20.113772 },
    { lat: 48.555168, lng: 20.113943 },
    { lat: 48.55498, lng: 20.114104 },
    { lat: 48.554547, lng: 20.113721 },
    { lat: 48.554245, lng: 20.113501 },
    { lat: 48.553966, lng: 20.113387 },
    { lat: 48.553767, lng: 20.113321 },
    { lat: 48.553561, lng: 20.113298 },
    { lat: 48.553352, lng: 20.112893 },
    { lat: 48.553331, lng: 20.112854 },
    { lat: 48.553133, lng: 20.113059 },
    { lat: 48.55306, lng: 20.113175 },
    { lat: 48.553018, lng: 20.113274 },
    { lat: 48.553029, lng: 20.113517 },
    { lat: 48.553073, lng: 20.113615 },
    { lat: 48.553119, lng: 20.113693 },
    { lat: 48.553084, lng: 20.113718 },
    { lat: 48.552943, lng: 20.113822 },
    { lat: 48.552783, lng: 20.114567 },
    { lat: 48.552816, lng: 20.114618 },
    { lat: 48.552708, lng: 20.114932 },
    { lat: 48.552639, lng: 20.114988 },
    { lat: 48.552251, lng: 20.115244 },
    { lat: 48.552136, lng: 20.115611 },
    { lat: 48.552087, lng: 20.115646 },
    { lat: 48.551991, lng: 20.115613 },
    { lat: 48.551938, lng: 20.115668 },
    { lat: 48.551757, lng: 20.116116 },
    { lat: 48.551651, lng: 20.116576 },
    { lat: 48.551532, lng: 20.116819 },
    { lat: 48.5515, lng: 20.116936 },
    { lat: 48.551493, lng: 20.117183 },
    { lat: 48.551482, lng: 20.11719 },
    { lat: 48.551458, lng: 20.117204 },
    { lat: 48.551433, lng: 20.117275 },
    { lat: 48.551405, lng: 20.117352 },
    { lat: 48.551277, lng: 20.117578 },
    { lat: 48.551173, lng: 20.117709 },
    { lat: 48.550891, lng: 20.117526 },
    { lat: 48.5509975, lng: 20.1180846 },
    { lat: 48.5509486, lng: 20.11843 },
    { lat: 48.551, lng: 20.1187801 },
    { lat: 48.5509649, lng: 20.1188846 },
    { lat: 48.550788, lng: 20.1190216 },
    { lat: 48.5506842, lng: 20.1194319 },
    { lat: 48.550586, lng: 20.1196152 },
    { lat: 48.5506461, lng: 20.1196832 },
    { lat: 48.5511361, lng: 20.1200121 },
    { lat: 48.5515238, lng: 20.1201837 },
    { lat: 48.5517046, lng: 20.1202231 },
    { lat: 48.5518658, lng: 20.1200108 },
    { lat: 48.5523938, lng: 20.1201428 },
    { lat: 48.5523667, lng: 20.1202758 },
    { lat: 48.5524193, lng: 20.1203331 },
    { lat: 48.5526048, lng: 20.1205349 },
    { lat: 48.5530436, lng: 20.120715 },
    { lat: 48.5532476, lng: 20.1208546 },
    { lat: 48.5532449, lng: 20.1209039 },
    { lat: 48.5535455, lng: 20.1210648 },
    { lat: 48.5535805, lng: 20.1211473 },
    { lat: 48.5543058, lng: 20.1215326 },
    { lat: 48.5548793, lng: 20.1216777 },
    { lat: 48.5555581, lng: 20.1220233 },
    { lat: 48.5557479, lng: 20.1222841 },
    { lat: 48.556166, lng: 20.1226503 },
    { lat: 48.5562841, lng: 20.1228953 },
    { lat: 48.5567364, lng: 20.123019 },
    { lat: 48.5569338, lng: 20.1232233 },
    { lat: 48.556987, lng: 20.1233929 },
    { lat: 48.5571446, lng: 20.1234725 },
    { lat: 48.5574418, lng: 20.1233856 },
    { lat: 48.5577473, lng: 20.1236136 },
    { lat: 48.5579141, lng: 20.1238371 },
    { lat: 48.5581153, lng: 20.1239617 },
    { lat: 48.5584198, lng: 20.1243612 },
    { lat: 48.5584812, lng: 20.124593 },
    { lat: 48.5586873, lng: 20.1247848 },
    { lat: 48.5587636, lng: 20.1249473 },
    { lat: 48.5592867, lng: 20.1253998 },
    { lat: 48.5594712, lng: 20.125334 },
    { lat: 48.5596928, lng: 20.1254144 },
    { lat: 48.5600692, lng: 20.1257205 },
    { lat: 48.5601283, lng: 20.1257065 },
    { lat: 48.5605789, lng: 20.1265384 },
    { lat: 48.5607246, lng: 20.1265459 },
    { lat: 48.5608489, lng: 20.1267473 },
    { lat: 48.5611921, lng: 20.1269897 },
    { lat: 48.5615274, lng: 20.1273391 },
    { lat: 48.5620748, lng: 20.1276422 },
    { lat: 48.5620802, lng: 20.1277743 },
    { lat: 48.562377, lng: 20.128315 },
    { lat: 48.5626601, lng: 20.1294277 },
    { lat: 48.5629134, lng: 20.1301115 },
    { lat: 48.5632815, lng: 20.1308551 },
    { lat: 48.5637211, lng: 20.1327396 },
    { lat: 48.5639117, lng: 20.1331589 },
    { lat: 48.5640433, lng: 20.1338754 },
    { lat: 48.5641461, lng: 20.1349028 },
    { lat: 48.5642904, lng: 20.13557 },
    { lat: 48.5645977, lng: 20.136006 },
    { lat: 48.5647263, lng: 20.1364265 },
    { lat: 48.5650974, lng: 20.1372392 },
    { lat: 48.5652106, lng: 20.1377547 },
    { lat: 48.5661968, lng: 20.1386254 },
    { lat: 48.5663717, lng: 20.1388006 },
    { lat: 48.5663829, lng: 20.1387448 },
    { lat: 48.5665266, lng: 20.138095 },
    { lat: 48.5667432, lng: 20.137635 },
    { lat: 48.5671603, lng: 20.1372014 },
    { lat: 48.5675515, lng: 20.1366311 },
    { lat: 48.5676397, lng: 20.1364166 },
    { lat: 48.5679812, lng: 20.1344341 },
    { lat: 48.5682243, lng: 20.1336289 },
    { lat: 48.5684805, lng: 20.1332567 },
    { lat: 48.5689178, lng: 20.1329612 },
    { lat: 48.5693206, lng: 20.1323382 },
    { lat: 48.5693822, lng: 20.1322226 },
    { lat: 48.5695835, lng: 20.1313316 },
    { lat: 48.5699257, lng: 20.1304393 },
    { lat: 48.5703699, lng: 20.1297854 },
    { lat: 48.5706434, lng: 20.1296743 },
    { lat: 48.5715288, lng: 20.1288216 },
    { lat: 48.5732666, lng: 20.1274382 },
    { lat: 48.5734483, lng: 20.1270323 },
    { lat: 48.5737515, lng: 20.1261826 },
    { lat: 48.573935, lng: 20.1258195 },
    { lat: 48.5740991, lng: 20.1251225 },
    { lat: 48.5743413, lng: 20.1244364 },
    { lat: 48.5746971, lng: 20.1236077 },
    { lat: 48.5746577, lng: 20.1232486 },
    { lat: 48.5747244, lng: 20.1227566 },
    { lat: 48.5756358, lng: 20.1179141 },
    { lat: 48.5759014, lng: 20.1167502 },
    { lat: 48.5758447, lng: 20.1165776 },
    { lat: 48.5760674, lng: 20.1160732 },
    { lat: 48.5764032, lng: 20.11558 },
    { lat: 48.5768645, lng: 20.1154055 },
    { lat: 48.5777225, lng: 20.1149555 },
    { lat: 48.5780179, lng: 20.114636 },
    { lat: 48.578593, lng: 20.1141845 },
    { lat: 48.578705, lng: 20.1128509 },
    { lat: 48.5788618, lng: 20.1120552 },
    { lat: 48.579022, lng: 20.1096695 },
    { lat: 48.5791602, lng: 20.1088955 },
    { lat: 48.5788527, lng: 20.1069432 },
    { lat: 48.5789943, lng: 20.1061086 },
    { lat: 48.5790791, lng: 20.1058386 },
    { lat: 48.5792214, lng: 20.1056895 },
    { lat: 48.5793273, lng: 20.105469 },
    { lat: 48.5794483, lng: 20.1050409 },
    { lat: 48.5796492, lng: 20.1047472 },
    { lat: 48.5799004, lng: 20.1041458 },
    { lat: 48.580032, lng: 20.1037013 },
    { lat: 48.5799967, lng: 20.1033642 },
    { lat: 48.5800325, lng: 20.1029713 },
    { lat: 48.580312, lng: 20.1022634 },
    { lat: 48.580327, lng: 20.1019956 },
    { lat: 48.5802892, lng: 20.1019269 },
    { lat: 48.5803486, lng: 20.1014233 },
    { lat: 48.5805059, lng: 20.1010579 },
    { lat: 48.5804347, lng: 20.1008298 },
    { lat: 48.5804212, lng: 20.1005301 },
    { lat: 48.5804215, lng: 20.1005091 },
  ],
  Ploské: [
    { lat: 48.6222194, lng: 20.0724043 },
    { lat: 48.6220798, lng: 20.0724011 },
    { lat: 48.621925, lng: 20.0722298 },
    { lat: 48.621903, lng: 20.0721321 },
    { lat: 48.622117, lng: 20.0709283 },
    { lat: 48.6220839, lng: 20.0706515 },
    { lat: 48.6220499, lng: 20.0703675 },
    { lat: 48.622129, lng: 20.0700471 },
    { lat: 48.6220218, lng: 20.0696623 },
    { lat: 48.6218727, lng: 20.0693495 },
    { lat: 48.6218723, lng: 20.0689569 },
    { lat: 48.6219247, lng: 20.0686436 },
    { lat: 48.6219079, lng: 20.068019 },
    { lat: 48.6219515, lng: 20.0677653 },
    { lat: 48.6225217, lng: 20.066518 },
    { lat: 48.6225517, lng: 20.0661535 },
    { lat: 48.6224573, lng: 20.0657245 },
    { lat: 48.6224067, lng: 20.0648769 },
    { lat: 48.6227404, lng: 20.0634709 },
    { lat: 48.62273, lng: 20.0628368 },
    { lat: 48.6225744, lng: 20.0626431 },
    { lat: 48.6223471, lng: 20.0620205 },
    { lat: 48.6218612, lng: 20.0613324 },
    { lat: 48.6211007, lng: 20.0605378 },
    { lat: 48.6207292, lng: 20.0599395 },
    { lat: 48.6205604, lng: 20.059374 },
    { lat: 48.6202854, lng: 20.0589901 },
    { lat: 48.6199951, lng: 20.058781 },
    { lat: 48.6196103, lng: 20.057918 },
    { lat: 48.6189869, lng: 20.0568717 },
    { lat: 48.6180154, lng: 20.0547066 },
    { lat: 48.6179792, lng: 20.0517116 },
    { lat: 48.6176368, lng: 20.0504901 },
    { lat: 48.617548, lng: 20.049816 },
    { lat: 48.6174482, lng: 20.0494203 },
    { lat: 48.61711, lng: 20.0485715 },
    { lat: 48.6169894, lng: 20.0485395 },
    { lat: 48.6169036, lng: 20.0480175 },
    { lat: 48.6170194, lng: 20.0474456 },
    { lat: 48.6171766, lng: 20.0471616 },
    { lat: 48.6176762, lng: 20.0465218 },
    { lat: 48.6176648, lng: 20.0459526 },
    { lat: 48.6177817, lng: 20.045158 },
    { lat: 48.6177587, lng: 20.04466 },
    { lat: 48.6178315, lng: 20.043939 },
    { lat: 48.6185027, lng: 20.0420676 },
    { lat: 48.6188931, lng: 20.0412657 },
    { lat: 48.6194335, lng: 20.0404734 },
    { lat: 48.6200741, lng: 20.0401722 },
    { lat: 48.6201008, lng: 20.0400533 },
    { lat: 48.6218713, lng: 20.0394502 },
    { lat: 48.6220336, lng: 20.0393173 },
    { lat: 48.6225693, lng: 20.038519 },
    { lat: 48.6228089, lng: 20.037753 },
    { lat: 48.62329, lng: 20.03694 },
    { lat: 48.623282, lng: 20.036914 },
    { lat: 48.623267, lng: 20.036887 },
    { lat: 48.623037, lng: 20.036368 },
    { lat: 48.622967, lng: 20.036188 },
    { lat: 48.622859, lng: 20.036056 },
    { lat: 48.622843, lng: 20.036039 },
    { lat: 48.622838, lng: 20.036033 },
    { lat: 48.622822, lng: 20.036005 },
    { lat: 48.622814, lng: 20.035987 },
    { lat: 48.622846, lng: 20.035937 },
    { lat: 48.622885, lng: 20.035872 },
    { lat: 48.622956, lng: 20.035789 },
    { lat: 48.622951, lng: 20.035765 },
    { lat: 48.622946, lng: 20.035744 },
    { lat: 48.622859, lng: 20.035286 },
    { lat: 48.622829, lng: 20.035122 },
    { lat: 48.62282, lng: 20.035071 },
    { lat: 48.622805, lng: 20.035037 },
    { lat: 48.622775, lng: 20.034965 },
    { lat: 48.622738, lng: 20.034876 },
    { lat: 48.622725, lng: 20.034821 },
    { lat: 48.622715, lng: 20.034775 },
    { lat: 48.622658, lng: 20.0345 },
    { lat: 48.622649, lng: 20.034463 },
    { lat: 48.6226, lng: 20.034323 },
    { lat: 48.622582, lng: 20.034273 },
    { lat: 48.62257, lng: 20.034237 },
    { lat: 48.622458, lng: 20.034258 },
    { lat: 48.622123, lng: 20.034314 },
    { lat: 48.621913, lng: 20.034346 },
    { lat: 48.621747, lng: 20.03437 },
    { lat: 48.621695, lng: 20.034369 },
    { lat: 48.621502, lng: 20.034259 },
    { lat: 48.621339, lng: 20.034166 },
    { lat: 48.621295, lng: 20.034152 },
    { lat: 48.621207, lng: 20.034172 },
    { lat: 48.621144, lng: 20.034176 },
    { lat: 48.620802, lng: 20.034085 },
    { lat: 48.620599, lng: 20.03404 },
    { lat: 48.620334, lng: 20.034022 },
    { lat: 48.620183, lng: 20.033965 },
    { lat: 48.61989, lng: 20.033864 },
    { lat: 48.619781, lng: 20.033806 },
    { lat: 48.619537, lng: 20.033672 },
    { lat: 48.619366, lng: 20.033513 },
    { lat: 48.619134, lng: 20.033292 },
    { lat: 48.618952, lng: 20.033301 },
    { lat: 48.618707, lng: 20.033309 },
    { lat: 48.618535, lng: 20.033343 },
    { lat: 48.618531, lng: 20.033288 },
    { lat: 48.618658, lng: 20.033115 },
    { lat: 48.618696, lng: 20.033031 },
    { lat: 48.618739, lng: 20.032715 },
    { lat: 48.618743, lng: 20.03235 },
    { lat: 48.61882, lng: 20.03219 },
    { lat: 48.618812, lng: 20.031945 },
    { lat: 48.618797, lng: 20.031576 },
    { lat: 48.618742, lng: 20.031409 },
    { lat: 48.618631, lng: 20.031058 },
    { lat: 48.6186, lng: 20.030436 },
    { lat: 48.618629, lng: 20.030209 },
    { lat: 48.618613, lng: 20.029768 },
    { lat: 48.618641, lng: 20.029369 },
    { lat: 48.618708, lng: 20.029376 },
    { lat: 48.618746, lng: 20.029375 },
    { lat: 48.618725, lng: 20.029064 },
    { lat: 48.618725, lng: 20.028761 },
    { lat: 48.618728, lng: 20.028411 },
    { lat: 48.61891, lng: 20.028019 },
    { lat: 48.61892, lng: 20.027864 },
    { lat: 48.618932, lng: 20.027817 },
    { lat: 48.618939, lng: 20.027793 },
    { lat: 48.618996, lng: 20.027561 },
    { lat: 48.619051, lng: 20.027343 },
    { lat: 48.61886, lng: 20.027294 },
    { lat: 48.61861, lng: 20.027227 },
    { lat: 48.618588, lng: 20.026736 },
    { lat: 48.618574, lng: 20.026378 },
    { lat: 48.618557, lng: 20.025934 },
    { lat: 48.618468, lng: 20.02564 },
    { lat: 48.618337, lng: 20.025192 },
    { lat: 48.617742, lng: 20.025019 },
    { lat: 48.617613, lng: 20.02498 },
    { lat: 48.617509, lng: 20.024949 },
    { lat: 48.617795, lng: 20.024404 },
    { lat: 48.617608, lng: 20.024305 },
    { lat: 48.617626, lng: 20.024049 },
    { lat: 48.617638, lng: 20.023942 },
    { lat: 48.61772, lng: 20.023451 },
    { lat: 48.617751, lng: 20.023265 },
    { lat: 48.617772, lng: 20.023171 },
    { lat: 48.617772, lng: 20.023135 },
    { lat: 48.617797, lng: 20.02308 },
    { lat: 48.617848, lng: 20.022897 },
    { lat: 48.617969, lng: 20.022662 },
    { lat: 48.61818, lng: 20.022365 },
    { lat: 48.618416, lng: 20.022069 },
    { lat: 48.618292, lng: 20.021833 },
    { lat: 48.618181, lng: 20.021616 },
    { lat: 48.618123, lng: 20.021502 },
    { lat: 48.617935, lng: 20.021004 },
    { lat: 48.617821, lng: 20.020719 },
    { lat: 48.617787, lng: 20.020698 },
    { lat: 48.61778, lng: 20.020699 },
    { lat: 48.617696, lng: 20.020709 },
    { lat: 48.617658, lng: 20.020718 },
    { lat: 48.617584, lng: 20.02077 },
    { lat: 48.617474, lng: 20.020856 },
    { lat: 48.617388, lng: 20.020938 },
    { lat: 48.617318, lng: 20.021009 },
    { lat: 48.617229, lng: 20.021091 },
    { lat: 48.617177, lng: 20.021137 },
    { lat: 48.616995, lng: 20.021273 },
    { lat: 48.616815, lng: 20.021414 },
    { lat: 48.616619, lng: 20.021559 },
    { lat: 48.616444, lng: 20.021728 },
    { lat: 48.61636, lng: 20.021817 },
    { lat: 48.616257, lng: 20.022005 },
    { lat: 48.616149, lng: 20.02236 },
    { lat: 48.615928, lng: 20.02302 },
    { lat: 48.615875, lng: 20.023062 },
    { lat: 48.615759, lng: 20.02315 },
    { lat: 48.615671, lng: 20.023419 },
    { lat: 48.615597, lng: 20.023613 },
    { lat: 48.615543, lng: 20.023741 },
    { lat: 48.615367, lng: 20.024037 },
    { lat: 48.615298, lng: 20.024162 },
    { lat: 48.615185, lng: 20.024389 },
    { lat: 48.615033, lng: 20.024589 },
    { lat: 48.614989, lng: 20.024751 },
    { lat: 48.614915, lng: 20.025043 },
    { lat: 48.614827, lng: 20.025469 },
    { lat: 48.61461, lng: 20.025395 },
    { lat: 48.614409, lng: 20.025328 },
    { lat: 48.614349, lng: 20.025323 },
    { lat: 48.614134, lng: 20.025295 },
    { lat: 48.613965, lng: 20.025314 },
    { lat: 48.613862, lng: 20.025313 },
    { lat: 48.613569, lng: 20.025272 },
    { lat: 48.613401, lng: 20.025263 },
    { lat: 48.613255, lng: 20.025258 },
    { lat: 48.61322, lng: 20.025727 },
    { lat: 48.613209, lng: 20.025837 },
    { lat: 48.613176, lng: 20.025996 },
    { lat: 48.613129, lng: 20.026312 },
    { lat: 48.613063, lng: 20.026475 },
    { lat: 48.612892, lng: 20.026892 },
    { lat: 48.612844, lng: 20.02713 },
    { lat: 48.612847, lng: 20.027466 },
    { lat: 48.612797, lng: 20.027734 },
    { lat: 48.612722, lng: 20.028081 },
    { lat: 48.612716, lng: 20.028112 },
    { lat: 48.612708, lng: 20.028153 },
    { lat: 48.612622, lng: 20.028568 },
    { lat: 48.612532, lng: 20.02901 },
    { lat: 48.612464, lng: 20.029423 },
    { lat: 48.612387, lng: 20.029925 },
    { lat: 48.61227, lng: 20.030358 },
    { lat: 48.612234, lng: 20.030483 },
    { lat: 48.612226, lng: 20.030512 },
    { lat: 48.612198, lng: 20.030608 },
    { lat: 48.61214, lng: 20.030731 },
    { lat: 48.612051, lng: 20.03104 },
    { lat: 48.612005, lng: 20.03125 },
    { lat: 48.612002, lng: 20.031328 },
    { lat: 48.612025, lng: 20.031597 },
    { lat: 48.61203, lng: 20.03165 },
    { lat: 48.612021, lng: 20.031662 },
    { lat: 48.612155, lng: 20.031836 },
    { lat: 48.612189, lng: 20.03197 },
    { lat: 48.612216, lng: 20.032128 },
    { lat: 48.612227, lng: 20.032208 },
    { lat: 48.612234, lng: 20.032369 },
    { lat: 48.612261, lng: 20.03246 },
    { lat: 48.612453, lng: 20.032887 },
    { lat: 48.612538, lng: 20.033386 },
    { lat: 48.612643, lng: 20.033909 },
    { lat: 48.612666, lng: 20.034053 },
    { lat: 48.612688, lng: 20.034098 },
    { lat: 48.612821, lng: 20.034357 },
    { lat: 48.612933, lng: 20.034578 },
    { lat: 48.612935, lng: 20.034679 },
    { lat: 48.613167, lng: 20.035095 },
    { lat: 48.613334, lng: 20.035415 },
    { lat: 48.613415, lng: 20.035755 },
    { lat: 48.613372, lng: 20.036037 },
    { lat: 48.613317, lng: 20.036263 },
    { lat: 48.613304, lng: 20.036523 },
    { lat: 48.613311, lng: 20.036838 },
    { lat: 48.613389, lng: 20.03749 },
    { lat: 48.613391, lng: 20.037505 },
    { lat: 48.613392, lng: 20.037514 },
    { lat: 48.613401, lng: 20.037584 },
    { lat: 48.613569, lng: 20.038903 },
    { lat: 48.613581, lng: 20.03899 },
    { lat: 48.613552, lng: 20.039021 },
    { lat: 48.613502, lng: 20.039075 },
    { lat: 48.613451, lng: 20.039131 },
    { lat: 48.612732, lng: 20.039216 },
    { lat: 48.612338, lng: 20.039274 },
    { lat: 48.61181, lng: 20.039416 },
    { lat: 48.611664, lng: 20.039455 },
    { lat: 48.611562, lng: 20.039482 },
    { lat: 48.611492, lng: 20.039459 },
    { lat: 48.61135, lng: 20.039411 },
    { lat: 48.610833, lng: 20.039235 },
    { lat: 48.610201, lng: 20.039518 },
    { lat: 48.609626, lng: 20.040415 },
    { lat: 48.609018, lng: 20.040785 },
    { lat: 48.608755, lng: 20.041046 },
    { lat: 48.608276, lng: 20.041383 },
    { lat: 48.607866, lng: 20.041573 },
    { lat: 48.607168, lng: 20.04189 },
    { lat: 48.607112, lng: 20.041918 },
    { lat: 48.607023, lng: 20.041961 },
    { lat: 48.606954, lng: 20.041996 },
    { lat: 48.606892, lng: 20.042026 },
    { lat: 48.606529, lng: 20.042206 },
    { lat: 48.606539, lng: 20.042225 },
    { lat: 48.606477, lng: 20.04228 },
    { lat: 48.606466, lng: 20.042267 },
    { lat: 48.606248, lng: 20.042492 },
    { lat: 48.606112, lng: 20.042753 },
    { lat: 48.605986, lng: 20.04306 },
    { lat: 48.60588, lng: 20.043415 },
    { lat: 48.605821, lng: 20.043652 },
    { lat: 48.605722, lng: 20.043929 },
    { lat: 48.605569, lng: 20.04455 },
    { lat: 48.605552, lng: 20.04476 },
    { lat: 48.605559, lng: 20.045112 },
    { lat: 48.605529, lng: 20.046344 },
    { lat: 48.605537, lng: 20.04668 },
    { lat: 48.605569, lng: 20.046918 },
    { lat: 48.605696, lng: 20.047828 },
    { lat: 48.605732, lng: 20.048149 },
    { lat: 48.605698, lng: 20.048598 },
    { lat: 48.60574, lng: 20.048929 },
    { lat: 48.605744, lng: 20.049172 },
    { lat: 48.605779, lng: 20.049282 },
    { lat: 48.605794, lng: 20.049677 },
    { lat: 48.605772, lng: 20.04994 },
    { lat: 48.605763, lng: 20.050143 },
    { lat: 48.605753, lng: 20.050382 },
    { lat: 48.6057, lng: 20.050614 },
    { lat: 48.605592, lng: 20.0509 },
    { lat: 48.605489, lng: 20.051172 },
    { lat: 48.6052, lng: 20.052268 },
    { lat: 48.605221, lng: 20.05281 },
    { lat: 48.605241, lng: 20.052919 },
    { lat: 48.605373, lng: 20.053594 },
    { lat: 48.605387, lng: 20.054156 },
    { lat: 48.605299, lng: 20.05471 },
    { lat: 48.605341, lng: 20.056 },
    { lat: 48.605176, lng: 20.056167 },
    { lat: 48.604381, lng: 20.05686 },
    { lat: 48.603975, lng: 20.057038 },
    { lat: 48.603833, lng: 20.057096 },
    { lat: 48.603238, lng: 20.057259 },
    { lat: 48.602998, lng: 20.057711 },
    { lat: 48.602856, lng: 20.057882 },
    { lat: 48.602826, lng: 20.057919 },
    { lat: 48.602785, lng: 20.057968 },
    { lat: 48.602438, lng: 20.058389 },
    { lat: 48.602362, lng: 20.05855 },
    { lat: 48.602274, lng: 20.058769 },
    { lat: 48.601912, lng: 20.059079 },
    { lat: 48.601433, lng: 20.059946 },
    { lat: 48.601339, lng: 20.060218 },
    { lat: 48.601272, lng: 20.060344 },
    { lat: 48.601175, lng: 20.060477 },
    { lat: 48.601005, lng: 20.060631 },
    { lat: 48.60074, lng: 20.060635 },
    { lat: 48.6005319, lng: 20.0610278 },
    { lat: 48.6004944, lng: 20.0614772 },
    { lat: 48.6005055, lng: 20.0618555 },
    { lat: 48.6007331, lng: 20.0624558 },
    { lat: 48.6004405, lng: 20.062983 },
    { lat: 48.6004194, lng: 20.0635049 },
    { lat: 48.6003185, lng: 20.0640905 },
    { lat: 48.6003626, lng: 20.0645867 },
    { lat: 48.6002618, lng: 20.0657691 },
    { lat: 48.5998289, lng: 20.0671507 },
    { lat: 48.5995266, lng: 20.0674136 },
    { lat: 48.5992488, lng: 20.0678311 },
    { lat: 48.5991829, lng: 20.0680344 },
    { lat: 48.5991902, lng: 20.0681615 },
    { lat: 48.5998052, lng: 20.0693859 },
    { lat: 48.6000837, lng: 20.0701288 },
    { lat: 48.6000906, lng: 20.0709484 },
    { lat: 48.5999803, lng: 20.0715804 },
    { lat: 48.5998057, lng: 20.0722071 },
    { lat: 48.5997304, lng: 20.0730284 },
    { lat: 48.5998436, lng: 20.0737544 },
    { lat: 48.6000175, lng: 20.0740658 },
    { lat: 48.6002716, lng: 20.0753352 },
    { lat: 48.6002609, lng: 20.0757607 },
    { lat: 48.6001759, lng: 20.076322 },
    { lat: 48.6001149, lng: 20.0778693 },
    { lat: 48.6000633, lng: 20.0780948 },
    { lat: 48.5997661, lng: 20.0786728 },
    { lat: 48.5994053, lng: 20.0790749 },
    { lat: 48.5996442, lng: 20.0798447 },
    { lat: 48.5996476, lng: 20.0800365 },
    { lat: 48.6001499, lng: 20.0817043 },
    { lat: 48.6001675, lng: 20.0821013 },
    { lat: 48.6004423, lng: 20.0829221 },
    { lat: 48.6004512, lng: 20.0831615 },
    { lat: 48.6003619, lng: 20.0835108 },
    { lat: 48.6004387, lng: 20.0842861 },
    { lat: 48.6004039, lng: 20.0845876 },
    { lat: 48.6005137, lng: 20.0848474 },
    { lat: 48.6005364, lng: 20.085152 },
    { lat: 48.6004941, lng: 20.08574 },
    { lat: 48.6005478, lng: 20.086305 },
    { lat: 48.6007831, lng: 20.0870396 },
    { lat: 48.6009352, lng: 20.087346 },
    { lat: 48.6009986, lng: 20.087871 },
    { lat: 48.6011257, lng: 20.0883338 },
    { lat: 48.6011087, lng: 20.0886027 },
    { lat: 48.6012686, lng: 20.08915 },
    { lat: 48.6014082, lng: 20.0903006 },
    { lat: 48.6017933, lng: 20.0902415 },
    { lat: 48.6018894, lng: 20.0903866 },
    { lat: 48.6020137, lng: 20.0908376 },
    { lat: 48.6020277, lng: 20.0911488 },
    { lat: 48.60216, lng: 20.0916004 },
    { lat: 48.602225, lng: 20.0921118 },
    { lat: 48.6026624, lng: 20.0919823 },
    { lat: 48.602761, lng: 20.0920777 },
    { lat: 48.6028048, lng: 20.0922704 },
    { lat: 48.6029883, lng: 20.0922967 },
    { lat: 48.6035832, lng: 20.0914785 },
    { lat: 48.6036112, lng: 20.0912805 },
    { lat: 48.6037379, lng: 20.0913195 },
    { lat: 48.6038869, lng: 20.0915508 },
    { lat: 48.6043255, lng: 20.0907368 },
    { lat: 48.604544, lng: 20.0906274 },
    { lat: 48.6045002, lng: 20.0905216 },
    { lat: 48.6050239, lng: 20.0893423 },
    { lat: 48.6050032, lng: 20.0891378 },
    { lat: 48.6048349, lng: 20.0889358 },
    { lat: 48.6047571, lng: 20.0886348 },
    { lat: 48.6047625, lng: 20.0879158 },
    { lat: 48.6050104, lng: 20.0875331 },
    { lat: 48.6050696, lng: 20.0875497 },
    { lat: 48.6052294, lng: 20.0873561 },
    { lat: 48.6054392, lng: 20.0869542 },
    { lat: 48.6061918, lng: 20.0863254 },
    { lat: 48.6064354, lng: 20.0859809 },
    { lat: 48.6070134, lng: 20.0857115 },
    { lat: 48.6073801, lng: 20.0856704 },
    { lat: 48.6076135, lng: 20.085761 },
    { lat: 48.6077751, lng: 20.0856313 },
    { lat: 48.6080363, lng: 20.0852235 },
    { lat: 48.6083362, lng: 20.0844875 },
    { lat: 48.6090881, lng: 20.0843712 },
    { lat: 48.6094469, lng: 20.0844124 },
    { lat: 48.6098946, lng: 20.0838484 },
    { lat: 48.6103324, lng: 20.0836194 },
    { lat: 48.6104586, lng: 20.0833239 },
    { lat: 48.6114627, lng: 20.0830032 },
    { lat: 48.6116963, lng: 20.0829902 },
    { lat: 48.6120933, lng: 20.0827854 },
    { lat: 48.6120822, lng: 20.0827338 },
    { lat: 48.6122089, lng: 20.082634 },
    { lat: 48.6124878, lng: 20.0824904 },
    { lat: 48.6124576, lng: 20.0823868 },
    { lat: 48.6129911, lng: 20.0820215 },
    { lat: 48.6129918, lng: 20.0817914 },
    { lat: 48.6134018, lng: 20.0816267 },
    { lat: 48.613808, lng: 20.0813444 },
    { lat: 48.6140312, lng: 20.0814285 },
    { lat: 48.6143992, lng: 20.0813094 },
    { lat: 48.6147117, lng: 20.0813264 },
    { lat: 48.6152318, lng: 20.0811014 },
    { lat: 48.6155208, lng: 20.0808109 },
    { lat: 48.6157724, lng: 20.0806617 },
    { lat: 48.6161455, lng: 20.0805862 },
    { lat: 48.6162147, lng: 20.0802141 },
    { lat: 48.6161554, lng: 20.0797608 },
    { lat: 48.6162537, lng: 20.0794647 },
    { lat: 48.6163799, lng: 20.0794112 },
    { lat: 48.6167459, lng: 20.0794399 },
    { lat: 48.6170299, lng: 20.0786782 },
    { lat: 48.6172001, lng: 20.078354 },
    { lat: 48.6172768, lng: 20.078324 },
    { lat: 48.6174919, lng: 20.078573 },
    { lat: 48.6176428, lng: 20.0784602 },
    { lat: 48.6177026, lng: 20.0780826 },
    { lat: 48.6177033, lng: 20.0773624 },
    { lat: 48.617749, lng: 20.0772687 },
    { lat: 48.6179612, lng: 20.0771431 },
    { lat: 48.6183148, lng: 20.0771142 },
    { lat: 48.6186609, lng: 20.076928 },
    { lat: 48.6187609, lng: 20.076674 },
    { lat: 48.6186496, lng: 20.0762416 },
    { lat: 48.6186706, lng: 20.0760702 },
    { lat: 48.6194268, lng: 20.0754489 },
    { lat: 48.6195749, lng: 20.0750281 },
    { lat: 48.6198944, lng: 20.0746386 },
    { lat: 48.6199678, lng: 20.0745854 },
    { lat: 48.6202293, lng: 20.0745915 },
    { lat: 48.6204009, lng: 20.0744658 },
    { lat: 48.6211431, lng: 20.0735349 },
    { lat: 48.62135, lng: 20.0733621 },
    { lat: 48.6212752, lng: 20.0731559 },
    { lat: 48.6214139, lng: 20.0731094 },
    { lat: 48.6216627, lng: 20.0728283 },
    { lat: 48.6220598, lng: 20.0725179 },
    { lat: 48.6222194, lng: 20.0724043 },
  ],
  Licince: [
    { lat: 48.5541763, lng: 20.2654127 },
    { lat: 48.5525974, lng: 20.2625993 },
    { lat: 48.5524806, lng: 20.2622984 },
    { lat: 48.552321, lng: 20.2621044 },
    { lat: 48.5521949, lng: 20.2617642 },
    { lat: 48.5520543, lng: 20.2617427 },
    { lat: 48.5516976, lng: 20.2611265 },
    { lat: 48.5515749, lng: 20.2613234 },
    { lat: 48.5511911, lng: 20.2611319 },
    { lat: 48.5504755, lng: 20.2605515 },
    { lat: 48.5503284, lng: 20.2605924 },
    { lat: 48.5500066, lng: 20.260202 },
    { lat: 48.5496073, lng: 20.2603876 },
    { lat: 48.5490332, lng: 20.2599071 },
    { lat: 48.5486316, lng: 20.2599514 },
    { lat: 48.548339, lng: 20.2597844 },
    { lat: 48.5481145, lng: 20.2598601 },
    { lat: 48.5478676, lng: 20.2603355 },
    { lat: 48.5477558, lng: 20.2603299 },
    { lat: 48.547584, lng: 20.2605697 },
    { lat: 48.5474168, lng: 20.2606132 },
    { lat: 48.5471635, lng: 20.2608144 },
    { lat: 48.5470309, lng: 20.260821 },
    { lat: 48.5467689, lng: 20.2612299 },
    { lat: 48.5467718, lng: 20.2613565 },
    { lat: 48.5465419, lng: 20.2614081 },
    { lat: 48.5465396, lng: 20.2615245 },
    { lat: 48.546287, lng: 20.2614861 },
    { lat: 48.546257, lng: 20.2615812 },
    { lat: 48.5461536, lng: 20.2615849 },
    { lat: 48.5459815, lng: 20.2617091 },
    { lat: 48.5458676, lng: 20.2619301 },
    { lat: 48.5457343, lng: 20.2620328 },
    { lat: 48.5457605, lng: 20.2622408 },
    { lat: 48.5456401, lng: 20.2623828 },
    { lat: 48.5454605, lng: 20.2628223 },
    { lat: 48.5453865, lng: 20.2628314 },
    { lat: 48.5451275, lng: 20.2631451 },
    { lat: 48.5451355, lng: 20.2633758 },
    { lat: 48.5450129, lng: 20.2636935 },
    { lat: 48.5448726, lng: 20.2637918 },
    { lat: 48.5446242, lng: 20.2642581 },
    { lat: 48.5446024, lng: 20.2645845 },
    { lat: 48.544494, lng: 20.2646963 },
    { lat: 48.5442088, lng: 20.2652814 },
    { lat: 48.5437997, lng: 20.2657624 },
    { lat: 48.5437425, lng: 20.2659901 },
    { lat: 48.5436734, lng: 20.2659806 },
    { lat: 48.5435528, lng: 20.2662655 },
    { lat: 48.5432782, lng: 20.266609 },
    { lat: 48.5429532, lng: 20.2667931 },
    { lat: 48.5429385, lng: 20.2670155 },
    { lat: 48.5426835, lng: 20.2672329 },
    { lat: 48.5426407, lng: 20.2674041 },
    { lat: 48.5423699, lng: 20.2677355 },
    { lat: 48.5423294, lng: 20.2676777 },
    { lat: 48.5421409, lng: 20.2677198 },
    { lat: 48.5417846, lng: 20.2679957 },
    { lat: 48.5416563, lng: 20.2681408 },
    { lat: 48.5413722, lng: 20.2686717 },
    { lat: 48.5411418, lng: 20.2688008 },
    { lat: 48.539895, lng: 20.2691118 },
    { lat: 48.539545, lng: 20.2690555 },
    { lat: 48.5393695, lng: 20.2689518 },
    { lat: 48.5390855, lng: 20.268574 },
    { lat: 48.5383396, lng: 20.2681954 },
    { lat: 48.5381785, lng: 20.2679934 },
    { lat: 48.5381367, lng: 20.2678198 },
    { lat: 48.537985, lng: 20.2677208 },
    { lat: 48.5376832, lng: 20.2677454 },
    { lat: 48.537728, lng: 20.2675957 },
    { lat: 48.537508, lng: 20.2677861 },
    { lat: 48.537243, lng: 20.2677509 },
    { lat: 48.5371821, lng: 20.2675467 },
    { lat: 48.5368578, lng: 20.267718 },
    { lat: 48.5363888, lng: 20.2677651 },
    { lat: 48.5361851, lng: 20.267853 },
    { lat: 48.5361203, lng: 20.2675564 },
    { lat: 48.5359246, lng: 20.2677887 },
    { lat: 48.5358284, lng: 20.2681178 },
    { lat: 48.5357264, lng: 20.2680453 },
    { lat: 48.5355598, lng: 20.2681896 },
    { lat: 48.5347466, lng: 20.2678206 },
    { lat: 48.5346619, lng: 20.2676413 },
    { lat: 48.5345741, lng: 20.2676136 },
    { lat: 48.5342733, lng: 20.2678414 },
    { lat: 48.5339657, lng: 20.2676061 },
    { lat: 48.5339176, lng: 20.2676737 },
    { lat: 48.5338132, lng: 20.2674553 },
    { lat: 48.533749, lng: 20.2675396 },
    { lat: 48.5335833, lng: 20.2673651 },
    { lat: 48.5336313, lng: 20.2670992 },
    { lat: 48.5334719, lng: 20.266943 },
    { lat: 48.5334763, lng: 20.2668211 },
    { lat: 48.5332188, lng: 20.2668094 },
    { lat: 48.5330482, lng: 20.2664818 },
    { lat: 48.5329004, lng: 20.2663846 },
    { lat: 48.5329003, lng: 20.2662702 },
    { lat: 48.5327463, lng: 20.2661945 },
    { lat: 48.5327447, lng: 20.2662394 },
    { lat: 48.5326792, lng: 20.2661223 },
    { lat: 48.5324107, lng: 20.266021 },
    { lat: 48.5323839, lng: 20.2661137 },
    { lat: 48.5318513, lng: 20.2655507 },
    { lat: 48.5317165, lng: 20.2653038 },
    { lat: 48.5314002, lng: 20.2650778 },
    { lat: 48.5309406, lng: 20.2649894 },
    { lat: 48.5307568, lng: 20.2650454 },
    { lat: 48.5306733, lng: 20.2649808 },
    { lat: 48.530562, lng: 20.2650121 },
    { lat: 48.5300347, lng: 20.2648691 },
    { lat: 48.5293799, lng: 20.2648 },
    { lat: 48.5292506, lng: 20.2648592 },
    { lat: 48.5291058, lng: 20.2647902 },
    { lat: 48.528663, lng: 20.2647525 },
    { lat: 48.5284474, lng: 20.2647964 },
    { lat: 48.5282151, lng: 20.2646229 },
    { lat: 48.5281312, lng: 20.2643825 },
    { lat: 48.5279044, lng: 20.2641957 },
    { lat: 48.527544, lng: 20.2642264 },
    { lat: 48.5271816, lng: 20.2644003 },
    { lat: 48.527126, lng: 20.264535 },
    { lat: 48.5266004, lng: 20.264599 },
    { lat: 48.5265558, lng: 20.2643259 },
    { lat: 48.5265471, lng: 20.2643618 },
    { lat: 48.5262419, lng: 20.2650746 },
    { lat: 48.5251554, lng: 20.266986 },
    { lat: 48.5250057, lng: 20.2673828 },
    { lat: 48.524121, lng: 20.2691584 },
    { lat: 48.5234837, lng: 20.2702708 },
    { lat: 48.5231557, lng: 20.2706342 },
    { lat: 48.5227552, lng: 20.2713249 },
    { lat: 48.5215729, lng: 20.2734052 },
    { lat: 48.5198845, lng: 20.2766181 },
    { lat: 48.5190297, lng: 20.2781562 },
    { lat: 48.5187892, lng: 20.2785078 },
    { lat: 48.5184581, lng: 20.2787645 },
    { lat: 48.517372, lng: 20.279384 },
    { lat: 48.513343, lng: 20.2816221 },
    { lat: 48.5113442, lng: 20.2826506 },
    { lat: 48.5043757, lng: 20.286538 },
    { lat: 48.5035448, lng: 20.2872561 },
    { lat: 48.5035329, lng: 20.2875299 },
    { lat: 48.5035191, lng: 20.2878913 },
    { lat: 48.5030335, lng: 20.2891979 },
    { lat: 48.5021185, lng: 20.2900701 },
    { lat: 48.5016313, lng: 20.2911662 },
    { lat: 48.5014787, lng: 20.2935814 },
    { lat: 48.5015808, lng: 20.2947399 },
    { lat: 48.5016283, lng: 20.2970049 },
    { lat: 48.5015532, lng: 20.2976145 },
    { lat: 48.50122, lng: 20.299455 },
    { lat: 48.501562, lng: 20.299331 },
    { lat: 48.501805, lng: 20.29924 },
    { lat: 48.501852, lng: 20.299223 },
    { lat: 48.502592, lng: 20.298955 },
    { lat: 48.503297, lng: 20.298747 },
    { lat: 48.503479, lng: 20.298554 },
    { lat: 48.503544, lng: 20.298548 },
    { lat: 48.503621, lng: 20.298533 },
    { lat: 48.503681, lng: 20.298441 },
    { lat: 48.504137, lng: 20.298412 },
    { lat: 48.504271, lng: 20.298361 },
    { lat: 48.504502, lng: 20.298145 },
    { lat: 48.504602, lng: 20.298157 },
    { lat: 48.504631, lng: 20.298271 },
    { lat: 48.50485, lng: 20.298334 },
    { lat: 48.504982, lng: 20.298453 },
    { lat: 48.505098, lng: 20.298345 },
    { lat: 48.505279, lng: 20.298424 },
    { lat: 48.505447, lng: 20.298623 },
    { lat: 48.505466, lng: 20.298681 },
    { lat: 48.505666, lng: 20.299087 },
    { lat: 48.505758, lng: 20.299231 },
    { lat: 48.505838, lng: 20.299342 },
    { lat: 48.505998, lng: 20.299834 },
    { lat: 48.506165, lng: 20.299906 },
    { lat: 48.506251, lng: 20.299854 },
    { lat: 48.506382, lng: 20.299909 },
    { lat: 48.507078, lng: 20.300653 },
    { lat: 48.507334, lng: 20.300843 },
    { lat: 48.507432, lng: 20.300855 },
    { lat: 48.507575, lng: 20.300986 },
    { lat: 48.507638, lng: 20.301038 },
    { lat: 48.507674, lng: 20.301108 },
    { lat: 48.507754, lng: 20.301159 },
    { lat: 48.507781, lng: 20.301186 },
    { lat: 48.507981, lng: 20.301212 },
    { lat: 48.508164, lng: 20.301269 },
    { lat: 48.508195, lng: 20.30121 },
    { lat: 48.508222, lng: 20.301153 },
    { lat: 48.508429, lng: 20.301123 },
    { lat: 48.508472, lng: 20.301244 },
    { lat: 48.508696, lng: 20.30138 },
    { lat: 48.509011, lng: 20.301326 },
    { lat: 48.509034, lng: 20.301379 },
    { lat: 48.509414, lng: 20.301385 },
    { lat: 48.509438, lng: 20.301399 },
    { lat: 48.509488, lng: 20.301436 },
    { lat: 48.509533, lng: 20.30151 },
    { lat: 48.509699, lng: 20.30145 },
    { lat: 48.510132, lng: 20.301609 },
    { lat: 48.51025, lng: 20.301674 },
    { lat: 48.510251, lng: 20.301675 },
    { lat: 48.510337, lng: 20.30173 },
    { lat: 48.510341, lng: 20.301732 },
    { lat: 48.510867, lng: 20.302059 },
    { lat: 48.511224, lng: 20.302145 },
    { lat: 48.511311, lng: 20.3022 },
    { lat: 48.511493, lng: 20.302183 },
    { lat: 48.511681, lng: 20.302282 },
    { lat: 48.511859, lng: 20.302251 },
    { lat: 48.511941, lng: 20.302273 },
    { lat: 48.51205, lng: 20.302198 },
    { lat: 48.512265, lng: 20.302215 },
    { lat: 48.51243, lng: 20.30237 },
    { lat: 48.512691, lng: 20.302424 },
    { lat: 48.512849, lng: 20.302207 },
    { lat: 48.513084, lng: 20.302211 },
    { lat: 48.513323, lng: 20.302314 },
    { lat: 48.513364, lng: 20.302322 },
    { lat: 48.513696, lng: 20.302579 },
    { lat: 48.514071, lng: 20.302738 },
    { lat: 48.514305, lng: 20.302881 },
    { lat: 48.514635, lng: 20.303059 },
    { lat: 48.514816, lng: 20.303105 },
    { lat: 48.514964, lng: 20.303097 },
    { lat: 48.515035, lng: 20.303158 },
    { lat: 48.515179, lng: 20.30323 },
    { lat: 48.515353, lng: 20.30323 },
    { lat: 48.515502, lng: 20.303288 },
    { lat: 48.515905, lng: 20.303328 },
    { lat: 48.516121, lng: 20.303112 },
    { lat: 48.516135, lng: 20.303135 },
    { lat: 48.516258, lng: 20.303393 },
    { lat: 48.516663, lng: 20.303287 },
    { lat: 48.516778, lng: 20.303252 },
    { lat: 48.516927, lng: 20.303254 },
    { lat: 48.517132, lng: 20.303122 },
    { lat: 48.517373, lng: 20.302981 },
    { lat: 48.517469, lng: 20.302956 },
    { lat: 48.517494, lng: 20.302803 },
    { lat: 48.517558, lng: 20.302797 },
    { lat: 48.517877, lng: 20.302695 },
    { lat: 48.51792, lng: 20.302603 },
    { lat: 48.518167, lng: 20.302571 },
    { lat: 48.518222, lng: 20.302564 },
    { lat: 48.51825, lng: 20.30256 },
    { lat: 48.518557, lng: 20.302718 },
    { lat: 48.518719, lng: 20.30275 },
    { lat: 48.518733, lng: 20.30264 },
    { lat: 48.518879, lng: 20.302672 },
    { lat: 48.519076, lng: 20.302878 },
    { lat: 48.519247, lng: 20.303013 },
    { lat: 48.519309, lng: 20.303217 },
    { lat: 48.519414, lng: 20.303328 },
    { lat: 48.519542, lng: 20.303463 },
    { lat: 48.519662, lng: 20.303575 },
    { lat: 48.519644, lng: 20.303631 },
    { lat: 48.51979, lng: 20.30373 },
    { lat: 48.519808, lng: 20.30371 },
    { lat: 48.519837, lng: 20.303917 },
    { lat: 48.519905, lng: 20.304014 },
    { lat: 48.51998, lng: 20.304274 },
    { lat: 48.519975, lng: 20.304298 },
    { lat: 48.519942, lng: 20.304367 },
    { lat: 48.51999, lng: 20.304475 },
    { lat: 48.520389, lng: 20.304879 },
    { lat: 48.520464, lng: 20.305133 },
    { lat: 48.520495, lng: 20.305211 },
    { lat: 48.520572, lng: 20.305373 },
    { lat: 48.520531, lng: 20.305622 },
    { lat: 48.520667, lng: 20.305819 },
    { lat: 48.520611, lng: 20.306101 },
    { lat: 48.520671, lng: 20.306395 },
    { lat: 48.520617, lng: 20.306664 },
    { lat: 48.520544, lng: 20.307181 },
    { lat: 48.52017, lng: 20.307363 },
    { lat: 48.520154, lng: 20.307969 },
    { lat: 48.520185, lng: 20.308124 },
    { lat: 48.520416, lng: 20.308033 },
    { lat: 48.520395, lng: 20.308256 },
    { lat: 48.520626, lng: 20.308486 },
    { lat: 48.520648, lng: 20.308667 },
    { lat: 48.520728, lng: 20.308852 },
    { lat: 48.520737, lng: 20.309016 },
    { lat: 48.520888, lng: 20.309124 },
    { lat: 48.520926, lng: 20.309471 },
    { lat: 48.521053, lng: 20.309588 },
    { lat: 48.521081, lng: 20.309862 },
    { lat: 48.520983, lng: 20.310125 },
    { lat: 48.520962, lng: 20.310368 },
    { lat: 48.520961, lng: 20.310571 },
    { lat: 48.520976, lng: 20.310725 },
    { lat: 48.521145, lng: 20.31037 },
    { lat: 48.521304, lng: 20.310075 },
    { lat: 48.521333, lng: 20.310182 },
    { lat: 48.521336, lng: 20.310192 },
    { lat: 48.521962, lng: 20.31234 },
    { lat: 48.52203, lng: 20.312564 },
    { lat: 48.522061, lng: 20.312815 },
    { lat: 48.522074, lng: 20.312929 },
    { lat: 48.522198, lng: 20.313752 },
    { lat: 48.522389, lng: 20.31471 },
    { lat: 48.52249, lng: 20.315215 },
    { lat: 48.522644, lng: 20.315973 },
    { lat: 48.522653, lng: 20.31602 },
    { lat: 48.522713, lng: 20.316288 },
    { lat: 48.522904, lng: 20.316732 },
    { lat: 48.522947, lng: 20.316838 },
    { lat: 48.522951, lng: 20.316844 },
    { lat: 48.523156, lng: 20.317177 },
    { lat: 48.523447, lng: 20.317688 },
    { lat: 48.523652, lng: 20.318064 },
    { lat: 48.523771, lng: 20.318292 },
    { lat: 48.523795, lng: 20.318338 },
    { lat: 48.523838, lng: 20.318422 },
    { lat: 48.523875, lng: 20.318493 },
    { lat: 48.523895, lng: 20.318544 },
    { lat: 48.52443, lng: 20.318899 },
    { lat: 48.524776, lng: 20.319079 },
    { lat: 48.524982, lng: 20.319154 },
    { lat: 48.525327, lng: 20.319294 },
    { lat: 48.525641, lng: 20.319437 },
    { lat: 48.525639, lng: 20.319456 },
    { lat: 48.525768, lng: 20.319597 },
    { lat: 48.525861, lng: 20.319799 },
    { lat: 48.526305, lng: 20.319999 },
    { lat: 48.526683, lng: 20.320142 },
    { lat: 48.526946, lng: 20.320383 },
    { lat: 48.527075, lng: 20.320417 },
    { lat: 48.527405, lng: 20.320493 },
    { lat: 48.527577, lng: 20.320525 },
    { lat: 48.527704, lng: 20.320564 },
    { lat: 48.527956, lng: 20.320778 },
    { lat: 48.528138, lng: 20.32083 },
    { lat: 48.528308, lng: 20.320932 },
    { lat: 48.5286, lng: 20.321013 },
    { lat: 48.528729, lng: 20.32103 },
    { lat: 48.528894, lng: 20.321049 },
    { lat: 48.529374, lng: 20.321228 },
    { lat: 48.529615, lng: 20.321382 },
    { lat: 48.529747, lng: 20.321432 },
    { lat: 48.529774, lng: 20.321443 },
    { lat: 48.530132, lng: 20.321769 },
    { lat: 48.530303, lng: 20.321998 },
    { lat: 48.530566, lng: 20.322167 },
    { lat: 48.530864, lng: 20.32231 },
    { lat: 48.531385, lng: 20.322417 },
    { lat: 48.531691, lng: 20.322506 },
    { lat: 48.532192, lng: 20.322476 },
    { lat: 48.532421, lng: 20.322377 },
    { lat: 48.532593, lng: 20.322316 },
    { lat: 48.532819, lng: 20.32228 },
    { lat: 48.533086, lng: 20.322306 },
    { lat: 48.533317, lng: 20.322329 },
    { lat: 48.533507, lng: 20.3224 },
    { lat: 48.533643, lng: 20.32245 },
    { lat: 48.533919, lng: 20.322621 },
    { lat: 48.534045, lng: 20.322682 },
    { lat: 48.534425, lng: 20.322981 },
    { lat: 48.534498, lng: 20.323046 },
    { lat: 48.534905, lng: 20.323442 },
    { lat: 48.53545, lng: 20.323848 },
    { lat: 48.535783, lng: 20.324285 },
    { lat: 48.536065, lng: 20.324519 },
    { lat: 48.536158, lng: 20.324732 },
    { lat: 48.536336, lng: 20.325296 },
    { lat: 48.536319, lng: 20.325831 },
    { lat: 48.536296, lng: 20.326123 },
    { lat: 48.536246, lng: 20.326328 },
    { lat: 48.536269, lng: 20.326524 },
    { lat: 48.536182, lng: 20.326888 },
    { lat: 48.536076, lng: 20.327154 },
    { lat: 48.535832, lng: 20.327628 },
    { lat: 48.535768, lng: 20.327896 },
    { lat: 48.535691, lng: 20.32805 },
    { lat: 48.535517, lng: 20.328367 },
    { lat: 48.53582, lng: 20.328962 },
    { lat: 48.535909, lng: 20.32929 },
    { lat: 48.536032, lng: 20.329483 },
    { lat: 48.536554, lng: 20.330659 },
    { lat: 48.536881, lng: 20.331288 },
    { lat: 48.536999, lng: 20.331464 },
    { lat: 48.537025, lng: 20.331494 },
    { lat: 48.537346, lng: 20.331713 },
    { lat: 48.537553, lng: 20.331797 },
    { lat: 48.538281, lng: 20.332092 },
    { lat: 48.538299, lng: 20.332106 },
    { lat: 48.53832, lng: 20.332122 },
    { lat: 48.538557, lng: 20.332247 },
    { lat: 48.538744, lng: 20.33226 },
    { lat: 48.539545, lng: 20.332337 },
    { lat: 48.539685, lng: 20.332265 },
    { lat: 48.539868, lng: 20.332173 },
    { lat: 48.540532, lng: 20.331836 },
    { lat: 48.540586, lng: 20.33181 },
    { lat: 48.540622, lng: 20.33179 },
    { lat: 48.541034, lng: 20.331761 },
    { lat: 48.541776, lng: 20.331531 },
    { lat: 48.542152, lng: 20.331402 },
    { lat: 48.54233, lng: 20.331341 },
    { lat: 48.542429, lng: 20.331314 },
    { lat: 48.543155, lng: 20.3312 },
    { lat: 48.543797, lng: 20.331096 },
    { lat: 48.544441, lng: 20.331132 },
    { lat: 48.545008, lng: 20.33117 },
    { lat: 48.545419, lng: 20.331164 },
    { lat: 48.545918, lng: 20.331157 },
    { lat: 48.546514, lng: 20.331138 },
    { lat: 48.546957, lng: 20.331133 },
    { lat: 48.547298, lng: 20.331059 },
    { lat: 48.54781, lng: 20.330955 },
    { lat: 48.548059, lng: 20.330958 },
    { lat: 48.5485, lng: 20.330969 },
    { lat: 48.548552, lng: 20.330967 },
    { lat: 48.548591, lng: 20.330968 },
    { lat: 48.548639, lng: 20.330969 },
    { lat: 48.548733, lng: 20.330972 },
    { lat: 48.549111, lng: 20.331051 },
    { lat: 48.549698, lng: 20.331175 },
    { lat: 48.550335, lng: 20.331275 },
    { lat: 48.550588, lng: 20.331314 },
    { lat: 48.550623, lng: 20.331282 },
    { lat: 48.5488086, lng: 20.3281788 },
    { lat: 48.5483039, lng: 20.3269906 },
    { lat: 48.5478063, lng: 20.3254431 },
    { lat: 48.5477886, lng: 20.3240141 },
    { lat: 48.547683, lng: 20.3233538 },
    { lat: 48.5473717, lng: 20.3223923 },
    { lat: 48.5469399, lng: 20.3214305 },
    { lat: 48.5458761, lng: 20.3199178 },
    { lat: 48.5457923, lng: 20.3165129 },
    { lat: 48.5459124, lng: 20.3155557 },
    { lat: 48.5460194, lng: 20.31527 },
    { lat: 48.5459297, lng: 20.3148789 },
    { lat: 48.546012, lng: 20.3144191 },
    { lat: 48.5460845, lng: 20.3142584 },
    { lat: 48.5464816, lng: 20.3126077 },
    { lat: 48.5462837, lng: 20.3099511 },
    { lat: 48.5461448, lng: 20.3087945 },
    { lat: 48.545989, lng: 20.3082088 },
    { lat: 48.5457911, lng: 20.3077317 },
    { lat: 48.545795, lng: 20.3076251 },
    { lat: 48.5457131, lng: 20.3075169 },
    { lat: 48.5456291, lng: 20.3072485 },
    { lat: 48.5455686, lng: 20.3061639 },
    { lat: 48.5454215, lng: 20.3060597 },
    { lat: 48.545475, lng: 20.3049577 },
    { lat: 48.5456404, lng: 20.3036379 },
    { lat: 48.5457909, lng: 20.3027942 },
    { lat: 48.5460273, lng: 20.3018156 },
    { lat: 48.5462249, lng: 20.3015698 },
    { lat: 48.5463717, lng: 20.3016129 },
    { lat: 48.5465412, lng: 20.3013397 },
    { lat: 48.5465846, lng: 20.3011511 },
    { lat: 48.5470411, lng: 20.3013343 },
    { lat: 48.5473957, lng: 20.3013915 },
    { lat: 48.5475957, lng: 20.3021527 },
    { lat: 48.5482072, lng: 20.3020623 },
    { lat: 48.5483622, lng: 20.3021391 },
    { lat: 48.5489106, lng: 20.3019107 },
    { lat: 48.5489992, lng: 20.3017272 },
    { lat: 48.5489651, lng: 20.301436 },
    { lat: 48.5488725, lng: 20.3012836 },
    { lat: 48.5485716, lng: 20.3011405 },
    { lat: 48.5484453, lng: 20.3011912 },
    { lat: 48.5484208, lng: 20.3010804 },
    { lat: 48.5485239, lng: 20.3008696 },
    { lat: 48.5484273, lng: 20.3006742 },
    { lat: 48.5486882, lng: 20.3001796 },
    { lat: 48.5487781, lng: 20.2997419 },
    { lat: 48.5488698, lng: 20.2997273 },
    { lat: 48.5488777, lng: 20.2998972 },
    { lat: 48.5490271, lng: 20.3000307 },
    { lat: 48.5491905, lng: 20.3003509 },
    { lat: 48.549305, lng: 20.3003397 },
    { lat: 48.5494731, lng: 20.3002035 },
    { lat: 48.5496059, lng: 20.3000032 },
    { lat: 48.5499325, lng: 20.3004902 },
    { lat: 48.5501682, lng: 20.3004119 },
    { lat: 48.55029, lng: 20.3002447 },
    { lat: 48.550499, lng: 20.299546 },
    { lat: 48.5506459, lng: 20.2993559 },
    { lat: 48.5510465, lng: 20.2995512 },
    { lat: 48.5511152, lng: 20.2994626 },
    { lat: 48.5511323, lng: 20.2993264 },
    { lat: 48.5512208, lng: 20.299285 },
    { lat: 48.5515185, lng: 20.2992389 },
    { lat: 48.5520196, lng: 20.2994093 },
    { lat: 48.5523842, lng: 20.2993437 },
    { lat: 48.5525209, lng: 20.2990403 },
    { lat: 48.5525198, lng: 20.2988972 },
    { lat: 48.5523328, lng: 20.2989263 },
    { lat: 48.5521939, lng: 20.2988331 },
    { lat: 48.5521842, lng: 20.2986698 },
    { lat: 48.5526535, lng: 20.2983737 },
    { lat: 48.5526543, lng: 20.2978241 },
    { lat: 48.5526984, lng: 20.297625 },
    { lat: 48.5529215, lng: 20.2976566 },
    { lat: 48.5529906, lng: 20.2975847 },
    { lat: 48.5530504, lng: 20.2976696 },
    { lat: 48.55299, lng: 20.2978282 },
    { lat: 48.5529155, lng: 20.2978765 },
    { lat: 48.5529405, lng: 20.2980267 },
    { lat: 48.5532065, lng: 20.2983457 },
    { lat: 48.5534255, lng: 20.2983638 },
    { lat: 48.553519, lng: 20.2982758 },
    { lat: 48.5535888, lng: 20.2979909 },
    { lat: 48.5537016, lng: 20.297877 },
    { lat: 48.5536556, lng: 20.2977393 },
    { lat: 48.5536932, lng: 20.2976101 },
    { lat: 48.5538363, lng: 20.2976133 },
    { lat: 48.5539374, lng: 20.2978055 },
    { lat: 48.554079, lng: 20.2978256 },
    { lat: 48.5541417, lng: 20.2977034 },
    { lat: 48.5540837, lng: 20.2976023 },
    { lat: 48.5541081, lng: 20.2975094 },
    { lat: 48.5542893, lng: 20.2974168 },
    { lat: 48.5543818, lng: 20.2971889 },
    { lat: 48.5542738, lng: 20.2969636 },
    { lat: 48.5542283, lng: 20.2969523 },
    { lat: 48.5540807, lng: 20.297209 },
    { lat: 48.5539604, lng: 20.2971863 },
    { lat: 48.5538441, lng: 20.2969539 },
    { lat: 48.5539004, lng: 20.2967758 },
    { lat: 48.5540006, lng: 20.2966673 },
    { lat: 48.5541831, lng: 20.2965486 },
    { lat: 48.5545434, lng: 20.2964635 },
    { lat: 48.5544761, lng: 20.2952093 },
    { lat: 48.5548638, lng: 20.2949916 },
    { lat: 48.5551635, lng: 20.2943619 },
    { lat: 48.5557829, lng: 20.2941327 },
    { lat: 48.5566483, lng: 20.2935474 },
    { lat: 48.5570649, lng: 20.293459 },
    { lat: 48.5575749, lng: 20.2935968 },
    { lat: 48.5590364, lng: 20.2945918 },
    { lat: 48.5593499, lng: 20.294744 },
    { lat: 48.5601358, lng: 20.2948 },
    { lat: 48.5606232, lng: 20.2945093 },
    { lat: 48.56061, lng: 20.2944856 },
    { lat: 48.5600365, lng: 20.2931354 },
    { lat: 48.5598325, lng: 20.293277 },
    { lat: 48.5596121, lng: 20.2930802 },
    { lat: 48.5592768, lng: 20.2925762 },
    { lat: 48.5590366, lng: 20.2915365 },
    { lat: 48.5586945, lng: 20.2907412 },
    { lat: 48.558454, lng: 20.2881309 },
    { lat: 48.5584966, lng: 20.2851233 },
    { lat: 48.5583013, lng: 20.2844863 },
    { lat: 48.5573728, lng: 20.2804193 },
    { lat: 48.5572597, lng: 20.2797241 },
    { lat: 48.5570935, lng: 20.2791673 },
    { lat: 48.5569811, lng: 20.2788845 },
    { lat: 48.5567003, lng: 20.2785638 },
    { lat: 48.5564204, lng: 20.2781203 },
    { lat: 48.5557085, lng: 20.2763494 },
    { lat: 48.5555162, lng: 20.2761008 },
    { lat: 48.5551111, lng: 20.2746281 },
    { lat: 48.5549339, lng: 20.2731381 },
    { lat: 48.5549337, lng: 20.272339 },
    { lat: 48.5550804, lng: 20.2708117 },
    { lat: 48.5552818, lng: 20.2696923 },
    { lat: 48.5552294, lng: 20.2689117 },
    { lat: 48.5552853, lng: 20.2681612 },
    { lat: 48.5549185, lng: 20.2673478 },
    { lat: 48.5547742, lng: 20.2667443 },
    { lat: 48.5541305, lng: 20.265703 },
    { lat: 48.5542031, lng: 20.265517 },
    { lat: 48.5541763, lng: 20.2654127 },
  ],
  Chvalová: [
    { lat: 48.532947, lng: 20.1636518 },
    { lat: 48.5329671, lng: 20.1635366 },
    { lat: 48.5330836, lng: 20.1628834 },
    { lat: 48.5328555, lng: 20.1627281 },
    { lat: 48.5330091, lng: 20.1623249 },
    { lat: 48.5330686, lng: 20.1607614 },
    { lat: 48.5332504, lng: 20.160523 },
    { lat: 48.5334417, lng: 20.1599926 },
    { lat: 48.5334085, lng: 20.1598954 },
    { lat: 48.5334756, lng: 20.1590829 },
    { lat: 48.5333753, lng: 20.1583547 },
    { lat: 48.533533, lng: 20.157915 },
    { lat: 48.532854, lng: 20.157242 },
    { lat: 48.53284, lng: 20.157228 },
    { lat: 48.532782, lng: 20.157168 },
    { lat: 48.532185, lng: 20.156588 },
    { lat: 48.531619, lng: 20.15588 },
    { lat: 48.530952, lng: 20.155347 },
    { lat: 48.530494, lng: 20.154794 },
    { lat: 48.530149, lng: 20.154061 },
    { lat: 48.529247, lng: 20.153398 },
    { lat: 48.528682, lng: 20.153036 },
    { lat: 48.528459, lng: 20.152885 },
    { lat: 48.528311, lng: 20.152784 },
    { lat: 48.528209, lng: 20.152715 },
    { lat: 48.528114, lng: 20.152652 },
    { lat: 48.528063, lng: 20.152619 },
    { lat: 48.52787, lng: 20.152491 },
    { lat: 48.527722, lng: 20.152393 },
    { lat: 48.52757, lng: 20.152294 },
    { lat: 48.527538, lng: 20.152272 },
    { lat: 48.527484, lng: 20.152236 },
    { lat: 48.527458, lng: 20.15222 },
    { lat: 48.527412, lng: 20.152189 },
    { lat: 48.527379, lng: 20.152167 },
    { lat: 48.527347, lng: 20.152145 },
    { lat: 48.527317, lng: 20.152125 },
    { lat: 48.527302, lng: 20.152115 },
    { lat: 48.527252, lng: 20.152082 },
    { lat: 48.5272, lng: 20.152046 },
    { lat: 48.527156, lng: 20.152018 },
    { lat: 48.527051, lng: 20.151947 },
    { lat: 48.527008, lng: 20.151919 },
    { lat: 48.526953, lng: 20.151883 },
    { lat: 48.526918, lng: 20.151858 },
    { lat: 48.526843, lng: 20.151809 },
    { lat: 48.526795, lng: 20.151776 },
    { lat: 48.526771, lng: 20.151759 },
    { lat: 48.526741, lng: 20.151741 },
    { lat: 48.526714, lng: 20.151723 },
    { lat: 48.526674, lng: 20.151687 },
    { lat: 48.52664, lng: 20.151657 },
    { lat: 48.526611, lng: 20.15163 },
    { lat: 48.526582, lng: 20.151605 },
    { lat: 48.526554, lng: 20.151579 },
    { lat: 48.526511, lng: 20.151541 },
    { lat: 48.526461, lng: 20.151497 },
    { lat: 48.526437, lng: 20.151475 },
    { lat: 48.526399, lng: 20.151442 },
    { lat: 48.526361, lng: 20.151408 },
    { lat: 48.526344, lng: 20.151393 },
    { lat: 48.526329, lng: 20.15138 },
    { lat: 48.526321, lng: 20.151372 },
    { lat: 48.526278, lng: 20.151333 },
    { lat: 48.526253, lng: 20.151312 },
    { lat: 48.526204, lng: 20.151268 },
    { lat: 48.52616, lng: 20.151228 },
    { lat: 48.526139, lng: 20.151209 },
    { lat: 48.526114, lng: 20.151188 },
    { lat: 48.526094, lng: 20.15117 },
    { lat: 48.526075, lng: 20.151153 },
    { lat: 48.526059, lng: 20.151138 },
    { lat: 48.526026, lng: 20.151097 },
    { lat: 48.525981, lng: 20.151041 },
    { lat: 48.52596, lng: 20.151015 },
    { lat: 48.525929, lng: 20.150976 },
    { lat: 48.525898, lng: 20.150937 },
    { lat: 48.525849, lng: 20.150878 },
    { lat: 48.525798, lng: 20.150813 },
    { lat: 48.525748, lng: 20.150751 },
    { lat: 48.525727, lng: 20.150724 },
    { lat: 48.525706, lng: 20.150697 },
    { lat: 48.525684, lng: 20.150671 },
    { lat: 48.525651, lng: 20.15063 },
    { lat: 48.52563, lng: 20.150603 },
    { lat: 48.525339, lng: 20.15024 },
    { lat: 48.524525, lng: 20.149631 },
    { lat: 48.524128, lng: 20.149333 },
    { lat: 48.524083, lng: 20.149298 },
    { lat: 48.524065, lng: 20.149283 },
    { lat: 48.523474, lng: 20.148818 },
    { lat: 48.523013, lng: 20.148459 },
    { lat: 48.522697, lng: 20.148219 },
    { lat: 48.522536, lng: 20.148007 },
    { lat: 48.522504, lng: 20.147966 },
    { lat: 48.522477, lng: 20.147931 },
    { lat: 48.522443, lng: 20.147889 },
    { lat: 48.522189, lng: 20.147556 },
    { lat: 48.521596, lng: 20.147068 },
    { lat: 48.521453, lng: 20.14693 },
    { lat: 48.52121, lng: 20.146672 },
    { lat: 48.521186, lng: 20.146646 },
    { lat: 48.520609, lng: 20.145996 },
    { lat: 48.520526, lng: 20.145902 },
    { lat: 48.520338, lng: 20.145687 },
    { lat: 48.519994, lng: 20.145296 },
    { lat: 48.519967, lng: 20.145072 },
    { lat: 48.519696, lng: 20.145023 },
    { lat: 48.519461, lng: 20.145033 },
    { lat: 48.519201, lng: 20.14506 },
    { lat: 48.518782, lng: 20.145103 },
    { lat: 48.518727, lng: 20.145108 },
    { lat: 48.518569, lng: 20.145125 },
    { lat: 48.517616, lng: 20.145206 },
    { lat: 48.51703, lng: 20.145259 },
    { lat: 48.516992, lng: 20.145263 },
    { lat: 48.51624, lng: 20.145331 },
    { lat: 48.514942, lng: 20.145698 },
    { lat: 48.514886, lng: 20.145713 },
    { lat: 48.514719, lng: 20.145751 },
    { lat: 48.5146, lng: 20.145779 },
    { lat: 48.514576, lng: 20.145768 },
    { lat: 48.514539, lng: 20.145748 },
    { lat: 48.51382, lng: 20.145392 },
    { lat: 48.513045, lng: 20.145147 },
    { lat: 48.512516, lng: 20.14482 },
    { lat: 48.512023, lng: 20.144741 },
    { lat: 48.511478, lng: 20.144692 },
    { lat: 48.511231, lng: 20.144803 },
    { lat: 48.510935, lng: 20.144938 },
    { lat: 48.510609, lng: 20.145087 },
    { lat: 48.510523, lng: 20.145285 },
    { lat: 48.510384, lng: 20.145507 },
    { lat: 48.510174, lng: 20.145844 },
    { lat: 48.510064, lng: 20.146067 },
    { lat: 48.509942, lng: 20.146312 },
    { lat: 48.509698, lng: 20.146762 },
    { lat: 48.509616, lng: 20.146919 },
    { lat: 48.509409, lng: 20.147159 },
    { lat: 48.509247, lng: 20.14735 },
    { lat: 48.509109, lng: 20.14753 },
    { lat: 48.508832, lng: 20.147902 },
    { lat: 48.508585, lng: 20.148208 },
    { lat: 48.508293, lng: 20.148231 },
    { lat: 48.507977, lng: 20.148353 },
    { lat: 48.50787, lng: 20.148392 },
    { lat: 48.507683, lng: 20.148585 },
    { lat: 48.507455, lng: 20.14861 },
    { lat: 48.50718, lng: 20.148726 },
    { lat: 48.506903, lng: 20.148777 },
    { lat: 48.506852, lng: 20.148783 },
    { lat: 48.506786, lng: 20.148768 },
    { lat: 48.506615, lng: 20.148718 },
    { lat: 48.506388, lng: 20.148641 },
    { lat: 48.506289, lng: 20.148444 },
    { lat: 48.506144, lng: 20.148293 },
    { lat: 48.505877, lng: 20.148153 },
    { lat: 48.505447, lng: 20.148 },
    { lat: 48.505181, lng: 20.14775 },
    { lat: 48.505056, lng: 20.147627 },
    { lat: 48.504923, lng: 20.147472 },
    { lat: 48.504702, lng: 20.147216 },
    { lat: 48.504609, lng: 20.147106 },
    { lat: 48.504379, lng: 20.146981 },
    { lat: 48.504128, lng: 20.146839 },
    { lat: 48.503895, lng: 20.146668 },
    { lat: 48.503328, lng: 20.146239 },
    { lat: 48.503066, lng: 20.145971 },
    { lat: 48.502841, lng: 20.145733 },
    { lat: 48.502715, lng: 20.145783 },
    { lat: 48.502425, lng: 20.145908 },
    { lat: 48.502212, lng: 20.145996 },
    { lat: 48.501923, lng: 20.145999 },
    { lat: 48.501655, lng: 20.146003 },
    { lat: 48.50137, lng: 20.145927 },
    { lat: 48.501175, lng: 20.145874 },
    { lat: 48.500829, lng: 20.145785 },
    { lat: 48.500534, lng: 20.145735 },
    { lat: 48.500229, lng: 20.145676 },
    { lat: 48.499949, lng: 20.145597 },
    { lat: 48.499789, lng: 20.145553 },
    { lat: 48.499677, lng: 20.145446 },
    { lat: 48.499475, lng: 20.145251 },
    { lat: 48.499359, lng: 20.145134 },
    { lat: 48.498851, lng: 20.144905 },
    { lat: 48.49871, lng: 20.144961 },
    { lat: 48.498671, lng: 20.144984 },
    { lat: 48.498564, lng: 20.145097 },
    { lat: 48.498339, lng: 20.145334 },
    { lat: 48.49823, lng: 20.145447 },
    { lat: 48.498022, lng: 20.145535 },
    { lat: 48.497689, lng: 20.145678 },
    { lat: 48.497486, lng: 20.145874 },
    { lat: 48.497135, lng: 20.146195 },
    { lat: 48.496944, lng: 20.146372 },
    { lat: 48.496812, lng: 20.146573 },
    { lat: 48.496628, lng: 20.146854 },
    { lat: 48.496482, lng: 20.1471 },
    { lat: 48.496378, lng: 20.147471 },
    { lat: 48.496278, lng: 20.147818 },
    { lat: 48.496228, lng: 20.148239 },
    { lat: 48.49614, lng: 20.148929 },
    { lat: 48.496037, lng: 20.149268 },
    { lat: 48.495962, lng: 20.149512 },
    { lat: 48.49598, lng: 20.149741 },
    { lat: 48.496019, lng: 20.150186 },
    { lat: 48.49604, lng: 20.150394 },
    { lat: 48.496067, lng: 20.150654 },
    { lat: 48.496101, lng: 20.150945 },
    { lat: 48.496159, lng: 20.151518 },
    { lat: 48.496249, lng: 20.151778 },
    { lat: 48.496232, lng: 20.152028 },
    { lat: 48.496211, lng: 20.15239 },
    { lat: 48.496189, lng: 20.15277 },
    { lat: 48.496113, lng: 20.153542 },
    { lat: 48.49608, lng: 20.153901 },
    { lat: 48.496039, lng: 20.154218 },
    { lat: 48.496045, lng: 20.154559 },
    { lat: 48.496007, lng: 20.154798 },
    { lat: 48.495962, lng: 20.155095 },
    { lat: 48.495905, lng: 20.155534 },
    { lat: 48.495827, lng: 20.156162 },
    { lat: 48.495782, lng: 20.156578 },
    { lat: 48.495653, lng: 20.156781 },
    { lat: 48.495478, lng: 20.157058 },
    { lat: 48.49544, lng: 20.157127 },
    { lat: 48.495416, lng: 20.157257 },
    { lat: 48.495463, lng: 20.157321 },
    { lat: 48.495362, lng: 20.157443 },
    { lat: 48.495273, lng: 20.157551 },
    { lat: 48.495229, lng: 20.157586 },
    { lat: 48.495153, lng: 20.157694 },
    { lat: 48.494961, lng: 20.157966 },
    { lat: 48.49473, lng: 20.158326 },
    { lat: 48.494647, lng: 20.158458 },
    { lat: 48.49444, lng: 20.158854 },
    { lat: 48.494267, lng: 20.159269 },
    { lat: 48.494048, lng: 20.159795 },
    { lat: 48.493865, lng: 20.160252 },
    { lat: 48.493807, lng: 20.160393 },
    { lat: 48.493662, lng: 20.160704 },
    { lat: 48.49359, lng: 20.160865 },
    { lat: 48.493493, lng: 20.161121 },
    { lat: 48.493431, lng: 20.1613 },
    { lat: 48.493359, lng: 20.161441 },
    { lat: 48.493164, lng: 20.161827 },
    { lat: 48.493123, lng: 20.161905 },
    { lat: 48.493087, lng: 20.162045 },
    { lat: 48.49301, lng: 20.162345 },
    { lat: 48.492973, lng: 20.162467 },
    { lat: 48.492904, lng: 20.162665 },
    { lat: 48.492791, lng: 20.162934 },
    { lat: 48.492756, lng: 20.163116 },
    { lat: 48.4929097, lng: 20.163696 },
    { lat: 48.493945, lng: 20.1656722 },
    { lat: 48.4954874, lng: 20.1682397 },
    { lat: 48.4976254, lng: 20.1720014 },
    { lat: 48.4985029, lng: 20.1734516 },
    { lat: 48.5008988, lng: 20.1768779 },
    { lat: 48.501029, lng: 20.1767313 },
    { lat: 48.5090903, lng: 20.1864386 },
    { lat: 48.5093995, lng: 20.1857841 },
    { lat: 48.5095659, lng: 20.1854453 },
    { lat: 48.5111545, lng: 20.1822485 },
    { lat: 48.5112849, lng: 20.1821861 },
    { lat: 48.5127841, lng: 20.1804582 },
    { lat: 48.5130524, lng: 20.1806902 },
    { lat: 48.5155932, lng: 20.1788476 },
    { lat: 48.5157138, lng: 20.1789591 },
    { lat: 48.5159534, lng: 20.1788143 },
    { lat: 48.5160658, lng: 20.1786402 },
    { lat: 48.5161565, lng: 20.1786301 },
    { lat: 48.5164147, lng: 20.1789696 },
    { lat: 48.5167214, lng: 20.1790594 },
    { lat: 48.5168905, lng: 20.1790383 },
    { lat: 48.5169351, lng: 20.1791372 },
    { lat: 48.5169486, lng: 20.1796646 },
    { lat: 48.5182586, lng: 20.1796448 },
    { lat: 48.5197453, lng: 20.1795574 },
    { lat: 48.5197585, lng: 20.1794642 },
    { lat: 48.5198891, lng: 20.1794955 },
    { lat: 48.5201516, lng: 20.1804734 },
    { lat: 48.5203755, lng: 20.1796279 },
    { lat: 48.5210088, lng: 20.1796371 },
    { lat: 48.5209205, lng: 20.1805476 },
    { lat: 48.5213409, lng: 20.1804679 },
    { lat: 48.521268, lng: 20.1795036 },
    { lat: 48.5216516, lng: 20.1793246 },
    { lat: 48.5222882, lng: 20.1793029 },
    { lat: 48.5225066, lng: 20.1796321 },
    { lat: 48.5229522, lng: 20.179872 },
    { lat: 48.5230772, lng: 20.1799277 },
    { lat: 48.5233206, lng: 20.1798479 },
    { lat: 48.5238527, lng: 20.1793017 },
    { lat: 48.5239626, lng: 20.1792899 },
    { lat: 48.5241496, lng: 20.1789278 },
    { lat: 48.5244723, lng: 20.1785799 },
    { lat: 48.5247463, lng: 20.1785924 },
    { lat: 48.5249087, lng: 20.1781785 },
    { lat: 48.5255523, lng: 20.1776858 },
    { lat: 48.525775, lng: 20.1773744 },
    { lat: 48.525938, lng: 20.177309 },
    { lat: 48.5261039, lng: 20.1773492 },
    { lat: 48.5262393, lng: 20.1772437 },
    { lat: 48.5263798, lng: 20.1769821 },
    { lat: 48.5264618, lng: 20.1765059 },
    { lat: 48.5265799, lng: 20.1761643 },
    { lat: 48.5268284, lng: 20.175848 },
    { lat: 48.5269154, lng: 20.1756585 },
    { lat: 48.5269538, lng: 20.1751902 },
    { lat: 48.5271521, lng: 20.1744376 },
    { lat: 48.527339, lng: 20.1741884 },
    { lat: 48.5278072, lng: 20.1739003 },
    { lat: 48.5279306, lng: 20.173636 },
    { lat: 48.5280745, lng: 20.1736051 },
    { lat: 48.5285434, lng: 20.1726947 },
    { lat: 48.5286506, lng: 20.1726724 },
    { lat: 48.5289031, lng: 20.1727882 },
    { lat: 48.5289178, lng: 20.1724287 },
    { lat: 48.529046, lng: 20.172284 },
    { lat: 48.5290675, lng: 20.1723137 },
    { lat: 48.5293771, lng: 20.1717803 },
    { lat: 48.5292329, lng: 20.1715889 },
    { lat: 48.5292283, lng: 20.1714461 },
    { lat: 48.5293471, lng: 20.1711499 },
    { lat: 48.5294919, lng: 20.1709436 },
    { lat: 48.5296727, lng: 20.1708233 },
    { lat: 48.529859, lng: 20.1708588 },
    { lat: 48.5299082, lng: 20.1707978 },
    { lat: 48.5299583, lng: 20.1707011 },
    { lat: 48.5299616, lng: 20.1703338 },
    { lat: 48.5300437, lng: 20.1700523 },
    { lat: 48.5303829, lng: 20.1697784 },
    { lat: 48.5305596, lng: 20.1698073 },
    { lat: 48.5307425, lng: 20.169701 },
    { lat: 48.5307411, lng: 20.1693628 },
    { lat: 48.5308745, lng: 20.1692664 },
    { lat: 48.5309536, lng: 20.1690214 },
    { lat: 48.5310635, lng: 20.1689573 },
    { lat: 48.5311877, lng: 20.1687121 },
    { lat: 48.5313906, lng: 20.1685182 },
    { lat: 48.5314771, lng: 20.1682932 },
    { lat: 48.5318501, lng: 20.1677142 },
    { lat: 48.5320574, lng: 20.1671571 },
    { lat: 48.5322934, lng: 20.1667303 },
    { lat: 48.5325079, lng: 20.1660717 },
    { lat: 48.5325621, lng: 20.1649402 },
    { lat: 48.5324428, lng: 20.1648026 },
    { lat: 48.5323355, lng: 20.164853 },
    { lat: 48.5322218, lng: 20.1643531 },
    { lat: 48.5324563, lng: 20.1637158 },
    { lat: 48.5326768, lng: 20.1638522 },
    { lat: 48.5328096, lng: 20.1635689 },
    { lat: 48.5329049, lng: 20.1636159 },
    { lat: 48.532947, lng: 20.1636518 },
  ],
  Lubeník: [
    { lat: 48.6593495, lng: 20.1755223 },
    { lat: 48.6586453, lng: 20.1766347 },
    { lat: 48.6585983, lng: 20.1762176 },
    { lat: 48.6587539, lng: 20.1744947 },
    { lat: 48.6587944, lng: 20.1733507 },
    { lat: 48.6587483, lng: 20.1732381 },
    { lat: 48.6586832, lng: 20.1725483 },
    { lat: 48.6586874, lng: 20.1718414 },
    { lat: 48.6587584, lng: 20.1713134 },
    { lat: 48.658973, lng: 20.1704611 },
    { lat: 48.6595781, lng: 20.1697478 },
    { lat: 48.6597566, lng: 20.1691713 },
    { lat: 48.6599008, lng: 20.1689486 },
    { lat: 48.6602939, lng: 20.1680869 },
    { lat: 48.6606274, lng: 20.1668902 },
    { lat: 48.6610489, lng: 20.1661427 },
    { lat: 48.66116, lng: 20.1652707 },
    { lat: 48.661086, lng: 20.1643049 },
    { lat: 48.6609082, lng: 20.1637773 },
    { lat: 48.6608485, lng: 20.1633613 },
    { lat: 48.6608583, lng: 20.1623785 },
    { lat: 48.660775, lng: 20.162129 },
    { lat: 48.6607181, lng: 20.1615856 },
    { lat: 48.6607437, lng: 20.1614061 },
    { lat: 48.6607113, lng: 20.1609453 },
    { lat: 48.6607786, lng: 20.1605714 },
    { lat: 48.660755, lng: 20.1586836 },
    { lat: 48.6608614, lng: 20.1580278 },
    { lat: 48.6608306, lng: 20.15774 },
    { lat: 48.6606782, lng: 20.1571765 },
    { lat: 48.6604968, lng: 20.1570098 },
    { lat: 48.6602704, lng: 20.1565358 },
    { lat: 48.6601828, lng: 20.1561213 },
    { lat: 48.6600847, lng: 20.155897 },
    { lat: 48.6597289, lng: 20.1554268 },
    { lat: 48.6593578, lng: 20.1551326 },
    { lat: 48.6585488, lng: 20.154208 },
    { lat: 48.6582669, lng: 20.1537865 },
    { lat: 48.6578425, lng: 20.1534 },
    { lat: 48.6571982, lng: 20.1537032 },
    { lat: 48.6567574, lng: 20.1542768 },
    { lat: 48.6565381, lng: 20.154426 },
    { lat: 48.6559684, lng: 20.1545676 },
    { lat: 48.6557044, lng: 20.1545068 },
    { lat: 48.6543427, lng: 20.1550023 },
    { lat: 48.6541825, lng: 20.1550217 },
    { lat: 48.6537087, lng: 20.1548097 },
    { lat: 48.6529325, lng: 20.1549728 },
    { lat: 48.6525188, lng: 20.1549098 },
    { lat: 48.6523305, lng: 20.1550166 },
    { lat: 48.6518364, lng: 20.1556663 },
    { lat: 48.6514588, lng: 20.1559641 },
    { lat: 48.6508644, lng: 20.1566059 },
    { lat: 48.6506105, lng: 20.1570671 },
    { lat: 48.649846, lng: 20.1577713 },
    { lat: 48.6492684, lng: 20.157997 },
    { lat: 48.6488631, lng: 20.1582822 },
    { lat: 48.6485016, lng: 20.1584185 },
    { lat: 48.6483928, lng: 20.1585981 },
    { lat: 48.648225, lng: 20.1586495 },
    { lat: 48.647892, lng: 20.1585509 },
    { lat: 48.647725, lng: 20.1586251 },
    { lat: 48.6475079, lng: 20.15894 },
    { lat: 48.6473687, lng: 20.1590334 },
    { lat: 48.6469311, lng: 20.1591318 },
    { lat: 48.6463478, lng: 20.1595274 },
    { lat: 48.6460839, lng: 20.1599621 },
    { lat: 48.6458678, lng: 20.1616036 },
    { lat: 48.6456693, lng: 20.1620468 },
    { lat: 48.645152, lng: 20.1625379 },
    { lat: 48.6448486, lng: 20.1628016 },
    { lat: 48.6445502, lng: 20.1628647 },
    { lat: 48.6439101, lng: 20.1628243 },
    { lat: 48.6433726, lng: 20.1631527 },
    { lat: 48.6417526, lng: 20.1645658 },
    { lat: 48.6416515, lng: 20.1658522 },
    { lat: 48.6417808, lng: 20.1665714 },
    { lat: 48.6418012, lng: 20.1674364 },
    { lat: 48.641417, lng: 20.1689963 },
    { lat: 48.6413338, lng: 20.1696963 },
    { lat: 48.6410802, lng: 20.1702769 },
    { lat: 48.6407004, lng: 20.1716669 },
    { lat: 48.6403726, lng: 20.1723612 },
    { lat: 48.6405215, lng: 20.1725608 },
    { lat: 48.6408702, lng: 20.1726912 },
    { lat: 48.6417513, lng: 20.174039 },
    { lat: 48.6424038, lng: 20.1746924 },
    { lat: 48.6425133, lng: 20.1751118 },
    { lat: 48.6428698, lng: 20.1754662 },
    { lat: 48.6431604, lng: 20.1758832 },
    { lat: 48.643538, lng: 20.176199 },
    { lat: 48.6442752, lng: 20.177355 },
    { lat: 48.6446209, lng: 20.1778055 },
    { lat: 48.6449098, lng: 20.1786825 },
    { lat: 48.6450263, lng: 20.1787812 },
    { lat: 48.6451171, lng: 20.1789666 },
    { lat: 48.645181, lng: 20.1792638 },
    { lat: 48.6451762, lng: 20.1795079 },
    { lat: 48.6453711, lng: 20.1802098 },
    { lat: 48.6453911, lng: 20.1804836 },
    { lat: 48.6455044, lng: 20.1806135 },
    { lat: 48.6456584, lng: 20.1811087 },
    { lat: 48.6457024, lng: 20.1814729 },
    { lat: 48.645912, lng: 20.181614 },
    { lat: 48.6463848, lng: 20.1816928 },
    { lat: 48.6466323, lng: 20.1815454 },
    { lat: 48.6469131, lng: 20.1814737 },
    { lat: 48.6471562, lng: 20.1814977 },
    { lat: 48.6472153, lng: 20.181675 },
    { lat: 48.6476969, lng: 20.1822822 },
    { lat: 48.6486034, lng: 20.1826682 },
    { lat: 48.6486427, lng: 20.1830589 },
    { lat: 48.6488493, lng: 20.183489 },
    { lat: 48.6491038, lng: 20.1842105 },
    { lat: 48.6494041, lng: 20.1853804 },
    { lat: 48.6494796, lng: 20.1855199 },
    { lat: 48.6494808, lng: 20.1856915 },
    { lat: 48.6494393, lng: 20.18578 },
    { lat: 48.6494447, lng: 20.1863605 },
    { lat: 48.6492678, lng: 20.1869437 },
    { lat: 48.6491782, lng: 20.1875436 },
    { lat: 48.6490857, lng: 20.1877971 },
    { lat: 48.6490251, lng: 20.1884434 },
    { lat: 48.6489966, lng: 20.1895822 },
    { lat: 48.6489236, lng: 20.1901727 },
    { lat: 48.6492385, lng: 20.1909798 },
    { lat: 48.6492519, lng: 20.191313 },
    { lat: 48.6490062, lng: 20.1936614 },
    { lat: 48.648988, lng: 20.1942721 },
    { lat: 48.6494638, lng: 20.1975097 },
    { lat: 48.6490695, lng: 20.1984052 },
    { lat: 48.6513722, lng: 20.2010047 },
    { lat: 48.6514388, lng: 20.201317 },
    { lat: 48.6512994, lng: 20.2019958 },
    { lat: 48.6514039, lng: 20.2023532 },
    { lat: 48.6514262, lng: 20.2026874 },
    { lat: 48.6517506, lng: 20.2028832 },
    { lat: 48.652487, lng: 20.2031622 },
    { lat: 48.6529208, lng: 20.2030264 },
    { lat: 48.6530479, lng: 20.2030775 },
    { lat: 48.6533771, lng: 20.203342 },
    { lat: 48.6534711, lng: 20.2033259 },
    { lat: 48.6538888, lng: 20.2035439 },
    { lat: 48.6543276, lng: 20.2038985 },
    { lat: 48.6544564, lng: 20.2041913 },
    { lat: 48.6547849, lng: 20.204432 },
    { lat: 48.6549063, lng: 20.2040918 },
    { lat: 48.6552083, lng: 20.2033073 },
    { lat: 48.6567426, lng: 20.2005667 },
    { lat: 48.6575634, lng: 20.1992114 },
    { lat: 48.6583612, lng: 20.1987444 },
    { lat: 48.6587019, lng: 20.1983367 },
    { lat: 48.6591692, lng: 20.1963425 },
    { lat: 48.6602088, lng: 20.1937098 },
    { lat: 48.6607746, lng: 20.1919811 },
    { lat: 48.6612803, lng: 20.1924895 },
    { lat: 48.6613386, lng: 20.1923947 },
    { lat: 48.6616922, lng: 20.1908532 },
    { lat: 48.6621382, lng: 20.190962 },
    { lat: 48.6633274, lng: 20.1918008 },
    { lat: 48.6641938, lng: 20.1917549 },
    { lat: 48.6646861, lng: 20.1918135 },
    { lat: 48.6646662, lng: 20.1915709 },
    { lat: 48.6662314, lng: 20.186324 },
    { lat: 48.6666705, lng: 20.1857938 },
    { lat: 48.6667379, lng: 20.1852581 },
    { lat: 48.6670498, lng: 20.1837416 },
    { lat: 48.6665356, lng: 20.1833338 },
    { lat: 48.6660586, lng: 20.1827056 },
    { lat: 48.6659619, lng: 20.1822904 },
    { lat: 48.6658679, lng: 20.1821348 },
    { lat: 48.6653816, lng: 20.1815538 },
    { lat: 48.665012, lng: 20.1814124 },
    { lat: 48.6645859, lng: 20.1811519 },
    { lat: 48.6641872, lng: 20.1806166 },
    { lat: 48.6637347, lng: 20.1803147 },
    { lat: 48.6641843, lng: 20.1794005 },
    { lat: 48.663059, lng: 20.1784186 },
    { lat: 48.6617538, lng: 20.1779186 },
    { lat: 48.6607028, lng: 20.1772951 },
    { lat: 48.66067, lng: 20.1769037 },
    { lat: 48.6593495, lng: 20.1755223 },
  ],
  Rašice: [
    { lat: 48.4568821, lng: 20.2487453 },
    { lat: 48.457243, lng: 20.2491269 },
    { lat: 48.4595652, lng: 20.2515814 },
    { lat: 48.4618218, lng: 20.2543143 },
    { lat: 48.4629534, lng: 20.2546727 },
    { lat: 48.4635498, lng: 20.2550602 },
    { lat: 48.4643584, lng: 20.2558508 },
    { lat: 48.4645949, lng: 20.2557671 },
    { lat: 48.4667512, lng: 20.2549297 },
    { lat: 48.4686039, lng: 20.2547357 },
    { lat: 48.4702526, lng: 20.2541054 },
    { lat: 48.4706341, lng: 20.2538766 },
    { lat: 48.4719587, lng: 20.2523512 },
    { lat: 48.4723132, lng: 20.2517471 },
    { lat: 48.4726377, lng: 20.2510155 },
    { lat: 48.4733996, lng: 20.2500713 },
    { lat: 48.4742849, lng: 20.2495034 },
    { lat: 48.4748879, lng: 20.2491166 },
    { lat: 48.4751075, lng: 20.248918 },
    { lat: 48.4759018, lng: 20.2476534 },
    { lat: 48.4763814, lng: 20.2470565 },
    { lat: 48.4765902, lng: 20.2467487 },
    { lat: 48.4769951, lng: 20.2461521 },
    { lat: 48.4776992, lng: 20.2444334 },
    { lat: 48.4785096, lng: 20.2437679 },
    { lat: 48.4788922, lng: 20.2431725 },
    { lat: 48.4791166, lng: 20.2424725 },
    { lat: 48.479243, lng: 20.2416301 },
    { lat: 48.4801536, lng: 20.240685 },
    { lat: 48.4807538, lng: 20.2391619 },
    { lat: 48.4809985, lng: 20.2387401 },
    { lat: 48.4811803, lng: 20.2383044 },
    { lat: 48.4815323, lng: 20.2366826 },
    { lat: 48.4825861, lng: 20.2348692 },
    { lat: 48.4815935, lng: 20.2327729 },
    { lat: 48.4812894, lng: 20.2323506 },
    { lat: 48.4812133, lng: 20.2317157 },
    { lat: 48.4807328, lng: 20.2298106 },
    { lat: 48.4803059, lng: 20.22902 },
    { lat: 48.4801502, lng: 20.2289727 },
    { lat: 48.4794338, lng: 20.2277742 },
    { lat: 48.4791944, lng: 20.2272485 },
    { lat: 48.4791571, lng: 20.22674 },
    { lat: 48.4791003, lng: 20.2265289 },
    { lat: 48.4786658, lng: 20.2254104 },
    { lat: 48.4780437, lng: 20.223234 },
    { lat: 48.4779109, lng: 20.2225346 },
    { lat: 48.4777669, lng: 20.2213341 },
    { lat: 48.4778901, lng: 20.2204962 },
    { lat: 48.4779737, lng: 20.2193264 },
    { lat: 48.4775804, lng: 20.2169867 },
    { lat: 48.4775563, lng: 20.2163556 },
    { lat: 48.4774333, lng: 20.2157093 },
    { lat: 48.4773066, lng: 20.2145402 },
    { lat: 48.4774165, lng: 20.2126787 },
    { lat: 48.4775189, lng: 20.2120319 },
    { lat: 48.4776467, lng: 20.2115926 },
    { lat: 48.4776802, lng: 20.2112228 },
    { lat: 48.477601, lng: 20.209943 },
    { lat: 48.477458, lng: 20.209877 },
    { lat: 48.477165, lng: 20.209747 },
    { lat: 48.477116, lng: 20.209768 },
    { lat: 48.477026, lng: 20.209821 },
    { lat: 48.476781, lng: 20.209676 },
    { lat: 48.476687, lng: 20.209682 },
    { lat: 48.476604, lng: 20.209676 },
    { lat: 48.476224, lng: 20.209524 },
    { lat: 48.476059, lng: 20.209467 },
    { lat: 48.475875, lng: 20.209408 },
    { lat: 48.47571, lng: 20.209554 },
    { lat: 48.475559, lng: 20.209786 },
    { lat: 48.475381, lng: 20.210062 },
    { lat: 48.475047, lng: 20.210548 },
    { lat: 48.474781, lng: 20.210924 },
    { lat: 48.474247, lng: 20.211457 },
    { lat: 48.473781, lng: 20.211578 },
    { lat: 48.473679, lng: 20.211604 },
    { lat: 48.473521, lng: 20.211649 },
    { lat: 48.473517, lng: 20.211619 },
    { lat: 48.473089, lng: 20.211698 },
    { lat: 48.472744, lng: 20.211634 },
    { lat: 48.47237, lng: 20.211562 },
    { lat: 48.47196, lng: 20.211375 },
    { lat: 48.471587, lng: 20.211114 },
    { lat: 48.47136, lng: 20.210725 },
    { lat: 48.471339, lng: 20.21075 },
    { lat: 48.470888, lng: 20.210224 },
    { lat: 48.470839, lng: 20.210234 },
    { lat: 48.470785, lng: 20.210245 },
    { lat: 48.470681, lng: 20.210266 },
    { lat: 48.470592, lng: 20.210324 },
    { lat: 48.470458, lng: 20.210324 },
    { lat: 48.470269, lng: 20.210323 },
    { lat: 48.470097, lng: 20.210323 },
    { lat: 48.470023, lng: 20.210402 },
    { lat: 48.46982, lng: 20.210623 },
    { lat: 48.469471, lng: 20.210797 },
    { lat: 48.46919, lng: 20.211097 },
    { lat: 48.468831, lng: 20.211316 },
    { lat: 48.468697, lng: 20.211399 },
    { lat: 48.468227, lng: 20.211493 },
    { lat: 48.467695, lng: 20.211555 },
    { lat: 48.467639, lng: 20.211572 },
    { lat: 48.467253, lng: 20.211722 },
    { lat: 48.46675, lng: 20.211807 },
    { lat: 48.466717, lng: 20.211824 },
    { lat: 48.466699, lng: 20.211854 },
    { lat: 48.466448, lng: 20.212077 },
    { lat: 48.466252, lng: 20.212256 },
    { lat: 48.466087, lng: 20.212323 },
    { lat: 48.466019, lng: 20.21235 },
    { lat: 48.465263, lng: 20.212664 },
    { lat: 48.46517, lng: 20.212641 },
    { lat: 48.464937, lng: 20.212583 },
    { lat: 48.46426, lng: 20.212218 },
    { lat: 48.4635, lng: 20.211817 },
    { lat: 48.463274, lng: 20.211677 },
    { lat: 48.463011, lng: 20.21175 },
    { lat: 48.462993, lng: 20.211718 },
    { lat: 48.462829, lng: 20.211765 },
    { lat: 48.462685, lng: 20.211808 },
    { lat: 48.462394, lng: 20.211883 },
    { lat: 48.462023, lng: 20.211982 },
    { lat: 48.462026, lng: 20.212007 },
    { lat: 48.461988, lng: 20.212016 },
    { lat: 48.461911, lng: 20.212035 },
    { lat: 48.461617, lng: 20.21218 },
    { lat: 48.461277, lng: 20.21235 },
    { lat: 48.46091, lng: 20.212513 },
    { lat: 48.460529, lng: 20.212672 },
    { lat: 48.46039, lng: 20.21273 },
    { lat: 48.460261, lng: 20.212845 },
    { lat: 48.460124, lng: 20.21297 },
    { lat: 48.45977, lng: 20.213125 },
    { lat: 48.459512, lng: 20.213216 },
    { lat: 48.459137, lng: 20.213347 },
    { lat: 48.458787, lng: 20.213148 },
    { lat: 48.45852, lng: 20.213156 },
    { lat: 48.458403, lng: 20.21316 },
    { lat: 48.458324, lng: 20.213186 },
    { lat: 48.458016, lng: 20.213503 },
    { lat: 48.457717, lng: 20.213812 },
    { lat: 48.457454, lng: 20.214008 },
    { lat: 48.457362, lng: 20.214077 },
    { lat: 48.45716, lng: 20.214445 },
    { lat: 48.457167, lng: 20.214482 },
    { lat: 48.457018, lng: 20.215021 },
    { lat: 48.456974, lng: 20.215185 },
    { lat: 48.456858, lng: 20.215646 },
    { lat: 48.456757, lng: 20.216086 },
    { lat: 48.456714, lng: 20.216912 },
    { lat: 48.456728, lng: 20.217541 },
    { lat: 48.45671, lng: 20.217767 },
    { lat: 48.456659, lng: 20.218404 },
    { lat: 48.456613, lng: 20.219004 },
    { lat: 48.45654, lng: 20.219083 },
    { lat: 48.456401, lng: 20.219358 },
    { lat: 48.456326, lng: 20.219506 },
    { lat: 48.456136, lng: 20.219738 },
    { lat: 48.455925, lng: 20.220121 },
    { lat: 48.455649, lng: 20.220708 },
    { lat: 48.455604, lng: 20.220802 },
    { lat: 48.455418, lng: 20.221129 },
    { lat: 48.455216, lng: 20.221368 },
    { lat: 48.455098, lng: 20.221581 },
    { lat: 48.454876, lng: 20.221852 },
    { lat: 48.454606, lng: 20.222118 },
    { lat: 48.454176, lng: 20.222262 },
    { lat: 48.454107, lng: 20.222286 },
    { lat: 48.453742, lng: 20.222568 },
    { lat: 48.453373, lng: 20.22293 },
    { lat: 48.452913, lng: 20.223398 },
    { lat: 48.452736, lng: 20.223581 },
    { lat: 48.452589, lng: 20.223756 },
    { lat: 48.452476, lng: 20.223904 },
    { lat: 48.452237, lng: 20.22427 },
    { lat: 48.452229, lng: 20.224348 },
    { lat: 48.452192, lng: 20.224746 },
    { lat: 48.451938, lng: 20.225261 },
    { lat: 48.451645, lng: 20.225602 },
    { lat: 48.45134, lng: 20.225648 },
    { lat: 48.450852, lng: 20.225652 },
    { lat: 48.45055, lng: 20.225487 },
    { lat: 48.450543, lng: 20.225509 },
    { lat: 48.450379, lng: 20.225375 },
    { lat: 48.450196, lng: 20.225206 },
    { lat: 48.449856, lng: 20.224747 },
    { lat: 48.449729, lng: 20.224564 },
    { lat: 48.449559, lng: 20.224362 },
    { lat: 48.449377, lng: 20.224155 },
    { lat: 48.449293, lng: 20.224107 },
    { lat: 48.449233, lng: 20.224083 },
    { lat: 48.449118, lng: 20.224077 },
    { lat: 48.449013, lng: 20.2241 },
    { lat: 48.448963, lng: 20.224122 },
    { lat: 48.44883, lng: 20.224264 },
    { lat: 48.448667, lng: 20.224474 },
    { lat: 48.448482, lng: 20.224715 },
    { lat: 48.4483, lng: 20.224973 },
    { lat: 48.448161, lng: 20.225178 },
    { lat: 48.448082, lng: 20.225251 },
    { lat: 48.448015, lng: 20.225291 },
    { lat: 48.447703, lng: 20.22542 },
    { lat: 48.447459, lng: 20.225387 },
    { lat: 48.44734, lng: 20.225444 },
    { lat: 48.447214, lng: 20.225562 },
    { lat: 48.446819, lng: 20.226101 },
    { lat: 48.446355, lng: 20.226725 },
    { lat: 48.445947, lng: 20.227291 },
    { lat: 48.445463, lng: 20.2278 },
    { lat: 48.445376, lng: 20.22802 },
    { lat: 48.44535, lng: 20.2284 },
    { lat: 48.445332, lng: 20.228956 },
    { lat: 48.445372, lng: 20.229503 },
    { lat: 48.4454, lng: 20.229971 },
    { lat: 48.445391, lng: 20.230586 },
    { lat: 48.445417, lng: 20.231062 },
    { lat: 48.445377, lng: 20.231464 },
    { lat: 48.445331, lng: 20.23169 },
    { lat: 48.445079, lng: 20.232291 },
    { lat: 48.445014, lng: 20.232629 },
    { lat: 48.444721, lng: 20.233579 },
    { lat: 48.444504, lng: 20.234187 },
    { lat: 48.444225, lng: 20.234646 },
    { lat: 48.44384, lng: 20.235446 },
    { lat: 48.44377, lng: 20.235975 },
    { lat: 48.443775, lng: 20.236491 },
    { lat: 48.4442445, lng: 20.2370308 },
    { lat: 48.4455453, lng: 20.2386967 },
    { lat: 48.4468484, lng: 20.2397157 },
    { lat: 48.447385, lng: 20.2396715 },
    { lat: 48.4480982, lng: 20.2397299 },
    { lat: 48.4491306, lng: 20.2399748 },
    { lat: 48.4505928, lng: 20.2400426 },
    { lat: 48.4512274, lng: 20.2401383 },
    { lat: 48.4516044, lng: 20.2403583 },
    { lat: 48.4518345, lng: 20.2406472 },
    { lat: 48.451904, lng: 20.2409457 },
    { lat: 48.4521396, lng: 20.2414336 },
    { lat: 48.4530301, lng: 20.2422959 },
    { lat: 48.4532294, lng: 20.2426373 },
    { lat: 48.4539121, lng: 20.2435465 },
    { lat: 48.4556737, lng: 20.2454934 },
    { lat: 48.4561149, lng: 20.2458571 },
    { lat: 48.4564264, lng: 20.246324 },
    { lat: 48.456851, lng: 20.2466625 },
    { lat: 48.4569905, lng: 20.2473975 },
    { lat: 48.4568947, lng: 20.2486934 },
    { lat: 48.4568821, lng: 20.2487453 },
  ],
  Nandraž: [
    { lat: 48.616084, lng: 20.1560105 },
    { lat: 48.6153521, lng: 20.1563175 },
    { lat: 48.6150559, lng: 20.1567894 },
    { lat: 48.6149777, lng: 20.1573629 },
    { lat: 48.6147584, lng: 20.1580676 },
    { lat: 48.6138909, lng: 20.1590303 },
    { lat: 48.6135189, lng: 20.1597462 },
    { lat: 48.6129498, lng: 20.1604912 },
    { lat: 48.6127436, lng: 20.1610975 },
    { lat: 48.6127207, lng: 20.1614527 },
    { lat: 48.6126921, lng: 20.1620818 },
    { lat: 48.6127723, lng: 20.1628094 },
    { lat: 48.6127663, lng: 20.1634139 },
    { lat: 48.6126828, lng: 20.1637409 },
    { lat: 48.6123583, lng: 20.1644353 },
    { lat: 48.6119922, lng: 20.1649665 },
    { lat: 48.6119617, lng: 20.1651281 },
    { lat: 48.6117949, lng: 20.1652922 },
    { lat: 48.6115561, lng: 20.1652935 },
    { lat: 48.6110531, lng: 20.1654448 },
    { lat: 48.6104148, lng: 20.1652537 },
    { lat: 48.6103434, lng: 20.1653841 },
    { lat: 48.6099233, lng: 20.1657181 },
    { lat: 48.6097772, lng: 20.1659223 },
    { lat: 48.6097305, lng: 20.1660736 },
    { lat: 48.6095561, lng: 20.167078 },
    { lat: 48.6095397, lng: 20.1673443 },
    { lat: 48.6096328, lng: 20.1678654 },
    { lat: 48.6091267, lng: 20.168634 },
    { lat: 48.6090611, lng: 20.1688216 },
    { lat: 48.6085349, lng: 20.1683457 },
    { lat: 48.6081978, lng: 20.1677759 },
    { lat: 48.6078666, lng: 20.1674644 },
    { lat: 48.6076468, lng: 20.1671301 },
    { lat: 48.6075396, lng: 20.1667923 },
    { lat: 48.6073352, lng: 20.1664164 },
    { lat: 48.6071201, lng: 20.1661938 },
    { lat: 48.6069801, lng: 20.1659053 },
    { lat: 48.6064072, lng: 20.1654138 },
    { lat: 48.6060114, lng: 20.1648477 },
    { lat: 48.6057714, lng: 20.163757 },
    { lat: 48.6058787, lng: 20.163067 },
    { lat: 48.6058627, lng: 20.1629001 },
    { lat: 48.6058094, lng: 20.1627499 },
    { lat: 48.6057382, lng: 20.1628806 },
    { lat: 48.6055967, lng: 20.162836 },
    { lat: 48.6054973, lng: 20.1630259 },
    { lat: 48.6051322, lng: 20.1634108 },
    { lat: 48.6050998, lng: 20.1635864 },
    { lat: 48.6049914, lng: 20.1637419 },
    { lat: 48.6048262, lng: 20.1637539 },
    { lat: 48.6047401, lng: 20.163964 },
    { lat: 48.6045236, lng: 20.1640373 },
    { lat: 48.6044291, lng: 20.1642403 },
    { lat: 48.6044328, lng: 20.1644118 },
    { lat: 48.6043602, lng: 20.1645676 },
    { lat: 48.6041664, lng: 20.1646762 },
    { lat: 48.604134, lng: 20.1648197 },
    { lat: 48.6040093, lng: 20.1649123 },
    { lat: 48.6038828, lng: 20.1651268 },
    { lat: 48.6035229, lng: 20.1652499 },
    { lat: 48.6034485, lng: 20.1652191 },
    { lat: 48.6033611, lng: 20.1653993 },
    { lat: 48.6031572, lng: 20.1655921 },
    { lat: 48.6030465, lng: 20.1655852 },
    { lat: 48.603035, lng: 20.1660777 },
    { lat: 48.6029306, lng: 20.1664766 },
    { lat: 48.6027822, lng: 20.1666255 },
    { lat: 48.6025807, lng: 20.1666846 },
    { lat: 48.6025728, lng: 20.1669035 },
    { lat: 48.6024159, lng: 20.1671197 },
    { lat: 48.6024182, lng: 20.167371 },
    { lat: 48.6023326, lng: 20.1673942 },
    { lat: 48.6024126, lng: 20.1676221 },
    { lat: 48.602272, lng: 20.1677642 },
    { lat: 48.6022081, lng: 20.1679394 },
    { lat: 48.6022724, lng: 20.1681072 },
    { lat: 48.6021043, lng: 20.1688803 },
    { lat: 48.6019399, lng: 20.1690768 },
    { lat: 48.6019525, lng: 20.169276 },
    { lat: 48.6020527, lng: 20.1695013 },
    { lat: 48.602137, lng: 20.1695502 },
    { lat: 48.6022365, lng: 20.1698976 },
    { lat: 48.6021633, lng: 20.1704235 },
    { lat: 48.6022423, lng: 20.1704846 },
    { lat: 48.6022979, lng: 20.1706919 },
    { lat: 48.6022274, lng: 20.1711211 },
    { lat: 48.6016794, lng: 20.1721365 },
    { lat: 48.6016668, lng: 20.1726316 },
    { lat: 48.6016039, lng: 20.172974 },
    { lat: 48.6014049, lng: 20.1728959 },
    { lat: 48.6013263, lng: 20.1727899 },
    { lat: 48.6010893, lng: 20.1727487 },
    { lat: 48.6006978, lng: 20.1729309 },
    { lat: 48.6000048, lng: 20.1726661 },
    { lat: 48.5999234, lng: 20.1727437 },
    { lat: 48.5995875, lng: 20.1724891 },
    { lat: 48.5994333, lng: 20.1724876 },
    { lat: 48.5992998, lng: 20.1726138 },
    { lat: 48.5991705, lng: 20.1729292 },
    { lat: 48.5990328, lng: 20.1730596 },
    { lat: 48.5989775, lng: 20.1729714 },
    { lat: 48.5987357, lng: 20.1729282 },
    { lat: 48.5986271, lng: 20.1730549 },
    { lat: 48.5985795, lng: 20.173377 },
    { lat: 48.5983275, lng: 20.1735848 },
    { lat: 48.5982589, lng: 20.1737498 },
    { lat: 48.5982711, lng: 20.1738298 },
    { lat: 48.5981808, lng: 20.173937 },
    { lat: 48.5981686, lng: 20.174078 },
    { lat: 48.5978609, lng: 20.174272 },
    { lat: 48.5978055, lng: 20.1745141 },
    { lat: 48.5975972, lng: 20.1749367 },
    { lat: 48.5975719, lng: 20.1751475 },
    { lat: 48.5974211, lng: 20.1753752 },
    { lat: 48.5972957, lng: 20.1753622 },
    { lat: 48.5968989, lng: 20.1755765 },
    { lat: 48.5967098, lng: 20.1753576 },
    { lat: 48.5964727, lng: 20.1749218 },
    { lat: 48.5963002, lng: 20.174865 },
    { lat: 48.5962106, lng: 20.1747388 },
    { lat: 48.5952389, lng: 20.1742697 },
    { lat: 48.5951479, lng: 20.1744987 },
    { lat: 48.5950939, lng: 20.1746691 },
    { lat: 48.5951161, lng: 20.1747165 },
    { lat: 48.5952285, lng: 20.1749574 },
    { lat: 48.595324, lng: 20.1762138 },
    { lat: 48.5954226, lng: 20.176725 },
    { lat: 48.5954244, lng: 20.1772734 },
    { lat: 48.5952513, lng: 20.1785912 },
    { lat: 48.5951284, lng: 20.1786462 },
    { lat: 48.5953187, lng: 20.1806547 },
    { lat: 48.595295, lng: 20.1812963 },
    { lat: 48.5947672, lng: 20.1816684 },
    { lat: 48.5950522, lng: 20.1849384 },
    { lat: 48.5949636, lng: 20.1857852 },
    { lat: 48.5949328, lng: 20.1870073 },
    { lat: 48.5950016, lng: 20.1875706 },
    { lat: 48.5951408, lng: 20.1879514 },
    { lat: 48.5983216, lng: 20.1855833 },
    { lat: 48.5989046, lng: 20.1855132 },
    { lat: 48.5991802, lng: 20.1852535 },
    { lat: 48.5994188, lng: 20.1851381 },
    { lat: 48.5996405, lng: 20.185216 },
    { lat: 48.5997774, lng: 20.1854276 },
    { lat: 48.6003468, lng: 20.1864497 },
    { lat: 48.6005511, lng: 20.1869096 },
    { lat: 48.6002763, lng: 20.1878051 },
    { lat: 48.6002094, lng: 20.1884062 },
    { lat: 48.6003231, lng: 20.1892189 },
    { lat: 48.6000475, lng: 20.1900113 },
    { lat: 48.5999324, lng: 20.1907587 },
    { lat: 48.6000573, lng: 20.1918514 },
    { lat: 48.6000335, lng: 20.1923792 },
    { lat: 48.6004749, lng: 20.1949517 },
    { lat: 48.6007618, lng: 20.1956717 },
    { lat: 48.6009715, lng: 20.1959866 },
    { lat: 48.6014173, lng: 20.1969608 },
    { lat: 48.6017907, lng: 20.1982123 },
    { lat: 48.6017834, lng: 20.1987574 },
    { lat: 48.6019848, lng: 20.1997297 },
    { lat: 48.6020334, lng: 20.2004409 },
    { lat: 48.6022086, lng: 20.2017426 },
    { lat: 48.6023265, lng: 20.2020731 },
    { lat: 48.6026636, lng: 20.2024465 },
    { lat: 48.6029575, lng: 20.2025016 },
    { lat: 48.6031792, lng: 20.2035617 },
    { lat: 48.6034661, lng: 20.2042017 },
    { lat: 48.6037046, lng: 20.2045881 },
    { lat: 48.603884, lng: 20.2050699 },
    { lat: 48.6041397, lng: 20.2061149 },
    { lat: 48.604278, lng: 20.2072848 },
    { lat: 48.6042702, lng: 20.2076791 },
    { lat: 48.6043491, lng: 20.2081591 },
    { lat: 48.6038143, lng: 20.2101597 },
    { lat: 48.6041598, lng: 20.2100955 },
    { lat: 48.6049088, lng: 20.2099578 },
    { lat: 48.605266, lng: 20.2097079 },
    { lat: 48.6056297, lng: 20.2092833 },
    { lat: 48.6059273, lng: 20.2095416 },
    { lat: 48.6062116, lng: 20.2094466 },
    { lat: 48.6074115, lng: 20.2095707 },
    { lat: 48.6076338, lng: 20.2094651 },
    { lat: 48.6078513, lng: 20.2091926 },
    { lat: 48.6081278, lng: 20.2090069 },
    { lat: 48.6083859, lng: 20.2089574 },
    { lat: 48.6087224, lng: 20.2086986 },
    { lat: 48.6091196, lng: 20.2085755 },
    { lat: 48.6095265, lng: 20.2075971 },
    { lat: 48.6098036, lng: 20.2073743 },
    { lat: 48.6100198, lng: 20.2074863 },
    { lat: 48.6101942, lng: 20.2074572 },
    { lat: 48.6103371, lng: 20.2076489 },
    { lat: 48.6105631, lng: 20.208118 },
    { lat: 48.6108119, lng: 20.2084263 },
    { lat: 48.6110452, lng: 20.2086001 },
    { lat: 48.6112599, lng: 20.2085339 },
    { lat: 48.6115554, lng: 20.2082866 },
    { lat: 48.6120039, lng: 20.2080706 },
    { lat: 48.6124725, lng: 20.2082386 },
    { lat: 48.613154, lng: 20.2089197 },
    { lat: 48.6132205, lng: 20.2090887 },
    { lat: 48.6133536, lng: 20.2097386 },
    { lat: 48.6133732, lng: 20.2101145 },
    { lat: 48.6134209, lng: 20.2102108 },
    { lat: 48.6137111, lng: 20.2102848 },
    { lat: 48.6139757, lng: 20.2105913 },
    { lat: 48.6140788, lng: 20.2106389 },
    { lat: 48.6144179, lng: 20.2107261 },
    { lat: 48.6150112, lng: 20.2107184 },
    { lat: 48.6159175, lng: 20.2108194 },
    { lat: 48.6187125, lng: 20.2105335 },
    { lat: 48.6188376, lng: 20.2101686 },
    { lat: 48.6189265, lng: 20.2095465 },
    { lat: 48.618924, lng: 20.2092897 },
    { lat: 48.6187888, lng: 20.2089784 },
    { lat: 48.6189265, lng: 20.2086751 },
    { lat: 48.6190974, lng: 20.2084473 },
    { lat: 48.619265, lng: 20.2080191 },
    { lat: 48.6196944, lng: 20.207215 },
    { lat: 48.6200356, lng: 20.2061185 },
    { lat: 48.6203672, lng: 20.2057643 },
    { lat: 48.6204466, lng: 20.2053552 },
    { lat: 48.6205839, lng: 20.2050436 },
    { lat: 48.6209026, lng: 20.2036394 },
    { lat: 48.6212529, lng: 20.2030219 },
    { lat: 48.6214101, lng: 20.202559 },
    { lat: 48.621546, lng: 20.2012049 },
    { lat: 48.6214786, lng: 20.2008725 },
    { lat: 48.6215362, lng: 20.200738 },
    { lat: 48.6220302, lng: 20.2002216 },
    { lat: 48.622222, lng: 20.2002845 },
    { lat: 48.6224168, lng: 20.2005099 },
    { lat: 48.6225156, lng: 20.2005308 },
    { lat: 48.6231439, lng: 20.2003281 },
    { lat: 48.6233177, lng: 20.2000293 },
    { lat: 48.6238953, lng: 20.1997141 },
    { lat: 48.6242765, lng: 20.1991984 },
    { lat: 48.6244169, lng: 20.1988213 },
    { lat: 48.6244655, lng: 20.1982391 },
    { lat: 48.6245969, lng: 20.1980042 },
    { lat: 48.6247161, lng: 20.1972162 },
    { lat: 48.6247116, lng: 20.1956708 },
    { lat: 48.6245218, lng: 20.1948726 },
    { lat: 48.624454, lng: 20.1940311 },
    { lat: 48.6242748, lng: 20.1934943 },
    { lat: 48.6239595, lng: 20.1931016 },
    { lat: 48.6238151, lng: 20.1926503 },
    { lat: 48.6241131, lng: 20.1919897 },
    { lat: 48.6243946, lng: 20.1915969 },
    { lat: 48.624512, lng: 20.1913238 },
    { lat: 48.6246139, lng: 20.1908046 },
    { lat: 48.6246568, lng: 20.1901934 },
    { lat: 48.6250118, lng: 20.1889935 },
    { lat: 48.6249937, lng: 20.1882678 },
    { lat: 48.6249396, lng: 20.1879029 },
    { lat: 48.6250123, lng: 20.1872754 },
    { lat: 48.6252928, lng: 20.1869371 },
    { lat: 48.6259109, lng: 20.1866808 },
    { lat: 48.6262437, lng: 20.1866108 },
    { lat: 48.6267136, lng: 20.1863651 },
    { lat: 48.6271817, lng: 20.1866373 },
    { lat: 48.6277942, lng: 20.1867133 },
    { lat: 48.6284722, lng: 20.1871055 },
    { lat: 48.6289275, lng: 20.1868612 },
    { lat: 48.629447, lng: 20.1868393 },
    { lat: 48.6298989, lng: 20.1866793 },
    { lat: 48.6305634, lng: 20.1859786 },
    { lat: 48.6310771, lng: 20.1860116 },
    { lat: 48.6313756, lng: 20.1855072 },
    { lat: 48.6315409, lng: 20.184917 },
    { lat: 48.6319111, lng: 20.1840933 },
    { lat: 48.6323043, lng: 20.182559 },
    { lat: 48.6339067, lng: 20.180459 },
    { lat: 48.6342315, lng: 20.1801639 },
    { lat: 48.6344, lng: 20.1798415 },
    { lat: 48.6349017, lng: 20.1792122 },
    { lat: 48.6353955, lng: 20.1782778 },
    { lat: 48.6359511, lng: 20.1778935 },
    { lat: 48.6366694, lng: 20.1769957 },
    { lat: 48.6372045, lng: 20.1765506 },
    { lat: 48.6374514, lng: 20.176248 },
    { lat: 48.6376787, lng: 20.1757991 },
    { lat: 48.637996, lng: 20.1749516 },
    { lat: 48.6382069, lng: 20.1746805 },
    { lat: 48.6386877, lng: 20.1736182 },
    { lat: 48.6395028, lng: 20.1728795 },
    { lat: 48.6402241, lng: 20.1726704 },
    { lat: 48.6403726, lng: 20.1723612 },
    { lat: 48.6402819, lng: 20.1722898 },
    { lat: 48.6397514, lng: 20.1720394 },
    { lat: 48.6394261, lng: 20.1720967 },
    { lat: 48.6391037, lng: 20.1720092 },
    { lat: 48.63825, lng: 20.1722048 },
    { lat: 48.6362288, lng: 20.1709594 },
    { lat: 48.6356636, lng: 20.1707974 },
    { lat: 48.6352307, lng: 20.170511 },
    { lat: 48.6347148, lng: 20.1703113 },
    { lat: 48.6343478, lng: 20.1702881 },
    { lat: 48.633527, lng: 20.1705174 },
    { lat: 48.6332921, lng: 20.170639 },
    { lat: 48.6329768, lng: 20.1706143 },
    { lat: 48.6321895, lng: 20.1703363 },
    { lat: 48.6318039, lng: 20.1702663 },
    { lat: 48.6313827, lng: 20.1700466 },
    { lat: 48.6310355, lng: 20.1699816 },
    { lat: 48.6305569, lng: 20.1695371 },
    { lat: 48.6297794, lng: 20.1689471 },
    { lat: 48.6295568, lng: 20.1685068 },
    { lat: 48.6293137, lng: 20.1677295 },
    { lat: 48.6291894, lng: 20.1667451 },
    { lat: 48.6290055, lng: 20.1659556 },
    { lat: 48.628928, lng: 20.1651844 },
    { lat: 48.6287958, lng: 20.1648669 },
    { lat: 48.6283572, lng: 20.1641596 },
    { lat: 48.6280743, lng: 20.1631225 },
    { lat: 48.6275756, lng: 20.1622651 },
    { lat: 48.6271369, lng: 20.1619452 },
    { lat: 48.6270405, lng: 20.1616814 },
    { lat: 48.6268267, lng: 20.1613504 },
    { lat: 48.6266167, lng: 20.1607275 },
    { lat: 48.6258859, lng: 20.1595071 },
    { lat: 48.6255047, lng: 20.1598836 },
    { lat: 48.6253293, lng: 20.1601675 },
    { lat: 48.6250478, lng: 20.160449 },
    { lat: 48.6246144, lng: 20.1607859 },
    { lat: 48.6238517, lng: 20.1615343 },
    { lat: 48.6237474, lng: 20.1615475 },
    { lat: 48.623715, lng: 20.1616907 },
    { lat: 48.6236601, lng: 20.161699 },
    { lat: 48.6235115, lng: 20.1620028 },
    { lat: 48.6233432, lng: 20.1620803 },
    { lat: 48.6231942, lng: 20.162346 },
    { lat: 48.6229536, lng: 20.1624942 },
    { lat: 48.6227768, lng: 20.1627213 },
    { lat: 48.6224328, lng: 20.1635984 },
    { lat: 48.6221067, lng: 20.1641429 },
    { lat: 48.6218792, lng: 20.164366 },
    { lat: 48.6217905, lng: 20.164676 },
    { lat: 48.6215769, lng: 20.1647149 },
    { lat: 48.6214052, lng: 20.1648945 },
    { lat: 48.6210875, lng: 20.1653947 },
    { lat: 48.6204916, lng: 20.1655876 },
    { lat: 48.6201859, lng: 20.1659372 },
    { lat: 48.6195153, lng: 20.166373 },
    { lat: 48.6190529, lng: 20.1663586 },
    { lat: 48.6188407, lng: 20.1665405 },
    { lat: 48.6184263, lng: 20.1667099 },
    { lat: 48.6183871, lng: 20.1668257 },
    { lat: 48.6179992, lng: 20.1671497 },
    { lat: 48.6178498, lng: 20.1671617 },
    { lat: 48.6176589, lng: 20.1672846 },
    { lat: 48.6175005, lng: 20.1669866 },
    { lat: 48.6174882, lng: 20.1668514 },
    { lat: 48.6173905, lng: 20.1667743 },
    { lat: 48.6173782, lng: 20.1666405 },
    { lat: 48.6170388, lng: 20.1660456 },
    { lat: 48.6169857, lng: 20.1652339 },
    { lat: 48.6169182, lng: 20.1650105 },
    { lat: 48.6169443, lng: 20.1647942 },
    { lat: 48.6169136, lng: 20.1641449 },
    { lat: 48.6167911, lng: 20.1630961 },
    { lat: 48.6168258, lng: 20.1625077 },
    { lat: 48.6168211, lng: 20.1620821 },
    { lat: 48.6167783, lng: 20.1618874 },
    { lat: 48.6167994, lng: 20.1609329 },
    { lat: 48.6167318, lng: 20.160266 },
    { lat: 48.6163769, lng: 20.1584844 },
    { lat: 48.6163655, lng: 20.1582043 },
    { lat: 48.6165476, lng: 20.1574435 },
    { lat: 48.6165416, lng: 20.1566556 },
    { lat: 48.616427, lng: 20.156081 },
    { lat: 48.6162211, lng: 20.1560843 },
    { lat: 48.616084, lng: 20.1560105 },
  ],
  GemerskáVes: [
    { lat: 48.4643584, lng: 20.2558508 },
    { lat: 48.4644456, lng: 20.2569909 },
    { lat: 48.4641797, lng: 20.2591188 },
    { lat: 48.4635033, lng: 20.2610543 },
    { lat: 48.4628774, lng: 20.2631861 },
    { lat: 48.4628433, lng: 20.2636874 },
    { lat: 48.462618, lng: 20.2647952 },
    { lat: 48.4623958, lng: 20.2664861 },
    { lat: 48.462291, lng: 20.266943 },
    { lat: 48.461764, lng: 20.2680502 },
    { lat: 48.4617081, lng: 20.2691098 },
    { lat: 48.4617232, lng: 20.2697454 },
    { lat: 48.4616726, lng: 20.2698028 },
    { lat: 48.4618325, lng: 20.272447 },
    { lat: 48.4610095, lng: 20.2723401 },
    { lat: 48.4607032, lng: 20.2723697 },
    { lat: 48.4605869, lng: 20.2724723 },
    { lat: 48.4604765, lng: 20.2733832 },
    { lat: 48.4605428, lng: 20.2737469 },
    { lat: 48.4606583, lng: 20.2738207 },
    { lat: 48.4609907, lng: 20.2737093 },
    { lat: 48.4609573, lng: 20.2736073 },
    { lat: 48.4609968, lng: 20.2734944 },
    { lat: 48.4612288, lng: 20.2733236 },
    { lat: 48.4614389, lng: 20.273342 },
    { lat: 48.461631, lng: 20.2735551 },
    { lat: 48.4616301, lng: 20.273904 },
    { lat: 48.4613274, lng: 20.274447 },
    { lat: 48.4613195, lng: 20.275332 },
    { lat: 48.4615348, lng: 20.2771802 },
    { lat: 48.4620462, lng: 20.2806261 },
    { lat: 48.4621997, lng: 20.2823465 },
    { lat: 48.4622673, lng: 20.2855056 },
    { lat: 48.4628541, lng: 20.2880996 },
    { lat: 48.4627606, lng: 20.2885533 },
    { lat: 48.462673, lng: 20.2885622 },
    { lat: 48.4626741, lng: 20.2888086 },
    { lat: 48.4626745, lng: 20.2889119 },
    { lat: 48.4625228, lng: 20.2895233 },
    { lat: 48.4625668, lng: 20.2896078 },
    { lat: 48.4618939, lng: 20.2905925 },
    { lat: 48.461779, lng: 20.2906177 },
    { lat: 48.4616784, lng: 20.2909819 },
    { lat: 48.4614798, lng: 20.2912831 },
    { lat: 48.4613362, lng: 20.2916131 },
    { lat: 48.4610977, lng: 20.2917061 },
    { lat: 48.4608474, lng: 20.2923252 },
    { lat: 48.4607421, lng: 20.2923424 },
    { lat: 48.4604506, lng: 20.292686 },
    { lat: 48.4602026, lng: 20.2936797 },
    { lat: 48.4602189, lng: 20.2940635 },
    { lat: 48.4604517, lng: 20.2948266 },
    { lat: 48.4607915, lng: 20.2955602 },
    { lat: 48.4613746, lng: 20.2971806 },
    { lat: 48.4618727, lng: 20.2982195 },
    { lat: 48.4620076, lng: 20.2985006 },
    { lat: 48.4623131, lng: 20.2983044 },
    { lat: 48.4635993, lng: 20.2980774 },
    { lat: 48.4639472, lng: 20.297936 },
    { lat: 48.4640846, lng: 20.2977332 },
    { lat: 48.4641596, lng: 20.2977492 },
    { lat: 48.4646539, lng: 20.2974362 },
    { lat: 48.4655903, lng: 20.2966753 },
    { lat: 48.4663088, lng: 20.2959909 },
    { lat: 48.4666749, lng: 20.2958781 },
    { lat: 48.4672144, lng: 20.2959617 },
    { lat: 48.4674786, lng: 20.2958696 },
    { lat: 48.4683184, lng: 20.2959733 },
    { lat: 48.4690453, lng: 20.2964103 },
    { lat: 48.4695441, lng: 20.2969535 },
    { lat: 48.4710036, lng: 20.29832 },
    { lat: 48.4722696, lng: 20.300212 },
    { lat: 48.473459, lng: 20.3030451 },
    { lat: 48.4737765, lng: 20.3047325 },
    { lat: 48.4741652, lng: 20.3059085 },
    { lat: 48.4750459, lng: 20.3059118 },
    { lat: 48.4771789, lng: 20.3056496 },
    { lat: 48.4789054, lng: 20.3055651 },
    { lat: 48.4791997, lng: 20.3056912 },
    { lat: 48.479673, lng: 20.306424 },
    { lat: 48.479931, lng: 20.30656 },
    { lat: 48.480423, lng: 20.306834 },
    { lat: 48.481301, lng: 20.307481 },
    { lat: 48.481728, lng: 20.307795 },
    { lat: 48.482261, lng: 20.308143 },
    { lat: 48.482673, lng: 20.308414 },
    { lat: 48.483143, lng: 20.308722 },
    { lat: 48.48337, lng: 20.308868 },
    { lat: 48.483891, lng: 20.30921 },
    { lat: 48.484115, lng: 20.309292 },
    { lat: 48.484421, lng: 20.309269 },
    { lat: 48.48506, lng: 20.309216 },
    { lat: 48.485225, lng: 20.309105 },
    { lat: 48.48566, lng: 20.30885 },
    { lat: 48.485912, lng: 20.30865 },
    { lat: 48.486573, lng: 20.308133 },
    { lat: 48.487004, lng: 20.307993 },
    { lat: 48.48762, lng: 20.307794 },
    { lat: 48.488233, lng: 20.307627 },
    { lat: 48.488584, lng: 20.307598 },
    { lat: 48.489139, lng: 20.307565 },
    { lat: 48.489718, lng: 20.307892 },
    { lat: 48.49024, lng: 20.308192 },
    { lat: 48.490754, lng: 20.308517 },
    { lat: 48.491441, lng: 20.308958 },
    { lat: 48.491928, lng: 20.309264 },
    { lat: 48.492493, lng: 20.309616 },
    { lat: 48.493442, lng: 20.309646 },
    { lat: 48.493812, lng: 20.309638 },
    { lat: 48.494255, lng: 20.309631 },
    { lat: 48.494827, lng: 20.309614 },
    { lat: 48.494983, lng: 20.30961 },
    { lat: 48.494982, lng: 20.309581 },
    { lat: 48.494978, lng: 20.309485 },
    { lat: 48.495146, lng: 20.309288 },
    { lat: 48.495299, lng: 20.309106 },
    { lat: 48.495545, lng: 20.308813 },
    { lat: 48.495711, lng: 20.308607 },
    { lat: 48.495938, lng: 20.308322 },
    { lat: 48.496172, lng: 20.307987 },
    { lat: 48.496446, lng: 20.307576 },
    { lat: 48.496734, lng: 20.307146 },
    { lat: 48.496974, lng: 20.306791 },
    { lat: 48.497144, lng: 20.306538 },
    { lat: 48.497481, lng: 20.306093 },
    { lat: 48.497728, lng: 20.305762 },
    { lat: 48.497972, lng: 20.305444 },
    { lat: 48.498135, lng: 20.305232 },
    { lat: 48.498295, lng: 20.304974 },
    { lat: 48.498424, lng: 20.304762 },
    { lat: 48.498486, lng: 20.30466 },
    { lat: 48.498573, lng: 20.304499 },
    { lat: 48.498715, lng: 20.304278 },
    { lat: 48.498989, lng: 20.303845 },
    { lat: 48.499084, lng: 20.303698 },
    { lat: 48.499351, lng: 20.303289 },
    { lat: 48.499407, lng: 20.303203 },
    { lat: 48.499571, lng: 20.302887 },
    { lat: 48.499788, lng: 20.302461 },
    { lat: 48.499927, lng: 20.302191 },
    { lat: 48.500118, lng: 20.301824 },
    { lat: 48.500153, lng: 20.301754 },
    { lat: 48.500398, lng: 20.301326 },
    { lat: 48.500534, lng: 20.301083 },
    { lat: 48.500682, lng: 20.300826 },
    { lat: 48.500703, lng: 20.300787 },
    { lat: 48.500713, lng: 20.300772 },
    { lat: 48.500811, lng: 20.300508 },
    { lat: 48.500998, lng: 20.300014 },
    { lat: 48.501174, lng: 20.299559 },
    { lat: 48.50122, lng: 20.299455 },
    { lat: 48.5015532, lng: 20.2976145 },
    { lat: 48.5016283, lng: 20.2970049 },
    { lat: 48.5015808, lng: 20.2947399 },
    { lat: 48.5014787, lng: 20.2935814 },
    { lat: 48.5016313, lng: 20.2911662 },
    { lat: 48.5021185, lng: 20.2900701 },
    { lat: 48.5030335, lng: 20.2891979 },
    { lat: 48.5035191, lng: 20.2878913 },
    { lat: 48.5035329, lng: 20.2875299 },
    { lat: 48.5035448, lng: 20.2872561 },
    { lat: 48.5035416, lng: 20.2866076 },
    { lat: 48.5034725, lng: 20.2862579 },
    { lat: 48.5035186, lng: 20.285338 },
    { lat: 48.5027802, lng: 20.2839235 },
    { lat: 48.5023224, lng: 20.2823121 },
    { lat: 48.5022203, lng: 20.2811568 },
    { lat: 48.5017047, lng: 20.2799331 },
    { lat: 48.5015843, lng: 20.2790845 },
    { lat: 48.5014373, lng: 20.2784172 },
    { lat: 48.5013746, lng: 20.2776867 },
    { lat: 48.5011025, lng: 20.2758723 },
    { lat: 48.5009691, lng: 20.2753246 },
    { lat: 48.5006836, lng: 20.274597 },
    { lat: 48.5006282, lng: 20.2741332 },
    { lat: 48.5007321, lng: 20.2726334 },
    { lat: 48.5005874, lng: 20.2719962 },
    { lat: 48.5003812, lng: 20.2715396 },
    { lat: 48.5003086, lng: 20.2709268 },
    { lat: 48.5000403, lng: 20.2703798 },
    { lat: 48.4998776, lng: 20.2697993 },
    { lat: 48.4994205, lng: 20.268836 },
    { lat: 48.4990382, lng: 20.267516 },
    { lat: 48.4989229, lng: 20.2662046 },
    { lat: 48.4988032, lng: 20.2654523 },
    { lat: 48.4987142, lng: 20.2652557 },
    { lat: 48.4985901, lng: 20.2647388 },
    { lat: 48.4985017, lng: 20.2641598 },
    { lat: 48.4984419, lng: 20.2640146 },
    { lat: 48.4980148, lng: 20.26389 },
    { lat: 48.4970814, lng: 20.2639259 },
    { lat: 48.4968316, lng: 20.2639874 },
    { lat: 48.4963438, lng: 20.2639585 },
    { lat: 48.4960952, lng: 20.2638796 },
    { lat: 48.4949899, lng: 20.2632176 },
    { lat: 48.4948683, lng: 20.2633052 },
    { lat: 48.4947792, lng: 20.26313 },
    { lat: 48.4947731, lng: 20.2630056 },
    { lat: 48.4948847, lng: 20.262922 },
    { lat: 48.4949398, lng: 20.2625205 },
    { lat: 48.4949136, lng: 20.2622281 },
    { lat: 48.4951202, lng: 20.2620998 },
    { lat: 48.4953304, lng: 20.2617528 },
    { lat: 48.4951052, lng: 20.2614681 },
    { lat: 48.4949128, lng: 20.261615 },
    { lat: 48.4948737, lng: 20.2613429 },
    { lat: 48.4950393, lng: 20.2611676 },
    { lat: 48.4950666, lng: 20.2610053 },
    { lat: 48.4950268, lng: 20.2607408 },
    { lat: 48.4951333, lng: 20.2606478 },
    { lat: 48.4953319, lng: 20.2607993 },
    { lat: 48.4955347, lng: 20.2607118 },
    { lat: 48.4955565, lng: 20.2606655 },
    { lat: 48.4954699, lng: 20.2605004 },
    { lat: 48.4955423, lng: 20.2603916 },
    { lat: 48.4958535, lng: 20.2609115 },
    { lat: 48.4959598, lng: 20.2606715 },
    { lat: 48.4961192, lng: 20.2606856 },
    { lat: 48.4961454, lng: 20.260805 },
    { lat: 48.4964529, lng: 20.2607511 },
    { lat: 48.4967407, lng: 20.2599706 },
    { lat: 48.4966912, lng: 20.2594613 },
    { lat: 48.4967421, lng: 20.2592976 },
    { lat: 48.496588, lng: 20.2589074 },
    { lat: 48.4966674, lng: 20.2586052 },
    { lat: 48.4966598, lng: 20.2584734 },
    { lat: 48.4967707, lng: 20.2583732 },
    { lat: 48.4968604, lng: 20.2585029 },
    { lat: 48.4969565, lng: 20.2585181 },
    { lat: 48.497058, lng: 20.2583445 },
    { lat: 48.4969102, lng: 20.2581128 },
    { lat: 48.4971489, lng: 20.2575456 },
    { lat: 48.4972889, lng: 20.2573544 },
    { lat: 48.4974317, lng: 20.2574924 },
    { lat: 48.4974331, lng: 20.2576407 },
    { lat: 48.4976103, lng: 20.2577952 },
    { lat: 48.4976844, lng: 20.2577882 },
    { lat: 48.4977299, lng: 20.2577159 },
    { lat: 48.4976718, lng: 20.2575827 },
    { lat: 48.4976838, lng: 20.2573448 },
    { lat: 48.497746, lng: 20.2571218 },
    { lat: 48.4980893, lng: 20.2569794 },
    { lat: 48.4983991, lng: 20.2570656 },
    { lat: 48.4985395, lng: 20.2569173 },
    { lat: 48.4987274, lng: 20.2564547 },
    { lat: 48.4987388, lng: 20.2564043 },
    { lat: 48.498598, lng: 20.2563758 },
    { lat: 48.4985331, lng: 20.2561022 },
    { lat: 48.4986596, lng: 20.2559122 },
    { lat: 48.4987887, lng: 20.2558771 },
    { lat: 48.4989723, lng: 20.2555626 },
    { lat: 48.4988266, lng: 20.2551869 },
    { lat: 48.4988088, lng: 20.2545369 },
    { lat: 48.4986515, lng: 20.2547169 },
    { lat: 48.4983648, lng: 20.2552711 },
    { lat: 48.4983271, lng: 20.2552021 },
    { lat: 48.498378, lng: 20.2524575 },
    { lat: 48.4984281, lng: 20.2519313 },
    { lat: 48.4992227, lng: 20.2494873 },
    { lat: 48.4992492, lng: 20.2493901 },
    { lat: 48.4989757, lng: 20.2482631 },
    { lat: 48.4983517, lng: 20.2476937 },
    { lat: 48.4979475, lng: 20.2471566 },
    { lat: 48.4973142, lng: 20.2467276 },
    { lat: 48.4969073, lng: 20.2466098 },
    { lat: 48.4962593, lng: 20.2462851 },
    { lat: 48.4959857, lng: 20.2459743 },
    { lat: 48.4954058, lng: 20.2456949 },
    { lat: 48.4940821, lng: 20.2455427 },
    { lat: 48.493732, lng: 20.2456066 },
    { lat: 48.493431, lng: 20.2457697 },
    { lat: 48.4930551, lng: 20.245805 },
    { lat: 48.4918876, lng: 20.2454761 },
    { lat: 48.491807, lng: 20.2453186 },
    { lat: 48.4915379, lng: 20.2452935 },
    { lat: 48.4908673, lng: 20.2450821 },
    { lat: 48.4902564, lng: 20.2450434 },
    { lat: 48.4891988, lng: 20.2447277 },
    { lat: 48.4887147, lng: 20.2446812 },
    { lat: 48.4887138, lng: 20.2445389 },
    { lat: 48.4885213, lng: 20.244601 },
    { lat: 48.4883365, lng: 20.2445705 },
    { lat: 48.4880363, lng: 20.2443575 },
    { lat: 48.4879951, lng: 20.244171 },
    { lat: 48.4878268, lng: 20.2441293 },
    { lat: 48.4876941, lng: 20.2441917 },
    { lat: 48.4876099, lng: 20.2441397 },
    { lat: 48.4872304, lng: 20.2443127 },
    { lat: 48.4870473, lng: 20.2444965 },
    { lat: 48.4865449, lng: 20.2446334 },
    { lat: 48.4863694, lng: 20.2449798 },
    { lat: 48.4864054, lng: 20.2453724 },
    { lat: 48.4859956, lng: 20.2460292 },
    { lat: 48.4853985, lng: 20.2456258 },
    { lat: 48.4847079, lng: 20.2448847 },
    { lat: 48.4840791, lng: 20.2435196 },
    { lat: 48.4839005, lng: 20.2433089 },
    { lat: 48.4838904, lng: 20.2431962 },
    { lat: 48.4837208, lng: 20.2430102 },
    { lat: 48.48357, lng: 20.2427036 },
    { lat: 48.4834236, lng: 20.242529 },
    { lat: 48.4827504, lng: 20.2420051 },
    { lat: 48.4825122, lng: 20.2420152 },
    { lat: 48.4822729, lng: 20.2418927 },
    { lat: 48.4819491, lng: 20.2413702 },
    { lat: 48.4819256, lng: 20.2409485 },
    { lat: 48.4816937, lng: 20.2403287 },
    { lat: 48.4816765, lng: 20.2400988 },
    { lat: 48.4814182, lng: 20.2398062 },
    { lat: 48.4811091, lng: 20.2397073 },
    { lat: 48.4808597, lng: 20.239301 },
    { lat: 48.4807538, lng: 20.2391619 },
    { lat: 48.4801536, lng: 20.240685 },
    { lat: 48.479243, lng: 20.2416301 },
    { lat: 48.4791166, lng: 20.2424725 },
    { lat: 48.4788922, lng: 20.2431725 },
    { lat: 48.4785096, lng: 20.2437679 },
    { lat: 48.4776992, lng: 20.2444334 },
    { lat: 48.4769951, lng: 20.2461521 },
    { lat: 48.4765902, lng: 20.2467487 },
    { lat: 48.4763814, lng: 20.2470565 },
    { lat: 48.4759018, lng: 20.2476534 },
    { lat: 48.4751075, lng: 20.248918 },
    { lat: 48.4748879, lng: 20.2491166 },
    { lat: 48.4742849, lng: 20.2495034 },
    { lat: 48.4733996, lng: 20.2500713 },
    { lat: 48.4726377, lng: 20.2510155 },
    { lat: 48.4723132, lng: 20.2517471 },
    { lat: 48.4719587, lng: 20.2523512 },
    { lat: 48.4706341, lng: 20.2538766 },
    { lat: 48.4702526, lng: 20.2541054 },
    { lat: 48.4686039, lng: 20.2547357 },
    { lat: 48.4667512, lng: 20.2549297 },
    { lat: 48.4645949, lng: 20.2557671 },
    { lat: 48.4643584, lng: 20.2558508 },
  ],
  GemerskéTeplice: [
    { lat: 48.611035, lng: 20.296459 },
    { lat: 48.611244, lng: 20.296271 },
    { lat: 48.612169, lng: 20.295938 },
    { lat: 48.612703, lng: 20.296118 },
    { lat: 48.612963, lng: 20.296199 },
    { lat: 48.61319, lng: 20.296274 },
    { lat: 48.613362, lng: 20.296258 },
    { lat: 48.613473, lng: 20.29624 },
    { lat: 48.613583, lng: 20.296231 },
    { lat: 48.613823, lng: 20.296235 },
    { lat: 48.614041, lng: 20.296228 },
    { lat: 48.614298, lng: 20.296399 },
    { lat: 48.614462, lng: 20.296516 },
    { lat: 48.614629, lng: 20.296656 },
    { lat: 48.61507, lng: 20.297012 },
    { lat: 48.615581, lng: 20.297274 },
    { lat: 48.615981, lng: 20.297451 },
    { lat: 48.616171, lng: 20.297605 },
    { lat: 48.616347, lng: 20.297916 },
    { lat: 48.616627, lng: 20.298409 },
    { lat: 48.616754, lng: 20.298577 },
    { lat: 48.616748, lng: 20.298606 },
    { lat: 48.617092, lng: 20.299052 },
    { lat: 48.617425, lng: 20.29946 },
    { lat: 48.617444, lng: 20.299455 },
    { lat: 48.617736, lng: 20.299944 },
    { lat: 48.617876, lng: 20.300344 },
    { lat: 48.617897, lng: 20.300701 },
    { lat: 48.617987, lng: 20.301016 },
    { lat: 48.61805, lng: 20.301165 },
    { lat: 48.618154, lng: 20.301244 },
    { lat: 48.618302, lng: 20.30129 },
    { lat: 48.618391, lng: 20.301326 },
    { lat: 48.618657, lng: 20.301471 },
    { lat: 48.618908, lng: 20.301627 },
    { lat: 48.619476, lng: 20.302227 },
    { lat: 48.619791, lng: 20.302556 },
    { lat: 48.619946, lng: 20.302691 },
    { lat: 48.620137, lng: 20.302782 },
    { lat: 48.620338, lng: 20.302844 },
    { lat: 48.620487, lng: 20.302908 },
    { lat: 48.620569, lng: 20.302926 },
    { lat: 48.620661, lng: 20.302876 },
    { lat: 48.620849, lng: 20.302772 },
    { lat: 48.620901, lng: 20.302749 },
    { lat: 48.621026, lng: 20.302748 },
    { lat: 48.621838, lng: 20.303266 },
    { lat: 48.622007, lng: 20.303336 },
    { lat: 48.622183, lng: 20.303382 },
    { lat: 48.622399, lng: 20.303487 },
    { lat: 48.622614, lng: 20.303645 },
    { lat: 48.622644, lng: 20.303667 },
    { lat: 48.622898, lng: 20.303678 },
    { lat: 48.623047, lng: 20.303687 },
    { lat: 48.623114, lng: 20.303698 },
    { lat: 48.62349, lng: 20.303781 },
    { lat: 48.623823, lng: 20.303601 },
    { lat: 48.623996, lng: 20.30354 },
    { lat: 48.624373, lng: 20.303208 },
    { lat: 48.624521, lng: 20.303191 },
    { lat: 48.624748, lng: 20.303184 },
    { lat: 48.624886, lng: 20.303081 },
    { lat: 48.625349, lng: 20.302715 },
    { lat: 48.625457, lng: 20.302601 },
    { lat: 48.625676, lng: 20.302305 },
    { lat: 48.625755, lng: 20.302198 },
    { lat: 48.625849, lng: 20.302071 },
    { lat: 48.625948, lng: 20.30195 },
    { lat: 48.626004, lng: 20.301851 },
    { lat: 48.626105, lng: 20.301677 },
    { lat: 48.626216, lng: 20.301506 },
    { lat: 48.62629, lng: 20.301435 },
    { lat: 48.626668, lng: 20.301112 },
    { lat: 48.627121, lng: 20.300775 },
    { lat: 48.627251, lng: 20.300722 },
    { lat: 48.627364, lng: 20.300709 },
    { lat: 48.627593, lng: 20.300873 },
    { lat: 48.627626, lng: 20.300889 },
    { lat: 48.627666, lng: 20.300906 },
    { lat: 48.627762, lng: 20.300841 },
    { lat: 48.627977, lng: 20.30041 },
    { lat: 48.628361, lng: 20.300193 },
    { lat: 48.628614, lng: 20.299988 },
    { lat: 48.628888, lng: 20.299683 },
    { lat: 48.629204, lng: 20.299266 },
    { lat: 48.629389, lng: 20.298994 },
    { lat: 48.629673, lng: 20.298512 },
    { lat: 48.62995, lng: 20.298052 },
    { lat: 48.629959, lng: 20.298035 },
    { lat: 48.6298226, lng: 20.2979302 },
    { lat: 48.6294129, lng: 20.2978828 },
    { lat: 48.6291154, lng: 20.2977649 },
    { lat: 48.628653, lng: 20.2974018 },
    { lat: 48.6283583, lng: 20.2968083 },
    { lat: 48.6282723, lng: 20.2961375 },
    { lat: 48.6281991, lng: 20.2959628 },
    { lat: 48.6279574, lng: 20.2956925 },
    { lat: 48.626875, lng: 20.2948538 },
    { lat: 48.6267551, lng: 20.294469 },
    { lat: 48.6264344, lng: 20.2937608 },
    { lat: 48.625917, lng: 20.2931158 },
    { lat: 48.6254776, lng: 20.2928294 },
    { lat: 48.6253285, lng: 20.2924166 },
    { lat: 48.6252667, lng: 20.2911018 },
    { lat: 48.6250831, lng: 20.2903298 },
    { lat: 48.6250861, lng: 20.2897172 },
    { lat: 48.6248907, lng: 20.2886187 },
    { lat: 48.6246804, lng: 20.287964 },
    { lat: 48.6244783, lng: 20.2876934 },
    { lat: 48.6242319, lng: 20.2871581 },
    { lat: 48.6239616, lng: 20.286187 },
    { lat: 48.6238973, lng: 20.2856979 },
    { lat: 48.623202, lng: 20.2851686 },
    { lat: 48.622822, lng: 20.2851621 },
    { lat: 48.6223352, lng: 20.2843554 },
    { lat: 48.6219891, lng: 20.283484 },
    { lat: 48.6217166, lng: 20.2833415 },
    { lat: 48.6214949, lng: 20.2833194 },
    { lat: 48.620915, lng: 20.2826905 },
    { lat: 48.6205172, lng: 20.2812557 },
    { lat: 48.6201556, lng: 20.2803698 },
    { lat: 48.6199881, lng: 20.2798378 },
    { lat: 48.6199297, lng: 20.2792144 },
    { lat: 48.6199538, lng: 20.2790225 },
    { lat: 48.620094, lng: 20.2786942 },
    { lat: 48.6201141, lng: 20.2779777 },
    { lat: 48.6199626, lng: 20.2774509 },
    { lat: 48.6199392, lng: 20.2769967 },
    { lat: 48.6197601, lng: 20.2763386 },
    { lat: 48.6198276, lng: 20.2757122 },
    { lat: 48.6198173, lng: 20.2753077 },
    { lat: 48.6199286, lng: 20.274847 },
    { lat: 48.6199246, lng: 20.2746904 },
    { lat: 48.6194789, lng: 20.2738976 },
    { lat: 48.6193245, lng: 20.2737245 },
    { lat: 48.6191972, lng: 20.2734099 },
    { lat: 48.6192425, lng: 20.2728193 },
    { lat: 48.6193859, lng: 20.2721714 },
    { lat: 48.6193332, lng: 20.2719505 },
    { lat: 48.6194162, lng: 20.2715714 },
    { lat: 48.6193797, lng: 20.2709884 },
    { lat: 48.6195304, lng: 20.2703587 },
    { lat: 48.6194362, lng: 20.2700529 },
    { lat: 48.6193109, lng: 20.2699357 },
    { lat: 48.6187884, lng: 20.2699649 },
    { lat: 48.6182909, lng: 20.2698861 },
    { lat: 48.6178006, lng: 20.2691593 },
    { lat: 48.6171633, lng: 20.2697654 },
    { lat: 48.6168937, lng: 20.2697822 },
    { lat: 48.6165679, lng: 20.269946 },
    { lat: 48.6161147, lng: 20.2700138 },
    { lat: 48.6156288, lng: 20.2697447 },
    { lat: 48.6152962, lng: 20.269912 },
    { lat: 48.615493, lng: 20.2691045 },
    { lat: 48.6156387, lng: 20.2687184 },
    { lat: 48.6156838, lng: 20.2682598 },
    { lat: 48.6154847, lng: 20.2677855 },
    { lat: 48.6150704, lng: 20.2673053 },
    { lat: 48.6144983, lng: 20.2669051 },
    { lat: 48.6141493, lng: 20.2667087 },
    { lat: 48.6137826, lng: 20.2667499 },
    { lat: 48.6132758, lng: 20.2670635 },
    { lat: 48.6125064, lng: 20.2671395 },
    { lat: 48.6121485, lng: 20.2670888 },
    { lat: 48.6114957, lng: 20.2673547 },
    { lat: 48.6111188, lng: 20.2673676 },
    { lat: 48.6106791, lng: 20.2668114 },
    { lat: 48.6104329, lng: 20.2662549 },
    { lat: 48.6100367, lng: 20.2660051 },
    { lat: 48.60977, lng: 20.2659522 },
    { lat: 48.60931, lng: 20.2656805 },
    { lat: 48.6090576, lng: 20.265679 },
    { lat: 48.6054158, lng: 20.2613971 },
    { lat: 48.605457, lng: 20.2610421 },
    { lat: 48.6049198, lng: 20.2604746 },
    { lat: 48.6045235, lng: 20.2598765 },
    { lat: 48.6040122, lng: 20.2592839 },
    { lat: 48.6038623, lng: 20.2589376 },
    { lat: 48.6036048, lng: 20.2586096 },
    { lat: 48.6030561, lng: 20.2577243 },
    { lat: 48.603063, lng: 20.257581 },
    { lat: 48.6029631, lng: 20.2573658 },
    { lat: 48.6031188, lng: 20.2570913 },
    { lat: 48.6029927, lng: 20.2568618 },
    { lat: 48.6018127, lng: 20.2553936 },
    { lat: 48.6021248, lng: 20.2549431 },
    { lat: 48.6019602, lng: 20.2546555 },
    { lat: 48.6025648, lng: 20.2534904 },
    { lat: 48.6024932, lng: 20.253161 },
    { lat: 48.6018228, lng: 20.2519024 },
    { lat: 48.600657, lng: 20.2493767 },
    { lat: 48.600218, lng: 20.2486998 },
    { lat: 48.6005756, lng: 20.2475552 },
    { lat: 48.6007848, lng: 20.2466143 },
    { lat: 48.5995758, lng: 20.2462276 },
    { lat: 48.5989626, lng: 20.2459062 },
    { lat: 48.5983395, lng: 20.2459828 },
    { lat: 48.5981566, lng: 20.2459157 },
    { lat: 48.5981386, lng: 20.2458562 },
    { lat: 48.59766, lng: 20.2458322 },
    { lat: 48.597726, lng: 20.2455914 },
    { lat: 48.5978868, lng: 20.2455576 },
    { lat: 48.5979816, lng: 20.2454509 },
    { lat: 48.5979974, lng: 20.2453075 },
    { lat: 48.5981414, lng: 20.2450244 },
    { lat: 48.5980766, lng: 20.244979 },
    { lat: 48.5980785, lng: 20.2449134 },
    { lat: 48.5981517, lng: 20.2448267 },
    { lat: 48.5981809, lng: 20.2444569 },
    { lat: 48.5982611, lng: 20.244461 },
    { lat: 48.5982646, lng: 20.2441572 },
    { lat: 48.5983354, lng: 20.2441223 },
    { lat: 48.5983582, lng: 20.2440196 },
    { lat: 48.5984374, lng: 20.2440298 },
    { lat: 48.5985106, lng: 20.2439417 },
    { lat: 48.5984834, lng: 20.2437965 },
    { lat: 48.598539, lng: 20.2436638 },
    { lat: 48.5984524, lng: 20.2435275 },
    { lat: 48.5985126, lng: 20.2432862 },
    { lat: 48.5985576, lng: 20.2433657 },
    { lat: 48.5986406, lng: 20.2433596 },
    { lat: 48.5987101, lng: 20.2432964 },
    { lat: 48.5987035, lng: 20.243101 },
    { lat: 48.5987496, lng: 20.2430135 },
    { lat: 48.5987962, lng: 20.242968 },
    { lat: 48.5988455, lng: 20.2430757 },
    { lat: 48.5988956, lng: 20.2429986 },
    { lat: 48.5988709, lng: 20.2428652 },
    { lat: 48.5990262, lng: 20.2428298 },
    { lat: 48.5990406, lng: 20.2426584 },
    { lat: 48.5991112, lng: 20.2426464 },
    { lat: 48.5991218, lng: 20.2425155 },
    { lat: 48.5992308, lng: 20.2424274 },
    { lat: 48.5991634, lng: 20.2422627 },
    { lat: 48.5992365, lng: 20.242001 },
    { lat: 48.5992752, lng: 20.2421256 },
    { lat: 48.5993438, lng: 20.2421229 },
    { lat: 48.5993968, lng: 20.2420142 },
    { lat: 48.5993586, lng: 20.2419323 },
    { lat: 48.5995026, lng: 20.241641 },
    { lat: 48.5995442, lng: 20.2418991 },
    { lat: 48.5996411, lng: 20.2417541 },
    { lat: 48.5996824, lng: 20.2416066 },
    { lat: 48.5995761, lng: 20.2415073 },
    { lat: 48.5998806, lng: 20.2414339 },
    { lat: 48.5998914, lng: 20.2412842 },
    { lat: 48.5998309, lng: 20.2412875 },
    { lat: 48.5998343, lng: 20.2412363 },
    { lat: 48.5999315, lng: 20.2412115 },
    { lat: 48.5999637, lng: 20.24103 },
    { lat: 48.6000383, lng: 20.2410681 },
    { lat: 48.6000832, lng: 20.2410082 },
    { lat: 48.6000461, lng: 20.2408663 },
    { lat: 48.6001686, lng: 20.24044 },
    { lat: 48.6002048, lng: 20.2404421 },
    { lat: 48.6002201, lng: 20.2406005 },
    { lat: 48.6003291, lng: 20.2405934 },
    { lat: 48.6003563, lng: 20.2403988 },
    { lat: 48.6004059, lng: 20.2403709 },
    { lat: 48.6004936, lng: 20.2404807 },
    { lat: 48.6005685, lng: 20.2403241 },
    { lat: 48.6005411, lng: 20.2402035 },
    { lat: 48.6005875, lng: 20.240042 },
    { lat: 48.6005188, lng: 20.2400109 },
    { lat: 48.6005665, lng: 20.239966 },
    { lat: 48.6005503, lng: 20.2398959 },
    { lat: 48.6005063, lng: 20.2398942 },
    { lat: 48.6004648, lng: 20.239788 },
    { lat: 48.6005345, lng: 20.2396415 },
    { lat: 48.6004312, lng: 20.2395964 },
    { lat: 48.600018, lng: 20.239035 },
    { lat: 48.5999127, lng: 20.2374031 },
    { lat: 48.599626, lng: 20.2374156 },
    { lat: 48.5977193, lng: 20.2353527 },
    { lat: 48.5975605, lng: 20.2352408 },
    { lat: 48.5968222, lng: 20.2340368 },
    { lat: 48.5965492, lng: 20.2328671 },
    { lat: 48.5963607, lng: 20.2326024 },
    { lat: 48.5960329, lng: 20.2323482 },
    { lat: 48.5958287, lng: 20.2320269 },
    { lat: 48.5955708, lng: 20.2313768 },
    { lat: 48.5953271, lng: 20.2311726 },
    { lat: 48.5952838, lng: 20.230904 },
    { lat: 48.5952744, lng: 20.230892 },
    { lat: 48.5951104, lng: 20.2313008 },
    { lat: 48.594871, lng: 20.2316541 },
    { lat: 48.5944421, lng: 20.232138 },
    { lat: 48.5941442, lng: 20.232603 },
    { lat: 48.5935996, lng: 20.2342256 },
    { lat: 48.5934609, lng: 20.2344459 },
    { lat: 48.5928052, lng: 20.2349191 },
    { lat: 48.5924766, lng: 20.2356917 },
    { lat: 48.5924457, lng: 20.2363011 },
    { lat: 48.592508, lng: 20.2374965 },
    { lat: 48.5924321, lng: 20.2381338 },
    { lat: 48.5924192, lng: 20.2389123 },
    { lat: 48.5924676, lng: 20.2391961 },
    { lat: 48.592688, lng: 20.2397539 },
    { lat: 48.5927276, lng: 20.2403807 },
    { lat: 48.5928962, lng: 20.240791 },
    { lat: 48.5927784, lng: 20.2411172 },
    { lat: 48.5925492, lng: 20.2414112 },
    { lat: 48.5924556, lng: 20.2416392 },
    { lat: 48.5921592, lng: 20.2429922 },
    { lat: 48.5921597, lng: 20.2433113 },
    { lat: 48.5922529, lng: 20.2438999 },
    { lat: 48.59224, lng: 20.2442557 },
    { lat: 48.592453, lng: 20.2450561 },
    { lat: 48.5923654, lng: 20.2453992 },
    { lat: 48.5920667, lng: 20.2458165 },
    { lat: 48.5915309, lng: 20.2471011 },
    { lat: 48.5913669, lng: 20.2480459 },
    { lat: 48.5912732, lng: 20.2482041 },
    { lat: 48.5907345, lng: 20.2486818 },
    { lat: 48.590192, lng: 20.2497945 },
    { lat: 48.58972, lng: 20.251831 },
    { lat: 48.5898673, lng: 20.2525036 },
    { lat: 48.5899943, lng: 20.2526121 },
    { lat: 48.5901711, lng: 20.2530397 },
    { lat: 48.5902597, lng: 20.2533989 },
    { lat: 48.5902813, lng: 20.253854 },
    { lat: 48.5905168, lng: 20.2549342 },
    { lat: 48.5905719, lng: 20.2551053 },
    { lat: 48.5908457, lng: 20.2561055 },
    { lat: 48.5914006, lng: 20.2574564 },
    { lat: 48.5915512, lng: 20.2581302 },
    { lat: 48.5917014, lng: 20.2584546 },
    { lat: 48.5918719, lng: 20.2587491 },
    { lat: 48.5921497, lng: 20.2590219 },
    { lat: 48.5927112, lng: 20.2598937 },
    { lat: 48.592838, lng: 20.2603359 },
    { lat: 48.59304, lng: 20.2607404 },
    { lat: 48.5931603, lng: 20.2611319 },
    { lat: 48.5934639, lng: 20.2617467 },
    { lat: 48.593714, lng: 20.2620323 },
    { lat: 48.5940515, lng: 20.2622225 },
    { lat: 48.5944893, lng: 20.2625886 },
    { lat: 48.5946304, lng: 20.2635877 },
    { lat: 48.5944981, lng: 20.2672438 },
    { lat: 48.5941052, lng: 20.2711197 },
    { lat: 48.5941564, lng: 20.2721286 },
    { lat: 48.5948126, lng: 20.2774774 },
    { lat: 48.5949659, lng: 20.2783349 },
    { lat: 48.5945, lng: 20.2786567 },
    { lat: 48.5941373, lng: 20.2788006 },
    { lat: 48.5938056, lng: 20.2788385 },
    { lat: 48.5933091, lng: 20.2787013 },
    { lat: 48.5925761, lng: 20.2782171 },
    { lat: 48.5922095, lng: 20.2781788 },
    { lat: 48.5919232, lng: 20.2782013 },
    { lat: 48.5909525, lng: 20.2784577 },
    { lat: 48.5900808, lng: 20.2787697 },
    { lat: 48.5897021, lng: 20.2787357 },
    { lat: 48.588769, lng: 20.2784309 },
    { lat: 48.5882245, lng: 20.2785061 },
    { lat: 48.5867701, lng: 20.2796319 },
    { lat: 48.5862164, lng: 20.2799288 },
    { lat: 48.5857465, lng: 20.2800356 },
    { lat: 48.5857052, lng: 20.2800454 },
    { lat: 48.5851703, lng: 20.281328 },
    { lat: 48.5858301, lng: 20.2838943 },
    { lat: 48.5859664, lng: 20.2838117 },
    { lat: 48.5864588, lng: 20.2862767 },
    { lat: 48.5869449, lng: 20.2882027 },
    { lat: 48.5884205, lng: 20.2910157 },
    { lat: 48.5886651, lng: 20.2908227 },
    { lat: 48.5886917, lng: 20.2909217 },
    { lat: 48.5887536, lng: 20.2908635 },
    { lat: 48.5887355, lng: 20.2913201 },
    { lat: 48.5888905, lng: 20.2924826 },
    { lat: 48.5889668, lng: 20.2927916 },
    { lat: 48.5895312, lng: 20.2938254 },
    { lat: 48.5897104, lng: 20.294115 },
    { lat: 48.5909866, lng: 20.2955793 },
    { lat: 48.5916728, lng: 20.2965905 },
    { lat: 48.5922622, lng: 20.2976029 },
    { lat: 48.5926013, lng: 20.2980632 },
    { lat: 48.5937834, lng: 20.300005 },
    { lat: 48.595284, lng: 20.3031747 },
    { lat: 48.5954537, lng: 20.3034505 },
    { lat: 48.5960142, lng: 20.3049012 },
    { lat: 48.5964885, lng: 20.3058558 },
    { lat: 48.5973829, lng: 20.3070952 },
    { lat: 48.5983677, lng: 20.3083178 },
    { lat: 48.6000353, lng: 20.3100908 },
    { lat: 48.606387, lng: 20.316443 },
    { lat: 48.606908, lng: 20.314711 },
    { lat: 48.607123, lng: 20.313996 },
    { lat: 48.607666, lng: 20.311879 },
    { lat: 48.60804, lng: 20.309866 },
    { lat: 48.609239, lng: 20.30659 },
    { lat: 48.609455, lng: 20.304573 },
    { lat: 48.609463, lng: 20.30449 },
    { lat: 48.609468, lng: 20.304446 },
    { lat: 48.610011, lng: 20.302948 },
    { lat: 48.610234, lng: 20.301004 },
    { lat: 48.610334, lng: 20.299353 },
    { lat: 48.611035, lng: 20.296459 },
  ],
  Držkovce: [
    { lat: 48.5265558, lng: 20.2643259 },
    { lat: 48.5266004, lng: 20.264599 },
    { lat: 48.527126, lng: 20.264535 },
    { lat: 48.5271816, lng: 20.2644003 },
    { lat: 48.527544, lng: 20.2642264 },
    { lat: 48.5279044, lng: 20.2641957 },
    { lat: 48.5281312, lng: 20.2643825 },
    { lat: 48.5282151, lng: 20.2646229 },
    { lat: 48.5284474, lng: 20.2647964 },
    { lat: 48.528663, lng: 20.2647525 },
    { lat: 48.5291058, lng: 20.2647902 },
    { lat: 48.5292506, lng: 20.2648592 },
    { lat: 48.5293799, lng: 20.2648 },
    { lat: 48.5300347, lng: 20.2648691 },
    { lat: 48.530562, lng: 20.2650121 },
    { lat: 48.5306733, lng: 20.2649808 },
    { lat: 48.5307568, lng: 20.2650454 },
    { lat: 48.5309406, lng: 20.2649894 },
    { lat: 48.5314002, lng: 20.2650778 },
    { lat: 48.5317165, lng: 20.2653038 },
    { lat: 48.5318513, lng: 20.2655507 },
    { lat: 48.5323839, lng: 20.2661137 },
    { lat: 48.5324107, lng: 20.266021 },
    { lat: 48.5326792, lng: 20.2661223 },
    { lat: 48.5327447, lng: 20.2662394 },
    { lat: 48.5327463, lng: 20.2661945 },
    { lat: 48.5329003, lng: 20.2662702 },
    { lat: 48.5329004, lng: 20.2663846 },
    { lat: 48.5330482, lng: 20.2664818 },
    { lat: 48.5332188, lng: 20.2668094 },
    { lat: 48.5334763, lng: 20.2668211 },
    { lat: 48.5334719, lng: 20.266943 },
    { lat: 48.5336313, lng: 20.2670992 },
    { lat: 48.5335833, lng: 20.2673651 },
    { lat: 48.533749, lng: 20.2675396 },
    { lat: 48.5338132, lng: 20.2674553 },
    { lat: 48.5339176, lng: 20.2676737 },
    { lat: 48.5339657, lng: 20.2676061 },
    { lat: 48.5342733, lng: 20.2678414 },
    { lat: 48.5345741, lng: 20.2676136 },
    { lat: 48.5346619, lng: 20.2676413 },
    { lat: 48.5347466, lng: 20.2678206 },
    { lat: 48.5355598, lng: 20.2681896 },
    { lat: 48.5357264, lng: 20.2680453 },
    { lat: 48.5358284, lng: 20.2681178 },
    { lat: 48.5359246, lng: 20.2677887 },
    { lat: 48.5361203, lng: 20.2675564 },
    { lat: 48.5361851, lng: 20.267853 },
    { lat: 48.5363888, lng: 20.2677651 },
    { lat: 48.5368578, lng: 20.267718 },
    { lat: 48.5371821, lng: 20.2675467 },
    { lat: 48.537243, lng: 20.2677509 },
    { lat: 48.537508, lng: 20.2677861 },
    { lat: 48.537728, lng: 20.2675957 },
    { lat: 48.5376832, lng: 20.2677454 },
    { lat: 48.537985, lng: 20.2677208 },
    { lat: 48.5381367, lng: 20.2678198 },
    { lat: 48.5381785, lng: 20.2679934 },
    { lat: 48.5383396, lng: 20.2681954 },
    { lat: 48.5390855, lng: 20.268574 },
    { lat: 48.5393695, lng: 20.2689518 },
    { lat: 48.539545, lng: 20.2690555 },
    { lat: 48.539895, lng: 20.2691118 },
    { lat: 48.5411418, lng: 20.2688008 },
    { lat: 48.5413722, lng: 20.2686717 },
    { lat: 48.5416563, lng: 20.2681408 },
    { lat: 48.5417846, lng: 20.2679957 },
    { lat: 48.5421409, lng: 20.2677198 },
    { lat: 48.5423294, lng: 20.2676777 },
    { lat: 48.5423699, lng: 20.2677355 },
    { lat: 48.5426407, lng: 20.2674041 },
    { lat: 48.5426835, lng: 20.2672329 },
    { lat: 48.5429385, lng: 20.2670155 },
    { lat: 48.5429532, lng: 20.2667931 },
    { lat: 48.5432782, lng: 20.266609 },
    { lat: 48.5435528, lng: 20.2662655 },
    { lat: 48.5436734, lng: 20.2659806 },
    { lat: 48.5437425, lng: 20.2659901 },
    { lat: 48.5437997, lng: 20.2657624 },
    { lat: 48.5442088, lng: 20.2652814 },
    { lat: 48.544494, lng: 20.2646963 },
    { lat: 48.5446024, lng: 20.2645845 },
    { lat: 48.5446242, lng: 20.2642581 },
    { lat: 48.5448726, lng: 20.2637918 },
    { lat: 48.5450129, lng: 20.2636935 },
    { lat: 48.5451355, lng: 20.2633758 },
    { lat: 48.5451275, lng: 20.2631451 },
    { lat: 48.5453865, lng: 20.2628314 },
    { lat: 48.5454605, lng: 20.2628223 },
    { lat: 48.5456401, lng: 20.2623828 },
    { lat: 48.5457605, lng: 20.2622408 },
    { lat: 48.5457343, lng: 20.2620328 },
    { lat: 48.5458676, lng: 20.2619301 },
    { lat: 48.5459815, lng: 20.2617091 },
    { lat: 48.5461536, lng: 20.2615849 },
    { lat: 48.546257, lng: 20.2615812 },
    { lat: 48.546287, lng: 20.2614861 },
    { lat: 48.5465396, lng: 20.2615245 },
    { lat: 48.5465419, lng: 20.2614081 },
    { lat: 48.5467718, lng: 20.2613565 },
    { lat: 48.5467689, lng: 20.2612299 },
    { lat: 48.5470309, lng: 20.260821 },
    { lat: 48.5471635, lng: 20.2608144 },
    { lat: 48.5474168, lng: 20.2606132 },
    { lat: 48.547584, lng: 20.2605697 },
    { lat: 48.5477558, lng: 20.2603299 },
    { lat: 48.5478676, lng: 20.2603355 },
    { lat: 48.5481145, lng: 20.2598601 },
    { lat: 48.548339, lng: 20.2597844 },
    { lat: 48.5486316, lng: 20.2599514 },
    { lat: 48.5490332, lng: 20.2599071 },
    { lat: 48.5496073, lng: 20.2603876 },
    { lat: 48.5500066, lng: 20.260202 },
    { lat: 48.5503284, lng: 20.2605924 },
    { lat: 48.5504755, lng: 20.2605515 },
    { lat: 48.5511911, lng: 20.2611319 },
    { lat: 48.5515749, lng: 20.2613234 },
    { lat: 48.5516976, lng: 20.2611265 },
    { lat: 48.5520543, lng: 20.2617427 },
    { lat: 48.5521949, lng: 20.2617642 },
    { lat: 48.552321, lng: 20.2621044 },
    { lat: 48.5524806, lng: 20.2622984 },
    { lat: 48.5525974, lng: 20.2625993 },
    { lat: 48.5541763, lng: 20.2654127 },
    { lat: 48.554247, lng: 20.2649503 },
    { lat: 48.5543715, lng: 20.2645385 },
    { lat: 48.5546593, lng: 20.2641294 },
    { lat: 48.555362, lng: 20.2634426 },
    { lat: 48.5573232, lng: 20.2621235 },
    { lat: 48.5576365, lng: 20.2617871 },
    { lat: 48.5576899, lng: 20.2613742 },
    { lat: 48.5575903, lng: 20.2610514 },
    { lat: 48.5578705, lng: 20.2594833 },
    { lat: 48.5580438, lng: 20.2589668 },
    { lat: 48.557919, lng: 20.2583192 },
    { lat: 48.5585736, lng: 20.2565943 },
    { lat: 48.5588603, lng: 20.255958 },
    { lat: 48.5594783, lng: 20.2555276 },
    { lat: 48.5598482, lng: 20.2551153 },
    { lat: 48.5604666, lng: 20.2550638 },
    { lat: 48.5606535, lng: 20.2547191 },
    { lat: 48.5612993, lng: 20.2541088 },
    { lat: 48.5618254, lng: 20.2522704 },
    { lat: 48.5625431, lng: 20.2510288 },
    { lat: 48.5636264, lng: 20.2514232 },
    { lat: 48.5637104, lng: 20.2515543 },
    { lat: 48.5639827, lng: 20.2514924 },
    { lat: 48.5641774, lng: 20.2515466 },
    { lat: 48.5643947, lng: 20.2518527 },
    { lat: 48.5647566, lng: 20.251952 },
    { lat: 48.5661387, lng: 20.2527209 },
    { lat: 48.5666008, lng: 20.2527037 },
    { lat: 48.5670456, lng: 20.2523034 },
    { lat: 48.5671731, lng: 20.2524782 },
    { lat: 48.5674352, lng: 20.2524702 },
    { lat: 48.5679645, lng: 20.2526992 },
    { lat: 48.568297, lng: 20.2527577 },
    { lat: 48.5691756, lng: 20.2540422 },
    { lat: 48.5696877, lng: 20.254885 },
    { lat: 48.5700253, lng: 20.2556725 },
    { lat: 48.5702002, lng: 20.2558231 },
    { lat: 48.5710329, lng: 20.2560855 },
    { lat: 48.5715191, lng: 20.2563084 },
    { lat: 48.571423, lng: 20.2560383 },
    { lat: 48.5709353, lng: 20.2545912 },
    { lat: 48.5705697, lng: 20.2539783 },
    { lat: 48.5703821, lng: 20.2535685 },
    { lat: 48.5703251, lng: 20.253179 },
    { lat: 48.5700827, lng: 20.2528331 },
    { lat: 48.5698863, lng: 20.2523686 },
    { lat: 48.5698964, lng: 20.2521437 },
    { lat: 48.569709, lng: 20.2508001 },
    { lat: 48.5695334, lng: 20.25029 },
    { lat: 48.5694499, lng: 20.2497787 },
    { lat: 48.5691366, lng: 20.2487252 },
    { lat: 48.5690136, lng: 20.248464 },
    { lat: 48.5687975, lng: 20.2474195 },
    { lat: 48.5688422, lng: 20.2471794 },
    { lat: 48.5690753, lng: 20.2467867 },
    { lat: 48.5693554, lng: 20.2460684 },
    { lat: 48.5695488, lng: 20.2457792 },
    { lat: 48.5700153, lng: 20.2439171 },
    { lat: 48.570826, lng: 20.2414527 },
    { lat: 48.5711409, lng: 20.2394943 },
    { lat: 48.5714299, lng: 20.2389991 },
    { lat: 48.5716011, lng: 20.2385447 },
    { lat: 48.5719271, lng: 20.2382215 },
    { lat: 48.5725328, lng: 20.2379345 },
    { lat: 48.5729136, lng: 20.2378794 },
    { lat: 48.5733144, lng: 20.2377087 },
    { lat: 48.5734747, lng: 20.2376042 },
    { lat: 48.5740187, lng: 20.2370123 },
    { lat: 48.5742502, lng: 20.236125 },
    { lat: 48.5747754, lng: 20.2349194 },
    { lat: 48.5748769, lng: 20.2345824 },
    { lat: 48.5753846, lng: 20.2336721 },
    { lat: 48.5755375, lng: 20.2330149 },
    { lat: 48.5756688, lng: 20.2326933 },
    { lat: 48.5759967, lng: 20.2320216 },
    { lat: 48.5763038, lng: 20.2315901 },
    { lat: 48.576447, lng: 20.2312282 },
    { lat: 48.5767032, lng: 20.2308465 },
    { lat: 48.577033, lng: 20.2295971 },
    { lat: 48.5766824, lng: 20.2294524 },
    { lat: 48.5760886, lng: 20.2292431 },
    { lat: 48.5756695, lng: 20.2286178 },
    { lat: 48.5752247, lng: 20.2283946 },
    { lat: 48.5746417, lng: 20.2276722 },
    { lat: 48.5744199, lng: 20.227533 },
    { lat: 48.5737384, lng: 20.2264304 },
    { lat: 48.5733812, lng: 20.2262832 },
    { lat: 48.5730275, lng: 20.2260326 },
    { lat: 48.5722727, lng: 20.2250666 },
    { lat: 48.5717557, lng: 20.2248098 },
    { lat: 48.5713894, lng: 20.2245262 },
    { lat: 48.5710741, lng: 20.2244954 },
    { lat: 48.570681, lng: 20.2243568 },
    { lat: 48.5699764, lng: 20.223573 },
    { lat: 48.5696848, lng: 20.2234106 },
    { lat: 48.5690271, lng: 20.2226514 },
    { lat: 48.5678387, lng: 20.2217967 },
    { lat: 48.5675772, lng: 20.221708 },
    { lat: 48.5668245, lng: 20.2218116 },
    { lat: 48.5665211, lng: 20.2216322 },
    { lat: 48.5660277, lng: 20.2205007 },
    { lat: 48.5635786, lng: 20.2186004 },
    { lat: 48.5627361, lng: 20.2176707 },
    { lat: 48.5624324, lng: 20.2172037 },
    { lat: 48.5619648, lng: 20.2168324 },
    { lat: 48.5614246, lng: 20.2165085 },
    { lat: 48.5611692, lng: 20.2162291 },
    { lat: 48.5603413, lng: 20.2150742 },
    { lat: 48.5600777, lng: 20.2148461 },
    { lat: 48.5595414, lng: 20.2146193 },
    { lat: 48.55886, lng: 20.2141952 },
    { lat: 48.5581859, lng: 20.2135338 },
    { lat: 48.5579436, lng: 20.2127445 },
    { lat: 48.5578639, lng: 20.2121875 },
    { lat: 48.5576371, lng: 20.2117289 },
    { lat: 48.5570354, lng: 20.2109382 },
    { lat: 48.5567061, lng: 20.2102244 },
    { lat: 48.5555068, lng: 20.208824 },
    { lat: 48.5545811, lng: 20.2081518 },
    { lat: 48.5542743, lng: 20.2079984 },
    { lat: 48.5541066, lng: 20.2077787 },
    { lat: 48.5536098, lng: 20.2077205 },
    { lat: 48.5534356, lng: 20.2078121 },
    { lat: 48.5533347, lng: 20.2079627 },
    { lat: 48.5532687, lng: 20.2081405 },
    { lat: 48.5532493, lng: 20.2085866 },
    { lat: 48.5531376, lng: 20.2088897 },
    { lat: 48.5529861, lng: 20.2090148 },
    { lat: 48.5527154, lng: 20.2090902 },
    { lat: 48.5520795, lng: 20.208654 },
    { lat: 48.551418, lng: 20.2079428 },
    { lat: 48.550947, lng: 20.2075371 },
    { lat: 48.5505732, lng: 20.2070091 },
    { lat: 48.5499011, lng: 20.2064578 },
    { lat: 48.5497512, lng: 20.2062578 },
    { lat: 48.5495688, lng: 20.2057533 },
    { lat: 48.5494012, lng: 20.2050594 },
    { lat: 48.5493239, lng: 20.2041433 },
    { lat: 48.5491306, lng: 20.2031158 },
    { lat: 48.5491786, lng: 20.2026828 },
    { lat: 48.5492628, lng: 20.2023719 },
    { lat: 48.5491397, lng: 20.2020493 },
    { lat: 48.548896, lng: 20.2017345 },
    { lat: 48.5485431, lng: 20.2008823 },
    { lat: 48.5481739, lng: 20.2002452 },
    { lat: 48.5475474, lng: 20.1999835 },
    { lat: 48.5466594, lng: 20.1989661 },
    { lat: 48.5465917, lng: 20.1990434 },
    { lat: 48.5465267, lng: 20.19897 },
    { lat: 48.5464591, lng: 20.1992204 },
    { lat: 48.5461656, lng: 20.199598 },
    { lat: 48.5460971, lng: 20.1998847 },
    { lat: 48.5460339, lng: 20.1999072 },
    { lat: 48.545938, lng: 20.2001007 },
    { lat: 48.5456553, lng: 20.2004347 },
    { lat: 48.5454612, lng: 20.2005917 },
    { lat: 48.5451843, lng: 20.2011018 },
    { lat: 48.5444093, lng: 20.2016591 },
    { lat: 48.5443178, lng: 20.2018725 },
    { lat: 48.5440621, lng: 20.2020981 },
    { lat: 48.5440271, lng: 20.2023739 },
    { lat: 48.543823, lng: 20.202369 },
    { lat: 48.5436784, lng: 20.2026107 },
    { lat: 48.5436886, lng: 20.2028366 },
    { lat: 48.5436176, lng: 20.2029472 },
    { lat: 48.5435779, lng: 20.2028819 },
    { lat: 48.543476, lng: 20.2030131 },
    { lat: 48.5434091, lng: 20.2031993 },
    { lat: 48.5432916, lng: 20.203187 },
    { lat: 48.5431785, lng: 20.2033146 },
    { lat: 48.5431102, lng: 20.2032941 },
    { lat: 48.5429413, lng: 20.2034975 },
    { lat: 48.5427689, lng: 20.2034351 },
    { lat: 48.5426681, lng: 20.203504 },
    { lat: 48.5425917, lng: 20.2038184 },
    { lat: 48.5424559, lng: 20.2039146 },
    { lat: 48.5423114, lng: 20.2042132 },
    { lat: 48.5422537, lng: 20.2042646 },
    { lat: 48.5421401, lng: 20.204243 },
    { lat: 48.5420386, lng: 20.2044317 },
    { lat: 48.5419275, lng: 20.2043626 },
    { lat: 48.5418344, lng: 20.2044539 },
    { lat: 48.5417516, lng: 20.2044362 },
    { lat: 48.5417068, lng: 20.2045028 },
    { lat: 48.5416857, lng: 20.2047358 },
    { lat: 48.5415829, lng: 20.2048912 },
    { lat: 48.5414144, lng: 20.2049606 },
    { lat: 48.5412935, lng: 20.2048135 },
    { lat: 48.5410863, lng: 20.2049553 },
    { lat: 48.5410416, lng: 20.2049334 },
    { lat: 48.5410168, lng: 20.2047626 },
    { lat: 48.540869, lng: 20.2046737 },
    { lat: 48.5408957, lng: 20.2048326 },
    { lat: 48.540857, lng: 20.2049083 },
    { lat: 48.5406059, lng: 20.2047986 },
    { lat: 48.5403489, lng: 20.2050442 },
    { lat: 48.5403511, lng: 20.2051534 },
    { lat: 48.5400905, lng: 20.2052585 },
    { lat: 48.5399076, lng: 20.2054099 },
    { lat: 48.5398053, lng: 20.2052483 },
    { lat: 48.5396304, lng: 20.2052948 },
    { lat: 48.539467, lng: 20.2054355 },
    { lat: 48.539443, lng: 20.2053428 },
    { lat: 48.5393867, lng: 20.2053973 },
    { lat: 48.539323, lng: 20.2053572 },
    { lat: 48.5392857, lng: 20.2053805 },
    { lat: 48.5393175, lng: 20.2054441 },
    { lat: 48.5392729, lng: 20.2055377 },
    { lat: 48.5391426, lng: 20.2054611 },
    { lat: 48.5390157, lng: 20.2055576 },
    { lat: 48.5389139, lng: 20.205767 },
    { lat: 48.53847, lng: 20.2061312 },
    { lat: 48.5384327, lng: 20.2061847 },
    { lat: 48.5384801, lng: 20.2063243 },
    { lat: 48.5384337, lng: 20.2065151 },
    { lat: 48.5383232, lng: 20.2065945 },
    { lat: 48.5383196, lng: 20.2068983 },
    { lat: 48.5381005, lng: 20.2074144 },
    { lat: 48.5375206, lng: 20.2079246 },
    { lat: 48.5375359, lng: 20.2079996 },
    { lat: 48.5374736, lng: 20.2080721 },
    { lat: 48.5374957, lng: 20.2082865 },
    { lat: 48.5373882, lng: 20.2084718 },
    { lat: 48.5371736, lng: 20.2082067 },
    { lat: 48.5371197, lng: 20.2083211 },
    { lat: 48.537035, lng: 20.2083394 },
    { lat: 48.5369952, lng: 20.2084717 },
    { lat: 48.5370877, lng: 20.208647 },
    { lat: 48.5369332, lng: 20.2087478 },
    { lat: 48.5368248, lng: 20.2087179 },
    { lat: 48.5368731, lng: 20.2089683 },
    { lat: 48.5369659, lng: 20.2089755 },
    { lat: 48.5370445, lng: 20.2090593 },
    { lat: 48.5370493, lng: 20.2091477 },
    { lat: 48.5369408, lng: 20.2092805 },
    { lat: 48.5367965, lng: 20.2092471 },
    { lat: 48.5367536, lng: 20.2090189 },
    { lat: 48.5366955, lng: 20.2089777 },
    { lat: 48.5365933, lng: 20.2090943 },
    { lat: 48.5365647, lng: 20.2091929 },
    { lat: 48.5366157, lng: 20.209207 },
    { lat: 48.5366006, lng: 20.2095826 },
    { lat: 48.5366737, lng: 20.2096929 },
    { lat: 48.5367067, lng: 20.2098729 },
    { lat: 48.5366702, lng: 20.2099175 },
    { lat: 48.5364422, lng: 20.209875 },
    { lat: 48.5363665, lng: 20.2100656 },
    { lat: 48.5364229, lng: 20.210348 },
    { lat: 48.5363576, lng: 20.2105725 },
    { lat: 48.5361234, lng: 20.2106876 },
    { lat: 48.5361381, lng: 20.210914 },
    { lat: 48.5360198, lng: 20.2110527 },
    { lat: 48.5359218, lng: 20.2111114 },
    { lat: 48.5358165, lng: 20.2110378 },
    { lat: 48.5357335, lng: 20.2111595 },
    { lat: 48.5355977, lng: 20.2111963 },
    { lat: 48.535546, lng: 20.2112032 },
    { lat: 48.535482, lng: 20.2113789 },
    { lat: 48.5355794, lng: 20.2114713 },
    { lat: 48.5354816, lng: 20.2117034 },
    { lat: 48.5350965, lng: 20.2119281 },
    { lat: 48.5350309, lng: 20.2118634 },
    { lat: 48.5349373, lng: 20.2123961 },
    { lat: 48.5348465, lng: 20.2124097 },
    { lat: 48.534824, lng: 20.2124902 },
    { lat: 48.5348034, lng: 20.212877 },
    { lat: 48.5347083, lng: 20.2128938 },
    { lat: 48.5346697, lng: 20.2128028 },
    { lat: 48.5345789, lng: 20.2129806 },
    { lat: 48.5346196, lng: 20.2132675 },
    { lat: 48.5344996, lng: 20.2133339 },
    { lat: 48.5344766, lng: 20.2135503 },
    { lat: 48.5343816, lng: 20.2135344 },
    { lat: 48.5342859, lng: 20.2138189 },
    { lat: 48.5343582, lng: 20.2138798 },
    { lat: 48.5343115, lng: 20.213953 },
    { lat: 48.534188, lng: 20.2139617 },
    { lat: 48.5341531, lng: 20.2141035 },
    { lat: 48.5341832, lng: 20.2142372 },
    { lat: 48.534019, lng: 20.2143509 },
    { lat: 48.533975, lng: 20.2143251 },
    { lat: 48.5337869, lng: 20.2146606 },
    { lat: 48.5338156, lng: 20.2152351 },
    { lat: 48.5338955, lng: 20.2152307 },
    { lat: 48.5339837, lng: 20.2153545 },
    { lat: 48.533945, lng: 20.2155435 },
    { lat: 48.5339768, lng: 20.2157169 },
    { lat: 48.5338388, lng: 20.2160126 },
    { lat: 48.5338576, lng: 20.2162814 },
    { lat: 48.5338005, lng: 20.2163487 },
    { lat: 48.533827, lng: 20.2164721 },
    { lat: 48.5337659, lng: 20.2166383 },
    { lat: 48.5336722, lng: 20.2166038 },
    { lat: 48.5336474, lng: 20.2166842 },
    { lat: 48.5336801, lng: 20.2168593 },
    { lat: 48.5336437, lng: 20.2169586 },
    { lat: 48.5337051, lng: 20.217141 },
    { lat: 48.5336244, lng: 20.2172364 },
    { lat: 48.5336764, lng: 20.2173259 },
    { lat: 48.5336367, lng: 20.2174009 },
    { lat: 48.5336609, lng: 20.2176169 },
    { lat: 48.5338011, lng: 20.2176831 },
    { lat: 48.5339069, lng: 20.2176258 },
    { lat: 48.5339316, lng: 20.2176819 },
    { lat: 48.5338885, lng: 20.2180962 },
    { lat: 48.5338057, lng: 20.2182203 },
    { lat: 48.5338046, lng: 20.2184773 },
    { lat: 48.5336518, lng: 20.2185385 },
    { lat: 48.5335871, lng: 20.2186961 },
    { lat: 48.5336752, lng: 20.2189809 },
    { lat: 48.5337369, lng: 20.2190083 },
    { lat: 48.5337684, lng: 20.2188342 },
    { lat: 48.5338872, lng: 20.2189357 },
    { lat: 48.533884, lng: 20.2190796 },
    { lat: 48.5337289, lng: 20.2193982 },
    { lat: 48.5336393, lng: 20.2192702 },
    { lat: 48.5335793, lng: 20.219599 },
    { lat: 48.5335038, lng: 20.2197399 },
    { lat: 48.5334184, lng: 20.2196883 },
    { lat: 48.5333301, lng: 20.2197286 },
    { lat: 48.5333438, lng: 20.2198461 },
    { lat: 48.5332184, lng: 20.2199815 },
    { lat: 48.5331055, lng: 20.2199711 },
    { lat: 48.5330427, lng: 20.2201193 },
    { lat: 48.5330821, lng: 20.2202888 },
    { lat: 48.5329642, lng: 20.2203816 },
    { lat: 48.5330629, lng: 20.220653 },
    { lat: 48.5331483, lng: 20.2207042 },
    { lat: 48.5330871, lng: 20.2208914 },
    { lat: 48.5330997, lng: 20.2209826 },
    { lat: 48.533044, lng: 20.2210545 },
    { lat: 48.5330447, lng: 20.2212129 },
    { lat: 48.5329587, lng: 20.2213387 },
    { lat: 48.5329218, lng: 20.2212905 },
    { lat: 48.532845, lng: 20.2214293 },
    { lat: 48.5328254, lng: 20.2212748 },
    { lat: 48.5327325, lng: 20.2212608 },
    { lat: 48.5327241, lng: 20.2214456 },
    { lat: 48.5326468, lng: 20.221512 },
    { lat: 48.532627, lng: 20.221609 },
    { lat: 48.5327033, lng: 20.221627 },
    { lat: 48.5327249, lng: 20.2217202 },
    { lat: 48.532605, lng: 20.2219006 },
    { lat: 48.5325533, lng: 20.2223541 },
    { lat: 48.5324829, lng: 20.2223943 },
    { lat: 48.5325433, lng: 20.2226137 },
    { lat: 48.5325071, lng: 20.2227226 },
    { lat: 48.5324038, lng: 20.2227312 },
    { lat: 48.5321768, lng: 20.2229668 },
    { lat: 48.5322408, lng: 20.2231323 },
    { lat: 48.5321827, lng: 20.2232857 },
    { lat: 48.5322379, lng: 20.2233152 },
    { lat: 48.5322865, lng: 20.2232027 },
    { lat: 48.5324142, lng: 20.2232718 },
    { lat: 48.5324467, lng: 20.2233783 },
    { lat: 48.5325217, lng: 20.2234241 },
    { lat: 48.5324841, lng: 20.2234993 },
    { lat: 48.5325662, lng: 20.2236102 },
    { lat: 48.5325702, lng: 20.2237093 },
    { lat: 48.5324343, lng: 20.2238578 },
    { lat: 48.5323283, lng: 20.2236854 },
    { lat: 48.5320626, lng: 20.2240823 },
    { lat: 48.531989, lng: 20.2238477 },
    { lat: 48.531879, lng: 20.2239106 },
    { lat: 48.5319733, lng: 20.2241281 },
    { lat: 48.5319235, lng: 20.2242108 },
    { lat: 48.5318178, lng: 20.224182 },
    { lat: 48.5317388, lng: 20.2242947 },
    { lat: 48.5317109, lng: 20.2244706 },
    { lat: 48.5315689, lng: 20.2246324 },
    { lat: 48.5313567, lng: 20.2247369 },
    { lat: 48.5313236, lng: 20.2248369 },
    { lat: 48.5312185, lng: 20.2248571 },
    { lat: 48.5311624, lng: 20.2246643 },
    { lat: 48.531092, lng: 20.2247044 },
    { lat: 48.5310715, lng: 20.224869 },
    { lat: 48.5311097, lng: 20.2250102 },
    { lat: 48.5310394, lng: 20.2250531 },
    { lat: 48.5308817, lng: 20.2254761 },
    { lat: 48.5308067, lng: 20.2255138 },
    { lat: 48.5307087, lng: 20.2254282 },
    { lat: 48.5306443, lng: 20.2256483 },
    { lat: 48.5305449, lng: 20.2255876 },
    { lat: 48.53028, lng: 20.2256611 },
    { lat: 48.5301844, lng: 20.2255557 },
    { lat: 48.5299653, lng: 20.2255418 },
    { lat: 48.5298734, lng: 20.2256631 },
    { lat: 48.5299103, lng: 20.2257724 },
    { lat: 48.5298403, lng: 20.2258494 },
    { lat: 48.5298061, lng: 20.2257555 },
    { lat: 48.5296652, lng: 20.2256983 },
    { lat: 48.5295168, lng: 20.2258457 },
    { lat: 48.5294015, lng: 20.2258015 },
    { lat: 48.5294305, lng: 20.2256592 },
    { lat: 48.5293853, lng: 20.2255968 },
    { lat: 48.5293095, lng: 20.2256126 },
    { lat: 48.5292855, lng: 20.2257228 },
    { lat: 48.5291716, lng: 20.2256589 },
    { lat: 48.5290885, lng: 20.2259776 },
    { lat: 48.5289432, lng: 20.2260018 },
    { lat: 48.5289717, lng: 20.226125 },
    { lat: 48.5287722, lng: 20.2263183 },
    { lat: 48.5286633, lng: 20.2262969 },
    { lat: 48.5286875, lng: 20.226536 },
    { lat: 48.5286058, lng: 20.226608 },
    { lat: 48.5287436, lng: 20.2268425 },
    { lat: 48.5286339, lng: 20.2268599 },
    { lat: 48.5286167, lng: 20.2269382 },
    { lat: 48.5284939, lng: 20.2268474 },
    { lat: 48.5284751, lng: 20.226773 },
    { lat: 48.5282719, lng: 20.2269855 },
    { lat: 48.5283446, lng: 20.2270343 },
    { lat: 48.5283991, lng: 20.226986 },
    { lat: 48.528536, lng: 20.2271164 },
    { lat: 48.528373, lng: 20.2273473 },
    { lat: 48.5281386, lng: 20.2275717 },
    { lat: 48.5279537, lng: 20.2274201 },
    { lat: 48.5278666, lng: 20.2274973 },
    { lat: 48.527842, lng: 20.227615 },
    { lat: 48.5279088, lng: 20.2277098 },
    { lat: 48.5278489, lng: 20.2277874 },
    { lat: 48.5275885, lng: 20.2276127 },
    { lat: 48.5273964, lng: 20.2277957 },
    { lat: 48.5266598, lng: 20.227289 },
    { lat: 48.5265651, lng: 20.2273683 },
    { lat: 48.5264427, lng: 20.2273243 },
    { lat: 48.526467, lng: 20.2271991 },
    { lat: 48.5262652, lng: 20.2272221 },
    { lat: 48.5261144, lng: 20.2271114 },
    { lat: 48.5261335, lng: 20.2269686 },
    { lat: 48.52607, lng: 20.2269871 },
    { lat: 48.5260339, lng: 20.2269272 },
    { lat: 48.5260763, lng: 20.2268078 },
    { lat: 48.5260206, lng: 20.2267081 },
    { lat: 48.5259073, lng: 20.226718 },
    { lat: 48.5259032, lng: 20.2268409 },
    { lat: 48.525855, lng: 20.2269043 },
    { lat: 48.5258886, lng: 20.2269837 },
    { lat: 48.5258664, lng: 20.2271061 },
    { lat: 48.52576, lng: 20.2269538 },
    { lat: 48.5256281, lng: 20.2272318 },
    { lat: 48.5254212, lng: 20.2272406 },
    { lat: 48.5254146, lng: 20.227329 },
    { lat: 48.5253546, lng: 20.2273742 },
    { lat: 48.5252277, lng: 20.227298 },
    { lat: 48.5252462, lng: 20.2272 },
    { lat: 48.5251464, lng: 20.2271536 },
    { lat: 48.525115, lng: 20.2272122 },
    { lat: 48.5248577, lng: 20.2270277 },
    { lat: 48.5247607, lng: 20.227082 },
    { lat: 48.5245777, lng: 20.2269262 },
    { lat: 48.5244883, lng: 20.2269439 },
    { lat: 48.5244835, lng: 20.2267717 },
    { lat: 48.5245288, lng: 20.2267481 },
    { lat: 48.5245473, lng: 20.2266203 },
    { lat: 48.524459, lng: 20.2266084 },
    { lat: 48.5244683, lng: 20.2263919 },
    { lat: 48.5242634, lng: 20.2262772 },
    { lat: 48.5242037, lng: 20.226338 },
    { lat: 48.5241231, lng: 20.2262942 },
    { lat: 48.5240656, lng: 20.2263719 },
    { lat: 48.524059, lng: 20.2265196 },
    { lat: 48.5237696, lng: 20.2266072 },
    { lat: 48.5236399, lng: 20.2267991 },
    { lat: 48.5235821, lng: 20.226734 },
    { lat: 48.5235495, lng: 20.2265611 },
    { lat: 48.5234692, lng: 20.2266434 },
    { lat: 48.52338, lng: 20.2265779 },
    { lat: 48.5232513, lng: 20.2267423 },
    { lat: 48.5230715, lng: 20.2266947 },
    { lat: 48.5230492, lng: 20.2266452 },
    { lat: 48.5231065, lng: 20.2264957 },
    { lat: 48.5230441, lng: 20.2264577 },
    { lat: 48.5228875, lng: 20.2265973 },
    { lat: 48.5226762, lng: 20.2265539 },
    { lat: 48.5226027, lng: 20.2266288 },
    { lat: 48.5224765, lng: 20.2263752 },
    { lat: 48.522509, lng: 20.226233 },
    { lat: 48.5224756, lng: 20.2260701 },
    { lat: 48.5224014, lng: 20.2260736 },
    { lat: 48.522378, lng: 20.2261909 },
    { lat: 48.5222118, lng: 20.2262077 },
    { lat: 48.5221902, lng: 20.2260867 },
    { lat: 48.5220854, lng: 20.2260849 },
    { lat: 48.5220554, lng: 20.2259856 },
    { lat: 48.5218707, lng: 20.2260116 },
    { lat: 48.5218938, lng: 20.2259381 },
    { lat: 48.5218468, lng: 20.2258681 },
    { lat: 48.5216684, lng: 20.2258548 },
    { lat: 48.5215692, lng: 20.2260205 },
    { lat: 48.5215192, lng: 20.2260072 },
    { lat: 48.5215021, lng: 20.2259182 },
    { lat: 48.5213478, lng: 20.2257528 },
    { lat: 48.521118, lng: 20.225807 },
    { lat: 48.5210078, lng: 20.2257562 },
    { lat: 48.5202688, lng: 20.2262261 },
    { lat: 48.5201971, lng: 20.2262077 },
    { lat: 48.5201212, lng: 20.2261101 },
    { lat: 48.5201187, lng: 20.2259356 },
    { lat: 48.5197669, lng: 20.2257859 },
    { lat: 48.5195066, lng: 20.2257857 },
    { lat: 48.5193915, lng: 20.2255816 },
    { lat: 48.5195041, lng: 20.2254978 },
    { lat: 48.5190776, lng: 20.2251077 },
    { lat: 48.5189559, lng: 20.2248864 },
    { lat: 48.5188407, lng: 20.2248496 },
    { lat: 48.518725, lng: 20.2246899 },
    { lat: 48.5184979, lng: 20.2248107 },
    { lat: 48.5183193, lng: 20.2246841 },
    { lat: 48.5181827, lng: 20.2247014 },
    { lat: 48.5181348, lng: 20.2246017 },
    { lat: 48.5179357, lng: 20.2246645 },
    { lat: 48.5178065, lng: 20.2249252 },
    { lat: 48.5177899, lng: 20.225326 },
    { lat: 48.5174967, lng: 20.2256539 },
    { lat: 48.5173553, lng: 20.2255299 },
    { lat: 48.517117, lng: 20.2257071 },
    { lat: 48.5168382, lng: 20.2256063 },
    { lat: 48.5165046, lng: 20.2257431 },
    { lat: 48.5162707, lng: 20.2258904 },
    { lat: 48.5162694, lng: 20.22606 },
    { lat: 48.5160295, lng: 20.2260263 },
    { lat: 48.515919, lng: 20.2263386 },
    { lat: 48.5156335, lng: 20.2266892 },
    { lat: 48.5155107, lng: 20.2267166 },
    { lat: 48.5155313, lng: 20.226919 },
    { lat: 48.5154428, lng: 20.2270183 },
    { lat: 48.5151546, lng: 20.2268757 },
    { lat: 48.5149394, lng: 20.2269368 },
    { lat: 48.5147952, lng: 20.2267648 },
    { lat: 48.5146812, lng: 20.2269531 },
    { lat: 48.5146776, lng: 20.2273697 },
    { lat: 48.5141173, lng: 20.2274411 },
    { lat: 48.5140884, lng: 20.2275094 },
    { lat: 48.5141088, lng: 20.2277662 },
    { lat: 48.513959, lng: 20.2280444 },
    { lat: 48.5138423, lng: 20.2287025 },
    { lat: 48.513608, lng: 20.2290409 },
    { lat: 48.5136746, lng: 20.2292949 },
    { lat: 48.513652, lng: 20.2294909 },
    { lat: 48.5135007, lng: 20.2296506 },
    { lat: 48.5133663, lng: 20.2296411 },
    { lat: 48.5130954, lng: 20.2297953 },
    { lat: 48.5130133, lng: 20.2296607 },
    { lat: 48.5129215, lng: 20.2296725 },
    { lat: 48.5128978, lng: 20.2297589 },
    { lat: 48.512716, lng: 20.2299405 },
    { lat: 48.5126403, lng: 20.2301612 },
    { lat: 48.5122883, lng: 20.2307358 },
    { lat: 48.5121854, lng: 20.2311206 },
    { lat: 48.5120789, lng: 20.2311668 },
    { lat: 48.5122007, lng: 20.231451 },
    { lat: 48.511988, lng: 20.2315679 },
    { lat: 48.5119491, lng: 20.2317504 },
    { lat: 48.5118519, lng: 20.2318218 },
    { lat: 48.5117842, lng: 20.2322719 },
    { lat: 48.5118359, lng: 20.2323851 },
    { lat: 48.5118956, lng: 20.2323542 },
    { lat: 48.5119603, lng: 20.2325916 },
    { lat: 48.5119296, lng: 20.2327812 },
    { lat: 48.5118218, lng: 20.2327054 },
    { lat: 48.5118041, lng: 20.2335851 },
    { lat: 48.5117457, lng: 20.2337601 },
    { lat: 48.5117774, lng: 20.2338802 },
    { lat: 48.5117797, lng: 20.2339627 },
    { lat: 48.5117881, lng: 20.2339762 },
    { lat: 48.5119102, lng: 20.234044 },
    { lat: 48.5120682, lng: 20.2347855 },
    { lat: 48.5126574, lng: 20.2343706 },
    { lat: 48.5136618, lng: 20.2342507 },
    { lat: 48.5137163, lng: 20.2343733 },
    { lat: 48.5141055, lng: 20.2343934 },
    { lat: 48.5145895, lng: 20.2345802 },
    { lat: 48.5148155, lng: 20.2365469 },
    { lat: 48.5149717, lng: 20.2370422 },
    { lat: 48.5154493, lng: 20.2377215 },
    { lat: 48.5160192, lng: 20.2379201 },
    { lat: 48.5165368, lng: 20.2383365 },
    { lat: 48.5170951, lng: 20.2400505 },
    { lat: 48.5171856, lng: 20.241443 },
    { lat: 48.518379, lng: 20.2428752 },
    { lat: 48.5197504, lng: 20.2443603 },
    { lat: 48.520029, lng: 20.2451057 },
    { lat: 48.5202185, lng: 20.2464723 },
    { lat: 48.5206723, lng: 20.2477704 },
    { lat: 48.5204864, lng: 20.249297 },
    { lat: 48.5202043, lng: 20.2496503 },
    { lat: 48.5199886, lng: 20.2501195 },
    { lat: 48.519024, lng: 20.2517191 },
    { lat: 48.5188021, lng: 20.2523395 },
    { lat: 48.5184727, lng: 20.2525799 },
    { lat: 48.5190278, lng: 20.2537672 },
    { lat: 48.5189555, lng: 20.254044 },
    { lat: 48.5194855, lng: 20.2553071 },
    { lat: 48.5197592, lng: 20.2555674 },
    { lat: 48.52074, lng: 20.2598153 },
    { lat: 48.5206294, lng: 20.2599248 },
    { lat: 48.5207061, lng: 20.260012 },
    { lat: 48.5206788, lng: 20.2601992 },
    { lat: 48.5207686, lng: 20.2601972 },
    { lat: 48.5208248, lng: 20.2602742 },
    { lat: 48.5207568, lng: 20.2602672 },
    { lat: 48.5207266, lng: 20.2603558 },
    { lat: 48.5207792, lng: 20.2606602 },
    { lat: 48.5208942, lng: 20.2608328 },
    { lat: 48.5210201, lng: 20.2609128 },
    { lat: 48.5211087, lng: 20.2607904 },
    { lat: 48.5211113, lng: 20.2611369 },
    { lat: 48.5213144, lng: 20.2614018 },
    { lat: 48.5212561, lng: 20.2614958 },
    { lat: 48.5213901, lng: 20.2617509 },
    { lat: 48.521429, lng: 20.2619343 },
    { lat: 48.5215253, lng: 20.2619214 },
    { lat: 48.5214993, lng: 20.2620342 },
    { lat: 48.5215629, lng: 20.2621859 },
    { lat: 48.5216342, lng: 20.2621983 },
    { lat: 48.521678, lng: 20.262107 },
    { lat: 48.5219078, lng: 20.2622778 },
    { lat: 48.5219266, lng: 20.2623827 },
    { lat: 48.5217886, lng: 20.2625692 },
    { lat: 48.521794, lng: 20.2626451 },
    { lat: 48.5219211, lng: 20.2626127 },
    { lat: 48.5220537, lng: 20.2627791 },
    { lat: 48.5221374, lng: 20.2627296 },
    { lat: 48.5222357, lng: 20.2627986 },
    { lat: 48.5223022, lng: 20.2627955 },
    { lat: 48.5223403, lng: 20.2626513 },
    { lat: 48.5223377, lng: 20.2628122 },
    { lat: 48.5225177, lng: 20.2629232 },
    { lat: 48.5225823, lng: 20.2632133 },
    { lat: 48.5227372, lng: 20.2630899 },
    { lat: 48.5227696, lng: 20.2634238 },
    { lat: 48.5228411, lng: 20.2634945 },
    { lat: 48.5230176, lng: 20.2631152 },
    { lat: 48.5231431, lng: 20.2631021 },
    { lat: 48.5233796, lng: 20.2632979 },
    { lat: 48.5235528, lng: 20.2632369 },
    { lat: 48.5235015, lng: 20.2635861 },
    { lat: 48.5235925, lng: 20.2637502 },
    { lat: 48.5236903, lng: 20.2635805 },
    { lat: 48.5237561, lng: 20.2636201 },
    { lat: 48.523771, lng: 20.2634855 },
    { lat: 48.5238958, lng: 20.2635077 },
    { lat: 48.5239957, lng: 20.263385 },
    { lat: 48.5241715, lng: 20.2635262 },
    { lat: 48.5241507, lng: 20.2636876 },
    { lat: 48.52445, lng: 20.2636813 },
    { lat: 48.5245512, lng: 20.26348 },
    { lat: 48.5246821, lng: 20.2634821 },
    { lat: 48.5248322, lng: 20.2636015 },
    { lat: 48.5248872, lng: 20.2635418 },
    { lat: 48.5249739, lng: 20.263658 },
    { lat: 48.5249516, lng: 20.2637737 },
    { lat: 48.5251122, lng: 20.2639611 },
    { lat: 48.5253858, lng: 20.2638516 },
    { lat: 48.5254638, lng: 20.263997 },
    { lat: 48.5254194, lng: 20.2642088 },
    { lat: 48.5257236, lng: 20.2640742 },
    { lat: 48.5258465, lng: 20.2641605 },
    { lat: 48.5259712, lng: 20.2641517 },
    { lat: 48.5260518, lng: 20.2639992 },
    { lat: 48.5262746, lng: 20.2643092 },
    { lat: 48.5263899, lng: 20.264372 },
    { lat: 48.5265558, lng: 20.2643259 },
  ],
  MokráLúka: [
    { lat: 48.699411, lng: 20.1400049 },
    { lat: 48.6994101, lng: 20.1400357 },
    { lat: 48.6992729, lng: 20.1399304 },
    { lat: 48.6989215, lng: 20.1400161 },
    { lat: 48.6986827, lng: 20.1398496 },
    { lat: 48.6985109, lng: 20.1398623 },
    { lat: 48.6981799, lng: 20.1395043 },
    { lat: 48.6974578, lng: 20.1391634 },
    { lat: 48.6971551, lng: 20.1391194 },
    { lat: 48.6970074, lng: 20.1390055 },
    { lat: 48.6965603, lng: 20.138392 },
    { lat: 48.6964938, lng: 20.1381168 },
    { lat: 48.6963302, lng: 20.1377498 },
    { lat: 48.6961764, lng: 20.1376232 },
    { lat: 48.695633, lng: 20.1367213 },
    { lat: 48.6949115, lng: 20.1360698 },
    { lat: 48.6947267, lng: 20.1358142 },
    { lat: 48.6945027, lng: 20.1352689 },
    { lat: 48.6940517, lng: 20.1344804 },
    { lat: 48.6938897, lng: 20.1342918 },
    { lat: 48.6936828, lng: 20.1341018 },
    { lat: 48.6928232, lng: 20.1336243 },
    { lat: 48.6924555, lng: 20.1332541 },
    { lat: 48.6914318, lng: 20.1319299 },
    { lat: 48.6897321, lng: 20.1306485 },
    { lat: 48.6896311, lng: 20.1306608 },
    { lat: 48.6888488, lng: 20.1312545 },
    { lat: 48.688442, lng: 20.1314417 },
    { lat: 48.6881555, lng: 20.131711 },
    { lat: 48.6879114, lng: 20.1320506 },
    { lat: 48.687479, lng: 20.1324386 },
    { lat: 48.6872752, lng: 20.1327445 },
    { lat: 48.6867869, lng: 20.1331434 },
    { lat: 48.6849532, lng: 20.1339198 },
    { lat: 48.6819334, lng: 20.1342444 },
    { lat: 48.6816406, lng: 20.1340784 },
    { lat: 48.681075, lng: 20.13352 },
    { lat: 48.6806243, lng: 20.1332143 },
    { lat: 48.6799856, lng: 20.1331035 },
    { lat: 48.6794687, lng: 20.1332138 },
    { lat: 48.6792439, lng: 20.1333619 },
    { lat: 48.6786832, lng: 20.1335365 },
    { lat: 48.6782496, lng: 20.1334787 },
    { lat: 48.6774009, lng: 20.1336059 },
    { lat: 48.6768627, lng: 20.133081 },
    { lat: 48.6761428, lng: 20.1330501 },
    { lat: 48.6730713, lng: 20.1320777 },
    { lat: 48.6710449, lng: 20.1310217 },
    { lat: 48.6706185, lng: 20.1308963 },
    { lat: 48.6705799, lng: 20.1301398 },
    { lat: 48.6705059, lng: 20.1299282 },
    { lat: 48.6702168, lng: 20.1295755 },
    { lat: 48.6697798, lng: 20.1298794 },
    { lat: 48.6695885, lng: 20.129802 },
    { lat: 48.6697463, lng: 20.1295843 },
    { lat: 48.6700059, lng: 20.1293763 },
    { lat: 48.6701943, lng: 20.1293581 },
    { lat: 48.6703237, lng: 20.1292442 },
    { lat: 48.6708979, lng: 20.127582 },
    { lat: 48.6708978, lng: 20.1272431 },
    { lat: 48.6705822, lng: 20.1270454 },
    { lat: 48.6705945, lng: 20.1268198 },
    { lat: 48.6711246, lng: 20.1268938 },
    { lat: 48.671271, lng: 20.1266992 },
    { lat: 48.671368, lng: 20.1260673 },
    { lat: 48.6713615, lng: 20.1257704 },
    { lat: 48.6711243, lng: 20.1246521 },
    { lat: 48.671199, lng: 20.1239381 },
    { lat: 48.6709179, lng: 20.1229832 },
    { lat: 48.6707444, lng: 20.1228183 },
    { lat: 48.6706004, lng: 20.1223268 },
    { lat: 48.6700187, lng: 20.1214269 },
    { lat: 48.6698263, lng: 20.1209096 },
    { lat: 48.6695407, lng: 20.1203952 },
    { lat: 48.6694435, lng: 20.1197828 },
    { lat: 48.6692235, lng: 20.1194127 },
    { lat: 48.6690096, lng: 20.1192762 },
    { lat: 48.6688605, lng: 20.1193474 },
    { lat: 48.6686244, lng: 20.119167 },
    { lat: 48.6684552, lng: 20.1188556 },
    { lat: 48.6681447, lng: 20.1186215 },
    { lat: 48.6680155, lng: 20.1184044 },
    { lat: 48.6677891, lng: 20.1183506 },
    { lat: 48.667654, lng: 20.1181287 },
    { lat: 48.6674414, lng: 20.1180256 },
    { lat: 48.6673208, lng: 20.117777 },
    { lat: 48.6670741, lng: 20.1176653 },
    { lat: 48.6667388, lng: 20.117673 },
    { lat: 48.6662735, lng: 20.1174754 },
    { lat: 48.6657357, lng: 20.1163624 },
    { lat: 48.6654489, lng: 20.1161247 },
    { lat: 48.6650368, lng: 20.115546 },
    { lat: 48.6646514, lng: 20.1152405 },
    { lat: 48.6645788, lng: 20.1150353 },
    { lat: 48.6645874, lng: 20.1146913 },
    { lat: 48.6648223, lng: 20.1139073 },
    { lat: 48.6643147, lng: 20.1129548 },
    { lat: 48.6632931, lng: 20.1128141 },
    { lat: 48.6624498, lng: 20.1125492 },
    { lat: 48.6614605, lng: 20.1127012 },
    { lat: 48.6606629, lng: 20.1117355 },
    { lat: 48.6605639, lng: 20.1115405 },
    { lat: 48.66056, lng: 20.1110874 },
    { lat: 48.6609143, lng: 20.1103111 },
    { lat: 48.6605222, lng: 20.109617 },
    { lat: 48.6597183, lng: 20.1086288 },
    { lat: 48.6596846, lng: 20.1083889 },
    { lat: 48.6597086, lng: 20.107568 },
    { lat: 48.6596557, lng: 20.1072672 },
    { lat: 48.6595473, lng: 20.1070985 },
    { lat: 48.6590346, lng: 20.106676 },
    { lat: 48.6586853, lng: 20.1062319 },
    { lat: 48.6585281, lng: 20.1057246 },
    { lat: 48.6584589, lng: 20.105078 },
    { lat: 48.6583414, lng: 20.104876 },
    { lat: 48.658186, lng: 20.1047932 },
    { lat: 48.6579891, lng: 20.1048269 },
    { lat: 48.6579004, lng: 20.1049132 },
    { lat: 48.6577958, lng: 20.1056618 },
    { lat: 48.6577807, lng: 20.1070573 },
    { lat: 48.6578926, lng: 20.1076172 },
    { lat: 48.6579211, lng: 20.1084233 },
    { lat: 48.6578727, lng: 20.1092479 },
    { lat: 48.6577945, lng: 20.1097892 },
    { lat: 48.6578285, lng: 20.1107291 },
    { lat: 48.6576247, lng: 20.1119675 },
    { lat: 48.657629, lng: 20.1122917 },
    { lat: 48.6576533, lng: 20.1124483 },
    { lat: 48.657823, lng: 20.1129095 },
    { lat: 48.6579636, lng: 20.1133699 },
    { lat: 48.6581103, lng: 20.1141247 },
    { lat: 48.6582793, lng: 20.1146202 },
    { lat: 48.6584801, lng: 20.1149613 },
    { lat: 48.6585333, lng: 20.1151823 },
    { lat: 48.658648, lng: 20.1157591 },
    { lat: 48.6587161, lng: 20.1165732 },
    { lat: 48.6589744, lng: 20.1178517 },
    { lat: 48.659031, lng: 20.1184115 },
    { lat: 48.6588773, lng: 20.1198836 },
    { lat: 48.6589073, lng: 20.1203472 },
    { lat: 48.6591049, lng: 20.1209904 },
    { lat: 48.6591012, lng: 20.1218956 },
    { lat: 48.6592576, lng: 20.1228024 },
    { lat: 48.6592823, lng: 20.1232162 },
    { lat: 48.6592106, lng: 20.1241381 },
    { lat: 48.659268, lng: 20.124798 },
    { lat: 48.6593607, lng: 20.1250295 },
    { lat: 48.6593472, lng: 20.1274598 },
    { lat: 48.6597361, lng: 20.1284308 },
    { lat: 48.660045, lng: 20.1289113 },
    { lat: 48.6600727, lng: 20.1290626 },
    { lat: 48.6598762, lng: 20.1299345 },
    { lat: 48.6597342, lng: 20.130326 },
    { lat: 48.6596713, lng: 20.1309364 },
    { lat: 48.659708, lng: 20.1316188 },
    { lat: 48.6593951, lng: 20.1329764 },
    { lat: 48.6594908, lng: 20.1337203 },
    { lat: 48.6594539, lng: 20.1341437 },
    { lat: 48.6595369, lng: 20.1348838 },
    { lat: 48.6596251, lng: 20.1354735 },
    { lat: 48.6597292, lng: 20.1358454 },
    { lat: 48.6595392, lng: 20.13649 },
    { lat: 48.6599867, lng: 20.1366406 },
    { lat: 48.6604809, lng: 20.1369935 },
    { lat: 48.66068, lng: 20.1372344 },
    { lat: 48.6608551, lng: 20.1373044 },
    { lat: 48.6609785, lng: 20.1377907 },
    { lat: 48.6612933, lng: 20.1384388 },
    { lat: 48.6613853, lng: 20.1387634 },
    { lat: 48.6612814, lng: 20.1397546 },
    { lat: 48.6613064, lng: 20.1399817 },
    { lat: 48.6614929, lng: 20.1406346 },
    { lat: 48.6614878, lng: 20.140898 },
    { lat: 48.6615626, lng: 20.1412691 },
    { lat: 48.6616473, lng: 20.141412 },
    { lat: 48.6618153, lng: 20.1421667 },
    { lat: 48.6618094, lng: 20.1428014 },
    { lat: 48.6617618, lng: 20.1431218 },
    { lat: 48.661154, lng: 20.1441023 },
    { lat: 48.6609475, lng: 20.1445688 },
    { lat: 48.6603845, lng: 20.1455141 },
    { lat: 48.6602307, lng: 20.1456662 },
    { lat: 48.6597791, lng: 20.1463628 },
    { lat: 48.6595928, lng: 20.1468189 },
    { lat: 48.6594181, lng: 20.1475105 },
    { lat: 48.6592891, lng: 20.1486347 },
    { lat: 48.6589209, lng: 20.1497742 },
    { lat: 48.6588735, lng: 20.1502193 },
    { lat: 48.6589017, lng: 20.1504949 },
    { lat: 48.6587973, lng: 20.1511729 },
    { lat: 48.6586325, lng: 20.151632 },
    { lat: 48.6583134, lng: 20.1522956 },
    { lat: 48.6580536, lng: 20.1527165 },
    { lat: 48.657934, lng: 20.1533513 },
    { lat: 48.6578425, lng: 20.1534 },
    { lat: 48.6582669, lng: 20.1537865 },
    { lat: 48.6585488, lng: 20.154208 },
    { lat: 48.6593578, lng: 20.1551326 },
    { lat: 48.6597289, lng: 20.1554268 },
    { lat: 48.6600847, lng: 20.155897 },
    { lat: 48.6601828, lng: 20.1561213 },
    { lat: 48.6602704, lng: 20.1565358 },
    { lat: 48.6604968, lng: 20.1570098 },
    { lat: 48.6606782, lng: 20.1571765 },
    { lat: 48.6608306, lng: 20.15774 },
    { lat: 48.6608614, lng: 20.1580278 },
    { lat: 48.660755, lng: 20.1586836 },
    { lat: 48.6607786, lng: 20.1605714 },
    { lat: 48.6607113, lng: 20.1609453 },
    { lat: 48.6607437, lng: 20.1614061 },
    { lat: 48.6607181, lng: 20.1615856 },
    { lat: 48.660775, lng: 20.162129 },
    { lat: 48.6608583, lng: 20.1623785 },
    { lat: 48.6608485, lng: 20.1633613 },
    { lat: 48.6609082, lng: 20.1637773 },
    { lat: 48.661086, lng: 20.1643049 },
    { lat: 48.66116, lng: 20.1652707 },
    { lat: 48.6610489, lng: 20.1661427 },
    { lat: 48.6606274, lng: 20.1668902 },
    { lat: 48.6602939, lng: 20.1680869 },
    { lat: 48.6599008, lng: 20.1689486 },
    { lat: 48.6597566, lng: 20.1691713 },
    { lat: 48.6595781, lng: 20.1697478 },
    { lat: 48.658973, lng: 20.1704611 },
    { lat: 48.6587584, lng: 20.1713134 },
    { lat: 48.6586874, lng: 20.1718414 },
    { lat: 48.6586832, lng: 20.1725483 },
    { lat: 48.6587483, lng: 20.1732381 },
    { lat: 48.6587944, lng: 20.1733507 },
    { lat: 48.6587539, lng: 20.1744947 },
    { lat: 48.6585983, lng: 20.1762176 },
    { lat: 48.6586453, lng: 20.1766347 },
    { lat: 48.6593495, lng: 20.1755223 },
    { lat: 48.6596764, lng: 20.1740776 },
    { lat: 48.6596573, lng: 20.1731591 },
    { lat: 48.6594296, lng: 20.1724818 },
    { lat: 48.6594729, lng: 20.1722167 },
    { lat: 48.6596701, lng: 20.1717165 },
    { lat: 48.6598836, lng: 20.1714272 },
    { lat: 48.6601668, lng: 20.1712993 },
    { lat: 48.6602681, lng: 20.1716854 },
    { lat: 48.6608732, lng: 20.1711346 },
    { lat: 48.6613622, lng: 20.1704985 },
    { lat: 48.6616253, lng: 20.1700122 },
    { lat: 48.6619678, lng: 20.1698778 },
    { lat: 48.6624801, lng: 20.1697882 },
    { lat: 48.6626394, lng: 20.1695773 },
    { lat: 48.6629911, lng: 20.1687796 },
    { lat: 48.6631571, lng: 20.1678686 },
    { lat: 48.6633555, lng: 20.1672593 },
    { lat: 48.6634178, lng: 20.1662656 },
    { lat: 48.663379, lng: 20.1656692 },
    { lat: 48.6634463, lng: 20.1651328 },
    { lat: 48.6635386, lng: 20.1649038 },
    { lat: 48.6635504, lng: 20.1645315 },
    { lat: 48.6637317, lng: 20.1638018 },
    { lat: 48.6660377, lng: 20.1650969 },
    { lat: 48.6682648, lng: 20.1661403 },
    { lat: 48.6684099, lng: 20.1661088 },
    { lat: 48.6688984, lng: 20.1663531 },
    { lat: 48.6700258, lng: 20.1666046 },
    { lat: 48.6714474, lng: 20.1671372 },
    { lat: 48.6721202, lng: 20.1672288 },
    { lat: 48.6725953, lng: 20.1671437 },
    { lat: 48.6731491, lng: 20.1669409 },
    { lat: 48.6742417, lng: 20.1663589 },
    { lat: 48.6748061, lng: 20.1659716 },
    { lat: 48.6752037, lng: 20.1655248 },
    { lat: 48.6758994, lng: 20.1650961 },
    { lat: 48.6765207, lng: 20.1646152 },
    { lat: 48.6777403, lng: 20.1642209 },
    { lat: 48.6785661, lng: 20.1637827 },
    { lat: 48.6790688, lng: 20.1632645 },
    { lat: 48.6792527, lng: 20.1629375 },
    { lat: 48.6797109, lng: 20.1625378 },
    { lat: 48.6801652, lng: 20.1622735 },
    { lat: 48.6809725, lng: 20.1621257 },
    { lat: 48.6814104, lng: 20.1619534 },
    { lat: 48.6823696, lng: 20.1613603 },
    { lat: 48.6825775, lng: 20.16157 },
    { lat: 48.6827997, lng: 20.1619394 },
    { lat: 48.6829771, lng: 20.1620382 },
    { lat: 48.6830945, lng: 20.1620448 },
    { lat: 48.6831793, lng: 20.1619718 },
    { lat: 48.6833901, lng: 20.1620005 },
    { lat: 48.6837403, lng: 20.1623342 },
    { lat: 48.6839393, lng: 20.1624403 },
    { lat: 48.6841895, lng: 20.1623876 },
    { lat: 48.6844832, lng: 20.1624882 },
    { lat: 48.6846678, lng: 20.1624622 },
    { lat: 48.684872, lng: 20.1623276 },
    { lat: 48.6849893, lng: 20.1623927 },
    { lat: 48.6849959, lng: 20.1624687 },
    { lat: 48.6853298, lng: 20.1623157 },
    { lat: 48.6856619, lng: 20.1624782 },
    { lat: 48.6863043, lng: 20.1621813 },
    { lat: 48.6866841, lng: 20.1621056 },
    { lat: 48.6866933, lng: 20.1622456 },
    { lat: 48.6869368, lng: 20.1622563 },
    { lat: 48.6872152, lng: 20.1623721 },
    { lat: 48.6873561, lng: 20.1625694 },
    { lat: 48.6875107, lng: 20.1626332 },
    { lat: 48.6881663, lng: 20.1633338 },
    { lat: 48.68844, lng: 20.1635842 },
    { lat: 48.6885099, lng: 20.163556 },
    { lat: 48.6888156, lng: 20.1638725 },
    { lat: 48.6896874, lng: 20.1644915 },
    { lat: 48.6902154, lng: 20.1646496 },
    { lat: 48.6909894, lng: 20.1657461 },
    { lat: 48.691699, lng: 20.1672208 },
    { lat: 48.6918145, lng: 20.1673527 },
    { lat: 48.6941665, lng: 20.1686427 },
    { lat: 48.6949647, lng: 20.1688312 },
    { lat: 48.696527, lng: 20.168754 },
    { lat: 48.6974908, lng: 20.1698614 },
    { lat: 48.6982123, lng: 20.1707983 },
    { lat: 48.6988106, lng: 20.1715008 },
    { lat: 48.6991785, lng: 20.171324 },
    { lat: 48.6995103, lng: 20.1710913 },
    { lat: 48.6998883, lng: 20.1710237 },
    { lat: 48.7002044, lng: 20.1708499 },
    { lat: 48.7014709, lng: 20.1706641 },
    { lat: 48.7016364, lng: 20.1707477 },
    { lat: 48.7020494, lng: 20.1712983 },
    { lat: 48.7021655, lng: 20.1715543 },
    { lat: 48.7025847, lng: 20.1720895 },
    { lat: 48.7029662, lng: 20.1727828 },
    { lat: 48.7037519, lng: 20.1736076 },
    { lat: 48.7054439, lng: 20.1746522 },
    { lat: 48.7061358, lng: 20.1749958 },
    { lat: 48.7064524, lng: 20.1752751 },
    { lat: 48.7081652, lng: 20.1764195 },
    { lat: 48.7084331, lng: 20.1764969 },
    { lat: 48.7088487, lng: 20.1768591 },
    { lat: 48.7091832, lng: 20.177364 },
    { lat: 48.7096707, lng: 20.1776665 },
    { lat: 48.7102706, lng: 20.1776306 },
    { lat: 48.710991, lng: 20.1778165 },
    { lat: 48.7116297, lng: 20.1776533 },
    { lat: 48.7119977, lng: 20.1774508 },
    { lat: 48.7126357, lng: 20.1773226 },
    { lat: 48.7128801, lng: 20.1774089 },
    { lat: 48.7133396, lng: 20.1774327 },
    { lat: 48.7138621, lng: 20.1776824 },
    { lat: 48.71433, lng: 20.1777514 },
    { lat: 48.7145609, lng: 20.1776132 },
    { lat: 48.7151943, lng: 20.1775635 },
    { lat: 48.7156759, lng: 20.1776098 },
    { lat: 48.7158206, lng: 20.177569 },
    { lat: 48.7162755, lng: 20.1777073 },
    { lat: 48.7170325, lng: 20.1775411 },
    { lat: 48.7176863, lng: 20.1775598 },
    { lat: 48.7182215, lng: 20.177283 },
    { lat: 48.7189625, lng: 20.1773105 },
    { lat: 48.7204558, lng: 20.1763581 },
    { lat: 48.720173, lng: 20.1760749 },
    { lat: 48.7195146, lng: 20.1753012 },
    { lat: 48.7191174, lng: 20.1746932 },
    { lat: 48.7188186, lng: 20.1744058 },
    { lat: 48.7187065, lng: 20.1741429 },
    { lat: 48.7183557, lng: 20.1737643 },
    { lat: 48.7180623, lng: 20.1730797 },
    { lat: 48.7177966, lng: 20.1718266 },
    { lat: 48.7176555, lng: 20.1713709 },
    { lat: 48.7171825, lng: 20.1705627 },
    { lat: 48.7170082, lng: 20.1699796 },
    { lat: 48.7166003, lng: 20.1690235 },
    { lat: 48.7161814, lng: 20.1685282 },
    { lat: 48.7157713, lng: 20.1676001 },
    { lat: 48.7154737, lng: 20.16715 },
    { lat: 48.7149194, lng: 20.1665834 },
    { lat: 48.7147155, lng: 20.1665019 },
    { lat: 48.7144812, lng: 20.1664971 },
    { lat: 48.7138469, lng: 20.1661601 },
    { lat: 48.713312, lng: 20.1659943 },
    { lat: 48.7131603, lng: 20.1658913 },
    { lat: 48.712829, lng: 20.1655527 },
    { lat: 48.712359, lng: 20.1647897 },
    { lat: 48.7120057, lng: 20.1633483 },
    { lat: 48.7119906, lng: 20.1621971 },
    { lat: 48.7118839, lng: 20.1617576 },
    { lat: 48.7114504, lng: 20.1610059 },
    { lat: 48.7106327, lng: 20.1602261 },
    { lat: 48.7100372, lng: 20.1594544 },
    { lat: 48.7097232, lng: 20.157537 },
    { lat: 48.7094005, lng: 20.1566592 },
    { lat: 48.7093381, lng: 20.1563419 },
    { lat: 48.7093487, lng: 20.155269 },
    { lat: 48.7089608, lng: 20.1542574 },
    { lat: 48.7088969, lng: 20.1542052 },
    { lat: 48.708747, lng: 20.1535336 },
    { lat: 48.7086058, lng: 20.153276 },
    { lat: 48.7083782, lng: 20.1529936 },
    { lat: 48.7075922, lng: 20.1525852 },
    { lat: 48.7073291, lng: 20.152341 },
    { lat: 48.7069194, lng: 20.1514039 },
    { lat: 48.7066277, lng: 20.1510422 },
    { lat: 48.7064845, lng: 20.1506774 },
    { lat: 48.706173, lng: 20.1502994 },
    { lat: 48.705904, lng: 20.1498582 },
    { lat: 48.7056571, lng: 20.1496592 },
    { lat: 48.7055197, lng: 20.1494366 },
    { lat: 48.7053944, lng: 20.1490709 },
    { lat: 48.7045506, lng: 20.1477864 },
    { lat: 48.704396, lng: 20.1474145 },
    { lat: 48.7040917, lng: 20.1471039 },
    { lat: 48.7035154, lng: 20.1460287 },
    { lat: 48.7032515, lng: 20.1456595 },
    { lat: 48.7029858, lng: 20.1454705 },
    { lat: 48.7025375, lng: 20.1449422 },
    { lat: 48.701654, lng: 20.1431538 },
    { lat: 48.7012528, lng: 20.1426197 },
    { lat: 48.7004779, lng: 20.1418407 },
    { lat: 48.6999461, lng: 20.1409195 },
    { lat: 48.6997214, lng: 20.1403777 },
    { lat: 48.699411, lng: 20.1400049 },
  ],
  Tornaľa: [
    { lat: 48.4250216, lng: 20.4270984 },
    { lat: 48.4250663, lng: 20.4271441 },
    { lat: 48.4252702, lng: 20.4273587 },
    { lat: 48.4259212, lng: 20.4278573 },
    { lat: 48.4260368, lng: 20.4279015 },
    { lat: 48.4262, lng: 20.427967 },
    { lat: 48.4263807, lng: 20.4280378 },
    { lat: 48.4265602, lng: 20.4281078 },
    { lat: 48.4267443, lng: 20.4282127 },
    { lat: 48.4271036, lng: 20.4284026 },
    { lat: 48.4273525, lng: 20.4285322 },
    { lat: 48.4278897, lng: 20.4288171 },
    { lat: 48.4284554, lng: 20.4291169 },
    { lat: 48.4286394, lng: 20.429313 },
    { lat: 48.428754, lng: 20.4296836 },
    { lat: 48.428801, lng: 20.4299803 },
    { lat: 48.4288329, lng: 20.4303653 },
    { lat: 48.4287457, lng: 20.4312385 },
    { lat: 48.4287771, lng: 20.4317867 },
    { lat: 48.4287879, lng: 20.4320094 },
    { lat: 48.4293325, lng: 20.4329557 },
    { lat: 48.4294615, lng: 20.4332842 },
    { lat: 48.4299417, lng: 20.43387 },
    { lat: 48.4301707, lng: 20.4345151 },
    { lat: 48.4304276, lng: 20.434772 },
    { lat: 48.4306774, lng: 20.4348795 },
    { lat: 48.4314196, lng: 20.4351939 },
    { lat: 48.4315003, lng: 20.4352379 },
    { lat: 48.4318266, lng: 20.4352327 },
    { lat: 48.4320086, lng: 20.4352288 },
    { lat: 48.4321627, lng: 20.4352292 },
    { lat: 48.4322947, lng: 20.4353258 },
    { lat: 48.4324703, lng: 20.4354358 },
    { lat: 48.4326946, lng: 20.4355767 },
    { lat: 48.432941, lng: 20.4357383 },
    { lat: 48.4332548, lng: 20.4361395 },
    { lat: 48.433642, lng: 20.436648 },
    { lat: 48.4337508, lng: 20.4367729 },
    { lat: 48.4339164, lng: 20.4370075 },
    { lat: 48.4340638, lng: 20.4372118 },
    { lat: 48.4343195, lng: 20.4375684 },
    { lat: 48.4345767, lng: 20.4379251 },
    { lat: 48.434926, lng: 20.438408 },
    { lat: 48.435197, lng: 20.438779 },
    { lat: 48.435509, lng: 20.439224 },
    { lat: 48.4356691, lng: 20.4394479 },
    { lat: 48.4358751, lng: 20.4397238 },
    { lat: 48.4360644, lng: 20.4399819 },
    { lat: 48.436289, lng: 20.4403029 },
    { lat: 48.4368129, lng: 20.4410014 },
    { lat: 48.4377097, lng: 20.4418116 },
    { lat: 48.4381407, lng: 20.4421902 },
    { lat: 48.4387003, lng: 20.4430703 },
    { lat: 48.4393697, lng: 20.4433324 },
    { lat: 48.4397478, lng: 20.4434746 },
    { lat: 48.4404505, lng: 20.4437501 },
    { lat: 48.4408252, lng: 20.4438931 },
    { lat: 48.4413352, lng: 20.4440888 },
    { lat: 48.4425843, lng: 20.4445764 },
    { lat: 48.442824, lng: 20.4446739 },
    { lat: 48.4430181, lng: 20.4447476 },
    { lat: 48.4431418, lng: 20.4448109 },
    { lat: 48.443268, lng: 20.4448873 },
    { lat: 48.4433955, lng: 20.4449583 },
    { lat: 48.443451, lng: 20.4451163 },
    { lat: 48.4436339, lng: 20.4451787 },
    { lat: 48.443843, lng: 20.445266 },
    { lat: 48.4439542, lng: 20.4453082 },
    { lat: 48.4443398, lng: 20.44547 },
    { lat: 48.4444874, lng: 20.4455368 },
    { lat: 48.4445687, lng: 20.4456148 },
    { lat: 48.4446649, lng: 20.4457087 },
    { lat: 48.4447172, lng: 20.4457821 },
    { lat: 48.444766, lng: 20.445854 },
    { lat: 48.4448754, lng: 20.4460135 },
    { lat: 48.444945, lng: 20.4461143 },
    { lat: 48.4450173, lng: 20.4462126 },
    { lat: 48.4450395, lng: 20.4462489 },
    { lat: 48.4450811, lng: 20.4463446 },
    { lat: 48.4450989, lng: 20.4463939 },
    { lat: 48.445152, lng: 20.44649 },
    { lat: 48.445198, lng: 20.446615 },
    { lat: 48.445242, lng: 20.44674 },
    { lat: 48.445275, lng: 20.44687 },
    { lat: 48.445404, lng: 20.4473077 },
    { lat: 48.4456013, lng: 20.4480558 },
    { lat: 48.4456823, lng: 20.4483706 },
    { lat: 48.4457219, lng: 20.4485206 },
    { lat: 48.4457691, lng: 20.4486954 },
    { lat: 48.4458246, lng: 20.4488253 },
    { lat: 48.4459762, lng: 20.4491764 },
    { lat: 48.4460478, lng: 20.4493288 },
    { lat: 48.4461043, lng: 20.449435 },
    { lat: 48.4461514, lng: 20.4495311 },
    { lat: 48.4462452, lng: 20.4497128 },
    { lat: 48.4463478, lng: 20.4499142 },
    { lat: 48.446434, lng: 20.450044 },
    { lat: 48.446513, lng: 20.450136 },
    { lat: 48.4466136, lng: 20.4502926 },
    { lat: 48.4467062, lng: 20.4503746 },
    { lat: 48.4468617, lng: 20.4505088 },
    { lat: 48.4470856, lng: 20.4506619 },
    { lat: 48.44718, lng: 20.450714 },
    { lat: 48.4473118, lng: 20.4508079 },
    { lat: 48.4474382, lng: 20.4508859 },
    { lat: 48.447605, lng: 20.450987 },
    { lat: 48.447765, lng: 20.451073 },
    { lat: 48.448087, lng: 20.4512609 },
    { lat: 48.4481845, lng: 20.4513386 },
    { lat: 48.4483277, lng: 20.4514623 },
    { lat: 48.4484529, lng: 20.4515809 },
    { lat: 48.4484915, lng: 20.4516433 },
    { lat: 48.4485646, lng: 20.4517574 },
    { lat: 48.4487259, lng: 20.4520262 },
    { lat: 48.448792, lng: 20.452232 },
    { lat: 48.448844, lng: 20.4524209 },
    { lat: 48.4488615, lng: 20.4524797 },
    { lat: 48.4488939, lng: 20.4527586 },
    { lat: 48.4489271, lng: 20.4530785 },
    { lat: 48.448942, lng: 20.4534434 },
    { lat: 48.448957, lng: 20.453877 },
    { lat: 48.4490777, lng: 20.4548589 },
    { lat: 48.449115, lng: 20.454995 },
    { lat: 48.4492807, lng: 20.4555271 },
    { lat: 48.4493629, lng: 20.4557854 },
    { lat: 48.4494231, lng: 20.4559746 },
    { lat: 48.4495162, lng: 20.4562891 },
    { lat: 48.4496385, lng: 20.4566678 },
    { lat: 48.4496797, lng: 20.45681 },
    { lat: 48.4497729, lng: 20.457105 },
    { lat: 48.4498069, lng: 20.4572187 },
    { lat: 48.4498387, lng: 20.4574843 },
    { lat: 48.4498595, lng: 20.4576587 },
    { lat: 48.4499206, lng: 20.4581449 },
    { lat: 48.4499199, lng: 20.4583348 },
    { lat: 48.4499197, lng: 20.4586613 },
    { lat: 48.4499192, lng: 20.4588497 },
    { lat: 48.4499191, lng: 20.459257 },
    { lat: 48.4499198, lng: 20.4595395 },
    { lat: 48.449918, lng: 20.459788 },
    { lat: 48.4499187, lng: 20.4600214 },
    { lat: 48.4499179, lng: 20.4601701 },
    { lat: 48.449938, lng: 20.4604899 },
    { lat: 48.4499565, lng: 20.4607277 },
    { lat: 48.4500027, lng: 20.461294 },
    { lat: 48.450128, lng: 20.4617276 },
    { lat: 48.4502351, lng: 20.4621032 },
    { lat: 48.450367, lng: 20.4623768 },
    { lat: 48.450526, lng: 20.4626994 },
    { lat: 48.4506794, lng: 20.4629489 },
    { lat: 48.4507866, lng: 20.4631256 },
    { lat: 48.4510636, lng: 20.4635835 },
    { lat: 48.4513221, lng: 20.4640056 },
    { lat: 48.451502, lng: 20.4643 },
    { lat: 48.4516615, lng: 20.4645677 },
    { lat: 48.4518593, lng: 20.4648946 },
    { lat: 48.451971, lng: 20.465077 },
    { lat: 48.452056, lng: 20.465244 },
    { lat: 48.4521594, lng: 20.4654543 },
    { lat: 48.4522332, lng: 20.4656011 },
    { lat: 48.452342, lng: 20.4658202 },
    { lat: 48.4524417, lng: 20.4660276 },
    { lat: 48.4525723, lng: 20.4662931 },
    { lat: 48.4526368, lng: 20.4664861 },
    { lat: 48.4527183, lng: 20.4667191 },
    { lat: 48.4528135, lng: 20.4669959 },
    { lat: 48.4528683, lng: 20.4671504 },
    { lat: 48.4529232, lng: 20.4673141 },
    { lat: 48.452956, lng: 20.467526 },
    { lat: 48.4529987, lng: 20.4677949 },
    { lat: 48.452987, lng: 20.4682249 },
    { lat: 48.4529474, lng: 20.4684529 },
    { lat: 48.4529248, lng: 20.4685886 },
    { lat: 48.4528969, lng: 20.4687274 },
    { lat: 48.4528311, lng: 20.4689537 },
    { lat: 48.4527855, lng: 20.4691274 },
    { lat: 48.4527296, lng: 20.469325 },
    { lat: 48.4526451, lng: 20.4696228 },
    { lat: 48.4525827, lng: 20.4697417 },
    { lat: 48.4525456, lng: 20.4698187 },
    { lat: 48.4526547, lng: 20.4697467 },
    { lat: 48.4528805, lng: 20.4696872 },
    { lat: 48.4530313, lng: 20.4696535 },
    { lat: 48.4533387, lng: 20.4696578 },
    { lat: 48.4535635, lng: 20.4696562 },
    { lat: 48.4537528, lng: 20.4696561 },
    { lat: 48.4539259, lng: 20.4696501 },
    { lat: 48.4540724, lng: 20.4696908 },
    { lat: 48.4544244, lng: 20.4698148 },
    { lat: 48.4547164, lng: 20.4699071 },
    { lat: 48.4548296, lng: 20.4699481 },
    { lat: 48.4549444, lng: 20.4699225 },
    { lat: 48.4551064, lng: 20.4698831 },
    { lat: 48.455166, lng: 20.4698674 },
    { lat: 48.4553154, lng: 20.469832 },
    { lat: 48.4555906, lng: 20.4697635 },
    { lat: 48.4558475, lng: 20.469705 },
    { lat: 48.4561268, lng: 20.4696355 },
    { lat: 48.4562643, lng: 20.4696256 },
    { lat: 48.4564416, lng: 20.4696254 },
    { lat: 48.4566908, lng: 20.4696099 },
    { lat: 48.4568495, lng: 20.4696025 },
    { lat: 48.4570639, lng: 20.469596 },
    { lat: 48.4573477, lng: 20.4695799 },
    { lat: 48.4575736, lng: 20.469571 },
    { lat: 48.4578311, lng: 20.4695583 },
    { lat: 48.4582852, lng: 20.4695415 },
    { lat: 48.4585813, lng: 20.4695264 },
    { lat: 48.4590618, lng: 20.4695085 },
    { lat: 48.4594531, lng: 20.4694892 },
    { lat: 48.459558, lng: 20.4694832 },
    { lat: 48.4598534, lng: 20.4694691 },
    { lat: 48.460239, lng: 20.4690904 },
    { lat: 48.4604915, lng: 20.4688372 },
    { lat: 48.4605366, lng: 20.468793 },
    { lat: 48.4606727, lng: 20.4685721 },
    { lat: 48.4610377, lng: 20.4680055 },
    { lat: 48.4610669, lng: 20.4679567 },
    { lat: 48.4612299, lng: 20.4676982 },
    { lat: 48.4613767, lng: 20.4674641 },
    { lat: 48.4616531, lng: 20.4670304 },
    { lat: 48.4617886, lng: 20.4668163 },
    { lat: 48.4620868, lng: 20.4663466 },
    { lat: 48.46221, lng: 20.466148 },
    { lat: 48.4624398, lng: 20.465791 },
    { lat: 48.4625961, lng: 20.4655336 },
    { lat: 48.46266, lng: 20.46543 },
    { lat: 48.4629507, lng: 20.465066 },
    { lat: 48.4631727, lng: 20.4647946 },
    { lat: 48.463307, lng: 20.464645 },
    { lat: 48.463418, lng: 20.464505 },
    { lat: 48.463554, lng: 20.46431 },
    { lat: 48.4637089, lng: 20.464136 },
    { lat: 48.4639011, lng: 20.4638934 },
    { lat: 48.4639617, lng: 20.4638826 },
    { lat: 48.464102, lng: 20.463871 },
    { lat: 48.464539, lng: 20.463438 },
    { lat: 48.465044, lng: 20.463135 },
    { lat: 48.465436, lng: 20.463023 },
    { lat: 48.46605, lng: 20.463205 },
    { lat: 48.466871, lng: 20.463894 },
    { lat: 48.467538, lng: 20.464407 },
    { lat: 48.468026, lng: 20.464546 },
    { lat: 48.468398, lng: 20.464326 },
    { lat: 48.468812, lng: 20.46386 },
    { lat: 48.469038, lng: 20.463345 },
    { lat: 48.469157, lng: 20.462766 },
    { lat: 48.469576, lng: 20.4621 },
    { lat: 48.46996, lng: 20.461542 },
    { lat: 48.470867, lng: 20.461256 },
    { lat: 48.470911, lng: 20.461221 },
    { lat: 48.471401, lng: 20.460818 },
    { lat: 48.471761, lng: 20.459934 },
    { lat: 48.472202, lng: 20.458775 },
    { lat: 48.472217, lng: 20.458143 },
    { lat: 48.472396, lng: 20.457653 },
    { lat: 48.472448, lng: 20.457579 },
    { lat: 48.472981, lng: 20.456829 },
    { lat: 48.47319, lng: 20.456353 },
    { lat: 48.473253, lng: 20.456212 },
    { lat: 48.473445, lng: 20.455811 },
    { lat: 48.473565, lng: 20.455592 },
    { lat: 48.473199, lng: 20.454727 },
    { lat: 48.472803, lng: 20.453791 },
    { lat: 48.472749, lng: 20.453664 },
    { lat: 48.47222, lng: 20.452542 },
    { lat: 48.472075, lng: 20.452234 },
    { lat: 48.471775, lng: 20.451311 },
    { lat: 48.471729, lng: 20.450943 },
    { lat: 48.47162, lng: 20.450066 },
    { lat: 48.471534, lng: 20.449376 },
    { lat: 48.471474, lng: 20.44896 },
    { lat: 48.470972, lng: 20.446557 },
    { lat: 48.470495, lng: 20.445535 },
    { lat: 48.470318, lng: 20.445158 },
    { lat: 48.469718, lng: 20.443874 },
    { lat: 48.4695, lng: 20.44344 },
    { lat: 48.469428, lng: 20.44131 },
    { lat: 48.469415, lng: 20.441073 },
    { lat: 48.469166, lng: 20.439978 },
    { lat: 48.468738, lng: 20.439144 },
    { lat: 48.467723, lng: 20.438332 },
    { lat: 48.467558, lng: 20.437402 },
    { lat: 48.46765, lng: 20.436842 },
    { lat: 48.467698, lng: 20.436724 },
    { lat: 48.468003, lng: 20.436113 },
    { lat: 48.468284, lng: 20.435194 },
    { lat: 48.468896, lng: 20.43397 },
    { lat: 48.469229, lng: 20.433131 },
    { lat: 48.469245, lng: 20.433098 },
    { lat: 48.469265, lng: 20.433056 },
    { lat: 48.46917, lng: 20.43203 },
    { lat: 48.468068, lng: 20.429971 },
    { lat: 48.46794, lng: 20.429427 },
    { lat: 48.467298, lng: 20.427988 },
    { lat: 48.467124, lng: 20.427645 },
    { lat: 48.467621, lng: 20.427046 },
    { lat: 48.467994, lng: 20.425237 },
    { lat: 48.469962, lng: 20.421202 },
    { lat: 48.470456, lng: 20.420356 },
    { lat: 48.470488, lng: 20.420277 },
    { lat: 48.470436, lng: 20.420298 },
    { lat: 48.470407, lng: 20.420219 },
    { lat: 48.470281, lng: 20.4201 },
    { lat: 48.470223, lng: 20.419965 },
    { lat: 48.470063, lng: 20.419718 },
    { lat: 48.469855, lng: 20.419926 },
    { lat: 48.469817, lng: 20.419916 },
    { lat: 48.469796, lng: 20.419843 },
    { lat: 48.469565, lng: 20.419756 },
    { lat: 48.469481, lng: 20.419606 },
    { lat: 48.469354, lng: 20.419636 },
    { lat: 48.469317, lng: 20.419636 },
    { lat: 48.469206, lng: 20.419478 },
    { lat: 48.469204, lng: 20.419446 },
    { lat: 48.469238, lng: 20.419262 },
    { lat: 48.469226, lng: 20.41923 },
    { lat: 48.469135, lng: 20.419199 },
    { lat: 48.469067, lng: 20.419134 },
    { lat: 48.468996, lng: 20.419123 },
    { lat: 48.46891, lng: 20.419174 },
    { lat: 48.468853, lng: 20.419127 },
    { lat: 48.468829, lng: 20.419008 },
    { lat: 48.468802, lng: 20.418995 },
    { lat: 48.468743, lng: 20.41915 },
    { lat: 48.468709, lng: 20.419155 },
    { lat: 48.468572, lng: 20.418999 },
    { lat: 48.46848, lng: 20.418838 },
    { lat: 48.468406, lng: 20.418803 },
    { lat: 48.46837, lng: 20.418723 },
    { lat: 48.468363, lng: 20.418617 },
    { lat: 48.468314, lng: 20.418494 },
    { lat: 48.468346, lng: 20.418304 },
    { lat: 48.468298, lng: 20.418175 },
    { lat: 48.468203, lng: 20.418141 },
    { lat: 48.468095, lng: 20.418077 },
    { lat: 48.467979, lng: 20.418035 },
    { lat: 48.467879, lng: 20.417983 },
    { lat: 48.467778, lng: 20.417709 },
    { lat: 48.467612, lng: 20.417589 },
    { lat: 48.467463, lng: 20.417437 },
    { lat: 48.467381, lng: 20.417354 },
    { lat: 48.467356, lng: 20.41738 },
    { lat: 48.467332, lng: 20.417404 },
    { lat: 48.467286, lng: 20.417423 },
    { lat: 48.467227, lng: 20.417447 },
    { lat: 48.467184, lng: 20.417386 },
    { lat: 48.467126, lng: 20.417343 },
    { lat: 48.467114, lng: 20.417369 },
    { lat: 48.467137, lng: 20.417451 },
    { lat: 48.467119, lng: 20.417498 },
    { lat: 48.466991, lng: 20.417352 },
    { lat: 48.466957, lng: 20.417233 },
    { lat: 48.466901, lng: 20.417173 },
    { lat: 48.466726, lng: 20.417382 },
    { lat: 48.466625, lng: 20.417387 },
    { lat: 48.466597, lng: 20.417333 },
    { lat: 48.466608, lng: 20.417291 },
    { lat: 48.466667, lng: 20.41723 },
    { lat: 48.466554, lng: 20.417065 },
    { lat: 48.466487, lng: 20.417013 },
    { lat: 48.466414, lng: 20.417017 },
    { lat: 48.466268, lng: 20.417081 },
    { lat: 48.466162, lng: 20.417029 },
    { lat: 48.466147, lng: 20.416925 },
    { lat: 48.465977, lng: 20.416808 },
    { lat: 48.465865, lng: 20.416844 },
    { lat: 48.465844, lng: 20.416825 },
    { lat: 48.465779, lng: 20.416611 },
    { lat: 48.465735, lng: 20.416641 },
    { lat: 48.465578, lng: 20.416709 },
    { lat: 48.465546, lng: 20.416683 },
    { lat: 48.465528, lng: 20.416593 },
    { lat: 48.465529, lng: 20.416435 },
    { lat: 48.465495, lng: 20.416453 },
    { lat: 48.46546, lng: 20.416548 },
    { lat: 48.465432, lng: 20.41658 },
    { lat: 48.465369, lng: 20.416563 },
    { lat: 48.465226, lng: 20.41659 },
    { lat: 48.465094, lng: 20.416432 },
    { lat: 48.465079, lng: 20.416381 },
    { lat: 48.465155, lng: 20.416326 },
    { lat: 48.465162, lng: 20.41624 },
    { lat: 48.465106, lng: 20.416196 },
    { lat: 48.465055, lng: 20.416255 },
    { lat: 48.465023, lng: 20.416256 },
    { lat: 48.464997, lng: 20.416107 },
    { lat: 48.464963, lng: 20.416059 },
    { lat: 48.464785, lng: 20.41602 },
    { lat: 48.464714, lng: 20.416064 },
    { lat: 48.464679, lng: 20.41604 },
    { lat: 48.464661, lng: 20.415901 },
    { lat: 48.464579, lng: 20.415826 },
    { lat: 48.464487, lng: 20.415931 },
    { lat: 48.46442, lng: 20.415902 },
    { lat: 48.464436, lng: 20.416012 },
    { lat: 48.464426, lng: 20.416047 },
    { lat: 48.46435, lng: 20.41609 },
    { lat: 48.464325, lng: 20.416041 },
    { lat: 48.464331, lng: 20.415855 },
    { lat: 48.46428, lng: 20.415795 },
    { lat: 48.464264, lng: 20.415753 },
    { lat: 48.464275, lng: 20.415698 },
    { lat: 48.464182, lng: 20.415621 },
    { lat: 48.46413, lng: 20.415642 },
    { lat: 48.464118, lng: 20.415643 },
    { lat: 48.464044, lng: 20.415536 },
    { lat: 48.464007, lng: 20.415531 },
    { lat: 48.46389, lng: 20.415516 },
    { lat: 48.463826, lng: 20.41564 },
    { lat: 48.46363, lng: 20.415743 },
    { lat: 48.463573, lng: 20.415865 },
    { lat: 48.463517, lng: 20.415794 },
    { lat: 48.463518, lng: 20.415745 },
    { lat: 48.463551, lng: 20.415699 },
    { lat: 48.463506, lng: 20.415565 },
    { lat: 48.463426, lng: 20.415565 },
    { lat: 48.463324, lng: 20.415291 },
    { lat: 48.463218, lng: 20.415203 },
    { lat: 48.463142, lng: 20.415253 },
    { lat: 48.46315, lng: 20.415355 },
    { lat: 48.463066, lng: 20.415438 },
    { lat: 48.463037, lng: 20.415392 },
    { lat: 48.463023, lng: 20.415256 },
    { lat: 48.462615, lng: 20.415117 },
    { lat: 48.462575, lng: 20.414935 },
    { lat: 48.462481, lng: 20.414925 },
    { lat: 48.462373, lng: 20.414871 },
    { lat: 48.462349, lng: 20.414913 },
    { lat: 48.462315, lng: 20.414914 },
    { lat: 48.462273, lng: 20.414843 },
    { lat: 48.462234, lng: 20.414866 },
    { lat: 48.462256, lng: 20.415014 },
    { lat: 48.462232, lng: 20.41505 },
    { lat: 48.462139, lng: 20.415029 },
    { lat: 48.462132, lng: 20.415029 },
    { lat: 48.462086, lng: 20.414989 },
    { lat: 48.462115, lng: 20.414773 },
    { lat: 48.461937, lng: 20.414786 },
    { lat: 48.461888, lng: 20.414647 },
    { lat: 48.461796, lng: 20.414732 },
    { lat: 48.461675, lng: 20.414678 },
    { lat: 48.461561, lng: 20.414282 },
    { lat: 48.461522, lng: 20.41429 },
    { lat: 48.461469, lng: 20.414308 },
    { lat: 48.46124, lng: 20.41399 },
    { lat: 48.461149, lng: 20.413967 },
    { lat: 48.461139, lng: 20.413861 },
    { lat: 48.461258, lng: 20.41369 },
    { lat: 48.461317, lng: 20.413756 },
    { lat: 48.461334, lng: 20.413754 },
    { lat: 48.461454, lng: 20.413699 },
    { lat: 48.461525, lng: 20.413596 },
    { lat: 48.461487, lng: 20.413542 },
    { lat: 48.461254, lng: 20.413617 },
    { lat: 48.461193, lng: 20.413469 },
    { lat: 48.461108, lng: 20.413393 },
    { lat: 48.461076, lng: 20.413246 },
    { lat: 48.460882, lng: 20.413429 },
    { lat: 48.460831, lng: 20.41333 },
    { lat: 48.460812, lng: 20.413136 },
    { lat: 48.460907, lng: 20.412959 },
    { lat: 48.461012, lng: 20.412829 },
    { lat: 48.460969, lng: 20.412737 },
    { lat: 48.460958, lng: 20.412586 },
    { lat: 48.460985, lng: 20.412546 },
    { lat: 48.460938, lng: 20.412348 },
    { lat: 48.460976, lng: 20.412203 },
    { lat: 48.460913, lng: 20.412013 },
    { lat: 48.461041, lng: 20.411821 },
    { lat: 48.461035, lng: 20.411703 },
    { lat: 48.460896, lng: 20.411701 },
    { lat: 48.46091, lng: 20.411406 },
    { lat: 48.460963, lng: 20.411267 },
    { lat: 48.461155, lng: 20.411081 },
    { lat: 48.46112, lng: 20.411016 },
    { lat: 48.461109, lng: 20.410937 },
    { lat: 48.461143, lng: 20.410901 },
    { lat: 48.461129, lng: 20.410812 },
    { lat: 48.461079, lng: 20.410853 },
    { lat: 48.460922, lng: 20.41089 },
    { lat: 48.460901, lng: 20.410759 },
    { lat: 48.46094, lng: 20.410715 },
    { lat: 48.460926, lng: 20.410466 },
    { lat: 48.461036, lng: 20.410437 },
    { lat: 48.461037, lng: 20.410378 },
    { lat: 48.460998, lng: 20.410357 },
    { lat: 48.460978, lng: 20.410355 },
    { lat: 48.460924, lng: 20.410233 },
    { lat: 48.460914, lng: 20.410101 },
    { lat: 48.460985, lng: 20.409957 },
    { lat: 48.461005, lng: 20.40983 },
    { lat: 48.461069, lng: 20.409744 },
    { lat: 48.460964, lng: 20.409472 },
    { lat: 48.460921, lng: 20.409382 },
    { lat: 48.460883, lng: 20.409304 },
    { lat: 48.460835, lng: 20.409316 },
    { lat: 48.46079, lng: 20.409471 },
    { lat: 48.460591, lng: 20.409494 },
    { lat: 48.460511, lng: 20.409413 },
    { lat: 48.460521, lng: 20.409281 },
    { lat: 48.460583, lng: 20.409214 },
    { lat: 48.460575, lng: 20.409088 },
    { lat: 48.460426, lng: 20.408805 },
    { lat: 48.460564, lng: 20.408557 },
    { lat: 48.460414, lng: 20.408472 },
    { lat: 48.460486, lng: 20.408334 },
    { lat: 48.460452, lng: 20.408328 },
    { lat: 48.460393, lng: 20.408331 },
    { lat: 48.460358, lng: 20.408451 },
    { lat: 48.460191, lng: 20.408438 },
    { lat: 48.460041, lng: 20.407976 },
    { lat: 48.459927, lng: 20.407928 },
    { lat: 48.459806, lng: 20.408176 },
    { lat: 48.459753, lng: 20.408223 },
    { lat: 48.459689, lng: 20.40823 },
    { lat: 48.459666, lng: 20.408314 },
    { lat: 48.459579, lng: 20.408336 },
    { lat: 48.459355, lng: 20.407983 },
    { lat: 48.45921, lng: 20.408052 },
    { lat: 48.45912, lng: 20.407964 },
    { lat: 48.459135, lng: 20.407767 },
    { lat: 48.459189, lng: 20.407655 },
    { lat: 48.459208, lng: 20.407611 },
    { lat: 48.459203, lng: 20.407549 },
    { lat: 48.459142, lng: 20.407548 },
    { lat: 48.458989, lng: 20.407405 },
    { lat: 48.458856, lng: 20.407528 },
    { lat: 48.458719, lng: 20.407516 },
    { lat: 48.458674, lng: 20.407402 },
    { lat: 48.45872, lng: 20.407299 },
    { lat: 48.458778, lng: 20.407287 },
    { lat: 48.458722, lng: 20.407088 },
    { lat: 48.458635, lng: 20.406897 },
    { lat: 48.458636, lng: 20.406768 },
    { lat: 48.458736, lng: 20.406724 },
    { lat: 48.458718, lng: 20.40667 },
    { lat: 48.45858, lng: 20.406628 },
    { lat: 48.458726, lng: 20.406229 },
    { lat: 48.458686, lng: 20.406022 },
    { lat: 48.458739, lng: 20.405782 },
    { lat: 48.458975, lng: 20.405807 },
    { lat: 48.458966, lng: 20.40574 },
    { lat: 48.458794, lng: 20.405606 },
    { lat: 48.458569, lng: 20.405288 },
    { lat: 48.458444, lng: 20.405164 },
    { lat: 48.4584, lng: 20.405029 },
    { lat: 48.458495, lng: 20.404936 },
    { lat: 48.458422, lng: 20.404949 },
    { lat: 48.458441, lng: 20.404905 },
    { lat: 48.458297, lng: 20.404729 },
    { lat: 48.458349, lng: 20.404641 },
    { lat: 48.458335, lng: 20.404562 },
    { lat: 48.458073, lng: 20.404683 },
    { lat: 48.457929, lng: 20.404502 },
    { lat: 48.457895, lng: 20.404306 },
    { lat: 48.457948, lng: 20.404153 },
    { lat: 48.45795, lng: 20.403895 },
    { lat: 48.457855, lng: 20.403962 },
    { lat: 48.457813, lng: 20.403892 },
    { lat: 48.457828, lng: 20.403827 },
    { lat: 48.457657, lng: 20.403491 },
    { lat: 48.457618, lng: 20.403341 },
    { lat: 48.457634, lng: 20.403233 },
    { lat: 48.457644, lng: 20.403175 },
    { lat: 48.457575, lng: 20.403162 },
    { lat: 48.457505, lng: 20.402995 },
    { lat: 48.45737, lng: 20.402997 },
    { lat: 48.457355, lng: 20.40272 },
    { lat: 48.457375, lng: 20.40233 },
    { lat: 48.457444, lng: 20.402186 },
    { lat: 48.457525, lng: 20.402228 },
    { lat: 48.45756, lng: 20.402027 },
    { lat: 48.457445, lng: 20.401926 },
    { lat: 48.457329, lng: 20.401934 },
    { lat: 48.457366, lng: 20.40165 },
    { lat: 48.457348, lng: 20.401584 },
    { lat: 48.457361, lng: 20.401412 },
    { lat: 48.457409, lng: 20.4013 },
    { lat: 48.457467, lng: 20.40136 },
    { lat: 48.457612, lng: 20.401223 },
    { lat: 48.45758, lng: 20.401128 },
    { lat: 48.457417, lng: 20.400992 },
    { lat: 48.457395, lng: 20.400893 },
    { lat: 48.457426, lng: 20.400758 },
    { lat: 48.457479, lng: 20.400739 },
    { lat: 48.457465, lng: 20.400584 },
    { lat: 48.457425, lng: 20.400468 },
    { lat: 48.457405, lng: 20.40013 },
    { lat: 48.457442, lng: 20.400104 },
    { lat: 48.457566, lng: 20.400119 },
    { lat: 48.457568, lng: 20.400004 },
    { lat: 48.457526, lng: 20.399973 },
    { lat: 48.457506, lng: 20.399743 },
    { lat: 48.457614, lng: 20.399556 },
    { lat: 48.457601, lng: 20.399489 },
    { lat: 48.457629, lng: 20.399397 },
    { lat: 48.457584, lng: 20.399389 },
    { lat: 48.457569, lng: 20.399198 },
    { lat: 48.457516, lng: 20.399055 },
    { lat: 48.457517, lng: 20.398876 },
    { lat: 48.457421, lng: 20.398757 },
    { lat: 48.457447, lng: 20.398698 },
    { lat: 48.457476, lng: 20.398592 },
    { lat: 48.457348, lng: 20.398496 },
    { lat: 48.457265, lng: 20.398337 },
    { lat: 48.457265, lng: 20.39812 },
    { lat: 48.457243, lng: 20.398021 },
    { lat: 48.457142, lng: 20.397974 },
    { lat: 48.457061, lng: 20.397869 },
    { lat: 48.456947, lng: 20.397941 },
    { lat: 48.456854, lng: 20.397781 },
    { lat: 48.456757, lng: 20.397795 },
    { lat: 48.456661, lng: 20.397525 },
    { lat: 48.456742, lng: 20.397416 },
    { lat: 48.45682, lng: 20.39746 },
    { lat: 48.456831, lng: 20.397362 },
    { lat: 48.456748, lng: 20.397362 },
    { lat: 48.456683, lng: 20.397191 },
    { lat: 48.456618, lng: 20.397083 },
    { lat: 48.456642, lng: 20.396835 },
    { lat: 48.456541, lng: 20.396722 },
    { lat: 48.456597, lng: 20.396382 },
    { lat: 48.456663, lng: 20.39628 },
    { lat: 48.456657, lng: 20.396274 },
    { lat: 48.456551, lng: 20.396265 },
    { lat: 48.45648, lng: 20.396198 },
    { lat: 48.456473, lng: 20.395979 },
    { lat: 48.456529, lng: 20.395977 },
    { lat: 48.4565, lng: 20.395816 },
    { lat: 48.45661, lng: 20.39571 },
    { lat: 48.456791, lng: 20.395661 },
    { lat: 48.456764, lng: 20.395555 },
    { lat: 48.456836, lng: 20.395444 },
    { lat: 48.456798, lng: 20.395279 },
    { lat: 48.456837, lng: 20.395148 },
    { lat: 48.456978, lng: 20.395037 },
    { lat: 48.456964, lng: 20.394774 },
    { lat: 48.456755, lng: 20.394676 },
    { lat: 48.456783, lng: 20.394482 },
    { lat: 48.456716, lng: 20.39437 },
    { lat: 48.456716, lng: 20.394243 },
    { lat: 48.456671, lng: 20.394035 },
    { lat: 48.456714, lng: 20.394008 },
    { lat: 48.456762, lng: 20.393981 },
    { lat: 48.457002, lng: 20.393913 },
    { lat: 48.457388, lng: 20.393702 },
    { lat: 48.457808, lng: 20.393718 },
    { lat: 48.458064, lng: 20.393649 },
    { lat: 48.458242, lng: 20.393474 },
    { lat: 48.458908, lng: 20.393146 },
    { lat: 48.458998, lng: 20.39311 },
    { lat: 48.4581185, lng: 20.3909149 },
    { lat: 48.4579473, lng: 20.3903611 },
    { lat: 48.4579361, lng: 20.388849 },
    { lat: 48.4580967, lng: 20.3880778 },
    { lat: 48.4580816, lng: 20.3873682 },
    { lat: 48.4579869, lng: 20.3849181 },
    { lat: 48.4578405, lng: 20.3836658 },
    { lat: 48.4577271, lng: 20.3819539 },
    { lat: 48.4577362, lng: 20.3812326 },
    { lat: 48.4575845, lng: 20.3802194 },
    { lat: 48.4568882, lng: 20.3775046 },
    { lat: 48.4562944, lng: 20.3748219 },
    { lat: 48.4558656, lng: 20.3719851 },
    { lat: 48.4552675, lng: 20.3697741 },
    { lat: 48.4551578, lng: 20.3694396 },
    { lat: 48.4550224, lng: 20.3692035 },
    { lat: 48.4547639, lng: 20.368898 },
    { lat: 48.4531399, lng: 20.3675008 },
    { lat: 48.4529385, lng: 20.3672712 },
    { lat: 48.4488932, lng: 20.3583762 },
    { lat: 48.4490791, lng: 20.3566093 },
    { lat: 48.4485461, lng: 20.3566586 },
    { lat: 48.4481117, lng: 20.3569267 },
    { lat: 48.4480817, lng: 20.35694 },
    { lat: 48.4480911, lng: 20.3566596 },
    { lat: 48.4480244, lng: 20.3565938 },
    { lat: 48.4479893, lng: 20.3563295 },
    { lat: 48.4480231, lng: 20.3558955 },
    { lat: 48.447991, lng: 20.3557664 },
    { lat: 48.4480134, lng: 20.3553295 },
    { lat: 48.447973, lng: 20.3552444 },
    { lat: 48.4480826, lng: 20.3551375 },
    { lat: 48.4480186, lng: 20.3549126 },
    { lat: 48.4480987, lng: 20.3547147 },
    { lat: 48.4479323, lng: 20.3543743 },
    { lat: 48.4479693, lng: 20.354166 },
    { lat: 48.447906, lng: 20.3538151 },
    { lat: 48.4479985, lng: 20.3535288 },
    { lat: 48.4478628, lng: 20.35308 },
    { lat: 48.4479648, lng: 20.3522892 },
    { lat: 48.4478956, lng: 20.3519831 },
    { lat: 48.4479195, lng: 20.351792 },
    { lat: 48.4478872, lng: 20.3515979 },
    { lat: 48.4479647, lng: 20.351075 },
    { lat: 48.4481354, lng: 20.3508595 },
    { lat: 48.4481976, lng: 20.3506026 },
    { lat: 48.4482133, lng: 20.3490113 },
    { lat: 48.4484195, lng: 20.3482656 },
    { lat: 48.448025, lng: 20.3466101 },
    { lat: 48.4480732, lng: 20.3461688 },
    { lat: 48.4480192, lng: 20.3456816 },
    { lat: 48.4479329, lng: 20.3456055 },
    { lat: 48.447869, lng: 20.3453813 },
    { lat: 48.4478946, lng: 20.3450587 },
    { lat: 48.4476948, lng: 20.344728 },
    { lat: 48.4476683, lng: 20.3444845 },
    { lat: 48.4475965, lng: 20.3443741 },
    { lat: 48.4472734, lng: 20.3441727 },
    { lat: 48.4472381, lng: 20.3440779 },
    { lat: 48.447264, lng: 20.3435273 },
    { lat: 48.4471496, lng: 20.3427609 },
    { lat: 48.4473086, lng: 20.3422772 },
    { lat: 48.4473017, lng: 20.3417234 },
    { lat: 48.4473722, lng: 20.3416253 },
    { lat: 48.4474061, lng: 20.3414297 },
    { lat: 48.4473265, lng: 20.3411501 },
    { lat: 48.4473301, lng: 20.3408376 },
    { lat: 48.4474901, lng: 20.3404649 },
    { lat: 48.4474714, lng: 20.3403058 },
    { lat: 48.4476027, lng: 20.3398909 },
    { lat: 48.4475518, lng: 20.3394212 },
    { lat: 48.4474741, lng: 20.3392758 },
    { lat: 48.4474255, lng: 20.3390144 },
    { lat: 48.4473837, lng: 20.3383211 },
    { lat: 48.4474557, lng: 20.3377415 },
    { lat: 48.4474625, lng: 20.33725 },
    { lat: 48.447726, lng: 20.3350345 },
    { lat: 48.4484348, lng: 20.332912 },
    { lat: 48.4489573, lng: 20.3297168 },
    { lat: 48.4487993, lng: 20.3294803 },
    { lat: 48.4487961, lng: 20.3292536 },
    { lat: 48.4487388, lng: 20.3291707 },
    { lat: 48.4487985, lng: 20.3289111 },
    { lat: 48.4486882, lng: 20.328686 },
    { lat: 48.4487098, lng: 20.3285184 },
    { lat: 48.4485892, lng: 20.3277366 },
    { lat: 48.4486713, lng: 20.3275076 },
    { lat: 48.448383, lng: 20.3269617 },
    { lat: 48.4481738, lng: 20.3267347 },
    { lat: 48.4480864, lng: 20.3264612 },
    { lat: 48.4477326, lng: 20.3259966 },
    { lat: 48.447159, lng: 20.3258434 },
    { lat: 48.4470493, lng: 20.3260606 },
    { lat: 48.4469681, lng: 20.3260852 },
    { lat: 48.4467203, lng: 20.3257712 },
    { lat: 48.4466226, lng: 20.325777 },
    { lat: 48.4464411, lng: 20.3256022 },
    { lat: 48.4460754, lng: 20.3257545 },
    { lat: 48.4458123, lng: 20.3259658 },
    { lat: 48.4455309, lng: 20.3263958 },
    { lat: 48.4453153, lng: 20.3265554 },
    { lat: 48.4449847, lng: 20.32637 },
    { lat: 48.4446134, lng: 20.326061 },
    { lat: 48.4447089, lng: 20.3257687 },
    { lat: 48.4446538, lng: 20.3256817 },
    { lat: 48.4445435, lng: 20.3256575 },
    { lat: 48.4443443, lng: 20.3257467 },
    { lat: 48.4443032, lng: 20.3259916 },
    { lat: 48.4439854, lng: 20.3258736 },
    { lat: 48.443843, lng: 20.3257149 },
    { lat: 48.443509, lng: 20.3256409 },
    { lat: 48.4433991, lng: 20.3254488 },
    { lat: 48.4433146, lng: 20.3251051 },
    { lat: 48.443332, lng: 20.3249486 },
    { lat: 48.4432522, lng: 20.3248393 },
    { lat: 48.443193, lng: 20.3249048 },
    { lat: 48.4431247, lng: 20.3248623 },
    { lat: 48.443084, lng: 20.3247687 },
    { lat: 48.4431564, lng: 20.3244051 },
    { lat: 48.4432917, lng: 20.3243779 },
    { lat: 48.4434389, lng: 20.3241946 },
    { lat: 48.4436085, lng: 20.3243023 },
    { lat: 48.4436082, lng: 20.3241796 },
    { lat: 48.4436874, lng: 20.3239839 },
    { lat: 48.4436896, lng: 20.3238719 },
    { lat: 48.4436177, lng: 20.323707 },
    { lat: 48.4436328, lng: 20.3235141 },
    { lat: 48.4431139, lng: 20.3229489 },
    { lat: 48.4430307, lng: 20.3229208 },
    { lat: 48.4429438, lng: 20.3226842 },
    { lat: 48.4428564, lng: 20.3225568 },
    { lat: 48.4427523, lng: 20.3225135 },
    { lat: 48.4425686, lng: 20.322595 },
    { lat: 48.4425072, lng: 20.3227799 },
    { lat: 48.4421409, lng: 20.3230039 },
    { lat: 48.4420983, lng: 20.3230957 },
    { lat: 48.4421187, lng: 20.3233865 },
    { lat: 48.4421749, lng: 20.3234957 },
    { lat: 48.4421333, lng: 20.3235551 },
    { lat: 48.4418894, lng: 20.3234266 },
    { lat: 48.4416219, lng: 20.3235855 },
    { lat: 48.4415264, lng: 20.3234421 },
    { lat: 48.4415426, lng: 20.3231708 },
    { lat: 48.4414965, lng: 20.3231138 },
    { lat: 48.4413453, lng: 20.3231929 },
    { lat: 48.4412482, lng: 20.3234735 },
    { lat: 48.4411963, lng: 20.3234987 },
    { lat: 48.4411443, lng: 20.3233529 },
    { lat: 48.4409968, lng: 20.3232081 },
    { lat: 48.4409307, lng: 20.3232179 },
    { lat: 48.4408825, lng: 20.3234215 },
    { lat: 48.4408052, lng: 20.3235205 },
    { lat: 48.4407264, lng: 20.3235828 },
    { lat: 48.4405986, lng: 20.3235651 },
    { lat: 48.4404749, lng: 20.3235101 },
    { lat: 48.4404084, lng: 20.3233681 },
    { lat: 48.4404976, lng: 20.3229711 },
    { lat: 48.4361383, lng: 20.322977 },
    { lat: 48.4359994, lng: 20.3234549 },
    { lat: 48.4358919, lng: 20.3236131 },
    { lat: 48.4358664, lng: 20.3237987 },
    { lat: 48.4357194, lng: 20.3239564 },
    { lat: 48.4355846, lng: 20.3239907 },
    { lat: 48.4354686, lng: 20.324163 },
    { lat: 48.4353144, lng: 20.3249423 },
    { lat: 48.4352498, lng: 20.3249629 },
    { lat: 48.4351112, lng: 20.3248713 },
    { lat: 48.4350052, lng: 20.3246952 },
    { lat: 48.4350641, lng: 20.32433 },
    { lat: 48.4350076, lng: 20.3242677 },
    { lat: 48.434738, lng: 20.3242305 },
    { lat: 48.4346673, lng: 20.3243531 },
    { lat: 48.4346543, lng: 20.3245617 },
    { lat: 48.4344156, lng: 20.324619 },
    { lat: 48.4343731, lng: 20.3245409 },
    { lat: 48.4344066, lng: 20.3243677 },
    { lat: 48.4343624, lng: 20.3242453 },
    { lat: 48.4343462, lng: 20.3238312 },
    { lat: 48.4342853, lng: 20.3237713 },
    { lat: 48.4340953, lng: 20.3237523 },
    { lat: 48.4338525, lng: 20.3241086 },
    { lat: 48.433826, lng: 20.3243478 },
    { lat: 48.4335697, lng: 20.3243893 },
    { lat: 48.4334844, lng: 20.3245125 },
    { lat: 48.4329288, lng: 20.3245297 },
    { lat: 48.4329115, lng: 20.3247497 },
    { lat: 48.4327535, lng: 20.3247718 },
    { lat: 48.4326087, lng: 20.3248985 },
    { lat: 48.4320726, lng: 20.3245538 },
    { lat: 48.4320882, lng: 20.3248896 },
    { lat: 48.4319535, lng: 20.324964 },
    { lat: 48.4318233, lng: 20.324568 },
    { lat: 48.4318035, lng: 20.3237815 },
    { lat: 48.4313541, lng: 20.3236645 },
    { lat: 48.431044, lng: 20.3239101 },
    { lat: 48.4309201, lng: 20.3243388 },
    { lat: 48.4308185, lng: 20.3242751 },
    { lat: 48.4309059, lng: 20.323839 },
    { lat: 48.4311903, lng: 20.3231347 },
    { lat: 48.4310671, lng: 20.3230371 },
    { lat: 48.4310537, lng: 20.3228659 },
    { lat: 48.4309525, lng: 20.3227572 },
    { lat: 48.4308826, lng: 20.3225517 },
    { lat: 48.4302608, lng: 20.3220057 },
    { lat: 48.4302619, lng: 20.3215414 },
    { lat: 48.42669, lng: 20.3183687 },
    { lat: 48.4258063, lng: 20.3159444 },
    { lat: 48.4255157, lng: 20.3155706 },
    { lat: 48.4255223, lng: 20.3151906 },
    { lat: 48.4257738, lng: 20.3143677 },
    { lat: 48.4252367, lng: 20.3141699 },
    { lat: 48.4254397, lng: 20.3141222 },
    { lat: 48.4254637, lng: 20.3139874 },
    { lat: 48.4241873, lng: 20.313488 },
    { lat: 48.4242457, lng: 20.3131704 },
    { lat: 48.423906, lng: 20.3130658 },
    { lat: 48.4237879, lng: 20.3137301 },
    { lat: 48.424029, lng: 20.3138186 },
    { lat: 48.4241404, lng: 20.3137584 },
    { lat: 48.4240994, lng: 20.3140074 },
    { lat: 48.4236241, lng: 20.3137422 },
    { lat: 48.4191463, lng: 20.3107342 },
    { lat: 48.4189007, lng: 20.3103344 },
    { lat: 48.4186257, lng: 20.3095005 },
    { lat: 48.4183998, lng: 20.3089941 },
    { lat: 48.4183297, lng: 20.3086157 },
    { lat: 48.4183197, lng: 20.3080738 },
    { lat: 48.4184978, lng: 20.3067373 },
    { lat: 48.4184997, lng: 20.3058973 },
    { lat: 48.418792, lng: 20.3044883 },
    { lat: 48.4188075, lng: 20.3044486 },
    { lat: 48.4188533, lng: 20.3044126 },
    { lat: 48.4215951, lng: 20.3046823 },
    { lat: 48.4228849, lng: 20.305093 },
    { lat: 48.4269392, lng: 20.3048606 },
    { lat: 48.4272579, lng: 20.304433 },
    { lat: 48.4276528, lng: 20.3043082 },
    { lat: 48.4278637, lng: 20.3047968 },
    { lat: 48.4281251, lng: 20.3044314 },
    { lat: 48.4290339, lng: 20.3059148 },
    { lat: 48.4292991, lng: 20.3060759 },
    { lat: 48.4306303, lng: 20.306559 },
    { lat: 48.4322776, lng: 20.3072978 },
    { lat: 48.4326026, lng: 20.3066532 },
    { lat: 48.4331451, lng: 20.3071061 },
    { lat: 48.4345967, lng: 20.308618 },
    { lat: 48.4348735, lng: 20.3086112 },
    { lat: 48.4354477, lng: 20.3083778 },
    { lat: 48.4356889, lng: 20.3082085 },
    { lat: 48.4358749, lng: 20.3079874 },
    { lat: 48.4364111, lng: 20.3081323 },
    { lat: 48.4375095, lng: 20.3081698 },
    { lat: 48.4379233, lng: 20.3079582 },
    { lat: 48.4380773, lng: 20.3076597 },
    { lat: 48.4386194, lng: 20.3070976 },
    { lat: 48.4395955, lng: 20.3067193 },
    { lat: 48.4399686, lng: 20.3064141 },
    { lat: 48.4403715, lng: 20.3057743 },
    { lat: 48.4405033, lng: 20.3053451 },
    { lat: 48.4406783, lng: 20.304042 },
    { lat: 48.4407556, lng: 20.303805 },
    { lat: 48.4410762, lng: 20.3032206 },
    { lat: 48.4411439, lng: 20.302999 },
    { lat: 48.4413758, lng: 20.302824 },
    { lat: 48.4415915, lng: 20.3025548 },
    { lat: 48.4424093, lng: 20.3021007 },
    { lat: 48.443037, lng: 20.3013688 },
    { lat: 48.4435694, lng: 20.3009302 },
    { lat: 48.4441386, lng: 20.3006858 },
    { lat: 48.445015, lng: 20.3000367 },
    { lat: 48.4454531, lng: 20.2998895 },
    { lat: 48.4458703, lng: 20.2998463 },
    { lat: 48.4464831, lng: 20.2994782 },
    { lat: 48.4469319, lng: 20.2995479 },
    { lat: 48.4470921, lng: 20.2994981 },
    { lat: 48.447292, lng: 20.2992529 },
    { lat: 48.4475866, lng: 20.2983881 },
    { lat: 48.4477349, lng: 20.2971805 },
    { lat: 48.4481146, lng: 20.2963244 },
    { lat: 48.4482241, lng: 20.2957023 },
    { lat: 48.4479518, lng: 20.2955127 },
    { lat: 48.4473792, lng: 20.2949309 },
    { lat: 48.4471882, lng: 20.2946013 },
    { lat: 48.4470026, lng: 20.2939464 },
    { lat: 48.4469632, lng: 20.2934923 },
    { lat: 48.4465796, lng: 20.2919884 },
    { lat: 48.4459776, lng: 20.291858 },
    { lat: 48.4450297, lng: 20.2913881 },
    { lat: 48.4447047, lng: 20.2910844 },
    { lat: 48.4444107, lng: 20.289801 },
    { lat: 48.4443816, lng: 20.2879236 },
    { lat: 48.4440679, lng: 20.2859407 },
    { lat: 48.4446508, lng: 20.2852176 },
    { lat: 48.4424283, lng: 20.2791751 },
    { lat: 48.4418802, lng: 20.2782182 },
    { lat: 48.4413044, lng: 20.2767292 },
    { lat: 48.440667, lng: 20.2744559 },
    { lat: 48.4403334, lng: 20.274196 },
    { lat: 48.440218, lng: 20.2742152 },
    { lat: 48.4398228, lng: 20.2748663 },
    { lat: 48.4396928, lng: 20.2752557 },
    { lat: 48.4396617, lng: 20.275267 },
    { lat: 48.4388929, lng: 20.2752152 },
    { lat: 48.4384129, lng: 20.2756402 },
    { lat: 48.4376817, lng: 20.2757159 },
    { lat: 48.4373846, lng: 20.2754127 },
    { lat: 48.4370562, lng: 20.2754814 },
    { lat: 48.4367636, lng: 20.2752012 },
    { lat: 48.4366901, lng: 20.2751929 },
    { lat: 48.4364033, lng: 20.2754604 },
    { lat: 48.436056, lng: 20.2761881 },
    { lat: 48.436034, lng: 20.2764557 },
    { lat: 48.4359664, lng: 20.2765121 },
    { lat: 48.4358619, lng: 20.2764303 },
    { lat: 48.4355603, lng: 20.2767516 },
    { lat: 48.435449, lng: 20.2770691 },
    { lat: 48.4354852, lng: 20.277329 },
    { lat: 48.4354532, lng: 20.2774021 },
    { lat: 48.4353146, lng: 20.2774308 },
    { lat: 48.4349684, lng: 20.2777884 },
    { lat: 48.4345276, lng: 20.2788546 },
    { lat: 48.4340787, lng: 20.2794384 },
    { lat: 48.4336854, lng: 20.2800552 },
    { lat: 48.4336017, lng: 20.280046 },
    { lat: 48.4335089, lng: 20.2798957 },
    { lat: 48.433297, lng: 20.280147 },
    { lat: 48.4331655, lng: 20.2801811 },
    { lat: 48.4330656, lng: 20.2803875 },
    { lat: 48.4329452, lng: 20.2804469 },
    { lat: 48.4329373, lng: 20.2806753 },
    { lat: 48.4331292, lng: 20.2806837 },
    { lat: 48.4332039, lng: 20.2808337 },
    { lat: 48.4330186, lng: 20.2809645 },
    { lat: 48.4329878, lng: 20.281296 },
    { lat: 48.4330893, lng: 20.2815816 },
    { lat: 48.4329995, lng: 20.2816446 },
    { lat: 48.4327716, lng: 20.2813238 },
    { lat: 48.4327101, lng: 20.2813623 },
    { lat: 48.4326693, lng: 20.2815532 },
    { lat: 48.4323988, lng: 20.2815533 },
    { lat: 48.4323518, lng: 20.2818993 },
    { lat: 48.4324366, lng: 20.2822209 },
    { lat: 48.4323934, lng: 20.2823233 },
    { lat: 48.4322737, lng: 20.2822929 },
    { lat: 48.4322546, lng: 20.2823765 },
    { lat: 48.4323681, lng: 20.2825656 },
    { lat: 48.4323143, lng: 20.2828042 },
    { lat: 48.432459, lng: 20.2829622 },
    { lat: 48.4324622, lng: 20.2830644 },
    { lat: 48.432066, lng: 20.2832157 },
    { lat: 48.4318967, lng: 20.2830936 },
    { lat: 48.4316758, lng: 20.2831485 },
    { lat: 48.431538, lng: 20.2833934 },
    { lat: 48.4312967, lng: 20.2836114 },
    { lat: 48.4312656, lng: 20.2837366 },
    { lat: 48.4313098, lng: 20.2840315 },
    { lat: 48.4316354, lng: 20.2843745 },
    { lat: 48.4316154, lng: 20.2844951 },
    { lat: 48.4314578, lng: 20.2845503 },
    { lat: 48.4313458, lng: 20.2848805 },
    { lat: 48.4311044, lng: 20.2849294 },
    { lat: 48.430779, lng: 20.2847584 },
    { lat: 48.4308021, lng: 20.2844063 },
    { lat: 48.4307583, lng: 20.2842358 },
    { lat: 48.4306305, lng: 20.2841951 },
    { lat: 48.4305582, lng: 20.2844157 },
    { lat: 48.4306185, lng: 20.2848582 },
    { lat: 48.4302809, lng: 20.2850639 },
    { lat: 48.4301419, lng: 20.2849387 },
    { lat: 48.4300285, lng: 20.2846329 },
    { lat: 48.430006, lng: 20.284381 },
    { lat: 48.4300822, lng: 20.2842306 },
    { lat: 48.4300439, lng: 20.2841145 },
    { lat: 48.4297418, lng: 20.2841312 },
    { lat: 48.4295779, lng: 20.2838604 },
    { lat: 48.4295059, lng: 20.2836409 },
    { lat: 48.4292971, lng: 20.283428 },
    { lat: 48.4292116, lng: 20.2834317 },
    { lat: 48.428983, lng: 20.2836007 },
    { lat: 48.4289301, lng: 20.2840877 },
    { lat: 48.4288123, lng: 20.2844695 },
    { lat: 48.4288132, lng: 20.2845467 },
    { lat: 48.4289222, lng: 20.2847108 },
    { lat: 48.4286279, lng: 20.284931 },
    { lat: 48.4285729, lng: 20.2851047 },
    { lat: 48.4284631, lng: 20.2852344 },
    { lat: 48.4285233, lng: 20.2853911 },
    { lat: 48.4285123, lng: 20.2861378 },
    { lat: 48.4285752, lng: 20.2863318 },
    { lat: 48.4286828, lng: 20.2863743 },
    { lat: 48.4286911, lng: 20.2859871 },
    { lat: 48.4287663, lng: 20.2860077 },
    { lat: 48.4287981, lng: 20.2861038 },
    { lat: 48.4288479, lng: 20.2864784 },
    { lat: 48.4288297, lng: 20.2867882 },
    { lat: 48.4289459, lng: 20.2871065 },
    { lat: 48.4288951, lng: 20.2874167 },
    { lat: 48.4289686, lng: 20.2877357 },
    { lat: 48.4288641, lng: 20.2878553 },
    { lat: 48.4287181, lng: 20.2882389 },
    { lat: 48.428345, lng: 20.2884555 },
    { lat: 48.4281709, lng: 20.288326 },
    { lat: 48.4280226, lng: 20.2881081 },
    { lat: 48.4278168, lng: 20.2881445 },
    { lat: 48.4255586, lng: 20.2891662 },
    { lat: 48.4247731, lng: 20.2894384 },
    { lat: 48.4247744, lng: 20.2880451 },
    { lat: 48.4236781, lng: 20.287237 },
    { lat: 48.4228023, lng: 20.285937 },
    { lat: 48.4221776, lng: 20.2840685 },
    { lat: 48.4221064, lng: 20.2840575 },
    { lat: 48.4219853, lng: 20.2841885 },
    { lat: 48.4213909, lng: 20.2841679 },
    { lat: 48.4213488, lng: 20.2844099 },
    { lat: 48.4212539, lng: 20.2845148 },
    { lat: 48.4211065, lng: 20.284406 },
    { lat: 48.4210636, lng: 20.2840365 },
    { lat: 48.42101, lng: 20.283987 },
    { lat: 48.420948, lng: 20.284036 },
    { lat: 48.420899, lng: 20.284126 },
    { lat: 48.420876, lng: 20.284423 },
    { lat: 48.420813, lng: 20.284478 },
    { lat: 48.420594, lng: 20.284656 },
    { lat: 48.420539, lng: 20.284639 },
    { lat: 48.420488, lng: 20.28456 },
    { lat: 48.42041, lng: 20.284665 },
    { lat: 48.420433, lng: 20.284744 },
    { lat: 48.420364, lng: 20.284773 },
    { lat: 48.420246, lng: 20.28475 },
    { lat: 48.420152, lng: 20.28487 },
    { lat: 48.420079, lng: 20.284936 },
    { lat: 48.419989, lng: 20.285014 },
    { lat: 48.419776, lng: 20.285191 },
    { lat: 48.419732, lng: 20.285226 },
    { lat: 48.419681, lng: 20.285354 },
    { lat: 48.419669, lng: 20.285385 },
    { lat: 48.419637, lng: 20.285463 },
    { lat: 48.419519, lng: 20.285527 },
    { lat: 48.419478, lng: 20.285548 },
    { lat: 48.419391, lng: 20.285499 },
    { lat: 48.419353, lng: 20.285482 },
    { lat: 48.419315, lng: 20.285491 },
    { lat: 48.419314, lng: 20.285563 },
    { lat: 48.419349, lng: 20.285679 },
    { lat: 48.419362, lng: 20.28569 },
    { lat: 48.419385, lng: 20.285712 },
    { lat: 48.419395, lng: 20.28572 },
    { lat: 48.419438, lng: 20.285767 },
    { lat: 48.41945, lng: 20.285827 },
    { lat: 48.419426, lng: 20.285881 },
    { lat: 48.419392, lng: 20.285931 },
    { lat: 48.419345, lng: 20.285942 },
    { lat: 48.419326, lng: 20.28594 },
    { lat: 48.419301, lng: 20.285937 },
    { lat: 48.419172, lng: 20.285894 },
    { lat: 48.419158, lng: 20.28589 },
    { lat: 48.419064, lng: 20.285858 },
    { lat: 48.419046, lng: 20.285852 },
    { lat: 48.419016, lng: 20.285842 },
    { lat: 48.418856, lng: 20.285738 },
    { lat: 48.418805, lng: 20.285723 },
    { lat: 48.418766, lng: 20.285726 },
    { lat: 48.418723, lng: 20.285739 },
    { lat: 48.418637, lng: 20.28579 },
    { lat: 48.418618, lng: 20.285802 },
    { lat: 48.41854, lng: 20.285849 },
    { lat: 48.418517, lng: 20.285862 },
    { lat: 48.418421, lng: 20.285919 },
    { lat: 48.418114, lng: 20.285885 },
    { lat: 48.418053, lng: 20.285887 },
    { lat: 48.417519, lng: 20.28585 },
    { lat: 48.41731, lng: 20.285836 },
    { lat: 48.417263, lng: 20.285978 },
    { lat: 48.417278, lng: 20.286113 },
    { lat: 48.417242, lng: 20.286178 },
    { lat: 48.417195, lng: 20.286189 },
    { lat: 48.417146, lng: 20.286162 },
    { lat: 48.41706, lng: 20.286011 },
    { lat: 48.416956, lng: 20.285981 },
    { lat: 48.416895, lng: 20.285964 },
    { lat: 48.416721, lng: 20.285797 },
    { lat: 48.416661, lng: 20.28566 },
    { lat: 48.416656, lng: 20.285649 },
    { lat: 48.416645, lng: 20.285621 },
    { lat: 48.416624, lng: 20.285604 },
    { lat: 48.416615, lng: 20.285597 },
    { lat: 48.416559, lng: 20.28555 },
    { lat: 48.416529, lng: 20.285345 },
    { lat: 48.416371, lng: 20.285412 },
    { lat: 48.416217, lng: 20.285343 },
    { lat: 48.416089, lng: 20.285345 },
    { lat: 48.415949, lng: 20.285428 },
    { lat: 48.415854, lng: 20.285322 },
    { lat: 48.415811, lng: 20.285335 },
    { lat: 48.415706, lng: 20.285586 },
    { lat: 48.415668, lng: 20.285634 },
    { lat: 48.415624, lng: 20.285644 },
    { lat: 48.41555, lng: 20.285629 },
    { lat: 48.415437, lng: 20.285747 },
    { lat: 48.415326, lng: 20.285747 },
    { lat: 48.415133, lng: 20.285816 },
    { lat: 48.414996, lng: 20.285819 },
    { lat: 48.414921, lng: 20.28587 },
    { lat: 48.414907, lng: 20.285915 },
    { lat: 48.41491, lng: 20.285962 },
    { lat: 48.414957, lng: 20.286054 },
    { lat: 48.414965, lng: 20.286068 },
    { lat: 48.414983, lng: 20.286106 },
    { lat: 48.414989, lng: 20.286116 },
    { lat: 48.415065, lng: 20.286265 },
    { lat: 48.415044, lng: 20.286433 },
    { lat: 48.414882, lng: 20.286468 },
    { lat: 48.414767, lng: 20.286485 },
    { lat: 48.414719, lng: 20.286458 },
    { lat: 48.41471, lng: 20.286208 },
    { lat: 48.414709, lng: 20.28619 },
    { lat: 48.414707, lng: 20.286147 },
    { lat: 48.4147, lng: 20.286131 },
    { lat: 48.414662, lng: 20.28606 },
    { lat: 48.414595, lng: 20.285977 },
    { lat: 48.414516, lng: 20.285917 },
    { lat: 48.414451, lng: 20.285894 },
    { lat: 48.414363, lng: 20.285897 },
    { lat: 48.414243, lng: 20.285922 },
    { lat: 48.414157, lng: 20.285965 },
    { lat: 48.414128, lng: 20.285998 },
    { lat: 48.414098, lng: 20.286059 },
    { lat: 48.41411, lng: 20.286291 },
    { lat: 48.414106, lng: 20.286308 },
    { lat: 48.414101, lng: 20.286328 },
    { lat: 48.414097, lng: 20.286345 },
    { lat: 48.41408, lng: 20.286377 },
    { lat: 48.414077, lng: 20.286384 },
    { lat: 48.414062, lng: 20.286398 },
    { lat: 48.41402, lng: 20.286436 },
    { lat: 48.413956, lng: 20.286463 },
    { lat: 48.413916, lng: 20.286446 },
    { lat: 48.413898, lng: 20.286438 },
    { lat: 48.413838, lng: 20.286413 },
    { lat: 48.413832, lng: 20.286407 },
    { lat: 48.413824, lng: 20.286397 },
    { lat: 48.413769, lng: 20.286337 },
    { lat: 48.413708, lng: 20.286255 },
    { lat: 48.413611, lng: 20.286256 },
    { lat: 48.413507, lng: 20.286338 },
    { lat: 48.413433, lng: 20.286439 },
    { lat: 48.413303, lng: 20.286465 },
    { lat: 48.413167, lng: 20.286471 },
    { lat: 48.413045, lng: 20.286454 },
    { lat: 48.413011, lng: 20.286397 },
    { lat: 48.412994, lng: 20.286302 },
    { lat: 48.413005, lng: 20.286128 },
    { lat: 48.412967, lng: 20.285987 },
    { lat: 48.412922, lng: 20.285899 },
    { lat: 48.412858, lng: 20.285806 },
    { lat: 48.412828, lng: 20.28577 },
    { lat: 48.412768, lng: 20.28573 },
    { lat: 48.412693, lng: 20.285726 },
    { lat: 48.412657, lng: 20.285814 },
    { lat: 48.41263, lng: 20.285947 },
    { lat: 48.412514, lng: 20.285959 },
    { lat: 48.412498, lng: 20.286005 },
    { lat: 48.412257, lng: 20.286894 },
    { lat: 48.412253, lng: 20.286906 },
    { lat: 48.41225, lng: 20.286917 },
    { lat: 48.412196, lng: 20.286937 },
    { lat: 48.412187, lng: 20.286941 },
    { lat: 48.412177, lng: 20.286945 },
    { lat: 48.4121579, lng: 20.2869823 },
    { lat: 48.41211, lng: 20.28697 },
    { lat: 48.412111, lng: 20.286996 },
    { lat: 48.412121, lng: 20.287177 },
    { lat: 48.411988, lng: 20.287243 },
    { lat: 48.411947, lng: 20.287249 },
    { lat: 48.411892, lng: 20.287258 },
    { lat: 48.411837, lng: 20.287266 },
    { lat: 48.41183, lng: 20.28726 },
    { lat: 48.41178, lng: 20.28722 },
    { lat: 48.411736, lng: 20.287184 },
    { lat: 48.411676, lng: 20.287161 },
    { lat: 48.411594, lng: 20.287128 },
    { lat: 48.41149, lng: 20.287183 },
    { lat: 48.411434, lng: 20.287212 },
    { lat: 48.411373, lng: 20.287242 },
    { lat: 48.411346, lng: 20.2873 },
    { lat: 48.411143, lng: 20.287336 },
    { lat: 48.411093, lng: 20.287202 },
    { lat: 48.411028, lng: 20.287102 },
    { lat: 48.410934, lng: 20.287043 },
    { lat: 48.410906, lng: 20.286993 },
    { lat: 48.410846, lng: 20.286887 },
    { lat: 48.410854, lng: 20.286861 },
    { lat: 48.410888, lng: 20.286755 },
    { lat: 48.410856, lng: 20.286604 },
    { lat: 48.410733, lng: 20.286521 },
    { lat: 48.410676, lng: 20.286439 },
    { lat: 48.410649, lng: 20.286478 },
    { lat: 48.410573, lng: 20.286345 },
    { lat: 48.410505, lng: 20.286275 },
    { lat: 48.410438, lng: 20.286254 },
    { lat: 48.410349, lng: 20.286265 },
    { lat: 48.410285, lng: 20.286319 },
    { lat: 48.41007, lng: 20.28652 },
    { lat: 48.410055, lng: 20.286533 },
    { lat: 48.410021, lng: 20.286565 },
    { lat: 48.410007, lng: 20.286578 },
    { lat: 48.409879, lng: 20.286622 },
    { lat: 48.409652, lng: 20.286636 },
    { lat: 48.409524, lng: 20.286787 },
    { lat: 48.409424, lng: 20.286799 },
    { lat: 48.409334, lng: 20.286848 },
    { lat: 48.409271, lng: 20.286835 },
    { lat: 48.409116, lng: 20.286524 },
    { lat: 48.409069, lng: 20.286125 },
    { lat: 48.409067, lng: 20.286109 },
    { lat: 48.409071, lng: 20.286068 },
    { lat: 48.409073, lng: 20.286052 },
    { lat: 48.409084, lng: 20.285953 },
    { lat: 48.409041, lng: 20.285815 },
    { lat: 48.408973, lng: 20.285739 },
    { lat: 48.40882, lng: 20.285735 },
    { lat: 48.408743, lng: 20.285637 },
    { lat: 48.408648, lng: 20.285608 },
    { lat: 48.408565, lng: 20.285567 },
    { lat: 48.408518, lng: 20.285517 },
    { lat: 48.408502, lng: 20.285412 },
    { lat: 48.408577, lng: 20.285277 },
    { lat: 48.408588, lng: 20.285257 },
    { lat: 48.408555, lng: 20.285047 },
    { lat: 48.408395, lng: 20.285024 },
    { lat: 48.408201, lng: 20.285112 },
    { lat: 48.408167, lng: 20.285121 },
    { lat: 48.408121, lng: 20.285134 },
    { lat: 48.408031, lng: 20.285089 },
    { lat: 48.407914, lng: 20.285141 },
    { lat: 48.407729, lng: 20.285351 },
    { lat: 48.407719, lng: 20.285364 },
    { lat: 48.407683, lng: 20.285404 },
    { lat: 48.407673, lng: 20.285416 },
    { lat: 48.407642, lng: 20.285451 },
    { lat: 48.407464, lng: 20.285448 },
    { lat: 48.407396, lng: 20.285587 },
    { lat: 48.407387, lng: 20.285605 },
    { lat: 48.407338, lng: 20.285707 },
    { lat: 48.407326, lng: 20.285733 },
    { lat: 48.407265, lng: 20.285817 },
    { lat: 48.407166, lng: 20.285957 },
    { lat: 48.40716, lng: 20.285962 },
    { lat: 48.407082, lng: 20.286015 },
    { lat: 48.40699, lng: 20.286015 },
    { lat: 48.40691, lng: 20.286016 },
    { lat: 48.406749, lng: 20.286017 },
    { lat: 48.406726, lng: 20.286018 },
    { lat: 48.406584, lng: 20.286003 },
    { lat: 48.406542, lng: 20.285999 },
    { lat: 48.406472, lng: 20.286341 },
    { lat: 48.406384, lng: 20.28668 },
    { lat: 48.406326, lng: 20.286934 },
    { lat: 48.406126, lng: 20.287841 },
    { lat: 48.406117, lng: 20.287875 },
    { lat: 48.406105, lng: 20.287922 },
    { lat: 48.406007, lng: 20.287843 },
    { lat: 48.40593, lng: 20.287781 },
    { lat: 48.405904, lng: 20.287768 },
    { lat: 48.405828, lng: 20.287781 },
    { lat: 48.405648, lng: 20.287923 },
    { lat: 48.405625, lng: 20.287932 },
    { lat: 48.405602, lng: 20.28792 },
    { lat: 48.405546, lng: 20.287844 },
    { lat: 48.405466, lng: 20.287695 },
    { lat: 48.405433, lng: 20.287427 },
    { lat: 48.405435, lng: 20.286931 },
    { lat: 48.405428, lng: 20.286881 },
    { lat: 48.405417, lng: 20.286848 },
    { lat: 48.405392, lng: 20.286815 },
    { lat: 48.405354, lng: 20.286779 },
    { lat: 48.405329, lng: 20.286757 },
    { lat: 48.405306, lng: 20.286748 },
    { lat: 48.405281, lng: 20.28674 },
    { lat: 48.405227, lng: 20.286754 },
    { lat: 48.405201, lng: 20.286773 },
    { lat: 48.405158, lng: 20.28682 },
    { lat: 48.405045, lng: 20.286946 },
    { lat: 48.404939, lng: 20.287065 },
    { lat: 48.404917, lng: 20.287074 },
    { lat: 48.404814, lng: 20.287076 },
    { lat: 48.404787, lng: 20.287077 },
    { lat: 48.40475, lng: 20.287051 },
    { lat: 48.404697, lng: 20.286954 },
    { lat: 48.404675, lng: 20.286839 },
    { lat: 48.404685, lng: 20.286625 },
    { lat: 48.404692, lng: 20.286414 },
    { lat: 48.404688, lng: 20.286352 },
    { lat: 48.404668, lng: 20.286267 },
    { lat: 48.404635, lng: 20.286207 },
    { lat: 48.404577, lng: 20.286143 },
    { lat: 48.404469, lng: 20.286038 },
    { lat: 48.404424, lng: 20.286007 },
    { lat: 48.404327, lng: 20.285922 },
    { lat: 48.404271, lng: 20.285856 },
    { lat: 48.404221, lng: 20.285775 },
    { lat: 48.404191, lng: 20.285691 },
    { lat: 48.404177, lng: 20.285592 },
    { lat: 48.404162, lng: 20.285332 },
    { lat: 48.404156, lng: 20.285265 },
    { lat: 48.40415, lng: 20.285223 },
    { lat: 48.404137, lng: 20.285166 },
    { lat: 48.404089, lng: 20.285076 },
    { lat: 48.40404, lng: 20.285047 },
    { lat: 48.403974, lng: 20.285023 },
    { lat: 48.403948, lng: 20.285015 },
    { lat: 48.403868, lng: 20.284941 },
    { lat: 48.403836, lng: 20.284872 },
    { lat: 48.403784, lng: 20.28467 },
    { lat: 48.403706, lng: 20.28448 },
    { lat: 48.403614, lng: 20.284238 },
    { lat: 48.403575, lng: 20.284091 },
    { lat: 48.403519, lng: 20.283848 },
    { lat: 48.403499, lng: 20.283791 },
    { lat: 48.403468, lng: 20.283778 },
    { lat: 48.403293, lng: 20.28371 },
    { lat: 48.403104, lng: 20.283608 },
    { lat: 48.40303, lng: 20.283571 },
    { lat: 48.402987, lng: 20.283591 },
    { lat: 48.402911, lng: 20.283803 },
    { lat: 48.402014, lng: 20.289321 },
    { lat: 48.401188, lng: 20.294396 },
    { lat: 48.400394, lng: 20.298834 },
    { lat: 48.400122, lng: 20.29957 },
    { lat: 48.399936, lng: 20.300072 },
    { lat: 48.399836, lng: 20.300341 },
    { lat: 48.399674, lng: 20.300782 },
    { lat: 48.399467, lng: 20.30134 },
    { lat: 48.399344, lng: 20.301673 },
    { lat: 48.399345, lng: 20.301686 },
    { lat: 48.399352, lng: 20.301829 },
    { lat: 48.399356, lng: 20.301912 },
    { lat: 48.399368, lng: 20.302144 },
    { lat: 48.399397, lng: 20.302712 },
    { lat: 48.399438, lng: 20.303509 },
    { lat: 48.399462, lng: 20.303992 },
    { lat: 48.399476, lng: 20.304248 },
    { lat: 48.399477, lng: 20.304276 },
    { lat: 48.399477, lng: 20.304278 },
    { lat: 48.399478, lng: 20.304358 },
    { lat: 48.399499, lng: 20.306808 },
    { lat: 48.399521, lng: 20.309392 },
    { lat: 48.399613, lng: 20.312715 },
    { lat: 48.399616, lng: 20.31283 },
    { lat: 48.399689, lng: 20.316092 },
    { lat: 48.399736, lng: 20.318582 },
    { lat: 48.399746, lng: 20.319136 },
    { lat: 48.399756, lng: 20.319412 },
    { lat: 48.398504, lng: 20.319473 },
    { lat: 48.397615, lng: 20.319516 },
    { lat: 48.39765, lng: 20.320652 },
    { lat: 48.398179, lng: 20.322323 },
    { lat: 48.398198, lng: 20.322399 },
    { lat: 48.398221, lng: 20.324835 },
    { lat: 48.398453, lng: 20.326039 },
    { lat: 48.398438, lng: 20.326459 },
    { lat: 48.398445, lng: 20.326691 },
    { lat: 48.398998, lng: 20.328569 },
    { lat: 48.398966, lng: 20.330893 },
    { lat: 48.398906, lng: 20.334661 },
    { lat: 48.398949, lng: 20.33509 },
    { lat: 48.398857, lng: 20.337214 },
    { lat: 48.398736, lng: 20.337916 },
    { lat: 48.398781, lng: 20.339068 },
    { lat: 48.398919, lng: 20.340507 },
    { lat: 48.399631, lng: 20.345733 },
    { lat: 48.399854, lng: 20.347364 },
    { lat: 48.400648, lng: 20.355942 },
    { lat: 48.40099, lng: 20.359026 },
    { lat: 48.402698, lng: 20.359865 },
    { lat: 48.403254, lng: 20.360138 },
    { lat: 48.403075, lng: 20.360775 },
    { lat: 48.402665, lng: 20.362128 },
    { lat: 48.403648, lng: 20.364074 },
    { lat: 48.404048, lng: 20.364773 },
    { lat: 48.404073, lng: 20.364817 },
    { lat: 48.404825, lng: 20.366133 },
    { lat: 48.404855, lng: 20.366186 },
    { lat: 48.405315, lng: 20.366991 },
    { lat: 48.405143, lng: 20.367359 },
    { lat: 48.404973, lng: 20.367656 },
    { lat: 48.405366, lng: 20.368151 },
    { lat: 48.406124, lng: 20.368678 },
    { lat: 48.406276, lng: 20.368734 },
    { lat: 48.406823, lng: 20.368896 },
    { lat: 48.407101, lng: 20.369115 },
    { lat: 48.407962, lng: 20.370033 },
    { lat: 48.408405, lng: 20.370483 },
    { lat: 48.409001, lng: 20.370972 },
    { lat: 48.409648, lng: 20.371691 },
    { lat: 48.409951, lng: 20.372009 },
    { lat: 48.410317, lng: 20.372359 },
    { lat: 48.410728, lng: 20.372612 },
    { lat: 48.411532, lng: 20.372311 },
    { lat: 48.412117, lng: 20.372001 },
    { lat: 48.412389, lng: 20.371807 },
    { lat: 48.413616, lng: 20.371453 },
    { lat: 48.414306, lng: 20.371436 },
    { lat: 48.415001, lng: 20.371376 },
    { lat: 48.415984, lng: 20.371171 },
    { lat: 48.416491, lng: 20.371212 },
    { lat: 48.417149, lng: 20.372037 },
    { lat: 48.417709, lng: 20.373536 },
    { lat: 48.41794, lng: 20.373368 },
    { lat: 48.418155, lng: 20.374225 },
    { lat: 48.418178, lng: 20.374671 },
    { lat: 48.417686, lng: 20.37521 },
    { lat: 48.41757, lng: 20.375585 },
    { lat: 48.417638, lng: 20.376065 },
    { lat: 48.41786, lng: 20.376825 },
    { lat: 48.418397, lng: 20.378197 },
    { lat: 48.418521, lng: 20.379123 },
    { lat: 48.41864, lng: 20.379956 },
    { lat: 48.419502, lng: 20.381069 },
    { lat: 48.420143, lng: 20.382048 },
    { lat: 48.420556, lng: 20.382432 },
    { lat: 48.421143, lng: 20.383064 },
    { lat: 48.4214, lng: 20.383728 },
    { lat: 48.422732, lng: 20.385203 },
    { lat: 48.423164, lng: 20.386695 },
    { lat: 48.423394, lng: 20.388414 },
    { lat: 48.423718, lng: 20.390351 },
    { lat: 48.424038, lng: 20.391728 },
    { lat: 48.424118, lng: 20.392322 },
    { lat: 48.424251, lng: 20.39283 },
    { lat: 48.424538, lng: 20.394488 },
    { lat: 48.424689, lng: 20.39511 },
    { lat: 48.424761, lng: 20.395811 },
    { lat: 48.424964, lng: 20.397134 },
    { lat: 48.424926, lng: 20.397988 },
    { lat: 48.424875, lng: 20.398885 },
    { lat: 48.424677, lng: 20.400078 },
    { lat: 48.424514, lng: 20.401029 },
    { lat: 48.424376, lng: 20.401913 },
    { lat: 48.424086, lng: 20.402478 },
    { lat: 48.423932, lng: 20.402932 },
    { lat: 48.423864, lng: 20.403179 },
    { lat: 48.423832, lng: 20.403298 },
    { lat: 48.42334, lng: 20.403919 },
    { lat: 48.422744, lng: 20.404294 },
    { lat: 48.422144, lng: 20.404715 },
    { lat: 48.42151, lng: 20.404997 },
    { lat: 48.420916, lng: 20.405349 },
    { lat: 48.420439, lng: 20.405946 },
    { lat: 48.419821, lng: 20.406656 },
    { lat: 48.419387, lng: 20.407346 },
    { lat: 48.419332, lng: 20.407643 },
    { lat: 48.419326, lng: 20.40821 },
    { lat: 48.419373, lng: 20.408674 },
    { lat: 48.419458, lng: 20.409574 },
    { lat: 48.419647, lng: 20.410493 },
    { lat: 48.419671, lng: 20.410976 },
    { lat: 48.419612, lng: 20.41147 },
    { lat: 48.419525, lng: 20.412284 },
    { lat: 48.419591, lng: 20.413324 },
    { lat: 48.419608, lng: 20.41416 },
    { lat: 48.419527, lng: 20.414713 },
    { lat: 48.419329, lng: 20.415581 },
    { lat: 48.419339, lng: 20.41569 },
    { lat: 48.41944, lng: 20.416372 },
    { lat: 48.419563, lng: 20.416941 },
    { lat: 48.419865, lng: 20.417477 },
    { lat: 48.4200456, lng: 20.4180413 },
    { lat: 48.4208942, lng: 20.4190964 },
    { lat: 48.4211725, lng: 20.4194497 },
    { lat: 48.4216248, lng: 20.4200155 },
    { lat: 48.4218218, lng: 20.4202851 },
    { lat: 48.4219277, lng: 20.4205593 },
    { lat: 48.4222848, lng: 20.4215047 },
    { lat: 48.4224935, lng: 20.4220305 },
    { lat: 48.4228633, lng: 20.4229408 },
    { lat: 48.4229268, lng: 20.423506 },
    { lat: 48.423051, lng: 20.42379 },
    { lat: 48.4231563, lng: 20.4240262 },
    { lat: 48.4232841, lng: 20.4243242 },
    { lat: 48.4234098, lng: 20.4246152 },
    { lat: 48.4235275, lng: 20.424793 },
    { lat: 48.4238116, lng: 20.4252427 },
    { lat: 48.4240707, lng: 20.4256417 },
    { lat: 48.4243022, lng: 20.4260026 },
    { lat: 48.4246191, lng: 20.4264964 },
    { lat: 48.4247862, lng: 20.4267494 },
    { lat: 48.4250216, lng: 20.4270984 },
  ],
  GemerskýSad: [
    { lat: 48.598839, lng: 20.355987 },
    { lat: 48.598849, lng: 20.355494 },
    { lat: 48.598831, lng: 20.355075 },
    { lat: 48.59883, lng: 20.354981 },
    { lat: 48.598829, lng: 20.354875 },
    { lat: 48.5987, lng: 20.354127 },
    { lat: 48.598713, lng: 20.354114 },
    { lat: 48.598597, lng: 20.353485 },
    { lat: 48.598673, lng: 20.352756 },
    { lat: 48.598712, lng: 20.352404 },
    { lat: 48.598951, lng: 20.351821 },
    { lat: 48.599026, lng: 20.351467 },
    { lat: 48.599079, lng: 20.351049 },
    { lat: 48.599146, lng: 20.350758 },
    { lat: 48.59925, lng: 20.350441 },
    { lat: 48.599332, lng: 20.349894 },
    { lat: 48.599312, lng: 20.34989 },
    { lat: 48.599422, lng: 20.349131 },
    { lat: 48.599469, lng: 20.348795 },
    { lat: 48.599451, lng: 20.34838 },
    { lat: 48.59948, lng: 20.348017 },
    { lat: 48.599551, lng: 20.347538 },
    { lat: 48.599784, lng: 20.346758 },
    { lat: 48.599874, lng: 20.346523 },
    { lat: 48.599907, lng: 20.346362 },
    { lat: 48.60001, lng: 20.34606 },
    { lat: 48.60011, lng: 20.345852 },
    { lat: 48.600177, lng: 20.345559 },
    { lat: 48.600267, lng: 20.345252 },
    { lat: 48.600366, lng: 20.344557 },
    { lat: 48.600375, lng: 20.344405 },
    { lat: 48.600403, lng: 20.344414 },
    { lat: 48.600216, lng: 20.343975 },
    { lat: 48.600135, lng: 20.343572 },
    { lat: 48.600116, lng: 20.343299 },
    { lat: 48.600105, lng: 20.343046 },
    { lat: 48.600119, lng: 20.342552 },
    { lat: 48.600129, lng: 20.34249 },
    { lat: 48.600169, lng: 20.342431 },
    { lat: 48.600276, lng: 20.342291 },
    { lat: 48.600466, lng: 20.342068 },
    { lat: 48.601221, lng: 20.341249 },
    { lat: 48.601283, lng: 20.341123 },
    { lat: 48.601318, lng: 20.34103 },
    { lat: 48.601352, lng: 20.340883 },
    { lat: 48.601373, lng: 20.340834 },
    { lat: 48.601508, lng: 20.340328 },
    { lat: 48.601579, lng: 20.340083 },
    { lat: 48.601859, lng: 20.339758 },
    { lat: 48.602154, lng: 20.339265 },
    { lat: 48.602476, lng: 20.339056 },
    { lat: 48.60279, lng: 20.338781 },
    { lat: 48.602801, lng: 20.338801 },
    { lat: 48.603104, lng: 20.338475 },
    { lat: 48.603819, lng: 20.338325 },
    { lat: 48.604216, lng: 20.338253 },
    { lat: 48.604265, lng: 20.338258 },
    { lat: 48.604579, lng: 20.338375 },
    { lat: 48.604665, lng: 20.338338 },
    { lat: 48.604899, lng: 20.338215 },
    { lat: 48.605399, lng: 20.337954 },
    { lat: 48.605594, lng: 20.337991 },
    { lat: 48.605761, lng: 20.337974 },
    { lat: 48.606095, lng: 20.337947 },
    { lat: 48.606119, lng: 20.337937 },
    { lat: 48.606287, lng: 20.337847 },
    { lat: 48.606477, lng: 20.337622 },
    { lat: 48.606585, lng: 20.337486 },
    { lat: 48.606616, lng: 20.337416 },
    { lat: 48.606778, lng: 20.337248 },
    { lat: 48.606864, lng: 20.337159 },
    { lat: 48.606968, lng: 20.33705 },
    { lat: 48.606989, lng: 20.337022 },
    { lat: 48.607001, lng: 20.337042 },
    { lat: 48.60718, lng: 20.337002 },
    { lat: 48.607204, lng: 20.33699 },
    { lat: 48.607338, lng: 20.336527 },
    { lat: 48.607663, lng: 20.336131 },
    { lat: 48.607721, lng: 20.335833 },
    { lat: 48.60786, lng: 20.335582 },
    { lat: 48.608012, lng: 20.335332 },
    { lat: 48.60829, lng: 20.334843 },
    { lat: 48.608517, lng: 20.33431 },
    { lat: 48.608544, lng: 20.334237 },
    { lat: 48.608683, lng: 20.333732 },
    { lat: 48.608707, lng: 20.333372 },
    { lat: 48.608691, lng: 20.33317 },
    { lat: 48.608735, lng: 20.333079 },
    { lat: 48.608809, lng: 20.332873 },
    { lat: 48.608808, lng: 20.332699 },
    { lat: 48.608806, lng: 20.332444 },
    { lat: 48.608671, lng: 20.332016 },
    { lat: 48.608529, lng: 20.331554 },
    { lat: 48.608396, lng: 20.33109 },
    { lat: 48.608204, lng: 20.33069 },
    { lat: 48.608044, lng: 20.330346 },
    { lat: 48.607908, lng: 20.329901 },
    { lat: 48.607798, lng: 20.329682 },
    { lat: 48.607499, lng: 20.329105 },
    { lat: 48.607177, lng: 20.328531 },
    { lat: 48.607085, lng: 20.328377 },
    { lat: 48.606933, lng: 20.328163 },
    { lat: 48.60681, lng: 20.327999 },
    { lat: 48.606691, lng: 20.32776 },
    { lat: 48.606544, lng: 20.327474 },
    { lat: 48.606306, lng: 20.326997 },
    { lat: 48.606208, lng: 20.326512 },
    { lat: 48.606255, lng: 20.326319 },
    { lat: 48.606298, lng: 20.326164 },
    { lat: 48.606379, lng: 20.32586 },
    { lat: 48.606558, lng: 20.325439 },
    { lat: 48.606633, lng: 20.325253 },
    { lat: 48.606639, lng: 20.325192 },
    { lat: 48.606637, lng: 20.325127 },
    { lat: 48.606607, lng: 20.325048 },
    { lat: 48.606465, lng: 20.324822 },
    { lat: 48.606249, lng: 20.324606 },
    { lat: 48.606027, lng: 20.324114 },
    { lat: 48.605909, lng: 20.323865 },
    { lat: 48.605872, lng: 20.3238 },
    { lat: 48.605763, lng: 20.323703 },
    { lat: 48.605588, lng: 20.323565 },
    { lat: 48.605449, lng: 20.323454 },
    { lat: 48.605409, lng: 20.32312 },
    { lat: 48.605364, lng: 20.322721 },
    { lat: 48.605346, lng: 20.32253 },
    { lat: 48.605312, lng: 20.322396 },
    { lat: 48.605157, lng: 20.321824 },
    { lat: 48.605079, lng: 20.321528 },
    { lat: 48.604992, lng: 20.321201 },
    { lat: 48.604974, lng: 20.32121 },
    { lat: 48.604978, lng: 20.321185 },
    { lat: 48.604979, lng: 20.321168 },
    { lat: 48.604985, lng: 20.321114 },
    { lat: 48.605055, lng: 20.320355 },
    { lat: 48.605063, lng: 20.320257 },
    { lat: 48.605067, lng: 20.320212 },
    { lat: 48.605305, lng: 20.317625 },
    { lat: 48.60535, lng: 20.317578 },
    { lat: 48.60572, lng: 20.31718 },
    { lat: 48.606, lng: 20.316882 },
    { lat: 48.606363, lng: 20.316495 },
    { lat: 48.606387, lng: 20.316443 },
    { lat: 48.6000353, lng: 20.3100908 },
    { lat: 48.5983677, lng: 20.3083178 },
    { lat: 48.5973829, lng: 20.3070952 },
    { lat: 48.5964885, lng: 20.3058558 },
    { lat: 48.5960142, lng: 20.3049012 },
    { lat: 48.5954537, lng: 20.3034505 },
    { lat: 48.5954259, lng: 20.30349 },
    { lat: 48.5947452, lng: 20.3044786 },
    { lat: 48.594339, lng: 20.3033102 },
    { lat: 48.5933479, lng: 20.3038274 },
    { lat: 48.5918183, lng: 20.301872 },
    { lat: 48.59007, lng: 20.3042361 },
    { lat: 48.5893907, lng: 20.3053806 },
    { lat: 48.5887554, lng: 20.3043157 },
    { lat: 48.588221, lng: 20.3046426 },
    { lat: 48.587888, lng: 20.3049668 },
    { lat: 48.5847539, lng: 20.3071215 },
    { lat: 48.5791987, lng: 20.3111138 },
    { lat: 48.5792202, lng: 20.3111436 },
    { lat: 48.5770208, lng: 20.3126656 },
    { lat: 48.5758814, lng: 20.3134477 },
    { lat: 48.5752688, lng: 20.3138824 },
    { lat: 48.5745279, lng: 20.311645 },
    { lat: 48.5731728, lng: 20.3126419 },
    { lat: 48.5720898, lng: 20.3146859 },
    { lat: 48.5708565, lng: 20.3177354 },
    { lat: 48.5699482, lng: 20.3192114 },
    { lat: 48.5695134, lng: 20.3204203 },
    { lat: 48.5691619, lng: 20.321135 },
    { lat: 48.5686546, lng: 20.3218119 },
    { lat: 48.5681445, lng: 20.322301 },
    { lat: 48.567605, lng: 20.3243714 },
    { lat: 48.5665388, lng: 20.3298826 },
    { lat: 48.5667647, lng: 20.3309357 },
    { lat: 48.5667576, lng: 20.3314188 },
    { lat: 48.5665187, lng: 20.3321347 },
    { lat: 48.5666099, lng: 20.332682 },
    { lat: 48.566604, lng: 20.3330499 },
    { lat: 48.5667632, lng: 20.333664 },
    { lat: 48.5669195, lng: 20.3350465 },
    { lat: 48.5668886, lng: 20.3357845 },
    { lat: 48.5672011, lng: 20.3368377 },
    { lat: 48.5672433, lng: 20.3378068 },
    { lat: 48.5675446, lng: 20.3385115 },
    { lat: 48.567891, lng: 20.340043 },
    { lat: 48.568077, lng: 20.340065 },
    { lat: 48.568583, lng: 20.340337 },
    { lat: 48.569329, lng: 20.340882 },
    { lat: 48.5694, lng: 20.340936 },
    { lat: 48.56965, lng: 20.341131 },
    { lat: 48.569953, lng: 20.341531 },
    { lat: 48.570429, lng: 20.342198 },
    { lat: 48.570718, lng: 20.342756 },
    { lat: 48.570813, lng: 20.342956 },
    { lat: 48.570971, lng: 20.343353 },
    { lat: 48.571254, lng: 20.344029 },
    { lat: 48.571312, lng: 20.344138 },
    { lat: 48.571658, lng: 20.344756 },
    { lat: 48.572052, lng: 20.34591 },
    { lat: 48.572206, lng: 20.346264 },
    { lat: 48.57238, lng: 20.346488 },
    { lat: 48.572786, lng: 20.346766 },
    { lat: 48.573257, lng: 20.347494 },
    { lat: 48.57355, lng: 20.348281 },
    { lat: 48.573629, lng: 20.34866 },
    { lat: 48.573738, lng: 20.349059 },
    { lat: 48.573764, lng: 20.349034 },
    { lat: 48.574049, lng: 20.349426 },
    { lat: 48.574278, lng: 20.349882 },
    { lat: 48.574498, lng: 20.350239 },
    { lat: 48.574772, lng: 20.350499 },
    { lat: 48.574794, lng: 20.35052 },
    { lat: 48.57541, lng: 20.350304 },
    { lat: 48.575742, lng: 20.350244 },
    { lat: 48.576038, lng: 20.350234 },
    { lat: 48.576308, lng: 20.350112 },
    { lat: 48.576927, lng: 20.349461 },
    { lat: 48.57717, lng: 20.349127 },
    { lat: 48.577499, lng: 20.348598 },
    { lat: 48.577772, lng: 20.348345 },
    { lat: 48.578012, lng: 20.348228 },
    { lat: 48.578674, lng: 20.347619 },
    { lat: 48.578846, lng: 20.347618 },
    { lat: 48.579215, lng: 20.34769 },
    { lat: 48.579446, lng: 20.347936 },
    { lat: 48.579635, lng: 20.348324 },
    { lat: 48.579809, lng: 20.348818 },
    { lat: 48.58015, lng: 20.349324 },
    { lat: 48.580492, lng: 20.349468 },
    { lat: 48.580612, lng: 20.349394 },
    { lat: 48.580715, lng: 20.349364 },
    { lat: 48.58134, lng: 20.349406 },
    { lat: 48.582035, lng: 20.349298 },
    { lat: 48.582102, lng: 20.348925 },
    { lat: 48.582112, lng: 20.348484 },
    { lat: 48.582124, lng: 20.34799 },
    { lat: 48.582889, lng: 20.347806 },
    { lat: 48.583006, lng: 20.347725 },
    { lat: 48.583192, lng: 20.347672 },
    { lat: 48.583553, lng: 20.347691 },
    { lat: 48.583883, lng: 20.347749 },
    { lat: 48.584091, lng: 20.347769 },
    { lat: 48.58435, lng: 20.347857 },
    { lat: 48.584402, lng: 20.347892 },
    { lat: 48.584559, lng: 20.348055 },
    { lat: 48.584785, lng: 20.348269 },
    { lat: 48.584853, lng: 20.348303 },
    { lat: 48.585087, lng: 20.348425 },
    { lat: 48.585396, lng: 20.348806 },
    { lat: 48.585602, lng: 20.349002 },
    { lat: 48.586091, lng: 20.34947 },
    { lat: 48.586286, lng: 20.349602 },
    { lat: 48.586464, lng: 20.349774 },
    { lat: 48.586685, lng: 20.349912 },
    { lat: 48.586838, lng: 20.350092 },
    { lat: 48.587367, lng: 20.35089 },
    { lat: 48.588037, lng: 20.35186 },
    { lat: 48.588463, lng: 20.351704 },
    { lat: 48.588591, lng: 20.351706 },
    { lat: 48.589059, lng: 20.351894 },
    { lat: 48.589768, lng: 20.35212 },
    { lat: 48.59054, lng: 20.352495 },
    { lat: 48.591706, lng: 20.352796 },
    { lat: 48.592338, lng: 20.353091 },
    { lat: 48.592651, lng: 20.353677 },
    { lat: 48.592849, lng: 20.353948 },
    { lat: 48.59324, lng: 20.354272 },
    { lat: 48.593885, lng: 20.354846 },
    { lat: 48.594933, lng: 20.355351 },
    { lat: 48.595288, lng: 20.35537 },
    { lat: 48.595593, lng: 20.355292 },
    { lat: 48.595948, lng: 20.355352 },
    { lat: 48.5963, lng: 20.355425 },
    { lat: 48.596543, lng: 20.355462 },
    { lat: 48.596821, lng: 20.355578 },
    { lat: 48.597334, lng: 20.355618 },
    { lat: 48.597444, lng: 20.355653 },
    { lat: 48.597899, lng: 20.355609 },
    { lat: 48.598839, lng: 20.355987 },
  ],
  Hucín: [
    { lat: 48.5770208, lng: 20.3126656 },
    { lat: 48.5792202, lng: 20.3111436 },
    { lat: 48.5791987, lng: 20.3111138 },
    { lat: 48.5847539, lng: 20.3071215 },
    { lat: 48.587888, lng: 20.3049668 },
    { lat: 48.588221, lng: 20.3046426 },
    { lat: 48.5887554, lng: 20.3043157 },
    { lat: 48.5893907, lng: 20.3053806 },
    { lat: 48.59007, lng: 20.3042361 },
    { lat: 48.5918183, lng: 20.301872 },
    { lat: 48.5933479, lng: 20.3038274 },
    { lat: 48.594339, lng: 20.3033102 },
    { lat: 48.5947452, lng: 20.3044786 },
    { lat: 48.5954259, lng: 20.30349 },
    { lat: 48.5954537, lng: 20.3034505 },
    { lat: 48.595284, lng: 20.3031747 },
    { lat: 48.5937834, lng: 20.300005 },
    { lat: 48.5926013, lng: 20.2980632 },
    { lat: 48.5922622, lng: 20.2976029 },
    { lat: 48.5916728, lng: 20.2965905 },
    { lat: 48.5909866, lng: 20.2955793 },
    { lat: 48.5897104, lng: 20.294115 },
    { lat: 48.5895312, lng: 20.2938254 },
    { lat: 48.5889668, lng: 20.2927916 },
    { lat: 48.5888905, lng: 20.2924826 },
    { lat: 48.5887355, lng: 20.2913201 },
    { lat: 48.5887536, lng: 20.2908635 },
    { lat: 48.5886917, lng: 20.2909217 },
    { lat: 48.5886651, lng: 20.2908227 },
    { lat: 48.5884205, lng: 20.2910157 },
    { lat: 48.5869449, lng: 20.2882027 },
    { lat: 48.5864588, lng: 20.2862767 },
    { lat: 48.5859664, lng: 20.2838117 },
    { lat: 48.5858301, lng: 20.2838943 },
    { lat: 48.5851703, lng: 20.281328 },
    { lat: 48.5857052, lng: 20.2800454 },
    { lat: 48.5854459, lng: 20.2800349 },
    { lat: 48.584704, lng: 20.2797034 },
    { lat: 48.58399, lng: 20.2791908 },
    { lat: 48.5834723, lng: 20.2789118 },
    { lat: 48.583278, lng: 20.2788695 },
    { lat: 48.5817162, lng: 20.2790476 },
    { lat: 48.5810557, lng: 20.2790019 },
    { lat: 48.5803807, lng: 20.2785927 },
    { lat: 48.5798433, lng: 20.2780718 },
    { lat: 48.5789403, lng: 20.2775685 },
    { lat: 48.5784433, lng: 20.2776148 },
    { lat: 48.5772736, lng: 20.2782824 },
    { lat: 48.5769338, lng: 20.2783179 },
    { lat: 48.5766086, lng: 20.2781795 },
    { lat: 48.5750758, lng: 20.2772416 },
    { lat: 48.5748058, lng: 20.2771648 },
    { lat: 48.5746084, lng: 20.2772085 },
    { lat: 48.5741115, lng: 20.2775764 },
    { lat: 48.5740678, lng: 20.2776333 },
    { lat: 48.5741124, lng: 20.2777655 },
    { lat: 48.5740313, lng: 20.2778261 },
    { lat: 48.5739978, lng: 20.2777448 },
    { lat: 48.5733254, lng: 20.2788844 },
    { lat: 48.572884, lng: 20.2794834 },
    { lat: 48.572668, lng: 20.2796632 },
    { lat: 48.5722157, lng: 20.2799267 },
    { lat: 48.5706821, lng: 20.2804497 },
    { lat: 48.5701552, lng: 20.2808226 },
    { lat: 48.5698542, lng: 20.2811597 },
    { lat: 48.5694012, lng: 20.2819524 },
    { lat: 48.5685853, lng: 20.2846476 },
    { lat: 48.5684593, lng: 20.2849337 },
    { lat: 48.5680455, lng: 20.2854636 },
    { lat: 48.5673551, lng: 20.2860951 },
    { lat: 48.5669501, lng: 20.2865612 },
    { lat: 48.5664454, lng: 20.287528 },
    { lat: 48.566082, lng: 20.2885394 },
    { lat: 48.5658527, lng: 20.2890609 },
    { lat: 48.565518, lng: 20.2896281 },
    { lat: 48.5655173, lng: 20.2897558 },
    { lat: 48.565334, lng: 20.2898156 },
    { lat: 48.5650962, lng: 20.290154 },
    { lat: 48.5647258, lng: 20.290558 },
    { lat: 48.5646707, lng: 20.2905317 },
    { lat: 48.5645786, lng: 20.2906793 },
    { lat: 48.5625593, lng: 20.29162 },
    { lat: 48.5623391, lng: 20.2917833 },
    { lat: 48.5620046, lng: 20.2921219 },
    { lat: 48.5618033, lng: 20.2924438 },
    { lat: 48.5610953, lng: 20.2939118 },
    { lat: 48.5606232, lng: 20.2945093 },
    { lat: 48.5601358, lng: 20.2948 },
    { lat: 48.5593499, lng: 20.294744 },
    { lat: 48.5590364, lng: 20.2945918 },
    { lat: 48.5575749, lng: 20.2935968 },
    { lat: 48.5570649, lng: 20.293459 },
    { lat: 48.5566483, lng: 20.2935474 },
    { lat: 48.5557829, lng: 20.2941327 },
    { lat: 48.5551635, lng: 20.2943619 },
    { lat: 48.5548638, lng: 20.2949916 },
    { lat: 48.5544761, lng: 20.2952093 },
    { lat: 48.5545434, lng: 20.2964635 },
    { lat: 48.5541831, lng: 20.2965486 },
    { lat: 48.5540006, lng: 20.2966673 },
    { lat: 48.5539004, lng: 20.2967758 },
    { lat: 48.5538441, lng: 20.2969539 },
    { lat: 48.5539604, lng: 20.2971863 },
    { lat: 48.5540807, lng: 20.297209 },
    { lat: 48.5542283, lng: 20.2969523 },
    { lat: 48.5542738, lng: 20.2969636 },
    { lat: 48.5543818, lng: 20.2971889 },
    { lat: 48.5542893, lng: 20.2974168 },
    { lat: 48.5541081, lng: 20.2975094 },
    { lat: 48.5540837, lng: 20.2976023 },
    { lat: 48.5541417, lng: 20.2977034 },
    { lat: 48.554079, lng: 20.2978256 },
    { lat: 48.5539374, lng: 20.2978055 },
    { lat: 48.5538363, lng: 20.2976133 },
    { lat: 48.5536932, lng: 20.2976101 },
    { lat: 48.5536556, lng: 20.2977393 },
    { lat: 48.5537016, lng: 20.297877 },
    { lat: 48.5535888, lng: 20.2979909 },
    { lat: 48.553519, lng: 20.2982758 },
    { lat: 48.5534255, lng: 20.2983638 },
    { lat: 48.5532065, lng: 20.2983457 },
    { lat: 48.5529405, lng: 20.2980267 },
    { lat: 48.5529155, lng: 20.2978765 },
    { lat: 48.55299, lng: 20.2978282 },
    { lat: 48.5530504, lng: 20.2976696 },
    { lat: 48.5529906, lng: 20.2975847 },
    { lat: 48.5529215, lng: 20.2976566 },
    { lat: 48.5526984, lng: 20.297625 },
    { lat: 48.5526543, lng: 20.2978241 },
    { lat: 48.5526535, lng: 20.2983737 },
    { lat: 48.5521842, lng: 20.2986698 },
    { lat: 48.5521939, lng: 20.2988331 },
    { lat: 48.5523328, lng: 20.2989263 },
    { lat: 48.5525198, lng: 20.2988972 },
    { lat: 48.5525209, lng: 20.2990403 },
    { lat: 48.5523842, lng: 20.2993437 },
    { lat: 48.5520196, lng: 20.2994093 },
    { lat: 48.5515185, lng: 20.2992389 },
    { lat: 48.5512208, lng: 20.299285 },
    { lat: 48.5511323, lng: 20.2993264 },
    { lat: 48.5511152, lng: 20.2994626 },
    { lat: 48.5510465, lng: 20.2995512 },
    { lat: 48.5506459, lng: 20.2993559 },
    { lat: 48.550499, lng: 20.299546 },
    { lat: 48.55029, lng: 20.3002447 },
    { lat: 48.5501682, lng: 20.3004119 },
    { lat: 48.5499325, lng: 20.3004902 },
    { lat: 48.5496059, lng: 20.3000032 },
    { lat: 48.5494731, lng: 20.3002035 },
    { lat: 48.549305, lng: 20.3003397 },
    { lat: 48.5491905, lng: 20.3003509 },
    { lat: 48.5490271, lng: 20.3000307 },
    { lat: 48.5488777, lng: 20.2998972 },
    { lat: 48.5488698, lng: 20.2997273 },
    { lat: 48.5487781, lng: 20.2997419 },
    { lat: 48.5486882, lng: 20.3001796 },
    { lat: 48.5484273, lng: 20.3006742 },
    { lat: 48.5485239, lng: 20.3008696 },
    { lat: 48.5484208, lng: 20.3010804 },
    { lat: 48.5484453, lng: 20.3011912 },
    { lat: 48.5485716, lng: 20.3011405 },
    { lat: 48.5488725, lng: 20.3012836 },
    { lat: 48.5489651, lng: 20.301436 },
    { lat: 48.5489992, lng: 20.3017272 },
    { lat: 48.5489106, lng: 20.3019107 },
    { lat: 48.5483622, lng: 20.3021391 },
    { lat: 48.5482072, lng: 20.3020623 },
    { lat: 48.5475957, lng: 20.3021527 },
    { lat: 48.5473957, lng: 20.3013915 },
    { lat: 48.5470411, lng: 20.3013343 },
    { lat: 48.5465846, lng: 20.3011511 },
    { lat: 48.5465412, lng: 20.3013397 },
    { lat: 48.5463717, lng: 20.3016129 },
    { lat: 48.5462249, lng: 20.3015698 },
    { lat: 48.5460273, lng: 20.3018156 },
    { lat: 48.5457909, lng: 20.3027942 },
    { lat: 48.5456404, lng: 20.3036379 },
    { lat: 48.545475, lng: 20.3049577 },
    { lat: 48.5454215, lng: 20.3060597 },
    { lat: 48.5455686, lng: 20.3061639 },
    { lat: 48.5456291, lng: 20.3072485 },
    { lat: 48.5457131, lng: 20.3075169 },
    { lat: 48.545795, lng: 20.3076251 },
    { lat: 48.5457911, lng: 20.3077317 },
    { lat: 48.545989, lng: 20.3082088 },
    { lat: 48.5461448, lng: 20.3087945 },
    { lat: 48.5462837, lng: 20.3099511 },
    { lat: 48.5464816, lng: 20.3126077 },
    { lat: 48.5460845, lng: 20.3142584 },
    { lat: 48.546012, lng: 20.3144191 },
    { lat: 48.5459297, lng: 20.3148789 },
    { lat: 48.5460194, lng: 20.31527 },
    { lat: 48.5459124, lng: 20.3155557 },
    { lat: 48.5457923, lng: 20.3165129 },
    { lat: 48.5458761, lng: 20.3199178 },
    { lat: 48.5469399, lng: 20.3214305 },
    { lat: 48.5473717, lng: 20.3223923 },
    { lat: 48.547683, lng: 20.3233538 },
    { lat: 48.5477886, lng: 20.3240141 },
    { lat: 48.5478063, lng: 20.3254431 },
    { lat: 48.5483039, lng: 20.3269906 },
    { lat: 48.5488086, lng: 20.3281788 },
    { lat: 48.550623, lng: 20.331282 },
    { lat: 48.550655, lng: 20.331327 },
    { lat: 48.550749, lng: 20.331438 },
    { lat: 48.550799, lng: 20.331495 },
    { lat: 48.550853, lng: 20.33156 },
    { lat: 48.551168, lng: 20.331938 },
    { lat: 48.551571, lng: 20.333283 },
    { lat: 48.552172, lng: 20.333617 },
    { lat: 48.552587, lng: 20.333848 },
    { lat: 48.55319, lng: 20.334183 },
    { lat: 48.554559, lng: 20.334954 },
    { lat: 48.554574, lng: 20.334963 },
    { lat: 48.555127, lng: 20.335271 },
    { lat: 48.555409, lng: 20.335428 },
    { lat: 48.557236, lng: 20.335924 },
    { lat: 48.557698, lng: 20.336052 },
    { lat: 48.558127, lng: 20.336127 },
    { lat: 48.558358, lng: 20.336136 },
    { lat: 48.558817, lng: 20.336155 },
    { lat: 48.55944, lng: 20.336187 },
    { lat: 48.560079, lng: 20.336222 },
    { lat: 48.560451, lng: 20.336236 },
    { lat: 48.560992, lng: 20.336207 },
    { lat: 48.561268, lng: 20.336359 },
    { lat: 48.562011, lng: 20.336866 },
    { lat: 48.562361, lng: 20.337094 },
    { lat: 48.562476, lng: 20.337159 },
    { lat: 48.56328, lng: 20.337517 },
    { lat: 48.563892, lng: 20.3378 },
    { lat: 48.564439, lng: 20.338044 },
    { lat: 48.564734, lng: 20.33818 },
    { lat: 48.56504, lng: 20.338315 },
    { lat: 48.565334, lng: 20.338507 },
    { lat: 48.565732, lng: 20.338784 },
    { lat: 48.565887, lng: 20.3389 },
    { lat: 48.566051, lng: 20.339001 },
    { lat: 48.566447, lng: 20.339156 },
    { lat: 48.567034, lng: 20.33982 },
    { lat: 48.567517, lng: 20.339963 },
    { lat: 48.567891, lng: 20.340043 },
    { lat: 48.5675446, lng: 20.3385115 },
    { lat: 48.5672433, lng: 20.3378068 },
    { lat: 48.5672011, lng: 20.3368377 },
    { lat: 48.5668886, lng: 20.3357845 },
    { lat: 48.5669195, lng: 20.3350465 },
    { lat: 48.5667632, lng: 20.333664 },
    { lat: 48.566604, lng: 20.3330499 },
    { lat: 48.5666099, lng: 20.332682 },
    { lat: 48.5665187, lng: 20.3321347 },
    { lat: 48.5667576, lng: 20.3314188 },
    { lat: 48.5667647, lng: 20.3309357 },
    { lat: 48.5665388, lng: 20.3298826 },
    { lat: 48.567605, lng: 20.3243714 },
    { lat: 48.5681445, lng: 20.322301 },
    { lat: 48.5686546, lng: 20.3218119 },
    { lat: 48.5691619, lng: 20.321135 },
    { lat: 48.5695134, lng: 20.3204203 },
    { lat: 48.5699482, lng: 20.3192114 },
    { lat: 48.5708565, lng: 20.3177354 },
    { lat: 48.5720898, lng: 20.3146859 },
    { lat: 48.5731728, lng: 20.3126419 },
    { lat: 48.5745279, lng: 20.311645 },
    { lat: 48.5752688, lng: 20.3138824 },
    { lat: 48.5758814, lng: 20.3134477 },
    { lat: 48.5770208, lng: 20.3126656 },
  ],
  MuránskaZdychava: [
    { lat: 48.796091, lng: 20.168255 },
    { lat: 48.796054, lng: 20.168234 },
    { lat: 48.795717, lng: 20.16793 },
    { lat: 48.795511, lng: 20.167555 },
    { lat: 48.79516, lng: 20.166933 },
    { lat: 48.794774, lng: 20.166285 },
    { lat: 48.794614, lng: 20.166018 },
    { lat: 48.794442, lng: 20.165746 },
    { lat: 48.794086, lng: 20.165181 },
    { lat: 48.793778, lng: 20.164713 },
    { lat: 48.793433, lng: 20.164176 },
    { lat: 48.79322, lng: 20.163983 },
    { lat: 48.79306, lng: 20.163703 },
    { lat: 48.792537, lng: 20.162804 },
    { lat: 48.792099, lng: 20.162017 },
    { lat: 48.791797, lng: 20.161481 },
    { lat: 48.791739, lng: 20.161382 },
    { lat: 48.79142, lng: 20.160943 },
    { lat: 48.791365, lng: 20.160867 },
    { lat: 48.791164, lng: 20.160576 },
    { lat: 48.790878, lng: 20.160143 },
    { lat: 48.790694, lng: 20.15986 },
    { lat: 48.790374, lng: 20.159492 },
    { lat: 48.79022, lng: 20.159313 },
    { lat: 48.789903, lng: 20.158799 },
    { lat: 48.789629, lng: 20.158356 },
    { lat: 48.789309, lng: 20.157837 },
    { lat: 48.789143, lng: 20.157572 },
    { lat: 48.78904, lng: 20.157359 },
    { lat: 48.788954, lng: 20.157187 },
    { lat: 48.788863, lng: 20.157047 },
    { lat: 48.788808, lng: 20.156959 },
    { lat: 48.788728, lng: 20.15684 },
    { lat: 48.788428, lng: 20.15637 },
    { lat: 48.788067, lng: 20.155811 },
    { lat: 48.787979, lng: 20.155678 },
    { lat: 48.787807, lng: 20.155512 },
    { lat: 48.787659, lng: 20.15536 },
    { lat: 48.787521, lng: 20.15521 },
    { lat: 48.787077, lng: 20.154727 },
    { lat: 48.78686, lng: 20.154489 },
    { lat: 48.786467, lng: 20.154265 },
    { lat: 48.785931, lng: 20.154005 },
    { lat: 48.785426, lng: 20.15354 },
    { lat: 48.785275, lng: 20.153344 },
    { lat: 48.784977, lng: 20.152949 },
    { lat: 48.784789, lng: 20.152804 },
    { lat: 48.784557, lng: 20.152622 },
    { lat: 48.784285, lng: 20.152085 },
    { lat: 48.783739, lng: 20.151798 },
    { lat: 48.783643, lng: 20.151742 },
    { lat: 48.783506, lng: 20.151397 },
    { lat: 48.783413, lng: 20.151318 },
    { lat: 48.782808, lng: 20.150807 },
    { lat: 48.782731, lng: 20.150738 },
    { lat: 48.782549, lng: 20.149957 },
    { lat: 48.782431, lng: 20.149482 },
    { lat: 48.78235, lng: 20.149151 },
    { lat: 48.782304, lng: 20.14855 },
    { lat: 48.782243, lng: 20.148328 },
    { lat: 48.782094, lng: 20.147793 },
    { lat: 48.781961, lng: 20.147373 },
    { lat: 48.781947, lng: 20.147318 },
    { lat: 48.7816848, lng: 20.1464268 },
    { lat: 48.7812145, lng: 20.1460732 },
    { lat: 48.7802771, lng: 20.1456469 },
    { lat: 48.7799392, lng: 20.1452876 },
    { lat: 48.7791342, lng: 20.1459589 },
    { lat: 48.7779024, lng: 20.1464977 },
    { lat: 48.7756521, lng: 20.1469422 },
    { lat: 48.7743763, lng: 20.1413573 },
    { lat: 48.773697, lng: 20.1412746 },
    { lat: 48.772349, lng: 20.1407762 },
    { lat: 48.7721427, lng: 20.1405768 },
    { lat: 48.7691725, lng: 20.1396802 },
    { lat: 48.7683447, lng: 20.1347454 },
    { lat: 48.7686725, lng: 20.134575 },
    { lat: 48.7684932, lng: 20.1341296 },
    { lat: 48.7679266, lng: 20.1344036 },
    { lat: 48.7672067, lng: 20.1345069 },
    { lat: 48.7650205, lng: 20.1359192 },
    { lat: 48.7637162, lng: 20.1364455 },
    { lat: 48.7629989, lng: 20.1365088 },
    { lat: 48.7629025, lng: 20.1362097 },
    { lat: 48.7625484, lng: 20.1340906 },
    { lat: 48.7626309, lng: 20.1332119 },
    { lat: 48.7636182, lng: 20.1321713 },
    { lat: 48.7642276, lng: 20.1313196 },
    { lat: 48.7638303, lng: 20.130881 },
    { lat: 48.7635237, lng: 20.1306821 },
    { lat: 48.7633965, lng: 20.1301548 },
    { lat: 48.7592309, lng: 20.1287843 },
    { lat: 48.7592514, lng: 20.1284005 },
    { lat: 48.7605697, lng: 20.1281383 },
    { lat: 48.7613526, lng: 20.1282258 },
    { lat: 48.7616396, lng: 20.1279724 },
    { lat: 48.7619562, lng: 20.1278341 },
    { lat: 48.7623448, lng: 20.1272803 },
    { lat: 48.7623992, lng: 20.1270685 },
    { lat: 48.7613745, lng: 20.1265762 },
    { lat: 48.7610781, lng: 20.126265 },
    { lat: 48.7612126, lng: 20.1260847 },
    { lat: 48.7614302, lng: 20.1260074 },
    { lat: 48.7617325, lng: 20.1254323 },
    { lat: 48.7616555, lng: 20.1247348 },
    { lat: 48.7615078, lng: 20.1242609 },
    { lat: 48.7607482, lng: 20.1234998 },
    { lat: 48.7600221, lng: 20.1229288 },
    { lat: 48.759329, lng: 20.1224725 },
    { lat: 48.7593184, lng: 20.1227102 },
    { lat: 48.7591685, lng: 20.1228202 },
    { lat: 48.7590636, lng: 20.1223469 },
    { lat: 48.758748, lng: 20.1217013 },
    { lat: 48.7587092, lng: 20.121444 },
    { lat: 48.7585062, lng: 20.1210798 },
    { lat: 48.7581212, lng: 20.1210265 },
    { lat: 48.7577992, lng: 20.1211974 },
    { lat: 48.7575392, lng: 20.1212524 },
    { lat: 48.757004, lng: 20.1210573 },
    { lat: 48.7568207, lng: 20.1210876 },
    { lat: 48.7567093, lng: 20.121204 },
    { lat: 48.7564122, lng: 20.1208212 },
    { lat: 48.7561523, lng: 20.120804 },
    { lat: 48.7558229, lng: 20.1209271 },
    { lat: 48.7553888, lng: 20.121332 },
    { lat: 48.755356, lng: 20.1213832 },
    { lat: 48.7555069, lng: 20.1215773 },
    { lat: 48.7555497, lng: 20.1217132 },
    { lat: 48.7554321, lng: 20.1217573 },
    { lat: 48.754232, lng: 20.1217417 },
    { lat: 48.753998, lng: 20.1218895 },
    { lat: 48.7534696, lng: 20.1220225 },
    { lat: 48.7531793, lng: 20.1222787 },
    { lat: 48.753043, lng: 20.1222525 },
    { lat: 48.7530197, lng: 20.1223692 },
    { lat: 48.7529265, lng: 20.1223744 },
    { lat: 48.7528845, lng: 20.1222308 },
    { lat: 48.752775, lng: 20.1221679 },
    { lat: 48.7526202, lng: 20.1222958 },
    { lat: 48.7525507, lng: 20.1221941 },
    { lat: 48.7522245, lng: 20.1220903 },
    { lat: 48.7516566, lng: 20.1221399 },
    { lat: 48.7513731, lng: 20.1219822 },
    { lat: 48.7512109, lng: 20.12212 },
    { lat: 48.7507943, lng: 20.1219864 },
    { lat: 48.7506669, lng: 20.1218716 },
    { lat: 48.7505564, lng: 20.1218694 },
    { lat: 48.7505205, lng: 20.1219288 },
    { lat: 48.7501035, lng: 20.1218655 },
    { lat: 48.7490342, lng: 20.1212748 },
    { lat: 48.7482956, lng: 20.1210306 },
    { lat: 48.7482443, lng: 20.1206274 },
    { lat: 48.7479752, lng: 20.1195561 },
    { lat: 48.7474119, lng: 20.1181967 },
    { lat: 48.7470693, lng: 20.117718 },
    { lat: 48.7455373, lng: 20.1169118 },
    { lat: 48.7454079, lng: 20.1168367 },
    { lat: 48.744795, lng: 20.1165227 },
    { lat: 48.7440696, lng: 20.1164972 },
    { lat: 48.7433634, lng: 20.1161894 },
    { lat: 48.7427755, lng: 20.1160811 },
    { lat: 48.7416204, lng: 20.1160981 },
    { lat: 48.7410093, lng: 20.1159973 },
    { lat: 48.7400972, lng: 20.1160292 },
    { lat: 48.7397148, lng: 20.115986 },
    { lat: 48.7394765, lng: 20.1166094 },
    { lat: 48.7389637, lng: 20.1196674 },
    { lat: 48.7389159, lng: 20.1205383 },
    { lat: 48.7387812, lng: 20.1214311 },
    { lat: 48.7384449, lng: 20.1221336 },
    { lat: 48.7378339, lng: 20.1228948 },
    { lat: 48.7374236, lng: 20.1237733 },
    { lat: 48.7371339, lng: 20.1246274 },
    { lat: 48.7369247, lng: 20.1255471 },
    { lat: 48.7368423, lng: 20.1263183 },
    { lat: 48.7364626, lng: 20.1278323 },
    { lat: 48.7363427, lng: 20.1286801 },
    { lat: 48.7355598, lng: 20.1315863 },
    { lat: 48.7340807, lng: 20.1296643 },
    { lat: 48.7330539, lng: 20.1294014 },
    { lat: 48.7328185, lng: 20.1292071 },
    { lat: 48.7325113, lng: 20.129129 },
    { lat: 48.7319522, lng: 20.1295862 },
    { lat: 48.7316507, lng: 20.1299564 },
    { lat: 48.7314008, lng: 20.1306011 },
    { lat: 48.7307192, lng: 20.1318967 },
    { lat: 48.7298829, lng: 20.1326583 },
    { lat: 48.7295438, lng: 20.1330968 },
    { lat: 48.7294271, lng: 20.1331341 },
    { lat: 48.7291126, lng: 20.1336599 },
    { lat: 48.7287146, lng: 20.1339772 },
    { lat: 48.7285037, lng: 20.1338859 },
    { lat: 48.7282641, lng: 20.1339773 },
    { lat: 48.727989, lng: 20.1341377 },
    { lat: 48.7279832, lng: 20.1342431 },
    { lat: 48.7283498, lng: 20.1347053 },
    { lat: 48.7288581, lng: 20.1359282 },
    { lat: 48.7294068, lng: 20.1364057 },
    { lat: 48.7296894, lng: 20.1367897 },
    { lat: 48.7297389, lng: 20.1372354 },
    { lat: 48.7295746, lng: 20.1380423 },
    { lat: 48.7295844, lng: 20.1381741 },
    { lat: 48.7297132, lng: 20.1384065 },
    { lat: 48.7298154, lng: 20.138452 },
    { lat: 48.729639, lng: 20.139408 },
    { lat: 48.7296585, lng: 20.1400805 },
    { lat: 48.729483, lng: 20.1402527 },
    { lat: 48.7294496, lng: 20.1404284 },
    { lat: 48.7293274, lng: 20.1405528 },
    { lat: 48.729349, lng: 20.1407834 },
    { lat: 48.7291153, lng: 20.1411537 },
    { lat: 48.7291494, lng: 20.1414423 },
    { lat: 48.7290885, lng: 20.1420527 },
    { lat: 48.7292243, lng: 20.1422877 },
    { lat: 48.7292481, lng: 20.1427336 },
    { lat: 48.7293774, lng: 20.142896 },
    { lat: 48.7294163, lng: 20.1435535 },
    { lat: 48.7293269, lng: 20.1440948 },
    { lat: 48.7293449, lng: 20.1442904 },
    { lat: 48.7291873, lng: 20.1447106 },
    { lat: 48.7288701, lng: 20.1453326 },
    { lat: 48.728246, lng: 20.1461873 },
    { lat: 48.7281209, lng: 20.1465178 },
    { lat: 48.728113, lng: 20.1467126 },
    { lat: 48.7280277, lng: 20.1468864 },
    { lat: 48.7279408, lng: 20.1473265 },
    { lat: 48.7278445, lng: 20.1475084 },
    { lat: 48.7277097, lng: 20.1482979 },
    { lat: 48.7277841, lng: 20.1492738 },
    { lat: 48.7280396, lng: 20.1500454 },
    { lat: 48.7282123, lng: 20.1504183 },
    { lat: 48.7283926, lng: 20.1511689 },
    { lat: 48.7284607, lng: 20.1538543 },
    { lat: 48.7286348, lng: 20.154652 },
    { lat: 48.7291458, lng: 20.1559479 },
    { lat: 48.7293178, lng: 20.1568631 },
    { lat: 48.7293528, lng: 20.157506 },
    { lat: 48.7292749, lng: 20.1581143 },
    { lat: 48.7293023, lng: 20.1585684 },
    { lat: 48.7292643, lng: 20.1587963 },
    { lat: 48.7292981, lng: 20.1597447 },
    { lat: 48.7294342, lng: 20.1603265 },
    { lat: 48.7297767, lng: 20.161029 },
    { lat: 48.7300362, lng: 20.1622651 },
    { lat: 48.7303261, lng: 20.162974 },
    { lat: 48.7306057, lng: 20.1632886 },
    { lat: 48.7309741, lng: 20.163509 },
    { lat: 48.7310089, lng: 20.1641248 },
    { lat: 48.7332261, lng: 20.164475 },
    { lat: 48.7334484, lng: 20.1652116 },
    { lat: 48.733696, lng: 20.1663515 },
    { lat: 48.733693, lng: 20.1666108 },
    { lat: 48.7335902, lng: 20.1671915 },
    { lat: 48.7335843, lng: 20.1676837 },
    { lat: 48.7334102, lng: 20.1683109 },
    { lat: 48.7333625, lng: 20.1688615 },
    { lat: 48.7337699, lng: 20.1697174 },
    { lat: 48.7340241, lng: 20.1706059 },
    { lat: 48.7345175, lng: 20.1714488 },
    { lat: 48.7347318, lng: 20.172989 },
    { lat: 48.7356439, lng: 20.1743864 },
    { lat: 48.7366342, lng: 20.174697 },
    { lat: 48.7356893, lng: 20.17593 },
    { lat: 48.7353911, lng: 20.1774752 },
    { lat: 48.736687, lng: 20.1793391 },
    { lat: 48.7371847, lng: 20.1802071 },
    { lat: 48.737943, lng: 20.182343 },
    { lat: 48.738257, lng: 20.182392 },
    { lat: 48.738548, lng: 20.182119 },
    { lat: 48.738861, lng: 20.181813 },
    { lat: 48.739228, lng: 20.181665 },
    { lat: 48.739682, lng: 20.181485 },
    { lat: 48.739918, lng: 20.181696 },
    { lat: 48.740549, lng: 20.181416 },
    { lat: 48.740997, lng: 20.181222 },
    { lat: 48.741321, lng: 20.18148 },
    { lat: 48.741635, lng: 20.181732 },
    { lat: 48.741875, lng: 20.181825 },
    { lat: 48.742201, lng: 20.181941 },
    { lat: 48.742573, lng: 20.182073 },
    { lat: 48.742921, lng: 20.182199 },
    { lat: 48.74342, lng: 20.182136 },
    { lat: 48.743738, lng: 20.182076 },
    { lat: 48.744098, lng: 20.182235 },
    { lat: 48.744439, lng: 20.182247 },
    { lat: 48.744587, lng: 20.182242 },
    { lat: 48.744798, lng: 20.182221 },
    { lat: 48.745076, lng: 20.182334 },
    { lat: 48.745267, lng: 20.182412 },
    { lat: 48.745548, lng: 20.182528 },
    { lat: 48.745691, lng: 20.182573 },
    { lat: 48.745941, lng: 20.182703 },
    { lat: 48.746234, lng: 20.182857 },
    { lat: 48.746518, lng: 20.182868 },
    { lat: 48.746648, lng: 20.182867 },
    { lat: 48.746824, lng: 20.182807 },
    { lat: 48.746927, lng: 20.182786 },
    { lat: 48.747188, lng: 20.182707 },
    { lat: 48.747361, lng: 20.182657 },
    { lat: 48.747464, lng: 20.182543 },
    { lat: 48.74757, lng: 20.182447 },
    { lat: 48.747951, lng: 20.182363 },
    { lat: 48.748243, lng: 20.18264 },
    { lat: 48.748327, lng: 20.182713 },
    { lat: 48.748531, lng: 20.182844 },
    { lat: 48.7489, lng: 20.182852 },
    { lat: 48.749114, lng: 20.182805 },
    { lat: 48.749435, lng: 20.182736 },
    { lat: 48.749737, lng: 20.182703 },
    { lat: 48.74994, lng: 20.182689 },
    { lat: 48.750203, lng: 20.18279 },
    { lat: 48.750612, lng: 20.182688 },
    { lat: 48.751015, lng: 20.182471 },
    { lat: 48.751376, lng: 20.18227 },
    { lat: 48.75154, lng: 20.18218 },
    { lat: 48.751537, lng: 20.182146 },
    { lat: 48.751993, lng: 20.18213 },
    { lat: 48.752458, lng: 20.182122 },
    { lat: 48.752709, lng: 20.182124 },
    { lat: 48.753486, lng: 20.182107 },
    { lat: 48.753788, lng: 20.1821 },
    { lat: 48.754207, lng: 20.182078 },
    { lat: 48.754541, lng: 20.182059 },
    { lat: 48.754981, lng: 20.182058 },
    { lat: 48.755326, lng: 20.182049 },
    { lat: 48.756144, lng: 20.181993 },
    { lat: 48.756345, lng: 20.181984 },
    { lat: 48.757074, lng: 20.18195 },
    { lat: 48.757397, lng: 20.182547 },
    { lat: 48.757806, lng: 20.183309 },
    { lat: 48.758196, lng: 20.185312 },
    { lat: 48.758465, lng: 20.185793 },
    { lat: 48.758852, lng: 20.18692 },
    { lat: 48.759576, lng: 20.189026 },
    { lat: 48.760455, lng: 20.191579 },
    { lat: 48.761823, lng: 20.193106 },
    { lat: 48.761997, lng: 20.193072 },
    { lat: 48.762047, lng: 20.193063 },
    { lat: 48.763008, lng: 20.192871 },
    { lat: 48.763446, lng: 20.19279 },
    { lat: 48.764222, lng: 20.193862 },
    { lat: 48.764434, lng: 20.194156 },
    { lat: 48.764688, lng: 20.194507 },
    { lat: 48.76503, lng: 20.194987 },
    { lat: 48.765492, lng: 20.195643 },
    { lat: 48.76605, lng: 20.196455 },
    { lat: 48.766431, lng: 20.197 },
    { lat: 48.766841, lng: 20.197581 },
    { lat: 48.767176, lng: 20.198068 },
    { lat: 48.767458, lng: 20.198478 },
    { lat: 48.768031, lng: 20.199305 },
    { lat: 48.768111, lng: 20.199413 },
    { lat: 48.768329, lng: 20.199737 },
    { lat: 48.76868, lng: 20.200259 },
    { lat: 48.769082, lng: 20.200853 },
    { lat: 48.769749, lng: 20.201814 },
    { lat: 48.769829, lng: 20.201928 },
    { lat: 48.770647, lng: 20.203135 },
    { lat: 48.770867, lng: 20.203455 },
    { lat: 48.771283, lng: 20.204059 },
    { lat: 48.771786, lng: 20.204792 },
    { lat: 48.772185, lng: 20.205376 },
    { lat: 48.772769, lng: 20.206224 },
    { lat: 48.773564, lng: 20.20763 },
    { lat: 48.774643, lng: 20.206575 },
    { lat: 48.7751921, lng: 20.2058317 },
    { lat: 48.776656, lng: 20.20385 },
    { lat: 48.777979, lng: 20.202647 },
    { lat: 48.778176, lng: 20.202451 },
    { lat: 48.778177, lng: 20.20245 },
    { lat: 48.779567, lng: 20.201088 },
    { lat: 48.780409, lng: 20.200545 },
    { lat: 48.780409, lng: 20.200565 },
    { lat: 48.780617, lng: 20.20056 },
    { lat: 48.781359, lng: 20.200387 },
    { lat: 48.781903, lng: 20.200372 },
    { lat: 48.782005, lng: 20.200492 },
    { lat: 48.782553, lng: 20.200456 },
    { lat: 48.783634, lng: 20.199751 },
    { lat: 48.784862, lng: 20.199323 },
    { lat: 48.785139, lng: 20.199087 },
    { lat: 48.785446, lng: 20.19901 },
    { lat: 48.785442, lng: 20.198979 },
    { lat: 48.786075, lng: 20.198413 },
    { lat: 48.78667, lng: 20.197847 },
    { lat: 48.787368, lng: 20.197263 },
    { lat: 48.787609, lng: 20.197034 },
    { lat: 48.78937, lng: 20.195454 },
    { lat: 48.790105, lng: 20.194794 },
    { lat: 48.790588, lng: 20.194319 },
    { lat: 48.790226, lng: 20.19287 },
    { lat: 48.789995, lng: 20.190358 },
    { lat: 48.789924, lng: 20.189569 },
    { lat: 48.789664, lng: 20.188648 },
    { lat: 48.789494, lng: 20.187956 },
    { lat: 48.789388, lng: 20.187185 },
    { lat: 48.789292, lng: 20.186538 },
    { lat: 48.7895, lng: 20.18594 },
    { lat: 48.78944, lng: 20.185046 },
    { lat: 48.789347, lng: 20.184311 },
    { lat: 48.789143, lng: 20.183069 },
    { lat: 48.789402, lng: 20.181602 },
    { lat: 48.789249, lng: 20.180636 },
    { lat: 48.788852, lng: 20.178883 },
    { lat: 48.788898, lng: 20.177994 },
    { lat: 48.788938, lng: 20.177247 },
    { lat: 48.788995, lng: 20.176985 },
    { lat: 48.7891, lng: 20.176799 },
    { lat: 48.78925, lng: 20.176689 },
    { lat: 48.789709, lng: 20.176647 },
    { lat: 48.790117, lng: 20.17646 },
    { lat: 48.790339, lng: 20.176293 },
    { lat: 48.790578, lng: 20.176002 },
    { lat: 48.790757, lng: 20.17563 },
    { lat: 48.79101, lng: 20.175224 },
    { lat: 48.791287, lng: 20.174927 },
    { lat: 48.791532, lng: 20.174614 },
    { lat: 48.79186, lng: 20.174378 },
    { lat: 48.792138, lng: 20.174177 },
    { lat: 48.792314, lng: 20.174033 },
    { lat: 48.792589, lng: 20.173807 },
    { lat: 48.793079, lng: 20.173299 },
    { lat: 48.793139, lng: 20.173239 },
    { lat: 48.793484, lng: 20.172887 },
    { lat: 48.793948, lng: 20.17233 },
    { lat: 48.794365, lng: 20.171828 },
    { lat: 48.794871, lng: 20.171231 },
    { lat: 48.795009, lng: 20.171069 },
    { lat: 48.795094, lng: 20.170949 },
    { lat: 48.795839, lng: 20.169925 },
    { lat: 48.796114, lng: 20.168861 },
    { lat: 48.796091, lng: 20.168255 },
  ],
  Levkuška: [
    { lat: 48.4643584, lng: 20.2558508 },
    { lat: 48.4635498, lng: 20.2550602 },
    { lat: 48.4629534, lng: 20.2546727 },
    { lat: 48.4618218, lng: 20.2543143 },
    { lat: 48.4595652, lng: 20.2515814 },
    { lat: 48.457243, lng: 20.2491269 },
    { lat: 48.4568821, lng: 20.2487453 },
    { lat: 48.4566329, lng: 20.2492435 },
    { lat: 48.456538, lng: 20.2493228 },
    { lat: 48.4560105, lng: 20.2497637 },
    { lat: 48.4556708, lng: 20.2501416 },
    { lat: 48.4553792, lng: 20.2500902 },
    { lat: 48.4552216, lng: 20.2503162 },
    { lat: 48.4538298, lng: 20.2502662 },
    { lat: 48.4536967, lng: 20.2504024 },
    { lat: 48.4535468, lng: 20.2504017 },
    { lat: 48.4533716, lng: 20.250608 },
    { lat: 48.4533192, lng: 20.2511591 },
    { lat: 48.4499132, lng: 20.2495198 },
    { lat: 48.4497357, lng: 20.2495843 },
    { lat: 48.4493849, lng: 20.2495684 },
    { lat: 48.4485567, lng: 20.2491648 },
    { lat: 48.4483306, lng: 20.2489166 },
    { lat: 48.4480221, lng: 20.2482412 },
    { lat: 48.4475442, lng: 20.2476369 },
    { lat: 48.4472498, lng: 20.2470369 },
    { lat: 48.4469377, lng: 20.2454779 },
    { lat: 48.4466143, lng: 20.245446 },
    { lat: 48.4465074, lng: 20.2455347 },
    { lat: 48.4452302, lng: 20.2448861 },
    { lat: 48.4449846, lng: 20.2447983 },
    { lat: 48.4442784, lng: 20.2447094 },
    { lat: 48.4438177, lng: 20.2450219 },
    { lat: 48.4427819, lng: 20.2452522 },
    { lat: 48.4423518, lng: 20.2455101 },
    { lat: 48.4422417, lng: 20.2468761 },
    { lat: 48.4438419, lng: 20.2494399 },
    { lat: 48.444566, lng: 20.249832 },
    { lat: 48.4464363, lng: 20.2523366 },
    { lat: 48.4465746, lng: 20.2543401 },
    { lat: 48.4469116, lng: 20.2563268 },
    { lat: 48.4476141, lng: 20.2584205 },
    { lat: 48.4481708, lng: 20.2598736 },
    { lat: 48.4489215, lng: 20.2624899 },
    { lat: 48.4492726, lng: 20.2634503 },
    { lat: 48.4508852, lng: 20.2657953 },
    { lat: 48.4525881, lng: 20.2678974 },
    { lat: 48.4529568, lng: 20.2685303 },
    { lat: 48.4535974, lng: 20.2698881 },
    { lat: 48.4542131, lng: 20.2704158 },
    { lat: 48.4546925, lng: 20.2705502 },
    { lat: 48.4553272, lng: 20.2724976 },
    { lat: 48.4557178, lng: 20.275504 },
    { lat: 48.4560807, lng: 20.2762247 },
    { lat: 48.4558905, lng: 20.2763961 },
    { lat: 48.4564855, lng: 20.2773696 },
    { lat: 48.4565217, lng: 20.2775438 },
    { lat: 48.456321, lng: 20.2792474 },
    { lat: 48.4562803, lng: 20.2802938 },
    { lat: 48.4565554, lng: 20.2808447 },
    { lat: 48.456696, lng: 20.2813508 },
    { lat: 48.4567209, lng: 20.2823807 },
    { lat: 48.4568578, lng: 20.2829645 },
    { lat: 48.4568625, lng: 20.2838228 },
    { lat: 48.457013, lng: 20.284895 },
    { lat: 48.4570151, lng: 20.2853741 },
    { lat: 48.4571827, lng: 20.2870821 },
    { lat: 48.457354, lng: 20.2882213 },
    { lat: 48.4573192, lng: 20.2891381 },
    { lat: 48.4571012, lng: 20.2900066 },
    { lat: 48.4570135, lng: 20.2907496 },
    { lat: 48.4571175, lng: 20.2914712 },
    { lat: 48.4573072, lng: 20.2917988 },
    { lat: 48.4573667, lng: 20.2919968 },
    { lat: 48.4573942, lng: 20.2927174 },
    { lat: 48.4570059, lng: 20.2934975 },
    { lat: 48.4569952, lng: 20.2935094 },
    { lat: 48.4573358, lng: 20.29458 },
    { lat: 48.4577364, lng: 20.2963946 },
    { lat: 48.4579423, lng: 20.2969067 },
    { lat: 48.4586883, lng: 20.298345 },
    { lat: 48.4589362, lng: 20.2984361 },
    { lat: 48.4593756, lng: 20.2983518 },
    { lat: 48.459829, lng: 20.2984555 },
    { lat: 48.460344, lng: 20.2987204 },
    { lat: 48.4608348, lng: 20.2988857 },
    { lat: 48.4611605, lng: 20.2990951 },
    { lat: 48.461768, lng: 20.2988448 },
    { lat: 48.4618721, lng: 20.2987206 },
    { lat: 48.4620076, lng: 20.2985006 },
    { lat: 48.4618727, lng: 20.2982195 },
    { lat: 48.4613746, lng: 20.2971806 },
    { lat: 48.4607915, lng: 20.2955602 },
    { lat: 48.4604517, lng: 20.2948266 },
    { lat: 48.4602189, lng: 20.2940635 },
    { lat: 48.4602026, lng: 20.2936797 },
    { lat: 48.4604506, lng: 20.292686 },
    { lat: 48.4607421, lng: 20.2923424 },
    { lat: 48.4608474, lng: 20.2923252 },
    { lat: 48.4610977, lng: 20.2917061 },
    { lat: 48.4613362, lng: 20.2916131 },
    { lat: 48.4614798, lng: 20.2912831 },
    { lat: 48.4616784, lng: 20.2909819 },
    { lat: 48.461779, lng: 20.2906177 },
    { lat: 48.4618939, lng: 20.2905925 },
    { lat: 48.4625668, lng: 20.2896078 },
    { lat: 48.4625228, lng: 20.2895233 },
    { lat: 48.4626745, lng: 20.2889119 },
    { lat: 48.4626741, lng: 20.2888086 },
    { lat: 48.462673, lng: 20.2885622 },
    { lat: 48.4627606, lng: 20.2885533 },
    { lat: 48.4628541, lng: 20.2880996 },
    { lat: 48.4622673, lng: 20.2855056 },
    { lat: 48.4621997, lng: 20.2823465 },
    { lat: 48.4620462, lng: 20.2806261 },
    { lat: 48.4615348, lng: 20.2771802 },
    { lat: 48.4613195, lng: 20.275332 },
    { lat: 48.4613274, lng: 20.274447 },
    { lat: 48.4616301, lng: 20.273904 },
    { lat: 48.461631, lng: 20.2735551 },
    { lat: 48.4614389, lng: 20.273342 },
    { lat: 48.4612288, lng: 20.2733236 },
    { lat: 48.4609968, lng: 20.2734944 },
    { lat: 48.4609573, lng: 20.2736073 },
    { lat: 48.4609907, lng: 20.2737093 },
    { lat: 48.4606583, lng: 20.2738207 },
    { lat: 48.4605428, lng: 20.2737469 },
    { lat: 48.4604765, lng: 20.2733832 },
    { lat: 48.4605869, lng: 20.2724723 },
    { lat: 48.4607032, lng: 20.2723697 },
    { lat: 48.4610095, lng: 20.2723401 },
    { lat: 48.4618325, lng: 20.272447 },
    { lat: 48.4616726, lng: 20.2698028 },
    { lat: 48.4617232, lng: 20.2697454 },
    { lat: 48.4617081, lng: 20.2691098 },
    { lat: 48.461764, lng: 20.2680502 },
    { lat: 48.462291, lng: 20.266943 },
    { lat: 48.4623958, lng: 20.2664861 },
    { lat: 48.462618, lng: 20.2647952 },
    { lat: 48.4628433, lng: 20.2636874 },
    { lat: 48.4628774, lng: 20.2631861 },
    { lat: 48.4635033, lng: 20.2610543 },
    { lat: 48.4641797, lng: 20.2591188 },
    { lat: 48.4644456, lng: 20.2569909 },
    { lat: 48.4643584, lng: 20.2558508 },
  ],
  Kameňany: [
    { lat: 48.5950939, lng: 20.1746691 },
    { lat: 48.5945604, lng: 20.1734521 },
    { lat: 48.5939703, lng: 20.1696897 },
    { lat: 48.5938197, lng: 20.1688599 },
    { lat: 48.5937205, lng: 20.1686371 },
    { lat: 48.5928901, lng: 20.1677937 },
    { lat: 48.5923461, lng: 20.1670702 },
    { lat: 48.591449, lng: 20.1653033 },
    { lat: 48.591136, lng: 20.1655812 },
    { lat: 48.590812, lng: 20.1659792 },
    { lat: 48.5907093, lng: 20.1657787 },
    { lat: 48.5905186, lng: 20.1658543 },
    { lat: 48.5903434, lng: 20.1657316 },
    { lat: 48.5902117, lng: 20.1658989 },
    { lat: 48.5900615, lng: 20.1658614 },
    { lat: 48.5899506, lng: 20.1661242 },
    { lat: 48.5895451, lng: 20.1665689 },
    { lat: 48.5894203, lng: 20.1661559 },
    { lat: 48.589571, lng: 20.1658724 },
    { lat: 48.5895503, lng: 20.1653912 },
    { lat: 48.5889705, lng: 20.1641157 },
    { lat: 48.5874729, lng: 20.1642926 },
    { lat: 48.5870157, lng: 20.164128 },
    { lat: 48.5867589, lng: 20.1637386 },
    { lat: 48.5866337, lng: 20.1625925 },
    { lat: 48.5864158, lng: 20.1618 },
    { lat: 48.5859862, lng: 20.1608129 },
    { lat: 48.5858061, lng: 20.1602653 },
    { lat: 48.5855472, lng: 20.1598754 },
    { lat: 48.5852271, lng: 20.1589006 },
    { lat: 48.5851505, lng: 20.1583965 },
    { lat: 48.585019, lng: 20.1581802 },
    { lat: 48.5847402, lng: 20.1579177 },
    { lat: 48.5846421, lng: 20.1576624 },
    { lat: 48.5846615, lng: 20.1575308 },
    { lat: 48.5848233, lng: 20.1573288 },
    { lat: 48.5848645, lng: 20.1571752 },
    { lat: 48.5849123, lng: 20.1564113 },
    { lat: 48.5850946, lng: 20.1561277 },
    { lat: 48.5857312, lng: 20.155554 },
    { lat: 48.5863531, lng: 20.1545294 },
    { lat: 48.586957, lng: 20.153452 },
    { lat: 48.5877731, lng: 20.1516944 },
    { lat: 48.5879601, lng: 20.1509767 },
    { lat: 48.5880437, lng: 20.1504538 },
    { lat: 48.5878767, lng: 20.1496962 },
    { lat: 48.5879019, lng: 20.1490951 },
    { lat: 48.5880912, lng: 20.1478229 },
    { lat: 48.5882691, lng: 20.1475727 },
    { lat: 48.5874671, lng: 20.1473746 },
    { lat: 48.587433, lng: 20.1473141 },
    { lat: 48.5874422, lng: 20.1471499 },
    { lat: 48.5870903, lng: 20.1466979 },
    { lat: 48.5867708, lng: 20.1450678 },
    { lat: 48.5864629, lng: 20.1444495 },
    { lat: 48.5864021, lng: 20.1442314 },
    { lat: 48.586351, lng: 20.1438867 },
    { lat: 48.5863814, lng: 20.1429437 },
    { lat: 48.5864228, lng: 20.1429399 },
    { lat: 48.5863947, lng: 20.1426628 },
    { lat: 48.586257, lng: 20.1423241 },
    { lat: 48.5858959, lng: 20.1419757 },
    { lat: 48.5854616, lng: 20.1414068 },
    { lat: 48.5850901, lng: 20.1405757 },
    { lat: 48.5842269, lng: 20.1392115 },
    { lat: 48.5841096, lng: 20.1386543 },
    { lat: 48.5841692, lng: 20.1379042 },
    { lat: 48.5840031, lng: 20.136729 },
    { lat: 48.5839323, lng: 20.1358487 },
    { lat: 48.5839482, lng: 20.1355779 },
    { lat: 48.5844117, lng: 20.1344824 },
    { lat: 48.5847638, lng: 20.1339194 },
    { lat: 48.5848546, lng: 20.1335531 },
    { lat: 48.5848638, lng: 20.1329212 },
    { lat: 48.5846423, lng: 20.1321829 },
    { lat: 48.5844167, lng: 20.1299694 },
    { lat: 48.5844894, lng: 20.1290726 },
    { lat: 48.5849004, lng: 20.1277944 },
    { lat: 48.5849797, lng: 20.127199 },
    { lat: 48.5845487, lng: 20.1250601 },
    { lat: 48.5846058, lng: 20.1225984 },
    { lat: 48.5848518, lng: 20.1216702 },
    { lat: 48.5854268, lng: 20.1201985 },
    { lat: 48.5855079, lng: 20.1199184 },
    { lat: 48.5849608, lng: 20.1196241 },
    { lat: 48.5838851, lng: 20.1194594 },
    { lat: 48.583381, lng: 20.1195528 },
    { lat: 48.5829567, lng: 20.1194211 },
    { lat: 48.5828028, lng: 20.1187452 },
    { lat: 48.5822091, lng: 20.1176685 },
    { lat: 48.58207, lng: 20.1171592 },
    { lat: 48.5817018, lng: 20.1165015 },
    { lat: 48.5815762, lng: 20.1159935 },
    { lat: 48.580805, lng: 20.1144559 },
    { lat: 48.5787592, lng: 20.1141449 },
    { lat: 48.578593, lng: 20.1141845 },
    { lat: 48.5780179, lng: 20.114636 },
    { lat: 48.5777225, lng: 20.1149555 },
    { lat: 48.5768645, lng: 20.1154055 },
    { lat: 48.5764032, lng: 20.11558 },
    { lat: 48.5760674, lng: 20.1160732 },
    { lat: 48.5758447, lng: 20.1165776 },
    { lat: 48.5759014, lng: 20.1167502 },
    { lat: 48.5756358, lng: 20.1179141 },
    { lat: 48.5747244, lng: 20.1227566 },
    { lat: 48.5746577, lng: 20.1232486 },
    { lat: 48.5746971, lng: 20.1236077 },
    { lat: 48.5743413, lng: 20.1244364 },
    { lat: 48.5740991, lng: 20.1251225 },
    { lat: 48.573935, lng: 20.1258195 },
    { lat: 48.5737515, lng: 20.1261826 },
    { lat: 48.5734483, lng: 20.1270323 },
    { lat: 48.5732666, lng: 20.1274382 },
    { lat: 48.5715288, lng: 20.1288216 },
    { lat: 48.5706434, lng: 20.1296743 },
    { lat: 48.5703699, lng: 20.1297854 },
    { lat: 48.5699257, lng: 20.1304393 },
    { lat: 48.5695835, lng: 20.1313316 },
    { lat: 48.5693822, lng: 20.1322226 },
    { lat: 48.5693206, lng: 20.1323382 },
    { lat: 48.5689178, lng: 20.1329612 },
    { lat: 48.5684805, lng: 20.1332567 },
    { lat: 48.5682243, lng: 20.1336289 },
    { lat: 48.5679812, lng: 20.1344341 },
    { lat: 48.5676397, lng: 20.1364166 },
    { lat: 48.5675515, lng: 20.1366311 },
    { lat: 48.5671603, lng: 20.1372014 },
    { lat: 48.5667432, lng: 20.137635 },
    { lat: 48.5665266, lng: 20.138095 },
    { lat: 48.5663829, lng: 20.1387448 },
    { lat: 48.5663717, lng: 20.1388006 },
    { lat: 48.5663071, lng: 20.1389876 },
    { lat: 48.5662838, lng: 20.1396309 },
    { lat: 48.5661701, lng: 20.1401837 },
    { lat: 48.5662026, lng: 20.1406796 },
    { lat: 48.5659336, lng: 20.1414022 },
    { lat: 48.565853, lng: 20.1422147 },
    { lat: 48.5656564, lng: 20.1426731 },
    { lat: 48.5656106, lng: 20.1429863 },
    { lat: 48.5654764, lng: 20.1433959 },
    { lat: 48.5647986, lng: 20.1445904 },
    { lat: 48.5645759, lng: 20.1453481 },
    { lat: 48.564523, lng: 20.1460151 },
    { lat: 48.5643468, lng: 20.1466092 },
    { lat: 48.5641971, lng: 20.1469452 },
    { lat: 48.5637691, lng: 20.1475035 },
    { lat: 48.5635274, lng: 20.1478188 },
    { lat: 48.5633378, lng: 20.1483331 },
    { lat: 48.5632383, lng: 20.1486029 },
    { lat: 48.5628792, lng: 20.1490309 },
    { lat: 48.5624961, lng: 20.1494877 },
    { lat: 48.5623794, lng: 20.1498212 },
    { lat: 48.5619767, lng: 20.1509725 },
    { lat: 48.561707, lng: 20.1517434 },
    { lat: 48.5615247, lng: 20.1520781 },
    { lat: 48.5611956, lng: 20.152518 },
    { lat: 48.5609102, lng: 20.1526222 },
    { lat: 48.5603201, lng: 20.1525098 },
    { lat: 48.5598417, lng: 20.1526257 },
    { lat: 48.5595667, lng: 20.1527055 },
    { lat: 48.5586573, lng: 20.1532223 },
    { lat: 48.5585641, lng: 20.1532878 },
    { lat: 48.5581043, lng: 20.1540295 },
    { lat: 48.5574906, lng: 20.1547825 },
    { lat: 48.5573201, lng: 20.1549917 },
    { lat: 48.5573118, lng: 20.1549793 },
    { lat: 48.5567812, lng: 20.1562808 },
    { lat: 48.5560917, lng: 20.1584134 },
    { lat: 48.555615, lng: 20.1593803 },
    { lat: 48.5552727, lng: 20.1605463 },
    { lat: 48.5546763, lng: 20.162201 },
    { lat: 48.5545764, lng: 20.1628465 },
    { lat: 48.5545888, lng: 20.1634299 },
    { lat: 48.5545395, lng: 20.1637181 },
    { lat: 48.5543878, lng: 20.1640047 },
    { lat: 48.5534446, lng: 20.1648227 },
    { lat: 48.553078, lng: 20.1652286 },
    { lat: 48.5527708, lng: 20.1657084 },
    { lat: 48.5524786, lng: 20.1668106 },
    { lat: 48.5521152, lng: 20.1678725 },
    { lat: 48.5521059, lng: 20.1678637 },
    { lat: 48.5518265, lng: 20.1685069 },
    { lat: 48.5517205, lng: 20.1690296 },
    { lat: 48.5513594, lng: 20.1694556 },
    { lat: 48.551309, lng: 20.171553 },
    { lat: 48.551352, lng: 20.1722832 },
    { lat: 48.5512103, lng: 20.1732901 },
    { lat: 48.5512277, lng: 20.1735512 },
    { lat: 48.551168, lng: 20.1737739 },
    { lat: 48.5510355, lng: 20.1739517 },
    { lat: 48.5509247, lng: 20.1744973 },
    { lat: 48.550302, lng: 20.1753368 },
    { lat: 48.5500037, lng: 20.1755351 },
    { lat: 48.5498178, lng: 20.1758981 },
    { lat: 48.549654, lng: 20.1760261 },
    { lat: 48.5495297, lng: 20.1762401 },
    { lat: 48.5492405, lng: 20.1768564 },
    { lat: 48.5490488, lng: 20.1779109 },
    { lat: 48.5490241, lng: 20.1783056 },
    { lat: 48.548844, lng: 20.1784746 },
    { lat: 48.5487228, lng: 20.178844 },
    { lat: 48.5487194, lng: 20.1789826 },
    { lat: 48.54851, lng: 20.1792375 },
    { lat: 48.5484286, lng: 20.1795944 },
    { lat: 48.5483285, lng: 20.1797941 },
    { lat: 48.5483362, lng: 20.1799544 },
    { lat: 48.5479353, lng: 20.1811268 },
    { lat: 48.5478339, lng: 20.1812925 },
    { lat: 48.5478587, lng: 20.1818544 },
    { lat: 48.547823, lng: 20.1821964 },
    { lat: 48.5475886, lng: 20.1828634 },
    { lat: 48.547604, lng: 20.1836895 },
    { lat: 48.5477527, lng: 20.1843623 },
    { lat: 48.5477507, lng: 20.1846753 },
    { lat: 48.5475419, lng: 20.1851096 },
    { lat: 48.5474948, lng: 20.1853404 },
    { lat: 48.547603, lng: 20.1855359 },
    { lat: 48.5474167, lng: 20.1859422 },
    { lat: 48.5473489, lng: 20.1862996 },
    { lat: 48.5473992, lng: 20.1867375 },
    { lat: 48.5473749, lng: 20.1869729 },
    { lat: 48.5474255, lng: 20.1871101 },
    { lat: 48.547385, lng: 20.1872827 },
    { lat: 48.547252, lng: 20.1875003 },
    { lat: 48.5473683, lng: 20.1880961 },
    { lat: 48.5477072, lng: 20.1908612 },
    { lat: 48.5479574, lng: 20.1910829 },
    { lat: 48.5480151, lng: 20.1919894 },
    { lat: 48.5479604, lng: 20.1920854 },
    { lat: 48.5479898, lng: 20.1923099 },
    { lat: 48.5478294, lng: 20.1925236 },
    { lat: 48.5478085, lng: 20.1929019 },
    { lat: 48.5475406, lng: 20.1934052 },
    { lat: 48.5474748, lng: 20.1938168 },
    { lat: 48.5475592, lng: 20.1941464 },
    { lat: 48.547497, lng: 20.1950653 },
    { lat: 48.547638, lng: 20.1954079 },
    { lat: 48.5476366, lng: 20.1955146 },
    { lat: 48.5474779, lng: 20.1962664 },
    { lat: 48.5474467, lng: 20.1963602 },
    { lat: 48.5473706, lng: 20.1963988 },
    { lat: 48.547428, lng: 20.1965108 },
    { lat: 48.5467627, lng: 20.197778 },
    { lat: 48.5466979, lng: 20.198075 },
    { lat: 48.5469445, lng: 20.1981563 },
    { lat: 48.5467546, lng: 20.1987264 },
    { lat: 48.5466594, lng: 20.1989661 },
    { lat: 48.5475474, lng: 20.1999835 },
    { lat: 48.5481739, lng: 20.2002452 },
    { lat: 48.5485431, lng: 20.2008823 },
    { lat: 48.548896, lng: 20.2017345 },
    { lat: 48.5491397, lng: 20.2020493 },
    { lat: 48.5492628, lng: 20.2023719 },
    { lat: 48.5491786, lng: 20.2026828 },
    { lat: 48.5491306, lng: 20.2031158 },
    { lat: 48.5493239, lng: 20.2041433 },
    { lat: 48.5494012, lng: 20.2050594 },
    { lat: 48.5495688, lng: 20.2057533 },
    { lat: 48.5497512, lng: 20.2062578 },
    { lat: 48.5499011, lng: 20.2064578 },
    { lat: 48.5505732, lng: 20.2070091 },
    { lat: 48.550947, lng: 20.2075371 },
    { lat: 48.551418, lng: 20.2079428 },
    { lat: 48.5520795, lng: 20.208654 },
    { lat: 48.5527154, lng: 20.2090902 },
    { lat: 48.5529861, lng: 20.2090148 },
    { lat: 48.5531376, lng: 20.2088897 },
    { lat: 48.5532493, lng: 20.2085866 },
    { lat: 48.5532687, lng: 20.2081405 },
    { lat: 48.5533347, lng: 20.2079627 },
    { lat: 48.5534356, lng: 20.2078121 },
    { lat: 48.5536098, lng: 20.2077205 },
    { lat: 48.5541066, lng: 20.2077787 },
    { lat: 48.5542743, lng: 20.2079984 },
    { lat: 48.5545811, lng: 20.2081518 },
    { lat: 48.5555068, lng: 20.208824 },
    { lat: 48.5567061, lng: 20.2102244 },
    { lat: 48.5570354, lng: 20.2109382 },
    { lat: 48.5576371, lng: 20.2117289 },
    { lat: 48.5578639, lng: 20.2121875 },
    { lat: 48.5579436, lng: 20.2127445 },
    { lat: 48.5581859, lng: 20.2135338 },
    { lat: 48.55886, lng: 20.2141952 },
    { lat: 48.5595414, lng: 20.2146193 },
    { lat: 48.5600777, lng: 20.2148461 },
    { lat: 48.5603413, lng: 20.2150742 },
    { lat: 48.5611692, lng: 20.2162291 },
    { lat: 48.5614246, lng: 20.2165085 },
    { lat: 48.5619648, lng: 20.2168324 },
    { lat: 48.5624324, lng: 20.2172037 },
    { lat: 48.5627361, lng: 20.2176707 },
    { lat: 48.5635786, lng: 20.2186004 },
    { lat: 48.5660277, lng: 20.2205007 },
    { lat: 48.5665211, lng: 20.2216322 },
    { lat: 48.5668245, lng: 20.2218116 },
    { lat: 48.5675772, lng: 20.221708 },
    { lat: 48.5678387, lng: 20.2217967 },
    { lat: 48.5690271, lng: 20.2226514 },
    { lat: 48.5696848, lng: 20.2234106 },
    { lat: 48.5699764, lng: 20.223573 },
    { lat: 48.570681, lng: 20.2243568 },
    { lat: 48.5710741, lng: 20.2244954 },
    { lat: 48.5713894, lng: 20.2245262 },
    { lat: 48.5717557, lng: 20.2248098 },
    { lat: 48.5722727, lng: 20.2250666 },
    { lat: 48.5730275, lng: 20.2260326 },
    { lat: 48.5733812, lng: 20.2262832 },
    { lat: 48.5737384, lng: 20.2264304 },
    { lat: 48.5744199, lng: 20.227533 },
    { lat: 48.5746417, lng: 20.2276722 },
    { lat: 48.5752247, lng: 20.2283946 },
    { lat: 48.5756695, lng: 20.2286178 },
    { lat: 48.5760886, lng: 20.2292431 },
    { lat: 48.5766824, lng: 20.2294524 },
    { lat: 48.577033, lng: 20.2295971 },
    { lat: 48.5787964, lng: 20.2290829 },
    { lat: 48.5794205, lng: 20.2292283 },
    { lat: 48.5797275, lng: 20.2291333 },
    { lat: 48.580123, lng: 20.2293076 },
    { lat: 48.5805756, lng: 20.2297567 },
    { lat: 48.5811339, lng: 20.2299732 },
    { lat: 48.5812596, lng: 20.2299056 },
    { lat: 48.5817614, lng: 20.2298658 },
    { lat: 48.5819967, lng: 20.2296136 },
    { lat: 48.5823404, lng: 20.2295021 },
    { lat: 48.584239, lng: 20.2291588 },
    { lat: 48.5843535, lng: 20.2295145 },
    { lat: 48.5843939, lng: 20.2301059 },
    { lat: 48.584589, lng: 20.2305912 },
    { lat: 48.5849036, lng: 20.2307676 },
    { lat: 48.5851135, lng: 20.2307535 },
    { lat: 48.5852837, lng: 20.2305883 },
    { lat: 48.5864736, lng: 20.2298661 },
    { lat: 48.5868076, lng: 20.2295733 },
    { lat: 48.5870648, lng: 20.2289089 },
    { lat: 48.587421, lng: 20.2284074 },
    { lat: 48.5878452, lng: 20.2281459 },
    { lat: 48.5881091, lng: 20.2280942 },
    { lat: 48.5888929, lng: 20.2281537 },
    { lat: 48.5891545, lng: 20.2282448 },
    { lat: 48.5898573, lng: 20.228582 },
    { lat: 48.5906981, lng: 20.2292784 },
    { lat: 48.5921556, lng: 20.2293355 },
    { lat: 48.5925691, lng: 20.22923 },
    { lat: 48.5926786, lng: 20.229377 },
    { lat: 48.5929961, lng: 20.2294275 },
    { lat: 48.593352, lng: 20.2294042 },
    { lat: 48.5936294, lng: 20.229299 },
    { lat: 48.5940824, lng: 20.2294457 },
    { lat: 48.5941095, lng: 20.229445 },
    { lat: 48.5942013, lng: 20.2284754 },
    { lat: 48.5943339, lng: 20.2281848 },
    { lat: 48.5947333, lng: 20.2276134 },
    { lat: 48.5948671, lng: 20.2272644 },
    { lat: 48.5950527, lng: 20.2265804 },
    { lat: 48.5951032, lng: 20.2258479 },
    { lat: 48.595229, lng: 20.2253583 },
    { lat: 48.5954216, lng: 20.224879 },
    { lat: 48.5957552, lng: 20.2244887 },
    { lat: 48.596251, lng: 20.224133 },
    { lat: 48.5965594, lng: 20.2235888 },
    { lat: 48.5970902, lng: 20.2222483 },
    { lat: 48.5972301, lng: 20.2217456 },
    { lat: 48.5973562, lng: 20.2208158 },
    { lat: 48.5975085, lng: 20.2204871 },
    { lat: 48.5979633, lng: 20.2200024 },
    { lat: 48.5982175, lng: 20.2198491 },
    { lat: 48.5984602, lng: 20.2195282 },
    { lat: 48.5988716, lng: 20.2192271 },
    { lat: 48.5992255, lng: 20.2187534 },
    { lat: 48.5994914, lng: 20.2179958 },
    { lat: 48.600201, lng: 20.217293 },
    { lat: 48.6005242, lng: 20.2176628 },
    { lat: 48.6007084, lng: 20.2180727 },
    { lat: 48.6008618, lng: 20.2180495 },
    { lat: 48.6011547, lng: 20.2178257 },
    { lat: 48.6015631, lng: 20.217025 },
    { lat: 48.6017596, lng: 20.2161908 },
    { lat: 48.6024249, lng: 20.2149471 },
    { lat: 48.6039234, lng: 20.2135008 },
    { lat: 48.6040688, lng: 20.2132253 },
    { lat: 48.604128, lng: 20.212758 },
    { lat: 48.604144, lng: 20.2117271 },
    { lat: 48.6039878, lng: 20.2111449 },
    { lat: 48.6037224, lng: 20.2104218 },
    { lat: 48.6038143, lng: 20.2101597 },
    { lat: 48.6043491, lng: 20.2081591 },
    { lat: 48.6042702, lng: 20.2076791 },
    { lat: 48.604278, lng: 20.2072848 },
    { lat: 48.6041397, lng: 20.2061149 },
    { lat: 48.603884, lng: 20.2050699 },
    { lat: 48.6037046, lng: 20.2045881 },
    { lat: 48.6034661, lng: 20.2042017 },
    { lat: 48.6031792, lng: 20.2035617 },
    { lat: 48.6029575, lng: 20.2025016 },
    { lat: 48.6026636, lng: 20.2024465 },
    { lat: 48.6023265, lng: 20.2020731 },
    { lat: 48.6022086, lng: 20.2017426 },
    { lat: 48.6020334, lng: 20.2004409 },
    { lat: 48.6019848, lng: 20.1997297 },
    { lat: 48.6017834, lng: 20.1987574 },
    { lat: 48.6017907, lng: 20.1982123 },
    { lat: 48.6014173, lng: 20.1969608 },
    { lat: 48.6009715, lng: 20.1959866 },
    { lat: 48.6007618, lng: 20.1956717 },
    { lat: 48.6004749, lng: 20.1949517 },
    { lat: 48.6000335, lng: 20.1923792 },
    { lat: 48.6000573, lng: 20.1918514 },
    { lat: 48.5999324, lng: 20.1907587 },
    { lat: 48.6000475, lng: 20.1900113 },
    { lat: 48.6003231, lng: 20.1892189 },
    { lat: 48.6002094, lng: 20.1884062 },
    { lat: 48.6002763, lng: 20.1878051 },
    { lat: 48.6005511, lng: 20.1869096 },
    { lat: 48.6003468, lng: 20.1864497 },
    { lat: 48.5997774, lng: 20.1854276 },
    { lat: 48.5996405, lng: 20.185216 },
    { lat: 48.5994188, lng: 20.1851381 },
    { lat: 48.5991802, lng: 20.1852535 },
    { lat: 48.5989046, lng: 20.1855132 },
    { lat: 48.5983216, lng: 20.1855833 },
    { lat: 48.5951408, lng: 20.1879514 },
    { lat: 48.5950016, lng: 20.1875706 },
    { lat: 48.5949328, lng: 20.1870073 },
    { lat: 48.5949636, lng: 20.1857852 },
    { lat: 48.5950522, lng: 20.1849384 },
    { lat: 48.5947672, lng: 20.1816684 },
    { lat: 48.595295, lng: 20.1812963 },
    { lat: 48.5953187, lng: 20.1806547 },
    { lat: 48.5951284, lng: 20.1786462 },
    { lat: 48.5952513, lng: 20.1785912 },
    { lat: 48.5954244, lng: 20.1772734 },
    { lat: 48.5954226, lng: 20.176725 },
    { lat: 48.595324, lng: 20.1762138 },
    { lat: 48.5952285, lng: 20.1749574 },
    { lat: 48.5951161, lng: 20.1747165 },
    { lat: 48.5950939, lng: 20.1746691 },
  ],
  Turčok: [
    { lat: 48.6576533, lng: 20.1124483 },
    { lat: 48.6573098, lng: 20.1125615 },
    { lat: 48.656905, lng: 20.1125479 },
    { lat: 48.6557235, lng: 20.1129601 },
    { lat: 48.6550978, lng: 20.1134964 },
    { lat: 48.6539929, lng: 20.1132509 },
    { lat: 48.6535078, lng: 20.1133702 },
    { lat: 48.6533318, lng: 20.1134988 },
    { lat: 48.6531037, lng: 20.1134854 },
    { lat: 48.6528914, lng: 20.113419 },
    { lat: 48.6527602, lng: 20.1132413 },
    { lat: 48.6525611, lng: 20.1132716 },
    { lat: 48.6520503, lng: 20.1140569 },
    { lat: 48.6515974, lng: 20.1143855 },
    { lat: 48.6514184, lng: 20.1144403 },
    { lat: 48.6508396, lng: 20.1149708 },
    { lat: 48.6504143, lng: 20.1150627 },
    { lat: 48.6502354, lng: 20.1150409 },
    { lat: 48.6494886, lng: 20.1146513 },
    { lat: 48.6476841, lng: 20.1147312 },
    { lat: 48.6454147, lng: 20.1173804 },
    { lat: 48.6451535, lng: 20.1175208 },
    { lat: 48.6448782, lng: 20.1175407 },
    { lat: 48.6445065, lng: 20.1179518 },
    { lat: 48.6438839, lng: 20.1179011 },
    { lat: 48.6436751, lng: 20.1180882 },
    { lat: 48.6433716, lng: 20.1184876 },
    { lat: 48.643086, lng: 20.1184988 },
    { lat: 48.6428074, lng: 20.1183224 },
    { lat: 48.6422639, lng: 20.1181728 },
    { lat: 48.641355, lng: 20.1182541 },
    { lat: 48.64013, lng: 20.1173007 },
    { lat: 48.6386415, lng: 20.1172557 },
    { lat: 48.638092, lng: 20.1168738 },
    { lat: 48.6377766, lng: 20.1165406 },
    { lat: 48.6374217, lng: 20.1163194 },
    { lat: 48.6371969, lng: 20.115997 },
    { lat: 48.6368024, lng: 20.1159076 },
    { lat: 48.6365094, lng: 20.1161247 },
    { lat: 48.6362344, lng: 20.116235 },
    { lat: 48.6360394, lng: 20.1164237 },
    { lat: 48.6358602, lng: 20.11719 },
    { lat: 48.6356807, lng: 20.1175678 },
    { lat: 48.6351824, lng: 20.1180233 },
    { lat: 48.6349078, lng: 20.1185238 },
    { lat: 48.6346513, lng: 20.1187788 },
    { lat: 48.634449, lng: 20.1184903 },
    { lat: 48.6336615, lng: 20.1187587 },
    { lat: 48.6325656, lng: 20.1193697 },
    { lat: 48.6323863, lng: 20.1199422 },
    { lat: 48.632125, lng: 20.1200808 },
    { lat: 48.6319241, lng: 20.1203979 },
    { lat: 48.6319493, lng: 20.1205472 },
    { lat: 48.6315719, lng: 20.1201874 },
    { lat: 48.6315302, lng: 20.1202137 },
    { lat: 48.6310771, lng: 20.1205094 },
    { lat: 48.6311288, lng: 20.1207809 },
    { lat: 48.6309101, lng: 20.1209967 },
    { lat: 48.6307588, lng: 20.1213486 },
    { lat: 48.6304758, lng: 20.1215045 },
    { lat: 48.6302446, lng: 20.1215552 },
    { lat: 48.6301169, lng: 20.1214325 },
    { lat: 48.6293675, lng: 20.1220341 },
    { lat: 48.629273, lng: 20.1219492 },
    { lat: 48.629218, lng: 20.1215384 },
    { lat: 48.6290708, lng: 20.1212174 },
    { lat: 48.6287527, lng: 20.1207396 },
    { lat: 48.6285663, lng: 20.1205614 },
    { lat: 48.6277446, lng: 20.1202404 },
    { lat: 48.6275555, lng: 20.1203549 },
    { lat: 48.627284, lng: 20.1207931 },
    { lat: 48.6273391, lng: 20.1209276 },
    { lat: 48.6276266, lng: 20.120882 },
    { lat: 48.6276947, lng: 20.1210052 },
    { lat: 48.6281288, lng: 20.121379 },
    { lat: 48.6283707, lng: 20.1222509 },
    { lat: 48.6283298, lng: 20.1226296 },
    { lat: 48.6284105, lng: 20.1231448 },
    { lat: 48.6294442, lng: 20.1243228 },
    { lat: 48.6267465, lng: 20.1270246 },
    { lat: 48.6248339, lng: 20.1262511 },
    { lat: 48.6214282, lng: 20.1266415 },
    { lat: 48.621118, lng: 20.1266632 },
    { lat: 48.6212214, lng: 20.1278138 },
    { lat: 48.6211793, lng: 20.1282752 },
    { lat: 48.6207762, lng: 20.1292431 },
    { lat: 48.6206923, lng: 20.1296439 },
    { lat: 48.6203163, lng: 20.1301188 },
    { lat: 48.6197801, lng: 20.1303935 },
    { lat: 48.6195086, lng: 20.1308586 },
    { lat: 48.6190186, lng: 20.1313579 },
    { lat: 48.6188496, lng: 20.1316584 },
    { lat: 48.6189043, lng: 20.1320623 },
    { lat: 48.6198645, lng: 20.133739 },
    { lat: 48.6207954, lng: 20.1354947 },
    { lat: 48.6216453, lng: 20.1378033 },
    { lat: 48.6215705, lng: 20.138378 },
    { lat: 48.6216321, lng: 20.1389469 },
    { lat: 48.6215471, lng: 20.1396319 },
    { lat: 48.6220233, lng: 20.1413707 },
    { lat: 48.6221373, lng: 20.141735 },
    { lat: 48.62225, lng: 20.1419296 },
    { lat: 48.6224236, lng: 20.1425437 },
    { lat: 48.6225048, lng: 20.143073 },
    { lat: 48.6219436, lng: 20.1436721 },
    { lat: 48.6215322, lng: 20.1443609 },
    { lat: 48.621075, lng: 20.1449179 },
    { lat: 48.6206232, lng: 20.1453285 },
    { lat: 48.6200062, lng: 20.1457468 },
    { lat: 48.6198013, lng: 20.1460591 },
    { lat: 48.6188383, lng: 20.1467796 },
    { lat: 48.6176597, lng: 20.1485127 },
    { lat: 48.6173337, lng: 20.148829 },
    { lat: 48.6170272, lng: 20.1489937 },
    { lat: 48.6172553, lng: 20.1501498 },
    { lat: 48.6172722, lng: 20.1508683 },
    { lat: 48.6172282, lng: 20.1513433 },
    { lat: 48.6169762, lng: 20.1520119 },
    { lat: 48.6168157, lng: 20.1522222 },
    { lat: 48.6164313, lng: 20.152934 },
    { lat: 48.6162527, lng: 20.1534993 },
    { lat: 48.6162597, lng: 20.1538699 },
    { lat: 48.6163581, lng: 20.1544044 },
    { lat: 48.6163577, lng: 20.1552865 },
    { lat: 48.6162975, lng: 20.155606 },
    { lat: 48.6161864, lng: 20.1557579 },
    { lat: 48.616084, lng: 20.1560105 },
    { lat: 48.6162211, lng: 20.1560843 },
    { lat: 48.616427, lng: 20.156081 },
    { lat: 48.6165416, lng: 20.1566556 },
    { lat: 48.6165476, lng: 20.1574435 },
    { lat: 48.6163655, lng: 20.1582043 },
    { lat: 48.6163769, lng: 20.1584844 },
    { lat: 48.6167318, lng: 20.160266 },
    { lat: 48.6167994, lng: 20.1609329 },
    { lat: 48.6167783, lng: 20.1618874 },
    { lat: 48.6168211, lng: 20.1620821 },
    { lat: 48.6168258, lng: 20.1625077 },
    { lat: 48.6167911, lng: 20.1630961 },
    { lat: 48.6169136, lng: 20.1641449 },
    { lat: 48.6169443, lng: 20.1647942 },
    { lat: 48.6169182, lng: 20.1650105 },
    { lat: 48.6169857, lng: 20.1652339 },
    { lat: 48.6170388, lng: 20.1660456 },
    { lat: 48.6173782, lng: 20.1666405 },
    { lat: 48.6173905, lng: 20.1667743 },
    { lat: 48.6174882, lng: 20.1668514 },
    { lat: 48.6175005, lng: 20.1669866 },
    { lat: 48.6176589, lng: 20.1672846 },
    { lat: 48.6178498, lng: 20.1671617 },
    { lat: 48.6179992, lng: 20.1671497 },
    { lat: 48.6183871, lng: 20.1668257 },
    { lat: 48.6184263, lng: 20.1667099 },
    { lat: 48.6188407, lng: 20.1665405 },
    { lat: 48.6190529, lng: 20.1663586 },
    { lat: 48.6195153, lng: 20.166373 },
    { lat: 48.6201859, lng: 20.1659372 },
    { lat: 48.6204916, lng: 20.1655876 },
    { lat: 48.6210875, lng: 20.1653947 },
    { lat: 48.6214052, lng: 20.1648945 },
    { lat: 48.6215769, lng: 20.1647149 },
    { lat: 48.6217905, lng: 20.164676 },
    { lat: 48.6218792, lng: 20.164366 },
    { lat: 48.6221067, lng: 20.1641429 },
    { lat: 48.6224328, lng: 20.1635984 },
    { lat: 48.6227768, lng: 20.1627213 },
    { lat: 48.6229536, lng: 20.1624942 },
    { lat: 48.6231942, lng: 20.162346 },
    { lat: 48.6233432, lng: 20.1620803 },
    { lat: 48.6235115, lng: 20.1620028 },
    { lat: 48.6236601, lng: 20.161699 },
    { lat: 48.623715, lng: 20.1616907 },
    { lat: 48.6237474, lng: 20.1615475 },
    { lat: 48.6238517, lng: 20.1615343 },
    { lat: 48.6246144, lng: 20.1607859 },
    { lat: 48.6250478, lng: 20.160449 },
    { lat: 48.6253293, lng: 20.1601675 },
    { lat: 48.6255047, lng: 20.1598836 },
    { lat: 48.6258859, lng: 20.1595071 },
    { lat: 48.6266167, lng: 20.1607275 },
    { lat: 48.6268267, lng: 20.1613504 },
    { lat: 48.6270405, lng: 20.1616814 },
    { lat: 48.6271369, lng: 20.1619452 },
    { lat: 48.6275756, lng: 20.1622651 },
    { lat: 48.6280743, lng: 20.1631225 },
    { lat: 48.6283572, lng: 20.1641596 },
    { lat: 48.6287958, lng: 20.1648669 },
    { lat: 48.628928, lng: 20.1651844 },
    { lat: 48.6290055, lng: 20.1659556 },
    { lat: 48.6291894, lng: 20.1667451 },
    { lat: 48.6293137, lng: 20.1677295 },
    { lat: 48.6295568, lng: 20.1685068 },
    { lat: 48.6297794, lng: 20.1689471 },
    { lat: 48.6305569, lng: 20.1695371 },
    { lat: 48.6310355, lng: 20.1699816 },
    { lat: 48.6313827, lng: 20.1700466 },
    { lat: 48.6318039, lng: 20.1702663 },
    { lat: 48.6321895, lng: 20.1703363 },
    { lat: 48.6329768, lng: 20.1706143 },
    { lat: 48.6332921, lng: 20.170639 },
    { lat: 48.633527, lng: 20.1705174 },
    { lat: 48.6343478, lng: 20.1702881 },
    { lat: 48.6347148, lng: 20.1703113 },
    { lat: 48.6352307, lng: 20.170511 },
    { lat: 48.6356636, lng: 20.1707974 },
    { lat: 48.6362288, lng: 20.1709594 },
    { lat: 48.63825, lng: 20.1722048 },
    { lat: 48.6391037, lng: 20.1720092 },
    { lat: 48.6394261, lng: 20.1720967 },
    { lat: 48.6397514, lng: 20.1720394 },
    { lat: 48.6402819, lng: 20.1722898 },
    { lat: 48.6403726, lng: 20.1723612 },
    { lat: 48.6407004, lng: 20.1716669 },
    { lat: 48.6410802, lng: 20.1702769 },
    { lat: 48.6413338, lng: 20.1696963 },
    { lat: 48.641417, lng: 20.1689963 },
    { lat: 48.6418012, lng: 20.1674364 },
    { lat: 48.6417808, lng: 20.1665714 },
    { lat: 48.6416515, lng: 20.1658522 },
    { lat: 48.6417526, lng: 20.1645658 },
    { lat: 48.6433726, lng: 20.1631527 },
    { lat: 48.6439101, lng: 20.1628243 },
    { lat: 48.6445502, lng: 20.1628647 },
    { lat: 48.6448486, lng: 20.1628016 },
    { lat: 48.645152, lng: 20.1625379 },
    { lat: 48.6456693, lng: 20.1620468 },
    { lat: 48.6458678, lng: 20.1616036 },
    { lat: 48.6460839, lng: 20.1599621 },
    { lat: 48.6463478, lng: 20.1595274 },
    { lat: 48.6469311, lng: 20.1591318 },
    { lat: 48.6473687, lng: 20.1590334 },
    { lat: 48.6475079, lng: 20.15894 },
    { lat: 48.647725, lng: 20.1586251 },
    { lat: 48.647892, lng: 20.1585509 },
    { lat: 48.648225, lng: 20.1586495 },
    { lat: 48.6483928, lng: 20.1585981 },
    { lat: 48.6485016, lng: 20.1584185 },
    { lat: 48.6488631, lng: 20.1582822 },
    { lat: 48.6492684, lng: 20.157997 },
    { lat: 48.649846, lng: 20.1577713 },
    { lat: 48.6506105, lng: 20.1570671 },
    { lat: 48.6508644, lng: 20.1566059 },
    { lat: 48.6514588, lng: 20.1559641 },
    { lat: 48.6518364, lng: 20.1556663 },
    { lat: 48.6523305, lng: 20.1550166 },
    { lat: 48.6525188, lng: 20.1549098 },
    { lat: 48.6529325, lng: 20.1549728 },
    { lat: 48.6537087, lng: 20.1548097 },
    { lat: 48.6541825, lng: 20.1550217 },
    { lat: 48.6543427, lng: 20.1550023 },
    { lat: 48.6557044, lng: 20.1545068 },
    { lat: 48.6559684, lng: 20.1545676 },
    { lat: 48.6565381, lng: 20.154426 },
    { lat: 48.6567574, lng: 20.1542768 },
    { lat: 48.6571982, lng: 20.1537032 },
    { lat: 48.6578425, lng: 20.1534 },
    { lat: 48.657934, lng: 20.1533513 },
    { lat: 48.6580536, lng: 20.1527165 },
    { lat: 48.6583134, lng: 20.1522956 },
    { lat: 48.6586325, lng: 20.151632 },
    { lat: 48.6587973, lng: 20.1511729 },
    { lat: 48.6589017, lng: 20.1504949 },
    { lat: 48.6588735, lng: 20.1502193 },
    { lat: 48.6589209, lng: 20.1497742 },
    { lat: 48.6592891, lng: 20.1486347 },
    { lat: 48.6594181, lng: 20.1475105 },
    { lat: 48.6595928, lng: 20.1468189 },
    { lat: 48.6597791, lng: 20.1463628 },
    { lat: 48.6602307, lng: 20.1456662 },
    { lat: 48.6603845, lng: 20.1455141 },
    { lat: 48.6609475, lng: 20.1445688 },
    { lat: 48.661154, lng: 20.1441023 },
    { lat: 48.6617618, lng: 20.1431218 },
    { lat: 48.6618094, lng: 20.1428014 },
    { lat: 48.6618153, lng: 20.1421667 },
    { lat: 48.6616473, lng: 20.141412 },
    { lat: 48.6615626, lng: 20.1412691 },
    { lat: 48.6614878, lng: 20.140898 },
    { lat: 48.6614929, lng: 20.1406346 },
    { lat: 48.6613064, lng: 20.1399817 },
    { lat: 48.6612814, lng: 20.1397546 },
    { lat: 48.6613853, lng: 20.1387634 },
    { lat: 48.6612933, lng: 20.1384388 },
    { lat: 48.6609785, lng: 20.1377907 },
    { lat: 48.6608551, lng: 20.1373044 },
    { lat: 48.66068, lng: 20.1372344 },
    { lat: 48.6604809, lng: 20.1369935 },
    { lat: 48.6599867, lng: 20.1366406 },
    { lat: 48.6595392, lng: 20.13649 },
    { lat: 48.6597292, lng: 20.1358454 },
    { lat: 48.6596251, lng: 20.1354735 },
    { lat: 48.6595369, lng: 20.1348838 },
    { lat: 48.6594539, lng: 20.1341437 },
    { lat: 48.6594908, lng: 20.1337203 },
    { lat: 48.6593951, lng: 20.1329764 },
    { lat: 48.659708, lng: 20.1316188 },
    { lat: 48.6596713, lng: 20.1309364 },
    { lat: 48.6597342, lng: 20.130326 },
    { lat: 48.6598762, lng: 20.1299345 },
    { lat: 48.6600727, lng: 20.1290626 },
    { lat: 48.660045, lng: 20.1289113 },
    { lat: 48.6597361, lng: 20.1284308 },
    { lat: 48.6593472, lng: 20.1274598 },
    { lat: 48.6593607, lng: 20.1250295 },
    { lat: 48.659268, lng: 20.124798 },
    { lat: 48.6592106, lng: 20.1241381 },
    { lat: 48.6592823, lng: 20.1232162 },
    { lat: 48.6592576, lng: 20.1228024 },
    { lat: 48.6591012, lng: 20.1218956 },
    { lat: 48.6591049, lng: 20.1209904 },
    { lat: 48.6589073, lng: 20.1203472 },
    { lat: 48.6588773, lng: 20.1198836 },
    { lat: 48.659031, lng: 20.1184115 },
    { lat: 48.6589744, lng: 20.1178517 },
    { lat: 48.6587161, lng: 20.1165732 },
    { lat: 48.658648, lng: 20.1157591 },
    { lat: 48.6585333, lng: 20.1151823 },
    { lat: 48.6584801, lng: 20.1149613 },
    { lat: 48.6582793, lng: 20.1146202 },
    { lat: 48.6581103, lng: 20.1141247 },
    { lat: 48.6579636, lng: 20.1133699 },
    { lat: 48.657823, lng: 20.1129095 },
    { lat: 48.6576533, lng: 20.1124483 },
  ],
  Ratková: [
    { lat: 48.5855079, lng: 20.1199184 },
    { lat: 48.5861385, lng: 20.1196976 },
    { lat: 48.5866206, lng: 20.1201926 },
    { lat: 48.5867897, lng: 20.1204714 },
    { lat: 48.5869478, lng: 20.1208993 },
    { lat: 48.587139, lng: 20.1209157 },
    { lat: 48.5874308, lng: 20.120837 },
    { lat: 48.5878716, lng: 20.1210377 },
    { lat: 48.5881203, lng: 20.1210594 },
    { lat: 48.5886797, lng: 20.1204994 },
    { lat: 48.5888659, lng: 20.1202324 },
    { lat: 48.5892474, lng: 20.1203112 },
    { lat: 48.5896758, lng: 20.1202372 },
    { lat: 48.5899144, lng: 20.1201486 },
    { lat: 48.5901911, lng: 20.1199208 },
    { lat: 48.590704, lng: 20.1199041 },
    { lat: 48.5910571, lng: 20.1199731 },
    { lat: 48.5916547, lng: 20.1196842 },
    { lat: 48.5926927, lng: 20.119365 },
    { lat: 48.5945881, lng: 20.1184802 },
    { lat: 48.5945851, lng: 20.1181079 },
    { lat: 48.5946805, lng: 20.1178479 },
    { lat: 48.5950032, lng: 20.1173653 },
    { lat: 48.5955041, lng: 20.1161207 },
    { lat: 48.5956267, lng: 20.1159488 },
    { lat: 48.5956628, lng: 20.1158982 },
    { lat: 48.5960609, lng: 20.1151691 },
    { lat: 48.596236, lng: 20.1143764 },
    { lat: 48.5965138, lng: 20.1137955 },
    { lat: 48.5972989, lng: 20.1128286 },
    { lat: 48.5975197, lng: 20.1123629 },
    { lat: 48.5976095, lng: 20.111918 },
    { lat: 48.5977963, lng: 20.1114412 },
    { lat: 48.5982634, lng: 20.1107718 },
    { lat: 48.5990151, lng: 20.1102361 },
    { lat: 48.5994423, lng: 20.1096373 },
    { lat: 48.5997202, lng: 20.1089986 },
    { lat: 48.6001108, lng: 20.1077253 },
    { lat: 48.6001835, lng: 20.1073285 },
    { lat: 48.6003992, lng: 20.1067394 },
    { lat: 48.6014985, lng: 20.1048438 },
    { lat: 48.6016468, lng: 20.104366 },
    { lat: 48.6028036, lng: 20.102993 },
    { lat: 48.6037153, lng: 20.1023166 },
    { lat: 48.6037702, lng: 20.1022876 },
    { lat: 48.6043745, lng: 20.1019722 },
    { lat: 48.604694, lng: 20.1019079 },
    { lat: 48.6059436, lng: 20.1019205 },
    { lat: 48.6063745, lng: 20.1018494 },
    { lat: 48.6072621, lng: 20.1011904 },
    { lat: 48.6077379, lng: 20.1007129 },
    { lat: 48.6077714, lng: 20.1004816 },
    { lat: 48.608208, lng: 20.0998714 },
    { lat: 48.6085993, lng: 20.099773 },
    { lat: 48.609222, lng: 20.0999875 },
    { lat: 48.6096646, lng: 20.0999598 },
    { lat: 48.6097974, lng: 20.0998199 },
    { lat: 48.6099907, lng: 20.0994812 },
    { lat: 48.6100393, lng: 20.098962 },
    { lat: 48.6100245, lng: 20.0981369 },
    { lat: 48.609836, lng: 20.0977641 },
    { lat: 48.6098685, lng: 20.0974073 },
    { lat: 48.6101068, lng: 20.0969262 },
    { lat: 48.6102745, lng: 20.0962393 },
    { lat: 48.6104059, lng: 20.0961181 },
    { lat: 48.6105894, lng: 20.0957593 },
    { lat: 48.6111505, lng: 20.0943127 },
    { lat: 48.6113026, lng: 20.0940376 },
    { lat: 48.6114659, lng: 20.0934632 },
    { lat: 48.6118101, lng: 20.0929601 },
    { lat: 48.611928, lng: 20.0926191 },
    { lat: 48.6123146, lng: 20.0918875 },
    { lat: 48.6130788, lng: 20.091295 },
    { lat: 48.6150205, lng: 20.08883 },
    { lat: 48.6152496, lng: 20.0889207 },
    { lat: 48.6154203, lng: 20.088663 },
    { lat: 48.6155147, lng: 20.0883299 },
    { lat: 48.6161493, lng: 20.0875198 },
    { lat: 48.6167434, lng: 20.0869584 },
    { lat: 48.6172489, lng: 20.0867316 },
    { lat: 48.6179789, lng: 20.0868168 },
    { lat: 48.6184013, lng: 20.0864301 },
    { lat: 48.6185992, lng: 20.0864223 },
    { lat: 48.6189291, lng: 20.0861515 },
    { lat: 48.6194556, lng: 20.086161 },
    { lat: 48.6200365, lng: 20.085715 },
    { lat: 48.6208636, lng: 20.0856134 },
    { lat: 48.6215998, lng: 20.0851368 },
    { lat: 48.6217614, lng: 20.0849334 },
    { lat: 48.6228046, lng: 20.0847737 },
    { lat: 48.6229267, lng: 20.0847296 },
    { lat: 48.6230247, lng: 20.0844836 },
    { lat: 48.6234338, lng: 20.084461 },
    { lat: 48.6235692, lng: 20.084386 },
    { lat: 48.6237915, lng: 20.0844773 },
    { lat: 48.6240396, lng: 20.0842643 },
    { lat: 48.6244681, lng: 20.0846579 },
    { lat: 48.6247745, lng: 20.0847445 },
    { lat: 48.6252099, lng: 20.0844862 },
    { lat: 48.6255159, lng: 20.0844487 },
    { lat: 48.6260406, lng: 20.0841962 },
    { lat: 48.6267991, lng: 20.0840732 },
    { lat: 48.626939, lng: 20.0839686 },
    { lat: 48.6273467, lng: 20.0831211 },
    { lat: 48.6278443, lng: 20.081719 },
    { lat: 48.6280692, lng: 20.0815164 },
    { lat: 48.6287334, lng: 20.0811782 },
    { lat: 48.6289793, lng: 20.0809703 },
    { lat: 48.6293268, lng: 20.0808758 },
    { lat: 48.6297904, lng: 20.0806198 },
    { lat: 48.6310053, lng: 20.0793198 },
    { lat: 48.6312845, lng: 20.0791523 },
    { lat: 48.6321584, lng: 20.0782517 },
    { lat: 48.6326618, lng: 20.0778659 },
    { lat: 48.6330622, lng: 20.0774384 },
    { lat: 48.6338497, lng: 20.0759419 },
    { lat: 48.6343357, lng: 20.0754612 },
    { lat: 48.6345632, lng: 20.0753793 },
    { lat: 48.6354786, lng: 20.0754283 },
    { lat: 48.6356633, lng: 20.0753156 },
    { lat: 48.6364987, lng: 20.0753878 },
    { lat: 48.6370458, lng: 20.0752998 },
    { lat: 48.6375748, lng: 20.0755368 },
    { lat: 48.6378828, lng: 20.0755756 },
    { lat: 48.6381613, lng: 20.0755302 },
    { lat: 48.6384107, lng: 20.0752946 },
    { lat: 48.6384476, lng: 20.0752601 },
    { lat: 48.6388324, lng: 20.0733119 },
    { lat: 48.6389261, lng: 20.0721432 },
    { lat: 48.6388929, lng: 20.0712015 },
    { lat: 48.638508, lng: 20.0690517 },
    { lat: 48.6385407, lng: 20.0679051 },
    { lat: 48.6383651, lng: 20.0677161 },
    { lat: 48.6381226, lng: 20.0676004 },
    { lat: 48.6376656, lng: 20.0669254 },
    { lat: 48.6367364, lng: 20.0659996 },
    { lat: 48.6361802, lng: 20.065106 },
    { lat: 48.6360073, lng: 20.0649552 },
    { lat: 48.6360346, lng: 20.0649064 },
    { lat: 48.6356857, lng: 20.0641522 },
    { lat: 48.635678, lng: 20.0639652 },
    { lat: 48.6354899, lng: 20.0641247 },
    { lat: 48.6353043, lng: 20.0641873 },
    { lat: 48.6352789, lng: 20.0648001 },
    { lat: 48.6349143, lng: 20.064865 },
    { lat: 48.6344524, lng: 20.0648066 },
    { lat: 48.6340338, lng: 20.0651416 },
    { lat: 48.6334715, lng: 20.0657101 },
    { lat: 48.6331793, lng: 20.0657507 },
    { lat: 48.6331056, lng: 20.0655792 },
    { lat: 48.6326721, lng: 20.0661296 },
    { lat: 48.6324091, lng: 20.066196 },
    { lat: 48.6322452, lng: 20.0661245 },
    { lat: 48.6322277, lng: 20.0661938 },
    { lat: 48.6320725, lng: 20.0662023 },
    { lat: 48.6316902, lng: 20.0664088 },
    { lat: 48.6316659, lng: 20.0663631 },
    { lat: 48.6315293, lng: 20.0664898 },
    { lat: 48.6311527, lng: 20.0664818 },
    { lat: 48.631005, lng: 20.0663686 },
    { lat: 48.6308809, lng: 20.0661171 },
    { lat: 48.6297921, lng: 20.065826 },
    { lat: 48.6287091, lng: 20.0664427 },
    { lat: 48.6283395, lng: 20.0663564 },
    { lat: 48.6280887, lng: 20.0664515 },
    { lat: 48.6274271, lng: 20.0674227 },
    { lat: 48.6266383, lng: 20.0679822 },
    { lat: 48.6266653, lng: 20.0680633 },
    { lat: 48.6258313, lng: 20.0690916 },
    { lat: 48.6252585, lng: 20.0696759 },
    { lat: 48.6244621, lng: 20.0702449 },
    { lat: 48.6241288, lng: 20.0706062 },
    { lat: 48.6234724, lng: 20.0719773 },
    { lat: 48.6233094, lng: 20.0722048 },
    { lat: 48.6228643, lng: 20.0715687 },
    { lat: 48.6228333, lng: 20.0717215 },
    { lat: 48.6227, lng: 20.0719573 },
    { lat: 48.6224175, lng: 20.072291 },
    { lat: 48.6222383, lng: 20.0724204 },
    { lat: 48.6222194, lng: 20.0724043 },
    { lat: 48.6220598, lng: 20.0725179 },
    { lat: 48.6216627, lng: 20.0728283 },
    { lat: 48.6214139, lng: 20.0731094 },
    { lat: 48.6212752, lng: 20.0731559 },
    { lat: 48.62135, lng: 20.0733621 },
    { lat: 48.6211431, lng: 20.0735349 },
    { lat: 48.6204009, lng: 20.0744658 },
    { lat: 48.6202293, lng: 20.0745915 },
    { lat: 48.6199678, lng: 20.0745854 },
    { lat: 48.6198944, lng: 20.0746386 },
    { lat: 48.6195749, lng: 20.0750281 },
    { lat: 48.6194268, lng: 20.0754489 },
    { lat: 48.6186706, lng: 20.0760702 },
    { lat: 48.6186496, lng: 20.0762416 },
    { lat: 48.6187609, lng: 20.076674 },
    { lat: 48.6186609, lng: 20.076928 },
    { lat: 48.6183148, lng: 20.0771142 },
    { lat: 48.6179612, lng: 20.0771431 },
    { lat: 48.617749, lng: 20.0772687 },
    { lat: 48.6177033, lng: 20.0773624 },
    { lat: 48.6177026, lng: 20.0780826 },
    { lat: 48.6176428, lng: 20.0784602 },
    { lat: 48.6174919, lng: 20.078573 },
    { lat: 48.6172768, lng: 20.078324 },
    { lat: 48.6172001, lng: 20.078354 },
    { lat: 48.6170299, lng: 20.0786782 },
    { lat: 48.6167459, lng: 20.0794399 },
    { lat: 48.6163799, lng: 20.0794112 },
    { lat: 48.6162537, lng: 20.0794647 },
    { lat: 48.6161554, lng: 20.0797608 },
    { lat: 48.6162147, lng: 20.0802141 },
    { lat: 48.6161455, lng: 20.0805862 },
    { lat: 48.6157724, lng: 20.0806617 },
    { lat: 48.6155208, lng: 20.0808109 },
    { lat: 48.6152318, lng: 20.0811014 },
    { lat: 48.6147117, lng: 20.0813264 },
    { lat: 48.6143992, lng: 20.0813094 },
    { lat: 48.6140312, lng: 20.0814285 },
    { lat: 48.613808, lng: 20.0813444 },
    { lat: 48.6134018, lng: 20.0816267 },
    { lat: 48.6129918, lng: 20.0817914 },
    { lat: 48.6129911, lng: 20.0820215 },
    { lat: 48.6124576, lng: 20.0823868 },
    { lat: 48.6124878, lng: 20.0824904 },
    { lat: 48.6122089, lng: 20.082634 },
    { lat: 48.6120822, lng: 20.0827338 },
    { lat: 48.6120933, lng: 20.0827854 },
    { lat: 48.6116963, lng: 20.0829902 },
    { lat: 48.6114627, lng: 20.0830032 },
    { lat: 48.6104586, lng: 20.0833239 },
    { lat: 48.6103324, lng: 20.0836194 },
    { lat: 48.6098946, lng: 20.0838484 },
    { lat: 48.6094469, lng: 20.0844124 },
    { lat: 48.6090881, lng: 20.0843712 },
    { lat: 48.6083362, lng: 20.0844875 },
    { lat: 48.6080363, lng: 20.0852235 },
    { lat: 48.6077751, lng: 20.0856313 },
    { lat: 48.6076135, lng: 20.085761 },
    { lat: 48.6073801, lng: 20.0856704 },
    { lat: 48.6070134, lng: 20.0857115 },
    { lat: 48.6064354, lng: 20.0859809 },
    { lat: 48.6061918, lng: 20.0863254 },
    { lat: 48.6054392, lng: 20.0869542 },
    { lat: 48.6052294, lng: 20.0873561 },
    { lat: 48.6050696, lng: 20.0875497 },
    { lat: 48.6050104, lng: 20.0875331 },
    { lat: 48.6047625, lng: 20.0879158 },
    { lat: 48.6047571, lng: 20.0886348 },
    { lat: 48.6048349, lng: 20.0889358 },
    { lat: 48.6050032, lng: 20.0891378 },
    { lat: 48.6050239, lng: 20.0893423 },
    { lat: 48.6045002, lng: 20.0905216 },
    { lat: 48.604544, lng: 20.0906274 },
    { lat: 48.6043255, lng: 20.0907368 },
    { lat: 48.6038869, lng: 20.0915508 },
    { lat: 48.6037379, lng: 20.0913195 },
    { lat: 48.6036112, lng: 20.0912805 },
    { lat: 48.6035832, lng: 20.0914785 },
    { lat: 48.6029883, lng: 20.0922967 },
    { lat: 48.6028048, lng: 20.0922704 },
    { lat: 48.602761, lng: 20.0920777 },
    { lat: 48.6026624, lng: 20.0919823 },
    { lat: 48.602225, lng: 20.0921118 },
    { lat: 48.60216, lng: 20.0916004 },
    { lat: 48.6020277, lng: 20.0911488 },
    { lat: 48.6020137, lng: 20.0908376 },
    { lat: 48.6018894, lng: 20.0903866 },
    { lat: 48.6017933, lng: 20.0902415 },
    { lat: 48.6014082, lng: 20.0903006 },
    { lat: 48.6012686, lng: 20.08915 },
    { lat: 48.6011087, lng: 20.0886027 },
    { lat: 48.6011257, lng: 20.0883338 },
    { lat: 48.6009986, lng: 20.087871 },
    { lat: 48.6009352, lng: 20.087346 },
    { lat: 48.6007831, lng: 20.0870396 },
    { lat: 48.6005478, lng: 20.086305 },
    { lat: 48.6004941, lng: 20.08574 },
    { lat: 48.6005364, lng: 20.085152 },
    { lat: 48.6005137, lng: 20.0848474 },
    { lat: 48.6004039, lng: 20.0845876 },
    { lat: 48.6004387, lng: 20.0842861 },
    { lat: 48.6003619, lng: 20.0835108 },
    { lat: 48.6004512, lng: 20.0831615 },
    { lat: 48.6004423, lng: 20.0829221 },
    { lat: 48.6001675, lng: 20.0821013 },
    { lat: 48.6001499, lng: 20.0817043 },
    { lat: 48.5996476, lng: 20.0800365 },
    { lat: 48.5996442, lng: 20.0798447 },
    { lat: 48.5994053, lng: 20.0790749 },
    { lat: 48.5997661, lng: 20.0786728 },
    { lat: 48.6000633, lng: 20.0780948 },
    { lat: 48.6001149, lng: 20.0778693 },
    { lat: 48.6001759, lng: 20.076322 },
    { lat: 48.6002609, lng: 20.0757607 },
    { lat: 48.6002716, lng: 20.0753352 },
    { lat: 48.6000175, lng: 20.0740658 },
    { lat: 48.5998436, lng: 20.0737544 },
    { lat: 48.5997304, lng: 20.0730284 },
    { lat: 48.5998057, lng: 20.0722071 },
    { lat: 48.5999803, lng: 20.0715804 },
    { lat: 48.6000906, lng: 20.0709484 },
    { lat: 48.6000837, lng: 20.0701288 },
    { lat: 48.5998052, lng: 20.0693859 },
    { lat: 48.5991902, lng: 20.0681615 },
    { lat: 48.5991829, lng: 20.0680344 },
    { lat: 48.5992488, lng: 20.0678311 },
    { lat: 48.5995266, lng: 20.0674136 },
    { lat: 48.5998289, lng: 20.0671507 },
    { lat: 48.6002618, lng: 20.0657691 },
    { lat: 48.6003626, lng: 20.0645867 },
    { lat: 48.6003185, lng: 20.0640905 },
    { lat: 48.6004194, lng: 20.0635049 },
    { lat: 48.6004405, lng: 20.062983 },
    { lat: 48.6007331, lng: 20.0624558 },
    { lat: 48.6005055, lng: 20.0618555 },
    { lat: 48.6004944, lng: 20.0614772 },
    { lat: 48.6005319, lng: 20.0610278 },
    { lat: 48.60074, lng: 20.060635 },
    { lat: 48.600614, lng: 20.05979 },
    { lat: 48.600529, lng: 20.059346 },
    { lat: 48.600532, lng: 20.059269 },
    { lat: 48.600508, lng: 20.059282 },
    { lat: 48.60038, lng: 20.059352 },
    { lat: 48.598864, lng: 20.060185 },
    { lat: 48.598599, lng: 20.060343 },
    { lat: 48.598426, lng: 20.060447 },
    { lat: 48.598008, lng: 20.06068 },
    { lat: 48.597855, lng: 20.060773 },
    { lat: 48.597704, lng: 20.060865 },
    { lat: 48.597411, lng: 20.060873 },
    { lat: 48.597194, lng: 20.060884 },
    { lat: 48.596854, lng: 20.060903 },
    { lat: 48.596759, lng: 20.060909 },
    { lat: 48.596696, lng: 20.060912 },
    { lat: 48.596114, lng: 20.060944 },
    { lat: 48.595385, lng: 20.06143 },
    { lat: 48.594844, lng: 20.061791 },
    { lat: 48.5944, lng: 20.062086 },
    { lat: 48.594257, lng: 20.062182 },
    { lat: 48.593786, lng: 20.062499 },
    { lat: 48.593584, lng: 20.062693 },
    { lat: 48.593351, lng: 20.063037 },
    { lat: 48.593226, lng: 20.063108 },
    { lat: 48.593191, lng: 20.063127 },
    { lat: 48.59301, lng: 20.063378 },
    { lat: 48.59265, lng: 20.063615 },
    { lat: 48.592624, lng: 20.063631 },
    { lat: 48.592612, lng: 20.06364 },
    { lat: 48.592501, lng: 20.063667 },
    { lat: 48.592013, lng: 20.063791 },
    { lat: 48.591896, lng: 20.063778 },
    { lat: 48.591725, lng: 20.063989 },
    { lat: 48.591592, lng: 20.064216 },
    { lat: 48.591416, lng: 20.064072 },
    { lat: 48.591278, lng: 20.06387 },
    { lat: 48.591177, lng: 20.063763 },
    { lat: 48.591128, lng: 20.063731 },
    { lat: 48.590985, lng: 20.063701 },
    { lat: 48.590871, lng: 20.063563 },
    { lat: 48.590768, lng: 20.063493 },
    { lat: 48.590613, lng: 20.063318 },
    { lat: 48.590332, lng: 20.063151 },
    { lat: 48.590145, lng: 20.063152 },
    { lat: 48.589997, lng: 20.063262 },
    { lat: 48.589916, lng: 20.063288 },
    { lat: 48.589758, lng: 20.063222 },
    { lat: 48.589652, lng: 20.063271 },
    { lat: 48.589538, lng: 20.063317 },
    { lat: 48.589408, lng: 20.063506 },
    { lat: 48.58922, lng: 20.063606 },
    { lat: 48.589134, lng: 20.063559 },
    { lat: 48.589066, lng: 20.063541 },
    { lat: 48.588977, lng: 20.063541 },
    { lat: 48.588634, lng: 20.063504 },
    { lat: 48.588475, lng: 20.063523 },
    { lat: 48.588408, lng: 20.063478 },
    { lat: 48.588363, lng: 20.063483 },
    { lat: 48.588318, lng: 20.063563 },
    { lat: 48.588237, lng: 20.063708 },
    { lat: 48.587658, lng: 20.064417 },
    { lat: 48.587395, lng: 20.064687 },
    { lat: 48.587039, lng: 20.065013 },
    { lat: 48.586487, lng: 20.065126 },
    { lat: 48.586344, lng: 20.065276 },
    { lat: 48.586274, lng: 20.065376 },
    { lat: 48.586216, lng: 20.065492 },
    { lat: 48.586158, lng: 20.065619 },
    { lat: 48.586088, lng: 20.065818 },
    { lat: 48.585924, lng: 20.066166 },
    { lat: 48.585937, lng: 20.066273 },
    { lat: 48.585941, lng: 20.066416 },
    { lat: 48.585923, lng: 20.066582 },
    { lat: 48.585846, lng: 20.066702 },
    { lat: 48.585826, lng: 20.066746 },
    { lat: 48.585782, lng: 20.066848 },
    { lat: 48.585732, lng: 20.066985 },
    { lat: 48.585653, lng: 20.067057 },
    { lat: 48.585611, lng: 20.067128 },
    { lat: 48.585568, lng: 20.067248 },
    { lat: 48.5855, lng: 20.06775 },
    { lat: 48.585486, lng: 20.067866 },
    { lat: 48.585464, lng: 20.068275 },
    { lat: 48.585282, lng: 20.06853 },
    { lat: 48.584624, lng: 20.06893 },
    { lat: 48.584469, lng: 20.069134 },
    { lat: 48.584338, lng: 20.069395 },
    { lat: 48.584264, lng: 20.06965 },
    { lat: 48.584245, lng: 20.069789 },
    { lat: 48.584235, lng: 20.070026 },
    { lat: 48.584242, lng: 20.070153 },
    { lat: 48.584396, lng: 20.071157 },
    { lat: 48.584413, lng: 20.071407 },
    { lat: 48.584504, lng: 20.071712 },
    { lat: 48.584563, lng: 20.072405 },
    { lat: 48.584608, lng: 20.07272 },
    { lat: 48.584673, lng: 20.073056 },
    { lat: 48.584746, lng: 20.073288 },
    { lat: 48.584813, lng: 20.073506 },
    { lat: 48.584825, lng: 20.073544 },
    { lat: 48.58486, lng: 20.073853 },
    { lat: 48.58493, lng: 20.074223 },
    { lat: 48.58485, lng: 20.074513 },
    { lat: 48.584759, lng: 20.074975 },
    { lat: 48.584757, lng: 20.075281 },
    { lat: 48.584797, lng: 20.075442 },
    { lat: 48.584928, lng: 20.075949 },
    { lat: 48.58491, lng: 20.076051 },
    { lat: 48.58491, lng: 20.076225 },
    { lat: 48.58496, lng: 20.076507 },
    { lat: 48.584892, lng: 20.077087 },
    { lat: 48.585008, lng: 20.077552 },
    { lat: 48.585012, lng: 20.078091 },
    { lat: 48.585037, lng: 20.078621 },
    { lat: 48.584904, lng: 20.079659 },
    { lat: 48.58487, lng: 20.080391 },
    { lat: 48.584892, lng: 20.080611 },
    { lat: 48.584876, lng: 20.08095 },
    { lat: 48.584815, lng: 20.081366 },
    { lat: 48.584761, lng: 20.081652 },
    { lat: 48.584768, lng: 20.081999 },
    { lat: 48.584734, lng: 20.082011 },
    { lat: 48.584719, lng: 20.082017 },
    { lat: 48.584708, lng: 20.08208 },
    { lat: 48.584703, lng: 20.08211 },
    { lat: 48.584636, lng: 20.082257 },
    { lat: 48.584192, lng: 20.083132 },
    { lat: 48.584043, lng: 20.083301 },
    { lat: 48.583705, lng: 20.083456 },
    { lat: 48.583585, lng: 20.083647 },
    { lat: 48.583517, lng: 20.083678 },
    { lat: 48.583389, lng: 20.083691 },
    { lat: 48.583294, lng: 20.083726 },
    { lat: 48.583201, lng: 20.083792 },
    { lat: 48.583144, lng: 20.083781 },
    { lat: 48.583074, lng: 20.08366 },
    { lat: 48.582884, lng: 20.084009 },
    { lat: 48.582798, lng: 20.084067 },
    { lat: 48.582781, lng: 20.084139 },
    { lat: 48.582734, lng: 20.084014 },
    { lat: 48.582642, lng: 20.08385 },
    { lat: 48.582554, lng: 20.083783 },
    { lat: 48.582481, lng: 20.083757 },
    { lat: 48.582248, lng: 20.083525 },
    { lat: 48.582194, lng: 20.083462 },
    { lat: 48.582174, lng: 20.08344 },
    { lat: 48.582076, lng: 20.083327 },
    { lat: 48.581951, lng: 20.083451 },
    { lat: 48.581825, lng: 20.083685 },
    { lat: 48.581682, lng: 20.083992 },
    { lat: 48.581437, lng: 20.08377 },
    { lat: 48.58141, lng: 20.083734 },
    { lat: 48.581344, lng: 20.08381 },
    { lat: 48.581213, lng: 20.083804 },
    { lat: 48.581023, lng: 20.083841 },
    { lat: 48.580822, lng: 20.083758 },
    { lat: 48.580707, lng: 20.083697 },
    { lat: 48.580613, lng: 20.083618 },
    { lat: 48.580333, lng: 20.083761 },
    { lat: 48.580239, lng: 20.083676 },
    { lat: 48.580133, lng: 20.083733 },
    { lat: 48.580047, lng: 20.083703 },
    { lat: 48.579964, lng: 20.083719 },
    { lat: 48.579837, lng: 20.08369 },
    { lat: 48.579483, lng: 20.083736 },
    { lat: 48.578961, lng: 20.084041 },
    { lat: 48.578692, lng: 20.084234 },
    { lat: 48.578516, lng: 20.08436 },
    { lat: 48.578433, lng: 20.084348 },
    { lat: 48.578195, lng: 20.08446 },
    { lat: 48.577938, lng: 20.084713 },
    { lat: 48.577681, lng: 20.084812 },
    { lat: 48.577575, lng: 20.08479 },
    { lat: 48.577449, lng: 20.08485 },
    { lat: 48.577343, lng: 20.084967 },
    { lat: 48.5772, lng: 20.085035 },
    { lat: 48.577106, lng: 20.085216 },
    { lat: 48.577102, lng: 20.085303 },
    { lat: 48.577055, lng: 20.085393 },
    { lat: 48.57698, lng: 20.085591 },
    { lat: 48.576938, lng: 20.085809 },
    { lat: 48.576869, lng: 20.085962 },
    { lat: 48.576683, lng: 20.086226 },
    { lat: 48.576681, lng: 20.086595 },
    { lat: 48.5766, lng: 20.08679 },
    { lat: 48.576447, lng: 20.086852 },
    { lat: 48.576416, lng: 20.086992 },
    { lat: 48.576259, lng: 20.087012 },
    { lat: 48.576242, lng: 20.087056 },
    { lat: 48.576238, lng: 20.087185 },
    { lat: 48.576216, lng: 20.087269 },
    { lat: 48.576062, lng: 20.087315 },
    { lat: 48.575941, lng: 20.087441 },
    { lat: 48.575928, lng: 20.087577 },
    { lat: 48.575935, lng: 20.087732 },
    { lat: 48.575964, lng: 20.087943 },
    { lat: 48.576033, lng: 20.088149 },
    { lat: 48.576082, lng: 20.088269 },
    { lat: 48.576104, lng: 20.088423 },
    { lat: 48.576104, lng: 20.08856 },
    { lat: 48.576099, lng: 20.088709 },
    { lat: 48.576084, lng: 20.088847 },
    { lat: 48.576068, lng: 20.088925 },
    { lat: 48.57603, lng: 20.089036 },
    { lat: 48.575905, lng: 20.089323 },
    { lat: 48.575871, lng: 20.089406 },
    { lat: 48.575825, lng: 20.089526 },
    { lat: 48.57579, lng: 20.089645 },
    { lat: 48.575694, lng: 20.089939 },
    { lat: 48.575654, lng: 20.090102 },
    { lat: 48.575565, lng: 20.090573 },
    { lat: 48.575549, lng: 20.09068 },
    { lat: 48.575515, lng: 20.091119 },
    { lat: 48.575505, lng: 20.091241 },
    { lat: 48.5755, lng: 20.091484 },
    { lat: 48.575515, lng: 20.0919 },
    { lat: 48.575549, lng: 20.092206 },
    { lat: 48.575494, lng: 20.092205 },
    { lat: 48.575472, lng: 20.092204 },
    { lat: 48.57544, lng: 20.092282 },
    { lat: 48.575263, lng: 20.092587 },
    { lat: 48.575264, lng: 20.092738 },
    { lat: 48.575057, lng: 20.093271 },
    { lat: 48.575009, lng: 20.093604 },
    { lat: 48.574913, lng: 20.094068 },
    { lat: 48.574702, lng: 20.094519 },
    { lat: 48.574359, lng: 20.094925 },
    { lat: 48.574471, lng: 20.095389 },
    { lat: 48.574528, lng: 20.095692 },
    { lat: 48.574516, lng: 20.095952 },
    { lat: 48.574515, lng: 20.095968 },
    { lat: 48.574527, lng: 20.095973 },
    { lat: 48.574494, lng: 20.097124 },
    { lat: 48.574468, lng: 20.097811 },
    { lat: 48.574451, lng: 20.098296 },
    { lat: 48.574432, lng: 20.098796 },
    { lat: 48.574413, lng: 20.099341 },
    { lat: 48.574383, lng: 20.099531 },
    { lat: 48.574353, lng: 20.099527 },
    { lat: 48.574104, lng: 20.099614 },
    { lat: 48.574121, lng: 20.099793 },
    { lat: 48.574049, lng: 20.099955 },
    { lat: 48.574047, lng: 20.099969 },
    { lat: 48.573976, lng: 20.100315 },
    { lat: 48.5745543, lng: 20.1003472 },
    { lat: 48.575261, lng: 20.1005203 },
    { lat: 48.5752045, lng: 20.1007945 },
    { lat: 48.5756897, lng: 20.1008161 },
    { lat: 48.5759359, lng: 20.1006381 },
    { lat: 48.5762613, lng: 20.1002791 },
    { lat: 48.5762968, lng: 20.1003451 },
    { lat: 48.5770986, lng: 20.0997354 },
    { lat: 48.5777159, lng: 20.0995203 },
    { lat: 48.5777139, lng: 20.0993615 },
    { lat: 48.5782498, lng: 20.0993023 },
    { lat: 48.5784214, lng: 20.099314 },
    { lat: 48.5784806, lng: 20.0994618 },
    { lat: 48.5785657, lng: 20.0994525 },
    { lat: 48.5786446, lng: 20.0993394 },
    { lat: 48.5789519, lng: 20.0994211 },
    { lat: 48.5788936, lng: 20.1000013 },
    { lat: 48.5792842, lng: 20.1003 },
    { lat: 48.5797044, lng: 20.1003519 },
    { lat: 48.5798475, lng: 20.1004691 },
    { lat: 48.5801255, lng: 20.1004346 },
    { lat: 48.5802759, lng: 20.1005337 },
    { lat: 48.5804215, lng: 20.1005091 },
    { lat: 48.5804212, lng: 20.1005301 },
    { lat: 48.5804347, lng: 20.1008298 },
    { lat: 48.5805059, lng: 20.1010579 },
    { lat: 48.5803486, lng: 20.1014233 },
    { lat: 48.5802892, lng: 20.1019269 },
    { lat: 48.580327, lng: 20.1019956 },
    { lat: 48.580312, lng: 20.1022634 },
    { lat: 48.5800325, lng: 20.1029713 },
    { lat: 48.5799967, lng: 20.1033642 },
    { lat: 48.580032, lng: 20.1037013 },
    { lat: 48.5799004, lng: 20.1041458 },
    { lat: 48.5796492, lng: 20.1047472 },
    { lat: 48.5794483, lng: 20.1050409 },
    { lat: 48.5793273, lng: 20.105469 },
    { lat: 48.5792214, lng: 20.1056895 },
    { lat: 48.5790791, lng: 20.1058386 },
    { lat: 48.5789943, lng: 20.1061086 },
    { lat: 48.5788527, lng: 20.1069432 },
    { lat: 48.5791602, lng: 20.1088955 },
    { lat: 48.579022, lng: 20.1096695 },
    { lat: 48.5788618, lng: 20.1120552 },
    { lat: 48.578705, lng: 20.1128509 },
    { lat: 48.578593, lng: 20.1141845 },
    { lat: 48.5787592, lng: 20.1141449 },
    { lat: 48.580805, lng: 20.1144559 },
    { lat: 48.5815762, lng: 20.1159935 },
    { lat: 48.5817018, lng: 20.1165015 },
    { lat: 48.58207, lng: 20.1171592 },
    { lat: 48.5822091, lng: 20.1176685 },
    { lat: 48.5828028, lng: 20.1187452 },
    { lat: 48.5829567, lng: 20.1194211 },
    { lat: 48.583381, lng: 20.1195528 },
    { lat: 48.5838851, lng: 20.1194594 },
    { lat: 48.5849608, lng: 20.1196241 },
    { lat: 48.5855079, lng: 20.1199184 },
  ],
  Skerešovo: [
    { lat: 48.5095659, lng: 20.1854453 },
    { lat: 48.5093995, lng: 20.1857841 },
    { lat: 48.5090903, lng: 20.1864386 },
    { lat: 48.501029, lng: 20.1767313 },
    { lat: 48.5008988, lng: 20.1768779 },
    { lat: 48.4985029, lng: 20.1734516 },
    { lat: 48.4976254, lng: 20.1720014 },
    { lat: 48.4954874, lng: 20.1682397 },
    { lat: 48.493945, lng: 20.1656722 },
    { lat: 48.4929097, lng: 20.163696 },
    { lat: 48.492756, lng: 20.163116 },
    { lat: 48.492727, lng: 20.163341 },
    { lat: 48.492683, lng: 20.163653 },
    { lat: 48.492642, lng: 20.163878 },
    { lat: 48.492431, lng: 20.164852 },
    { lat: 48.492453, lng: 20.165328 },
    { lat: 48.492527, lng: 20.165731 },
    { lat: 48.492619, lng: 20.166338 },
    { lat: 48.492647, lng: 20.166811 },
    { lat: 48.492609, lng: 20.166923 },
    { lat: 48.492538, lng: 20.167196 },
    { lat: 48.492483, lng: 20.167629 },
    { lat: 48.49232, lng: 20.16852 },
    { lat: 48.491934, lng: 20.169107 },
    { lat: 48.491703, lng: 20.1695 },
    { lat: 48.491585, lng: 20.169773 },
    { lat: 48.491192, lng: 20.170443 },
    { lat: 48.491173, lng: 20.170479 },
    { lat: 48.490901, lng: 20.17093 },
    { lat: 48.490822, lng: 20.171134 },
    { lat: 48.490853, lng: 20.17114 },
    { lat: 48.490835, lng: 20.171174 },
    { lat: 48.490825, lng: 20.171249 },
    { lat: 48.49082, lng: 20.17134 },
    { lat: 48.490818, lng: 20.17143 },
    { lat: 48.490819, lng: 20.171485 },
    { lat: 48.490797, lng: 20.171661 },
    { lat: 48.490787, lng: 20.171743 },
    { lat: 48.490784, lng: 20.171837 },
    { lat: 48.490784, lng: 20.171971 },
    { lat: 48.490799, lng: 20.171972 },
    { lat: 48.490805, lng: 20.172244 },
    { lat: 48.490789, lng: 20.172691 },
    { lat: 48.490773, lng: 20.17323 },
    { lat: 48.490774, lng: 20.173517 },
    { lat: 48.49028, lng: 20.174552 },
    { lat: 48.489965, lng: 20.175443 },
    { lat: 48.48978, lng: 20.175837 },
    { lat: 48.489653, lng: 20.176164 },
    { lat: 48.48947, lng: 20.176763 },
    { lat: 48.489078, lng: 20.177516 },
    { lat: 48.488918, lng: 20.17819 },
    { lat: 48.488762, lng: 20.178713 },
    { lat: 48.488508, lng: 20.179223 },
    { lat: 48.488434, lng: 20.179396 },
    { lat: 48.488338, lng: 20.179577 },
    { lat: 48.488145, lng: 20.179819 },
    { lat: 48.488001, lng: 20.18005 },
    { lat: 48.487853, lng: 20.180287 },
    { lat: 48.487731, lng: 20.180633 },
    { lat: 48.487682, lng: 20.18077 },
    { lat: 48.487667, lng: 20.18076 },
    { lat: 48.487504, lng: 20.18122 },
    { lat: 48.487367, lng: 20.181534 },
    { lat: 48.487247, lng: 20.181828 },
    { lat: 48.487067, lng: 20.182111 },
    { lat: 48.486975, lng: 20.182398 },
    { lat: 48.486904, lng: 20.182827 },
    { lat: 48.486806, lng: 20.183181 },
    { lat: 48.486584, lng: 20.183566 },
    { lat: 48.486502, lng: 20.183868 },
    { lat: 48.486402, lng: 20.184156 },
    { lat: 48.48616, lng: 20.184628 },
    { lat: 48.486012, lng: 20.185031 },
    { lat: 48.485913, lng: 20.185192 },
    { lat: 48.485823, lng: 20.185376 },
    { lat: 48.485755, lng: 20.185558 },
    { lat: 48.485509, lng: 20.185835 },
    { lat: 48.485434, lng: 20.18592 },
    { lat: 48.485261, lng: 20.186219 },
    { lat: 48.485167, lng: 20.18633 },
    { lat: 48.485078, lng: 20.186495 },
    { lat: 48.485037, lng: 20.186652 },
    { lat: 48.485025, lng: 20.187176 },
    { lat: 48.484979, lng: 20.187321 },
    { lat: 48.484833, lng: 20.187612 },
    { lat: 48.48478, lng: 20.187799 },
    { lat: 48.484538, lng: 20.188163 },
    { lat: 48.484419, lng: 20.188363 },
    { lat: 48.484378, lng: 20.188434 },
    { lat: 48.4850719, lng: 20.1893949 },
    { lat: 48.4873232, lng: 20.1921844 },
    { lat: 48.4894286, lng: 20.1946247 },
    { lat: 48.4892479, lng: 20.195564 },
    { lat: 48.488934, lng: 20.1964362 },
    { lat: 48.4889834, lng: 20.1964914 },
    { lat: 48.4887493, lng: 20.1976695 },
    { lat: 48.488659, lng: 20.1988682 },
    { lat: 48.4887712, lng: 20.1990522 },
    { lat: 48.4887023, lng: 20.1993812 },
    { lat: 48.4888185, lng: 20.1994689 },
    { lat: 48.4888513, lng: 20.1996098 },
    { lat: 48.4888146, lng: 20.1997079 },
    { lat: 48.4889418, lng: 20.1997886 },
    { lat: 48.4895887, lng: 20.1997996 },
    { lat: 48.4897606, lng: 20.1998727 },
    { lat: 48.4898807, lng: 20.2000592 },
    { lat: 48.4896964, lng: 20.2005482 },
    { lat: 48.4896784, lng: 20.2008282 },
    { lat: 48.4957923, lng: 20.2084307 },
    { lat: 48.4959285, lng: 20.2080902 },
    { lat: 48.4964878, lng: 20.2084179 },
    { lat: 48.4954509, lng: 20.2106708 },
    { lat: 48.4953329, lng: 20.211771 },
    { lat: 48.4950578, lng: 20.2131673 },
    { lat: 48.4950176, lng: 20.2135641 },
    { lat: 48.4961334, lng: 20.2142855 },
    { lat: 48.5073518, lng: 20.2232446 },
    { lat: 48.5073843, lng: 20.2244801 },
    { lat: 48.5073076, lng: 20.2249577 },
    { lat: 48.506985, lng: 20.2267752 },
    { lat: 48.5066076, lng: 20.2277002 },
    { lat: 48.5072857, lng: 20.2284059 },
    { lat: 48.5075074, lng: 20.2285439 },
    { lat: 48.5078614, lng: 20.2292261 },
    { lat: 48.508118, lng: 20.2294469 },
    { lat: 48.5084962, lng: 20.2295859 },
    { lat: 48.5093876, lng: 20.2308681 },
    { lat: 48.5097806, lng: 20.2313422 },
    { lat: 48.5102205, lng: 20.2322337 },
    { lat: 48.5104187, lng: 20.2334138 },
    { lat: 48.5105318, lng: 20.2349217 },
    { lat: 48.5106164, lng: 20.2350362 },
    { lat: 48.5107844, lng: 20.2350715 },
    { lat: 48.5109999, lng: 20.2346289 },
    { lat: 48.5113367, lng: 20.2344642 },
    { lat: 48.5112915, lng: 20.2343717 },
    { lat: 48.5113552, lng: 20.2342171 },
    { lat: 48.5117797, lng: 20.2339627 },
    { lat: 48.5117774, lng: 20.2338802 },
    { lat: 48.5117457, lng: 20.2337601 },
    { lat: 48.5118041, lng: 20.2335851 },
    { lat: 48.5118218, lng: 20.2327054 },
    { lat: 48.5119296, lng: 20.2327812 },
    { lat: 48.5119603, lng: 20.2325916 },
    { lat: 48.5118956, lng: 20.2323542 },
    { lat: 48.5118359, lng: 20.2323851 },
    { lat: 48.5117842, lng: 20.2322719 },
    { lat: 48.5118519, lng: 20.2318218 },
    { lat: 48.5119491, lng: 20.2317504 },
    { lat: 48.511988, lng: 20.2315679 },
    { lat: 48.5122007, lng: 20.231451 },
    { lat: 48.5120789, lng: 20.2311668 },
    { lat: 48.5121854, lng: 20.2311206 },
    { lat: 48.5122883, lng: 20.2307358 },
    { lat: 48.5126403, lng: 20.2301612 },
    { lat: 48.512716, lng: 20.2299405 },
    { lat: 48.5128978, lng: 20.2297589 },
    { lat: 48.5129215, lng: 20.2296725 },
    { lat: 48.5130133, lng: 20.2296607 },
    { lat: 48.5130954, lng: 20.2297953 },
    { lat: 48.5133663, lng: 20.2296411 },
    { lat: 48.5135007, lng: 20.2296506 },
    { lat: 48.513652, lng: 20.2294909 },
    { lat: 48.5136746, lng: 20.2292949 },
    { lat: 48.513608, lng: 20.2290409 },
    { lat: 48.5138423, lng: 20.2287025 },
    { lat: 48.513959, lng: 20.2280444 },
    { lat: 48.5141088, lng: 20.2277662 },
    { lat: 48.5140884, lng: 20.2275094 },
    { lat: 48.5141173, lng: 20.2274411 },
    { lat: 48.5146776, lng: 20.2273697 },
    { lat: 48.5146812, lng: 20.2269531 },
    { lat: 48.5147952, lng: 20.2267648 },
    { lat: 48.5149394, lng: 20.2269368 },
    { lat: 48.5151546, lng: 20.2268757 },
    { lat: 48.5154428, lng: 20.2270183 },
    { lat: 48.5155313, lng: 20.226919 },
    { lat: 48.5155107, lng: 20.2267166 },
    { lat: 48.5156335, lng: 20.2266892 },
    { lat: 48.515919, lng: 20.2263386 },
    { lat: 48.5160295, lng: 20.2260263 },
    { lat: 48.5162694, lng: 20.22606 },
    { lat: 48.5162707, lng: 20.2258904 },
    { lat: 48.5165046, lng: 20.2257431 },
    { lat: 48.5168382, lng: 20.2256063 },
    { lat: 48.517117, lng: 20.2257071 },
    { lat: 48.5173553, lng: 20.2255299 },
    { lat: 48.5174967, lng: 20.2256539 },
    { lat: 48.5177899, lng: 20.225326 },
    { lat: 48.5178065, lng: 20.2249252 },
    { lat: 48.5179357, lng: 20.2246645 },
    { lat: 48.5181348, lng: 20.2246017 },
    { lat: 48.5181827, lng: 20.2247014 },
    { lat: 48.5183193, lng: 20.2246841 },
    { lat: 48.5184979, lng: 20.2248107 },
    { lat: 48.518725, lng: 20.2246899 },
    { lat: 48.5188407, lng: 20.2248496 },
    { lat: 48.5189559, lng: 20.2248864 },
    { lat: 48.5190776, lng: 20.2251077 },
    { lat: 48.5195041, lng: 20.2254978 },
    { lat: 48.5193915, lng: 20.2255816 },
    { lat: 48.5195066, lng: 20.2257857 },
    { lat: 48.5197669, lng: 20.2257859 },
    { lat: 48.5201187, lng: 20.2259356 },
    { lat: 48.5201212, lng: 20.2261101 },
    { lat: 48.5201971, lng: 20.2262077 },
    { lat: 48.5202688, lng: 20.2262261 },
    { lat: 48.5210078, lng: 20.2257562 },
    { lat: 48.521118, lng: 20.225807 },
    { lat: 48.5213478, lng: 20.2257528 },
    { lat: 48.5215021, lng: 20.2259182 },
    { lat: 48.5215192, lng: 20.2260072 },
    { lat: 48.5215692, lng: 20.2260205 },
    { lat: 48.5216684, lng: 20.2258548 },
    { lat: 48.5218468, lng: 20.2258681 },
    { lat: 48.5218938, lng: 20.2259381 },
    { lat: 48.5218707, lng: 20.2260116 },
    { lat: 48.5220554, lng: 20.2259856 },
    { lat: 48.5220854, lng: 20.2260849 },
    { lat: 48.5221902, lng: 20.2260867 },
    { lat: 48.5222118, lng: 20.2262077 },
    { lat: 48.522378, lng: 20.2261909 },
    { lat: 48.5224014, lng: 20.2260736 },
    { lat: 48.5224756, lng: 20.2260701 },
    { lat: 48.522509, lng: 20.226233 },
    { lat: 48.5224765, lng: 20.2263752 },
    { lat: 48.5226027, lng: 20.2266288 },
    { lat: 48.5226762, lng: 20.2265539 },
    { lat: 48.5228875, lng: 20.2265973 },
    { lat: 48.5230441, lng: 20.2264577 },
    { lat: 48.5231065, lng: 20.2264957 },
    { lat: 48.5230492, lng: 20.2266452 },
    { lat: 48.5230715, lng: 20.2266947 },
    { lat: 48.5232513, lng: 20.2267423 },
    { lat: 48.52338, lng: 20.2265779 },
    { lat: 48.5234692, lng: 20.2266434 },
    { lat: 48.5235495, lng: 20.2265611 },
    { lat: 48.5235821, lng: 20.226734 },
    { lat: 48.5236399, lng: 20.2267991 },
    { lat: 48.5237696, lng: 20.2266072 },
    { lat: 48.524059, lng: 20.2265196 },
    { lat: 48.5240656, lng: 20.2263719 },
    { lat: 48.5241231, lng: 20.2262942 },
    { lat: 48.5242037, lng: 20.226338 },
    { lat: 48.5242634, lng: 20.2262772 },
    { lat: 48.5244683, lng: 20.2263919 },
    { lat: 48.524459, lng: 20.2266084 },
    { lat: 48.5245473, lng: 20.2266203 },
    { lat: 48.5245288, lng: 20.2267481 },
    { lat: 48.5244835, lng: 20.2267717 },
    { lat: 48.5244883, lng: 20.2269439 },
    { lat: 48.5245777, lng: 20.2269262 },
    { lat: 48.5247607, lng: 20.227082 },
    { lat: 48.5248577, lng: 20.2270277 },
    { lat: 48.525115, lng: 20.2272122 },
    { lat: 48.5251464, lng: 20.2271536 },
    { lat: 48.5252462, lng: 20.2272 },
    { lat: 48.5252277, lng: 20.227298 },
    { lat: 48.5253546, lng: 20.2273742 },
    { lat: 48.5254146, lng: 20.227329 },
    { lat: 48.5254212, lng: 20.2272406 },
    { lat: 48.5256281, lng: 20.2272318 },
    { lat: 48.52576, lng: 20.2269538 },
    { lat: 48.5258664, lng: 20.2271061 },
    { lat: 48.5258886, lng: 20.2269837 },
    { lat: 48.525855, lng: 20.2269043 },
    { lat: 48.5259032, lng: 20.2268409 },
    { lat: 48.5259073, lng: 20.226718 },
    { lat: 48.5260206, lng: 20.2267081 },
    { lat: 48.5260763, lng: 20.2268078 },
    { lat: 48.5260339, lng: 20.2269272 },
    { lat: 48.52607, lng: 20.2269871 },
    { lat: 48.5261335, lng: 20.2269686 },
    { lat: 48.5261144, lng: 20.2271114 },
    { lat: 48.5262652, lng: 20.2272221 },
    { lat: 48.526467, lng: 20.2271991 },
    { lat: 48.5264427, lng: 20.2273243 },
    { lat: 48.5265651, lng: 20.2273683 },
    { lat: 48.5266598, lng: 20.227289 },
    { lat: 48.5273964, lng: 20.2277957 },
    { lat: 48.5275885, lng: 20.2276127 },
    { lat: 48.5278489, lng: 20.2277874 },
    { lat: 48.5279088, lng: 20.2277098 },
    { lat: 48.527842, lng: 20.227615 },
    { lat: 48.5278666, lng: 20.2274973 },
    { lat: 48.5279537, lng: 20.2274201 },
    { lat: 48.5281386, lng: 20.2275717 },
    { lat: 48.528373, lng: 20.2273473 },
    { lat: 48.528536, lng: 20.2271164 },
    { lat: 48.5283991, lng: 20.226986 },
    { lat: 48.5283446, lng: 20.2270343 },
    { lat: 48.5282719, lng: 20.2269855 },
    { lat: 48.5284751, lng: 20.226773 },
    { lat: 48.5284939, lng: 20.2268474 },
    { lat: 48.5286167, lng: 20.2269382 },
    { lat: 48.5286339, lng: 20.2268599 },
    { lat: 48.5287436, lng: 20.2268425 },
    { lat: 48.5286058, lng: 20.226608 },
    { lat: 48.5286875, lng: 20.226536 },
    { lat: 48.5286633, lng: 20.2262969 },
    { lat: 48.5287722, lng: 20.2263183 },
    { lat: 48.5289717, lng: 20.226125 },
    { lat: 48.5289432, lng: 20.2260018 },
    { lat: 48.5290885, lng: 20.2259776 },
    { lat: 48.5291716, lng: 20.2256589 },
    { lat: 48.5292855, lng: 20.2257228 },
    { lat: 48.5293095, lng: 20.2256126 },
    { lat: 48.5293853, lng: 20.2255968 },
    { lat: 48.5294305, lng: 20.2256592 },
    { lat: 48.5294015, lng: 20.2258015 },
    { lat: 48.5295168, lng: 20.2258457 },
    { lat: 48.5296652, lng: 20.2256983 },
    { lat: 48.5298061, lng: 20.2257555 },
    { lat: 48.5298403, lng: 20.2258494 },
    { lat: 48.5299103, lng: 20.2257724 },
    { lat: 48.5298734, lng: 20.2256631 },
    { lat: 48.5299653, lng: 20.2255418 },
    { lat: 48.5301844, lng: 20.2255557 },
    { lat: 48.53028, lng: 20.2256611 },
    { lat: 48.5305449, lng: 20.2255876 },
    { lat: 48.5306443, lng: 20.2256483 },
    { lat: 48.5307087, lng: 20.2254282 },
    { lat: 48.5308067, lng: 20.2255138 },
    { lat: 48.5308817, lng: 20.2254761 },
    { lat: 48.5310394, lng: 20.2250531 },
    { lat: 48.5311097, lng: 20.2250102 },
    { lat: 48.5310715, lng: 20.224869 },
    { lat: 48.531092, lng: 20.2247044 },
    { lat: 48.5311624, lng: 20.2246643 },
    { lat: 48.5312185, lng: 20.2248571 },
    { lat: 48.5313236, lng: 20.2248369 },
    { lat: 48.5313567, lng: 20.2247369 },
    { lat: 48.5315689, lng: 20.2246324 },
    { lat: 48.5317109, lng: 20.2244706 },
    { lat: 48.5317388, lng: 20.2242947 },
    { lat: 48.5318178, lng: 20.224182 },
    { lat: 48.5319235, lng: 20.2242108 },
    { lat: 48.5319733, lng: 20.2241281 },
    { lat: 48.531879, lng: 20.2239106 },
    { lat: 48.531989, lng: 20.2238477 },
    { lat: 48.5320626, lng: 20.2240823 },
    { lat: 48.5323283, lng: 20.2236854 },
    { lat: 48.5324343, lng: 20.2238578 },
    { lat: 48.5325702, lng: 20.2237093 },
    { lat: 48.5325662, lng: 20.2236102 },
    { lat: 48.5324841, lng: 20.2234993 },
    { lat: 48.5325217, lng: 20.2234241 },
    { lat: 48.5324467, lng: 20.2233783 },
    { lat: 48.5324142, lng: 20.2232718 },
    { lat: 48.5322865, lng: 20.2232027 },
    { lat: 48.5322379, lng: 20.2233152 },
    { lat: 48.5321827, lng: 20.2232857 },
    { lat: 48.5322408, lng: 20.2231323 },
    { lat: 48.5321768, lng: 20.2229668 },
    { lat: 48.5324038, lng: 20.2227312 },
    { lat: 48.5325071, lng: 20.2227226 },
    { lat: 48.5325433, lng: 20.2226137 },
    { lat: 48.5324829, lng: 20.2223943 },
    { lat: 48.5325533, lng: 20.2223541 },
    { lat: 48.532605, lng: 20.2219006 },
    { lat: 48.5327249, lng: 20.2217202 },
    { lat: 48.5327033, lng: 20.221627 },
    { lat: 48.532627, lng: 20.221609 },
    { lat: 48.5326468, lng: 20.221512 },
    { lat: 48.5327241, lng: 20.2214456 },
    { lat: 48.5327325, lng: 20.2212608 },
    { lat: 48.5328254, lng: 20.2212748 },
    { lat: 48.532845, lng: 20.2214293 },
    { lat: 48.5329218, lng: 20.2212905 },
    { lat: 48.5329587, lng: 20.2213387 },
    { lat: 48.5330447, lng: 20.2212129 },
    { lat: 48.533044, lng: 20.2210545 },
    { lat: 48.5330997, lng: 20.2209826 },
    { lat: 48.5330871, lng: 20.2208914 },
    { lat: 48.5331483, lng: 20.2207042 },
    { lat: 48.5330629, lng: 20.220653 },
    { lat: 48.5329642, lng: 20.2203816 },
    { lat: 48.5330821, lng: 20.2202888 },
    { lat: 48.5330427, lng: 20.2201193 },
    { lat: 48.5331055, lng: 20.2199711 },
    { lat: 48.5332184, lng: 20.2199815 },
    { lat: 48.5333438, lng: 20.2198461 },
    { lat: 48.5333301, lng: 20.2197286 },
    { lat: 48.5334184, lng: 20.2196883 },
    { lat: 48.5335038, lng: 20.2197399 },
    { lat: 48.5335793, lng: 20.219599 },
    { lat: 48.5336393, lng: 20.2192702 },
    { lat: 48.5337289, lng: 20.2193982 },
    { lat: 48.533884, lng: 20.2190796 },
    { lat: 48.5338872, lng: 20.2189357 },
    { lat: 48.5337684, lng: 20.2188342 },
    { lat: 48.5337369, lng: 20.2190083 },
    { lat: 48.5336752, lng: 20.2189809 },
    { lat: 48.5335871, lng: 20.2186961 },
    { lat: 48.5336518, lng: 20.2185385 },
    { lat: 48.5338046, lng: 20.2184773 },
    { lat: 48.5338057, lng: 20.2182203 },
    { lat: 48.5338885, lng: 20.2180962 },
    { lat: 48.5339316, lng: 20.2176819 },
    { lat: 48.5339069, lng: 20.2176258 },
    { lat: 48.5338011, lng: 20.2176831 },
    { lat: 48.5336609, lng: 20.2176169 },
    { lat: 48.5336367, lng: 20.2174009 },
    { lat: 48.5336764, lng: 20.2173259 },
    { lat: 48.5336244, lng: 20.2172364 },
    { lat: 48.5337051, lng: 20.217141 },
    { lat: 48.5336437, lng: 20.2169586 },
    { lat: 48.5336801, lng: 20.2168593 },
    { lat: 48.5336474, lng: 20.2166842 },
    { lat: 48.5336722, lng: 20.2166038 },
    { lat: 48.5337659, lng: 20.2166383 },
    { lat: 48.533827, lng: 20.2164721 },
    { lat: 48.5338005, lng: 20.2163487 },
    { lat: 48.5338576, lng: 20.2162814 },
    { lat: 48.5338388, lng: 20.2160126 },
    { lat: 48.5339768, lng: 20.2157169 },
    { lat: 48.533945, lng: 20.2155435 },
    { lat: 48.5339837, lng: 20.2153545 },
    { lat: 48.5338955, lng: 20.2152307 },
    { lat: 48.5338156, lng: 20.2152351 },
    { lat: 48.5337869, lng: 20.2146606 },
    { lat: 48.533975, lng: 20.2143251 },
    { lat: 48.534019, lng: 20.2143509 },
    { lat: 48.5341832, lng: 20.2142372 },
    { lat: 48.5341531, lng: 20.2141035 },
    { lat: 48.534188, lng: 20.2139617 },
    { lat: 48.5343115, lng: 20.213953 },
    { lat: 48.5343582, lng: 20.2138798 },
    { lat: 48.5342859, lng: 20.2138189 },
    { lat: 48.5343816, lng: 20.2135344 },
    { lat: 48.5344766, lng: 20.2135503 },
    { lat: 48.5344996, lng: 20.2133339 },
    { lat: 48.5346196, lng: 20.2132675 },
    { lat: 48.5345789, lng: 20.2129806 },
    { lat: 48.5346697, lng: 20.2128028 },
    { lat: 48.5347083, lng: 20.2128938 },
    { lat: 48.5348034, lng: 20.212877 },
    { lat: 48.534824, lng: 20.2124902 },
    { lat: 48.5348465, lng: 20.2124097 },
    { lat: 48.5349373, lng: 20.2123961 },
    { lat: 48.5350309, lng: 20.2118634 },
    { lat: 48.5350965, lng: 20.2119281 },
    { lat: 48.5354816, lng: 20.2117034 },
    { lat: 48.5355794, lng: 20.2114713 },
    { lat: 48.535482, lng: 20.2113789 },
    { lat: 48.535546, lng: 20.2112032 },
    { lat: 48.5355395, lng: 20.2111821 },
    { lat: 48.5354374, lng: 20.2108253 },
    { lat: 48.5354436, lng: 20.2105323 },
    { lat: 48.535331, lng: 20.2103901 },
    { lat: 48.5351864, lng: 20.2103517 },
    { lat: 48.535117, lng: 20.2100812 },
    { lat: 48.5349407, lng: 20.2097568 },
    { lat: 48.5348889, lng: 20.2094193 },
    { lat: 48.5347435, lng: 20.2091656 },
    { lat: 48.5347317, lng: 20.2090139 },
    { lat: 48.5346305, lng: 20.2088795 },
    { lat: 48.5344959, lng: 20.2084703 },
    { lat: 48.5344025, lng: 20.2084735 },
    { lat: 48.5343066, lng: 20.2083298 },
    { lat: 48.5335128, lng: 20.2080509 },
    { lat: 48.5326083, lng: 20.207377 },
    { lat: 48.5307107, lng: 20.2063405 },
    { lat: 48.529168, lng: 20.2052253 },
    { lat: 48.5283356, lng: 20.2028994 },
    { lat: 48.5276864, lng: 20.2014566 },
    { lat: 48.5271369, lng: 20.2008145 },
    { lat: 48.5265835, lng: 20.2003579 },
    { lat: 48.5256956, lng: 20.2000949 },
    { lat: 48.525354, lng: 20.1996688 },
    { lat: 48.5241775, lng: 20.1985314 },
    { lat: 48.5233469, lng: 20.1972318 },
    { lat: 48.5214082, lng: 20.1964391 },
    { lat: 48.5203934, lng: 20.1962414 },
    { lat: 48.5195552, lng: 20.1962074 },
    { lat: 48.5188393, lng: 20.19658 },
    { lat: 48.5184748, lng: 20.1974861 },
    { lat: 48.5175241, lng: 20.1972563 },
    { lat: 48.5175539, lng: 20.197049 },
    { lat: 48.5144855, lng: 20.1941008 },
    { lat: 48.5157583, lng: 20.1915332 },
    { lat: 48.5095659, lng: 20.1854453 },
  ],
  Višňové: [
    { lat: 48.532947, lng: 20.1636518 },
    { lat: 48.5329049, lng: 20.1636159 },
    { lat: 48.5328096, lng: 20.1635689 },
    { lat: 48.5326768, lng: 20.1638522 },
    { lat: 48.5324563, lng: 20.1637158 },
    { lat: 48.5322218, lng: 20.1643531 },
    { lat: 48.5323355, lng: 20.164853 },
    { lat: 48.5324428, lng: 20.1648026 },
    { lat: 48.5325621, lng: 20.1649402 },
    { lat: 48.5325079, lng: 20.1660717 },
    { lat: 48.5322934, lng: 20.1667303 },
    { lat: 48.5320574, lng: 20.1671571 },
    { lat: 48.5318501, lng: 20.1677142 },
    { lat: 48.5314771, lng: 20.1682932 },
    { lat: 48.5313906, lng: 20.1685182 },
    { lat: 48.5311877, lng: 20.1687121 },
    { lat: 48.5310635, lng: 20.1689573 },
    { lat: 48.5309536, lng: 20.1690214 },
    { lat: 48.5308745, lng: 20.1692664 },
    { lat: 48.5307411, lng: 20.1693628 },
    { lat: 48.5307425, lng: 20.169701 },
    { lat: 48.5305596, lng: 20.1698073 },
    { lat: 48.5303829, lng: 20.1697784 },
    { lat: 48.5300437, lng: 20.1700523 },
    { lat: 48.5299616, lng: 20.1703338 },
    { lat: 48.5299583, lng: 20.1707011 },
    { lat: 48.5299082, lng: 20.1707978 },
    { lat: 48.529859, lng: 20.1708588 },
    { lat: 48.5296727, lng: 20.1708233 },
    { lat: 48.5294919, lng: 20.1709436 },
    { lat: 48.5293471, lng: 20.1711499 },
    { lat: 48.5292283, lng: 20.1714461 },
    { lat: 48.5292329, lng: 20.1715889 },
    { lat: 48.5293771, lng: 20.1717803 },
    { lat: 48.5290675, lng: 20.1723137 },
    { lat: 48.529046, lng: 20.172284 },
    { lat: 48.5289178, lng: 20.1724287 },
    { lat: 48.5289031, lng: 20.1727882 },
    { lat: 48.5286506, lng: 20.1726724 },
    { lat: 48.5285434, lng: 20.1726947 },
    { lat: 48.5280745, lng: 20.1736051 },
    { lat: 48.5279306, lng: 20.173636 },
    { lat: 48.5278072, lng: 20.1739003 },
    { lat: 48.527339, lng: 20.1741884 },
    { lat: 48.5271521, lng: 20.1744376 },
    { lat: 48.5269538, lng: 20.1751902 },
    { lat: 48.5269154, lng: 20.1756585 },
    { lat: 48.5268284, lng: 20.175848 },
    { lat: 48.5265799, lng: 20.1761643 },
    { lat: 48.5264618, lng: 20.1765059 },
    { lat: 48.5263798, lng: 20.1769821 },
    { lat: 48.5262393, lng: 20.1772437 },
    { lat: 48.5261039, lng: 20.1773492 },
    { lat: 48.525938, lng: 20.177309 },
    { lat: 48.525775, lng: 20.1773744 },
    { lat: 48.5255523, lng: 20.1776858 },
    { lat: 48.5249087, lng: 20.1781785 },
    { lat: 48.5247463, lng: 20.1785924 },
    { lat: 48.5244723, lng: 20.1785799 },
    { lat: 48.5241496, lng: 20.1789278 },
    { lat: 48.5239626, lng: 20.1792899 },
    { lat: 48.5238527, lng: 20.1793017 },
    { lat: 48.5233206, lng: 20.1798479 },
    { lat: 48.5230772, lng: 20.1799277 },
    { lat: 48.5229522, lng: 20.179872 },
    { lat: 48.5225066, lng: 20.1796321 },
    { lat: 48.5222882, lng: 20.1793029 },
    { lat: 48.5216516, lng: 20.1793246 },
    { lat: 48.521268, lng: 20.1795036 },
    { lat: 48.5213409, lng: 20.1804679 },
    { lat: 48.5209205, lng: 20.1805476 },
    { lat: 48.5210088, lng: 20.1796371 },
    { lat: 48.5203755, lng: 20.1796279 },
    { lat: 48.5201516, lng: 20.1804734 },
    { lat: 48.5198891, lng: 20.1794955 },
    { lat: 48.5197585, lng: 20.1794642 },
    { lat: 48.5197453, lng: 20.1795574 },
    { lat: 48.5182586, lng: 20.1796448 },
    { lat: 48.5169486, lng: 20.1796646 },
    { lat: 48.5169351, lng: 20.1791372 },
    { lat: 48.5168905, lng: 20.1790383 },
    { lat: 48.5167214, lng: 20.1790594 },
    { lat: 48.5164147, lng: 20.1789696 },
    { lat: 48.5161565, lng: 20.1786301 },
    { lat: 48.5160658, lng: 20.1786402 },
    { lat: 48.5159534, lng: 20.1788143 },
    { lat: 48.5157138, lng: 20.1789591 },
    { lat: 48.5155932, lng: 20.1788476 },
    { lat: 48.5130524, lng: 20.1806902 },
    { lat: 48.5127841, lng: 20.1804582 },
    { lat: 48.5112849, lng: 20.1821861 },
    { lat: 48.5111545, lng: 20.1822485 },
    { lat: 48.5095659, lng: 20.1854453 },
    { lat: 48.5157583, lng: 20.1915332 },
    { lat: 48.5144855, lng: 20.1941008 },
    { lat: 48.5175539, lng: 20.197049 },
    { lat: 48.5175241, lng: 20.1972563 },
    { lat: 48.5184748, lng: 20.1974861 },
    { lat: 48.5188393, lng: 20.19658 },
    { lat: 48.5195552, lng: 20.1962074 },
    { lat: 48.5203934, lng: 20.1962414 },
    { lat: 48.5214082, lng: 20.1964391 },
    { lat: 48.5233469, lng: 20.1972318 },
    { lat: 48.5241775, lng: 20.1985314 },
    { lat: 48.525354, lng: 20.1996688 },
    { lat: 48.5256956, lng: 20.2000949 },
    { lat: 48.5265835, lng: 20.2003579 },
    { lat: 48.5271369, lng: 20.2008145 },
    { lat: 48.5276864, lng: 20.2014566 },
    { lat: 48.5283356, lng: 20.2028994 },
    { lat: 48.529168, lng: 20.2052253 },
    { lat: 48.5307107, lng: 20.2063405 },
    { lat: 48.5326083, lng: 20.207377 },
    { lat: 48.5335128, lng: 20.2080509 },
    { lat: 48.5343066, lng: 20.2083298 },
    { lat: 48.5344025, lng: 20.2084735 },
    { lat: 48.5344959, lng: 20.2084703 },
    { lat: 48.5346305, lng: 20.2088795 },
    { lat: 48.5347317, lng: 20.2090139 },
    { lat: 48.5347435, lng: 20.2091656 },
    { lat: 48.5348889, lng: 20.2094193 },
    { lat: 48.5349407, lng: 20.2097568 },
    { lat: 48.535117, lng: 20.2100812 },
    { lat: 48.5351864, lng: 20.2103517 },
    { lat: 48.535331, lng: 20.2103901 },
    { lat: 48.5354436, lng: 20.2105323 },
    { lat: 48.5354374, lng: 20.2108253 },
    { lat: 48.5355395, lng: 20.2111821 },
    { lat: 48.535546, lng: 20.2112032 },
    { lat: 48.5355977, lng: 20.2111963 },
    { lat: 48.5357335, lng: 20.2111595 },
    { lat: 48.5358165, lng: 20.2110378 },
    { lat: 48.5359218, lng: 20.2111114 },
    { lat: 48.5360198, lng: 20.2110527 },
    { lat: 48.5361381, lng: 20.210914 },
    { lat: 48.5361234, lng: 20.2106876 },
    { lat: 48.5363576, lng: 20.2105725 },
    { lat: 48.5364229, lng: 20.210348 },
    { lat: 48.5363665, lng: 20.2100656 },
    { lat: 48.5364422, lng: 20.209875 },
    { lat: 48.5366702, lng: 20.2099175 },
    { lat: 48.5367067, lng: 20.2098729 },
    { lat: 48.5366737, lng: 20.2096929 },
    { lat: 48.5366006, lng: 20.2095826 },
    { lat: 48.5366157, lng: 20.209207 },
    { lat: 48.5365647, lng: 20.2091929 },
    { lat: 48.5365933, lng: 20.2090943 },
    { lat: 48.5366955, lng: 20.2089777 },
    { lat: 48.5367536, lng: 20.2090189 },
    { lat: 48.5367965, lng: 20.2092471 },
    { lat: 48.5369408, lng: 20.2092805 },
    { lat: 48.5370493, lng: 20.2091477 },
    { lat: 48.5370445, lng: 20.2090593 },
    { lat: 48.5369659, lng: 20.2089755 },
    { lat: 48.5368731, lng: 20.2089683 },
    { lat: 48.5368248, lng: 20.2087179 },
    { lat: 48.5369332, lng: 20.2087478 },
    { lat: 48.5370877, lng: 20.208647 },
    { lat: 48.5369952, lng: 20.2084717 },
    { lat: 48.537035, lng: 20.2083394 },
    { lat: 48.5371197, lng: 20.2083211 },
    { lat: 48.5371736, lng: 20.2082067 },
    { lat: 48.5373882, lng: 20.2084718 },
    { lat: 48.5374957, lng: 20.2082865 },
    { lat: 48.5374736, lng: 20.2080721 },
    { lat: 48.5375359, lng: 20.2079996 },
    { lat: 48.5375206, lng: 20.2079246 },
    { lat: 48.5381005, lng: 20.2074144 },
    { lat: 48.5383196, lng: 20.2068983 },
    { lat: 48.5383232, lng: 20.2065945 },
    { lat: 48.5384337, lng: 20.2065151 },
    { lat: 48.5384801, lng: 20.2063243 },
    { lat: 48.5384327, lng: 20.2061847 },
    { lat: 48.53847, lng: 20.2061312 },
    { lat: 48.5389139, lng: 20.205767 },
    { lat: 48.5390157, lng: 20.2055576 },
    { lat: 48.5391426, lng: 20.2054611 },
    { lat: 48.5392729, lng: 20.2055377 },
    { lat: 48.5393175, lng: 20.2054441 },
    { lat: 48.5392857, lng: 20.2053805 },
    { lat: 48.539323, lng: 20.2053572 },
    { lat: 48.5393867, lng: 20.2053973 },
    { lat: 48.539443, lng: 20.2053428 },
    { lat: 48.539467, lng: 20.2054355 },
    { lat: 48.5396304, lng: 20.2052948 },
    { lat: 48.5398053, lng: 20.2052483 },
    { lat: 48.5399076, lng: 20.2054099 },
    { lat: 48.5400905, lng: 20.2052585 },
    { lat: 48.5403511, lng: 20.2051534 },
    { lat: 48.5403489, lng: 20.2050442 },
    { lat: 48.5406059, lng: 20.2047986 },
    { lat: 48.540857, lng: 20.2049083 },
    { lat: 48.5408957, lng: 20.2048326 },
    { lat: 48.540869, lng: 20.2046737 },
    { lat: 48.5410168, lng: 20.2047626 },
    { lat: 48.5410416, lng: 20.2049334 },
    { lat: 48.5410863, lng: 20.2049553 },
    { lat: 48.5412935, lng: 20.2048135 },
    { lat: 48.5414144, lng: 20.2049606 },
    { lat: 48.5415829, lng: 20.2048912 },
    { lat: 48.5416857, lng: 20.2047358 },
    { lat: 48.5417068, lng: 20.2045028 },
    { lat: 48.5417516, lng: 20.2044362 },
    { lat: 48.5418344, lng: 20.2044539 },
    { lat: 48.5419275, lng: 20.2043626 },
    { lat: 48.5420386, lng: 20.2044317 },
    { lat: 48.5421401, lng: 20.204243 },
    { lat: 48.5422537, lng: 20.2042646 },
    { lat: 48.5423114, lng: 20.2042132 },
    { lat: 48.5424559, lng: 20.2039146 },
    { lat: 48.5425917, lng: 20.2038184 },
    { lat: 48.5426681, lng: 20.203504 },
    { lat: 48.5427689, lng: 20.2034351 },
    { lat: 48.5429413, lng: 20.2034975 },
    { lat: 48.5431102, lng: 20.2032941 },
    { lat: 48.5431785, lng: 20.2033146 },
    { lat: 48.5432916, lng: 20.203187 },
    { lat: 48.5434091, lng: 20.2031993 },
    { lat: 48.543476, lng: 20.2030131 },
    { lat: 48.5435779, lng: 20.2028819 },
    { lat: 48.5436176, lng: 20.2029472 },
    { lat: 48.5436886, lng: 20.2028366 },
    { lat: 48.5436784, lng: 20.2026107 },
    { lat: 48.543823, lng: 20.202369 },
    { lat: 48.5440271, lng: 20.2023739 },
    { lat: 48.5440621, lng: 20.2020981 },
    { lat: 48.5443178, lng: 20.2018725 },
    { lat: 48.5444093, lng: 20.2016591 },
    { lat: 48.5451843, lng: 20.2011018 },
    { lat: 48.5454612, lng: 20.2005917 },
    { lat: 48.5456553, lng: 20.2004347 },
    { lat: 48.545938, lng: 20.2001007 },
    { lat: 48.5460339, lng: 20.1999072 },
    { lat: 48.5460971, lng: 20.1998847 },
    { lat: 48.5461656, lng: 20.199598 },
    { lat: 48.5464591, lng: 20.1992204 },
    { lat: 48.5465267, lng: 20.19897 },
    { lat: 48.5465917, lng: 20.1990434 },
    { lat: 48.5466594, lng: 20.1989661 },
    { lat: 48.5467546, lng: 20.1987264 },
    { lat: 48.5469445, lng: 20.1981563 },
    { lat: 48.5466979, lng: 20.198075 },
    { lat: 48.5467627, lng: 20.197778 },
    { lat: 48.547428, lng: 20.1965108 },
    { lat: 48.5473706, lng: 20.1963988 },
    { lat: 48.5474467, lng: 20.1963602 },
    { lat: 48.5474779, lng: 20.1962664 },
    { lat: 48.5476366, lng: 20.1955146 },
    { lat: 48.547638, lng: 20.1954079 },
    { lat: 48.547497, lng: 20.1950653 },
    { lat: 48.5475592, lng: 20.1941464 },
    { lat: 48.5474748, lng: 20.1938168 },
    { lat: 48.5475406, lng: 20.1934052 },
    { lat: 48.5478085, lng: 20.1929019 },
    { lat: 48.5478294, lng: 20.1925236 },
    { lat: 48.5479898, lng: 20.1923099 },
    { lat: 48.5479604, lng: 20.1920854 },
    { lat: 48.5480151, lng: 20.1919894 },
    { lat: 48.5479574, lng: 20.1910829 },
    { lat: 48.5477072, lng: 20.1908612 },
    { lat: 48.5473683, lng: 20.1880961 },
    { lat: 48.547252, lng: 20.1875003 },
    { lat: 48.547385, lng: 20.1872827 },
    { lat: 48.5474255, lng: 20.1871101 },
    { lat: 48.5473749, lng: 20.1869729 },
    { lat: 48.5473992, lng: 20.1867375 },
    { lat: 48.5473489, lng: 20.1862996 },
    { lat: 48.5474167, lng: 20.1859422 },
    { lat: 48.547603, lng: 20.1855359 },
    { lat: 48.5474948, lng: 20.1853404 },
    { lat: 48.5475419, lng: 20.1851096 },
    { lat: 48.5477507, lng: 20.1846753 },
    { lat: 48.5477527, lng: 20.1843623 },
    { lat: 48.547604, lng: 20.1836895 },
    { lat: 48.5475886, lng: 20.1828634 },
    { lat: 48.547823, lng: 20.1821964 },
    { lat: 48.5478587, lng: 20.1818544 },
    { lat: 48.5478339, lng: 20.1812925 },
    { lat: 48.5479353, lng: 20.1811268 },
    { lat: 48.5483362, lng: 20.1799544 },
    { lat: 48.5483285, lng: 20.1797941 },
    { lat: 48.5484286, lng: 20.1795944 },
    { lat: 48.54851, lng: 20.1792375 },
    { lat: 48.5487194, lng: 20.1789826 },
    { lat: 48.5487228, lng: 20.178844 },
    { lat: 48.548844, lng: 20.1784746 },
    { lat: 48.5490241, lng: 20.1783056 },
    { lat: 48.5490488, lng: 20.1779109 },
    { lat: 48.5492405, lng: 20.1768564 },
    { lat: 48.5495297, lng: 20.1762401 },
    { lat: 48.549654, lng: 20.1760261 },
    { lat: 48.5498178, lng: 20.1758981 },
    { lat: 48.5500037, lng: 20.1755351 },
    { lat: 48.550302, lng: 20.1753368 },
    { lat: 48.5509247, lng: 20.1744973 },
    { lat: 48.5510355, lng: 20.1739517 },
    { lat: 48.551168, lng: 20.1737739 },
    { lat: 48.5512277, lng: 20.1735512 },
    { lat: 48.5512103, lng: 20.1732901 },
    { lat: 48.551352, lng: 20.1722832 },
    { lat: 48.551309, lng: 20.171553 },
    { lat: 48.5513594, lng: 20.1694556 },
    { lat: 48.5517205, lng: 20.1690296 },
    { lat: 48.5518265, lng: 20.1685069 },
    { lat: 48.5521059, lng: 20.1678637 },
    { lat: 48.5520779, lng: 20.167873 },
    { lat: 48.5512651, lng: 20.1681304 },
    { lat: 48.5508227, lng: 20.1681369 },
    { lat: 48.5504509, lng: 20.1682702 },
    { lat: 48.5501155, lng: 20.1690031 },
    { lat: 48.54962, lng: 20.1697006 },
    { lat: 48.5493918, lng: 20.169906 },
    { lat: 48.5488827, lng: 20.1708802 },
    { lat: 48.5481962, lng: 20.17178 },
    { lat: 48.5480467, lng: 20.171787 },
    { lat: 48.5457725, lng: 20.1707551 },
    { lat: 48.5451888, lng: 20.1706103 },
    { lat: 48.5444058, lng: 20.1706587 },
    { lat: 48.5439872, lng: 20.1710288 },
    { lat: 48.5438883, lng: 20.1709796 },
    { lat: 48.5431082, lng: 20.1702901 },
    { lat: 48.5417735, lng: 20.1688441 },
    { lat: 48.5413493, lng: 20.1684918 },
    { lat: 48.5410504, lng: 20.1678713 },
    { lat: 48.540549, lng: 20.1675598 },
    { lat: 48.5400961, lng: 20.1676419 },
    { lat: 48.5398182, lng: 20.1676215 },
    { lat: 48.5394618, lng: 20.1674975 },
    { lat: 48.5393254, lng: 20.1675214 },
    { lat: 48.5390652, lng: 20.1678289 },
    { lat: 48.5388074, lng: 20.1684152 },
    { lat: 48.53859, lng: 20.1686637 },
    { lat: 48.5378584, lng: 20.1690628 },
    { lat: 48.5374939, lng: 20.1689605 },
    { lat: 48.5372756, lng: 20.1691064 },
    { lat: 48.5370073, lng: 20.1696033 },
    { lat: 48.5366566, lng: 20.1698729 },
    { lat: 48.5363194, lng: 20.1703868 },
    { lat: 48.5354556, lng: 20.1703644 },
    { lat: 48.5348726, lng: 20.1702342 },
    { lat: 48.5340713, lng: 20.1704136 },
    { lat: 48.5339724, lng: 20.1703676 },
    { lat: 48.5336255, lng: 20.1699183 },
    { lat: 48.5332452, lng: 20.1692346 },
    { lat: 48.533046, lng: 20.1689853 },
    { lat: 48.5322709, lng: 20.1683436 },
    { lat: 48.5327032, lng: 20.1678096 },
    { lat: 48.5333553, lng: 20.1666385 },
    { lat: 48.5335715, lng: 20.1656958 },
    { lat: 48.5336594, lng: 20.1648085 },
    { lat: 48.5336047, lng: 20.1642335 },
    { lat: 48.5331776, lng: 20.1643115 },
    { lat: 48.5331691, lng: 20.1641289 },
    { lat: 48.5329194, lng: 20.1640542 },
    { lat: 48.532947, lng: 20.1636518 },
  ],
  RatkovskéBystré: [
    { lat: 48.6941298, lng: 20.0108953 },
    { lat: 48.6940963, lng: 20.0108226 },
    { lat: 48.6938449, lng: 20.0104151 },
    { lat: 48.693336, lng: 20.0091758 },
    { lat: 48.6931714, lng: 20.0085304 },
    { lat: 48.6927983, lng: 20.007419 },
    { lat: 48.6927582, lng: 20.0069679 },
    { lat: 48.6927099, lng: 20.0068388 },
    { lat: 48.6924529, lng: 20.006484 },
    { lat: 48.6916637, lng: 20.0058194 },
    { lat: 48.6914926, lng: 20.0056068 },
    { lat: 48.691393, lng: 20.0053739 },
    { lat: 48.6911908, lng: 20.0051511 },
    { lat: 48.69087, lng: 20.0049121 },
    { lat: 48.690632, lng: 20.004345 },
    { lat: 48.690573, lng: 20.004436 },
    { lat: 48.689556, lng: 20.005331 },
    { lat: 48.68917, lng: 20.005612 },
    { lat: 48.68898, lng: 20.005648 },
    { lat: 48.688695, lng: 20.005697 },
    { lat: 48.688189, lng: 20.005621 },
    { lat: 48.687769, lng: 20.005556 },
    { lat: 48.687475, lng: 20.005569 },
    { lat: 48.687424, lng: 20.00557 },
    { lat: 48.686945, lng: 20.005629 },
    { lat: 48.686601, lng: 20.005649 },
    { lat: 48.686536, lng: 20.005645 },
    { lat: 48.686482, lng: 20.005639 },
    { lat: 48.68573, lng: 20.005549 },
    { lat: 48.685407, lng: 20.005263 },
    { lat: 48.685124, lng: 20.005077 },
    { lat: 48.684589, lng: 20.004674 },
    { lat: 48.684478, lng: 20.004338 },
    { lat: 48.684095, lng: 20.004264 },
    { lat: 48.683859, lng: 20.004243 },
    { lat: 48.683584, lng: 20.00421 },
    { lat: 48.683459, lng: 20.004155 },
    { lat: 48.683295, lng: 20.004079 },
    { lat: 48.682892, lng: 20.003786 },
    { lat: 48.682649, lng: 20.0031 },
    { lat: 48.682117, lng: 20.002294 },
    { lat: 48.682063, lng: 20.002114 },
    { lat: 48.681765, lng: 20.001173 },
    { lat: 48.68156, lng: 20.000534 },
    { lat: 48.681219, lng: 19.999459 },
    { lat: 48.680748, lng: 19.99975 },
    { lat: 48.680065, lng: 20.000124 },
    { lat: 48.679873, lng: 20.000256 },
    { lat: 48.679251, lng: 20.00066 },
    { lat: 48.678671, lng: 20.001044 },
    { lat: 48.678577, lng: 20.001105 },
    { lat: 48.678294, lng: 20.00129 },
    { lat: 48.677743, lng: 20.001658 },
    { lat: 48.677634, lng: 20.001731 },
    { lat: 48.677322, lng: 20.001941 },
    { lat: 48.677176, lng: 20.002031 },
    { lat: 48.677075, lng: 20.002096 },
    { lat: 48.677039, lng: 20.002118 },
    { lat: 48.677015, lng: 20.002133 },
    { lat: 48.676703, lng: 20.002329 },
    { lat: 48.67634, lng: 20.002558 },
    { lat: 48.675827, lng: 20.002803 },
    { lat: 48.675095, lng: 20.003021 },
    { lat: 48.674844, lng: 20.003092 },
    { lat: 48.674199, lng: 20.00317 },
    { lat: 48.673618, lng: 20.003246 },
    { lat: 48.673592, lng: 20.003225 },
    { lat: 48.673178, lng: 20.002945 },
    { lat: 48.672603, lng: 20.002682 },
    { lat: 48.672534, lng: 20.002651 },
    { lat: 48.672352, lng: 20.002479 },
    { lat: 48.672207, lng: 20.00234 },
    { lat: 48.671873, lng: 20.002016 },
    { lat: 48.671749, lng: 20.001889 },
    { lat: 48.671431, lng: 20.001556 },
    { lat: 48.670755, lng: 20.001223 },
    { lat: 48.670342, lng: 20.000924 },
    { lat: 48.669973, lng: 20.000482 },
    { lat: 48.669728, lng: 20.000114 },
    { lat: 48.66956, lng: 19.999578 },
    { lat: 48.669548, lng: 19.999527 },
    { lat: 48.66943, lng: 19.999115 },
    { lat: 48.668716, lng: 19.998482 },
    { lat: 48.668526, lng: 19.998321 },
    { lat: 48.668344, lng: 19.998165 },
    { lat: 48.668099, lng: 19.998032 },
    { lat: 48.667776, lng: 19.997799 },
    { lat: 48.667603, lng: 19.997674 },
    { lat: 48.667562, lng: 19.997641 },
    { lat: 48.667389, lng: 19.9976 },
    { lat: 48.667199, lng: 19.9975535 },
    { lat: 48.666939, lng: 19.99749 },
    { lat: 48.666161, lng: 19.997332 },
    { lat: 48.665735, lng: 19.997054 },
    { lat: 48.66532, lng: 19.997396 },
    { lat: 48.665219, lng: 19.997458 },
    { lat: 48.665177, lng: 19.997483 },
    { lat: 48.664855, lng: 19.997672 },
    { lat: 48.664715, lng: 19.997754 },
    { lat: 48.664435, lng: 19.997833 },
    { lat: 48.664018, lng: 19.997898 },
    { lat: 48.663241, lng: 19.998404 },
    { lat: 48.663037, lng: 19.999048 },
    { lat: 48.662869, lng: 19.99959 },
    { lat: 48.662695, lng: 20.000153 },
    { lat: 48.662596, lng: 20.000486 },
    { lat: 48.662449, lng: 20.000971 },
    { lat: 48.662241, lng: 20.001662 },
    { lat: 48.662212, lng: 20.001736 },
    { lat: 48.662064, lng: 20.002197 },
    { lat: 48.661985, lng: 20.002468 },
    { lat: 48.661833, lng: 20.002976 },
    { lat: 48.66165, lng: 20.003548 },
    { lat: 48.661483, lng: 20.004148 },
    { lat: 48.661235, lng: 20.005017 },
    { lat: 48.661193, lng: 20.005072 },
    { lat: 48.660951, lng: 20.005532 },
    { lat: 48.660792, lng: 20.005825 },
    { lat: 48.66057, lng: 20.006262 },
    { lat: 48.660344, lng: 20.006727 },
    { lat: 48.659967, lng: 20.00752 },
    { lat: 48.659681, lng: 20.008072 },
    { lat: 48.659525, lng: 20.00838 },
    { lat: 48.659408, lng: 20.008648 },
    { lat: 48.659181, lng: 20.00918 },
    { lat: 48.659071, lng: 20.00952 },
    { lat: 48.658901, lng: 20.010145 },
    { lat: 48.658839, lng: 20.010357 },
    { lat: 48.658657, lng: 20.010947 },
    { lat: 48.658559, lng: 20.011282 },
    { lat: 48.658433, lng: 20.011779 },
    { lat: 48.658339, lng: 20.012139 },
    { lat: 48.657963, lng: 20.012555 },
    { lat: 48.657873, lng: 20.012648 },
    { lat: 48.657556, lng: 20.013032 },
    { lat: 48.657213, lng: 20.013334 },
    { lat: 48.65706, lng: 20.013299 },
    { lat: 48.656466, lng: 20.01313 },
    { lat: 48.656022, lng: 20.013018 },
    { lat: 48.655669, lng: 20.012941 },
    { lat: 48.655226, lng: 20.012846 },
    { lat: 48.655052, lng: 20.012816 },
    { lat: 48.654735, lng: 20.012798 },
    { lat: 48.654443, lng: 20.012868 },
    { lat: 48.654221, lng: 20.012913 },
    { lat: 48.654172, lng: 20.012911 },
    { lat: 48.653717, lng: 20.012966 },
    { lat: 48.653671, lng: 20.012977 },
    { lat: 48.653473, lng: 20.013003 },
    { lat: 48.653174, lng: 20.012788 },
    { lat: 48.652798, lng: 20.012574 },
    { lat: 48.652412, lng: 20.012347 },
    { lat: 48.652163, lng: 20.012184 },
    { lat: 48.651698, lng: 20.011837 },
    { lat: 48.6515, lng: 20.011666 },
    { lat: 48.651113, lng: 20.011384 },
    { lat: 48.650748, lng: 20.01117 },
    { lat: 48.65067, lng: 20.011135 },
    { lat: 48.650148, lng: 20.010876 },
    { lat: 48.649843, lng: 20.010667 },
    { lat: 48.649558, lng: 20.010495 },
    { lat: 48.649342, lng: 20.010358 },
    { lat: 48.649046, lng: 20.010151 },
    { lat: 48.648627, lng: 20.009861 },
    { lat: 48.648552, lng: 20.009809 },
    { lat: 48.648499, lng: 20.009785 },
    { lat: 48.648459, lng: 20.009766 },
    { lat: 48.648097, lng: 20.009515 },
    { lat: 48.64797, lng: 20.009418 },
    { lat: 48.647759, lng: 20.009533 },
    { lat: 48.647613, lng: 20.009601 },
    { lat: 48.647353, lng: 20.009742 },
    { lat: 48.64713, lng: 20.009841 },
    { lat: 48.646959, lng: 20.009905 },
    { lat: 48.646622, lng: 20.010044 },
    { lat: 48.646324, lng: 20.010185 },
    { lat: 48.646301, lng: 20.010196 },
    { lat: 48.646252, lng: 20.010219 },
    { lat: 48.646207, lng: 20.010235 },
    { lat: 48.645757, lng: 20.010404 },
    { lat: 48.645394, lng: 20.010567 },
    { lat: 48.644991, lng: 20.010691 },
    { lat: 48.644446, lng: 20.010907 },
    { lat: 48.644334, lng: 20.010968 },
    { lat: 48.644047, lng: 20.011093 },
    { lat: 48.643863, lng: 20.011155 },
    { lat: 48.643637, lng: 20.011254 },
    { lat: 48.643422, lng: 20.011352 },
    { lat: 48.643183, lng: 20.011442 },
    { lat: 48.64298, lng: 20.011529 },
    { lat: 48.642742, lng: 20.011641 },
    { lat: 48.642685, lng: 20.01167 },
    { lat: 48.642561, lng: 20.011721 },
    { lat: 48.642206, lng: 20.011861 },
    { lat: 48.64185, lng: 20.012009 },
    { lat: 48.641579, lng: 20.012133 },
    { lat: 48.641253, lng: 20.012287 },
    { lat: 48.640933, lng: 20.012575 },
    { lat: 48.640882, lng: 20.012633 },
    { lat: 48.640656, lng: 20.012921 },
    { lat: 48.640299, lng: 20.013395 },
    { lat: 48.640266, lng: 20.013471 },
    { lat: 48.640102, lng: 20.013801 },
    { lat: 48.64007, lng: 20.013847 },
    { lat: 48.640033, lng: 20.013943 },
    { lat: 48.639824, lng: 20.014349 },
    { lat: 48.639809, lng: 20.014463 },
    { lat: 48.639759, lng: 20.014694 },
    { lat: 48.639705, lng: 20.014953 },
    { lat: 48.639617, lng: 20.015038 },
    { lat: 48.639399, lng: 20.015382 },
    { lat: 48.639319, lng: 20.01551 },
    { lat: 48.639188, lng: 20.016042 },
    { lat: 48.639173, lng: 20.016821 },
    { lat: 48.639162, lng: 20.018035 },
    { lat: 48.639122, lng: 20.018307 },
    { lat: 48.639068, lng: 20.01876 },
    { lat: 48.639056, lng: 20.018858 },
    { lat: 48.63908, lng: 20.019404 },
    { lat: 48.638746, lng: 20.019825 },
    { lat: 48.638467, lng: 20.020172 },
    { lat: 48.638084, lng: 20.020524 },
    { lat: 48.638516, lng: 20.0215005 },
    { lat: 48.6386048, lng: 20.0216317 },
    { lat: 48.6388729, lng: 20.0217658 },
    { lat: 48.6389588, lng: 20.0220215 },
    { lat: 48.6390914, lng: 20.0221446 },
    { lat: 48.6389886, lng: 20.0223563 },
    { lat: 48.6395692, lng: 20.0252626 },
    { lat: 48.6396904, lng: 20.0260855 },
    { lat: 48.6402709, lng: 20.0291279 },
    { lat: 48.6401525, lng: 20.0302368 },
    { lat: 48.6399568, lng: 20.0314595 },
    { lat: 48.64012, lng: 20.0342437 },
    { lat: 48.6400994, lng: 20.0345394 },
    { lat: 48.6395804, lng: 20.036443 },
    { lat: 48.6391953, lng: 20.0426799 },
    { lat: 48.6378246, lng: 20.0462186 },
    { lat: 48.6370821, lng: 20.0488728 },
    { lat: 48.6370357, lng: 20.049115 },
    { lat: 48.6370671, lng: 20.049246 },
    { lat: 48.63705, lng: 20.0496461 },
    { lat: 48.6369575, lng: 20.0500526 },
    { lat: 48.636821, lng: 20.0505349 },
    { lat: 48.636388, lng: 20.0513111 },
    { lat: 48.6363208, lng: 20.0517302 },
    { lat: 48.6361234, lng: 20.0520746 },
    { lat: 48.6360855, lng: 20.0525504 },
    { lat: 48.6361668, lng: 20.0528779 },
    { lat: 48.6363053, lng: 20.0530706 },
    { lat: 48.6364168, lng: 20.0535868 },
    { lat: 48.6365242, lng: 20.0537857 },
    { lat: 48.6366518, lng: 20.0542277 },
    { lat: 48.636622, lng: 20.0545461 },
    { lat: 48.6368132, lng: 20.0553778 },
    { lat: 48.6369252, lng: 20.055474 },
    { lat: 48.6369535, lng: 20.0557224 },
    { lat: 48.6370784, lng: 20.055992 },
    { lat: 48.6371817, lng: 20.0560934 },
    { lat: 48.6372511, lng: 20.056543 },
    { lat: 48.6371694, lng: 20.0570219 },
    { lat: 48.6373731, lng: 20.0579685 },
    { lat: 48.6374422, lng: 20.0586002 },
    { lat: 48.637738, lng: 20.0592984 },
    { lat: 48.6380021, lng: 20.0595831 },
    { lat: 48.6380185, lng: 20.0597111 },
    { lat: 48.6381821, lng: 20.0598275 },
    { lat: 48.6382369, lng: 20.0603366 },
    { lat: 48.6382204, lng: 20.0611594 },
    { lat: 48.638121, lng: 20.0613978 },
    { lat: 48.6379436, lng: 20.0615779 },
    { lat: 48.6379532, lng: 20.0616205 },
    { lat: 48.6374429, lng: 20.0618099 },
    { lat: 48.6366232, lng: 20.0623585 },
    { lat: 48.6362337, lng: 20.0624479 },
    { lat: 48.6359212, lng: 20.0625977 },
    { lat: 48.6357185, lng: 20.0630886 },
    { lat: 48.635556, lng: 20.0631893 },
    { lat: 48.6356701, lng: 20.0636654 },
    { lat: 48.635679, lng: 20.0637433 },
    { lat: 48.635678, lng: 20.0639652 },
    { lat: 48.6356857, lng: 20.0641522 },
    { lat: 48.6360346, lng: 20.0649064 },
    { lat: 48.6360073, lng: 20.0649552 },
    { lat: 48.6361802, lng: 20.065106 },
    { lat: 48.6367364, lng: 20.0659996 },
    { lat: 48.6376656, lng: 20.0669254 },
    { lat: 48.6381226, lng: 20.0676004 },
    { lat: 48.6383651, lng: 20.0677161 },
    { lat: 48.6385407, lng: 20.0679051 },
    { lat: 48.638508, lng: 20.0690517 },
    { lat: 48.6388929, lng: 20.0712015 },
    { lat: 48.6389261, lng: 20.0721432 },
    { lat: 48.6388324, lng: 20.0733119 },
    { lat: 48.6384476, lng: 20.0752601 },
    { lat: 48.6384173, lng: 20.0753958 },
    { lat: 48.6384894, lng: 20.0757256 },
    { lat: 48.6386892, lng: 20.0761474 },
    { lat: 48.6389641, lng: 20.0760642 },
    { lat: 48.63921, lng: 20.0759087 },
    { lat: 48.6394254, lng: 20.0756726 },
    { lat: 48.6399014, lng: 20.0753897 },
    { lat: 48.6414125, lng: 20.0742564 },
    { lat: 48.641746, lng: 20.0735484 },
    { lat: 48.6419818, lng: 20.0727107 },
    { lat: 48.6424257, lng: 20.0722758 },
    { lat: 48.6433371, lng: 20.0718721 },
    { lat: 48.6438387, lng: 20.071523 },
    { lat: 48.6442941, lng: 20.0715596 },
    { lat: 48.6444252, lng: 20.071625 },
    { lat: 48.6445935, lng: 20.0718321 },
    { lat: 48.6448076, lng: 20.0718309 },
    { lat: 48.6459623, lng: 20.0710731 },
    { lat: 48.6463155, lng: 20.070703 },
    { lat: 48.6467208, lng: 20.0704006 },
    { lat: 48.6473247, lng: 20.070216 },
    { lat: 48.6477195, lng: 20.0706886 },
    { lat: 48.6483338, lng: 20.0712179 },
    { lat: 48.6492545, lng: 20.0728986 },
    { lat: 48.6494069, lng: 20.0732572 },
    { lat: 48.6500504, lng: 20.0740346 },
    { lat: 48.6503269, lng: 20.0743228 },
    { lat: 48.6505831, lng: 20.0744975 },
    { lat: 48.6512172, lng: 20.0747127 },
    { lat: 48.6516772, lng: 20.074968 },
    { lat: 48.6523077, lng: 20.0757331 },
    { lat: 48.6529089, lng: 20.0762153 },
    { lat: 48.6536363, lng: 20.076266 },
    { lat: 48.6544546, lng: 20.0761166 },
    { lat: 48.6553106, lng: 20.0760868 },
    { lat: 48.6564394, lng: 20.0765576 },
    { lat: 48.657184, lng: 20.0766363 },
    { lat: 48.6575578, lng: 20.0765841 },
    { lat: 48.6581595, lng: 20.0762597 },
    { lat: 48.6582647, lng: 20.0763261 },
    { lat: 48.6588046, lng: 20.0759801 },
    { lat: 48.660103, lng: 20.0756405 },
    { lat: 48.6603559, lng: 20.0760675 },
    { lat: 48.6606803, lng: 20.0763965 },
    { lat: 48.6614639, lng: 20.0775668 },
    { lat: 48.6623554, lng: 20.0777682 },
    { lat: 48.6638291, lng: 20.0790381 },
    { lat: 48.6639924, lng: 20.0792836 },
    { lat: 48.6642756, lng: 20.0792948 },
    { lat: 48.6651001, lng: 20.0797031 },
    { lat: 48.6657298, lng: 20.0796526 },
    { lat: 48.6662206, lng: 20.0797277 },
    { lat: 48.6666291, lng: 20.0798308 },
    { lat: 48.6670047, lng: 20.0794585 },
    { lat: 48.6678116, lng: 20.078839 },
    { lat: 48.6681448, lng: 20.0786995 },
    { lat: 48.6685237, lng: 20.0784077 },
    { lat: 48.6689176, lng: 20.0782068 },
    { lat: 48.669394, lng: 20.0776301 },
    { lat: 48.6701793, lng: 20.0762675 },
    { lat: 48.6707722, lng: 20.0755169 },
    { lat: 48.6707799, lng: 20.0741446 },
    { lat: 48.6709491, lng: 20.0729765 },
    { lat: 48.671205, lng: 20.0719119 },
    { lat: 48.6716471, lng: 20.0709962 },
    { lat: 48.6717232, lng: 20.0705451 },
    { lat: 48.6717858, lng: 20.0691743 },
    { lat: 48.6720567, lng: 20.0674104 },
    { lat: 48.6722653, lng: 20.0669504 },
    { lat: 48.67287, lng: 20.0663439 },
    { lat: 48.6734906, lng: 20.0660995 },
    { lat: 48.674329, lng: 20.0658888 },
    { lat: 48.6765646, lng: 20.0635668 },
    { lat: 48.6779218, lng: 20.0627201 },
    { lat: 48.6783578, lng: 20.0623679 },
    { lat: 48.6793711, lng: 20.0609905 },
    { lat: 48.6797773, lng: 20.0601624 },
    { lat: 48.6801457, lng: 20.0597335 },
    { lat: 48.6809562, lng: 20.0592301 },
    { lat: 48.6821052, lng: 20.057921 },
    { lat: 48.6825153, lng: 20.0575412 },
    { lat: 48.6834984, lng: 20.0568575 },
    { lat: 48.683923, lng: 20.056337 },
    { lat: 48.6842825, lng: 20.0555496 },
    { lat: 48.6843358, lng: 20.0546356 },
    { lat: 48.6845138, lng: 20.0537344 },
    { lat: 48.6845585, lng: 20.0500519 },
    { lat: 48.6849608, lng: 20.048151 },
    { lat: 48.6850733, lng: 20.0469513 },
    { lat: 48.6850436, lng: 20.0464529 },
    { lat: 48.6852174, lng: 20.0455652 },
    { lat: 48.6852456, lng: 20.0449127 },
    { lat: 48.685573, lng: 20.0443325 },
    { lat: 48.6859719, lng: 20.042573 },
    { lat: 48.6863424, lng: 20.0420022 },
    { lat: 48.6872452, lng: 20.040873 },
    { lat: 48.6881498, lng: 20.0401436 },
    { lat: 48.6889336, lng: 20.0390781 },
    { lat: 48.6893289, lng: 20.0389683 },
    { lat: 48.6902851, lng: 20.0383937 },
    { lat: 48.6906179, lng: 20.0381893 },
    { lat: 48.6908586, lng: 20.037908 },
    { lat: 48.6908257, lng: 20.0373382 },
    { lat: 48.6910329, lng: 20.0365172 },
    { lat: 48.6911068, lng: 20.0360189 },
    { lat: 48.6912345, lng: 20.0356492 },
    { lat: 48.6914901, lng: 20.0337202 },
    { lat: 48.6916731, lng: 20.0332215 },
    { lat: 48.6916155, lng: 20.033001 },
    { lat: 48.6916318, lng: 20.0327114 },
    { lat: 48.6919588, lng: 20.0311217 },
    { lat: 48.6919754, lng: 20.0306028 },
    { lat: 48.6923799, lng: 20.0289469 },
    { lat: 48.6924336, lng: 20.0285361 },
    { lat: 48.6925523, lng: 20.0283826 },
    { lat: 48.6926227, lng: 20.0281795 },
    { lat: 48.6928754, lng: 20.0279737 },
    { lat: 48.6935916, lng: 20.02667 },
    { lat: 48.6940804, lng: 20.0260161 },
    { lat: 48.6946071, lng: 20.0254901 },
    { lat: 48.6946548, lng: 20.0254742 },
    { lat: 48.6947905, lng: 20.0247821 },
    { lat: 48.6950736, lng: 20.0241719 },
    { lat: 48.6950506, lng: 20.0236407 },
    { lat: 48.6952512, lng: 20.0232669 },
    { lat: 48.6955234, lng: 20.0223889 },
    { lat: 48.6956277, lng: 20.0214074 },
    { lat: 48.6958752, lng: 20.0211828 },
    { lat: 48.6966508, lng: 20.0200594 },
    { lat: 48.6963227, lng: 20.0177038 },
    { lat: 48.6961388, lng: 20.0169665 },
    { lat: 48.6959513, lng: 20.0165226 },
    { lat: 48.6953627, lng: 20.0154758 },
    { lat: 48.6951328, lng: 20.0146384 },
    { lat: 48.6949539, lng: 20.0141577 },
    { lat: 48.6946318, lng: 20.0124006 },
    { lat: 48.6943955, lng: 20.0117592 },
    { lat: 48.6942523, lng: 20.0113486 },
    { lat: 48.6941298, lng: 20.0108953 },
  ],
  Muráň: [
    { lat: 48.785364, lng: 19.977053 },
    { lat: 48.785015, lng: 19.975881 },
    { lat: 48.785499, lng: 19.974316 },
    { lat: 48.785454, lng: 19.973091 },
    { lat: 48.781023, lng: 19.971296 },
    { lat: 48.782048, lng: 19.966652 },
    { lat: 48.782257, lng: 19.965702 },
    { lat: 48.782254, lng: 19.965642 },
    { lat: 48.782197, lng: 19.964277 },
    { lat: 48.781718, lng: 19.964344 },
    { lat: 48.782288, lng: 19.958351 },
    { lat: 48.781694, lng: 19.950115 },
    { lat: 48.781613, lng: 19.948603 },
    { lat: 48.781442, lng: 19.942717 },
    { lat: 48.782691, lng: 19.941871 },
    { lat: 48.783981, lng: 19.940623 },
    { lat: 48.784201, lng: 19.940849 },
    { lat: 48.786766, lng: 19.939113 },
    { lat: 48.786835, lng: 19.938914 },
    { lat: 48.7867541, lng: 19.9388067 },
    { lat: 48.786581, lng: 19.938577 },
    { lat: 48.78626, lng: 19.938603 },
    { lat: 48.785904, lng: 19.938124 },
    { lat: 48.785876, lng: 19.938067 },
    { lat: 48.785873, lng: 19.93801 },
    { lat: 48.785956, lng: 19.937446 },
    { lat: 48.785947, lng: 19.937393 },
    { lat: 48.785581, lng: 19.936836 },
    { lat: 48.785577, lng: 19.936725 },
    { lat: 48.785676, lng: 19.936338 },
    { lat: 48.785697, lng: 19.936249 },
    { lat: 48.786108, lng: 19.935189 },
    { lat: 48.78637, lng: 19.934647 },
    { lat: 48.786372, lng: 19.934579 },
    { lat: 48.786308, lng: 19.933546 },
    { lat: 48.786307, lng: 19.933492 },
    { lat: 48.786319, lng: 19.933429 },
    { lat: 48.786687, lng: 19.932091 },
    { lat: 48.786696, lng: 19.932046 },
    { lat: 48.786692, lng: 19.931994 },
    { lat: 48.786664, lng: 19.931762 },
    { lat: 48.786659, lng: 19.931692 },
    { lat: 48.78666, lng: 19.931626 },
    { lat: 48.786743, lng: 19.931102 },
    { lat: 48.786627, lng: 19.930767 },
    { lat: 48.786276, lng: 19.930323 },
    { lat: 48.786071, lng: 19.929795 },
    { lat: 48.78545, lng: 19.929367 },
    { lat: 48.78515, lng: 19.928727 },
    { lat: 48.785071, lng: 19.926981 },
    { lat: 48.7851348, lng: 19.9267225 },
    { lat: 48.78524, lng: 19.926296 },
    { lat: 48.785247, lng: 19.926233 },
    { lat: 48.785237, lng: 19.92617 },
    { lat: 48.785064, lng: 19.925773 },
    { lat: 48.785043, lng: 19.92574 },
    { lat: 48.785018, lng: 19.925729 },
    { lat: 48.784563, lng: 19.925654 },
    { lat: 48.783812, lng: 19.926155 },
    { lat: 48.78378, lng: 19.926165 },
    { lat: 48.783749, lng: 19.926156 },
    { lat: 48.7833042, lng: 19.9254857 },
    { lat: 48.783242, lng: 19.925392 },
    { lat: 48.783067, lng: 19.924523 },
    { lat: 48.7828, lng: 19.923976 },
    { lat: 48.781499, lng: 19.92333 },
    { lat: 48.781224, lng: 19.923053 },
    { lat: 48.780745, lng: 19.922299 },
    { lat: 48.780482, lng: 19.921616 },
    { lat: 48.7802949, lng: 19.9213949 },
    { lat: 48.779943, lng: 19.920979 },
    { lat: 48.77988, lng: 19.9208 },
    { lat: 48.779838, lng: 19.920373 },
    { lat: 48.778362, lng: 19.919074 },
    { lat: 48.777931, lng: 19.919118 },
    { lat: 48.77763, lng: 19.918998 },
    { lat: 48.777335, lng: 19.918761 },
    { lat: 48.777034, lng: 19.918388 },
    { lat: 48.776938, lng: 19.918338 },
    { lat: 48.776898, lng: 19.918294 },
    { lat: 48.776777, lng: 19.918207 },
    { lat: 48.776796, lng: 19.918152 },
    { lat: 48.77677, lng: 19.918082 },
    { lat: 48.776788, lng: 19.918021 },
    { lat: 48.77664, lng: 19.917872 },
    { lat: 48.776656, lng: 19.917818 },
    { lat: 48.776685, lng: 19.917725 },
    { lat: 48.776775, lng: 19.917462 },
    { lat: 48.777598, lng: 19.916861 },
    { lat: 48.777588, lng: 19.916844 },
    { lat: 48.777763, lng: 19.916717 },
    { lat: 48.778034, lng: 19.916357 },
    { lat: 48.778321, lng: 19.915842 },
    { lat: 48.778439, lng: 19.915701 },
    { lat: 48.778744, lng: 19.915577 },
    { lat: 48.778749, lng: 19.915603 },
    { lat: 48.779408, lng: 19.914634 },
    { lat: 48.778982, lng: 19.913511 },
    { lat: 48.77884, lng: 19.913108 },
    { lat: 48.778899, lng: 19.912855 },
    { lat: 48.778989, lng: 19.912618 },
    { lat: 48.779039, lng: 19.912381 },
    { lat: 48.779017, lng: 19.912101 },
    { lat: 48.77913, lng: 19.911642 },
    { lat: 48.779169, lng: 19.911436 },
    { lat: 48.779115, lng: 19.911129 },
    { lat: 48.779281, lng: 19.910557 },
    { lat: 48.779301, lng: 19.910318 },
    { lat: 48.779327, lng: 19.909308 },
    { lat: 48.779495, lng: 19.908759 },
    { lat: 48.779574, lng: 19.908419 },
    { lat: 48.779652, lng: 19.907902 },
    { lat: 48.779707, lng: 19.907755 },
    { lat: 48.779729, lng: 19.907345 },
    { lat: 48.779946, lng: 19.906818 },
    { lat: 48.780034, lng: 19.906663 },
    { lat: 48.780106, lng: 19.906284 },
    { lat: 48.780215, lng: 19.906093 },
    { lat: 48.780322, lng: 19.905793 },
    { lat: 48.780567, lng: 19.905581 },
    { lat: 48.780617, lng: 19.905493 },
    { lat: 48.78067, lng: 19.905042 },
    { lat: 48.780768, lng: 19.90463 },
    { lat: 48.780778, lng: 19.904476 },
    { lat: 48.780813, lng: 19.904345 },
    { lat: 48.78104, lng: 19.90393 },
    { lat: 48.78129, lng: 19.903388 },
    { lat: 48.781296, lng: 19.903313 },
    { lat: 48.781355, lng: 19.903268 },
    { lat: 48.781496, lng: 19.902967 },
    { lat: 48.781714, lng: 19.902568 },
    { lat: 48.781713, lng: 19.902529 },
    { lat: 48.781946, lng: 19.900733 },
    { lat: 48.781993, lng: 19.900386 },
    { lat: 48.782324, lng: 19.898294 },
    { lat: 48.782383, lng: 19.897917 },
    { lat: 48.78247, lng: 19.897368 },
    { lat: 48.782501, lng: 19.897169 },
    { lat: 48.783115, lng: 19.8965 },
    { lat: 48.783435, lng: 19.895762 },
    { lat: 48.783468, lng: 19.895682 },
    { lat: 48.783828, lng: 19.894851 },
    { lat: 48.783686, lng: 19.893448 },
    { lat: 48.783586, lng: 19.891301 },
    { lat: 48.7831, lng: 19.891075 },
    { lat: 48.781885, lng: 19.889507 },
    { lat: 48.781776, lng: 19.889394 },
    { lat: 48.780414, lng: 19.887984 },
    { lat: 48.77962, lng: 19.887113 },
    { lat: 48.778669, lng: 19.887247 },
    { lat: 48.778534, lng: 19.887181 },
    { lat: 48.777201, lng: 19.886525 },
    { lat: 48.777057, lng: 19.886558 },
    { lat: 48.775804, lng: 19.886842 },
    { lat: 48.773418, lng: 19.886122 },
    { lat: 48.772064, lng: 19.885814 },
    { lat: 48.771072, lng: 19.886539 },
    { lat: 48.770872, lng: 19.886661 },
    { lat: 48.770502, lng: 19.887111 },
    { lat: 48.770295, lng: 19.887365 },
    { lat: 48.76994, lng: 19.887884 },
    { lat: 48.769622, lng: 19.888156 },
    { lat: 48.769584, lng: 19.888171 },
    { lat: 48.768777, lng: 19.88939 },
    { lat: 48.768556, lng: 19.889996 },
    { lat: 48.768271, lng: 19.891176 },
    { lat: 48.767595, lng: 19.893324 },
    { lat: 48.765834, lng: 19.899531 },
    { lat: 48.765435, lng: 19.900338 },
    { lat: 48.76472, lng: 19.900983 },
    { lat: 48.763981, lng: 19.901359 },
    { lat: 48.762909, lng: 19.903232 },
    { lat: 48.762625, lng: 19.90511 },
    { lat: 48.762427, lng: 19.9060625 },
    { lat: 48.762288, lng: 19.906616 },
    { lat: 48.761707, lng: 19.907374 },
    { lat: 48.761412, lng: 19.908004 },
    { lat: 48.761132, lng: 19.908414 },
    { lat: 48.760996, lng: 19.908527 },
    { lat: 48.76096, lng: 19.908639 },
    { lat: 48.760591, lng: 19.90891 },
    { lat: 48.756172, lng: 19.913979 },
    { lat: 48.756245, lng: 19.915958 },
    { lat: 48.756235, lng: 19.917024 },
    { lat: 48.756177, lng: 19.917388 },
    { lat: 48.756044, lng: 19.920317 },
    { lat: 48.755791, lng: 19.921819 },
    { lat: 48.755727, lng: 19.922942 },
    { lat: 48.755001, lng: 19.924892 },
    { lat: 48.754547, lng: 19.926768 },
    { lat: 48.75381, lng: 19.928548 },
    { lat: 48.754002, lng: 19.929143 },
    { lat: 48.754187, lng: 19.930501 },
    { lat: 48.754779, lng: 19.932246 },
    { lat: 48.755251, lng: 19.934423 },
    { lat: 48.755226, lng: 19.93628 },
    { lat: 48.75514, lng: 19.937008 },
    { lat: 48.754905, lng: 19.937889 },
    { lat: 48.754939, lng: 19.938908 },
    { lat: 48.755098, lng: 19.94036 },
    { lat: 48.755167, lng: 19.941844 },
    { lat: 48.754928, lng: 19.942662 },
    { lat: 48.754203, lng: 19.943707 },
    { lat: 48.753824, lng: 19.947656 },
    { lat: 48.753529, lng: 19.948627 },
    { lat: 48.752531, lng: 19.95075 },
    { lat: 48.75168, lng: 19.952049 },
    { lat: 48.750515, lng: 19.954324 },
    { lat: 48.750505, lng: 19.955356 },
    { lat: 48.750639, lng: 19.956054 },
    { lat: 48.750291, lng: 19.957063 },
    { lat: 48.75013, lng: 19.957335 },
    { lat: 48.750404, lng: 19.959555 },
    { lat: 48.750429, lng: 19.960894 },
    { lat: 48.750299, lng: 19.961556 },
    { lat: 48.749833, lng: 19.96214 },
    { lat: 48.74881, lng: 19.962512 },
    { lat: 48.746994, lng: 19.963556 },
    { lat: 48.745946, lng: 19.965328 },
    { lat: 48.745313, lng: 19.965401 },
    { lat: 48.744936, lng: 19.965169 },
    { lat: 48.744757, lng: 19.96517 },
    { lat: 48.744241, lng: 19.966053 },
    { lat: 48.743061, lng: 19.96627 },
    { lat: 48.742558, lng: 19.966898 },
    { lat: 48.74011, lng: 19.968313 },
    { lat: 48.739462, lng: 19.968159 },
    { lat: 48.738776, lng: 19.967743 },
    { lat: 48.738416, lng: 19.968196 },
    { lat: 48.737055, lng: 19.969587 },
    { lat: 48.736122, lng: 19.969098 },
    { lat: 48.735312, lng: 19.969391 },
    { lat: 48.735299, lng: 19.969636 },
    { lat: 48.733816, lng: 19.970076 },
    { lat: 48.73331, lng: 19.970391 },
    { lat: 48.732482, lng: 19.97013 },
    { lat: 48.731915, lng: 19.969521 },
    { lat: 48.730481, lng: 19.968925 },
    { lat: 48.729543, lng: 19.968616 },
    { lat: 48.729173, lng: 19.968594 },
    { lat: 48.728045, lng: 19.968168 },
    { lat: 48.72632, lng: 19.968395 },
    { lat: 48.726215, lng: 19.96856 },
    { lat: 48.726113, lng: 19.968953 },
    { lat: 48.725562, lng: 19.969823 },
    { lat: 48.724992, lng: 19.97135 },
    { lat: 48.724426, lng: 19.971583 },
    { lat: 48.7238487, lng: 19.9712695 },
    { lat: 48.723754, lng: 19.971218 },
    { lat: 48.723277, lng: 19.971734 },
    { lat: 48.721882, lng: 19.971572 },
    { lat: 48.721587, lng: 19.971079 },
    { lat: 48.721155, lng: 19.971522 },
    { lat: 48.720627, lng: 19.971838 },
    { lat: 48.719239, lng: 19.972931 },
    { lat: 48.7175653, lng: 19.9723308 },
    { lat: 48.716835, lng: 19.972069 },
    { lat: 48.716535, lng: 19.971297 },
    { lat: 48.716345, lng: 19.969449 },
    { lat: 48.715692, lng: 19.968502 },
    { lat: 48.715388, lng: 19.968485 },
    { lat: 48.714297, lng: 19.967553 },
    { lat: 48.713461, lng: 19.967228 },
    { lat: 48.713222, lng: 19.967291 },
    { lat: 48.712995, lng: 19.966822 },
    { lat: 48.712829, lng: 19.965409 },
    { lat: 48.711886, lng: 19.965121 },
    { lat: 48.710837, lng: 19.965036 },
    { lat: 48.71047, lng: 19.963363 },
    { lat: 48.710306, lng: 19.962301 },
    { lat: 48.709868, lng: 19.961669 },
    { lat: 48.709547, lng: 19.961333 },
    { lat: 48.708675, lng: 19.960968 },
    { lat: 48.707157, lng: 19.961593 },
    { lat: 48.706882, lng: 19.96157 },
    { lat: 48.706167, lng: 19.961273 },
    { lat: 48.705324, lng: 19.960702 },
    { lat: 48.705117, lng: 19.96104 },
    { lat: 48.705431, lng: 19.961469 },
    { lat: 48.705277, lng: 19.961615 },
    { lat: 48.705229, lng: 19.961837 },
    { lat: 48.705096, lng: 19.962087 },
    { lat: 48.704551, lng: 19.96273 },
    { lat: 48.702772, lng: 19.963975 },
    { lat: 48.702753, lng: 19.964291 },
    { lat: 48.703205, lng: 19.965281 },
    { lat: 48.70301, lng: 19.966091 },
    { lat: 48.703156, lng: 19.966556 },
    { lat: 48.704097, lng: 19.968529 },
    { lat: 48.704654, lng: 19.969323 },
    { lat: 48.705553, lng: 19.970923 },
    { lat: 48.705719, lng: 19.971749 },
    { lat: 48.706056, lng: 19.97243 },
    { lat: 48.706593, lng: 19.97367 },
    { lat: 48.706739, lng: 19.974156 },
    { lat: 48.707251, lng: 19.975249 },
    { lat: 48.706906, lng: 19.97677 },
    { lat: 48.706616, lng: 19.977244 },
    { lat: 48.706248, lng: 19.977157 },
    { lat: 48.705856, lng: 19.977611 },
    { lat: 48.705463, lng: 19.978323 },
    { lat: 48.703631, lng: 19.978469 },
    { lat: 48.703352, lng: 19.978828 },
    { lat: 48.700978, lng: 19.983886 },
    { lat: 48.700477, lng: 19.985154 },
    { lat: 48.699326, lng: 19.986934 },
    { lat: 48.695881, lng: 19.991489 },
    { lat: 48.6957, lng: 19.992875 },
    { lat: 48.695369, lng: 19.993862 },
    { lat: 48.695234, lng: 19.994767 },
    { lat: 48.694996, lng: 19.995869 },
    { lat: 48.694937, lng: 19.996851 },
    { lat: 48.694866, lng: 19.997306 },
    { lat: 48.69486, lng: 19.997892 },
    { lat: 48.694649, lng: 19.998319 },
    { lat: 48.694294, lng: 19.998402 },
    { lat: 48.693867, lng: 19.998764 },
    { lat: 48.693198, lng: 19.999173 },
    { lat: 48.691819, lng: 20.000267 },
    { lat: 48.691295, lng: 20.001016 },
    { lat: 48.690632, lng: 20.004345 },
    { lat: 48.69087, lng: 20.0049121 },
    { lat: 48.6911908, lng: 20.0051511 },
    { lat: 48.691393, lng: 20.0053739 },
    { lat: 48.6914926, lng: 20.0056068 },
    { lat: 48.6916637, lng: 20.0058194 },
    { lat: 48.6924529, lng: 20.006484 },
    { lat: 48.6927099, lng: 20.0068388 },
    { lat: 48.6927582, lng: 20.0069679 },
    { lat: 48.6927983, lng: 20.007419 },
    { lat: 48.6931714, lng: 20.0085304 },
    { lat: 48.693336, lng: 20.0091758 },
    { lat: 48.6938449, lng: 20.0104151 },
    { lat: 48.6940963, lng: 20.0108226 },
    { lat: 48.6941298, lng: 20.0108953 },
    { lat: 48.6942342, lng: 20.0107003 },
    { lat: 48.6943261, lng: 20.010719 },
    { lat: 48.694394, lng: 20.0105917 },
    { lat: 48.694569, lng: 20.0105444 },
    { lat: 48.6948716, lng: 20.0108047 },
    { lat: 48.6948788, lng: 20.0108968 },
    { lat: 48.6949663, lng: 20.0108895 },
    { lat: 48.6950465, lng: 20.011058 },
    { lat: 48.6951596, lng: 20.0110929 },
    { lat: 48.6959693, lng: 20.0103094 },
    { lat: 48.6963881, lng: 20.0096552 },
    { lat: 48.6967759, lng: 20.0093384 },
    { lat: 48.6971957, lng: 20.0088152 },
    { lat: 48.6976506, lng: 20.0087313 },
    { lat: 48.6976754, lng: 20.0086747 },
    { lat: 48.6977817, lng: 20.0087398 },
    { lat: 48.6978958, lng: 20.0085874 },
    { lat: 48.6984113, lng: 20.0086335 },
    { lat: 48.6985648, lng: 20.0085329 },
    { lat: 48.6987079, lng: 20.0085849 },
    { lat: 48.6987235, lng: 20.0087432 },
    { lat: 48.6983107, lng: 20.0095131 },
    { lat: 48.6978997, lng: 20.0101124 },
    { lat: 48.6977932, lng: 20.0108813 },
    { lat: 48.697872, lng: 20.0124199 },
    { lat: 48.6979274, lng: 20.0126378 },
    { lat: 48.6981574, lng: 20.0133693 },
    { lat: 48.6983368, lng: 20.0135109 },
    { lat: 48.6986497, lng: 20.0139112 },
    { lat: 48.6987592, lng: 20.0142163 },
    { lat: 48.6988497, lng: 20.014877 },
    { lat: 48.6988489, lng: 20.0152868 },
    { lat: 48.6987919, lng: 20.0156459 },
    { lat: 48.698351, lng: 20.0170678 },
    { lat: 48.6983267, lng: 20.0175169 },
    { lat: 48.6984293, lng: 20.0179232 },
    { lat: 48.6986137, lng: 20.0182419 },
    { lat: 48.6991468, lng: 20.0187311 },
    { lat: 48.6993656, lng: 20.019038 },
    { lat: 48.6995328, lng: 20.0193747 },
    { lat: 48.6997901, lng: 20.0202531 },
    { lat: 48.6999779, lng: 20.0207038 },
    { lat: 48.7008009, lng: 20.0205828 },
    { lat: 48.7009551, lng: 20.0202569 },
    { lat: 48.7020232, lng: 20.0208864 },
    { lat: 48.7024729, lng: 20.0203501 },
    { lat: 48.7029962, lng: 20.0195833 },
    { lat: 48.7032817, lng: 20.01957 },
    { lat: 48.7034368, lng: 20.0194233 },
    { lat: 48.7035625, lng: 20.0191992 },
    { lat: 48.7036404, lng: 20.0188498 },
    { lat: 48.7037531, lng: 20.0177169 },
    { lat: 48.7038225, lng: 20.017599 },
    { lat: 48.7040081, lng: 20.0176966 },
    { lat: 48.7041414, lng: 20.0174913 },
    { lat: 48.704448, lng: 20.0174408 },
    { lat: 48.7048654, lng: 20.0175965 },
    { lat: 48.7049557, lng: 20.0180012 },
    { lat: 48.7056582, lng: 20.0177525 },
    { lat: 48.7062353, lng: 20.0175158 },
    { lat: 48.7066671, lng: 20.0171994 },
    { lat: 48.7069812, lng: 20.0168172 },
    { lat: 48.7080371, lng: 20.0160741 },
    { lat: 48.7094309, lng: 20.0155413 },
    { lat: 48.7096241, lng: 20.0156389 },
    { lat: 48.7097824, lng: 20.0155696 },
    { lat: 48.7100721, lng: 20.0156273 },
    { lat: 48.7109049, lng: 20.0160199 },
    { lat: 48.7116455, lng: 20.0159234 },
    { lat: 48.7118251, lng: 20.0161249 },
    { lat: 48.7122075, lng: 20.0163814 },
    { lat: 48.7141533, lng: 20.0175298 },
    { lat: 48.7145093, lng: 20.0178245 },
    { lat: 48.7148451, lng: 20.0184521 },
    { lat: 48.7151673, lng: 20.018782 },
    { lat: 48.7154318, lng: 20.0190158 },
    { lat: 48.716231, lng: 20.0194363 },
    { lat: 48.7170368, lng: 20.0201495 },
    { lat: 48.7174139, lng: 20.0207375 },
    { lat: 48.7177354, lng: 20.021538 },
    { lat: 48.7182771, lng: 20.0223708 },
    { lat: 48.7184213, lng: 20.0225076 },
    { lat: 48.7193069, lng: 20.0230007 },
    { lat: 48.7198957, lng: 20.023214 },
    { lat: 48.7202276, lng: 20.0234978 },
    { lat: 48.7205513, lng: 20.024 },
    { lat: 48.7208692, lng: 20.0249244 },
    { lat: 48.7211009, lng: 20.0251874 },
    { lat: 48.7213103, lng: 20.0255346 },
    { lat: 48.7221552, lng: 20.0258404 },
    { lat: 48.7224738, lng: 20.0258608 },
    { lat: 48.7228599, lng: 20.0256889 },
    { lat: 48.7231009, lng: 20.025798 },
    { lat: 48.7242406, lng: 20.0267946 },
    { lat: 48.7249559, lng: 20.0272343 },
    { lat: 48.7258423, lng: 20.0269875 },
    { lat: 48.7260405, lng: 20.0270129 },
    { lat: 48.7264304, lng: 20.0273127 },
    { lat: 48.7266306, lng: 20.0277125 },
    { lat: 48.7264704, lng: 20.0282465 },
    { lat: 48.7272981, lng: 20.0298194 },
    { lat: 48.7273376, lng: 20.0297924 },
    { lat: 48.728032, lng: 20.0307819 },
    { lat: 48.7284005, lng: 20.0315447 },
    { lat: 48.7289702, lng: 20.0310775 },
    { lat: 48.7294891, lng: 20.0327797 },
    { lat: 48.7298388, lng: 20.0337152 },
    { lat: 48.7304152, lng: 20.0342207 },
    { lat: 48.7307528, lng: 20.0343478 },
    { lat: 48.730903, lng: 20.0351456 },
    { lat: 48.7313104, lng: 20.0362505 },
    { lat: 48.7313639, lng: 20.0366697 },
    { lat: 48.7313706, lng: 20.0372132 },
    { lat: 48.731446, lng: 20.0374308 },
    { lat: 48.7319084, lng: 20.0379322 },
    { lat: 48.7320841, lng: 20.0382302 },
    { lat: 48.732144, lng: 20.0391579 },
    { lat: 48.7324715, lng: 20.0403729 },
    { lat: 48.7325087, lng: 20.0407879 },
    { lat: 48.7326841, lng: 20.0415673 },
    { lat: 48.7328812, lng: 20.0421895 },
    { lat: 48.7330007, lng: 20.0430627 },
    { lat: 48.7328244, lng: 20.044026 },
    { lat: 48.7325911, lng: 20.0445147 },
    { lat: 48.7322826, lng: 20.0457984 },
    { lat: 48.7321135, lng: 20.0470714 },
    { lat: 48.7321042, lng: 20.0481252 },
    { lat: 48.7319963, lng: 20.0486481 },
    { lat: 48.7317601, lng: 20.0493641 },
    { lat: 48.7316804, lng: 20.0496738 },
    { lat: 48.7317064, lng: 20.0497541 },
    { lat: 48.731637, lng: 20.0499309 },
    { lat: 48.7310326, lng: 20.050733 },
    { lat: 48.7309758, lng: 20.051129 },
    { lat: 48.7314258, lng: 20.0525576 },
    { lat: 48.7314026, lng: 20.0531963 },
    { lat: 48.7312219, lng: 20.0537824 },
    { lat: 48.7309614, lng: 20.0541841 },
    { lat: 48.7309026, lng: 20.0544797 },
    { lat: 48.7305793, lng: 20.0552174 },
    { lat: 48.7303612, lng: 20.0564792 },
    { lat: 48.7301642, lng: 20.0572485 },
    { lat: 48.7297844, lng: 20.0581385 },
    { lat: 48.7296675, lng: 20.0589376 },
    { lat: 48.7295212, lng: 20.0590463 },
    { lat: 48.7291641, lng: 20.0591053 },
    { lat: 48.7289587, lng: 20.0590919 },
    { lat: 48.7288182, lng: 20.0589938 },
    { lat: 48.7283364, lng: 20.0589683 },
    { lat: 48.7280331, lng: 20.0590432 },
    { lat: 48.7278714, lng: 20.0591905 },
    { lat: 48.7274657, lng: 20.0597639 },
    { lat: 48.7269447, lng: 20.0609732 },
    { lat: 48.7265469, lng: 20.06231 },
    { lat: 48.726291, lng: 20.0633972 },
    { lat: 48.7259497, lng: 20.0639688 },
    { lat: 48.7257498, lng: 20.0642845 },
    { lat: 48.7255815, lng: 20.0649236 },
    { lat: 48.7243756, lng: 20.0667962 },
    { lat: 48.7241144, lng: 20.0674554 },
    { lat: 48.7238575, lng: 20.0678877 },
    { lat: 48.7236781, lng: 20.0680198 },
    { lat: 48.7233752, lng: 20.068648 },
    { lat: 48.7231083, lng: 20.0697444 },
    { lat: 48.7232868, lng: 20.0697576 },
    { lat: 48.7236884, lng: 20.0702067 },
    { lat: 48.7240129, lng: 20.0704576 },
    { lat: 48.7244328, lng: 20.0705605 },
    { lat: 48.7247526, lng: 20.0708301 },
    { lat: 48.7251655, lng: 20.0710377 },
    { lat: 48.7256816, lng: 20.0715639 },
    { lat: 48.725817, lng: 20.0717916 },
    { lat: 48.725519, lng: 20.072293 },
    { lat: 48.7256336, lng: 20.0726454 },
    { lat: 48.726183, lng: 20.0732402 },
    { lat: 48.7265703, lng: 20.0735088 },
    { lat: 48.726943, lng: 20.0744084 },
    { lat: 48.7277481, lng: 20.0750877 },
    { lat: 48.7274378, lng: 20.0755139 },
    { lat: 48.7280118, lng: 20.0763515 },
    { lat: 48.7283208, lng: 20.0767135 },
    { lat: 48.7286117, lng: 20.0768028 },
    { lat: 48.7293002, lng: 20.0767281 },
    { lat: 48.7296507, lng: 20.0769292 },
    { lat: 48.7299276, lng: 20.0771929 },
    { lat: 48.7307286, lng: 20.0774734 },
    { lat: 48.7309961, lng: 20.0777618 },
    { lat: 48.7313748, lng: 20.0783678 },
    { lat: 48.7318362, lng: 20.0787972 },
    { lat: 48.7327436, lng: 20.0790884 },
    { lat: 48.7328988, lng: 20.079224 },
    { lat: 48.7334775, lng: 20.0791304 },
    { lat: 48.7343333, lng: 20.0795385 },
    { lat: 48.7347453, lng: 20.0796439 },
    { lat: 48.7355003, lng: 20.0803099 },
    { lat: 48.7367223, lng: 20.0826379 },
    { lat: 48.7375467, lng: 20.0833495 },
    { lat: 48.7380919, lng: 20.0846375 },
    { lat: 48.7386589, lng: 20.0858018 },
    { lat: 48.7389343, lng: 20.0859548 },
    { lat: 48.7392889, lng: 20.0863322 },
    { lat: 48.7402892, lng: 20.0877885 },
    { lat: 48.7410402, lng: 20.0885796 },
    { lat: 48.7413367, lng: 20.0888059 },
    { lat: 48.7414727, lng: 20.0888837 },
    { lat: 48.7417573, lng: 20.0888175 },
    { lat: 48.7427901, lng: 20.0889273 },
    { lat: 48.7437443, lng: 20.0884952 },
    { lat: 48.7443903, lng: 20.0883169 },
    { lat: 48.7453932, lng: 20.0882132 },
    { lat: 48.7459135, lng: 20.0883546 },
    { lat: 48.7464833, lng: 20.0882395 },
    { lat: 48.7467175, lng: 20.0891172 },
    { lat: 48.7468669, lng: 20.0894105 },
    { lat: 48.7470396, lng: 20.0896095 },
    { lat: 48.747458, lng: 20.0905293 },
    { lat: 48.7480327, lng: 20.0913877 },
    { lat: 48.7484339, lng: 20.0921924 },
    { lat: 48.7486642, lng: 20.0928078 },
    { lat: 48.7488848, lng: 20.0929953 },
    { lat: 48.749331, lng: 20.0939085 },
    { lat: 48.7495035, lng: 20.0944906 },
    { lat: 48.7494937, lng: 20.0978818 },
    { lat: 48.7497908, lng: 20.0987295 },
    { lat: 48.7503137, lng: 20.0994822 },
    { lat: 48.7506651, lng: 20.1001385 },
    { lat: 48.7510919, lng: 20.1012639 },
    { lat: 48.7514693, lng: 20.1017619 },
    { lat: 48.751903, lng: 20.1026775 },
    { lat: 48.7519523, lng: 20.1030502 },
    { lat: 48.7505785, lng: 20.1093718 },
    { lat: 48.7495892, lng: 20.1099988 },
    { lat: 48.7488404, lng: 20.1102265 },
    { lat: 48.7484513, lng: 20.1104587 },
    { lat: 48.7479565, lng: 20.1106184 },
    { lat: 48.7472932, lng: 20.1111425 },
    { lat: 48.7470164, lng: 20.1115088 },
    { lat: 48.7456846, lng: 20.1136794 },
    { lat: 48.7455663, lng: 20.1142077 },
    { lat: 48.7453896, lng: 20.1152814 },
    { lat: 48.7454388, lng: 20.1155491 },
    { lat: 48.7453998, lng: 20.1167765 },
    { lat: 48.7454079, lng: 20.1168367 },
    { lat: 48.7455373, lng: 20.1169118 },
    { lat: 48.7470693, lng: 20.117718 },
    { lat: 48.7474119, lng: 20.1181967 },
    { lat: 48.7479752, lng: 20.1195561 },
    { lat: 48.7482443, lng: 20.1206274 },
    { lat: 48.7482956, lng: 20.1210306 },
    { lat: 48.7490342, lng: 20.1212748 },
    { lat: 48.7501035, lng: 20.1218655 },
    { lat: 48.7505205, lng: 20.1219288 },
    { lat: 48.7505564, lng: 20.1218694 },
    { lat: 48.7506669, lng: 20.1218716 },
    { lat: 48.7507943, lng: 20.1219864 },
    { lat: 48.7512109, lng: 20.12212 },
    { lat: 48.7513731, lng: 20.1219822 },
    { lat: 48.7516566, lng: 20.1221399 },
    { lat: 48.7522245, lng: 20.1220903 },
    { lat: 48.7525507, lng: 20.1221941 },
    { lat: 48.7526202, lng: 20.1222958 },
    { lat: 48.752775, lng: 20.1221679 },
    { lat: 48.7528845, lng: 20.1222308 },
    { lat: 48.7529265, lng: 20.1223744 },
    { lat: 48.7530197, lng: 20.1223692 },
    { lat: 48.753043, lng: 20.1222525 },
    { lat: 48.7531793, lng: 20.1222787 },
    { lat: 48.7534696, lng: 20.1220225 },
    { lat: 48.753998, lng: 20.1218895 },
    { lat: 48.754232, lng: 20.1217417 },
    { lat: 48.7554321, lng: 20.1217573 },
    { lat: 48.7555497, lng: 20.1217132 },
    { lat: 48.7555069, lng: 20.1215773 },
    { lat: 48.755356, lng: 20.1213832 },
    { lat: 48.7553888, lng: 20.121332 },
    { lat: 48.7558229, lng: 20.1209271 },
    { lat: 48.7561523, lng: 20.120804 },
    { lat: 48.7564122, lng: 20.1208212 },
    { lat: 48.7567093, lng: 20.121204 },
    { lat: 48.7568207, lng: 20.1210876 },
    { lat: 48.757004, lng: 20.1210573 },
    { lat: 48.7575392, lng: 20.1212524 },
    { lat: 48.7577992, lng: 20.1211974 },
    { lat: 48.7581212, lng: 20.1210265 },
    { lat: 48.7585062, lng: 20.1210798 },
    { lat: 48.7584957, lng: 20.1210644 },
    { lat: 48.7578813, lng: 20.1195867 },
    { lat: 48.75797, lng: 20.1188602 },
    { lat: 48.7578933, lng: 20.1168159 },
    { lat: 48.7578349, lng: 20.1155759 },
    { lat: 48.7577042, lng: 20.1150501 },
    { lat: 48.7576424, lng: 20.1142798 },
    { lat: 48.759243, lng: 20.1128212 },
    { lat: 48.7594753, lng: 20.1130197 },
    { lat: 48.7598513, lng: 20.1126559 },
    { lat: 48.760147, lng: 20.112227 },
    { lat: 48.7601523, lng: 20.1117254 },
    { lat: 48.7603595, lng: 20.111529 },
    { lat: 48.7605468, lng: 20.111479 },
    { lat: 48.760763, lng: 20.1115898 },
    { lat: 48.7611623, lng: 20.111549 },
    { lat: 48.7612625, lng: 20.1116617 },
    { lat: 48.7615279, lng: 20.1115666 },
    { lat: 48.7617918, lng: 20.1111343 },
    { lat: 48.7618513, lng: 20.1111562 },
    { lat: 48.7623065, lng: 20.1107496 },
    { lat: 48.7620862, lng: 20.1103436 },
    { lat: 48.7627135, lng: 20.109792 },
    { lat: 48.7633649, lng: 20.1090586 },
    { lat: 48.7642972, lng: 20.1077537 },
    { lat: 48.7645101, lng: 20.1072551 },
    { lat: 48.7652026, lng: 20.1069494 },
    { lat: 48.7661263, lng: 20.1068977 },
    { lat: 48.7661331, lng: 20.1069824 },
    { lat: 48.7665126, lng: 20.1075307 },
    { lat: 48.7668625, lng: 20.1078508 },
    { lat: 48.7668992, lng: 20.1080637 },
    { lat: 48.7671554, lng: 20.1087653 },
    { lat: 48.7674781, lng: 20.1092767 },
    { lat: 48.7681051, lng: 20.1085502 },
    { lat: 48.7681561, lng: 20.1083076 },
    { lat: 48.7683456, lng: 20.1080132 },
    { lat: 48.7680963, lng: 20.1075609 },
    { lat: 48.768041, lng: 20.1073401 },
    { lat: 48.7678026, lng: 20.1070153 },
    { lat: 48.7678816, lng: 20.1069101 },
    { lat: 48.7677828, lng: 20.1065587 },
    { lat: 48.7678735, lng: 20.1062996 },
    { lat: 48.7678299, lng: 20.1061691 },
    { lat: 48.7673099, lng: 20.1059313 },
    { lat: 48.7669238, lng: 20.1054358 },
    { lat: 48.7670101, lng: 20.1050393 },
    { lat: 48.7669944, lng: 20.1045173 },
    { lat: 48.7669439, lng: 20.1044714 },
    { lat: 48.7668189, lng: 20.1039479 },
    { lat: 48.7667707, lng: 20.1039242 },
    { lat: 48.7666619, lng: 20.1036569 },
    { lat: 48.7667116, lng: 20.1035502 },
    { lat: 48.7668544, lng: 20.1035454 },
    { lat: 48.7668788, lng: 20.1032357 },
    { lat: 48.7669596, lng: 20.10306 },
    { lat: 48.7670513, lng: 20.1014748 },
    { lat: 48.7671973, lng: 20.1015757 },
    { lat: 48.7673247, lng: 20.1014931 },
    { lat: 48.7675258, lng: 20.1015925 },
    { lat: 48.7674934, lng: 20.1019597 },
    { lat: 48.7676491, lng: 20.1022936 },
    { lat: 48.7679287, lng: 20.102552 },
    { lat: 48.7679534, lng: 20.10271 },
    { lat: 48.7681127, lng: 20.1029986 },
    { lat: 48.7684143, lng: 20.1032978 },
    { lat: 48.7686311, lng: 20.1036128 },
    { lat: 48.7687942, lng: 20.1036886 },
    { lat: 48.7689348, lng: 20.103629 },
    { lat: 48.7690965, lng: 20.1037874 },
    { lat: 48.7698213, lng: 20.1041031 },
    { lat: 48.7698713, lng: 20.1043344 },
    { lat: 48.7702857, lng: 20.104691 },
    { lat: 48.7706505, lng: 20.1048511 },
    { lat: 48.7712562, lng: 20.104351 },
    { lat: 48.7714005, lng: 20.1043011 },
    { lat: 48.7718182, lng: 20.1043227 },
    { lat: 48.7718273, lng: 20.1040198 },
    { lat: 48.7715073, lng: 20.1035248 },
    { lat: 48.7717409, lng: 20.1033373 },
    { lat: 48.7717003, lng: 20.1030887 },
    { lat: 48.7719222, lng: 20.1027905 },
    { lat: 48.7719336, lng: 20.1025427 },
    { lat: 48.7720215, lng: 20.1024873 },
    { lat: 48.7721529, lng: 20.10259 },
    { lat: 48.7721797, lng: 20.1027207 },
    { lat: 48.7723541, lng: 20.1029622 },
    { lat: 48.7724349, lng: 20.1032367 },
    { lat: 48.7725363, lng: 20.1032337 },
    { lat: 48.772634, lng: 20.1021819 },
    { lat: 48.772765, lng: 20.1021071 },
    { lat: 48.7727039, lng: 20.1017158 },
    { lat: 48.7727446, lng: 20.1015513 },
    { lat: 48.7728557, lng: 20.1014059 },
    { lat: 48.7730599, lng: 20.1013026 },
    { lat: 48.7731365, lng: 20.1010815 },
    { lat: 48.7729883, lng: 20.1007629 },
    { lat: 48.77301, lng: 20.1003878 },
    { lat: 48.7727941, lng: 20.0994824 },
    { lat: 48.7728835, lng: 20.0992741 },
    { lat: 48.7728851, lng: 20.0988465 },
    { lat: 48.7735378, lng: 20.0984219 },
    { lat: 48.7752078, lng: 20.0913698 },
    { lat: 48.7760358, lng: 20.0911222 },
    { lat: 48.7763622, lng: 20.0911444 },
    { lat: 48.7765905, lng: 20.091359 },
    { lat: 48.7766446, lng: 20.0915504 },
    { lat: 48.7771133, lng: 20.09125 },
    { lat: 48.7772544, lng: 20.0914236 },
    { lat: 48.7777045, lng: 20.0923791 },
    { lat: 48.7780221, lng: 20.0941649 },
    { lat: 48.7783587, lng: 20.0946851 },
    { lat: 48.778395, lng: 20.0944696 },
    { lat: 48.7787046, lng: 20.0942717 },
    { lat: 48.7795222, lng: 20.0942447 },
    { lat: 48.7801259, lng: 20.0940531 },
    { lat: 48.7804262, lng: 20.0941471 },
    { lat: 48.7805183, lng: 20.0941142 },
    { lat: 48.7808623, lng: 20.0952308 },
    { lat: 48.7810253, lng: 20.095412 },
    { lat: 48.7813313, lng: 20.0960897 },
    { lat: 48.7817856, lng: 20.0974218 },
    { lat: 48.781986, lng: 20.098773 },
    { lat: 48.7820484, lng: 20.0996932 },
    { lat: 48.7822525, lng: 20.100522 },
    { lat: 48.7824937, lng: 20.1009676 },
    { lat: 48.7829149, lng: 20.1014607 },
    { lat: 48.783328, lng: 20.1022539 },
    { lat: 48.7836849, lng: 20.1033012 },
    { lat: 48.7838075, lng: 20.1041533 },
    { lat: 48.7838817, lng: 20.1043978 },
    { lat: 48.7838662, lng: 20.1048432 },
    { lat: 48.7840776, lng: 20.1059913 },
    { lat: 48.7840085, lng: 20.1082725 },
    { lat: 48.7841888, lng: 20.1087442 },
    { lat: 48.7844466, lng: 20.109128 },
    { lat: 48.784546, lng: 20.109957 },
    { lat: 48.784569, lng: 20.109933 },
    { lat: 48.784593, lng: 20.109899 },
    { lat: 48.78502, lng: 20.10916 },
    { lat: 48.785353, lng: 20.108581 },
    { lat: 48.785647, lng: 20.108079 },
    { lat: 48.786019, lng: 20.107433 },
    { lat: 48.786325, lng: 20.106908 },
    { lat: 48.786596, lng: 20.106445 },
    { lat: 48.78683, lng: 20.106038 },
    { lat: 48.78692, lng: 20.105883 },
    { lat: 48.786929, lng: 20.105846 },
    { lat: 48.786988, lng: 20.105503 },
    { lat: 48.787025, lng: 20.105309 },
    { lat: 48.787114, lng: 20.104805 },
    { lat: 48.78722, lng: 20.104214 },
    { lat: 48.787376, lng: 20.103329 },
    { lat: 48.787541, lng: 20.102393 },
    { lat: 48.787672, lng: 20.101657 },
    { lat: 48.787825, lng: 20.100797 },
    { lat: 48.787885, lng: 20.100455 },
    { lat: 48.788008, lng: 20.100034 },
    { lat: 48.788216, lng: 20.099336 },
    { lat: 48.788272, lng: 20.099149 },
    { lat: 48.788372, lng: 20.098708 },
    { lat: 48.788562, lng: 20.097879 },
    { lat: 48.788753, lng: 20.097044 },
    { lat: 48.78895, lng: 20.096168 },
    { lat: 48.789153, lng: 20.095284 },
    { lat: 48.789314, lng: 20.094569 },
    { lat: 48.789326, lng: 20.094509 },
    { lat: 48.789429, lng: 20.093994 },
    { lat: 48.789437, lng: 20.094001 },
    { lat: 48.789473, lng: 20.093917 },
    { lat: 48.78945, lng: 20.093806 },
    { lat: 48.789465, lng: 20.093789 },
    { lat: 48.789506, lng: 20.093803 },
    { lat: 48.789516, lng: 20.093765 },
    { lat: 48.789441, lng: 20.093651 },
    { lat: 48.789394, lng: 20.093557 },
    { lat: 48.78939, lng: 20.093479 },
    { lat: 48.789372, lng: 20.093411 },
    { lat: 48.789411, lng: 20.093364 },
    { lat: 48.789421, lng: 20.093335 },
    { lat: 48.789384, lng: 20.093279 },
    { lat: 48.789384, lng: 20.093261 },
    { lat: 48.789808, lng: 20.092991 },
    { lat: 48.789888, lng: 20.092933 },
    { lat: 48.789888, lng: 20.092904 },
    { lat: 48.789842, lng: 20.092777 },
    { lat: 48.78985, lng: 20.092629 },
    { lat: 48.789866, lng: 20.092622 },
    { lat: 48.789977, lng: 20.092662 },
    { lat: 48.789995, lng: 20.092653 },
    { lat: 48.790055, lng: 20.092326 },
    { lat: 48.790064, lng: 20.092334 },
    { lat: 48.790273, lng: 20.092264 },
    { lat: 48.790418, lng: 20.09212 },
    { lat: 48.79046, lng: 20.092066 },
    { lat: 48.790576, lng: 20.091901 },
    { lat: 48.790709, lng: 20.091937 },
    { lat: 48.790759, lng: 20.091946 },
    { lat: 48.791001, lng: 20.091849 },
    { lat: 48.791067, lng: 20.09171 },
    { lat: 48.791151, lng: 20.091537 },
    { lat: 48.791334, lng: 20.091268 },
    { lat: 48.791502, lng: 20.09134 },
    { lat: 48.791682, lng: 20.091102 },
    { lat: 48.792027, lng: 20.091063 },
    { lat: 48.792069, lng: 20.091064 },
    { lat: 48.792318, lng: 20.091049 },
    { lat: 48.792525, lng: 20.091038 },
    { lat: 48.792886, lng: 20.091011 },
    { lat: 48.793348, lng: 20.090975 },
    { lat: 48.793654, lng: 20.090953 },
    { lat: 48.793833, lng: 20.090938 },
    { lat: 48.793988, lng: 20.090869 },
    { lat: 48.794381, lng: 20.090682 },
    { lat: 48.79482, lng: 20.090479 },
    { lat: 48.794979, lng: 20.090405 },
    { lat: 48.795229, lng: 20.090108 },
    { lat: 48.795479, lng: 20.089807 },
    { lat: 48.795727, lng: 20.089819 },
    { lat: 48.79585, lng: 20.089823 },
    { lat: 48.796028, lng: 20.08969 },
    { lat: 48.796156, lng: 20.089603 },
    { lat: 48.796329, lng: 20.089643 },
    { lat: 48.796734, lng: 20.08974 },
    { lat: 48.796952, lng: 20.089769 },
    { lat: 48.797441, lng: 20.089832 },
    { lat: 48.797744, lng: 20.089873 },
    { lat: 48.79778, lng: 20.089876 },
    { lat: 48.797967, lng: 20.089813 },
    { lat: 48.798192, lng: 20.089726 },
    { lat: 48.798341, lng: 20.089737 },
    { lat: 48.798605, lng: 20.08976 },
    { lat: 48.798786, lng: 20.089597 },
    { lat: 48.799028, lng: 20.08968 },
    { lat: 48.799249, lng: 20.089757 },
    { lat: 48.799389, lng: 20.089746 },
    { lat: 48.799864, lng: 20.08971 },
    { lat: 48.800279, lng: 20.089682 },
    { lat: 48.800639, lng: 20.089651 },
    { lat: 48.80086, lng: 20.089636 },
    { lat: 48.801179, lng: 20.08984 },
    { lat: 48.801343, lng: 20.090052 },
    { lat: 48.80156, lng: 20.090037 },
    { lat: 48.801693, lng: 20.090422 },
    { lat: 48.802086, lng: 20.090943 },
    { lat: 48.80227, lng: 20.091187 },
    { lat: 48.802423, lng: 20.091271 },
    { lat: 48.80276, lng: 20.09145 },
    { lat: 48.802785, lng: 20.090473 },
    { lat: 48.802806, lng: 20.089679 },
    { lat: 48.803159, lng: 20.088924 },
    { lat: 48.803583, lng: 20.088012 },
    { lat: 48.803893, lng: 20.08734 },
    { lat: 48.804125, lng: 20.086844 },
    { lat: 48.804104, lng: 20.086312 },
    { lat: 48.804039, lng: 20.085036 },
    { lat: 48.803993, lng: 20.084018 },
    { lat: 48.803981, lng: 20.083903 },
    { lat: 48.80367, lng: 20.083578 },
    { lat: 48.803267, lng: 20.083163 },
    { lat: 48.802961, lng: 20.082848 },
    { lat: 48.802798, lng: 20.082109 },
    { lat: 48.802627, lng: 20.081363 },
    { lat: 48.802489, lng: 20.080737 },
    { lat: 48.802382, lng: 20.080267 },
    { lat: 48.80256, lng: 20.079984 },
    { lat: 48.802658, lng: 20.079825 },
    { lat: 48.802819, lng: 20.079567 },
    { lat: 48.802936, lng: 20.079387 },
    { lat: 48.803413, lng: 20.078644 },
    { lat: 48.803798, lng: 20.07805 },
    { lat: 48.803943, lng: 20.077818 },
    { lat: 48.803941, lng: 20.07732 },
    { lat: 48.803927, lng: 20.076029 },
    { lat: 48.803917, lng: 20.07502 },
    { lat: 48.803902, lng: 20.074468 },
    { lat: 48.803872, lng: 20.07351 },
    { lat: 48.803838, lng: 20.072477 },
    { lat: 48.803803, lng: 20.071229 },
    { lat: 48.803776, lng: 20.070387 },
    { lat: 48.80365, lng: 20.070138 },
    { lat: 48.803143, lng: 20.06916 },
    { lat: 48.802673, lng: 20.068244 },
    { lat: 48.802207, lng: 20.067343 },
    { lat: 48.801863, lng: 20.066681 },
    { lat: 48.801316, lng: 20.065855 },
    { lat: 48.800886, lng: 20.06521 },
    { lat: 48.800448, lng: 20.064553 },
    { lat: 48.800168, lng: 20.064134 },
    { lat: 48.799858, lng: 20.06366 },
    { lat: 48.799369, lng: 20.062926 },
    { lat: 48.799296, lng: 20.062822 },
    { lat: 48.799155, lng: 20.061916 },
    { lat: 48.799002, lng: 20.060934 },
    { lat: 48.79884, lng: 20.059885 },
    { lat: 48.798646, lng: 20.058646 },
    { lat: 48.79849, lng: 20.057647 },
    { lat: 48.798344, lng: 20.056723 },
    { lat: 48.79823, lng: 20.055985 },
    { lat: 48.798068, lng: 20.054945 },
    { lat: 48.797942, lng: 20.054152 },
    { lat: 48.797905, lng: 20.053911 },
    { lat: 48.797874, lng: 20.053695 },
    { lat: 48.797809, lng: 20.053285 },
    { lat: 48.797746, lng: 20.052867 },
    { lat: 48.797653, lng: 20.052274 },
    { lat: 48.797838, lng: 20.05162 },
    { lat: 48.798047, lng: 20.050885 },
    { lat: 48.798232, lng: 20.050231 },
    { lat: 48.798421, lng: 20.04958 },
    { lat: 48.798505, lng: 20.04991 },
    { lat: 48.798492, lng: 20.050426 },
    { lat: 48.798689, lng: 20.05089 },
    { lat: 48.798955, lng: 20.051403 },
    { lat: 48.799192, lng: 20.05163 },
    { lat: 48.799274, lng: 20.05177 },
    { lat: 48.79944, lng: 20.052257 },
    { lat: 48.799642, lng: 20.052554 },
    { lat: 48.799703, lng: 20.052836 },
    { lat: 48.799942, lng: 20.052715 },
    { lat: 48.800317, lng: 20.052522 },
    { lat: 48.800412, lng: 20.05245 },
    { lat: 48.800694, lng: 20.052249 },
    { lat: 48.801035, lng: 20.051641 },
    { lat: 48.801225, lng: 20.051293 },
    { lat: 48.801556, lng: 20.051692 },
    { lat: 48.801784, lng: 20.051967 },
    { lat: 48.80216, lng: 20.052418 },
    { lat: 48.802417, lng: 20.052726 },
    { lat: 48.802443, lng: 20.052777 },
    { lat: 48.802617, lng: 20.053139 },
    { lat: 48.80291, lng: 20.053744 },
    { lat: 48.803042, lng: 20.05401 },
    { lat: 48.803132, lng: 20.054208 },
    { lat: 48.803311, lng: 20.054584 },
    { lat: 48.803531, lng: 20.055063 },
    { lat: 48.803902, lng: 20.054436 },
    { lat: 48.804131, lng: 20.054039 },
    { lat: 48.804231, lng: 20.053861 },
    { lat: 48.804394, lng: 20.05358 },
    { lat: 48.804691, lng: 20.053065 },
    { lat: 48.804998, lng: 20.052533 },
    { lat: 48.805034, lng: 20.052471 },
    { lat: 48.80497, lng: 20.052089 },
    { lat: 48.804783, lng: 20.051036 },
    { lat: 48.804658, lng: 20.050319 },
    { lat: 48.804656, lng: 20.050028 },
    { lat: 48.804645, lng: 20.04959 },
    { lat: 48.804601, lng: 20.049047 },
    { lat: 48.804251, lng: 20.048311 },
    { lat: 48.804168, lng: 20.048003 },
    { lat: 48.804087, lng: 20.047562 },
    { lat: 48.804196, lng: 20.047017 },
    { lat: 48.804161, lng: 20.046735 },
    { lat: 48.804045, lng: 20.0458 },
    { lat: 48.803936, lng: 20.044915 },
    { lat: 48.803904, lng: 20.044646 },
    { lat: 48.804169, lng: 20.043932 },
    { lat: 48.804323, lng: 20.043513 },
    { lat: 48.804515, lng: 20.043443 },
    { lat: 48.804738, lng: 20.043367 },
    { lat: 48.804879, lng: 20.042973 },
    { lat: 48.8049, lng: 20.042612 },
    { lat: 48.805115, lng: 20.04214 },
    { lat: 48.80517, lng: 20.04174 },
    { lat: 48.805237, lng: 20.041214 },
    { lat: 48.805427, lng: 20.040693 },
    { lat: 48.805675, lng: 20.040009 },
    { lat: 48.805737, lng: 20.03983 },
    { lat: 48.805872, lng: 20.039365 },
    { lat: 48.806072, lng: 20.038679 },
    { lat: 48.806168, lng: 20.038351 },
    { lat: 48.80627, lng: 20.037954 },
    { lat: 48.806397, lng: 20.037509 },
    { lat: 48.806454, lng: 20.036942 },
    { lat: 48.806516, lng: 20.036289 },
    { lat: 48.806561, lng: 20.035771 },
    { lat: 48.806606, lng: 20.035225 },
    { lat: 48.806681, lng: 20.034317 },
    { lat: 48.806725, lng: 20.033791 },
    { lat: 48.806726, lng: 20.033742 },
    { lat: 48.806572, lng: 20.033524 },
    { lat: 48.806388, lng: 20.033337 },
    { lat: 48.806213, lng: 20.032195 },
    { lat: 48.806127, lng: 20.032011 },
    { lat: 48.805962, lng: 20.03185 },
    { lat: 48.80597, lng: 20.031808 },
    { lat: 48.805948, lng: 20.03181 },
    { lat: 48.805858, lng: 20.03145 },
    { lat: 48.805724, lng: 20.030727 },
    { lat: 48.805482, lng: 20.030176 },
    { lat: 48.805237, lng: 20.029858 },
    { lat: 48.805115, lng: 20.02957 },
    { lat: 48.804967, lng: 20.029216 },
    { lat: 48.804907, lng: 20.028799 },
    { lat: 48.804877, lng: 20.028598 },
    { lat: 48.804844, lng: 20.02852 },
    { lat: 48.804637, lng: 20.028348 },
    { lat: 48.804536, lng: 20.028235 },
    { lat: 48.804423, lng: 20.02811 },
    { lat: 48.804072, lng: 20.027843 },
    { lat: 48.803987, lng: 20.027251 },
    { lat: 48.803792, lng: 20.026792 },
    { lat: 48.8037, lng: 20.026528 },
    { lat: 48.803409, lng: 20.025737 },
    { lat: 48.803424, lng: 20.025736 },
    { lat: 48.803524, lng: 20.025658 },
    { lat: 48.801721, lng: 20.020714 },
    { lat: 48.80161, lng: 20.018855 },
    { lat: 48.801688, lng: 20.018561 },
    { lat: 48.801958, lng: 20.017868 },
    { lat: 48.801965, lng: 20.017525 },
    { lat: 48.802207, lng: 20.016984 },
    { lat: 48.802313, lng: 20.016674 },
    { lat: 48.802275, lng: 20.016377 },
    { lat: 48.801995, lng: 20.016022 },
    { lat: 48.801752, lng: 20.015595 },
    { lat: 48.801113, lng: 20.015233 },
    { lat: 48.800404, lng: 20.014946 },
    { lat: 48.799077, lng: 20.013954 },
    { lat: 48.797646, lng: 20.013831 },
    { lat: 48.797123, lng: 20.013786 },
    { lat: 48.795824, lng: 20.013678 },
    { lat: 48.795678, lng: 20.013324 },
    { lat: 48.795405, lng: 20.012653 },
    { lat: 48.794684, lng: 20.010881 },
    { lat: 48.793908, lng: 20.008568 },
    { lat: 48.793805, lng: 20.008142 },
    { lat: 48.793774, lng: 20.007763 },
    { lat: 48.793567, lng: 20.007523 },
    { lat: 48.79344, lng: 20.007181 },
    { lat: 48.793258, lng: 20.006803 },
    { lat: 48.793183, lng: 20.006414 },
    { lat: 48.793056, lng: 20.006058 },
    { lat: 48.793217, lng: 20.005647 },
    { lat: 48.793199, lng: 20.005308 },
    { lat: 48.792963, lng: 20.004921 },
    { lat: 48.792797, lng: 20.00476 },
    { lat: 48.792442, lng: 20.004412 },
    { lat: 48.792313, lng: 20.003579 },
    { lat: 48.792186, lng: 20.003359 },
    { lat: 48.791988, lng: 20.002997 },
    { lat: 48.791916, lng: 20.002662 },
    { lat: 48.791767, lng: 20.002159 },
    { lat: 48.791295, lng: 20.001467 },
    { lat: 48.791299, lng: 20.000853 },
    { lat: 48.791153, lng: 20.000321 },
    { lat: 48.79081, lng: 19.999413 },
    { lat: 48.790647, lng: 19.999074 },
    { lat: 48.790407, lng: 19.998647 },
    { lat: 48.790055, lng: 19.99789 },
    { lat: 48.790102, lng: 19.997408 },
    { lat: 48.790063, lng: 19.99703 },
    { lat: 48.790069, lng: 19.996784 },
    { lat: 48.790076, lng: 19.996469 },
    { lat: 48.789995, lng: 19.996122 },
    { lat: 48.789906, lng: 19.995736 },
    { lat: 48.789828, lng: 19.994952 },
    { lat: 48.789721, lng: 19.994076 },
    { lat: 48.789633, lng: 19.993702 },
    { lat: 48.789639, lng: 19.992973 },
    { lat: 48.789051, lng: 19.991998 },
    { lat: 48.788941, lng: 19.992104 },
    { lat: 48.788192, lng: 19.992181 },
    { lat: 48.787493, lng: 19.991826 },
    { lat: 48.787403, lng: 19.991401 },
    { lat: 48.787322, lng: 19.991114 },
    { lat: 48.787169, lng: 19.990432 },
    { lat: 48.786937, lng: 19.99002 },
    { lat: 48.786512, lng: 19.98972 },
    { lat: 48.786368, lng: 19.988898 },
    { lat: 48.785885, lng: 19.988354 },
    { lat: 48.785822, lng: 19.987771 },
    { lat: 48.785838, lng: 19.987406 },
    { lat: 48.785625, lng: 19.986747 },
    { lat: 48.785628, lng: 19.986184 },
    { lat: 48.785809, lng: 19.985641 },
    { lat: 48.785873, lng: 19.985386 },
    { lat: 48.785834, lng: 19.984777 },
    { lat: 48.785808, lng: 19.984506 },
    { lat: 48.786057, lng: 19.984046 },
    { lat: 48.785726, lng: 19.982513 },
    { lat: 48.785527, lng: 19.982118 },
    { lat: 48.785459, lng: 19.981232 },
    { lat: 48.785518, lng: 19.98103 },
    { lat: 48.78552, lng: 19.977723 },
    { lat: 48.785364, lng: 19.977053 },
  ],
  Jelšava: [
    { lat: 48.6739677, lng: 20.2672714 },
    { lat: 48.6724305, lng: 20.2672185 },
    { lat: 48.6722227, lng: 20.2671439 },
    { lat: 48.6716637, lng: 20.2665671 },
    { lat: 48.6713734, lng: 20.266171 },
    { lat: 48.670978, lng: 20.2654288 },
    { lat: 48.6705537, lng: 20.2650399 },
    { lat: 48.6702578, lng: 20.2645335 },
    { lat: 48.668854, lng: 20.2632975 },
    { lat: 48.667918, lng: 20.2626636 },
    { lat: 48.6671289, lng: 20.2614251 },
    { lat: 48.6668494, lng: 20.2611599 },
    { lat: 48.6664976, lng: 20.2604396 },
    { lat: 48.6662779, lng: 20.2600956 },
    { lat: 48.6660783, lng: 20.2595482 },
    { lat: 48.6659549, lng: 20.258649 },
    { lat: 48.6657788, lng: 20.2578127 },
    { lat: 48.6659323, lng: 20.2569392 },
    { lat: 48.6659159, lng: 20.2566699 },
    { lat: 48.6658217, lng: 20.2564536 },
    { lat: 48.6657451, lng: 20.255801 },
    { lat: 48.6655382, lng: 20.2552953 },
    { lat: 48.6651195, lng: 20.2545913 },
    { lat: 48.6645628, lng: 20.2534435 },
    { lat: 48.6643136, lng: 20.2532964 },
    { lat: 48.664128, lng: 20.2532747 },
    { lat: 48.6636782, lng: 20.2528358 },
    { lat: 48.6635567, lng: 20.2527816 },
    { lat: 48.6634063, lng: 20.2523997 },
    { lat: 48.6631499, lng: 20.2522359 },
    { lat: 48.662949, lng: 20.2522818 },
    { lat: 48.6625394, lng: 20.2525757 },
    { lat: 48.661997, lng: 20.2523888 },
    { lat: 48.6619361, lng: 20.2521275 },
    { lat: 48.6619778, lng: 20.2517921 },
    { lat: 48.6617589, lng: 20.2513551 },
    { lat: 48.6616449, lng: 20.2508076 },
    { lat: 48.6614273, lng: 20.2500934 },
    { lat: 48.6614252, lng: 20.2495271 },
    { lat: 48.6612295, lng: 20.2489673 },
    { lat: 48.6610708, lng: 20.2483173 },
    { lat: 48.6609046, lng: 20.2481022 },
    { lat: 48.6608484, lng: 20.2479053 },
    { lat: 48.6606667, lng: 20.2476139 },
    { lat: 48.6603528, lng: 20.2468814 },
    { lat: 48.6597515, lng: 20.246265 },
    { lat: 48.6597031, lng: 20.2460912 },
    { lat: 48.659695, lng: 20.245859 },
    { lat: 48.6597581, lng: 20.2456951 },
    { lat: 48.6600935, lng: 20.2451197 },
    { lat: 48.6601398, lng: 20.2445844 },
    { lat: 48.6601053, lng: 20.2439434 },
    { lat: 48.6600444, lng: 20.2437416 },
    { lat: 48.6600667, lng: 20.2433684 },
    { lat: 48.6597982, lng: 20.2426168 },
    { lat: 48.6595546, lng: 20.2422726 },
    { lat: 48.6594226, lng: 20.2415878 },
    { lat: 48.6592553, lng: 20.2410063 },
    { lat: 48.6589797, lng: 20.2406151 },
    { lat: 48.6588625, lng: 20.2400487 },
    { lat: 48.6586222, lng: 20.2392458 },
    { lat: 48.6580138, lng: 20.2379633 },
    { lat: 48.6577987, lng: 20.2372846 },
    { lat: 48.6576455, lng: 20.2371955 },
    { lat: 48.6573784, lng: 20.2365984 },
    { lat: 48.6570843, lng: 20.2361403 },
    { lat: 48.6567857, lng: 20.2355451 },
    { lat: 48.6566233, lng: 20.2350878 },
    { lat: 48.6561355, lng: 20.2331034 },
    { lat: 48.6561164, lng: 20.2321754 },
    { lat: 48.6564269, lng: 20.2300694 },
    { lat: 48.6566902, lng: 20.2286527 },
    { lat: 48.6567748, lng: 20.2276985 },
    { lat: 48.6567559, lng: 20.225616 },
    { lat: 48.6568514, lng: 20.2240866 },
    { lat: 48.6568224, lng: 20.2233319 },
    { lat: 48.6569974, lng: 20.2195888 },
    { lat: 48.6580308, lng: 20.2149873 },
    { lat: 48.6583242, lng: 20.2112244 },
    { lat: 48.6583356, lng: 20.2110082 },
    { lat: 48.6583634, lng: 20.2102932 },
    { lat: 48.6580015, lng: 20.2098905 },
    { lat: 48.65794, lng: 20.2096745 },
    { lat: 48.657963, lng: 20.2089589 },
    { lat: 48.6575311, lng: 20.2086342 },
    { lat: 48.6574506, lng: 20.2079729 },
    { lat: 48.6558898, lng: 20.2056428 },
    { lat: 48.6557417, lng: 20.2052933 },
    { lat: 48.6553596, lng: 20.2048922 },
    { lat: 48.6551297, lng: 20.2047497 },
    { lat: 48.6550345, lng: 20.2045934 },
    { lat: 48.6547849, lng: 20.204432 },
    { lat: 48.6544564, lng: 20.2041913 },
    { lat: 48.6543276, lng: 20.2038985 },
    { lat: 48.6538888, lng: 20.2035439 },
    { lat: 48.6534711, lng: 20.2033259 },
    { lat: 48.6533771, lng: 20.203342 },
    { lat: 48.6530479, lng: 20.2030775 },
    { lat: 48.6529208, lng: 20.2030264 },
    { lat: 48.652487, lng: 20.2031622 },
    { lat: 48.6517506, lng: 20.2028832 },
    { lat: 48.6514262, lng: 20.2026874 },
    { lat: 48.6514039, lng: 20.2023532 },
    { lat: 48.6512994, lng: 20.2019958 },
    { lat: 48.6514388, lng: 20.201317 },
    { lat: 48.6513722, lng: 20.2010047 },
    { lat: 48.6490695, lng: 20.1984052 },
    { lat: 48.6494638, lng: 20.1975097 },
    { lat: 48.648988, lng: 20.1942721 },
    { lat: 48.6490062, lng: 20.1936614 },
    { lat: 48.6492519, lng: 20.191313 },
    { lat: 48.6492385, lng: 20.1909798 },
    { lat: 48.6489236, lng: 20.1901727 },
    { lat: 48.6489966, lng: 20.1895822 },
    { lat: 48.6490251, lng: 20.1884434 },
    { lat: 48.6490857, lng: 20.1877971 },
    { lat: 48.6491782, lng: 20.1875436 },
    { lat: 48.6492678, lng: 20.1869437 },
    { lat: 48.6494447, lng: 20.1863605 },
    { lat: 48.6494393, lng: 20.18578 },
    { lat: 48.6494808, lng: 20.1856915 },
    { lat: 48.6494796, lng: 20.1855199 },
    { lat: 48.6494041, lng: 20.1853804 },
    { lat: 48.6491038, lng: 20.1842105 },
    { lat: 48.6488493, lng: 20.183489 },
    { lat: 48.6486427, lng: 20.1830589 },
    { lat: 48.6486034, lng: 20.1826682 },
    { lat: 48.6476969, lng: 20.1822822 },
    { lat: 48.6472153, lng: 20.181675 },
    { lat: 48.6471562, lng: 20.1814977 },
    { lat: 48.6469131, lng: 20.1814737 },
    { lat: 48.6466323, lng: 20.1815454 },
    { lat: 48.6463848, lng: 20.1816928 },
    { lat: 48.645912, lng: 20.181614 },
    { lat: 48.6457024, lng: 20.1814729 },
    { lat: 48.6456584, lng: 20.1811087 },
    { lat: 48.6455044, lng: 20.1806135 },
    { lat: 48.6453911, lng: 20.1804836 },
    { lat: 48.6453711, lng: 20.1802098 },
    { lat: 48.6451762, lng: 20.1795079 },
    { lat: 48.645181, lng: 20.1792638 },
    { lat: 48.6451171, lng: 20.1789666 },
    { lat: 48.6450263, lng: 20.1787812 },
    { lat: 48.6449098, lng: 20.1786825 },
    { lat: 48.6446209, lng: 20.1778055 },
    { lat: 48.6442752, lng: 20.177355 },
    { lat: 48.643538, lng: 20.176199 },
    { lat: 48.6431604, lng: 20.1758832 },
    { lat: 48.6428698, lng: 20.1754662 },
    { lat: 48.6425133, lng: 20.1751118 },
    { lat: 48.6424038, lng: 20.1746924 },
    { lat: 48.6417513, lng: 20.174039 },
    { lat: 48.6408702, lng: 20.1726912 },
    { lat: 48.6405215, lng: 20.1725608 },
    { lat: 48.6403726, lng: 20.1723612 },
    { lat: 48.6402241, lng: 20.1726704 },
    { lat: 48.6395028, lng: 20.1728795 },
    { lat: 48.6386877, lng: 20.1736182 },
    { lat: 48.6382069, lng: 20.1746805 },
    { lat: 48.637996, lng: 20.1749516 },
    { lat: 48.6376787, lng: 20.1757991 },
    { lat: 48.6374514, lng: 20.176248 },
    { lat: 48.6372045, lng: 20.1765506 },
    { lat: 48.6366694, lng: 20.1769957 },
    { lat: 48.6359511, lng: 20.1778935 },
    { lat: 48.6353955, lng: 20.1782778 },
    { lat: 48.6349017, lng: 20.1792122 },
    { lat: 48.6344, lng: 20.1798415 },
    { lat: 48.6342315, lng: 20.1801639 },
    { lat: 48.6339067, lng: 20.180459 },
    { lat: 48.6323043, lng: 20.182559 },
    { lat: 48.6319111, lng: 20.1840933 },
    { lat: 48.6315409, lng: 20.184917 },
    { lat: 48.6313756, lng: 20.1855072 },
    { lat: 48.6310771, lng: 20.1860116 },
    { lat: 48.6305634, lng: 20.1859786 },
    { lat: 48.6298989, lng: 20.1866793 },
    { lat: 48.629447, lng: 20.1868393 },
    { lat: 48.6289275, lng: 20.1868612 },
    { lat: 48.6284722, lng: 20.1871055 },
    { lat: 48.6277942, lng: 20.1867133 },
    { lat: 48.6271817, lng: 20.1866373 },
    { lat: 48.6267136, lng: 20.1863651 },
    { lat: 48.6262437, lng: 20.1866108 },
    { lat: 48.6259109, lng: 20.1866808 },
    { lat: 48.6252928, lng: 20.1869371 },
    { lat: 48.6250123, lng: 20.1872754 },
    { lat: 48.6249396, lng: 20.1879029 },
    { lat: 48.6249937, lng: 20.1882678 },
    { lat: 48.6250118, lng: 20.1889935 },
    { lat: 48.6246568, lng: 20.1901934 },
    { lat: 48.6246139, lng: 20.1908046 },
    { lat: 48.624512, lng: 20.1913238 },
    { lat: 48.6243946, lng: 20.1915969 },
    { lat: 48.6241131, lng: 20.1919897 },
    { lat: 48.6238151, lng: 20.1926503 },
    { lat: 48.6239595, lng: 20.1931016 },
    { lat: 48.6242748, lng: 20.1934943 },
    { lat: 48.624454, lng: 20.1940311 },
    { lat: 48.6245218, lng: 20.1948726 },
    { lat: 48.6247116, lng: 20.1956708 },
    { lat: 48.6247161, lng: 20.1972162 },
    { lat: 48.6245969, lng: 20.1980042 },
    { lat: 48.6244655, lng: 20.1982391 },
    { lat: 48.6244169, lng: 20.1988213 },
    { lat: 48.6242765, lng: 20.1991984 },
    { lat: 48.6238953, lng: 20.1997141 },
    { lat: 48.6233177, lng: 20.2000293 },
    { lat: 48.6231439, lng: 20.2003281 },
    { lat: 48.6225156, lng: 20.2005308 },
    { lat: 48.6224168, lng: 20.2005099 },
    { lat: 48.622222, lng: 20.2002845 },
    { lat: 48.6220302, lng: 20.2002216 },
    { lat: 48.6215362, lng: 20.200738 },
    { lat: 48.6214786, lng: 20.2008725 },
    { lat: 48.621546, lng: 20.2012049 },
    { lat: 48.6214101, lng: 20.202559 },
    { lat: 48.6212529, lng: 20.2030219 },
    { lat: 48.6209026, lng: 20.2036394 },
    { lat: 48.6205839, lng: 20.2050436 },
    { lat: 48.6204466, lng: 20.2053552 },
    { lat: 48.6203672, lng: 20.2057643 },
    { lat: 48.6200356, lng: 20.2061185 },
    { lat: 48.6196944, lng: 20.207215 },
    { lat: 48.619265, lng: 20.2080191 },
    { lat: 48.6190974, lng: 20.2084473 },
    { lat: 48.6189265, lng: 20.2086751 },
    { lat: 48.6187888, lng: 20.2089784 },
    { lat: 48.618924, lng: 20.2092897 },
    { lat: 48.6189265, lng: 20.2095465 },
    { lat: 48.6188376, lng: 20.2101686 },
    { lat: 48.6187125, lng: 20.2105335 },
    { lat: 48.6159175, lng: 20.2108194 },
    { lat: 48.6150112, lng: 20.2107184 },
    { lat: 48.6144179, lng: 20.2107261 },
    { lat: 48.6140788, lng: 20.2106389 },
    { lat: 48.6139757, lng: 20.2105913 },
    { lat: 48.6137111, lng: 20.2102848 },
    { lat: 48.6134209, lng: 20.2102108 },
    { lat: 48.6133732, lng: 20.2101145 },
    { lat: 48.6133536, lng: 20.2097386 },
    { lat: 48.6132205, lng: 20.2090887 },
    { lat: 48.613154, lng: 20.2089197 },
    { lat: 48.6124725, lng: 20.2082386 },
    { lat: 48.6120039, lng: 20.2080706 },
    { lat: 48.6115554, lng: 20.2082866 },
    { lat: 48.6112599, lng: 20.2085339 },
    { lat: 48.6110452, lng: 20.2086001 },
    { lat: 48.6108119, lng: 20.2084263 },
    { lat: 48.6105631, lng: 20.208118 },
    { lat: 48.6103371, lng: 20.2076489 },
    { lat: 48.6101942, lng: 20.2074572 },
    { lat: 48.6100198, lng: 20.2074863 },
    { lat: 48.6098036, lng: 20.2073743 },
    { lat: 48.6095265, lng: 20.2075971 },
    { lat: 48.6091196, lng: 20.2085755 },
    { lat: 48.6087224, lng: 20.2086986 },
    { lat: 48.6083859, lng: 20.2089574 },
    { lat: 48.6081278, lng: 20.2090069 },
    { lat: 48.6078513, lng: 20.2091926 },
    { lat: 48.6076338, lng: 20.2094651 },
    { lat: 48.6074115, lng: 20.2095707 },
    { lat: 48.6062116, lng: 20.2094466 },
    { lat: 48.6059273, lng: 20.2095416 },
    { lat: 48.6056297, lng: 20.2092833 },
    { lat: 48.605266, lng: 20.2097079 },
    { lat: 48.6049088, lng: 20.2099578 },
    { lat: 48.6041598, lng: 20.2100955 },
    { lat: 48.6038143, lng: 20.2101597 },
    { lat: 48.6037224, lng: 20.2104218 },
    { lat: 48.6039878, lng: 20.2111449 },
    { lat: 48.604144, lng: 20.2117271 },
    { lat: 48.604128, lng: 20.212758 },
    { lat: 48.6040688, lng: 20.2132253 },
    { lat: 48.6039234, lng: 20.2135008 },
    { lat: 48.6024249, lng: 20.2149471 },
    { lat: 48.6017596, lng: 20.2161908 },
    { lat: 48.6015631, lng: 20.217025 },
    { lat: 48.6011547, lng: 20.2178257 },
    { lat: 48.6008618, lng: 20.2180495 },
    { lat: 48.6007084, lng: 20.2180727 },
    { lat: 48.6005242, lng: 20.2176628 },
    { lat: 48.600201, lng: 20.217293 },
    { lat: 48.5994914, lng: 20.2179958 },
    { lat: 48.5992255, lng: 20.2187534 },
    { lat: 48.5988716, lng: 20.2192271 },
    { lat: 48.5984602, lng: 20.2195282 },
    { lat: 48.5982175, lng: 20.2198491 },
    { lat: 48.5979633, lng: 20.2200024 },
    { lat: 48.5975085, lng: 20.2204871 },
    { lat: 48.5973562, lng: 20.2208158 },
    { lat: 48.5972301, lng: 20.2217456 },
    { lat: 48.5970902, lng: 20.2222483 },
    { lat: 48.5965594, lng: 20.2235888 },
    { lat: 48.596251, lng: 20.224133 },
    { lat: 48.5957552, lng: 20.2244887 },
    { lat: 48.5954216, lng: 20.224879 },
    { lat: 48.595229, lng: 20.2253583 },
    { lat: 48.5951032, lng: 20.2258479 },
    { lat: 48.5950527, lng: 20.2265804 },
    { lat: 48.5948671, lng: 20.2272644 },
    { lat: 48.5947333, lng: 20.2276134 },
    { lat: 48.5943339, lng: 20.2281848 },
    { lat: 48.5942013, lng: 20.2284754 },
    { lat: 48.5941095, lng: 20.229445 },
    { lat: 48.5952626, lng: 20.2308814 },
    { lat: 48.5952744, lng: 20.230892 },
    { lat: 48.5952838, lng: 20.230904 },
    { lat: 48.5953271, lng: 20.2311726 },
    { lat: 48.5955708, lng: 20.2313768 },
    { lat: 48.5958287, lng: 20.2320269 },
    { lat: 48.5960329, lng: 20.2323482 },
    { lat: 48.5963607, lng: 20.2326024 },
    { lat: 48.5965492, lng: 20.2328671 },
    { lat: 48.5968222, lng: 20.2340368 },
    { lat: 48.5975605, lng: 20.2352408 },
    { lat: 48.5977193, lng: 20.2353527 },
    { lat: 48.599626, lng: 20.2374156 },
    { lat: 48.5999127, lng: 20.2374031 },
    { lat: 48.600018, lng: 20.239035 },
    { lat: 48.6004312, lng: 20.2395964 },
    { lat: 48.6005345, lng: 20.2396415 },
    { lat: 48.6004648, lng: 20.239788 },
    { lat: 48.6005063, lng: 20.2398942 },
    { lat: 48.6005503, lng: 20.2398959 },
    { lat: 48.6005665, lng: 20.239966 },
    { lat: 48.6005188, lng: 20.2400109 },
    { lat: 48.6005875, lng: 20.240042 },
    { lat: 48.6005411, lng: 20.2402035 },
    { lat: 48.6005685, lng: 20.2403241 },
    { lat: 48.6004936, lng: 20.2404807 },
    { lat: 48.6004059, lng: 20.2403709 },
    { lat: 48.6003563, lng: 20.2403988 },
    { lat: 48.6003291, lng: 20.2405934 },
    { lat: 48.6002201, lng: 20.2406005 },
    { lat: 48.6002048, lng: 20.2404421 },
    { lat: 48.6001686, lng: 20.24044 },
    { lat: 48.6000461, lng: 20.2408663 },
    { lat: 48.6000832, lng: 20.2410082 },
    { lat: 48.6000383, lng: 20.2410681 },
    { lat: 48.5999637, lng: 20.24103 },
    { lat: 48.5999315, lng: 20.2412115 },
    { lat: 48.5998343, lng: 20.2412363 },
    { lat: 48.5998309, lng: 20.2412875 },
    { lat: 48.5998914, lng: 20.2412842 },
    { lat: 48.5998806, lng: 20.2414339 },
    { lat: 48.5995761, lng: 20.2415073 },
    { lat: 48.5996824, lng: 20.2416066 },
    { lat: 48.5996411, lng: 20.2417541 },
    { lat: 48.5995442, lng: 20.2418991 },
    { lat: 48.5995026, lng: 20.241641 },
    { lat: 48.5993586, lng: 20.2419323 },
    { lat: 48.5993968, lng: 20.2420142 },
    { lat: 48.5993438, lng: 20.2421229 },
    { lat: 48.5992752, lng: 20.2421256 },
    { lat: 48.5992365, lng: 20.242001 },
    { lat: 48.5991634, lng: 20.2422627 },
    { lat: 48.5992308, lng: 20.2424274 },
    { lat: 48.5991218, lng: 20.2425155 },
    { lat: 48.5991112, lng: 20.2426464 },
    { lat: 48.5990406, lng: 20.2426584 },
    { lat: 48.5990262, lng: 20.2428298 },
    { lat: 48.5988709, lng: 20.2428652 },
    { lat: 48.5988956, lng: 20.2429986 },
    { lat: 48.5988455, lng: 20.2430757 },
    { lat: 48.5987962, lng: 20.242968 },
    { lat: 48.5987496, lng: 20.2430135 },
    { lat: 48.5987035, lng: 20.243101 },
    { lat: 48.5987101, lng: 20.2432964 },
    { lat: 48.5986406, lng: 20.2433596 },
    { lat: 48.5985576, lng: 20.2433657 },
    { lat: 48.5985126, lng: 20.2432862 },
    { lat: 48.5984524, lng: 20.2435275 },
    { lat: 48.598539, lng: 20.2436638 },
    { lat: 48.5984834, lng: 20.2437965 },
    { lat: 48.5985106, lng: 20.2439417 },
    { lat: 48.5984374, lng: 20.2440298 },
    { lat: 48.5983582, lng: 20.2440196 },
    { lat: 48.5983354, lng: 20.2441223 },
    { lat: 48.5982646, lng: 20.2441572 },
    { lat: 48.5982611, lng: 20.244461 },
    { lat: 48.5981809, lng: 20.2444569 },
    { lat: 48.5981517, lng: 20.2448267 },
    { lat: 48.5980785, lng: 20.2449134 },
    { lat: 48.5980766, lng: 20.244979 },
    { lat: 48.5981414, lng: 20.2450244 },
    { lat: 48.5979974, lng: 20.2453075 },
    { lat: 48.5979816, lng: 20.2454509 },
    { lat: 48.5978868, lng: 20.2455576 },
    { lat: 48.597726, lng: 20.2455914 },
    { lat: 48.59766, lng: 20.2458322 },
    { lat: 48.5981386, lng: 20.2458562 },
    { lat: 48.5981566, lng: 20.2459157 },
    { lat: 48.5983395, lng: 20.2459828 },
    { lat: 48.5989626, lng: 20.2459062 },
    { lat: 48.5995758, lng: 20.2462276 },
    { lat: 48.6007848, lng: 20.2466143 },
    { lat: 48.6005756, lng: 20.2475552 },
    { lat: 48.600218, lng: 20.2486998 },
    { lat: 48.600657, lng: 20.2493767 },
    { lat: 48.6018228, lng: 20.2519024 },
    { lat: 48.6024932, lng: 20.253161 },
    { lat: 48.6025648, lng: 20.2534904 },
    { lat: 48.6019602, lng: 20.2546555 },
    { lat: 48.6021248, lng: 20.2549431 },
    { lat: 48.6018127, lng: 20.2553936 },
    { lat: 48.6029927, lng: 20.2568618 },
    { lat: 48.6031188, lng: 20.2570913 },
    { lat: 48.6029631, lng: 20.2573658 },
    { lat: 48.603063, lng: 20.257581 },
    { lat: 48.6030561, lng: 20.2577243 },
    { lat: 48.6036048, lng: 20.2586096 },
    { lat: 48.6038623, lng: 20.2589376 },
    { lat: 48.6040122, lng: 20.2592839 },
    { lat: 48.6045235, lng: 20.2598765 },
    { lat: 48.6049198, lng: 20.2604746 },
    { lat: 48.605457, lng: 20.2610421 },
    { lat: 48.6054158, lng: 20.2613971 },
    { lat: 48.6090576, lng: 20.265679 },
    { lat: 48.60931, lng: 20.2656805 },
    { lat: 48.60977, lng: 20.2659522 },
    { lat: 48.6100367, lng: 20.2660051 },
    { lat: 48.6104329, lng: 20.2662549 },
    { lat: 48.6106791, lng: 20.2668114 },
    { lat: 48.6111188, lng: 20.2673676 },
    { lat: 48.6114957, lng: 20.2673547 },
    { lat: 48.6121485, lng: 20.2670888 },
    { lat: 48.6125064, lng: 20.2671395 },
    { lat: 48.6132758, lng: 20.2670635 },
    { lat: 48.6137826, lng: 20.2667499 },
    { lat: 48.6141493, lng: 20.2667087 },
    { lat: 48.6144983, lng: 20.2669051 },
    { lat: 48.6150704, lng: 20.2673053 },
    { lat: 48.6154847, lng: 20.2677855 },
    { lat: 48.6156838, lng: 20.2682598 },
    { lat: 48.6156387, lng: 20.2687184 },
    { lat: 48.615493, lng: 20.2691045 },
    { lat: 48.6152962, lng: 20.269912 },
    { lat: 48.6156288, lng: 20.2697447 },
    { lat: 48.6161147, lng: 20.2700138 },
    { lat: 48.6165679, lng: 20.269946 },
    { lat: 48.6168937, lng: 20.2697822 },
    { lat: 48.6171633, lng: 20.2697654 },
    { lat: 48.6178006, lng: 20.2691593 },
    { lat: 48.6182909, lng: 20.2698861 },
    { lat: 48.6187884, lng: 20.2699649 },
    { lat: 48.6193109, lng: 20.2699357 },
    { lat: 48.6194362, lng: 20.2700529 },
    { lat: 48.6195304, lng: 20.2703587 },
    { lat: 48.6193797, lng: 20.2709884 },
    { lat: 48.6194162, lng: 20.2715714 },
    { lat: 48.6193332, lng: 20.2719505 },
    { lat: 48.6193859, lng: 20.2721714 },
    { lat: 48.6192425, lng: 20.2728193 },
    { lat: 48.6191972, lng: 20.2734099 },
    { lat: 48.6193245, lng: 20.2737245 },
    { lat: 48.6194789, lng: 20.2738976 },
    { lat: 48.6199246, lng: 20.2746904 },
    { lat: 48.6199286, lng: 20.274847 },
    { lat: 48.6198173, lng: 20.2753077 },
    { lat: 48.6198276, lng: 20.2757122 },
    { lat: 48.6197601, lng: 20.2763386 },
    { lat: 48.6199392, lng: 20.2769967 },
    { lat: 48.6199626, lng: 20.2774509 },
    { lat: 48.6201141, lng: 20.2779777 },
    { lat: 48.620094, lng: 20.2786942 },
    { lat: 48.6199538, lng: 20.2790225 },
    { lat: 48.6199297, lng: 20.2792144 },
    { lat: 48.6199881, lng: 20.2798378 },
    { lat: 48.6201556, lng: 20.2803698 },
    { lat: 48.6205172, lng: 20.2812557 },
    { lat: 48.620915, lng: 20.2826905 },
    { lat: 48.6214949, lng: 20.2833194 },
    { lat: 48.6217166, lng: 20.2833415 },
    { lat: 48.6219891, lng: 20.283484 },
    { lat: 48.6223352, lng: 20.2843554 },
    { lat: 48.622822, lng: 20.2851621 },
    { lat: 48.623202, lng: 20.2851686 },
    { lat: 48.6238973, lng: 20.2856979 },
    { lat: 48.6239616, lng: 20.286187 },
    { lat: 48.6242319, lng: 20.2871581 },
    { lat: 48.6244783, lng: 20.2876934 },
    { lat: 48.6246804, lng: 20.287964 },
    { lat: 48.6248907, lng: 20.2886187 },
    { lat: 48.6250861, lng: 20.2897172 },
    { lat: 48.6250831, lng: 20.2903298 },
    { lat: 48.6252667, lng: 20.2911018 },
    { lat: 48.6253285, lng: 20.2924166 },
    { lat: 48.6254776, lng: 20.2928294 },
    { lat: 48.625917, lng: 20.2931158 },
    { lat: 48.6264344, lng: 20.2937608 },
    { lat: 48.6267551, lng: 20.294469 },
    { lat: 48.626875, lng: 20.2948538 },
    { lat: 48.6279574, lng: 20.2956925 },
    { lat: 48.6281991, lng: 20.2959628 },
    { lat: 48.6282723, lng: 20.2961375 },
    { lat: 48.6283583, lng: 20.2968083 },
    { lat: 48.628653, lng: 20.2974018 },
    { lat: 48.6291154, lng: 20.2977649 },
    { lat: 48.6294129, lng: 20.2978828 },
    { lat: 48.6298226, lng: 20.2979302 },
    { lat: 48.629959, lng: 20.298035 },
    { lat: 48.630085, lng: 20.298171 },
    { lat: 48.63024, lng: 20.298258 },
    { lat: 48.630446, lng: 20.298207 },
    { lat: 48.630539, lng: 20.298142 },
    { lat: 48.630657, lng: 20.298025 },
    { lat: 48.630777, lng: 20.29791 },
    { lat: 48.630951, lng: 20.297816 },
    { lat: 48.631113, lng: 20.297775 },
    { lat: 48.631293, lng: 20.297706 },
    { lat: 48.631513, lng: 20.297621 },
    { lat: 48.631607, lng: 20.297569 },
    { lat: 48.631719, lng: 20.297486 },
    { lat: 48.63183, lng: 20.297382 },
    { lat: 48.632098, lng: 20.297213 },
    { lat: 48.632294, lng: 20.297172 },
    { lat: 48.632452, lng: 20.297135 },
    { lat: 48.632557, lng: 20.297086 },
    { lat: 48.632656, lng: 20.297109 },
    { lat: 48.632852, lng: 20.297094 },
    { lat: 48.633021, lng: 20.297128 },
    { lat: 48.633369, lng: 20.297186 },
    { lat: 48.633664, lng: 20.297301 },
    { lat: 48.633775, lng: 20.297319 },
    { lat: 48.633804, lng: 20.297318 },
    { lat: 48.634082, lng: 20.297239 },
    { lat: 48.634272, lng: 20.297293 },
    { lat: 48.634517, lng: 20.297323 },
    { lat: 48.634687, lng: 20.297416 },
    { lat: 48.63479, lng: 20.297437 },
    { lat: 48.634819, lng: 20.297433 },
    { lat: 48.635, lng: 20.29738 },
    { lat: 48.635216, lng: 20.297365 },
    { lat: 48.635461, lng: 20.297313 },
    { lat: 48.635632, lng: 20.297264 },
    { lat: 48.635723, lng: 20.297287 },
    { lat: 48.636005, lng: 20.297445 },
    { lat: 48.636111, lng: 20.297475 },
    { lat: 48.6365, lng: 20.297246 },
    { lat: 48.636613, lng: 20.297107 },
    { lat: 48.636753, lng: 20.296905 },
    { lat: 48.637013, lng: 20.296777 },
    { lat: 48.637276, lng: 20.296709 },
    { lat: 48.63729, lng: 20.296702 },
    { lat: 48.637316, lng: 20.29669 },
    { lat: 48.63732, lng: 20.296639 },
    { lat: 48.6374, lng: 20.296535 },
    { lat: 48.637491, lng: 20.296421 },
    { lat: 48.637551, lng: 20.29638 },
    { lat: 48.637647, lng: 20.296319 },
    { lat: 48.63773, lng: 20.296262 },
    { lat: 48.637876, lng: 20.296151 },
    { lat: 48.63798, lng: 20.296067 },
    { lat: 48.638037, lng: 20.296028 },
    { lat: 48.638083, lng: 20.295944 },
    { lat: 48.63815, lng: 20.295804 },
    { lat: 48.638215, lng: 20.295729 },
    { lat: 48.638326, lng: 20.295617 },
    { lat: 48.6386, lng: 20.295488 },
    { lat: 48.638724, lng: 20.295364 },
    { lat: 48.638847, lng: 20.295235 },
    { lat: 48.638934, lng: 20.295151 },
    { lat: 48.638982, lng: 20.295082 },
    { lat: 48.63904, lng: 20.295004 },
    { lat: 48.639133, lng: 20.294886 },
    { lat: 48.639433, lng: 20.294914 },
    { lat: 48.639489, lng: 20.294933 },
    { lat: 48.639553, lng: 20.294959 },
    { lat: 48.639626, lng: 20.294981 },
    { lat: 48.639703, lng: 20.295009 },
    { lat: 48.639862, lng: 20.295053 },
    { lat: 48.639894, lng: 20.295065 },
    { lat: 48.640064, lng: 20.295133 },
    { lat: 48.640147, lng: 20.295175 },
    { lat: 48.64026, lng: 20.295241 },
    { lat: 48.640371, lng: 20.295317 },
    { lat: 48.640565, lng: 20.295356 },
    { lat: 48.640783, lng: 20.295318 },
    { lat: 48.641066, lng: 20.29519 },
    { lat: 48.641298, lng: 20.295082 },
    { lat: 48.64153, lng: 20.294978 },
    { lat: 48.641702, lng: 20.294846 },
    { lat: 48.641844, lng: 20.294681 },
    { lat: 48.642083, lng: 20.294429 },
    { lat: 48.642204, lng: 20.294374 },
    { lat: 48.642446, lng: 20.294261 },
    { lat: 48.642578, lng: 20.294141 },
    { lat: 48.642756, lng: 20.293976 },
    { lat: 48.64299, lng: 20.293828 },
    { lat: 48.643137, lng: 20.293749 },
    { lat: 48.64339, lng: 20.293619 },
    { lat: 48.643569, lng: 20.293517 },
    { lat: 48.64376, lng: 20.293426 },
    { lat: 48.643859, lng: 20.293424 },
    { lat: 48.644101, lng: 20.293431 },
    { lat: 48.644254, lng: 20.293445 },
    { lat: 48.644394, lng: 20.293489 },
    { lat: 48.644542, lng: 20.29354 },
    { lat: 48.644672, lng: 20.293654 },
    { lat: 48.64485, lng: 20.293828 },
    { lat: 48.644927, lng: 20.29387 },
    { lat: 48.645229, lng: 20.294023 },
    { lat: 48.645416, lng: 20.294108 },
    { lat: 48.645705, lng: 20.294159 },
    { lat: 48.645863, lng: 20.294225 },
    { lat: 48.64601, lng: 20.294288 },
    { lat: 48.646191, lng: 20.29437 },
    { lat: 48.646459, lng: 20.294494 },
    { lat: 48.64662, lng: 20.294655 },
    { lat: 48.646711, lng: 20.294747 },
    { lat: 48.646832, lng: 20.294803 },
    { lat: 48.646858, lng: 20.294815 },
    { lat: 48.647024, lng: 20.294884 },
    { lat: 48.647175, lng: 20.29495 },
    { lat: 48.647331, lng: 20.29507 },
    { lat: 48.647545, lng: 20.295243 },
    { lat: 48.647616, lng: 20.295285 },
    { lat: 48.647779, lng: 20.29538 },
    { lat: 48.647968, lng: 20.295483 },
    { lat: 48.648011, lng: 20.295513 },
    { lat: 48.648175, lng: 20.295634 },
    { lat: 48.64829, lng: 20.295717 },
    { lat: 48.64838, lng: 20.295799 },
    { lat: 48.64853, lng: 20.295912 },
    { lat: 48.648652, lng: 20.295986 },
    { lat: 48.648826, lng: 20.296065 },
    { lat: 48.648908, lng: 20.29608 },
    { lat: 48.64895, lng: 20.296088 },
    { lat: 48.648976, lng: 20.296093 },
    { lat: 48.649044, lng: 20.296112 },
    { lat: 48.649073, lng: 20.296119 },
    { lat: 48.649151, lng: 20.29614 },
    { lat: 48.649147, lng: 20.29616 },
    { lat: 48.649193, lng: 20.296176 },
    { lat: 48.649558, lng: 20.296825 },
    { lat: 48.649572, lng: 20.296926 },
    { lat: 48.649587, lng: 20.296947 },
    { lat: 48.649657, lng: 20.296993 },
    { lat: 48.649745, lng: 20.29705 },
    { lat: 48.650004, lng: 20.297226 },
    { lat: 48.650264, lng: 20.297339 },
    { lat: 48.650453, lng: 20.297422 },
    { lat: 48.650756, lng: 20.297647 },
    { lat: 48.65107, lng: 20.297916 },
    { lat: 48.651188, lng: 20.298022 },
    { lat: 48.65185, lng: 20.298004 },
    { lat: 48.652473, lng: 20.29798 },
    { lat: 48.652809, lng: 20.297966 },
    { lat: 48.653104, lng: 20.29817 },
    { lat: 48.653163, lng: 20.298211 },
    { lat: 48.653729, lng: 20.298367 },
    { lat: 48.653833, lng: 20.298395 },
    { lat: 48.653959, lng: 20.29843 },
    { lat: 48.65403, lng: 20.298449 },
    { lat: 48.654277, lng: 20.298538 },
    { lat: 48.654437, lng: 20.298594 },
    { lat: 48.654559, lng: 20.298659 },
    { lat: 48.65478, lng: 20.298783 },
    { lat: 48.654933, lng: 20.298872 },
    { lat: 48.655205, lng: 20.298997 },
    { lat: 48.655425, lng: 20.299253 },
    { lat: 48.655648, lng: 20.299418 },
    { lat: 48.655878, lng: 20.299595 },
    { lat: 48.656035, lng: 20.299737 },
    { lat: 48.656091, lng: 20.299788 },
    { lat: 48.656337, lng: 20.299934 },
    { lat: 48.656542, lng: 20.300153 },
    { lat: 48.656728, lng: 20.300352 },
    { lat: 48.656936, lng: 20.300623 },
    { lat: 48.657195, lng: 20.300956 },
    { lat: 48.657305, lng: 20.301268 },
    { lat: 48.657457, lng: 20.301665 },
    { lat: 48.65755, lng: 20.301909 },
    { lat: 48.657712, lng: 20.302212 },
    { lat: 48.657861, lng: 20.302295 },
    { lat: 48.658018, lng: 20.302389 },
    { lat: 48.658014, lng: 20.302226 },
    { lat: 48.657587, lng: 20.301746 },
    { lat: 48.657448, lng: 20.301278 },
    { lat: 48.657407, lng: 20.301173 },
    { lat: 48.657296, lng: 20.300339 },
    { lat: 48.657282, lng: 20.30031 },
    { lat: 48.657391, lng: 20.299938 },
    { lat: 48.657377, lng: 20.299924 },
    { lat: 48.65737, lng: 20.299918 },
    { lat: 48.657404, lng: 20.299472 },
    { lat: 48.657449, lng: 20.298843 },
    { lat: 48.657608, lng: 20.298485 },
    { lat: 48.657782, lng: 20.298078 },
    { lat: 48.657879, lng: 20.297812 },
    { lat: 48.657892, lng: 20.297785 },
    { lat: 48.657912, lng: 20.297486 },
    { lat: 48.658051, lng: 20.297165 },
    { lat: 48.658275, lng: 20.296941 },
    { lat: 48.658518, lng: 20.296631 },
    { lat: 48.658531, lng: 20.296653 },
    { lat: 48.658627, lng: 20.29648 },
    { lat: 48.658682, lng: 20.296445 },
    { lat: 48.658814, lng: 20.296178 },
    { lat: 48.658963, lng: 20.296042 },
    { lat: 48.659227, lng: 20.295716 },
    { lat: 48.659255, lng: 20.295681 },
    { lat: 48.659304, lng: 20.29563 },
    { lat: 48.659416, lng: 20.295512 },
    { lat: 48.659419, lng: 20.295511 },
    { lat: 48.659427, lng: 20.295501 },
    { lat: 48.659467, lng: 20.29545 },
    { lat: 48.659468, lng: 20.29545 },
    { lat: 48.659603, lng: 20.295276 },
    { lat: 48.659608, lng: 20.295269 },
    { lat: 48.659669, lng: 20.295185 },
    { lat: 48.659677, lng: 20.295174 },
    { lat: 48.659677, lng: 20.295173 },
    { lat: 48.659784, lng: 20.295024 },
    { lat: 48.659813, lng: 20.294983 },
    { lat: 48.659872, lng: 20.294901 },
    { lat: 48.660062, lng: 20.294631 },
    { lat: 48.66008, lng: 20.294605 },
    { lat: 48.660132, lng: 20.294534 },
    { lat: 48.660262, lng: 20.294352 },
    { lat: 48.660506, lng: 20.294058 },
    { lat: 48.660525, lng: 20.294035 },
    { lat: 48.66057, lng: 20.29398 },
    { lat: 48.660655, lng: 20.293878 },
    { lat: 48.660746, lng: 20.29377 },
    { lat: 48.66089, lng: 20.293597 },
    { lat: 48.66094, lng: 20.293528 },
    { lat: 48.660993, lng: 20.29345 },
    { lat: 48.661045, lng: 20.293375 },
    { lat: 48.661102, lng: 20.293277 },
    { lat: 48.661275, lng: 20.292859 },
    { lat: 48.66137, lng: 20.292459 },
    { lat: 48.661371, lng: 20.292456 },
    { lat: 48.661483, lng: 20.292091 },
    { lat: 48.661511, lng: 20.291995 },
    { lat: 48.661669, lng: 20.291476 },
    { lat: 48.661798, lng: 20.291062 },
    { lat: 48.661871, lng: 20.290894 },
    { lat: 48.661931, lng: 20.290666 },
    { lat: 48.662214, lng: 20.290195 },
    { lat: 48.662632, lng: 20.290048 },
    { lat: 48.662938, lng: 20.289732 },
    { lat: 48.663348, lng: 20.289482 },
    { lat: 48.663524, lng: 20.289373 },
    { lat: 48.664057, lng: 20.289235 },
    { lat: 48.66435, lng: 20.289046 },
    { lat: 48.66455, lng: 20.288945 },
    { lat: 48.664765, lng: 20.288832 },
    { lat: 48.665042, lng: 20.288714 },
    { lat: 48.665269, lng: 20.288614 },
    { lat: 48.665271, lng: 20.288612 },
    { lat: 48.665393, lng: 20.288496 },
    { lat: 48.665477, lng: 20.288365 },
    { lat: 48.665617, lng: 20.288217 },
    { lat: 48.665737, lng: 20.288069 },
    { lat: 48.666342, lng: 20.287826 },
    { lat: 48.666479, lng: 20.287767 },
    { lat: 48.666488, lng: 20.287787 },
    { lat: 48.666906, lng: 20.287374 },
    { lat: 48.667334, lng: 20.286958 },
    { lat: 48.667365, lng: 20.286918 },
    { lat: 48.667618, lng: 20.286749 },
    { lat: 48.667937, lng: 20.286328 },
    { lat: 48.668453, lng: 20.285844 },
    { lat: 48.668611, lng: 20.285637 },
    { lat: 48.668719, lng: 20.285506 },
    { lat: 48.668835, lng: 20.285136 },
    { lat: 48.669204, lng: 20.28496 },
    { lat: 48.669498, lng: 20.284815 },
    { lat: 48.669564, lng: 20.284747 },
    { lat: 48.669757, lng: 20.284447 },
    { lat: 48.669877, lng: 20.284268 },
    { lat: 48.670185, lng: 20.283952 },
    { lat: 48.67068, lng: 20.283604 },
    { lat: 48.671055, lng: 20.283514 },
    { lat: 48.671182, lng: 20.283474 },
    { lat: 48.67146, lng: 20.283165 },
    { lat: 48.671525, lng: 20.283145 },
    { lat: 48.671806, lng: 20.283073 },
    { lat: 48.672021, lng: 20.282851 },
    { lat: 48.672344, lng: 20.282628 },
    { lat: 48.672566, lng: 20.282506 },
    { lat: 48.672937, lng: 20.282304 },
    { lat: 48.673235, lng: 20.28216 },
    { lat: 48.673324, lng: 20.281968 },
    { lat: 48.673388, lng: 20.281803 },
    { lat: 48.673566, lng: 20.281355 },
    { lat: 48.673648, lng: 20.280931 },
    { lat: 48.673766, lng: 20.280617 },
    { lat: 48.67382, lng: 20.280411 },
    { lat: 48.673942, lng: 20.279983 },
    { lat: 48.674029, lng: 20.279674 },
    { lat: 48.67411, lng: 20.27956 },
    { lat: 48.67433, lng: 20.279089 },
    { lat: 48.67447, lng: 20.278673 },
    { lat: 48.674544, lng: 20.278448 },
    { lat: 48.674634, lng: 20.277914 },
    { lat: 48.674701, lng: 20.277742 },
    { lat: 48.67473, lng: 20.277567 },
    { lat: 48.674786, lng: 20.277504 },
    { lat: 48.675408, lng: 20.277465 },
    { lat: 48.675734, lng: 20.27732 },
    { lat: 48.675983, lng: 20.277156 },
    { lat: 48.676206, lng: 20.277106 },
    { lat: 48.676422, lng: 20.277063 },
    { lat: 48.67662, lng: 20.277061 },
    { lat: 48.676828, lng: 20.276984 },
    { lat: 48.676828, lng: 20.276983 },
    { lat: 48.676933, lng: 20.27693 },
    { lat: 48.676954, lng: 20.276919 },
    { lat: 48.677317, lng: 20.276684 },
    { lat: 48.677651, lng: 20.27647 },
    { lat: 48.677904, lng: 20.276303 },
    { lat: 48.677924, lng: 20.27629 },
    { lat: 48.67794, lng: 20.276279 },
    { lat: 48.678263, lng: 20.276064 },
    { lat: 48.678429, lng: 20.276004 },
    { lat: 48.678804, lng: 20.275876 },
    { lat: 48.679125, lng: 20.275593 },
    { lat: 48.679401, lng: 20.27539 },
    { lat: 48.679583, lng: 20.275212 },
    { lat: 48.679697, lng: 20.275108 },
    { lat: 48.679987, lng: 20.275113 },
    { lat: 48.680268, lng: 20.27512 },
    { lat: 48.680435, lng: 20.274945 },
    { lat: 48.680848, lng: 20.27497 },
    { lat: 48.680983, lng: 20.274923 },
    { lat: 48.681133, lng: 20.275079 },
    { lat: 48.681291, lng: 20.275239 },
    { lat: 48.681415, lng: 20.275281 },
    { lat: 48.68157, lng: 20.275339 },
    { lat: 48.681808, lng: 20.275426 },
    { lat: 48.68184, lng: 20.275447 },
    { lat: 48.682012, lng: 20.275513 },
    { lat: 48.682051, lng: 20.275681 },
    { lat: 48.6821, lng: 20.2759 },
    { lat: 48.682129, lng: 20.276026 },
    { lat: 48.6820756, lng: 20.2747887 },
    { lat: 48.6821102, lng: 20.2743269 },
    { lat: 48.6815487, lng: 20.2734512 },
    { lat: 48.6810938, lng: 20.2725392 },
    { lat: 48.6800121, lng: 20.2709872 },
    { lat: 48.6789262, lng: 20.2707208 },
    { lat: 48.6781652, lng: 20.270614 },
    { lat: 48.6776657, lng: 20.2703115 },
    { lat: 48.6773069, lng: 20.2701845 },
    { lat: 48.6768305, lng: 20.2701554 },
    { lat: 48.6760551, lng: 20.2698842 },
    { lat: 48.6756982, lng: 20.2696006 },
    { lat: 48.6748041, lng: 20.268575 },
    { lat: 48.6744024, lng: 20.2680115 },
    { lat: 48.6742086, lng: 20.2676106 },
    { lat: 48.6740348, lng: 20.2674877 },
    { lat: 48.6739753, lng: 20.2672971 },
    { lat: 48.6739677, lng: 20.2672714 },
  ],
  MuránskaDlháLúka: [
    { lat: 48.7198461, lng: 20.1095699 },
    { lat: 48.7198811, lng: 20.1095484 },
    { lat: 48.7204531, lng: 20.109764 },
    { lat: 48.7206234, lng: 20.1100966 },
    { lat: 48.7210575, lng: 20.1106886 },
    { lat: 48.7212628, lng: 20.1108089 },
    { lat: 48.7215583, lng: 20.1111202 },
    { lat: 48.7222256, lng: 20.111521 },
    { lat: 48.7225674, lng: 20.1118062 },
    { lat: 48.7234956, lng: 20.1129718 },
    { lat: 48.7241127, lng: 20.1134903 },
    { lat: 48.7245064, lng: 20.1140579 },
    { lat: 48.7248889, lng: 20.1148756 },
    { lat: 48.725045, lng: 20.1153876 },
    { lat: 48.7255308, lng: 20.1161425 },
    { lat: 48.7257173, lng: 20.1162696 },
    { lat: 48.7261863, lng: 20.1168623 },
    { lat: 48.7268677, lng: 20.1173015 },
    { lat: 48.7271834, lng: 20.1176113 },
    { lat: 48.727898, lng: 20.1177367 },
    { lat: 48.7291265, lng: 20.1178135 },
    { lat: 48.730177, lng: 20.1176692 },
    { lat: 48.7305051, lng: 20.1177279 },
    { lat: 48.7317999, lng: 20.1169993 },
    { lat: 48.7337058, lng: 20.1150717 },
    { lat: 48.7358559, lng: 20.1123136 },
    { lat: 48.7363106, lng: 20.1126862 },
    { lat: 48.7366916, lng: 20.1133015 },
    { lat: 48.7368176, lng: 20.1134096 },
    { lat: 48.7369282, lng: 20.1136619 },
    { lat: 48.7371979, lng: 20.1139554 },
    { lat: 48.7373964, lng: 20.1139337 },
    { lat: 48.7376835, lng: 20.1142887 },
    { lat: 48.7381272, lng: 20.1146759 },
    { lat: 48.738312, lng: 20.1149542 },
    { lat: 48.7397148, lng: 20.115986 },
    { lat: 48.7400972, lng: 20.1160292 },
    { lat: 48.7410093, lng: 20.1159973 },
    { lat: 48.7416204, lng: 20.1160981 },
    { lat: 48.7427755, lng: 20.1160811 },
    { lat: 48.7433634, lng: 20.1161894 },
    { lat: 48.7440696, lng: 20.1164972 },
    { lat: 48.744795, lng: 20.1165227 },
    { lat: 48.7454079, lng: 20.1168367 },
    { lat: 48.7453998, lng: 20.1167765 },
    { lat: 48.7454388, lng: 20.1155491 },
    { lat: 48.7453896, lng: 20.1152814 },
    { lat: 48.7455663, lng: 20.1142077 },
    { lat: 48.7456846, lng: 20.1136794 },
    { lat: 48.7470164, lng: 20.1115088 },
    { lat: 48.7472932, lng: 20.1111425 },
    { lat: 48.7479565, lng: 20.1106184 },
    { lat: 48.7484513, lng: 20.1104587 },
    { lat: 48.7488404, lng: 20.1102265 },
    { lat: 48.7495892, lng: 20.1099988 },
    { lat: 48.7505785, lng: 20.1093718 },
    { lat: 48.7519523, lng: 20.1030502 },
    { lat: 48.751903, lng: 20.1026775 },
    { lat: 48.7514693, lng: 20.1017619 },
    { lat: 48.7510919, lng: 20.1012639 },
    { lat: 48.7506651, lng: 20.1001385 },
    { lat: 48.7503137, lng: 20.0994822 },
    { lat: 48.7497908, lng: 20.0987295 },
    { lat: 48.7494937, lng: 20.0978818 },
    { lat: 48.7495035, lng: 20.0944906 },
    { lat: 48.749331, lng: 20.0939085 },
    { lat: 48.7488848, lng: 20.0929953 },
    { lat: 48.7486642, lng: 20.0928078 },
    { lat: 48.7484339, lng: 20.0921924 },
    { lat: 48.7480327, lng: 20.0913877 },
    { lat: 48.747458, lng: 20.0905293 },
    { lat: 48.7470396, lng: 20.0896095 },
    { lat: 48.7468669, lng: 20.0894105 },
    { lat: 48.7467175, lng: 20.0891172 },
    { lat: 48.7464833, lng: 20.0882395 },
    { lat: 48.7459135, lng: 20.0883546 },
    { lat: 48.7453932, lng: 20.0882132 },
    { lat: 48.7443903, lng: 20.0883169 },
    { lat: 48.7437443, lng: 20.0884952 },
    { lat: 48.7427901, lng: 20.0889273 },
    { lat: 48.7417573, lng: 20.0888175 },
    { lat: 48.7414727, lng: 20.0888837 },
    { lat: 48.7413367, lng: 20.0888059 },
    { lat: 48.7410402, lng: 20.0885796 },
    { lat: 48.7402892, lng: 20.0877885 },
    { lat: 48.7392889, lng: 20.0863322 },
    { lat: 48.7389343, lng: 20.0859548 },
    { lat: 48.7386589, lng: 20.0858018 },
    { lat: 48.7380919, lng: 20.0846375 },
    { lat: 48.7375467, lng: 20.0833495 },
    { lat: 48.7367223, lng: 20.0826379 },
    { lat: 48.7355003, lng: 20.0803099 },
    { lat: 48.7347453, lng: 20.0796439 },
    { lat: 48.7343333, lng: 20.0795385 },
    { lat: 48.7334775, lng: 20.0791304 },
    { lat: 48.7328988, lng: 20.079224 },
    { lat: 48.7327436, lng: 20.0790884 },
    { lat: 48.7318362, lng: 20.0787972 },
    { lat: 48.7313748, lng: 20.0783678 },
    { lat: 48.7309961, lng: 20.0777618 },
    { lat: 48.7307286, lng: 20.0774734 },
    { lat: 48.7299276, lng: 20.0771929 },
    { lat: 48.7296507, lng: 20.0769292 },
    { lat: 48.7293002, lng: 20.0767281 },
    { lat: 48.7286117, lng: 20.0768028 },
    { lat: 48.7283208, lng: 20.0767135 },
    { lat: 48.7280118, lng: 20.0763515 },
    { lat: 48.7274378, lng: 20.0755139 },
    { lat: 48.7277481, lng: 20.0750877 },
    { lat: 48.726943, lng: 20.0744084 },
    { lat: 48.7265703, lng: 20.0735088 },
    { lat: 48.726183, lng: 20.0732402 },
    { lat: 48.7256336, lng: 20.0726454 },
    { lat: 48.725519, lng: 20.072293 },
    { lat: 48.725817, lng: 20.0717916 },
    { lat: 48.7256816, lng: 20.0715639 },
    { lat: 48.7251655, lng: 20.0710377 },
    { lat: 48.7247526, lng: 20.0708301 },
    { lat: 48.7244328, lng: 20.0705605 },
    { lat: 48.7240129, lng: 20.0704576 },
    { lat: 48.7236884, lng: 20.0702067 },
    { lat: 48.7232868, lng: 20.0697576 },
    { lat: 48.7231083, lng: 20.0697444 },
    { lat: 48.7233752, lng: 20.068648 },
    { lat: 48.7236781, lng: 20.0680198 },
    { lat: 48.7238575, lng: 20.0678877 },
    { lat: 48.7241144, lng: 20.0674554 },
    { lat: 48.7243756, lng: 20.0667962 },
    { lat: 48.7255815, lng: 20.0649236 },
    { lat: 48.7257498, lng: 20.0642845 },
    { lat: 48.7250147, lng: 20.0635543 },
    { lat: 48.7243745, lng: 20.0627274 },
    { lat: 48.7242917, lng: 20.0624655 },
    { lat: 48.7234846, lng: 20.0619879 },
    { lat: 48.7234049, lng: 20.062127 },
    { lat: 48.7230345, lng: 20.0614802 },
    { lat: 48.7227314, lng: 20.061283 },
    { lat: 48.7224365, lng: 20.0609615 },
    { lat: 48.7221916, lng: 20.0607874 },
    { lat: 48.7220504, lng: 20.0608884 },
    { lat: 48.7216199, lng: 20.0607728 },
    { lat: 48.7214217, lng: 20.0605817 },
    { lat: 48.7211858, lng: 20.0607901 },
    { lat: 48.7210026, lng: 20.0604689 },
    { lat: 48.7208204, lng: 20.060343 },
    { lat: 48.7204099, lng: 20.0598627 },
    { lat: 48.7201582, lng: 20.0596606 },
    { lat: 48.7196137, lng: 20.0587795 },
    { lat: 48.7191204, lng: 20.0576892 },
    { lat: 48.7186713, lng: 20.0569586 },
    { lat: 48.7180983, lng: 20.0556861 },
    { lat: 48.7176531, lng: 20.0537146 },
    { lat: 48.7175054, lng: 20.0526777 },
    { lat: 48.7167141, lng: 20.0499411 },
    { lat: 48.7165077, lng: 20.0495504 },
    { lat: 48.716326, lng: 20.0493228 },
    { lat: 48.7161532, lng: 20.0492603 },
    { lat: 48.7159078, lng: 20.048827 },
    { lat: 48.7155627, lng: 20.0484098 },
    { lat: 48.7152797, lng: 20.0477196 },
    { lat: 48.7152064, lng: 20.0471191 },
    { lat: 48.7149469, lng: 20.0462156 },
    { lat: 48.714613, lng: 20.0456016 },
    { lat: 48.7142037, lng: 20.0446659 },
    { lat: 48.7131577, lng: 20.0433471 },
    { lat: 48.711845, lng: 20.0421359 },
    { lat: 48.7114687, lng: 20.0419472 },
    { lat: 48.7109114, lng: 20.0411889 },
    { lat: 48.7106315, lng: 20.041316 },
    { lat: 48.7103376, lng: 20.0413142 },
    { lat: 48.7087645, lng: 20.0408086 },
    { lat: 48.7079928, lng: 20.0408856 },
    { lat: 48.7068466, lng: 20.0405208 },
    { lat: 48.7064929, lng: 20.0406263 },
    { lat: 48.7056533, lng: 20.0402893 },
    { lat: 48.7053614, lng: 20.0399892 },
    { lat: 48.7047812, lng: 20.0396864 },
    { lat: 48.7041592, lng: 20.0394643 },
    { lat: 48.7038344, lng: 20.0391284 },
    { lat: 48.7026061, lng: 20.0370235 },
    { lat: 48.70172, lng: 20.0358387 },
    { lat: 48.7015607, lng: 20.0357201 },
    { lat: 48.7013763, lng: 20.0357026 },
    { lat: 48.7008504, lng: 20.0354846 },
    { lat: 48.6999623, lng: 20.0347946 },
    { lat: 48.6998168, lng: 20.0346269 },
    { lat: 48.6996504, lng: 20.0345528 },
    { lat: 48.6995948, lng: 20.0344886 },
    { lat: 48.6994084, lng: 20.0338815 },
    { lat: 48.6987046, lng: 20.0330978 },
    { lat: 48.6985955, lng: 20.0327213 },
    { lat: 48.6984366, lng: 20.0324186 },
    { lat: 48.6981775, lng: 20.0323131 },
    { lat: 48.6977182, lng: 20.0315062 },
    { lat: 48.6971909, lng: 20.0309041 },
    { lat: 48.6970277, lng: 20.030829 },
    { lat: 48.6964542, lng: 20.030227 },
    { lat: 48.6959773, lng: 20.0295947 },
    { lat: 48.6954047, lng: 20.0284482 },
    { lat: 48.6954106, lng: 20.0283457 },
    { lat: 48.6952342, lng: 20.0281389 },
    { lat: 48.6949435, lng: 20.0276192 },
    { lat: 48.6946438, lng: 20.0273777 },
    { lat: 48.6945548, lng: 20.0272132 },
    { lat: 48.6946208, lng: 20.0271203 },
    { lat: 48.6945797, lng: 20.0264835 },
    { lat: 48.6946764, lng: 20.0258283 },
    { lat: 48.6946548, lng: 20.0254742 },
    { lat: 48.6946071, lng: 20.0254901 },
    { lat: 48.6940804, lng: 20.0260161 },
    { lat: 48.6935916, lng: 20.02667 },
    { lat: 48.6928754, lng: 20.0279737 },
    { lat: 48.6926227, lng: 20.0281795 },
    { lat: 48.6925523, lng: 20.0283826 },
    { lat: 48.6924336, lng: 20.0285361 },
    { lat: 48.6923799, lng: 20.0289469 },
    { lat: 48.6919754, lng: 20.0306028 },
    { lat: 48.6919588, lng: 20.0311217 },
    { lat: 48.6916318, lng: 20.0327114 },
    { lat: 48.6916155, lng: 20.033001 },
    { lat: 48.6916731, lng: 20.0332215 },
    { lat: 48.6914901, lng: 20.0337202 },
    { lat: 48.6912345, lng: 20.0356492 },
    { lat: 48.6911068, lng: 20.0360189 },
    { lat: 48.6910329, lng: 20.0365172 },
    { lat: 48.6908257, lng: 20.0373382 },
    { lat: 48.6908586, lng: 20.037908 },
    { lat: 48.6913962, lng: 20.0388179 },
    { lat: 48.6915452, lng: 20.0397161 },
    { lat: 48.6916806, lng: 20.0403468 },
    { lat: 48.6917588, lng: 20.0405248 },
    { lat: 48.691741, lng: 20.0411771 },
    { lat: 48.6918322, lng: 20.0415036 },
    { lat: 48.6920058, lng: 20.0420526 },
    { lat: 48.6922722, lng: 20.0425301 },
    { lat: 48.6925648, lng: 20.0433078 },
    { lat: 48.6930241, lng: 20.0441735 },
    { lat: 48.6932394, lng: 20.0447493 },
    { lat: 48.6934606, lng: 20.0457929 },
    { lat: 48.6939509, lng: 20.04686 },
    { lat: 48.6942679, lng: 20.0477941 },
    { lat: 48.6947836, lng: 20.0495844 },
    { lat: 48.6947112, lng: 20.0502298 },
    { lat: 48.6948202, lng: 20.0508798 },
    { lat: 48.6951169, lng: 20.0514611 },
    { lat: 48.6954209, lng: 20.0527391 },
    { lat: 48.6958906, lng: 20.0539943 },
    { lat: 48.6959786, lng: 20.0544335 },
    { lat: 48.6965663, lng: 20.0552145 },
    { lat: 48.6968989, lng: 20.0557925 },
    { lat: 48.6974104, lng: 20.0563988 },
    { lat: 48.6982816, lng: 20.0580203 },
    { lat: 48.6984995, lng: 20.0593918 },
    { lat: 48.698756, lng: 20.062061 },
    { lat: 48.6987368, lng: 20.0627103 },
    { lat: 48.6988341, lng: 20.0634884 },
    { lat: 48.6988559, lng: 20.0652706 },
    { lat: 48.6991264, lng: 20.0665872 },
    { lat: 48.6992086, lng: 20.0685235 },
    { lat: 48.6990887, lng: 20.0690838 },
    { lat: 48.6995326, lng: 20.0705079 },
    { lat: 48.6996719, lng: 20.0712368 },
    { lat: 48.6997137, lng: 20.0720351 },
    { lat: 48.6996789, lng: 20.0726392 },
    { lat: 48.6995803, lng: 20.0730653 },
    { lat: 48.6995723, lng: 20.0735813 },
    { lat: 48.6998157, lng: 20.0745678 },
    { lat: 48.700128, lng: 20.0749304 },
    { lat: 48.7004209, lng: 20.0759454 },
    { lat: 48.7005779, lng: 20.0768007 },
    { lat: 48.7015039, lng: 20.080313 },
    { lat: 48.7017752, lng: 20.0816764 },
    { lat: 48.7035837, lng: 20.0855832 },
    { lat: 48.7034482, lng: 20.085934 },
    { lat: 48.7037702, lng: 20.0863435 },
    { lat: 48.7038151, lng: 20.086586 },
    { lat: 48.7045733, lng: 20.0874393 },
    { lat: 48.7062063, lng: 20.0907556 },
    { lat: 48.7073759, lng: 20.0926624 },
    { lat: 48.7078972, lng: 20.093727 },
    { lat: 48.7082011, lng: 20.094548 },
    { lat: 48.7087056, lng: 20.0955346 },
    { lat: 48.7092416, lng: 20.096023 },
    { lat: 48.709278, lng: 20.0970206 },
    { lat: 48.7102907, lng: 20.0979355 },
    { lat: 48.7105222, lng: 20.0980341 },
    { lat: 48.7113277, lng: 20.0989135 },
    { lat: 48.7119766, lng: 20.0993589 },
    { lat: 48.7132452, lng: 20.0999996 },
    { lat: 48.7139322, lng: 20.1000827 },
    { lat: 48.714147, lng: 20.1002114 },
    { lat: 48.7145011, lng: 20.100138 },
    { lat: 48.7150661, lng: 20.1003794 },
    { lat: 48.7161091, lng: 20.1005416 },
    { lat: 48.716664, lng: 20.1012501 },
    { lat: 48.7177339, lng: 20.102238 },
    { lat: 48.718069, lng: 20.1028823 },
    { lat: 48.7183672, lng: 20.1040618 },
    { lat: 48.7185273, lng: 20.1045309 },
    { lat: 48.7186139, lng: 20.1050554 },
    { lat: 48.7186192, lng: 20.1054287 },
    { lat: 48.7188737, lng: 20.1064746 },
    { lat: 48.7189313, lng: 20.1072261 },
    { lat: 48.7191747, lng: 20.1082999 },
    { lat: 48.7193124, lng: 20.108724 },
    { lat: 48.7198461, lng: 20.1095699 },
  ],
  Otročok: [
    { lat: 48.4568821, lng: 20.2487453 },
    { lat: 48.4568947, lng: 20.2486934 },
    { lat: 48.4569905, lng: 20.2473975 },
    { lat: 48.456851, lng: 20.2466625 },
    { lat: 48.4564264, lng: 20.246324 },
    { lat: 48.4561149, lng: 20.2458571 },
    { lat: 48.4556737, lng: 20.2454934 },
    { lat: 48.4539121, lng: 20.2435465 },
    { lat: 48.4532294, lng: 20.2426373 },
    { lat: 48.4530301, lng: 20.2422959 },
    { lat: 48.4521396, lng: 20.2414336 },
    { lat: 48.451904, lng: 20.2409457 },
    { lat: 48.4518345, lng: 20.2406472 },
    { lat: 48.4516044, lng: 20.2403583 },
    { lat: 48.4512274, lng: 20.2401383 },
    { lat: 48.4505928, lng: 20.2400426 },
    { lat: 48.4491306, lng: 20.2399748 },
    { lat: 48.4480982, lng: 20.2397299 },
    { lat: 48.447385, lng: 20.2396715 },
    { lat: 48.4468484, lng: 20.2397157 },
    { lat: 48.4455453, lng: 20.2386967 },
    { lat: 48.4442445, lng: 20.2370308 },
    { lat: 48.443775, lng: 20.236491 },
    { lat: 48.443763, lng: 20.23651 },
    { lat: 48.443754, lng: 20.236501 },
    { lat: 48.443387, lng: 20.23636 },
    { lat: 48.443308, lng: 20.237492 },
    { lat: 48.443151, lng: 20.238553 },
    { lat: 48.44293, lng: 20.23948 },
    { lat: 48.442711, lng: 20.240149 },
    { lat: 48.442595, lng: 20.240567 },
    { lat: 48.442581, lng: 20.240648 },
    { lat: 48.442528, lng: 20.240975 },
    { lat: 48.442548, lng: 20.241272 },
    { lat: 48.442555, lng: 20.241484 },
    { lat: 48.442531, lng: 20.241691 },
    { lat: 48.442409, lng: 20.242039 },
    { lat: 48.442334, lng: 20.242243 },
    { lat: 48.442257, lng: 20.242763 },
    { lat: 48.442231, lng: 20.24321 },
    { lat: 48.442047, lng: 20.243685 },
    { lat: 48.442012, lng: 20.244181 },
    { lat: 48.441948, lng: 20.244644 },
    { lat: 48.441954, lng: 20.24477 },
    { lat: 48.44211, lng: 20.245157 },
    { lat: 48.442186, lng: 20.245574 },
    { lat: 48.442018, lng: 20.246451 },
    { lat: 48.441886, lng: 20.24666 },
    { lat: 48.441577, lng: 20.246971 },
    { lat: 48.441319, lng: 20.247291 },
    { lat: 48.44096, lng: 20.247511 },
    { lat: 48.440821, lng: 20.247564 },
    { lat: 48.440636, lng: 20.24756 },
    { lat: 48.440356, lng: 20.247468 },
    { lat: 48.440199, lng: 20.247342 },
    { lat: 48.439771, lng: 20.247084 },
    { lat: 48.439436, lng: 20.24681 },
    { lat: 48.439336, lng: 20.24673 },
    { lat: 48.439221, lng: 20.246669 },
    { lat: 48.439103, lng: 20.246664 },
    { lat: 48.438567, lng: 20.246862 },
    { lat: 48.438364, lng: 20.2469 },
    { lat: 48.4380482, lng: 20.2471446 },
    { lat: 48.4386222, lng: 20.2477852 },
    { lat: 48.4392811, lng: 20.2495958 },
    { lat: 48.4395641, lng: 20.2510178 },
    { lat: 48.4395289, lng: 20.2519716 },
    { lat: 48.4394271, lng: 20.2525791 },
    { lat: 48.4391906, lng: 20.2528618 },
    { lat: 48.4389925, lng: 20.253573 },
    { lat: 48.4390183, lng: 20.2537988 },
    { lat: 48.438724, lng: 20.2546098 },
    { lat: 48.4386947, lng: 20.2554037 },
    { lat: 48.4386045, lng: 20.2554274 },
    { lat: 48.4384536, lng: 20.256106 },
    { lat: 48.4382181, lng: 20.2564461 },
    { lat: 48.4382089, lng: 20.2568615 },
    { lat: 48.4380858, lng: 20.2571113 },
    { lat: 48.4382061, lng: 20.2583514 },
    { lat: 48.4382132, lng: 20.2589271 },
    { lat: 48.4380924, lng: 20.2596285 },
    { lat: 48.4379965, lng: 20.259822 },
    { lat: 48.4378356, lng: 20.2599935 },
    { lat: 48.4377676, lng: 20.2602965 },
    { lat: 48.4376658, lng: 20.2605075 },
    { lat: 48.4375194, lng: 20.2620978 },
    { lat: 48.4371447, lng: 20.2630125 },
    { lat: 48.4371636, lng: 20.2631434 },
    { lat: 48.4371292, lng: 20.2632465 },
    { lat: 48.4371885, lng: 20.2636878 },
    { lat: 48.4371387, lng: 20.2638547 },
    { lat: 48.4371409, lng: 20.2656374 },
    { lat: 48.4371946, lng: 20.2663352 },
    { lat: 48.4371009, lng: 20.2666156 },
    { lat: 48.4370645, lng: 20.2675393 },
    { lat: 48.4369786, lng: 20.267874 },
    { lat: 48.4371804, lng: 20.2682769 },
    { lat: 48.437182, lng: 20.2686275 },
    { lat: 48.4372645, lng: 20.26869 },
    { lat: 48.4372731, lng: 20.2688534 },
    { lat: 48.4373878, lng: 20.2690737 },
    { lat: 48.4373136, lng: 20.2690764 },
    { lat: 48.437326, lng: 20.2691643 },
    { lat: 48.4374872, lng: 20.2692531 },
    { lat: 48.4375023, lng: 20.2693482 },
    { lat: 48.4375789, lng: 20.2693523 },
    { lat: 48.4376297, lng: 20.2695797 },
    { lat: 48.4377792, lng: 20.2695451 },
    { lat: 48.4378606, lng: 20.2697775 },
    { lat: 48.4379315, lng: 20.2696371 },
    { lat: 48.4380886, lng: 20.2697078 },
    { lat: 48.4380792, lng: 20.2698378 },
    { lat: 48.438103, lng: 20.269897 },
    { lat: 48.4381756, lng: 20.269883 },
    { lat: 48.4381725, lng: 20.2700599 },
    { lat: 48.4383262, lng: 20.2703583 },
    { lat: 48.4383116, lng: 20.2704731 },
    { lat: 48.4384445, lng: 20.2703619 },
    { lat: 48.4385541, lng: 20.2705124 },
    { lat: 48.4386109, lng: 20.2704619 },
    { lat: 48.4387562, lng: 20.2705208 },
    { lat: 48.4389906, lng: 20.2708059 },
    { lat: 48.4389727, lng: 20.2715212 },
    { lat: 48.4391023, lng: 20.2719518 },
    { lat: 48.4390955, lng: 20.2722085 },
    { lat: 48.4396526, lng: 20.2742949 },
    { lat: 48.4396266, lng: 20.2747526 },
    { lat: 48.4396617, lng: 20.275267 },
    { lat: 48.4396928, lng: 20.2752557 },
    { lat: 48.4398228, lng: 20.2748663 },
    { lat: 48.440218, lng: 20.2742152 },
    { lat: 48.4403334, lng: 20.274196 },
    { lat: 48.440667, lng: 20.2744559 },
    { lat: 48.4413044, lng: 20.2767292 },
    { lat: 48.4418802, lng: 20.2782182 },
    { lat: 48.4424283, lng: 20.2791751 },
    { lat: 48.4446508, lng: 20.2852176 },
    { lat: 48.4440679, lng: 20.2859407 },
    { lat: 48.4443816, lng: 20.2879236 },
    { lat: 48.4444107, lng: 20.289801 },
    { lat: 48.4447047, lng: 20.2910844 },
    { lat: 48.4450297, lng: 20.2913881 },
    { lat: 48.4459776, lng: 20.291858 },
    { lat: 48.4465796, lng: 20.2919884 },
    { lat: 48.4469632, lng: 20.2934923 },
    { lat: 48.4470026, lng: 20.2939464 },
    { lat: 48.4471882, lng: 20.2946013 },
    { lat: 48.4473792, lng: 20.2949309 },
    { lat: 48.4479518, lng: 20.2955127 },
    { lat: 48.4482241, lng: 20.2957023 },
    { lat: 48.4484186, lng: 20.2952136 },
    { lat: 48.4484885, lng: 20.295125 },
    { lat: 48.4487306, lng: 20.2942393 },
    { lat: 48.4488962, lng: 20.2940709 },
    { lat: 48.4498672, lng: 20.2938758 },
    { lat: 48.4502684, lng: 20.293625 },
    { lat: 48.4507735, lng: 20.2934693 },
    { lat: 48.4520824, lng: 20.2931901 },
    { lat: 48.4525627, lng: 20.2928635 },
    { lat: 48.453045, lng: 20.292443 },
    { lat: 48.453929, lng: 20.2921257 },
    { lat: 48.4544761, lng: 20.2924295 },
    { lat: 48.4548456, lng: 20.292436 },
    { lat: 48.4552856, lng: 20.2921391 },
    { lat: 48.4559063, lng: 20.2920845 },
    { lat: 48.4566384, lng: 20.2929175 },
    { lat: 48.4568605, lng: 20.293212 },
    { lat: 48.4569952, lng: 20.2935094 },
    { lat: 48.4570059, lng: 20.2934975 },
    { lat: 48.4573942, lng: 20.2927174 },
    { lat: 48.4573667, lng: 20.2919968 },
    { lat: 48.4573072, lng: 20.2917988 },
    { lat: 48.4571175, lng: 20.2914712 },
    { lat: 48.4570135, lng: 20.2907496 },
    { lat: 48.4571012, lng: 20.2900066 },
    { lat: 48.4573192, lng: 20.2891381 },
    { lat: 48.457354, lng: 20.2882213 },
    { lat: 48.4571827, lng: 20.2870821 },
    { lat: 48.4570151, lng: 20.2853741 },
    { lat: 48.457013, lng: 20.284895 },
    { lat: 48.4568625, lng: 20.2838228 },
    { lat: 48.4568578, lng: 20.2829645 },
    { lat: 48.4567209, lng: 20.2823807 },
    { lat: 48.456696, lng: 20.2813508 },
    { lat: 48.4565554, lng: 20.2808447 },
    { lat: 48.4562803, lng: 20.2802938 },
    { lat: 48.456321, lng: 20.2792474 },
    { lat: 48.4565217, lng: 20.2775438 },
    { lat: 48.4564855, lng: 20.2773696 },
    { lat: 48.4558905, lng: 20.2763961 },
    { lat: 48.4560807, lng: 20.2762247 },
    { lat: 48.4557178, lng: 20.275504 },
    { lat: 48.4553272, lng: 20.2724976 },
    { lat: 48.4546925, lng: 20.2705502 },
    { lat: 48.4542131, lng: 20.2704158 },
    { lat: 48.4535974, lng: 20.2698881 },
    { lat: 48.4529568, lng: 20.2685303 },
    { lat: 48.4525881, lng: 20.2678974 },
    { lat: 48.4508852, lng: 20.2657953 },
    { lat: 48.4492726, lng: 20.2634503 },
    { lat: 48.4489215, lng: 20.2624899 },
    { lat: 48.4481708, lng: 20.2598736 },
    { lat: 48.4476141, lng: 20.2584205 },
    { lat: 48.4469116, lng: 20.2563268 },
    { lat: 48.4465746, lng: 20.2543401 },
    { lat: 48.4464363, lng: 20.2523366 },
    { lat: 48.444566, lng: 20.249832 },
    { lat: 48.4438419, lng: 20.2494399 },
    { lat: 48.4422417, lng: 20.2468761 },
    { lat: 48.4423518, lng: 20.2455101 },
    { lat: 48.4427819, lng: 20.2452522 },
    { lat: 48.4438177, lng: 20.2450219 },
    { lat: 48.4442784, lng: 20.2447094 },
    { lat: 48.4449846, lng: 20.2447983 },
    { lat: 48.4452302, lng: 20.2448861 },
    { lat: 48.4465074, lng: 20.2455347 },
    { lat: 48.4466143, lng: 20.245446 },
    { lat: 48.4469377, lng: 20.2454779 },
    { lat: 48.4472498, lng: 20.2470369 },
    { lat: 48.4475442, lng: 20.2476369 },
    { lat: 48.4480221, lng: 20.2482412 },
    { lat: 48.4483306, lng: 20.2489166 },
    { lat: 48.4485567, lng: 20.2491648 },
    { lat: 48.4493849, lng: 20.2495684 },
    { lat: 48.4497357, lng: 20.2495843 },
    { lat: 48.4499132, lng: 20.2495198 },
    { lat: 48.4533192, lng: 20.2511591 },
    { lat: 48.4533716, lng: 20.250608 },
    { lat: 48.4535468, lng: 20.2504017 },
    { lat: 48.4536967, lng: 20.2504024 },
    { lat: 48.4538298, lng: 20.2502662 },
    { lat: 48.4552216, lng: 20.2503162 },
    { lat: 48.4553792, lng: 20.2500902 },
    { lat: 48.4556708, lng: 20.2501416 },
    { lat: 48.4560105, lng: 20.2497637 },
    { lat: 48.456538, lng: 20.2493228 },
    { lat: 48.4566329, lng: 20.2492435 },
    { lat: 48.4568821, lng: 20.2487453 },
  ],
  Žiar: [
    { lat: 48.42101, lng: 20.283987 },
    { lat: 48.4210636, lng: 20.2840365 },
    { lat: 48.4211065, lng: 20.284406 },
    { lat: 48.4212539, lng: 20.2845148 },
    { lat: 48.4213488, lng: 20.2844099 },
    { lat: 48.4213909, lng: 20.2841679 },
    { lat: 48.4219853, lng: 20.2841885 },
    { lat: 48.4221064, lng: 20.2840575 },
    { lat: 48.4221776, lng: 20.2840685 },
    { lat: 48.4228023, lng: 20.285937 },
    { lat: 48.4236781, lng: 20.287237 },
    { lat: 48.4247744, lng: 20.2880451 },
    { lat: 48.4247731, lng: 20.2894384 },
    { lat: 48.4255586, lng: 20.2891662 },
    { lat: 48.4278168, lng: 20.2881445 },
    { lat: 48.4280226, lng: 20.2881081 },
    { lat: 48.4281709, lng: 20.288326 },
    { lat: 48.428345, lng: 20.2884555 },
    { lat: 48.4287181, lng: 20.2882389 },
    { lat: 48.4288641, lng: 20.2878553 },
    { lat: 48.4289686, lng: 20.2877357 },
    { lat: 48.4288951, lng: 20.2874167 },
    { lat: 48.4289459, lng: 20.2871065 },
    { lat: 48.4288297, lng: 20.2867882 },
    { lat: 48.4288479, lng: 20.2864784 },
    { lat: 48.4287981, lng: 20.2861038 },
    { lat: 48.4287663, lng: 20.2860077 },
    { lat: 48.4286911, lng: 20.2859871 },
    { lat: 48.4286828, lng: 20.2863743 },
    { lat: 48.4285752, lng: 20.2863318 },
    { lat: 48.4285123, lng: 20.2861378 },
    { lat: 48.4285233, lng: 20.2853911 },
    { lat: 48.4284631, lng: 20.2852344 },
    { lat: 48.4285729, lng: 20.2851047 },
    { lat: 48.4286279, lng: 20.284931 },
    { lat: 48.4289222, lng: 20.2847108 },
    { lat: 48.4288132, lng: 20.2845467 },
    { lat: 48.4288123, lng: 20.2844695 },
    { lat: 48.4289301, lng: 20.2840877 },
    { lat: 48.428983, lng: 20.2836007 },
    { lat: 48.4292116, lng: 20.2834317 },
    { lat: 48.4292971, lng: 20.283428 },
    { lat: 48.4295059, lng: 20.2836409 },
    { lat: 48.4295779, lng: 20.2838604 },
    { lat: 48.4297418, lng: 20.2841312 },
    { lat: 48.4300439, lng: 20.2841145 },
    { lat: 48.4300822, lng: 20.2842306 },
    { lat: 48.430006, lng: 20.284381 },
    { lat: 48.4300285, lng: 20.2846329 },
    { lat: 48.4301419, lng: 20.2849387 },
    { lat: 48.4302809, lng: 20.2850639 },
    { lat: 48.4306185, lng: 20.2848582 },
    { lat: 48.4305582, lng: 20.2844157 },
    { lat: 48.4306305, lng: 20.2841951 },
    { lat: 48.4307583, lng: 20.2842358 },
    { lat: 48.4308021, lng: 20.2844063 },
    { lat: 48.430779, lng: 20.2847584 },
    { lat: 48.4311044, lng: 20.2849294 },
    { lat: 48.4313458, lng: 20.2848805 },
    { lat: 48.4314578, lng: 20.2845503 },
    { lat: 48.4316154, lng: 20.2844951 },
    { lat: 48.4316354, lng: 20.2843745 },
    { lat: 48.4313098, lng: 20.2840315 },
    { lat: 48.4312656, lng: 20.2837366 },
    { lat: 48.4312967, lng: 20.2836114 },
    { lat: 48.431538, lng: 20.2833934 },
    { lat: 48.4316758, lng: 20.2831485 },
    { lat: 48.4318967, lng: 20.2830936 },
    { lat: 48.432066, lng: 20.2832157 },
    { lat: 48.4324622, lng: 20.2830644 },
    { lat: 48.432459, lng: 20.2829622 },
    { lat: 48.4323143, lng: 20.2828042 },
    { lat: 48.4323681, lng: 20.2825656 },
    { lat: 48.4322546, lng: 20.2823765 },
    { lat: 48.4322737, lng: 20.2822929 },
    { lat: 48.4323934, lng: 20.2823233 },
    { lat: 48.4324366, lng: 20.2822209 },
    { lat: 48.4323518, lng: 20.2818993 },
    { lat: 48.4323988, lng: 20.2815533 },
    { lat: 48.4326693, lng: 20.2815532 },
    { lat: 48.4327101, lng: 20.2813623 },
    { lat: 48.4327716, lng: 20.2813238 },
    { lat: 48.4329995, lng: 20.2816446 },
    { lat: 48.4330893, lng: 20.2815816 },
    { lat: 48.4329878, lng: 20.281296 },
    { lat: 48.4330186, lng: 20.2809645 },
    { lat: 48.4332039, lng: 20.2808337 },
    { lat: 48.4331292, lng: 20.2806837 },
    { lat: 48.4329373, lng: 20.2806753 },
    { lat: 48.4329452, lng: 20.2804469 },
    { lat: 48.4330656, lng: 20.2803875 },
    { lat: 48.4331655, lng: 20.2801811 },
    { lat: 48.433297, lng: 20.280147 },
    { lat: 48.4335089, lng: 20.2798957 },
    { lat: 48.4336017, lng: 20.280046 },
    { lat: 48.4336854, lng: 20.2800552 },
    { lat: 48.4340787, lng: 20.2794384 },
    { lat: 48.4345276, lng: 20.2788546 },
    { lat: 48.4349684, lng: 20.2777884 },
    { lat: 48.4353146, lng: 20.2774308 },
    { lat: 48.4354532, lng: 20.2774021 },
    { lat: 48.4354852, lng: 20.277329 },
    { lat: 48.435449, lng: 20.2770691 },
    { lat: 48.4355603, lng: 20.2767516 },
    { lat: 48.4358619, lng: 20.2764303 },
    { lat: 48.4359664, lng: 20.2765121 },
    { lat: 48.436034, lng: 20.2764557 },
    { lat: 48.436056, lng: 20.2761881 },
    { lat: 48.4364033, lng: 20.2754604 },
    { lat: 48.4366901, lng: 20.2751929 },
    { lat: 48.4367636, lng: 20.2752012 },
    { lat: 48.4370562, lng: 20.2754814 },
    { lat: 48.4373846, lng: 20.2754127 },
    { lat: 48.4376817, lng: 20.2757159 },
    { lat: 48.4384129, lng: 20.2756402 },
    { lat: 48.4388929, lng: 20.2752152 },
    { lat: 48.4396617, lng: 20.275267 },
    { lat: 48.4396266, lng: 20.2747526 },
    { lat: 48.4396526, lng: 20.2742949 },
    { lat: 48.4390955, lng: 20.2722085 },
    { lat: 48.4391023, lng: 20.2719518 },
    { lat: 48.4389727, lng: 20.2715212 },
    { lat: 48.4389906, lng: 20.2708059 },
    { lat: 48.4387562, lng: 20.2705208 },
    { lat: 48.4386109, lng: 20.2704619 },
    { lat: 48.4385541, lng: 20.2705124 },
    { lat: 48.4384445, lng: 20.2703619 },
    { lat: 48.4383116, lng: 20.2704731 },
    { lat: 48.4383262, lng: 20.2703583 },
    { lat: 48.4381725, lng: 20.2700599 },
    { lat: 48.4381756, lng: 20.269883 },
    { lat: 48.438103, lng: 20.269897 },
    { lat: 48.4380792, lng: 20.2698378 },
    { lat: 48.4380886, lng: 20.2697078 },
    { lat: 48.4379315, lng: 20.2696371 },
    { lat: 48.4378606, lng: 20.2697775 },
    { lat: 48.4377792, lng: 20.2695451 },
    { lat: 48.4376297, lng: 20.2695797 },
    { lat: 48.4375789, lng: 20.2693523 },
    { lat: 48.4375023, lng: 20.2693482 },
    { lat: 48.4374872, lng: 20.2692531 },
    { lat: 48.437326, lng: 20.2691643 },
    { lat: 48.4373136, lng: 20.2690764 },
    { lat: 48.4373878, lng: 20.2690737 },
    { lat: 48.4372731, lng: 20.2688534 },
    { lat: 48.4372645, lng: 20.26869 },
    { lat: 48.437182, lng: 20.2686275 },
    { lat: 48.4371804, lng: 20.2682769 },
    { lat: 48.4369786, lng: 20.267874 },
    { lat: 48.4370645, lng: 20.2675393 },
    { lat: 48.4371009, lng: 20.2666156 },
    { lat: 48.4371946, lng: 20.2663352 },
    { lat: 48.4371409, lng: 20.2656374 },
    { lat: 48.4371387, lng: 20.2638547 },
    { lat: 48.4371885, lng: 20.2636878 },
    { lat: 48.4371292, lng: 20.2632465 },
    { lat: 48.4371636, lng: 20.2631434 },
    { lat: 48.4371447, lng: 20.2630125 },
    { lat: 48.4375194, lng: 20.2620978 },
    { lat: 48.4376658, lng: 20.2605075 },
    { lat: 48.4377676, lng: 20.2602965 },
    { lat: 48.4378356, lng: 20.2599935 },
    { lat: 48.4379965, lng: 20.259822 },
    { lat: 48.4380924, lng: 20.2596285 },
    { lat: 48.4382132, lng: 20.2589271 },
    { lat: 48.4382061, lng: 20.2583514 },
    { lat: 48.4380858, lng: 20.2571113 },
    { lat: 48.4382089, lng: 20.2568615 },
    { lat: 48.4382181, lng: 20.2564461 },
    { lat: 48.4384536, lng: 20.256106 },
    { lat: 48.4386045, lng: 20.2554274 },
    { lat: 48.4386947, lng: 20.2554037 },
    { lat: 48.438724, lng: 20.2546098 },
    { lat: 48.4390183, lng: 20.2537988 },
    { lat: 48.4389925, lng: 20.253573 },
    { lat: 48.4391906, lng: 20.2528618 },
    { lat: 48.4394271, lng: 20.2525791 },
    { lat: 48.4395289, lng: 20.2519716 },
    { lat: 48.4395641, lng: 20.2510178 },
    { lat: 48.4392811, lng: 20.2495958 },
    { lat: 48.4386222, lng: 20.2477852 },
    { lat: 48.4380482, lng: 20.2471446 },
    { lat: 48.438364, lng: 20.2469 },
    { lat: 48.437986, lng: 20.245474 },
    { lat: 48.437188, lng: 20.245844 },
    { lat: 48.436702, lng: 20.246176 },
    { lat: 48.436182, lng: 20.246429 },
    { lat: 48.435798, lng: 20.246786 },
    { lat: 48.435295, lng: 20.246849 },
    { lat: 48.435179, lng: 20.246866 },
    { lat: 48.434286, lng: 20.247134 },
    { lat: 48.434126, lng: 20.247171 },
    { lat: 48.434115, lng: 20.247168 },
    { lat: 48.433835, lng: 20.247248 },
    { lat: 48.433247, lng: 20.247079 },
    { lat: 48.431878, lng: 20.246472 },
    { lat: 48.431312, lng: 20.246311 },
    { lat: 48.430778, lng: 20.246231 },
    { lat: 48.430542, lng: 20.246252 },
    { lat: 48.429927, lng: 20.246279 },
    { lat: 48.429388, lng: 20.246473 },
    { lat: 48.429359, lng: 20.246482 },
    { lat: 48.429175, lng: 20.246523 },
    { lat: 48.429073, lng: 20.246545 },
    { lat: 48.429037, lng: 20.246639 },
    { lat: 48.428705, lng: 20.24657 },
    { lat: 48.428649, lng: 20.246621 },
    { lat: 48.428481, lng: 20.246687 },
    { lat: 48.428304, lng: 20.246922 },
    { lat: 48.428276, lng: 20.247155 },
    { lat: 48.428085, lng: 20.247537 },
    { lat: 48.428053, lng: 20.247678 },
    { lat: 48.427938, lng: 20.247939 },
    { lat: 48.427915, lng: 20.24799 },
    { lat: 48.427851, lng: 20.248329 },
    { lat: 48.42775, lng: 20.248548 },
    { lat: 48.427716, lng: 20.248774 },
    { lat: 48.427618, lng: 20.248998 },
    { lat: 48.427628, lng: 20.2492 },
    { lat: 48.427483, lng: 20.249583 },
    { lat: 48.427376, lng: 20.249661 },
    { lat: 48.427178, lng: 20.250278 },
    { lat: 48.427141, lng: 20.250431 },
    { lat: 48.427051, lng: 20.250546 },
    { lat: 48.426829, lng: 20.2506 },
    { lat: 48.426732, lng: 20.250782 },
    { lat: 48.426602, lng: 20.250942 },
    { lat: 48.426272, lng: 20.251549 },
    { lat: 48.42631, lng: 20.251717 },
    { lat: 48.426405, lng: 20.251852 },
    { lat: 48.426394, lng: 20.251935 },
    { lat: 48.426233, lng: 20.251931 },
    { lat: 48.4261, lng: 20.252537 },
    { lat: 48.425763, lng: 20.252854 },
    { lat: 48.425619, lng: 20.253223 },
    { lat: 48.425497, lng: 20.253203 },
    { lat: 48.425434, lng: 20.253387 },
    { lat: 48.425344, lng: 20.253534 },
    { lat: 48.42529, lng: 20.253548 },
    { lat: 48.425204, lng: 20.253496 },
    { lat: 48.425064, lng: 20.25356 },
    { lat: 48.424973, lng: 20.253709 },
    { lat: 48.424768, lng: 20.253767 },
    { lat: 48.424591, lng: 20.253988 },
    { lat: 48.424503, lng: 20.254165 },
    { lat: 48.424382, lng: 20.254328 },
    { lat: 48.424303, lng: 20.254501 },
    { lat: 48.424182, lng: 20.254522 },
    { lat: 48.424101, lng: 20.254836 },
    { lat: 48.424075, lng: 20.255001 },
    { lat: 48.423997, lng: 20.255175 },
    { lat: 48.423975, lng: 20.255401 },
    { lat: 48.42395, lng: 20.255596 },
    { lat: 48.423945, lng: 20.255647 },
    { lat: 48.423939, lng: 20.255709 },
    { lat: 48.423892, lng: 20.255765 },
    { lat: 48.423878, lng: 20.255769 },
    { lat: 48.423822, lng: 20.255774 },
    { lat: 48.423755, lng: 20.255872 },
    { lat: 48.423732, lng: 20.255978 },
    { lat: 48.423734, lng: 20.255999 },
    { lat: 48.423753, lng: 20.256136 },
    { lat: 48.42374, lng: 20.256149 },
    { lat: 48.423673, lng: 20.256144 },
    { lat: 48.423602, lng: 20.256249 },
    { lat: 48.423601, lng: 20.256284 },
    { lat: 48.42361, lng: 20.256304 },
    { lat: 48.423687, lng: 20.256382 },
    { lat: 48.423672, lng: 20.256449 },
    { lat: 48.423548, lng: 20.25648 },
    { lat: 48.423455, lng: 20.256506 },
    { lat: 48.423443, lng: 20.256522 },
    { lat: 48.423437, lng: 20.256545 },
    { lat: 48.423447, lng: 20.256714 },
    { lat: 48.423402, lng: 20.256864 },
    { lat: 48.423385, lng: 20.256918 },
    { lat: 48.423328, lng: 20.257006 },
    { lat: 48.423349, lng: 20.257089 },
    { lat: 48.423348, lng: 20.257111 },
    { lat: 48.423333, lng: 20.257168 },
    { lat: 48.423333, lng: 20.25729 },
    { lat: 48.423286, lng: 20.257372 },
    { lat: 48.423278, lng: 20.257416 },
    { lat: 48.423267, lng: 20.257472 },
    { lat: 48.423262, lng: 20.257495 },
    { lat: 48.423332, lng: 20.257607 },
    { lat: 48.423348, lng: 20.257743 },
    { lat: 48.423242, lng: 20.257864 },
    { lat: 48.423178, lng: 20.257937 },
    { lat: 48.423174, lng: 20.25798 },
    { lat: 48.423233, lng: 20.258082 },
    { lat: 48.423188, lng: 20.25815 },
    { lat: 48.423082, lng: 20.258198 },
    { lat: 48.423048, lng: 20.258295 },
    { lat: 48.423018, lng: 20.258315 },
    { lat: 48.423016, lng: 20.258329 },
    { lat: 48.423033, lng: 20.258394 },
    { lat: 48.42302, lng: 20.258411 },
    { lat: 48.42294, lng: 20.258338 },
    { lat: 48.422925, lng: 20.258339 },
    { lat: 48.422845, lng: 20.258513 },
    { lat: 48.42278, lng: 20.25856 },
    { lat: 48.422728, lng: 20.258562 },
    { lat: 48.422718, lng: 20.258713 },
    { lat: 48.422729, lng: 20.258769 },
    { lat: 48.42273, lng: 20.258792 },
    { lat: 48.422717, lng: 20.258821 },
    { lat: 48.422596, lng: 20.258856 },
    { lat: 48.422534, lng: 20.259063 },
    { lat: 48.422444, lng: 20.259097 },
    { lat: 48.422409, lng: 20.259108 },
    { lat: 48.422374, lng: 20.259105 },
    { lat: 48.422362, lng: 20.259071 },
    { lat: 48.422336, lng: 20.258988 },
    { lat: 48.422322, lng: 20.258981 },
    { lat: 48.422226, lng: 20.259045 },
    { lat: 48.422152, lng: 20.259066 },
    { lat: 48.422086, lng: 20.259077 },
    { lat: 48.42202, lng: 20.259109 },
    { lat: 48.421961, lng: 20.259045 },
    { lat: 48.421922, lng: 20.259328 },
    { lat: 48.421841, lng: 20.259433 },
    { lat: 48.421829, lng: 20.25956 },
    { lat: 48.421708, lng: 20.259624 },
    { lat: 48.421664, lng: 20.259664 },
    { lat: 48.421587, lng: 20.259663 },
    { lat: 48.42157, lng: 20.25968 },
    { lat: 48.421566, lng: 20.259791 },
    { lat: 48.421621, lng: 20.259819 },
    { lat: 48.421627, lng: 20.259851 },
    { lat: 48.421606, lng: 20.25989 },
    { lat: 48.421532, lng: 20.259927 },
    { lat: 48.421522, lng: 20.259949 },
    { lat: 48.421561, lng: 20.260097 },
    { lat: 48.421442, lng: 20.260318 },
    { lat: 48.421399, lng: 20.260372 },
    { lat: 48.421418, lng: 20.260417 },
    { lat: 48.421457, lng: 20.260393 },
    { lat: 48.421512, lng: 20.260399 },
    { lat: 48.421508, lng: 20.260437 },
    { lat: 48.421473, lng: 20.260473 },
    { lat: 48.421433, lng: 20.260562 },
    { lat: 48.421408, lng: 20.260584 },
    { lat: 48.421381, lng: 20.260559 },
    { lat: 48.421386, lng: 20.260483 },
    { lat: 48.421358, lng: 20.260448 },
    { lat: 48.421331, lng: 20.260606 },
    { lat: 48.421301, lng: 20.260659 },
    { lat: 48.421279, lng: 20.260785 },
    { lat: 48.421241, lng: 20.260848 },
    { lat: 48.421287, lng: 20.260934 },
    { lat: 48.421211, lng: 20.26104 },
    { lat: 48.421158, lng: 20.260996 },
    { lat: 48.421077, lng: 20.261008 },
    { lat: 48.421015, lng: 20.26098 },
    { lat: 48.420972, lng: 20.26102 },
    { lat: 48.420952, lng: 20.261149 },
    { lat: 48.420955, lng: 20.261236 },
    { lat: 48.421005, lng: 20.261341 },
    { lat: 48.420987, lng: 20.261394 },
    { lat: 48.420881, lng: 20.261363 },
    { lat: 48.420826, lng: 20.261477 },
    { lat: 48.420748, lng: 20.26143 },
    { lat: 48.420702, lng: 20.261618 },
    { lat: 48.420657, lng: 20.261702 },
    { lat: 48.420651, lng: 20.2618 },
    { lat: 48.420742, lng: 20.261857 },
    { lat: 48.420786, lng: 20.26192 },
    { lat: 48.420762, lng: 20.262034 },
    { lat: 48.420749, lng: 20.262154 },
    { lat: 48.420617, lng: 20.262236 },
    { lat: 48.420596, lng: 20.262322 },
    { lat: 48.420523, lng: 20.262347 },
    { lat: 48.420514, lng: 20.262372 },
    { lat: 48.420546, lng: 20.262627 },
    { lat: 48.420469, lng: 20.262763 },
    { lat: 48.420465, lng: 20.262857 },
    { lat: 48.420463, lng: 20.262907 },
    { lat: 48.420412, lng: 20.262998 },
    { lat: 48.420341, lng: 20.263024 },
    { lat: 48.420265, lng: 20.263179 },
    { lat: 48.420223, lng: 20.26329 },
    { lat: 48.420115, lng: 20.263396 },
    { lat: 48.419924, lng: 20.263723 },
    { lat: 48.419943, lng: 20.263803 },
    { lat: 48.420038, lng: 20.263849 },
    { lat: 48.420015, lng: 20.263988 },
    { lat: 48.41993, lng: 20.263994 },
    { lat: 48.419891, lng: 20.263967 },
    { lat: 48.419856, lng: 20.263981 },
    { lat: 48.419791, lng: 20.264169 },
    { lat: 48.419698, lng: 20.264144 },
    { lat: 48.419697, lng: 20.264329 },
    { lat: 48.419722, lng: 20.264356 },
    { lat: 48.419783, lng: 20.264354 },
    { lat: 48.419822, lng: 20.264414 },
    { lat: 48.419808, lng: 20.264485 },
    { lat: 48.419723, lng: 20.264515 },
    { lat: 48.4197, lng: 20.264619 },
    { lat: 48.419702, lng: 20.264719 },
    { lat: 48.41968, lng: 20.264782 },
    { lat: 48.419883, lng: 20.265346 },
    { lat: 48.419901, lng: 20.265404 },
    { lat: 48.41992, lng: 20.265432 },
    { lat: 48.420174, lng: 20.265716 },
    { lat: 48.420124, lng: 20.266335 },
    { lat: 48.420067, lng: 20.267053 },
    { lat: 48.42006, lng: 20.267475 },
    { lat: 48.420041, lng: 20.268127 },
    { lat: 48.420093, lng: 20.268425 },
    { lat: 48.420104, lng: 20.268898 },
    { lat: 48.420073, lng: 20.269107 },
    { lat: 48.419782, lng: 20.269734 },
    { lat: 48.419704, lng: 20.270155 },
    { lat: 48.419714, lng: 20.270642 },
    { lat: 48.419621, lng: 20.27094 },
    { lat: 48.419496, lng: 20.271319 },
    { lat: 48.41942, lng: 20.2715 },
    { lat: 48.419316, lng: 20.271742 },
    { lat: 48.419271, lng: 20.271845 },
    { lat: 48.419093, lng: 20.272491 },
    { lat: 48.419033, lng: 20.27287 },
    { lat: 48.418699, lng: 20.273392 },
    { lat: 48.418611, lng: 20.273609 },
    { lat: 48.418403, lng: 20.274136 },
    { lat: 48.4184, lng: 20.274614 },
    { lat: 48.418455, lng: 20.274879 },
    { lat: 48.418518, lng: 20.27518 },
    { lat: 48.418601, lng: 20.275578 },
    { lat: 48.418663, lng: 20.275878 },
    { lat: 48.418703, lng: 20.276012 },
    { lat: 48.418785, lng: 20.276294 },
    { lat: 48.418865, lng: 20.27657 },
    { lat: 48.418937, lng: 20.276825 },
    { lat: 48.419033, lng: 20.277102 },
    { lat: 48.419154, lng: 20.277488 },
    { lat: 48.419253, lng: 20.277873 },
    { lat: 48.419387, lng: 20.278389 },
    { lat: 48.41946, lng: 20.278671 },
    { lat: 48.419502, lng: 20.278831 },
    { lat: 48.419509, lng: 20.278848 },
    { lat: 48.419555, lng: 20.278946 },
    { lat: 48.419655, lng: 20.279166 },
    { lat: 48.419773, lng: 20.279423 },
    { lat: 48.419979, lng: 20.279862 },
    { lat: 48.420195, lng: 20.280325 },
    { lat: 48.420422, lng: 20.280814 },
    { lat: 48.420621, lng: 20.281235 },
    { lat: 48.420827, lng: 20.281661 },
    { lat: 48.420858, lng: 20.28184 },
    { lat: 48.420973, lng: 20.282488 },
    { lat: 48.42106, lng: 20.282912 },
    { lat: 48.421188, lng: 20.283532 },
    { lat: 48.420946, lng: 20.283753 },
    { lat: 48.42101, lng: 20.283987 },
  ],
  Gemer: [
    { lat: 48.47493, lng: 20.336234 },
    { lat: 48.474827, lng: 20.336162 },
    { lat: 48.474739, lng: 20.336169 },
    { lat: 48.47455, lng: 20.336177 },
    { lat: 48.474471, lng: 20.33612 },
    { lat: 48.474363, lng: 20.336017 },
    { lat: 48.474262, lng: 20.335853 },
    { lat: 48.474237, lng: 20.335793 },
    { lat: 48.474167, lng: 20.335542 },
    { lat: 48.474099, lng: 20.335333 },
    { lat: 48.474065, lng: 20.33526 },
    { lat: 48.473965, lng: 20.335138 },
    { lat: 48.473903, lng: 20.335079 },
    { lat: 48.473869, lng: 20.334922 },
    { lat: 48.473881, lng: 20.334808 },
    { lat: 48.473972, lng: 20.334609 },
    { lat: 48.474523, lng: 20.33441 },
    { lat: 48.474597, lng: 20.334338 },
    { lat: 48.474623, lng: 20.334246 },
    { lat: 48.474622, lng: 20.334216 },
    { lat: 48.474523, lng: 20.333982 },
    { lat: 48.474527, lng: 20.333527 },
    { lat: 48.474517, lng: 20.33345 },
    { lat: 48.474419, lng: 20.333343 },
    { lat: 48.47429, lng: 20.333384 },
    { lat: 48.474075, lng: 20.333776 },
    { lat: 48.47416, lng: 20.334142 },
    { lat: 48.474149, lng: 20.334193 },
    { lat: 48.474127, lng: 20.334235 },
    { lat: 48.474035, lng: 20.334319 },
    { lat: 48.474008, lng: 20.334331 },
    { lat: 48.473918, lng: 20.334329 },
    { lat: 48.473805, lng: 20.334288 },
    { lat: 48.473765, lng: 20.334257 },
    { lat: 48.473635, lng: 20.334015 },
    { lat: 48.473605, lng: 20.333728 },
    { lat: 48.473613, lng: 20.3334 },
    { lat: 48.473602, lng: 20.333305 },
    { lat: 48.473552, lng: 20.333065 },
    { lat: 48.473537, lng: 20.332957 },
    { lat: 48.473534, lng: 20.332833 },
    { lat: 48.47355, lng: 20.332712 },
    { lat: 48.473582, lng: 20.332638 },
    { lat: 48.473657, lng: 20.332608 },
    { lat: 48.473707, lng: 20.332611 },
    { lat: 48.473813, lng: 20.332634 },
    { lat: 48.473873, lng: 20.332633 },
    { lat: 48.474014, lng: 20.332568 },
    { lat: 48.474178, lng: 20.332523 },
    { lat: 48.474238, lng: 20.332614 },
    { lat: 48.474266, lng: 20.332712 },
    { lat: 48.47433, lng: 20.332975 },
    { lat: 48.474395, lng: 20.333014 },
    { lat: 48.474433, lng: 20.333005 },
    { lat: 48.474541, lng: 20.332914 },
    { lat: 48.474609, lng: 20.332826 },
    { lat: 48.47465, lng: 20.332482 },
    { lat: 48.474676, lng: 20.332378 },
    { lat: 48.474707, lng: 20.332344 },
    { lat: 48.474741, lng: 20.332335 },
    { lat: 48.474824, lng: 20.332372 },
    { lat: 48.474848, lng: 20.332403 },
    { lat: 48.474874, lng: 20.332518 },
    { lat: 48.47488, lng: 20.33259 },
    { lat: 48.474882, lng: 20.332754 },
    { lat: 48.474966, lng: 20.332878 },
    { lat: 48.475091, lng: 20.333024 },
    { lat: 48.475134, lng: 20.333045 },
    { lat: 48.475391, lng: 20.332967 },
    { lat: 48.475538, lng: 20.333054 },
    { lat: 48.4756, lng: 20.33302 },
    { lat: 48.475625, lng: 20.332961 },
    { lat: 48.475649, lng: 20.332838 },
    { lat: 48.475641, lng: 20.332659 },
    { lat: 48.475621, lng: 20.332484 },
    { lat: 48.475665, lng: 20.332197 },
    { lat: 48.475665, lng: 20.33205 },
    { lat: 48.475632, lng: 20.331781 },
    { lat: 48.475505, lng: 20.331566 },
    { lat: 48.475462, lng: 20.331468 },
    { lat: 48.475461, lng: 20.331346 },
    { lat: 48.475483, lng: 20.331227 },
    { lat: 48.475622, lng: 20.330926 },
    { lat: 48.475709, lng: 20.330736 },
    { lat: 48.475703, lng: 20.330294 },
    { lat: 48.475675, lng: 20.330248 },
    { lat: 48.475525, lng: 20.330197 },
    { lat: 48.475054, lng: 20.330049 },
    { lat: 48.475019, lng: 20.329737 },
    { lat: 48.474985, lng: 20.329275 },
    { lat: 48.474973, lng: 20.3292 },
    { lat: 48.474507, lng: 20.32906 },
    { lat: 48.47448, lng: 20.329045 },
    { lat: 48.474208, lng: 20.328789 },
    { lat: 48.473817, lng: 20.327933 },
    { lat: 48.473813, lng: 20.327495 },
    { lat: 48.473817, lng: 20.327446 },
    { lat: 48.473876, lng: 20.327294 },
    { lat: 48.474018, lng: 20.32708 },
    { lat: 48.474052, lng: 20.327036 },
    { lat: 48.474238, lng: 20.326988 },
    { lat: 48.474315, lng: 20.326971 },
    { lat: 48.47456, lng: 20.326882 },
    { lat: 48.474603, lng: 20.326776 },
    { lat: 48.474635, lng: 20.326755 },
    { lat: 48.47468, lng: 20.32672 },
    { lat: 48.47472, lng: 20.326678 },
    { lat: 48.474752, lng: 20.326644 },
    { lat: 48.474795, lng: 20.32659 },
    { lat: 48.474822, lng: 20.326522 },
    { lat: 48.474835, lng: 20.326453 },
    { lat: 48.474823, lng: 20.326301 },
    { lat: 48.474786, lng: 20.326219 },
    { lat: 48.474766, lng: 20.32594 },
    { lat: 48.474632, lng: 20.325543 },
    { lat: 48.474424, lng: 20.325128 },
    { lat: 48.474408, lng: 20.324906 },
    { lat: 48.474424, lng: 20.324854 },
    { lat: 48.474394, lng: 20.324739 },
    { lat: 48.474362, lng: 20.324721 },
    { lat: 48.47434, lng: 20.324704 },
    { lat: 48.474354, lng: 20.324565 },
    { lat: 48.474245, lng: 20.324196 },
    { lat: 48.474252, lng: 20.323845 },
    { lat: 48.474212, lng: 20.323588 },
    { lat: 48.474255, lng: 20.323397 },
    { lat: 48.474237, lng: 20.32337 },
    { lat: 48.474211, lng: 20.323128 },
    { lat: 48.474168, lng: 20.322941 },
    { lat: 48.474278, lng: 20.322721 },
    { lat: 48.474187, lng: 20.322522 },
    { lat: 48.47416, lng: 20.322297 },
    { lat: 48.474161, lng: 20.322006 },
    { lat: 48.474269, lng: 20.32189 },
    { lat: 48.474363, lng: 20.321805 },
    { lat: 48.474362, lng: 20.321722 },
    { lat: 48.474234, lng: 20.321557 },
    { lat: 48.474195, lng: 20.321363 },
    { lat: 48.474188, lng: 20.321313 },
    { lat: 48.474173, lng: 20.321079 },
    { lat: 48.474222, lng: 20.32093 },
    { lat: 48.474216, lng: 20.320811 },
    { lat: 48.474113, lng: 20.32071 },
    { lat: 48.474071, lng: 20.320511 },
    { lat: 48.474151, lng: 20.320203 },
    { lat: 48.474174, lng: 20.320111 },
    { lat: 48.474282, lng: 20.32 },
    { lat: 48.474319, lng: 20.319942 },
    { lat: 48.474327, lng: 20.319777 },
    { lat: 48.474326, lng: 20.319715 },
    { lat: 48.474321, lng: 20.31967 },
    { lat: 48.474371, lng: 20.319596 },
    { lat: 48.474386, lng: 20.319569 },
    { lat: 48.474416, lng: 20.319201 },
    { lat: 48.474411, lng: 20.319169 },
    { lat: 48.474385, lng: 20.319057 },
    { lat: 48.474389, lng: 20.318945 },
    { lat: 48.474417, lng: 20.318826 },
    { lat: 48.474427, lng: 20.318796 },
    { lat: 48.474509, lng: 20.318648 },
    { lat: 48.4746, lng: 20.318487 },
    { lat: 48.47469, lng: 20.318442 },
    { lat: 48.474738, lng: 20.31834 },
    { lat: 48.47476, lng: 20.318268 },
    { lat: 48.474683, lng: 20.318026 },
    { lat: 48.474686, lng: 20.317782 },
    { lat: 48.474701, lng: 20.317583 },
    { lat: 48.474782, lng: 20.317435 },
    { lat: 48.474827, lng: 20.317249 },
    { lat: 48.474844, lng: 20.317197 },
    { lat: 48.474906, lng: 20.317084 },
    { lat: 48.474888, lng: 20.316992 },
    { lat: 48.47484, lng: 20.316872 },
    { lat: 48.474942, lng: 20.316669 },
    { lat: 48.474972, lng: 20.316684 },
    { lat: 48.475017, lng: 20.316436 },
    { lat: 48.475046, lng: 20.316386 },
    { lat: 48.475077, lng: 20.316328 },
    { lat: 48.475131, lng: 20.316042 },
    { lat: 48.475116, lng: 20.31583 },
    { lat: 48.475165, lng: 20.315704 },
    { lat: 48.475297, lng: 20.315549 },
    { lat: 48.475336, lng: 20.315381 },
    { lat: 48.475358, lng: 20.315338 },
    { lat: 48.475556, lng: 20.31496 },
    { lat: 48.475576, lng: 20.314821 },
    { lat: 48.47562, lng: 20.314762 },
    { lat: 48.475672, lng: 20.314693 },
    { lat: 48.475741, lng: 20.31454 },
    { lat: 48.475912, lng: 20.314159 },
    { lat: 48.475953, lng: 20.314117 },
    { lat: 48.475973, lng: 20.314088 },
    { lat: 48.476044, lng: 20.314017 },
    { lat: 48.476055, lng: 20.313985 },
    { lat: 48.476063, lng: 20.31395 },
    { lat: 48.476082, lng: 20.313861 },
    { lat: 48.476186, lng: 20.313676 },
    { lat: 48.476324, lng: 20.313479 },
    { lat: 48.476394, lng: 20.313308 },
    { lat: 48.476514, lng: 20.313084 },
    { lat: 48.476672, lng: 20.312647 },
    { lat: 48.476838, lng: 20.31202 },
    { lat: 48.477205, lng: 20.31175 },
    { lat: 48.477457, lng: 20.311451 },
    { lat: 48.477706, lng: 20.311091 },
    { lat: 48.477834, lng: 20.310976 },
    { lat: 48.477907, lng: 20.310956 },
    { lat: 48.478322, lng: 20.309886 },
    { lat: 48.478434, lng: 20.308642 },
    { lat: 48.478573, lng: 20.308499 },
    { lat: 48.478942, lng: 20.307805 },
    { lat: 48.47909, lng: 20.30753 },
    { lat: 48.479252, lng: 20.307226 },
    { lat: 48.479373, lng: 20.307002 },
    { lat: 48.47947, lng: 20.306811 },
    { lat: 48.47955, lng: 20.306662 },
    { lat: 48.479572, lng: 20.30662 },
    { lat: 48.479673, lng: 20.306424 },
    { lat: 48.4791997, lng: 20.3056912 },
    { lat: 48.4789054, lng: 20.3055651 },
    { lat: 48.4771789, lng: 20.3056496 },
    { lat: 48.4750459, lng: 20.3059118 },
    { lat: 48.4741652, lng: 20.3059085 },
    { lat: 48.4737765, lng: 20.3047325 },
    { lat: 48.473459, lng: 20.3030451 },
    { lat: 48.4722696, lng: 20.300212 },
    { lat: 48.4710036, lng: 20.29832 },
    { lat: 48.4695441, lng: 20.2969535 },
    { lat: 48.4690453, lng: 20.2964103 },
    { lat: 48.4683184, lng: 20.2959733 },
    { lat: 48.4674786, lng: 20.2958696 },
    { lat: 48.4672144, lng: 20.2959617 },
    { lat: 48.4666749, lng: 20.2958781 },
    { lat: 48.4663088, lng: 20.2959909 },
    { lat: 48.4655903, lng: 20.2966753 },
    { lat: 48.4646539, lng: 20.2974362 },
    { lat: 48.4641596, lng: 20.2977492 },
    { lat: 48.4640846, lng: 20.2977332 },
    { lat: 48.4639472, lng: 20.297936 },
    { lat: 48.4635993, lng: 20.2980774 },
    { lat: 48.4623131, lng: 20.2983044 },
    { lat: 48.4620076, lng: 20.2985006 },
    { lat: 48.4618721, lng: 20.2987206 },
    { lat: 48.461768, lng: 20.2988448 },
    { lat: 48.4611605, lng: 20.2990951 },
    { lat: 48.4608348, lng: 20.2988857 },
    { lat: 48.460344, lng: 20.2987204 },
    { lat: 48.459829, lng: 20.2984555 },
    { lat: 48.4593756, lng: 20.2983518 },
    { lat: 48.4589362, lng: 20.2984361 },
    { lat: 48.4586883, lng: 20.298345 },
    { lat: 48.4579423, lng: 20.2969067 },
    { lat: 48.4577364, lng: 20.2963946 },
    { lat: 48.4573358, lng: 20.29458 },
    { lat: 48.4569952, lng: 20.2935094 },
    { lat: 48.4568605, lng: 20.293212 },
    { lat: 48.4566384, lng: 20.2929175 },
    { lat: 48.4559063, lng: 20.2920845 },
    { lat: 48.4552856, lng: 20.2921391 },
    { lat: 48.4548456, lng: 20.292436 },
    { lat: 48.4544761, lng: 20.2924295 },
    { lat: 48.453929, lng: 20.2921257 },
    { lat: 48.453045, lng: 20.292443 },
    { lat: 48.4525627, lng: 20.2928635 },
    { lat: 48.4520824, lng: 20.2931901 },
    { lat: 48.4507735, lng: 20.2934693 },
    { lat: 48.4502684, lng: 20.293625 },
    { lat: 48.4498672, lng: 20.2938758 },
    { lat: 48.4488962, lng: 20.2940709 },
    { lat: 48.4487306, lng: 20.2942393 },
    { lat: 48.4484885, lng: 20.295125 },
    { lat: 48.4484186, lng: 20.2952136 },
    { lat: 48.4482241, lng: 20.2957023 },
    { lat: 48.4481146, lng: 20.2963244 },
    { lat: 48.4477349, lng: 20.2971805 },
    { lat: 48.4475866, lng: 20.2983881 },
    { lat: 48.447292, lng: 20.2992529 },
    { lat: 48.4470921, lng: 20.2994981 },
    { lat: 48.4469319, lng: 20.2995479 },
    { lat: 48.4464831, lng: 20.2994782 },
    { lat: 48.4458703, lng: 20.2998463 },
    { lat: 48.4454531, lng: 20.2998895 },
    { lat: 48.445015, lng: 20.3000367 },
    { lat: 48.4441386, lng: 20.3006858 },
    { lat: 48.4435694, lng: 20.3009302 },
    { lat: 48.443037, lng: 20.3013688 },
    { lat: 48.4424093, lng: 20.3021007 },
    { lat: 48.4415915, lng: 20.3025548 },
    { lat: 48.4413758, lng: 20.302824 },
    { lat: 48.4411439, lng: 20.302999 },
    { lat: 48.4410762, lng: 20.3032206 },
    { lat: 48.4407556, lng: 20.303805 },
    { lat: 48.4406783, lng: 20.304042 },
    { lat: 48.4405033, lng: 20.3053451 },
    { lat: 48.4403715, lng: 20.3057743 },
    { lat: 48.4399686, lng: 20.3064141 },
    { lat: 48.4395955, lng: 20.3067193 },
    { lat: 48.4386194, lng: 20.3070976 },
    { lat: 48.4380773, lng: 20.3076597 },
    { lat: 48.4379233, lng: 20.3079582 },
    { lat: 48.4375095, lng: 20.3081698 },
    { lat: 48.4364111, lng: 20.3081323 },
    { lat: 48.4358749, lng: 20.3079874 },
    { lat: 48.4356889, lng: 20.3082085 },
    { lat: 48.4354477, lng: 20.3083778 },
    { lat: 48.4348735, lng: 20.3086112 },
    { lat: 48.4345967, lng: 20.308618 },
    { lat: 48.4331451, lng: 20.3071061 },
    { lat: 48.4326026, lng: 20.3066532 },
    { lat: 48.4322776, lng: 20.3072978 },
    { lat: 48.4306303, lng: 20.306559 },
    { lat: 48.4292991, lng: 20.3060759 },
    { lat: 48.4290339, lng: 20.3059148 },
    { lat: 48.4281251, lng: 20.3044314 },
    { lat: 48.4278637, lng: 20.3047968 },
    { lat: 48.4276528, lng: 20.3043082 },
    { lat: 48.4272579, lng: 20.304433 },
    { lat: 48.4269392, lng: 20.3048606 },
    { lat: 48.4228849, lng: 20.305093 },
    { lat: 48.4215951, lng: 20.3046823 },
    { lat: 48.4188533, lng: 20.3044126 },
    { lat: 48.4188075, lng: 20.3044486 },
    { lat: 48.418792, lng: 20.3044883 },
    { lat: 48.4184997, lng: 20.3058973 },
    { lat: 48.4184978, lng: 20.3067373 },
    { lat: 48.4183197, lng: 20.3080738 },
    { lat: 48.4183297, lng: 20.3086157 },
    { lat: 48.4183998, lng: 20.3089941 },
    { lat: 48.4186257, lng: 20.3095005 },
    { lat: 48.4189007, lng: 20.3103344 },
    { lat: 48.4191463, lng: 20.3107342 },
    { lat: 48.4236241, lng: 20.3137422 },
    { lat: 48.4240994, lng: 20.3140074 },
    { lat: 48.4241404, lng: 20.3137584 },
    { lat: 48.424029, lng: 20.3138186 },
    { lat: 48.4237879, lng: 20.3137301 },
    { lat: 48.423906, lng: 20.3130658 },
    { lat: 48.4242457, lng: 20.3131704 },
    { lat: 48.4241873, lng: 20.313488 },
    { lat: 48.4254637, lng: 20.3139874 },
    { lat: 48.4254397, lng: 20.3141222 },
    { lat: 48.4252367, lng: 20.3141699 },
    { lat: 48.4257738, lng: 20.3143677 },
    { lat: 48.4255223, lng: 20.3151906 },
    { lat: 48.4255157, lng: 20.3155706 },
    { lat: 48.4258063, lng: 20.3159444 },
    { lat: 48.42669, lng: 20.3183687 },
    { lat: 48.4302619, lng: 20.3215414 },
    { lat: 48.4302608, lng: 20.3220057 },
    { lat: 48.4308826, lng: 20.3225517 },
    { lat: 48.4309525, lng: 20.3227572 },
    { lat: 48.4310537, lng: 20.3228659 },
    { lat: 48.4310671, lng: 20.3230371 },
    { lat: 48.4311903, lng: 20.3231347 },
    { lat: 48.4309059, lng: 20.323839 },
    { lat: 48.4308185, lng: 20.3242751 },
    { lat: 48.4309201, lng: 20.3243388 },
    { lat: 48.431044, lng: 20.3239101 },
    { lat: 48.4313541, lng: 20.3236645 },
    { lat: 48.4318035, lng: 20.3237815 },
    { lat: 48.4318233, lng: 20.324568 },
    { lat: 48.4319535, lng: 20.324964 },
    { lat: 48.4320882, lng: 20.3248896 },
    { lat: 48.4320726, lng: 20.3245538 },
    { lat: 48.4326087, lng: 20.3248985 },
    { lat: 48.4327535, lng: 20.3247718 },
    { lat: 48.4329115, lng: 20.3247497 },
    { lat: 48.4329288, lng: 20.3245297 },
    { lat: 48.4334844, lng: 20.3245125 },
    { lat: 48.4335697, lng: 20.3243893 },
    { lat: 48.433826, lng: 20.3243478 },
    { lat: 48.4338525, lng: 20.3241086 },
    { lat: 48.4340953, lng: 20.3237523 },
    { lat: 48.4342853, lng: 20.3237713 },
    { lat: 48.4343462, lng: 20.3238312 },
    { lat: 48.4343624, lng: 20.3242453 },
    { lat: 48.4344066, lng: 20.3243677 },
    { lat: 48.4343731, lng: 20.3245409 },
    { lat: 48.4344156, lng: 20.324619 },
    { lat: 48.4346543, lng: 20.3245617 },
    { lat: 48.4346673, lng: 20.3243531 },
    { lat: 48.434738, lng: 20.3242305 },
    { lat: 48.4350076, lng: 20.3242677 },
    { lat: 48.4350641, lng: 20.32433 },
    { lat: 48.4350052, lng: 20.3246952 },
    { lat: 48.4351112, lng: 20.3248713 },
    { lat: 48.4352498, lng: 20.3249629 },
    { lat: 48.4353144, lng: 20.3249423 },
    { lat: 48.4354686, lng: 20.324163 },
    { lat: 48.4355846, lng: 20.3239907 },
    { lat: 48.4357194, lng: 20.3239564 },
    { lat: 48.4358664, lng: 20.3237987 },
    { lat: 48.4358919, lng: 20.3236131 },
    { lat: 48.4359994, lng: 20.3234549 },
    { lat: 48.4361383, lng: 20.322977 },
    { lat: 48.4404976, lng: 20.3229711 },
    { lat: 48.4404084, lng: 20.3233681 },
    { lat: 48.4404749, lng: 20.3235101 },
    { lat: 48.4405986, lng: 20.3235651 },
    { lat: 48.4407264, lng: 20.3235828 },
    { lat: 48.4408052, lng: 20.3235205 },
    { lat: 48.4408825, lng: 20.3234215 },
    { lat: 48.4409307, lng: 20.3232179 },
    { lat: 48.4409968, lng: 20.3232081 },
    { lat: 48.4411443, lng: 20.3233529 },
    { lat: 48.4411963, lng: 20.3234987 },
    { lat: 48.4412482, lng: 20.3234735 },
    { lat: 48.4413453, lng: 20.3231929 },
    { lat: 48.4414965, lng: 20.3231138 },
    { lat: 48.4415426, lng: 20.3231708 },
    { lat: 48.4415264, lng: 20.3234421 },
    { lat: 48.4416219, lng: 20.3235855 },
    { lat: 48.4418894, lng: 20.3234266 },
    { lat: 48.4421333, lng: 20.3235551 },
    { lat: 48.4421749, lng: 20.3234957 },
    { lat: 48.4421187, lng: 20.3233865 },
    { lat: 48.4420983, lng: 20.3230957 },
    { lat: 48.4421409, lng: 20.3230039 },
    { lat: 48.4425072, lng: 20.3227799 },
    { lat: 48.4425686, lng: 20.322595 },
    { lat: 48.4427523, lng: 20.3225135 },
    { lat: 48.4428564, lng: 20.3225568 },
    { lat: 48.4429438, lng: 20.3226842 },
    { lat: 48.4430307, lng: 20.3229208 },
    { lat: 48.4431139, lng: 20.3229489 },
    { lat: 48.4436328, lng: 20.3235141 },
    { lat: 48.4436177, lng: 20.323707 },
    { lat: 48.4436896, lng: 20.3238719 },
    { lat: 48.4436874, lng: 20.3239839 },
    { lat: 48.4436082, lng: 20.3241796 },
    { lat: 48.4436085, lng: 20.3243023 },
    { lat: 48.4434389, lng: 20.3241946 },
    { lat: 48.4432917, lng: 20.3243779 },
    { lat: 48.4431564, lng: 20.3244051 },
    { lat: 48.443084, lng: 20.3247687 },
    { lat: 48.4431247, lng: 20.3248623 },
    { lat: 48.443193, lng: 20.3249048 },
    { lat: 48.4432522, lng: 20.3248393 },
    { lat: 48.443332, lng: 20.3249486 },
    { lat: 48.4433146, lng: 20.3251051 },
    { lat: 48.4433991, lng: 20.3254488 },
    { lat: 48.443509, lng: 20.3256409 },
    { lat: 48.443843, lng: 20.3257149 },
    { lat: 48.4439854, lng: 20.3258736 },
    { lat: 48.4443032, lng: 20.3259916 },
    { lat: 48.4443443, lng: 20.3257467 },
    { lat: 48.4445435, lng: 20.3256575 },
    { lat: 48.4446538, lng: 20.3256817 },
    { lat: 48.4447089, lng: 20.3257687 },
    { lat: 48.4446134, lng: 20.326061 },
    { lat: 48.4449847, lng: 20.32637 },
    { lat: 48.4453153, lng: 20.3265554 },
    { lat: 48.4455309, lng: 20.3263958 },
    { lat: 48.4458123, lng: 20.3259658 },
    { lat: 48.4460754, lng: 20.3257545 },
    { lat: 48.4464411, lng: 20.3256022 },
    { lat: 48.4466226, lng: 20.325777 },
    { lat: 48.4467203, lng: 20.3257712 },
    { lat: 48.4469681, lng: 20.3260852 },
    { lat: 48.4470493, lng: 20.3260606 },
    { lat: 48.447159, lng: 20.3258434 },
    { lat: 48.4477326, lng: 20.3259966 },
    { lat: 48.4480864, lng: 20.3264612 },
    { lat: 48.4481738, lng: 20.3267347 },
    { lat: 48.448383, lng: 20.3269617 },
    { lat: 48.4486713, lng: 20.3275076 },
    { lat: 48.4485892, lng: 20.3277366 },
    { lat: 48.4487098, lng: 20.3285184 },
    { lat: 48.4486882, lng: 20.328686 },
    { lat: 48.4487985, lng: 20.3289111 },
    { lat: 48.4487388, lng: 20.3291707 },
    { lat: 48.4487961, lng: 20.3292536 },
    { lat: 48.4487993, lng: 20.3294803 },
    { lat: 48.4489573, lng: 20.3297168 },
    { lat: 48.4484348, lng: 20.332912 },
    { lat: 48.447726, lng: 20.3350345 },
    { lat: 48.4474625, lng: 20.33725 },
    { lat: 48.4474557, lng: 20.3377415 },
    { lat: 48.4473837, lng: 20.3383211 },
    { lat: 48.4474255, lng: 20.3390144 },
    { lat: 48.4474741, lng: 20.3392758 },
    { lat: 48.4475518, lng: 20.3394212 },
    { lat: 48.4476027, lng: 20.3398909 },
    { lat: 48.4474714, lng: 20.3403058 },
    { lat: 48.4474901, lng: 20.3404649 },
    { lat: 48.4473301, lng: 20.3408376 },
    { lat: 48.4473265, lng: 20.3411501 },
    { lat: 48.4474061, lng: 20.3414297 },
    { lat: 48.4473722, lng: 20.3416253 },
    { lat: 48.4473017, lng: 20.3417234 },
    { lat: 48.4473086, lng: 20.3422772 },
    { lat: 48.4471496, lng: 20.3427609 },
    { lat: 48.447264, lng: 20.3435273 },
    { lat: 48.4472381, lng: 20.3440779 },
    { lat: 48.4472734, lng: 20.3441727 },
    { lat: 48.4475965, lng: 20.3443741 },
    { lat: 48.4476683, lng: 20.3444845 },
    { lat: 48.4476948, lng: 20.344728 },
    { lat: 48.4478946, lng: 20.3450587 },
    { lat: 48.447869, lng: 20.3453813 },
    { lat: 48.4479329, lng: 20.3456055 },
    { lat: 48.4480192, lng: 20.3456816 },
    { lat: 48.4480732, lng: 20.3461688 },
    { lat: 48.448025, lng: 20.3466101 },
    { lat: 48.4484195, lng: 20.3482656 },
    { lat: 48.4482133, lng: 20.3490113 },
    { lat: 48.4481976, lng: 20.3506026 },
    { lat: 48.4481354, lng: 20.3508595 },
    { lat: 48.4479647, lng: 20.351075 },
    { lat: 48.4478872, lng: 20.3515979 },
    { lat: 48.4479195, lng: 20.351792 },
    { lat: 48.4478956, lng: 20.3519831 },
    { lat: 48.4479648, lng: 20.3522892 },
    { lat: 48.4478628, lng: 20.35308 },
    { lat: 48.4479985, lng: 20.3535288 },
    { lat: 48.447906, lng: 20.3538151 },
    { lat: 48.4479693, lng: 20.354166 },
    { lat: 48.4479323, lng: 20.3543743 },
    { lat: 48.4480987, lng: 20.3547147 },
    { lat: 48.4480186, lng: 20.3549126 },
    { lat: 48.4480826, lng: 20.3551375 },
    { lat: 48.447973, lng: 20.3552444 },
    { lat: 48.4480134, lng: 20.3553295 },
    { lat: 48.447991, lng: 20.3557664 },
    { lat: 48.4480231, lng: 20.3558955 },
    { lat: 48.4479893, lng: 20.3563295 },
    { lat: 48.4480244, lng: 20.3565938 },
    { lat: 48.4480911, lng: 20.3566596 },
    { lat: 48.4480817, lng: 20.35694 },
    { lat: 48.4481117, lng: 20.3569267 },
    { lat: 48.4485461, lng: 20.3566586 },
    { lat: 48.4490791, lng: 20.3566093 },
    { lat: 48.4488932, lng: 20.3583762 },
    { lat: 48.4529385, lng: 20.3672712 },
    { lat: 48.4531399, lng: 20.3675008 },
    { lat: 48.4547639, lng: 20.368898 },
    { lat: 48.4550224, lng: 20.3692035 },
    { lat: 48.4551578, lng: 20.3694396 },
    { lat: 48.4552675, lng: 20.3697741 },
    { lat: 48.4558656, lng: 20.3719851 },
    { lat: 48.4562944, lng: 20.3748219 },
    { lat: 48.4568882, lng: 20.3775046 },
    { lat: 48.4575845, lng: 20.3802194 },
    { lat: 48.4577362, lng: 20.3812326 },
    { lat: 48.4577271, lng: 20.3819539 },
    { lat: 48.4578405, lng: 20.3836658 },
    { lat: 48.4579869, lng: 20.3849181 },
    { lat: 48.4580816, lng: 20.3873682 },
    { lat: 48.4580967, lng: 20.3880778 },
    { lat: 48.4579361, lng: 20.388849 },
    { lat: 48.4579473, lng: 20.3903611 },
    { lat: 48.4581185, lng: 20.3909149 },
    { lat: 48.458998, lng: 20.39311 },
    { lat: 48.459044, lng: 20.393113 },
    { lat: 48.459072, lng: 20.393115 },
    { lat: 48.459259, lng: 20.393127 },
    { lat: 48.459409, lng: 20.39309 },
    { lat: 48.459645, lng: 20.392896 },
    { lat: 48.459927, lng: 20.392687 },
    { lat: 48.460016, lng: 20.392642 },
    { lat: 48.460243, lng: 20.392525 },
    { lat: 48.460446, lng: 20.392523 },
    { lat: 48.46063, lng: 20.392461 },
    { lat: 48.460691, lng: 20.392438 },
    { lat: 48.460734, lng: 20.392432 },
    { lat: 48.460784, lng: 20.392421 },
    { lat: 48.460813, lng: 20.392418 },
    { lat: 48.460907, lng: 20.392377 },
    { lat: 48.460973, lng: 20.392343 },
    { lat: 48.461072, lng: 20.392327 },
    { lat: 48.461182, lng: 20.392307 },
    { lat: 48.461259, lng: 20.392297 },
    { lat: 48.461444, lng: 20.392249 },
    { lat: 48.46156, lng: 20.392197 },
    { lat: 48.461598, lng: 20.392188 },
    { lat: 48.461682, lng: 20.392168 },
    { lat: 48.462094, lng: 20.392105 },
    { lat: 48.462386, lng: 20.391767 },
    { lat: 48.462823, lng: 20.39127 },
    { lat: 48.46291, lng: 20.391172 },
    { lat: 48.463089, lng: 20.390967 },
    { lat: 48.463316, lng: 20.390605 },
    { lat: 48.463995, lng: 20.3895 },
    { lat: 48.464242, lng: 20.389117 },
    { lat: 48.4647, lng: 20.388421 },
    { lat: 48.464948, lng: 20.388041 },
    { lat: 48.465084, lng: 20.387833 },
    { lat: 48.465529, lng: 20.386753 },
    { lat: 48.465576, lng: 20.386636 },
    { lat: 48.465595, lng: 20.386541 },
    { lat: 48.465605, lng: 20.38645 },
    { lat: 48.465594, lng: 20.386371 },
    { lat: 48.465579, lng: 20.386271 },
    { lat: 48.465573, lng: 20.38621 },
    { lat: 48.465584, lng: 20.386066 },
    { lat: 48.465597, lng: 20.385988 },
    { lat: 48.465747, lng: 20.385725 },
    { lat: 48.465774, lng: 20.385674 },
    { lat: 48.465789, lng: 20.385591 },
    { lat: 48.465805, lng: 20.385505 },
    { lat: 48.465863, lng: 20.385463 },
    { lat: 48.465893, lng: 20.385394 },
    { lat: 48.465877, lng: 20.385302 },
    { lat: 48.465846, lng: 20.385071 },
    { lat: 48.465909, lng: 20.384898 },
    { lat: 48.466001, lng: 20.38469 },
    { lat: 48.466149, lng: 20.384571 },
    { lat: 48.466202, lng: 20.384472 },
    { lat: 48.46633, lng: 20.384423 },
    { lat: 48.466398, lng: 20.384298 },
    { lat: 48.466541, lng: 20.384254 },
    { lat: 48.466561, lng: 20.384175 },
    { lat: 48.466608, lng: 20.384054 },
    { lat: 48.466652, lng: 20.383977 },
    { lat: 48.466702, lng: 20.383939 },
    { lat: 48.466674, lng: 20.383816 },
    { lat: 48.466779, lng: 20.383709 },
    { lat: 48.466803, lng: 20.383679 },
    { lat: 48.466816, lng: 20.383562 },
    { lat: 48.466826, lng: 20.383497 },
    { lat: 48.46694, lng: 20.383199 },
    { lat: 48.467037, lng: 20.382974 },
    { lat: 48.467111, lng: 20.382924 },
    { lat: 48.467341, lng: 20.382454 },
    { lat: 48.467389, lng: 20.382351 },
    { lat: 48.467485, lng: 20.382061 },
    { lat: 48.467501, lng: 20.381983 },
    { lat: 48.46752, lng: 20.381853 },
    { lat: 48.467554, lng: 20.381828 },
    { lat: 48.467651, lng: 20.381733 },
    { lat: 48.467823, lng: 20.38155 },
    { lat: 48.467844, lng: 20.38152 },
    { lat: 48.467901, lng: 20.38133 },
    { lat: 48.467822, lng: 20.381063 },
    { lat: 48.467858, lng: 20.380983 },
    { lat: 48.467937, lng: 20.380832 },
    { lat: 48.468024, lng: 20.380709 },
    { lat: 48.468073, lng: 20.380315 },
    { lat: 48.46811, lng: 20.380202 },
    { lat: 48.468161, lng: 20.380033 },
    { lat: 48.468067, lng: 20.379907 },
    { lat: 48.468007, lng: 20.379632 },
    { lat: 48.468068, lng: 20.379443 },
    { lat: 48.46803, lng: 20.379219 },
    { lat: 48.468077, lng: 20.37906 },
    { lat: 48.467994, lng: 20.378896 },
    { lat: 48.468011, lng: 20.378861 },
    { lat: 48.467949, lng: 20.378766 },
    { lat: 48.467963, lng: 20.378636 },
    { lat: 48.467941, lng: 20.378566 },
    { lat: 48.467889, lng: 20.378486 },
    { lat: 48.467828, lng: 20.378381 },
    { lat: 48.467803, lng: 20.378201 },
    { lat: 48.467695, lng: 20.378096 },
    { lat: 48.46767, lng: 20.377882 },
    { lat: 48.467547, lng: 20.377751 },
    { lat: 48.467436, lng: 20.377521 },
    { lat: 48.467266, lng: 20.377211 },
    { lat: 48.467174, lng: 20.377086 },
    { lat: 48.467067, lng: 20.37692 },
    { lat: 48.466935, lng: 20.37662 },
    { lat: 48.466838, lng: 20.376425 },
    { lat: 48.466779, lng: 20.376315 },
    { lat: 48.466554, lng: 20.376159 },
    { lat: 48.466487, lng: 20.376004 },
    { lat: 48.466457, lng: 20.375959 },
    { lat: 48.466477, lng: 20.375919 },
    { lat: 48.466403, lng: 20.375819 },
    { lat: 48.466272, lng: 20.375778 },
    { lat: 48.466031, lng: 20.375412 },
    { lat: 48.466009, lng: 20.375332 },
    { lat: 48.46598, lng: 20.375268 },
    { lat: 48.465918, lng: 20.375183 },
    { lat: 48.465743, lng: 20.374937 },
    { lat: 48.465678, lng: 20.374867 },
    { lat: 48.465674, lng: 20.374827 },
    { lat: 48.465626, lng: 20.374771 },
    { lat: 48.465489, lng: 20.374686 },
    { lat: 48.465368, lng: 20.374585 },
    { lat: 48.46533, lng: 20.374445 },
    { lat: 48.465175, lng: 20.374324 },
    { lat: 48.465186, lng: 20.374225 },
    { lat: 48.465086, lng: 20.37398 },
    { lat: 48.465028, lng: 20.37382 },
    { lat: 48.464959, lng: 20.373805 },
    { lat: 48.464879, lng: 20.373649 },
    { lat: 48.464836, lng: 20.373569 },
    { lat: 48.464746, lng: 20.373454 },
    { lat: 48.464704, lng: 20.373294 },
    { lat: 48.464618, lng: 20.373134 },
    { lat: 48.464589, lng: 20.373085 },
    { lat: 48.464523, lng: 20.372964 },
    { lat: 48.464549, lng: 20.372625 },
    { lat: 48.464514, lng: 20.372556 },
    { lat: 48.464413, lng: 20.372734 },
    { lat: 48.464379, lng: 20.372574 },
    { lat: 48.464387, lng: 20.372502 },
    { lat: 48.464393, lng: 20.37242 },
    { lat: 48.464494, lng: 20.372261 },
    { lat: 48.464551, lng: 20.372078 },
    { lat: 48.464217, lng: 20.371891 },
    { lat: 48.463778, lng: 20.371738 },
    { lat: 48.463698, lng: 20.371711 },
    { lat: 48.463665, lng: 20.371688 },
    { lat: 48.463536, lng: 20.371557 },
    { lat: 48.463485, lng: 20.371502 },
    { lat: 48.463466, lng: 20.371481 },
    { lat: 48.46338, lng: 20.371396 },
    { lat: 48.463342, lng: 20.371359 },
    { lat: 48.463278, lng: 20.371293 },
    { lat: 48.463217, lng: 20.371227 },
    { lat: 48.463184, lng: 20.371193 },
    { lat: 48.46311, lng: 20.371117 },
    { lat: 48.46304, lng: 20.371048 },
    { lat: 48.462964, lng: 20.370979 },
    { lat: 48.46288, lng: 20.3709 },
    { lat: 48.46284, lng: 20.370866 },
    { lat: 48.462788, lng: 20.370825 },
    { lat: 48.462745, lng: 20.370787 },
    { lat: 48.462703, lng: 20.370756 },
    { lat: 48.46266, lng: 20.370718 },
    { lat: 48.462609, lng: 20.37068 },
    { lat: 48.462572, lng: 20.370648 },
    { lat: 48.462528, lng: 20.370617 },
    { lat: 48.462505, lng: 20.370598 },
    { lat: 48.462458, lng: 20.370563 },
    { lat: 48.462415, lng: 20.370532 },
    { lat: 48.462364, lng: 20.370491 },
    { lat: 48.462316, lng: 20.370457 },
    { lat: 48.462274, lng: 20.370422 },
    { lat: 48.462142, lng: 20.370324 },
    { lat: 48.462117, lng: 20.370302 },
    { lat: 48.462019, lng: 20.37023 },
    { lat: 48.461963, lng: 20.370192 },
    { lat: 48.461945, lng: 20.37017 },
    { lat: 48.461921, lng: 20.370154 },
    { lat: 48.461814, lng: 20.370082 },
    { lat: 48.461514, lng: 20.36985 },
    { lat: 48.461268, lng: 20.369632 },
    { lat: 48.461699, lng: 20.367476 },
    { lat: 48.461723, lng: 20.367351 },
    { lat: 48.461738, lng: 20.367277 },
    { lat: 48.461756, lng: 20.367183 },
    { lat: 48.461771, lng: 20.367109 },
    { lat: 48.461798, lng: 20.366979 },
    { lat: 48.461813, lng: 20.366901 },
    { lat: 48.461825, lng: 20.366839 },
    { lat: 48.461852, lng: 20.366711 },
    { lat: 48.461903, lng: 20.36643 },
    { lat: 48.46193, lng: 20.3663 },
    { lat: 48.461941, lng: 20.366238 },
    { lat: 48.461952, lng: 20.366176 },
    { lat: 48.461981, lng: 20.366045 },
    { lat: 48.461994, lng: 20.365995 },
    { lat: 48.462005, lng: 20.365921 },
    { lat: 48.462035, lng: 20.365793 },
    { lat: 48.462066, lng: 20.36565 },
    { lat: 48.462092, lng: 20.365536 },
    { lat: 48.462114, lng: 20.365429 },
    { lat: 48.462182, lng: 20.36511 },
    { lat: 48.462308, lng: 20.364656 },
    { lat: 48.462445, lng: 20.364275 },
    { lat: 48.462524, lng: 20.364086 },
    { lat: 48.462659, lng: 20.363744 },
    { lat: 48.462787, lng: 20.363422 },
    { lat: 48.462849, lng: 20.36327 },
    { lat: 48.462975, lng: 20.362953 },
    { lat: 48.463095, lng: 20.362658 },
    { lat: 48.463196, lng: 20.362391 },
    { lat: 48.463216, lng: 20.362183 },
    { lat: 48.463183, lng: 20.362012 },
    { lat: 48.463161, lng: 20.361912 },
    { lat: 48.463141, lng: 20.361824 },
    { lat: 48.463111, lng: 20.361684 },
    { lat: 48.463093, lng: 20.361597 },
    { lat: 48.463077, lng: 20.361513 },
    { lat: 48.46306, lng: 20.361432 },
    { lat: 48.463044, lng: 20.361357 },
    { lat: 48.463027, lng: 20.361275 },
    { lat: 48.463009, lng: 20.361191 },
    { lat: 48.462977, lng: 20.361045 },
    { lat: 48.462958, lng: 20.36097 },
    { lat: 48.462936, lng: 20.360886 },
    { lat: 48.462916, lng: 20.360808 },
    { lat: 48.462877, lng: 20.360654 },
    { lat: 48.462858, lng: 20.360589 },
    { lat: 48.462838, lng: 20.360511 },
    { lat: 48.4628, lng: 20.360377 },
    { lat: 48.462761, lng: 20.360233 },
    { lat: 48.462742, lng: 20.360159 },
    { lat: 48.462727, lng: 20.360099 },
    { lat: 48.462715, lng: 20.360049 },
    { lat: 48.462694, lng: 20.359949 },
    { lat: 48.462662, lng: 20.359803 },
    { lat: 48.462634, lng: 20.35966 },
    { lat: 48.462616, lng: 20.359585 },
    { lat: 48.4626, lng: 20.359513 },
    { lat: 48.462565, lng: 20.35935 },
    { lat: 48.462549, lng: 20.359279 },
    { lat: 48.462526, lng: 20.359167 },
    { lat: 48.462514, lng: 20.359113 },
    { lat: 48.462502, lng: 20.359066 },
    { lat: 48.462488, lng: 20.359013 },
    { lat: 48.462472, lng: 20.358946 },
    { lat: 48.462452, lng: 20.358867 },
    { lat: 48.462439, lng: 20.358808 },
    { lat: 48.462423, lng: 20.35874 },
    { lat: 48.462407, lng: 20.358655 },
    { lat: 48.462393, lng: 20.358586 },
    { lat: 48.462375, lng: 20.358502 },
    { lat: 48.462349, lng: 20.358387 },
    { lat: 48.462338, lng: 20.358309 },
    { lat: 48.462324, lng: 20.358218 },
    { lat: 48.462293, lng: 20.357988 },
    { lat: 48.46226, lng: 20.357782 },
    { lat: 48.462237, lng: 20.357642 },
    { lat: 48.462213, lng: 20.357506 },
    { lat: 48.462204, lng: 20.35745 },
    { lat: 48.462202, lng: 20.357439 },
    { lat: 48.461971, lng: 20.356182 },
    { lat: 48.461879, lng: 20.355686 },
    { lat: 48.461826, lng: 20.355413 },
    { lat: 48.461753, lng: 20.355049 },
    { lat: 48.461726, lng: 20.354918 },
    { lat: 48.461696, lng: 20.354768 },
    { lat: 48.461912, lng: 20.354683 },
    { lat: 48.461888, lng: 20.354387 },
    { lat: 48.461872, lng: 20.354207 },
    { lat: 48.461849, lng: 20.353944 },
    { lat: 48.461846, lng: 20.353909 },
    { lat: 48.461816, lng: 20.353295 },
    { lat: 48.461792, lng: 20.352842 },
    { lat: 48.461783, lng: 20.352665 },
    { lat: 48.461763, lng: 20.352271 },
    { lat: 48.461705, lng: 20.351172 },
    { lat: 48.461644, lng: 20.350023 },
    { lat: 48.461643, lng: 20.349943 },
    { lat: 48.461582, lng: 20.348982 },
    { lat: 48.461569, lng: 20.3487897 },
    { lat: 48.461559, lng: 20.348642 },
    { lat: 48.461558, lng: 20.348622 },
    { lat: 48.461556, lng: 20.348596 },
    { lat: 48.461549, lng: 20.348479 },
    { lat: 48.461546, lng: 20.348419 },
    { lat: 48.461541, lng: 20.348351 },
    { lat: 48.461532, lng: 20.348219 },
    { lat: 48.461507, lng: 20.347825 },
    { lat: 48.461426, lng: 20.346661 },
    { lat: 48.461424, lng: 20.346633 },
    { lat: 48.461414, lng: 20.346487 },
    { lat: 48.461411, lng: 20.346452 },
    { lat: 48.461375, lng: 20.345867 },
    { lat: 48.461355, lng: 20.345512 },
    { lat: 48.46129, lng: 20.344472 },
    { lat: 48.461263, lng: 20.344111 },
    { lat: 48.461172, lng: 20.34294 },
    { lat: 48.461114, lng: 20.342218 },
    { lat: 48.461095, lng: 20.341975 },
    { lat: 48.461073, lng: 20.341705 },
    { lat: 48.46108, lng: 20.341661 },
    { lat: 48.461128, lng: 20.341228 },
    { lat: 48.461135, lng: 20.341157 },
    { lat: 48.461197, lng: 20.340543 },
    { lat: 48.461228, lng: 20.340225 },
    { lat: 48.46125, lng: 20.340178 },
    { lat: 48.461288, lng: 20.340067 },
    { lat: 48.46138, lng: 20.339808 },
    { lat: 48.461472, lng: 20.339558 },
    { lat: 48.461486, lng: 20.339518 },
    { lat: 48.461537, lng: 20.339381 },
    { lat: 48.461558, lng: 20.339325 },
    { lat: 48.461645, lng: 20.339087 },
    { lat: 48.461661, lng: 20.339044 },
    { lat: 48.461681, lng: 20.338988 },
    { lat: 48.461702, lng: 20.338926 },
    { lat: 48.461755, lng: 20.338779 },
    { lat: 48.461784, lng: 20.338695 },
    { lat: 48.461806, lng: 20.338634 },
    { lat: 48.461846, lng: 20.338521 },
    { lat: 48.461942, lng: 20.338246 },
    { lat: 48.461995, lng: 20.338107 },
    { lat: 48.4621, lng: 20.337803 },
    { lat: 48.462129, lng: 20.337719 },
    { lat: 48.462201, lng: 20.337519 },
    { lat: 48.462299, lng: 20.337245 },
    { lat: 48.462403, lng: 20.336952 },
    { lat: 48.4626, lng: 20.336405 },
    { lat: 48.462768, lng: 20.335929 },
    { lat: 48.462901, lng: 20.335553 },
    { lat: 48.463283, lng: 20.334478 },
    { lat: 48.463308, lng: 20.334462 },
    { lat: 48.463633, lng: 20.334701 },
    { lat: 48.463741, lng: 20.334782 },
    { lat: 48.463831, lng: 20.334849 },
    { lat: 48.464313, lng: 20.335206 },
    { lat: 48.464681, lng: 20.335476 },
    { lat: 48.465063, lng: 20.335761 },
    { lat: 48.46537, lng: 20.33599 },
    { lat: 48.46564, lng: 20.336197 },
    { lat: 48.465972, lng: 20.336453 },
    { lat: 48.466275, lng: 20.336684 },
    { lat: 48.466348, lng: 20.336743 },
    { lat: 48.466583, lng: 20.336915 },
    { lat: 48.466646, lng: 20.336969 },
    { lat: 48.466914, lng: 20.337171 },
    { lat: 48.467218, lng: 20.337403 },
    { lat: 48.4678, lng: 20.337846 },
    { lat: 48.468174, lng: 20.338134 },
    { lat: 48.468349, lng: 20.338269 },
    { lat: 48.468452, lng: 20.338349 },
    { lat: 48.46864, lng: 20.33849 },
    { lat: 48.468946, lng: 20.338724 },
    { lat: 48.469306, lng: 20.338994 },
    { lat: 48.469714, lng: 20.339299 },
    { lat: 48.470202, lng: 20.339668 },
    { lat: 48.470603, lng: 20.339971 },
    { lat: 48.471724, lng: 20.3408 },
    { lat: 48.471891, lng: 20.340925 },
    { lat: 48.472728, lng: 20.341563 },
    { lat: 48.47278, lng: 20.341602 },
    { lat: 48.473079, lng: 20.340605 },
    { lat: 48.473265, lng: 20.340065 },
    { lat: 48.473596, lng: 20.339113 },
    { lat: 48.473733, lng: 20.338808 },
    { lat: 48.473885, lng: 20.338465 },
    { lat: 48.473953, lng: 20.338311 },
    { lat: 48.474092, lng: 20.338 },
    { lat: 48.474309, lng: 20.337539 },
    { lat: 48.474459, lng: 20.337221 },
    { lat: 48.474529, lng: 20.337014 },
    { lat: 48.474888, lng: 20.336367 },
    { lat: 48.474915, lng: 20.336283 },
    { lat: 48.47493, lng: 20.336234 },
  ],
  Sirk: [
    { lat: 48.6384476, lng: 20.0752601 },
    { lat: 48.6384107, lng: 20.0752946 },
    { lat: 48.6381613, lng: 20.0755302 },
    { lat: 48.6378828, lng: 20.0755756 },
    { lat: 48.6375748, lng: 20.0755368 },
    { lat: 48.6370458, lng: 20.0752998 },
    { lat: 48.6364987, lng: 20.0753878 },
    { lat: 48.6356633, lng: 20.0753156 },
    { lat: 48.6354786, lng: 20.0754283 },
    { lat: 48.6345632, lng: 20.0753793 },
    { lat: 48.6343357, lng: 20.0754612 },
    { lat: 48.6338497, lng: 20.0759419 },
    { lat: 48.6330622, lng: 20.0774384 },
    { lat: 48.6326618, lng: 20.0778659 },
    { lat: 48.6321584, lng: 20.0782517 },
    { lat: 48.6312845, lng: 20.0791523 },
    { lat: 48.6310053, lng: 20.0793198 },
    { lat: 48.6297904, lng: 20.0806198 },
    { lat: 48.6293268, lng: 20.0808758 },
    { lat: 48.6289793, lng: 20.0809703 },
    { lat: 48.6287334, lng: 20.0811782 },
    { lat: 48.6280692, lng: 20.0815164 },
    { lat: 48.6278443, lng: 20.081719 },
    { lat: 48.6273467, lng: 20.0831211 },
    { lat: 48.626939, lng: 20.0839686 },
    { lat: 48.6267991, lng: 20.0840732 },
    { lat: 48.6260406, lng: 20.0841962 },
    { lat: 48.6255159, lng: 20.0844487 },
    { lat: 48.6252099, lng: 20.0844862 },
    { lat: 48.6247745, lng: 20.0847445 },
    { lat: 48.6244681, lng: 20.0846579 },
    { lat: 48.6240396, lng: 20.0842643 },
    { lat: 48.6237915, lng: 20.0844773 },
    { lat: 48.6235692, lng: 20.084386 },
    { lat: 48.6234338, lng: 20.084461 },
    { lat: 48.6230247, lng: 20.0844836 },
    { lat: 48.6229267, lng: 20.0847296 },
    { lat: 48.6228046, lng: 20.0847737 },
    { lat: 48.6217614, lng: 20.0849334 },
    { lat: 48.6215998, lng: 20.0851368 },
    { lat: 48.6208636, lng: 20.0856134 },
    { lat: 48.6200365, lng: 20.085715 },
    { lat: 48.6194556, lng: 20.086161 },
    { lat: 48.6189291, lng: 20.0861515 },
    { lat: 48.6185992, lng: 20.0864223 },
    { lat: 48.6184013, lng: 20.0864301 },
    { lat: 48.6179789, lng: 20.0868168 },
    { lat: 48.6172489, lng: 20.0867316 },
    { lat: 48.6167434, lng: 20.0869584 },
    { lat: 48.6161493, lng: 20.0875198 },
    { lat: 48.6155147, lng: 20.0883299 },
    { lat: 48.6154203, lng: 20.088663 },
    { lat: 48.6152496, lng: 20.0889207 },
    { lat: 48.6150205, lng: 20.08883 },
    { lat: 48.6130788, lng: 20.091295 },
    { lat: 48.6123146, lng: 20.0918875 },
    { lat: 48.611928, lng: 20.0926191 },
    { lat: 48.6118101, lng: 20.0929601 },
    { lat: 48.6114659, lng: 20.0934632 },
    { lat: 48.6113026, lng: 20.0940376 },
    { lat: 48.6111505, lng: 20.0943127 },
    { lat: 48.6105894, lng: 20.0957593 },
    { lat: 48.6104059, lng: 20.0961181 },
    { lat: 48.6102745, lng: 20.0962393 },
    { lat: 48.6101068, lng: 20.0969262 },
    { lat: 48.6098685, lng: 20.0974073 },
    { lat: 48.609836, lng: 20.0977641 },
    { lat: 48.6100245, lng: 20.0981369 },
    { lat: 48.6100393, lng: 20.098962 },
    { lat: 48.6099907, lng: 20.0994812 },
    { lat: 48.6097974, lng: 20.0998199 },
    { lat: 48.6096646, lng: 20.0999598 },
    { lat: 48.609222, lng: 20.0999875 },
    { lat: 48.6085993, lng: 20.099773 },
    { lat: 48.608208, lng: 20.0998714 },
    { lat: 48.6077714, lng: 20.1004816 },
    { lat: 48.6077379, lng: 20.1007129 },
    { lat: 48.6072621, lng: 20.1011904 },
    { lat: 48.6063745, lng: 20.1018494 },
    { lat: 48.6059436, lng: 20.1019205 },
    { lat: 48.604694, lng: 20.1019079 },
    { lat: 48.6043745, lng: 20.1019722 },
    { lat: 48.6037702, lng: 20.1022876 },
    { lat: 48.6037153, lng: 20.1023166 },
    { lat: 48.6028036, lng: 20.102993 },
    { lat: 48.6016468, lng: 20.104366 },
    { lat: 48.6014985, lng: 20.1048438 },
    { lat: 48.6003992, lng: 20.1067394 },
    { lat: 48.6001835, lng: 20.1073285 },
    { lat: 48.6001108, lng: 20.1077253 },
    { lat: 48.5997202, lng: 20.1089986 },
    { lat: 48.5994423, lng: 20.1096373 },
    { lat: 48.5990151, lng: 20.1102361 },
    { lat: 48.5982634, lng: 20.1107718 },
    { lat: 48.5977963, lng: 20.1114412 },
    { lat: 48.5976095, lng: 20.111918 },
    { lat: 48.5975197, lng: 20.1123629 },
    { lat: 48.5972989, lng: 20.1128286 },
    { lat: 48.5965138, lng: 20.1137955 },
    { lat: 48.596236, lng: 20.1143764 },
    { lat: 48.5960609, lng: 20.1151691 },
    { lat: 48.5956628, lng: 20.1158982 },
    { lat: 48.5957541, lng: 20.1160637 },
    { lat: 48.596806, lng: 20.1166391 },
    { lat: 48.597037, lng: 20.1170543 },
    { lat: 48.5971996, lng: 20.1171217 },
    { lat: 48.5973365, lng: 20.1173004 },
    { lat: 48.5980207, lng: 20.1176991 },
    { lat: 48.5985025, lng: 20.1176667 },
    { lat: 48.5989089, lng: 20.1177169 },
    { lat: 48.5992187, lng: 20.1176052 },
    { lat: 48.5997571, lng: 20.1175537 },
    { lat: 48.6002413, lng: 20.1173842 },
    { lat: 48.6010503, lng: 20.1169503 },
    { lat: 48.6015194, lng: 20.1168843 },
    { lat: 48.60206, lng: 20.1171564 },
    { lat: 48.6022651, lng: 20.117353 },
    { lat: 48.6028948, lng: 20.1184265 },
    { lat: 48.6040819, lng: 20.117571 },
    { lat: 48.6043014, lng: 20.1174833 },
    { lat: 48.6045674, lng: 20.1174914 },
    { lat: 48.6049796, lng: 20.1178221 },
    { lat: 48.6053685, lng: 20.1184333 },
    { lat: 48.6055687, lng: 20.1186478 },
    { lat: 48.6056781, lng: 20.1186786 },
    { lat: 48.6059552, lng: 20.1185703 },
    { lat: 48.6066144, lng: 20.1186852 },
    { lat: 48.607008, lng: 20.1186194 },
    { lat: 48.6072867, lng: 20.1186587 },
    { lat: 48.6076078, lng: 20.1187705 },
    { lat: 48.6077845, lng: 20.1189302 },
    { lat: 48.6080754, lng: 20.1189391 },
    { lat: 48.6087923, lng: 20.1192847 },
    { lat: 48.6093828, lng: 20.1193184 },
    { lat: 48.609605, lng: 20.1191871 },
    { lat: 48.6102672, lng: 20.1189668 },
    { lat: 48.610689, lng: 20.119007 },
    { lat: 48.6108691, lng: 20.1188693 },
    { lat: 48.6116685, lng: 20.1184999 },
    { lat: 48.6124441, lng: 20.1183798 },
    { lat: 48.6133192, lng: 20.1179895 },
    { lat: 48.6138525, lng: 20.1175377 },
    { lat: 48.6140972, lng: 20.117489 },
    { lat: 48.6141913, lng: 20.1176735 },
    { lat: 48.614217, lng: 20.1180253 },
    { lat: 48.6141483, lng: 20.1189322 },
    { lat: 48.6142066, lng: 20.1193703 },
    { lat: 48.6143812, lng: 20.120333 },
    { lat: 48.6146688, lng: 20.1210587 },
    { lat: 48.6148732, lng: 20.1211796 },
    { lat: 48.6150127, lng: 20.1211805 },
    { lat: 48.6157071, lng: 20.1216906 },
    { lat: 48.6159609, lng: 20.1217841 },
    { lat: 48.6164443, lng: 20.1217342 },
    { lat: 48.6167508, lng: 20.1221771 },
    { lat: 48.6170037, lng: 20.1229648 },
    { lat: 48.6172447, lng: 20.1232667 },
    { lat: 48.6174251, lng: 20.1236038 },
    { lat: 48.617552, lng: 20.1237284 },
    { lat: 48.6176895, lng: 20.1237331 },
    { lat: 48.617982, lng: 20.1236126 },
    { lat: 48.6180972, lng: 20.1236244 },
    { lat: 48.6185217, lng: 20.1244219 },
    { lat: 48.6186172, lng: 20.12447 },
    { lat: 48.6187305, lng: 20.1244011 },
    { lat: 48.6189656, lng: 20.1241183 },
    { lat: 48.6193988, lng: 20.1241112 },
    { lat: 48.6194577, lng: 20.1241712 },
    { lat: 48.6195299, lng: 20.1244546 },
    { lat: 48.6195815, lng: 20.1250831 },
    { lat: 48.6198577, lng: 20.1256684 },
    { lat: 48.6204276, lng: 20.1264702 },
    { lat: 48.6207471, lng: 20.1263178 },
    { lat: 48.6210955, lng: 20.1266614 },
    { lat: 48.621118, lng: 20.1266632 },
    { lat: 48.6214282, lng: 20.1266415 },
    { lat: 48.6248339, lng: 20.1262511 },
    { lat: 48.6267465, lng: 20.1270246 },
    { lat: 48.6294442, lng: 20.1243228 },
    { lat: 48.6284105, lng: 20.1231448 },
    { lat: 48.6283298, lng: 20.1226296 },
    { lat: 48.6283707, lng: 20.1222509 },
    { lat: 48.6281288, lng: 20.121379 },
    { lat: 48.6276947, lng: 20.1210052 },
    { lat: 48.6276266, lng: 20.120882 },
    { lat: 48.6273391, lng: 20.1209276 },
    { lat: 48.627284, lng: 20.1207931 },
    { lat: 48.6275555, lng: 20.1203549 },
    { lat: 48.6277446, lng: 20.1202404 },
    { lat: 48.6285663, lng: 20.1205614 },
    { lat: 48.6287527, lng: 20.1207396 },
    { lat: 48.6290708, lng: 20.1212174 },
    { lat: 48.629218, lng: 20.1215384 },
    { lat: 48.629273, lng: 20.1219492 },
    { lat: 48.6293675, lng: 20.1220341 },
    { lat: 48.6301169, lng: 20.1214325 },
    { lat: 48.6302446, lng: 20.1215552 },
    { lat: 48.6304758, lng: 20.1215045 },
    { lat: 48.6307588, lng: 20.1213486 },
    { lat: 48.6309101, lng: 20.1209967 },
    { lat: 48.6311288, lng: 20.1207809 },
    { lat: 48.6310771, lng: 20.1205094 },
    { lat: 48.6315302, lng: 20.1202137 },
    { lat: 48.6315719, lng: 20.1201874 },
    { lat: 48.6319493, lng: 20.1205472 },
    { lat: 48.6319241, lng: 20.1203979 },
    { lat: 48.632125, lng: 20.1200808 },
    { lat: 48.6323863, lng: 20.1199422 },
    { lat: 48.6325656, lng: 20.1193697 },
    { lat: 48.6336615, lng: 20.1187587 },
    { lat: 48.634449, lng: 20.1184903 },
    { lat: 48.6346513, lng: 20.1187788 },
    { lat: 48.6349078, lng: 20.1185238 },
    { lat: 48.6351824, lng: 20.1180233 },
    { lat: 48.6356807, lng: 20.1175678 },
    { lat: 48.6358602, lng: 20.11719 },
    { lat: 48.6360394, lng: 20.1164237 },
    { lat: 48.6362344, lng: 20.116235 },
    { lat: 48.6365094, lng: 20.1161247 },
    { lat: 48.6368024, lng: 20.1159076 },
    { lat: 48.6371969, lng: 20.115997 },
    { lat: 48.6374217, lng: 20.1163194 },
    { lat: 48.6377766, lng: 20.1165406 },
    { lat: 48.638092, lng: 20.1168738 },
    { lat: 48.6386415, lng: 20.1172557 },
    { lat: 48.64013, lng: 20.1173007 },
    { lat: 48.641355, lng: 20.1182541 },
    { lat: 48.6422639, lng: 20.1181728 },
    { lat: 48.6428074, lng: 20.1183224 },
    { lat: 48.643086, lng: 20.1184988 },
    { lat: 48.6433716, lng: 20.1184876 },
    { lat: 48.6436751, lng: 20.1180882 },
    { lat: 48.6438839, lng: 20.1179011 },
    { lat: 48.6445065, lng: 20.1179518 },
    { lat: 48.6448782, lng: 20.1175407 },
    { lat: 48.6451535, lng: 20.1175208 },
    { lat: 48.6454147, lng: 20.1173804 },
    { lat: 48.6476841, lng: 20.1147312 },
    { lat: 48.6494886, lng: 20.1146513 },
    { lat: 48.6502354, lng: 20.1150409 },
    { lat: 48.6504143, lng: 20.1150627 },
    { lat: 48.6508396, lng: 20.1149708 },
    { lat: 48.6514184, lng: 20.1144403 },
    { lat: 48.6515974, lng: 20.1143855 },
    { lat: 48.6520503, lng: 20.1140569 },
    { lat: 48.6525611, lng: 20.1132716 },
    { lat: 48.6527602, lng: 20.1132413 },
    { lat: 48.6528914, lng: 20.113419 },
    { lat: 48.6531037, lng: 20.1134854 },
    { lat: 48.6533318, lng: 20.1134988 },
    { lat: 48.6535078, lng: 20.1133702 },
    { lat: 48.6539929, lng: 20.1132509 },
    { lat: 48.6550978, lng: 20.1134964 },
    { lat: 48.6557235, lng: 20.1129601 },
    { lat: 48.656905, lng: 20.1125479 },
    { lat: 48.6573098, lng: 20.1125615 },
    { lat: 48.6576533, lng: 20.1124483 },
    { lat: 48.657629, lng: 20.1122917 },
    { lat: 48.6576247, lng: 20.1119675 },
    { lat: 48.6578285, lng: 20.1107291 },
    { lat: 48.6577945, lng: 20.1097892 },
    { lat: 48.6578727, lng: 20.1092479 },
    { lat: 48.6579211, lng: 20.1084233 },
    { lat: 48.6578926, lng: 20.1076172 },
    { lat: 48.6577807, lng: 20.1070573 },
    { lat: 48.6577958, lng: 20.1056618 },
    { lat: 48.6579004, lng: 20.1049132 },
    { lat: 48.6579891, lng: 20.1048269 },
    { lat: 48.658019, lng: 20.1047621 },
    { lat: 48.658226, lng: 20.104259 },
    { lat: 48.6585218, lng: 20.1038349 },
    { lat: 48.6586877, lng: 20.1032643 },
    { lat: 48.6586966, lng: 20.1027654 },
    { lat: 48.658613, lng: 20.1022926 },
    { lat: 48.6588386, lng: 20.1010652 },
    { lat: 48.6592394, lng: 20.1000459 },
    { lat: 48.6597253, lng: 20.0995335 },
    { lat: 48.6599626, lng: 20.0991668 },
    { lat: 48.6602584, lng: 20.0989671 },
    { lat: 48.6611026, lng: 20.0978536 },
    { lat: 48.6612115, lng: 20.0974604 },
    { lat: 48.6616076, lng: 20.09704 },
    { lat: 48.6618645, lng: 20.0969344 },
    { lat: 48.6621386, lng: 20.0965846 },
    { lat: 48.6624421, lng: 20.0960485 },
    { lat: 48.6626657, lng: 20.0952106 },
    { lat: 48.6629328, lng: 20.0948004 },
    { lat: 48.6638458, lng: 20.0939957 },
    { lat: 48.6641369, lng: 20.0936236 },
    { lat: 48.6649361, lng: 20.0915271 },
    { lat: 48.6655509, lng: 20.0895205 },
    { lat: 48.6658053, lng: 20.088995 },
    { lat: 48.6665539, lng: 20.0882173 },
    { lat: 48.6672195, lng: 20.0877473 },
    { lat: 48.6675771, lng: 20.086937 },
    { lat: 48.66777, lng: 20.0860962 },
    { lat: 48.6676996, lng: 20.085342 },
    { lat: 48.6677479, lng: 20.083481 },
    { lat: 48.6676534, lng: 20.0819747 },
    { lat: 48.6668512, lng: 20.0801061 },
    { lat: 48.6666291, lng: 20.0798308 },
    { lat: 48.6662206, lng: 20.0797277 },
    { lat: 48.6657298, lng: 20.0796526 },
    { lat: 48.6651001, lng: 20.0797031 },
    { lat: 48.6642756, lng: 20.0792948 },
    { lat: 48.6639924, lng: 20.0792836 },
    { lat: 48.6638291, lng: 20.0790381 },
    { lat: 48.6623554, lng: 20.0777682 },
    { lat: 48.6614639, lng: 20.0775668 },
    { lat: 48.6606803, lng: 20.0763965 },
    { lat: 48.6603559, lng: 20.0760675 },
    { lat: 48.660103, lng: 20.0756405 },
    { lat: 48.6588046, lng: 20.0759801 },
    { lat: 48.6582647, lng: 20.0763261 },
    { lat: 48.6581595, lng: 20.0762597 },
    { lat: 48.6575578, lng: 20.0765841 },
    { lat: 48.657184, lng: 20.0766363 },
    { lat: 48.6564394, lng: 20.0765576 },
    { lat: 48.6553106, lng: 20.0760868 },
    { lat: 48.6544546, lng: 20.0761166 },
    { lat: 48.6536363, lng: 20.076266 },
    { lat: 48.6529089, lng: 20.0762153 },
    { lat: 48.6523077, lng: 20.0757331 },
    { lat: 48.6516772, lng: 20.074968 },
    { lat: 48.6512172, lng: 20.0747127 },
    { lat: 48.6505831, lng: 20.0744975 },
    { lat: 48.6503269, lng: 20.0743228 },
    { lat: 48.6500504, lng: 20.0740346 },
    { lat: 48.6494069, lng: 20.0732572 },
    { lat: 48.6492545, lng: 20.0728986 },
    { lat: 48.6483338, lng: 20.0712179 },
    { lat: 48.6477195, lng: 20.0706886 },
    { lat: 48.6473247, lng: 20.070216 },
    { lat: 48.6467208, lng: 20.0704006 },
    { lat: 48.6463155, lng: 20.070703 },
    { lat: 48.6459623, lng: 20.0710731 },
    { lat: 48.6448076, lng: 20.0718309 },
    { lat: 48.6445935, lng: 20.0718321 },
    { lat: 48.6444252, lng: 20.071625 },
    { lat: 48.6442941, lng: 20.0715596 },
    { lat: 48.6438387, lng: 20.071523 },
    { lat: 48.6433371, lng: 20.0718721 },
    { lat: 48.6424257, lng: 20.0722758 },
    { lat: 48.6419818, lng: 20.0727107 },
    { lat: 48.641746, lng: 20.0735484 },
    { lat: 48.6414125, lng: 20.0742564 },
    { lat: 48.6399014, lng: 20.0753897 },
    { lat: 48.6394254, lng: 20.0756726 },
    { lat: 48.63921, lng: 20.0759087 },
    { lat: 48.6389641, lng: 20.0760642 },
    { lat: 48.6386892, lng: 20.0761474 },
    { lat: 48.6384894, lng: 20.0757256 },
    { lat: 48.6384173, lng: 20.0753958 },
    { lat: 48.6384476, lng: 20.0752601 },
  ],
  RevúckaLehota: [
    { lat: 48.6593495, lng: 20.1755223 },
    { lat: 48.66067, lng: 20.1769037 },
    { lat: 48.6607028, lng: 20.1772951 },
    { lat: 48.6617538, lng: 20.1779186 },
    { lat: 48.663059, lng: 20.1784186 },
    { lat: 48.6641843, lng: 20.1794005 },
    { lat: 48.6637347, lng: 20.1803147 },
    { lat: 48.6641872, lng: 20.1806166 },
    { lat: 48.6645859, lng: 20.1811519 },
    { lat: 48.665012, lng: 20.1814124 },
    { lat: 48.6653816, lng: 20.1815538 },
    { lat: 48.6658679, lng: 20.1821348 },
    { lat: 48.6659619, lng: 20.1822904 },
    { lat: 48.6660586, lng: 20.1827056 },
    { lat: 48.6665356, lng: 20.1833338 },
    { lat: 48.6670498, lng: 20.1837416 },
    { lat: 48.6667379, lng: 20.1852581 },
    { lat: 48.6666705, lng: 20.1857938 },
    { lat: 48.6662314, lng: 20.186324 },
    { lat: 48.6670388, lng: 20.1869116 },
    { lat: 48.6675808, lng: 20.1873073 },
    { lat: 48.6681676, lng: 20.1878446 },
    { lat: 48.6688976, lng: 20.1886896 },
    { lat: 48.6696737, lng: 20.1893321 },
    { lat: 48.6701049, lng: 20.1897794 },
    { lat: 48.6701322, lng: 20.1897291 },
    { lat: 48.6706182, lng: 20.1902184 },
    { lat: 48.6719431, lng: 20.1912918 },
    { lat: 48.6734048, lng: 20.1929172 },
    { lat: 48.6737825, lng: 20.1934589 },
    { lat: 48.6740887, lng: 20.193625 },
    { lat: 48.6741715, lng: 20.1938077 },
    { lat: 48.6745492, lng: 20.1931691 },
    { lat: 48.6752301, lng: 20.1936638 },
    { lat: 48.6752501, lng: 20.1936035 },
    { lat: 48.6753764, lng: 20.193603 },
    { lat: 48.6758078, lng: 20.193885 },
    { lat: 48.6767659, lng: 20.1943314 },
    { lat: 48.6775367, lng: 20.1942541 },
    { lat: 48.6785223, lng: 20.1938919 },
    { lat: 48.6787758, lng: 20.1936977 },
    { lat: 48.6793546, lng: 20.1926308 },
    { lat: 48.6800515, lng: 20.1929369 },
    { lat: 48.6804725, lng: 20.1930416 },
    { lat: 48.6817331, lng: 20.1928784 },
    { lat: 48.683023, lng: 20.1929467 },
    { lat: 48.6833543, lng: 20.1931444 },
    { lat: 48.6834154, lng: 20.1933203 },
    { lat: 48.6836144, lng: 20.1933694 },
    { lat: 48.6841556, lng: 20.1937176 },
    { lat: 48.6848689, lng: 20.1937835 },
    { lat: 48.6850931, lng: 20.1935363 },
    { lat: 48.6855657, lng: 20.1926297 },
    { lat: 48.6861053, lng: 20.191984 },
    { lat: 48.6862654, lng: 20.191682 },
    { lat: 48.6867175, lng: 20.1911337 },
    { lat: 48.6870649, lng: 20.1904991 },
    { lat: 48.6873766, lng: 20.1902161 },
    { lat: 48.6876084, lng: 20.1899004 },
    { lat: 48.6883891, lng: 20.1886714 },
    { lat: 48.6891582, lng: 20.187996 },
    { lat: 48.6899038, lng: 20.1870992 },
    { lat: 48.6903383, lng: 20.1867575 },
    { lat: 48.6907445, lng: 20.1865833 },
    { lat: 48.6913837, lng: 20.1864573 },
    { lat: 48.692029, lng: 20.1865122 },
    { lat: 48.6925628, lng: 20.1860636 },
    { lat: 48.6934519, lng: 20.1855963 },
    { lat: 48.6938736, lng: 20.1850778 },
    { lat: 48.6941598, lng: 20.1844897 },
    { lat: 48.6945401, lng: 20.1840651 },
    { lat: 48.6946471, lng: 20.1834208 },
    { lat: 48.6946776, lng: 20.1828055 },
    { lat: 48.6949916, lng: 20.1821881 },
    { lat: 48.6960195, lng: 20.1796284 },
    { lat: 48.696226, lng: 20.1774607 },
    { lat: 48.6961673, lng: 20.1767289 },
    { lat: 48.6965679, lng: 20.1752392 },
    { lat: 48.6966696, lng: 20.1750203 },
    { lat: 48.6968598, lng: 20.1739304 },
    { lat: 48.6969115, lng: 20.1732582 },
    { lat: 48.6975813, lng: 20.1727737 },
    { lat: 48.6982437, lng: 20.1720233 },
    { lat: 48.6988106, lng: 20.1715008 },
    { lat: 48.6982123, lng: 20.1707983 },
    { lat: 48.6974908, lng: 20.1698614 },
    { lat: 48.696527, lng: 20.168754 },
    { lat: 48.6949647, lng: 20.1688312 },
    { lat: 48.6941665, lng: 20.1686427 },
    { lat: 48.6918145, lng: 20.1673527 },
    { lat: 48.691699, lng: 20.1672208 },
    { lat: 48.6909894, lng: 20.1657461 },
    { lat: 48.6902154, lng: 20.1646496 },
    { lat: 48.6896874, lng: 20.1644915 },
    { lat: 48.6888156, lng: 20.1638725 },
    { lat: 48.6885099, lng: 20.163556 },
    { lat: 48.68844, lng: 20.1635842 },
    { lat: 48.6881663, lng: 20.1633338 },
    { lat: 48.6875107, lng: 20.1626332 },
    { lat: 48.6873561, lng: 20.1625694 },
    { lat: 48.6872152, lng: 20.1623721 },
    { lat: 48.6869368, lng: 20.1622563 },
    { lat: 48.6866933, lng: 20.1622456 },
    { lat: 48.6866841, lng: 20.1621056 },
    { lat: 48.6863043, lng: 20.1621813 },
    { lat: 48.6856619, lng: 20.1624782 },
    { lat: 48.6853298, lng: 20.1623157 },
    { lat: 48.6849959, lng: 20.1624687 },
    { lat: 48.6849893, lng: 20.1623927 },
    { lat: 48.684872, lng: 20.1623276 },
    { lat: 48.6846678, lng: 20.1624622 },
    { lat: 48.6844832, lng: 20.1624882 },
    { lat: 48.6841895, lng: 20.1623876 },
    { lat: 48.6839393, lng: 20.1624403 },
    { lat: 48.6837403, lng: 20.1623342 },
    { lat: 48.6833901, lng: 20.1620005 },
    { lat: 48.6831793, lng: 20.1619718 },
    { lat: 48.6830945, lng: 20.1620448 },
    { lat: 48.6829771, lng: 20.1620382 },
    { lat: 48.6827997, lng: 20.1619394 },
    { lat: 48.6825775, lng: 20.16157 },
    { lat: 48.6823696, lng: 20.1613603 },
    { lat: 48.6814104, lng: 20.1619534 },
    { lat: 48.6809725, lng: 20.1621257 },
    { lat: 48.6801652, lng: 20.1622735 },
    { lat: 48.6797109, lng: 20.1625378 },
    { lat: 48.6792527, lng: 20.1629375 },
    { lat: 48.6790688, lng: 20.1632645 },
    { lat: 48.6785661, lng: 20.1637827 },
    { lat: 48.6777403, lng: 20.1642209 },
    { lat: 48.6765207, lng: 20.1646152 },
    { lat: 48.6758994, lng: 20.1650961 },
    { lat: 48.6752037, lng: 20.1655248 },
    { lat: 48.6748061, lng: 20.1659716 },
    { lat: 48.6742417, lng: 20.1663589 },
    { lat: 48.6731491, lng: 20.1669409 },
    { lat: 48.6725953, lng: 20.1671437 },
    { lat: 48.6721202, lng: 20.1672288 },
    { lat: 48.6714474, lng: 20.1671372 },
    { lat: 48.6700258, lng: 20.1666046 },
    { lat: 48.6688984, lng: 20.1663531 },
    { lat: 48.6684099, lng: 20.1661088 },
    { lat: 48.6682648, lng: 20.1661403 },
    { lat: 48.6660377, lng: 20.1650969 },
    { lat: 48.6637317, lng: 20.1638018 },
    { lat: 48.6635504, lng: 20.1645315 },
    { lat: 48.6635386, lng: 20.1649038 },
    { lat: 48.6634463, lng: 20.1651328 },
    { lat: 48.663379, lng: 20.1656692 },
    { lat: 48.6634178, lng: 20.1662656 },
    { lat: 48.6633555, lng: 20.1672593 },
    { lat: 48.6631571, lng: 20.1678686 },
    { lat: 48.6629911, lng: 20.1687796 },
    { lat: 48.6626394, lng: 20.1695773 },
    { lat: 48.6624801, lng: 20.1697882 },
    { lat: 48.6619678, lng: 20.1698778 },
    { lat: 48.6616253, lng: 20.1700122 },
    { lat: 48.6613622, lng: 20.1704985 },
    { lat: 48.6608732, lng: 20.1711346 },
    { lat: 48.6602681, lng: 20.1716854 },
    { lat: 48.6601668, lng: 20.1712993 },
    { lat: 48.6598836, lng: 20.1714272 },
    { lat: 48.6596701, lng: 20.1717165 },
    { lat: 48.6594729, lng: 20.1722167 },
    { lat: 48.6594296, lng: 20.1724818 },
    { lat: 48.6596573, lng: 20.1731591 },
    { lat: 48.6596764, lng: 20.1740776 },
    { lat: 48.6593495, lng: 20.1755223 },
  ],
  Rybník: [
    { lat: 48.554101, lng: 20.082528 },
    { lat: 48.553963, lng: 20.082558 },
    { lat: 48.553678, lng: 20.082645 },
    { lat: 48.553488, lng: 20.082637 },
    { lat: 48.553292, lng: 20.082535 },
    { lat: 48.553211, lng: 20.082535 },
    { lat: 48.55305, lng: 20.082571 },
    { lat: 48.552989, lng: 20.082588 },
    { lat: 48.552666, lng: 20.082615 },
    { lat: 48.552506, lng: 20.082679 },
    { lat: 48.552444, lng: 20.082696 },
    { lat: 48.55228, lng: 20.082683 },
    { lat: 48.551939, lng: 20.082604 },
    { lat: 48.551637, lng: 20.082465 },
    { lat: 48.551293, lng: 20.082301 },
    { lat: 48.551041, lng: 20.082249 },
    { lat: 48.550903, lng: 20.082284 },
    { lat: 48.550462, lng: 20.082386 },
    { lat: 48.549884, lng: 20.082536 },
    { lat: 48.549712, lng: 20.082645 },
    { lat: 48.549671, lng: 20.082695 },
    { lat: 48.549575, lng: 20.082889 },
    { lat: 48.549472, lng: 20.083142 },
    { lat: 48.54944, lng: 20.083191 },
    { lat: 48.549394, lng: 20.083223 },
    { lat: 48.549345, lng: 20.083225 },
    { lat: 48.549264, lng: 20.083203 },
    { lat: 48.549189, lng: 20.083157 },
    { lat: 48.548988, lng: 20.083012 },
    { lat: 48.548883, lng: 20.082969 },
    { lat: 48.548746, lng: 20.082942 },
    { lat: 48.548583, lng: 20.082932 },
    { lat: 48.548364, lng: 20.082906 },
    { lat: 48.547952, lng: 20.082816 },
    { lat: 48.547881, lng: 20.082759 },
    { lat: 48.547757, lng: 20.082628 },
    { lat: 48.54767, lng: 20.082493 },
    { lat: 48.547494, lng: 20.082282 },
    { lat: 48.547292, lng: 20.082154 },
    { lat: 48.547208, lng: 20.082123 },
    { lat: 48.547154, lng: 20.082121 },
    { lat: 48.547073, lng: 20.082139 },
    { lat: 48.546995, lng: 20.082179 },
    { lat: 48.546468, lng: 20.082605 },
    { lat: 48.546398, lng: 20.082588 },
    { lat: 48.546258, lng: 20.082578 },
    { lat: 48.546117, lng: 20.082597 },
    { lat: 48.545879, lng: 20.082646 },
    { lat: 48.545452, lng: 20.082905 },
    { lat: 48.545019, lng: 20.083051 },
    { lat: 48.544977, lng: 20.083055 },
    { lat: 48.544692, lng: 20.082853 },
    { lat: 48.544577, lng: 20.082772 },
    { lat: 48.544414, lng: 20.082573 },
    { lat: 48.544347, lng: 20.082556 },
    { lat: 48.544287, lng: 20.082585 },
    { lat: 48.544188, lng: 20.082667 },
    { lat: 48.544088, lng: 20.082767 },
    { lat: 48.543838, lng: 20.082955 },
    { lat: 48.543701, lng: 20.083078 },
    { lat: 48.543478, lng: 20.0834 },
    { lat: 48.543276, lng: 20.083575 },
    { lat: 48.543134, lng: 20.083661 },
    { lat: 48.542848, lng: 20.083798 },
    { lat: 48.542698, lng: 20.083857 },
    { lat: 48.542298, lng: 20.084068 },
    { lat: 48.541962, lng: 20.084291 },
    { lat: 48.541546, lng: 20.084591 },
    { lat: 48.54131, lng: 20.084905 },
    { lat: 48.541186, lng: 20.085214 },
    { lat: 48.541004, lng: 20.085689 },
    { lat: 48.540964, lng: 20.085634 },
    { lat: 48.54072, lng: 20.085162 },
    { lat: 48.540204, lng: 20.084172 },
    { lat: 48.539576, lng: 20.082972 },
    { lat: 48.538968, lng: 20.081792 },
    { lat: 48.538981, lng: 20.081784 },
    { lat: 48.538963, lng: 20.081723 },
    { lat: 48.538942, lng: 20.081715 },
    { lat: 48.538905, lng: 20.081752 },
    { lat: 48.538888, lng: 20.081819 },
    { lat: 48.538855, lng: 20.081825 },
    { lat: 48.538837, lng: 20.081883 },
    { lat: 48.538844, lng: 20.081933 },
    { lat: 48.538827, lng: 20.081982 },
    { lat: 48.538804, lng: 20.081999 },
    { lat: 48.538783, lng: 20.081981 },
    { lat: 48.53876, lng: 20.081994 },
    { lat: 48.538734, lng: 20.082024 },
    { lat: 48.538729, lng: 20.08206 },
    { lat: 48.538694, lng: 20.082063 },
    { lat: 48.538641, lng: 20.082098 },
    { lat: 48.538635, lng: 20.082155 },
    { lat: 48.538601, lng: 20.082194 },
    { lat: 48.538572, lng: 20.082206 },
    { lat: 48.538538, lng: 20.082265 },
    { lat: 48.538489, lng: 20.082294 },
    { lat: 48.53846, lng: 20.08227 },
    { lat: 48.538422, lng: 20.082264 },
    { lat: 48.5384, lng: 20.08229 },
    { lat: 48.538338, lng: 20.082299 },
    { lat: 48.538301, lng: 20.082255 },
    { lat: 48.538227, lng: 20.082241 },
    { lat: 48.538199, lng: 20.082285 },
    { lat: 48.538141, lng: 20.082294 },
    { lat: 48.53813, lng: 20.082327 },
    { lat: 48.538124, lng: 20.082391 },
    { lat: 48.538049, lng: 20.082415 },
    { lat: 48.538012, lng: 20.082452 },
    { lat: 48.537963, lng: 20.08245 },
    { lat: 48.53792, lng: 20.082395 },
    { lat: 48.537862, lng: 20.082383 },
    { lat: 48.537829, lng: 20.082401 },
    { lat: 48.537794, lng: 20.082392 },
    { lat: 48.537763, lng: 20.082347 },
    { lat: 48.537742, lng: 20.082331 },
    { lat: 48.537687, lng: 20.082339 },
    { lat: 48.53761, lng: 20.082421 },
    { lat: 48.537544, lng: 20.082418 },
    { lat: 48.537511, lng: 20.08243 },
    { lat: 48.537457, lng: 20.082474 },
    { lat: 48.537307, lng: 20.082443 },
    { lat: 48.537271, lng: 20.082479 },
    { lat: 48.537229, lng: 20.082474 },
    { lat: 48.537188, lng: 20.082437 },
    { lat: 48.53717, lng: 20.082423 },
    { lat: 48.537109, lng: 20.082444 },
    { lat: 48.537074, lng: 20.082512 },
    { lat: 48.537037, lng: 20.082513 },
    { lat: 48.537006, lng: 20.082486 },
    { lat: 48.536957, lng: 20.082479 },
    { lat: 48.536912, lng: 20.082516 },
    { lat: 48.536867, lng: 20.082508 },
    { lat: 48.536812, lng: 20.082466 },
    { lat: 48.536732, lng: 20.082467 },
    { lat: 48.53668, lng: 20.082502 },
    { lat: 48.536538, lng: 20.082461 },
    { lat: 48.53648, lng: 20.082475 },
    { lat: 48.536418, lng: 20.082476 },
    { lat: 48.536382, lng: 20.082488 },
    { lat: 48.536354, lng: 20.08252 },
    { lat: 48.536287, lng: 20.082554 },
    { lat: 48.536242, lng: 20.08254 },
    { lat: 48.536188, lng: 20.082572 },
    { lat: 48.536155, lng: 20.082595 },
    { lat: 48.536115, lng: 20.082647 },
    { lat: 48.536075, lng: 20.08268 },
    { lat: 48.536034, lng: 20.082681 },
    { lat: 48.535998, lng: 20.082667 },
    { lat: 48.535956, lng: 20.082664 },
    { lat: 48.5359, lng: 20.082694 },
    { lat: 48.535846, lng: 20.082729 },
    { lat: 48.535788, lng: 20.082719 },
    { lat: 48.535763, lng: 20.082671 },
    { lat: 48.535684, lng: 20.082639 },
    { lat: 48.535615, lng: 20.082652 },
    { lat: 48.535561, lng: 20.082624 },
    { lat: 48.53554, lng: 20.082549 },
    { lat: 48.535483, lng: 20.082405 },
    { lat: 48.535448, lng: 20.082396 },
    { lat: 48.535428, lng: 20.082375 },
    { lat: 48.535378, lng: 20.082219 },
    { lat: 48.53535, lng: 20.082173 },
    { lat: 48.535272, lng: 20.08208 },
    { lat: 48.535229, lng: 20.08209 },
    { lat: 48.535195, lng: 20.082093 },
    { lat: 48.535182, lng: 20.082121 },
    { lat: 48.535173, lng: 20.08214 },
    { lat: 48.535169, lng: 20.082167 },
    { lat: 48.535149, lng: 20.082186 },
    { lat: 48.535129, lng: 20.082176 },
    { lat: 48.535107, lng: 20.082128 },
    { lat: 48.535104, lng: 20.082077 },
    { lat: 48.535084, lng: 20.082011 },
    { lat: 48.535072, lng: 20.081988 },
    { lat: 48.535048, lng: 20.081982 },
    { lat: 48.535014, lng: 20.081949 },
    { lat: 48.534967, lng: 20.081916 },
    { lat: 48.534943, lng: 20.081902 },
    { lat: 48.534839, lng: 20.082043 },
    { lat: 48.534791, lng: 20.082101 },
    { lat: 48.534658, lng: 20.082188 },
    { lat: 48.534471, lng: 20.082311 },
    { lat: 48.534328, lng: 20.082438 },
    { lat: 48.533975, lng: 20.082726 },
    { lat: 48.533939, lng: 20.082761 },
    { lat: 48.533875, lng: 20.082861 },
    { lat: 48.533844, lng: 20.082923 },
    { lat: 48.533782, lng: 20.083103 },
    { lat: 48.533361, lng: 20.083733 },
    { lat: 48.533336, lng: 20.083692 },
    { lat: 48.533218, lng: 20.083466 },
    { lat: 48.532871, lng: 20.082572 },
    { lat: 48.532712, lng: 20.082321 },
    { lat: 48.532629, lng: 20.082112 },
    { lat: 48.532478, lng: 20.081741 },
    { lat: 48.532373, lng: 20.081488 },
    { lat: 48.53229, lng: 20.081296 },
    { lat: 48.53219, lng: 20.080804 },
    { lat: 48.532123, lng: 20.080507 },
    { lat: 48.532049, lng: 20.080153 },
    { lat: 48.532008, lng: 20.079988 },
    { lat: 48.531995, lng: 20.079905 },
    { lat: 48.531906, lng: 20.079664 },
    { lat: 48.531791, lng: 20.079342 },
    { lat: 48.531741, lng: 20.079004 },
    { lat: 48.531703, lng: 20.078766 },
    { lat: 48.531613, lng: 20.078581 },
    { lat: 48.531306, lng: 20.077933 },
    { lat: 48.531197, lng: 20.077774 },
    { lat: 48.531035, lng: 20.077563 },
    { lat: 48.530872, lng: 20.077352 },
    { lat: 48.530571, lng: 20.076961 },
    { lat: 48.530453, lng: 20.076834 },
    { lat: 48.530441, lng: 20.076821 },
    { lat: 48.53031, lng: 20.076959 },
    { lat: 48.530141, lng: 20.077135 },
    { lat: 48.530008, lng: 20.077243 },
    { lat: 48.529967, lng: 20.077303 },
    { lat: 48.529906, lng: 20.077397 },
    { lat: 48.529875, lng: 20.077461 },
    { lat: 48.529835, lng: 20.077647 },
    { lat: 48.529788, lng: 20.077849 },
    { lat: 48.529728, lng: 20.078055 },
    { lat: 48.529686, lng: 20.078339 },
    { lat: 48.529687, lng: 20.078486 },
    { lat: 48.52968, lng: 20.078876 },
    { lat: 48.529626, lng: 20.079032 },
    { lat: 48.52957, lng: 20.079208 },
    { lat: 48.529532, lng: 20.079323 },
    { lat: 48.529523, lng: 20.079288 },
    { lat: 48.529502, lng: 20.079362 },
    { lat: 48.529436, lng: 20.079553 },
    { lat: 48.529329, lng: 20.079858 },
    { lat: 48.52924, lng: 20.08011 },
    { lat: 48.52916, lng: 20.08033 },
    { lat: 48.529105, lng: 20.080603 },
    { lat: 48.528993, lng: 20.081169 },
    { lat: 48.528905, lng: 20.081498 },
    { lat: 48.528819, lng: 20.081816 },
    { lat: 48.528742, lng: 20.082098 },
    { lat: 48.528732, lng: 20.082413 },
    { lat: 48.528724, lng: 20.082696 },
    { lat: 48.528733, lng: 20.082754 },
    { lat: 48.528748, lng: 20.082868 },
    { lat: 48.528785, lng: 20.083122 },
    { lat: 48.528825, lng: 20.083379 },
    { lat: 48.528906, lng: 20.083895 },
    { lat: 48.528947, lng: 20.084173 },
    { lat: 48.528887, lng: 20.084264 },
    { lat: 48.528803, lng: 20.084396 },
    { lat: 48.528647, lng: 20.084649 },
    { lat: 48.528439, lng: 20.084475 },
    { lat: 48.52841, lng: 20.084452 },
    { lat: 48.528276, lng: 20.084346 },
    { lat: 48.528106, lng: 20.084217 },
    { lat: 48.528063, lng: 20.084184 },
    { lat: 48.527809, lng: 20.083975 },
    { lat: 48.527712, lng: 20.083933 },
    { lat: 48.52761, lng: 20.083888 },
    { lat: 48.527377, lng: 20.083795 },
    { lat: 48.527264, lng: 20.083831 },
    { lat: 48.527202, lng: 20.083841 },
    { lat: 48.527077, lng: 20.083852 },
    { lat: 48.526921, lng: 20.083876 },
    { lat: 48.526782, lng: 20.083906 },
    { lat: 48.526586, lng: 20.083951 },
    { lat: 48.526468, lng: 20.08402 },
    { lat: 48.526338, lng: 20.084081 },
    { lat: 48.526152, lng: 20.084173 },
    { lat: 48.526073, lng: 20.08421 },
    { lat: 48.52597, lng: 20.084256 },
    { lat: 48.525711, lng: 20.084387 },
    { lat: 48.525563, lng: 20.084462 },
    { lat: 48.525302, lng: 20.084598 },
    { lat: 48.525185, lng: 20.084659 },
    { lat: 48.524816, lng: 20.084565 },
    { lat: 48.524594, lng: 20.08451 },
    { lat: 48.524461, lng: 20.084828 },
    { lat: 48.52438, lng: 20.085023 },
    { lat: 48.524231, lng: 20.085549 },
    { lat: 48.524073, lng: 20.085943 },
    { lat: 48.523939, lng: 20.086269 },
    { lat: 48.523821, lng: 20.086678 },
    { lat: 48.523706, lng: 20.087081 },
    { lat: 48.523564, lng: 20.087456 },
    { lat: 48.52344, lng: 20.087792 },
    { lat: 48.523412, lng: 20.088222 },
    { lat: 48.523373, lng: 20.088863 },
    { lat: 48.523354, lng: 20.089227 },
    { lat: 48.523268, lng: 20.089594 },
    { lat: 48.523175, lng: 20.090005 },
    { lat: 48.523068, lng: 20.090244 },
    { lat: 48.522969, lng: 20.090414 },
    { lat: 48.522842, lng: 20.090592 },
    { lat: 48.522679, lng: 20.090811 },
    { lat: 48.522607, lng: 20.091008 },
    { lat: 48.522546, lng: 20.091193 },
    { lat: 48.522449, lng: 20.091293 },
    { lat: 48.522307, lng: 20.091429 },
    { lat: 48.522299, lng: 20.091498 },
    { lat: 48.522272, lng: 20.091702 },
    { lat: 48.522257, lng: 20.091845 },
    { lat: 48.522126, lng: 20.092131 },
    { lat: 48.522, lng: 20.0924 },
    { lat: 48.521819, lng: 20.092484 },
    { lat: 48.521597, lng: 20.09259 },
    { lat: 48.521408, lng: 20.092679 },
    { lat: 48.521373, lng: 20.092694 },
    { lat: 48.521321, lng: 20.092717 },
    { lat: 48.521195, lng: 20.09274 },
    { lat: 48.521163, lng: 20.092746 },
    { lat: 48.521145, lng: 20.092745 },
    { lat: 48.521096, lng: 20.092757 },
    { lat: 48.521042, lng: 20.092767 },
    { lat: 48.521006, lng: 20.092774 },
    { lat: 48.520934, lng: 20.092786 },
    { lat: 48.520791, lng: 20.092813 },
    { lat: 48.520745, lng: 20.093066 },
    { lat: 48.520636, lng: 20.093722 },
    { lat: 48.520604, lng: 20.093906 },
    { lat: 48.520617, lng: 20.093971 },
    { lat: 48.52064, lng: 20.094086 },
    { lat: 48.520785, lng: 20.094834 },
    { lat: 48.520891, lng: 20.095355 },
    { lat: 48.520981, lng: 20.095785 },
    { lat: 48.521044, lng: 20.096079 },
    { lat: 48.521121, lng: 20.096444 },
    { lat: 48.521177, lng: 20.096762 },
    { lat: 48.521188, lng: 20.096827 },
    { lat: 48.521198, lng: 20.096885 },
    { lat: 48.5212, lng: 20.096898 },
    { lat: 48.52121, lng: 20.096956 },
    { lat: 48.521222, lng: 20.097021 },
    { lat: 48.521267, lng: 20.09728 },
    { lat: 48.52139, lng: 20.097985 },
    { lat: 48.521513, lng: 20.098689 },
    { lat: 48.521555, lng: 20.098936 },
    { lat: 48.521579, lng: 20.099068 },
    { lat: 48.521635, lng: 20.099383 },
    { lat: 48.521685, lng: 20.099668 },
    { lat: 48.521793, lng: 20.100209 },
    { lat: 48.521851, lng: 20.100495 },
    { lat: 48.521891, lng: 20.100688 },
    { lat: 48.521904, lng: 20.100753 },
    { lat: 48.521889, lng: 20.100778 },
    { lat: 48.521803, lng: 20.100923 },
    { lat: 48.521519, lng: 20.101388 },
    { lat: 48.521268, lng: 20.101799 },
    { lat: 48.521086, lng: 20.102151 },
    { lat: 48.520928, lng: 20.102463 },
    { lat: 48.520734, lng: 20.10284 },
    { lat: 48.520613, lng: 20.103073 },
    { lat: 48.520415, lng: 20.103417 },
    { lat: 48.520133, lng: 20.10391 },
    { lat: 48.520015, lng: 20.104113 },
    { lat: 48.519905, lng: 20.10431 },
    { lat: 48.519673, lng: 20.104732 },
    { lat: 48.519441, lng: 20.105149 },
    { lat: 48.519276, lng: 20.105423 },
    { lat: 48.519163, lng: 20.105617 },
    { lat: 48.519082, lng: 20.105741 },
    { lat: 48.518987, lng: 20.105886 },
    { lat: 48.518764, lng: 20.106249 },
    { lat: 48.518593, lng: 20.106528 },
    { lat: 48.518541, lng: 20.106616 },
    { lat: 48.51834, lng: 20.106961 },
    { lat: 48.518261, lng: 20.107098 },
    { lat: 48.518186, lng: 20.107238 },
    { lat: 48.518054, lng: 20.107474 },
    { lat: 48.517954, lng: 20.107647 },
    { lat: 48.517909, lng: 20.107721 },
    { lat: 48.517934, lng: 20.107755 },
    { lat: 48.517975, lng: 20.107799 },
    { lat: 48.518014, lng: 20.107838 },
    { lat: 48.518082, lng: 20.107903 },
    { lat: 48.518134, lng: 20.107923 },
    { lat: 48.518195, lng: 20.107946 },
    { lat: 48.518214, lng: 20.107949 },
    { lat: 48.518309, lng: 20.107949 },
    { lat: 48.518371, lng: 20.107943 },
    { lat: 48.518422, lng: 20.107937 },
    { lat: 48.518496, lng: 20.107921 },
    { lat: 48.518643, lng: 20.107891 },
    { lat: 48.518718, lng: 20.1079 },
    { lat: 48.518782, lng: 20.107906 },
    { lat: 48.518909, lng: 20.107937 },
    { lat: 48.519948, lng: 20.107003 },
    { lat: 48.520176, lng: 20.106832 },
    { lat: 48.520196, lng: 20.106822 },
    { lat: 48.52023, lng: 20.106806 },
    { lat: 48.520638, lng: 20.106613 },
    { lat: 48.520656, lng: 20.10659 },
    { lat: 48.520699, lng: 20.106533 },
    { lat: 48.520715, lng: 20.106511 },
    { lat: 48.520972, lng: 20.107109 },
    { lat: 48.52132, lng: 20.107403 },
    { lat: 48.521559, lng: 20.107689 },
    { lat: 48.521714, lng: 20.10813 },
    { lat: 48.52201, lng: 20.108475 },
    { lat: 48.522351, lng: 20.108751 },
    { lat: 48.522699, lng: 20.108856 },
    { lat: 48.522849, lng: 20.10884 },
    { lat: 48.523094, lng: 20.108725 },
    { lat: 48.523544, lng: 20.108644 },
    { lat: 48.523813, lng: 20.108702 },
    { lat: 48.523932, lng: 20.108783 },
    { lat: 48.524024, lng: 20.108738 },
    { lat: 48.524358, lng: 20.109264 },
    { lat: 48.524427, lng: 20.109456 },
    { lat: 48.524801, lng: 20.109722 },
    { lat: 48.525302, lng: 20.11016 },
    { lat: 48.525802, lng: 20.109906 },
    { lat: 48.526195, lng: 20.109833 },
    { lat: 48.526842, lng: 20.11024 },
    { lat: 48.527586, lng: 20.110631 },
    { lat: 48.527695, lng: 20.1107 },
    { lat: 48.52782, lng: 20.110861 },
    { lat: 48.527963, lng: 20.111217 },
    { lat: 48.528291, lng: 20.111211 },
    { lat: 48.528383, lng: 20.111361 },
    { lat: 48.528569, lng: 20.111872 },
    { lat: 48.528744, lng: 20.112162 },
    { lat: 48.528885, lng: 20.112299 },
    { lat: 48.529028, lng: 20.112503 },
    { lat: 48.529056, lng: 20.112596 },
    { lat: 48.52928, lng: 20.112715 },
    { lat: 48.529794, lng: 20.112696 },
    { lat: 48.530299, lng: 20.112659 },
    { lat: 48.530404, lng: 20.112719 },
    { lat: 48.530802, lng: 20.113038 },
    { lat: 48.530828, lng: 20.113047 },
    { lat: 48.530886, lng: 20.113068 },
    { lat: 48.530908, lng: 20.113076 },
    { lat: 48.531333, lng: 20.112594 },
    { lat: 48.531742, lng: 20.11197 },
    { lat: 48.532087, lng: 20.111348 },
    { lat: 48.53211, lng: 20.111305 },
    { lat: 48.532141, lng: 20.111249 },
    { lat: 48.532162, lng: 20.111212 },
    { lat: 48.532489, lng: 20.111069 },
    { lat: 48.533085, lng: 20.109977 },
    { lat: 48.533102, lng: 20.109951 },
    { lat: 48.533327, lng: 20.110297 },
    { lat: 48.533393, lng: 20.110388 },
    { lat: 48.53344, lng: 20.110421 },
    { lat: 48.533476, lng: 20.1105 },
    { lat: 48.533504, lng: 20.1105 },
    { lat: 48.533726, lng: 20.110664 },
    { lat: 48.533861, lng: 20.110708 },
    { lat: 48.533972, lng: 20.110745 },
    { lat: 48.534271, lng: 20.111007 },
    { lat: 48.53432, lng: 20.111077 },
    { lat: 48.534492, lng: 20.111327 },
    { lat: 48.534822, lng: 20.111806 },
    { lat: 48.535241, lng: 20.112435 },
    { lat: 48.535433, lng: 20.112975 },
    { lat: 48.53564, lng: 20.114366 },
    { lat: 48.535643, lng: 20.114421 },
    { lat: 48.535648, lng: 20.114517 },
    { lat: 48.535649, lng: 20.114542 },
    { lat: 48.535625, lng: 20.114572 },
    { lat: 48.535708, lng: 20.114619 },
    { lat: 48.535779, lng: 20.114706 },
    { lat: 48.535878, lng: 20.114893 },
    { lat: 48.535963, lng: 20.115036 },
    { lat: 48.536392, lng: 20.115662 },
    { lat: 48.536406, lng: 20.115628 },
    { lat: 48.536484, lng: 20.115456 },
    { lat: 48.536671, lng: 20.11563 },
    { lat: 48.536717, lng: 20.11567 },
    { lat: 48.536776, lng: 20.115704 },
    { lat: 48.53681, lng: 20.115718 },
    { lat: 48.536825, lng: 20.115725 },
    { lat: 48.536858, lng: 20.115743 },
    { lat: 48.536944, lng: 20.115788 },
    { lat: 48.536917, lng: 20.115848 },
    { lat: 48.53654, lng: 20.116683 },
    { lat: 48.536525, lng: 20.116715 },
    { lat: 48.536394, lng: 20.117005 },
    { lat: 48.536166, lng: 20.117509 },
    { lat: 48.536159, lng: 20.117525 },
    { lat: 48.536154, lng: 20.117535 },
    { lat: 48.536287, lng: 20.117699 },
    { lat: 48.536511, lng: 20.117924 },
    { lat: 48.536721, lng: 20.117975 },
    { lat: 48.536791, lng: 20.117945 },
    { lat: 48.536857, lng: 20.11795 },
    { lat: 48.536971, lng: 20.117926 },
    { lat: 48.53717, lng: 20.117993 },
    { lat: 48.537257, lng: 20.118015 },
    { lat: 48.537375, lng: 20.118079 },
    { lat: 48.537532, lng: 20.118323 },
    { lat: 48.537693, lng: 20.118489 },
    { lat: 48.537752, lng: 20.118647 },
    { lat: 48.537869, lng: 20.118714 },
    { lat: 48.537977, lng: 20.118794 },
    { lat: 48.538124, lng: 20.118937 },
    { lat: 48.5382, lng: 20.119043 },
    { lat: 48.53828, lng: 20.119086 },
    { lat: 48.538268, lng: 20.119119 },
    { lat: 48.538201, lng: 20.119342 },
    { lat: 48.538484, lng: 20.11957 },
    { lat: 48.539214, lng: 20.119736 },
    { lat: 48.539339, lng: 20.119709 },
    { lat: 48.539523, lng: 20.119668 },
    { lat: 48.539853, lng: 20.120161 },
    { lat: 48.539989, lng: 20.120372 },
    { lat: 48.540053, lng: 20.120754 },
    { lat: 48.540167, lng: 20.121433 },
    { lat: 48.540384, lng: 20.122718 },
    { lat: 48.540666, lng: 20.124425 },
    { lat: 48.540843, lng: 20.125768 },
    { lat: 48.540975, lng: 20.126936 },
    { lat: 48.541044, lng: 20.126984 },
    { lat: 48.542146, lng: 20.12782 },
    { lat: 48.542167, lng: 20.127838 },
    { lat: 48.542194, lng: 20.127863 },
    { lat: 48.542398, lng: 20.128059 },
    { lat: 48.542467, lng: 20.12841 },
    { lat: 48.542649, lng: 20.129137 },
    { lat: 48.54299, lng: 20.128997 },
    { lat: 48.543021, lng: 20.128972 },
    { lat: 48.543002, lng: 20.129272 },
    { lat: 48.542971, lng: 20.129376 },
    { lat: 48.543002, lng: 20.129513 },
    { lat: 48.54303, lng: 20.129542 },
    { lat: 48.543066, lng: 20.129577 },
    { lat: 48.543041, lng: 20.12965 },
    { lat: 48.543026, lng: 20.129686 },
    { lat: 48.542922, lng: 20.129952 },
    { lat: 48.542592, lng: 20.130136 },
    { lat: 48.542575, lng: 20.130142 },
    { lat: 48.542583, lng: 20.130177 },
    { lat: 48.542642, lng: 20.13019 },
    { lat: 48.542685, lng: 20.130269 },
    { lat: 48.542673, lng: 20.130465 },
    { lat: 48.542647, lng: 20.130627 },
    { lat: 48.54267, lng: 20.130732 },
    { lat: 48.542745, lng: 20.130742 },
    { lat: 48.542833, lng: 20.130808 },
    { lat: 48.542844, lng: 20.130885 },
    { lat: 48.542837, lng: 20.130942 },
    { lat: 48.542766, lng: 20.131044 },
    { lat: 48.542867, lng: 20.131196 },
    { lat: 48.542959, lng: 20.131468 },
    { lat: 48.542979, lng: 20.131462 },
    { lat: 48.543117, lng: 20.131498 },
    { lat: 48.543198, lng: 20.131567 },
    { lat: 48.543296, lng: 20.131847 },
    { lat: 48.54337, lng: 20.132127 },
    { lat: 48.543403, lng: 20.132328 },
    { lat: 48.543676, lng: 20.132827 },
    { lat: 48.543732, lng: 20.133076 },
    { lat: 48.543809, lng: 20.133215 },
    { lat: 48.543846, lng: 20.133357 },
    { lat: 48.544013, lng: 20.133756 },
    { lat: 48.544089, lng: 20.134135 },
    { lat: 48.543921, lng: 20.13441 },
    { lat: 48.543953, lng: 20.134746 },
    { lat: 48.544002, lng: 20.134865 },
    { lat: 48.544042, lng: 20.134908 },
    { lat: 48.544064, lng: 20.134962 },
    { lat: 48.544104, lng: 20.135249 },
    { lat: 48.544132, lng: 20.135385 },
    { lat: 48.544149, lng: 20.135974 },
    { lat: 48.544187, lng: 20.135984 },
    { lat: 48.544102, lng: 20.136126 },
    { lat: 48.543978, lng: 20.136168 },
    { lat: 48.543684, lng: 20.13636 },
    { lat: 48.543667, lng: 20.136309 },
    { lat: 48.543603, lng: 20.136261 },
    { lat: 48.543459, lng: 20.136175 },
    { lat: 48.543398, lng: 20.136224 },
    { lat: 48.543328, lng: 20.13636 },
    { lat: 48.543242, lng: 20.136374 },
    { lat: 48.54317, lng: 20.136464 },
    { lat: 48.543137, lng: 20.136571 },
    { lat: 48.543097, lng: 20.136593 },
    { lat: 48.543038, lng: 20.136497 },
    { lat: 48.542923, lng: 20.136377 },
    { lat: 48.542833, lng: 20.136357 },
    { lat: 48.54263, lng: 20.136459 },
    { lat: 48.542594, lng: 20.136446 },
    { lat: 48.542482, lng: 20.136318 },
    { lat: 48.541577, lng: 20.137196 },
    { lat: 48.541555, lng: 20.137306 },
    { lat: 48.541455, lng: 20.137476 },
    { lat: 48.541373, lng: 20.137654 },
    { lat: 48.541322, lng: 20.137834 },
    { lat: 48.54143, lng: 20.137964 },
    { lat: 48.541402, lng: 20.138086 },
    { lat: 48.541234, lng: 20.138381 },
    { lat: 48.541245, lng: 20.138523 },
    { lat: 48.54131, lng: 20.138682 },
    { lat: 48.541784, lng: 20.138887 },
    { lat: 48.541766, lng: 20.139026 },
    { lat: 48.541709, lng: 20.139308 },
    { lat: 48.541573, lng: 20.139711 },
    { lat: 48.541485, lng: 20.139867 },
    { lat: 48.541343, lng: 20.140062 },
    { lat: 48.541053, lng: 20.140568 },
    { lat: 48.540941, lng: 20.14059 },
    { lat: 48.540901, lng: 20.140563 },
    { lat: 48.540926, lng: 20.141317 },
    { lat: 48.540877, lng: 20.141528 },
    { lat: 48.54078, lng: 20.141908 },
    { lat: 48.540691, lng: 20.142428 },
    { lat: 48.540537, lng: 20.142678 },
    { lat: 48.540294, lng: 20.142989 },
    { lat: 48.539963, lng: 20.142974 },
    { lat: 48.539928, lng: 20.143747 },
    { lat: 48.53996, lng: 20.143817 },
    { lat: 48.539796, lng: 20.144033 },
    { lat: 48.539643, lng: 20.14414 },
    { lat: 48.53959, lng: 20.144205 },
    { lat: 48.539559, lng: 20.144408 },
    { lat: 48.53959, lng: 20.144667 },
    { lat: 48.539616, lng: 20.145029 },
    { lat: 48.53971, lng: 20.145555 },
    { lat: 48.539757, lng: 20.145972 },
    { lat: 48.539748, lng: 20.146036 },
    { lat: 48.539797, lng: 20.146102 },
    { lat: 48.539784, lng: 20.146307 },
    { lat: 48.539749, lng: 20.146939 },
    { lat: 48.53939, lng: 20.14706 },
    { lat: 48.539339, lng: 20.147073 },
    { lat: 48.539104, lng: 20.14709 },
    { lat: 48.539112, lng: 20.146971 },
    { lat: 48.538753, lng: 20.146934 },
    { lat: 48.538635, lng: 20.146867 },
    { lat: 48.53845, lng: 20.146823 },
    { lat: 48.538197, lng: 20.146976 },
    { lat: 48.538214, lng: 20.147054 },
    { lat: 48.538174, lng: 20.147343 },
    { lat: 48.538164, lng: 20.147608 },
    { lat: 48.538146, lng: 20.147736 },
    { lat: 48.53806, lng: 20.14806 },
    { lat: 48.537913, lng: 20.14848 },
    { lat: 48.537889, lng: 20.148613 },
    { lat: 48.537595, lng: 20.148586 },
    { lat: 48.537528, lng: 20.148659 },
    { lat: 48.53745, lng: 20.148825 },
    { lat: 48.537315, lng: 20.148978 },
    { lat: 48.537226, lng: 20.149135 },
    { lat: 48.53708, lng: 20.149275 },
    { lat: 48.536889, lng: 20.149333 },
    { lat: 48.53681, lng: 20.149473 },
    { lat: 48.536784, lng: 20.149588 },
    { lat: 48.536859, lng: 20.150163 },
    { lat: 48.536789, lng: 20.150473 },
    { lat: 48.536716, lng: 20.150573 },
    { lat: 48.536694, lng: 20.150708 },
    { lat: 48.536608, lng: 20.150744 },
    { lat: 48.536512, lng: 20.15092 },
    { lat: 48.53643, lng: 20.1511 },
    { lat: 48.536368, lng: 20.151131 },
    { lat: 48.536265, lng: 20.151082 },
    { lat: 48.536146, lng: 20.150762 },
    { lat: 48.536038, lng: 20.150709 },
    { lat: 48.535891, lng: 20.150693 },
    { lat: 48.535789, lng: 20.150901 },
    { lat: 48.535741, lng: 20.150907 },
    { lat: 48.53579, lng: 20.151303 },
    { lat: 48.535782, lng: 20.151588 },
    { lat: 48.535805, lng: 20.151849 },
    { lat: 48.535805, lng: 20.151939 },
    { lat: 48.535795, lng: 20.152045 },
    { lat: 48.535796, lng: 20.152096 },
    { lat: 48.535777, lng: 20.152141 },
    { lat: 48.535741, lng: 20.152177 },
    { lat: 48.535654, lng: 20.152274 },
    { lat: 48.535629, lng: 20.152436 },
    { lat: 48.535639, lng: 20.152534 },
    { lat: 48.535624, lng: 20.152658 },
    { lat: 48.535576, lng: 20.152754 },
    { lat: 48.535507, lng: 20.152853 },
    { lat: 48.535372, lng: 20.153057 },
    { lat: 48.535276, lng: 20.15322 },
    { lat: 48.535135, lng: 20.153399 },
    { lat: 48.535099, lng: 20.153353 },
    { lat: 48.535037, lng: 20.153027 },
    { lat: 48.534971, lng: 20.152969 },
    { lat: 48.534784, lng: 20.152881 },
    { lat: 48.534767, lng: 20.152882 },
    { lat: 48.534731, lng: 20.15289 },
    { lat: 48.534676, lng: 20.152901 },
    { lat: 48.534518, lng: 20.152868 },
    { lat: 48.534388, lng: 20.152838 },
    { lat: 48.534294, lng: 20.152864 },
    { lat: 48.534193, lng: 20.152964 },
    { lat: 48.534149, lng: 20.153102 },
    { lat: 48.534124, lng: 20.153398 },
    { lat: 48.534148, lng: 20.153576 },
    { lat: 48.534204, lng: 20.153667 },
    { lat: 48.534219, lng: 20.15382 },
    { lat: 48.53388, lng: 20.15466 },
    { lat: 48.533809, lng: 20.154726 },
    { lat: 48.533701, lng: 20.154766 },
    { lat: 48.53372, lng: 20.154883 },
    { lat: 48.533772, lng: 20.155089 },
    { lat: 48.533696, lng: 20.155475 },
    { lat: 48.533659, lng: 20.155846 },
    { lat: 48.533634, lng: 20.155991 },
    { lat: 48.533578, lng: 20.156248 },
    { lat: 48.533473, lng: 20.156605 },
    { lat: 48.533557, lng: 20.156752 },
    { lat: 48.533606, lng: 20.156803 },
    { lat: 48.533709, lng: 20.157004 },
    { lat: 48.533684, lng: 20.157155 },
    { lat: 48.533634, lng: 20.157379 },
    { lat: 48.533584, lng: 20.157754 },
    { lat: 48.533533, lng: 20.157915 },
    { lat: 48.5333753, lng: 20.1583547 },
    { lat: 48.5334756, lng: 20.1590829 },
    { lat: 48.5334085, lng: 20.1598954 },
    { lat: 48.5334417, lng: 20.1599926 },
    { lat: 48.5332504, lng: 20.160523 },
    { lat: 48.5330686, lng: 20.1607614 },
    { lat: 48.5330091, lng: 20.1623249 },
    { lat: 48.5328555, lng: 20.1627281 },
    { lat: 48.5330836, lng: 20.1628834 },
    { lat: 48.5329671, lng: 20.1635366 },
    { lat: 48.532947, lng: 20.1636518 },
    { lat: 48.5329194, lng: 20.1640542 },
    { lat: 48.5331691, lng: 20.1641289 },
    { lat: 48.5331776, lng: 20.1643115 },
    { lat: 48.5336047, lng: 20.1642335 },
    { lat: 48.5336594, lng: 20.1648085 },
    { lat: 48.5335715, lng: 20.1656958 },
    { lat: 48.5333553, lng: 20.1666385 },
    { lat: 48.5327032, lng: 20.1678096 },
    { lat: 48.5322709, lng: 20.1683436 },
    { lat: 48.533046, lng: 20.1689853 },
    { lat: 48.5332452, lng: 20.1692346 },
    { lat: 48.5336255, lng: 20.1699183 },
    { lat: 48.5339724, lng: 20.1703676 },
    { lat: 48.5340713, lng: 20.1704136 },
    { lat: 48.5348726, lng: 20.1702342 },
    { lat: 48.5354556, lng: 20.1703644 },
    { lat: 48.5363194, lng: 20.1703868 },
    { lat: 48.5366566, lng: 20.1698729 },
    { lat: 48.5370073, lng: 20.1696033 },
    { lat: 48.5372756, lng: 20.1691064 },
    { lat: 48.5374939, lng: 20.1689605 },
    { lat: 48.5378584, lng: 20.1690628 },
    { lat: 48.53859, lng: 20.1686637 },
    { lat: 48.5388074, lng: 20.1684152 },
    { lat: 48.5390652, lng: 20.1678289 },
    { lat: 48.5393254, lng: 20.1675214 },
    { lat: 48.5394618, lng: 20.1674975 },
    { lat: 48.5398182, lng: 20.1676215 },
    { lat: 48.5400961, lng: 20.1676419 },
    { lat: 48.540549, lng: 20.1675598 },
    { lat: 48.5410504, lng: 20.1678713 },
    { lat: 48.5413493, lng: 20.1684918 },
    { lat: 48.5417735, lng: 20.1688441 },
    { lat: 48.5431082, lng: 20.1702901 },
    { lat: 48.5438883, lng: 20.1709796 },
    { lat: 48.5439872, lng: 20.1710288 },
    { lat: 48.5444058, lng: 20.1706587 },
    { lat: 48.5451888, lng: 20.1706103 },
    { lat: 48.5457725, lng: 20.1707551 },
    { lat: 48.5480467, lng: 20.171787 },
    { lat: 48.5481962, lng: 20.17178 },
    { lat: 48.5488827, lng: 20.1708802 },
    { lat: 48.5493918, lng: 20.169906 },
    { lat: 48.54962, lng: 20.1697006 },
    { lat: 48.5501155, lng: 20.1690031 },
    { lat: 48.5504509, lng: 20.1682702 },
    { lat: 48.5508227, lng: 20.1681369 },
    { lat: 48.5512651, lng: 20.1681304 },
    { lat: 48.5520779, lng: 20.167873 },
    { lat: 48.5521059, lng: 20.1678637 },
    { lat: 48.5521152, lng: 20.1678725 },
    { lat: 48.5524786, lng: 20.1668106 },
    { lat: 48.5527708, lng: 20.1657084 },
    { lat: 48.553078, lng: 20.1652286 },
    { lat: 48.5534446, lng: 20.1648227 },
    { lat: 48.5543878, lng: 20.1640047 },
    { lat: 48.5545395, lng: 20.1637181 },
    { lat: 48.5545888, lng: 20.1634299 },
    { lat: 48.5545764, lng: 20.1628465 },
    { lat: 48.5546763, lng: 20.162201 },
    { lat: 48.5552727, lng: 20.1605463 },
    { lat: 48.555615, lng: 20.1593803 },
    { lat: 48.5560917, lng: 20.1584134 },
    { lat: 48.5567812, lng: 20.1562808 },
    { lat: 48.5573118, lng: 20.1549793 },
    { lat: 48.5573201, lng: 20.1549917 },
    { lat: 48.5574906, lng: 20.1547825 },
    { lat: 48.5581043, lng: 20.1540295 },
    { lat: 48.5585641, lng: 20.1532878 },
    { lat: 48.5586573, lng: 20.1532223 },
    { lat: 48.5595667, lng: 20.1527055 },
    { lat: 48.5598417, lng: 20.1526257 },
    { lat: 48.5603201, lng: 20.1525098 },
    { lat: 48.5609102, lng: 20.1526222 },
    { lat: 48.5611956, lng: 20.152518 },
    { lat: 48.5615247, lng: 20.1520781 },
    { lat: 48.561707, lng: 20.1517434 },
    { lat: 48.5619767, lng: 20.1509725 },
    { lat: 48.5623794, lng: 20.1498212 },
    { lat: 48.5624961, lng: 20.1494877 },
    { lat: 48.5628792, lng: 20.1490309 },
    { lat: 48.5632383, lng: 20.1486029 },
    { lat: 48.5633378, lng: 20.1483331 },
    { lat: 48.5635274, lng: 20.1478188 },
    { lat: 48.5637691, lng: 20.1475035 },
    { lat: 48.5641971, lng: 20.1469452 },
    { lat: 48.5643468, lng: 20.1466092 },
    { lat: 48.564523, lng: 20.1460151 },
    { lat: 48.5645759, lng: 20.1453481 },
    { lat: 48.5647986, lng: 20.1445904 },
    { lat: 48.5654764, lng: 20.1433959 },
    { lat: 48.5656106, lng: 20.1429863 },
    { lat: 48.5656564, lng: 20.1426731 },
    { lat: 48.565853, lng: 20.1422147 },
    { lat: 48.5659336, lng: 20.1414022 },
    { lat: 48.5662026, lng: 20.1406796 },
    { lat: 48.5661701, lng: 20.1401837 },
    { lat: 48.5662838, lng: 20.1396309 },
    { lat: 48.5663071, lng: 20.1389876 },
    { lat: 48.5663717, lng: 20.1388006 },
    { lat: 48.5661968, lng: 20.1386254 },
    { lat: 48.5652106, lng: 20.1377547 },
    { lat: 48.5650974, lng: 20.1372392 },
    { lat: 48.5647263, lng: 20.1364265 },
    { lat: 48.5645977, lng: 20.136006 },
    { lat: 48.5642904, lng: 20.13557 },
    { lat: 48.5641461, lng: 20.1349028 },
    { lat: 48.5640433, lng: 20.1338754 },
    { lat: 48.5639117, lng: 20.1331589 },
    { lat: 48.5637211, lng: 20.1327396 },
    { lat: 48.5632815, lng: 20.1308551 },
    { lat: 48.5629134, lng: 20.1301115 },
    { lat: 48.5626601, lng: 20.1294277 },
    { lat: 48.562377, lng: 20.128315 },
    { lat: 48.5620802, lng: 20.1277743 },
    { lat: 48.5620748, lng: 20.1276422 },
    { lat: 48.5615274, lng: 20.1273391 },
    { lat: 48.5611921, lng: 20.1269897 },
    { lat: 48.5608489, lng: 20.1267473 },
    { lat: 48.5607246, lng: 20.1265459 },
    { lat: 48.5605789, lng: 20.1265384 },
    { lat: 48.5601283, lng: 20.1257065 },
    { lat: 48.5600692, lng: 20.1257205 },
    { lat: 48.5596928, lng: 20.1254144 },
    { lat: 48.5594712, lng: 20.125334 },
    { lat: 48.5592867, lng: 20.1253998 },
    { lat: 48.5587636, lng: 20.1249473 },
    { lat: 48.5586873, lng: 20.1247848 },
    { lat: 48.5584812, lng: 20.124593 },
    { lat: 48.5584198, lng: 20.1243612 },
    { lat: 48.5581153, lng: 20.1239617 },
    { lat: 48.5579141, lng: 20.1238371 },
    { lat: 48.5577473, lng: 20.1236136 },
    { lat: 48.5574418, lng: 20.1233856 },
    { lat: 48.5571446, lng: 20.1234725 },
    { lat: 48.556987, lng: 20.1233929 },
    { lat: 48.5569338, lng: 20.1232233 },
    { lat: 48.5567364, lng: 20.123019 },
    { lat: 48.5562841, lng: 20.1228953 },
    { lat: 48.556166, lng: 20.1226503 },
    { lat: 48.5557479, lng: 20.1222841 },
    { lat: 48.5555581, lng: 20.1220233 },
    { lat: 48.5548793, lng: 20.1216777 },
    { lat: 48.5543058, lng: 20.1215326 },
    { lat: 48.5535805, lng: 20.1211473 },
    { lat: 48.5535455, lng: 20.1210648 },
    { lat: 48.5532449, lng: 20.1209039 },
    { lat: 48.5532476, lng: 20.1208546 },
    { lat: 48.5530436, lng: 20.120715 },
    { lat: 48.5526048, lng: 20.1205349 },
    { lat: 48.5524193, lng: 20.1203331 },
    { lat: 48.5523667, lng: 20.1202758 },
    { lat: 48.5523938, lng: 20.1201428 },
    { lat: 48.5518658, lng: 20.1200108 },
    { lat: 48.5517046, lng: 20.1202231 },
    { lat: 48.5515238, lng: 20.1201837 },
    { lat: 48.5511361, lng: 20.1200121 },
    { lat: 48.5506461, lng: 20.1196832 },
    { lat: 48.550586, lng: 20.1196152 },
    { lat: 48.5506842, lng: 20.1194319 },
    { lat: 48.550788, lng: 20.1190216 },
    { lat: 48.5509649, lng: 20.1188846 },
    { lat: 48.551, lng: 20.1187801 },
    { lat: 48.5509486, lng: 20.11843 },
    { lat: 48.5509975, lng: 20.1180846 },
    { lat: 48.550891, lng: 20.117526 },
    { lat: 48.550904, lng: 20.117402 },
    { lat: 48.550924, lng: 20.117271 },
    { lat: 48.550993, lng: 20.117034 },
    { lat: 48.551043, lng: 20.116936 },
    { lat: 48.551032, lng: 20.116549 },
    { lat: 48.55117, lng: 20.116194 },
    { lat: 48.551183, lng: 20.116162 },
    { lat: 48.55147, lng: 20.115878 },
    { lat: 48.55154, lng: 20.115738 },
    { lat: 48.551652, lng: 20.115455 },
    { lat: 48.551656, lng: 20.115286 },
    { lat: 48.551689, lng: 20.115105 },
    { lat: 48.551738, lng: 20.114851 },
    { lat: 48.551863, lng: 20.114436 },
    { lat: 48.55203, lng: 20.114148 },
    { lat: 48.552263, lng: 20.113946 },
    { lat: 48.552253, lng: 20.113923 },
    { lat: 48.552526, lng: 20.113654 },
    { lat: 48.552665, lng: 20.113587 },
    { lat: 48.552764, lng: 20.113455 },
    { lat: 48.552976, lng: 20.113245 },
    { lat: 48.55313, lng: 20.112982 },
    { lat: 48.553241, lng: 20.112879 },
    { lat: 48.553309, lng: 20.112802 },
    { lat: 48.553348, lng: 20.112756 },
    { lat: 48.553518, lng: 20.112573 },
    { lat: 48.553677, lng: 20.112426 },
    { lat: 48.553833, lng: 20.112205 },
    { lat: 48.553946, lng: 20.111979 },
    { lat: 48.554093, lng: 20.111763 },
    { lat: 48.554172, lng: 20.111749 },
    { lat: 48.554201, lng: 20.111591 },
    { lat: 48.554266, lng: 20.111473 },
    { lat: 48.554285, lng: 20.111434 },
    { lat: 48.554357, lng: 20.111369 },
    { lat: 48.554424, lng: 20.111357 },
    { lat: 48.554487, lng: 20.11136 },
    { lat: 48.554548, lng: 20.111333 },
    { lat: 48.554568, lng: 20.111099 },
    { lat: 48.554669, lng: 20.110313 },
    { lat: 48.554682, lng: 20.110219 },
    { lat: 48.554719, lng: 20.110031 },
    { lat: 48.554721, lng: 20.109731 },
    { lat: 48.55471, lng: 20.109679 },
    { lat: 48.554706, lng: 20.109515 },
    { lat: 48.554689, lng: 20.109346 },
    { lat: 48.554655, lng: 20.109259 },
    { lat: 48.554605, lng: 20.109196 },
    { lat: 48.55456, lng: 20.109153 },
    { lat: 48.554519, lng: 20.109127 },
    { lat: 48.554426, lng: 20.109069 },
    { lat: 48.554293, lng: 20.108976 },
    { lat: 48.55419, lng: 20.108885 },
    { lat: 48.554085, lng: 20.108822 },
    { lat: 48.554016, lng: 20.108674 },
    { lat: 48.553959, lng: 20.108673 },
    { lat: 48.553923, lng: 20.108688 },
    { lat: 48.553866, lng: 20.108662 },
    { lat: 48.553783, lng: 20.108514 },
    { lat: 48.553753, lng: 20.108452 },
    { lat: 48.553695, lng: 20.108322 },
    { lat: 48.553618, lng: 20.108264 },
    { lat: 48.553569, lng: 20.108144 },
    { lat: 48.5535, lng: 20.108136 },
    { lat: 48.553406, lng: 20.10806 },
    { lat: 48.553212, lng: 20.108047 },
    { lat: 48.553143, lng: 20.108039 },
    { lat: 48.553069, lng: 20.108025 },
    { lat: 48.553001, lng: 20.108047 },
    { lat: 48.552939, lng: 20.108004 },
    { lat: 48.552883, lng: 20.107988 },
    { lat: 48.552853, lng: 20.107903 },
    { lat: 48.552778, lng: 20.107907 },
    { lat: 48.552725, lng: 20.107889 },
    { lat: 48.552579, lng: 20.107837 },
    { lat: 48.552509, lng: 20.10769 },
    { lat: 48.552468, lng: 20.10761 },
    { lat: 48.552403, lng: 20.107547 },
    { lat: 48.552333, lng: 20.107568 },
    { lat: 48.552275, lng: 20.107431 },
    { lat: 48.552222, lng: 20.107413 },
    { lat: 48.552137, lng: 20.10737 },
    { lat: 48.552097, lng: 20.107256 },
    { lat: 48.551982, lng: 20.10708 },
    { lat: 48.551894, lng: 20.107012 },
    { lat: 48.551827, lng: 20.106857 },
    { lat: 48.551685, lng: 20.106776 },
    { lat: 48.551508, lng: 20.106568 },
    { lat: 48.551423, lng: 20.106422 },
    { lat: 48.551361, lng: 20.10634 },
    { lat: 48.551273, lng: 20.106267 },
    { lat: 48.551261, lng: 20.106182 },
    { lat: 48.551211, lng: 20.10608 },
    { lat: 48.55113, lng: 20.106061 },
    { lat: 48.551062, lng: 20.105996 },
    { lat: 48.551005, lng: 20.105869 },
    { lat: 48.550987, lng: 20.105769 },
    { lat: 48.550955, lng: 20.105685 },
    { lat: 48.550842, lng: 20.105497 },
    { lat: 48.550713, lng: 20.105349 },
    { lat: 48.550666, lng: 20.105324 },
    { lat: 48.55062, lng: 20.105286 },
    { lat: 48.550496, lng: 20.10514 },
    { lat: 48.550367, lng: 20.104947 },
    { lat: 48.550245, lng: 20.104732 },
    { lat: 48.550156, lng: 20.104501 },
    { lat: 48.549862, lng: 20.10347 },
    { lat: 48.54963, lng: 20.102624 },
    { lat: 48.549577, lng: 20.102422 },
    { lat: 48.549417, lng: 20.102088 },
    { lat: 48.549376, lng: 20.102008 },
    { lat: 48.549367, lng: 20.10199 },
    { lat: 48.54952, lng: 20.101682 },
    { lat: 48.549687, lng: 20.101203 },
    { lat: 48.549783, lng: 20.100947 },
    { lat: 48.549794, lng: 20.100627 },
    { lat: 48.549788, lng: 20.100395 },
    { lat: 48.549786, lng: 20.100244 },
    { lat: 48.549704, lng: 20.099782 },
    { lat: 48.549687, lng: 20.099576 },
    { lat: 48.549623, lng: 20.099126 },
    { lat: 48.549532, lng: 20.098291 },
    { lat: 48.549475, lng: 20.097995 },
    { lat: 48.549353, lng: 20.09774 },
    { lat: 48.549362, lng: 20.097733 },
    { lat: 48.549394, lng: 20.097719 },
    { lat: 48.549185, lng: 20.0971 },
    { lat: 48.549019, lng: 20.096661 },
    { lat: 48.549233, lng: 20.095967 },
    { lat: 48.549256, lng: 20.095888 },
    { lat: 48.549246, lng: 20.095875 },
    { lat: 48.549238, lng: 20.095862 },
    { lat: 48.549431, lng: 20.095565 },
    { lat: 48.549619, lng: 20.095149 },
    { lat: 48.549673, lng: 20.094977 },
    { lat: 48.54972, lng: 20.094817 },
    { lat: 48.549943, lng: 20.094329 },
    { lat: 48.550099, lng: 20.093891 },
    { lat: 48.550259, lng: 20.093483 },
    { lat: 48.55044, lng: 20.093085 },
    { lat: 48.550495, lng: 20.093029 },
    { lat: 48.550577, lng: 20.092862 },
    { lat: 48.550747, lng: 20.092596 },
    { lat: 48.550931, lng: 20.092368 },
    { lat: 48.551035, lng: 20.092259 },
    { lat: 48.551345, lng: 20.092022 },
    { lat: 48.551503, lng: 20.091788 },
    { lat: 48.551575, lng: 20.091643 },
    { lat: 48.551899, lng: 20.091289 },
    { lat: 48.552155, lng: 20.090851 },
    { lat: 48.55229, lng: 20.090643 },
    { lat: 48.55239, lng: 20.090483 },
    { lat: 48.55246, lng: 20.09027 },
    { lat: 48.55254, lng: 20.089882 },
    { lat: 48.552673, lng: 20.089556 },
    { lat: 48.552706, lng: 20.089352 },
    { lat: 48.552669, lng: 20.088684 },
    { lat: 48.552669, lng: 20.08847 },
    { lat: 48.552711, lng: 20.088206 },
    { lat: 48.552741, lng: 20.088126 },
    { lat: 48.552976, lng: 20.087597 },
    { lat: 48.553058, lng: 20.087351 },
    { lat: 48.553168, lng: 20.086833 },
    { lat: 48.553371, lng: 20.086395 },
    { lat: 48.553437, lng: 20.086297 },
    { lat: 48.553565, lng: 20.086244 },
    { lat: 48.553721, lng: 20.086244 },
    { lat: 48.553913, lng: 20.086283 },
    { lat: 48.554491, lng: 20.086471 },
    { lat: 48.554713, lng: 20.086478 },
    { lat: 48.554709, lng: 20.086456 },
    { lat: 48.554665, lng: 20.086257 },
    { lat: 48.554588, lng: 20.085892 },
    { lat: 48.554547, lng: 20.085681 },
    { lat: 48.554436, lng: 20.08508 },
    { lat: 48.554384, lng: 20.084572 },
    { lat: 48.554374, lng: 20.084231 },
    { lat: 48.554365, lng: 20.084082 },
    { lat: 48.554284, lng: 20.083606 },
    { lat: 48.554179, lng: 20.083281 },
    { lat: 48.554107, lng: 20.082581 },
    { lat: 48.554101, lng: 20.082528 },
  ],
  Leváre: [
    { lat: 48.5117797, lng: 20.2339627 },
    { lat: 48.5113552, lng: 20.2342171 },
    { lat: 48.5112915, lng: 20.2343717 },
    { lat: 48.5113367, lng: 20.2344642 },
    { lat: 48.5109999, lng: 20.2346289 },
    { lat: 48.5107844, lng: 20.2350715 },
    { lat: 48.5106164, lng: 20.2350362 },
    { lat: 48.5105318, lng: 20.2349217 },
    { lat: 48.5103948, lng: 20.2349305 },
    { lat: 48.5102775, lng: 20.2348424 },
    { lat: 48.5101803, lng: 20.2349194 },
    { lat: 48.5101994, lng: 20.2350601 },
    { lat: 48.5101418, lng: 20.2351143 },
    { lat: 48.5099322, lng: 20.2351063 },
    { lat: 48.5099121, lng: 20.2352001 },
    { lat: 48.5099757, lng: 20.2352977 },
    { lat: 48.509973, lng: 20.2353974 },
    { lat: 48.509847, lng: 20.2355977 },
    { lat: 48.5097018, lng: 20.2353487 },
    { lat: 48.5096122, lng: 20.2353018 },
    { lat: 48.5095897, lng: 20.2354427 },
    { lat: 48.5096532, lng: 20.2357673 },
    { lat: 48.5096414, lng: 20.2359214 },
    { lat: 48.5094959, lng: 20.2361978 },
    { lat: 48.5093881, lng: 20.2362324 },
    { lat: 48.5092857, lng: 20.2364363 },
    { lat: 48.5092691, lng: 20.2365802 },
    { lat: 48.5091486, lng: 20.2366661 },
    { lat: 48.5091579, lng: 20.2369881 },
    { lat: 48.5092566, lng: 20.2371127 },
    { lat: 48.5092353, lng: 20.2373434 },
    { lat: 48.5091156, lng: 20.2372828 },
    { lat: 48.5089902, lng: 20.2376127 },
    { lat: 48.5089524, lng: 20.237511 },
    { lat: 48.5089043, lng: 20.2375459 },
    { lat: 48.5088239, lng: 20.2378157 },
    { lat: 48.5088584, lng: 20.2378925 },
    { lat: 48.5089793, lng: 20.2379011 },
    { lat: 48.5088935, lng: 20.238091 },
    { lat: 48.5086192, lng: 20.238162 },
    { lat: 48.5083764, lng: 20.2383463 },
    { lat: 48.5082809, lng: 20.238319 },
    { lat: 48.5082034, lng: 20.2384695 },
    { lat: 48.5080102, lng: 20.2385367 },
    { lat: 48.507915, lng: 20.2387186 },
    { lat: 48.5076366, lng: 20.2389093 },
    { lat: 48.5075993, lng: 20.2391041 },
    { lat: 48.507432, lng: 20.2391775 },
    { lat: 48.5074031, lng: 20.2392685 },
    { lat: 48.5074805, lng: 20.2395169 },
    { lat: 48.5072865, lng: 20.2397361 },
    { lat: 48.5072132, lng: 20.2399268 },
    { lat: 48.5072348, lng: 20.2400477 },
    { lat: 48.5071847, lng: 20.2401765 },
    { lat: 48.5070528, lng: 20.2401128 },
    { lat: 48.5070589, lng: 20.2399584 },
    { lat: 48.5069325, lng: 20.2399248 },
    { lat: 48.5068393, lng: 20.2403285 },
    { lat: 48.5069224, lng: 20.2404052 },
    { lat: 48.5068302, lng: 20.2406319 },
    { lat: 48.506717, lng: 20.2407583 },
    { lat: 48.5065733, lng: 20.2407709 },
    { lat: 48.506489, lng: 20.2410008 },
    { lat: 48.5064118, lng: 20.2410117 },
    { lat: 48.5063084, lng: 20.240852 },
    { lat: 48.5062152, lng: 20.2408871 },
    { lat: 48.5062552, lng: 20.2412979 },
    { lat: 48.5061044, lng: 20.2414124 },
    { lat: 48.5059275, lng: 20.2417221 },
    { lat: 48.5058662, lng: 20.2422447 },
    { lat: 48.5059676, lng: 20.2423894 },
    { lat: 48.5059653, lng: 20.2425267 },
    { lat: 48.5057815, lng: 20.2427763 },
    { lat: 48.5056151, lng: 20.2427828 },
    { lat: 48.5055827, lng: 20.2431849 },
    { lat: 48.505448, lng: 20.2432254 },
    { lat: 48.5053461, lng: 20.2434342 },
    { lat: 48.5054596, lng: 20.2436595 },
    { lat: 48.5054411, lng: 20.2438181 },
    { lat: 48.5052867, lng: 20.2439823 },
    { lat: 48.5052072, lng: 20.2439653 },
    { lat: 48.5051884, lng: 20.2440565 },
    { lat: 48.5052381, lng: 20.244291 },
    { lat: 48.5049847, lng: 20.2447419 },
    { lat: 48.5052037, lng: 20.2454054 },
    { lat: 48.5053115, lng: 20.2454213 },
    { lat: 48.5052496, lng: 20.2456797 },
    { lat: 48.5051569, lng: 20.2457822 },
    { lat: 48.5052318, lng: 20.2459676 },
    { lat: 48.5053156, lng: 20.2465126 },
    { lat: 48.5052014, lng: 20.2468458 },
    { lat: 48.5050916, lng: 20.2482127 },
    { lat: 48.5031847, lng: 20.248836 },
    { lat: 48.5025143, lng: 20.2491334 },
    { lat: 48.5015978, lng: 20.2496531 },
    { lat: 48.5005847, lng: 20.2500641 },
    { lat: 48.4999645, lng: 20.2499268 },
    { lat: 48.4994306, lng: 20.2495896 },
    { lat: 48.4992492, lng: 20.2493901 },
    { lat: 48.4992227, lng: 20.2494873 },
    { lat: 48.4984281, lng: 20.2519313 },
    { lat: 48.498378, lng: 20.2524575 },
    { lat: 48.4983271, lng: 20.2552021 },
    { lat: 48.4983648, lng: 20.2552711 },
    { lat: 48.4986515, lng: 20.2547169 },
    { lat: 48.4988088, lng: 20.2545369 },
    { lat: 48.4988266, lng: 20.2551869 },
    { lat: 48.4989723, lng: 20.2555626 },
    { lat: 48.4987887, lng: 20.2558771 },
    { lat: 48.4986596, lng: 20.2559122 },
    { lat: 48.4985331, lng: 20.2561022 },
    { lat: 48.498598, lng: 20.2563758 },
    { lat: 48.4987388, lng: 20.2564043 },
    { lat: 48.4987274, lng: 20.2564547 },
    { lat: 48.4985395, lng: 20.2569173 },
    { lat: 48.4983991, lng: 20.2570656 },
    { lat: 48.4980893, lng: 20.2569794 },
    { lat: 48.497746, lng: 20.2571218 },
    { lat: 48.4976838, lng: 20.2573448 },
    { lat: 48.4976718, lng: 20.2575827 },
    { lat: 48.4977299, lng: 20.2577159 },
    { lat: 48.4976844, lng: 20.2577882 },
    { lat: 48.4976103, lng: 20.2577952 },
    { lat: 48.4974331, lng: 20.2576407 },
    { lat: 48.4974317, lng: 20.2574924 },
    { lat: 48.4972889, lng: 20.2573544 },
    { lat: 48.4971489, lng: 20.2575456 },
    { lat: 48.4969102, lng: 20.2581128 },
    { lat: 48.497058, lng: 20.2583445 },
    { lat: 48.4969565, lng: 20.2585181 },
    { lat: 48.4968604, lng: 20.2585029 },
    { lat: 48.4967707, lng: 20.2583732 },
    { lat: 48.4966598, lng: 20.2584734 },
    { lat: 48.4966674, lng: 20.2586052 },
    { lat: 48.496588, lng: 20.2589074 },
    { lat: 48.4967421, lng: 20.2592976 },
    { lat: 48.4966912, lng: 20.2594613 },
    { lat: 48.4967407, lng: 20.2599706 },
    { lat: 48.4964529, lng: 20.2607511 },
    { lat: 48.4961454, lng: 20.260805 },
    { lat: 48.4961192, lng: 20.2606856 },
    { lat: 48.4959598, lng: 20.2606715 },
    { lat: 48.4958535, lng: 20.2609115 },
    { lat: 48.4955423, lng: 20.2603916 },
    { lat: 48.4954699, lng: 20.2605004 },
    { lat: 48.4955565, lng: 20.2606655 },
    { lat: 48.4955347, lng: 20.2607118 },
    { lat: 48.4953319, lng: 20.2607993 },
    { lat: 48.4951333, lng: 20.2606478 },
    { lat: 48.4950268, lng: 20.2607408 },
    { lat: 48.4950666, lng: 20.2610053 },
    { lat: 48.4950393, lng: 20.2611676 },
    { lat: 48.4948737, lng: 20.2613429 },
    { lat: 48.4949128, lng: 20.261615 },
    { lat: 48.4951052, lng: 20.2614681 },
    { lat: 48.4953304, lng: 20.2617528 },
    { lat: 48.4951202, lng: 20.2620998 },
    { lat: 48.4949136, lng: 20.2622281 },
    { lat: 48.4949398, lng: 20.2625205 },
    { lat: 48.4948847, lng: 20.262922 },
    { lat: 48.4947731, lng: 20.2630056 },
    { lat: 48.4947792, lng: 20.26313 },
    { lat: 48.4948683, lng: 20.2633052 },
    { lat: 48.4949899, lng: 20.2632176 },
    { lat: 48.4960952, lng: 20.2638796 },
    { lat: 48.4963438, lng: 20.2639585 },
    { lat: 48.4968316, lng: 20.2639874 },
    { lat: 48.4970814, lng: 20.2639259 },
    { lat: 48.4980148, lng: 20.26389 },
    { lat: 48.4984419, lng: 20.2640146 },
    { lat: 48.4985017, lng: 20.2641598 },
    { lat: 48.4985901, lng: 20.2647388 },
    { lat: 48.4987142, lng: 20.2652557 },
    { lat: 48.4988032, lng: 20.2654523 },
    { lat: 48.4989229, lng: 20.2662046 },
    { lat: 48.4990382, lng: 20.267516 },
    { lat: 48.4994205, lng: 20.268836 },
    { lat: 48.4998776, lng: 20.2697993 },
    { lat: 48.5000403, lng: 20.2703798 },
    { lat: 48.5003086, lng: 20.2709268 },
    { lat: 48.5003812, lng: 20.2715396 },
    { lat: 48.5005874, lng: 20.2719962 },
    { lat: 48.5007321, lng: 20.2726334 },
    { lat: 48.5006282, lng: 20.2741332 },
    { lat: 48.5006836, lng: 20.274597 },
    { lat: 48.5009691, lng: 20.2753246 },
    { lat: 48.5011025, lng: 20.2758723 },
    { lat: 48.5013746, lng: 20.2776867 },
    { lat: 48.5014373, lng: 20.2784172 },
    { lat: 48.5015843, lng: 20.2790845 },
    { lat: 48.5017047, lng: 20.2799331 },
    { lat: 48.5022203, lng: 20.2811568 },
    { lat: 48.5023224, lng: 20.2823121 },
    { lat: 48.5027802, lng: 20.2839235 },
    { lat: 48.5035186, lng: 20.285338 },
    { lat: 48.5034725, lng: 20.2862579 },
    { lat: 48.5035416, lng: 20.2866076 },
    { lat: 48.5035448, lng: 20.2872561 },
    { lat: 48.5043757, lng: 20.286538 },
    { lat: 48.5113442, lng: 20.2826506 },
    { lat: 48.513343, lng: 20.2816221 },
    { lat: 48.517372, lng: 20.279384 },
    { lat: 48.5184581, lng: 20.2787645 },
    { lat: 48.5187892, lng: 20.2785078 },
    { lat: 48.5190297, lng: 20.2781562 },
    { lat: 48.5198845, lng: 20.2766181 },
    { lat: 48.5215729, lng: 20.2734052 },
    { lat: 48.5227552, lng: 20.2713249 },
    { lat: 48.5231557, lng: 20.2706342 },
    { lat: 48.5234837, lng: 20.2702708 },
    { lat: 48.524121, lng: 20.2691584 },
    { lat: 48.5250057, lng: 20.2673828 },
    { lat: 48.5251554, lng: 20.266986 },
    { lat: 48.5262419, lng: 20.2650746 },
    { lat: 48.5265471, lng: 20.2643618 },
    { lat: 48.5265558, lng: 20.2643259 },
    { lat: 48.5263899, lng: 20.264372 },
    { lat: 48.5262746, lng: 20.2643092 },
    { lat: 48.5260518, lng: 20.2639992 },
    { lat: 48.5259712, lng: 20.2641517 },
    { lat: 48.5258465, lng: 20.2641605 },
    { lat: 48.5257236, lng: 20.2640742 },
    { lat: 48.5254194, lng: 20.2642088 },
    { lat: 48.5254638, lng: 20.263997 },
    { lat: 48.5253858, lng: 20.2638516 },
    { lat: 48.5251122, lng: 20.2639611 },
    { lat: 48.5249516, lng: 20.2637737 },
    { lat: 48.5249739, lng: 20.263658 },
    { lat: 48.5248872, lng: 20.2635418 },
    { lat: 48.5248322, lng: 20.2636015 },
    { lat: 48.5246821, lng: 20.2634821 },
    { lat: 48.5245512, lng: 20.26348 },
    { lat: 48.52445, lng: 20.2636813 },
    { lat: 48.5241507, lng: 20.2636876 },
    { lat: 48.5241715, lng: 20.2635262 },
    { lat: 48.5239957, lng: 20.263385 },
    { lat: 48.5238958, lng: 20.2635077 },
    { lat: 48.523771, lng: 20.2634855 },
    { lat: 48.5237561, lng: 20.2636201 },
    { lat: 48.5236903, lng: 20.2635805 },
    { lat: 48.5235925, lng: 20.2637502 },
    { lat: 48.5235015, lng: 20.2635861 },
    { lat: 48.5235528, lng: 20.2632369 },
    { lat: 48.5233796, lng: 20.2632979 },
    { lat: 48.5231431, lng: 20.2631021 },
    { lat: 48.5230176, lng: 20.2631152 },
    { lat: 48.5228411, lng: 20.2634945 },
    { lat: 48.5227696, lng: 20.2634238 },
    { lat: 48.5227372, lng: 20.2630899 },
    { lat: 48.5225823, lng: 20.2632133 },
    { lat: 48.5225177, lng: 20.2629232 },
    { lat: 48.5223377, lng: 20.2628122 },
    { lat: 48.5223403, lng: 20.2626513 },
    { lat: 48.5223022, lng: 20.2627955 },
    { lat: 48.5222357, lng: 20.2627986 },
    { lat: 48.5221374, lng: 20.2627296 },
    { lat: 48.5220537, lng: 20.2627791 },
    { lat: 48.5219211, lng: 20.2626127 },
    { lat: 48.521794, lng: 20.2626451 },
    { lat: 48.5217886, lng: 20.2625692 },
    { lat: 48.5219266, lng: 20.2623827 },
    { lat: 48.5219078, lng: 20.2622778 },
    { lat: 48.521678, lng: 20.262107 },
    { lat: 48.5216342, lng: 20.2621983 },
    { lat: 48.5215629, lng: 20.2621859 },
    { lat: 48.5214993, lng: 20.2620342 },
    { lat: 48.5215253, lng: 20.2619214 },
    { lat: 48.521429, lng: 20.2619343 },
    { lat: 48.5213901, lng: 20.2617509 },
    { lat: 48.5212561, lng: 20.2614958 },
    { lat: 48.5213144, lng: 20.2614018 },
    { lat: 48.5211113, lng: 20.2611369 },
    { lat: 48.5211087, lng: 20.2607904 },
    { lat: 48.5210201, lng: 20.2609128 },
    { lat: 48.5208942, lng: 20.2608328 },
    { lat: 48.5207792, lng: 20.2606602 },
    { lat: 48.5207266, lng: 20.2603558 },
    { lat: 48.5207568, lng: 20.2602672 },
    { lat: 48.5208248, lng: 20.2602742 },
    { lat: 48.5207686, lng: 20.2601972 },
    { lat: 48.5206788, lng: 20.2601992 },
    { lat: 48.5207061, lng: 20.260012 },
    { lat: 48.5206294, lng: 20.2599248 },
    { lat: 48.52074, lng: 20.2598153 },
    { lat: 48.5197592, lng: 20.2555674 },
    { lat: 48.5194855, lng: 20.2553071 },
    { lat: 48.5189555, lng: 20.254044 },
    { lat: 48.5190278, lng: 20.2537672 },
    { lat: 48.5184727, lng: 20.2525799 },
    { lat: 48.5188021, lng: 20.2523395 },
    { lat: 48.519024, lng: 20.2517191 },
    { lat: 48.5199886, lng: 20.2501195 },
    { lat: 48.5202043, lng: 20.2496503 },
    { lat: 48.5204864, lng: 20.249297 },
    { lat: 48.5206723, lng: 20.2477704 },
    { lat: 48.5202185, lng: 20.2464723 },
    { lat: 48.520029, lng: 20.2451057 },
    { lat: 48.5197504, lng: 20.2443603 },
    { lat: 48.518379, lng: 20.2428752 },
    { lat: 48.5171856, lng: 20.241443 },
    { lat: 48.5170951, lng: 20.2400505 },
    { lat: 48.5165368, lng: 20.2383365 },
    { lat: 48.5160192, lng: 20.2379201 },
    { lat: 48.5154493, lng: 20.2377215 },
    { lat: 48.5149717, lng: 20.2370422 },
    { lat: 48.5148155, lng: 20.2365469 },
    { lat: 48.5145895, lng: 20.2345802 },
    { lat: 48.5141055, lng: 20.2343934 },
    { lat: 48.5137163, lng: 20.2343733 },
    { lat: 48.5136618, lng: 20.2342507 },
    { lat: 48.5126574, lng: 20.2343706 },
    { lat: 48.5120682, lng: 20.2347855 },
    { lat: 48.5119102, lng: 20.234044 },
    { lat: 48.5117881, lng: 20.2339762 },
    { lat: 48.5117797, lng: 20.2339627 },
  ],
  Šivetice: [
    { lat: 48.5715191, lng: 20.2563084 },
    { lat: 48.5710329, lng: 20.2560855 },
    { lat: 48.5702002, lng: 20.2558231 },
    { lat: 48.5700253, lng: 20.2556725 },
    { lat: 48.5696877, lng: 20.254885 },
    { lat: 48.5691756, lng: 20.2540422 },
    { lat: 48.568297, lng: 20.2527577 },
    { lat: 48.5679645, lng: 20.2526992 },
    { lat: 48.5674352, lng: 20.2524702 },
    { lat: 48.5671731, lng: 20.2524782 },
    { lat: 48.5670456, lng: 20.2523034 },
    { lat: 48.5666008, lng: 20.2527037 },
    { lat: 48.5661387, lng: 20.2527209 },
    { lat: 48.5647566, lng: 20.251952 },
    { lat: 48.5643947, lng: 20.2518527 },
    { lat: 48.5641774, lng: 20.2515466 },
    { lat: 48.5639827, lng: 20.2514924 },
    { lat: 48.5637104, lng: 20.2515543 },
    { lat: 48.5636264, lng: 20.2514232 },
    { lat: 48.5625431, lng: 20.2510288 },
    { lat: 48.5618254, lng: 20.2522704 },
    { lat: 48.5612993, lng: 20.2541088 },
    { lat: 48.5606535, lng: 20.2547191 },
    { lat: 48.5604666, lng: 20.2550638 },
    { lat: 48.5598482, lng: 20.2551153 },
    { lat: 48.5594783, lng: 20.2555276 },
    { lat: 48.5588603, lng: 20.255958 },
    { lat: 48.5585736, lng: 20.2565943 },
    { lat: 48.557919, lng: 20.2583192 },
    { lat: 48.5580438, lng: 20.2589668 },
    { lat: 48.5578705, lng: 20.2594833 },
    { lat: 48.5575903, lng: 20.2610514 },
    { lat: 48.5576899, lng: 20.2613742 },
    { lat: 48.5576365, lng: 20.2617871 },
    { lat: 48.5573232, lng: 20.2621235 },
    { lat: 48.555362, lng: 20.2634426 },
    { lat: 48.5546593, lng: 20.2641294 },
    { lat: 48.5543715, lng: 20.2645385 },
    { lat: 48.554247, lng: 20.2649503 },
    { lat: 48.5541763, lng: 20.2654127 },
    { lat: 48.5542031, lng: 20.265517 },
    { lat: 48.5541305, lng: 20.265703 },
    { lat: 48.5547742, lng: 20.2667443 },
    { lat: 48.5549185, lng: 20.2673478 },
    { lat: 48.5552853, lng: 20.2681612 },
    { lat: 48.5552294, lng: 20.2689117 },
    { lat: 48.5552818, lng: 20.2696923 },
    { lat: 48.5550804, lng: 20.2708117 },
    { lat: 48.5549337, lng: 20.272339 },
    { lat: 48.5549339, lng: 20.2731381 },
    { lat: 48.5551111, lng: 20.2746281 },
    { lat: 48.5555162, lng: 20.2761008 },
    { lat: 48.5557085, lng: 20.2763494 },
    { lat: 48.5564204, lng: 20.2781203 },
    { lat: 48.5567003, lng: 20.2785638 },
    { lat: 48.5569811, lng: 20.2788845 },
    { lat: 48.5570935, lng: 20.2791673 },
    { lat: 48.5572597, lng: 20.2797241 },
    { lat: 48.5573728, lng: 20.2804193 },
    { lat: 48.5583013, lng: 20.2844863 },
    { lat: 48.5584966, lng: 20.2851233 },
    { lat: 48.558454, lng: 20.2881309 },
    { lat: 48.5586945, lng: 20.2907412 },
    { lat: 48.5590366, lng: 20.2915365 },
    { lat: 48.5592768, lng: 20.2925762 },
    { lat: 48.5596121, lng: 20.2930802 },
    { lat: 48.5598325, lng: 20.293277 },
    { lat: 48.5600365, lng: 20.2931354 },
    { lat: 48.56061, lng: 20.2944856 },
    { lat: 48.5606232, lng: 20.2945093 },
    { lat: 48.5610953, lng: 20.2939118 },
    { lat: 48.5618033, lng: 20.2924438 },
    { lat: 48.5620046, lng: 20.2921219 },
    { lat: 48.5623391, lng: 20.2917833 },
    { lat: 48.5625593, lng: 20.29162 },
    { lat: 48.5645786, lng: 20.2906793 },
    { lat: 48.5646707, lng: 20.2905317 },
    { lat: 48.5647258, lng: 20.290558 },
    { lat: 48.5650962, lng: 20.290154 },
    { lat: 48.565334, lng: 20.2898156 },
    { lat: 48.5655173, lng: 20.2897558 },
    { lat: 48.565518, lng: 20.2896281 },
    { lat: 48.5658527, lng: 20.2890609 },
    { lat: 48.566082, lng: 20.2885394 },
    { lat: 48.5664454, lng: 20.287528 },
    { lat: 48.5669501, lng: 20.2865612 },
    { lat: 48.5673551, lng: 20.2860951 },
    { lat: 48.5680455, lng: 20.2854636 },
    { lat: 48.5684593, lng: 20.2849337 },
    { lat: 48.5685853, lng: 20.2846476 },
    { lat: 48.5694012, lng: 20.2819524 },
    { lat: 48.5698542, lng: 20.2811597 },
    { lat: 48.5701552, lng: 20.2808226 },
    { lat: 48.5706821, lng: 20.2804497 },
    { lat: 48.5722157, lng: 20.2799267 },
    { lat: 48.572668, lng: 20.2796632 },
    { lat: 48.572884, lng: 20.2794834 },
    { lat: 48.5733254, lng: 20.2788844 },
    { lat: 48.5739978, lng: 20.2777448 },
    { lat: 48.5740313, lng: 20.2778261 },
    { lat: 48.5741124, lng: 20.2777655 },
    { lat: 48.5740678, lng: 20.2776333 },
    { lat: 48.5741115, lng: 20.2775764 },
    { lat: 48.5746084, lng: 20.2772085 },
    { lat: 48.5748058, lng: 20.2771648 },
    { lat: 48.5750758, lng: 20.2772416 },
    { lat: 48.5766086, lng: 20.2781795 },
    { lat: 48.5769338, lng: 20.2783179 },
    { lat: 48.5772736, lng: 20.2782824 },
    { lat: 48.5784433, lng: 20.2776148 },
    { lat: 48.5789403, lng: 20.2775685 },
    { lat: 48.5798433, lng: 20.2780718 },
    { lat: 48.5803807, lng: 20.2785927 },
    { lat: 48.5810557, lng: 20.2790019 },
    { lat: 48.5817162, lng: 20.2790476 },
    { lat: 48.583278, lng: 20.2788695 },
    { lat: 48.5834723, lng: 20.2789118 },
    { lat: 48.58399, lng: 20.2791908 },
    { lat: 48.584704, lng: 20.2797034 },
    { lat: 48.5854459, lng: 20.2800349 },
    { lat: 48.5857052, lng: 20.2800454 },
    { lat: 48.5857465, lng: 20.2800356 },
    { lat: 48.5862164, lng: 20.2799288 },
    { lat: 48.5867701, lng: 20.2796319 },
    { lat: 48.5882245, lng: 20.2785061 },
    { lat: 48.588769, lng: 20.2784309 },
    { lat: 48.5897021, lng: 20.2787357 },
    { lat: 48.5900808, lng: 20.2787697 },
    { lat: 48.5909525, lng: 20.2784577 },
    { lat: 48.5919232, lng: 20.2782013 },
    { lat: 48.5922095, lng: 20.2781788 },
    { lat: 48.5925761, lng: 20.2782171 },
    { lat: 48.5933091, lng: 20.2787013 },
    { lat: 48.5938056, lng: 20.2788385 },
    { lat: 48.5941373, lng: 20.2788006 },
    { lat: 48.5945, lng: 20.2786567 },
    { lat: 48.5949659, lng: 20.2783349 },
    { lat: 48.5948126, lng: 20.2774774 },
    { lat: 48.5941564, lng: 20.2721286 },
    { lat: 48.5941052, lng: 20.2711197 },
    { lat: 48.5944981, lng: 20.2672438 },
    { lat: 48.5946304, lng: 20.2635877 },
    { lat: 48.5944893, lng: 20.2625886 },
    { lat: 48.5940515, lng: 20.2622225 },
    { lat: 48.593714, lng: 20.2620323 },
    { lat: 48.5934639, lng: 20.2617467 },
    { lat: 48.5931603, lng: 20.2611319 },
    { lat: 48.59304, lng: 20.2607404 },
    { lat: 48.592838, lng: 20.2603359 },
    { lat: 48.5927112, lng: 20.2598937 },
    { lat: 48.5921497, lng: 20.2590219 },
    { lat: 48.5918719, lng: 20.2587491 },
    { lat: 48.5917014, lng: 20.2584546 },
    { lat: 48.5915512, lng: 20.2581302 },
    { lat: 48.5914006, lng: 20.2574564 },
    { lat: 48.5908457, lng: 20.2561055 },
    { lat: 48.5905719, lng: 20.2551053 },
    { lat: 48.5899506, lng: 20.255029 },
    { lat: 48.5888871, lng: 20.255074 },
    { lat: 48.5880783, lng: 20.2547343 },
    { lat: 48.5845907, lng: 20.2542268 },
    { lat: 48.5845267, lng: 20.2542031 },
    { lat: 48.5843998, lng: 20.2532769 },
    { lat: 48.5827988, lng: 20.2530941 },
    { lat: 48.5827102, lng: 20.2529897 },
    { lat: 48.582077, lng: 20.2526676 },
    { lat: 48.5814101, lng: 20.2527744 },
    { lat: 48.5807797, lng: 20.2532072 },
    { lat: 48.5805414, lng: 20.2532749 },
    { lat: 48.5799664, lng: 20.2532909 },
    { lat: 48.5793869, lng: 20.2537853 },
    { lat: 48.5787284, lng: 20.254699 },
    { lat: 48.5787082, lng: 20.2551283 },
    { lat: 48.5787698, lng: 20.2567652 },
    { lat: 48.5787606, lng: 20.2593688 },
    { lat: 48.5803386, lng: 20.2588901 },
    { lat: 48.5818353, lng: 20.2585814 },
    { lat: 48.5815439, lng: 20.2599535 },
    { lat: 48.5805661, lng: 20.2600587 },
    { lat: 48.5786967, lng: 20.2604842 },
    { lat: 48.5786818, lng: 20.2607092 },
    { lat: 48.5789154, lng: 20.2625074 },
    { lat: 48.5789886, lng: 20.2633882 },
    { lat: 48.5791054, lng: 20.2656578 },
    { lat: 48.5791078, lng: 20.2660268 },
    { lat: 48.5790427, lng: 20.2660344 },
    { lat: 48.5786068, lng: 20.2660057 },
    { lat: 48.5782957, lng: 20.2658004 },
    { lat: 48.5780211, lng: 20.2654645 },
    { lat: 48.5774318, lng: 20.2625553 },
    { lat: 48.5774873, lng: 20.2620819 },
    { lat: 48.5769744, lng: 20.261782 },
    { lat: 48.5763768, lng: 20.2610295 },
    { lat: 48.5753346, lng: 20.2603076 },
    { lat: 48.5750639, lng: 20.2602128 },
    { lat: 48.5734904, lng: 20.2600369 },
    { lat: 48.5731173, lng: 20.2585805 },
    { lat: 48.5727676, lng: 20.2580277 },
    { lat: 48.572269, lng: 20.2570132 },
    { lat: 48.5716938, lng: 20.2564513 },
    { lat: 48.5715191, lng: 20.2563084 },
  ],
  Magnezitovce: [
    { lat: 48.692741, lng: 20.263524 },
    { lat: 48.693011, lng: 20.262497 },
    { lat: 48.693151, lng: 20.260505 },
    { lat: 48.693361, lng: 20.257703 },
    { lat: 48.693728, lng: 20.256621 },
    { lat: 48.693914, lng: 20.25626 },
    { lat: 48.694989, lng: 20.255168 },
    { lat: 48.695749, lng: 20.254631 },
    { lat: 48.696993, lng: 20.253941 },
    { lat: 48.697776, lng: 20.252964 },
    { lat: 48.698116, lng: 20.251914 },
    { lat: 48.698716, lng: 20.251053 },
    { lat: 48.699346, lng: 20.249935 },
    { lat: 48.699803, lng: 20.248063 },
    { lat: 48.700129, lng: 20.246623 },
    { lat: 48.700138, lng: 20.246582 },
    { lat: 48.700175, lng: 20.246423 },
    { lat: 48.700568, lng: 20.244387 },
    { lat: 48.700976, lng: 20.243675 },
    { lat: 48.701188, lng: 20.243395 },
    { lat: 48.701379, lng: 20.242497 },
    { lat: 48.7016, lng: 20.241923 },
    { lat: 48.70217, lng: 20.240852 },
    { lat: 48.702303, lng: 20.240664 },
    { lat: 48.7031, lng: 20.239537 },
    { lat: 48.704108, lng: 20.23768 },
    { lat: 48.70444, lng: 20.237116 },
    { lat: 48.704768, lng: 20.236558 },
    { lat: 48.705858, lng: 20.235374 },
    { lat: 48.703752, lng: 20.233868 },
    { lat: 48.7008787, lng: 20.2336376 },
    { lat: 48.6981905, lng: 20.2305843 },
    { lat: 48.6979791, lng: 20.2307329 },
    { lat: 48.6961006, lng: 20.2284113 },
    { lat: 48.6956884, lng: 20.2274549 },
    { lat: 48.69543, lng: 20.2270755 },
    { lat: 48.6948583, lng: 20.227027 },
    { lat: 48.6943849, lng: 20.227245 },
    { lat: 48.6939742, lng: 20.2272257 },
    { lat: 48.6934262, lng: 20.2267586 },
    { lat: 48.6928604, lng: 20.2258141 },
    { lat: 48.6922669, lng: 20.2253604 },
    { lat: 48.6921847, lng: 20.2253326 },
    { lat: 48.6920315, lng: 20.2255052 },
    { lat: 48.6917968, lng: 20.2263125 },
    { lat: 48.6914736, lng: 20.2263986 },
    { lat: 48.6906648, lng: 20.2261973 },
    { lat: 48.6903717, lng: 20.2259894 },
    { lat: 48.6898784, lng: 20.225224 },
    { lat: 48.6894872, lng: 20.2248183 },
    { lat: 48.6892839, lng: 20.2247206 },
    { lat: 48.688945, lng: 20.2248088 },
    { lat: 48.6884092, lng: 20.2253815 },
    { lat: 48.687577, lng: 20.2260004 },
    { lat: 48.6874076, lng: 20.2260191 },
    { lat: 48.6868576, lng: 20.2257575 },
    { lat: 48.6861661, lng: 20.2249393 },
    { lat: 48.6858696, lng: 20.2247877 },
    { lat: 48.6852803, lng: 20.2246406 },
    { lat: 48.6846307, lng: 20.2240514 },
    { lat: 48.6842085, lng: 20.2235734 },
    { lat: 48.6829178, lng: 20.2230389 },
    { lat: 48.6826871, lng: 20.2226465 },
    { lat: 48.6815184, lng: 20.221072 },
    { lat: 48.6805249, lng: 20.2199992 },
    { lat: 48.680111, lng: 20.2194499 },
    { lat: 48.6796783, lng: 20.2185135 },
    { lat: 48.678312, lng: 20.2173521 },
    { lat: 48.6780501, lng: 20.2170239 },
    { lat: 48.6775038, lng: 20.2165751 },
    { lat: 48.6757204, lng: 20.215543 },
    { lat: 48.6753711, lng: 20.2154833 },
    { lat: 48.6750331, lng: 20.2156185 },
    { lat: 48.6748918, lng: 20.215579 },
    { lat: 48.6744777, lng: 20.2152222 },
    { lat: 48.6743383, lng: 20.2152591 },
    { lat: 48.6740425, lng: 20.2154924 },
    { lat: 48.6739668, lng: 20.215458 },
    { lat: 48.6738148, lng: 20.2155456 },
    { lat: 48.6736359, lng: 20.2154632 },
    { lat: 48.6735618, lng: 20.2155238 },
    { lat: 48.6733188, lng: 20.2153071 },
    { lat: 48.673122, lng: 20.215231 },
    { lat: 48.6726208, lng: 20.2152849 },
    { lat: 48.6724443, lng: 20.2153804 },
    { lat: 48.6721798, lng: 20.2153821 },
    { lat: 48.6719344, lng: 20.2152459 },
    { lat: 48.6718077, lng: 20.2152884 },
    { lat: 48.6709202, lng: 20.2148604 },
    { lat: 48.6705058, lng: 20.2143598 },
    { lat: 48.6705139, lng: 20.2141969 },
    { lat: 48.6697569, lng: 20.2131186 },
    { lat: 48.669381, lng: 20.2129312 },
    { lat: 48.6690982, lng: 20.2128989 },
    { lat: 48.6689823, lng: 20.2128179 },
    { lat: 48.6689518, lng: 20.2127024 },
    { lat: 48.6688365, lng: 20.212688 },
    { lat: 48.6687815, lng: 20.2128362 },
    { lat: 48.6686989, lng: 20.2128224 },
    { lat: 48.6684572, lng: 20.2124973 },
    { lat: 48.6682556, lng: 20.2124958 },
    { lat: 48.6677968, lng: 20.2127688 },
    { lat: 48.6665138, lng: 20.2128188 },
    { lat: 48.6663754, lng: 20.2127691 },
    { lat: 48.6662019, lng: 20.2123442 },
    { lat: 48.6660399, lng: 20.2122335 },
    { lat: 48.6657972, lng: 20.2122211 },
    { lat: 48.6656289, lng: 20.2121256 },
    { lat: 48.665236, lng: 20.2123813 },
    { lat: 48.6649856, lng: 20.2122031 },
    { lat: 48.6648671, lng: 20.2122257 },
    { lat: 48.6648173, lng: 20.2123019 },
    { lat: 48.6645018, lng: 20.2120723 },
    { lat: 48.6643873, lng: 20.2121679 },
    { lat: 48.6642836, lng: 20.2120812 },
    { lat: 48.6640375, lng: 20.2120958 },
    { lat: 48.664046, lng: 20.2122247 },
    { lat: 48.6639978, lng: 20.2122879 },
    { lat: 48.6638266, lng: 20.2122867 },
    { lat: 48.6637879, lng: 20.212193 },
    { lat: 48.6636942, lng: 20.2122436 },
    { lat: 48.6636073, lng: 20.2122077 },
    { lat: 48.663614, lng: 20.2121219 },
    { lat: 48.6634542, lng: 20.2120449 },
    { lat: 48.663424, lng: 20.2121029 },
    { lat: 48.6632957, lng: 20.2120522 },
    { lat: 48.6631538, lng: 20.2122469 },
    { lat: 48.6631352, lng: 20.2123462 },
    { lat: 48.6630829, lng: 20.2123324 },
    { lat: 48.6630696, lng: 20.212252 },
    { lat: 48.662851, lng: 20.2123333 },
    { lat: 48.6628219, lng: 20.2122037 },
    { lat: 48.662875, lng: 20.2121722 },
    { lat: 48.6628376, lng: 20.2120591 },
    { lat: 48.6627496, lng: 20.2119991 },
    { lat: 48.6626169, lng: 20.2123699 },
    { lat: 48.66254, lng: 20.2124464 },
    { lat: 48.6623152, lng: 20.2125399 },
    { lat: 48.6620004, lng: 20.2125522 },
    { lat: 48.662051, lng: 20.2124646 },
    { lat: 48.6618842, lng: 20.2122119 },
    { lat: 48.6618201, lng: 20.2122441 },
    { lat: 48.661644, lng: 20.2120892 },
    { lat: 48.6615242, lng: 20.2121631 },
    { lat: 48.6614234, lng: 20.2120095 },
    { lat: 48.6613305, lng: 20.2121348 },
    { lat: 48.6612353, lng: 20.2120384 },
    { lat: 48.6612191, lng: 20.2117998 },
    { lat: 48.661113, lng: 20.2119067 },
    { lat: 48.6610737, lng: 20.2120806 },
    { lat: 48.6607551, lng: 20.212193 },
    { lat: 48.6606994, lng: 20.2120406 },
    { lat: 48.660545, lng: 20.2120087 },
    { lat: 48.6605017, lng: 20.2118602 },
    { lat: 48.6603494, lng: 20.2116575 },
    { lat: 48.6600308, lng: 20.2114614 },
    { lat: 48.6599561, lng: 20.2112829 },
    { lat: 48.6598103, lng: 20.2112421 },
    { lat: 48.6597333, lng: 20.2113713 },
    { lat: 48.6596285, lng: 20.2113174 },
    { lat: 48.6595849, lng: 20.2113531 },
    { lat: 48.6594649, lng: 20.2111987 },
    { lat: 48.6594348, lng: 20.2110636 },
    { lat: 48.6592967, lng: 20.211016 },
    { lat: 48.6589768, lng: 20.2112151 },
    { lat: 48.6587719, lng: 20.2112124 },
    { lat: 48.6587138, lng: 20.2111123 },
    { lat: 48.6585415, lng: 20.21117 },
    { lat: 48.6583356, lng: 20.2110082 },
    { lat: 48.6583242, lng: 20.2112244 },
    { lat: 48.6580308, lng: 20.2149873 },
    { lat: 48.6569974, lng: 20.2195888 },
    { lat: 48.6568224, lng: 20.2233319 },
    { lat: 48.6568514, lng: 20.2240866 },
    { lat: 48.6567559, lng: 20.225616 },
    { lat: 48.6567748, lng: 20.2276985 },
    { lat: 48.6566902, lng: 20.2286527 },
    { lat: 48.6564269, lng: 20.2300694 },
    { lat: 48.6561164, lng: 20.2321754 },
    { lat: 48.6561355, lng: 20.2331034 },
    { lat: 48.6566233, lng: 20.2350878 },
    { lat: 48.6567857, lng: 20.2355451 },
    { lat: 48.6570843, lng: 20.2361403 },
    { lat: 48.6573784, lng: 20.2365984 },
    { lat: 48.6576455, lng: 20.2371955 },
    { lat: 48.6577987, lng: 20.2372846 },
    { lat: 48.6580138, lng: 20.2379633 },
    { lat: 48.6586222, lng: 20.2392458 },
    { lat: 48.6588625, lng: 20.2400487 },
    { lat: 48.6589797, lng: 20.2406151 },
    { lat: 48.6592553, lng: 20.2410063 },
    { lat: 48.6594226, lng: 20.2415878 },
    { lat: 48.6595546, lng: 20.2422726 },
    { lat: 48.6597982, lng: 20.2426168 },
    { lat: 48.6600667, lng: 20.2433684 },
    { lat: 48.6600444, lng: 20.2437416 },
    { lat: 48.6601053, lng: 20.2439434 },
    { lat: 48.6601398, lng: 20.2445844 },
    { lat: 48.6600935, lng: 20.2451197 },
    { lat: 48.6597581, lng: 20.2456951 },
    { lat: 48.659695, lng: 20.245859 },
    { lat: 48.6597031, lng: 20.2460912 },
    { lat: 48.6597515, lng: 20.246265 },
    { lat: 48.6603528, lng: 20.2468814 },
    { lat: 48.6606667, lng: 20.2476139 },
    { lat: 48.6608484, lng: 20.2479053 },
    { lat: 48.6609046, lng: 20.2481022 },
    { lat: 48.6610708, lng: 20.2483173 },
    { lat: 48.6612295, lng: 20.2489673 },
    { lat: 48.6614252, lng: 20.2495271 },
    { lat: 48.6614273, lng: 20.2500934 },
    { lat: 48.6616449, lng: 20.2508076 },
    { lat: 48.6617589, lng: 20.2513551 },
    { lat: 48.6619778, lng: 20.2517921 },
    { lat: 48.6619361, lng: 20.2521275 },
    { lat: 48.661997, lng: 20.2523888 },
    { lat: 48.6625394, lng: 20.2525757 },
    { lat: 48.662949, lng: 20.2522818 },
    { lat: 48.6631499, lng: 20.2522359 },
    { lat: 48.6634063, lng: 20.2523997 },
    { lat: 48.6635567, lng: 20.2527816 },
    { lat: 48.6636782, lng: 20.2528358 },
    { lat: 48.664128, lng: 20.2532747 },
    { lat: 48.6643136, lng: 20.2532964 },
    { lat: 48.6645628, lng: 20.2534435 },
    { lat: 48.6651195, lng: 20.2545913 },
    { lat: 48.6655382, lng: 20.2552953 },
    { lat: 48.6657451, lng: 20.255801 },
    { lat: 48.6658217, lng: 20.2564536 },
    { lat: 48.6659159, lng: 20.2566699 },
    { lat: 48.6659323, lng: 20.2569392 },
    { lat: 48.6657788, lng: 20.2578127 },
    { lat: 48.6659549, lng: 20.258649 },
    { lat: 48.6660783, lng: 20.2595482 },
    { lat: 48.6662779, lng: 20.2600956 },
    { lat: 48.6664976, lng: 20.2604396 },
    { lat: 48.6668494, lng: 20.2611599 },
    { lat: 48.6671289, lng: 20.2614251 },
    { lat: 48.667918, lng: 20.2626636 },
    { lat: 48.668854, lng: 20.2632975 },
    { lat: 48.6702578, lng: 20.2645335 },
    { lat: 48.6705537, lng: 20.2650399 },
    { lat: 48.670978, lng: 20.2654288 },
    { lat: 48.6713734, lng: 20.266171 },
    { lat: 48.6716637, lng: 20.2665671 },
    { lat: 48.6722227, lng: 20.2671439 },
    { lat: 48.6724305, lng: 20.2672185 },
    { lat: 48.6739677, lng: 20.2672714 },
    { lat: 48.6739753, lng: 20.2672971 },
    { lat: 48.6740348, lng: 20.2674877 },
    { lat: 48.6742086, lng: 20.2676106 },
    { lat: 48.6744024, lng: 20.2680115 },
    { lat: 48.6748041, lng: 20.268575 },
    { lat: 48.6756982, lng: 20.2696006 },
    { lat: 48.6760551, lng: 20.2698842 },
    { lat: 48.6768305, lng: 20.2701554 },
    { lat: 48.6773069, lng: 20.2701845 },
    { lat: 48.6776657, lng: 20.2703115 },
    { lat: 48.6781652, lng: 20.270614 },
    { lat: 48.6789262, lng: 20.2707208 },
    { lat: 48.6800121, lng: 20.2709872 },
    { lat: 48.6810938, lng: 20.2725392 },
    { lat: 48.6815487, lng: 20.2734512 },
    { lat: 48.6821102, lng: 20.2743269 },
    { lat: 48.6820756, lng: 20.2747887 },
    { lat: 48.682129, lng: 20.276026 },
    { lat: 48.682386, lng: 20.275325 },
    { lat: 48.682724, lng: 20.274753 },
    { lat: 48.683073, lng: 20.274552 },
    { lat: 48.683502, lng: 20.274402 },
    { lat: 48.684082, lng: 20.274284 },
    { lat: 48.684449, lng: 20.274109 },
    { lat: 48.684593, lng: 20.274053 },
    { lat: 48.685125, lng: 20.273674 },
    { lat: 48.68544, lng: 20.273422 },
    { lat: 48.685635, lng: 20.273247 },
    { lat: 48.685834, lng: 20.273 },
    { lat: 48.686057, lng: 20.272668 },
    { lat: 48.686694, lng: 20.27217 },
    { lat: 48.687923, lng: 20.271317 },
    { lat: 48.688051, lng: 20.270407 },
    { lat: 48.688501, lng: 20.269345 },
    { lat: 48.68867, lng: 20.269003 },
    { lat: 48.688828, lng: 20.268451 },
    { lat: 48.688982, lng: 20.267993 },
    { lat: 48.689103, lng: 20.267571 },
    { lat: 48.689313, lng: 20.266975 },
    { lat: 48.689468, lng: 20.266408 },
    { lat: 48.689645, lng: 20.265884 },
    { lat: 48.689832, lng: 20.265551 },
    { lat: 48.690919, lng: 20.264399 },
    { lat: 48.691941, lng: 20.263899 },
    { lat: 48.692384, lng: 20.263823 },
    { lat: 48.692741, lng: 20.263524 },
  ],
  Revúca: [
    { lat: 48.7397148, lng: 20.115986 },
    { lat: 48.738312, lng: 20.1149542 },
    { lat: 48.7381272, lng: 20.1146759 },
    { lat: 48.7376835, lng: 20.1142887 },
    { lat: 48.7373964, lng: 20.1139337 },
    { lat: 48.7371979, lng: 20.1139554 },
    { lat: 48.7369282, lng: 20.1136619 },
    { lat: 48.7368176, lng: 20.1134096 },
    { lat: 48.7366916, lng: 20.1133015 },
    { lat: 48.7363106, lng: 20.1126862 },
    { lat: 48.7358559, lng: 20.1123136 },
    { lat: 48.7337058, lng: 20.1150717 },
    { lat: 48.7317999, lng: 20.1169993 },
    { lat: 48.7305051, lng: 20.1177279 },
    { lat: 48.730177, lng: 20.1176692 },
    { lat: 48.7291265, lng: 20.1178135 },
    { lat: 48.727898, lng: 20.1177367 },
    { lat: 48.7271834, lng: 20.1176113 },
    { lat: 48.7268677, lng: 20.1173015 },
    { lat: 48.7261863, lng: 20.1168623 },
    { lat: 48.7257173, lng: 20.1162696 },
    { lat: 48.7255308, lng: 20.1161425 },
    { lat: 48.725045, lng: 20.1153876 },
    { lat: 48.7248889, lng: 20.1148756 },
    { lat: 48.7245064, lng: 20.1140579 },
    { lat: 48.7241127, lng: 20.1134903 },
    { lat: 48.7234956, lng: 20.1129718 },
    { lat: 48.7225674, lng: 20.1118062 },
    { lat: 48.7222256, lng: 20.111521 },
    { lat: 48.7215583, lng: 20.1111202 },
    { lat: 48.7212628, lng: 20.1108089 },
    { lat: 48.7210575, lng: 20.1106886 },
    { lat: 48.7206234, lng: 20.1100966 },
    { lat: 48.7204531, lng: 20.109764 },
    { lat: 48.7198811, lng: 20.1095484 },
    { lat: 48.7198461, lng: 20.1095699 },
    { lat: 48.7193124, lng: 20.108724 },
    { lat: 48.7191747, lng: 20.1082999 },
    { lat: 48.7189313, lng: 20.1072261 },
    { lat: 48.7188737, lng: 20.1064746 },
    { lat: 48.7186192, lng: 20.1054287 },
    { lat: 48.7186139, lng: 20.1050554 },
    { lat: 48.7185273, lng: 20.1045309 },
    { lat: 48.7183672, lng: 20.1040618 },
    { lat: 48.718069, lng: 20.1028823 },
    { lat: 48.7177339, lng: 20.102238 },
    { lat: 48.716664, lng: 20.1012501 },
    { lat: 48.7161091, lng: 20.1005416 },
    { lat: 48.7150661, lng: 20.1003794 },
    { lat: 48.7145011, lng: 20.100138 },
    { lat: 48.714147, lng: 20.1002114 },
    { lat: 48.7139322, lng: 20.1000827 },
    { lat: 48.7132452, lng: 20.0999996 },
    { lat: 48.7119766, lng: 20.0993589 },
    { lat: 48.7113277, lng: 20.0989135 },
    { lat: 48.7105222, lng: 20.0980341 },
    { lat: 48.7102907, lng: 20.0979355 },
    { lat: 48.709278, lng: 20.0970206 },
    { lat: 48.7092416, lng: 20.096023 },
    { lat: 48.7087056, lng: 20.0955346 },
    { lat: 48.7082011, lng: 20.094548 },
    { lat: 48.7078972, lng: 20.093727 },
    { lat: 48.7073759, lng: 20.0926624 },
    { lat: 48.7062063, lng: 20.0907556 },
    { lat: 48.7045733, lng: 20.0874393 },
    { lat: 48.7038151, lng: 20.086586 },
    { lat: 48.7037702, lng: 20.0863435 },
    { lat: 48.7034482, lng: 20.085934 },
    { lat: 48.7035837, lng: 20.0855832 },
    { lat: 48.7017752, lng: 20.0816764 },
    { lat: 48.7015039, lng: 20.080313 },
    { lat: 48.7005779, lng: 20.0768007 },
    { lat: 48.7004209, lng: 20.0759454 },
    { lat: 48.700128, lng: 20.0749304 },
    { lat: 48.6998157, lng: 20.0745678 },
    { lat: 48.6995723, lng: 20.0735813 },
    { lat: 48.6995803, lng: 20.0730653 },
    { lat: 48.6996789, lng: 20.0726392 },
    { lat: 48.6997137, lng: 20.0720351 },
    { lat: 48.6996719, lng: 20.0712368 },
    { lat: 48.6995326, lng: 20.0705079 },
    { lat: 48.6990887, lng: 20.0690838 },
    { lat: 48.6992086, lng: 20.0685235 },
    { lat: 48.6991264, lng: 20.0665872 },
    { lat: 48.6988559, lng: 20.0652706 },
    { lat: 48.6988341, lng: 20.0634884 },
    { lat: 48.6987368, lng: 20.0627103 },
    { lat: 48.698756, lng: 20.062061 },
    { lat: 48.6984995, lng: 20.0593918 },
    { lat: 48.6982816, lng: 20.0580203 },
    { lat: 48.6974104, lng: 20.0563988 },
    { lat: 48.6968989, lng: 20.0557925 },
    { lat: 48.6965663, lng: 20.0552145 },
    { lat: 48.6959786, lng: 20.0544335 },
    { lat: 48.6958906, lng: 20.0539943 },
    { lat: 48.6954209, lng: 20.0527391 },
    { lat: 48.6951169, lng: 20.0514611 },
    { lat: 48.6948202, lng: 20.0508798 },
    { lat: 48.6947112, lng: 20.0502298 },
    { lat: 48.6947836, lng: 20.0495844 },
    { lat: 48.6942679, lng: 20.0477941 },
    { lat: 48.6939509, lng: 20.04686 },
    { lat: 48.6934606, lng: 20.0457929 },
    { lat: 48.6932394, lng: 20.0447493 },
    { lat: 48.6930241, lng: 20.0441735 },
    { lat: 48.6925648, lng: 20.0433078 },
    { lat: 48.6922722, lng: 20.0425301 },
    { lat: 48.6920058, lng: 20.0420526 },
    { lat: 48.6918322, lng: 20.0415036 },
    { lat: 48.691741, lng: 20.0411771 },
    { lat: 48.6917588, lng: 20.0405248 },
    { lat: 48.6916806, lng: 20.0403468 },
    { lat: 48.6915452, lng: 20.0397161 },
    { lat: 48.6913962, lng: 20.0388179 },
    { lat: 48.6908586, lng: 20.037908 },
    { lat: 48.6906179, lng: 20.0381893 },
    { lat: 48.6902851, lng: 20.0383937 },
    { lat: 48.6893289, lng: 20.0389683 },
    { lat: 48.6889336, lng: 20.0390781 },
    { lat: 48.6881498, lng: 20.0401436 },
    { lat: 48.6872452, lng: 20.040873 },
    { lat: 48.6863424, lng: 20.0420022 },
    { lat: 48.6859719, lng: 20.042573 },
    { lat: 48.685573, lng: 20.0443325 },
    { lat: 48.6852456, lng: 20.0449127 },
    { lat: 48.6852174, lng: 20.0455652 },
    { lat: 48.6850436, lng: 20.0464529 },
    { lat: 48.6850733, lng: 20.0469513 },
    { lat: 48.6849608, lng: 20.048151 },
    { lat: 48.6845585, lng: 20.0500519 },
    { lat: 48.6845138, lng: 20.0537344 },
    { lat: 48.6843358, lng: 20.0546356 },
    { lat: 48.6842825, lng: 20.0555496 },
    { lat: 48.683923, lng: 20.056337 },
    { lat: 48.6834984, lng: 20.0568575 },
    { lat: 48.6825153, lng: 20.0575412 },
    { lat: 48.6821052, lng: 20.057921 },
    { lat: 48.6809562, lng: 20.0592301 },
    { lat: 48.6801457, lng: 20.0597335 },
    { lat: 48.6797773, lng: 20.0601624 },
    { lat: 48.6793711, lng: 20.0609905 },
    { lat: 48.6783578, lng: 20.0623679 },
    { lat: 48.6779218, lng: 20.0627201 },
    { lat: 48.6765646, lng: 20.0635668 },
    { lat: 48.674329, lng: 20.0658888 },
    { lat: 48.6734906, lng: 20.0660995 },
    { lat: 48.67287, lng: 20.0663439 },
    { lat: 48.6722653, lng: 20.0669504 },
    { lat: 48.6720567, lng: 20.0674104 },
    { lat: 48.6717858, lng: 20.0691743 },
    { lat: 48.6717232, lng: 20.0705451 },
    { lat: 48.6716471, lng: 20.0709962 },
    { lat: 48.671205, lng: 20.0719119 },
    { lat: 48.6709491, lng: 20.0729765 },
    { lat: 48.6707799, lng: 20.0741446 },
    { lat: 48.6707722, lng: 20.0755169 },
    { lat: 48.6701793, lng: 20.0762675 },
    { lat: 48.669394, lng: 20.0776301 },
    { lat: 48.6689176, lng: 20.0782068 },
    { lat: 48.6685237, lng: 20.0784077 },
    { lat: 48.6681448, lng: 20.0786995 },
    { lat: 48.6678116, lng: 20.078839 },
    { lat: 48.6670047, lng: 20.0794585 },
    { lat: 48.6666291, lng: 20.0798308 },
    { lat: 48.6668512, lng: 20.0801061 },
    { lat: 48.6676534, lng: 20.0819747 },
    { lat: 48.6677479, lng: 20.083481 },
    { lat: 48.6676996, lng: 20.085342 },
    { lat: 48.66777, lng: 20.0860962 },
    { lat: 48.6675771, lng: 20.086937 },
    { lat: 48.6672195, lng: 20.0877473 },
    { lat: 48.6665539, lng: 20.0882173 },
    { lat: 48.6658053, lng: 20.088995 },
    { lat: 48.6655509, lng: 20.0895205 },
    { lat: 48.6649361, lng: 20.0915271 },
    { lat: 48.6641369, lng: 20.0936236 },
    { lat: 48.6638458, lng: 20.0939957 },
    { lat: 48.6629328, lng: 20.0948004 },
    { lat: 48.6626657, lng: 20.0952106 },
    { lat: 48.6624421, lng: 20.0960485 },
    { lat: 48.6621386, lng: 20.0965846 },
    { lat: 48.6618645, lng: 20.0969344 },
    { lat: 48.6616076, lng: 20.09704 },
    { lat: 48.6612115, lng: 20.0974604 },
    { lat: 48.6611026, lng: 20.0978536 },
    { lat: 48.6602584, lng: 20.0989671 },
    { lat: 48.6599626, lng: 20.0991668 },
    { lat: 48.6597253, lng: 20.0995335 },
    { lat: 48.6592394, lng: 20.1000459 },
    { lat: 48.6588386, lng: 20.1010652 },
    { lat: 48.658613, lng: 20.1022926 },
    { lat: 48.6586966, lng: 20.1027654 },
    { lat: 48.6586877, lng: 20.1032643 },
    { lat: 48.6585218, lng: 20.1038349 },
    { lat: 48.658226, lng: 20.104259 },
    { lat: 48.658019, lng: 20.1047621 },
    { lat: 48.6579891, lng: 20.1048269 },
    { lat: 48.658186, lng: 20.1047932 },
    { lat: 48.6583414, lng: 20.104876 },
    { lat: 48.6584589, lng: 20.105078 },
    { lat: 48.6585281, lng: 20.1057246 },
    { lat: 48.6586853, lng: 20.1062319 },
    { lat: 48.6590346, lng: 20.106676 },
    { lat: 48.6595473, lng: 20.1070985 },
    { lat: 48.6596557, lng: 20.1072672 },
    { lat: 48.6597086, lng: 20.107568 },
    { lat: 48.6596846, lng: 20.1083889 },
    { lat: 48.6597183, lng: 20.1086288 },
    { lat: 48.6605222, lng: 20.109617 },
    { lat: 48.6609143, lng: 20.1103111 },
    { lat: 48.66056, lng: 20.1110874 },
    { lat: 48.6605639, lng: 20.1115405 },
    { lat: 48.6606629, lng: 20.1117355 },
    { lat: 48.6614605, lng: 20.1127012 },
    { lat: 48.6624498, lng: 20.1125492 },
    { lat: 48.6632931, lng: 20.1128141 },
    { lat: 48.6643147, lng: 20.1129548 },
    { lat: 48.6648223, lng: 20.1139073 },
    { lat: 48.6645874, lng: 20.1146913 },
    { lat: 48.6645788, lng: 20.1150353 },
    { lat: 48.6646514, lng: 20.1152405 },
    { lat: 48.6650368, lng: 20.115546 },
    { lat: 48.6654489, lng: 20.1161247 },
    { lat: 48.6657357, lng: 20.1163624 },
    { lat: 48.6662735, lng: 20.1174754 },
    { lat: 48.6667388, lng: 20.117673 },
    { lat: 48.6670741, lng: 20.1176653 },
    { lat: 48.6673208, lng: 20.117777 },
    { lat: 48.6674414, lng: 20.1180256 },
    { lat: 48.667654, lng: 20.1181287 },
    { lat: 48.6677891, lng: 20.1183506 },
    { lat: 48.6680155, lng: 20.1184044 },
    { lat: 48.6681447, lng: 20.1186215 },
    { lat: 48.6684552, lng: 20.1188556 },
    { lat: 48.6686244, lng: 20.119167 },
    { lat: 48.6688605, lng: 20.1193474 },
    { lat: 48.6690096, lng: 20.1192762 },
    { lat: 48.6692235, lng: 20.1194127 },
    { lat: 48.6694435, lng: 20.1197828 },
    { lat: 48.6695407, lng: 20.1203952 },
    { lat: 48.6698263, lng: 20.1209096 },
    { lat: 48.6700187, lng: 20.1214269 },
    { lat: 48.6706004, lng: 20.1223268 },
    { lat: 48.6707444, lng: 20.1228183 },
    { lat: 48.6709179, lng: 20.1229832 },
    { lat: 48.671199, lng: 20.1239381 },
    { lat: 48.6711243, lng: 20.1246521 },
    { lat: 48.6713615, lng: 20.1257704 },
    { lat: 48.671368, lng: 20.1260673 },
    { lat: 48.671271, lng: 20.1266992 },
    { lat: 48.6711246, lng: 20.1268938 },
    { lat: 48.6705945, lng: 20.1268198 },
    { lat: 48.6705822, lng: 20.1270454 },
    { lat: 48.6708978, lng: 20.1272431 },
    { lat: 48.6708979, lng: 20.127582 },
    { lat: 48.6703237, lng: 20.1292442 },
    { lat: 48.6701943, lng: 20.1293581 },
    { lat: 48.6700059, lng: 20.1293763 },
    { lat: 48.6697463, lng: 20.1295843 },
    { lat: 48.6695885, lng: 20.129802 },
    { lat: 48.6697798, lng: 20.1298794 },
    { lat: 48.6702168, lng: 20.1295755 },
    { lat: 48.6705059, lng: 20.1299282 },
    { lat: 48.6705799, lng: 20.1301398 },
    { lat: 48.6706185, lng: 20.1308963 },
    { lat: 48.6710449, lng: 20.1310217 },
    { lat: 48.6730713, lng: 20.1320777 },
    { lat: 48.6761428, lng: 20.1330501 },
    { lat: 48.6768627, lng: 20.133081 },
    { lat: 48.6774009, lng: 20.1336059 },
    { lat: 48.6782496, lng: 20.1334787 },
    { lat: 48.6786832, lng: 20.1335365 },
    { lat: 48.6792439, lng: 20.1333619 },
    { lat: 48.6794687, lng: 20.1332138 },
    { lat: 48.6799856, lng: 20.1331035 },
    { lat: 48.6806243, lng: 20.1332143 },
    { lat: 48.681075, lng: 20.13352 },
    { lat: 48.6816406, lng: 20.1340784 },
    { lat: 48.6819334, lng: 20.1342444 },
    { lat: 48.6849532, lng: 20.1339198 },
    { lat: 48.6867869, lng: 20.1331434 },
    { lat: 48.6872752, lng: 20.1327445 },
    { lat: 48.687479, lng: 20.1324386 },
    { lat: 48.6879114, lng: 20.1320506 },
    { lat: 48.6881555, lng: 20.131711 },
    { lat: 48.688442, lng: 20.1314417 },
    { lat: 48.6888488, lng: 20.1312545 },
    { lat: 48.6896311, lng: 20.1306608 },
    { lat: 48.6897321, lng: 20.1306485 },
    { lat: 48.6914318, lng: 20.1319299 },
    { lat: 48.6924555, lng: 20.1332541 },
    { lat: 48.6928232, lng: 20.1336243 },
    { lat: 48.6936828, lng: 20.1341018 },
    { lat: 48.6938897, lng: 20.1342918 },
    { lat: 48.6940517, lng: 20.1344804 },
    { lat: 48.6945027, lng: 20.1352689 },
    { lat: 48.6947267, lng: 20.1358142 },
    { lat: 48.6949115, lng: 20.1360698 },
    { lat: 48.695633, lng: 20.1367213 },
    { lat: 48.6961764, lng: 20.1376232 },
    { lat: 48.6963302, lng: 20.1377498 },
    { lat: 48.6964938, lng: 20.1381168 },
    { lat: 48.6965603, lng: 20.138392 },
    { lat: 48.6970074, lng: 20.1390055 },
    { lat: 48.6971551, lng: 20.1391194 },
    { lat: 48.6974578, lng: 20.1391634 },
    { lat: 48.6981799, lng: 20.1395043 },
    { lat: 48.6985109, lng: 20.1398623 },
    { lat: 48.6986827, lng: 20.1398496 },
    { lat: 48.6989215, lng: 20.1400161 },
    { lat: 48.6992729, lng: 20.1399304 },
    { lat: 48.6994101, lng: 20.1400357 },
    { lat: 48.699411, lng: 20.1400049 },
    { lat: 48.6997214, lng: 20.1403777 },
    { lat: 48.6999461, lng: 20.1409195 },
    { lat: 48.7004779, lng: 20.1418407 },
    { lat: 48.7012528, lng: 20.1426197 },
    { lat: 48.701654, lng: 20.1431538 },
    { lat: 48.7025375, lng: 20.1449422 },
    { lat: 48.7029858, lng: 20.1454705 },
    { lat: 48.7032515, lng: 20.1456595 },
    { lat: 48.7035154, lng: 20.1460287 },
    { lat: 48.7040917, lng: 20.1471039 },
    { lat: 48.704396, lng: 20.1474145 },
    { lat: 48.7045506, lng: 20.1477864 },
    { lat: 48.7053944, lng: 20.1490709 },
    { lat: 48.7055197, lng: 20.1494366 },
    { lat: 48.7056571, lng: 20.1496592 },
    { lat: 48.705904, lng: 20.1498582 },
    { lat: 48.706173, lng: 20.1502994 },
    { lat: 48.7064845, lng: 20.1506774 },
    { lat: 48.7066277, lng: 20.1510422 },
    { lat: 48.7069194, lng: 20.1514039 },
    { lat: 48.7073291, lng: 20.152341 },
    { lat: 48.7075922, lng: 20.1525852 },
    { lat: 48.7083782, lng: 20.1529936 },
    { lat: 48.7086058, lng: 20.153276 },
    { lat: 48.708747, lng: 20.1535336 },
    { lat: 48.7088969, lng: 20.1542052 },
    { lat: 48.7089608, lng: 20.1542574 },
    { lat: 48.7093487, lng: 20.155269 },
    { lat: 48.7093381, lng: 20.1563419 },
    { lat: 48.7094005, lng: 20.1566592 },
    { lat: 48.7097232, lng: 20.157537 },
    { lat: 48.7100372, lng: 20.1594544 },
    { lat: 48.7106327, lng: 20.1602261 },
    { lat: 48.7114504, lng: 20.1610059 },
    { lat: 48.7118839, lng: 20.1617576 },
    { lat: 48.7119906, lng: 20.1621971 },
    { lat: 48.7120057, lng: 20.1633483 },
    { lat: 48.712359, lng: 20.1647897 },
    { lat: 48.712829, lng: 20.1655527 },
    { lat: 48.7131603, lng: 20.1658913 },
    { lat: 48.713312, lng: 20.1659943 },
    { lat: 48.7138469, lng: 20.1661601 },
    { lat: 48.7144812, lng: 20.1664971 },
    { lat: 48.7147155, lng: 20.1665019 },
    { lat: 48.7149194, lng: 20.1665834 },
    { lat: 48.7154737, lng: 20.16715 },
    { lat: 48.7157713, lng: 20.1676001 },
    { lat: 48.7161814, lng: 20.1685282 },
    { lat: 48.7166003, lng: 20.1690235 },
    { lat: 48.7170082, lng: 20.1699796 },
    { lat: 48.7171825, lng: 20.1705627 },
    { lat: 48.7176555, lng: 20.1713709 },
    { lat: 48.7177966, lng: 20.1718266 },
    { lat: 48.7180623, lng: 20.1730797 },
    { lat: 48.7183557, lng: 20.1737643 },
    { lat: 48.7187065, lng: 20.1741429 },
    { lat: 48.7188186, lng: 20.1744058 },
    { lat: 48.7191174, lng: 20.1746932 },
    { lat: 48.7195146, lng: 20.1753012 },
    { lat: 48.720173, lng: 20.1760749 },
    { lat: 48.7204558, lng: 20.1763581 },
    { lat: 48.7209977, lng: 20.1768608 },
    { lat: 48.7217552, lng: 20.177441 },
    { lat: 48.7220551, lng: 20.1778654 },
    { lat: 48.722151, lng: 20.1779261 },
    { lat: 48.7224036, lng: 20.1779047 },
    { lat: 48.7227301, lng: 20.1779906 },
    { lat: 48.7233631, lng: 20.1796392 },
    { lat: 48.723676, lng: 20.1802779 },
    { lat: 48.7238679, lng: 20.1811292 },
    { lat: 48.7238202, lng: 20.1832742 },
    { lat: 48.7235497, lng: 20.1843884 },
    { lat: 48.7232647, lng: 20.1849503 },
    { lat: 48.7228393, lng: 20.1862432 },
    { lat: 48.7222811, lng: 20.1884981 },
    { lat: 48.7216812, lng: 20.1895729 },
    { lat: 48.720113, lng: 20.190903 },
    { lat: 48.720737, lng: 20.190464 },
    { lat: 48.721388, lng: 20.190006 },
    { lat: 48.721399, lng: 20.190033 },
    { lat: 48.721444, lng: 20.189991 },
    { lat: 48.721874, lng: 20.189593 },
    { lat: 48.722343, lng: 20.189167 },
    { lat: 48.722826, lng: 20.188751 },
    { lat: 48.723163, lng: 20.188265 },
    { lat: 48.723409, lng: 20.187874 },
    { lat: 48.72361, lng: 20.187582 },
    { lat: 48.723786, lng: 20.187324 },
    { lat: 48.724177, lng: 20.186822 },
    { lat: 48.724281, lng: 20.186698 },
    { lat: 48.724793, lng: 20.1861 },
    { lat: 48.725019, lng: 20.185774 },
    { lat: 48.725264, lng: 20.185399 },
    { lat: 48.725623, lng: 20.185205 },
    { lat: 48.725953, lng: 20.184994 },
    { lat: 48.726201, lng: 20.184592 },
    { lat: 48.726378, lng: 20.184238 },
    { lat: 48.726649, lng: 20.183875 },
    { lat: 48.726935, lng: 20.183494 },
    { lat: 48.727221, lng: 20.183248 },
    { lat: 48.72742, lng: 20.183056 },
    { lat: 48.727631, lng: 20.182844 },
    { lat: 48.727953, lng: 20.182544 },
    { lat: 48.728225, lng: 20.182303 },
    { lat: 48.728689, lng: 20.182264 },
    { lat: 48.729026, lng: 20.182331 },
    { lat: 48.729281, lng: 20.182366 },
    { lat: 48.729488, lng: 20.182385 },
    { lat: 48.730025, lng: 20.182517 },
    { lat: 48.730492, lng: 20.18275 },
    { lat: 48.730859, lng: 20.182926 },
    { lat: 48.731219, lng: 20.183141 },
    { lat: 48.731638, lng: 20.183399 },
    { lat: 48.731885, lng: 20.183458 },
    { lat: 48.732362, lng: 20.183528 },
    { lat: 48.732693, lng: 20.183575 },
    { lat: 48.733012, lng: 20.183602 },
    { lat: 48.733394, lng: 20.183665 },
    { lat: 48.7339, lng: 20.183737 },
    { lat: 48.734278, lng: 20.183791 },
    { lat: 48.734411, lng: 20.183796 },
    { lat: 48.73495, lng: 20.18379 },
    { lat: 48.735257, lng: 20.183785 },
    { lat: 48.735605, lng: 20.183783 },
    { lat: 48.736008, lng: 20.183776 },
    { lat: 48.73689, lng: 20.183311 },
    { lat: 48.737101, lng: 20.183193 },
    { lat: 48.737582, lng: 20.182699 },
    { lat: 48.737943, lng: 20.182343 },
    { lat: 48.7371847, lng: 20.1802071 },
    { lat: 48.736687, lng: 20.1793391 },
    { lat: 48.7353911, lng: 20.1774752 },
    { lat: 48.7356893, lng: 20.17593 },
    { lat: 48.7366342, lng: 20.174697 },
    { lat: 48.7356439, lng: 20.1743864 },
    { lat: 48.7347318, lng: 20.172989 },
    { lat: 48.7345175, lng: 20.1714488 },
    { lat: 48.7340241, lng: 20.1706059 },
    { lat: 48.7337699, lng: 20.1697174 },
    { lat: 48.7333625, lng: 20.1688615 },
    { lat: 48.7334102, lng: 20.1683109 },
    { lat: 48.7335843, lng: 20.1676837 },
    { lat: 48.7335902, lng: 20.1671915 },
    { lat: 48.733693, lng: 20.1666108 },
    { lat: 48.733696, lng: 20.1663515 },
    { lat: 48.7334484, lng: 20.1652116 },
    { lat: 48.7332261, lng: 20.164475 },
    { lat: 48.7310089, lng: 20.1641248 },
    { lat: 48.7309741, lng: 20.163509 },
    { lat: 48.7306057, lng: 20.1632886 },
    { lat: 48.7303261, lng: 20.162974 },
    { lat: 48.7300362, lng: 20.1622651 },
    { lat: 48.7297767, lng: 20.161029 },
    { lat: 48.7294342, lng: 20.1603265 },
    { lat: 48.7292981, lng: 20.1597447 },
    { lat: 48.7292643, lng: 20.1587963 },
    { lat: 48.7293023, lng: 20.1585684 },
    { lat: 48.7292749, lng: 20.1581143 },
    { lat: 48.7293528, lng: 20.157506 },
    { lat: 48.7293178, lng: 20.1568631 },
    { lat: 48.7291458, lng: 20.1559479 },
    { lat: 48.7286348, lng: 20.154652 },
    { lat: 48.7284607, lng: 20.1538543 },
    { lat: 48.7283926, lng: 20.1511689 },
    { lat: 48.7282123, lng: 20.1504183 },
    { lat: 48.7280396, lng: 20.1500454 },
    { lat: 48.7277841, lng: 20.1492738 },
    { lat: 48.7277097, lng: 20.1482979 },
    { lat: 48.7278445, lng: 20.1475084 },
    { lat: 48.7279408, lng: 20.1473265 },
    { lat: 48.7280277, lng: 20.1468864 },
    { lat: 48.728113, lng: 20.1467126 },
    { lat: 48.7281209, lng: 20.1465178 },
    { lat: 48.728246, lng: 20.1461873 },
    { lat: 48.7288701, lng: 20.1453326 },
    { lat: 48.7291873, lng: 20.1447106 },
    { lat: 48.7293449, lng: 20.1442904 },
    { lat: 48.7293269, lng: 20.1440948 },
    { lat: 48.7294163, lng: 20.1435535 },
    { lat: 48.7293774, lng: 20.142896 },
    { lat: 48.7292481, lng: 20.1427336 },
    { lat: 48.7292243, lng: 20.1422877 },
    { lat: 48.7290885, lng: 20.1420527 },
    { lat: 48.7291494, lng: 20.1414423 },
    { lat: 48.7291153, lng: 20.1411537 },
    { lat: 48.729349, lng: 20.1407834 },
    { lat: 48.7293274, lng: 20.1405528 },
    { lat: 48.7294496, lng: 20.1404284 },
    { lat: 48.729483, lng: 20.1402527 },
    { lat: 48.7296585, lng: 20.1400805 },
    { lat: 48.729639, lng: 20.139408 },
    { lat: 48.7298154, lng: 20.138452 },
    { lat: 48.7297132, lng: 20.1384065 },
    { lat: 48.7295844, lng: 20.1381741 },
    { lat: 48.7295746, lng: 20.1380423 },
    { lat: 48.7297389, lng: 20.1372354 },
    { lat: 48.7296894, lng: 20.1367897 },
    { lat: 48.7294068, lng: 20.1364057 },
    { lat: 48.7288581, lng: 20.1359282 },
    { lat: 48.7283498, lng: 20.1347053 },
    { lat: 48.7279832, lng: 20.1342431 },
    { lat: 48.727989, lng: 20.1341377 },
    { lat: 48.7282641, lng: 20.1339773 },
    { lat: 48.7285037, lng: 20.1338859 },
    { lat: 48.7287146, lng: 20.1339772 },
    { lat: 48.7291126, lng: 20.1336599 },
    { lat: 48.7294271, lng: 20.1331341 },
    { lat: 48.7295438, lng: 20.1330968 },
    { lat: 48.7298829, lng: 20.1326583 },
    { lat: 48.7307192, lng: 20.1318967 },
    { lat: 48.7314008, lng: 20.1306011 },
    { lat: 48.7316507, lng: 20.1299564 },
    { lat: 48.7319522, lng: 20.1295862 },
    { lat: 48.7325113, lng: 20.129129 },
    { lat: 48.7328185, lng: 20.1292071 },
    { lat: 48.7330539, lng: 20.1294014 },
    { lat: 48.7340807, lng: 20.1296643 },
    { lat: 48.7355598, lng: 20.1315863 },
    { lat: 48.7363427, lng: 20.1286801 },
    { lat: 48.7364626, lng: 20.1278323 },
    { lat: 48.7368423, lng: 20.1263183 },
    { lat: 48.7369247, lng: 20.1255471 },
    { lat: 48.7371339, lng: 20.1246274 },
    { lat: 48.7374236, lng: 20.1237733 },
    { lat: 48.7378339, lng: 20.1228948 },
    { lat: 48.7384449, lng: 20.1221336 },
    { lat: 48.7387812, lng: 20.1214311 },
    { lat: 48.7389159, lng: 20.1205383 },
    { lat: 48.7389637, lng: 20.1196674 },
    { lat: 48.7394765, lng: 20.1166094 },
    { lat: 48.7397148, lng: 20.115986 },
  ],
};

export default UnitsRevúca;
