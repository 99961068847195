import { useMediaQuery } from "usehooks-ts";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

export default function WarningMessage({ setIgnoreMessage }) {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 640px)");
  const navigate = useNavigate();

  return (
    <AnimatePresence>
      <div
        className={clsx("absolute left-0 bottom-0 w-full h-10 z-50 overflow-hidden", {
          "h-10": !isSmallDevice,
          "h-auto": isSmallDevice,
        })}
      >
        <motion.div
          initial={{ opacity: 1, scale: 1, y: !isSmallDevice ? 300 : 300 }}
          animate={{ opacity: 1, scale: 1, y: !isSmallDevice ? 0 : 0 }}
          transition={{
            duration: 1,
            ease: [0, 0.71, 0.2, 1.01],
          }}
          className={clsx(
            "transition-all duration-200 ease-linear w-full px-2 bg-red-100 z-50 flex justify-between items-center gap-2 select-none",
            {
              //absolute left-0 bottom-0
              " h-10 flex-row": !isSmallDevice,
              "h-auto flex-col pb-1 rounded-t-lg": isSmallDevice,
            },
          )}
        >
          <p className="text-center">Pre dokončenie registrácie je potrebné skompletizovať profil.</p>
          <motion.div className="flex flex-row gap-1 px-1">
            <motion.button
              onClick={() => navigate("/sprava-uctu/profil")}
              className="h-8 w-20 bg-red-200 rounded-lg hover:bg-red-300"
            >
              Profil
            </motion.button>
            <motion.button
              onClick={() => setIgnoreMessage(false)}
              className="h-8 w-20 bg-red-200 rounded-lg hover:bg-red-300"
            >
              Zavrieť
            </motion.button>
          </motion.div>
        </motion.div>
      </div>
    </AnimatePresence>
  );
}
