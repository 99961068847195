import { React, useState, useCallback, useEffect, useRef } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import Cookiess from "../Cookies";
import { averagePriceService } from "../../services/averagePriceService";
import { categoryService } from "../../services/categoryService";
import { propertyListService } from "../../services/propertyListService";
import { saveAddressService } from "../../services/saveAddressService";
import { forSaleService } from "../../services/forSaleService";
import "../../App.css";
import Pin from "./Marker";
import MapControl from "./MapControl";
import SearchBox from "./SearchBox";
import NewAveragePrice from "../Calculator/AveragePrice";
import Cookies from "universal-cookie";
import ForSalePin from "../../Images/ForSalePin.svg";
import { useMediaQuery } from "@uidotdev/usehooks";
import NewEditMenu from "../Property/NewEditMenu";
import Polygons from "./Polygons";
import RegionsPolygons from "./RegionsPolygons";
import UnitsofKosiceRegion from "./UnitsofKosiceRegion";
import UnitsofPresovRegion from "./UnitsofPresovRegion";
import UnitsofBanskaBystricaRegion from "./UnitsofBanskaBystricaRegion";
import UnitsofZilinaRegion from "./UnitsofZilinaRegion";
import UnitsofTrencinRegion from "./UnitsofTrencinRegion";
import UnitsofNitraRegion from "./UnitsofNitraRegion";
import UnitsofTrnavaRegion from "./UnitsofTrnavaRegion";
import UnitsofBratislavaRegion from "./UnitsofBratislavaRegion";
import Legend from "./Legend";

export default function Map({
  mapActive,
  historyPins,
  historyPin,
  setHistoryItems,
  historyItems,
  center,
  setCenter,
  map,
  setMap,
  forSalePins,
  longRef,
  latRef,
  isClickedRef,
  setVisibilityOfMarkers,
  visibilityOfMarkers,
  setGotResponse,
  gotResponse,
  setHistoryInfo,
  setShowLV,
  showLV,
  forSalePin,
  openLeftBar,
  setLeftBar,
  setNavSmall,
  navSmall,
  setHistory,
  setViewSale,
  viewSale,
  subMenu,
  setSubMenu,
  blockOfFlats,
  setBlockOfFlats,
  realEstatePins,
  setSubjects,
  subjects,
  mouseOver,
  setMouseOver,
  view,
  setView,
  REBrokersPins,
  FInstitutionPins,
  FinBrokersPins,
  zoom,
  setZoom,
  hideMarkers,
  setHideMarkers,
  filters,
  setFilters,
  setFilterSale,
  responseForSale,
  filterSale,
  forSale,
  setForSale,
  setResponseForSale,
  indx,
  shareLoad,
  setShareLoad,
  setHistoryMenu,
  setPMResult,
  PMResult,
  setClickedReg,
  clickedReg,
  setPriceMap,
  priceMap,
  setGenAvgPrice,
  genAvgPrice,
  setRegDisUni,
  regDisUni,
  setMapActive,
  setPick,
  pick,
  PMHistory,
  setPMHistory,
}) {
  const cookies = new Cookies();
  const [lvNumber, setLvNumber] = useState(null);
  const locRef = useRef("");
  const municipalitiesRef = useRef(0);
  const slicedLocRef = useRef("");
  const neighborhoodRef = useRef("");
  const avgPriceRef = useRef([]);
  const locPscRef = useRef("");
  const regNumRef = useRef("");
  const countryRef = useRef("");
  const streetNumberRef = useRef("");
  const streetNameRef = useRef("");
  const latitudeRef = useRef("");
  const longitudeRef = useRef("");
  const [edit, setEdit] = useState({ editpath: false, lat: "", lng: "" });
  const [height, setHeight] = useState(window.innerHeight);
  const isSmallDevice = useMediaQuery("only screen and (max-width : 640px)");

  useEffect(() => {
    componentDidMount();
    setHeight(window.innerHeight);
    // window.google.map.event.addListener(map, "click", function (event) {
    //   return false; // Prevent the default click behavior on the map
    // });
    // eslint-disable-next-line
  }, []);

  const [address, setAddress] = useState("Fraňa Kráľa 6547/59, 071 01 Michalovce");
  const [avgPrice, setAvgPrice] = useState([]);
  const [libraries] = useState(["places"]);
  const containerStyle = { width: "100vw", height: isSmallDevice ? height + "px" : "100vh" };
  const [render, setRender] = useState(false);
  const [showInfo, setInfo] = useState(false);
  // const [genAvgPrice, setGenAvgPrice] = useState({});
  const canvasRef = useRef(null);

  const propList = useRef([]);

  function componentDidMount() {
    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;

      setCenter({
        ...center,
        lat: latitude,
        lng: longitude,
      });
      fetch(
        "https://maps.googleapis.com/maps/api/geocode/json?address=" +
          latitude +
          "," +
          longitude +
          "&key=" +
          process.env.REACT_APP_API_KEY,
      )
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.results[0] !== undefined) {
            locRef.current = responseJson.results[0].formatted_address;
            var location = locRef.current.slice(0, locRef.current.lastIndexOf(","));
            slicedLocRef.current = location.slice(location.lastIndexOf(" ") + 1);
            getNumProp();
            setAddress(locRef.current);
          } else {
            alert("API key is not working");
            // return <ErrorMessage />;
          }
        });
    });
  }

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_API_KEY,
    libraries,
  });

  const onLoad = useCallback(
    function callback(map) {
      setShareLoad(false);
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;
        setCenter({
          ...center,
          lat: latitude,
          lng: longitude,
        });
      });
      const bounds = new window.google.maps.LatLngBounds(center);
      map.fitBounds(bounds);
      setMap(map);
      map.setOptions({
        maxZoom: 18,
        minZoom: 4,
        center: center,
      });
      if (window.location.pathname.includes("edit")) {
        const path = window.location.pathname.split("/");
        const lat = Number(path[3]);
        const lng = Number(path[4]);
        setEdit({ ...edit, editpath: true, lat: lat, lng: lng });
        map?.panTo({ lat: lat, lng: lng });
      }
      if (window.location.pathname.includes("share")) {
        setShareLoad(true);
        const path = window.location.pathname.split("/");
        const id = path[2];
        // console.log("id = ", id);
        const lat = Number(path[3]);
        const lng = Number(path[4]);
        map?.panTo({ lat: lat, lng: lng });
        setNavSmall({
          ...navSmall,
          properties: true,
          subjects: false,
          helper: false,
          options: false,
          login: false,
        });
        if (window.location.pathname.includes("History")) {
          console.log("share history");
          console.log("locPscRef.current = ", locPscRef.current);
          setHistory(true);
          setSubMenu({ ...subMenu, lv: true, interest: false, owner: false, rating: false, share: false });
          const callHistory = async () => {
            try {
              console.log("before call");
              const id = path[2].split("_");
              console.log("id0 = ", id[0]);
              console.log("id1 = ", id[1]);
              console.log("id2 = ", id[2]);
              console.log("id3 = ", id[3]);
              console.log("id4 = ", id[4]);
              console.log("id5 = ", id[5]);
              console.log("id6 = ", decodeURIComponent(id[6]));
              const response = await categoryService.getHistory(
                id[0],
                id[1],
                id[2],
                id[3],
                id[4],
                id[5],
                decodeURIComponent(id[6]),
              );
              const resp = await response.json();

              setHistoryItems([...historyItems, resp[0]]);
              indx.current = historyItems.length;
              console.log("resp service = ", resp);
            } catch (error) {
              console.error("Error calling getHistory:", error);
            }
          };
          callHistory();
          setHistoryMenu(true);
        } else {
          setViewSale({ ...viewSale, view: true });
          const callSales = async () => {
            const response = await forSaleService.getAll();
            const resp = await response.json();
            console.log("resp service = ", resp.items);
            setResponseForSale(resp);
            resp.items.forEach((item, index) => {
              if (item.id === id) {
                console.log("same id ", index);
                indx.current = index;
              }
            });
            setForSale({ ...forSale, menu: true });
            setShareLoad(false);
          };
          callSales();
        }
      }
    },
    // eslint-disable-next-line
    [center, setMap, setCenter],
  );

  const onUnmount = useCallback(
    function callback(map) {
      setMap(null);
    },
    [setMap],
  );

  function handleClick(ev) {
    if (!priceMap.region && !priceMap.district && !priceMap.city) {
      setSubMenu({ ...subMenu, lv: false, interest: false, owner: true, disp: false, rating: false, share: false });
      setSubjects({ ...subjects, ROffice: false, RBroker: false, FInstitution: false, FBroker: false });
      if (window.location.pathname.includes("share")) {
        window.history.replaceState(null, "", "/");
      }
      if (navSmall.properties || navSmall.subjects || navSmall.helper || navSmall.options || navSmall.login) {
        setNavSmall({
          ...navSmall,
          properties: false,
          subjects: false,
          helper: false,
          options: false,
          login: false,
        });

        setVisibilityOfMarkers("false");
        setHistory(false);
        setViewSale({ ...viewSale, view: false });
        return;
      }
      // window.location.assign("/");
      if (window.location.pathname.includes("add") && gotResponse !== "false") {
        window.history.replaceState(null, "", "/");
        return;
      }
      if (window.location.pathname.includes("edit")) {
        window.history.replaceState(null, "", "/");
        return;
      }
      if (cookies.get("infoIntro") === undefined) {
        cookies.set("infoIntro", { path: "/" });
      }
      if (visibilityOfMarkers !== "false") {
        setLvNumber(null);
        setBlockOfFlats({ ...blockOfFlats, entrance: null, floor: null, flat: null });
        setVisibilityOfMarkers("false");
        setLeftBar({ ...openLeftBar, history: false, forSale: false });
        setHistoryInfo(false);
      } else {
        setLeftBar({ ...openLeftBar, history: false, forSale: false });
        setGotResponse("loading");
        isClickedRef.current = true;
        const { latLng } = ev;
        latRef.current = latLng.lat();
        longRef.current = latLng.lng();
        getAddress();
        map.panTo({ lat: latLng.lat(), lng: latLng.lng() });
        // htmlToImageConvert();
        setVisibilityOfMarkers("mainMarker");
        setInfo(true);
        setRender(!render);
      }
    }
  }

  const getNumProp = async () => {
    const response = await categoryService.getAllMunicipalities();

    const data = await response.json();

    municipalitiesRef.current = data.findIndex((city) => city.name === slicedLocRef.current) + 1;

    if (municipalitiesRef.current === 0) {
      municipalitiesRef.current = data.findIndex((city) => city.postalCode === locPscRef.current) + 1;

      averagePriceService
        .getAveragePrice(9, "", 100, "", municipalitiesRef.current)
        .then((response) => response.json())
        .then((data) => {
          avgPriceRef.current = data;
          // console.log("avgPriceRef = ", avgPriceRef.current);
          setAvgPrice(data);
        });
    } else {
      averagePriceService
        .getAveragePrice(9, "", 100, "", municipalitiesRef.current)
        .then((response) => response.json())
        .then((data) => {
          avgPriceRef.current = data;
          // console.log("avgPriceRef = ", avgPriceRef.current);
          setAvgPrice(data);
        });
    }
  };

  function getAddress() {
    fetch(
      "https://maps.googleapis.com/maps/api/geocode/json?address=" +
        latRef.current +
        "," +
        longRef.current +
        "&key=" +
        process.env.REACT_APP_API_KEY,
    )
      .then((response) => response.json())
      .then((responseJson) => {
        locRef.current = responseJson.results[0];
        // console.log("locRef = " + JSON.stringify(locRef.current));
        latitudeRef.current = responseJson.results[0].geometry.location.lat;
        longitudeRef.current = responseJson.results[0].geometry.location.lng;
        if (
          JSON.parse(JSON.stringify(!locRef.current.address_components[0].types.includes("street_number"))) ||
          JSON.parse(JSON.stringify(!locRef.current.address_components[2].types.includes("route")))
        ) {
          streetNumberRef.current = "";
          streetNameRef.current = "";
        }
        for (var i = 0; i < JSON.parse(JSON.stringify(locRef.current.address_components)).length; i++) {
          var current = JSON.parse(JSON.stringify(locRef.current.address_components[i]));
          if (JSON.parse(JSON.stringify(locRef.current.address_components[0].types.includes("route")))) {
            regNumRef.current = "";
          }
          if (JSON.stringify(current.types).includes("premise")) {
            // console.log("premise = ", current.long_name);
            regNumRef.current = current.long_name;
          }
          if (JSON.stringify(current.types).includes("locality")) {
            // console.log("locality = ", current.long_name);
            slicedLocRef.current = current.long_name;
          }
          if (JSON.stringify(current.types).includes("postal_code")) {
            locPscRef.current = current.long_name;
          }
          if (JSON.stringify(current.types).includes("country")) {
            countryRef.current = current.long_name;
          }
          if (JSON.stringify(current.types).includes("street_number")) {
            // console.log("street_number = ", current.long_name);
            streetNumberRef.current = current.long_name;
          }
          if (JSON.stringify(current.types).includes("route")) {
            // console.log("strret name = ", current.long_name);
            streetNameRef.current = current.long_name;
          }
          if (JSON.stringify(current.types).includes("neighborhood")) {
            neighborhoodRef.current = encodeURIComponent(current.long_name);
          }
        }
        // console.log("slicedLocRef.current = ", slicedLocRef.current);
        // console.log("streetNameRef.current = ", streetNameRef.current);
        getPropertyList();
        getNumProp();
        //setGotResponse("true")//
        setAddress(locRef.current.formatted_address);
        neighborhoodRef.current = "";
      });
  }

  const getPropertyList = async () => {
    const newHistory = {};
    propList.current = [];
    // console.log("slicedLocRef.current = " + slicedLocRef.current);
    // console.log("regNumRef.current = " + regNumRef.current);
    const loc = neighborhoodRef.current !== "" ? neighborhoodRef.current : slicedLocRef.current;

    const response = await propertyListService.getPropertyList(regNumRef.current, loc, cookies.get("sessionIdToken"));
    const data = await response.json();
    propList.current = data;
    fetch(
      `https://maps.googleapis.com/maps/api/streetview?size=400x400&location=${latRef.current},${longRef.current}&fov=80&heading=70&pitch=0&key=` +
        process.env.REACT_APP_API_KEY,
    )
      .then((response) => response)
      .then((responseUrl) => {
        Object.assign(propList.current, { img: responseUrl.url });
        setSubMenu({ ...subMenu, lv: true, interest: false, owner: false, disp: false, rating: false, share: false });
        setGotResponse("true");
        const druhStavby =
          data.List?.length === undefined
            ? data.List?.table_stavby?.druh_stavby
            : data.List[0]?.table_stavby?.druh_stavby;

        const charsToEncode = /[\u007f-\uffff]/g;
        const addressCurr = JSON.stringify(encodeURIComponent(slicedLocRef.current)).replace(
          charsToEncode,
          function (c) {
            return "\\u" + ("000" + c.charCodeAt(0).toString(16)).slice(-4);
          },
        );

        if (druhStavby === "10") {
          const user = JSON.parse(localStorage.getItem("userData"));
          saveAddressService.setSaveAddressService(
            user?.m,
            streetNameRef.current,
            regNumRef.current,
            addressCurr.replaceAll('"', ""),
            locPscRef.current,
            countryRef.current,
            latitudeRef.current,
            longitudeRef.current,
            // propList.current.List?.stav_ku_dnu,
            JSON.stringify(avgPriceRef.current).replace(charsToEncode, function (c) {
              return "\\u" + ("000" + c.charCodeAt(0).toString(16)).slice(-4);
            }),
            JSON.stringify(propList.current).replace(charsToEncode, function (c) {
              return "\\u" + ("000" + c.charCodeAt(0).toString(16)).slice(-4);
            }),
            null,
            null,
            "0",
            propList.current.List.cislo_listu_vlastnictva,
          );
        }

        // console.log("propList = ", propList.current);
        // console.log(JSON.stringify(avgPriceRef.current));
        // console.log("data = ", data.List.cislo_listu_vlastnictva);
        newHistory.response = data;
        newHistory.location = slicedLocRef.current;
        newHistory.number = regNumRef.current;
        newHistory.avgPrice = avgPriceRef.current;
        newHistory.latAndLng = locRef.current.geometry.location;
        newHistory.psc = locPscRef.current;
        newHistory.lvNum = data?.List?.cislo_listu_vlastnictva;
        if (druhStavby !== "10") {
          // console.log("blockOfFlats = ", blockOfFlats);
          newHistory.flat = blockOfFlats.flat;
          newHistory.entrance = blockOfFlats.entrance;
          newHistory.street = streetNameRef.current;
        }
        if (localStorage.history) {
          var history = JSON.parse(localStorage.getItem("history"));
        } else {
          history = [];
        }
        newHistory.index = history.length;
        // console.log("new data history = " + JSON.stringify(data.hasOwnProperty("stavby")))
        if (data.hasOwnProperty("List")) {
          history?.reverse().push(newHistory);
          setHistoryItems([...history].reverse().slice(0, 10));
          localStorage.setItem("history", JSON.stringify([...history].reverse().slice(0, 10)));
        }
      });
  };

  const charsToEncode = /[\u007f-\uffff]/g;
  const addressService = {
    streetName: JSON.stringify(streetNameRef.current)
      .replace(charsToEncode, function (c) {
        return "\\u" + ("000" + c.charCodeAt(0).toString(16)).slice(-4);
      })
      .replaceAll('"', ""),
    regNumber: regNumRef.current,
    addressCurr: JSON.stringify(slicedLocRef.current)
      .replace(charsToEncode, function (c) {
        return "\\u" + ("000" + c.charCodeAt(0).toString(16)).slice(-4);
      })
      .replaceAll('"', ""),
    psc: locPscRef.current,
    country: countryRef.current,
    latitude: latitudeRef.current,
    longitude: longitudeRef.current,
    avgPrice: JSON.stringify(avgPriceRef.current).replace(charsToEncode, function (c) {
      return "\\u" + ("000" + c.charCodeAt(0).toString(16)).slice(-4);
    }),
    propList: JSON.stringify(propList.current).replace(charsToEncode, function (c) {
      return "\\u" + ("000" + c.charCodeAt(0).toString(16)).slice(-4);
    }),
  };

  const mapStyles = [
    {
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
  ];

  const defaultMapOptions = {
    styles: mapStyles,
    // mapId: process.env.REACT_APP_API_KEY,
    mapTypeId: view,
    fullscreenControl: false,
    streetViewControl: false,
    mapTypeControl: false,
    zoomControl: false,
    clickableIcons: false,
    // draggable: priceMap.region || priceMap.district || priceMap.city ? false : true,
    draggable: true,
  };

  // const [zoom, setZoom] = useState(15);

  return isLoaded ? (
    <div className={`${gotResponse ? "bg-center" : "z-0"}`}>
      <canvas ref={canvasRef} style={{ display: "none" }} />
      <GoogleMap
        id="mapa"
        mapContainerStyle={containerStyle}
        center={center}
        // zoom={14}
        zoom={zoom}
        onLoad={onLoad}
        onUnmount={onUnmount}
        options={defaultMapOptions}
        // options={{ styles: mapStyles }}
        onClick={handleClick}
        onMouseOver={() =>
          setMouseOver({
            ...mouseOver,
            previewFS: false,
            previewH: false,
            previewREO: false,
            previewREB: false,
            previewFI: false,
            previewFB: false,
            indexFS: null,
            indexH: null,
            indexREO: null,
            indexREB: null,
            indexFI: null,
            indexFB: null,
          })
        }
        onZoomChanged={() => {
          setZoom(map?.getZoom());
          // console.log("zoom changed = ", map?.getZoom());
        }}
      >
        <Polygons
          priceMap={priceMap}
          setPriceMap={setPriceMap}
          map={map}
          setZoom={setZoom}
          genAvgPrice={genAvgPrice}
          setGenAvgPrice={setGenAvgPrice}
          setPMResult={setPMResult}
          PMResult={PMResult}
          setRegDisUni={setRegDisUni}
          regDisUni={regDisUni}
          PMHistory={PMHistory}
          setPMHistory={setPMHistory}
        />
        <RegionsPolygons
          priceMap={priceMap}
          map={map}
          setPMResult={setPMResult}
          PMResult={PMResult}
          genAvgPrice={genAvgPrice}
          setRegDisUni={setRegDisUni}
          regDisUni={regDisUni}
          PMHistory={PMHistory}
          setPMHistory={setPMHistory}
        />
        {priceMap ? <Legend priceMap={priceMap} /> : null}
        {mapActive ? (
          <div
            className={`flex flex-wrap gap-4 justify-center sm:justify-start w-screen ${
              mapActive ? "pt-20 sm:pt-5 sm:pl-3" : ""
            }`}
          >
            <SearchBox
              setGotResponse={setGotResponse}
              gotResponse={gotResponse}
              isClickedRef={isClickedRef}
              setCenter={setCenter}
              center={center}
              latRef={latRef}
              longRef={longRef}
              map={map}
              setMap={setMap}
              getAddress={getAddress}
              setVisibilityOfMarkers={setVisibilityOfMarkers}
              visibilityOfMarkers={visibilityOfMarkers}
              setInfo={setInfo}
              hideMarkers={hideMarkers}
              setHideMarkers={setHideMarkers}
              setNavSmall={setNavSmall}
              navSmall={navSmall}
              setViewSale={setViewSale}
              viewSale={viewSale}
              filters={filters}
              setFilters={setFilters}
              setFilterSale={setFilterSale}
              forSaleItems={responseForSale}
              filterSale={filterSale}
              priceMap={priceMap}
              setPriceMap={setPriceMap}
              genAvgPrice={genAvgPrice}
              setGenAvgPrice={setGenAvgPrice}
              setPMResult={setPMResult}
              PMResult={PMResult}
              setZoom={setZoom}
              clickedReg={clickedReg}
              setClickedReg={setClickedReg}
              setPick={setPick}
              pick={pick}
              PMHistory={PMHistory}
              setPMHistory={setPMHistory}
            />
            <UnitsofKosiceRegion
              priceMap={priceMap}
              genAvgPrice={genAvgPrice}
              setPMResult={setPMResult}
              PMResult={PMResult}
              map={map}
              PMHistory={PMHistory}
              setPMHistory={setPMHistory}
            />
            <UnitsofPresovRegion
              priceMap={priceMap}
              genAvgPrice={genAvgPrice}
              setPMResult={setPMResult}
              PMResult={PMResult}
              map={map}
              PMHistory={PMHistory}
              setPMHistory={setPMHistory}
            />
            <UnitsofBanskaBystricaRegion
              priceMap={priceMap}
              genAvgPrice={genAvgPrice}
              setPMResult={setPMResult}
              PMResult={PMResult}
              map={map}
              PMHistory={PMHistory}
              setPMHistory={setPMHistory}
            />
            <UnitsofZilinaRegion
              priceMap={priceMap}
              genAvgPrice={genAvgPrice}
              setPMResult={setPMResult}
              PMResult={PMResult}
              map={map}
              PMHistory={PMHistory}
              setPMHistory={setPMHistory}
            />
            <UnitsofTrencinRegion
              priceMap={priceMap}
              genAvgPrice={genAvgPrice}
              setPMResult={setPMResult}
              PMResult={PMResult}
              map={map}
              PMHistory={PMHistory}
              setPMHistory={setPMHistory}
            />
            <UnitsofNitraRegion
              priceMap={priceMap}
              genAvgPrice={genAvgPrice}
              setPMResult={setPMResult}
              PMResult={PMResult}
              map={map}
              PMHistory={PMHistory}
              setPMHistory={setPMHistory}
            />
            <UnitsofTrnavaRegion
              priceMap={priceMap}
              genAvgPrice={genAvgPrice}
              setPMResult={setPMResult}
              PMResult={PMResult}
              map={map}
              PMHistory={PMHistory}
              setPMHistory={setPMHistory}
            />
            <UnitsofBratislavaRegion
              priceMap={priceMap}
              genAvgPrice={genAvgPrice}
              setPMResult={setPMResult}
              PMResult={PMResult}
              map={map}
              PMHistory={PMHistory}
              setPMHistory={setPMHistory}
            />
            <MapControl
              view={view}
              setView={setView}
              setPriceMap={setPriceMap}
              priceMap={priceMap}
              hideMarkers={hideMarkers}
              setHideMarkers={setHideMarkers}
              map={map}
              setZoom={setZoom}
              setGenAvgPrice={setGenAvgPrice}
              setPMResult={setPMResult}
              PMResult={PMResult}
              setClickedReg={setClickedReg}
              setMapActive={setMapActive}
              mapActive={mapActive}
            />
            {visibilityOfMarkers === "mainMarker" ? (
              <Pin
                isClickedRef={isClickedRef}
                latRef={latRef}
                longRef={longRef}
                address={address}
                center={center}
                avgPrice={avgPrice}
                setResponse={setGotResponse}
                gotResponse={gotResponse}
                propList={propList.current}
                showInfo={showInfo}
                setInfo={setInfo}
                setShowLV={setShowLV}
                showLV={showLV}
                setLvNumber={setLvNumber}
                lvNumber={lvNumber}
                blockOfFlats={blockOfFlats}
                setBlockOfFlats={setBlockOfFlats}
                subMenu={subMenu}
                setSubMenu={setSubMenu}
                addressService={addressService}
                setHistoryItems={setHistoryItems}
                historyItems={historyItems}
                setVisibilityOfMarkers={setVisibilityOfMarkers}
              />
            ) : null}
            {realEstatePins}
            {historyPins}
            {historyPin}
            {forSalePins}
            {REBrokersPins}
            {FInstitutionPins}
            {FinBrokersPins}
            {forSalePin}
            {edit.editpath ? (
              <Marker
                position={{ lat: edit.lat, lng: edit.lng }}
                icon={{ url: ForSalePin, scaledSize: new window.google.maps.Size(50, 50) }}
              >
                <NewEditMenu gotResponse={gotResponse} />
              </Marker>
            ) : null}
          </div>
        ) : (
          <div
            className={`flex flex-wrap gap-4 justify-start sm:justify-start w-screen ${
              mapActive ? "pt-20 sm:pt-5 sm:pl-3" : ""
            }`}
          >
            <NewAveragePrice />
          </div>
        )}
        <Cookiess />
      </GoogleMap>
    </div>
  ) : (
    <></>
  );
}
