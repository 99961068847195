import clsx from "clsx";
import { useMediaQuery } from "@uidotdev/usehooks";

export default function Contact({ size }) {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 640px)");

  return (
    <div className="w-full h-screen bg-sky-100 flex flex-col justify-start items-center p-2">
      <div
        className={clsx("w-full h-auto flex justify-center items-start gap-3", {
          "flex-col": size.mid || isSmallDevice,
        })}
      >
        <div className="w-full h-auto flex flex-col justify-center items-center gap-1 p-1">
          <h2 className="text-2xl uppercase">Kontakt</h2>
          <span className="text-center">Tel.: +421 908 651 113</span>
          <span className="text-center">E-mail: lotario@lotario.sk</span>
        </div>
        <div className="w-full h-auto flex flex-col justify-center items-center gap-1 p-1">
          <h2 className="text-2xl uppercase">Adresa</h2>
          <span className="text-center">Lotario s.r.o.</span>
          <span className="text-center">Fraňa Kráľa 6547/59, 071 01 Michalovce</span>
        </div>
        <div className="w-full h-auto flex flex-col justify-center items-center gap-1 p-1">
          <h2 className="text-2xl uppercase">Fakturačné údaje</h2>
          <span className="text-center">Sídlo: Fraňa Kráľa 6547/59, 071 01 Michalovce</span>
          <span className="text-center">IČO: 50257137</span>
          <span className="text-center">DIČ: 2120258877</span>
          <span className="text-center">IČ DPH: SK2120258877</span>
        </div>
      </div>
    </div>
  );
}
