import { useState } from "react";
import { Polygon } from "@react-google-maps/api";
import UnitsBytča from "../constants/Units/RegionZilina/UnitsBytca";
import UnitsČadca from "../constants/Units/RegionZilina/UnitsCadca";
import UnitsDolnýKubín from "../constants/Units/RegionZilina/UnitsDolnyKubin";
import UnitsKysuckéNovéMesto from "../constants/Units/RegionZilina/UnitsKysuckeNoveMesto";
import UnitsLiptovskýMikuláš from "../constants/Units/RegionZilina/UnitsLiptovskyMikulas";
import UnitsMartin from "../constants/Units/RegionZilina/UnitsMartin";
import UnitsNámestovo from "../constants/Units/RegionZilina/UnitsNamestovo";
import UnitsRužomberok from "../constants/Units/RegionZilina/UnitsRuzomberok";
import UnitsTurčianskeTeplice from "../constants/Units/RegionZilina/UnitsTurcianskeTeplice";
import UnitsTvrdošín from "../constants/Units/RegionZilina/UnitsTvrdosin";
import UnitsŽilina from "../constants/Units/RegionZilina/UnitsZilina";
import { averagePriceService } from "../../services/averagePriceService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTags } from "@fortawesome/free-solid-svg-icons";

export default function UnitsofZilinaRegion({
  priceMap,
  genAvgPrice,
  setPMResult,
  PMResult,
  map,
  PMHistory,
  setPMHistory,
}) {
  const [showTooltip, setShowTooltip] = useState({ active: false, name: "", x: 0, y: 0 });

  const defaultColor = "#7551b0";
  const green3 = "#007500";
  const green1 = "#00A300";
  const green2 = "#2EFF2E";
  const yellow1 = "#D1D100";
  const yellow2 = "#FFFF2E";
  const orange1 = "#FFA500";
  // const orange2 = "#FFB52E";
  const red1 = "#FF0000";
  const red2 = "#D10000";

  const setColor = (key) => {
    // console.log("length = ", Object.keys(genAvgPrice).length);
    if (Object.keys(genAvgPrice).length > 0) {
      // for (const k of Object.keys(genAvgPrice)) {
      if (genAvgPrice.hasOwnProperty(key)) {
        if (genAvgPrice[key]?.avgBytM2 >= 700 && genAvgPrice[key]?.avgBytM2 <= 1000) {
          return green1;
        } else if (genAvgPrice[key]?.avgBytM2 > 1000 && genAvgPrice[key]?.avgBytM2 <= 1300) {
          return green2;
        } else if (genAvgPrice[key]?.avgBytM2 > 1300 && genAvgPrice[key]?.avgBytM2 <= 1600) {
          return yellow1;
        } else if (genAvgPrice[key]?.avgBytM2 > 1600 && genAvgPrice[key]?.avgBytM2 <= 1800) {
          return yellow2;
        } else if (genAvgPrice[key]?.avgBytM2 > 1800 && genAvgPrice[key]?.avgBytM2 <= 2000) {
          return orange1;
        } else if (genAvgPrice[key]?.avgBytM2 > 2000 && genAvgPrice[key]?.avgBytM2 <= 2100) {
          return red1;
        } else if (genAvgPrice[key]?.avgBytM2 > 2100) {
          return red2;
        }
        return green3;
      } else return defaultColor;
      // }
    } else return defaultColor;
  };

  const unitAvgPrice = async (parent, key) => {
    // console.log("parent = ", parent, "|  key = ", key);
    const request = await averagePriceService.getUnitAvgPrice(decodeURIComponent(parent), decodeURIComponent(key));
    const response = await request.json();
    setPMResult({ ...PMResult, active: true, data: response });
    // console.log("response = ", response);
  };

  const convertLatLngToPoint = (latLng, map) => {
    const scale = Math.pow(2, map.getZoom());
    const nw = new window.google.maps.LatLng(
      map.getBounds().getNorthEast().lat(),
      map.getBounds().getSouthWest().lng(),
    );
    const worldCoordinateNW = map.getProjection().fromLatLngToPoint(nw);
    const worldCoordinate = map.getProjection().fromLatLngToPoint(latLng);
    const pixelOffset = new window.google.maps.Point(
      Math.floor((worldCoordinate.x - worldCoordinateNW.x) * scale),
      Math.floor((worldCoordinate.y - worldCoordinateNW.y) * scale),
    );
    return pixelOffset;
  };

  return priceMap.city ? (
    <>
      {Object.keys(UnitsBytča).map((key) => {
        if (priceMap.cityName === "DistrictBytča") {
          return (
            <Polygon
              key={key}
              paths={UnitsBytča[key]}
              options={{
                // strokeColor: "#2d3740",
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("Bytča", key);
                setPMHistory({ ...PMHistory, city: "Bytča " + key });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {Object.keys(UnitsČadca).map((key) => {
        if (priceMap.cityName === "DistrictČadca") {
          return (
            <Polygon
              key={key}
              paths={UnitsČadca[key]}
              options={{
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("Čadca", key);
                setPMHistory({ ...PMHistory, city: "Čadca " + key });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {Object.keys(UnitsDolnýKubín).map((key) => {
        if (priceMap.cityName === "DistrictDolnýKubín") {
          return (
            <Polygon
              key={key}
              paths={UnitsDolnýKubín[key]}
              options={{
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("DolnýKubín", key);
                setPMHistory({ ...PMHistory, city: "DolnýKubín " + key });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {Object.keys(UnitsKysuckéNovéMesto).map((key) => {
        if (priceMap.cityName === "DistrictKysuckéNovéMesto") {
          return (
            <Polygon
              key={key}
              paths={UnitsKysuckéNovéMesto[key]}
              options={{
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("KysuckéNovéMesto", key);
                setPMHistory({ ...PMHistory, city: "KysuckéNovéMesto " + key });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {Object.keys(UnitsLiptovskýMikuláš).map((key) => {
        if (priceMap.cityName === "DistrictLiptovskýMikuláš") {
          return (
            <Polygon
              key={key}
              paths={UnitsLiptovskýMikuláš[key]}
              options={{
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("LiptovskýMikuláš", key);
                setPMHistory({ ...PMHistory, city: "LiptovskýMikuláš " + key });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {Object.keys(UnitsMartin).map((key) => {
        if (priceMap.cityName === "DistrictMartin") {
          return (
            <Polygon
              key={key}
              paths={UnitsMartin[key]}
              options={{
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("Martin", key);
                setPMHistory({ ...PMHistory, city: "Martin " + key });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {Object.keys(UnitsNámestovo).map((key) => {
        if (priceMap.cityName === "DistrictNámestovo") {
          return (
            <Polygon
              key={key}
              paths={UnitsNámestovo[key]}
              options={{
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("Námestovo", key);
                setPMHistory({ ...PMHistory, city: "Námestovo " + key });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {Object.keys(UnitsRužomberok).map((key) => {
        if (priceMap.cityName === "DistrictRužomberok") {
          return (
            <Polygon
              key={key}
              paths={UnitsRužomberok[key]}
              options={{
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("Ružomberok", key);
                setPMHistory({ ...PMHistory, city: "Ružomberok " + key });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {Object.keys(UnitsTurčianskeTeplice).map((key) => {
        if (priceMap.cityName === "DistrictTurčianskeTeplice") {
          return (
            <Polygon
              key={key}
              paths={UnitsTurčianskeTeplice[key]}
              options={{
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("TurčianskeTeplice", key);
                setPMHistory({ ...PMHistory, city: "TurčianskeTeplice " + key });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {Object.keys(UnitsTvrdošín).map((key) => {
        if (priceMap.cityName === "DistrictTvrdošín") {
          return (
            <Polygon
              key={key}
              paths={UnitsTvrdošín[key]}
              options={{
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("Tvrdošín", key);
                setPMHistory({ ...PMHistory, city: "Tvrdošín " + key });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {Object.keys(UnitsŽilina).map((key) => {
        if (priceMap.cityName === "DistrictŽilina") {
          return (
            <Polygon
              key={key}
              paths={UnitsŽilina[key]}
              options={{
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("Žilina", key);
                setPMHistory({ ...PMHistory, city: "Žilina" + key });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {showTooltip.active ? (
        <div
          className="absolute mb-2 h-auto w-auto p-2 bg-sky-200 text-center text-sm rounded-lg opacity-100 transition-opacity duration-300"
          style={{ top: showTooltip.y + 10, left: showTooltip.x + 10 }}
        >
          <div className="flex flex-col">
            <div className="flex flex-row gap-1 justify-between items-center">
              Trhová cena pre
              <FontAwesomeIcon icon={faTags} />
            </div>
            <div>
              {genAvgPrice[showTooltip.name.replace("Ext", "")]?.obec
                .replace("nad", " nad")
                .replace("pod", " pod")
                .replace("pri", " pri")
                .replace(/(?<!^)(?<=\p{L})(?=[A-Z])/gu, " ")
                .replace("Ext", "")
                .replace("1", "")}
            </div>
            <div>za dom: {genAvgPrice[showTooltip.name.replace("Ext", "")]?.avgDom} €</div>
            <div>za m² bytu: {genAvgPrice[showTooltip.name.replace("Ext", "")]?.avgBytM2} €</div>
            <div>za m² pozemku: {genAvgPrice[showTooltip.name.replace("Ext", "")]?.avgPozemokM2} €</div>
          </div>
        </div>
      ) : null}
    </>
  ) : null;
}
