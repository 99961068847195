const UnitsZvolen = {
  Dubové: [
    { lat: 48.492044, lng: 18.996027 },
    { lat: 48.49139, lng: 18.99639 },
    { lat: 48.491138, lng: 18.99653 },
    { lat: 48.491115, lng: 18.996542 },
    { lat: 48.490889, lng: 18.996667 },
    { lat: 48.490351, lng: 18.997349 },
    { lat: 48.490085, lng: 18.997447 },
    { lat: 48.489636, lng: 18.997666 },
    { lat: 48.489275, lng: 18.997687 },
    { lat: 48.488978, lng: 18.997665 },
    { lat: 48.488495, lng: 18.997674 },
    { lat: 48.488454, lng: 18.997662 },
    { lat: 48.488042, lng: 18.997536 },
    { lat: 48.487854, lng: 18.997349 },
    { lat: 48.487613, lng: 18.997443 },
    { lat: 48.487424, lng: 18.997465 },
    { lat: 48.486788, lng: 18.997584 },
    { lat: 48.485903, lng: 18.99795 },
    { lat: 48.485531, lng: 18.998035 },
    { lat: 48.484027, lng: 18.9981 },
    { lat: 48.483694, lng: 18.998493 },
    { lat: 48.483298, lng: 18.999338 },
    { lat: 48.482915, lng: 19.000607 },
    { lat: 48.482597, lng: 19.0018 },
    { lat: 48.482396, lng: 19.002819 },
    { lat: 48.482203, lng: 19.0035 },
    { lat: 48.481749, lng: 19.005321 },
    { lat: 48.481515, lng: 19.005422 },
    { lat: 48.481268, lng: 19.005683 },
    { lat: 48.481149, lng: 19.005857 },
    { lat: 48.48032, lng: 19.007062 },
    { lat: 48.4806114, lng: 19.0078704 },
    { lat: 48.4806117, lng: 19.0084539 },
    { lat: 48.4805203, lng: 19.0095881 },
    { lat: 48.4809374, lng: 19.0111 },
    { lat: 48.4817481, lng: 19.0122164 },
    { lat: 48.482466, lng: 19.0137679 },
    { lat: 48.4826073, lng: 19.0138619 },
    { lat: 48.4827649, lng: 19.014217 },
    { lat: 48.4828346, lng: 19.0145672 },
    { lat: 48.4829615, lng: 19.0154912 },
    { lat: 48.482851, lng: 19.015694 },
    { lat: 48.4826864, lng: 19.0162506 },
    { lat: 48.4825972, lng: 19.0165796 },
    { lat: 48.4825557, lng: 19.0169866 },
    { lat: 48.4828727, lng: 19.0182151 },
    { lat: 48.4833398, lng: 19.0192705 },
    { lat: 48.4832826, lng: 19.0194981 },
    { lat: 48.483324, lng: 19.0196913 },
    { lat: 48.483458, lng: 19.019822 },
    { lat: 48.4835252, lng: 19.0200151 },
    { lat: 48.4836617, lng: 19.0208176 },
    { lat: 48.4835641, lng: 19.021009 },
    { lat: 48.4836417, lng: 19.0213149 },
    { lat: 48.4835986, lng: 19.0216689 },
    { lat: 48.4834753, lng: 19.0220384 },
    { lat: 48.4834505, lng: 19.022263 },
    { lat: 48.4835223, lng: 19.0226126 },
    { lat: 48.4833962, lng: 19.0233705 },
    { lat: 48.4831993, lng: 19.0240519 },
    { lat: 48.4831622, lng: 19.0243227 },
    { lat: 48.4832847, lng: 19.024647 },
    { lat: 48.483261, lng: 19.0247347 },
    { lat: 48.4833045, lng: 19.0247905 },
    { lat: 48.483332, lng: 19.0250681 },
    { lat: 48.483955, lng: 19.0261533 },
    { lat: 48.484163, lng: 19.0270336 },
    { lat: 48.4844439, lng: 19.0279508 },
    { lat: 48.48436, lng: 19.0283012 },
    { lat: 48.4844096, lng: 19.0289281 },
    { lat: 48.4843258, lng: 19.0290749 },
    { lat: 48.4841072, lng: 19.0291663 },
    { lat: 48.4839821, lng: 19.0292986 },
    { lat: 48.4839497, lng: 19.029888 },
    { lat: 48.4839851, lng: 19.0302113 },
    { lat: 48.4839078, lng: 19.0304863 },
    { lat: 48.4838125, lng: 19.0306329 },
    { lat: 48.4837502, lng: 19.0309162 },
    { lat: 48.4835598, lng: 19.0311878 },
    { lat: 48.4835751, lng: 19.0312425 },
    { lat: 48.483492, lng: 19.0314503 },
    { lat: 48.4835503, lng: 19.0316921 },
    { lat: 48.4834276, lng: 19.0320505 },
    { lat: 48.4834353, lng: 19.0322728 },
    { lat: 48.4833635, lng: 19.0324341 },
    { lat: 48.4833409, lng: 19.0326368 },
    { lat: 48.4835359, lng: 19.0328818 },
    { lat: 48.4835786, lng: 19.0337039 },
    { lat: 48.4837759, lng: 19.0341075 },
    { lat: 48.4840085, lng: 19.0348955 },
    { lat: 48.4840058, lng: 19.035334 },
    { lat: 48.483907, lng: 19.035787 },
    { lat: 48.4839456, lng: 19.0360374 },
    { lat: 48.4838487, lng: 19.0364443 },
    { lat: 48.4839716, lng: 19.0365767 },
    { lat: 48.4839861, lng: 19.036799 },
    { lat: 48.4839178, lng: 19.0369393 },
    { lat: 48.4839098, lng: 19.0371183 },
    { lat: 48.4839718, lng: 19.0373216 },
    { lat: 48.4840652, lng: 19.0373358 },
    { lat: 48.4841749, lng: 19.0376329 },
    { lat: 48.4842877, lng: 19.0376832 },
    { lat: 48.4843161, lng: 19.0378424 },
    { lat: 48.484502, lng: 19.0382161 },
    { lat: 48.4844565, lng: 19.0385887 },
    { lat: 48.4844245, lng: 19.0385814 },
    { lat: 48.4844105, lng: 19.0390634 },
    { lat: 48.4843202, lng: 19.0392415 },
    { lat: 48.4843935, lng: 19.0393774 },
    { lat: 48.4843535, lng: 19.0399254 },
    { lat: 48.48459, lng: 19.0401635 },
    { lat: 48.4847275, lng: 19.040526 },
    { lat: 48.4848314, lng: 19.0406357 },
    { lat: 48.4848442, lng: 19.0409608 },
    { lat: 48.4849668, lng: 19.0410645 },
    { lat: 48.4850727, lng: 19.0415812 },
    { lat: 48.4851388, lng: 19.04166 },
    { lat: 48.4851375, lng: 19.0418116 },
    { lat: 48.4852103, lng: 19.0418925 },
    { lat: 48.4852012, lng: 19.0420481 },
    { lat: 48.4853227, lng: 19.0421292 },
    { lat: 48.4856255, lng: 19.0431982 },
    { lat: 48.4858009, lng: 19.0436359 },
    { lat: 48.4858439, lng: 19.0445785 },
    { lat: 48.4859695, lng: 19.0449239 },
    { lat: 48.4859858, lng: 19.0452717 },
    { lat: 48.4861253, lng: 19.0456866 },
    { lat: 48.4861427, lng: 19.0463041 },
    { lat: 48.4863984, lng: 19.046657 },
    { lat: 48.4864342, lng: 19.0468104 },
    { lat: 48.4866319, lng: 19.0468607 },
    { lat: 48.4868384, lng: 19.0470447 },
    { lat: 48.4872631, lng: 19.047151 },
    { lat: 48.4875338, lng: 19.0471265 },
    { lat: 48.4883024, lng: 19.0474797 },
    { lat: 48.4891151, lng: 19.0476107 },
    { lat: 48.4892398, lng: 19.0482305 },
    { lat: 48.4894067, lng: 19.0486359 },
    { lat: 48.4896958, lng: 19.0489104 },
    { lat: 48.4899496, lng: 19.0487163 },
    { lat: 48.4900346, lng: 19.0487659 },
    { lat: 48.4901362, lng: 19.048974 },
    { lat: 48.4904042, lng: 19.0492101 },
    { lat: 48.4906221, lng: 19.0491966 },
    { lat: 48.4907373, lng: 19.0493122 },
    { lat: 48.490814, lng: 19.0493109 },
    { lat: 48.491041, lng: 19.0496995 },
    { lat: 48.4911836, lng: 19.0496879 },
    { lat: 48.4912573, lng: 19.0497637 },
    { lat: 48.4914142, lng: 19.0497039 },
    { lat: 48.4916373, lng: 19.0499668 },
    { lat: 48.4917999, lng: 19.0500634 },
    { lat: 48.4922684, lng: 19.0498946 },
    { lat: 48.492408, lng: 19.0499601 },
    { lat: 48.4926277, lng: 19.0503587 },
    { lat: 48.4925426, lng: 19.0505038 },
    { lat: 48.4926581, lng: 19.0507612 },
    { lat: 48.492762, lng: 19.0508532 },
    { lat: 48.4932177, lng: 19.0507444 },
    { lat: 48.4933102, lng: 19.0507719 },
    { lat: 48.4935294, lng: 19.0512492 },
    { lat: 48.4941424, lng: 19.0516465 },
    { lat: 48.4944274, lng: 19.0514658 },
    { lat: 48.4945595, lng: 19.0516033 },
    { lat: 48.4946079, lng: 19.0518701 },
    { lat: 48.4947986, lng: 19.0520968 },
    { lat: 48.4947702, lng: 19.0527193 },
    { lat: 48.494631, lng: 19.053381 },
    { lat: 48.4951656, lng: 19.0538796 },
    { lat: 48.4955031, lng: 19.0539333 },
    { lat: 48.4956271, lng: 19.0540699 },
    { lat: 48.4955606, lng: 19.0542271 },
    { lat: 48.4953777, lng: 19.0543336 },
    { lat: 48.4954283, lng: 19.0547341 },
    { lat: 48.4957882, lng: 19.0549857 },
    { lat: 48.4966007, lng: 19.0553803 },
    { lat: 48.4978291, lng: 19.0556849 },
    { lat: 48.4984051, lng: 19.0562916 },
    { lat: 48.4985963, lng: 19.0568194 },
    { lat: 48.4986381, lng: 19.0576049 },
    { lat: 48.4985113, lng: 19.0582692 },
    { lat: 48.4987991, lng: 19.0585006 },
    { lat: 48.498829, lng: 19.0588708 },
    { lat: 48.4989615, lng: 19.0591934 },
    { lat: 48.4989869, lng: 19.0594305 },
    { lat: 48.4989519, lng: 19.0598351 },
    { lat: 48.4990981, lng: 19.0603656 },
    { lat: 48.4991205, lng: 19.0611511 },
    { lat: 48.4988314, lng: 19.0614766 },
    { lat: 48.4986736, lng: 19.0622211 },
    { lat: 48.4983859, lng: 19.0625275 },
    { lat: 48.4981536, lng: 19.0626392 },
    { lat: 48.498087, lng: 19.0636521 },
    { lat: 48.4979748, lng: 19.0642446 },
    { lat: 48.4979105, lng: 19.0645125 },
    { lat: 48.4977142, lng: 19.0648465 },
    { lat: 48.4976447, lng: 19.0650819 },
    { lat: 48.4976193, lng: 19.0653624 },
    { lat: 48.4976567, lng: 19.0655903 },
    { lat: 48.4978362, lng: 19.0662016 },
    { lat: 48.4976909, lng: 19.0672124 },
    { lat: 48.4975956, lng: 19.0673394 },
    { lat: 48.497134, lng: 19.0676399 },
    { lat: 48.4969354, lng: 19.0678409 },
    { lat: 48.4967245, lng: 19.0683128 },
    { lat: 48.4965432, lng: 19.0684748 },
    { lat: 48.4965667, lng: 19.0686719 },
    { lat: 48.4964927, lng: 19.0690176 },
    { lat: 48.4961413, lng: 19.0696533 },
    { lat: 48.4960876, lng: 19.0697529 },
    { lat: 48.4959557, lng: 19.0699975 },
    { lat: 48.4960111, lng: 19.070048 },
    { lat: 48.4969767, lng: 19.0712988 },
    { lat: 48.4972145, lng: 19.0715403 },
    { lat: 48.4976388, lng: 19.0718661 },
    { lat: 48.4986045, lng: 19.0722385 },
    { lat: 48.499133, lng: 19.0719607 },
    { lat: 48.5015955, lng: 19.0706664 },
    { lat: 48.5020731, lng: 19.0703623 },
    { lat: 48.502368, lng: 19.0701749 },
    { lat: 48.5030026, lng: 19.0701484 },
    { lat: 48.5024288, lng: 19.0686866 },
    { lat: 48.5016482, lng: 19.0673727 },
    { lat: 48.5013967, lng: 19.0664038 },
    { lat: 48.5019411, lng: 19.0646064 },
    { lat: 48.502564, lng: 19.0634279 },
    { lat: 48.5032296, lng: 19.0631653 },
    { lat: 48.5041882, lng: 19.0630137 },
    { lat: 48.5055131, lng: 19.0624543 },
    { lat: 48.5056084, lng: 19.0610053 },
    { lat: 48.50526, lng: 19.0587639 },
    { lat: 48.5053136, lng: 19.0587073 },
    { lat: 48.5053094, lng: 19.0586221 },
    { lat: 48.5063873, lng: 19.0581312 },
    { lat: 48.5091592, lng: 19.0571336 },
    { lat: 48.5090784, lng: 19.0565835 },
    { lat: 48.5089974, lng: 19.0549013 },
    { lat: 48.5094959, lng: 19.054638 },
    { lat: 48.5100677, lng: 19.0540812 },
    { lat: 48.5106248, lng: 19.0529148 },
    { lat: 48.5119933, lng: 19.0519262 },
    { lat: 48.5118766, lng: 19.0513105 },
    { lat: 48.5119631, lng: 19.0500241 },
    { lat: 48.5120645, lng: 19.0499358 },
    { lat: 48.5121342, lng: 19.0493919 },
    { lat: 48.512525, lng: 19.0483973 },
    { lat: 48.513047, lng: 19.0463376 },
    { lat: 48.5132913, lng: 19.0456331 },
    { lat: 48.5134842, lng: 19.044608 },
    { lat: 48.5135738, lng: 19.0443524 },
    { lat: 48.5134673, lng: 19.0441359 },
    { lat: 48.5133821, lng: 19.0434291 },
    { lat: 48.5134297, lng: 19.042493 },
    { lat: 48.5141837, lng: 19.0407883 },
    { lat: 48.5142307, lng: 19.040155 },
    { lat: 48.5141259, lng: 19.0393508 },
    { lat: 48.5141918, lng: 19.0380411 },
    { lat: 48.5137489, lng: 19.0377994 },
    { lat: 48.513676, lng: 19.0376473 },
    { lat: 48.5139, lng: 19.0370154 },
    { lat: 48.5141619, lng: 19.0359913 },
    { lat: 48.5131073, lng: 19.0341079 },
    { lat: 48.5127272, lng: 19.0325593 },
    { lat: 48.5128129, lng: 19.0322484 },
    { lat: 48.5130789, lng: 19.0318873 },
    { lat: 48.5133466, lng: 19.0314054 },
    { lat: 48.5135707, lng: 19.0305516 },
    { lat: 48.513559, lng: 19.029851 },
    { lat: 48.5134251, lng: 19.0293887 },
    { lat: 48.5133805, lng: 19.0290617 },
    { lat: 48.513199, lng: 19.0267394 },
    { lat: 48.5131002, lng: 19.0263457 },
    { lat: 48.513332, lng: 19.0243499 },
    { lat: 48.5136282, lng: 19.0231834 },
    { lat: 48.5136829, lng: 19.0222131 },
    { lat: 48.5138038, lng: 19.0216609 },
    { lat: 48.5147026, lng: 19.0194549 },
    { lat: 48.5151256, lng: 19.018659 },
    { lat: 48.5152455, lng: 19.0178209 },
    { lat: 48.515389, lng: 19.0173123 },
    { lat: 48.5154482, lng: 19.0164784 },
    { lat: 48.5152842, lng: 19.015643 },
    { lat: 48.515313, lng: 19.0154978 },
    { lat: 48.5154035, lng: 19.0154607 },
    { lat: 48.5155031, lng: 19.0149771 },
    { lat: 48.5157379, lng: 19.0148922 },
    { lat: 48.5157426, lng: 19.014807 },
    { lat: 48.5164657, lng: 19.0138113 },
    { lat: 48.516731, lng: 19.0138207 },
    { lat: 48.517308, lng: 19.0124778 },
    { lat: 48.5176277, lng: 19.0120247 },
    { lat: 48.5177263, lng: 19.0116774 },
    { lat: 48.5181856, lng: 19.0110386 },
    { lat: 48.518245, lng: 19.010715 },
    { lat: 48.518107, lng: 19.010477 },
    { lat: 48.518069, lng: 19.010409 },
    { lat: 48.517813, lng: 19.009984 },
    { lat: 48.517149, lng: 19.009658 },
    { lat: 48.516536, lng: 19.008981 },
    { lat: 48.516128, lng: 19.008997 },
    { lat: 48.515219, lng: 19.009286 },
    { lat: 48.514477, lng: 19.010238 },
    { lat: 48.513527, lng: 19.010588 },
    { lat: 48.512805, lng: 19.011323 },
    { lat: 48.512744, lng: 19.011297 },
    { lat: 48.511922, lng: 19.010903 },
    { lat: 48.511393, lng: 19.010433 },
    { lat: 48.510726, lng: 19.010205 },
    { lat: 48.509991, lng: 19.00975 },
    { lat: 48.509572, lng: 19.009723 },
    { lat: 48.509266, lng: 19.009481 },
    { lat: 48.50872, lng: 19.008118 },
    { lat: 48.508597, lng: 19.007811 },
    { lat: 48.508471, lng: 19.007571 },
    { lat: 48.508449, lng: 19.007531 },
    { lat: 48.508276, lng: 19.007198 },
    { lat: 48.507984, lng: 19.00676 },
    { lat: 48.507871, lng: 19.006656 },
    { lat: 48.507585, lng: 19.006393 },
    { lat: 48.50675, lng: 19.005619 },
    { lat: 48.506551, lng: 19.005433 },
    { lat: 48.506165, lng: 19.005335 },
    { lat: 48.505201, lng: 19.005328 },
    { lat: 48.504693, lng: 19.005167 },
    { lat: 48.504664, lng: 19.005161 },
    { lat: 48.504636, lng: 19.005156 },
    { lat: 48.504474, lng: 19.005126 },
    { lat: 48.502703, lng: 19.005359 },
    { lat: 48.502165, lng: 19.005319 },
    { lat: 48.501535, lng: 19.00531 },
    { lat: 48.501004, lng: 19.005155 },
    { lat: 48.50038, lng: 19.004694 },
    { lat: 48.499848, lng: 19.004572 },
    { lat: 48.499348, lng: 19.003983 },
    { lat: 48.498939, lng: 19.003623 },
    { lat: 48.498906, lng: 19.003612 },
    { lat: 48.498851, lng: 19.003547 },
    { lat: 48.49865, lng: 19.003374 },
    { lat: 48.498031, lng: 19.002927 },
    { lat: 48.497916, lng: 19.002851 },
    { lat: 48.49729, lng: 19.002734 },
    { lat: 48.496665, lng: 19.002764 },
    { lat: 48.496173, lng: 19.00288 },
    { lat: 48.495375, lng: 19.002622 },
    { lat: 48.494867, lng: 19.002254 },
    { lat: 48.494226, lng: 19.001722 },
    { lat: 48.494171, lng: 19.001274 },
    { lat: 48.494171, lng: 19.000867 },
    { lat: 48.494038, lng: 18.999686 },
    { lat: 48.49389, lng: 18.999078 },
    { lat: 48.493734, lng: 18.998323 },
    { lat: 48.493682, lng: 18.998072 },
    { lat: 48.493657, lng: 18.998141 },
    { lat: 48.493635, lng: 18.998159 },
    { lat: 48.492948, lng: 18.997291 },
    { lat: 48.492761, lng: 18.997032 },
    { lat: 48.492089, lng: 18.996109 },
    { lat: 48.49206, lng: 18.996072 },
    { lat: 48.492044, lng: 18.996027 },
  ],
  Turová: [
    { lat: 48.6191304, lng: 19.042631 },
    { lat: 48.6189242, lng: 19.0419832 },
    { lat: 48.6187859, lng: 19.0418344 },
    { lat: 48.618179, lng: 19.040636 },
    { lat: 48.6180949, lng: 19.0412054 },
    { lat: 48.6178041, lng: 19.0413904 },
    { lat: 48.6168029, lng: 19.0411842 },
    { lat: 48.6151982, lng: 19.0418954 },
    { lat: 48.6150256, lng: 19.0409531 },
    { lat: 48.61463, lng: 19.040057 },
    { lat: 48.6141656, lng: 19.0396547 },
    { lat: 48.6140267, lng: 19.0401872 },
    { lat: 48.6135718, lng: 19.0399767 },
    { lat: 48.6131607, lng: 19.0392918 },
    { lat: 48.6124254, lng: 19.0396891 },
    { lat: 48.6121593, lng: 19.0393953 },
    { lat: 48.6106753, lng: 19.0401735 },
    { lat: 48.610216, lng: 19.0403226 },
    { lat: 48.6097926, lng: 19.0403311 },
    { lat: 48.6094973, lng: 19.0399758 },
    { lat: 48.6093791, lng: 19.0401796 },
    { lat: 48.6091556, lng: 19.0398849 },
    { lat: 48.6090747, lng: 19.0394167 },
    { lat: 48.6092405, lng: 19.0389271 },
    { lat: 48.608811, lng: 19.0377151 },
    { lat: 48.6082076, lng: 19.0371086 },
    { lat: 48.6080092, lng: 19.0371114 },
    { lat: 48.6069916, lng: 19.0375231 },
    { lat: 48.6068442, lng: 19.0377295 },
    { lat: 48.6065012, lng: 19.0378332 },
    { lat: 48.6060211, lng: 19.0382415 },
    { lat: 48.6053147, lng: 19.0385891 },
    { lat: 48.6046614, lng: 19.0390717 },
    { lat: 48.6045556, lng: 19.0390763 },
    { lat: 48.60394, lng: 19.0398588 },
    { lat: 48.6035962, lng: 19.0395711 },
    { lat: 48.6030094, lng: 19.0388435 },
    { lat: 48.6024666, lng: 19.0386053 },
    { lat: 48.6023718, lng: 19.037463 },
    { lat: 48.6016634, lng: 19.0370938 },
    { lat: 48.6014121, lng: 19.0370299 },
    { lat: 48.6011253, lng: 19.0362142 },
    { lat: 48.6001809, lng: 19.0369399 },
    { lat: 48.5995524, lng: 19.035675 },
    { lat: 48.5984756, lng: 19.036009 },
    { lat: 48.5974678, lng: 19.0356296 },
    { lat: 48.5974435, lng: 19.0356861 },
    { lat: 48.5967369, lng: 19.035134 },
    { lat: 48.5963763, lng: 19.0349997 },
    { lat: 48.5951107, lng: 19.0355915 },
    { lat: 48.5943866, lng: 19.0362356 },
    { lat: 48.59485, lng: 19.0367959 },
    { lat: 48.5946362, lng: 19.0371869 },
    { lat: 48.5935406, lng: 19.0383995 },
    { lat: 48.5918219, lng: 19.0397574 },
    { lat: 48.5915864, lng: 19.039805 },
    { lat: 48.5917564, lng: 19.0402026 },
    { lat: 48.5917683, lng: 19.0403736 },
    { lat: 48.5918622, lng: 19.0405349 },
    { lat: 48.5919967, lng: 19.0412186 },
    { lat: 48.5920196, lng: 19.0415839 },
    { lat: 48.5921239, lng: 19.0417045 },
    { lat: 48.5920425, lng: 19.0421297 },
    { lat: 48.5919308, lng: 19.0422614 },
    { lat: 48.591736, lng: 19.0423343 },
    { lat: 48.5916395, lng: 19.0426092 },
    { lat: 48.5916216, lng: 19.0429955 },
    { lat: 48.5917131, lng: 19.0435798 },
    { lat: 48.5916378, lng: 19.0439674 },
    { lat: 48.5916751, lng: 19.0441725 },
    { lat: 48.5919299, lng: 19.0443307 },
    { lat: 48.5924132, lng: 19.0453532 },
    { lat: 48.5925259, lng: 19.0462934 },
    { lat: 48.5924439, lng: 19.0464588 },
    { lat: 48.592559, lng: 19.0469286 },
    { lat: 48.5928545, lng: 19.0474511 },
    { lat: 48.593052, lng: 19.0480141 },
    { lat: 48.5932176, lng: 19.0482114 },
    { lat: 48.5932136, lng: 19.0483151 },
    { lat: 48.5933754, lng: 19.0486403 },
    { lat: 48.593593, lng: 19.0489563 },
    { lat: 48.5941631, lng: 19.0494166 },
    { lat: 48.5943388, lng: 19.0494582 },
    { lat: 48.5946698, lng: 19.0505979 },
    { lat: 48.594677, lng: 19.0511644 },
    { lat: 48.5946417, lng: 19.0513173 },
    { lat: 48.5946868, lng: 19.0525502 },
    { lat: 48.5946805, lng: 19.0528305 },
    { lat: 48.5944831, lng: 19.0533911 },
    { lat: 48.5944151, lng: 19.0539253 },
    { lat: 48.594303, lng: 19.0542946 },
    { lat: 48.5944941, lng: 19.0551365 },
    { lat: 48.5940737, lng: 19.0554551 },
    { lat: 48.5941219, lng: 19.0557423 },
    { lat: 48.59399, lng: 19.0560993 },
    { lat: 48.5940536, lng: 19.0568293 },
    { lat: 48.5940215, lng: 19.0569519 },
    { lat: 48.5938714, lng: 19.0570174 },
    { lat: 48.5937589, lng: 19.0571813 },
    { lat: 48.5936085, lng: 19.0580496 },
    { lat: 48.5935149, lng: 19.0581148 },
    { lat: 48.59333, lng: 19.0580268 },
    { lat: 48.5932941, lng: 19.0585691 },
    { lat: 48.5932403, lng: 19.0587305 },
    { lat: 48.59246, lng: 19.0593343 },
    { lat: 48.5926004, lng: 19.059641 },
    { lat: 48.5924205, lng: 19.0598321 },
    { lat: 48.5924178, lng: 19.060269 },
    { lat: 48.592486, lng: 19.060372 },
    { lat: 48.5920975, lng: 19.061755 },
    { lat: 48.5924973, lng: 19.0630717 },
    { lat: 48.5925939, lng: 19.0635614 },
    { lat: 48.5923202, lng: 19.0642849 },
    { lat: 48.5923431, lng: 19.0647663 },
    { lat: 48.5931356, lng: 19.0649902 },
    { lat: 48.5936553, lng: 19.0667923 },
    { lat: 48.5939536, lng: 19.0675458 },
    { lat: 48.5940692, lng: 19.0683454 },
    { lat: 48.5940499, lng: 19.0692914 },
    { lat: 48.5940807, lng: 19.0696797 },
    { lat: 48.5943057, lng: 19.070527 },
    { lat: 48.5942371, lng: 19.0711627 },
    { lat: 48.5942843, lng: 19.07314 },
    { lat: 48.5935039, lng: 19.0741963 },
    { lat: 48.5927887, lng: 19.0756323 },
    { lat: 48.592211, lng: 19.0763668 },
    { lat: 48.592094, lng: 19.0786504 },
    { lat: 48.5918797, lng: 19.0798699 },
    { lat: 48.5917865, lng: 19.0807562 },
    { lat: 48.591788, lng: 19.082277 },
    { lat: 48.5914943, lng: 19.0827156 },
    { lat: 48.5912415, lng: 19.0836578 },
    { lat: 48.59101, lng: 19.0856598 },
    { lat: 48.5910359, lng: 19.085956 },
    { lat: 48.5908253, lng: 19.0868153 },
    { lat: 48.5908352, lng: 19.0868345 },
    { lat: 48.5908812, lng: 19.0868097 },
    { lat: 48.5910692, lng: 19.0870487 },
    { lat: 48.5911278, lng: 19.0869359 },
    { lat: 48.5914431, lng: 19.087158 },
    { lat: 48.5915308, lng: 19.0869921 },
    { lat: 48.5916117, lng: 19.0872116 },
    { lat: 48.5918448, lng: 19.0873436 },
    { lat: 48.5918962, lng: 19.0872231 },
    { lat: 48.5920603, lng: 19.0872355 },
    { lat: 48.5920873, lng: 19.087257 },
    { lat: 48.5920351, lng: 19.0873612 },
    { lat: 48.5921488, lng: 19.0875189 },
    { lat: 48.5927338, lng: 19.0878743 },
    { lat: 48.5928486, lng: 19.0877612 },
    { lat: 48.5928775, lng: 19.0875912 },
    { lat: 48.5930302, lng: 19.0872869 },
    { lat: 48.5932644, lng: 19.0870983 },
    { lat: 48.5934637, lng: 19.0874147 },
    { lat: 48.593667, lng: 19.0871702 },
    { lat: 48.5937255, lng: 19.0871927 },
    { lat: 48.5937539, lng: 19.0874207 },
    { lat: 48.5938274, lng: 19.0874027 },
    { lat: 48.5938897, lng: 19.0872721 },
    { lat: 48.5940366, lng: 19.0872821 },
    { lat: 48.5940954, lng: 19.0875315 },
    { lat: 48.5942085, lng: 19.0876302 },
    { lat: 48.5941714, lng: 19.0878815 },
    { lat: 48.5947178, lng: 19.0881647 },
    { lat: 48.594953, lng: 19.0884287 },
    { lat: 48.5951657, lng: 19.0884202 },
    { lat: 48.5953213, lng: 19.088669 },
    { lat: 48.5953749, lng: 19.0886601 },
    { lat: 48.5954787, lng: 19.08844 },
    { lat: 48.5956076, lng: 19.0883331 },
    { lat: 48.5958898, lng: 19.0882513 },
    { lat: 48.5961492, lng: 19.0883871 },
    { lat: 48.5961983, lng: 19.0883296 },
    { lat: 48.5962322, lng: 19.0881715 },
    { lat: 48.5963937, lng: 19.0879096 },
    { lat: 48.596647, lng: 19.0876554 },
    { lat: 48.5966026, lng: 19.0874599 },
    { lat: 48.5967206, lng: 19.0874102 },
    { lat: 48.5968268, lng: 19.0872575 },
    { lat: 48.5969025, lng: 19.0872593 },
    { lat: 48.5969731, lng: 19.0869802 },
    { lat: 48.597577, lng: 19.0865214 },
    { lat: 48.5976399, lng: 19.0861097 },
    { lat: 48.5978691, lng: 19.0856666 },
    { lat: 48.5979681, lng: 19.0852554 },
    { lat: 48.5982724, lng: 19.0852511 },
    { lat: 48.5983328, lng: 19.0851966 },
    { lat: 48.5984022, lng: 19.0849825 },
    { lat: 48.5984656, lng: 19.0849657 },
    { lat: 48.5984893, lng: 19.0847154 },
    { lat: 48.5984577, lng: 19.0846506 },
    { lat: 48.5985712, lng: 19.0844639 },
    { lat: 48.5985807, lng: 19.0841331 },
    { lat: 48.5988601, lng: 19.0842164 },
    { lat: 48.5989541, lng: 19.0837758 },
    { lat: 48.598917, lng: 19.0833256 },
    { lat: 48.5991729, lng: 19.0831248 },
    { lat: 48.5991556, lng: 19.0829095 },
    { lat: 48.5992852, lng: 19.0822776 },
    { lat: 48.5994467, lng: 19.0822379 },
    { lat: 48.599492, lng: 19.0820383 },
    { lat: 48.5996376, lng: 19.0820225 },
    { lat: 48.5996923, lng: 19.0818065 },
    { lat: 48.5999095, lng: 19.08153 },
    { lat: 48.5999959, lng: 19.0811113 },
    { lat: 48.5999174, lng: 19.0807166 },
    { lat: 48.6000725, lng: 19.0804119 },
    { lat: 48.6000385, lng: 19.0802705 },
    { lat: 48.5998352, lng: 19.0799278 },
    { lat: 48.5998304, lng: 19.0798325 },
    { lat: 48.6000377, lng: 19.0795361 },
    { lat: 48.6001216, lng: 19.0795654 },
    { lat: 48.6001759, lng: 19.0793224 },
    { lat: 48.6002312, lng: 19.0792577 },
    { lat: 48.6004146, lng: 19.0794046 },
    { lat: 48.6005316, lng: 19.079354 },
    { lat: 48.6005496, lng: 19.0792885 },
    { lat: 48.6004725, lng: 19.0788958 },
    { lat: 48.600497, lng: 19.0787082 },
    { lat: 48.6004241, lng: 19.0785117 },
    { lat: 48.6005277, lng: 19.0783549 },
    { lat: 48.6005124, lng: 19.0781387 },
    { lat: 48.6004096, lng: 19.077995 },
    { lat: 48.6004207, lng: 19.077815 },
    { lat: 48.6003569, lng: 19.0776391 },
    { lat: 48.6004711, lng: 19.0775809 },
    { lat: 48.6005312, lng: 19.0774548 },
    { lat: 48.6005553, lng: 19.0767842 },
    { lat: 48.6006162, lng: 19.0764474 },
    { lat: 48.6007047, lng: 19.0762739 },
    { lat: 48.6007051, lng: 19.0760124 },
    { lat: 48.6010226, lng: 19.0751065 },
    { lat: 48.6010749, lng: 19.0751355 },
    { lat: 48.6011651, lng: 19.0749788 },
    { lat: 48.6012076, lng: 19.0752187 },
    { lat: 48.6012538, lng: 19.0752651 },
    { lat: 48.6014385, lng: 19.0746721 },
    { lat: 48.6017119, lng: 19.0745046 },
    { lat: 48.6018301, lng: 19.0742514 },
    { lat: 48.6018314, lng: 19.0741636 },
    { lat: 48.6017047, lng: 19.0739779 },
    { lat: 48.6020148, lng: 19.0730973 },
    { lat: 48.6022684, lng: 19.0728722 },
    { lat: 48.6025636, lng: 19.0724401 },
    { lat: 48.6027507, lng: 19.0724402 },
    { lat: 48.602818, lng: 19.0722975 },
    { lat: 48.6030112, lng: 19.0722353 },
    { lat: 48.6031637, lng: 19.0720876 },
    { lat: 48.6042695, lng: 19.0715419 },
    { lat: 48.6046144, lng: 19.0714713 },
    { lat: 48.6046178, lng: 19.0714254 },
    { lat: 48.6050092, lng: 19.0713862 },
    { lat: 48.6060981, lng: 19.0714726 },
    { lat: 48.6065311, lng: 19.071341 },
    { lat: 48.6066751, lng: 19.070838 },
    { lat: 48.6070393, lng: 19.0705654 },
    { lat: 48.6078526, lng: 19.0694491 },
    { lat: 48.6085659, lng: 19.068271 },
    { lat: 48.6089852, lng: 19.0678874 },
    { lat: 48.6107173, lng: 19.0654615 },
    { lat: 48.611091, lng: 19.0652055 },
    { lat: 48.6114598, lng: 19.0648002 },
    { lat: 48.6130165, lng: 19.0636271 },
    { lat: 48.6131632, lng: 19.0636507 },
    { lat: 48.6134735, lng: 19.0634974 },
    { lat: 48.6148549, lng: 19.0623321 },
    { lat: 48.6149475, lng: 19.0621823 },
    { lat: 48.6154279, lng: 19.06065 },
    { lat: 48.6151785, lng: 19.05968 },
    { lat: 48.61518, lng: 19.0591447 },
    { lat: 48.6149444, lng: 19.0586538 },
    { lat: 48.614973, lng: 19.0580545 },
    { lat: 48.6151823, lng: 19.0576159 },
    { lat: 48.6152163, lng: 19.0574648 },
    { lat: 48.6151863, lng: 19.0572484 },
    { lat: 48.6153029, lng: 19.0566045 },
    { lat: 48.6152331, lng: 19.0554153 },
    { lat: 48.6153042, lng: 19.0550341 },
    { lat: 48.6153125, lng: 19.0547054 },
    { lat: 48.6156934, lng: 19.0533297 },
    { lat: 48.6162025, lng: 19.052647 },
    { lat: 48.6169295, lng: 19.0523706 },
    { lat: 48.6169572, lng: 19.0517125 },
    { lat: 48.6173584, lng: 19.0505839 },
    { lat: 48.6178472, lng: 19.0499487 },
    { lat: 48.61795, lng: 19.0490975 },
    { lat: 48.6180422, lng: 19.0486889 },
    { lat: 48.6181264, lng: 19.0471521 },
    { lat: 48.6184112, lng: 19.0458653 },
    { lat: 48.6184197, lng: 19.0452718 },
    { lat: 48.618364, lng: 19.0452161 },
    { lat: 48.6183227, lng: 19.0448891 },
    { lat: 48.6184783, lng: 19.0440599 },
    { lat: 48.61897, lng: 19.0430303 },
    { lat: 48.6191304, lng: 19.042631 },
  ],
  Sliač: [
    { lat: 48.667108, lng: 19.0255525 },
    { lat: 48.6667871, lng: 19.0257391 },
    { lat: 48.6645804, lng: 19.0265641 },
    { lat: 48.6631725, lng: 19.026971 },
    { lat: 48.6623979, lng: 19.0274646 },
    { lat: 48.6621637, lng: 19.0278706 },
    { lat: 48.6617229, lng: 19.0282093 },
    { lat: 48.6610658, lng: 19.0285515 },
    { lat: 48.6605389, lng: 19.0289907 },
    { lat: 48.6592851, lng: 19.0298163 },
    { lat: 48.6591397, lng: 19.029839 },
    { lat: 48.6588411, lng: 19.0302013 },
    { lat: 48.6581273, lng: 19.0306252 },
    { lat: 48.6576527, lng: 19.0307153 },
    { lat: 48.6571346, lng: 19.0309501 },
    { lat: 48.6563554, lng: 19.031171 },
    { lat: 48.6561222, lng: 19.0313964 },
    { lat: 48.6550214, lng: 19.0315678 },
    { lat: 48.6548841, lng: 19.0317729 },
    { lat: 48.6545016, lng: 19.0319465 },
    { lat: 48.6543207, lng: 19.0318492 },
    { lat: 48.6539979, lng: 19.0319511 },
    { lat: 48.6537021, lng: 19.0318335 },
    { lat: 48.653469, lng: 19.0318388 },
    { lat: 48.6533462, lng: 19.0317516 },
    { lat: 48.6530392, lng: 19.0317861 },
    { lat: 48.6526947, lng: 19.0315988 },
    { lat: 48.6516679, lng: 19.0313988 },
    { lat: 48.6514407, lng: 19.0310308 },
    { lat: 48.6512586, lng: 19.0309037 },
    { lat: 48.6503807, lng: 19.0299286 },
    { lat: 48.649735, lng: 19.0296885 },
    { lat: 48.6486737, lng: 19.0297898 },
    { lat: 48.6484058, lng: 19.0299596 },
    { lat: 48.6479552, lng: 19.0299207 },
    { lat: 48.647697, lng: 19.0301649 },
    { lat: 48.6470166, lng: 19.030085 },
    { lat: 48.6463357, lng: 19.0295965 },
    { lat: 48.6456764, lng: 19.0293298 },
    { lat: 48.6454704, lng: 19.0290682 },
    { lat: 48.6453952, lng: 19.0292551 },
    { lat: 48.6449914, lng: 19.0289627 },
    { lat: 48.6449044, lng: 19.0290215 },
    { lat: 48.6447758, lng: 19.0288241 },
    { lat: 48.6443094, lng: 19.0285831 },
    { lat: 48.6430938, lng: 19.0283988 },
    { lat: 48.6429446, lng: 19.0278758 },
    { lat: 48.6424493, lng: 19.0278028 },
    { lat: 48.6422851, lng: 19.0279193 },
    { lat: 48.6416462, lng: 19.0280171 },
    { lat: 48.6416301, lng: 19.0290625 },
    { lat: 48.6415336, lng: 19.029568 },
    { lat: 48.6416007, lng: 19.030207 },
    { lat: 48.6417922, lng: 19.0310527 },
    { lat: 48.6416189, lng: 19.0325399 },
    { lat: 48.6412202, lng: 19.0333307 },
    { lat: 48.6411879, lng: 19.0335843 },
    { lat: 48.6411957, lng: 19.034726 },
    { lat: 48.6408666, lng: 19.0360752 },
    { lat: 48.6395451, lng: 19.0358696 },
    { lat: 48.6394107, lng: 19.0359538 },
    { lat: 48.6392664, lng: 19.0359097 },
    { lat: 48.638179, lng: 19.036097 },
    { lat: 48.6380083, lng: 19.0358884 },
    { lat: 48.6379251, lng: 19.0360719 },
    { lat: 48.6377258, lng: 19.0360786 },
    { lat: 48.6371944, lng: 19.03587 },
    { lat: 48.637021, lng: 19.0360959 },
    { lat: 48.6367845, lng: 19.0360107 },
    { lat: 48.6366499, lng: 19.0361384 },
    { lat: 48.636351, lng: 19.0360442 },
    { lat: 48.6359668, lng: 19.0360943 },
    { lat: 48.6355014, lng: 19.0365033 },
    { lat: 48.6352366, lng: 19.036503 },
    { lat: 48.6350458, lng: 19.036703 },
    { lat: 48.6343047, lng: 19.0368731 },
    { lat: 48.6342027, lng: 19.0370195 },
    { lat: 48.6340601, lng: 19.0369406 },
    { lat: 48.6338389, lng: 19.0370483 },
    { lat: 48.6333271, lng: 19.0370509 },
    { lat: 48.6332331, lng: 19.0371789 },
    { lat: 48.6328349, lng: 19.0370399 },
    { lat: 48.6322627, lng: 19.0370487 },
    { lat: 48.6320401, lng: 19.0369259 },
    { lat: 48.6318238, lng: 19.0369991 },
    { lat: 48.631516, lng: 19.036906 },
    { lat: 48.6310039, lng: 19.0369016 },
    { lat: 48.6309305, lng: 19.0369884 },
    { lat: 48.6304855, lng: 19.0369958 },
    { lat: 48.6301715, lng: 19.0368493 },
    { lat: 48.6301663, lng: 19.0368356 },
    { lat: 48.629879, lng: 19.0369993 },
    { lat: 48.6296029, lng: 19.0369115 },
    { lat: 48.6293983, lng: 19.0371933 },
    { lat: 48.6292353, lng: 19.0369774 },
    { lat: 48.629093, lng: 19.0370169 },
    { lat: 48.6289881, lng: 19.0369466 },
    { lat: 48.6287036, lng: 19.0372985 },
    { lat: 48.6283358, lng: 19.0372233 },
    { lat: 48.6281801, lng: 19.0372896 },
    { lat: 48.6279412, lng: 19.0372036 },
    { lat: 48.6276959, lng: 19.0372816 },
    { lat: 48.6269804, lng: 19.0372654 },
    { lat: 48.6266852, lng: 19.0371135 },
    { lat: 48.6262147, lng: 19.0372601 },
    { lat: 48.6261718, lng: 19.0373747 },
    { lat: 48.6259796, lng: 19.0374299 },
    { lat: 48.6259015, lng: 19.0373568 },
    { lat: 48.6256648, lng: 19.0374937 },
    { lat: 48.6252982, lng: 19.0373532 },
    { lat: 48.6252152, lng: 19.0374977 },
    { lat: 48.6246513, lng: 19.0375833 },
    { lat: 48.624619, lng: 19.0374341 },
    { lat: 48.624312, lng: 19.0376941 },
    { lat: 48.6241294, lng: 19.0376287 },
    { lat: 48.6232052, lng: 19.0381083 },
    { lat: 48.623032, lng: 19.0380469 },
    { lat: 48.6228147, lng: 19.0382129 },
    { lat: 48.6225266, lng: 19.0382195 },
    { lat: 48.6221, lng: 19.0387048 },
    { lat: 48.6216046, lng: 19.0395276 },
    { lat: 48.6211598, lng: 19.0404352 },
    { lat: 48.6209124, lng: 19.0407396 },
    { lat: 48.6205866, lng: 19.0410074 },
    { lat: 48.6203472, lng: 19.0409789 },
    { lat: 48.6201878, lng: 19.0410591 },
    { lat: 48.6197009, lng: 19.0416892 },
    { lat: 48.6193116, lng: 19.0420662 },
    { lat: 48.6191304, lng: 19.042631 },
    { lat: 48.61897, lng: 19.0430303 },
    { lat: 48.6184783, lng: 19.0440599 },
    { lat: 48.6183227, lng: 19.0448891 },
    { lat: 48.618364, lng: 19.0452161 },
    { lat: 48.6184197, lng: 19.0452718 },
    { lat: 48.6184112, lng: 19.0458653 },
    { lat: 48.6181264, lng: 19.0471521 },
    { lat: 48.6180422, lng: 19.0486889 },
    { lat: 48.61795, lng: 19.0490975 },
    { lat: 48.6178472, lng: 19.0499487 },
    { lat: 48.6173584, lng: 19.0505839 },
    { lat: 48.6169572, lng: 19.0517125 },
    { lat: 48.6169295, lng: 19.0523706 },
    { lat: 48.6162025, lng: 19.052647 },
    { lat: 48.6156934, lng: 19.0533297 },
    { lat: 48.6153125, lng: 19.0547054 },
    { lat: 48.6153042, lng: 19.0550341 },
    { lat: 48.6152331, lng: 19.0554153 },
    { lat: 48.6153029, lng: 19.0566045 },
    { lat: 48.6151863, lng: 19.0572484 },
    { lat: 48.6152163, lng: 19.0574648 },
    { lat: 48.6152917, lng: 19.0576397 },
    { lat: 48.6152704, lng: 19.0576663 },
    { lat: 48.6158491, lng: 19.058773 },
    { lat: 48.6164892, lng: 19.0592188 },
    { lat: 48.6166813, lng: 19.0595443 },
    { lat: 48.6170705, lng: 19.0604228 },
    { lat: 48.6175104, lng: 19.0605889 },
    { lat: 48.6182692, lng: 19.0605221 },
    { lat: 48.6187462, lng: 19.0606127 },
    { lat: 48.6189277, lng: 19.0607699 },
    { lat: 48.6194089, lng: 19.0617124 },
    { lat: 48.620553, lng: 19.0616836 },
    { lat: 48.621426, lng: 19.0619868 },
    { lat: 48.6210227, lng: 19.063322 },
    { lat: 48.6209346, lng: 19.064219 },
    { lat: 48.6206102, lng: 19.0651948 },
    { lat: 48.6207982, lng: 19.0661331 },
    { lat: 48.6212727, lng: 19.0668965 },
    { lat: 48.6213754, lng: 19.0679136 },
    { lat: 48.6216114, lng: 19.0685748 },
    { lat: 48.6210956, lng: 19.0693715 },
    { lat: 48.6209884, lng: 19.0710164 },
    { lat: 48.6210498, lng: 19.0715229 },
    { lat: 48.6214784, lng: 19.0724078 },
    { lat: 48.6216961, lng: 19.0731099 },
    { lat: 48.6219403, lng: 19.0732122 },
    { lat: 48.6220443, lng: 19.0731751 },
    { lat: 48.6229037, lng: 19.0748285 },
    { lat: 48.6235883, lng: 19.074833 },
    { lat: 48.6236453, lng: 19.0749815 },
    { lat: 48.6239814, lng: 19.0749597 },
    { lat: 48.6242016, lng: 19.0750604 },
    { lat: 48.6241112, lng: 19.0752799 },
    { lat: 48.6240014, lng: 19.0751349 },
    { lat: 48.6239311, lng: 19.0752637 },
    { lat: 48.6237675, lng: 19.0756447 },
    { lat: 48.623829, lng: 19.0757453 },
    { lat: 48.6236677, lng: 19.0760391 },
    { lat: 48.6235842, lng: 19.0758344 },
    { lat: 48.6235175, lng: 19.075852 },
    { lat: 48.6238109, lng: 19.076309 },
    { lat: 48.6236836, lng: 19.0766271 },
    { lat: 48.6236384, lng: 19.0768756 },
    { lat: 48.6232877, lng: 19.0773477 },
    { lat: 48.6227874, lng: 19.0774434 },
    { lat: 48.6224954, lng: 19.0777791 },
    { lat: 48.6224209, lng: 19.0778008 },
    { lat: 48.6218066, lng: 19.0774878 },
    { lat: 48.6211168, lng: 19.0774921 },
    { lat: 48.6203368, lng: 19.0772472 },
    { lat: 48.6198252, lng: 19.0776082 },
    { lat: 48.6196059, lng: 19.0776698 },
    { lat: 48.6192118, lng: 19.0779245 },
    { lat: 48.6187053, lng: 19.0784819 },
    { lat: 48.618227, lng: 19.0792453 },
    { lat: 48.6176866, lng: 19.0796869 },
    { lat: 48.6173836, lng: 19.0797847 },
    { lat: 48.6169012, lng: 19.0796952 },
    { lat: 48.6163922, lng: 19.080047 },
    { lat: 48.6164406, lng: 19.0804056 },
    { lat: 48.6154116, lng: 19.0817884 },
    { lat: 48.6144486, lng: 19.0833207 },
    { lat: 48.6143189, lng: 19.0836814 },
    { lat: 48.6137116, lng: 19.084387 },
    { lat: 48.6137031, lng: 19.0848767 },
    { lat: 48.6135749, lng: 19.0851524 },
    { lat: 48.613601, lng: 19.0856518 },
    { lat: 48.6134944, lng: 19.0863181 },
    { lat: 48.6133407, lng: 19.0866045 },
    { lat: 48.6132532, lng: 19.0869953 },
    { lat: 48.6132577, lng: 19.087428 },
    { lat: 48.6129077, lng: 19.088524 },
    { lat: 48.6130522, lng: 19.0890488 },
    { lat: 48.6132627, lng: 19.0890681 },
    { lat: 48.6132742, lng: 19.0892264 },
    { lat: 48.6132511, lng: 19.0898724 },
    { lat: 48.6128065, lng: 19.0923106 },
    { lat: 48.6137011, lng: 19.0927981 },
    { lat: 48.6129385, lng: 19.0952787 },
    { lat: 48.6123155, lng: 19.0986365 },
    { lat: 48.6125323, lng: 19.0995942 },
    { lat: 48.6124989, lng: 19.099987 },
    { lat: 48.6123104, lng: 19.1007529 },
    { lat: 48.6122667, lng: 19.1008391 },
    { lat: 48.6120026, lng: 19.1014796 },
    { lat: 48.6117264, lng: 19.1018621 },
    { lat: 48.6112982, lng: 19.1027285 },
    { lat: 48.6111941, lng: 19.1031069 },
    { lat: 48.6108737, lng: 19.102898 },
    { lat: 48.6108447, lng: 19.1028791 },
    { lat: 48.61052, lng: 19.1026674 },
    { lat: 48.6104791, lng: 19.1026407 },
    { lat: 48.6102283, lng: 19.1024772 },
    { lat: 48.6101055, lng: 19.1037704 },
    { lat: 48.610013, lng: 19.1041104 },
    { lat: 48.6098137, lng: 19.1044788 },
    { lat: 48.6095942, lng: 19.1046917 },
    { lat: 48.6092504, lng: 19.1048503 },
    { lat: 48.6080263, lng: 19.105284 },
    { lat: 48.6076489, lng: 19.1053349 },
    { lat: 48.6073173, lng: 19.1052416 },
    { lat: 48.6069393, lng: 19.1049167 },
    { lat: 48.6067148, lng: 19.10453 },
    { lat: 48.6035588, lng: 19.1103946 },
    { lat: 48.6033426, lng: 19.1108728 },
    { lat: 48.6031639, lng: 19.111091 },
    { lat: 48.6023547, lng: 19.1087824 },
    { lat: 48.6021175, lng: 19.108339 },
    { lat: 48.6014962, lng: 19.1074978 },
    { lat: 48.6005008, lng: 19.1084508 },
    { lat: 48.5996171, lng: 19.1088412 },
    { lat: 48.597972, lng: 19.1091003 },
    { lat: 48.5953777, lng: 19.1099162 },
    { lat: 48.593956, lng: 19.1116611 },
    { lat: 48.5940891, lng: 19.1118438 },
    { lat: 48.5950323, lng: 19.1130191 },
    { lat: 48.5955989, lng: 19.1133067 },
    { lat: 48.5956046, lng: 19.1136262 },
    { lat: 48.5955209, lng: 19.1140292 },
    { lat: 48.5963943, lng: 19.1148858 },
    { lat: 48.5967077, lng: 19.115613 },
    { lat: 48.5975994, lng: 19.1160637 },
    { lat: 48.597689, lng: 19.1161889 },
    { lat: 48.5979378, lng: 19.1163411 },
    { lat: 48.5979588, lng: 19.1169453 },
    { lat: 48.5981337, lng: 19.1181326 },
    { lat: 48.5981204, lng: 19.1184984 },
    { lat: 48.5981705, lng: 19.1189839 },
    { lat: 48.5983245, lng: 19.1200236 },
    { lat: 48.5982718, lng: 19.1202119 },
    { lat: 48.5979805, lng: 19.1204976 },
    { lat: 48.5977511, lng: 19.1210078 },
    { lat: 48.5971347, lng: 19.1238378 },
    { lat: 48.5969483, lng: 19.1244849 },
    { lat: 48.5968937, lng: 19.1245462 },
    { lat: 48.5971219, lng: 19.1256 },
    { lat: 48.5971081, lng: 19.1264139 },
    { lat: 48.597208, lng: 19.1274537 },
    { lat: 48.5973119, lng: 19.1280054 },
    { lat: 48.5974433, lng: 19.1280408 },
    { lat: 48.597887, lng: 19.128419 },
    { lat: 48.5985292, lng: 19.1295231 },
    { lat: 48.5987449, lng: 19.1301018 },
    { lat: 48.598832, lng: 19.1309962 },
    { lat: 48.5992069, lng: 19.1312828 },
    { lat: 48.5996727, lng: 19.1312867 },
    { lat: 48.5995782, lng: 19.131631 },
    { lat: 48.5996081, lng: 19.1317584 },
    { lat: 48.5995719, lng: 19.1320947 },
    { lat: 48.5996031, lng: 19.132592 },
    { lat: 48.599533, lng: 19.1332493 },
    { lat: 48.5996206, lng: 19.1340645 },
    { lat: 48.599617, lng: 19.1350839 },
    { lat: 48.5995219, lng: 19.1360331 },
    { lat: 48.5994484, lng: 19.1377377 },
    { lat: 48.5990402, lng: 19.1402239 },
    { lat: 48.5988715, lng: 19.1407536 },
    { lat: 48.5982407, lng: 19.1422592 },
    { lat: 48.5976421, lng: 19.1436878 },
    { lat: 48.5973841, lng: 19.1445341 },
    { lat: 48.596817, lng: 19.1437141 },
    { lat: 48.5960337, lng: 19.1449007 },
    { lat: 48.5970222, lng: 19.1460413 },
    { lat: 48.5969574, lng: 19.1462604 },
    { lat: 48.5977852, lng: 19.1475693 },
    { lat: 48.5979928, lng: 19.1480991 },
    { lat: 48.5984201, lng: 19.1499267 },
    { lat: 48.5990092, lng: 19.1513112 },
    { lat: 48.5993666, lng: 19.1524992 },
    { lat: 48.5994395, lng: 19.152881 },
    { lat: 48.598972, lng: 19.1533212 },
    { lat: 48.5983569, lng: 19.1547731 },
    { lat: 48.5979628, lng: 19.1554006 },
    { lat: 48.59778, lng: 19.1560155 },
    { lat: 48.5976644, lng: 19.156756 },
    { lat: 48.5974572, lng: 19.1573779 },
    { lat: 48.5972869, lng: 19.1580832 },
    { lat: 48.5970427, lng: 19.159352 },
    { lat: 48.5970171, lng: 19.1608036 },
    { lat: 48.5970504, lng: 19.161316 },
    { lat: 48.5972256, lng: 19.1621062 },
    { lat: 48.5972197, lng: 19.1624427 },
    { lat: 48.5974144, lng: 19.1626638 },
    { lat: 48.5979567, lng: 19.1628991 },
    { lat: 48.5986208, lng: 19.1630588 },
    { lat: 48.5991232, lng: 19.163326 },
    { lat: 48.5999208, lng: 19.1635252 },
    { lat: 48.6006023, lng: 19.1641125 },
    { lat: 48.6009528, lng: 19.165436 },
    { lat: 48.6015774, lng: 19.1655998 },
    { lat: 48.6019199, lng: 19.1669918 },
    { lat: 48.601983, lng: 19.1670175 },
    { lat: 48.6021541, lng: 19.1690082 },
    { lat: 48.6022688, lng: 19.169025 },
    { lat: 48.6024023, lng: 19.1702937 },
    { lat: 48.6025408, lng: 19.1702921 },
    { lat: 48.6026052, lng: 19.1704345 },
    { lat: 48.6025316, lng: 19.1719166 },
    { lat: 48.6025223, lng: 19.1729672 },
    { lat: 48.6025588, lng: 19.1734799 },
    { lat: 48.6028847, lng: 19.1743304 },
    { lat: 48.6032335, lng: 19.1746637 },
    { lat: 48.6031446, lng: 19.1752396 },
    { lat: 48.6030415, lng: 19.1769261 },
    { lat: 48.603297, lng: 19.1779063 },
    { lat: 48.6033205, lng: 19.1781541 },
    { lat: 48.6032624, lng: 19.1784433 },
    { lat: 48.6030444, lng: 19.1789406 },
    { lat: 48.6029597, lng: 19.1798562 },
    { lat: 48.6024874, lng: 19.1808905 },
    { lat: 48.6023981, lng: 19.1812755 },
    { lat: 48.6022239, lng: 19.1816539 },
    { lat: 48.6018942, lng: 19.1835534 },
    { lat: 48.60188, lng: 19.1842512 },
    { lat: 48.6016184, lng: 19.1851697 },
    { lat: 48.6014619, lng: 19.1853556 },
    { lat: 48.6012915, lng: 19.1863647 },
    { lat: 48.6004272, lng: 19.1877499 },
    { lat: 48.6004365, lng: 19.1887251 },
    { lat: 48.6001507, lng: 19.1890778 },
    { lat: 48.599939, lng: 19.1895921 },
    { lat: 48.6002613, lng: 19.1899127 },
    { lat: 48.6005251, lng: 19.1902776 },
    { lat: 48.60056, lng: 19.1904849 },
    { lat: 48.6007173, lng: 19.1907979 },
    { lat: 48.601003, lng: 19.1910176 },
    { lat: 48.6011219, lng: 19.1914873 },
    { lat: 48.6012087, lng: 19.191624 },
    { lat: 48.6020691, lng: 19.1918381 },
    { lat: 48.6025917, lng: 19.1924726 },
    { lat: 48.6027133, lng: 19.1927024 },
    { lat: 48.602889, lng: 19.1928612 },
    { lat: 48.6030119, lng: 19.1928397 },
    { lat: 48.6031343, lng: 19.1926911 },
    { lat: 48.603525, lng: 19.1928631 },
    { lat: 48.6036697, lng: 19.1928032 },
    { lat: 48.6038009, lng: 19.1926515 },
    { lat: 48.6043486, lng: 19.1929246 },
    { lat: 48.6044351, lng: 19.1929161 },
    { lat: 48.6048547, lng: 19.1926963 },
    { lat: 48.6051155, lng: 19.1926772 },
    { lat: 48.6053045, lng: 19.1924646 },
    { lat: 48.6056032, lng: 19.1923034 },
    { lat: 48.6060291, lng: 19.1917919 },
    { lat: 48.6061853, lng: 19.1914597 },
    { lat: 48.6063238, lng: 19.191409 },
    { lat: 48.6064177, lng: 19.1909576 },
    { lat: 48.6063849, lng: 19.1905384 },
    { lat: 48.6064099, lng: 19.1902689 },
    { lat: 48.6065634, lng: 19.1896252 },
    { lat: 48.6066611, lng: 19.1894787 },
    { lat: 48.6071469, lng: 19.1892012 },
    { lat: 48.6076804, lng: 19.188704 },
    { lat: 48.6078421, lng: 19.1883929 },
    { lat: 48.6079725, lng: 19.187833 },
    { lat: 48.6080015, lng: 19.1874558 },
    { lat: 48.6080771, lng: 19.1864667 },
    { lat: 48.6081502, lng: 19.1860504 },
    { lat: 48.6083943, lng: 19.185304 },
    { lat: 48.6086451, lng: 19.1849957 },
    { lat: 48.6088084, lng: 19.1845536 },
    { lat: 48.6087656, lng: 19.1819121 },
    { lat: 48.6088906, lng: 19.1817486 },
    { lat: 48.6088683, lng: 19.1813614 },
    { lat: 48.6090064, lng: 19.1807768 },
    { lat: 48.6093706, lng: 19.1796529 },
    { lat: 48.6100549, lng: 19.1796289 },
    { lat: 48.6106306, lng: 19.1795095 },
    { lat: 48.6113153, lng: 19.1795845 },
    { lat: 48.6124423, lng: 19.178799 },
    { lat: 48.6126148, lng: 19.1783145 },
    { lat: 48.6127397, lng: 19.1770046 },
    { lat: 48.6132194, lng: 19.175613 },
    { lat: 48.6136531, lng: 19.1749664 },
    { lat: 48.613928, lng: 19.1748257 },
    { lat: 48.6140036, lng: 19.1745963 },
    { lat: 48.6142195, lng: 19.1743795 },
    { lat: 48.6146297, lng: 19.1737808 },
    { lat: 48.6149463, lng: 19.1728095 },
    { lat: 48.6148262, lng: 19.1724998 },
    { lat: 48.6149168, lng: 19.1721655 },
    { lat: 48.6147696, lng: 19.1714399 },
    { lat: 48.6149386, lng: 19.1710943 },
    { lat: 48.6149828, lng: 19.1706202 },
    { lat: 48.6153233, lng: 19.1697702 },
    { lat: 48.6153894, lng: 19.1692559 },
    { lat: 48.6156044, lng: 19.1689732 },
    { lat: 48.6154925, lng: 19.1684399 },
    { lat: 48.6156616, lng: 19.1683715 },
    { lat: 48.6158197, lng: 19.1681237 },
    { lat: 48.6159613, lng: 19.1677502 },
    { lat: 48.6162435, lng: 19.1679666 },
    { lat: 48.6168993, lng: 19.1675437 },
    { lat: 48.6174391, lng: 19.1675881 },
    { lat: 48.618081, lng: 19.1671387 },
    { lat: 48.6187562, lng: 19.1668843 },
    { lat: 48.6199229, lng: 19.1666787 },
    { lat: 48.6206185, lng: 19.1668635 },
    { lat: 48.6212774, lng: 19.1667363 },
    { lat: 48.6216775, lng: 19.1665765 },
    { lat: 48.6228506, lng: 19.1642525 },
    { lat: 48.6227686, lng: 19.1641452 },
    { lat: 48.6227164, lng: 19.1638852 },
    { lat: 48.6225738, lng: 19.1635513 },
    { lat: 48.6214236, lng: 19.1618689 },
    { lat: 48.6229366, lng: 19.1576115 },
    { lat: 48.6235761, lng: 19.1554258 },
    { lat: 48.6237003, lng: 19.1552241 },
    { lat: 48.6244882, lng: 19.1552196 },
    { lat: 48.6248577, lng: 19.1551258 },
    { lat: 48.6258039, lng: 19.1553383 },
    { lat: 48.6261348, lng: 19.1555346 },
    { lat: 48.6267745, lng: 19.1562066 },
    { lat: 48.6272343, lng: 19.1560446 },
    { lat: 48.6277913, lng: 19.1562755 },
    { lat: 48.6281781, lng: 19.1550647 },
    { lat: 48.6293216, lng: 19.1526455 },
    { lat: 48.6297971, lng: 19.1514336 },
    { lat: 48.6298665, lng: 19.1511927 },
    { lat: 48.6297971, lng: 19.1511349 },
    { lat: 48.6297797, lng: 19.1510328 },
    { lat: 48.6297793, lng: 19.1505692 },
    { lat: 48.6299603, lng: 19.1506038 },
    { lat: 48.6304603, lng: 19.1478268 },
    { lat: 48.6298021, lng: 19.1474178 },
    { lat: 48.629027, lng: 19.1467411 },
    { lat: 48.6288412, lng: 19.1463322 },
    { lat: 48.6285996, lng: 19.1460981 },
    { lat: 48.6283148, lng: 19.1456255 },
    { lat: 48.6280712, lng: 19.1451654 },
    { lat: 48.6279072, lng: 19.1446097 },
    { lat: 48.627794, lng: 19.1444841 },
    { lat: 48.6277314, lng: 19.1440136 },
    { lat: 48.6277536, lng: 19.1438226 },
    { lat: 48.6280471, lng: 19.1435388 },
    { lat: 48.6281206, lng: 19.1433367 },
    { lat: 48.6283398, lng: 19.1431841 },
    { lat: 48.6283343, lng: 19.143029 },
    { lat: 48.628536, lng: 19.1419098 },
    { lat: 48.6282497, lng: 19.1414306 },
    { lat: 48.628221, lng: 19.1414189 },
    { lat: 48.6277975, lng: 19.1412434 },
    { lat: 48.6276186, lng: 19.1409545 },
    { lat: 48.6274403, lng: 19.1407882 },
    { lat: 48.6273414, lng: 19.140588 },
    { lat: 48.6271766, lng: 19.1404273 },
    { lat: 48.6270021, lng: 19.1403624 },
    { lat: 48.6268735, lng: 19.1404503 },
    { lat: 48.6261952, lng: 19.1404592 },
    { lat: 48.6258806, lng: 19.1405701 },
    { lat: 48.6249033, lng: 19.1407077 },
    { lat: 48.6242684, lng: 19.1409333 },
    { lat: 48.6236605, lng: 19.1409029 },
    { lat: 48.6229488, lng: 19.1406693 },
    { lat: 48.6223585, lng: 19.1403783 },
    { lat: 48.6223675, lng: 19.1403356 },
    { lat: 48.6222655, lng: 19.1397776 },
    { lat: 48.6220613, lng: 19.1376716 },
    { lat: 48.6230535, lng: 19.1330935 },
    { lat: 48.6237279, lng: 19.1303854 },
    { lat: 48.6239753, lng: 19.1291207 },
    { lat: 48.6241791, lng: 19.128523 },
    { lat: 48.6242573, lng: 19.1280751 },
    { lat: 48.6248429, lng: 19.1268336 },
    { lat: 48.624339, lng: 19.124897 },
    { lat: 48.6240806, lng: 19.1241661 },
    { lat: 48.6239026, lng: 19.1226671 },
    { lat: 48.623679, lng: 19.1224065 },
    { lat: 48.6235406, lng: 19.1218887 },
    { lat: 48.6235414, lng: 19.1215176 },
    { lat: 48.6237396, lng: 19.1203311 },
    { lat: 48.6238192, lng: 19.119411 },
    { lat: 48.6237397, lng: 19.1188552 },
    { lat: 48.6238045, lng: 19.1182998 },
    { lat: 48.6238594, lng: 19.1154476 },
    { lat: 48.6239917, lng: 19.1147046 },
    { lat: 48.624004, lng: 19.11423 },
    { lat: 48.6244836, lng: 19.1135124 },
    { lat: 48.6246185, lng: 19.1131839 },
    { lat: 48.6253706, lng: 19.1106444 },
    { lat: 48.6258809, lng: 19.1087277 },
    { lat: 48.626439, lng: 19.1057574 },
    { lat: 48.6274736, lng: 19.1044822 },
    { lat: 48.6275814, lng: 19.10368 },
    { lat: 48.6276574, lng: 19.1036649 },
    { lat: 48.6279575, lng: 19.1032853 },
    { lat: 48.6284792, lng: 19.1028706 },
    { lat: 48.6288726, lng: 19.1023903 },
    { lat: 48.6289289, lng: 19.1021227 },
    { lat: 48.6292043, lng: 19.1019222 },
    { lat: 48.6298029, lng: 19.1011032 },
    { lat: 48.6299879, lng: 19.100563 },
    { lat: 48.6301605, lng: 19.1002277 },
    { lat: 48.6301916, lng: 19.0996522 },
    { lat: 48.6304134, lng: 19.0993056 },
    { lat: 48.6303141, lng: 19.0992798 },
    { lat: 48.631041, lng: 19.0983005 },
    { lat: 48.6316864, lng: 19.0969532 },
    { lat: 48.6321179, lng: 19.0962671 },
    { lat: 48.6323783, lng: 19.0956314 },
    { lat: 48.6325142, lng: 19.0948982 },
    { lat: 48.6326505, lng: 19.0926769 },
    { lat: 48.6326342, lng: 19.0923494 },
    { lat: 48.6328951, lng: 19.0917485 },
    { lat: 48.6330359, lng: 19.0911798 },
    { lat: 48.6331796, lng: 19.0902603 },
    { lat: 48.6335316, lng: 19.0896541 },
    { lat: 48.634424, lng: 19.0874956 },
    { lat: 48.6353396, lng: 19.086527 },
    { lat: 48.6361291, lng: 19.0852472 },
    { lat: 48.6365178, lng: 19.084923 },
    { lat: 48.6368573, lng: 19.0844528 },
    { lat: 48.6371206, lng: 19.0846029 },
    { lat: 48.6372462, lng: 19.0845872 },
    { lat: 48.6374927, lng: 19.0843757 },
    { lat: 48.6375497, lng: 19.0842548 },
    { lat: 48.6384671, lng: 19.0837959 },
    { lat: 48.6390352, lng: 19.083185 },
    { lat: 48.6397678, lng: 19.0821618 },
    { lat: 48.639919, lng: 19.0815076 },
    { lat: 48.6404801, lng: 19.0802825 },
    { lat: 48.6407137, lng: 19.0799259 },
    { lat: 48.6418069, lng: 19.0789023 },
    { lat: 48.6421229, lng: 19.0787287 },
    { lat: 48.6427113, lng: 19.0777999 },
    { lat: 48.6431502, lng: 19.077401 },
    { lat: 48.6434831, lng: 19.0769999 },
    { lat: 48.6448241, lng: 19.0759943 },
    { lat: 48.6458847, lng: 19.075699 },
    { lat: 48.6460525, lng: 19.0755823 },
    { lat: 48.6473396, lng: 19.0743531 },
    { lat: 48.6478311, lng: 19.0733813 },
    { lat: 48.6482993, lng: 19.07303 },
    { lat: 48.6488946, lng: 19.0727141 },
    { lat: 48.6494046, lng: 19.0720692 },
    { lat: 48.6498732, lng: 19.0715848 },
    { lat: 48.6509024, lng: 19.0702069 },
    { lat: 48.651602, lng: 19.0694288 },
    { lat: 48.6539019, lng: 19.0664349 },
    { lat: 48.6542196, lng: 19.0658425 },
    { lat: 48.6543291, lng: 19.0650562 },
    { lat: 48.6544019, lng: 19.0648454 },
    { lat: 48.6546689, lng: 19.0645008 },
    { lat: 48.65487, lng: 19.0643495 },
    { lat: 48.6560593, lng: 19.0642375 },
    { lat: 48.6565918, lng: 19.0638837 },
    { lat: 48.6570173, lng: 19.0633752 },
    { lat: 48.6571697, lng: 19.0626817 },
    { lat: 48.6572058, lng: 19.062141 },
    { lat: 48.6573819, lng: 19.0617386 },
    { lat: 48.6575377, lng: 19.0611774 },
    { lat: 48.6578169, lng: 19.059753 },
    { lat: 48.6578562, lng: 19.0585778 },
    { lat: 48.6580437, lng: 19.0579777 },
    { lat: 48.6583383, lng: 19.0567476 },
    { lat: 48.6589339, lng: 19.0552671 },
    { lat: 48.6599023, lng: 19.0538296 },
    { lat: 48.6607704, lng: 19.0529976 },
    { lat: 48.661003, lng: 19.052439 },
    { lat: 48.6621752, lng: 19.0488796 },
    { lat: 48.6621554, lng: 19.0485081 },
    { lat: 48.6619884, lng: 19.0480101 },
    { lat: 48.6615132, lng: 19.047706 },
    { lat: 48.661484, lng: 19.047439 },
    { lat: 48.6618329, lng: 19.047091 },
    { lat: 48.6622968, lng: 19.0457091 },
    { lat: 48.6627764, lng: 19.0437844 },
    { lat: 48.6628471, lng: 19.0432631 },
    { lat: 48.6627505, lng: 19.0429578 },
    { lat: 48.6627239, lng: 19.0423799 },
    { lat: 48.6625817, lng: 19.0419939 },
    { lat: 48.6626264, lng: 19.0404395 },
    { lat: 48.6629879, lng: 19.0391502 },
    { lat: 48.662908, lng: 19.0376486 },
    { lat: 48.662761, lng: 19.0367685 },
    { lat: 48.6632965, lng: 19.0353526 },
    { lat: 48.6634824, lng: 19.0349914 },
    { lat: 48.6637804, lng: 19.0347294 },
    { lat: 48.6643219, lng: 19.0339537 },
    { lat: 48.6646782, lng: 19.0327651 },
    { lat: 48.6647929, lng: 19.031819 },
    { lat: 48.6652154, lng: 19.0301766 },
    { lat: 48.6655811, lng: 19.0293793 },
    { lat: 48.66662, lng: 19.0279536 },
    { lat: 48.6667245, lng: 19.0275899 },
    { lat: 48.6667864, lng: 19.0270732 },
    { lat: 48.6668763, lng: 19.0268171 },
    { lat: 48.6669389, lng: 19.0260698 },
    { lat: 48.6670966, lng: 19.0257967 },
    { lat: 48.667108, lng: 19.0255525 },
  ],
  SliačExt: [
    { lat: 48.624067, lng: 19.22379 },
    { lat: 48.625087, lng: 19.223594 },
    { lat: 48.625315, lng: 19.223561 },
    { lat: 48.625471, lng: 19.223432 },
    { lat: 48.6263, lng: 19.223325 },
    { lat: 48.626468, lng: 19.223244 },
    { lat: 48.626721, lng: 19.22312 },
    { lat: 48.626795, lng: 19.223084 },
    { lat: 48.627454, lng: 19.222371 },
    { lat: 48.62806, lng: 19.221982 },
    { lat: 48.628639, lng: 19.222249 },
    { lat: 48.629056, lng: 19.222786 },
    { lat: 48.6297219, lng: 19.2216654 },
    { lat: 48.6299546, lng: 19.221631 },
    { lat: 48.6310017, lng: 19.2211662 },
    { lat: 48.6310931, lng: 19.2207965 },
    { lat: 48.6313419, lng: 19.2204201 },
    { lat: 48.6320088, lng: 19.2198537 },
    { lat: 48.6330024, lng: 19.2198907 },
    { lat: 48.6334746, lng: 19.2202112 },
    { lat: 48.6352379, lng: 19.2199887 },
    { lat: 48.6353374, lng: 19.2202948 },
    { lat: 48.6357524, lng: 19.2202749 },
    { lat: 48.6366275, lng: 19.2199584 },
    { lat: 48.6373496, lng: 19.2192799 },
    { lat: 48.6378909, lng: 19.2186123 },
    { lat: 48.6382653, lng: 19.2177111 },
    { lat: 48.6385988, lng: 19.2174528 },
    { lat: 48.6384709, lng: 19.2171409 },
    { lat: 48.6385006, lng: 19.216639 },
    { lat: 48.6383295, lng: 19.2161138 },
    { lat: 48.6384474, lng: 19.2155219 },
    { lat: 48.6381052, lng: 19.2147248 },
    { lat: 48.637998, lng: 19.2138917 },
    { lat: 48.6383752, lng: 19.2130465 },
    { lat: 48.6382677, lng: 19.211883 },
    { lat: 48.6382774, lng: 19.2109973 },
    { lat: 48.6383485, lng: 19.2098871 },
    { lat: 48.6384971, lng: 19.2093074 },
    { lat: 48.6384334, lng: 19.2086438 },
    { lat: 48.6386813, lng: 19.2081818 },
    { lat: 48.6384079, lng: 19.20801 },
    { lat: 48.6384096, lng: 19.2079773 },
    { lat: 48.6384377, lng: 19.2074404 },
    { lat: 48.6382925, lng: 19.20717 },
    { lat: 48.6381998, lng: 19.2068139 },
    { lat: 48.6379518, lng: 19.2051984 },
    { lat: 48.6378581, lng: 19.2049618 },
    { lat: 48.637621, lng: 19.2046336 },
    { lat: 48.6376244, lng: 19.2037311 },
    { lat: 48.6374414, lng: 19.2035622 },
    { lat: 48.6373302, lng: 19.203158 },
    { lat: 48.6372867, lng: 19.2025662 },
    { lat: 48.637403, lng: 19.2022924 },
    { lat: 48.6374131, lng: 19.2016928 },
    { lat: 48.6380339, lng: 19.2002068 },
    { lat: 48.6381152, lng: 19.1999083 },
    { lat: 48.6378798, lng: 19.1986008 },
    { lat: 48.6378837, lng: 19.19794 },
    { lat: 48.6379892, lng: 19.1975611 },
    { lat: 48.6381747, lng: 19.1974628 },
    { lat: 48.6384035, lng: 19.1969768 },
    { lat: 48.6385559, lng: 19.1967696 },
    { lat: 48.6385368, lng: 19.1964039 },
    { lat: 48.638267, lng: 19.1962332 },
    { lat: 48.6381359, lng: 19.1954445 },
    { lat: 48.6378758, lng: 19.194875 },
    { lat: 48.6380246, lng: 19.1947102 },
    { lat: 48.6379809, lng: 19.1946716 },
    { lat: 48.6382451, lng: 19.1945199 },
    { lat: 48.6376145, lng: 19.1940954 },
    { lat: 48.637905, lng: 19.193187 },
    { lat: 48.6379424, lng: 19.1931925 },
    { lat: 48.6380778, lng: 19.1929194 },
    { lat: 48.6383057, lng: 19.1927589 },
    { lat: 48.6384051, lng: 19.1926024 },
    { lat: 48.6383474, lng: 19.1923469 },
    { lat: 48.6380705, lng: 19.1920074 },
    { lat: 48.6380711, lng: 19.1918602 },
    { lat: 48.6383041, lng: 19.1918075 },
    { lat: 48.6383502, lng: 19.1914142 },
    { lat: 48.638493, lng: 19.190966 },
    { lat: 48.6386647, lng: 19.1906293 },
    { lat: 48.6387986, lng: 19.1905071 },
    { lat: 48.6387117, lng: 19.1901839 },
    { lat: 48.6385593, lng: 19.1899022 },
    { lat: 48.638657, lng: 19.1896889 },
    { lat: 48.6384889, lng: 19.1893373 },
    { lat: 48.6385265, lng: 19.1887272 },
    { lat: 48.6384103, lng: 19.1886107 },
    { lat: 48.6383137, lng: 19.1878577 },
    { lat: 48.6383307, lng: 19.1876028 },
    { lat: 48.6382184, lng: 19.1875226 },
    { lat: 48.6381234, lng: 19.1873108 },
    { lat: 48.6379705, lng: 19.1872462 },
    { lat: 48.6375016, lng: 19.1874771 },
    { lat: 48.6370358, lng: 19.1875397 },
    { lat: 48.6368926, lng: 19.1869223 },
    { lat: 48.6365736, lng: 19.1860458 },
    { lat: 48.6364728, lng: 19.1854163 },
    { lat: 48.6361021, lng: 19.186175 },
    { lat: 48.6354157, lng: 19.185671 },
    { lat: 48.6351387, lng: 19.1860002 },
    { lat: 48.6347881, lng: 19.1869438 },
    { lat: 48.6344353, lng: 19.1876613 },
    { lat: 48.6340677, lng: 19.1879535 },
    { lat: 48.6335667, lng: 19.188798 },
    { lat: 48.633432, lng: 19.188715 },
    { lat: 48.6327396, lng: 19.1895163 },
    { lat: 48.6317224, lng: 19.1892334 },
    { lat: 48.6283581, lng: 19.1867814 },
    { lat: 48.6274628, lng: 19.1865191 },
    { lat: 48.6269697, lng: 19.1863057 },
    { lat: 48.6255333, lng: 19.1860212 },
    { lat: 48.6252084, lng: 19.1860617 },
    { lat: 48.6249779, lng: 19.1862609 },
    { lat: 48.6245082, lng: 19.1863364 },
    { lat: 48.6233185, lng: 19.1858907 },
    { lat: 48.6229715, lng: 19.1858713 },
    { lat: 48.6224849, lng: 19.1864778 },
    { lat: 48.6219499, lng: 19.1869707 },
    { lat: 48.622011, lng: 19.1877303 },
    { lat: 48.6219756, lng: 19.1877221 },
    { lat: 48.6210165, lng: 19.1904217 },
    { lat: 48.6189398, lng: 19.1914532 },
    { lat: 48.6180625, lng: 19.1917465 },
    { lat: 48.6173208, lng: 19.1915839 },
    { lat: 48.6165504, lng: 19.1918282 },
    { lat: 48.6153575, lng: 19.192536 },
    { lat: 48.6147048, lng: 19.1932075 },
    { lat: 48.6147142, lng: 19.1946162 },
    { lat: 48.6150143, lng: 19.1971346 },
    { lat: 48.6148101, lng: 19.197339 },
    { lat: 48.6146501, lng: 19.198009 },
    { lat: 48.614403, lng: 19.1999866 },
    { lat: 48.6142321, lng: 19.2006208 },
    { lat: 48.6131652, lng: 19.2034699 },
    { lat: 48.6135264, lng: 19.2041241 },
    { lat: 48.614153, lng: 19.2052546 },
    { lat: 48.6141642, lng: 19.2056431 },
    { lat: 48.6155719, lng: 19.2088259 },
    { lat: 48.6159457, lng: 19.2101834 },
    { lat: 48.6164956, lng: 19.2111288 },
    { lat: 48.6164124, lng: 19.2113849 },
    { lat: 48.6181091, lng: 19.2131725 },
    { lat: 48.6185436, lng: 19.2139451 },
    { lat: 48.6184087, lng: 19.214347 },
    { lat: 48.6183629, lng: 19.2157864 },
    { lat: 48.6188136, lng: 19.2164311 },
    { lat: 48.6190327, lng: 19.2171069 },
    { lat: 48.6217123, lng: 19.2201201 },
    { lat: 48.621818, lng: 19.2204195 },
    { lat: 48.6230942, lng: 19.2222291 },
    { lat: 48.6233101, lng: 19.2226118 },
    { lat: 48.6240938, lng: 19.2234521 },
    { lat: 48.624067, lng: 19.22379 },
  ],
  Zvolen: [
    { lat: 48.5972197, lng: 19.1624427 },
    { lat: 48.5972256, lng: 19.1621062 },
    { lat: 48.5970504, lng: 19.161316 },
    { lat: 48.5970171, lng: 19.1608036 },
    { lat: 48.5970427, lng: 19.159352 },
    { lat: 48.5972869, lng: 19.1580832 },
    { lat: 48.5974572, lng: 19.1573779 },
    { lat: 48.5976644, lng: 19.156756 },
    { lat: 48.59778, lng: 19.1560155 },
    { lat: 48.5979628, lng: 19.1554006 },
    { lat: 48.5983569, lng: 19.1547731 },
    { lat: 48.598972, lng: 19.1533212 },
    { lat: 48.5994395, lng: 19.152881 },
    { lat: 48.5993666, lng: 19.1524992 },
    { lat: 48.5990092, lng: 19.1513112 },
    { lat: 48.5984201, lng: 19.1499267 },
    { lat: 48.5979928, lng: 19.1480991 },
    { lat: 48.5977852, lng: 19.1475693 },
    { lat: 48.5969574, lng: 19.1462604 },
    { lat: 48.5970222, lng: 19.1460413 },
    { lat: 48.5960337, lng: 19.1449007 },
    { lat: 48.596817, lng: 19.1437141 },
    { lat: 48.5973841, lng: 19.1445341 },
    { lat: 48.5976421, lng: 19.1436878 },
    { lat: 48.5982407, lng: 19.1422592 },
    { lat: 48.5988715, lng: 19.1407536 },
    { lat: 48.5990402, lng: 19.1402239 },
    { lat: 48.5994484, lng: 19.1377377 },
    { lat: 48.5995219, lng: 19.1360331 },
    { lat: 48.599617, lng: 19.1350839 },
    { lat: 48.5996206, lng: 19.1340645 },
    { lat: 48.599533, lng: 19.1332493 },
    { lat: 48.5996031, lng: 19.132592 },
    { lat: 48.5995719, lng: 19.1320947 },
    { lat: 48.5996081, lng: 19.1317584 },
    { lat: 48.5995782, lng: 19.131631 },
    { lat: 48.5996727, lng: 19.1312867 },
    { lat: 48.5992069, lng: 19.1312828 },
    { lat: 48.598832, lng: 19.1309962 },
    { lat: 48.5987449, lng: 19.1301018 },
    { lat: 48.5985292, lng: 19.1295231 },
    { lat: 48.597887, lng: 19.128419 },
    { lat: 48.5974433, lng: 19.1280408 },
    { lat: 48.5973119, lng: 19.1280054 },
    { lat: 48.597208, lng: 19.1274537 },
    { lat: 48.5971081, lng: 19.1264139 },
    { lat: 48.5971219, lng: 19.1256 },
    { lat: 48.5968937, lng: 19.1245462 },
    { lat: 48.5969483, lng: 19.1244849 },
    { lat: 48.5971347, lng: 19.1238378 },
    { lat: 48.5977511, lng: 19.1210078 },
    { lat: 48.5979805, lng: 19.1204976 },
    { lat: 48.5982718, lng: 19.1202119 },
    { lat: 48.5983245, lng: 19.1200236 },
    { lat: 48.5981705, lng: 19.1189839 },
    { lat: 48.5981204, lng: 19.1184984 },
    { lat: 48.5981337, lng: 19.1181326 },
    { lat: 48.5979588, lng: 19.1169453 },
    { lat: 48.5979378, lng: 19.1163411 },
    { lat: 48.597689, lng: 19.1161889 },
    { lat: 48.5975994, lng: 19.1160637 },
    { lat: 48.5967077, lng: 19.115613 },
    { lat: 48.5963943, lng: 19.1148858 },
    { lat: 48.5955209, lng: 19.1140292 },
    { lat: 48.5956046, lng: 19.1136262 },
    { lat: 48.5955989, lng: 19.1133067 },
    { lat: 48.5950323, lng: 19.1130191 },
    { lat: 48.5940891, lng: 19.1118438 },
    { lat: 48.593956, lng: 19.1116611 },
    { lat: 48.593962, lng: 19.1116498 },
    { lat: 48.5938866, lng: 19.1115166 },
    { lat: 48.593426, lng: 19.1117502 },
    { lat: 48.5931291, lng: 19.1120843 },
    { lat: 48.5926282, lng: 19.1123727 },
    { lat: 48.5924239, lng: 19.1120492 },
    { lat: 48.5919443, lng: 19.1107687 },
    { lat: 48.5908495, lng: 19.1070886 },
    { lat: 48.5907047, lng: 19.1070306 },
    { lat: 48.590267, lng: 19.1071178 },
    { lat: 48.5894997, lng: 19.1074687 },
    { lat: 48.5893966, lng: 19.1075725 },
    { lat: 48.5886424, lng: 19.1071421 },
    { lat: 48.5889986, lng: 19.1058897 },
    { lat: 48.5890171, lng: 19.1055589 },
    { lat: 48.5889498, lng: 19.1048433 },
    { lat: 48.5888923, lng: 19.1047512 },
    { lat: 48.5888085, lng: 19.1025254 },
    { lat: 48.5891389, lng: 19.1020052 },
    { lat: 48.5893698, lng: 19.1000505 },
    { lat: 48.5894254, lng: 19.098587 },
    { lat: 48.5895134, lng: 19.0979998 },
    { lat: 48.5897936, lng: 19.0968332 },
    { lat: 48.5901377, lng: 19.09566 },
    { lat: 48.5900241, lng: 19.0952812 },
    { lat: 48.5899982, lng: 19.0947371 },
    { lat: 48.5901652, lng: 19.0944013 },
    { lat: 48.5903339, lng: 19.0925201 },
    { lat: 48.590404, lng: 19.0920438 },
    { lat: 48.5905294, lng: 19.0917355 },
    { lat: 48.5904588, lng: 19.0910637 },
    { lat: 48.5907439, lng: 19.0897308 },
    { lat: 48.5907678, lng: 19.0893713 },
    { lat: 48.5907296, lng: 19.088762 },
    { lat: 48.5908828, lng: 19.0871357 },
    { lat: 48.5908352, lng: 19.0868345 },
    { lat: 48.5906495, lng: 19.0870895 },
    { lat: 48.5905497, lng: 19.0869399 },
    { lat: 48.5904722, lng: 19.0869497 },
    { lat: 48.590175, lng: 19.0870261 },
    { lat: 48.5902558, lng: 19.087267 },
    { lat: 48.5901158, lng: 19.0873534 },
    { lat: 48.5899505, lng: 19.0873361 },
    { lat: 48.5897612, lng: 19.0871834 },
    { lat: 48.5895998, lng: 19.0872441 },
    { lat: 48.5895611, lng: 19.0873293 },
    { lat: 48.5894389, lng: 19.0873421 },
    { lat: 48.5893633, lng: 19.0872723 },
    { lat: 48.5891909, lng: 19.0873863 },
    { lat: 48.5890826, lng: 19.0873839 },
    { lat: 48.5888073, lng: 19.0871139 },
    { lat: 48.5887681, lng: 19.086957 },
    { lat: 48.588669, lng: 19.0869163 },
    { lat: 48.5883828, lng: 19.0870338 },
    { lat: 48.5881961, lng: 19.0869275 },
    { lat: 48.5878125, lng: 19.0870098 },
    { lat: 48.5875979, lng: 19.0867563 },
    { lat: 48.587498, lng: 19.0869457 },
    { lat: 48.5874899, lng: 19.0871863 },
    { lat: 48.5874558, lng: 19.0872268 },
    { lat: 48.5872669, lng: 19.0873764 },
    { lat: 48.5870261, lng: 19.0873613 },
    { lat: 48.5869704, lng: 19.0875325 },
    { lat: 48.5866856, lng: 19.0878329 },
    { lat: 48.586282, lng: 19.087858 },
    { lat: 48.5860817, lng: 19.0880677 },
    { lat: 48.5859779, lng: 19.0880179 },
    { lat: 48.5859021, lng: 19.0883562 },
    { lat: 48.5859715, lng: 19.0884583 },
    { lat: 48.5859547, lng: 19.0885485 },
    { lat: 48.5858566, lng: 19.0885515 },
    { lat: 48.5857609, lng: 19.0884366 },
    { lat: 48.5855354, lng: 19.0883258 },
    { lat: 48.5853255, lng: 19.0888388 },
    { lat: 48.5851991, lng: 19.0888642 },
    { lat: 48.585047, lng: 19.0891335 },
    { lat: 48.5853002, lng: 19.089759 },
    { lat: 48.5848459, lng: 19.0904838 },
    { lat: 48.5846034, lng: 19.0906373 },
    { lat: 48.58451, lng: 19.0908665 },
    { lat: 48.5836147, lng: 19.0909768 },
    { lat: 48.5835801, lng: 19.0909669 },
    { lat: 48.5832693, lng: 19.0908774 },
    { lat: 48.583137, lng: 19.0910761 },
    { lat: 48.5831141, lng: 19.0910936 },
    { lat: 48.5826061, lng: 19.0914823 },
    { lat: 48.5825564, lng: 19.0908026 },
    { lat: 48.5816502, lng: 19.0909397 },
    { lat: 48.581525, lng: 19.0911437 },
    { lat: 48.5812611, lng: 19.0912758 },
    { lat: 48.5809447, lng: 19.091308 },
    { lat: 48.5800707, lng: 19.090827 },
    { lat: 48.579801, lng: 19.0918178 },
    { lat: 48.5796066, lng: 19.0916486 },
    { lat: 48.5793646, lng: 19.0912292 },
    { lat: 48.579172, lng: 19.0910951 },
    { lat: 48.5779873, lng: 19.0908732 },
    { lat: 48.5774878, lng: 19.0909152 },
    { lat: 48.5763393, lng: 19.0904247 },
    { lat: 48.5752074, lng: 19.0897448 },
    { lat: 48.5742915, lng: 19.0893284 },
    { lat: 48.5736923, lng: 19.0886394 },
    { lat: 48.5733374, lng: 19.0871335 },
    { lat: 48.5730258, lng: 19.0863088 },
    { lat: 48.5729271, lng: 19.0861825 },
    { lat: 48.5726631, lng: 19.0863168 },
    { lat: 48.5719015, lng: 19.0863714 },
    { lat: 48.5714891, lng: 19.0862622 },
    { lat: 48.5709859, lng: 19.0863438 },
    { lat: 48.5697102, lng: 19.0861968 },
    { lat: 48.5694911, lng: 19.0862316 },
    { lat: 48.5689553, lng: 19.0865219 },
    { lat: 48.5685736, lng: 19.0865317 },
    { lat: 48.5679962, lng: 19.086999 },
    { lat: 48.5677497, lng: 19.0870778 },
    { lat: 48.5672235, lng: 19.0874457 },
    { lat: 48.5664801, lng: 19.0875509 },
    { lat: 48.5662113, lng: 19.0876754 },
    { lat: 48.5655932, lng: 19.0875122 },
    { lat: 48.5652208, lng: 19.0871882 },
    { lat: 48.5646387, lng: 19.0870224 },
    { lat: 48.5643383, lng: 19.0870347 },
    { lat: 48.5631074, lng: 19.0862459 },
    { lat: 48.5629736, lng: 19.0859842 },
    { lat: 48.5627357, lng: 19.0851954 },
    { lat: 48.5622651, lng: 19.0848198 },
    { lat: 48.5615095, lng: 19.0840246 },
    { lat: 48.5613578, lng: 19.0839478 },
    { lat: 48.5603272, lng: 19.0839411 },
    { lat: 48.5597195, lng: 19.0838303 },
    { lat: 48.5595682, lng: 19.0838706 },
    { lat: 48.5594886, lng: 19.0840127 },
    { lat: 48.5593255, lng: 19.0845415 },
    { lat: 48.5592689, lng: 19.0849405 },
    { lat: 48.5589839, lng: 19.0856906 },
    { lat: 48.5586505, lng: 19.0863085 },
    { lat: 48.5578352, lng: 19.0856265 },
    { lat: 48.5566492, lng: 19.0851957 },
    { lat: 48.5564512, lng: 19.0846644 },
    { lat: 48.5562019, lng: 19.0843467 },
    { lat: 48.5561754, lng: 19.0838818 },
    { lat: 48.5560194, lng: 19.0837614 },
    { lat: 48.5559864, lng: 19.0830064 },
    { lat: 48.5560563, lng: 19.0822216 },
    { lat: 48.5563439, lng: 19.0812064 },
    { lat: 48.5560923, lng: 19.0811787 },
    { lat: 48.5558405, lng: 19.081151 },
    { lat: 48.5548104, lng: 19.0815842 },
    { lat: 48.5546167, lng: 19.0813862 },
    { lat: 48.5544812, lng: 19.080929 },
    { lat: 48.5532361, lng: 19.0804019 },
    { lat: 48.5521268, lng: 19.0805377 },
    { lat: 48.5518873, lng: 19.0808879 },
    { lat: 48.5515813, lng: 19.08115 },
    { lat: 48.550968, lng: 19.0818732 },
    { lat: 48.5506188, lng: 19.0824412 },
    { lat: 48.5505982, lng: 19.0824119 },
    { lat: 48.5498157, lng: 19.0832699 },
    { lat: 48.5489547, lng: 19.0839304 },
    { lat: 48.5482431, lng: 19.0843078 },
    { lat: 48.5476535, lng: 19.0848951 },
    { lat: 48.5472289, lng: 19.0853567 },
    { lat: 48.5464068, lng: 19.086592 },
    { lat: 48.5458536, lng: 19.0868499 },
    { lat: 48.5448993, lng: 19.0874469 },
    { lat: 48.5439912, lng: 19.0877273 },
    { lat: 48.5437096, lng: 19.0878691 },
    { lat: 48.5424769, lng: 19.0886732 },
    { lat: 48.5422551, lng: 19.0888823 },
    { lat: 48.5415706, lng: 19.0890605 },
    { lat: 48.5407574, lng: 19.0889821 },
    { lat: 48.5405145, lng: 19.0890445 },
    { lat: 48.5397417, lng: 19.0894592 },
    { lat: 48.5396931, lng: 19.0896835 },
    { lat: 48.5388137, lng: 19.0904939 },
    { lat: 48.5382369, lng: 19.0909074 },
    { lat: 48.5376009, lng: 19.0907282 },
    { lat: 48.5365054, lng: 19.0908216 },
    { lat: 48.5358586, lng: 19.0901488 },
    { lat: 48.5356258, lng: 19.0900293 },
    { lat: 48.5352177, lng: 19.0899602 },
    { lat: 48.5348043, lng: 19.0899003 },
    { lat: 48.5339036, lng: 19.0913023 },
    { lat: 48.532813, lng: 19.0919913 },
    { lat: 48.5309496, lng: 19.0933755 },
    { lat: 48.5306001, lng: 19.0934385 },
    { lat: 48.5302323, lng: 19.0935964 },
    { lat: 48.5301524, lng: 19.0937982 },
    { lat: 48.529891, lng: 19.0939153 },
    { lat: 48.5292841, lng: 19.0948813 },
    { lat: 48.5288951, lng: 19.0953983 },
    { lat: 48.5288525, lng: 19.0955635 },
    { lat: 48.5284303, lng: 19.0960505 },
    { lat: 48.5282979, lng: 19.0979609 },
    { lat: 48.5280673, lng: 19.0997582 },
    { lat: 48.5276118, lng: 19.1009079 },
    { lat: 48.5264797, lng: 19.1040706 },
    { lat: 48.5253004, lng: 19.107108 },
    { lat: 48.5251843, lng: 19.1075375 },
    { lat: 48.5251652, lng: 19.1076528 },
    { lat: 48.5243401, lng: 19.1101934 },
    { lat: 48.5260527, lng: 19.1113745 },
    { lat: 48.5264443, lng: 19.1114723 },
    { lat: 48.5269829, lng: 19.1117608 },
    { lat: 48.5286607, lng: 19.1129215 },
    { lat: 48.5289374, lng: 19.1131606 },
    { lat: 48.5290703, lng: 19.1134764 },
    { lat: 48.5297451, lng: 19.1139652 },
    { lat: 48.5302445, lng: 19.1145058 },
    { lat: 48.5312616, lng: 19.1161916 },
    { lat: 48.531622, lng: 19.1165935 },
    { lat: 48.5323803, lng: 19.1171537 },
    { lat: 48.5331615, lng: 19.1172916 },
    { lat: 48.5330336, lng: 19.1180693 },
    { lat: 48.5329404, lng: 19.1190317 },
    { lat: 48.5325731, lng: 19.1202849 },
    { lat: 48.5327218, lng: 19.1200544 },
    { lat: 48.5328032, lng: 19.1199463 },
    { lat: 48.532537, lng: 19.1204664 },
    { lat: 48.5325382, lng: 19.1209006 },
    { lat: 48.5323926, lng: 19.1218745 },
    { lat: 48.5321429, lng: 19.1226549 },
    { lat: 48.5318251, lng: 19.1242059 },
    { lat: 48.5318184, lng: 19.1243834 },
    { lat: 48.5319343, lng: 19.1249068 },
    { lat: 48.5316725, lng: 19.1256298 },
    { lat: 48.5316639, lng: 19.1258606 },
    { lat: 48.5317247, lng: 19.1262243 },
    { lat: 48.5316656, lng: 19.1267144 },
    { lat: 48.5317702, lng: 19.1272322 },
    { lat: 48.5317118, lng: 19.1276461 },
    { lat: 48.5317195, lng: 19.1279386 },
    { lat: 48.5318395, lng: 19.128448 },
    { lat: 48.5318643, lng: 19.1287685 },
    { lat: 48.5320067, lng: 19.1294095 },
    { lat: 48.5322175, lng: 19.129888 },
    { lat: 48.5324552, lng: 19.1313378 },
    { lat: 48.5327307, lng: 19.1317358 },
    { lat: 48.5328104, lng: 19.1322522 },
    { lat: 48.5328119, lng: 19.1326679 },
    { lat: 48.5330362, lng: 19.1334871 },
    { lat: 48.5330766, lng: 19.1339876 },
    { lat: 48.5330529, lng: 19.1341439 },
    { lat: 48.5329113, lng: 19.134517 },
    { lat: 48.5328774, lng: 19.134901 },
    { lat: 48.5327547, lng: 19.1351823 },
    { lat: 48.5326472, lng: 19.1356493 },
    { lat: 48.5322766, lng: 19.1361116 },
    { lat: 48.5321152, lng: 19.136426 },
    { lat: 48.5318914, lng: 19.1365284 },
    { lat: 48.5316734, lng: 19.1364738 },
    { lat: 48.530669, lng: 19.1364984 },
    { lat: 48.5292588, lng: 19.1367043 },
    { lat: 48.5287918, lng: 19.1365615 },
    { lat: 48.5285021, lng: 19.1366331 },
    { lat: 48.5281653, lng: 19.136614 },
    { lat: 48.5278227, lng: 19.1364785 },
    { lat: 48.5274727, lng: 19.1366714 },
    { lat: 48.5265805, lng: 19.1386679 },
    { lat: 48.5263589, lng: 19.138973 },
    { lat: 48.5260518, lng: 19.1392112 },
    { lat: 48.5255898, lng: 19.1394 },
    { lat: 48.5252394, lng: 19.1396306 },
    { lat: 48.5250485, lng: 19.1398736 },
    { lat: 48.5247584, lng: 19.1401177 },
    { lat: 48.5243851, lng: 19.1402261 },
    { lat: 48.5242128, lng: 19.1403432 },
    { lat: 48.5236823, lng: 19.1409468 },
    { lat: 48.522428, lng: 19.1416404 },
    { lat: 48.5221268, lng: 19.1420703 },
    { lat: 48.5219321, lng: 19.1422373 },
    { lat: 48.5217061, lng: 19.1423618 },
    { lat: 48.5213518, lng: 19.1424207 },
    { lat: 48.5210808, lng: 19.142572 },
    { lat: 48.5206599, lng: 19.1429993 },
    { lat: 48.520701, lng: 19.1432145 },
    { lat: 48.5199589, lng: 19.1443918 },
    { lat: 48.5196722, lng: 19.1445651 },
    { lat: 48.5191503, lng: 19.1450215 },
    { lat: 48.5187976, lng: 19.1454369 },
    { lat: 48.5186947, lng: 19.1455025 },
    { lat: 48.5179494, lng: 19.1459746 },
    { lat: 48.5179298, lng: 19.1461034 },
    { lat: 48.5178249, lng: 19.1461945 },
    { lat: 48.5176139, lng: 19.1462355 },
    { lat: 48.5173747, lng: 19.1466638 },
    { lat: 48.5171597, lng: 19.1465822 },
    { lat: 48.5169836, lng: 19.1468046 },
    { lat: 48.5159524, lng: 19.147377 },
    { lat: 48.515938, lng: 19.1475689 },
    { lat: 48.5153947, lng: 19.1481368 },
    { lat: 48.5149199, lng: 19.148181 },
    { lat: 48.5144753, lng: 19.1483097 },
    { lat: 48.5142384, lng: 19.148167 },
    { lat: 48.5138652, lng: 19.1484396 },
    { lat: 48.513688, lng: 19.1488032 },
    { lat: 48.5135387, lng: 19.1489504 },
    { lat: 48.5134522, lng: 19.1489125 },
    { lat: 48.5133764, lng: 19.1490415 },
    { lat: 48.5133916, lng: 19.1492336 },
    { lat: 48.5132355, lng: 19.1492678 },
    { lat: 48.5131559, lng: 19.1493929 },
    { lat: 48.5129495, lng: 19.1500976 },
    { lat: 48.5128819, lng: 19.1501443 },
    { lat: 48.5127191, lng: 19.1505266 },
    { lat: 48.5126447, lng: 19.1509132 },
    { lat: 48.5125731, lng: 19.1510137 },
    { lat: 48.5125522, lng: 19.1512315 },
    { lat: 48.5126564, lng: 19.1515142 },
    { lat: 48.5126597, lng: 19.1518107 },
    { lat: 48.5124676, lng: 19.1520722 },
    { lat: 48.5124302, lng: 19.1522762 },
    { lat: 48.5122857, lng: 19.1525455 },
    { lat: 48.512294, lng: 19.1526424 },
    { lat: 48.5120394, lng: 19.153308 },
    { lat: 48.5120474, lng: 19.1534699 },
    { lat: 48.5119742, lng: 19.1535848 },
    { lat: 48.5119262, lng: 19.1538237 },
    { lat: 48.5115697, lng: 19.1538657 },
    { lat: 48.5114355, lng: 19.1540898 },
    { lat: 48.5114519, lng: 19.1541495 },
    { lat: 48.5113384, lng: 19.1542489 },
    { lat: 48.5110234, lng: 19.1547865 },
    { lat: 48.5105291, lng: 19.1549808 },
    { lat: 48.5105604, lng: 19.1551601 },
    { lat: 48.5105246, lng: 19.1552096 },
    { lat: 48.5103872, lng: 19.1552352 },
    { lat: 48.5103759, lng: 19.1553752 },
    { lat: 48.5102546, lng: 19.1555217 },
    { lat: 48.5098012, lng: 19.1557637 },
    { lat: 48.509505, lng: 19.1562526 },
    { lat: 48.5095131, lng: 19.1563448 },
    { lat: 48.5093763, lng: 19.1566125 },
    { lat: 48.5092948, lng: 19.1569707 },
    { lat: 48.5091807, lng: 19.1571051 },
    { lat: 48.5090234, lng: 19.1574565 },
    { lat: 48.5085514, lng: 19.1589061 },
    { lat: 48.5084595, lng: 19.1595758 },
    { lat: 48.5083793, lng: 19.1595983 },
    { lat: 48.5082423, lng: 19.1611156 },
    { lat: 48.5080904, lng: 19.1614188 },
    { lat: 48.5079892, lng: 19.162498 },
    { lat: 48.5079231, lng: 19.1620738 },
    { lat: 48.5077892, lng: 19.1620116 },
    { lat: 48.5076188, lng: 19.1621871 },
    { lat: 48.5073881, lng: 19.1630892 },
    { lat: 48.507335, lng: 19.1630393 },
    { lat: 48.5074383, lng: 19.1624589 },
    { lat: 48.5072339, lng: 19.1623147 },
    { lat: 48.5070939, lng: 19.1630452 },
    { lat: 48.5069846, lng: 19.1631143 },
    { lat: 48.5069227, lng: 19.1632762 },
    { lat: 48.506909, lng: 19.1635451 },
    { lat: 48.5069916, lng: 19.1637353 },
    { lat: 48.5065947, lng: 19.163983 },
    { lat: 48.5064021, lng: 19.1643081 },
    { lat: 48.5062, lng: 19.1643719 },
    { lat: 48.5061201, lng: 19.164534 },
    { lat: 48.5058671, lng: 19.164773 },
    { lat: 48.5056868, lng: 19.1648003 },
    { lat: 48.5054712, lng: 19.164658 },
    { lat: 48.5052902, lng: 19.16414 },
    { lat: 48.5052355, lng: 19.1633039 },
    { lat: 48.5052714, lng: 19.1628666 },
    { lat: 48.5029171, lng: 19.1641166 },
    { lat: 48.5022123, lng: 19.1647263 },
    { lat: 48.5010231, lng: 19.1654409 },
    { lat: 48.5006724, lng: 19.1655768 },
    { lat: 48.4997784, lng: 19.1662353 },
    { lat: 48.4993127, lng: 19.1667124 },
    { lat: 48.4975207, lng: 19.1679107 },
    { lat: 48.4959722, lng: 19.1670282 },
    { lat: 48.4958435, lng: 19.1694267 },
    { lat: 48.4951392, lng: 19.1713083 },
    { lat: 48.4946371, lng: 19.1721024 },
    { lat: 48.4941551, lng: 19.1732099 },
    { lat: 48.4938334, lng: 19.1737039 },
    { lat: 48.493449, lng: 19.1746 },
    { lat: 48.4924739, lng: 19.1761132 },
    { lat: 48.4923033, lng: 19.1764741 },
    { lat: 48.4915605, lng: 19.1783046 },
    { lat: 48.4912379, lng: 19.178966 },
    { lat: 48.4904327, lng: 19.1812295 },
    { lat: 48.4900316, lng: 19.1821761 },
    { lat: 48.4895987, lng: 19.1827459 },
    { lat: 48.4890274, lng: 19.1837377 },
    { lat: 48.4885118, lng: 19.1848792 },
    { lat: 48.4880321, lng: 19.1855058 },
    { lat: 48.4874898, lng: 19.1860554 },
    { lat: 48.4874631, lng: 19.1862243 },
    { lat: 48.4874217, lng: 19.1863678 },
    { lat: 48.4869706, lng: 19.1868934 },
    { lat: 48.4853226, lng: 19.1885697 },
    { lat: 48.48476, lng: 19.1888206 },
    { lat: 48.4842497, lng: 19.1894494 },
    { lat: 48.4837418, lng: 19.1898735 },
    { lat: 48.4832787, lng: 19.1912575 },
    { lat: 48.4824273, lng: 19.192031 },
    { lat: 48.4817955, lng: 19.1924799 },
    { lat: 48.4808437, lng: 19.1941255 },
    { lat: 48.4805929, lng: 19.1952626 },
    { lat: 48.4797304, lng: 19.19558 },
    { lat: 48.479477, lng: 19.1961837 },
    { lat: 48.4791948, lng: 19.196797 },
    { lat: 48.4790645, lng: 19.1974986 },
    { lat: 48.4791734, lng: 19.1978784 },
    { lat: 48.4791152, lng: 19.1982085 },
    { lat: 48.4791027, lng: 19.1987823 },
    { lat: 48.4791968, lng: 19.1997344 },
    { lat: 48.479372, lng: 19.2003449 },
    { lat: 48.4799455, lng: 19.2016759 },
    { lat: 48.4803406, lng: 19.2028603 },
    { lat: 48.4806788, lng: 19.2044271 },
    { lat: 48.4807208, lng: 19.205467 },
    { lat: 48.4810342, lng: 19.2058331 },
    { lat: 48.4812024, lng: 19.206437 },
    { lat: 48.4811164, lng: 19.2080006 },
    { lat: 48.4808995, lng: 19.2083568 },
    { lat: 48.4807202, lng: 19.2089785 },
    { lat: 48.4807139, lng: 19.2093818 },
    { lat: 48.4812501, lng: 19.2106175 },
    { lat: 48.481402, lng: 19.2112321 },
    { lat: 48.4815826, lng: 19.2115394 },
    { lat: 48.4827423, lng: 19.2127336 },
    { lat: 48.4831897, lng: 19.212937 },
    { lat: 48.4824493, lng: 19.2151334 },
    { lat: 48.4823186, lng: 19.2153412 },
    { lat: 48.4817644, lng: 19.2158814 },
    { lat: 48.4816366, lng: 19.2166793 },
    { lat: 48.4815863, lng: 19.2174719 },
    { lat: 48.4816899, lng: 19.2179287 },
    { lat: 48.4816648, lng: 19.2201444 },
    { lat: 48.4819745, lng: 19.2215236 },
    { lat: 48.4822056, lng: 19.2233012 },
    { lat: 48.4823151, lng: 19.2235126 },
    { lat: 48.4824788, lng: 19.2241423 },
    { lat: 48.4828354, lng: 19.225026 },
    { lat: 48.4842012, lng: 19.2257089 },
    { lat: 48.4845887, lng: 19.2260072 },
    { lat: 48.4847564, lng: 19.2261386 },
    { lat: 48.4853692, lng: 19.2268961 },
    { lat: 48.4856117, lng: 19.2275658 },
    { lat: 48.4862503, lng: 19.2276474 },
    { lat: 48.4870547, lng: 19.227499 },
    { lat: 48.4873437, lng: 19.2281348 },
    { lat: 48.4880231, lng: 19.2293042 },
    { lat: 48.4881765, lng: 19.2293801 },
    { lat: 48.4892043, lng: 19.2308047 },
    { lat: 48.489415, lng: 19.2315469 },
    { lat: 48.4897908, lng: 19.232552 },
    { lat: 48.4900855, lng: 19.2336698 },
    { lat: 48.4901669, lng: 19.2353907 },
    { lat: 48.4902502, lng: 19.2361743 },
    { lat: 48.4904087, lng: 19.236766 },
    { lat: 48.490474, lng: 19.237249 },
    { lat: 48.491017, lng: 19.237554 },
    { lat: 48.491349, lng: 19.237828 },
    { lat: 48.491629, lng: 19.238023 },
    { lat: 48.491947, lng: 19.238113 },
    { lat: 48.492208, lng: 19.238123 },
    { lat: 48.492353, lng: 19.238136 },
    { lat: 48.493273, lng: 19.238186 },
    { lat: 48.493624, lng: 19.238462 },
    { lat: 48.494273, lng: 19.239651 },
    { lat: 48.494505, lng: 19.240124 },
    { lat: 48.495228, lng: 19.241576 },
    { lat: 48.495346, lng: 19.241815 },
    { lat: 48.495378, lng: 19.24188 },
    { lat: 48.495589, lng: 19.241851 },
    { lat: 48.496049, lng: 19.241863 },
    { lat: 48.496592, lng: 19.242095 },
    { lat: 48.496841, lng: 19.242298 },
    { lat: 48.497115, lng: 19.242611 },
    { lat: 48.497236, lng: 19.242826 },
    { lat: 48.497386, lng: 19.243331 },
    { lat: 48.497601, lng: 19.243477 },
    { lat: 48.497885, lng: 19.243422 },
    { lat: 48.498365, lng: 19.243217 },
    { lat: 48.498861, lng: 19.242883 },
    { lat: 48.4993709, lng: 19.2427429 },
    { lat: 48.499566, lng: 19.242717 },
    { lat: 48.499866, lng: 19.242574 },
    { lat: 48.5003, lng: 19.24244 },
    { lat: 48.501092, lng: 19.241961 },
    { lat: 48.50204, lng: 19.241556 },
    { lat: 48.5027959, lng: 19.2412912 },
    { lat: 48.5034545, lng: 19.2394851 },
    { lat: 48.5042022, lng: 19.2371993 },
    { lat: 48.5045354, lng: 19.2365672 },
    { lat: 48.5051225, lng: 19.235981 },
    { lat: 48.5059649, lng: 19.2349245 },
    { lat: 48.5067022, lng: 19.2333978 },
    { lat: 48.5075541, lng: 19.2326047 },
    { lat: 48.5082514, lng: 19.2322469 },
    { lat: 48.5100837, lng: 19.2320288 },
    { lat: 48.5107269, lng: 19.2317865 },
    { lat: 48.5110062, lng: 19.2316181 },
    { lat: 48.5114177, lng: 19.2309488 },
    { lat: 48.5119425, lng: 19.229295 },
    { lat: 48.512524, lng: 19.2281163 },
    { lat: 48.5127609, lng: 19.227378 },
    { lat: 48.513227, lng: 19.2262785 },
    { lat: 48.5141323, lng: 19.223806 },
    { lat: 48.5158958, lng: 19.2225695 },
    { lat: 48.5168544, lng: 19.2210751 },
    { lat: 48.5174694, lng: 19.220258 },
    { lat: 48.5178418, lng: 19.2195974 },
    { lat: 48.518185, lng: 19.2182425 },
    { lat: 48.5185288, lng: 19.2176441 },
    { lat: 48.5191066, lng: 19.2157383 },
    { lat: 48.5201049, lng: 19.2154298 },
    { lat: 48.5209234, lng: 19.2150185 },
    { lat: 48.5213468, lng: 19.2141069 },
    { lat: 48.5218246, lng: 19.2128343 },
    { lat: 48.5220071, lng: 19.2124639 },
    { lat: 48.5222011, lng: 19.2121896 },
    { lat: 48.5226597, lng: 19.2118162 },
    { lat: 48.523598, lng: 19.2105783 },
    { lat: 48.524081, lng: 19.2100608 },
    { lat: 48.5249302, lng: 19.2106217 },
    { lat: 48.5251939, lng: 19.2109919 },
    { lat: 48.5254781, lng: 19.2110842 },
    { lat: 48.5263744, lng: 19.2118263 },
    { lat: 48.5278426, lng: 19.2119063 },
    { lat: 48.5283491, lng: 19.2121429 },
    { lat: 48.5286539, lng: 19.2121301 },
    { lat: 48.5290121, lng: 19.2120072 },
    { lat: 48.529165, lng: 19.2120909 },
    { lat: 48.5294978, lng: 19.2121191 },
    { lat: 48.5298568, lng: 19.2118756 },
    { lat: 48.5303159, lng: 19.21186 },
    { lat: 48.5305302, lng: 19.2117267 },
    { lat: 48.5307996, lng: 19.2116794 },
    { lat: 48.531389, lng: 19.2120249 },
    { lat: 48.531759, lng: 19.2121671 },
    { lat: 48.5319798, lng: 19.2126723 },
    { lat: 48.5324961, lng: 19.2133189 },
    { lat: 48.5328832, lng: 19.2120135 },
    { lat: 48.5332939, lng: 19.2113971 },
    { lat: 48.5335614, lng: 19.210872 },
    { lat: 48.5339158, lng: 19.2100037 },
    { lat: 48.5344531, lng: 19.209305 },
    { lat: 48.5357026, lng: 19.2063616 },
    { lat: 48.5359728, lng: 19.204974 },
    { lat: 48.5370072, lng: 19.2018796 },
    { lat: 48.5372258, lng: 19.2007697 },
    { lat: 48.5374323, lng: 19.2000134 },
    { lat: 48.5375566, lng: 19.1997452 },
    { lat: 48.5379778, lng: 19.1991365 },
    { lat: 48.5386838, lng: 19.1999278 },
    { lat: 48.5404549, lng: 19.2008286 },
    { lat: 48.5410981, lng: 19.2010941 },
    { lat: 48.542006, lng: 19.2016564 },
    { lat: 48.5431186, lng: 19.2032273 },
    { lat: 48.5435928, lng: 19.2033551 },
    { lat: 48.54396, lng: 19.2035363 },
    { lat: 48.5446743, lng: 19.2026282 },
    { lat: 48.5443905, lng: 19.2024957 },
    { lat: 48.5447425, lng: 19.2021521 },
    { lat: 48.5450514, lng: 19.2022911 },
    { lat: 48.5453311, lng: 19.2035436 },
    { lat: 48.5458252, lng: 19.2035265 },
    { lat: 48.5459509, lng: 19.2044838 },
    { lat: 48.5461433, lng: 19.2049118 },
    { lat: 48.5472707, lng: 19.2058135 },
    { lat: 48.5483859, lng: 19.2063519 },
    { lat: 48.5487914, lng: 19.2043636 },
    { lat: 48.550065, lng: 19.2044298 },
    { lat: 48.5506266, lng: 19.2048247 },
    { lat: 48.5510259, lng: 19.2047156 },
    { lat: 48.5516749, lng: 19.2043896 },
    { lat: 48.5519416, lng: 19.2043785 },
    { lat: 48.5524714, lng: 19.2039636 },
    { lat: 48.5530696, lng: 19.2043462 },
    { lat: 48.553299, lng: 19.2044058 },
    { lat: 48.5543245, lng: 19.2041422 },
    { lat: 48.5549968, lng: 19.2037374 },
    { lat: 48.5565135, lng: 19.2038862 },
    { lat: 48.5575193, lng: 19.2039155 },
    { lat: 48.5579507, lng: 19.2044619 },
    { lat: 48.5591641, lng: 19.2054425 },
    { lat: 48.5595536, lng: 19.205916 },
    { lat: 48.5600751, lng: 19.2061133 },
    { lat: 48.5624112, lng: 19.2082922 },
    { lat: 48.5633332, lng: 19.2089619 },
    { lat: 48.5638023, lng: 19.2093814 },
    { lat: 48.5656325, lng: 19.2102725 },
    { lat: 48.5671938, lng: 19.2109191 },
    { lat: 48.5678797, lng: 19.211368 },
    { lat: 48.5679227, lng: 19.2112817 },
    { lat: 48.5685363, lng: 19.2089095 },
    { lat: 48.5692647, lng: 19.2074374 },
    { lat: 48.5694633, lng: 19.2068161 },
    { lat: 48.569565, lng: 19.2062691 },
    { lat: 48.5700489, lng: 19.202192 },
    { lat: 48.5703247, lng: 19.2014029 },
    { lat: 48.5703491, lng: 19.2007958 },
    { lat: 48.570304, lng: 19.199874 },
    { lat: 48.5700581, lng: 19.1991107 },
    { lat: 48.5699292, lng: 19.1990532 },
    { lat: 48.5699979, lng: 19.1972335 },
    { lat: 48.5698977, lng: 19.196499 },
    { lat: 48.5699832, lng: 19.1964019 },
    { lat: 48.5700552, lng: 19.1964455 },
    { lat: 48.5701745, lng: 19.196329 },
    { lat: 48.57027, lng: 19.1961807 },
    { lat: 48.5703132, lng: 19.1959345 },
    { lat: 48.570402, lng: 19.1957456 },
    { lat: 48.5704504, lng: 19.1957933 },
    { lat: 48.5705227, lng: 19.195634 },
    { lat: 48.5709172, lng: 19.1952402 },
    { lat: 48.5708475, lng: 19.1947618 },
    { lat: 48.5712923, lng: 19.1945927 },
    { lat: 48.5715589, lng: 19.1936113 },
    { lat: 48.5716957, lng: 19.1927443 },
    { lat: 48.5722535, lng: 19.1915689 },
    { lat: 48.5728705, lng: 19.1890466 },
    { lat: 48.5730493, lng: 19.1879097 },
    { lat: 48.5731119, lng: 19.1869314 },
    { lat: 48.5730159, lng: 19.1853634 },
    { lat: 48.5722158, lng: 19.1791443 },
    { lat: 48.571839, lng: 19.1760639 },
    { lat: 48.571809, lng: 19.1755213 },
    { lat: 48.5718045, lng: 19.1745344 },
    { lat: 48.5720844, lng: 19.1649372 },
    { lat: 48.5723816, lng: 19.1649766 },
    { lat: 48.5731538, lng: 19.1649038 },
    { lat: 48.573263, lng: 19.1647663 },
    { lat: 48.5736118, lng: 19.1647739 },
    { lat: 48.5742421, lng: 19.1645981 },
    { lat: 48.574716, lng: 19.1647844 },
    { lat: 48.5751172, lng: 19.1651756 },
    { lat: 48.5751149, lng: 19.1646682 },
    { lat: 48.5750428, lng: 19.1640953 },
    { lat: 48.5747782, lng: 19.1627274 },
    { lat: 48.5749873, lng: 19.1627435 },
    { lat: 48.5753212, lng: 19.1635705 },
    { lat: 48.575433, lng: 19.1634821 },
    { lat: 48.5755954, lng: 19.1629788 },
    { lat: 48.5755674, lng: 19.1626854 },
    { lat: 48.5766109, lng: 19.1621301 },
    { lat: 48.5771419, lng: 19.1637311 },
    { lat: 48.5783773, lng: 19.1638703 },
    { lat: 48.5789728, lng: 19.1638753 },
    { lat: 48.5799494, lng: 19.1637661 },
    { lat: 48.5802566, lng: 19.1636431 },
    { lat: 48.5805985, lng: 19.1634187 },
    { lat: 48.5813235, lng: 19.1626423 },
    { lat: 48.5823592, lng: 19.1621608 },
    { lat: 48.583195, lng: 19.1619591 },
    { lat: 48.5835632, lng: 19.1625232 },
    { lat: 48.5838835, lng: 19.1634454 },
    { lat: 48.584074, lng: 19.1637214 },
    { lat: 48.5847414, lng: 19.1631701 },
    { lat: 48.5853843, lng: 19.1625117 },
    { lat: 48.5860138, lng: 19.164055 },
    { lat: 48.586437, lng: 19.1637463 },
    { lat: 48.5866509, lng: 19.1646129 },
    { lat: 48.5878782, lng: 19.1634335 },
    { lat: 48.5877831, lng: 19.163062 },
    { lat: 48.589155, lng: 19.1612073 },
    { lat: 48.5896976, lng: 19.1605939 },
    { lat: 48.5900015, lng: 19.1604242 },
    { lat: 48.5904302, lng: 19.159929 },
    { lat: 48.5905533, lng: 19.1600915 },
    { lat: 48.5909682, lng: 19.1610343 },
    { lat: 48.5914562, lng: 19.1623346 },
    { lat: 48.5916689, lng: 19.1625565 },
    { lat: 48.5918955, lng: 19.1625579 },
    { lat: 48.592312, lng: 19.1623375 },
    { lat: 48.5928711, lng: 19.1628886 },
    { lat: 48.5945839, lng: 19.1632632 },
    { lat: 48.5950235, lng: 19.1634259 },
    { lat: 48.5955702, lng: 19.163494 },
    { lat: 48.5960857, lng: 19.1634289 },
    { lat: 48.596615, lng: 19.1631015 },
    { lat: 48.5972197, lng: 19.1624427 },
  ],
  ZvolenExt: [
    { lat: 48.624067, lng: 19.22379 },
    { lat: 48.6240938, lng: 19.2234521 },
    { lat: 48.6233101, lng: 19.2226118 },
    { lat: 48.6230942, lng: 19.2222291 },
    { lat: 48.621818, lng: 19.2204195 },
    { lat: 48.6217123, lng: 19.2201201 },
    { lat: 48.6190327, lng: 19.2171069 },
    { lat: 48.6188136, lng: 19.2164311 },
    { lat: 48.6183629, lng: 19.2157864 },
    { lat: 48.6184087, lng: 19.214347 },
    { lat: 48.6185436, lng: 19.2139451 },
    { lat: 48.6181091, lng: 19.2131725 },
    { lat: 48.6164124, lng: 19.2113849 },
    { lat: 48.6164956, lng: 19.2111288 },
    { lat: 48.6159457, lng: 19.2101834 },
    { lat: 48.6155719, lng: 19.2088259 },
    { lat: 48.6141642, lng: 19.2056431 },
    { lat: 48.614153, lng: 19.2052546 },
    { lat: 48.6135264, lng: 19.2041241 },
    { lat: 48.6131652, lng: 19.2034699 },
    { lat: 48.6142321, lng: 19.2006208 },
    { lat: 48.614403, lng: 19.1999866 },
    { lat: 48.6146501, lng: 19.198009 },
    { lat: 48.6148101, lng: 19.197339 },
    { lat: 48.6150143, lng: 19.1971346 },
    { lat: 48.6147142, lng: 19.1946162 },
    { lat: 48.6147048, lng: 19.1932075 },
    { lat: 48.6153575, lng: 19.192536 },
    { lat: 48.6165504, lng: 19.1918282 },
    { lat: 48.6173208, lng: 19.1915839 },
    { lat: 48.6180625, lng: 19.1917465 },
    { lat: 48.6189398, lng: 19.1914532 },
    { lat: 48.6210165, lng: 19.1904217 },
    { lat: 48.6219756, lng: 19.1877221 },
    { lat: 48.6178609, lng: 19.187421 },
    { lat: 48.6174758, lng: 19.1874735 },
    { lat: 48.6173336, lng: 19.1872656 },
    { lat: 48.6172241, lng: 19.1872643 },
    { lat: 48.6171489, lng: 19.1871501 },
    { lat: 48.6169935, lng: 19.1872045 },
    { lat: 48.6169081, lng: 19.1870712 },
    { lat: 48.6167282, lng: 19.1871949 },
    { lat: 48.6160576, lng: 19.1871271 },
    { lat: 48.6159335, lng: 19.1872168 },
    { lat: 48.6157463, lng: 19.1871479 },
    { lat: 48.615507, lng: 19.187368 },
    { lat: 48.6153589, lng: 19.1873154 },
    { lat: 48.6151953, lng: 19.1875203 },
    { lat: 48.6147335, lng: 19.1876658 },
    { lat: 48.6144768, lng: 19.1878789 },
    { lat: 48.6143688, lng: 19.1878817 },
    { lat: 48.6143405, lng: 19.1879932 },
    { lat: 48.6137668, lng: 19.1879762 },
    { lat: 48.6134248, lng: 19.1878764 },
    { lat: 48.6128656, lng: 19.1875953 },
    { lat: 48.612634, lng: 19.1876076 },
    { lat: 48.6125139, lng: 19.1875034 },
    { lat: 48.612233, lng: 19.187431 },
    { lat: 48.6115212, lng: 19.1868698 },
    { lat: 48.6107646, lng: 19.186724 },
    { lat: 48.6105888, lng: 19.1867272 },
    { lat: 48.6100099, lng: 19.187082 },
    { lat: 48.6097802, lng: 19.187315 },
    { lat: 48.6090133, lng: 19.1874488 },
    { lat: 48.6085416, lng: 19.1873472 },
    { lat: 48.6082548, lng: 19.1874966 },
    { lat: 48.6079725, lng: 19.187833 },
    { lat: 48.6078421, lng: 19.1883929 },
    { lat: 48.6076804, lng: 19.188704 },
    { lat: 48.6071469, lng: 19.1892012 },
    { lat: 48.6066611, lng: 19.1894787 },
    { lat: 48.6065634, lng: 19.1896252 },
    { lat: 48.6064099, lng: 19.1902689 },
    { lat: 48.6063849, lng: 19.1905384 },
    { lat: 48.6064177, lng: 19.1909576 },
    { lat: 48.6063238, lng: 19.191409 },
    { lat: 48.6061853, lng: 19.1914597 },
    { lat: 48.6060291, lng: 19.1917919 },
    { lat: 48.6056032, lng: 19.1923034 },
    { lat: 48.6053045, lng: 19.1924646 },
    { lat: 48.6051155, lng: 19.1926772 },
    { lat: 48.6048547, lng: 19.1926963 },
    { lat: 48.6044351, lng: 19.1929161 },
    { lat: 48.6043486, lng: 19.1929246 },
    { lat: 48.6038009, lng: 19.1926515 },
    { lat: 48.6036697, lng: 19.1928032 },
    { lat: 48.603525, lng: 19.1928631 },
    { lat: 48.6031343, lng: 19.1926911 },
    { lat: 48.6030119, lng: 19.1928397 },
    { lat: 48.602889, lng: 19.1928612 },
    { lat: 48.6027133, lng: 19.1927024 },
    { lat: 48.6025917, lng: 19.1924726 },
    { lat: 48.6020691, lng: 19.1918381 },
    { lat: 48.6012087, lng: 19.191624 },
    { lat: 48.6011219, lng: 19.1914873 },
    { lat: 48.601003, lng: 19.1910176 },
    { lat: 48.6007173, lng: 19.1907979 },
    { lat: 48.60056, lng: 19.1904849 },
    { lat: 48.6005251, lng: 19.1902776 },
    { lat: 48.6002613, lng: 19.1899127 },
    { lat: 48.599939, lng: 19.1895921 },
    { lat: 48.599572, lng: 19.189831 },
    { lat: 48.5988193, lng: 19.1907343 },
    { lat: 48.5979972, lng: 19.1921335 },
    { lat: 48.5979365, lng: 19.1924123 },
    { lat: 48.597942, lng: 19.1930748 },
    { lat: 48.597812, lng: 19.1932093 },
    { lat: 48.5975925, lng: 19.1938338 },
    { lat: 48.597515, lng: 19.1942548 },
    { lat: 48.5975402, lng: 19.1944945 },
    { lat: 48.5973595, lng: 19.1949484 },
    { lat: 48.5974069, lng: 19.1949944 },
    { lat: 48.597352, lng: 19.1952092 },
    { lat: 48.5973331, lng: 19.195654 },
    { lat: 48.5971649, lng: 19.1959476 },
    { lat: 48.597025, lng: 19.1959229 },
    { lat: 48.5970546, lng: 19.1962049 },
    { lat: 48.5967761, lng: 19.1965713 },
    { lat: 48.5967733, lng: 19.1968138 },
    { lat: 48.5966064, lng: 19.1969256 },
    { lat: 48.5964339, lng: 19.1973397 },
    { lat: 48.5962955, lng: 19.1973704 },
    { lat: 48.5962967, lng: 19.1976691 },
    { lat: 48.5961194, lng: 19.197875 },
    { lat: 48.5957969, lng: 19.1986082 },
    { lat: 48.5956369, lng: 19.1987449 },
    { lat: 48.595544, lng: 19.1989671 },
    { lat: 48.5953905, lng: 19.1990582 },
    { lat: 48.59528, lng: 19.1992863 },
    { lat: 48.5951799, lng: 19.1996603 },
    { lat: 48.5950162, lng: 19.1996792 },
    { lat: 48.5949413, lng: 19.199554 },
    { lat: 48.5948927, lng: 19.1996655 },
    { lat: 48.5948354, lng: 19.1996003 },
    { lat: 48.594782, lng: 19.1997733 },
    { lat: 48.5948431, lng: 19.1997818 },
    { lat: 48.5948258, lng: 19.1999796 },
    { lat: 48.5947348, lng: 19.200031 },
    { lat: 48.5946797, lng: 19.1999424 },
    { lat: 48.5946099, lng: 19.1999683 },
    { lat: 48.5946342, lng: 19.1996355 },
    { lat: 48.5945209, lng: 19.199714 },
    { lat: 48.5944543, lng: 19.1998987 },
    { lat: 48.5945096, lng: 19.1999463 },
    { lat: 48.5945133, lng: 19.200041 },
    { lat: 48.5943378, lng: 19.2000961 },
    { lat: 48.5943579, lng: 19.2001872 },
    { lat: 48.5942111, lng: 19.2002046 },
    { lat: 48.5941854, lng: 19.1999777 },
    { lat: 48.5942271, lng: 19.1999093 },
    { lat: 48.594186, lng: 19.1998071 },
    { lat: 48.5940054, lng: 19.1999821 },
    { lat: 48.593729, lng: 19.1999583 },
    { lat: 48.5936617, lng: 19.2002389 },
    { lat: 48.5932244, lng: 19.2004502 },
    { lat: 48.5931417, lng: 19.2006293 },
    { lat: 48.5930503, lng: 19.2005999 },
    { lat: 48.5928678, lng: 19.2008584 },
    { lat: 48.5926672, lng: 19.200948 },
    { lat: 48.5924711, lng: 19.2013875 },
    { lat: 48.5922242, lng: 19.2015018 },
    { lat: 48.592173, lng: 19.201657 },
    { lat: 48.5918561, lng: 19.2018824 },
    { lat: 48.5918288, lng: 19.2019716 },
    { lat: 48.5917289, lng: 19.2018645 },
    { lat: 48.5915846, lng: 19.2019332 },
    { lat: 48.5915562, lng: 19.2018352 },
    { lat: 48.5914733, lng: 19.2019374 },
    { lat: 48.591398, lng: 19.201851 },
    { lat: 48.5913015, lng: 19.2018827 },
    { lat: 48.5912508, lng: 19.2017684 },
    { lat: 48.5909697, lng: 19.201832 },
    { lat: 48.5909193, lng: 19.2019743 },
    { lat: 48.5908624, lng: 19.2019657 },
    { lat: 48.5907621, lng: 19.2021702 },
    { lat: 48.590714, lng: 19.202549 },
    { lat: 48.590894, lng: 19.2042258 },
    { lat: 48.5907912, lng: 19.2047047 },
    { lat: 48.5909699, lng: 19.2048561 },
    { lat: 48.5911082, lng: 19.2047763 },
    { lat: 48.5912385, lng: 19.2044941 },
    { lat: 48.5914383, lng: 19.2046592 },
    { lat: 48.5913919, lng: 19.2049072 },
    { lat: 48.5912518, lng: 19.2050851 },
    { lat: 48.591191, lng: 19.2057371 },
    { lat: 48.5912263, lng: 19.2064364 },
    { lat: 48.591691, lng: 19.2061659 },
    { lat: 48.5918319, lng: 19.2061876 },
    { lat: 48.5919571, lng: 19.2063952 },
    { lat: 48.5919395, lng: 19.2066101 },
    { lat: 48.5918775, lng: 19.2066383 },
    { lat: 48.5918168, lng: 19.2066383 },
    { lat: 48.5917711, lng: 19.2067181 },
    { lat: 48.5918604, lng: 19.2069799 },
    { lat: 48.5918212, lng: 19.2077933 },
    { lat: 48.5918579, lng: 19.208496 },
    { lat: 48.5919498, lng: 19.2085777 },
    { lat: 48.5920857, lng: 19.2085897 },
    { lat: 48.5921012, lng: 19.2087714 },
    { lat: 48.5918997, lng: 19.2088923 },
    { lat: 48.5917297, lng: 19.2087346 },
    { lat: 48.5916932, lng: 19.2087693 },
    { lat: 48.5917048, lng: 19.2094239 },
    { lat: 48.5916104, lng: 19.2094785 },
    { lat: 48.5914914, lng: 19.2092097 },
    { lat: 48.591384, lng: 19.2092725 },
    { lat: 48.5913668, lng: 19.2096618 },
    { lat: 48.5914563, lng: 19.2101547 },
    { lat: 48.5914232, lng: 19.2103521 },
    { lat: 48.5915226, lng: 19.2107285 },
    { lat: 48.5914135, lng: 19.2108937 },
    { lat: 48.591085, lng: 19.21109 },
    { lat: 48.5909411, lng: 19.2110547 },
    { lat: 48.5908647, lng: 19.2109649 },
    { lat: 48.5907822, lng: 19.2111236 },
    { lat: 48.5907477, lng: 19.2112495 },
    { lat: 48.590833, lng: 19.2115153 },
    { lat: 48.5908063, lng: 19.2115692 },
    { lat: 48.5906699, lng: 19.2115469 },
    { lat: 48.590605, lng: 19.2119757 },
    { lat: 48.5905591, lng: 19.2124668 },
    { lat: 48.5906292, lng: 19.2127478 },
    { lat: 48.5904733, lng: 19.2127854 },
    { lat: 48.5903484, lng: 19.2131357 },
    { lat: 48.5904319, lng: 19.2134188 },
    { lat: 48.590366, lng: 19.2136369 },
    { lat: 48.5904009, lng: 19.2138435 },
    { lat: 48.5903329, lng: 19.2140244 },
    { lat: 48.5901571, lng: 19.2141628 },
    { lat: 48.590052, lng: 19.2144533 },
    { lat: 48.5899132, lng: 19.2144313 },
    { lat: 48.5898928, lng: 19.2147971 },
    { lat: 48.5899554, lng: 19.2149226 },
    { lat: 48.5899444, lng: 19.2151143 },
    { lat: 48.5899606, lng: 19.2151702 },
    { lat: 48.5902302, lng: 19.2152457 },
    { lat: 48.5902312, lng: 19.2155411 },
    { lat: 48.5900461, lng: 19.2155823 },
    { lat: 48.5900019, lng: 19.2156922 },
    { lat: 48.5900295, lng: 19.2159342 },
    { lat: 48.5899992, lng: 19.2161957 },
    { lat: 48.5899351, lng: 19.2163149 },
    { lat: 48.5898382, lng: 19.2161729 },
    { lat: 48.5898475, lng: 19.2159475 },
    { lat: 48.5897753, lng: 19.2159488 },
    { lat: 48.5896637, lng: 19.2161103 },
    { lat: 48.589389, lng: 19.2160731 },
    { lat: 48.5893355, lng: 19.2162692 },
    { lat: 48.5893891, lng: 19.2165353 },
    { lat: 48.5895934, lng: 19.2169307 },
    { lat: 48.5894044, lng: 19.2171724 },
    { lat: 48.5894008, lng: 19.2176079 },
    { lat: 48.5891451, lng: 19.2179114 },
    { lat: 48.5887061, lng: 19.2176948 },
    { lat: 48.5886794, lng: 19.2177487 },
    { lat: 48.5886609, lng: 19.2178768 },
    { lat: 48.5887635, lng: 19.2180384 },
    { lat: 48.5885703, lng: 19.2186071 },
    { lat: 48.5885306, lng: 19.2185773 },
    { lat: 48.5884577, lng: 19.2186569 },
    { lat: 48.5884233, lng: 19.2188274 },
    { lat: 48.5884588, lng: 19.2189117 },
    { lat: 48.5885535, lng: 19.2189554 },
    { lat: 48.5884937, lng: 19.2191183 },
    { lat: 48.5883373, lng: 19.2190067 },
    { lat: 48.5883304, lng: 19.219099 },
    { lat: 48.5884643, lng: 19.2194377 },
    { lat: 48.5881878, lng: 19.2195025 },
    { lat: 48.5879644, lng: 19.2193571 },
    { lat: 48.5875728, lng: 19.2195126 },
    { lat: 48.5875775, lng: 19.2197026 },
    { lat: 48.5880412, lng: 19.2203818 },
    { lat: 48.5881272, lng: 19.220712 },
    { lat: 48.5882696, lng: 19.2208119 },
    { lat: 48.5882813, lng: 19.2209566 },
    { lat: 48.5880039, lng: 19.2213274 },
    { lat: 48.5877337, lng: 19.2213334 },
    { lat: 48.587528, lng: 19.2209076 },
    { lat: 48.5874065, lng: 19.2209584 },
    { lat: 48.5873368, lng: 19.2210544 },
    { lat: 48.5873667, lng: 19.2211597 },
    { lat: 48.5871384, lng: 19.2213313 },
    { lat: 48.5871259, lng: 19.2215356 },
    { lat: 48.5872706, lng: 19.2217753 },
    { lat: 48.5871662, lng: 19.222412 },
    { lat: 48.5872381, lng: 19.2224041 },
    { lat: 48.5873072, lng: 19.2228044 },
    { lat: 48.5873985, lng: 19.2229232 },
    { lat: 48.5873155, lng: 19.2239927 },
    { lat: 48.5871319, lng: 19.2240198 },
    { lat: 48.5873666, lng: 19.2246735 },
    { lat: 48.5873223, lng: 19.2248757 },
    { lat: 48.5874905, lng: 19.2250881 },
    { lat: 48.5873825, lng: 19.2252767 },
    { lat: 48.5873174, lng: 19.2257937 },
    { lat: 48.5874177, lng: 19.2258165 },
    { lat: 48.587468, lng: 19.2260149 },
    { lat: 48.5872812, lng: 19.2262531 },
    { lat: 48.5872563, lng: 19.2263915 },
    { lat: 48.5875118, lng: 19.2269141 },
    { lat: 48.5875526, lng: 19.2275686 },
    { lat: 48.5872513, lng: 19.2276767 },
    { lat: 48.5872924, lng: 19.2278761 },
    { lat: 48.5872609, lng: 19.2279679 },
    { lat: 48.5871182, lng: 19.2279089 },
    { lat: 48.5869916, lng: 19.228181 },
    { lat: 48.5868806, lng: 19.228214 },
    { lat: 48.5868682, lng: 19.2281448 },
    { lat: 48.587002, lng: 19.2279285 },
    { lat: 48.5869761, lng: 19.2277683 },
    { lat: 48.586783, lng: 19.2278306 },
    { lat: 48.5866673, lng: 19.2279997 },
    { lat: 48.5865322, lng: 19.2289525 },
    { lat: 48.5866445, lng: 19.2290622 },
    { lat: 48.5868661, lng: 19.2290516 },
    { lat: 48.5870216, lng: 19.2291668 },
    { lat: 48.5869066, lng: 19.2294003 },
    { lat: 48.5871818, lng: 19.2300018 },
    { lat: 48.5872724, lng: 19.2306174 },
    { lat: 48.5871189, lng: 19.2309331 },
    { lat: 48.5871273, lng: 19.231014 },
    { lat: 48.5875592, lng: 19.2312486 },
    { lat: 48.5874905, lng: 19.2314834 },
    { lat: 48.587611, lng: 19.232431 },
    { lat: 48.5876777, lng: 19.2324144 },
    { lat: 48.5877149, lng: 19.2323914 },
    { lat: 48.5879647, lng: 19.2331233 },
    { lat: 48.5880551, lng: 19.2338457 },
    { lat: 48.5882788, lng: 19.2341809 },
    { lat: 48.5883184, lng: 19.2345095 },
    { lat: 48.5882068, lng: 19.2350669 },
    { lat: 48.5883062, lng: 19.2353237 },
    { lat: 48.5884692, lng: 19.2354993 },
    { lat: 48.5885582, lng: 19.2359164 },
    { lat: 48.5887056, lng: 19.2360276 },
    { lat: 48.5889126, lng: 19.2366474 },
    { lat: 48.5889945, lng: 19.2370112 },
    { lat: 48.5889153, lng: 19.2371887 },
    { lat: 48.5889482, lng: 19.237424 },
    { lat: 48.589089, lng: 19.2375861 },
    { lat: 48.589072, lng: 19.2382317 },
    { lat: 48.5893237, lng: 19.238707 },
    { lat: 48.5897242, lng: 19.2386657 },
    { lat: 48.5898188, lng: 19.2388051 },
    { lat: 48.5898296, lng: 19.2390261 },
    { lat: 48.5897287, lng: 19.2394542 },
    { lat: 48.5897094, lng: 19.2399869 },
    { lat: 48.5897964, lng: 19.2402696 },
    { lat: 48.5897752, lng: 19.240597 },
    { lat: 48.5894801, lng: 19.2409069 },
    { lat: 48.5895247, lng: 19.241106 },
    { lat: 48.5896506, lng: 19.2412132 },
    { lat: 48.5897615, lng: 19.241478 },
    { lat: 48.5898271, lng: 19.2418991 },
    { lat: 48.5900845, lng: 19.241421 },
    { lat: 48.5902077, lng: 19.2414284 },
    { lat: 48.5903319, lng: 19.2416615 },
    { lat: 48.5903733, lng: 19.241893 },
    { lat: 48.590304, lng: 19.2426202 },
    { lat: 48.5904148, lng: 19.2433949 },
    { lat: 48.590548, lng: 19.2436768 },
    { lat: 48.5908298, lng: 19.2439797 },
    { lat: 48.5910257, lng: 19.2444872 },
    { lat: 48.5909717, lng: 19.2449753 },
    { lat: 48.5909993, lng: 19.245778 },
    { lat: 48.5912412, lng: 19.246305 },
    { lat: 48.5915211, lng: 19.2467057 },
    { lat: 48.5917613, lng: 19.2467526 },
    { lat: 48.5943815, lng: 19.2459304 },
    { lat: 48.5944899, lng: 19.2457931 },
    { lat: 48.5971206, lng: 19.244447 },
    { lat: 48.5972729, lng: 19.2454437 },
    { lat: 48.5974313, lng: 19.2459244 },
    { lat: 48.5979284, lng: 19.2466676 },
    { lat: 48.5987934, lng: 19.2468871 },
    { lat: 48.5993663, lng: 19.24859 },
    { lat: 48.5996798, lng: 19.2489183 },
    { lat: 48.5999193, lng: 19.2490006 },
    { lat: 48.6013924, lng: 19.2489501 },
    { lat: 48.6014019, lng: 19.2481228 },
    { lat: 48.6015129, lng: 19.2479218 },
    { lat: 48.6021962, lng: 19.2480916 },
    { lat: 48.6021091, lng: 19.2494345 },
    { lat: 48.6027696, lng: 19.2495035 },
    { lat: 48.6026814, lng: 19.2500339 },
    { lat: 48.602828, lng: 19.2501036 },
    { lat: 48.6030545, lng: 19.2511474 },
    { lat: 48.6040323, lng: 19.2517895 },
    { lat: 48.6037687, lng: 19.2525358 },
    { lat: 48.6037572, lng: 19.2537679 },
    { lat: 48.6042094, lng: 19.2536084 },
    { lat: 48.6047299, lng: 19.253856 },
    { lat: 48.6050462, lng: 19.2535683 },
    { lat: 48.6054405, lng: 19.2536823 },
    { lat: 48.6055721, lng: 19.253956 },
    { lat: 48.6065739, lng: 19.2542072 },
    { lat: 48.6068208, lng: 19.2540913 },
    { lat: 48.6071722, lng: 19.2544548 },
    { lat: 48.6079084, lng: 19.2543417 },
    { lat: 48.6083461, lng: 19.2541631 },
    { lat: 48.6092242, lng: 19.2552333 },
    { lat: 48.6097743, lng: 19.2554488 },
    { lat: 48.6104196, lng: 19.2559954 },
    { lat: 48.610541, lng: 19.2557758 },
    { lat: 48.6106266, lng: 19.2557529 },
    { lat: 48.6107068, lng: 19.2560824 },
    { lat: 48.6106868, lng: 19.2562741 },
    { lat: 48.6108507, lng: 19.2564691 },
    { lat: 48.6116039, lng: 19.2569415 },
    { lat: 48.6124934, lng: 19.2571322 },
    { lat: 48.613749, lng: 19.2576648 },
    { lat: 48.6140506, lng: 19.25756 },
    { lat: 48.6143932, lng: 19.2571672 },
    { lat: 48.6151846, lng: 19.2566266 },
    { lat: 48.6152312, lng: 19.2565947 },
    { lat: 48.6164152, lng: 19.2563455 },
    { lat: 48.6175068, lng: 19.2555859 },
    { lat: 48.61769, lng: 19.2557652 },
    { lat: 48.6200792, lng: 19.2589686 },
    { lat: 48.6214862, lng: 19.2565504 },
    { lat: 48.6216276, lng: 19.2565153 },
    { lat: 48.6241108, lng: 19.2597603 },
    { lat: 48.6262955, lng: 19.2615349 },
    { lat: 48.6271605, lng: 19.2640882 },
    { lat: 48.6290942, lng: 19.2692583 },
    { lat: 48.6294676, lng: 19.2707366 },
    { lat: 48.6299396, lng: 19.2719244 },
    { lat: 48.6305182, lng: 19.2730785 },
    { lat: 48.6308495, lng: 19.2740556 },
    { lat: 48.6309565, lng: 19.2748737 },
    { lat: 48.631569, lng: 19.2754187 },
    { lat: 48.6333274, lng: 19.278511 },
    { lat: 48.6335496, lng: 19.2787706 },
    { lat: 48.6339355, lng: 19.2790387 },
    { lat: 48.6340333, lng: 19.2792211 },
    { lat: 48.6340814, lng: 19.2795615 },
    { lat: 48.6353863, lng: 19.2753438 },
    { lat: 48.6348775, lng: 19.2745643 },
    { lat: 48.6357637, lng: 19.2729336 },
    { lat: 48.6365464, lng: 19.2716289 },
    { lat: 48.6367012, lng: 19.2714747 },
    { lat: 48.637267, lng: 19.271171 },
    { lat: 48.637292, lng: 19.27104 },
    { lat: 48.637191, lng: 19.270682 },
    { lat: 48.6371, lng: 19.270518 },
    { lat: 48.636858, lng: 19.270086 },
    { lat: 48.636514, lng: 19.269529 },
    { lat: 48.636221, lng: 19.268761 },
    { lat: 48.635401, lng: 19.268074 },
    { lat: 48.634969, lng: 19.267713 },
    { lat: 48.634839, lng: 19.26755 },
    { lat: 48.634534, lng: 19.266889 },
    { lat: 48.634292, lng: 19.266402 },
    { lat: 48.633877, lng: 19.265574 },
    { lat: 48.633628, lng: 19.265096 },
    { lat: 48.633243, lng: 19.264759 },
    { lat: 48.63292, lng: 19.264476 },
    { lat: 48.632138, lng: 19.263362 },
    { lat: 48.63213, lng: 19.26332 },
    { lat: 48.631935, lng: 19.262251 },
    { lat: 48.631906, lng: 19.262136 },
    { lat: 48.631355, lng: 19.262082 },
    { lat: 48.631192, lng: 19.261959 },
    { lat: 48.631464, lng: 19.260229 },
    { lat: 48.631435, lng: 19.259268 },
    { lat: 48.631062, lng: 19.259472 },
    { lat: 48.630722, lng: 19.258874 },
    { lat: 48.630495, lng: 19.25866 },
    { lat: 48.630113, lng: 19.258272 },
    { lat: 48.630087, lng: 19.258246 },
    { lat: 48.630078, lng: 19.258236 },
    { lat: 48.629995, lng: 19.257747 },
    { lat: 48.629932, lng: 19.257371 },
    { lat: 48.629525, lng: 19.257325 },
    { lat: 48.629014, lng: 19.256896 },
    { lat: 48.628537, lng: 19.256314 },
    { lat: 48.628011, lng: 19.255904 },
    { lat: 48.628012, lng: 19.255886 },
    { lat: 48.628016, lng: 19.255825 },
    { lat: 48.628038, lng: 19.255444 },
    { lat: 48.628053, lng: 19.252796 },
    { lat: 48.628016, lng: 19.251754 },
    { lat: 48.627907, lng: 19.249622 },
    { lat: 48.628007, lng: 19.249543 },
    { lat: 48.62798, lng: 19.246208 },
    { lat: 48.628274, lng: 19.245283 },
    { lat: 48.62841, lng: 19.24468 },
    { lat: 48.627873, lng: 19.244136 },
    { lat: 48.627781, lng: 19.243118 },
    { lat: 48.627102, lng: 19.241362 },
    { lat: 48.626556, lng: 19.240745 },
    { lat: 48.627222, lng: 19.23786 },
    { lat: 48.627372, lng: 19.237238 },
    { lat: 48.62739, lng: 19.237164 },
    { lat: 48.627406, lng: 19.237054 },
    { lat: 48.627419, lng: 19.236959 },
    { lat: 48.627431, lng: 19.236877 },
    { lat: 48.627465, lng: 19.236781 },
    { lat: 48.626951, lng: 19.234803 },
    { lat: 48.626947, lng: 19.234769 },
    { lat: 48.626755, lng: 19.234729 },
    { lat: 48.6264047, lng: 19.2346131 },
    { lat: 48.626103, lng: 19.234278 },
    { lat: 48.626006, lng: 19.234174 },
    { lat: 48.625959, lng: 19.234109 },
    { lat: 48.6258, lng: 19.233921 },
    { lat: 48.625621, lng: 19.233776 },
    { lat: 48.625529, lng: 19.233906 },
    { lat: 48.625329, lng: 19.234188 },
    { lat: 48.625114, lng: 19.234028 },
    { lat: 48.624791, lng: 19.233823 },
    { lat: 48.624639, lng: 19.233693 },
    { lat: 48.624572, lng: 19.233712 },
    { lat: 48.624477, lng: 19.234043 },
    { lat: 48.624271, lng: 19.234095 },
    { lat: 48.62424, lng: 19.234181 },
    { lat: 48.624204, lng: 19.234219 },
    { lat: 48.624073, lng: 19.234359 },
    { lat: 48.623738, lng: 19.234158 },
    { lat: 48.623669, lng: 19.233991 },
    { lat: 48.623767, lng: 19.233262 },
    { lat: 48.623637, lng: 19.233046 },
    { lat: 48.623585, lng: 19.233156 },
    { lat: 48.623377, lng: 19.23313 },
    { lat: 48.623267, lng: 19.232985 },
    { lat: 48.623241, lng: 19.23267 },
    { lat: 48.623106, lng: 19.232536 },
    { lat: 48.622984, lng: 19.232553 },
    { lat: 48.622809, lng: 19.232679 },
    { lat: 48.622655, lng: 19.232583 },
    { lat: 48.622527, lng: 19.23245 },
    { lat: 48.622492, lng: 19.232155 },
    { lat: 48.622393, lng: 19.231962 },
    { lat: 48.622069, lng: 19.231962 },
    { lat: 48.621455, lng: 19.232073 },
    { lat: 48.621299, lng: 19.232299 },
    { lat: 48.621104, lng: 19.232389 },
    { lat: 48.620837, lng: 19.232265 },
    { lat: 48.620738, lng: 19.23215 },
    { lat: 48.620569, lng: 19.232406 },
    { lat: 48.620317, lng: 19.232281 },
    { lat: 48.620386, lng: 19.231902 },
    { lat: 48.620045, lng: 19.231431 },
    { lat: 48.619909, lng: 19.231556 },
    { lat: 48.619862, lng: 19.23173 },
    { lat: 48.619819, lng: 19.231858 },
    { lat: 48.61963, lng: 19.231847 },
    { lat: 48.619403, lng: 19.231703 },
    { lat: 48.619129, lng: 19.231439 },
    { lat: 48.618923, lng: 19.231305 },
    { lat: 48.61895, lng: 19.23112 },
    { lat: 48.619514, lng: 19.229268 },
    { lat: 48.620016, lng: 19.229473 },
    { lat: 48.620234, lng: 19.22905 },
    { lat: 48.620559, lng: 19.228419 },
    { lat: 48.620808, lng: 19.227469 },
    { lat: 48.621288, lng: 19.227009 },
    { lat: 48.621579, lng: 19.226406 },
    { lat: 48.622178, lng: 19.225781 },
    { lat: 48.622848, lng: 19.225225 },
    { lat: 48.6233, lng: 19.224475 },
    { lat: 48.624067, lng: 19.22379 },
  ],
  Lukavica: [
    { lat: 48.6384035, lng: 19.1969768 },
    { lat: 48.6381747, lng: 19.1974628 },
    { lat: 48.6379892, lng: 19.1975611 },
    { lat: 48.6378837, lng: 19.19794 },
    { lat: 48.6378798, lng: 19.1986008 },
    { lat: 48.6381152, lng: 19.1999083 },
    { lat: 48.6380339, lng: 19.2002068 },
    { lat: 48.6374131, lng: 19.2016928 },
    { lat: 48.637403, lng: 19.2022924 },
    { lat: 48.6372867, lng: 19.2025662 },
    { lat: 48.6373302, lng: 19.203158 },
    { lat: 48.6374414, lng: 19.2035622 },
    { lat: 48.6376244, lng: 19.2037311 },
    { lat: 48.637621, lng: 19.2046336 },
    { lat: 48.6378581, lng: 19.2049618 },
    { lat: 48.6379518, lng: 19.2051984 },
    { lat: 48.6381998, lng: 19.2068139 },
    { lat: 48.6382925, lng: 19.20717 },
    { lat: 48.6384377, lng: 19.2074404 },
    { lat: 48.6384096, lng: 19.2079773 },
    { lat: 48.6384079, lng: 19.20801 },
    { lat: 48.6386813, lng: 19.2081818 },
    { lat: 48.6384334, lng: 19.2086438 },
    { lat: 48.6384971, lng: 19.2093074 },
    { lat: 48.6383485, lng: 19.2098871 },
    { lat: 48.6382774, lng: 19.2109973 },
    { lat: 48.6382677, lng: 19.211883 },
    { lat: 48.6383752, lng: 19.2130465 },
    { lat: 48.637998, lng: 19.2138917 },
    { lat: 48.6381052, lng: 19.2147248 },
    { lat: 48.6384474, lng: 19.2155219 },
    { lat: 48.6383295, lng: 19.2161138 },
    { lat: 48.6385006, lng: 19.216639 },
    { lat: 48.6384709, lng: 19.2171409 },
    { lat: 48.6385988, lng: 19.2174528 },
    { lat: 48.6382653, lng: 19.2177111 },
    { lat: 48.6378909, lng: 19.2186123 },
    { lat: 48.6373496, lng: 19.2192799 },
    { lat: 48.6366275, lng: 19.2199584 },
    { lat: 48.6357524, lng: 19.2202749 },
    { lat: 48.6353374, lng: 19.2202948 },
    { lat: 48.6352379, lng: 19.2199887 },
    { lat: 48.6334746, lng: 19.2202112 },
    { lat: 48.6330024, lng: 19.2198907 },
    { lat: 48.6320088, lng: 19.2198537 },
    { lat: 48.6313419, lng: 19.2204201 },
    { lat: 48.6310931, lng: 19.2207965 },
    { lat: 48.6310017, lng: 19.2211662 },
    { lat: 48.6299546, lng: 19.221631 },
    { lat: 48.6297219, lng: 19.2216654 },
    { lat: 48.629056, lng: 19.222786 },
    { lat: 48.629754, lng: 19.223809 },
    { lat: 48.630178, lng: 19.224236 },
    { lat: 48.631613, lng: 19.224351 },
    { lat: 48.631956, lng: 19.224446 },
    { lat: 48.633356, lng: 19.224949 },
    { lat: 48.633899, lng: 19.225324 },
    { lat: 48.634839, lng: 19.22608 },
    { lat: 48.635941, lng: 19.226792 },
    { lat: 48.635984, lng: 19.22682 },
    { lat: 48.636433, lng: 19.22711 },
    { lat: 48.637455, lng: 19.228086 },
    { lat: 48.63777, lng: 19.228389 },
    { lat: 48.638515, lng: 19.229102 },
    { lat: 48.638971, lng: 19.229444 },
    { lat: 48.639191, lng: 19.229609 },
    { lat: 48.639196, lng: 19.229612 },
    { lat: 48.639931, lng: 19.230163 },
    { lat: 48.640469, lng: 19.230341 },
    { lat: 48.641202, lng: 19.230483 },
    { lat: 48.641252, lng: 19.230682 },
    { lat: 48.642419, lng: 19.231165 },
    { lat: 48.643315, lng: 19.231277 },
    { lat: 48.643414, lng: 19.231267 },
    { lat: 48.643636, lng: 19.231247 },
    { lat: 48.644632, lng: 19.229544 },
    { lat: 48.644637, lng: 19.229537 },
    { lat: 48.645933, lng: 19.229575 },
    { lat: 48.64656, lng: 19.230153 },
    { lat: 48.646569, lng: 19.230162 },
    { lat: 48.646953, lng: 19.230624 },
    { lat: 48.647058, lng: 19.230566 },
    { lat: 48.647164, lng: 19.230508 },
    { lat: 48.647171, lng: 19.230504 },
    { lat: 48.647314, lng: 19.230489 },
    { lat: 48.647402, lng: 19.230403 },
    { lat: 48.647546, lng: 19.23026 },
    { lat: 48.647678, lng: 19.230128 },
    { lat: 48.647749, lng: 19.230149 },
    { lat: 48.647777, lng: 19.230121 },
    { lat: 48.647819, lng: 19.230079 },
    { lat: 48.64798, lng: 19.229863 },
    { lat: 48.64805, lng: 19.229761 },
    { lat: 48.648121, lng: 19.229649 },
    { lat: 48.648256, lng: 19.229398 },
    { lat: 48.648366, lng: 19.229233 },
    { lat: 48.648407, lng: 19.229184 },
    { lat: 48.648558, lng: 19.229002 },
    { lat: 48.648624, lng: 19.228903 },
    { lat: 48.648655, lng: 19.228859 },
    { lat: 48.648803, lng: 19.228624 },
    { lat: 48.648929, lng: 19.228494 },
    { lat: 48.649047, lng: 19.228372 },
    { lat: 48.64913, lng: 19.228286 },
    { lat: 48.649138, lng: 19.228277 },
    { lat: 48.649441, lng: 19.227942 },
    { lat: 48.649664, lng: 19.227731 },
    { lat: 48.649956, lng: 19.227456 },
    { lat: 48.650152, lng: 19.227197 },
    { lat: 48.650375, lng: 19.226904 },
    { lat: 48.650454, lng: 19.226644 },
    { lat: 48.650486, lng: 19.226544 },
    { lat: 48.650732, lng: 19.226253 },
    { lat: 48.65078, lng: 19.22601 },
    { lat: 48.650848, lng: 19.225874 },
    { lat: 48.650853, lng: 19.225794 },
    { lat: 48.650825, lng: 19.225596 },
    { lat: 48.650806, lng: 19.225271 },
    { lat: 48.650817, lng: 19.225026 },
    { lat: 48.650744, lng: 19.224815 },
    { lat: 48.650851, lng: 19.22453 },
    { lat: 48.650968, lng: 19.224321 },
    { lat: 48.651086, lng: 19.224043 },
    { lat: 48.651137, lng: 19.223921 },
    { lat: 48.651316, lng: 19.223799 },
    { lat: 48.651512, lng: 19.223629 },
    { lat: 48.651693, lng: 19.22369 },
    { lat: 48.651815, lng: 19.22359 },
    { lat: 48.651956, lng: 19.22321 },
    { lat: 48.652116, lng: 19.22287 },
    { lat: 48.652246, lng: 19.22273 },
    { lat: 48.652212, lng: 19.222461 },
    { lat: 48.652373, lng: 19.22201 },
    { lat: 48.652522, lng: 19.221367 },
    { lat: 48.652625, lng: 19.221242 },
    { lat: 48.652825, lng: 19.221003 },
    { lat: 48.652934, lng: 19.22044 },
    { lat: 48.65312, lng: 19.220052 },
    { lat: 48.653053, lng: 19.219721 },
    { lat: 48.65325, lng: 19.219325 },
    { lat: 48.653241, lng: 19.219142 },
    { lat: 48.653415, lng: 19.219279 },
    { lat: 48.653552, lng: 19.219081 },
    { lat: 48.653569, lng: 19.219057 },
    { lat: 48.653673, lng: 19.218905 },
    { lat: 48.653846, lng: 19.218393 },
    { lat: 48.654008, lng: 19.217917 },
    { lat: 48.654013, lng: 19.217877 },
    { lat: 48.653907, lng: 19.21778 },
    { lat: 48.653763, lng: 19.217601 },
    { lat: 48.653636, lng: 19.217438 },
    { lat: 48.653523, lng: 19.217275 },
    { lat: 48.653512, lng: 19.21727 },
    { lat: 48.65337, lng: 19.217199 },
    { lat: 48.653236, lng: 19.21722 },
    { lat: 48.653128, lng: 19.217212 },
    { lat: 48.653014, lng: 19.217178 },
    { lat: 48.653005, lng: 19.217214 },
    { lat: 48.653003, lng: 19.217229 },
    { lat: 48.652968, lng: 19.217425 },
    { lat: 48.652942, lng: 19.217424 },
    { lat: 48.652851, lng: 19.217421 },
    { lat: 48.652743, lng: 19.217423 },
    { lat: 48.652826, lng: 19.217144 },
    { lat: 48.652839, lng: 19.217102 },
    { lat: 48.652737, lng: 19.217071 },
    { lat: 48.652717, lng: 19.217011 },
    { lat: 48.652768, lng: 19.216778 },
    { lat: 48.652691, lng: 19.216623 },
    { lat: 48.652578, lng: 19.216467 },
    { lat: 48.652524, lng: 19.216458 },
    { lat: 48.652436, lng: 19.216428 },
    { lat: 48.652327, lng: 19.216438 },
    { lat: 48.652233, lng: 19.21654 },
    { lat: 48.65216, lng: 19.216584 },
    { lat: 48.652095, lng: 19.216509 },
    { lat: 48.652042, lng: 19.216487 },
    { lat: 48.651995, lng: 19.216537 },
    { lat: 48.651924, lng: 19.216565 },
    { lat: 48.651753, lng: 19.216544 },
    { lat: 48.651618, lng: 19.216449 },
    { lat: 48.651574, lng: 19.216319 },
    { lat: 48.651647, lng: 19.216208 },
    { lat: 48.651679, lng: 19.216019 },
    { lat: 48.651666, lng: 19.215964 },
    { lat: 48.651544, lng: 19.215866 },
    { lat: 48.651487, lng: 19.215862 },
    { lat: 48.651453, lng: 19.21592 },
    { lat: 48.651358, lng: 19.215866 },
    { lat: 48.651273, lng: 19.215954 },
    { lat: 48.651268, lng: 19.215928 },
    { lat: 48.651239, lng: 19.215889 },
    { lat: 48.651207, lng: 19.215801 },
    { lat: 48.651197, lng: 19.215644 },
    { lat: 48.651122, lng: 19.21558 },
    { lat: 48.651107, lng: 19.215499 },
    { lat: 48.651097, lng: 19.215471 },
    { lat: 48.651118, lng: 19.215403 },
    { lat: 48.651114, lng: 19.215324 },
    { lat: 48.651116, lng: 19.215215 },
    { lat: 48.651139, lng: 19.215134 },
    { lat: 48.651086, lng: 19.21506 },
    { lat: 48.651059, lng: 19.214997 },
    { lat: 48.651053, lng: 19.214873 },
    { lat: 48.651079, lng: 19.21481 },
    { lat: 48.651223, lng: 19.214859 },
    { lat: 48.651314, lng: 19.214763 },
    { lat: 48.651354, lng: 19.214819 },
    { lat: 48.651415, lng: 19.214857 },
    { lat: 48.651462, lng: 19.214787 },
    { lat: 48.651445, lng: 19.214732 },
    { lat: 48.651404, lng: 19.214701 },
    { lat: 48.651403, lng: 19.214592 },
    { lat: 48.651465, lng: 19.214569 },
    { lat: 48.651438, lng: 19.214501 },
    { lat: 48.651443, lng: 19.214415 },
    { lat: 48.65145, lng: 19.214317 },
    { lat: 48.65145, lng: 19.214275 },
    { lat: 48.651427, lng: 19.21427 },
    { lat: 48.6514, lng: 19.214278 },
    { lat: 48.651382, lng: 19.214308 },
    { lat: 48.651371, lng: 19.214375 },
    { lat: 48.651263, lng: 19.214245 },
    { lat: 48.651313, lng: 19.214129 },
    { lat: 48.651399, lng: 19.214159 },
    { lat: 48.651417, lng: 19.214171 },
    { lat: 48.651499, lng: 19.214175 },
    { lat: 48.651514, lng: 19.214122 },
    { lat: 48.651563, lng: 19.214076 },
    { lat: 48.651619, lng: 19.213959 },
    { lat: 48.651653, lng: 19.214056 },
    { lat: 48.651736, lng: 19.214106 },
    { lat: 48.651797, lng: 19.214032 },
    { lat: 48.65183, lng: 19.213933 },
    { lat: 48.651804, lng: 19.213838 },
    { lat: 48.651832, lng: 19.213664 },
    { lat: 48.651872, lng: 19.21365 },
    { lat: 48.651925, lng: 19.213591 },
    { lat: 48.651917, lng: 19.213468 },
    { lat: 48.651952, lng: 19.21337 },
    { lat: 48.652032, lng: 19.21339 },
    { lat: 48.652145, lng: 19.213199 },
    { lat: 48.652066, lng: 19.213146 },
    { lat: 48.652111, lng: 19.213046 },
    { lat: 48.652194, lng: 19.213091 },
    { lat: 48.652241, lng: 19.212984 },
    { lat: 48.652223, lng: 19.212925 },
    { lat: 48.65218, lng: 19.212972 },
    { lat: 48.652139, lng: 19.212892 },
    { lat: 48.652198, lng: 19.212857 },
    { lat: 48.652257, lng: 19.212707 },
    { lat: 48.652347, lng: 19.212734 },
    { lat: 48.652367, lng: 19.212606 },
    { lat: 48.652428, lng: 19.212513 },
    { lat: 48.652531, lng: 19.212557 },
    { lat: 48.652619, lng: 19.212549 },
    { lat: 48.652735, lng: 19.212471 },
    { lat: 48.652837, lng: 19.212389 },
    { lat: 48.652968, lng: 19.212326 },
    { lat: 48.653007, lng: 19.212216 },
    { lat: 48.653045, lng: 19.212139 },
    { lat: 48.653183, lng: 19.21212 },
    { lat: 48.653259, lng: 19.212078 },
    { lat: 48.653265, lng: 19.212076 },
    { lat: 48.653278, lng: 19.21198 },
    { lat: 48.653331, lng: 19.211865 },
    { lat: 48.653416, lng: 19.211734 },
    { lat: 48.65358, lng: 19.211563 },
    { lat: 48.653616, lng: 19.211638 },
    { lat: 48.653703, lng: 19.211554 },
    { lat: 48.653769, lng: 19.211507 },
    { lat: 48.653807, lng: 19.211578 },
    { lat: 48.653927, lng: 19.211485 },
    { lat: 48.653932, lng: 19.211469 },
    { lat: 48.653951, lng: 19.211402 },
    { lat: 48.653938, lng: 19.211352 },
    { lat: 48.653853, lng: 19.211345 },
    { lat: 48.653823, lng: 19.211261 },
    { lat: 48.653859, lng: 19.211107 },
    { lat: 48.653957, lng: 19.210993 },
    { lat: 48.654028, lng: 19.211041 },
    { lat: 48.654023, lng: 19.211142 },
    { lat: 48.654037, lng: 19.211165 },
    { lat: 48.654044, lng: 19.211167 },
    { lat: 48.654076, lng: 19.211124 },
    { lat: 48.654158, lng: 19.210945 },
    { lat: 48.654215, lng: 19.210996 },
    { lat: 48.654255, lng: 19.210976 },
    { lat: 48.654297, lng: 19.211033 },
    { lat: 48.654389, lng: 19.210956 },
    { lat: 48.654376, lng: 19.210793 },
    { lat: 48.654311, lng: 19.210721 },
    { lat: 48.65436, lng: 19.210512 },
    { lat: 48.654499, lng: 19.210498 },
    { lat: 48.6545, lng: 19.210598 },
    { lat: 48.654531, lng: 19.210633 },
    { lat: 48.654535, lng: 19.210741 },
    { lat: 48.654633, lng: 19.210726 },
    { lat: 48.65463, lng: 19.210669 },
    { lat: 48.654708, lng: 19.210641 },
    { lat: 48.65478, lng: 19.210519 },
    { lat: 48.654878, lng: 19.21062 },
    { lat: 48.654932, lng: 19.210549 },
    { lat: 48.65499, lng: 19.210548 },
    { lat: 48.654996, lng: 19.210532 },
    { lat: 48.655115, lng: 19.210254 },
    { lat: 48.65505, lng: 19.210176 },
    { lat: 48.655029, lng: 19.210178 },
    { lat: 48.654941, lng: 19.210181 },
    { lat: 48.65493, lng: 19.210147 },
    { lat: 48.654972, lng: 19.210076 },
    { lat: 48.655046, lng: 19.209996 },
    { lat: 48.655037, lng: 19.209981 },
    { lat: 48.655099, lng: 19.209901 },
    { lat: 48.655164, lng: 19.209919 },
    { lat: 48.655201, lng: 19.209889 },
    { lat: 48.655189, lng: 19.209781 },
    { lat: 48.655261, lng: 19.20974 },
    { lat: 48.655273, lng: 19.209848 },
    { lat: 48.655315, lng: 19.209886 },
    { lat: 48.655329, lng: 19.209874 },
    { lat: 48.655329, lng: 19.209861 },
    { lat: 48.655334, lng: 19.209769 },
    { lat: 48.655483, lng: 19.209592 },
    { lat: 48.655473, lng: 19.209543 },
    { lat: 48.655425, lng: 19.209526 },
    { lat: 48.655411, lng: 19.209508 },
    { lat: 48.655394, lng: 19.209511 },
    { lat: 48.655267, lng: 19.209379 },
    { lat: 48.655425, lng: 19.209084 },
    { lat: 48.655445, lng: 19.209092 },
    { lat: 48.655545, lng: 19.208855 },
    { lat: 48.655592, lng: 19.208919 },
    { lat: 48.655576, lng: 19.208983 },
    { lat: 48.655645, lng: 19.209151 },
    { lat: 48.655716, lng: 19.209149 },
    { lat: 48.655752, lng: 19.209092 },
    { lat: 48.655748, lng: 19.208983 },
    { lat: 48.655797, lng: 19.208966 },
    { lat: 48.655888, lng: 19.209029 },
    { lat: 48.655925, lng: 19.209067 },
    { lat: 48.655919, lng: 19.209138 },
    { lat: 48.655951, lng: 19.209155 },
    { lat: 48.656008, lng: 19.209021 },
    { lat: 48.656066, lng: 19.20898 },
    { lat: 48.656162, lng: 19.209079 },
    { lat: 48.656226, lng: 19.20901 },
    { lat: 48.656198, lng: 19.208914 },
    { lat: 48.656266, lng: 19.208787 },
    { lat: 48.656206, lng: 19.208663 },
    { lat: 48.65624, lng: 19.20862 },
    { lat: 48.656261, lng: 19.208517 },
    { lat: 48.656369, lng: 19.208538 },
    { lat: 48.656417, lng: 19.208488 },
    { lat: 48.65645, lng: 19.208379 },
    { lat: 48.656471, lng: 19.208312 },
    { lat: 48.656572, lng: 19.208063 },
    { lat: 48.656585, lng: 19.208035 },
    { lat: 48.656677, lng: 19.207898 },
    { lat: 48.656696, lng: 19.207882 },
    { lat: 48.656811, lng: 19.207778 },
    { lat: 48.656861, lng: 19.207751 },
    { lat: 48.6569, lng: 19.20773 },
    { lat: 48.656927, lng: 19.207729 },
    { lat: 48.656961, lng: 19.207762 },
    { lat: 48.657043, lng: 19.207867 },
    { lat: 48.657141, lng: 19.207994 },
    { lat: 48.657185, lng: 19.207972 },
    { lat: 48.657286, lng: 19.207922 },
    { lat: 48.657376, lng: 19.207877 },
    { lat: 48.657404, lng: 19.207863 },
    { lat: 48.657472, lng: 19.20785 },
    { lat: 48.657522, lng: 19.207841 },
    { lat: 48.657572, lng: 19.207844 },
    { lat: 48.65767, lng: 19.207848 },
    { lat: 48.657774, lng: 19.207854 },
    { lat: 48.657815, lng: 19.207856 },
    { lat: 48.657834, lng: 19.207841 },
    { lat: 48.657869, lng: 19.207837 },
    { lat: 48.657873, lng: 19.207836 },
    { lat: 48.657884, lng: 19.207792 },
    { lat: 48.657956, lng: 19.20776 },
    { lat: 48.658018, lng: 19.207731 },
    { lat: 48.658048, lng: 19.207716 },
    { lat: 48.658055, lng: 19.207713 },
    { lat: 48.658092, lng: 19.207665 },
    { lat: 48.658104, lng: 19.207646 },
    { lat: 48.658146, lng: 19.207584 },
    { lat: 48.658254, lng: 19.207425 },
    { lat: 48.658286, lng: 19.207377 },
    { lat: 48.658334, lng: 19.20731 },
    { lat: 48.658356, lng: 19.207223 },
    { lat: 48.658414, lng: 19.207014 },
    { lat: 48.658528, lng: 19.207045 },
    { lat: 48.658549, lng: 19.20705 },
    { lat: 48.658589, lng: 19.207069 },
    { lat: 48.658607, lng: 19.207052 },
    { lat: 48.658622, lng: 19.207 },
    { lat: 48.658807, lng: 19.206702 },
    { lat: 48.658867, lng: 19.206604 },
    { lat: 48.658879, lng: 19.206563 },
    { lat: 48.658886, lng: 19.206538 },
    { lat: 48.658903, lng: 19.206523 },
    { lat: 48.659044, lng: 19.206314 },
    { lat: 48.659122, lng: 19.206125 },
    { lat: 48.659124, lng: 19.20612 },
    { lat: 48.659199, lng: 19.206046 },
    { lat: 48.659551, lng: 19.205698 },
    { lat: 48.659752, lng: 19.205475 },
    { lat: 48.659893, lng: 19.205283 },
    { lat: 48.659755, lng: 19.204972 },
    { lat: 48.659646, lng: 19.204733 },
    { lat: 48.65952, lng: 19.204431 },
    { lat: 48.65949, lng: 19.204314 },
    { lat: 48.659474, lng: 19.204253 },
    { lat: 48.659453, lng: 19.204113 },
    { lat: 48.659395, lng: 19.203775 },
    { lat: 48.659326, lng: 19.20341 },
    { lat: 48.65926, lng: 19.203095 },
    { lat: 48.659187, lng: 19.202901 },
    { lat: 48.659099, lng: 19.20267 },
    { lat: 48.659076, lng: 19.202609 },
    { lat: 48.65903, lng: 19.202488 },
    { lat: 48.658876, lng: 19.202087 },
    { lat: 48.658779, lng: 19.201791 },
    { lat: 48.658747, lng: 19.201731 },
    { lat: 48.658629, lng: 19.201203 },
    { lat: 48.658617, lng: 19.201157 },
    { lat: 48.658542, lng: 19.200973 },
    { lat: 48.65832, lng: 19.20044 },
    { lat: 48.657926, lng: 19.199496 },
    { lat: 48.657882, lng: 19.199389 },
    { lat: 48.657861, lng: 19.199328 },
    { lat: 48.657861, lng: 19.199258 },
    { lat: 48.65748, lng: 19.19786 },
    { lat: 48.65723, lng: 19.197946 },
    { lat: 48.65702, lng: 19.197996 },
    { lat: 48.656751, lng: 19.198021 },
    { lat: 48.656328, lng: 19.197917 },
    { lat: 48.65573, lng: 19.197743 },
    { lat: 48.655666, lng: 19.197718 },
    { lat: 48.655296, lng: 19.197569 },
    { lat: 48.655215, lng: 19.19755 },
    { lat: 48.655181, lng: 19.197542 },
    { lat: 48.655174, lng: 19.19754 },
    { lat: 48.655115, lng: 19.197526 },
    { lat: 48.65506, lng: 19.197436 },
    { lat: 48.655048, lng: 19.197429 },
    { lat: 48.654963, lng: 19.197371 },
    { lat: 48.654929, lng: 19.197348 },
    { lat: 48.654854, lng: 19.197299 },
    { lat: 48.654782, lng: 19.197024 },
    { lat: 48.654779, lng: 19.196723 },
    { lat: 48.654814, lng: 19.196164 },
    { lat: 48.654817, lng: 19.196121 },
    { lat: 48.654817, lng: 19.196111 },
    { lat: 48.65479, lng: 19.195588 },
    { lat: 48.654628, lng: 19.19456 },
    { lat: 48.654577, lng: 19.19418 },
    { lat: 48.654366, lng: 19.194189 },
    { lat: 48.654128, lng: 19.194142 },
    { lat: 48.654141, lng: 19.193683 },
    { lat: 48.654022, lng: 19.193603 },
    { lat: 48.653753, lng: 19.19329 },
    { lat: 48.653741, lng: 19.193275 },
    { lat: 48.653335, lng: 19.192668 },
    { lat: 48.653253, lng: 19.19277 },
    { lat: 48.652845, lng: 19.193726 },
    { lat: 48.652746, lng: 19.193866 },
    { lat: 48.652622, lng: 19.194103 },
    { lat: 48.652599, lng: 19.194183 },
    { lat: 48.652585, lng: 19.194188 },
    { lat: 48.652371, lng: 19.194274 },
    { lat: 48.651977, lng: 19.194469 },
    { lat: 48.651784, lng: 19.194566 },
    { lat: 48.65116, lng: 19.1949819 },
    { lat: 48.6503867, lng: 19.1946117 },
    { lat: 48.6503106, lng: 19.1944355 },
    { lat: 48.6493439, lng: 19.1939899 },
    { lat: 48.6487579, lng: 19.1942904 },
    { lat: 48.6474994, lng: 19.1946245 },
    { lat: 48.6469927, lng: 19.1945472 },
    { lat: 48.6468859, lng: 19.194434 },
    { lat: 48.6467394, lng: 19.1944578 },
    { lat: 48.6461758, lng: 19.1942507 },
    { lat: 48.6455198, lng: 19.1935634 },
    { lat: 48.6448479, lng: 19.1931221 },
    { lat: 48.6444864, lng: 19.1931966 },
    { lat: 48.6439347, lng: 19.1934887 },
    { lat: 48.6435171, lng: 19.1935237 },
    { lat: 48.6426722, lng: 19.1933733 },
    { lat: 48.6418122, lng: 19.1933076 },
    { lat: 48.64114, lng: 19.1930674 },
    { lat: 48.6408482, lng: 19.1931181 },
    { lat: 48.6406059, lng: 19.1932783 },
    { lat: 48.6404911, lng: 19.1936761 },
    { lat: 48.6401978, lng: 19.194315 },
    { lat: 48.640246, lng: 19.1945424 },
    { lat: 48.6400391, lng: 19.1948766 },
    { lat: 48.6399605, lng: 19.1951822 },
    { lat: 48.639736, lng: 19.1952952 },
    { lat: 48.6396117, lng: 19.195565 },
    { lat: 48.6394384, lng: 19.1957301 },
    { lat: 48.6393172, lng: 19.1959962 },
    { lat: 48.6394307, lng: 19.1961041 },
    { lat: 48.6394404, lng: 19.196211 },
    { lat: 48.6393999, lng: 19.1963495 },
    { lat: 48.6392686, lng: 19.1964329 },
    { lat: 48.6391952, lng: 19.1968884 },
    { lat: 48.6390027, lng: 19.1972897 },
    { lat: 48.6390356, lng: 19.1975238 },
    { lat: 48.6388485, lng: 19.1976623 },
    { lat: 48.6387295, lng: 19.1976734 },
    { lat: 48.6385973, lng: 19.1975979 },
    { lat: 48.6384388, lng: 19.197144 },
    { lat: 48.6384035, lng: 19.1969768 },
  ],
  ŽeleznáBreznica: [
    { lat: 48.593472, lng: 19.009869 },
    { lat: 48.5937513, lng: 19.0096306 },
    { lat: 48.5940521, lng: 19.0091829 },
    { lat: 48.595072, lng: 19.0084577 },
    { lat: 48.5953482, lng: 19.0082332 },
    { lat: 48.5955015, lng: 19.0080315 },
    { lat: 48.5956499, lng: 19.0079806 },
    { lat: 48.5959799, lng: 19.0076862 },
    { lat: 48.5963696, lng: 19.0074555 },
    { lat: 48.5966792, lng: 19.0074231 },
    { lat: 48.5970717, lng: 19.0072245 },
    { lat: 48.5978064, lng: 19.0065459 },
    { lat: 48.5982267, lng: 19.0057606 },
    { lat: 48.5986861, lng: 19.0054367 },
    { lat: 48.5995856, lng: 19.005568 },
    { lat: 48.6001529, lng: 19.0059526 },
    { lat: 48.6004837, lng: 19.0059392 },
    { lat: 48.6008246, lng: 19.0060553 },
    { lat: 48.6010698, lng: 19.0065593 },
    { lat: 48.6013959, lng: 19.0076357 },
    { lat: 48.6016868, lng: 19.0080803 },
    { lat: 48.6020475, lng: 19.0079672 },
    { lat: 48.6023873, lng: 19.0075947 },
    { lat: 48.6032702, lng: 19.0082021 },
    { lat: 48.6033346, lng: 19.0081345 },
    { lat: 48.603942, lng: 19.0083761 },
    { lat: 48.6041369, lng: 19.0083708 },
    { lat: 48.6042834, lng: 19.0082599 },
    { lat: 48.6044818, lng: 19.0083005 },
    { lat: 48.6047794, lng: 19.0084569 },
    { lat: 48.605326, lng: 19.0091008 },
    { lat: 48.6061099, lng: 19.0096666 },
    { lat: 48.6066145, lng: 19.0098095 },
    { lat: 48.606828, lng: 19.0097949 },
    { lat: 48.6069648, lng: 19.0101822 },
    { lat: 48.6069897, lng: 19.0104501 },
    { lat: 48.6078084, lng: 19.0115277 },
    { lat: 48.6094017, lng: 19.0126436 },
    { lat: 48.6097193, lng: 19.0134412 },
    { lat: 48.6102314, lng: 19.014473 },
    { lat: 48.6101241, lng: 19.0149819 },
    { lat: 48.6106837, lng: 19.0163093 },
    { lat: 48.6104018, lng: 19.0165736 },
    { lat: 48.6105233, lng: 19.0173528 },
    { lat: 48.6099863, lng: 19.0179474 },
    { lat: 48.6100933, lng: 19.0196228 },
    { lat: 48.6098407, lng: 19.0221696 },
    { lat: 48.6097853, lng: 19.0231689 },
    { lat: 48.6096767, lng: 19.0236356 },
    { lat: 48.6109482, lng: 19.024637 },
    { lat: 48.6113508, lng: 19.0247508 },
    { lat: 48.6126154, lng: 19.0247395 },
    { lat: 48.6126803, lng: 19.024887 },
    { lat: 48.6131112, lng: 19.0251597 },
    { lat: 48.6146124, lng: 19.0251743 },
    { lat: 48.6157436, lng: 19.0245974 },
    { lat: 48.6162615, lng: 19.0240257 },
    { lat: 48.6166349, lng: 19.0234013 },
    { lat: 48.6173693, lng: 19.0225831 },
    { lat: 48.6177973, lng: 19.0222404 },
    { lat: 48.618211, lng: 19.0215476 },
    { lat: 48.6182981, lng: 19.0215974 },
    { lat: 48.6186568, lng: 19.0215328 },
    { lat: 48.6190421, lng: 19.0216158 },
    { lat: 48.6194645, lng: 19.0219443 },
    { lat: 48.619972, lng: 19.022684 },
    { lat: 48.6206497, lng: 19.0227338 },
    { lat: 48.6207446, lng: 19.0219771 },
    { lat: 48.6209901, lng: 19.0215006 },
    { lat: 48.6216401, lng: 19.0188738 },
    { lat: 48.6219255, lng: 19.0190798 },
    { lat: 48.6220376, lng: 19.0190648 },
    { lat: 48.6222944, lng: 19.0192424 },
    { lat: 48.622659, lng: 19.0193363 },
    { lat: 48.6234419, lng: 19.0199499 },
    { lat: 48.6236691, lng: 19.0200083 },
    { lat: 48.6238899, lng: 19.0203621 },
    { lat: 48.6245849, lng: 19.0208868 },
    { lat: 48.6251498, lng: 19.0215326 },
    { lat: 48.6255012, lng: 19.0223547 },
    { lat: 48.6257258, lng: 19.022536 },
    { lat: 48.6262068, lng: 19.0231352 },
    { lat: 48.6265564, lng: 19.0234472 },
    { lat: 48.6266275, lng: 19.0237847 },
    { lat: 48.6270882, lng: 19.0244268 },
    { lat: 48.6271755, lng: 19.0247255 },
    { lat: 48.6275179, lng: 19.0250558 },
    { lat: 48.627833, lng: 19.0249783 },
    { lat: 48.6280853, lng: 19.0250631 },
    { lat: 48.6283877, lng: 19.0248192 },
    { lat: 48.6289363, lng: 19.024923 },
    { lat: 48.6290919, lng: 19.0246764 },
    { lat: 48.6294071, lng: 19.0249357 },
    { lat: 48.6300679, lng: 19.0250299 },
    { lat: 48.6302476, lng: 19.0251497 },
    { lat: 48.6304965, lng: 19.025165 },
    { lat: 48.6306454, lng: 19.0252788 },
    { lat: 48.6312578, lng: 19.0254167 },
    { lat: 48.6314972, lng: 19.0256701 },
    { lat: 48.6319621, lng: 19.0257027 },
    { lat: 48.6321515, lng: 19.0255662 },
    { lat: 48.6330627, lng: 19.0259489 },
    { lat: 48.6335661, lng: 19.0257183 },
    { lat: 48.6339771, lng: 19.0257493 },
    { lat: 48.6343077, lng: 19.026024 },
    { lat: 48.6349187, lng: 19.0255319 },
    { lat: 48.6352692, lng: 19.0254838 },
    { lat: 48.6356117, lng: 19.0252324 },
    { lat: 48.6358651, lng: 19.0248466 },
    { lat: 48.6361797, lng: 19.0247374 },
    { lat: 48.6368012, lng: 19.0247645 },
    { lat: 48.6371951, lng: 19.0245728 },
    { lat: 48.6374406, lng: 19.0246341 },
    { lat: 48.6377271, lng: 19.0245253 },
    { lat: 48.6383216, lng: 19.0249337 },
    { lat: 48.6383726, lng: 19.0254084 },
    { lat: 48.6385066, lng: 19.0259196 },
    { lat: 48.6389527, lng: 19.0264281 },
    { lat: 48.6390822, lng: 19.0267137 },
    { lat: 48.6392895, lng: 19.0269783 },
    { lat: 48.6395584, lng: 19.0272377 },
    { lat: 48.6397576, lng: 19.0273236 },
    { lat: 48.6402956, lng: 19.0279053 },
    { lat: 48.6408262, lng: 19.0281671 },
    { lat: 48.6410315, lng: 19.0280609 },
    { lat: 48.6412295, lng: 19.0281637 },
    { lat: 48.6416207, lng: 19.028003 },
    { lat: 48.6416462, lng: 19.0280171 },
    { lat: 48.6422851, lng: 19.0279193 },
    { lat: 48.6424493, lng: 19.0278028 },
    { lat: 48.6429446, lng: 19.0278758 },
    { lat: 48.6430938, lng: 19.0283988 },
    { lat: 48.6443094, lng: 19.0285831 },
    { lat: 48.6447758, lng: 19.0288241 },
    { lat: 48.6449044, lng: 19.0290215 },
    { lat: 48.6449914, lng: 19.0289627 },
    { lat: 48.6453952, lng: 19.0292551 },
    { lat: 48.6454704, lng: 19.0290682 },
    { lat: 48.6456764, lng: 19.0293298 },
    { lat: 48.6463357, lng: 19.0295965 },
    { lat: 48.6470166, lng: 19.030085 },
    { lat: 48.647697, lng: 19.0301649 },
    { lat: 48.6479552, lng: 19.0299207 },
    { lat: 48.6484058, lng: 19.0299596 },
    { lat: 48.6486737, lng: 19.0297898 },
    { lat: 48.649735, lng: 19.0296885 },
    { lat: 48.6503807, lng: 19.0299286 },
    { lat: 48.6512586, lng: 19.0309037 },
    { lat: 48.6514407, lng: 19.0310308 },
    { lat: 48.6516679, lng: 19.0313988 },
    { lat: 48.6526947, lng: 19.0315988 },
    { lat: 48.6530392, lng: 19.0317861 },
    { lat: 48.6533462, lng: 19.0317516 },
    { lat: 48.653469, lng: 19.0318388 },
    { lat: 48.6537021, lng: 19.0318335 },
    { lat: 48.6539979, lng: 19.0319511 },
    { lat: 48.6543207, lng: 19.0318492 },
    { lat: 48.6545016, lng: 19.0319465 },
    { lat: 48.6548841, lng: 19.0317729 },
    { lat: 48.6550214, lng: 19.0315678 },
    { lat: 48.6561222, lng: 19.0313964 },
    { lat: 48.6563554, lng: 19.031171 },
    { lat: 48.6571346, lng: 19.0309501 },
    { lat: 48.6576527, lng: 19.0307153 },
    { lat: 48.6581273, lng: 19.0306252 },
    { lat: 48.6588411, lng: 19.0302013 },
    { lat: 48.6591397, lng: 19.029839 },
    { lat: 48.6592851, lng: 19.0298163 },
    { lat: 48.6605389, lng: 19.0289907 },
    { lat: 48.6610658, lng: 19.0285515 },
    { lat: 48.6617229, lng: 19.0282093 },
    { lat: 48.6621637, lng: 19.0278706 },
    { lat: 48.6623979, lng: 19.0274646 },
    { lat: 48.6631725, lng: 19.026971 },
    { lat: 48.6645804, lng: 19.0265641 },
    { lat: 48.6667871, lng: 19.0257391 },
    { lat: 48.667108, lng: 19.0255525 },
    { lat: 48.6676088, lng: 19.024863 },
    { lat: 48.6678526, lng: 19.0243317 },
    { lat: 48.6683719, lng: 19.0236283 },
    { lat: 48.6688533, lng: 19.0232709 },
    { lat: 48.6704765, lng: 19.0232878 },
    { lat: 48.6708592, lng: 19.0228754 },
    { lat: 48.6707019, lng: 19.0220777 },
    { lat: 48.6708219, lng: 19.0217486 },
    { lat: 48.6708339, lng: 19.0212271 },
    { lat: 48.6712541, lng: 19.0200353 },
    { lat: 48.672069, lng: 19.0183065 },
    { lat: 48.6722168, lng: 19.0177045 },
    { lat: 48.672668, lng: 19.0170394 },
    { lat: 48.6730493, lng: 19.0170009 },
    { lat: 48.6730956, lng: 19.0172721 },
    { lat: 48.67382, lng: 19.017014 },
    { lat: 48.674799, lng: 19.018168 },
    { lat: 48.674857, lng: 19.018126 },
    { lat: 48.674887, lng: 19.01785 },
    { lat: 48.674978, lng: 19.017339 },
    { lat: 48.675026, lng: 19.016858 },
    { lat: 48.675273, lng: 19.015486 },
    { lat: 48.675498, lng: 19.015105 },
    { lat: 48.675866, lng: 19.014276 },
    { lat: 48.675945, lng: 19.014123 },
    { lat: 48.675922, lng: 19.01404 },
    { lat: 48.675555, lng: 19.013987 },
    { lat: 48.675114, lng: 19.013346 },
    { lat: 48.674735, lng: 19.012831 },
    { lat: 48.674416, lng: 19.012395 },
    { lat: 48.673727, lng: 19.011718 },
    { lat: 48.673395, lng: 19.01125 },
    { lat: 48.671847, lng: 19.009592 },
    { lat: 48.670589, lng: 19.008594 },
    { lat: 48.670011, lng: 19.008133 },
    { lat: 48.668999, lng: 19.007279 },
    { lat: 48.668773, lng: 19.007061 },
    { lat: 48.668475, lng: 19.006682 },
    { lat: 48.668325, lng: 19.006358 },
    { lat: 48.668263, lng: 19.006132 },
    { lat: 48.66826, lng: 19.005655 },
    { lat: 48.668245, lng: 19.005322 },
    { lat: 48.668177, lng: 19.004918 },
    { lat: 48.668096, lng: 19.004237 },
    { lat: 48.667963, lng: 19.003677 },
    { lat: 48.667837, lng: 19.003385 },
    { lat: 48.667569, lng: 19.003004 },
    { lat: 48.667452, lng: 19.002927 },
    { lat: 48.667199, lng: 19.002713 },
    { lat: 48.667042, lng: 19.002481 },
    { lat: 48.66694, lng: 19.002221 },
    { lat: 48.666885, lng: 19.001854 },
    { lat: 48.666849, lng: 19.001321 },
    { lat: 48.666836, lng: 19.00122 },
    { lat: 48.666788, lng: 19.000924 },
    { lat: 48.666701, lng: 19.000723 },
    { lat: 48.666511, lng: 19.000483 },
    { lat: 48.666244, lng: 19.000306 },
    { lat: 48.665543, lng: 19.000169 },
    { lat: 48.665151, lng: 18.999978 },
    { lat: 48.665044, lng: 18.999915 },
    { lat: 48.664945, lng: 18.999823 },
    { lat: 48.664831, lng: 18.999653 },
    { lat: 48.664764, lng: 18.999462 },
    { lat: 48.664703, lng: 18.998945 },
    { lat: 48.664643, lng: 18.998656 },
    { lat: 48.664496, lng: 18.998232 },
    { lat: 48.664405, lng: 18.998034 },
    { lat: 48.66426, lng: 18.997786 },
    { lat: 48.664054, lng: 18.997534 },
    { lat: 48.663973, lng: 18.997407 },
    { lat: 48.66387, lng: 18.997245 },
    { lat: 48.663747, lng: 18.996939 },
    { lat: 48.663667, lng: 18.996823 },
    { lat: 48.663566, lng: 18.996742 },
    { lat: 48.663278, lng: 18.99659 },
    { lat: 48.663191, lng: 18.996513 },
    { lat: 48.662911, lng: 18.996219 },
    { lat: 48.66284, lng: 18.996151 },
    { lat: 48.662639, lng: 18.995968 },
    { lat: 48.662497, lng: 18.995921 },
    { lat: 48.662118, lng: 18.99589 },
    { lat: 48.661271, lng: 18.99594 },
    { lat: 48.661066, lng: 18.995806 },
    { lat: 48.660999, lng: 18.995733 },
    { lat: 48.660526, lng: 18.99519 },
    { lat: 48.660341, lng: 18.994976 },
    { lat: 48.659515, lng: 18.99424 },
    { lat: 48.659216, lng: 18.993997 },
    { lat: 48.65892, lng: 18.993721 },
    { lat: 48.65867, lng: 18.993449 },
    { lat: 48.658411, lng: 18.993131 },
    { lat: 48.658328, lng: 18.993035 },
    { lat: 48.657962, lng: 18.992615 },
    { lat: 48.657537, lng: 18.992114 },
    { lat: 48.657022, lng: 18.991486 },
    { lat: 48.656722, lng: 18.991067 },
    { lat: 48.656448, lng: 18.990741 },
    { lat: 48.656349, lng: 18.990645 },
    { lat: 48.655972, lng: 18.99058 },
    { lat: 48.655555, lng: 18.990589 },
    { lat: 48.655173, lng: 18.990671 },
    { lat: 48.654814, lng: 18.990708 },
    { lat: 48.654712, lng: 18.990693 },
    { lat: 48.654449, lng: 18.99056 },
    { lat: 48.65412, lng: 18.990361 },
    { lat: 48.654012, lng: 18.990295 },
    { lat: 48.653877, lng: 18.990242 },
    { lat: 48.653748, lng: 18.990231 },
    { lat: 48.653593, lng: 18.990239 },
    { lat: 48.65336, lng: 18.990315 },
    { lat: 48.653157, lng: 18.990418 },
    { lat: 48.652997, lng: 18.990537 },
    { lat: 48.652824, lng: 18.990623 },
    { lat: 48.652394, lng: 18.990653 },
    { lat: 48.652133, lng: 18.990608 },
    { lat: 48.651918, lng: 18.990528 },
    { lat: 48.651718, lng: 18.990373 },
    { lat: 48.651455, lng: 18.990147 },
    { lat: 48.651281, lng: 18.990039 },
    { lat: 48.651156, lng: 18.98999 },
    { lat: 48.651058, lng: 18.98998 },
    { lat: 48.65076, lng: 18.989982 },
    { lat: 48.650364, lng: 18.990049 },
    { lat: 48.650243, lng: 18.990058 },
    { lat: 48.650143, lng: 18.99003 },
    { lat: 48.649512, lng: 18.989735 },
    { lat: 48.649386, lng: 18.989642 },
    { lat: 48.649204, lng: 18.98949 },
    { lat: 48.648847, lng: 18.989141 },
    { lat: 48.648741, lng: 18.988976 },
    { lat: 48.648608, lng: 18.988713 },
    { lat: 48.648564, lng: 18.988639 },
    { lat: 48.648453, lng: 18.98858 },
    { lat: 48.648419, lng: 18.98862 },
    { lat: 48.648369, lng: 18.988596 },
    { lat: 48.648352, lng: 18.989018 },
    { lat: 48.648329, lng: 18.989199 },
    { lat: 48.648282, lng: 18.989575 },
    { lat: 48.648236, lng: 18.989751 },
    { lat: 48.648105, lng: 18.989981 },
    { lat: 48.647907, lng: 18.990262 },
    { lat: 48.64781, lng: 18.990374 },
    { lat: 48.647719, lng: 18.990481 },
    { lat: 48.647597, lng: 18.990584 },
    { lat: 48.647208, lng: 18.991098 },
    { lat: 48.646676, lng: 18.991887 },
    { lat: 48.646442, lng: 18.992245 },
    { lat: 48.645962, lng: 18.993011 },
    { lat: 48.645819, lng: 18.993213 },
    { lat: 48.645417, lng: 18.993746 },
    { lat: 48.645199, lng: 18.99402 },
    { lat: 48.644979, lng: 18.994312 },
    { lat: 48.644781, lng: 18.994578 },
    { lat: 48.644638, lng: 18.994808 },
    { lat: 48.644339, lng: 18.995299 },
    { lat: 48.644196, lng: 18.995492 },
    { lat: 48.644096, lng: 18.995605 },
    { lat: 48.643527, lng: 18.996036 },
    { lat: 48.643305, lng: 18.996207 },
    { lat: 48.643077, lng: 18.996399 },
    { lat: 48.642918, lng: 18.996543 },
    { lat: 48.64281, lng: 18.996667 },
    { lat: 48.642717, lng: 18.996811 },
    { lat: 48.642575, lng: 18.99701 },
    { lat: 48.642384, lng: 18.997241 },
    { lat: 48.642246, lng: 18.997344 },
    { lat: 48.642072, lng: 18.997447 },
    { lat: 48.641918, lng: 18.997481 },
    { lat: 48.641446, lng: 18.997434 },
    { lat: 48.640575, lng: 18.997689 },
    { lat: 48.639827, lng: 18.997239 },
    { lat: 48.639008, lng: 18.995921 },
    { lat: 48.637842, lng: 18.995663 },
    { lat: 48.635504, lng: 18.994108 },
    { lat: 48.634525, lng: 18.993061 },
    { lat: 48.634438, lng: 18.992838 },
    { lat: 48.63374, lng: 18.992139 },
    { lat: 48.631957, lng: 18.990512 },
    { lat: 48.631786, lng: 18.990456 },
    { lat: 48.629906, lng: 18.989825 },
    { lat: 48.629323, lng: 18.989952 },
    { lat: 48.629181, lng: 18.99 },
    { lat: 48.628419, lng: 18.9898 },
    { lat: 48.628219, lng: 18.989674 },
    { lat: 48.627688, lng: 18.989564 },
    { lat: 48.627252, lng: 18.989558 },
    { lat: 48.626211, lng: 18.989679 },
    { lat: 48.625114, lng: 18.989108 },
    { lat: 48.624222, lng: 18.988557 },
    { lat: 48.623288, lng: 18.988472 },
    { lat: 48.62291, lng: 18.988384 },
    { lat: 48.622485, lng: 18.988294 },
    { lat: 48.62204, lng: 18.988303 },
    { lat: 48.621483, lng: 18.988383 },
    { lat: 48.620911, lng: 18.988216 },
    { lat: 48.619399, lng: 18.988 },
    { lat: 48.61872, lng: 18.987949 },
    { lat: 48.618403, lng: 18.987972 },
    { lat: 48.617977, lng: 18.987998 },
    { lat: 48.61692, lng: 18.988083 },
    { lat: 48.616495, lng: 18.988164 },
    { lat: 48.616291, lng: 18.988227 },
    { lat: 48.615959, lng: 18.988241 },
    { lat: 48.615846, lng: 18.988184 },
    { lat: 48.615674, lng: 18.988101 },
    { lat: 48.615048, lng: 18.987874 },
    { lat: 48.614322, lng: 18.987245 },
    { lat: 48.613814, lng: 18.986701 },
    { lat: 48.613661, lng: 18.986541 },
    { lat: 48.613304, lng: 18.98638 },
    { lat: 48.612898, lng: 18.986212 },
    { lat: 48.612572, lng: 18.98621 },
    { lat: 48.611965, lng: 18.985802 },
    { lat: 48.611611, lng: 18.985793 },
    { lat: 48.61152, lng: 18.985983 },
    { lat: 48.611242, lng: 18.986245 },
    { lat: 48.611009, lng: 18.986563 },
    { lat: 48.610641, lng: 18.986697 },
    { lat: 48.610532, lng: 18.986578 },
    { lat: 48.610353, lng: 18.986638 },
    { lat: 48.608862, lng: 18.987367 },
    { lat: 48.608677, lng: 18.987469 },
    { lat: 48.608358, lng: 18.987775 },
    { lat: 48.608332, lng: 18.987779 },
    { lat: 48.607682, lng: 18.987876 },
    { lat: 48.606128, lng: 18.988502 },
    { lat: 48.605281, lng: 18.988819 },
    { lat: 48.604935, lng: 18.989008 },
    { lat: 48.604407, lng: 18.989371 },
    { lat: 48.603342, lng: 18.989921 },
    { lat: 48.602622, lng: 18.989774 },
    { lat: 48.602431, lng: 18.991467 },
    { lat: 48.601931, lng: 18.992338 },
    { lat: 48.601735, lng: 18.992636 },
    { lat: 48.601533, lng: 18.993252 },
    { lat: 48.601168, lng: 18.993522 },
    { lat: 48.600709, lng: 18.993372 },
    { lat: 48.600347, lng: 18.993651 },
    { lat: 48.600032, lng: 18.994075 },
    { lat: 48.599014, lng: 18.995159 },
    { lat: 48.598307, lng: 18.996057 },
    { lat: 48.598267, lng: 18.996142 },
    { lat: 48.598144, lng: 18.996544 },
    { lat: 48.597049, lng: 18.997283 },
    { lat: 48.596554, lng: 18.997749 },
    { lat: 48.59654, lng: 18.997868 },
    { lat: 48.595189, lng: 18.999208 },
    { lat: 48.594598, lng: 18.999591 },
    { lat: 48.594245, lng: 19.000267 },
    { lat: 48.594232, lng: 19.000531 },
    { lat: 48.594177, lng: 19.000798 },
    { lat: 48.594211, lng: 19.0012 },
    { lat: 48.594293, lng: 19.001573 },
    { lat: 48.594298, lng: 19.001806 },
    { lat: 48.594434, lng: 19.00222 },
    { lat: 48.594548, lng: 19.002567 },
    { lat: 48.594574, lng: 19.002758 },
    { lat: 48.594539, lng: 19.002965 },
    { lat: 48.594441, lng: 19.003165 },
    { lat: 48.59443, lng: 19.003306 },
    { lat: 48.594437, lng: 19.003462 },
    { lat: 48.594405, lng: 19.003609 },
    { lat: 48.594361, lng: 19.003693 },
    { lat: 48.594321, lng: 19.003922 },
    { lat: 48.59426, lng: 19.004117 },
    { lat: 48.594144, lng: 19.004517 },
    { lat: 48.594144, lng: 19.004732 },
    { lat: 48.594084, lng: 19.004958 },
    { lat: 48.593979, lng: 19.005211 },
    { lat: 48.59393, lng: 19.005477 },
    { lat: 48.593905, lng: 19.005765 },
    { lat: 48.593832, lng: 19.006008 },
    { lat: 48.593678, lng: 19.006349 },
    { lat: 48.593539, lng: 19.006673 },
    { lat: 48.593452, lng: 19.006989 },
    { lat: 48.593396, lng: 19.007298 },
    { lat: 48.593368, lng: 19.007547 },
    { lat: 48.593333, lng: 19.007896 },
    { lat: 48.593272, lng: 19.008398 },
    { lat: 48.59327, lng: 19.00877 },
    { lat: 48.593237, lng: 19.009072 },
    { lat: 48.593215, lng: 19.00929 },
    { lat: 48.593233, lng: 19.009429 },
    { lat: 48.593301, lng: 19.00951 },
    { lat: 48.593423, lng: 19.009617 },
    { lat: 48.593494, lng: 19.009755 },
    { lat: 48.593481, lng: 19.00983 },
    { lat: 48.593472, lng: 19.009869 },
  ],
  Budča: [
    { lat: 48.5908352, lng: 19.0868345 },
    { lat: 48.5908253, lng: 19.0868153 },
    { lat: 48.5910359, lng: 19.085956 },
    { lat: 48.59101, lng: 19.0856598 },
    { lat: 48.5912415, lng: 19.0836578 },
    { lat: 48.5914943, lng: 19.0827156 },
    { lat: 48.591788, lng: 19.082277 },
    { lat: 48.5917865, lng: 19.0807562 },
    { lat: 48.5918797, lng: 19.0798699 },
    { lat: 48.592094, lng: 19.0786504 },
    { lat: 48.592211, lng: 19.0763668 },
    { lat: 48.5927887, lng: 19.0756323 },
    { lat: 48.5935039, lng: 19.0741963 },
    { lat: 48.5942843, lng: 19.07314 },
    { lat: 48.5942371, lng: 19.0711627 },
    { lat: 48.5943057, lng: 19.070527 },
    { lat: 48.5940807, lng: 19.0696797 },
    { lat: 48.5940499, lng: 19.0692914 },
    { lat: 48.5940692, lng: 19.0683454 },
    { lat: 48.5939536, lng: 19.0675458 },
    { lat: 48.5936553, lng: 19.0667923 },
    { lat: 48.5931356, lng: 19.0649902 },
    { lat: 48.5923431, lng: 19.0647663 },
    { lat: 48.5923202, lng: 19.0642849 },
    { lat: 48.5925939, lng: 19.0635614 },
    { lat: 48.5924973, lng: 19.0630717 },
    { lat: 48.5920975, lng: 19.061755 },
    { lat: 48.592486, lng: 19.060372 },
    { lat: 48.5924178, lng: 19.060269 },
    { lat: 48.5924205, lng: 19.0598321 },
    { lat: 48.5926004, lng: 19.059641 },
    { lat: 48.59246, lng: 19.0593343 },
    { lat: 48.5932403, lng: 19.0587305 },
    { lat: 48.5932941, lng: 19.0585691 },
    { lat: 48.59333, lng: 19.0580268 },
    { lat: 48.5935149, lng: 19.0581148 },
    { lat: 48.5936085, lng: 19.0580496 },
    { lat: 48.5937589, lng: 19.0571813 },
    { lat: 48.5938714, lng: 19.0570174 },
    { lat: 48.5940215, lng: 19.0569519 },
    { lat: 48.5940536, lng: 19.0568293 },
    { lat: 48.59399, lng: 19.0560993 },
    { lat: 48.5941219, lng: 19.0557423 },
    { lat: 48.5940737, lng: 19.0554551 },
    { lat: 48.5944941, lng: 19.0551365 },
    { lat: 48.594303, lng: 19.0542946 },
    { lat: 48.5944151, lng: 19.0539253 },
    { lat: 48.5944831, lng: 19.0533911 },
    { lat: 48.5946805, lng: 19.0528305 },
    { lat: 48.5946868, lng: 19.0525502 },
    { lat: 48.5946417, lng: 19.0513173 },
    { lat: 48.594677, lng: 19.0511644 },
    { lat: 48.5946698, lng: 19.0505979 },
    { lat: 48.5943388, lng: 19.0494582 },
    { lat: 48.5941631, lng: 19.0494166 },
    { lat: 48.593593, lng: 19.0489563 },
    { lat: 48.5933754, lng: 19.0486403 },
    { lat: 48.5932136, lng: 19.0483151 },
    { lat: 48.5932176, lng: 19.0482114 },
    { lat: 48.593052, lng: 19.0480141 },
    { lat: 48.5928545, lng: 19.0474511 },
    { lat: 48.592559, lng: 19.0469286 },
    { lat: 48.5924439, lng: 19.0464588 },
    { lat: 48.5925259, lng: 19.0462934 },
    { lat: 48.5924132, lng: 19.0453532 },
    { lat: 48.5919299, lng: 19.0443307 },
    { lat: 48.5916751, lng: 19.0441725 },
    { lat: 48.5916378, lng: 19.0439674 },
    { lat: 48.5917131, lng: 19.0435798 },
    { lat: 48.5916216, lng: 19.0429955 },
    { lat: 48.5916395, lng: 19.0426092 },
    { lat: 48.591736, lng: 19.0423343 },
    { lat: 48.5919308, lng: 19.0422614 },
    { lat: 48.5920425, lng: 19.0421297 },
    { lat: 48.5921239, lng: 19.0417045 },
    { lat: 48.5920196, lng: 19.0415839 },
    { lat: 48.5919967, lng: 19.0412186 },
    { lat: 48.5918622, lng: 19.0405349 },
    { lat: 48.5917683, lng: 19.0403736 },
    { lat: 48.5917564, lng: 19.0402026 },
    { lat: 48.5915864, lng: 19.039805 },
    { lat: 48.5912274, lng: 19.0385642 },
    { lat: 48.5903948, lng: 19.0377224 },
    { lat: 48.5900783, lng: 19.037686 },
    { lat: 48.5900241, lng: 19.0374158 },
    { lat: 48.5894643, lng: 19.0371789 },
    { lat: 48.5891716, lng: 19.0371418 },
    { lat: 48.5889254, lng: 19.0367758 },
    { lat: 48.5887787, lng: 19.034805 },
    { lat: 48.5887382, lng: 19.0346469 },
    { lat: 48.5884309, lng: 19.0341653 },
    { lat: 48.588162, lng: 19.0344642 },
    { lat: 48.5880771, lng: 19.0342578 },
    { lat: 48.5878101, lng: 19.0344212 },
    { lat: 48.5874885, lng: 19.0337443 },
    { lat: 48.587407, lng: 19.0337116 },
    { lat: 48.587274, lng: 19.0338059 },
    { lat: 48.5871294, lng: 19.0337765 },
    { lat: 48.5868082, lng: 19.0329254 },
    { lat: 48.586261, lng: 19.0325159 },
    { lat: 48.5861415, lng: 19.0323568 },
    { lat: 48.5857821, lng: 19.0323428 },
    { lat: 48.5855063, lng: 19.0319936 },
    { lat: 48.5848742, lng: 19.0319773 },
    { lat: 48.5842484, lng: 19.032222 },
    { lat: 48.5840743, lng: 19.0319873 },
    { lat: 48.5835727, lng: 19.0317242 },
    { lat: 48.5826047, lng: 19.0319988 },
    { lat: 48.5825221, lng: 19.0322639 },
    { lat: 48.5824041, lng: 19.0324248 },
    { lat: 48.5819593, lng: 19.0324604 },
    { lat: 48.581647, lng: 19.0329533 },
    { lat: 48.5814761, lng: 19.0329412 },
    { lat: 48.5813793, lng: 19.0327881 },
    { lat: 48.5813381, lng: 19.0325514 },
    { lat: 48.581295, lng: 19.0325457 },
    { lat: 48.5810536, lng: 19.032855 },
    { lat: 48.5809095, lng: 19.0329266 },
    { lat: 48.5807606, lng: 19.0327706 },
    { lat: 48.5806677, lng: 19.0325822 },
    { lat: 48.5805938, lng: 19.0325686 },
    { lat: 48.5804824, lng: 19.0327067 },
    { lat: 48.5803076, lng: 19.032773 },
    { lat: 48.5800378, lng: 19.0324784 },
    { lat: 48.579827, lng: 19.0323868 },
    { lat: 48.5796104, lng: 19.0324304 },
    { lat: 48.5792269, lng: 19.0323358 },
    { lat: 48.5789081, lng: 19.0323431 },
    { lat: 48.5787509, lng: 19.0326687 },
    { lat: 48.5786095, lng: 19.0326585 },
    { lat: 48.5784723, lng: 19.0325094 },
    { lat: 48.5783162, lng: 19.0325668 },
    { lat: 48.5781885, lng: 19.0330286 },
    { lat: 48.5780817, lng: 19.0330561 },
    { lat: 48.5777806, lng: 19.0328765 },
    { lat: 48.5774641, lng: 19.0329751 },
    { lat: 48.5771745, lng: 19.0328633 },
    { lat: 48.5767562, lng: 19.0328424 },
    { lat: 48.576475, lng: 19.0322982 },
    { lat: 48.5765243, lng: 19.0321092 },
    { lat: 48.5761593, lng: 19.0307958 },
    { lat: 48.5762176, lng: 19.0305236 },
    { lat: 48.5761761, lng: 19.0297425 },
    { lat: 48.5762006, lng: 19.0293787 },
    { lat: 48.5761656, lng: 19.0290883 },
    { lat: 48.5759505, lng: 19.0286451 },
    { lat: 48.575362, lng: 19.0280171 },
    { lat: 48.5747579, lng: 19.0280884 },
    { lat: 48.5746742, lng: 19.027994 },
    { lat: 48.5744441, lng: 19.0273005 },
    { lat: 48.5742949, lng: 19.0271581 },
    { lat: 48.5739837, lng: 19.0270008 },
    { lat: 48.5737856, lng: 19.0265595 },
    { lat: 48.5736125, lng: 19.0264156 },
    { lat: 48.5729725, lng: 19.0263778 },
    { lat: 48.571548, lng: 19.0271251 },
    { lat: 48.5711207, lng: 19.0275066 },
    { lat: 48.5708835, lng: 19.027543 },
    { lat: 48.5701638, lng: 19.0272238 },
    { lat: 48.5694556, lng: 19.0269955 },
    { lat: 48.5689628, lng: 19.0268369 },
    { lat: 48.5686188, lng: 19.0268133 },
    { lat: 48.5683754, lng: 19.0266572 },
    { lat: 48.5683409, lng: 19.0262884 },
    { lat: 48.5685238, lng: 19.0255768 },
    { lat: 48.5685435, lng: 19.0251392 },
    { lat: 48.5684239, lng: 19.0241362 },
    { lat: 48.5685194, lng: 19.0226536 },
    { lat: 48.5683081, lng: 19.0218185 },
    { lat: 48.5686103, lng: 19.0214165 },
    { lat: 48.5695261, lng: 19.0209306 },
    { lat: 48.5700742, lng: 19.0204669 },
    { lat: 48.5702912, lng: 19.0202081 },
    { lat: 48.5707071, lng: 19.0199383 },
    { lat: 48.5709918, lng: 19.0193667 },
    { lat: 48.57103, lng: 19.0185855 },
    { lat: 48.5709583, lng: 19.0180355 },
    { lat: 48.5711649, lng: 19.0170136 },
    { lat: 48.571246, lng: 19.016252 },
    { lat: 48.5711482, lng: 19.015144 },
    { lat: 48.571242, lng: 19.0148591 },
    { lat: 48.5718389, lng: 19.0142644 },
    { lat: 48.5720636, lng: 19.013423 },
    { lat: 48.5726997, lng: 19.012335 },
    { lat: 48.5726553, lng: 19.0115237 },
    { lat: 48.5725893, lng: 19.011134 },
    { lat: 48.5732281, lng: 19.0105444 },
    { lat: 48.5732499, lng: 19.0103311 },
    { lat: 48.5732058, lng: 19.0099492 },
    { lat: 48.5734307, lng: 19.0092676 },
    { lat: 48.5735995, lng: 19.0089053 },
    { lat: 48.573616, lng: 19.0086528 },
    { lat: 48.5737396, lng: 19.0082644 },
    { lat: 48.5738545, lng: 19.00737 },
    { lat: 48.5736412, lng: 19.0071649 },
    { lat: 48.5730453, lng: 19.0070197 },
    { lat: 48.5728408, lng: 19.0064102 },
    { lat: 48.5727014, lng: 19.0058182 },
    { lat: 48.5725613, lng: 19.0048744 },
    { lat: 48.5726176, lng: 19.0044286 },
    { lat: 48.5724692, lng: 19.0040815 },
    { lat: 48.5724231, lng: 19.0033965 },
    { lat: 48.5720944, lng: 19.002583 },
    { lat: 48.5721449, lng: 19.001863 },
    { lat: 48.5720758, lng: 19.0011657 },
    { lat: 48.5719573, lng: 19.000944 },
    { lat: 48.5718978, lng: 19.0005909 },
    { lat: 48.5719195, lng: 19.0001103 },
    { lat: 48.5718241, lng: 18.9998275 },
    { lat: 48.5716323, lng: 18.998537 },
    { lat: 48.571977, lng: 18.998516 },
    { lat: 48.571983, lng: 18.998494 },
    { lat: 48.571987, lng: 18.998439 },
    { lat: 48.571995, lng: 18.998401 },
    { lat: 48.572029, lng: 18.998125 },
    { lat: 48.571754, lng: 18.997948 },
    { lat: 48.571695, lng: 18.997911 },
    { lat: 48.571562, lng: 18.997887 },
    { lat: 48.571544, lng: 18.997884 },
    { lat: 48.571373, lng: 18.99786 },
    { lat: 48.571279, lng: 18.997852 },
    { lat: 48.5713174, lng: 18.9983507 },
    { lat: 48.5713181, lng: 18.9994963 },
    { lat: 48.5712355, lng: 19.0002021 },
    { lat: 48.5711505, lng: 19.0006195 },
    { lat: 48.5708738, lng: 19.001499 },
    { lat: 48.569348, lng: 19.0050123 },
    { lat: 48.5689176, lng: 19.0056852 },
    { lat: 48.5684595, lng: 19.0065021 },
    { lat: 48.568458, lng: 19.0065852 },
    { lat: 48.568146, lng: 19.006609 },
    { lat: 48.5680752, lng: 19.0066819 },
    { lat: 48.5677314, lng: 19.0075523 },
    { lat: 48.5669627, lng: 19.010319 },
    { lat: 48.5665297, lng: 19.011343 },
    { lat: 48.5656762, lng: 19.0129485 },
    { lat: 48.5646939, lng: 19.0142395 },
    { lat: 48.5643465, lng: 19.0144805 },
    { lat: 48.5642916, lng: 19.0146644 },
    { lat: 48.5643096, lng: 19.0150443 },
    { lat: 48.5637577, lng: 19.0157227 },
    { lat: 48.5622277, lng: 19.0134034 },
    { lat: 48.5620648, lng: 19.0136815 },
    { lat: 48.561709, lng: 19.0146973 },
    { lat: 48.56154, lng: 19.0155681 },
    { lat: 48.5614236, lng: 19.0167678 },
    { lat: 48.5614084, lng: 19.0184528 },
    { lat: 48.5613643, lng: 19.0187169 },
    { lat: 48.5613433, lng: 19.0197516 },
    { lat: 48.5613997, lng: 19.0204662 },
    { lat: 48.5615124, lng: 19.021157 },
    { lat: 48.5622144, lng: 19.0249279 },
    { lat: 48.5622585, lng: 19.0256885 },
    { lat: 48.5617808, lng: 19.0278389 },
    { lat: 48.5614841, lng: 19.0297793 },
    { lat: 48.5614492, lng: 19.0306567 },
    { lat: 48.5614773, lng: 19.0316815 },
    { lat: 48.5615676, lng: 19.0324186 },
    { lat: 48.5618623, lng: 19.0334392 },
    { lat: 48.5633587, lng: 19.0365758 },
    { lat: 48.5636251, lng: 19.0374328 },
    { lat: 48.5636935, lng: 19.0378934 },
    { lat: 48.5637031, lng: 19.0384878 },
    { lat: 48.5636498, lng: 19.0389079 },
    { lat: 48.563487, lng: 19.0397526 },
    { lat: 48.5630211, lng: 19.0416307 },
    { lat: 48.5628865, lng: 19.0428766 },
    { lat: 48.5628819, lng: 19.043658 },
    { lat: 48.5631824, lng: 19.0461815 },
    { lat: 48.5633257, lng: 19.0495018 },
    { lat: 48.5634647, lng: 19.0502939 },
    { lat: 48.5638242, lng: 19.0516607 },
    { lat: 48.5640658, lng: 19.053079 },
    { lat: 48.5643344, lng: 19.0539834 },
    { lat: 48.5647247, lng: 19.0548206 },
    { lat: 48.5655203, lng: 19.0560481 },
    { lat: 48.5657853, lng: 19.056565 },
    { lat: 48.5659659, lng: 19.0570883 },
    { lat: 48.5662684, lng: 19.0584414 },
    { lat: 48.5663338, lng: 19.0592512 },
    { lat: 48.5662915, lng: 19.0600943 },
    { lat: 48.5660258, lng: 19.0612501 },
    { lat: 48.5650521, lng: 19.0634147 },
    { lat: 48.5647319, lng: 19.0637776 },
    { lat: 48.5632378, lng: 19.0661518 },
    { lat: 48.5621107, lng: 19.0675346 },
    { lat: 48.5608784, lng: 19.0685663 },
    { lat: 48.5605705, lng: 19.0689223 },
    { lat: 48.5602436, lng: 19.0695556 },
    { lat: 48.5597776, lng: 19.0707624 },
    { lat: 48.5597732, lng: 19.0715989 },
    { lat: 48.5596146, lng: 19.0727687 },
    { lat: 48.5596081, lng: 19.0735025 },
    { lat: 48.5595777, lng: 19.0741132 },
    { lat: 48.559472, lng: 19.0748181 },
    { lat: 48.5586296, lng: 19.0767523 },
    { lat: 48.5582053, lng: 19.0773087 },
    { lat: 48.5572927, lng: 19.0781076 },
    { lat: 48.5567405, lng: 19.0790361 },
    { lat: 48.5564764, lng: 19.0799106 },
    { lat: 48.5560923, lng: 19.0811787 },
    { lat: 48.5563439, lng: 19.0812064 },
    { lat: 48.5560563, lng: 19.0822216 },
    { lat: 48.5559864, lng: 19.0830064 },
    { lat: 48.5560194, lng: 19.0837614 },
    { lat: 48.5561754, lng: 19.0838818 },
    { lat: 48.5562019, lng: 19.0843467 },
    { lat: 48.5564512, lng: 19.0846644 },
    { lat: 48.5566492, lng: 19.0851957 },
    { lat: 48.5578352, lng: 19.0856265 },
    { lat: 48.5586505, lng: 19.0863085 },
    { lat: 48.5589839, lng: 19.0856906 },
    { lat: 48.5592689, lng: 19.0849405 },
    { lat: 48.5593255, lng: 19.0845415 },
    { lat: 48.5594886, lng: 19.0840127 },
    { lat: 48.5595682, lng: 19.0838706 },
    { lat: 48.5597195, lng: 19.0838303 },
    { lat: 48.5603272, lng: 19.0839411 },
    { lat: 48.5613578, lng: 19.0839478 },
    { lat: 48.5615095, lng: 19.0840246 },
    { lat: 48.5622651, lng: 19.0848198 },
    { lat: 48.5627357, lng: 19.0851954 },
    { lat: 48.5629736, lng: 19.0859842 },
    { lat: 48.5631074, lng: 19.0862459 },
    { lat: 48.5643383, lng: 19.0870347 },
    { lat: 48.5646387, lng: 19.0870224 },
    { lat: 48.5652208, lng: 19.0871882 },
    { lat: 48.5655932, lng: 19.0875122 },
    { lat: 48.5662113, lng: 19.0876754 },
    { lat: 48.5664801, lng: 19.0875509 },
    { lat: 48.5672235, lng: 19.0874457 },
    { lat: 48.5677497, lng: 19.0870778 },
    { lat: 48.5679962, lng: 19.086999 },
    { lat: 48.5685736, lng: 19.0865317 },
    { lat: 48.5689553, lng: 19.0865219 },
    { lat: 48.5694911, lng: 19.0862316 },
    { lat: 48.5697102, lng: 19.0861968 },
    { lat: 48.5709859, lng: 19.0863438 },
    { lat: 48.5714891, lng: 19.0862622 },
    { lat: 48.5719015, lng: 19.0863714 },
    { lat: 48.5726631, lng: 19.0863168 },
    { lat: 48.5729271, lng: 19.0861825 },
    { lat: 48.5730258, lng: 19.0863088 },
    { lat: 48.5733374, lng: 19.0871335 },
    { lat: 48.5736923, lng: 19.0886394 },
    { lat: 48.5742915, lng: 19.0893284 },
    { lat: 48.5752074, lng: 19.0897448 },
    { lat: 48.5763393, lng: 19.0904247 },
    { lat: 48.5774878, lng: 19.0909152 },
    { lat: 48.5779873, lng: 19.0908732 },
    { lat: 48.579172, lng: 19.0910951 },
    { lat: 48.5793646, lng: 19.0912292 },
    { lat: 48.5796066, lng: 19.0916486 },
    { lat: 48.579801, lng: 19.0918178 },
    { lat: 48.5800707, lng: 19.090827 },
    { lat: 48.5809447, lng: 19.091308 },
    { lat: 48.5812611, lng: 19.0912758 },
    { lat: 48.581525, lng: 19.0911437 },
    { lat: 48.5816502, lng: 19.0909397 },
    { lat: 48.5825564, lng: 19.0908026 },
    { lat: 48.5826061, lng: 19.0914823 },
    { lat: 48.5831141, lng: 19.0910936 },
    { lat: 48.583137, lng: 19.0910761 },
    { lat: 48.5832693, lng: 19.0908774 },
    { lat: 48.5835801, lng: 19.0909669 },
    { lat: 48.5836147, lng: 19.0909768 },
    { lat: 48.58451, lng: 19.0908665 },
    { lat: 48.5846034, lng: 19.0906373 },
    { lat: 48.5848459, lng: 19.0904838 },
    { lat: 48.5853002, lng: 19.089759 },
    { lat: 48.585047, lng: 19.0891335 },
    { lat: 48.5851991, lng: 19.0888642 },
    { lat: 48.5853255, lng: 19.0888388 },
    { lat: 48.5855354, lng: 19.0883258 },
    { lat: 48.5857609, lng: 19.0884366 },
    { lat: 48.5858566, lng: 19.0885515 },
    { lat: 48.5859547, lng: 19.0885485 },
    { lat: 48.5859715, lng: 19.0884583 },
    { lat: 48.5859021, lng: 19.0883562 },
    { lat: 48.5859779, lng: 19.0880179 },
    { lat: 48.5860817, lng: 19.0880677 },
    { lat: 48.586282, lng: 19.087858 },
    { lat: 48.5866856, lng: 19.0878329 },
    { lat: 48.5869704, lng: 19.0875325 },
    { lat: 48.5870261, lng: 19.0873613 },
    { lat: 48.5872669, lng: 19.0873764 },
    { lat: 48.5874558, lng: 19.0872268 },
    { lat: 48.5874899, lng: 19.0871863 },
    { lat: 48.587498, lng: 19.0869457 },
    { lat: 48.5875979, lng: 19.0867563 },
    { lat: 48.5878125, lng: 19.0870098 },
    { lat: 48.5881961, lng: 19.0869275 },
    { lat: 48.5883828, lng: 19.0870338 },
    { lat: 48.588669, lng: 19.0869163 },
    { lat: 48.5887681, lng: 19.086957 },
    { lat: 48.5888073, lng: 19.0871139 },
    { lat: 48.5890826, lng: 19.0873839 },
    { lat: 48.5891909, lng: 19.0873863 },
    { lat: 48.5893633, lng: 19.0872723 },
    { lat: 48.5894389, lng: 19.0873421 },
    { lat: 48.5895611, lng: 19.0873293 },
    { lat: 48.5895998, lng: 19.0872441 },
    { lat: 48.5897612, lng: 19.0871834 },
    { lat: 48.5899505, lng: 19.0873361 },
    { lat: 48.5901158, lng: 19.0873534 },
    { lat: 48.5902558, lng: 19.087267 },
    { lat: 48.590175, lng: 19.0870261 },
    { lat: 48.5904722, lng: 19.0869497 },
    { lat: 48.5905497, lng: 19.0869399 },
    { lat: 48.5906495, lng: 19.0870895 },
    { lat: 48.5908352, lng: 19.0868345 },
  ],
  Sása: [
    { lat: 48.4874898, lng: 19.1860554 },
    { lat: 48.4874631, lng: 19.1855594 },
    { lat: 48.4864375, lng: 19.1824227 },
    { lat: 48.4853433, lng: 19.1805047 },
    { lat: 48.4848806, lng: 19.1798281 },
    { lat: 48.4837351, lng: 19.1790431 },
    { lat: 48.4820874, lng: 19.1777454 },
    { lat: 48.4807186, lng: 19.1757211 },
    { lat: 48.4802601, lng: 19.1749496 },
    { lat: 48.4795786, lng: 19.174364 },
    { lat: 48.4794562, lng: 19.1739164 },
    { lat: 48.4788573, lng: 19.1732483 },
    { lat: 48.4784905, lng: 19.1727371 },
    { lat: 48.4775986, lng: 19.1720001 },
    { lat: 48.4771338, lng: 19.1713491 },
    { lat: 48.4770426, lng: 19.1698429 },
    { lat: 48.4766649, lng: 19.1673926 },
    { lat: 48.4759438, lng: 19.1669458 },
    { lat: 48.4745602, lng: 19.1658378 },
    { lat: 48.4745267, lng: 19.1653871 },
    { lat: 48.4746018, lng: 19.1646256 },
    { lat: 48.4745541, lng: 19.1634727 },
    { lat: 48.4742886, lng: 19.1623427 },
    { lat: 48.4742329, lng: 19.1616671 },
    { lat: 48.4738531, lng: 19.160826 },
    { lat: 48.4738904, lng: 19.1604385 },
    { lat: 48.4738759, lng: 19.1596649 },
    { lat: 48.4737714, lng: 19.1578036 },
    { lat: 48.4738026, lng: 19.1567879 },
    { lat: 48.4737307, lng: 19.1562712 },
    { lat: 48.4737487, lng: 19.155813 },
    { lat: 48.4735128, lng: 19.1542073 },
    { lat: 48.4733392, lng: 19.1538867 },
    { lat: 48.4732949, lng: 19.1536799 },
    { lat: 48.4732665, lng: 19.1531232 },
    { lat: 48.4731131, lng: 19.1519843 },
    { lat: 48.4728108, lng: 19.1510208 },
    { lat: 48.4726924, lng: 19.1503839 },
    { lat: 48.472833, lng: 19.1495344 },
    { lat: 48.472731, lng: 19.1491102 },
    { lat: 48.4721252, lng: 19.1476223 },
    { lat: 48.4719096, lng: 19.147251 },
    { lat: 48.4712399, lng: 19.1457006 },
    { lat: 48.4710293, lng: 19.1447238 },
    { lat: 48.4707433, lng: 19.144278 },
    { lat: 48.4707385, lng: 19.1439755 },
    { lat: 48.4704854, lng: 19.1437335 },
    { lat: 48.4702061, lng: 19.1433086 },
    { lat: 48.4701593, lng: 19.1428634 },
    { lat: 48.4700902, lng: 19.1427667 },
    { lat: 48.470091, lng: 19.1425772 },
    { lat: 48.4700224, lng: 19.1425366 },
    { lat: 48.4699366, lng: 19.1422354 },
    { lat: 48.4697932, lng: 19.1421878 },
    { lat: 48.4698182, lng: 19.1420774 },
    { lat: 48.4697353, lng: 19.1420874 },
    { lat: 48.4694929, lng: 19.1419486 },
    { lat: 48.4694307, lng: 19.1417176 },
    { lat: 48.4690906, lng: 19.1411322 },
    { lat: 48.4690344, lng: 19.1409552 },
    { lat: 48.4688694, lng: 19.1408103 },
    { lat: 48.4687396, lng: 19.1408749 },
    { lat: 48.4686737, lng: 19.1410912 },
    { lat: 48.468584, lng: 19.141011 },
    { lat: 48.4684345, lng: 19.1411542 },
    { lat: 48.4683649, lng: 19.140865 },
    { lat: 48.4681735, lng: 19.141074 },
    { lat: 48.4679975, lng: 19.1409353 },
    { lat: 48.4679249, lng: 19.1410869 },
    { lat: 48.4678471, lng: 19.1410857 },
    { lat: 48.4677714, lng: 19.1411969 },
    { lat: 48.4677332, lng: 19.1413599 },
    { lat: 48.4676378, lng: 19.1414143 },
    { lat: 48.4675255, lng: 19.1413544 },
    { lat: 48.4674588, lng: 19.1413963 },
    { lat: 48.4675089, lng: 19.1416113 },
    { lat: 48.4673609, lng: 19.1416712 },
    { lat: 48.4673199, lng: 19.1415483 },
    { lat: 48.4671572, lng: 19.1415648 },
    { lat: 48.4670718, lng: 19.141504 },
    { lat: 48.4670978, lng: 19.1413231 },
    { lat: 48.4669901, lng: 19.1412187 },
    { lat: 48.4669405, lng: 19.1413109 },
    { lat: 48.4668373, lng: 19.1412731 },
    { lat: 48.4668345, lng: 19.1411487 },
    { lat: 48.4666417, lng: 19.1412385 },
    { lat: 48.4665592, lng: 19.1414619 },
    { lat: 48.4664109, lng: 19.1414491 },
    { lat: 48.4663128, lng: 19.1417254 },
    { lat: 48.4662605, lng: 19.141694 },
    { lat: 48.4662099, lng: 19.1418069 },
    { lat: 48.4661818, lng: 19.1418118 },
    { lat: 48.4661628, lng: 19.1415866 },
    { lat: 48.4660691, lng: 19.1416078 },
    { lat: 48.4659977, lng: 19.1417582 },
    { lat: 48.4659161, lng: 19.1417943 },
    { lat: 48.4658429, lng: 19.141637 },
    { lat: 48.4656057, lng: 19.1416273 },
    { lat: 48.4655659, lng: 19.1418254 },
    { lat: 48.4654248, lng: 19.141843 },
    { lat: 48.4654052, lng: 19.1416999 },
    { lat: 48.4652159, lng: 19.1418603 },
    { lat: 48.4651439, lng: 19.1418183 },
    { lat: 48.4650275, lng: 19.1419485 },
    { lat: 48.4648573, lng: 19.1419701 },
    { lat: 48.4647327, lng: 19.1417773 },
    { lat: 48.4648162, lng: 19.1416902 },
    { lat: 48.4648222, lng: 19.1415577 },
    { lat: 48.4646701, lng: 19.1414021 },
    { lat: 48.4645676, lng: 19.1415645 },
    { lat: 48.464427, lng: 19.1415668 },
    { lat: 48.4644499, lng: 19.1414388 },
    { lat: 48.4643822, lng: 19.1414596 },
    { lat: 48.4642723, lng: 19.1416104 },
    { lat: 48.4639655, lng: 19.14179 },
    { lat: 48.4638061, lng: 19.1415752 },
    { lat: 48.463651, lng: 19.1416548 },
    { lat: 48.463544, lng: 19.1415154 },
    { lat: 48.4633888, lng: 19.1415019 },
    { lat: 48.4632663, lng: 19.1416236 },
    { lat: 48.4631659, lng: 19.1415752 },
    { lat: 48.4631159, lng: 19.141319 },
    { lat: 48.4629938, lng: 19.1412865 },
    { lat: 48.4628954, lng: 19.1413266 },
    { lat: 48.4628189, lng: 19.1412606 },
    { lat: 48.4626341, lng: 19.1414017 },
    { lat: 48.4624478, lng: 19.1412137 },
    { lat: 48.4622452, lng: 19.1411732 },
    { lat: 48.4620311, lng: 19.1413574 },
    { lat: 48.4619642, lng: 19.1413279 },
    { lat: 48.4618188, lng: 19.1410764 },
    { lat: 48.4617459, lng: 19.1411307 },
    { lat: 48.4618018, lng: 19.1414399 },
    { lat: 48.4617183, lng: 19.1415046 },
    { lat: 48.4615778, lng: 19.1414 },
    { lat: 48.4613693, lng: 19.1414681 },
    { lat: 48.4612955, lng: 19.1413005 },
    { lat: 48.4610955, lng: 19.1411783 },
    { lat: 48.4609446, lng: 19.1413409 },
    { lat: 48.4608301, lng: 19.1412812 },
    { lat: 48.4607519, lng: 19.1411145 },
    { lat: 48.4606676, lng: 19.1411854 },
    { lat: 48.4605961, lng: 19.1411543 },
    { lat: 48.4605697, lng: 19.141055 },
    { lat: 48.4605179, lng: 19.1410544 },
    { lat: 48.4604852, lng: 19.1411476 },
    { lat: 48.4603927, lng: 19.141078 },
    { lat: 48.4603979, lng: 19.1409545 },
    { lat: 48.4603482, lng: 19.1408607 },
    { lat: 48.4603841, lng: 19.140722 },
    { lat: 48.4602595, lng: 19.1406166 },
    { lat: 48.460085, lng: 19.1406465 },
    { lat: 48.4598543, lng: 19.1403139 },
    { lat: 48.4597515, lng: 19.1403333 },
    { lat: 48.4596914, lng: 19.1402118 },
    { lat: 48.4597167, lng: 19.1400402 },
    { lat: 48.459679, lng: 19.1399123 },
    { lat: 48.4597375, lng: 19.139796 },
    { lat: 48.459647, lng: 19.1396779 },
    { lat: 48.4597699, lng: 19.1396301 },
    { lat: 48.4598902, lng: 19.139422 },
    { lat: 48.4597423, lng: 19.1391892 },
    { lat: 48.4598637, lng: 19.1390921 },
    { lat: 48.4598864, lng: 19.1389592 },
    { lat: 48.4597863, lng: 19.1389417 },
    { lat: 48.4597435, lng: 19.1388509 },
    { lat: 48.4599487, lng: 19.1387342 },
    { lat: 48.459928, lng: 19.1386157 },
    { lat: 48.4599927, lng: 19.1385108 },
    { lat: 48.4598619, lng: 19.1380311 },
    { lat: 48.4598799, lng: 19.1378461 },
    { lat: 48.4598244, lng: 19.137685 },
    { lat: 48.4599204, lng: 19.1376189 },
    { lat: 48.4601526, lng: 19.1370054 },
    { lat: 48.4601384, lng: 19.1365371 },
    { lat: 48.4599803, lng: 19.1363257 },
    { lat: 48.4598461, lng: 19.1363013 },
    { lat: 48.4601086, lng: 19.1354603 },
    { lat: 48.4601663, lng: 19.1335749 },
    { lat: 48.4599821, lng: 19.1322046 },
    { lat: 48.4592688, lng: 19.1304356 },
    { lat: 48.4588931, lng: 19.1297716 },
    { lat: 48.4587535, lng: 19.1293678 },
    { lat: 48.458452, lng: 19.1288821 },
    { lat: 48.4577168, lng: 19.1274249 },
    { lat: 48.4574234, lng: 19.1266015 },
    { lat: 48.4573448, lng: 19.126518 },
    { lat: 48.4563716, lng: 19.1245126 },
    { lat: 48.4559025, lng: 19.1237389 },
    { lat: 48.455068, lng: 19.122738 },
    { lat: 48.4551979, lng: 19.1219478 },
    { lat: 48.4552642, lng: 19.1205353 },
    { lat: 48.4536925, lng: 19.1180608 },
    { lat: 48.4528778, lng: 19.1174829 },
    { lat: 48.4519162, lng: 19.1161897 },
    { lat: 48.451271, lng: 19.1157083 },
    { lat: 48.4510805, lng: 19.1156395 },
    { lat: 48.4505496, lng: 19.1150067 },
    { lat: 48.4497668, lng: 19.1145674 },
    { lat: 48.4493831, lng: 19.1141532 },
    { lat: 48.4491626, lng: 19.1141378 },
    { lat: 48.4483287, lng: 19.1134696 },
    { lat: 48.4478017, lng: 19.1133421 },
    { lat: 48.4473629, lng: 19.1130456 },
    { lat: 48.446578, lng: 19.1126964 },
    { lat: 48.446376, lng: 19.1125174 },
    { lat: 48.4461292, lng: 19.1121102 },
    { lat: 48.4460782, lng: 19.1120826 },
    { lat: 48.4459612, lng: 19.1122651 },
    { lat: 48.4458844, lng: 19.1122425 },
    { lat: 48.4458423, lng: 19.1123027 },
    { lat: 48.445677, lng: 19.1121284 },
    { lat: 48.4456587, lng: 19.1122168 },
    { lat: 48.4454646, lng: 19.1124354 },
    { lat: 48.4453823, lng: 19.1124162 },
    { lat: 48.4452934, lng: 19.1121013 },
    { lat: 48.445176, lng: 19.1119625 },
    { lat: 48.44511, lng: 19.111971 },
    { lat: 48.4448842, lng: 19.1121896 },
    { lat: 48.4447811, lng: 19.1124072 },
    { lat: 48.4447818, lng: 19.1125115 },
    { lat: 48.444608, lng: 19.1125776 },
    { lat: 48.444352, lng: 19.1128691 },
    { lat: 48.4441504, lng: 19.112759 },
    { lat: 48.4440398, lng: 19.1128243 },
    { lat: 48.4431898, lng: 19.1121221 },
    { lat: 48.4423855, lng: 19.1117958 },
    { lat: 48.4411948, lng: 19.11116 },
    { lat: 48.4405298, lng: 19.1106664 },
    { lat: 48.440214, lng: 19.110572 },
    { lat: 48.4396499, lng: 19.1109476 },
    { lat: 48.4388649, lng: 19.1108253 },
    { lat: 48.4355125, lng: 19.1107645 },
    { lat: 48.4346777, lng: 19.1106426 },
    { lat: 48.434141, lng: 19.1104151 },
    { lat: 48.4335307, lng: 19.1103148 },
    { lat: 48.4324133, lng: 19.109745 },
    { lat: 48.4320269, lng: 19.1095479 },
    { lat: 48.4309807, lng: 19.1096415 },
    { lat: 48.4300848, lng: 19.1100561 },
    { lat: 48.429518, lng: 19.1101966 },
    { lat: 48.4291442, lng: 19.1101822 },
    { lat: 48.4283126, lng: 19.1099205 },
    { lat: 48.4277336, lng: 19.1094518 },
    { lat: 48.4265946, lng: 19.1080415 },
    { lat: 48.4254153, lng: 19.107316 },
    { lat: 48.4237038, lng: 19.1067967 },
    { lat: 48.4231214, lng: 19.1064441 },
    { lat: 48.4221033, lng: 19.1063288 },
    { lat: 48.4209628, lng: 19.1058176 },
    { lat: 48.4205698, lng: 19.1058257 },
    { lat: 48.419871, lng: 19.1054706 },
    { lat: 48.4193495, lng: 19.105387 },
    { lat: 48.41928, lng: 19.105304 },
    { lat: 48.419326, lng: 19.106026 },
    { lat: 48.419304, lng: 19.106382 },
    { lat: 48.419176, lng: 19.106663 },
    { lat: 48.418816, lng: 19.106996 },
    { lat: 48.418618, lng: 19.107449 },
    { lat: 48.418464, lng: 19.107578 },
    { lat: 48.418378, lng: 19.107853 },
    { lat: 48.418213, lng: 19.108823 },
    { lat: 48.418038, lng: 19.10922 },
    { lat: 48.417645, lng: 19.109776 },
    { lat: 48.417088, lng: 19.110202 },
    { lat: 48.416662, lng: 19.10988 },
    { lat: 48.416624, lng: 19.109884 },
    { lat: 48.416068, lng: 19.109958 },
    { lat: 48.415806, lng: 19.110476 },
    { lat: 48.41555, lng: 19.110718 },
    { lat: 48.415321, lng: 19.110717 },
    { lat: 48.414918, lng: 19.110513 },
    { lat: 48.414666, lng: 19.110551 },
    { lat: 48.414441, lng: 19.111165 },
    { lat: 48.414633, lng: 19.111953 },
    { lat: 48.414835, lng: 19.112298 },
    { lat: 48.415087, lng: 19.112551 },
    { lat: 48.415122, lng: 19.112991 },
    { lat: 48.415035, lng: 19.114059 },
    { lat: 48.415004, lng: 19.11412 },
    { lat: 48.4156994, lng: 19.1154811 },
    { lat: 48.415749, lng: 19.115586 },
    { lat: 48.415718, lng: 19.115628 },
    { lat: 48.4162301, lng: 19.1168093 },
    { lat: 48.4169184, lng: 19.1180032 },
    { lat: 48.4169812, lng: 19.1182446 },
    { lat: 48.416755, lng: 19.1186827 },
    { lat: 48.4169467, lng: 19.1194061 },
    { lat: 48.4172131, lng: 19.120322 },
    { lat: 48.417573, lng: 19.1210251 },
    { lat: 48.4177684, lng: 19.1216682 },
    { lat: 48.4184093, lng: 19.1227038 },
    { lat: 48.4182371, lng: 19.1230699 },
    { lat: 48.4183874, lng: 19.1233689 },
    { lat: 48.4186595, lng: 19.1236532 },
    { lat: 48.4186566, lng: 19.1241361 },
    { lat: 48.4188076, lng: 19.1244088 },
    { lat: 48.4190773, lng: 19.1247057 },
    { lat: 48.4193785, lng: 19.1246673 },
    { lat: 48.4195857, lng: 19.124934 },
    { lat: 48.4196332, lng: 19.1254586 },
    { lat: 48.419563, lng: 19.1260648 },
    { lat: 48.4192152, lng: 19.1268907 },
    { lat: 48.4192028, lng: 19.1271891 },
    { lat: 48.4191259, lng: 19.127547 },
    { lat: 48.4188104, lng: 19.1279333 },
    { lat: 48.4186279, lng: 19.1284354 },
    { lat: 48.4186644, lng: 19.1287177 },
    { lat: 48.4185892, lng: 19.1287446 },
    { lat: 48.4181604, lng: 19.1286021 },
    { lat: 48.4178772, lng: 19.1288039 },
    { lat: 48.4178563, lng: 19.1292431 },
    { lat: 48.4177438, lng: 19.129409 },
    { lat: 48.417707, lng: 19.1297322 },
    { lat: 48.417841, lng: 19.13 },
    { lat: 48.4178265, lng: 19.1302976 },
    { lat: 48.4177001, lng: 19.1305928 },
    { lat: 48.4176708, lng: 19.1309358 },
    { lat: 48.4175573, lng: 19.1310759 },
    { lat: 48.417258, lng: 19.130838 },
    { lat: 48.4171889, lng: 19.1309014 },
    { lat: 48.4171875, lng: 19.1313725 },
    { lat: 48.4174838, lng: 19.1318457 },
    { lat: 48.4176819, lng: 19.1318622 },
    { lat: 48.4174572, lng: 19.1323079 },
    { lat: 48.4172521, lng: 19.1323316 },
    { lat: 48.4170979, lng: 19.1324941 },
    { lat: 48.4170749, lng: 19.1332355 },
    { lat: 48.4173191, lng: 19.1342721 },
    { lat: 48.4170748, lng: 19.1347314 },
    { lat: 48.4170935, lng: 19.1350664 },
    { lat: 48.4170094, lng: 19.1357749 },
    { lat: 48.4168721, lng: 19.1361466 },
    { lat: 48.4166521, lng: 19.1363675 },
    { lat: 48.4158951, lng: 19.1365609 },
    { lat: 48.4159272, lng: 19.1376837 },
    { lat: 48.4160675, lng: 19.1378117 },
    { lat: 48.4162261, lng: 19.1378042 },
    { lat: 48.4162418, lng: 19.1380732 },
    { lat: 48.4161251, lng: 19.1385402 },
    { lat: 48.4161121, lng: 19.1390746 },
    { lat: 48.4159565, lng: 19.1397092 },
    { lat: 48.415685, lng: 19.1395082 },
    { lat: 48.415589, lng: 19.1391616 },
    { lat: 48.4154103, lng: 19.1391518 },
    { lat: 48.4154026, lng: 19.1394777 },
    { lat: 48.4154851, lng: 19.13973 },
    { lat: 48.4154271, lng: 19.140014 },
    { lat: 48.4153103, lng: 19.1402255 },
    { lat: 48.415265, lng: 19.1404938 },
    { lat: 48.4151452, lng: 19.140784 },
    { lat: 48.4151353, lng: 19.1411755 },
    { lat: 48.4153015, lng: 19.1413024 },
    { lat: 48.4153313, lng: 19.1416302 },
    { lat: 48.4149797, lng: 19.1419239 },
    { lat: 48.4148456, lng: 19.1418843 },
    { lat: 48.4147552, lng: 19.1419909 },
    { lat: 48.4146477, lng: 19.1424852 },
    { lat: 48.4149357, lng: 19.1428341 },
    { lat: 48.4149586, lng: 19.1432961 },
    { lat: 48.4148302, lng: 19.143666 },
    { lat: 48.4145615, lng: 19.143767 },
    { lat: 48.414306, lng: 19.1437566 },
    { lat: 48.4139438, lng: 19.144274 },
    { lat: 48.4138455, lng: 19.145494 },
    { lat: 48.4139759, lng: 19.1458283 },
    { lat: 48.4139739, lng: 19.146041 },
    { lat: 48.4138379, lng: 19.1460942 },
    { lat: 48.4136803, lng: 19.1459834 },
    { lat: 48.4136438, lng: 19.1461585 },
    { lat: 48.4137465, lng: 19.1464085 },
    { lat: 48.4136673, lng: 19.1465888 },
    { lat: 48.4135681, lng: 19.1467241 },
    { lat: 48.4136504, lng: 19.1470641 },
    { lat: 48.4137527, lng: 19.1472396 },
    { lat: 48.4144751, lng: 19.1472174 },
    { lat: 48.4148362, lng: 19.1471104 },
    { lat: 48.4152889, lng: 19.1470729 },
    { lat: 48.4158968, lng: 19.1471703 },
    { lat: 48.4161085, lng: 19.1466417 },
    { lat: 48.4168764, lng: 19.1472596 },
    { lat: 48.4173813, lng: 19.147256 },
    { lat: 48.4184108, lng: 19.1473755 },
    { lat: 48.4199982, lng: 19.147356 },
    { lat: 48.4205006, lng: 19.1470535 },
    { lat: 48.4204978, lng: 19.1467916 },
    { lat: 48.4213322, lng: 19.146683 },
    { lat: 48.4213378, lng: 19.1464769 },
    { lat: 48.4218149, lng: 19.1464136 },
    { lat: 48.4218856, lng: 19.1459763 },
    { lat: 48.4226438, lng: 19.1460789 },
    { lat: 48.4226905, lng: 19.1457479 },
    { lat: 48.4230052, lng: 19.1452041 },
    { lat: 48.4237879, lng: 19.1443481 },
    { lat: 48.4248935, lng: 19.1442728 },
    { lat: 48.4250779, lng: 19.1438071 },
    { lat: 48.42588, lng: 19.1439534 },
    { lat: 48.4266982, lng: 19.1442232 },
    { lat: 48.4275005, lng: 19.1448774 },
    { lat: 48.4277942, lng: 19.1451836 },
    { lat: 48.4281224, lng: 19.1456626 },
    { lat: 48.4286572, lng: 19.1446022 },
    { lat: 48.4292238, lng: 19.143022 },
    { lat: 48.4300028, lng: 19.14314 },
    { lat: 48.4303078, lng: 19.1428308 },
    { lat: 48.4306929, lng: 19.1423025 },
    { lat: 48.4312827, lng: 19.141847 },
    { lat: 48.4314572, lng: 19.1422315 },
    { lat: 48.4315903, lng: 19.1423675 },
    { lat: 48.4318498, lng: 19.1420782 },
    { lat: 48.4322446, lng: 19.1418086 },
    { lat: 48.4327525, lng: 19.1416417 },
    { lat: 48.4330285, lng: 19.1418632 },
    { lat: 48.4339015, lng: 19.14351 },
    { lat: 48.4343274, lng: 19.1435291 },
    { lat: 48.4348323, lng: 19.1434584 },
    { lat: 48.4349651, lng: 19.1433154 },
    { lat: 48.4352866, lng: 19.1427047 },
    { lat: 48.4355603, lng: 19.1428132 },
    { lat: 48.4361306, lng: 19.1435386 },
    { lat: 48.4367893, lng: 19.1439122 },
    { lat: 48.4368528, lng: 19.1437592 },
    { lat: 48.4370721, lng: 19.1438617 },
    { lat: 48.4371788, lng: 19.1447175 },
    { lat: 48.4377602, lng: 19.1453728 },
    { lat: 48.4382247, lng: 19.1461281 },
    { lat: 48.4384721, lng: 19.1466149 },
    { lat: 48.4388222, lng: 19.147676 },
    { lat: 48.4394875, lng: 19.1478636 },
    { lat: 48.4399181, lng: 19.1479057 },
    { lat: 48.4400119, lng: 19.1481146 },
    { lat: 48.4402697, lng: 19.1490859 },
    { lat: 48.4404166, lng: 19.149868 },
    { lat: 48.4407833, lng: 19.1509255 },
    { lat: 48.4411583, lng: 19.1522161 },
    { lat: 48.4417283, lng: 19.1534153 },
    { lat: 48.4423171, lng: 19.1545147 },
    { lat: 48.4425472, lng: 19.1552153 },
    { lat: 48.4426252, lng: 19.1555261 },
    { lat: 48.442766, lng: 19.1562606 },
    { lat: 48.4431123, lng: 19.1578545 },
    { lat: 48.4434033, lng: 19.1585655 },
    { lat: 48.4435576, lng: 19.1592449 },
    { lat: 48.4436978, lng: 19.159482 },
    { lat: 48.4437655, lng: 19.1597585 },
    { lat: 48.4439383, lng: 19.1601522 },
    { lat: 48.444188, lng: 19.1605983 },
    { lat: 48.4444754, lng: 19.1615807 },
    { lat: 48.444631, lng: 19.1618527 },
    { lat: 48.4447359, lng: 19.1631782 },
    { lat: 48.4448343, lng: 19.1638273 },
    { lat: 48.4454697, lng: 19.1656213 },
    { lat: 48.446036, lng: 19.1664079 },
    { lat: 48.4461762, lng: 19.1671734 },
    { lat: 48.4466429, lng: 19.1685403 },
    { lat: 48.4469077, lng: 19.1696389 },
    { lat: 48.447283, lng: 19.17039 },
    { lat: 48.4476418, lng: 19.1704899 },
    { lat: 48.4479296, lng: 19.1710906 },
    { lat: 48.4481194, lng: 19.1721518 },
    { lat: 48.4481894, lng: 19.1723119 },
    { lat: 48.4484066, lng: 19.1725335 },
    { lat: 48.4492185, lng: 19.1737999 },
    { lat: 48.449346, lng: 19.1741198 },
    { lat: 48.4494457, lng: 19.1745189 },
    { lat: 48.4494986, lng: 19.1751603 },
    { lat: 48.4494106, lng: 19.1758682 },
    { lat: 48.4494614, lng: 19.1762337 },
    { lat: 48.4494365, lng: 19.1764847 },
    { lat: 48.4493683, lng: 19.1766783 },
    { lat: 48.4489879, lng: 19.1771524 },
    { lat: 48.4485129, lng: 19.1781509 },
    { lat: 48.4484897, lng: 19.1791005 },
    { lat: 48.4486367, lng: 19.1800475 },
    { lat: 48.4489598, lng: 19.180912 },
    { lat: 48.4492233, lng: 19.181268 },
    { lat: 48.4499087, lng: 19.1816361 },
    { lat: 48.4500273, lng: 19.181799 },
    { lat: 48.4504116, lng: 19.1830623 },
    { lat: 48.4508606, lng: 19.1852659 },
    { lat: 48.451036, lng: 19.1856484 },
    { lat: 48.4515523, lng: 19.1864969 },
    { lat: 48.4518293, lng: 19.1876831 },
    { lat: 48.4518198, lng: 19.1879523 },
    { lat: 48.4518389, lng: 19.1882254 },
    { lat: 48.4521953, lng: 19.1894205 },
    { lat: 48.4523722, lng: 19.1896456 },
    { lat: 48.4526877, lng: 19.1898985 },
    { lat: 48.4527536, lng: 19.1900911 },
    { lat: 48.4534011, lng: 19.1911791 },
    { lat: 48.4537373, lng: 19.1915324 },
    { lat: 48.4542429, lng: 19.1922746 },
    { lat: 48.454661, lng: 19.1927325 },
    { lat: 48.4552335, lng: 19.1928451 },
    { lat: 48.455709, lng: 19.1928392 },
    { lat: 48.4566623, lng: 19.1931987 },
    { lat: 48.4576422, lng: 19.1927923 },
    { lat: 48.4581507, lng: 19.1928677 },
    { lat: 48.458596, lng: 19.1927714 },
    { lat: 48.4595968, lng: 19.1924899 },
    { lat: 48.459988, lng: 19.1922415 },
    { lat: 48.4611351, lng: 19.1919068 },
    { lat: 48.4620663, lng: 19.1920006 },
    { lat: 48.4622483, lng: 19.1919449 },
    { lat: 48.4631167, lng: 19.1921575 },
    { lat: 48.4635507, lng: 19.1919289 },
    { lat: 48.4649818, lng: 19.1921469 },
    { lat: 48.4654046, lng: 19.1926324 },
    { lat: 48.4667784, lng: 19.1937719 },
    { lat: 48.4674205, lng: 19.194659 },
    { lat: 48.4675865, lng: 19.1952643 },
    { lat: 48.4678252, lng: 19.1959018 },
    { lat: 48.4680759, lng: 19.1962793 },
    { lat: 48.4684995, lng: 19.1967331 },
    { lat: 48.4697974, lng: 19.1949249 },
    { lat: 48.4706264, lng: 19.1945232 },
    { lat: 48.4718636, lng: 19.1941198 },
    { lat: 48.472224, lng: 19.1941136 },
    { lat: 48.4732097, lng: 19.1939161 },
    { lat: 48.473656, lng: 19.1942591 },
    { lat: 48.4738472, lng: 19.1946191 },
    { lat: 48.4744867, lng: 19.1950377 },
    { lat: 48.4749756, lng: 19.196067 },
    { lat: 48.4749763, lng: 19.1964489 },
    { lat: 48.4751047, lng: 19.196721 },
    { lat: 48.4756582, lng: 19.1965132 },
    { lat: 48.476678, lng: 19.1963713 },
    { lat: 48.4776022, lng: 19.1964787 },
    { lat: 48.4788234, lng: 19.1962555 },
    { lat: 48.479477, lng: 19.1961837 },
    { lat: 48.4797304, lng: 19.19558 },
    { lat: 48.4805929, lng: 19.1952626 },
    { lat: 48.4808437, lng: 19.1941255 },
    { lat: 48.4817955, lng: 19.1924799 },
    { lat: 48.4824273, lng: 19.192031 },
    { lat: 48.4832787, lng: 19.1912575 },
    { lat: 48.4837418, lng: 19.1898735 },
    { lat: 48.4842497, lng: 19.1894494 },
    { lat: 48.48476, lng: 19.1888206 },
    { lat: 48.4853226, lng: 19.1885697 },
    { lat: 48.4869706, lng: 19.1868934 },
    { lat: 48.4874217, lng: 19.1863678 },
    { lat: 48.4874631, lng: 19.1862243 },
    { lat: 48.4874898, lng: 19.1860554 },
  ],
  Podzámčok: [
    { lat: 48.5251843, lng: 19.1075375 },
    { lat: 48.5248868, lng: 19.1076543 },
    { lat: 48.5247304, lng: 19.1076595 },
    { lat: 48.5245206, lng: 19.1075637 },
    { lat: 48.5238033, lng: 19.1066979 },
    { lat: 48.5226333, lng: 19.1061836 },
    { lat: 48.5223018, lng: 19.105912 },
    { lat: 48.5220026, lng: 19.1052878 },
    { lat: 48.5219332, lng: 19.1048903 },
    { lat: 48.521916, lng: 19.104028 },
    { lat: 48.5211105, lng: 19.1025862 },
    { lat: 48.5210964, lng: 19.102304 },
    { lat: 48.520994, lng: 19.1019034 },
    { lat: 48.5208177, lng: 19.1014838 },
    { lat: 48.5206212, lng: 19.100799 },
    { lat: 48.5205137, lng: 19.1006557 },
    { lat: 48.5204589, lng: 19.1003052 },
    { lat: 48.5202728, lng: 19.0997565 },
    { lat: 48.5198154, lng: 19.0994953 },
    { lat: 48.5196188, lng: 19.0992806 },
    { lat: 48.519428, lng: 19.0992542 },
    { lat: 48.5193517, lng: 19.0991689 },
    { lat: 48.519301, lng: 19.0989197 },
    { lat: 48.5190289, lng: 19.0986872 },
    { lat: 48.5187191, lng: 19.0983062 },
    { lat: 48.5184287, lng: 19.0981121 },
    { lat: 48.5181992, lng: 19.0980765 },
    { lat: 48.5171715, lng: 19.0982225 },
    { lat: 48.5166388, lng: 19.0981184 },
    { lat: 48.5162871, lng: 19.0979639 },
    { lat: 48.5162575, lng: 19.0976629 },
    { lat: 48.5162827, lng: 19.0972646 },
    { lat: 48.5162247, lng: 19.0970525 },
    { lat: 48.5163156, lng: 19.0967954 },
    { lat: 48.5163543, lng: 19.0963256 },
    { lat: 48.5164375, lng: 19.0960282 },
    { lat: 48.5164074, lng: 19.0958083 },
    { lat: 48.5162375, lng: 19.0957275 },
    { lat: 48.5160858, lng: 19.0960725 },
    { lat: 48.5160004, lng: 19.096169 },
    { lat: 48.5158995, lng: 19.0961363 },
    { lat: 48.515591, lng: 19.0958287 },
    { lat: 48.5154967, lng: 19.095269 },
    { lat: 48.5152606, lng: 19.0950116 },
    { lat: 48.5149509, lng: 19.0950633 },
    { lat: 48.5148003, lng: 19.0948485 },
    { lat: 48.5146583, lng: 19.094824 },
    { lat: 48.5142802, lng: 19.0949983 },
    { lat: 48.5139724, lng: 19.0952012 },
    { lat: 48.5138021, lng: 19.0957887 },
    { lat: 48.5135589, lng: 19.0959772 },
    { lat: 48.5133882, lng: 19.0962806 },
    { lat: 48.513059, lng: 19.0961223 },
    { lat: 48.5129797, lng: 19.0961614 },
    { lat: 48.5127042, lng: 19.096536 },
    { lat: 48.5122178, lng: 19.0967528 },
    { lat: 48.5115528, lng: 19.0968667 },
    { lat: 48.5108254, lng: 19.0968201 },
    { lat: 48.5103783, lng: 19.0965329 },
    { lat: 48.5100821, lng: 19.0961304 },
    { lat: 48.5097932, lng: 19.0958562 },
    { lat: 48.5093845, lng: 19.0957286 },
    { lat: 48.5087519, lng: 19.0957965 },
    { lat: 48.5055983, lng: 19.0942525 },
    { lat: 48.5054264, lng: 19.0940611 },
    { lat: 48.5045231, lng: 19.0941223 },
    { lat: 48.5039744, lng: 19.093992 },
    { lat: 48.5038978, lng: 19.0940426 },
    { lat: 48.5037937, lng: 19.094011 },
    { lat: 48.5037075, lng: 19.0940929 },
    { lat: 48.5035484, lng: 19.0940857 },
    { lat: 48.5033345, lng: 19.0942062 },
    { lat: 48.5033049, lng: 19.0942906 },
    { lat: 48.5032848, lng: 19.0944745 },
    { lat: 48.5033736, lng: 19.0949007 },
    { lat: 48.5032987, lng: 19.0952085 },
    { lat: 48.5030762, lng: 19.0953132 },
    { lat: 48.5025985, lng: 19.0953637 },
    { lat: 48.5024874, lng: 19.0953062 },
    { lat: 48.5022659, lng: 19.0950475 },
    { lat: 48.501502, lng: 19.0947616 },
    { lat: 48.5012765, lng: 19.0945589 },
    { lat: 48.5011696, lng: 19.0945628 },
    { lat: 48.5008603, lng: 19.0949394 },
    { lat: 48.5006932, lng: 19.0949099 },
    { lat: 48.5005013, lng: 19.0945437 },
    { lat: 48.5004057, lng: 19.0944788 },
    { lat: 48.5000126, lng: 19.0948485 },
    { lat: 48.4998501, lng: 19.0948015 },
    { lat: 48.4995888, lng: 19.0945352 },
    { lat: 48.4995249, lng: 19.0942687 },
    { lat: 48.4994016, lng: 19.0941028 },
    { lat: 48.4992392, lng: 19.0940355 },
    { lat: 48.4989268, lng: 19.0940338 },
    { lat: 48.4987678, lng: 19.0938054 },
    { lat: 48.4985837, lng: 19.0937338 },
    { lat: 48.4984284, lng: 19.093761 },
    { lat: 48.4980595, lng: 19.0942306 },
    { lat: 48.4979537, lng: 19.0942554 },
    { lat: 48.4977146, lng: 19.0940514 },
    { lat: 48.4973135, lng: 19.094279 },
    { lat: 48.4970681, lng: 19.0942904 },
    { lat: 48.4967414, lng: 19.0945406 },
    { lat: 48.4966153, lng: 19.0945005 },
    { lat: 48.4962283, lng: 19.0941294 },
    { lat: 48.496051, lng: 19.0940374 },
    { lat: 48.495784, lng: 19.0941974 },
    { lat: 48.4953614, lng: 19.0946763 },
    { lat: 48.4952835, lng: 19.0946971 },
    { lat: 48.4953071, lng: 19.0948096 },
    { lat: 48.4950905, lng: 19.0950989 },
    { lat: 48.4951242, lng: 19.0974684 },
    { lat: 48.4949312, lng: 19.0986155 },
    { lat: 48.4945412, lng: 19.0989795 },
    { lat: 48.4944559, lng: 19.0991888 },
    { lat: 48.4944519, lng: 19.0997438 },
    { lat: 48.4947145, lng: 19.1005327 },
    { lat: 48.494703, lng: 19.1007999 },
    { lat: 48.494797, lng: 19.1013295 },
    { lat: 48.4954584, lng: 19.1041016 },
    { lat: 48.4956115, lng: 19.1050736 },
    { lat: 48.4950677, lng: 19.1062641 },
    { lat: 48.4955443, lng: 19.1069593 },
    { lat: 48.4958575, lng: 19.1076363 },
    { lat: 48.4959129, lng: 19.1080644 },
    { lat: 48.4964037, lng: 19.1097717 },
    { lat: 48.4967683, lng: 19.1114812 },
    { lat: 48.496814, lng: 19.1133081 },
    { lat: 48.4967912, lng: 19.1139362 },
    { lat: 48.496679, lng: 19.1143548 },
    { lat: 48.4966078, lng: 19.1151647 },
    { lat: 48.4968149, lng: 19.1171113 },
    { lat: 48.4974247, lng: 19.1187854 },
    { lat: 48.4975256, lng: 19.1194565 },
    { lat: 48.497908, lng: 19.1202115 },
    { lat: 48.4979471, lng: 19.1211992 },
    { lat: 48.498271, lng: 19.1227058 },
    { lat: 48.4988712, lng: 19.1242368 },
    { lat: 48.5000549, lng: 19.1275059 },
    { lat: 48.5006278, lng: 19.1287159 },
    { lat: 48.5009304, lng: 19.1295678 },
    { lat: 48.5012189, lng: 19.1306075 },
    { lat: 48.5014105, lng: 19.1316094 },
    { lat: 48.5014247, lng: 19.1326909 },
    { lat: 48.5019123, lng: 19.1348657 },
    { lat: 48.5022952, lng: 19.1359045 },
    { lat: 48.5024655, lng: 19.1366319 },
    { lat: 48.503112, lng: 19.1382152 },
    { lat: 48.5033494, lng: 19.1385683 },
    { lat: 48.5033343, lng: 19.1419766 },
    { lat: 48.503322, lng: 19.1424106 },
    { lat: 48.50337, lng: 19.142402 },
    { lat: 48.5038308, lng: 19.1420322 },
    { lat: 48.5041629, lng: 19.1419079 },
    { lat: 48.5042358, lng: 19.1421958 },
    { lat: 48.50578, lng: 19.1426019 },
    { lat: 48.5066368, lng: 19.1420092 },
    { lat: 48.5070433, lng: 19.1421126 },
    { lat: 48.5079091, lng: 19.1426392 },
    { lat: 48.508987, lng: 19.1440084 },
    { lat: 48.5090174, lng: 19.1439399 },
    { lat: 48.5093542, lng: 19.1437781 },
    { lat: 48.5096853, lng: 19.1433353 },
    { lat: 48.5096678, lng: 19.1430294 },
    { lat: 48.5097465, lng: 19.1427067 },
    { lat: 48.5104701, lng: 19.1416998 },
    { lat: 48.5110219, lng: 19.1404579 },
    { lat: 48.5109927, lng: 19.1403918 },
    { lat: 48.5118453, lng: 19.1397402 },
    { lat: 48.5121827, lng: 19.138992 },
    { lat: 48.5125151, lng: 19.1384189 },
    { lat: 48.5126064, lng: 19.1381249 },
    { lat: 48.5123695, lng: 19.1378013 },
    { lat: 48.5125099, lng: 19.1369254 },
    { lat: 48.5126485, lng: 19.1364 },
    { lat: 48.513176, lng: 19.1351924 },
    { lat: 48.5132817, lng: 19.1344612 },
    { lat: 48.5135578, lng: 19.1343687 },
    { lat: 48.5134831, lng: 19.1340685 },
    { lat: 48.5137686, lng: 19.1338651 },
    { lat: 48.5138257, lng: 19.1340165 },
    { lat: 48.5138848, lng: 19.1339839 },
    { lat: 48.5139473, lng: 19.1341064 },
    { lat: 48.5139343, lng: 19.1342298 },
    { lat: 48.5139853, lng: 19.134324 },
    { lat: 48.5141703, lng: 19.1342829 },
    { lat: 48.5141869, lng: 19.1340251 },
    { lat: 48.5143769, lng: 19.13388 },
    { lat: 48.514548, lng: 19.1336236 },
    { lat: 48.5145695, lng: 19.1333997 },
    { lat: 48.5146955, lng: 19.1332341 },
    { lat: 48.5149905, lng: 19.1331384 },
    { lat: 48.5151512, lng: 19.132491 },
    { lat: 48.515274, lng: 19.1322809 },
    { lat: 48.5156739, lng: 19.1309794 },
    { lat: 48.5169597, lng: 19.1303116 },
    { lat: 48.5168097, lng: 19.128057 },
    { lat: 48.5168818, lng: 19.127897 },
    { lat: 48.5168259, lng: 19.1275659 },
    { lat: 48.5168323, lng: 19.1272419 },
    { lat: 48.5169389, lng: 19.1267531 },
    { lat: 48.516905, lng: 19.1267064 },
    { lat: 48.516917, lng: 19.1261962 },
    { lat: 48.5169868, lng: 19.1260373 },
    { lat: 48.5169864, lng: 19.125824 },
    { lat: 48.5171024, lng: 19.1254397 },
    { lat: 48.5171809, lng: 19.1253359 },
    { lat: 48.5172085, lng: 19.1249872 },
    { lat: 48.5171341, lng: 19.1249646 },
    { lat: 48.5173917, lng: 19.1241331 },
    { lat: 48.5175928, lng: 19.1238239 },
    { lat: 48.5176892, lng: 19.1235624 },
    { lat: 48.5180704, lng: 19.1231145 },
    { lat: 48.5188093, lng: 19.1226662 },
    { lat: 48.5189496, lng: 19.1226792 },
    { lat: 48.5189921, lng: 19.1225556 },
    { lat: 48.5191044, lng: 19.1225046 },
    { lat: 48.5192005, lng: 19.1222804 },
    { lat: 48.51938, lng: 19.1222414 },
    { lat: 48.5194348, lng: 19.1222989 },
    { lat: 48.5195947, lng: 19.1222268 },
    { lat: 48.5201418, lng: 19.1222841 },
    { lat: 48.5203858, lng: 19.1222218 },
    { lat: 48.5204557, lng: 19.1221296 },
    { lat: 48.5208266, lng: 19.122244 },
    { lat: 48.5209718, lng: 19.121877 },
    { lat: 48.5210625, lng: 19.1218419 },
    { lat: 48.5212165, lng: 19.1216525 },
    { lat: 48.5213923, lng: 19.121605 },
    { lat: 48.5214401, lng: 19.1216597 },
    { lat: 48.5216639, lng: 19.1215566 },
    { lat: 48.5217642, lng: 19.1212648 },
    { lat: 48.5218829, lng: 19.1212006 },
    { lat: 48.5219811, lng: 19.1210455 },
    { lat: 48.5221077, lng: 19.120937 },
    { lat: 48.5221923, lng: 19.1209847 },
    { lat: 48.5222537, lng: 19.1208132 },
    { lat: 48.5224647, lng: 19.1208186 },
    { lat: 48.5232067, lng: 19.1199147 },
    { lat: 48.5234468, lng: 19.1197543 },
    { lat: 48.5234878, lng: 19.1195562 },
    { lat: 48.5236334, lng: 19.1193784 },
    { lat: 48.5240485, lng: 19.1194547 },
    { lat: 48.5242065, lng: 19.1196403 },
    { lat: 48.5244171, lng: 19.1195426 },
    { lat: 48.5244794, lng: 19.1193454 },
    { lat: 48.5246179, lng: 19.1193668 },
    { lat: 48.5247022, lng: 19.1192893 },
    { lat: 48.524805, lng: 19.1194083 },
    { lat: 48.5248739, lng: 19.1193219 },
    { lat: 48.5252687, lng: 19.1194375 },
    { lat: 48.5253607, lng: 19.119519 },
    { lat: 48.5253766, lng: 19.1196352 },
    { lat: 48.5254678, lng: 19.1197021 },
    { lat: 48.5257119, lng: 19.1196466 },
    { lat: 48.5257991, lng: 19.1195621 },
    { lat: 48.526044, lng: 19.1197031 },
    { lat: 48.5259169, lng: 19.1190502 },
    { lat: 48.5257648, lng: 19.1188465 },
    { lat: 48.5258763, lng: 19.1184142 },
    { lat: 48.5259207, lng: 19.1174728 },
    { lat: 48.5257877, lng: 19.117406 },
    { lat: 48.5259151, lng: 19.1167465 },
    { lat: 48.526584, lng: 19.1161405 },
    { lat: 48.5265312, lng: 19.1156678 },
    { lat: 48.5267725, lng: 19.1158494 },
    { lat: 48.5266903, lng: 19.1151046 },
    { lat: 48.5268363, lng: 19.1148908 },
    { lat: 48.5272557, lng: 19.1150722 },
    { lat: 48.5274434, lng: 19.1152175 },
    { lat: 48.5281627, lng: 19.1162871 },
    { lat: 48.5282076, lng: 19.1162813 },
    { lat: 48.5286189, lng: 19.1168005 },
    { lat: 48.5287706, lng: 19.1165153 },
    { lat: 48.5294288, lng: 19.1170123 },
    { lat: 48.5293213, lng: 19.1178663 },
    { lat: 48.5291195, lng: 19.1186601 },
    { lat: 48.5288977, lng: 19.1190561 },
    { lat: 48.5280777, lng: 19.119864 },
    { lat: 48.5281398, lng: 19.119911 },
    { lat: 48.5280468, lng: 19.1200602 },
    { lat: 48.5280623, lng: 19.1202107 },
    { lat: 48.5280164, lng: 19.1203318 },
    { lat: 48.5280426, lng: 19.1205177 },
    { lat: 48.5284517, lng: 19.1206075 },
    { lat: 48.528589, lng: 19.120532 },
    { lat: 48.5285895, lng: 19.1206802 },
    { lat: 48.5286865, lng: 19.1209069 },
    { lat: 48.5288605, lng: 19.1210275 },
    { lat: 48.5289553, lng: 19.1209855 },
    { lat: 48.5290897, lng: 19.1207865 },
    { lat: 48.5291512, lng: 19.1208219 },
    { lat: 48.5293665, lng: 19.1207514 },
    { lat: 48.5296596, lng: 19.1204371 },
    { lat: 48.5298385, lng: 19.1203622 },
    { lat: 48.5301939, lng: 19.1203443 },
    { lat: 48.5307518, lng: 19.1208178 },
    { lat: 48.5308411, lng: 19.12071 },
    { lat: 48.5311375, lng: 19.1207979 },
    { lat: 48.5316482, lng: 19.1206405 },
    { lat: 48.5320382, lng: 19.1206823 },
    { lat: 48.5321712, lng: 19.1205919 },
    { lat: 48.532438, lng: 19.1205605 },
    { lat: 48.5325731, lng: 19.1202849 },
    { lat: 48.5329404, lng: 19.1190317 },
    { lat: 48.5330336, lng: 19.1180693 },
    { lat: 48.5331615, lng: 19.1172916 },
    { lat: 48.5323803, lng: 19.1171537 },
    { lat: 48.531622, lng: 19.1165935 },
    { lat: 48.5312616, lng: 19.1161916 },
    { lat: 48.5302445, lng: 19.1145058 },
    { lat: 48.5297451, lng: 19.1139652 },
    { lat: 48.5290703, lng: 19.1134764 },
    { lat: 48.5289374, lng: 19.1131606 },
    { lat: 48.5286607, lng: 19.1129215 },
    { lat: 48.5269829, lng: 19.1117608 },
    { lat: 48.5264443, lng: 19.1114723 },
    { lat: 48.5260527, lng: 19.1113745 },
    { lat: 48.5243401, lng: 19.1101934 },
    { lat: 48.5251652, lng: 19.1076528 },
    { lat: 48.5251843, lng: 19.1075375 },
  ],
  HronskáBreznica: [
    { lat: 48.573511, lng: 18.989339 },
    { lat: 48.573416, lng: 18.988916 },
    { lat: 48.573273, lng: 18.988255 },
    { lat: 48.573239, lng: 18.988121 },
    { lat: 48.573267, lng: 18.987912 },
    { lat: 48.573297, lng: 18.987693 },
    { lat: 48.573528, lng: 18.987268 },
    { lat: 48.573529, lng: 18.987223 },
    { lat: 48.57344, lng: 18.987163 },
    { lat: 48.572782, lng: 18.986461 },
    { lat: 48.572517, lng: 18.986216 },
    { lat: 48.572481, lng: 18.98618 },
    { lat: 48.571262, lng: 18.985102 },
    { lat: 48.570832, lng: 18.984406 },
    { lat: 48.570093, lng: 18.98292 },
    { lat: 48.569943, lng: 18.98251 },
    { lat: 48.569135, lng: 18.981551 },
    { lat: 48.568766, lng: 18.981365 },
    { lat: 48.567555, lng: 18.981087 },
    { lat: 48.567118, lng: 18.980869 },
    { lat: 48.566559, lng: 18.980235 },
    { lat: 48.56672, lng: 18.979625 },
    { lat: 48.566973, lng: 18.978115 },
    { lat: 48.567055, lng: 18.977243 },
    { lat: 48.566774, lng: 18.976406 },
    { lat: 48.566745, lng: 18.975727 },
    { lat: 48.566405, lng: 18.975617 },
    { lat: 48.565823, lng: 18.975665 },
    { lat: 48.565261, lng: 18.975291 },
    { lat: 48.564397, lng: 18.975555 },
    { lat: 48.563989, lng: 18.975415 },
    { lat: 48.563275, lng: 18.975444 },
    { lat: 48.56305, lng: 18.97528 },
    { lat: 48.562685, lng: 18.974686 },
    { lat: 48.56244, lng: 18.974588 },
    { lat: 48.561562, lng: 18.974134 },
    { lat: 48.561304, lng: 18.97417 },
    { lat: 48.560945, lng: 18.974144 },
    { lat: 48.560302, lng: 18.973612 },
    { lat: 48.559415, lng: 18.972908 },
    { lat: 48.559246, lng: 18.973393 },
    { lat: 48.558693, lng: 18.974394 },
    { lat: 48.557992, lng: 18.975347 },
    { lat: 48.557518, lng: 18.975714 },
    { lat: 48.557096, lng: 18.976024 },
    { lat: 48.556516, lng: 18.976406 },
    { lat: 48.555875, lng: 18.976725 },
    { lat: 48.555687, lng: 18.976778 },
    { lat: 48.555182, lng: 18.976801 },
    { lat: 48.554799, lng: 18.976677 },
    { lat: 48.553503, lng: 18.976876 },
    { lat: 48.553223, lng: 18.977023 },
    { lat: 48.552708, lng: 18.977396 },
    { lat: 48.552231, lng: 18.9778 },
    { lat: 48.551761, lng: 18.977817 },
    { lat: 48.551012, lng: 18.977997 },
    { lat: 48.550522, lng: 18.978036 },
    { lat: 48.549794, lng: 18.978532 },
    { lat: 48.549692, lng: 18.978642 },
    { lat: 48.548993, lng: 18.979496 },
    { lat: 48.548467, lng: 18.980544 },
    { lat: 48.548129, lng: 18.981036 },
    { lat: 48.547903, lng: 18.981238 },
    { lat: 48.547368, lng: 18.981207 },
    { lat: 48.547037, lng: 18.981121 },
    { lat: 48.546827, lng: 18.980816 },
    { lat: 48.546555, lng: 18.98051 },
    { lat: 48.546252, lng: 18.980454 },
    { lat: 48.546027, lng: 18.980054 },
    { lat: 48.545657, lng: 18.980236 },
    { lat: 48.545326, lng: 18.980462 },
    { lat: 48.544482, lng: 18.980972 },
    { lat: 48.54373, lng: 18.981536 },
    { lat: 48.543684, lng: 18.981524 },
    { lat: 48.543416, lng: 18.981891 },
    { lat: 48.543025, lng: 18.982003 },
    { lat: 48.54273, lng: 18.982047 },
    { lat: 48.541072, lng: 18.982754 },
    { lat: 48.540123, lng: 18.983251 },
    { lat: 48.539707, lng: 18.98351 },
    { lat: 48.539372, lng: 18.983732 },
    { lat: 48.5384, lng: 18.984258 },
    { lat: 48.537701, lng: 18.984667 },
    { lat: 48.53772, lng: 18.984927 },
    { lat: 48.537725, lng: 18.984998 },
    { lat: 48.537703, lng: 18.985003 },
    { lat: 48.537654, lng: 18.985016 },
    { lat: 48.536546, lng: 18.985293 },
    { lat: 48.536489, lng: 18.985307 },
    { lat: 48.535774, lng: 18.985865 },
    { lat: 48.534884, lng: 18.986429 },
    { lat: 48.534712, lng: 18.986506 },
    { lat: 48.533876, lng: 18.986401 },
    { lat: 48.533363, lng: 18.986394 },
    { lat: 48.533069, lng: 18.986615 },
    { lat: 48.532874, lng: 18.986699 },
    { lat: 48.532494, lng: 18.986968 },
    { lat: 48.532515, lng: 18.987608 },
    { lat: 48.53294, lng: 18.988553 },
    { lat: 48.533271, lng: 18.9889 },
    { lat: 48.533826, lng: 18.989125 },
    { lat: 48.534646, lng: 18.989947 },
    { lat: 48.534997, lng: 18.990016 },
    { lat: 48.535502, lng: 18.989693 },
    { lat: 48.536223, lng: 18.989784 },
    { lat: 48.53675, lng: 18.990334 },
    { lat: 48.537115, lng: 18.990941 },
    { lat: 48.537886, lng: 18.992069 },
    { lat: 48.538141, lng: 18.992791 },
    { lat: 48.53909, lng: 18.994345 },
    { lat: 48.539556, lng: 18.994993 },
    { lat: 48.540202, lng: 18.996428 },
    { lat: 48.540289, lng: 18.99685 },
    { lat: 48.540427, lng: 18.997402 },
    { lat: 48.540387, lng: 18.998627 },
    { lat: 48.540314, lng: 18.999577 },
    { lat: 48.540439, lng: 19.000189 },
    { lat: 48.540454, lng: 19.000264 },
    { lat: 48.54049, lng: 19.000442 },
    { lat: 48.540602, lng: 19.000907 },
    { lat: 48.540769, lng: 19.001605 },
    { lat: 48.54091, lng: 19.001929 },
    { lat: 48.540976, lng: 19.002114 },
    { lat: 48.541149, lng: 19.002404 },
    { lat: 48.541363, lng: 19.003167 },
    { lat: 48.541373, lng: 19.003204 },
    { lat: 48.541375, lng: 19.003209 },
    { lat: 48.541382, lng: 19.003234 },
    { lat: 48.541432, lng: 19.003415 },
    { lat: 48.54144, lng: 19.003443 },
    { lat: 48.541453, lng: 19.003476 },
    { lat: 48.54146, lng: 19.003493 },
    { lat: 48.541524, lng: 19.003657 },
    { lat: 48.541576, lng: 19.00396 },
    { lat: 48.541581, lng: 19.003995 },
    { lat: 48.541586, lng: 19.004024 },
    { lat: 48.541592, lng: 19.004058 },
    { lat: 48.541594, lng: 19.004074 },
    { lat: 48.541604, lng: 19.004118 },
    { lat: 48.54162, lng: 19.00419 },
    { lat: 48.541661, lng: 19.004371 },
    { lat: 48.541709, lng: 19.004583 },
    { lat: 48.541751, lng: 19.004772 },
    { lat: 48.54176, lng: 19.004811 },
    { lat: 48.541773, lng: 19.004868 },
    { lat: 48.541808, lng: 19.005027 },
    { lat: 48.541814, lng: 19.005051 },
    { lat: 48.541861, lng: 19.005331 },
    { lat: 48.541861, lng: 19.005332 },
    { lat: 48.542016, lng: 19.006257 },
    { lat: 48.542108, lng: 19.007879 },
    { lat: 48.542215, lng: 19.008941 },
    { lat: 48.542501, lng: 19.009537 },
    { lat: 48.542835, lng: 19.010353 },
    { lat: 48.542974, lng: 19.011024 },
    { lat: 48.543035, lng: 19.011274 },
    { lat: 48.543448, lng: 19.011921 },
    { lat: 48.543529, lng: 19.01261 },
    { lat: 48.543465, lng: 19.013556 },
    { lat: 48.543421, lng: 19.013862 },
    { lat: 48.543248, lng: 19.014211 },
    { lat: 48.5434805, lng: 19.014724 },
    { lat: 48.5438538, lng: 19.0151722 },
    { lat: 48.5447679, lng: 19.0157246 },
    { lat: 48.5453404, lng: 19.0161649 },
    { lat: 48.5461028, lng: 19.0165244 },
    { lat: 48.5470657, lng: 19.0165503 },
    { lat: 48.5480071, lng: 19.016816 },
    { lat: 48.5486789, lng: 19.0167657 },
    { lat: 48.549511, lng: 19.0168358 },
    { lat: 48.5501519, lng: 19.0172228 },
    { lat: 48.5506319, lng: 19.0172411 },
    { lat: 48.5514686, lng: 19.0174014 },
    { lat: 48.5524254, lng: 19.0173269 },
    { lat: 48.5530915, lng: 19.0176776 },
    { lat: 48.5544399, lng: 19.0175454 },
    { lat: 48.5552984, lng: 19.017552 },
    { lat: 48.5568825, lng: 19.017753 },
    { lat: 48.5584156, lng: 19.0170334 },
    { lat: 48.5587793, lng: 19.0171397 },
    { lat: 48.5591069, lng: 19.0171744 },
    { lat: 48.5592793, lng: 19.0171269 },
    { lat: 48.5596982, lng: 19.0173372 },
    { lat: 48.561131, lng: 19.0174716 },
    { lat: 48.560894, lng: 19.0181341 },
    { lat: 48.5607592, lng: 19.0187251 },
    { lat: 48.560748, lng: 19.019061 },
    { lat: 48.5606609, lng: 19.0192119 },
    { lat: 48.5604729, lng: 19.0193723 },
    { lat: 48.5603798, lng: 19.0201848 },
    { lat: 48.5602795, lng: 19.0202294 },
    { lat: 48.5602184, lng: 19.0204269 },
    { lat: 48.5602805, lng: 19.0209882 },
    { lat: 48.5601793, lng: 19.0214639 },
    { lat: 48.5602286, lng: 19.0219672 },
    { lat: 48.5600804, lng: 19.0230328 },
    { lat: 48.5601259, lng: 19.0242256 },
    { lat: 48.5600746, lng: 19.0248366 },
    { lat: 48.5601274, lng: 19.0250362 },
    { lat: 48.5597496, lng: 19.0253443 },
    { lat: 48.5596638, lng: 19.0272405 },
    { lat: 48.5597193, lng: 19.0279428 },
    { lat: 48.5598608, lng: 19.0284904 },
    { lat: 48.5615569, lng: 19.0279386 },
    { lat: 48.5617808, lng: 19.0278389 },
    { lat: 48.5622585, lng: 19.0256885 },
    { lat: 48.5622144, lng: 19.0249279 },
    { lat: 48.5615124, lng: 19.021157 },
    { lat: 48.5613997, lng: 19.0204662 },
    { lat: 48.5613433, lng: 19.0197516 },
    { lat: 48.5613643, lng: 19.0187169 },
    { lat: 48.5614084, lng: 19.0184528 },
    { lat: 48.5614236, lng: 19.0167678 },
    { lat: 48.56154, lng: 19.0155681 },
    { lat: 48.561709, lng: 19.0146973 },
    { lat: 48.5620648, lng: 19.0136815 },
    { lat: 48.5622277, lng: 19.0134034 },
    { lat: 48.5637577, lng: 19.0157227 },
    { lat: 48.5643096, lng: 19.0150443 },
    { lat: 48.5642916, lng: 19.0146644 },
    { lat: 48.5643465, lng: 19.0144805 },
    { lat: 48.5646939, lng: 19.0142395 },
    { lat: 48.5656762, lng: 19.0129485 },
    { lat: 48.5665297, lng: 19.011343 },
    { lat: 48.5669627, lng: 19.010319 },
    { lat: 48.5677314, lng: 19.0075523 },
    { lat: 48.5680752, lng: 19.0066819 },
    { lat: 48.568146, lng: 19.006609 },
    { lat: 48.568458, lng: 19.0065852 },
    { lat: 48.5684595, lng: 19.0065021 },
    { lat: 48.5689176, lng: 19.0056852 },
    { lat: 48.569348, lng: 19.0050123 },
    { lat: 48.5708738, lng: 19.001499 },
    { lat: 48.5711505, lng: 19.0006195 },
    { lat: 48.5712355, lng: 19.0002021 },
    { lat: 48.5713181, lng: 18.9994963 },
    { lat: 48.5713174, lng: 18.9983507 },
    { lat: 48.571279, lng: 18.997852 },
    { lat: 48.571153, lng: 18.997842 },
    { lat: 48.571096, lng: 18.997837 },
    { lat: 48.57106, lng: 18.997834 },
    { lat: 48.571016, lng: 18.997831 },
    { lat: 48.570924, lng: 18.997824 },
    { lat: 48.570512, lng: 18.997791 },
    { lat: 48.570577, lng: 18.99705 },
    { lat: 48.570641, lng: 18.996105 },
    { lat: 48.570623, lng: 18.995182 },
    { lat: 48.570513, lng: 18.992736 },
    { lat: 48.570498, lng: 18.992482 },
    { lat: 48.570469, lng: 18.9919 },
    { lat: 48.570547, lng: 18.991454 },
    { lat: 48.571237, lng: 18.990564 },
    { lat: 48.571267, lng: 18.990531 },
    { lat: 48.571443, lng: 18.990324 },
    { lat: 48.572649, lng: 18.989645 },
    { lat: 48.573127, lng: 18.989541 },
    { lat: 48.573511, lng: 18.989339 },
  ],
  Lešť: [
    { lat: 48.359667, lng: 19.2326 },
    { lat: 48.359656, lng: 19.232618 },
    { lat: 48.359419, lng: 19.232996 },
    { lat: 48.359036, lng: 19.233605 },
    { lat: 48.357819, lng: 19.235741 },
    { lat: 48.357615, lng: 19.235806 },
    { lat: 48.357508, lng: 19.235788 },
    { lat: 48.357191, lng: 19.235763 },
    { lat: 48.35676, lng: 19.235972 },
    { lat: 48.356633, lng: 19.236123 },
    { lat: 48.356528, lng: 19.236389 },
    { lat: 48.356329, lng: 19.236635 },
    { lat: 48.356178, lng: 19.236966 },
    { lat: 48.356108, lng: 19.237383 },
    { lat: 48.355802, lng: 19.239188 },
    { lat: 48.355547, lng: 19.239834 },
    { lat: 48.355114, lng: 19.241053 },
    { lat: 48.355066, lng: 19.241187 },
    { lat: 48.355039, lng: 19.241315 },
    { lat: 48.354964, lng: 19.241568 },
    { lat: 48.354732, lng: 19.241892 },
    { lat: 48.354306, lng: 19.242322 },
    { lat: 48.353963, lng: 19.2425 },
    { lat: 48.353673, lng: 19.242628 },
    { lat: 48.353499, lng: 19.242807 },
    { lat: 48.353377, lng: 19.242807 },
    { lat: 48.353164, lng: 19.242761 },
    { lat: 48.353053, lng: 19.242768 },
    { lat: 48.352995, lng: 19.242772 },
    { lat: 48.352462, lng: 19.242807 },
    { lat: 48.351586, lng: 19.243239 },
    { lat: 48.351421, lng: 19.24339 },
    { lat: 48.350827, lng: 19.243611 },
    { lat: 48.350831, lng: 19.243653 },
    { lat: 48.350919, lng: 19.244588 },
    { lat: 48.350933, lng: 19.244747 },
    { lat: 48.351023, lng: 19.245712 },
    { lat: 48.35096, lng: 19.245733 },
    { lat: 48.350963, lng: 19.245893 },
    { lat: 48.351004, lng: 19.247612 },
    { lat: 48.350999, lng: 19.248116 },
    { lat: 48.350998, lng: 19.248315 },
    { lat: 48.35098, lng: 19.250283 },
    { lat: 48.35096, lng: 19.250357 },
    { lat: 48.350764, lng: 19.250413 },
    { lat: 48.35073, lng: 19.250385 },
    { lat: 48.350703, lng: 19.25039 },
    { lat: 48.350174, lng: 19.250478 },
    { lat: 48.348196, lng: 19.250941 },
    { lat: 48.347188, lng: 19.251146 },
    { lat: 48.347432, lng: 19.251689 },
    { lat: 48.346234, lng: 19.252018 },
    { lat: 48.345466, lng: 19.252189 },
    { lat: 48.345392, lng: 19.252205 },
    { lat: 48.344926, lng: 19.252263 },
    { lat: 48.344708, lng: 19.251746 },
    { lat: 48.344275, lng: 19.251828 },
    { lat: 48.343991, lng: 19.251953 },
    { lat: 48.343818, lng: 19.252004 },
    { lat: 48.343486, lng: 19.252117 },
    { lat: 48.343114, lng: 19.254351 },
    { lat: 48.342916, lng: 19.254453 },
    { lat: 48.342777, lng: 19.255103 },
    { lat: 48.34276, lng: 19.255188 },
    { lat: 48.342711, lng: 19.255163 },
    { lat: 48.342587, lng: 19.254992 },
    { lat: 48.342289, lng: 19.256434 },
    { lat: 48.342159, lng: 19.256555 },
    { lat: 48.34201, lng: 19.256652 },
    { lat: 48.34152, lng: 19.25675 },
    { lat: 48.341297, lng: 19.256906 },
    { lat: 48.341172, lng: 19.257024 },
    { lat: 48.340903, lng: 19.257148 },
    { lat: 48.340485, lng: 19.25733 },
    { lat: 48.340359, lng: 19.25748 },
    { lat: 48.340419, lng: 19.257703 },
    { lat: 48.340348, lng: 19.257747 },
    { lat: 48.340174, lng: 19.257856 },
    { lat: 48.340234, lng: 19.258081 },
    { lat: 48.340219, lng: 19.258254 },
    { lat: 48.340104, lng: 19.258312 },
    { lat: 48.34003, lng: 19.257925 },
    { lat: 48.339494, lng: 19.258196 },
    { lat: 48.339328, lng: 19.257854 },
    { lat: 48.338953, lng: 19.258239 },
    { lat: 48.338813, lng: 19.258361 },
    { lat: 48.338774, lng: 19.258531 },
    { lat: 48.338658, lng: 19.258603 },
    { lat: 48.33841, lng: 19.258665 },
    { lat: 48.338138, lng: 19.258879 },
    { lat: 48.337883, lng: 19.259345 },
    { lat: 48.337805, lng: 19.25937 },
    { lat: 48.337685, lng: 19.259564 },
    { lat: 48.337543, lng: 19.259628 },
    { lat: 48.337454, lng: 19.259817 },
    { lat: 48.337363, lng: 19.259886 },
    { lat: 48.336743, lng: 19.259876 },
    { lat: 48.336569, lng: 19.26017 },
    { lat: 48.336302, lng: 19.260472 },
    { lat: 48.336063, lng: 19.260675 },
    { lat: 48.335801, lng: 19.26074 },
    { lat: 48.335718, lng: 19.260956 },
    { lat: 48.33556, lng: 19.261233 },
    { lat: 48.335275, lng: 19.26159 },
    { lat: 48.335224, lng: 19.261322 },
    { lat: 48.335118, lng: 19.261628 },
    { lat: 48.33456, lng: 19.263626 },
    { lat: 48.334539, lng: 19.263791 },
    { lat: 48.334598, lng: 19.264152 },
    { lat: 48.334706, lng: 19.264538 },
    { lat: 48.334869, lng: 19.264639 },
    { lat: 48.33486, lng: 19.264723 },
    { lat: 48.334713, lng: 19.264779 },
    { lat: 48.334563, lng: 19.264746 },
    { lat: 48.334255, lng: 19.264606 },
    { lat: 48.334128, lng: 19.264487 },
    { lat: 48.334032, lng: 19.264519 },
    { lat: 48.33384, lng: 19.264452 },
    { lat: 48.333687, lng: 19.264134 },
    { lat: 48.33356, lng: 19.264049 },
    { lat: 48.333231, lng: 19.26423 },
    { lat: 48.333182, lng: 19.264213 },
    { lat: 48.332972, lng: 19.264065 },
    { lat: 48.332921, lng: 19.263617 },
    { lat: 48.332879, lng: 19.263553 },
    { lat: 48.332676, lng: 19.263456 },
    { lat: 48.332348, lng: 19.263361 },
    { lat: 48.332135, lng: 19.263085 },
    { lat: 48.332027, lng: 19.262971 },
    { lat: 48.331984, lng: 19.26294 },
    { lat: 48.331951, lng: 19.262948 },
    { lat: 48.331919, lng: 19.262987 },
    { lat: 48.331819, lng: 19.263176 },
    { lat: 48.331718, lng: 19.263385 },
    { lat: 48.331497, lng: 19.26353 },
    { lat: 48.331309, lng: 19.263626 },
    { lat: 48.331112, lng: 19.263618 },
    { lat: 48.331066, lng: 19.26362 },
    { lat: 48.330927, lng: 19.263482 },
    { lat: 48.330962, lng: 19.263394 },
    { lat: 48.33083, lng: 19.26331 },
    { lat: 48.330676, lng: 19.263344 },
    { lat: 48.330622, lng: 19.263446 },
    { lat: 48.330616, lng: 19.263565 },
    { lat: 48.330437, lng: 19.263564 },
    { lat: 48.330278, lng: 19.263571 },
    { lat: 48.33024, lng: 19.263426 },
    { lat: 48.330174, lng: 19.263311 },
    { lat: 48.330092, lng: 19.2633 },
    { lat: 48.33, lng: 19.263362 },
    { lat: 48.329768, lng: 19.263355 },
    { lat: 48.329479, lng: 19.263353 },
    { lat: 48.329388, lng: 19.263441 },
    { lat: 48.329037, lng: 19.263542 },
    { lat: 48.328916, lng: 19.263681 },
    { lat: 48.328916, lng: 19.263709 },
    { lat: 48.328822, lng: 19.263718 },
    { lat: 48.328701, lng: 19.26361 },
    { lat: 48.328709, lng: 19.263566 },
    { lat: 48.328678, lng: 19.263563 },
    { lat: 48.328655, lng: 19.26356 },
    { lat: 48.328703, lng: 19.263474 },
    { lat: 48.328749, lng: 19.263296 },
    { lat: 48.328768, lng: 19.263154 },
    { lat: 48.328784, lng: 19.263053 },
    { lat: 48.32881, lng: 19.262873 },
    { lat: 48.328554, lng: 19.262598 },
    { lat: 48.328555, lng: 19.262576 },
    { lat: 48.328558, lng: 19.262522 },
    { lat: 48.328477, lng: 19.262439 },
    { lat: 48.328373, lng: 19.262669 },
    { lat: 48.328336, lng: 19.262702 },
    { lat: 48.328346, lng: 19.262832 },
    { lat: 48.328291, lng: 19.262859 },
    { lat: 48.328331, lng: 19.263041 },
    { lat: 48.328387, lng: 19.263059 },
    { lat: 48.328432, lng: 19.263447 },
    { lat: 48.328524, lng: 19.263434 },
    { lat: 48.328538, lng: 19.263558 },
    { lat: 48.328452, lng: 19.263651 },
    { lat: 48.328318, lng: 19.263678 },
    { lat: 48.328227, lng: 19.263846 },
    { lat: 48.328157, lng: 19.263889 },
    { lat: 48.328128, lng: 19.263898 },
    { lat: 48.327949, lng: 19.263766 },
    { lat: 48.327859, lng: 19.263743 },
    { lat: 48.32773, lng: 19.263736 },
    { lat: 48.327575, lng: 19.263686 },
    { lat: 48.327495, lng: 19.2636 },
    { lat: 48.327361, lng: 19.263565 },
    { lat: 48.327329, lng: 19.26346 },
    { lat: 48.327192, lng: 19.263326 },
    { lat: 48.327172, lng: 19.263218 },
    { lat: 48.327153, lng: 19.263062 },
    { lat: 48.326969, lng: 19.262934 },
    { lat: 48.326578, lng: 19.262901 },
    { lat: 48.326314, lng: 19.262879 },
    { lat: 48.326249, lng: 19.262927 },
    { lat: 48.326096, lng: 19.262873 },
    { lat: 48.325858, lng: 19.262751 },
    { lat: 48.32567, lng: 19.262737 },
    { lat: 48.325637, lng: 19.262737 },
    { lat: 48.325455, lng: 19.262975 },
    { lat: 48.325463, lng: 19.262999 },
    { lat: 48.325476, lng: 19.263038 },
    { lat: 48.325512, lng: 19.263149 },
    { lat: 48.325552, lng: 19.263305 },
    { lat: 48.325421, lng: 19.263541 },
    { lat: 48.325411, lng: 19.263566 },
    { lat: 48.325401, lng: 19.263591 },
    { lat: 48.32512, lng: 19.264264 },
    { lat: 48.325078, lng: 19.264162 },
    { lat: 48.324959, lng: 19.264344 },
    { lat: 48.324884, lng: 19.264388 },
    { lat: 48.324904, lng: 19.264471 },
    { lat: 48.324919, lng: 19.264517 },
    { lat: 48.324893, lng: 19.264541 },
    { lat: 48.324917, lng: 19.264737 },
    { lat: 48.324768, lng: 19.26486 },
    { lat: 48.324589, lng: 19.264992 },
    { lat: 48.324411, lng: 19.265103 },
    { lat: 48.324152, lng: 19.265272 },
    { lat: 48.324057, lng: 19.2653 },
    { lat: 48.323983, lng: 19.265335 },
    { lat: 48.323883, lng: 19.265382 },
    { lat: 48.323571, lng: 19.26549 },
    { lat: 48.323454, lng: 19.26558 },
    { lat: 48.323377, lng: 19.26557 },
    { lat: 48.322928, lng: 19.265772 },
    { lat: 48.322877, lng: 19.26578 },
    { lat: 48.322732, lng: 19.265828 },
    { lat: 48.322283, lng: 19.265955 },
    { lat: 48.322226, lng: 19.265959 },
    { lat: 48.322046, lng: 19.265946 },
    { lat: 48.321722, lng: 19.265925 },
    { lat: 48.321664, lng: 19.266 },
    { lat: 48.321281, lng: 19.265905 },
    { lat: 48.321234, lng: 19.265843 },
    { lat: 48.32115, lng: 19.265812 },
    { lat: 48.320885, lng: 19.265652 },
    { lat: 48.320787, lng: 19.265507 },
    { lat: 48.320501, lng: 19.265746 },
    { lat: 48.3204, lng: 19.265752 },
    { lat: 48.319988, lng: 19.265838 },
    { lat: 48.319503, lng: 19.265952 },
    { lat: 48.318895, lng: 19.266098 },
    { lat: 48.318757, lng: 19.266195 },
    { lat: 48.318627, lng: 19.26619 },
    { lat: 48.318374, lng: 19.266278 },
    { lat: 48.318241, lng: 19.266369 },
    { lat: 48.318109, lng: 19.266375 },
    { lat: 48.318031, lng: 19.266332 },
    { lat: 48.317812, lng: 19.266386 },
    { lat: 48.317541, lng: 19.266488 },
    { lat: 48.317257, lng: 19.26646 },
    { lat: 48.316623, lng: 19.266313 },
    { lat: 48.316402, lng: 19.26623 },
    { lat: 48.316401, lng: 19.266155 },
    { lat: 48.316147, lng: 19.266054 },
    { lat: 48.316034, lng: 19.266037 },
    { lat: 48.31599, lng: 19.265969 },
    { lat: 48.315859, lng: 19.26586 },
    { lat: 48.315529, lng: 19.265697 },
    { lat: 48.31446, lng: 19.265547 },
    { lat: 48.314074, lng: 19.265599 },
    { lat: 48.313343, lng: 19.265698 },
    { lat: 48.313237, lng: 19.265678 },
    { lat: 48.313032, lng: 19.265649 },
    { lat: 48.312826, lng: 19.265645 },
    { lat: 48.312215, lng: 19.265717 },
    { lat: 48.311978, lng: 19.265856 },
    { lat: 48.311699, lng: 19.26596 },
    { lat: 48.311618, lng: 19.266014 },
    { lat: 48.311354, lng: 19.266216 },
    { lat: 48.311229, lng: 19.266256 },
    { lat: 48.311084, lng: 19.266412 },
    { lat: 48.310958, lng: 19.266425 },
    { lat: 48.310879, lng: 19.266527 },
    { lat: 48.310477, lng: 19.266861 },
    { lat: 48.3103, lng: 19.266948 },
    { lat: 48.309359, lng: 19.267693 },
    { lat: 48.308865, lng: 19.268023 },
    { lat: 48.308724, lng: 19.268059 },
    { lat: 48.308718, lng: 19.267908 },
    { lat: 48.308673, lng: 19.267951 },
    { lat: 48.308158, lng: 19.267969 },
    { lat: 48.308107, lng: 19.267961 },
    { lat: 48.307881, lng: 19.267661 },
    { lat: 48.307638, lng: 19.267707 },
    { lat: 48.307551, lng: 19.267792 },
    { lat: 48.307484, lng: 19.26792 },
    { lat: 48.307448, lng: 19.268009 },
    { lat: 48.307391, lng: 19.268181 },
    { lat: 48.30732, lng: 19.268324 },
    { lat: 48.307128, lng: 19.268488 },
    { lat: 48.306715, lng: 19.268382 },
    { lat: 48.306319, lng: 19.268178 },
    { lat: 48.306188, lng: 19.26794 },
    { lat: 48.306013, lng: 19.267382 },
    { lat: 48.305907, lng: 19.266805 },
    { lat: 48.305854, lng: 19.266595 },
    { lat: 48.305728, lng: 19.266518 },
    { lat: 48.30531, lng: 19.266422 },
    { lat: 48.305046, lng: 19.266211 },
    { lat: 48.30478, lng: 19.266188 },
    { lat: 48.304508, lng: 19.266143 },
    { lat: 48.304349, lng: 19.266133 },
    { lat: 48.304072, lng: 19.266175 },
    { lat: 48.303875, lng: 19.266217 },
    { lat: 48.303693, lng: 19.266285 },
    { lat: 48.303446, lng: 19.266438 },
    { lat: 48.303261, lng: 19.266643 },
    { lat: 48.303096, lng: 19.266912 },
    { lat: 48.303047, lng: 19.267146 },
    { lat: 48.302957, lng: 19.267461 },
    { lat: 48.302916, lng: 19.267837 },
    { lat: 48.302879, lng: 19.26816 },
    { lat: 48.302818, lng: 19.268558 },
    { lat: 48.302748, lng: 19.26891 },
    { lat: 48.302678, lng: 19.269227 },
    { lat: 48.302582, lng: 19.269581 },
    { lat: 48.302516, lng: 19.269708 },
    { lat: 48.302489, lng: 19.269759 },
    { lat: 48.30226, lng: 19.270198 },
    { lat: 48.30208, lng: 19.270419 },
    { lat: 48.30187, lng: 19.270628 },
    { lat: 48.301649, lng: 19.270754 },
    { lat: 48.301414, lng: 19.27089 },
    { lat: 48.301157, lng: 19.270944 },
    { lat: 48.300907, lng: 19.270931 },
    { lat: 48.30067, lng: 19.270875 },
    { lat: 48.300415, lng: 19.270793 },
    { lat: 48.300237, lng: 19.27075 },
    { lat: 48.30003, lng: 19.270725 },
    { lat: 48.299824, lng: 19.270704 },
    { lat: 48.299605, lng: 19.270671 },
    { lat: 48.299376, lng: 19.270848 },
    { lat: 48.299239, lng: 19.271017 },
    { lat: 48.299219, lng: 19.271054 },
    { lat: 48.299194, lng: 19.2711 },
    { lat: 48.299097, lng: 19.27128 },
    { lat: 48.29894, lng: 19.271526 },
    { lat: 48.298787, lng: 19.271793 },
    { lat: 48.298686, lng: 19.272089 },
    { lat: 48.298602, lng: 19.272418 },
    { lat: 48.29852, lng: 19.272735 },
    { lat: 48.29843, lng: 19.273086 },
    { lat: 48.298346, lng: 19.273443 },
    { lat: 48.298251, lng: 19.273814 },
    { lat: 48.298159, lng: 19.274161 },
    { lat: 48.297973, lng: 19.274816 },
    { lat: 48.297884, lng: 19.275175 },
    { lat: 48.297788, lng: 19.275542 },
    { lat: 48.297696, lng: 19.275896 },
    { lat: 48.297603, lng: 19.276278 },
    { lat: 48.297522, lng: 19.276587 },
    { lat: 48.297434, lng: 19.27693 },
    { lat: 48.297343, lng: 19.277289 },
    { lat: 48.297251, lng: 19.277642 },
    { lat: 48.297064, lng: 19.278261 },
    { lat: 48.296918, lng: 19.278624 },
    { lat: 48.296756, lng: 19.278949 },
    { lat: 48.296516, lng: 19.279338 },
    { lat: 48.29629, lng: 19.279574 },
    { lat: 48.296055, lng: 19.279864 },
    { lat: 48.295804, lng: 19.28017 },
    { lat: 48.29557, lng: 19.280446 },
    { lat: 48.295341, lng: 19.280716 },
    { lat: 48.294944, lng: 19.281189 },
    { lat: 48.294692, lng: 19.281456 },
    { lat: 48.294425, lng: 19.281719 },
    { lat: 48.294183, lng: 19.281915 },
    { lat: 48.29391, lng: 19.282152 },
    { lat: 48.293401, lng: 19.282527 },
    { lat: 48.29321, lng: 19.282678 },
    { lat: 48.292738, lng: 19.283096 },
    { lat: 48.292442, lng: 19.283371 },
    { lat: 48.292192, lng: 19.283634 },
    { lat: 48.291978, lng: 19.283835 },
    { lat: 48.291731, lng: 19.284017 },
    { lat: 48.29149, lng: 19.284237 },
    { lat: 48.291287, lng: 19.284456 },
    { lat: 48.291107, lng: 19.28465 },
    { lat: 48.290949, lng: 19.284887 },
    { lat: 48.290872, lng: 19.285128 },
    { lat: 48.290813, lng: 19.28539 },
    { lat: 48.290761, lng: 19.285685 },
    { lat: 48.290718, lng: 19.285968 },
    { lat: 48.290672, lng: 19.286317 },
    { lat: 48.290945, lng: 19.287837 },
    { lat: 48.291046, lng: 19.288149 },
    { lat: 48.291132, lng: 19.28855 },
    { lat: 48.291187, lng: 19.288862 },
    { lat: 48.291262, lng: 19.289256 },
    { lat: 48.291327, lng: 19.289592 },
    { lat: 48.291387, lng: 19.289907 },
    { lat: 48.291493, lng: 19.290394 },
    { lat: 48.291577, lng: 19.290781 },
    { lat: 48.291626, lng: 19.291056 },
    { lat: 48.291761, lng: 19.291399 },
    { lat: 48.291786, lng: 19.291459 },
    { lat: 48.291841, lng: 19.291624 },
    { lat: 48.291855, lng: 19.291679 },
    { lat: 48.291944, lng: 19.292028 },
    { lat: 48.291981, lng: 19.292396 },
    { lat: 48.29198, lng: 19.293252 },
    { lat: 48.292046, lng: 19.293602 },
    { lat: 48.292122, lng: 19.293969 },
    { lat: 48.292235, lng: 19.294534 },
    { lat: 48.292301, lng: 19.294919 },
    { lat: 48.292433, lng: 19.295587 },
    { lat: 48.292501, lng: 19.296154 },
    { lat: 48.292486, lng: 19.296473 },
    { lat: 48.292424, lng: 19.2967 },
    { lat: 48.292374, lng: 19.29685 },
    { lat: 48.292288, lng: 19.297014 },
    { lat: 48.292278, lng: 19.297032 },
    { lat: 48.292193, lng: 19.297195 },
    { lat: 48.292049, lng: 19.297434 },
    { lat: 48.291988, lng: 19.297502 },
    { lat: 48.291949, lng: 19.297545 },
    { lat: 48.291902, lng: 19.297597 },
    { lat: 48.291119, lng: 19.298257 },
    { lat: 48.290428, lng: 19.298854 },
    { lat: 48.290129, lng: 19.299141 },
    { lat: 48.28991, lng: 19.299452 },
    { lat: 48.289639, lng: 19.299836 },
    { lat: 48.289471, lng: 19.30015 },
    { lat: 48.289207, lng: 19.300647 },
    { lat: 48.288543, lng: 19.301925 },
    { lat: 48.288011, lng: 19.302979 },
    { lat: 48.287977, lng: 19.303039 },
    { lat: 48.287945, lng: 19.303097 },
    { lat: 48.287722, lng: 19.303488 },
    { lat: 48.28757, lng: 19.303778 },
    { lat: 48.287569, lng: 19.30378 },
    { lat: 48.287338, lng: 19.304238 },
    { lat: 48.287208, lng: 19.304613 },
    { lat: 48.287181, lng: 19.304691 },
    { lat: 48.287149, lng: 19.304808 },
    { lat: 48.286867, lng: 19.305813 },
    { lat: 48.286793, lng: 19.306074 },
    { lat: 48.286453, lng: 19.307266 },
    { lat: 48.286206, lng: 19.308136 },
    { lat: 48.28579, lng: 19.309572 },
    { lat: 48.285745, lng: 19.30973 },
    { lat: 48.285669, lng: 19.310002 },
    { lat: 48.285587, lng: 19.310291 },
    { lat: 48.285466, lng: 19.310713 },
    { lat: 48.285408, lng: 19.310919 },
    { lat: 48.285338, lng: 19.311171 },
    { lat: 48.285139, lng: 19.311871 },
    { lat: 48.285378, lng: 19.312097 },
    { lat: 48.28545, lng: 19.312143 },
    { lat: 48.285533, lng: 19.312196 },
    { lat: 48.28591, lng: 19.312441 },
    { lat: 48.285851, lng: 19.312655 },
    { lat: 48.28583, lng: 19.312729 },
    { lat: 48.285792, lng: 19.31287 },
    { lat: 48.285741, lng: 19.313055 },
    { lat: 48.285683, lng: 19.313261 },
    { lat: 48.285633, lng: 19.313443 },
    { lat: 48.285627, lng: 19.313462 },
    { lat: 48.285685, lng: 19.313499 },
    { lat: 48.285691, lng: 19.313511 },
    { lat: 48.285608, lng: 19.313806 },
    { lat: 48.285501, lng: 19.314043 },
    { lat: 48.285189, lng: 19.313932 },
    { lat: 48.285056, lng: 19.313885 },
    { lat: 48.285009, lng: 19.313868 },
    { lat: 48.284726, lng: 19.313767 },
    { lat: 48.284651, lng: 19.313624 },
    { lat: 48.284556, lng: 19.313981 },
    { lat: 48.284536, lng: 19.314045 },
    { lat: 48.284386, lng: 19.314558 },
    { lat: 48.284358, lng: 19.314707 },
    { lat: 48.284332, lng: 19.314846 },
    { lat: 48.284331, lng: 19.314847 },
    { lat: 48.283898, lng: 19.317084 },
    { lat: 48.283741, lng: 19.317897 },
    { lat: 48.283503, lng: 19.319128 },
    { lat: 48.283249, lng: 19.320433 },
    { lat: 48.282996, lng: 19.321739 },
    { lat: 48.282996, lng: 19.32174 },
    { lat: 48.282988, lng: 19.321782 },
    { lat: 48.282988, lng: 19.321784 },
    { lat: 48.282968, lng: 19.321891 },
    { lat: 48.282958, lng: 19.321936 },
    { lat: 48.282948, lng: 19.321996 },
    { lat: 48.282938, lng: 19.322079 },
    { lat: 48.28284, lng: 19.322772 },
    { lat: 48.28284, lng: 19.322773 },
    { lat: 48.282757, lng: 19.323354 },
    { lat: 48.282719, lng: 19.323618 },
    { lat: 48.282719, lng: 19.323619 },
    { lat: 48.282667, lng: 19.323993 },
    { lat: 48.282662, lng: 19.324033 },
    { lat: 48.282652, lng: 19.324117 },
    { lat: 48.282587, lng: 19.324566 },
    { lat: 48.282587, lng: 19.324568 },
    { lat: 48.282468, lng: 19.325396 },
    { lat: 48.282421, lng: 19.325723 },
    { lat: 48.282448, lng: 19.325829 },
    { lat: 48.282467, lng: 19.325805 },
    { lat: 48.282587, lng: 19.325926 },
    { lat: 48.282797, lng: 19.326212 },
    { lat: 48.282943, lng: 19.326473 },
    { lat: 48.28302, lng: 19.32661 },
    { lat: 48.283205, lng: 19.32683 },
    { lat: 48.283411, lng: 19.327215 },
    { lat: 48.283392, lng: 19.327267 },
    { lat: 48.283399, lng: 19.327331 },
    { lat: 48.283435, lng: 19.327606 },
    { lat: 48.283463, lng: 19.327754 },
    { lat: 48.28355, lng: 19.327911 },
    { lat: 48.283745, lng: 19.328871 },
    { lat: 48.283744, lng: 19.329658 },
    { lat: 48.283732, lng: 19.330307 },
    { lat: 48.283732, lng: 19.330944 },
    { lat: 48.283835, lng: 19.331548 },
    { lat: 48.283895, lng: 19.331973 },
    { lat: 48.284057, lng: 19.333295 },
    { lat: 48.284011, lng: 19.333743 },
    { lat: 48.284008, lng: 19.334112 },
    { lat: 48.28399, lng: 19.334407 },
    { lat: 48.28388, lng: 19.33503 },
    { lat: 48.283666, lng: 19.3356 },
    { lat: 48.283412, lng: 19.336001 },
    { lat: 48.282977, lng: 19.336958 },
    { lat: 48.282912, lng: 19.337179 },
    { lat: 48.282853, lng: 19.337531 },
    { lat: 48.28281, lng: 19.337989 },
    { lat: 48.282819, lng: 19.338228 },
    { lat: 48.282879, lng: 19.3384 },
    { lat: 48.28297, lng: 19.33925 },
    { lat: 48.282927, lng: 19.339545 },
    { lat: 48.282924, lng: 19.339565 },
    { lat: 48.282966, lng: 19.33965 },
    { lat: 48.282595, lng: 19.340068 },
    { lat: 48.282583, lng: 19.340264 },
    { lat: 48.282476, lng: 19.340336 },
    { lat: 48.282264, lng: 19.340713 },
    { lat: 48.282091, lng: 19.341151 },
    { lat: 48.282025, lng: 19.341366 },
    { lat: 48.282032, lng: 19.341418 },
    { lat: 48.282032, lng: 19.341419 },
    { lat: 48.282044, lng: 19.341516 },
    { lat: 48.282046, lng: 19.341534 },
    { lat: 48.282647, lng: 19.342292 },
    { lat: 48.282703, lng: 19.34236 },
    { lat: 48.282894, lng: 19.34309 },
    { lat: 48.282994, lng: 19.343232 },
    { lat: 48.283141, lng: 19.343326 },
    { lat: 48.283303, lng: 19.343478 },
    { lat: 48.283458, lng: 19.343733 },
    { lat: 48.283555, lng: 19.343828 },
    { lat: 48.283658, lng: 19.343986 },
    { lat: 48.283814, lng: 19.344008 },
    { lat: 48.283942, lng: 19.344254 },
    { lat: 48.284167, lng: 19.344539 },
    { lat: 48.284412, lng: 19.344659 },
    { lat: 48.284704, lng: 19.344827 },
    { lat: 48.284894, lng: 19.344983 },
    { lat: 48.284953, lng: 19.344998 },
    { lat: 48.285219, lng: 19.345211 },
    { lat: 48.284777, lng: 19.346332 },
    { lat: 48.285147, lng: 19.346466 },
    { lat: 48.285133, lng: 19.346522 },
    { lat: 48.284818, lng: 19.347247 },
    { lat: 48.284318, lng: 19.348406 },
    { lat: 48.284129, lng: 19.348847 },
    { lat: 48.284133, lng: 19.348855 },
    { lat: 48.284151, lng: 19.348895 },
    { lat: 48.284674, lng: 19.350082 },
    { lat: 48.285087, lng: 19.350721 },
    { lat: 48.285765, lng: 19.352234 },
    { lat: 48.285934, lng: 19.353099 },
    { lat: 48.286092, lng: 19.354004 },
    { lat: 48.286298, lng: 19.355166 },
    { lat: 48.286301, lng: 19.355179 },
    { lat: 48.286306, lng: 19.355206 },
    { lat: 48.286404, lng: 19.355781 },
    { lat: 48.286467, lng: 19.356145 },
    { lat: 48.286484, lng: 19.356124 },
    { lat: 48.286686, lng: 19.355967 },
    { lat: 48.287031, lng: 19.355801 },
    { lat: 48.287118, lng: 19.355759 },
    { lat: 48.287641, lng: 19.355479 },
    { lat: 48.287684, lng: 19.355427 },
    { lat: 48.2885, lng: 19.354414 },
    { lat: 48.28885, lng: 19.353974 },
    { lat: 48.288762, lng: 19.352853 },
    { lat: 48.289081, lng: 19.351321 },
    { lat: 48.289465, lng: 19.350133 },
    { lat: 48.289538, lng: 19.349589 },
    { lat: 48.289661, lng: 19.348653 },
    { lat: 48.289935, lng: 19.347674 },
    { lat: 48.290213, lng: 19.346829 },
    { lat: 48.290245, lng: 19.346732 },
    { lat: 48.290674, lng: 19.346466 },
    { lat: 48.291163, lng: 19.346245 },
    { lat: 48.291359, lng: 19.346254 },
    { lat: 48.292969, lng: 19.346573 },
    { lat: 48.294387, lng: 19.346432 },
    { lat: 48.294577, lng: 19.34601 },
    { lat: 48.295228, lng: 19.344746 },
    { lat: 48.295759, lng: 19.343691 },
    { lat: 48.29579, lng: 19.343639 },
    { lat: 48.295831, lng: 19.343627 },
    { lat: 48.295841, lng: 19.343682 },
    { lat: 48.296575, lng: 19.342939 },
    { lat: 48.297304, lng: 19.342288 },
    { lat: 48.297379, lng: 19.342222 },
    { lat: 48.298266, lng: 19.341294 },
    { lat: 48.299192, lng: 19.340369 },
    { lat: 48.300079, lng: 19.339765 },
    { lat: 48.300624, lng: 19.339685 },
    { lat: 48.301785, lng: 19.33946 },
    { lat: 48.303151, lng: 19.338646 },
    { lat: 48.303713, lng: 19.338333 },
    { lat: 48.304187, lng: 19.338186 },
    { lat: 48.30513, lng: 19.337818 },
    { lat: 48.30591, lng: 19.337598 },
    { lat: 48.306837, lng: 19.337406 },
    { lat: 48.307007, lng: 19.337374 },
    { lat: 48.307402, lng: 19.337282 },
    { lat: 48.308004, lng: 19.337133 },
    { lat: 48.308489, lng: 19.337185 },
    { lat: 48.309001, lng: 19.337198 },
    { lat: 48.309555, lng: 19.337172 },
    { lat: 48.310361, lng: 19.337073 },
    { lat: 48.310941, lng: 19.337024 },
    { lat: 48.311312, lng: 19.336919 },
    { lat: 48.312229, lng: 19.336721 },
    { lat: 48.312901, lng: 19.336797 },
    { lat: 48.313791, lng: 19.336862 },
    { lat: 48.314381, lng: 19.336967 },
    { lat: 48.314777, lng: 19.337116 },
    { lat: 48.315577, lng: 19.337507 },
    { lat: 48.316479, lng: 19.337992 },
    { lat: 48.316871, lng: 19.337943 },
    { lat: 48.317258, lng: 19.337892 },
    { lat: 48.317732, lng: 19.337803 },
    { lat: 48.318137, lng: 19.337857 },
    { lat: 48.318577, lng: 19.33785 },
    { lat: 48.31955, lng: 19.337677 },
    { lat: 48.319976, lng: 19.337503 },
    { lat: 48.320406, lng: 19.337448 },
    { lat: 48.320902, lng: 19.337294 },
    { lat: 48.321466, lng: 19.336915 },
    { lat: 48.321959, lng: 19.336808 },
    { lat: 48.322719, lng: 19.336664 },
    { lat: 48.323592, lng: 19.336429 },
    { lat: 48.32504, lng: 19.336584 },
    { lat: 48.325497, lng: 19.336627 },
    { lat: 48.325533, lng: 19.336797 },
    { lat: 48.325563, lng: 19.336934 },
    { lat: 48.325643, lng: 19.337241 },
    { lat: 48.325821, lng: 19.337624 },
    { lat: 48.324968, lng: 19.339546 },
    { lat: 48.324703, lng: 19.340393 },
    { lat: 48.325483, lng: 19.341242 },
    { lat: 48.325964, lng: 19.341371 },
    { lat: 48.326198, lng: 19.341382 },
    { lat: 48.326229, lng: 19.341466 },
    { lat: 48.326386, lng: 19.34163 },
    { lat: 48.326445, lng: 19.341691 },
    { lat: 48.326501, lng: 19.341764 },
    { lat: 48.326812, lng: 19.34218 },
    { lat: 48.326865, lng: 19.34237 },
    { lat: 48.326909, lng: 19.3425 },
    { lat: 48.326998, lng: 19.342714 },
    { lat: 48.327271, lng: 19.343293 },
    { lat: 48.327579, lng: 19.343673 },
    { lat: 48.328079, lng: 19.344482 },
    { lat: 48.328303, lng: 19.3446 },
    { lat: 48.328513, lng: 19.345009 },
    { lat: 48.328929, lng: 19.345207 },
    { lat: 48.329355, lng: 19.345343 },
    { lat: 48.32948, lng: 19.345411 },
    { lat: 48.329953, lng: 19.345675 },
    { lat: 48.330771, lng: 19.346062 },
    { lat: 48.331268, lng: 19.346454 },
    { lat: 48.331993, lng: 19.34739 },
    { lat: 48.332234, lng: 19.347865 },
    { lat: 48.332418, lng: 19.348118 },
    { lat: 48.332477, lng: 19.348192 },
    { lat: 48.332553, lng: 19.34828 },
    { lat: 48.332744, lng: 19.34846 },
    { lat: 48.332841, lng: 19.34857 },
    { lat: 48.332939, lng: 19.348682 },
    { lat: 48.333013, lng: 19.34877 },
    { lat: 48.33322, lng: 19.348954 },
    { lat: 48.333338, lng: 19.349062 },
    { lat: 48.333582, lng: 19.349279 },
    { lat: 48.33381, lng: 19.349611 },
    { lat: 48.333842, lng: 19.34942 },
    { lat: 48.334166, lng: 19.349549 },
    { lat: 48.334426, lng: 19.349779 },
    { lat: 48.334605, lng: 19.349891 },
    { lat: 48.335039, lng: 19.350082 },
    { lat: 48.33514, lng: 19.350208 },
    { lat: 48.335189, lng: 19.350204 },
    { lat: 48.335225, lng: 19.350196 },
    { lat: 48.335257, lng: 19.350195 },
    { lat: 48.335291, lng: 19.350197 },
    { lat: 48.335409, lng: 19.350199 },
    { lat: 48.335581, lng: 19.350203 },
    { lat: 48.335716, lng: 19.350231 },
    { lat: 48.33588, lng: 19.350259 },
    { lat: 48.335965, lng: 19.350302 },
    { lat: 48.336012, lng: 19.350318 },
    { lat: 48.33605, lng: 19.350337 },
    { lat: 48.336141, lng: 19.35036 },
    { lat: 48.336231, lng: 19.350375 },
    { lat: 48.33631, lng: 19.350374 },
    { lat: 48.336348, lng: 19.350368 },
    { lat: 48.33639, lng: 19.350374 },
    { lat: 48.336428, lng: 19.350384 },
    { lat: 48.336453, lng: 19.350391 },
    { lat: 48.336504, lng: 19.350403 },
    { lat: 48.336973, lng: 19.350189 },
    { lat: 48.338824, lng: 19.35014 },
    { lat: 48.3396, lng: 19.35029 },
    { lat: 48.341036, lng: 19.350896 },
    { lat: 48.34124, lng: 19.35116 },
    { lat: 48.341683, lng: 19.351473 },
    { lat: 48.341738, lng: 19.351621 },
    { lat: 48.342099, lng: 19.352077 },
    { lat: 48.342463, lng: 19.352538 },
    { lat: 48.343311, lng: 19.35268 },
    { lat: 48.343637, lng: 19.352671 },
    { lat: 48.343982, lng: 19.352535 },
    { lat: 48.344502, lng: 19.352191 },
    { lat: 48.344829, lng: 19.351878 },
    { lat: 48.34525, lng: 19.351646 },
    { lat: 48.345667, lng: 19.351538 },
    { lat: 48.345613, lng: 19.352137 },
    { lat: 48.345606, lng: 19.352455 },
    { lat: 48.345597, lng: 19.352546 },
    { lat: 48.34571, lng: 19.35256 },
    { lat: 48.346069, lng: 19.352649 },
    { lat: 48.346129, lng: 19.352999 },
    { lat: 48.346147, lng: 19.353073 },
    { lat: 48.345708, lng: 19.353276 },
    { lat: 48.345443, lng: 19.353468 },
    { lat: 48.345254, lng: 19.353697 },
    { lat: 48.345539, lng: 19.354549 },
    { lat: 48.345867, lng: 19.354312 },
    { lat: 48.346247, lng: 19.354404 },
    { lat: 48.346458, lng: 19.354344 },
    { lat: 48.346495, lng: 19.354332 },
    { lat: 48.347095, lng: 19.354279 },
    { lat: 48.34706, lng: 19.354405 },
    { lat: 48.347241, lng: 19.354518 },
    { lat: 48.347291, lng: 19.354528 },
    { lat: 48.347805, lng: 19.354598 },
    { lat: 48.348248, lng: 19.354663 },
    { lat: 48.348299, lng: 19.354671 },
    { lat: 48.348406, lng: 19.354671 },
    { lat: 48.348441, lng: 19.354665 },
    { lat: 48.348454, lng: 19.355435 },
    { lat: 48.348456, lng: 19.356024 },
    { lat: 48.34846, lng: 19.356348 },
    { lat: 48.348482, lng: 19.356624 },
    { lat: 48.348504, lng: 19.356925 },
    { lat: 48.348549, lng: 19.357466 },
    { lat: 48.348628, lng: 19.358482 },
    { lat: 48.348688, lng: 19.359415 },
    { lat: 48.348644, lng: 19.359436 },
    { lat: 48.348656, lng: 19.359482 },
    { lat: 48.348663, lng: 19.359565 },
    { lat: 48.348673, lng: 19.359703 },
    { lat: 48.348687, lng: 19.359892 },
    { lat: 48.348701, lng: 19.360082 },
    { lat: 48.348718, lng: 19.360292 },
    { lat: 48.348723, lng: 19.360358 },
    { lat: 48.348902, lng: 19.360941 },
    { lat: 48.349026, lng: 19.361757 },
    { lat: 48.349048, lng: 19.361804 },
    { lat: 48.349049, lng: 19.361868 },
    { lat: 48.349051, lng: 19.361922 },
    { lat: 48.349068, lng: 19.36251 },
    { lat: 48.349215, lng: 19.362548 },
    { lat: 48.349612, lng: 19.362694 },
    { lat: 48.349812, lng: 19.362696 },
    { lat: 48.350129, lng: 19.362754 },
    { lat: 48.350252, lng: 19.36278 },
    { lat: 48.350323, lng: 19.362806 },
    { lat: 48.350376, lng: 19.362826 },
    { lat: 48.3505, lng: 19.362883 },
    { lat: 48.350616, lng: 19.362934 },
    { lat: 48.350835, lng: 19.363096 },
    { lat: 48.350914, lng: 19.363368 },
    { lat: 48.350954, lng: 19.363587 },
    { lat: 48.350957, lng: 19.36364 },
    { lat: 48.350962, lng: 19.363683 },
    { lat: 48.350971, lng: 19.36376 },
    { lat: 48.350982, lng: 19.363878 },
    { lat: 48.350995, lng: 19.363986 },
    { lat: 48.351002, lng: 19.364066 },
    { lat: 48.351009, lng: 19.364141 },
    { lat: 48.351022, lng: 19.364293 },
    { lat: 48.351091, lng: 19.364593 },
    { lat: 48.351137, lng: 19.364975 },
    { lat: 48.35114, lng: 19.365041 },
    { lat: 48.351141, lng: 19.365095 },
    { lat: 48.350975, lng: 19.365157 },
    { lat: 48.350989, lng: 19.365309 },
    { lat: 48.350995, lng: 19.365372 },
    { lat: 48.350996, lng: 19.365386 },
    { lat: 48.351002, lng: 19.365453 },
    { lat: 48.351509, lng: 19.365417 },
    { lat: 48.351774, lng: 19.36545 },
    { lat: 48.351834, lng: 19.366102 },
    { lat: 48.351054, lng: 19.366421 },
    { lat: 48.351104, lng: 19.366667 },
    { lat: 48.351141, lng: 19.366837 },
    { lat: 48.351209, lng: 19.367194 },
    { lat: 48.351268, lng: 19.367534 },
    { lat: 48.351294, lng: 19.367698 },
    { lat: 48.351326, lng: 19.367862 },
    { lat: 48.35135, lng: 19.36794 },
    { lat: 48.351325, lng: 19.367964 },
    { lat: 48.35138, lng: 19.368124 },
    { lat: 48.351388, lng: 19.368148 },
    { lat: 48.351419, lng: 19.368325 },
    { lat: 48.351448, lng: 19.368552 },
    { lat: 48.351456, lng: 19.368619 },
    { lat: 48.351476, lng: 19.368714 },
    { lat: 48.35149, lng: 19.368841 },
    { lat: 48.351421, lng: 19.368888 },
    { lat: 48.351448, lng: 19.369027 },
    { lat: 48.351468, lng: 19.369127 },
    { lat: 48.351505, lng: 19.369313 },
    { lat: 48.351505, lng: 19.369614 },
    { lat: 48.351505, lng: 19.369743 },
    { lat: 48.351555, lng: 19.369924 },
    { lat: 48.351552, lng: 19.370017 },
    { lat: 48.351548, lng: 19.370123 },
    { lat: 48.351603, lng: 19.3705 },
    { lat: 48.351613, lng: 19.370557 },
    { lat: 48.351618, lng: 19.370581 },
    { lat: 48.351645, lng: 19.370703 },
    { lat: 48.351686, lng: 19.370801 },
    { lat: 48.351754, lng: 19.370969 },
    { lat: 48.351789, lng: 19.371105 },
    { lat: 48.351813, lng: 19.371202 },
    { lat: 48.351847, lng: 19.371391 },
    { lat: 48.351705, lng: 19.371434 },
    { lat: 48.351923, lng: 19.3718 },
    { lat: 48.35211, lng: 19.372135 },
    { lat: 48.352171, lng: 19.372219 },
    { lat: 48.352373, lng: 19.372526 },
    { lat: 48.352577, lng: 19.372928 },
    { lat: 48.3527, lng: 19.373201 },
    { lat: 48.352814, lng: 19.373422 },
    { lat: 48.352907, lng: 19.373632 },
    { lat: 48.353281, lng: 19.373337 },
    { lat: 48.353678, lng: 19.373051 },
    { lat: 48.353748, lng: 19.373018 },
    { lat: 48.353947, lng: 19.373003 },
    { lat: 48.354061, lng: 19.373026 },
    { lat: 48.354309, lng: 19.373198 },
    { lat: 48.354411, lng: 19.373306 },
    { lat: 48.354518, lng: 19.373429 },
    { lat: 48.354657, lng: 19.37382 },
    { lat: 48.354726, lng: 19.37401 },
    { lat: 48.354826, lng: 19.374096 },
    { lat: 48.354915, lng: 19.374012 },
    { lat: 48.355085, lng: 19.373961 },
    { lat: 48.355421, lng: 19.374631 },
    { lat: 48.355498, lng: 19.374789 },
    { lat: 48.355615, lng: 19.375189 },
    { lat: 48.355675, lng: 19.375514 },
    { lat: 48.356058, lng: 19.375686 },
    { lat: 48.356339, lng: 19.375808 },
    { lat: 48.356496, lng: 19.375872 },
    { lat: 48.356962, lng: 19.37606 },
    { lat: 48.357549, lng: 19.376133 },
    { lat: 48.358555, lng: 19.37626 },
    { lat: 48.359083, lng: 19.376331 },
    { lat: 48.359162, lng: 19.376341 },
    { lat: 48.359334, lng: 19.376365 },
    { lat: 48.359955, lng: 19.376453 },
    { lat: 48.360554, lng: 19.376505 },
    { lat: 48.361015, lng: 19.376597 },
    { lat: 48.361124, lng: 19.376616 },
    { lat: 48.361824, lng: 19.376698 },
    { lat: 48.362404, lng: 19.376777 },
    { lat: 48.362521, lng: 19.377185 },
    { lat: 48.362527, lng: 19.377218 },
    { lat: 48.362705, lng: 19.377769 },
    { lat: 48.363172, lng: 19.377852 },
    { lat: 48.363631, lng: 19.377832 },
    { lat: 48.363709, lng: 19.377826 },
    { lat: 48.364254, lng: 19.37798 },
    { lat: 48.364958, lng: 19.378256 },
    { lat: 48.365547, lng: 19.378478 },
    { lat: 48.366221, lng: 19.378055 },
    { lat: 48.366861, lng: 19.377678 },
    { lat: 48.36716, lng: 19.377579 },
    { lat: 48.367469, lng: 19.3774 },
    { lat: 48.368218, lng: 19.376958 },
    { lat: 48.368431, lng: 19.376833 },
    { lat: 48.368905, lng: 19.376543 },
    { lat: 48.369061, lng: 19.376479 },
    { lat: 48.369481, lng: 19.37603 },
    { lat: 48.369579, lng: 19.375925 },
    { lat: 48.36964, lng: 19.373868 },
    { lat: 48.369944, lng: 19.372844 },
    { lat: 48.370078, lng: 19.371754 },
    { lat: 48.37006, lng: 19.371019 },
    { lat: 48.370136, lng: 19.370548 },
    { lat: 48.370438, lng: 19.369796 },
    { lat: 48.370799, lng: 19.368557 },
    { lat: 48.371148, lng: 19.366959 },
    { lat: 48.371456, lng: 19.366402 },
    { lat: 48.372101, lng: 19.365755 },
    { lat: 48.372808, lng: 19.364601 },
    { lat: 48.373695, lng: 19.363523 },
    { lat: 48.374507, lng: 19.362348 },
    { lat: 48.375202, lng: 19.361536 },
    { lat: 48.375779, lng: 19.361129 },
    { lat: 48.37605, lng: 19.360731 },
    { lat: 48.376381, lng: 19.359988 },
    { lat: 48.376909, lng: 19.359665 },
    { lat: 48.37756, lng: 19.35837 },
    { lat: 48.377689, lng: 19.357366 },
    { lat: 48.377674, lng: 19.357282 },
    { lat: 48.377603, lng: 19.356934 },
    { lat: 48.378581, lng: 19.35712 },
    { lat: 48.378551, lng: 19.357056 },
    { lat: 48.379183, lng: 19.356554 },
    { lat: 48.379055, lng: 19.355709 },
    { lat: 48.379081, lng: 19.355252 },
    { lat: 48.379453, lng: 19.354746 },
    { lat: 48.379707, lng: 19.354852 },
    { lat: 48.379827, lng: 19.354938 },
    { lat: 48.379965, lng: 19.355102 },
    { lat: 48.380146, lng: 19.355406 },
    { lat: 48.380602, lng: 19.35528 },
    { lat: 48.380758, lng: 19.355238 },
    { lat: 48.380907, lng: 19.355277 },
    { lat: 48.381159, lng: 19.355179 },
    { lat: 48.381272, lng: 19.355111 },
    { lat: 48.381504, lng: 19.355004 },
    { lat: 48.382025, lng: 19.35486 },
    { lat: 48.382247, lng: 19.354781 },
    { lat: 48.3827, lng: 19.354459 },
    { lat: 48.382865, lng: 19.354331 },
    { lat: 48.383074, lng: 19.354209 },
    { lat: 48.383398, lng: 19.354026 },
    { lat: 48.383824, lng: 19.353802 },
    { lat: 48.384004, lng: 19.35365 },
    { lat: 48.384021, lng: 19.353691 },
    { lat: 48.384142, lng: 19.35396 },
    { lat: 48.384183, lng: 19.354126 },
    { lat: 48.384238, lng: 19.354345 },
    { lat: 48.384242, lng: 19.354367 },
    { lat: 48.384252, lng: 19.354404 },
    { lat: 48.384256, lng: 19.354423 },
    { lat: 48.384499, lng: 19.355409 },
    { lat: 48.384526, lng: 19.355513 },
    { lat: 48.384557, lng: 19.355541 },
    { lat: 48.384574, lng: 19.355555 },
    { lat: 48.384607, lng: 19.355584 },
    { lat: 48.384627, lng: 19.355601 },
    { lat: 48.384706, lng: 19.355262 },
    { lat: 48.384877, lng: 19.354641 },
    { lat: 48.384967, lng: 19.354318 },
    { lat: 48.385105, lng: 19.353617 },
    { lat: 48.385755, lng: 19.353437 },
    { lat: 48.385983, lng: 19.353406 },
    { lat: 48.386136, lng: 19.353318 },
    { lat: 48.386529, lng: 19.353191 },
    { lat: 48.386623, lng: 19.353202 },
    { lat: 48.386885, lng: 19.353089 },
    { lat: 48.387671, lng: 19.352759 },
    { lat: 48.388154, lng: 19.35255 },
    { lat: 48.388399, lng: 19.352466 },
    { lat: 48.388616, lng: 19.352429 },
    { lat: 48.389194, lng: 19.352438 },
    { lat: 48.390097, lng: 19.353532 },
    { lat: 48.390754, lng: 19.353323 },
    { lat: 48.391009, lng: 19.353128 },
    { lat: 48.39157, lng: 19.35269 },
    { lat: 48.391919, lng: 19.352421 },
    { lat: 48.392332, lng: 19.352201 },
    { lat: 48.392613, lng: 19.351942 },
    { lat: 48.393226, lng: 19.351716 },
    { lat: 48.393755, lng: 19.351535 },
    { lat: 48.394188, lng: 19.351641 },
    { lat: 48.394456, lng: 19.351672 },
    { lat: 48.394899, lng: 19.351705 },
    { lat: 48.395842, lng: 19.351458 },
    { lat: 48.396424, lng: 19.351223 },
    { lat: 48.396832, lng: 19.351014 },
    { lat: 48.39714, lng: 19.350867 },
    { lat: 48.397359, lng: 19.350838 },
    { lat: 48.397838, lng: 19.350651 },
    { lat: 48.398171, lng: 19.35046 },
    { lat: 48.398434, lng: 19.350482 },
    { lat: 48.398675, lng: 19.350271 },
    { lat: 48.399148, lng: 19.350073 },
    { lat: 48.39956, lng: 19.349872 },
    { lat: 48.400059, lng: 19.349535 },
    { lat: 48.400487, lng: 19.349311 },
    { lat: 48.400628, lng: 19.349268 },
    { lat: 48.401903, lng: 19.348875 },
    { lat: 48.402754, lng: 19.349064 },
    { lat: 48.403668, lng: 19.348785 },
    { lat: 48.404085, lng: 19.34878 },
    { lat: 48.40443, lng: 19.348576 },
    { lat: 48.404725, lng: 19.348735 },
    { lat: 48.405015, lng: 19.348637 },
    { lat: 48.405511, lng: 19.348383 },
    { lat: 48.405834, lng: 19.34834 },
    { lat: 48.405845, lng: 19.348344 },
    { lat: 48.405919, lng: 19.348348 },
    { lat: 48.406068, lng: 19.348413 },
    { lat: 48.406299, lng: 19.348478 },
    { lat: 48.406564, lng: 19.348452 },
    { lat: 48.406753, lng: 19.348469 },
    { lat: 48.407021, lng: 19.348769 },
    { lat: 48.40721, lng: 19.348894 },
    { lat: 48.407549, lng: 19.34905 },
    { lat: 48.407835, lng: 19.349308 },
    { lat: 48.407966, lng: 19.349465 },
    { lat: 48.40803, lng: 19.349403 },
    { lat: 48.408517, lng: 19.349108 },
    { lat: 48.408637, lng: 19.349037 },
    { lat: 48.408708, lng: 19.348989 },
    { lat: 48.40927, lng: 19.348585 },
    { lat: 48.409782, lng: 19.348158 },
    { lat: 48.410234, lng: 19.347725 },
    { lat: 48.410516, lng: 19.347522 },
    { lat: 48.410712, lng: 19.347479 },
    { lat: 48.410938, lng: 19.347538 },
    { lat: 48.411021, lng: 19.347561 },
    { lat: 48.411135, lng: 19.347594 },
    { lat: 48.411198, lng: 19.347612 },
    { lat: 48.411336, lng: 19.347618 },
    { lat: 48.411524, lng: 19.347625 },
    { lat: 48.411655, lng: 19.347629 },
    { lat: 48.411853, lng: 19.347605 },
    { lat: 48.411857, lng: 19.347603 },
    { lat: 48.411917, lng: 19.347546 },
    { lat: 48.412021, lng: 19.34752 },
    { lat: 48.41212, lng: 19.347498 },
    { lat: 48.412207, lng: 19.347481 },
    { lat: 48.412306, lng: 19.346994 },
    { lat: 48.412345, lng: 19.346428 },
    { lat: 48.412351, lng: 19.346053 },
    { lat: 48.41262, lng: 19.345779 },
    { lat: 48.412736, lng: 19.345656 },
    { lat: 48.412555, lng: 19.345118 },
    { lat: 48.412971, lng: 19.344951 },
    { lat: 48.413585, lng: 19.344851 },
    { lat: 48.414041, lng: 19.344918 },
    { lat: 48.414075, lng: 19.344928 },
    { lat: 48.414356, lng: 19.344906 },
    { lat: 48.414493, lng: 19.344825 },
    { lat: 48.414686, lng: 19.34462 },
    { lat: 48.414814, lng: 19.344462 },
    { lat: 48.415103, lng: 19.343913 },
    { lat: 48.415735, lng: 19.343522 },
    { lat: 48.416083, lng: 19.343312 },
    { lat: 48.41615, lng: 19.34329 },
    { lat: 48.416249, lng: 19.34326 },
    { lat: 48.416325, lng: 19.34324 },
    { lat: 48.416368, lng: 19.343229 },
    { lat: 48.416418, lng: 19.343203 },
    { lat: 48.416546, lng: 19.343175 },
    { lat: 48.416647, lng: 19.343141 },
    { lat: 48.417061, lng: 19.343005 },
    { lat: 48.417073, lng: 19.343005 },
    { lat: 48.417304, lng: 19.343053 },
    { lat: 48.417346, lng: 19.343051 },
    { lat: 48.417631, lng: 19.34303 },
    { lat: 48.417837, lng: 19.342964 },
    { lat: 48.418053, lng: 19.342972 },
    { lat: 48.41813, lng: 19.342976 },
    { lat: 48.418723, lng: 19.343127 },
    { lat: 48.419919, lng: 19.342607 },
    { lat: 48.420348, lng: 19.34242 },
    { lat: 48.421037, lng: 19.341889 },
    { lat: 48.422153, lng: 19.339092 },
    { lat: 48.422454, lng: 19.338922 },
    { lat: 48.422635, lng: 19.338823 },
    { lat: 48.422795, lng: 19.338735 },
    { lat: 48.423351, lng: 19.338428 },
    { lat: 48.423999, lng: 19.338186 },
    { lat: 48.424411, lng: 19.338021 },
    { lat: 48.424688, lng: 19.337863 },
    { lat: 48.424858, lng: 19.33773 },
    { lat: 48.425278, lng: 19.337716 },
    { lat: 48.425441, lng: 19.337676 },
    { lat: 48.425629, lng: 19.337605 },
    { lat: 48.42575, lng: 19.3374 },
    { lat: 48.425942, lng: 19.337178 },
    { lat: 48.426133, lng: 19.336901 },
    { lat: 48.426212, lng: 19.336804 },
    { lat: 48.426484, lng: 19.336481 },
    { lat: 48.427174, lng: 19.33368 },
    { lat: 48.428281, lng: 19.332199 },
    { lat: 48.428551, lng: 19.331975 },
    { lat: 48.429085, lng: 19.331532 },
    { lat: 48.430055, lng: 19.331431 },
    { lat: 48.431681, lng: 19.332725 },
    { lat: 48.432221, lng: 19.331943 },
    { lat: 48.43265, lng: 19.330286 },
    { lat: 48.432591, lng: 19.329698 },
    { lat: 48.433305, lng: 19.328012 },
    { lat: 48.434102, lng: 19.326408 },
    { lat: 48.434116, lng: 19.326377 },
    { lat: 48.434136, lng: 19.325503 },
    { lat: 48.434917, lng: 19.32455 },
    { lat: 48.436001, lng: 19.323703 },
    { lat: 48.436294, lng: 19.323477 },
    { lat: 48.438071, lng: 19.321791 },
    { lat: 48.438076, lng: 19.321778 },
    { lat: 48.438057, lng: 19.321761 },
    { lat: 48.438115, lng: 19.321632 },
    { lat: 48.438147, lng: 19.321541 },
    { lat: 48.438289, lng: 19.320895 },
    { lat: 48.438378, lng: 19.319542 },
    { lat: 48.438341, lng: 19.319242 },
    { lat: 48.437963, lng: 19.31805 },
    { lat: 48.437979, lng: 19.318039 },
    { lat: 48.437946, lng: 19.316582 },
    { lat: 48.438325, lng: 19.315759 },
    { lat: 48.437911, lng: 19.314341 },
    { lat: 48.437875, lng: 19.314217 },
    { lat: 48.437794, lng: 19.313813 },
    { lat: 48.438016, lng: 19.31309 },
    { lat: 48.43852, lng: 19.311918 },
    { lat: 48.438567, lng: 19.311768 },
    { lat: 48.438347, lng: 19.311398 },
    { lat: 48.437556, lng: 19.310067 },
    { lat: 48.436523, lng: 19.309411 },
    { lat: 48.437028, lng: 19.307737 },
    { lat: 48.437174, lng: 19.30699 },
    { lat: 48.437346, lng: 19.306177 },
    { lat: 48.43766, lng: 19.304534 },
    { lat: 48.437749, lng: 19.303967 },
    { lat: 48.43784, lng: 19.303761 },
    { lat: 48.438006, lng: 19.303417 },
    { lat: 48.438123, lng: 19.302834 },
    { lat: 48.438208, lng: 19.302466 },
    { lat: 48.438456, lng: 19.301435 },
    { lat: 48.438775, lng: 19.299595 },
    { lat: 48.439062, lng: 19.298199 },
    { lat: 48.439243, lng: 19.297803 },
    { lat: 48.439431, lng: 19.297233 },
    { lat: 48.441337, lng: 19.295938 },
    { lat: 48.441917, lng: 19.295606 },
    { lat: 48.442751, lng: 19.295114 },
    { lat: 48.443227, lng: 19.293967 },
    { lat: 48.443235, lng: 19.293954 },
    { lat: 48.442148, lng: 19.290743 },
    { lat: 48.441707, lng: 19.288892 },
    { lat: 48.441895, lng: 19.287848 },
    { lat: 48.442182, lng: 19.285704 },
    { lat: 48.442825, lng: 19.284215 },
    { lat: 48.443789, lng: 19.281962 },
    { lat: 48.444225, lng: 19.279926 },
    { lat: 48.444389, lng: 19.278726 },
    { lat: 48.443981, lng: 19.27564 },
    { lat: 48.4433988, lng: 19.2746874 },
    { lat: 48.4425843, lng: 19.273787 },
    { lat: 48.4424233, lng: 19.2734425 },
    { lat: 48.4421608, lng: 19.2731186 },
    { lat: 48.4422574, lng: 19.2726689 },
    { lat: 48.4422183, lng: 19.2723501 },
    { lat: 48.4416167, lng: 19.2716321 },
    { lat: 48.4409015, lng: 19.2709257 },
    { lat: 48.4404432, lng: 19.2702129 },
    { lat: 48.4404846, lng: 19.2697845 },
    { lat: 48.4407854, lng: 19.2689483 },
    { lat: 48.4410479, lng: 19.2686188 },
    { lat: 48.4413414, lng: 19.2674401 },
    { lat: 48.4412295, lng: 19.2669894 },
    { lat: 48.4409799, lng: 19.2647475 },
    { lat: 48.4408258, lng: 19.2638243 },
    { lat: 48.4408036, lng: 19.262461 },
    { lat: 48.4405817, lng: 19.2619755 },
    { lat: 48.4404962, lng: 19.26149 },
    { lat: 48.4404497, lng: 19.2607638 },
    { lat: 48.4405622, lng: 19.2600592 },
    { lat: 48.4407199, lng: 19.259502 },
    { lat: 48.4407638, lng: 19.2589664 },
    { lat: 48.4407243, lng: 19.2582455 },
    { lat: 48.440886, lng: 19.2575816 },
    { lat: 48.4410038, lng: 19.2561107 },
    { lat: 48.4406405, lng: 19.2549616 },
    { lat: 48.4403333, lng: 19.2542541 },
    { lat: 48.4402341, lng: 19.2535578 },
    { lat: 48.4403163, lng: 19.2421008 },
    { lat: 48.4385174, lng: 19.2409492 },
    { lat: 48.4384094, lng: 19.2395649 },
    { lat: 48.4384242, lng: 19.2393116 },
    { lat: 48.4380855, lng: 19.2371241 },
    { lat: 48.4377991, lng: 19.2361041 },
    { lat: 48.434921, lng: 19.2355196 },
    { lat: 48.4346506, lng: 19.2351088 },
    { lat: 48.4343848, lng: 19.2348545 },
    { lat: 48.4340683, lng: 19.2347931 },
    { lat: 48.4337661, lng: 19.2348398 },
    { lat: 48.43358, lng: 19.2344422 },
    { lat: 48.4334825, lng: 19.2345207 },
    { lat: 48.4325554, lng: 19.2340272 },
    { lat: 48.4325732, lng: 19.2338832 },
    { lat: 48.4326319, lng: 19.233887 },
    { lat: 48.4325988, lng: 19.2335996 },
    { lat: 48.4324229, lng: 19.2331803 },
    { lat: 48.4324521, lng: 19.2330862 },
    { lat: 48.4324017, lng: 19.232445 },
    { lat: 48.4321791, lng: 19.2321128 },
    { lat: 48.4321412, lng: 19.231937 },
    { lat: 48.4320168, lng: 19.2317895 },
    { lat: 48.4315277, lng: 19.2316741 },
    { lat: 48.4313657, lng: 19.2311283 },
    { lat: 48.4307032, lng: 19.2310181 },
    { lat: 48.4306404, lng: 19.2300989 },
    { lat: 48.4303936, lng: 19.2302156 },
    { lat: 48.4301076, lng: 19.230245 },
    { lat: 48.4289834, lng: 19.2298712 },
    { lat: 48.4285776, lng: 19.2298964 },
    { lat: 48.4274622, lng: 19.2296299 },
    { lat: 48.4279295, lng: 19.2311177 },
    { lat: 48.4264106, lng: 19.2324967 },
    { lat: 48.4262872, lng: 19.2323442 },
    { lat: 48.4260854, lng: 19.23179 },
    { lat: 48.4259033, lng: 19.231085 },
    { lat: 48.4252929, lng: 19.2283729 },
    { lat: 48.4252381, lng: 19.228358 },
    { lat: 48.4251032, lng: 19.2280613 },
    { lat: 48.4236528, lng: 19.2267546 },
    { lat: 48.4229743, lng: 19.2257661 },
    { lat: 48.422005, lng: 19.2241518 },
    { lat: 48.4209214, lng: 19.2230583 },
    { lat: 48.4204568, lng: 19.2219949 },
    { lat: 48.4201933, lng: 19.2216101 },
    { lat: 48.4198451, lng: 19.2213103 },
    { lat: 48.4185957, lng: 19.2207714 },
    { lat: 48.4183776, lng: 19.220369 },
    { lat: 48.4183975, lng: 19.2199707 },
    { lat: 48.4186913, lng: 19.219659 },
    { lat: 48.4209612, lng: 19.2192606 },
    { lat: 48.4214656, lng: 19.2193643 },
    { lat: 48.4213125, lng: 19.2186054 },
    { lat: 48.4204782, lng: 19.2183976 },
    { lat: 48.4202166, lng: 19.2185106 },
    { lat: 48.4196096, lng: 19.2182458 },
    { lat: 48.4187395, lng: 19.2181576 },
    { lat: 48.4180491, lng: 19.2180095 },
    { lat: 48.4179557, lng: 19.2175951 },
    { lat: 48.4177485, lng: 19.2175761 },
    { lat: 48.4177429, lng: 19.2172105 },
    { lat: 48.417095, lng: 19.2172394 },
    { lat: 48.416656, lng: 19.2171796 },
    { lat: 48.4165679, lng: 19.2172465 },
    { lat: 48.4165827, lng: 19.2176668 },
    { lat: 48.4164976, lng: 19.2178149 },
    { lat: 48.4159643, lng: 19.2177428 },
    { lat: 48.4157514, lng: 19.2175151 },
    { lat: 48.4153288, lng: 19.2167325 },
    { lat: 48.4146934, lng: 19.2164861 },
    { lat: 48.413459, lng: 19.2155213 },
    { lat: 48.4132029, lng: 19.215352 },
    { lat: 48.4130874, lng: 19.2154094 },
    { lat: 48.4121827, lng: 19.2148465 },
    { lat: 48.4118786, lng: 19.2145533 },
    { lat: 48.411466, lng: 19.2144594 },
    { lat: 48.4111444, lng: 19.214292 },
    { lat: 48.4110464, lng: 19.2141772 },
    { lat: 48.4108669, lng: 19.2137819 },
    { lat: 48.4107308, lng: 19.2136273 },
    { lat: 48.4102129, lng: 19.2133393 },
    { lat: 48.4098941, lng: 19.2129512 },
    { lat: 48.4095226, lng: 19.2126871 },
    { lat: 48.4091559, lng: 19.212612 },
    { lat: 48.4087658, lng: 19.2123553 },
    { lat: 48.4083089, lng: 19.2118849 },
    { lat: 48.408013, lng: 19.2117132 },
    { lat: 48.4073819, lng: 19.2115796 },
    { lat: 48.4072539, lng: 19.2116133 },
    { lat: 48.4066598, lng: 19.2119571 },
    { lat: 48.406426, lng: 19.2119045 },
    { lat: 48.4044331, lng: 19.2133089 },
    { lat: 48.4039889, lng: 19.2134473 },
    { lat: 48.4036511, lng: 19.213475 },
    { lat: 48.4033716, lng: 19.2133626 },
    { lat: 48.4022935, lng: 19.2137009 },
    { lat: 48.4021791, lng: 19.2136426 },
    { lat: 48.4023494, lng: 19.2130239 },
    { lat: 48.4023874, lng: 19.2125556 },
    { lat: 48.4023515, lng: 19.2125366 },
    { lat: 48.4023902, lng: 19.212406 },
    { lat: 48.4023652, lng: 19.2116634 },
    { lat: 48.4024678, lng: 19.2101933 },
    { lat: 48.4022067, lng: 19.2080855 },
    { lat: 48.4021805, lng: 19.2070628 },
    { lat: 48.402366, lng: 19.2046413 },
    { lat: 48.4019834, lng: 19.2018722 },
    { lat: 48.4019308, lng: 19.2006428 },
    { lat: 48.4020737, lng: 19.200059 },
    { lat: 48.4026985, lng: 19.1983207 },
    { lat: 48.4031317, lng: 19.1967433 },
    { lat: 48.4034302, lng: 19.19472 },
    { lat: 48.4035328, lng: 19.1942858 },
    { lat: 48.4037051, lng: 19.1938679 },
    { lat: 48.4037954, lng: 19.1922646 },
    { lat: 48.4037175, lng: 19.1906076 },
    { lat: 48.4037668, lng: 19.1899366 },
    { lat: 48.4039209, lng: 19.1892181 },
    { lat: 48.4043422, lng: 19.1877225 },
    { lat: 48.4044231, lng: 19.1870511 },
    { lat: 48.4048651, lng: 19.1856589 },
    { lat: 48.4051681, lng: 19.1842569 },
    { lat: 48.405259, lng: 19.1832133 },
    { lat: 48.4045309, lng: 19.1829926 },
    { lat: 48.4048403, lng: 19.1804563 },
    { lat: 48.4055791, lng: 19.1806513 },
    { lat: 48.4056486, lng: 19.1800923 },
    { lat: 48.4056902, lng: 19.1795168 },
    { lat: 48.4056851, lng: 19.1779254 },
    { lat: 48.4054196, lng: 19.1707098 },
    { lat: 48.4050853, lng: 19.1717954 },
    { lat: 48.4047935, lng: 19.172073 },
    { lat: 48.4045819, lng: 19.1720824 },
    { lat: 48.4043695, lng: 19.171957 },
    { lat: 48.4033709, lng: 19.170567 },
    { lat: 48.4032867, lng: 19.1703454 },
    { lat: 48.4031459, lng: 19.1694543 },
    { lat: 48.4031004, lng: 19.1693317 },
    { lat: 48.4030297, lng: 19.1693154 },
    { lat: 48.4029413, lng: 19.1695594 },
    { lat: 48.4028699, lng: 19.170329 },
    { lat: 48.4020532, lng: 19.1728994 },
    { lat: 48.4014515, lng: 19.173751 },
    { lat: 48.4005748, lng: 19.1747184 },
    { lat: 48.4002841, lng: 19.1748149 },
    { lat: 48.4000069, lng: 19.1748188 },
    { lat: 48.3991877, lng: 19.1744114 },
    { lat: 48.3988131, lng: 19.1738334 },
    { lat: 48.3986046, lng: 19.173787 },
    { lat: 48.3975337, lng: 19.17402 },
    { lat: 48.3973085, lng: 19.1740227 },
    { lat: 48.3971058, lng: 19.1738884 },
    { lat: 48.3961839, lng: 19.1727061 },
    { lat: 48.3958385, lng: 19.1719202 },
    { lat: 48.3954158, lng: 19.1715543 },
    { lat: 48.3950427, lng: 19.1710967 },
    { lat: 48.3949133, lng: 19.1710132 },
    { lat: 48.394833, lng: 19.1710757 },
    { lat: 48.394689, lng: 19.171766 },
    { lat: 48.3944959, lng: 19.1722199 },
    { lat: 48.3942834, lng: 19.1725273 },
    { lat: 48.3941388, lng: 19.1726097 },
    { lat: 48.3939174, lng: 19.1726028 },
    { lat: 48.3930895, lng: 19.1723164 },
    { lat: 48.391234, lng: 19.1724802 },
    { lat: 48.390965, lng: 19.172606 },
    { lat: 48.391315, lng: 19.173644 },
    { lat: 48.391407, lng: 19.173936 },
    { lat: 48.391557, lng: 19.174376 },
    { lat: 48.391594, lng: 19.174518 },
    { lat: 48.390783, lng: 19.174775 },
    { lat: 48.390589, lng: 19.1749 },
    { lat: 48.390387, lng: 19.175076 },
    { lat: 48.39017, lng: 19.175299 },
    { lat: 48.390048, lng: 19.175387 },
    { lat: 48.389715, lng: 19.175588 },
    { lat: 48.389349, lng: 19.175794 },
    { lat: 48.38924, lng: 19.175914 },
    { lat: 48.389129, lng: 19.176152 },
    { lat: 48.388923, lng: 19.177008 },
    { lat: 48.38884, lng: 19.177523 },
    { lat: 48.388818, lng: 19.177832 },
    { lat: 48.388865, lng: 19.178619 },
    { lat: 48.388912, lng: 19.178925 },
    { lat: 48.38824, lng: 19.182198 },
    { lat: 48.388162, lng: 19.182816 },
    { lat: 48.388069, lng: 19.183559 },
    { lat: 48.387792, lng: 19.184723 },
    { lat: 48.387291, lng: 19.186253 },
    { lat: 48.387111, lng: 19.187085 },
    { lat: 48.386624, lng: 19.18737 },
    { lat: 48.38628, lng: 19.187762 },
    { lat: 48.38618, lng: 19.18786 },
    { lat: 48.386146, lng: 19.187894 },
    { lat: 48.386128, lng: 19.187912 },
    { lat: 48.385689, lng: 19.188328 },
    { lat: 48.385722, lng: 19.188343 },
    { lat: 48.386096, lng: 19.188516 },
    { lat: 48.386386, lng: 19.188656 },
    { lat: 48.386435, lng: 19.188679 },
    { lat: 48.386464, lng: 19.188692 },
    { lat: 48.386447, lng: 19.188729 },
    { lat: 48.386419, lng: 19.188792 },
    { lat: 48.385918, lng: 19.189908 },
    { lat: 48.385513, lng: 19.190803 },
    { lat: 48.385123, lng: 19.191671 },
    { lat: 48.384661, lng: 19.192709 },
    { lat: 48.384017, lng: 19.194093 },
    { lat: 48.383631, lng: 19.194911 },
    { lat: 48.383534, lng: 19.195114 },
    { lat: 48.382788, lng: 19.196676 },
    { lat: 48.382557, lng: 19.196575 },
    { lat: 48.381863, lng: 19.196273 },
    { lat: 48.380982, lng: 19.195914 },
    { lat: 48.380534, lng: 19.195749 },
    { lat: 48.380202, lng: 19.195562 },
    { lat: 48.380034, lng: 19.197063 },
    { lat: 48.380029, lng: 19.197105 },
    { lat: 48.380016, lng: 19.197172 },
    { lat: 48.38001, lng: 19.197236 },
    { lat: 48.379985, lng: 19.197519 },
    { lat: 48.379806, lng: 19.199611 },
    { lat: 48.379613, lng: 19.199732 },
    { lat: 48.379552, lng: 19.199772 },
    { lat: 48.379363, lng: 19.199894 },
    { lat: 48.377857, lng: 19.200576 },
    { lat: 48.377003, lng: 19.201251 },
    { lat: 48.375566, lng: 19.201261 },
    { lat: 48.375561, lng: 19.201867 },
    { lat: 48.375184, lng: 19.201694 },
    { lat: 48.374798, lng: 19.201612 },
    { lat: 48.374231, lng: 19.201562 },
    { lat: 48.373766, lng: 19.201606 },
    { lat: 48.37379, lng: 19.202002 },
    { lat: 48.373705, lng: 19.201983 },
    { lat: 48.3729, lng: 19.201725 },
    { lat: 48.372766, lng: 19.201667 },
    { lat: 48.372752, lng: 19.202169 },
    { lat: 48.372755, lng: 19.202643 },
    { lat: 48.372736, lng: 19.203631 },
    { lat: 48.372686, lng: 19.20484 },
    { lat: 48.372672, lng: 19.205519 },
    { lat: 48.372622, lng: 19.206718 },
    { lat: 48.372596, lng: 19.207992 },
    { lat: 48.372545, lng: 19.209395 },
    { lat: 48.371944, lng: 19.210134 },
    { lat: 48.371084, lng: 19.211191 },
    { lat: 48.370987, lng: 19.211309 },
    { lat: 48.370879, lng: 19.212081 },
    { lat: 48.370774, lng: 19.212962 },
    { lat: 48.370502, lng: 19.213705 },
    { lat: 48.370419, lng: 19.21393 },
    { lat: 48.370386, lng: 19.214019 },
    { lat: 48.370366, lng: 19.214039 },
    { lat: 48.370343, lng: 19.214043 },
    { lat: 48.370303, lng: 19.214603 },
    { lat: 48.370213, lng: 19.215865 },
    { lat: 48.370207, lng: 19.215951 },
    { lat: 48.370255, lng: 19.215988 },
    { lat: 48.370218, lng: 19.216456 },
    { lat: 48.36997, lng: 19.219583 },
    { lat: 48.369831, lng: 19.221335 },
    { lat: 48.369022, lng: 19.221272 },
    { lat: 48.36874, lng: 19.221268 },
    { lat: 48.3684, lng: 19.221209 },
    { lat: 48.367941, lng: 19.22115 },
    { lat: 48.367759, lng: 19.221145 },
    { lat: 48.367331, lng: 19.221073 },
    { lat: 48.367091, lng: 19.221055 },
    { lat: 48.366789, lng: 19.221016 },
    { lat: 48.366348, lng: 19.220939 },
    { lat: 48.365759, lng: 19.220909 },
    { lat: 48.365389, lng: 19.220858 },
    { lat: 48.365087, lng: 19.220798 },
    { lat: 48.365053, lng: 19.221262 },
    { lat: 48.364977, lng: 19.222437 },
    { lat: 48.364917, lng: 19.2233 },
    { lat: 48.364845, lng: 19.22424 },
    { lat: 48.364833, lng: 19.224416 },
    { lat: 48.364827, lng: 19.224623 },
    { lat: 48.364121, lng: 19.224781 },
    { lat: 48.36154, lng: 19.22545 },
    { lat: 48.361855, lng: 19.2265 },
    { lat: 48.36208, lng: 19.227464 },
    { lat: 48.362297, lng: 19.228276 },
    { lat: 48.359791, lng: 19.232396 },
    { lat: 48.359678, lng: 19.232583 },
    { lat: 48.359667, lng: 19.2326 },
  ],
  Lieskovec: [
    { lat: 48.5972197, lng: 19.1624427 },
    { lat: 48.596615, lng: 19.1631015 },
    { lat: 48.5960857, lng: 19.1634289 },
    { lat: 48.5955702, lng: 19.163494 },
    { lat: 48.5950235, lng: 19.1634259 },
    { lat: 48.5945839, lng: 19.1632632 },
    { lat: 48.5928711, lng: 19.1628886 },
    { lat: 48.592312, lng: 19.1623375 },
    { lat: 48.5918955, lng: 19.1625579 },
    { lat: 48.5916689, lng: 19.1625565 },
    { lat: 48.5914562, lng: 19.1623346 },
    { lat: 48.5909682, lng: 19.1610343 },
    { lat: 48.5905533, lng: 19.1600915 },
    { lat: 48.5904302, lng: 19.159929 },
    { lat: 48.5900015, lng: 19.1604242 },
    { lat: 48.5896976, lng: 19.1605939 },
    { lat: 48.589155, lng: 19.1612073 },
    { lat: 48.5877831, lng: 19.163062 },
    { lat: 48.5878782, lng: 19.1634335 },
    { lat: 48.5866509, lng: 19.1646129 },
    { lat: 48.586437, lng: 19.1637463 },
    { lat: 48.5860138, lng: 19.164055 },
    { lat: 48.5853843, lng: 19.1625117 },
    { lat: 48.5847414, lng: 19.1631701 },
    { lat: 48.584074, lng: 19.1637214 },
    { lat: 48.5838835, lng: 19.1634454 },
    { lat: 48.5835632, lng: 19.1625232 },
    { lat: 48.583195, lng: 19.1619591 },
    { lat: 48.5823592, lng: 19.1621608 },
    { lat: 48.5813235, lng: 19.1626423 },
    { lat: 48.5805985, lng: 19.1634187 },
    { lat: 48.5802566, lng: 19.1636431 },
    { lat: 48.5799494, lng: 19.1637661 },
    { lat: 48.5789728, lng: 19.1638753 },
    { lat: 48.5783773, lng: 19.1638703 },
    { lat: 48.5771419, lng: 19.1637311 },
    { lat: 48.5766109, lng: 19.1621301 },
    { lat: 48.5755674, lng: 19.1626854 },
    { lat: 48.5755954, lng: 19.1629788 },
    { lat: 48.575433, lng: 19.1634821 },
    { lat: 48.5753212, lng: 19.1635705 },
    { lat: 48.5749873, lng: 19.1627435 },
    { lat: 48.5747782, lng: 19.1627274 },
    { lat: 48.5750428, lng: 19.1640953 },
    { lat: 48.5751149, lng: 19.1646682 },
    { lat: 48.5751172, lng: 19.1651756 },
    { lat: 48.574716, lng: 19.1647844 },
    { lat: 48.5742421, lng: 19.1645981 },
    { lat: 48.5736118, lng: 19.1647739 },
    { lat: 48.573263, lng: 19.1647663 },
    { lat: 48.5731538, lng: 19.1649038 },
    { lat: 48.5723816, lng: 19.1649766 },
    { lat: 48.5720844, lng: 19.1649372 },
    { lat: 48.5718045, lng: 19.1745344 },
    { lat: 48.571809, lng: 19.1755213 },
    { lat: 48.571839, lng: 19.1760639 },
    { lat: 48.5722158, lng: 19.1791443 },
    { lat: 48.5730159, lng: 19.1853634 },
    { lat: 48.5731119, lng: 19.1869314 },
    { lat: 48.5730493, lng: 19.1879097 },
    { lat: 48.5728705, lng: 19.1890466 },
    { lat: 48.5722535, lng: 19.1915689 },
    { lat: 48.5716957, lng: 19.1927443 },
    { lat: 48.5715589, lng: 19.1936113 },
    { lat: 48.5712923, lng: 19.1945927 },
    { lat: 48.5708475, lng: 19.1947618 },
    { lat: 48.5709172, lng: 19.1952402 },
    { lat: 48.5705227, lng: 19.195634 },
    { lat: 48.5704504, lng: 19.1957933 },
    { lat: 48.570402, lng: 19.1957456 },
    { lat: 48.5703132, lng: 19.1959345 },
    { lat: 48.57027, lng: 19.1961807 },
    { lat: 48.5701745, lng: 19.196329 },
    { lat: 48.5700552, lng: 19.1964455 },
    { lat: 48.5699832, lng: 19.1964019 },
    { lat: 48.5698977, lng: 19.196499 },
    { lat: 48.5699979, lng: 19.1972335 },
    { lat: 48.5699292, lng: 19.1990532 },
    { lat: 48.5700581, lng: 19.1991107 },
    { lat: 48.570304, lng: 19.199874 },
    { lat: 48.5703491, lng: 19.2007958 },
    { lat: 48.5703247, lng: 19.2014029 },
    { lat: 48.5700489, lng: 19.202192 },
    { lat: 48.569565, lng: 19.2062691 },
    { lat: 48.5694633, lng: 19.2068161 },
    { lat: 48.5692647, lng: 19.2074374 },
    { lat: 48.5685363, lng: 19.2089095 },
    { lat: 48.5679227, lng: 19.2112817 },
    { lat: 48.5678797, lng: 19.211368 },
    { lat: 48.5676413, lng: 19.2124643 },
    { lat: 48.5675749, lng: 19.2129973 },
    { lat: 48.5676927, lng: 19.2173531 },
    { lat: 48.5676093, lng: 19.2183709 },
    { lat: 48.5674712, lng: 19.2188222 },
    { lat: 48.5665782, lng: 19.2206233 },
    { lat: 48.5663063, lng: 19.2217275 },
    { lat: 48.5666391, lng: 19.2256458 },
    { lat: 48.5677885, lng: 19.225476 },
    { lat: 48.5679761, lng: 19.2255055 },
    { lat: 48.5681177, lng: 19.2253371 },
    { lat: 48.5684539, lng: 19.2252722 },
    { lat: 48.5690858, lng: 19.2250059 },
    { lat: 48.5694143, lng: 19.2249275 },
    { lat: 48.5696609, lng: 19.2250569 },
    { lat: 48.5700234, lng: 19.2249581 },
    { lat: 48.570183, lng: 19.2248069 },
    { lat: 48.5709369, lng: 19.2247321 },
    { lat: 48.5709918, lng: 19.2246577 },
    { lat: 48.5710778, lng: 19.2246671 },
    { lat: 48.5713016, lng: 19.2243537 },
    { lat: 48.5716316, lng: 19.224184 },
    { lat: 48.5717111, lng: 19.2239457 },
    { lat: 48.5722988, lng: 19.2243921 },
    { lat: 48.5725051, lng: 19.2245523 },
    { lat: 48.5729553, lng: 19.2247947 },
    { lat: 48.5733632, lng: 19.2252319 },
    { lat: 48.5736051, lng: 19.2254056 },
    { lat: 48.5738396, lng: 19.2256616 },
    { lat: 48.5741308, lng: 19.2261074 },
    { lat: 48.5742595, lng: 19.2260935 },
    { lat: 48.575692, lng: 19.2268272 },
    { lat: 48.5770487, lng: 19.2284182 },
    { lat: 48.5781977, lng: 19.2301822 },
    { lat: 48.5804229, lng: 19.232225 },
    { lat: 48.5816354, lng: 19.2342338 },
    { lat: 48.5821538, lng: 19.2335607 },
    { lat: 48.5830355, lng: 19.2333302 },
    { lat: 48.5844292, lng: 19.2341065 },
    { lat: 48.5859062, lng: 19.2341841 },
    { lat: 48.5861172, lng: 19.2343983 },
    { lat: 48.5861208, lng: 19.2344046 },
    { lat: 48.5870823, lng: 19.2330801 },
    { lat: 48.587611, lng: 19.232431 },
    { lat: 48.5874905, lng: 19.2314834 },
    { lat: 48.5875592, lng: 19.2312486 },
    { lat: 48.5871273, lng: 19.231014 },
    { lat: 48.5871189, lng: 19.2309331 },
    { lat: 48.5872724, lng: 19.2306174 },
    { lat: 48.5871818, lng: 19.2300018 },
    { lat: 48.5869066, lng: 19.2294003 },
    { lat: 48.5870216, lng: 19.2291668 },
    { lat: 48.5868661, lng: 19.2290516 },
    { lat: 48.5866445, lng: 19.2290622 },
    { lat: 48.5865322, lng: 19.2289525 },
    { lat: 48.5866673, lng: 19.2279997 },
    { lat: 48.586783, lng: 19.2278306 },
    { lat: 48.5869761, lng: 19.2277683 },
    { lat: 48.587002, lng: 19.2279285 },
    { lat: 48.5868682, lng: 19.2281448 },
    { lat: 48.5868806, lng: 19.228214 },
    { lat: 48.5869916, lng: 19.228181 },
    { lat: 48.5871182, lng: 19.2279089 },
    { lat: 48.5872609, lng: 19.2279679 },
    { lat: 48.5872924, lng: 19.2278761 },
    { lat: 48.5872513, lng: 19.2276767 },
    { lat: 48.5875526, lng: 19.2275686 },
    { lat: 48.5875118, lng: 19.2269141 },
    { lat: 48.5872563, lng: 19.2263915 },
    { lat: 48.5872812, lng: 19.2262531 },
    { lat: 48.587468, lng: 19.2260149 },
    { lat: 48.5874177, lng: 19.2258165 },
    { lat: 48.5873174, lng: 19.2257937 },
    { lat: 48.5873825, lng: 19.2252767 },
    { lat: 48.5874905, lng: 19.2250881 },
    { lat: 48.5873223, lng: 19.2248757 },
    { lat: 48.5873666, lng: 19.2246735 },
    { lat: 48.5871319, lng: 19.2240198 },
    { lat: 48.5873155, lng: 19.2239927 },
    { lat: 48.5873985, lng: 19.2229232 },
    { lat: 48.5873072, lng: 19.2228044 },
    { lat: 48.5872381, lng: 19.2224041 },
    { lat: 48.5871662, lng: 19.222412 },
    { lat: 48.5872706, lng: 19.2217753 },
    { lat: 48.5871259, lng: 19.2215356 },
    { lat: 48.5871384, lng: 19.2213313 },
    { lat: 48.5873667, lng: 19.2211597 },
    { lat: 48.5873368, lng: 19.2210544 },
    { lat: 48.5874065, lng: 19.2209584 },
    { lat: 48.587528, lng: 19.2209076 },
    { lat: 48.5877337, lng: 19.2213334 },
    { lat: 48.5880039, lng: 19.2213274 },
    { lat: 48.5882813, lng: 19.2209566 },
    { lat: 48.5882696, lng: 19.2208119 },
    { lat: 48.5881272, lng: 19.220712 },
    { lat: 48.5880412, lng: 19.2203818 },
    { lat: 48.5875775, lng: 19.2197026 },
    { lat: 48.5875728, lng: 19.2195126 },
    { lat: 48.5879644, lng: 19.2193571 },
    { lat: 48.5881878, lng: 19.2195025 },
    { lat: 48.5884643, lng: 19.2194377 },
    { lat: 48.5883304, lng: 19.219099 },
    { lat: 48.5883373, lng: 19.2190067 },
    { lat: 48.5884937, lng: 19.2191183 },
    { lat: 48.5885535, lng: 19.2189554 },
    { lat: 48.5884588, lng: 19.2189117 },
    { lat: 48.5884233, lng: 19.2188274 },
    { lat: 48.5884577, lng: 19.2186569 },
    { lat: 48.5885306, lng: 19.2185773 },
    { lat: 48.5885703, lng: 19.2186071 },
    { lat: 48.5887635, lng: 19.2180384 },
    { lat: 48.5886609, lng: 19.2178768 },
    { lat: 48.5886794, lng: 19.2177487 },
    { lat: 48.5887061, lng: 19.2176948 },
    { lat: 48.5891451, lng: 19.2179114 },
    { lat: 48.5894008, lng: 19.2176079 },
    { lat: 48.5894044, lng: 19.2171724 },
    { lat: 48.5895934, lng: 19.2169307 },
    { lat: 48.5893891, lng: 19.2165353 },
    { lat: 48.5893355, lng: 19.2162692 },
    { lat: 48.589389, lng: 19.2160731 },
    { lat: 48.5896637, lng: 19.2161103 },
    { lat: 48.5897753, lng: 19.2159488 },
    { lat: 48.5898475, lng: 19.2159475 },
    { lat: 48.5898382, lng: 19.2161729 },
    { lat: 48.5899351, lng: 19.2163149 },
    { lat: 48.5899992, lng: 19.2161957 },
    { lat: 48.5900295, lng: 19.2159342 },
    { lat: 48.5900019, lng: 19.2156922 },
    { lat: 48.5900461, lng: 19.2155823 },
    { lat: 48.5902312, lng: 19.2155411 },
    { lat: 48.5902302, lng: 19.2152457 },
    { lat: 48.5899606, lng: 19.2151702 },
    { lat: 48.5899444, lng: 19.2151143 },
    { lat: 48.5899554, lng: 19.2149226 },
    { lat: 48.5898928, lng: 19.2147971 },
    { lat: 48.5899132, lng: 19.2144313 },
    { lat: 48.590052, lng: 19.2144533 },
    { lat: 48.5901571, lng: 19.2141628 },
    { lat: 48.5903329, lng: 19.2140244 },
    { lat: 48.5904009, lng: 19.2138435 },
    { lat: 48.590366, lng: 19.2136369 },
    { lat: 48.5904319, lng: 19.2134188 },
    { lat: 48.5903484, lng: 19.2131357 },
    { lat: 48.5904733, lng: 19.2127854 },
    { lat: 48.5906292, lng: 19.2127478 },
    { lat: 48.5905591, lng: 19.2124668 },
    { lat: 48.590605, lng: 19.2119757 },
    { lat: 48.5906699, lng: 19.2115469 },
    { lat: 48.5908063, lng: 19.2115692 },
    { lat: 48.590833, lng: 19.2115153 },
    { lat: 48.5907477, lng: 19.2112495 },
    { lat: 48.5907822, lng: 19.2111236 },
    { lat: 48.5908647, lng: 19.2109649 },
    { lat: 48.5909411, lng: 19.2110547 },
    { lat: 48.591085, lng: 19.21109 },
    { lat: 48.5914135, lng: 19.2108937 },
    { lat: 48.5915226, lng: 19.2107285 },
    { lat: 48.5914232, lng: 19.2103521 },
    { lat: 48.5914563, lng: 19.2101547 },
    { lat: 48.5913668, lng: 19.2096618 },
    { lat: 48.591384, lng: 19.2092725 },
    { lat: 48.5914914, lng: 19.2092097 },
    { lat: 48.5916104, lng: 19.2094785 },
    { lat: 48.5917048, lng: 19.2094239 },
    { lat: 48.5916932, lng: 19.2087693 },
    { lat: 48.5917297, lng: 19.2087346 },
    { lat: 48.5918997, lng: 19.2088923 },
    { lat: 48.5921012, lng: 19.2087714 },
    { lat: 48.5920857, lng: 19.2085897 },
    { lat: 48.5919498, lng: 19.2085777 },
    { lat: 48.5918579, lng: 19.208496 },
    { lat: 48.5918212, lng: 19.2077933 },
    { lat: 48.5918604, lng: 19.2069799 },
    { lat: 48.5917711, lng: 19.2067181 },
    { lat: 48.5918168, lng: 19.2066383 },
    { lat: 48.5918775, lng: 19.2066383 },
    { lat: 48.5919395, lng: 19.2066101 },
    { lat: 48.5919571, lng: 19.2063952 },
    { lat: 48.5918319, lng: 19.2061876 },
    { lat: 48.591691, lng: 19.2061659 },
    { lat: 48.5912263, lng: 19.2064364 },
    { lat: 48.591191, lng: 19.2057371 },
    { lat: 48.5912518, lng: 19.2050851 },
    { lat: 48.5913919, lng: 19.2049072 },
    { lat: 48.5914383, lng: 19.2046592 },
    { lat: 48.5912385, lng: 19.2044941 },
    { lat: 48.5911082, lng: 19.2047763 },
    { lat: 48.5909699, lng: 19.2048561 },
    { lat: 48.5907912, lng: 19.2047047 },
    { lat: 48.590894, lng: 19.2042258 },
    { lat: 48.590714, lng: 19.202549 },
    { lat: 48.5907621, lng: 19.2021702 },
    { lat: 48.5908624, lng: 19.2019657 },
    { lat: 48.5909193, lng: 19.2019743 },
    { lat: 48.5909697, lng: 19.201832 },
    { lat: 48.5912508, lng: 19.2017684 },
    { lat: 48.5913015, lng: 19.2018827 },
    { lat: 48.591398, lng: 19.201851 },
    { lat: 48.5914733, lng: 19.2019374 },
    { lat: 48.5915562, lng: 19.2018352 },
    { lat: 48.5915846, lng: 19.2019332 },
    { lat: 48.5917289, lng: 19.2018645 },
    { lat: 48.5918288, lng: 19.2019716 },
    { lat: 48.5918561, lng: 19.2018824 },
    { lat: 48.592173, lng: 19.201657 },
    { lat: 48.5922242, lng: 19.2015018 },
    { lat: 48.5924711, lng: 19.2013875 },
    { lat: 48.5926672, lng: 19.200948 },
    { lat: 48.5928678, lng: 19.2008584 },
    { lat: 48.5930503, lng: 19.2005999 },
    { lat: 48.5931417, lng: 19.2006293 },
    { lat: 48.5932244, lng: 19.2004502 },
    { lat: 48.5936617, lng: 19.2002389 },
    { lat: 48.593729, lng: 19.1999583 },
    { lat: 48.5940054, lng: 19.1999821 },
    { lat: 48.594186, lng: 19.1998071 },
    { lat: 48.5942271, lng: 19.1999093 },
    { lat: 48.5941854, lng: 19.1999777 },
    { lat: 48.5942111, lng: 19.2002046 },
    { lat: 48.5943579, lng: 19.2001872 },
    { lat: 48.5943378, lng: 19.2000961 },
    { lat: 48.5945133, lng: 19.200041 },
    { lat: 48.5945096, lng: 19.1999463 },
    { lat: 48.5944543, lng: 19.1998987 },
    { lat: 48.5945209, lng: 19.199714 },
    { lat: 48.5946342, lng: 19.1996355 },
    { lat: 48.5946099, lng: 19.1999683 },
    { lat: 48.5946797, lng: 19.1999424 },
    { lat: 48.5947348, lng: 19.200031 },
    { lat: 48.5948258, lng: 19.1999796 },
    { lat: 48.5948431, lng: 19.1997818 },
    { lat: 48.594782, lng: 19.1997733 },
    { lat: 48.5948354, lng: 19.1996003 },
    { lat: 48.5948927, lng: 19.1996655 },
    { lat: 48.5949413, lng: 19.199554 },
    { lat: 48.5950162, lng: 19.1996792 },
    { lat: 48.5951799, lng: 19.1996603 },
    { lat: 48.59528, lng: 19.1992863 },
    { lat: 48.5953905, lng: 19.1990582 },
    { lat: 48.595544, lng: 19.1989671 },
    { lat: 48.5956369, lng: 19.1987449 },
    { lat: 48.5957969, lng: 19.1986082 },
    { lat: 48.5961194, lng: 19.197875 },
    { lat: 48.5962967, lng: 19.1976691 },
    { lat: 48.5962955, lng: 19.1973704 },
    { lat: 48.5964339, lng: 19.1973397 },
    { lat: 48.5966064, lng: 19.1969256 },
    { lat: 48.5967733, lng: 19.1968138 },
    { lat: 48.5967761, lng: 19.1965713 },
    { lat: 48.5970546, lng: 19.1962049 },
    { lat: 48.597025, lng: 19.1959229 },
    { lat: 48.5971649, lng: 19.1959476 },
    { lat: 48.5973331, lng: 19.195654 },
    { lat: 48.597352, lng: 19.1952092 },
    { lat: 48.5974069, lng: 19.1949944 },
    { lat: 48.5973595, lng: 19.1949484 },
    { lat: 48.5975402, lng: 19.1944945 },
    { lat: 48.597515, lng: 19.1942548 },
    { lat: 48.5975925, lng: 19.1938338 },
    { lat: 48.597812, lng: 19.1932093 },
    { lat: 48.597942, lng: 19.1930748 },
    { lat: 48.5979365, lng: 19.1924123 },
    { lat: 48.5979972, lng: 19.1921335 },
    { lat: 48.5988193, lng: 19.1907343 },
    { lat: 48.599572, lng: 19.189831 },
    { lat: 48.599939, lng: 19.1895921 },
    { lat: 48.6001507, lng: 19.1890778 },
    { lat: 48.6004365, lng: 19.1887251 },
    { lat: 48.6004272, lng: 19.1877499 },
    { lat: 48.6012915, lng: 19.1863647 },
    { lat: 48.6014619, lng: 19.1853556 },
    { lat: 48.6016184, lng: 19.1851697 },
    { lat: 48.60188, lng: 19.1842512 },
    { lat: 48.6018942, lng: 19.1835534 },
    { lat: 48.6022239, lng: 19.1816539 },
    { lat: 48.6023981, lng: 19.1812755 },
    { lat: 48.6024874, lng: 19.1808905 },
    { lat: 48.6029597, lng: 19.1798562 },
    { lat: 48.6030444, lng: 19.1789406 },
    { lat: 48.6032624, lng: 19.1784433 },
    { lat: 48.6033205, lng: 19.1781541 },
    { lat: 48.603297, lng: 19.1779063 },
    { lat: 48.6030415, lng: 19.1769261 },
    { lat: 48.6031446, lng: 19.1752396 },
    { lat: 48.6032335, lng: 19.1746637 },
    { lat: 48.6028847, lng: 19.1743304 },
    { lat: 48.6025588, lng: 19.1734799 },
    { lat: 48.6025223, lng: 19.1729672 },
    { lat: 48.6025316, lng: 19.1719166 },
    { lat: 48.6026052, lng: 19.1704345 },
    { lat: 48.6025408, lng: 19.1702921 },
    { lat: 48.6024023, lng: 19.1702937 },
    { lat: 48.6022688, lng: 19.169025 },
    { lat: 48.6021541, lng: 19.1690082 },
    { lat: 48.601983, lng: 19.1670175 },
    { lat: 48.6019199, lng: 19.1669918 },
    { lat: 48.6015774, lng: 19.1655998 },
    { lat: 48.6009528, lng: 19.165436 },
    { lat: 48.6006023, lng: 19.1641125 },
    { lat: 48.5999208, lng: 19.1635252 },
    { lat: 48.5991232, lng: 19.163326 },
    { lat: 48.5986208, lng: 19.1630588 },
    { lat: 48.5979567, lng: 19.1628991 },
    { lat: 48.5974144, lng: 19.1626638 },
    { lat: 48.5972197, lng: 19.1624427 },
  ],
  Sielnica: [
    { lat: 48.6282497, lng: 19.1414306 },
    { lat: 48.628313, lng: 19.1414549 },
    { lat: 48.6288471, lng: 19.1412915 },
    { lat: 48.629375, lng: 19.141372 },
    { lat: 48.629404, lng: 19.141338 },
    { lat: 48.629674, lng: 19.141016 },
    { lat: 48.629716, lng: 19.140966 },
    { lat: 48.629677, lng: 19.140855 },
    { lat: 48.629611, lng: 19.140827 },
    { lat: 48.629569, lng: 19.14078 },
    { lat: 48.62955, lng: 19.140657 },
    { lat: 48.629576, lng: 19.140554 },
    { lat: 48.629591, lng: 19.140469 },
    { lat: 48.629658, lng: 19.140291 },
    { lat: 48.629773, lng: 19.140088 },
    { lat: 48.629817, lng: 19.139933 },
    { lat: 48.630072, lng: 19.139592 },
    { lat: 48.630219, lng: 19.139502 },
    { lat: 48.630274, lng: 19.139423 },
    { lat: 48.630353, lng: 19.139442 },
    { lat: 48.630482, lng: 19.139497 },
    { lat: 48.630609, lng: 19.139485 },
    { lat: 48.630671, lng: 19.1395 },
    { lat: 48.63079, lng: 19.139433 },
    { lat: 48.630932, lng: 19.13934 },
    { lat: 48.630955, lng: 19.139337 },
    { lat: 48.631082, lng: 19.139317 },
    { lat: 48.631895, lng: 19.139523 },
    { lat: 48.632461, lng: 19.139816 },
    { lat: 48.632878, lng: 19.140137 },
    { lat: 48.63292, lng: 19.140168 },
    { lat: 48.632988, lng: 19.14022 },
    { lat: 48.63318, lng: 19.140368 },
    { lat: 48.63286, lng: 19.141261 },
    { lat: 48.632703, lng: 19.14214 },
    { lat: 48.633399, lng: 19.142581 },
    { lat: 48.633934, lng: 19.142968 },
    { lat: 48.635024, lng: 19.143156 },
    { lat: 48.635378, lng: 19.143149 },
    { lat: 48.635376, lng: 19.142958 },
    { lat: 48.63587, lng: 19.142915 },
    { lat: 48.635869, lng: 19.142736 },
    { lat: 48.636865, lng: 19.142656 },
    { lat: 48.63695, lng: 19.142706 },
    { lat: 48.640292, lng: 19.142528 },
    { lat: 48.640292, lng: 19.142561 },
    { lat: 48.641029, lng: 19.142538 },
    { lat: 48.64108, lng: 19.142537 },
    { lat: 48.64112, lng: 19.142385 },
    { lat: 48.641332, lng: 19.141357 },
    { lat: 48.641447, lng: 19.141105 },
    { lat: 48.641466, lng: 19.141064 },
    { lat: 48.641509, lng: 19.140969 },
    { lat: 48.641536, lng: 19.14091 },
    { lat: 48.641702, lng: 19.140855 },
    { lat: 48.641906, lng: 19.140845 },
    { lat: 48.642138, lng: 19.140852 },
    { lat: 48.642436, lng: 19.140986 },
    { lat: 48.642834, lng: 19.141171 },
    { lat: 48.643038, lng: 19.141249 },
    { lat: 48.643103, lng: 19.141266 },
    { lat: 48.643452, lng: 19.141342 },
    { lat: 48.643626, lng: 19.141373 },
    { lat: 48.6439, lng: 19.1414 },
    { lat: 48.644284, lng: 19.141428 },
    { lat: 48.644819, lng: 19.141453 },
    { lat: 48.645131, lng: 19.141459 },
    { lat: 48.645495, lng: 19.141501 },
    { lat: 48.64576, lng: 19.141569 },
    { lat: 48.6458, lng: 19.139999 },
    { lat: 48.645845, lng: 19.137929 },
    { lat: 48.646507, lng: 19.137975 },
    { lat: 48.646712, lng: 19.13799 },
    { lat: 48.648724, lng: 19.138153 },
    { lat: 48.648767, lng: 19.13678 },
    { lat: 48.648784, lng: 19.136082 },
    { lat: 48.648859, lng: 19.133098 },
    { lat: 48.648909, lng: 19.131142 },
    { lat: 48.648701, lng: 19.130891 },
    { lat: 48.648167, lng: 19.13053 },
    { lat: 48.647938, lng: 19.130528 },
    { lat: 48.646945, lng: 19.130435 },
    { lat: 48.645405, lng: 19.130361 },
    { lat: 48.644412, lng: 19.130317 },
    { lat: 48.643368, lng: 19.13025 },
    { lat: 48.639758, lng: 19.130028 },
    { lat: 48.638609, lng: 19.129948 },
    { lat: 48.635515, lng: 19.129854 },
    { lat: 48.635408, lng: 19.129848 },
    { lat: 48.635407, lng: 19.129783 },
    { lat: 48.635408, lng: 19.129742 },
    { lat: 48.635408, lng: 19.129715 },
    { lat: 48.635499, lng: 19.129629 },
    { lat: 48.635814, lng: 19.129331 },
    { lat: 48.635895, lng: 19.129243 },
    { lat: 48.635993, lng: 19.129108 },
    { lat: 48.636472, lng: 19.128411 },
    { lat: 48.637122, lng: 19.127402 },
    { lat: 48.637948, lng: 19.126134 },
    { lat: 48.638241, lng: 19.125673 },
    { lat: 48.638349, lng: 19.125516 },
    { lat: 48.638542, lng: 19.125269 },
    { lat: 48.638703, lng: 19.125103 },
    { lat: 48.638793, lng: 19.125006 },
    { lat: 48.639001, lng: 19.12483 },
    { lat: 48.639937, lng: 19.124315 },
    { lat: 48.640972, lng: 19.123727 },
    { lat: 48.641733, lng: 19.123299 },
    { lat: 48.641926, lng: 19.123161 },
    { lat: 48.642046, lng: 19.123016 },
    { lat: 48.642189, lng: 19.122812 },
    { lat: 48.642427, lng: 19.122434 },
    { lat: 48.642806, lng: 19.121797 },
    { lat: 48.643029, lng: 19.121465 },
    { lat: 48.643377, lng: 19.120901 },
    { lat: 48.643379, lng: 19.120808 },
    { lat: 48.643379, lng: 19.12079 },
    { lat: 48.643459, lng: 19.120675 },
    { lat: 48.643544, lng: 19.120554 },
    { lat: 48.6435879, lng: 19.1204799 },
    { lat: 48.643627, lng: 19.120435 },
    { lat: 48.643629, lng: 19.120417 },
    { lat: 48.643716, lng: 19.120333 },
    { lat: 48.644294, lng: 19.119401 },
    { lat: 48.644434, lng: 19.119149 },
    { lat: 48.64458, lng: 19.118916 },
    { lat: 48.644727, lng: 19.118649 },
    { lat: 48.645497, lng: 19.117007 },
    { lat: 48.645552, lng: 19.116908 },
    { lat: 48.645555, lng: 19.116901 },
    { lat: 48.645581, lng: 19.116844 },
    { lat: 48.645584, lng: 19.116837 },
    { lat: 48.645592, lng: 19.116826 },
    { lat: 48.645611, lng: 19.116771 },
    { lat: 48.645818, lng: 19.116366 },
    { lat: 48.645964, lng: 19.115957 },
    { lat: 48.646045, lng: 19.115724 },
    { lat: 48.646173, lng: 19.115385 },
    { lat: 48.646349, lng: 19.114921 },
    { lat: 48.646541, lng: 19.114447 },
    { lat: 48.646608, lng: 19.114249 },
    { lat: 48.646638, lng: 19.113988 },
    { lat: 48.646633, lng: 19.113819 },
    { lat: 48.646626, lng: 19.113625 },
    { lat: 48.646611, lng: 19.113418 },
    { lat: 48.646535, lng: 19.112637 },
    { lat: 48.646476, lng: 19.112054 },
    { lat: 48.646418, lng: 19.111476 },
    { lat: 48.646356, lng: 19.110929 },
    { lat: 48.646327, lng: 19.110792 },
    { lat: 48.646285, lng: 19.110675 },
    { lat: 48.646086, lng: 19.110313 },
    { lat: 48.645828, lng: 19.109863 },
    { lat: 48.64551, lng: 19.109289 },
    { lat: 48.645263, lng: 19.10881 },
    { lat: 48.64524, lng: 19.108635 },
    { lat: 48.645237, lng: 19.108415 },
    { lat: 48.645243, lng: 19.108298 },
    { lat: 48.645266, lng: 19.108065 },
    { lat: 48.645331, lng: 19.107985 },
    { lat: 48.645409, lng: 19.107833 },
    { lat: 48.645424, lng: 19.107743 },
    { lat: 48.645501, lng: 19.107661 },
    { lat: 48.645548, lng: 19.107483 },
    { lat: 48.645543, lng: 19.107354 },
    { lat: 48.645547, lng: 19.1072 },
    { lat: 48.64574, lng: 19.107214 },
    { lat: 48.64572, lng: 19.106899 },
    { lat: 48.645729, lng: 19.106779 },
    { lat: 48.64576, lng: 19.106404 },
    { lat: 48.64584, lng: 19.106297 },
    { lat: 48.64592, lng: 19.106169 },
    { lat: 48.645885, lng: 19.106002 },
    { lat: 48.645837, lng: 19.1059 },
    { lat: 48.64581, lng: 19.105838 },
    { lat: 48.645758, lng: 19.105776 },
    { lat: 48.645745, lng: 19.105702 },
    { lat: 48.645708, lng: 19.105656 },
    { lat: 48.645702, lng: 19.105554 },
    { lat: 48.645624, lng: 19.105371 },
    { lat: 48.645713, lng: 19.105187 },
    { lat: 48.645668, lng: 19.105018 },
    { lat: 48.645664, lng: 19.104854 },
    { lat: 48.645642, lng: 19.104738 },
    { lat: 48.64567, lng: 19.104668 },
    { lat: 48.645654, lng: 19.104639 },
    { lat: 48.645636, lng: 19.10452 },
    { lat: 48.645686, lng: 19.104405 },
    { lat: 48.645701, lng: 19.104273 },
    { lat: 48.645694, lng: 19.104176 },
    { lat: 48.645712, lng: 19.10407 },
    { lat: 48.645743, lng: 19.103975 },
    { lat: 48.64583, lng: 19.10378 },
    { lat: 48.645913, lng: 19.103761 },
    { lat: 48.645968, lng: 19.103781 },
    { lat: 48.646061, lng: 19.103665 },
    { lat: 48.646165, lng: 19.103661 },
    { lat: 48.646188, lng: 19.103594 },
    { lat: 48.646127, lng: 19.103538 },
    { lat: 48.64611, lng: 19.103463 },
    { lat: 48.646121, lng: 19.103405 },
    { lat: 48.646198, lng: 19.103304 },
    { lat: 48.646237, lng: 19.103144 },
    { lat: 48.646303, lng: 19.103188 },
    { lat: 48.646363, lng: 19.103089 },
    { lat: 48.646376, lng: 19.103008 },
    { lat: 48.646359, lng: 19.102934 },
    { lat: 48.646374, lng: 19.102626 },
    { lat: 48.646464, lng: 19.102507 },
    { lat: 48.646523, lng: 19.102297 },
    { lat: 48.646665, lng: 19.102095 },
    { lat: 48.646693, lng: 19.102022 },
    { lat: 48.646701, lng: 19.101921 },
    { lat: 48.646644, lng: 19.101852 },
    { lat: 48.646655, lng: 19.101734 },
    { lat: 48.646618, lng: 19.101604 },
    { lat: 48.646551, lng: 19.101542 },
    { lat: 48.646517, lng: 19.101457 },
    { lat: 48.646431, lng: 19.101336 },
    { lat: 48.646441, lng: 19.101256 },
    { lat: 48.646422, lng: 19.101174 },
    { lat: 48.646395, lng: 19.101122 },
    { lat: 48.646411, lng: 19.101065 },
    { lat: 48.646423, lng: 19.100895 },
    { lat: 48.64648, lng: 19.100815 },
    { lat: 48.646483, lng: 19.100793 },
    { lat: 48.646414, lng: 19.100763 },
    { lat: 48.646367, lng: 19.100483 },
    { lat: 48.646233, lng: 19.100365 },
    { lat: 48.646359, lng: 19.100228 },
    { lat: 48.64637, lng: 19.100191 },
    { lat: 48.646377, lng: 19.100125 },
    { lat: 48.646509, lng: 19.100177 },
    { lat: 48.646525, lng: 19.100242 },
    { lat: 48.646535, lng: 19.100227 },
    { lat: 48.646549, lng: 19.100093 },
    { lat: 48.646575, lng: 19.100035 },
    { lat: 48.646609, lng: 19.099552 },
    { lat: 48.646655, lng: 19.099544 },
    { lat: 48.646647, lng: 19.099395 },
    { lat: 48.646713, lng: 19.099246 },
    { lat: 48.646797, lng: 19.099232 },
    { lat: 48.646841, lng: 19.099262 },
    { lat: 48.646846, lng: 19.099261 },
    { lat: 48.646892, lng: 19.099094 },
    { lat: 48.646907, lng: 19.099033 },
    { lat: 48.646902, lng: 19.098897 },
    { lat: 48.646902, lng: 19.0988 },
    { lat: 48.646985, lng: 19.098778 },
    { lat: 48.646991, lng: 19.098677 },
    { lat: 48.647016, lng: 19.098635 },
    { lat: 48.647054, lng: 19.098602 },
    { lat: 48.647038, lng: 19.098481 },
    { lat: 48.647144, lng: 19.098353 },
    { lat: 48.647159, lng: 19.098301 },
    { lat: 48.647188, lng: 19.098251 },
    { lat: 48.647198, lng: 19.098006 },
    { lat: 48.647283, lng: 19.098014 },
    { lat: 48.6473, lng: 19.097992 },
    { lat: 48.64734, lng: 19.097946 },
    { lat: 48.647363, lng: 19.097788 },
    { lat: 48.647391, lng: 19.097703 },
    { lat: 48.647428, lng: 19.097668 },
    { lat: 48.647441, lng: 19.097631 },
    { lat: 48.647497, lng: 19.097552 },
    { lat: 48.647514, lng: 19.097484 },
    { lat: 48.647525, lng: 19.097435 },
    { lat: 48.647579, lng: 19.097406 },
    { lat: 48.647614, lng: 19.097399 },
    { lat: 48.647716, lng: 19.097381 },
    { lat: 48.647744, lng: 19.097292 },
    { lat: 48.647821, lng: 19.097202 },
    { lat: 48.647898, lng: 19.097072 },
    { lat: 48.647921, lng: 19.097035 },
    { lat: 48.647967, lng: 19.096932 },
    { lat: 48.64809, lng: 19.096847 },
    { lat: 48.648234, lng: 19.096836 },
    { lat: 48.648349, lng: 19.096898 },
    { lat: 48.648402, lng: 19.096901 },
    { lat: 48.648448, lng: 19.096896 },
    { lat: 48.648519, lng: 19.097007 },
    { lat: 48.648581, lng: 19.097054 },
    { lat: 48.648706, lng: 19.096963 },
    { lat: 48.648764, lng: 19.096809 },
    { lat: 48.648823, lng: 19.096752 },
    { lat: 48.648869, lng: 19.096588 },
    { lat: 48.648873, lng: 19.096527 },
    { lat: 48.648897, lng: 19.09649 },
    { lat: 48.648958, lng: 19.096495 },
    { lat: 48.649037, lng: 19.096462 },
    { lat: 48.649112, lng: 19.09645 },
    { lat: 48.649176, lng: 19.096338 },
    { lat: 48.649345, lng: 19.096369 },
    { lat: 48.649406, lng: 19.096376 },
    { lat: 48.649505, lng: 19.096276 },
    { lat: 48.64958, lng: 19.096213 },
    { lat: 48.649687, lng: 19.096139 },
    { lat: 48.649713, lng: 19.096168 },
    { lat: 48.649775, lng: 19.096138 },
    { lat: 48.649832, lng: 19.096181 },
    { lat: 48.649867, lng: 19.096203 },
    { lat: 48.649946, lng: 19.096162 },
    { lat: 48.650053, lng: 19.096098 },
    { lat: 48.650156, lng: 19.0961 },
    { lat: 48.650237, lng: 19.096075 },
    { lat: 48.650208, lng: 19.095965 },
    { lat: 48.650291, lng: 19.095883 },
    { lat: 48.650301, lng: 19.095772 },
    { lat: 48.650378, lng: 19.095707 },
    { lat: 48.650462, lng: 19.095696 },
    { lat: 48.650482, lng: 19.095738 },
    { lat: 48.65058, lng: 19.095721 },
    { lat: 48.650628, lng: 19.0958 },
    { lat: 48.650709, lng: 19.095775 },
    { lat: 48.650769, lng: 19.095707 },
    { lat: 48.65079, lng: 19.095612 },
    { lat: 48.650881, lng: 19.095453 },
    { lat: 48.650906, lng: 19.095357 },
    { lat: 48.650996, lng: 19.095247 },
    { lat: 48.650998, lng: 19.095144 },
    { lat: 48.651028, lng: 19.095003 },
    { lat: 48.65108, lng: 19.094869 },
    { lat: 48.651218, lng: 19.094719 },
    { lat: 48.651277, lng: 19.094656 },
    { lat: 48.651328, lng: 19.094611 },
    { lat: 48.651391, lng: 19.094563 },
    { lat: 48.651476, lng: 19.094468 },
    { lat: 48.651557, lng: 19.09424 },
    { lat: 48.651596, lng: 19.094092 },
    { lat: 48.651645, lng: 19.093962 },
    { lat: 48.651679, lng: 19.093845 },
    { lat: 48.651713, lng: 19.093782 },
    { lat: 48.651794, lng: 19.093693 },
    { lat: 48.651898, lng: 19.093483 },
    { lat: 48.651947, lng: 19.093405 },
    { lat: 48.652002, lng: 19.093289 },
    { lat: 48.65206, lng: 19.09318 },
    { lat: 48.652181, lng: 19.093028 },
    { lat: 48.652232, lng: 19.092898 },
    { lat: 48.65231, lng: 19.092855 },
    { lat: 48.65244, lng: 19.092896 },
    { lat: 48.652521, lng: 19.092851 },
    { lat: 48.652618, lng: 19.092833 },
    { lat: 48.652715, lng: 19.092723 },
    { lat: 48.652793, lng: 19.092703 },
    { lat: 48.652833, lng: 19.092614 },
    { lat: 48.652974, lng: 19.092515 },
    { lat: 48.653029, lng: 19.0925 },
    { lat: 48.653123, lng: 19.092341 },
    { lat: 48.653232, lng: 19.092196 },
    { lat: 48.653303, lng: 19.092074 },
    { lat: 48.653372, lng: 19.091948 },
    { lat: 48.653475, lng: 19.091854 },
    { lat: 48.653516, lng: 19.091799 },
    { lat: 48.653514, lng: 19.091735 },
    { lat: 48.653546, lng: 19.091703 },
    { lat: 48.653583, lng: 19.091639 },
    { lat: 48.653642, lng: 19.091618 },
    { lat: 48.653692, lng: 19.091458 },
    { lat: 48.653778, lng: 19.091466 },
    { lat: 48.653935, lng: 19.091357 },
    { lat: 48.654019, lng: 19.091375 },
    { lat: 48.654102, lng: 19.091351 },
    { lat: 48.65419, lng: 19.091284 },
    { lat: 48.654337, lng: 19.091256 },
    { lat: 48.654433, lng: 19.091265 },
    { lat: 48.654592, lng: 19.091202 },
    { lat: 48.654709, lng: 19.09119 },
    { lat: 48.654818, lng: 19.09126 },
    { lat: 48.654916, lng: 19.091229 },
    { lat: 48.655028, lng: 19.091211 },
    { lat: 48.655154, lng: 19.091144 },
    { lat: 48.655268, lng: 19.091116 },
    { lat: 48.655349, lng: 19.091021 },
    { lat: 48.655483, lng: 19.09092 },
    { lat: 48.655673, lng: 19.090464 },
    { lat: 48.655781, lng: 19.090201 },
    { lat: 48.655836, lng: 19.090019 },
    { lat: 48.655884, lng: 19.089811 },
    { lat: 48.655971, lng: 19.089527 },
    { lat: 48.65602, lng: 19.089376 },
    { lat: 48.656222, lng: 19.088929 },
    { lat: 48.656419, lng: 19.088509 },
    { lat: 48.656517, lng: 19.08815 },
    { lat: 48.656694, lng: 19.087551 },
    { lat: 48.65672, lng: 19.087403 },
    { lat: 48.656735, lng: 19.087251 },
    { lat: 48.65676, lng: 19.087166 },
    { lat: 48.656821, lng: 19.087063 },
    { lat: 48.657483, lng: 19.086043 },
    { lat: 48.657662, lng: 19.085912 },
    { lat: 48.657996, lng: 19.085647 },
    { lat: 48.65823, lng: 19.085408 },
    { lat: 48.658709, lng: 19.085037 },
    { lat: 48.659018, lng: 19.08468 },
    { lat: 48.659822, lng: 19.083633 },
    { lat: 48.659998, lng: 19.08337 },
    { lat: 48.660317, lng: 19.082805 },
    { lat: 48.660444, lng: 19.082341 },
    { lat: 48.66056, lng: 19.081871 },
    { lat: 48.660633, lng: 19.081286 },
    { lat: 48.66065, lng: 19.081015 },
    { lat: 48.660642, lng: 19.080751 },
    { lat: 48.660622, lng: 19.080455 },
    { lat: 48.660548, lng: 19.08008 },
    { lat: 48.660306, lng: 19.079695 },
    { lat: 48.659822, lng: 19.079098 },
    { lat: 48.659579, lng: 19.079143 },
    { lat: 48.659404, lng: 19.078999 },
    { lat: 48.659329, lng: 19.078879 },
    { lat: 48.6591847, lng: 19.0785226 },
    { lat: 48.659042, lng: 19.07817 },
    { lat: 48.658995, lng: 19.078 },
    { lat: 48.65899, lng: 19.077901 },
    { lat: 48.659037, lng: 19.077753 },
    { lat: 48.659079, lng: 19.077653 },
    { lat: 48.659182, lng: 19.077509 },
    { lat: 48.659461, lng: 19.077236 },
    { lat: 48.660087, lng: 19.076845 },
    { lat: 48.660768, lng: 19.076262 },
    { lat: 48.661566, lng: 19.075647 },
    { lat: 48.662156, lng: 19.075161 },
    { lat: 48.662769, lng: 19.074339 },
    { lat: 48.663001, lng: 19.074106 },
    { lat: 48.663544, lng: 19.073976 },
    { lat: 48.664923, lng: 19.073618 },
    { lat: 48.665041, lng: 19.073415 },
    { lat: 48.665207, lng: 19.073077 },
    { lat: 48.665486, lng: 19.072253 },
    { lat: 48.665912, lng: 19.071641 },
    { lat: 48.666622, lng: 19.070875 },
    { lat: 48.667867, lng: 19.069851 },
    { lat: 48.668311, lng: 19.068958 },
    { lat: 48.6687964, lng: 19.0686392 },
    { lat: 48.66906, lng: 19.068466 },
    { lat: 48.6692549, lng: 19.0682035 },
    { lat: 48.66953, lng: 19.067833 },
    { lat: 48.669953, lng: 19.06601 },
    { lat: 48.670229, lng: 19.065295 },
    { lat: 48.670468, lng: 19.064343 },
    { lat: 48.670613, lng: 19.063869 },
    { lat: 48.670761, lng: 19.062635 },
    { lat: 48.670915, lng: 19.062201 },
    { lat: 48.670937, lng: 19.061822 },
    { lat: 48.671033, lng: 19.061599 },
    { lat: 48.671528, lng: 19.061061 },
    { lat: 48.672002, lng: 19.060528 },
    { lat: 48.672108, lng: 19.0604 },
    { lat: 48.67226, lng: 19.060065 },
    { lat: 48.672711, lng: 19.059076 },
    { lat: 48.672827, lng: 19.058587 },
    { lat: 48.67311, lng: 19.057292 },
    { lat: 48.673352, lng: 19.056461 },
    { lat: 48.673733, lng: 19.055649 },
    { lat: 48.67393, lng: 19.055173 },
    { lat: 48.674294, lng: 19.054305 },
    { lat: 48.674456, lng: 19.053962 },
    { lat: 48.674569, lng: 19.053768 },
    { lat: 48.675161, lng: 19.052785 },
    { lat: 48.675546, lng: 19.052178 },
    { lat: 48.676227, lng: 19.051355 },
    { lat: 48.676491, lng: 19.050903 },
    { lat: 48.677115, lng: 19.049835 },
    { lat: 48.67854, lng: 19.048041 },
    { lat: 48.679158, lng: 19.046614 },
    { lat: 48.679302, lng: 19.045769 },
    { lat: 48.679761, lng: 19.045017 },
    { lat: 48.680188, lng: 19.044485 },
    { lat: 48.680849, lng: 19.043553 },
    { lat: 48.681002, lng: 19.043581 },
    { lat: 48.681185, lng: 19.043591 },
    { lat: 48.681382, lng: 19.043586 },
    { lat: 48.681476, lng: 19.04351 },
    { lat: 48.68167, lng: 19.043052 },
    { lat: 48.681924, lng: 19.04245 },
    { lat: 48.681918, lng: 19.041887 },
    { lat: 48.682122, lng: 19.040197 },
    { lat: 48.682254, lng: 19.039691 },
    { lat: 48.682278, lng: 19.039449 },
    { lat: 48.682412, lng: 19.037629 },
    { lat: 48.682633, lng: 19.036982 },
    { lat: 48.682881, lng: 19.036599 },
    { lat: 48.683306, lng: 19.035941 },
    { lat: 48.683685, lng: 19.035488 },
    { lat: 48.683816, lng: 19.035245 },
    { lat: 48.684497, lng: 19.03404 },
    { lat: 48.684787, lng: 19.033438 },
    { lat: 48.684492, lng: 19.033154 },
    { lat: 48.683817, lng: 19.032722 },
    { lat: 48.683706, lng: 19.032557 },
    { lat: 48.683349, lng: 19.03204 },
    { lat: 48.683147, lng: 19.031605 },
    { lat: 48.682898, lng: 19.031216 },
    { lat: 48.68271, lng: 19.03084 },
    { lat: 48.682517, lng: 19.030577 },
    { lat: 48.68234, lng: 19.030178 },
    { lat: 48.682241, lng: 19.02978 },
    { lat: 48.682013, lng: 19.029146 },
    { lat: 48.681615, lng: 19.028292 },
    { lat: 48.681453, lng: 19.028091 },
    { lat: 48.680943, lng: 19.027932 },
    { lat: 48.68056, lng: 19.027645 },
    { lat: 48.680185, lng: 19.027324 },
    { lat: 48.679298, lng: 19.026496 },
    { lat: 48.679085, lng: 19.026572 },
    { lat: 48.678951, lng: 19.02639 },
    { lat: 48.678687, lng: 19.026369 },
    { lat: 48.678495, lng: 19.026173 },
    { lat: 48.678131, lng: 19.026083 },
    { lat: 48.678099, lng: 19.026003 },
    { lat: 48.67738, lng: 19.026208 },
    { lat: 48.677318, lng: 19.026225 },
    { lat: 48.677269, lng: 19.026003 },
    { lat: 48.676657, lng: 19.025059 },
    { lat: 48.676414, lng: 19.024898 },
    { lat: 48.67595, lng: 19.024303 },
    { lat: 48.675812, lng: 19.024067 },
    { lat: 48.675589, lng: 19.024265 },
    { lat: 48.6755, lng: 19.023944 },
    { lat: 48.675432, lng: 19.023531 },
    { lat: 48.67525, lng: 19.022842 },
    { lat: 48.675088, lng: 19.022517 },
    { lat: 48.675039, lng: 19.022354 },
    { lat: 48.675029, lng: 19.022233 },
    { lat: 48.674877, lng: 19.022093 },
    { lat: 48.674791, lng: 19.021003 },
    { lat: 48.674635, lng: 19.020775 },
    { lat: 48.674485, lng: 19.020321 },
    { lat: 48.674481, lng: 19.020236 },
    { lat: 48.674441, lng: 19.019522 },
    { lat: 48.674355, lng: 19.019152 },
    { lat: 48.674153, lng: 19.018787 },
    { lat: 48.673911, lng: 19.018056 },
    { lat: 48.673901, lng: 19.017609 },
    { lat: 48.673822, lng: 19.017393 },
    { lat: 48.673818, lng: 19.017268 },
    { lat: 48.67382, lng: 19.017014 },
    { lat: 48.6730956, lng: 19.0172721 },
    { lat: 48.6730493, lng: 19.0170009 },
    { lat: 48.672668, lng: 19.0170394 },
    { lat: 48.6722168, lng: 19.0177045 },
    { lat: 48.672069, lng: 19.0183065 },
    { lat: 48.6712541, lng: 19.0200353 },
    { lat: 48.6708339, lng: 19.0212271 },
    { lat: 48.6708219, lng: 19.0217486 },
    { lat: 48.6707019, lng: 19.0220777 },
    { lat: 48.6708592, lng: 19.0228754 },
    { lat: 48.6704765, lng: 19.0232878 },
    { lat: 48.6688533, lng: 19.0232709 },
    { lat: 48.6683719, lng: 19.0236283 },
    { lat: 48.6678526, lng: 19.0243317 },
    { lat: 48.6676088, lng: 19.024863 },
    { lat: 48.667108, lng: 19.0255525 },
    { lat: 48.6670966, lng: 19.0257967 },
    { lat: 48.6669389, lng: 19.0260698 },
    { lat: 48.6668763, lng: 19.0268171 },
    { lat: 48.6667864, lng: 19.0270732 },
    { lat: 48.6667245, lng: 19.0275899 },
    { lat: 48.66662, lng: 19.0279536 },
    { lat: 48.6655811, lng: 19.0293793 },
    { lat: 48.6652154, lng: 19.0301766 },
    { lat: 48.6647929, lng: 19.031819 },
    { lat: 48.6646782, lng: 19.0327651 },
    { lat: 48.6643219, lng: 19.0339537 },
    { lat: 48.6637804, lng: 19.0347294 },
    { lat: 48.6634824, lng: 19.0349914 },
    { lat: 48.6632965, lng: 19.0353526 },
    { lat: 48.662761, lng: 19.0367685 },
    { lat: 48.662908, lng: 19.0376486 },
    { lat: 48.6629879, lng: 19.0391502 },
    { lat: 48.6626264, lng: 19.0404395 },
    { lat: 48.6625817, lng: 19.0419939 },
    { lat: 48.6627239, lng: 19.0423799 },
    { lat: 48.6627505, lng: 19.0429578 },
    { lat: 48.6628471, lng: 19.0432631 },
    { lat: 48.6627764, lng: 19.0437844 },
    { lat: 48.6622968, lng: 19.0457091 },
    { lat: 48.6618329, lng: 19.047091 },
    { lat: 48.661484, lng: 19.047439 },
    { lat: 48.6615132, lng: 19.047706 },
    { lat: 48.6619884, lng: 19.0480101 },
    { lat: 48.6621554, lng: 19.0485081 },
    { lat: 48.6621752, lng: 19.0488796 },
    { lat: 48.661003, lng: 19.052439 },
    { lat: 48.6607704, lng: 19.0529976 },
    { lat: 48.6599023, lng: 19.0538296 },
    { lat: 48.6589339, lng: 19.0552671 },
    { lat: 48.6583383, lng: 19.0567476 },
    { lat: 48.6580437, lng: 19.0579777 },
    { lat: 48.6578562, lng: 19.0585778 },
    { lat: 48.6578169, lng: 19.059753 },
    { lat: 48.6575377, lng: 19.0611774 },
    { lat: 48.6573819, lng: 19.0617386 },
    { lat: 48.6572058, lng: 19.062141 },
    { lat: 48.6571697, lng: 19.0626817 },
    { lat: 48.6570173, lng: 19.0633752 },
    { lat: 48.6565918, lng: 19.0638837 },
    { lat: 48.6560593, lng: 19.0642375 },
    { lat: 48.65487, lng: 19.0643495 },
    { lat: 48.6546689, lng: 19.0645008 },
    { lat: 48.6544019, lng: 19.0648454 },
    { lat: 48.6543291, lng: 19.0650562 },
    { lat: 48.6542196, lng: 19.0658425 },
    { lat: 48.6539019, lng: 19.0664349 },
    { lat: 48.651602, lng: 19.0694288 },
    { lat: 48.6509024, lng: 19.0702069 },
    { lat: 48.6498732, lng: 19.0715848 },
    { lat: 48.6494046, lng: 19.0720692 },
    { lat: 48.6488946, lng: 19.0727141 },
    { lat: 48.6482993, lng: 19.07303 },
    { lat: 48.6478311, lng: 19.0733813 },
    { lat: 48.6473396, lng: 19.0743531 },
    { lat: 48.6460525, lng: 19.0755823 },
    { lat: 48.6458847, lng: 19.075699 },
    { lat: 48.6448241, lng: 19.0759943 },
    { lat: 48.6434831, lng: 19.0769999 },
    { lat: 48.6431502, lng: 19.077401 },
    { lat: 48.6427113, lng: 19.0777999 },
    { lat: 48.6421229, lng: 19.0787287 },
    { lat: 48.6418069, lng: 19.0789023 },
    { lat: 48.6407137, lng: 19.0799259 },
    { lat: 48.6404801, lng: 19.0802825 },
    { lat: 48.639919, lng: 19.0815076 },
    { lat: 48.6397678, lng: 19.0821618 },
    { lat: 48.6390352, lng: 19.083185 },
    { lat: 48.6384671, lng: 19.0837959 },
    { lat: 48.6375497, lng: 19.0842548 },
    { lat: 48.6374927, lng: 19.0843757 },
    { lat: 48.6372462, lng: 19.0845872 },
    { lat: 48.6371206, lng: 19.0846029 },
    { lat: 48.6368573, lng: 19.0844528 },
    { lat: 48.6365178, lng: 19.084923 },
    { lat: 48.6361291, lng: 19.0852472 },
    { lat: 48.6353396, lng: 19.086527 },
    { lat: 48.634424, lng: 19.0874956 },
    { lat: 48.6335316, lng: 19.0896541 },
    { lat: 48.6331796, lng: 19.0902603 },
    { lat: 48.6330359, lng: 19.0911798 },
    { lat: 48.6328951, lng: 19.0917485 },
    { lat: 48.6326342, lng: 19.0923494 },
    { lat: 48.6326505, lng: 19.0926769 },
    { lat: 48.6325142, lng: 19.0948982 },
    { lat: 48.6323783, lng: 19.0956314 },
    { lat: 48.6321179, lng: 19.0962671 },
    { lat: 48.6316864, lng: 19.0969532 },
    { lat: 48.631041, lng: 19.0983005 },
    { lat: 48.6303141, lng: 19.0992798 },
    { lat: 48.6304134, lng: 19.0993056 },
    { lat: 48.6301916, lng: 19.0996522 },
    { lat: 48.6301605, lng: 19.1002277 },
    { lat: 48.6299879, lng: 19.100563 },
    { lat: 48.6298029, lng: 19.1011032 },
    { lat: 48.6292043, lng: 19.1019222 },
    { lat: 48.6289289, lng: 19.1021227 },
    { lat: 48.6288726, lng: 19.1023903 },
    { lat: 48.6284792, lng: 19.1028706 },
    { lat: 48.6279575, lng: 19.1032853 },
    { lat: 48.6276574, lng: 19.1036649 },
    { lat: 48.6275814, lng: 19.10368 },
    { lat: 48.6274736, lng: 19.1044822 },
    { lat: 48.626439, lng: 19.1057574 },
    { lat: 48.6258809, lng: 19.1087277 },
    { lat: 48.6253706, lng: 19.1106444 },
    { lat: 48.6246185, lng: 19.1131839 },
    { lat: 48.6244836, lng: 19.1135124 },
    { lat: 48.624004, lng: 19.11423 },
    { lat: 48.6239917, lng: 19.1147046 },
    { lat: 48.6238594, lng: 19.1154476 },
    { lat: 48.6238045, lng: 19.1182998 },
    { lat: 48.6237397, lng: 19.1188552 },
    { lat: 48.6238192, lng: 19.119411 },
    { lat: 48.6237396, lng: 19.1203311 },
    { lat: 48.6235414, lng: 19.1215176 },
    { lat: 48.6235406, lng: 19.1218887 },
    { lat: 48.623679, lng: 19.1224065 },
    { lat: 48.6239026, lng: 19.1226671 },
    { lat: 48.6240806, lng: 19.1241661 },
    { lat: 48.624339, lng: 19.124897 },
    { lat: 48.6248429, lng: 19.1268336 },
    { lat: 48.6242573, lng: 19.1280751 },
    { lat: 48.6241791, lng: 19.128523 },
    { lat: 48.6239753, lng: 19.1291207 },
    { lat: 48.6237279, lng: 19.1303854 },
    { lat: 48.6230535, lng: 19.1330935 },
    { lat: 48.6220613, lng: 19.1376716 },
    { lat: 48.6222655, lng: 19.1397776 },
    { lat: 48.6223675, lng: 19.1403356 },
    { lat: 48.6223585, lng: 19.1403783 },
    { lat: 48.6229488, lng: 19.1406693 },
    { lat: 48.6236605, lng: 19.1409029 },
    { lat: 48.6242684, lng: 19.1409333 },
    { lat: 48.6249033, lng: 19.1407077 },
    { lat: 48.6258806, lng: 19.1405701 },
    { lat: 48.6261952, lng: 19.1404592 },
    { lat: 48.6268735, lng: 19.1404503 },
    { lat: 48.6270021, lng: 19.1403624 },
    { lat: 48.6271766, lng: 19.1404273 },
    { lat: 48.6273414, lng: 19.140588 },
    { lat: 48.6274403, lng: 19.1407882 },
    { lat: 48.6276186, lng: 19.1409545 },
    { lat: 48.6277975, lng: 19.1412434 },
    { lat: 48.628221, lng: 19.1414189 },
    { lat: 48.6282497, lng: 19.1414306 },
  ],
  Breziny: [
    { lat: 48.5348043, lng: 19.0899003 },
    { lat: 48.5340657, lng: 19.0900536 },
    { lat: 48.5331721, lng: 19.0906278 },
    { lat: 48.5312508, lng: 19.0912777 },
    { lat: 48.5300415, lng: 19.0914853 },
    { lat: 48.5288511, lng: 19.0917839 },
    { lat: 48.5275726, lng: 19.0918037 },
    { lat: 48.5262516, lng: 19.0922842 },
    { lat: 48.5251746, lng: 19.09316 },
    { lat: 48.5239764, lng: 19.093771 },
    { lat: 48.5225933, lng: 19.0924173 },
    { lat: 48.5214235, lng: 19.0924287 },
    { lat: 48.520061, lng: 19.092778 },
    { lat: 48.5192692, lng: 19.0933323 },
    { lat: 48.5177375, lng: 19.0940631 },
    { lat: 48.517038, lng: 19.0939409 },
    { lat: 48.5163697, lng: 19.0936751 },
    { lat: 48.5163098, lng: 19.093583 },
    { lat: 48.5163398, lng: 19.0934601 },
    { lat: 48.5165384, lng: 19.093103 },
    { lat: 48.516764, lng: 19.0928571 },
    { lat: 48.516823, lng: 19.0924582 },
    { lat: 48.5168623, lng: 19.0924086 },
    { lat: 48.5168361, lng: 19.0923137 },
    { lat: 48.5170227, lng: 19.0921244 },
    { lat: 48.5170371, lng: 19.0922085 },
    { lat: 48.5171357, lng: 19.0923097 },
    { lat: 48.5171804, lng: 19.0922542 },
    { lat: 48.5171349, lng: 19.0921331 },
    { lat: 48.5171604, lng: 19.0920801 },
    { lat: 48.5172536, lng: 19.0922299 },
    { lat: 48.5173355, lng: 19.092152 },
    { lat: 48.5173413, lng: 19.0918196 },
    { lat: 48.5172219, lng: 19.0915291 },
    { lat: 48.5172921, lng: 19.0914671 },
    { lat: 48.5172675, lng: 19.0913998 },
    { lat: 48.5174323, lng: 19.091181 },
    { lat: 48.5180982, lng: 19.0907678 },
    { lat: 48.5184077, lng: 19.0903705 },
    { lat: 48.5182973, lng: 19.0899438 },
    { lat: 48.5188979, lng: 19.0893072 },
    { lat: 48.5190685, lng: 19.0889053 },
    { lat: 48.5194496, lng: 19.088727 },
    { lat: 48.5194978, lng: 19.0887881 },
    { lat: 48.5200891, lng: 19.0885988 },
    { lat: 48.5202406, lng: 19.0886748 },
    { lat: 48.5205798, lng: 19.0886514 },
    { lat: 48.5213417, lng: 19.0877615 },
    { lat: 48.5210175, lng: 19.0872117 },
    { lat: 48.5207311, lng: 19.0868711 },
    { lat: 48.520063, lng: 19.0862703 },
    { lat: 48.5199874, lng: 19.08609 },
    { lat: 48.5203796, lng: 19.0858817 },
    { lat: 48.5207986, lng: 19.0853791 },
    { lat: 48.5211932, lng: 19.0842743 },
    { lat: 48.5212577, lng: 19.0828583 },
    { lat: 48.5216835, lng: 19.0815654 },
    { lat: 48.5217205, lng: 19.0805227 },
    { lat: 48.5218584, lng: 19.0798805 },
    { lat: 48.5221956, lng: 19.0792953 },
    { lat: 48.5222914, lng: 19.0789764 },
    { lat: 48.5222909, lng: 19.0789015 },
    { lat: 48.5223133, lng: 19.0786507 },
    { lat: 48.5223697, lng: 19.0780198 },
    { lat: 48.52222, lng: 19.0770314 },
    { lat: 48.5221586, lng: 19.0768662 },
    { lat: 48.5201722, lng: 19.0760349 },
    { lat: 48.5201737, lng: 19.0744877 },
    { lat: 48.5187116, lng: 19.0747581 },
    { lat: 48.5181912, lng: 19.0750181 },
    { lat: 48.5171519, lng: 19.0743412 },
    { lat: 48.5161768, lng: 19.0744141 },
    { lat: 48.5158242, lng: 19.0744875 },
    { lat: 48.5158614, lng: 19.0748439 },
    { lat: 48.5157226, lng: 19.0748418 },
    { lat: 48.5153848, lng: 19.0749893 },
    { lat: 48.5152099, lng: 19.0745102 },
    { lat: 48.5151087, lng: 19.0739755 },
    { lat: 48.5148522, lng: 19.0738962 },
    { lat: 48.5145693, lng: 19.0742344 },
    { lat: 48.5140744, lng: 19.0743243 },
    { lat: 48.5136504, lng: 19.0745516 },
    { lat: 48.5134499, lng: 19.074238 },
    { lat: 48.5130814, lng: 19.0739039 },
    { lat: 48.5124026, lng: 19.0745516 },
    { lat: 48.5120173, lng: 19.0747845 },
    { lat: 48.5116255, lng: 19.0749093 },
    { lat: 48.5113888, lng: 19.0740753 },
    { lat: 48.5109298, lng: 19.0741412 },
    { lat: 48.5107964, lng: 19.0737748 },
    { lat: 48.5101824, lng: 19.0741862 },
    { lat: 48.5091436, lng: 19.0742688 },
    { lat: 48.5074188, lng: 19.0755289 },
    { lat: 48.5074238, lng: 19.075652 },
    { lat: 48.5064623, lng: 19.0756117 },
    { lat: 48.506469, lng: 19.0751587 },
    { lat: 48.5060406, lng: 19.0747995 },
    { lat: 48.5058458, lng: 19.0744892 },
    { lat: 48.505859, lng: 19.0741869 },
    { lat: 48.5057601, lng: 19.0739493 },
    { lat: 48.505753, lng: 19.0734491 },
    { lat: 48.5053309, lng: 19.0733281 },
    { lat: 48.5044991, lng: 19.0735106 },
    { lat: 48.5037632, lng: 19.0735831 },
    { lat: 48.5035024, lng: 19.073442 },
    { lat: 48.50261, lng: 19.0723914 },
    { lat: 48.5017104, lng: 19.0711571 },
    { lat: 48.5015884, lng: 19.070905 },
    { lat: 48.5015955, lng: 19.0706664 },
    { lat: 48.499133, lng: 19.0719607 },
    { lat: 48.4986045, lng: 19.0722385 },
    { lat: 48.4976388, lng: 19.0718661 },
    { lat: 48.4972145, lng: 19.0715403 },
    { lat: 48.4969767, lng: 19.0712988 },
    { lat: 48.4960111, lng: 19.070048 },
    { lat: 48.4959557, lng: 19.0699975 },
    { lat: 48.4956468, lng: 19.0704957 },
    { lat: 48.4955474, lng: 19.0708731 },
    { lat: 48.4954358, lng: 19.0709853 },
    { lat: 48.4953126, lng: 19.0714965 },
    { lat: 48.4947707, lng: 19.0717094 },
    { lat: 48.4944247, lng: 19.0725413 },
    { lat: 48.4945591, lng: 19.0732668 },
    { lat: 48.4948179, lng: 19.0738627 },
    { lat: 48.4948352, lng: 19.0740061 },
    { lat: 48.4947106, lng: 19.074627 },
    { lat: 48.4948013, lng: 19.0748385 },
    { lat: 48.4948279, lng: 19.075053 },
    { lat: 48.4947722, lng: 19.0753135 },
    { lat: 48.4944302, lng: 19.0754187 },
    { lat: 48.4943468, lng: 19.0755796 },
    { lat: 48.4945007, lng: 19.0759731 },
    { lat: 48.4945061, lng: 19.0761047 },
    { lat: 48.4944319, lng: 19.0763537 },
    { lat: 48.4942501, lng: 19.0765311 },
    { lat: 48.4943982, lng: 19.0768079 },
    { lat: 48.4947784, lng: 19.0772859 },
    { lat: 48.4948738, lng: 19.0775257 },
    { lat: 48.4948715, lng: 19.0779283 },
    { lat: 48.4946902, lng: 19.0784077 },
    { lat: 48.4946535, lng: 19.0787329 },
    { lat: 48.4948204, lng: 19.0791355 },
    { lat: 48.494931, lng: 19.079231 },
    { lat: 48.4952418, lng: 19.0798523 },
    { lat: 48.4957358, lng: 19.0810092 },
    { lat: 48.4956818, lng: 19.0813455 },
    { lat: 48.4959844, lng: 19.0815153 },
    { lat: 48.4960595, lng: 19.0816415 },
    { lat: 48.4960795, lng: 19.081881 },
    { lat: 48.4961696, lng: 19.081993 },
    { lat: 48.4960772, lng: 19.0823074 },
    { lat: 48.4961205, lng: 19.0825684 },
    { lat: 48.4962435, lng: 19.0830208 },
    { lat: 48.4965467, lng: 19.0836508 },
    { lat: 48.4963049, lng: 19.0841108 },
    { lat: 48.4963371, lng: 19.0841928 },
    { lat: 48.4964742, lng: 19.0842274 },
    { lat: 48.4964257, lng: 19.084657 },
    { lat: 48.4961801, lng: 19.0848433 },
    { lat: 48.4961252, lng: 19.0850713 },
    { lat: 48.4958915, lng: 19.0851315 },
    { lat: 48.4957312, lng: 19.0850157 },
    { lat: 48.4956625, lng: 19.085107 },
    { lat: 48.4954887, lng: 19.0856477 },
    { lat: 48.4953429, lng: 19.0857278 },
    { lat: 48.4952409, lng: 19.0859448 },
    { lat: 48.4951821, lng: 19.0863901 },
    { lat: 48.495052, lng: 19.0865623 },
    { lat: 48.4949687, lng: 19.0870167 },
    { lat: 48.4948276, lng: 19.0871248 },
    { lat: 48.4947529, lng: 19.0873216 },
    { lat: 48.4949479, lng: 19.0877065 },
    { lat: 48.4949727, lng: 19.0879102 },
    { lat: 48.4949112, lng: 19.0882773 },
    { lat: 48.4949754, lng: 19.088844 },
    { lat: 48.4949808, lng: 19.0890659 },
    { lat: 48.4949192, lng: 19.0892282 },
    { lat: 48.4949462, lng: 19.0895643 },
    { lat: 48.4945567, lng: 19.0898724 },
    { lat: 48.4946947, lng: 19.0901298 },
    { lat: 48.4948549, lng: 19.0901767 },
    { lat: 48.4949573, lng: 19.0904445 },
    { lat: 48.4948553, lng: 19.0910653 },
    { lat: 48.4949239, lng: 19.0914211 },
    { lat: 48.4948505, lng: 19.0916455 },
    { lat: 48.4948442, lng: 19.0919925 },
    { lat: 48.4947774, lng: 19.0921248 },
    { lat: 48.494618, lng: 19.0921367 },
    { lat: 48.4945423, lng: 19.0923134 },
    { lat: 48.4945957, lng: 19.0924364 },
    { lat: 48.4945771, lng: 19.0925611 },
    { lat: 48.4943855, lng: 19.0926276 },
    { lat: 48.4942904, lng: 19.0927567 },
    { lat: 48.4942621, lng: 19.0930712 },
    { lat: 48.4941911, lng: 19.0932286 },
    { lat: 48.4942428, lng: 19.0934495 },
    { lat: 48.4942055, lng: 19.0940142 },
    { lat: 48.4942477, lng: 19.0941363 },
    { lat: 48.4943898, lng: 19.0942699 },
    { lat: 48.4945742, lng: 19.0942626 },
    { lat: 48.4946511, lng: 19.0945585 },
    { lat: 48.4947323, lng: 19.0946528 },
    { lat: 48.4949583, lng: 19.0946603 },
    { lat: 48.4952835, lng: 19.0946971 },
    { lat: 48.4953614, lng: 19.0946763 },
    { lat: 48.495784, lng: 19.0941974 },
    { lat: 48.496051, lng: 19.0940374 },
    { lat: 48.4962283, lng: 19.0941294 },
    { lat: 48.4966153, lng: 19.0945005 },
    { lat: 48.4967414, lng: 19.0945406 },
    { lat: 48.4970681, lng: 19.0942904 },
    { lat: 48.4973135, lng: 19.094279 },
    { lat: 48.4977146, lng: 19.0940514 },
    { lat: 48.4979537, lng: 19.0942554 },
    { lat: 48.4980595, lng: 19.0942306 },
    { lat: 48.4984284, lng: 19.093761 },
    { lat: 48.4985837, lng: 19.0937338 },
    { lat: 48.4987678, lng: 19.0938054 },
    { lat: 48.4989268, lng: 19.0940338 },
    { lat: 48.4992392, lng: 19.0940355 },
    { lat: 48.4994016, lng: 19.0941028 },
    { lat: 48.4995249, lng: 19.0942687 },
    { lat: 48.4995888, lng: 19.0945352 },
    { lat: 48.4998501, lng: 19.0948015 },
    { lat: 48.5000126, lng: 19.0948485 },
    { lat: 48.5004057, lng: 19.0944788 },
    { lat: 48.5005013, lng: 19.0945437 },
    { lat: 48.5006932, lng: 19.0949099 },
    { lat: 48.5008603, lng: 19.0949394 },
    { lat: 48.5011696, lng: 19.0945628 },
    { lat: 48.5012765, lng: 19.0945589 },
    { lat: 48.501502, lng: 19.0947616 },
    { lat: 48.5022659, lng: 19.0950475 },
    { lat: 48.5024874, lng: 19.0953062 },
    { lat: 48.5025985, lng: 19.0953637 },
    { lat: 48.5030762, lng: 19.0953132 },
    { lat: 48.5032987, lng: 19.0952085 },
    { lat: 48.5033736, lng: 19.0949007 },
    { lat: 48.5032848, lng: 19.0944745 },
    { lat: 48.5033049, lng: 19.0942906 },
    { lat: 48.5033345, lng: 19.0942062 },
    { lat: 48.5035484, lng: 19.0940857 },
    { lat: 48.5037075, lng: 19.0940929 },
    { lat: 48.5037937, lng: 19.094011 },
    { lat: 48.5038978, lng: 19.0940426 },
    { lat: 48.5039744, lng: 19.093992 },
    { lat: 48.5045231, lng: 19.0941223 },
    { lat: 48.5054264, lng: 19.0940611 },
    { lat: 48.5055983, lng: 19.0942525 },
    { lat: 48.5087519, lng: 19.0957965 },
    { lat: 48.5093845, lng: 19.0957286 },
    { lat: 48.5097932, lng: 19.0958562 },
    { lat: 48.5100821, lng: 19.0961304 },
    { lat: 48.5103783, lng: 19.0965329 },
    { lat: 48.5108254, lng: 19.0968201 },
    { lat: 48.5115528, lng: 19.0968667 },
    { lat: 48.5122178, lng: 19.0967528 },
    { lat: 48.5127042, lng: 19.096536 },
    { lat: 48.5129797, lng: 19.0961614 },
    { lat: 48.513059, lng: 19.0961223 },
    { lat: 48.5133882, lng: 19.0962806 },
    { lat: 48.5135589, lng: 19.0959772 },
    { lat: 48.5138021, lng: 19.0957887 },
    { lat: 48.5139724, lng: 19.0952012 },
    { lat: 48.5142802, lng: 19.0949983 },
    { lat: 48.5146583, lng: 19.094824 },
    { lat: 48.5148003, lng: 19.0948485 },
    { lat: 48.5149509, lng: 19.0950633 },
    { lat: 48.5152606, lng: 19.0950116 },
    { lat: 48.5154967, lng: 19.095269 },
    { lat: 48.515591, lng: 19.0958287 },
    { lat: 48.5158995, lng: 19.0961363 },
    { lat: 48.5160004, lng: 19.096169 },
    { lat: 48.5160858, lng: 19.0960725 },
    { lat: 48.5162375, lng: 19.0957275 },
    { lat: 48.5164074, lng: 19.0958083 },
    { lat: 48.5164375, lng: 19.0960282 },
    { lat: 48.5163543, lng: 19.0963256 },
    { lat: 48.5163156, lng: 19.0967954 },
    { lat: 48.5162247, lng: 19.0970525 },
    { lat: 48.5162827, lng: 19.0972646 },
    { lat: 48.5162575, lng: 19.0976629 },
    { lat: 48.5162871, lng: 19.0979639 },
    { lat: 48.5166388, lng: 19.0981184 },
    { lat: 48.5171715, lng: 19.0982225 },
    { lat: 48.5181992, lng: 19.0980765 },
    { lat: 48.5184287, lng: 19.0981121 },
    { lat: 48.5187191, lng: 19.0983062 },
    { lat: 48.5190289, lng: 19.0986872 },
    { lat: 48.519301, lng: 19.0989197 },
    { lat: 48.5193517, lng: 19.0991689 },
    { lat: 48.519428, lng: 19.0992542 },
    { lat: 48.5196188, lng: 19.0992806 },
    { lat: 48.5198154, lng: 19.0994953 },
    { lat: 48.5202728, lng: 19.0997565 },
    { lat: 48.5204589, lng: 19.1003052 },
    { lat: 48.5205137, lng: 19.1006557 },
    { lat: 48.5206212, lng: 19.100799 },
    { lat: 48.5208177, lng: 19.1014838 },
    { lat: 48.520994, lng: 19.1019034 },
    { lat: 48.5210964, lng: 19.102304 },
    { lat: 48.5211105, lng: 19.1025862 },
    { lat: 48.521916, lng: 19.104028 },
    { lat: 48.5219332, lng: 19.1048903 },
    { lat: 48.5220026, lng: 19.1052878 },
    { lat: 48.5223018, lng: 19.105912 },
    { lat: 48.5226333, lng: 19.1061836 },
    { lat: 48.5238033, lng: 19.1066979 },
    { lat: 48.5245206, lng: 19.1075637 },
    { lat: 48.5247304, lng: 19.1076595 },
    { lat: 48.5248868, lng: 19.1076543 },
    { lat: 48.5251843, lng: 19.1075375 },
    { lat: 48.5253004, lng: 19.107108 },
    { lat: 48.5264797, lng: 19.1040706 },
    { lat: 48.5276118, lng: 19.1009079 },
    { lat: 48.5280673, lng: 19.0997582 },
    { lat: 48.5282979, lng: 19.0979609 },
    { lat: 48.5284303, lng: 19.0960505 },
    { lat: 48.5288525, lng: 19.0955635 },
    { lat: 48.5288951, lng: 19.0953983 },
    { lat: 48.5292841, lng: 19.0948813 },
    { lat: 48.529891, lng: 19.0939153 },
    { lat: 48.5301524, lng: 19.0937982 },
    { lat: 48.5302323, lng: 19.0935964 },
    { lat: 48.5306001, lng: 19.0934385 },
    { lat: 48.5309496, lng: 19.0933755 },
    { lat: 48.532813, lng: 19.0919913 },
    { lat: 48.5339036, lng: 19.0913023 },
    { lat: 48.5348043, lng: 19.0899003 },
  ],
  ZvolenskáSlatina: [
    { lat: 48.50204, lng: 19.241556 },
    { lat: 48.50177, lng: 19.242537 },
    { lat: 48.501847, lng: 19.242778 },
    { lat: 48.501697, lng: 19.242935 },
    { lat: 48.501513, lng: 19.243343 },
    { lat: 48.501578, lng: 19.243595 },
    { lat: 48.501642, lng: 19.243945 },
    { lat: 48.501863, lng: 19.244382 },
    { lat: 48.501878, lng: 19.24441 },
    { lat: 48.501885, lng: 19.244425 },
    { lat: 48.501823, lng: 19.244807 },
    { lat: 48.501801, lng: 19.245131 },
    { lat: 48.501887, lng: 19.245725 },
    { lat: 48.50189, lng: 19.245883 },
    { lat: 48.502037, lng: 19.246134 },
    { lat: 48.502068, lng: 19.246289 },
    { lat: 48.502109, lng: 19.246413 },
    { lat: 48.502093, lng: 19.246546 },
    { lat: 48.501992, lng: 19.246836 },
    { lat: 48.50194, lng: 19.247065 },
    { lat: 48.501815, lng: 19.247315 },
    { lat: 48.501765, lng: 19.247583 },
    { lat: 48.501663, lng: 19.247857 },
    { lat: 48.501406, lng: 19.249294 },
    { lat: 48.50137, lng: 19.249717 },
    { lat: 48.501264, lng: 19.250068 },
    { lat: 48.50126, lng: 19.250203 },
    { lat: 48.501268, lng: 19.250308 },
    { lat: 48.501201, lng: 19.250541 },
    { lat: 48.501124, lng: 19.250697 },
    { lat: 48.501114, lng: 19.25076 },
    { lat: 48.501106, lng: 19.250818 },
    { lat: 48.501089, lng: 19.250934 },
    { lat: 48.501023, lng: 19.251016 },
    { lat: 48.500797, lng: 19.251009 },
    { lat: 48.500673, lng: 19.251164 },
    { lat: 48.500471, lng: 19.251901 },
    { lat: 48.500388, lng: 19.252129 },
    { lat: 48.500327, lng: 19.252295 },
    { lat: 48.500244, lng: 19.252609 },
    { lat: 48.500147, lng: 19.252784 },
    { lat: 48.500083, lng: 19.25313 },
    { lat: 48.500141, lng: 19.253843 },
    { lat: 48.500145, lng: 19.254127 },
    { lat: 48.50013, lng: 19.254316 },
    { lat: 48.500184, lng: 19.254671 },
    { lat: 48.500174, lng: 19.254884 },
    { lat: 48.500115, lng: 19.255194 },
    { lat: 48.500164, lng: 19.255573 },
    { lat: 48.50015, lng: 19.256094 },
    { lat: 48.500037, lng: 19.256688 },
    { lat: 48.500007, lng: 19.256937 },
    { lat: 48.499792, lng: 19.257659 },
    { lat: 48.499692, lng: 19.257923 },
    { lat: 48.499634, lng: 19.258076 },
    { lat: 48.499447, lng: 19.258372 },
    { lat: 48.499471, lng: 19.258474 },
    { lat: 48.499479, lng: 19.258509 },
    { lat: 48.499527, lng: 19.258706 },
    { lat: 48.499515, lng: 19.25892 },
    { lat: 48.499571, lng: 19.259489 },
    { lat: 48.499432, lng: 19.260428 },
    { lat: 48.498738, lng: 19.260757 },
    { lat: 48.498504, lng: 19.260555 },
    { lat: 48.49843, lng: 19.260531 },
    { lat: 48.498347, lng: 19.260504 },
    { lat: 48.498286, lng: 19.260485 },
    { lat: 48.498147, lng: 19.26044 },
    { lat: 48.497937, lng: 19.260542 },
    { lat: 48.497938, lng: 19.261066 },
    { lat: 48.497965, lng: 19.261371 },
    { lat: 48.497912, lng: 19.26138 },
    { lat: 48.497829, lng: 19.261285 },
    { lat: 48.497632, lng: 19.261221 },
    { lat: 48.497505, lng: 19.261288 },
    { lat: 48.497417, lng: 19.261223 },
    { lat: 48.497216, lng: 19.261239 },
    { lat: 48.497132, lng: 19.261171 },
    { lat: 48.497046, lng: 19.261175 },
    { lat: 48.496931, lng: 19.261285 },
    { lat: 48.496897, lng: 19.26145 },
    { lat: 48.496815, lng: 19.261311 },
    { lat: 48.496694, lng: 19.261289 },
    { lat: 48.496626, lng: 19.261336 },
    { lat: 48.496543, lng: 19.261299 },
    { lat: 48.496564, lng: 19.261049 },
    { lat: 48.496416, lng: 19.261039 },
    { lat: 48.496379, lng: 19.261313 },
    { lat: 48.496375, lng: 19.261347 },
    { lat: 48.496258, lng: 19.262049 },
    { lat: 48.496579, lng: 19.262319 },
    { lat: 48.496832, lng: 19.262533 },
    { lat: 48.496957, lng: 19.262897 },
    { lat: 48.497313, lng: 19.262869 },
    { lat: 48.497746, lng: 19.263828 },
    { lat: 48.497841, lng: 19.264035 },
    { lat: 48.497867, lng: 19.264099 },
    { lat: 48.4981, lng: 19.26461 },
    { lat: 48.498455, lng: 19.264652 },
    { lat: 48.498653, lng: 19.264701 },
    { lat: 48.49898, lng: 19.264862 },
    { lat: 48.499242, lng: 19.264552 },
    { lat: 48.499083, lng: 19.264202 },
    { lat: 48.49905, lng: 19.264127 },
    { lat: 48.498998, lng: 19.263703 },
    { lat: 48.499079, lng: 19.263396 },
    { lat: 48.499392, lng: 19.263037 },
    { lat: 48.499669, lng: 19.262922 },
    { lat: 48.499658, lng: 19.262767 },
    { lat: 48.500366, lng: 19.262451 },
    { lat: 48.500425, lng: 19.262507 },
    { lat: 48.500584, lng: 19.26266 },
    { lat: 48.500993, lng: 19.262024 },
    { lat: 48.501803, lng: 19.261555 },
    { lat: 48.502136, lng: 19.261479 },
    { lat: 48.502841, lng: 19.261026 },
    { lat: 48.503154, lng: 19.261111 },
    { lat: 48.503359, lng: 19.26111 },
    { lat: 48.503554, lng: 19.261037 },
    { lat: 48.50405, lng: 19.261424 },
    { lat: 48.504051, lng: 19.261361 },
    { lat: 48.504049, lng: 19.261016 },
    { lat: 48.5042, lng: 19.261045 },
    { lat: 48.504415, lng: 19.260883 },
    { lat: 48.50472, lng: 19.260706 },
    { lat: 48.504855, lng: 19.260452 },
    { lat: 48.504935, lng: 19.260396 },
    { lat: 48.505012, lng: 19.260435 },
    { lat: 48.505061, lng: 19.26046 },
    { lat: 48.505687, lng: 19.260777 },
    { lat: 48.505846, lng: 19.260799 },
    { lat: 48.50607, lng: 19.260714 },
    { lat: 48.506222, lng: 19.260326 },
    { lat: 48.506324, lng: 19.260151 },
    { lat: 48.506428, lng: 19.260115 },
    { lat: 48.506534, lng: 19.260116 },
    { lat: 48.506758, lng: 19.260214 },
    { lat: 48.506891, lng: 19.260217 },
    { lat: 48.50714, lng: 19.260332 },
    { lat: 48.507361, lng: 19.260257 },
    { lat: 48.507529, lng: 19.260346 },
    { lat: 48.507587, lng: 19.260508 },
    { lat: 48.507536, lng: 19.26133 },
    { lat: 48.507448, lng: 19.261609 },
    { lat: 48.507212, lng: 19.26215 },
    { lat: 48.507182, lng: 19.262554 },
    { lat: 48.507031, lng: 19.262774 },
    { lat: 48.506887, lng: 19.263061 },
    { lat: 48.506812, lng: 19.263375 },
    { lat: 48.506786, lng: 19.263785 },
    { lat: 48.506407, lng: 19.26443 },
    { lat: 48.506361, lng: 19.265243 },
    { lat: 48.50628, lng: 19.265815 },
    { lat: 48.506117, lng: 19.266077 },
    { lat: 48.505978, lng: 19.266181 },
    { lat: 48.505913, lng: 19.266412 },
    { lat: 48.505742, lng: 19.266702 },
    { lat: 48.506171, lng: 19.269168 },
    { lat: 48.506179, lng: 19.269205 },
    { lat: 48.506628, lng: 19.271789 },
    { lat: 48.506079, lng: 19.272303 },
    { lat: 48.506038, lng: 19.27234 },
    { lat: 48.506009, lng: 19.272368 },
    { lat: 48.504957, lng: 19.273352 },
    { lat: 48.504919, lng: 19.273387 },
    { lat: 48.503013, lng: 19.27517 },
    { lat: 48.503011, lng: 19.275591 },
    { lat: 48.502769, lng: 19.277388 },
    { lat: 48.502574, lng: 19.278328 },
    { lat: 48.502565, lng: 19.278375 },
    { lat: 48.502359, lng: 19.279366 },
    { lat: 48.502691, lng: 19.279905 },
    { lat: 48.502844, lng: 19.280155 },
    { lat: 48.503428, lng: 19.280792 },
    { lat: 48.503459, lng: 19.280825 },
    { lat: 48.503505, lng: 19.280876 },
    { lat: 48.503925, lng: 19.281523 },
    { lat: 48.50507, lng: 19.281464 },
    { lat: 48.50536, lng: 19.281516 },
    { lat: 48.505786, lng: 19.281516 },
    { lat: 48.506283, lng: 19.281638 },
    { lat: 48.506522, lng: 19.281998 },
    { lat: 48.507103, lng: 19.283116 },
    { lat: 48.50746, lng: 19.284447 },
    { lat: 48.508321, lng: 19.285099 },
    { lat: 48.509119, lng: 19.286125 },
    { lat: 48.509545, lng: 19.286357 },
    { lat: 48.509858, lng: 19.286675 },
    { lat: 48.510147, lng: 19.28678 },
    { lat: 48.510599, lng: 19.286995 },
    { lat: 48.511703, lng: 19.287634 },
    { lat: 48.512663, lng: 19.288079 },
    { lat: 48.512833, lng: 19.288149 },
    { lat: 48.513533, lng: 19.288554 },
    { lat: 48.513802, lng: 19.288884 },
    { lat: 48.514187, lng: 19.289215 },
    { lat: 48.514553, lng: 19.289311 },
    { lat: 48.514862, lng: 19.289359 },
    { lat: 48.515156, lng: 19.289395 },
    { lat: 48.515648, lng: 19.289117 },
    { lat: 48.51614, lng: 19.289043 },
    { lat: 48.516411, lng: 19.288952 },
    { lat: 48.51693, lng: 19.288875 },
    { lat: 48.517772, lng: 19.288946 },
    { lat: 48.518249, lng: 19.289098 },
    { lat: 48.518635, lng: 19.289083 },
    { lat: 48.51862, lng: 19.289227 },
    { lat: 48.518771, lng: 19.289265 },
    { lat: 48.519508, lng: 19.289408 },
    { lat: 48.520224, lng: 19.289769 },
    { lat: 48.520436, lng: 19.289893 },
    { lat: 48.521079, lng: 19.288904 },
    { lat: 48.521472, lng: 19.288217 },
    { lat: 48.521666, lng: 19.287585 },
    { lat: 48.521916, lng: 19.28718 },
    { lat: 48.522774, lng: 19.286923 },
    { lat: 48.523737, lng: 19.286513 },
    { lat: 48.524318, lng: 19.286153 },
    { lat: 48.524492, lng: 19.285847 },
    { lat: 48.524526, lng: 19.285764 },
    { lat: 48.524958, lng: 19.28495 },
    { lat: 48.526068, lng: 19.284155 },
    { lat: 48.526113, lng: 19.284123 },
    { lat: 48.52629, lng: 19.283758 },
    { lat: 48.526698, lng: 19.283392 },
    { lat: 48.526904, lng: 19.283152 },
    { lat: 48.527055, lng: 19.283025 },
    { lat: 48.527124, lng: 19.282922 },
    { lat: 48.527314, lng: 19.282691 },
    { lat: 48.527417, lng: 19.282464 },
    { lat: 48.52752, lng: 19.28236 },
    { lat: 48.527634, lng: 19.282237 },
    { lat: 48.52791, lng: 19.281877 },
    { lat: 48.52797, lng: 19.28172 },
    { lat: 48.528075, lng: 19.281286 },
    { lat: 48.528231, lng: 19.280815 },
    { lat: 48.528445, lng: 19.280295 },
    { lat: 48.528765, lng: 19.279617 },
    { lat: 48.529255, lng: 19.279098 },
    { lat: 48.529366, lng: 19.278881 },
    { lat: 48.529478, lng: 19.278541 },
    { lat: 48.5296, lng: 19.278157 },
    { lat: 48.529769, lng: 19.277867 },
    { lat: 48.530145, lng: 19.277521 },
    { lat: 48.530187, lng: 19.277495 },
    { lat: 48.531721, lng: 19.276635 },
    { lat: 48.532324, lng: 19.276161 },
    { lat: 48.533691, lng: 19.27543 },
    { lat: 48.538723, lng: 19.283503 },
    { lat: 48.539374, lng: 19.283891 },
    { lat: 48.539531, lng: 19.283984 },
    { lat: 48.53987, lng: 19.284185 },
    { lat: 48.540414, lng: 19.284598 },
    { lat: 48.540522, lng: 19.284688 },
    { lat: 48.540571, lng: 19.284747 },
    { lat: 48.540424, lng: 19.285238 },
    { lat: 48.539449, lng: 19.286794 },
    { lat: 48.53929, lng: 19.287333 },
    { lat: 48.539313, lng: 19.287937 },
    { lat: 48.539316, lng: 19.288014 },
    { lat: 48.538905, lng: 19.288887 },
    { lat: 48.538897, lng: 19.288917 },
    { lat: 48.538879, lng: 19.28895 },
    { lat: 48.539156, lng: 19.289583 },
    { lat: 48.539533, lng: 19.289291 },
    { lat: 48.539942, lng: 19.288993 },
    { lat: 48.541041, lng: 19.288029 },
    { lat: 48.541281, lng: 19.287759 },
    { lat: 48.541709, lng: 19.286892 },
    { lat: 48.542907, lng: 19.284499 },
    { lat: 48.542963, lng: 19.284312 },
    { lat: 48.543251, lng: 19.284098 },
    { lat: 48.543867, lng: 19.283476 },
    { lat: 48.544375, lng: 19.282995 },
    { lat: 48.544664, lng: 19.282603 },
    { lat: 48.545968, lng: 19.281535 },
    { lat: 48.546002, lng: 19.281515 },
    { lat: 48.546904, lng: 19.281386 },
    { lat: 48.547602, lng: 19.281816 },
    { lat: 48.548083, lng: 19.281543 },
    { lat: 48.548105, lng: 19.28153 },
    { lat: 48.548293, lng: 19.282262 },
    { lat: 48.548845, lng: 19.282145 },
    { lat: 48.549156, lng: 19.28314 },
    { lat: 48.549255, lng: 19.284578 },
    { lat: 48.550158, lng: 19.284123 },
    { lat: 48.550282, lng: 19.284651 },
    { lat: 48.550511, lng: 19.285106 },
    { lat: 48.550725, lng: 19.285396 },
    { lat: 48.550682, lng: 19.284988 },
    { lat: 48.550722, lng: 19.284995 },
    { lat: 48.550842, lng: 19.285014 },
    { lat: 48.550866, lng: 19.285019 },
    { lat: 48.550884, lng: 19.285021 },
    { lat: 48.550712, lng: 19.284402 },
    { lat: 48.550718, lng: 19.283889 },
    { lat: 48.550664, lng: 19.28345 },
    { lat: 48.550472, lng: 19.28287 },
    { lat: 48.550284, lng: 19.282294 },
    { lat: 48.550136, lng: 19.281824 },
    { lat: 48.5499, lng: 19.281283 },
    { lat: 48.54981, lng: 19.280754 },
    { lat: 48.55001, lng: 19.280274 },
    { lat: 48.550345, lng: 19.280238 },
    { lat: 48.550704, lng: 19.280238 },
    { lat: 48.553025, lng: 19.280079 },
    { lat: 48.554202, lng: 19.279999 },
    { lat: 48.554289, lng: 19.279604 },
    { lat: 48.554135, lng: 19.279579 },
    { lat: 48.554117, lng: 19.279577 },
    { lat: 48.554135, lng: 19.279196 },
    { lat: 48.554157, lng: 19.279091 },
    { lat: 48.554169, lng: 19.279012 },
    { lat: 48.554183, lng: 19.278934 },
    { lat: 48.554194, lng: 19.278878 },
    { lat: 48.554303, lng: 19.278103 },
    { lat: 48.554559, lng: 19.277971 },
    { lat: 48.554566, lng: 19.277927 },
    { lat: 48.55457, lng: 19.2779 },
    { lat: 48.554576, lng: 19.27787 },
    { lat: 48.554579, lng: 19.27787 },
    { lat: 48.554782, lng: 19.277898 },
    { lat: 48.554786, lng: 19.2779 },
    { lat: 48.554775, lng: 19.277945 },
    { lat: 48.554769, lng: 19.27797 },
    { lat: 48.554758, lng: 19.278016 },
    { lat: 48.554873, lng: 19.278108 },
    { lat: 48.554961, lng: 19.278178 },
    { lat: 48.554826, lng: 19.279242 },
    { lat: 48.554842, lng: 19.279488 },
    { lat: 48.55491, lng: 19.279729 },
    { lat: 48.554984, lng: 19.279965 },
    { lat: 48.555109, lng: 19.280261 },
    { lat: 48.556718, lng: 19.284081 },
    { lat: 48.556659, lng: 19.284383 },
    { lat: 48.556673, lng: 19.284453 },
    { lat: 48.556697, lng: 19.284622 },
    { lat: 48.556706, lng: 19.284689 },
    { lat: 48.55684, lng: 19.285524 },
    { lat: 48.557172, lng: 19.286319 },
    { lat: 48.557266, lng: 19.286518 },
    { lat: 48.5574, lng: 19.286671 },
    { lat: 48.557405, lng: 19.286674 },
    { lat: 48.557579, lng: 19.286771 },
    { lat: 48.558545, lng: 19.286076 },
    { lat: 48.558582, lng: 19.286049 },
    { lat: 48.558633, lng: 19.286013 },
    { lat: 48.558608, lng: 19.286056 },
    { lat: 48.55859, lng: 19.286086 },
    { lat: 48.558467, lng: 19.286294 },
    { lat: 48.558354, lng: 19.286548 },
    { lat: 48.558735, lng: 19.286309 },
    { lat: 48.558894, lng: 19.286158 },
    { lat: 48.558923, lng: 19.286131 },
    { lat: 48.558992, lng: 19.286065 },
    { lat: 48.559056, lng: 19.286006 },
    { lat: 48.559145, lng: 19.285921 },
    { lat: 48.559173, lng: 19.285894 },
    { lat: 48.559236, lng: 19.285901 },
    { lat: 48.559314, lng: 19.28591 },
    { lat: 48.559383, lng: 19.285919 },
    { lat: 48.559413, lng: 19.285922 },
    { lat: 48.559462, lng: 19.285927 },
    { lat: 48.559533, lng: 19.285936 },
    { lat: 48.559686, lng: 19.285953 },
    { lat: 48.55977, lng: 19.285861 },
    { lat: 48.559785, lng: 19.285846 },
    { lat: 48.559792, lng: 19.285838 },
    { lat: 48.559866, lng: 19.285759 },
    { lat: 48.560282, lng: 19.285209 },
    { lat: 48.56032, lng: 19.28516 },
    { lat: 48.560445, lng: 19.285105 },
    { lat: 48.561877, lng: 19.284474 },
    { lat: 48.562816, lng: 19.284182 },
    { lat: 48.563356, lng: 19.284474 },
    { lat: 48.563613, lng: 19.284427 },
    { lat: 48.56408, lng: 19.284745 },
    { lat: 48.564593, lng: 19.285373 },
    { lat: 48.564689, lng: 19.285492 },
    { lat: 48.564506, lng: 19.285912 },
    { lat: 48.564535, lng: 19.286206 },
    { lat: 48.564821, lng: 19.286785 },
    { lat: 48.564953, lng: 19.287618 },
    { lat: 48.56509, lng: 19.287789 },
    { lat: 48.565146, lng: 19.287883 },
    { lat: 48.56507, lng: 19.288605 },
    { lat: 48.56483, lng: 19.288619 },
    { lat: 48.565182, lng: 19.289723 },
    { lat: 48.565368, lng: 19.289843 },
    { lat: 48.565496, lng: 19.29062 },
    { lat: 48.565527, lng: 19.290617 },
    { lat: 48.565552, lng: 19.290613 },
    { lat: 48.565986, lng: 19.290721 },
    { lat: 48.565841, lng: 19.291212 },
    { lat: 48.566174, lng: 19.291429 },
    { lat: 48.566344, lng: 19.292058 },
    { lat: 48.566356, lng: 19.292103 },
    { lat: 48.566363, lng: 19.292131 },
    { lat: 48.567737, lng: 19.292497 },
    { lat: 48.569286, lng: 19.292937 },
    { lat: 48.569898, lng: 19.293148 },
    { lat: 48.571784, lng: 19.293768 },
    { lat: 48.572785, lng: 19.29407 },
    { lat: 48.57447, lng: 19.294587 },
    { lat: 48.5747293, lng: 19.2924928 },
    { lat: 48.5748672, lng: 19.2917751 },
    { lat: 48.5752452, lng: 19.2905386 },
    { lat: 48.5787171, lng: 19.2827515 },
    { lat: 48.5787503, lng: 19.2825275 },
    { lat: 48.5787108, lng: 19.2824582 },
    { lat: 48.5787517, lng: 19.2824159 },
    { lat: 48.5787153, lng: 19.2823843 },
    { lat: 48.5809853, lng: 19.2721723 },
    { lat: 48.5833441, lng: 19.2619679 },
    { lat: 48.583886, lng: 19.2534881 },
    { lat: 48.5844236, lng: 19.2479694 },
    { lat: 48.5895864, lng: 19.2473248 },
    { lat: 48.5894769, lng: 19.2466725 },
    { lat: 48.5895478, lng: 19.2457382 },
    { lat: 48.5890999, lng: 19.2434982 },
    { lat: 48.5888037, lng: 19.2430648 },
    { lat: 48.5885244, lng: 19.2423279 },
    { lat: 48.5880922, lng: 19.2414809 },
    { lat: 48.5873222, lng: 19.2408498 },
    { lat: 48.5862355, lng: 19.2389442 },
    { lat: 48.5862644, lng: 19.2381916 },
    { lat: 48.5862155, lng: 19.2344971 },
    { lat: 48.5861208, lng: 19.2344046 },
    { lat: 48.5861172, lng: 19.2343983 },
    { lat: 48.5859062, lng: 19.2341841 },
    { lat: 48.5844292, lng: 19.2341065 },
    { lat: 48.5830355, lng: 19.2333302 },
    { lat: 48.5821538, lng: 19.2335607 },
    { lat: 48.5816354, lng: 19.2342338 },
    { lat: 48.5804229, lng: 19.232225 },
    { lat: 48.5781977, lng: 19.2301822 },
    { lat: 48.5770487, lng: 19.2284182 },
    { lat: 48.575692, lng: 19.2268272 },
    { lat: 48.5742595, lng: 19.2260935 },
    { lat: 48.5741308, lng: 19.2261074 },
    { lat: 48.5738396, lng: 19.2256616 },
    { lat: 48.5736051, lng: 19.2254056 },
    { lat: 48.5733632, lng: 19.2252319 },
    { lat: 48.5729553, lng: 19.2247947 },
    { lat: 48.5725051, lng: 19.2245523 },
    { lat: 48.5722988, lng: 19.2243921 },
    { lat: 48.5717111, lng: 19.2239457 },
    { lat: 48.5716316, lng: 19.224184 },
    { lat: 48.5713016, lng: 19.2243537 },
    { lat: 48.5710778, lng: 19.2246671 },
    { lat: 48.5709918, lng: 19.2246577 },
    { lat: 48.5709369, lng: 19.2247321 },
    { lat: 48.570183, lng: 19.2248069 },
    { lat: 48.5700234, lng: 19.2249581 },
    { lat: 48.5696609, lng: 19.2250569 },
    { lat: 48.5694143, lng: 19.2249275 },
    { lat: 48.5690858, lng: 19.2250059 },
    { lat: 48.5684539, lng: 19.2252722 },
    { lat: 48.5681177, lng: 19.2253371 },
    { lat: 48.5679761, lng: 19.2255055 },
    { lat: 48.5677885, lng: 19.225476 },
    { lat: 48.5666391, lng: 19.2256458 },
    { lat: 48.5663063, lng: 19.2217275 },
    { lat: 48.5665782, lng: 19.2206233 },
    { lat: 48.5674712, lng: 19.2188222 },
    { lat: 48.5676093, lng: 19.2183709 },
    { lat: 48.5676927, lng: 19.2173531 },
    { lat: 48.5675749, lng: 19.2129973 },
    { lat: 48.5676413, lng: 19.2124643 },
    { lat: 48.5678797, lng: 19.211368 },
    { lat: 48.5671938, lng: 19.2109191 },
    { lat: 48.5656325, lng: 19.2102725 },
    { lat: 48.5638023, lng: 19.2093814 },
    { lat: 48.5633332, lng: 19.2089619 },
    { lat: 48.5624112, lng: 19.2082922 },
    { lat: 48.5600751, lng: 19.2061133 },
    { lat: 48.5595536, lng: 19.205916 },
    { lat: 48.5591641, lng: 19.2054425 },
    { lat: 48.5579507, lng: 19.2044619 },
    { lat: 48.5575193, lng: 19.2039155 },
    { lat: 48.5565135, lng: 19.2038862 },
    { lat: 48.5549968, lng: 19.2037374 },
    { lat: 48.5543245, lng: 19.2041422 },
    { lat: 48.553299, lng: 19.2044058 },
    { lat: 48.5530696, lng: 19.2043462 },
    { lat: 48.5524714, lng: 19.2039636 },
    { lat: 48.5519416, lng: 19.2043785 },
    { lat: 48.5516749, lng: 19.2043896 },
    { lat: 48.5510259, lng: 19.2047156 },
    { lat: 48.5506266, lng: 19.2048247 },
    { lat: 48.550065, lng: 19.2044298 },
    { lat: 48.5487914, lng: 19.2043636 },
    { lat: 48.5483859, lng: 19.2063519 },
    { lat: 48.5472707, lng: 19.2058135 },
    { lat: 48.5461433, lng: 19.2049118 },
    { lat: 48.5459509, lng: 19.2044838 },
    { lat: 48.5458252, lng: 19.2035265 },
    { lat: 48.5453311, lng: 19.2035436 },
    { lat: 48.5450514, lng: 19.2022911 },
    { lat: 48.5447425, lng: 19.2021521 },
    { lat: 48.5443905, lng: 19.2024957 },
    { lat: 48.5446743, lng: 19.2026282 },
    { lat: 48.54396, lng: 19.2035363 },
    { lat: 48.5435928, lng: 19.2033551 },
    { lat: 48.5431186, lng: 19.2032273 },
    { lat: 48.542006, lng: 19.2016564 },
    { lat: 48.5410981, lng: 19.2010941 },
    { lat: 48.5404549, lng: 19.2008286 },
    { lat: 48.5386838, lng: 19.1999278 },
    { lat: 48.5379778, lng: 19.1991365 },
    { lat: 48.5375566, lng: 19.1997452 },
    { lat: 48.5374323, lng: 19.2000134 },
    { lat: 48.5372258, lng: 19.2007697 },
    { lat: 48.5370072, lng: 19.2018796 },
    { lat: 48.5359728, lng: 19.204974 },
    { lat: 48.5357026, lng: 19.2063616 },
    { lat: 48.5344531, lng: 19.209305 },
    { lat: 48.5339158, lng: 19.2100037 },
    { lat: 48.5335614, lng: 19.210872 },
    { lat: 48.5332939, lng: 19.2113971 },
    { lat: 48.5328832, lng: 19.2120135 },
    { lat: 48.5324961, lng: 19.2133189 },
    { lat: 48.5319798, lng: 19.2126723 },
    { lat: 48.531759, lng: 19.2121671 },
    { lat: 48.531389, lng: 19.2120249 },
    { lat: 48.5307996, lng: 19.2116794 },
    { lat: 48.5305302, lng: 19.2117267 },
    { lat: 48.5303159, lng: 19.21186 },
    { lat: 48.5298568, lng: 19.2118756 },
    { lat: 48.5294978, lng: 19.2121191 },
    { lat: 48.529165, lng: 19.2120909 },
    { lat: 48.5290121, lng: 19.2120072 },
    { lat: 48.5286539, lng: 19.2121301 },
    { lat: 48.5283491, lng: 19.2121429 },
    { lat: 48.5278426, lng: 19.2119063 },
    { lat: 48.5263744, lng: 19.2118263 },
    { lat: 48.5254781, lng: 19.2110842 },
    { lat: 48.5251939, lng: 19.2109919 },
    { lat: 48.5249302, lng: 19.2106217 },
    { lat: 48.524081, lng: 19.2100608 },
    { lat: 48.523598, lng: 19.2105783 },
    { lat: 48.5226597, lng: 19.2118162 },
    { lat: 48.5222011, lng: 19.2121896 },
    { lat: 48.5220071, lng: 19.2124639 },
    { lat: 48.5218246, lng: 19.2128343 },
    { lat: 48.5213468, lng: 19.2141069 },
    { lat: 48.5209234, lng: 19.2150185 },
    { lat: 48.5201049, lng: 19.2154298 },
    { lat: 48.5191066, lng: 19.2157383 },
    { lat: 48.5185288, lng: 19.2176441 },
    { lat: 48.518185, lng: 19.2182425 },
    { lat: 48.5178418, lng: 19.2195974 },
    { lat: 48.5174694, lng: 19.220258 },
    { lat: 48.5168544, lng: 19.2210751 },
    { lat: 48.5158958, lng: 19.2225695 },
    { lat: 48.5141323, lng: 19.223806 },
    { lat: 48.513227, lng: 19.2262785 },
    { lat: 48.5127609, lng: 19.227378 },
    { lat: 48.512524, lng: 19.2281163 },
    { lat: 48.5119425, lng: 19.229295 },
    { lat: 48.5114177, lng: 19.2309488 },
    { lat: 48.5110062, lng: 19.2316181 },
    { lat: 48.5107269, lng: 19.2317865 },
    { lat: 48.5100837, lng: 19.2320288 },
    { lat: 48.5082514, lng: 19.2322469 },
    { lat: 48.5075541, lng: 19.2326047 },
    { lat: 48.5067022, lng: 19.2333978 },
    { lat: 48.5059649, lng: 19.2349245 },
    { lat: 48.5051225, lng: 19.235981 },
    { lat: 48.5045354, lng: 19.2365672 },
    { lat: 48.5042022, lng: 19.2371993 },
    { lat: 48.5034545, lng: 19.2394851 },
    { lat: 48.5027959, lng: 19.2412912 },
    { lat: 48.50204, lng: 19.241556 },
  ],
  Babiná: [
    { lat: 48.41928, lng: 19.105304 },
    { lat: 48.4193495, lng: 19.105387 },
    { lat: 48.419871, lng: 19.1054706 },
    { lat: 48.4205698, lng: 19.1058257 },
    { lat: 48.4209628, lng: 19.1058176 },
    { lat: 48.4221033, lng: 19.1063288 },
    { lat: 48.4231214, lng: 19.1064441 },
    { lat: 48.4237038, lng: 19.1067967 },
    { lat: 48.4254153, lng: 19.107316 },
    { lat: 48.4265946, lng: 19.1080415 },
    { lat: 48.4277336, lng: 19.1094518 },
    { lat: 48.4283126, lng: 19.1099205 },
    { lat: 48.4291442, lng: 19.1101822 },
    { lat: 48.429518, lng: 19.1101966 },
    { lat: 48.4300848, lng: 19.1100561 },
    { lat: 48.4309807, lng: 19.1096415 },
    { lat: 48.4320269, lng: 19.1095479 },
    { lat: 48.4324133, lng: 19.109745 },
    { lat: 48.4335307, lng: 19.1103148 },
    { lat: 48.434141, lng: 19.1104151 },
    { lat: 48.4346777, lng: 19.1106426 },
    { lat: 48.4355125, lng: 19.1107645 },
    { lat: 48.4388649, lng: 19.1108253 },
    { lat: 48.4396499, lng: 19.1109476 },
    { lat: 48.440214, lng: 19.110572 },
    { lat: 48.4405298, lng: 19.1106664 },
    { lat: 48.4411948, lng: 19.11116 },
    { lat: 48.4423855, lng: 19.1117958 },
    { lat: 48.4431898, lng: 19.1121221 },
    { lat: 48.4440398, lng: 19.1128243 },
    { lat: 48.4441504, lng: 19.112759 },
    { lat: 48.444352, lng: 19.1128691 },
    { lat: 48.444608, lng: 19.1125776 },
    { lat: 48.4447818, lng: 19.1125115 },
    { lat: 48.4447811, lng: 19.1124072 },
    { lat: 48.4448842, lng: 19.1121896 },
    { lat: 48.44511, lng: 19.111971 },
    { lat: 48.445176, lng: 19.1119625 },
    { lat: 48.4452934, lng: 19.1121013 },
    { lat: 48.4453823, lng: 19.1124162 },
    { lat: 48.4454646, lng: 19.1124354 },
    { lat: 48.4456587, lng: 19.1122168 },
    { lat: 48.445677, lng: 19.1121284 },
    { lat: 48.4458423, lng: 19.1123027 },
    { lat: 48.4458844, lng: 19.1122425 },
    { lat: 48.4459612, lng: 19.1122651 },
    { lat: 48.4460782, lng: 19.1120826 },
    { lat: 48.4461196, lng: 19.1119359 },
    { lat: 48.4463617, lng: 19.111887 },
    { lat: 48.4463543, lng: 19.1117167 },
    { lat: 48.4464461, lng: 19.1115661 },
    { lat: 48.446632, lng: 19.1114766 },
    { lat: 48.446661, lng: 19.1112844 },
    { lat: 48.4466099, lng: 19.1111893 },
    { lat: 48.446667, lng: 19.1110686 },
    { lat: 48.4466521, lng: 19.1107705 },
    { lat: 48.4465747, lng: 19.1106631 },
    { lat: 48.4464613, lng: 19.1106297 },
    { lat: 48.4464133, lng: 19.1107761 },
    { lat: 48.4462939, lng: 19.1108453 },
    { lat: 48.4462318, lng: 19.110978 },
    { lat: 48.4460988, lng: 19.1107508 },
    { lat: 48.4460672, lng: 19.1105246 },
    { lat: 48.4457809, lng: 19.1103019 },
    { lat: 48.4457727, lng: 19.1101803 },
    { lat: 48.4458515, lng: 19.1100212 },
    { lat: 48.4458398, lng: 19.1098961 },
    { lat: 48.445803, lng: 19.1098147 },
    { lat: 48.445707, lng: 19.1098148 },
    { lat: 48.4456996, lng: 19.1097077 },
    { lat: 48.4458733, lng: 19.1094637 },
    { lat: 48.4459564, lng: 19.1094991 },
    { lat: 48.4460152, lng: 19.109391 },
    { lat: 48.4460952, lng: 19.1093876 },
    { lat: 48.4461728, lng: 19.1092962 },
    { lat: 48.4461211, lng: 19.1090502 },
    { lat: 48.4459659, lng: 19.1088323 },
    { lat: 48.4457766, lng: 19.1087396 },
    { lat: 48.445739, lng: 19.1086217 },
    { lat: 48.4461543, lng: 19.1080849 },
    { lat: 48.4462749, lng: 19.1081306 },
    { lat: 48.4463454, lng: 19.1082324 },
    { lat: 48.4465029, lng: 19.1081362 },
    { lat: 48.4465494, lng: 19.1080731 },
    { lat: 48.4464691, lng: 19.1076858 },
    { lat: 48.4465673, lng: 19.107598 },
    { lat: 48.4467749, lng: 19.1075544 },
    { lat: 48.446802, lng: 19.1078273 },
    { lat: 48.4468704, lng: 19.1079545 },
    { lat: 48.4470014, lng: 19.1077807 },
    { lat: 48.4472551, lng: 19.1076424 },
    { lat: 48.4473534, lng: 19.1078057 },
    { lat: 48.4475029, lng: 19.107824 },
    { lat: 48.4476385, lng: 19.1077135 },
    { lat: 48.4477779, lng: 19.1075008 },
    { lat: 48.4478337, lng: 19.1070854 },
    { lat: 48.4480055, lng: 19.106821 },
    { lat: 48.4481306, lng: 19.1067515 },
    { lat: 48.4481201, lng: 19.1064086 },
    { lat: 48.4484825, lng: 19.106034 },
    { lat: 48.4484723, lng: 19.1059385 },
    { lat: 48.4485189, lng: 19.1059027 },
    { lat: 48.449382, lng: 19.1055772 },
    { lat: 48.4495718, lng: 19.1053143 },
    { lat: 48.4495604, lng: 19.1049949 },
    { lat: 48.4496282, lng: 19.1048623 },
    { lat: 48.4503245, lng: 19.1046765 },
    { lat: 48.4510001, lng: 19.1043866 },
    { lat: 48.4511051, lng: 19.1045012 },
    { lat: 48.4512499, lng: 19.1045108 },
    { lat: 48.4514718, lng: 19.1043693 },
    { lat: 48.451557, lng: 19.1040707 },
    { lat: 48.4516768, lng: 19.1038945 },
    { lat: 48.4518676, lng: 19.1039231 },
    { lat: 48.4520043, lng: 19.103451 },
    { lat: 48.4520017, lng: 19.1031963 },
    { lat: 48.4520624, lng: 19.1029675 },
    { lat: 48.4524274, lng: 19.1024433 },
    { lat: 48.452648, lng: 19.1013718 },
    { lat: 48.45255, lng: 19.1013058 },
    { lat: 48.4518336, lng: 19.1001671 },
    { lat: 48.4509823, lng: 19.0984703 },
    { lat: 48.4508136, lng: 19.0979354 },
    { lat: 48.4506587, lng: 19.0976993 },
    { lat: 48.4501302, lng: 19.0963515 },
    { lat: 48.449948, lng: 19.0953891 },
    { lat: 48.4500147, lng: 19.0948514 },
    { lat: 48.4500185, lng: 19.0944084 },
    { lat: 48.4498298, lng: 19.0934301 },
    { lat: 48.4498264, lng: 19.0930032 },
    { lat: 48.4498894, lng: 19.0925717 },
    { lat: 48.4497791, lng: 19.0916918 },
    { lat: 48.4496937, lng: 19.0912692 },
    { lat: 48.4495391, lng: 19.0908367 },
    { lat: 48.4494991, lng: 19.0905149 },
    { lat: 48.449474, lng: 19.0889495 },
    { lat: 48.4493449, lng: 19.0873177 },
    { lat: 48.4496667, lng: 19.0862656 },
    { lat: 48.4498892, lng: 19.0856464 },
    { lat: 48.4500476, lng: 19.0853401 },
    { lat: 48.4503423, lng: 19.0844459 },
    { lat: 48.450603, lng: 19.0834157 },
    { lat: 48.4507967, lng: 19.082084 },
    { lat: 48.450721, lng: 19.081024 },
    { lat: 48.4505699, lng: 19.0803233 },
    { lat: 48.4504961, lng: 19.0801562 },
    { lat: 48.4504877, lng: 19.0795364 },
    { lat: 48.4499777, lng: 19.0782677 },
    { lat: 48.4502038, lng: 19.0771316 },
    { lat: 48.4502109, lng: 19.0769152 },
    { lat: 48.4505081, lng: 19.0755999 },
    { lat: 48.4504579, lng: 19.0751187 },
    { lat: 48.4505162, lng: 19.0736272 },
    { lat: 48.4504915, lng: 19.0734269 },
    { lat: 48.4506174, lng: 19.0730177 },
    { lat: 48.4506785, lng: 19.0725917 },
    { lat: 48.4507515, lng: 19.0703631 },
    { lat: 48.4508402, lng: 19.0698622 },
    { lat: 48.4511124, lng: 19.0688114 },
    { lat: 48.4513842, lng: 19.0684812 },
    { lat: 48.4523013, lng: 19.0671199 },
    { lat: 48.453102, lng: 19.0653041 },
    { lat: 48.4537749, lng: 19.0643785 },
    { lat: 48.4539947, lng: 19.0637079 },
    { lat: 48.4543617, lng: 19.0608227 },
    { lat: 48.4547473, lng: 19.0601506 },
    { lat: 48.4551001, lng: 19.0597248 },
    { lat: 48.4552923, lng: 19.0592864 },
    { lat: 48.4554292, lng: 19.0587565 },
    { lat: 48.4555202, lng: 19.0576725 },
    { lat: 48.4556664, lng: 19.0569704 },
    { lat: 48.4556391, lng: 19.056494 },
    { lat: 48.4557219, lng: 19.0561244 },
    { lat: 48.4557677, lng: 19.0553072 },
    { lat: 48.4562513, lng: 19.0540539 },
    { lat: 48.456446, lng: 19.0533068 },
    { lat: 48.4565206, lng: 19.0522544 },
    { lat: 48.4564453, lng: 19.0513871 },
    { lat: 48.4560712, lng: 19.0501826 },
    { lat: 48.4556287, lng: 19.0495879 },
    { lat: 48.4550392, lng: 19.0484925 },
    { lat: 48.4544521, lng: 19.0471553 },
    { lat: 48.4542923, lng: 19.0466702 },
    { lat: 48.4541722, lng: 19.046079 },
    { lat: 48.4540019, lng: 19.0441527 },
    { lat: 48.4541415, lng: 19.0426472 },
    { lat: 48.4540647, lng: 19.0411927 },
    { lat: 48.454105, lng: 19.0410087 },
    { lat: 48.4540636, lng: 19.040254 },
    { lat: 48.4539941, lng: 19.0397697 },
    { lat: 48.4536165, lng: 19.0384112 },
    { lat: 48.4538352, lng: 19.0362186 },
    { lat: 48.4538385, lng: 19.0340922 },
    { lat: 48.4537657, lng: 19.0334125 },
    { lat: 48.4536964, lng: 19.0330237 },
    { lat: 48.4535494, lng: 19.0325873 },
    { lat: 48.4532991, lng: 19.0320095 },
    { lat: 48.4528048, lng: 19.031172 },
    { lat: 48.4523657, lng: 19.0296015 },
    { lat: 48.4522783, lng: 19.028606 },
    { lat: 48.4516067, lng: 19.0272283 },
    { lat: 48.4508121, lng: 19.0260785 },
    { lat: 48.4506272, lng: 19.0261206 },
    { lat: 48.4505238, lng: 19.0259261 },
    { lat: 48.4503497, lng: 19.0259839 },
    { lat: 48.4502695, lng: 19.0258699 },
    { lat: 48.4498734, lng: 19.0257486 },
    { lat: 48.4497453, lng: 19.0258704 },
    { lat: 48.4490392, lng: 19.0260218 },
    { lat: 48.4490213, lng: 19.0261815 },
    { lat: 48.4487609, lng: 19.0262031 },
    { lat: 48.4484338, lng: 19.0266681 },
    { lat: 48.4481929, lng: 19.0264086 },
    { lat: 48.4480069, lng: 19.0264309 },
    { lat: 48.4478636, lng: 19.0260485 },
    { lat: 48.4476814, lng: 19.0260803 },
    { lat: 48.4475663, lng: 19.0259905 },
    { lat: 48.4475532, lng: 19.0258211 },
    { lat: 48.4474827, lng: 19.0257166 },
    { lat: 48.4473532, lng: 19.0257431 },
    { lat: 48.4472308, lng: 19.0256868 },
    { lat: 48.4469609, lng: 19.0252315 },
    { lat: 48.4470294, lng: 19.0250038 },
    { lat: 48.4468487, lng: 19.0247744 },
    { lat: 48.4467001, lng: 19.0244018 },
    { lat: 48.4463768, lng: 19.0242268 },
    { lat: 48.4462269, lng: 19.0239392 },
    { lat: 48.4461617, lng: 19.0234957 },
    { lat: 48.4459632, lng: 19.0232932 },
    { lat: 48.4456204, lng: 19.0224492 },
    { lat: 48.4454388, lng: 19.0223108 },
    { lat: 48.4453331, lng: 19.0216677 },
    { lat: 48.4452717, lng: 19.0215941 },
    { lat: 48.4450339, lng: 19.0215269 },
    { lat: 48.4448335, lng: 19.0212682 },
    { lat: 48.4446244, lng: 19.021302 },
    { lat: 48.4444493, lng: 19.0212066 },
    { lat: 48.4440568, lng: 19.0207572 },
    { lat: 48.4437971, lng: 19.0206583 },
    { lat: 48.4434719, lng: 19.0204033 },
    { lat: 48.4434013, lng: 19.0202359 },
    { lat: 48.4433891, lng: 19.0199255 },
    { lat: 48.4432264, lng: 19.0197215 },
    { lat: 48.4428841, lng: 19.018995 },
    { lat: 48.442733, lng: 19.0181941 },
    { lat: 48.4427865, lng: 19.0177342 },
    { lat: 48.442735, lng: 19.0175199 },
    { lat: 48.4428754, lng: 19.0171988 },
    { lat: 48.4428425, lng: 19.0170162 },
    { lat: 48.4429408, lng: 19.01655 },
    { lat: 48.4428964, lng: 19.0161449 },
    { lat: 48.4429128, lng: 19.0158657 },
    { lat: 48.4428508, lng: 19.015734 },
    { lat: 48.4427903, lng: 19.0151841 },
    { lat: 48.4426873, lng: 19.0147307 },
    { lat: 48.4425303, lng: 19.0146343 },
    { lat: 48.4423935, lng: 19.0142288 },
    { lat: 48.4424437, lng: 19.0141059 },
    { lat: 48.4424346, lng: 19.0138144 },
    { lat: 48.4423172, lng: 19.0137681 },
    { lat: 48.4422813, lng: 19.0136384 },
    { lat: 48.4423085, lng: 19.0131481 },
    { lat: 48.4423713, lng: 19.0129434 },
    { lat: 48.4422324, lng: 19.0126078 },
    { lat: 48.442098, lng: 19.0124918 },
    { lat: 48.4419166, lng: 19.0118027 },
    { lat: 48.4418898, lng: 19.0113204 },
    { lat: 48.4419356, lng: 19.010976 },
    { lat: 48.4417491, lng: 19.0106341 },
    { lat: 48.4417394, lng: 19.0104875 },
    { lat: 48.441815, lng: 19.0102423 },
    { lat: 48.4417921, lng: 19.0100131 },
    { lat: 48.4415918, lng: 19.0098879 },
    { lat: 48.441576, lng: 19.009759 },
    { lat: 48.440708, lng: 19.010229 },
    { lat: 48.439791, lng: 19.01067 },
    { lat: 48.439243, lng: 19.009764 },
    { lat: 48.438898, lng: 19.009843 },
    { lat: 48.437977, lng: 19.010025 },
    { lat: 48.437337, lng: 19.010187 },
    { lat: 48.436505, lng: 19.010721 },
    { lat: 48.43614, lng: 19.011009 },
    { lat: 48.435711, lng: 19.011437 },
    { lat: 48.435415, lng: 19.011524 },
    { lat: 48.435204, lng: 19.011561 },
    { lat: 48.434646, lng: 19.01143 },
    { lat: 48.43417, lng: 19.011059 },
    { lat: 48.433135, lng: 19.01007 },
    { lat: 48.432502, lng: 19.009571 },
    { lat: 48.432362, lng: 19.009516 },
    { lat: 48.431296, lng: 19.008169 },
    { lat: 48.43049, lng: 19.007324 },
    { lat: 48.430126, lng: 19.007073 },
    { lat: 48.42966, lng: 19.006375 },
    { lat: 48.429483, lng: 19.006305 },
    { lat: 48.4291, lng: 19.006057 },
    { lat: 48.428463, lng: 19.00547 },
    { lat: 48.428132, lng: 19.004996 },
    { lat: 48.428003, lng: 19.004751 },
    { lat: 48.427657, lng: 19.003763 },
    { lat: 48.427229, lng: 19.003018 },
    { lat: 48.426939, lng: 19.002235 },
    { lat: 48.426816, lng: 19.001734 },
    { lat: 48.426461, lng: 19.000652 },
    { lat: 48.425904, lng: 18.999986 },
    { lat: 48.425464, lng: 18.999205 },
    { lat: 48.425263, lng: 18.998706 },
    { lat: 48.425231, lng: 18.998645 },
    { lat: 48.425003, lng: 18.998126 },
    { lat: 48.423888, lng: 18.996512 },
    { lat: 48.423815, lng: 18.994823 },
    { lat: 48.424166, lng: 18.994302 },
    { lat: 48.423848, lng: 18.994574 },
    { lat: 48.423784, lng: 18.994629 },
    { lat: 48.423684, lng: 18.994704 },
    { lat: 48.423512, lng: 18.99483 },
    { lat: 48.423494, lng: 18.994844 },
    { lat: 48.423254, lng: 18.99512 },
    { lat: 48.42312, lng: 18.995278 },
    { lat: 48.422961, lng: 18.995388 },
    { lat: 48.422943, lng: 18.995402 },
    { lat: 48.422841, lng: 18.995518 },
    { lat: 48.422871, lng: 18.995942 },
    { lat: 48.422875, lng: 18.995997 },
    { lat: 48.42291, lng: 18.996454 },
    { lat: 48.423372, lng: 18.997183 },
    { lat: 48.423485, lng: 18.997691 },
    { lat: 48.423999, lng: 18.998262 },
    { lat: 48.42418, lng: 18.998622 },
    { lat: 48.424665, lng: 18.999878 },
    { lat: 48.424739, lng: 19.00007 },
    { lat: 48.424817, lng: 19.000582 },
    { lat: 48.424963, lng: 19.001055 },
    { lat: 48.424978, lng: 19.001106 },
    { lat: 48.425106, lng: 19.00152 },
    { lat: 48.425731, lng: 19.003037 },
    { lat: 48.425783, lng: 19.003139 },
    { lat: 48.425806, lng: 19.003184 },
    { lat: 48.426824, lng: 19.005188 },
    { lat: 48.427311, lng: 19.006246 },
    { lat: 48.427333, lng: 19.006294 },
    { lat: 48.427567, lng: 19.006801 },
    { lat: 48.428712, lng: 19.008636 },
    { lat: 48.428717, lng: 19.008644 },
    { lat: 48.428741, lng: 19.008687 },
    { lat: 48.429003, lng: 19.009147 },
    { lat: 48.429635, lng: 19.010293 },
    { lat: 48.430174, lng: 19.011293 },
    { lat: 48.430205, lng: 19.011351 },
    { lat: 48.430562, lng: 19.012013 },
    { lat: 48.431756, lng: 19.012803 },
    { lat: 48.432905, lng: 19.013184 },
    { lat: 48.434048, lng: 19.014078 },
    { lat: 48.434514, lng: 19.014684 },
    { lat: 48.434552, lng: 19.014732 },
    { lat: 48.434721, lng: 19.014951 },
    { lat: 48.435312, lng: 19.015719 },
    { lat: 48.435558, lng: 19.015998 },
    { lat: 48.435761, lng: 19.016343 },
    { lat: 48.435928, lng: 19.016643 },
    { lat: 48.43619, lng: 19.017402 },
    { lat: 48.436751, lng: 19.018692 },
    { lat: 48.436926, lng: 19.019093 },
    { lat: 48.437368, lng: 19.020622 },
    { lat: 48.43781, lng: 19.021931 },
    { lat: 48.437878, lng: 19.022194 },
    { lat: 48.437977, lng: 19.022587 },
    { lat: 48.437991, lng: 19.022642 },
    { lat: 48.438187, lng: 19.02342 },
    { lat: 48.4384, lng: 19.024112 },
    { lat: 48.438537, lng: 19.025334 },
    { lat: 48.438674, lng: 19.026411 },
    { lat: 48.438692, lng: 19.026813 },
    { lat: 48.438689, lng: 19.028239 },
    { lat: 48.438708, lng: 19.028828 },
    { lat: 48.438752, lng: 19.029339 },
    { lat: 48.438796, lng: 19.029864 },
    { lat: 48.438845, lng: 19.030466 },
    { lat: 48.438977, lng: 19.031043 },
    { lat: 48.438963, lng: 19.031509 },
    { lat: 48.438936, lng: 19.032669 },
    { lat: 48.439013, lng: 19.032858 },
    { lat: 48.439328, lng: 19.033429 },
    { lat: 48.439437, lng: 19.033743 },
    { lat: 48.439799, lng: 19.034905 },
    { lat: 48.439913, lng: 19.035247 },
    { lat: 48.439974, lng: 19.035267 },
    { lat: 48.440013, lng: 19.035281 },
    { lat: 48.440033, lng: 19.035288 },
    { lat: 48.440126, lng: 19.035555 },
    { lat: 48.440725, lng: 19.037006 },
    { lat: 48.440509, lng: 19.037263 },
    { lat: 48.440472, lng: 19.037306 },
    { lat: 48.440437, lng: 19.037348 },
    { lat: 48.440401, lng: 19.037391 },
    { lat: 48.440238, lng: 19.03753 },
    { lat: 48.439476, lng: 19.038175 },
    { lat: 48.439107, lng: 19.03858 },
    { lat: 48.437536, lng: 19.040291 },
    { lat: 48.43705, lng: 19.040575 },
    { lat: 48.436909, lng: 19.040613 },
    { lat: 48.436156, lng: 19.040982 },
    { lat: 48.435986, lng: 19.041096 },
    { lat: 48.435948, lng: 19.041121 },
    { lat: 48.435406, lng: 19.041483 },
    { lat: 48.434539, lng: 19.042156 },
    { lat: 48.433699, lng: 19.042789 },
    { lat: 48.433104, lng: 19.043435 },
    { lat: 48.432291, lng: 19.04366 },
    { lat: 48.431978, lng: 19.043752 },
    { lat: 48.431939, lng: 19.043764 },
    { lat: 48.431784, lng: 19.043809 },
    { lat: 48.430279, lng: 19.044479 },
    { lat: 48.429477, lng: 19.044838 },
    { lat: 48.42813, lng: 19.045523 },
    { lat: 48.427988, lng: 19.045595 },
    { lat: 48.427671, lng: 19.045709 },
    { lat: 48.427207, lng: 19.045836 },
    { lat: 48.427125, lng: 19.045835 },
    { lat: 48.426724, lng: 19.045898 },
    { lat: 48.425527, lng: 19.045938 },
    { lat: 48.425175, lng: 19.046199 },
    { lat: 48.425091, lng: 19.046248 },
    { lat: 48.424475, lng: 19.046807 },
    { lat: 48.424437, lng: 19.04684 },
    { lat: 48.423742, lng: 19.047471 },
    { lat: 48.42336, lng: 19.047593 },
    { lat: 48.422997, lng: 19.048084 },
    { lat: 48.422473, lng: 19.048827 },
    { lat: 48.422376, lng: 19.048982 },
    { lat: 48.421798, lng: 19.050141 },
    { lat: 48.421775, lng: 19.050209 },
    { lat: 48.421755, lng: 19.050267 },
    { lat: 48.42173, lng: 19.050345 },
    { lat: 48.421468, lng: 19.051416 },
    { lat: 48.421311, lng: 19.052041 },
    { lat: 48.420622, lng: 19.052771 },
    { lat: 48.420532, lng: 19.052977 },
    { lat: 48.420103, lng: 19.054011 },
    { lat: 48.419796, lng: 19.054638 },
    { lat: 48.419158, lng: 19.055491 },
    { lat: 48.419153, lng: 19.055631 },
    { lat: 48.419044, lng: 19.056027 },
    { lat: 48.418912, lng: 19.056605 },
    { lat: 48.418671, lng: 19.057452 },
    { lat: 48.418429, lng: 19.058317 },
    { lat: 48.418415, lng: 19.058368 },
    { lat: 48.418231, lng: 19.059023 },
    { lat: 48.418106, lng: 19.059447 },
    { lat: 48.417983, lng: 19.059876 },
    { lat: 48.417699, lng: 19.060972 },
    { lat: 48.417439, lng: 19.061484 },
    { lat: 48.417275, lng: 19.061808 },
    { lat: 48.417142, lng: 19.062026 },
    { lat: 48.416947, lng: 19.062441 },
    { lat: 48.416799, lng: 19.062939 },
    { lat: 48.416714, lng: 19.063347 },
    { lat: 48.416578, lng: 19.063863 },
    { lat: 48.416425, lng: 19.06413 },
    { lat: 48.416206, lng: 19.064759 },
    { lat: 48.416126, lng: 19.065055 },
    { lat: 48.416007, lng: 19.065351 },
    { lat: 48.415792, lng: 19.06576 },
    { lat: 48.415723, lng: 19.066202 },
    { lat: 48.41568, lng: 19.066517 },
    { lat: 48.415671, lng: 19.066574 },
    { lat: 48.415658, lng: 19.066673 },
    { lat: 48.415581, lng: 19.067109 },
    { lat: 48.415602, lng: 19.067485 },
    { lat: 48.414907, lng: 19.068153 },
    { lat: 48.414465, lng: 19.068708 },
    { lat: 48.414367, lng: 19.06913 },
    { lat: 48.414311, lng: 19.069135 },
    { lat: 48.414356, lng: 19.06934 },
    { lat: 48.414431, lng: 19.069604 },
    { lat: 48.414458, lng: 19.069801 },
    { lat: 48.414646, lng: 19.070194 },
    { lat: 48.414731, lng: 19.070796 },
    { lat: 48.414831, lng: 19.070988 },
    { lat: 48.414941, lng: 19.071113 },
    { lat: 48.415014, lng: 19.071359 },
    { lat: 48.415021, lng: 19.071679 },
    { lat: 48.415237, lng: 19.071918 },
    { lat: 48.415349, lng: 19.072143 },
    { lat: 48.415351, lng: 19.072294 },
    { lat: 48.415408, lng: 19.072741 },
    { lat: 48.41546, lng: 19.07289 },
    { lat: 48.415564, lng: 19.073028 },
    { lat: 48.41551, lng: 19.073244 },
    { lat: 48.415564, lng: 19.073346 },
    { lat: 48.415564, lng: 19.073559 },
    { lat: 48.415613, lng: 19.07403 },
    { lat: 48.415616, lng: 19.074139 },
    { lat: 48.415723, lng: 19.074211 },
    { lat: 48.415831, lng: 19.07426 },
    { lat: 48.415812, lng: 19.074292 },
    { lat: 48.415667, lng: 19.074564 },
    { lat: 48.415609, lng: 19.07466 },
    { lat: 48.415556, lng: 19.07475 },
    { lat: 48.415504, lng: 19.074877 },
    { lat: 48.415476, lng: 19.074944 },
    { lat: 48.415303, lng: 19.075158 },
    { lat: 48.41512, lng: 19.075407 },
    { lat: 48.415022, lng: 19.075569 },
    { lat: 48.414623, lng: 19.076168 },
    { lat: 48.414347, lng: 19.076505 },
    { lat: 48.414143, lng: 19.076849 },
    { lat: 48.41354, lng: 19.07772 },
    { lat: 48.413332, lng: 19.077861 },
    { lat: 48.413295, lng: 19.077886 },
    { lat: 48.412807, lng: 19.078217 },
    { lat: 48.412384, lng: 19.07959 },
    { lat: 48.412372, lng: 19.079633 },
    { lat: 48.412356, lng: 19.079682 },
    { lat: 48.412084, lng: 19.080343 },
    { lat: 48.411958, lng: 19.080792 },
    { lat: 48.411853, lng: 19.08127 },
    { lat: 48.411901, lng: 19.081571 },
    { lat: 48.41191, lng: 19.082066 },
    { lat: 48.411904, lng: 19.082332 },
    { lat: 48.411933, lng: 19.082673 },
    { lat: 48.41195, lng: 19.08309 },
    { lat: 48.411966, lng: 19.083349 },
    { lat: 48.411957, lng: 19.08363 },
    { lat: 48.411897, lng: 19.083955 },
    { lat: 48.411753, lng: 19.08486 },
    { lat: 48.411788, lng: 19.085322 },
    { lat: 48.411781, lng: 19.085349 },
    { lat: 48.411766, lng: 19.085406 },
    { lat: 48.411748, lng: 19.085474 },
    { lat: 48.411724, lng: 19.085578 },
    { lat: 48.411686, lng: 19.0857 },
    { lat: 48.411683, lng: 19.085709 },
    { lat: 48.411647, lng: 19.085773 },
    { lat: 48.411338, lng: 19.086315 },
    { lat: 48.411318, lng: 19.086501 },
    { lat: 48.411262, lng: 19.087577 },
    { lat: 48.411392, lng: 19.087938 },
    { lat: 48.411513, lng: 19.088196 },
    { lat: 48.411892, lng: 19.088604 },
    { lat: 48.412292, lng: 19.088865 },
    { lat: 48.412542, lng: 19.089071 },
    { lat: 48.413201, lng: 19.089514 },
    { lat: 48.41403, lng: 19.090479 },
    { lat: 48.414038, lng: 19.090783 },
    { lat: 48.414372, lng: 19.091223 },
    { lat: 48.415062, lng: 19.091614 },
    { lat: 48.415264, lng: 19.091617 },
    { lat: 48.415398, lng: 19.091438 },
    { lat: 48.415429, lng: 19.091435 },
    { lat: 48.415644, lng: 19.091066 },
    { lat: 48.41595, lng: 19.090972 },
    { lat: 48.416416, lng: 19.091166 },
    { lat: 48.416472, lng: 19.091138 },
    { lat: 48.417011, lng: 19.091686 },
    { lat: 48.417429, lng: 19.093098 },
    { lat: 48.417478, lng: 19.093362 },
    { lat: 48.417779, lng: 19.093869 },
    { lat: 48.417875, lng: 19.094306 },
    { lat: 48.417836, lng: 19.094876 },
    { lat: 48.417902, lng: 19.095044 },
    { lat: 48.418002, lng: 19.095509 },
    { lat: 48.418288, lng: 19.096111 },
    { lat: 48.418884, lng: 19.097257 },
    { lat: 48.418906, lng: 19.097313 },
    { lat: 48.419262, lng: 19.097984 },
    { lat: 48.419384, lng: 19.0984 },
    { lat: 48.419447, lng: 19.098617 },
    { lat: 48.419527, lng: 19.098776 },
    { lat: 48.419441, lng: 19.099144 },
    { lat: 48.419457, lng: 19.099711 },
    { lat: 48.419535, lng: 19.10005 },
    { lat: 48.419646, lng: 19.100429 },
    { lat: 48.419714, lng: 19.10084 },
    { lat: 48.419376, lng: 19.102122 },
    { lat: 48.419147, lng: 19.102866 },
    { lat: 48.41898, lng: 19.103556 },
    { lat: 48.418988, lng: 19.103527 },
    { lat: 48.419024, lng: 19.103795 },
    { lat: 48.419236, lng: 19.104317 },
    { lat: 48.419204, lng: 19.104786 },
    { lat: 48.41928, lng: 19.105304 },
  ],
  Očová: [
    { lat: 48.57447, lng: 19.294587 },
    { lat: 48.574424, lng: 19.296717 },
    { lat: 48.574418, lng: 19.296903 },
    { lat: 48.574415, lng: 19.296977 },
    { lat: 48.57473, lng: 19.296948 },
    { lat: 48.574814, lng: 19.296974 },
    { lat: 48.575125, lng: 19.297239 },
    { lat: 48.575269, lng: 19.29727 },
    { lat: 48.575364, lng: 19.297291 },
    { lat: 48.575943, lng: 19.297187 },
    { lat: 48.576134, lng: 19.297081 },
    { lat: 48.576344, lng: 19.296874 },
    { lat: 48.577366, lng: 19.295905 },
    { lat: 48.577928, lng: 19.295408 },
    { lat: 48.578028, lng: 19.29535 },
    { lat: 48.578233, lng: 19.295319 },
    { lat: 48.57838, lng: 19.295344 },
    { lat: 48.57839, lng: 19.295458 },
    { lat: 48.578325, lng: 19.296272 },
    { lat: 48.578155, lng: 19.298272 },
    { lat: 48.578002, lng: 19.300633 },
    { lat: 48.578053, lng: 19.300646 },
    { lat: 48.578046, lng: 19.300704 },
    { lat: 48.578043, lng: 19.300766 },
    { lat: 48.57804, lng: 19.300835 },
    { lat: 48.577784, lng: 19.303341 },
    { lat: 48.577577, lng: 19.305378 },
    { lat: 48.577566, lng: 19.305555 },
    { lat: 48.577562, lng: 19.305579 },
    { lat: 48.577558, lng: 19.305606 },
    { lat: 48.577524, lng: 19.305794 },
    { lat: 48.577235, lng: 19.308347 },
    { lat: 48.576953, lng: 19.311076 },
    { lat: 48.576557, lng: 19.31485 },
    { lat: 48.576553, lng: 19.314896 },
    { lat: 48.576835, lng: 19.315491 },
    { lat: 48.576856, lng: 19.315531 },
    { lat: 48.576876, lng: 19.315571 },
    { lat: 48.577177, lng: 19.316232 },
    { lat: 48.577211, lng: 19.316288 },
    { lat: 48.577223, lng: 19.316322 },
    { lat: 48.577289, lng: 19.317535 },
    { lat: 48.577718, lng: 19.317552 },
    { lat: 48.577531, lng: 19.319651 },
    { lat: 48.577332, lng: 19.321378 },
    { lat: 48.577273, lng: 19.321725 },
    { lat: 48.577216, lng: 19.321983 },
    { lat: 48.576499, lng: 19.323217 },
    { lat: 48.575332, lng: 19.325186 },
    { lat: 48.575163, lng: 19.325449 },
    { lat: 48.575045, lng: 19.325633 },
    { lat: 48.574738, lng: 19.326129 },
    { lat: 48.574712, lng: 19.326097 },
    { lat: 48.574568, lng: 19.325993 },
    { lat: 48.574457, lng: 19.325926 },
    { lat: 48.57442, lng: 19.325904 },
    { lat: 48.574317, lng: 19.325843 },
    { lat: 48.574172, lng: 19.325753 },
    { lat: 48.574136, lng: 19.32573 },
    { lat: 48.573868, lng: 19.32556 },
    { lat: 48.573679, lng: 19.325444 },
    { lat: 48.573488, lng: 19.325423 },
    { lat: 48.573265, lng: 19.325488 },
    { lat: 48.57263, lng: 19.325673 },
    { lat: 48.5714527, lng: 19.3261652 },
    { lat: 48.571344, lng: 19.326193 },
    { lat: 48.57122, lng: 19.326239 },
    { lat: 48.571116, lng: 19.326284 },
    { lat: 48.571082, lng: 19.326295 },
    { lat: 48.570472, lng: 19.326555 },
    { lat: 48.570032, lng: 19.326824 },
    { lat: 48.569466, lng: 19.327269 },
    { lat: 48.569046, lng: 19.327619 },
    { lat: 48.568951, lng: 19.327699 },
    { lat: 48.568449, lng: 19.328104 },
    { lat: 48.568216, lng: 19.32826 },
    { lat: 48.56806, lng: 19.328212 },
    { lat: 48.567792, lng: 19.328035 },
    { lat: 48.567779, lng: 19.328076 },
    { lat: 48.567749, lng: 19.328145 },
    { lat: 48.5677, lng: 19.328196 },
    { lat: 48.567303, lng: 19.327897 },
    { lat: 48.567014, lng: 19.327841 },
    { lat: 48.566329, lng: 19.327969 },
    { lat: 48.565713, lng: 19.328083 },
    { lat: 48.565487, lng: 19.328111 },
    { lat: 48.565451, lng: 19.328108 },
    { lat: 48.564667, lng: 19.327835 },
    { lat: 48.564153, lng: 19.327776 },
    { lat: 48.563209, lng: 19.330306 },
    { lat: 48.562692, lng: 19.331687 },
    { lat: 48.562677, lng: 19.331737 },
    { lat: 48.562676, lng: 19.331787 },
    { lat: 48.562673, lng: 19.331852 },
    { lat: 48.562044, lng: 19.3317 },
    { lat: 48.561527, lng: 19.331575 },
    { lat: 48.560598, lng: 19.33135 },
    { lat: 48.560337, lng: 19.331291 },
    { lat: 48.560174, lng: 19.331134 },
    { lat: 48.55997, lng: 19.331338 },
    { lat: 48.560224, lng: 19.331915 },
    { lat: 48.560704, lng: 19.332555 },
    { lat: 48.560679, lng: 19.33262 },
    { lat: 48.560655, lng: 19.332661 },
    { lat: 48.560578, lng: 19.333045 },
    { lat: 48.560566, lng: 19.333104 },
    { lat: 48.560555, lng: 19.333188 },
    { lat: 48.560542, lng: 19.333273 },
    { lat: 48.560525, lng: 19.33337 },
    { lat: 48.560499, lng: 19.333453 },
    { lat: 48.560838, lng: 19.334012 },
    { lat: 48.560954, lng: 19.334207 },
    { lat: 48.561184, lng: 19.334698 },
    { lat: 48.561675, lng: 19.33573 },
    { lat: 48.56188, lng: 19.336153 },
    { lat: 48.562172, lng: 19.336723 },
    { lat: 48.562349, lng: 19.33717 },
    { lat: 48.562465, lng: 19.337673 },
    { lat: 48.562514, lng: 19.33824 },
    { lat: 48.562535, lng: 19.340038 },
    { lat: 48.562518, lng: 19.341785 },
    { lat: 48.562465, lng: 19.3437 },
    { lat: 48.562427, lng: 19.343933 },
    { lat: 48.562378, lng: 19.344288 },
    { lat: 48.562287, lng: 19.344304 },
    { lat: 48.562269, lng: 19.3444 },
    { lat: 48.56231, lng: 19.34459 },
    { lat: 48.562266, lng: 19.344792 },
    { lat: 48.562168, lng: 19.345239 },
    { lat: 48.56216, lng: 19.345282 },
    { lat: 48.561853, lng: 19.346646 },
    { lat: 48.561549, lng: 19.348075 },
    { lat: 48.561427, lng: 19.348651 },
    { lat: 48.561422, lng: 19.348676 },
    { lat: 48.561117, lng: 19.350458 },
    { lat: 48.561098, lng: 19.350572 },
    { lat: 48.560901, lng: 19.351829 },
    { lat: 48.56089, lng: 19.351948 },
    { lat: 48.560719, lng: 19.353323 },
    { lat: 48.560608, lng: 19.354104 },
    { lat: 48.560601, lng: 19.354157 },
    { lat: 48.56048, lng: 19.354272 },
    { lat: 48.560478, lng: 19.354317 },
    { lat: 48.560476, lng: 19.354368 },
    { lat: 48.560591, lng: 19.3544 },
    { lat: 48.560506, lng: 19.355189 },
    { lat: 48.560465, lng: 19.355682 },
    { lat: 48.560439, lng: 19.356003 },
    { lat: 48.560353, lng: 19.357026 },
    { lat: 48.560137, lng: 19.358316 },
    { lat: 48.559409, lng: 19.361823 },
    { lat: 48.559352, lng: 19.362095 },
    { lat: 48.559312, lng: 19.362163 },
    { lat: 48.559309, lng: 19.362153 },
    { lat: 48.55916, lng: 19.362378 },
    { lat: 48.559183, lng: 19.362412 },
    { lat: 48.559003, lng: 19.362685 },
    { lat: 48.558865, lng: 19.36292 },
    { lat: 48.558796, lng: 19.363026 },
    { lat: 48.558476, lng: 19.363521 },
    { lat: 48.558391, lng: 19.36365 },
    { lat: 48.558335, lng: 19.363739 },
    { lat: 48.558253, lng: 19.363863 },
    { lat: 48.557384, lng: 19.364842 },
    { lat: 48.55608, lng: 19.366313 },
    { lat: 48.555739, lng: 19.366343 },
    { lat: 48.555565, lng: 19.366503 },
    { lat: 48.555536, lng: 19.36653 },
    { lat: 48.555442, lng: 19.366618 },
    { lat: 48.55519, lng: 19.36685 },
    { lat: 48.554536, lng: 19.367416 },
    { lat: 48.553223, lng: 19.368434 },
    { lat: 48.552594, lng: 19.368862 },
    { lat: 48.552151, lng: 19.369134 },
    { lat: 48.552123, lng: 19.369145 },
    { lat: 48.551758, lng: 19.369339 },
    { lat: 48.551516, lng: 19.369556 },
    { lat: 48.550593, lng: 19.369487 },
    { lat: 48.550572, lng: 19.369484 },
    { lat: 48.550185, lng: 19.369474 },
    { lat: 48.550066, lng: 19.369475 },
    { lat: 48.549393, lng: 19.369178 },
    { lat: 48.549354, lng: 19.369342 },
    { lat: 48.54912, lng: 19.369753 },
    { lat: 48.549045, lng: 19.369976 },
    { lat: 48.548636, lng: 19.370376 },
    { lat: 48.548499, lng: 19.370614 },
    { lat: 48.548226, lng: 19.371385 },
    { lat: 48.548125, lng: 19.371973 },
    { lat: 48.548211, lng: 19.372328 },
    { lat: 48.548392, lng: 19.372728 },
    { lat: 48.548457, lng: 19.373024 },
    { lat: 48.548597, lng: 19.373357 },
    { lat: 48.548637, lng: 19.373567 },
    { lat: 48.548598, lng: 19.373775 },
    { lat: 48.548687, lng: 19.374219 },
    { lat: 48.548763, lng: 19.374856 },
    { lat: 48.548724, lng: 19.376041 },
    { lat: 48.54875, lng: 19.37612 },
    { lat: 48.548737, lng: 19.376794 },
    { lat: 48.548776, lng: 19.377523 },
    { lat: 48.5488001, lng: 19.3779355 },
    { lat: 48.548813, lng: 19.378157 },
    { lat: 48.54886, lng: 19.378526 },
    { lat: 48.548773, lng: 19.379363 },
    { lat: 48.548738, lng: 19.379856 },
    { lat: 48.548635, lng: 19.380356 },
    { lat: 48.548537, lng: 19.380665 },
    { lat: 48.54854, lng: 19.380774 },
    { lat: 48.548545, lng: 19.380923 },
    { lat: 48.548607, lng: 19.381561 },
    { lat: 48.548577, lng: 19.381985 },
    { lat: 48.5483761, lng: 19.3825477 },
    { lat: 48.548424, lng: 19.382748 },
    { lat: 48.548374, lng: 19.382747 },
    { lat: 48.548363, lng: 19.383563 },
    { lat: 48.548315, lng: 19.38407 },
    { lat: 48.548233, lng: 19.384724 },
    { lat: 48.548109, lng: 19.385342 },
    { lat: 48.547905, lng: 19.386063 },
    { lat: 48.548759, lng: 19.384308 },
    { lat: 48.551368, lng: 19.380266 },
    { lat: 48.552856, lng: 19.37812 },
    { lat: 48.553022, lng: 19.377881 },
    { lat: 48.553693, lng: 19.377021 },
    { lat: 48.553749, lng: 19.37695 },
    { lat: 48.554209, lng: 19.376361 },
    { lat: 48.554497, lng: 19.375992 },
    { lat: 48.554618, lng: 19.375884 },
    { lat: 48.554656, lng: 19.37585 },
    { lat: 48.554839, lng: 19.375686 },
    { lat: 48.55493, lng: 19.375605 },
    { lat: 48.555021, lng: 19.37546 },
    { lat: 48.555138, lng: 19.375276 },
    { lat: 48.555265, lng: 19.375076 },
    { lat: 48.555316, lng: 19.374995 },
    { lat: 48.55555, lng: 19.374625 },
    { lat: 48.555843, lng: 19.374161 },
    { lat: 48.556577, lng: 19.37337 },
    { lat: 48.556795, lng: 19.371099 },
    { lat: 48.557168, lng: 19.370101 },
    { lat: 48.557769, lng: 19.368863 },
    { lat: 48.558249, lng: 19.367871 },
    { lat: 48.55853, lng: 19.367425 },
    { lat: 48.558518, lng: 19.367406 },
    { lat: 48.558577, lng: 19.367316 },
    { lat: 48.558587, lng: 19.367331 },
    { lat: 48.55865, lng: 19.367233 },
    { lat: 48.558641, lng: 19.367217 },
    { lat: 48.558965, lng: 19.366709 },
    { lat: 48.558982, lng: 19.366735 },
    { lat: 48.559149, lng: 19.366475 },
    { lat: 48.559581, lng: 19.365678 },
    { lat: 48.559792, lng: 19.365356 },
    { lat: 48.560922, lng: 19.363486 },
    { lat: 48.561494, lng: 19.362601 },
    { lat: 48.56257, lng: 19.361271 },
    { lat: 48.563242, lng: 19.360441 },
    { lat: 48.563273, lng: 19.360195 },
    { lat: 48.563511, lng: 19.359933 },
    { lat: 48.563605, lng: 19.359968 },
    { lat: 48.563624, lng: 19.359975 },
    { lat: 48.563647, lng: 19.359816 },
    { lat: 48.563689, lng: 19.359798 },
    { lat: 48.563789, lng: 19.359756 },
    { lat: 48.563863, lng: 19.359724 },
    { lat: 48.564445, lng: 19.359474 },
    { lat: 48.564692, lng: 19.359368 },
    { lat: 48.565397, lng: 19.359066 },
    { lat: 48.567078, lng: 19.358532 },
    { lat: 48.567451, lng: 19.358826 },
    { lat: 48.567624, lng: 19.358962 },
    { lat: 48.567747, lng: 19.360426 },
    { lat: 48.5682, lng: 19.360839 },
    { lat: 48.568689, lng: 19.361567 },
    { lat: 48.569101, lng: 19.362075 },
    { lat: 48.569538, lng: 19.362248 },
    { lat: 48.569784, lng: 19.362448 },
    { lat: 48.569981, lng: 19.362773 },
    { lat: 48.570075, lng: 19.363424 },
    { lat: 48.570182, lng: 19.364008 },
    { lat: 48.570356, lng: 19.364582 },
    { lat: 48.57071, lng: 19.365786 },
    { lat: 48.570759, lng: 19.366059 },
    { lat: 48.57076, lng: 19.36625 },
    { lat: 48.571123, lng: 19.366479 },
    { lat: 48.571761, lng: 19.36688 },
    { lat: 48.571773, lng: 19.366889 },
    { lat: 48.572228, lng: 19.367207 },
    { lat: 48.57228, lng: 19.367255 },
    { lat: 48.572597, lng: 19.366341 },
    { lat: 48.573192, lng: 19.365255 },
    { lat: 48.573794, lng: 19.364847 },
    { lat: 48.573859, lng: 19.364804 },
    { lat: 48.573869, lng: 19.364739 },
    { lat: 48.573957, lng: 19.364682 },
    { lat: 48.574655, lng: 19.363604 },
    { lat: 48.575444, lng: 19.362736 },
    { lat: 48.575482, lng: 19.362613 },
    { lat: 48.576678, lng: 19.35877 },
    { lat: 48.576828, lng: 19.35848 },
    { lat: 48.57689, lng: 19.358359 },
    { lat: 48.57768, lng: 19.355498 },
    { lat: 48.577765, lng: 19.355255 },
    { lat: 48.578183, lng: 19.354386 },
    { lat: 48.578337, lng: 19.353915 },
    { lat: 48.578902, lng: 19.350949 },
    { lat: 48.578919, lng: 19.350901 },
    { lat: 48.579375, lng: 19.349574 },
    { lat: 48.5794115, lng: 19.3494153 },
    { lat: 48.57952, lng: 19.348944 },
    { lat: 48.579674, lng: 19.347193 },
    { lat: 48.57975, lng: 19.347042 },
    { lat: 48.579993, lng: 19.346656 },
    { lat: 48.580503, lng: 19.344715 },
    { lat: 48.581074, lng: 19.343759 },
    { lat: 48.582627, lng: 19.341777 },
    { lat: 48.583178, lng: 19.341145 },
    { lat: 48.583241, lng: 19.341097 },
    { lat: 48.583284, lng: 19.341081 },
    { lat: 48.583313, lng: 19.341059 },
    { lat: 48.583297, lng: 19.340873 },
    { lat: 48.58336, lng: 19.340425 },
    { lat: 48.58369, lng: 19.339241 },
    { lat: 48.584133, lng: 19.338535 },
    { lat: 48.584163, lng: 19.338381 },
    { lat: 48.584164, lng: 19.338282 },
    { lat: 48.584242, lng: 19.338217 },
    { lat: 48.584186, lng: 19.338132 },
    { lat: 48.584135, lng: 19.338081 },
    { lat: 48.584031, lng: 19.338045 },
    { lat: 48.583713, lng: 19.337861 },
    { lat: 48.583484, lng: 19.337727 },
    { lat: 48.58372, lng: 19.337525 },
    { lat: 48.583775, lng: 19.337099 },
    { lat: 48.583843, lng: 19.336893 },
    { lat: 48.583961, lng: 19.336782 },
    { lat: 48.582887, lng: 19.337006 },
    { lat: 48.58287, lng: 19.336983 },
    { lat: 48.582475, lng: 19.336163 },
    { lat: 48.582607, lng: 19.335841 },
    { lat: 48.582779, lng: 19.335424 },
    { lat: 48.583013, lng: 19.334855 },
    { lat: 48.583927, lng: 19.335786 },
    { lat: 48.584119, lng: 19.335353 },
    { lat: 48.584246, lng: 19.335066 },
    { lat: 48.584295, lng: 19.334956 },
    { lat: 48.584313, lng: 19.334916 },
    { lat: 48.584372, lng: 19.334782 },
    { lat: 48.584406, lng: 19.334707 },
    { lat: 48.583164, lng: 19.333344 },
    { lat: 48.583179, lng: 19.333318 },
    { lat: 48.583212, lng: 19.333248 },
    { lat: 48.583227, lng: 19.333207 },
    { lat: 48.583476, lng: 19.332676 },
    { lat: 48.583564, lng: 19.332754 },
    { lat: 48.583693, lng: 19.332867 },
    { lat: 48.584067, lng: 19.331782 },
    { lat: 48.584206, lng: 19.331948 },
    { lat: 48.58436, lng: 19.332132 },
    { lat: 48.584489, lng: 19.332287 },
    { lat: 48.584613, lng: 19.332436 },
    { lat: 48.584748, lng: 19.332599 },
    { lat: 48.584867, lng: 19.33274 },
    { lat: 48.584999, lng: 19.3329 },
    { lat: 48.58514, lng: 19.333068 },
    { lat: 48.585284, lng: 19.33324 },
    { lat: 48.58543, lng: 19.333415 },
    { lat: 48.585566, lng: 19.333579 },
    { lat: 48.585577, lng: 19.333591 },
    { lat: 48.585643, lng: 19.333426 },
    { lat: 48.585721, lng: 19.333232 },
    { lat: 48.585781, lng: 19.333082 },
    { lat: 48.585336, lng: 19.332611 },
    { lat: 48.585602, lng: 19.331981 },
    { lat: 48.585666, lng: 19.331829 },
    { lat: 48.585799, lng: 19.331515 },
    { lat: 48.586113, lng: 19.331758 },
    { lat: 48.586146, lng: 19.331643 },
    { lat: 48.586251, lng: 19.331283 },
    { lat: 48.586263, lng: 19.33129 },
    { lat: 48.586705, lng: 19.331553 },
    { lat: 48.586746, lng: 19.331578 },
    { lat: 48.586838, lng: 19.331633 },
    { lat: 48.586857, lng: 19.331644 },
    { lat: 48.586887, lng: 19.331661 },
    { lat: 48.58697, lng: 19.331711 },
    { lat: 48.587012, lng: 19.331736 },
    { lat: 48.587616, lng: 19.328384 },
    { lat: 48.587987, lng: 19.326374 },
    { lat: 48.588189, lng: 19.325597 },
    { lat: 48.588255, lng: 19.325337 },
    { lat: 48.588975, lng: 19.323351 },
    { lat: 48.589797, lng: 19.321412 },
    { lat: 48.591057, lng: 19.318358 },
    { lat: 48.59393, lng: 19.320577 },
    { lat: 48.596548, lng: 19.322599 },
    { lat: 48.598358, lng: 19.32399 },
    { lat: 48.598614, lng: 19.324258 },
    { lat: 48.60026, lng: 19.327221 },
    { lat: 48.600233, lng: 19.327737 },
    { lat: 48.600408, lng: 19.328603 },
    { lat: 48.600401, lng: 19.329153 },
    { lat: 48.600399, lng: 19.329329 },
    { lat: 48.600393, lng: 19.329749 },
    { lat: 48.600452, lng: 19.331081 },
    { lat: 48.600626, lng: 19.331116 },
    { lat: 48.600679, lng: 19.331126 },
    { lat: 48.600719, lng: 19.331135 },
    { lat: 48.601075, lng: 19.331482 },
    { lat: 48.601505, lng: 19.331455 },
    { lat: 48.601649, lng: 19.33169 },
    { lat: 48.602165, lng: 19.331136 },
    { lat: 48.602391, lng: 19.330905 },
    { lat: 48.602675, lng: 19.330432 },
    { lat: 48.603457, lng: 19.329346 },
    { lat: 48.604373, lng: 19.328501 },
    { lat: 48.60449, lng: 19.329278 },
    { lat: 48.604726, lng: 19.329188 },
    { lat: 48.604868, lng: 19.330099 },
    { lat: 48.605313, lng: 19.329631 },
    { lat: 48.605824, lng: 19.328844 },
    { lat: 48.606417, lng: 19.329012 },
    { lat: 48.606399, lng: 19.329504 },
    { lat: 48.606394, lng: 19.329625 },
    { lat: 48.606762, lng: 19.331221 },
    { lat: 48.606717, lng: 19.331492 },
    { lat: 48.60694, lng: 19.332566 },
    { lat: 48.607059, lng: 19.333546 },
    { lat: 48.606312, lng: 19.33519 },
    { lat: 48.606153, lng: 19.335684 },
    { lat: 48.606018, lng: 19.336624 },
    { lat: 48.605894, lng: 19.33798 },
    { lat: 48.606161, lng: 19.339995 },
    { lat: 48.606076, lng: 19.341369 },
    { lat: 48.606162, lng: 19.342628 },
    { lat: 48.60628, lng: 19.343086 },
    { lat: 48.606356, lng: 19.343383 },
    { lat: 48.606538, lng: 19.343827 },
    { lat: 48.60657, lng: 19.343905 },
    { lat: 48.606661, lng: 19.344124 },
    { lat: 48.606681, lng: 19.344573 },
    { lat: 48.606749, lng: 19.346048 },
    { lat: 48.606575, lng: 19.347453 },
    { lat: 48.606543, lng: 19.347711 },
    { lat: 48.606145, lng: 19.3482 },
    { lat: 48.605909, lng: 19.349312 },
    { lat: 48.605737, lng: 19.349937 },
    { lat: 48.605479, lng: 19.350878 },
    { lat: 48.605433, lng: 19.352108 },
    { lat: 48.60532, lng: 19.353451 },
    { lat: 48.60529, lng: 19.353803 },
    { lat: 48.605256, lng: 19.354197 },
    { lat: 48.605234, lng: 19.354436 },
    { lat: 48.605394, lng: 19.35466 },
    { lat: 48.605486, lng: 19.354789 },
    { lat: 48.606581, lng: 19.355692 },
    { lat: 48.60763, lng: 19.356556 },
    { lat: 48.608645, lng: 19.357404 },
    { lat: 48.609118, lng: 19.357713 },
    { lat: 48.610054, lng: 19.358003 },
    { lat: 48.609475, lng: 19.35911 },
    { lat: 48.609401, lng: 19.359613 },
    { lat: 48.609771, lng: 19.36008 },
    { lat: 48.609913, lng: 19.360282 },
    { lat: 48.610335, lng: 19.360869 },
    { lat: 48.610472, lng: 19.361571 },
    { lat: 48.611614, lng: 19.36266 },
    { lat: 48.611591, lng: 19.362682 },
    { lat: 48.611307, lng: 19.362963 },
    { lat: 48.611648, lng: 19.363404 },
    { lat: 48.612069, lng: 19.36392 },
    { lat: 48.61284, lng: 19.36495 },
    { lat: 48.61304, lng: 19.365508 },
    { lat: 48.613057, lng: 19.367112 },
    { lat: 48.613238, lng: 19.367034 },
    { lat: 48.613355, lng: 19.367785 },
    { lat: 48.613497, lng: 19.368435 },
    { lat: 48.613669, lng: 19.369416 },
    { lat: 48.613499, lng: 19.370391 },
    { lat: 48.613622, lng: 19.370797 },
    { lat: 48.611915, lng: 19.371601 },
    { lat: 48.612789, lng: 19.373349 },
    { lat: 48.613404, lng: 19.373816 },
    { lat: 48.613694, lng: 19.373644 },
    { lat: 48.614068, lng: 19.373584 },
    { lat: 48.614392, lng: 19.373591 },
    { lat: 48.614828, lng: 19.373793 },
    { lat: 48.615169, lng: 19.374152 },
    { lat: 48.615637, lng: 19.374708 },
    { lat: 48.616277, lng: 19.375402 },
    { lat: 48.616652, lng: 19.375744 },
    { lat: 48.616827, lng: 19.376327 },
    { lat: 48.616931, lng: 19.376981 },
    { lat: 48.616998, lng: 19.37731 },
    { lat: 48.617304, lng: 19.378203 },
    { lat: 48.617366, lng: 19.378373 },
    { lat: 48.617495, lng: 19.379487 },
    { lat: 48.618045, lng: 19.379502 },
    { lat: 48.61845, lng: 19.379807 },
    { lat: 48.619255, lng: 19.380135 },
    { lat: 48.619634, lng: 19.380331 },
    { lat: 48.619755, lng: 19.381868 },
    { lat: 48.620201, lng: 19.381891 },
    { lat: 48.620378, lng: 19.3819 },
    { lat: 48.620516, lng: 19.381923 },
    { lat: 48.620553, lng: 19.381929 },
    { lat: 48.620354, lng: 19.382595 },
    { lat: 48.620351, lng: 19.383145 },
    { lat: 48.620343, lng: 19.383482 },
    { lat: 48.620335, lng: 19.38382 },
    { lat: 48.620242, lng: 19.384299 },
    { lat: 48.620191, lng: 19.384694 },
    { lat: 48.62016, lng: 19.384924 },
    { lat: 48.620114, lng: 19.385455 },
    { lat: 48.61969, lng: 19.38548 },
    { lat: 48.619284, lng: 19.385531 },
    { lat: 48.619444, lng: 19.386098 },
    { lat: 48.619452, lng: 19.386681 },
    { lat: 48.619327, lng: 19.386954 },
    { lat: 48.619089, lng: 19.387071 },
    { lat: 48.618684, lng: 19.387324 },
    { lat: 48.618206, lng: 19.387423 },
    { lat: 48.618689, lng: 19.387664 },
    { lat: 48.619481, lng: 19.388168 },
    { lat: 48.61988, lng: 19.388498 },
    { lat: 48.619915, lng: 19.388704 },
    { lat: 48.619667, lng: 19.389626 },
    { lat: 48.619594, lng: 19.389988 },
    { lat: 48.619461, lng: 19.390498 },
    { lat: 48.619441, lng: 19.390698 },
    { lat: 48.619731, lng: 19.39076 },
    { lat: 48.619477, lng: 19.391806 },
    { lat: 48.619359, lng: 19.392077 },
    { lat: 48.619252, lng: 19.392721 },
    { lat: 48.619412, lng: 19.393069 },
    { lat: 48.619477, lng: 19.393433 },
    { lat: 48.619477, lng: 19.393526 },
    { lat: 48.619447, lng: 19.393609 },
    { lat: 48.619092, lng: 19.393676 },
    { lat: 48.618605, lng: 19.393813 },
    { lat: 48.618262, lng: 19.393933 },
    { lat: 48.618028, lng: 19.394093 },
    { lat: 48.617939, lng: 19.394242 },
    { lat: 48.617667, lng: 19.394407 },
    { lat: 48.617619, lng: 19.394436 },
    { lat: 48.617596, lng: 19.394301 },
    { lat: 48.617417, lng: 19.393866 },
    { lat: 48.617261, lng: 19.393615 },
    { lat: 48.617235, lng: 19.39372 },
    { lat: 48.617154, lng: 19.393835 },
    { lat: 48.617148, lng: 19.393846 },
    { lat: 48.617079, lng: 19.393945 },
    { lat: 48.617027, lng: 19.394021 },
    { lat: 48.616989, lng: 19.394077 },
    { lat: 48.616917, lng: 19.394102 },
    { lat: 48.617003, lng: 19.394157 },
    { lat: 48.617088, lng: 19.394665 },
    { lat: 48.617038, lng: 19.394853 },
    { lat: 48.616995, lng: 19.395058 },
    { lat: 48.617, lng: 19.395379 },
    { lat: 48.616908, lng: 19.39564 },
    { lat: 48.616948, lng: 19.396109 },
    { lat: 48.617002, lng: 19.396451 },
    { lat: 48.616916, lng: 19.397 },
    { lat: 48.617049, lng: 19.397497 },
    { lat: 48.617006, lng: 19.397897 },
    { lat: 48.616867, lng: 19.398417 },
    { lat: 48.616753, lng: 19.398661 },
    { lat: 48.616462, lng: 19.399459 },
    { lat: 48.616284, lng: 19.399248 },
    { lat: 48.616027, lng: 19.399233 },
    { lat: 48.61588, lng: 19.399164 },
    { lat: 48.615573, lng: 19.400541 },
    { lat: 48.615723, lng: 19.400715 },
    { lat: 48.615689, lng: 19.400997 },
    { lat: 48.615678, lng: 19.401154 },
    { lat: 48.615664, lng: 19.40123 },
    { lat: 48.615737, lng: 19.401543 },
    { lat: 48.615869, lng: 19.401778 },
    { lat: 48.616147, lng: 19.401975 },
    { lat: 48.616184, lng: 19.402223 },
    { lat: 48.616113, lng: 19.402439 },
    { lat: 48.616004, lng: 19.4025 },
    { lat: 48.616023, lng: 19.402807 },
    { lat: 48.616283, lng: 19.403191 },
    { lat: 48.616398, lng: 19.403255 },
    { lat: 48.616655, lng: 19.403622 },
    { lat: 48.616837, lng: 19.403727 },
    { lat: 48.617236, lng: 19.403886 },
    { lat: 48.617803, lng: 19.404153 },
    { lat: 48.617705, lng: 19.405591 },
    { lat: 48.617573, lng: 19.406503 },
    { lat: 48.617086, lng: 19.406551 },
    { lat: 48.616718, lng: 19.406558 },
    { lat: 48.616542, lng: 19.406592 },
    { lat: 48.61636, lng: 19.406689 },
    { lat: 48.61617, lng: 19.40682 },
    { lat: 48.616033, lng: 19.406957 },
    { lat: 48.61591, lng: 19.407123 },
    { lat: 48.615762, lng: 19.407375 },
    { lat: 48.615632, lng: 19.407637 },
    { lat: 48.615392, lng: 19.407908 },
    { lat: 48.615265, lng: 19.408178 },
    { lat: 48.615242, lng: 19.408924 },
    { lat: 48.615197, lng: 19.409049 },
    { lat: 48.615139, lng: 19.409133 },
    { lat: 48.61493, lng: 19.409243 },
    { lat: 48.614785, lng: 19.409464 },
    { lat: 48.614706, lng: 19.409534 },
    { lat: 48.614592, lng: 19.409584 },
    { lat: 48.614391, lng: 19.409664 },
    { lat: 48.614348, lng: 19.409681 },
    { lat: 48.614279, lng: 19.409709 },
    { lat: 48.614187, lng: 19.409793 },
    { lat: 48.614115, lng: 19.409893 },
    { lat: 48.613994, lng: 19.410101 },
    { lat: 48.613802, lng: 19.410522 },
    { lat: 48.613326, lng: 19.411331 },
    { lat: 48.6131, lng: 19.411743 },
    { lat: 48.612933, lng: 19.412111 },
    { lat: 48.612692, lng: 19.4128 },
    { lat: 48.612275, lng: 19.41361 },
    { lat: 48.611995, lng: 19.41438 },
    { lat: 48.611914, lng: 19.414413 },
    { lat: 48.611457, lng: 19.414916 },
    { lat: 48.611461, lng: 19.414659 },
    { lat: 48.611423, lng: 19.414277 },
    { lat: 48.611116, lng: 19.413783 },
    { lat: 48.611017, lng: 19.413769 },
    { lat: 48.610875, lng: 19.413606 },
    { lat: 48.610576, lng: 19.413572 },
    { lat: 48.610501, lng: 19.412855 },
    { lat: 48.610261, lng: 19.411573 },
    { lat: 48.609509, lng: 19.412174 },
    { lat: 48.609289, lng: 19.412424 },
    { lat: 48.609279, lng: 19.413085 },
    { lat: 48.609488, lng: 19.414408 },
    { lat: 48.609653, lng: 19.414822 },
    { lat: 48.609776, lng: 19.41516 },
    { lat: 48.609854, lng: 19.415328 },
    { lat: 48.60987, lng: 19.415775 },
    { lat: 48.610034, lng: 19.416297 },
    { lat: 48.6101, lng: 19.416736 },
    { lat: 48.610175, lng: 19.417075 },
    { lat: 48.610187, lng: 19.417318 },
    { lat: 48.610195, lng: 19.417473 },
    { lat: 48.610105, lng: 19.418109 },
    { lat: 48.610118, lng: 19.418181 },
    { lat: 48.610066, lng: 19.418358 },
    { lat: 48.609931, lng: 19.418659 },
    { lat: 48.609614, lng: 19.418478 },
    { lat: 48.609252, lng: 19.418063 },
    { lat: 48.608985, lng: 19.417418 },
    { lat: 48.608958, lng: 19.417348 },
    { lat: 48.608887, lng: 19.417165 },
    { lat: 48.608879, lng: 19.416963 },
    { lat: 48.608025, lng: 19.416469 },
    { lat: 48.608236, lng: 19.416713 },
    { lat: 48.608239, lng: 19.416848 },
    { lat: 48.608288, lng: 19.416959 },
    { lat: 48.608393, lng: 19.417046 },
    { lat: 48.608534, lng: 19.417105 },
    { lat: 48.608644, lng: 19.417463 },
    { lat: 48.608757, lng: 19.417942 },
    { lat: 48.608863, lng: 19.418019 },
    { lat: 48.609053, lng: 19.418461 },
    { lat: 48.60911, lng: 19.41878 },
    { lat: 48.609239, lng: 19.4199 },
    { lat: 48.609176, lng: 19.420127 },
    { lat: 48.609178, lng: 19.420287 },
    { lat: 48.609139, lng: 19.420416 },
    { lat: 48.609089, lng: 19.42088 },
    { lat: 48.60915, lng: 19.42116 },
    { lat: 48.609013, lng: 19.421542 },
    { lat: 48.609017, lng: 19.421589 },
    { lat: 48.608954, lng: 19.421869 },
    { lat: 48.608974, lng: 19.422052 },
    { lat: 48.608909, lng: 19.422285 },
    { lat: 48.608807, lng: 19.422401 },
    { lat: 48.608781, lng: 19.422509 },
    { lat: 48.608676, lng: 19.422744 },
    { lat: 48.608575, lng: 19.422804 },
    { lat: 48.608534, lng: 19.422905 },
    { lat: 48.608532, lng: 19.422976 },
    { lat: 48.608455, lng: 19.423249 },
    { lat: 48.60826, lng: 19.423649 },
    { lat: 48.608184, lng: 19.423936 },
    { lat: 48.608084, lng: 19.42417 },
    { lat: 48.607953, lng: 19.424374 },
    { lat: 48.607872, lng: 19.424535 },
    { lat: 48.607844, lng: 19.424732 },
    { lat: 48.607838, lng: 19.425012 },
    { lat: 48.607819, lng: 19.425197 },
    { lat: 48.607881, lng: 19.425607 },
    { lat: 48.60793, lng: 19.425953 },
    { lat: 48.608087, lng: 19.426323 },
    { lat: 48.608333, lng: 19.426786 },
    { lat: 48.60857, lng: 19.42699 },
    { lat: 48.608673, lng: 19.427115 },
    { lat: 48.608977, lng: 19.427338 },
    { lat: 48.60908, lng: 19.427392 },
    { lat: 48.609317, lng: 19.427524 },
    { lat: 48.609575, lng: 19.427599 },
    { lat: 48.609802, lng: 19.427607 },
    { lat: 48.610186, lng: 19.427738 },
    { lat: 48.610449, lng: 19.427749 },
    { lat: 48.610634, lng: 19.427706 },
    { lat: 48.610795, lng: 19.42771 },
    { lat: 48.611008, lng: 19.427799 },
    { lat: 48.611498, lng: 19.428181 },
    { lat: 48.611667, lng: 19.428316 },
    { lat: 48.611977, lng: 19.428494 },
    { lat: 48.612153, lng: 19.428679 },
    { lat: 48.612514, lng: 19.429067 },
    { lat: 48.613, lng: 19.429643 },
    { lat: 48.613074, lng: 19.429765 },
    { lat: 48.613247, lng: 19.430066 },
    { lat: 48.613412, lng: 19.43029 },
    { lat: 48.613542, lng: 19.430635 },
    { lat: 48.613636, lng: 19.430853 },
    { lat: 48.61387, lng: 19.431547 },
    { lat: 48.613969, lng: 19.431928 },
    { lat: 48.614162, lng: 19.432286 },
    { lat: 48.6142, lng: 19.432441 },
    { lat: 48.614342, lng: 19.432927 },
    { lat: 48.614394, lng: 19.433242 },
    { lat: 48.614545, lng: 19.433467 },
    { lat: 48.614809, lng: 19.433871 },
    { lat: 48.615115, lng: 19.434252 },
    { lat: 48.615403, lng: 19.434479 },
    { lat: 48.616038, lng: 19.434822 },
    { lat: 48.616535, lng: 19.434918 },
    { lat: 48.616765, lng: 19.43499 },
    { lat: 48.616952, lng: 19.434996 },
    { lat: 48.617117, lng: 19.435058 },
    { lat: 48.61732, lng: 19.435049 },
    { lat: 48.617686, lng: 19.435189 },
    { lat: 48.617811, lng: 19.435126 },
    { lat: 48.618155, lng: 19.435434 },
    { lat: 48.618394, lng: 19.435699 },
    { lat: 48.618496, lng: 19.435726 },
    { lat: 48.618623, lng: 19.435627 },
    { lat: 48.618699, lng: 19.43563 },
    { lat: 48.618902, lng: 19.435505 },
    { lat: 48.619002, lng: 19.435507 },
    { lat: 48.619076, lng: 19.435471 },
    { lat: 48.619136, lng: 19.435542 },
    { lat: 48.619203, lng: 19.435589 },
    { lat: 48.61933, lng: 19.435702 },
    { lat: 48.619532, lng: 19.435866 },
    { lat: 48.619983, lng: 19.436278 },
    { lat: 48.62057, lng: 19.436831 },
    { lat: 48.620817, lng: 19.437187 },
    { lat: 48.621165, lng: 19.437615 },
    { lat: 48.621306, lng: 19.437755 },
    { lat: 48.621763, lng: 19.438163 },
    { lat: 48.622192, lng: 19.438498 },
    { lat: 48.622534, lng: 19.438851 },
    { lat: 48.622716, lng: 19.438984 },
    { lat: 48.62306, lng: 19.439365 },
    { lat: 48.623353, lng: 19.439611 },
    { lat: 48.623738, lng: 19.439792 },
    { lat: 48.624105, lng: 19.439938 },
    { lat: 48.624363, lng: 19.440061 },
    { lat: 48.624785, lng: 19.440278 },
    { lat: 48.625461, lng: 19.440712 },
    { lat: 48.625722, lng: 19.440878 },
    { lat: 48.625969, lng: 19.441144 },
    { lat: 48.626128, lng: 19.441386 },
    { lat: 48.626505, lng: 19.44187 },
    { lat: 48.627199, lng: 19.44224 },
    { lat: 48.627609, lng: 19.442743 },
    { lat: 48.62819, lng: 19.443665 },
    { lat: 48.628248, lng: 19.443696 },
    { lat: 48.628615, lng: 19.444068 },
    { lat: 48.629073, lng: 19.444459 },
    { lat: 48.629274, lng: 19.44457 },
    { lat: 48.629794, lng: 19.445567 },
    { lat: 48.629885, lng: 19.445744 },
    { lat: 48.630646, lng: 19.447504 },
    { lat: 48.630739, lng: 19.44772 },
    { lat: 48.631407, lng: 19.448149 },
    { lat: 48.63189, lng: 19.448845 },
    { lat: 48.632152, lng: 19.448586 },
    { lat: 48.632208, lng: 19.448502 },
    { lat: 48.632396, lng: 19.448216 },
    { lat: 48.633286, lng: 19.448196 },
    { lat: 48.634046, lng: 19.448303 },
    { lat: 48.634711, lng: 19.448185 },
    { lat: 48.634916, lng: 19.448243 },
    { lat: 48.635228, lng: 19.448332 },
    { lat: 48.635629, lng: 19.448797 },
    { lat: 48.635642, lng: 19.448817 },
    { lat: 48.635979, lng: 19.449326 },
    { lat: 48.636102, lng: 19.450093 },
    { lat: 48.636316, lng: 19.451311 },
    { lat: 48.636561, lng: 19.451857 },
    { lat: 48.636686, lng: 19.452141 },
    { lat: 48.637399, lng: 19.453107 },
    { lat: 48.638308, lng: 19.454393 },
    { lat: 48.638485, lng: 19.454577 },
    { lat: 48.639407, lng: 19.455535 },
    { lat: 48.640373, lng: 19.456376 },
    { lat: 48.640604, lng: 19.456936 },
    { lat: 48.640724, lng: 19.457228 },
    { lat: 48.641246, lng: 19.458093 },
    { lat: 48.641993, lng: 19.45873 },
    { lat: 48.642369, lng: 19.459114 },
    { lat: 48.642379, lng: 19.459127 },
    { lat: 48.642428, lng: 19.459194 },
    { lat: 48.64287, lng: 19.459796 },
    { lat: 48.643039, lng: 19.460275 },
    { lat: 48.643171, lng: 19.460649 },
    { lat: 48.643648, lng: 19.461312 },
    { lat: 48.644377, lng: 19.462485 },
    { lat: 48.644521, lng: 19.463503 },
    { lat: 48.644658, lng: 19.46447 },
    { lat: 48.64486, lng: 19.464779 },
    { lat: 48.644796, lng: 19.465271 },
    { lat: 48.644864, lng: 19.466381 },
    { lat: 48.644932, lng: 19.466919 },
    { lat: 48.645319, lng: 19.468089 },
    { lat: 48.645518, lng: 19.468223 },
    { lat: 48.646106, lng: 19.468628 },
    { lat: 48.646764, lng: 19.468889 },
    { lat: 48.646993, lng: 19.468818 },
    { lat: 48.647234, lng: 19.468744 },
    { lat: 48.647459, lng: 19.469 },
    { lat: 48.648344, lng: 19.470203 },
    { lat: 48.64899, lng: 19.471156 },
    { lat: 48.648999, lng: 19.471171 },
    { lat: 48.649195, lng: 19.471502 },
    { lat: 48.649372, lng: 19.472302 },
    { lat: 48.649392, lng: 19.472391 },
    { lat: 48.649461, lng: 19.472998 },
    { lat: 48.649591, lng: 19.473755 },
    { lat: 48.650202, lng: 19.475203 },
    { lat: 48.650454, lng: 19.475646 },
    { lat: 48.650724, lng: 19.476119 },
    { lat: 48.651307, lng: 19.476971 },
    { lat: 48.65166, lng: 19.477488 },
    { lat: 48.652017, lng: 19.478487 },
    { lat: 48.652102, lng: 19.478711 },
    { lat: 48.652495, lng: 19.479751 },
    { lat: 48.65261, lng: 19.480867 },
    { lat: 48.652753, lng: 19.481211 },
    { lat: 48.653186, lng: 19.482246 },
    { lat: 48.653933, lng: 19.483299 },
    { lat: 48.654755, lng: 19.484491 },
    { lat: 48.655023, lng: 19.485654 },
    { lat: 48.655175, lng: 19.486087 },
    { lat: 48.655336, lng: 19.486546 },
    { lat: 48.655362, lng: 19.486798 },
    { lat: 48.655313, lng: 19.487821 },
    { lat: 48.655073, lng: 19.489029 },
    { lat: 48.654955, lng: 19.489949 },
    { lat: 48.654916, lng: 19.490255 },
    { lat: 48.654855, lng: 19.491237 },
    { lat: 48.654714, lng: 19.492175 },
    { lat: 48.654474, lng: 19.492971 },
    { lat: 48.654331, lng: 19.493537 },
    { lat: 48.654299, lng: 19.494255 },
    { lat: 48.655182, lng: 19.494455 },
    { lat: 48.655736, lng: 19.494592 },
    { lat: 48.656051, lng: 19.494572 },
    { lat: 48.656899, lng: 19.493227 },
    { lat: 48.657109, lng: 19.492496 },
    { lat: 48.65782, lng: 19.492512 },
    { lat: 48.658904, lng: 19.493784 },
    { lat: 48.659891, lng: 19.493775 },
    { lat: 48.660665, lng: 19.493659 },
    { lat: 48.6617766, lng: 19.4933965 },
    { lat: 48.662016, lng: 19.49334 },
    { lat: 48.662548, lng: 19.492902 },
    { lat: 48.662794, lng: 19.492422 },
    { lat: 48.663326, lng: 19.489881 },
    { lat: 48.663359, lng: 19.489711 },
    { lat: 48.663388, lng: 19.489566 },
    { lat: 48.663588, lng: 19.488553 },
    { lat: 48.663813, lng: 19.487597 },
    { lat: 48.664062, lng: 19.48704 },
    { lat: 48.664455, lng: 19.486338 },
    { lat: 48.665161, lng: 19.485999 },
    { lat: 48.665699, lng: 19.486132 },
    { lat: 48.666587, lng: 19.486087 },
    { lat: 48.667412, lng: 19.485837 },
    { lat: 48.667807, lng: 19.484807 },
    { lat: 48.667934, lng: 19.484014 },
    { lat: 48.667997, lng: 19.483624 },
    { lat: 48.668248, lng: 19.482701 },
    { lat: 48.668412, lng: 19.481833 },
    { lat: 48.668885, lng: 19.480414 },
    { lat: 48.669187, lng: 19.47996 },
    { lat: 48.669519, lng: 19.479277 },
    { lat: 48.668294, lng: 19.478139 },
    { lat: 48.667941, lng: 19.477811 },
    { lat: 48.66624, lng: 19.475112 },
    { lat: 48.666192, lng: 19.47503 },
    { lat: 48.666181, lng: 19.475011 },
    { lat: 48.666162, lng: 19.474979 },
    { lat: 48.665815, lng: 19.474388 },
    { lat: 48.665737, lng: 19.474318 },
    { lat: 48.665096, lng: 19.473579 },
    { lat: 48.664292, lng: 19.472503 },
    { lat: 48.664006, lng: 19.47208 },
    { lat: 48.663973, lng: 19.472031 },
    { lat: 48.663159, lng: 19.470824 },
    { lat: 48.662156, lng: 19.469535 },
    { lat: 48.662152, lng: 19.469519 },
    { lat: 48.662122, lng: 19.469414 },
    { lat: 48.662021, lng: 19.469229 },
    { lat: 48.661725, lng: 19.468873 },
    { lat: 48.661251, lng: 19.468574 },
    { lat: 48.661001, lng: 19.468331 },
    { lat: 48.660891, lng: 19.468002 },
    { lat: 48.66087, lng: 19.467644 },
    { lat: 48.660724, lng: 19.467179 },
    { lat: 48.660785, lng: 19.466784 },
    { lat: 48.660836, lng: 19.466481 },
    { lat: 48.660892, lng: 19.466273 },
    { lat: 48.660954, lng: 19.466029 },
    { lat: 48.660992, lng: 19.465916 },
    { lat: 48.660981, lng: 19.465781 },
    { lat: 48.660914, lng: 19.465484 },
    { lat: 48.660889, lng: 19.464967 },
    { lat: 48.660792, lng: 19.464706 },
    { lat: 48.660685, lng: 19.464447 },
    { lat: 48.660598, lng: 19.464229 },
    { lat: 48.66047, lng: 19.464007 },
    { lat: 48.660093, lng: 19.463847 },
    { lat: 48.659719, lng: 19.463649 },
    { lat: 48.659531, lng: 19.463456 },
    { lat: 48.659487, lng: 19.463111 },
    { lat: 48.659487, lng: 19.462575 },
    { lat: 48.659523, lng: 19.462193 },
    { lat: 48.659638, lng: 19.461904 },
    { lat: 48.659599, lng: 19.461486 },
    { lat: 48.659624, lng: 19.461132 },
    { lat: 48.659786, lng: 19.460455 },
    { lat: 48.659853, lng: 19.45994 },
    { lat: 48.659967, lng: 19.459621 },
    { lat: 48.66011, lng: 19.459361 },
    { lat: 48.660362, lng: 19.458674 },
    { lat: 48.660417, lng: 19.458067 },
    { lat: 48.660454, lng: 19.457937 },
    { lat: 48.660797, lng: 19.457455 },
    { lat: 48.660904, lng: 19.457242 },
    { lat: 48.660947, lng: 19.456771 },
    { lat: 48.661049, lng: 19.45625 },
    { lat: 48.661236, lng: 19.45571 },
    { lat: 48.661425, lng: 19.455163 },
    { lat: 48.661445, lng: 19.454912 },
    { lat: 48.661261, lng: 19.454344 },
    { lat: 48.661255, lng: 19.453788 },
    { lat: 48.661085, lng: 19.453097 },
    { lat: 48.660985, lng: 19.452652 },
    { lat: 48.660903, lng: 19.452425 },
    { lat: 48.660758, lng: 19.452134 },
    { lat: 48.660602, lng: 19.451882 },
    { lat: 48.660464, lng: 19.45124 },
    { lat: 48.660283, lng: 19.450735 },
    { lat: 48.660096, lng: 19.45024 },
    { lat: 48.659776, lng: 19.449476 },
    { lat: 48.659753, lng: 19.449056 },
    { lat: 48.659769, lng: 19.448666 },
    { lat: 48.659849, lng: 19.448338 },
    { lat: 48.659857, lng: 19.44799 },
    { lat: 48.659768, lng: 19.446704 },
    { lat: 48.65977, lng: 19.446071 },
    { lat: 48.659972, lng: 19.445617 },
    { lat: 48.660219, lng: 19.445256 },
    { lat: 48.66032, lng: 19.444924 },
    { lat: 48.660311, lng: 19.444574 },
    { lat: 48.660159, lng: 19.443499 },
    { lat: 48.659978, lng: 19.442368 },
    { lat: 48.659676, lng: 19.44135 },
    { lat: 48.659401, lng: 19.440676 },
    { lat: 48.659297, lng: 19.440299 },
    { lat: 48.659268, lng: 19.439842 },
    { lat: 48.659293, lng: 19.439375 },
    { lat: 48.659347, lng: 19.438856 },
    { lat: 48.659559, lng: 19.43861 },
    { lat: 48.659595, lng: 19.438523 },
    { lat: 48.659631, lng: 19.438438 },
    { lat: 48.659784, lng: 19.437981 },
    { lat: 48.660004, lng: 19.437373 },
    { lat: 48.659987, lng: 19.437164 },
    { lat: 48.659658, lng: 19.436641 },
    { lat: 48.659588, lng: 19.436485 },
    { lat: 48.659387, lng: 19.435655 },
    { lat: 48.659126, lng: 19.435542 },
    { lat: 48.65877, lng: 19.435388 },
    { lat: 48.658521, lng: 19.435428 },
    { lat: 48.658403, lng: 19.43543 },
    { lat: 48.658216, lng: 19.435381 },
    { lat: 48.657824, lng: 19.43472 },
    { lat: 48.657647, lng: 19.434446 },
    { lat: 48.657438, lng: 19.43418 },
    { lat: 48.657291, lng: 19.433929 },
    { lat: 48.657064, lng: 19.433706 },
    { lat: 48.656954, lng: 19.433656 },
    { lat: 48.656432, lng: 19.433584 },
    { lat: 48.655697, lng: 19.43396 },
    { lat: 48.655542, lng: 19.433923 },
    { lat: 48.655294, lng: 19.433747 },
    { lat: 48.65469, lng: 19.433455 },
    { lat: 48.654493, lng: 19.43314 },
    { lat: 48.654442, lng: 19.433105 },
    { lat: 48.654416, lng: 19.433091 },
    { lat: 48.654381, lng: 19.433072 },
    { lat: 48.654033, lng: 19.432886 },
    { lat: 48.653702, lng: 19.432438 },
    { lat: 48.653458, lng: 19.431976 },
    { lat: 48.653278, lng: 19.431455 },
    { lat: 48.653111, lng: 19.431004 },
    { lat: 48.652937, lng: 19.430469 },
    { lat: 48.652639, lng: 19.430174 },
    { lat: 48.652427, lng: 19.430324 },
    { lat: 48.652213, lng: 19.430264 },
    { lat: 48.652153, lng: 19.430224 },
    { lat: 48.652103, lng: 19.430192 },
    { lat: 48.652099, lng: 19.430189 },
    { lat: 48.652007, lng: 19.429713 },
    { lat: 48.651959, lng: 19.429034 },
    { lat: 48.65198, lng: 19.428384 },
    { lat: 48.65215, lng: 19.428008 },
    { lat: 48.652257, lng: 19.427456 },
    { lat: 48.652409, lng: 19.4271 },
    { lat: 48.652467, lng: 19.426983 },
    { lat: 48.652537, lng: 19.426785 },
    { lat: 48.652545, lng: 19.426592 },
    { lat: 48.652411, lng: 19.426286 },
    { lat: 48.652403, lng: 19.425949 },
    { lat: 48.65248, lng: 19.425488 },
    { lat: 48.652444, lng: 19.424865 },
    { lat: 48.652492, lng: 19.424391 },
    { lat: 48.652476, lng: 19.424148 },
    { lat: 48.652609, lng: 19.423727 },
    { lat: 48.652682, lng: 19.423495 },
    { lat: 48.652803, lng: 19.42314 },
    { lat: 48.65261, lng: 19.422869 },
    { lat: 48.652533, lng: 19.422483 },
    { lat: 48.652618, lng: 19.422025 },
    { lat: 48.652683, lng: 19.421775 },
    { lat: 48.652697, lng: 19.421418 },
    { lat: 48.652575, lng: 19.420882 },
    { lat: 48.65257, lng: 19.420538 },
    { lat: 48.652593, lng: 19.420124 },
    { lat: 48.652563, lng: 19.419709 },
    { lat: 48.652366, lng: 19.419008 },
    { lat: 48.651847, lng: 19.417801 },
    { lat: 48.651681, lng: 19.417147 },
    { lat: 48.651576, lng: 19.416485 },
    { lat: 48.651451, lng: 19.416135 },
    { lat: 48.651458, lng: 19.415804 },
    { lat: 48.65154, lng: 19.41511 },
    { lat: 48.65143, lng: 19.414734 },
    { lat: 48.651401, lng: 19.414163 },
    { lat: 48.651297, lng: 19.413951 },
    { lat: 48.651039, lng: 19.413662 },
    { lat: 48.650971, lng: 19.413406 },
    { lat: 48.650983, lng: 19.413283 },
    { lat: 48.651184, lng: 19.412958 },
    { lat: 48.651416, lng: 19.41256 },
    { lat: 48.651481, lng: 19.412493 },
    { lat: 48.651552, lng: 19.412427 },
    { lat: 48.651663, lng: 19.412241 },
    { lat: 48.651749, lng: 19.412014 },
    { lat: 48.651863, lng: 19.411562 },
    { lat: 48.65191, lng: 19.410852 },
    { lat: 48.652002, lng: 19.410444 },
    { lat: 48.652194, lng: 19.409949 },
    { lat: 48.652313, lng: 19.409428 },
    { lat: 48.652299, lng: 19.408902 },
    { lat: 48.652328, lng: 19.408465 },
    { lat: 48.652225, lng: 19.408188 },
    { lat: 48.65206, lng: 19.408078 },
    { lat: 48.651918, lng: 19.407842 },
    { lat: 48.651748, lng: 19.407449 },
    { lat: 48.651499, lng: 19.406317 },
    { lat: 48.651608, lng: 19.405806 },
    { lat: 48.651759, lng: 19.405375 },
    { lat: 48.651987, lng: 19.404616 },
    { lat: 48.652383, lng: 19.404077 },
    { lat: 48.652653, lng: 19.40388 },
    { lat: 48.652802, lng: 19.403722 },
    { lat: 48.652927, lng: 19.403617 },
    { lat: 48.653091, lng: 19.403209 },
    { lat: 48.653089, lng: 19.403085 },
    { lat: 48.653081, lng: 19.402934 },
    { lat: 48.653031, lng: 19.401653 },
    { lat: 48.652998, lng: 19.401341 },
    { lat: 48.652971, lng: 19.400864 },
    { lat: 48.653106, lng: 19.400529 },
    { lat: 48.653687, lng: 19.399671 },
    { lat: 48.653811, lng: 19.399308 },
    { lat: 48.654064, lng: 19.397717 },
    { lat: 48.654182, lng: 19.397089 },
    { lat: 48.654192, lng: 19.396868 },
    { lat: 48.654179, lng: 19.396828 },
    { lat: 48.65431, lng: 19.396644 },
    { lat: 48.654462, lng: 19.3961 },
    { lat: 48.654486, lng: 19.395502 },
    { lat: 48.654508, lng: 19.395413 },
    { lat: 48.65489, lng: 19.394323 },
    { lat: 48.655005, lng: 19.393746 },
    { lat: 48.654994, lng: 19.393377 },
    { lat: 48.655412, lng: 19.392766 },
    { lat: 48.655571, lng: 19.392432 },
    { lat: 48.655635, lng: 19.392147 },
    { lat: 48.655639, lng: 19.391609 },
    { lat: 48.65566, lng: 19.391004 },
    { lat: 48.655483, lng: 19.390301 },
    { lat: 48.655519, lng: 19.389308 },
    { lat: 48.655517, lng: 19.388881 },
    { lat: 48.655499, lng: 19.388603 },
    { lat: 48.655571, lng: 19.388071 },
    { lat: 48.655829, lng: 19.387479 },
    { lat: 48.655934, lng: 19.387399 },
    { lat: 48.656023, lng: 19.387382 },
    { lat: 48.656164, lng: 19.387393 },
    { lat: 48.656369, lng: 19.387105 },
    { lat: 48.656432, lng: 19.386686 },
    { lat: 48.656344, lng: 19.386194 },
    { lat: 48.656283, lng: 19.385388 },
    { lat: 48.656387, lng: 19.385005 },
    { lat: 48.656451, lng: 19.384528 },
    { lat: 48.656463, lng: 19.38409 },
    { lat: 48.65674, lng: 19.383557 },
    { lat: 48.656946, lng: 19.383196 },
    { lat: 48.657249, lng: 19.382828 },
    { lat: 48.657296, lng: 19.382403 },
    { lat: 48.657288, lng: 19.381504 },
    { lat: 48.657412, lng: 19.381041 },
    { lat: 48.657502, lng: 19.38063 },
    { lat: 48.657534, lng: 19.380237 },
    { lat: 48.657564, lng: 19.380011 },
    { lat: 48.657706, lng: 19.379608 },
    { lat: 48.657876, lng: 19.379332 },
    { lat: 48.657953, lng: 19.378976 },
    { lat: 48.658088, lng: 19.378463 },
    { lat: 48.658217, lng: 19.377951 },
    { lat: 48.658337, lng: 19.377709 },
    { lat: 48.658668, lng: 19.377237 },
    { lat: 48.658876, lng: 19.376871 },
    { lat: 48.658883, lng: 19.376344 },
    { lat: 48.658631, lng: 19.375371 },
    { lat: 48.658434, lng: 19.374996 },
    { lat: 48.658155, lng: 19.374103 },
    { lat: 48.658043, lng: 19.373767 },
    { lat: 48.657978, lng: 19.373506 },
    { lat: 48.657977, lng: 19.373102 },
    { lat: 48.657885, lng: 19.372658 },
    { lat: 48.657718, lng: 19.372246 },
    { lat: 48.657571, lng: 19.371911 },
    { lat: 48.657527, lng: 19.371692 },
    { lat: 48.657459, lng: 19.371536 },
    { lat: 48.657433, lng: 19.371308 },
    { lat: 48.657495, lng: 19.370758 },
    { lat: 48.65743, lng: 19.370305 },
    { lat: 48.657491, lng: 19.369889 },
    { lat: 48.65753, lng: 19.369664 },
    { lat: 48.657538, lng: 19.369405 },
    { lat: 48.657476, lng: 19.369157 },
    { lat: 48.657337, lng: 19.368826 },
    { lat: 48.65726, lng: 19.368636 },
    { lat: 48.657225, lng: 19.36831 },
    { lat: 48.657292, lng: 19.367978 },
    { lat: 48.657399, lng: 19.367655 },
    { lat: 48.657357, lng: 19.367299 },
    { lat: 48.657247, lng: 19.36702 },
    { lat: 48.657014, lng: 19.366517 },
    { lat: 48.656947, lng: 19.366105 },
    { lat: 48.656856, lng: 19.365815 },
    { lat: 48.656654, lng: 19.365502 },
    { lat: 48.656548, lng: 19.365186 },
    { lat: 48.656488, lng: 19.364798 },
    { lat: 48.656452, lng: 19.364363 },
    { lat: 48.656267, lng: 19.36385 },
    { lat: 48.656227, lng: 19.363592 },
    { lat: 48.656182, lng: 19.363175 },
    { lat: 48.656045, lng: 19.36283 },
    { lat: 48.656023, lng: 19.362648 },
    { lat: 48.655958, lng: 19.361975 },
    { lat: 48.655857, lng: 19.361694 },
    { lat: 48.655824, lng: 19.361393 },
    { lat: 48.655802, lng: 19.361221 },
    { lat: 48.655528, lng: 19.360436 },
    { lat: 48.655481, lng: 19.360111 },
    { lat: 48.655351, lng: 19.359797 },
    { lat: 48.655141, lng: 19.359543 },
    { lat: 48.655065, lng: 19.359227 },
    { lat: 48.654999, lng: 19.358744 },
    { lat: 48.654861, lng: 19.357967 },
    { lat: 48.654767, lng: 19.357634 },
    { lat: 48.654653, lng: 19.357333 },
    { lat: 48.654545, lng: 19.356912 },
    { lat: 48.654413, lng: 19.3565 },
    { lat: 48.654275, lng: 19.356175 },
    { lat: 48.654084, lng: 19.355868 },
    { lat: 48.653934, lng: 19.355668 },
    { lat: 48.653703, lng: 19.355214 },
    { lat: 48.653374, lng: 19.354748 },
    { lat: 48.653129, lng: 19.354582 },
    { lat: 48.65296, lng: 19.354167 },
    { lat: 48.652864, lng: 19.353837 },
    { lat: 48.652812, lng: 19.353444 },
    { lat: 48.652692, lng: 19.352912 },
    { lat: 48.65186, lng: 19.351914 },
    { lat: 48.651769, lng: 19.35172 },
    { lat: 48.651744, lng: 19.351468 },
    { lat: 48.651818, lng: 19.350748 },
    { lat: 48.651817, lng: 19.350306 },
    { lat: 48.651776, lng: 19.349675 },
    { lat: 48.651637, lng: 19.349173 },
    { lat: 48.651588, lng: 19.348795 },
    { lat: 48.651433, lng: 19.347819 },
    { lat: 48.651339, lng: 19.347382 },
    { lat: 48.651182, lng: 19.346739 },
    { lat: 48.65108, lng: 19.346094 },
    { lat: 48.651013, lng: 19.345861 },
    { lat: 48.650991, lng: 19.345261 },
    { lat: 48.651043, lng: 19.344579 },
    { lat: 48.650929, lng: 19.344121 },
    { lat: 48.650952, lng: 19.34362 },
    { lat: 48.651018, lng: 19.342961 },
    { lat: 48.650973, lng: 19.342597 },
    { lat: 48.650906, lng: 19.34223 },
    { lat: 48.650772, lng: 19.341696 },
    { lat: 48.650754, lng: 19.341573 },
    { lat: 48.650709, lng: 19.340895 },
    { lat: 48.650422, lng: 19.339651 },
    { lat: 48.650433, lng: 19.339088 },
    { lat: 48.650278, lng: 19.337968 },
    { lat: 48.650319, lng: 19.33743 },
    { lat: 48.650317, lng: 19.337345 },
    { lat: 48.650283, lng: 19.336755 },
    { lat: 48.650194, lng: 19.336219 },
    { lat: 48.650157, lng: 19.335936 },
    { lat: 48.650159, lng: 19.335385 },
    { lat: 48.650099, lng: 19.334716 },
    { lat: 48.650087, lng: 19.333939 },
    { lat: 48.650083, lng: 19.333128 },
    { lat: 48.649984, lng: 19.332675 },
    { lat: 48.649812, lng: 19.332419 },
    { lat: 48.649743, lng: 19.331969 },
    { lat: 48.649632, lng: 19.331586 },
    { lat: 48.649596, lng: 19.331343 },
    { lat: 48.649564, lng: 19.331156 },
    { lat: 48.649555, lng: 19.330823 },
    { lat: 48.649512, lng: 19.330345 },
    { lat: 48.649304, lng: 19.329948 },
    { lat: 48.649172, lng: 19.329368 },
    { lat: 48.649125, lng: 19.329233 },
    { lat: 48.649093, lng: 19.328583 },
    { lat: 48.649137, lng: 19.327154 },
    { lat: 48.649008, lng: 19.326876 },
    { lat: 48.648842, lng: 19.326647 },
    { lat: 48.648876, lng: 19.325812 },
    { lat: 48.648734, lng: 19.325372 },
    { lat: 48.648285, lng: 19.324701 },
    { lat: 48.64777, lng: 19.323118 },
    { lat: 48.647491, lng: 19.322754 },
    { lat: 48.647484, lng: 19.321913 },
    { lat: 48.647213, lng: 19.321462 },
    { lat: 48.646912, lng: 19.321064 },
    { lat: 48.646642, lng: 19.320132 },
    { lat: 48.646439, lng: 19.319843 },
    { lat: 48.646171, lng: 19.319905 },
    { lat: 48.645803, lng: 19.319746 },
    { lat: 48.645052, lng: 19.318664 },
    { lat: 48.644991, lng: 19.318515 },
    { lat: 48.644747, lng: 19.317494 },
    { lat: 48.644092, lng: 19.316186 },
    { lat: 48.644046, lng: 19.316094 },
    { lat: 48.643695, lng: 19.315931 },
    { lat: 48.643407, lng: 19.315565 },
    { lat: 48.643231, lng: 19.314999 },
    { lat: 48.643108, lng: 19.314491 },
    { lat: 48.643049, lng: 19.314385 },
    { lat: 48.642804, lng: 19.314045 },
    { lat: 48.642282, lng: 19.313701 },
    { lat: 48.64228, lng: 19.313671 },
    { lat: 48.642267, lng: 19.313557 },
    { lat: 48.642236, lng: 19.313413 },
    { lat: 48.642195, lng: 19.313272 },
    { lat: 48.642119, lng: 19.31308 },
    { lat: 48.641977, lng: 19.312862 },
    { lat: 48.641823, lng: 19.312692 },
    { lat: 48.641624, lng: 19.312396 },
    { lat: 48.641394, lng: 19.312021 },
    { lat: 48.641045, lng: 19.311413 },
    { lat: 48.640489, lng: 19.310644 },
    { lat: 48.640202, lng: 19.310224 },
    { lat: 48.639948, lng: 19.309839 },
    { lat: 48.639337, lng: 19.309007 },
    { lat: 48.639126, lng: 19.308781 },
    { lat: 48.638399, lng: 19.307797 },
    { lat: 48.638165, lng: 19.307526 },
    { lat: 48.638405, lng: 19.30687 },
    { lat: 48.638631, lng: 19.306152 },
    { lat: 48.63614, lng: 19.302856 },
    { lat: 48.635888, lng: 19.302108 },
    { lat: 48.635823, lng: 19.301711 },
    { lat: 48.635654, lng: 19.299692 },
    { lat: 48.635859, lng: 19.298486 },
    { lat: 48.635921, lng: 19.298118 },
    { lat: 48.635958, lng: 19.297654 },
    { lat: 48.636065, lng: 19.296781 },
    { lat: 48.636162, lng: 19.296273 },
    { lat: 48.636296, lng: 19.295567 },
    { lat: 48.636358, lng: 19.295243 },
    { lat: 48.636377, lng: 19.295144 },
    { lat: 48.63639, lng: 19.295079 },
    { lat: 48.636399, lng: 19.295029 },
    { lat: 48.636416, lng: 19.294941 },
    { lat: 48.636445, lng: 19.294931 },
    { lat: 48.636531, lng: 19.294901 },
    { lat: 48.636586, lng: 19.294258 },
    { lat: 48.636628, lng: 19.293753 },
    { lat: 48.636702, lng: 19.293477 },
    { lat: 48.63768, lng: 19.291273 },
    { lat: 48.638454, lng: 19.289146 },
    { lat: 48.638823, lng: 19.288689 },
    { lat: 48.63893, lng: 19.288617 },
    { lat: 48.639245, lng: 19.288401 },
    { lat: 48.640044, lng: 19.28749 },
    { lat: 48.640078, lng: 19.287451 },
    { lat: 48.640303, lng: 19.287394 },
    { lat: 48.641039, lng: 19.287531 },
    { lat: 48.641356, lng: 19.287521 },
    { lat: 48.641369, lng: 19.287502 },
    { lat: 48.641648, lng: 19.287244 },
    { lat: 48.641783, lng: 19.286814 },
    { lat: 48.641928, lng: 19.286023 },
    { lat: 48.642053, lng: 19.285573 },
    { lat: 48.642612, lng: 19.283812 },
    { lat: 48.642662, lng: 19.283487 },
    { lat: 48.642527, lng: 19.282839 },
    { lat: 48.642496, lng: 19.28238 },
    { lat: 48.642638, lng: 19.282217 },
    { lat: 48.642866, lng: 19.282117 },
    { lat: 48.643148, lng: 19.282107 },
    { lat: 48.643273, lng: 19.282369 },
    { lat: 48.643418, lng: 19.282846 },
    { lat: 48.643522, lng: 19.283324 },
    { lat: 48.644365, lng: 19.282955 },
    { lat: 48.644181, lng: 19.282395 },
    { lat: 48.64411, lng: 19.281869 },
    { lat: 48.643852, lng: 19.281474 },
    { lat: 48.643341, lng: 19.281025 },
    { lat: 48.642756, lng: 19.280706 },
    { lat: 48.642239, lng: 19.280301 },
    { lat: 48.642084, lng: 19.280121 },
    { lat: 48.642014, lng: 19.280036 },
    { lat: 48.641819, lng: 19.279796 },
    { lat: 48.641473, lng: 19.279406 },
    { lat: 48.641039, lng: 19.278861 },
    { lat: 48.640865, lng: 19.278282 },
    { lat: 48.640629, lng: 19.277582 },
    { lat: 48.640438, lng: 19.277001 },
    { lat: 48.640319, lng: 19.276587 },
    { lat: 48.639903, lng: 19.276315 },
    { lat: 48.639581, lng: 19.275967 },
    { lat: 48.639562, lng: 19.275617 },
    { lat: 48.639584, lng: 19.275177 },
    { lat: 48.639449, lng: 19.274823 },
    { lat: 48.639337, lng: 19.274594 },
    { lat: 48.639144, lng: 19.27445 },
    { lat: 48.638931, lng: 19.274506 },
    { lat: 48.638622, lng: 19.2738 },
    { lat: 48.638441, lng: 19.27369 },
    { lat: 48.638259, lng: 19.273036 },
    { lat: 48.638172, lng: 19.272883 },
    { lat: 48.637267, lng: 19.271171 },
    { lat: 48.6367012, lng: 19.2714747 },
    { lat: 48.6365464, lng: 19.2716289 },
    { lat: 48.6357637, lng: 19.2729336 },
    { lat: 48.6348775, lng: 19.2745643 },
    { lat: 48.6353863, lng: 19.2753438 },
    { lat: 48.6340814, lng: 19.2795615 },
    { lat: 48.6340333, lng: 19.2792211 },
    { lat: 48.6339355, lng: 19.2790387 },
    { lat: 48.6335496, lng: 19.2787706 },
    { lat: 48.6333274, lng: 19.278511 },
    { lat: 48.631569, lng: 19.2754187 },
    { lat: 48.6309565, lng: 19.2748737 },
    { lat: 48.6308495, lng: 19.2740556 },
    { lat: 48.6305182, lng: 19.2730785 },
    { lat: 48.6299396, lng: 19.2719244 },
    { lat: 48.6294676, lng: 19.2707366 },
    { lat: 48.6290942, lng: 19.2692583 },
    { lat: 48.6271605, lng: 19.2640882 },
    { lat: 48.6262955, lng: 19.2615349 },
    { lat: 48.6241108, lng: 19.2597603 },
    { lat: 48.6216276, lng: 19.2565153 },
    { lat: 48.6214862, lng: 19.2565504 },
    { lat: 48.6200792, lng: 19.2589686 },
    { lat: 48.61769, lng: 19.2557652 },
    { lat: 48.6175068, lng: 19.2555859 },
    { lat: 48.6164152, lng: 19.2563455 },
    { lat: 48.6152312, lng: 19.2565947 },
    { lat: 48.6151846, lng: 19.2566266 },
    { lat: 48.6143932, lng: 19.2571672 },
    { lat: 48.6140506, lng: 19.25756 },
    { lat: 48.613749, lng: 19.2576648 },
    { lat: 48.6124934, lng: 19.2571322 },
    { lat: 48.6116039, lng: 19.2569415 },
    { lat: 48.6108507, lng: 19.2564691 },
    { lat: 48.6106868, lng: 19.2562741 },
    { lat: 48.6107068, lng: 19.2560824 },
    { lat: 48.6106266, lng: 19.2557529 },
    { lat: 48.610541, lng: 19.2557758 },
    { lat: 48.6104196, lng: 19.2559954 },
    { lat: 48.6097743, lng: 19.2554488 },
    { lat: 48.6092242, lng: 19.2552333 },
    { lat: 48.6083461, lng: 19.2541631 },
    { lat: 48.6079084, lng: 19.2543417 },
    { lat: 48.6071722, lng: 19.2544548 },
    { lat: 48.6068208, lng: 19.2540913 },
    { lat: 48.6065739, lng: 19.2542072 },
    { lat: 48.6055721, lng: 19.253956 },
    { lat: 48.6054405, lng: 19.2536823 },
    { lat: 48.6050462, lng: 19.2535683 },
    { lat: 48.6047299, lng: 19.253856 },
    { lat: 48.6042094, lng: 19.2536084 },
    { lat: 48.6037572, lng: 19.2537679 },
    { lat: 48.6037687, lng: 19.2525358 },
    { lat: 48.6040323, lng: 19.2517895 },
    { lat: 48.6030545, lng: 19.2511474 },
    { lat: 48.602828, lng: 19.2501036 },
    { lat: 48.6026814, lng: 19.2500339 },
    { lat: 48.6027696, lng: 19.2495035 },
    { lat: 48.6021091, lng: 19.2494345 },
    { lat: 48.6021962, lng: 19.2480916 },
    { lat: 48.6015129, lng: 19.2479218 },
    { lat: 48.6014019, lng: 19.2481228 },
    { lat: 48.6013924, lng: 19.2489501 },
    { lat: 48.5999193, lng: 19.2490006 },
    { lat: 48.5996798, lng: 19.2489183 },
    { lat: 48.5993663, lng: 19.24859 },
    { lat: 48.5987934, lng: 19.2468871 },
    { lat: 48.5979284, lng: 19.2466676 },
    { lat: 48.5974313, lng: 19.2459244 },
    { lat: 48.5972729, lng: 19.2454437 },
    { lat: 48.5971206, lng: 19.244447 },
    { lat: 48.5944899, lng: 19.2457931 },
    { lat: 48.5943815, lng: 19.2459304 },
    { lat: 48.5917613, lng: 19.2467526 },
    { lat: 48.5915211, lng: 19.2467057 },
    { lat: 48.5912412, lng: 19.246305 },
    { lat: 48.5909993, lng: 19.245778 },
    { lat: 48.5909717, lng: 19.2449753 },
    { lat: 48.5910257, lng: 19.2444872 },
    { lat: 48.5908298, lng: 19.2439797 },
    { lat: 48.590548, lng: 19.2436768 },
    { lat: 48.5904148, lng: 19.2433949 },
    { lat: 48.590304, lng: 19.2426202 },
    { lat: 48.5903733, lng: 19.241893 },
    { lat: 48.5903319, lng: 19.2416615 },
    { lat: 48.5902077, lng: 19.2414284 },
    { lat: 48.5900845, lng: 19.241421 },
    { lat: 48.5898271, lng: 19.2418991 },
    { lat: 48.5897615, lng: 19.241478 },
    { lat: 48.5896506, lng: 19.2412132 },
    { lat: 48.5895247, lng: 19.241106 },
    { lat: 48.5894801, lng: 19.2409069 },
    { lat: 48.5897752, lng: 19.240597 },
    { lat: 48.5897964, lng: 19.2402696 },
    { lat: 48.5897094, lng: 19.2399869 },
    { lat: 48.5897287, lng: 19.2394542 },
    { lat: 48.5898296, lng: 19.2390261 },
    { lat: 48.5898188, lng: 19.2388051 },
    { lat: 48.5897242, lng: 19.2386657 },
    { lat: 48.5893237, lng: 19.238707 },
    { lat: 48.589072, lng: 19.2382317 },
    { lat: 48.589089, lng: 19.2375861 },
    { lat: 48.5889482, lng: 19.237424 },
    { lat: 48.5889153, lng: 19.2371887 },
    { lat: 48.5889945, lng: 19.2370112 },
    { lat: 48.5889126, lng: 19.2366474 },
    { lat: 48.5887056, lng: 19.2360276 },
    { lat: 48.5885582, lng: 19.2359164 },
    { lat: 48.5884692, lng: 19.2354993 },
    { lat: 48.5883062, lng: 19.2353237 },
    { lat: 48.5882068, lng: 19.2350669 },
    { lat: 48.5883184, lng: 19.2345095 },
    { lat: 48.5882788, lng: 19.2341809 },
    { lat: 48.5880551, lng: 19.2338457 },
    { lat: 48.5879647, lng: 19.2331233 },
    { lat: 48.5877149, lng: 19.2323914 },
    { lat: 48.5876777, lng: 19.2324144 },
    { lat: 48.587611, lng: 19.232431 },
    { lat: 48.5870823, lng: 19.2330801 },
    { lat: 48.5861208, lng: 19.2344046 },
    { lat: 48.5862155, lng: 19.2344971 },
    { lat: 48.5862644, lng: 19.2381916 },
    { lat: 48.5862355, lng: 19.2389442 },
    { lat: 48.5873222, lng: 19.2408498 },
    { lat: 48.5880922, lng: 19.2414809 },
    { lat: 48.5885244, lng: 19.2423279 },
    { lat: 48.5888037, lng: 19.2430648 },
    { lat: 48.5890999, lng: 19.2434982 },
    { lat: 48.5895478, lng: 19.2457382 },
    { lat: 48.5894769, lng: 19.2466725 },
    { lat: 48.5895864, lng: 19.2473248 },
    { lat: 48.5844236, lng: 19.2479694 },
    { lat: 48.583886, lng: 19.2534881 },
    { lat: 48.5833441, lng: 19.2619679 },
    { lat: 48.5809853, lng: 19.2721723 },
    { lat: 48.5787153, lng: 19.2823843 },
    { lat: 48.5787517, lng: 19.2824159 },
    { lat: 48.5787108, lng: 19.2824582 },
    { lat: 48.5787503, lng: 19.2825275 },
    { lat: 48.5787171, lng: 19.2827515 },
    { lat: 48.5752452, lng: 19.2905386 },
    { lat: 48.5748672, lng: 19.2917751 },
    { lat: 48.5747293, lng: 19.2924928 },
    { lat: 48.57447, lng: 19.294587 },
  ],
  Bacúrov: [
    { lat: 48.523243, lng: 19.013419 },
    { lat: 48.523178, lng: 19.013516 },
    { lat: 48.521769, lng: 19.013251 },
    { lat: 48.521057, lng: 19.012626 },
    { lat: 48.521041, lng: 19.012415 },
    { lat: 48.52077, lng: 19.011506 },
    { lat: 48.520784, lng: 19.011078 },
    { lat: 48.520803, lng: 19.010048 },
    { lat: 48.520689, lng: 19.0097 },
    { lat: 48.520633, lng: 19.00921 },
    { lat: 48.520209, lng: 19.008101 },
    { lat: 48.519892, lng: 19.007447 },
    { lat: 48.5196126, lng: 19.0068485 },
    { lat: 48.5191707, lng: 19.0067613 },
    { lat: 48.519302, lng: 19.007172 },
    { lat: 48.519275, lng: 19.007494 },
    { lat: 48.519176, lng: 19.008138 },
    { lat: 48.518695, lng: 19.009411 },
    { lat: 48.518479, lng: 19.010111 },
    { lat: 48.518403, lng: 19.010485 },
    { lat: 48.518245, lng: 19.010715 },
    { lat: 48.5181856, lng: 19.0110386 },
    { lat: 48.5177263, lng: 19.0116774 },
    { lat: 48.5176277, lng: 19.0120247 },
    { lat: 48.517308, lng: 19.0124778 },
    { lat: 48.516731, lng: 19.0138207 },
    { lat: 48.5164657, lng: 19.0138113 },
    { lat: 48.5157426, lng: 19.014807 },
    { lat: 48.5157379, lng: 19.0148922 },
    { lat: 48.5155031, lng: 19.0149771 },
    { lat: 48.5154035, lng: 19.0154607 },
    { lat: 48.515313, lng: 19.0154978 },
    { lat: 48.5152842, lng: 19.015643 },
    { lat: 48.5154482, lng: 19.0164784 },
    { lat: 48.515389, lng: 19.0173123 },
    { lat: 48.5152455, lng: 19.0178209 },
    { lat: 48.5151256, lng: 19.018659 },
    { lat: 48.5147026, lng: 19.0194549 },
    { lat: 48.5138038, lng: 19.0216609 },
    { lat: 48.5136829, lng: 19.0222131 },
    { lat: 48.5136282, lng: 19.0231834 },
    { lat: 48.513332, lng: 19.0243499 },
    { lat: 48.5131002, lng: 19.0263457 },
    { lat: 48.513199, lng: 19.0267394 },
    { lat: 48.5133805, lng: 19.0290617 },
    { lat: 48.5134251, lng: 19.0293887 },
    { lat: 48.513559, lng: 19.029851 },
    { lat: 48.5135707, lng: 19.0305516 },
    { lat: 48.5133466, lng: 19.0314054 },
    { lat: 48.5130789, lng: 19.0318873 },
    { lat: 48.5128129, lng: 19.0322484 },
    { lat: 48.5127272, lng: 19.0325593 },
    { lat: 48.5131073, lng: 19.0341079 },
    { lat: 48.5141619, lng: 19.0359913 },
    { lat: 48.5139, lng: 19.0370154 },
    { lat: 48.513676, lng: 19.0376473 },
    { lat: 48.5137489, lng: 19.0377994 },
    { lat: 48.5141918, lng: 19.0380411 },
    { lat: 48.5141259, lng: 19.0393508 },
    { lat: 48.5142307, lng: 19.040155 },
    { lat: 48.5141837, lng: 19.0407883 },
    { lat: 48.5134297, lng: 19.042493 },
    { lat: 48.5133821, lng: 19.0434291 },
    { lat: 48.5134673, lng: 19.0441359 },
    { lat: 48.5135738, lng: 19.0443524 },
    { lat: 48.5134842, lng: 19.044608 },
    { lat: 48.5132913, lng: 19.0456331 },
    { lat: 48.513047, lng: 19.0463376 },
    { lat: 48.512525, lng: 19.0483973 },
    { lat: 48.5121342, lng: 19.0493919 },
    { lat: 48.5120645, lng: 19.0499358 },
    { lat: 48.5119631, lng: 19.0500241 },
    { lat: 48.5118766, lng: 19.0513105 },
    { lat: 48.5119933, lng: 19.0519262 },
    { lat: 48.5106248, lng: 19.0529148 },
    { lat: 48.5100677, lng: 19.0540812 },
    { lat: 48.5094959, lng: 19.054638 },
    { lat: 48.5089974, lng: 19.0549013 },
    { lat: 48.5090784, lng: 19.0565835 },
    { lat: 48.5091592, lng: 19.0571336 },
    { lat: 48.5063873, lng: 19.0581312 },
    { lat: 48.5053094, lng: 19.0586221 },
    { lat: 48.5053136, lng: 19.0587073 },
    { lat: 48.50526, lng: 19.0587639 },
    { lat: 48.5056084, lng: 19.0610053 },
    { lat: 48.5055131, lng: 19.0624543 },
    { lat: 48.5041882, lng: 19.0630137 },
    { lat: 48.5032296, lng: 19.0631653 },
    { lat: 48.502564, lng: 19.0634279 },
    { lat: 48.5019411, lng: 19.0646064 },
    { lat: 48.5013967, lng: 19.0664038 },
    { lat: 48.5016482, lng: 19.0673727 },
    { lat: 48.5024288, lng: 19.0686866 },
    { lat: 48.5030026, lng: 19.0701484 },
    { lat: 48.502368, lng: 19.0701749 },
    { lat: 48.5020731, lng: 19.0703623 },
    { lat: 48.5015955, lng: 19.0706664 },
    { lat: 48.5015884, lng: 19.070905 },
    { lat: 48.5017104, lng: 19.0711571 },
    { lat: 48.50261, lng: 19.0723914 },
    { lat: 48.5035024, lng: 19.073442 },
    { lat: 48.5037632, lng: 19.0735831 },
    { lat: 48.5044991, lng: 19.0735106 },
    { lat: 48.5053309, lng: 19.0733281 },
    { lat: 48.505753, lng: 19.0734491 },
    { lat: 48.5057601, lng: 19.0739493 },
    { lat: 48.505859, lng: 19.0741869 },
    { lat: 48.5058458, lng: 19.0744892 },
    { lat: 48.5060406, lng: 19.0747995 },
    { lat: 48.506469, lng: 19.0751587 },
    { lat: 48.5064623, lng: 19.0756117 },
    { lat: 48.5074238, lng: 19.075652 },
    { lat: 48.5074188, lng: 19.0755289 },
    { lat: 48.5091436, lng: 19.0742688 },
    { lat: 48.5101824, lng: 19.0741862 },
    { lat: 48.5107964, lng: 19.0737748 },
    { lat: 48.5109298, lng: 19.0741412 },
    { lat: 48.5113888, lng: 19.0740753 },
    { lat: 48.5116255, lng: 19.0749093 },
    { lat: 48.5120173, lng: 19.0747845 },
    { lat: 48.5124026, lng: 19.0745516 },
    { lat: 48.5130814, lng: 19.0739039 },
    { lat: 48.5134499, lng: 19.074238 },
    { lat: 48.5136504, lng: 19.0745516 },
    { lat: 48.5140744, lng: 19.0743243 },
    { lat: 48.5145693, lng: 19.0742344 },
    { lat: 48.5148522, lng: 19.0738962 },
    { lat: 48.5151087, lng: 19.0739755 },
    { lat: 48.5152099, lng: 19.0745102 },
    { lat: 48.5153848, lng: 19.0749893 },
    { lat: 48.5157226, lng: 19.0748418 },
    { lat: 48.5158614, lng: 19.0748439 },
    { lat: 48.5158242, lng: 19.0744875 },
    { lat: 48.5161768, lng: 19.0744141 },
    { lat: 48.5171519, lng: 19.0743412 },
    { lat: 48.5181912, lng: 19.0750181 },
    { lat: 48.5187116, lng: 19.0747581 },
    { lat: 48.5201737, lng: 19.0744877 },
    { lat: 48.5201722, lng: 19.0760349 },
    { lat: 48.5221586, lng: 19.0768662 },
    { lat: 48.52222, lng: 19.0770314 },
    { lat: 48.5223697, lng: 19.0780198 },
    { lat: 48.5223133, lng: 19.0786507 },
    { lat: 48.5222909, lng: 19.0789015 },
    { lat: 48.5223954, lng: 19.0787555 },
    { lat: 48.5231196, lng: 19.078183 },
    { lat: 48.5238731, lng: 19.0774663 },
    { lat: 48.5243126, lng: 19.077308 },
    { lat: 48.5246721, lng: 19.0774639 },
    { lat: 48.5252509, lng: 19.0774491 },
    { lat: 48.5266104, lng: 19.0767007 },
    { lat: 48.5273803, lng: 19.075865 },
    { lat: 48.5281621, lng: 19.074843 },
    { lat: 48.5292027, lng: 19.0740538 },
    { lat: 48.5301372, lng: 19.0736219 },
    { lat: 48.5308295, lng: 19.0731293 },
    { lat: 48.531242, lng: 19.0731714 },
    { lat: 48.5314782, lng: 19.0730724 },
    { lat: 48.5318038, lng: 19.0727292 },
    { lat: 48.530647, lng: 19.067684 },
    { lat: 48.530753, lng: 19.0672814 },
    { lat: 48.5307792, lng: 19.0669935 },
    { lat: 48.5306285, lng: 19.0659439 },
    { lat: 48.5305219, lng: 19.06557 },
    { lat: 48.5304206, lng: 19.0655289 },
    { lat: 48.5304088, lng: 19.0651141 },
    { lat: 48.5304861, lng: 19.0648805 },
    { lat: 48.5304463, lng: 19.063639 },
    { lat: 48.5303875, lng: 19.063253 },
    { lat: 48.5305015, lng: 19.0614106 },
    { lat: 48.5303943, lng: 19.0612973 },
    { lat: 48.5303651, lng: 19.0609837 },
    { lat: 48.5306366, lng: 19.0592536 },
    { lat: 48.5305636, lng: 19.0587173 },
    { lat: 48.5307271, lng: 19.0583117 },
    { lat: 48.5312885, lng: 19.0578192 },
    { lat: 48.5315391, lng: 19.0572208 },
    { lat: 48.5313836, lng: 19.0549527 },
    { lat: 48.5309613, lng: 19.052383 },
    { lat: 48.5299629, lng: 19.0505727 },
    { lat: 48.5297361, lng: 19.0495624 },
    { lat: 48.5296252, lng: 19.0483848 },
    { lat: 48.5293636, lng: 19.0471996 },
    { lat: 48.5291754, lng: 19.0467757 },
    { lat: 48.5286984, lng: 19.0454044 },
    { lat: 48.5285451, lng: 19.0446013 },
    { lat: 48.5285605, lng: 19.0438095 },
    { lat: 48.5284764, lng: 19.0433681 },
    { lat: 48.5283917, lng: 19.042136 },
    { lat: 48.5283571, lng: 19.0404628 },
    { lat: 48.5283935, lng: 19.0398212 },
    { lat: 48.5290476, lng: 19.0377656 },
    { lat: 48.5295772, lng: 19.036728 },
    { lat: 48.5298205, lng: 19.0349128 },
    { lat: 48.5297248, lng: 19.0329018 },
    { lat: 48.5296351, lng: 19.0320171 },
    { lat: 48.5290574, lng: 19.0299325 },
    { lat: 48.52909, lng: 19.0284757 },
    { lat: 48.5281548, lng: 19.0263204 },
    { lat: 48.526527, lng: 19.0218816 },
    { lat: 48.5234806, lng: 19.0138998 },
    { lat: 48.523243, lng: 19.013419 },
  ],
  Michalková: [
    { lat: 48.5325731, lng: 19.1202849 },
    { lat: 48.532438, lng: 19.1205605 },
    { lat: 48.5321712, lng: 19.1205919 },
    { lat: 48.5320382, lng: 19.1206823 },
    { lat: 48.5316482, lng: 19.1206405 },
    { lat: 48.5311375, lng: 19.1207979 },
    { lat: 48.5308411, lng: 19.12071 },
    { lat: 48.5307518, lng: 19.1208178 },
    { lat: 48.5301939, lng: 19.1203443 },
    { lat: 48.5298385, lng: 19.1203622 },
    { lat: 48.5296596, lng: 19.1204371 },
    { lat: 48.5293665, lng: 19.1207514 },
    { lat: 48.5291512, lng: 19.1208219 },
    { lat: 48.5290897, lng: 19.1207865 },
    { lat: 48.5289553, lng: 19.1209855 },
    { lat: 48.5288605, lng: 19.1210275 },
    { lat: 48.5286865, lng: 19.1209069 },
    { lat: 48.5285895, lng: 19.1206802 },
    { lat: 48.528589, lng: 19.120532 },
    { lat: 48.5284517, lng: 19.1206075 },
    { lat: 48.5280426, lng: 19.1205177 },
    { lat: 48.5280164, lng: 19.1203318 },
    { lat: 48.5280623, lng: 19.1202107 },
    { lat: 48.5280468, lng: 19.1200602 },
    { lat: 48.5281398, lng: 19.119911 },
    { lat: 48.5280777, lng: 19.119864 },
    { lat: 48.5288977, lng: 19.1190561 },
    { lat: 48.5291195, lng: 19.1186601 },
    { lat: 48.5293213, lng: 19.1178663 },
    { lat: 48.5294288, lng: 19.1170123 },
    { lat: 48.5287706, lng: 19.1165153 },
    { lat: 48.5286189, lng: 19.1168005 },
    { lat: 48.5282076, lng: 19.1162813 },
    { lat: 48.5281627, lng: 19.1162871 },
    { lat: 48.5274434, lng: 19.1152175 },
    { lat: 48.5272557, lng: 19.1150722 },
    { lat: 48.5268363, lng: 19.1148908 },
    { lat: 48.5266903, lng: 19.1151046 },
    { lat: 48.5267725, lng: 19.1158494 },
    { lat: 48.5265312, lng: 19.1156678 },
    { lat: 48.526584, lng: 19.1161405 },
    { lat: 48.5259151, lng: 19.1167465 },
    { lat: 48.5257877, lng: 19.117406 },
    { lat: 48.5259207, lng: 19.1174728 },
    { lat: 48.5258763, lng: 19.1184142 },
    { lat: 48.5257648, lng: 19.1188465 },
    { lat: 48.5259169, lng: 19.1190502 },
    { lat: 48.526044, lng: 19.1197031 },
    { lat: 48.5257991, lng: 19.1195621 },
    { lat: 48.5257119, lng: 19.1196466 },
    { lat: 48.5254678, lng: 19.1197021 },
    { lat: 48.5253766, lng: 19.1196352 },
    { lat: 48.5253607, lng: 19.119519 },
    { lat: 48.5252687, lng: 19.1194375 },
    { lat: 48.5248739, lng: 19.1193219 },
    { lat: 48.524805, lng: 19.1194083 },
    { lat: 48.5247022, lng: 19.1192893 },
    { lat: 48.5246179, lng: 19.1193668 },
    { lat: 48.5244794, lng: 19.1193454 },
    { lat: 48.5244171, lng: 19.1195426 },
    { lat: 48.5242065, lng: 19.1196403 },
    { lat: 48.5240485, lng: 19.1194547 },
    { lat: 48.5236334, lng: 19.1193784 },
    { lat: 48.5234878, lng: 19.1195562 },
    { lat: 48.5234468, lng: 19.1197543 },
    { lat: 48.5232067, lng: 19.1199147 },
    { lat: 48.5224647, lng: 19.1208186 },
    { lat: 48.5222537, lng: 19.1208132 },
    { lat: 48.5221923, lng: 19.1209847 },
    { lat: 48.5221077, lng: 19.120937 },
    { lat: 48.5219811, lng: 19.1210455 },
    { lat: 48.5218829, lng: 19.1212006 },
    { lat: 48.5217642, lng: 19.1212648 },
    { lat: 48.5216639, lng: 19.1215566 },
    { lat: 48.5214401, lng: 19.1216597 },
    { lat: 48.5213923, lng: 19.121605 },
    { lat: 48.5212165, lng: 19.1216525 },
    { lat: 48.5210625, lng: 19.1218419 },
    { lat: 48.5209718, lng: 19.121877 },
    { lat: 48.5208266, lng: 19.122244 },
    { lat: 48.5204557, lng: 19.1221296 },
    { lat: 48.5203858, lng: 19.1222218 },
    { lat: 48.5201418, lng: 19.1222841 },
    { lat: 48.5195947, lng: 19.1222268 },
    { lat: 48.5194348, lng: 19.1222989 },
    { lat: 48.51938, lng: 19.1222414 },
    { lat: 48.5192005, lng: 19.1222804 },
    { lat: 48.5191044, lng: 19.1225046 },
    { lat: 48.5189921, lng: 19.1225556 },
    { lat: 48.5189496, lng: 19.1226792 },
    { lat: 48.5188093, lng: 19.1226662 },
    { lat: 48.5180704, lng: 19.1231145 },
    { lat: 48.5176892, lng: 19.1235624 },
    { lat: 48.5175928, lng: 19.1238239 },
    { lat: 48.5173917, lng: 19.1241331 },
    { lat: 48.5171341, lng: 19.1249646 },
    { lat: 48.5172085, lng: 19.1249872 },
    { lat: 48.5171809, lng: 19.1253359 },
    { lat: 48.5171024, lng: 19.1254397 },
    { lat: 48.5169864, lng: 19.125824 },
    { lat: 48.5169868, lng: 19.1260373 },
    { lat: 48.516917, lng: 19.1261962 },
    { lat: 48.516905, lng: 19.1267064 },
    { lat: 48.5169389, lng: 19.1267531 },
    { lat: 48.5168323, lng: 19.1272419 },
    { lat: 48.5168259, lng: 19.1275659 },
    { lat: 48.5168818, lng: 19.127897 },
    { lat: 48.5168097, lng: 19.128057 },
    { lat: 48.5169597, lng: 19.1303116 },
    { lat: 48.5156739, lng: 19.1309794 },
    { lat: 48.515274, lng: 19.1322809 },
    { lat: 48.5151512, lng: 19.132491 },
    { lat: 48.5149905, lng: 19.1331384 },
    { lat: 48.5146955, lng: 19.1332341 },
    { lat: 48.5145695, lng: 19.1333997 },
    { lat: 48.514548, lng: 19.1336236 },
    { lat: 48.5143769, lng: 19.13388 },
    { lat: 48.5141869, lng: 19.1340251 },
    { lat: 48.5141703, lng: 19.1342829 },
    { lat: 48.5139853, lng: 19.134324 },
    { lat: 48.5139343, lng: 19.1342298 },
    { lat: 48.5139473, lng: 19.1341064 },
    { lat: 48.5138848, lng: 19.1339839 },
    { lat: 48.5138257, lng: 19.1340165 },
    { lat: 48.5137686, lng: 19.1338651 },
    { lat: 48.5134831, lng: 19.1340685 },
    { lat: 48.5135578, lng: 19.1343687 },
    { lat: 48.5132817, lng: 19.1344612 },
    { lat: 48.513176, lng: 19.1351924 },
    { lat: 48.5126485, lng: 19.1364 },
    { lat: 48.5125099, lng: 19.1369254 },
    { lat: 48.5123695, lng: 19.1378013 },
    { lat: 48.5126064, lng: 19.1381249 },
    { lat: 48.5125151, lng: 19.1384189 },
    { lat: 48.5121827, lng: 19.138992 },
    { lat: 48.5118453, lng: 19.1397402 },
    { lat: 48.5109927, lng: 19.1403918 },
    { lat: 48.5110219, lng: 19.1404579 },
    { lat: 48.5104701, lng: 19.1416998 },
    { lat: 48.5097465, lng: 19.1427067 },
    { lat: 48.5096678, lng: 19.1430294 },
    { lat: 48.5096853, lng: 19.1433353 },
    { lat: 48.5093542, lng: 19.1437781 },
    { lat: 48.5090174, lng: 19.1439399 },
    { lat: 48.508987, lng: 19.1440084 },
    { lat: 48.5079091, lng: 19.1426392 },
    { lat: 48.5070433, lng: 19.1421126 },
    { lat: 48.5066368, lng: 19.1420092 },
    { lat: 48.50578, lng: 19.1426019 },
    { lat: 48.5042358, lng: 19.1421958 },
    { lat: 48.5041629, lng: 19.1419079 },
    { lat: 48.5038308, lng: 19.1420322 },
    { lat: 48.50337, lng: 19.142402 },
    { lat: 48.503322, lng: 19.1424106 },
    { lat: 48.5033322, lng: 19.1428452 },
    { lat: 48.5028331, lng: 19.1425553 },
    { lat: 48.5021724, lng: 19.1423943 },
    { lat: 48.5021258, lng: 19.1425695 },
    { lat: 48.5021814, lng: 19.1428476 },
    { lat: 48.50209, lng: 19.1429144 },
    { lat: 48.501995, lng: 19.1428191 },
    { lat: 48.5019295, lng: 19.1426356 },
    { lat: 48.501926, lng: 19.1424024 },
    { lat: 48.5017086, lng: 19.1424058 },
    { lat: 48.5016353, lng: 19.1423145 },
    { lat: 48.5008415, lng: 19.1422655 },
    { lat: 48.5006467, lng: 19.1420193 },
    { lat: 48.5004653, lng: 19.141499 },
    { lat: 48.5003234, lng: 19.1413855 },
    { lat: 48.5000482, lng: 19.1414522 },
    { lat: 48.4997716, lng: 19.1401694 },
    { lat: 48.4995775, lng: 19.1399857 },
    { lat: 48.4995888, lng: 19.1397118 },
    { lat: 48.4992476, lng: 19.1389891 },
    { lat: 48.4992883, lng: 19.1385629 },
    { lat: 48.4991587, lng: 19.1381733 },
    { lat: 48.4990515, lng: 19.1373959 },
    { lat: 48.4991191, lng: 19.1372592 },
    { lat: 48.4990639, lng: 19.1366011 },
    { lat: 48.498739, lng: 19.1367093 },
    { lat: 48.4986427, lng: 19.1370404 },
    { lat: 48.4986228, lng: 19.137665 },
    { lat: 48.498762, lng: 19.139384 },
    { lat: 48.498699, lng: 19.1397949 },
    { lat: 48.4987592, lng: 19.1398283 },
    { lat: 48.4987871, lng: 19.140138 },
    { lat: 48.498812, lng: 19.1414856 },
    { lat: 48.4989438, lng: 19.1418003 },
    { lat: 48.4984643, lng: 19.1414531 },
    { lat: 48.497229, lng: 19.1418012 },
    { lat: 48.4969717, lng: 19.1417941 },
    { lat: 48.4966951, lng: 19.1424013 },
    { lat: 48.4965666, lng: 19.142994 },
    { lat: 48.4965039, lng: 19.1436842 },
    { lat: 48.4965743, lng: 19.1446054 },
    { lat: 48.4969838, lng: 19.1467085 },
    { lat: 48.497227, lng: 19.1473907 },
    { lat: 48.4972125, lng: 19.147461 },
    { lat: 48.4972797, lng: 19.1476575 },
    { lat: 48.4978887, lng: 19.1483709 },
    { lat: 48.4981685, lng: 19.1485788 },
    { lat: 48.4984883, lng: 19.1487545 },
    { lat: 48.4987503, lng: 19.1486726 },
    { lat: 48.4988036, lng: 19.1483375 },
    { lat: 48.4990882, lng: 19.1481876 },
    { lat: 48.4991132, lng: 19.1478471 },
    { lat: 48.498967, lng: 19.1476278 },
    { lat: 48.4990064, lng: 19.1468297 },
    { lat: 48.499204, lng: 19.1457181 },
    { lat: 48.4993582, lng: 19.1451687 },
    { lat: 48.4995139, lng: 19.1453503 },
    { lat: 48.4995022, lng: 19.1464116 },
    { lat: 48.4995648, lng: 19.1464277 },
    { lat: 48.4995725, lng: 19.1468552 },
    { lat: 48.4995017, lng: 19.1468613 },
    { lat: 48.499507, lng: 19.1469873 },
    { lat: 48.499415, lng: 19.1472475 },
    { lat: 48.4997125, lng: 19.1472685 },
    { lat: 48.4999586, lng: 19.1475492 },
    { lat: 48.4997394, lng: 19.1481097 },
    { lat: 48.49979, lng: 19.1481766 },
    { lat: 48.4997691, lng: 19.148347 },
    { lat: 48.4999564, lng: 19.1489186 },
    { lat: 48.4999402, lng: 19.1492983 },
    { lat: 48.5001013, lng: 19.1496151 },
    { lat: 48.4998447, lng: 19.1500107 },
    { lat: 48.500283, lng: 19.150557 },
    { lat: 48.5004179, lng: 19.1506144 },
    { lat: 48.5005276, lng: 19.1505311 },
    { lat: 48.5006683, lng: 19.1502089 },
    { lat: 48.5007034, lng: 19.1497752 },
    { lat: 48.5006535, lng: 19.1496127 },
    { lat: 48.5018653, lng: 19.1478334 },
    { lat: 48.5023957, lng: 19.1474566 },
    { lat: 48.5021944, lng: 19.1485373 },
    { lat: 48.5021764, lng: 19.1489044 },
    { lat: 48.5022665, lng: 19.1494268 },
    { lat: 48.5023094, lng: 19.1501333 },
    { lat: 48.5017782, lng: 19.1519076 },
    { lat: 48.5009008, lng: 19.1541648 },
    { lat: 48.4997983, lng: 19.156284 },
    { lat: 48.4995485, lng: 19.1570715 },
    { lat: 48.4987645, lng: 19.1586374 },
    { lat: 48.4982193, lng: 19.1606088 },
    { lat: 48.4971106, lng: 19.1615377 },
    { lat: 48.4960142, lng: 19.1628483 },
    { lat: 48.4959272, lng: 19.1636897 },
    { lat: 48.4960086, lng: 19.1661412 },
    { lat: 48.4959722, lng: 19.1670282 },
    { lat: 48.4975207, lng: 19.1679107 },
    { lat: 48.4993127, lng: 19.1667124 },
    { lat: 48.4997784, lng: 19.1662353 },
    { lat: 48.5006724, lng: 19.1655768 },
    { lat: 48.5010231, lng: 19.1654409 },
    { lat: 48.5022123, lng: 19.1647263 },
    { lat: 48.5029171, lng: 19.1641166 },
    { lat: 48.5052714, lng: 19.1628666 },
    { lat: 48.5052355, lng: 19.1633039 },
    { lat: 48.5052902, lng: 19.16414 },
    { lat: 48.5054712, lng: 19.164658 },
    { lat: 48.5056868, lng: 19.1648003 },
    { lat: 48.5058671, lng: 19.164773 },
    { lat: 48.5061201, lng: 19.164534 },
    { lat: 48.5062, lng: 19.1643719 },
    { lat: 48.5064021, lng: 19.1643081 },
    { lat: 48.5065947, lng: 19.163983 },
    { lat: 48.5069916, lng: 19.1637353 },
    { lat: 48.506909, lng: 19.1635451 },
    { lat: 48.5069227, lng: 19.1632762 },
    { lat: 48.5069846, lng: 19.1631143 },
    { lat: 48.5070939, lng: 19.1630452 },
    { lat: 48.5072339, lng: 19.1623147 },
    { lat: 48.5074383, lng: 19.1624589 },
    { lat: 48.507335, lng: 19.1630393 },
    { lat: 48.5073881, lng: 19.1630892 },
    { lat: 48.5076188, lng: 19.1621871 },
    { lat: 48.5077892, lng: 19.1620116 },
    { lat: 48.5079231, lng: 19.1620738 },
    { lat: 48.5079892, lng: 19.162498 },
    { lat: 48.5080904, lng: 19.1614188 },
    { lat: 48.5082423, lng: 19.1611156 },
    { lat: 48.5083793, lng: 19.1595983 },
    { lat: 48.5084595, lng: 19.1595758 },
    { lat: 48.5085514, lng: 19.1589061 },
    { lat: 48.5090234, lng: 19.1574565 },
    { lat: 48.5091807, lng: 19.1571051 },
    { lat: 48.5092948, lng: 19.1569707 },
    { lat: 48.5093763, lng: 19.1566125 },
    { lat: 48.5095131, lng: 19.1563448 },
    { lat: 48.509505, lng: 19.1562526 },
    { lat: 48.5098012, lng: 19.1557637 },
    { lat: 48.5102546, lng: 19.1555217 },
    { lat: 48.5103759, lng: 19.1553752 },
    { lat: 48.5103872, lng: 19.1552352 },
    { lat: 48.5105246, lng: 19.1552096 },
    { lat: 48.5105604, lng: 19.1551601 },
    { lat: 48.5105291, lng: 19.1549808 },
    { lat: 48.5110234, lng: 19.1547865 },
    { lat: 48.5113384, lng: 19.1542489 },
    { lat: 48.5114519, lng: 19.1541495 },
    { lat: 48.5114355, lng: 19.1540898 },
    { lat: 48.5115697, lng: 19.1538657 },
    { lat: 48.5119262, lng: 19.1538237 },
    { lat: 48.5119742, lng: 19.1535848 },
    { lat: 48.5120474, lng: 19.1534699 },
    { lat: 48.5120394, lng: 19.153308 },
    { lat: 48.512294, lng: 19.1526424 },
    { lat: 48.5122857, lng: 19.1525455 },
    { lat: 48.5124302, lng: 19.1522762 },
    { lat: 48.5124676, lng: 19.1520722 },
    { lat: 48.5126597, lng: 19.1518107 },
    { lat: 48.5126564, lng: 19.1515142 },
    { lat: 48.5125522, lng: 19.1512315 },
    { lat: 48.5125731, lng: 19.1510137 },
    { lat: 48.5126447, lng: 19.1509132 },
    { lat: 48.5127191, lng: 19.1505266 },
    { lat: 48.5128819, lng: 19.1501443 },
    { lat: 48.5129495, lng: 19.1500976 },
    { lat: 48.5131559, lng: 19.1493929 },
    { lat: 48.5132355, lng: 19.1492678 },
    { lat: 48.5133916, lng: 19.1492336 },
    { lat: 48.5133764, lng: 19.1490415 },
    { lat: 48.5134522, lng: 19.1489125 },
    { lat: 48.5135387, lng: 19.1489504 },
    { lat: 48.513688, lng: 19.1488032 },
    { lat: 48.5138652, lng: 19.1484396 },
    { lat: 48.5142384, lng: 19.148167 },
    { lat: 48.5144753, lng: 19.1483097 },
    { lat: 48.5149199, lng: 19.148181 },
    { lat: 48.5153947, lng: 19.1481368 },
    { lat: 48.515938, lng: 19.1475689 },
    { lat: 48.5159524, lng: 19.147377 },
    { lat: 48.5169836, lng: 19.1468046 },
    { lat: 48.5171597, lng: 19.1465822 },
    { lat: 48.5173747, lng: 19.1466638 },
    { lat: 48.5176139, lng: 19.1462355 },
    { lat: 48.5178249, lng: 19.1461945 },
    { lat: 48.5179298, lng: 19.1461034 },
    { lat: 48.5179494, lng: 19.1459746 },
    { lat: 48.5186947, lng: 19.1455025 },
    { lat: 48.5187976, lng: 19.1454369 },
    { lat: 48.5191503, lng: 19.1450215 },
    { lat: 48.5196722, lng: 19.1445651 },
    { lat: 48.5199589, lng: 19.1443918 },
    { lat: 48.520701, lng: 19.1432145 },
    { lat: 48.5206599, lng: 19.1429993 },
    { lat: 48.5210808, lng: 19.142572 },
    { lat: 48.5213518, lng: 19.1424207 },
    { lat: 48.5217061, lng: 19.1423618 },
    { lat: 48.5219321, lng: 19.1422373 },
    { lat: 48.5221268, lng: 19.1420703 },
    { lat: 48.522428, lng: 19.1416404 },
    { lat: 48.5236823, lng: 19.1409468 },
    { lat: 48.5242128, lng: 19.1403432 },
    { lat: 48.5243851, lng: 19.1402261 },
    { lat: 48.5247584, lng: 19.1401177 },
    { lat: 48.5250485, lng: 19.1398736 },
    { lat: 48.5252394, lng: 19.1396306 },
    { lat: 48.5255898, lng: 19.1394 },
    { lat: 48.5260518, lng: 19.1392112 },
    { lat: 48.5263589, lng: 19.138973 },
    { lat: 48.5265805, lng: 19.1386679 },
    { lat: 48.5274727, lng: 19.1366714 },
    { lat: 48.5278227, lng: 19.1364785 },
    { lat: 48.5281653, lng: 19.136614 },
    { lat: 48.5285021, lng: 19.1366331 },
    { lat: 48.5287918, lng: 19.1365615 },
    { lat: 48.5292588, lng: 19.1367043 },
    { lat: 48.530669, lng: 19.1364984 },
    { lat: 48.5316734, lng: 19.1364738 },
    { lat: 48.5318914, lng: 19.1365284 },
    { lat: 48.5321152, lng: 19.136426 },
    { lat: 48.5322766, lng: 19.1361116 },
    { lat: 48.5326472, lng: 19.1356493 },
    { lat: 48.5327547, lng: 19.1351823 },
    { lat: 48.5328774, lng: 19.134901 },
    { lat: 48.5329113, lng: 19.134517 },
    { lat: 48.5330529, lng: 19.1341439 },
    { lat: 48.5330766, lng: 19.1339876 },
    { lat: 48.5330362, lng: 19.1334871 },
    { lat: 48.5328119, lng: 19.1326679 },
    { lat: 48.5328104, lng: 19.1322522 },
    { lat: 48.5327307, lng: 19.1317358 },
    { lat: 48.5324552, lng: 19.1313378 },
    { lat: 48.5322175, lng: 19.129888 },
    { lat: 48.5320067, lng: 19.1294095 },
    { lat: 48.5318643, lng: 19.1287685 },
    { lat: 48.5318395, lng: 19.128448 },
    { lat: 48.5317195, lng: 19.1279386 },
    { lat: 48.5317118, lng: 19.1276461 },
    { lat: 48.5317702, lng: 19.1272322 },
    { lat: 48.5316656, lng: 19.1267144 },
    { lat: 48.5317247, lng: 19.1262243 },
    { lat: 48.5316639, lng: 19.1258606 },
    { lat: 48.5316725, lng: 19.1256298 },
    { lat: 48.5319343, lng: 19.1249068 },
    { lat: 48.5318184, lng: 19.1243834 },
    { lat: 48.5318251, lng: 19.1242059 },
    { lat: 48.5321429, lng: 19.1226549 },
    { lat: 48.5323926, lng: 19.1218745 },
    { lat: 48.5325382, lng: 19.1209006 },
    { lat: 48.532537, lng: 19.1204664 },
    { lat: 48.5328032, lng: 19.1199463 },
    { lat: 48.5327218, lng: 19.1200544 },
    { lat: 48.5325731, lng: 19.1202849 },
  ],
  VeľkáLúka: [
    { lat: 48.6384035, lng: 19.1969768 },
    { lat: 48.6384388, lng: 19.197144 },
    { lat: 48.6385973, lng: 19.1975979 },
    { lat: 48.6387295, lng: 19.1976734 },
    { lat: 48.6388485, lng: 19.1976623 },
    { lat: 48.6390356, lng: 19.1975238 },
    { lat: 48.6390027, lng: 19.1972897 },
    { lat: 48.6391952, lng: 19.1968884 },
    { lat: 48.6392686, lng: 19.1964329 },
    { lat: 48.6393999, lng: 19.1963495 },
    { lat: 48.6394404, lng: 19.196211 },
    { lat: 48.6394307, lng: 19.1961041 },
    { lat: 48.6393172, lng: 19.1959962 },
    { lat: 48.6394384, lng: 19.1957301 },
    { lat: 48.6396117, lng: 19.195565 },
    { lat: 48.639736, lng: 19.1952952 },
    { lat: 48.6399605, lng: 19.1951822 },
    { lat: 48.6400391, lng: 19.1948766 },
    { lat: 48.640246, lng: 19.1945424 },
    { lat: 48.6401978, lng: 19.194315 },
    { lat: 48.6404911, lng: 19.1936761 },
    { lat: 48.6406059, lng: 19.1932783 },
    { lat: 48.6408482, lng: 19.1931181 },
    { lat: 48.64114, lng: 19.1930674 },
    { lat: 48.6418122, lng: 19.1933076 },
    { lat: 48.6426722, lng: 19.1933733 },
    { lat: 48.6435171, lng: 19.1935237 },
    { lat: 48.6439347, lng: 19.1934887 },
    { lat: 48.6444864, lng: 19.1931966 },
    { lat: 48.6448479, lng: 19.1931221 },
    { lat: 48.6455198, lng: 19.1935634 },
    { lat: 48.6461758, lng: 19.1942507 },
    { lat: 48.6467394, lng: 19.1944578 },
    { lat: 48.6468859, lng: 19.194434 },
    { lat: 48.6469927, lng: 19.1945472 },
    { lat: 48.6474994, lng: 19.1946245 },
    { lat: 48.6487579, lng: 19.1942904 },
    { lat: 48.6493439, lng: 19.1939899 },
    { lat: 48.6503106, lng: 19.1944355 },
    { lat: 48.6503867, lng: 19.1946117 },
    { lat: 48.65116, lng: 19.1949819 },
    { lat: 48.651784, lng: 19.194566 },
    { lat: 48.651727, lng: 19.194309 },
    { lat: 48.651679, lng: 19.193934 },
    { lat: 48.651692, lng: 19.193784 },
    { lat: 48.651714, lng: 19.193525 },
    { lat: 48.65177, lng: 19.193363 },
    { lat: 48.65201, lng: 19.193105 },
    { lat: 48.652099, lng: 19.192189 },
    { lat: 48.652179, lng: 19.191863 },
    { lat: 48.652227, lng: 19.191613 },
    { lat: 48.652841, lng: 19.189507 },
    { lat: 48.652509, lng: 19.189118 },
    { lat: 48.652237, lng: 19.188738 },
    { lat: 48.651961, lng: 19.188283 },
    { lat: 48.651843, lng: 19.187909 },
    { lat: 48.651785, lng: 19.187771 },
    { lat: 48.651627, lng: 19.187472 },
    { lat: 48.65131, lng: 19.187238 },
    { lat: 48.65111, lng: 19.186957 },
    { lat: 48.650692, lng: 19.186323 },
    { lat: 48.650512, lng: 19.186114 },
    { lat: 48.65034, lng: 19.185562 },
    { lat: 48.650234, lng: 19.185342 },
    { lat: 48.650131, lng: 19.185126 },
    { lat: 48.650025, lng: 19.184767 },
    { lat: 48.649949, lng: 19.184461 },
    { lat: 48.649774, lng: 19.183904 },
    { lat: 48.649664, lng: 19.183722 },
    { lat: 48.649498, lng: 19.183583 },
    { lat: 48.649113, lng: 19.183158 },
    { lat: 48.648885, lng: 19.182973 },
    { lat: 48.648526, lng: 19.18272 },
    { lat: 48.648329, lng: 19.182622 },
    { lat: 48.648233, lng: 19.182487 },
    { lat: 48.648121, lng: 19.182375 },
    { lat: 48.647861, lng: 19.182199 },
    { lat: 48.647703, lng: 19.181989 },
    { lat: 48.647549, lng: 19.181762 },
    { lat: 48.647413, lng: 19.181609 },
    { lat: 48.647322, lng: 19.181516 },
    { lat: 48.647252, lng: 19.181463 },
    { lat: 48.647031, lng: 19.181301 },
    { lat: 48.646922, lng: 19.181179 },
    { lat: 48.646782, lng: 19.180959 },
    { lat: 48.646659, lng: 19.180726 },
    { lat: 48.646616, lng: 19.180587 },
    { lat: 48.646562, lng: 19.180434 },
    { lat: 48.646408, lng: 19.180282 },
    { lat: 48.646222, lng: 19.180245 },
    { lat: 48.645821, lng: 19.179997 },
    { lat: 48.64576, lng: 19.179965 },
    { lat: 48.645464, lng: 19.179677 },
    { lat: 48.644895, lng: 19.179013 },
    { lat: 48.644684, lng: 19.178814 },
    { lat: 48.644477, lng: 19.178648 },
    { lat: 48.643958, lng: 19.178159 },
    { lat: 48.643803, lng: 19.177975 },
    { lat: 48.643643, lng: 19.177735 },
    { lat: 48.643512, lng: 19.177492 },
    { lat: 48.643314, lng: 19.177235 },
    { lat: 48.64317, lng: 19.177114 },
    { lat: 48.642822, lng: 19.17685 },
    { lat: 48.642404, lng: 19.176488 },
    { lat: 48.642232, lng: 19.176345 },
    { lat: 48.642064, lng: 19.176238 },
    { lat: 48.641882, lng: 19.176086 },
    { lat: 48.641753, lng: 19.175971 },
    { lat: 48.641637, lng: 19.175988 },
    { lat: 48.641125, lng: 19.176075 },
    { lat: 48.641018, lng: 19.175844 },
    { lat: 48.640987, lng: 19.175289 },
    { lat: 48.640764, lng: 19.174123 },
    { lat: 48.640833, lng: 19.173682 },
    { lat: 48.640754, lng: 19.172951 },
    { lat: 48.64099, lng: 19.172394 },
    { lat: 48.640983, lng: 19.171895 },
    { lat: 48.641042, lng: 19.171738 },
    { lat: 48.640828, lng: 19.171414 },
    { lat: 48.640637, lng: 19.171523 },
    { lat: 48.640497, lng: 19.170922 },
    { lat: 48.640454, lng: 19.170427 },
    { lat: 48.640451, lng: 19.170394 },
    { lat: 48.640448, lng: 19.170372 },
    { lat: 48.640443, lng: 19.170336 },
    { lat: 48.640419, lng: 19.170161 },
    { lat: 48.640359, lng: 19.169732 },
    { lat: 48.640234, lng: 19.168781 },
    { lat: 48.640175, lng: 19.168458 },
    { lat: 48.640018, lng: 19.16785 },
    { lat: 48.639965, lng: 19.167541 },
    { lat: 48.63995, lng: 19.166727 },
    { lat: 48.639718, lng: 19.165609 },
    { lat: 48.639684, lng: 19.16523 },
    { lat: 48.639689, lng: 19.164482 },
    { lat: 48.639661, lng: 19.164178 },
    { lat: 48.639359, lng: 19.162573 },
    { lat: 48.639287, lng: 19.162421 },
    { lat: 48.639242, lng: 19.162375 },
    { lat: 48.639705, lng: 19.162226 },
    { lat: 48.639584, lng: 19.160968 },
    { lat: 48.639558, lng: 19.160316 },
    { lat: 48.63953, lng: 19.159667 },
    { lat: 48.639475, lng: 19.159074 },
    { lat: 48.639392, lng: 19.158499 },
    { lat: 48.639513, lng: 19.158376 },
    { lat: 48.639565, lng: 19.15839 },
    { lat: 48.639581, lng: 19.158101 },
    { lat: 48.639454, lng: 19.156967 },
    { lat: 48.639452, lng: 19.156865 },
    { lat: 48.639427, lng: 19.156781 },
    { lat: 48.639429, lng: 19.15673 },
    { lat: 48.639426, lng: 19.156702 },
    { lat: 48.639425, lng: 19.156697 },
    { lat: 48.639398, lng: 19.156447 },
    { lat: 48.639336, lng: 19.155965 },
    { lat: 48.639216, lng: 19.15502 },
    { lat: 48.63921, lng: 19.154818 },
    { lat: 48.639212, lng: 19.154611 },
    { lat: 48.639241, lng: 19.153337 },
    { lat: 48.639251, lng: 19.153291 },
    { lat: 48.639264, lng: 19.153234 },
    { lat: 48.639278, lng: 19.153167 },
    { lat: 48.639293, lng: 19.153108 },
    { lat: 48.6393, lng: 19.153075 },
    { lat: 48.63932, lng: 19.152957 },
    { lat: 48.639332, lng: 19.152772 },
    { lat: 48.639371, lng: 19.15257 },
    { lat: 48.639423, lng: 19.15203 },
    { lat: 48.639437, lng: 19.151771 },
    { lat: 48.639441, lng: 19.151641 },
    { lat: 48.639457, lng: 19.151137 },
    { lat: 48.639469, lng: 19.150802 },
    { lat: 48.639471, lng: 19.150733 },
    { lat: 48.639476, lng: 19.150593 },
    { lat: 48.639805, lng: 19.149946 },
    { lat: 48.639916, lng: 19.149564 },
    { lat: 48.639939, lng: 19.149485 },
    { lat: 48.640024, lng: 19.149191 },
    { lat: 48.640036, lng: 19.149148 },
    { lat: 48.640042, lng: 19.149128 },
    { lat: 48.640103, lng: 19.148922 },
    { lat: 48.640156, lng: 19.148737 },
    { lat: 48.640166, lng: 19.148706 },
    { lat: 48.64018, lng: 19.148654 },
    { lat: 48.640188, lng: 19.148628 },
    { lat: 48.640188, lng: 19.148573 },
    { lat: 48.640188, lng: 19.148056 },
    { lat: 48.640135, lng: 19.147609 },
    { lat: 48.640098, lng: 19.14713 },
    { lat: 48.64015, lng: 19.1466 },
    { lat: 48.639583, lng: 19.146754 },
    { lat: 48.638432, lng: 19.146883 },
    { lat: 48.638245, lng: 19.146951 },
    { lat: 48.637314, lng: 19.147864 },
    { lat: 48.636826, lng: 19.148504 },
    { lat: 48.636486, lng: 19.149612 },
    { lat: 48.636459, lng: 19.149698 },
    { lat: 48.636282, lng: 19.150275 },
    { lat: 48.636254, lng: 19.150364 },
    { lat: 48.635871, lng: 19.150479 },
    { lat: 48.635489, lng: 19.150491 },
    { lat: 48.634962, lng: 19.150376 },
    { lat: 48.634357, lng: 19.15003 },
    { lat: 48.634225, lng: 19.149932 },
    { lat: 48.634066, lng: 19.149804 },
    { lat: 48.633805, lng: 19.149504 },
    { lat: 48.633814, lng: 19.149381 },
    { lat: 48.633859, lng: 19.148745 },
    { lat: 48.63387, lng: 19.148599 },
    { lat: 48.634027, lng: 19.14641 },
    { lat: 48.633982, lng: 19.145732 },
    { lat: 48.633496, lng: 19.145623 },
    { lat: 48.633132, lng: 19.145016 },
    { lat: 48.632841, lng: 19.144903 },
    { lat: 48.632323, lng: 19.1447 },
    { lat: 48.632269, lng: 19.14468 },
    { lat: 48.631948, lng: 19.144553 },
    { lat: 48.631841, lng: 19.144512 },
    { lat: 48.631524, lng: 19.143476 },
    { lat: 48.631282, lng: 19.142893 },
    { lat: 48.631116, lng: 19.142606 },
    { lat: 48.630822, lng: 19.142277 },
    { lat: 48.630586, lng: 19.141973 },
    { lat: 48.63008, lng: 19.141653 },
    { lat: 48.6298944, lng: 19.1415511 },
    { lat: 48.629723, lng: 19.141457 },
    { lat: 48.629375, lng: 19.141372 },
    { lat: 48.6288471, lng: 19.1412915 },
    { lat: 48.628313, lng: 19.1414549 },
    { lat: 48.6282497, lng: 19.1414306 },
    { lat: 48.628536, lng: 19.1419098 },
    { lat: 48.6283343, lng: 19.143029 },
    { lat: 48.6283398, lng: 19.1431841 },
    { lat: 48.6281206, lng: 19.1433367 },
    { lat: 48.6280471, lng: 19.1435388 },
    { lat: 48.6277536, lng: 19.1438226 },
    { lat: 48.6277314, lng: 19.1440136 },
    { lat: 48.627794, lng: 19.1444841 },
    { lat: 48.6279072, lng: 19.1446097 },
    { lat: 48.6280712, lng: 19.1451654 },
    { lat: 48.6283148, lng: 19.1456255 },
    { lat: 48.6285996, lng: 19.1460981 },
    { lat: 48.6288412, lng: 19.1463322 },
    { lat: 48.629027, lng: 19.1467411 },
    { lat: 48.6298021, lng: 19.1474178 },
    { lat: 48.6304603, lng: 19.1478268 },
    { lat: 48.6299603, lng: 19.1506038 },
    { lat: 48.6297793, lng: 19.1505692 },
    { lat: 48.6297797, lng: 19.1510328 },
    { lat: 48.6297971, lng: 19.1511349 },
    { lat: 48.6298665, lng: 19.1511927 },
    { lat: 48.6297971, lng: 19.1514336 },
    { lat: 48.6293216, lng: 19.1526455 },
    { lat: 48.6281781, lng: 19.1550647 },
    { lat: 48.6277913, lng: 19.1562755 },
    { lat: 48.6272343, lng: 19.1560446 },
    { lat: 48.6267745, lng: 19.1562066 },
    { lat: 48.6261348, lng: 19.1555346 },
    { lat: 48.6258039, lng: 19.1553383 },
    { lat: 48.6248577, lng: 19.1551258 },
    { lat: 48.6244882, lng: 19.1552196 },
    { lat: 48.6237003, lng: 19.1552241 },
    { lat: 48.6235761, lng: 19.1554258 },
    { lat: 48.6229366, lng: 19.1576115 },
    { lat: 48.6214236, lng: 19.1618689 },
    { lat: 48.6225738, lng: 19.1635513 },
    { lat: 48.6227164, lng: 19.1638852 },
    { lat: 48.6227686, lng: 19.1641452 },
    { lat: 48.6228506, lng: 19.1642525 },
    { lat: 48.6216775, lng: 19.1665765 },
    { lat: 48.6212774, lng: 19.1667363 },
    { lat: 48.6206185, lng: 19.1668635 },
    { lat: 48.6199229, lng: 19.1666787 },
    { lat: 48.6187562, lng: 19.1668843 },
    { lat: 48.618081, lng: 19.1671387 },
    { lat: 48.6174391, lng: 19.1675881 },
    { lat: 48.6168993, lng: 19.1675437 },
    { lat: 48.6162435, lng: 19.1679666 },
    { lat: 48.6159613, lng: 19.1677502 },
    { lat: 48.6158197, lng: 19.1681237 },
    { lat: 48.6156616, lng: 19.1683715 },
    { lat: 48.6154925, lng: 19.1684399 },
    { lat: 48.6156044, lng: 19.1689732 },
    { lat: 48.6153894, lng: 19.1692559 },
    { lat: 48.6153233, lng: 19.1697702 },
    { lat: 48.6149828, lng: 19.1706202 },
    { lat: 48.6149386, lng: 19.1710943 },
    { lat: 48.6147696, lng: 19.1714399 },
    { lat: 48.6149168, lng: 19.1721655 },
    { lat: 48.6148262, lng: 19.1724998 },
    { lat: 48.6149463, lng: 19.1728095 },
    { lat: 48.6146297, lng: 19.1737808 },
    { lat: 48.6142195, lng: 19.1743795 },
    { lat: 48.6140036, lng: 19.1745963 },
    { lat: 48.613928, lng: 19.1748257 },
    { lat: 48.6136531, lng: 19.1749664 },
    { lat: 48.6132194, lng: 19.175613 },
    { lat: 48.6127397, lng: 19.1770046 },
    { lat: 48.6126148, lng: 19.1783145 },
    { lat: 48.6124423, lng: 19.178799 },
    { lat: 48.6113153, lng: 19.1795845 },
    { lat: 48.6106306, lng: 19.1795095 },
    { lat: 48.6100549, lng: 19.1796289 },
    { lat: 48.6093706, lng: 19.1796529 },
    { lat: 48.6090064, lng: 19.1807768 },
    { lat: 48.6088683, lng: 19.1813614 },
    { lat: 48.6088906, lng: 19.1817486 },
    { lat: 48.6087656, lng: 19.1819121 },
    { lat: 48.6088084, lng: 19.1845536 },
    { lat: 48.6086451, lng: 19.1849957 },
    { lat: 48.6083943, lng: 19.185304 },
    { lat: 48.6081502, lng: 19.1860504 },
    { lat: 48.6080771, lng: 19.1864667 },
    { lat: 48.6080015, lng: 19.1874558 },
    { lat: 48.6079725, lng: 19.187833 },
    { lat: 48.6082548, lng: 19.1874966 },
    { lat: 48.6085416, lng: 19.1873472 },
    { lat: 48.6090133, lng: 19.1874488 },
    { lat: 48.6097802, lng: 19.187315 },
    { lat: 48.6100099, lng: 19.187082 },
    { lat: 48.6105888, lng: 19.1867272 },
    { lat: 48.6107646, lng: 19.186724 },
    { lat: 48.6115212, lng: 19.1868698 },
    { lat: 48.612233, lng: 19.187431 },
    { lat: 48.6125139, lng: 19.1875034 },
    { lat: 48.612634, lng: 19.1876076 },
    { lat: 48.6128656, lng: 19.1875953 },
    { lat: 48.6134248, lng: 19.1878764 },
    { lat: 48.6137668, lng: 19.1879762 },
    { lat: 48.6143405, lng: 19.1879932 },
    { lat: 48.6143688, lng: 19.1878817 },
    { lat: 48.6144768, lng: 19.1878789 },
    { lat: 48.6147335, lng: 19.1876658 },
    { lat: 48.6151953, lng: 19.1875203 },
    { lat: 48.6153589, lng: 19.1873154 },
    { lat: 48.615507, lng: 19.187368 },
    { lat: 48.6157463, lng: 19.1871479 },
    { lat: 48.6159335, lng: 19.1872168 },
    { lat: 48.6160576, lng: 19.1871271 },
    { lat: 48.6167282, lng: 19.1871949 },
    { lat: 48.6169081, lng: 19.1870712 },
    { lat: 48.6169935, lng: 19.1872045 },
    { lat: 48.6171489, lng: 19.1871501 },
    { lat: 48.6172241, lng: 19.1872643 },
    { lat: 48.6173336, lng: 19.1872656 },
    { lat: 48.6174758, lng: 19.1874735 },
    { lat: 48.6178609, lng: 19.187421 },
    { lat: 48.6219756, lng: 19.1877221 },
    { lat: 48.622011, lng: 19.1877303 },
    { lat: 48.6219499, lng: 19.1869707 },
    { lat: 48.6224849, lng: 19.1864778 },
    { lat: 48.6229715, lng: 19.1858713 },
    { lat: 48.6233185, lng: 19.1858907 },
    { lat: 48.6245082, lng: 19.1863364 },
    { lat: 48.6249779, lng: 19.1862609 },
    { lat: 48.6252084, lng: 19.1860617 },
    { lat: 48.6255333, lng: 19.1860212 },
    { lat: 48.6269697, lng: 19.1863057 },
    { lat: 48.6274628, lng: 19.1865191 },
    { lat: 48.6283581, lng: 19.1867814 },
    { lat: 48.6317224, lng: 19.1892334 },
    { lat: 48.6327396, lng: 19.1895163 },
    { lat: 48.633432, lng: 19.188715 },
    { lat: 48.6335667, lng: 19.188798 },
    { lat: 48.6340677, lng: 19.1879535 },
    { lat: 48.6344353, lng: 19.1876613 },
    { lat: 48.6347881, lng: 19.1869438 },
    { lat: 48.6351387, lng: 19.1860002 },
    { lat: 48.6354157, lng: 19.185671 },
    { lat: 48.6361021, lng: 19.186175 },
    { lat: 48.6364728, lng: 19.1854163 },
    { lat: 48.6365736, lng: 19.1860458 },
    { lat: 48.6368926, lng: 19.1869223 },
    { lat: 48.6370358, lng: 19.1875397 },
    { lat: 48.6375016, lng: 19.1874771 },
    { lat: 48.6379705, lng: 19.1872462 },
    { lat: 48.6381234, lng: 19.1873108 },
    { lat: 48.6382184, lng: 19.1875226 },
    { lat: 48.6383307, lng: 19.1876028 },
    { lat: 48.6383137, lng: 19.1878577 },
    { lat: 48.6384103, lng: 19.1886107 },
    { lat: 48.6385265, lng: 19.1887272 },
    { lat: 48.6384889, lng: 19.1893373 },
    { lat: 48.638657, lng: 19.1896889 },
    { lat: 48.6385593, lng: 19.1899022 },
    { lat: 48.6387117, lng: 19.1901839 },
    { lat: 48.6387986, lng: 19.1905071 },
    { lat: 48.6386647, lng: 19.1906293 },
    { lat: 48.638493, lng: 19.190966 },
    { lat: 48.6383502, lng: 19.1914142 },
    { lat: 48.6383041, lng: 19.1918075 },
    { lat: 48.6380711, lng: 19.1918602 },
    { lat: 48.6380705, lng: 19.1920074 },
    { lat: 48.6383474, lng: 19.1923469 },
    { lat: 48.6384051, lng: 19.1926024 },
    { lat: 48.6383057, lng: 19.1927589 },
    { lat: 48.6380778, lng: 19.1929194 },
    { lat: 48.6379424, lng: 19.1931925 },
    { lat: 48.637905, lng: 19.193187 },
    { lat: 48.6376145, lng: 19.1940954 },
    { lat: 48.6382451, lng: 19.1945199 },
    { lat: 48.6379809, lng: 19.1946716 },
    { lat: 48.6380246, lng: 19.1947102 },
    { lat: 48.6378758, lng: 19.194875 },
    { lat: 48.6381359, lng: 19.1954445 },
    { lat: 48.638267, lng: 19.1962332 },
    { lat: 48.6385368, lng: 19.1964039 },
    { lat: 48.6385559, lng: 19.1967696 },
    { lat: 48.6384035, lng: 19.1969768 },
  ],
  BzovskáLehôtka: [
    { lat: 48.415718, lng: 19.115628 },
    { lat: 48.415687, lng: 19.11567 },
    { lat: 48.415849, lng: 19.11686 },
    { lat: 48.415976, lng: 19.117461 },
    { lat: 48.41537, lng: 19.11734 },
    { lat: 48.41514, lng: 19.117257 },
    { lat: 48.415105, lng: 19.117245 },
    { lat: 48.414867, lng: 19.116589 },
    { lat: 48.414384, lng: 19.11699 },
    { lat: 48.414378, lng: 19.116923 },
    { lat: 48.413804, lng: 19.117053 },
    { lat: 48.413508, lng: 19.117139 },
    { lat: 48.413309, lng: 19.117071 },
    { lat: 48.413173, lng: 19.117165 },
    { lat: 48.412814, lng: 19.117195 },
    { lat: 48.412324, lng: 19.117178 },
    { lat: 48.412169, lng: 19.117282 },
    { lat: 48.412016, lng: 19.117448 },
    { lat: 48.411874, lng: 19.117555 },
    { lat: 48.411592, lng: 19.117428 },
    { lat: 48.411238, lng: 19.117347 },
    { lat: 48.410836, lng: 19.117139 },
    { lat: 48.410385, lng: 19.116674 },
    { lat: 48.409842, lng: 19.116534 },
    { lat: 48.409612, lng: 19.116651 },
    { lat: 48.409095, lng: 19.116654 },
    { lat: 48.408707, lng: 19.116673 },
    { lat: 48.408576, lng: 19.116756 },
    { lat: 48.408492, lng: 19.116676 },
    { lat: 48.408329, lng: 19.116665 },
    { lat: 48.408146, lng: 19.116584 },
    { lat: 48.407959, lng: 19.116598 },
    { lat: 48.40778, lng: 19.116488 },
    { lat: 48.407623, lng: 19.116548 },
    { lat: 48.407209, lng: 19.116706 },
    { lat: 48.406947, lng: 19.116847 },
    { lat: 48.40675, lng: 19.117018 },
    { lat: 48.406469, lng: 19.117207 },
    { lat: 48.406198, lng: 19.117322 },
    { lat: 48.405828, lng: 19.117624 },
    { lat: 48.405476, lng: 19.117694 },
    { lat: 48.405321, lng: 19.117797 },
    { lat: 48.40512, lng: 19.117924 },
    { lat: 48.404927, lng: 19.11789 },
    { lat: 48.404526, lng: 19.118211 },
    { lat: 48.403658, lng: 19.118427 },
    { lat: 48.403529, lng: 19.118411 },
    { lat: 48.403301, lng: 19.118523 },
    { lat: 48.403096, lng: 19.118532 },
    { lat: 48.402705, lng: 19.118339 },
    { lat: 48.402662, lng: 19.118318 },
    { lat: 48.401938, lng: 19.117962 },
    { lat: 48.401812, lng: 19.117911 },
    { lat: 48.401449, lng: 19.117802 },
    { lat: 48.401383, lng: 19.117742 },
    { lat: 48.401213, lng: 19.117744 },
    { lat: 48.400981, lng: 19.117853 },
    { lat: 48.400441, lng: 19.118281 },
    { lat: 48.400359, lng: 19.118355 },
    { lat: 48.400305, lng: 19.118455 },
    { lat: 48.400244, lng: 19.118522 },
    { lat: 48.400172, lng: 19.118635 },
    { lat: 48.400133, lng: 19.118701 },
    { lat: 48.399976, lng: 19.118744 },
    { lat: 48.39978, lng: 19.118797 },
    { lat: 48.399733, lng: 19.118851 },
    { lat: 48.399424, lng: 19.119194 },
    { lat: 48.399315, lng: 19.119316 },
    { lat: 48.399502, lng: 19.119843 },
    { lat: 48.399388, lng: 19.119922 },
    { lat: 48.399117, lng: 19.12008 },
    { lat: 48.399009, lng: 19.120033 },
    { lat: 48.398906, lng: 19.120193 },
    { lat: 48.398566, lng: 19.120358 },
    { lat: 48.398495, lng: 19.12038 },
    { lat: 48.398142, lng: 19.120707 },
    { lat: 48.397768, lng: 19.120398 },
    { lat: 48.397747, lng: 19.120483 },
    { lat: 48.397593, lng: 19.121005 },
    { lat: 48.397557, lng: 19.121075 },
    { lat: 48.397357, lng: 19.121121 },
    { lat: 48.397173, lng: 19.121374 },
    { lat: 48.396868, lng: 19.121713 },
    { lat: 48.396721, lng: 19.121559 },
    { lat: 48.396559, lng: 19.121391 },
    { lat: 48.396471, lng: 19.121573 },
    { lat: 48.396328, lng: 19.121858 },
    { lat: 48.39622, lng: 19.122075 },
    { lat: 48.396086, lng: 19.122436 },
    { lat: 48.395934, lng: 19.122482 },
    { lat: 48.395796, lng: 19.122651 },
    { lat: 48.395702, lng: 19.12277 },
    { lat: 48.395375, lng: 19.123047 },
    { lat: 48.395342, lng: 19.123052 },
    { lat: 48.395212, lng: 19.123179 },
    { lat: 48.395151, lng: 19.123322 },
    { lat: 48.394845, lng: 19.123423 },
    { lat: 48.394647, lng: 19.123468 },
    { lat: 48.394342, lng: 19.12357 },
    { lat: 48.394233, lng: 19.123558 },
    { lat: 48.393973, lng: 19.124162 },
    { lat: 48.393771, lng: 19.124789 },
    { lat: 48.393705, lng: 19.124958 },
    { lat: 48.393625, lng: 19.125138 },
    { lat: 48.393521, lng: 19.125588 },
    { lat: 48.393427, lng: 19.125771 },
    { lat: 48.393207, lng: 19.126441 },
    { lat: 48.393016, lng: 19.126955 },
    { lat: 48.392954, lng: 19.127018 },
    { lat: 48.392924, lng: 19.127605 },
    { lat: 48.39248, lng: 19.127596 },
    { lat: 48.392013, lng: 19.127706 },
    { lat: 48.391479, lng: 19.127487 },
    { lat: 48.391209, lng: 19.127284 },
    { lat: 48.390918, lng: 19.127235 },
    { lat: 48.390635, lng: 19.127315 },
    { lat: 48.390196, lng: 19.12744 },
    { lat: 48.389635, lng: 19.127518 },
    { lat: 48.3894, lng: 19.127583 },
    { lat: 48.388936, lng: 19.127492 },
    { lat: 48.388827, lng: 19.127387 },
    { lat: 48.388684, lng: 19.127275 },
    { lat: 48.388582, lng: 19.127321 },
    { lat: 48.388412, lng: 19.127213 },
    { lat: 48.388217, lng: 19.127045 },
    { lat: 48.388005, lng: 19.126887 },
    { lat: 48.387768, lng: 19.126683 },
    { lat: 48.387578, lng: 19.126522 },
    { lat: 48.387353, lng: 19.12619 },
    { lat: 48.387298, lng: 19.12628 },
    { lat: 48.38694, lng: 19.127774 },
    { lat: 48.386871, lng: 19.128264 },
    { lat: 48.386857, lng: 19.128512 },
    { lat: 48.386532, lng: 19.128459 },
    { lat: 48.386515, lng: 19.128947 },
    { lat: 48.386511, lng: 19.129015 },
    { lat: 48.386509, lng: 19.129082 },
    { lat: 48.386509, lng: 19.129115 },
    { lat: 48.386515, lng: 19.129216 },
    { lat: 48.386577, lng: 19.129495 },
    { lat: 48.386591, lng: 19.12967 },
    { lat: 48.38639, lng: 19.131006 },
    { lat: 48.386404, lng: 19.13106 },
    { lat: 48.386474, lng: 19.13137 },
    { lat: 48.386489, lng: 19.131616 },
    { lat: 48.386548, lng: 19.131986 },
    { lat: 48.386298, lng: 19.132864 },
    { lat: 48.385913, lng: 19.134442 },
    { lat: 48.385886, lng: 19.134811 },
    { lat: 48.385875, lng: 19.135049 },
    { lat: 48.385905, lng: 19.135176 },
    { lat: 48.385976, lng: 19.135504 },
    { lat: 48.385934, lng: 19.136303 },
    { lat: 48.385937, lng: 19.136354 },
    { lat: 48.386098, lng: 19.136495 },
    { lat: 48.386142, lng: 19.137059 },
    { lat: 48.38613, lng: 19.137583 },
    { lat: 48.386109, lng: 19.138069 },
    { lat: 48.386115, lng: 19.138288 },
    { lat: 48.385991, lng: 19.1382 },
    { lat: 48.386034, lng: 19.13856 },
    { lat: 48.385886, lng: 19.139092 },
    { lat: 48.385701, lng: 19.139746 },
    { lat: 48.385821, lng: 19.139867 },
    { lat: 48.385934, lng: 19.140039 },
    { lat: 48.385789, lng: 19.140927 },
    { lat: 48.385696, lng: 19.14154 },
    { lat: 48.385668, lng: 19.141742 },
    { lat: 48.385556, lng: 19.142583 },
    { lat: 48.385519, lng: 19.142708 },
    { lat: 48.385315, lng: 19.143405 },
    { lat: 48.385202, lng: 19.143652 },
    { lat: 48.385175, lng: 19.143717 },
    { lat: 48.3866896, lng: 19.1456556 },
    { lat: 48.3877679, lng: 19.1464057 },
    { lat: 48.3882081, lng: 19.1464658 },
    { lat: 48.3882573, lng: 19.1463675 },
    { lat: 48.3883682, lng: 19.1463466 },
    { lat: 48.3884986, lng: 19.1465257 },
    { lat: 48.388614, lng: 19.146599 },
    { lat: 48.3886351, lng: 19.1465764 },
    { lat: 48.3890279, lng: 19.1455316 },
    { lat: 48.3892757, lng: 19.1452961 },
    { lat: 48.3896006, lng: 19.1448263 },
    { lat: 48.3907076, lng: 19.1439368 },
    { lat: 48.3910819, lng: 19.1433055 },
    { lat: 48.3913879, lng: 19.1429244 },
    { lat: 48.3924756, lng: 19.1425371 },
    { lat: 48.3926668, lng: 19.1423894 },
    { lat: 48.392829, lng: 19.1427075 },
    { lat: 48.3930406, lng: 19.1428339 },
    { lat: 48.3931893, lng: 19.1427187 },
    { lat: 48.3937095, lng: 19.1426602 },
    { lat: 48.3944089, lng: 19.1421925 },
    { lat: 48.3950642, lng: 19.1415399 },
    { lat: 48.3956194, lng: 19.1416173 },
    { lat: 48.3956403, lng: 19.1409945 },
    { lat: 48.3956027, lng: 19.1408508 },
    { lat: 48.3951751, lng: 19.1405935 },
    { lat: 48.3952574, lng: 19.1402771 },
    { lat: 48.3952791, lng: 19.1399611 },
    { lat: 48.3956365, lng: 19.1385086 },
    { lat: 48.3958387, lng: 19.1385179 },
    { lat: 48.3959898, lng: 19.138451 },
    { lat: 48.3960975, lng: 19.1386684 },
    { lat: 48.396364, lng: 19.1389742 },
    { lat: 48.3963866, lng: 19.1391536 },
    { lat: 48.3971276, lng: 19.1398661 },
    { lat: 48.3973492, lng: 19.1399239 },
    { lat: 48.3975429, lng: 19.1400991 },
    { lat: 48.3977445, lng: 19.140597 },
    { lat: 48.3985097, lng: 19.1413412 },
    { lat: 48.3990283, lng: 19.1415712 },
    { lat: 48.3996007, lng: 19.1421993 },
    { lat: 48.3997769, lng: 19.1422325 },
    { lat: 48.4002422, lng: 19.142818 },
    { lat: 48.4005588, lng: 19.1429709 },
    { lat: 48.4006348, lng: 19.1428883 },
    { lat: 48.4009445, lng: 19.1430637 },
    { lat: 48.4015706, lng: 19.1440967 },
    { lat: 48.4018843, lng: 19.1443997 },
    { lat: 48.4019059, lng: 19.1443352 },
    { lat: 48.4021877, lng: 19.1450178 },
    { lat: 48.4023853, lng: 19.1453204 },
    { lat: 48.4024658, lng: 19.1453281 },
    { lat: 48.402671, lng: 19.1456266 },
    { lat: 48.4027388, lng: 19.1456073 },
    { lat: 48.4029969, lng: 19.1458101 },
    { lat: 48.4034604, lng: 19.1459247 },
    { lat: 48.4034919, lng: 19.1458559 },
    { lat: 48.4037728, lng: 19.1458844 },
    { lat: 48.4042995, lng: 19.1462304 },
    { lat: 48.4046875, lng: 19.1468476 },
    { lat: 48.4054546, lng: 19.147039 },
    { lat: 48.4061297, lng: 19.1477878 },
    { lat: 48.4067109, lng: 19.1480276 },
    { lat: 48.4066701, lng: 19.1482532 },
    { lat: 48.4067786, lng: 19.1485739 },
    { lat: 48.4075398, lng: 19.148332 },
    { lat: 48.4076801, lng: 19.1481609 },
    { lat: 48.4079994, lng: 19.1480958 },
    { lat: 48.4082985, lng: 19.1476253 },
    { lat: 48.4087443, lng: 19.1474974 },
    { lat: 48.4090048, lng: 19.1469751 },
    { lat: 48.4089676, lng: 19.1466595 },
    { lat: 48.4091906, lng: 19.145882 },
    { lat: 48.4094745, lng: 19.1457843 },
    { lat: 48.409773, lng: 19.1455544 },
    { lat: 48.4099538, lng: 19.1455845 },
    { lat: 48.4102214, lng: 19.1457494 },
    { lat: 48.4108985, lng: 19.1457223 },
    { lat: 48.4112691, lng: 19.1455315 },
    { lat: 48.4122512, lng: 19.1464242 },
    { lat: 48.4124091, lng: 19.1466744 },
    { lat: 48.4129371, lng: 19.1468659 },
    { lat: 48.413377, lng: 19.1472341 },
    { lat: 48.4134065, lng: 19.1472382 },
    { lat: 48.4134488, lng: 19.1469092 },
    { lat: 48.4135681, lng: 19.1467241 },
    { lat: 48.4136673, lng: 19.1465888 },
    { lat: 48.4137465, lng: 19.1464085 },
    { lat: 48.4136438, lng: 19.1461585 },
    { lat: 48.4136803, lng: 19.1459834 },
    { lat: 48.4138379, lng: 19.1460942 },
    { lat: 48.4139739, lng: 19.146041 },
    { lat: 48.4139759, lng: 19.1458283 },
    { lat: 48.4138455, lng: 19.145494 },
    { lat: 48.4139438, lng: 19.144274 },
    { lat: 48.414306, lng: 19.1437566 },
    { lat: 48.4145615, lng: 19.143767 },
    { lat: 48.4148302, lng: 19.143666 },
    { lat: 48.4149586, lng: 19.1432961 },
    { lat: 48.4149357, lng: 19.1428341 },
    { lat: 48.4146477, lng: 19.1424852 },
    { lat: 48.4147552, lng: 19.1419909 },
    { lat: 48.4148456, lng: 19.1418843 },
    { lat: 48.4149797, lng: 19.1419239 },
    { lat: 48.4153313, lng: 19.1416302 },
    { lat: 48.4153015, lng: 19.1413024 },
    { lat: 48.4151353, lng: 19.1411755 },
    { lat: 48.4151452, lng: 19.140784 },
    { lat: 48.415265, lng: 19.1404938 },
    { lat: 48.4153103, lng: 19.1402255 },
    { lat: 48.4154271, lng: 19.140014 },
    { lat: 48.4154851, lng: 19.13973 },
    { lat: 48.4154026, lng: 19.1394777 },
    { lat: 48.4154103, lng: 19.1391518 },
    { lat: 48.415589, lng: 19.1391616 },
    { lat: 48.415685, lng: 19.1395082 },
    { lat: 48.4159565, lng: 19.1397092 },
    { lat: 48.4161121, lng: 19.1390746 },
    { lat: 48.4161251, lng: 19.1385402 },
    { lat: 48.4162418, lng: 19.1380732 },
    { lat: 48.4162261, lng: 19.1378042 },
    { lat: 48.4160675, lng: 19.1378117 },
    { lat: 48.4159272, lng: 19.1376837 },
    { lat: 48.4158951, lng: 19.1365609 },
    { lat: 48.4166521, lng: 19.1363675 },
    { lat: 48.4168721, lng: 19.1361466 },
    { lat: 48.4170094, lng: 19.1357749 },
    { lat: 48.4170935, lng: 19.1350664 },
    { lat: 48.4170748, lng: 19.1347314 },
    { lat: 48.4173191, lng: 19.1342721 },
    { lat: 48.4170749, lng: 19.1332355 },
    { lat: 48.4170979, lng: 19.1324941 },
    { lat: 48.4172521, lng: 19.1323316 },
    { lat: 48.4174572, lng: 19.1323079 },
    { lat: 48.4176819, lng: 19.1318622 },
    { lat: 48.4174838, lng: 19.1318457 },
    { lat: 48.4171875, lng: 19.1313725 },
    { lat: 48.4171889, lng: 19.1309014 },
    { lat: 48.417258, lng: 19.130838 },
    { lat: 48.4175573, lng: 19.1310759 },
    { lat: 48.4176708, lng: 19.1309358 },
    { lat: 48.4177001, lng: 19.1305928 },
    { lat: 48.4178265, lng: 19.1302976 },
    { lat: 48.417841, lng: 19.13 },
    { lat: 48.417707, lng: 19.1297322 },
    { lat: 48.4177438, lng: 19.129409 },
    { lat: 48.4178563, lng: 19.1292431 },
    { lat: 48.4178772, lng: 19.1288039 },
    { lat: 48.4181604, lng: 19.1286021 },
    { lat: 48.4185892, lng: 19.1287446 },
    { lat: 48.4186644, lng: 19.1287177 },
    { lat: 48.4186279, lng: 19.1284354 },
    { lat: 48.4188104, lng: 19.1279333 },
    { lat: 48.4191259, lng: 19.127547 },
    { lat: 48.4192028, lng: 19.1271891 },
    { lat: 48.4192152, lng: 19.1268907 },
    { lat: 48.419563, lng: 19.1260648 },
    { lat: 48.4196332, lng: 19.1254586 },
    { lat: 48.4195857, lng: 19.124934 },
    { lat: 48.4193785, lng: 19.1246673 },
    { lat: 48.4190773, lng: 19.1247057 },
    { lat: 48.4188076, lng: 19.1244088 },
    { lat: 48.4186566, lng: 19.1241361 },
    { lat: 48.4186595, lng: 19.1236532 },
    { lat: 48.4183874, lng: 19.1233689 },
    { lat: 48.4182371, lng: 19.1230699 },
    { lat: 48.4184093, lng: 19.1227038 },
    { lat: 48.4177684, lng: 19.1216682 },
    { lat: 48.417573, lng: 19.1210251 },
    { lat: 48.4172131, lng: 19.120322 },
    { lat: 48.4169467, lng: 19.1194061 },
    { lat: 48.416755, lng: 19.1186827 },
    { lat: 48.4169812, lng: 19.1182446 },
    { lat: 48.4169184, lng: 19.1180032 },
    { lat: 48.4162301, lng: 19.1168093 },
    { lat: 48.415718, lng: 19.115628 },
  ],
  OstráLúka: [
    { lat: 48.5617808, lng: 19.0278389 },
    { lat: 48.5615569, lng: 19.0279386 },
    { lat: 48.5598608, lng: 19.0284904 },
    { lat: 48.5597193, lng: 19.0279428 },
    { lat: 48.5596638, lng: 19.0272405 },
    { lat: 48.5597496, lng: 19.0253443 },
    { lat: 48.5601274, lng: 19.0250362 },
    { lat: 48.5600746, lng: 19.0248366 },
    { lat: 48.5601259, lng: 19.0242256 },
    { lat: 48.5600804, lng: 19.0230328 },
    { lat: 48.5602286, lng: 19.0219672 },
    { lat: 48.5601793, lng: 19.0214639 },
    { lat: 48.5602805, lng: 19.0209882 },
    { lat: 48.5602184, lng: 19.0204269 },
    { lat: 48.5602795, lng: 19.0202294 },
    { lat: 48.5603798, lng: 19.0201848 },
    { lat: 48.5604729, lng: 19.0193723 },
    { lat: 48.5606609, lng: 19.0192119 },
    { lat: 48.560748, lng: 19.019061 },
    { lat: 48.5607592, lng: 19.0187251 },
    { lat: 48.560894, lng: 19.0181341 },
    { lat: 48.561131, lng: 19.0174716 },
    { lat: 48.5596982, lng: 19.0173372 },
    { lat: 48.5592793, lng: 19.0171269 },
    { lat: 48.5591069, lng: 19.0171744 },
    { lat: 48.5587793, lng: 19.0171397 },
    { lat: 48.5584156, lng: 19.0170334 },
    { lat: 48.5568825, lng: 19.017753 },
    { lat: 48.5552984, lng: 19.017552 },
    { lat: 48.5544399, lng: 19.0175454 },
    { lat: 48.5530915, lng: 19.0176776 },
    { lat: 48.5524254, lng: 19.0173269 },
    { lat: 48.5514686, lng: 19.0174014 },
    { lat: 48.5506319, lng: 19.0172411 },
    { lat: 48.5501519, lng: 19.0172228 },
    { lat: 48.549511, lng: 19.0168358 },
    { lat: 48.5486789, lng: 19.0167657 },
    { lat: 48.5480071, lng: 19.016816 },
    { lat: 48.5470657, lng: 19.0165503 },
    { lat: 48.5461028, lng: 19.0165244 },
    { lat: 48.5453404, lng: 19.0161649 },
    { lat: 48.5447679, lng: 19.0157246 },
    { lat: 48.5438538, lng: 19.0151722 },
    { lat: 48.5434805, lng: 19.014724 },
    { lat: 48.543248, lng: 19.014211 },
    { lat: 48.542554, lng: 19.014121 },
    { lat: 48.54178, lng: 19.013953 },
    { lat: 48.540789, lng: 19.014141 },
    { lat: 48.539732, lng: 19.014752 },
    { lat: 48.539677, lng: 19.014816 },
    { lat: 48.539411, lng: 19.015099 },
    { lat: 48.539161, lng: 19.015287 },
    { lat: 48.538996, lng: 19.015308 },
    { lat: 48.538639, lng: 19.015201 },
    { lat: 48.538429, lng: 19.015183 },
    { lat: 48.537775, lng: 19.014809 },
    { lat: 48.537422, lng: 19.014511 },
    { lat: 48.537187, lng: 19.014243 },
    { lat: 48.536808, lng: 19.01425 },
    { lat: 48.536275, lng: 19.013502 },
    { lat: 48.536075, lng: 19.013365 },
    { lat: 48.535932, lng: 19.013314 },
    { lat: 48.535862, lng: 19.013289 },
    { lat: 48.535657, lng: 19.013334 },
    { lat: 48.535483, lng: 19.013522 },
    { lat: 48.535119, lng: 19.013787 },
    { lat: 48.534641, lng: 19.013966 },
    { lat: 48.534462, lng: 19.014005 },
    { lat: 48.534147, lng: 19.013887 },
    { lat: 48.534015, lng: 19.01377 },
    { lat: 48.533824, lng: 19.013548 },
    { lat: 48.533728, lng: 19.013405 },
    { lat: 48.533324, lng: 19.012848 },
    { lat: 48.532735, lng: 19.01289 },
    { lat: 48.532393, lng: 19.012996 },
    { lat: 48.532148, lng: 19.01314 },
    { lat: 48.532024, lng: 19.013159 },
    { lat: 48.531652, lng: 19.013033 },
    { lat: 48.530929, lng: 19.012333 },
    { lat: 48.530545, lng: 19.012095 },
    { lat: 48.530001, lng: 19.012481 },
    { lat: 48.529392, lng: 19.012705 },
    { lat: 48.529038, lng: 19.012451 },
    { lat: 48.528266, lng: 19.012424 },
    { lat: 48.528163, lng: 19.012472 },
    { lat: 48.5278, lng: 19.012531 },
    { lat: 48.527349, lng: 19.012539 },
    { lat: 48.52611, lng: 19.012409 },
    { lat: 48.525587, lng: 19.012266 },
    { lat: 48.525316, lng: 19.012158 },
    { lat: 48.524606, lng: 19.011697 },
    { lat: 48.524294, lng: 19.010945 },
    { lat: 48.524163, lng: 19.010891 },
    { lat: 48.52403, lng: 19.010784 },
    { lat: 48.52381, lng: 19.010821 },
    { lat: 48.523637, lng: 19.011682 },
    { lat: 48.52338, lng: 19.012322 },
    { lat: 48.523243, lng: 19.013419 },
    { lat: 48.5234806, lng: 19.0138998 },
    { lat: 48.526527, lng: 19.0218816 },
    { lat: 48.5281548, lng: 19.0263204 },
    { lat: 48.52909, lng: 19.0284757 },
    { lat: 48.5290574, lng: 19.0299325 },
    { lat: 48.5296351, lng: 19.0320171 },
    { lat: 48.5297248, lng: 19.0329018 },
    { lat: 48.5298205, lng: 19.0349128 },
    { lat: 48.5295772, lng: 19.036728 },
    { lat: 48.5290476, lng: 19.0377656 },
    { lat: 48.5283935, lng: 19.0398212 },
    { lat: 48.5283571, lng: 19.0404628 },
    { lat: 48.5283917, lng: 19.042136 },
    { lat: 48.5284764, lng: 19.0433681 },
    { lat: 48.5285605, lng: 19.0438095 },
    { lat: 48.5285451, lng: 19.0446013 },
    { lat: 48.5286984, lng: 19.0454044 },
    { lat: 48.5291754, lng: 19.0467757 },
    { lat: 48.5293636, lng: 19.0471996 },
    { lat: 48.5296252, lng: 19.0483848 },
    { lat: 48.5297361, lng: 19.0495624 },
    { lat: 48.5299629, lng: 19.0505727 },
    { lat: 48.5309613, lng: 19.052383 },
    { lat: 48.5313836, lng: 19.0549527 },
    { lat: 48.5315391, lng: 19.0572208 },
    { lat: 48.5312885, lng: 19.0578192 },
    { lat: 48.5307271, lng: 19.0583117 },
    { lat: 48.5305636, lng: 19.0587173 },
    { lat: 48.5306366, lng: 19.0592536 },
    { lat: 48.5303651, lng: 19.0609837 },
    { lat: 48.5303943, lng: 19.0612973 },
    { lat: 48.5305015, lng: 19.0614106 },
    { lat: 48.5303875, lng: 19.063253 },
    { lat: 48.5304463, lng: 19.063639 },
    { lat: 48.5304861, lng: 19.0648805 },
    { lat: 48.5304088, lng: 19.0651141 },
    { lat: 48.5304206, lng: 19.0655289 },
    { lat: 48.5305219, lng: 19.06557 },
    { lat: 48.5306285, lng: 19.0659439 },
    { lat: 48.5307792, lng: 19.0669935 },
    { lat: 48.530753, lng: 19.0672814 },
    { lat: 48.530647, lng: 19.067684 },
    { lat: 48.5318038, lng: 19.0727292 },
    { lat: 48.5314782, lng: 19.0730724 },
    { lat: 48.531242, lng: 19.0731714 },
    { lat: 48.5308295, lng: 19.0731293 },
    { lat: 48.5301372, lng: 19.0736219 },
    { lat: 48.5292027, lng: 19.0740538 },
    { lat: 48.5281621, lng: 19.074843 },
    { lat: 48.5273803, lng: 19.075865 },
    { lat: 48.5266104, lng: 19.0767007 },
    { lat: 48.5252509, lng: 19.0774491 },
    { lat: 48.5246721, lng: 19.0774639 },
    { lat: 48.5243126, lng: 19.077308 },
    { lat: 48.5238731, lng: 19.0774663 },
    { lat: 48.5231196, lng: 19.078183 },
    { lat: 48.5223954, lng: 19.0787555 },
    { lat: 48.5222909, lng: 19.0789015 },
    { lat: 48.5222914, lng: 19.0789764 },
    { lat: 48.5221956, lng: 19.0792953 },
    { lat: 48.5218584, lng: 19.0798805 },
    { lat: 48.5217205, lng: 19.0805227 },
    { lat: 48.5216835, lng: 19.0815654 },
    { lat: 48.5212577, lng: 19.0828583 },
    { lat: 48.5211932, lng: 19.0842743 },
    { lat: 48.5207986, lng: 19.0853791 },
    { lat: 48.5203796, lng: 19.0858817 },
    { lat: 48.5199874, lng: 19.08609 },
    { lat: 48.520063, lng: 19.0862703 },
    { lat: 48.5207311, lng: 19.0868711 },
    { lat: 48.5210175, lng: 19.0872117 },
    { lat: 48.5213417, lng: 19.0877615 },
    { lat: 48.5205798, lng: 19.0886514 },
    { lat: 48.5202406, lng: 19.0886748 },
    { lat: 48.5200891, lng: 19.0885988 },
    { lat: 48.5194978, lng: 19.0887881 },
    { lat: 48.5194496, lng: 19.088727 },
    { lat: 48.5190685, lng: 19.0889053 },
    { lat: 48.5188979, lng: 19.0893072 },
    { lat: 48.5182973, lng: 19.0899438 },
    { lat: 48.5184077, lng: 19.0903705 },
    { lat: 48.5180982, lng: 19.0907678 },
    { lat: 48.5174323, lng: 19.091181 },
    { lat: 48.5172675, lng: 19.0913998 },
    { lat: 48.5172921, lng: 19.0914671 },
    { lat: 48.5172219, lng: 19.0915291 },
    { lat: 48.5173413, lng: 19.0918196 },
    { lat: 48.5173355, lng: 19.092152 },
    { lat: 48.5172536, lng: 19.0922299 },
    { lat: 48.5171604, lng: 19.0920801 },
    { lat: 48.5171349, lng: 19.0921331 },
    { lat: 48.5171804, lng: 19.0922542 },
    { lat: 48.5171357, lng: 19.0923097 },
    { lat: 48.5170371, lng: 19.0922085 },
    { lat: 48.5170227, lng: 19.0921244 },
    { lat: 48.5168361, lng: 19.0923137 },
    { lat: 48.5168623, lng: 19.0924086 },
    { lat: 48.516823, lng: 19.0924582 },
    { lat: 48.516764, lng: 19.0928571 },
    { lat: 48.5165384, lng: 19.093103 },
    { lat: 48.5163398, lng: 19.0934601 },
    { lat: 48.5163098, lng: 19.093583 },
    { lat: 48.5163697, lng: 19.0936751 },
    { lat: 48.517038, lng: 19.0939409 },
    { lat: 48.5177375, lng: 19.0940631 },
    { lat: 48.5192692, lng: 19.0933323 },
    { lat: 48.520061, lng: 19.092778 },
    { lat: 48.5214235, lng: 19.0924287 },
    { lat: 48.5225933, lng: 19.0924173 },
    { lat: 48.5239764, lng: 19.093771 },
    { lat: 48.5251746, lng: 19.09316 },
    { lat: 48.5262516, lng: 19.0922842 },
    { lat: 48.5275726, lng: 19.0918037 },
    { lat: 48.5288511, lng: 19.0917839 },
    { lat: 48.5300415, lng: 19.0914853 },
    { lat: 48.5312508, lng: 19.0912777 },
    { lat: 48.5331721, lng: 19.0906278 },
    { lat: 48.5340657, lng: 19.0900536 },
    { lat: 48.5348043, lng: 19.0899003 },
    { lat: 48.5352177, lng: 19.0899602 },
    { lat: 48.5356258, lng: 19.0900293 },
    { lat: 48.5358586, lng: 19.0901488 },
    { lat: 48.5365054, lng: 19.0908216 },
    { lat: 48.5376009, lng: 19.0907282 },
    { lat: 48.5382369, lng: 19.0909074 },
    { lat: 48.5388137, lng: 19.0904939 },
    { lat: 48.5396931, lng: 19.0896835 },
    { lat: 48.5397417, lng: 19.0894592 },
    { lat: 48.5405145, lng: 19.0890445 },
    { lat: 48.5407574, lng: 19.0889821 },
    { lat: 48.5415706, lng: 19.0890605 },
    { lat: 48.5422551, lng: 19.0888823 },
    { lat: 48.5424769, lng: 19.0886732 },
    { lat: 48.5437096, lng: 19.0878691 },
    { lat: 48.5439912, lng: 19.0877273 },
    { lat: 48.5448993, lng: 19.0874469 },
    { lat: 48.5458536, lng: 19.0868499 },
    { lat: 48.5464068, lng: 19.086592 },
    { lat: 48.5472289, lng: 19.0853567 },
    { lat: 48.5476535, lng: 19.0848951 },
    { lat: 48.5482431, lng: 19.0843078 },
    { lat: 48.5489547, lng: 19.0839304 },
    { lat: 48.5498157, lng: 19.0832699 },
    { lat: 48.5505982, lng: 19.0824119 },
    { lat: 48.5506188, lng: 19.0824412 },
    { lat: 48.550968, lng: 19.0818732 },
    { lat: 48.5515813, lng: 19.08115 },
    { lat: 48.5518873, lng: 19.0808879 },
    { lat: 48.5521268, lng: 19.0805377 },
    { lat: 48.5532361, lng: 19.0804019 },
    { lat: 48.5544812, lng: 19.080929 },
    { lat: 48.5546167, lng: 19.0813862 },
    { lat: 48.5548104, lng: 19.0815842 },
    { lat: 48.5558405, lng: 19.081151 },
    { lat: 48.5560923, lng: 19.0811787 },
    { lat: 48.5564764, lng: 19.0799106 },
    { lat: 48.5567405, lng: 19.0790361 },
    { lat: 48.5572927, lng: 19.0781076 },
    { lat: 48.5582053, lng: 19.0773087 },
    { lat: 48.5586296, lng: 19.0767523 },
    { lat: 48.559472, lng: 19.0748181 },
    { lat: 48.5595777, lng: 19.0741132 },
    { lat: 48.5596081, lng: 19.0735025 },
    { lat: 48.5596146, lng: 19.0727687 },
    { lat: 48.5597732, lng: 19.0715989 },
    { lat: 48.5597776, lng: 19.0707624 },
    { lat: 48.5602436, lng: 19.0695556 },
    { lat: 48.5605705, lng: 19.0689223 },
    { lat: 48.5608784, lng: 19.0685663 },
    { lat: 48.5621107, lng: 19.0675346 },
    { lat: 48.5632378, lng: 19.0661518 },
    { lat: 48.5647319, lng: 19.0637776 },
    { lat: 48.5650521, lng: 19.0634147 },
    { lat: 48.5660258, lng: 19.0612501 },
    { lat: 48.5662915, lng: 19.0600943 },
    { lat: 48.5663338, lng: 19.0592512 },
    { lat: 48.5662684, lng: 19.0584414 },
    { lat: 48.5659659, lng: 19.0570883 },
    { lat: 48.5657853, lng: 19.056565 },
    { lat: 48.5655203, lng: 19.0560481 },
    { lat: 48.5647247, lng: 19.0548206 },
    { lat: 48.5643344, lng: 19.0539834 },
    { lat: 48.5640658, lng: 19.053079 },
    { lat: 48.5638242, lng: 19.0516607 },
    { lat: 48.5634647, lng: 19.0502939 },
    { lat: 48.5633257, lng: 19.0495018 },
    { lat: 48.5631824, lng: 19.0461815 },
    { lat: 48.5628819, lng: 19.043658 },
    { lat: 48.5628865, lng: 19.0428766 },
    { lat: 48.5630211, lng: 19.0416307 },
    { lat: 48.563487, lng: 19.0397526 },
    { lat: 48.5636498, lng: 19.0389079 },
    { lat: 48.5637031, lng: 19.0384878 },
    { lat: 48.5636935, lng: 19.0378934 },
    { lat: 48.5636251, lng: 19.0374328 },
    { lat: 48.5633587, lng: 19.0365758 },
    { lat: 48.5618623, lng: 19.0334392 },
    { lat: 48.5615676, lng: 19.0324186 },
    { lat: 48.5614773, lng: 19.0316815 },
    { lat: 48.5614492, lng: 19.0306567 },
    { lat: 48.5614841, lng: 19.0297793 },
    { lat: 48.5617808, lng: 19.0278389 },
  ],
  Pliešovce: [
    { lat: 48.4021791, lng: 19.2136426 },
    { lat: 48.4022935, lng: 19.2137009 },
    { lat: 48.4033716, lng: 19.2133626 },
    { lat: 48.4036511, lng: 19.213475 },
    { lat: 48.4039889, lng: 19.2134473 },
    { lat: 48.4044331, lng: 19.2133089 },
    { lat: 48.406426, lng: 19.2119045 },
    { lat: 48.4066598, lng: 19.2119571 },
    { lat: 48.4072539, lng: 19.2116133 },
    { lat: 48.4073819, lng: 19.2115796 },
    { lat: 48.408013, lng: 19.2117132 },
    { lat: 48.4083089, lng: 19.2118849 },
    { lat: 48.4087658, lng: 19.2123553 },
    { lat: 48.4091559, lng: 19.212612 },
    { lat: 48.4095226, lng: 19.2126871 },
    { lat: 48.4098941, lng: 19.2129512 },
    { lat: 48.4102129, lng: 19.2133393 },
    { lat: 48.4107308, lng: 19.2136273 },
    { lat: 48.4108669, lng: 19.2137819 },
    { lat: 48.4110464, lng: 19.2141772 },
    { lat: 48.4111444, lng: 19.214292 },
    { lat: 48.411466, lng: 19.2144594 },
    { lat: 48.4118786, lng: 19.2145533 },
    { lat: 48.4121827, lng: 19.2148465 },
    { lat: 48.4130874, lng: 19.2154094 },
    { lat: 48.4132029, lng: 19.215352 },
    { lat: 48.413459, lng: 19.2155213 },
    { lat: 48.4146934, lng: 19.2164861 },
    { lat: 48.4153288, lng: 19.2167325 },
    { lat: 48.4157514, lng: 19.2175151 },
    { lat: 48.4159643, lng: 19.2177428 },
    { lat: 48.4164976, lng: 19.2178149 },
    { lat: 48.4165827, lng: 19.2176668 },
    { lat: 48.4165679, lng: 19.2172465 },
    { lat: 48.416656, lng: 19.2171796 },
    { lat: 48.417095, lng: 19.2172394 },
    { lat: 48.4177429, lng: 19.2172105 },
    { lat: 48.4177485, lng: 19.2175761 },
    { lat: 48.4179557, lng: 19.2175951 },
    { lat: 48.4180491, lng: 19.2180095 },
    { lat: 48.4187395, lng: 19.2181576 },
    { lat: 48.4196096, lng: 19.2182458 },
    { lat: 48.4202166, lng: 19.2185106 },
    { lat: 48.4204782, lng: 19.2183976 },
    { lat: 48.4213125, lng: 19.2186054 },
    { lat: 48.4214656, lng: 19.2193643 },
    { lat: 48.4209612, lng: 19.2192606 },
    { lat: 48.4186913, lng: 19.219659 },
    { lat: 48.4183975, lng: 19.2199707 },
    { lat: 48.4183776, lng: 19.220369 },
    { lat: 48.4185957, lng: 19.2207714 },
    { lat: 48.4198451, lng: 19.2213103 },
    { lat: 48.4201933, lng: 19.2216101 },
    { lat: 48.4204568, lng: 19.2219949 },
    { lat: 48.4209214, lng: 19.2230583 },
    { lat: 48.422005, lng: 19.2241518 },
    { lat: 48.4229743, lng: 19.2257661 },
    { lat: 48.4236528, lng: 19.2267546 },
    { lat: 48.4251032, lng: 19.2280613 },
    { lat: 48.4252381, lng: 19.228358 },
    { lat: 48.4252929, lng: 19.2283729 },
    { lat: 48.4259033, lng: 19.231085 },
    { lat: 48.4260854, lng: 19.23179 },
    { lat: 48.4262872, lng: 19.2323442 },
    { lat: 48.4264106, lng: 19.2324967 },
    { lat: 48.4279295, lng: 19.2311177 },
    { lat: 48.4274622, lng: 19.2296299 },
    { lat: 48.4285776, lng: 19.2298964 },
    { lat: 48.4289834, lng: 19.2298712 },
    { lat: 48.4301076, lng: 19.230245 },
    { lat: 48.4303936, lng: 19.2302156 },
    { lat: 48.4306404, lng: 19.2300989 },
    { lat: 48.4307032, lng: 19.2310181 },
    { lat: 48.4313657, lng: 19.2311283 },
    { lat: 48.4315277, lng: 19.2316741 },
    { lat: 48.4320168, lng: 19.2317895 },
    { lat: 48.4321412, lng: 19.231937 },
    { lat: 48.4321791, lng: 19.2321128 },
    { lat: 48.4324017, lng: 19.232445 },
    { lat: 48.4324521, lng: 19.2330862 },
    { lat: 48.4324229, lng: 19.2331803 },
    { lat: 48.4325988, lng: 19.2335996 },
    { lat: 48.4326319, lng: 19.233887 },
    { lat: 48.4325732, lng: 19.2338832 },
    { lat: 48.4325554, lng: 19.2340272 },
    { lat: 48.4334825, lng: 19.2345207 },
    { lat: 48.43358, lng: 19.2344422 },
    { lat: 48.4337661, lng: 19.2348398 },
    { lat: 48.4340683, lng: 19.2347931 },
    { lat: 48.4343848, lng: 19.2348545 },
    { lat: 48.4346506, lng: 19.2351088 },
    { lat: 48.434921, lng: 19.2355196 },
    { lat: 48.4377991, lng: 19.2361041 },
    { lat: 48.4380855, lng: 19.2371241 },
    { lat: 48.4384242, lng: 19.2393116 },
    { lat: 48.4384094, lng: 19.2395649 },
    { lat: 48.4385174, lng: 19.2409492 },
    { lat: 48.4403163, lng: 19.2421008 },
    { lat: 48.4402341, lng: 19.2535578 },
    { lat: 48.4403333, lng: 19.2542541 },
    { lat: 48.4406405, lng: 19.2549616 },
    { lat: 48.4410038, lng: 19.2561107 },
    { lat: 48.440886, lng: 19.2575816 },
    { lat: 48.4407243, lng: 19.2582455 },
    { lat: 48.4407638, lng: 19.2589664 },
    { lat: 48.4407199, lng: 19.259502 },
    { lat: 48.4405622, lng: 19.2600592 },
    { lat: 48.4404497, lng: 19.2607638 },
    { lat: 48.4404962, lng: 19.26149 },
    { lat: 48.4405817, lng: 19.2619755 },
    { lat: 48.4408036, lng: 19.262461 },
    { lat: 48.4408258, lng: 19.2638243 },
    { lat: 48.4409799, lng: 19.2647475 },
    { lat: 48.4412295, lng: 19.2669894 },
    { lat: 48.4413414, lng: 19.2674401 },
    { lat: 48.4410479, lng: 19.2686188 },
    { lat: 48.4407854, lng: 19.2689483 },
    { lat: 48.4404846, lng: 19.2697845 },
    { lat: 48.4404432, lng: 19.2702129 },
    { lat: 48.4409015, lng: 19.2709257 },
    { lat: 48.4416167, lng: 19.2716321 },
    { lat: 48.4422183, lng: 19.2723501 },
    { lat: 48.4422574, lng: 19.2726689 },
    { lat: 48.4421608, lng: 19.2731186 },
    { lat: 48.4424233, lng: 19.2734425 },
    { lat: 48.4425843, lng: 19.273787 },
    { lat: 48.4433988, lng: 19.2746874 },
    { lat: 48.443981, lng: 19.27564 },
    { lat: 48.444006, lng: 19.275639 },
    { lat: 48.4448, lng: 19.275404 },
    { lat: 48.446058, lng: 19.275309 },
    { lat: 48.448362, lng: 19.275116 },
    { lat: 48.448363, lng: 19.275092 },
    { lat: 48.448542, lng: 19.275063 },
    { lat: 48.448603, lng: 19.275043 },
    { lat: 48.448893, lng: 19.274932 },
    { lat: 48.449012, lng: 19.27487 },
    { lat: 48.44935, lng: 19.27472 },
    { lat: 48.449535, lng: 19.274677 },
    { lat: 48.449737, lng: 19.274685 },
    { lat: 48.449887, lng: 19.274653 },
    { lat: 48.450021, lng: 19.274538 },
    { lat: 48.450187, lng: 19.274448 },
    { lat: 48.450536, lng: 19.274343 },
    { lat: 48.450724, lng: 19.274288 },
    { lat: 48.450903, lng: 19.27427 },
    { lat: 48.451003, lng: 19.27428 },
    { lat: 48.451237, lng: 19.274313 },
    { lat: 48.451588, lng: 19.27427 },
    { lat: 48.451752, lng: 19.274286 },
    { lat: 48.452035, lng: 19.274363 },
    { lat: 48.452202, lng: 19.274398 },
    { lat: 48.452371, lng: 19.274352 },
    { lat: 48.452583, lng: 19.274336 },
    { lat: 48.452895, lng: 19.274337 },
    { lat: 48.453232, lng: 19.274357 },
    { lat: 48.453556, lng: 19.274341 },
    { lat: 48.453767, lng: 19.274378 },
    { lat: 48.453941, lng: 19.274452 },
    { lat: 48.454205, lng: 19.274562 },
    { lat: 48.454432, lng: 19.274637 },
    { lat: 48.454518, lng: 19.274659 },
    { lat: 48.454794, lng: 19.274695 },
    { lat: 48.454968, lng: 19.274732 },
    { lat: 48.455157, lng: 19.274722 },
    { lat: 48.455847, lng: 19.274653 },
    { lat: 48.456329, lng: 19.274677 },
    { lat: 48.456821, lng: 19.274739 },
    { lat: 48.457172, lng: 19.274873 },
    { lat: 48.45731, lng: 19.274922 },
    { lat: 48.457477, lng: 19.275046 },
    { lat: 48.457581, lng: 19.275083 },
    { lat: 48.457893, lng: 19.275153 },
    { lat: 48.458128, lng: 19.275287 },
    { lat: 48.458392, lng: 19.275438 },
    { lat: 48.458636, lng: 19.275602 },
    { lat: 48.458833, lng: 19.275731 },
    { lat: 48.459045, lng: 19.27596 },
    { lat: 48.459058, lng: 19.275937 },
    { lat: 48.459331, lng: 19.276202 },
    { lat: 48.459855, lng: 19.276648 },
    { lat: 48.461448, lng: 19.277722 },
    { lat: 48.463012, lng: 19.278877 },
    { lat: 48.463644, lng: 19.278997 },
    { lat: 48.464449, lng: 19.279113 },
    { lat: 48.465308, lng: 19.27909 },
    { lat: 48.466154, lng: 19.279058 },
    { lat: 48.466258, lng: 19.279044 },
    { lat: 48.466971, lng: 19.278984 },
    { lat: 48.467855, lng: 19.278721 },
    { lat: 48.468089, lng: 19.277414 },
    { lat: 48.468336, lng: 19.276887 },
    { lat: 48.468903, lng: 19.276608 },
    { lat: 48.469188, lng: 19.276423 },
    { lat: 48.46984, lng: 19.275952 },
    { lat: 48.469993, lng: 19.2757 },
    { lat: 48.470067, lng: 19.275578 },
    { lat: 48.470125, lng: 19.275482 },
    { lat: 48.470206, lng: 19.27522 },
    { lat: 48.470357, lng: 19.274882 },
    { lat: 48.470666, lng: 19.274201 },
    { lat: 48.471194, lng: 19.273075 },
    { lat: 48.47147, lng: 19.272646 },
    { lat: 48.471752, lng: 19.27182 },
    { lat: 48.471778, lng: 19.271362 },
    { lat: 48.47175, lng: 19.270797 },
    { lat: 48.471615, lng: 19.270095 },
    { lat: 48.471534, lng: 19.269881 },
    { lat: 48.471495, lng: 19.269789 },
    { lat: 48.47128, lng: 19.269372 },
    { lat: 48.471112, lng: 19.268957 },
    { lat: 48.47096, lng: 19.268409 },
    { lat: 48.470817, lng: 19.267496 },
    { lat: 48.47073, lng: 19.266813 },
    { lat: 48.470668, lng: 19.266353 },
    { lat: 48.47062, lng: 19.26597 },
    { lat: 48.470596, lng: 19.265733 },
    { lat: 48.470675, lng: 19.265267 },
    { lat: 48.470681, lng: 19.264837 },
    { lat: 48.470479, lng: 19.264151 },
    { lat: 48.470656, lng: 19.263674 },
    { lat: 48.470803, lng: 19.262993 },
    { lat: 48.470914, lng: 19.262099 },
    { lat: 48.471654, lng: 19.261032 },
    { lat: 48.471868, lng: 19.260879 },
    { lat: 48.471117, lng: 19.259567 },
    { lat: 48.470314, lng: 19.258371 },
    { lat: 48.470311, lng: 19.257234 },
    { lat: 48.470777, lng: 19.25629 },
    { lat: 48.47092, lng: 19.255477 },
    { lat: 48.471295, lng: 19.255146 },
    { lat: 48.471618, lng: 19.254818 },
    { lat: 48.472142, lng: 19.254164 },
    { lat: 48.472417, lng: 19.253278 },
    { lat: 48.472852, lng: 19.252221 },
    { lat: 48.473189, lng: 19.251808 },
    { lat: 48.473514, lng: 19.251429 },
    { lat: 48.473704, lng: 19.251211 },
    { lat: 48.473946, lng: 19.250926 },
    { lat: 48.474206, lng: 19.250703 },
    { lat: 48.474597, lng: 19.250364 },
    { lat: 48.474978, lng: 19.250047 },
    { lat: 48.475707, lng: 19.249456 },
    { lat: 48.475943, lng: 19.249058 },
    { lat: 48.476235, lng: 19.248459 },
    { lat: 48.47661, lng: 19.247929 },
    { lat: 48.477475, lng: 19.247414 },
    { lat: 48.477632, lng: 19.247393 },
    { lat: 48.477666, lng: 19.247389 },
    { lat: 48.478101, lng: 19.247331 },
    { lat: 48.478771, lng: 19.247416 },
    { lat: 48.4797, lng: 19.247574 },
    { lat: 48.481211, lng: 19.246778 },
    { lat: 48.481664, lng: 19.246701 },
    { lat: 48.481931, lng: 19.246118 },
    { lat: 48.482126, lng: 19.24567 },
    { lat: 48.482265, lng: 19.245376 },
    { lat: 48.482462, lng: 19.244907 },
    { lat: 48.482618, lng: 19.244522 },
    { lat: 48.482864, lng: 19.243905 },
    { lat: 48.483155, lng: 19.243239 },
    { lat: 48.483162, lng: 19.242977 },
    { lat: 48.484148, lng: 19.241639 },
    { lat: 48.484257, lng: 19.241625 },
    { lat: 48.484281, lng: 19.241487 },
    { lat: 48.484368, lng: 19.240979 },
    { lat: 48.4842, lng: 19.240251 },
    { lat: 48.484269, lng: 19.239872 },
    { lat: 48.484439, lng: 19.239534 },
    { lat: 48.484617, lng: 19.239081 },
    { lat: 48.484907, lng: 19.238579 },
    { lat: 48.485148, lng: 19.238391 },
    { lat: 48.485471, lng: 19.238084 },
    { lat: 48.485744, lng: 19.237664 },
    { lat: 48.485899, lng: 19.237625 },
    { lat: 48.486125, lng: 19.237719 },
    { lat: 48.486675, lng: 19.237463 },
    { lat: 48.48671, lng: 19.237485 },
    { lat: 48.486929, lng: 19.237747 },
    { lat: 48.487287, lng: 19.238252 },
    { lat: 48.487693, lng: 19.238848 },
    { lat: 48.488093, lng: 19.238501 },
    { lat: 48.488257, lng: 19.238471 },
    { lat: 48.488449, lng: 19.238486 },
    { lat: 48.488723, lng: 19.238333 },
    { lat: 48.489055, lng: 19.238155 },
    { lat: 48.489306, lng: 19.238017 },
    { lat: 48.489592, lng: 19.23782 },
    { lat: 48.490092, lng: 19.237543 },
    { lat: 48.490474, lng: 19.237249 },
    { lat: 48.4904087, lng: 19.236766 },
    { lat: 48.4902502, lng: 19.2361743 },
    { lat: 48.4901669, lng: 19.2353907 },
    { lat: 48.4900855, lng: 19.2336698 },
    { lat: 48.4897908, lng: 19.232552 },
    { lat: 48.489415, lng: 19.2315469 },
    { lat: 48.4892043, lng: 19.2308047 },
    { lat: 48.4881765, lng: 19.2293801 },
    { lat: 48.4880231, lng: 19.2293042 },
    { lat: 48.4873437, lng: 19.2281348 },
    { lat: 48.4870547, lng: 19.227499 },
    { lat: 48.4862503, lng: 19.2276474 },
    { lat: 48.4856117, lng: 19.2275658 },
    { lat: 48.4853692, lng: 19.2268961 },
    { lat: 48.4847564, lng: 19.2261386 },
    { lat: 48.4845887, lng: 19.2260072 },
    { lat: 48.4842012, lng: 19.2257089 },
    { lat: 48.4828354, lng: 19.225026 },
    { lat: 48.4824788, lng: 19.2241423 },
    { lat: 48.4823151, lng: 19.2235126 },
    { lat: 48.4822056, lng: 19.2233012 },
    { lat: 48.4819745, lng: 19.2215236 },
    { lat: 48.4816648, lng: 19.2201444 },
    { lat: 48.4816899, lng: 19.2179287 },
    { lat: 48.4815863, lng: 19.2174719 },
    { lat: 48.4816366, lng: 19.2166793 },
    { lat: 48.4817644, lng: 19.2158814 },
    { lat: 48.4823186, lng: 19.2153412 },
    { lat: 48.4824493, lng: 19.2151334 },
    { lat: 48.4831897, lng: 19.212937 },
    { lat: 48.4827423, lng: 19.2127336 },
    { lat: 48.4815826, lng: 19.2115394 },
    { lat: 48.481402, lng: 19.2112321 },
    { lat: 48.4812501, lng: 19.2106175 },
    { lat: 48.4807139, lng: 19.2093818 },
    { lat: 48.4807202, lng: 19.2089785 },
    { lat: 48.4808995, lng: 19.2083568 },
    { lat: 48.4811164, lng: 19.2080006 },
    { lat: 48.4812024, lng: 19.206437 },
    { lat: 48.4810342, lng: 19.2058331 },
    { lat: 48.4807208, lng: 19.205467 },
    { lat: 48.4806788, lng: 19.2044271 },
    { lat: 48.4803406, lng: 19.2028603 },
    { lat: 48.4799455, lng: 19.2016759 },
    { lat: 48.479372, lng: 19.2003449 },
    { lat: 48.4791968, lng: 19.1997344 },
    { lat: 48.4791027, lng: 19.1987823 },
    { lat: 48.4791152, lng: 19.1982085 },
    { lat: 48.4791734, lng: 19.1978784 },
    { lat: 48.4790645, lng: 19.1974986 },
    { lat: 48.4791948, lng: 19.196797 },
    { lat: 48.479477, lng: 19.1961837 },
    { lat: 48.4788234, lng: 19.1962555 },
    { lat: 48.4776022, lng: 19.1964787 },
    { lat: 48.476678, lng: 19.1963713 },
    { lat: 48.4756582, lng: 19.1965132 },
    { lat: 48.4751047, lng: 19.196721 },
    { lat: 48.4749763, lng: 19.1964489 },
    { lat: 48.4749756, lng: 19.196067 },
    { lat: 48.4744867, lng: 19.1950377 },
    { lat: 48.4738472, lng: 19.1946191 },
    { lat: 48.473656, lng: 19.1942591 },
    { lat: 48.4732097, lng: 19.1939161 },
    { lat: 48.472224, lng: 19.1941136 },
    { lat: 48.4718636, lng: 19.1941198 },
    { lat: 48.4706264, lng: 19.1945232 },
    { lat: 48.4697974, lng: 19.1949249 },
    { lat: 48.4684995, lng: 19.1967331 },
    { lat: 48.4680759, lng: 19.1962793 },
    { lat: 48.4678252, lng: 19.1959018 },
    { lat: 48.4675865, lng: 19.1952643 },
    { lat: 48.4674205, lng: 19.194659 },
    { lat: 48.4667784, lng: 19.1937719 },
    { lat: 48.4654046, lng: 19.1926324 },
    { lat: 48.4649818, lng: 19.1921469 },
    { lat: 48.4635507, lng: 19.1919289 },
    { lat: 48.4631167, lng: 19.1921575 },
    { lat: 48.4622483, lng: 19.1919449 },
    { lat: 48.4620663, lng: 19.1920006 },
    { lat: 48.4611351, lng: 19.1919068 },
    { lat: 48.459988, lng: 19.1922415 },
    { lat: 48.4595968, lng: 19.1924899 },
    { lat: 48.458596, lng: 19.1927714 },
    { lat: 48.4581507, lng: 19.1928677 },
    { lat: 48.4576422, lng: 19.1927923 },
    { lat: 48.4566623, lng: 19.1931987 },
    { lat: 48.455709, lng: 19.1928392 },
    { lat: 48.4552335, lng: 19.1928451 },
    { lat: 48.454661, lng: 19.1927325 },
    { lat: 48.4542429, lng: 19.1922746 },
    { lat: 48.4537373, lng: 19.1915324 },
    { lat: 48.4534011, lng: 19.1911791 },
    { lat: 48.4527536, lng: 19.1900911 },
    { lat: 48.4526877, lng: 19.1898985 },
    { lat: 48.4523722, lng: 19.1896456 },
    { lat: 48.4521953, lng: 19.1894205 },
    { lat: 48.4518389, lng: 19.1882254 },
    { lat: 48.4518198, lng: 19.1879523 },
    { lat: 48.4518293, lng: 19.1876831 },
    { lat: 48.4515523, lng: 19.1864969 },
    { lat: 48.451036, lng: 19.1856484 },
    { lat: 48.4508606, lng: 19.1852659 },
    { lat: 48.4504116, lng: 19.1830623 },
    { lat: 48.4500273, lng: 19.181799 },
    { lat: 48.4499087, lng: 19.1816361 },
    { lat: 48.4492233, lng: 19.181268 },
    { lat: 48.4489598, lng: 19.180912 },
    { lat: 48.4486367, lng: 19.1800475 },
    { lat: 48.4484897, lng: 19.1791005 },
    { lat: 48.4485129, lng: 19.1781509 },
    { lat: 48.4489879, lng: 19.1771524 },
    { lat: 48.4493683, lng: 19.1766783 },
    { lat: 48.4494365, lng: 19.1764847 },
    { lat: 48.4494614, lng: 19.1762337 },
    { lat: 48.4494106, lng: 19.1758682 },
    { lat: 48.4494986, lng: 19.1751603 },
    { lat: 48.4494457, lng: 19.1745189 },
    { lat: 48.449346, lng: 19.1741198 },
    { lat: 48.4492185, lng: 19.1737999 },
    { lat: 48.4484066, lng: 19.1725335 },
    { lat: 48.4481894, lng: 19.1723119 },
    { lat: 48.4481194, lng: 19.1721518 },
    { lat: 48.4479296, lng: 19.1710906 },
    { lat: 48.4476418, lng: 19.1704899 },
    { lat: 48.447283, lng: 19.17039 },
    { lat: 48.4469077, lng: 19.1696389 },
    { lat: 48.4466429, lng: 19.1685403 },
    { lat: 48.4461762, lng: 19.1671734 },
    { lat: 48.446036, lng: 19.1664079 },
    { lat: 48.4454697, lng: 19.1656213 },
    { lat: 48.4448343, lng: 19.1638273 },
    { lat: 48.4447359, lng: 19.1631782 },
    { lat: 48.444631, lng: 19.1618527 },
    { lat: 48.4444754, lng: 19.1615807 },
    { lat: 48.444188, lng: 19.1605983 },
    { lat: 48.4439383, lng: 19.1601522 },
    { lat: 48.4437655, lng: 19.1597585 },
    { lat: 48.4436978, lng: 19.159482 },
    { lat: 48.4435576, lng: 19.1592449 },
    { lat: 48.4434033, lng: 19.1585655 },
    { lat: 48.4431123, lng: 19.1578545 },
    { lat: 48.442766, lng: 19.1562606 },
    { lat: 48.4426252, lng: 19.1555261 },
    { lat: 48.4425472, lng: 19.1552153 },
    { lat: 48.4423171, lng: 19.1545147 },
    { lat: 48.4417283, lng: 19.1534153 },
    { lat: 48.4411583, lng: 19.1522161 },
    { lat: 48.4407833, lng: 19.1509255 },
    { lat: 48.4404166, lng: 19.149868 },
    { lat: 48.4402697, lng: 19.1490859 },
    { lat: 48.4400119, lng: 19.1481146 },
    { lat: 48.4399181, lng: 19.1479057 },
    { lat: 48.4394875, lng: 19.1478636 },
    { lat: 48.4388222, lng: 19.147676 },
    { lat: 48.4384721, lng: 19.1466149 },
    { lat: 48.4382247, lng: 19.1461281 },
    { lat: 48.4377602, lng: 19.1453728 },
    { lat: 48.4371788, lng: 19.1447175 },
    { lat: 48.4370721, lng: 19.1438617 },
    { lat: 48.4368528, lng: 19.1437592 },
    { lat: 48.4367893, lng: 19.1439122 },
    { lat: 48.4361306, lng: 19.1435386 },
    { lat: 48.4355603, lng: 19.1428132 },
    { lat: 48.4352866, lng: 19.1427047 },
    { lat: 48.4349651, lng: 19.1433154 },
    { lat: 48.4348323, lng: 19.1434584 },
    { lat: 48.4343274, lng: 19.1435291 },
    { lat: 48.4339015, lng: 19.14351 },
    { lat: 48.4330285, lng: 19.1418632 },
    { lat: 48.4327525, lng: 19.1416417 },
    { lat: 48.4322446, lng: 19.1418086 },
    { lat: 48.4318498, lng: 19.1420782 },
    { lat: 48.4315903, lng: 19.1423675 },
    { lat: 48.4314572, lng: 19.1422315 },
    { lat: 48.4312827, lng: 19.141847 },
    { lat: 48.4306929, lng: 19.1423025 },
    { lat: 48.4303078, lng: 19.1428308 },
    { lat: 48.4300028, lng: 19.14314 },
    { lat: 48.4292238, lng: 19.143022 },
    { lat: 48.4286572, lng: 19.1446022 },
    { lat: 48.4281224, lng: 19.1456626 },
    { lat: 48.4277942, lng: 19.1451836 },
    { lat: 48.4275005, lng: 19.1448774 },
    { lat: 48.4266982, lng: 19.1442232 },
    { lat: 48.42588, lng: 19.1439534 },
    { lat: 48.4250779, lng: 19.1438071 },
    { lat: 48.4248935, lng: 19.1442728 },
    { lat: 48.4237879, lng: 19.1443481 },
    { lat: 48.4230052, lng: 19.1452041 },
    { lat: 48.4226905, lng: 19.1457479 },
    { lat: 48.4226438, lng: 19.1460789 },
    { lat: 48.4218856, lng: 19.1459763 },
    { lat: 48.4218149, lng: 19.1464136 },
    { lat: 48.4213378, lng: 19.1464769 },
    { lat: 48.4213322, lng: 19.146683 },
    { lat: 48.4204978, lng: 19.1467916 },
    { lat: 48.4205006, lng: 19.1470535 },
    { lat: 48.4199982, lng: 19.147356 },
    { lat: 48.4184108, lng: 19.1473755 },
    { lat: 48.4173813, lng: 19.147256 },
    { lat: 48.4168764, lng: 19.1472596 },
    { lat: 48.4161085, lng: 19.1466417 },
    { lat: 48.4158968, lng: 19.1471703 },
    { lat: 48.4152889, lng: 19.1470729 },
    { lat: 48.4148362, lng: 19.1471104 },
    { lat: 48.4144751, lng: 19.1472174 },
    { lat: 48.4137527, lng: 19.1472396 },
    { lat: 48.4136504, lng: 19.1470641 },
    { lat: 48.4135681, lng: 19.1467241 },
    { lat: 48.4134488, lng: 19.1469092 },
    { lat: 48.4134065, lng: 19.1472382 },
    { lat: 48.413377, lng: 19.1472341 },
    { lat: 48.4129371, lng: 19.1468659 },
    { lat: 48.4124091, lng: 19.1466744 },
    { lat: 48.4122512, lng: 19.1464242 },
    { lat: 48.4112691, lng: 19.1455315 },
    { lat: 48.4108985, lng: 19.1457223 },
    { lat: 48.4102214, lng: 19.1457494 },
    { lat: 48.4099538, lng: 19.1455845 },
    { lat: 48.409773, lng: 19.1455544 },
    { lat: 48.4094745, lng: 19.1457843 },
    { lat: 48.4091906, lng: 19.145882 },
    { lat: 48.4089676, lng: 19.1466595 },
    { lat: 48.4090048, lng: 19.1469751 },
    { lat: 48.4087443, lng: 19.1474974 },
    { lat: 48.4082985, lng: 19.1476253 },
    { lat: 48.4079994, lng: 19.1480958 },
    { lat: 48.4076801, lng: 19.1481609 },
    { lat: 48.4075398, lng: 19.148332 },
    { lat: 48.4067786, lng: 19.1485739 },
    { lat: 48.4066701, lng: 19.1482532 },
    { lat: 48.4067109, lng: 19.1480276 },
    { lat: 48.4061297, lng: 19.1477878 },
    { lat: 48.4054546, lng: 19.147039 },
    { lat: 48.4046875, lng: 19.1468476 },
    { lat: 48.4042995, lng: 19.1462304 },
    { lat: 48.4037728, lng: 19.1458844 },
    { lat: 48.4034919, lng: 19.1458559 },
    { lat: 48.4034604, lng: 19.1459247 },
    { lat: 48.4029969, lng: 19.1458101 },
    { lat: 48.4027388, lng: 19.1456073 },
    { lat: 48.402671, lng: 19.1456266 },
    { lat: 48.4024658, lng: 19.1453281 },
    { lat: 48.4023853, lng: 19.1453204 },
    { lat: 48.4021877, lng: 19.1450178 },
    { lat: 48.4019059, lng: 19.1443352 },
    { lat: 48.4018843, lng: 19.1443997 },
    { lat: 48.4015706, lng: 19.1440967 },
    { lat: 48.4009445, lng: 19.1430637 },
    { lat: 48.4006348, lng: 19.1428883 },
    { lat: 48.4005588, lng: 19.1429709 },
    { lat: 48.4002422, lng: 19.142818 },
    { lat: 48.3997769, lng: 19.1422325 },
    { lat: 48.3996007, lng: 19.1421993 },
    { lat: 48.3990283, lng: 19.1415712 },
    { lat: 48.3985097, lng: 19.1413412 },
    { lat: 48.3977445, lng: 19.140597 },
    { lat: 48.3975429, lng: 19.1400991 },
    { lat: 48.3973492, lng: 19.1399239 },
    { lat: 48.3971276, lng: 19.1398661 },
    { lat: 48.3963866, lng: 19.1391536 },
    { lat: 48.396364, lng: 19.1389742 },
    { lat: 48.3960975, lng: 19.1386684 },
    { lat: 48.3959898, lng: 19.138451 },
    { lat: 48.3958387, lng: 19.1385179 },
    { lat: 48.3956365, lng: 19.1385086 },
    { lat: 48.3952791, lng: 19.1399611 },
    { lat: 48.3952574, lng: 19.1402771 },
    { lat: 48.3951751, lng: 19.1405935 },
    { lat: 48.3956027, lng: 19.1408508 },
    { lat: 48.3956403, lng: 19.1409945 },
    { lat: 48.3956194, lng: 19.1416173 },
    { lat: 48.3950642, lng: 19.1415399 },
    { lat: 48.3944089, lng: 19.1421925 },
    { lat: 48.3937095, lng: 19.1426602 },
    { lat: 48.3931893, lng: 19.1427187 },
    { lat: 48.3930406, lng: 19.1428339 },
    { lat: 48.392829, lng: 19.1427075 },
    { lat: 48.3926668, lng: 19.1423894 },
    { lat: 48.3924756, lng: 19.1425371 },
    { lat: 48.3913879, lng: 19.1429244 },
    { lat: 48.3910819, lng: 19.1433055 },
    { lat: 48.3907076, lng: 19.1439368 },
    { lat: 48.3896006, lng: 19.1448263 },
    { lat: 48.3892757, lng: 19.1452961 },
    { lat: 48.3890279, lng: 19.1455316 },
    { lat: 48.3886351, lng: 19.1465764 },
    { lat: 48.38865, lng: 19.146634 },
    { lat: 48.388513, lng: 19.147084 },
    { lat: 48.38845, lng: 19.147232 },
    { lat: 48.38838, lng: 19.147766 },
    { lat: 48.388262, lng: 19.14777 },
    { lat: 48.388148, lng: 19.147802 },
    { lat: 48.388139, lng: 19.147927 },
    { lat: 48.38813, lng: 19.147964 },
    { lat: 48.388119, lng: 19.147991 },
    { lat: 48.388059, lng: 19.148135 },
    { lat: 48.388027, lng: 19.148353 },
    { lat: 48.388024, lng: 19.148465 },
    { lat: 48.388024, lng: 19.149705 },
    { lat: 48.388013, lng: 19.150032 },
    { lat: 48.388002, lng: 19.15028 },
    { lat: 48.387996, lng: 19.150399 },
    { lat: 48.388007, lng: 19.150482 },
    { lat: 48.388076, lng: 19.150913 },
    { lat: 48.388085, lng: 19.150956 },
    { lat: 48.38809, lng: 19.150966 },
    { lat: 48.388158, lng: 19.151115 },
    { lat: 48.388383, lng: 19.151592 },
    { lat: 48.388505, lng: 19.151751 },
    { lat: 48.388714, lng: 19.152002 },
    { lat: 48.388815, lng: 19.152138 },
    { lat: 48.388895, lng: 19.152194 },
    { lat: 48.389021, lng: 19.152346 },
    { lat: 48.389089, lng: 19.152436 },
    { lat: 48.389124, lng: 19.152485 },
    { lat: 48.389163, lng: 19.152508 },
    { lat: 48.389279, lng: 19.152628 },
    { lat: 48.389327, lng: 19.152697 },
    { lat: 48.389399, lng: 19.15275 },
    { lat: 48.389456, lng: 19.152809 },
    { lat: 48.389532, lng: 19.152859 },
    { lat: 48.389596, lng: 19.152928 },
    { lat: 48.389686, lng: 19.152998 },
    { lat: 48.38974, lng: 19.153094 },
    { lat: 48.389784, lng: 19.15317 },
    { lat: 48.389808, lng: 19.153359 },
    { lat: 48.389835, lng: 19.153571 },
    { lat: 48.389843, lng: 19.153737 },
    { lat: 48.389915, lng: 19.154038 },
    { lat: 48.389945, lng: 19.154221 },
    { lat: 48.389986, lng: 19.15438 },
    { lat: 48.390001, lng: 19.154453 },
    { lat: 48.39005, lng: 19.154552 },
    { lat: 48.390094, lng: 19.154698 },
    { lat: 48.390205, lng: 19.155112 },
    { lat: 48.390307, lng: 19.15543 },
    { lat: 48.390349, lng: 19.155689 },
    { lat: 48.390362, lng: 19.155871 },
    { lat: 48.390367, lng: 19.156302 },
    { lat: 48.390382, lng: 19.156356 },
    { lat: 48.390389, lng: 19.156379 },
    { lat: 48.390433, lng: 19.156539 },
    { lat: 48.390455, lng: 19.156615 },
    { lat: 48.390578, lng: 19.15698 },
    { lat: 48.390659, lng: 19.157226 },
    { lat: 48.390755, lng: 19.157534 },
    { lat: 48.390838, lng: 19.157796 },
    { lat: 48.390839, lng: 19.157833 },
    { lat: 48.39084, lng: 19.157855 },
    { lat: 48.390806, lng: 19.157858 },
    { lat: 48.390668, lng: 19.157942 },
    { lat: 48.390522, lng: 19.157883 },
    { lat: 48.390369, lng: 19.157776 },
    { lat: 48.390256, lng: 19.157697 },
    { lat: 48.390059, lng: 19.157554 },
    { lat: 48.389895, lng: 19.157427 },
    { lat: 48.389606, lng: 19.157248 },
    { lat: 48.389534, lng: 19.157174 },
    { lat: 48.38939, lng: 19.157035 },
    { lat: 48.389375, lng: 19.157023 },
    { lat: 48.389156, lng: 19.156957 },
    { lat: 48.389049, lng: 19.156915 },
    { lat: 48.388693, lng: 19.156649 },
    { lat: 48.388593, lng: 19.156519 },
    { lat: 48.38849, lng: 19.156319 },
    { lat: 48.388358, lng: 19.155981 },
    { lat: 48.38805, lng: 19.155588 },
    { lat: 48.38778, lng: 19.155365 },
    { lat: 48.387757, lng: 19.155729 },
    { lat: 48.387618, lng: 19.15616 },
    { lat: 48.387423, lng: 19.156754 },
    { lat: 48.387274, lng: 19.156988 },
    { lat: 48.386921, lng: 19.156946 },
    { lat: 48.386816, lng: 19.156919 },
    { lat: 48.386659, lng: 19.157021 },
    { lat: 48.386587, lng: 19.157061 },
    { lat: 48.38642, lng: 19.157163 },
    { lat: 48.386345, lng: 19.157218 },
    { lat: 48.38632, lng: 19.157249 },
    { lat: 48.386237, lng: 19.157456 },
    { lat: 48.38625, lng: 19.158035 },
    { lat: 48.385974, lng: 19.158121 },
    { lat: 48.385699, lng: 19.15823 },
    { lat: 48.38529, lng: 19.15832 },
    { lat: 48.384858, lng: 19.158423 },
    { lat: 48.38421, lng: 19.158579 },
    { lat: 48.384063, lng: 19.1586 },
    { lat: 48.383934, lng: 19.158629 },
    { lat: 48.383677, lng: 19.159166 },
    { lat: 48.383244, lng: 19.159705 },
    { lat: 48.383743, lng: 19.160135 },
    { lat: 48.383815, lng: 19.160207 },
    { lat: 48.384216, lng: 19.160695 },
    { lat: 48.384543, lng: 19.161049 },
    { lat: 48.384725, lng: 19.161215 },
    { lat: 48.384917, lng: 19.161475 },
    { lat: 48.385009, lng: 19.161611 },
    { lat: 48.385164, lng: 19.16195 },
    { lat: 48.385269, lng: 19.162273 },
    { lat: 48.385498, lng: 19.1625 },
    { lat: 48.385578, lng: 19.162583 },
    { lat: 48.385763, lng: 19.162789 },
    { lat: 48.385818, lng: 19.162856 },
    { lat: 48.385831, lng: 19.162891 },
    { lat: 48.385997, lng: 19.163268 },
    { lat: 48.386123, lng: 19.163557 },
    { lat: 48.386179, lng: 19.163684 },
    { lat: 48.386279, lng: 19.163959 },
    { lat: 48.386571, lng: 19.164329 },
    { lat: 48.386754, lng: 19.164586 },
    { lat: 48.386861, lng: 19.164739 },
    { lat: 48.387239, lng: 19.165114 },
    { lat: 48.38767, lng: 19.165495 },
    { lat: 48.387739, lng: 19.165576 },
    { lat: 48.387994, lng: 19.165877 },
    { lat: 48.388001, lng: 19.16589 },
    { lat: 48.388152, lng: 19.166282 },
    { lat: 48.388368, lng: 19.166872 },
    { lat: 48.388361, lng: 19.166873 },
    { lat: 48.388739, lng: 19.16744 },
    { lat: 48.389269, lng: 19.168225 },
    { lat: 48.389244, lng: 19.168461 },
    { lat: 48.389221, lng: 19.168597 },
    { lat: 48.389082, lng: 19.169332 },
    { lat: 48.388794, lng: 19.17005 },
    { lat: 48.388794, lng: 19.170107 },
    { lat: 48.388825, lng: 19.170129 },
    { lat: 48.389032, lng: 19.170184 },
    { lat: 48.389456, lng: 19.170081 },
    { lat: 48.389616, lng: 19.17008 },
    { lat: 48.389656, lng: 19.17008 },
    { lat: 48.389678, lng: 19.170089 },
    { lat: 48.389717, lng: 19.170119 },
    { lat: 48.389737, lng: 19.170143 },
    { lat: 48.390189, lng: 19.169487 },
    { lat: 48.390223, lng: 19.169434 },
    { lat: 48.390496, lng: 19.169915 },
    { lat: 48.390576, lng: 19.170071 },
    { lat: 48.390641, lng: 19.1702598 },
    { lat: 48.3907387, lng: 19.1710687 },
    { lat: 48.3908249, lng: 19.1715633 },
    { lat: 48.390931, lng: 19.172422 },
    { lat: 48.390963, lng: 19.172584 },
    { lat: 48.390965, lng: 19.172606 },
    { lat: 48.391234, lng: 19.1724802 },
    { lat: 48.3930895, lng: 19.1723164 },
    { lat: 48.3939174, lng: 19.1726028 },
    { lat: 48.3941388, lng: 19.1726097 },
    { lat: 48.3942834, lng: 19.1725273 },
    { lat: 48.3944959, lng: 19.1722199 },
    { lat: 48.394689, lng: 19.171766 },
    { lat: 48.394833, lng: 19.1710757 },
    { lat: 48.3949133, lng: 19.1710132 },
    { lat: 48.3950427, lng: 19.1710967 },
    { lat: 48.3954158, lng: 19.1715543 },
    { lat: 48.3958385, lng: 19.1719202 },
    { lat: 48.3961839, lng: 19.1727061 },
    { lat: 48.3971058, lng: 19.1738884 },
    { lat: 48.3973085, lng: 19.1740227 },
    { lat: 48.3975337, lng: 19.17402 },
    { lat: 48.3986046, lng: 19.173787 },
    { lat: 48.3988131, lng: 19.1738334 },
    { lat: 48.3991877, lng: 19.1744114 },
    { lat: 48.4000069, lng: 19.1748188 },
    { lat: 48.4002841, lng: 19.1748149 },
    { lat: 48.4005748, lng: 19.1747184 },
    { lat: 48.4014515, lng: 19.173751 },
    { lat: 48.4020532, lng: 19.1728994 },
    { lat: 48.4028699, lng: 19.170329 },
    { lat: 48.4029413, lng: 19.1695594 },
    { lat: 48.4030297, lng: 19.1693154 },
    { lat: 48.4031004, lng: 19.1693317 },
    { lat: 48.4031459, lng: 19.1694543 },
    { lat: 48.4032867, lng: 19.1703454 },
    { lat: 48.4033709, lng: 19.170567 },
    { lat: 48.4043695, lng: 19.171957 },
    { lat: 48.4045819, lng: 19.1720824 },
    { lat: 48.4047935, lng: 19.172073 },
    { lat: 48.4050853, lng: 19.1717954 },
    { lat: 48.4054196, lng: 19.1707098 },
    { lat: 48.4056851, lng: 19.1779254 },
    { lat: 48.4056902, lng: 19.1795168 },
    { lat: 48.4056486, lng: 19.1800923 },
    { lat: 48.4055791, lng: 19.1806513 },
    { lat: 48.4048403, lng: 19.1804563 },
    { lat: 48.4045309, lng: 19.1829926 },
    { lat: 48.405259, lng: 19.1832133 },
    { lat: 48.4051681, lng: 19.1842569 },
    { lat: 48.4048651, lng: 19.1856589 },
    { lat: 48.4044231, lng: 19.1870511 },
    { lat: 48.4043422, lng: 19.1877225 },
    { lat: 48.4039209, lng: 19.1892181 },
    { lat: 48.4037668, lng: 19.1899366 },
    { lat: 48.4037175, lng: 19.1906076 },
    { lat: 48.4037954, lng: 19.1922646 },
    { lat: 48.4037051, lng: 19.1938679 },
    { lat: 48.4035328, lng: 19.1942858 },
    { lat: 48.4034302, lng: 19.19472 },
    { lat: 48.4031317, lng: 19.1967433 },
    { lat: 48.4026985, lng: 19.1983207 },
    { lat: 48.4020737, lng: 19.200059 },
    { lat: 48.4019308, lng: 19.2006428 },
    { lat: 48.4019834, lng: 19.2018722 },
    { lat: 48.402366, lng: 19.2046413 },
    { lat: 48.4021805, lng: 19.2070628 },
    { lat: 48.4022067, lng: 19.2080855 },
    { lat: 48.4024678, lng: 19.2101933 },
    { lat: 48.4023652, lng: 19.2116634 },
    { lat: 48.4023902, lng: 19.212406 },
    { lat: 48.4023515, lng: 19.2125366 },
    { lat: 48.4023874, lng: 19.2125556 },
    { lat: 48.4023494, lng: 19.2130239 },
    { lat: 48.4021791, lng: 19.2136426 },
  ],
  Tŕnie: [
    { lat: 48.5915864, lng: 19.039805 },
    { lat: 48.5918219, lng: 19.0397574 },
    { lat: 48.5935406, lng: 19.0383995 },
    { lat: 48.5946362, lng: 19.0371869 },
    { lat: 48.59485, lng: 19.0367959 },
    { lat: 48.5943866, lng: 19.0362356 },
    { lat: 48.5951107, lng: 19.0355915 },
    { lat: 48.5963763, lng: 19.0349997 },
    { lat: 48.5967369, lng: 19.035134 },
    { lat: 48.5974435, lng: 19.0356861 },
    { lat: 48.5974678, lng: 19.0356296 },
    { lat: 48.5984756, lng: 19.036009 },
    { lat: 48.5995524, lng: 19.035675 },
    { lat: 48.6001809, lng: 19.0369399 },
    { lat: 48.6011253, lng: 19.0362142 },
    { lat: 48.6014121, lng: 19.0370299 },
    { lat: 48.6016634, lng: 19.0370938 },
    { lat: 48.6023718, lng: 19.037463 },
    { lat: 48.6024666, lng: 19.0386053 },
    { lat: 48.6030094, lng: 19.0388435 },
    { lat: 48.6035962, lng: 19.0395711 },
    { lat: 48.60394, lng: 19.0398588 },
    { lat: 48.6045556, lng: 19.0390763 },
    { lat: 48.6046614, lng: 19.0390717 },
    { lat: 48.6053147, lng: 19.0385891 },
    { lat: 48.6060211, lng: 19.0382415 },
    { lat: 48.6065012, lng: 19.0378332 },
    { lat: 48.6068442, lng: 19.0377295 },
    { lat: 48.6069916, lng: 19.0375231 },
    { lat: 48.6080092, lng: 19.0371114 },
    { lat: 48.6082076, lng: 19.0371086 },
    { lat: 48.608811, lng: 19.0377151 },
    { lat: 48.6092405, lng: 19.0389271 },
    { lat: 48.6090747, lng: 19.0394167 },
    { lat: 48.6091556, lng: 19.0398849 },
    { lat: 48.6093791, lng: 19.0401796 },
    { lat: 48.6094973, lng: 19.0399758 },
    { lat: 48.6097926, lng: 19.0403311 },
    { lat: 48.610216, lng: 19.0403226 },
    { lat: 48.6106753, lng: 19.0401735 },
    { lat: 48.6121593, lng: 19.0393953 },
    { lat: 48.6124254, lng: 19.0396891 },
    { lat: 48.6131607, lng: 19.0392918 },
    { lat: 48.6135718, lng: 19.0399767 },
    { lat: 48.6140267, lng: 19.0401872 },
    { lat: 48.6141656, lng: 19.0396547 },
    { lat: 48.61463, lng: 19.040057 },
    { lat: 48.6150256, lng: 19.0409531 },
    { lat: 48.6151982, lng: 19.0418954 },
    { lat: 48.6168029, lng: 19.0411842 },
    { lat: 48.6178041, lng: 19.0413904 },
    { lat: 48.6180949, lng: 19.0412054 },
    { lat: 48.618179, lng: 19.040636 },
    { lat: 48.6187859, lng: 19.0418344 },
    { lat: 48.6189242, lng: 19.0419832 },
    { lat: 48.6191304, lng: 19.042631 },
    { lat: 48.6193116, lng: 19.0420662 },
    { lat: 48.6197009, lng: 19.0416892 },
    { lat: 48.6201878, lng: 19.0410591 },
    { lat: 48.6203472, lng: 19.0409789 },
    { lat: 48.6205866, lng: 19.0410074 },
    { lat: 48.6209124, lng: 19.0407396 },
    { lat: 48.6211598, lng: 19.0404352 },
    { lat: 48.6216046, lng: 19.0395276 },
    { lat: 48.6221, lng: 19.0387048 },
    { lat: 48.6225266, lng: 19.0382195 },
    { lat: 48.6228147, lng: 19.0382129 },
    { lat: 48.623032, lng: 19.0380469 },
    { lat: 48.6232052, lng: 19.0381083 },
    { lat: 48.6241294, lng: 19.0376287 },
    { lat: 48.624312, lng: 19.0376941 },
    { lat: 48.624619, lng: 19.0374341 },
    { lat: 48.6246513, lng: 19.0375833 },
    { lat: 48.6252152, lng: 19.0374977 },
    { lat: 48.6252982, lng: 19.0373532 },
    { lat: 48.6256648, lng: 19.0374937 },
    { lat: 48.6259015, lng: 19.0373568 },
    { lat: 48.6259796, lng: 19.0374299 },
    { lat: 48.6261718, lng: 19.0373747 },
    { lat: 48.6262147, lng: 19.0372601 },
    { lat: 48.6266852, lng: 19.0371135 },
    { lat: 48.6269804, lng: 19.0372654 },
    { lat: 48.6276959, lng: 19.0372816 },
    { lat: 48.6279412, lng: 19.0372036 },
    { lat: 48.6281801, lng: 19.0372896 },
    { lat: 48.6283358, lng: 19.0372233 },
    { lat: 48.6287036, lng: 19.0372985 },
    { lat: 48.6289881, lng: 19.0369466 },
    { lat: 48.629093, lng: 19.0370169 },
    { lat: 48.6292353, lng: 19.0369774 },
    { lat: 48.6293983, lng: 19.0371933 },
    { lat: 48.6296029, lng: 19.0369115 },
    { lat: 48.629879, lng: 19.0369993 },
    { lat: 48.6301663, lng: 19.0368356 },
    { lat: 48.6301715, lng: 19.0368493 },
    { lat: 48.6304855, lng: 19.0369958 },
    { lat: 48.6309305, lng: 19.0369884 },
    { lat: 48.6310039, lng: 19.0369016 },
    { lat: 48.631516, lng: 19.036906 },
    { lat: 48.6318238, lng: 19.0369991 },
    { lat: 48.6320401, lng: 19.0369259 },
    { lat: 48.6322627, lng: 19.0370487 },
    { lat: 48.6328349, lng: 19.0370399 },
    { lat: 48.6332331, lng: 19.0371789 },
    { lat: 48.6333271, lng: 19.0370509 },
    { lat: 48.6338389, lng: 19.0370483 },
    { lat: 48.6340601, lng: 19.0369406 },
    { lat: 48.6342027, lng: 19.0370195 },
    { lat: 48.6343047, lng: 19.0368731 },
    { lat: 48.6350458, lng: 19.036703 },
    { lat: 48.6352366, lng: 19.036503 },
    { lat: 48.6355014, lng: 19.0365033 },
    { lat: 48.6359668, lng: 19.0360943 },
    { lat: 48.636351, lng: 19.0360442 },
    { lat: 48.6366499, lng: 19.0361384 },
    { lat: 48.6367845, lng: 19.0360107 },
    { lat: 48.637021, lng: 19.0360959 },
    { lat: 48.6371944, lng: 19.03587 },
    { lat: 48.6377258, lng: 19.0360786 },
    { lat: 48.6379251, lng: 19.0360719 },
    { lat: 48.6380083, lng: 19.0358884 },
    { lat: 48.638179, lng: 19.036097 },
    { lat: 48.6392664, lng: 19.0359097 },
    { lat: 48.6394107, lng: 19.0359538 },
    { lat: 48.6395451, lng: 19.0358696 },
    { lat: 48.6408666, lng: 19.0360752 },
    { lat: 48.6411957, lng: 19.034726 },
    { lat: 48.6411879, lng: 19.0335843 },
    { lat: 48.6412202, lng: 19.0333307 },
    { lat: 48.6416189, lng: 19.0325399 },
    { lat: 48.6417922, lng: 19.0310527 },
    { lat: 48.6416007, lng: 19.030207 },
    { lat: 48.6415336, lng: 19.029568 },
    { lat: 48.6416301, lng: 19.0290625 },
    { lat: 48.6416462, lng: 19.0280171 },
    { lat: 48.6416207, lng: 19.028003 },
    { lat: 48.6412295, lng: 19.0281637 },
    { lat: 48.6410315, lng: 19.0280609 },
    { lat: 48.6408262, lng: 19.0281671 },
    { lat: 48.6402956, lng: 19.0279053 },
    { lat: 48.6397576, lng: 19.0273236 },
    { lat: 48.6395584, lng: 19.0272377 },
    { lat: 48.6392895, lng: 19.0269783 },
    { lat: 48.6390822, lng: 19.0267137 },
    { lat: 48.6389527, lng: 19.0264281 },
    { lat: 48.6385066, lng: 19.0259196 },
    { lat: 48.6383726, lng: 19.0254084 },
    { lat: 48.6383216, lng: 19.0249337 },
    { lat: 48.6377271, lng: 19.0245253 },
    { lat: 48.6374406, lng: 19.0246341 },
    { lat: 48.6371951, lng: 19.0245728 },
    { lat: 48.6368012, lng: 19.0247645 },
    { lat: 48.6361797, lng: 19.0247374 },
    { lat: 48.6358651, lng: 19.0248466 },
    { lat: 48.6356117, lng: 19.0252324 },
    { lat: 48.6352692, lng: 19.0254838 },
    { lat: 48.6349187, lng: 19.0255319 },
    { lat: 48.6343077, lng: 19.026024 },
    { lat: 48.6339771, lng: 19.0257493 },
    { lat: 48.6335661, lng: 19.0257183 },
    { lat: 48.6330627, lng: 19.0259489 },
    { lat: 48.6321515, lng: 19.0255662 },
    { lat: 48.6319621, lng: 19.0257027 },
    { lat: 48.6314972, lng: 19.0256701 },
    { lat: 48.6312578, lng: 19.0254167 },
    { lat: 48.6306454, lng: 19.0252788 },
    { lat: 48.6304965, lng: 19.025165 },
    { lat: 48.6302476, lng: 19.0251497 },
    { lat: 48.6300679, lng: 19.0250299 },
    { lat: 48.6294071, lng: 19.0249357 },
    { lat: 48.6290919, lng: 19.0246764 },
    { lat: 48.6289363, lng: 19.024923 },
    { lat: 48.6283877, lng: 19.0248192 },
    { lat: 48.6280853, lng: 19.0250631 },
    { lat: 48.627833, lng: 19.0249783 },
    { lat: 48.6275179, lng: 19.0250558 },
    { lat: 48.6271755, lng: 19.0247255 },
    { lat: 48.6270882, lng: 19.0244268 },
    { lat: 48.6266275, lng: 19.0237847 },
    { lat: 48.6265564, lng: 19.0234472 },
    { lat: 48.6262068, lng: 19.0231352 },
    { lat: 48.6257258, lng: 19.022536 },
    { lat: 48.6255012, lng: 19.0223547 },
    { lat: 48.6251498, lng: 19.0215326 },
    { lat: 48.6245849, lng: 19.0208868 },
    { lat: 48.6238899, lng: 19.0203621 },
    { lat: 48.6236691, lng: 19.0200083 },
    { lat: 48.6234419, lng: 19.0199499 },
    { lat: 48.622659, lng: 19.0193363 },
    { lat: 48.6222944, lng: 19.0192424 },
    { lat: 48.6220376, lng: 19.0190648 },
    { lat: 48.6219255, lng: 19.0190798 },
    { lat: 48.6216401, lng: 19.0188738 },
    { lat: 48.6209901, lng: 19.0215006 },
    { lat: 48.6207446, lng: 19.0219771 },
    { lat: 48.6206497, lng: 19.0227338 },
    { lat: 48.619972, lng: 19.022684 },
    { lat: 48.6194645, lng: 19.0219443 },
    { lat: 48.6190421, lng: 19.0216158 },
    { lat: 48.6186568, lng: 19.0215328 },
    { lat: 48.6182981, lng: 19.0215974 },
    { lat: 48.618211, lng: 19.0215476 },
    { lat: 48.6177973, lng: 19.0222404 },
    { lat: 48.6173693, lng: 19.0225831 },
    { lat: 48.6166349, lng: 19.0234013 },
    { lat: 48.6162615, lng: 19.0240257 },
    { lat: 48.6157436, lng: 19.0245974 },
    { lat: 48.6146124, lng: 19.0251743 },
    { lat: 48.6131112, lng: 19.0251597 },
    { lat: 48.6126803, lng: 19.024887 },
    { lat: 48.6126154, lng: 19.0247395 },
    { lat: 48.6113508, lng: 19.0247508 },
    { lat: 48.6109482, lng: 19.024637 },
    { lat: 48.6096767, lng: 19.0236356 },
    { lat: 48.6097853, lng: 19.0231689 },
    { lat: 48.6098407, lng: 19.0221696 },
    { lat: 48.6100933, lng: 19.0196228 },
    { lat: 48.6099863, lng: 19.0179474 },
    { lat: 48.6105233, lng: 19.0173528 },
    { lat: 48.6104018, lng: 19.0165736 },
    { lat: 48.6106837, lng: 19.0163093 },
    { lat: 48.6101241, lng: 19.0149819 },
    { lat: 48.6102314, lng: 19.014473 },
    { lat: 48.6097193, lng: 19.0134412 },
    { lat: 48.6094017, lng: 19.0126436 },
    { lat: 48.6078084, lng: 19.0115277 },
    { lat: 48.6069897, lng: 19.0104501 },
    { lat: 48.6069648, lng: 19.0101822 },
    { lat: 48.606828, lng: 19.0097949 },
    { lat: 48.6066145, lng: 19.0098095 },
    { lat: 48.6061099, lng: 19.0096666 },
    { lat: 48.605326, lng: 19.0091008 },
    { lat: 48.6047794, lng: 19.0084569 },
    { lat: 48.6044818, lng: 19.0083005 },
    { lat: 48.6042834, lng: 19.0082599 },
    { lat: 48.6041369, lng: 19.0083708 },
    { lat: 48.603942, lng: 19.0083761 },
    { lat: 48.6033346, lng: 19.0081345 },
    { lat: 48.6032702, lng: 19.0082021 },
    { lat: 48.6023873, lng: 19.0075947 },
    { lat: 48.6020475, lng: 19.0079672 },
    { lat: 48.6016868, lng: 19.0080803 },
    { lat: 48.6013959, lng: 19.0076357 },
    { lat: 48.6010698, lng: 19.0065593 },
    { lat: 48.6008246, lng: 19.0060553 },
    { lat: 48.6004837, lng: 19.0059392 },
    { lat: 48.6001529, lng: 19.0059526 },
    { lat: 48.5995856, lng: 19.005568 },
    { lat: 48.5986861, lng: 19.0054367 },
    { lat: 48.5982267, lng: 19.0057606 },
    { lat: 48.5978064, lng: 19.0065459 },
    { lat: 48.5970717, lng: 19.0072245 },
    { lat: 48.5966792, lng: 19.0074231 },
    { lat: 48.5963696, lng: 19.0074555 },
    { lat: 48.5959799, lng: 19.0076862 },
    { lat: 48.5956499, lng: 19.0079806 },
    { lat: 48.5955015, lng: 19.0080315 },
    { lat: 48.5953482, lng: 19.0082332 },
    { lat: 48.595072, lng: 19.0084577 },
    { lat: 48.5940521, lng: 19.0091829 },
    { lat: 48.5937513, lng: 19.0096306 },
    { lat: 48.593472, lng: 19.009869 },
    { lat: 48.593464, lng: 19.009907 },
    { lat: 48.593434, lng: 19.010055 },
    { lat: 48.59335, lng: 19.010197 },
    { lat: 48.593226, lng: 19.010209 },
    { lat: 48.593048, lng: 19.010187 },
    { lat: 48.592846, lng: 19.01015 },
    { lat: 48.592641, lng: 19.010123 },
    { lat: 48.592434, lng: 19.010139 },
    { lat: 48.592184, lng: 19.010337 },
    { lat: 48.592038, lng: 19.010356 },
    { lat: 48.591906, lng: 19.010378 },
    { lat: 48.59175, lng: 19.010537 },
    { lat: 48.591587, lng: 19.010785 },
    { lat: 48.591337, lng: 19.011175 },
    { lat: 48.591171, lng: 19.011357 },
    { lat: 48.590991, lng: 19.011472 },
    { lat: 48.590846, lng: 19.011525 },
    { lat: 48.590504, lng: 19.011648 },
    { lat: 48.59028, lng: 19.011806 },
    { lat: 48.59008, lng: 19.011986 },
    { lat: 48.589936, lng: 19.012041 },
    { lat: 48.589773, lng: 19.0122 },
    { lat: 48.589573, lng: 19.01229 },
    { lat: 48.589401, lng: 19.012456 },
    { lat: 48.589245, lng: 19.012528 },
    { lat: 48.589015, lng: 19.012588 },
    { lat: 48.58879, lng: 19.012743 },
    { lat: 48.588548, lng: 19.012916 },
    { lat: 48.588407, lng: 19.013071 },
    { lat: 48.588292, lng: 19.013146 },
    { lat: 48.588094, lng: 19.013199 },
    { lat: 48.587913, lng: 19.013146 },
    { lat: 48.587735, lng: 19.013109 },
    { lat: 48.587484, lng: 19.013031 },
    { lat: 48.58729, lng: 19.012944 },
    { lat: 48.587202, lng: 19.012882 },
    { lat: 48.587064, lng: 19.012789 },
    { lat: 48.586945, lng: 19.012746 },
    { lat: 48.586638, lng: 19.012723 },
    { lat: 48.586476, lng: 19.012688 },
    { lat: 48.586184, lng: 19.012558 },
    { lat: 48.585979, lng: 19.012513 },
    { lat: 48.585719, lng: 19.012488 },
    { lat: 48.585552, lng: 19.012475 },
    { lat: 48.585425, lng: 19.012431 },
    { lat: 48.58532, lng: 19.012299 },
    { lat: 48.585238, lng: 19.012188 },
    { lat: 48.585154, lng: 19.01216 },
    { lat: 48.584998, lng: 19.01221 },
    { lat: 48.584865, lng: 19.012291 },
    { lat: 48.584656, lng: 19.0123 },
    { lat: 48.58447, lng: 19.012179 },
    { lat: 48.584342, lng: 19.012022 },
    { lat: 48.584238, lng: 19.011962 },
    { lat: 48.584071, lng: 19.011982 },
    { lat: 48.583864, lng: 19.012049 },
    { lat: 48.583681, lng: 19.012118 },
    { lat: 48.583523, lng: 19.0122 },
    { lat: 48.583334, lng: 19.012302 },
    { lat: 48.583147, lng: 19.012372 },
    { lat: 48.582972, lng: 19.01237 },
    { lat: 48.58286, lng: 19.012282 },
    { lat: 48.582752, lng: 19.012183 },
    { lat: 48.582685, lng: 19.012008 },
    { lat: 48.582685, lng: 19.011791 },
    { lat: 48.582823, lng: 19.011581 },
    { lat: 48.582961, lng: 19.011381 },
    { lat: 48.583077, lng: 19.011101 },
    { lat: 48.58311, lng: 19.010825 },
    { lat: 48.583178, lng: 19.010529 },
    { lat: 48.583218, lng: 19.010318 },
    { lat: 48.583201, lng: 19.009996 },
    { lat: 48.583131, lng: 19.009664 },
    { lat: 48.583033, lng: 19.009396 },
    { lat: 48.582923, lng: 19.009187 },
    { lat: 48.582855, lng: 19.00895 },
    { lat: 48.582833, lng: 19.008697 },
    { lat: 48.582799, lng: 19.00847 },
    { lat: 48.582753, lng: 19.008354 },
    { lat: 48.582536, lng: 19.008085 },
    { lat: 48.582386, lng: 19.007899 },
    { lat: 48.582281, lng: 19.007686 },
    { lat: 48.582181, lng: 19.007369 },
    { lat: 48.582073, lng: 19.007091 },
    { lat: 48.581949, lng: 19.006773 },
    { lat: 48.58189, lng: 19.00645 },
    { lat: 48.581799, lng: 19.006123 },
    { lat: 48.581712, lng: 19.005977 },
    { lat: 48.581517, lng: 19.005677 },
    { lat: 48.581287, lng: 19.005462 },
    { lat: 48.581004, lng: 19.005374 },
    { lat: 48.580697, lng: 19.005235 },
    { lat: 48.580562, lng: 19.005035 },
    { lat: 48.580457, lng: 19.004823 },
    { lat: 48.580388, lng: 19.004654 },
    { lat: 48.58026, lng: 19.004574 },
    { lat: 48.580037, lng: 19.004471 },
    { lat: 48.579871, lng: 19.004291 },
    { lat: 48.579691, lng: 19.004088 },
    { lat: 48.579442, lng: 19.003981 },
    { lat: 48.579186, lng: 19.003778 },
    { lat: 48.579029, lng: 19.003513 },
    { lat: 48.578812, lng: 19.003284 },
    { lat: 48.578564, lng: 19.003076 },
    { lat: 48.578333, lng: 19.002834 },
    { lat: 48.578132, lng: 19.002607 },
    { lat: 48.577954, lng: 19.002513 },
    { lat: 48.577806, lng: 19.002425 },
    { lat: 48.577718, lng: 19.002334 },
    { lat: 48.577639, lng: 19.002114 },
    { lat: 48.577528, lng: 19.001845 },
    { lat: 48.577416, lng: 19.001644 },
    { lat: 48.577287, lng: 19.001466 },
    { lat: 48.577123, lng: 19.001358 },
    { lat: 48.576934, lng: 19.001168 },
    { lat: 48.576749, lng: 19.000961 },
    { lat: 48.576594, lng: 19.000762 },
    { lat: 48.576505, lng: 19.000512 },
    { lat: 48.576425, lng: 19.000286 },
    { lat: 48.576317, lng: 19.000047 },
    { lat: 48.57624, lng: 18.999901 },
    { lat: 48.576107, lng: 18.999771 },
    { lat: 48.575938, lng: 18.999788 },
    { lat: 48.575808, lng: 18.999779 },
    { lat: 48.575378, lng: 18.999806 },
    { lat: 48.575289, lng: 18.999711 },
    { lat: 48.575233, lng: 18.999612 },
    { lat: 48.575155, lng: 18.999574 },
    { lat: 48.574621, lng: 18.999474 },
    { lat: 48.574502, lng: 18.999422 },
    { lat: 48.574183, lng: 18.999187 },
    { lat: 48.574098, lng: 18.999136 },
    { lat: 48.573817, lng: 18.999026 },
    { lat: 48.573785, lng: 18.999013 },
    { lat: 48.573094, lng: 18.998695 },
    { lat: 48.572895, lng: 18.998619 },
    { lat: 48.572865, lng: 18.998612 },
    { lat: 48.57277, lng: 18.998601 },
    { lat: 48.572714, lng: 18.998592 },
    { lat: 48.572449, lng: 18.998553 },
    { lat: 48.57241, lng: 18.998546 },
    { lat: 48.571977, lng: 18.998516 },
    { lat: 48.5716323, lng: 18.998537 },
    { lat: 48.5718241, lng: 18.9998275 },
    { lat: 48.5719195, lng: 19.0001103 },
    { lat: 48.5718978, lng: 19.0005909 },
    { lat: 48.5719573, lng: 19.000944 },
    { lat: 48.5720758, lng: 19.0011657 },
    { lat: 48.5721449, lng: 19.001863 },
    { lat: 48.5720944, lng: 19.002583 },
    { lat: 48.5724231, lng: 19.0033965 },
    { lat: 48.5724692, lng: 19.0040815 },
    { lat: 48.5726176, lng: 19.0044286 },
    { lat: 48.5725613, lng: 19.0048744 },
    { lat: 48.5727014, lng: 19.0058182 },
    { lat: 48.5728408, lng: 19.0064102 },
    { lat: 48.5730453, lng: 19.0070197 },
    { lat: 48.5736412, lng: 19.0071649 },
    { lat: 48.5738545, lng: 19.00737 },
    { lat: 48.5737396, lng: 19.0082644 },
    { lat: 48.573616, lng: 19.0086528 },
    { lat: 48.5735995, lng: 19.0089053 },
    { lat: 48.5734307, lng: 19.0092676 },
    { lat: 48.5732058, lng: 19.0099492 },
    { lat: 48.5732499, lng: 19.0103311 },
    { lat: 48.5732281, lng: 19.0105444 },
    { lat: 48.5725893, lng: 19.011134 },
    { lat: 48.5726553, lng: 19.0115237 },
    { lat: 48.5726997, lng: 19.012335 },
    { lat: 48.5720636, lng: 19.013423 },
    { lat: 48.5718389, lng: 19.0142644 },
    { lat: 48.571242, lng: 19.0148591 },
    { lat: 48.5711482, lng: 19.015144 },
    { lat: 48.571246, lng: 19.016252 },
    { lat: 48.5711649, lng: 19.0170136 },
    { lat: 48.5709583, lng: 19.0180355 },
    { lat: 48.57103, lng: 19.0185855 },
    { lat: 48.5709918, lng: 19.0193667 },
    { lat: 48.5707071, lng: 19.0199383 },
    { lat: 48.5702912, lng: 19.0202081 },
    { lat: 48.5700742, lng: 19.0204669 },
    { lat: 48.5695261, lng: 19.0209306 },
    { lat: 48.5686103, lng: 19.0214165 },
    { lat: 48.5683081, lng: 19.0218185 },
    { lat: 48.5685194, lng: 19.0226536 },
    { lat: 48.5684239, lng: 19.0241362 },
    { lat: 48.5685435, lng: 19.0251392 },
    { lat: 48.5685238, lng: 19.0255768 },
    { lat: 48.5683409, lng: 19.0262884 },
    { lat: 48.5683754, lng: 19.0266572 },
    { lat: 48.5686188, lng: 19.0268133 },
    { lat: 48.5689628, lng: 19.0268369 },
    { lat: 48.5694556, lng: 19.0269955 },
    { lat: 48.5701638, lng: 19.0272238 },
    { lat: 48.5708835, lng: 19.027543 },
    { lat: 48.5711207, lng: 19.0275066 },
    { lat: 48.571548, lng: 19.0271251 },
    { lat: 48.5729725, lng: 19.0263778 },
    { lat: 48.5736125, lng: 19.0264156 },
    { lat: 48.5737856, lng: 19.0265595 },
    { lat: 48.5739837, lng: 19.0270008 },
    { lat: 48.5742949, lng: 19.0271581 },
    { lat: 48.5744441, lng: 19.0273005 },
    { lat: 48.5746742, lng: 19.027994 },
    { lat: 48.5747579, lng: 19.0280884 },
    { lat: 48.575362, lng: 19.0280171 },
    { lat: 48.5759505, lng: 19.0286451 },
    { lat: 48.5761656, lng: 19.0290883 },
    { lat: 48.5762006, lng: 19.0293787 },
    { lat: 48.5761761, lng: 19.0297425 },
    { lat: 48.5762176, lng: 19.0305236 },
    { lat: 48.5761593, lng: 19.0307958 },
    { lat: 48.5765243, lng: 19.0321092 },
    { lat: 48.576475, lng: 19.0322982 },
    { lat: 48.5767562, lng: 19.0328424 },
    { lat: 48.5771745, lng: 19.0328633 },
    { lat: 48.5774641, lng: 19.0329751 },
    { lat: 48.5777806, lng: 19.0328765 },
    { lat: 48.5780817, lng: 19.0330561 },
    { lat: 48.5781885, lng: 19.0330286 },
    { lat: 48.5783162, lng: 19.0325668 },
    { lat: 48.5784723, lng: 19.0325094 },
    { lat: 48.5786095, lng: 19.0326585 },
    { lat: 48.5787509, lng: 19.0326687 },
    { lat: 48.5789081, lng: 19.0323431 },
    { lat: 48.5792269, lng: 19.0323358 },
    { lat: 48.5796104, lng: 19.0324304 },
    { lat: 48.579827, lng: 19.0323868 },
    { lat: 48.5800378, lng: 19.0324784 },
    { lat: 48.5803076, lng: 19.032773 },
    { lat: 48.5804824, lng: 19.0327067 },
    { lat: 48.5805938, lng: 19.0325686 },
    { lat: 48.5806677, lng: 19.0325822 },
    { lat: 48.5807606, lng: 19.0327706 },
    { lat: 48.5809095, lng: 19.0329266 },
    { lat: 48.5810536, lng: 19.032855 },
    { lat: 48.581295, lng: 19.0325457 },
    { lat: 48.5813381, lng: 19.0325514 },
    { lat: 48.5813793, lng: 19.0327881 },
    { lat: 48.5814761, lng: 19.0329412 },
    { lat: 48.581647, lng: 19.0329533 },
    { lat: 48.5819593, lng: 19.0324604 },
    { lat: 48.5824041, lng: 19.0324248 },
    { lat: 48.5825221, lng: 19.0322639 },
    { lat: 48.5826047, lng: 19.0319988 },
    { lat: 48.5835727, lng: 19.0317242 },
    { lat: 48.5840743, lng: 19.0319873 },
    { lat: 48.5842484, lng: 19.032222 },
    { lat: 48.5848742, lng: 19.0319773 },
    { lat: 48.5855063, lng: 19.0319936 },
    { lat: 48.5857821, lng: 19.0323428 },
    { lat: 48.5861415, lng: 19.0323568 },
    { lat: 48.586261, lng: 19.0325159 },
    { lat: 48.5868082, lng: 19.0329254 },
    { lat: 48.5871294, lng: 19.0337765 },
    { lat: 48.587274, lng: 19.0338059 },
    { lat: 48.587407, lng: 19.0337116 },
    { lat: 48.5874885, lng: 19.0337443 },
    { lat: 48.5878101, lng: 19.0344212 },
    { lat: 48.5880771, lng: 19.0342578 },
    { lat: 48.588162, lng: 19.0344642 },
    { lat: 48.5884309, lng: 19.0341653 },
    { lat: 48.5887382, lng: 19.0346469 },
    { lat: 48.5887787, lng: 19.034805 },
    { lat: 48.5889254, lng: 19.0367758 },
    { lat: 48.5891716, lng: 19.0371418 },
    { lat: 48.5894643, lng: 19.0371789 },
    { lat: 48.5900241, lng: 19.0374158 },
    { lat: 48.5900783, lng: 19.037686 },
    { lat: 48.5903948, lng: 19.0377224 },
    { lat: 48.5912274, lng: 19.0385642 },
    { lat: 48.5915864, lng: 19.039805 },
  ],
  Kováčová: [
    { lat: 48.6152163, lng: 19.0574648 },
    { lat: 48.6151823, lng: 19.0576159 },
    { lat: 48.614973, lng: 19.0580545 },
    { lat: 48.6149444, lng: 19.0586538 },
    { lat: 48.61518, lng: 19.0591447 },
    { lat: 48.6151785, lng: 19.05968 },
    { lat: 48.6154279, lng: 19.06065 },
    { lat: 48.6149475, lng: 19.0621823 },
    { lat: 48.6148549, lng: 19.0623321 },
    { lat: 48.6134735, lng: 19.0634974 },
    { lat: 48.6131632, lng: 19.0636507 },
    { lat: 48.6130165, lng: 19.0636271 },
    { lat: 48.6114598, lng: 19.0648002 },
    { lat: 48.611091, lng: 19.0652055 },
    { lat: 48.6107173, lng: 19.0654615 },
    { lat: 48.6089852, lng: 19.0678874 },
    { lat: 48.6085659, lng: 19.068271 },
    { lat: 48.6078526, lng: 19.0694491 },
    { lat: 48.6070393, lng: 19.0705654 },
    { lat: 48.6066751, lng: 19.070838 },
    { lat: 48.6065311, lng: 19.071341 },
    { lat: 48.6060981, lng: 19.0714726 },
    { lat: 48.6050092, lng: 19.0713862 },
    { lat: 48.6046178, lng: 19.0714254 },
    { lat: 48.6046144, lng: 19.0714713 },
    { lat: 48.6042695, lng: 19.0715419 },
    { lat: 48.6031637, lng: 19.0720876 },
    { lat: 48.6030112, lng: 19.0722353 },
    { lat: 48.602818, lng: 19.0722975 },
    { lat: 48.6027507, lng: 19.0724402 },
    { lat: 48.6025636, lng: 19.0724401 },
    { lat: 48.6022684, lng: 19.0728722 },
    { lat: 48.6020148, lng: 19.0730973 },
    { lat: 48.6017047, lng: 19.0739779 },
    { lat: 48.6018314, lng: 19.0741636 },
    { lat: 48.6018301, lng: 19.0742514 },
    { lat: 48.6017119, lng: 19.0745046 },
    { lat: 48.6014385, lng: 19.0746721 },
    { lat: 48.6012538, lng: 19.0752651 },
    { lat: 48.6012076, lng: 19.0752187 },
    { lat: 48.6011651, lng: 19.0749788 },
    { lat: 48.6010749, lng: 19.0751355 },
    { lat: 48.6010226, lng: 19.0751065 },
    { lat: 48.6007051, lng: 19.0760124 },
    { lat: 48.6007047, lng: 19.0762739 },
    { lat: 48.6006162, lng: 19.0764474 },
    { lat: 48.6005553, lng: 19.0767842 },
    { lat: 48.6005312, lng: 19.0774548 },
    { lat: 48.6004711, lng: 19.0775809 },
    { lat: 48.6003569, lng: 19.0776391 },
    { lat: 48.6004207, lng: 19.077815 },
    { lat: 48.6004096, lng: 19.077995 },
    { lat: 48.6005124, lng: 19.0781387 },
    { lat: 48.6005277, lng: 19.0783549 },
    { lat: 48.6004241, lng: 19.0785117 },
    { lat: 48.600497, lng: 19.0787082 },
    { lat: 48.6004725, lng: 19.0788958 },
    { lat: 48.6005496, lng: 19.0792885 },
    { lat: 48.6005316, lng: 19.079354 },
    { lat: 48.6004146, lng: 19.0794046 },
    { lat: 48.6002312, lng: 19.0792577 },
    { lat: 48.6001759, lng: 19.0793224 },
    { lat: 48.6001216, lng: 19.0795654 },
    { lat: 48.6000377, lng: 19.0795361 },
    { lat: 48.5998304, lng: 19.0798325 },
    { lat: 48.5998352, lng: 19.0799278 },
    { lat: 48.6000385, lng: 19.0802705 },
    { lat: 48.6000725, lng: 19.0804119 },
    { lat: 48.5999174, lng: 19.0807166 },
    { lat: 48.5999959, lng: 19.0811113 },
    { lat: 48.5999095, lng: 19.08153 },
    { lat: 48.5996923, lng: 19.0818065 },
    { lat: 48.5996376, lng: 19.0820225 },
    { lat: 48.599492, lng: 19.0820383 },
    { lat: 48.5994467, lng: 19.0822379 },
    { lat: 48.5992852, lng: 19.0822776 },
    { lat: 48.5991556, lng: 19.0829095 },
    { lat: 48.5991729, lng: 19.0831248 },
    { lat: 48.598917, lng: 19.0833256 },
    { lat: 48.5989541, lng: 19.0837758 },
    { lat: 48.5988601, lng: 19.0842164 },
    { lat: 48.5985807, lng: 19.0841331 },
    { lat: 48.5985712, lng: 19.0844639 },
    { lat: 48.5984577, lng: 19.0846506 },
    { lat: 48.5984893, lng: 19.0847154 },
    { lat: 48.5984656, lng: 19.0849657 },
    { lat: 48.5984022, lng: 19.0849825 },
    { lat: 48.5983328, lng: 19.0851966 },
    { lat: 48.5982724, lng: 19.0852511 },
    { lat: 48.5979681, lng: 19.0852554 },
    { lat: 48.5978691, lng: 19.0856666 },
    { lat: 48.5976399, lng: 19.0861097 },
    { lat: 48.597577, lng: 19.0865214 },
    { lat: 48.5969731, lng: 19.0869802 },
    { lat: 48.5969025, lng: 19.0872593 },
    { lat: 48.5968268, lng: 19.0872575 },
    { lat: 48.5967206, lng: 19.0874102 },
    { lat: 48.5966026, lng: 19.0874599 },
    { lat: 48.596647, lng: 19.0876554 },
    { lat: 48.5963937, lng: 19.0879096 },
    { lat: 48.5962322, lng: 19.0881715 },
    { lat: 48.5961983, lng: 19.0883296 },
    { lat: 48.5961492, lng: 19.0883871 },
    { lat: 48.5958898, lng: 19.0882513 },
    { lat: 48.5956076, lng: 19.0883331 },
    { lat: 48.5954787, lng: 19.08844 },
    { lat: 48.5953749, lng: 19.0886601 },
    { lat: 48.5953213, lng: 19.088669 },
    { lat: 48.5951657, lng: 19.0884202 },
    { lat: 48.594953, lng: 19.0884287 },
    { lat: 48.5947178, lng: 19.0881647 },
    { lat: 48.5941714, lng: 19.0878815 },
    { lat: 48.5942085, lng: 19.0876302 },
    { lat: 48.5940954, lng: 19.0875315 },
    { lat: 48.5940366, lng: 19.0872821 },
    { lat: 48.5938897, lng: 19.0872721 },
    { lat: 48.5938274, lng: 19.0874027 },
    { lat: 48.5937539, lng: 19.0874207 },
    { lat: 48.5937255, lng: 19.0871927 },
    { lat: 48.593667, lng: 19.0871702 },
    { lat: 48.5934637, lng: 19.0874147 },
    { lat: 48.5932644, lng: 19.0870983 },
    { lat: 48.5930302, lng: 19.0872869 },
    { lat: 48.5928775, lng: 19.0875912 },
    { lat: 48.5928486, lng: 19.0877612 },
    { lat: 48.5927338, lng: 19.0878743 },
    { lat: 48.5921488, lng: 19.0875189 },
    { lat: 48.5920351, lng: 19.0873612 },
    { lat: 48.5920873, lng: 19.087257 },
    { lat: 48.5920603, lng: 19.0872355 },
    { lat: 48.5918962, lng: 19.0872231 },
    { lat: 48.5918448, lng: 19.0873436 },
    { lat: 48.5916117, lng: 19.0872116 },
    { lat: 48.5915308, lng: 19.0869921 },
    { lat: 48.5914431, lng: 19.087158 },
    { lat: 48.5911278, lng: 19.0869359 },
    { lat: 48.5910692, lng: 19.0870487 },
    { lat: 48.5908812, lng: 19.0868097 },
    { lat: 48.5908352, lng: 19.0868345 },
    { lat: 48.5908828, lng: 19.0871357 },
    { lat: 48.5907296, lng: 19.088762 },
    { lat: 48.5907678, lng: 19.0893713 },
    { lat: 48.5907439, lng: 19.0897308 },
    { lat: 48.5904588, lng: 19.0910637 },
    { lat: 48.5905294, lng: 19.0917355 },
    { lat: 48.590404, lng: 19.0920438 },
    { lat: 48.5903339, lng: 19.0925201 },
    { lat: 48.5901652, lng: 19.0944013 },
    { lat: 48.5899982, lng: 19.0947371 },
    { lat: 48.5900241, lng: 19.0952812 },
    { lat: 48.5901377, lng: 19.09566 },
    { lat: 48.5897936, lng: 19.0968332 },
    { lat: 48.5895134, lng: 19.0979998 },
    { lat: 48.5894254, lng: 19.098587 },
    { lat: 48.5893698, lng: 19.1000505 },
    { lat: 48.5891389, lng: 19.1020052 },
    { lat: 48.5888085, lng: 19.1025254 },
    { lat: 48.5888923, lng: 19.1047512 },
    { lat: 48.5889498, lng: 19.1048433 },
    { lat: 48.5890171, lng: 19.1055589 },
    { lat: 48.5889986, lng: 19.1058897 },
    { lat: 48.5886424, lng: 19.1071421 },
    { lat: 48.5893966, lng: 19.1075725 },
    { lat: 48.5894997, lng: 19.1074687 },
    { lat: 48.590267, lng: 19.1071178 },
    { lat: 48.5907047, lng: 19.1070306 },
    { lat: 48.5908495, lng: 19.1070886 },
    { lat: 48.5919443, lng: 19.1107687 },
    { lat: 48.5924239, lng: 19.1120492 },
    { lat: 48.5926282, lng: 19.1123727 },
    { lat: 48.5931291, lng: 19.1120843 },
    { lat: 48.593426, lng: 19.1117502 },
    { lat: 48.5938866, lng: 19.1115166 },
    { lat: 48.593962, lng: 19.1116498 },
    { lat: 48.593956, lng: 19.1116611 },
    { lat: 48.5953777, lng: 19.1099162 },
    { lat: 48.597972, lng: 19.1091003 },
    { lat: 48.5996171, lng: 19.1088412 },
    { lat: 48.6005008, lng: 19.1084508 },
    { lat: 48.6014962, lng: 19.1074978 },
    { lat: 48.6021175, lng: 19.108339 },
    { lat: 48.6023547, lng: 19.1087824 },
    { lat: 48.6031639, lng: 19.111091 },
    { lat: 48.6033426, lng: 19.1108728 },
    { lat: 48.6035588, lng: 19.1103946 },
    { lat: 48.6067148, lng: 19.10453 },
    { lat: 48.6069393, lng: 19.1049167 },
    { lat: 48.6073173, lng: 19.1052416 },
    { lat: 48.6076489, lng: 19.1053349 },
    { lat: 48.6080263, lng: 19.105284 },
    { lat: 48.6092504, lng: 19.1048503 },
    { lat: 48.6095942, lng: 19.1046917 },
    { lat: 48.6098137, lng: 19.1044788 },
    { lat: 48.610013, lng: 19.1041104 },
    { lat: 48.6101055, lng: 19.1037704 },
    { lat: 48.6102283, lng: 19.1024772 },
    { lat: 48.6104791, lng: 19.1026407 },
    { lat: 48.61052, lng: 19.1026674 },
    { lat: 48.6108447, lng: 19.1028791 },
    { lat: 48.6108737, lng: 19.102898 },
    { lat: 48.6111941, lng: 19.1031069 },
    { lat: 48.6112982, lng: 19.1027285 },
    { lat: 48.6117264, lng: 19.1018621 },
    { lat: 48.6120026, lng: 19.1014796 },
    { lat: 48.6122667, lng: 19.1008391 },
    { lat: 48.6123104, lng: 19.1007529 },
    { lat: 48.6124989, lng: 19.099987 },
    { lat: 48.6125323, lng: 19.0995942 },
    { lat: 48.6123155, lng: 19.0986365 },
    { lat: 48.6129385, lng: 19.0952787 },
    { lat: 48.6137011, lng: 19.0927981 },
    { lat: 48.6128065, lng: 19.0923106 },
    { lat: 48.6132511, lng: 19.0898724 },
    { lat: 48.6132742, lng: 19.0892264 },
    { lat: 48.6132627, lng: 19.0890681 },
    { lat: 48.6130522, lng: 19.0890488 },
    { lat: 48.6129077, lng: 19.088524 },
    { lat: 48.6132577, lng: 19.087428 },
    { lat: 48.6132532, lng: 19.0869953 },
    { lat: 48.6133407, lng: 19.0866045 },
    { lat: 48.6134944, lng: 19.0863181 },
    { lat: 48.613601, lng: 19.0856518 },
    { lat: 48.6135749, lng: 19.0851524 },
    { lat: 48.6137031, lng: 19.0848767 },
    { lat: 48.6137116, lng: 19.084387 },
    { lat: 48.6143189, lng: 19.0836814 },
    { lat: 48.6144486, lng: 19.0833207 },
    { lat: 48.6154116, lng: 19.0817884 },
    { lat: 48.6164406, lng: 19.0804056 },
    { lat: 48.6163922, lng: 19.080047 },
    { lat: 48.6169012, lng: 19.0796952 },
    { lat: 48.6173836, lng: 19.0797847 },
    { lat: 48.6176866, lng: 19.0796869 },
    { lat: 48.618227, lng: 19.0792453 },
    { lat: 48.6187053, lng: 19.0784819 },
    { lat: 48.6192118, lng: 19.0779245 },
    { lat: 48.6196059, lng: 19.0776698 },
    { lat: 48.6198252, lng: 19.0776082 },
    { lat: 48.6203368, lng: 19.0772472 },
    { lat: 48.6211168, lng: 19.0774921 },
    { lat: 48.6218066, lng: 19.0774878 },
    { lat: 48.6224209, lng: 19.0778008 },
    { lat: 48.6224954, lng: 19.0777791 },
    { lat: 48.6227874, lng: 19.0774434 },
    { lat: 48.6232877, lng: 19.0773477 },
    { lat: 48.6236384, lng: 19.0768756 },
    { lat: 48.6236836, lng: 19.0766271 },
    { lat: 48.6238109, lng: 19.076309 },
    { lat: 48.6235175, lng: 19.075852 },
    { lat: 48.6235842, lng: 19.0758344 },
    { lat: 48.6236677, lng: 19.0760391 },
    { lat: 48.623829, lng: 19.0757453 },
    { lat: 48.6237675, lng: 19.0756447 },
    { lat: 48.6239311, lng: 19.0752637 },
    { lat: 48.6240014, lng: 19.0751349 },
    { lat: 48.6241112, lng: 19.0752799 },
    { lat: 48.6242016, lng: 19.0750604 },
    { lat: 48.6239814, lng: 19.0749597 },
    { lat: 48.6236453, lng: 19.0749815 },
    { lat: 48.6235883, lng: 19.074833 },
    { lat: 48.6229037, lng: 19.0748285 },
    { lat: 48.6220443, lng: 19.0731751 },
    { lat: 48.6219403, lng: 19.0732122 },
    { lat: 48.6216961, lng: 19.0731099 },
    { lat: 48.6214784, lng: 19.0724078 },
    { lat: 48.6210498, lng: 19.0715229 },
    { lat: 48.6209884, lng: 19.0710164 },
    { lat: 48.6210956, lng: 19.0693715 },
    { lat: 48.6216114, lng: 19.0685748 },
    { lat: 48.6213754, lng: 19.0679136 },
    { lat: 48.6212727, lng: 19.0668965 },
    { lat: 48.6207982, lng: 19.0661331 },
    { lat: 48.6206102, lng: 19.0651948 },
    { lat: 48.6209346, lng: 19.064219 },
    { lat: 48.6210227, lng: 19.063322 },
    { lat: 48.621426, lng: 19.0619868 },
    { lat: 48.620553, lng: 19.0616836 },
    { lat: 48.6194089, lng: 19.0617124 },
    { lat: 48.6189277, lng: 19.0607699 },
    { lat: 48.6187462, lng: 19.0606127 },
    { lat: 48.6182692, lng: 19.0605221 },
    { lat: 48.6175104, lng: 19.0605889 },
    { lat: 48.6170705, lng: 19.0604228 },
    { lat: 48.6166813, lng: 19.0595443 },
    { lat: 48.6164892, lng: 19.0592188 },
    { lat: 48.6158491, lng: 19.058773 },
    { lat: 48.6152704, lng: 19.0576663 },
    { lat: 48.6152917, lng: 19.0576397 },
    { lat: 48.6152163, lng: 19.0574648 },
  ],
  DobráNiva: [
    { lat: 48.4959557, lng: 19.0699975 },
    { lat: 48.4960876, lng: 19.0697529 },
    { lat: 48.4961413, lng: 19.0696533 },
    { lat: 48.4964927, lng: 19.0690176 },
    { lat: 48.4965667, lng: 19.0686719 },
    { lat: 48.4965432, lng: 19.0684748 },
    { lat: 48.4967245, lng: 19.0683128 },
    { lat: 48.4969354, lng: 19.0678409 },
    { lat: 48.497134, lng: 19.0676399 },
    { lat: 48.4975956, lng: 19.0673394 },
    { lat: 48.4976909, lng: 19.0672124 },
    { lat: 48.4978362, lng: 19.0662016 },
    { lat: 48.4976567, lng: 19.0655903 },
    { lat: 48.4976193, lng: 19.0653624 },
    { lat: 48.4976447, lng: 19.0650819 },
    { lat: 48.4977142, lng: 19.0648465 },
    { lat: 48.4979105, lng: 19.0645125 },
    { lat: 48.4979748, lng: 19.0642446 },
    { lat: 48.498087, lng: 19.0636521 },
    { lat: 48.4981536, lng: 19.0626392 },
    { lat: 48.4983859, lng: 19.0625275 },
    { lat: 48.4986736, lng: 19.0622211 },
    { lat: 48.4988314, lng: 19.0614766 },
    { lat: 48.4991205, lng: 19.0611511 },
    { lat: 48.4990981, lng: 19.0603656 },
    { lat: 48.4989519, lng: 19.0598351 },
    { lat: 48.4989869, lng: 19.0594305 },
    { lat: 48.4989615, lng: 19.0591934 },
    { lat: 48.498829, lng: 19.0588708 },
    { lat: 48.4987991, lng: 19.0585006 },
    { lat: 48.4985113, lng: 19.0582692 },
    { lat: 48.4986381, lng: 19.0576049 },
    { lat: 48.4985963, lng: 19.0568194 },
    { lat: 48.4984051, lng: 19.0562916 },
    { lat: 48.4978291, lng: 19.0556849 },
    { lat: 48.4966007, lng: 19.0553803 },
    { lat: 48.4957882, lng: 19.0549857 },
    { lat: 48.4954283, lng: 19.0547341 },
    { lat: 48.4953777, lng: 19.0543336 },
    { lat: 48.4955606, lng: 19.0542271 },
    { lat: 48.4956271, lng: 19.0540699 },
    { lat: 48.4955031, lng: 19.0539333 },
    { lat: 48.4951656, lng: 19.0538796 },
    { lat: 48.494631, lng: 19.053381 },
    { lat: 48.4947702, lng: 19.0527193 },
    { lat: 48.4947986, lng: 19.0520968 },
    { lat: 48.4946079, lng: 19.0518701 },
    { lat: 48.4945595, lng: 19.0516033 },
    { lat: 48.4944274, lng: 19.0514658 },
    { lat: 48.4941424, lng: 19.0516465 },
    { lat: 48.4935294, lng: 19.0512492 },
    { lat: 48.4933102, lng: 19.0507719 },
    { lat: 48.4932177, lng: 19.0507444 },
    { lat: 48.492762, lng: 19.0508532 },
    { lat: 48.4926581, lng: 19.0507612 },
    { lat: 48.4925426, lng: 19.0505038 },
    { lat: 48.4926277, lng: 19.0503587 },
    { lat: 48.492408, lng: 19.0499601 },
    { lat: 48.4922684, lng: 19.0498946 },
    { lat: 48.4917999, lng: 19.0500634 },
    { lat: 48.4916373, lng: 19.0499668 },
    { lat: 48.4914142, lng: 19.0497039 },
    { lat: 48.4912573, lng: 19.0497637 },
    { lat: 48.4911836, lng: 19.0496879 },
    { lat: 48.491041, lng: 19.0496995 },
    { lat: 48.490814, lng: 19.0493109 },
    { lat: 48.4907373, lng: 19.0493122 },
    { lat: 48.4906221, lng: 19.0491966 },
    { lat: 48.4904042, lng: 19.0492101 },
    { lat: 48.4901362, lng: 19.048974 },
    { lat: 48.4900346, lng: 19.0487659 },
    { lat: 48.4899496, lng: 19.0487163 },
    { lat: 48.4896958, lng: 19.0489104 },
    { lat: 48.4894067, lng: 19.0486359 },
    { lat: 48.4892398, lng: 19.0482305 },
    { lat: 48.4891151, lng: 19.0476107 },
    { lat: 48.4883024, lng: 19.0474797 },
    { lat: 48.4875338, lng: 19.0471265 },
    { lat: 48.4872631, lng: 19.047151 },
    { lat: 48.4868384, lng: 19.0470447 },
    { lat: 48.4866319, lng: 19.0468607 },
    { lat: 48.4864342, lng: 19.0468104 },
    { lat: 48.4863984, lng: 19.046657 },
    { lat: 48.4861427, lng: 19.0463041 },
    { lat: 48.4861253, lng: 19.0456866 },
    { lat: 48.4859858, lng: 19.0452717 },
    { lat: 48.4859695, lng: 19.0449239 },
    { lat: 48.4858439, lng: 19.0445785 },
    { lat: 48.4858009, lng: 19.0436359 },
    { lat: 48.4856255, lng: 19.0431982 },
    { lat: 48.4853227, lng: 19.0421292 },
    { lat: 48.4852012, lng: 19.0420481 },
    { lat: 48.4852103, lng: 19.0418925 },
    { lat: 48.4851375, lng: 19.0418116 },
    { lat: 48.4851388, lng: 19.04166 },
    { lat: 48.4850727, lng: 19.0415812 },
    { lat: 48.4849668, lng: 19.0410645 },
    { lat: 48.4848442, lng: 19.0409608 },
    { lat: 48.4848314, lng: 19.0406357 },
    { lat: 48.4847275, lng: 19.040526 },
    { lat: 48.48459, lng: 19.0401635 },
    { lat: 48.4843535, lng: 19.0399254 },
    { lat: 48.4843935, lng: 19.0393774 },
    { lat: 48.4843202, lng: 19.0392415 },
    { lat: 48.4844105, lng: 19.0390634 },
    { lat: 48.4844245, lng: 19.0385814 },
    { lat: 48.4844565, lng: 19.0385887 },
    { lat: 48.484502, lng: 19.0382161 },
    { lat: 48.4843161, lng: 19.0378424 },
    { lat: 48.4842877, lng: 19.0376832 },
    { lat: 48.4841749, lng: 19.0376329 },
    { lat: 48.4840652, lng: 19.0373358 },
    { lat: 48.4839718, lng: 19.0373216 },
    { lat: 48.4839098, lng: 19.0371183 },
    { lat: 48.4839178, lng: 19.0369393 },
    { lat: 48.4839861, lng: 19.036799 },
    { lat: 48.4839716, lng: 19.0365767 },
    { lat: 48.4838487, lng: 19.0364443 },
    { lat: 48.4839456, lng: 19.0360374 },
    { lat: 48.483907, lng: 19.035787 },
    { lat: 48.4840058, lng: 19.035334 },
    { lat: 48.4840085, lng: 19.0348955 },
    { lat: 48.4837759, lng: 19.0341075 },
    { lat: 48.4835786, lng: 19.0337039 },
    { lat: 48.4835359, lng: 19.0328818 },
    { lat: 48.4833409, lng: 19.0326368 },
    { lat: 48.4833635, lng: 19.0324341 },
    { lat: 48.4834353, lng: 19.0322728 },
    { lat: 48.4834276, lng: 19.0320505 },
    { lat: 48.4835503, lng: 19.0316921 },
    { lat: 48.483492, lng: 19.0314503 },
    { lat: 48.4835751, lng: 19.0312425 },
    { lat: 48.4835598, lng: 19.0311878 },
    { lat: 48.4837502, lng: 19.0309162 },
    { lat: 48.4838125, lng: 19.0306329 },
    { lat: 48.4839078, lng: 19.0304863 },
    { lat: 48.4839851, lng: 19.0302113 },
    { lat: 48.4839497, lng: 19.029888 },
    { lat: 48.4839821, lng: 19.0292986 },
    { lat: 48.4841072, lng: 19.0291663 },
    { lat: 48.4843258, lng: 19.0290749 },
    { lat: 48.4844096, lng: 19.0289281 },
    { lat: 48.48436, lng: 19.0283012 },
    { lat: 48.4844439, lng: 19.0279508 },
    { lat: 48.484163, lng: 19.0270336 },
    { lat: 48.483955, lng: 19.0261533 },
    { lat: 48.483332, lng: 19.0250681 },
    { lat: 48.4833045, lng: 19.0247905 },
    { lat: 48.483261, lng: 19.0247347 },
    { lat: 48.4832847, lng: 19.024647 },
    { lat: 48.4831622, lng: 19.0243227 },
    { lat: 48.4831993, lng: 19.0240519 },
    { lat: 48.4833962, lng: 19.0233705 },
    { lat: 48.4835223, lng: 19.0226126 },
    { lat: 48.4834505, lng: 19.022263 },
    { lat: 48.4834753, lng: 19.0220384 },
    { lat: 48.4835986, lng: 19.0216689 },
    { lat: 48.4836417, lng: 19.0213149 },
    { lat: 48.4835641, lng: 19.021009 },
    { lat: 48.4836617, lng: 19.0208176 },
    { lat: 48.4835252, lng: 19.0200151 },
    { lat: 48.483458, lng: 19.019822 },
    { lat: 48.483324, lng: 19.0196913 },
    { lat: 48.4832826, lng: 19.0194981 },
    { lat: 48.4833398, lng: 19.0192705 },
    { lat: 48.4828727, lng: 19.0182151 },
    { lat: 48.4825557, lng: 19.0169866 },
    { lat: 48.4825972, lng: 19.0165796 },
    { lat: 48.4826864, lng: 19.0162506 },
    { lat: 48.482851, lng: 19.015694 },
    { lat: 48.4829615, lng: 19.0154912 },
    { lat: 48.4828346, lng: 19.0145672 },
    { lat: 48.4827649, lng: 19.014217 },
    { lat: 48.4826073, lng: 19.0138619 },
    { lat: 48.482466, lng: 19.0137679 },
    { lat: 48.4817481, lng: 19.0122164 },
    { lat: 48.4809374, lng: 19.0111 },
    { lat: 48.4805203, lng: 19.0095881 },
    { lat: 48.4806117, lng: 19.0084539 },
    { lat: 48.4806114, lng: 19.0078704 },
    { lat: 48.48032, lng: 19.007062 },
    { lat: 48.479963, lng: 19.007178 },
    { lat: 48.479725, lng: 19.007248 },
    { lat: 48.479641, lng: 19.00739 },
    { lat: 48.479382, lng: 19.007821 },
    { lat: 48.479317, lng: 19.007956 },
    { lat: 48.479105, lng: 19.008199 },
    { lat: 48.47894, lng: 19.008348 },
    { lat: 48.478738, lng: 19.008675 },
    { lat: 48.47823, lng: 19.009675 },
    { lat: 48.478105, lng: 19.009929 },
    { lat: 48.477816, lng: 19.01024 },
    { lat: 48.477601, lng: 19.010793 },
    { lat: 48.477461, lng: 19.011341 },
    { lat: 48.477388, lng: 19.01205 },
    { lat: 48.47734, lng: 19.012433 },
    { lat: 48.477169, lng: 19.013138 },
    { lat: 48.476816, lng: 19.013091 },
    { lat: 48.476322, lng: 19.012403 },
    { lat: 48.476226, lng: 19.012269 },
    { lat: 48.476014, lng: 19.011972 },
    { lat: 48.475754, lng: 19.011683 },
    { lat: 48.475736, lng: 19.011374 },
    { lat: 48.475278, lng: 19.010814 },
    { lat: 48.475166, lng: 19.010765 },
    { lat: 48.475127, lng: 19.010748 },
    { lat: 48.474821, lng: 19.010614 },
    { lat: 48.474423, lng: 19.009816 },
    { lat: 48.473652, lng: 19.009531 },
    { lat: 48.472949, lng: 19.009243 },
    { lat: 48.472717, lng: 19.009114 },
    { lat: 48.472378, lng: 19.008943 },
    { lat: 48.472197, lng: 19.008907 },
    { lat: 48.471763, lng: 19.008653 },
    { lat: 48.471229, lng: 19.008208 },
    { lat: 48.470743, lng: 19.007855 },
    { lat: 48.470367, lng: 19.007584 },
    { lat: 48.470246, lng: 19.007496 },
    { lat: 48.470143, lng: 19.007422 },
    { lat: 48.469886, lng: 19.007303 },
    { lat: 48.469185, lng: 19.007149 },
    { lat: 48.468907, lng: 19.006728 },
    { lat: 48.468117, lng: 19.006256 },
    { lat: 48.46758, lng: 19.005618 },
    { lat: 48.467012, lng: 19.00515 },
    { lat: 48.466836, lng: 19.005089 },
    { lat: 48.465918, lng: 19.00487 },
    { lat: 48.465529, lng: 19.004586 },
    { lat: 48.464541, lng: 19.003819 },
    { lat: 48.463832, lng: 19.00325 },
    { lat: 48.463721, lng: 19.003079 },
    { lat: 48.463593, lng: 19.003014 },
    { lat: 48.463486, lng: 19.002931 },
    { lat: 48.463368, lng: 19.00287 },
    { lat: 48.463214, lng: 19.002656 },
    { lat: 48.462976, lng: 19.002389 },
    { lat: 48.462398, lng: 19.00218 },
    { lat: 48.462163, lng: 19.002091 },
    { lat: 48.461702, lng: 19.001511 },
    { lat: 48.461529, lng: 19.001387 },
    { lat: 48.461026, lng: 19.001662 },
    { lat: 48.460572, lng: 19.001793 },
    { lat: 48.459146, lng: 19.001826 },
    { lat: 48.458919, lng: 19.001806 },
    { lat: 48.458268, lng: 19.002047 },
    { lat: 48.457373, lng: 19.002171 },
    { lat: 48.456956, lng: 19.002294 },
    { lat: 48.456698, lng: 19.002658 },
    { lat: 48.456218, lng: 19.003479 },
    { lat: 48.455669, lng: 19.003935 },
    { lat: 48.455288, lng: 19.004352 },
    { lat: 48.454304, lng: 19.004601 },
    { lat: 48.45334, lng: 19.005555 },
    { lat: 48.452079, lng: 19.005966 },
    { lat: 48.451981, lng: 19.00618 },
    { lat: 48.451905, lng: 19.006806 },
    { lat: 48.451785, lng: 19.006966 },
    { lat: 48.451363, lng: 19.007493 },
    { lat: 48.45075, lng: 19.007903 },
    { lat: 48.450501, lng: 19.008258 },
    { lat: 48.449894, lng: 19.009714 },
    { lat: 48.449633, lng: 19.010129 },
    { lat: 48.449321, lng: 19.0103 },
    { lat: 48.448639, lng: 19.010187 },
    { lat: 48.448394, lng: 19.010132 },
    { lat: 48.448192, lng: 19.010082 },
    { lat: 48.446801, lng: 19.009683 },
    { lat: 48.446019, lng: 19.009681 },
    { lat: 48.445026, lng: 19.009865 },
    { lat: 48.44463, lng: 19.009709 },
    { lat: 48.443742, lng: 19.009691 },
    { lat: 48.443419, lng: 19.009718 },
    { lat: 48.442729, lng: 19.009764 },
    { lat: 48.442572, lng: 19.009714 },
    { lat: 48.441576, lng: 19.009759 },
    { lat: 48.4415918, lng: 19.0098879 },
    { lat: 48.4417921, lng: 19.0100131 },
    { lat: 48.441815, lng: 19.0102423 },
    { lat: 48.4417394, lng: 19.0104875 },
    { lat: 48.4417491, lng: 19.0106341 },
    { lat: 48.4419356, lng: 19.010976 },
    { lat: 48.4418898, lng: 19.0113204 },
    { lat: 48.4419166, lng: 19.0118027 },
    { lat: 48.442098, lng: 19.0124918 },
    { lat: 48.4422324, lng: 19.0126078 },
    { lat: 48.4423713, lng: 19.0129434 },
    { lat: 48.4423085, lng: 19.0131481 },
    { lat: 48.4422813, lng: 19.0136384 },
    { lat: 48.4423172, lng: 19.0137681 },
    { lat: 48.4424346, lng: 19.0138144 },
    { lat: 48.4424437, lng: 19.0141059 },
    { lat: 48.4423935, lng: 19.0142288 },
    { lat: 48.4425303, lng: 19.0146343 },
    { lat: 48.4426873, lng: 19.0147307 },
    { lat: 48.4427903, lng: 19.0151841 },
    { lat: 48.4428508, lng: 19.015734 },
    { lat: 48.4429128, lng: 19.0158657 },
    { lat: 48.4428964, lng: 19.0161449 },
    { lat: 48.4429408, lng: 19.01655 },
    { lat: 48.4428425, lng: 19.0170162 },
    { lat: 48.4428754, lng: 19.0171988 },
    { lat: 48.442735, lng: 19.0175199 },
    { lat: 48.4427865, lng: 19.0177342 },
    { lat: 48.442733, lng: 19.0181941 },
    { lat: 48.4428841, lng: 19.018995 },
    { lat: 48.4432264, lng: 19.0197215 },
    { lat: 48.4433891, lng: 19.0199255 },
    { lat: 48.4434013, lng: 19.0202359 },
    { lat: 48.4434719, lng: 19.0204033 },
    { lat: 48.4437971, lng: 19.0206583 },
    { lat: 48.4440568, lng: 19.0207572 },
    { lat: 48.4444493, lng: 19.0212066 },
    { lat: 48.4446244, lng: 19.021302 },
    { lat: 48.4448335, lng: 19.0212682 },
    { lat: 48.4450339, lng: 19.0215269 },
    { lat: 48.4452717, lng: 19.0215941 },
    { lat: 48.4453331, lng: 19.0216677 },
    { lat: 48.4454388, lng: 19.0223108 },
    { lat: 48.4456204, lng: 19.0224492 },
    { lat: 48.4459632, lng: 19.0232932 },
    { lat: 48.4461617, lng: 19.0234957 },
    { lat: 48.4462269, lng: 19.0239392 },
    { lat: 48.4463768, lng: 19.0242268 },
    { lat: 48.4467001, lng: 19.0244018 },
    { lat: 48.4468487, lng: 19.0247744 },
    { lat: 48.4470294, lng: 19.0250038 },
    { lat: 48.4469609, lng: 19.0252315 },
    { lat: 48.4472308, lng: 19.0256868 },
    { lat: 48.4473532, lng: 19.0257431 },
    { lat: 48.4474827, lng: 19.0257166 },
    { lat: 48.4475532, lng: 19.0258211 },
    { lat: 48.4475663, lng: 19.0259905 },
    { lat: 48.4476814, lng: 19.0260803 },
    { lat: 48.4478636, lng: 19.0260485 },
    { lat: 48.4480069, lng: 19.0264309 },
    { lat: 48.4481929, lng: 19.0264086 },
    { lat: 48.4484338, lng: 19.0266681 },
    { lat: 48.4487609, lng: 19.0262031 },
    { lat: 48.4490213, lng: 19.0261815 },
    { lat: 48.4490392, lng: 19.0260218 },
    { lat: 48.4497453, lng: 19.0258704 },
    { lat: 48.4498734, lng: 19.0257486 },
    { lat: 48.4502695, lng: 19.0258699 },
    { lat: 48.4503497, lng: 19.0259839 },
    { lat: 48.4505238, lng: 19.0259261 },
    { lat: 48.4506272, lng: 19.0261206 },
    { lat: 48.4508121, lng: 19.0260785 },
    { lat: 48.4516067, lng: 19.0272283 },
    { lat: 48.4522783, lng: 19.028606 },
    { lat: 48.4523657, lng: 19.0296015 },
    { lat: 48.4528048, lng: 19.031172 },
    { lat: 48.4532991, lng: 19.0320095 },
    { lat: 48.4535494, lng: 19.0325873 },
    { lat: 48.4536964, lng: 19.0330237 },
    { lat: 48.4537657, lng: 19.0334125 },
    { lat: 48.4538385, lng: 19.0340922 },
    { lat: 48.4538352, lng: 19.0362186 },
    { lat: 48.4536165, lng: 19.0384112 },
    { lat: 48.4539941, lng: 19.0397697 },
    { lat: 48.4540636, lng: 19.040254 },
    { lat: 48.454105, lng: 19.0410087 },
    { lat: 48.4540647, lng: 19.0411927 },
    { lat: 48.4541415, lng: 19.0426472 },
    { lat: 48.4540019, lng: 19.0441527 },
    { lat: 48.4541722, lng: 19.046079 },
    { lat: 48.4542923, lng: 19.0466702 },
    { lat: 48.4544521, lng: 19.0471553 },
    { lat: 48.4550392, lng: 19.0484925 },
    { lat: 48.4556287, lng: 19.0495879 },
    { lat: 48.4560712, lng: 19.0501826 },
    { lat: 48.4564453, lng: 19.0513871 },
    { lat: 48.4565206, lng: 19.0522544 },
    { lat: 48.456446, lng: 19.0533068 },
    { lat: 48.4562513, lng: 19.0540539 },
    { lat: 48.4557677, lng: 19.0553072 },
    { lat: 48.4557219, lng: 19.0561244 },
    { lat: 48.4556391, lng: 19.056494 },
    { lat: 48.4556664, lng: 19.0569704 },
    { lat: 48.4555202, lng: 19.0576725 },
    { lat: 48.4554292, lng: 19.0587565 },
    { lat: 48.4552923, lng: 19.0592864 },
    { lat: 48.4551001, lng: 19.0597248 },
    { lat: 48.4547473, lng: 19.0601506 },
    { lat: 48.4543617, lng: 19.0608227 },
    { lat: 48.4539947, lng: 19.0637079 },
    { lat: 48.4537749, lng: 19.0643785 },
    { lat: 48.453102, lng: 19.0653041 },
    { lat: 48.4523013, lng: 19.0671199 },
    { lat: 48.4513842, lng: 19.0684812 },
    { lat: 48.4511124, lng: 19.0688114 },
    { lat: 48.4508402, lng: 19.0698622 },
    { lat: 48.4507515, lng: 19.0703631 },
    { lat: 48.4506785, lng: 19.0725917 },
    { lat: 48.4506174, lng: 19.0730177 },
    { lat: 48.4504915, lng: 19.0734269 },
    { lat: 48.4505162, lng: 19.0736272 },
    { lat: 48.4504579, lng: 19.0751187 },
    { lat: 48.4505081, lng: 19.0755999 },
    { lat: 48.4502109, lng: 19.0769152 },
    { lat: 48.4502038, lng: 19.0771316 },
    { lat: 48.4499777, lng: 19.0782677 },
    { lat: 48.4504877, lng: 19.0795364 },
    { lat: 48.4504961, lng: 19.0801562 },
    { lat: 48.4505699, lng: 19.0803233 },
    { lat: 48.450721, lng: 19.081024 },
    { lat: 48.4507967, lng: 19.082084 },
    { lat: 48.450603, lng: 19.0834157 },
    { lat: 48.4503423, lng: 19.0844459 },
    { lat: 48.4500476, lng: 19.0853401 },
    { lat: 48.4498892, lng: 19.0856464 },
    { lat: 48.4496667, lng: 19.0862656 },
    { lat: 48.4493449, lng: 19.0873177 },
    { lat: 48.449474, lng: 19.0889495 },
    { lat: 48.4494991, lng: 19.0905149 },
    { lat: 48.4495391, lng: 19.0908367 },
    { lat: 48.4496937, lng: 19.0912692 },
    { lat: 48.4497791, lng: 19.0916918 },
    { lat: 48.4498894, lng: 19.0925717 },
    { lat: 48.4498264, lng: 19.0930032 },
    { lat: 48.4498298, lng: 19.0934301 },
    { lat: 48.4500185, lng: 19.0944084 },
    { lat: 48.4500147, lng: 19.0948514 },
    { lat: 48.449948, lng: 19.0953891 },
    { lat: 48.4501302, lng: 19.0963515 },
    { lat: 48.4506587, lng: 19.0976993 },
    { lat: 48.4508136, lng: 19.0979354 },
    { lat: 48.4509823, lng: 19.0984703 },
    { lat: 48.4518336, lng: 19.1001671 },
    { lat: 48.45255, lng: 19.1013058 },
    { lat: 48.452648, lng: 19.1013718 },
    { lat: 48.4524274, lng: 19.1024433 },
    { lat: 48.4520624, lng: 19.1029675 },
    { lat: 48.4520017, lng: 19.1031963 },
    { lat: 48.4520043, lng: 19.103451 },
    { lat: 48.4518676, lng: 19.1039231 },
    { lat: 48.4516768, lng: 19.1038945 },
    { lat: 48.451557, lng: 19.1040707 },
    { lat: 48.4514718, lng: 19.1043693 },
    { lat: 48.4512499, lng: 19.1045108 },
    { lat: 48.4511051, lng: 19.1045012 },
    { lat: 48.4510001, lng: 19.1043866 },
    { lat: 48.4503245, lng: 19.1046765 },
    { lat: 48.4496282, lng: 19.1048623 },
    { lat: 48.4495604, lng: 19.1049949 },
    { lat: 48.4495718, lng: 19.1053143 },
    { lat: 48.449382, lng: 19.1055772 },
    { lat: 48.4485189, lng: 19.1059027 },
    { lat: 48.4484723, lng: 19.1059385 },
    { lat: 48.4484825, lng: 19.106034 },
    { lat: 48.4481201, lng: 19.1064086 },
    { lat: 48.4481306, lng: 19.1067515 },
    { lat: 48.4480055, lng: 19.106821 },
    { lat: 48.4478337, lng: 19.1070854 },
    { lat: 48.4477779, lng: 19.1075008 },
    { lat: 48.4476385, lng: 19.1077135 },
    { lat: 48.4475029, lng: 19.107824 },
    { lat: 48.4473534, lng: 19.1078057 },
    { lat: 48.4472551, lng: 19.1076424 },
    { lat: 48.4470014, lng: 19.1077807 },
    { lat: 48.4468704, lng: 19.1079545 },
    { lat: 48.446802, lng: 19.1078273 },
    { lat: 48.4467749, lng: 19.1075544 },
    { lat: 48.4465673, lng: 19.107598 },
    { lat: 48.4464691, lng: 19.1076858 },
    { lat: 48.4465494, lng: 19.1080731 },
    { lat: 48.4465029, lng: 19.1081362 },
    { lat: 48.4463454, lng: 19.1082324 },
    { lat: 48.4462749, lng: 19.1081306 },
    { lat: 48.4461543, lng: 19.1080849 },
    { lat: 48.445739, lng: 19.1086217 },
    { lat: 48.4457766, lng: 19.1087396 },
    { lat: 48.4459659, lng: 19.1088323 },
    { lat: 48.4461211, lng: 19.1090502 },
    { lat: 48.4461728, lng: 19.1092962 },
    { lat: 48.4460952, lng: 19.1093876 },
    { lat: 48.4460152, lng: 19.109391 },
    { lat: 48.4459564, lng: 19.1094991 },
    { lat: 48.4458733, lng: 19.1094637 },
    { lat: 48.4456996, lng: 19.1097077 },
    { lat: 48.445707, lng: 19.1098148 },
    { lat: 48.445803, lng: 19.1098147 },
    { lat: 48.4458398, lng: 19.1098961 },
    { lat: 48.4458515, lng: 19.1100212 },
    { lat: 48.4457727, lng: 19.1101803 },
    { lat: 48.4457809, lng: 19.1103019 },
    { lat: 48.4460672, lng: 19.1105246 },
    { lat: 48.4460988, lng: 19.1107508 },
    { lat: 48.4462318, lng: 19.110978 },
    { lat: 48.4462939, lng: 19.1108453 },
    { lat: 48.4464133, lng: 19.1107761 },
    { lat: 48.4464613, lng: 19.1106297 },
    { lat: 48.4465747, lng: 19.1106631 },
    { lat: 48.4466521, lng: 19.1107705 },
    { lat: 48.446667, lng: 19.1110686 },
    { lat: 48.4466099, lng: 19.1111893 },
    { lat: 48.446661, lng: 19.1112844 },
    { lat: 48.446632, lng: 19.1114766 },
    { lat: 48.4464461, lng: 19.1115661 },
    { lat: 48.4463543, lng: 19.1117167 },
    { lat: 48.4463617, lng: 19.111887 },
    { lat: 48.4461196, lng: 19.1119359 },
    { lat: 48.4460782, lng: 19.1120826 },
    { lat: 48.4461292, lng: 19.1121102 },
    { lat: 48.446376, lng: 19.1125174 },
    { lat: 48.446578, lng: 19.1126964 },
    { lat: 48.4473629, lng: 19.1130456 },
    { lat: 48.4478017, lng: 19.1133421 },
    { lat: 48.4483287, lng: 19.1134696 },
    { lat: 48.4491626, lng: 19.1141378 },
    { lat: 48.4493831, lng: 19.1141532 },
    { lat: 48.4497668, lng: 19.1145674 },
    { lat: 48.4505496, lng: 19.1150067 },
    { lat: 48.4510805, lng: 19.1156395 },
    { lat: 48.451271, lng: 19.1157083 },
    { lat: 48.4519162, lng: 19.1161897 },
    { lat: 48.4528778, lng: 19.1174829 },
    { lat: 48.4536925, lng: 19.1180608 },
    { lat: 48.4552642, lng: 19.1205353 },
    { lat: 48.4551979, lng: 19.1219478 },
    { lat: 48.455068, lng: 19.122738 },
    { lat: 48.4559025, lng: 19.1237389 },
    { lat: 48.4563716, lng: 19.1245126 },
    { lat: 48.4573448, lng: 19.126518 },
    { lat: 48.4574234, lng: 19.1266015 },
    { lat: 48.4577168, lng: 19.1274249 },
    { lat: 48.458452, lng: 19.1288821 },
    { lat: 48.4587535, lng: 19.1293678 },
    { lat: 48.4588931, lng: 19.1297716 },
    { lat: 48.4592688, lng: 19.1304356 },
    { lat: 48.4599821, lng: 19.1322046 },
    { lat: 48.4601663, lng: 19.1335749 },
    { lat: 48.4601086, lng: 19.1354603 },
    { lat: 48.4598461, lng: 19.1363013 },
    { lat: 48.4599803, lng: 19.1363257 },
    { lat: 48.4601384, lng: 19.1365371 },
    { lat: 48.4601526, lng: 19.1370054 },
    { lat: 48.4599204, lng: 19.1376189 },
    { lat: 48.4598244, lng: 19.137685 },
    { lat: 48.4598799, lng: 19.1378461 },
    { lat: 48.4598619, lng: 19.1380311 },
    { lat: 48.4599927, lng: 19.1385108 },
    { lat: 48.459928, lng: 19.1386157 },
    { lat: 48.4599487, lng: 19.1387342 },
    { lat: 48.4597435, lng: 19.1388509 },
    { lat: 48.4597863, lng: 19.1389417 },
    { lat: 48.4598864, lng: 19.1389592 },
    { lat: 48.4598637, lng: 19.1390921 },
    { lat: 48.4597423, lng: 19.1391892 },
    { lat: 48.4598902, lng: 19.139422 },
    { lat: 48.4597699, lng: 19.1396301 },
    { lat: 48.459647, lng: 19.1396779 },
    { lat: 48.4597375, lng: 19.139796 },
    { lat: 48.459679, lng: 19.1399123 },
    { lat: 48.4597167, lng: 19.1400402 },
    { lat: 48.4596914, lng: 19.1402118 },
    { lat: 48.4597515, lng: 19.1403333 },
    { lat: 48.4598543, lng: 19.1403139 },
    { lat: 48.460085, lng: 19.1406465 },
    { lat: 48.4602595, lng: 19.1406166 },
    { lat: 48.4603841, lng: 19.140722 },
    { lat: 48.4603482, lng: 19.1408607 },
    { lat: 48.4603979, lng: 19.1409545 },
    { lat: 48.4603927, lng: 19.141078 },
    { lat: 48.4604852, lng: 19.1411476 },
    { lat: 48.4605179, lng: 19.1410544 },
    { lat: 48.4605697, lng: 19.141055 },
    { lat: 48.4605961, lng: 19.1411543 },
    { lat: 48.4606676, lng: 19.1411854 },
    { lat: 48.4607519, lng: 19.1411145 },
    { lat: 48.4608301, lng: 19.1412812 },
    { lat: 48.4609446, lng: 19.1413409 },
    { lat: 48.4610955, lng: 19.1411783 },
    { lat: 48.4612955, lng: 19.1413005 },
    { lat: 48.4613693, lng: 19.1414681 },
    { lat: 48.4615778, lng: 19.1414 },
    { lat: 48.4617183, lng: 19.1415046 },
    { lat: 48.4618018, lng: 19.1414399 },
    { lat: 48.4617459, lng: 19.1411307 },
    { lat: 48.4618188, lng: 19.1410764 },
    { lat: 48.4619642, lng: 19.1413279 },
    { lat: 48.4620311, lng: 19.1413574 },
    { lat: 48.4622452, lng: 19.1411732 },
    { lat: 48.4624478, lng: 19.1412137 },
    { lat: 48.4626341, lng: 19.1414017 },
    { lat: 48.4628189, lng: 19.1412606 },
    { lat: 48.4628954, lng: 19.1413266 },
    { lat: 48.4629938, lng: 19.1412865 },
    { lat: 48.4631159, lng: 19.141319 },
    { lat: 48.4631659, lng: 19.1415752 },
    { lat: 48.4632663, lng: 19.1416236 },
    { lat: 48.4633888, lng: 19.1415019 },
    { lat: 48.463544, lng: 19.1415154 },
    { lat: 48.463651, lng: 19.1416548 },
    { lat: 48.4638061, lng: 19.1415752 },
    { lat: 48.4639655, lng: 19.14179 },
    { lat: 48.4642723, lng: 19.1416104 },
    { lat: 48.4643822, lng: 19.1414596 },
    { lat: 48.4644499, lng: 19.1414388 },
    { lat: 48.464427, lng: 19.1415668 },
    { lat: 48.4645676, lng: 19.1415645 },
    { lat: 48.4646701, lng: 19.1414021 },
    { lat: 48.4648222, lng: 19.1415577 },
    { lat: 48.4648162, lng: 19.1416902 },
    { lat: 48.4647327, lng: 19.1417773 },
    { lat: 48.4648573, lng: 19.1419701 },
    { lat: 48.4650275, lng: 19.1419485 },
    { lat: 48.4651439, lng: 19.1418183 },
    { lat: 48.4652159, lng: 19.1418603 },
    { lat: 48.4654052, lng: 19.1416999 },
    { lat: 48.4654248, lng: 19.141843 },
    { lat: 48.4655659, lng: 19.1418254 },
    { lat: 48.4656057, lng: 19.1416273 },
    { lat: 48.4658429, lng: 19.141637 },
    { lat: 48.4659161, lng: 19.1417943 },
    { lat: 48.4659977, lng: 19.1417582 },
    { lat: 48.4660691, lng: 19.1416078 },
    { lat: 48.4661628, lng: 19.1415866 },
    { lat: 48.4661818, lng: 19.1418118 },
    { lat: 48.4662099, lng: 19.1418069 },
    { lat: 48.4662605, lng: 19.141694 },
    { lat: 48.4663128, lng: 19.1417254 },
    { lat: 48.4664109, lng: 19.1414491 },
    { lat: 48.4665592, lng: 19.1414619 },
    { lat: 48.4666417, lng: 19.1412385 },
    { lat: 48.4668345, lng: 19.1411487 },
    { lat: 48.4668373, lng: 19.1412731 },
    { lat: 48.4669405, lng: 19.1413109 },
    { lat: 48.4669901, lng: 19.1412187 },
    { lat: 48.4670978, lng: 19.1413231 },
    { lat: 48.4670718, lng: 19.141504 },
    { lat: 48.4671572, lng: 19.1415648 },
    { lat: 48.4673199, lng: 19.1415483 },
    { lat: 48.4673609, lng: 19.1416712 },
    { lat: 48.4675089, lng: 19.1416113 },
    { lat: 48.4674588, lng: 19.1413963 },
    { lat: 48.4675255, lng: 19.1413544 },
    { lat: 48.4676378, lng: 19.1414143 },
    { lat: 48.4677332, lng: 19.1413599 },
    { lat: 48.4677714, lng: 19.1411969 },
    { lat: 48.4678471, lng: 19.1410857 },
    { lat: 48.4679249, lng: 19.1410869 },
    { lat: 48.4679975, lng: 19.1409353 },
    { lat: 48.4681735, lng: 19.141074 },
    { lat: 48.4683649, lng: 19.140865 },
    { lat: 48.4684345, lng: 19.1411542 },
    { lat: 48.468584, lng: 19.141011 },
    { lat: 48.4686737, lng: 19.1410912 },
    { lat: 48.4687396, lng: 19.1408749 },
    { lat: 48.4688694, lng: 19.1408103 },
    { lat: 48.4690344, lng: 19.1409552 },
    { lat: 48.4690906, lng: 19.1411322 },
    { lat: 48.4694307, lng: 19.1417176 },
    { lat: 48.4694929, lng: 19.1419486 },
    { lat: 48.4697353, lng: 19.1420874 },
    { lat: 48.4698182, lng: 19.1420774 },
    { lat: 48.4697932, lng: 19.1421878 },
    { lat: 48.4699366, lng: 19.1422354 },
    { lat: 48.4700224, lng: 19.1425366 },
    { lat: 48.470091, lng: 19.1425772 },
    { lat: 48.4700902, lng: 19.1427667 },
    { lat: 48.4701593, lng: 19.1428634 },
    { lat: 48.4702061, lng: 19.1433086 },
    { lat: 48.4704854, lng: 19.1437335 },
    { lat: 48.4707385, lng: 19.1439755 },
    { lat: 48.4707433, lng: 19.144278 },
    { lat: 48.4710293, lng: 19.1447238 },
    { lat: 48.4712399, lng: 19.1457006 },
    { lat: 48.4719096, lng: 19.147251 },
    { lat: 48.4721252, lng: 19.1476223 },
    { lat: 48.472731, lng: 19.1491102 },
    { lat: 48.472833, lng: 19.1495344 },
    { lat: 48.4726924, lng: 19.1503839 },
    { lat: 48.4728108, lng: 19.1510208 },
    { lat: 48.4731131, lng: 19.1519843 },
    { lat: 48.4732665, lng: 19.1531232 },
    { lat: 48.4732949, lng: 19.1536799 },
    { lat: 48.4733392, lng: 19.1538867 },
    { lat: 48.4735128, lng: 19.1542073 },
    { lat: 48.4737487, lng: 19.155813 },
    { lat: 48.4737307, lng: 19.1562712 },
    { lat: 48.4738026, lng: 19.1567879 },
    { lat: 48.4737714, lng: 19.1578036 },
    { lat: 48.4738759, lng: 19.1596649 },
    { lat: 48.4738904, lng: 19.1604385 },
    { lat: 48.4738531, lng: 19.160826 },
    { lat: 48.4742329, lng: 19.1616671 },
    { lat: 48.4742886, lng: 19.1623427 },
    { lat: 48.4745541, lng: 19.1634727 },
    { lat: 48.4746018, lng: 19.1646256 },
    { lat: 48.4745267, lng: 19.1653871 },
    { lat: 48.4745602, lng: 19.1658378 },
    { lat: 48.4759438, lng: 19.1669458 },
    { lat: 48.4766649, lng: 19.1673926 },
    { lat: 48.4770426, lng: 19.1698429 },
    { lat: 48.4771338, lng: 19.1713491 },
    { lat: 48.4775986, lng: 19.1720001 },
    { lat: 48.4784905, lng: 19.1727371 },
    { lat: 48.4788573, lng: 19.1732483 },
    { lat: 48.4794562, lng: 19.1739164 },
    { lat: 48.4795786, lng: 19.174364 },
    { lat: 48.4802601, lng: 19.1749496 },
    { lat: 48.4807186, lng: 19.1757211 },
    { lat: 48.4820874, lng: 19.1777454 },
    { lat: 48.4837351, lng: 19.1790431 },
    { lat: 48.4848806, lng: 19.1798281 },
    { lat: 48.4853433, lng: 19.1805047 },
    { lat: 48.4864375, lng: 19.1824227 },
    { lat: 48.4874631, lng: 19.1855594 },
    { lat: 48.4874898, lng: 19.1860554 },
    { lat: 48.4880321, lng: 19.1855058 },
    { lat: 48.4885118, lng: 19.1848792 },
    { lat: 48.4890274, lng: 19.1837377 },
    { lat: 48.4895987, lng: 19.1827459 },
    { lat: 48.4900316, lng: 19.1821761 },
    { lat: 48.4904327, lng: 19.1812295 },
    { lat: 48.4912379, lng: 19.178966 },
    { lat: 48.4915605, lng: 19.1783046 },
    { lat: 48.4923033, lng: 19.1764741 },
    { lat: 48.4924739, lng: 19.1761132 },
    { lat: 48.493449, lng: 19.1746 },
    { lat: 48.4938334, lng: 19.1737039 },
    { lat: 48.4941551, lng: 19.1732099 },
    { lat: 48.4946371, lng: 19.1721024 },
    { lat: 48.4951392, lng: 19.1713083 },
    { lat: 48.4958435, lng: 19.1694267 },
    { lat: 48.4959722, lng: 19.1670282 },
    { lat: 48.4960086, lng: 19.1661412 },
    { lat: 48.4959272, lng: 19.1636897 },
    { lat: 48.4960142, lng: 19.1628483 },
    { lat: 48.4971106, lng: 19.1615377 },
    { lat: 48.4982193, lng: 19.1606088 },
    { lat: 48.4987645, lng: 19.1586374 },
    { lat: 48.4995485, lng: 19.1570715 },
    { lat: 48.4997983, lng: 19.156284 },
    { lat: 48.5009008, lng: 19.1541648 },
    { lat: 48.5017782, lng: 19.1519076 },
    { lat: 48.5023094, lng: 19.1501333 },
    { lat: 48.5022665, lng: 19.1494268 },
    { lat: 48.5021764, lng: 19.1489044 },
    { lat: 48.5021944, lng: 19.1485373 },
    { lat: 48.5023957, lng: 19.1474566 },
    { lat: 48.5018653, lng: 19.1478334 },
    { lat: 48.5006535, lng: 19.1496127 },
    { lat: 48.5007034, lng: 19.1497752 },
    { lat: 48.5006683, lng: 19.1502089 },
    { lat: 48.5005276, lng: 19.1505311 },
    { lat: 48.5004179, lng: 19.1506144 },
    { lat: 48.500283, lng: 19.150557 },
    { lat: 48.4998447, lng: 19.1500107 },
    { lat: 48.5001013, lng: 19.1496151 },
    { lat: 48.4999402, lng: 19.1492983 },
    { lat: 48.4999564, lng: 19.1489186 },
    { lat: 48.4997691, lng: 19.148347 },
    { lat: 48.49979, lng: 19.1481766 },
    { lat: 48.4997394, lng: 19.1481097 },
    { lat: 48.4999586, lng: 19.1475492 },
    { lat: 48.4997125, lng: 19.1472685 },
    { lat: 48.499415, lng: 19.1472475 },
    { lat: 48.499507, lng: 19.1469873 },
    { lat: 48.4995017, lng: 19.1468613 },
    { lat: 48.4995725, lng: 19.1468552 },
    { lat: 48.4995648, lng: 19.1464277 },
    { lat: 48.4995022, lng: 19.1464116 },
    { lat: 48.4995139, lng: 19.1453503 },
    { lat: 48.4993582, lng: 19.1451687 },
    { lat: 48.499204, lng: 19.1457181 },
    { lat: 48.4990064, lng: 19.1468297 },
    { lat: 48.498967, lng: 19.1476278 },
    { lat: 48.4991132, lng: 19.1478471 },
    { lat: 48.4990882, lng: 19.1481876 },
    { lat: 48.4988036, lng: 19.1483375 },
    { lat: 48.4987503, lng: 19.1486726 },
    { lat: 48.4984883, lng: 19.1487545 },
    { lat: 48.4981685, lng: 19.1485788 },
    { lat: 48.4978887, lng: 19.1483709 },
    { lat: 48.4972797, lng: 19.1476575 },
    { lat: 48.4972125, lng: 19.147461 },
    { lat: 48.497227, lng: 19.1473907 },
    { lat: 48.4969838, lng: 19.1467085 },
    { lat: 48.4965743, lng: 19.1446054 },
    { lat: 48.4965039, lng: 19.1436842 },
    { lat: 48.4965666, lng: 19.142994 },
    { lat: 48.4966951, lng: 19.1424013 },
    { lat: 48.4969717, lng: 19.1417941 },
    { lat: 48.497229, lng: 19.1418012 },
    { lat: 48.4984643, lng: 19.1414531 },
    { lat: 48.4989438, lng: 19.1418003 },
    { lat: 48.498812, lng: 19.1414856 },
    { lat: 48.4987871, lng: 19.140138 },
    { lat: 48.4987592, lng: 19.1398283 },
    { lat: 48.498699, lng: 19.1397949 },
    { lat: 48.498762, lng: 19.139384 },
    { lat: 48.4986228, lng: 19.137665 },
    { lat: 48.4986427, lng: 19.1370404 },
    { lat: 48.498739, lng: 19.1367093 },
    { lat: 48.4990639, lng: 19.1366011 },
    { lat: 48.4991191, lng: 19.1372592 },
    { lat: 48.4990515, lng: 19.1373959 },
    { lat: 48.4991587, lng: 19.1381733 },
    { lat: 48.4992883, lng: 19.1385629 },
    { lat: 48.4992476, lng: 19.1389891 },
    { lat: 48.4995888, lng: 19.1397118 },
    { lat: 48.4995775, lng: 19.1399857 },
    { lat: 48.4997716, lng: 19.1401694 },
    { lat: 48.5000482, lng: 19.1414522 },
    { lat: 48.5003234, lng: 19.1413855 },
    { lat: 48.5004653, lng: 19.141499 },
    { lat: 48.5006467, lng: 19.1420193 },
    { lat: 48.5008415, lng: 19.1422655 },
    { lat: 48.5016353, lng: 19.1423145 },
    { lat: 48.5017086, lng: 19.1424058 },
    { lat: 48.501926, lng: 19.1424024 },
    { lat: 48.5019295, lng: 19.1426356 },
    { lat: 48.501995, lng: 19.1428191 },
    { lat: 48.50209, lng: 19.1429144 },
    { lat: 48.5021814, lng: 19.1428476 },
    { lat: 48.5021258, lng: 19.1425695 },
    { lat: 48.5021724, lng: 19.1423943 },
    { lat: 48.5028331, lng: 19.1425553 },
    { lat: 48.5033322, lng: 19.1428452 },
    { lat: 48.503322, lng: 19.1424106 },
    { lat: 48.5033343, lng: 19.1419766 },
    { lat: 48.5033494, lng: 19.1385683 },
    { lat: 48.503112, lng: 19.1382152 },
    { lat: 48.5024655, lng: 19.1366319 },
    { lat: 48.5022952, lng: 19.1359045 },
    { lat: 48.5019123, lng: 19.1348657 },
    { lat: 48.5014247, lng: 19.1326909 },
    { lat: 48.5014105, lng: 19.1316094 },
    { lat: 48.5012189, lng: 19.1306075 },
    { lat: 48.5009304, lng: 19.1295678 },
    { lat: 48.5006278, lng: 19.1287159 },
    { lat: 48.5000549, lng: 19.1275059 },
    { lat: 48.4988712, lng: 19.1242368 },
    { lat: 48.498271, lng: 19.1227058 },
    { lat: 48.4979471, lng: 19.1211992 },
    { lat: 48.497908, lng: 19.1202115 },
    { lat: 48.4975256, lng: 19.1194565 },
    { lat: 48.4974247, lng: 19.1187854 },
    { lat: 48.4968149, lng: 19.1171113 },
    { lat: 48.4966078, lng: 19.1151647 },
    { lat: 48.496679, lng: 19.1143548 },
    { lat: 48.4967912, lng: 19.1139362 },
    { lat: 48.496814, lng: 19.1133081 },
    { lat: 48.4967683, lng: 19.1114812 },
    { lat: 48.4964037, lng: 19.1097717 },
    { lat: 48.4959129, lng: 19.1080644 },
    { lat: 48.4958575, lng: 19.1076363 },
    { lat: 48.4955443, lng: 19.1069593 },
    { lat: 48.4950677, lng: 19.1062641 },
    { lat: 48.4956115, lng: 19.1050736 },
    { lat: 48.4954584, lng: 19.1041016 },
    { lat: 48.494797, lng: 19.1013295 },
    { lat: 48.494703, lng: 19.1007999 },
    { lat: 48.4947145, lng: 19.1005327 },
    { lat: 48.4944519, lng: 19.0997438 },
    { lat: 48.4944559, lng: 19.0991888 },
    { lat: 48.4945412, lng: 19.0989795 },
    { lat: 48.4949312, lng: 19.0986155 },
    { lat: 48.4951242, lng: 19.0974684 },
    { lat: 48.4950905, lng: 19.0950989 },
    { lat: 48.4953071, lng: 19.0948096 },
    { lat: 48.4952835, lng: 19.0946971 },
    { lat: 48.4949583, lng: 19.0946603 },
    { lat: 48.4947323, lng: 19.0946528 },
    { lat: 48.4946511, lng: 19.0945585 },
    { lat: 48.4945742, lng: 19.0942626 },
    { lat: 48.4943898, lng: 19.0942699 },
    { lat: 48.4942477, lng: 19.0941363 },
    { lat: 48.4942055, lng: 19.0940142 },
    { lat: 48.4942428, lng: 19.0934495 },
    { lat: 48.4941911, lng: 19.0932286 },
    { lat: 48.4942621, lng: 19.0930712 },
    { lat: 48.4942904, lng: 19.0927567 },
    { lat: 48.4943855, lng: 19.0926276 },
    { lat: 48.4945771, lng: 19.0925611 },
    { lat: 48.4945957, lng: 19.0924364 },
    { lat: 48.4945423, lng: 19.0923134 },
    { lat: 48.494618, lng: 19.0921367 },
    { lat: 48.4947774, lng: 19.0921248 },
    { lat: 48.4948442, lng: 19.0919925 },
    { lat: 48.4948505, lng: 19.0916455 },
    { lat: 48.4949239, lng: 19.0914211 },
    { lat: 48.4948553, lng: 19.0910653 },
    { lat: 48.4949573, lng: 19.0904445 },
    { lat: 48.4948549, lng: 19.0901767 },
    { lat: 48.4946947, lng: 19.0901298 },
    { lat: 48.4945567, lng: 19.0898724 },
    { lat: 48.4949462, lng: 19.0895643 },
    { lat: 48.4949192, lng: 19.0892282 },
    { lat: 48.4949808, lng: 19.0890659 },
    { lat: 48.4949754, lng: 19.088844 },
    { lat: 48.4949112, lng: 19.0882773 },
    { lat: 48.4949727, lng: 19.0879102 },
    { lat: 48.4949479, lng: 19.0877065 },
    { lat: 48.4947529, lng: 19.0873216 },
    { lat: 48.4948276, lng: 19.0871248 },
    { lat: 48.4949687, lng: 19.0870167 },
    { lat: 48.495052, lng: 19.0865623 },
    { lat: 48.4951821, lng: 19.0863901 },
    { lat: 48.4952409, lng: 19.0859448 },
    { lat: 48.4953429, lng: 19.0857278 },
    { lat: 48.4954887, lng: 19.0856477 },
    { lat: 48.4956625, lng: 19.085107 },
    { lat: 48.4957312, lng: 19.0850157 },
    { lat: 48.4958915, lng: 19.0851315 },
    { lat: 48.4961252, lng: 19.0850713 },
    { lat: 48.4961801, lng: 19.0848433 },
    { lat: 48.4964257, lng: 19.084657 },
    { lat: 48.4964742, lng: 19.0842274 },
    { lat: 48.4963371, lng: 19.0841928 },
    { lat: 48.4963049, lng: 19.0841108 },
    { lat: 48.4965467, lng: 19.0836508 },
    { lat: 48.4962435, lng: 19.0830208 },
    { lat: 48.4961205, lng: 19.0825684 },
    { lat: 48.4960772, lng: 19.0823074 },
    { lat: 48.4961696, lng: 19.081993 },
    { lat: 48.4960795, lng: 19.081881 },
    { lat: 48.4960595, lng: 19.0816415 },
    { lat: 48.4959844, lng: 19.0815153 },
    { lat: 48.4956818, lng: 19.0813455 },
    { lat: 48.4957358, lng: 19.0810092 },
    { lat: 48.4952418, lng: 19.0798523 },
    { lat: 48.494931, lng: 19.079231 },
    { lat: 48.4948204, lng: 19.0791355 },
    { lat: 48.4946535, lng: 19.0787329 },
    { lat: 48.4946902, lng: 19.0784077 },
    { lat: 48.4948715, lng: 19.0779283 },
    { lat: 48.4948738, lng: 19.0775257 },
    { lat: 48.4947784, lng: 19.0772859 },
    { lat: 48.4943982, lng: 19.0768079 },
    { lat: 48.4942501, lng: 19.0765311 },
    { lat: 48.4944319, lng: 19.0763537 },
    { lat: 48.4945061, lng: 19.0761047 },
    { lat: 48.4945007, lng: 19.0759731 },
    { lat: 48.4943468, lng: 19.0755796 },
    { lat: 48.4944302, lng: 19.0754187 },
    { lat: 48.4947722, lng: 19.0753135 },
    { lat: 48.4948279, lng: 19.075053 },
    { lat: 48.4948013, lng: 19.0748385 },
    { lat: 48.4947106, lng: 19.074627 },
    { lat: 48.4948352, lng: 19.0740061 },
    { lat: 48.4948179, lng: 19.0738627 },
    { lat: 48.4945591, lng: 19.0732668 },
    { lat: 48.4944247, lng: 19.0725413 },
    { lat: 48.4947707, lng: 19.0717094 },
    { lat: 48.4953126, lng: 19.0714965 },
    { lat: 48.4954358, lng: 19.0709853 },
    { lat: 48.4955474, lng: 19.0708731 },
    { lat: 48.4956468, lng: 19.0704957 },
    { lat: 48.4959557, lng: 19.0699975 },
  ],
};

export default UnitsZvolen;
