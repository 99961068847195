import { useEffect, useRef, useState } from "react";

const Municipalities = ({
    options, name, code, selectedVal, handleChange , district, changeIsOpen, resetAvgPrice
}) => {
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const inputRef = useRef(null);

  useEffect(() => {
    document.addEventListener("click", toggle);

    return () => document.removeEventListener("click", toggle);
  }, []);

  const selectOption = (option) => {
    setQuery(() => "");
    handleChange(option[name]);
    //setIsOpen((isOpen) => !isOpen);
    changeIsOpen(false)
    resetAvgPrice([])
  };

  function toggle(e) {
    setIsOpen(e && e.target === inputRef.current);
  }

  const getDisplayValue = () => {
    if (query) return query;
    if (selectedVal) return selectedVal;

    return "";
  };

  const filter = (options) => {
    return options.filter(
     (option) => option[name].toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(query.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) > -1
    );
  };

  return (
    <div className="relative w-screen px-2 sm:max-w-sm">
        <p className="text-white">Lokalita:</p>
        <input
            className="w-full mb-4 p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600"
            ref={inputRef}
            type="text"
            value={getDisplayValue()}
            name="searchTerm"
            placeholder="Lokalita"
            onChange={(e) => {
                setQuery(e.target.value);
                handleChange(null);
            }}
            onClick={toggle}
            onChangeCapture={toggle}
        />

        <div className={`options ${isOpen ? "open rounded-md" : ""}`}>
            {filter(options).map((option, index) => {
            return (
                <div
                    onClick={() => selectOption(option)}
                    className={`box-border text-black cursor-pointer block px-2.5 py-2 bg-white hover:bg-sky-500 ${
                        option[name] === selectedVal ? "" : ""
                    }`}
                    key={`${code}-${index}`}
                    > 
                    {option[name]}, (okres {option[district]})
                </div>
            );
            })}
        </div>
    </div>
  );
};

export default Municipalities