import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import clsx from "clsx";
// import { useMediaQuery } from "usehooks-ts";

export default function BookInput({ inputValues, setInputValues, size }) {
  // const isSmallDevice = useMediaQuery("only screen and (max-width : 640px)");

  const isWeekend = (date) => {
    const day = date.day();
    return day === 0 || day === 6;
  };

  function onChange(e) {
    setInputValues({ ...inputValues, book: e });
    return;
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div
        className={clsx("text-start px-4 w-full", {
          // "flex flex-col w-1/2": !isSmallDevice && size.full,
          // "w-full": isSmallDevice || !size.full,
        })}
      >
        Rezervuj
        <DatePicker
          shouldDisableDate={isWeekend}
          disablePast={true}
          value={inputValues.book}
          onChange={(newValue) => {
            onChange(newValue);
          }}
          className="w-full rounded-xl bg-white border border-opacity-50 border-sky-500"
        />
      </div>
    </LocalizationProvider>
  );
}
