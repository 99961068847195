import {
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Paper,
  Typography,
  Collapse,
  useTheme,
  MobileStepper,
} from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useMediaQuery } from "@uidotdev/usehooks";
import cena from "../Images/gifs/cena.gif";
import cenaSmall from "../Images/gifs/cenaSmall.gif";
import cenovaMapa from "../Images/gifs/cenovaMapa.gif";
import cenovaMapaSmall from "../Images/gifs/cenovaMapaSmall.gif";
import calc from "../Images/gifs/calc.gif";
import calcSmall from "../Images/gifs/calcSmall.gif";
import predaj from "../Images/gifs/predaj.gif";
import predajSmall from "../Images/gifs/predajSmall.gif";

const steps = [
  {
    gif: `${cena}`,
    smallGif: `${cenaSmall}`,
    label: "Okamžite zistiť aktuálnu trhovú cenu a dôležité informácie domu, bytu alebo pozemku",
    fullDescription: `Vyhľadajte a priblížte si na mape akýkoľvek dom, byt alebo pozemok, následne doň kliknite. 
                    V prípade bytu zvoľte vchod, poschodie a číslo bytu ak je treba.
                    V momente Vám zobrazíme výsledok. Jednoducho a intuitívne si môžete prezerať všetky informácie 
                    a vykonať požadovanú aktuvitu z kontextovej ponuky. Napríklad ak je byt na predaj, 
                    môžete prejaviť záujem a kontaktovať predajcu. Ak ste vlastníkom, môžete ho predať, poistiť a iné. 
                    Ak sa Vám nehnuteľnosť páči, môžete ju hodnotiť, prípadne zdieľať na sociálnych sieťach`,
    shortDescription: `Vyhľadajte a priblížte si na mape akýkoľvek dom, byt alebo pozemok, následne doň kliknite...`,
  },
  {
    gif: `${cenovaMapa}`,
    smallGif: `${cenovaMapaSmall}`,
    label: "Prehľadávať cenovú mapu",
    fullDescription: `V spodnej časti obrazovky kliknite na tlačidlo "Cenová mapa". Mapa sa vám vycentruje a vyfarbí 
                    na základe priemernej ceny za m2 bytu podľa jednotlivých krajov. Môžete kliknúť do mapy na ľubovoľný kraj a 
                    dostanete výsledok. Ten môžete daľaj upresniť, napríklad o koľkoizbový byt ide, akú ma rozlohu a podobne., 
                    pričom tieto dodatočné parametre zohľadníme pri určení aktuálnej trhovej ceny. Podobne postupujte, 
                    ak chcete zisťovať premernú cenu v konkrétnom okrese alebo obci (klikom na mape alebo výberom z 
                    možností z ponuky vedľa vyhľadávacieho políčka)`,
    shortDescription: `V spodnej časti obrazovky kliknite na tlačidlo "Cenová mapa"...`,
  },
  {
    gif: `${calc}`,
    smallGif: `${calcSmall}`,
    label: "Vykalkulovať aktuálnu trhovú cenu nehnuteľnosti na základe vstupných parametrov.",
    fullDescription: `V spodnej časti obrazovky kliknite na tlačidlo "Kalkulačka". Následne si vyberajte z ponúkaných možností. 
                    Najprv vyberte druh, potom lokalitu, stav, úžitkovú plochu a kliknite na tlačidlo "Zisti cenu". V momente 
                    vám zobrazíme výslednú aktuálnu trhovú cenu aj presnosť, s akou môžete k danej cene pristupovať. 
                    Ak dostanete výsledok s 90% presnosťou, tak máte skoro obdobu znaleckého posudku. Naopak, ak 
                    v databáze nemáme dostatočný počet podobných nehnuteľností pre výpočet tak Vám zobrazíme správu 
                    o nemožnosti výpočtu.`,
    shortDescription: `V spodnej časti obrazovky kliknite na tlačidlo "Kalkulačka"...`,
  },
  {
    gif: `${predaj}`,
    smallGif: `${predajSmall}`,
    label: "Inzerovať, kúpiť, navrhnúť cenu, úverovať, poistiť, naceniť získať geodetické a iné realitné služby",
    fullDescription: `Realitný kompas je Váš jedinečný nástroj, ktorý poskytuje všetko súvisiace s nehnuteľnosťami. Práca s ním 
                    by mala byť jednoduchá a intuitívna. A zároveň by Vám mala pomôcť zorientovať sa v realitnom svete, 
                    v súvisiacej administratíve a vo všetkých realitných úkonoch. Bez zbytočného stresu, zdlhavých 
                    postupov a čakania. Sme tu pre Vás, tak poďme na to `,
    shortDescription: `Realitný kompas je Váš jedinečný nástroj, ktorý poskytuje všetko súvisiace s nehnuteľnosťami...`,
  },
];

export default function VerticalLinearStepper({ setIntroVisible, introVisible }) {
  const theme = useTheme();
  const isSmallDevice = useMediaQuery("only screen and (max-width : 640px)");
  const maxSteps = steps.length;

  const [activeStep, setActiveStep] = useState(0);
  const [visibleStates, setVisibleStates] = useState(Array(steps.length).fill(false));
  // const [isFullTextVisible, setIsFullTextVisible] = useState(false)
  const toggleVisibility = (index) => {
    setVisibleStates((prev) => prev.map((isVisible, i) => (i === index ? !isVisible : isVisible)));
  };

  useEffect(() => {
    setActiveStep(0);
    // eslint-disable-next-line
  }, [introVisible]);

  const handleNext = (index) => {
    if (index === steps.length - 1) {
      setActiveStep(0);
      setIntroVisible(false);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const handleReset = () => {
  //   setActiveStep(0);
  // };

  return introVisible ? (
    <div className="backdrop-brightness-50 absolute w-full h-full flex justify-center items-center bg-transparent z-30">
      {!isSmallDevice ? (
        <Stepper activeStep={activeStep} orientation="vertical" className="bg-sky-200 w-[672px] rounded-2xl p-2">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
              // optional={index === steps.length - 1 ? <Typography variant="caption">Last step</Typography> : null}
              >
                {step.label}
              </StepLabel>
              <StepContent>
                <Box
                  sx={{
                    "& > :not(style)": {
                      display: "flex",
                      justifyContent: "space-around",
                      height: 120,
                      width: 250,
                    },
                  }}
                >
                  <Collapse in={index === activeStep}>
                    <img src={step.gif} alt="" loading="lazy" />
                  </Collapse>
                </Box>
                <Collapse in={!visibleStates[index]}>
                  <Typography>{step.shortDescription}</Typography>
                </Collapse>
                <Collapse in={visibleStates[index]}>
                  <Typography>{step.fullDescription}</Typography>
                </Collapse>
                <Button variant="text" onClick={() => toggleVisibility(index)}>
                  {visibleStates[index] ? "zobraziť menej" : "zobraziť viac"}
                </Button>
                <Box sx={{ mb: 2 }}>
                  <Button variant="contained" onClick={() => handleNext(index)} sx={{ mt: 1, mr: 1 }}>
                    {index === steps.length - 1 ? "Začať" : "Ďalej"}
                  </Button>
                  <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                    Späť
                  </Button>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      ) : (
        <Box sx={{ width: "100%", flexGrow: 1, height: "100%" }}>
          <Paper
            square
            elevation={0}
            sx={{
              display: "flex",
              alignItems: "center",
              height: 50,
              // pl: 2,
              bgcolor: "#BAE6FD",
            }}
          >
            <Typography className="text-justify px-2">{steps[activeStep].label}</Typography>
          </Paper>

          <div className="bg-sky-200 h-[86%] p-2 overflow-y-scroll">
            <div className="w-full flex items-center justify-center ">
              {/* <Box
                sx={{
                  "& > :not(style)": {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    height: "70%",
                    width: "100%",
                    backgroundColor: "yellow",
                  },
                }}
              > */}
              <img src={steps[activeStep].smallGif} alt="" loading="lazy" className="!h-4/5 !w-4/5" />
              {/*object-contain */}
              {/* </Box> */}
            </div>
            <div className="text-justify">{steps[activeStep].fullDescription}</div>
          </div>
          <MobileStepper
            sx={{ backgroundColor: "#BAE6FD" }}
            variant="text"
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button size="small" onClick={() => handleNext(activeStep)}>
                {activeStep === steps.length - 1 ? "Začať" : "Ďalej"}
                {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
              </Button>
            }
            backButton={
              <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                Späť
              </Button>
            }
          />
        </Box>
      )}
    </div>
  ) : null;
  // {
  //   activeStep === steps.length && (
  //     <Paper square elevation={0} sx={{ p: 3 }} className="rounded-2xl absolute">
  //       <Typography>All steps completed - you&apos;re finished</Typography>
  //       <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
  //         Reset
  //       </Button>
  //     </Paper>
  //   );
  // }
}
