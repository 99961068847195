export const propertyListService = {
  getPropertyList: async (registerNumber, city, sessionIdToken) => {
    return await fetch(process.env.REACT_APP_LV_SERVICE + `${city}`, {
      headers: {
        registernumber: registerNumber,
        sessionIdToken: sessionIdToken,
      },
    });
  },
};
