export const averagePriceService = {
  getAveragePrice: async (category, statusCode, usableArea, landArea, location) => {
    return await fetch(process.env.REACT_APP_SERVICE + `${category}`, {
      headers: {
        status: statusCode,
        landarea: landArea,
        usablearea: usableArea,
        municipalcode: location,
      },
    });
  },

  getGeneralAvgPrice: async (parent) => {
    // console.log("parent = ", parent);
    return await fetch(process.env.REACT_APP_AVGPRICE_SERVICE + `avgPriceGeneral`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ parent: parent }),
    });
  },

  getUnitAvgPrice: async (parent, obec) => {
    return await fetch(process.env.REACT_APP_AVGPRICE_SERVICE + `avgUnitPrice`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ parent: parent, obec: obec }),
    });
  },

  getRegionAvgPrice: async (parent, categoryCode, statusCode, usableArea, landArea) => {
    return await fetch(process.env.REACT_APP_AVGPRICE_SERVICE + `avgRegionPrice`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        parent: parent,
        categoryCode: categoryCode,
        statusCode: statusCode,
        usableArea: usableArea,
        landArea: landArea,
      }),
    });
  },

  getDistrictAvgPrice: async (parent, categoryCode, statusCode, usableArea, landArea) => {
    return await fetch(process.env.REACT_APP_AVGPRICE_SERVICE + `avgDistrictPrice`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        parent: parent,
        categoryCode: categoryCode,
        statusCode: statusCode,
        usableArea: usableArea,
        landArea: landArea,
      }),
    });
  },
};
