const UnitsPoltár = {
  HrnčiarskeZalužany: [
    { lat: 48.4153934, lng: 19.8609658 },
    { lat: 48.4152717, lng: 19.8607841 },
    { lat: 48.4148604, lng: 19.8601054 },
    { lat: 48.4140988, lng: 19.8569878 },
    { lat: 48.4138284, lng: 19.8542542 },
    { lat: 48.4138368, lng: 19.8536696 },
    { lat: 48.413783, lng: 19.8529997 },
    { lat: 48.4138262, lng: 19.852227 },
    { lat: 48.4137153, lng: 19.8517981 },
    { lat: 48.4132179, lng: 19.85114 },
    { lat: 48.4126194, lng: 19.8506939 },
    { lat: 48.4124627, lng: 19.8502217 },
    { lat: 48.4124225, lng: 19.849321 },
    { lat: 48.4126512, lng: 19.8480731 },
    { lat: 48.4128484, lng: 19.8476342 },
    { lat: 48.4128669, lng: 19.8474087 },
    { lat: 48.4126675, lng: 19.84622 },
    { lat: 48.4126629, lng: 19.8455417 },
    { lat: 48.4126114, lng: 19.8453407 },
    { lat: 48.4126651, lng: 19.8451731 },
    { lat: 48.4125905, lng: 19.8449626 },
    { lat: 48.4126512, lng: 19.8448535 },
    { lat: 48.4126241, lng: 19.8444366 },
    { lat: 48.412706, lng: 19.844252 },
    { lat: 48.4127342, lng: 19.8437049 },
    { lat: 48.4128254, lng: 19.8432605 },
    { lat: 48.4129218, lng: 19.8431205 },
    { lat: 48.4129691, lng: 19.842807 },
    { lat: 48.4137516, lng: 19.8410608 },
    { lat: 48.4144744, lng: 19.8401196 },
    { lat: 48.4155316, lng: 19.8389081 },
    { lat: 48.4133912, lng: 19.8378477 },
    { lat: 48.4125223, lng: 19.8377915 },
    { lat: 48.4106892, lng: 19.8382539 },
    { lat: 48.4087314, lng: 19.8353613 },
    { lat: 48.4064136, lng: 19.8355373 },
    { lat: 48.4029072, lng: 19.8372606 },
    { lat: 48.4018785, lng: 19.8381922 },
    { lat: 48.40063, lng: 19.839679 },
    { lat: 48.400751, lng: 19.840168 },
    { lat: 48.400959, lng: 19.840984 },
    { lat: 48.401179, lng: 19.841868 },
    { lat: 48.401234, lng: 19.842599 },
    { lat: 48.401316, lng: 19.84368 },
    { lat: 48.401393, lng: 19.844698 },
    { lat: 48.40145, lng: 19.845446 },
    { lat: 48.401481, lng: 19.845891 },
    { lat: 48.401475, lng: 19.847116 },
    { lat: 48.40147, lng: 19.847982 },
    { lat: 48.401467, lng: 19.84862 },
    { lat: 48.40146, lng: 19.849381 },
    { lat: 48.401504, lng: 19.850388 },
    { lat: 48.401539, lng: 19.851104 },
    { lat: 48.40155, lng: 19.851379 },
    { lat: 48.401484, lng: 19.851907 },
    { lat: 48.401343, lng: 19.85303 },
    { lat: 48.40124, lng: 19.853828 },
    { lat: 48.401157, lng: 19.854467 },
    { lat: 48.40111, lng: 19.854835 },
    { lat: 48.401083, lng: 19.855048 },
    { lat: 48.401056, lng: 19.855273 },
    { lat: 48.401045, lng: 19.855362 },
    { lat: 48.401028, lng: 19.855503 },
    { lat: 48.400896, lng: 19.856265 },
    { lat: 48.400764, lng: 19.857063 },
    { lat: 48.400739, lng: 19.857342 },
    { lat: 48.400672, lng: 19.858214 },
    { lat: 48.399673, lng: 19.861033 },
    { lat: 48.398943, lng: 19.86267 },
    { lat: 48.398755, lng: 19.863059 },
    { lat: 48.398563, lng: 19.86344 },
    { lat: 48.398408, lng: 19.863936 },
    { lat: 48.398197, lng: 19.864952 },
    { lat: 48.397807, lng: 19.866807 },
    { lat: 48.397487, lng: 19.868923 },
    { lat: 48.396134, lng: 19.872979 },
    { lat: 48.395895, lng: 19.87372 },
    { lat: 48.395622, lng: 19.874726 },
    { lat: 48.394902, lng: 19.877409 },
    { lat: 48.395162, lng: 19.879861 },
    { lat: 48.394832, lng: 19.880164 },
    { lat: 48.393863, lng: 19.880353 },
    { lat: 48.393791, lng: 19.881162 },
    { lat: 48.393786, lng: 19.881205 },
    { lat: 48.39377, lng: 19.881381 },
    { lat: 48.393766, lng: 19.881421 },
    { lat: 48.393715, lng: 19.882475 },
    { lat: 48.393819, lng: 19.883612 },
    { lat: 48.393238, lng: 19.88421 },
    { lat: 48.393406, lng: 19.885183 },
    { lat: 48.393594, lng: 19.886265 },
    { lat: 48.39369, lng: 19.886258 },
    { lat: 48.395132, lng: 19.885918 },
    { lat: 48.3951781, lng: 19.886491 },
    { lat: 48.3958734, lng: 19.8861786 },
    { lat: 48.3973264, lng: 19.8849725 },
    { lat: 48.3976484, lng: 19.8845719 },
    { lat: 48.3980087, lng: 19.8824258 },
    { lat: 48.3989838, lng: 19.8812916 },
    { lat: 48.39998, lng: 19.8799157 },
    { lat: 48.4009299, lng: 19.8789012 },
    { lat: 48.4011949, lng: 19.8793487 },
    { lat: 48.4020963, lng: 19.8786498 },
    { lat: 48.4025674, lng: 19.8782094 },
    { lat: 48.4035449, lng: 19.8767621 },
    { lat: 48.4055296, lng: 19.8735456 },
    { lat: 48.407406, lng: 19.8759874 },
    { lat: 48.407633, lng: 19.8759465 },
    { lat: 48.4078595, lng: 19.8757894 },
    { lat: 48.408046, lng: 19.875806 },
    { lat: 48.4081244, lng: 19.8756934 },
    { lat: 48.408218, lng: 19.8754325 },
    { lat: 48.4081287, lng: 19.8748243 },
    { lat: 48.4082187, lng: 19.8745084 },
    { lat: 48.4084132, lng: 19.8744739 },
    { lat: 48.4085019, lng: 19.8745723 },
    { lat: 48.408626, lng: 19.8745216 },
    { lat: 48.4087538, lng: 19.8743741 },
    { lat: 48.4088575, lng: 19.8745079 },
    { lat: 48.4089767, lng: 19.8744797 },
    { lat: 48.4091245, lng: 19.8744323 },
    { lat: 48.4093537, lng: 19.8742072 },
    { lat: 48.4104333, lng: 19.8751804 },
    { lat: 48.4110302, lng: 19.8762712 },
    { lat: 48.4114896, lng: 19.8773651 },
    { lat: 48.4115742, lng: 19.8777338 },
    { lat: 48.4126836, lng: 19.8792911 },
    { lat: 48.4135023, lng: 19.8799366 },
    { lat: 48.4150144, lng: 19.8813566 },
    { lat: 48.4156985, lng: 19.8819071 },
    { lat: 48.4170804, lng: 19.8828658 },
    { lat: 48.4177591, lng: 19.8830892 },
    { lat: 48.4191556, lng: 19.8829449 },
    { lat: 48.4205244, lng: 19.8827639 },
    { lat: 48.4207012, lng: 19.8826329 },
    { lat: 48.4216753, lng: 19.8823101 },
    { lat: 48.4218064, lng: 19.8824226 },
    { lat: 48.4228208, lng: 19.8828766 },
    { lat: 48.424252, lng: 19.8838057 },
    { lat: 48.424283, lng: 19.8763713 },
    { lat: 48.4239832, lng: 19.8757152 },
    { lat: 48.4235945, lng: 19.8753714 },
    { lat: 48.4234704, lng: 19.8751054 },
    { lat: 48.4231883, lng: 19.874991 },
    { lat: 48.4232268, lng: 19.8748433 },
    { lat: 48.4231605, lng: 19.8745333 },
    { lat: 48.4230717, lng: 19.8743512 },
    { lat: 48.4230673, lng: 19.8740959 },
    { lat: 48.4229974, lng: 19.8741995 },
    { lat: 48.4228944, lng: 19.8741099 },
    { lat: 48.4227432, lng: 19.8741572 },
    { lat: 48.4226154, lng: 19.8740902 },
    { lat: 48.4224025, lng: 19.8742486 },
    { lat: 48.4223422, lng: 19.8741601 },
    { lat: 48.4222071, lng: 19.8743648 },
    { lat: 48.42188, lng: 19.8740175 },
    { lat: 48.4208731, lng: 19.870426 },
    { lat: 48.4199704, lng: 19.8707817 },
    { lat: 48.4185746, lng: 19.8705486 },
    { lat: 48.4162564, lng: 19.8702716 },
    { lat: 48.4169271, lng: 19.8678525 },
    { lat: 48.4170146, lng: 19.866829 },
    { lat: 48.4169838, lng: 19.8667937 },
    { lat: 48.4170129, lng: 19.8663756 },
    { lat: 48.4148177, lng: 19.8612302 },
    { lat: 48.4153934, lng: 19.8609658 },
  ],
  Selce: [
    { lat: 48.4841303, lng: 19.8430884 },
    { lat: 48.4840233, lng: 19.8434028 },
    { lat: 48.4838578, lng: 19.8440292 },
    { lat: 48.4838386, lng: 19.8445453 },
    { lat: 48.4837044, lng: 19.8453468 },
    { lat: 48.4835055, lng: 19.845878 },
    { lat: 48.4832691, lng: 19.8461991 },
    { lat: 48.4830467, lng: 19.846705 },
    { lat: 48.4830177, lng: 19.8469724 },
    { lat: 48.4829413, lng: 19.8472128 },
    { lat: 48.48285, lng: 19.8473411 },
    { lat: 48.4828477, lng: 19.8478051 },
    { lat: 48.4827756, lng: 19.8482983 },
    { lat: 48.4825547, lng: 19.8489219 },
    { lat: 48.4820889, lng: 19.8494334 },
    { lat: 48.4818677, lng: 19.849291 },
    { lat: 48.481439, lng: 19.8494612 },
    { lat: 48.4812665, lng: 19.8493542 },
    { lat: 48.4810013, lng: 19.8493195 },
    { lat: 48.4804517, lng: 19.848981 },
    { lat: 48.4799409, lng: 19.8488677 },
    { lat: 48.4795727, lng: 19.8486414 },
    { lat: 48.478717, lng: 19.8474842 },
    { lat: 48.4783653, lng: 19.8470866 },
    { lat: 48.4781157, lng: 19.8461864 },
    { lat: 48.4779448, lng: 19.8453663 },
    { lat: 48.4774765, lng: 19.8446765 },
    { lat: 48.4772997, lng: 19.8445447 },
    { lat: 48.4771229, lng: 19.844235 },
    { lat: 48.4765445, lng: 19.8435223 },
    { lat: 48.4762973, lng: 19.8433811 },
    { lat: 48.4762321, lng: 19.8427857 },
    { lat: 48.4762468, lng: 19.8421675 },
    { lat: 48.4761973, lng: 19.8418808 },
    { lat: 48.4757875, lng: 19.8417346 },
    { lat: 48.4754487, lng: 19.8417679 },
    { lat: 48.4751913, lng: 19.8417008 },
    { lat: 48.4747586, lng: 19.841439 },
    { lat: 48.4745941, lng: 19.841441 },
    { lat: 48.4740446, lng: 19.8417317 },
    { lat: 48.4735793, lng: 19.8417877 },
    { lat: 48.4732065, lng: 19.8419166 },
    { lat: 48.4729292, lng: 19.8424302 },
    { lat: 48.4728642, lng: 19.842831 },
    { lat: 48.472312, lng: 19.8440714 },
    { lat: 48.4720637, lng: 19.8442949 },
    { lat: 48.4718024, lng: 19.8444278 },
    { lat: 48.470976, lng: 19.8456325 },
    { lat: 48.4704936, lng: 19.8460181 },
    { lat: 48.4698623, lng: 19.8463758 },
    { lat: 48.4696763, lng: 19.8465293 },
    { lat: 48.4694857, lng: 19.8468114 },
    { lat: 48.4688269, lng: 19.8472324 },
    { lat: 48.4685656, lng: 19.8473361 },
    { lat: 48.4682644, lng: 19.8476166 },
    { lat: 48.4677866, lng: 19.8477916 },
    { lat: 48.4667699, lng: 19.8477826 },
    { lat: 48.46646, lng: 19.8478602 },
    { lat: 48.4664544, lng: 19.8479378 },
    { lat: 48.4662049, lng: 19.8479761 },
    { lat: 48.4658824, lng: 19.8481231 },
    { lat: 48.4655693, lng: 19.8484388 },
    { lat: 48.4652703, lng: 19.8491039 },
    { lat: 48.4648757, lng: 19.8496697 },
    { lat: 48.4646584, lng: 19.8501958 },
    { lat: 48.4644543, lng: 19.8504216 },
    { lat: 48.4639322, lng: 19.850722 },
    { lat: 48.4631003, lng: 19.8513611 },
    { lat: 48.4626917, lng: 19.851788 },
    { lat: 48.4621547, lng: 19.8522361 },
    { lat: 48.4617988, lng: 19.8526312 },
    { lat: 48.4609662, lng: 19.8539312 },
    { lat: 48.4605758, lng: 19.8540461 },
    { lat: 48.459987, lng: 19.8544229 },
    { lat: 48.4596977, lng: 19.8544529 },
    { lat: 48.4595084, lng: 19.8546071 },
    { lat: 48.4592839, lng: 19.8552505 },
    { lat: 48.4593442, lng: 19.8553177 },
    { lat: 48.4593538, lng: 19.8555117 },
    { lat: 48.4592742, lng: 19.8557019 },
    { lat: 48.4589873, lng: 19.8559465 },
    { lat: 48.4590154, lng: 19.8560002 },
    { lat: 48.4587602, lng: 19.8562448 },
    { lat: 48.4587609, lng: 19.8563378 },
    { lat: 48.4587066, lng: 19.8563058 },
    { lat: 48.4586199, lng: 19.8564627 },
    { lat: 48.45838, lng: 19.856467 },
    { lat: 48.4581262, lng: 19.8566135 },
    { lat: 48.4581011, lng: 19.856764 },
    { lat: 48.4579309, lng: 19.8569443 },
    { lat: 48.4574906, lng: 19.8577572 },
    { lat: 48.4572119, lng: 19.8580059 },
    { lat: 48.4570699, lng: 19.8583687 },
    { lat: 48.4569174, lng: 19.8585426 },
    { lat: 48.4566994, lng: 19.8586328 },
    { lat: 48.456648, lng: 19.8587982 },
    { lat: 48.4563739, lng: 19.8588964 },
    { lat: 48.4560888, lng: 19.8594449 },
    { lat: 48.4559198, lng: 19.8595438 },
    { lat: 48.4557741, lng: 19.8597983 },
    { lat: 48.455493, lng: 19.8600188 },
    { lat: 48.4551122, lng: 19.860489 },
    { lat: 48.4548672, lng: 19.8604246 },
    { lat: 48.4547479, lng: 19.8605591 },
    { lat: 48.4547262, lng: 19.8608107 },
    { lat: 48.4543931, lng: 19.8615474 },
    { lat: 48.4540719, lng: 19.8618826 },
    { lat: 48.4540159, lng: 19.8621265 },
    { lat: 48.4539291, lng: 19.8622053 },
    { lat: 48.4535242, lng: 19.8629191 },
    { lat: 48.4533719, lng: 19.8630714 },
    { lat: 48.4533428, lng: 19.8632066 },
    { lat: 48.4530278, lng: 19.8633463 },
    { lat: 48.4529284, lng: 19.8635274 },
    { lat: 48.4528023, lng: 19.8639961 },
    { lat: 48.4524023, lng: 19.8645398 },
    { lat: 48.4524723, lng: 19.8647775 },
    { lat: 48.4522905, lng: 19.8651885 },
    { lat: 48.4518371, lng: 19.865906 },
    { lat: 48.4516201, lng: 19.8663573 },
    { lat: 48.4515513, lng: 19.8667753 },
    { lat: 48.4516526, lng: 19.8668761 },
    { lat: 48.4513464, lng: 19.8670884 },
    { lat: 48.4512207, lng: 19.8672597 },
    { lat: 48.4508566, lng: 19.8673084 },
    { lat: 48.4505087, lng: 19.8672068 },
    { lat: 48.4504165, lng: 19.8676792 },
    { lat: 48.4504502, lng: 19.8676784 },
    { lat: 48.4501176, lng: 19.8683234 },
    { lat: 48.4500701, lng: 19.8686619 },
    { lat: 48.449882, lng: 19.8690508 },
    { lat: 48.4498421, lng: 19.8693506 },
    { lat: 48.4495977, lng: 19.8697698 },
    { lat: 48.4492701, lng: 19.8705598 },
    { lat: 48.4490894, lng: 19.8705522 },
    { lat: 48.4489403, lng: 19.8706473 },
    { lat: 48.4484566, lng: 19.8706086 },
    { lat: 48.4482077, lng: 19.8704331 },
    { lat: 48.4480861, lng: 19.8708477 },
    { lat: 48.4479879, lng: 19.8708431 },
    { lat: 48.4478786, lng: 19.8706884 },
    { lat: 48.4477469, lng: 19.8706359 },
    { lat: 48.4473967, lng: 19.8701726 },
    { lat: 48.4473101, lng: 19.8701753 },
    { lat: 48.4468716, lng: 19.8696478 },
    { lat: 48.4465292, lng: 19.869623 },
    { lat: 48.4464546, lng: 19.8693543 },
    { lat: 48.445919, lng: 19.869657 },
    { lat: 48.4459502, lng: 19.8699648 },
    { lat: 48.4456527, lng: 19.8703022 },
    { lat: 48.44532, lng: 19.8705312 },
    { lat: 48.4447551, lng: 19.8707005 },
    { lat: 48.4446552, lng: 19.8708373 },
    { lat: 48.4444883, lng: 19.8708369 },
    { lat: 48.444185, lng: 19.8710886 },
    { lat: 48.4440188, lng: 19.8710991 },
    { lat: 48.443955, lng: 19.8712416 },
    { lat: 48.4438209, lng: 19.8712128 },
    { lat: 48.4436622, lng: 19.8713752 },
    { lat: 48.4435297, lng: 19.8713863 },
    { lat: 48.4433281, lng: 19.8715171 },
    { lat: 48.4432171, lng: 19.8718437 },
    { lat: 48.4431709, lng: 19.8718684 },
    { lat: 48.443118, lng: 19.8717898 },
    { lat: 48.4430232, lng: 19.8718427 },
    { lat: 48.442437, lng: 19.8725644 },
    { lat: 48.4423002, lng: 19.8728886 },
    { lat: 48.4421828, lng: 19.8728596 },
    { lat: 48.4422044, lng: 19.8729937 },
    { lat: 48.4420498, lng: 19.8732469 },
    { lat: 48.4420854, lng: 19.8733452 },
    { lat: 48.4421786, lng: 19.8733379 },
    { lat: 48.4422879, lng: 19.8735455 },
    { lat: 48.4422208, lng: 19.8737915 },
    { lat: 48.4423001, lng: 19.8740634 },
    { lat: 48.4422642, lng: 19.8743028 },
    { lat: 48.442225, lng: 19.8744411 },
    { lat: 48.442075, lng: 19.8745647 },
    { lat: 48.4418268, lng: 19.8752353 },
    { lat: 48.4417118, lng: 19.8752614 },
    { lat: 48.441596, lng: 19.8756041 },
    { lat: 48.4416781, lng: 19.8757068 },
    { lat: 48.4416799, lng: 19.8758801 },
    { lat: 48.441485, lng: 19.8759814 },
    { lat: 48.4415468, lng: 19.8761109 },
    { lat: 48.4413293, lng: 19.8764986 },
    { lat: 48.44124, lng: 19.8770938 },
    { lat: 48.4410724, lng: 19.8773351 },
    { lat: 48.4410038, lng: 19.8776264 },
    { lat: 48.4411197, lng: 19.8778837 },
    { lat: 48.4410318, lng: 19.8780678 },
    { lat: 48.4410823, lng: 19.8781385 },
    { lat: 48.4410598, lng: 19.8782677 },
    { lat: 48.4409057, lng: 19.8785864 },
    { lat: 48.4409796, lng: 19.8789206 },
    { lat: 48.4409079, lng: 19.8789297 },
    { lat: 48.4408071, lng: 19.8791761 },
    { lat: 48.4407361, lng: 19.879204 },
    { lat: 48.4407865, lng: 19.8793264 },
    { lat: 48.4407132, lng: 19.8795103 },
    { lat: 48.4405731, lng: 19.8795512 },
    { lat: 48.4405099, lng: 19.8799427 },
    { lat: 48.4404533, lng: 19.8799876 },
    { lat: 48.4404502, lng: 19.8803058 },
    { lat: 48.4403504, lng: 19.8806288 },
    { lat: 48.4402307, lng: 19.880705 },
    { lat: 48.4401833, lng: 19.8810963 },
    { lat: 48.4402474, lng: 19.8812743 },
    { lat: 48.4400852, lng: 19.8814813 },
    { lat: 48.4401176, lng: 19.881636 },
    { lat: 48.4402027, lng: 19.8815454 },
    { lat: 48.4402481, lng: 19.8817613 },
    { lat: 48.4400867, lng: 19.8820704 },
    { lat: 48.4402109, lng: 19.8823113 },
    { lat: 48.4402726, lng: 19.8822762 },
    { lat: 48.4401686, lng: 19.8831063 },
    { lat: 48.4403029, lng: 19.8836581 },
    { lat: 48.4403638, lng: 19.8836859 },
    { lat: 48.4404645, lng: 19.8839844 },
    { lat: 48.4404802, lng: 19.8842184 },
    { lat: 48.4406728, lng: 19.8848213 },
    { lat: 48.4408158, lng: 19.8851587 },
    { lat: 48.4408616, lng: 19.8851475 },
    { lat: 48.4409156, lng: 19.8853824 },
    { lat: 48.4409627, lng: 19.8854066 },
    { lat: 48.4409591, lng: 19.8855808 },
    { lat: 48.4410432, lng: 19.8858086 },
    { lat: 48.4410203, lng: 19.8859055 },
    { lat: 48.4411787, lng: 19.8859714 },
    { lat: 48.441251, lng: 19.8861882 },
    { lat: 48.4412669, lng: 19.8862438 },
    { lat: 48.4412812, lng: 19.886549 },
    { lat: 48.4414256, lng: 19.8868153 },
    { lat: 48.4414436, lng: 19.8869703 },
    { lat: 48.4413827, lng: 19.8870763 },
    { lat: 48.4413906, lng: 19.8872586 },
    { lat: 48.4414896, lng: 19.8873114 },
    { lat: 48.4415209, lng: 19.887512 },
    { lat: 48.4416336, lng: 19.8877225 },
    { lat: 48.4416918, lng: 19.8884705 },
    { lat: 48.4417205, lng: 19.8884588 },
    { lat: 48.441719, lng: 19.8886864 },
    { lat: 48.441653, lng: 19.8888815 },
    { lat: 48.4417528, lng: 19.8890269 },
    { lat: 48.441673, lng: 19.8892919 },
    { lat: 48.4417686, lng: 19.8895271 },
    { lat: 48.4417906, lng: 19.8898794 },
    { lat: 48.4416842, lng: 19.8901285 },
    { lat: 48.4417722, lng: 19.890291 },
    { lat: 48.4418016, lng: 19.8906064 },
    { lat: 48.4417719, lng: 19.8911248 },
    { lat: 48.4418299, lng: 19.8912111 },
    { lat: 48.4417267, lng: 19.8914833 },
    { lat: 48.441463, lng: 19.8918229 },
    { lat: 48.4415463, lng: 19.892033 },
    { lat: 48.4415447, lng: 19.8924394 },
    { lat: 48.4414767, lng: 19.8927199 },
    { lat: 48.4415064, lng: 19.893382 },
    { lat: 48.4415844, lng: 19.8935713 },
    { lat: 48.4417385, lng: 19.8936482 },
    { lat: 48.4418003, lng: 19.8940091 },
    { lat: 48.4418877, lng: 19.8941585 },
    { lat: 48.4420389, lng: 19.8942155 },
    { lat: 48.442208, lng: 19.8941626 },
    { lat: 48.4426074, lng: 19.8945205 },
    { lat: 48.4431538, lng: 19.8952544 },
    { lat: 48.4434061, lng: 19.8959055 },
    { lat: 48.4436716, lng: 19.8962669 },
    { lat: 48.4436856, lng: 19.8964121 },
    { lat: 48.4438443, lng: 19.896694 },
    { lat: 48.4446077, lng: 19.8972643 },
    { lat: 48.4447924, lng: 19.8975516 },
    { lat: 48.4448982, lng: 19.8976016 },
    { lat: 48.4449908, lng: 19.8977367 },
    { lat: 48.4450987, lng: 19.8976546 },
    { lat: 48.4453718, lng: 19.8979765 },
    { lat: 48.4454016, lng: 19.8983022 },
    { lat: 48.44565, lng: 19.898536 },
    { lat: 48.445678, lng: 19.898569 },
    { lat: 48.445745, lng: 19.898642 },
    { lat: 48.445817, lng: 19.898711 },
    { lat: 48.445941, lng: 19.898827 },
    { lat: 48.446089, lng: 19.898855 },
    { lat: 48.446463, lng: 19.898768 },
    { lat: 48.446513, lng: 19.898786 },
    { lat: 48.446938, lng: 19.899018 },
    { lat: 48.447123, lng: 19.898976 },
    { lat: 48.447426, lng: 19.898832 },
    { lat: 48.447497, lng: 19.8988 },
    { lat: 48.447514, lng: 19.898786 },
    { lat: 48.447755, lng: 19.898648 },
    { lat: 48.448119, lng: 19.898497 },
    { lat: 48.448439, lng: 19.898251 },
    { lat: 48.448698, lng: 19.898052 },
    { lat: 48.448758, lng: 19.89803 },
    { lat: 48.44881, lng: 19.897969 },
    { lat: 48.448967, lng: 19.897856 },
    { lat: 48.449023, lng: 19.897829 },
    { lat: 48.449107, lng: 19.897723 },
    { lat: 48.449122, lng: 19.897718 },
    { lat: 48.449188, lng: 19.89768 },
    { lat: 48.449259, lng: 19.897601 },
    { lat: 48.449375, lng: 19.897516 },
    { lat: 48.449496, lng: 19.897446 },
    { lat: 48.449905, lng: 19.897211 },
    { lat: 48.450064, lng: 19.897111 },
    { lat: 48.450381, lng: 19.89691 },
    { lat: 48.450438, lng: 19.896875 },
    { lat: 48.450483, lng: 19.896846 },
    { lat: 48.450578, lng: 19.89678 },
    { lat: 48.450987, lng: 19.896464 },
    { lat: 48.451467, lng: 19.895918 },
    { lat: 48.451828, lng: 19.895553 },
    { lat: 48.452593, lng: 19.894753 },
    { lat: 48.452714, lng: 19.894656 },
    { lat: 48.452879, lng: 19.894439 },
    { lat: 48.453135, lng: 19.894252 },
    { lat: 48.453282, lng: 19.89411 },
    { lat: 48.453751, lng: 19.893648 },
    { lat: 48.454266, lng: 19.893104 },
    { lat: 48.454542, lng: 19.892387 },
    { lat: 48.454819, lng: 19.891687 },
    { lat: 48.455021, lng: 19.891293 },
    { lat: 48.455387, lng: 19.890505 },
    { lat: 48.455488, lng: 19.890287 },
    { lat: 48.455852, lng: 19.890401 },
    { lat: 48.455925, lng: 19.890448 },
    { lat: 48.456127, lng: 19.890677 },
    { lat: 48.456188, lng: 19.890739 },
    { lat: 48.45661, lng: 19.89109 },
    { lat: 48.456754, lng: 19.891195 },
    { lat: 48.456808, lng: 19.89146 },
    { lat: 48.456889, lng: 19.891391 },
    { lat: 48.456932, lng: 19.891238 },
    { lat: 48.457025, lng: 19.89106 },
    { lat: 48.457184, lng: 19.891017 },
    { lat: 48.45725, lng: 19.891029 },
    { lat: 48.457512, lng: 19.891176 },
    { lat: 48.457682, lng: 19.891274 },
    { lat: 48.457783, lng: 19.89133 },
    { lat: 48.457834, lng: 19.891377 },
    { lat: 48.457881, lng: 19.891398 },
    { lat: 48.457972, lng: 19.891437 },
    { lat: 48.458028, lng: 19.891486 },
    { lat: 48.458095, lng: 19.891506 },
    { lat: 48.45822, lng: 19.891666 },
    { lat: 48.458333, lng: 19.891808 },
    { lat: 48.458438, lng: 19.891902 },
    { lat: 48.458513, lng: 19.89191 },
    { lat: 48.458571, lng: 19.89192 },
    { lat: 48.458802, lng: 19.892026 },
    { lat: 48.458875, lng: 19.892023 },
    { lat: 48.458961, lng: 19.892065 },
    { lat: 48.459031, lng: 19.892131 },
    { lat: 48.459096, lng: 19.892213 },
    { lat: 48.459197, lng: 19.892431 },
    { lat: 48.459261, lng: 19.892597 },
    { lat: 48.459344, lng: 19.892641 },
    { lat: 48.459408, lng: 19.892686 },
    { lat: 48.459465, lng: 19.892733 },
    { lat: 48.459633, lng: 19.892896 },
    { lat: 48.459703, lng: 19.892973 },
    { lat: 48.45974, lng: 19.893015 },
    { lat: 48.459773, lng: 19.89303 },
    { lat: 48.459843, lng: 19.893063 },
    { lat: 48.459936, lng: 19.893106 },
    { lat: 48.460161, lng: 19.893154 },
    { lat: 48.460242, lng: 19.893071 },
    { lat: 48.460296, lng: 19.89327 },
    { lat: 48.460515, lng: 19.893476 },
    { lat: 48.460636, lng: 19.893583 },
    { lat: 48.46071, lng: 19.893674 },
    { lat: 48.460806, lng: 19.893811 },
    { lat: 48.460927, lng: 19.893978 },
    { lat: 48.461022, lng: 19.894081 },
    { lat: 48.46112, lng: 19.894251 },
    { lat: 48.461158, lng: 19.894336 },
    { lat: 48.461285, lng: 19.894632 },
    { lat: 48.461315, lng: 19.894671 },
    { lat: 48.461403, lng: 19.894788 },
    { lat: 48.46143, lng: 19.894829 },
    { lat: 48.461584, lng: 19.895205 },
    { lat: 48.461636, lng: 19.895194 },
    { lat: 48.461715, lng: 19.895174 },
    { lat: 48.46178, lng: 19.895069 },
    { lat: 48.461865, lng: 19.895121 },
    { lat: 48.461926, lng: 19.895284 },
    { lat: 48.462026, lng: 19.895496 },
    { lat: 48.462208, lng: 19.895714 },
    { lat: 48.462407, lng: 19.89566 },
    { lat: 48.462569, lng: 19.895785 },
    { lat: 48.462779, lng: 19.895945 },
    { lat: 48.462915, lng: 19.896064 },
    { lat: 48.463118, lng: 19.896185 },
    { lat: 48.463187, lng: 19.895975 },
    { lat: 48.463362, lng: 19.896039 },
    { lat: 48.46343, lng: 19.896156 },
    { lat: 48.463514, lng: 19.896282 },
    { lat: 48.463783, lng: 19.896321 },
    { lat: 48.463814, lng: 19.8965 },
    { lat: 48.463825, lng: 19.896518 },
    { lat: 48.464095, lng: 19.896883 },
    { lat: 48.46441, lng: 19.89715 },
    { lat: 48.464398, lng: 19.89739 },
    { lat: 48.464428, lng: 19.897433 },
    { lat: 48.464476, lng: 19.897501 },
    { lat: 48.464513, lng: 19.897554 },
    { lat: 48.464652, lng: 19.897576 },
    { lat: 48.464746, lng: 19.89754 },
    { lat: 48.464877, lng: 19.897673 },
    { lat: 48.465003, lng: 19.897586 },
    { lat: 48.465172, lng: 19.897777 },
    { lat: 48.465206, lng: 19.898057 },
    { lat: 48.465301, lng: 19.898295 },
    { lat: 48.465317, lng: 19.898564 },
    { lat: 48.465417, lng: 19.898721 },
    { lat: 48.465448, lng: 19.898885 },
    { lat: 48.465449, lng: 19.898898 },
    { lat: 48.465551, lng: 19.89893 },
    { lat: 48.465658, lng: 19.898962 },
    { lat: 48.465769, lng: 19.899026 },
    { lat: 48.465777, lng: 19.899081 },
    { lat: 48.465792, lng: 19.899185 },
    { lat: 48.465803, lng: 19.899254 },
    { lat: 48.465819, lng: 19.899325 },
    { lat: 48.465911, lng: 19.899317 },
    { lat: 48.465995, lng: 19.89931 },
    { lat: 48.466122, lng: 19.899299 },
    { lat: 48.466198, lng: 19.899492 },
    { lat: 48.466255, lng: 19.899542 },
    { lat: 48.466301, lng: 19.899587 },
    { lat: 48.466321, lng: 19.899663 },
    { lat: 48.466359, lng: 19.899796 },
    { lat: 48.466403, lng: 19.899936 },
    { lat: 48.466421, lng: 19.899993 },
    { lat: 48.466456, lng: 19.90007 },
    { lat: 48.466485, lng: 19.900137 },
    { lat: 48.466531, lng: 19.900198 },
    { lat: 48.46654, lng: 19.900311 },
    { lat: 48.466548, lng: 19.900446 },
    { lat: 48.466598, lng: 19.900537 },
    { lat: 48.466777, lng: 19.900667 },
    { lat: 48.466864, lng: 19.900541 },
    { lat: 48.467012, lng: 19.90056 },
    { lat: 48.467026, lng: 19.900566 },
    { lat: 48.467065, lng: 19.900581 },
    { lat: 48.467148, lng: 19.900615 },
    { lat: 48.467356, lng: 19.900736 },
    { lat: 48.4675, lng: 19.900765 },
    { lat: 48.467556, lng: 19.900731 },
    { lat: 48.46757, lng: 19.90074 },
    { lat: 48.467627, lng: 19.900774 },
    { lat: 48.467691, lng: 19.900813 },
    { lat: 48.467737, lng: 19.90086 },
    { lat: 48.467882, lng: 19.901006 },
    { lat: 48.467898, lng: 19.901036 },
    { lat: 48.467942, lng: 19.901125 },
    { lat: 48.467995, lng: 19.901206 },
    { lat: 48.468074, lng: 19.901226 },
    { lat: 48.468157, lng: 19.901251 },
    { lat: 48.468177, lng: 19.901413 },
    { lat: 48.468205, lng: 19.90153 },
    { lat: 48.468284, lng: 19.901553 },
    { lat: 48.468451, lng: 19.901603 },
    { lat: 48.468514, lng: 19.901669 },
    { lat: 48.468512, lng: 19.901771 },
    { lat: 48.468476, lng: 19.901851 },
    { lat: 48.468487, lng: 19.901927 },
    { lat: 48.468508, lng: 19.901971 },
    { lat: 48.468525, lng: 19.901995 },
    { lat: 48.468556, lng: 19.902034 },
    { lat: 48.468614, lng: 19.901926 },
    { lat: 48.468708, lng: 19.902024 },
    { lat: 48.468756, lng: 19.902031 },
    { lat: 48.468766, lng: 19.902084 },
    { lat: 48.468715, lng: 19.902126 },
    { lat: 48.468684, lng: 19.902161 },
    { lat: 48.468618, lng: 19.902239 },
    { lat: 48.468665, lng: 19.902291 },
    { lat: 48.468705, lng: 19.902332 },
    { lat: 48.468738, lng: 19.902418 },
    { lat: 48.468762, lng: 19.902526 },
    { lat: 48.468815, lng: 19.902463 },
    { lat: 48.468909, lng: 19.90224 },
    { lat: 48.47133, lng: 19.896461 },
    { lat: 48.471624, lng: 19.895761 },
    { lat: 48.471654, lng: 19.895685 },
    { lat: 48.471694, lng: 19.895715 },
    { lat: 48.471743, lng: 19.8957 },
    { lat: 48.471851, lng: 19.895698 },
    { lat: 48.472006, lng: 19.895802 },
    { lat: 48.472039, lng: 19.896001 },
    { lat: 48.472207, lng: 19.896187 },
    { lat: 48.472232, lng: 19.89635 },
    { lat: 48.472216, lng: 19.896485 },
    { lat: 48.472268, lng: 19.896618 },
    { lat: 48.472318, lng: 19.896744 },
    { lat: 48.472342, lng: 19.896868 },
    { lat: 48.472471, lng: 19.896915 },
    { lat: 48.472584, lng: 19.896897 },
    { lat: 48.472611, lng: 19.89693 },
    { lat: 48.472665, lng: 19.897098 },
    { lat: 48.472651, lng: 19.897313 },
    { lat: 48.472798, lng: 19.897371 },
    { lat: 48.472892, lng: 19.897486 },
    { lat: 48.472964, lng: 19.897504 },
    { lat: 48.473091, lng: 19.897582 },
    { lat: 48.473148, lng: 19.897759 },
    { lat: 48.473208, lng: 19.897749 },
    { lat: 48.473404, lng: 19.897818 },
    { lat: 48.473482, lng: 19.897986 },
    { lat: 48.473459, lng: 19.898054 },
    { lat: 48.473381, lng: 19.89807 },
    { lat: 48.473399, lng: 19.898149 },
    { lat: 48.473489, lng: 19.898347 },
    { lat: 48.473563, lng: 19.898422 },
    { lat: 48.473591, lng: 19.898501 },
    { lat: 48.473732, lng: 19.898762 },
    { lat: 48.473815, lng: 19.898756 },
    { lat: 48.473899, lng: 19.898788 },
    { lat: 48.473965, lng: 19.898711 },
    { lat: 48.474104, lng: 19.898888 },
    { lat: 48.474175, lng: 19.89893 },
    { lat: 48.474239, lng: 19.898983 },
    { lat: 48.474348, lng: 19.898969 },
    { lat: 48.474381, lng: 19.898993 },
    { lat: 48.47447, lng: 19.898953 },
    { lat: 48.474482, lng: 19.898994 },
    { lat: 48.474508, lng: 19.89909 },
    { lat: 48.474537, lng: 19.899232 },
    { lat: 48.474608, lng: 19.899176 },
    { lat: 48.474724, lng: 19.899258 },
    { lat: 48.474806, lng: 19.89921 },
    { lat: 48.477258, lng: 19.897687 },
    { lat: 48.479898, lng: 19.896048 },
    { lat: 48.480552, lng: 19.895671 },
    { lat: 48.480595, lng: 19.895644 },
    { lat: 48.480631, lng: 19.895621 },
    { lat: 48.481313, lng: 19.895189 },
    { lat: 48.481362, lng: 19.895144 },
    { lat: 48.481381, lng: 19.895116 },
    { lat: 48.481497, lng: 19.894873 },
    { lat: 48.481658, lng: 19.894528 },
    { lat: 48.481772, lng: 19.894277 },
    { lat: 48.481876, lng: 19.894048 },
    { lat: 48.482054, lng: 19.893653 },
    { lat: 48.482221, lng: 19.893289 },
    { lat: 48.482327, lng: 19.893056 },
    { lat: 48.482406, lng: 19.892875 },
    { lat: 48.482598, lng: 19.892501 },
    { lat: 48.482767, lng: 19.892169 },
    { lat: 48.482875, lng: 19.891952 },
    { lat: 48.482933, lng: 19.891802 },
    { lat: 48.483085, lng: 19.891404 },
    { lat: 48.483197, lng: 19.891103 },
    { lat: 48.483294, lng: 19.890848 },
    { lat: 48.483456, lng: 19.890426 },
    { lat: 48.483604, lng: 19.890041 },
    { lat: 48.483654, lng: 19.889914 },
    { lat: 48.483691, lng: 19.88981 },
    { lat: 48.483706, lng: 19.889652 },
    { lat: 48.483721, lng: 19.889497 },
    { lat: 48.483775, lng: 19.889247 },
    { lat: 48.483845, lng: 19.888909 },
    { lat: 48.483899, lng: 19.888641 },
    { lat: 48.483978, lng: 19.888231 },
    { lat: 48.484054, lng: 19.887844 },
    { lat: 48.484097, lng: 19.887623 },
    { lat: 48.484116, lng: 19.887433 },
    { lat: 48.484164, lng: 19.886894 },
    { lat: 48.484192, lng: 19.886577 },
    { lat: 48.484231, lng: 19.886166 },
    { lat: 48.484242, lng: 19.886038 },
    { lat: 48.484229, lng: 19.885748 },
    { lat: 48.484212, lng: 19.885293 },
    { lat: 48.484289, lng: 19.885217 },
    { lat: 48.484444, lng: 19.885074 },
    { lat: 48.484537, lng: 19.884988 },
    { lat: 48.484604, lng: 19.88487 },
    { lat: 48.484738, lng: 19.884648 },
    { lat: 48.484809, lng: 19.884529 },
    { lat: 48.484886, lng: 19.884482 },
    { lat: 48.485058, lng: 19.884372 },
    { lat: 48.485134, lng: 19.884325 },
    { lat: 48.485249, lng: 19.884253 },
    { lat: 48.485377, lng: 19.884318 },
    { lat: 48.485564, lng: 19.884412 },
    { lat: 48.485641, lng: 19.884451 },
    { lat: 48.485778, lng: 19.884587 },
    { lat: 48.485868, lng: 19.884688 },
    { lat: 48.486031, lng: 19.884861 },
    { lat: 48.486262, lng: 19.884889 },
    { lat: 48.486449, lng: 19.884909 },
    { lat: 48.486533, lng: 19.884924 },
    { lat: 48.486612, lng: 19.884854 },
    { lat: 48.486739, lng: 19.884738 },
    { lat: 48.48685, lng: 19.884493 },
    { lat: 48.486936, lng: 19.884308 },
    { lat: 48.486931, lng: 19.884113 },
    { lat: 48.486926, lng: 19.883692 },
    { lat: 48.486922, lng: 19.883314 },
    { lat: 48.486977, lng: 19.883137 },
    { lat: 48.487072, lng: 19.882813 },
    { lat: 48.487134, lng: 19.88259 },
    { lat: 48.487181, lng: 19.882436 },
    { lat: 48.487314, lng: 19.882525 },
    { lat: 48.487479, lng: 19.882635 },
    { lat: 48.487572, lng: 19.882703 },
    { lat: 48.48772, lng: 19.882811 },
    { lat: 48.48779, lng: 19.882858 },
    { lat: 48.48802, lng: 19.882957 },
    { lat: 48.488175, lng: 19.883022 },
    { lat: 48.488379, lng: 19.883029 },
    { lat: 48.488529, lng: 19.883031 },
    { lat: 48.488627, lng: 19.883025 },
    { lat: 48.488737, lng: 19.883021 },
    { lat: 48.488779, lng: 19.883025 },
    { lat: 48.488844, lng: 19.88304 },
    { lat: 48.489209, lng: 19.883112 },
    { lat: 48.489344, lng: 19.88314 },
    { lat: 48.489717, lng: 19.88313 },
    { lat: 48.490017, lng: 19.883122 },
    { lat: 48.49036, lng: 19.883111 },
    { lat: 48.490488, lng: 19.883106 },
    { lat: 48.490621, lng: 19.883112 },
    { lat: 48.490854, lng: 19.883125 },
    { lat: 48.491014, lng: 19.883133 },
    { lat: 48.491117, lng: 19.883185 },
    { lat: 48.491252, lng: 19.883255 },
    { lat: 48.491414, lng: 19.883273 },
    { lat: 48.491536, lng: 19.883283 },
    { lat: 48.491666, lng: 19.883208 },
    { lat: 48.492058, lng: 19.882977 },
    { lat: 48.492331, lng: 19.882848 },
    { lat: 48.492625, lng: 19.882471 },
    { lat: 48.492999, lng: 19.881996 },
    { lat: 48.493053, lng: 19.881873 },
    { lat: 48.493087, lng: 19.881768 },
    { lat: 48.493157, lng: 19.881461 },
    { lat: 48.493197, lng: 19.881355 },
    { lat: 48.493385, lng: 19.880969 },
    { lat: 48.493459, lng: 19.880869 },
    { lat: 48.49356, lng: 19.88081 },
    { lat: 48.493935, lng: 19.880591 },
    { lat: 48.494449, lng: 19.880367 },
    { lat: 48.494732, lng: 19.879966 },
    { lat: 48.494887, lng: 19.879697 },
    { lat: 48.495094, lng: 19.879129 },
    { lat: 48.495219, lng: 19.878857 },
    { lat: 48.495464, lng: 19.87847 },
    { lat: 48.495577, lng: 19.87821 },
    { lat: 48.495682, lng: 19.87802 },
    { lat: 48.496123, lng: 19.877488 },
    { lat: 48.496281, lng: 19.877068 },
    { lat: 48.496677, lng: 19.876604 },
    { lat: 48.497303, lng: 19.875808 },
    { lat: 48.497411, lng: 19.875507 },
    { lat: 48.497596, lng: 19.874777 },
    { lat: 48.497661, lng: 19.874415 },
    { lat: 48.497682, lng: 19.874139 },
    { lat: 48.497817, lng: 19.873171 },
    { lat: 48.497865, lng: 19.872706 },
    { lat: 48.497864, lng: 19.872388 },
    { lat: 48.497918, lng: 19.872082 },
    { lat: 48.497955, lng: 19.871939 },
    { lat: 48.498025, lng: 19.871751 },
    { lat: 48.498046, lng: 19.871601 },
    { lat: 48.498059, lng: 19.871396 },
    { lat: 48.498129, lng: 19.871001 },
    { lat: 48.498202, lng: 19.870838 },
    { lat: 48.498303, lng: 19.870665 },
    { lat: 48.498403, lng: 19.870511 },
    { lat: 48.49873, lng: 19.869847 },
    { lat: 48.498842, lng: 19.869388 },
    { lat: 48.499082, lng: 19.868759 },
    { lat: 48.499117, lng: 19.868571 },
    { lat: 48.499199, lng: 19.868324 },
    { lat: 48.49933, lng: 19.868071 },
    { lat: 48.499471, lng: 19.867912 },
    { lat: 48.499542, lng: 19.867811 },
    { lat: 48.499673, lng: 19.867572 },
    { lat: 48.49979, lng: 19.867318 },
    { lat: 48.500031, lng: 19.866857 },
    { lat: 48.50021, lng: 19.866644 },
    { lat: 48.500426, lng: 19.866298 },
    { lat: 48.500516, lng: 19.866174 },
    { lat: 48.500974, lng: 19.865786 },
    { lat: 48.501306, lng: 19.865377 },
    { lat: 48.501425, lng: 19.865252 },
    { lat: 48.501762, lng: 19.865003 },
    { lat: 48.502028, lng: 19.864683 },
    { lat: 48.50231, lng: 19.86446 },
    { lat: 48.502736, lng: 19.863984 },
    { lat: 48.50287, lng: 19.863773 },
    { lat: 48.502906, lng: 19.863702 },
    { lat: 48.503135, lng: 19.863093 },
    { lat: 48.503272, lng: 19.862658 },
    { lat: 48.503348, lng: 19.862351 },
    { lat: 48.503427, lng: 19.862122 },
    { lat: 48.5035, lng: 19.86192 },
    { lat: 48.50361, lng: 19.861453 },
    { lat: 48.503845, lng: 19.860886 },
    { lat: 48.504018, lng: 19.860433 },
    { lat: 48.504071, lng: 19.860349 },
    { lat: 48.504305, lng: 19.859949 },
    { lat: 48.504378, lng: 19.85979 },
    { lat: 48.504461, lng: 19.859651 },
    { lat: 48.504589, lng: 19.859554 },
    { lat: 48.504813, lng: 19.859369 },
    { lat: 48.505141, lng: 19.859222 },
    { lat: 48.505383, lng: 19.859089 },
    { lat: 48.50568, lng: 19.859013 },
    { lat: 48.506339, lng: 19.858956 },
    { lat: 48.507068, lng: 19.858663 },
    { lat: 48.508174, lng: 19.857672 },
    { lat: 48.508671, lng: 19.857064 },
    { lat: 48.509132, lng: 19.856475 },
    { lat: 48.50958, lng: 19.855918 },
    { lat: 48.510332, lng: 19.855149 },
    { lat: 48.510514, lng: 19.85489 },
    { lat: 48.510665, lng: 19.854715 },
    { lat: 48.510922, lng: 19.854266 },
    { lat: 48.511018, lng: 19.854155 },
    { lat: 48.51122, lng: 19.854 },
    { lat: 48.511411, lng: 19.853936 },
    { lat: 48.511528, lng: 19.853847 },
    { lat: 48.511592, lng: 19.85377 },
    { lat: 48.511744, lng: 19.853531 },
    { lat: 48.511871, lng: 19.85337 },
    { lat: 48.512071, lng: 19.853159 },
    { lat: 48.512459, lng: 19.852548 },
    { lat: 48.512513, lng: 19.852417 },
    { lat: 48.51264, lng: 19.851999 },
    { lat: 48.512731, lng: 19.851787 },
    { lat: 48.512862, lng: 19.851573 },
    { lat: 48.513109, lng: 19.851134 },
    { lat: 48.51325, lng: 19.850955 },
    { lat: 48.513373, lng: 19.850871 },
    { lat: 48.51357, lng: 19.850808 },
    { lat: 48.513797, lng: 19.850649 },
    { lat: 48.514073, lng: 19.850493 },
    { lat: 48.514378, lng: 19.850282 },
    { lat: 48.514496, lng: 19.850253 },
    { lat: 48.514651, lng: 19.850238 },
    { lat: 48.514832, lng: 19.850154 },
    { lat: 48.515072, lng: 19.850003 },
    { lat: 48.51521, lng: 19.849836 },
    { lat: 48.515365, lng: 19.849571 },
    { lat: 48.515492, lng: 19.849401 },
    { lat: 48.515537, lng: 19.849266 },
    { lat: 48.515634, lng: 19.84884 },
    { lat: 48.515686, lng: 19.848441 },
    { lat: 48.515721, lng: 19.848328 },
    { lat: 48.515858, lng: 19.848108 },
    { lat: 48.515961, lng: 19.847861 },
    { lat: 48.5162, lng: 19.84758 },
    { lat: 48.516328, lng: 19.847357 },
    { lat: 48.516459, lng: 19.847135 },
    { lat: 48.516831, lng: 19.846662 },
    { lat: 48.516908, lng: 19.846525 },
    { lat: 48.517037, lng: 19.846247 },
    { lat: 48.517157, lng: 19.846073 },
    { lat: 48.517346, lng: 19.845906 },
    { lat: 48.517476, lng: 19.845752 },
    { lat: 48.517765, lng: 19.845545 },
    { lat: 48.51837, lng: 19.845039 },
    { lat: 48.518489, lng: 19.844864 },
    { lat: 48.518709, lng: 19.844623 },
    { lat: 48.51901, lng: 19.844546 },
    { lat: 48.519423, lng: 19.844348 },
    { lat: 48.519878, lng: 19.844049 },
    { lat: 48.520487, lng: 19.843551 },
    { lat: 48.520632, lng: 19.843369 },
    { lat: 48.520892, lng: 19.84299 },
    { lat: 48.521155, lng: 19.842442 },
    { lat: 48.521456, lng: 19.842019 },
    { lat: 48.522448, lng: 19.841167 },
    { lat: 48.522835, lng: 19.841107 },
    { lat: 48.523705, lng: 19.840807 },
    { lat: 48.524769, lng: 19.840645 },
    { lat: 48.524739, lng: 19.838945 },
    { lat: 48.52472, lng: 19.838471 },
    { lat: 48.524747, lng: 19.838258 },
    { lat: 48.524778, lng: 19.837243 },
    { lat: 48.524749, lng: 19.835857 },
    { lat: 48.524432, lng: 19.835065 },
    { lat: 48.524279, lng: 19.835027 },
    { lat: 48.523634, lng: 19.834819 },
    { lat: 48.523407, lng: 19.834645 },
    { lat: 48.522382, lng: 19.834293 },
    { lat: 48.522318, lng: 19.83426 },
    { lat: 48.522168, lng: 19.834098 },
    { lat: 48.521835, lng: 19.833848 },
    { lat: 48.521654, lng: 19.833711 },
    { lat: 48.521252, lng: 19.833509 },
    { lat: 48.521142, lng: 19.833363 },
    { lat: 48.5210053, lng: 19.8331955 },
    { lat: 48.5206943, lng: 19.8330363 },
    { lat: 48.5205324, lng: 19.8328351 },
    { lat: 48.5203661, lng: 19.832431 },
    { lat: 48.5200912, lng: 19.8320714 },
    { lat: 48.5189859, lng: 19.8312609 },
    { lat: 48.518177, lng: 19.8323511 },
    { lat: 48.5175327, lng: 19.8328726 },
    { lat: 48.517095, lng: 19.8330681 },
    { lat: 48.5169763, lng: 19.8330865 },
    { lat: 48.5166924, lng: 19.8328771 },
    { lat: 48.5164534, lng: 19.832928 },
    { lat: 48.5160327, lng: 19.8328912 },
    { lat: 48.5156188, lng: 19.8331661 },
    { lat: 48.5154031, lng: 19.8331802 },
    { lat: 48.5149102, lng: 19.8330389 },
    { lat: 48.5147061, lng: 19.8328995 },
    { lat: 48.5136143, lng: 19.8328747 },
    { lat: 48.5134386, lng: 19.8327225 },
    { lat: 48.5132712, lng: 19.8323421 },
    { lat: 48.5131947, lng: 19.8320309 },
    { lat: 48.513209, lng: 19.8314801 },
    { lat: 48.5134931, lng: 19.8308079 },
    { lat: 48.5134234, lng: 19.8302957 },
    { lat: 48.5131394, lng: 19.8300355 },
    { lat: 48.5128951, lng: 19.8299611 },
    { lat: 48.5125385, lng: 19.8296877 },
    { lat: 48.5122308, lng: 19.8292941 },
    { lat: 48.5121217, lng: 19.8292235 },
    { lat: 48.5111245, lng: 19.8293487 },
    { lat: 48.5106259, lng: 19.8296454 },
    { lat: 48.5104468, lng: 19.8296698 },
    { lat: 48.510295, lng: 19.8295998 },
    { lat: 48.5100819, lng: 19.8297548 },
    { lat: 48.5099162, lng: 19.829775 },
    { lat: 48.5096745, lng: 19.8297124 },
    { lat: 48.5090638, lng: 19.8297345 },
    { lat: 48.5089032, lng: 19.8295922 },
    { lat: 48.5087952, lng: 19.8295954 },
    { lat: 48.5084682, lng: 19.8298504 },
    { lat: 48.5081354, lng: 19.8305351 },
    { lat: 48.5078888, lng: 19.8307512 },
    { lat: 48.5066755, lng: 19.8305956 },
    { lat: 48.5063515, lng: 19.8306812 },
    { lat: 48.5061401, lng: 19.8306686 },
    { lat: 48.505804, lng: 19.8305553 },
    { lat: 48.5044633, lng: 19.8295415 },
    { lat: 48.5040912, lng: 19.8294577 },
    { lat: 48.5028765, lng: 19.8296582 },
    { lat: 48.5023646, lng: 19.830481 },
    { lat: 48.5022979, lng: 19.8306595 },
    { lat: 48.5019764, lng: 19.8308272 },
    { lat: 48.5010384, lng: 19.8301926 },
    { lat: 48.5007021, lng: 19.829841 },
    { lat: 48.5002469, lng: 19.8304879 },
    { lat: 48.4995505, lng: 19.8309003 },
    { lat: 48.4990254, lng: 19.8311332 },
    { lat: 48.4985621, lng: 19.8311788 },
    { lat: 48.4980558, lng: 19.831063 },
    { lat: 48.4973068, lng: 19.8311463 },
    { lat: 48.4970769, lng: 19.8314023 },
    { lat: 48.4968842, lng: 19.8318948 },
    { lat: 48.4967528, lng: 19.8321095 },
    { lat: 48.4964026, lng: 19.8324775 },
    { lat: 48.4962004, lng: 19.832778 },
    { lat: 48.4959952, lng: 19.8329029 },
    { lat: 48.4955711, lng: 19.8334107 },
    { lat: 48.4954293, lng: 19.8335152 },
    { lat: 48.4953146, lng: 19.8335229 },
    { lat: 48.4951255, lng: 19.8334247 },
    { lat: 48.4944286, lng: 19.8335664 },
    { lat: 48.493992, lng: 19.8339922 },
    { lat: 48.4938094, lng: 19.8340656 },
    { lat: 48.4934733, lng: 19.8344499 },
    { lat: 48.4927024, lng: 19.8355273 },
    { lat: 48.4925204, lng: 19.8356418 },
    { lat: 48.4922213, lng: 19.8360229 },
    { lat: 48.4915818, lng: 19.8362633 },
    { lat: 48.491131, lng: 19.8367004 },
    { lat: 48.4909424, lng: 19.8369242 },
    { lat: 48.4906723, lng: 19.8377073 },
    { lat: 48.4901476, lng: 19.838341 },
    { lat: 48.4895404, lng: 19.8394277 },
    { lat: 48.4892503, lng: 19.8396516 },
    { lat: 48.4888465, lng: 19.8398255 },
    { lat: 48.4883412, lng: 19.8399145 },
    { lat: 48.4879453, lng: 19.8397737 },
    { lat: 48.4874106, lng: 19.8397846 },
    { lat: 48.4869152, lng: 19.8399462 },
    { lat: 48.4863184, lng: 19.8399523 },
    { lat: 48.4861829, lng: 19.8399965 },
    { lat: 48.4860759, lng: 19.8401295 },
    { lat: 48.4858436, lng: 19.840202 },
    { lat: 48.4855296, lng: 19.8404935 },
    { lat: 48.4849151, lng: 19.8414971 },
    { lat: 48.4846212, lng: 19.8422266 },
    { lat: 48.4844364, lng: 19.842434 },
    { lat: 48.4842175, lng: 19.8429164 },
    { lat: 48.4841303, lng: 19.8430884 },
  ],
  Krná: [
    { lat: 48.5468616, lng: 19.7232061 },
    { lat: 48.5464534, lng: 19.7217281 },
    { lat: 48.5443835, lng: 19.7198449 },
    { lat: 48.5441418, lng: 19.7181614 },
    { lat: 48.5440457, lng: 19.7178215 },
    { lat: 48.5439158, lng: 19.7171284 },
    { lat: 48.5430419, lng: 19.7166052 },
    { lat: 48.5417602, lng: 19.7160083 },
    { lat: 48.5418342, lng: 19.7143258 },
    { lat: 48.5409731, lng: 19.7147781 },
    { lat: 48.5405995, lng: 19.7148125 },
    { lat: 48.5407409, lng: 19.7140427 },
    { lat: 48.5403688, lng: 19.7141375 },
    { lat: 48.5403495, lng: 19.71408 },
    { lat: 48.5398004, lng: 19.7144013 },
    { lat: 48.538706, lng: 19.7153831 },
    { lat: 48.5383457, lng: 19.7155431 },
    { lat: 48.5379302, lng: 19.7155964 },
    { lat: 48.5371715, lng: 19.715414 },
    { lat: 48.5367706, lng: 19.71562 },
    { lat: 48.5365501, lng: 19.7159288 },
    { lat: 48.5362639, lng: 19.7160081 },
    { lat: 48.5357482, lng: 19.7163943 },
    { lat: 48.5352444, lng: 19.7154293 },
    { lat: 48.5348542, lng: 19.7153197 },
    { lat: 48.534738, lng: 19.7151657 },
    { lat: 48.5339892, lng: 19.7152749 },
    { lat: 48.5334633, lng: 19.715537 },
    { lat: 48.5329164, lng: 19.716339 },
    { lat: 48.5323503, lng: 19.7170373 },
    { lat: 48.5319661, lng: 19.7172634 },
    { lat: 48.5310039, lng: 19.717517 },
    { lat: 48.5309419, lng: 19.717621 },
    { lat: 48.5299323, lng: 19.7174387 },
    { lat: 48.5291878, lng: 19.7176231 },
    { lat: 48.5285826, lng: 19.7178778 },
    { lat: 48.5280017, lng: 19.7179868 },
    { lat: 48.5274572, lng: 19.717726 },
    { lat: 48.5268087, lng: 19.7177113 },
    { lat: 48.5249696, lng: 19.718216 },
    { lat: 48.524217, lng: 19.7189252 },
    { lat: 48.5238751, lng: 19.7195738 },
    { lat: 48.5230828, lng: 19.71957 },
    { lat: 48.5225587, lng: 19.7196444 },
    { lat: 48.522024, lng: 19.7197789 },
    { lat: 48.5213349, lng: 19.7201973 },
    { lat: 48.5199222, lng: 19.7219758 },
    { lat: 48.5190798, lng: 19.7235073 },
    { lat: 48.5182088, lng: 19.7241668 },
    { lat: 48.5177971, lng: 19.7245895 },
    { lat: 48.5176441, lng: 19.7250257 },
    { lat: 48.5167926, lng: 19.7253632 },
    { lat: 48.5164696, lng: 19.7253957 },
    { lat: 48.5161585, lng: 19.7258559 },
    { lat: 48.5158419, lng: 19.7261315 },
    { lat: 48.5156563, lng: 19.7265738 },
    { lat: 48.5148845, lng: 19.7275856 },
    { lat: 48.5147309, lng: 19.7280886 },
    { lat: 48.5146187, lng: 19.728225 },
    { lat: 48.5144578, lng: 19.7287874 },
    { lat: 48.5143463, lng: 19.7289666 },
    { lat: 48.5140635, lng: 19.7296827 },
    { lat: 48.512919, lng: 19.7304739 },
    { lat: 48.5127833, lng: 19.7306925 },
    { lat: 48.5126759, lng: 19.7307108 },
    { lat: 48.5126137, lng: 19.730883 },
    { lat: 48.5125567, lng: 19.7308915 },
    { lat: 48.512356, lng: 19.7314234 },
    { lat: 48.5121264, lng: 19.7316554 },
    { lat: 48.5118634, lng: 19.7322596 },
    { lat: 48.5115666, lng: 19.7322695 },
    { lat: 48.5114011, lng: 19.7323497 },
    { lat: 48.5112192, lng: 19.7325915 },
    { lat: 48.5109363, lng: 19.7327666 },
    { lat: 48.5105084, lng: 19.7328767 },
    { lat: 48.510016, lng: 19.7328743 },
    { lat: 48.5095665, lng: 19.7327737 },
    { lat: 48.5092416, lng: 19.7326674 },
    { lat: 48.5091203, lng: 19.7324713 },
    { lat: 48.5087257, lng: 19.7323368 },
    { lat: 48.5084436, lng: 19.7320226 },
    { lat: 48.508332, lng: 19.7315655 },
    { lat: 48.507987, lng: 19.731432 },
    { lat: 48.5066863, lng: 19.7312926 },
    { lat: 48.5047695, lng: 19.7305029 },
    { lat: 48.5043385, lng: 19.7300524 },
    { lat: 48.5036007, lng: 19.7299296 },
    { lat: 48.5033871, lng: 19.7294362 },
    { lat: 48.5030239, lng: 19.7294258 },
    { lat: 48.5026733, lng: 19.7292443 },
    { lat: 48.5024892, lng: 19.729282 },
    { lat: 48.5021933, lng: 19.7294463 },
    { lat: 48.5021042, lng: 19.7296461 },
    { lat: 48.5009074, lng: 19.7303563 },
    { lat: 48.50042, lng: 19.7317123 },
    { lat: 48.4995738, lng: 19.7336719 },
    { lat: 48.4995247, lng: 19.7339067 },
    { lat: 48.4994821, lng: 19.7356955 },
    { lat: 48.4994829, lng: 19.7357132 },
    { lat: 48.4995352, lng: 19.7359509 },
    { lat: 48.4994943, lng: 19.7363003 },
    { lat: 48.4997166, lng: 19.736559 },
    { lat: 48.4996966, lng: 19.7368751 },
    { lat: 48.4997615, lng: 19.7372224 },
    { lat: 48.4996226, lng: 19.737593 },
    { lat: 48.4996726, lng: 19.7378857 },
    { lat: 48.5000945, lng: 19.7380989 },
    { lat: 48.5001734, lng: 19.7384299 },
    { lat: 48.5003368, lng: 19.7388139 },
    { lat: 48.500362, lng: 19.7390263 },
    { lat: 48.5004146, lng: 19.7390409 },
    { lat: 48.5004861, lng: 19.7393599 },
    { lat: 48.5005481, lng: 19.7394123 },
    { lat: 48.5006572, lng: 19.739968 },
    { lat: 48.5017225, lng: 19.7413552 },
    { lat: 48.5018181, lng: 19.7413748 },
    { lat: 48.5019289, lng: 19.741618 },
    { lat: 48.5018957, lng: 19.7424193 },
    { lat: 48.5019691, lng: 19.7425001 },
    { lat: 48.501924, lng: 19.7426758 },
    { lat: 48.5020599, lng: 19.7427699 },
    { lat: 48.5020735, lng: 19.7430506 },
    { lat: 48.5019899, lng: 19.7432256 },
    { lat: 48.5020441, lng: 19.7436078 },
    { lat: 48.5021766, lng: 19.7437252 },
    { lat: 48.5022093, lng: 19.7441085 },
    { lat: 48.5021578, lng: 19.7442412 },
    { lat: 48.5022094, lng: 19.7447725 },
    { lat: 48.5021613, lng: 19.7452378 },
    { lat: 48.5020289, lng: 19.745839 },
    { lat: 48.5019354, lng: 19.746884 },
    { lat: 48.5019599, lng: 19.7471359 },
    { lat: 48.5020974, lng: 19.7477943 },
    { lat: 48.5021302, lng: 19.7482859 },
    { lat: 48.5028427, lng: 19.7510754 },
    { lat: 48.5031143, lng: 19.7524555 },
    { lat: 48.5031732, lng: 19.7530003 },
    { lat: 48.5049242, lng: 19.7533053 },
    { lat: 48.5053051, lng: 19.7536658 },
    { lat: 48.5057437, lng: 19.7544626 },
    { lat: 48.50635, lng: 19.7551034 },
    { lat: 48.5070614, lng: 19.7565438 },
    { lat: 48.5075613, lng: 19.7573278 },
    { lat: 48.5078961, lng: 19.7580995 },
    { lat: 48.508453, lng: 19.7591322 },
    { lat: 48.5089221, lng: 19.7597793 },
    { lat: 48.5096143, lng: 19.7602226 },
    { lat: 48.5101421, lng: 19.7606709 },
    { lat: 48.5103617, lng: 19.7609446 },
    { lat: 48.5110985, lng: 19.762991 },
    { lat: 48.5115101, lng: 19.7633831 },
    { lat: 48.5118972, lng: 19.7645782 },
    { lat: 48.5118783, lng: 19.7650655 },
    { lat: 48.5122306, lng: 19.7669542 },
    { lat: 48.5124229, lng: 19.7694032 },
    { lat: 48.5124491, lng: 19.7694624 },
    { lat: 48.5135617, lng: 19.7688601 },
    { lat: 48.514754, lng: 19.76815 },
    { lat: 48.5155365, lng: 19.7678801 },
    { lat: 48.516791, lng: 19.7679686 },
    { lat: 48.5169342, lng: 19.7680247 },
    { lat: 48.5174416, lng: 19.7677236 },
    { lat: 48.5181258, lng: 19.7676554 },
    { lat: 48.5193043, lng: 19.7678357 },
    { lat: 48.5196502, lng: 19.7678083 },
    { lat: 48.5202119, lng: 19.7679219 },
    { lat: 48.521642, lng: 19.7676226 },
    { lat: 48.5223859, lng: 19.7673472 },
    { lat: 48.5227599, lng: 19.7669151 },
    { lat: 48.5234769, lng: 19.7665113 },
    { lat: 48.5241058, lng: 19.7663654 },
    { lat: 48.5243752, lng: 19.7664665 },
    { lat: 48.5245976, lng: 19.7663011 },
    { lat: 48.5248512, lng: 19.7663017 },
    { lat: 48.5252052, lng: 19.7660987 },
    { lat: 48.5255703, lng: 19.7662567 },
    { lat: 48.5266309, lng: 19.765884 },
    { lat: 48.5273015, lng: 19.7655533 },
    { lat: 48.5276184, lng: 19.7655118 },
    { lat: 48.5299896, lng: 19.7647019 },
    { lat: 48.5307736, lng: 19.764137 },
    { lat: 48.5319345, lng: 19.7637354 },
    { lat: 48.5335478, lng: 19.7640685 },
    { lat: 48.5339948, lng: 19.7638291 },
    { lat: 48.5342151, lng: 19.7636155 },
    { lat: 48.5344195, lng: 19.7635507 },
    { lat: 48.5348883, lng: 19.7631672 },
    { lat: 48.5350327, lng: 19.7629935 },
    { lat: 48.5355982, lng: 19.7617081 },
    { lat: 48.5358127, lng: 19.7613621 },
    { lat: 48.5361363, lng: 19.7610063 },
    { lat: 48.5364634, lng: 19.7600692 },
    { lat: 48.5365176, lng: 19.7595593 },
    { lat: 48.5366216, lng: 19.7591589 },
    { lat: 48.5366566, lng: 19.7586734 },
    { lat: 48.5366054, lng: 19.7578434 },
    { lat: 48.5366791, lng: 19.7573728 },
    { lat: 48.5367573, lng: 19.7571481 },
    { lat: 48.5374617, lng: 19.755597 },
    { lat: 48.5380423, lng: 19.7545065 },
    { lat: 48.5380824, lng: 19.7541619 },
    { lat: 48.5384838, lng: 19.7535319 },
    { lat: 48.5390523, lng: 19.7528828 },
    { lat: 48.5395894, lng: 19.7516191 },
    { lat: 48.5400881, lng: 19.7506143 },
    { lat: 48.5401681, lng: 19.7502077 },
    { lat: 48.5405082, lng: 19.7510707 },
    { lat: 48.5409077, lng: 19.7516775 },
    { lat: 48.5412246, lng: 19.7516835 },
    { lat: 48.54155, lng: 19.7513209 },
    { lat: 48.5419758, lng: 19.7505249 },
    { lat: 48.5418604, lng: 19.7499007 },
    { lat: 48.541959, lng: 19.748074 },
    { lat: 48.5423069, lng: 19.7474852 },
    { lat: 48.5427554, lng: 19.7469265 },
    { lat: 48.542683, lng: 19.7465103 },
    { lat: 48.5427954, lng: 19.7459657 },
    { lat: 48.5426594, lng: 19.7456671 },
    { lat: 48.5425384, lng: 19.7455303 },
    { lat: 48.5422046, lng: 19.7456544 },
    { lat: 48.5420853, lng: 19.7454772 },
    { lat: 48.542168, lng: 19.7450017 },
    { lat: 48.5421769, lng: 19.744236 },
    { lat: 48.5423286, lng: 19.7429518 },
    { lat: 48.5425198, lng: 19.7427364 },
    { lat: 48.5428695, lng: 19.7426725 },
    { lat: 48.5435668, lng: 19.7427445 },
    { lat: 48.5439663, lng: 19.7431407 },
    { lat: 48.5439503, lng: 19.7436451 },
    { lat: 48.5442448, lng: 19.7436558 },
    { lat: 48.544165, lng: 19.7428179 },
    { lat: 48.5443552, lng: 19.7426608 },
    { lat: 48.5445891, lng: 19.7423213 },
    { lat: 48.5446043, lng: 19.7419994 },
    { lat: 48.5447464, lng: 19.7416456 },
    { lat: 48.5450233, lng: 19.7415371 },
    { lat: 48.5453817, lng: 19.741057 },
    { lat: 48.5455343, lng: 19.7409671 },
    { lat: 48.5455562, lng: 19.7408486 },
    { lat: 48.5460924, lng: 19.7398561 },
    { lat: 48.5462358, lng: 19.739836 },
    { lat: 48.5464083, lng: 19.7396093 },
    { lat: 48.5470341, lng: 19.739543 },
    { lat: 48.5482004, lng: 19.7398267 },
    { lat: 48.5481974, lng: 19.7398107 },
    { lat: 48.5472608, lng: 19.7351411 },
    { lat: 48.546864, lng: 19.7325132 },
    { lat: 48.5470875, lng: 19.7307686 },
    { lat: 48.5459061, lng: 19.7263856 },
    { lat: 48.5455015, lng: 19.7252898 },
    { lat: 48.54513, lng: 19.7240261 },
    { lat: 48.5468371, lng: 19.7232651 },
    { lat: 48.5468616, lng: 19.7232061 },
  ],
  KokavanadRimavicou: [
    { lat: 48.5920108, lng: 19.731382 },
    { lat: 48.5920587, lng: 19.7317548 },
    { lat: 48.59207, lng: 19.7323413 },
    { lat: 48.5919938, lng: 19.7323483 },
    { lat: 48.5918903, lng: 19.7326067 },
    { lat: 48.5918402, lng: 19.732621 },
    { lat: 48.5917945, lng: 19.7329652 },
    { lat: 48.5918141, lng: 19.7333024 },
    { lat: 48.5915026, lng: 19.7334166 },
    { lat: 48.5914333, lng: 19.7335332 },
    { lat: 48.591377, lng: 19.7339677 },
    { lat: 48.5914342, lng: 19.7340922 },
    { lat: 48.591296, lng: 19.7347831 },
    { lat: 48.5912223, lng: 19.7347517 },
    { lat: 48.5910147, lng: 19.7358381 },
    { lat: 48.5911587, lng: 19.7357318 },
    { lat: 48.5911473, lng: 19.7363387 },
    { lat: 48.5913508, lng: 19.7366108 },
    { lat: 48.5916994, lng: 19.7375642 },
    { lat: 48.5919873, lng: 19.7381115 },
    { lat: 48.5918507, lng: 19.7387174 },
    { lat: 48.5919028, lng: 19.7392133 },
    { lat: 48.5918777, lng: 19.7396376 },
    { lat: 48.5917028, lng: 19.7403179 },
    { lat: 48.5914408, lng: 19.7409449 },
    { lat: 48.5910813, lng: 19.7415576 },
    { lat: 48.5909875, lng: 19.7415225 },
    { lat: 48.5908751, lng: 19.7416551 },
    { lat: 48.590808, lng: 19.7418469 },
    { lat: 48.5914101, lng: 19.7426713 },
    { lat: 48.5913834, lng: 19.7427843 },
    { lat: 48.5911807, lng: 19.742962 },
    { lat: 48.5910633, lng: 19.743315 },
    { lat: 48.5910664, lng: 19.7436418 },
    { lat: 48.5909518, lng: 19.7438256 },
    { lat: 48.5907237, lng: 19.744451 },
    { lat: 48.5907324, lng: 19.7446768 },
    { lat: 48.5907888, lng: 19.7447818 },
    { lat: 48.5906638, lng: 19.7452129 },
    { lat: 48.5904525, lng: 19.7456366 },
    { lat: 48.5904581, lng: 19.7457905 },
    { lat: 48.5902954, lng: 19.7458275 },
    { lat: 48.5902852, lng: 19.7459548 },
    { lat: 48.5901793, lng: 19.7460035 },
    { lat: 48.5900642, lng: 19.7462315 },
    { lat: 48.5900832, lng: 19.7465079 },
    { lat: 48.5900019, lng: 19.7464793 },
    { lat: 48.5898683, lng: 19.7465927 },
    { lat: 48.5898921, lng: 19.7467996 },
    { lat: 48.5897256, lng: 19.7470334 },
    { lat: 48.5897367, lng: 19.7471828 },
    { lat: 48.5896925, lng: 19.7472029 },
    { lat: 48.5896273, lng: 19.747491 },
    { lat: 48.5895185, lng: 19.7475795 },
    { lat: 48.5894406, lng: 19.7477763 },
    { lat: 48.5894647, lng: 19.7478148 },
    { lat: 48.5893765, lng: 19.7480642 },
    { lat: 48.5893747, lng: 19.7483021 },
    { lat: 48.5893054, lng: 19.7482877 },
    { lat: 48.5891228, lng: 19.7485426 },
    { lat: 48.5890001, lng: 19.7485702 },
    { lat: 48.5889842, lng: 19.7487192 },
    { lat: 48.588917, lng: 19.7486775 },
    { lat: 48.5887933, lng: 19.7488646 },
    { lat: 48.5887932, lng: 19.7491419 },
    { lat: 48.5887277, lng: 19.7493969 },
    { lat: 48.5885713, lng: 19.7494004 },
    { lat: 48.5885397, lng: 19.7495228 },
    { lat: 48.588587, lng: 19.7496277 },
    { lat: 48.5885134, lng: 19.7498208 },
    { lat: 48.5886132, lng: 19.7499165 },
    { lat: 48.5885354, lng: 19.7500418 },
    { lat: 48.5886044, lng: 19.7501772 },
    { lat: 48.5886109, lng: 19.7505004 },
    { lat: 48.5887378, lng: 19.7505955 },
    { lat: 48.5887336, lng: 19.7506753 },
    { lat: 48.5886693, lng: 19.7507013 },
    { lat: 48.5885836, lng: 19.7506473 },
    { lat: 48.5885965, lng: 19.7507912 },
    { lat: 48.5884438, lng: 19.7511068 },
    { lat: 48.5884903, lng: 19.751294 },
    { lat: 48.5884874, lng: 19.7515623 },
    { lat: 48.5885311, lng: 19.7515291 },
    { lat: 48.5885509, lng: 19.7515922 },
    { lat: 48.5885268, lng: 19.7516366 },
    { lat: 48.5885689, lng: 19.7518239 },
    { lat: 48.588612, lng: 19.7518277 },
    { lat: 48.5885798, lng: 19.7518897 },
    { lat: 48.5886435, lng: 19.7520872 },
    { lat: 48.5885251, lng: 19.7521592 },
    { lat: 48.5885503, lng: 19.7523457 },
    { lat: 48.5886312, lng: 19.7523443 },
    { lat: 48.588652, lng: 19.7524558 },
    { lat: 48.5885909, lng: 19.752453 },
    { lat: 48.5885297, lng: 19.7526705 },
    { lat: 48.588579, lng: 19.7529756 },
    { lat: 48.5885129, lng: 19.7530555 },
    { lat: 48.5885663, lng: 19.7533523 },
    { lat: 48.588499, lng: 19.7534327 },
    { lat: 48.5884944, lng: 19.7539929 },
    { lat: 48.5881031, lng: 19.7539841 },
    { lat: 48.5878737, lng: 19.7538686 },
    { lat: 48.5870884, lng: 19.7542006 },
    { lat: 48.585953, lng: 19.7548954 },
    { lat: 48.5857306, lng: 19.7549657 },
    { lat: 48.5855515, lng: 19.7555796 },
    { lat: 48.585519, lng: 19.7559178 },
    { lat: 48.5853434, lng: 19.7560965 },
    { lat: 48.5852685, lng: 19.7562674 },
    { lat: 48.5844754, lng: 19.7561919 },
    { lat: 48.5837645, lng: 19.7556058 },
    { lat: 48.5835103, lng: 19.7560476 },
    { lat: 48.5834812, lng: 19.7559765 },
    { lat: 48.5833734, lng: 19.7560855 },
    { lat: 48.5834794, lng: 19.7578541 },
    { lat: 48.5836064, lng: 19.7582026 },
    { lat: 48.5819668, lng: 19.7611178 },
    { lat: 48.5819556, lng: 19.761299 },
    { lat: 48.5821884, lng: 19.7618233 },
    { lat: 48.581959, lng: 19.7620146 },
    { lat: 48.5820452, lng: 19.7623643 },
    { lat: 48.5822135, lng: 19.7626531 },
    { lat: 48.5819746, lng: 19.7629585 },
    { lat: 48.5814884, lng: 19.7639446 },
    { lat: 48.5813687, lng: 19.7638854 },
    { lat: 48.5812235, lng: 19.7636612 },
    { lat: 48.5810743, lng: 19.7632149 },
    { lat: 48.5807321, lng: 19.7625802 },
    { lat: 48.5804858, lng: 19.7623852 },
    { lat: 48.5803695, lng: 19.7619955 },
    { lat: 48.5803613, lng: 19.7616793 },
    { lat: 48.5800585, lng: 19.7610225 },
    { lat: 48.5800392, lng: 19.7608418 },
    { lat: 48.5796873, lng: 19.7605482 },
    { lat: 48.5794284, lng: 19.7604752 },
    { lat: 48.5792435, lng: 19.760245 },
    { lat: 48.5791076, lng: 19.7597152 },
    { lat: 48.579025, lng: 19.7589653 },
    { lat: 48.5789371, lng: 19.7588136 },
    { lat: 48.5787443, lng: 19.7586813 },
    { lat: 48.5786312, lng: 19.7586716 },
    { lat: 48.5784932, lng: 19.7593757 },
    { lat: 48.5768171, lng: 19.764403 },
    { lat: 48.5766639, lng: 19.7648382 },
    { lat: 48.5758312, lng: 19.7664558 },
    { lat: 48.5756848, lng: 19.7671738 },
    { lat: 48.5747349, lng: 19.7675049 },
    { lat: 48.5745996, lng: 19.767016 },
    { lat: 48.5741637, lng: 19.7670407 },
    { lat: 48.5741621, lng: 19.7669295 },
    { lat: 48.574579, lng: 19.7666986 },
    { lat: 48.5746084, lng: 19.766395 },
    { lat: 48.5748678, lng: 19.7660183 },
    { lat: 48.5748496, lng: 19.7658074 },
    { lat: 48.5747346, lng: 19.7655752 },
    { lat: 48.5744373, lng: 19.765628 },
    { lat: 48.5742741, lng: 19.7655293 },
    { lat: 48.574037, lng: 19.7659056 },
    { lat: 48.5738995, lng: 19.7658279 },
    { lat: 48.5737196, lng: 19.7659348 },
    { lat: 48.573171, lng: 19.7656306 },
    { lat: 48.5737528, lng: 19.7647948 },
    { lat: 48.5741875, lng: 19.7637378 },
    { lat: 48.5735891, lng: 19.7632738 },
    { lat: 48.5731786, lng: 19.7634472 },
    { lat: 48.5728228, lng: 19.7641451 },
    { lat: 48.5724839, lng: 19.7646095 },
    { lat: 48.5722287, lng: 19.7645994 },
    { lat: 48.5720425, lng: 19.764079 },
    { lat: 48.5716824, lng: 19.7636756 },
    { lat: 48.5715036, lng: 19.763739 },
    { lat: 48.5711493, lng: 19.7629828 },
    { lat: 48.5707766, lng: 19.7625238 },
    { lat: 48.5705532, lng: 19.7619057 },
    { lat: 48.570622, lng: 19.7618284 },
    { lat: 48.5706438, lng: 19.7615566 },
    { lat: 48.5707492, lng: 19.7611348 },
    { lat: 48.5708089, lng: 19.7610054 },
    { lat: 48.5709223, lng: 19.7609478 },
    { lat: 48.5709505, lng: 19.760639 },
    { lat: 48.5709169, lng: 19.7601333 },
    { lat: 48.5703916, lng: 19.7596938 },
    { lat: 48.5699104, lng: 19.7592794 },
    { lat: 48.56952, lng: 19.7589588 },
    { lat: 48.5691987, lng: 19.7592865 },
    { lat: 48.5688562, lng: 19.759928 },
    { lat: 48.5684267, lng: 19.7597717 },
    { lat: 48.5681053, lng: 19.7613516 },
    { lat: 48.5681059, lng: 19.7616714 },
    { lat: 48.5684577, lng: 19.7637262 },
    { lat: 48.5676173, lng: 19.7639599 },
    { lat: 48.5659475, lng: 19.7629591 },
    { lat: 48.5651873, lng: 19.7609417 },
    { lat: 48.5649143, lng: 19.7604205 },
    { lat: 48.564447, lng: 19.7599147 },
    { lat: 48.5637449, lng: 19.7612188 },
    { lat: 48.5632208, lng: 19.7604193 },
    { lat: 48.5619025, lng: 19.7586779 },
    { lat: 48.5599557, lng: 19.7578122 },
    { lat: 48.5592791, lng: 19.7565216 },
    { lat: 48.5589948, lng: 19.7560766 },
    { lat: 48.5585728, lng: 19.7558798 },
    { lat: 48.5577839, lng: 19.7566711 },
    { lat: 48.5575363, lng: 19.7579321 },
    { lat: 48.5561589, lng: 19.7594486 },
    { lat: 48.5553983, lng: 19.7603689 },
    { lat: 48.5551529, lng: 19.7616812 },
    { lat: 48.5548124, lng: 19.7638516 },
    { lat: 48.5546738, lng: 19.7650326 },
    { lat: 48.5540325, lng: 19.7689193 },
    { lat: 48.5533778, lng: 19.7696603 },
    { lat: 48.5530174, lng: 19.7718933 },
    { lat: 48.5529195, lng: 19.772131 },
    { lat: 48.5528736, lng: 19.7728536 },
    { lat: 48.5530725, lng: 19.7742762 },
    { lat: 48.5531111, lng: 19.7747204 },
    { lat: 48.5530993, lng: 19.77535 },
    { lat: 48.5526231, lng: 19.7779799 },
    { lat: 48.552537, lng: 19.7787609 },
    { lat: 48.5518919, lng: 19.7808052 },
    { lat: 48.5517514, lng: 19.7818647 },
    { lat: 48.5514573, lng: 19.7833797 },
    { lat: 48.5508602, lng: 19.7835347 },
    { lat: 48.5502515, lng: 19.7840202 },
    { lat: 48.5497948, lng: 19.7833117 },
    { lat: 48.5493764, lng: 19.7828413 },
    { lat: 48.5492148, lng: 19.7828278 },
    { lat: 48.5489216, lng: 19.7824623 },
    { lat: 48.5487527, lng: 19.7821317 },
    { lat: 48.5483256, lng: 19.7815398 },
    { lat: 48.5481082, lng: 19.78105 },
    { lat: 48.5478259, lng: 19.7806813 },
    { lat: 48.5462536, lng: 19.7789443 },
    { lat: 48.5461343, lng: 19.7795396 },
    { lat: 48.5459128, lng: 19.7808297 },
    { lat: 48.5457828, lng: 19.7819249 },
    { lat: 48.5453717, lng: 19.7834201 },
    { lat: 48.5450387, lng: 19.78531 },
    { lat: 48.5443261, lng: 19.7871998 },
    { lat: 48.5434444, lng: 19.7884195 },
    { lat: 48.5426348, lng: 19.7890226 },
    { lat: 48.5415851, lng: 19.7895449 },
    { lat: 48.5409235, lng: 19.7901802 },
    { lat: 48.5405751, lng: 19.7906404 },
    { lat: 48.5402972, lng: 19.7911621 },
    { lat: 48.5401825, lng: 19.7915287 },
    { lat: 48.539789, lng: 19.7940177 },
    { lat: 48.5394748, lng: 19.7968543 },
    { lat: 48.5393078, lng: 19.7974183 },
    { lat: 48.538917, lng: 19.7982961 },
    { lat: 48.538641, lng: 19.7987622 },
    { lat: 48.5387359, lng: 19.8000568 },
    { lat: 48.53894, lng: 19.8016051 },
    { lat: 48.539139, lng: 19.8028383 },
    { lat: 48.5394601, lng: 19.804097 },
    { lat: 48.5394956, lng: 19.8044736 },
    { lat: 48.5394548, lng: 19.8059399 },
    { lat: 48.5396097, lng: 19.8066761 },
    { lat: 48.5394224, lng: 19.8071888 },
    { lat: 48.539274, lng: 19.8078886 },
    { lat: 48.5383292, lng: 19.8094251 },
    { lat: 48.5378595, lng: 19.8104623 },
    { lat: 48.5377365, lng: 19.8108421 },
    { lat: 48.5373179, lng: 19.8115013 },
    { lat: 48.5363093, lng: 19.812323 },
    { lat: 48.5356366, lng: 19.813247 },
    { lat: 48.5343012, lng: 19.8145072 },
    { lat: 48.5338389, lng: 19.8152002 },
    { lat: 48.5335372, lng: 19.81546 },
    { lat: 48.5332517, lng: 19.815839 },
    { lat: 48.5321466, lng: 19.8169815 },
    { lat: 48.5316533, lng: 19.8176302 },
    { lat: 48.5312113, lng: 19.8179049 },
    { lat: 48.5310334, lng: 19.8178869 },
    { lat: 48.5306027, lng: 19.8180848 },
    { lat: 48.5293686, lng: 19.8189779 },
    { lat: 48.5286079, lng: 19.8194155 },
    { lat: 48.5275023, lng: 19.8200013 },
    { lat: 48.5269265, lng: 19.820181 },
    { lat: 48.5260247, lng: 19.8206581 },
    { lat: 48.5258965, lng: 19.8210003 },
    { lat: 48.5254799, lng: 19.8218115 },
    { lat: 48.5254172, lng: 19.8227777 },
    { lat: 48.525456, lng: 19.823751 },
    { lat: 48.525469, lng: 19.823757 },
    { lat: 48.526163, lng: 19.824129 },
    { lat: 48.526665, lng: 19.824382 },
    { lat: 48.526887, lng: 19.824473 },
    { lat: 48.527671, lng: 19.824559 },
    { lat: 48.528785, lng: 19.824618 },
    { lat: 48.529388, lng: 19.824631 },
    { lat: 48.529671, lng: 19.824638 },
    { lat: 48.529809, lng: 19.824696 },
    { lat: 48.530209, lng: 19.824621 },
    { lat: 48.530531, lng: 19.824595 },
    { lat: 48.53106, lng: 19.82463 },
    { lat: 48.531465, lng: 19.824634 },
    { lat: 48.531719, lng: 19.824636 },
    { lat: 48.531902, lng: 19.824566 },
    { lat: 48.53225, lng: 19.824432 },
    { lat: 48.532742, lng: 19.824442 },
    { lat: 48.533748, lng: 19.824612 },
    { lat: 48.534456, lng: 19.824691 },
    { lat: 48.534624, lng: 19.824675 },
    { lat: 48.534733, lng: 19.824662 },
    { lat: 48.535131, lng: 19.824558 },
    { lat: 48.535507, lng: 19.824563 },
    { lat: 48.535891, lng: 19.824683 },
    { lat: 48.536109, lng: 19.824753 },
    { lat: 48.536655, lng: 19.824992 },
    { lat: 48.536632, lng: 19.825022 },
    { lat: 48.536633, lng: 19.825315 },
    { lat: 48.536721, lng: 19.825375 },
    { lat: 48.536806, lng: 19.825312 },
    { lat: 48.536879, lng: 19.825582 },
    { lat: 48.537179, lng: 19.825655 },
    { lat: 48.537228, lng: 19.825811 },
    { lat: 48.537407, lng: 19.825816 },
    { lat: 48.537506, lng: 19.825753 },
    { lat: 48.537571, lng: 19.825807 },
    { lat: 48.537637, lng: 19.826021 },
    { lat: 48.53772, lng: 19.826087 },
    { lat: 48.537825, lng: 19.826464 },
    { lat: 48.537942, lng: 19.826515 },
    { lat: 48.53806, lng: 19.826441 },
    { lat: 48.538157, lng: 19.826472 },
    { lat: 48.538299, lng: 19.826732 },
    { lat: 48.538525, lng: 19.826866 },
    { lat: 48.53857, lng: 19.826944 },
    { lat: 48.538617, lng: 19.826804 },
    { lat: 48.538758, lng: 19.82673 },
    { lat: 48.538819, lng: 19.826747 },
    { lat: 48.53886, lng: 19.826857 },
    { lat: 48.539018, lng: 19.826833 },
    { lat: 48.539173, lng: 19.826904 },
    { lat: 48.539217, lng: 19.826996 },
    { lat: 48.539315, lng: 19.82698 },
    { lat: 48.539412, lng: 19.826944 },
    { lat: 48.539439, lng: 19.826922 },
    { lat: 48.539464, lng: 19.826902 },
    { lat: 48.540013, lng: 19.827629 },
    { lat: 48.540987, lng: 19.828765 },
    { lat: 48.540977, lng: 19.828803 },
    { lat: 48.54097, lng: 19.828842 },
    { lat: 48.541213, lng: 19.829407 },
    { lat: 48.541694, lng: 19.830091 },
    { lat: 48.5424, lng: 19.830352 },
    { lat: 48.542433, lng: 19.830364 },
    { lat: 48.542477, lng: 19.830379 },
    { lat: 48.542706, lng: 19.830461 },
    { lat: 48.542769, lng: 19.830492 },
    { lat: 48.543133, lng: 19.830673 },
    { lat: 48.543239, lng: 19.830961 },
    { lat: 48.543391, lng: 19.831006 },
    { lat: 48.543746, lng: 19.831433 },
    { lat: 48.544077, lng: 19.831278 },
    { lat: 48.544168, lng: 19.831334 },
    { lat: 48.544333, lng: 19.83144 },
    { lat: 48.544409, lng: 19.831578 },
    { lat: 48.544449, lng: 19.831803 },
    { lat: 48.545058, lng: 19.832338 },
    { lat: 48.545087, lng: 19.83233 },
    { lat: 48.545228, lng: 19.832483 },
    { lat: 48.54531, lng: 19.832473 },
    { lat: 48.545333, lng: 19.832737 },
    { lat: 48.54554, lng: 19.832936 },
    { lat: 48.546111, lng: 19.833451 },
    { lat: 48.546371, lng: 19.833772 },
    { lat: 48.546507, lng: 19.833952 },
    { lat: 48.546729, lng: 19.834104 },
    { lat: 48.546865, lng: 19.834286 },
    { lat: 48.546992, lng: 19.834337 },
    { lat: 48.547068, lng: 19.834298 },
    { lat: 48.547159, lng: 19.834402 },
    { lat: 48.547343, lng: 19.83454 },
    { lat: 48.547499, lng: 19.834698 },
    { lat: 48.547657, lng: 19.834929 },
    { lat: 48.547734, lng: 19.835187 },
    { lat: 48.547786, lng: 19.835444 },
    { lat: 48.547791, lng: 19.835657 },
    { lat: 48.547739, lng: 19.835952 },
    { lat: 48.547804, lng: 19.836208 },
    { lat: 48.547963, lng: 19.836751 },
    { lat: 48.548054, lng: 19.837059 },
    { lat: 48.548001, lng: 19.837391 },
    { lat: 48.548073, lng: 19.837898 },
    { lat: 48.547972, lng: 19.838101 },
    { lat: 48.547952, lng: 19.838214 },
    { lat: 48.548007, lng: 19.838386 },
    { lat: 48.548294, lng: 19.838645 },
    { lat: 48.548362, lng: 19.838987 },
    { lat: 48.548259, lng: 19.839121 },
    { lat: 48.548082, lng: 19.839201 },
    { lat: 48.548004, lng: 19.839501 },
    { lat: 48.547879, lng: 19.839845 },
    { lat: 48.547851, lng: 19.840013 },
    { lat: 48.548283, lng: 19.840698 },
    { lat: 48.548384, lng: 19.841015 },
    { lat: 48.548355, lng: 19.841227 },
    { lat: 48.548316, lng: 19.841512 },
    { lat: 48.548441, lng: 19.841891 },
    { lat: 48.548474, lng: 19.842177 },
    { lat: 48.54836, lng: 19.842276 },
    { lat: 48.548529, lng: 19.843149 },
    { lat: 48.548728, lng: 19.843709 },
    { lat: 48.548726, lng: 19.843804 },
    { lat: 48.548715, lng: 19.844151 },
    { lat: 48.548619, lng: 19.844409 },
    { lat: 48.54858, lng: 19.844449 },
    { lat: 48.548555, lng: 19.844949 },
    { lat: 48.548544, lng: 19.845148 },
    { lat: 48.548511, lng: 19.845409 },
    { lat: 48.548441, lng: 19.84563 },
    { lat: 48.548175, lng: 19.846001 },
    { lat: 48.547962, lng: 19.846787 },
    { lat: 48.547947, lng: 19.846865 },
    { lat: 48.5479, lng: 19.847115 },
    { lat: 48.54792, lng: 19.847183 },
    { lat: 48.547903, lng: 19.847442 },
    { lat: 48.547813, lng: 19.84744 },
    { lat: 48.547773, lng: 19.847436 },
    { lat: 48.547771, lng: 19.848078 },
    { lat: 48.547625, lng: 19.848289 },
    { lat: 48.547588, lng: 19.848656 },
    { lat: 48.547649, lng: 19.848862 },
    { lat: 48.547684, lng: 19.848991 },
    { lat: 48.547645, lng: 19.849122 },
    { lat: 48.547535, lng: 19.849234 },
    { lat: 48.54751, lng: 19.849287 },
    { lat: 48.547514, lng: 19.849339 },
    { lat: 48.547604, lng: 19.84959 },
    { lat: 48.547599, lng: 19.849702 },
    { lat: 48.54763, lng: 19.849866 },
    { lat: 48.54763, lng: 19.84993 },
    { lat: 48.5475, lng: 19.850004 },
    { lat: 48.547436, lng: 19.850021 },
    { lat: 48.547416, lng: 19.850026 },
    { lat: 48.547379, lng: 19.850045 },
    { lat: 48.54739, lng: 19.85008 },
    { lat: 48.547403, lng: 19.850121 },
    { lat: 48.547426, lng: 19.850172 },
    { lat: 48.547478, lng: 19.850299 },
    { lat: 48.547575, lng: 19.850534 },
    { lat: 48.547655, lng: 19.85082 },
    { lat: 48.547759, lng: 19.850934 },
    { lat: 48.547739, lng: 19.851466 },
    { lat: 48.547599, lng: 19.851772 },
    { lat: 48.547501, lng: 19.851747 },
    { lat: 48.547347, lng: 19.852005 },
    { lat: 48.547217, lng: 19.851947 },
    { lat: 48.547089, lng: 19.851896 },
    { lat: 48.546888, lng: 19.852067 },
    { lat: 48.546805, lng: 19.852043 },
    { lat: 48.546525, lng: 19.852308 },
    { lat: 48.546506, lng: 19.852329 },
    { lat: 48.546544, lng: 19.852436 },
    { lat: 48.546508, lng: 19.852626 },
    { lat: 48.546492, lng: 19.85276 },
    { lat: 48.546378, lng: 19.852929 },
    { lat: 48.546311, lng: 19.85304 },
    { lat: 48.546045, lng: 19.853114 },
    { lat: 48.545723, lng: 19.853173 },
    { lat: 48.545579, lng: 19.853214 },
    { lat: 48.545445, lng: 19.853297 },
    { lat: 48.545295, lng: 19.853499 },
    { lat: 48.545222, lng: 19.853676 },
    { lat: 48.54501, lng: 19.853975 },
    { lat: 48.544842, lng: 19.854067 },
    { lat: 48.544854, lng: 19.85423 },
    { lat: 48.54488, lng: 19.854557 },
    { lat: 48.544972, lng: 19.855085 },
    { lat: 48.545056, lng: 19.855308 },
    { lat: 48.545179, lng: 19.855567 },
    { lat: 48.545196, lng: 19.855586 },
    { lat: 48.545237, lng: 19.855635 },
    { lat: 48.545265, lng: 19.855667 },
    { lat: 48.545325, lng: 19.855837 },
    { lat: 48.545451, lng: 19.856369 },
    { lat: 48.545479, lng: 19.856452 },
    { lat: 48.545294, lng: 19.856966 },
    { lat: 48.54533, lng: 19.856992 },
    { lat: 48.545526, lng: 19.857352 },
    { lat: 48.545934, lng: 19.857726 },
    { lat: 48.546367, lng: 19.857852 },
    { lat: 48.546583, lng: 19.857941 },
    { lat: 48.547078, lng: 19.858249 },
    { lat: 48.547268, lng: 19.858355 },
    { lat: 48.547952, lng: 19.858413 },
    { lat: 48.548101, lng: 19.858172 },
    { lat: 48.548926, lng: 19.859388 },
    { lat: 48.549033, lng: 19.859572 },
    { lat: 48.549411, lng: 19.859878 },
    { lat: 48.549816, lng: 19.860292 },
    { lat: 48.550285, lng: 19.860628 },
    { lat: 48.550696, lng: 19.860737 },
    { lat: 48.550705, lng: 19.860719 },
    { lat: 48.550946, lng: 19.861028 },
    { lat: 48.551181, lng: 19.861233 },
    { lat: 48.551619, lng: 19.861724 },
    { lat: 48.551865, lng: 19.861932 },
    { lat: 48.552078, lng: 19.862157 },
    { lat: 48.552398, lng: 19.862396 },
    { lat: 48.553016, lng: 19.863244 },
    { lat: 48.553096, lng: 19.863353 },
    { lat: 48.553179, lng: 19.863559 },
    { lat: 48.553425, lng: 19.863926 },
    { lat: 48.553653, lng: 19.864124 },
    { lat: 48.554042, lng: 19.864628 },
    { lat: 48.554238, lng: 19.86493 },
    { lat: 48.554437, lng: 19.865132 },
    { lat: 48.554724, lng: 19.865423 },
    { lat: 48.554803, lng: 19.865503 },
    { lat: 48.55494, lng: 19.865788 },
    { lat: 48.555143, lng: 19.866203 },
    { lat: 48.55544, lng: 19.866441 },
    { lat: 48.555931, lng: 19.866801 },
    { lat: 48.55649, lng: 19.86766 },
    { lat: 48.556737, lng: 19.867983 },
    { lat: 48.557194, lng: 19.868754 },
    { lat: 48.557257, lng: 19.868859 },
    { lat: 48.557794, lng: 19.869477 },
    { lat: 48.558065, lng: 19.869778 },
    { lat: 48.558541, lng: 19.870107 },
    { lat: 48.559045, lng: 19.870405 },
    { lat: 48.559291, lng: 19.870587 },
    { lat: 48.559565, lng: 19.870928 },
    { lat: 48.559834, lng: 19.871454 },
    { lat: 48.55985, lng: 19.871606 },
    { lat: 48.559947, lng: 19.872554 },
    { lat: 48.559938, lng: 19.87368 },
    { lat: 48.559914, lng: 19.874157 },
    { lat: 48.560124, lng: 19.875656 },
    { lat: 48.55999, lng: 19.87644 },
    { lat: 48.559967, lng: 19.87693 },
    { lat: 48.559842, lng: 19.87749 },
    { lat: 48.559706, lng: 19.878292 },
    { lat: 48.559931, lng: 19.878817 },
    { lat: 48.560248, lng: 19.878954 },
    { lat: 48.561639, lng: 19.879766 },
    { lat: 48.562095, lng: 19.880011 },
    { lat: 48.563126, lng: 19.880475 },
    { lat: 48.56313, lng: 19.880456 },
    { lat: 48.563474, lng: 19.880631 },
    { lat: 48.563851, lng: 19.880914 },
    { lat: 48.564761, lng: 19.881127 },
    { lat: 48.565622, lng: 19.881217 },
    { lat: 48.566114, lng: 19.881051 },
    { lat: 48.566732, lng: 19.880968 },
    { lat: 48.566943, lng: 19.880939 },
    { lat: 48.566941, lng: 19.880919 },
    { lat: 48.567456, lng: 19.880866 },
    { lat: 48.568241, lng: 19.880777 },
    { lat: 48.568711, lng: 19.880777 },
    { lat: 48.568978, lng: 19.880686 },
    { lat: 48.569276, lng: 19.880583 },
    { lat: 48.569821, lng: 19.880011 },
    { lat: 48.570271, lng: 19.879659 },
    { lat: 48.570716, lng: 19.879211 },
    { lat: 48.570706, lng: 19.88261 },
    { lat: 48.570711, lng: 19.882728 },
    { lat: 48.570891, lng: 19.882625 },
    { lat: 48.570993, lng: 19.882601 },
    { lat: 48.571402, lng: 19.882654 },
    { lat: 48.57171, lng: 19.882656 },
    { lat: 48.57234, lng: 19.88293 },
    { lat: 48.573046, lng: 19.882507 },
    { lat: 48.57311, lng: 19.882474 },
    { lat: 48.573221, lng: 19.882417 },
    { lat: 48.573413, lng: 19.882345 },
    { lat: 48.573627, lng: 19.882315 },
    { lat: 48.57471, lng: 19.882068 },
    { lat: 48.575589, lng: 19.881452 },
    { lat: 48.57576, lng: 19.881336 },
    { lat: 48.575812, lng: 19.881301 },
    { lat: 48.576142, lng: 19.881186 },
    { lat: 48.576358, lng: 19.881165 },
    { lat: 48.57676, lng: 19.88134 },
    { lat: 48.577141, lng: 19.881579 },
    { lat: 48.577458, lng: 19.881748 },
    { lat: 48.578509, lng: 19.881696 },
    { lat: 48.578811, lng: 19.881683 },
    { lat: 48.579719, lng: 19.881428 },
    { lat: 48.58029, lng: 19.880649 },
    { lat: 48.580528, lng: 19.880335 },
    { lat: 48.580878, lng: 19.879853 },
    { lat: 48.581072, lng: 19.879417 },
    { lat: 48.581253, lng: 19.879026 },
    { lat: 48.581524, lng: 19.878421 },
    { lat: 48.581927, lng: 19.876863 },
    { lat: 48.582563, lng: 19.875385 },
    { lat: 48.582787, lng: 19.875165 },
    { lat: 48.583141, lng: 19.874879 },
    { lat: 48.583867, lng: 19.874406 },
    { lat: 48.583942, lng: 19.874403 },
    { lat: 48.584265, lng: 19.874303 },
    { lat: 48.584414, lng: 19.874166 },
    { lat: 48.584616, lng: 19.873983 },
    { lat: 48.58487, lng: 19.873851 },
    { lat: 48.585523, lng: 19.87328 },
    { lat: 48.585919, lng: 19.872742 },
    { lat: 48.586276, lng: 19.872487 },
    { lat: 48.586671, lng: 19.872314 },
    { lat: 48.587147, lng: 19.871852 },
    { lat: 48.587782, lng: 19.87121 },
    { lat: 48.588262, lng: 19.870827 },
    { lat: 48.588519, lng: 19.870614 },
    { lat: 48.588753, lng: 19.870285 },
    { lat: 48.589033, lng: 19.869661 },
    { lat: 48.589351, lng: 19.869064 },
    { lat: 48.589533, lng: 19.868651 },
    { lat: 48.589683, lng: 19.868062 },
    { lat: 48.590129, lng: 19.867212 },
    { lat: 48.590504, lng: 19.866598 },
    { lat: 48.590843, lng: 19.866031 },
    { lat: 48.591379, lng: 19.864997 },
    { lat: 48.592139, lng: 19.864421 },
    { lat: 48.592933, lng: 19.863565 },
    { lat: 48.59301, lng: 19.863415 },
    { lat: 48.593054, lng: 19.863329 },
    { lat: 48.593173, lng: 19.862754 },
    { lat: 48.593229, lng: 19.862614 },
    { lat: 48.593577, lng: 19.862081 },
    { lat: 48.593702, lng: 19.861896 },
    { lat: 48.593979, lng: 19.861221 },
    { lat: 48.594288, lng: 19.860606 },
    { lat: 48.594265, lng: 19.860567 },
    { lat: 48.594415, lng: 19.860209 },
    { lat: 48.594458, lng: 19.860093 },
    { lat: 48.594498, lng: 19.859986 },
    { lat: 48.594557, lng: 19.859825 },
    { lat: 48.594575, lng: 19.859641 },
    { lat: 48.594674, lng: 19.858669 },
    { lat: 48.594816, lng: 19.857534 },
    { lat: 48.594988, lng: 19.855373 },
    { lat: 48.59535, lng: 19.854229 },
    { lat: 48.595394, lng: 19.853249 },
    { lat: 48.595444, lng: 19.851644 },
    { lat: 48.595312, lng: 19.850599 },
    { lat: 48.595172, lng: 19.849473 },
    { lat: 48.595086, lng: 19.848448 },
    { lat: 48.594953, lng: 19.846824 },
    { lat: 48.594942, lng: 19.846766 },
    { lat: 48.594815, lng: 19.846022 },
    { lat: 48.594763, lng: 19.845382 },
    { lat: 48.595064, lng: 19.844543 },
    { lat: 48.595691, lng: 19.842205 },
    { lat: 48.59642, lng: 19.841544 },
    { lat: 48.596679, lng: 19.841287 },
    { lat: 48.596705, lng: 19.841263 },
    { lat: 48.597693, lng: 19.840281 },
    { lat: 48.598569, lng: 19.839424 },
    { lat: 48.598961, lng: 19.839636 },
    { lat: 48.60029, lng: 19.839899 },
    { lat: 48.600327, lng: 19.839832 },
    { lat: 48.600376, lng: 19.839748 },
    { lat: 48.600983, lng: 19.83867 },
    { lat: 48.601537, lng: 19.837723 },
    { lat: 48.60174, lng: 19.836915 },
    { lat: 48.601937, lng: 19.836125 },
    { lat: 48.602202, lng: 19.834918 },
    { lat: 48.602298, lng: 19.834488 },
    { lat: 48.602473, lng: 19.833773 },
    { lat: 48.603016, lng: 19.833628 },
    { lat: 48.603273, lng: 19.833176 },
    { lat: 48.60337, lng: 19.833006 },
    { lat: 48.603435, lng: 19.832893 },
    { lat: 48.603534, lng: 19.832722 },
    { lat: 48.603688, lng: 19.832209 },
    { lat: 48.6037547, lng: 19.8300247 },
    { lat: 48.6036298, lng: 19.8292728 },
    { lat: 48.6032914, lng: 19.8288199 },
    { lat: 48.6031257, lng: 19.827883 },
    { lat: 48.6027105, lng: 19.8263414 },
    { lat: 48.6025152, lng: 19.8257836 },
    { lat: 48.6016336, lng: 19.8241628 },
    { lat: 48.6013018, lng: 19.8221777 },
    { lat: 48.6007331, lng: 19.8208891 },
    { lat: 48.5995055, lng: 19.8189516 },
    { lat: 48.59911, lng: 19.8181995 },
    { lat: 48.5990403, lng: 19.8191378 },
    { lat: 48.5978484, lng: 19.8190851 },
    { lat: 48.5975122, lng: 19.8189988 },
    { lat: 48.5973555, lng: 19.8185544 },
    { lat: 48.5966313, lng: 19.8182213 },
    { lat: 48.5966523, lng: 19.8174611 },
    { lat: 48.5967852, lng: 19.8160649 },
    { lat: 48.596137, lng: 19.8132567 },
    { lat: 48.5960457, lng: 19.812066 },
    { lat: 48.5952003, lng: 19.8108132 },
    { lat: 48.5943435, lng: 19.8116803 },
    { lat: 48.5942973, lng: 19.8115514 },
    { lat: 48.5944148, lng: 19.8109643 },
    { lat: 48.5945883, lng: 19.8105997 },
    { lat: 48.5945313, lng: 19.8101754 },
    { lat: 48.594205, lng: 19.8100284 },
    { lat: 48.5909034, lng: 19.8030361 },
    { lat: 48.5917976, lng: 19.8026937 },
    { lat: 48.5923003, lng: 19.8021058 },
    { lat: 48.5930128, lng: 19.8009539 },
    { lat: 48.5949048, lng: 19.7994117 },
    { lat: 48.5957852, lng: 19.7982875 },
    { lat: 48.596339, lng: 19.7980966 },
    { lat: 48.5967273, lng: 19.7974683 },
    { lat: 48.5966533, lng: 19.795234 },
    { lat: 48.5970647, lng: 19.7936855 },
    { lat: 48.5974643, lng: 19.7924947 },
    { lat: 48.5981314, lng: 19.7929367 },
    { lat: 48.5984998, lng: 19.7923167 },
    { lat: 48.5989409, lng: 19.7911955 },
    { lat: 48.5995009, lng: 19.7903215 },
    { lat: 48.5999268, lng: 19.790193 },
    { lat: 48.6002867, lng: 19.7902042 },
    { lat: 48.6008474, lng: 19.79035 },
    { lat: 48.6009549, lng: 19.7903094 },
    { lat: 48.6010332, lng: 19.7901944 },
    { lat: 48.6012564, lng: 19.7892955 },
    { lat: 48.6019821, lng: 19.7873396 },
    { lat: 48.6026391, lng: 19.7843339 },
    { lat: 48.6026998, lng: 19.783763 },
    { lat: 48.6021815, lng: 19.7819329 },
    { lat: 48.60205, lng: 19.780834 },
    { lat: 48.6023569, lng: 19.7801 },
    { lat: 48.6029671, lng: 19.7793707 },
    { lat: 48.6030828, lng: 19.7791296 },
    { lat: 48.6035174, lng: 19.7785854 },
    { lat: 48.6038289, lng: 19.7783176 },
    { lat: 48.603922, lng: 19.7776619 },
    { lat: 48.6038941, lng: 19.7770997 },
    { lat: 48.6036668, lng: 19.7751307 },
    { lat: 48.6032221, lng: 19.7725458 },
    { lat: 48.602851, lng: 19.7715611 },
    { lat: 48.6028298, lng: 19.7711265 },
    { lat: 48.6027694, lng: 19.7708805 },
    { lat: 48.6026936, lng: 19.7694602 },
    { lat: 48.6016971, lng: 19.7673687 },
    { lat: 48.601329, lng: 19.7664824 },
    { lat: 48.6010974, lng: 19.7654387 },
    { lat: 48.6009646, lng: 19.7617041 },
    { lat: 48.6006922, lng: 19.7571767 },
    { lat: 48.6017423, lng: 19.7565907 },
    { lat: 48.6015427, lng: 19.7561746 },
    { lat: 48.6009146, lng: 19.7536788 },
    { lat: 48.6059857, lng: 19.7504059 },
    { lat: 48.6059862, lng: 19.7493403 },
    { lat: 48.6059349, lng: 19.7490236 },
    { lat: 48.6059461, lng: 19.7488431 },
    { lat: 48.605984, lng: 19.7488831 },
    { lat: 48.6060355, lng: 19.7483122 },
    { lat: 48.6062868, lng: 19.7476227 },
    { lat: 48.6066125, lng: 19.7472935 },
    { lat: 48.6067192, lng: 19.7470852 },
    { lat: 48.6068161, lng: 19.7470338 },
    { lat: 48.6071213, lng: 19.746341 },
    { lat: 48.6071284, lng: 19.7459398 },
    { lat: 48.6073191, lng: 19.7452329 },
    { lat: 48.6076065, lng: 19.7446184 },
    { lat: 48.6082453, lng: 19.7417862 },
    { lat: 48.6112576, lng: 19.7426687 },
    { lat: 48.615109, lng: 19.7440041 },
    { lat: 48.6158313, lng: 19.7427266 },
    { lat: 48.6166403, lng: 19.7411744 },
    { lat: 48.6168817, lng: 19.7404911 },
    { lat: 48.6171379, lng: 19.7401933 },
    { lat: 48.6172396, lng: 19.7398922 },
    { lat: 48.6172842, lng: 19.7393258 },
    { lat: 48.617408, lng: 19.7387707 },
    { lat: 48.617431, lng: 19.7386676 },
    { lat: 48.6174316, lng: 19.7380854 },
    { lat: 48.6174321, lng: 19.7375956 },
    { lat: 48.617459, lng: 19.7373954 },
    { lat: 48.6175822, lng: 19.7364774 },
    { lat: 48.6178404, lng: 19.7353003 },
    { lat: 48.6179225, lng: 19.7349258 },
    { lat: 48.6179955, lng: 19.7346973 },
    { lat: 48.6180956, lng: 19.7343839 },
    { lat: 48.6181986, lng: 19.7340613 },
    { lat: 48.6181085, lng: 19.7336804 },
    { lat: 48.6180733, lng: 19.732397 },
    { lat: 48.6181362, lng: 19.7316041 },
    { lat: 48.6181265, lng: 19.7313834 },
    { lat: 48.6181222, lng: 19.7312871 },
    { lat: 48.6181497, lng: 19.7311261 },
    { lat: 48.6182124, lng: 19.7307596 },
    { lat: 48.6184167, lng: 19.7295653 },
    { lat: 48.6184822, lng: 19.7293752 },
    { lat: 48.6186636, lng: 19.7288489 },
    { lat: 48.6190109, lng: 19.7278414 },
    { lat: 48.6190568, lng: 19.7275147 },
    { lat: 48.6190657, lng: 19.7274513 },
    { lat: 48.6189961, lng: 19.7269252 },
    { lat: 48.6190453, lng: 19.7266425 },
    { lat: 48.6191147, lng: 19.7262434 },
    { lat: 48.6191361, lng: 19.7261927 },
    { lat: 48.6194004, lng: 19.725568 },
    { lat: 48.6195286, lng: 19.7252648 },
    { lat: 48.6195481, lng: 19.7252187 },
    { lat: 48.6197653, lng: 19.7249031 },
    { lat: 48.6198901, lng: 19.7247217 },
    { lat: 48.6199245, lng: 19.7246313 },
    { lat: 48.6200375, lng: 19.724334 },
    { lat: 48.6200618, lng: 19.7242966 },
    { lat: 48.6202411, lng: 19.7240207 },
    { lat: 48.6204087, lng: 19.7235836 },
    { lat: 48.6205857, lng: 19.7228518 },
    { lat: 48.6205866, lng: 19.7216509 },
    { lat: 48.620512, lng: 19.7211605 },
    { lat: 48.6204095, lng: 19.7208023 },
    { lat: 48.6200669, lng: 19.7202959 },
    { lat: 48.6199095, lng: 19.7195892 },
    { lat: 48.6197959, lng: 19.7193934 },
    { lat: 48.619791, lng: 19.7191805 },
    { lat: 48.6197826, lng: 19.71881 },
    { lat: 48.6198217, lng: 19.7183475 },
    { lat: 48.6198736, lng: 19.7177334 },
    { lat: 48.6198794, lng: 19.7176653 },
    { lat: 48.619956, lng: 19.7175137 },
    { lat: 48.61997, lng: 19.7165558 },
    { lat: 48.620173, lng: 19.7168166 },
    { lat: 48.6207877, lng: 19.7171876 },
    { lat: 48.6213739, lng: 19.717272 },
    { lat: 48.6214095, lng: 19.7181274 },
    { lat: 48.6215372, lng: 19.7185625 },
    { lat: 48.6218282, lng: 19.718395 },
    { lat: 48.6222063, lng: 19.7196001 },
    { lat: 48.6222753, lng: 19.720498 },
    { lat: 48.6223494, lng: 19.7207463 },
    { lat: 48.6236172, lng: 19.7211886 },
    { lat: 48.6235684, lng: 19.7207206 },
    { lat: 48.6233377, lng: 19.7196865 },
    { lat: 48.6226412, lng: 19.7173443 },
    { lat: 48.6227283, lng: 19.7159544 },
    { lat: 48.62329, lng: 19.7158368 },
    { lat: 48.623174, lng: 19.7146494 },
    { lat: 48.6232333, lng: 19.713341 },
    { lat: 48.6239489, lng: 19.7132933 },
    { lat: 48.6244313, lng: 19.7131452 },
    { lat: 48.6243944, lng: 19.7123672 },
    { lat: 48.6277026, lng: 19.7095043 },
    { lat: 48.6277141, lng: 19.7102422 },
    { lat: 48.6278057, lng: 19.7111889 },
    { lat: 48.6279164, lng: 19.7112977 },
    { lat: 48.6280276, lng: 19.7116174 },
    { lat: 48.6280632, lng: 19.7124198 },
    { lat: 48.628241, lng: 19.7137136 },
    { lat: 48.6282389, lng: 19.7141715 },
    { lat: 48.6281755, lng: 19.7143713 },
    { lat: 48.6283097, lng: 19.714622 },
    { lat: 48.628356, lng: 19.7150052 },
    { lat: 48.6284584, lng: 19.7151327 },
    { lat: 48.6285245, lng: 19.7156069 },
    { lat: 48.6286263, lng: 19.7157655 },
    { lat: 48.6287178, lng: 19.7165085 },
    { lat: 48.6289585, lng: 19.7171623 },
    { lat: 48.6290266, lng: 19.7172221 },
    { lat: 48.6290072, lng: 19.7173181 },
    { lat: 48.6292222, lng: 19.7180237 },
    { lat: 48.6294741, lng: 19.7183965 },
    { lat: 48.6295677, lng: 19.7187817 },
    { lat: 48.6305572, lng: 19.7200813 },
    { lat: 48.6306675, lng: 19.7204613 },
    { lat: 48.6308673, lng: 19.720798 },
    { lat: 48.6310133, lng: 19.7211673 },
    { lat: 48.6313504, lng: 19.7225987 },
    { lat: 48.6312984, lng: 19.7230025 },
    { lat: 48.6313591, lng: 19.7230993 },
    { lat: 48.6313408, lng: 19.7237309 },
    { lat: 48.6315764, lng: 19.7247227 },
    { lat: 48.6315664, lng: 19.7253128 },
    { lat: 48.631721, lng: 19.7255469 },
    { lat: 48.6316955, lng: 19.7255841 },
    { lat: 48.6318544, lng: 19.7260158 },
    { lat: 48.6318359, lng: 19.726184 },
    { lat: 48.6319334, lng: 19.7265051 },
    { lat: 48.6322094, lng: 19.7269578 },
    { lat: 48.6322932, lng: 19.7273996 },
    { lat: 48.6322401, lng: 19.7275292 },
    { lat: 48.6323425, lng: 19.7286965 },
    { lat: 48.6324149, lng: 19.7291117 },
    { lat: 48.6324959, lng: 19.7292603 },
    { lat: 48.6325009, lng: 19.7294756 },
    { lat: 48.6326006, lng: 19.7297438 },
    { lat: 48.6326664, lng: 19.730259 },
    { lat: 48.632809, lng: 19.7307344 },
    { lat: 48.6328378, lng: 19.7310549 },
    { lat: 48.6329086, lng: 19.7311778 },
    { lat: 48.6329592, lng: 19.7314559 },
    { lat: 48.6330259, lng: 19.73154 },
    { lat: 48.6331553, lng: 19.7323986 },
    { lat: 48.6330976, lng: 19.7325191 },
    { lat: 48.6331929, lng: 19.7330944 },
    { lat: 48.6332869, lng: 19.733288 },
    { lat: 48.6333464, lng: 19.7338918 },
    { lat: 48.6334165, lng: 19.7341523 },
    { lat: 48.6334759, lng: 19.7342478 },
    { lat: 48.6336221, lng: 19.7342969 },
    { lat: 48.6337275, lng: 19.7345385 },
    { lat: 48.6340071, lng: 19.7349253 },
    { lat: 48.6340769, lng: 19.735152 },
    { lat: 48.6341917, lng: 19.7353798 },
    { lat: 48.6343373, lng: 19.7355376 },
    { lat: 48.6344962, lng: 19.735922 },
    { lat: 48.6348428, lng: 19.7361148 },
    { lat: 48.6350992, lng: 19.7361039 },
    { lat: 48.6352015, lng: 19.7360253 },
    { lat: 48.6352419, lng: 19.7356101 },
    { lat: 48.6355716, lng: 19.7350401 },
    { lat: 48.6356147, lng: 19.7346379 },
    { lat: 48.6355391, lng: 19.7340271 },
    { lat: 48.6353044, lng: 19.7335093 },
    { lat: 48.6352062, lng: 19.7331715 },
    { lat: 48.6352564, lng: 19.7328017 },
    { lat: 48.6353879, lng: 19.7323485 },
    { lat: 48.6353941, lng: 19.7323272 },
    { lat: 48.6353429, lng: 19.7318048 },
    { lat: 48.6358384, lng: 19.7312863 },
    { lat: 48.6358619, lng: 19.7312617 },
    { lat: 48.6360627, lng: 19.7311364 },
    { lat: 48.6363921, lng: 19.7303295 },
    { lat: 48.6365623, lng: 19.7295935 },
    { lat: 48.6365798, lng: 19.7289217 },
    { lat: 48.6366412, lng: 19.7285475 },
    { lat: 48.6367834, lng: 19.7280488 },
    { lat: 48.6371879, lng: 19.7274355 },
    { lat: 48.6373342, lng: 19.7270493 },
    { lat: 48.6374091, lng: 19.7266559 },
    { lat: 48.637497, lng: 19.726579 },
    { lat: 48.6378117, lng: 19.7268437 },
    { lat: 48.638021, lng: 19.7267353 },
    { lat: 48.6381704, lng: 19.7267499 },
    { lat: 48.6383992, lng: 19.7266491 },
    { lat: 48.6385658, lng: 19.726466 },
    { lat: 48.6387406, lng: 19.7261164 },
    { lat: 48.6389949, lng: 19.7243035 },
    { lat: 48.6391595, lng: 19.7239449 },
    { lat: 48.639173, lng: 19.7237176 },
    { lat: 48.6391452, lng: 19.7233186 },
    { lat: 48.6391733, lng: 19.7225256 },
    { lat: 48.6389606, lng: 19.7220248 },
    { lat: 48.6389865, lng: 19.7216431 },
    { lat: 48.6388715, lng: 19.7212878 },
    { lat: 48.6388883, lng: 19.7211059 },
    { lat: 48.6390266, lng: 19.7208429 },
    { lat: 48.639161, lng: 19.7203343 },
    { lat: 48.639235, lng: 19.7200541 },
    { lat: 48.6392978, lng: 19.7191315 },
    { lat: 48.6394035, lng: 19.7189485 },
    { lat: 48.6396016, lng: 19.7189164 },
    { lat: 48.6397375, lng: 19.7188093 },
    { lat: 48.6398604, lng: 19.7184275 },
    { lat: 48.639917, lng: 19.718388 },
    { lat: 48.639915, lng: 19.718312 },
    { lat: 48.639866, lng: 19.718237 },
    { lat: 48.639837, lng: 19.718191 },
    { lat: 48.639824, lng: 19.717978 },
    { lat: 48.639816, lng: 19.717791 },
    { lat: 48.639815, lng: 19.717772 },
    { lat: 48.63967, lng: 19.717486 },
    { lat: 48.63945, lng: 19.717348 },
    { lat: 48.63937, lng: 19.717211 },
    { lat: 48.639267, lng: 19.716631 },
    { lat: 48.639108, lng: 19.716319 },
    { lat: 48.63886, lng: 19.715716 },
    { lat: 48.638682, lng: 19.71544 },
    { lat: 48.638373, lng: 19.7151 },
    { lat: 48.63826, lng: 19.714675 },
    { lat: 48.638146, lng: 19.714362 },
    { lat: 48.638039, lng: 19.71419 },
    { lat: 48.6379, lng: 19.713824 },
    { lat: 48.637892, lng: 19.713606 },
    { lat: 48.637654, lng: 19.713265 },
    { lat: 48.637663, lng: 19.712846 },
    { lat: 48.637568, lng: 19.712624 },
    { lat: 48.63755, lng: 19.711802 },
    { lat: 48.637593, lng: 19.711545 },
    { lat: 48.637551, lng: 19.71135 },
    { lat: 48.637557, lng: 19.710485 },
    { lat: 48.637596, lng: 19.710305 },
    { lat: 48.637588, lng: 19.70983 },
    { lat: 48.637605, lng: 19.709676 },
    { lat: 48.637502, lng: 19.709429 },
    { lat: 48.63746, lng: 19.709055 },
    { lat: 48.637291, lng: 19.708988 },
    { lat: 48.63711, lng: 19.70871 },
    { lat: 48.637037, lng: 19.708273 },
    { lat: 48.636883, lng: 19.708076 },
    { lat: 48.636883, lng: 19.707858 },
    { lat: 48.637044, lng: 19.707138 },
    { lat: 48.637172, lng: 19.706887 },
    { lat: 48.637189, lng: 19.706563 },
    { lat: 48.637124, lng: 19.706326 },
    { lat: 48.637102, lng: 19.706093 },
    { lat: 48.63691, lng: 19.705831 },
    { lat: 48.636919, lng: 19.705671 },
    { lat: 48.636883, lng: 19.705494 },
    { lat: 48.636888, lng: 19.705298 },
    { lat: 48.636787, lng: 19.704902 },
    { lat: 48.636708, lng: 19.704701 },
    { lat: 48.636483, lng: 19.704407 },
    { lat: 48.636475, lng: 19.704139 },
    { lat: 48.636422, lng: 19.703939 },
    { lat: 48.636348, lng: 19.703725 },
    { lat: 48.63632, lng: 19.703325 },
    { lat: 48.63623, lng: 19.70302 },
    { lat: 48.636113, lng: 19.702807 },
    { lat: 48.636098, lng: 19.702757 },
    { lat: 48.636107, lng: 19.702547 },
    { lat: 48.63615, lng: 19.702381 },
    { lat: 48.635993, lng: 19.701853 },
    { lat: 48.635906, lng: 19.701709 },
    { lat: 48.635736, lng: 19.701581 },
    { lat: 48.635567, lng: 19.701374 },
    { lat: 48.635547, lng: 19.701339 },
    { lat: 48.635507, lng: 19.701011 },
    { lat: 48.63541, lng: 19.700824 },
    { lat: 48.635272, lng: 19.700729 },
    { lat: 48.635179, lng: 19.700594 },
    { lat: 48.635035, lng: 19.700433 },
    { lat: 48.63489, lng: 19.700031 },
    { lat: 48.63468, lng: 19.699679 },
    { lat: 48.634505, lng: 19.699557 },
    { lat: 48.634361, lng: 19.699236 },
    { lat: 48.634188, lng: 19.698736 },
    { lat: 48.634139, lng: 19.698666 },
    { lat: 48.633978, lng: 19.698568 },
    { lat: 48.633854, lng: 19.698193 },
    { lat: 48.633824, lng: 19.69802 },
    { lat: 48.633707, lng: 19.697696 },
    { lat: 48.633637, lng: 19.69761 },
    { lat: 48.633424, lng: 19.697246 },
    { lat: 48.633415, lng: 19.697219 },
    { lat: 48.633412, lng: 19.697048 },
    { lat: 48.633371, lng: 19.69686 },
    { lat: 48.633331, lng: 19.696824 },
    { lat: 48.633309, lng: 19.696712 },
    { lat: 48.633243, lng: 19.696638 },
    { lat: 48.633057, lng: 19.696379 },
    { lat: 48.632961, lng: 19.696327 },
    { lat: 48.632816, lng: 19.696326 },
    { lat: 48.632766, lng: 19.696268 },
    { lat: 48.632696, lng: 19.695997 },
    { lat: 48.632425, lng: 19.695961 },
    { lat: 48.632249, lng: 19.695977 },
    { lat: 48.632162, lng: 19.695934 },
    { lat: 48.632071, lng: 19.695988 },
    { lat: 48.632053, lng: 19.695985 },
    { lat: 48.631908, lng: 19.695775 },
    { lat: 48.631788, lng: 19.695759 },
    { lat: 48.63171, lng: 19.695798 },
    { lat: 48.631505, lng: 19.695675 },
    { lat: 48.631495, lng: 19.695659 },
    { lat: 48.631477, lng: 19.695529 },
    { lat: 48.631343, lng: 19.695341 },
    { lat: 48.631234, lng: 19.695219 },
    { lat: 48.631217, lng: 19.695209 },
    { lat: 48.631086, lng: 19.695112 },
    { lat: 48.630937, lng: 19.694954 },
    { lat: 48.630762, lng: 19.694863 },
    { lat: 48.630724, lng: 19.69483 },
    { lat: 48.630704, lng: 19.694518 },
    { lat: 48.630632, lng: 19.694399 },
    { lat: 48.630462, lng: 19.694302 },
    { lat: 48.630421, lng: 19.694222 },
    { lat: 48.630398, lng: 19.694085 },
    { lat: 48.630316, lng: 19.693905 },
    { lat: 48.630286, lng: 19.69369 },
    { lat: 48.630208, lng: 19.693528 },
    { lat: 48.630076, lng: 19.693434 },
    { lat: 48.62999, lng: 19.693328 },
    { lat: 48.629918, lng: 19.693207 },
    { lat: 48.629908, lng: 19.69309 },
    { lat: 48.629777, lng: 19.692874 },
    { lat: 48.629725, lng: 19.692826 },
    { lat: 48.629647, lng: 19.692675 },
    { lat: 48.629575, lng: 19.692601 },
    { lat: 48.629488, lng: 19.692373 },
    { lat: 48.629427, lng: 19.692211 },
    { lat: 48.629433, lng: 19.691947 },
    { lat: 48.62941, lng: 19.691738 },
    { lat: 48.629257, lng: 19.691352 },
    { lat: 48.629237, lng: 19.691014 },
    { lat: 48.629208, lng: 19.690907 },
    { lat: 48.629265, lng: 19.690575 },
    { lat: 48.629202, lng: 19.690385 },
    { lat: 48.629148, lng: 19.690182 },
    { lat: 48.629121, lng: 19.689998 },
    { lat: 48.629057, lng: 19.689765 },
    { lat: 48.629051, lng: 19.689635 },
    { lat: 48.62903, lng: 19.689597 },
    { lat: 48.628941, lng: 19.689558 },
    { lat: 48.628872, lng: 19.68947 },
    { lat: 48.628722, lng: 19.689389 },
    { lat: 48.62865, lng: 19.689136 },
    { lat: 48.62846, lng: 19.688723 },
    { lat: 48.628446, lng: 19.688542 },
    { lat: 48.628379, lng: 19.688455 },
    { lat: 48.628293, lng: 19.688449 },
    { lat: 48.628191, lng: 19.688353 },
    { lat: 48.628142, lng: 19.688244 },
    { lat: 48.628078, lng: 19.688084 },
    { lat: 48.627975, lng: 19.687981 },
    { lat: 48.627938, lng: 19.68791 },
    { lat: 48.627874, lng: 19.68784 },
    { lat: 48.627689, lng: 19.687812 },
    { lat: 48.627675, lng: 19.68775 },
    { lat: 48.627704, lng: 19.687511 },
    { lat: 48.627681, lng: 19.687297 },
    { lat: 48.627725, lng: 19.687101 },
    { lat: 48.627641, lng: 19.687026 },
    { lat: 48.627605, lng: 19.68693 },
    { lat: 48.627533, lng: 19.686876 },
    { lat: 48.627534, lng: 19.686481 },
    { lat: 48.627494, lng: 19.686416 },
    { lat: 48.62737, lng: 19.686406 },
    { lat: 48.627345, lng: 19.686257 },
    { lat: 48.62741, lng: 19.686108 },
    { lat: 48.627334, lng: 19.685962 },
    { lat: 48.627357, lng: 19.685815 },
    { lat: 48.627337, lng: 19.68568 },
    { lat: 48.627117, lng: 19.685549 },
    { lat: 48.627072, lng: 19.685438 },
    { lat: 48.627, lng: 19.685443 },
    { lat: 48.626948, lng: 19.685391 },
    { lat: 48.626877, lng: 19.685213 },
    { lat: 48.626905, lng: 19.685093 },
    { lat: 48.62672, lng: 19.684948 },
    { lat: 48.626596, lng: 19.684758 },
    { lat: 48.626551, lng: 19.684636 },
    { lat: 48.626505, lng: 19.684583 },
    { lat: 48.626417, lng: 19.684374 },
    { lat: 48.626259, lng: 19.684349 },
    { lat: 48.625912, lng: 19.684152 },
    { lat: 48.625847, lng: 19.683964 },
    { lat: 48.625759, lng: 19.683773 },
    { lat: 48.625694, lng: 19.683723 },
    { lat: 48.62567, lng: 19.683456 },
    { lat: 48.625543, lng: 19.683415 },
    { lat: 48.625507, lng: 19.683251 },
    { lat: 48.625485, lng: 19.68301 },
    { lat: 48.625431, lng: 19.682956 },
    { lat: 48.625391, lng: 19.682704 },
    { lat: 48.625278, lng: 19.682469 },
    { lat: 48.625254, lng: 19.682355 },
    { lat: 48.625324, lng: 19.682148 },
    { lat: 48.625361, lng: 19.681973 },
    { lat: 48.625297, lng: 19.681723 },
    { lat: 48.625262, lng: 19.681594 },
    { lat: 48.625229, lng: 19.681406 },
    { lat: 48.625244, lng: 19.681195 },
    { lat: 48.625205, lng: 19.68105 },
    { lat: 48.625044, lng: 19.680827 },
    { lat: 48.624685, lng: 19.680284 },
    { lat: 48.624317, lng: 19.679746 },
    { lat: 48.624049, lng: 19.679327 },
    { lat: 48.623712, lng: 19.678923 },
    { lat: 48.623388, lng: 19.678431 },
    { lat: 48.623154, lng: 19.678065 },
    { lat: 48.622926, lng: 19.677741 },
    { lat: 48.622394, lng: 19.677251 },
    { lat: 48.622027, lng: 19.676647 },
    { lat: 48.621775, lng: 19.676276 },
    { lat: 48.621502, lng: 19.675848 },
    { lat: 48.621308, lng: 19.675398 },
    { lat: 48.62112, lng: 19.674958 },
    { lat: 48.620837, lng: 19.674465 },
    { lat: 48.620535, lng: 19.673937 },
    { lat: 48.620275, lng: 19.673636 },
    { lat: 48.620006, lng: 19.673311 },
    { lat: 48.61985, lng: 19.673142 },
    { lat: 48.619497, lng: 19.672707 },
    { lat: 48.619438, lng: 19.672635 },
    { lat: 48.619342, lng: 19.672516 },
    { lat: 48.619304, lng: 19.672469 },
    { lat: 48.619159, lng: 19.672291 },
    { lat: 48.619098, lng: 19.672311 },
    { lat: 48.618863, lng: 19.672386 },
    { lat: 48.618678, lng: 19.672444 },
    { lat: 48.617703, lng: 19.672849 },
    { lat: 48.616978, lng: 19.673341 },
    { lat: 48.616733, lng: 19.673709 },
    { lat: 48.616435, lng: 19.674106 },
    { lat: 48.615991, lng: 19.674462 },
    { lat: 48.615948, lng: 19.674497 },
    { lat: 48.615668, lng: 19.674941 },
    { lat: 48.615537, lng: 19.675208 },
    { lat: 48.615485, lng: 19.675315 },
    { lat: 48.615456, lng: 19.675679 },
    { lat: 48.615493, lng: 19.676205 },
    { lat: 48.615497, lng: 19.67626 },
    { lat: 48.615507, lng: 19.676415 },
    { lat: 48.615393, lng: 19.676676 },
    { lat: 48.615329, lng: 19.676819 },
    { lat: 48.61489, lng: 19.677741 },
    { lat: 48.614823, lng: 19.678252 },
    { lat: 48.614683, lng: 19.678672 },
    { lat: 48.614686, lng: 19.678711 },
    { lat: 48.61466, lng: 19.679085 },
    { lat: 48.61451, lng: 19.679492 },
    { lat: 48.614273, lng: 19.680023 },
    { lat: 48.61393, lng: 19.68105 },
    { lat: 48.613851, lng: 19.681665 },
    { lat: 48.613878, lng: 19.6826 },
    { lat: 48.614021, lng: 19.683388 },
    { lat: 48.614534, lng: 19.684796 },
    { lat: 48.614414, lng: 19.68538 },
    { lat: 48.614448, lng: 19.686132 },
    { lat: 48.61439, lng: 19.686811 },
    { lat: 48.614361, lng: 19.688166 },
    { lat: 48.614317, lng: 19.68905 },
    { lat: 48.614292, lng: 19.689552 },
    { lat: 48.614279, lng: 19.689809 },
    { lat: 48.613865, lng: 19.69037 },
    { lat: 48.613475, lng: 19.690956 },
    { lat: 48.613211, lng: 19.691033 },
    { lat: 48.613263, lng: 19.691074 },
    { lat: 48.612977, lng: 19.691385 },
    { lat: 48.612597, lng: 19.691628 },
    { lat: 48.612207, lng: 19.69202 },
    { lat: 48.612182, lng: 19.691958 },
    { lat: 48.611662, lng: 19.692781 },
    { lat: 48.611417, lng: 19.693036 },
    { lat: 48.611036, lng: 19.693463 },
    { lat: 48.610606, lng: 19.694055 },
    { lat: 48.610295, lng: 19.694537 },
    { lat: 48.609868, lng: 19.695271 },
    { lat: 48.609787, lng: 19.69541 },
    { lat: 48.609757, lng: 19.695462 },
    { lat: 48.609508, lng: 19.695891 },
    { lat: 48.609348, lng: 19.696354 },
    { lat: 48.609203, lng: 19.696773 },
    { lat: 48.609137, lng: 19.696961 },
    { lat: 48.608935, lng: 19.69753 },
    { lat: 48.608668, lng: 19.698244 },
    { lat: 48.608402, lng: 19.698946 },
    { lat: 48.608165, lng: 19.699415 },
    { lat: 48.607833, lng: 19.699982 },
    { lat: 48.607528, lng: 19.700639 },
    { lat: 48.607132, lng: 19.701093 },
    { lat: 48.606619, lng: 19.70151 },
    { lat: 48.606379, lng: 19.701585 },
    { lat: 48.605998, lng: 19.701743 },
    { lat: 48.605571, lng: 19.702208 },
    { lat: 48.6055126, lng: 19.7022703 },
    { lat: 48.605432, lng: 19.702358 },
    { lat: 48.605008, lng: 19.702887 },
    { lat: 48.604539, lng: 19.703236 },
    { lat: 48.603856, lng: 19.703699 },
    { lat: 48.603345, lng: 19.704108 },
    { lat: 48.603104, lng: 19.704302 },
    { lat: 48.603094, lng: 19.704311 },
    { lat: 48.602668, lng: 19.70469 },
    { lat: 48.602223, lng: 19.7058 },
    { lat: 48.602105, lng: 19.706661 },
    { lat: 48.602006, lng: 19.707607 },
    { lat: 48.601955, lng: 19.708129 },
    { lat: 48.601818, lng: 19.708695 },
    { lat: 48.601593, lng: 19.7097 },
    { lat: 48.601447, lng: 19.710472 },
    { lat: 48.601334, lng: 19.710973 },
    { lat: 48.601193, lng: 19.711545 },
    { lat: 48.601074, lng: 19.712268 },
    { lat: 48.600952, lng: 19.712802 },
    { lat: 48.60083, lng: 19.713228 },
    { lat: 48.600721, lng: 19.713701 },
    { lat: 48.600456, lng: 19.714273 },
    { lat: 48.600228, lng: 19.714774 },
    { lat: 48.600065, lng: 19.715166 },
    { lat: 48.599814, lng: 19.715828 },
    { lat: 48.599463, lng: 19.716532 },
    { lat: 48.59929, lng: 19.716785 },
    { lat: 48.599017, lng: 19.717154 },
    { lat: 48.59899, lng: 19.717193 },
    { lat: 48.598677, lng: 19.717659 },
    { lat: 48.598486, lng: 19.717911 },
    { lat: 48.598304, lng: 19.718292 },
    { lat: 48.598191, lng: 19.718444 },
    { lat: 48.59796, lng: 19.718755 },
    { lat: 48.597718, lng: 19.719107 },
    { lat: 48.597408, lng: 19.719648 },
    { lat: 48.596968, lng: 19.720823 },
    { lat: 48.596913, lng: 19.720961 },
    { lat: 48.596838, lng: 19.721142 },
    { lat: 48.596766, lng: 19.721316 },
    { lat: 48.5965847, lng: 19.7217806 },
    { lat: 48.5953285, lng: 19.72402 },
    { lat: 48.595037, lng: 19.7244584 },
    { lat: 48.5946332, lng: 19.7244243 },
    { lat: 48.59448, lng: 19.7242714 },
    { lat: 48.594379, lng: 19.724026 },
    { lat: 48.5937945, lng: 19.7247483 },
    { lat: 48.5935491, lng: 19.7253108 },
    { lat: 48.5933084, lng: 19.72639 },
    { lat: 48.5928933, lng: 19.7268575 },
    { lat: 48.5927685, lng: 19.7270676 },
    { lat: 48.5926865, lng: 19.7273278 },
    { lat: 48.5926451, lng: 19.7279715 },
    { lat: 48.5925754, lng: 19.72828 },
    { lat: 48.5926554, lng: 19.7283047 },
    { lat: 48.5922326, lng: 19.730691 },
    { lat: 48.5920221, lng: 19.7313588 },
    { lat: 48.5920108, lng: 19.731382 },
  ],
  Mládzovo: [
    { lat: 48.4590858, lng: 19.6914315 },
    { lat: 48.4588396, lng: 19.6916225 },
    { lat: 48.458055, lng: 19.6913383 },
    { lat: 48.4566633, lng: 19.6910048 },
    { lat: 48.4556485, lng: 19.6905873 },
    { lat: 48.4550714, lng: 19.6906542 },
    { lat: 48.4547302, lng: 19.6905584 },
    { lat: 48.4542864, lng: 19.6905884 },
    { lat: 48.4537102, lng: 19.6908819 },
    { lat: 48.4518906, lng: 19.6921676 },
    { lat: 48.4505101, lng: 19.6924899 },
    { lat: 48.447492, lng: 19.6928584 },
    { lat: 48.4454437, lng: 19.693011 },
    { lat: 48.4448498, lng: 19.6931611 },
    { lat: 48.442037, lng: 19.6935308 },
    { lat: 48.4413134, lng: 19.6937018 },
    { lat: 48.4409948, lng: 19.6936318 },
    { lat: 48.4409969, lng: 19.6933516 },
    { lat: 48.440866, lng: 19.6931696 },
    { lat: 48.4408157, lng: 19.6929263 },
    { lat: 48.4408303, lng: 19.6927251 },
    { lat: 48.4405478, lng: 19.6921535 },
    { lat: 48.4407065, lng: 19.6916673 },
    { lat: 48.4407249, lng: 19.6912247 },
    { lat: 48.4404723, lng: 19.6907392 },
    { lat: 48.440464, lng: 19.690634 },
    { lat: 48.4405856, lng: 19.6905801 },
    { lat: 48.4406203, lng: 19.6904964 },
    { lat: 48.4406988, lng: 19.6893789 },
    { lat: 48.4411001, lng: 19.6886285 },
    { lat: 48.4413032, lng: 19.687679 },
    { lat: 48.4414522, lng: 19.6855975 },
    { lat: 48.4412983, lng: 19.6855825 },
    { lat: 48.4412649, lng: 19.6834308 },
    { lat: 48.4413621, lng: 19.6824529 },
    { lat: 48.4412266, lng: 19.6806372 },
    { lat: 48.4411563, lng: 19.6806007 },
    { lat: 48.4412284, lng: 19.6797236 },
    { lat: 48.4417894, lng: 19.678539 },
    { lat: 48.4420743, lng: 19.6777578 },
    { lat: 48.4420669, lng: 19.6775921 },
    { lat: 48.4419767, lng: 19.6774927 },
    { lat: 48.4419796, lng: 19.6771049 },
    { lat: 48.4419257, lng: 19.6770283 },
    { lat: 48.441951, lng: 19.6767131 },
    { lat: 48.4418465, lng: 19.6763962 },
    { lat: 48.4418981, lng: 19.6759133 },
    { lat: 48.4420343, lng: 19.675634 },
    { lat: 48.4420891, lng: 19.6753765 },
    { lat: 48.4420008, lng: 19.675367 },
    { lat: 48.4419866, lng: 19.6752506 },
    { lat: 48.4418926, lng: 19.6752382 },
    { lat: 48.4419354, lng: 19.6751398 },
    { lat: 48.4419175, lng: 19.6749407 },
    { lat: 48.4417131, lng: 19.6750058 },
    { lat: 48.4415462, lng: 19.6747012 },
    { lat: 48.4414892, lng: 19.6744582 },
    { lat: 48.4415176, lng: 19.6743386 },
    { lat: 48.4414532, lng: 19.6741088 },
    { lat: 48.4414532, lng: 19.67386 },
    { lat: 48.4413964, lng: 19.6738253 },
    { lat: 48.4414632, lng: 19.6733522 },
    { lat: 48.4416332, lng: 19.6733726 },
    { lat: 48.4417489, lng: 19.6731955 },
    { lat: 48.4417154, lng: 19.6731222 },
    { lat: 48.4417425, lng: 19.6728725 },
    { lat: 48.4418293, lng: 19.6727966 },
    { lat: 48.4419301, lng: 19.6724088 },
    { lat: 48.4419183, lng: 19.6722706 },
    { lat: 48.4418595, lng: 19.6721193 },
    { lat: 48.4418873, lng: 19.6719806 },
    { lat: 48.4417585, lng: 19.6717999 },
    { lat: 48.4418011, lng: 19.6711443 },
    { lat: 48.4416996, lng: 19.6711394 },
    { lat: 48.4414026, lng: 19.670952 },
    { lat: 48.441375, lng: 19.6709349 },
    { lat: 48.4393572, lng: 19.6691879 },
    { lat: 48.4363945, lng: 19.6729688 },
    { lat: 48.4342629, lng: 19.6734762 },
    { lat: 48.4328787, lng: 19.6729112 },
    { lat: 48.4327304, lng: 19.6738519 },
    { lat: 48.4323353, lng: 19.6752914 },
    { lat: 48.4323779, lng: 19.6758611 },
    { lat: 48.4323017, lng: 19.6770787 },
    { lat: 48.4323583, lng: 19.6773611 },
    { lat: 48.4323547, lng: 19.6776067 },
    { lat: 48.4322468, lng: 19.6780912 },
    { lat: 48.4322926, lng: 19.6783343 },
    { lat: 48.432361, lng: 19.6783994 },
    { lat: 48.4322741, lng: 19.6787204 },
    { lat: 48.4322919, lng: 19.6788186 },
    { lat: 48.4322361, lng: 19.67888 },
    { lat: 48.4322821, lng: 19.679151 },
    { lat: 48.4321285, lng: 19.679371 },
    { lat: 48.4321061, lng: 19.6795964 },
    { lat: 48.4321529, lng: 19.6797889 },
    { lat: 48.4320715, lng: 19.6798841 },
    { lat: 48.4320856, lng: 19.6800721 },
    { lat: 48.4320266, lng: 19.6801899 },
    { lat: 48.4320793, lng: 19.6805855 },
    { lat: 48.4317341, lng: 19.6813066 },
    { lat: 48.4317463, lng: 19.6813788 },
    { lat: 48.4318759, lng: 19.681503 },
    { lat: 48.4318581, lng: 19.6816343 },
    { lat: 48.4317426, lng: 19.6817961 },
    { lat: 48.4313755, lng: 19.6829828 },
    { lat: 48.4310473, lng: 19.6835773 },
    { lat: 48.430559, lng: 19.6848802 },
    { lat: 48.4304885, lng: 19.6858204 },
    { lat: 48.4303966, lng: 19.6856552 },
    { lat: 48.4303096, lng: 19.6857747 },
    { lat: 48.4303832, lng: 19.686183 },
    { lat: 48.430365, lng: 19.6865351 },
    { lat: 48.430213, lng: 19.6866115 },
    { lat: 48.4302696, lng: 19.6867931 },
    { lat: 48.4301958, lng: 19.6868328 },
    { lat: 48.4302516, lng: 19.6869932 },
    { lat: 48.4301076, lng: 19.6872795 },
    { lat: 48.4301187, lng: 19.6874729 },
    { lat: 48.4298706, lng: 19.6879964 },
    { lat: 48.4299069, lng: 19.6882539 },
    { lat: 48.4298345, lng: 19.6882516 },
    { lat: 48.4298331, lng: 19.6886463 },
    { lat: 48.4297323, lng: 19.6889119 },
    { lat: 48.4296612, lng: 19.6893367 },
    { lat: 48.4296399, lng: 19.6899175 },
    { lat: 48.4296768, lng: 19.6900135 },
    { lat: 48.4295717, lng: 19.6901344 },
    { lat: 48.4295212, lng: 19.6903354 },
    { lat: 48.4294372, lng: 19.690346 },
    { lat: 48.4293779, lng: 19.6904836 },
    { lat: 48.4293612, lng: 19.6911697 },
    { lat: 48.4289865, lng: 19.6932706 },
    { lat: 48.4289341, lng: 19.6933826 },
    { lat: 48.4289584, lng: 19.6936239 },
    { lat: 48.429034, lng: 19.6937522 },
    { lat: 48.4290569, lng: 19.6939102 },
    { lat: 48.4288885, lng: 19.6942047 },
    { lat: 48.4288975, lng: 19.6943276 },
    { lat: 48.4288224, lng: 19.6944373 },
    { lat: 48.4288413, lng: 19.6946327 },
    { lat: 48.4286909, lng: 19.6952513 },
    { lat: 48.4287542, lng: 19.6953312 },
    { lat: 48.4285961, lng: 19.6960301 },
    { lat: 48.4286035, lng: 19.6961947 },
    { lat: 48.4284875, lng: 19.6964735 },
    { lat: 48.4283856, lng: 19.6972217 },
    { lat: 48.4284261, lng: 19.6977462 },
    { lat: 48.4283812, lng: 19.6981059 },
    { lat: 48.4284261, lng: 19.6984547 },
    { lat: 48.4283714, lng: 19.6985681 },
    { lat: 48.4283537, lng: 19.6987739 },
    { lat: 48.4282778, lng: 19.6988124 },
    { lat: 48.4283261, lng: 19.6989388 },
    { lat: 48.4283051, lng: 19.699351 },
    { lat: 48.4283852, lng: 19.6998322 },
    { lat: 48.4284595, lng: 19.700033 },
    { lat: 48.4283306, lng: 19.7003014 },
    { lat: 48.4283803, lng: 19.7006792 },
    { lat: 48.4283517, lng: 19.7008963 },
    { lat: 48.4282781, lng: 19.7010404 },
    { lat: 48.4284287, lng: 19.701287 },
    { lat: 48.4284995, lng: 19.7016091 },
    { lat: 48.4282935, lng: 19.7018406 },
    { lat: 48.4283972, lng: 19.7020207 },
    { lat: 48.4283143, lng: 19.7022791 },
    { lat: 48.4283684, lng: 19.7023595 },
    { lat: 48.4283718, lng: 19.7025625 },
    { lat: 48.4280546, lng: 19.7031511 },
    { lat: 48.4280404, lng: 19.7033154 },
    { lat: 48.4281122, lng: 19.7034592 },
    { lat: 48.4281387, lng: 19.7036709 },
    { lat: 48.4281336, lng: 19.7044661 },
    { lat: 48.4279714, lng: 19.704795 },
    { lat: 48.4279583, lng: 19.7050567 },
    { lat: 48.4278634, lng: 19.7053323 },
    { lat: 48.4278544, lng: 19.7056589 },
    { lat: 48.4277531, lng: 19.7061942 },
    { lat: 48.4276397, lng: 19.7064032 },
    { lat: 48.4276768, lng: 19.70648 },
    { lat: 48.4277831, lng: 19.7065381 },
    { lat: 48.4276329, lng: 19.7068332 },
    { lat: 48.42775, lng: 19.7069299 },
    { lat: 48.4277729, lng: 19.7072423 },
    { lat: 48.427659, lng: 19.7073141 },
    { lat: 48.427622, lng: 19.7081288 },
    { lat: 48.4278804, lng: 19.7084387 },
    { lat: 48.4279789, lng: 19.708752 },
    { lat: 48.4278578, lng: 19.7090452 },
    { lat: 48.4280145, lng: 19.7093491 },
    { lat: 48.4279233, lng: 19.7095754 },
    { lat: 48.4278553, lng: 19.7095975 },
    { lat: 48.4275949, lng: 19.7103758 },
    { lat: 48.4274809, lng: 19.7104202 },
    { lat: 48.4272294, lng: 19.7103647 },
    { lat: 48.4270772, lng: 19.7104414 },
    { lat: 48.4269336, lng: 19.7106584 },
    { lat: 48.4269058, lng: 19.7108393 },
    { lat: 48.4268307, lng: 19.7109504 },
    { lat: 48.4268225, lng: 19.7113954 },
    { lat: 48.4267604, lng: 19.7115237 },
    { lat: 48.4266875, lng: 19.7114782 },
    { lat: 48.4266086, lng: 19.7116035 },
    { lat: 48.4265948, lng: 19.7118298 },
    { lat: 48.4264977, lng: 19.7120979 },
    { lat: 48.4265189, lng: 19.7122242 },
    { lat: 48.4266621, lng: 19.7124538 },
    { lat: 48.4266638, lng: 19.7125672 },
    { lat: 48.4265777, lng: 19.7127596 },
    { lat: 48.4265295, lng: 19.7126882 },
    { lat: 48.4264904, lng: 19.7127705 },
    { lat: 48.4264962, lng: 19.7129278 },
    { lat: 48.4264303, lng: 19.7130387 },
    { lat: 48.4265172, lng: 19.7133464 },
    { lat: 48.4264713, lng: 19.7134028 },
    { lat: 48.4264686, lng: 19.7135688 },
    { lat: 48.4264045, lng: 19.7136253 },
    { lat: 48.4264605, lng: 19.7139432 },
    { lat: 48.4264096, lng: 19.714115 },
    { lat: 48.42632, lng: 19.7141556 },
    { lat: 48.4263621, lng: 19.7142908 },
    { lat: 48.4263268, lng: 19.7145359 },
    { lat: 48.4262655, lng: 19.7146013 },
    { lat: 48.4261809, lng: 19.7149015 },
    { lat: 48.4262239, lng: 19.7153367 },
    { lat: 48.4262835, lng: 19.7154343 },
    { lat: 48.4262515, lng: 19.7157032 },
    { lat: 48.4263825, lng: 19.7160136 },
    { lat: 48.4264929, lng: 19.7160912 },
    { lat: 48.426758, lng: 19.7167279 },
    { lat: 48.4267857, lng: 19.7168989 },
    { lat: 48.4267251, lng: 19.7170001 },
    { lat: 48.4268176, lng: 19.7174339 },
    { lat: 48.4267716, lng: 19.7175421 },
    { lat: 48.4267589, lng: 19.7178613 },
    { lat: 48.4267987, lng: 19.7179222 },
    { lat: 48.4267501, lng: 19.7179718 },
    { lat: 48.4266823, lng: 19.7184476 },
    { lat: 48.4266272, lng: 19.7184457 },
    { lat: 48.4266409, lng: 19.7186302 },
    { lat: 48.4267214, lng: 19.7188161 },
    { lat: 48.4267007, lng: 19.7189892 },
    { lat: 48.4265134, lng: 19.7193096 },
    { lat: 48.4265078, lng: 19.7195141 },
    { lat: 48.4266162, lng: 19.7198693 },
    { lat: 48.4265787, lng: 19.7200391 },
    { lat: 48.4266188, lng: 19.7201075 },
    { lat: 48.4265363, lng: 19.720429 },
    { lat: 48.4263928, lng: 19.7207024 },
    { lat: 48.4263772, lng: 19.7210566 },
    { lat: 48.4262821, lng: 19.7210729 },
    { lat: 48.4262648, lng: 19.721169 },
    { lat: 48.426363, lng: 19.7214479 },
    { lat: 48.4263699, lng: 19.7216562 },
    { lat: 48.4265103, lng: 19.7220007 },
    { lat: 48.4264056, lng: 19.7222532 },
    { lat: 48.4264437, lng: 19.7223501 },
    { lat: 48.4264288, lng: 19.7224715 },
    { lat: 48.4263687, lng: 19.7224889 },
    { lat: 48.4263159, lng: 19.7226723 },
    { lat: 48.4262761, lng: 19.7232439 },
    { lat: 48.4263029, lng: 19.7232678 },
    { lat: 48.4260862, lng: 19.7233591 },
    { lat: 48.4260096, lng: 19.7232554 },
    { lat: 48.4259541, lng: 19.7233227 },
    { lat: 48.4259852, lng: 19.7234923 },
    { lat: 48.4259217, lng: 19.7236363 },
    { lat: 48.4258041, lng: 19.723652 },
    { lat: 48.4257832, lng: 19.7237907 },
    { lat: 48.4257087, lng: 19.7238372 },
    { lat: 48.4255325, lng: 19.7237016 },
    { lat: 48.4253536, lng: 19.7239587 },
    { lat: 48.4252475, lng: 19.7240045 },
    { lat: 48.4251101, lng: 19.7238033 },
    { lat: 48.4249791, lng: 19.7238253 },
    { lat: 48.4248535, lng: 19.723989 },
    { lat: 48.4246546, lng: 19.7239505 },
    { lat: 48.4245695, lng: 19.7240126 },
    { lat: 48.4244734, lng: 19.7242085 },
    { lat: 48.42448, lng: 19.7243571 },
    { lat: 48.424389, lng: 19.7245404 },
    { lat: 48.4243005, lng: 19.7246043 },
    { lat: 48.4242708, lng: 19.7247229 },
    { lat: 48.4243045, lng: 19.7248448 },
    { lat: 48.4242178, lng: 19.7250763 },
    { lat: 48.4241595, lng: 19.7250016 },
    { lat: 48.423818, lng: 19.7252439 },
    { lat: 48.4235586, lng: 19.725428 },
    { lat: 48.4235695, lng: 19.7256255 },
    { lat: 48.4234647, lng: 19.7257219 },
    { lat: 48.423444, lng: 19.7261943 },
    { lat: 48.4235721, lng: 19.7263152 },
    { lat: 48.4235952, lng: 19.7264303 },
    { lat: 48.423738, lng: 19.7266271 },
    { lat: 48.4239557, lng: 19.7266318 },
    { lat: 48.4240619, lng: 19.726811 },
    { lat: 48.4240654, lng: 19.726944 },
    { lat: 48.4238289, lng: 19.7271483 },
    { lat: 48.423696, lng: 19.727505 },
    { lat: 48.4236087, lng: 19.727595 },
    { lat: 48.4235886, lng: 19.7277747 },
    { lat: 48.4250875, lng: 19.7282129 },
    { lat: 48.4254746, lng: 19.7284341 },
    { lat: 48.425595, lng: 19.7281464 },
    { lat: 48.425832, lng: 19.7280825 },
    { lat: 48.4261822, lng: 19.7276749 },
    { lat: 48.4263058, lng: 19.7276132 },
    { lat: 48.4265104, lng: 19.727681 },
    { lat: 48.426559, lng: 19.7278322 },
    { lat: 48.4266456, lng: 19.7278803 },
    { lat: 48.4267445, lng: 19.7277987 },
    { lat: 48.4269737, lng: 19.7279375 },
    { lat: 48.4270933, lng: 19.7278915 },
    { lat: 48.4272164, lng: 19.727999 },
    { lat: 48.4272439, lng: 19.7281094 },
    { lat: 48.4279879, lng: 19.7283477 },
    { lat: 48.4282116, lng: 19.7285112 },
    { lat: 48.4285762, lng: 19.7285268 },
    { lat: 48.4286892, lng: 19.7286361 },
    { lat: 48.4287474, lng: 19.7285497 },
    { lat: 48.4293154, lng: 19.7284789 },
    { lat: 48.4295399, lng: 19.7283563 },
    { lat: 48.4297134, lng: 19.7283808 },
    { lat: 48.4300479, lng: 19.7285342 },
    { lat: 48.4301414, lng: 19.7286831 },
    { lat: 48.4303055, lng: 19.7287741 },
    { lat: 48.4305643, lng: 19.7287691 },
    { lat: 48.4306779, lng: 19.7288914 },
    { lat: 48.4307526, lng: 19.7288463 },
    { lat: 48.4312483, lng: 19.7293004 },
    { lat: 48.4315985, lng: 19.7294232 },
    { lat: 48.4317482, lng: 19.7293975 },
    { lat: 48.4317872, lng: 19.7295362 },
    { lat: 48.4318275, lng: 19.7294832 },
    { lat: 48.4319885, lng: 19.7293756 },
    { lat: 48.4322419, lng: 19.7296539 },
    { lat: 48.4326521, lng: 19.7296812 },
    { lat: 48.432953, lng: 19.7298593 },
    { lat: 48.4330719, lng: 19.7298243 },
    { lat: 48.433261, lng: 19.729921 },
    { lat: 48.4337039, lng: 19.7299731 },
    { lat: 48.4339672, lng: 19.7298357 },
    { lat: 48.4342028, lng: 19.7299149 },
    { lat: 48.4344097, lng: 19.7298317 },
    { lat: 48.4344479, lng: 19.7297026 },
    { lat: 48.4347466, lng: 19.7293001 },
    { lat: 48.4350996, lng: 19.728647 },
    { lat: 48.4352469, lng: 19.7285666 },
    { lat: 48.4353723, lng: 19.7283958 },
    { lat: 48.4357911, lng: 19.7275231 },
    { lat: 48.4363755, lng: 19.7267538 },
    { lat: 48.4377289, lng: 19.7245273 },
    { lat: 48.4383691, lng: 19.7232392 },
    { lat: 48.4387503, lng: 19.7222067 },
    { lat: 48.4389913, lng: 19.7219036 },
    { lat: 48.4395133, lng: 19.7216801 },
    { lat: 48.4417254, lng: 19.7210796 },
    { lat: 48.4423024, lng: 19.7207544 },
    { lat: 48.442672, lng: 19.7207531 },
    { lat: 48.4428976, lng: 19.7206052 },
    { lat: 48.443653, lng: 19.7196592 },
    { lat: 48.4442686, lng: 19.7185245 },
    { lat: 48.4447064, lng: 19.7173257 },
    { lat: 48.4447249, lng: 19.7162723 },
    { lat: 48.4448635, lng: 19.715006 },
    { lat: 48.4449144, lng: 19.7141005 },
    { lat: 48.4455037, lng: 19.7124034 },
    { lat: 48.4475538, lng: 19.7074871 },
    { lat: 48.4486155, lng: 19.7057021 },
    { lat: 48.4495618, lng: 19.7057963 },
    { lat: 48.4508095, lng: 19.7064397 },
    { lat: 48.4517428, lng: 19.7062956 },
    { lat: 48.4519312, lng: 19.7059174 },
    { lat: 48.4520209, lng: 19.7054839 },
    { lat: 48.4521931, lng: 19.7052471 },
    { lat: 48.4523178, lng: 19.7049627 },
    { lat: 48.4527704, lng: 19.7046541 },
    { lat: 48.4533518, lng: 19.7043502 },
    { lat: 48.4546884, lng: 19.7044557 },
    { lat: 48.4561691, lng: 19.7044829 },
    { lat: 48.4560473, lng: 19.7023891 },
    { lat: 48.4562523, lng: 19.7011239 },
    { lat: 48.4563151, lng: 19.6994751 },
    { lat: 48.4567631, lng: 19.6980548 },
    { lat: 48.4569503, lng: 19.6970714 },
    { lat: 48.45805, lng: 19.6942462 },
    { lat: 48.4588753, lng: 19.6927492 },
    { lat: 48.4590562, lng: 19.6918506 },
    { lat: 48.4590858, lng: 19.6914315 },
  ],
  Rovňany: [
    { lat: 48.4742973, lng: 19.7265674 },
    { lat: 48.4730079, lng: 19.725566 },
    { lat: 48.4725928, lng: 19.7249444 },
    { lat: 48.4724706, lng: 19.7246545 },
    { lat: 48.4724593, lng: 19.7244263 },
    { lat: 48.4722423, lng: 19.7242609 },
    { lat: 48.4721833, lng: 19.7238688 },
    { lat: 48.4718892, lng: 19.7234886 },
    { lat: 48.4715612, lng: 19.7233103 },
    { lat: 48.4712884, lng: 19.7230267 },
    { lat: 48.4711821, lng: 19.7229951 },
    { lat: 48.4711019, lng: 19.7228633 },
    { lat: 48.4708183, lng: 19.7226933 },
    { lat: 48.4706606, lng: 19.7226655 },
    { lat: 48.4703023, lng: 19.722313 },
    { lat: 48.4696909, lng: 19.7220453 },
    { lat: 48.4693158, lng: 19.722072 },
    { lat: 48.4691589, lng: 19.7221929 },
    { lat: 48.4686551, lng: 19.7218061 },
    { lat: 48.4685455, lng: 19.721853 },
    { lat: 48.468163, lng: 19.7214856 },
    { lat: 48.4681083, lng: 19.72137 },
    { lat: 48.4679249, lng: 19.7209191 },
    { lat: 48.467965, lng: 19.720881 },
    { lat: 48.4679898, lng: 19.7203232 },
    { lat: 48.4679562, lng: 19.7199992 },
    { lat: 48.4682105, lng: 19.7195114 },
    { lat: 48.4682445, lng: 19.7192608 },
    { lat: 48.4686738, lng: 19.7187572 },
    { lat: 48.4687965, lng: 19.7184473 },
    { lat: 48.4695347, lng: 19.7179996 },
    { lat: 48.4696541, lng: 19.7178168 },
    { lat: 48.4696723, lng: 19.7175948 },
    { lat: 48.4693556, lng: 19.7173643 },
    { lat: 48.4692069, lng: 19.7171644 },
    { lat: 48.4684893, lng: 19.7177468 },
    { lat: 48.468049, lng: 19.7170206 },
    { lat: 48.4672484, lng: 19.7177385 },
    { lat: 48.4670418, lng: 19.7173772 },
    { lat: 48.4664404, lng: 19.7167223 },
    { lat: 48.4662766, lng: 19.7179835 },
    { lat: 48.465625, lng: 19.7187584 },
    { lat: 48.4655662, lng: 19.7188797 },
    { lat: 48.4656617, lng: 19.7191002 },
    { lat: 48.4654231, lng: 19.7195115 },
    { lat: 48.4642234, lng: 19.7207852 },
    { lat: 48.4638329, lng: 19.7204411 },
    { lat: 48.4637671, lng: 19.7203035 },
    { lat: 48.463838, lng: 19.7201047 },
    { lat: 48.4611197, lng: 19.7177584 },
    { lat: 48.4607565, lng: 19.7172156 },
    { lat: 48.4607621, lng: 19.7170638 },
    { lat: 48.461101, lng: 19.7164521 },
    { lat: 48.46049, lng: 19.716039 },
    { lat: 48.4604253, lng: 19.7159258 },
    { lat: 48.4601084, lng: 19.7159467 },
    { lat: 48.4600378, lng: 19.7157515 },
    { lat: 48.4599661, lng: 19.7157108 },
    { lat: 48.4597814, lng: 19.715205 },
    { lat: 48.4596751, lng: 19.7152239 },
    { lat: 48.4595782, lng: 19.7151225 },
    { lat: 48.4593716, lng: 19.715138 },
    { lat: 48.4591224, lng: 19.7147062 },
    { lat: 48.4590374, lng: 19.7147698 },
    { lat: 48.4589473, lng: 19.7146439 },
    { lat: 48.4585154, lng: 19.7135177 },
    { lat: 48.4582862, lng: 19.7131528 },
    { lat: 48.4582002, lng: 19.7125431 },
    { lat: 48.4577895, lng: 19.7120942 },
    { lat: 48.4576443, lng: 19.7121007 },
    { lat: 48.4574388, lng: 19.7118864 },
    { lat: 48.4569173, lng: 19.7115651 },
    { lat: 48.4566881, lng: 19.7115026 },
    { lat: 48.4565173, lng: 19.7113359 },
    { lat: 48.4557825, lng: 19.7109801 },
    { lat: 48.4546682, lng: 19.7102201 },
    { lat: 48.455396, lng: 19.708426 },
    { lat: 48.4559405, lng: 19.7061072 },
    { lat: 48.4561745, lng: 19.704482 },
    { lat: 48.4561691, lng: 19.7044829 },
    { lat: 48.4546884, lng: 19.7044557 },
    { lat: 48.4533518, lng: 19.7043502 },
    { lat: 48.4527704, lng: 19.7046541 },
    { lat: 48.4523178, lng: 19.7049627 },
    { lat: 48.4521931, lng: 19.7052471 },
    { lat: 48.4520209, lng: 19.7054839 },
    { lat: 48.4519312, lng: 19.7059174 },
    { lat: 48.4517428, lng: 19.7062956 },
    { lat: 48.4508095, lng: 19.7064397 },
    { lat: 48.4495618, lng: 19.7057963 },
    { lat: 48.4486155, lng: 19.7057021 },
    { lat: 48.4475538, lng: 19.7074871 },
    { lat: 48.4455037, lng: 19.7124034 },
    { lat: 48.4449144, lng: 19.7141005 },
    { lat: 48.4448635, lng: 19.715006 },
    { lat: 48.4447249, lng: 19.7162723 },
    { lat: 48.4447064, lng: 19.7173257 },
    { lat: 48.4442686, lng: 19.7185245 },
    { lat: 48.443653, lng: 19.7196592 },
    { lat: 48.4428976, lng: 19.7206052 },
    { lat: 48.442672, lng: 19.7207531 },
    { lat: 48.4423024, lng: 19.7207544 },
    { lat: 48.4417254, lng: 19.7210796 },
    { lat: 48.4395133, lng: 19.7216801 },
    { lat: 48.4389913, lng: 19.7219036 },
    { lat: 48.4387503, lng: 19.7222067 },
    { lat: 48.4383691, lng: 19.7232392 },
    { lat: 48.4377289, lng: 19.7245273 },
    { lat: 48.4363755, lng: 19.7267538 },
    { lat: 48.4357911, lng: 19.7275231 },
    { lat: 48.4353723, lng: 19.7283958 },
    { lat: 48.4352469, lng: 19.7285666 },
    { lat: 48.4350996, lng: 19.728647 },
    { lat: 48.4347466, lng: 19.7293001 },
    { lat: 48.4344479, lng: 19.7297026 },
    { lat: 48.4344097, lng: 19.7298317 },
    { lat: 48.4342028, lng: 19.7299149 },
    { lat: 48.4339672, lng: 19.7298357 },
    { lat: 48.4337039, lng: 19.7299731 },
    { lat: 48.433261, lng: 19.729921 },
    { lat: 48.4330719, lng: 19.7298243 },
    { lat: 48.432953, lng: 19.7298593 },
    { lat: 48.4326521, lng: 19.7296812 },
    { lat: 48.4322419, lng: 19.7296539 },
    { lat: 48.4319885, lng: 19.7293756 },
    { lat: 48.4318275, lng: 19.7294832 },
    { lat: 48.431864, lng: 19.7298738 },
    { lat: 48.4319088, lng: 19.7302233 },
    { lat: 48.4320812, lng: 19.7307294 },
    { lat: 48.4320797, lng: 19.7314831 },
    { lat: 48.4324782, lng: 19.7326952 },
    { lat: 48.4325085, lng: 19.7330679 },
    { lat: 48.4324677, lng: 19.7333942 },
    { lat: 48.4324982, lng: 19.733522 },
    { lat: 48.4324379, lng: 19.7336913 },
    { lat: 48.4324596, lng: 19.7337962 },
    { lat: 48.4322754, lng: 19.7343912 },
    { lat: 48.4321149, lng: 19.7347394 },
    { lat: 48.4319897, lng: 19.7353973 },
    { lat: 48.4318546, lng: 19.7357057 },
    { lat: 48.4318033, lng: 19.736172 },
    { lat: 48.431867, lng: 19.7364644 },
    { lat: 48.4328683, lng: 19.7378798 },
    { lat: 48.4334253, lng: 19.7380142 },
    { lat: 48.4345779, lng: 19.7388011 },
    { lat: 48.4351893, lng: 19.7390227 },
    { lat: 48.4359628, lng: 19.7394171 },
    { lat: 48.4361551, lng: 19.7396111 },
    { lat: 48.43675, lng: 19.741545 },
    { lat: 48.437418, lng: 19.7426867 },
    { lat: 48.4379233, lng: 19.7431556 },
    { lat: 48.437952, lng: 19.7434749 },
    { lat: 48.4378536, lng: 19.7440255 },
    { lat: 48.4376881, lng: 19.7445629 },
    { lat: 48.4377819, lng: 19.7451578 },
    { lat: 48.4378539, lng: 19.7458926 },
    { lat: 48.4378323, lng: 19.7462162 },
    { lat: 48.4378982, lng: 19.7466649 },
    { lat: 48.4384317, lng: 19.747719 },
    { lat: 48.43886, lng: 19.7484082 },
    { lat: 48.4389528, lng: 19.7493854 },
    { lat: 48.4392, lng: 19.7498805 },
    { lat: 48.4395805, lng: 19.7504344 },
    { lat: 48.4397694, lng: 19.7508363 },
    { lat: 48.4403303, lng: 19.7514637 },
    { lat: 48.4409131, lng: 19.7518551 },
    { lat: 48.4414966, lng: 19.751981 },
    { lat: 48.4421743, lng: 19.7522239 },
    { lat: 48.4432829, lng: 19.7533158 },
    { lat: 48.4435855, lng: 19.7526415 },
    { lat: 48.4443104, lng: 19.752754 },
    { lat: 48.4436484, lng: 19.7551874 },
    { lat: 48.443739, lng: 19.7556567 },
    { lat: 48.4437155, lng: 19.7559419 },
    { lat: 48.443858, lng: 19.7565913 },
    { lat: 48.4440427, lng: 19.7570998 },
    { lat: 48.4441564, lng: 19.7571999 },
    { lat: 48.4441371, lng: 19.7572494 },
    { lat: 48.4444049, lng: 19.7578015 },
    { lat: 48.4447436, lng: 19.7587358 },
    { lat: 48.4448423, lng: 19.7588267 },
    { lat: 48.4450838, lng: 19.7586813 },
    { lat: 48.4465357, lng: 19.7588495 },
    { lat: 48.4471171, lng: 19.7584469 },
    { lat: 48.4473932, lng: 19.757936 },
    { lat: 48.4474744, lng: 19.7576841 },
    { lat: 48.4475731, lng: 19.7575923 },
    { lat: 48.4476846, lng: 19.757979 },
    { lat: 48.4477665, lng: 19.7579942 },
    { lat: 48.4480183, lng: 19.7575212 },
    { lat: 48.4490297, lng: 19.7580986 },
    { lat: 48.4491594, lng: 19.757977 },
    { lat: 48.4497079, lng: 19.7571284 },
    { lat: 48.450265, lng: 19.7560943 },
    { lat: 48.4503962, lng: 19.7551144 },
    { lat: 48.45041, lng: 19.7537151 },
    { lat: 48.4505017, lng: 19.7531146 },
    { lat: 48.4512483, lng: 19.7536921 },
    { lat: 48.451886, lng: 19.7522133 },
    { lat: 48.4550857, lng: 19.7551639 },
    { lat: 48.4573826, lng: 19.7514854 },
    { lat: 48.4596836, lng: 19.7462663 },
    { lat: 48.4596043, lng: 19.745975 },
    { lat: 48.4595383, lng: 19.7459344 },
    { lat: 48.4591042, lng: 19.7452146 },
    { lat: 48.459566, lng: 19.74427 },
    { lat: 48.4596141, lng: 19.7438793 },
    { lat: 48.459953, lng: 19.742779 },
    { lat: 48.4603478, lng: 19.742282 },
    { lat: 48.4605659, lng: 19.7417632 },
    { lat: 48.4607433, lng: 19.7409353 },
    { lat: 48.4608155, lng: 19.7408596 },
    { lat: 48.461032, lng: 19.7402302 },
    { lat: 48.4620341, lng: 19.7386111 },
    { lat: 48.4626112, lng: 19.737925 },
    { lat: 48.463148, lng: 19.7369464 },
    { lat: 48.4634059, lng: 19.7366401 },
    { lat: 48.4635202, lng: 19.7363692 },
    { lat: 48.4638077, lng: 19.7359669 },
    { lat: 48.4642287, lng: 19.7355253 },
    { lat: 48.4655035, lng: 19.7350546 },
    { lat: 48.4656064, lng: 19.734936 },
    { lat: 48.4665084, lng: 19.734874 },
    { lat: 48.4670734, lng: 19.7350116 },
    { lat: 48.4672782, lng: 19.7349836 },
    { lat: 48.4675222, lng: 19.7348952 },
    { lat: 48.4679727, lng: 19.7346079 },
    { lat: 48.4684271, lng: 19.7344139 },
    { lat: 48.4686757, lng: 19.7344015 },
    { lat: 48.4691127, lng: 19.734146 },
    { lat: 48.4696497, lng: 19.7334783 },
    { lat: 48.4697212, lng: 19.7328271 },
    { lat: 48.4698129, lng: 19.7324824 },
    { lat: 48.4701162, lng: 19.7318256 },
    { lat: 48.4703566, lng: 19.7307942 },
    { lat: 48.4706241, lng: 19.7302195 },
    { lat: 48.4708187, lng: 19.7299813 },
    { lat: 48.471177, lng: 19.7298832 },
    { lat: 48.4718156, lng: 19.7295741 },
    { lat: 48.4727606, lng: 19.7288043 },
    { lat: 48.4730155, lng: 19.7285315 },
    { lat: 48.4740869, lng: 19.7271049 },
    { lat: 48.4743092, lng: 19.7265794 },
    { lat: 48.4742973, lng: 19.7265674 },
  ],
  Uhorské: [
    { lat: 48.4994829, lng: 19.7357132 },
    { lat: 48.4974919, lng: 19.7365242 },
    { lat: 48.4971173, lng: 19.7367671 },
    { lat: 48.4958352, lng: 19.7373041 },
    { lat: 48.4917188, lng: 19.7386368 },
    { lat: 48.4910207, lng: 19.7358022 },
    { lat: 48.491028, lng: 19.7355092 },
    { lat: 48.4899604, lng: 19.7334865 },
    { lat: 48.4874658, lng: 19.729265 },
    { lat: 48.4873507, lng: 19.7284011 },
    { lat: 48.4854477, lng: 19.7305604 },
    { lat: 48.485223, lng: 19.7301692 },
    { lat: 48.4836748, lng: 19.7319466 },
    { lat: 48.4835165, lng: 19.7317515 },
    { lat: 48.4831254, lng: 19.731877 },
    { lat: 48.482583, lng: 19.7317905 },
    { lat: 48.4825689, lng: 19.7310674 },
    { lat: 48.4826497, lng: 19.73068 },
    { lat: 48.4826006, lng: 19.7303067 },
    { lat: 48.4827509, lng: 19.729582 },
    { lat: 48.4827668, lng: 19.7292796 },
    { lat: 48.4826884, lng: 19.728785 },
    { lat: 48.4829226, lng: 19.7283949 },
    { lat: 48.4829492, lng: 19.7280851 },
    { lat: 48.4829111, lng: 19.7275295 },
    { lat: 48.4827358, lng: 19.7272886 },
    { lat: 48.4827615, lng: 19.7269263 },
    { lat: 48.4826724, lng: 19.726318 },
    { lat: 48.482636, lng: 19.7251667 },
    { lat: 48.4830438, lng: 19.7244033 },
    { lat: 48.4828284, lng: 19.7226464 },
    { lat: 48.4829421, lng: 19.7221886 },
    { lat: 48.4829168, lng: 19.7216442 },
    { lat: 48.4830061, lng: 19.7211206 },
    { lat: 48.4829048, lng: 19.720435 },
    { lat: 48.4824746, lng: 19.7201089 },
    { lat: 48.4816626, lng: 19.7198639 },
    { lat: 48.4814061, lng: 19.7197245 },
    { lat: 48.4812204, lng: 19.7197805 },
    { lat: 48.4808171, lng: 19.7197063 },
    { lat: 48.4805641, lng: 19.7197666 },
    { lat: 48.4801086, lng: 19.7202428 },
    { lat: 48.4798373, lng: 19.7203766 },
    { lat: 48.4791063, lng: 19.7206564 },
    { lat: 48.4787614, lng: 19.7206531 },
    { lat: 48.4784662, lng: 19.7211871 },
    { lat: 48.4773119, lng: 19.722622 },
    { lat: 48.4769373, lng: 19.7229114 },
    { lat: 48.4760906, lng: 19.7231822 },
    { lat: 48.4754061, lng: 19.7239516 },
    { lat: 48.475026, lng: 19.7246279 },
    { lat: 48.474644, lng: 19.7258473 },
    { lat: 48.4744759, lng: 19.7262725 },
    { lat: 48.4742973, lng: 19.7265674 },
    { lat: 48.4743092, lng: 19.7265794 },
    { lat: 48.4740869, lng: 19.7271049 },
    { lat: 48.4730155, lng: 19.7285315 },
    { lat: 48.4727606, lng: 19.7288043 },
    { lat: 48.4718156, lng: 19.7295741 },
    { lat: 48.471177, lng: 19.7298832 },
    { lat: 48.4708187, lng: 19.7299813 },
    { lat: 48.4706241, lng: 19.7302195 },
    { lat: 48.4703566, lng: 19.7307942 },
    { lat: 48.4701162, lng: 19.7318256 },
    { lat: 48.4698129, lng: 19.7324824 },
    { lat: 48.4697212, lng: 19.7328271 },
    { lat: 48.4696497, lng: 19.7334783 },
    { lat: 48.4691127, lng: 19.734146 },
    { lat: 48.4686757, lng: 19.7344015 },
    { lat: 48.4684271, lng: 19.7344139 },
    { lat: 48.4679727, lng: 19.7346079 },
    { lat: 48.4675222, lng: 19.7348952 },
    { lat: 48.4672782, lng: 19.7349836 },
    { lat: 48.4670734, lng: 19.7350116 },
    { lat: 48.4665084, lng: 19.734874 },
    { lat: 48.4656064, lng: 19.734936 },
    { lat: 48.4655035, lng: 19.7350546 },
    { lat: 48.4642287, lng: 19.7355253 },
    { lat: 48.4638077, lng: 19.7359669 },
    { lat: 48.4635202, lng: 19.7363692 },
    { lat: 48.4634059, lng: 19.7366401 },
    { lat: 48.463148, lng: 19.7369464 },
    { lat: 48.4626112, lng: 19.737925 },
    { lat: 48.4620341, lng: 19.7386111 },
    { lat: 48.461032, lng: 19.7402302 },
    { lat: 48.4608155, lng: 19.7408596 },
    { lat: 48.4607433, lng: 19.7409353 },
    { lat: 48.4605659, lng: 19.7417632 },
    { lat: 48.4603478, lng: 19.742282 },
    { lat: 48.459953, lng: 19.742779 },
    { lat: 48.4596141, lng: 19.7438793 },
    { lat: 48.459566, lng: 19.74427 },
    { lat: 48.4591042, lng: 19.7452146 },
    { lat: 48.4595383, lng: 19.7459344 },
    { lat: 48.4596043, lng: 19.745975 },
    { lat: 48.4596836, lng: 19.7462663 },
    { lat: 48.4573826, lng: 19.7514854 },
    { lat: 48.4550857, lng: 19.7551639 },
    { lat: 48.451886, lng: 19.7522133 },
    { lat: 48.4512483, lng: 19.7536921 },
    { lat: 48.4505017, lng: 19.7531146 },
    { lat: 48.45041, lng: 19.7537151 },
    { lat: 48.4503962, lng: 19.7551144 },
    { lat: 48.450265, lng: 19.7560943 },
    { lat: 48.4497079, lng: 19.7571284 },
    { lat: 48.4491594, lng: 19.757977 },
    { lat: 48.4490297, lng: 19.7580986 },
    { lat: 48.4480183, lng: 19.7575212 },
    { lat: 48.4477665, lng: 19.7579942 },
    { lat: 48.4476846, lng: 19.757979 },
    { lat: 48.4475731, lng: 19.7575923 },
    { lat: 48.4474744, lng: 19.7576841 },
    { lat: 48.4473932, lng: 19.757936 },
    { lat: 48.4471171, lng: 19.7584469 },
    { lat: 48.4465357, lng: 19.7588495 },
    { lat: 48.4450838, lng: 19.7586813 },
    { lat: 48.4448423, lng: 19.7588267 },
    { lat: 48.4447436, lng: 19.7587358 },
    { lat: 48.4444049, lng: 19.7578015 },
    { lat: 48.4441371, lng: 19.7572494 },
    { lat: 48.4436623, lng: 19.7584639 },
    { lat: 48.4435081, lng: 19.7588739 },
    { lat: 48.4434836, lng: 19.7591602 },
    { lat: 48.4434979, lng: 19.760404 },
    { lat: 48.4434128, lng: 19.7606201 },
    { lat: 48.4431803, lng: 19.7609158 },
    { lat: 48.4430528, lng: 19.7612202 },
    { lat: 48.4428672, lng: 19.7618153 },
    { lat: 48.4426804, lng: 19.7627921 },
    { lat: 48.4424874, lng: 19.7635385 },
    { lat: 48.4426009, lng: 19.7661729 },
    { lat: 48.4425396, lng: 19.7670324 },
    { lat: 48.4423505, lng: 19.7673979 },
    { lat: 48.4416887, lng: 19.7681795 },
    { lat: 48.4414206, lng: 19.7687694 },
    { lat: 48.441212, lng: 19.7702748 },
    { lat: 48.4407688, lng: 19.7743853 },
    { lat: 48.4406146, lng: 19.7747711 },
    { lat: 48.4398688, lng: 19.7760534 },
    { lat: 48.4389862, lng: 19.7787853 },
    { lat: 48.4387602, lng: 19.7791814 },
    { lat: 48.438333, lng: 19.7796145 },
    { lat: 48.4378153, lng: 19.779905 },
    { lat: 48.4379441, lng: 19.7801704 },
    { lat: 48.4381786, lng: 19.7804558 },
    { lat: 48.4383685, lng: 19.7803408 },
    { lat: 48.4387732, lng: 19.7798851 },
    { lat: 48.4390306, lng: 19.7797957 },
    { lat: 48.439277, lng: 19.7798142 },
    { lat: 48.4401062, lng: 19.7806593 },
    { lat: 48.4403752, lng: 19.7807799 },
    { lat: 48.4414033, lng: 19.7814588 },
    { lat: 48.4418788, lng: 19.7820773 },
    { lat: 48.4429343, lng: 19.7821507 },
    { lat: 48.4430674, lng: 19.7820473 },
    { lat: 48.4432971, lng: 19.7820731 },
    { lat: 48.4441312, lng: 19.7815957 },
    { lat: 48.4445817, lng: 19.7817952 },
    { lat: 48.446019, lng: 19.7819924 },
    { lat: 48.4460738, lng: 19.7811122 },
    { lat: 48.4474054, lng: 19.7814148 },
    { lat: 48.4485326, lng: 19.7815552 },
    { lat: 48.4495617, lng: 19.7820269 },
    { lat: 48.4504781, lng: 19.7826262 },
    { lat: 48.4509865, lng: 19.7828664 },
    { lat: 48.4511325, lng: 19.7823688 },
    { lat: 48.4511621, lng: 19.7822676 },
    { lat: 48.4513185, lng: 19.7824469 },
    { lat: 48.4515775, lng: 19.782599 },
    { lat: 48.4527229, lng: 19.7830202 },
    { lat: 48.4529878, lng: 19.7832292 },
    { lat: 48.4528505, lng: 19.7843119 },
    { lat: 48.4525611, lng: 19.7856271 },
    { lat: 48.4527025, lng: 19.785841 },
    { lat: 48.4532418, lng: 19.786295 },
    { lat: 48.4538532, lng: 19.787029 },
    { lat: 48.4538741, lng: 19.7870688 },
    { lat: 48.4537719, lng: 19.7872582 },
    { lat: 48.4539666, lng: 19.787537 },
    { lat: 48.4557106, lng: 19.7892307 },
    { lat: 48.4561657, lng: 19.7897687 },
    { lat: 48.4570092, lng: 19.7905537 },
    { lat: 48.457474, lng: 19.7908523 },
    { lat: 48.4578539, lng: 19.7909821 },
    { lat: 48.4597247, lng: 19.7914832 },
    { lat: 48.4600194, lng: 19.7916798 },
    { lat: 48.4607385, lng: 19.7918666 },
    { lat: 48.4613547, lng: 19.7919425 },
    { lat: 48.46172, lng: 19.7920795 },
    { lat: 48.462379, lng: 19.7925875 },
    { lat: 48.4628479, lng: 19.792748 },
    { lat: 48.4633761, lng: 19.7931263 },
    { lat: 48.4636553, lng: 19.7932016 },
    { lat: 48.46366, lng: 19.7931777 },
    { lat: 48.4636558, lng: 19.7930826 },
    { lat: 48.464235, lng: 19.7930085 },
    { lat: 48.4643983, lng: 19.7932124 },
    { lat: 48.4644755, lng: 19.7931746 },
    { lat: 48.4646255, lng: 19.7934611 },
    { lat: 48.464807, lng: 19.7934872 },
    { lat: 48.4648602, lng: 19.7937258 },
    { lat: 48.465042, lng: 19.7940163 },
    { lat: 48.465286, lng: 19.7941377 },
    { lat: 48.4653572, lng: 19.7940663 },
    { lat: 48.4653308, lng: 19.7938723 },
    { lat: 48.4655295, lng: 19.7938831 },
    { lat: 48.4657155, lng: 19.7941665 },
    { lat: 48.46574, lng: 19.7943163 },
    { lat: 48.4659215, lng: 19.7944443 },
    { lat: 48.4660365, lng: 19.7944496 },
    { lat: 48.4661779, lng: 19.7941814 },
    { lat: 48.4668378, lng: 19.7942246 },
    { lat: 48.4672661, lng: 19.7941754 },
    { lat: 48.4676586, lng: 19.7942381 },
    { lat: 48.4678355, lng: 19.7940036 },
    { lat: 48.4679646, lng: 19.7939706 },
    { lat: 48.4681769, lng: 19.7933127 },
    { lat: 48.4681198, lng: 19.7931895 },
    { lat: 48.4681429, lng: 19.7930484 },
    { lat: 48.4683204, lng: 19.7929832 },
    { lat: 48.4684476, lng: 19.7927505 },
    { lat: 48.4686126, lng: 19.7922019 },
    { lat: 48.4689412, lng: 19.7917256 },
    { lat: 48.4689091, lng: 19.7915594 },
    { lat: 48.4689766, lng: 19.7913528 },
    { lat: 48.4690641, lng: 19.791293 },
    { lat: 48.4691757, lng: 19.7910111 },
    { lat: 48.4693244, lng: 19.7908064 },
    { lat: 48.469395, lng: 19.790515 },
    { lat: 48.469515, lng: 19.7903707 },
    { lat: 48.4696013, lng: 19.7900778 },
    { lat: 48.4698027, lng: 19.7897933 },
    { lat: 48.4698096, lng: 19.7895895 },
    { lat: 48.4699661, lng: 19.7892484 },
    { lat: 48.4702631, lng: 19.788931 },
    { lat: 48.4703757, lng: 19.7887267 },
    { lat: 48.4706151, lng: 19.7886895 },
    { lat: 48.4708307, lng: 19.7884647 },
    { lat: 48.4708397, lng: 19.7883079 },
    { lat: 48.4708965, lng: 19.788221 },
    { lat: 48.4710241, lng: 19.7882016 },
    { lat: 48.4709502, lng: 19.7879547 },
    { lat: 48.4711298, lng: 19.7877846 },
    { lat: 48.4713058, lng: 19.7877398 },
    { lat: 48.4713124, lng: 19.7874274 },
    { lat: 48.47142, lng: 19.7873116 },
    { lat: 48.4716015, lng: 19.7872904 },
    { lat: 48.4716646, lng: 19.7868233 },
    { lat: 48.4717701, lng: 19.786816 },
    { lat: 48.4719312, lng: 19.7864308 },
    { lat: 48.4720974, lng: 19.7863667 },
    { lat: 48.4722752, lng: 19.7859528 },
    { lat: 48.4728181, lng: 19.7849705 },
    { lat: 48.4731299, lng: 19.7841669 },
    { lat: 48.4732353, lng: 19.7841596 },
    { lat: 48.4733385, lng: 19.7837361 },
    { lat: 48.4735191, lng: 19.7834877 },
    { lat: 48.4735687, lng: 19.7832829 },
    { lat: 48.4737572, lng: 19.7833181 },
    { lat: 48.4744687, lng: 19.7822785 },
    { lat: 48.4746193, lng: 19.7822195 },
    { lat: 48.4749965, lng: 19.7818295 },
    { lat: 48.4755293, lng: 19.781441 },
    { lat: 48.4759467, lng: 19.7809894 },
    { lat: 48.4761039, lng: 19.7809298 },
    { lat: 48.4761961, lng: 19.7807715 },
    { lat: 48.4767366, lng: 19.7807618 },
    { lat: 48.4769149, lng: 19.7806663 },
    { lat: 48.4770936, lng: 19.7806823 },
    { lat: 48.4771865, lng: 19.7805885 },
    { lat: 48.477442, lng: 19.7806647 },
    { lat: 48.4781094, lng: 19.7803612 },
    { lat: 48.4781498, lng: 19.7804587 },
    { lat: 48.4785352, lng: 19.7805629 },
    { lat: 48.47957, lng: 19.7804771 },
    { lat: 48.4797359, lng: 19.7806097 },
    { lat: 48.4798459, lng: 19.7805988 },
    { lat: 48.4798978, lng: 19.7807055 },
    { lat: 48.4800316, lng: 19.7806716 },
    { lat: 48.4804693, lng: 19.7808383 },
    { lat: 48.4810608, lng: 19.7812235 },
    { lat: 48.4814496, lng: 19.7816628 },
    { lat: 48.4820469, lng: 19.7821287 },
    { lat: 48.4825046, lng: 19.7823919 },
    { lat: 48.4828392, lng: 19.7824669 },
    { lat: 48.4833423, lng: 19.7827359 },
    { lat: 48.4842953, lng: 19.7834005 },
    { lat: 48.4847182, lng: 19.7837957 },
    { lat: 48.4862386, lng: 19.7845532 },
    { lat: 48.4872976, lng: 19.7848579 },
    { lat: 48.4879124, lng: 19.7851598 },
    { lat: 48.4885773, lng: 19.7851648 },
    { lat: 48.4897692, lng: 19.7855178 },
    { lat: 48.4908255, lng: 19.7859212 },
    { lat: 48.4912598, lng: 19.7863664 },
    { lat: 48.4917732, lng: 19.786719 },
    { lat: 48.4926216, lng: 19.7874652 },
    { lat: 48.4931338, lng: 19.7875873 },
    { lat: 48.4934157, lng: 19.7877423 },
    { lat: 48.4937167, lng: 19.787516 },
    { lat: 48.4950208, lng: 19.7861692 },
    { lat: 48.4961377, lng: 19.7858449 },
    { lat: 48.4994005, lng: 19.7828626 },
    { lat: 48.5005803, lng: 19.7808906 },
    { lat: 48.5014199, lng: 19.7791765 },
    { lat: 48.5024841, lng: 19.7772905 },
    { lat: 48.5038336, lng: 19.7754914 },
    { lat: 48.5044007, lng: 19.7751635 },
    { lat: 48.5050112, lng: 19.7746992 },
    { lat: 48.5059736, lng: 19.7742442 },
    { lat: 48.5067963, lng: 19.7741453 },
    { lat: 48.5083371, lng: 19.7734696 },
    { lat: 48.5094888, lng: 19.7721916 },
    { lat: 48.5100381, lng: 19.7714114 },
    { lat: 48.5117491, lng: 19.7702471 },
    { lat: 48.5124491, lng: 19.7694624 },
    { lat: 48.5124229, lng: 19.7694032 },
    { lat: 48.5122306, lng: 19.7669542 },
    { lat: 48.5118783, lng: 19.7650655 },
    { lat: 48.5118972, lng: 19.7645782 },
    { lat: 48.5115101, lng: 19.7633831 },
    { lat: 48.5110985, lng: 19.762991 },
    { lat: 48.5103617, lng: 19.7609446 },
    { lat: 48.5101421, lng: 19.7606709 },
    { lat: 48.5096143, lng: 19.7602226 },
    { lat: 48.5089221, lng: 19.7597793 },
    { lat: 48.508453, lng: 19.7591322 },
    { lat: 48.5078961, lng: 19.7580995 },
    { lat: 48.5075613, lng: 19.7573278 },
    { lat: 48.5070614, lng: 19.7565438 },
    { lat: 48.50635, lng: 19.7551034 },
    { lat: 48.5057437, lng: 19.7544626 },
    { lat: 48.5053051, lng: 19.7536658 },
    { lat: 48.5049242, lng: 19.7533053 },
    { lat: 48.5031732, lng: 19.7530003 },
    { lat: 48.5031143, lng: 19.7524555 },
    { lat: 48.5028427, lng: 19.7510754 },
    { lat: 48.5021302, lng: 19.7482859 },
    { lat: 48.5020974, lng: 19.7477943 },
    { lat: 48.5019599, lng: 19.7471359 },
    { lat: 48.5019354, lng: 19.746884 },
    { lat: 48.5020289, lng: 19.745839 },
    { lat: 48.5021613, lng: 19.7452378 },
    { lat: 48.5022094, lng: 19.7447725 },
    { lat: 48.5021578, lng: 19.7442412 },
    { lat: 48.5022093, lng: 19.7441085 },
    { lat: 48.5021766, lng: 19.7437252 },
    { lat: 48.5020441, lng: 19.7436078 },
    { lat: 48.5019899, lng: 19.7432256 },
    { lat: 48.5020735, lng: 19.7430506 },
    { lat: 48.5020599, lng: 19.7427699 },
    { lat: 48.501924, lng: 19.7426758 },
    { lat: 48.5019691, lng: 19.7425001 },
    { lat: 48.5018957, lng: 19.7424193 },
    { lat: 48.5019289, lng: 19.741618 },
    { lat: 48.5018181, lng: 19.7413748 },
    { lat: 48.5017225, lng: 19.7413552 },
    { lat: 48.5006572, lng: 19.739968 },
    { lat: 48.5005481, lng: 19.7394123 },
    { lat: 48.5004861, lng: 19.7393599 },
    { lat: 48.5004146, lng: 19.7390409 },
    { lat: 48.500362, lng: 19.7390263 },
    { lat: 48.5003368, lng: 19.7388139 },
    { lat: 48.5001734, lng: 19.7384299 },
    { lat: 48.5000945, lng: 19.7380989 },
    { lat: 48.4996726, lng: 19.7378857 },
    { lat: 48.4996226, lng: 19.737593 },
    { lat: 48.4997615, lng: 19.7372224 },
    { lat: 48.4996966, lng: 19.7368751 },
    { lat: 48.4997166, lng: 19.736559 },
    { lat: 48.4994943, lng: 19.7363003 },
    { lat: 48.4995352, lng: 19.7359509 },
    { lat: 48.4994829, lng: 19.7357132 },
  ],
  VeľkáVes: [
    { lat: 48.365857, lng: 19.691961 },
    { lat: 48.3672215, lng: 19.6927571 },
    { lat: 48.3677522, lng: 19.6928623 },
    { lat: 48.3680586, lng: 19.6927332 },
    { lat: 48.3683108, lng: 19.6925008 },
    { lat: 48.3686401, lng: 19.6920052 },
    { lat: 48.3690639, lng: 19.6908096 },
    { lat: 48.3691233, lng: 19.6906421 },
    { lat: 48.3692812, lng: 19.6904202 },
    { lat: 48.3696065, lng: 19.6902124 },
    { lat: 48.3770066, lng: 19.6871329 },
    { lat: 48.3768614, lng: 19.6863051 },
    { lat: 48.376559, lng: 19.6833791 },
    { lat: 48.3797423, lng: 19.6818544 },
    { lat: 48.37999, lng: 19.6826272 },
    { lat: 48.3858849, lng: 19.680235 },
    { lat: 48.3882057, lng: 19.6793717 },
    { lat: 48.3893914, lng: 19.6786508 },
    { lat: 48.3904854, lng: 19.6778201 },
    { lat: 48.3934595, lng: 19.6748714 },
    { lat: 48.3957808, lng: 19.6736208 },
    { lat: 48.3978809, lng: 19.6722614 },
    { lat: 48.398373, lng: 19.6718534 },
    { lat: 48.3996282, lng: 19.6715531 },
    { lat: 48.399794, lng: 19.671618 },
    { lat: 48.400664, lng: 19.671404 },
    { lat: 48.401177, lng: 19.670572 },
    { lat: 48.401182, lng: 19.670565 },
    { lat: 48.401435, lng: 19.669987 },
    { lat: 48.401912, lng: 19.66928 },
    { lat: 48.401918, lng: 19.669271 },
    { lat: 48.402378, lng: 19.668556 },
    { lat: 48.402475, lng: 19.66804 },
    { lat: 48.402943, lng: 19.667547 },
    { lat: 48.403236, lng: 19.666998 },
    { lat: 48.403466, lng: 19.666267 },
    { lat: 48.404611, lng: 19.665471 },
    { lat: 48.40501, lng: 19.665393 },
    { lat: 48.405584, lng: 19.665384 },
    { lat: 48.405891, lng: 19.664548 },
    { lat: 48.406096, lng: 19.663787 },
    { lat: 48.406386, lng: 19.662898 },
    { lat: 48.406595, lng: 19.661936 },
    { lat: 48.406775, lng: 19.661316 },
    { lat: 48.406955, lng: 19.66086 },
    { lat: 48.407016, lng: 19.659952 },
    { lat: 48.407062, lng: 19.658556 },
    { lat: 48.407112, lng: 19.65797 },
    { lat: 48.407114, lng: 19.65796 },
    { lat: 48.407219, lng: 19.657372 },
    { lat: 48.407222, lng: 19.657339 },
    { lat: 48.407211, lng: 19.656902 },
    { lat: 48.407419, lng: 19.655861 },
    { lat: 48.407819, lng: 19.654906 },
    { lat: 48.407562, lng: 19.65448 },
    { lat: 48.407356, lng: 19.653523 },
    { lat: 48.406406, lng: 19.652956 },
    { lat: 48.405606, lng: 19.652402 },
    { lat: 48.405048, lng: 19.652095 },
    { lat: 48.404121, lng: 19.651633 },
    { lat: 48.403515, lng: 19.651312 },
    { lat: 48.403487, lng: 19.651297 },
    { lat: 48.40264, lng: 19.650322 },
    { lat: 48.402425, lng: 19.649954 },
    { lat: 48.402294, lng: 19.64929 },
    { lat: 48.402079, lng: 19.648859 },
    { lat: 48.401497, lng: 19.648205 },
    { lat: 48.40025, lng: 19.647654 },
    { lat: 48.399279, lng: 19.646387 },
    { lat: 48.398757, lng: 19.646181 },
    { lat: 48.397752, lng: 19.645568 },
    { lat: 48.396829, lng: 19.644802 },
    { lat: 48.396356, lng: 19.644587 },
    { lat: 48.396217, lng: 19.644513 },
    { lat: 48.39612, lng: 19.644484 },
    { lat: 48.395157, lng: 19.644556 },
    { lat: 48.394067, lng: 19.644838 },
    { lat: 48.393725, lng: 19.644875 },
    { lat: 48.393396, lng: 19.645076 },
    { lat: 48.392511, lng: 19.645191 },
    { lat: 48.392477, lng: 19.645257 },
    { lat: 48.392112, lng: 19.645956 },
    { lat: 48.391961, lng: 19.646232 },
    { lat: 48.391553, lng: 19.646986 },
    { lat: 48.391533, lng: 19.647024 },
    { lat: 48.39119, lng: 19.646981 },
    { lat: 48.390221, lng: 19.647029 },
    { lat: 48.390146, lng: 19.647018 },
    { lat: 48.38963, lng: 19.6469 },
    { lat: 48.389512, lng: 19.646836 },
    { lat: 48.388912, lng: 19.646409 },
    { lat: 48.388797, lng: 19.646334 },
    { lat: 48.388314, lng: 19.64599 },
    { lat: 48.387774, lng: 19.646216 },
    { lat: 48.387644, lng: 19.64612 },
    { lat: 48.387585, lng: 19.646096 },
    { lat: 48.387324, lng: 19.646088 },
    { lat: 48.387046, lng: 19.646352 },
    { lat: 48.386873, lng: 19.646158 },
    { lat: 48.386524, lng: 19.646211 },
    { lat: 48.386474, lng: 19.646361 },
    { lat: 48.386107, lng: 19.646232 },
    { lat: 48.386065, lng: 19.646222 },
    { lat: 48.385697, lng: 19.646238 },
    { lat: 48.385668, lng: 19.646237 },
    { lat: 48.385208, lng: 19.646013 },
    { lat: 48.385166, lng: 19.646001 },
    { lat: 48.384894, lng: 19.64612 },
    { lat: 48.384448, lng: 19.645364 },
    { lat: 48.38415, lng: 19.644872 },
    { lat: 48.384067, lng: 19.644799 },
    { lat: 48.384073, lng: 19.644757 },
    { lat: 48.382915, lng: 19.643466 },
    { lat: 48.382773, lng: 19.643311 },
    { lat: 48.382701, lng: 19.643323 },
    { lat: 48.382688, lng: 19.643327 },
    { lat: 48.382649, lng: 19.643337 },
    { lat: 48.382607, lng: 19.643337 },
    { lat: 48.382325, lng: 19.643769 },
    { lat: 48.382123, lng: 19.643811 },
    { lat: 48.381906, lng: 19.644059 },
    { lat: 48.381813, lng: 19.644088 },
    { lat: 48.381722, lng: 19.644043 },
    { lat: 48.381595, lng: 19.644094 },
    { lat: 48.381489, lng: 19.644151 },
    { lat: 48.381304, lng: 19.644192 },
    { lat: 48.381066, lng: 19.644317 },
    { lat: 48.380982, lng: 19.644287 },
    { lat: 48.380838, lng: 19.644294 },
    { lat: 48.380529, lng: 19.644536 },
    { lat: 48.380148, lng: 19.644702 },
    { lat: 48.37978, lng: 19.64484 },
    { lat: 48.379634, lng: 19.644921 },
    { lat: 48.379445, lng: 19.64505 },
    { lat: 48.379281, lng: 19.64523 },
    { lat: 48.37901, lng: 19.645517 },
    { lat: 48.378877, lng: 19.645697 },
    { lat: 48.378443, lng: 19.645799 },
    { lat: 48.378348, lng: 19.645883 },
    { lat: 48.378348, lng: 19.646006 },
    { lat: 48.37822, lng: 19.646285 },
    { lat: 48.378222, lng: 19.646287 },
    { lat: 48.378735, lng: 19.64679 },
    { lat: 48.379103, lng: 19.64715 },
    { lat: 48.379198, lng: 19.647286 },
    { lat: 48.379222, lng: 19.647321 },
    { lat: 48.379835, lng: 19.648196 },
    { lat: 48.380119, lng: 19.649775 },
    { lat: 48.380063, lng: 19.651277 },
    { lat: 48.379687, lng: 19.65215 },
    { lat: 48.379459, lng: 19.65263 },
    { lat: 48.379109, lng: 19.653383 },
    { lat: 48.379365, lng: 19.65504 },
    { lat: 48.379149, lng: 19.655675 },
    { lat: 48.379106, lng: 19.655802 },
    { lat: 48.378393, lng: 19.656958 },
    { lat: 48.37767, lng: 19.658152 },
    { lat: 48.377226, lng: 19.65894 },
    { lat: 48.377172, lng: 19.65901 },
    { lat: 48.376718, lng: 19.659527 },
    { lat: 48.376484, lng: 19.659833 },
    { lat: 48.375878, lng: 19.660631 },
    { lat: 48.375714, lng: 19.660839 },
    { lat: 48.375649, lng: 19.660923 },
    { lat: 48.374666, lng: 19.662176 },
    { lat: 48.373821, lng: 19.663038 },
    { lat: 48.372798, lng: 19.66395 },
    { lat: 48.371426, lng: 19.664937 },
    { lat: 48.370467, lng: 19.665471 },
    { lat: 48.37043, lng: 19.665514 },
    { lat: 48.370127, lng: 19.66587 },
    { lat: 48.370054, lng: 19.665955 },
    { lat: 48.369784, lng: 19.66642 },
    { lat: 48.369455, lng: 19.667242 },
    { lat: 48.369432, lng: 19.667326 },
    { lat: 48.369221, lng: 19.668107 },
    { lat: 48.36875, lng: 19.668827 },
    { lat: 48.36873, lng: 19.668881 },
    { lat: 48.368061, lng: 19.669952 },
    { lat: 48.366983, lng: 19.671279 },
    { lat: 48.366465, lng: 19.671328 },
    { lat: 48.365636, lng: 19.671534 },
    { lat: 48.365195, lng: 19.671861 },
    { lat: 48.364538, lng: 19.671788 },
    { lat: 48.363972, lng: 19.671886 },
    { lat: 48.363099, lng: 19.673016 },
    { lat: 48.362163, lng: 19.67388 },
    { lat: 48.362197, lng: 19.675474 },
    { lat: 48.36211, lng: 19.675999 },
    { lat: 48.361784, lng: 19.677946 },
    { lat: 48.361435, lng: 19.679663 },
    { lat: 48.361137, lng: 19.680094 },
    { lat: 48.36094, lng: 19.680379 },
    { lat: 48.360679, lng: 19.681039 },
    { lat: 48.360101, lng: 19.684145 },
    { lat: 48.360054, lng: 19.684395 },
    { lat: 48.360055, lng: 19.684401 },
    { lat: 48.360056, lng: 19.684427 },
    { lat: 48.360057, lng: 19.684449 },
    { lat: 48.360057, lng: 19.684455 },
    { lat: 48.360061, lng: 19.684512 },
    { lat: 48.360109, lng: 19.684853 },
    { lat: 48.360259, lng: 19.685571 },
    { lat: 48.360292, lng: 19.68583 },
    { lat: 48.360293, lng: 19.685898 },
    { lat: 48.360319, lng: 19.685895 },
    { lat: 48.360323, lng: 19.685896 },
    { lat: 48.360382, lng: 19.685901 },
    { lat: 48.360387, lng: 19.685902 },
    { lat: 48.360651, lng: 19.685977 },
    { lat: 48.360694, lng: 19.685988 },
    { lat: 48.360731, lng: 19.685999 },
    { lat: 48.360758, lng: 19.686007 },
    { lat: 48.360785, lng: 19.686014 },
    { lat: 48.360808, lng: 19.68602 },
    { lat: 48.361074, lng: 19.686189 },
    { lat: 48.361273, lng: 19.68643 },
    { lat: 48.361316, lng: 19.686536 },
    { lat: 48.361334, lng: 19.686584 },
    { lat: 48.361355, lng: 19.686632 },
    { lat: 48.36145, lng: 19.686872 },
    { lat: 48.361513, lng: 19.687167 },
    { lat: 48.361528, lng: 19.687281 },
    { lat: 48.361533, lng: 19.687314 },
    { lat: 48.361537, lng: 19.687348 },
    { lat: 48.361602, lng: 19.687827 },
    { lat: 48.361606, lng: 19.687856 },
    { lat: 48.36161, lng: 19.687884 },
    { lat: 48.361626, lng: 19.688004 },
    { lat: 48.361631, lng: 19.688036 },
    { lat: 48.361635, lng: 19.688068 },
    { lat: 48.361679, lng: 19.68839 },
    { lat: 48.361778, lng: 19.688855 },
    { lat: 48.361915, lng: 19.689277 },
    { lat: 48.362148, lng: 19.689674 },
    { lat: 48.362618, lng: 19.690085 },
    { lat: 48.36382, lng: 19.690788 },
    { lat: 48.364808, lng: 19.691346 },
    { lat: 48.36485, lng: 19.691381 },
    { lat: 48.365797, lng: 19.691925 },
    { lat: 48.365802, lng: 19.691927 },
    { lat: 48.365852, lng: 19.691958 },
    { lat: 48.365857, lng: 19.691961 },
  ],
  Málinec: [
    { lat: 48.519019, lng: 19.625704 },
    { lat: 48.518697, lng: 19.6261499 },
    { lat: 48.5180164, lng: 19.6273698 },
    { lat: 48.517897, lng: 19.6280186 },
    { lat: 48.5178364, lng: 19.6288728 },
    { lat: 48.5178954, lng: 19.6297532 },
    { lat: 48.5175918, lng: 19.6313126 },
    { lat: 48.5172518, lng: 19.6317935 },
    { lat: 48.516955, lng: 19.6328066 },
    { lat: 48.5160838, lng: 19.6345761 },
    { lat: 48.515636, lng: 19.6352375 },
    { lat: 48.5152297, lng: 19.6356988 },
    { lat: 48.5149371, lng: 19.6374567 },
    { lat: 48.5151499, lng: 19.6377228 },
    { lat: 48.514532, lng: 19.6381162 },
    { lat: 48.5131162, lng: 19.6388551 },
    { lat: 48.512691, lng: 19.6392959 },
    { lat: 48.5122668, lng: 19.6396058 },
    { lat: 48.5117139, lng: 19.6398923 },
    { lat: 48.510556, lng: 19.6402753 },
    { lat: 48.5102212, lng: 19.6401973 },
    { lat: 48.5085974, lng: 19.6388736 },
    { lat: 48.5078501, lng: 19.6381401 },
    { lat: 48.5072406, lng: 19.6393694 },
    { lat: 48.5070379, lng: 19.6400475 },
    { lat: 48.506195, lng: 19.6406915 },
    { lat: 48.505518, lng: 19.6421454 },
    { lat: 48.5050648, lng: 19.6428661 },
    { lat: 48.5050309, lng: 19.6430622 },
    { lat: 48.5046656, lng: 19.6436328 },
    { lat: 48.5044314, lng: 19.6442878 },
    { lat: 48.5037368, lng: 19.6456251 },
    { lat: 48.5034693, lng: 19.6460404 },
    { lat: 48.5028611, lng: 19.647895 },
    { lat: 48.5026097, lng: 19.6484956 },
    { lat: 48.5018788, lng: 19.6491521 },
    { lat: 48.5011938, lng: 19.6497073 },
    { lat: 48.5004473, lng: 19.6505431 },
    { lat: 48.4996287, lng: 19.6515837 },
    { lat: 48.4992337, lng: 19.652218 },
    { lat: 48.4988763, lng: 19.6529444 },
    { lat: 48.4987082, lng: 19.6535112 },
    { lat: 48.4983631, lng: 19.6540586 },
    { lat: 48.497236, lng: 19.6549274 },
    { lat: 48.4966353, lng: 19.6556994 },
    { lat: 48.4963527, lng: 19.6565542 },
    { lat: 48.4960583, lng: 19.6571498 },
    { lat: 48.4959002, lng: 19.657843 },
    { lat: 48.4956718, lng: 19.6582787 },
    { lat: 48.4945157, lng: 19.659232 },
    { lat: 48.493268, lng: 19.6599764 },
    { lat: 48.4917645, lng: 19.6617237 },
    { lat: 48.4913601, lng: 19.6627758 },
    { lat: 48.4909176, lng: 19.6630591 },
    { lat: 48.4901194, lng: 19.664407 },
    { lat: 48.4897787, lng: 19.6645706 },
    { lat: 48.4895319, lng: 19.664822 },
    { lat: 48.4890492, lng: 19.6650863 },
    { lat: 48.4886628, lng: 19.6656897 },
    { lat: 48.4882282, lng: 19.6658752 },
    { lat: 48.4876987, lng: 19.6664306 },
    { lat: 48.4875511, lng: 19.6667546 },
    { lat: 48.4871404, lng: 19.6671213 },
    { lat: 48.4862509, lng: 19.6684133 },
    { lat: 48.4861037, lng: 19.6691745 },
    { lat: 48.4857982, lng: 19.6689708 },
    { lat: 48.4853533, lng: 19.670631 },
    { lat: 48.4849182, lng: 19.6713053 },
    { lat: 48.484767, lng: 19.6721048 },
    { lat: 48.4845061, lng: 19.6727458 },
    { lat: 48.4843844, lng: 19.6728963 },
    { lat: 48.484266, lng: 19.6729211 },
    { lat: 48.4839995, lng: 19.6733069 },
    { lat: 48.4834248, lng: 19.6738074 },
    { lat: 48.4824605, lng: 19.6754704 },
    { lat: 48.4831227, lng: 19.6766691 },
    { lat: 48.4843829, lng: 19.6785405 },
    { lat: 48.4849426, lng: 19.6796452 },
    { lat: 48.4838656, lng: 19.6820108 },
    { lat: 48.4853178, lng: 19.6838679 },
    { lat: 48.4856367, lng: 19.6839205 },
    { lat: 48.4859005, lng: 19.6841264 },
    { lat: 48.4864545, lng: 19.6847981 },
    { lat: 48.4869418, lng: 19.6858903 },
    { lat: 48.4860793, lng: 19.6862816 },
    { lat: 48.4869221, lng: 19.6884472 },
    { lat: 48.4874914, lng: 19.6880265 },
    { lat: 48.489374, lng: 19.6948058 },
    { lat: 48.4884998, lng: 19.6966755 },
    { lat: 48.4881546, lng: 19.6972186 },
    { lat: 48.487663, lng: 19.6975425 },
    { lat: 48.487223, lng: 19.697702 },
    { lat: 48.4868445, lng: 19.6977356 },
    { lat: 48.4863596, lng: 19.6979263 },
    { lat: 48.4856215, lng: 19.6986295 },
    { lat: 48.4852968, lng: 19.6991781 },
    { lat: 48.4848743, lng: 19.7004461 },
    { lat: 48.4857104, lng: 19.7011047 },
    { lat: 48.4853406, lng: 19.7023084 },
    { lat: 48.4853703, lng: 19.7030026 },
    { lat: 48.4851932, lng: 19.7033238 },
    { lat: 48.4850526, lng: 19.7053024 },
    { lat: 48.4847629, lng: 19.7064103 },
    { lat: 48.4853844, lng: 19.7064268 },
    { lat: 48.4858012, lng: 19.706324 },
    { lat: 48.4865128, lng: 19.7059827 },
    { lat: 48.4875084, lng: 19.704127 },
    { lat: 48.4887076, lng: 19.7023692 },
    { lat: 48.4892615, lng: 19.7018505 },
    { lat: 48.4901609, lng: 19.7015292 },
    { lat: 48.4919721, lng: 19.7011844 },
    { lat: 48.4925266, lng: 19.7014946 },
    { lat: 48.4930848, lng: 19.7014781 },
    { lat: 48.4945566, lng: 19.7016826 },
    { lat: 48.4947959, lng: 19.7018229 },
    { lat: 48.4950623, lng: 19.7018567 },
    { lat: 48.4959021, lng: 19.702469 },
    { lat: 48.4967087, lng: 19.7037038 },
    { lat: 48.4972626, lng: 19.7042553 },
    { lat: 48.4979086, lng: 19.7044136 },
    { lat: 48.4988333, lng: 19.7043786 },
    { lat: 48.4994506, lng: 19.7048805 },
    { lat: 48.499561, lng: 19.7051578 },
    { lat: 48.5004782, lng: 19.7060941 },
    { lat: 48.5011464, lng: 19.7066769 },
    { lat: 48.5016754, lng: 19.7061347 },
    { lat: 48.5030914, lng: 19.7051398 },
    { lat: 48.5034685, lng: 19.7051089 },
    { lat: 48.5052023, lng: 19.7038641 },
    { lat: 48.50572, lng: 19.7036186 },
    { lat: 48.5059552, lng: 19.7036396 },
    { lat: 48.5066064, lng: 19.7034616 },
    { lat: 48.5071629, lng: 19.7035355 },
    { lat: 48.5077038, lng: 19.7031049 },
    { lat: 48.5079313, lng: 19.7031289 },
    { lat: 48.5088857, lng: 19.7023464 },
    { lat: 48.5089957, lng: 19.7021135 },
    { lat: 48.5107514, lng: 19.7010273 },
    { lat: 48.5109554, lng: 19.7007042 },
    { lat: 48.5110857, lng: 19.7007195 },
    { lat: 48.5112701, lng: 19.7006074 },
    { lat: 48.5114377, lng: 19.7006001 },
    { lat: 48.5128488, lng: 19.6996232 },
    { lat: 48.5132196, lng: 19.6990225 },
    { lat: 48.5141506, lng: 19.6982919 },
    { lat: 48.514737, lng: 19.6980265 },
    { lat: 48.5149839, lng: 19.6974522 },
    { lat: 48.5153862, lng: 19.6968969 },
    { lat: 48.5155297, lng: 19.6968259 },
    { lat: 48.5164619, lng: 19.6954473 },
    { lat: 48.5170454, lng: 19.6949653 },
    { lat: 48.5173744, lng: 19.6948887 },
    { lat: 48.5177515, lng: 19.6946526 },
    { lat: 48.5183148, lng: 19.6946497 },
    { lat: 48.5193401, lng: 19.6938174 },
    { lat: 48.5203341, lng: 19.6932658 },
    { lat: 48.5218429, lng: 19.692612 },
    { lat: 48.5223665, lng: 19.6924769 },
    { lat: 48.5236243, lng: 19.6923837 },
    { lat: 48.5248364, lng: 19.6924815 },
    { lat: 48.5257328, lng: 19.6928976 },
    { lat: 48.5272927, lng: 19.6937667 },
    { lat: 48.5287154, lng: 19.6941074 },
    { lat: 48.5288895, lng: 19.6941491 },
    { lat: 48.5301388, lng: 19.6949526 },
    { lat: 48.5319943, lng: 19.6955019 },
    { lat: 48.5323631, lng: 19.6976819 },
    { lat: 48.5326516, lng: 19.6997573 },
    { lat: 48.5348512, lng: 19.6996836 },
    { lat: 48.5362888, lng: 19.6990715 },
    { lat: 48.5374887, lng: 19.6994277 },
    { lat: 48.537522, lng: 19.6993642 },
    { lat: 48.5377763, lng: 19.6996603 },
    { lat: 48.5380062, lng: 19.6996118 },
    { lat: 48.5382601, lng: 19.6997454 },
    { lat: 48.5385137, lng: 19.6996721 },
    { lat: 48.5402395, lng: 19.7019365 },
    { lat: 48.5418897, lng: 19.7046075 },
    { lat: 48.5427595, lng: 19.7061255 },
    { lat: 48.5427474, lng: 19.7061825 },
    { lat: 48.5442744, lng: 19.7093026 },
    { lat: 48.5444253, lng: 19.7140664 },
    { lat: 48.5441639, lng: 19.7154311 },
    { lat: 48.5441134, lng: 19.7167554 },
    { lat: 48.5439158, lng: 19.7171284 },
    { lat: 48.5440457, lng: 19.7178215 },
    { lat: 48.5441418, lng: 19.7181614 },
    { lat: 48.5443835, lng: 19.7198449 },
    { lat: 48.5464534, lng: 19.7217281 },
    { lat: 48.5468616, lng: 19.7232061 },
    { lat: 48.5505156, lng: 19.7233075 },
    { lat: 48.5513844, lng: 19.7232869 },
    { lat: 48.5533995, lng: 19.7234013 },
    { lat: 48.5545364, lng: 19.7233492 },
    { lat: 48.554677, lng: 19.7234214 },
    { lat: 48.5551697, lng: 19.7239647 },
    { lat: 48.5556764, lng: 19.7250245 },
    { lat: 48.5570172, lng: 19.7263176 },
    { lat: 48.5574546, lng: 19.7270617 },
    { lat: 48.5576818, lng: 19.7281176 },
    { lat: 48.5582323, lng: 19.7292022 },
    { lat: 48.5592961, lng: 19.7301225 },
    { lat: 48.5604056, lng: 19.7303151 },
    { lat: 48.5604116, lng: 19.7302476 },
    { lat: 48.5611236, lng: 19.7296382 },
    { lat: 48.5615181, lng: 19.7302257 },
    { lat: 48.5620818, lng: 19.7298275 },
    { lat: 48.562618, lng: 19.7294179 },
    { lat: 48.5631459, lng: 19.7288455 },
    { lat: 48.563395, lng: 19.7281589 },
    { lat: 48.5637807, lng: 19.7277907 },
    { lat: 48.5640971, lng: 19.7275831 },
    { lat: 48.5643107, lng: 19.7279273 },
    { lat: 48.5651653, lng: 19.7277339 },
    { lat: 48.5663091, lng: 19.728451 },
    { lat: 48.5678925, lng: 19.7296521 },
    { lat: 48.5683559, lng: 19.729762 },
    { lat: 48.5692589, lng: 19.7304859 },
    { lat: 48.5700998, lng: 19.7313829 },
    { lat: 48.5716003, lng: 19.7325751 },
    { lat: 48.5715996, lng: 19.7326609 },
    { lat: 48.5718199, lng: 19.732806 },
    { lat: 48.5733728, lng: 19.7349252 },
    { lat: 48.5744605, lng: 19.7359022 },
    { lat: 48.5745714, lng: 19.7357334 },
    { lat: 48.5746506, lng: 19.7357429 },
    { lat: 48.5749353, lng: 19.7354531 },
    { lat: 48.5751962, lng: 19.7357457 },
    { lat: 48.5753398, lng: 19.7363451 },
    { lat: 48.5753282, lng: 19.7367454 },
    { lat: 48.5757916, lng: 19.7375176 },
    { lat: 48.5760371, lng: 19.7375659 },
    { lat: 48.5762408, lng: 19.7373565 },
    { lat: 48.5762734, lng: 19.7370202 },
    { lat: 48.5764438, lng: 19.7373325 },
    { lat: 48.5768933, lng: 19.7360339 },
    { lat: 48.5780241, lng: 19.7361762 },
    { lat: 48.5782141, lng: 19.7359877 },
    { lat: 48.5783292, lng: 19.7364231 },
    { lat: 48.5788624, lng: 19.7374768 },
    { lat: 48.5790467, lng: 19.7373139 },
    { lat: 48.5794143, lng: 19.7373677 },
    { lat: 48.5795412, lng: 19.7377453 },
    { lat: 48.5798905, lng: 19.7372604 },
    { lat: 48.580096, lng: 19.7377533 },
    { lat: 48.5800469, lng: 19.7391441 },
    { lat: 48.580216, lng: 19.7396794 },
    { lat: 48.5804806, lng: 19.739903 },
    { lat: 48.5811978, lng: 19.7401253 },
    { lat: 48.5812908, lng: 19.7404458 },
    { lat: 48.5814335, lng: 19.7404315 },
    { lat: 48.5820487, lng: 19.7414287 },
    { lat: 48.5820998, lng: 19.7405173 },
    { lat: 48.5822812, lng: 19.7396276 },
    { lat: 48.5826494, lng: 19.7384224 },
    { lat: 48.5825782, lng: 19.7383645 },
    { lat: 48.5827616, lng: 19.738134 },
    { lat: 48.5832781, lng: 19.7370761 },
    { lat: 48.583407, lng: 19.7363956 },
    { lat: 48.5845294, lng: 19.7352548 },
    { lat: 48.5848351, lng: 19.7350821 },
    { lat: 48.5849511, lng: 19.7351327 },
    { lat: 48.5855401, lng: 19.7349753 },
    { lat: 48.5858985, lng: 19.7347984 },
    { lat: 48.5861905, lng: 19.7344733 },
    { lat: 48.5863997, lng: 19.7340832 },
    { lat: 48.586566, lng: 19.7332569 },
    { lat: 48.5868498, lng: 19.7327939 },
    { lat: 48.5899468, lng: 19.7334733 },
    { lat: 48.5920108, lng: 19.731382 },
    { lat: 48.5920221, lng: 19.7313588 },
    { lat: 48.5922326, lng: 19.730691 },
    { lat: 48.5926554, lng: 19.7283047 },
    { lat: 48.5925754, lng: 19.72828 },
    { lat: 48.5926451, lng: 19.7279715 },
    { lat: 48.5926865, lng: 19.7273278 },
    { lat: 48.5927685, lng: 19.7270676 },
    { lat: 48.5928933, lng: 19.7268575 },
    { lat: 48.5933084, lng: 19.72639 },
    { lat: 48.5935491, lng: 19.7253108 },
    { lat: 48.5937945, lng: 19.7247483 },
    { lat: 48.594379, lng: 19.724026 },
    { lat: 48.59448, lng: 19.7242714 },
    { lat: 48.5946332, lng: 19.7244243 },
    { lat: 48.595037, lng: 19.7244584 },
    { lat: 48.5953285, lng: 19.72402 },
    { lat: 48.5965847, lng: 19.7217806 },
    { lat: 48.596766, lng: 19.721316 },
    { lat: 48.596741, lng: 19.721231 },
    { lat: 48.594323, lng: 19.7163 },
    { lat: 48.594282, lng: 19.716222 },
    { lat: 48.593648, lng: 19.716705 },
    { lat: 48.593629, lng: 19.716656 },
    { lat: 48.593407, lng: 19.71635 },
    { lat: 48.59334, lng: 19.716258 },
    { lat: 48.593382, lng: 19.716106 },
    { lat: 48.593419, lng: 19.715974 },
    { lat: 48.59344, lng: 19.715894 },
    { lat: 48.593414, lng: 19.715315 },
    { lat: 48.593498, lng: 19.715294 },
    { lat: 48.593362, lng: 19.714272 },
    { lat: 48.592858, lng: 19.713995 },
    { lat: 48.592624, lng: 19.71357 },
    { lat: 48.59517, lng: 19.711336 },
    { lat: 48.594163, lng: 19.709719 },
    { lat: 48.59411, lng: 19.709652 },
    { lat: 48.593825, lng: 19.709054 },
    { lat: 48.589871, lng: 19.708433 },
    { lat: 48.589437, lng: 19.709616 },
    { lat: 48.588832, lng: 19.70923 },
    { lat: 48.588623, lng: 19.709944 },
    { lat: 48.588257, lng: 19.709012 },
    { lat: 48.587433, lng: 19.710178 },
    { lat: 48.587342, lng: 19.710265 },
    { lat: 48.587279, lng: 19.710426 },
    { lat: 48.587141, lng: 19.710208 },
    { lat: 48.586616, lng: 19.710632 },
    { lat: 48.586634, lng: 19.711047 },
    { lat: 48.586347, lng: 19.711072 },
    { lat: 48.585895, lng: 19.713006 },
    { lat: 48.585408, lng: 19.713775 },
    { lat: 48.584771, lng: 19.715607 },
    { lat: 48.583229, lng: 19.714817 },
    { lat: 48.583088, lng: 19.71548 },
    { lat: 48.582564, lng: 19.715258 },
    { lat: 48.582034, lng: 19.714443 },
    { lat: 48.581863, lng: 19.712872 },
    { lat: 48.582323, lng: 19.711587 },
    { lat: 48.582911, lng: 19.710511 },
    { lat: 48.582596, lng: 19.710576 },
    { lat: 48.582532, lng: 19.710601 },
    { lat: 48.578847, lng: 19.712012 },
    { lat: 48.578233, lng: 19.707139 },
    { lat: 48.578452, lng: 19.706084 },
    { lat: 48.578233, lng: 19.70412 },
    { lat: 48.577544, lng: 19.703099 },
    { lat: 48.577372, lng: 19.701475 },
    { lat: 48.576634, lng: 19.701123 },
    { lat: 48.577012, lng: 19.699999 },
    { lat: 48.576912, lng: 19.697142 },
    { lat: 48.575906, lng: 19.696751 },
    { lat: 48.575274, lng: 19.695444 },
    { lat: 48.574842, lng: 19.695039 },
    { lat: 48.57526, lng: 19.692846 },
    { lat: 48.575368, lng: 19.692776 },
    { lat: 48.575962, lng: 19.692747 },
    { lat: 48.574403, lng: 19.690568 },
    { lat: 48.573067, lng: 19.689024 },
    { lat: 48.572813, lng: 19.68876 },
    { lat: 48.573145, lng: 19.68849 },
    { lat: 48.573535, lng: 19.688128 },
    { lat: 48.574378, lng: 19.687186 },
    { lat: 48.574262, lng: 19.68543 },
    { lat: 48.57415, lng: 19.685007 },
    { lat: 48.574117, lng: 19.684877 },
    { lat: 48.573793, lng: 19.683739 },
    { lat: 48.573573, lng: 19.684051 },
    { lat: 48.573502, lng: 19.683387 },
    { lat: 48.573644, lng: 19.682976 },
    { lat: 48.573124, lng: 19.682997 },
    { lat: 48.573093, lng: 19.68233 },
    { lat: 48.572934, lng: 19.681495 },
    { lat: 48.575072, lng: 19.677646 },
    { lat: 48.574016, lng: 19.675364 },
    { lat: 48.573805, lng: 19.673891 },
    { lat: 48.57339, lng: 19.673416 },
    { lat: 48.572971, lng: 19.672613 },
    { lat: 48.572791, lng: 19.671375 },
    { lat: 48.572115, lng: 19.6701 },
    { lat: 48.571369, lng: 19.669839 },
    { lat: 48.571111, lng: 19.668947 },
    { lat: 48.571075, lng: 19.668958 },
    { lat: 48.570576, lng: 19.669107 },
    { lat: 48.570493, lng: 19.669214 },
    { lat: 48.569713, lng: 19.669449 },
    { lat: 48.569715, lng: 19.669322 },
    { lat: 48.569334, lng: 19.669448 },
    { lat: 48.569083, lng: 19.66879 },
    { lat: 48.569071, lng: 19.668235 },
    { lat: 48.568969, lng: 19.667659 },
    { lat: 48.568961, lng: 19.667114 },
    { lat: 48.568605, lng: 19.666297 },
    { lat: 48.567935, lng: 19.66677 },
    { lat: 48.567778, lng: 19.665258 },
    { lat: 48.568755, lng: 19.66449 },
    { lat: 48.568776, lng: 19.664321 },
    { lat: 48.568202, lng: 19.66373 },
    { lat: 48.567612, lng: 19.66434 },
    { lat: 48.56719, lng: 19.663414 },
    { lat: 48.567381, lng: 19.662581 },
    { lat: 48.566581, lng: 19.662654 },
    { lat: 48.566311, lng: 19.662467 },
    { lat: 48.565865, lng: 19.661587 },
    { lat: 48.565842, lng: 19.660152 },
    { lat: 48.565588, lng: 19.659774 },
    { lat: 48.565162, lng: 19.659594 },
    { lat: 48.564827, lng: 19.660134 },
    { lat: 48.564712, lng: 19.659975 },
    { lat: 48.564661, lng: 19.659906 },
    { lat: 48.563989, lng: 19.658982 },
    { lat: 48.563489, lng: 19.658537 },
    { lat: 48.563583, lng: 19.658191 },
    { lat: 48.564046, lng: 19.658144 },
    { lat: 48.56434, lng: 19.657333 },
    { lat: 48.563493, lng: 19.657017 },
    { lat: 48.56308, lng: 19.656808 },
    { lat: 48.562918, lng: 19.65642 },
    { lat: 48.562792, lng: 19.656425 },
    { lat: 48.562674, lng: 19.655848 },
    { lat: 48.562712, lng: 19.655661 },
    { lat: 48.562478, lng: 19.654997 },
    { lat: 48.562624, lng: 19.654634 },
    { lat: 48.563289, lng: 19.654579 },
    { lat: 48.563318, lng: 19.65372 },
    { lat: 48.563555, lng: 19.652199 },
    { lat: 48.562331, lng: 19.651237 },
    { lat: 48.562207, lng: 19.650593 },
    { lat: 48.562224, lng: 19.650053 },
    { lat: 48.5627, lng: 19.649854 },
    { lat: 48.563357, lng: 19.650168 },
    { lat: 48.56437, lng: 19.651185 },
    { lat: 48.565044, lng: 19.650271 },
    { lat: 48.564547, lng: 19.648594 },
    { lat: 48.564215, lng: 19.647008 },
    { lat: 48.563555, lng: 19.646727 },
    { lat: 48.56343, lng: 19.646674 },
    { lat: 48.563267, lng: 19.646604 },
    { lat: 48.563214, lng: 19.646655 },
    { lat: 48.563167, lng: 19.6467 },
    { lat: 48.555895, lng: 19.653731 },
    { lat: 48.555862, lng: 19.653761 },
    { lat: 48.553308, lng: 19.656232 },
    { lat: 48.55351, lng: 19.655498 },
    { lat: 48.55352, lng: 19.655148 },
    { lat: 48.553743, lng: 19.653982 },
    { lat: 48.553482, lng: 19.653845 },
    { lat: 48.55345, lng: 19.653828 },
    { lat: 48.552037, lng: 19.653088 },
    { lat: 48.552899, lng: 19.650203 },
    { lat: 48.550894, lng: 19.648114 },
    { lat: 48.55083, lng: 19.648078 },
    { lat: 48.549534, lng: 19.646744 },
    { lat: 48.54663, lng: 19.648214 },
    { lat: 48.54583, lng: 19.647645 },
    { lat: 48.545308, lng: 19.64535 },
    { lat: 48.546161, lng: 19.643618 },
    { lat: 48.546094, lng: 19.643371 },
    { lat: 48.546083, lng: 19.64332 },
    { lat: 48.545486, lng: 19.641053 },
    { lat: 48.542679, lng: 19.639802 },
    { lat: 48.541065, lng: 19.642826 },
    { lat: 48.540475, lng: 19.639507 },
    { lat: 48.540448, lng: 19.638906 },
    { lat: 48.540477, lng: 19.637961 },
    { lat: 48.539357, lng: 19.634608 },
    { lat: 48.539492, lng: 19.634372 },
    { lat: 48.535382, lng: 19.632756 },
    { lat: 48.527933, lng: 19.629615 },
    { lat: 48.527809, lng: 19.630379 },
    { lat: 48.526152, lng: 19.629643 },
    { lat: 48.524974, lng: 19.630824 },
    { lat: 48.524243, lng: 19.630505 },
    { lat: 48.523768, lng: 19.629792 },
    { lat: 48.522672, lng: 19.630684 },
    { lat: 48.52211, lng: 19.628537 },
    { lat: 48.521867, lng: 19.628649 },
    { lat: 48.521309, lng: 19.627555 },
    { lat: 48.520718, lng: 19.627038 },
    { lat: 48.520608, lng: 19.626943 },
    { lat: 48.520347, lng: 19.626926 },
    { lat: 48.519966, lng: 19.626309 },
    { lat: 48.519019, lng: 19.625704 },
  ],
  Utekáč: [
    { lat: 48.603688, lng: 19.832209 },
    { lat: 48.603896, lng: 19.832086 },
    { lat: 48.604521, lng: 19.831501 },
    { lat: 48.604909, lng: 19.831001 },
    { lat: 48.60546, lng: 19.830427 },
    { lat: 48.605689, lng: 19.830234 },
    { lat: 48.606355, lng: 19.829685 },
    { lat: 48.606725, lng: 19.829388 },
    { lat: 48.606902, lng: 19.829217 },
    { lat: 48.607793, lng: 19.828458 },
    { lat: 48.60877, lng: 19.827967 },
    { lat: 48.608799, lng: 19.827956 },
    { lat: 48.609425, lng: 19.827707 },
    { lat: 48.610456, lng: 19.827442 },
    { lat: 48.611175, lng: 19.827206 },
    { lat: 48.611428, lng: 19.827122 },
    { lat: 48.612099, lng: 19.826927 },
    { lat: 48.612362, lng: 19.826855 },
    { lat: 48.613001, lng: 19.826732 },
    { lat: 48.613252, lng: 19.826629 },
    { lat: 48.61362, lng: 19.82626 },
    { lat: 48.613997, lng: 19.825788 },
    { lat: 48.614278, lng: 19.825281 },
    { lat: 48.614473, lng: 19.824949 },
    { lat: 48.614954, lng: 19.82425 },
    { lat: 48.615571, lng: 19.823743 },
    { lat: 48.61601, lng: 19.822943 },
    { lat: 48.616148, lng: 19.821336 },
    { lat: 48.616187, lng: 19.820637 },
    { lat: 48.61625, lng: 19.819588 },
    { lat: 48.61646, lng: 19.818921 },
    { lat: 48.617094, lng: 19.81775 },
    { lat: 48.617563, lng: 19.8158 },
    { lat: 48.617722, lng: 19.815101 },
    { lat: 48.617943, lng: 19.814447 },
    { lat: 48.618361, lng: 19.813289 },
    { lat: 48.618498, lng: 19.813081 },
    { lat: 48.618624, lng: 19.812585 },
    { lat: 48.618631, lng: 19.81256 },
    { lat: 48.618747, lng: 19.8121 },
    { lat: 48.618941, lng: 19.811322 },
    { lat: 48.619211, lng: 19.810313 },
    { lat: 48.619438, lng: 19.81012 },
    { lat: 48.620002, lng: 19.810026 },
    { lat: 48.620677, lng: 19.809891 },
    { lat: 48.621116, lng: 19.809806 },
    { lat: 48.621499, lng: 19.809521 },
    { lat: 48.622124, lng: 19.809567 },
    { lat: 48.622766, lng: 19.809433 },
    { lat: 48.62293, lng: 19.809399 },
    { lat: 48.623115, lng: 19.809376 },
    { lat: 48.623191, lng: 19.808836 },
    { lat: 48.623475, lng: 19.806875 },
    { lat: 48.623792, lng: 19.805499 },
    { lat: 48.624049, lng: 19.804648 },
    { lat: 48.624303, lng: 19.804217 },
    { lat: 48.624552, lng: 19.803793 },
    { lat: 48.624993, lng: 19.80327 },
    { lat: 48.625385, lng: 19.802728 },
    { lat: 48.625544, lng: 19.801506 },
    { lat: 48.62573, lng: 19.800492 },
    { lat: 48.62581, lng: 19.800118 },
    { lat: 48.626004, lng: 19.799269 },
    { lat: 48.626019, lng: 19.798938 },
    { lat: 48.626036, lng: 19.798282 },
    { lat: 48.626004, lng: 19.797541 },
    { lat: 48.625943, lng: 19.79716 },
    { lat: 48.62589, lng: 19.795734 },
    { lat: 48.625767, lng: 19.794386 },
    { lat: 48.62575, lng: 19.794079 },
    { lat: 48.625782, lng: 19.792901 },
    { lat: 48.625839, lng: 19.792266 },
    { lat: 48.625865, lng: 19.792101 },
    { lat: 48.626016, lng: 19.791622 },
    { lat: 48.626166, lng: 19.791041 },
    { lat: 48.62661, lng: 19.790599 },
    { lat: 48.626742, lng: 19.790567 },
    { lat: 48.627326, lng: 19.790182 },
    { lat: 48.628035, lng: 19.789677 },
    { lat: 48.628177, lng: 19.789648 },
    { lat: 48.629334, lng: 19.789065 },
    { lat: 48.629361, lng: 19.789049 },
    { lat: 48.629468, lng: 19.788992 },
    { lat: 48.629886, lng: 19.788906 },
    { lat: 48.630113, lng: 19.788874 },
    { lat: 48.630607, lng: 19.788628 },
    { lat: 48.630894, lng: 19.78856 },
    { lat: 48.63121, lng: 19.788549 },
    { lat: 48.631253, lng: 19.788544 },
    { lat: 48.631284, lng: 19.788539 },
    { lat: 48.631308, lng: 19.788558 },
    { lat: 48.631615, lng: 19.78837 },
    { lat: 48.632071, lng: 19.788297 },
    { lat: 48.632884, lng: 19.788404 },
    { lat: 48.633287, lng: 19.788337 },
    { lat: 48.633742, lng: 19.788351 },
    { lat: 48.634142, lng: 19.788354 },
    { lat: 48.634433, lng: 19.788174 },
    { lat: 48.634829, lng: 19.787677 },
    { lat: 48.635172, lng: 19.78737 },
    { lat: 48.635943, lng: 19.786414 },
    { lat: 48.635867, lng: 19.785413 },
    { lat: 48.635981, lng: 19.783819 },
    { lat: 48.636189, lng: 19.783402 },
    { lat: 48.636296, lng: 19.783039 },
    { lat: 48.636467, lng: 19.78234 },
    { lat: 48.636777, lng: 19.781697 },
    { lat: 48.637063, lng: 19.781143 },
    { lat: 48.637106, lng: 19.780882 },
    { lat: 48.63729, lng: 19.780334 },
    { lat: 48.637509, lng: 19.780022 },
    { lat: 48.637885, lng: 19.779445 },
    { lat: 48.638154, lng: 19.778786 },
    { lat: 48.638441, lng: 19.778135 },
    { lat: 48.638625, lng: 19.777409 },
    { lat: 48.638709, lng: 19.777078 },
    { lat: 48.638975, lng: 19.776326 },
    { lat: 48.639037, lng: 19.776092 },
    { lat: 48.639218, lng: 19.775359 },
    { lat: 48.639392, lng: 19.774446 },
    { lat: 48.639379, lng: 19.774016 },
    { lat: 48.639373, lng: 19.773861 },
    { lat: 48.639328, lng: 19.773483 },
    { lat: 48.63938, lng: 19.773032 },
    { lat: 48.639455, lng: 19.771856 },
    { lat: 48.6396, lng: 19.771272 },
    { lat: 48.639617, lng: 19.770974 },
    { lat: 48.639627, lng: 19.770457 },
    { lat: 48.639569, lng: 19.769821 },
    { lat: 48.639586, lng: 19.769617 },
    { lat: 48.6396, lng: 19.769396 },
    { lat: 48.639593, lng: 19.768615 },
    { lat: 48.639611, lng: 19.768612 },
    { lat: 48.639588, lng: 19.768361 },
    { lat: 48.639431, lng: 19.766655 },
    { lat: 48.639414, lng: 19.765492 },
    { lat: 48.639701, lng: 19.764533 },
    { lat: 48.640106, lng: 19.763351 },
    { lat: 48.640504, lng: 19.762383 },
    { lat: 48.640821, lng: 19.76132 },
    { lat: 48.640959, lng: 19.760782 },
    { lat: 48.641049, lng: 19.760529 },
    { lat: 48.641712, lng: 19.75942 },
    { lat: 48.642071, lng: 19.758961 },
    { lat: 48.642701, lng: 19.757944 },
    { lat: 48.642804, lng: 19.757777 },
    { lat: 48.643119, lng: 19.757265 },
    { lat: 48.643821, lng: 19.756237 },
    { lat: 48.644418, lng: 19.755382 },
    { lat: 48.645554, lng: 19.75419 },
    { lat: 48.646081, lng: 19.753255 },
    { lat: 48.646365, lng: 19.752723 },
    { lat: 48.646391, lng: 19.752675 },
    { lat: 48.646499, lng: 19.752468 },
    { lat: 48.646546, lng: 19.752382 },
    { lat: 48.646667, lng: 19.752154 },
    { lat: 48.646784, lng: 19.751929 },
    { lat: 48.647498, lng: 19.750706 },
    { lat: 48.648181, lng: 19.749437 },
    { lat: 48.648777, lng: 19.748368 },
    { lat: 48.648666, lng: 19.747886 },
    { lat: 48.648478, lng: 19.747156 },
    { lat: 48.648176, lng: 19.746055 },
    { lat: 48.647915, lng: 19.745111 },
    { lat: 48.64764, lng: 19.744178 },
    { lat: 48.647634, lng: 19.744151 },
    { lat: 48.647623, lng: 19.744113 },
    { lat: 48.647415, lng: 19.743308 },
    { lat: 48.647401, lng: 19.743255 },
    { lat: 48.64734, lng: 19.743022 },
    { lat: 48.647017, lng: 19.741823 },
    { lat: 48.646583, lng: 19.740203 },
    { lat: 48.646275, lng: 19.739093 },
    { lat: 48.646265, lng: 19.739055 },
    { lat: 48.646248, lng: 19.738994 },
    { lat: 48.646051, lng: 19.738294 },
    { lat: 48.645906, lng: 19.737861 },
    { lat: 48.645719, lng: 19.737302 },
    { lat: 48.645304, lng: 19.736095 },
    { lat: 48.645152, lng: 19.735622 },
    { lat: 48.645077, lng: 19.735388 },
    { lat: 48.644992, lng: 19.735124 },
    { lat: 48.644853, lng: 19.73444 },
    { lat: 48.644841, lng: 19.734386 },
    { lat: 48.64484, lng: 19.734382 },
    { lat: 48.644728, lng: 19.733809 },
    { lat: 48.644404, lng: 19.732639 },
    { lat: 48.644059, lng: 19.731579 },
    { lat: 48.643838, lng: 19.730795 },
    { lat: 48.643617, lng: 19.730037 },
    { lat: 48.643512, lng: 19.729619 },
    { lat: 48.643491, lng: 19.72954 },
    { lat: 48.643269, lng: 19.728821 },
    { lat: 48.643077, lng: 19.728297 },
    { lat: 48.643005, lng: 19.728089 },
    { lat: 48.642927, lng: 19.72779 },
    { lat: 48.642715, lng: 19.72701 },
    { lat: 48.642522, lng: 19.726295 },
    { lat: 48.642344, lng: 19.725595 },
    { lat: 48.642216, lng: 19.725142 },
    { lat: 48.642029, lng: 19.724572 },
    { lat: 48.641777, lng: 19.723598 },
    { lat: 48.641625, lng: 19.722983 },
    { lat: 48.641537, lng: 19.722669 },
    { lat: 48.641461, lng: 19.722477 },
    { lat: 48.641293, lng: 19.7219 },
    { lat: 48.641197, lng: 19.72159 },
    { lat: 48.641104, lng: 19.721272 },
    { lat: 48.64104, lng: 19.721084 },
    { lat: 48.640955, lng: 19.720791 },
    { lat: 48.640851, lng: 19.720427 },
    { lat: 48.640782, lng: 19.720183 },
    { lat: 48.640523, lng: 19.719432 },
    { lat: 48.640278, lng: 19.718894 },
    { lat: 48.640241, lng: 19.718814 },
    { lat: 48.64013, lng: 19.71866 },
    { lat: 48.640111, lng: 19.718636 },
    { lat: 48.640069, lng: 19.718583 },
    { lat: 48.640047, lng: 19.718555 },
    { lat: 48.639993, lng: 19.718487 },
    { lat: 48.639964, lng: 19.718447 },
    { lat: 48.639917, lng: 19.718388 },
    { lat: 48.6398604, lng: 19.7184275 },
    { lat: 48.6397375, lng: 19.7188093 },
    { lat: 48.6396016, lng: 19.7189164 },
    { lat: 48.6394035, lng: 19.7189485 },
    { lat: 48.6392978, lng: 19.7191315 },
    { lat: 48.639235, lng: 19.7200541 },
    { lat: 48.639161, lng: 19.7203343 },
    { lat: 48.6390266, lng: 19.7208429 },
    { lat: 48.6388883, lng: 19.7211059 },
    { lat: 48.6388715, lng: 19.7212878 },
    { lat: 48.6389865, lng: 19.7216431 },
    { lat: 48.6389606, lng: 19.7220248 },
    { lat: 48.6391733, lng: 19.7225256 },
    { lat: 48.6391452, lng: 19.7233186 },
    { lat: 48.639173, lng: 19.7237176 },
    { lat: 48.6391595, lng: 19.7239449 },
    { lat: 48.6389949, lng: 19.7243035 },
    { lat: 48.6387406, lng: 19.7261164 },
    { lat: 48.6385658, lng: 19.726466 },
    { lat: 48.6383992, lng: 19.7266491 },
    { lat: 48.6381704, lng: 19.7267499 },
    { lat: 48.638021, lng: 19.7267353 },
    { lat: 48.6378117, lng: 19.7268437 },
    { lat: 48.637497, lng: 19.726579 },
    { lat: 48.6374091, lng: 19.7266559 },
    { lat: 48.6373342, lng: 19.7270493 },
    { lat: 48.6371879, lng: 19.7274355 },
    { lat: 48.6367834, lng: 19.7280488 },
    { lat: 48.6366412, lng: 19.7285475 },
    { lat: 48.6365798, lng: 19.7289217 },
    { lat: 48.6365623, lng: 19.7295935 },
    { lat: 48.6363921, lng: 19.7303295 },
    { lat: 48.6360627, lng: 19.7311364 },
    { lat: 48.6358619, lng: 19.7312617 },
    { lat: 48.6358384, lng: 19.7312863 },
    { lat: 48.6353429, lng: 19.7318048 },
    { lat: 48.6353941, lng: 19.7323272 },
    { lat: 48.6353879, lng: 19.7323485 },
    { lat: 48.6352564, lng: 19.7328017 },
    { lat: 48.6352062, lng: 19.7331715 },
    { lat: 48.6353044, lng: 19.7335093 },
    { lat: 48.6355391, lng: 19.7340271 },
    { lat: 48.6356147, lng: 19.7346379 },
    { lat: 48.6355716, lng: 19.7350401 },
    { lat: 48.6352419, lng: 19.7356101 },
    { lat: 48.6352015, lng: 19.7360253 },
    { lat: 48.6350992, lng: 19.7361039 },
    { lat: 48.6348428, lng: 19.7361148 },
    { lat: 48.6344962, lng: 19.735922 },
    { lat: 48.6343373, lng: 19.7355376 },
    { lat: 48.6341917, lng: 19.7353798 },
    { lat: 48.6340769, lng: 19.735152 },
    { lat: 48.6340071, lng: 19.7349253 },
    { lat: 48.6337275, lng: 19.7345385 },
    { lat: 48.6336221, lng: 19.7342969 },
    { lat: 48.6334759, lng: 19.7342478 },
    { lat: 48.6334165, lng: 19.7341523 },
    { lat: 48.6333464, lng: 19.7338918 },
    { lat: 48.6332869, lng: 19.733288 },
    { lat: 48.6331929, lng: 19.7330944 },
    { lat: 48.6330976, lng: 19.7325191 },
    { lat: 48.6331553, lng: 19.7323986 },
    { lat: 48.6330259, lng: 19.73154 },
    { lat: 48.6329592, lng: 19.7314559 },
    { lat: 48.6329086, lng: 19.7311778 },
    { lat: 48.6328378, lng: 19.7310549 },
    { lat: 48.632809, lng: 19.7307344 },
    { lat: 48.6326664, lng: 19.730259 },
    { lat: 48.6326006, lng: 19.7297438 },
    { lat: 48.6325009, lng: 19.7294756 },
    { lat: 48.6324959, lng: 19.7292603 },
    { lat: 48.6324149, lng: 19.7291117 },
    { lat: 48.6323425, lng: 19.7286965 },
    { lat: 48.6322401, lng: 19.7275292 },
    { lat: 48.6322932, lng: 19.7273996 },
    { lat: 48.6322094, lng: 19.7269578 },
    { lat: 48.6319334, lng: 19.7265051 },
    { lat: 48.6318359, lng: 19.726184 },
    { lat: 48.6318544, lng: 19.7260158 },
    { lat: 48.6316955, lng: 19.7255841 },
    { lat: 48.631721, lng: 19.7255469 },
    { lat: 48.6315664, lng: 19.7253128 },
    { lat: 48.6315764, lng: 19.7247227 },
    { lat: 48.6313408, lng: 19.7237309 },
    { lat: 48.6313591, lng: 19.7230993 },
    { lat: 48.6312984, lng: 19.7230025 },
    { lat: 48.6313504, lng: 19.7225987 },
    { lat: 48.6310133, lng: 19.7211673 },
    { lat: 48.6308673, lng: 19.720798 },
    { lat: 48.6306675, lng: 19.7204613 },
    { lat: 48.6305572, lng: 19.7200813 },
    { lat: 48.6295677, lng: 19.7187817 },
    { lat: 48.6294741, lng: 19.7183965 },
    { lat: 48.6292222, lng: 19.7180237 },
    { lat: 48.6290072, lng: 19.7173181 },
    { lat: 48.6290266, lng: 19.7172221 },
    { lat: 48.6289585, lng: 19.7171623 },
    { lat: 48.6287178, lng: 19.7165085 },
    { lat: 48.6286263, lng: 19.7157655 },
    { lat: 48.6285245, lng: 19.7156069 },
    { lat: 48.6284584, lng: 19.7151327 },
    { lat: 48.628356, lng: 19.7150052 },
    { lat: 48.6283097, lng: 19.714622 },
    { lat: 48.6281755, lng: 19.7143713 },
    { lat: 48.6282389, lng: 19.7141715 },
    { lat: 48.628241, lng: 19.7137136 },
    { lat: 48.6280632, lng: 19.7124198 },
    { lat: 48.6280276, lng: 19.7116174 },
    { lat: 48.6279164, lng: 19.7112977 },
    { lat: 48.6278057, lng: 19.7111889 },
    { lat: 48.6277141, lng: 19.7102422 },
    { lat: 48.6277026, lng: 19.7095043 },
    { lat: 48.6243944, lng: 19.7123672 },
    { lat: 48.6244313, lng: 19.7131452 },
    { lat: 48.6239489, lng: 19.7132933 },
    { lat: 48.6232333, lng: 19.713341 },
    { lat: 48.623174, lng: 19.7146494 },
    { lat: 48.62329, lng: 19.7158368 },
    { lat: 48.6227283, lng: 19.7159544 },
    { lat: 48.6226412, lng: 19.7173443 },
    { lat: 48.6233377, lng: 19.7196865 },
    { lat: 48.6235684, lng: 19.7207206 },
    { lat: 48.6236172, lng: 19.7211886 },
    { lat: 48.6223494, lng: 19.7207463 },
    { lat: 48.6222753, lng: 19.720498 },
    { lat: 48.6222063, lng: 19.7196001 },
    { lat: 48.6218282, lng: 19.718395 },
    { lat: 48.6215372, lng: 19.7185625 },
    { lat: 48.6214095, lng: 19.7181274 },
    { lat: 48.6213739, lng: 19.717272 },
    { lat: 48.6207877, lng: 19.7171876 },
    { lat: 48.620173, lng: 19.7168166 },
    { lat: 48.61997, lng: 19.7165558 },
    { lat: 48.619956, lng: 19.7175137 },
    { lat: 48.6198794, lng: 19.7176653 },
    { lat: 48.6198736, lng: 19.7177334 },
    { lat: 48.6198217, lng: 19.7183475 },
    { lat: 48.6197826, lng: 19.71881 },
    { lat: 48.619791, lng: 19.7191805 },
    { lat: 48.6197959, lng: 19.7193934 },
    { lat: 48.6199095, lng: 19.7195892 },
    { lat: 48.6200669, lng: 19.7202959 },
    { lat: 48.6204095, lng: 19.7208023 },
    { lat: 48.620512, lng: 19.7211605 },
    { lat: 48.6205866, lng: 19.7216509 },
    { lat: 48.6205857, lng: 19.7228518 },
    { lat: 48.6204087, lng: 19.7235836 },
    { lat: 48.6202411, lng: 19.7240207 },
    { lat: 48.6200618, lng: 19.7242966 },
    { lat: 48.6200375, lng: 19.724334 },
    { lat: 48.6199245, lng: 19.7246313 },
    { lat: 48.6198901, lng: 19.7247217 },
    { lat: 48.6197653, lng: 19.7249031 },
    { lat: 48.6195481, lng: 19.7252187 },
    { lat: 48.6195286, lng: 19.7252648 },
    { lat: 48.6194004, lng: 19.725568 },
    { lat: 48.6191361, lng: 19.7261927 },
    { lat: 48.6191147, lng: 19.7262434 },
    { lat: 48.6190453, lng: 19.7266425 },
    { lat: 48.6189961, lng: 19.7269252 },
    { lat: 48.6190657, lng: 19.7274513 },
    { lat: 48.6190568, lng: 19.7275147 },
    { lat: 48.6190109, lng: 19.7278414 },
    { lat: 48.6186636, lng: 19.7288489 },
    { lat: 48.6184822, lng: 19.7293752 },
    { lat: 48.6184167, lng: 19.7295653 },
    { lat: 48.6182124, lng: 19.7307596 },
    { lat: 48.6181497, lng: 19.7311261 },
    { lat: 48.6181222, lng: 19.7312871 },
    { lat: 48.6181265, lng: 19.7313834 },
    { lat: 48.6181362, lng: 19.7316041 },
    { lat: 48.6180733, lng: 19.732397 },
    { lat: 48.6181085, lng: 19.7336804 },
    { lat: 48.6181986, lng: 19.7340613 },
    { lat: 48.6180956, lng: 19.7343839 },
    { lat: 48.6179955, lng: 19.7346973 },
    { lat: 48.6179225, lng: 19.7349258 },
    { lat: 48.6178404, lng: 19.7353003 },
    { lat: 48.6175822, lng: 19.7364774 },
    { lat: 48.617459, lng: 19.7373954 },
    { lat: 48.6174321, lng: 19.7375956 },
    { lat: 48.6174316, lng: 19.7380854 },
    { lat: 48.617431, lng: 19.7386676 },
    { lat: 48.617408, lng: 19.7387707 },
    { lat: 48.6172842, lng: 19.7393258 },
    { lat: 48.6172396, lng: 19.7398922 },
    { lat: 48.6171379, lng: 19.7401933 },
    { lat: 48.6168817, lng: 19.7404911 },
    { lat: 48.6166403, lng: 19.7411744 },
    { lat: 48.6158313, lng: 19.7427266 },
    { lat: 48.615109, lng: 19.7440041 },
    { lat: 48.6112576, lng: 19.7426687 },
    { lat: 48.6082453, lng: 19.7417862 },
    { lat: 48.6076065, lng: 19.7446184 },
    { lat: 48.6073191, lng: 19.7452329 },
    { lat: 48.6071284, lng: 19.7459398 },
    { lat: 48.6071213, lng: 19.746341 },
    { lat: 48.6068161, lng: 19.7470338 },
    { lat: 48.6067192, lng: 19.7470852 },
    { lat: 48.6066125, lng: 19.7472935 },
    { lat: 48.6062868, lng: 19.7476227 },
    { lat: 48.6060355, lng: 19.7483122 },
    { lat: 48.605984, lng: 19.7488831 },
    { lat: 48.6059461, lng: 19.7488431 },
    { lat: 48.6059349, lng: 19.7490236 },
    { lat: 48.6059862, lng: 19.7493403 },
    { lat: 48.6059857, lng: 19.7504059 },
    { lat: 48.6009146, lng: 19.7536788 },
    { lat: 48.6015427, lng: 19.7561746 },
    { lat: 48.6017423, lng: 19.7565907 },
    { lat: 48.6006922, lng: 19.7571767 },
    { lat: 48.6009646, lng: 19.7617041 },
    { lat: 48.6010974, lng: 19.7654387 },
    { lat: 48.601329, lng: 19.7664824 },
    { lat: 48.6016971, lng: 19.7673687 },
    { lat: 48.6026936, lng: 19.7694602 },
    { lat: 48.6027694, lng: 19.7708805 },
    { lat: 48.6028298, lng: 19.7711265 },
    { lat: 48.602851, lng: 19.7715611 },
    { lat: 48.6032221, lng: 19.7725458 },
    { lat: 48.6036668, lng: 19.7751307 },
    { lat: 48.6038941, lng: 19.7770997 },
    { lat: 48.603922, lng: 19.7776619 },
    { lat: 48.6038289, lng: 19.7783176 },
    { lat: 48.6035174, lng: 19.7785854 },
    { lat: 48.6030828, lng: 19.7791296 },
    { lat: 48.6029671, lng: 19.7793707 },
    { lat: 48.6023569, lng: 19.7801 },
    { lat: 48.60205, lng: 19.780834 },
    { lat: 48.6021815, lng: 19.7819329 },
    { lat: 48.6026998, lng: 19.783763 },
    { lat: 48.6026391, lng: 19.7843339 },
    { lat: 48.6019821, lng: 19.7873396 },
    { lat: 48.6012564, lng: 19.7892955 },
    { lat: 48.6010332, lng: 19.7901944 },
    { lat: 48.6009549, lng: 19.7903094 },
    { lat: 48.6008474, lng: 19.79035 },
    { lat: 48.6002867, lng: 19.7902042 },
    { lat: 48.5999268, lng: 19.790193 },
    { lat: 48.5995009, lng: 19.7903215 },
    { lat: 48.5989409, lng: 19.7911955 },
    { lat: 48.5984998, lng: 19.7923167 },
    { lat: 48.5981314, lng: 19.7929367 },
    { lat: 48.5974643, lng: 19.7924947 },
    { lat: 48.5970647, lng: 19.7936855 },
    { lat: 48.5966533, lng: 19.795234 },
    { lat: 48.5967273, lng: 19.7974683 },
    { lat: 48.596339, lng: 19.7980966 },
    { lat: 48.5957852, lng: 19.7982875 },
    { lat: 48.5949048, lng: 19.7994117 },
    { lat: 48.5930128, lng: 19.8009539 },
    { lat: 48.5923003, lng: 19.8021058 },
    { lat: 48.5917976, lng: 19.8026937 },
    { lat: 48.5909034, lng: 19.8030361 },
    { lat: 48.594205, lng: 19.8100284 },
    { lat: 48.5945313, lng: 19.8101754 },
    { lat: 48.5945883, lng: 19.8105997 },
    { lat: 48.5944148, lng: 19.8109643 },
    { lat: 48.5942973, lng: 19.8115514 },
    { lat: 48.5943435, lng: 19.8116803 },
    { lat: 48.5952003, lng: 19.8108132 },
    { lat: 48.5960457, lng: 19.812066 },
    { lat: 48.596137, lng: 19.8132567 },
    { lat: 48.5967852, lng: 19.8160649 },
    { lat: 48.5966523, lng: 19.8174611 },
    { lat: 48.5966313, lng: 19.8182213 },
    { lat: 48.5973555, lng: 19.8185544 },
    { lat: 48.5975122, lng: 19.8189988 },
    { lat: 48.5978484, lng: 19.8190851 },
    { lat: 48.5990403, lng: 19.8191378 },
    { lat: 48.59911, lng: 19.8181995 },
    { lat: 48.5995055, lng: 19.8189516 },
    { lat: 48.6007331, lng: 19.8208891 },
    { lat: 48.6013018, lng: 19.8221777 },
    { lat: 48.6016336, lng: 19.8241628 },
    { lat: 48.6025152, lng: 19.8257836 },
    { lat: 48.6027105, lng: 19.8263414 },
    { lat: 48.6031257, lng: 19.827883 },
    { lat: 48.6032914, lng: 19.8288199 },
    { lat: 48.6036298, lng: 19.8292728 },
    { lat: 48.6037547, lng: 19.8300247 },
    { lat: 48.603688, lng: 19.832209 },
  ],
  ČeskéBrezovo: [
    { lat: 48.4841303, lng: 19.8430884 },
    { lat: 48.4842175, lng: 19.8429164 },
    { lat: 48.4844364, lng: 19.842434 },
    { lat: 48.4846212, lng: 19.8422266 },
    { lat: 48.4849151, lng: 19.8414971 },
    { lat: 48.4855296, lng: 19.8404935 },
    { lat: 48.4858436, lng: 19.840202 },
    { lat: 48.4860759, lng: 19.8401295 },
    { lat: 48.4861829, lng: 19.8399965 },
    { lat: 48.4863184, lng: 19.8399523 },
    { lat: 48.4869152, lng: 19.8399462 },
    { lat: 48.4874106, lng: 19.8397846 },
    { lat: 48.4879453, lng: 19.8397737 },
    { lat: 48.4883412, lng: 19.8399145 },
    { lat: 48.4888465, lng: 19.8398255 },
    { lat: 48.4892503, lng: 19.8396516 },
    { lat: 48.4895404, lng: 19.8394277 },
    { lat: 48.4901476, lng: 19.838341 },
    { lat: 48.4906723, lng: 19.8377073 },
    { lat: 48.4909424, lng: 19.8369242 },
    { lat: 48.491131, lng: 19.8367004 },
    { lat: 48.4915818, lng: 19.8362633 },
    { lat: 48.4922213, lng: 19.8360229 },
    { lat: 48.4925204, lng: 19.8356418 },
    { lat: 48.4927024, lng: 19.8355273 },
    { lat: 48.4934733, lng: 19.8344499 },
    { lat: 48.4938094, lng: 19.8340656 },
    { lat: 48.493992, lng: 19.8339922 },
    { lat: 48.4944286, lng: 19.8335664 },
    { lat: 48.4951255, lng: 19.8334247 },
    { lat: 48.4953146, lng: 19.8335229 },
    { lat: 48.4954293, lng: 19.8335152 },
    { lat: 48.4955711, lng: 19.8334107 },
    { lat: 48.4959952, lng: 19.8329029 },
    { lat: 48.4962004, lng: 19.832778 },
    { lat: 48.4964026, lng: 19.8324775 },
    { lat: 48.4967528, lng: 19.8321095 },
    { lat: 48.4968842, lng: 19.8318948 },
    { lat: 48.4970769, lng: 19.8314023 },
    { lat: 48.4973068, lng: 19.8311463 },
    { lat: 48.4980558, lng: 19.831063 },
    { lat: 48.4985621, lng: 19.8311788 },
    { lat: 48.4990254, lng: 19.8311332 },
    { lat: 48.4995505, lng: 19.8309003 },
    { lat: 48.5002469, lng: 19.8304879 },
    { lat: 48.5007021, lng: 19.829841 },
    { lat: 48.5010384, lng: 19.8301926 },
    { lat: 48.5019764, lng: 19.8308272 },
    { lat: 48.5022979, lng: 19.8306595 },
    { lat: 48.5023646, lng: 19.830481 },
    { lat: 48.5028765, lng: 19.8296582 },
    { lat: 48.5040912, lng: 19.8294577 },
    { lat: 48.5044633, lng: 19.8295415 },
    { lat: 48.505804, lng: 19.8305553 },
    { lat: 48.5061401, lng: 19.8306686 },
    { lat: 48.5063515, lng: 19.8306812 },
    { lat: 48.5066755, lng: 19.8305956 },
    { lat: 48.5078888, lng: 19.8307512 },
    { lat: 48.5081354, lng: 19.8305351 },
    { lat: 48.5084682, lng: 19.8298504 },
    { lat: 48.5087952, lng: 19.8295954 },
    { lat: 48.5089032, lng: 19.8295922 },
    { lat: 48.5090638, lng: 19.8297345 },
    { lat: 48.5096745, lng: 19.8297124 },
    { lat: 48.5099162, lng: 19.829775 },
    { lat: 48.5100819, lng: 19.8297548 },
    { lat: 48.510295, lng: 19.8295998 },
    { lat: 48.5104468, lng: 19.8296698 },
    { lat: 48.5106259, lng: 19.8296454 },
    { lat: 48.5111245, lng: 19.8293487 },
    { lat: 48.5121217, lng: 19.8292235 },
    { lat: 48.5122308, lng: 19.8292941 },
    { lat: 48.5125385, lng: 19.8296877 },
    { lat: 48.5128951, lng: 19.8299611 },
    { lat: 48.5131394, lng: 19.8300355 },
    { lat: 48.5134234, lng: 19.8302957 },
    { lat: 48.5134931, lng: 19.8308079 },
    { lat: 48.513209, lng: 19.8314801 },
    { lat: 48.5131947, lng: 19.8320309 },
    { lat: 48.5132712, lng: 19.8323421 },
    { lat: 48.5134386, lng: 19.8327225 },
    { lat: 48.5136143, lng: 19.8328747 },
    { lat: 48.5147061, lng: 19.8328995 },
    { lat: 48.5149102, lng: 19.8330389 },
    { lat: 48.5154031, lng: 19.8331802 },
    { lat: 48.5156188, lng: 19.8331661 },
    { lat: 48.5160327, lng: 19.8328912 },
    { lat: 48.5164534, lng: 19.832928 },
    { lat: 48.5166924, lng: 19.8328771 },
    { lat: 48.5169763, lng: 19.8330865 },
    { lat: 48.517095, lng: 19.8330681 },
    { lat: 48.5175327, lng: 19.8328726 },
    { lat: 48.518177, lng: 19.8323511 },
    { lat: 48.5189859, lng: 19.8312609 },
    { lat: 48.5200912, lng: 19.8320714 },
    { lat: 48.5203661, lng: 19.832431 },
    { lat: 48.5205324, lng: 19.8328351 },
    { lat: 48.5206943, lng: 19.8330363 },
    { lat: 48.5210053, lng: 19.8331955 },
    { lat: 48.521142, lng: 19.833363 },
    { lat: 48.521151, lng: 19.833345 },
    { lat: 48.52151, lng: 19.832414 },
    { lat: 48.521922, lng: 19.831402 },
    { lat: 48.522178, lng: 19.830716 },
    { lat: 48.522551, lng: 19.82979 },
    { lat: 48.522956, lng: 19.82882 },
    { lat: 48.52343, lng: 19.827904 },
    { lat: 48.524064, lng: 19.826951 },
    { lat: 48.524731, lng: 19.825773 },
    { lat: 48.525172, lng: 19.824665 },
    { lat: 48.525193, lng: 19.824674 },
    { lat: 48.525456, lng: 19.823751 },
    { lat: 48.5254172, lng: 19.8227777 },
    { lat: 48.5254799, lng: 19.8218115 },
    { lat: 48.5258965, lng: 19.8210003 },
    { lat: 48.5260247, lng: 19.8206581 },
    { lat: 48.5269265, lng: 19.820181 },
    { lat: 48.5275023, lng: 19.8200013 },
    { lat: 48.5286079, lng: 19.8194155 },
    { lat: 48.5293686, lng: 19.8189779 },
    { lat: 48.5306027, lng: 19.8180848 },
    { lat: 48.5310334, lng: 19.8178869 },
    { lat: 48.5312113, lng: 19.8179049 },
    { lat: 48.5316533, lng: 19.8176302 },
    { lat: 48.5321466, lng: 19.8169815 },
    { lat: 48.5332517, lng: 19.815839 },
    { lat: 48.5335372, lng: 19.81546 },
    { lat: 48.5338389, lng: 19.8152002 },
    { lat: 48.5343012, lng: 19.8145072 },
    { lat: 48.5356366, lng: 19.813247 },
    { lat: 48.5363093, lng: 19.812323 },
    { lat: 48.5373179, lng: 19.8115013 },
    { lat: 48.5377365, lng: 19.8108421 },
    { lat: 48.5378595, lng: 19.8104623 },
    { lat: 48.5383292, lng: 19.8094251 },
    { lat: 48.539274, lng: 19.8078886 },
    { lat: 48.5394224, lng: 19.8071888 },
    { lat: 48.5396097, lng: 19.8066761 },
    { lat: 48.5394548, lng: 19.8059399 },
    { lat: 48.5394956, lng: 19.8044736 },
    { lat: 48.5394601, lng: 19.804097 },
    { lat: 48.539139, lng: 19.8028383 },
    { lat: 48.53894, lng: 19.8016051 },
    { lat: 48.5387359, lng: 19.8000568 },
    { lat: 48.538641, lng: 19.7987622 },
    { lat: 48.538917, lng: 19.7982961 },
    { lat: 48.5393078, lng: 19.7974183 },
    { lat: 48.5394748, lng: 19.7968543 },
    { lat: 48.539789, lng: 19.7940177 },
    { lat: 48.5401825, lng: 19.7915287 },
    { lat: 48.5402972, lng: 19.7911621 },
    { lat: 48.5405751, lng: 19.7906404 },
    { lat: 48.5409235, lng: 19.7901802 },
    { lat: 48.5415851, lng: 19.7895449 },
    { lat: 48.5426348, lng: 19.7890226 },
    { lat: 48.5434444, lng: 19.7884195 },
    { lat: 48.5443261, lng: 19.7871998 },
    { lat: 48.5450387, lng: 19.78531 },
    { lat: 48.5453717, lng: 19.7834201 },
    { lat: 48.5457828, lng: 19.7819249 },
    { lat: 48.5459128, lng: 19.7808297 },
    { lat: 48.5461343, lng: 19.7795396 },
    { lat: 48.5462536, lng: 19.7789443 },
    { lat: 48.5463994, lng: 19.7778001 },
    { lat: 48.5471634, lng: 19.7770324 },
    { lat: 48.5468441, lng: 19.7747647 },
    { lat: 48.5467712, lng: 19.7736426 },
    { lat: 48.5467542, lng: 19.7724339 },
    { lat: 48.5476851, lng: 19.7693101 },
    { lat: 48.5476443, lng: 19.7690763 },
    { lat: 48.5479174, lng: 19.7679851 },
    { lat: 48.5477984, lng: 19.7678918 },
    { lat: 48.548265, lng: 19.7661505 },
    { lat: 48.5483402, lng: 19.765686 },
    { lat: 48.5484813, lng: 19.7644864 },
    { lat: 48.5484813, lng: 19.7638687 },
    { lat: 48.5484141, lng: 19.7635981 },
    { lat: 48.5486303, lng: 19.762903 },
    { lat: 48.5489662, lng: 19.7622703 },
    { lat: 48.5489248, lng: 19.7611244 },
    { lat: 48.5488292, lng: 19.7608747 },
    { lat: 48.5489334, lng: 19.7595304 },
    { lat: 48.5488948, lng: 19.7595215 },
    { lat: 48.5487951, lng: 19.7591229 },
    { lat: 48.5489373, lng: 19.758364 },
    { lat: 48.5493374, lng: 19.757392 },
    { lat: 48.5495484, lng: 19.7567106 },
    { lat: 48.550672, lng: 19.7544397 },
    { lat: 48.5509312, lng: 19.7535492 },
    { lat: 48.5516725, lng: 19.7522198 },
    { lat: 48.5521576, lng: 19.7509002 },
    { lat: 48.5527925, lng: 19.7498964 },
    { lat: 48.5528705, lng: 19.7488811 },
    { lat: 48.5528652, lng: 19.7479705 },
    { lat: 48.5533034, lng: 19.7459482 },
    { lat: 48.5534227, lng: 19.7449017 },
    { lat: 48.5526504, lng: 19.7436664 },
    { lat: 48.5516891, lng: 19.7426917 },
    { lat: 48.5509812, lng: 19.7421222 },
    { lat: 48.5491648, lng: 19.7411009 },
    { lat: 48.5483342, lng: 19.740277 },
    { lat: 48.5482004, lng: 19.7398267 },
    { lat: 48.5470341, lng: 19.739543 },
    { lat: 48.5464083, lng: 19.7396093 },
    { lat: 48.5462358, lng: 19.739836 },
    { lat: 48.5460924, lng: 19.7398561 },
    { lat: 48.5455562, lng: 19.7408486 },
    { lat: 48.5455343, lng: 19.7409671 },
    { lat: 48.5453817, lng: 19.741057 },
    { lat: 48.5450233, lng: 19.7415371 },
    { lat: 48.5447464, lng: 19.7416456 },
    { lat: 48.5446043, lng: 19.7419994 },
    { lat: 48.5445891, lng: 19.7423213 },
    { lat: 48.5443552, lng: 19.7426608 },
    { lat: 48.544165, lng: 19.7428179 },
    { lat: 48.5442448, lng: 19.7436558 },
    { lat: 48.5439503, lng: 19.7436451 },
    { lat: 48.5439663, lng: 19.7431407 },
    { lat: 48.5435668, lng: 19.7427445 },
    { lat: 48.5428695, lng: 19.7426725 },
    { lat: 48.5425198, lng: 19.7427364 },
    { lat: 48.5423286, lng: 19.7429518 },
    { lat: 48.5421769, lng: 19.744236 },
    { lat: 48.542168, lng: 19.7450017 },
    { lat: 48.5420853, lng: 19.7454772 },
    { lat: 48.5422046, lng: 19.7456544 },
    { lat: 48.5425384, lng: 19.7455303 },
    { lat: 48.5426594, lng: 19.7456671 },
    { lat: 48.5427954, lng: 19.7459657 },
    { lat: 48.542683, lng: 19.7465103 },
    { lat: 48.5427554, lng: 19.7469265 },
    { lat: 48.5423069, lng: 19.7474852 },
    { lat: 48.541959, lng: 19.748074 },
    { lat: 48.5418604, lng: 19.7499007 },
    { lat: 48.5419758, lng: 19.7505249 },
    { lat: 48.54155, lng: 19.7513209 },
    { lat: 48.5412246, lng: 19.7516835 },
    { lat: 48.5409077, lng: 19.7516775 },
    { lat: 48.5405082, lng: 19.7510707 },
    { lat: 48.5401681, lng: 19.7502077 },
    { lat: 48.5400881, lng: 19.7506143 },
    { lat: 48.5395894, lng: 19.7516191 },
    { lat: 48.5390523, lng: 19.7528828 },
    { lat: 48.5384838, lng: 19.7535319 },
    { lat: 48.5380824, lng: 19.7541619 },
    { lat: 48.5380423, lng: 19.7545065 },
    { lat: 48.5374617, lng: 19.755597 },
    { lat: 48.5367573, lng: 19.7571481 },
    { lat: 48.5366791, lng: 19.7573728 },
    { lat: 48.5366054, lng: 19.7578434 },
    { lat: 48.5366566, lng: 19.7586734 },
    { lat: 48.5366216, lng: 19.7591589 },
    { lat: 48.5365176, lng: 19.7595593 },
    { lat: 48.5364634, lng: 19.7600692 },
    { lat: 48.5361363, lng: 19.7610063 },
    { lat: 48.5358127, lng: 19.7613621 },
    { lat: 48.5355982, lng: 19.7617081 },
    { lat: 48.5350327, lng: 19.7629935 },
    { lat: 48.5348883, lng: 19.7631672 },
    { lat: 48.5344195, lng: 19.7635507 },
    { lat: 48.5342151, lng: 19.7636155 },
    { lat: 48.5339948, lng: 19.7638291 },
    { lat: 48.5335478, lng: 19.7640685 },
    { lat: 48.5319345, lng: 19.7637354 },
    { lat: 48.5307736, lng: 19.764137 },
    { lat: 48.5299896, lng: 19.7647019 },
    { lat: 48.5276184, lng: 19.7655118 },
    { lat: 48.5273015, lng: 19.7655533 },
    { lat: 48.5266309, lng: 19.765884 },
    { lat: 48.5255703, lng: 19.7662567 },
    { lat: 48.5252052, lng: 19.7660987 },
    { lat: 48.5248512, lng: 19.7663017 },
    { lat: 48.5245976, lng: 19.7663011 },
    { lat: 48.5243752, lng: 19.7664665 },
    { lat: 48.5241058, lng: 19.7663654 },
    { lat: 48.5234769, lng: 19.7665113 },
    { lat: 48.5227599, lng: 19.7669151 },
    { lat: 48.5223859, lng: 19.7673472 },
    { lat: 48.521642, lng: 19.7676226 },
    { lat: 48.5202119, lng: 19.7679219 },
    { lat: 48.5196502, lng: 19.7678083 },
    { lat: 48.5193043, lng: 19.7678357 },
    { lat: 48.5181258, lng: 19.7676554 },
    { lat: 48.5174416, lng: 19.7677236 },
    { lat: 48.5169342, lng: 19.7680247 },
    { lat: 48.516791, lng: 19.7679686 },
    { lat: 48.5155365, lng: 19.7678801 },
    { lat: 48.514754, lng: 19.76815 },
    { lat: 48.5135617, lng: 19.7688601 },
    { lat: 48.5124491, lng: 19.7694624 },
    { lat: 48.5117491, lng: 19.7702471 },
    { lat: 48.5100381, lng: 19.7714114 },
    { lat: 48.5094888, lng: 19.7721916 },
    { lat: 48.5083371, lng: 19.7734696 },
    { lat: 48.5067963, lng: 19.7741453 },
    { lat: 48.5059736, lng: 19.7742442 },
    { lat: 48.5050112, lng: 19.7746992 },
    { lat: 48.5044007, lng: 19.7751635 },
    { lat: 48.5038336, lng: 19.7754914 },
    { lat: 48.5024841, lng: 19.7772905 },
    { lat: 48.5014199, lng: 19.7791765 },
    { lat: 48.5005803, lng: 19.7808906 },
    { lat: 48.4994005, lng: 19.7828626 },
    { lat: 48.4961377, lng: 19.7858449 },
    { lat: 48.4950208, lng: 19.7861692 },
    { lat: 48.4937167, lng: 19.787516 },
    { lat: 48.4934157, lng: 19.7877423 },
    { lat: 48.4931338, lng: 19.7875873 },
    { lat: 48.4926216, lng: 19.7874652 },
    { lat: 48.4917732, lng: 19.786719 },
    { lat: 48.4912598, lng: 19.7863664 },
    { lat: 48.4908255, lng: 19.7859212 },
    { lat: 48.4897692, lng: 19.7855178 },
    { lat: 48.4885773, lng: 19.7851648 },
    { lat: 48.4879124, lng: 19.7851598 },
    { lat: 48.4872976, lng: 19.7848579 },
    { lat: 48.4862386, lng: 19.7845532 },
    { lat: 48.4847182, lng: 19.7837957 },
    { lat: 48.4842953, lng: 19.7834005 },
    { lat: 48.4833423, lng: 19.7827359 },
    { lat: 48.4828392, lng: 19.7824669 },
    { lat: 48.4825046, lng: 19.7823919 },
    { lat: 48.4820469, lng: 19.7821287 },
    { lat: 48.4814496, lng: 19.7816628 },
    { lat: 48.4810608, lng: 19.7812235 },
    { lat: 48.4804693, lng: 19.7808383 },
    { lat: 48.4800316, lng: 19.7806716 },
    { lat: 48.4798978, lng: 19.7807055 },
    { lat: 48.4798459, lng: 19.7805988 },
    { lat: 48.4797359, lng: 19.7806097 },
    { lat: 48.47957, lng: 19.7804771 },
    { lat: 48.4785352, lng: 19.7805629 },
    { lat: 48.4781498, lng: 19.7804587 },
    { lat: 48.4781094, lng: 19.7803612 },
    { lat: 48.477442, lng: 19.7806647 },
    { lat: 48.4771865, lng: 19.7805885 },
    { lat: 48.4770936, lng: 19.7806823 },
    { lat: 48.4769149, lng: 19.7806663 },
    { lat: 48.4767366, lng: 19.7807618 },
    { lat: 48.4761961, lng: 19.7807715 },
    { lat: 48.4761039, lng: 19.7809298 },
    { lat: 48.4759467, lng: 19.7809894 },
    { lat: 48.4755293, lng: 19.781441 },
    { lat: 48.4749965, lng: 19.7818295 },
    { lat: 48.4746193, lng: 19.7822195 },
    { lat: 48.4744687, lng: 19.7822785 },
    { lat: 48.4737572, lng: 19.7833181 },
    { lat: 48.4735687, lng: 19.7832829 },
    { lat: 48.4735191, lng: 19.7834877 },
    { lat: 48.4733385, lng: 19.7837361 },
    { lat: 48.4732353, lng: 19.7841596 },
    { lat: 48.4731299, lng: 19.7841669 },
    { lat: 48.4728181, lng: 19.7849705 },
    { lat: 48.4722752, lng: 19.7859528 },
    { lat: 48.4720974, lng: 19.7863667 },
    { lat: 48.4719312, lng: 19.7864308 },
    { lat: 48.4717701, lng: 19.786816 },
    { lat: 48.4716646, lng: 19.7868233 },
    { lat: 48.4716015, lng: 19.7872904 },
    { lat: 48.47142, lng: 19.7873116 },
    { lat: 48.4713124, lng: 19.7874274 },
    { lat: 48.4713058, lng: 19.7877398 },
    { lat: 48.4711298, lng: 19.7877846 },
    { lat: 48.4709502, lng: 19.7879547 },
    { lat: 48.4710241, lng: 19.7882016 },
    { lat: 48.4708965, lng: 19.788221 },
    { lat: 48.4708397, lng: 19.7883079 },
    { lat: 48.4708307, lng: 19.7884647 },
    { lat: 48.4706151, lng: 19.7886895 },
    { lat: 48.4703757, lng: 19.7887267 },
    { lat: 48.4702631, lng: 19.788931 },
    { lat: 48.4699661, lng: 19.7892484 },
    { lat: 48.4698096, lng: 19.7895895 },
    { lat: 48.4698027, lng: 19.7897933 },
    { lat: 48.4696013, lng: 19.7900778 },
    { lat: 48.469515, lng: 19.7903707 },
    { lat: 48.469395, lng: 19.790515 },
    { lat: 48.4693244, lng: 19.7908064 },
    { lat: 48.4691757, lng: 19.7910111 },
    { lat: 48.4690641, lng: 19.791293 },
    { lat: 48.4689766, lng: 19.7913528 },
    { lat: 48.4689091, lng: 19.7915594 },
    { lat: 48.4689412, lng: 19.7917256 },
    { lat: 48.4686126, lng: 19.7922019 },
    { lat: 48.4684476, lng: 19.7927505 },
    { lat: 48.4683204, lng: 19.7929832 },
    { lat: 48.4681429, lng: 19.7930484 },
    { lat: 48.4681198, lng: 19.7931895 },
    { lat: 48.4681769, lng: 19.7933127 },
    { lat: 48.4679646, lng: 19.7939706 },
    { lat: 48.4678355, lng: 19.7940036 },
    { lat: 48.4676586, lng: 19.7942381 },
    { lat: 48.4672661, lng: 19.7941754 },
    { lat: 48.4668378, lng: 19.7942246 },
    { lat: 48.4661779, lng: 19.7941814 },
    { lat: 48.4660365, lng: 19.7944496 },
    { lat: 48.4659215, lng: 19.7944443 },
    { lat: 48.46574, lng: 19.7943163 },
    { lat: 48.4657155, lng: 19.7941665 },
    { lat: 48.4655295, lng: 19.7938831 },
    { lat: 48.4653308, lng: 19.7938723 },
    { lat: 48.4653572, lng: 19.7940663 },
    { lat: 48.465286, lng: 19.7941377 },
    { lat: 48.465042, lng: 19.7940163 },
    { lat: 48.4648602, lng: 19.7937258 },
    { lat: 48.464807, lng: 19.7934872 },
    { lat: 48.4646255, lng: 19.7934611 },
    { lat: 48.4644755, lng: 19.7931746 },
    { lat: 48.4643983, lng: 19.7932124 },
    { lat: 48.464235, lng: 19.7930085 },
    { lat: 48.4636558, lng: 19.7930826 },
    { lat: 48.46366, lng: 19.7931777 },
    { lat: 48.4636553, lng: 19.7932016 },
    { lat: 48.4636291, lng: 19.7935027 },
    { lat: 48.463697, lng: 19.7939745 },
    { lat: 48.4640182, lng: 19.7946662 },
    { lat: 48.4640709, lng: 19.7949219 },
    { lat: 48.4638983, lng: 19.7962248 },
    { lat: 48.4633411, lng: 19.8029569 },
    { lat: 48.4629522, lng: 19.8048331 },
    { lat: 48.4628926, lng: 19.8055547 },
    { lat: 48.4629545, lng: 19.8067445 },
    { lat: 48.4631018, lng: 19.8077391 },
    { lat: 48.463221, lng: 19.8081477 },
    { lat: 48.4632382, lng: 19.8086454 },
    { lat: 48.4635619, lng: 19.8104076 },
    { lat: 48.4626171, lng: 19.8100291 },
    { lat: 48.4620759, lng: 19.8094553 },
    { lat: 48.461786, lng: 19.8093952 },
    { lat: 48.4611914, lng: 19.8093968 },
    { lat: 48.4609711, lng: 19.8094391 },
    { lat: 48.4605185, lng: 19.8097252 },
    { lat: 48.4598763, lng: 19.8099835 },
    { lat: 48.4592815, lng: 19.8097004 },
    { lat: 48.4588206, lng: 19.8097278 },
    { lat: 48.4579163, lng: 19.8106384 },
    { lat: 48.4575541, lng: 19.8108598 },
    { lat: 48.4566622, lng: 19.8107903 },
    { lat: 48.4554368, lng: 19.810463 },
    { lat: 48.4545188, lng: 19.810445 },
    { lat: 48.4545696, lng: 19.8109599 },
    { lat: 48.4546256, lng: 19.8112895 },
    { lat: 48.4538374, lng: 19.8138283 },
    { lat: 48.452925, lng: 19.8161564 },
    { lat: 48.4527954, lng: 19.8162852 },
    { lat: 48.4527654, lng: 19.8163999 },
    { lat: 48.4526508, lng: 19.8191135 },
    { lat: 48.4525099, lng: 19.8198124 },
    { lat: 48.4525594, lng: 19.8198107 },
    { lat: 48.4526386, lng: 19.8229995 },
    { lat: 48.4526338, lng: 19.8248285 },
    { lat: 48.4523632, lng: 19.8273334 },
    { lat: 48.4532301, lng: 19.8287146 },
    { lat: 48.4544685, lng: 19.8298364 },
    { lat: 48.4566698, lng: 19.8316924 },
    { lat: 48.4573097, lng: 19.8323418 },
    { lat: 48.4593997, lng: 19.8339537 },
    { lat: 48.459946, lng: 19.8334322 },
    { lat: 48.460754, lng: 19.8330483 },
    { lat: 48.4609617, lng: 19.8331347 },
    { lat: 48.4611686, lng: 19.8334626 },
    { lat: 48.4613665, lng: 19.8336589 },
    { lat: 48.46194, lng: 19.8338393 },
    { lat: 48.4628657, lng: 19.833288 },
    { lat: 48.4634942, lng: 19.8327168 },
    { lat: 48.4638786, lng: 19.8325878 },
    { lat: 48.4643083, lng: 19.8327042 },
    { lat: 48.4644891, lng: 19.8330272 },
    { lat: 48.4647588, lng: 19.8338456 },
    { lat: 48.4649921, lng: 19.834101 },
    { lat: 48.4652373, lng: 19.8341702 },
    { lat: 48.4662615, lng: 19.8331626 },
    { lat: 48.4667999, lng: 19.8328494 },
    { lat: 48.4679164, lng: 19.8327216 },
    { lat: 48.4687579, lng: 19.8324851 },
    { lat: 48.469351, lng: 19.8331706 },
    { lat: 48.4707138, lng: 19.8334449 },
    { lat: 48.4712424, lng: 19.8334504 },
    { lat: 48.4714844, lng: 19.8335253 },
    { lat: 48.4723178, lng: 19.8339557 },
    { lat: 48.4725706, lng: 19.8341517 },
    { lat: 48.4733099, lng: 19.8343383 },
    { lat: 48.4740085, lng: 19.8347024 },
    { lat: 48.4747194, lng: 19.8353771 },
    { lat: 48.4754268, lng: 19.8356087 },
    { lat: 48.4755908, lng: 19.8357477 },
    { lat: 48.4764336, lng: 19.8357799 },
    { lat: 48.4769, lng: 19.8357238 },
    { lat: 48.4772336, lng: 19.8355994 },
    { lat: 48.4775593, lng: 19.8357325 },
    { lat: 48.4777276, lng: 19.8358733 },
    { lat: 48.4781421, lng: 19.8358169 },
    { lat: 48.4789348, lng: 19.836038 },
    { lat: 48.4796311, lng: 19.836819 },
    { lat: 48.4800682, lng: 19.8374944 },
    { lat: 48.48042, lng: 19.83885 },
    { lat: 48.4809459, lng: 19.8395981 },
    { lat: 48.4813293, lng: 19.8403051 },
    { lat: 48.4815054, lng: 19.8404401 },
    { lat: 48.4821888, lng: 19.8415214 },
    { lat: 48.4829205, lng: 19.8417685 },
    { lat: 48.4832795, lng: 19.8419677 },
    { lat: 48.4836833, lng: 19.8423954 },
    { lat: 48.4840401, lng: 19.8425656 },
    { lat: 48.4841303, lng: 19.8430884 },
  ],
  Šoltýska: [
    { lat: 48.5920108, lng: 19.731382 },
    { lat: 48.5899468, lng: 19.7334733 },
    { lat: 48.5868498, lng: 19.7327939 },
    { lat: 48.586566, lng: 19.7332569 },
    { lat: 48.5863997, lng: 19.7340832 },
    { lat: 48.5861905, lng: 19.7344733 },
    { lat: 48.5858985, lng: 19.7347984 },
    { lat: 48.5855401, lng: 19.7349753 },
    { lat: 48.5849511, lng: 19.7351327 },
    { lat: 48.5848351, lng: 19.7350821 },
    { lat: 48.5845294, lng: 19.7352548 },
    { lat: 48.583407, lng: 19.7363956 },
    { lat: 48.5832781, lng: 19.7370761 },
    { lat: 48.5827616, lng: 19.738134 },
    { lat: 48.5825782, lng: 19.7383645 },
    { lat: 48.5826494, lng: 19.7384224 },
    { lat: 48.5822812, lng: 19.7396276 },
    { lat: 48.5820998, lng: 19.7405173 },
    { lat: 48.5820487, lng: 19.7414287 },
    { lat: 48.5814335, lng: 19.7404315 },
    { lat: 48.5812908, lng: 19.7404458 },
    { lat: 48.5811978, lng: 19.7401253 },
    { lat: 48.5804806, lng: 19.739903 },
    { lat: 48.580216, lng: 19.7396794 },
    { lat: 48.5800469, lng: 19.7391441 },
    { lat: 48.580096, lng: 19.7377533 },
    { lat: 48.5798905, lng: 19.7372604 },
    { lat: 48.5795412, lng: 19.7377453 },
    { lat: 48.5794143, lng: 19.7373677 },
    { lat: 48.5790467, lng: 19.7373139 },
    { lat: 48.5788624, lng: 19.7374768 },
    { lat: 48.5783292, lng: 19.7364231 },
    { lat: 48.5782141, lng: 19.7359877 },
    { lat: 48.5780241, lng: 19.7361762 },
    { lat: 48.5768933, lng: 19.7360339 },
    { lat: 48.5764438, lng: 19.7373325 },
    { lat: 48.5762734, lng: 19.7370202 },
    { lat: 48.5762408, lng: 19.7373565 },
    { lat: 48.5760371, lng: 19.7375659 },
    { lat: 48.5757916, lng: 19.7375176 },
    { lat: 48.5753282, lng: 19.7367454 },
    { lat: 48.5753398, lng: 19.7363451 },
    { lat: 48.5751962, lng: 19.7357457 },
    { lat: 48.5749353, lng: 19.7354531 },
    { lat: 48.5746506, lng: 19.7357429 },
    { lat: 48.5745714, lng: 19.7357334 },
    { lat: 48.5744605, lng: 19.7359022 },
    { lat: 48.5733728, lng: 19.7349252 },
    { lat: 48.5718199, lng: 19.732806 },
    { lat: 48.5715996, lng: 19.7326609 },
    { lat: 48.5716003, lng: 19.7325751 },
    { lat: 48.5700998, lng: 19.7313829 },
    { lat: 48.5692589, lng: 19.7304859 },
    { lat: 48.5683559, lng: 19.729762 },
    { lat: 48.5678925, lng: 19.7296521 },
    { lat: 48.5663091, lng: 19.728451 },
    { lat: 48.5651653, lng: 19.7277339 },
    { lat: 48.5643107, lng: 19.7279273 },
    { lat: 48.5640971, lng: 19.7275831 },
    { lat: 48.5637807, lng: 19.7277907 },
    { lat: 48.563395, lng: 19.7281589 },
    { lat: 48.5631459, lng: 19.7288455 },
    { lat: 48.562618, lng: 19.7294179 },
    { lat: 48.5620818, lng: 19.7298275 },
    { lat: 48.5615181, lng: 19.7302257 },
    { lat: 48.5615254, lng: 19.7302616 },
    { lat: 48.5616015, lng: 19.7306584 },
    { lat: 48.5618408, lng: 19.7313802 },
    { lat: 48.5622034, lng: 19.7315017 },
    { lat: 48.5626401, lng: 19.7318526 },
    { lat: 48.5626623, lng: 19.7318149 },
    { lat: 48.5627499, lng: 19.7319384 },
    { lat: 48.563353, lng: 19.731945 },
    { lat: 48.5640485, lng: 19.7327377 },
    { lat: 48.5643673, lng: 19.7341161 },
    { lat: 48.5644725, lng: 19.7342998 },
    { lat: 48.5648324, lng: 19.7348193 },
    { lat: 48.5655836, lng: 19.7350949 },
    { lat: 48.5657228, lng: 19.7352851 },
    { lat: 48.5658554, lng: 19.7356087 },
    { lat: 48.5661048, lng: 19.7355694 },
    { lat: 48.5664107, lng: 19.7352772 },
    { lat: 48.566439, lng: 19.7353313 },
    { lat: 48.5669249, lng: 19.734269 },
    { lat: 48.5672224, lng: 19.7342687 },
    { lat: 48.5672503, lng: 19.7343864 },
    { lat: 48.5673636, lng: 19.7344298 },
    { lat: 48.5678391, lng: 19.7349143 },
    { lat: 48.5681022, lng: 19.7352059 },
    { lat: 48.5681293, lng: 19.7353576 },
    { lat: 48.5683622, lng: 19.7355807 },
    { lat: 48.5685716, lng: 19.7360378 },
    { lat: 48.5686835, lng: 19.7361706 },
    { lat: 48.5688469, lng: 19.7362464 },
    { lat: 48.5689999, lng: 19.7366999 },
    { lat: 48.569163, lng: 19.7368226 },
    { lat: 48.5693159, lng: 19.7372267 },
    { lat: 48.5695967, lng: 19.7374835 },
    { lat: 48.5697255, lng: 19.737849 },
    { lat: 48.5702234, lng: 19.7380253 },
    { lat: 48.5702406, lng: 19.7381097 },
    { lat: 48.570587, lng: 19.738473 },
    { lat: 48.5707932, lng: 19.7385772 },
    { lat: 48.5708759, lng: 19.7388944 },
    { lat: 48.571054, lng: 19.7391996 },
    { lat: 48.5712785, lng: 19.7397989 },
    { lat: 48.5713773, lng: 19.7405265 },
    { lat: 48.5714964, lng: 19.740876 },
    { lat: 48.5716137, lng: 19.7411314 },
    { lat: 48.5717168, lng: 19.7412221 },
    { lat: 48.5717252, lng: 19.7414927 },
    { lat: 48.5717773, lng: 19.7416515 },
    { lat: 48.5717416, lng: 19.7419657 },
    { lat: 48.5716578, lng: 19.7421584 },
    { lat: 48.5715972, lng: 19.7432061 },
    { lat: 48.5714733, lng: 19.7436462 },
    { lat: 48.5714246, lng: 19.7442231 },
    { lat: 48.5714376, lng: 19.744468 },
    { lat: 48.5714991, lng: 19.7446646 },
    { lat: 48.571439, lng: 19.745089 },
    { lat: 48.5714587, lng: 19.7453791 },
    { lat: 48.5715485, lng: 19.745654 },
    { lat: 48.5698523, lng: 19.7456917 },
    { lat: 48.5695265, lng: 19.745766 },
    { lat: 48.5694413, lng: 19.7462103 },
    { lat: 48.5687645, lng: 19.7465755 },
    { lat: 48.568452, lng: 19.7470771 },
    { lat: 48.5683747, lng: 19.7469077 },
    { lat: 48.5682323, lng: 19.7469995 },
    { lat: 48.5679408, lng: 19.7474385 },
    { lat: 48.5681158, lng: 19.74768 },
    { lat: 48.5681752, lng: 19.7478765 },
    { lat: 48.5684911, lng: 19.7479085 },
    { lat: 48.5686478, lng: 19.7483457 },
    { lat: 48.5688149, lng: 19.7490664 },
    { lat: 48.5690045, lng: 19.7493088 },
    { lat: 48.569319, lng: 19.7491809 },
    { lat: 48.569593, lng: 19.7499817 },
    { lat: 48.5696038, lng: 19.7500713 },
    { lat: 48.5689802, lng: 19.7501594 },
    { lat: 48.5688197, lng: 19.75066 },
    { lat: 48.5688906, lng: 19.7514003 },
    { lat: 48.5692282, lng: 19.7518012 },
    { lat: 48.5693076, lng: 19.7522959 },
    { lat: 48.5696222, lng: 19.752966 },
    { lat: 48.57004, lng: 19.7535012 },
    { lat: 48.5705401, lng: 19.7543384 },
    { lat: 48.5707706, lng: 19.7544309 },
    { lat: 48.5712723, lng: 19.7549493 },
    { lat: 48.5719891, lng: 19.755545 },
    { lat: 48.5721946, lng: 19.7561694 },
    { lat: 48.5722828, lng: 19.7566337 },
    { lat: 48.5722536, lng: 19.7570743 },
    { lat: 48.5717885, lng: 19.7573584 },
    { lat: 48.5716775, lng: 19.757625 },
    { lat: 48.571643, lng: 19.7579689 },
    { lat: 48.5715734, lng: 19.7581786 },
    { lat: 48.571337, lng: 19.7584388 },
    { lat: 48.5708938, lng: 19.759248 },
    { lat: 48.5704589, lng: 19.7595316 },
    { lat: 48.5703916, lng: 19.7596938 },
    { lat: 48.5709169, lng: 19.7601333 },
    { lat: 48.5709505, lng: 19.760639 },
    { lat: 48.5709223, lng: 19.7609478 },
    { lat: 48.5708089, lng: 19.7610054 },
    { lat: 48.5707492, lng: 19.7611348 },
    { lat: 48.5706438, lng: 19.7615566 },
    { lat: 48.570622, lng: 19.7618284 },
    { lat: 48.5705532, lng: 19.7619057 },
    { lat: 48.5707766, lng: 19.7625238 },
    { lat: 48.5711493, lng: 19.7629828 },
    { lat: 48.5715036, lng: 19.763739 },
    { lat: 48.5716824, lng: 19.7636756 },
    { lat: 48.5720425, lng: 19.764079 },
    { lat: 48.5722287, lng: 19.7645994 },
    { lat: 48.5724839, lng: 19.7646095 },
    { lat: 48.5728228, lng: 19.7641451 },
    { lat: 48.5731786, lng: 19.7634472 },
    { lat: 48.5735891, lng: 19.7632738 },
    { lat: 48.5741875, lng: 19.7637378 },
    { lat: 48.5737528, lng: 19.7647948 },
    { lat: 48.573171, lng: 19.7656306 },
    { lat: 48.5737196, lng: 19.7659348 },
    { lat: 48.5738995, lng: 19.7658279 },
    { lat: 48.574037, lng: 19.7659056 },
    { lat: 48.5742741, lng: 19.7655293 },
    { lat: 48.5744373, lng: 19.765628 },
    { lat: 48.5747346, lng: 19.7655752 },
    { lat: 48.5748496, lng: 19.7658074 },
    { lat: 48.5748678, lng: 19.7660183 },
    { lat: 48.5746084, lng: 19.766395 },
    { lat: 48.574579, lng: 19.7666986 },
    { lat: 48.5741621, lng: 19.7669295 },
    { lat: 48.5741637, lng: 19.7670407 },
    { lat: 48.5745996, lng: 19.767016 },
    { lat: 48.5747349, lng: 19.7675049 },
    { lat: 48.5756848, lng: 19.7671738 },
    { lat: 48.5758312, lng: 19.7664558 },
    { lat: 48.5766639, lng: 19.7648382 },
    { lat: 48.5768171, lng: 19.764403 },
    { lat: 48.5784932, lng: 19.7593757 },
    { lat: 48.5786312, lng: 19.7586716 },
    { lat: 48.5787443, lng: 19.7586813 },
    { lat: 48.5789371, lng: 19.7588136 },
    { lat: 48.579025, lng: 19.7589653 },
    { lat: 48.5791076, lng: 19.7597152 },
    { lat: 48.5792435, lng: 19.760245 },
    { lat: 48.5794284, lng: 19.7604752 },
    { lat: 48.5796873, lng: 19.7605482 },
    { lat: 48.5800392, lng: 19.7608418 },
    { lat: 48.5800585, lng: 19.7610225 },
    { lat: 48.5803613, lng: 19.7616793 },
    { lat: 48.5803695, lng: 19.7619955 },
    { lat: 48.5804858, lng: 19.7623852 },
    { lat: 48.5807321, lng: 19.7625802 },
    { lat: 48.5810743, lng: 19.7632149 },
    { lat: 48.5812235, lng: 19.7636612 },
    { lat: 48.5813687, lng: 19.7638854 },
    { lat: 48.5814884, lng: 19.7639446 },
    { lat: 48.5819746, lng: 19.7629585 },
    { lat: 48.5822135, lng: 19.7626531 },
    { lat: 48.5820452, lng: 19.7623643 },
    { lat: 48.581959, lng: 19.7620146 },
    { lat: 48.5821884, lng: 19.7618233 },
    { lat: 48.5819556, lng: 19.761299 },
    { lat: 48.5819668, lng: 19.7611178 },
    { lat: 48.5836064, lng: 19.7582026 },
    { lat: 48.5834794, lng: 19.7578541 },
    { lat: 48.5833734, lng: 19.7560855 },
    { lat: 48.5834812, lng: 19.7559765 },
    { lat: 48.5835103, lng: 19.7560476 },
    { lat: 48.5837645, lng: 19.7556058 },
    { lat: 48.5844754, lng: 19.7561919 },
    { lat: 48.5852685, lng: 19.7562674 },
    { lat: 48.5853434, lng: 19.7560965 },
    { lat: 48.585519, lng: 19.7559178 },
    { lat: 48.5855515, lng: 19.7555796 },
    { lat: 48.5857306, lng: 19.7549657 },
    { lat: 48.585953, lng: 19.7548954 },
    { lat: 48.5870884, lng: 19.7542006 },
    { lat: 48.5878737, lng: 19.7538686 },
    { lat: 48.5881031, lng: 19.7539841 },
    { lat: 48.5884944, lng: 19.7539929 },
    { lat: 48.588499, lng: 19.7534327 },
    { lat: 48.5885663, lng: 19.7533523 },
    { lat: 48.5885129, lng: 19.7530555 },
    { lat: 48.588579, lng: 19.7529756 },
    { lat: 48.5885297, lng: 19.7526705 },
    { lat: 48.5885909, lng: 19.752453 },
    { lat: 48.588652, lng: 19.7524558 },
    { lat: 48.5886312, lng: 19.7523443 },
    { lat: 48.5885503, lng: 19.7523457 },
    { lat: 48.5885251, lng: 19.7521592 },
    { lat: 48.5886435, lng: 19.7520872 },
    { lat: 48.5885798, lng: 19.7518897 },
    { lat: 48.588612, lng: 19.7518277 },
    { lat: 48.5885689, lng: 19.7518239 },
    { lat: 48.5885268, lng: 19.7516366 },
    { lat: 48.5885509, lng: 19.7515922 },
    { lat: 48.5885311, lng: 19.7515291 },
    { lat: 48.5884874, lng: 19.7515623 },
    { lat: 48.5884903, lng: 19.751294 },
    { lat: 48.5884438, lng: 19.7511068 },
    { lat: 48.5885965, lng: 19.7507912 },
    { lat: 48.5885836, lng: 19.7506473 },
    { lat: 48.5886693, lng: 19.7507013 },
    { lat: 48.5887336, lng: 19.7506753 },
    { lat: 48.5887378, lng: 19.7505955 },
    { lat: 48.5886109, lng: 19.7505004 },
    { lat: 48.5886044, lng: 19.7501772 },
    { lat: 48.5885354, lng: 19.7500418 },
    { lat: 48.5886132, lng: 19.7499165 },
    { lat: 48.5885134, lng: 19.7498208 },
    { lat: 48.588587, lng: 19.7496277 },
    { lat: 48.5885397, lng: 19.7495228 },
    { lat: 48.5885713, lng: 19.7494004 },
    { lat: 48.5887277, lng: 19.7493969 },
    { lat: 48.5887932, lng: 19.7491419 },
    { lat: 48.5887933, lng: 19.7488646 },
    { lat: 48.588917, lng: 19.7486775 },
    { lat: 48.5889842, lng: 19.7487192 },
    { lat: 48.5890001, lng: 19.7485702 },
    { lat: 48.5891228, lng: 19.7485426 },
    { lat: 48.5893054, lng: 19.7482877 },
    { lat: 48.5893747, lng: 19.7483021 },
    { lat: 48.5893765, lng: 19.7480642 },
    { lat: 48.5894647, lng: 19.7478148 },
    { lat: 48.5894406, lng: 19.7477763 },
    { lat: 48.5895185, lng: 19.7475795 },
    { lat: 48.5896273, lng: 19.747491 },
    { lat: 48.5896925, lng: 19.7472029 },
    { lat: 48.5897367, lng: 19.7471828 },
    { lat: 48.5897256, lng: 19.7470334 },
    { lat: 48.5898921, lng: 19.7467996 },
    { lat: 48.5898683, lng: 19.7465927 },
    { lat: 48.5900019, lng: 19.7464793 },
    { lat: 48.5900832, lng: 19.7465079 },
    { lat: 48.5900642, lng: 19.7462315 },
    { lat: 48.5901793, lng: 19.7460035 },
    { lat: 48.5902852, lng: 19.7459548 },
    { lat: 48.5902954, lng: 19.7458275 },
    { lat: 48.5904581, lng: 19.7457905 },
    { lat: 48.5904525, lng: 19.7456366 },
    { lat: 48.5906638, lng: 19.7452129 },
    { lat: 48.5907888, lng: 19.7447818 },
    { lat: 48.5907324, lng: 19.7446768 },
    { lat: 48.5907237, lng: 19.744451 },
    { lat: 48.5909518, lng: 19.7438256 },
    { lat: 48.5910664, lng: 19.7436418 },
    { lat: 48.5910633, lng: 19.743315 },
    { lat: 48.5911807, lng: 19.742962 },
    { lat: 48.5913834, lng: 19.7427843 },
    { lat: 48.5914101, lng: 19.7426713 },
    { lat: 48.590808, lng: 19.7418469 },
    { lat: 48.5908751, lng: 19.7416551 },
    { lat: 48.5909875, lng: 19.7415225 },
    { lat: 48.5910813, lng: 19.7415576 },
    { lat: 48.5914408, lng: 19.7409449 },
    { lat: 48.5917028, lng: 19.7403179 },
    { lat: 48.5918777, lng: 19.7396376 },
    { lat: 48.5919028, lng: 19.7392133 },
    { lat: 48.5918507, lng: 19.7387174 },
    { lat: 48.5919873, lng: 19.7381115 },
    { lat: 48.5916994, lng: 19.7375642 },
    { lat: 48.5913508, lng: 19.7366108 },
    { lat: 48.5911473, lng: 19.7363387 },
    { lat: 48.5911587, lng: 19.7357318 },
    { lat: 48.5910147, lng: 19.7358381 },
    { lat: 48.5912223, lng: 19.7347517 },
    { lat: 48.591296, lng: 19.7347831 },
    { lat: 48.5914342, lng: 19.7340922 },
    { lat: 48.591377, lng: 19.7339677 },
    { lat: 48.5914333, lng: 19.7335332 },
    { lat: 48.5915026, lng: 19.7334166 },
    { lat: 48.5918141, lng: 19.7333024 },
    { lat: 48.5917945, lng: 19.7329652 },
    { lat: 48.5918402, lng: 19.732621 },
    { lat: 48.5918903, lng: 19.7326067 },
    { lat: 48.5919938, lng: 19.7323483 },
    { lat: 48.59207, lng: 19.7323413 },
    { lat: 48.5920587, lng: 19.7317548 },
    { lat: 48.5920108, lng: 19.731382 },
  ],
  Sušany: [
    { lat: 48.441251, lng: 19.8861882 },
    { lat: 48.4409606, lng: 19.8865876 },
    { lat: 48.4406609, lng: 19.8865624 },
    { lat: 48.4405022, lng: 19.8866651 },
    { lat: 48.4402406, lng: 19.8867133 },
    { lat: 48.4397285, lng: 19.8865974 },
    { lat: 48.4384513, lng: 19.8868371 },
    { lat: 48.4375061, lng: 19.8869141 },
    { lat: 48.4368515, lng: 19.886811 },
    { lat: 48.4362919, lng: 19.8868189 },
    { lat: 48.4357632, lng: 19.8866797 },
    { lat: 48.4355281, lng: 19.8866859 },
    { lat: 48.4352582, lng: 19.8868047 },
    { lat: 48.4336262, lng: 19.8880935 },
    { lat: 48.4330069, lng: 19.8873662 },
    { lat: 48.431951, lng: 19.88676 },
    { lat: 48.4312565, lng: 19.8867771 },
    { lat: 48.4298583, lng: 19.8870364 },
    { lat: 48.4288266, lng: 19.8870898 },
    { lat: 48.4277158, lng: 19.8872733 },
    { lat: 48.4277035, lng: 19.8870418 },
    { lat: 48.4277579, lng: 19.8866569 },
    { lat: 48.4270445, lng: 19.8865688 },
    { lat: 48.4269355, lng: 19.8863949 },
    { lat: 48.4267502, lng: 19.8858844 },
    { lat: 48.4242709, lng: 19.8838213 },
    { lat: 48.424252, lng: 19.8838057 },
    { lat: 48.4228208, lng: 19.8828766 },
    { lat: 48.4218064, lng: 19.8824226 },
    { lat: 48.4216753, lng: 19.8823101 },
    { lat: 48.4207012, lng: 19.8826329 },
    { lat: 48.4205244, lng: 19.8827639 },
    { lat: 48.4191556, lng: 19.8829449 },
    { lat: 48.4177591, lng: 19.8830892 },
    { lat: 48.4170804, lng: 19.8828658 },
    { lat: 48.4156985, lng: 19.8819071 },
    { lat: 48.4150144, lng: 19.8813566 },
    { lat: 48.4135023, lng: 19.8799366 },
    { lat: 48.4126836, lng: 19.8792911 },
    { lat: 48.4115742, lng: 19.8777338 },
    { lat: 48.4114896, lng: 19.8773651 },
    { lat: 48.4110302, lng: 19.8762712 },
    { lat: 48.4104333, lng: 19.8751804 },
    { lat: 48.4093537, lng: 19.8742072 },
    { lat: 48.4091245, lng: 19.8744323 },
    { lat: 48.4089767, lng: 19.8744797 },
    { lat: 48.4088575, lng: 19.8745079 },
    { lat: 48.4087538, lng: 19.8743741 },
    { lat: 48.408626, lng: 19.8745216 },
    { lat: 48.4085019, lng: 19.8745723 },
    { lat: 48.4084132, lng: 19.8744739 },
    { lat: 48.4082187, lng: 19.8745084 },
    { lat: 48.4081287, lng: 19.8748243 },
    { lat: 48.408218, lng: 19.8754325 },
    { lat: 48.4081244, lng: 19.8756934 },
    { lat: 48.408046, lng: 19.875806 },
    { lat: 48.4078595, lng: 19.8757894 },
    { lat: 48.407633, lng: 19.8759465 },
    { lat: 48.407406, lng: 19.8759874 },
    { lat: 48.4055296, lng: 19.8735456 },
    { lat: 48.4035449, lng: 19.8767621 },
    { lat: 48.4025674, lng: 19.8782094 },
    { lat: 48.4020963, lng: 19.8786498 },
    { lat: 48.4011949, lng: 19.8793487 },
    { lat: 48.4009299, lng: 19.8789012 },
    { lat: 48.39998, lng: 19.8799157 },
    { lat: 48.3989838, lng: 19.8812916 },
    { lat: 48.3980087, lng: 19.8824258 },
    { lat: 48.3976484, lng: 19.8845719 },
    { lat: 48.3973264, lng: 19.8849725 },
    { lat: 48.3958734, lng: 19.8861786 },
    { lat: 48.3951781, lng: 19.886491 },
    { lat: 48.395132, lng: 19.885918 },
    { lat: 48.39369, lng: 19.886258 },
    { lat: 48.393933, lng: 19.887407 },
    { lat: 48.394149, lng: 19.888413 },
    { lat: 48.39466, lng: 19.890809 },
    { lat: 48.394777, lng: 19.891352 },
    { lat: 48.394784, lng: 19.891387 },
    { lat: 48.394828, lng: 19.891605 },
    { lat: 48.39483, lng: 19.891611 },
    { lat: 48.394972, lng: 19.892297 },
    { lat: 48.395297, lng: 19.893856 },
    { lat: 48.395478, lng: 19.894706 },
    { lat: 48.395613, lng: 19.895359 },
    { lat: 48.395796, lng: 19.896235 },
    { lat: 48.395963, lng: 19.897038 },
    { lat: 48.396127, lng: 19.89783 },
    { lat: 48.396304, lng: 19.898663 },
    { lat: 48.396356, lng: 19.898923 },
    { lat: 48.396558, lng: 19.899888 },
    { lat: 48.396742, lng: 19.900746 },
    { lat: 48.396765, lng: 19.900838 },
    { lat: 48.396534, lng: 19.900811 },
    { lat: 48.396729, lng: 19.901554 },
    { lat: 48.397028, lng: 19.901922 },
    { lat: 48.397168, lng: 19.901959 },
    { lat: 48.397303, lng: 19.901995 },
    { lat: 48.39742, lng: 19.901917 },
    { lat: 48.39768, lng: 19.901728 },
    { lat: 48.397815, lng: 19.90163 },
    { lat: 48.397763, lng: 19.902085 },
    { lat: 48.397841, lng: 19.902721 },
    { lat: 48.39787, lng: 19.902932 },
    { lat: 48.397852, lng: 19.903346 },
    { lat: 48.398053, lng: 19.903468 },
    { lat: 48.398537, lng: 19.903907 },
    { lat: 48.399001, lng: 19.904717 },
    { lat: 48.399185, lng: 19.904761 },
    { lat: 48.399197, lng: 19.904807 },
    { lat: 48.399313, lng: 19.905257 },
    { lat: 48.399322, lng: 19.905296 },
    { lat: 48.39946, lng: 19.905828 },
    { lat: 48.399471, lng: 19.905873 },
    { lat: 48.399781, lng: 19.907119 },
    { lat: 48.400039, lng: 19.908163 },
    { lat: 48.400261, lng: 19.909069 },
    { lat: 48.400264, lng: 19.909121 },
    { lat: 48.399834, lng: 19.909115 },
    { lat: 48.399449, lng: 19.909108 },
    { lat: 48.399053, lng: 19.909103 },
    { lat: 48.398878, lng: 19.909105 },
    { lat: 48.398639, lng: 19.909711 },
    { lat: 48.398633, lng: 19.909725 },
    { lat: 48.39848, lng: 19.910115 },
    { lat: 48.398182, lng: 19.911112 },
    { lat: 48.398101, lng: 19.911086 },
    { lat: 48.398153, lng: 19.911674 },
    { lat: 48.398145, lng: 19.912011 },
    { lat: 48.398144, lng: 19.913235 },
    { lat: 48.398144, lng: 19.914065 },
    { lat: 48.398337, lng: 19.914738 },
    { lat: 48.39812, lng: 19.915875 },
    { lat: 48.398062, lng: 19.91619 },
    { lat: 48.397986, lng: 19.91697 },
    { lat: 48.397983, lng: 19.917892 },
    { lat: 48.397978, lng: 19.918103 },
    { lat: 48.397987, lng: 19.918684 },
    { lat: 48.397998, lng: 19.919696 },
    { lat: 48.398002, lng: 19.920232 },
    { lat: 48.398147, lng: 19.920072 },
    { lat: 48.39817, lng: 19.920036 },
    { lat: 48.398235, lng: 19.919909 },
    { lat: 48.398615, lng: 19.919159 },
    { lat: 48.398903, lng: 19.918595 },
    { lat: 48.399135, lng: 19.918121 },
    { lat: 48.399191, lng: 19.918006 },
    { lat: 48.399248, lng: 19.917893 },
    { lat: 48.399288, lng: 19.917929 },
    { lat: 48.399538, lng: 19.918131 },
    { lat: 48.399644, lng: 19.918219 },
    { lat: 48.399911, lng: 19.918452 },
    { lat: 48.400241, lng: 19.918754 },
    { lat: 48.400521, lng: 19.919005 },
    { lat: 48.400794, lng: 19.919248 },
    { lat: 48.400889, lng: 19.919336 },
    { lat: 48.401114, lng: 19.919532 },
    { lat: 48.401308, lng: 19.9197 },
    { lat: 48.401489, lng: 19.91974 },
    { lat: 48.401498, lng: 19.919753 },
    { lat: 48.401632, lng: 19.919898 },
    { lat: 48.401642, lng: 19.919911 },
    { lat: 48.401765, lng: 19.919931 },
    { lat: 48.402158, lng: 19.919992 },
    { lat: 48.402387, lng: 19.920032 },
    { lat: 48.402507, lng: 19.920052 },
    { lat: 48.402537, lng: 19.919698 },
    { lat: 48.402647, lng: 19.918399 },
    { lat: 48.4027, lng: 19.917841 },
    { lat: 48.402798, lng: 19.91665 },
    { lat: 48.40287, lng: 19.915665 },
    { lat: 48.402908, lng: 19.915629 },
    { lat: 48.403148, lng: 19.915785 },
    { lat: 48.403501, lng: 19.915916 },
    { lat: 48.404014, lng: 19.916015 },
    { lat: 48.404194, lng: 19.91604 },
    { lat: 48.404609, lng: 19.916096 },
    { lat: 48.405224, lng: 19.916252 },
    { lat: 48.40535, lng: 19.916283 },
    { lat: 48.405983, lng: 19.91645 },
    { lat: 48.406122, lng: 19.916418 },
    { lat: 48.406782, lng: 19.916264 },
    { lat: 48.407061, lng: 19.916325 },
    { lat: 48.407212, lng: 19.916356 },
    { lat: 48.407893, lng: 19.916551 },
    { lat: 48.408025, lng: 19.916564 },
    { lat: 48.408379, lng: 19.916587 },
    { lat: 48.408651, lng: 19.916564 },
    { lat: 48.408907, lng: 19.916532 },
    { lat: 48.409171, lng: 19.916448 },
    { lat: 48.40923, lng: 19.916435 },
    { lat: 48.409516, lng: 19.916385 },
    { lat: 48.409886, lng: 19.916317 },
    { lat: 48.410046, lng: 19.916284 },
    { lat: 48.410227, lng: 19.916234 },
    { lat: 48.410351, lng: 19.916182 },
    { lat: 48.410779, lng: 19.915987 },
    { lat: 48.410842, lng: 19.915975 },
    { lat: 48.410893, lng: 19.915952 },
    { lat: 48.410984, lng: 19.915926 },
    { lat: 48.41128, lng: 19.915831 },
    { lat: 48.411375, lng: 19.915802 },
    { lat: 48.41162, lng: 19.915755 },
    { lat: 48.411947, lng: 19.915714 },
    { lat: 48.412141, lng: 19.915705 },
    { lat: 48.41259, lng: 19.915686 },
    { lat: 48.413062, lng: 19.915666 },
    { lat: 48.413189, lng: 19.915661 },
    { lat: 48.413506, lng: 19.915641 },
    { lat: 48.413768, lng: 19.915631 },
    { lat: 48.414089, lng: 19.915606 },
    { lat: 48.414602, lng: 19.91558 },
    { lat: 48.414672, lng: 19.915573 },
    { lat: 48.414765, lng: 19.915545 },
    { lat: 48.415153, lng: 19.915431 },
    { lat: 48.415548, lng: 19.915318 },
    { lat: 48.416055, lng: 19.915161 },
    { lat: 48.416266, lng: 19.915113 },
    { lat: 48.416668, lng: 19.915011 },
    { lat: 48.417166, lng: 19.914865 },
    { lat: 48.417336, lng: 19.914818 },
    { lat: 48.417398, lng: 19.914791 },
    { lat: 48.417755, lng: 19.914698 },
    { lat: 48.41816, lng: 19.91462 },
    { lat: 48.418582, lng: 19.914783 },
    { lat: 48.418673, lng: 19.914819 },
    { lat: 48.418797, lng: 19.914882 },
    { lat: 48.418957, lng: 19.914978 },
    { lat: 48.419296, lng: 19.915176 },
    { lat: 48.41934, lng: 19.915221 },
    { lat: 48.419361, lng: 19.915244 },
    { lat: 48.419531, lng: 19.915343 },
    { lat: 48.419712, lng: 19.91544 },
    { lat: 48.420137, lng: 19.915574 },
    { lat: 48.420663, lng: 19.915763 },
    { lat: 48.420897, lng: 19.915858 },
    { lat: 48.421175, lng: 19.915977 },
    { lat: 48.421399, lng: 19.916071 },
    { lat: 48.421417, lng: 19.916119 },
    { lat: 48.421472, lng: 19.916132 },
    { lat: 48.421564, lng: 19.916151 },
    { lat: 48.421848, lng: 19.916203 },
    { lat: 48.421908, lng: 19.916203 },
    { lat: 48.422015, lng: 19.916183 },
    { lat: 48.422277, lng: 19.916126 },
    { lat: 48.422667, lng: 19.916037 },
    { lat: 48.422933, lng: 19.915975 },
    { lat: 48.423072, lng: 19.915944 },
    { lat: 48.423487, lng: 19.915854 },
    { lat: 48.423926, lng: 19.915759 },
    { lat: 48.42446, lng: 19.915642 },
    { lat: 48.424786, lng: 19.915576 },
    { lat: 48.424999, lng: 19.915531 },
    { lat: 48.425233, lng: 19.915476 },
    { lat: 48.425479, lng: 19.915426 },
    { lat: 48.425942, lng: 19.915327 },
    { lat: 48.426324, lng: 19.915228 },
    { lat: 48.426702, lng: 19.915152 },
    { lat: 48.427066, lng: 19.915087 },
    { lat: 48.427125, lng: 19.915062 },
    { lat: 48.427709, lng: 19.914939 },
    { lat: 48.427856, lng: 19.914906 },
    { lat: 48.42824, lng: 19.914818 },
    { lat: 48.428339, lng: 19.914794 },
    { lat: 48.428476, lng: 19.914762 },
    { lat: 48.428538, lng: 19.914752 },
    { lat: 48.42861, lng: 19.914728 },
    { lat: 48.428839, lng: 19.914677 },
    { lat: 48.428857, lng: 19.914728 },
    { lat: 48.42913, lng: 19.915478 },
    { lat: 48.429582, lng: 19.916718 },
    { lat: 48.429638, lng: 19.916873 },
    { lat: 48.429858, lng: 19.917483 },
    { lat: 48.430151, lng: 19.918295 },
    { lat: 48.430336, lng: 19.91879 },
    { lat: 48.430415, lng: 19.919008 },
    { lat: 48.430487, lng: 19.919205 },
    { lat: 48.430865, lng: 19.920243 },
    { lat: 48.430975, lng: 19.920547 },
    { lat: 48.431217, lng: 19.921211 },
    { lat: 48.431299, lng: 19.921449 },
    { lat: 48.43146, lng: 19.921883 },
    { lat: 48.431545, lng: 19.922126 },
    { lat: 48.431629, lng: 19.922359 },
    { lat: 48.431716, lng: 19.922605 },
    { lat: 48.431947, lng: 19.923236 },
    { lat: 48.432108, lng: 19.923683 },
    { lat: 48.432133, lng: 19.923751 },
    { lat: 48.432291, lng: 19.924185 },
    { lat: 48.432322, lng: 19.924271 },
    { lat: 48.432449, lng: 19.924618 },
    { lat: 48.432628, lng: 19.925108 },
    { lat: 48.432718, lng: 19.925361 },
    { lat: 48.43285, lng: 19.925216 },
    { lat: 48.432978, lng: 19.925075 },
    { lat: 48.433163, lng: 19.924554 },
    { lat: 48.433388, lng: 19.923913 },
    { lat: 48.433511, lng: 19.92383 },
    { lat: 48.433972, lng: 19.923525 },
    { lat: 48.434248, lng: 19.923249 },
    { lat: 48.434423, lng: 19.92352 },
    { lat: 48.434591, lng: 19.923777 },
    { lat: 48.434787, lng: 19.924084 },
    { lat: 48.434967, lng: 19.92408 },
    { lat: 48.435379, lng: 19.924061 },
    { lat: 48.435483, lng: 19.924136 },
    { lat: 48.435497, lng: 19.924144 },
    { lat: 48.435627, lng: 19.924236 },
    { lat: 48.435738, lng: 19.924318 },
    { lat: 48.436121, lng: 19.924592 },
    { lat: 48.436358, lng: 19.924762 },
    { lat: 48.436587, lng: 19.924929 },
    { lat: 48.436758, lng: 19.925107 },
    { lat: 48.436943, lng: 19.925298 },
    { lat: 48.437283, lng: 19.925651 },
    { lat: 48.437551, lng: 19.925926 },
    { lat: 48.437826, lng: 19.926186 },
    { lat: 48.438017, lng: 19.926368 },
    { lat: 48.438142, lng: 19.926481 },
    { lat: 48.438519, lng: 19.926845 },
    { lat: 48.43871, lng: 19.927016 },
    { lat: 48.438821, lng: 19.927118 },
    { lat: 48.439161, lng: 19.927432 },
    { lat: 48.439242, lng: 19.927516 },
    { lat: 48.439262, lng: 19.927335 },
    { lat: 48.439346, lng: 19.926484 },
    { lat: 48.439156, lng: 19.925329 },
    { lat: 48.439094, lng: 19.92497 },
    { lat: 48.439043, lng: 19.924683 },
    { lat: 48.439017, lng: 19.924269 },
    { lat: 48.438989, lng: 19.923849 },
    { lat: 48.438963, lng: 19.923596 },
    { lat: 48.438939, lng: 19.923423 },
    { lat: 48.438885, lng: 19.923169 },
    { lat: 48.438745, lng: 19.922512 },
    { lat: 48.438675, lng: 19.922197 },
    { lat: 48.43857, lng: 19.921689 },
    { lat: 48.438371, lng: 19.920708 },
    { lat: 48.438227, lng: 19.920008 },
    { lat: 48.438191, lng: 19.919845 },
    { lat: 48.438113, lng: 19.91942 },
    { lat: 48.438031, lng: 19.918977 },
    { lat: 48.437977, lng: 19.918678 },
    { lat: 48.437881, lng: 19.918157 },
    { lat: 48.437853, lng: 19.917585 },
    { lat: 48.437821, lng: 19.916888 },
    { lat: 48.437798, lng: 19.916361 },
    { lat: 48.437769, lng: 19.915649 },
    { lat: 48.437747, lng: 19.915089 },
    { lat: 48.437751, lng: 19.914852 },
    { lat: 48.437777, lng: 19.913869 },
    { lat: 48.437795, lng: 19.913198 },
    { lat: 48.437805, lng: 19.912932 },
    { lat: 48.437985, lng: 19.912013 },
    { lat: 48.438132, lng: 19.911275 },
    { lat: 48.438161, lng: 19.911166 },
    { lat: 48.43823, lng: 19.910953 },
    { lat: 48.438262, lng: 19.910844 },
    { lat: 48.438323, lng: 19.910668 },
    { lat: 48.438501, lng: 19.910279 },
    { lat: 48.438692, lng: 19.909805 },
    { lat: 48.438803, lng: 19.90964 },
    { lat: 48.438859, lng: 19.90958 },
    { lat: 48.438922, lng: 19.909432 },
    { lat: 48.439019, lng: 19.909273 },
    { lat: 48.43918, lng: 19.909157 },
    { lat: 48.439238, lng: 19.909052 },
    { lat: 48.439306, lng: 19.908797 },
    { lat: 48.439688, lng: 19.908187 },
    { lat: 48.43982, lng: 19.907789 },
    { lat: 48.439992, lng: 19.907288 },
    { lat: 48.440035, lng: 19.90696 },
    { lat: 48.44008, lng: 19.906832 },
    { lat: 48.440394, lng: 19.90606 },
    { lat: 48.440538, lng: 19.905815 },
    { lat: 48.440901, lng: 19.905201 },
    { lat: 48.441091, lng: 19.904943 },
    { lat: 48.4414, lng: 19.904485 },
    { lat: 48.441536, lng: 19.904371 },
    { lat: 48.441784, lng: 19.903944 },
    { lat: 48.441886, lng: 19.903852 },
    { lat: 48.44195, lng: 19.903784 },
    { lat: 48.44231, lng: 19.903475 },
    { lat: 48.44262, lng: 19.90306 },
    { lat: 48.442885, lng: 19.902833 },
    { lat: 48.443256, lng: 19.902247 },
    { lat: 48.443378, lng: 19.902021 },
    { lat: 48.443425, lng: 19.901935 },
    { lat: 48.443758, lng: 19.901445 },
    { lat: 48.443948, lng: 19.901219 },
    { lat: 48.444349, lng: 19.900578 },
    { lat: 48.444604, lng: 19.900189 },
    { lat: 48.444901, lng: 19.899735 },
    { lat: 48.445023, lng: 19.899484 },
    { lat: 48.445064, lng: 19.899423 },
    { lat: 48.445263, lng: 19.899134 },
    { lat: 48.445562, lng: 19.898666 },
    { lat: 48.44565, lng: 19.898536 },
    { lat: 48.4454016, lng: 19.8983022 },
    { lat: 48.4453718, lng: 19.8979765 },
    { lat: 48.4450987, lng: 19.8976546 },
    { lat: 48.4449908, lng: 19.8977367 },
    { lat: 48.4448982, lng: 19.8976016 },
    { lat: 48.4447924, lng: 19.8975516 },
    { lat: 48.4446077, lng: 19.8972643 },
    { lat: 48.4438443, lng: 19.896694 },
    { lat: 48.4436856, lng: 19.8964121 },
    { lat: 48.4436716, lng: 19.8962669 },
    { lat: 48.4434061, lng: 19.8959055 },
    { lat: 48.4431538, lng: 19.8952544 },
    { lat: 48.4426074, lng: 19.8945205 },
    { lat: 48.442208, lng: 19.8941626 },
    { lat: 48.4420389, lng: 19.8942155 },
    { lat: 48.4418877, lng: 19.8941585 },
    { lat: 48.4418003, lng: 19.8940091 },
    { lat: 48.4417385, lng: 19.8936482 },
    { lat: 48.4415844, lng: 19.8935713 },
    { lat: 48.4415064, lng: 19.893382 },
    { lat: 48.4414767, lng: 19.8927199 },
    { lat: 48.4415447, lng: 19.8924394 },
    { lat: 48.4415463, lng: 19.892033 },
    { lat: 48.441463, lng: 19.8918229 },
    { lat: 48.4417267, lng: 19.8914833 },
    { lat: 48.4418299, lng: 19.8912111 },
    { lat: 48.4417719, lng: 19.8911248 },
    { lat: 48.4418016, lng: 19.8906064 },
    { lat: 48.4417722, lng: 19.890291 },
    { lat: 48.4416842, lng: 19.8901285 },
    { lat: 48.4417906, lng: 19.8898794 },
    { lat: 48.4417686, lng: 19.8895271 },
    { lat: 48.441673, lng: 19.8892919 },
    { lat: 48.4417528, lng: 19.8890269 },
    { lat: 48.441653, lng: 19.8888815 },
    { lat: 48.441719, lng: 19.8886864 },
    { lat: 48.4417205, lng: 19.8884588 },
    { lat: 48.4416918, lng: 19.8884705 },
    { lat: 48.4416336, lng: 19.8877225 },
    { lat: 48.4415209, lng: 19.887512 },
    { lat: 48.4414896, lng: 19.8873114 },
    { lat: 48.4413906, lng: 19.8872586 },
    { lat: 48.4413827, lng: 19.8870763 },
    { lat: 48.4414436, lng: 19.8869703 },
    { lat: 48.4414256, lng: 19.8868153 },
    { lat: 48.4412812, lng: 19.886549 },
    { lat: 48.4412669, lng: 19.8862438 },
    { lat: 48.441251, lng: 19.8861882 },
  ],
  Ozdín: [
    { lat: 48.4696723, lng: 19.7175948 },
    { lat: 48.4699068, lng: 19.7175211 },
    { lat: 48.4701261, lng: 19.7173872 },
    { lat: 48.4702848, lng: 19.7170553 },
    { lat: 48.4703221, lng: 19.7169059 },
    { lat: 48.4703127, lng: 19.7166406 },
    { lat: 48.4704454, lng: 19.7164071 },
    { lat: 48.4706013, lng: 19.7164415 },
    { lat: 48.4706949, lng: 19.7165446 },
    { lat: 48.4708026, lng: 19.7164333 },
    { lat: 48.4708254, lng: 19.7162151 },
    { lat: 48.4709288, lng: 19.7160552 },
    { lat: 48.4711035, lng: 19.7159276 },
    { lat: 48.4713278, lng: 19.7155017 },
    { lat: 48.4712865, lng: 19.7149778 },
    { lat: 48.4709608, lng: 19.7147198 },
    { lat: 48.4708564, lng: 19.7147303 },
    { lat: 48.4708254, lng: 19.714617 },
    { lat: 48.4708956, lng: 19.7144231 },
    { lat: 48.4709456, lng: 19.7144593 },
    { lat: 48.4710286, lng: 19.7142972 },
    { lat: 48.4709841, lng: 19.7138802 },
    { lat: 48.470692, lng: 19.7134455 },
    { lat: 48.4704563, lng: 19.7132619 },
    { lat: 48.4705662, lng: 19.712948 },
    { lat: 48.4709866, lng: 19.7125673 },
    { lat: 48.4709556, lng: 19.7121486 },
    { lat: 48.4710104, lng: 19.7116609 },
    { lat: 48.4713661, lng: 19.7114248 },
    { lat: 48.4714456, lng: 19.7114658 },
    { lat: 48.4714751, lng: 19.7118992 },
    { lat: 48.4715559, lng: 19.7120924 },
    { lat: 48.4716168, lng: 19.7121452 },
    { lat: 48.4717413, lng: 19.7120826 },
    { lat: 48.4719896, lng: 19.712495 },
    { lat: 48.4720638, lng: 19.7124166 },
    { lat: 48.4721709, lng: 19.7121391 },
    { lat: 48.4730376, lng: 19.711511 },
    { lat: 48.4737712, lng: 19.7108069 },
    { lat: 48.4745271, lng: 19.7106231 },
    { lat: 48.4751708, lng: 19.7098982 },
    { lat: 48.4755258, lng: 19.7095471 },
    { lat: 48.4759311, lng: 19.7092626 },
    { lat: 48.4764222, lng: 19.7091308 },
    { lat: 48.4767006, lng: 19.708956 },
    { lat: 48.4773188, lng: 19.7079605 },
    { lat: 48.4779533, lng: 19.7072595 },
    { lat: 48.4788546, lng: 19.7066714 },
    { lat: 48.4842888, lng: 19.7020725 },
    { lat: 48.4845029, lng: 19.7018168 },
    { lat: 48.4845971, lng: 19.7016063 },
    { lat: 48.4848709, lng: 19.7004436 },
    { lat: 48.4848743, lng: 19.7004461 },
    { lat: 48.4852968, lng: 19.6991781 },
    { lat: 48.4856215, lng: 19.6986295 },
    { lat: 48.4863596, lng: 19.6979263 },
    { lat: 48.4868445, lng: 19.6977356 },
    { lat: 48.487223, lng: 19.697702 },
    { lat: 48.487663, lng: 19.6975425 },
    { lat: 48.4881546, lng: 19.6972186 },
    { lat: 48.4884998, lng: 19.6966755 },
    { lat: 48.489374, lng: 19.6948058 },
    { lat: 48.4874914, lng: 19.6880265 },
    { lat: 48.4869221, lng: 19.6884472 },
    { lat: 48.4860793, lng: 19.6862816 },
    { lat: 48.4869418, lng: 19.6858903 },
    { lat: 48.4864545, lng: 19.6847981 },
    { lat: 48.4859005, lng: 19.6841264 },
    { lat: 48.4856367, lng: 19.6839205 },
    { lat: 48.4853178, lng: 19.6838679 },
    { lat: 48.4838656, lng: 19.6820108 },
    { lat: 48.4849426, lng: 19.6796452 },
    { lat: 48.4843829, lng: 19.6785405 },
    { lat: 48.4831227, lng: 19.6766691 },
    { lat: 48.4824605, lng: 19.6754704 },
    { lat: 48.4834248, lng: 19.6738074 },
    { lat: 48.4839995, lng: 19.6733069 },
    { lat: 48.484266, lng: 19.6729211 },
    { lat: 48.4843844, lng: 19.6728963 },
    { lat: 48.4845061, lng: 19.6727458 },
    { lat: 48.484767, lng: 19.6721048 },
    { lat: 48.4849182, lng: 19.6713053 },
    { lat: 48.4853533, lng: 19.670631 },
    { lat: 48.4857982, lng: 19.6689708 },
    { lat: 48.4861037, lng: 19.6691745 },
    { lat: 48.4862509, lng: 19.6684133 },
    { lat: 48.4871404, lng: 19.6671213 },
    { lat: 48.4875511, lng: 19.6667546 },
    { lat: 48.4876987, lng: 19.6664306 },
    { lat: 48.4882282, lng: 19.6658752 },
    { lat: 48.4886628, lng: 19.6656897 },
    { lat: 48.4890492, lng: 19.6650863 },
    { lat: 48.4895319, lng: 19.664822 },
    { lat: 48.4897787, lng: 19.6645706 },
    { lat: 48.4901194, lng: 19.664407 },
    { lat: 48.4909176, lng: 19.6630591 },
    { lat: 48.4913601, lng: 19.6627758 },
    { lat: 48.4917645, lng: 19.6617237 },
    { lat: 48.493268, lng: 19.6599764 },
    { lat: 48.4945157, lng: 19.659232 },
    { lat: 48.4956718, lng: 19.6582787 },
    { lat: 48.4959002, lng: 19.657843 },
    { lat: 48.4960583, lng: 19.6571498 },
    { lat: 48.4963527, lng: 19.6565542 },
    { lat: 48.4966353, lng: 19.6556994 },
    { lat: 48.497236, lng: 19.6549274 },
    { lat: 48.4983631, lng: 19.6540586 },
    { lat: 48.4987082, lng: 19.6535112 },
    { lat: 48.4988763, lng: 19.6529444 },
    { lat: 48.4992337, lng: 19.652218 },
    { lat: 48.4996287, lng: 19.6515837 },
    { lat: 48.5004473, lng: 19.6505431 },
    { lat: 48.5011938, lng: 19.6497073 },
    { lat: 48.5018788, lng: 19.6491521 },
    { lat: 48.5015639, lng: 19.6480164 },
    { lat: 48.5012856, lng: 19.6469394 },
    { lat: 48.5011632, lng: 19.6462257 },
    { lat: 48.5011276, lng: 19.6445875 },
    { lat: 48.5009722, lng: 19.6436383 },
    { lat: 48.5001227, lng: 19.6432422 },
    { lat: 48.4997911, lng: 19.6433877 },
    { lat: 48.4993757, lng: 19.6436941 },
    { lat: 48.4989783, lng: 19.6436273 },
    { lat: 48.4986551, lng: 19.6434333 },
    { lat: 48.4984385, lng: 19.6434263 },
    { lat: 48.4981491, lng: 19.6442769 },
    { lat: 48.4982676, lng: 19.6445327 },
    { lat: 48.4983623, lng: 19.645732 },
    { lat: 48.4981863, lng: 19.6469295 },
    { lat: 48.498397, lng: 19.6476545 },
    { lat: 48.4981824, lng: 19.6486417 },
    { lat: 48.4979143, lng: 19.6486441 },
    { lat: 48.4972699, lng: 19.6470973 },
    { lat: 48.496735, lng: 19.6466818 },
    { lat: 48.4959767, lng: 19.6463549 },
    { lat: 48.4959635, lng: 19.6461872 },
    { lat: 48.4956045, lng: 19.6458234 },
    { lat: 48.4953754, lng: 19.6467389 },
    { lat: 48.4952238, lng: 19.6467018 },
    { lat: 48.4949372, lng: 19.647244 },
    { lat: 48.4947253, lng: 19.6474191 },
    { lat: 48.4944248, lng: 19.6475265 },
    { lat: 48.4941102, lng: 19.6478222 },
    { lat: 48.4936783, lng: 19.6484609 },
    { lat: 48.4934555, lng: 19.6492176 },
    { lat: 48.4931366, lng: 19.6494181 },
    { lat: 48.4924186, lng: 19.6504889 },
    { lat: 48.4922439, lng: 19.650636 },
    { lat: 48.4920522, lng: 19.6506843 },
    { lat: 48.4917565, lng: 19.6511031 },
    { lat: 48.4916683, lng: 19.651419 },
    { lat: 48.4915612, lng: 19.6521443 },
    { lat: 48.4911989, lng: 19.6521329 },
    { lat: 48.4904937, lng: 19.6527855 },
    { lat: 48.4897886, lng: 19.6516873 },
    { lat: 48.4895638, lng: 19.6509284 },
    { lat: 48.4893454, lng: 19.6505816 },
    { lat: 48.4892694, lng: 19.6506707 },
    { lat: 48.4890917, lng: 19.6501755 },
    { lat: 48.4895398, lng: 19.6466437 },
    { lat: 48.4894883, lng: 19.6465001 },
    { lat: 48.4891424, lng: 19.6464495 },
    { lat: 48.4886999, lng: 19.6469358 },
    { lat: 48.4878459, lng: 19.6476609 },
    { lat: 48.4874522, lng: 19.6481035 },
    { lat: 48.4871315, lng: 19.6480353 },
    { lat: 48.4865624, lng: 19.6473361 },
    { lat: 48.4861457, lng: 19.6480925 },
    { lat: 48.4850193, lng: 19.6494257 },
    { lat: 48.4841698, lng: 19.6488506 },
    { lat: 48.4836779, lng: 19.6490112 },
    { lat: 48.4834886, lng: 19.6487995 },
    { lat: 48.4829587, lng: 19.6482069 },
    { lat: 48.4830763, lng: 19.6474882 },
    { lat: 48.4829447, lng: 19.6477232 },
    { lat: 48.4826463, lng: 19.64795 },
    { lat: 48.4815437, lng: 19.6472126 },
    { lat: 48.4809991, lng: 19.6469801 },
    { lat: 48.4807486, lng: 19.6474993 },
    { lat: 48.4798879, lng: 19.6488767 },
    { lat: 48.4788507, lng: 19.6502865 },
    { lat: 48.4783691, lng: 19.6508026 },
    { lat: 48.4777078, lng: 19.6504332 },
    { lat: 48.4774238, lng: 19.6504799 },
    { lat: 48.4770818, lng: 19.6503951 },
    { lat: 48.4763266, lng: 19.6497902 },
    { lat: 48.4760844, lng: 19.6494619 },
    { lat: 48.4758883, lng: 19.6495117 },
    { lat: 48.4750625, lng: 19.6493687 },
    { lat: 48.4742593, lng: 19.6488461 },
    { lat: 48.4735673, lng: 19.6481451 },
    { lat: 48.4733619, lng: 19.6478372 },
    { lat: 48.4730489, lng: 19.6475935 },
    { lat: 48.4718982, lng: 19.6480102 },
    { lat: 48.4710544, lng: 19.6483906 },
    { lat: 48.4702449, lng: 19.6488535 },
    { lat: 48.4697131, lng: 19.6488327 },
    { lat: 48.4680522, lng: 19.6493554 },
    { lat: 48.4673742, lng: 19.6493176 },
    { lat: 48.4669396, lng: 19.6495494 },
    { lat: 48.4667747, lng: 19.6496894 },
    { lat: 48.4665212, lng: 19.6500628 },
    { lat: 48.4661639, lng: 19.650335 },
    { lat: 48.4650556, lng: 19.6507959 },
    { lat: 48.4648481, lng: 19.6509455 },
    { lat: 48.464572, lng: 19.651295 },
    { lat: 48.464235, lng: 19.6515651 },
    { lat: 48.4638215, lng: 19.6517943 },
    { lat: 48.4633621, lng: 19.6524248 },
    { lat: 48.4631596, lng: 19.6528043 },
    { lat: 48.4627097, lng: 19.6533525 },
    { lat: 48.4623842, lng: 19.6550284 },
    { lat: 48.4626835, lng: 19.6559952 },
    { lat: 48.4626564, lng: 19.6560189 },
    { lat: 48.4627534, lng: 19.656499 },
    { lat: 48.4627257, lng: 19.6570073 },
    { lat: 48.4623639, lng: 19.6581849 },
    { lat: 48.4622218, lng: 19.6588053 },
    { lat: 48.4620359, lng: 19.6593088 },
    { lat: 48.4618387, lng: 19.6603365 },
    { lat: 48.461384, lng: 19.6614243 },
    { lat: 48.4610357, lng: 19.6625006 },
    { lat: 48.4608402, lng: 19.6644422 },
    { lat: 48.460922, lng: 19.6655316 },
    { lat: 48.4610271, lng: 19.6661681 },
    { lat: 48.4613136, lng: 19.6672527 },
    { lat: 48.4616974, lng: 19.6692227 },
    { lat: 48.4618002, lng: 19.672695 },
    { lat: 48.4601126, lng: 19.6743565 },
    { lat: 48.459566, lng: 19.6750559 },
    { lat: 48.4592931, lng: 19.6752313 },
    { lat: 48.4589192, lng: 19.6762383 },
    { lat: 48.4586345, lng: 19.6773034 },
    { lat: 48.4583369, lng: 19.6787337 },
    { lat: 48.4583959, lng: 19.679771 },
    { lat: 48.4586014, lng: 19.6804836 },
    { lat: 48.4586046, lng: 19.6815614 },
    { lat: 48.458783, lng: 19.6826285 },
    { lat: 48.4587359, lng: 19.6832382 },
    { lat: 48.458912, lng: 19.6838221 },
    { lat: 48.4589538, lng: 19.6847029 },
    { lat: 48.458534, lng: 19.6860013 },
    { lat: 48.458481, lng: 19.6862509 },
    { lat: 48.4584765, lng: 19.686879 },
    { lat: 48.4585591, lng: 19.6873151 },
    { lat: 48.4588554, lng: 19.687742 },
    { lat: 48.4591946, lng: 19.6891273 },
    { lat: 48.4591008, lng: 19.691085 },
    { lat: 48.4590858, lng: 19.6914315 },
    { lat: 48.4590562, lng: 19.6918506 },
    { lat: 48.4588753, lng: 19.6927492 },
    { lat: 48.45805, lng: 19.6942462 },
    { lat: 48.4569503, lng: 19.6970714 },
    { lat: 48.4567631, lng: 19.6980548 },
    { lat: 48.4563151, lng: 19.6994751 },
    { lat: 48.4562523, lng: 19.7011239 },
    { lat: 48.4560473, lng: 19.7023891 },
    { lat: 48.4561691, lng: 19.7044829 },
    { lat: 48.4561745, lng: 19.704482 },
    { lat: 48.4559405, lng: 19.7061072 },
    { lat: 48.455396, lng: 19.708426 },
    { lat: 48.4546682, lng: 19.7102201 },
    { lat: 48.4557825, lng: 19.7109801 },
    { lat: 48.4565173, lng: 19.7113359 },
    { lat: 48.4566881, lng: 19.7115026 },
    { lat: 48.4569173, lng: 19.7115651 },
    { lat: 48.4574388, lng: 19.7118864 },
    { lat: 48.4576443, lng: 19.7121007 },
    { lat: 48.4577895, lng: 19.7120942 },
    { lat: 48.4582002, lng: 19.7125431 },
    { lat: 48.4582862, lng: 19.7131528 },
    { lat: 48.4585154, lng: 19.7135177 },
    { lat: 48.4589473, lng: 19.7146439 },
    { lat: 48.4590374, lng: 19.7147698 },
    { lat: 48.4591224, lng: 19.7147062 },
    { lat: 48.4593716, lng: 19.715138 },
    { lat: 48.4595782, lng: 19.7151225 },
    { lat: 48.4596751, lng: 19.7152239 },
    { lat: 48.4597814, lng: 19.715205 },
    { lat: 48.4599661, lng: 19.7157108 },
    { lat: 48.4600378, lng: 19.7157515 },
    { lat: 48.4601084, lng: 19.7159467 },
    { lat: 48.4604253, lng: 19.7159258 },
    { lat: 48.46049, lng: 19.716039 },
    { lat: 48.461101, lng: 19.7164521 },
    { lat: 48.4607621, lng: 19.7170638 },
    { lat: 48.4607565, lng: 19.7172156 },
    { lat: 48.4611197, lng: 19.7177584 },
    { lat: 48.463838, lng: 19.7201047 },
    { lat: 48.4637671, lng: 19.7203035 },
    { lat: 48.4638329, lng: 19.7204411 },
    { lat: 48.4642234, lng: 19.7207852 },
    { lat: 48.4654231, lng: 19.7195115 },
    { lat: 48.4656617, lng: 19.7191002 },
    { lat: 48.4655662, lng: 19.7188797 },
    { lat: 48.465625, lng: 19.7187584 },
    { lat: 48.4662766, lng: 19.7179835 },
    { lat: 48.4664404, lng: 19.7167223 },
    { lat: 48.4670418, lng: 19.7173772 },
    { lat: 48.4672484, lng: 19.7177385 },
    { lat: 48.468049, lng: 19.7170206 },
    { lat: 48.4684893, lng: 19.7177468 },
    { lat: 48.4692069, lng: 19.7171644 },
    { lat: 48.4693556, lng: 19.7173643 },
    { lat: 48.4696723, lng: 19.7175948 },
  ],
  HrnčiarskaVes: [
    { lat: 48.441251, lng: 19.8861882 },
    { lat: 48.4411787, lng: 19.8859714 },
    { lat: 48.4410203, lng: 19.8859055 },
    { lat: 48.4410432, lng: 19.8858086 },
    { lat: 48.4409591, lng: 19.8855808 },
    { lat: 48.4409627, lng: 19.8854066 },
    { lat: 48.4409156, lng: 19.8853824 },
    { lat: 48.4408616, lng: 19.8851475 },
    { lat: 48.4408158, lng: 19.8851587 },
    { lat: 48.4406728, lng: 19.8848213 },
    { lat: 48.4404802, lng: 19.8842184 },
    { lat: 48.4404645, lng: 19.8839844 },
    { lat: 48.4403638, lng: 19.8836859 },
    { lat: 48.4403029, lng: 19.8836581 },
    { lat: 48.4401686, lng: 19.8831063 },
    { lat: 48.4402726, lng: 19.8822762 },
    { lat: 48.4402109, lng: 19.8823113 },
    { lat: 48.4400867, lng: 19.8820704 },
    { lat: 48.4402481, lng: 19.8817613 },
    { lat: 48.4402027, lng: 19.8815454 },
    { lat: 48.4401176, lng: 19.881636 },
    { lat: 48.4400852, lng: 19.8814813 },
    { lat: 48.4402474, lng: 19.8812743 },
    { lat: 48.4401833, lng: 19.8810963 },
    { lat: 48.4402307, lng: 19.880705 },
    { lat: 48.4403504, lng: 19.8806288 },
    { lat: 48.4404502, lng: 19.8803058 },
    { lat: 48.4404533, lng: 19.8799876 },
    { lat: 48.4405099, lng: 19.8799427 },
    { lat: 48.4405731, lng: 19.8795512 },
    { lat: 48.4407132, lng: 19.8795103 },
    { lat: 48.4407865, lng: 19.8793264 },
    { lat: 48.4407361, lng: 19.879204 },
    { lat: 48.4408071, lng: 19.8791761 },
    { lat: 48.4409079, lng: 19.8789297 },
    { lat: 48.4409796, lng: 19.8789206 },
    { lat: 48.4409057, lng: 19.8785864 },
    { lat: 48.4410598, lng: 19.8782677 },
    { lat: 48.4410823, lng: 19.8781385 },
    { lat: 48.4410318, lng: 19.8780678 },
    { lat: 48.4411197, lng: 19.8778837 },
    { lat: 48.4410038, lng: 19.8776264 },
    { lat: 48.4410724, lng: 19.8773351 },
    { lat: 48.44124, lng: 19.8770938 },
    { lat: 48.4413293, lng: 19.8764986 },
    { lat: 48.4415468, lng: 19.8761109 },
    { lat: 48.441485, lng: 19.8759814 },
    { lat: 48.4416799, lng: 19.8758801 },
    { lat: 48.4416781, lng: 19.8757068 },
    { lat: 48.441596, lng: 19.8756041 },
    { lat: 48.4417118, lng: 19.8752614 },
    { lat: 48.4418268, lng: 19.8752353 },
    { lat: 48.442075, lng: 19.8745647 },
    { lat: 48.442225, lng: 19.8744411 },
    { lat: 48.4422642, lng: 19.8743028 },
    { lat: 48.4423001, lng: 19.8740634 },
    { lat: 48.4422208, lng: 19.8737915 },
    { lat: 48.4422879, lng: 19.8735455 },
    { lat: 48.4421786, lng: 19.8733379 },
    { lat: 48.4420854, lng: 19.8733452 },
    { lat: 48.4420498, lng: 19.8732469 },
    { lat: 48.4422044, lng: 19.8729937 },
    { lat: 48.4421828, lng: 19.8728596 },
    { lat: 48.4423002, lng: 19.8728886 },
    { lat: 48.442437, lng: 19.8725644 },
    { lat: 48.4430232, lng: 19.8718427 },
    { lat: 48.443118, lng: 19.8717898 },
    { lat: 48.4431709, lng: 19.8718684 },
    { lat: 48.4432171, lng: 19.8718437 },
    { lat: 48.4433281, lng: 19.8715171 },
    { lat: 48.4435297, lng: 19.8713863 },
    { lat: 48.4436622, lng: 19.8713752 },
    { lat: 48.4438209, lng: 19.8712128 },
    { lat: 48.443955, lng: 19.8712416 },
    { lat: 48.4440188, lng: 19.8710991 },
    { lat: 48.444185, lng: 19.8710886 },
    { lat: 48.4444883, lng: 19.8708369 },
    { lat: 48.4446552, lng: 19.8708373 },
    { lat: 48.4447551, lng: 19.8707005 },
    { lat: 48.44532, lng: 19.8705312 },
    { lat: 48.4456527, lng: 19.8703022 },
    { lat: 48.4459502, lng: 19.8699648 },
    { lat: 48.445919, lng: 19.869657 },
    { lat: 48.4464546, lng: 19.8693543 },
    { lat: 48.4465292, lng: 19.869623 },
    { lat: 48.4468716, lng: 19.8696478 },
    { lat: 48.4473101, lng: 19.8701753 },
    { lat: 48.4473967, lng: 19.8701726 },
    { lat: 48.4477469, lng: 19.8706359 },
    { lat: 48.4478786, lng: 19.8706884 },
    { lat: 48.4479879, lng: 19.8708431 },
    { lat: 48.4480861, lng: 19.8708477 },
    { lat: 48.4482077, lng: 19.8704331 },
    { lat: 48.4484566, lng: 19.8706086 },
    { lat: 48.4489403, lng: 19.8706473 },
    { lat: 48.4490894, lng: 19.8705522 },
    { lat: 48.4492701, lng: 19.8705598 },
    { lat: 48.4495977, lng: 19.8697698 },
    { lat: 48.4498421, lng: 19.8693506 },
    { lat: 48.449882, lng: 19.8690508 },
    { lat: 48.4500701, lng: 19.8686619 },
    { lat: 48.4501176, lng: 19.8683234 },
    { lat: 48.4504502, lng: 19.8676784 },
    { lat: 48.4504165, lng: 19.8676792 },
    { lat: 48.4505087, lng: 19.8672068 },
    { lat: 48.4508566, lng: 19.8673084 },
    { lat: 48.4512207, lng: 19.8672597 },
    { lat: 48.4513464, lng: 19.8670884 },
    { lat: 48.4516526, lng: 19.8668761 },
    { lat: 48.4515513, lng: 19.8667753 },
    { lat: 48.4516201, lng: 19.8663573 },
    { lat: 48.4518371, lng: 19.865906 },
    { lat: 48.4522905, lng: 19.8651885 },
    { lat: 48.4524723, lng: 19.8647775 },
    { lat: 48.4524023, lng: 19.8645398 },
    { lat: 48.4528023, lng: 19.8639961 },
    { lat: 48.4529284, lng: 19.8635274 },
    { lat: 48.4530278, lng: 19.8633463 },
    { lat: 48.4533428, lng: 19.8632066 },
    { lat: 48.4533719, lng: 19.8630714 },
    { lat: 48.4535242, lng: 19.8629191 },
    { lat: 48.4539291, lng: 19.8622053 },
    { lat: 48.4540159, lng: 19.8621265 },
    { lat: 48.4540719, lng: 19.8618826 },
    { lat: 48.4543931, lng: 19.8615474 },
    { lat: 48.4547262, lng: 19.8608107 },
    { lat: 48.4547479, lng: 19.8605591 },
    { lat: 48.4548672, lng: 19.8604246 },
    { lat: 48.4551122, lng: 19.860489 },
    { lat: 48.455493, lng: 19.8600188 },
    { lat: 48.4557741, lng: 19.8597983 },
    { lat: 48.4559198, lng: 19.8595438 },
    { lat: 48.4560888, lng: 19.8594449 },
    { lat: 48.4563739, lng: 19.8588964 },
    { lat: 48.456648, lng: 19.8587982 },
    { lat: 48.4566994, lng: 19.8586328 },
    { lat: 48.4569174, lng: 19.8585426 },
    { lat: 48.4570699, lng: 19.8583687 },
    { lat: 48.4572119, lng: 19.8580059 },
    { lat: 48.4574906, lng: 19.8577572 },
    { lat: 48.4579309, lng: 19.8569443 },
    { lat: 48.4581011, lng: 19.856764 },
    { lat: 48.4581262, lng: 19.8566135 },
    { lat: 48.45838, lng: 19.856467 },
    { lat: 48.4586199, lng: 19.8564627 },
    { lat: 48.4587066, lng: 19.8563058 },
    { lat: 48.4587609, lng: 19.8563378 },
    { lat: 48.4587602, lng: 19.8562448 },
    { lat: 48.4590154, lng: 19.8560002 },
    { lat: 48.4589873, lng: 19.8559465 },
    { lat: 48.4592742, lng: 19.8557019 },
    { lat: 48.4593538, lng: 19.8555117 },
    { lat: 48.4593442, lng: 19.8553177 },
    { lat: 48.4592839, lng: 19.8552505 },
    { lat: 48.4595084, lng: 19.8546071 },
    { lat: 48.4596977, lng: 19.8544529 },
    { lat: 48.459987, lng: 19.8544229 },
    { lat: 48.4605758, lng: 19.8540461 },
    { lat: 48.4609662, lng: 19.8539312 },
    { lat: 48.4617988, lng: 19.8526312 },
    { lat: 48.4621547, lng: 19.8522361 },
    { lat: 48.4626917, lng: 19.851788 },
    { lat: 48.4631003, lng: 19.8513611 },
    { lat: 48.4639322, lng: 19.850722 },
    { lat: 48.4644543, lng: 19.8504216 },
    { lat: 48.4646584, lng: 19.8501958 },
    { lat: 48.4648757, lng: 19.8496697 },
    { lat: 48.4652703, lng: 19.8491039 },
    { lat: 48.4655693, lng: 19.8484388 },
    { lat: 48.4658824, lng: 19.8481231 },
    { lat: 48.4662049, lng: 19.8479761 },
    { lat: 48.4664544, lng: 19.8479378 },
    { lat: 48.46646, lng: 19.8478602 },
    { lat: 48.4667699, lng: 19.8477826 },
    { lat: 48.4677866, lng: 19.8477916 },
    { lat: 48.4682644, lng: 19.8476166 },
    { lat: 48.4685656, lng: 19.8473361 },
    { lat: 48.4688269, lng: 19.8472324 },
    { lat: 48.4694857, lng: 19.8468114 },
    { lat: 48.4696763, lng: 19.8465293 },
    { lat: 48.4698623, lng: 19.8463758 },
    { lat: 48.4704936, lng: 19.8460181 },
    { lat: 48.470976, lng: 19.8456325 },
    { lat: 48.4718024, lng: 19.8444278 },
    { lat: 48.4720637, lng: 19.8442949 },
    { lat: 48.472312, lng: 19.8440714 },
    { lat: 48.4728642, lng: 19.842831 },
    { lat: 48.4729292, lng: 19.8424302 },
    { lat: 48.4732065, lng: 19.8419166 },
    { lat: 48.4735793, lng: 19.8417877 },
    { lat: 48.4740446, lng: 19.8417317 },
    { lat: 48.4745941, lng: 19.841441 },
    { lat: 48.4747586, lng: 19.841439 },
    { lat: 48.4751913, lng: 19.8417008 },
    { lat: 48.4754487, lng: 19.8417679 },
    { lat: 48.4757875, lng: 19.8417346 },
    { lat: 48.4761973, lng: 19.8418808 },
    { lat: 48.4762468, lng: 19.8421675 },
    { lat: 48.4762321, lng: 19.8427857 },
    { lat: 48.4762973, lng: 19.8433811 },
    { lat: 48.4765445, lng: 19.8435223 },
    { lat: 48.4771229, lng: 19.844235 },
    { lat: 48.4772997, lng: 19.8445447 },
    { lat: 48.4774765, lng: 19.8446765 },
    { lat: 48.4779448, lng: 19.8453663 },
    { lat: 48.4781157, lng: 19.8461864 },
    { lat: 48.4783653, lng: 19.8470866 },
    { lat: 48.478717, lng: 19.8474842 },
    { lat: 48.4795727, lng: 19.8486414 },
    { lat: 48.4799409, lng: 19.8488677 },
    { lat: 48.4804517, lng: 19.848981 },
    { lat: 48.4810013, lng: 19.8493195 },
    { lat: 48.4812665, lng: 19.8493542 },
    { lat: 48.481439, lng: 19.8494612 },
    { lat: 48.4818677, lng: 19.849291 },
    { lat: 48.4820889, lng: 19.8494334 },
    { lat: 48.4825547, lng: 19.8489219 },
    { lat: 48.4827756, lng: 19.8482983 },
    { lat: 48.4828477, lng: 19.8478051 },
    { lat: 48.48285, lng: 19.8473411 },
    { lat: 48.4829413, lng: 19.8472128 },
    { lat: 48.4830177, lng: 19.8469724 },
    { lat: 48.4830467, lng: 19.846705 },
    { lat: 48.4832691, lng: 19.8461991 },
    { lat: 48.4835055, lng: 19.845878 },
    { lat: 48.4837044, lng: 19.8453468 },
    { lat: 48.4838386, lng: 19.8445453 },
    { lat: 48.4838578, lng: 19.8440292 },
    { lat: 48.4840233, lng: 19.8434028 },
    { lat: 48.4841303, lng: 19.8430884 },
    { lat: 48.4840401, lng: 19.8425656 },
    { lat: 48.4836833, lng: 19.8423954 },
    { lat: 48.4832795, lng: 19.8419677 },
    { lat: 48.4829205, lng: 19.8417685 },
    { lat: 48.4821888, lng: 19.8415214 },
    { lat: 48.4815054, lng: 19.8404401 },
    { lat: 48.4813293, lng: 19.8403051 },
    { lat: 48.4809459, lng: 19.8395981 },
    { lat: 48.48042, lng: 19.83885 },
    { lat: 48.4800682, lng: 19.8374944 },
    { lat: 48.4796311, lng: 19.836819 },
    { lat: 48.4789348, lng: 19.836038 },
    { lat: 48.4781421, lng: 19.8358169 },
    { lat: 48.4777276, lng: 19.8358733 },
    { lat: 48.4775593, lng: 19.8357325 },
    { lat: 48.4772336, lng: 19.8355994 },
    { lat: 48.4769, lng: 19.8357238 },
    { lat: 48.4764336, lng: 19.8357799 },
    { lat: 48.4755908, lng: 19.8357477 },
    { lat: 48.4754268, lng: 19.8356087 },
    { lat: 48.4747194, lng: 19.8353771 },
    { lat: 48.4740085, lng: 19.8347024 },
    { lat: 48.4733099, lng: 19.8343383 },
    { lat: 48.4725706, lng: 19.8341517 },
    { lat: 48.4723178, lng: 19.8339557 },
    { lat: 48.4714844, lng: 19.8335253 },
    { lat: 48.4712424, lng: 19.8334504 },
    { lat: 48.4707138, lng: 19.8334449 },
    { lat: 48.469351, lng: 19.8331706 },
    { lat: 48.4687579, lng: 19.8324851 },
    { lat: 48.4679164, lng: 19.8327216 },
    { lat: 48.4667999, lng: 19.8328494 },
    { lat: 48.4662615, lng: 19.8331626 },
    { lat: 48.4652373, lng: 19.8341702 },
    { lat: 48.4649921, lng: 19.834101 },
    { lat: 48.4647588, lng: 19.8338456 },
    { lat: 48.4644891, lng: 19.8330272 },
    { lat: 48.4643083, lng: 19.8327042 },
    { lat: 48.4638786, lng: 19.8325878 },
    { lat: 48.4634942, lng: 19.8327168 },
    { lat: 48.4628657, lng: 19.833288 },
    { lat: 48.46194, lng: 19.8338393 },
    { lat: 48.4613665, lng: 19.8336589 },
    { lat: 48.4611686, lng: 19.8334626 },
    { lat: 48.4609617, lng: 19.8331347 },
    { lat: 48.460754, lng: 19.8330483 },
    { lat: 48.459946, lng: 19.8334322 },
    { lat: 48.4593997, lng: 19.8339537 },
    { lat: 48.4573097, lng: 19.8323418 },
    { lat: 48.4566698, lng: 19.8316924 },
    { lat: 48.4544685, lng: 19.8298364 },
    { lat: 48.4532301, lng: 19.8287146 },
    { lat: 48.4523632, lng: 19.8273334 },
    { lat: 48.4526338, lng: 19.8248285 },
    { lat: 48.4526386, lng: 19.8229995 },
    { lat: 48.4525594, lng: 19.8198107 },
    { lat: 48.4525099, lng: 19.8198124 },
    { lat: 48.4526508, lng: 19.8191135 },
    { lat: 48.4527654, lng: 19.8163999 },
    { lat: 48.4527954, lng: 19.8162852 },
    { lat: 48.452925, lng: 19.8161564 },
    { lat: 48.4538374, lng: 19.8138283 },
    { lat: 48.4546256, lng: 19.8112895 },
    { lat: 48.4545696, lng: 19.8109599 },
    { lat: 48.4545188, lng: 19.810445 },
    { lat: 48.4541006, lng: 19.8105406 },
    { lat: 48.453366, lng: 19.8110557 },
    { lat: 48.4531118, lng: 19.8111443 },
    { lat: 48.4526511, lng: 19.8119958 },
    { lat: 48.4522241, lng: 19.8125114 },
    { lat: 48.451746, lng: 19.8135108 },
    { lat: 48.4505084, lng: 19.8151497 },
    { lat: 48.4490603, lng: 19.8173636 },
    { lat: 48.4486051, lng: 19.8175965 },
    { lat: 48.4477252, lng: 19.8186517 },
    { lat: 48.4473225, lng: 19.8199835 },
    { lat: 48.4465096, lng: 19.8212613 },
    { lat: 48.4461251, lng: 19.8219773 },
    { lat: 48.4456629, lng: 19.8224671 },
    { lat: 48.445311, lng: 19.8227388 },
    { lat: 48.4451788, lng: 19.8230431 },
    { lat: 48.4433164, lng: 19.8236612 },
    { lat: 48.4425353, lng: 19.8236263 },
    { lat: 48.4412476, lng: 19.8238854 },
    { lat: 48.4410248, lng: 19.8236866 },
    { lat: 48.4389938, lng: 19.8232266 },
    { lat: 48.4383811, lng: 19.8232567 },
    { lat: 48.4378642, lng: 19.8230532 },
    { lat: 48.4370555, lng: 19.8235767 },
    { lat: 48.4353504, lng: 19.8243934 },
    { lat: 48.4351934, lng: 19.8246143 },
    { lat: 48.4351068, lng: 19.8249031 },
    { lat: 48.4347508, lng: 19.8253199 },
    { lat: 48.4342482, lng: 19.8254463 },
    { lat: 48.4341282, lng: 19.8255638 },
    { lat: 48.433866, lng: 19.8256191 },
    { lat: 48.4328716, lng: 19.8263307 },
    { lat: 48.4320682, lng: 19.8263522 },
    { lat: 48.4316065, lng: 19.8262767 },
    { lat: 48.431339, lng: 19.8263196 },
    { lat: 48.4303296, lng: 19.8266577 },
    { lat: 48.4302941, lng: 19.8267985 },
    { lat: 48.430156, lng: 19.826657 },
    { lat: 48.4294253, lng: 19.8262493 },
    { lat: 48.4292246, lng: 19.8260654 },
    { lat: 48.429006, lng: 19.8257573 },
    { lat: 48.4282043, lng: 19.8250689 },
    { lat: 48.4277375, lng: 19.8244884 },
    { lat: 48.4275142, lng: 19.8239701 },
    { lat: 48.4267032, lng: 19.8230425 },
    { lat: 48.4266123, lng: 19.8225587 },
    { lat: 48.426875, lng: 19.8216875 },
    { lat: 48.4258221, lng: 19.8206366 },
    { lat: 48.425315, lng: 19.8199125 },
    { lat: 48.4248047, lng: 19.8193193 },
    { lat: 48.4241851, lng: 19.818777 },
    { lat: 48.4240671, lng: 19.8187333 },
    { lat: 48.4238738, lng: 19.8186647 },
    { lat: 48.4232117, lng: 19.8186508 },
    { lat: 48.4205845, lng: 19.8187042 },
    { lat: 48.41914, lng: 19.8196537 },
    { lat: 48.4184348, lng: 19.820275 },
    { lat: 48.418445, lng: 19.8201747 },
    { lat: 48.4182831, lng: 19.8202073 },
    { lat: 48.4178565, lng: 19.8205281 },
    { lat: 48.4172676, lng: 19.8204332 },
    { lat: 48.4167487, lng: 19.8205205 },
    { lat: 48.4164838, lng: 19.8204923 },
    { lat: 48.4154853, lng: 19.8206913 },
    { lat: 48.4147579, lng: 19.8204945 },
    { lat: 48.4145431, lng: 19.8203514 },
    { lat: 48.4138054, lng: 19.8200985 },
    { lat: 48.4135653, lng: 19.8198873 },
    { lat: 48.413344, lng: 19.8194364 },
    { lat: 48.4131469, lng: 19.8185607 },
    { lat: 48.4129821, lng: 19.8180879 },
    { lat: 48.412821, lng: 19.8178261 },
    { lat: 48.4125685, lng: 19.8176453 },
    { lat: 48.4121944, lng: 19.8176974 },
    { lat: 48.4119338, lng: 19.8178447 },
    { lat: 48.4112989, lng: 19.8186806 },
    { lat: 48.4107038, lng: 19.8190882 },
    { lat: 48.4102865, lng: 19.8195927 },
    { lat: 48.410058, lng: 19.8197018 },
    { lat: 48.4091697, lng: 19.8191477 },
    { lat: 48.4081206, lng: 19.8188085 },
    { lat: 48.4076178, lng: 19.8187222 },
    { lat: 48.4067974, lng: 19.8183814 },
    { lat: 48.4061215, lng: 19.8178962 },
    { lat: 48.4059739, lng: 19.81756 },
    { lat: 48.4059671, lng: 19.8170136 },
    { lat: 48.4061419, lng: 19.8151894 },
    { lat: 48.4061665, lng: 19.8143094 },
    { lat: 48.4063308, lng: 19.8132709 },
    { lat: 48.4050625, lng: 19.8137679 },
    { lat: 48.4045303, lng: 19.8140693 },
    { lat: 48.4041749, lng: 19.8141578 },
    { lat: 48.4034959, lng: 19.81453 },
    { lat: 48.4026385, lng: 19.8147592 },
    { lat: 48.4020289, lng: 19.814731 },
    { lat: 48.4005412, lng: 19.8141028 },
    { lat: 48.4001499, lng: 19.8140411 },
    { lat: 48.3999418, lng: 19.814125 },
    { lat: 48.3989481, lng: 19.8142045 },
    { lat: 48.3977126, lng: 19.8144468 },
    { lat: 48.3969236, lng: 19.8147507 },
    { lat: 48.3970108, lng: 19.8146017 },
    { lat: 48.3962498, lng: 19.8150582 },
    { lat: 48.395568, lng: 19.815204 },
    { lat: 48.3951271, lng: 19.8154556 },
    { lat: 48.394704, lng: 19.8155951 },
    { lat: 48.3940117, lng: 19.8157122 },
    { lat: 48.3931194, lng: 19.8174378 },
    { lat: 48.3927356, lng: 19.8178078 },
    { lat: 48.39176, lng: 19.818131 },
    { lat: 48.391766, lng: 19.818173 },
    { lat: 48.391898, lng: 19.819059 },
    { lat: 48.391621, lng: 19.820589 },
    { lat: 48.391618, lng: 19.820702 },
    { lat: 48.391614, lng: 19.820905 },
    { lat: 48.391532, lng: 19.821491 },
    { lat: 48.391427, lng: 19.822864 },
    { lat: 48.39152, lng: 19.825799 },
    { lat: 48.391645, lng: 19.827062 },
    { lat: 48.391503, lng: 19.82796 },
    { lat: 48.391467, lng: 19.828176 },
    { lat: 48.3913, lng: 19.829229 },
    { lat: 48.391241, lng: 19.829579 },
    { lat: 48.390643, lng: 19.833225 },
    { lat: 48.390679, lng: 19.83324 },
    { lat: 48.39085, lng: 19.833271 },
    { lat: 48.391412, lng: 19.833379 },
    { lat: 48.391616, lng: 19.833418 },
    { lat: 48.391847, lng: 19.833638 },
    { lat: 48.3922, lng: 19.833979 },
    { lat: 48.392533, lng: 19.834295 },
    { lat: 48.393221, lng: 19.834966 },
    { lat: 48.393492, lng: 19.835264 },
    { lat: 48.393938, lng: 19.835751 },
    { lat: 48.39439, lng: 19.836246 },
    { lat: 48.394721, lng: 19.836608 },
    { lat: 48.395131, lng: 19.837041 },
    { lat: 48.395198, lng: 19.837126 },
    { lat: 48.395578, lng: 19.83767 },
    { lat: 48.395899, lng: 19.838118 },
    { lat: 48.396301, lng: 19.838403 },
    { lat: 48.396702, lng: 19.838687 },
    { lat: 48.397342, lng: 19.839138 },
    { lat: 48.397878, lng: 19.839247 },
    { lat: 48.398885, lng: 19.83944 },
    { lat: 48.399941, lng: 19.839588 },
    { lat: 48.40063, lng: 19.839679 },
    { lat: 48.4018785, lng: 19.8381922 },
    { lat: 48.4029072, lng: 19.8372606 },
    { lat: 48.4064136, lng: 19.8355373 },
    { lat: 48.4087314, lng: 19.8353613 },
    { lat: 48.4106892, lng: 19.8382539 },
    { lat: 48.4125223, lng: 19.8377915 },
    { lat: 48.4133912, lng: 19.8378477 },
    { lat: 48.4155316, lng: 19.8389081 },
    { lat: 48.4144744, lng: 19.8401196 },
    { lat: 48.4137516, lng: 19.8410608 },
    { lat: 48.4129691, lng: 19.842807 },
    { lat: 48.4129218, lng: 19.8431205 },
    { lat: 48.4128254, lng: 19.8432605 },
    { lat: 48.4127342, lng: 19.8437049 },
    { lat: 48.412706, lng: 19.844252 },
    { lat: 48.4126241, lng: 19.8444366 },
    { lat: 48.4126512, lng: 19.8448535 },
    { lat: 48.4125905, lng: 19.8449626 },
    { lat: 48.4126651, lng: 19.8451731 },
    { lat: 48.4126114, lng: 19.8453407 },
    { lat: 48.4126629, lng: 19.8455417 },
    { lat: 48.4126675, lng: 19.84622 },
    { lat: 48.4128669, lng: 19.8474087 },
    { lat: 48.4128484, lng: 19.8476342 },
    { lat: 48.4126512, lng: 19.8480731 },
    { lat: 48.4124225, lng: 19.849321 },
    { lat: 48.4124627, lng: 19.8502217 },
    { lat: 48.4126194, lng: 19.8506939 },
    { lat: 48.4132179, lng: 19.85114 },
    { lat: 48.4137153, lng: 19.8517981 },
    { lat: 48.4138262, lng: 19.852227 },
    { lat: 48.413783, lng: 19.8529997 },
    { lat: 48.4138368, lng: 19.8536696 },
    { lat: 48.4138284, lng: 19.8542542 },
    { lat: 48.4140988, lng: 19.8569878 },
    { lat: 48.4148604, lng: 19.8601054 },
    { lat: 48.4152717, lng: 19.8607841 },
    { lat: 48.4153934, lng: 19.8609658 },
    { lat: 48.4148177, lng: 19.8612302 },
    { lat: 48.4170129, lng: 19.8663756 },
    { lat: 48.4169838, lng: 19.8667937 },
    { lat: 48.4170146, lng: 19.866829 },
    { lat: 48.4169271, lng: 19.8678525 },
    { lat: 48.4162564, lng: 19.8702716 },
    { lat: 48.4185746, lng: 19.8705486 },
    { lat: 48.4199704, lng: 19.8707817 },
    { lat: 48.4208731, lng: 19.870426 },
    { lat: 48.42188, lng: 19.8740175 },
    { lat: 48.4222071, lng: 19.8743648 },
    { lat: 48.4223422, lng: 19.8741601 },
    { lat: 48.4224025, lng: 19.8742486 },
    { lat: 48.4226154, lng: 19.8740902 },
    { lat: 48.4227432, lng: 19.8741572 },
    { lat: 48.4228944, lng: 19.8741099 },
    { lat: 48.4229974, lng: 19.8741995 },
    { lat: 48.4230673, lng: 19.8740959 },
    { lat: 48.4230717, lng: 19.8743512 },
    { lat: 48.4231605, lng: 19.8745333 },
    { lat: 48.4232268, lng: 19.8748433 },
    { lat: 48.4231883, lng: 19.874991 },
    { lat: 48.4234704, lng: 19.8751054 },
    { lat: 48.4235945, lng: 19.8753714 },
    { lat: 48.4239832, lng: 19.8757152 },
    { lat: 48.424283, lng: 19.8763713 },
    { lat: 48.424252, lng: 19.8838057 },
    { lat: 48.4242709, lng: 19.8838213 },
    { lat: 48.4267502, lng: 19.8858844 },
    { lat: 48.4269355, lng: 19.8863949 },
    { lat: 48.4270445, lng: 19.8865688 },
    { lat: 48.4277579, lng: 19.8866569 },
    { lat: 48.4277035, lng: 19.8870418 },
    { lat: 48.4277158, lng: 19.8872733 },
    { lat: 48.4288266, lng: 19.8870898 },
    { lat: 48.4298583, lng: 19.8870364 },
    { lat: 48.4312565, lng: 19.8867771 },
    { lat: 48.431951, lng: 19.88676 },
    { lat: 48.4330069, lng: 19.8873662 },
    { lat: 48.4336262, lng: 19.8880935 },
    { lat: 48.4352582, lng: 19.8868047 },
    { lat: 48.4355281, lng: 19.8866859 },
    { lat: 48.4357632, lng: 19.8866797 },
    { lat: 48.4362919, lng: 19.8868189 },
    { lat: 48.4368515, lng: 19.886811 },
    { lat: 48.4375061, lng: 19.8869141 },
    { lat: 48.4384513, lng: 19.8868371 },
    { lat: 48.4397285, lng: 19.8865974 },
    { lat: 48.4402406, lng: 19.8867133 },
    { lat: 48.4405022, lng: 19.8866651 },
    { lat: 48.4406609, lng: 19.8865624 },
    { lat: 48.4409606, lng: 19.8865876 },
    { lat: 48.441251, lng: 19.8861882 },
  ],
  Hradište: [
    { lat: 48.4751708, lng: 19.7098982 },
    { lat: 48.4745271, lng: 19.7106231 },
    { lat: 48.4737712, lng: 19.7108069 },
    { lat: 48.4730376, lng: 19.711511 },
    { lat: 48.4721709, lng: 19.7121391 },
    { lat: 48.4720638, lng: 19.7124166 },
    { lat: 48.4719896, lng: 19.712495 },
    { lat: 48.4717413, lng: 19.7120826 },
    { lat: 48.4716168, lng: 19.7121452 },
    { lat: 48.4715559, lng: 19.7120924 },
    { lat: 48.4714751, lng: 19.7118992 },
    { lat: 48.4714456, lng: 19.7114658 },
    { lat: 48.4713661, lng: 19.7114248 },
    { lat: 48.4710104, lng: 19.7116609 },
    { lat: 48.4709556, lng: 19.7121486 },
    { lat: 48.4709866, lng: 19.7125673 },
    { lat: 48.4705662, lng: 19.712948 },
    { lat: 48.4704563, lng: 19.7132619 },
    { lat: 48.470692, lng: 19.7134455 },
    { lat: 48.4709841, lng: 19.7138802 },
    { lat: 48.4710286, lng: 19.7142972 },
    { lat: 48.4709456, lng: 19.7144593 },
    { lat: 48.4708956, lng: 19.7144231 },
    { lat: 48.4708254, lng: 19.714617 },
    { lat: 48.4708564, lng: 19.7147303 },
    { lat: 48.4709608, lng: 19.7147198 },
    { lat: 48.4712865, lng: 19.7149778 },
    { lat: 48.4713278, lng: 19.7155017 },
    { lat: 48.4711035, lng: 19.7159276 },
    { lat: 48.4709288, lng: 19.7160552 },
    { lat: 48.4708254, lng: 19.7162151 },
    { lat: 48.4708026, lng: 19.7164333 },
    { lat: 48.4706949, lng: 19.7165446 },
    { lat: 48.4706013, lng: 19.7164415 },
    { lat: 48.4704454, lng: 19.7164071 },
    { lat: 48.4703127, lng: 19.7166406 },
    { lat: 48.4703221, lng: 19.7169059 },
    { lat: 48.4702848, lng: 19.7170553 },
    { lat: 48.4701261, lng: 19.7173872 },
    { lat: 48.4699068, lng: 19.7175211 },
    { lat: 48.4696723, lng: 19.7175948 },
    { lat: 48.4696541, lng: 19.7178168 },
    { lat: 48.4695347, lng: 19.7179996 },
    { lat: 48.4687965, lng: 19.7184473 },
    { lat: 48.4686738, lng: 19.7187572 },
    { lat: 48.4682445, lng: 19.7192608 },
    { lat: 48.4682105, lng: 19.7195114 },
    { lat: 48.4679562, lng: 19.7199992 },
    { lat: 48.4679898, lng: 19.7203232 },
    { lat: 48.467965, lng: 19.720881 },
    { lat: 48.4679249, lng: 19.7209191 },
    { lat: 48.4681083, lng: 19.72137 },
    { lat: 48.468163, lng: 19.7214856 },
    { lat: 48.4685455, lng: 19.721853 },
    { lat: 48.4686551, lng: 19.7218061 },
    { lat: 48.4691589, lng: 19.7221929 },
    { lat: 48.4693158, lng: 19.722072 },
    { lat: 48.4696909, lng: 19.7220453 },
    { lat: 48.4703023, lng: 19.722313 },
    { lat: 48.4706606, lng: 19.7226655 },
    { lat: 48.4708183, lng: 19.7226933 },
    { lat: 48.4711019, lng: 19.7228633 },
    { lat: 48.4711821, lng: 19.7229951 },
    { lat: 48.4712884, lng: 19.7230267 },
    { lat: 48.4715612, lng: 19.7233103 },
    { lat: 48.4718892, lng: 19.7234886 },
    { lat: 48.4721833, lng: 19.7238688 },
    { lat: 48.4722423, lng: 19.7242609 },
    { lat: 48.4724593, lng: 19.7244263 },
    { lat: 48.4724706, lng: 19.7246545 },
    { lat: 48.4725928, lng: 19.7249444 },
    { lat: 48.4730079, lng: 19.725566 },
    { lat: 48.4742973, lng: 19.7265674 },
    { lat: 48.4744759, lng: 19.7262725 },
    { lat: 48.474644, lng: 19.7258473 },
    { lat: 48.475026, lng: 19.7246279 },
    { lat: 48.4754061, lng: 19.7239516 },
    { lat: 48.4760906, lng: 19.7231822 },
    { lat: 48.4769373, lng: 19.7229114 },
    { lat: 48.4773119, lng: 19.722622 },
    { lat: 48.4784662, lng: 19.7211871 },
    { lat: 48.4787614, lng: 19.7206531 },
    { lat: 48.4791063, lng: 19.7206564 },
    { lat: 48.4798373, lng: 19.7203766 },
    { lat: 48.4801086, lng: 19.7202428 },
    { lat: 48.4805641, lng: 19.7197666 },
    { lat: 48.4808171, lng: 19.7197063 },
    { lat: 48.4812204, lng: 19.7197805 },
    { lat: 48.4814061, lng: 19.7197245 },
    { lat: 48.4816626, lng: 19.7198639 },
    { lat: 48.4824746, lng: 19.7201089 },
    { lat: 48.4829048, lng: 19.720435 },
    { lat: 48.4830061, lng: 19.7211206 },
    { lat: 48.4829168, lng: 19.7216442 },
    { lat: 48.4829421, lng: 19.7221886 },
    { lat: 48.4828284, lng: 19.7226464 },
    { lat: 48.4830438, lng: 19.7244033 },
    { lat: 48.482636, lng: 19.7251667 },
    { lat: 48.4826724, lng: 19.726318 },
    { lat: 48.4827615, lng: 19.7269263 },
    { lat: 48.4827358, lng: 19.7272886 },
    { lat: 48.4829111, lng: 19.7275295 },
    { lat: 48.4829492, lng: 19.7280851 },
    { lat: 48.4829226, lng: 19.7283949 },
    { lat: 48.4826884, lng: 19.728785 },
    { lat: 48.4827668, lng: 19.7292796 },
    { lat: 48.4827509, lng: 19.729582 },
    { lat: 48.4826006, lng: 19.7303067 },
    { lat: 48.4826497, lng: 19.73068 },
    { lat: 48.4825689, lng: 19.7310674 },
    { lat: 48.482583, lng: 19.7317905 },
    { lat: 48.4831254, lng: 19.731877 },
    { lat: 48.4835165, lng: 19.7317515 },
    { lat: 48.4836748, lng: 19.7319466 },
    { lat: 48.485223, lng: 19.7301692 },
    { lat: 48.4854477, lng: 19.7305604 },
    { lat: 48.4873507, lng: 19.7284011 },
    { lat: 48.4874658, lng: 19.729265 },
    { lat: 48.4899604, lng: 19.7334865 },
    { lat: 48.491028, lng: 19.7355092 },
    { lat: 48.4910207, lng: 19.7358022 },
    { lat: 48.4917188, lng: 19.7386368 },
    { lat: 48.4958352, lng: 19.7373041 },
    { lat: 48.4971173, lng: 19.7367671 },
    { lat: 48.4974919, lng: 19.7365242 },
    { lat: 48.4994829, lng: 19.7357132 },
    { lat: 48.4994821, lng: 19.7356955 },
    { lat: 48.4995247, lng: 19.7339067 },
    { lat: 48.4995738, lng: 19.7336719 },
    { lat: 48.50042, lng: 19.7317123 },
    { lat: 48.5009074, lng: 19.7303563 },
    { lat: 48.5021042, lng: 19.7296461 },
    { lat: 48.5021933, lng: 19.7294463 },
    { lat: 48.5024892, lng: 19.729282 },
    { lat: 48.5026733, lng: 19.7292443 },
    { lat: 48.5030239, lng: 19.7294258 },
    { lat: 48.5033871, lng: 19.7294362 },
    { lat: 48.5036007, lng: 19.7299296 },
    { lat: 48.5043385, lng: 19.7300524 },
    { lat: 48.5047695, lng: 19.7305029 },
    { lat: 48.5066863, lng: 19.7312926 },
    { lat: 48.507987, lng: 19.731432 },
    { lat: 48.508332, lng: 19.7315655 },
    { lat: 48.5084436, lng: 19.7320226 },
    { lat: 48.5087257, lng: 19.7323368 },
    { lat: 48.5091203, lng: 19.7324713 },
    { lat: 48.5092416, lng: 19.7326674 },
    { lat: 48.5095665, lng: 19.7327737 },
    { lat: 48.510016, lng: 19.7328743 },
    { lat: 48.5105084, lng: 19.7328767 },
    { lat: 48.5109363, lng: 19.7327666 },
    { lat: 48.5112192, lng: 19.7325915 },
    { lat: 48.5114011, lng: 19.7323497 },
    { lat: 48.5115666, lng: 19.7322695 },
    { lat: 48.5118634, lng: 19.7322596 },
    { lat: 48.5121264, lng: 19.7316554 },
    { lat: 48.512356, lng: 19.7314234 },
    { lat: 48.5125567, lng: 19.7308915 },
    { lat: 48.5126137, lng: 19.730883 },
    { lat: 48.5126759, lng: 19.7307108 },
    { lat: 48.5127833, lng: 19.7306925 },
    { lat: 48.512919, lng: 19.7304739 },
    { lat: 48.5140635, lng: 19.7296827 },
    { lat: 48.5143463, lng: 19.7289666 },
    { lat: 48.5144578, lng: 19.7287874 },
    { lat: 48.5146187, lng: 19.728225 },
    { lat: 48.5147309, lng: 19.7280886 },
    { lat: 48.5148845, lng: 19.7275856 },
    { lat: 48.5156563, lng: 19.7265738 },
    { lat: 48.5158419, lng: 19.7261315 },
    { lat: 48.5161585, lng: 19.7258559 },
    { lat: 48.5164696, lng: 19.7253957 },
    { lat: 48.5167926, lng: 19.7253632 },
    { lat: 48.5176441, lng: 19.7250257 },
    { lat: 48.5177971, lng: 19.7245895 },
    { lat: 48.5182088, lng: 19.7241668 },
    { lat: 48.5190798, lng: 19.7235073 },
    { lat: 48.5199222, lng: 19.7219758 },
    { lat: 48.5213349, lng: 19.7201973 },
    { lat: 48.522024, lng: 19.7197789 },
    { lat: 48.5225587, lng: 19.7196444 },
    { lat: 48.5230828, lng: 19.71957 },
    { lat: 48.5238751, lng: 19.7195738 },
    { lat: 48.524217, lng: 19.7189252 },
    { lat: 48.5249696, lng: 19.718216 },
    { lat: 48.5268087, lng: 19.7177113 },
    { lat: 48.5274572, lng: 19.717726 },
    { lat: 48.5280017, lng: 19.7179868 },
    { lat: 48.5285826, lng: 19.7178778 },
    { lat: 48.5291878, lng: 19.7176231 },
    { lat: 48.5299323, lng: 19.7174387 },
    { lat: 48.5309419, lng: 19.717621 },
    { lat: 48.5310039, lng: 19.717517 },
    { lat: 48.5319661, lng: 19.7172634 },
    { lat: 48.5323503, lng: 19.7170373 },
    { lat: 48.5329164, lng: 19.716339 },
    { lat: 48.5334633, lng: 19.715537 },
    { lat: 48.5339892, lng: 19.7152749 },
    { lat: 48.534738, lng: 19.7151657 },
    { lat: 48.5348542, lng: 19.7153197 },
    { lat: 48.5352444, lng: 19.7154293 },
    { lat: 48.5357482, lng: 19.7163943 },
    { lat: 48.5362639, lng: 19.7160081 },
    { lat: 48.5365501, lng: 19.7159288 },
    { lat: 48.5367706, lng: 19.71562 },
    { lat: 48.5371715, lng: 19.715414 },
    { lat: 48.5379302, lng: 19.7155964 },
    { lat: 48.5383457, lng: 19.7155431 },
    { lat: 48.538706, lng: 19.7153831 },
    { lat: 48.5398004, lng: 19.7144013 },
    { lat: 48.5403495, lng: 19.71408 },
    { lat: 48.5403688, lng: 19.7141375 },
    { lat: 48.5407409, lng: 19.7140427 },
    { lat: 48.5405995, lng: 19.7148125 },
    { lat: 48.5409731, lng: 19.7147781 },
    { lat: 48.5418342, lng: 19.7143258 },
    { lat: 48.5417602, lng: 19.7160083 },
    { lat: 48.5430419, lng: 19.7166052 },
    { lat: 48.5439158, lng: 19.7171284 },
    { lat: 48.5441134, lng: 19.7167554 },
    { lat: 48.5441639, lng: 19.7154311 },
    { lat: 48.5444253, lng: 19.7140664 },
    { lat: 48.5442744, lng: 19.7093026 },
    { lat: 48.5427474, lng: 19.7061825 },
    { lat: 48.5427595, lng: 19.7061255 },
    { lat: 48.5418897, lng: 19.7046075 },
    { lat: 48.5402395, lng: 19.7019365 },
    { lat: 48.5385137, lng: 19.6996721 },
    { lat: 48.5382601, lng: 19.6997454 },
    { lat: 48.5380062, lng: 19.6996118 },
    { lat: 48.5377763, lng: 19.6996603 },
    { lat: 48.537522, lng: 19.6993642 },
    { lat: 48.5374887, lng: 19.6994277 },
    { lat: 48.5362888, lng: 19.6990715 },
    { lat: 48.5348512, lng: 19.6996836 },
    { lat: 48.5326516, lng: 19.6997573 },
    { lat: 48.5323631, lng: 19.6976819 },
    { lat: 48.5319943, lng: 19.6955019 },
    { lat: 48.5301388, lng: 19.6949526 },
    { lat: 48.5288895, lng: 19.6941491 },
    { lat: 48.5287154, lng: 19.6941074 },
    { lat: 48.5272927, lng: 19.6937667 },
    { lat: 48.5257328, lng: 19.6928976 },
    { lat: 48.5248364, lng: 19.6924815 },
    { lat: 48.5236243, lng: 19.6923837 },
    { lat: 48.5223665, lng: 19.6924769 },
    { lat: 48.5218429, lng: 19.692612 },
    { lat: 48.5203341, lng: 19.6932658 },
    { lat: 48.5193401, lng: 19.6938174 },
    { lat: 48.5183148, lng: 19.6946497 },
    { lat: 48.5177515, lng: 19.6946526 },
    { lat: 48.5173744, lng: 19.6948887 },
    { lat: 48.5170454, lng: 19.6949653 },
    { lat: 48.5164619, lng: 19.6954473 },
    { lat: 48.5155297, lng: 19.6968259 },
    { lat: 48.5153862, lng: 19.6968969 },
    { lat: 48.5149839, lng: 19.6974522 },
    { lat: 48.514737, lng: 19.6980265 },
    { lat: 48.5141506, lng: 19.6982919 },
    { lat: 48.5132196, lng: 19.6990225 },
    { lat: 48.5128488, lng: 19.6996232 },
    { lat: 48.5114377, lng: 19.7006001 },
    { lat: 48.5112701, lng: 19.7006074 },
    { lat: 48.5110857, lng: 19.7007195 },
    { lat: 48.5109554, lng: 19.7007042 },
    { lat: 48.5107514, lng: 19.7010273 },
    { lat: 48.5089957, lng: 19.7021135 },
    { lat: 48.5088857, lng: 19.7023464 },
    { lat: 48.5079313, lng: 19.7031289 },
    { lat: 48.5077038, lng: 19.7031049 },
    { lat: 48.5071629, lng: 19.7035355 },
    { lat: 48.5066064, lng: 19.7034616 },
    { lat: 48.5059552, lng: 19.7036396 },
    { lat: 48.50572, lng: 19.7036186 },
    { lat: 48.5052023, lng: 19.7038641 },
    { lat: 48.5034685, lng: 19.7051089 },
    { lat: 48.5030914, lng: 19.7051398 },
    { lat: 48.5016754, lng: 19.7061347 },
    { lat: 48.5011464, lng: 19.7066769 },
    { lat: 48.5004782, lng: 19.7060941 },
    { lat: 48.499561, lng: 19.7051578 },
    { lat: 48.4994506, lng: 19.7048805 },
    { lat: 48.4988333, lng: 19.7043786 },
    { lat: 48.4979086, lng: 19.7044136 },
    { lat: 48.4972626, lng: 19.7042553 },
    { lat: 48.4967087, lng: 19.7037038 },
    { lat: 48.4959021, lng: 19.702469 },
    { lat: 48.4950623, lng: 19.7018567 },
    { lat: 48.4947959, lng: 19.7018229 },
    { lat: 48.4945566, lng: 19.7016826 },
    { lat: 48.4930848, lng: 19.7014781 },
    { lat: 48.4925266, lng: 19.7014946 },
    { lat: 48.4919721, lng: 19.7011844 },
    { lat: 48.4901609, lng: 19.7015292 },
    { lat: 48.4892615, lng: 19.7018505 },
    { lat: 48.4887076, lng: 19.7023692 },
    { lat: 48.4875084, lng: 19.704127 },
    { lat: 48.4865128, lng: 19.7059827 },
    { lat: 48.4858012, lng: 19.706324 },
    { lat: 48.4853844, lng: 19.7064268 },
    { lat: 48.4847629, lng: 19.7064103 },
    { lat: 48.4850526, lng: 19.7053024 },
    { lat: 48.4851932, lng: 19.7033238 },
    { lat: 48.4853703, lng: 19.7030026 },
    { lat: 48.4853406, lng: 19.7023084 },
    { lat: 48.4857104, lng: 19.7011047 },
    { lat: 48.4848743, lng: 19.7004461 },
    { lat: 48.4848709, lng: 19.7004436 },
    { lat: 48.4845971, lng: 19.7016063 },
    { lat: 48.4845029, lng: 19.7018168 },
    { lat: 48.4842888, lng: 19.7020725 },
    { lat: 48.4788546, lng: 19.7066714 },
    { lat: 48.4779533, lng: 19.7072595 },
    { lat: 48.4773188, lng: 19.7079605 },
    { lat: 48.4767006, lng: 19.708956 },
    { lat: 48.4764222, lng: 19.7091308 },
    { lat: 48.4759311, lng: 19.7092626 },
    { lat: 48.4755258, lng: 19.7095471 },
    { lat: 48.4751708, lng: 19.7098982 },
  ],
  Zlatno: [
    { lat: 48.5217375, lng: 19.8173654 },
    { lat: 48.5225868, lng: 19.8151623 },
    { lat: 48.523526, lng: 19.8117678 },
    { lat: 48.5221939, lng: 19.8110355 },
    { lat: 48.5220395, lng: 19.8123515 },
    { lat: 48.5215321, lng: 19.8140777 },
    { lat: 48.5209787, lng: 19.8153378 },
    { lat: 48.5204085, lng: 19.816107 },
    { lat: 48.519768, lng: 19.8174123 },
    { lat: 48.5194608, lng: 19.8171885 },
    { lat: 48.5182988, lng: 19.817617 },
    { lat: 48.5176771, lng: 19.8173923 },
    { lat: 48.5166417, lng: 19.8171565 },
    { lat: 48.5160245, lng: 19.8164122 },
    { lat: 48.5153688, lng: 19.8172655 },
    { lat: 48.5150496, lng: 19.8173361 },
    { lat: 48.5150117, lng: 19.8174759 },
    { lat: 48.5149682, lng: 19.8174564 },
    { lat: 48.514957, lng: 19.817617 },
    { lat: 48.514332, lng: 19.8173394 },
    { lat: 48.5139651, lng: 19.8168298 },
    { lat: 48.5138748, lng: 19.816773 },
    { lat: 48.5139765, lng: 19.8165764 },
    { lat: 48.5136071, lng: 19.8163719 },
    { lat: 48.5135852, lng: 19.8165404 },
    { lat: 48.5132248, lng: 19.8162842 },
    { lat: 48.5124695, lng: 19.8156534 },
    { lat: 48.5125568, lng: 19.8154853 },
    { lat: 48.5122729, lng: 19.8152759 },
    { lat: 48.5122547, lng: 19.8154806 },
    { lat: 48.5120736, lng: 19.8153827 },
    { lat: 48.5114612, lng: 19.8149546 },
    { lat: 48.5115173, lng: 19.8148453 },
    { lat: 48.5111431, lng: 19.8146889 },
    { lat: 48.5108138, lng: 19.8156904 },
    { lat: 48.5129579, lng: 19.8172188 },
    { lat: 48.5140144, lng: 19.8178572 },
    { lat: 48.5148697, lng: 19.818562 },
    { lat: 48.5156826, lng: 19.819066 },
    { lat: 48.5156654, lng: 19.8197069 },
    { lat: 48.515954, lng: 19.8202019 },
    { lat: 48.5173229, lng: 19.820665 },
    { lat: 48.5179477, lng: 19.8227775 },
    { lat: 48.5185341, lng: 19.8222235 },
    { lat: 48.518769, lng: 19.8211499 },
    { lat: 48.5192241, lng: 19.8214039 },
    { lat: 48.5198799, lng: 19.822313 },
    { lat: 48.520095, lng: 19.8218194 },
    { lat: 48.5215325, lng: 19.8224926 },
    { lat: 48.5217043, lng: 19.8210001 },
    { lat: 48.522851, lng: 19.8184084 },
    { lat: 48.5230624, lng: 19.8184536 },
    { lat: 48.5231911, lng: 19.8176303 },
    { lat: 48.5221692, lng: 19.8175254 },
    { lat: 48.5217764, lng: 19.81738 },
    { lat: 48.5217375, lng: 19.8173654 },
  ],
  Poltár: [
    { lat: 48.387014, lng: 19.784959 },
    { lat: 48.387024, lng: 19.785026 },
    { lat: 48.387111, lng: 19.785165 },
    { lat: 48.387123, lng: 19.785341 },
    { lat: 48.387148, lng: 19.785946 },
    { lat: 48.387148, lng: 19.78604 },
    { lat: 48.387146, lng: 19.786238 },
    { lat: 48.387139, lng: 19.78659 },
    { lat: 48.387165, lng: 19.786973 },
    { lat: 48.387199, lng: 19.787259 },
    { lat: 48.387336, lng: 19.787587 },
    { lat: 48.387463, lng: 19.787967 },
    { lat: 48.387575, lng: 19.78831 },
    { lat: 48.387661, lng: 19.788585 },
    { lat: 48.387709, lng: 19.788763 },
    { lat: 48.387791, lng: 19.788991 },
    { lat: 48.387918, lng: 19.789316 },
    { lat: 48.38809, lng: 19.789614 },
    { lat: 48.388257, lng: 19.789852 },
    { lat: 48.388364, lng: 19.79005 },
    { lat: 48.388451, lng: 19.790218 },
    { lat: 48.388516, lng: 19.790434 },
    { lat: 48.388539, lng: 19.790581 },
    { lat: 48.388577, lng: 19.790781 },
    { lat: 48.388623, lng: 19.791003 },
    { lat: 48.388662, lng: 19.791169 },
    { lat: 48.38873, lng: 19.791468 },
    { lat: 48.388801, lng: 19.791739 },
    { lat: 48.388858, lng: 19.791998 },
    { lat: 48.388928, lng: 19.792293 },
    { lat: 48.388989, lng: 19.792632 },
    { lat: 48.389086, lng: 19.793069 },
    { lat: 48.389156, lng: 19.793418 },
    { lat: 48.389231, lng: 19.793764 },
    { lat: 48.389308, lng: 19.794121 },
    { lat: 48.389393, lng: 19.79448 },
    { lat: 48.389517, lng: 19.795059 },
    { lat: 48.389564, lng: 19.795279 },
    { lat: 48.389632, lng: 19.795587 },
    { lat: 48.389694, lng: 19.795762 },
    { lat: 48.389765, lng: 19.795916 },
    { lat: 48.389824, lng: 19.796001 },
    { lat: 48.389912, lng: 19.796104 },
    { lat: 48.390337, lng: 19.796552 },
    { lat: 48.390625, lng: 19.796832 },
    { lat: 48.390913, lng: 19.797097 },
    { lat: 48.390929, lng: 19.797121 },
    { lat: 48.391129, lng: 19.797322 },
    { lat: 48.391263, lng: 19.797455 },
    { lat: 48.391423, lng: 19.797625 },
    { lat: 48.391595, lng: 19.797827 },
    { lat: 48.391722, lng: 19.798019 },
    { lat: 48.391842, lng: 19.798263 },
    { lat: 48.391966, lng: 19.798525 },
    { lat: 48.392101, lng: 19.798849 },
    { lat: 48.392252, lng: 19.799221 },
    { lat: 48.39239, lng: 19.79958 },
    { lat: 48.392465, lng: 19.79978 },
    { lat: 48.392495, lng: 19.799987 },
    { lat: 48.392489, lng: 19.800272 },
    { lat: 48.392298, lng: 19.800469 },
    { lat: 48.392111, lng: 19.800913 },
    { lat: 48.391923, lng: 19.801406 },
    { lat: 48.391737, lng: 19.801836 },
    { lat: 48.391505, lng: 19.8022 },
    { lat: 48.391196, lng: 19.80267 },
    { lat: 48.390736, lng: 19.803282 },
    { lat: 48.390396, lng: 19.803716 },
    { lat: 48.390059, lng: 19.804012 },
    { lat: 48.389795, lng: 19.804253 },
    { lat: 48.389586, lng: 19.804425 },
    { lat: 48.389357, lng: 19.804669 },
    { lat: 48.38905, lng: 19.804881 },
    { lat: 48.388815, lng: 19.805089 },
    { lat: 48.38824, lng: 19.805535 },
    { lat: 48.387999, lng: 19.80573 },
    { lat: 48.387734, lng: 19.805916 },
    { lat: 48.387343, lng: 19.806177 },
    { lat: 48.387075, lng: 19.806419 },
    { lat: 48.386859, lng: 19.806704 },
    { lat: 48.386742, lng: 19.807067 },
    { lat: 48.386755, lng: 19.807366 },
    { lat: 48.386804, lng: 19.807745 },
    { lat: 48.386878, lng: 19.80818 },
    { lat: 48.386954, lng: 19.808574 },
    { lat: 48.38697, lng: 19.808864 },
    { lat: 48.386985, lng: 19.809131 },
    { lat: 48.387158, lng: 19.812204 },
    { lat: 48.38716, lng: 19.81226 },
    { lat: 48.387166, lng: 19.812354 },
    { lat: 48.387375, lng: 19.816091 },
    { lat: 48.387903, lng: 19.818059 },
    { lat: 48.387771, lng: 19.818351 },
    { lat: 48.387757, lng: 19.818618 },
    { lat: 48.387794, lng: 19.818706 },
    { lat: 48.387927, lng: 19.818927 },
    { lat: 48.388195, lng: 19.818977 },
    { lat: 48.388334, lng: 19.818949 },
    { lat: 48.388544, lng: 19.818965 },
    { lat: 48.388719, lng: 19.818946 },
    { lat: 48.389534, lng: 19.818638 },
    { lat: 48.389655, lng: 19.818509 },
    { lat: 48.390062, lng: 19.818401 },
    { lat: 48.390214, lng: 19.818406 },
    { lat: 48.390584, lng: 19.818345 },
    { lat: 48.391106, lng: 19.818325 },
    { lat: 48.391257, lng: 19.81825 },
    { lat: 48.391404, lng: 19.81825 },
    { lat: 48.39176, lng: 19.818131 },
    { lat: 48.3927356, lng: 19.8178078 },
    { lat: 48.3931194, lng: 19.8174378 },
    { lat: 48.3940117, lng: 19.8157122 },
    { lat: 48.394704, lng: 19.8155951 },
    { lat: 48.3951271, lng: 19.8154556 },
    { lat: 48.395568, lng: 19.815204 },
    { lat: 48.3962498, lng: 19.8150582 },
    { lat: 48.3970108, lng: 19.8146017 },
    { lat: 48.3969236, lng: 19.8147507 },
    { lat: 48.3977126, lng: 19.8144468 },
    { lat: 48.3989481, lng: 19.8142045 },
    { lat: 48.3999418, lng: 19.814125 },
    { lat: 48.4001499, lng: 19.8140411 },
    { lat: 48.4005412, lng: 19.8141028 },
    { lat: 48.4020289, lng: 19.814731 },
    { lat: 48.4026385, lng: 19.8147592 },
    { lat: 48.4034959, lng: 19.81453 },
    { lat: 48.4041749, lng: 19.8141578 },
    { lat: 48.4045303, lng: 19.8140693 },
    { lat: 48.4050625, lng: 19.8137679 },
    { lat: 48.4063308, lng: 19.8132709 },
    { lat: 48.4061665, lng: 19.8143094 },
    { lat: 48.4061419, lng: 19.8151894 },
    { lat: 48.4059671, lng: 19.8170136 },
    { lat: 48.4059739, lng: 19.81756 },
    { lat: 48.4061215, lng: 19.8178962 },
    { lat: 48.4067974, lng: 19.8183814 },
    { lat: 48.4076178, lng: 19.8187222 },
    { lat: 48.4081206, lng: 19.8188085 },
    { lat: 48.4091697, lng: 19.8191477 },
    { lat: 48.410058, lng: 19.8197018 },
    { lat: 48.4102865, lng: 19.8195927 },
    { lat: 48.4107038, lng: 19.8190882 },
    { lat: 48.4112989, lng: 19.8186806 },
    { lat: 48.4119338, lng: 19.8178447 },
    { lat: 48.4121944, lng: 19.8176974 },
    { lat: 48.4125685, lng: 19.8176453 },
    { lat: 48.412821, lng: 19.8178261 },
    { lat: 48.4129821, lng: 19.8180879 },
    { lat: 48.4131469, lng: 19.8185607 },
    { lat: 48.413344, lng: 19.8194364 },
    { lat: 48.4135653, lng: 19.8198873 },
    { lat: 48.4138054, lng: 19.8200985 },
    { lat: 48.4145431, lng: 19.8203514 },
    { lat: 48.4147579, lng: 19.8204945 },
    { lat: 48.4154853, lng: 19.8206913 },
    { lat: 48.4164838, lng: 19.8204923 },
    { lat: 48.4167487, lng: 19.8205205 },
    { lat: 48.4172676, lng: 19.8204332 },
    { lat: 48.4178565, lng: 19.8205281 },
    { lat: 48.4182831, lng: 19.8202073 },
    { lat: 48.418445, lng: 19.8201747 },
    { lat: 48.4184348, lng: 19.820275 },
    { lat: 48.41914, lng: 19.8196537 },
    { lat: 48.4205845, lng: 19.8187042 },
    { lat: 48.4232117, lng: 19.8186508 },
    { lat: 48.4238738, lng: 19.8186647 },
    { lat: 48.4240671, lng: 19.8187333 },
    { lat: 48.4241851, lng: 19.818777 },
    { lat: 48.4248047, lng: 19.8193193 },
    { lat: 48.425315, lng: 19.8199125 },
    { lat: 48.4258221, lng: 19.8206366 },
    { lat: 48.426875, lng: 19.8216875 },
    { lat: 48.4266123, lng: 19.8225587 },
    { lat: 48.4267032, lng: 19.8230425 },
    { lat: 48.4275142, lng: 19.8239701 },
    { lat: 48.4277375, lng: 19.8244884 },
    { lat: 48.4282043, lng: 19.8250689 },
    { lat: 48.429006, lng: 19.8257573 },
    { lat: 48.4292246, lng: 19.8260654 },
    { lat: 48.4294253, lng: 19.8262493 },
    { lat: 48.430156, lng: 19.826657 },
    { lat: 48.4302941, lng: 19.8267985 },
    { lat: 48.4303296, lng: 19.8266577 },
    { lat: 48.431339, lng: 19.8263196 },
    { lat: 48.4316065, lng: 19.8262767 },
    { lat: 48.4320682, lng: 19.8263522 },
    { lat: 48.4328716, lng: 19.8263307 },
    { lat: 48.433866, lng: 19.8256191 },
    { lat: 48.4341282, lng: 19.8255638 },
    { lat: 48.4342482, lng: 19.8254463 },
    { lat: 48.4347508, lng: 19.8253199 },
    { lat: 48.4351068, lng: 19.8249031 },
    { lat: 48.4351934, lng: 19.8246143 },
    { lat: 48.4353504, lng: 19.8243934 },
    { lat: 48.4370555, lng: 19.8235767 },
    { lat: 48.4378642, lng: 19.8230532 },
    { lat: 48.4383811, lng: 19.8232567 },
    { lat: 48.4389938, lng: 19.8232266 },
    { lat: 48.4410248, lng: 19.8236866 },
    { lat: 48.4412476, lng: 19.8238854 },
    { lat: 48.4425353, lng: 19.8236263 },
    { lat: 48.4433164, lng: 19.8236612 },
    { lat: 48.4451788, lng: 19.8230431 },
    { lat: 48.445311, lng: 19.8227388 },
    { lat: 48.4456629, lng: 19.8224671 },
    { lat: 48.4461251, lng: 19.8219773 },
    { lat: 48.4465096, lng: 19.8212613 },
    { lat: 48.4473225, lng: 19.8199835 },
    { lat: 48.4477252, lng: 19.8186517 },
    { lat: 48.4486051, lng: 19.8175965 },
    { lat: 48.4490603, lng: 19.8173636 },
    { lat: 48.4505084, lng: 19.8151497 },
    { lat: 48.451746, lng: 19.8135108 },
    { lat: 48.4522241, lng: 19.8125114 },
    { lat: 48.4526511, lng: 19.8119958 },
    { lat: 48.4531118, lng: 19.8111443 },
    { lat: 48.453366, lng: 19.8110557 },
    { lat: 48.4541006, lng: 19.8105406 },
    { lat: 48.4545188, lng: 19.810445 },
    { lat: 48.4554368, lng: 19.810463 },
    { lat: 48.4566622, lng: 19.8107903 },
    { lat: 48.4575541, lng: 19.8108598 },
    { lat: 48.4579163, lng: 19.8106384 },
    { lat: 48.4588206, lng: 19.8097278 },
    { lat: 48.4592815, lng: 19.8097004 },
    { lat: 48.4598763, lng: 19.8099835 },
    { lat: 48.4605185, lng: 19.8097252 },
    { lat: 48.4609711, lng: 19.8094391 },
    { lat: 48.4611914, lng: 19.8093968 },
    { lat: 48.461786, lng: 19.8093952 },
    { lat: 48.4620759, lng: 19.8094553 },
    { lat: 48.4626171, lng: 19.8100291 },
    { lat: 48.4635619, lng: 19.8104076 },
    { lat: 48.4632382, lng: 19.8086454 },
    { lat: 48.463221, lng: 19.8081477 },
    { lat: 48.4631018, lng: 19.8077391 },
    { lat: 48.4629545, lng: 19.8067445 },
    { lat: 48.4628926, lng: 19.8055547 },
    { lat: 48.4629522, lng: 19.8048331 },
    { lat: 48.4633411, lng: 19.8029569 },
    { lat: 48.4638983, lng: 19.7962248 },
    { lat: 48.4640709, lng: 19.7949219 },
    { lat: 48.4640182, lng: 19.7946662 },
    { lat: 48.463697, lng: 19.7939745 },
    { lat: 48.4636291, lng: 19.7935027 },
    { lat: 48.4636553, lng: 19.7932016 },
    { lat: 48.4633761, lng: 19.7931263 },
    { lat: 48.4628479, lng: 19.792748 },
    { lat: 48.462379, lng: 19.7925875 },
    { lat: 48.46172, lng: 19.7920795 },
    { lat: 48.4613547, lng: 19.7919425 },
    { lat: 48.4607385, lng: 19.7918666 },
    { lat: 48.4600194, lng: 19.7916798 },
    { lat: 48.4597247, lng: 19.7914832 },
    { lat: 48.4578539, lng: 19.7909821 },
    { lat: 48.457474, lng: 19.7908523 },
    { lat: 48.4570092, lng: 19.7905537 },
    { lat: 48.4561657, lng: 19.7897687 },
    { lat: 48.4557106, lng: 19.7892307 },
    { lat: 48.4539666, lng: 19.787537 },
    { lat: 48.4537719, lng: 19.7872582 },
    { lat: 48.4538741, lng: 19.7870688 },
    { lat: 48.4538532, lng: 19.787029 },
    { lat: 48.4532418, lng: 19.786295 },
    { lat: 48.4527025, lng: 19.785841 },
    { lat: 48.4525611, lng: 19.7856271 },
    { lat: 48.4528505, lng: 19.7843119 },
    { lat: 48.4529878, lng: 19.7832292 },
    { lat: 48.4527229, lng: 19.7830202 },
    { lat: 48.4515775, lng: 19.782599 },
    { lat: 48.4513185, lng: 19.7824469 },
    { lat: 48.4511621, lng: 19.7822676 },
    { lat: 48.4511325, lng: 19.7823688 },
    { lat: 48.4509865, lng: 19.7828664 },
    { lat: 48.4504781, lng: 19.7826262 },
    { lat: 48.4495617, lng: 19.7820269 },
    { lat: 48.4485326, lng: 19.7815552 },
    { lat: 48.4474054, lng: 19.7814148 },
    { lat: 48.4460738, lng: 19.7811122 },
    { lat: 48.446019, lng: 19.7819924 },
    { lat: 48.4445817, lng: 19.7817952 },
    { lat: 48.4441312, lng: 19.7815957 },
    { lat: 48.4432971, lng: 19.7820731 },
    { lat: 48.4430674, lng: 19.7820473 },
    { lat: 48.4429343, lng: 19.7821507 },
    { lat: 48.4418788, lng: 19.7820773 },
    { lat: 48.4414033, lng: 19.7814588 },
    { lat: 48.4403752, lng: 19.7807799 },
    { lat: 48.4401062, lng: 19.7806593 },
    { lat: 48.439277, lng: 19.7798142 },
    { lat: 48.4390306, lng: 19.7797957 },
    { lat: 48.4387732, lng: 19.7798851 },
    { lat: 48.4383685, lng: 19.7803408 },
    { lat: 48.4381786, lng: 19.7804558 },
    { lat: 48.4379441, lng: 19.7801704 },
    { lat: 48.4378153, lng: 19.779905 },
    { lat: 48.438333, lng: 19.7796145 },
    { lat: 48.4387602, lng: 19.7791814 },
    { lat: 48.4389862, lng: 19.7787853 },
    { lat: 48.4398688, lng: 19.7760534 },
    { lat: 48.4406146, lng: 19.7747711 },
    { lat: 48.4407688, lng: 19.7743853 },
    { lat: 48.441212, lng: 19.7702748 },
    { lat: 48.4414206, lng: 19.7687694 },
    { lat: 48.4416887, lng: 19.7681795 },
    { lat: 48.4423505, lng: 19.7673979 },
    { lat: 48.4425396, lng: 19.7670324 },
    { lat: 48.4426009, lng: 19.7661729 },
    { lat: 48.4424874, lng: 19.7635385 },
    { lat: 48.4426804, lng: 19.7627921 },
    { lat: 48.4428672, lng: 19.7618153 },
    { lat: 48.4430528, lng: 19.7612202 },
    { lat: 48.4431803, lng: 19.7609158 },
    { lat: 48.4434128, lng: 19.7606201 },
    { lat: 48.4434979, lng: 19.760404 },
    { lat: 48.4434836, lng: 19.7591602 },
    { lat: 48.4435081, lng: 19.7588739 },
    { lat: 48.4436623, lng: 19.7584639 },
    { lat: 48.4441371, lng: 19.7572494 },
    { lat: 48.4441564, lng: 19.7571999 },
    { lat: 48.4440427, lng: 19.7570998 },
    { lat: 48.443858, lng: 19.7565913 },
    { lat: 48.4437155, lng: 19.7559419 },
    { lat: 48.443739, lng: 19.7556567 },
    { lat: 48.4436484, lng: 19.7551874 },
    { lat: 48.4443104, lng: 19.752754 },
    { lat: 48.4435855, lng: 19.7526415 },
    { lat: 48.4432829, lng: 19.7533158 },
    { lat: 48.4421743, lng: 19.7522239 },
    { lat: 48.4414966, lng: 19.751981 },
    { lat: 48.4409131, lng: 19.7518551 },
    { lat: 48.4403303, lng: 19.7514637 },
    { lat: 48.4397694, lng: 19.7508363 },
    { lat: 48.4395805, lng: 19.7504344 },
    { lat: 48.4392, lng: 19.7498805 },
    { lat: 48.4389528, lng: 19.7493854 },
    { lat: 48.43886, lng: 19.7484082 },
    { lat: 48.4384317, lng: 19.747719 },
    { lat: 48.4378982, lng: 19.7466649 },
    { lat: 48.4378323, lng: 19.7462162 },
    { lat: 48.4378539, lng: 19.7458926 },
    { lat: 48.4377819, lng: 19.7451578 },
    { lat: 48.4376881, lng: 19.7445629 },
    { lat: 48.4378536, lng: 19.7440255 },
    { lat: 48.437952, lng: 19.7434749 },
    { lat: 48.4379233, lng: 19.7431556 },
    { lat: 48.437418, lng: 19.7426867 },
    { lat: 48.43675, lng: 19.741545 },
    { lat: 48.4361551, lng: 19.7396111 },
    { lat: 48.4359628, lng: 19.7394171 },
    { lat: 48.4351893, lng: 19.7390227 },
    { lat: 48.4345779, lng: 19.7388011 },
    { lat: 48.4334253, lng: 19.7380142 },
    { lat: 48.4328683, lng: 19.7378798 },
    { lat: 48.4321486, lng: 19.7386714 },
    { lat: 48.4317846, lng: 19.7401227 },
    { lat: 48.4311732, lng: 19.7421926 },
    { lat: 48.4310951, lng: 19.7433496 },
    { lat: 48.4308298, lng: 19.7450485 },
    { lat: 48.4308198, lng: 19.7455583 },
    { lat: 48.4304862, lng: 19.7466776 },
    { lat: 48.4300882, lng: 19.7474315 },
    { lat: 48.4281849, lng: 19.7501522 },
    { lat: 48.4278395, lng: 19.7507507 },
    { lat: 48.427097, lng: 19.7517909 },
    { lat: 48.4262451, lng: 19.7532161 },
    { lat: 48.4249567, lng: 19.7549619 },
    { lat: 48.4240364, lng: 19.7558342 },
    { lat: 48.4238745, lng: 19.7556116 },
    { lat: 48.4236507, lng: 19.7556977 },
    { lat: 48.4230407, lng: 19.7556139 },
    { lat: 48.4225798, lng: 19.7556868 },
    { lat: 48.4222745, lng: 19.75589 },
    { lat: 48.4221917, lng: 19.7563406 },
    { lat: 48.4221919, lng: 19.7566754 },
    { lat: 48.4221916, lng: 19.7568734 },
    { lat: 48.4219301, lng: 19.758938 },
    { lat: 48.4219137, lng: 19.7594061 },
    { lat: 48.4217968, lng: 19.7598962 },
    { lat: 48.4216447, lng: 19.7600777 },
    { lat: 48.4208733, lng: 19.7602908 },
    { lat: 48.4205251, lng: 19.7602641 },
    { lat: 48.4202573, lng: 19.7600929 },
    { lat: 48.4201648, lng: 19.7599375 },
    { lat: 48.4200666, lng: 19.7591904 },
    { lat: 48.4199579, lng: 19.7590008 },
    { lat: 48.4195611, lng: 19.7587666 },
    { lat: 48.419246, lng: 19.7588763 },
    { lat: 48.4190083, lng: 19.7580101 },
    { lat: 48.4186578, lng: 19.7559928 },
    { lat: 48.4186169, lng: 19.7558316 },
    { lat: 48.4185314, lng: 19.7557841 },
    { lat: 48.4183116, lng: 19.7559596 },
    { lat: 48.4171972, lng: 19.7564446 },
    { lat: 48.4160345, lng: 19.7563186 },
    { lat: 48.4151469, lng: 19.7563227 },
    { lat: 48.4147469, lng: 19.7564218 },
    { lat: 48.4139614, lng: 19.7563157 },
    { lat: 48.4130948, lng: 19.7563929 },
    { lat: 48.4124721, lng: 19.7566054 },
    { lat: 48.4120066, lng: 19.7565741 },
    { lat: 48.4113759, lng: 19.7566789 },
    { lat: 48.4103476, lng: 19.7567154 },
    { lat: 48.4100078, lng: 19.7570553 },
    { lat: 48.4087419, lng: 19.7588791 },
    { lat: 48.4081137, lng: 19.7595282 },
    { lat: 48.4080248, lng: 19.7596041 },
    { lat: 48.4075519, lng: 19.7596629 },
    { lat: 48.4070783, lng: 19.7598094 },
    { lat: 48.4065806, lng: 19.7600707 },
    { lat: 48.4063944, lng: 19.7602162 },
    { lat: 48.4063541, lng: 19.7603245 },
    { lat: 48.4058757, lng: 19.7599537 },
    { lat: 48.4051583, lng: 19.7595511 },
    { lat: 48.4044546, lng: 19.758928 },
    { lat: 48.4044051, lng: 19.7588765 },
    { lat: 48.4045211, lng: 19.7604805 },
    { lat: 48.4045264, lng: 19.7613664 },
    { lat: 48.4035635, lng: 19.7632416 },
    { lat: 48.4022768, lng: 19.7652812 },
    { lat: 48.402205, lng: 19.7654412 },
    { lat: 48.4020099, lng: 19.7666647 },
    { lat: 48.4020993, lng: 19.7682028 },
    { lat: 48.4025221, lng: 19.7704623 },
    { lat: 48.4034193, lng: 19.7722573 },
    { lat: 48.4038418, lng: 19.7735631 },
    { lat: 48.4040102, lng: 19.7744169 },
    { lat: 48.4037279, lng: 19.7759413 },
    { lat: 48.4035464, lng: 19.7764521 },
    { lat: 48.4022938, lng: 19.7784277 },
    { lat: 48.4017084, lng: 19.7796655 },
    { lat: 48.4015948, lng: 19.7802862 },
    { lat: 48.4015506, lng: 19.7808927 },
    { lat: 48.4019281, lng: 19.7825078 },
    { lat: 48.4025832, lng: 19.7847249 },
    { lat: 48.4026235, lng: 19.7846968 },
    { lat: 48.4026485, lng: 19.7847776 },
    { lat: 48.4011953, lng: 19.7861126 },
    { lat: 48.4001474, lng: 19.7868564 },
    { lat: 48.4001138, lng: 19.7867853 },
    { lat: 48.3990856, lng: 19.7866897 },
    { lat: 48.3984832, lng: 19.7869068 },
    { lat: 48.3981545, lng: 19.7868086 },
    { lat: 48.3970755, lng: 19.7868924 },
    { lat: 48.3968405, lng: 19.7870568 },
    { lat: 48.3956717, lng: 19.787431 },
    { lat: 48.3953564, lng: 19.7873584 },
    { lat: 48.3951458, lng: 19.7872077 },
    { lat: 48.3947934, lng: 19.7867998 },
    { lat: 48.394083, lng: 19.7862516 },
    { lat: 48.3936776, lng: 19.7858163 },
    { lat: 48.3933055, lng: 19.7856327 },
    { lat: 48.3927405, lng: 19.785209 },
    { lat: 48.3919023, lng: 19.7849826 },
    { lat: 48.3911874, lng: 19.7847121 },
    { lat: 48.3899929, lng: 19.7844253 },
    { lat: 48.3882967, lng: 19.7845619 },
    { lat: 48.387014, lng: 19.784959 },
  ],
  Breznička: [
    { lat: 48.4022503, lng: 19.7263553 },
    { lat: 48.4021804, lng: 19.7264517 },
    { lat: 48.4023212, lng: 19.7268786 },
    { lat: 48.4033686, lng: 19.7284668 },
    { lat: 48.4038051, lng: 19.72929 },
    { lat: 48.404058, lng: 19.7296558 },
    { lat: 48.4045599, lng: 19.7302239 },
    { lat: 48.4048345, lng: 19.7304485 },
    { lat: 48.4046715, lng: 19.7309337 },
    { lat: 48.4046836, lng: 19.7317161 },
    { lat: 48.4047738, lng: 19.7322766 },
    { lat: 48.4053112, lng: 19.7331631 },
    { lat: 48.4056087, lng: 19.7338975 },
    { lat: 48.4057128, lng: 19.7347963 },
    { lat: 48.4058694, lng: 19.7356335 },
    { lat: 48.4056852, lng: 19.7365111 },
    { lat: 48.40542, lng: 19.7371639 },
    { lat: 48.4052312, lng: 19.7378576 },
    { lat: 48.4045006, lng: 19.7398788 },
    { lat: 48.4040959, lng: 19.741299 },
    { lat: 48.4036748, lng: 19.7421432 },
    { lat: 48.402782, lng: 19.7427161 },
    { lat: 48.402435, lng: 19.7431022 },
    { lat: 48.4021808, lng: 19.743262 },
    { lat: 48.4019248, lng: 19.7438414 },
    { lat: 48.4011664, lng: 19.7447313 },
    { lat: 48.4008374, lng: 19.7453893 },
    { lat: 48.4006636, lng: 19.7458899 },
    { lat: 48.3996619, lng: 19.7472678 },
    { lat: 48.3987956, lng: 19.7485666 },
    { lat: 48.3982223, lng: 19.7489762 },
    { lat: 48.3979105, lng: 19.7493896 },
    { lat: 48.3992982, lng: 19.7528172 },
    { lat: 48.4002709, lng: 19.7549422 },
    { lat: 48.4004429, lng: 19.7551896 },
    { lat: 48.401218, lng: 19.7557725 },
    { lat: 48.4027351, lng: 19.756475 },
    { lat: 48.4035, lng: 19.757109 },
    { lat: 48.4043931, lng: 19.7582724 },
    { lat: 48.4044051, lng: 19.7588765 },
    { lat: 48.4044546, lng: 19.758928 },
    { lat: 48.4051583, lng: 19.7595511 },
    { lat: 48.4058757, lng: 19.7599537 },
    { lat: 48.4063541, lng: 19.7603245 },
    { lat: 48.4063944, lng: 19.7602162 },
    { lat: 48.4065806, lng: 19.7600707 },
    { lat: 48.4070783, lng: 19.7598094 },
    { lat: 48.4075519, lng: 19.7596629 },
    { lat: 48.4080248, lng: 19.7596041 },
    { lat: 48.4081137, lng: 19.7595282 },
    { lat: 48.4087419, lng: 19.7588791 },
    { lat: 48.4100078, lng: 19.7570553 },
    { lat: 48.4103476, lng: 19.7567154 },
    { lat: 48.4113759, lng: 19.7566789 },
    { lat: 48.4120066, lng: 19.7565741 },
    { lat: 48.4124721, lng: 19.7566054 },
    { lat: 48.4130948, lng: 19.7563929 },
    { lat: 48.4139614, lng: 19.7563157 },
    { lat: 48.4147469, lng: 19.7564218 },
    { lat: 48.4151469, lng: 19.7563227 },
    { lat: 48.4160345, lng: 19.7563186 },
    { lat: 48.4171972, lng: 19.7564446 },
    { lat: 48.4183116, lng: 19.7559596 },
    { lat: 48.4185314, lng: 19.7557841 },
    { lat: 48.4186169, lng: 19.7558316 },
    { lat: 48.4186578, lng: 19.7559928 },
    { lat: 48.4190083, lng: 19.7580101 },
    { lat: 48.419246, lng: 19.7588763 },
    { lat: 48.4195611, lng: 19.7587666 },
    { lat: 48.4199579, lng: 19.7590008 },
    { lat: 48.4200666, lng: 19.7591904 },
    { lat: 48.4201648, lng: 19.7599375 },
    { lat: 48.4202573, lng: 19.7600929 },
    { lat: 48.4205251, lng: 19.7602641 },
    { lat: 48.4208733, lng: 19.7602908 },
    { lat: 48.4216447, lng: 19.7600777 },
    { lat: 48.4217968, lng: 19.7598962 },
    { lat: 48.4219137, lng: 19.7594061 },
    { lat: 48.4219301, lng: 19.758938 },
    { lat: 48.4221916, lng: 19.7568734 },
    { lat: 48.4221919, lng: 19.7566754 },
    { lat: 48.4221917, lng: 19.7563406 },
    { lat: 48.4222745, lng: 19.75589 },
    { lat: 48.4225798, lng: 19.7556868 },
    { lat: 48.4230407, lng: 19.7556139 },
    { lat: 48.4236507, lng: 19.7556977 },
    { lat: 48.4238745, lng: 19.7556116 },
    { lat: 48.4240364, lng: 19.7558342 },
    { lat: 48.4249567, lng: 19.7549619 },
    { lat: 48.4262451, lng: 19.7532161 },
    { lat: 48.427097, lng: 19.7517909 },
    { lat: 48.4278395, lng: 19.7507507 },
    { lat: 48.4281849, lng: 19.7501522 },
    { lat: 48.4300882, lng: 19.7474315 },
    { lat: 48.4304862, lng: 19.7466776 },
    { lat: 48.4308198, lng: 19.7455583 },
    { lat: 48.4308298, lng: 19.7450485 },
    { lat: 48.4310951, lng: 19.7433496 },
    { lat: 48.4311732, lng: 19.7421926 },
    { lat: 48.4317846, lng: 19.7401227 },
    { lat: 48.4321486, lng: 19.7386714 },
    { lat: 48.4328683, lng: 19.7378798 },
    { lat: 48.431867, lng: 19.7364644 },
    { lat: 48.4318033, lng: 19.736172 },
    { lat: 48.4318546, lng: 19.7357057 },
    { lat: 48.4319897, lng: 19.7353973 },
    { lat: 48.4321149, lng: 19.7347394 },
    { lat: 48.4322754, lng: 19.7343912 },
    { lat: 48.4324596, lng: 19.7337962 },
    { lat: 48.4324379, lng: 19.7336913 },
    { lat: 48.4324982, lng: 19.733522 },
    { lat: 48.4324677, lng: 19.7333942 },
    { lat: 48.4325085, lng: 19.7330679 },
    { lat: 48.4324782, lng: 19.7326952 },
    { lat: 48.4320797, lng: 19.7314831 },
    { lat: 48.4320812, lng: 19.7307294 },
    { lat: 48.4319088, lng: 19.7302233 },
    { lat: 48.431864, lng: 19.7298738 },
    { lat: 48.4318275, lng: 19.7294832 },
    { lat: 48.4317872, lng: 19.7295362 },
    { lat: 48.4317482, lng: 19.7293975 },
    { lat: 48.4315985, lng: 19.7294232 },
    { lat: 48.4312483, lng: 19.7293004 },
    { lat: 48.4307526, lng: 19.7288463 },
    { lat: 48.4306779, lng: 19.7288914 },
    { lat: 48.4305643, lng: 19.7287691 },
    { lat: 48.4303055, lng: 19.7287741 },
    { lat: 48.4301414, lng: 19.7286831 },
    { lat: 48.4300479, lng: 19.7285342 },
    { lat: 48.4297134, lng: 19.7283808 },
    { lat: 48.4295399, lng: 19.7283563 },
    { lat: 48.4293154, lng: 19.7284789 },
    { lat: 48.4287474, lng: 19.7285497 },
    { lat: 48.4286892, lng: 19.7286361 },
    { lat: 48.4285762, lng: 19.7285268 },
    { lat: 48.4282116, lng: 19.7285112 },
    { lat: 48.4279879, lng: 19.7283477 },
    { lat: 48.4272439, lng: 19.7281094 },
    { lat: 48.4272164, lng: 19.727999 },
    { lat: 48.4270933, lng: 19.7278915 },
    { lat: 48.4269737, lng: 19.7279375 },
    { lat: 48.4267445, lng: 19.7277987 },
    { lat: 48.4266456, lng: 19.7278803 },
    { lat: 48.426559, lng: 19.7278322 },
    { lat: 48.4265104, lng: 19.727681 },
    { lat: 48.4263058, lng: 19.7276132 },
    { lat: 48.4261822, lng: 19.7276749 },
    { lat: 48.425832, lng: 19.7280825 },
    { lat: 48.425595, lng: 19.7281464 },
    { lat: 48.4254746, lng: 19.7284341 },
    { lat: 48.4250875, lng: 19.7282129 },
    { lat: 48.4235886, lng: 19.7277747 },
    { lat: 48.4236087, lng: 19.727595 },
    { lat: 48.423696, lng: 19.727505 },
    { lat: 48.4238289, lng: 19.7271483 },
    { lat: 48.4240654, lng: 19.726944 },
    { lat: 48.4240619, lng: 19.726811 },
    { lat: 48.4239557, lng: 19.7266318 },
    { lat: 48.423738, lng: 19.7266271 },
    { lat: 48.4235952, lng: 19.7264303 },
    { lat: 48.4235721, lng: 19.7263152 },
    { lat: 48.423444, lng: 19.7261943 },
    { lat: 48.4234647, lng: 19.7257219 },
    { lat: 48.4235695, lng: 19.7256255 },
    { lat: 48.4235586, lng: 19.725428 },
    { lat: 48.423818, lng: 19.7252439 },
    { lat: 48.4241595, lng: 19.7250016 },
    { lat: 48.4242178, lng: 19.7250763 },
    { lat: 48.4243045, lng: 19.7248448 },
    { lat: 48.4242708, lng: 19.7247229 },
    { lat: 48.4243005, lng: 19.7246043 },
    { lat: 48.424389, lng: 19.7245404 },
    { lat: 48.42448, lng: 19.7243571 },
    { lat: 48.4244734, lng: 19.7242085 },
    { lat: 48.4245695, lng: 19.7240126 },
    { lat: 48.4246546, lng: 19.7239505 },
    { lat: 48.4248535, lng: 19.723989 },
    { lat: 48.4249791, lng: 19.7238253 },
    { lat: 48.4251101, lng: 19.7238033 },
    { lat: 48.4252475, lng: 19.7240045 },
    { lat: 48.4253536, lng: 19.7239587 },
    { lat: 48.4255325, lng: 19.7237016 },
    { lat: 48.4257087, lng: 19.7238372 },
    { lat: 48.4257832, lng: 19.7237907 },
    { lat: 48.4258041, lng: 19.723652 },
    { lat: 48.4259217, lng: 19.7236363 },
    { lat: 48.4259852, lng: 19.7234923 },
    { lat: 48.4259541, lng: 19.7233227 },
    { lat: 48.4260096, lng: 19.7232554 },
    { lat: 48.4260862, lng: 19.7233591 },
    { lat: 48.4263029, lng: 19.7232678 },
    { lat: 48.4262761, lng: 19.7232439 },
    { lat: 48.4263159, lng: 19.7226723 },
    { lat: 48.4263687, lng: 19.7224889 },
    { lat: 48.4264288, lng: 19.7224715 },
    { lat: 48.4264437, lng: 19.7223501 },
    { lat: 48.4264056, lng: 19.7222532 },
    { lat: 48.4265103, lng: 19.7220007 },
    { lat: 48.4263699, lng: 19.7216562 },
    { lat: 48.426363, lng: 19.7214479 },
    { lat: 48.4262648, lng: 19.721169 },
    { lat: 48.4262821, lng: 19.7210729 },
    { lat: 48.4263772, lng: 19.7210566 },
    { lat: 48.4263928, lng: 19.7207024 },
    { lat: 48.4265363, lng: 19.720429 },
    { lat: 48.4266188, lng: 19.7201075 },
    { lat: 48.4265787, lng: 19.7200391 },
    { lat: 48.4266162, lng: 19.7198693 },
    { lat: 48.4265078, lng: 19.7195141 },
    { lat: 48.4265134, lng: 19.7193096 },
    { lat: 48.4267007, lng: 19.7189892 },
    { lat: 48.4267214, lng: 19.7188161 },
    { lat: 48.4266409, lng: 19.7186302 },
    { lat: 48.4266272, lng: 19.7184457 },
    { lat: 48.4266823, lng: 19.7184476 },
    { lat: 48.4267501, lng: 19.7179718 },
    { lat: 48.4267987, lng: 19.7179222 },
    { lat: 48.4267589, lng: 19.7178613 },
    { lat: 48.4267716, lng: 19.7175421 },
    { lat: 48.4268176, lng: 19.7174339 },
    { lat: 48.4267251, lng: 19.7170001 },
    { lat: 48.4267857, lng: 19.7168989 },
    { lat: 48.426758, lng: 19.7167279 },
    { lat: 48.4264929, lng: 19.7160912 },
    { lat: 48.4263825, lng: 19.7160136 },
    { lat: 48.4262515, lng: 19.7157032 },
    { lat: 48.4262835, lng: 19.7154343 },
    { lat: 48.4262239, lng: 19.7153367 },
    { lat: 48.4261809, lng: 19.7149015 },
    { lat: 48.4262655, lng: 19.7146013 },
    { lat: 48.4263268, lng: 19.7145359 },
    { lat: 48.4263621, lng: 19.7142908 },
    { lat: 48.42632, lng: 19.7141556 },
    { lat: 48.4264096, lng: 19.714115 },
    { lat: 48.4264605, lng: 19.7139432 },
    { lat: 48.4264045, lng: 19.7136253 },
    { lat: 48.4264686, lng: 19.7135688 },
    { lat: 48.4264713, lng: 19.7134028 },
    { lat: 48.4265172, lng: 19.7133464 },
    { lat: 48.4264303, lng: 19.7130387 },
    { lat: 48.4264962, lng: 19.7129278 },
    { lat: 48.4264904, lng: 19.7127705 },
    { lat: 48.4265295, lng: 19.7126882 },
    { lat: 48.4265777, lng: 19.7127596 },
    { lat: 48.4266638, lng: 19.7125672 },
    { lat: 48.4266621, lng: 19.7124538 },
    { lat: 48.4265189, lng: 19.7122242 },
    { lat: 48.4264977, lng: 19.7120979 },
    { lat: 48.4265948, lng: 19.7118298 },
    { lat: 48.4266086, lng: 19.7116035 },
    { lat: 48.4266875, lng: 19.7114782 },
    { lat: 48.4267604, lng: 19.7115237 },
    { lat: 48.4268225, lng: 19.7113954 },
    { lat: 48.4268307, lng: 19.7109504 },
    { lat: 48.4269058, lng: 19.7108393 },
    { lat: 48.4269336, lng: 19.7106584 },
    { lat: 48.4270772, lng: 19.7104414 },
    { lat: 48.4272294, lng: 19.7103647 },
    { lat: 48.4274809, lng: 19.7104202 },
    { lat: 48.4275949, lng: 19.7103758 },
    { lat: 48.4278553, lng: 19.7095975 },
    { lat: 48.4279233, lng: 19.7095754 },
    { lat: 48.4280145, lng: 19.7093491 },
    { lat: 48.4278578, lng: 19.7090452 },
    { lat: 48.4279789, lng: 19.708752 },
    { lat: 48.4278804, lng: 19.7084387 },
    { lat: 48.427622, lng: 19.7081288 },
    { lat: 48.427659, lng: 19.7073141 },
    { lat: 48.4277729, lng: 19.7072423 },
    { lat: 48.42775, lng: 19.7069299 },
    { lat: 48.4276329, lng: 19.7068332 },
    { lat: 48.4277831, lng: 19.7065381 },
    { lat: 48.4276768, lng: 19.70648 },
    { lat: 48.4276397, lng: 19.7064032 },
    { lat: 48.4273501, lng: 19.7063274 },
    { lat: 48.4262168, lng: 19.7061066 },
    { lat: 48.4255721, lng: 19.7061247 },
    { lat: 48.4254181, lng: 19.7060324 },
    { lat: 48.4250896, lng: 19.7059938 },
    { lat: 48.4242195, lng: 19.7059892 },
    { lat: 48.4229445, lng: 19.707184 },
    { lat: 48.4218542, lng: 19.708635 },
    { lat: 48.4207973, lng: 19.7094654 },
    { lat: 48.4184289, lng: 19.7103273 },
    { lat: 48.4170548, lng: 19.7111721 },
    { lat: 48.4160554, lng: 19.7122101 },
    { lat: 48.4151445, lng: 19.7127564 },
    { lat: 48.414466, lng: 19.7132832 },
    { lat: 48.4138725, lng: 19.7138466 },
    { lat: 48.4128333, lng: 19.7155297 },
    { lat: 48.4124307, lng: 19.7161266 },
    { lat: 48.4118794, lng: 19.7168048 },
    { lat: 48.4111582, lng: 19.7179685 },
    { lat: 48.4108173, lng: 19.7183843 },
    { lat: 48.4104739, lng: 19.7193523 },
    { lat: 48.4102503, lng: 19.7195694 },
    { lat: 48.4100852, lng: 19.7198321 },
    { lat: 48.4099666, lng: 19.7198997 },
    { lat: 48.4098939, lng: 19.7198637 },
    { lat: 48.4096973, lng: 19.7200498 },
    { lat: 48.4096367, lng: 19.7200314 },
    { lat: 48.4088502, lng: 19.7210715 },
    { lat: 48.4075394, lng: 19.7229865 },
    { lat: 48.4069761, lng: 19.7240015 },
    { lat: 48.4068733, lng: 19.7247282 },
    { lat: 48.4066955, lng: 19.724912 },
    { lat: 48.4060741, lng: 19.7258846 },
    { lat: 48.4059166, lng: 19.725661 },
    { lat: 48.4056453, lng: 19.725646 },
    { lat: 48.4055138, lng: 19.7254972 },
    { lat: 48.4053859, lng: 19.7250526 },
    { lat: 48.4053168, lng: 19.7246148 },
    { lat: 48.4051941, lng: 19.7244406 },
    { lat: 48.4046444, lng: 19.724572 },
    { lat: 48.4044278, lng: 19.7245182 },
    { lat: 48.404389, lng: 19.7246792 },
    { lat: 48.4042932, lng: 19.7246806 },
    { lat: 48.4040166, lng: 19.7250491 },
    { lat: 48.4038702, lng: 19.7250755 },
    { lat: 48.4038146, lng: 19.7252162 },
    { lat: 48.4036394, lng: 19.7252338 },
    { lat: 48.4033688, lng: 19.7250255 },
    { lat: 48.4032945, lng: 19.7252556 },
    { lat: 48.4031725, lng: 19.725426 },
    { lat: 48.4027142, lng: 19.725935 },
    { lat: 48.4022503, lng: 19.7263553 },
  ],
  Ďubákovo: [
    { lat: 48.5615181, lng: 19.7302257 },
    { lat: 48.5611236, lng: 19.7296382 },
    { lat: 48.5604116, lng: 19.7302476 },
    { lat: 48.5604056, lng: 19.7303151 },
    { lat: 48.5592961, lng: 19.7301225 },
    { lat: 48.5582323, lng: 19.7292022 },
    { lat: 48.5576818, lng: 19.7281176 },
    { lat: 48.5574546, lng: 19.7270617 },
    { lat: 48.5570172, lng: 19.7263176 },
    { lat: 48.5556764, lng: 19.7250245 },
    { lat: 48.5551697, lng: 19.7239647 },
    { lat: 48.554677, lng: 19.7234214 },
    { lat: 48.5545364, lng: 19.7233492 },
    { lat: 48.5533995, lng: 19.7234013 },
    { lat: 48.5513844, lng: 19.7232869 },
    { lat: 48.5505156, lng: 19.7233075 },
    { lat: 48.5468616, lng: 19.7232061 },
    { lat: 48.5468371, lng: 19.7232651 },
    { lat: 48.54513, lng: 19.7240261 },
    { lat: 48.5455015, lng: 19.7252898 },
    { lat: 48.5459061, lng: 19.7263856 },
    { lat: 48.5470875, lng: 19.7307686 },
    { lat: 48.546864, lng: 19.7325132 },
    { lat: 48.5472608, lng: 19.7351411 },
    { lat: 48.5481974, lng: 19.7398107 },
    { lat: 48.5482004, lng: 19.7398267 },
    { lat: 48.5483342, lng: 19.740277 },
    { lat: 48.5491648, lng: 19.7411009 },
    { lat: 48.5509812, lng: 19.7421222 },
    { lat: 48.5516891, lng: 19.7426917 },
    { lat: 48.5526504, lng: 19.7436664 },
    { lat: 48.5534227, lng: 19.7449017 },
    { lat: 48.5533034, lng: 19.7459482 },
    { lat: 48.5528652, lng: 19.7479705 },
    { lat: 48.5528705, lng: 19.7488811 },
    { lat: 48.5527925, lng: 19.7498964 },
    { lat: 48.5521576, lng: 19.7509002 },
    { lat: 48.5516725, lng: 19.7522198 },
    { lat: 48.5509312, lng: 19.7535492 },
    { lat: 48.550672, lng: 19.7544397 },
    { lat: 48.5495484, lng: 19.7567106 },
    { lat: 48.5493374, lng: 19.757392 },
    { lat: 48.5489373, lng: 19.758364 },
    { lat: 48.5487951, lng: 19.7591229 },
    { lat: 48.5488948, lng: 19.7595215 },
    { lat: 48.5489334, lng: 19.7595304 },
    { lat: 48.5488292, lng: 19.7608747 },
    { lat: 48.5489248, lng: 19.7611244 },
    { lat: 48.5489662, lng: 19.7622703 },
    { lat: 48.5486303, lng: 19.762903 },
    { lat: 48.5484141, lng: 19.7635981 },
    { lat: 48.5484813, lng: 19.7638687 },
    { lat: 48.5484813, lng: 19.7644864 },
    { lat: 48.5483402, lng: 19.765686 },
    { lat: 48.548265, lng: 19.7661505 },
    { lat: 48.5477984, lng: 19.7678918 },
    { lat: 48.5479174, lng: 19.7679851 },
    { lat: 48.5476443, lng: 19.7690763 },
    { lat: 48.5476851, lng: 19.7693101 },
    { lat: 48.5467542, lng: 19.7724339 },
    { lat: 48.5467712, lng: 19.7736426 },
    { lat: 48.5468441, lng: 19.7747647 },
    { lat: 48.5471634, lng: 19.7770324 },
    { lat: 48.5463994, lng: 19.7778001 },
    { lat: 48.5462536, lng: 19.7789443 },
    { lat: 48.5478259, lng: 19.7806813 },
    { lat: 48.5481082, lng: 19.78105 },
    { lat: 48.5483256, lng: 19.7815398 },
    { lat: 48.5487527, lng: 19.7821317 },
    { lat: 48.5489216, lng: 19.7824623 },
    { lat: 48.5492148, lng: 19.7828278 },
    { lat: 48.5493764, lng: 19.7828413 },
    { lat: 48.5497948, lng: 19.7833117 },
    { lat: 48.5502515, lng: 19.7840202 },
    { lat: 48.5508602, lng: 19.7835347 },
    { lat: 48.5514573, lng: 19.7833797 },
    { lat: 48.5517514, lng: 19.7818647 },
    { lat: 48.5518919, lng: 19.7808052 },
    { lat: 48.552537, lng: 19.7787609 },
    { lat: 48.5526231, lng: 19.7779799 },
    { lat: 48.5530993, lng: 19.77535 },
    { lat: 48.5531111, lng: 19.7747204 },
    { lat: 48.5530725, lng: 19.7742762 },
    { lat: 48.5528736, lng: 19.7728536 },
    { lat: 48.5529195, lng: 19.772131 },
    { lat: 48.5530174, lng: 19.7718933 },
    { lat: 48.5533778, lng: 19.7696603 },
    { lat: 48.5540325, lng: 19.7689193 },
    { lat: 48.5546738, lng: 19.7650326 },
    { lat: 48.5548124, lng: 19.7638516 },
    { lat: 48.5551529, lng: 19.7616812 },
    { lat: 48.5553983, lng: 19.7603689 },
    { lat: 48.5561589, lng: 19.7594486 },
    { lat: 48.5575363, lng: 19.7579321 },
    { lat: 48.5577839, lng: 19.7566711 },
    { lat: 48.5585728, lng: 19.7558798 },
    { lat: 48.5589948, lng: 19.7560766 },
    { lat: 48.5592791, lng: 19.7565216 },
    { lat: 48.5599557, lng: 19.7578122 },
    { lat: 48.5619025, lng: 19.7586779 },
    { lat: 48.5632208, lng: 19.7604193 },
    { lat: 48.5637449, lng: 19.7612188 },
    { lat: 48.564447, lng: 19.7599147 },
    { lat: 48.5649143, lng: 19.7604205 },
    { lat: 48.5651873, lng: 19.7609417 },
    { lat: 48.5659475, lng: 19.7629591 },
    { lat: 48.5676173, lng: 19.7639599 },
    { lat: 48.5684577, lng: 19.7637262 },
    { lat: 48.5681059, lng: 19.7616714 },
    { lat: 48.5681053, lng: 19.7613516 },
    { lat: 48.5684267, lng: 19.7597717 },
    { lat: 48.5688562, lng: 19.759928 },
    { lat: 48.5691987, lng: 19.7592865 },
    { lat: 48.56952, lng: 19.7589588 },
    { lat: 48.5699104, lng: 19.7592794 },
    { lat: 48.5703916, lng: 19.7596938 },
    { lat: 48.5704589, lng: 19.7595316 },
    { lat: 48.5708938, lng: 19.759248 },
    { lat: 48.571337, lng: 19.7584388 },
    { lat: 48.5715734, lng: 19.7581786 },
    { lat: 48.571643, lng: 19.7579689 },
    { lat: 48.5716775, lng: 19.757625 },
    { lat: 48.5717885, lng: 19.7573584 },
    { lat: 48.5722536, lng: 19.7570743 },
    { lat: 48.5722828, lng: 19.7566337 },
    { lat: 48.5721946, lng: 19.7561694 },
    { lat: 48.5719891, lng: 19.755545 },
    { lat: 48.5712723, lng: 19.7549493 },
    { lat: 48.5707706, lng: 19.7544309 },
    { lat: 48.5705401, lng: 19.7543384 },
    { lat: 48.57004, lng: 19.7535012 },
    { lat: 48.5696222, lng: 19.752966 },
    { lat: 48.5693076, lng: 19.7522959 },
    { lat: 48.5692282, lng: 19.7518012 },
    { lat: 48.5688906, lng: 19.7514003 },
    { lat: 48.5688197, lng: 19.75066 },
    { lat: 48.5689802, lng: 19.7501594 },
    { lat: 48.5696038, lng: 19.7500713 },
    { lat: 48.569593, lng: 19.7499817 },
    { lat: 48.569319, lng: 19.7491809 },
    { lat: 48.5690045, lng: 19.7493088 },
    { lat: 48.5688149, lng: 19.7490664 },
    { lat: 48.5686478, lng: 19.7483457 },
    { lat: 48.5684911, lng: 19.7479085 },
    { lat: 48.5681752, lng: 19.7478765 },
    { lat: 48.5681158, lng: 19.74768 },
    { lat: 48.5679408, lng: 19.7474385 },
    { lat: 48.5682323, lng: 19.7469995 },
    { lat: 48.5683747, lng: 19.7469077 },
    { lat: 48.568452, lng: 19.7470771 },
    { lat: 48.5687645, lng: 19.7465755 },
    { lat: 48.5694413, lng: 19.7462103 },
    { lat: 48.5695265, lng: 19.745766 },
    { lat: 48.5698523, lng: 19.7456917 },
    { lat: 48.5715485, lng: 19.745654 },
    { lat: 48.5714587, lng: 19.7453791 },
    { lat: 48.571439, lng: 19.745089 },
    { lat: 48.5714991, lng: 19.7446646 },
    { lat: 48.5714376, lng: 19.744468 },
    { lat: 48.5714246, lng: 19.7442231 },
    { lat: 48.5714733, lng: 19.7436462 },
    { lat: 48.5715972, lng: 19.7432061 },
    { lat: 48.5716578, lng: 19.7421584 },
    { lat: 48.5717416, lng: 19.7419657 },
    { lat: 48.5717773, lng: 19.7416515 },
    { lat: 48.5717252, lng: 19.7414927 },
    { lat: 48.5717168, lng: 19.7412221 },
    { lat: 48.5716137, lng: 19.7411314 },
    { lat: 48.5714964, lng: 19.740876 },
    { lat: 48.5713773, lng: 19.7405265 },
    { lat: 48.5712785, lng: 19.7397989 },
    { lat: 48.571054, lng: 19.7391996 },
    { lat: 48.5708759, lng: 19.7388944 },
    { lat: 48.5707932, lng: 19.7385772 },
    { lat: 48.570587, lng: 19.738473 },
    { lat: 48.5702406, lng: 19.7381097 },
    { lat: 48.5702234, lng: 19.7380253 },
    { lat: 48.5697255, lng: 19.737849 },
    { lat: 48.5695967, lng: 19.7374835 },
    { lat: 48.5693159, lng: 19.7372267 },
    { lat: 48.569163, lng: 19.7368226 },
    { lat: 48.5689999, lng: 19.7366999 },
    { lat: 48.5688469, lng: 19.7362464 },
    { lat: 48.5686835, lng: 19.7361706 },
    { lat: 48.5685716, lng: 19.7360378 },
    { lat: 48.5683622, lng: 19.7355807 },
    { lat: 48.5681293, lng: 19.7353576 },
    { lat: 48.5681022, lng: 19.7352059 },
    { lat: 48.5678391, lng: 19.7349143 },
    { lat: 48.5673636, lng: 19.7344298 },
    { lat: 48.5672503, lng: 19.7343864 },
    { lat: 48.5672224, lng: 19.7342687 },
    { lat: 48.5669249, lng: 19.734269 },
    { lat: 48.566439, lng: 19.7353313 },
    { lat: 48.5664107, lng: 19.7352772 },
    { lat: 48.5661048, lng: 19.7355694 },
    { lat: 48.5658554, lng: 19.7356087 },
    { lat: 48.5657228, lng: 19.7352851 },
    { lat: 48.5655836, lng: 19.7350949 },
    { lat: 48.5648324, lng: 19.7348193 },
    { lat: 48.5644725, lng: 19.7342998 },
    { lat: 48.5643673, lng: 19.7341161 },
    { lat: 48.5640485, lng: 19.7327377 },
    { lat: 48.563353, lng: 19.731945 },
    { lat: 48.5627499, lng: 19.7319384 },
    { lat: 48.5626623, lng: 19.7318149 },
    { lat: 48.5626401, lng: 19.7318526 },
    { lat: 48.5622034, lng: 19.7315017 },
    { lat: 48.5618408, lng: 19.7313802 },
    { lat: 48.5616015, lng: 19.7306584 },
    { lat: 48.5615254, lng: 19.7302616 },
    { lat: 48.5615181, lng: 19.7302257 },
  ],
  Cinobaňa: [
    { lat: 48.519019, lng: 19.625704 },
    { lat: 48.519183, lng: 19.625464 },
    { lat: 48.519706, lng: 19.625201 },
    { lat: 48.520294, lng: 19.625014 },
    { lat: 48.520438, lng: 19.624991 },
    { lat: 48.520737, lng: 19.624937 },
    { lat: 48.521382, lng: 19.624848 },
    { lat: 48.52171, lng: 19.624852 },
    { lat: 48.522117, lng: 19.624859 },
    { lat: 48.522591, lng: 19.624527 },
    { lat: 48.52331, lng: 19.62413 },
    { lat: 48.523718, lng: 19.623805 },
    { lat: 48.523936, lng: 19.62347 },
    { lat: 48.524277, lng: 19.622991 },
    { lat: 48.524601, lng: 19.622551 },
    { lat: 48.524896, lng: 19.622145 },
    { lat: 48.525168, lng: 19.621691 },
    { lat: 48.525631, lng: 19.621438 },
    { lat: 48.526169, lng: 19.62116 },
    { lat: 48.52651, lng: 19.620936 },
    { lat: 48.526849, lng: 19.620782 },
    { lat: 48.527227, lng: 19.620702 },
    { lat: 48.527442, lng: 19.620611 },
    { lat: 48.527843, lng: 19.620359 },
    { lat: 48.528313, lng: 19.620316 },
    { lat: 48.528914, lng: 19.620138 },
    { lat: 48.529226, lng: 19.6202 },
    { lat: 48.529824, lng: 19.620041 },
    { lat: 48.530376, lng: 19.619859 },
    { lat: 48.530807, lng: 19.61979 },
    { lat: 48.531291, lng: 19.619637 },
    { lat: 48.531479, lng: 19.619496 },
    { lat: 48.531838, lng: 19.619228 },
    { lat: 48.53222, lng: 19.618992 },
    { lat: 48.532501, lng: 19.618861 },
    { lat: 48.532716, lng: 19.618766 },
    { lat: 48.532947, lng: 19.618753 },
    { lat: 48.533393, lng: 19.618515 },
    { lat: 48.533664, lng: 19.618391 },
    { lat: 48.533759, lng: 19.618347 },
    { lat: 48.534091, lng: 19.618146 },
    { lat: 48.534498, lng: 19.617869 },
    { lat: 48.534586, lng: 19.617752 },
    { lat: 48.534855, lng: 19.617389 },
    { lat: 48.534866, lng: 19.617375 },
    { lat: 48.535073, lng: 19.617097 },
    { lat: 48.535363, lng: 19.616805 },
    { lat: 48.535706, lng: 19.616259 },
    { lat: 48.536033, lng: 19.615819 },
    { lat: 48.536362, lng: 19.615029 },
    { lat: 48.536682, lng: 19.614157 },
    { lat: 48.537317, lng: 19.612798 },
    { lat: 48.537557, lng: 19.612249 },
    { lat: 48.537681, lng: 19.611952 },
    { lat: 48.537821, lng: 19.611656 },
    { lat: 48.53793, lng: 19.611402 },
    { lat: 48.538006, lng: 19.611226 },
    { lat: 48.538148, lng: 19.610869 },
    { lat: 48.538524, lng: 19.609964 },
    { lat: 48.538904, lng: 19.609008 },
    { lat: 48.538957, lng: 19.608875 },
    { lat: 48.539113, lng: 19.608335 },
    { lat: 48.539316, lng: 19.607637 },
    { lat: 48.539414, lng: 19.607241 },
    { lat: 48.539481, lng: 19.606723 },
    { lat: 48.539654, lng: 19.605393 },
    { lat: 48.539654, lng: 19.60508 },
    { lat: 48.539419, lng: 19.604124 },
    { lat: 48.539368, lng: 19.604048 },
    { lat: 48.539286, lng: 19.603974 },
    { lat: 48.539112, lng: 19.603922 },
    { lat: 48.538922, lng: 19.603803 },
    { lat: 48.538737, lng: 19.603553 },
    { lat: 48.538565, lng: 19.603172 },
    { lat: 48.538494, lng: 19.602918 },
    { lat: 48.538467, lng: 19.602625 },
    { lat: 48.538468, lng: 19.601812 },
    { lat: 48.538452, lng: 19.601227 },
    { lat: 48.538256, lng: 19.599891 },
    { lat: 48.538203, lng: 19.599531 },
    { lat: 48.537971, lng: 19.598237 },
    { lat: 48.537856, lng: 19.597137 },
    { lat: 48.53783, lng: 19.595885 },
    { lat: 48.53774, lng: 19.594814 },
    { lat: 48.537683, lng: 19.594388 },
    { lat: 48.537611, lng: 19.594114 },
    { lat: 48.537512, lng: 19.593881 },
    { lat: 48.537353, lng: 19.593404 },
    { lat: 48.536916, lng: 19.592392 },
    { lat: 48.536792, lng: 19.592202 },
    { lat: 48.536567, lng: 19.591975 },
    { lat: 48.536451, lng: 19.591755 },
    { lat: 48.536407, lng: 19.59156 },
    { lat: 48.536473, lng: 19.591343 },
    { lat: 48.536584, lng: 19.590952 },
    { lat: 48.536709, lng: 19.590446 },
    { lat: 48.536805, lng: 19.589886 },
    { lat: 48.536921, lng: 19.589308 },
    { lat: 48.537051, lng: 19.588939 },
    { lat: 48.537106, lng: 19.588748 },
    { lat: 48.537203, lng: 19.588211 },
    { lat: 48.537425, lng: 19.587097 },
    { lat: 48.537494, lng: 19.586652 },
    { lat: 48.537619, lng: 19.58584 },
    { lat: 48.537884, lng: 19.585073 },
    { lat: 48.537976, lng: 19.584755 },
    { lat: 48.538066, lng: 19.584229 },
    { lat: 48.538051, lng: 19.583743 },
    { lat: 48.537981, lng: 19.583231 },
    { lat: 48.537754, lng: 19.582264 },
    { lat: 48.537542, lng: 19.581866 },
    { lat: 48.53725, lng: 19.58101 },
    { lat: 48.537111, lng: 19.580469 },
    { lat: 48.53559, lng: 19.581432 },
    { lat: 48.534345, lng: 19.581761 },
    { lat: 48.534206, lng: 19.582113 },
    { lat: 48.533667, lng: 19.583095 },
    { lat: 48.532898, lng: 19.583596 },
    { lat: 48.532853, lng: 19.583625 },
    { lat: 48.532417, lng: 19.58391 },
    { lat: 48.531959, lng: 19.584792 },
    { lat: 48.531685, lng: 19.585207 },
    { lat: 48.530797, lng: 19.586569 },
    { lat: 48.529754, lng: 19.587663 },
    { lat: 48.529436, lng: 19.587936 },
    { lat: 48.529394, lng: 19.587972 },
    { lat: 48.528885, lng: 19.588409 },
    { lat: 48.528496, lng: 19.589262 },
    { lat: 48.528268, lng: 19.590021 },
    { lat: 48.527759, lng: 19.5899 },
    { lat: 48.527248, lng: 19.591692 },
    { lat: 48.526862, lng: 19.591881 },
    { lat: 48.525819, lng: 19.592049 },
    { lat: 48.525315, lng: 19.592142 },
    { lat: 48.525278, lng: 19.592179 },
    { lat: 48.525093, lng: 19.592376 },
    { lat: 48.524469, lng: 19.592267 },
    { lat: 48.52326, lng: 19.591283 },
    { lat: 48.522397, lng: 19.590934 },
    { lat: 48.521993, lng: 19.59097 },
    { lat: 48.521643, lng: 19.591198 },
    { lat: 48.520231, lng: 19.59213 },
    { lat: 48.519253, lng: 19.592565 },
    { lat: 48.518715, lng: 19.592899 },
    { lat: 48.518214, lng: 19.59343 },
    { lat: 48.517614, lng: 19.593696 },
    { lat: 48.516759, lng: 19.59407 },
    { lat: 48.516508, lng: 19.594219 },
    { lat: 48.515683, lng: 19.594668 },
    { lat: 48.514774, lng: 19.594637 },
    { lat: 48.514576, lng: 19.594883 },
    { lat: 48.514289, lng: 19.595167 },
    { lat: 48.514034, lng: 19.59549 },
    { lat: 48.512937, lng: 19.596946 },
    { lat: 48.51236, lng: 19.597444 },
    { lat: 48.512149, lng: 19.597943 },
    { lat: 48.511797, lng: 19.598621 },
    { lat: 48.511272, lng: 19.599084 },
    { lat: 48.511111, lng: 19.59924 },
    { lat: 48.511084, lng: 19.599266 },
    { lat: 48.510777, lng: 19.599562 },
    { lat: 48.510567, lng: 19.599889 },
    { lat: 48.510261, lng: 19.60049 },
    { lat: 48.509866, lng: 19.600945 },
    { lat: 48.509552, lng: 19.601381 },
    { lat: 48.509124, lng: 19.602283 },
    { lat: 48.508292, lng: 19.603596 },
    { lat: 48.508094, lng: 19.604142 },
    { lat: 48.508004, lng: 19.604501 },
    { lat: 48.507909, lng: 19.60536 },
    { lat: 48.50788, lng: 19.606218 },
    { lat: 48.507665, lng: 19.607157 },
    { lat: 48.507295, lng: 19.60777 },
    { lat: 48.507261, lng: 19.607829 },
    { lat: 48.50698, lng: 19.607944 },
    { lat: 48.506462, lng: 19.607738 },
    { lat: 48.506399, lng: 19.607877 },
    { lat: 48.505826, lng: 19.608042 },
    { lat: 48.505358, lng: 19.608216 },
    { lat: 48.505055, lng: 19.608329 },
    { lat: 48.504864, lng: 19.608275 },
    { lat: 48.504539, lng: 19.60838 },
    { lat: 48.504366, lng: 19.608209 },
    { lat: 48.503947, lng: 19.607928 },
    { lat: 48.503341, lng: 19.607864 },
    { lat: 48.502871, lng: 19.607846 },
    { lat: 48.502092, lng: 19.608053 },
    { lat: 48.50156, lng: 19.608145 },
    { lat: 48.501448, lng: 19.608115 },
    { lat: 48.50142, lng: 19.608122 },
    { lat: 48.501397, lng: 19.608129 },
    { lat: 48.500968, lng: 19.608241 },
    { lat: 48.50035, lng: 19.608849 },
    { lat: 48.500037, lng: 19.60903 },
    { lat: 48.499577, lng: 19.609502 },
    { lat: 48.49896, lng: 19.60928 },
    { lat: 48.498749, lng: 19.608856 },
    { lat: 48.498419, lng: 19.608408 },
    { lat: 48.498106, lng: 19.607876 },
    { lat: 48.49751, lng: 19.60693 },
    { lat: 48.497335, lng: 19.606965 },
    { lat: 48.497143, lng: 19.606742 },
    { lat: 48.496884, lng: 19.606551 },
    { lat: 48.496699, lng: 19.606429 },
    { lat: 48.496558, lng: 19.606373 },
    { lat: 48.496429, lng: 19.606143 },
    { lat: 48.496282, lng: 19.606019 },
    { lat: 48.495279, lng: 19.605684 },
    { lat: 48.494539, lng: 19.605166 },
    { lat: 48.493985, lng: 19.604556 },
    { lat: 48.493308, lng: 19.603712 },
    { lat: 48.492999, lng: 19.603503 },
    { lat: 48.492925, lng: 19.603278 },
    { lat: 48.492876, lng: 19.603138 },
    { lat: 48.492584, lng: 19.602893 },
    { lat: 48.492496, lng: 19.60278 },
    { lat: 48.492087, lng: 19.602709 },
    { lat: 48.491711, lng: 19.602807 },
    { lat: 48.491193, lng: 19.602935 },
    { lat: 48.490978, lng: 19.602902 },
    { lat: 48.490876, lng: 19.603055 },
    { lat: 48.490289, lng: 19.602447 },
    { lat: 48.48987, lng: 19.602296 },
    { lat: 48.489825, lng: 19.602356 },
    { lat: 48.489519, lng: 19.602285 },
    { lat: 48.489279, lng: 19.602086 },
    { lat: 48.488166, lng: 19.600868 },
    { lat: 48.487958, lng: 19.600801 },
    { lat: 48.487625, lng: 19.600597 },
    { lat: 48.487421, lng: 19.60062 },
    { lat: 48.487265, lng: 19.60059 },
    { lat: 48.486492, lng: 19.600474 },
    { lat: 48.48601, lng: 19.600262 },
    { lat: 48.485633, lng: 19.600655 },
    { lat: 48.485226, lng: 19.601137 },
    { lat: 48.485097, lng: 19.601254 },
    { lat: 48.484703, lng: 19.601609 },
    { lat: 48.484673, lng: 19.601636 },
    { lat: 48.484185, lng: 19.602098 },
    { lat: 48.483854, lng: 19.602385 },
    { lat: 48.483684, lng: 19.60272 },
    { lat: 48.483526, lng: 19.602935 },
    { lat: 48.483434, lng: 19.603041 },
    { lat: 48.483323, lng: 19.603546 },
    { lat: 48.482836, lng: 19.603728 },
    { lat: 48.482329, lng: 19.603887 },
    { lat: 48.482163, lng: 19.603938 },
    { lat: 48.481333, lng: 19.604407 },
    { lat: 48.480807, lng: 19.604903 },
    { lat: 48.480603, lng: 19.605106 },
    { lat: 48.479624, lng: 19.605635 },
    { lat: 48.479357, lng: 19.605844 },
    { lat: 48.478618, lng: 19.606269 },
    { lat: 48.478304, lng: 19.606363 },
    { lat: 48.476905, lng: 19.607968 },
    { lat: 48.476429, lng: 19.608461 },
    { lat: 48.475668, lng: 19.608714 },
    { lat: 48.47464, lng: 19.608849 },
    { lat: 48.474424, lng: 19.609036 },
    { lat: 48.474231, lng: 19.609477 },
    { lat: 48.474119, lng: 19.609857 },
    { lat: 48.473558, lng: 19.610636 },
    { lat: 48.473005, lng: 19.610535 },
    { lat: 48.472388, lng: 19.610683 },
    { lat: 48.471814, lng: 19.610797 },
    { lat: 48.47154, lng: 19.611045 },
    { lat: 48.471344, lng: 19.61143 },
    { lat: 48.470866, lng: 19.61176 },
    { lat: 48.470332, lng: 19.612182 },
    { lat: 48.469894, lng: 19.612294 },
    { lat: 48.469327, lng: 19.612122 },
    { lat: 48.469019, lng: 19.612329 },
    { lat: 48.468464, lng: 19.61236 },
    { lat: 48.468141, lng: 19.61165 },
    { lat: 48.467817, lng: 19.611004 },
    { lat: 48.466858, lng: 19.609372 },
    { lat: 48.466761, lng: 19.60921 },
    { lat: 48.466202, lng: 19.608662 },
    { lat: 48.465917, lng: 19.608353 },
    { lat: 48.465381, lng: 19.607287 },
    { lat: 48.465189, lng: 19.607042 },
    { lat: 48.464961, lng: 19.606674 },
    { lat: 48.464621, lng: 19.605842 },
    { lat: 48.464205, lng: 19.605055 },
    { lat: 48.463791, lng: 19.604545 },
    { lat: 48.463229, lng: 19.603979 },
    { lat: 48.461903, lng: 19.602128 },
    { lat: 48.46187, lng: 19.602079 },
    { lat: 48.461596, lng: 19.60176 },
    { lat: 48.461073, lng: 19.600605 },
    { lat: 48.460933, lng: 19.599519 },
    { lat: 48.460623, lng: 19.598682 },
    { lat: 48.460601, lng: 19.598631 },
    { lat: 48.460343, lng: 19.59811 },
    { lat: 48.460331, lng: 19.598086 },
    { lat: 48.460292, lng: 19.598157 },
    { lat: 48.460183, lng: 19.598236 },
    { lat: 48.460003, lng: 19.598169 },
    { lat: 48.45989, lng: 19.598546 },
    { lat: 48.459824, lng: 19.598793 },
    { lat: 48.459774, lng: 19.598921 },
    { lat: 48.459641, lng: 19.59892 },
    { lat: 48.459565, lng: 19.599006 },
    { lat: 48.459337, lng: 19.599571 },
    { lat: 48.4592, lng: 19.599825 },
    { lat: 48.459024, lng: 19.599829 },
    { lat: 48.458898, lng: 19.59986 },
    { lat: 48.458766, lng: 19.600221 },
    { lat: 48.458707, lng: 19.600267 },
    { lat: 48.458547, lng: 19.600275 },
    { lat: 48.458467, lng: 19.600503 },
    { lat: 48.458302, lng: 19.600903 },
    { lat: 48.458014, lng: 19.601056 },
    { lat: 48.457942, lng: 19.601275 },
    { lat: 48.457929, lng: 19.601614 },
    { lat: 48.457941, lng: 19.601869 },
    { lat: 48.457668, lng: 19.602116 },
    { lat: 48.457499, lng: 19.602392 },
    { lat: 48.457436, lng: 19.602433 },
    { lat: 48.457382, lng: 19.60265 },
    { lat: 48.457396, lng: 19.602961 },
    { lat: 48.457161, lng: 19.603144 },
    { lat: 48.457142, lng: 19.603334 },
    { lat: 48.45701, lng: 19.603615 },
    { lat: 48.457004, lng: 19.603831 },
    { lat: 48.456847, lng: 19.60418 },
    { lat: 48.45668, lng: 19.604256 },
    { lat: 48.456473, lng: 19.604791 },
    { lat: 48.456285, lng: 19.605266 },
    { lat: 48.456053, lng: 19.605494 },
    { lat: 48.456012, lng: 19.605709 },
    { lat: 48.455859, lng: 19.605806 },
    { lat: 48.455763, lng: 19.605928 },
    { lat: 48.455664, lng: 19.606028 },
    { lat: 48.4556, lng: 19.606248 },
    { lat: 48.45548, lng: 19.606213 },
    { lat: 48.45534, lng: 19.606357 },
    { lat: 48.455317, lng: 19.606694 },
    { lat: 48.455158, lng: 19.606844 },
    { lat: 48.454889, lng: 19.607283 },
    { lat: 48.454839, lng: 19.607529 },
    { lat: 48.454862, lng: 19.607864 },
    { lat: 48.454801, lng: 19.608164 },
    { lat: 48.454792, lng: 19.608492 },
    { lat: 48.454655, lng: 19.608859 },
    { lat: 48.454722, lng: 19.609058 },
    { lat: 48.454707, lng: 19.609336 },
    { lat: 48.454525, lng: 19.609798 },
    { lat: 48.454294, lng: 19.610151 },
    { lat: 48.454355, lng: 19.610331 },
    { lat: 48.454289, lng: 19.610641 },
    { lat: 48.454195, lng: 19.61097 },
    { lat: 48.454117, lng: 19.611169 },
    { lat: 48.454163, lng: 19.611641 },
    { lat: 48.454007, lng: 19.611821 },
    { lat: 48.454, lng: 19.612163 },
    { lat: 48.453956, lng: 19.61249 },
    { lat: 48.454159, lng: 19.612977 },
    { lat: 48.454018, lng: 19.613643 },
    { lat: 48.453816, lng: 19.613966 },
    { lat: 48.453904, lng: 19.614191 },
    { lat: 48.453924, lng: 19.614442 },
    { lat: 48.453883, lng: 19.614522 },
    { lat: 48.453842, lng: 19.614876 },
    { lat: 48.45375, lng: 19.615174 },
    { lat: 48.453699, lng: 19.615475 },
    { lat: 48.453811, lng: 19.615546 },
    { lat: 48.453823, lng: 19.61571 },
    { lat: 48.453671, lng: 19.616142 },
    { lat: 48.453651, lng: 19.616295 },
    { lat: 48.453452, lng: 19.616495 },
    { lat: 48.453395, lng: 19.616699 },
    { lat: 48.453521, lng: 19.616816 },
    { lat: 48.453307, lng: 19.617268 },
    { lat: 48.453328, lng: 19.617615 },
    { lat: 48.453274, lng: 19.617859 },
    { lat: 48.453337, lng: 19.618059 },
    { lat: 48.453189, lng: 19.618542 },
    { lat: 48.452895, lng: 19.618718 },
    { lat: 48.452693, lng: 19.618944 },
    { lat: 48.452532, lng: 19.619205 },
    { lat: 48.45229, lng: 19.61932 },
    { lat: 48.452182, lng: 19.619372 },
    { lat: 48.452119, lng: 19.619398 },
    { lat: 48.452024, lng: 19.619452 },
    { lat: 48.45192, lng: 19.619643 },
    { lat: 48.451602, lng: 19.619821 },
    { lat: 48.450952, lng: 19.620358 },
    { lat: 48.450789, lng: 19.620361 },
    { lat: 48.450495, lng: 19.62061 },
    { lat: 48.450287, lng: 19.620962 },
    { lat: 48.450197, lng: 19.620934 },
    { lat: 48.450046, lng: 19.621062 },
    { lat: 48.449926, lng: 19.621033 },
    { lat: 48.449741, lng: 19.621148 },
    { lat: 48.449671, lng: 19.621288 },
    { lat: 48.44945, lng: 19.621236 },
    { lat: 48.449332, lng: 19.621107 },
    { lat: 48.448719, lng: 19.620725 },
    { lat: 48.448686, lng: 19.62046 },
    { lat: 48.448516, lng: 19.620222 },
    { lat: 48.44853, lng: 19.620153 },
    { lat: 48.448536, lng: 19.620129 },
    { lat: 48.448479, lng: 19.620033 },
    { lat: 48.448334, lng: 19.619976 },
    { lat: 48.448285, lng: 19.619961 },
    { lat: 48.448159, lng: 19.619897 },
    { lat: 48.447931, lng: 19.619793 },
    { lat: 48.447785, lng: 19.619952 },
    { lat: 48.447533, lng: 19.619769 },
    { lat: 48.447542, lng: 19.619488 },
    { lat: 48.447456, lng: 19.619147 },
    { lat: 48.447358, lng: 19.619019 },
    { lat: 48.447155, lng: 19.618926 },
    { lat: 48.446928, lng: 19.618613 },
    { lat: 48.446743, lng: 19.618567 },
    { lat: 48.446777, lng: 19.618505 },
    { lat: 48.446639, lng: 19.61838 },
    { lat: 48.446465, lng: 19.618191 },
    { lat: 48.446337, lng: 19.61828 },
    { lat: 48.446128, lng: 19.618113 },
    { lat: 48.445833, lng: 19.61801 },
    { lat: 48.445774, lng: 19.617777 },
    { lat: 48.445662, lng: 19.617708 },
    { lat: 48.445546, lng: 19.617452 },
    { lat: 48.445311, lng: 19.617462 },
    { lat: 48.445295, lng: 19.617152 },
    { lat: 48.444963, lng: 19.617156 },
    { lat: 48.444862, lng: 19.617361 },
    { lat: 48.444709, lng: 19.617341 },
    { lat: 48.444597, lng: 19.6172 },
    { lat: 48.444489, lng: 19.617209 },
    { lat: 48.444446, lng: 19.617128 },
    { lat: 48.444439, lng: 19.617067 },
    { lat: 48.444492, lng: 19.617065 },
    { lat: 48.444492, lng: 19.616735 },
    { lat: 48.44445, lng: 19.616601 },
    { lat: 48.444333, lng: 19.616635 },
    { lat: 48.444233, lng: 19.616586 },
    { lat: 48.444225, lng: 19.616616 },
    { lat: 48.444107, lng: 19.617117 },
    { lat: 48.443945, lng: 19.617873 },
    { lat: 48.443911, lng: 19.618182 },
    { lat: 48.443804, lng: 19.61899 },
    { lat: 48.443755, lng: 19.620012 },
    { lat: 48.443743, lng: 19.620254 },
    { lat: 48.443757, lng: 19.620536 },
    { lat: 48.443535, lng: 19.621351 },
    { lat: 48.443357, lng: 19.622454 },
    { lat: 48.442986, lng: 19.623281 },
    { lat: 48.442914, lng: 19.623703 },
    { lat: 48.442453, lng: 19.624747 },
    { lat: 48.4417, lng: 19.625255 },
    { lat: 48.441657, lng: 19.625234 },
    { lat: 48.441632, lng: 19.625223 },
    { lat: 48.440663, lng: 19.624777 },
    { lat: 48.440372, lng: 19.624415 },
    { lat: 48.440373, lng: 19.623661 },
    { lat: 48.440141, lng: 19.622435 },
    { lat: 48.440016, lng: 19.622214 },
    { lat: 48.43998, lng: 19.622147 },
    { lat: 48.439886, lng: 19.62202 },
    { lat: 48.439828, lng: 19.621927 },
    { lat: 48.439692, lng: 19.621703 },
    { lat: 48.439522, lng: 19.621425 },
    { lat: 48.439276, lng: 19.621003 },
    { lat: 48.438996, lng: 19.620501 },
    { lat: 48.437389, lng: 19.621782 },
    { lat: 48.437294, lng: 19.621877 },
    { lat: 48.436961, lng: 19.622217 },
    { lat: 48.436477, lng: 19.622704 },
    { lat: 48.436311, lng: 19.622861 },
    { lat: 48.435977, lng: 19.623172 },
    { lat: 48.435495, lng: 19.623624 },
    { lat: 48.435326, lng: 19.624261 },
    { lat: 48.435305, lng: 19.624556 },
    { lat: 48.435297, lng: 19.624646 },
    { lat: 48.434869, lng: 19.625935 },
    { lat: 48.434535, lng: 19.626734 },
    { lat: 48.434092, lng: 19.62721 },
    { lat: 48.433404, lng: 19.627505 },
    { lat: 48.433118, lng: 19.627888 },
    { lat: 48.432886, lng: 19.628195 },
    { lat: 48.432468, lng: 19.628781 },
    { lat: 48.431906, lng: 19.630621 },
    { lat: 48.431656, lng: 19.631236 },
    { lat: 48.432672, lng: 19.631833 },
    { lat: 48.432903, lng: 19.632393 },
    { lat: 48.433027, lng: 19.632811 },
    { lat: 48.433013, lng: 19.63371 },
    { lat: 48.433028, lng: 19.63371 },
    { lat: 48.432912, lng: 19.634306 },
    { lat: 48.433121, lng: 19.635093 },
    { lat: 48.43274, lng: 19.636472 },
    { lat: 48.432517, lng: 19.637041 },
    { lat: 48.431278, lng: 19.638654 },
    { lat: 48.431003, lng: 19.639159 },
    { lat: 48.430944, lng: 19.639824 },
    { lat: 48.43093, lng: 19.639981 },
    { lat: 48.430877, lng: 19.640679 },
    { lat: 48.4308556, lng: 19.6409403 },
    { lat: 48.4308337, lng: 19.6412144 },
    { lat: 48.430812, lng: 19.6415 },
    { lat: 48.430421, lng: 19.642566 },
    { lat: 48.429956, lng: 19.643592 },
    { lat: 48.429757, lng: 19.644026 },
    { lat: 48.429628, lng: 19.644936 },
    { lat: 48.429235, lng: 19.645838 },
    { lat: 48.428997, lng: 19.646332 },
    { lat: 48.428703, lng: 19.647177 },
    { lat: 48.4292807, lng: 19.647132 },
    { lat: 48.4300119, lng: 19.6474276 },
    { lat: 48.430939, lng: 19.648769 },
    { lat: 48.4317484, lng: 19.6497528 },
    { lat: 48.4322664, lng: 19.6509188 },
    { lat: 48.4329331, lng: 19.6514821 },
    { lat: 48.4336072, lng: 19.6499158 },
    { lat: 48.4336157, lng: 19.6492045 },
    { lat: 48.4337528, lng: 19.6488989 },
    { lat: 48.4338668, lng: 19.6484544 },
    { lat: 48.4339831, lng: 19.6482321 },
    { lat: 48.4342577, lng: 19.647101 },
    { lat: 48.4342389, lng: 19.6468627 },
    { lat: 48.4343311, lng: 19.6466542 },
    { lat: 48.4347491, lng: 19.646254 },
    { lat: 48.4350098, lng: 19.6457633 },
    { lat: 48.4351596, lng: 19.6453609 },
    { lat: 48.4353446, lng: 19.6451185 },
    { lat: 48.4353648, lng: 19.6449901 },
    { lat: 48.4356141, lng: 19.6447741 },
    { lat: 48.4358154, lng: 19.6442916 },
    { lat: 48.4357964, lng: 19.6442222 },
    { lat: 48.4358979, lng: 19.6441203 },
    { lat: 48.4359392, lng: 19.6438638 },
    { lat: 48.4358996, lng: 19.6437089 },
    { lat: 48.436146, lng: 19.643325 },
    { lat: 48.4363098, lng: 19.6433338 },
    { lat: 48.436421, lng: 19.6432036 },
    { lat: 48.4368224, lng: 19.6432595 },
    { lat: 48.437027, lng: 19.6429477 },
    { lat: 48.437244, lng: 19.6427669 },
    { lat: 48.4374716, lng: 19.6427391 },
    { lat: 48.4375858, lng: 19.6429475 },
    { lat: 48.4377354, lng: 19.6429382 },
    { lat: 48.4378113, lng: 19.6428006 },
    { lat: 48.4380423, lng: 19.6426234 },
    { lat: 48.4380443, lng: 19.6424962 },
    { lat: 48.438194, lng: 19.6424642 },
    { lat: 48.4383034, lng: 19.6423665 },
    { lat: 48.4386198, lng: 19.6423413 },
    { lat: 48.4387138, lng: 19.6422008 },
    { lat: 48.4389919, lng: 19.6422725 },
    { lat: 48.4392153, lng: 19.6421531 },
    { lat: 48.4392823, lng: 19.6422403 },
    { lat: 48.4393614, lng: 19.6421922 },
    { lat: 48.4395225, lng: 19.64224 },
    { lat: 48.4397011, lng: 19.6421078 },
    { lat: 48.4398563, lng: 19.6418479 },
    { lat: 48.4400726, lng: 19.6419472 },
    { lat: 48.4405056, lng: 19.6418316 },
    { lat: 48.4406631, lng: 19.6420722 },
    { lat: 48.4407857, lng: 19.6421203 },
    { lat: 48.4408711, lng: 19.6420612 },
    { lat: 48.4408946, lng: 19.6421358 },
    { lat: 48.4410175, lng: 19.6421602 },
    { lat: 48.4410591, lng: 19.6423071 },
    { lat: 48.441462, lng: 19.6425698 },
    { lat: 48.4416083, lng: 19.6427668 },
    { lat: 48.4416935, lng: 19.6427542 },
    { lat: 48.4417698, lng: 19.6430221 },
    { lat: 48.4417994, lng: 19.6434315 },
    { lat: 48.4419786, lng: 19.6436826 },
    { lat: 48.4422961, lng: 19.6439472 },
    { lat: 48.4424944, lng: 19.6440516 },
    { lat: 48.4425665, lng: 19.6440474 },
    { lat: 48.4426428, lng: 19.6439183 },
    { lat: 48.4429605, lng: 19.6439423 },
    { lat: 48.442997, lng: 19.6440024 },
    { lat: 48.4429576, lng: 19.6441491 },
    { lat: 48.4429906, lng: 19.6443317 },
    { lat: 48.443192, lng: 19.6444237 },
    { lat: 48.4433564, lng: 19.6444204 },
    { lat: 48.4433995, lng: 19.6446534 },
    { lat: 48.4436035, lng: 19.6446805 },
    { lat: 48.4437857, lng: 19.6448245 },
    { lat: 48.4441191, lng: 19.6448441 },
    { lat: 48.4444133, lng: 19.6449692 },
    { lat: 48.4446398, lng: 19.6454955 },
    { lat: 48.4447489, lng: 19.6459656 },
    { lat: 48.4449205, lng: 19.6461735 },
    { lat: 48.4449477, lng: 19.6465977 },
    { lat: 48.4448723, lng: 19.647027 },
    { lat: 48.4449348, lng: 19.6477368 },
    { lat: 48.4445396, lng: 19.6479205 },
    { lat: 48.4444133, lng: 19.6490161 },
    { lat: 48.4442515, lng: 19.649752 },
    { lat: 48.444174, lng: 19.6499083 },
    { lat: 48.443986, lng: 19.6499136 },
    { lat: 48.4436289, lng: 19.6506129 },
    { lat: 48.4436499, lng: 19.6509536 },
    { lat: 48.4430076, lng: 19.6507269 },
    { lat: 48.4426602, lng: 19.6504731 },
    { lat: 48.4420312, lng: 19.6502387 },
    { lat: 48.4416671, lng: 19.6499401 },
    { lat: 48.4412335, lng: 19.6498189 },
    { lat: 48.4406584, lng: 19.6498359 },
    { lat: 48.4401397, lng: 19.6496496 },
    { lat: 48.4400435, lng: 19.6496668 },
    { lat: 48.4398889, lng: 19.6494125 },
    { lat: 48.4400509, lng: 19.6488062 },
    { lat: 48.4400735, lng: 19.648531 },
    { lat: 48.4400002, lng: 19.6484562 },
    { lat: 48.4395482, lng: 19.6483021 },
    { lat: 48.4383865, lng: 19.6484762 },
    { lat: 48.4377672, lng: 19.6484867 },
    { lat: 48.4374293, lng: 19.6486911 },
    { lat: 48.4369973, lng: 19.6491077 },
    { lat: 48.4362479, lng: 19.6503015 },
    { lat: 48.4361166, lng: 19.6508893 },
    { lat: 48.4357146, lng: 19.6518447 },
    { lat: 48.4355956, lng: 19.6523043 },
    { lat: 48.4355493, lng: 19.6530786 },
    { lat: 48.4355938, lng: 19.6535638 },
    { lat: 48.4359671, lng: 19.6549469 },
    { lat: 48.4360329, lng: 19.6565069 },
    { lat: 48.4359599, lng: 19.6577381 },
    { lat: 48.4359774, lng: 19.6581788 },
    { lat: 48.436127, lng: 19.6592988 },
    { lat: 48.436893, lng: 19.6597734 },
    { lat: 48.4368223, lng: 19.6586472 },
    { lat: 48.4369029, lng: 19.6586134 },
    { lat: 48.4368763, lng: 19.6584199 },
    { lat: 48.4368154, lng: 19.658447 },
    { lat: 48.4367771, lng: 19.6577689 },
    { lat: 48.4371838, lng: 19.656492 },
    { lat: 48.4371688, lng: 19.6563125 },
    { lat: 48.4393618, lng: 19.6525149 },
    { lat: 48.4397236, lng: 19.6528152 },
    { lat: 48.4400729, lng: 19.6534141 },
    { lat: 48.4403836, lng: 19.6542115 },
    { lat: 48.4409143, lng: 19.655309 },
    { lat: 48.4408221, lng: 19.6554632 },
    { lat: 48.4407461, lng: 19.656203 },
    { lat: 48.4404056, lng: 19.6579748 },
    { lat: 48.4403253, lng: 19.6591242 },
    { lat: 48.4403457, lng: 19.6599483 },
    { lat: 48.4402811, lng: 19.6603193 },
    { lat: 48.440411, lng: 19.6616993 },
    { lat: 48.4403456, lng: 19.6632046 },
    { lat: 48.4400228, lng: 19.6635141 },
    { lat: 48.4393017, lng: 19.6638926 },
    { lat: 48.4400003, lng: 19.6661744 },
    { lat: 48.4402824, lng: 19.6663828 },
    { lat: 48.440644, lng: 19.6672847 },
    { lat: 48.4410099, lng: 19.6687323 },
    { lat: 48.4412203, lng: 19.670177 },
    { lat: 48.4414026, lng: 19.670952 },
    { lat: 48.4416996, lng: 19.6711394 },
    { lat: 48.4418011, lng: 19.6711443 },
    { lat: 48.4417585, lng: 19.6717999 },
    { lat: 48.4418873, lng: 19.6719806 },
    { lat: 48.4418595, lng: 19.6721193 },
    { lat: 48.4419183, lng: 19.6722706 },
    { lat: 48.4419301, lng: 19.6724088 },
    { lat: 48.4418293, lng: 19.6727966 },
    { lat: 48.4417425, lng: 19.6728725 },
    { lat: 48.4417154, lng: 19.6731222 },
    { lat: 48.4417489, lng: 19.6731955 },
    { lat: 48.4416332, lng: 19.6733726 },
    { lat: 48.4414632, lng: 19.6733522 },
    { lat: 48.4413964, lng: 19.6738253 },
    { lat: 48.4414532, lng: 19.67386 },
    { lat: 48.4414532, lng: 19.6741088 },
    { lat: 48.4415176, lng: 19.6743386 },
    { lat: 48.4414892, lng: 19.6744582 },
    { lat: 48.4415462, lng: 19.6747012 },
    { lat: 48.4417131, lng: 19.6750058 },
    { lat: 48.4419175, lng: 19.6749407 },
    { lat: 48.4419354, lng: 19.6751398 },
    { lat: 48.4418926, lng: 19.6752382 },
    { lat: 48.4419866, lng: 19.6752506 },
    { lat: 48.4420008, lng: 19.675367 },
    { lat: 48.4420891, lng: 19.6753765 },
    { lat: 48.4420343, lng: 19.675634 },
    { lat: 48.4418981, lng: 19.6759133 },
    { lat: 48.4418465, lng: 19.6763962 },
    { lat: 48.441951, lng: 19.6767131 },
    { lat: 48.4419257, lng: 19.6770283 },
    { lat: 48.4419796, lng: 19.6771049 },
    { lat: 48.4419767, lng: 19.6774927 },
    { lat: 48.4420669, lng: 19.6775921 },
    { lat: 48.4420743, lng: 19.6777578 },
    { lat: 48.4417894, lng: 19.678539 },
    { lat: 48.4412284, lng: 19.6797236 },
    { lat: 48.4411563, lng: 19.6806007 },
    { lat: 48.4412266, lng: 19.6806372 },
    { lat: 48.4413621, lng: 19.6824529 },
    { lat: 48.4412649, lng: 19.6834308 },
    { lat: 48.4412983, lng: 19.6855825 },
    { lat: 48.4414522, lng: 19.6855975 },
    { lat: 48.4413032, lng: 19.687679 },
    { lat: 48.4411001, lng: 19.6886285 },
    { lat: 48.4406988, lng: 19.6893789 },
    { lat: 48.4406203, lng: 19.6904964 },
    { lat: 48.4405856, lng: 19.6905801 },
    { lat: 48.440464, lng: 19.690634 },
    { lat: 48.4404723, lng: 19.6907392 },
    { lat: 48.4407249, lng: 19.6912247 },
    { lat: 48.4407065, lng: 19.6916673 },
    { lat: 48.4405478, lng: 19.6921535 },
    { lat: 48.4408303, lng: 19.6927251 },
    { lat: 48.4408157, lng: 19.6929263 },
    { lat: 48.440866, lng: 19.6931696 },
    { lat: 48.4409969, lng: 19.6933516 },
    { lat: 48.4409948, lng: 19.6936318 },
    { lat: 48.4413134, lng: 19.6937018 },
    { lat: 48.442037, lng: 19.6935308 },
    { lat: 48.4448498, lng: 19.6931611 },
    { lat: 48.4454437, lng: 19.693011 },
    { lat: 48.447492, lng: 19.6928584 },
    { lat: 48.4505101, lng: 19.6924899 },
    { lat: 48.4518906, lng: 19.6921676 },
    { lat: 48.4537102, lng: 19.6908819 },
    { lat: 48.4542864, lng: 19.6905884 },
    { lat: 48.4547302, lng: 19.6905584 },
    { lat: 48.4550714, lng: 19.6906542 },
    { lat: 48.4556485, lng: 19.6905873 },
    { lat: 48.4566633, lng: 19.6910048 },
    { lat: 48.458055, lng: 19.6913383 },
    { lat: 48.4588396, lng: 19.6916225 },
    { lat: 48.4590858, lng: 19.6914315 },
    { lat: 48.4591008, lng: 19.691085 },
    { lat: 48.4591946, lng: 19.6891273 },
    { lat: 48.4588554, lng: 19.687742 },
    { lat: 48.4585591, lng: 19.6873151 },
    { lat: 48.4584765, lng: 19.686879 },
    { lat: 48.458481, lng: 19.6862509 },
    { lat: 48.458534, lng: 19.6860013 },
    { lat: 48.4589538, lng: 19.6847029 },
    { lat: 48.458912, lng: 19.6838221 },
    { lat: 48.4587359, lng: 19.6832382 },
    { lat: 48.458783, lng: 19.6826285 },
    { lat: 48.4586046, lng: 19.6815614 },
    { lat: 48.4586014, lng: 19.6804836 },
    { lat: 48.4583959, lng: 19.679771 },
    { lat: 48.4583369, lng: 19.6787337 },
    { lat: 48.4586345, lng: 19.6773034 },
    { lat: 48.4589192, lng: 19.6762383 },
    { lat: 48.4592931, lng: 19.6752313 },
    { lat: 48.459566, lng: 19.6750559 },
    { lat: 48.4601126, lng: 19.6743565 },
    { lat: 48.4618002, lng: 19.672695 },
    { lat: 48.4616974, lng: 19.6692227 },
    { lat: 48.4613136, lng: 19.6672527 },
    { lat: 48.4610271, lng: 19.6661681 },
    { lat: 48.460922, lng: 19.6655316 },
    { lat: 48.4608402, lng: 19.6644422 },
    { lat: 48.4610357, lng: 19.6625006 },
    { lat: 48.461384, lng: 19.6614243 },
    { lat: 48.4618387, lng: 19.6603365 },
    { lat: 48.4620359, lng: 19.6593088 },
    { lat: 48.4622218, lng: 19.6588053 },
    { lat: 48.4623639, lng: 19.6581849 },
    { lat: 48.4627257, lng: 19.6570073 },
    { lat: 48.4627534, lng: 19.656499 },
    { lat: 48.4626564, lng: 19.6560189 },
    { lat: 48.4626835, lng: 19.6559952 },
    { lat: 48.4623842, lng: 19.6550284 },
    { lat: 48.4627097, lng: 19.6533525 },
    { lat: 48.4631596, lng: 19.6528043 },
    { lat: 48.4633621, lng: 19.6524248 },
    { lat: 48.4638215, lng: 19.6517943 },
    { lat: 48.464235, lng: 19.6515651 },
    { lat: 48.464572, lng: 19.651295 },
    { lat: 48.4648481, lng: 19.6509455 },
    { lat: 48.4650556, lng: 19.6507959 },
    { lat: 48.4661639, lng: 19.650335 },
    { lat: 48.4665212, lng: 19.6500628 },
    { lat: 48.4667747, lng: 19.6496894 },
    { lat: 48.4669396, lng: 19.6495494 },
    { lat: 48.4673742, lng: 19.6493176 },
    { lat: 48.4680522, lng: 19.6493554 },
    { lat: 48.4697131, lng: 19.6488327 },
    { lat: 48.4702449, lng: 19.6488535 },
    { lat: 48.4710544, lng: 19.6483906 },
    { lat: 48.4718982, lng: 19.6480102 },
    { lat: 48.4730489, lng: 19.6475935 },
    { lat: 48.4733619, lng: 19.6478372 },
    { lat: 48.4735673, lng: 19.6481451 },
    { lat: 48.4742593, lng: 19.6488461 },
    { lat: 48.4750625, lng: 19.6493687 },
    { lat: 48.4758883, lng: 19.6495117 },
    { lat: 48.4760844, lng: 19.6494619 },
    { lat: 48.4763266, lng: 19.6497902 },
    { lat: 48.4770818, lng: 19.6503951 },
    { lat: 48.4774238, lng: 19.6504799 },
    { lat: 48.4777078, lng: 19.6504332 },
    { lat: 48.4783691, lng: 19.6508026 },
    { lat: 48.4788507, lng: 19.6502865 },
    { lat: 48.4798879, lng: 19.6488767 },
    { lat: 48.4807486, lng: 19.6474993 },
    { lat: 48.4809991, lng: 19.6469801 },
    { lat: 48.4815437, lng: 19.6472126 },
    { lat: 48.4826463, lng: 19.64795 },
    { lat: 48.4829447, lng: 19.6477232 },
    { lat: 48.4830763, lng: 19.6474882 },
    { lat: 48.4829587, lng: 19.6482069 },
    { lat: 48.4834886, lng: 19.6487995 },
    { lat: 48.4836779, lng: 19.6490112 },
    { lat: 48.4841698, lng: 19.6488506 },
    { lat: 48.4850193, lng: 19.6494257 },
    { lat: 48.4861457, lng: 19.6480925 },
    { lat: 48.4865624, lng: 19.6473361 },
    { lat: 48.4871315, lng: 19.6480353 },
    { lat: 48.4874522, lng: 19.6481035 },
    { lat: 48.4878459, lng: 19.6476609 },
    { lat: 48.4886999, lng: 19.6469358 },
    { lat: 48.4891424, lng: 19.6464495 },
    { lat: 48.4894883, lng: 19.6465001 },
    { lat: 48.4895398, lng: 19.6466437 },
    { lat: 48.4890917, lng: 19.6501755 },
    { lat: 48.4892694, lng: 19.6506707 },
    { lat: 48.4893454, lng: 19.6505816 },
    { lat: 48.4895638, lng: 19.6509284 },
    { lat: 48.4897886, lng: 19.6516873 },
    { lat: 48.4904937, lng: 19.6527855 },
    { lat: 48.4911989, lng: 19.6521329 },
    { lat: 48.4915612, lng: 19.6521443 },
    { lat: 48.4916683, lng: 19.651419 },
    { lat: 48.4917565, lng: 19.6511031 },
    { lat: 48.4920522, lng: 19.6506843 },
    { lat: 48.4922439, lng: 19.650636 },
    { lat: 48.4924186, lng: 19.6504889 },
    { lat: 48.4931366, lng: 19.6494181 },
    { lat: 48.4934555, lng: 19.6492176 },
    { lat: 48.4936783, lng: 19.6484609 },
    { lat: 48.4941102, lng: 19.6478222 },
    { lat: 48.4944248, lng: 19.6475265 },
    { lat: 48.4947253, lng: 19.6474191 },
    { lat: 48.4949372, lng: 19.647244 },
    { lat: 48.4952238, lng: 19.6467018 },
    { lat: 48.4953754, lng: 19.6467389 },
    { lat: 48.4956045, lng: 19.6458234 },
    { lat: 48.4959635, lng: 19.6461872 },
    { lat: 48.4959767, lng: 19.6463549 },
    { lat: 48.496735, lng: 19.6466818 },
    { lat: 48.4972699, lng: 19.6470973 },
    { lat: 48.4979143, lng: 19.6486441 },
    { lat: 48.4981824, lng: 19.6486417 },
    { lat: 48.498397, lng: 19.6476545 },
    { lat: 48.4981863, lng: 19.6469295 },
    { lat: 48.4983623, lng: 19.645732 },
    { lat: 48.4982676, lng: 19.6445327 },
    { lat: 48.4981491, lng: 19.6442769 },
    { lat: 48.4984385, lng: 19.6434263 },
    { lat: 48.4986551, lng: 19.6434333 },
    { lat: 48.4989783, lng: 19.6436273 },
    { lat: 48.4993757, lng: 19.6436941 },
    { lat: 48.4997911, lng: 19.6433877 },
    { lat: 48.5001227, lng: 19.6432422 },
    { lat: 48.5009722, lng: 19.6436383 },
    { lat: 48.5011276, lng: 19.6445875 },
    { lat: 48.5011632, lng: 19.6462257 },
    { lat: 48.5012856, lng: 19.6469394 },
    { lat: 48.5015639, lng: 19.6480164 },
    { lat: 48.5018788, lng: 19.6491521 },
    { lat: 48.5026097, lng: 19.6484956 },
    { lat: 48.5028611, lng: 19.647895 },
    { lat: 48.5034693, lng: 19.6460404 },
    { lat: 48.5037368, lng: 19.6456251 },
    { lat: 48.5044314, lng: 19.6442878 },
    { lat: 48.5046656, lng: 19.6436328 },
    { lat: 48.5050309, lng: 19.6430622 },
    { lat: 48.5050648, lng: 19.6428661 },
    { lat: 48.505518, lng: 19.6421454 },
    { lat: 48.506195, lng: 19.6406915 },
    { lat: 48.5070379, lng: 19.6400475 },
    { lat: 48.5072406, lng: 19.6393694 },
    { lat: 48.5078501, lng: 19.6381401 },
    { lat: 48.5085974, lng: 19.6388736 },
    { lat: 48.5102212, lng: 19.6401973 },
    { lat: 48.510556, lng: 19.6402753 },
    { lat: 48.5117139, lng: 19.6398923 },
    { lat: 48.5122668, lng: 19.6396058 },
    { lat: 48.512691, lng: 19.6392959 },
    { lat: 48.5131162, lng: 19.6388551 },
    { lat: 48.514532, lng: 19.6381162 },
    { lat: 48.5151499, lng: 19.6377228 },
    { lat: 48.5149371, lng: 19.6374567 },
    { lat: 48.5152297, lng: 19.6356988 },
    { lat: 48.515636, lng: 19.6352375 },
    { lat: 48.5160838, lng: 19.6345761 },
    { lat: 48.516955, lng: 19.6328066 },
    { lat: 48.5172518, lng: 19.6317935 },
    { lat: 48.5175918, lng: 19.6313126 },
    { lat: 48.5178954, lng: 19.6297532 },
    { lat: 48.5178364, lng: 19.6288728 },
    { lat: 48.517897, lng: 19.6280186 },
    { lat: 48.5180164, lng: 19.6273698 },
    { lat: 48.518697, lng: 19.6261499 },
    { lat: 48.519019, lng: 19.625704 },
  ],
  Kalinovo: [
    { lat: 48.399794, lng: 19.671618 },
    { lat: 48.3996282, lng: 19.6715531 },
    { lat: 48.398373, lng: 19.6718534 },
    { lat: 48.3978809, lng: 19.6722614 },
    { lat: 48.3957808, lng: 19.6736208 },
    { lat: 48.3934595, lng: 19.6748714 },
    { lat: 48.3904854, lng: 19.6778201 },
    { lat: 48.3893914, lng: 19.6786508 },
    { lat: 48.3882057, lng: 19.6793717 },
    { lat: 48.3858849, lng: 19.680235 },
    { lat: 48.37999, lng: 19.6826272 },
    { lat: 48.3797423, lng: 19.6818544 },
    { lat: 48.376559, lng: 19.6833791 },
    { lat: 48.3768614, lng: 19.6863051 },
    { lat: 48.3770066, lng: 19.6871329 },
    { lat: 48.3696065, lng: 19.6902124 },
    { lat: 48.3692812, lng: 19.6904202 },
    { lat: 48.3691233, lng: 19.6906421 },
    { lat: 48.3690639, lng: 19.6908096 },
    { lat: 48.3686401, lng: 19.6920052 },
    { lat: 48.3683108, lng: 19.6925008 },
    { lat: 48.3680586, lng: 19.6927332 },
    { lat: 48.3677522, lng: 19.6928623 },
    { lat: 48.3672215, lng: 19.6927571 },
    { lat: 48.365857, lng: 19.691961 },
    { lat: 48.365838, lng: 19.692029 },
    { lat: 48.365821, lng: 19.692087 },
    { lat: 48.365666, lng: 19.692627 },
    { lat: 48.365666, lng: 19.692698 },
    { lat: 48.365666, lng: 19.69277 },
    { lat: 48.365701, lng: 19.69373 },
    { lat: 48.365725, lng: 19.693866 },
    { lat: 48.365927, lng: 19.695006 },
    { lat: 48.365941, lng: 19.695073 },
    { lat: 48.365992, lng: 19.695063 },
    { lat: 48.366009, lng: 19.695297 },
    { lat: 48.366012, lng: 19.695342 },
    { lat: 48.366033, lng: 19.695653 },
    { lat: 48.366032, lng: 19.696531 },
    { lat: 48.366031, lng: 19.696654 },
    { lat: 48.365791, lng: 19.697319 },
    { lat: 48.365686, lng: 19.698235 },
    { lat: 48.365695, lng: 19.698423 },
    { lat: 48.365964, lng: 19.69963 },
    { lat: 48.365963, lng: 19.699936 },
    { lat: 48.36574, lng: 19.700846 },
    { lat: 48.365672, lng: 19.701513 },
    { lat: 48.365512, lng: 19.702623 },
    { lat: 48.365325, lng: 19.703539 },
    { lat: 48.365286, lng: 19.703764 },
    { lat: 48.365268, lng: 19.703856 },
    { lat: 48.3649, lng: 19.705913 },
    { lat: 48.364888, lng: 19.707119 },
    { lat: 48.364879, lng: 19.707325 },
    { lat: 48.364865, lng: 19.707658 },
    { lat: 48.364858, lng: 19.707673 },
    { lat: 48.364847, lng: 19.707713 },
    { lat: 48.364809, lng: 19.707845 },
    { lat: 48.364804, lng: 19.707865 },
    { lat: 48.364801, lng: 19.707893 },
    { lat: 48.364723, lng: 19.708517 },
    { lat: 48.364535, lng: 19.709657 },
    { lat: 48.36448, lng: 19.709903 },
    { lat: 48.364262, lng: 19.710801 },
    { lat: 48.364226, lng: 19.711269 },
    { lat: 48.364215, lng: 19.711558 },
    { lat: 48.364185, lng: 19.71238 },
    { lat: 48.364164, lng: 19.71296 },
    { lat: 48.364356, lng: 19.714019 },
    { lat: 48.364992, lng: 19.715708 },
    { lat: 48.365305, lng: 19.716539 },
    { lat: 48.366238, lng: 19.717756 },
    { lat: 48.367422, lng: 19.718798 },
    { lat: 48.367673, lng: 19.720156 },
    { lat: 48.367998, lng: 19.72187 },
    { lat: 48.368123, lng: 19.722639 },
    { lat: 48.368255, lng: 19.72335 },
    { lat: 48.368421, lng: 19.724241 },
    { lat: 48.368609, lng: 19.724812 },
    { lat: 48.368884, lng: 19.72565 },
    { lat: 48.368347, lng: 19.726207 },
    { lat: 48.367846, lng: 19.726995 },
    { lat: 48.367433, lng: 19.727709 },
    { lat: 48.366994, lng: 19.728645 },
    { lat: 48.366579, lng: 19.729208 },
    { lat: 48.366117, lng: 19.730322 },
    { lat: 48.365754, lng: 19.731317 },
    { lat: 48.365469, lng: 19.731849 },
    { lat: 48.365315, lng: 19.732136 },
    { lat: 48.364533, lng: 19.73357 },
    { lat: 48.364976, lng: 19.734159 },
    { lat: 48.365326, lng: 19.734628 },
    { lat: 48.365389, lng: 19.734726 },
    { lat: 48.365584, lng: 19.735038 },
    { lat: 48.365733, lng: 19.735182 },
    { lat: 48.366069, lng: 19.73562 },
    { lat: 48.366227, lng: 19.735826 },
    { lat: 48.366272, lng: 19.735963 },
    { lat: 48.366514, lng: 19.736154 },
    { lat: 48.366621, lng: 19.736272 },
    { lat: 48.366936, lng: 19.736626 },
    { lat: 48.366962, lng: 19.736776 },
    { lat: 48.367072, lng: 19.736867 },
    { lat: 48.367124, lng: 19.73691 },
    { lat: 48.367256, lng: 19.73702 },
    { lat: 48.368128, lng: 19.737707 },
    { lat: 48.368197, lng: 19.737761 },
    { lat: 48.368197, lng: 19.737968 },
    { lat: 48.36819, lng: 19.738109 },
    { lat: 48.368042, lng: 19.738509 },
    { lat: 48.367988, lng: 19.738878 },
    { lat: 48.367945, lng: 19.739109 },
    { lat: 48.367921, lng: 19.739183 },
    { lat: 48.367864, lng: 19.739273 },
    { lat: 48.367757, lng: 19.739345 },
    { lat: 48.367698, lng: 19.739338 },
    { lat: 48.367468, lng: 19.739214 },
    { lat: 48.367412, lng: 19.73906 },
    { lat: 48.36727, lng: 19.738951 },
    { lat: 48.367182, lng: 19.738934 },
    { lat: 48.367099, lng: 19.738968 },
    { lat: 48.366989, lng: 19.738998 },
    { lat: 48.366751, lng: 19.739298 },
    { lat: 48.366734, lng: 19.739307 },
    { lat: 48.366595, lng: 19.739324 },
    { lat: 48.366502, lng: 19.739289 },
    { lat: 48.366412, lng: 19.739239 },
    { lat: 48.366161, lng: 19.739109 },
    { lat: 48.36602, lng: 19.738968 },
    { lat: 48.365895, lng: 19.738904 },
    { lat: 48.365825, lng: 19.738873 },
    { lat: 48.365804, lng: 19.738879 },
    { lat: 48.365785, lng: 19.738879 },
    { lat: 48.365532, lng: 19.739169 },
    { lat: 48.365336, lng: 19.739669 },
    { lat: 48.36531, lng: 19.739807 },
    { lat: 48.365235, lng: 19.740179 },
    { lat: 48.365208, lng: 19.74027 },
    { lat: 48.365175, lng: 19.740611 },
    { lat: 48.365163, lng: 19.740759 },
    { lat: 48.365143, lng: 19.740861 },
    { lat: 48.365096, lng: 19.741049 },
    { lat: 48.365193, lng: 19.741178 },
    { lat: 48.365211, lng: 19.741201 },
    { lat: 48.365266, lng: 19.74128 },
    { lat: 48.365528, lng: 19.741649 },
    { lat: 48.365647, lng: 19.741816 },
    { lat: 48.365915, lng: 19.742194 },
    { lat: 48.366052, lng: 19.742387 },
    { lat: 48.366108, lng: 19.74246 },
    { lat: 48.366127, lng: 19.742482 },
    { lat: 48.366157, lng: 19.742516 },
    { lat: 48.36617, lng: 19.742532 },
    { lat: 48.367315, lng: 19.744137 },
    { lat: 48.368517, lng: 19.745784 },
    { lat: 48.368827, lng: 19.746226 },
    { lat: 48.368839, lng: 19.746241 },
    { lat: 48.368858, lng: 19.746269 },
    { lat: 48.369057, lng: 19.746551 },
    { lat: 48.369239, lng: 19.746861 },
    { lat: 48.369263, lng: 19.746886 },
    { lat: 48.369284, lng: 19.746923 },
    { lat: 48.369325, lng: 19.746993 },
    { lat: 48.369399, lng: 19.747115 },
    { lat: 48.369471, lng: 19.74724 },
    { lat: 48.369516, lng: 19.747305 },
    { lat: 48.369594, lng: 19.747432 },
    { lat: 48.369665, lng: 19.747547 },
    { lat: 48.369734, lng: 19.747658 },
    { lat: 48.369764, lng: 19.747711 },
    { lat: 48.369805, lng: 19.747774 },
    { lat: 48.369868, lng: 19.74788 },
    { lat: 48.369929, lng: 19.74798 },
    { lat: 48.369942, lng: 19.748004 },
    { lat: 48.369968, lng: 19.74805 },
    { lat: 48.369984, lng: 19.74808 },
    { lat: 48.370387, lng: 19.749338 },
    { lat: 48.370625, lng: 19.750088 },
    { lat: 48.371051, lng: 19.751366 },
    { lat: 48.371117, lng: 19.751569 },
    { lat: 48.371944, lng: 19.751114 },
    { lat: 48.37205, lng: 19.751065 },
    { lat: 48.372157, lng: 19.751011 },
    { lat: 48.372465, lng: 19.750865 },
    { lat: 48.372811, lng: 19.750684 },
    { lat: 48.372905, lng: 19.750639 },
    { lat: 48.373055, lng: 19.750563 },
    { lat: 48.373244, lng: 19.750461 },
    { lat: 48.37339, lng: 19.750384 },
    { lat: 48.373482, lng: 19.750333 },
    { lat: 48.373536, lng: 19.750303 },
    { lat: 48.373662, lng: 19.750243 },
    { lat: 48.373758, lng: 19.750198 },
    { lat: 48.37386, lng: 19.750142 },
    { lat: 48.373997, lng: 19.750069 },
    { lat: 48.374169, lng: 19.749979 },
    { lat: 48.374266, lng: 19.749926 },
    { lat: 48.374374, lng: 19.749871 },
    { lat: 48.374595, lng: 19.749761 },
    { lat: 48.374933, lng: 19.749622 },
    { lat: 48.375329, lng: 19.749454 },
    { lat: 48.375538, lng: 19.749377 },
    { lat: 48.375759, lng: 19.749289 },
    { lat: 48.376188, lng: 19.749095 },
    { lat: 48.377113, lng: 19.74868 },
    { lat: 48.377241, lng: 19.748617 },
    { lat: 48.378052, lng: 19.748265 },
    { lat: 48.37829, lng: 19.748228 },
    { lat: 48.378551, lng: 19.748193 },
    { lat: 48.378815, lng: 19.748169 },
    { lat: 48.379643, lng: 19.748097 },
    { lat: 48.380622, lng: 19.748089 },
    { lat: 48.380997, lng: 19.748345 },
    { lat: 48.38097, lng: 19.748681 },
    { lat: 48.380893, lng: 19.748968 },
    { lat: 48.380835, lng: 19.749851 },
    { lat: 48.380816, lng: 19.750136 },
    { lat: 48.380672, lng: 19.75143 },
    { lat: 48.380581, lng: 19.752078 },
    { lat: 48.380573, lng: 19.752136 },
    { lat: 48.380479, lng: 19.752808 },
    { lat: 48.38045, lng: 19.752818 },
    { lat: 48.38023, lng: 19.752897 },
    { lat: 48.380554, lng: 19.753748 },
    { lat: 48.380717, lng: 19.754343 },
    { lat: 48.380729, lng: 19.754385 },
    { lat: 48.380737, lng: 19.754412 },
    { lat: 48.381, lng: 19.755147 },
    { lat: 48.381043, lng: 19.755844 },
    { lat: 48.381175, lng: 19.756359 },
    { lat: 48.381229, lng: 19.75662 },
    { lat: 48.381515, lng: 19.757403 },
    { lat: 48.381578, lng: 19.757571 },
    { lat: 48.382254, lng: 19.758568 },
    { lat: 48.382562, lng: 19.759546 },
    { lat: 48.382605, lng: 19.760288 },
    { lat: 48.382575, lng: 19.760785 },
    { lat: 48.382133, lng: 19.762467 },
    { lat: 48.382025, lng: 19.763327 },
    { lat: 48.382042, lng: 19.76333 },
    { lat: 48.382001, lng: 19.764171 },
    { lat: 48.382005, lng: 19.764231 },
    { lat: 48.381992, lng: 19.764233 },
    { lat: 48.382077, lng: 19.764948 },
    { lat: 48.382161, lng: 19.766321 },
    { lat: 48.38206, lng: 19.76821 },
    { lat: 48.381899, lng: 19.769268 },
    { lat: 48.381871, lng: 19.769777 },
    { lat: 48.381748, lng: 19.77126 },
    { lat: 48.381581, lng: 19.772023 },
    { lat: 48.381346, lng: 19.772825 },
    { lat: 48.381297, lng: 19.773244 },
    { lat: 48.381127, lng: 19.774269 },
    { lat: 48.381057, lng: 19.774597 },
    { lat: 48.38078, lng: 19.775448 },
    { lat: 48.380706, lng: 19.775779 },
    { lat: 48.380564, lng: 19.776353 },
    { lat: 48.38022, lng: 19.777524 },
    { lat: 48.380071, lng: 19.77892 },
    { lat: 48.38002, lng: 19.77926 },
    { lat: 48.379981, lng: 19.779991 },
    { lat: 48.379988, lng: 19.780013 },
    { lat: 48.380003, lng: 19.780023 },
    { lat: 48.381841, lng: 19.781637 },
    { lat: 48.38196, lng: 19.78174 },
    { lat: 48.382508, lng: 19.782224 },
    { lat: 48.382478, lng: 19.782314 },
    { lat: 48.38247, lng: 19.782335 },
    { lat: 48.382749, lng: 19.782548 },
    { lat: 48.383044, lng: 19.782941 },
    { lat: 48.383127, lng: 19.782998 },
    { lat: 48.383212, lng: 19.783051 },
    { lat: 48.383511, lng: 19.783202 },
    { lat: 48.383583, lng: 19.783267 },
    { lat: 48.383664, lng: 19.783404 },
    { lat: 48.383729, lng: 19.78356 },
    { lat: 48.383775, lng: 19.783742 },
    { lat: 48.383843, lng: 19.784399 },
    { lat: 48.383929, lng: 19.784778 },
    { lat: 48.384036, lng: 19.78551 },
    { lat: 48.38419, lng: 19.786046 },
    { lat: 48.384366, lng: 19.786339 },
    { lat: 48.384566, lng: 19.786482 },
    { lat: 48.384857, lng: 19.786482 },
    { lat: 48.385384, lng: 19.786333 },
    { lat: 48.385917, lng: 19.786052 },
    { lat: 48.386344, lng: 19.785696 },
    { lat: 48.386673, lng: 19.785253 },
    { lat: 48.386819, lng: 19.785123 },
    { lat: 48.387014, lng: 19.784959 },
    { lat: 48.3882967, lng: 19.7845619 },
    { lat: 48.3899929, lng: 19.7844253 },
    { lat: 48.3911874, lng: 19.7847121 },
    { lat: 48.3919023, lng: 19.7849826 },
    { lat: 48.3927405, lng: 19.785209 },
    { lat: 48.3933055, lng: 19.7856327 },
    { lat: 48.3936776, lng: 19.7858163 },
    { lat: 48.394083, lng: 19.7862516 },
    { lat: 48.3947934, lng: 19.7867998 },
    { lat: 48.3951458, lng: 19.7872077 },
    { lat: 48.3953564, lng: 19.7873584 },
    { lat: 48.3956717, lng: 19.787431 },
    { lat: 48.3968405, lng: 19.7870568 },
    { lat: 48.3970755, lng: 19.7868924 },
    { lat: 48.3981545, lng: 19.7868086 },
    { lat: 48.3984832, lng: 19.7869068 },
    { lat: 48.3990856, lng: 19.7866897 },
    { lat: 48.4001138, lng: 19.7867853 },
    { lat: 48.4001474, lng: 19.7868564 },
    { lat: 48.4011953, lng: 19.7861126 },
    { lat: 48.4026485, lng: 19.7847776 },
    { lat: 48.4026235, lng: 19.7846968 },
    { lat: 48.4025832, lng: 19.7847249 },
    { lat: 48.4019281, lng: 19.7825078 },
    { lat: 48.4015506, lng: 19.7808927 },
    { lat: 48.4015948, lng: 19.7802862 },
    { lat: 48.4017084, lng: 19.7796655 },
    { lat: 48.4022938, lng: 19.7784277 },
    { lat: 48.4035464, lng: 19.7764521 },
    { lat: 48.4037279, lng: 19.7759413 },
    { lat: 48.4040102, lng: 19.7744169 },
    { lat: 48.4038418, lng: 19.7735631 },
    { lat: 48.4034193, lng: 19.7722573 },
    { lat: 48.4025221, lng: 19.7704623 },
    { lat: 48.4020993, lng: 19.7682028 },
    { lat: 48.4020099, lng: 19.7666647 },
    { lat: 48.402205, lng: 19.7654412 },
    { lat: 48.4022768, lng: 19.7652812 },
    { lat: 48.4035635, lng: 19.7632416 },
    { lat: 48.4045264, lng: 19.7613664 },
    { lat: 48.4045211, lng: 19.7604805 },
    { lat: 48.4044051, lng: 19.7588765 },
    { lat: 48.4043931, lng: 19.7582724 },
    { lat: 48.4035, lng: 19.757109 },
    { lat: 48.4027351, lng: 19.756475 },
    { lat: 48.401218, lng: 19.7557725 },
    { lat: 48.4004429, lng: 19.7551896 },
    { lat: 48.4002709, lng: 19.7549422 },
    { lat: 48.3992982, lng: 19.7528172 },
    { lat: 48.3979105, lng: 19.7493896 },
    { lat: 48.3982223, lng: 19.7489762 },
    { lat: 48.3987956, lng: 19.7485666 },
    { lat: 48.3996619, lng: 19.7472678 },
    { lat: 48.4006636, lng: 19.7458899 },
    { lat: 48.4008374, lng: 19.7453893 },
    { lat: 48.4011664, lng: 19.7447313 },
    { lat: 48.4019248, lng: 19.7438414 },
    { lat: 48.4021808, lng: 19.743262 },
    { lat: 48.402435, lng: 19.7431022 },
    { lat: 48.402782, lng: 19.7427161 },
    { lat: 48.4036748, lng: 19.7421432 },
    { lat: 48.4040959, lng: 19.741299 },
    { lat: 48.4045006, lng: 19.7398788 },
    { lat: 48.4052312, lng: 19.7378576 },
    { lat: 48.40542, lng: 19.7371639 },
    { lat: 48.4056852, lng: 19.7365111 },
    { lat: 48.4058694, lng: 19.7356335 },
    { lat: 48.4057128, lng: 19.7347963 },
    { lat: 48.4056087, lng: 19.7338975 },
    { lat: 48.4053112, lng: 19.7331631 },
    { lat: 48.4047738, lng: 19.7322766 },
    { lat: 48.4046836, lng: 19.7317161 },
    { lat: 48.4046715, lng: 19.7309337 },
    { lat: 48.4048345, lng: 19.7304485 },
    { lat: 48.4045599, lng: 19.7302239 },
    { lat: 48.404058, lng: 19.7296558 },
    { lat: 48.4038051, lng: 19.72929 },
    { lat: 48.4033686, lng: 19.7284668 },
    { lat: 48.4023212, lng: 19.7268786 },
    { lat: 48.4021804, lng: 19.7264517 },
    { lat: 48.4022503, lng: 19.7263553 },
    { lat: 48.4027142, lng: 19.725935 },
    { lat: 48.4031725, lng: 19.725426 },
    { lat: 48.4032945, lng: 19.7252556 },
    { lat: 48.4033688, lng: 19.7250255 },
    { lat: 48.4036394, lng: 19.7252338 },
    { lat: 48.4038146, lng: 19.7252162 },
    { lat: 48.4038702, lng: 19.7250755 },
    { lat: 48.4040166, lng: 19.7250491 },
    { lat: 48.4042932, lng: 19.7246806 },
    { lat: 48.404389, lng: 19.7246792 },
    { lat: 48.4044278, lng: 19.7245182 },
    { lat: 48.4046444, lng: 19.724572 },
    { lat: 48.4051941, lng: 19.7244406 },
    { lat: 48.4053168, lng: 19.7246148 },
    { lat: 48.4053859, lng: 19.7250526 },
    { lat: 48.4055138, lng: 19.7254972 },
    { lat: 48.4056453, lng: 19.725646 },
    { lat: 48.4059166, lng: 19.725661 },
    { lat: 48.4060741, lng: 19.7258846 },
    { lat: 48.4066955, lng: 19.724912 },
    { lat: 48.4068733, lng: 19.7247282 },
    { lat: 48.4069761, lng: 19.7240015 },
    { lat: 48.4075394, lng: 19.7229865 },
    { lat: 48.4088502, lng: 19.7210715 },
    { lat: 48.4096367, lng: 19.7200314 },
    { lat: 48.4096973, lng: 19.7200498 },
    { lat: 48.4098939, lng: 19.7198637 },
    { lat: 48.4099666, lng: 19.7198997 },
    { lat: 48.4100852, lng: 19.7198321 },
    { lat: 48.4102503, lng: 19.7195694 },
    { lat: 48.4104739, lng: 19.7193523 },
    { lat: 48.4108173, lng: 19.7183843 },
    { lat: 48.4111582, lng: 19.7179685 },
    { lat: 48.4118794, lng: 19.7168048 },
    { lat: 48.4124307, lng: 19.7161266 },
    { lat: 48.4128333, lng: 19.7155297 },
    { lat: 48.4138725, lng: 19.7138466 },
    { lat: 48.414466, lng: 19.7132832 },
    { lat: 48.4151445, lng: 19.7127564 },
    { lat: 48.4160554, lng: 19.7122101 },
    { lat: 48.4170548, lng: 19.7111721 },
    { lat: 48.4184289, lng: 19.7103273 },
    { lat: 48.4207973, lng: 19.7094654 },
    { lat: 48.4218542, lng: 19.708635 },
    { lat: 48.4229445, lng: 19.707184 },
    { lat: 48.4242195, lng: 19.7059892 },
    { lat: 48.4250896, lng: 19.7059938 },
    { lat: 48.4254181, lng: 19.7060324 },
    { lat: 48.4255721, lng: 19.7061247 },
    { lat: 48.4262168, lng: 19.7061066 },
    { lat: 48.4273501, lng: 19.7063274 },
    { lat: 48.4276397, lng: 19.7064032 },
    { lat: 48.4277531, lng: 19.7061942 },
    { lat: 48.4278544, lng: 19.7056589 },
    { lat: 48.4278634, lng: 19.7053323 },
    { lat: 48.4279583, lng: 19.7050567 },
    { lat: 48.4279714, lng: 19.704795 },
    { lat: 48.4281336, lng: 19.7044661 },
    { lat: 48.4281387, lng: 19.7036709 },
    { lat: 48.4281122, lng: 19.7034592 },
    { lat: 48.4280404, lng: 19.7033154 },
    { lat: 48.4280546, lng: 19.7031511 },
    { lat: 48.4283718, lng: 19.7025625 },
    { lat: 48.4283684, lng: 19.7023595 },
    { lat: 48.4283143, lng: 19.7022791 },
    { lat: 48.4283972, lng: 19.7020207 },
    { lat: 48.4282935, lng: 19.7018406 },
    { lat: 48.4284995, lng: 19.7016091 },
    { lat: 48.4284287, lng: 19.701287 },
    { lat: 48.4282781, lng: 19.7010404 },
    { lat: 48.4283517, lng: 19.7008963 },
    { lat: 48.4283803, lng: 19.7006792 },
    { lat: 48.4283306, lng: 19.7003014 },
    { lat: 48.4284595, lng: 19.700033 },
    { lat: 48.4283852, lng: 19.6998322 },
    { lat: 48.4283051, lng: 19.699351 },
    { lat: 48.4283261, lng: 19.6989388 },
    { lat: 48.4282778, lng: 19.6988124 },
    { lat: 48.4283537, lng: 19.6987739 },
    { lat: 48.4283714, lng: 19.6985681 },
    { lat: 48.4284261, lng: 19.6984547 },
    { lat: 48.4283812, lng: 19.6981059 },
    { lat: 48.4284261, lng: 19.6977462 },
    { lat: 48.4283856, lng: 19.6972217 },
    { lat: 48.4284875, lng: 19.6964735 },
    { lat: 48.4286035, lng: 19.6961947 },
    { lat: 48.4285961, lng: 19.6960301 },
    { lat: 48.4287542, lng: 19.6953312 },
    { lat: 48.4286909, lng: 19.6952513 },
    { lat: 48.4288413, lng: 19.6946327 },
    { lat: 48.4288224, lng: 19.6944373 },
    { lat: 48.4288975, lng: 19.6943276 },
    { lat: 48.4288885, lng: 19.6942047 },
    { lat: 48.4290569, lng: 19.6939102 },
    { lat: 48.429034, lng: 19.6937522 },
    { lat: 48.4289584, lng: 19.6936239 },
    { lat: 48.4289341, lng: 19.6933826 },
    { lat: 48.4289865, lng: 19.6932706 },
    { lat: 48.4293612, lng: 19.6911697 },
    { lat: 48.4293779, lng: 19.6904836 },
    { lat: 48.4294372, lng: 19.690346 },
    { lat: 48.4295212, lng: 19.6903354 },
    { lat: 48.4295717, lng: 19.6901344 },
    { lat: 48.4296768, lng: 19.6900135 },
    { lat: 48.4296399, lng: 19.6899175 },
    { lat: 48.4296612, lng: 19.6893367 },
    { lat: 48.4297323, lng: 19.6889119 },
    { lat: 48.4298331, lng: 19.6886463 },
    { lat: 48.4298345, lng: 19.6882516 },
    { lat: 48.4299069, lng: 19.6882539 },
    { lat: 48.4298706, lng: 19.6879964 },
    { lat: 48.4301187, lng: 19.6874729 },
    { lat: 48.4301076, lng: 19.6872795 },
    { lat: 48.4302516, lng: 19.6869932 },
    { lat: 48.4301958, lng: 19.6868328 },
    { lat: 48.4302696, lng: 19.6867931 },
    { lat: 48.430213, lng: 19.6866115 },
    { lat: 48.430365, lng: 19.6865351 },
    { lat: 48.4303832, lng: 19.686183 },
    { lat: 48.4303096, lng: 19.6857747 },
    { lat: 48.4303966, lng: 19.6856552 },
    { lat: 48.4304885, lng: 19.6858204 },
    { lat: 48.430559, lng: 19.6848802 },
    { lat: 48.4310473, lng: 19.6835773 },
    { lat: 48.4313755, lng: 19.6829828 },
    { lat: 48.4317426, lng: 19.6817961 },
    { lat: 48.4318581, lng: 19.6816343 },
    { lat: 48.4318759, lng: 19.681503 },
    { lat: 48.4317463, lng: 19.6813788 },
    { lat: 48.4317341, lng: 19.6813066 },
    { lat: 48.4320793, lng: 19.6805855 },
    { lat: 48.4320266, lng: 19.6801899 },
    { lat: 48.4320856, lng: 19.6800721 },
    { lat: 48.4320715, lng: 19.6798841 },
    { lat: 48.4321529, lng: 19.6797889 },
    { lat: 48.4321061, lng: 19.6795964 },
    { lat: 48.4321285, lng: 19.679371 },
    { lat: 48.4322821, lng: 19.679151 },
    { lat: 48.4322361, lng: 19.67888 },
    { lat: 48.4322919, lng: 19.6788186 },
    { lat: 48.4322741, lng: 19.6787204 },
    { lat: 48.432361, lng: 19.6783994 },
    { lat: 48.4322926, lng: 19.6783343 },
    { lat: 48.4322468, lng: 19.6780912 },
    { lat: 48.4323547, lng: 19.6776067 },
    { lat: 48.4323583, lng: 19.6773611 },
    { lat: 48.4323017, lng: 19.6770787 },
    { lat: 48.4323779, lng: 19.6758611 },
    { lat: 48.4323353, lng: 19.6752914 },
    { lat: 48.4327304, lng: 19.6738519 },
    { lat: 48.4328787, lng: 19.6729112 },
    { lat: 48.4342629, lng: 19.6734762 },
    { lat: 48.4363945, lng: 19.6729688 },
    { lat: 48.4393572, lng: 19.6691879 },
    { lat: 48.441375, lng: 19.6709349 },
    { lat: 48.4414026, lng: 19.670952 },
    { lat: 48.4412203, lng: 19.670177 },
    { lat: 48.4410099, lng: 19.6687323 },
    { lat: 48.440644, lng: 19.6672847 },
    { lat: 48.4402824, lng: 19.6663828 },
    { lat: 48.4400003, lng: 19.6661744 },
    { lat: 48.4393017, lng: 19.6638926 },
    { lat: 48.4400228, lng: 19.6635141 },
    { lat: 48.4403456, lng: 19.6632046 },
    { lat: 48.440411, lng: 19.6616993 },
    { lat: 48.4402811, lng: 19.6603193 },
    { lat: 48.4403457, lng: 19.6599483 },
    { lat: 48.4403253, lng: 19.6591242 },
    { lat: 48.4404056, lng: 19.6579748 },
    { lat: 48.4407461, lng: 19.656203 },
    { lat: 48.4408221, lng: 19.6554632 },
    { lat: 48.4409143, lng: 19.655309 },
    { lat: 48.4403836, lng: 19.6542115 },
    { lat: 48.4400729, lng: 19.6534141 },
    { lat: 48.4397236, lng: 19.6528152 },
    { lat: 48.4393618, lng: 19.6525149 },
    { lat: 48.4371688, lng: 19.6563125 },
    { lat: 48.4371838, lng: 19.656492 },
    { lat: 48.4367771, lng: 19.6577689 },
    { lat: 48.4368154, lng: 19.658447 },
    { lat: 48.4368763, lng: 19.6584199 },
    { lat: 48.4369029, lng: 19.6586134 },
    { lat: 48.4368223, lng: 19.6586472 },
    { lat: 48.436893, lng: 19.6597734 },
    { lat: 48.436127, lng: 19.6592988 },
    { lat: 48.4359774, lng: 19.6581788 },
    { lat: 48.4359599, lng: 19.6577381 },
    { lat: 48.4360329, lng: 19.6565069 },
    { lat: 48.4359671, lng: 19.6549469 },
    { lat: 48.4355938, lng: 19.6535638 },
    { lat: 48.4355493, lng: 19.6530786 },
    { lat: 48.4355956, lng: 19.6523043 },
    { lat: 48.4357146, lng: 19.6518447 },
    { lat: 48.4361166, lng: 19.6508893 },
    { lat: 48.4362479, lng: 19.6503015 },
    { lat: 48.4369973, lng: 19.6491077 },
    { lat: 48.4374293, lng: 19.6486911 },
    { lat: 48.4377672, lng: 19.6484867 },
    { lat: 48.4383865, lng: 19.6484762 },
    { lat: 48.4395482, lng: 19.6483021 },
    { lat: 48.4400002, lng: 19.6484562 },
    { lat: 48.4400735, lng: 19.648531 },
    { lat: 48.4400509, lng: 19.6488062 },
    { lat: 48.4398889, lng: 19.6494125 },
    { lat: 48.4400435, lng: 19.6496668 },
    { lat: 48.4401397, lng: 19.6496496 },
    { lat: 48.4406584, lng: 19.6498359 },
    { lat: 48.4412335, lng: 19.6498189 },
    { lat: 48.4416671, lng: 19.6499401 },
    { lat: 48.4420312, lng: 19.6502387 },
    { lat: 48.4426602, lng: 19.6504731 },
    { lat: 48.4430076, lng: 19.6507269 },
    { lat: 48.4436499, lng: 19.6509536 },
    { lat: 48.4436289, lng: 19.6506129 },
    { lat: 48.443986, lng: 19.6499136 },
    { lat: 48.444174, lng: 19.6499083 },
    { lat: 48.4442515, lng: 19.649752 },
    { lat: 48.4444133, lng: 19.6490161 },
    { lat: 48.4445396, lng: 19.6479205 },
    { lat: 48.4449348, lng: 19.6477368 },
    { lat: 48.4448723, lng: 19.647027 },
    { lat: 48.4449477, lng: 19.6465977 },
    { lat: 48.4449205, lng: 19.6461735 },
    { lat: 48.4447489, lng: 19.6459656 },
    { lat: 48.4446398, lng: 19.6454955 },
    { lat: 48.4444133, lng: 19.6449692 },
    { lat: 48.4441191, lng: 19.6448441 },
    { lat: 48.4437857, lng: 19.6448245 },
    { lat: 48.4436035, lng: 19.6446805 },
    { lat: 48.4433995, lng: 19.6446534 },
    { lat: 48.4433564, lng: 19.6444204 },
    { lat: 48.443192, lng: 19.6444237 },
    { lat: 48.4429906, lng: 19.6443317 },
    { lat: 48.4429576, lng: 19.6441491 },
    { lat: 48.442997, lng: 19.6440024 },
    { lat: 48.4429605, lng: 19.6439423 },
    { lat: 48.4426428, lng: 19.6439183 },
    { lat: 48.4425665, lng: 19.6440474 },
    { lat: 48.4424944, lng: 19.6440516 },
    { lat: 48.4422961, lng: 19.6439472 },
    { lat: 48.4419786, lng: 19.6436826 },
    { lat: 48.4417994, lng: 19.6434315 },
    { lat: 48.4417698, lng: 19.6430221 },
    { lat: 48.4416935, lng: 19.6427542 },
    { lat: 48.4416083, lng: 19.6427668 },
    { lat: 48.441462, lng: 19.6425698 },
    { lat: 48.4410591, lng: 19.6423071 },
    { lat: 48.4410175, lng: 19.6421602 },
    { lat: 48.4408946, lng: 19.6421358 },
    { lat: 48.4408711, lng: 19.6420612 },
    { lat: 48.4407857, lng: 19.6421203 },
    { lat: 48.4406631, lng: 19.6420722 },
    { lat: 48.4405056, lng: 19.6418316 },
    { lat: 48.4400726, lng: 19.6419472 },
    { lat: 48.4398563, lng: 19.6418479 },
    { lat: 48.4397011, lng: 19.6421078 },
    { lat: 48.4395225, lng: 19.64224 },
    { lat: 48.4393614, lng: 19.6421922 },
    { lat: 48.4392823, lng: 19.6422403 },
    { lat: 48.4392153, lng: 19.6421531 },
    { lat: 48.4389919, lng: 19.6422725 },
    { lat: 48.4387138, lng: 19.6422008 },
    { lat: 48.4386198, lng: 19.6423413 },
    { lat: 48.4383034, lng: 19.6423665 },
    { lat: 48.438194, lng: 19.6424642 },
    { lat: 48.4380443, lng: 19.6424962 },
    { lat: 48.4380423, lng: 19.6426234 },
    { lat: 48.4378113, lng: 19.6428006 },
    { lat: 48.4377354, lng: 19.6429382 },
    { lat: 48.4375858, lng: 19.6429475 },
    { lat: 48.4374716, lng: 19.6427391 },
    { lat: 48.437244, lng: 19.6427669 },
    { lat: 48.437027, lng: 19.6429477 },
    { lat: 48.4368224, lng: 19.6432595 },
    { lat: 48.436421, lng: 19.6432036 },
    { lat: 48.4363098, lng: 19.6433338 },
    { lat: 48.436146, lng: 19.643325 },
    { lat: 48.4358996, lng: 19.6437089 },
    { lat: 48.4359392, lng: 19.6438638 },
    { lat: 48.4358979, lng: 19.6441203 },
    { lat: 48.4357964, lng: 19.6442222 },
    { lat: 48.4358154, lng: 19.6442916 },
    { lat: 48.4356141, lng: 19.6447741 },
    { lat: 48.4353648, lng: 19.6449901 },
    { lat: 48.4353446, lng: 19.6451185 },
    { lat: 48.4351596, lng: 19.6453609 },
    { lat: 48.4350098, lng: 19.6457633 },
    { lat: 48.4347491, lng: 19.646254 },
    { lat: 48.4343311, lng: 19.6466542 },
    { lat: 48.4342389, lng: 19.6468627 },
    { lat: 48.4342577, lng: 19.647101 },
    { lat: 48.4339831, lng: 19.6482321 },
    { lat: 48.4338668, lng: 19.6484544 },
    { lat: 48.4337528, lng: 19.6488989 },
    { lat: 48.4336157, lng: 19.6492045 },
    { lat: 48.4336072, lng: 19.6499158 },
    { lat: 48.4329331, lng: 19.6514821 },
    { lat: 48.4322664, lng: 19.6509188 },
    { lat: 48.4317484, lng: 19.6497528 },
    { lat: 48.430939, lng: 19.648769 },
    { lat: 48.4300119, lng: 19.6474276 },
    { lat: 48.4292807, lng: 19.647132 },
    { lat: 48.428703, lng: 19.647177 },
    { lat: 48.428373, lng: 19.64781 },
    { lat: 48.428377, lng: 19.647835 },
    { lat: 48.427645, lng: 19.648855 },
    { lat: 48.427631, lng: 19.648839 },
    { lat: 48.427252, lng: 19.649741 },
    { lat: 48.42701, lng: 19.649864 },
    { lat: 48.42679, lng: 19.650017 },
    { lat: 48.426439, lng: 19.650433 },
    { lat: 48.426249, lng: 19.650718 },
    { lat: 48.426069, lng: 19.651209 },
    { lat: 48.42571, lng: 19.651459 },
    { lat: 48.4255996, lng: 19.6516685 },
    { lat: 48.425457, lng: 19.652022 },
    { lat: 48.425131, lng: 19.652546 },
    { lat: 48.424791, lng: 19.653159 },
    { lat: 48.4247477, lng: 19.6532939 },
    { lat: 48.424478, lng: 19.654134 },
    { lat: 48.424139, lng: 19.655058 },
    { lat: 48.423688, lng: 19.656244 },
    { lat: 48.423788, lng: 19.657857 },
    { lat: 48.423432, lng: 19.659535 },
    { lat: 48.422784, lng: 19.661026 },
    { lat: 48.422413, lng: 19.661734 },
    { lat: 48.421797, lng: 19.662172 },
    { lat: 48.421038, lng: 19.663129 },
    { lat: 48.420428, lng: 19.663997 },
    { lat: 48.420076, lng: 19.6649 },
    { lat: 48.419972, lng: 19.665345 },
    { lat: 48.419881, lng: 19.666135 },
    { lat: 48.419459, lng: 19.667224 },
    { lat: 48.418993, lng: 19.668346 },
    { lat: 48.418389, lng: 19.669733 },
    { lat: 48.417182, lng: 19.671495 },
    { lat: 48.416889, lng: 19.671877 },
    { lat: 48.415771, lng: 19.67272 },
    { lat: 48.415291, lng: 19.673037 },
    { lat: 48.414769, lng: 19.673204 },
    { lat: 48.414407, lng: 19.673416 },
    { lat: 48.414141, lng: 19.673958 },
    { lat: 48.413319, lng: 19.674378 },
    { lat: 48.412854, lng: 19.674848 },
    { lat: 48.412479, lng: 19.675009 },
    { lat: 48.411971, lng: 19.675808 },
    { lat: 48.411406, lng: 19.676881 },
    { lat: 48.411219, lng: 19.677108 },
    { lat: 48.411037, lng: 19.677327 },
    { lat: 48.410855, lng: 19.677548 },
    { lat: 48.410419, lng: 19.678103 },
    { lat: 48.41008, lng: 19.678389 },
    { lat: 48.409988, lng: 19.678466 },
    { lat: 48.40967, lng: 19.678674 },
    { lat: 48.40949, lng: 19.678733 },
    { lat: 48.409285, lng: 19.678712 },
    { lat: 48.40872, lng: 19.678617 },
    { lat: 48.408205, lng: 19.678665 },
    { lat: 48.408014, lng: 19.678623 },
    { lat: 48.407696, lng: 19.678702 },
    { lat: 48.407491, lng: 19.678837 },
    { lat: 48.407219, lng: 19.678701 },
    { lat: 48.406489, lng: 19.67922 },
    { lat: 48.406235, lng: 19.679368 },
    { lat: 48.405962, lng: 19.679367 },
    { lat: 48.405696, lng: 19.679202 },
    { lat: 48.405456, lng: 19.678257 },
    { lat: 48.405101, lng: 19.678104 },
    { lat: 48.404633, lng: 19.677716 },
    { lat: 48.404192, lng: 19.67774 },
    { lat: 48.404049, lng: 19.677593 },
    { lat: 48.403917, lng: 19.677577 },
    { lat: 48.40387, lng: 19.677427 },
    { lat: 48.403542, lng: 19.677559 },
    { lat: 48.40337, lng: 19.677526 },
    { lat: 48.40322, lng: 19.677659 },
    { lat: 48.402841, lng: 19.67773 },
    { lat: 48.402832, lng: 19.67773 },
    { lat: 48.402824, lng: 19.677729 },
    { lat: 48.402616, lng: 19.677295 },
    { lat: 48.402606, lng: 19.677307 },
    { lat: 48.401838, lng: 19.676378 },
    { lat: 48.400903, lng: 19.67525 },
    { lat: 48.400879, lng: 19.67522 },
    { lat: 48.401533, lng: 19.674531 },
    { lat: 48.401511, lng: 19.674496 },
    { lat: 48.400642, lng: 19.673043 },
    { lat: 48.399794, lng: 19.671618 },
  ],
};

export default UnitsPoltár;
