import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import clsx from "clsx";
// import { useMediaQuery } from "usehooks-ts";

export default function ArrangeVisit({ inputValues, setInputValues, size }) {
  // const isSmallDevice = useMediaQuery("only screen and (max-width : 640px)");

  const isWeekend = (date) => {
    const day = date.day();
    return day === 0 || day === 6;
  };

  function onChange(e) {
    setInputValues({ ...inputValues, arrangeVisit: e });
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div
        className={clsx("text-start px-4 w-full", {
          // "flex flex-col w-1/2": !isSmallDevice && size.full,
          // "w-full": isSmallDevice,
        })}
      >
        Dohodni obhliadku
        <DateTimePicker
          minutesStep={30}
          disablePast={true}
          ampm={false}
          shouldDisableDate={isWeekend}
          shouldDisableTime={(value, view) =>
            view === "hours" && ((value.hour() >= 0 && value.hour() < 9) || (value.hour() > 17 && value.hour() <= 23))
          }
          value={inputValues.arrangeVisit}
          onChange={(newValue) => {
            onChange(newValue);
          }}
          className="w-full rounded-xl bg-white border border-opacity-50 border-sky-500"
        />
      </div>
    </LocalizationProvider>
  );
}
