const UnitsHlohovec = {
  HornéTrhovište: [
    { lat: 48.4697681, lng: 17.8826152 },
    { lat: 48.4719011, lng: 17.8782333 },
    { lat: 48.4730551, lng: 17.8758701 },
    { lat: 48.4727603, lng: 17.875722 },
    { lat: 48.4734566, lng: 17.8748232 },
    { lat: 48.4745266, lng: 17.8739859 },
    { lat: 48.4751479, lng: 17.8732663 },
    { lat: 48.4755293, lng: 17.8730219 },
    { lat: 48.4760201, lng: 17.8722177 },
    { lat: 48.4764664, lng: 17.871145 },
    { lat: 48.4774125, lng: 17.8695457 },
    { lat: 48.4775188, lng: 17.8692508 },
    { lat: 48.4779264, lng: 17.8685654 },
    { lat: 48.4784733, lng: 17.8678126 },
    { lat: 48.4794551, lng: 17.8666192 },
    { lat: 48.4812316, lng: 17.8651074 },
    { lat: 48.4815526, lng: 17.864668 },
    { lat: 48.4823808, lng: 17.8638226 },
    { lat: 48.4829059, lng: 17.8636045 },
    { lat: 48.4835382, lng: 17.8632141 },
    { lat: 48.4837801, lng: 17.8629987 },
    { lat: 48.4844566, lng: 17.8625936 },
    { lat: 48.4849228, lng: 17.8622036 },
    { lat: 48.4855502, lng: 17.8618117 },
    { lat: 48.4869573, lng: 17.8615794 },
    { lat: 48.4874833, lng: 17.8615576 },
    { lat: 48.4889041, lng: 17.8603986 },
    { lat: 48.4909898, lng: 17.8590725 },
    { lat: 48.492202, lng: 17.8581235 },
    { lat: 48.4924346, lng: 17.8579392 },
    { lat: 48.4925322, lng: 17.8577617 },
    { lat: 48.4926631, lng: 17.8570152 },
    { lat: 48.4928088, lng: 17.8564987 },
    { lat: 48.4934408, lng: 17.8555665 },
    { lat: 48.493395, lng: 17.8554628 },
    { lat: 48.4931147, lng: 17.8549191 },
    { lat: 48.4928976, lng: 17.8546362 },
    { lat: 48.4926503, lng: 17.8541136 },
    { lat: 48.4920083, lng: 17.8531183 },
    { lat: 48.4918568, lng: 17.8526694 },
    { lat: 48.4917091, lng: 17.8519288 },
    { lat: 48.4915585, lng: 17.8514211 },
    { lat: 48.4914915, lng: 17.8505057 },
    { lat: 48.4915077, lng: 17.8504336 },
    { lat: 48.4915653, lng: 17.850234 },
    { lat: 48.4917529, lng: 17.8499967 },
    { lat: 48.4918557, lng: 17.8497381 },
    { lat: 48.4916842, lng: 17.8491279 },
    { lat: 48.4916488, lng: 17.8486747 },
    { lat: 48.4916461, lng: 17.8483509 },
    { lat: 48.4917372, lng: 17.8478259 },
    { lat: 48.49171, lng: 17.8473598 },
    { lat: 48.4917485, lng: 17.8468082 },
    { lat: 48.4915802, lng: 17.8461374 },
    { lat: 48.4915756, lng: 17.845895 },
    { lat: 48.4917288, lng: 17.8446496 },
    { lat: 48.4916701, lng: 17.8447428 },
    { lat: 48.4913432, lng: 17.845548 },
    { lat: 48.4911563, lng: 17.845757 },
    { lat: 48.4904541, lng: 17.8462752 },
    { lat: 48.4900401, lng: 17.8464653 },
    { lat: 48.4897435, lng: 17.8467891 },
    { lat: 48.4895429, lng: 17.8471652 },
    { lat: 48.4881342, lng: 17.8484632 },
    { lat: 48.4870105, lng: 17.8498119 },
    { lat: 48.4864053, lng: 17.8508272 },
    { lat: 48.4861244, lng: 17.8515726 },
    { lat: 48.4852165, lng: 17.8523495 },
    { lat: 48.4847519, lng: 17.8526344 },
    { lat: 48.4841585, lng: 17.852845 },
    { lat: 48.483507, lng: 17.8529353 },
    { lat: 48.4830913, lng: 17.8530808 },
    { lat: 48.4829353, lng: 17.8532076 },
    { lat: 48.4826651, lng: 17.8535875 },
    { lat: 48.4820521, lng: 17.85396 },
    { lat: 48.4812672, lng: 17.8540304 },
    { lat: 48.4805871, lng: 17.8542777 },
    { lat: 48.4802492, lng: 17.8543121 },
    { lat: 48.4793404, lng: 17.8545695 },
    { lat: 48.4787033, lng: 17.8546188 },
    { lat: 48.4783262, lng: 17.8548404 },
    { lat: 48.4777799, lng: 17.8547679 },
    { lat: 48.4773894, lng: 17.8552909 },
    { lat: 48.4771845, lng: 17.8553162 },
    { lat: 48.4768619, lng: 17.8552283 },
    { lat: 48.4764218, lng: 17.8553426 },
    { lat: 48.4758448, lng: 17.854762 },
    { lat: 48.4757575, lng: 17.8547391 },
    { lat: 48.4751209, lng: 17.85554 },
    { lat: 48.4750686, lng: 17.8557386 },
    { lat: 48.475062, lng: 17.8562209 },
    { lat: 48.4750121, lng: 17.8564775 },
    { lat: 48.4748635, lng: 17.8567452 },
    { lat: 48.4746838, lng: 17.8569642 },
    { lat: 48.4744518, lng: 17.8570858 },
    { lat: 48.4741338, lng: 17.8573679 },
    { lat: 48.4731296, lng: 17.8585273 },
    { lat: 48.4729669, lng: 17.8590652 },
    { lat: 48.4724991, lng: 17.8594639 },
    { lat: 48.4721556, lng: 17.8596264 },
    { lat: 48.4714341, lng: 17.8602382 },
    { lat: 48.4701309, lng: 17.860692 },
    { lat: 48.4688235, lng: 17.8615796 },
    { lat: 48.4682062, lng: 17.8617527 },
    { lat: 48.4677102, lng: 17.8620809 },
    { lat: 48.4673342, lng: 17.8624684 },
    { lat: 48.4670313, lng: 17.8629059 },
    { lat: 48.4667034, lng: 17.8636805 },
    { lat: 48.4665279, lng: 17.8639689 },
    { lat: 48.4656708, lng: 17.8650026 },
    { lat: 48.4650731, lng: 17.8653668 },
    { lat: 48.4646292, lng: 17.8655469 },
    { lat: 48.4635328, lng: 17.8657853 },
    { lat: 48.4628967, lng: 17.8662939 },
    { lat: 48.4620507, lng: 17.8667138 },
    { lat: 48.4608756, lng: 17.8670889 },
    { lat: 48.4596995, lng: 17.8672952 },
    { lat: 48.459309, lng: 17.8675384 },
    { lat: 48.4583067, lng: 17.8683393 },
    { lat: 48.455572, lng: 17.8710437 },
    { lat: 48.4554138, lng: 17.8712631 },
    { lat: 48.4546935, lng: 17.8711315 },
    { lat: 48.4540377, lng: 17.8709114 },
    { lat: 48.4538271, lng: 17.8707312 },
    { lat: 48.4521526, lng: 17.8735728 },
    { lat: 48.4507195, lng: 17.8762962 },
    { lat: 48.4500873, lng: 17.8781573 },
    { lat: 48.4492064, lng: 17.8804744 },
    { lat: 48.4481177, lng: 17.8823495 },
    { lat: 48.4463075, lng: 17.8847195 },
    { lat: 48.4483344, lng: 17.8875255 },
    { lat: 48.4485882, lng: 17.8877638 },
    { lat: 48.4503258, lng: 17.8900439 },
    { lat: 48.4502887, lng: 17.8905333 },
    { lat: 48.4494987, lng: 17.8919876 },
    { lat: 48.4506408, lng: 17.8933646 },
    { lat: 48.4511234, lng: 17.8938025 },
    { lat: 48.4516691, lng: 17.8926188 },
    { lat: 48.4520777, lng: 17.8932691 },
    { lat: 48.4525618, lng: 17.8929152 },
    { lat: 48.452843, lng: 17.8950416 },
    { lat: 48.4528694, lng: 17.8967282 },
    { lat: 48.4538208, lng: 17.8981646 },
    { lat: 48.4534667, lng: 17.8992524 },
    { lat: 48.4559729, lng: 17.9012905 },
    { lat: 48.4562393, lng: 17.9019265 },
    { lat: 48.4562067, lng: 17.9043379 },
    { lat: 48.4582086, lng: 17.90513 },
    { lat: 48.4597854, lng: 17.9069792 },
    { lat: 48.459797, lng: 17.9070021 },
    { lat: 48.4598717, lng: 17.9068763 },
    { lat: 48.4600966, lng: 17.9066561 },
    { lat: 48.4603637, lng: 17.9061245 },
    { lat: 48.4605304, lng: 17.9059122 },
    { lat: 48.4616016, lng: 17.9048853 },
    { lat: 48.462417, lng: 17.9038827 },
    { lat: 48.4631026, lng: 17.9023927 },
    { lat: 48.463485, lng: 17.9011784 },
    { lat: 48.4636703, lng: 17.9003777 },
    { lat: 48.4640729, lng: 17.8981662 },
    { lat: 48.464389, lng: 17.8973466 },
    { lat: 48.4649915, lng: 17.8946321 },
    { lat: 48.4680149, lng: 17.8874423 },
    { lat: 48.4697681, lng: 17.8826152 },
  ],
  Tepličky: [
    { lat: 48.4801941, lng: 17.8369662 },
    { lat: 48.4801146, lng: 17.8371985 },
    { lat: 48.4797717, lng: 17.8376304 },
    { lat: 48.4796598, lng: 17.8385151 },
    { lat: 48.4794395, lng: 17.8390877 },
    { lat: 48.479175, lng: 17.839507 },
    { lat: 48.4787259, lng: 17.8399756 },
    { lat: 48.4783381, lng: 17.8406286 },
    { lat: 48.4777979, lng: 17.8411585 },
    { lat: 48.4771253, lng: 17.8409759 },
    { lat: 48.4765673, lng: 17.840712 },
    { lat: 48.4756999, lng: 17.840574 },
    { lat: 48.4751865, lng: 17.8408743 },
    { lat: 48.4749532, lng: 17.840862 },
    { lat: 48.4740488, lng: 17.8405626 },
    { lat: 48.4732746, lng: 17.8403662 },
    { lat: 48.4731394, lng: 17.840387 },
    { lat: 48.4728686, lng: 17.84026 },
    { lat: 48.4717961, lng: 17.8400158 },
    { lat: 48.4710032, lng: 17.8399003 },
    { lat: 48.4704722, lng: 17.8399486 },
    { lat: 48.4701474, lng: 17.8396967 },
    { lat: 48.4693177, lng: 17.8393423 },
    { lat: 48.4682321, lng: 17.8391825 },
    { lat: 48.467891, lng: 17.8392359 },
    { lat: 48.4675134, lng: 17.8394096 },
    { lat: 48.4668697, lng: 17.8393913 },
    { lat: 48.4663623, lng: 17.839918 },
    { lat: 48.4658526, lng: 17.8408349 },
    { lat: 48.4654256, lng: 17.8412391 },
    { lat: 48.4647282, lng: 17.8415569 },
    { lat: 48.4645931, lng: 17.8414716 },
    { lat: 48.4629485, lng: 17.8424555 },
    { lat: 48.4619149, lng: 17.8431447 },
    { lat: 48.4601694, lng: 17.8444589 },
    { lat: 48.4599658, lng: 17.8447466 },
    { lat: 48.4588653, lng: 17.8469749 },
    { lat: 48.4586524, lng: 17.8474438 },
    { lat: 48.458631, lng: 17.8476491 },
    { lat: 48.458517, lng: 17.8475944 },
    { lat: 48.4583837, lng: 17.8480385 },
    { lat: 48.4584598, lng: 17.848399 },
    { lat: 48.4583971, lng: 17.8488696 },
    { lat: 48.4581733, lng: 17.8496412 },
    { lat: 48.4572999, lng: 17.8510002 },
    { lat: 48.4572137, lng: 17.8512901 },
    { lat: 48.4566928, lng: 17.8517647 },
    { lat: 48.4558791, lng: 17.852068 },
    { lat: 48.4557626, lng: 17.8520624 },
    { lat: 48.4546918, lng: 17.8526813 },
    { lat: 48.4542306, lng: 17.8532661 },
    { lat: 48.4539219, lng: 17.8539503 },
    { lat: 48.4533245, lng: 17.8552744 },
    { lat: 48.4526993, lng: 17.8564678 },
    { lat: 48.4516664, lng: 17.8576905 },
    { lat: 48.4499235, lng: 17.8600675 },
    { lat: 48.4495209, lng: 17.8604845 },
    { lat: 48.4490463, lng: 17.8611254 },
    { lat: 48.4485348, lng: 17.86166 },
    { lat: 48.4485339, lng: 17.8617391 },
    { lat: 48.4485582, lng: 17.8617685 },
    { lat: 48.4486124, lng: 17.8618435 },
    { lat: 48.449027, lng: 17.8616244 },
    { lat: 48.4487833, lng: 17.8621287 },
    { lat: 48.4500258, lng: 17.8639408 },
    { lat: 48.4500583, lng: 17.8641255 },
    { lat: 48.4502644, lng: 17.8642824 },
    { lat: 48.4505596, lng: 17.8648699 },
    { lat: 48.4509729, lng: 17.8653533 },
    { lat: 48.4504616, lng: 17.8666937 },
    { lat: 48.4498849, lng: 17.8686649 },
    { lat: 48.4492342, lng: 17.870162 },
    { lat: 48.4512553, lng: 17.8724208 },
    { lat: 48.4517768, lng: 17.873091 },
    { lat: 48.4521526, lng: 17.8735728 },
    { lat: 48.4538271, lng: 17.8707312 },
    { lat: 48.4540377, lng: 17.8709114 },
    { lat: 48.4546935, lng: 17.8711315 },
    { lat: 48.4554138, lng: 17.8712631 },
    { lat: 48.455572, lng: 17.8710437 },
    { lat: 48.4583067, lng: 17.8683393 },
    { lat: 48.459309, lng: 17.8675384 },
    { lat: 48.4596995, lng: 17.8672952 },
    { lat: 48.4608756, lng: 17.8670889 },
    { lat: 48.4620507, lng: 17.8667138 },
    { lat: 48.4628967, lng: 17.8662939 },
    { lat: 48.4635328, lng: 17.8657853 },
    { lat: 48.4646292, lng: 17.8655469 },
    { lat: 48.4650731, lng: 17.8653668 },
    { lat: 48.4656708, lng: 17.8650026 },
    { lat: 48.4665279, lng: 17.8639689 },
    { lat: 48.4667034, lng: 17.8636805 },
    { lat: 48.4670313, lng: 17.8629059 },
    { lat: 48.4673342, lng: 17.8624684 },
    { lat: 48.4677102, lng: 17.8620809 },
    { lat: 48.4682062, lng: 17.8617527 },
    { lat: 48.4688235, lng: 17.8615796 },
    { lat: 48.4701309, lng: 17.860692 },
    { lat: 48.4714341, lng: 17.8602382 },
    { lat: 48.4721556, lng: 17.8596264 },
    { lat: 48.4724991, lng: 17.8594639 },
    { lat: 48.4729669, lng: 17.8590652 },
    { lat: 48.4731296, lng: 17.8585273 },
    { lat: 48.4741338, lng: 17.8573679 },
    { lat: 48.4744518, lng: 17.8570858 },
    { lat: 48.4746838, lng: 17.8569642 },
    { lat: 48.4748635, lng: 17.8567452 },
    { lat: 48.4750121, lng: 17.8564775 },
    { lat: 48.475062, lng: 17.8562209 },
    { lat: 48.4750686, lng: 17.8557386 },
    { lat: 48.4751209, lng: 17.85554 },
    { lat: 48.4757575, lng: 17.8547391 },
    { lat: 48.4758448, lng: 17.854762 },
    { lat: 48.4764218, lng: 17.8553426 },
    { lat: 48.4768619, lng: 17.8552283 },
    { lat: 48.4771845, lng: 17.8553162 },
    { lat: 48.4773894, lng: 17.8552909 },
    { lat: 48.4777799, lng: 17.8547679 },
    { lat: 48.4783262, lng: 17.8548404 },
    { lat: 48.4787033, lng: 17.8546188 },
    { lat: 48.4793404, lng: 17.8545695 },
    { lat: 48.4802492, lng: 17.8543121 },
    { lat: 48.4805871, lng: 17.8542777 },
    { lat: 48.4812672, lng: 17.8540304 },
    { lat: 48.4820521, lng: 17.85396 },
    { lat: 48.4826651, lng: 17.8535875 },
    { lat: 48.4829353, lng: 17.8532076 },
    { lat: 48.4830913, lng: 17.8530808 },
    { lat: 48.483507, lng: 17.8529353 },
    { lat: 48.4841585, lng: 17.852845 },
    { lat: 48.4847519, lng: 17.8526344 },
    { lat: 48.4852165, lng: 17.8523495 },
    { lat: 48.4861244, lng: 17.8515726 },
    { lat: 48.4864053, lng: 17.8508272 },
    { lat: 48.4870105, lng: 17.8498119 },
    { lat: 48.4881342, lng: 17.8484632 },
    { lat: 48.4895429, lng: 17.8471652 },
    { lat: 48.4897435, lng: 17.8467891 },
    { lat: 48.4900401, lng: 17.8464653 },
    { lat: 48.4904541, lng: 17.8462752 },
    { lat: 48.4911563, lng: 17.845757 },
    { lat: 48.4913432, lng: 17.845548 },
    { lat: 48.4916701, lng: 17.8447428 },
    { lat: 48.4917288, lng: 17.8446496 },
    { lat: 48.4916102, lng: 17.8443343 },
    { lat: 48.4912173, lng: 17.843756 },
    { lat: 48.4907616, lng: 17.8433936 },
    { lat: 48.4904313, lng: 17.8432722 },
    { lat: 48.4898439, lng: 17.8427621 },
    { lat: 48.4882134, lng: 17.8420919 },
    { lat: 48.4875497, lng: 17.8419069 },
    { lat: 48.4870586, lng: 17.8415155 },
    { lat: 48.4860761, lng: 17.8413436 },
    { lat: 48.4858532, lng: 17.8412444 },
    { lat: 48.4855804, lng: 17.8410269 },
    { lat: 48.4852024, lng: 17.8405691 },
    { lat: 48.4849915, lng: 17.8405907 },
    { lat: 48.4847543, lng: 17.8404395 },
    { lat: 48.4846171, lng: 17.840521 },
    { lat: 48.4843226, lng: 17.8405417 },
    { lat: 48.4835001, lng: 17.8404406 },
    { lat: 48.482926, lng: 17.8400956 },
    { lat: 48.4821017, lng: 17.8401669 },
    { lat: 48.4819388, lng: 17.8401218 },
    { lat: 48.4816636, lng: 17.8397508 },
    { lat: 48.4815752, lng: 17.8396592 },
    { lat: 48.481411, lng: 17.8393338 },
    { lat: 48.4814364, lng: 17.8392876 },
    { lat: 48.4806543, lng: 17.8378484 },
    { lat: 48.4801941, lng: 17.8369662 },
  ],
  Merašice: [
    { lat: 48.4804859, lng: 17.9326057 },
    { lat: 48.478759, lng: 17.9302357 },
    { lat: 48.478161, lng: 17.9295472 },
    { lat: 48.4781248, lng: 17.9293927 },
    { lat: 48.4781486, lng: 17.9291511 },
    { lat: 48.4771915, lng: 17.9275236 },
    { lat: 48.4769964, lng: 17.9276384 },
    { lat: 48.4757152, lng: 17.9256862 },
    { lat: 48.4759098, lng: 17.925321 },
    { lat: 48.4758967, lng: 17.9253064 },
    { lat: 48.4743907, lng: 17.9232052 },
    { lat: 48.4738956, lng: 17.9235662 },
    { lat: 48.4728421, lng: 17.9248157 },
    { lat: 48.4712068, lng: 17.9271272 },
    { lat: 48.4694516, lng: 17.929044 },
    { lat: 48.4671669, lng: 17.9317249 },
    { lat: 48.4655692, lng: 17.9330467 },
    { lat: 48.4640121, lng: 17.9338656 },
    { lat: 48.463619, lng: 17.9343103 },
    { lat: 48.4632383, lng: 17.9346068 },
    { lat: 48.4626222, lng: 17.9348904 },
    { lat: 48.4622883, lng: 17.9349703 },
    { lat: 48.4619326, lng: 17.9373319 },
    { lat: 48.4614567, lng: 17.9371508 },
    { lat: 48.4612833, lng: 17.9375514 },
    { lat: 48.4611561, lng: 17.9377098 },
    { lat: 48.4604913, lng: 17.9383294 },
    { lat: 48.460195, lng: 17.9385083 },
    { lat: 48.4591553, lng: 17.9388833 },
    { lat: 48.4589694, lng: 17.9396387 },
    { lat: 48.4565481, lng: 17.9310421 },
    { lat: 48.4493714, lng: 17.9402759 },
    { lat: 48.449232, lng: 17.9408292 },
    { lat: 48.4492158, lng: 17.9411816 },
    { lat: 48.4495187, lng: 17.9418072 },
    { lat: 48.4498342, lng: 17.9429789 },
    { lat: 48.4501113, lng: 17.9436667 },
    { lat: 48.4496066, lng: 17.9444545 },
    { lat: 48.449151, lng: 17.945056 },
    { lat: 48.450102, lng: 17.946394 },
    { lat: 48.450642, lng: 17.947198 },
    { lat: 48.451097, lng: 17.947909 },
    { lat: 48.451192, lng: 17.94803 },
    { lat: 48.451392, lng: 17.948286 },
    { lat: 48.451782, lng: 17.948849 },
    { lat: 48.452174, lng: 17.949312 },
    { lat: 48.452568, lng: 17.949719 },
    { lat: 48.452886, lng: 17.950079 },
    { lat: 48.453313, lng: 17.950516 },
    { lat: 48.453488, lng: 17.950676 },
    { lat: 48.453849, lng: 17.951059 },
    { lat: 48.45428, lng: 17.951511 },
    { lat: 48.454721, lng: 17.951962 },
    { lat: 48.455074, lng: 17.952295 },
    { lat: 48.455377, lng: 17.952565 },
    { lat: 48.455407, lng: 17.952595 },
    { lat: 48.455475, lng: 17.952627 },
    { lat: 48.45666, lng: 17.953464 },
    { lat: 48.457971, lng: 17.954386 },
    { lat: 48.458346, lng: 17.954649 },
    { lat: 48.458383, lng: 17.954678 },
    { lat: 48.458703, lng: 17.954922 },
    { lat: 48.45872, lng: 17.954792 },
    { lat: 48.459339, lng: 17.955211 },
    { lat: 48.460571, lng: 17.956071 },
    { lat: 48.46059, lng: 17.956085 },
    { lat: 48.461117, lng: 17.956418 },
    { lat: 48.461163, lng: 17.956451 },
    { lat: 48.46118, lng: 17.956455 },
    { lat: 48.461324, lng: 17.956566 },
    { lat: 48.461545, lng: 17.956729 },
    { lat: 48.461565, lng: 17.956738 },
    { lat: 48.461527, lng: 17.956859 },
    { lat: 48.461442, lng: 17.956942 },
    { lat: 48.461277, lng: 17.957378 },
    { lat: 48.461265, lng: 17.957494 },
    { lat: 48.461172, lng: 17.957722 },
    { lat: 48.46115, lng: 17.957787 },
    { lat: 48.461076, lng: 17.957925 },
    { lat: 48.461011, lng: 17.958193 },
    { lat: 48.460998, lng: 17.958268 },
    { lat: 48.460942, lng: 17.95846 },
    { lat: 48.460789, lng: 17.9589 },
    { lat: 48.460761, lng: 17.959037 },
    { lat: 48.460629, lng: 17.959266 },
    { lat: 48.460447, lng: 17.959733 },
    { lat: 48.460337, lng: 17.959931 },
    { lat: 48.460187, lng: 17.960292 },
    { lat: 48.459945, lng: 17.960735 },
    { lat: 48.459856, lng: 17.960898 },
    { lat: 48.459796, lng: 17.96114 },
    { lat: 48.459788, lng: 17.96124 },
    { lat: 48.459861, lng: 17.961703 },
    { lat: 48.459893, lng: 17.962043 },
    { lat: 48.45989, lng: 17.962137 },
    { lat: 48.459892, lng: 17.962263 },
    { lat: 48.459988, lng: 17.96247 },
    { lat: 48.46009, lng: 17.962796 },
    { lat: 48.460164, lng: 17.962919 },
    { lat: 48.460255, lng: 17.96299 },
    { lat: 48.460478, lng: 17.963238 },
    { lat: 48.460559, lng: 17.963375 },
    { lat: 48.460676, lng: 17.963476 },
    { lat: 48.460835, lng: 17.963681 },
    { lat: 48.460939, lng: 17.963823 },
    { lat: 48.461007, lng: 17.963885 },
    { lat: 48.46112, lng: 17.963916 },
    { lat: 48.461196, lng: 17.963929 },
    { lat: 48.46128, lng: 17.963991 },
    { lat: 48.461447, lng: 17.964055 },
    { lat: 48.461687, lng: 17.964192 },
    { lat: 48.461728, lng: 17.964235 },
    { lat: 48.462105, lng: 17.964418 },
    { lat: 48.462242, lng: 17.964438 },
    { lat: 48.462448, lng: 17.964486 },
    { lat: 48.463204, lng: 17.964665 },
    { lat: 48.463444, lng: 17.964707 },
    { lat: 48.463672, lng: 17.964675 },
    { lat: 48.463898, lng: 17.964707 },
    { lat: 48.464174, lng: 17.964668 },
    { lat: 48.46427, lng: 17.964656 },
    { lat: 48.464348, lng: 17.964635 },
    { lat: 48.464398, lng: 17.964625 },
    { lat: 48.464515, lng: 17.964594 },
    { lat: 48.464798, lng: 17.964665 },
    { lat: 48.464902, lng: 17.963917 },
    { lat: 48.46495, lng: 17.963589 },
    { lat: 48.464983, lng: 17.963308 },
    { lat: 48.465027, lng: 17.962977 },
    { lat: 48.465076, lng: 17.9626 },
    { lat: 48.46505, lng: 17.962361 },
    { lat: 48.465253, lng: 17.961687 },
    { lat: 48.465409, lng: 17.961276 },
    { lat: 48.465488, lng: 17.961071 },
    { lat: 48.465575, lng: 17.960846 },
    { lat: 48.465638, lng: 17.96079 },
    { lat: 48.465699, lng: 17.960775 },
    { lat: 48.46578, lng: 17.960822 },
    { lat: 48.465882, lng: 17.9606 },
    { lat: 48.466003, lng: 17.960351 },
    { lat: 48.466178, lng: 17.960061 },
    { lat: 48.466322, lng: 17.959747 },
    { lat: 48.466487, lng: 17.95948 },
    { lat: 48.466674, lng: 17.959137 },
    { lat: 48.466845, lng: 17.958807 },
    { lat: 48.466907, lng: 17.95868 },
    { lat: 48.467053, lng: 17.958401 },
    { lat: 48.467228, lng: 17.958173 },
    { lat: 48.46728, lng: 17.958122 },
    { lat: 48.467363, lng: 17.957954 },
    { lat: 48.467531, lng: 17.957645 },
    { lat: 48.467641, lng: 17.957442 },
    { lat: 48.468126, lng: 17.956585 },
    { lat: 48.468369, lng: 17.95616 },
    { lat: 48.468511, lng: 17.955919 },
    { lat: 48.46876, lng: 17.955509 },
    { lat: 48.468884, lng: 17.955303 },
    { lat: 48.469082, lng: 17.954969 },
    { lat: 48.469289, lng: 17.954651 },
    { lat: 48.469522, lng: 17.954292 },
    { lat: 48.469743, lng: 17.953945 },
    { lat: 48.469924, lng: 17.953672 },
    { lat: 48.470174, lng: 17.953316 },
    { lat: 48.47022, lng: 17.953244 },
    { lat: 48.470278, lng: 17.953177 },
    { lat: 48.47037, lng: 17.953029 },
    { lat: 48.470509, lng: 17.95279 },
    { lat: 48.47092, lng: 17.952093 },
    { lat: 48.471097, lng: 17.951838 },
    { lat: 48.4712, lng: 17.95173 },
    { lat: 48.471437, lng: 17.951486 },
    { lat: 48.47176, lng: 17.951082 },
    { lat: 48.471964, lng: 17.95083 },
    { lat: 48.472289, lng: 17.950433 },
    { lat: 48.472391, lng: 17.950288 },
    { lat: 48.472484, lng: 17.950153 },
    { lat: 48.472606, lng: 17.949977 },
    { lat: 48.472815, lng: 17.949635 },
    { lat: 48.473018, lng: 17.949307 },
    { lat: 48.473168, lng: 17.949065 },
    { lat: 48.473294, lng: 17.948844 },
    { lat: 48.473375, lng: 17.948704 },
    { lat: 48.473425, lng: 17.94863 },
    { lat: 48.473515, lng: 17.948495 },
    { lat: 48.47351, lng: 17.948487 },
    { lat: 48.473227, lng: 17.948183 },
    { lat: 48.473112, lng: 17.948056 },
    { lat: 48.473208, lng: 17.947861 },
    { lat: 48.473304, lng: 17.947668 },
    { lat: 48.473497, lng: 17.9473 },
    { lat: 48.47355, lng: 17.947191 },
    { lat: 48.473608, lng: 17.947086 },
    { lat: 48.473678, lng: 17.946941 },
    { lat: 48.473852, lng: 17.946556 },
    { lat: 48.473907, lng: 17.94643 },
    { lat: 48.47402, lng: 17.946161 },
    { lat: 48.47435, lng: 17.945394 },
    { lat: 48.474533, lng: 17.944938 },
    { lat: 48.474463, lng: 17.94484 },
    { lat: 48.474603, lng: 17.944431 },
    { lat: 48.475084, lng: 17.94326 },
    { lat: 48.475228, lng: 17.942837 },
    { lat: 48.475355, lng: 17.942489 },
    { lat: 48.475444, lng: 17.942236 },
    { lat: 48.475636, lng: 17.941758 },
    { lat: 48.475294, lng: 17.941416 },
    { lat: 48.475236, lng: 17.941353 },
    { lat: 48.475083, lng: 17.941164 },
    { lat: 48.475172, lng: 17.940903 },
    { lat: 48.47554, lng: 17.940076 },
    { lat: 48.475812, lng: 17.93947 },
    { lat: 48.476311, lng: 17.938356 },
    { lat: 48.476461, lng: 17.938307 },
    { lat: 48.476989, lng: 17.938141 },
    { lat: 48.477121, lng: 17.938097 },
    { lat: 48.477326, lng: 17.937792 },
    { lat: 48.477555, lng: 17.937453 },
    { lat: 48.47795, lng: 17.936876 },
    { lat: 48.477971, lng: 17.936847 },
    { lat: 48.47848, lng: 17.936112 },
    { lat: 48.478848, lng: 17.93558 },
    { lat: 48.479, lng: 17.935373 },
    { lat: 48.479267, lng: 17.935055 },
    { lat: 48.47954, lng: 17.934731 },
    { lat: 48.478903, lng: 17.933731 },
    { lat: 48.478868, lng: 17.933682 },
    { lat: 48.479275, lng: 17.933485 },
    { lat: 48.47945, lng: 17.933428 },
    { lat: 48.479722, lng: 17.933312 },
    { lat: 48.479969, lng: 17.933168 },
    { lat: 48.480154, lng: 17.932992 },
    { lat: 48.4804859, lng: 17.9326057 },
  ],
  Červeník: [
    { lat: 48.486163, lng: 17.751766 },
    { lat: 48.486002, lng: 17.751632 },
    { lat: 48.485854, lng: 17.751453 },
    { lat: 48.485674, lng: 17.751286 },
    { lat: 48.485348, lng: 17.75118 },
    { lat: 48.485167, lng: 17.751093 },
    { lat: 48.484907, lng: 17.750849 },
    { lat: 48.484445, lng: 17.750483 },
    { lat: 48.484272, lng: 17.750359 },
    { lat: 48.484155, lng: 17.750306 },
    { lat: 48.483841, lng: 17.750101 },
    { lat: 48.483487, lng: 17.749868 },
    { lat: 48.483102, lng: 17.749628 },
    { lat: 48.482712, lng: 17.749387 },
    { lat: 48.482388, lng: 17.749173 },
    { lat: 48.481994, lng: 17.748958 },
    { lat: 48.481704, lng: 17.74878 },
    { lat: 48.481495, lng: 17.74872 },
    { lat: 48.48149, lng: 17.748719 },
    { lat: 48.481415, lng: 17.748687 },
    { lat: 48.481271, lng: 17.748626 },
    { lat: 48.481052, lng: 17.748547 },
    { lat: 48.480703, lng: 17.748437 },
    { lat: 48.480407, lng: 17.748332 },
    { lat: 48.480107, lng: 17.748238 },
    { lat: 48.479973, lng: 17.748095 },
    { lat: 48.479699, lng: 17.74799 },
    { lat: 48.479302, lng: 17.747872 },
    { lat: 48.479171, lng: 17.747826 },
    { lat: 48.478785, lng: 17.747689 },
    { lat: 48.478719, lng: 17.747592 },
    { lat: 48.478418, lng: 17.747304 },
    { lat: 48.478371, lng: 17.747259 },
    { lat: 48.478367, lng: 17.747255 },
    { lat: 48.478268, lng: 17.747102 },
    { lat: 48.478059, lng: 17.74687 },
    { lat: 48.477664, lng: 17.746463 },
    { lat: 48.477309, lng: 17.74613 },
    { lat: 48.4771, lng: 17.746073 },
    { lat: 48.476823, lng: 17.746155 },
    { lat: 48.476536, lng: 17.745964 },
    { lat: 48.476212, lng: 17.746217 },
    { lat: 48.476171, lng: 17.746221 },
    { lat: 48.476111, lng: 17.746229 },
    { lat: 48.475896, lng: 17.746301 },
    { lat: 48.475732, lng: 17.746362 },
    { lat: 48.475562, lng: 17.746404 },
    { lat: 48.475425, lng: 17.74647 },
    { lat: 48.475193, lng: 17.746434 },
    { lat: 48.474934, lng: 17.746281 },
    { lat: 48.47438, lng: 17.746327 },
    { lat: 48.474283, lng: 17.746369 },
    { lat: 48.474229, lng: 17.746385 },
    { lat: 48.474031, lng: 17.746541 },
    { lat: 48.473862, lng: 17.746536 },
    { lat: 48.47343, lng: 17.74628 },
    { lat: 48.473339, lng: 17.746177 },
    { lat: 48.472839, lng: 17.746148 },
    { lat: 48.47246, lng: 17.746151 },
    { lat: 48.472368, lng: 17.746156 },
    { lat: 48.472352, lng: 17.746156 },
    { lat: 48.471645, lng: 17.7463221 },
    { lat: 48.4708095, lng: 17.7462083 },
    { lat: 48.4705202, lng: 17.7460612 },
    { lat: 48.4704981, lng: 17.7459938 },
    { lat: 48.4696194, lng: 17.74535 },
    { lat: 48.4690553, lng: 17.7451915 },
    { lat: 48.467523, lng: 17.7444199 },
    { lat: 48.4664886, lng: 17.7442695 },
    { lat: 48.4656277, lng: 17.7440604 },
    { lat: 48.4648784, lng: 17.7436109 },
    { lat: 48.4642025, lng: 17.743509 },
    { lat: 48.4640125, lng: 17.7435192 },
    { lat: 48.4635444, lng: 17.7436942 },
    { lat: 48.463385, lng: 17.7436866 },
    { lat: 48.462929, lng: 17.7433986 },
    { lat: 48.4621171, lng: 17.7431386 },
    { lat: 48.4619623, lng: 17.7428468 },
    { lat: 48.4617945, lng: 17.7428151 },
    { lat: 48.4612555, lng: 17.7429751 },
    { lat: 48.4612369, lng: 17.7429656 },
    { lat: 48.4612182, lng: 17.7430259 },
    { lat: 48.4607215, lng: 17.7431195 },
    { lat: 48.4606664, lng: 17.7430667 },
    { lat: 48.4604275, lng: 17.7423834 },
    { lat: 48.460349, lng: 17.7422613 },
    { lat: 48.4601153, lng: 17.7421545 },
    { lat: 48.4599832, lng: 17.7422452 },
    { lat: 48.4599776, lng: 17.7428134 },
    { lat: 48.4598534, lng: 17.7431573 },
    { lat: 48.4597762, lng: 17.7431335 },
    { lat: 48.4597462, lng: 17.7430122 },
    { lat: 48.4594376, lng: 17.7425506 },
    { lat: 48.4592151, lng: 17.7426749 },
    { lat: 48.4591105, lng: 17.7426639 },
    { lat: 48.4590597, lng: 17.7425924 },
    { lat: 48.458973, lng: 17.7420852 },
    { lat: 48.4586189, lng: 17.7418181 },
    { lat: 48.4585977, lng: 17.7417481 },
    { lat: 48.4589283, lng: 17.7409603 },
    { lat: 48.4589264, lng: 17.7405671 },
    { lat: 48.4587384, lng: 17.7403921 },
    { lat: 48.4585822, lng: 17.7403834 },
    { lat: 48.4584458, lng: 17.7405148 },
    { lat: 48.4581514, lng: 17.74116 },
    { lat: 48.4577272, lng: 17.7405637 },
    { lat: 48.4575677, lng: 17.7406127 },
    { lat: 48.4574979, lng: 17.7409259 },
    { lat: 48.457615, lng: 17.7414854 },
    { lat: 48.4576164, lng: 17.7416911 },
    { lat: 48.4575184, lng: 17.7418396 },
    { lat: 48.4572626, lng: 17.7420497 },
    { lat: 48.4571835, lng: 17.7423766 },
    { lat: 48.457114, lng: 17.7424233 },
    { lat: 48.4570362, lng: 17.7422937 },
    { lat: 48.4569414, lng: 17.7417282 },
    { lat: 48.456667, lng: 17.7412171 },
    { lat: 48.4565139, lng: 17.7412819 },
    { lat: 48.4559061, lng: 17.7420778 },
    { lat: 48.4558145, lng: 17.7421298 },
    { lat: 48.454951, lng: 17.7414773 },
    { lat: 48.4546232, lng: 17.741326 },
    { lat: 48.454177, lng: 17.7410086 },
    { lat: 48.4538281, lng: 17.7406259 },
    { lat: 48.4537295, lng: 17.7407116 },
    { lat: 48.4536508, lng: 17.7410067 },
    { lat: 48.4537469, lng: 17.7418305 },
    { lat: 48.4538312, lng: 17.7421378 },
    { lat: 48.4537599, lng: 17.7422772 },
    { lat: 48.4535838, lng: 17.7422606 },
    { lat: 48.4528474, lng: 17.7418731 },
    { lat: 48.4525976, lng: 17.7419414 },
    { lat: 48.4524639, lng: 17.7420444 },
    { lat: 48.4523493, lng: 17.742235 },
    { lat: 48.4524536, lng: 17.7424783 },
    { lat: 48.4523373, lng: 17.7426642 },
    { lat: 48.4522788, lng: 17.7429036 },
    { lat: 48.4521438, lng: 17.7429665 },
    { lat: 48.4520607, lng: 17.7432452 },
    { lat: 48.4517764, lng: 17.7434336 },
    { lat: 48.4510163, lng: 17.7432095 },
    { lat: 48.450827, lng: 17.7430514 },
    { lat: 48.4504237, lng: 17.7429921 },
    { lat: 48.4500233, lng: 17.7427074 },
    { lat: 48.4496773, lng: 17.7425722 },
    { lat: 48.4495871, lng: 17.7424245 },
    { lat: 48.4497216, lng: 17.7421553 },
    { lat: 48.4497247, lng: 17.7420069 },
    { lat: 48.4496279, lng: 17.7418266 },
    { lat: 48.4496896, lng: 17.7413628 },
    { lat: 48.4498232, lng: 17.7411901 },
    { lat: 48.4500474, lng: 17.7410357 },
    { lat: 48.450074, lng: 17.7409179 },
    { lat: 48.4500504, lng: 17.7407554 },
    { lat: 48.4498159, lng: 17.7402679 },
    { lat: 48.449483, lng: 17.7398322 },
    { lat: 48.4495345, lng: 17.7396237 },
    { lat: 48.4498512, lng: 17.7395773 },
    { lat: 48.4500246, lng: 17.7396996 },
    { lat: 48.4500856, lng: 17.7399361 },
    { lat: 48.4501501, lng: 17.7399921 },
    { lat: 48.4503346, lng: 17.7400176 },
    { lat: 48.4504465, lng: 17.7399297 },
    { lat: 48.4507313, lng: 17.7399173 },
    { lat: 48.4507717, lng: 17.7398586 },
    { lat: 48.4507256, lng: 17.7393853 },
    { lat: 48.4507658, lng: 17.7393438 },
    { lat: 48.4509737, lng: 17.7392891 },
    { lat: 48.4512038, lng: 17.7390503 },
    { lat: 48.4514037, lng: 17.7390874 },
    { lat: 48.4515952, lng: 17.7392278 },
    { lat: 48.4517542, lng: 17.7392048 },
    { lat: 48.4520415, lng: 17.7393052 },
    { lat: 48.4521422, lng: 17.7391428 },
    { lat: 48.4521463, lng: 17.7386823 },
    { lat: 48.451885, lng: 17.7384546 },
    { lat: 48.4516405, lng: 17.738354 },
    { lat: 48.4515067, lng: 17.7380527 },
    { lat: 48.451387, lng: 17.7373773 },
    { lat: 48.4514463, lng: 17.7369853 },
    { lat: 48.451327, lng: 17.736663 },
    { lat: 48.4513264, lng: 17.7364899 },
    { lat: 48.4512643, lng: 17.7363236 },
    { lat: 48.4512758, lng: 17.7361072 },
    { lat: 48.4511782, lng: 17.735953 },
    { lat: 48.4508732, lng: 17.7360244 },
    { lat: 48.4507117, lng: 17.7359884 },
    { lat: 48.4505769, lng: 17.7361801 },
    { lat: 48.4500754, lng: 17.7364142 },
    { lat: 48.4495033, lng: 17.736364 },
    { lat: 48.4493082, lng: 17.7362587 },
    { lat: 48.4490939, lng: 17.7358953 },
    { lat: 48.4491246, lng: 17.7357004 },
    { lat: 48.449221, lng: 17.7355994 },
    { lat: 48.4492233, lng: 17.7353459 },
    { lat: 48.4491652, lng: 17.7351339 },
    { lat: 48.4490128, lng: 17.7350791 },
    { lat: 48.4489204, lng: 17.7351386 },
    { lat: 48.4489427, lng: 17.7354794 },
    { lat: 48.4488597, lng: 17.7357094 },
    { lat: 48.4486884, lng: 17.7357518 },
    { lat: 48.4484079, lng: 17.7356296 },
    { lat: 48.4483477, lng: 17.7356814 },
    { lat: 48.4483462, lng: 17.7358954 },
    { lat: 48.448472, lng: 17.736264 },
    { lat: 48.4483832, lng: 17.7364197 },
    { lat: 48.4480447, lng: 17.7363476 },
    { lat: 48.4478436, lng: 17.736199 },
    { lat: 48.4477248, lng: 17.7363049 },
    { lat: 48.4476951, lng: 17.7364449 },
    { lat: 48.4474243, lng: 17.7365759 },
    { lat: 48.4472704, lng: 17.7364979 },
    { lat: 48.4471676, lng: 17.7361869 },
    { lat: 48.4469617, lng: 17.7358337 },
    { lat: 48.4468747, lng: 17.7358306 },
    { lat: 48.4467313, lng: 17.7361782 },
    { lat: 48.4465219, lng: 17.7362619 },
    { lat: 48.4465522, lng: 17.7366072 },
    { lat: 48.4464975, lng: 17.736962 },
    { lat: 48.4464514, lng: 17.7370004 },
    { lat: 48.4463436, lng: 17.7369358 },
    { lat: 48.446302, lng: 17.7369939 },
    { lat: 48.4463184, lng: 17.7372441 },
    { lat: 48.446389, lng: 17.7373445 },
    { lat: 48.4464085, lng: 17.7374961 },
    { lat: 48.4462747, lng: 17.7377036 },
    { lat: 48.4459838, lng: 17.7378393 },
    { lat: 48.445755, lng: 17.7380989 },
    { lat: 48.4456925, lng: 17.7380749 },
    { lat: 48.4454442, lng: 17.7377099 },
    { lat: 48.445278, lng: 17.737635 },
    { lat: 48.4447706, lng: 17.7380463 },
    { lat: 48.4446058, lng: 17.7380476 },
    { lat: 48.4444955, lng: 17.7382533 },
    { lat: 48.4444872, lng: 17.7383757 },
    { lat: 48.4448072, lng: 17.738821 },
    { lat: 48.4447264, lng: 17.7391011 },
    { lat: 48.4447633, lng: 17.7394231 },
    { lat: 48.4449859, lng: 17.7395556 },
    { lat: 48.4452103, lng: 17.7394058 },
    { lat: 48.4452582, lng: 17.7395399 },
    { lat: 48.4451924, lng: 17.7397358 },
    { lat: 48.4452045, lng: 17.740004 },
    { lat: 48.4452647, lng: 17.7401005 },
    { lat: 48.445659, lng: 17.7402865 },
    { lat: 48.4455893, lng: 17.7405146 },
    { lat: 48.4454408, lng: 17.7406303 },
    { lat: 48.4452724, lng: 17.7411584 },
    { lat: 48.4449894, lng: 17.7412343 },
    { lat: 48.4448039, lng: 17.7416119 },
    { lat: 48.4446329, lng: 17.7416785 },
    { lat: 48.4446829, lng: 17.7418667 },
    { lat: 48.4450565, lng: 17.7426864 },
    { lat: 48.4451683, lng: 17.7432693 },
    { lat: 48.4454569, lng: 17.7434451 },
    { lat: 48.4438144, lng: 17.7495519 },
    { lat: 48.4436341, lng: 17.7493367 },
    { lat: 48.4435642, lng: 17.7500847 },
    { lat: 48.4435883, lng: 17.7512786 },
    { lat: 48.4435478, lng: 17.7517038 },
    { lat: 48.4434579, lng: 17.7521136 },
    { lat: 48.4431719, lng: 17.7528383 },
    { lat: 48.4430942, lng: 17.7533527 },
    { lat: 48.4430308, lng: 17.7546477 },
    { lat: 48.4431936, lng: 17.7556761 },
    { lat: 48.4433944, lng: 17.756276 },
    { lat: 48.4435994, lng: 17.756597 },
    { lat: 48.4439786, lng: 17.7569793 },
    { lat: 48.4442562, lng: 17.7571227 },
    { lat: 48.4455859, lng: 17.7573188 },
    { lat: 48.4481853, lng: 17.7585555 },
    { lat: 48.4491806, lng: 17.7594252 },
    { lat: 48.449957, lng: 17.7598195 },
    { lat: 48.4504939, lng: 17.759979 },
    { lat: 48.4520232, lng: 17.7602245 },
    { lat: 48.4520656, lng: 17.7606735 },
    { lat: 48.4526968, lng: 17.7612253 },
    { lat: 48.4536481, lng: 17.7622614 },
    { lat: 48.4542791, lng: 17.7628271 },
    { lat: 48.4551115, lng: 17.7640065 },
    { lat: 48.4555065, lng: 17.7643505 },
    { lat: 48.4556995, lng: 17.7644217 },
    { lat: 48.4549831, lng: 17.770146 },
    { lat: 48.4547878, lng: 17.7722716 },
    { lat: 48.4545758, lng: 17.7729356 },
    { lat: 48.4538488, lng: 17.7785327 },
    { lat: 48.4535895, lng: 17.7789055 },
    { lat: 48.4539979, lng: 17.7791215 },
    { lat: 48.4545245, lng: 17.7796792 },
    { lat: 48.4547993, lng: 17.7800934 },
    { lat: 48.4550201, lng: 17.7805781 },
    { lat: 48.455398, lng: 17.781988 },
    { lat: 48.4555337, lng: 17.782764 },
    { lat: 48.4555566, lng: 17.7851911 },
    { lat: 48.4563271, lng: 17.78759 },
    { lat: 48.4563677, lng: 17.7877156 },
    { lat: 48.4617033, lng: 17.7884923 },
    { lat: 48.4618049, lng: 17.7884166 },
    { lat: 48.4662168, lng: 17.7891332 },
    { lat: 48.466571, lng: 17.7882633 },
    { lat: 48.4669404, lng: 17.7871302 },
    { lat: 48.4690275, lng: 17.7854866 },
    { lat: 48.4694338, lng: 17.7847336 },
    { lat: 48.4696225, lng: 17.7835575 },
    { lat: 48.4696612, lng: 17.782059 },
    { lat: 48.4709119, lng: 17.7820004 },
    { lat: 48.4720274, lng: 17.7739773 },
    { lat: 48.4717631, lng: 17.7737045 },
    { lat: 48.4720321, lng: 17.7717249 },
    { lat: 48.4734604, lng: 17.7634456 },
    { lat: 48.4749629, lng: 17.7636986 },
    { lat: 48.4779318, lng: 17.764075 },
    { lat: 48.4781319, lng: 17.7638983 },
    { lat: 48.4782459, lng: 17.7640814 },
    { lat: 48.4818735, lng: 17.7645951 },
    { lat: 48.4820346, lng: 17.7645185 },
    { lat: 48.4821719, lng: 17.7646382 },
    { lat: 48.48773, lng: 17.765494 },
    { lat: 48.487567, lng: 17.764304 },
    { lat: 48.4876, lng: 17.76409 },
    { lat: 48.487618, lng: 17.76401 },
    { lat: 48.487646, lng: 17.763904 },
    { lat: 48.487668, lng: 17.763705 },
    { lat: 48.487636, lng: 17.763236 },
    { lat: 48.487573, lng: 17.762495 },
    { lat: 48.487519, lng: 17.762201 },
    { lat: 48.487447, lng: 17.761883 },
    { lat: 48.487407, lng: 17.761741 },
    { lat: 48.487338, lng: 17.761552 },
    { lat: 48.487313, lng: 17.76137 },
    { lat: 48.487313, lng: 17.761238 },
    { lat: 48.487338, lng: 17.760946 },
    { lat: 48.487314, lng: 17.760797 },
    { lat: 48.487304, lng: 17.760731 },
    { lat: 48.487293, lng: 17.760666 },
    { lat: 48.487288, lng: 17.760631 },
    { lat: 48.487263, lng: 17.760473 },
    { lat: 48.487056, lng: 17.759786 },
    { lat: 48.48702, lng: 17.759643 },
    { lat: 48.487018, lng: 17.7595 },
    { lat: 48.487052, lng: 17.759302 },
    { lat: 48.487083, lng: 17.759225 },
    { lat: 48.487176, lng: 17.758967 },
    { lat: 48.487184, lng: 17.758826 },
    { lat: 48.487075, lng: 17.758626 },
    { lat: 48.487003, lng: 17.758523 },
    { lat: 48.487007, lng: 17.758459 },
    { lat: 48.487146, lng: 17.757995 },
    { lat: 48.487166, lng: 17.757759 },
    { lat: 48.487283, lng: 17.757321 },
    { lat: 48.487251, lng: 17.757094 },
    { lat: 48.48727, lng: 17.757011 },
    { lat: 48.487439, lng: 17.756563 },
    { lat: 48.487636, lng: 17.756108 },
    { lat: 48.48774, lng: 17.755868 },
    { lat: 48.487581, lng: 17.755534 },
    { lat: 48.487435, lng: 17.755289 },
    { lat: 48.48719, lng: 17.75491 },
    { lat: 48.487116, lng: 17.754689 },
    { lat: 48.487006, lng: 17.753926 },
    { lat: 48.487006, lng: 17.753766 },
    { lat: 48.486999, lng: 17.753536 },
    { lat: 48.486978, lng: 17.753342 },
    { lat: 48.486858, lng: 17.753065 },
    { lat: 48.486758, lng: 17.752927 },
    { lat: 48.48658, lng: 17.752629 },
    { lat: 48.486406, lng: 17.752252 },
    { lat: 48.48616, lng: 17.751796 },
    { lat: 48.48616, lng: 17.751793 },
    { lat: 48.486163, lng: 17.751766 },
  ],
  Tekolďany: [
    { lat: 48.493257, lng: 17.915405 },
    { lat: 48.493325, lng: 17.915203 },
    { lat: 48.493595, lng: 17.914735 },
    { lat: 48.493706, lng: 17.914487 },
    { lat: 48.493841, lng: 17.914173 },
    { lat: 48.493898, lng: 17.913919 },
    { lat: 48.494403, lng: 17.912742 },
    { lat: 48.494554, lng: 17.912446 },
    { lat: 48.494708, lng: 17.912185 },
    { lat: 48.49541, lng: 17.910998 },
    { lat: 48.495563, lng: 17.910626 },
    { lat: 48.495911, lng: 17.910179 },
    { lat: 48.495976, lng: 17.910073 },
    { lat: 48.496296, lng: 17.909421 },
    { lat: 48.496541, lng: 17.908806 },
    { lat: 48.496925, lng: 17.907372 },
    { lat: 48.497158, lng: 17.906134 },
    { lat: 48.497517, lng: 17.905018 },
    { lat: 48.497923, lng: 17.904065 },
    { lat: 48.498061, lng: 17.90371 },
    { lat: 48.498418, lng: 17.90287 },
    { lat: 48.499098, lng: 17.901449 },
    { lat: 48.499538, lng: 17.900801 },
    { lat: 48.500578, lng: 17.899497 },
    { lat: 48.500829, lng: 17.899152 },
    { lat: 48.501229, lng: 17.898435 },
    { lat: 48.501439, lng: 17.897761 },
    { lat: 48.501528, lng: 17.897554 },
    { lat: 48.501738, lng: 17.896963 },
    { lat: 48.501787, lng: 17.89678 },
    { lat: 48.501845, lng: 17.89665 },
    { lat: 48.501892, lng: 17.896666 },
    { lat: 48.501938, lng: 17.896542 },
    { lat: 48.501975, lng: 17.896313 },
    { lat: 48.502043, lng: 17.896047 },
    { lat: 48.502199, lng: 17.895392 },
    { lat: 48.502279, lng: 17.895173 },
    { lat: 48.502637, lng: 17.894491 },
    { lat: 48.502968, lng: 17.893916 },
    { lat: 48.50306, lng: 17.893785 },
    { lat: 48.503231, lng: 17.893483 },
    { lat: 48.503339, lng: 17.89331 },
    { lat: 48.503407, lng: 17.893216 },
    { lat: 48.503493, lng: 17.893102 },
    { lat: 48.503548, lng: 17.893049 },
    { lat: 48.503681, lng: 17.892776 },
    { lat: 48.503778, lng: 17.892527 },
    { lat: 48.503981, lng: 17.892072 },
    { lat: 48.504057, lng: 17.891741 },
    { lat: 48.504118, lng: 17.891623 },
    { lat: 48.504199, lng: 17.891439 },
    { lat: 48.504321, lng: 17.891249 },
    { lat: 48.504385, lng: 17.891044 },
    { lat: 48.50441, lng: 17.890967 },
    { lat: 48.504435, lng: 17.890955 },
    { lat: 48.504555, lng: 17.890669 },
    { lat: 48.504703, lng: 17.890295 },
    { lat: 48.504766, lng: 17.890105 },
    { lat: 48.504983, lng: 17.88964 },
    { lat: 48.505091, lng: 17.889308 },
    { lat: 48.505244, lng: 17.88871 },
    { lat: 48.505244, lng: 17.888416 },
    { lat: 48.505225, lng: 17.888175 },
    { lat: 48.50521, lng: 17.887935 },
    { lat: 48.505214, lng: 17.887682 },
    { lat: 48.505193, lng: 17.887559 },
    { lat: 48.505211, lng: 17.887406 },
    { lat: 48.505042, lng: 17.886976 },
    { lat: 48.504975, lng: 17.886878 },
    { lat: 48.504925, lng: 17.886715 },
    { lat: 48.504884, lng: 17.886598 },
    { lat: 48.504816, lng: 17.88606 },
    { lat: 48.504679, lng: 17.8851 },
    { lat: 48.5043205, lng: 17.8852805 },
    { lat: 48.5040293, lng: 17.8855262 },
    { lat: 48.5036346, lng: 17.8863328 },
    { lat: 48.5030524, lng: 17.8861331 },
    { lat: 48.5027206, lng: 17.885927 },
    { lat: 48.5026516, lng: 17.8860179 },
    { lat: 48.5023982, lng: 17.8867368 },
    { lat: 48.5020139, lng: 17.8872148 },
    { lat: 48.5018392, lng: 17.8876656 },
    { lat: 48.5015755, lng: 17.8880106 },
    { lat: 48.5012155, lng: 17.888664 },
    { lat: 48.5010366, lng: 17.8891783 },
    { lat: 48.500902, lng: 17.8899015 },
    { lat: 48.5005421, lng: 17.8907452 },
    { lat: 48.5004723, lng: 17.8907843 },
    { lat: 48.5004199, lng: 17.8909271 },
    { lat: 48.5001696, lng: 17.8922549 },
    { lat: 48.5000552, lng: 17.8925111 },
    { lat: 48.4999315, lng: 17.8929854 },
    { lat: 48.4999326, lng: 17.8933387 },
    { lat: 48.4998698, lng: 17.8935876 },
    { lat: 48.4996539, lng: 17.8940709 },
    { lat: 48.4998759, lng: 17.8943764 },
    { lat: 48.4995382, lng: 17.8947353 },
    { lat: 48.4991321, lng: 17.8959886 },
    { lat: 48.4989846, lng: 17.8963148 },
    { lat: 48.4986009, lng: 17.8968731 },
    { lat: 48.4981944, lng: 17.897337 },
    { lat: 48.4979202, lng: 17.8974734 },
    { lat: 48.4978544, lng: 17.8975789 },
    { lat: 48.4976124, lng: 17.8975704 },
    { lat: 48.4973473, lng: 17.8976856 },
    { lat: 48.4972375, lng: 17.8979423 },
    { lat: 48.4967903, lng: 17.8984455 },
    { lat: 48.4966596, lng: 17.8987642 },
    { lat: 48.4958582, lng: 17.8996055 },
    { lat: 48.4950773, lng: 17.9002276 },
    { lat: 48.4946476, lng: 17.900664 },
    { lat: 48.4945142, lng: 17.9007146 },
    { lat: 48.4942493, lng: 17.9011541 },
    { lat: 48.4941331, lng: 17.9012465 },
    { lat: 48.4939288, lng: 17.9012282 },
    { lat: 48.4934904, lng: 17.9015756 },
    { lat: 48.4933382, lng: 17.9014872 },
    { lat: 48.4931179, lng: 17.9016316 },
    { lat: 48.4929961, lng: 17.9018233 },
    { lat: 48.4923193, lng: 17.9024845 },
    { lat: 48.4920676, lng: 17.9025236 },
    { lat: 48.4918583, lng: 17.9028137 },
    { lat: 48.4916307, lng: 17.9029303 },
    { lat: 48.491516, lng: 17.903126 },
    { lat: 48.4914392, lng: 17.9036692 },
    { lat: 48.4912355, lng: 17.9040722 },
    { lat: 48.4911887, lng: 17.9042535 },
    { lat: 48.4911431, lng: 17.90443 },
    { lat: 48.4909474, lng: 17.904703 },
    { lat: 48.4909112, lng: 17.9051296 },
    { lat: 48.490685, lng: 17.9052737 },
    { lat: 48.4906252, lng: 17.9055723 },
    { lat: 48.4904453, lng: 17.9058285 },
    { lat: 48.4903257, lng: 17.9058292 },
    { lat: 48.4899779, lng: 17.9061464 },
    { lat: 48.4897716, lng: 17.9062241 },
    { lat: 48.4896056, lng: 17.9064471 },
    { lat: 48.489265, lng: 17.9064716 },
    { lat: 48.4888217, lng: 17.9067773 },
    { lat: 48.4882111, lng: 17.9077674 },
    { lat: 48.4872601, lng: 17.9086542 },
    { lat: 48.4867412, lng: 17.9092791 },
    { lat: 48.4863858, lng: 17.9095084 },
    { lat: 48.486112, lng: 17.9099849 },
    { lat: 48.4855931, lng: 17.9103628 },
    { lat: 48.4849313, lng: 17.9107709 },
    { lat: 48.483832, lng: 17.9112778 },
    { lat: 48.4832772, lng: 17.9114735 },
    { lat: 48.482797, lng: 17.911539 },
    { lat: 48.4820694, lng: 17.9122008 },
    { lat: 48.4817368, lng: 17.9126945 },
    { lat: 48.4815074, lng: 17.9131425 },
    { lat: 48.4812002, lng: 17.9140536 },
    { lat: 48.481005, lng: 17.9148409 },
    { lat: 48.4806881, lng: 17.9170007 },
    { lat: 48.4804226, lng: 17.9183096 },
    { lat: 48.4802056, lng: 17.9190533 },
    { lat: 48.4799835, lng: 17.919619 },
    { lat: 48.4797248, lng: 17.9199734 },
    { lat: 48.4794307, lng: 17.9207699 },
    { lat: 48.4792214, lng: 17.9214929 },
    { lat: 48.4789718, lng: 17.9227056 },
    { lat: 48.4789262, lng: 17.9231494 },
    { lat: 48.478853, lng: 17.9233205 },
    { lat: 48.4785217, lng: 17.9237613 },
    { lat: 48.4775227, lng: 17.9239678 },
    { lat: 48.4772657, lng: 17.9241227 },
    { lat: 48.4768595, lng: 17.924425 },
    { lat: 48.4760987, lng: 17.9252526 },
    { lat: 48.4759098, lng: 17.925321 },
    { lat: 48.4757152, lng: 17.9256862 },
    { lat: 48.4769964, lng: 17.9276384 },
    { lat: 48.4771915, lng: 17.9275236 },
    { lat: 48.4781486, lng: 17.9291511 },
    { lat: 48.4781248, lng: 17.9293927 },
    { lat: 48.478161, lng: 17.9295472 },
    { lat: 48.478759, lng: 17.9302357 },
    { lat: 48.4804859, lng: 17.9326057 },
    { lat: 48.480496, lng: 17.932594 },
    { lat: 48.480803, lng: 17.9322 },
    { lat: 48.480987, lng: 17.931945 },
    { lat: 48.481268, lng: 17.931505 },
    { lat: 48.481486, lng: 17.931168 },
    { lat: 48.481611, lng: 17.931001 },
    { lat: 48.481673, lng: 17.930917 },
    { lat: 48.481794, lng: 17.930748 },
    { lat: 48.481999, lng: 17.930459 },
    { lat: 48.482028, lng: 17.930419 },
    { lat: 48.482181, lng: 17.930184 },
    { lat: 48.482448, lng: 17.929787 },
    { lat: 48.482758, lng: 17.929273 },
    { lat: 48.482837, lng: 17.929141 },
    { lat: 48.483589, lng: 17.927805 },
    { lat: 48.483962, lng: 17.927264 },
    { lat: 48.484455, lng: 17.926612 },
    { lat: 48.484589, lng: 17.926441 },
    { lat: 48.485181, lng: 17.925737 },
    { lat: 48.48549, lng: 17.92522 },
    { lat: 48.485784, lng: 17.924803 },
    { lat: 48.486293, lng: 17.924137 },
    { lat: 48.486421, lng: 17.923936 },
    { lat: 48.486535, lng: 17.923775 },
    { lat: 48.486706, lng: 17.923511 },
    { lat: 48.486839, lng: 17.923304 },
    { lat: 48.487015, lng: 17.92291 },
    { lat: 48.487174, lng: 17.922529 },
    { lat: 48.487459, lng: 17.921774 },
    { lat: 48.487645, lng: 17.921258 },
    { lat: 48.487807, lng: 17.920794 },
    { lat: 48.487917, lng: 17.920493 },
    { lat: 48.487982, lng: 17.920316 },
    { lat: 48.488059, lng: 17.920136 },
    { lat: 48.488115, lng: 17.920016 },
    { lat: 48.488131, lng: 17.919997 },
    { lat: 48.488615, lng: 17.919371 },
    { lat: 48.488733, lng: 17.919214 },
    { lat: 48.488963, lng: 17.918928 },
    { lat: 48.489075, lng: 17.918788 },
    { lat: 48.489365, lng: 17.918488 },
    { lat: 48.489422, lng: 17.918441 },
    { lat: 48.48967, lng: 17.918235 },
    { lat: 48.489686, lng: 17.918224 },
    { lat: 48.489937, lng: 17.918061 },
    { lat: 48.490127, lng: 17.917937 },
    { lat: 48.490196, lng: 17.917878 },
    { lat: 48.490316, lng: 17.917775 },
    { lat: 48.490445, lng: 17.917665 },
    { lat: 48.490682, lng: 17.917462 },
    { lat: 48.490709, lng: 17.917443 },
    { lat: 48.490972, lng: 17.917257 },
    { lat: 48.491014, lng: 17.917226 },
    { lat: 48.491287, lng: 17.917063 },
    { lat: 48.491585, lng: 17.916883 },
    { lat: 48.491881, lng: 17.916704 },
    { lat: 48.491988, lng: 17.916629 },
    { lat: 48.492041, lng: 17.916589 },
    { lat: 48.492184, lng: 17.916476 },
    { lat: 48.492416, lng: 17.916281 },
    { lat: 48.492487, lng: 17.91622 },
    { lat: 48.492626, lng: 17.916101 },
    { lat: 48.492736, lng: 17.916034 },
    { lat: 48.492955, lng: 17.915833 },
    { lat: 48.49318, lng: 17.915556 },
    { lat: 48.493213, lng: 17.915496 },
    { lat: 48.493257, lng: 17.915405 },
  ],
  Madunice: [
    { lat: 48.48773, lng: 17.765494 },
    { lat: 48.4821719, lng: 17.7646382 },
    { lat: 48.4820346, lng: 17.7645185 },
    { lat: 48.4818735, lng: 17.7645951 },
    { lat: 48.4782459, lng: 17.7640814 },
    { lat: 48.4781319, lng: 17.7638983 },
    { lat: 48.4779318, lng: 17.764075 },
    { lat: 48.4749629, lng: 17.7636986 },
    { lat: 48.4734604, lng: 17.7634456 },
    { lat: 48.4720321, lng: 17.7717249 },
    { lat: 48.4717631, lng: 17.7737045 },
    { lat: 48.4720274, lng: 17.7739773 },
    { lat: 48.4709119, lng: 17.7820004 },
    { lat: 48.4696612, lng: 17.782059 },
    { lat: 48.4696225, lng: 17.7835575 },
    { lat: 48.4694338, lng: 17.7847336 },
    { lat: 48.4690275, lng: 17.7854866 },
    { lat: 48.4669404, lng: 17.7871302 },
    { lat: 48.466571, lng: 17.7882633 },
    { lat: 48.4662168, lng: 17.7891332 },
    { lat: 48.4618049, lng: 17.7884166 },
    { lat: 48.4617033, lng: 17.7884923 },
    { lat: 48.4563677, lng: 17.7877156 },
    { lat: 48.4559774, lng: 17.7876848 },
    { lat: 48.4527638, lng: 17.7872737 },
    { lat: 48.4518483, lng: 17.787308 },
    { lat: 48.4509471, lng: 17.7874723 },
    { lat: 48.4500644, lng: 17.7877684 },
    { lat: 48.4491781, lng: 17.7881518 },
    { lat: 48.4482816, lng: 17.7887919 },
    { lat: 48.4470107, lng: 17.7899555 },
    { lat: 48.4470304, lng: 17.789983 },
    { lat: 48.4470574, lng: 17.7900183 },
    { lat: 48.4457448, lng: 17.7916613 },
    { lat: 48.4455932, lng: 17.7935492 },
    { lat: 48.4463, lng: 17.7924654 },
    { lat: 48.446319, lng: 17.7926086 },
    { lat: 48.4468476, lng: 17.7920406 },
    { lat: 48.4487143, lng: 17.7911492 },
    { lat: 48.4503009, lng: 17.7906428 },
    { lat: 48.4513794, lng: 17.7904272 },
    { lat: 48.4527286, lng: 17.7903004 },
    { lat: 48.4538901, lng: 17.790296 },
    { lat: 48.4546228, lng: 17.79037 },
    { lat: 48.4560199, lng: 17.7906569 },
    { lat: 48.4562602, lng: 17.7906519 },
    { lat: 48.4614818, lng: 17.7917414 },
    { lat: 48.4626788, lng: 17.7921689 },
    { lat: 48.46359, lng: 17.7925948 },
    { lat: 48.4651687, lng: 17.7936285 },
    { lat: 48.4681087, lng: 17.7965759 },
    { lat: 48.4687416, lng: 17.7992572 },
    { lat: 48.4689947, lng: 17.8001445 },
    { lat: 48.4696924, lng: 17.8003455 },
    { lat: 48.4704637, lng: 17.8005516 },
    { lat: 48.472159, lng: 17.8003824 },
    { lat: 48.4762749, lng: 17.7987025 },
    { lat: 48.479638, lng: 17.7983712 },
    { lat: 48.4835894, lng: 17.8018892 },
    { lat: 48.4872821, lng: 17.804007 },
    { lat: 48.496329, lng: 17.802823 },
    { lat: 48.496718, lng: 17.800858 },
    { lat: 48.496818, lng: 17.800358 },
    { lat: 48.496881, lng: 17.800054 },
    { lat: 48.49692, lng: 17.799789 },
    { lat: 48.496959, lng: 17.799696 },
    { lat: 48.497028, lng: 17.799597 },
    { lat: 48.497111, lng: 17.799464 },
    { lat: 48.497265, lng: 17.799289 },
    { lat: 48.497293, lng: 17.799244 },
    { lat: 48.497382, lng: 17.799129 },
    { lat: 48.49743, lng: 17.799064 },
    { lat: 48.49757, lng: 17.7988 },
    { lat: 48.497639, lng: 17.798737 },
    { lat: 48.49771, lng: 17.798716 },
    { lat: 48.497887, lng: 17.798715 },
    { lat: 48.497933, lng: 17.798648 },
    { lat: 48.498048, lng: 17.798504 },
    { lat: 48.498152, lng: 17.798466 },
    { lat: 48.498244, lng: 17.798409 },
    { lat: 48.498333, lng: 17.798311 },
    { lat: 48.498446, lng: 17.79813 },
    { lat: 48.498551, lng: 17.798014 },
    { lat: 48.498658, lng: 17.797918 },
    { lat: 48.498746, lng: 17.797834 },
    { lat: 48.498791, lng: 17.797792 },
    { lat: 48.49897, lng: 17.797594 },
    { lat: 48.499258, lng: 17.797159 },
    { lat: 48.499302, lng: 17.7971 },
    { lat: 48.499343, lng: 17.797035 },
    { lat: 48.49938, lng: 17.79697 },
    { lat: 48.499482, lng: 17.796778 },
    { lat: 48.499632, lng: 17.796485 },
    { lat: 48.499678, lng: 17.796407 },
    { lat: 48.499766, lng: 17.796188 },
    { lat: 48.499805, lng: 17.796098 },
    { lat: 48.499864, lng: 17.795997 },
    { lat: 48.499991, lng: 17.795804 },
    { lat: 48.499999, lng: 17.795791 },
    { lat: 48.500005, lng: 17.795784 },
    { lat: 48.500016, lng: 17.795769 },
    { lat: 48.50003, lng: 17.79575 },
    { lat: 48.500038, lng: 17.795739 },
    { lat: 48.500067, lng: 17.795694 },
    { lat: 48.500071, lng: 17.79568 },
    { lat: 48.500085, lng: 17.795633 },
    { lat: 48.500142, lng: 17.795497 },
    { lat: 48.500162, lng: 17.795441 },
    { lat: 48.500207, lng: 17.795332 },
    { lat: 48.500233, lng: 17.79527 },
    { lat: 48.500266, lng: 17.795184 },
    { lat: 48.500301, lng: 17.795094 },
    { lat: 48.50034, lng: 17.794939 },
    { lat: 48.500364, lng: 17.794779 },
    { lat: 48.5004, lng: 17.794573 },
    { lat: 48.500502, lng: 17.794333 },
    { lat: 48.500578, lng: 17.794032 },
    { lat: 48.500646, lng: 17.793801 },
    { lat: 48.500698, lng: 17.793699 },
    { lat: 48.500783, lng: 17.793502 },
    { lat: 48.50088, lng: 17.793363 },
    { lat: 48.500981, lng: 17.793213 },
    { lat: 48.501094, lng: 17.793033 },
    { lat: 48.501128, lng: 17.792969 },
    { lat: 48.501195, lng: 17.792847 },
    { lat: 48.50126, lng: 17.792767 },
    { lat: 48.50127, lng: 17.792756 },
    { lat: 48.501297, lng: 17.792722 },
    { lat: 48.501388, lng: 17.792627 },
    { lat: 48.501497, lng: 17.792472 },
    { lat: 48.501624, lng: 17.792316 },
    { lat: 48.501692, lng: 17.792261 },
    { lat: 48.501761, lng: 17.792218 },
    { lat: 48.501893, lng: 17.792143 },
    { lat: 48.501989, lng: 17.79213 },
    { lat: 48.50212, lng: 17.792029 },
    { lat: 48.502209, lng: 17.791966 },
    { lat: 48.502359, lng: 17.791909 },
    { lat: 48.502448, lng: 17.791878 },
    { lat: 48.502532, lng: 17.791844 },
    { lat: 48.502739, lng: 17.791745 },
    { lat: 48.502806, lng: 17.791707 },
    { lat: 48.50286, lng: 17.791676 },
    { lat: 48.502936, lng: 17.791654 },
    { lat: 48.503064, lng: 17.791687 },
    { lat: 48.503127, lng: 17.791712 },
    { lat: 48.503198, lng: 17.791739 },
    { lat: 48.503337, lng: 17.791686 },
    { lat: 48.503366, lng: 17.791655 },
    { lat: 48.503408, lng: 17.791573 },
    { lat: 48.503514, lng: 17.791486 },
    { lat: 48.503638, lng: 17.791501 },
    { lat: 48.503845, lng: 17.791504 },
    { lat: 48.504002, lng: 17.791587 },
    { lat: 48.504113, lng: 17.791674 },
    { lat: 48.50416, lng: 17.791747 },
    { lat: 48.504192, lng: 17.791758 },
    { lat: 48.504316, lng: 17.791792 },
    { lat: 48.504427, lng: 17.79182 },
    { lat: 48.5045, lng: 17.791856 },
    { lat: 48.504566, lng: 17.791877 },
    { lat: 48.504649, lng: 17.791893 },
    { lat: 48.504722, lng: 17.791905 },
    { lat: 48.504811, lng: 17.791923 },
    { lat: 48.50491, lng: 17.79207 },
    { lat: 48.505007, lng: 17.792242 },
    { lat: 48.505101, lng: 17.792424 },
    { lat: 48.505228, lng: 17.792419 },
    { lat: 48.505261, lng: 17.79236 },
    { lat: 48.505347, lng: 17.792229 },
    { lat: 48.505442, lng: 17.792077 },
    { lat: 48.5056, lng: 17.791789 },
    { lat: 48.505641, lng: 17.791712 },
    { lat: 48.505727, lng: 17.791516 },
    { lat: 48.505818, lng: 17.791409 },
    { lat: 48.50602, lng: 17.791256 },
    { lat: 48.506189, lng: 17.791174 },
    { lat: 48.506511, lng: 17.791 },
    { lat: 48.506575, lng: 17.790974 },
    { lat: 48.506633, lng: 17.790956 },
    { lat: 48.506695, lng: 17.790933 },
    { lat: 48.506762, lng: 17.790903 },
    { lat: 48.506824, lng: 17.790874 },
    { lat: 48.506893, lng: 17.790783 },
    { lat: 48.506927, lng: 17.790721 },
    { lat: 48.506971, lng: 17.790618 },
    { lat: 48.506984, lng: 17.790602 },
    { lat: 48.507003, lng: 17.790579 },
    { lat: 48.507055, lng: 17.79052 },
    { lat: 48.507074, lng: 17.790512 },
    { lat: 48.507095, lng: 17.790502 },
    { lat: 48.507145, lng: 17.79048 },
    { lat: 48.507257, lng: 17.790446 },
    { lat: 48.507386, lng: 17.790424 },
    { lat: 48.507465, lng: 17.790458 },
    { lat: 48.507607, lng: 17.790591 },
    { lat: 48.50774, lng: 17.790715 },
    { lat: 48.507807, lng: 17.790777 },
    { lat: 48.507831, lng: 17.790799 },
    { lat: 48.507855, lng: 17.790821 },
    { lat: 48.507928, lng: 17.790936 },
    { lat: 48.507948, lng: 17.790972 },
    { lat: 48.508022, lng: 17.791105 },
    { lat: 48.508099, lng: 17.79124 },
    { lat: 48.508202, lng: 17.791424 },
    { lat: 48.508245, lng: 17.791421 },
    { lat: 48.508336, lng: 17.7914 },
    { lat: 48.508411, lng: 17.791323 },
    { lat: 48.508451, lng: 17.791248 },
    { lat: 48.508602, lng: 17.791115 },
    { lat: 48.508723, lng: 17.791048 },
    { lat: 48.508813, lng: 17.790952 },
    { lat: 48.508894, lng: 17.790866 },
    { lat: 48.508999, lng: 17.790754 },
    { lat: 48.509057, lng: 17.790701 },
    { lat: 48.509154, lng: 17.790513 },
    { lat: 48.509249, lng: 17.790458 },
    { lat: 48.509271, lng: 17.790449 },
    { lat: 48.509378, lng: 17.790475 },
    { lat: 48.509436, lng: 17.790509 },
    { lat: 48.509483, lng: 17.790647 },
    { lat: 48.509516, lng: 17.790947 },
    { lat: 48.50953, lng: 17.790987 },
    { lat: 48.509548, lng: 17.791039 },
    { lat: 48.509627, lng: 17.791117 },
    { lat: 48.509695, lng: 17.791111 },
    { lat: 48.509838, lng: 17.791051 },
    { lat: 48.509865, lng: 17.79104 },
    { lat: 48.509964, lng: 17.791035 },
    { lat: 48.510009, lng: 17.791061 },
    { lat: 48.510021, lng: 17.791088 },
    { lat: 48.510076, lng: 17.791216 },
    { lat: 48.510093, lng: 17.791254 },
    { lat: 48.510322, lng: 17.791253 },
    { lat: 48.510444, lng: 17.791309 },
    { lat: 48.510522, lng: 17.791347 },
    { lat: 48.510596, lng: 17.791367 },
    { lat: 48.510734, lng: 17.791363 },
    { lat: 48.51084, lng: 17.791408 },
    { lat: 48.510869, lng: 17.791429 },
    { lat: 48.510908, lng: 17.791463 },
    { lat: 48.51089, lng: 17.791298 },
    { lat: 48.510889, lng: 17.791022 },
    { lat: 48.510906, lng: 17.790852 },
    { lat: 48.511104, lng: 17.790159 },
    { lat: 48.511233, lng: 17.789544 },
    { lat: 48.511362, lng: 17.78917 },
    { lat: 48.511549, lng: 17.788849 },
    { lat: 48.511921, lng: 17.788245 },
    { lat: 48.512079, lng: 17.788039 },
    { lat: 48.512297, lng: 17.787767 },
    { lat: 48.512574, lng: 17.787273 },
    { lat: 48.513035, lng: 17.786434 },
    { lat: 48.513194, lng: 17.786121 },
    { lat: 48.513293, lng: 17.785825 },
    { lat: 48.513439, lng: 17.785225 },
    { lat: 48.513565, lng: 17.784657 },
    { lat: 48.513612, lng: 17.784341 },
    { lat: 48.513672, lng: 17.783653 },
    { lat: 48.51373, lng: 17.783297 },
    { lat: 48.513819, lng: 17.783038 },
    { lat: 48.514197, lng: 17.782194 },
    { lat: 48.5142, lng: 17.782188 },
    { lat: 48.514267, lng: 17.78206 },
    { lat: 48.514286, lng: 17.782022 },
    { lat: 48.514289, lng: 17.782018 },
    { lat: 48.514394, lng: 17.781816 },
    { lat: 48.514404, lng: 17.781797 },
    { lat: 48.51446, lng: 17.781696 },
    { lat: 48.514911, lng: 17.78086 },
    { lat: 48.515374, lng: 17.779753 },
    { lat: 48.515708, lng: 17.779001 },
    { lat: 48.515864, lng: 17.778704 },
    { lat: 48.516035, lng: 17.778413 },
    { lat: 48.516197, lng: 17.778103 },
    { lat: 48.516337, lng: 17.777807 },
    { lat: 48.516394, lng: 17.777679 },
    { lat: 48.516482, lng: 17.777371 },
    { lat: 48.516727, lng: 17.776828 },
    { lat: 48.516758, lng: 17.776766 },
    { lat: 48.516826, lng: 17.776654 },
    { lat: 48.517052, lng: 17.776082 },
    { lat: 48.517364, lng: 17.775393 },
    { lat: 48.517372, lng: 17.775375 },
    { lat: 48.517408, lng: 17.775298 },
    { lat: 48.517414, lng: 17.775284 },
    { lat: 48.517446, lng: 17.775212 },
    { lat: 48.517506, lng: 17.775083 },
    { lat: 48.517529, lng: 17.775034 },
    { lat: 48.517585, lng: 17.774911 },
    { lat: 48.517614, lng: 17.774848 },
    { lat: 48.51762, lng: 17.774832 },
    { lat: 48.517904, lng: 17.774205 },
    { lat: 48.51799, lng: 17.774062 },
    { lat: 48.518003, lng: 17.774076 },
    { lat: 48.519676, lng: 17.770248 },
    { lat: 48.519667, lng: 17.770245 },
    { lat: 48.518748, lng: 17.76991 },
    { lat: 48.516886, lng: 17.769228 },
    { lat: 48.516755, lng: 17.76918 },
    { lat: 48.515269, lng: 17.768662 },
    { lat: 48.514503, lng: 17.768394 },
    { lat: 48.512688, lng: 17.767745 },
    { lat: 48.50937, lng: 17.766502 },
    { lat: 48.509363, lng: 17.766517 },
    { lat: 48.509291, lng: 17.766697 },
    { lat: 48.509181, lng: 17.767572 },
    { lat: 48.509072, lng: 17.767854 },
    { lat: 48.509002, lng: 17.768093 },
    { lat: 48.508931, lng: 17.768266 },
    { lat: 48.508805, lng: 17.768453 },
    { lat: 48.508662, lng: 17.768649 },
    { lat: 48.508434, lng: 17.768879 },
    { lat: 48.508377, lng: 17.768918 },
    { lat: 48.508316, lng: 17.768958 },
    { lat: 48.508221, lng: 17.768982 },
    { lat: 48.508149, lng: 17.769001 },
    { lat: 48.508, lng: 17.769039 },
    { lat: 48.507988, lng: 17.769047 },
    { lat: 48.507936, lng: 17.769084 },
    { lat: 48.507761, lng: 17.769198 },
    { lat: 48.508942, lng: 17.770185 },
    { lat: 48.509011, lng: 17.770241 },
    { lat: 48.509024, lng: 17.770252 },
    { lat: 48.509255, lng: 17.770444 },
    { lat: 48.50952, lng: 17.770665 },
    { lat: 48.509226, lng: 17.771506 },
    { lat: 48.508284, lng: 17.774206 },
    { lat: 48.509148, lng: 17.775168 },
    { lat: 48.509368, lng: 17.775234 },
    { lat: 48.509595, lng: 17.775477 },
    { lat: 48.509697, lng: 17.775252 },
    { lat: 48.510207, lng: 17.775795 },
    { lat: 48.5101, lng: 17.776032 },
    { lat: 48.510729, lng: 17.77672 },
    { lat: 48.510768, lng: 17.776772 },
    { lat: 48.511314, lng: 17.777377 },
    { lat: 48.511553, lng: 17.777894 },
    { lat: 48.51192, lng: 17.778247 },
    { lat: 48.512184, lng: 17.77889 },
    { lat: 48.512321, lng: 17.779088 },
    { lat: 48.512272, lng: 17.779191 },
    { lat: 48.512176, lng: 17.779392 },
    { lat: 48.512067, lng: 17.779629 },
    { lat: 48.512025, lng: 17.779713 },
    { lat: 48.511935, lng: 17.779628 },
    { lat: 48.511896, lng: 17.779584 },
    { lat: 48.511808, lng: 17.779495 },
    { lat: 48.51175, lng: 17.779431 },
    { lat: 48.511701, lng: 17.779376 },
    { lat: 48.511615, lng: 17.779276 },
    { lat: 48.51148, lng: 17.779128 },
    { lat: 48.511463, lng: 17.779108 },
    { lat: 48.511405, lng: 17.779043 },
    { lat: 48.511343, lng: 17.778977 },
    { lat: 48.511254, lng: 17.77888 },
    { lat: 48.51121, lng: 17.778832 },
    { lat: 48.511164, lng: 17.778784 },
    { lat: 48.511079, lng: 17.77869 },
    { lat: 48.510995, lng: 17.778599 },
    { lat: 48.510856, lng: 17.778444 },
    { lat: 48.510754, lng: 17.778334 },
    { lat: 48.51068, lng: 17.778249 },
    { lat: 48.510618, lng: 17.778188 },
    { lat: 48.510559, lng: 17.77812 },
    { lat: 48.510432, lng: 17.777981 },
    { lat: 48.510335, lng: 17.777879 },
    { lat: 48.510323, lng: 17.777864 },
    { lat: 48.510154, lng: 17.778201 },
    { lat: 48.510079, lng: 17.778121 },
    { lat: 48.510031, lng: 17.778068 },
    { lat: 48.509978, lng: 17.778013 },
    { lat: 48.509927, lng: 17.777958 },
    { lat: 48.509777, lng: 17.777797 },
    { lat: 48.509437, lng: 17.777427 },
    { lat: 48.509585, lng: 17.77713 },
    { lat: 48.509401, lng: 17.776937 },
    { lat: 48.509327, lng: 17.77685 },
    { lat: 48.509291, lng: 17.776809 },
    { lat: 48.509254, lng: 17.776772 },
    { lat: 48.509212, lng: 17.776726 },
    { lat: 48.509176, lng: 17.776687 },
    { lat: 48.509023, lng: 17.776524 },
    { lat: 48.508975, lng: 17.776472 },
    { lat: 48.508845, lng: 17.77633 },
    { lat: 48.508811, lng: 17.776293 },
    { lat: 48.508779, lng: 17.776257 },
    { lat: 48.508742, lng: 17.776217 },
    { lat: 48.508706, lng: 17.776176 },
    { lat: 48.508648, lng: 17.776114 },
    { lat: 48.508593, lng: 17.776053 },
    { lat: 48.508536, lng: 17.775991 },
    { lat: 48.50843, lng: 17.775875 },
    { lat: 48.508387, lng: 17.775833 },
    { lat: 48.508342, lng: 17.775781 },
    { lat: 48.508298, lng: 17.775733 },
    { lat: 48.508242, lng: 17.775673 },
    { lat: 48.508194, lng: 17.775622 },
    { lat: 48.508125, lng: 17.775546 },
    { lat: 48.508051, lng: 17.775465 },
    { lat: 48.508025, lng: 17.775436 },
    { lat: 48.507999, lng: 17.775407 },
    { lat: 48.507948, lng: 17.77535 },
    { lat: 48.507885, lng: 17.775284 },
    { lat: 48.507828, lng: 17.775221 },
    { lat: 48.507786, lng: 17.775175 },
    { lat: 48.507723, lng: 17.775109 },
    { lat: 48.507644, lng: 17.775024 },
    { lat: 48.507557, lng: 17.77493 },
    { lat: 48.507484, lng: 17.774842 },
    { lat: 48.507409, lng: 17.774754 },
    { lat: 48.507337, lng: 17.774673 },
    { lat: 48.507287, lng: 17.774618 },
    { lat: 48.507238, lng: 17.774578 },
    { lat: 48.507192, lng: 17.77454 },
    { lat: 48.507146, lng: 17.774505 },
    { lat: 48.507057, lng: 17.77443 },
    { lat: 48.506975, lng: 17.774364 },
    { lat: 48.506881, lng: 17.774315 },
    { lat: 48.506777, lng: 17.774254 },
    { lat: 48.506691, lng: 17.77423 },
    { lat: 48.50661, lng: 17.774337 },
    { lat: 48.506426, lng: 17.774571 },
    { lat: 48.505384, lng: 17.773418 },
    { lat: 48.505123, lng: 17.773132 },
    { lat: 48.505111, lng: 17.773118 },
    { lat: 48.505451, lng: 17.772664 },
    { lat: 48.505444, lng: 17.77265 },
    { lat: 48.505406, lng: 17.772582 },
    { lat: 48.505396, lng: 17.772565 },
    { lat: 48.504861, lng: 17.773242 },
    { lat: 48.504985, lng: 17.771894 },
    { lat: 48.504806, lng: 17.771695 },
    { lat: 48.504699, lng: 17.771583 },
    { lat: 48.504611, lng: 17.771485 },
    { lat: 48.504333, lng: 17.771194 },
    { lat: 48.504052, lng: 17.770895 },
    { lat: 48.503976, lng: 17.770816 },
    { lat: 48.503891, lng: 17.770732 },
    { lat: 48.503676, lng: 17.770503 },
    { lat: 48.50347, lng: 17.770288 },
    { lat: 48.503379, lng: 17.770195 },
    { lat: 48.503099, lng: 17.769916 },
    { lat: 48.503063, lng: 17.76988 },
    { lat: 48.503008, lng: 17.769825 },
    { lat: 48.50289, lng: 17.769702 },
    { lat: 48.502759, lng: 17.769582 },
    { lat: 48.502698, lng: 17.769533 },
    { lat: 48.502667, lng: 17.769504 },
    { lat: 48.502637, lng: 17.769478 },
    { lat: 48.502586, lng: 17.769435 },
    { lat: 48.502457, lng: 17.769325 },
    { lat: 48.502417, lng: 17.76929 },
    { lat: 48.502399, lng: 17.769277 },
    { lat: 48.502323, lng: 17.769213 },
    { lat: 48.502185, lng: 17.769103 },
    { lat: 48.50214, lng: 17.769066 },
    { lat: 48.502059, lng: 17.769004 },
    { lat: 48.502012, lng: 17.76897 },
    { lat: 48.501968, lng: 17.768933 },
    { lat: 48.501847, lng: 17.768836 },
    { lat: 48.501786, lng: 17.768805 },
    { lat: 48.501703, lng: 17.768758 },
    { lat: 48.501608, lng: 17.768702 },
    { lat: 48.501554, lng: 17.76867 },
    { lat: 48.501269, lng: 17.768443 },
    { lat: 48.500896, lng: 17.768288 },
    { lat: 48.500797, lng: 17.768242 },
    { lat: 48.500705, lng: 17.76819 },
    { lat: 48.50053, lng: 17.768091 },
    { lat: 48.500472, lng: 17.76806 },
    { lat: 48.500245, lng: 17.767876 },
    { lat: 48.499832, lng: 17.767352 },
    { lat: 48.499716, lng: 17.767131 },
    { lat: 48.499615, lng: 17.766971 },
    { lat: 48.499523, lng: 17.767 },
    { lat: 48.499292, lng: 17.767127 },
    { lat: 48.499012, lng: 17.767245 },
    { lat: 48.498482, lng: 17.76747 },
    { lat: 48.498437, lng: 17.767487 },
    { lat: 48.498385, lng: 17.767513 },
    { lat: 48.498286, lng: 17.767559 },
    { lat: 48.498078, lng: 17.767649 },
    { lat: 48.498028, lng: 17.767672 },
    { lat: 48.498014, lng: 17.767678 },
    { lat: 48.49794, lng: 17.76771 },
    { lat: 48.497893, lng: 17.767731 },
    { lat: 48.49779, lng: 17.767775 },
    { lat: 48.497685, lng: 17.767823 },
    { lat: 48.497589, lng: 17.767854 },
    { lat: 48.497498, lng: 17.767884 },
    { lat: 48.497402, lng: 17.767917 },
    { lat: 48.497301, lng: 17.767951 },
    { lat: 48.497195, lng: 17.767983 },
    { lat: 48.496998, lng: 17.768045 },
    { lat: 48.496893, lng: 17.76808 },
    { lat: 48.496798, lng: 17.768109 },
    { lat: 48.496709, lng: 17.768144 },
    { lat: 48.496469, lng: 17.76822 },
    { lat: 48.496418, lng: 17.768237 },
    { lat: 48.496318, lng: 17.768265 },
    { lat: 48.49626, lng: 17.768283 },
    { lat: 48.496217, lng: 17.768296 },
    { lat: 48.496148, lng: 17.768312 },
    { lat: 48.49612, lng: 17.768319 },
    { lat: 48.495927, lng: 17.768371 },
    { lat: 48.49578, lng: 17.768412 },
    { lat: 48.49571, lng: 17.768424 },
    { lat: 48.495636, lng: 17.768435 },
    { lat: 48.495537, lng: 17.768455 },
    { lat: 48.495486, lng: 17.768469 },
    { lat: 48.495434, lng: 17.768473 },
    { lat: 48.495386, lng: 17.768476 },
    { lat: 48.495359, lng: 17.768478 },
    { lat: 48.495262, lng: 17.768492 },
    { lat: 48.49516, lng: 17.768487 },
    { lat: 48.495038, lng: 17.768496 },
    { lat: 48.49493, lng: 17.768464 },
    { lat: 48.49482, lng: 17.768425 },
    { lat: 48.494789, lng: 17.768408 },
    { lat: 48.494712, lng: 17.768354 },
    { lat: 48.494604, lng: 17.768262 },
    { lat: 48.494436, lng: 17.768075 },
    { lat: 48.494352, lng: 17.768013 },
    { lat: 48.494258, lng: 17.767961 },
    { lat: 48.494077, lng: 17.767883 },
    { lat: 48.493894, lng: 17.767802 },
    { lat: 48.49386, lng: 17.767788 },
    { lat: 48.493826, lng: 17.767773 },
    { lat: 48.493711, lng: 17.767726 },
    { lat: 48.493572, lng: 17.76766 },
    { lat: 48.493449, lng: 17.767603 },
    { lat: 48.493344, lng: 17.767545 },
    { lat: 48.493253, lng: 17.767506 },
    { lat: 48.493164, lng: 17.767468 },
    { lat: 48.493124, lng: 17.767451 },
    { lat: 48.493074, lng: 17.76743 },
    { lat: 48.492943, lng: 17.767374 },
    { lat: 48.492805, lng: 17.767323 },
    { lat: 48.49255, lng: 17.767229 },
    { lat: 48.492507, lng: 17.76721 },
    { lat: 48.492454, lng: 17.76719 },
    { lat: 48.492397, lng: 17.767172 },
    { lat: 48.492341, lng: 17.767152 },
    { lat: 48.492241, lng: 17.767127 },
    { lat: 48.492051, lng: 17.767122 },
    { lat: 48.491982, lng: 17.767118 },
    { lat: 48.491927, lng: 17.767109 },
    { lat: 48.491873, lng: 17.767096 },
    { lat: 48.491785, lng: 17.767073 },
    { lat: 48.491688, lng: 17.767045 },
    { lat: 48.491598, lng: 17.767022 },
    { lat: 48.491504, lng: 17.766996 },
    { lat: 48.491442, lng: 17.76698 },
    { lat: 48.491377, lng: 17.766961 },
    { lat: 48.491318, lng: 17.766946 },
    { lat: 48.491224, lng: 17.766914 },
    { lat: 48.491177, lng: 17.7669 },
    { lat: 48.49113, lng: 17.766883 },
    { lat: 48.490946, lng: 17.766835 },
    { lat: 48.490861, lng: 17.766808 },
    { lat: 48.490767, lng: 17.766782 },
    { lat: 48.490714, lng: 17.766768 },
    { lat: 48.490669, lng: 17.766754 },
    { lat: 48.490571, lng: 17.766726 },
    { lat: 48.490484, lng: 17.766701 },
    { lat: 48.490391, lng: 17.766676 },
    { lat: 48.490295, lng: 17.766652 },
    { lat: 48.490202, lng: 17.766633 },
    { lat: 48.490138, lng: 17.76662 },
    { lat: 48.490075, lng: 17.76661 },
    { lat: 48.490025, lng: 17.766601 },
    { lat: 48.489766, lng: 17.766556 },
    { lat: 48.489642, lng: 17.766531 },
    { lat: 48.489615, lng: 17.766528 },
    { lat: 48.489553, lng: 17.766519 },
    { lat: 48.48946, lng: 17.766505 },
    { lat: 48.489317, lng: 17.766486 },
    { lat: 48.489274, lng: 17.766482 },
    { lat: 48.48918, lng: 17.766466 },
    { lat: 48.489083, lng: 17.766441 },
    { lat: 48.489024, lng: 17.766425 },
    { lat: 48.488904, lng: 17.766393 },
    { lat: 48.48881, lng: 17.766355 },
    { lat: 48.488713, lng: 17.766316 },
    { lat: 48.488657, lng: 17.766306 },
    { lat: 48.488602, lng: 17.766291 },
    { lat: 48.488537, lng: 17.766259 },
    { lat: 48.488458, lng: 17.766212 },
    { lat: 48.488382, lng: 17.766184 },
    { lat: 48.488297, lng: 17.766163 },
    { lat: 48.488171, lng: 17.766128 },
    { lat: 48.48811, lng: 17.766103 },
    { lat: 48.488044, lng: 17.766072 },
    { lat: 48.48799, lng: 17.766042 },
    { lat: 48.487921, lng: 17.766015 },
    { lat: 48.487846, lng: 17.765979 },
    { lat: 48.487822, lng: 17.765974 },
    { lat: 48.487779, lng: 17.76592 },
    { lat: 48.48777, lng: 17.765889 },
    { lat: 48.487762, lng: 17.765766 },
    { lat: 48.487754, lng: 17.765681 },
    { lat: 48.48773, lng: 17.765494 },
  ],
  Dvorníky: [
    { lat: 48.382239, lng: 17.7760624 },
    { lat: 48.381673, lng: 17.7738773 },
    { lat: 48.3798681, lng: 17.7708072 },
    { lat: 48.3774239, lng: 17.7626877 },
    { lat: 48.3773623, lng: 17.7624563 },
    { lat: 48.373911, lng: 17.7587148 },
    { lat: 48.3716087, lng: 17.756738 },
    { lat: 48.3709579, lng: 17.7570364 },
    { lat: 48.3699549, lng: 17.7579657 },
    { lat: 48.3688677, lng: 17.757615 },
    { lat: 48.3681565, lng: 17.7574002 },
    { lat: 48.3688899, lng: 17.7535589 },
    { lat: 48.3691627, lng: 17.752347 },
    { lat: 48.3691041, lng: 17.7509779 },
    { lat: 48.3703872, lng: 17.7497459 },
    { lat: 48.3716479, lng: 17.7489175 },
    { lat: 48.3721774, lng: 17.748643 },
    { lat: 48.3736533, lng: 17.7462253 },
    { lat: 48.3738209, lng: 17.7463415 },
    { lat: 48.3743369, lng: 17.7454663 },
    { lat: 48.3740026, lng: 17.7449543 },
    { lat: 48.3738468, lng: 17.7445698 },
    { lat: 48.3736596, lng: 17.7429678 },
    { lat: 48.3734634, lng: 17.7422452 },
    { lat: 48.3729738, lng: 17.7414119 },
    { lat: 48.3722545, lng: 17.7406278 },
    { lat: 48.3722472, lng: 17.7406196 },
    { lat: 48.3716856, lng: 17.7419962 },
    { lat: 48.3713482, lng: 17.7416363 },
    { lat: 48.3701288, lng: 17.7407239 },
    { lat: 48.3689954, lng: 17.7397398 },
    { lat: 48.3678561, lng: 17.7389026 },
    { lat: 48.3674709, lng: 17.7387717 },
    { lat: 48.3665099, lng: 17.7382224 },
    { lat: 48.3661158, lng: 17.7381663 },
    { lat: 48.3655606, lng: 17.7382048 },
    { lat: 48.3650351, lng: 17.7384187 },
    { lat: 48.3641941, lng: 17.7390573 },
    { lat: 48.3639615, lng: 17.7394761 },
    { lat: 48.3637064, lng: 17.7401327 },
    { lat: 48.3635083, lng: 17.7409971 },
    { lat: 48.3635049, lng: 17.7419278 },
    { lat: 48.3635589, lng: 17.7423456 },
    { lat: 48.3638821, lng: 17.7437362 },
    { lat: 48.3642877, lng: 17.7446159 },
    { lat: 48.3650839, lng: 17.7459648 },
    { lat: 48.3660725, lng: 17.7480008 },
    { lat: 48.3665589, lng: 17.7494949 },
    { lat: 48.3664723, lng: 17.7502096 },
    { lat: 48.3659943, lng: 17.7524115 },
    { lat: 48.366352, lng: 17.7536302 },
    { lat: 48.3663542, lng: 17.7545605 },
    { lat: 48.3661293, lng: 17.7558369 },
    { lat: 48.3659212, lng: 17.7563584 },
    { lat: 48.3655311, lng: 17.7570436 },
    { lat: 48.3648217, lng: 17.7576043 },
    { lat: 48.3628501, lng: 17.7584401 },
    { lat: 48.3573178, lng: 17.7557352 },
    { lat: 48.3547449, lng: 17.7524682 },
    { lat: 48.3507172, lng: 17.7477727 },
    { lat: 48.3505757, lng: 17.7500661 },
    { lat: 48.3509676, lng: 17.7523303 },
    { lat: 48.3513295, lng: 17.7540532 },
    { lat: 48.3512076, lng: 17.7560263 },
    { lat: 48.3507864, lng: 17.756721 },
    { lat: 48.3503194, lng: 17.7573113 },
    { lat: 48.3495864, lng: 17.7571223 },
    { lat: 48.3487052, lng: 17.7569865 },
    { lat: 48.3478094, lng: 17.7562314 },
    { lat: 48.3472131, lng: 17.7554988 },
    { lat: 48.3470922, lng: 17.7547111 },
    { lat: 48.3470384, lng: 17.7540432 },
    { lat: 48.3471596, lng: 17.750474 },
    { lat: 48.3474687, lng: 17.748115 },
    { lat: 48.3503459, lng: 17.7421993 },
    { lat: 48.3520186, lng: 17.7413707 },
    { lat: 48.3528688, lng: 17.7403693 },
    { lat: 48.3527861, lng: 17.7400202 },
    { lat: 48.3530627, lng: 17.739818 },
    { lat: 48.3520958, lng: 17.7366973 },
    { lat: 48.3514517, lng: 17.7351434 },
    { lat: 48.350122, lng: 17.734086 },
    { lat: 48.349797, lng: 17.734175 },
    { lat: 48.349498, lng: 17.734232 },
    { lat: 48.349301, lng: 17.734285 },
    { lat: 48.349159, lng: 17.734672 },
    { lat: 48.34897, lng: 17.73519 },
    { lat: 48.348839, lng: 17.735524 },
    { lat: 48.348824, lng: 17.735587 },
    { lat: 48.348804, lng: 17.735632 },
    { lat: 48.348795, lng: 17.735656 },
    { lat: 48.34878, lng: 17.7357 },
    { lat: 48.348668, lng: 17.736001 },
    { lat: 48.348471, lng: 17.736538 },
    { lat: 48.348315, lng: 17.736965 },
    { lat: 48.348147, lng: 17.737417 },
    { lat: 48.348052, lng: 17.737658 },
    { lat: 48.348022, lng: 17.737737 },
    { lat: 48.348006, lng: 17.737779 },
    { lat: 48.347904, lng: 17.738032 },
    { lat: 48.347745, lng: 17.738437 },
    { lat: 48.347587, lng: 17.738835 },
    { lat: 48.347414, lng: 17.739273 },
    { lat: 48.347317, lng: 17.739509 },
    { lat: 48.347273, lng: 17.739599 },
    { lat: 48.347188, lng: 17.739784 },
    { lat: 48.346925, lng: 17.74034 },
    { lat: 48.346767, lng: 17.740669 },
    { lat: 48.346612, lng: 17.741045 },
    { lat: 48.346431, lng: 17.741458 },
    { lat: 48.346242, lng: 17.741889 },
    { lat: 48.346052, lng: 17.742322 },
    { lat: 48.345932, lng: 17.74259 },
    { lat: 48.345894, lng: 17.74266 },
    { lat: 48.345779, lng: 17.742915 },
    { lat: 48.345769, lng: 17.742939 },
    { lat: 48.345573, lng: 17.743359 },
    { lat: 48.3454, lng: 17.743756 },
    { lat: 48.345248, lng: 17.744086 },
    { lat: 48.345101, lng: 17.744408 },
    { lat: 48.344897, lng: 17.744861 },
    { lat: 48.344714, lng: 17.745276 },
    { lat: 48.34453, lng: 17.745699 },
    { lat: 48.344166, lng: 17.746531 },
    { lat: 48.343997, lng: 17.746919 },
    { lat: 48.343746, lng: 17.747492 },
    { lat: 48.343664, lng: 17.747686 },
    { lat: 48.343514, lng: 17.748022 },
    { lat: 48.34334, lng: 17.748423 },
    { lat: 48.343176, lng: 17.748804 },
    { lat: 48.34301, lng: 17.749183 },
    { lat: 48.34286, lng: 17.749531 },
    { lat: 48.34277, lng: 17.749739 },
    { lat: 48.342735, lng: 17.749813 },
    { lat: 48.342595, lng: 17.750097 },
    { lat: 48.342429, lng: 17.750455 },
    { lat: 48.342197, lng: 17.751009 },
    { lat: 48.342232, lng: 17.751415 },
    { lat: 48.342281, lng: 17.751918 },
    { lat: 48.342329, lng: 17.752434 },
    { lat: 48.342336, lng: 17.752513 },
    { lat: 48.342342, lng: 17.752541 },
    { lat: 48.342345, lng: 17.752619 },
    { lat: 48.342342, lng: 17.753061 },
    { lat: 48.342538, lng: 17.753398 },
    { lat: 48.342678, lng: 17.753645 },
    { lat: 48.342766, lng: 17.754195 },
    { lat: 48.342748, lng: 17.754617 },
    { lat: 48.342731, lng: 17.75499 },
    { lat: 48.342637, lng: 17.755344 },
    { lat: 48.342419, lng: 17.755965 },
    { lat: 48.342332, lng: 17.756126 },
    { lat: 48.3421, lng: 17.756376 },
    { lat: 48.341855, lng: 17.756656 },
    { lat: 48.341529, lng: 17.75688 },
    { lat: 48.341299, lng: 17.757031 },
    { lat: 48.341033, lng: 17.757176 },
    { lat: 48.341002, lng: 17.757162 },
    { lat: 48.340968, lng: 17.757159 },
    { lat: 48.340893, lng: 17.757556 },
    { lat: 48.340822, lng: 17.758001 },
    { lat: 48.340588, lng: 17.758513 },
    { lat: 48.34059, lng: 17.758537 },
    { lat: 48.340617, lng: 17.758998 },
    { lat: 48.340498, lng: 17.759736 },
    { lat: 48.340489, lng: 17.759796 },
    { lat: 48.340412, lng: 17.760276 },
    { lat: 48.340396, lng: 17.760418 },
    { lat: 48.340362, lng: 17.760732 },
    { lat: 48.340322, lng: 17.760896 },
    { lat: 48.340305, lng: 17.760966 },
    { lat: 48.340289, lng: 17.761034 },
    { lat: 48.340271, lng: 17.761106 },
    { lat: 48.34025, lng: 17.761189 },
    { lat: 48.340215, lng: 17.761293 },
    { lat: 48.340191, lng: 17.76137 },
    { lat: 48.340182, lng: 17.761428 },
    { lat: 48.340137, lng: 17.761636 },
    { lat: 48.340066, lng: 17.76183 },
    { lat: 48.339979, lng: 17.762068 },
    { lat: 48.339851, lng: 17.762458 },
    { lat: 48.339819, lng: 17.762678 },
    { lat: 48.33977, lng: 17.762843 },
    { lat: 48.339628, lng: 17.763178 },
    { lat: 48.339483, lng: 17.763296 },
    { lat: 48.339391, lng: 17.763509 },
    { lat: 48.339344, lng: 17.76362 },
    { lat: 48.339288, lng: 17.763745 },
    { lat: 48.339183, lng: 17.764103 },
    { lat: 48.339168, lng: 17.764382 },
    { lat: 48.339135, lng: 17.764464 },
    { lat: 48.339167, lng: 17.764895 },
    { lat: 48.339129, lng: 17.764985 },
    { lat: 48.339083, lng: 17.765028 },
    { lat: 48.339053, lng: 17.765056 },
    { lat: 48.339018, lng: 17.765127 },
    { lat: 48.338957, lng: 17.765205 },
    { lat: 48.338871, lng: 17.765288 },
    { lat: 48.338808, lng: 17.765396 },
    { lat: 48.338816, lng: 17.765583 },
    { lat: 48.338892, lng: 17.76584 },
    { lat: 48.338931, lng: 17.766128 },
    { lat: 48.338925, lng: 17.766259 },
    { lat: 48.338647, lng: 17.766673 },
    { lat: 48.338427, lng: 17.767397 },
    { lat: 48.338368, lng: 17.767399 },
    { lat: 48.338336, lng: 17.7674 },
    { lat: 48.338293, lng: 17.767423 },
    { lat: 48.338057, lng: 17.767544 },
    { lat: 48.337976, lng: 17.767586 },
    { lat: 48.337891, lng: 17.76763 },
    { lat: 48.337551, lng: 17.767974 },
    { lat: 48.337431, lng: 17.768068 },
    { lat: 48.337161, lng: 17.768324 },
    { lat: 48.337088, lng: 17.768397 },
    { lat: 48.337029, lng: 17.768456 },
    { lat: 48.336963, lng: 17.768597 },
    { lat: 48.336704, lng: 17.768789 },
    { lat: 48.336496, lng: 17.768927 },
    { lat: 48.336242, lng: 17.769076 },
    { lat: 48.336103, lng: 17.769188 },
    { lat: 48.335979, lng: 17.769288 },
    { lat: 48.335918, lng: 17.769337 },
    { lat: 48.335812, lng: 17.769469 },
    { lat: 48.335501, lng: 17.769776 },
    { lat: 48.33534, lng: 17.769978 },
    { lat: 48.335225, lng: 17.770158 },
    { lat: 48.335059, lng: 17.770414 },
    { lat: 48.335016, lng: 17.770481 },
    { lat: 48.334646, lng: 17.771063 },
    { lat: 48.334208, lng: 17.7718 },
    { lat: 48.334144, lng: 17.771885 },
    { lat: 48.333779, lng: 17.772191 },
    { lat: 48.333627, lng: 17.772251 },
    { lat: 48.333473, lng: 17.772392 },
    { lat: 48.333065, lng: 17.772694 },
    { lat: 48.332882, lng: 17.772754 },
    { lat: 48.332792, lng: 17.772814 },
    { lat: 48.332516, lng: 17.773059 },
    { lat: 48.332268, lng: 17.773198 },
    { lat: 48.332139, lng: 17.773292 },
    { lat: 48.331913, lng: 17.77349 },
    { lat: 48.331746, lng: 17.773595 },
    { lat: 48.331678, lng: 17.773607 },
    { lat: 48.331572, lng: 17.773653 },
    { lat: 48.33148, lng: 17.773683 },
    { lat: 48.331292, lng: 17.77364 },
    { lat: 48.331283, lng: 17.77362 },
    { lat: 48.331235, lng: 17.773666 },
    { lat: 48.331029, lng: 17.773858 },
    { lat: 48.330837, lng: 17.773948 },
    { lat: 48.330585, lng: 17.774068 },
    { lat: 48.330185, lng: 17.774007 },
    { lat: 48.330129, lng: 17.774009 },
    { lat: 48.33006, lng: 17.774051 },
    { lat: 48.330035, lng: 17.774112 },
    { lat: 48.329873, lng: 17.774691 },
    { lat: 48.329527, lng: 17.775895 },
    { lat: 48.329497, lng: 17.776001 },
    { lat: 48.32943, lng: 17.776256 },
    { lat: 48.329427, lng: 17.776277 },
    { lat: 48.329349, lng: 17.776721 },
    { lat: 48.329341, lng: 17.77677 },
    { lat: 48.329353, lng: 17.776879 },
    { lat: 48.329356, lng: 17.776906 },
    { lat: 48.329349, lng: 17.776983 },
    { lat: 48.329346, lng: 17.77701 },
    { lat: 48.329371, lng: 17.777282 },
    { lat: 48.329358, lng: 17.777808 },
    { lat: 48.329204, lng: 17.778491 },
    { lat: 48.329015, lng: 17.778903 },
    { lat: 48.32879, lng: 17.779372 },
    { lat: 48.328695, lng: 17.779654 },
    { lat: 48.328673, lng: 17.780002 },
    { lat: 48.328653, lng: 17.780256 },
    { lat: 48.328598, lng: 17.78063 },
    { lat: 48.328599, lng: 17.780784 },
    { lat: 48.328574, lng: 17.780997 },
    { lat: 48.328545, lng: 17.781093 },
    { lat: 48.328467, lng: 17.781083 },
    { lat: 48.328043, lng: 17.781166 },
    { lat: 48.32795, lng: 17.78121 },
    { lat: 48.327871, lng: 17.781257 },
    { lat: 48.327739, lng: 17.781366 },
    { lat: 48.327557, lng: 17.781581 },
    { lat: 48.327105, lng: 17.781984 },
    { lat: 48.327081, lng: 17.782008 },
    { lat: 48.32669, lng: 17.782368 },
    { lat: 48.326003, lng: 17.783203 },
    { lat: 48.325878, lng: 17.783391 },
    { lat: 48.325412, lng: 17.78406 },
    { lat: 48.325223, lng: 17.784324 },
    { lat: 48.32489, lng: 17.784821 },
    { lat: 48.324581, lng: 17.785324 },
    { lat: 48.324545, lng: 17.785381 },
    { lat: 48.324506, lng: 17.785444 },
    { lat: 48.324454, lng: 17.785528 },
    { lat: 48.32426, lng: 17.785902 },
    { lat: 48.323868, lng: 17.786774 },
    { lat: 48.323771, lng: 17.786938 },
    { lat: 48.323344, lng: 17.787443 },
    { lat: 48.32321, lng: 17.787574 },
    { lat: 48.32222, lng: 17.788586 },
    { lat: 48.322196, lng: 17.78861 },
    { lat: 48.322461, lng: 17.789208 },
    { lat: 48.322763, lng: 17.789935 },
    { lat: 48.322975, lng: 17.79044 },
    { lat: 48.323109, lng: 17.790767 },
    { lat: 48.323389, lng: 17.791361 },
    { lat: 48.323819, lng: 17.792261 },
    { lat: 48.324194, lng: 17.793021 },
    { lat: 48.3242, lng: 17.793541 },
    { lat: 48.324204, lng: 17.793754 },
    { lat: 48.324363, lng: 17.79409 },
    { lat: 48.324453, lng: 17.794277 },
    { lat: 48.324626, lng: 17.794658 },
    { lat: 48.324872, lng: 17.795193 },
    { lat: 48.32507, lng: 17.795518 },
    { lat: 48.325355, lng: 17.796007 },
    { lat: 48.325667, lng: 17.796871 },
    { lat: 48.325896, lng: 17.797511 },
    { lat: 48.326042, lng: 17.797749 },
    { lat: 48.326171, lng: 17.797954 },
    { lat: 48.326329, lng: 17.798222 },
    { lat: 48.326295, lng: 17.798963 },
    { lat: 48.326264, lng: 17.799596 },
    { lat: 48.326233, lng: 17.800173 },
    { lat: 48.326205, lng: 17.800794 },
    { lat: 48.326206, lng: 17.800838 },
    { lat: 48.326262, lng: 17.801097 },
    { lat: 48.326355, lng: 17.801563 },
    { lat: 48.326487, lng: 17.802236 },
    { lat: 48.326578, lng: 17.802679 },
    { lat: 48.326705, lng: 17.803325 },
    { lat: 48.326853, lng: 17.804037 },
    { lat: 48.326865, lng: 17.804033 },
    { lat: 48.327051, lng: 17.80454 },
    { lat: 48.327209, lng: 17.804951 },
    { lat: 48.327353, lng: 17.805351 },
    { lat: 48.327513, lng: 17.805761 },
    { lat: 48.327662, lng: 17.806153 },
    { lat: 48.327717, lng: 17.806313 },
    { lat: 48.327825, lng: 17.806693 },
    { lat: 48.327927, lng: 17.80702 },
    { lat: 48.328085, lng: 17.807419 },
    { lat: 48.328164, lng: 17.807642 },
    { lat: 48.328295, lng: 17.808018 },
    { lat: 48.328364, lng: 17.808309 },
    { lat: 48.328441, lng: 17.808675 },
    { lat: 48.32849, lng: 17.808864 },
    { lat: 48.328579, lng: 17.809138 },
    { lat: 48.328657, lng: 17.809464 },
    { lat: 48.32871, lng: 17.809737 },
    { lat: 48.328744, lng: 17.810014 },
    { lat: 48.328756, lng: 17.810171 },
    { lat: 48.32876, lng: 17.810482 },
    { lat: 48.328764, lng: 17.810763 },
    { lat: 48.328767, lng: 17.811126 },
    { lat: 48.328769, lng: 17.811351 },
    { lat: 48.328765, lng: 17.811595 },
    { lat: 48.328755, lng: 17.811826 },
    { lat: 48.328759, lng: 17.811962 },
    { lat: 48.32874, lng: 17.812131 },
    { lat: 48.32872, lng: 17.812401 },
    { lat: 48.328731, lng: 17.812604 },
    { lat: 48.328732, lng: 17.812799 },
    { lat: 48.328736, lng: 17.812995 },
    { lat: 48.328749, lng: 17.813117 },
    { lat: 48.328762, lng: 17.813252 },
    { lat: 48.328764, lng: 17.813404 },
    { lat: 48.328751, lng: 17.813547 },
    { lat: 48.328725, lng: 17.81366 },
    { lat: 48.328682, lng: 17.813755 },
    { lat: 48.328634, lng: 17.813822 },
    { lat: 48.328653, lng: 17.813835 },
    { lat: 48.32886, lng: 17.813866 },
    { lat: 48.329, lng: 17.813807 },
    { lat: 48.329102, lng: 17.813831 },
    { lat: 48.329213, lng: 17.813825 },
    { lat: 48.329382, lng: 17.813756 },
    { lat: 48.329591, lng: 17.813591 },
    { lat: 48.329871, lng: 17.813326 },
    { lat: 48.33016, lng: 17.8131 },
    { lat: 48.330524, lng: 17.812926 },
    { lat: 48.330721, lng: 17.812746 },
    { lat: 48.331085, lng: 17.812426 },
    { lat: 48.331275, lng: 17.811948 },
    { lat: 48.331592, lng: 17.811802 },
    { lat: 48.331822, lng: 17.811621 },
    { lat: 48.332139, lng: 17.811381 },
    { lat: 48.332227, lng: 17.811349 },
    { lat: 48.332503, lng: 17.811108 },
    { lat: 48.332674, lng: 17.810959 },
    { lat: 48.332981, lng: 17.810703 },
    { lat: 48.333097, lng: 17.810614 },
    { lat: 48.333396, lng: 17.810396 },
    { lat: 48.333728, lng: 17.810144 },
    { lat: 48.334004, lng: 17.809938 },
    { lat: 48.334328, lng: 17.80971 },
    { lat: 48.33461, lng: 17.809516 },
    { lat: 48.334762, lng: 17.809466 },
    { lat: 48.334832, lng: 17.809457 },
    { lat: 48.335045, lng: 17.809561 },
    { lat: 48.335092, lng: 17.809577 },
    { lat: 48.335162, lng: 17.809552 },
    { lat: 48.335338, lng: 17.809454 },
    { lat: 48.335448, lng: 17.809381 },
    { lat: 48.33568, lng: 17.809965 },
    { lat: 48.3357, lng: 17.810028 },
    { lat: 48.336033, lng: 17.809816 },
    { lat: 48.336375, lng: 17.809604 },
    { lat: 48.336782, lng: 17.809363 },
    { lat: 48.337076, lng: 17.809171 },
    { lat: 48.337167, lng: 17.809119 },
    { lat: 48.337498, lng: 17.808952 },
    { lat: 48.337812, lng: 17.808789 },
    { lat: 48.338165, lng: 17.808603 },
    { lat: 48.338457, lng: 17.808441 },
    { lat: 48.338687, lng: 17.808274 },
    { lat: 48.338848, lng: 17.808106 },
    { lat: 48.339013, lng: 17.807964 },
    { lat: 48.339198, lng: 17.807826 },
    { lat: 48.339426, lng: 17.807626 },
    { lat: 48.33978, lng: 17.807255 },
    { lat: 48.340017, lng: 17.807019 },
    { lat: 48.340366, lng: 17.806673 },
    { lat: 48.340516, lng: 17.806568 },
    { lat: 48.340868, lng: 17.806323 },
    { lat: 48.341119, lng: 17.806166 },
    { lat: 48.341524, lng: 17.806018 },
    { lat: 48.341953, lng: 17.805738 },
    { lat: 48.342276, lng: 17.805624 },
    { lat: 48.342485, lng: 17.805516 },
    { lat: 48.342868, lng: 17.805309 },
    { lat: 48.343216, lng: 17.805113 },
    { lat: 48.343233, lng: 17.805162 },
    { lat: 48.343695, lng: 17.804885 },
    { lat: 48.344207, lng: 17.804588 },
    { lat: 48.344746, lng: 17.80427 },
    { lat: 48.345258, lng: 17.803974 },
    { lat: 48.345234, lng: 17.803752 },
    { lat: 48.345664, lng: 17.803657 },
    { lat: 48.345834, lng: 17.803554 },
    { lat: 48.345919, lng: 17.803526 },
    { lat: 48.346112, lng: 17.803518 },
    { lat: 48.346439, lng: 17.8034 },
    { lat: 48.346558, lng: 17.803378 },
    { lat: 48.346729, lng: 17.803346 },
    { lat: 48.346911, lng: 17.80325 },
    { lat: 48.34724, lng: 17.803124 },
    { lat: 48.347505, lng: 17.803005 },
    { lat: 48.347582, lng: 17.803401 },
    { lat: 48.347626, lng: 17.803576 },
    { lat: 48.347741, lng: 17.803988 },
    { lat: 48.347866, lng: 17.804484 },
    { lat: 48.347999, lng: 17.80501 },
    { lat: 48.347977, lng: 17.805079 },
    { lat: 48.348017, lng: 17.805131 },
    { lat: 48.348223, lng: 17.804929 },
    { lat: 48.348508, lng: 17.804695 },
    { lat: 48.348993, lng: 17.804305 },
    { lat: 48.349008, lng: 17.804347 },
    { lat: 48.349365, lng: 17.805093 },
    { lat: 48.349423, lng: 17.805229 },
    { lat: 48.34946, lng: 17.805376 },
    { lat: 48.349669, lng: 17.805863 },
    { lat: 48.349904, lng: 17.806433 },
    { lat: 48.349977, lng: 17.806575 },
    { lat: 48.350239, lng: 17.807102 },
    { lat: 48.350423, lng: 17.807463 },
    { lat: 48.350577, lng: 17.807808 },
    { lat: 48.350774, lng: 17.808246 },
    { lat: 48.351026, lng: 17.808729 },
    { lat: 48.351266, lng: 17.809222 },
    { lat: 48.351496, lng: 17.809704 },
    { lat: 48.351741, lng: 17.810225 },
    { lat: 48.351951, lng: 17.810649 },
    { lat: 48.352111, lng: 17.811057 },
    { lat: 48.352316, lng: 17.811551 },
    { lat: 48.35248, lng: 17.812016 },
    { lat: 48.352589, lng: 17.812491 },
    { lat: 48.352701, lng: 17.81299 },
    { lat: 48.35273, lng: 17.813216 },
    { lat: 48.352767, lng: 17.813531 },
    { lat: 48.352824, lng: 17.814069 },
    { lat: 48.352847, lng: 17.814378 },
    { lat: 48.352873, lng: 17.814782 },
    { lat: 48.352903, lng: 17.815108 },
    { lat: 48.352911, lng: 17.815108 },
    { lat: 48.352921, lng: 17.815496 },
    { lat: 48.352953, lng: 17.815695 },
    { lat: 48.352992, lng: 17.815897 },
    { lat: 48.352999, lng: 17.81641 },
    { lat: 48.352978, lng: 17.816636 },
    { lat: 48.352942, lng: 17.816826 },
    { lat: 48.35266, lng: 17.817628 },
    { lat: 48.352567, lng: 17.817778 },
    { lat: 48.352002, lng: 17.818045 },
    { lat: 48.351623, lng: 17.818704 },
    { lat: 48.351624, lng: 17.818786 },
    { lat: 48.351722, lng: 17.818931 },
    { lat: 48.351765, lng: 17.819118 },
    { lat: 48.351696, lng: 17.820035 },
    { lat: 48.352125, lng: 17.82021 },
    { lat: 48.352559, lng: 17.820396 },
    { lat: 48.353, lng: 17.82057 },
    { lat: 48.353416, lng: 17.820986 },
    { lat: 48.353586, lng: 17.821162 },
    { lat: 48.353761, lng: 17.821342 },
    { lat: 48.35414, lng: 17.821737 },
    { lat: 48.35464, lng: 17.822251 },
    { lat: 48.355126, lng: 17.822744 },
    { lat: 48.355153, lng: 17.822765 },
    { lat: 48.3561974, lng: 17.8262002 },
    { lat: 48.3567201, lng: 17.8274166 },
    { lat: 48.3571865, lng: 17.8302793 },
    { lat: 48.3581405, lng: 17.832307 },
    { lat: 48.3589878, lng: 17.8314712 },
    { lat: 48.3593244, lng: 17.8320623 },
    { lat: 48.3595643, lng: 17.8317772 },
    { lat: 48.3601625, lng: 17.8304489 },
    { lat: 48.3608076, lng: 17.828447 },
    { lat: 48.3614429, lng: 17.828974 },
    { lat: 48.3615073, lng: 17.8286818 },
    { lat: 48.3625192, lng: 17.8268622 },
    { lat: 48.3630142, lng: 17.8256353 },
    { lat: 48.3644465, lng: 17.8251847 },
    { lat: 48.3657959, lng: 17.8251161 },
    { lat: 48.3662785, lng: 17.8247023 },
    { lat: 48.3668767, lng: 17.8245556 },
    { lat: 48.3673876, lng: 17.8247666 },
    { lat: 48.3677481, lng: 17.8248082 },
    { lat: 48.3682365, lng: 17.8252819 },
    { lat: 48.3685395, lng: 17.8249028 },
    { lat: 48.368829, lng: 17.8242093 },
    { lat: 48.3688135, lng: 17.8237175 },
    { lat: 48.369016, lng: 17.8222105 },
    { lat: 48.3693214, lng: 17.8214647 },
    { lat: 48.3703132, lng: 17.8218256 },
    { lat: 48.3708489, lng: 17.8218905 },
    { lat: 48.371864, lng: 17.8218204 },
    { lat: 48.3719624, lng: 17.8206994 },
    { lat: 48.3718478, lng: 17.8200989 },
    { lat: 48.3733297, lng: 17.8183626 },
    { lat: 48.373232, lng: 17.8179461 },
    { lat: 48.3734857, lng: 17.817627 },
    { lat: 48.3731811, lng: 17.8161387 },
    { lat: 48.375727, lng: 17.8143014 },
    { lat: 48.3755728, lng: 17.8135682 },
    { lat: 48.3756588, lng: 17.8126327 },
    { lat: 48.3760082, lng: 17.8116303 },
    { lat: 48.3767445, lng: 17.8085429 },
    { lat: 48.3763376, lng: 17.8074519 },
    { lat: 48.3775769, lng: 17.806028 },
    { lat: 48.3772134, lng: 17.804502 },
    { lat: 48.3771645, lng: 17.803175 },
    { lat: 48.3776147, lng: 17.8029132 },
    { lat: 48.3787351, lng: 17.8024428 },
    { lat: 48.3790936, lng: 17.8021741 },
    { lat: 48.379308, lng: 17.8018593 },
    { lat: 48.3796001, lng: 17.8012311 },
    { lat: 48.379602, lng: 17.8010039 },
    { lat: 48.3798451, lng: 17.8002742 },
    { lat: 48.3798877, lng: 17.7996806 },
    { lat: 48.3807488, lng: 17.7995845 },
    { lat: 48.380964, lng: 17.7981083 },
    { lat: 48.3809352, lng: 17.798098 },
    { lat: 48.381007, lng: 17.7971175 },
    { lat: 48.3819824, lng: 17.7970638 },
    { lat: 48.3819209, lng: 17.79698 },
    { lat: 48.3816898, lng: 17.7962996 },
    { lat: 48.3812054, lng: 17.7948733 },
    { lat: 48.3807442, lng: 17.7940203 },
    { lat: 48.3802024, lng: 17.792862 },
    { lat: 48.3799685, lng: 17.792513 },
    { lat: 48.3800042, lng: 17.7924502 },
    { lat: 48.3798447, lng: 17.7918576 },
    { lat: 48.3789696, lng: 17.7892295 },
    { lat: 48.3780234, lng: 17.7879623 },
    { lat: 48.3776271, lng: 17.7869171 },
    { lat: 48.3774483, lng: 17.7865786 },
    { lat: 48.377064, lng: 17.7860591 },
    { lat: 48.3770552, lng: 17.7860426 },
    { lat: 48.3776919, lng: 17.7849795 },
    { lat: 48.3777347, lng: 17.7842406 },
    { lat: 48.3776564, lng: 17.7831187 },
    { lat: 48.3777767, lng: 17.7827067 },
    { lat: 48.3784571, lng: 17.7816542 },
    { lat: 48.3792991, lng: 17.7805511 },
    { lat: 48.3803371, lng: 17.7796435 },
    { lat: 48.3820517, lng: 17.7764123 },
    { lat: 48.382239, lng: 17.7760624 },
  ],
  DolnéOtrokovce: [
    { lat: 48.459797, lng: 17.9070021 },
    { lat: 48.4595102, lng: 17.9078781 },
    { lat: 48.4577091, lng: 17.9107289 },
    { lat: 48.4574089, lng: 17.9104698 },
    { lat: 48.4572332, lng: 17.9101772 },
    { lat: 48.4565416, lng: 17.9082577 },
    { lat: 48.4561913, lng: 17.9076163 },
    { lat: 48.4559442, lng: 17.9073161 },
    { lat: 48.45487, lng: 17.9064645 },
    { lat: 48.4544132, lng: 17.9070866 },
    { lat: 48.453743, lng: 17.907821 },
    { lat: 48.453987, lng: 17.9092069 },
    { lat: 48.4535326, lng: 17.9092334 },
    { lat: 48.4535836, lng: 17.9101378 },
    { lat: 48.4535559, lng: 17.9105374 },
    { lat: 48.452545, lng: 17.9112948 },
    { lat: 48.4519606, lng: 17.91182 },
    { lat: 48.4520316, lng: 17.9128476 },
    { lat: 48.4521084, lng: 17.9132816 },
    { lat: 48.4529403, lng: 17.9158419 },
    { lat: 48.4525906, lng: 17.9160731 },
    { lat: 48.4517247, lng: 17.917026 },
    { lat: 48.451311, lng: 17.9173519 },
    { lat: 48.4518321, lng: 17.9184504 },
    { lat: 48.4511253, lng: 17.9192693 },
    { lat: 48.4504291, lng: 17.9202503 },
    { lat: 48.4489106, lng: 17.9230929 },
    { lat: 48.4482803, lng: 17.9251432 },
    { lat: 48.4481205, lng: 17.9259422 },
    { lat: 48.4480099, lng: 17.9269874 },
    { lat: 48.4478524, lng: 17.9300911 },
    { lat: 48.4478799, lng: 17.9303242 },
    { lat: 48.4470784, lng: 17.9318991 },
    { lat: 48.4467054, lng: 17.9324882 },
    { lat: 48.4460315, lng: 17.9331444 },
    { lat: 48.4451233, lng: 17.9336415 },
    { lat: 48.4453357, lng: 17.9341133 },
    { lat: 48.4458074, lng: 17.9349152 },
    { lat: 48.4469892, lng: 17.9364392 },
    { lat: 48.4486349, lng: 17.9392353 },
    { lat: 48.4493616, lng: 17.9402633 },
    { lat: 48.4493714, lng: 17.9402759 },
    { lat: 48.4565481, lng: 17.9310421 },
    { lat: 48.4589694, lng: 17.9396387 },
    { lat: 48.4591553, lng: 17.9388833 },
    { lat: 48.460195, lng: 17.9385083 },
    { lat: 48.4604913, lng: 17.9383294 },
    { lat: 48.4611561, lng: 17.9377098 },
    { lat: 48.4612833, lng: 17.9375514 },
    { lat: 48.4614567, lng: 17.9371508 },
    { lat: 48.4619326, lng: 17.9373319 },
    { lat: 48.4622883, lng: 17.9349703 },
    { lat: 48.4626222, lng: 17.9348904 },
    { lat: 48.4632383, lng: 17.9346068 },
    { lat: 48.463619, lng: 17.9343103 },
    { lat: 48.4640121, lng: 17.9338656 },
    { lat: 48.4655692, lng: 17.9330467 },
    { lat: 48.4671669, lng: 17.9317249 },
    { lat: 48.4694516, lng: 17.929044 },
    { lat: 48.4712068, lng: 17.9271272 },
    { lat: 48.4728421, lng: 17.9248157 },
    { lat: 48.4738956, lng: 17.9235662 },
    { lat: 48.4743907, lng: 17.9232052 },
    { lat: 48.4758967, lng: 17.9253064 },
    { lat: 48.4759098, lng: 17.925321 },
    { lat: 48.4760987, lng: 17.9252526 },
    { lat: 48.4768595, lng: 17.924425 },
    { lat: 48.4772657, lng: 17.9241227 },
    { lat: 48.4775227, lng: 17.9239678 },
    { lat: 48.4785217, lng: 17.9237613 },
    { lat: 48.478853, lng: 17.9233205 },
    { lat: 48.4789262, lng: 17.9231494 },
    { lat: 48.4789718, lng: 17.9227056 },
    { lat: 48.4792214, lng: 17.9214929 },
    { lat: 48.4794307, lng: 17.9207699 },
    { lat: 48.4797248, lng: 17.9199734 },
    { lat: 48.4799835, lng: 17.919619 },
    { lat: 48.4802056, lng: 17.9190533 },
    { lat: 48.4804226, lng: 17.9183096 },
    { lat: 48.4806881, lng: 17.9170007 },
    { lat: 48.481005, lng: 17.9148409 },
    { lat: 48.4812002, lng: 17.9140536 },
    { lat: 48.4815074, lng: 17.9131425 },
    { lat: 48.4817368, lng: 17.9126945 },
    { lat: 48.4820694, lng: 17.9122008 },
    { lat: 48.482797, lng: 17.911539 },
    { lat: 48.4832772, lng: 17.9114735 },
    { lat: 48.483832, lng: 17.9112778 },
    { lat: 48.4849313, lng: 17.9107709 },
    { lat: 48.4855931, lng: 17.9103628 },
    { lat: 48.486112, lng: 17.9099849 },
    { lat: 48.4863858, lng: 17.9095084 },
    { lat: 48.4867412, lng: 17.9092791 },
    { lat: 48.4872601, lng: 17.9086542 },
    { lat: 48.4872471, lng: 17.9086268 },
    { lat: 48.4860351, lng: 17.9063705 },
    { lat: 48.485227, lng: 17.9050259 },
    { lat: 48.4825172, lng: 17.9000434 },
    { lat: 48.4844284, lng: 17.8979368 },
    { lat: 48.4822431, lng: 17.8946687 },
    { lat: 48.4825214, lng: 17.8942325 },
    { lat: 48.4824712, lng: 17.8941105 },
    { lat: 48.4824889, lng: 17.8939918 },
    { lat: 48.4815736, lng: 17.8921683 },
    { lat: 48.4812302, lng: 17.891641 },
    { lat: 48.4798863, lng: 17.8881792 },
    { lat: 48.4789447, lng: 17.8886067 },
    { lat: 48.4786378, lng: 17.8888323 },
    { lat: 48.4779346, lng: 17.88958 },
    { lat: 48.4773346, lng: 17.8885617 },
    { lat: 48.4772274, lng: 17.8886536 },
    { lat: 48.4760113, lng: 17.8904203 },
    { lat: 48.4743758, lng: 17.8883532 },
    { lat: 48.4723223, lng: 17.8852377 },
    { lat: 48.4719225, lng: 17.8847685 },
    { lat: 48.4709759, lng: 17.8841053 },
    { lat: 48.4697681, lng: 17.8826152 },
    { lat: 48.4680149, lng: 17.8874423 },
    { lat: 48.4649915, lng: 17.8946321 },
    { lat: 48.464389, lng: 17.8973466 },
    { lat: 48.4640729, lng: 17.8981662 },
    { lat: 48.4636703, lng: 17.9003777 },
    { lat: 48.463485, lng: 17.9011784 },
    { lat: 48.4631026, lng: 17.9023927 },
    { lat: 48.462417, lng: 17.9038827 },
    { lat: 48.4616016, lng: 17.9048853 },
    { lat: 48.4605304, lng: 17.9059122 },
    { lat: 48.4603637, lng: 17.9061245 },
    { lat: 48.4600966, lng: 17.9066561 },
    { lat: 48.4598717, lng: 17.9068763 },
    { lat: 48.459797, lng: 17.9070021 },
  ],
  Leopoldov: [
    { lat: 48.4563677, lng: 17.7877156 },
    { lat: 48.4563271, lng: 17.78759 },
    { lat: 48.4555566, lng: 17.7851911 },
    { lat: 48.4555337, lng: 17.782764 },
    { lat: 48.455398, lng: 17.781988 },
    { lat: 48.4550201, lng: 17.7805781 },
    { lat: 48.4547993, lng: 17.7800934 },
    { lat: 48.4545245, lng: 17.7796792 },
    { lat: 48.4539979, lng: 17.7791215 },
    { lat: 48.4535895, lng: 17.7789055 },
    { lat: 48.4538488, lng: 17.7785327 },
    { lat: 48.4545758, lng: 17.7729356 },
    { lat: 48.4547878, lng: 17.7722716 },
    { lat: 48.4549831, lng: 17.770146 },
    { lat: 48.4556995, lng: 17.7644217 },
    { lat: 48.4555065, lng: 17.7643505 },
    { lat: 48.4551115, lng: 17.7640065 },
    { lat: 48.4542791, lng: 17.7628271 },
    { lat: 48.4536481, lng: 17.7622614 },
    { lat: 48.4526968, lng: 17.7612253 },
    { lat: 48.4520656, lng: 17.7606735 },
    { lat: 48.4520232, lng: 17.7602245 },
    { lat: 48.4504939, lng: 17.759979 },
    { lat: 48.449957, lng: 17.7598195 },
    { lat: 48.4491806, lng: 17.7594252 },
    { lat: 48.4481853, lng: 17.7585555 },
    { lat: 48.4455859, lng: 17.7573188 },
    { lat: 48.4442562, lng: 17.7571227 },
    { lat: 48.4439786, lng: 17.7569793 },
    { lat: 48.4435994, lng: 17.756597 },
    { lat: 48.4433944, lng: 17.756276 },
    { lat: 48.4431936, lng: 17.7556761 },
    { lat: 48.4430308, lng: 17.7546477 },
    { lat: 48.4430942, lng: 17.7533527 },
    { lat: 48.4431719, lng: 17.7528383 },
    { lat: 48.4434579, lng: 17.7521136 },
    { lat: 48.4435478, lng: 17.7517038 },
    { lat: 48.4435883, lng: 17.7512786 },
    { lat: 48.4435642, lng: 17.7500847 },
    { lat: 48.4436341, lng: 17.7493367 },
    { lat: 48.4438144, lng: 17.7495519 },
    { lat: 48.4454569, lng: 17.7434451 },
    { lat: 48.4451683, lng: 17.7432693 },
    { lat: 48.4450565, lng: 17.7426864 },
    { lat: 48.4446829, lng: 17.7418667 },
    { lat: 48.4443395, lng: 17.7423211 },
    { lat: 48.4441209, lng: 17.7425807 },
    { lat: 48.4414514, lng: 17.7436507 },
    { lat: 48.4410059, lng: 17.7441314 },
    { lat: 48.4409384, lng: 17.7442646 },
    { lat: 48.4408697, lng: 17.7445427 },
    { lat: 48.4408352, lng: 17.7449678 },
    { lat: 48.4396585, lng: 17.7435769 },
    { lat: 48.4388563, lng: 17.7451002 },
    { lat: 48.4384905, lng: 17.7446286 },
    { lat: 48.438525, lng: 17.7449115 },
    { lat: 48.4383671, lng: 17.7449673 },
    { lat: 48.4382543, lng: 17.7439128 },
    { lat: 48.4380694, lng: 17.7434782 },
    { lat: 48.437946, lng: 17.7437092 },
    { lat: 48.4377238, lng: 17.7450277 },
    { lat: 48.4373062, lng: 17.7455937 },
    { lat: 48.4373478, lng: 17.744533 },
    { lat: 48.4367591, lng: 17.7473024 },
    { lat: 48.4367491, lng: 17.7473593 },
    { lat: 48.4346123, lng: 17.7574357 },
    { lat: 48.4352615, lng: 17.7663137 },
    { lat: 48.4351547, lng: 17.7680425 },
    { lat: 48.4348843, lng: 17.768579 },
    { lat: 48.4349666, lng: 17.768944 },
    { lat: 48.4351355, lng: 17.768955 },
    { lat: 48.4353281, lng: 17.7691315 },
    { lat: 48.4354188, lng: 17.7694129 },
    { lat: 48.4354952, lng: 17.7721544 },
    { lat: 48.4355576, lng: 17.7745089 },
    { lat: 48.4355432, lng: 17.7756304 },
    { lat: 48.4353837, lng: 17.7776783 },
    { lat: 48.4351979, lng: 17.7789151 },
    { lat: 48.4383789, lng: 17.7788871 },
    { lat: 48.4388058, lng: 17.7788011 },
    { lat: 48.4390881, lng: 17.7788937 },
    { lat: 48.4388973, lng: 17.7791304 },
    { lat: 48.4385778, lng: 17.7792399 },
    { lat: 48.4382771, lng: 17.7791046 },
    { lat: 48.4376167, lng: 17.7791992 },
    { lat: 48.4373396, lng: 17.7793899 },
    { lat: 48.4370292, lng: 17.7797399 },
    { lat: 48.4368854, lng: 17.7801211 },
    { lat: 48.4368602, lng: 17.7807383 },
    { lat: 48.4366487, lng: 17.7806044 },
    { lat: 48.4365244, lng: 17.781117 },
    { lat: 48.4360985, lng: 17.7821738 },
    { lat: 48.4358536, lng: 17.7831311 },
    { lat: 48.4342143, lng: 17.7870433 },
    { lat: 48.4403662, lng: 17.7879977 },
    { lat: 48.4423828, lng: 17.7885366 },
    { lat: 48.4424207, lng: 17.7885456 },
    { lat: 48.4436248, lng: 17.7887411 },
    { lat: 48.4468216, lng: 17.7897065 },
    { lat: 48.4470107, lng: 17.7899555 },
    { lat: 48.4482816, lng: 17.7887919 },
    { lat: 48.4491781, lng: 17.7881518 },
    { lat: 48.4500644, lng: 17.7877684 },
    { lat: 48.4509471, lng: 17.7874723 },
    { lat: 48.4518483, lng: 17.787308 },
    { lat: 48.4527638, lng: 17.7872737 },
    { lat: 48.4559774, lng: 17.7876848 },
    { lat: 48.4563677, lng: 17.7877156 },
  ],
  DolnéTrhovište: [
    { lat: 48.446749, lng: 17.947006 },
    { lat: 48.446815, lng: 17.947056 },
    { lat: 48.447242, lng: 17.946696 },
    { lat: 48.447702, lng: 17.946312 },
    { lat: 48.448212, lng: 17.945887 },
    { lat: 48.448592, lng: 17.945565 },
    { lat: 48.448983, lng: 17.945225 },
    { lat: 48.449151, lng: 17.945056 },
    { lat: 48.4496066, lng: 17.9444545 },
    { lat: 48.4501113, lng: 17.9436667 },
    { lat: 48.4498342, lng: 17.9429789 },
    { lat: 48.4495187, lng: 17.9418072 },
    { lat: 48.4492158, lng: 17.9411816 },
    { lat: 48.449232, lng: 17.9408292 },
    { lat: 48.4493714, lng: 17.9402759 },
    { lat: 48.4493616, lng: 17.9402633 },
    { lat: 48.4486349, lng: 17.9392353 },
    { lat: 48.4469892, lng: 17.9364392 },
    { lat: 48.4458074, lng: 17.9349152 },
    { lat: 48.4453357, lng: 17.9341133 },
    { lat: 48.4451233, lng: 17.9336415 },
    { lat: 48.4460315, lng: 17.9331444 },
    { lat: 48.4467054, lng: 17.9324882 },
    { lat: 48.4470784, lng: 17.9318991 },
    { lat: 48.4478799, lng: 17.9303242 },
    { lat: 48.4478524, lng: 17.9300911 },
    { lat: 48.4480099, lng: 17.9269874 },
    { lat: 48.4481205, lng: 17.9259422 },
    { lat: 48.4482803, lng: 17.9251432 },
    { lat: 48.4489106, lng: 17.9230929 },
    { lat: 48.4504291, lng: 17.9202503 },
    { lat: 48.4511253, lng: 17.9192693 },
    { lat: 48.4518321, lng: 17.9184504 },
    { lat: 48.451311, lng: 17.9173519 },
    { lat: 48.4517247, lng: 17.917026 },
    { lat: 48.4525906, lng: 17.9160731 },
    { lat: 48.4529403, lng: 17.9158419 },
    { lat: 48.4521084, lng: 17.9132816 },
    { lat: 48.4520316, lng: 17.9128476 },
    { lat: 48.4519606, lng: 17.91182 },
    { lat: 48.452545, lng: 17.9112948 },
    { lat: 48.4535559, lng: 17.9105374 },
    { lat: 48.4535836, lng: 17.9101378 },
    { lat: 48.4535326, lng: 17.9092334 },
    { lat: 48.453987, lng: 17.9092069 },
    { lat: 48.453743, lng: 17.907821 },
    { lat: 48.4544132, lng: 17.9070866 },
    { lat: 48.45487, lng: 17.9064645 },
    { lat: 48.4559442, lng: 17.9073161 },
    { lat: 48.4561913, lng: 17.9076163 },
    { lat: 48.4565416, lng: 17.9082577 },
    { lat: 48.4572332, lng: 17.9101772 },
    { lat: 48.4574089, lng: 17.9104698 },
    { lat: 48.4577091, lng: 17.9107289 },
    { lat: 48.4595102, lng: 17.9078781 },
    { lat: 48.459797, lng: 17.9070021 },
    { lat: 48.4597854, lng: 17.9069792 },
    { lat: 48.4582086, lng: 17.90513 },
    { lat: 48.4562067, lng: 17.9043379 },
    { lat: 48.4562393, lng: 17.9019265 },
    { lat: 48.4559729, lng: 17.9012905 },
    { lat: 48.4534667, lng: 17.8992524 },
    { lat: 48.4538208, lng: 17.8981646 },
    { lat: 48.4528694, lng: 17.8967282 },
    { lat: 48.452843, lng: 17.8950416 },
    { lat: 48.4525618, lng: 17.8929152 },
    { lat: 48.4520777, lng: 17.8932691 },
    { lat: 48.4516691, lng: 17.8926188 },
    { lat: 48.4511234, lng: 17.8938025 },
    { lat: 48.4506408, lng: 17.8933646 },
    { lat: 48.4494987, lng: 17.8919876 },
    { lat: 48.4502887, lng: 17.8905333 },
    { lat: 48.4503258, lng: 17.8900439 },
    { lat: 48.4485882, lng: 17.8877638 },
    { lat: 48.4483344, lng: 17.8875255 },
    { lat: 48.4463075, lng: 17.8847195 },
    { lat: 48.4481177, lng: 17.8823495 },
    { lat: 48.4492064, lng: 17.8804744 },
    { lat: 48.4500873, lng: 17.8781573 },
    { lat: 48.4507195, lng: 17.8762962 },
    { lat: 48.4521526, lng: 17.8735728 },
    { lat: 48.4517768, lng: 17.873091 },
    { lat: 48.4512553, lng: 17.8724208 },
    { lat: 48.4492342, lng: 17.870162 },
    { lat: 48.4498849, lng: 17.8686649 },
    { lat: 48.4504616, lng: 17.8666937 },
    { lat: 48.4509729, lng: 17.8653533 },
    { lat: 48.4505596, lng: 17.8648699 },
    { lat: 48.4502644, lng: 17.8642824 },
    { lat: 48.4500583, lng: 17.8641255 },
    { lat: 48.4500258, lng: 17.8639408 },
    { lat: 48.4487833, lng: 17.8621287 },
    { lat: 48.449027, lng: 17.8616244 },
    { lat: 48.4486124, lng: 17.8618435 },
    { lat: 48.4485582, lng: 17.8617685 },
    { lat: 48.4483137, lng: 17.8619806 },
    { lat: 48.4481558, lng: 17.862169 },
    { lat: 48.4477851, lng: 17.8628657 },
    { lat: 48.4474048, lng: 17.8639441 },
    { lat: 48.447263, lng: 17.8645112 },
    { lat: 48.4471687, lng: 17.8655204 },
    { lat: 48.4469235, lng: 17.866613 },
    { lat: 48.4461999, lng: 17.8682135 },
    { lat: 48.4449893, lng: 17.8699347 },
    { lat: 48.4449378, lng: 17.8700079 },
    { lat: 48.4440002, lng: 17.8713409 },
    { lat: 48.4427508, lng: 17.8724793 },
    { lat: 48.442024, lng: 17.8734867 },
    { lat: 48.4408592, lng: 17.8753479 },
    { lat: 48.4406522, lng: 17.875788 },
    { lat: 48.4395826, lng: 17.8785505 },
    { lat: 48.43962, lng: 17.8786822 },
    { lat: 48.4392367, lng: 17.8780227 },
    { lat: 48.4390279, lng: 17.8783193 },
    { lat: 48.4388159, lng: 17.8783811 },
    { lat: 48.4385569, lng: 17.8783544 },
    { lat: 48.4384121, lng: 17.8782715 },
    { lat: 48.4381085, lng: 17.878441 },
    { lat: 48.437857, lng: 17.8785039 },
    { lat: 48.4376649, lng: 17.8787013 },
    { lat: 48.4375436, lng: 17.8790659 },
    { lat: 48.4374302, lng: 17.8797661 },
    { lat: 48.4371427, lng: 17.8803487 },
    { lat: 48.4366387, lng: 17.8803193 },
    { lat: 48.4363715, lng: 17.8804572 },
    { lat: 48.4361073, lng: 17.8808823 },
    { lat: 48.4357108, lng: 17.8820518 },
    { lat: 48.4356133, lng: 17.8822112 },
    { lat: 48.4354192, lng: 17.8822869 },
    { lat: 48.4348287, lng: 17.8834409 },
    { lat: 48.4339647, lng: 17.884418 },
    { lat: 48.4336649, lng: 17.8870753 },
    { lat: 48.4330127, lng: 17.8893503 },
    { lat: 48.4328979, lng: 17.8912201 },
    { lat: 48.432904, lng: 17.8915822 },
    { lat: 48.4330091, lng: 17.89233 },
    { lat: 48.4333159, lng: 17.8935172 },
    { lat: 48.4335883, lng: 17.8943448 },
    { lat: 48.4336315, lng: 17.8954487 },
    { lat: 48.433733, lng: 17.896439 },
    { lat: 48.4337186, lng: 17.8972443 },
    { lat: 48.4335701, lng: 17.8983712 },
    { lat: 48.4328518, lng: 17.9014065 },
    { lat: 48.432201, lng: 17.9032101 },
    { lat: 48.4317848, lng: 17.9042252 },
    { lat: 48.4314012, lng: 17.9057964 },
    { lat: 48.4313058, lng: 17.9068868 },
    { lat: 48.4312272, lng: 17.9092865 },
    { lat: 48.4312487, lng: 17.9095672 },
    { lat: 48.4314416, lng: 17.910368 },
    { lat: 48.4314562, lng: 17.9108722 },
    { lat: 48.4313255, lng: 17.9117008 },
    { lat: 48.4309803, lng: 17.9128437 },
    { lat: 48.4305569, lng: 17.9136826 },
    { lat: 48.4305671, lng: 17.9135725 },
    { lat: 48.4299453, lng: 17.9142284 },
    { lat: 48.4296325, lng: 17.9144509 },
    { lat: 48.4295097, lng: 17.9144929 },
    { lat: 48.4290335, lng: 17.9144213 },
    { lat: 48.4286183, lng: 17.9144793 },
    { lat: 48.4284018, lng: 17.9145959 },
    { lat: 48.4278585, lng: 17.9151522 },
    { lat: 48.4270627, lng: 17.9162171 },
    { lat: 48.426736, lng: 17.9171647 },
    { lat: 48.4266373, lng: 17.9177557 },
    { lat: 48.4263205, lng: 17.9183231 },
    { lat: 48.4260927, lng: 17.9190763 },
    { lat: 48.425915, lng: 17.919974 },
    { lat: 48.42611, lng: 17.920096 },
    { lat: 48.426254, lng: 17.920112 },
    { lat: 48.426491, lng: 17.920113 },
    { lat: 48.426796, lng: 17.920106 },
    { lat: 48.426887, lng: 17.92014 },
    { lat: 48.426979, lng: 17.920199 },
    { lat: 48.427308, lng: 17.920509 },
    { lat: 48.42761, lng: 17.920775 },
    { lat: 48.427752, lng: 17.920815 },
    { lat: 48.427881, lng: 17.920815 },
    { lat: 48.428044, lng: 17.920781 },
    { lat: 48.428211, lng: 17.920695 },
    { lat: 48.428432, lng: 17.920496 },
    { lat: 48.428594, lng: 17.920479 },
    { lat: 48.428575, lng: 17.920712 },
    { lat: 48.428545, lng: 17.921158 },
    { lat: 48.428572, lng: 17.921204 },
    { lat: 48.428602, lng: 17.92131 },
    { lat: 48.42873, lng: 17.921576 },
    { lat: 48.428886, lng: 17.921895 },
    { lat: 48.429013, lng: 17.922108 },
    { lat: 48.429276, lng: 17.92257 },
    { lat: 48.429337, lng: 17.92271 },
    { lat: 48.42958, lng: 17.923193 },
    { lat: 48.429796, lng: 17.923653 },
    { lat: 48.429916, lng: 17.923932 },
    { lat: 48.430155, lng: 17.924505 },
    { lat: 48.43049, lng: 17.925408 },
    { lat: 48.430685, lng: 17.925939 },
    { lat: 48.430891, lng: 17.926472 },
    { lat: 48.431021, lng: 17.926794 },
    { lat: 48.431258, lng: 17.927333 },
    { lat: 48.431362, lng: 17.927514 },
    { lat: 48.431677, lng: 17.928048 },
    { lat: 48.431992, lng: 17.928555 },
    { lat: 48.432302, lng: 17.928967 },
    { lat: 48.432534, lng: 17.929312 },
    { lat: 48.432645, lng: 17.929469 },
    { lat: 48.432935, lng: 17.929837 },
    { lat: 48.433243, lng: 17.93025 },
    { lat: 48.43357, lng: 17.930697 },
    { lat: 48.43389, lng: 17.931134 },
    { lat: 48.434197, lng: 17.931503 },
    { lat: 48.434556, lng: 17.931916 },
    { lat: 48.434622, lng: 17.931737 },
    { lat: 48.4347, lng: 17.931552 },
    { lat: 48.434867, lng: 17.931457 },
    { lat: 48.435073, lng: 17.931454 },
    { lat: 48.435386, lng: 17.931569 },
    { lat: 48.435528, lng: 17.931703 },
    { lat: 48.435864, lng: 17.932062 },
    { lat: 48.436243, lng: 17.932451 },
    { lat: 48.436407, lng: 17.932523 },
    { lat: 48.43657, lng: 17.932528 },
    { lat: 48.43657, lng: 17.932547 },
    { lat: 48.436848, lng: 17.932608 },
    { lat: 48.437086, lng: 17.932598 },
    { lat: 48.437479, lng: 17.932517 },
    { lat: 48.4376, lng: 17.932465 },
    { lat: 48.437939, lng: 17.932243 },
    { lat: 48.438157, lng: 17.932053 },
    { lat: 48.438566, lng: 17.931806 },
    { lat: 48.438973, lng: 17.93157 },
    { lat: 48.43905, lng: 17.931542 },
    { lat: 48.439618, lng: 17.931609 },
    { lat: 48.439693, lng: 17.931638 },
    { lat: 48.44002, lng: 17.931787 },
    { lat: 48.440584, lng: 17.931911 },
    { lat: 48.440944, lng: 17.932008 },
    { lat: 48.441457, lng: 17.932167 },
    { lat: 48.441824, lng: 17.93227 },
    { lat: 48.442194, lng: 17.932362 },
    { lat: 48.442164, lng: 17.932667 },
    { lat: 48.442131, lng: 17.932851 },
    { lat: 48.442097, lng: 17.933028 },
    { lat: 48.442046, lng: 17.933228 },
    { lat: 48.441918, lng: 17.933637 },
    { lat: 48.441697, lng: 17.934435 },
    { lat: 48.441578, lng: 17.934879 },
    { lat: 48.441527, lng: 17.935154 },
    { lat: 48.441591, lng: 17.93547 },
    { lat: 48.441596, lng: 17.935979 },
    { lat: 48.441612, lng: 17.936199 },
    { lat: 48.441648, lng: 17.936592 },
    { lat: 48.441678, lng: 17.936964 },
    { lat: 48.44171, lng: 17.937332 },
    { lat: 48.441754, lng: 17.937779 },
    { lat: 48.441801, lng: 17.938175 },
    { lat: 48.441784, lng: 17.938604 },
    { lat: 48.441703, lng: 17.938897 },
    { lat: 48.441557, lng: 17.939747 },
    { lat: 48.441497, lng: 17.940135 },
    { lat: 48.441439, lng: 17.940555 },
    { lat: 48.441378, lng: 17.941021 },
    { lat: 48.44138, lng: 17.941274 },
    { lat: 48.441385, lng: 17.94179 },
    { lat: 48.441411, lng: 17.941928 },
    { lat: 48.441445, lng: 17.942351 },
    { lat: 48.441442, lng: 17.942452 },
    { lat: 48.441437, lng: 17.942975 },
    { lat: 48.441421, lng: 17.943201 },
    { lat: 48.441347, lng: 17.943468 },
    { lat: 48.441229, lng: 17.943907 },
    { lat: 48.441108, lng: 17.944435 },
    { lat: 48.441049, lng: 17.944657 },
    { lat: 48.44093, lng: 17.945014 },
    { lat: 48.440956, lng: 17.94503 },
    { lat: 48.44096, lng: 17.945046 },
    { lat: 48.441309, lng: 17.945048 },
    { lat: 48.441586, lng: 17.944978 },
    { lat: 48.441811, lng: 17.944907 },
    { lat: 48.442173, lng: 17.944868 },
    { lat: 48.442492, lng: 17.944789 },
    { lat: 48.442916, lng: 17.944664 },
    { lat: 48.443355, lng: 17.944551 },
    { lat: 48.443856, lng: 17.944342 },
    { lat: 48.444192, lng: 17.944192 },
    { lat: 48.445077, lng: 17.943693 },
    { lat: 48.445512, lng: 17.943454 },
    { lat: 48.445809, lng: 17.943285 },
    { lat: 48.446205, lng: 17.943011 },
    { lat: 48.446265, lng: 17.943202 },
    { lat: 48.446549, lng: 17.944155 },
    { lat: 48.446671, lng: 17.944671 },
    { lat: 48.446776, lng: 17.945274 },
    { lat: 48.446808, lng: 17.945535 },
    { lat: 48.446823, lng: 17.945809 },
    { lat: 48.446835, lng: 17.946228 },
    { lat: 48.446812, lng: 17.946508 },
    { lat: 48.446786, lng: 17.946819 },
    { lat: 48.446752, lng: 17.946895 },
    { lat: 48.446749, lng: 17.947006 },
  ],
  Koplotovce: [
    { lat: 48.501132, lng: 17.812709 },
    { lat: 48.500698, lng: 17.812343 },
    { lat: 48.500232, lng: 17.811924 },
    { lat: 48.499721, lng: 17.811469 },
    { lat: 48.499124, lng: 17.810939 },
    { lat: 48.498772, lng: 17.810616 },
    { lat: 48.498623, lng: 17.809542 },
    { lat: 48.498549, lng: 17.808799 },
    { lat: 48.498464, lng: 17.807983 },
    { lat: 48.49831, lng: 17.806948 },
    { lat: 48.498196, lng: 17.806209 },
    { lat: 48.498117, lng: 17.80562 },
    { lat: 48.497944, lng: 17.804859 },
    { lat: 48.497717, lng: 17.803887 },
    { lat: 48.497527, lng: 17.803281 },
    { lat: 48.497254, lng: 17.802787 },
    { lat: 48.496329, lng: 17.802823 },
    { lat: 48.4872821, lng: 17.804007 },
    { lat: 48.4835894, lng: 17.8018892 },
    { lat: 48.479638, lng: 17.7983712 },
    { lat: 48.4762749, lng: 17.7987025 },
    { lat: 48.472159, lng: 17.8003824 },
    { lat: 48.4704637, lng: 17.8005516 },
    { lat: 48.4696924, lng: 17.8003455 },
    { lat: 48.4689947, lng: 17.8001445 },
    { lat: 48.4692971, lng: 17.8008781 },
    { lat: 48.469468, lng: 17.8012902 },
    { lat: 48.4703896, lng: 17.8055685 },
    { lat: 48.470609, lng: 17.8077135 },
    { lat: 48.4697486, lng: 17.8075826 },
    { lat: 48.4705158, lng: 17.8101548 },
    { lat: 48.4698113, lng: 17.8106725 },
    { lat: 48.4708453, lng: 17.8134173 },
    { lat: 48.47201, lng: 17.8117719 },
    { lat: 48.4721931, lng: 17.8122553 },
    { lat: 48.4733165, lng: 17.8138039 },
    { lat: 48.4736792, lng: 17.8143998 },
    { lat: 48.4739663, lng: 17.8147201 },
    { lat: 48.4751285, lng: 17.8164627 },
    { lat: 48.4761906, lng: 17.8182875 },
    { lat: 48.476574, lng: 17.8195157 },
    { lat: 48.4766685, lng: 17.8201217 },
    { lat: 48.4766407, lng: 17.8201472 },
    { lat: 48.4769794, lng: 17.8210326 },
    { lat: 48.4770095, lng: 17.8214515 },
    { lat: 48.4771743, lng: 17.8221342 },
    { lat: 48.4779551, lng: 17.8237363 },
    { lat: 48.4783338, lng: 17.8249474 },
    { lat: 48.4785614, lng: 17.825493 },
    { lat: 48.478804, lng: 17.8263988 },
    { lat: 48.4788333, lng: 17.8271719 },
    { lat: 48.478802, lng: 17.8276747 },
    { lat: 48.4788693, lng: 17.8284282 },
    { lat: 48.4790884, lng: 17.8290761 },
    { lat: 48.4794008, lng: 17.8305128 },
    { lat: 48.4796225, lng: 17.8311671 },
    { lat: 48.4798368, lng: 17.8327561 },
    { lat: 48.4800655, lng: 17.8339492 },
    { lat: 48.4801601, lng: 17.8342048 },
    { lat: 48.4802034, lng: 17.8350823 },
    { lat: 48.480278, lng: 17.8353634 },
    { lat: 48.4802737, lng: 17.8358273 },
    { lat: 48.4804112, lng: 17.8363993 },
    { lat: 48.480171, lng: 17.836921 },
    { lat: 48.4801941, lng: 17.8369662 },
    { lat: 48.4806543, lng: 17.8378484 },
    { lat: 48.4814364, lng: 17.8392876 },
    { lat: 48.4817946, lng: 17.8392927 },
    { lat: 48.4822428, lng: 17.8393145 },
    { lat: 48.4826051, lng: 17.8388866 },
    { lat: 48.4830613, lng: 17.838687 },
    { lat: 48.4831306, lng: 17.838027 },
    { lat: 48.4830909, lng: 17.8376162 },
    { lat: 48.4831613, lng: 17.8367535 },
    { lat: 48.4832866, lng: 17.8356969 },
    { lat: 48.4834122, lng: 17.8351269 },
    { lat: 48.4840669, lng: 17.8339029 },
    { lat: 48.4843856, lng: 17.8331707 },
    { lat: 48.4845922, lng: 17.8325411 },
    { lat: 48.4850271, lng: 17.8315843 },
    { lat: 48.485393, lng: 17.830883 },
    { lat: 48.4855985, lng: 17.8306792 },
    { lat: 48.4861521, lng: 17.8302814 },
    { lat: 48.4867893, lng: 17.8302524 },
    { lat: 48.4873176, lng: 17.830362 },
    { lat: 48.4876682, lng: 17.8302609 },
    { lat: 48.4882798, lng: 17.8302593 },
    { lat: 48.4886882, lng: 17.8300862 },
    { lat: 48.4889298, lng: 17.8300867 },
    { lat: 48.4900879, lng: 17.8297135 },
    { lat: 48.490597, lng: 17.8293606 },
    { lat: 48.4907768, lng: 17.8292909 },
    { lat: 48.4908873, lng: 17.8293273 },
    { lat: 48.4913681, lng: 17.8289185 },
    { lat: 48.4917939, lng: 17.8287945 },
    { lat: 48.492541, lng: 17.8288838 },
    { lat: 48.4931847, lng: 17.8291091 },
    { lat: 48.4933552, lng: 17.828961 },
    { lat: 48.4939095, lng: 17.8279423 },
    { lat: 48.4941006, lng: 17.8276889 },
    { lat: 48.4941736, lng: 17.8274634 },
    { lat: 48.4942764, lng: 17.8273528 },
    { lat: 48.4944536, lng: 17.8268528 },
    { lat: 48.4950375, lng: 17.8256531 },
    { lat: 48.4953744, lng: 17.8251616 },
    { lat: 48.4958026, lng: 17.8247974 },
    { lat: 48.4961192, lng: 17.8244009 },
    { lat: 48.4961706, lng: 17.8240952 },
    { lat: 48.496153, lng: 17.8239737 },
    { lat: 48.4962321, lng: 17.8238279 },
    { lat: 48.4962298, lng: 17.8231979 },
    { lat: 48.4960926, lng: 17.8225972 },
    { lat: 48.4960853, lng: 17.8221988 },
    { lat: 48.4965082, lng: 17.8207881 },
    { lat: 48.4966644, lng: 17.8205533 },
    { lat: 48.4966476, lng: 17.8204097 },
    { lat: 48.4969448, lng: 17.8198429 },
    { lat: 48.4971759, lng: 17.8196532 },
    { lat: 48.4972376, lng: 17.8193297 },
    { lat: 48.4973916, lng: 17.8192642 },
    { lat: 48.4977366, lng: 17.8187787 },
    { lat: 48.4982071, lng: 17.8192461 },
    { lat: 48.4990969, lng: 17.8200527 },
    { lat: 48.501132, lng: 17.812709 },
  ],
  Siladice: [
    { lat: 48.372926, lng: 17.721612 },
    { lat: 48.372943, lng: 17.721484 },
    { lat: 48.372933, lng: 17.720408 },
    { lat: 48.372924, lng: 17.719484 },
    { lat: 48.372767, lng: 17.719143 },
    { lat: 48.372484, lng: 17.718543 },
    { lat: 48.372253, lng: 17.717884 },
    { lat: 48.372148, lng: 17.717248 },
    { lat: 48.371969, lng: 17.717002 },
    { lat: 48.371398, lng: 17.716626 },
    { lat: 48.371086, lng: 17.716351 },
    { lat: 48.370936, lng: 17.716208 },
    { lat: 48.370598, lng: 17.715833 },
    { lat: 48.370612, lng: 17.715818 },
    { lat: 48.370425, lng: 17.715343 },
    { lat: 48.370263, lng: 17.714931 },
    { lat: 48.370137, lng: 17.714688 },
    { lat: 48.37002, lng: 17.714443 },
    { lat: 48.369869, lng: 17.714183 },
    { lat: 48.369748, lng: 17.713824 },
    { lat: 48.369582, lng: 17.713317 },
    { lat: 48.369339, lng: 17.712822 },
    { lat: 48.369208, lng: 17.712591 },
    { lat: 48.369067, lng: 17.712393 },
    { lat: 48.368672, lng: 17.712004 },
    { lat: 48.368535, lng: 17.711904 },
    { lat: 48.368202, lng: 17.711614 },
    { lat: 48.367997, lng: 17.711303 },
    { lat: 48.367651, lng: 17.710766 },
    { lat: 48.367737, lng: 17.710202 },
    { lat: 48.367812, lng: 17.709751 },
    { lat: 48.367891, lng: 17.709249 },
    { lat: 48.368026, lng: 17.708395 },
    { lat: 48.368095, lng: 17.707912 },
    { lat: 48.368198, lng: 17.707233 },
    { lat: 48.368268, lng: 17.706766 },
    { lat: 48.368415, lng: 17.706034 },
    { lat: 48.368553, lng: 17.705453 },
    { lat: 48.36868, lng: 17.704879 },
    { lat: 48.368782, lng: 17.704393 },
    { lat: 48.36858, lng: 17.703905 },
    { lat: 48.368385, lng: 17.703425 },
    { lat: 48.36807, lng: 17.702998 },
    { lat: 48.367809, lng: 17.702618 },
    { lat: 48.367531, lng: 17.702225 },
    { lat: 48.367452, lng: 17.701814 },
    { lat: 48.36707, lng: 17.701865 },
    { lat: 48.366606, lng: 17.701893 },
    { lat: 48.366669, lng: 17.702813 },
    { lat: 48.36635, lng: 17.702716 },
    { lat: 48.365908, lng: 17.702591 },
    { lat: 48.36564, lng: 17.702533 },
    { lat: 48.36536, lng: 17.70231 },
    { lat: 48.365109, lng: 17.702113 },
    { lat: 48.364756, lng: 17.701748 },
    { lat: 48.364534, lng: 17.701513 },
    { lat: 48.364263, lng: 17.701444 },
    { lat: 48.363996, lng: 17.701552 },
    { lat: 48.363575, lng: 17.702228 },
    { lat: 48.36355, lng: 17.702269 },
    { lat: 48.363076, lng: 17.702292 },
    { lat: 48.362918, lng: 17.702252 },
    { lat: 48.362149, lng: 17.70228 },
    { lat: 48.361905, lng: 17.702238 },
    { lat: 48.361543, lng: 17.702089 },
    { lat: 48.361351, lng: 17.70198 },
    { lat: 48.361193, lng: 17.70183 },
    { lat: 48.360968, lng: 17.701496 },
    { lat: 48.360622, lng: 17.701019 },
    { lat: 48.360211, lng: 17.700865 },
    { lat: 48.3593516, lng: 17.7009402 },
    { lat: 48.358978, lng: 17.701339 },
    { lat: 48.358524, lng: 17.7018 },
    { lat: 48.358247, lng: 17.702156 },
    { lat: 48.357997, lng: 17.702362 },
    { lat: 48.357872, lng: 17.702436 },
    { lat: 48.357687, lng: 17.702472 },
    { lat: 48.357393, lng: 17.702387 },
    { lat: 48.35696, lng: 17.702163 },
    { lat: 48.356736, lng: 17.701893 },
    { lat: 48.356632, lng: 17.701854 },
    { lat: 48.356565, lng: 17.701827 },
    { lat: 48.356457, lng: 17.70184 },
    { lat: 48.356347, lng: 17.7019 },
    { lat: 48.356154, lng: 17.701922 },
    { lat: 48.356022, lng: 17.701961 },
    { lat: 48.355934, lng: 17.702074 },
    { lat: 48.355918, lng: 17.702149 },
    { lat: 48.355937, lng: 17.7022 },
    { lat: 48.356041, lng: 17.70238 },
    { lat: 48.35615, lng: 17.702626 },
    { lat: 48.356158, lng: 17.702695 },
    { lat: 48.356048, lng: 17.702942 },
    { lat: 48.356058, lng: 17.703008 },
    { lat: 48.356177, lng: 17.703217 },
    { lat: 48.356206, lng: 17.703327 },
    { lat: 48.356223, lng: 17.703429 },
    { lat: 48.356212, lng: 17.703504 },
    { lat: 48.356178, lng: 17.703585 },
    { lat: 48.356065, lng: 17.703722 },
    { lat: 48.356038, lng: 17.703789 },
    { lat: 48.356057, lng: 17.70384 },
    { lat: 48.356205, lng: 17.703908 },
    { lat: 48.356274, lng: 17.703948 },
    { lat: 48.356326, lng: 17.704015 },
    { lat: 48.356359, lng: 17.704217 },
    { lat: 48.356398, lng: 17.704303 },
    { lat: 48.356417, lng: 17.704366 },
    { lat: 48.356413, lng: 17.704427 },
    { lat: 48.35636, lng: 17.704546 },
    { lat: 48.356337, lng: 17.704595 },
    { lat: 48.356307, lng: 17.70473 },
    { lat: 48.356304, lng: 17.704783 },
    { lat: 48.356344, lng: 17.705191 },
    { lat: 48.356376, lng: 17.705321 },
    { lat: 48.356426, lng: 17.705495 },
    { lat: 48.356486, lng: 17.705625 },
    { lat: 48.356511, lng: 17.705713 },
    { lat: 48.356598, lng: 17.705922 },
    { lat: 48.35661, lng: 17.70598 },
    { lat: 48.35658, lng: 17.70603 },
    { lat: 48.356397, lng: 17.70603 },
    { lat: 48.356374, lng: 17.70604 },
    { lat: 48.356339, lng: 17.706114 },
    { lat: 48.356265, lng: 17.706178 },
    { lat: 48.356189, lng: 17.706208 },
    { lat: 48.356151, lng: 17.706249 },
    { lat: 48.356125, lng: 17.70632 },
    { lat: 48.356123, lng: 17.706399 },
    { lat: 48.35621, lng: 17.706629 },
    { lat: 48.356301, lng: 17.706931 },
    { lat: 48.356404, lng: 17.707055 },
    { lat: 48.35652, lng: 17.707279 },
    { lat: 48.356605, lng: 17.707441 },
    { lat: 48.356627, lng: 17.707681 },
    { lat: 48.356484, lng: 17.707891 },
    { lat: 48.356326, lng: 17.708196 },
    { lat: 48.356274, lng: 17.708687 },
    { lat: 48.356182, lng: 17.709122 },
    { lat: 48.356331, lng: 17.709572 },
    { lat: 48.356085, lng: 17.710156 },
    { lat: 48.355857, lng: 17.710683 },
    { lat: 48.355596, lng: 17.711283 },
    { lat: 48.355445, lng: 17.711626 },
    { lat: 48.355225, lng: 17.712124 },
    { lat: 48.355006, lng: 17.712616 },
    { lat: 48.354774, lng: 17.713201 },
    { lat: 48.354551, lng: 17.713769 },
    { lat: 48.354475, lng: 17.713963 },
    { lat: 48.354225, lng: 17.714575 },
    { lat: 48.354137, lng: 17.714796 },
    { lat: 48.353913, lng: 17.715217 },
    { lat: 48.353663, lng: 17.7157 },
    { lat: 48.353569, lng: 17.715869 },
    { lat: 48.353404, lng: 17.71619 },
    { lat: 48.353299, lng: 17.716409 },
    { lat: 48.353115, lng: 17.716784 },
    { lat: 48.353105, lng: 17.716806 },
    { lat: 48.353006, lng: 17.717154 },
    { lat: 48.352862, lng: 17.71764 },
    { lat: 48.352713, lng: 17.718129 },
    { lat: 48.352552, lng: 17.718808 },
    { lat: 48.35246, lng: 17.719228 },
    { lat: 48.352378, lng: 17.719555 },
    { lat: 48.352215, lng: 17.720271 },
    { lat: 48.35206, lng: 17.721079 },
    { lat: 48.352018, lng: 17.721298 },
    { lat: 48.351792, lng: 17.72222 },
    { lat: 48.351688, lng: 17.722763 },
    { lat: 48.351599, lng: 17.723207 },
    { lat: 48.351561, lng: 17.723812 },
    { lat: 48.351516, lng: 17.724538 },
    { lat: 48.351471, lng: 17.725261 },
    { lat: 48.351421, lng: 17.726026 },
    { lat: 48.351407, lng: 17.726495 },
    { lat: 48.351515, lng: 17.726542 },
    { lat: 48.351475, lng: 17.726905 },
    { lat: 48.351387, lng: 17.727673 },
    { lat: 48.351317, lng: 17.7283 },
    { lat: 48.351239, lng: 17.728995 },
    { lat: 48.351148, lng: 17.729807 },
    { lat: 48.351083, lng: 17.73038 },
    { lat: 48.351003, lng: 17.731101 },
    { lat: 48.350943, lng: 17.731602 },
    { lat: 48.350887, lng: 17.732109 },
    { lat: 48.350836, lng: 17.732559 },
    { lat: 48.350628, lng: 17.733009 },
    { lat: 48.3504, lng: 17.733489 },
    { lat: 48.350122, lng: 17.734086 },
    { lat: 48.3514517, lng: 17.7351434 },
    { lat: 48.3520958, lng: 17.7366973 },
    { lat: 48.3530627, lng: 17.739818 },
    { lat: 48.3527861, lng: 17.7400202 },
    { lat: 48.3528688, lng: 17.7403693 },
    { lat: 48.3520186, lng: 17.7413707 },
    { lat: 48.3503459, lng: 17.7421993 },
    { lat: 48.3474687, lng: 17.748115 },
    { lat: 48.3471596, lng: 17.750474 },
    { lat: 48.3470384, lng: 17.7540432 },
    { lat: 48.3470922, lng: 17.7547111 },
    { lat: 48.3472131, lng: 17.7554988 },
    { lat: 48.3478094, lng: 17.7562314 },
    { lat: 48.3487052, lng: 17.7569865 },
    { lat: 48.3495864, lng: 17.7571223 },
    { lat: 48.3503194, lng: 17.7573113 },
    { lat: 48.3507864, lng: 17.756721 },
    { lat: 48.3512076, lng: 17.7560263 },
    { lat: 48.3513295, lng: 17.7540532 },
    { lat: 48.3509676, lng: 17.7523303 },
    { lat: 48.3505757, lng: 17.7500661 },
    { lat: 48.3507172, lng: 17.7477727 },
    { lat: 48.3547449, lng: 17.7524682 },
    { lat: 48.3573178, lng: 17.7557352 },
    { lat: 48.3628501, lng: 17.7584401 },
    { lat: 48.3648217, lng: 17.7576043 },
    { lat: 48.3655311, lng: 17.7570436 },
    { lat: 48.3659212, lng: 17.7563584 },
    { lat: 48.3661293, lng: 17.7558369 },
    { lat: 48.3663542, lng: 17.7545605 },
    { lat: 48.366352, lng: 17.7536302 },
    { lat: 48.3659943, lng: 17.7524115 },
    { lat: 48.3664723, lng: 17.7502096 },
    { lat: 48.3665589, lng: 17.7494949 },
    { lat: 48.3660725, lng: 17.7480008 },
    { lat: 48.3650839, lng: 17.7459648 },
    { lat: 48.3642877, lng: 17.7446159 },
    { lat: 48.3638821, lng: 17.7437362 },
    { lat: 48.3635589, lng: 17.7423456 },
    { lat: 48.3635049, lng: 17.7419278 },
    { lat: 48.3635083, lng: 17.7409971 },
    { lat: 48.3637064, lng: 17.7401327 },
    { lat: 48.3639615, lng: 17.7394761 },
    { lat: 48.3641941, lng: 17.7390573 },
    { lat: 48.3650351, lng: 17.7384187 },
    { lat: 48.3655606, lng: 17.7382048 },
    { lat: 48.3661158, lng: 17.7381663 },
    { lat: 48.3665099, lng: 17.7382224 },
    { lat: 48.3674709, lng: 17.7387717 },
    { lat: 48.3678561, lng: 17.7389026 },
    { lat: 48.3689954, lng: 17.7397398 },
    { lat: 48.3701288, lng: 17.7407239 },
    { lat: 48.3713482, lng: 17.7416363 },
    { lat: 48.3716856, lng: 17.7419962 },
    { lat: 48.3722472, lng: 17.7406196 },
    { lat: 48.3723008, lng: 17.7404818 },
    { lat: 48.3736564, lng: 17.7379062 },
    { lat: 48.3746632, lng: 17.7359897 },
    { lat: 48.374633, lng: 17.7352645 },
    { lat: 48.3744245, lng: 17.7346726 },
    { lat: 48.3745471, lng: 17.7344825 },
    { lat: 48.3748951, lng: 17.734322 },
    { lat: 48.3749819, lng: 17.734138 },
    { lat: 48.3749629, lng: 17.7338509 },
    { lat: 48.3746791, lng: 17.7332048 },
    { lat: 48.3745557, lng: 17.7330001 },
    { lat: 48.3743864, lng: 17.7328714 },
    { lat: 48.3741976, lng: 17.7326173 },
    { lat: 48.374167, lng: 17.7323215 },
    { lat: 48.3741078, lng: 17.732241 },
    { lat: 48.3734161, lng: 17.7315209 },
    { lat: 48.3725013, lng: 17.7309514 },
    { lat: 48.3720755, lng: 17.730948 },
    { lat: 48.3718901, lng: 17.7307098 },
    { lat: 48.3717426, lng: 17.7307346 },
    { lat: 48.3715068, lng: 17.730937 },
    { lat: 48.371235, lng: 17.7309036 },
    { lat: 48.3711428, lng: 17.7308217 },
    { lat: 48.371024, lng: 17.7308511 },
    { lat: 48.3706639, lng: 17.7311446 },
    { lat: 48.3701414, lng: 17.7313302 },
    { lat: 48.370033, lng: 17.7314015 },
    { lat: 48.3699406, lng: 17.7318607 },
    { lat: 48.3698643, lng: 17.731996 },
    { lat: 48.3694308, lng: 17.73227 },
    { lat: 48.368716, lng: 17.7322116 },
    { lat: 48.3684947, lng: 17.7319932 },
    { lat: 48.3684334, lng: 17.7318054 },
    { lat: 48.3684745, lng: 17.7314311 },
    { lat: 48.3689045, lng: 17.7305213 },
    { lat: 48.368984, lng: 17.7302635 },
    { lat: 48.3691424, lng: 17.7297499 },
    { lat: 48.3691416, lng: 17.7295536 },
    { lat: 48.369025, lng: 17.7293867 },
    { lat: 48.36892, lng: 17.7288567 },
    { lat: 48.3689594, lng: 17.7279294 },
    { lat: 48.3691554, lng: 17.7277216 },
    { lat: 48.3693332, lng: 17.7274035 },
    { lat: 48.369408, lng: 17.7270262 },
    { lat: 48.3697609, lng: 17.7259816 },
    { lat: 48.3701936, lng: 17.7254236 },
    { lat: 48.371238, lng: 17.7238056 },
    { lat: 48.3728527, lng: 17.7218435 },
    { lat: 48.372926, lng: 17.721612 },
  ],
  DolnéZelenice: [
    { lat: 48.3773623, lng: 17.7624563 },
    { lat: 48.3788455, lng: 17.7618492 },
    { lat: 48.3805687, lng: 17.7616175 },
    { lat: 48.3811684, lng: 17.7613298 },
    { lat: 48.3818262, lng: 17.7600056 },
    { lat: 48.3817315, lng: 17.759074 },
    { lat: 48.3815022, lng: 17.7576502 },
    { lat: 48.381477, lng: 17.7568734 },
    { lat: 48.3816646, lng: 17.7557446 },
    { lat: 48.3813899, lng: 17.7535333 },
    { lat: 48.381331, lng: 17.7526522 },
    { lat: 48.3812693, lng: 17.7496814 },
    { lat: 48.384405, lng: 17.7463129 },
    { lat: 48.3848787, lng: 17.7458664 },
    { lat: 48.3855983, lng: 17.7453647 },
    { lat: 48.3862189, lng: 17.7450483 },
    { lat: 48.3884188, lng: 17.7440717 },
    { lat: 48.3887808, lng: 17.743992 },
    { lat: 48.3898439, lng: 17.7423614 },
    { lat: 48.3913566, lng: 17.7397176 },
    { lat: 48.3917543, lng: 17.7391363 },
    { lat: 48.3918161, lng: 17.7391344 },
    { lat: 48.3918236, lng: 17.7385977 },
    { lat: 48.3919668, lng: 17.7381563 },
    { lat: 48.3918449, lng: 17.7375745 },
    { lat: 48.3929209, lng: 17.7369026 },
    { lat: 48.393794, lng: 17.7360677 },
    { lat: 48.3935919, lng: 17.7344287 },
    { lat: 48.3935458, lng: 17.734048 },
    { lat: 48.3931995, lng: 17.7340004 },
    { lat: 48.3925178, lng: 17.7337313 },
    { lat: 48.3919901, lng: 17.7337226 },
    { lat: 48.3918812, lng: 17.7337702 },
    { lat: 48.3906399, lng: 17.7350353 },
    { lat: 48.3904885, lng: 17.7350895 },
    { lat: 48.3899107, lng: 17.7346228 },
    { lat: 48.389788, lng: 17.7346425 },
    { lat: 48.3895049, lng: 17.7348845 },
    { lat: 48.389332, lng: 17.7353218 },
    { lat: 48.3892964, lng: 17.7356013 },
    { lat: 48.3892286, lng: 17.7357106 },
    { lat: 48.3890654, lng: 17.7356997 },
    { lat: 48.3886825, lng: 17.735225 },
    { lat: 48.3886161, lng: 17.7348631 },
    { lat: 48.3884057, lng: 17.7344197 },
    { lat: 48.3883896, lng: 17.7337446 },
    { lat: 48.3880891, lng: 17.7326822 },
    { lat: 48.3879569, lng: 17.7323704 },
    { lat: 48.3877013, lng: 17.7320348 },
    { lat: 48.3873385, lng: 17.7315584 },
    { lat: 48.3872537, lng: 17.7314471 },
    { lat: 48.3865307, lng: 17.7327324 },
    { lat: 48.3855044, lng: 17.7341901 },
    { lat: 48.3847392, lng: 17.7348692 },
    { lat: 48.384413, lng: 17.7350683 },
    { lat: 48.3837088, lng: 17.7352185 },
    { lat: 48.3832482, lng: 17.7355134 },
    { lat: 48.3825662, lng: 17.7362782 },
    { lat: 48.3822455, lng: 17.7365481 },
    { lat: 48.3816699, lng: 17.7369329 },
    { lat: 48.3813075, lng: 17.7370453 },
    { lat: 48.3807504, lng: 17.7377106 },
    { lat: 48.3803891, lng: 17.7380576 },
    { lat: 48.380221, lng: 17.7381319 },
    { lat: 48.3792927, lng: 17.7381283 },
    { lat: 48.3781026, lng: 17.7398582 },
    { lat: 48.3776165, lng: 17.7407759 },
    { lat: 48.3752286, lng: 17.739045 },
    { lat: 48.3747878, lng: 17.7386218 },
    { lat: 48.3744124, lng: 17.7384658 },
    { lat: 48.3737288, lng: 17.7379835 },
    { lat: 48.3736564, lng: 17.7379062 },
    { lat: 48.3723008, lng: 17.7404818 },
    { lat: 48.3722472, lng: 17.7406196 },
    { lat: 48.3722545, lng: 17.7406278 },
    { lat: 48.3729738, lng: 17.7414119 },
    { lat: 48.3734634, lng: 17.7422452 },
    { lat: 48.3736596, lng: 17.7429678 },
    { lat: 48.3738468, lng: 17.7445698 },
    { lat: 48.3740026, lng: 17.7449543 },
    { lat: 48.3743369, lng: 17.7454663 },
    { lat: 48.3738209, lng: 17.7463415 },
    { lat: 48.3736533, lng: 17.7462253 },
    { lat: 48.3721774, lng: 17.748643 },
    { lat: 48.3716479, lng: 17.7489175 },
    { lat: 48.3703872, lng: 17.7497459 },
    { lat: 48.3691041, lng: 17.7509779 },
    { lat: 48.3691627, lng: 17.752347 },
    { lat: 48.3688899, lng: 17.7535589 },
    { lat: 48.3681565, lng: 17.7574002 },
    { lat: 48.3688677, lng: 17.757615 },
    { lat: 48.3699549, lng: 17.7579657 },
    { lat: 48.3709579, lng: 17.7570364 },
    { lat: 48.3716087, lng: 17.756738 },
    { lat: 48.373911, lng: 17.7587148 },
    { lat: 48.3773623, lng: 17.7624563 },
  ],
  Hlohovec: [
    { lat: 48.3736564, lng: 17.7379062 },
    { lat: 48.3737288, lng: 17.7379835 },
    { lat: 48.3744124, lng: 17.7384658 },
    { lat: 48.3747878, lng: 17.7386218 },
    { lat: 48.3752286, lng: 17.739045 },
    { lat: 48.3776165, lng: 17.7407759 },
    { lat: 48.3781026, lng: 17.7398582 },
    { lat: 48.3792927, lng: 17.7381283 },
    { lat: 48.380221, lng: 17.7381319 },
    { lat: 48.3803891, lng: 17.7380576 },
    { lat: 48.3807504, lng: 17.7377106 },
    { lat: 48.3813075, lng: 17.7370453 },
    { lat: 48.3816699, lng: 17.7369329 },
    { lat: 48.3822455, lng: 17.7365481 },
    { lat: 48.3825662, lng: 17.7362782 },
    { lat: 48.3832482, lng: 17.7355134 },
    { lat: 48.3837088, lng: 17.7352185 },
    { lat: 48.384413, lng: 17.7350683 },
    { lat: 48.3847392, lng: 17.7348692 },
    { lat: 48.3855044, lng: 17.7341901 },
    { lat: 48.3865307, lng: 17.7327324 },
    { lat: 48.3872537, lng: 17.7314471 },
    { lat: 48.3873385, lng: 17.7315584 },
    { lat: 48.3877013, lng: 17.7320348 },
    { lat: 48.3879569, lng: 17.7323704 },
    { lat: 48.3880891, lng: 17.7326822 },
    { lat: 48.3883896, lng: 17.7337446 },
    { lat: 48.3884057, lng: 17.7344197 },
    { lat: 48.3886161, lng: 17.7348631 },
    { lat: 48.3886825, lng: 17.735225 },
    { lat: 48.3890654, lng: 17.7356997 },
    { lat: 48.3892286, lng: 17.7357106 },
    { lat: 48.3892964, lng: 17.7356013 },
    { lat: 48.389332, lng: 17.7353218 },
    { lat: 48.3895049, lng: 17.7348845 },
    { lat: 48.389788, lng: 17.7346425 },
    { lat: 48.3899107, lng: 17.7346228 },
    { lat: 48.3904885, lng: 17.7350895 },
    { lat: 48.3906399, lng: 17.7350353 },
    { lat: 48.3918812, lng: 17.7337702 },
    { lat: 48.3919901, lng: 17.7337226 },
    { lat: 48.3925178, lng: 17.7337313 },
    { lat: 48.3931995, lng: 17.7340004 },
    { lat: 48.3935458, lng: 17.734048 },
    { lat: 48.39358, lng: 17.7334176 },
    { lat: 48.3936213, lng: 17.7331361 },
    { lat: 48.3937096, lng: 17.7329043 },
    { lat: 48.3942492, lng: 17.7324459 },
    { lat: 48.3952226, lng: 17.7318291 },
    { lat: 48.3953506, lng: 17.7315654 },
    { lat: 48.3954187, lng: 17.7311878 },
    { lat: 48.395569, lng: 17.7309515 },
    { lat: 48.3956906, lng: 17.7308567 },
    { lat: 48.3959963, lng: 17.7308867 },
    { lat: 48.3970345, lng: 17.7313729 },
    { lat: 48.3976868, lng: 17.7314753 },
    { lat: 48.3981555, lng: 17.731296 },
    { lat: 48.3992062, lng: 17.7317094 },
    { lat: 48.3994796, lng: 17.731966 },
    { lat: 48.3996353, lng: 17.7320206 },
    { lat: 48.4005298, lng: 17.7320618 },
    { lat: 48.4006674, lng: 17.7319687 },
    { lat: 48.4012129, lng: 17.7308752 },
    { lat: 48.4021133, lng: 17.7292843 },
    { lat: 48.4031902, lng: 17.7266589 },
    { lat: 48.4036522, lng: 17.7259177 },
    { lat: 48.404042, lng: 17.7256112 },
    { lat: 48.4046358, lng: 17.7252829 },
    { lat: 48.4051942, lng: 17.7252573 },
    { lat: 48.4056274, lng: 17.7253409 },
    { lat: 48.4061797, lng: 17.725819 },
    { lat: 48.4065606, lng: 17.7263712 },
    { lat: 48.4068107, lng: 17.727055 },
    { lat: 48.4069767, lng: 17.7280363 },
    { lat: 48.4069996, lng: 17.7289329 },
    { lat: 48.4069022, lng: 17.7298953 },
    { lat: 48.4073572, lng: 17.7304225 },
    { lat: 48.4062671, lng: 17.7316332 },
    { lat: 48.405983, lng: 17.7320383 },
    { lat: 48.4049899, lng: 17.7330139 },
    { lat: 48.4038787, lng: 17.7345387 },
    { lat: 48.4037422, lng: 17.734852 },
    { lat: 48.4036632, lng: 17.7353786 },
    { lat: 48.4034393, lng: 17.7377607 },
    { lat: 48.4025748, lng: 17.740666 },
    { lat: 48.4022886, lng: 17.7414397 },
    { lat: 48.4016227, lng: 17.7440333 },
    { lat: 48.4010007, lng: 17.7450947 },
    { lat: 48.4006728, lng: 17.7451024 },
    { lat: 48.4001096, lng: 17.7453985 },
    { lat: 48.4004081, lng: 17.7464262 },
    { lat: 48.3979478, lng: 17.7482695 },
    { lat: 48.3979793, lng: 17.7489959 },
    { lat: 48.3979477, lng: 17.7497087 },
    { lat: 48.3978991, lng: 17.7500743 },
    { lat: 48.3977392, lng: 17.7505919 },
    { lat: 48.3976797, lng: 17.7507437 },
    { lat: 48.3966671, lng: 17.7522228 },
    { lat: 48.3964576, lng: 17.752635 },
    { lat: 48.3967718, lng: 17.7531525 },
    { lat: 48.3967968, lng: 17.7542482 },
    { lat: 48.3962148, lng: 17.7546053 },
    { lat: 48.395082, lng: 17.7544705 },
    { lat: 48.3944214, lng: 17.7543232 },
    { lat: 48.3934034, lng: 17.7544007 },
    { lat: 48.3933315, lng: 17.7560893 },
    { lat: 48.392068, lng: 17.7561399 },
    { lat: 48.3919888, lng: 17.7584777 },
    { lat: 48.3921215, lng: 17.7587068 },
    { lat: 48.3910255, lng: 17.7597539 },
    { lat: 48.39107, lng: 17.7598873 },
    { lat: 48.3900169, lng: 17.7602577 },
    { lat: 48.3895774, lng: 17.7607846 },
    { lat: 48.3892799, lng: 17.761395 },
    { lat: 48.3884963, lng: 17.7633139 },
    { lat: 48.3883496, lng: 17.7648321 },
    { lat: 48.3883159, lng: 17.7660992 },
    { lat: 48.3882696, lng: 17.7664836 },
    { lat: 48.3882624, lng: 17.7665119 },
    { lat: 48.3881434, lng: 17.7675088 },
    { lat: 48.3876595, lng: 17.7696391 },
    { lat: 48.3851785, lng: 17.7748122 },
    { lat: 48.3847315, lng: 17.7759647 },
    { lat: 48.3843859, lng: 17.776864 },
    { lat: 48.3866737, lng: 17.7780788 },
    { lat: 48.3874276, lng: 17.7783883 },
    { lat: 48.3885989, lng: 17.7788184 },
    { lat: 48.3896778, lng: 17.7790312 },
    { lat: 48.3906947, lng: 17.7788214 },
    { lat: 48.3911456, lng: 17.7786643 },
    { lat: 48.3922921, lng: 17.7785648 },
    { lat: 48.3930169, lng: 17.7785639 },
    { lat: 48.3940442, lng: 17.7787303 },
    { lat: 48.394813, lng: 17.778492 },
    { lat: 48.3952878, lng: 17.7785568 },
    { lat: 48.3958931, lng: 17.7784472 },
    { lat: 48.3960779, lng: 17.7782947 },
    { lat: 48.3964475, lng: 17.7781563 },
    { lat: 48.396815, lng: 17.7811156 },
    { lat: 48.3971532, lng: 17.7818065 },
    { lat: 48.3971473, lng: 17.7825704 },
    { lat: 48.3973206, lng: 17.7842867 },
    { lat: 48.3972096, lng: 17.7853625 },
    { lat: 48.3971487, lng: 17.7867568 },
    { lat: 48.3975183, lng: 17.7870427 },
    { lat: 48.397783, lng: 17.7873811 },
    { lat: 48.3981719, lng: 17.7876465 },
    { lat: 48.3989403, lng: 17.78793 },
    { lat: 48.4003747, lng: 17.7883815 },
    { lat: 48.4025834, lng: 17.7886826 },
    { lat: 48.4029536, lng: 17.7885733 },
    { lat: 48.4032333, lng: 17.7885669 },
    { lat: 48.4034072, lng: 17.7887527 },
    { lat: 48.4053289, lng: 17.7899677 },
    { lat: 48.4057166, lng: 17.7901012 },
    { lat: 48.406458, lng: 17.790483 },
    { lat: 48.4066466, lng: 17.7905365 },
    { lat: 48.4088065, lng: 17.7905403 },
    { lat: 48.4088173, lng: 17.7907748 },
    { lat: 48.408808, lng: 17.7910273 },
    { lat: 48.408684, lng: 17.7913422 },
    { lat: 48.4085377, lng: 17.7919723 },
    { lat: 48.4079826, lng: 17.793429 },
    { lat: 48.4075321, lng: 17.7941637 },
    { lat: 48.406, lng: 17.7958534 },
    { lat: 48.4057123, lng: 17.7962063 },
    { lat: 48.4054632, lng: 17.7966334 },
    { lat: 48.4051464, lng: 17.7973229 },
    { lat: 48.4048547, lng: 17.7988605 },
    { lat: 48.4047551, lng: 17.7992026 },
    { lat: 48.4041409, lng: 17.800307 },
    { lat: 48.4040991, lng: 17.8005093 },
    { lat: 48.4036401, lng: 17.8012323 },
    { lat: 48.4017279, lng: 17.803186 },
    { lat: 48.4013301, lng: 17.8036592 },
    { lat: 48.4024108, lng: 17.8053557 },
    { lat: 48.4020533, lng: 17.8054165 },
    { lat: 48.401108, lng: 17.8070107 },
    { lat: 48.4023992, lng: 17.8083679 },
    { lat: 48.4027556, lng: 17.8088259 },
    { lat: 48.4035963, lng: 17.8106765 },
    { lat: 48.4046201, lng: 17.8124087 },
    { lat: 48.4040281, lng: 17.8129506 },
    { lat: 48.4038523, lng: 17.813251 },
    { lat: 48.4037794, lng: 17.8135563 },
    { lat: 48.4036379, lng: 17.813826 },
    { lat: 48.4034269, lng: 17.8140654 },
    { lat: 48.4031254, lng: 17.8145811 },
    { lat: 48.402133, lng: 17.8153183 },
    { lat: 48.4018509, lng: 17.8158141 },
    { lat: 48.4014437, lng: 17.8161333 },
    { lat: 48.4012923, lng: 17.8163336 },
    { lat: 48.4010151, lng: 17.8164891 },
    { lat: 48.3985695, lng: 17.8183485 },
    { lat: 48.3978616, lng: 17.8190111 },
    { lat: 48.3971626, lng: 17.8198927 },
    { lat: 48.3965421, lng: 17.8204871 },
    { lat: 48.3960033, lng: 17.8207796 },
    { lat: 48.3957323, lng: 17.8210898 },
    { lat: 48.3954169, lng: 17.8213248 },
    { lat: 48.3947587, lng: 17.8214708 },
    { lat: 48.393725, lng: 17.8219743 },
    { lat: 48.3931516, lng: 17.8226389 },
    { lat: 48.392467, lng: 17.8233166 },
    { lat: 48.3922059, lng: 17.8235142 },
    { lat: 48.3918536, lng: 17.8236414 },
    { lat: 48.3915061, lng: 17.8236648 },
    { lat: 48.3908219, lng: 17.8244721 },
    { lat: 48.3897883, lng: 17.8247028 },
    { lat: 48.3886491, lng: 17.8250862 },
    { lat: 48.3878808, lng: 17.8255939 },
    { lat: 48.3873608, lng: 17.8261731 },
    { lat: 48.3867181, lng: 17.8267199 },
    { lat: 48.3857964, lng: 17.827101 },
    { lat: 48.3853249, lng: 17.8274761 },
    { lat: 48.3843992, lng: 17.8274958 },
    { lat: 48.3840674, lng: 17.8283637 },
    { lat: 48.3840425, lng: 17.8290458 },
    { lat: 48.3840363, lng: 17.8290725 },
    { lat: 48.3841856, lng: 17.829297 },
    { lat: 48.3844447, lng: 17.8295646 },
    { lat: 48.3852556, lng: 17.829738 },
    { lat: 48.3862283, lng: 17.8294909 },
    { lat: 48.3881609, lng: 17.8294724 },
    { lat: 48.3890645, lng: 17.8298859 },
    { lat: 48.3895876, lng: 17.8302425 },
    { lat: 48.3899829, lng: 17.8306529 },
    { lat: 48.3916836, lng: 17.8315472 },
    { lat: 48.3919613, lng: 17.8315658 },
    { lat: 48.3922452, lng: 17.8314474 },
    { lat: 48.3926003, lng: 17.831494 },
    { lat: 48.3928707, lng: 17.8322396 },
    { lat: 48.3928352, lng: 17.8329329 },
    { lat: 48.3929103, lng: 17.8332951 },
    { lat: 48.3928065, lng: 17.8338341 },
    { lat: 48.3928142, lng: 17.8345672 },
    { lat: 48.3929342, lng: 17.8357374 },
    { lat: 48.392919, lng: 17.836891 },
    { lat: 48.3925809, lng: 17.8374554 },
    { lat: 48.3914096, lng: 17.838407 },
    { lat: 48.390931, lng: 17.8386843 },
    { lat: 48.3905895, lng: 17.8387139 },
    { lat: 48.3899701, lng: 17.8386071 },
    { lat: 48.3891825, lng: 17.8388189 },
    { lat: 48.3888551, lng: 17.8388 },
    { lat: 48.3879364, lng: 17.8389332 },
    { lat: 48.3870816, lng: 17.8387419 },
    { lat: 48.3865517, lng: 17.838718 },
    { lat: 48.3860168, lng: 17.8388875 },
    { lat: 48.3854902, lng: 17.839395 },
    { lat: 48.3852515, lng: 17.8397209 },
    { lat: 48.3850671, lng: 17.8403965 },
    { lat: 48.3849144, lng: 17.8415405 },
    { lat: 48.3849323, lng: 17.8415551 },
    { lat: 48.3858064, lng: 17.8423731 },
    { lat: 48.3876779, lng: 17.8444052 },
    { lat: 48.3883647, lng: 17.8449688 },
    { lat: 48.389107, lng: 17.8458487 },
    { lat: 48.3920593, lng: 17.8488506 },
    { lat: 48.3946668, lng: 17.8480687 },
    { lat: 48.3949669, lng: 17.8478786 },
    { lat: 48.3953503, lng: 17.8488158 },
    { lat: 48.3971631, lng: 17.8517965 },
    { lat: 48.401307, lng: 17.8575538 },
    { lat: 48.4037582, lng: 17.8610849 },
    { lat: 48.403994, lng: 17.8615432 },
    { lat: 48.4040596, lng: 17.8617708 },
    { lat: 48.4042683, lng: 17.8619883 },
    { lat: 48.4050334, lng: 17.8612243 },
    { lat: 48.4056697, lng: 17.8606999 },
    { lat: 48.4062461, lng: 17.8603945 },
    { lat: 48.4062884, lng: 17.8604789 },
    { lat: 48.4067497, lng: 17.8603239 },
    { lat: 48.4072052, lng: 17.8602756 },
    { lat: 48.4075124, lng: 17.8605191 },
    { lat: 48.4077135, lng: 17.8605597 },
    { lat: 48.4088281, lng: 17.8599171 },
    { lat: 48.4096018, lng: 17.8608522 },
    { lat: 48.4098618, lng: 17.8609157 },
    { lat: 48.4103715, lng: 17.86133 },
    { lat: 48.4107112, lng: 17.8614787 },
    { lat: 48.4112482, lng: 17.861195 },
    { lat: 48.4114044, lng: 17.8613864 },
    { lat: 48.4116328, lng: 17.8611898 },
    { lat: 48.4124242, lng: 17.8601303 },
    { lat: 48.4130018, lng: 17.8592167 },
    { lat: 48.4138038, lng: 17.860411 },
    { lat: 48.4140102, lng: 17.8609654 },
    { lat: 48.4142529, lng: 17.8607988 },
    { lat: 48.4144582, lng: 17.8607589 },
    { lat: 48.4146829, lng: 17.86091 },
    { lat: 48.4153032, lng: 17.8609193 },
    { lat: 48.4154928, lng: 17.8608425 },
    { lat: 48.4155757, lng: 17.8609605 },
    { lat: 48.4157515, lng: 17.8609193 },
    { lat: 48.4159088, lng: 17.8607989 },
    { lat: 48.417124, lng: 17.8606931 },
    { lat: 48.4176499, lng: 17.860209 },
    { lat: 48.4182789, lng: 17.8605669 },
    { lat: 48.4184602, lng: 17.8605555 },
    { lat: 48.4186081, lng: 17.860463 },
    { lat: 48.4186996, lng: 17.8603186 },
    { lat: 48.4188998, lng: 17.8603981 },
    { lat: 48.4192803, lng: 17.8601941 },
    { lat: 48.4199467, lng: 17.8600159 },
    { lat: 48.4210071, lng: 17.8591125 },
    { lat: 48.4216394, lng: 17.8587815 },
    { lat: 48.422111, lng: 17.8583695 },
    { lat: 48.4223439, lng: 17.8582665 },
    { lat: 48.4224487, lng: 17.8580765 },
    { lat: 48.422966, lng: 17.8575431 },
    { lat: 48.4231186, lng: 17.8572676 },
    { lat: 48.4236376, lng: 17.8566682 },
    { lat: 48.4239368, lng: 17.8564469 },
    { lat: 48.4247524, lng: 17.8556414 },
    { lat: 48.4257079, lng: 17.8549631 },
    { lat: 48.4260094, lng: 17.8546842 },
    { lat: 48.4262481, lng: 17.8545458 },
    { lat: 48.4266893, lng: 17.8545413 },
    { lat: 48.426867, lng: 17.854476 },
    { lat: 48.4274313, lng: 17.8539389 },
    { lat: 48.4278243, lng: 17.855051 },
    { lat: 48.4279554, lng: 17.8553127 },
    { lat: 48.4289984, lng: 17.8532528 },
    { lat: 48.4294932, lng: 17.8528908 },
    { lat: 48.4300311, lng: 17.8522147 },
    { lat: 48.4301565, lng: 17.8521214 },
    { lat: 48.4301982, lng: 17.851971 },
    { lat: 48.4305434, lng: 17.8517092 },
    { lat: 48.4310028, lng: 17.8509287 },
    { lat: 48.4311852, lng: 17.8504582 },
    { lat: 48.4312611, lng: 17.8497987 },
    { lat: 48.4315793, lng: 17.8493863 },
    { lat: 48.4321738, lng: 17.8489174 },
    { lat: 48.4324828, lng: 17.8487825 },
    { lat: 48.4326323, lng: 17.8487712 },
    { lat: 48.4333005, lng: 17.8489718 },
    { lat: 48.43412, lng: 17.8495284 },
    { lat: 48.4344112, lng: 17.8501029 },
    { lat: 48.434925, lng: 17.8507169 },
    { lat: 48.4352158, lng: 17.8512066 },
    { lat: 48.435619, lng: 17.8521362 },
    { lat: 48.4357894, lng: 17.8527285 },
    { lat: 48.4359466, lng: 17.852661 },
    { lat: 48.4361316, lng: 17.8537522 },
    { lat: 48.4363135, lng: 17.8561857 },
    { lat: 48.436762, lng: 17.8580086 },
    { lat: 48.436864, lng: 17.858627 },
    { lat: 48.4368654, lng: 17.8591512 },
    { lat: 48.4367024, lng: 17.8605358 },
    { lat: 48.4365023, lng: 17.8612366 },
    { lat: 48.4352694, lng: 17.8640177 },
    { lat: 48.435138, lng: 17.8644354 },
    { lat: 48.4350417, lng: 17.8649487 },
    { lat: 48.4353655, lng: 17.8670848 },
    { lat: 48.4357946, lng: 17.8684447 },
    { lat: 48.4360464, lng: 17.8694852 },
    { lat: 48.4361934, lng: 17.8704533 },
    { lat: 48.4361896, lng: 17.8711182 },
    { lat: 48.4363051, lng: 17.8711244 },
    { lat: 48.4367351, lng: 17.8723857 },
    { lat: 48.4372952, lng: 17.8736157 },
    { lat: 48.4378818, lng: 17.8744645 },
    { lat: 48.4389791, lng: 17.877481 },
    { lat: 48.4389492, lng: 17.8775263 },
    { lat: 48.4392367, lng: 17.8780227 },
    { lat: 48.43962, lng: 17.8786822 },
    { lat: 48.4395826, lng: 17.8785505 },
    { lat: 48.4406522, lng: 17.875788 },
    { lat: 48.4408592, lng: 17.8753479 },
    { lat: 48.442024, lng: 17.8734867 },
    { lat: 48.4427508, lng: 17.8724793 },
    { lat: 48.4440002, lng: 17.8713409 },
    { lat: 48.4449378, lng: 17.8700079 },
    { lat: 48.4449893, lng: 17.8699347 },
    { lat: 48.4461999, lng: 17.8682135 },
    { lat: 48.4469235, lng: 17.866613 },
    { lat: 48.4471687, lng: 17.8655204 },
    { lat: 48.447263, lng: 17.8645112 },
    { lat: 48.4474048, lng: 17.8639441 },
    { lat: 48.4477851, lng: 17.8628657 },
    { lat: 48.4481558, lng: 17.862169 },
    { lat: 48.4483137, lng: 17.8619806 },
    { lat: 48.4485582, lng: 17.8617685 },
    { lat: 48.4485339, lng: 17.8617391 },
    { lat: 48.4485348, lng: 17.86166 },
    { lat: 48.4490463, lng: 17.8611254 },
    { lat: 48.4495209, lng: 17.8604845 },
    { lat: 48.4499235, lng: 17.8600675 },
    { lat: 48.4516664, lng: 17.8576905 },
    { lat: 48.4526993, lng: 17.8564678 },
    { lat: 48.4533245, lng: 17.8552744 },
    { lat: 48.4539219, lng: 17.8539503 },
    { lat: 48.4542306, lng: 17.8532661 },
    { lat: 48.4546918, lng: 17.8526813 },
    { lat: 48.4557626, lng: 17.8520624 },
    { lat: 48.4558791, lng: 17.852068 },
    { lat: 48.4566928, lng: 17.8517647 },
    { lat: 48.4572137, lng: 17.8512901 },
    { lat: 48.4572999, lng: 17.8510002 },
    { lat: 48.4581733, lng: 17.8496412 },
    { lat: 48.4583971, lng: 17.8488696 },
    { lat: 48.4584598, lng: 17.848399 },
    { lat: 48.4583837, lng: 17.8480385 },
    { lat: 48.458517, lng: 17.8475944 },
    { lat: 48.458631, lng: 17.8476491 },
    { lat: 48.4586524, lng: 17.8474438 },
    { lat: 48.4588653, lng: 17.8469749 },
    { lat: 48.4599658, lng: 17.8447466 },
    { lat: 48.4601694, lng: 17.8444589 },
    { lat: 48.4619149, lng: 17.8431447 },
    { lat: 48.4629485, lng: 17.8424555 },
    { lat: 48.4645931, lng: 17.8414716 },
    { lat: 48.4647282, lng: 17.8415569 },
    { lat: 48.4654256, lng: 17.8412391 },
    { lat: 48.4658526, lng: 17.8408349 },
    { lat: 48.4663623, lng: 17.839918 },
    { lat: 48.4668697, lng: 17.8393913 },
    { lat: 48.4675134, lng: 17.8394096 },
    { lat: 48.467891, lng: 17.8392359 },
    { lat: 48.4682321, lng: 17.8391825 },
    { lat: 48.4693177, lng: 17.8393423 },
    { lat: 48.4701474, lng: 17.8396967 },
    { lat: 48.4704722, lng: 17.8399486 },
    { lat: 48.4710032, lng: 17.8399003 },
    { lat: 48.4717961, lng: 17.8400158 },
    { lat: 48.4728686, lng: 17.84026 },
    { lat: 48.4731394, lng: 17.840387 },
    { lat: 48.4732746, lng: 17.8403662 },
    { lat: 48.4740488, lng: 17.8405626 },
    { lat: 48.4749532, lng: 17.840862 },
    { lat: 48.4751865, lng: 17.8408743 },
    { lat: 48.4756999, lng: 17.840574 },
    { lat: 48.4765673, lng: 17.840712 },
    { lat: 48.4771253, lng: 17.8409759 },
    { lat: 48.4777979, lng: 17.8411585 },
    { lat: 48.4783381, lng: 17.8406286 },
    { lat: 48.4787259, lng: 17.8399756 },
    { lat: 48.479175, lng: 17.839507 },
    { lat: 48.4794395, lng: 17.8390877 },
    { lat: 48.4796598, lng: 17.8385151 },
    { lat: 48.4797717, lng: 17.8376304 },
    { lat: 48.4801146, lng: 17.8371985 },
    { lat: 48.4801941, lng: 17.8369662 },
    { lat: 48.480171, lng: 17.836921 },
    { lat: 48.4804112, lng: 17.8363993 },
    { lat: 48.4802737, lng: 17.8358273 },
    { lat: 48.480278, lng: 17.8353634 },
    { lat: 48.4802034, lng: 17.8350823 },
    { lat: 48.4801601, lng: 17.8342048 },
    { lat: 48.4800655, lng: 17.8339492 },
    { lat: 48.4798368, lng: 17.8327561 },
    { lat: 48.4796225, lng: 17.8311671 },
    { lat: 48.4794008, lng: 17.8305128 },
    { lat: 48.4790884, lng: 17.8290761 },
    { lat: 48.4788693, lng: 17.8284282 },
    { lat: 48.478802, lng: 17.8276747 },
    { lat: 48.4788333, lng: 17.8271719 },
    { lat: 48.478804, lng: 17.8263988 },
    { lat: 48.4785614, lng: 17.825493 },
    { lat: 48.4783338, lng: 17.8249474 },
    { lat: 48.4779551, lng: 17.8237363 },
    { lat: 48.4771743, lng: 17.8221342 },
    { lat: 48.4770095, lng: 17.8214515 },
    { lat: 48.4769794, lng: 17.8210326 },
    { lat: 48.4766407, lng: 17.8201472 },
    { lat: 48.4766685, lng: 17.8201217 },
    { lat: 48.476574, lng: 17.8195157 },
    { lat: 48.4761906, lng: 17.8182875 },
    { lat: 48.4751285, lng: 17.8164627 },
    { lat: 48.4739663, lng: 17.8147201 },
    { lat: 48.4736792, lng: 17.8143998 },
    { lat: 48.4733165, lng: 17.8138039 },
    { lat: 48.4721931, lng: 17.8122553 },
    { lat: 48.47201, lng: 17.8117719 },
    { lat: 48.4708453, lng: 17.8134173 },
    { lat: 48.4698113, lng: 17.8106725 },
    { lat: 48.4705158, lng: 17.8101548 },
    { lat: 48.4697486, lng: 17.8075826 },
    { lat: 48.470609, lng: 17.8077135 },
    { lat: 48.4703896, lng: 17.8055685 },
    { lat: 48.469468, lng: 17.8012902 },
    { lat: 48.4692971, lng: 17.8008781 },
    { lat: 48.4689947, lng: 17.8001445 },
    { lat: 48.4687416, lng: 17.7992572 },
    { lat: 48.4681087, lng: 17.7965759 },
    { lat: 48.4651687, lng: 17.7936285 },
    { lat: 48.46359, lng: 17.7925948 },
    { lat: 48.4626788, lng: 17.7921689 },
    { lat: 48.4614818, lng: 17.7917414 },
    { lat: 48.4562602, lng: 17.7906519 },
    { lat: 48.4560199, lng: 17.7906569 },
    { lat: 48.4546228, lng: 17.79037 },
    { lat: 48.4538901, lng: 17.790296 },
    { lat: 48.4527286, lng: 17.7903004 },
    { lat: 48.4513794, lng: 17.7904272 },
    { lat: 48.4503009, lng: 17.7906428 },
    { lat: 48.4487143, lng: 17.7911492 },
    { lat: 48.4468476, lng: 17.7920406 },
    { lat: 48.446319, lng: 17.7926086 },
    { lat: 48.4463, lng: 17.7924654 },
    { lat: 48.4455932, lng: 17.7935492 },
    { lat: 48.4457448, lng: 17.7916613 },
    { lat: 48.4470574, lng: 17.7900183 },
    { lat: 48.4470304, lng: 17.789983 },
    { lat: 48.4470107, lng: 17.7899555 },
    { lat: 48.4468216, lng: 17.7897065 },
    { lat: 48.4436248, lng: 17.7887411 },
    { lat: 48.4424207, lng: 17.7885456 },
    { lat: 48.4423828, lng: 17.7885366 },
    { lat: 48.4403662, lng: 17.7879977 },
    { lat: 48.4342143, lng: 17.7870433 },
    { lat: 48.4358536, lng: 17.7831311 },
    { lat: 48.4360985, lng: 17.7821738 },
    { lat: 48.4365244, lng: 17.781117 },
    { lat: 48.4366487, lng: 17.7806044 },
    { lat: 48.4368602, lng: 17.7807383 },
    { lat: 48.4368854, lng: 17.7801211 },
    { lat: 48.4370292, lng: 17.7797399 },
    { lat: 48.4373396, lng: 17.7793899 },
    { lat: 48.4376167, lng: 17.7791992 },
    { lat: 48.4382771, lng: 17.7791046 },
    { lat: 48.4385778, lng: 17.7792399 },
    { lat: 48.4388973, lng: 17.7791304 },
    { lat: 48.4390881, lng: 17.7788937 },
    { lat: 48.4388058, lng: 17.7788011 },
    { lat: 48.4383789, lng: 17.7788871 },
    { lat: 48.4351979, lng: 17.7789151 },
    { lat: 48.4353837, lng: 17.7776783 },
    { lat: 48.4355432, lng: 17.7756304 },
    { lat: 48.4355576, lng: 17.7745089 },
    { lat: 48.4354952, lng: 17.7721544 },
    { lat: 48.4354188, lng: 17.7694129 },
    { lat: 48.4353281, lng: 17.7691315 },
    { lat: 48.4351355, lng: 17.768955 },
    { lat: 48.4349666, lng: 17.768944 },
    { lat: 48.4348843, lng: 17.768579 },
    { lat: 48.4351547, lng: 17.7680425 },
    { lat: 48.4352615, lng: 17.7663137 },
    { lat: 48.4346123, lng: 17.7574357 },
    { lat: 48.4367491, lng: 17.7473593 },
    { lat: 48.4367591, lng: 17.7473024 },
    { lat: 48.4366044, lng: 17.7471729 },
    { lat: 48.4365165, lng: 17.7470288 },
    { lat: 48.436359, lng: 17.7472022 },
    { lat: 48.436262, lng: 17.747187 },
    { lat: 48.4360677, lng: 17.74693 },
    { lat: 48.4361781, lng: 17.7465032 },
    { lat: 48.4361408, lng: 17.7464132 },
    { lat: 48.4360655, lng: 17.7463625 },
    { lat: 48.4358919, lng: 17.7464237 },
    { lat: 48.4352897, lng: 17.7470397 },
    { lat: 48.4352094, lng: 17.7476191 },
    { lat: 48.4348483, lng: 17.7478597 },
    { lat: 48.4347871, lng: 17.747837 },
    { lat: 48.4347303, lng: 17.7475388 },
    { lat: 48.4346042, lng: 17.7472371 },
    { lat: 48.4344685, lng: 17.7472298 },
    { lat: 48.4342933, lng: 17.747374 },
    { lat: 48.4342041, lng: 17.7473552 },
    { lat: 48.434135, lng: 17.7472433 },
    { lat: 48.4342628, lng: 17.7467739 },
    { lat: 48.4342774, lng: 17.7464775 },
    { lat: 48.4341437, lng: 17.7463052 },
    { lat: 48.4341414, lng: 17.7459575 },
    { lat: 48.4339898, lng: 17.7456797 },
    { lat: 48.4338481, lng: 17.7451598 },
    { lat: 48.4337814, lng: 17.7451023 },
    { lat: 48.4336033, lng: 17.7451446 },
    { lat: 48.433501, lng: 17.7452616 },
    { lat: 48.4332219, lng: 17.7449646 },
    { lat: 48.4331347, lng: 17.7449242 },
    { lat: 48.4329601, lng: 17.7449667 },
    { lat: 48.4327999, lng: 17.7447128 },
    { lat: 48.4326479, lng: 17.7448496 },
    { lat: 48.4324651, lng: 17.7452875 },
    { lat: 48.4323439, lng: 17.7453926 },
    { lat: 48.4320864, lng: 17.7457937 },
    { lat: 48.4318299, lng: 17.7457154 },
    { lat: 48.4316062, lng: 17.7457676 },
    { lat: 48.4315362, lng: 17.7457145 },
    { lat: 48.4314591, lng: 17.7452858 },
    { lat: 48.4312999, lng: 17.7451801 },
    { lat: 48.431099, lng: 17.7451598 },
    { lat: 48.4308606, lng: 17.7448859 },
    { lat: 48.4305927, lng: 17.7447368 },
    { lat: 48.4304698, lng: 17.7446067 },
    { lat: 48.4303944, lng: 17.7443023 },
    { lat: 48.4303147, lng: 17.7443109 },
    { lat: 48.4302085, lng: 17.7444911 },
    { lat: 48.4300153, lng: 17.7450541 },
    { lat: 48.429917, lng: 17.7451443 },
    { lat: 48.4297213, lng: 17.7451553 },
    { lat: 48.429232, lng: 17.7447494 },
    { lat: 48.4290924, lng: 17.7447913 },
    { lat: 48.4289628, lng: 17.7445929 },
    { lat: 48.4286509, lng: 17.7444957 },
    { lat: 48.4285192, lng: 17.744374 },
    { lat: 48.428366, lng: 17.7441427 },
    { lat: 48.4283805, lng: 17.7435935 },
    { lat: 48.4282085, lng: 17.7434405 },
    { lat: 48.4280021, lng: 17.7434082 },
    { lat: 48.4275317, lng: 17.7435799 },
    { lat: 48.4269434, lng: 17.7432131 },
    { lat: 48.4266922, lng: 17.7434053 },
    { lat: 48.4265621, lng: 17.743582 },
    { lat: 48.4261723, lng: 17.7429945 },
    { lat: 48.4264194, lng: 17.7422807 },
    { lat: 48.4263221, lng: 17.7421314 },
    { lat: 48.4260855, lng: 17.7419555 },
    { lat: 48.4258596, lng: 17.7414627 },
    { lat: 48.4257878, lng: 17.7414884 },
    { lat: 48.4254778, lng: 17.7419725 },
    { lat: 48.4253297, lng: 17.7420746 },
    { lat: 48.4251635, lng: 17.7420541 },
    { lat: 48.4249153, lng: 17.7418737 },
    { lat: 48.4246803, lng: 17.7414181 },
    { lat: 48.4244438, lng: 17.7411903 },
    { lat: 48.4243165, lng: 17.740865 },
    { lat: 48.4241605, lng: 17.7407869 },
    { lat: 48.4241354, lng: 17.7404734 },
    { lat: 48.4240632, lng: 17.7403129 },
    { lat: 48.4238822, lng: 17.7401245 },
    { lat: 48.4238698, lng: 17.7400313 },
    { lat: 48.4239962, lng: 17.7397958 },
    { lat: 48.424014, lng: 17.7396621 },
    { lat: 48.4239686, lng: 17.7395295 },
    { lat: 48.4237024, lng: 17.7392998 },
    { lat: 48.4232198, lng: 17.7392005 },
    { lat: 48.4227566, lng: 17.7390104 },
    { lat: 48.4225663, lng: 17.7388031 },
    { lat: 48.4221714, lng: 17.7387669 },
    { lat: 48.4221566, lng: 17.7386019 },
    { lat: 48.4223776, lng: 17.7383066 },
    { lat: 48.4227643, lng: 17.738061 },
    { lat: 48.4229697, lng: 17.7376569 },
    { lat: 48.4231639, lng: 17.7378398 },
    { lat: 48.4232876, lng: 17.7378495 },
    { lat: 48.423339, lng: 17.7377865 },
    { lat: 48.4234552, lng: 17.7373473 },
    { lat: 48.4234364, lng: 17.7368253 },
    { lat: 48.4235459, lng: 17.7364975 },
    { lat: 48.4235666, lng: 17.736082 },
    { lat: 48.4234565, lng: 17.7358564 },
    { lat: 48.4231547, lng: 17.7357418 },
    { lat: 48.4228045, lng: 17.7359561 },
    { lat: 48.4226389, lng: 17.7356543 },
    { lat: 48.4226221, lng: 17.7354701 },
    { lat: 48.4226875, lng: 17.7353813 },
    { lat: 48.4228416, lng: 17.7353519 },
    { lat: 48.4228846, lng: 17.7352436 },
    { lat: 48.4228458, lng: 17.735148 },
    { lat: 48.4226181, lng: 17.734989 },
    { lat: 48.4221547, lng: 17.7352361 },
    { lat: 48.421789, lng: 17.7352755 },
    { lat: 48.4216588, lng: 17.7351207 },
    { lat: 48.4215885, lng: 17.734898 },
    { lat: 48.4215381, lng: 17.7343225 },
    { lat: 48.4215775, lng: 17.734194 },
    { lat: 48.4216907, lng: 17.7341238 },
    { lat: 48.4217155, lng: 17.7340315 },
    { lat: 48.4216368, lng: 17.7336547 },
    { lat: 48.4217094, lng: 17.7334242 },
    { lat: 48.4216751, lng: 17.7333067 },
    { lat: 48.4215802, lng: 17.733214 },
    { lat: 48.4212124, lng: 17.7332001 },
    { lat: 48.4210054, lng: 17.7333613 },
    { lat: 48.4207997, lng: 17.7332834 },
    { lat: 48.4203834, lng: 17.7321772 },
    { lat: 48.4200518, lng: 17.7321301 },
    { lat: 48.4199941, lng: 17.7320728 },
    { lat: 48.4200025, lng: 17.7319337 },
    { lat: 48.420193, lng: 17.7313999 },
    { lat: 48.4199239, lng: 17.7313495 },
    { lat: 48.4198217, lng: 17.7313303 },
    { lat: 48.4194826, lng: 17.7317264 },
    { lat: 48.4193553, lng: 17.7316568 },
    { lat: 48.4192867, lng: 17.7314588 },
    { lat: 48.41936, lng: 17.7310576 },
    { lat: 48.4194939, lng: 17.7306681 },
    { lat: 48.419363, lng: 17.730542 },
    { lat: 48.419297, lng: 17.730573 },
    { lat: 48.418707, lng: 17.731184 },
    { lat: 48.418547, lng: 17.731254 },
    { lat: 48.418385, lng: 17.731102 },
    { lat: 48.418236, lng: 17.730715 },
    { lat: 48.418148, lng: 17.730406 },
    { lat: 48.417924, lng: 17.730283 },
    { lat: 48.417771, lng: 17.730121 },
    { lat: 48.417622, lng: 17.729907 },
    { lat: 48.417513, lng: 17.729748 },
    { lat: 48.417464, lng: 17.729705 },
    { lat: 48.417246, lng: 17.729655 },
    { lat: 48.417147, lng: 17.729584 },
    { lat: 48.417042, lng: 17.729348 },
    { lat: 48.417028, lng: 17.729174 },
    { lat: 48.416951, lng: 17.729048 },
    { lat: 48.416849, lng: 17.72894 },
    { lat: 48.416765, lng: 17.728686 },
    { lat: 48.416747, lng: 17.728609 },
    { lat: 48.416685, lng: 17.72836 },
    { lat: 48.41662, lng: 17.728241 },
    { lat: 48.416424, lng: 17.728048 },
    { lat: 48.416357, lng: 17.727769 },
    { lat: 48.4163, lng: 17.727477 },
    { lat: 48.4162633, lng: 17.7274134 },
    { lat: 48.416237, lng: 17.727368 },
    { lat: 48.415909, lng: 17.727173 },
    { lat: 48.415838, lng: 17.727057 },
    { lat: 48.415809, lng: 17.726937 },
    { lat: 48.415759, lng: 17.726785 },
    { lat: 48.41573, lng: 17.726607 },
    { lat: 48.415628, lng: 17.726439 },
    { lat: 48.415499, lng: 17.726301 },
    { lat: 48.415339, lng: 17.726212 },
    { lat: 48.415251, lng: 17.726151 },
    { lat: 48.415185, lng: 17.726169 },
    { lat: 48.415116, lng: 17.726157 },
    { lat: 48.415086, lng: 17.726152 },
    { lat: 48.415056, lng: 17.72613 },
    { lat: 48.414975, lng: 17.726151 },
    { lat: 48.41486, lng: 17.726045 },
    { lat: 48.414848, lng: 17.725933 },
    { lat: 48.414835, lng: 17.725822 },
    { lat: 48.4148, lng: 17.72577 },
    { lat: 48.414747, lng: 17.725691 },
    { lat: 48.414703, lng: 17.725691 },
    { lat: 48.414625, lng: 17.725692 },
    { lat: 48.414508, lng: 17.725672 },
    { lat: 48.414348, lng: 17.725559 },
    { lat: 48.414238, lng: 17.725459 },
    { lat: 48.414192, lng: 17.725414 },
    { lat: 48.414102, lng: 17.725272 },
    { lat: 48.41401, lng: 17.725195 },
    { lat: 48.413934, lng: 17.725085 },
    { lat: 48.413884, lng: 17.724815 },
    { lat: 48.413766, lng: 17.724601 },
    { lat: 48.413649, lng: 17.72434 },
    { lat: 48.413624, lng: 17.724105 },
    { lat: 48.413621, lng: 17.724074 },
    { lat: 48.413609, lng: 17.724071 },
    { lat: 48.413519, lng: 17.72405 },
    { lat: 48.41347, lng: 17.723894 },
    { lat: 48.413444, lng: 17.723779 },
    { lat: 48.41344, lng: 17.723747 },
    { lat: 48.413423, lng: 17.72361 },
    { lat: 48.413404, lng: 17.723445 },
    { lat: 48.413306, lng: 17.723333 },
    { lat: 48.413176, lng: 17.723317 },
    { lat: 48.413127, lng: 17.723337 },
    { lat: 48.412999, lng: 17.723389 },
    { lat: 48.412941, lng: 17.723418 },
    { lat: 48.412863, lng: 17.723447 },
    { lat: 48.412816, lng: 17.723487 },
    { lat: 48.41278, lng: 17.723535 },
    { lat: 48.412741, lng: 17.723709 },
    { lat: 48.41274, lng: 17.723862 },
    { lat: 48.412737, lng: 17.723967 },
    { lat: 48.412646, lng: 17.724083 },
    { lat: 48.412445, lng: 17.724224 },
    { lat: 48.412241, lng: 17.724274 },
    { lat: 48.412163, lng: 17.724106 },
    { lat: 48.4121, lng: 17.723978 },
    { lat: 48.412033, lng: 17.72384 },
    { lat: 48.41198, lng: 17.723764 },
    { lat: 48.41194, lng: 17.72371 },
    { lat: 48.411914, lng: 17.723676 },
    { lat: 48.411788, lng: 17.723588 },
    { lat: 48.411708, lng: 17.723665 },
    { lat: 48.411608, lng: 17.724182 },
    { lat: 48.411576, lng: 17.724352 },
    { lat: 48.411521, lng: 17.724631 },
    { lat: 48.411407, lng: 17.724672 },
    { lat: 48.411218, lng: 17.724494 },
    { lat: 48.411014, lng: 17.724293 },
    { lat: 48.410865, lng: 17.724154 },
    { lat: 48.410769, lng: 17.724064 },
    { lat: 48.410735, lng: 17.72402 },
    { lat: 48.410589, lng: 17.723861 },
    { lat: 48.41048, lng: 17.723574 },
    { lat: 48.410398, lng: 17.723576 },
    { lat: 48.410315, lng: 17.723578 },
    { lat: 48.4101935, lng: 17.723332 },
    { lat: 48.410072, lng: 17.723086 },
    { lat: 48.40999, lng: 17.722911 },
    { lat: 48.409877, lng: 17.722526 },
    { lat: 48.409693, lng: 17.722417 },
    { lat: 48.409673, lng: 17.722234 },
    { lat: 48.409738, lng: 17.722113 },
    { lat: 48.409833, lng: 17.722001 },
    { lat: 48.409918, lng: 17.721874 },
    { lat: 48.409936, lng: 17.721848 },
    { lat: 48.40993, lng: 17.721561 },
    { lat: 48.409926, lng: 17.721445 },
    { lat: 48.40992, lng: 17.721232 },
    { lat: 48.409889, lng: 17.720916 },
    { lat: 48.409838, lng: 17.720799 },
    { lat: 48.409802, lng: 17.720717 },
    { lat: 48.409726, lng: 17.720617 },
    { lat: 48.40966, lng: 17.72053 },
    { lat: 48.409618, lng: 17.720474 },
    { lat: 48.409487, lng: 17.720483 },
    { lat: 48.409379, lng: 17.720597 },
    { lat: 48.409252, lng: 17.720732 },
    { lat: 48.408945, lng: 17.721056 },
    { lat: 48.408878, lng: 17.721077 },
    { lat: 48.408746, lng: 17.721059 },
    { lat: 48.408697, lng: 17.720867 },
    { lat: 48.408673, lng: 17.720768 },
    { lat: 48.408642, lng: 17.720645 },
    { lat: 48.408636, lng: 17.720622 },
    { lat: 48.408587, lng: 17.720573 },
    { lat: 48.408536, lng: 17.720587 },
    { lat: 48.408456, lng: 17.720668 },
    { lat: 48.408285, lng: 17.720842 },
    { lat: 48.408203, lng: 17.720868 },
    { lat: 48.408175, lng: 17.720877 },
    { lat: 48.408015, lng: 17.720828 },
    { lat: 48.40797, lng: 17.720814 },
    { lat: 48.407801, lng: 17.720565 },
    { lat: 48.407584, lng: 17.720708 },
    { lat: 48.407551, lng: 17.720696 },
    { lat: 48.407503, lng: 17.720458 },
    { lat: 48.407527, lng: 17.720167 },
    { lat: 48.407399, lng: 17.719894 },
    { lat: 48.407217, lng: 17.719791 },
    { lat: 48.407125, lng: 17.71978 },
    { lat: 48.407067, lng: 17.719772 },
    { lat: 48.40699, lng: 17.719747 },
    { lat: 48.406882, lng: 17.719615 },
    { lat: 48.406864, lng: 17.719593 },
    { lat: 48.406392, lng: 17.719262 },
    { lat: 48.406363, lng: 17.719241 },
    { lat: 48.406228, lng: 17.719126 },
    { lat: 48.405871, lng: 17.719527 },
    { lat: 48.405769, lng: 17.719592 },
    { lat: 48.405747, lng: 17.719606 },
    { lat: 48.405715, lng: 17.719626 },
    { lat: 48.40569, lng: 17.719642 },
    { lat: 48.405626, lng: 17.719683 },
    { lat: 48.405572, lng: 17.719716 },
    { lat: 48.404863, lng: 17.720163 },
    { lat: 48.403473, lng: 17.721152 },
    { lat: 48.403285, lng: 17.721275 },
    { lat: 48.402432, lng: 17.721838 },
    { lat: 48.402371, lng: 17.721689 },
    { lat: 48.402366, lng: 17.721677 },
    { lat: 48.402357, lng: 17.721653 },
    { lat: 48.40234, lng: 17.721625 },
    { lat: 48.402228, lng: 17.721427 },
    { lat: 48.401968, lng: 17.721079 },
    { lat: 48.401897, lng: 17.721121 },
    { lat: 48.401642, lng: 17.721357 },
    { lat: 48.40141, lng: 17.721253 },
    { lat: 48.401047, lng: 17.721491 },
    { lat: 48.400907, lng: 17.721862 },
    { lat: 48.400877, lng: 17.722077 },
    { lat: 48.400836, lng: 17.722149 },
    { lat: 48.400525, lng: 17.722127 },
    { lat: 48.400471, lng: 17.722159 },
    { lat: 48.400409, lng: 17.722342 },
    { lat: 48.40032, lng: 17.722705 },
    { lat: 48.400308, lng: 17.722778 },
    { lat: 48.400224, lng: 17.722892 },
    { lat: 48.399988, lng: 17.722865 },
    { lat: 48.399784, lng: 17.722872 },
    { lat: 48.399595, lng: 17.722971 },
    { lat: 48.399402, lng: 17.722979 },
    { lat: 48.399194, lng: 17.723084 },
    { lat: 48.39902, lng: 17.723083 },
    { lat: 48.398902, lng: 17.723197 },
    { lat: 48.398558, lng: 17.723226 },
    { lat: 48.398441, lng: 17.723059 },
    { lat: 48.398271, lng: 17.722902 },
    { lat: 48.398137, lng: 17.723017 },
    { lat: 48.398055, lng: 17.723167 },
    { lat: 48.397972, lng: 17.723183 },
    { lat: 48.397781, lng: 17.723155 },
    { lat: 48.39756, lng: 17.72307 },
    { lat: 48.397516, lng: 17.723025 },
    { lat: 48.397472, lng: 17.722987 },
    { lat: 48.395588, lng: 17.722003 },
    { lat: 48.395564, lng: 17.72199 },
    { lat: 48.393494, lng: 17.720909 },
    { lat: 48.393431, lng: 17.720876 },
    { lat: 48.393469, lng: 17.720753 },
    { lat: 48.393399, lng: 17.720567 },
    { lat: 48.393326, lng: 17.720391 },
    { lat: 48.393048, lng: 17.719836 },
    { lat: 48.392817, lng: 17.719735 },
    { lat: 48.392707, lng: 17.719869 },
    { lat: 48.392599, lng: 17.720169 },
    { lat: 48.392548, lng: 17.720367 },
    { lat: 48.392398, lng: 17.72085 },
    { lat: 48.392295, lng: 17.720843 },
    { lat: 48.39225, lng: 17.720839 },
    { lat: 48.391977, lng: 17.720699 },
    { lat: 48.391782, lng: 17.720716 },
    { lat: 48.391603, lng: 17.720846 },
    { lat: 48.391502, lng: 17.720972 },
    { lat: 48.39144, lng: 17.721297 },
    { lat: 48.391125, lng: 17.721355 },
    { lat: 48.390951, lng: 17.72122 },
    { lat: 48.390799, lng: 17.720979 },
    { lat: 48.390721, lng: 17.720812 },
    { lat: 48.390562, lng: 17.720222 },
    { lat: 48.390412, lng: 17.71978 },
    { lat: 48.390398, lng: 17.719689 },
    { lat: 48.390312, lng: 17.719548 },
    { lat: 48.390222, lng: 17.719492 },
    { lat: 48.390147, lng: 17.719499 },
    { lat: 48.390052, lng: 17.719548 },
    { lat: 48.389947, lng: 17.719428 },
    { lat: 48.389917, lng: 17.719389 },
    { lat: 48.389819, lng: 17.719122 },
    { lat: 48.389651, lng: 17.719144 },
    { lat: 48.389467, lng: 17.719687 },
    { lat: 48.389455, lng: 17.719816 },
    { lat: 48.389283, lng: 17.719787 },
    { lat: 48.389183, lng: 17.719644 },
    { lat: 48.389177, lng: 17.719344 },
    { lat: 48.388813, lng: 17.719147 },
    { lat: 48.388679, lng: 17.719045 },
    { lat: 48.388609, lng: 17.7189 },
    { lat: 48.388614, lng: 17.718844 },
    { lat: 48.388464, lng: 17.718808 },
    { lat: 48.388235, lng: 17.718866 },
    { lat: 48.38808, lng: 17.718909 },
    { lat: 48.387921, lng: 17.719058 },
    { lat: 48.387785, lng: 17.718669 },
    { lat: 48.38745, lng: 17.718425 },
    { lat: 48.387045, lng: 17.718223 },
    { lat: 48.386835, lng: 17.718173 },
    { lat: 48.386689, lng: 17.718038 },
    { lat: 48.386175, lng: 17.718222 },
    { lat: 48.385972, lng: 17.719077 },
    { lat: 48.386081, lng: 17.719539 },
    { lat: 48.38601, lng: 17.719817 },
    { lat: 48.385843, lng: 17.719966 },
    { lat: 48.385823, lng: 17.720107 },
    { lat: 48.385644, lng: 17.720188 },
    { lat: 48.38546, lng: 17.719946 },
    { lat: 48.385332, lng: 17.719613 },
    { lat: 48.385186, lng: 17.719337 },
    { lat: 48.385201, lng: 17.719201 },
    { lat: 48.385356, lng: 17.718919 },
    { lat: 48.385199, lng: 17.718488 },
    { lat: 48.384732, lng: 17.71827 },
    { lat: 48.384455, lng: 17.71852 },
    { lat: 48.38437, lng: 17.718718 },
    { lat: 48.384296, lng: 17.718755 },
    { lat: 48.384147, lng: 17.718662 },
    { lat: 48.383985, lng: 17.718912 },
    { lat: 48.383791, lng: 17.719052 },
    { lat: 48.383411, lng: 17.718921 },
    { lat: 48.383165, lng: 17.719032 },
    { lat: 48.382865, lng: 17.719118 },
    { lat: 48.382529, lng: 17.71912 },
    { lat: 48.382135, lng: 17.719112 },
    { lat: 48.381851, lng: 17.718981 },
    { lat: 48.381797, lng: 17.719259 },
    { lat: 48.381695, lng: 17.719374 },
    { lat: 48.381607, lng: 17.71964 },
    { lat: 48.381513, lng: 17.719786 },
    { lat: 48.381311, lng: 17.719942 },
    { lat: 48.380997, lng: 17.720019 },
    { lat: 48.380815, lng: 17.719999 },
    { lat: 48.38037, lng: 17.720151 },
    { lat: 48.380172, lng: 17.720246 },
    { lat: 48.380044, lng: 17.720376 },
    { lat: 48.379894, lng: 17.720616 },
    { lat: 48.379754, lng: 17.72071 },
    { lat: 48.379349, lng: 17.721056 },
    { lat: 48.379146, lng: 17.721109 },
    { lat: 48.379122, lng: 17.721263 },
    { lat: 48.378934, lng: 17.721642 },
    { lat: 48.378859, lng: 17.72176 },
    { lat: 48.378494, lng: 17.722474 },
    { lat: 48.378333, lng: 17.722756 },
    { lat: 48.378191, lng: 17.723033 },
    { lat: 48.378042, lng: 17.723361 },
    { lat: 48.378026, lng: 17.723531 },
    { lat: 48.377994, lng: 17.723843 },
    { lat: 48.378017, lng: 17.72395 },
    { lat: 48.377587, lng: 17.724234 },
    { lat: 48.37753, lng: 17.724312 },
    { lat: 48.377447, lng: 17.72435 },
    { lat: 48.377396, lng: 17.724372 },
    { lat: 48.377366, lng: 17.724385 },
    { lat: 48.377232, lng: 17.724444 },
    { lat: 48.377219, lng: 17.724358 },
    { lat: 48.377208, lng: 17.724319 },
    { lat: 48.377041, lng: 17.724216 },
    { lat: 48.376855, lng: 17.724138 },
    { lat: 48.37686, lng: 17.724107 },
    { lat: 48.376871, lng: 17.724044 },
    { lat: 48.376875, lng: 17.724019 },
    { lat: 48.376906, lng: 17.723902 },
    { lat: 48.376964, lng: 17.723685 },
    { lat: 48.37667, lng: 17.72362 },
    { lat: 48.376578, lng: 17.72345 },
    { lat: 48.376391, lng: 17.723366 },
    { lat: 48.376323, lng: 17.723235 },
    { lat: 48.376287, lng: 17.723072 },
    { lat: 48.376306, lng: 17.722903 },
    { lat: 48.37625, lng: 17.722711 },
    { lat: 48.376136, lng: 17.722712 },
    { lat: 48.375945, lng: 17.722828 },
    { lat: 48.375718, lng: 17.722608 },
    { lat: 48.375404, lng: 17.722687 },
    { lat: 48.37533, lng: 17.723024 },
    { lat: 48.375277, lng: 17.723119 },
    { lat: 48.375263, lng: 17.723147 },
    { lat: 48.37516, lng: 17.723161 },
    { lat: 48.375092, lng: 17.723131 },
    { lat: 48.375089, lng: 17.723126 },
    { lat: 48.374998, lng: 17.722995 },
    { lat: 48.374901, lng: 17.722998 },
    { lat: 48.374805, lng: 17.723 },
    { lat: 48.37475, lng: 17.723176 },
    { lat: 48.374364, lng: 17.72288 },
    { lat: 48.374399, lng: 17.722754 },
    { lat: 48.374353, lng: 17.722563 },
    { lat: 48.374347, lng: 17.722507 },
    { lat: 48.374343, lng: 17.722456 },
    { lat: 48.374334, lng: 17.722377 },
    { lat: 48.374332, lng: 17.722323 },
    { lat: 48.374321, lng: 17.721995 },
    { lat: 48.374306, lng: 17.721918 },
    { lat: 48.374279, lng: 17.721781 },
    { lat: 48.374352, lng: 17.72155 },
    { lat: 48.37421, lng: 17.721379 },
    { lat: 48.374111, lng: 17.721096 },
    { lat: 48.374044, lng: 17.721028 },
    { lat: 48.373804, lng: 17.721128 },
    { lat: 48.37349, lng: 17.721148 },
    { lat: 48.373329, lng: 17.721369 },
    { lat: 48.373198, lng: 17.721377 },
    { lat: 48.372983, lng: 17.721548 },
    { lat: 48.372961, lng: 17.72163 },
    { lat: 48.372926, lng: 17.721612 },
    { lat: 48.3728527, lng: 17.7218435 },
    { lat: 48.371238, lng: 17.7238056 },
    { lat: 48.3701936, lng: 17.7254236 },
    { lat: 48.3697609, lng: 17.7259816 },
    { lat: 48.369408, lng: 17.7270262 },
    { lat: 48.3693332, lng: 17.7274035 },
    { lat: 48.3691554, lng: 17.7277216 },
    { lat: 48.3689594, lng: 17.7279294 },
    { lat: 48.36892, lng: 17.7288567 },
    { lat: 48.369025, lng: 17.7293867 },
    { lat: 48.3691416, lng: 17.7295536 },
    { lat: 48.3691424, lng: 17.7297499 },
    { lat: 48.368984, lng: 17.7302635 },
    { lat: 48.3689045, lng: 17.7305213 },
    { lat: 48.3684745, lng: 17.7314311 },
    { lat: 48.3684334, lng: 17.7318054 },
    { lat: 48.3684947, lng: 17.7319932 },
    { lat: 48.368716, lng: 17.7322116 },
    { lat: 48.3694308, lng: 17.73227 },
    { lat: 48.3698643, lng: 17.731996 },
    { lat: 48.3699406, lng: 17.7318607 },
    { lat: 48.370033, lng: 17.7314015 },
    { lat: 48.3701414, lng: 17.7313302 },
    { lat: 48.3706639, lng: 17.7311446 },
    { lat: 48.371024, lng: 17.7308511 },
    { lat: 48.3711428, lng: 17.7308217 },
    { lat: 48.371235, lng: 17.7309036 },
    { lat: 48.3715068, lng: 17.730937 },
    { lat: 48.3717426, lng: 17.7307346 },
    { lat: 48.3718901, lng: 17.7307098 },
    { lat: 48.3720755, lng: 17.730948 },
    { lat: 48.3725013, lng: 17.7309514 },
    { lat: 48.3734161, lng: 17.7315209 },
    { lat: 48.3741078, lng: 17.732241 },
    { lat: 48.374167, lng: 17.7323215 },
    { lat: 48.3741976, lng: 17.7326173 },
    { lat: 48.3743864, lng: 17.7328714 },
    { lat: 48.3745557, lng: 17.7330001 },
    { lat: 48.3746791, lng: 17.7332048 },
    { lat: 48.3749629, lng: 17.7338509 },
    { lat: 48.3749819, lng: 17.734138 },
    { lat: 48.3748951, lng: 17.734322 },
    { lat: 48.3745471, lng: 17.7344825 },
    { lat: 48.3744245, lng: 17.7346726 },
    { lat: 48.374633, lng: 17.7352645 },
    { lat: 48.3746632, lng: 17.7359897 },
    { lat: 48.3736564, lng: 17.7379062 },
  ],
  Sasinkovo: [
    { lat: 48.3840363, lng: 17.8290725 },
    { lat: 48.3838746, lng: 17.8287532 },
    { lat: 48.3833693, lng: 17.8281886 },
    { lat: 48.3827192, lng: 17.82784 },
    { lat: 48.3819354, lng: 17.8275226 },
    { lat: 48.3816639, lng: 17.8273303 },
    { lat: 48.3809196, lng: 17.8265682 },
    { lat: 48.3792665, lng: 17.8258058 },
    { lat: 48.3788311, lng: 17.8251862 },
    { lat: 48.3774183, lng: 17.8236283 },
    { lat: 48.3772251, lng: 17.8235126 },
    { lat: 48.3765123, lng: 17.823324 },
    { lat: 48.3750162, lng: 17.8225423 },
    { lat: 48.3736032, lng: 17.8221627 },
    { lat: 48.372879, lng: 17.8218756 },
    { lat: 48.3720521, lng: 17.8218324 },
    { lat: 48.371864, lng: 17.8218204 },
    { lat: 48.3708489, lng: 17.8218905 },
    { lat: 48.3703132, lng: 17.8218256 },
    { lat: 48.3693214, lng: 17.8214647 },
    { lat: 48.369016, lng: 17.8222105 },
    { lat: 48.3688135, lng: 17.8237175 },
    { lat: 48.368829, lng: 17.8242093 },
    { lat: 48.3685395, lng: 17.8249028 },
    { lat: 48.3682365, lng: 17.8252819 },
    { lat: 48.3677481, lng: 17.8248082 },
    { lat: 48.3673876, lng: 17.8247666 },
    { lat: 48.3668767, lng: 17.8245556 },
    { lat: 48.3662785, lng: 17.8247023 },
    { lat: 48.3657959, lng: 17.8251161 },
    { lat: 48.3644465, lng: 17.8251847 },
    { lat: 48.3630142, lng: 17.8256353 },
    { lat: 48.3625192, lng: 17.8268622 },
    { lat: 48.3615073, lng: 17.8286818 },
    { lat: 48.3614429, lng: 17.828974 },
    { lat: 48.3608076, lng: 17.828447 },
    { lat: 48.3601625, lng: 17.8304489 },
    { lat: 48.3595643, lng: 17.8317772 },
    { lat: 48.3593244, lng: 17.8320623 },
    { lat: 48.3589878, lng: 17.8314712 },
    { lat: 48.3581405, lng: 17.832307 },
    { lat: 48.3571865, lng: 17.8302793 },
    { lat: 48.3567201, lng: 17.8274166 },
    { lat: 48.3561974, lng: 17.8262002 },
    { lat: 48.355153, lng: 17.822765 },
    { lat: 48.355046, lng: 17.82323 },
    { lat: 48.35491, lng: 17.823831 },
    { lat: 48.354786, lng: 17.824387 },
    { lat: 48.354582, lng: 17.825281 },
    { lat: 48.354416, lng: 17.826022 },
    { lat: 48.354228, lng: 17.826691 },
    { lat: 48.354044, lng: 17.827359 },
    { lat: 48.353874, lng: 17.827828 },
    { lat: 48.353725, lng: 17.828232 },
    { lat: 48.353649, lng: 17.828443 },
    { lat: 48.353519, lng: 17.828805 },
    { lat: 48.353252, lng: 17.82952 },
    { lat: 48.353159, lng: 17.829762 },
    { lat: 48.352807, lng: 17.830298 },
    { lat: 48.352353, lng: 17.830959 },
    { lat: 48.351847, lng: 17.831702 },
    { lat: 48.35142, lng: 17.832028 },
    { lat: 48.350934, lng: 17.832397 },
    { lat: 48.350579, lng: 17.832774 },
    { lat: 48.350109, lng: 17.833253 },
    { lat: 48.349785, lng: 17.833738 },
    { lat: 48.349485, lng: 17.834174 },
    { lat: 48.349145, lng: 17.834645 },
    { lat: 48.348748, lng: 17.835202 },
    { lat: 48.347953, lng: 17.83632 },
    { lat: 48.347713, lng: 17.836648 },
    { lat: 48.347503, lng: 17.837214 },
    { lat: 48.347251, lng: 17.8379 },
    { lat: 48.347002, lng: 17.838572 },
    { lat: 48.347279, lng: 17.839371 },
    { lat: 48.34759, lng: 17.840243 },
    { lat: 48.347716, lng: 17.840593 },
    { lat: 48.347231, lng: 17.84081 },
    { lat: 48.346678, lng: 17.841054 },
    { lat: 48.346061, lng: 17.841305 },
    { lat: 48.345943, lng: 17.841645 },
    { lat: 48.345833, lng: 17.841962 },
    { lat: 48.345298, lng: 17.842122 },
    { lat: 48.344216, lng: 17.842434 },
    { lat: 48.343688, lng: 17.842588 },
    { lat: 48.342968, lng: 17.842804 },
    { lat: 48.342565, lng: 17.842913 },
    { lat: 48.342027, lng: 17.843058 },
    { lat: 48.341315, lng: 17.84326 },
    { lat: 48.340858, lng: 17.843405 },
    { lat: 48.340493, lng: 17.843525 },
    { lat: 48.340445, lng: 17.843566 },
    { lat: 48.340538, lng: 17.844201 },
    { lat: 48.340659, lng: 17.845052 },
    { lat: 48.340712, lng: 17.845299 },
    { lat: 48.340767, lng: 17.845666 },
    { lat: 48.340844, lng: 17.846126 },
    { lat: 48.340866, lng: 17.846236 },
    { lat: 48.340981, lng: 17.846642 },
    { lat: 48.341103, lng: 17.847092 },
    { lat: 48.341236, lng: 17.847576 },
    { lat: 48.341356, lng: 17.848014 },
    { lat: 48.341475, lng: 17.848449 },
    { lat: 48.341549, lng: 17.848656 },
    { lat: 48.341842, lng: 17.848591 },
    { lat: 48.342107, lng: 17.848535 },
    { lat: 48.342427, lng: 17.848484 },
    { lat: 48.342748, lng: 17.848457 },
    { lat: 48.343055, lng: 17.848464 },
    { lat: 48.343325, lng: 17.848486 },
    { lat: 48.343996, lng: 17.848536 },
    { lat: 48.344209, lng: 17.848559 },
    { lat: 48.344296, lng: 17.848643 },
    { lat: 48.344527, lng: 17.84906 },
    { lat: 48.344631, lng: 17.849198 },
    { lat: 48.344763, lng: 17.849328 },
    { lat: 48.344947, lng: 17.849479 },
    { lat: 48.34503, lng: 17.849583 },
    { lat: 48.345075, lng: 17.849757 },
    { lat: 48.345128, lng: 17.850107 },
    { lat: 48.345127, lng: 17.850211 },
    { lat: 48.345103, lng: 17.850277 },
    { lat: 48.344841, lng: 17.850688 },
    { lat: 48.344833, lng: 17.850792 },
    { lat: 48.344917, lng: 17.85112 },
    { lat: 48.34506, lng: 17.851511 },
    { lat: 48.34521, lng: 17.8518 },
    { lat: 48.345462, lng: 17.852083 },
    { lat: 48.345489, lng: 17.852145 },
    { lat: 48.345478, lng: 17.852328 },
    { lat: 48.345422, lng: 17.852564 },
    { lat: 48.345413, lng: 17.852683 },
    { lat: 48.345422, lng: 17.852812 },
    { lat: 48.345462, lng: 17.852898 },
    { lat: 48.345508, lng: 17.852986 },
    { lat: 48.345684, lng: 17.853296 },
    { lat: 48.345919, lng: 17.853707 },
    { lat: 48.346104, lng: 17.85401 },
    { lat: 48.346177, lng: 17.854278 },
    { lat: 48.34619, lng: 17.854514 },
    { lat: 48.34622, lng: 17.854765 },
    { lat: 48.346282, lng: 17.855279 },
    { lat: 48.346349, lng: 17.855737 },
    { lat: 48.34638, lng: 17.855832 },
    { lat: 48.346468, lng: 17.855947 },
    { lat: 48.346759, lng: 17.856317 },
    { lat: 48.34708, lng: 17.856835 },
    { lat: 48.347233, lng: 17.857073 },
    { lat: 48.347262, lng: 17.857096 },
    { lat: 48.347488, lng: 17.857421 },
    { lat: 48.347727, lng: 17.857743 },
    { lat: 48.348004, lng: 17.858063 },
    { lat: 48.348204, lng: 17.858337 },
    { lat: 48.348588, lng: 17.857751 },
    { lat: 48.34893, lng: 17.857233 },
    { lat: 48.349008, lng: 17.857086 },
    { lat: 48.349297, lng: 17.856563 },
    { lat: 48.349633, lng: 17.855864 },
    { lat: 48.349785, lng: 17.855621 },
    { lat: 48.349932, lng: 17.855454 },
    { lat: 48.350189, lng: 17.855307 },
    { lat: 48.350326, lng: 17.855302 },
    { lat: 48.35043, lng: 17.855323 },
    { lat: 48.35058, lng: 17.855516 },
    { lat: 48.35076, lng: 17.855807 },
    { lat: 48.350987, lng: 17.856339 },
    { lat: 48.351315, lng: 17.856901 },
    { lat: 48.351482, lng: 17.857265 },
    { lat: 48.351774, lng: 17.857441 },
    { lat: 48.351972, lng: 17.857623 },
    { lat: 48.352382, lng: 17.858233 },
    { lat: 48.352628, lng: 17.858644 },
    { lat: 48.352692, lng: 17.858826 },
    { lat: 48.352706, lng: 17.859334 },
    { lat: 48.352996, lng: 17.859609 },
    { lat: 48.353289, lng: 17.859895 },
    { lat: 48.35326, lng: 17.859935 },
    { lat: 48.353397, lng: 17.860096 },
    { lat: 48.353726, lng: 17.860529 },
    { lat: 48.353991, lng: 17.860875 },
    { lat: 48.354163, lng: 17.861082 },
    { lat: 48.354339, lng: 17.861355 },
    { lat: 48.354325, lng: 17.861367 },
    { lat: 48.354599, lng: 17.861713 },
    { lat: 48.354799, lng: 17.86194 },
    { lat: 48.354805, lng: 17.861968 },
    { lat: 48.354823, lng: 17.861966 },
    { lat: 48.354986, lng: 17.862356 },
    { lat: 48.35517, lng: 17.862776 },
    { lat: 48.355346, lng: 17.863166 },
    { lat: 48.355493, lng: 17.863517 },
    { lat: 48.355629, lng: 17.863893 },
    { lat: 48.355805, lng: 17.864365 },
    { lat: 48.356035, lng: 17.864982 },
    { lat: 48.356062, lng: 17.86511 },
    { lat: 48.3563, lng: 17.865998 },
    { lat: 48.35641, lng: 17.866403 },
    { lat: 48.356549, lng: 17.866902 },
    { lat: 48.35659, lng: 17.867213 },
    { lat: 48.356651, lng: 17.867685 },
    { lat: 48.35673, lng: 17.868002 },
    { lat: 48.356806, lng: 17.868233 },
    { lat: 48.356927, lng: 17.868708 },
    { lat: 48.357025, lng: 17.869158 },
    { lat: 48.35711, lng: 17.869544 },
    { lat: 48.357149, lng: 17.869723 },
    { lat: 48.357183, lng: 17.869927 },
    { lat: 48.357305, lng: 17.870257 },
    { lat: 48.357386, lng: 17.870393 },
    { lat: 48.357472, lng: 17.870508 },
    { lat: 48.357498, lng: 17.870484 },
    { lat: 48.35773, lng: 17.870784 },
    { lat: 48.357886, lng: 17.870924 },
    { lat: 48.358073, lng: 17.871004 },
    { lat: 48.358261, lng: 17.870997 },
    { lat: 48.358566, lng: 17.870922 },
    { lat: 48.358855, lng: 17.870838 },
    { lat: 48.359089, lng: 17.87083 },
    { lat: 48.359545, lng: 17.87077 },
    { lat: 48.35978, lng: 17.870743 },
    { lat: 48.360112, lng: 17.870726 },
    { lat: 48.360415, lng: 17.870604 },
    { lat: 48.360841, lng: 17.870436 },
    { lat: 48.361183, lng: 17.870329 },
    { lat: 48.361446, lng: 17.870156 },
    { lat: 48.36184, lng: 17.869892 },
    { lat: 48.362279, lng: 17.869598 },
    { lat: 48.362632, lng: 17.869415 },
    { lat: 48.362956, lng: 17.86933 },
    { lat: 48.363265, lng: 17.869305 },
    { lat: 48.363521, lng: 17.86924 },
    { lat: 48.363489, lng: 17.869304 },
    { lat: 48.363578, lng: 17.869387 },
    { lat: 48.363637, lng: 17.869639 },
    { lat: 48.363651, lng: 17.869744 },
    { lat: 48.363734, lng: 17.870101 },
    { lat: 48.363769, lng: 17.870268 },
    { lat: 48.363789, lng: 17.870393 },
    { lat: 48.363859, lng: 17.870765 },
    { lat: 48.363932, lng: 17.870913 },
    { lat: 48.364075, lng: 17.871145 },
    { lat: 48.364165, lng: 17.871326 },
    { lat: 48.364269, lng: 17.871545 },
    { lat: 48.364466, lng: 17.871987 },
    { lat: 48.3645918, lng: 17.8722254 },
    { lat: 48.364521, lng: 17.872275 },
    { lat: 48.36459, lng: 17.872586 },
    { lat: 48.364695, lng: 17.872774 },
    { lat: 48.364822, lng: 17.873121 },
    { lat: 48.364961, lng: 17.873484 },
    { lat: 48.365142, lng: 17.873755 },
    { lat: 48.365345, lng: 17.874089 },
    { lat: 48.365476, lng: 17.874356 },
    { lat: 48.365546, lng: 17.874449 },
    { lat: 48.3656, lng: 17.874428 },
    { lat: 48.3657259, lng: 17.8739332 },
    { lat: 48.3660011, lng: 17.8733148 },
    { lat: 48.3663537, lng: 17.8731188 },
    { lat: 48.3668021, lng: 17.8726775 },
    { lat: 48.3675228, lng: 17.8717201 },
    { lat: 48.3685693, lng: 17.8706813 },
    { lat: 48.3689532, lng: 17.8705325 },
    { lat: 48.3691759, lng: 17.8703163 },
    { lat: 48.3698269, lng: 17.8698643 },
    { lat: 48.3707602, lng: 17.8698784 },
    { lat: 48.3718678, lng: 17.8691408 },
    { lat: 48.3722, lng: 17.8689984 },
    { lat: 48.3727048, lng: 17.8689437 },
    { lat: 48.3732284, lng: 17.8686607 },
    { lat: 48.373614, lng: 17.8682841 },
    { lat: 48.3748202, lng: 17.867514 },
    { lat: 48.3752087, lng: 17.8671622 },
    { lat: 48.3762405, lng: 17.8664764 },
    { lat: 48.3778937, lng: 17.8649629 },
    { lat: 48.3785921, lng: 17.8642144 },
    { lat: 48.3800326, lng: 17.8633652 },
    { lat: 48.3812363, lng: 17.8621628 },
    { lat: 48.3815984, lng: 17.8616945 },
    { lat: 48.3820658, lng: 17.8607504 },
    { lat: 48.3815, lng: 17.8599303 },
    { lat: 48.3805254, lng: 17.858719 },
    { lat: 48.3800075, lng: 17.8582439 },
    { lat: 48.3798869, lng: 17.8578762 },
    { lat: 48.3796465, lng: 17.8573933 },
    { lat: 48.3789499, lng: 17.8562799 },
    { lat: 48.3788485, lng: 17.8561535 },
    { lat: 48.3785152, lng: 17.8560026 },
    { lat: 48.3783968, lng: 17.8555583 },
    { lat: 48.3778655, lng: 17.8546956 },
    { lat: 48.3774871, lng: 17.8539519 },
    { lat: 48.3789237, lng: 17.8521679 },
    { lat: 48.379302, lng: 17.8518529 },
    { lat: 48.3796143, lng: 17.851458 },
    { lat: 48.3803491, lng: 17.850454 },
    { lat: 48.3807055, lng: 17.8497818 },
    { lat: 48.3810566, lng: 17.8494495 },
    { lat: 48.3813464, lng: 17.8490584 },
    { lat: 48.3821114, lng: 17.8476067 },
    { lat: 48.3829769, lng: 17.8457519 },
    { lat: 48.3832476, lng: 17.8448034 },
    { lat: 48.3835397, lng: 17.8441527 },
    { lat: 48.3845411, lng: 17.8428963 },
    { lat: 48.3848036, lng: 17.8420348 },
    { lat: 48.3849144, lng: 17.8415405 },
    { lat: 48.3850671, lng: 17.8403965 },
    { lat: 48.3852515, lng: 17.8397209 },
    { lat: 48.3854902, lng: 17.839395 },
    { lat: 48.3860168, lng: 17.8388875 },
    { lat: 48.3865517, lng: 17.838718 },
    { lat: 48.3870816, lng: 17.8387419 },
    { lat: 48.3879364, lng: 17.8389332 },
    { lat: 48.3888551, lng: 17.8388 },
    { lat: 48.3891825, lng: 17.8388189 },
    { lat: 48.3899701, lng: 17.8386071 },
    { lat: 48.3905895, lng: 17.8387139 },
    { lat: 48.390931, lng: 17.8386843 },
    { lat: 48.3914096, lng: 17.838407 },
    { lat: 48.3925809, lng: 17.8374554 },
    { lat: 48.392919, lng: 17.836891 },
    { lat: 48.3929342, lng: 17.8357374 },
    { lat: 48.3928142, lng: 17.8345672 },
    { lat: 48.3928065, lng: 17.8338341 },
    { lat: 48.3929103, lng: 17.8332951 },
    { lat: 48.3928352, lng: 17.8329329 },
    { lat: 48.3928707, lng: 17.8322396 },
    { lat: 48.3926003, lng: 17.831494 },
    { lat: 48.3922452, lng: 17.8314474 },
    { lat: 48.3919613, lng: 17.8315658 },
    { lat: 48.3916836, lng: 17.8315472 },
    { lat: 48.3899829, lng: 17.8306529 },
    { lat: 48.3895876, lng: 17.8302425 },
    { lat: 48.3890645, lng: 17.8298859 },
    { lat: 48.3881609, lng: 17.8294724 },
    { lat: 48.3862283, lng: 17.8294909 },
    { lat: 48.3852556, lng: 17.829738 },
    { lat: 48.3844447, lng: 17.8295646 },
    { lat: 48.3841856, lng: 17.829297 },
    { lat: 48.3840363, lng: 17.8290725 },
  ],
  Ratkovce: [
    { lat: 48.472352, lng: 17.746156 },
    { lat: 48.472417, lng: 17.74575 },
    { lat: 48.472498, lng: 17.745337 },
    { lat: 48.47249, lng: 17.744992 },
    { lat: 48.472414, lng: 17.744576 },
    { lat: 48.472324, lng: 17.744332 },
    { lat: 48.472137, lng: 17.743965 },
    { lat: 48.472096, lng: 17.743462 },
    { lat: 48.472092, lng: 17.743151 },
    { lat: 48.472248, lng: 17.742489 },
    { lat: 48.472418, lng: 17.742202 },
    { lat: 48.472438, lng: 17.741753 },
    { lat: 48.472496, lng: 17.741415 },
    { lat: 48.47255, lng: 17.740852 },
    { lat: 48.472553, lng: 17.740683 },
    { lat: 48.472386, lng: 17.740239 },
    { lat: 48.472454, lng: 17.739847 },
    { lat: 48.472506, lng: 17.739353 },
    { lat: 48.472538, lng: 17.738838 },
    { lat: 48.472551, lng: 17.738626 },
    { lat: 48.47258, lng: 17.737965 },
    { lat: 48.472624, lng: 17.737556 },
    { lat: 48.472707, lng: 17.736732 },
    { lat: 48.472753, lng: 17.736322 },
    { lat: 48.472835, lng: 17.735799 },
    { lat: 48.472921, lng: 17.735273 },
    { lat: 48.473005, lng: 17.734743 },
    { lat: 48.473079, lng: 17.734224 },
    { lat: 48.473157, lng: 17.733708 },
    { lat: 48.473229, lng: 17.733178 },
    { lat: 48.473294, lng: 17.732718 },
    { lat: 48.473366, lng: 17.732184 },
    { lat: 48.473453, lng: 17.731563 },
    { lat: 48.4735015, lng: 17.7312058 },
    { lat: 48.473533, lng: 17.730974 },
    { lat: 48.473627, lng: 17.730305 },
    { lat: 48.473708, lng: 17.729738 },
    { lat: 48.473791, lng: 17.729127 },
    { lat: 48.473873, lng: 17.728533 },
    { lat: 48.473944, lng: 17.727975 },
    { lat: 48.473985, lng: 17.727517 },
    { lat: 48.474041, lng: 17.726918 },
    { lat: 48.474099, lng: 17.726316 },
    { lat: 48.474151, lng: 17.725742 },
    { lat: 48.474224, lng: 17.725063 },
    { lat: 48.474282, lng: 17.724463 },
    { lat: 48.47433, lng: 17.723978 },
    { lat: 48.474376, lng: 17.723493 },
    { lat: 48.474418, lng: 17.723043 },
    { lat: 48.474472, lng: 17.722491 },
    { lat: 48.474517, lng: 17.722006 },
    { lat: 48.474556, lng: 17.721906 },
    { lat: 48.474606, lng: 17.721182 },
    { lat: 48.474633, lng: 17.72085 },
    { lat: 48.474737, lng: 17.719983 },
    { lat: 48.474821, lng: 17.719197 },
    { lat: 48.474939, lng: 17.718529 },
    { lat: 48.474994, lng: 17.718234 },
    { lat: 48.475108, lng: 17.717651 },
    { lat: 48.475179, lng: 17.717497 },
    { lat: 48.475468, lng: 17.716883 },
    { lat: 48.475649, lng: 17.716426 },
    { lat: 48.475777, lng: 17.716096 },
    { lat: 48.475979, lng: 17.715536 },
    { lat: 48.476063, lng: 17.715316 },
    { lat: 48.476222, lng: 17.714863 },
    { lat: 48.476279, lng: 17.714678 },
    { lat: 48.476517, lng: 17.713836 },
    { lat: 48.476592, lng: 17.713586 },
    { lat: 48.476626, lng: 17.713482 },
    { lat: 48.476661, lng: 17.713355 },
    { lat: 48.476823, lng: 17.712759 },
    { lat: 48.477023, lng: 17.712058 },
    { lat: 48.4772, lng: 17.711443 },
    { lat: 48.47728, lng: 17.711152 },
    { lat: 48.477355, lng: 17.710826 },
    { lat: 48.47738, lng: 17.710694 },
    { lat: 48.477551, lng: 17.709907 },
    { lat: 48.4776, lng: 17.709681 },
    { lat: 48.477911, lng: 17.708922 },
    { lat: 48.477981, lng: 17.708715 },
    { lat: 48.478167, lng: 17.708149 },
    { lat: 48.478507, lng: 17.707121 },
    { lat: 48.478676, lng: 17.70664 },
    { lat: 48.478854, lng: 17.706203 },
    { lat: 48.479031, lng: 17.705762 },
    { lat: 48.479202, lng: 17.705378 },
    { lat: 48.479335, lng: 17.705129 },
    { lat: 48.479581, lng: 17.704695 },
    { lat: 48.479845, lng: 17.704233 },
    { lat: 48.480134, lng: 17.703757 },
    { lat: 48.480366, lng: 17.703376 },
    { lat: 48.480595, lng: 17.703031 },
    { lat: 48.480793, lng: 17.702721 },
    { lat: 48.481095, lng: 17.702246 },
    { lat: 48.481527, lng: 17.701666 },
    { lat: 48.481726, lng: 17.701451 },
    { lat: 48.481833, lng: 17.701369 },
    { lat: 48.482052, lng: 17.701095 },
    { lat: 48.482198, lng: 17.700876 },
    { lat: 48.482331, lng: 17.700658 },
    { lat: 48.482751, lng: 17.699959 },
    { lat: 48.482962, lng: 17.699575 },
    { lat: 48.483218, lng: 17.699127 },
    { lat: 48.483375, lng: 17.698897 },
    { lat: 48.483503, lng: 17.698678 },
    { lat: 48.48378, lng: 17.698184 },
    { lat: 48.484048, lng: 17.69775 },
    { lat: 48.48419, lng: 17.69749 },
    { lat: 48.484406, lng: 17.697143 },
    { lat: 48.484596, lng: 17.696831 },
    { lat: 48.484886, lng: 17.696361 },
    { lat: 48.485044, lng: 17.696141 },
    { lat: 48.485124, lng: 17.696023 },
    { lat: 48.485365, lng: 17.695669 },
    { lat: 48.485657, lng: 17.695189 },
    { lat: 48.486001, lng: 17.694706 },
    { lat: 48.486332, lng: 17.69418 },
    { lat: 48.48658, lng: 17.693751 },
    { lat: 48.486804, lng: 17.69331 },
    { lat: 48.486989, lng: 17.692892 },
    { lat: 48.487192, lng: 17.692473 },
    { lat: 48.48745, lng: 17.691924 },
    { lat: 48.487794, lng: 17.691344 },
    { lat: 48.488222, lng: 17.690732 },
    { lat: 48.488411, lng: 17.690468 },
    { lat: 48.488558, lng: 17.690238 },
    { lat: 48.48874, lng: 17.689966 },
    { lat: 48.488807, lng: 17.689836 },
    { lat: 48.489073, lng: 17.689403 },
    { lat: 48.489333, lng: 17.688969 },
    { lat: 48.489741, lng: 17.68832 },
    { lat: 48.489949, lng: 17.688052 },
    { lat: 48.490117, lng: 17.687816 },
    { lat: 48.490275, lng: 17.687574 },
    { lat: 48.490584, lng: 17.687216 },
    { lat: 48.490791, lng: 17.687022 },
    { lat: 48.491219, lng: 17.686499 },
    { lat: 48.49141, lng: 17.686212 },
    { lat: 48.491905, lng: 17.685443 },
    { lat: 48.492086, lng: 17.685196 },
    { lat: 48.49246, lng: 17.684743 },
    { lat: 48.492867, lng: 17.684232 },
    { lat: 48.493185, lng: 17.683855 },
    { lat: 48.493393, lng: 17.683619 },
    { lat: 48.493589, lng: 17.68336 },
    { lat: 48.493886, lng: 17.682936 },
    { lat: 48.494237, lng: 17.682434 },
    { lat: 48.494578, lng: 17.681947 },
    { lat: 48.494956, lng: 17.68143 },
    { lat: 48.495209, lng: 17.681032 },
    { lat: 48.494666, lng: 17.680442 },
    { lat: 48.494524, lng: 17.680279 },
    { lat: 48.494405, lng: 17.680141 },
    { lat: 48.494204, lng: 17.679934 },
    { lat: 48.494127, lng: 17.679837 },
    { lat: 48.494083, lng: 17.679759 },
    { lat: 48.494065, lng: 17.679687 },
    { lat: 48.494014, lng: 17.679439 },
    { lat: 48.493539, lng: 17.679822 },
    { lat: 48.493337, lng: 17.679981 },
    { lat: 48.492982, lng: 17.680195 },
    { lat: 48.492876, lng: 17.680259 },
    { lat: 48.492733, lng: 17.680366 },
    { lat: 48.492622, lng: 17.680472 },
    { lat: 48.492447, lng: 17.680736 },
    { lat: 48.492318, lng: 17.680927 },
    { lat: 48.492228, lng: 17.681028 },
    { lat: 48.491937, lng: 17.681287 },
    { lat: 48.4917403, lng: 17.6814852 },
    { lat: 48.490873, lng: 17.682218 },
    { lat: 48.490447, lng: 17.682582 },
    { lat: 48.4904349, lng: 17.6825906 },
    { lat: 48.490231, lng: 17.682736 },
    { lat: 48.490007, lng: 17.682912 },
    { lat: 48.489632, lng: 17.68325 },
    { lat: 48.489362, lng: 17.683533 },
    { lat: 48.489077, lng: 17.683841 },
    { lat: 48.488783, lng: 17.684152 },
    { lat: 48.488643, lng: 17.684293 },
    { lat: 48.488357, lng: 17.68465 },
    { lat: 48.488026, lng: 17.685196 },
    { lat: 48.487725, lng: 17.685691 },
    { lat: 48.48745, lng: 17.686127 },
    { lat: 48.487113, lng: 17.686595 },
    { lat: 48.486973, lng: 17.686796 },
    { lat: 48.486722, lng: 17.68725 },
    { lat: 48.486614, lng: 17.687521 },
    { lat: 48.486559, lng: 17.687695 },
    { lat: 48.486501, lng: 17.687894 },
    { lat: 48.486487, lng: 17.687873 },
    { lat: 48.48647, lng: 17.687919 },
    { lat: 48.486195, lng: 17.687704 },
    { lat: 48.485815, lng: 17.687373 },
    { lat: 48.485557, lng: 17.687147 },
    { lat: 48.485522, lng: 17.687189 },
    { lat: 48.485457, lng: 17.68723 },
    { lat: 48.485367, lng: 17.68731 },
    { lat: 48.485219, lng: 17.687486 },
    { lat: 48.485003, lng: 17.687732 },
    { lat: 48.484964, lng: 17.687752 },
    { lat: 48.484403, lng: 17.688446 },
    { lat: 48.484049, lng: 17.688805 },
    { lat: 48.483724, lng: 17.689046 },
    { lat: 48.483668, lng: 17.689121 },
    { lat: 48.483347, lng: 17.68936 },
    { lat: 48.482926, lng: 17.689723 },
    { lat: 48.482424, lng: 17.690192 },
    { lat: 48.481941, lng: 17.690678 },
    { lat: 48.481541, lng: 17.690982 },
    { lat: 48.481392, lng: 17.69105 },
    { lat: 48.481187, lng: 17.691094 },
    { lat: 48.481012, lng: 17.691104 },
    { lat: 48.480867, lng: 17.691111 },
    { lat: 48.4799725, lng: 17.6919906 },
    { lat: 48.4779345, lng: 17.695184 },
    { lat: 48.4772745, lng: 17.6960267 },
    { lat: 48.4762794, lng: 17.6971831 },
    { lat: 48.4753662, lng: 17.6980749 },
    { lat: 48.4744621, lng: 17.6990579 },
    { lat: 48.4731096, lng: 17.7007501 },
    { lat: 48.4717198, lng: 17.7036227 },
    { lat: 48.4711245, lng: 17.7051072 },
    { lat: 48.4698445, lng: 17.7085633 },
    { lat: 48.4684005, lng: 17.7130737 },
    { lat: 48.4670665, lng: 17.7164839 },
    { lat: 48.4660314, lng: 17.7187268 },
    { lat: 48.4654912, lng: 17.7203391 },
    { lat: 48.4650925, lng: 17.7218838 },
    { lat: 48.4645388, lng: 17.7236797 },
    { lat: 48.4643894, lng: 17.7244221 },
    { lat: 48.4639175, lng: 17.725843 },
    { lat: 48.4636621, lng: 17.7267852 },
    { lat: 48.4632709, lng: 17.7293451 },
    { lat: 48.462996, lng: 17.7307433 },
    { lat: 48.4627506, lng: 17.7325421 },
    { lat: 48.4625897, lng: 17.7338783 },
    { lat: 48.4623241, lng: 17.7372526 },
    { lat: 48.4621465, lng: 17.7404192 },
    { lat: 48.4619765, lng: 17.7407005 },
    { lat: 48.4620019, lng: 17.7409321 },
    { lat: 48.4622271, lng: 17.7413413 },
    { lat: 48.4621888, lng: 17.7415626 },
    { lat: 48.4623805, lng: 17.7418318 },
    { lat: 48.4623927, lng: 17.7419368 },
    { lat: 48.4623578, lng: 17.7420317 },
    { lat: 48.462212, lng: 17.7420642 },
    { lat: 48.4619647, lng: 17.7419913 },
    { lat: 48.461756, lng: 17.7416452 },
    { lat: 48.4616682, lng: 17.7413964 },
    { lat: 48.4616174, lng: 17.7413743 },
    { lat: 48.4615859, lng: 17.7414317 },
    { lat: 48.4613995, lng: 17.7414507 },
    { lat: 48.4611415, lng: 17.7415867 },
    { lat: 48.460936, lng: 17.7418602 },
    { lat: 48.4608645, lng: 17.742345 },
    { lat: 48.4608726, lng: 17.742441 },
    { lat: 48.4610106, lng: 17.7426643 },
    { lat: 48.4612256, lng: 17.742874 },
    { lat: 48.4612369, lng: 17.7429656 },
    { lat: 48.4612555, lng: 17.7429751 },
    { lat: 48.4617945, lng: 17.7428151 },
    { lat: 48.4619623, lng: 17.7428468 },
    { lat: 48.4621171, lng: 17.7431386 },
    { lat: 48.462929, lng: 17.7433986 },
    { lat: 48.463385, lng: 17.7436866 },
    { lat: 48.4635444, lng: 17.7436942 },
    { lat: 48.4640125, lng: 17.7435192 },
    { lat: 48.4642025, lng: 17.743509 },
    { lat: 48.4648784, lng: 17.7436109 },
    { lat: 48.4656277, lng: 17.7440604 },
    { lat: 48.4664886, lng: 17.7442695 },
    { lat: 48.467523, lng: 17.7444199 },
    { lat: 48.4690553, lng: 17.7451915 },
    { lat: 48.4696194, lng: 17.74535 },
    { lat: 48.4704981, lng: 17.7459938 },
    { lat: 48.4705202, lng: 17.7460612 },
    { lat: 48.4708095, lng: 17.7462083 },
    { lat: 48.471645, lng: 17.7463221 },
    { lat: 48.472352, lng: 17.746156 },
  ],
  HornéOtrokovce: [
    { lat: 48.507204, lng: 17.872014 },
    { lat: 48.507207, lng: 17.871819 },
    { lat: 48.507648, lng: 17.870028 },
    { lat: 48.50763, lng: 17.869012 },
    { lat: 48.507628, lng: 17.868953 },
    { lat: 48.5075128, lng: 17.8691494 },
    { lat: 48.5073111, lng: 17.8690055 },
    { lat: 48.506775, lng: 17.8688411 },
    { lat: 48.5066088, lng: 17.8685193 },
    { lat: 48.5061379, lng: 17.8681458 },
    { lat: 48.5059488, lng: 17.8677076 },
    { lat: 48.5053503, lng: 17.8667358 },
    { lat: 48.5053146, lng: 17.8660732 },
    { lat: 48.5052068, lng: 17.8656381 },
    { lat: 48.5050375, lng: 17.8645219 },
    { lat: 48.5045991, lng: 17.8640496 },
    { lat: 48.5044146, lng: 17.8637058 },
    { lat: 48.504091, lng: 17.8626543 },
    { lat: 48.5037826, lng: 17.8618691 },
    { lat: 48.5035434, lng: 17.8608675 },
    { lat: 48.50334, lng: 17.8604189 },
    { lat: 48.5031192, lng: 17.8602043 },
    { lat: 48.5023511, lng: 17.8600096 },
    { lat: 48.5016764, lng: 17.8599963 },
    { lat: 48.501091, lng: 17.8598565 },
    { lat: 48.4995418, lng: 17.8589581 },
    { lat: 48.499229, lng: 17.8589755 },
    { lat: 48.499092, lng: 17.8587644 },
    { lat: 48.498751, lng: 17.8584632 },
    { lat: 48.4984941, lng: 17.8583615 },
    { lat: 48.497723, lng: 17.8582494 },
    { lat: 48.4967792, lng: 17.8584683 },
    { lat: 48.4965287, lng: 17.8584333 },
    { lat: 48.4960401, lng: 17.8576232 },
    { lat: 48.4947512, lng: 17.8571055 },
    { lat: 48.4940702, lng: 17.8564137 },
    { lat: 48.4936892, lng: 17.8559558 },
    { lat: 48.493523, lng: 17.8557048 },
    { lat: 48.4934408, lng: 17.8555665 },
    { lat: 48.4928088, lng: 17.8564987 },
    { lat: 48.4926631, lng: 17.8570152 },
    { lat: 48.4925322, lng: 17.8577617 },
    { lat: 48.4924346, lng: 17.8579392 },
    { lat: 48.492202, lng: 17.8581235 },
    { lat: 48.4909898, lng: 17.8590725 },
    { lat: 48.4889041, lng: 17.8603986 },
    { lat: 48.4874833, lng: 17.8615576 },
    { lat: 48.4869573, lng: 17.8615794 },
    { lat: 48.4855502, lng: 17.8618117 },
    { lat: 48.4849228, lng: 17.8622036 },
    { lat: 48.4844566, lng: 17.8625936 },
    { lat: 48.4837801, lng: 17.8629987 },
    { lat: 48.4835382, lng: 17.8632141 },
    { lat: 48.4829059, lng: 17.8636045 },
    { lat: 48.4823808, lng: 17.8638226 },
    { lat: 48.4815526, lng: 17.864668 },
    { lat: 48.4812316, lng: 17.8651074 },
    { lat: 48.4794551, lng: 17.8666192 },
    { lat: 48.4784733, lng: 17.8678126 },
    { lat: 48.4779264, lng: 17.8685654 },
    { lat: 48.4775188, lng: 17.8692508 },
    { lat: 48.4774125, lng: 17.8695457 },
    { lat: 48.4764664, lng: 17.871145 },
    { lat: 48.4760201, lng: 17.8722177 },
    { lat: 48.4755293, lng: 17.8730219 },
    { lat: 48.4751479, lng: 17.8732663 },
    { lat: 48.4745266, lng: 17.8739859 },
    { lat: 48.4734566, lng: 17.8748232 },
    { lat: 48.4727603, lng: 17.875722 },
    { lat: 48.4730551, lng: 17.8758701 },
    { lat: 48.4719011, lng: 17.8782333 },
    { lat: 48.4697681, lng: 17.8826152 },
    { lat: 48.4709759, lng: 17.8841053 },
    { lat: 48.4719225, lng: 17.8847685 },
    { lat: 48.4723223, lng: 17.8852377 },
    { lat: 48.4743758, lng: 17.8883532 },
    { lat: 48.4760113, lng: 17.8904203 },
    { lat: 48.4772274, lng: 17.8886536 },
    { lat: 48.4773346, lng: 17.8885617 },
    { lat: 48.4779346, lng: 17.88958 },
    { lat: 48.4786378, lng: 17.8888323 },
    { lat: 48.4789447, lng: 17.8886067 },
    { lat: 48.4798863, lng: 17.8881792 },
    { lat: 48.4812302, lng: 17.891641 },
    { lat: 48.4815736, lng: 17.8921683 },
    { lat: 48.4824889, lng: 17.8939918 },
    { lat: 48.4824712, lng: 17.8941105 },
    { lat: 48.4825214, lng: 17.8942325 },
    { lat: 48.4822431, lng: 17.8946687 },
    { lat: 48.4844284, lng: 17.8979368 },
    { lat: 48.4825172, lng: 17.9000434 },
    { lat: 48.485227, lng: 17.9050259 },
    { lat: 48.4860351, lng: 17.9063705 },
    { lat: 48.4872471, lng: 17.9086268 },
    { lat: 48.4872601, lng: 17.9086542 },
    { lat: 48.4882111, lng: 17.9077674 },
    { lat: 48.4888217, lng: 17.9067773 },
    { lat: 48.489265, lng: 17.9064716 },
    { lat: 48.4896056, lng: 17.9064471 },
    { lat: 48.4897716, lng: 17.9062241 },
    { lat: 48.4899779, lng: 17.9061464 },
    { lat: 48.4903257, lng: 17.9058292 },
    { lat: 48.4904453, lng: 17.9058285 },
    { lat: 48.4906252, lng: 17.9055723 },
    { lat: 48.490685, lng: 17.9052737 },
    { lat: 48.4909112, lng: 17.9051296 },
    { lat: 48.4909474, lng: 17.904703 },
    { lat: 48.4911431, lng: 17.90443 },
    { lat: 48.4911887, lng: 17.9042535 },
    { lat: 48.4912355, lng: 17.9040722 },
    { lat: 48.4914392, lng: 17.9036692 },
    { lat: 48.491516, lng: 17.903126 },
    { lat: 48.4916307, lng: 17.9029303 },
    { lat: 48.4918583, lng: 17.9028137 },
    { lat: 48.4920676, lng: 17.9025236 },
    { lat: 48.4923193, lng: 17.9024845 },
    { lat: 48.4929961, lng: 17.9018233 },
    { lat: 48.4931179, lng: 17.9016316 },
    { lat: 48.4933382, lng: 17.9014872 },
    { lat: 48.4934904, lng: 17.9015756 },
    { lat: 48.4939288, lng: 17.9012282 },
    { lat: 48.4941331, lng: 17.9012465 },
    { lat: 48.4942493, lng: 17.9011541 },
    { lat: 48.4945142, lng: 17.9007146 },
    { lat: 48.4946476, lng: 17.900664 },
    { lat: 48.4950773, lng: 17.9002276 },
    { lat: 48.4958582, lng: 17.8996055 },
    { lat: 48.4966596, lng: 17.8987642 },
    { lat: 48.4967903, lng: 17.8984455 },
    { lat: 48.4972375, lng: 17.8979423 },
    { lat: 48.4973473, lng: 17.8976856 },
    { lat: 48.4976124, lng: 17.8975704 },
    { lat: 48.4978544, lng: 17.8975789 },
    { lat: 48.4979202, lng: 17.8974734 },
    { lat: 48.4981944, lng: 17.897337 },
    { lat: 48.4986009, lng: 17.8968731 },
    { lat: 48.4989846, lng: 17.8963148 },
    { lat: 48.4991321, lng: 17.8959886 },
    { lat: 48.4995382, lng: 17.8947353 },
    { lat: 48.4998759, lng: 17.8943764 },
    { lat: 48.4996539, lng: 17.8940709 },
    { lat: 48.4998698, lng: 17.8935876 },
    { lat: 48.4999326, lng: 17.8933387 },
    { lat: 48.4999315, lng: 17.8929854 },
    { lat: 48.5000552, lng: 17.8925111 },
    { lat: 48.5001696, lng: 17.8922549 },
    { lat: 48.5004199, lng: 17.8909271 },
    { lat: 48.5004723, lng: 17.8907843 },
    { lat: 48.5005421, lng: 17.8907452 },
    { lat: 48.500902, lng: 17.8899015 },
    { lat: 48.5010366, lng: 17.8891783 },
    { lat: 48.5012155, lng: 17.888664 },
    { lat: 48.5015755, lng: 17.8880106 },
    { lat: 48.5018392, lng: 17.8876656 },
    { lat: 48.5020139, lng: 17.8872148 },
    { lat: 48.5023982, lng: 17.8867368 },
    { lat: 48.5026516, lng: 17.8860179 },
    { lat: 48.5027206, lng: 17.885927 },
    { lat: 48.5030524, lng: 17.8861331 },
    { lat: 48.5036346, lng: 17.8863328 },
    { lat: 48.5040293, lng: 17.8855262 },
    { lat: 48.5043205, lng: 17.8852805 },
    { lat: 48.504679, lng: 17.8851 },
    { lat: 48.504681, lng: 17.884712 },
    { lat: 48.504711, lng: 17.884352 },
    { lat: 48.504724, lng: 17.883764 },
    { lat: 48.504671, lng: 17.883272 },
    { lat: 48.504638, lng: 17.882291 },
    { lat: 48.504662, lng: 17.881973 },
    { lat: 48.504741, lng: 17.881742 },
    { lat: 48.50487, lng: 17.881467 },
    { lat: 48.505097, lng: 17.880668 },
    { lat: 48.50532, lng: 17.879809 },
    { lat: 48.505452, lng: 17.879188 },
    { lat: 48.505505, lng: 17.878716 },
    { lat: 48.505492, lng: 17.878358 },
    { lat: 48.505414, lng: 17.87779 },
    { lat: 48.505395, lng: 17.87745 },
    { lat: 48.505409, lng: 17.877326 },
    { lat: 48.505587, lng: 17.876369 },
    { lat: 48.505698, lng: 17.875931 },
    { lat: 48.505821, lng: 17.875527 },
    { lat: 48.506121, lng: 17.874843 },
    { lat: 48.50646, lng: 17.873881 },
    { lat: 48.506932, lng: 17.873056 },
    { lat: 48.507121, lng: 17.872633 },
    { lat: 48.50718, lng: 17.872032 },
    { lat: 48.507204, lng: 17.872014 },
  ],
  Kľačany: [
    { lat: 48.4042683, lng: 17.8619883 },
    { lat: 48.4040596, lng: 17.8617708 },
    { lat: 48.403994, lng: 17.8615432 },
    { lat: 48.4037582, lng: 17.8610849 },
    { lat: 48.401307, lng: 17.8575538 },
    { lat: 48.3971631, lng: 17.8517965 },
    { lat: 48.3953503, lng: 17.8488158 },
    { lat: 48.3949669, lng: 17.8478786 },
    { lat: 48.3946668, lng: 17.8480687 },
    { lat: 48.3920593, lng: 17.8488506 },
    { lat: 48.389107, lng: 17.8458487 },
    { lat: 48.3883647, lng: 17.8449688 },
    { lat: 48.3876779, lng: 17.8444052 },
    { lat: 48.3858064, lng: 17.8423731 },
    { lat: 48.3849323, lng: 17.8415551 },
    { lat: 48.3849144, lng: 17.8415405 },
    { lat: 48.3848036, lng: 17.8420348 },
    { lat: 48.3845411, lng: 17.8428963 },
    { lat: 48.3835397, lng: 17.8441527 },
    { lat: 48.3832476, lng: 17.8448034 },
    { lat: 48.3829769, lng: 17.8457519 },
    { lat: 48.3821114, lng: 17.8476067 },
    { lat: 48.3813464, lng: 17.8490584 },
    { lat: 48.3810566, lng: 17.8494495 },
    { lat: 48.3807055, lng: 17.8497818 },
    { lat: 48.3803491, lng: 17.850454 },
    { lat: 48.3796143, lng: 17.851458 },
    { lat: 48.379302, lng: 17.8518529 },
    { lat: 48.3789237, lng: 17.8521679 },
    { lat: 48.3774871, lng: 17.8539519 },
    { lat: 48.3778655, lng: 17.8546956 },
    { lat: 48.3783968, lng: 17.8555583 },
    { lat: 48.3785152, lng: 17.8560026 },
    { lat: 48.3788485, lng: 17.8561535 },
    { lat: 48.3789499, lng: 17.8562799 },
    { lat: 48.3796465, lng: 17.8573933 },
    { lat: 48.3798869, lng: 17.8578762 },
    { lat: 48.3800075, lng: 17.8582439 },
    { lat: 48.3805254, lng: 17.858719 },
    { lat: 48.3815, lng: 17.8599303 },
    { lat: 48.3820658, lng: 17.8607504 },
    { lat: 48.3815984, lng: 17.8616945 },
    { lat: 48.3812363, lng: 17.8621628 },
    { lat: 48.3800326, lng: 17.8633652 },
    { lat: 48.3785921, lng: 17.8642144 },
    { lat: 48.3778937, lng: 17.8649629 },
    { lat: 48.3762405, lng: 17.8664764 },
    { lat: 48.3752087, lng: 17.8671622 },
    { lat: 48.3748202, lng: 17.867514 },
    { lat: 48.373614, lng: 17.8682841 },
    { lat: 48.3732284, lng: 17.8686607 },
    { lat: 48.3727048, lng: 17.8689437 },
    { lat: 48.3722, lng: 17.8689984 },
    { lat: 48.3718678, lng: 17.8691408 },
    { lat: 48.3707602, lng: 17.8698784 },
    { lat: 48.3698269, lng: 17.8698643 },
    { lat: 48.3691759, lng: 17.8703163 },
    { lat: 48.3689532, lng: 17.8705325 },
    { lat: 48.3685693, lng: 17.8706813 },
    { lat: 48.3675228, lng: 17.8717201 },
    { lat: 48.3668021, lng: 17.8726775 },
    { lat: 48.3663537, lng: 17.8731188 },
    { lat: 48.3660011, lng: 17.8733148 },
    { lat: 48.3657259, lng: 17.8739332 },
    { lat: 48.3656, lng: 17.874428 },
    { lat: 48.365587, lng: 17.874533 },
    { lat: 48.365595, lng: 17.874679 },
    { lat: 48.36571, lng: 17.875036 },
    { lat: 48.365806, lng: 17.875361 },
    { lat: 48.366022, lng: 17.875852 },
    { lat: 48.366219, lng: 17.876383 },
    { lat: 48.36625, lng: 17.876515 },
    { lat: 48.36629, lng: 17.877017 },
    { lat: 48.366874, lng: 17.877428 },
    { lat: 48.367123, lng: 17.877663 },
    { lat: 48.367457, lng: 17.878163 },
    { lat: 48.367736, lng: 17.878526 },
    { lat: 48.367827, lng: 17.878595 },
    { lat: 48.367951, lng: 17.87872 },
    { lat: 48.368169, lng: 17.878788 },
    { lat: 48.368178, lng: 17.878764 },
    { lat: 48.36847, lng: 17.879017 },
    { lat: 48.368662, lng: 17.879017 },
    { lat: 48.368951, lng: 17.878844 },
    { lat: 48.369064, lng: 17.878747 },
    { lat: 48.369152, lng: 17.878679 },
    { lat: 48.369248, lng: 17.87867 },
    { lat: 48.36947, lng: 17.87859 },
    { lat: 48.369601, lng: 17.878586 },
    { lat: 48.369839, lng: 17.878716 },
    { lat: 48.37001, lng: 17.878768 },
    { lat: 48.370181, lng: 17.87879 },
    { lat: 48.370564, lng: 17.878931 },
    { lat: 48.370948, lng: 17.879069 },
    { lat: 48.371308, lng: 17.879193 },
    { lat: 48.371627, lng: 17.879317 },
    { lat: 48.372003, lng: 17.879443 },
    { lat: 48.372376, lng: 17.87957 },
    { lat: 48.372464, lng: 17.879624 },
    { lat: 48.373187, lng: 17.87996 },
    { lat: 48.373645, lng: 17.88023 },
    { lat: 48.373828, lng: 17.880347 },
    { lat: 48.374118, lng: 17.88056 },
    { lat: 48.374411, lng: 17.880806 },
    { lat: 48.374785, lng: 17.88112 },
    { lat: 48.375192, lng: 17.881452 },
    { lat: 48.375311, lng: 17.881547 },
    { lat: 48.37544, lng: 17.881694 },
    { lat: 48.375753, lng: 17.882024 },
    { lat: 48.376072, lng: 17.882374 },
    { lat: 48.376356, lng: 17.882723 },
    { lat: 48.376604, lng: 17.883031 },
    { lat: 48.376831, lng: 17.883292 },
    { lat: 48.377133, lng: 17.883648 },
    { lat: 48.377462, lng: 17.884098 },
    { lat: 48.377478, lng: 17.884077 },
    { lat: 48.377733, lng: 17.884423 },
    { lat: 48.378047, lng: 17.884838 },
    { lat: 48.378074, lng: 17.884871 },
    { lat: 48.378087, lng: 17.884866 },
    { lat: 48.378423, lng: 17.885295 },
    { lat: 48.378758, lng: 17.885713 },
    { lat: 48.379513, lng: 17.88666 },
    { lat: 48.379872, lng: 17.88711 },
    { lat: 48.379948, lng: 17.887206 },
    { lat: 48.38018, lng: 17.887473 },
    { lat: 48.380567, lng: 17.887887 },
    { lat: 48.380896, lng: 17.888261 },
    { lat: 48.381517, lng: 17.88878 },
    { lat: 48.381564, lng: 17.888802 },
    { lat: 48.381792, lng: 17.889018 },
    { lat: 48.382061, lng: 17.889281 },
    { lat: 48.382274, lng: 17.889461 },
    { lat: 48.382429, lng: 17.889696 },
    { lat: 48.382564, lng: 17.889815 },
    { lat: 48.382819, lng: 17.889954 },
    { lat: 48.383138, lng: 17.890136 },
    { lat: 48.38339, lng: 17.890265 },
    { lat: 48.383548, lng: 17.890275 },
    { lat: 48.383837, lng: 17.890376 },
    { lat: 48.383965, lng: 17.890403 },
    { lat: 48.384294, lng: 17.890545 },
    { lat: 48.384527, lng: 17.890584 },
    { lat: 48.384831, lng: 17.890644 },
    { lat: 48.384941, lng: 17.890718 },
    { lat: 48.38519, lng: 17.891038 },
    { lat: 48.385374, lng: 17.891172 },
    { lat: 48.38561, lng: 17.891269 },
    { lat: 48.385806, lng: 17.891346 },
    { lat: 48.385879, lng: 17.891344 },
    { lat: 48.385965, lng: 17.891417 },
    { lat: 48.386086, lng: 17.89147 },
    { lat: 48.386319, lng: 17.89187 },
    { lat: 48.386815, lng: 17.892368 },
    { lat: 48.387159, lng: 17.892812 },
    { lat: 48.387399, lng: 17.893266 },
    { lat: 48.387555, lng: 17.893761 },
    { lat: 48.387719, lng: 17.89397 },
    { lat: 48.387835, lng: 17.894103 },
    { lat: 48.388068, lng: 17.89424 },
    { lat: 48.388268, lng: 17.894497 },
    { lat: 48.388578, lng: 17.894898 },
    { lat: 48.388759, lng: 17.895324 },
    { lat: 48.38896, lng: 17.895602 },
    { lat: 48.389168, lng: 17.895675 },
    { lat: 48.389454, lng: 17.895843 },
    { lat: 48.389665, lng: 17.896009 },
    { lat: 48.38983, lng: 17.896123 },
    { lat: 48.390128, lng: 17.896371 },
    { lat: 48.390321, lng: 17.896385 },
    { lat: 48.390506, lng: 17.896474 },
    { lat: 48.390714, lng: 17.896635 },
    { lat: 48.39086, lng: 17.89676 },
    { lat: 48.391087, lng: 17.89698 },
    { lat: 48.391341, lng: 17.897332 },
    { lat: 48.391594, lng: 17.897844 },
    { lat: 48.391719, lng: 17.898038 },
    { lat: 48.392004, lng: 17.898478 },
    { lat: 48.3923, lng: 17.898853 },
    { lat: 48.392528, lng: 17.899183 },
    { lat: 48.392616, lng: 17.899429 },
    { lat: 48.392667, lng: 17.899566 },
    { lat: 48.392677, lng: 17.899622 },
    { lat: 48.392824, lng: 17.899729 },
    { lat: 48.393048, lng: 17.899828 },
    { lat: 48.393362, lng: 17.900011 },
    { lat: 48.393707, lng: 17.900197 },
    { lat: 48.393848, lng: 17.900282 },
    { lat: 48.3941659, lng: 17.8989794 },
    { lat: 48.3946367, lng: 17.8976361 },
    { lat: 48.3952736, lng: 17.8954627 },
    { lat: 48.3953836, lng: 17.8950015 },
    { lat: 48.3955069, lng: 17.8939283 },
    { lat: 48.3955592, lng: 17.8928705 },
    { lat: 48.395493, lng: 17.8924852 },
    { lat: 48.3951065, lng: 17.8918259 },
    { lat: 48.3946307, lng: 17.8911833 },
    { lat: 48.3947857, lng: 17.8907988 },
    { lat: 48.3955252, lng: 17.8894211 },
    { lat: 48.3963845, lng: 17.8880309 },
    { lat: 48.3971897, lng: 17.8863021 },
    { lat: 48.3979515, lng: 17.8849421 },
    { lat: 48.3990042, lng: 17.8834621 },
    { lat: 48.4003019, lng: 17.88219 },
    { lat: 48.4005406, lng: 17.8820312 },
    { lat: 48.4016528, lng: 17.8817963 },
    { lat: 48.4020289, lng: 17.881575 },
    { lat: 48.4027985, lng: 17.881459 },
    { lat: 48.4032963, lng: 17.8815497 },
    { lat: 48.4036865, lng: 17.8815094 },
    { lat: 48.4036784, lng: 17.8814651 },
    { lat: 48.4039817, lng: 17.8813493 },
    { lat: 48.4047697, lng: 17.8805121 },
    { lat: 48.4056903, lng: 17.879873 },
    { lat: 48.4063465, lng: 17.8793188 },
    { lat: 48.4071708, lng: 17.8784339 },
    { lat: 48.4079823, lng: 17.8774413 },
    { lat: 48.4094598, lng: 17.8754913 },
    { lat: 48.4100572, lng: 17.8750488 },
    { lat: 48.4109418, lng: 17.8735167 },
    { lat: 48.4118779, lng: 17.8723871 },
    { lat: 48.4116249, lng: 17.8721596 },
    { lat: 48.4114144, lng: 17.8718718 },
    { lat: 48.4109609, lng: 17.871023 },
    { lat: 48.4102285, lng: 17.8699679 },
    { lat: 48.409075, lng: 17.8690637 },
    { lat: 48.4087067, lng: 17.8688151 },
    { lat: 48.4083845, lng: 17.868752 },
    { lat: 48.4067686, lng: 17.8687629 },
    { lat: 48.4066319, lng: 17.8684445 },
    { lat: 48.4063259, lng: 17.8680182 },
    { lat: 48.4060918, lng: 17.8675642 },
    { lat: 48.405568, lng: 17.8668587 },
    { lat: 48.4055876, lng: 17.8658631 },
    { lat: 48.4053676, lng: 17.8648246 },
    { lat: 48.4053207, lng: 17.8641855 },
    { lat: 48.4050336, lng: 17.8633239 },
    { lat: 48.4046951, lng: 17.862511 },
    { lat: 48.4044392, lng: 17.8621656 },
    { lat: 48.4042683, lng: 17.8619883 },
  ],
  Trakovice: [
    { lat: 48.432634, lng: 17.689601 },
    { lat: 48.432557, lng: 17.689629 },
    { lat: 48.432404, lng: 17.689682 },
    { lat: 48.432246, lng: 17.68983 },
    { lat: 48.432233, lng: 17.689983 },
    { lat: 48.432163, lng: 17.690172 },
    { lat: 48.432037, lng: 17.690269 },
    { lat: 48.431877, lng: 17.690473 },
    { lat: 48.431763, lng: 17.690954 },
    { lat: 48.431705, lng: 17.691084 },
    { lat: 48.431485, lng: 17.691189 },
    { lat: 48.431446, lng: 17.691215 },
    { lat: 48.43126, lng: 17.691621 },
    { lat: 48.431184, lng: 17.691743 },
    { lat: 48.430957, lng: 17.691863 },
    { lat: 48.430779, lng: 17.691938 },
    { lat: 48.430584, lng: 17.692328 },
    { lat: 48.430335, lng: 17.692499 },
    { lat: 48.430182, lng: 17.692654 },
    { lat: 48.42999, lng: 17.692756 },
    { lat: 48.429899, lng: 17.692868 },
    { lat: 48.429865, lng: 17.693012 },
    { lat: 48.429847, lng: 17.693059 },
    { lat: 48.429556, lng: 17.693549 },
    { lat: 48.429441, lng: 17.693949 },
    { lat: 48.429351, lng: 17.694095 },
    { lat: 48.429197, lng: 17.694119 },
    { lat: 48.429061, lng: 17.694272 },
    { lat: 48.428932, lng: 17.69456 },
    { lat: 48.428701, lng: 17.694831 },
    { lat: 48.428594, lng: 17.695059 },
    { lat: 48.428477, lng: 17.695208 },
    { lat: 48.428192, lng: 17.695509 },
    { lat: 48.428163, lng: 17.695542 },
    { lat: 48.427999, lng: 17.695813 },
    { lat: 48.427696, lng: 17.696154 },
    { lat: 48.427681, lng: 17.696213 },
    { lat: 48.427779, lng: 17.696396 },
    { lat: 48.428047, lng: 17.696829 },
    { lat: 48.427012, lng: 17.697868 },
    { lat: 48.427036, lng: 17.69793 },
    { lat: 48.427072, lng: 17.698016 },
    { lat: 48.427165, lng: 17.698241 },
    { lat: 48.427181, lng: 17.698281 },
    { lat: 48.427179, lng: 17.698339 },
    { lat: 48.427167, lng: 17.698411 },
    { lat: 48.427165, lng: 17.698466 },
    { lat: 48.427364, lng: 17.698516 },
    { lat: 48.427524, lng: 17.698555 },
    { lat: 48.427523, lng: 17.69863 },
    { lat: 48.427532, lng: 17.698706 },
    { lat: 48.42754, lng: 17.698836 },
    { lat: 48.427545, lng: 17.698916 },
    { lat: 48.427556, lng: 17.699076 },
    { lat: 48.427582, lng: 17.699627 },
    { lat: 48.427591, lng: 17.699776 },
    { lat: 48.427673, lng: 17.701219 },
    { lat: 48.427674, lng: 17.701234 },
    { lat: 48.427677, lng: 17.701286 },
    { lat: 48.4277, lng: 17.702158 },
    { lat: 48.42775, lng: 17.703763 },
    { lat: 48.427596, lng: 17.705061 },
    { lat: 48.427402, lng: 17.706378 },
    { lat: 48.427274, lng: 17.707456 },
    { lat: 48.427213, lng: 17.707988 },
    { lat: 48.427345, lng: 17.708038 },
    { lat: 48.427865, lng: 17.708104 },
    { lat: 48.427862, lng: 17.708139 },
    { lat: 48.427856, lng: 17.708189 },
    { lat: 48.427846, lng: 17.708286 },
    { lat: 48.427765, lng: 17.709179 },
    { lat: 48.427835, lng: 17.70939 },
    { lat: 48.427868, lng: 17.709486 },
    { lat: 48.428421, lng: 17.711129 },
    { lat: 48.428309, lng: 17.711691 },
    { lat: 48.427354, lng: 17.713305 },
    { lat: 48.427337, lng: 17.713332 },
    { lat: 48.427228, lng: 17.713516 },
    { lat: 48.427213, lng: 17.713542 },
    { lat: 48.427226, lng: 17.715292 },
    { lat: 48.427387, lng: 17.718099 },
    { lat: 48.427477, lng: 17.719669 },
    { lat: 48.427479, lng: 17.721933 },
    { lat: 48.427479, lng: 17.722024 },
    { lat: 48.427479, lng: 17.722234 },
    { lat: 48.426906, lng: 17.722108 },
    { lat: 48.426774, lng: 17.723343 },
    { lat: 48.423743, lng: 17.724628 },
    { lat: 48.42355, lng: 17.725639 },
    { lat: 48.423505, lng: 17.725535 },
    { lat: 48.423054, lng: 17.724479 },
    { lat: 48.422446, lng: 17.723708 },
    { lat: 48.422049, lng: 17.72325 },
    { lat: 48.421937, lng: 17.723239 },
    { lat: 48.421912, lng: 17.724647 },
    { lat: 48.42191, lng: 17.724704 },
    { lat: 48.421911, lng: 17.724712 },
    { lat: 48.421928, lng: 17.724835 },
    { lat: 48.422098, lng: 17.725239 },
    { lat: 48.422176, lng: 17.72551 },
    { lat: 48.422254, lng: 17.72603 },
    { lat: 48.422445, lng: 17.726452 },
    { lat: 48.4225, lng: 17.726648 },
    { lat: 48.422537, lng: 17.726867 },
    { lat: 48.420707, lng: 17.726801 },
    { lat: 48.420728, lng: 17.726974 },
    { lat: 48.42073, lng: 17.726993 },
    { lat: 48.42074, lng: 17.727074 },
    { lat: 48.42079, lng: 17.727488 },
    { lat: 48.420912, lng: 17.72846 },
    { lat: 48.420318, lng: 17.729241 },
    { lat: 48.420254, lng: 17.729325 },
    { lat: 48.419366, lng: 17.730491 },
    { lat: 48.419363, lng: 17.730542 },
    { lat: 48.4194939, lng: 17.7306681 },
    { lat: 48.41936, lng: 17.7310576 },
    { lat: 48.4192867, lng: 17.7314588 },
    { lat: 48.4193553, lng: 17.7316568 },
    { lat: 48.4194826, lng: 17.7317264 },
    { lat: 48.4198217, lng: 17.7313303 },
    { lat: 48.4199239, lng: 17.7313495 },
    { lat: 48.420193, lng: 17.7313999 },
    { lat: 48.4200025, lng: 17.7319337 },
    { lat: 48.4199941, lng: 17.7320728 },
    { lat: 48.4200518, lng: 17.7321301 },
    { lat: 48.4203834, lng: 17.7321772 },
    { lat: 48.4207997, lng: 17.7332834 },
    { lat: 48.4210054, lng: 17.7333613 },
    { lat: 48.4212124, lng: 17.7332001 },
    { lat: 48.4215802, lng: 17.733214 },
    { lat: 48.4216751, lng: 17.7333067 },
    { lat: 48.4217094, lng: 17.7334242 },
    { lat: 48.4216368, lng: 17.7336547 },
    { lat: 48.4217155, lng: 17.7340315 },
    { lat: 48.4216907, lng: 17.7341238 },
    { lat: 48.4215775, lng: 17.734194 },
    { lat: 48.4215381, lng: 17.7343225 },
    { lat: 48.4215885, lng: 17.734898 },
    { lat: 48.4216588, lng: 17.7351207 },
    { lat: 48.421789, lng: 17.7352755 },
    { lat: 48.4221547, lng: 17.7352361 },
    { lat: 48.4226181, lng: 17.734989 },
    { lat: 48.4228458, lng: 17.735148 },
    { lat: 48.4228846, lng: 17.7352436 },
    { lat: 48.4228416, lng: 17.7353519 },
    { lat: 48.4226875, lng: 17.7353813 },
    { lat: 48.4226221, lng: 17.7354701 },
    { lat: 48.4226389, lng: 17.7356543 },
    { lat: 48.4228045, lng: 17.7359561 },
    { lat: 48.4231547, lng: 17.7357418 },
    { lat: 48.4234565, lng: 17.7358564 },
    { lat: 48.4235666, lng: 17.736082 },
    { lat: 48.4235459, lng: 17.7364975 },
    { lat: 48.4234364, lng: 17.7368253 },
    { lat: 48.4234552, lng: 17.7373473 },
    { lat: 48.423339, lng: 17.7377865 },
    { lat: 48.4232876, lng: 17.7378495 },
    { lat: 48.4231639, lng: 17.7378398 },
    { lat: 48.4229697, lng: 17.7376569 },
    { lat: 48.4227643, lng: 17.738061 },
    { lat: 48.4223776, lng: 17.7383066 },
    { lat: 48.4221566, lng: 17.7386019 },
    { lat: 48.4221714, lng: 17.7387669 },
    { lat: 48.4225663, lng: 17.7388031 },
    { lat: 48.4227566, lng: 17.7390104 },
    { lat: 48.4232198, lng: 17.7392005 },
    { lat: 48.4237024, lng: 17.7392998 },
    { lat: 48.4239686, lng: 17.7395295 },
    { lat: 48.424014, lng: 17.7396621 },
    { lat: 48.4239962, lng: 17.7397958 },
    { lat: 48.4238698, lng: 17.7400313 },
    { lat: 48.4238822, lng: 17.7401245 },
    { lat: 48.4240632, lng: 17.7403129 },
    { lat: 48.4241354, lng: 17.7404734 },
    { lat: 48.4241605, lng: 17.7407869 },
    { lat: 48.4243165, lng: 17.740865 },
    { lat: 48.4244438, lng: 17.7411903 },
    { lat: 48.4246803, lng: 17.7414181 },
    { lat: 48.4249153, lng: 17.7418737 },
    { lat: 48.4251635, lng: 17.7420541 },
    { lat: 48.4253297, lng: 17.7420746 },
    { lat: 48.4254778, lng: 17.7419725 },
    { lat: 48.4257878, lng: 17.7414884 },
    { lat: 48.4258596, lng: 17.7414627 },
    { lat: 48.4260855, lng: 17.7419555 },
    { lat: 48.4263221, lng: 17.7421314 },
    { lat: 48.4264194, lng: 17.7422807 },
    { lat: 48.4261723, lng: 17.7429945 },
    { lat: 48.4265621, lng: 17.743582 },
    { lat: 48.4266922, lng: 17.7434053 },
    { lat: 48.4269434, lng: 17.7432131 },
    { lat: 48.4275317, lng: 17.7435799 },
    { lat: 48.4280021, lng: 17.7434082 },
    { lat: 48.4282085, lng: 17.7434405 },
    { lat: 48.4283805, lng: 17.7435935 },
    { lat: 48.428366, lng: 17.7441427 },
    { lat: 48.4285192, lng: 17.744374 },
    { lat: 48.4286509, lng: 17.7444957 },
    { lat: 48.4289628, lng: 17.7445929 },
    { lat: 48.4290924, lng: 17.7447913 },
    { lat: 48.429232, lng: 17.7447494 },
    { lat: 48.4297213, lng: 17.7451553 },
    { lat: 48.429917, lng: 17.7451443 },
    { lat: 48.4300153, lng: 17.7450541 },
    { lat: 48.4302085, lng: 17.7444911 },
    { lat: 48.4303147, lng: 17.7443109 },
    { lat: 48.4303944, lng: 17.7443023 },
    { lat: 48.4304698, lng: 17.7446067 },
    { lat: 48.4305927, lng: 17.7447368 },
    { lat: 48.4308606, lng: 17.7448859 },
    { lat: 48.431099, lng: 17.7451598 },
    { lat: 48.4312999, lng: 17.7451801 },
    { lat: 48.4314591, lng: 17.7452858 },
    { lat: 48.4315362, lng: 17.7457145 },
    { lat: 48.4316062, lng: 17.7457676 },
    { lat: 48.4318299, lng: 17.7457154 },
    { lat: 48.4320864, lng: 17.7457937 },
    { lat: 48.4323439, lng: 17.7453926 },
    { lat: 48.4324651, lng: 17.7452875 },
    { lat: 48.4326479, lng: 17.7448496 },
    { lat: 48.4327999, lng: 17.7447128 },
    { lat: 48.4329601, lng: 17.7449667 },
    { lat: 48.4331347, lng: 17.7449242 },
    { lat: 48.4332219, lng: 17.7449646 },
    { lat: 48.433501, lng: 17.7452616 },
    { lat: 48.4336033, lng: 17.7451446 },
    { lat: 48.4337814, lng: 17.7451023 },
    { lat: 48.4338481, lng: 17.7451598 },
    { lat: 48.4339898, lng: 17.7456797 },
    { lat: 48.4341414, lng: 17.7459575 },
    { lat: 48.4341437, lng: 17.7463052 },
    { lat: 48.4342774, lng: 17.7464775 },
    { lat: 48.4342628, lng: 17.7467739 },
    { lat: 48.434135, lng: 17.7472433 },
    { lat: 48.4342041, lng: 17.7473552 },
    { lat: 48.4342933, lng: 17.747374 },
    { lat: 48.4344685, lng: 17.7472298 },
    { lat: 48.4346042, lng: 17.7472371 },
    { lat: 48.4347303, lng: 17.7475388 },
    { lat: 48.4347871, lng: 17.747837 },
    { lat: 48.4348483, lng: 17.7478597 },
    { lat: 48.4352094, lng: 17.7476191 },
    { lat: 48.4352897, lng: 17.7470397 },
    { lat: 48.4358919, lng: 17.7464237 },
    { lat: 48.4360655, lng: 17.7463625 },
    { lat: 48.4361408, lng: 17.7464132 },
    { lat: 48.4361781, lng: 17.7465032 },
    { lat: 48.4360677, lng: 17.74693 },
    { lat: 48.436262, lng: 17.747187 },
    { lat: 48.436359, lng: 17.7472022 },
    { lat: 48.4365165, lng: 17.7470288 },
    { lat: 48.4366044, lng: 17.7471729 },
    { lat: 48.4367591, lng: 17.7473024 },
    { lat: 48.4373478, lng: 17.744533 },
    { lat: 48.4373062, lng: 17.7455937 },
    { lat: 48.4377238, lng: 17.7450277 },
    { lat: 48.437946, lng: 17.7437092 },
    { lat: 48.4380694, lng: 17.7434782 },
    { lat: 48.4382543, lng: 17.7439128 },
    { lat: 48.4383671, lng: 17.7449673 },
    { lat: 48.438525, lng: 17.7449115 },
    { lat: 48.4384905, lng: 17.7446286 },
    { lat: 48.4388563, lng: 17.7451002 },
    { lat: 48.4396585, lng: 17.7435769 },
    { lat: 48.4408352, lng: 17.7449678 },
    { lat: 48.4408697, lng: 17.7445427 },
    { lat: 48.4409384, lng: 17.7442646 },
    { lat: 48.4410059, lng: 17.7441314 },
    { lat: 48.4414514, lng: 17.7436507 },
    { lat: 48.4441209, lng: 17.7425807 },
    { lat: 48.4443395, lng: 17.7423211 },
    { lat: 48.4446829, lng: 17.7418667 },
    { lat: 48.4446329, lng: 17.7416785 },
    { lat: 48.4448039, lng: 17.7416119 },
    { lat: 48.4449894, lng: 17.7412343 },
    { lat: 48.4452724, lng: 17.7411584 },
    { lat: 48.4454408, lng: 17.7406303 },
    { lat: 48.4455893, lng: 17.7405146 },
    { lat: 48.445659, lng: 17.7402865 },
    { lat: 48.4452647, lng: 17.7401005 },
    { lat: 48.4452045, lng: 17.740004 },
    { lat: 48.4451924, lng: 17.7397358 },
    { lat: 48.4452582, lng: 17.7395399 },
    { lat: 48.4452103, lng: 17.7394058 },
    { lat: 48.4449859, lng: 17.7395556 },
    { lat: 48.4447633, lng: 17.7394231 },
    { lat: 48.4447264, lng: 17.7391011 },
    { lat: 48.4448072, lng: 17.738821 },
    { lat: 48.4444872, lng: 17.7383757 },
    { lat: 48.4444955, lng: 17.7382533 },
    { lat: 48.4446058, lng: 17.7380476 },
    { lat: 48.4447706, lng: 17.7380463 },
    { lat: 48.445278, lng: 17.737635 },
    { lat: 48.4454442, lng: 17.7377099 },
    { lat: 48.4456925, lng: 17.7380749 },
    { lat: 48.445755, lng: 17.7380989 },
    { lat: 48.4459838, lng: 17.7378393 },
    { lat: 48.4462747, lng: 17.7377036 },
    { lat: 48.4464085, lng: 17.7374961 },
    { lat: 48.446389, lng: 17.7373445 },
    { lat: 48.4463184, lng: 17.7372441 },
    { lat: 48.446302, lng: 17.7369939 },
    { lat: 48.4463436, lng: 17.7369358 },
    { lat: 48.4464514, lng: 17.7370004 },
    { lat: 48.4464975, lng: 17.736962 },
    { lat: 48.4465522, lng: 17.7366072 },
    { lat: 48.4465219, lng: 17.7362619 },
    { lat: 48.4467313, lng: 17.7361782 },
    { lat: 48.4468747, lng: 17.7358306 },
    { lat: 48.4469617, lng: 17.7358337 },
    { lat: 48.4471676, lng: 17.7361869 },
    { lat: 48.4472704, lng: 17.7364979 },
    { lat: 48.4474243, lng: 17.7365759 },
    { lat: 48.4476951, lng: 17.7364449 },
    { lat: 48.4477248, lng: 17.7363049 },
    { lat: 48.4478436, lng: 17.736199 },
    { lat: 48.4480447, lng: 17.7363476 },
    { lat: 48.4483832, lng: 17.7364197 },
    { lat: 48.448472, lng: 17.736264 },
    { lat: 48.4483462, lng: 17.7358954 },
    { lat: 48.4483477, lng: 17.7356814 },
    { lat: 48.4484079, lng: 17.7356296 },
    { lat: 48.4486884, lng: 17.7357518 },
    { lat: 48.4488597, lng: 17.7357094 },
    { lat: 48.4489427, lng: 17.7354794 },
    { lat: 48.4489204, lng: 17.7351386 },
    { lat: 48.4487515, lng: 17.734706 },
    { lat: 48.4487341, lng: 17.7344452 },
    { lat: 48.4487732, lng: 17.7341282 },
    { lat: 48.4492672, lng: 17.7332454 },
    { lat: 48.4498334, lng: 17.7317564 },
    { lat: 48.4484521, lng: 17.7294379 },
    { lat: 48.4483475, lng: 17.7291503 },
    { lat: 48.4479641, lng: 17.7288512 },
    { lat: 48.4486848, lng: 17.7263785 },
    { lat: 48.447394, lng: 17.7257346 },
    { lat: 48.4471686, lng: 17.7255411 },
    { lat: 48.447094, lng: 17.7247955 },
    { lat: 48.446611, lng: 17.7221224 },
    { lat: 48.4461034, lng: 17.7213095 },
    { lat: 48.4462405, lng: 17.7205555 },
    { lat: 48.446208, lng: 17.7196309 },
    { lat: 48.44612, lng: 17.7191748 },
    { lat: 48.4459625, lng: 17.7186375 },
    { lat: 48.4458958, lng: 17.7185654 },
    { lat: 48.4458605, lng: 17.7182674 },
    { lat: 48.4458848, lng: 17.7174767 },
    { lat: 48.4454165, lng: 17.713265 },
    { lat: 48.445461, lng: 17.7132349 },
    { lat: 48.4451249, lng: 17.710095 },
    { lat: 48.4450856, lng: 17.7094099 },
    { lat: 48.4449491, lng: 17.7087392 },
    { lat: 48.444604, lng: 17.7063855 },
    { lat: 48.444464, lng: 17.7039325 },
    { lat: 48.444488, lng: 17.7034131 },
    { lat: 48.4458823, lng: 17.7037553 },
    { lat: 48.4475256, lng: 17.7039621 },
    { lat: 48.4491105, lng: 17.7043198 },
    { lat: 48.4500462, lng: 17.7044244 },
    { lat: 48.4516828, lng: 17.7044334 },
    { lat: 48.4528118, lng: 17.7046119 },
    { lat: 48.4536258, lng: 17.7047572 },
    { lat: 48.4547112, lng: 17.7051969 },
    { lat: 48.4550549, lng: 17.7051343 },
    { lat: 48.4552365, lng: 17.7050231 },
    { lat: 48.4553901, lng: 17.7048759 },
    { lat: 48.455857, lng: 17.7041235 },
    { lat: 48.4562899, lng: 17.7036052 },
    { lat: 48.4569092, lng: 17.7030319 },
    { lat: 48.4572187, lng: 17.7026369 },
    { lat: 48.4577406, lng: 17.7016579 },
    { lat: 48.4590955, lng: 17.6995721 },
    { lat: 48.4597239, lng: 17.6983091 },
    { lat: 48.4613862, lng: 17.695287 },
    { lat: 48.4629654, lng: 17.6928498 },
    { lat: 48.4632265, lng: 17.6923274 },
    { lat: 48.4638203, lng: 17.6913839 },
    { lat: 48.4654407, lng: 17.689358 },
    { lat: 48.4667002, lng: 17.6882417 },
    { lat: 48.4676549, lng: 17.6872362 },
    { lat: 48.4695429, lng: 17.6858603 },
    { lat: 48.4702535, lng: 17.6854332 },
    { lat: 48.470602, lng: 17.685286 },
    { lat: 48.470029, lng: 17.683953 },
    { lat: 48.469684, lng: 17.683153 },
    { lat: 48.468866, lng: 17.683787 },
    { lat: 48.46804, lng: 17.684372 },
    { lat: 48.467337, lng: 17.684834 },
    { lat: 48.466111, lng: 17.68558 },
    { lat: 48.46581, lng: 17.685755 },
    { lat: 48.465235, lng: 17.686143 },
    { lat: 48.464545, lng: 17.686589 },
    { lat: 48.463806, lng: 17.687071 },
    { lat: 48.4634315, lng: 17.6872698 },
    { lat: 48.462932, lng: 17.687535 },
    { lat: 48.462295, lng: 17.687876 },
    { lat: 48.461297, lng: 17.688315 },
    { lat: 48.460883, lng: 17.688481 },
    { lat: 48.45978, lng: 17.688886 },
    { lat: 48.459304, lng: 17.688825 },
    { lat: 48.459117, lng: 17.688774 },
    { lat: 48.458759, lng: 17.688571 },
    { lat: 48.458525, lng: 17.688421 },
    { lat: 48.458467, lng: 17.688379 },
    { lat: 48.458394, lng: 17.688356 },
    { lat: 48.458159, lng: 17.688287 },
    { lat: 48.458123, lng: 17.688276 },
    { lat: 48.457737, lng: 17.688263 },
    { lat: 48.457099, lng: 17.688435 },
    { lat: 48.456927, lng: 17.688503 },
    { lat: 48.456459, lng: 17.688824 },
    { lat: 48.456429, lng: 17.688858 },
    { lat: 48.456404, lng: 17.688884 },
    { lat: 48.456355, lng: 17.688938 },
    { lat: 48.456344, lng: 17.68895 },
    { lat: 48.456322, lng: 17.688974 },
    { lat: 48.45605, lng: 17.689146 },
    { lat: 48.455942, lng: 17.689235 },
    { lat: 48.455662, lng: 17.689411 },
    { lat: 48.455568, lng: 17.689439 },
    { lat: 48.455501, lng: 17.689501 },
    { lat: 48.455099, lng: 17.689714 },
    { lat: 48.454083, lng: 17.690186 },
    { lat: 48.453842, lng: 17.69019 },
    { lat: 48.453773, lng: 17.690132 },
    { lat: 48.453789, lng: 17.690107 },
    { lat: 48.452607, lng: 17.689192 },
    { lat: 48.452311, lng: 17.688955 },
    { lat: 48.451789, lng: 17.688563 },
    { lat: 48.451473, lng: 17.688287 },
    { lat: 48.45132, lng: 17.688154 },
    { lat: 48.451104, lng: 17.687944 },
    { lat: 48.450753, lng: 17.687576 },
    { lat: 48.450573, lng: 17.687456 },
    { lat: 48.450363, lng: 17.687408 },
    { lat: 48.44961, lng: 17.687299 },
    { lat: 48.449287, lng: 17.687255 },
    { lat: 48.44857, lng: 17.68717 },
    { lat: 48.448051, lng: 17.687146 },
    { lat: 48.447836, lng: 17.68715 },
    { lat: 48.447581, lng: 17.687124 },
    { lat: 48.447006, lng: 17.687059 },
    { lat: 48.446363, lng: 17.686768 },
    { lat: 48.446312, lng: 17.686776 },
    { lat: 48.446238, lng: 17.68679 },
    { lat: 48.446176, lng: 17.686802 },
    { lat: 48.446157, lng: 17.686806 },
    { lat: 48.445977, lng: 17.686594 },
    { lat: 48.445667, lng: 17.686146 },
    { lat: 48.444706, lng: 17.68465 },
    { lat: 48.444381, lng: 17.684158 },
    { lat: 48.443963, lng: 17.683543 },
    { lat: 48.443808, lng: 17.683312 },
    { lat: 48.443306, lng: 17.683661 },
    { lat: 48.443192, lng: 17.683765 },
    { lat: 48.443009, lng: 17.683893 },
    { lat: 48.442339, lng: 17.684418 },
    { lat: 48.441272, lng: 17.685261 },
    { lat: 48.440748, lng: 17.685679 },
    { lat: 48.440268, lng: 17.68605 },
    { lat: 48.439893, lng: 17.686252 },
    { lat: 48.439533, lng: 17.686458 },
    { lat: 48.439356, lng: 17.686573 },
    { lat: 48.438269, lng: 17.687338 },
    { lat: 48.436928, lng: 17.688064 },
    { lat: 48.436611, lng: 17.688216 },
    { lat: 48.436522, lng: 17.68826 },
    { lat: 48.436265, lng: 17.688386 },
    { lat: 48.436133, lng: 17.688396 },
    { lat: 48.435895, lng: 17.688522 },
    { lat: 48.435752, lng: 17.688195 },
    { lat: 48.435139, lng: 17.688386 },
    { lat: 48.434254, lng: 17.688621 },
    { lat: 48.43394, lng: 17.688737 },
    { lat: 48.433436, lng: 17.68891 },
    { lat: 48.433214, lng: 17.689181 },
    { lat: 48.432966, lng: 17.68938 },
    { lat: 48.432854, lng: 17.689514 },
    { lat: 48.43282, lng: 17.689538 },
    { lat: 48.432729, lng: 17.689598 },
    { lat: 48.432634, lng: 17.689601 },
  ],
  Pastuchov: [
    { lat: 48.424495, lng: 17.919697 },
    { lat: 48.424647, lng: 17.919829 },
    { lat: 48.424865, lng: 17.919966 },
    { lat: 48.424971, lng: 17.919978 },
    { lat: 48.425376, lng: 17.919981 },
    { lat: 48.425696, lng: 17.919963 },
    { lat: 48.425872, lng: 17.919965 },
    { lat: 48.425915, lng: 17.919974 },
    { lat: 48.4260927, lng: 17.9190763 },
    { lat: 48.4263205, lng: 17.9183231 },
    { lat: 48.4266373, lng: 17.9177557 },
    { lat: 48.426736, lng: 17.9171647 },
    { lat: 48.4270627, lng: 17.9162171 },
    { lat: 48.4278585, lng: 17.9151522 },
    { lat: 48.4284018, lng: 17.9145959 },
    { lat: 48.4286183, lng: 17.9144793 },
    { lat: 48.4290335, lng: 17.9144213 },
    { lat: 48.4295097, lng: 17.9144929 },
    { lat: 48.4296325, lng: 17.9144509 },
    { lat: 48.4299453, lng: 17.9142284 },
    { lat: 48.4305671, lng: 17.9135725 },
    { lat: 48.4305569, lng: 17.9136826 },
    { lat: 48.4309803, lng: 17.9128437 },
    { lat: 48.4313255, lng: 17.9117008 },
    { lat: 48.4314562, lng: 17.9108722 },
    { lat: 48.4314416, lng: 17.910368 },
    { lat: 48.4312487, lng: 17.9095672 },
    { lat: 48.4312272, lng: 17.9092865 },
    { lat: 48.4313058, lng: 17.9068868 },
    { lat: 48.4314012, lng: 17.9057964 },
    { lat: 48.4317848, lng: 17.9042252 },
    { lat: 48.432201, lng: 17.9032101 },
    { lat: 48.4328518, lng: 17.9014065 },
    { lat: 48.4335701, lng: 17.8983712 },
    { lat: 48.4337186, lng: 17.8972443 },
    { lat: 48.433733, lng: 17.896439 },
    { lat: 48.4336315, lng: 17.8954487 },
    { lat: 48.4335883, lng: 17.8943448 },
    { lat: 48.4333159, lng: 17.8935172 },
    { lat: 48.4330091, lng: 17.89233 },
    { lat: 48.432904, lng: 17.8915822 },
    { lat: 48.4328979, lng: 17.8912201 },
    { lat: 48.4330127, lng: 17.8893503 },
    { lat: 48.4336649, lng: 17.8870753 },
    { lat: 48.4339647, lng: 17.884418 },
    { lat: 48.4348287, lng: 17.8834409 },
    { lat: 48.4354192, lng: 17.8822869 },
    { lat: 48.4356133, lng: 17.8822112 },
    { lat: 48.4357108, lng: 17.8820518 },
    { lat: 48.4361073, lng: 17.8808823 },
    { lat: 48.4363715, lng: 17.8804572 },
    { lat: 48.4366387, lng: 17.8803193 },
    { lat: 48.4371427, lng: 17.8803487 },
    { lat: 48.4374302, lng: 17.8797661 },
    { lat: 48.4375436, lng: 17.8790659 },
    { lat: 48.4376649, lng: 17.8787013 },
    { lat: 48.437857, lng: 17.8785039 },
    { lat: 48.4381085, lng: 17.878441 },
    { lat: 48.4384121, lng: 17.8782715 },
    { lat: 48.4385569, lng: 17.8783544 },
    { lat: 48.4388159, lng: 17.8783811 },
    { lat: 48.4390279, lng: 17.8783193 },
    { lat: 48.4392367, lng: 17.8780227 },
    { lat: 48.4389492, lng: 17.8775263 },
    { lat: 48.4389791, lng: 17.877481 },
    { lat: 48.4378818, lng: 17.8744645 },
    { lat: 48.4372952, lng: 17.8736157 },
    { lat: 48.4367351, lng: 17.8723857 },
    { lat: 48.4363051, lng: 17.8711244 },
    { lat: 48.4361896, lng: 17.8711182 },
    { lat: 48.4361934, lng: 17.8704533 },
    { lat: 48.4360464, lng: 17.8694852 },
    { lat: 48.4357946, lng: 17.8684447 },
    { lat: 48.4353655, lng: 17.8670848 },
    { lat: 48.4350417, lng: 17.8649487 },
    { lat: 48.435138, lng: 17.8644354 },
    { lat: 48.4352694, lng: 17.8640177 },
    { lat: 48.4365023, lng: 17.8612366 },
    { lat: 48.4367024, lng: 17.8605358 },
    { lat: 48.4368654, lng: 17.8591512 },
    { lat: 48.436864, lng: 17.858627 },
    { lat: 48.436762, lng: 17.8580086 },
    { lat: 48.4363135, lng: 17.8561857 },
    { lat: 48.4361316, lng: 17.8537522 },
    { lat: 48.4359466, lng: 17.852661 },
    { lat: 48.4357894, lng: 17.8527285 },
    { lat: 48.435619, lng: 17.8521362 },
    { lat: 48.4352158, lng: 17.8512066 },
    { lat: 48.434925, lng: 17.8507169 },
    { lat: 48.4344112, lng: 17.8501029 },
    { lat: 48.43412, lng: 17.8495284 },
    { lat: 48.4333005, lng: 17.8489718 },
    { lat: 48.4326323, lng: 17.8487712 },
    { lat: 48.4324828, lng: 17.8487825 },
    { lat: 48.4321738, lng: 17.8489174 },
    { lat: 48.4315793, lng: 17.8493863 },
    { lat: 48.4312611, lng: 17.8497987 },
    { lat: 48.4311852, lng: 17.8504582 },
    { lat: 48.4310028, lng: 17.8509287 },
    { lat: 48.4305434, lng: 17.8517092 },
    { lat: 48.4301982, lng: 17.851971 },
    { lat: 48.4301565, lng: 17.8521214 },
    { lat: 48.4300311, lng: 17.8522147 },
    { lat: 48.4294932, lng: 17.8528908 },
    { lat: 48.4289984, lng: 17.8532528 },
    { lat: 48.4279554, lng: 17.8553127 },
    { lat: 48.4278243, lng: 17.855051 },
    { lat: 48.4274313, lng: 17.8539389 },
    { lat: 48.426867, lng: 17.854476 },
    { lat: 48.4266893, lng: 17.8545413 },
    { lat: 48.4262481, lng: 17.8545458 },
    { lat: 48.4260094, lng: 17.8546842 },
    { lat: 48.4257079, lng: 17.8549631 },
    { lat: 48.4247524, lng: 17.8556414 },
    { lat: 48.4239368, lng: 17.8564469 },
    { lat: 48.4236376, lng: 17.8566682 },
    { lat: 48.4231186, lng: 17.8572676 },
    { lat: 48.422966, lng: 17.8575431 },
    { lat: 48.4224487, lng: 17.8580765 },
    { lat: 48.4223439, lng: 17.8582665 },
    { lat: 48.422111, lng: 17.8583695 },
    { lat: 48.4216394, lng: 17.8587815 },
    { lat: 48.4210071, lng: 17.8591125 },
    { lat: 48.4199467, lng: 17.8600159 },
    { lat: 48.4192803, lng: 17.8601941 },
    { lat: 48.4188998, lng: 17.8603981 },
    { lat: 48.4186996, lng: 17.8603186 },
    { lat: 48.4186081, lng: 17.860463 },
    { lat: 48.4184602, lng: 17.8605555 },
    { lat: 48.4182789, lng: 17.8605669 },
    { lat: 48.4176499, lng: 17.860209 },
    { lat: 48.417124, lng: 17.8606931 },
    { lat: 48.4159088, lng: 17.8607989 },
    { lat: 48.4157515, lng: 17.8609193 },
    { lat: 48.4155757, lng: 17.8609605 },
    { lat: 48.4154928, lng: 17.8608425 },
    { lat: 48.4153032, lng: 17.8609193 },
    { lat: 48.4146829, lng: 17.86091 },
    { lat: 48.4144582, lng: 17.8607589 },
    { lat: 48.4142529, lng: 17.8607988 },
    { lat: 48.4140102, lng: 17.8609654 },
    { lat: 48.4138038, lng: 17.860411 },
    { lat: 48.4130018, lng: 17.8592167 },
    { lat: 48.4124242, lng: 17.8601303 },
    { lat: 48.4116328, lng: 17.8611898 },
    { lat: 48.4114044, lng: 17.8613864 },
    { lat: 48.4112482, lng: 17.861195 },
    { lat: 48.4107112, lng: 17.8614787 },
    { lat: 48.4103715, lng: 17.86133 },
    { lat: 48.4098618, lng: 17.8609157 },
    { lat: 48.4096018, lng: 17.8608522 },
    { lat: 48.4088281, lng: 17.8599171 },
    { lat: 48.4077135, lng: 17.8605597 },
    { lat: 48.4075124, lng: 17.8605191 },
    { lat: 48.4072052, lng: 17.8602756 },
    { lat: 48.4067497, lng: 17.8603239 },
    { lat: 48.4062884, lng: 17.8604789 },
    { lat: 48.4062461, lng: 17.8603945 },
    { lat: 48.4056697, lng: 17.8606999 },
    { lat: 48.4050334, lng: 17.8612243 },
    { lat: 48.4042683, lng: 17.8619883 },
    { lat: 48.4044392, lng: 17.8621656 },
    { lat: 48.4046951, lng: 17.862511 },
    { lat: 48.4050336, lng: 17.8633239 },
    { lat: 48.4053207, lng: 17.8641855 },
    { lat: 48.4053676, lng: 17.8648246 },
    { lat: 48.4055876, lng: 17.8658631 },
    { lat: 48.405568, lng: 17.8668587 },
    { lat: 48.4060918, lng: 17.8675642 },
    { lat: 48.4063259, lng: 17.8680182 },
    { lat: 48.4066319, lng: 17.8684445 },
    { lat: 48.4067686, lng: 17.8687629 },
    { lat: 48.4083845, lng: 17.868752 },
    { lat: 48.4087067, lng: 17.8688151 },
    { lat: 48.409075, lng: 17.8690637 },
    { lat: 48.4102285, lng: 17.8699679 },
    { lat: 48.4109609, lng: 17.871023 },
    { lat: 48.4114144, lng: 17.8718718 },
    { lat: 48.4116249, lng: 17.8721596 },
    { lat: 48.4118779, lng: 17.8723871 },
    { lat: 48.4109418, lng: 17.8735167 },
    { lat: 48.4100572, lng: 17.8750488 },
    { lat: 48.4094598, lng: 17.8754913 },
    { lat: 48.4079823, lng: 17.8774413 },
    { lat: 48.4071708, lng: 17.8784339 },
    { lat: 48.4063465, lng: 17.8793188 },
    { lat: 48.4056903, lng: 17.879873 },
    { lat: 48.4047697, lng: 17.8805121 },
    { lat: 48.4039817, lng: 17.8813493 },
    { lat: 48.4036784, lng: 17.8814651 },
    { lat: 48.4036865, lng: 17.8815094 },
    { lat: 48.4032963, lng: 17.8815497 },
    { lat: 48.4027985, lng: 17.881459 },
    { lat: 48.4020289, lng: 17.881575 },
    { lat: 48.4016528, lng: 17.8817963 },
    { lat: 48.4005406, lng: 17.8820312 },
    { lat: 48.4003019, lng: 17.88219 },
    { lat: 48.3990042, lng: 17.8834621 },
    { lat: 48.3979515, lng: 17.8849421 },
    { lat: 48.3971897, lng: 17.8863021 },
    { lat: 48.3963845, lng: 17.8880309 },
    { lat: 48.3955252, lng: 17.8894211 },
    { lat: 48.3947857, lng: 17.8907988 },
    { lat: 48.3946307, lng: 17.8911833 },
    { lat: 48.3951065, lng: 17.8918259 },
    { lat: 48.395493, lng: 17.8924852 },
    { lat: 48.3955592, lng: 17.8928705 },
    { lat: 48.3955069, lng: 17.8939283 },
    { lat: 48.3953836, lng: 17.8950015 },
    { lat: 48.3952736, lng: 17.8954627 },
    { lat: 48.3946367, lng: 17.8976361 },
    { lat: 48.3941659, lng: 17.8989794 },
    { lat: 48.393848, lng: 17.900282 },
    { lat: 48.394183, lng: 17.900479 },
    { lat: 48.394291, lng: 17.9005454 },
    { lat: 48.394466, lng: 17.900653 },
    { lat: 48.394477, lng: 17.900629 },
    { lat: 48.395044, lng: 17.900855 },
    { lat: 48.3951223, lng: 17.9008807 },
    { lat: 48.395339, lng: 17.900952 },
    { lat: 48.395579, lng: 17.901183 },
    { lat: 48.395867, lng: 17.901467 },
    { lat: 48.395906, lng: 17.901882 },
    { lat: 48.395941, lng: 17.902275 },
    { lat: 48.39598, lng: 17.902481 },
    { lat: 48.396002, lng: 17.902682 },
    { lat: 48.396051, lng: 17.902866 },
    { lat: 48.396071, lng: 17.903138 },
    { lat: 48.396121, lng: 17.90323 },
    { lat: 48.396197, lng: 17.903466 },
    { lat: 48.396244, lng: 17.903526 },
    { lat: 48.396265, lng: 17.903605 },
    { lat: 48.396271, lng: 17.904062 },
    { lat: 48.396344, lng: 17.904412 },
    { lat: 48.396406, lng: 17.904601 },
    { lat: 48.3964, lng: 17.904601 },
    { lat: 48.396428, lng: 17.904669 },
    { lat: 48.396667, lng: 17.904334 },
    { lat: 48.397034, lng: 17.904417 },
    { lat: 48.39737, lng: 17.904548 },
    { lat: 48.3974836, lng: 17.9046 },
    { lat: 48.397545, lng: 17.904628 },
    { lat: 48.397865, lng: 17.904766 },
    { lat: 48.39817, lng: 17.904902 },
    { lat: 48.398478, lng: 17.905037 },
    { lat: 48.398523, lng: 17.905116 },
    { lat: 48.398523, lng: 17.905155 },
    { lat: 48.399114, lng: 17.90536 },
    { lat: 48.399639, lng: 17.905551 },
    { lat: 48.40033, lng: 17.905465 },
    { lat: 48.400589, lng: 17.905491 },
    { lat: 48.400852, lng: 17.905501 },
    { lat: 48.401258, lng: 17.905602 },
    { lat: 48.40154, lng: 17.905694 },
    { lat: 48.401825, lng: 17.90582 },
    { lat: 48.402176, lng: 17.905986 },
    { lat: 48.402546, lng: 17.906157 },
    { lat: 48.402879, lng: 17.906333 },
    { lat: 48.403271, lng: 17.906488 },
    { lat: 48.403662, lng: 17.906654 },
    { lat: 48.404128, lng: 17.906831 },
    { lat: 48.404273, lng: 17.906884 },
    { lat: 48.404657, lng: 17.907044 },
    { lat: 48.404965, lng: 17.907159 },
    { lat: 48.405459, lng: 17.907367 },
    { lat: 48.405962, lng: 17.907585 },
    { lat: 48.406463, lng: 17.90773 },
    { lat: 48.406738, lng: 17.907834 },
    { lat: 48.407178, lng: 17.908023 },
    { lat: 48.407623, lng: 17.908238 },
    { lat: 48.407933, lng: 17.908404 },
    { lat: 48.408309, lng: 17.90862 },
    { lat: 48.40869, lng: 17.908818 },
    { lat: 48.408895, lng: 17.908932 },
    { lat: 48.409066, lng: 17.909032 },
    { lat: 48.40925, lng: 17.909148 },
    { lat: 48.409467, lng: 17.909292 },
    { lat: 48.409474, lng: 17.90928 },
    { lat: 48.409555, lng: 17.90937 },
    { lat: 48.409644, lng: 17.909393 },
    { lat: 48.409736, lng: 17.909396 },
    { lat: 48.410077, lng: 17.909751 },
    { lat: 48.410373, lng: 17.910044 },
    { lat: 48.410763, lng: 17.910459 },
    { lat: 48.41086, lng: 17.910551 },
    { lat: 48.410977, lng: 17.910724 },
    { lat: 48.411258, lng: 17.911008 },
    { lat: 48.411563, lng: 17.911433 },
    { lat: 48.411766, lng: 17.911618 },
    { lat: 48.41179, lng: 17.911612 },
    { lat: 48.412271, lng: 17.912129 },
    { lat: 48.412451, lng: 17.912335 },
    { lat: 48.412888, lng: 17.912719 },
    { lat: 48.413073, lng: 17.912986 },
    { lat: 48.413507, lng: 17.913335 },
    { lat: 48.413543, lng: 17.913332 },
    { lat: 48.413754, lng: 17.913576 },
    { lat: 48.414301, lng: 17.914008 },
    { lat: 48.414564, lng: 17.914192 },
    { lat: 48.414867, lng: 17.91444 },
    { lat: 48.415235, lng: 17.914689 },
    { lat: 48.415332, lng: 17.91483 },
    { lat: 48.415547, lng: 17.914398 },
    { lat: 48.415824, lng: 17.914333 },
    { lat: 48.416592, lng: 17.914962 },
    { lat: 48.417227, lng: 17.915465 },
    { lat: 48.41762, lng: 17.915864 },
    { lat: 48.417937, lng: 17.916288 },
    { lat: 48.41846, lng: 17.91654 },
    { lat: 48.418658, lng: 17.916822 },
    { lat: 48.418847, lng: 17.917282 },
    { lat: 48.419388, lng: 17.917902 },
    { lat: 48.419832, lng: 17.918051 },
    { lat: 48.419919, lng: 17.917995 },
    { lat: 48.420025, lng: 17.917744 },
    { lat: 48.420293, lng: 17.917428 },
    { lat: 48.420611, lng: 17.917214 },
    { lat: 48.420966, lng: 17.917412 },
    { lat: 48.421183, lng: 17.917648 },
    { lat: 48.421348, lng: 17.917984 },
    { lat: 48.421425, lng: 17.918081 },
    { lat: 48.421774, lng: 17.918511 },
    { lat: 48.422123, lng: 17.918928 },
    { lat: 48.422298, lng: 17.919226 },
    { lat: 48.422576, lng: 17.91959 },
    { lat: 48.422821, lng: 17.919871 },
    { lat: 48.422952, lng: 17.920032 },
    { lat: 48.423111, lng: 17.919978 },
    { lat: 48.42338, lng: 17.919976 },
    { lat: 48.423665, lng: 17.919912 },
    { lat: 48.42388, lng: 17.919859 },
    { lat: 48.424125, lng: 17.919752 },
    { lat: 48.424295, lng: 17.919688 },
    { lat: 48.424495, lng: 17.919697 },
  ],
  Bojničky: [
    { lat: 48.382239, lng: 17.7760624 },
    { lat: 48.3820517, lng: 17.7764123 },
    { lat: 48.3803371, lng: 17.7796435 },
    { lat: 48.3792991, lng: 17.7805511 },
    { lat: 48.3784571, lng: 17.7816542 },
    { lat: 48.3777767, lng: 17.7827067 },
    { lat: 48.3776564, lng: 17.7831187 },
    { lat: 48.3777347, lng: 17.7842406 },
    { lat: 48.3776919, lng: 17.7849795 },
    { lat: 48.3770552, lng: 17.7860426 },
    { lat: 48.377064, lng: 17.7860591 },
    { lat: 48.3774483, lng: 17.7865786 },
    { lat: 48.3776271, lng: 17.7869171 },
    { lat: 48.3780234, lng: 17.7879623 },
    { lat: 48.3789696, lng: 17.7892295 },
    { lat: 48.3798447, lng: 17.7918576 },
    { lat: 48.3800042, lng: 17.7924502 },
    { lat: 48.3799685, lng: 17.792513 },
    { lat: 48.3802024, lng: 17.792862 },
    { lat: 48.3807442, lng: 17.7940203 },
    { lat: 48.3812054, lng: 17.7948733 },
    { lat: 48.3816898, lng: 17.7962996 },
    { lat: 48.3819209, lng: 17.79698 },
    { lat: 48.3819824, lng: 17.7970638 },
    { lat: 48.381007, lng: 17.7971175 },
    { lat: 48.3809352, lng: 17.798098 },
    { lat: 48.380964, lng: 17.7981083 },
    { lat: 48.3807488, lng: 17.7995845 },
    { lat: 48.3798877, lng: 17.7996806 },
    { lat: 48.3798451, lng: 17.8002742 },
    { lat: 48.379602, lng: 17.8010039 },
    { lat: 48.3796001, lng: 17.8012311 },
    { lat: 48.379308, lng: 17.8018593 },
    { lat: 48.3790936, lng: 17.8021741 },
    { lat: 48.3787351, lng: 17.8024428 },
    { lat: 48.3776147, lng: 17.8029132 },
    { lat: 48.3771645, lng: 17.803175 },
    { lat: 48.3772134, lng: 17.804502 },
    { lat: 48.3775769, lng: 17.806028 },
    { lat: 48.3763376, lng: 17.8074519 },
    { lat: 48.3767445, lng: 17.8085429 },
    { lat: 48.3760082, lng: 17.8116303 },
    { lat: 48.3756588, lng: 17.8126327 },
    { lat: 48.3755728, lng: 17.8135682 },
    { lat: 48.375727, lng: 17.8143014 },
    { lat: 48.3731811, lng: 17.8161387 },
    { lat: 48.3734857, lng: 17.817627 },
    { lat: 48.373232, lng: 17.8179461 },
    { lat: 48.3733297, lng: 17.8183626 },
    { lat: 48.3718478, lng: 17.8200989 },
    { lat: 48.3719624, lng: 17.8206994 },
    { lat: 48.371864, lng: 17.8218204 },
    { lat: 48.3720521, lng: 17.8218324 },
    { lat: 48.372879, lng: 17.8218756 },
    { lat: 48.3736032, lng: 17.8221627 },
    { lat: 48.3750162, lng: 17.8225423 },
    { lat: 48.3765123, lng: 17.823324 },
    { lat: 48.3772251, lng: 17.8235126 },
    { lat: 48.3774183, lng: 17.8236283 },
    { lat: 48.3788311, lng: 17.8251862 },
    { lat: 48.3792665, lng: 17.8258058 },
    { lat: 48.3809196, lng: 17.8265682 },
    { lat: 48.3816639, lng: 17.8273303 },
    { lat: 48.3819354, lng: 17.8275226 },
    { lat: 48.3827192, lng: 17.82784 },
    { lat: 48.3833693, lng: 17.8281886 },
    { lat: 48.3838746, lng: 17.8287532 },
    { lat: 48.3840363, lng: 17.8290725 },
    { lat: 48.3840425, lng: 17.8290458 },
    { lat: 48.3840674, lng: 17.8283637 },
    { lat: 48.3843992, lng: 17.8274958 },
    { lat: 48.3853249, lng: 17.8274761 },
    { lat: 48.3857964, lng: 17.827101 },
    { lat: 48.3867181, lng: 17.8267199 },
    { lat: 48.3873608, lng: 17.8261731 },
    { lat: 48.3878808, lng: 17.8255939 },
    { lat: 48.3886491, lng: 17.8250862 },
    { lat: 48.3897883, lng: 17.8247028 },
    { lat: 48.3908219, lng: 17.8244721 },
    { lat: 48.3915061, lng: 17.8236648 },
    { lat: 48.3918536, lng: 17.8236414 },
    { lat: 48.3922059, lng: 17.8235142 },
    { lat: 48.392467, lng: 17.8233166 },
    { lat: 48.3931516, lng: 17.8226389 },
    { lat: 48.393725, lng: 17.8219743 },
    { lat: 48.3947587, lng: 17.8214708 },
    { lat: 48.3954169, lng: 17.8213248 },
    { lat: 48.3957323, lng: 17.8210898 },
    { lat: 48.3960033, lng: 17.8207796 },
    { lat: 48.3965421, lng: 17.8204871 },
    { lat: 48.3971626, lng: 17.8198927 },
    { lat: 48.3978616, lng: 17.8190111 },
    { lat: 48.3985695, lng: 17.8183485 },
    { lat: 48.4010151, lng: 17.8164891 },
    { lat: 48.4012923, lng: 17.8163336 },
    { lat: 48.4014437, lng: 17.8161333 },
    { lat: 48.4018509, lng: 17.8158141 },
    { lat: 48.402133, lng: 17.8153183 },
    { lat: 48.4031254, lng: 17.8145811 },
    { lat: 48.4034269, lng: 17.8140654 },
    { lat: 48.4036379, lng: 17.813826 },
    { lat: 48.4037794, lng: 17.8135563 },
    { lat: 48.4038523, lng: 17.813251 },
    { lat: 48.4040281, lng: 17.8129506 },
    { lat: 48.4046201, lng: 17.8124087 },
    { lat: 48.4035963, lng: 17.8106765 },
    { lat: 48.4027556, lng: 17.8088259 },
    { lat: 48.4023992, lng: 17.8083679 },
    { lat: 48.401108, lng: 17.8070107 },
    { lat: 48.4020533, lng: 17.8054165 },
    { lat: 48.4024108, lng: 17.8053557 },
    { lat: 48.4013301, lng: 17.8036592 },
    { lat: 48.4017279, lng: 17.803186 },
    { lat: 48.4036401, lng: 17.8012323 },
    { lat: 48.4040991, lng: 17.8005093 },
    { lat: 48.4041409, lng: 17.800307 },
    { lat: 48.4047551, lng: 17.7992026 },
    { lat: 48.4048547, lng: 17.7988605 },
    { lat: 48.4051464, lng: 17.7973229 },
    { lat: 48.4054632, lng: 17.7966334 },
    { lat: 48.4057123, lng: 17.7962063 },
    { lat: 48.406, lng: 17.7958534 },
    { lat: 48.4075321, lng: 17.7941637 },
    { lat: 48.4079826, lng: 17.793429 },
    { lat: 48.4085377, lng: 17.7919723 },
    { lat: 48.408684, lng: 17.7913422 },
    { lat: 48.408808, lng: 17.7910273 },
    { lat: 48.4088173, lng: 17.7907748 },
    { lat: 48.4088065, lng: 17.7905403 },
    { lat: 48.4066466, lng: 17.7905365 },
    { lat: 48.406458, lng: 17.790483 },
    { lat: 48.4057166, lng: 17.7901012 },
    { lat: 48.4053289, lng: 17.7899677 },
    { lat: 48.4034072, lng: 17.7887527 },
    { lat: 48.4032333, lng: 17.7885669 },
    { lat: 48.4029536, lng: 17.7885733 },
    { lat: 48.4025834, lng: 17.7886826 },
    { lat: 48.4003747, lng: 17.7883815 },
    { lat: 48.3989403, lng: 17.78793 },
    { lat: 48.3981719, lng: 17.7876465 },
    { lat: 48.397783, lng: 17.7873811 },
    { lat: 48.3975183, lng: 17.7870427 },
    { lat: 48.3971487, lng: 17.7867568 },
    { lat: 48.3972096, lng: 17.7853625 },
    { lat: 48.3973206, lng: 17.7842867 },
    { lat: 48.3971473, lng: 17.7825704 },
    { lat: 48.3971532, lng: 17.7818065 },
    { lat: 48.396815, lng: 17.7811156 },
    { lat: 48.3964475, lng: 17.7781563 },
    { lat: 48.3960779, lng: 17.7782947 },
    { lat: 48.3958931, lng: 17.7784472 },
    { lat: 48.3952878, lng: 17.7785568 },
    { lat: 48.394813, lng: 17.778492 },
    { lat: 48.3940442, lng: 17.7787303 },
    { lat: 48.3930169, lng: 17.7785639 },
    { lat: 48.3922921, lng: 17.7785648 },
    { lat: 48.3911456, lng: 17.7786643 },
    { lat: 48.3906947, lng: 17.7788214 },
    { lat: 48.3896778, lng: 17.7790312 },
    { lat: 48.3885989, lng: 17.7788184 },
    { lat: 48.3874276, lng: 17.7783883 },
    { lat: 48.3866737, lng: 17.7780788 },
    { lat: 48.3843859, lng: 17.776864 },
    { lat: 48.3837233, lng: 17.7763307 },
    { lat: 48.3824502, lng: 17.7756619 },
    { lat: 48.382239, lng: 17.7760624 },
  ],
  Jalšové: [
    { lat: 48.507628, lng: 17.868953 },
    { lat: 48.507693, lng: 17.868583 },
    { lat: 48.507687, lng: 17.868313 },
    { lat: 48.507653, lng: 17.867791 },
    { lat: 48.507667, lng: 17.867555 },
    { lat: 48.507667, lng: 17.867421 },
    { lat: 48.507728, lng: 17.866768 },
    { lat: 48.50779, lng: 17.866537 },
    { lat: 48.507886, lng: 17.86633 },
    { lat: 48.507977, lng: 17.866046 },
    { lat: 48.508069, lng: 17.865851 },
    { lat: 48.50816, lng: 17.865644 },
    { lat: 48.508254, lng: 17.86556 },
    { lat: 48.508287, lng: 17.86543 },
    { lat: 48.508561, lng: 17.864985 },
    { lat: 48.508659, lng: 17.864642 },
    { lat: 48.50874, lng: 17.864518 },
    { lat: 48.508972, lng: 17.864291 },
    { lat: 48.509038, lng: 17.864178 },
    { lat: 48.50913, lng: 17.864053 },
    { lat: 48.509229, lng: 17.863839 },
    { lat: 48.509264, lng: 17.863704 },
    { lat: 48.509266, lng: 17.863539 },
    { lat: 48.509285, lng: 17.863469 },
    { lat: 48.50935, lng: 17.863344 },
    { lat: 48.509387, lng: 17.863085 },
    { lat: 48.509421, lng: 17.862867 },
    { lat: 48.509465, lng: 17.862684 },
    { lat: 48.509489, lng: 17.862161 },
    { lat: 48.509397, lng: 17.861458 },
    { lat: 48.509298, lng: 17.861131 },
    { lat: 48.509288, lng: 17.860955 },
    { lat: 48.50933, lng: 17.860843 },
    { lat: 48.509519, lng: 17.860598 },
    { lat: 48.509607, lng: 17.860374 },
    { lat: 48.509687, lng: 17.860249 },
    { lat: 48.509729, lng: 17.860054 },
    { lat: 48.509911, lng: 17.859546 },
    { lat: 48.510218, lng: 17.85883 },
    { lat: 48.510285, lng: 17.858535 },
    { lat: 48.510377, lng: 17.858375 },
    { lat: 48.510447, lng: 17.858321 },
    { lat: 48.510723, lng: 17.85821 },
    { lat: 48.510947, lng: 17.857971 },
    { lat: 48.511124, lng: 17.857756 },
    { lat: 48.511422, lng: 17.857339 },
    { lat: 48.511576, lng: 17.857243 },
    { lat: 48.511996, lng: 17.856566 },
    { lat: 48.512394, lng: 17.856112 },
    { lat: 48.51266, lng: 17.855872 },
    { lat: 48.512888, lng: 17.855527 },
    { lat: 48.513039, lng: 17.855431 },
    { lat: 48.513548, lng: 17.854752 },
    { lat: 48.513726, lng: 17.854607 },
    { lat: 48.513776, lng: 17.854542 },
    { lat: 48.513833, lng: 17.854406 },
    { lat: 48.513984, lng: 17.85425 },
    { lat: 48.514181, lng: 17.854006 },
    { lat: 48.514412, lng: 17.853655 },
    { lat: 48.514624, lng: 17.853346 },
    { lat: 48.514716, lng: 17.853109 },
    { lat: 48.514804, lng: 17.852937 },
    { lat: 48.514833, lng: 17.852737 },
    { lat: 48.514874, lng: 17.85249 },
    { lat: 48.514881, lng: 17.852266 },
    { lat: 48.514873, lng: 17.85182 },
    { lat: 48.514907, lng: 17.85135 },
    { lat: 48.514877, lng: 17.850904 },
    { lat: 48.514857, lng: 17.85091 },
    { lat: 48.514601, lng: 17.850046 },
    { lat: 48.51462, lng: 17.850028 },
    { lat: 48.514569, lng: 17.849947 },
    { lat: 48.514402, lng: 17.849574 },
    { lat: 48.514297, lng: 17.849182 },
    { lat: 48.514273, lng: 17.849007 },
    { lat: 48.514267, lng: 17.848854 },
    { lat: 48.514309, lng: 17.848695 },
    { lat: 48.514275, lng: 17.848413 },
    { lat: 48.514232, lng: 17.847921 },
    { lat: 48.514246, lng: 17.847703 },
    { lat: 48.514257, lng: 17.847621 },
    { lat: 48.514251, lng: 17.847386 },
    { lat: 48.514345, lng: 17.847009 },
    { lat: 48.514302, lng: 17.846886 },
    { lat: 48.514242, lng: 17.84674 },
    { lat: 48.514155, lng: 17.846613 },
    { lat: 48.514076, lng: 17.846502 },
    { lat: 48.514056, lng: 17.846509 },
    { lat: 48.514024, lng: 17.846468 },
    { lat: 48.514126, lng: 17.846349 },
    { lat: 48.514319, lng: 17.846228 },
    { lat: 48.514416, lng: 17.846173 },
    { lat: 48.514594, lng: 17.846059 },
    { lat: 48.514699, lng: 17.845986 },
    { lat: 48.515105, lng: 17.84562 },
    { lat: 48.515183, lng: 17.845554 },
    { lat: 48.515774, lng: 17.845355 },
    { lat: 48.515871, lng: 17.845353 },
    { lat: 48.516023, lng: 17.845351 },
    { lat: 48.516066, lng: 17.845326 },
    { lat: 48.516116, lng: 17.84522 },
    { lat: 48.516327, lng: 17.844858 },
    { lat: 48.51635, lng: 17.844822 },
    { lat: 48.516659, lng: 17.844939 },
    { lat: 48.516837, lng: 17.845153 },
    { lat: 48.517003, lng: 17.84515 },
    { lat: 48.517253, lng: 17.844594 },
    { lat: 48.517334, lng: 17.844615 },
    { lat: 48.517498, lng: 17.844659 },
    { lat: 48.51753, lng: 17.844653 },
    { lat: 48.517554, lng: 17.844676 },
    { lat: 48.517802, lng: 17.844869 },
    { lat: 48.517918, lng: 17.84509 },
    { lat: 48.51809, lng: 17.845132 },
    { lat: 48.518227, lng: 17.845264 },
    { lat: 48.518287, lng: 17.845415 },
    { lat: 48.518445, lng: 17.845553 },
    { lat: 48.51859, lng: 17.845614 },
    { lat: 48.518741, lng: 17.845616 },
    { lat: 48.51896, lng: 17.845664 },
    { lat: 48.519058, lng: 17.845703 },
    { lat: 48.519175, lng: 17.845782 },
    { lat: 48.519246, lng: 17.845833 },
    { lat: 48.519325, lng: 17.845908 },
    { lat: 48.519443, lng: 17.84604 },
    { lat: 48.519538, lng: 17.846138 },
    { lat: 48.519679, lng: 17.846281 },
    { lat: 48.519796, lng: 17.846338 },
    { lat: 48.519962, lng: 17.846439 },
    { lat: 48.519985, lng: 17.846468 },
    { lat: 48.520084, lng: 17.846612 },
    { lat: 48.520212, lng: 17.846891 },
    { lat: 48.520287, lng: 17.846995 },
    { lat: 48.520309, lng: 17.846977 },
    { lat: 48.520476, lng: 17.846797 },
    { lat: 48.520633, lng: 17.846658 },
    { lat: 48.521179, lng: 17.845947 },
    { lat: 48.52126, lng: 17.845846 },
    { lat: 48.521321, lng: 17.845757 },
    { lat: 48.521464, lng: 17.845595 },
    { lat: 48.521522, lng: 17.845564 },
    { lat: 48.521565, lng: 17.845534 },
    { lat: 48.521519, lng: 17.84549 },
    { lat: 48.520991, lng: 17.844854 },
    { lat: 48.520836, lng: 17.844537 },
    { lat: 48.520841, lng: 17.844086 },
    { lat: 48.520762, lng: 17.843788 },
    { lat: 48.520579, lng: 17.843368 },
    { lat: 48.520276, lng: 17.843045 },
    { lat: 48.520094, lng: 17.842833 },
    { lat: 48.519871, lng: 17.842758 },
    { lat: 48.51932, lng: 17.842371 },
    { lat: 48.519589, lng: 17.841451 },
    { lat: 48.519791, lng: 17.840826 },
    { lat: 48.519884, lng: 17.840535 },
    { lat: 48.52009, lng: 17.839978 },
    { lat: 48.520232, lng: 17.839572 },
    { lat: 48.520444, lng: 17.838845 },
    { lat: 48.520869, lng: 17.83832 },
    { lat: 48.520994, lng: 17.837546 },
    { lat: 48.521474, lng: 17.837816 },
    { lat: 48.521522, lng: 17.836933 },
    { lat: 48.521569, lng: 17.836057 },
    { lat: 48.521541, lng: 17.835578 },
    { lat: 48.521691, lng: 17.835154 },
    { lat: 48.521772, lng: 17.835017 },
    { lat: 48.52179, lng: 17.834987 },
    { lat: 48.522002, lng: 17.834624 },
    { lat: 48.521872, lng: 17.834241 },
    { lat: 48.521705, lng: 17.83375 },
    { lat: 48.521803, lng: 17.833705 },
    { lat: 48.521545, lng: 17.833008 },
    { lat: 48.521468, lng: 17.832812 },
    { lat: 48.521465, lng: 17.832768 },
    { lat: 48.521448, lng: 17.832552 },
    { lat: 48.521321, lng: 17.832336 },
    { lat: 48.521166, lng: 17.832003 },
    { lat: 48.521091, lng: 17.831791 },
    { lat: 48.52074, lng: 17.831931 },
    { lat: 48.5206, lng: 17.831737 },
    { lat: 48.52052, lng: 17.831588 },
    { lat: 48.520288, lng: 17.8309 },
    { lat: 48.520234, lng: 17.830509 },
    { lat: 48.519804, lng: 17.830237 },
    { lat: 48.519736, lng: 17.829342 },
    { lat: 48.519727, lng: 17.82927 },
    { lat: 48.519672, lng: 17.828645 },
    { lat: 48.519633, lng: 17.828319 },
    { lat: 48.51962, lng: 17.828113 },
    { lat: 48.519548, lng: 17.827759 },
    { lat: 48.519498, lng: 17.827589 },
    { lat: 48.519372, lng: 17.827371 },
    { lat: 48.519293, lng: 17.827239 },
    { lat: 48.519194, lng: 17.827133 },
    { lat: 48.519208, lng: 17.82702 },
    { lat: 48.519055, lng: 17.826906 },
    { lat: 48.518985, lng: 17.826781 },
    { lat: 48.518813, lng: 17.826472 },
    { lat: 48.518546, lng: 17.825985 },
    { lat: 48.51856, lng: 17.825923 },
    { lat: 48.518672, lng: 17.825424 },
    { lat: 48.518901, lng: 17.824706 },
    { lat: 48.519172, lng: 17.823862 },
    { lat: 48.519342, lng: 17.823519 },
    { lat: 48.519375, lng: 17.823455 },
    { lat: 48.519404, lng: 17.823396 },
    { lat: 48.51941, lng: 17.823383 },
    { lat: 48.519416, lng: 17.823371 },
    { lat: 48.519421, lng: 17.823361 },
    { lat: 48.519462, lng: 17.823274 },
    { lat: 48.519564, lng: 17.823056 },
    { lat: 48.519651, lng: 17.822872 },
    { lat: 48.519775, lng: 17.822613 },
    { lat: 48.519568, lng: 17.822369 },
    { lat: 48.519087, lng: 17.821799 },
    { lat: 48.518725, lng: 17.821364 },
    { lat: 48.51841, lng: 17.820979 },
    { lat: 48.518261, lng: 17.820796 },
    { lat: 48.518155, lng: 17.820667 },
    { lat: 48.517967, lng: 17.820438 },
    { lat: 48.517866, lng: 17.820315 },
    { lat: 48.517492, lng: 17.819856 },
    { lat: 48.517292, lng: 17.819614 },
    { lat: 48.517239, lng: 17.819552 },
    { lat: 48.516938, lng: 17.819169 },
    { lat: 48.516711, lng: 17.818877 },
    { lat: 48.516448, lng: 17.818544 },
    { lat: 48.516047, lng: 17.818036 },
    { lat: 48.515882, lng: 17.817822 },
    { lat: 48.515749, lng: 17.817663 },
    { lat: 48.515325, lng: 17.81781 },
    { lat: 48.515263, lng: 17.81783 },
    { lat: 48.515014, lng: 17.817882 },
    { lat: 48.514665, lng: 17.817954 },
    { lat: 48.514088, lng: 17.818066 },
    { lat: 48.514025, lng: 17.818078 },
    { lat: 48.513927, lng: 17.818101 },
    { lat: 48.513601, lng: 17.818178 },
    { lat: 48.513531, lng: 17.818195 },
    { lat: 48.513239, lng: 17.818258 },
    { lat: 48.512859, lng: 17.818341 },
    { lat: 48.512222, lng: 17.818463 },
    { lat: 48.512151, lng: 17.818487 },
    { lat: 48.511656, lng: 17.81866 },
    { lat: 48.511573, lng: 17.818687 },
    { lat: 48.511296, lng: 17.818777 },
    { lat: 48.51081, lng: 17.81895 },
    { lat: 48.510724, lng: 17.81898 },
    { lat: 48.510043, lng: 17.819213 },
    { lat: 48.509694, lng: 17.819326 },
    { lat: 48.509646, lng: 17.819341 },
    { lat: 48.508975, lng: 17.819565 },
    { lat: 48.508908, lng: 17.819584 },
    { lat: 48.508838, lng: 17.819604 },
    { lat: 48.508632, lng: 17.819663 },
    { lat: 48.508598, lng: 17.819673 },
    { lat: 48.508321, lng: 17.819821 },
    { lat: 48.507839, lng: 17.82007 },
    { lat: 48.507811, lng: 17.820085 },
    { lat: 48.507342, lng: 17.820318 },
    { lat: 48.506823, lng: 17.820536 },
    { lat: 48.506389, lng: 17.820708 },
    { lat: 48.505888, lng: 17.820912 },
    { lat: 48.50572, lng: 17.820973 },
    { lat: 48.505709, lng: 17.820977 },
    { lat: 48.505539, lng: 17.821048 },
    { lat: 48.505311, lng: 17.821142 },
    { lat: 48.504923, lng: 17.821305 },
    { lat: 48.50452, lng: 17.82147 },
    { lat: 48.503757, lng: 17.821792 },
    { lat: 48.503692, lng: 17.821827 },
    { lat: 48.503676, lng: 17.82183 },
    { lat: 48.503661, lng: 17.821782 },
    { lat: 48.503627, lng: 17.82168 },
    { lat: 48.503461, lng: 17.821168 },
    { lat: 48.503348, lng: 17.820858 },
    { lat: 48.503114, lng: 17.820219 },
    { lat: 48.502798, lng: 17.819345 },
    { lat: 48.502747, lng: 17.819142 },
    { lat: 48.502565, lng: 17.818445 },
    { lat: 48.50237, lng: 17.817685 },
    { lat: 48.502154, lng: 17.816849 },
    { lat: 48.502071, lng: 17.816527 },
    { lat: 48.502019, lng: 17.816323 },
    { lat: 48.501915, lng: 17.815902 },
    { lat: 48.501833, lng: 17.815592 },
    { lat: 48.501772, lng: 17.815352 },
    { lat: 48.501715, lng: 17.815129 },
    { lat: 48.501564, lng: 17.814503 },
    { lat: 48.501395, lng: 17.813812 },
    { lat: 48.501251, lng: 17.813229 },
    { lat: 48.50123, lng: 17.81314 },
    { lat: 48.501132, lng: 17.812709 },
    { lat: 48.4990969, lng: 17.8200527 },
    { lat: 48.4982071, lng: 17.8192461 },
    { lat: 48.4977366, lng: 17.8187787 },
    { lat: 48.4973916, lng: 17.8192642 },
    { lat: 48.4972376, lng: 17.8193297 },
    { lat: 48.4971759, lng: 17.8196532 },
    { lat: 48.4969448, lng: 17.8198429 },
    { lat: 48.4966476, lng: 17.8204097 },
    { lat: 48.4966644, lng: 17.8205533 },
    { lat: 48.4965082, lng: 17.8207881 },
    { lat: 48.4960853, lng: 17.8221988 },
    { lat: 48.4960926, lng: 17.8225972 },
    { lat: 48.4962298, lng: 17.8231979 },
    { lat: 48.4962321, lng: 17.8238279 },
    { lat: 48.496153, lng: 17.8239737 },
    { lat: 48.4961706, lng: 17.8240952 },
    { lat: 48.4961192, lng: 17.8244009 },
    { lat: 48.4958026, lng: 17.8247974 },
    { lat: 48.4953744, lng: 17.8251616 },
    { lat: 48.4950375, lng: 17.8256531 },
    { lat: 48.4944536, lng: 17.8268528 },
    { lat: 48.4942764, lng: 17.8273528 },
    { lat: 48.4941736, lng: 17.8274634 },
    { lat: 48.4941006, lng: 17.8276889 },
    { lat: 48.4939095, lng: 17.8279423 },
    { lat: 48.4933552, lng: 17.828961 },
    { lat: 48.4931847, lng: 17.8291091 },
    { lat: 48.492541, lng: 17.8288838 },
    { lat: 48.4917939, lng: 17.8287945 },
    { lat: 48.4913681, lng: 17.8289185 },
    { lat: 48.4908873, lng: 17.8293273 },
    { lat: 48.4907768, lng: 17.8292909 },
    { lat: 48.490597, lng: 17.8293606 },
    { lat: 48.4900879, lng: 17.8297135 },
    { lat: 48.4889298, lng: 17.8300867 },
    { lat: 48.4886882, lng: 17.8300862 },
    { lat: 48.4882798, lng: 17.8302593 },
    { lat: 48.4876682, lng: 17.8302609 },
    { lat: 48.4873176, lng: 17.830362 },
    { lat: 48.4867893, lng: 17.8302524 },
    { lat: 48.4861521, lng: 17.8302814 },
    { lat: 48.4855985, lng: 17.8306792 },
    { lat: 48.485393, lng: 17.830883 },
    { lat: 48.4850271, lng: 17.8315843 },
    { lat: 48.4845922, lng: 17.8325411 },
    { lat: 48.4843856, lng: 17.8331707 },
    { lat: 48.4840669, lng: 17.8339029 },
    { lat: 48.4834122, lng: 17.8351269 },
    { lat: 48.4832866, lng: 17.8356969 },
    { lat: 48.4831613, lng: 17.8367535 },
    { lat: 48.4830909, lng: 17.8376162 },
    { lat: 48.4831306, lng: 17.838027 },
    { lat: 48.4830613, lng: 17.838687 },
    { lat: 48.4826051, lng: 17.8388866 },
    { lat: 48.4822428, lng: 17.8393145 },
    { lat: 48.4817946, lng: 17.8392927 },
    { lat: 48.4814364, lng: 17.8392876 },
    { lat: 48.481411, lng: 17.8393338 },
    { lat: 48.4815752, lng: 17.8396592 },
    { lat: 48.4816636, lng: 17.8397508 },
    { lat: 48.4819388, lng: 17.8401218 },
    { lat: 48.4821017, lng: 17.8401669 },
    { lat: 48.482926, lng: 17.8400956 },
    { lat: 48.4835001, lng: 17.8404406 },
    { lat: 48.4843226, lng: 17.8405417 },
    { lat: 48.4846171, lng: 17.840521 },
    { lat: 48.4847543, lng: 17.8404395 },
    { lat: 48.4849915, lng: 17.8405907 },
    { lat: 48.4852024, lng: 17.8405691 },
    { lat: 48.4855804, lng: 17.8410269 },
    { lat: 48.4858532, lng: 17.8412444 },
    { lat: 48.4860761, lng: 17.8413436 },
    { lat: 48.4870586, lng: 17.8415155 },
    { lat: 48.4875497, lng: 17.8419069 },
    { lat: 48.4882134, lng: 17.8420919 },
    { lat: 48.4898439, lng: 17.8427621 },
    { lat: 48.4904313, lng: 17.8432722 },
    { lat: 48.4907616, lng: 17.8433936 },
    { lat: 48.4912173, lng: 17.843756 },
    { lat: 48.4916102, lng: 17.8443343 },
    { lat: 48.4917288, lng: 17.8446496 },
    { lat: 48.4915756, lng: 17.845895 },
    { lat: 48.4915802, lng: 17.8461374 },
    { lat: 48.4917485, lng: 17.8468082 },
    { lat: 48.49171, lng: 17.8473598 },
    { lat: 48.4917372, lng: 17.8478259 },
    { lat: 48.4916461, lng: 17.8483509 },
    { lat: 48.4916488, lng: 17.8486747 },
    { lat: 48.4916842, lng: 17.8491279 },
    { lat: 48.4918557, lng: 17.8497381 },
    { lat: 48.4917529, lng: 17.8499967 },
    { lat: 48.4915653, lng: 17.850234 },
    { lat: 48.4915077, lng: 17.8504336 },
    { lat: 48.4914915, lng: 17.8505057 },
    { lat: 48.4915585, lng: 17.8514211 },
    { lat: 48.4917091, lng: 17.8519288 },
    { lat: 48.4918568, lng: 17.8526694 },
    { lat: 48.4920083, lng: 17.8531183 },
    { lat: 48.4926503, lng: 17.8541136 },
    { lat: 48.4928976, lng: 17.8546362 },
    { lat: 48.4931147, lng: 17.8549191 },
    { lat: 48.493395, lng: 17.8554628 },
    { lat: 48.4934408, lng: 17.8555665 },
    { lat: 48.493523, lng: 17.8557048 },
    { lat: 48.4936892, lng: 17.8559558 },
    { lat: 48.4940702, lng: 17.8564137 },
    { lat: 48.4947512, lng: 17.8571055 },
    { lat: 48.4960401, lng: 17.8576232 },
    { lat: 48.4965287, lng: 17.8584333 },
    { lat: 48.4967792, lng: 17.8584683 },
    { lat: 48.497723, lng: 17.8582494 },
    { lat: 48.4984941, lng: 17.8583615 },
    { lat: 48.498751, lng: 17.8584632 },
    { lat: 48.499092, lng: 17.8587644 },
    { lat: 48.499229, lng: 17.8589755 },
    { lat: 48.4995418, lng: 17.8589581 },
    { lat: 48.501091, lng: 17.8598565 },
    { lat: 48.5016764, lng: 17.8599963 },
    { lat: 48.5023511, lng: 17.8600096 },
    { lat: 48.5031192, lng: 17.8602043 },
    { lat: 48.50334, lng: 17.8604189 },
    { lat: 48.5035434, lng: 17.8608675 },
    { lat: 48.5037826, lng: 17.8618691 },
    { lat: 48.504091, lng: 17.8626543 },
    { lat: 48.5044146, lng: 17.8637058 },
    { lat: 48.5045991, lng: 17.8640496 },
    { lat: 48.5050375, lng: 17.8645219 },
    { lat: 48.5052068, lng: 17.8656381 },
    { lat: 48.5053146, lng: 17.8660732 },
    { lat: 48.5053503, lng: 17.8667358 },
    { lat: 48.5059488, lng: 17.8677076 },
    { lat: 48.5061379, lng: 17.8681458 },
    { lat: 48.5066088, lng: 17.8685193 },
    { lat: 48.506775, lng: 17.8688411 },
    { lat: 48.5073111, lng: 17.8690055 },
    { lat: 48.5075128, lng: 17.8691494 },
    { lat: 48.507628, lng: 17.868953 },
  ],
  Žlkovce: [
    { lat: 48.470602, lng: 17.685286 },
    { lat: 48.4702535, lng: 17.6854332 },
    { lat: 48.4695429, lng: 17.6858603 },
    { lat: 48.4676549, lng: 17.6872362 },
    { lat: 48.4667002, lng: 17.6882417 },
    { lat: 48.4654407, lng: 17.689358 },
    { lat: 48.4638203, lng: 17.6913839 },
    { lat: 48.4632265, lng: 17.6923274 },
    { lat: 48.4629654, lng: 17.6928498 },
    { lat: 48.4613862, lng: 17.695287 },
    { lat: 48.4597239, lng: 17.6983091 },
    { lat: 48.4590955, lng: 17.6995721 },
    { lat: 48.4577406, lng: 17.7016579 },
    { lat: 48.4572187, lng: 17.7026369 },
    { lat: 48.4569092, lng: 17.7030319 },
    { lat: 48.4562899, lng: 17.7036052 },
    { lat: 48.455857, lng: 17.7041235 },
    { lat: 48.4553901, lng: 17.7048759 },
    { lat: 48.4552365, lng: 17.7050231 },
    { lat: 48.4550549, lng: 17.7051343 },
    { lat: 48.4547112, lng: 17.7051969 },
    { lat: 48.4536258, lng: 17.7047572 },
    { lat: 48.4528118, lng: 17.7046119 },
    { lat: 48.4516828, lng: 17.7044334 },
    { lat: 48.4500462, lng: 17.7044244 },
    { lat: 48.4491105, lng: 17.7043198 },
    { lat: 48.4475256, lng: 17.7039621 },
    { lat: 48.4458823, lng: 17.7037553 },
    { lat: 48.444488, lng: 17.7034131 },
    { lat: 48.444464, lng: 17.7039325 },
    { lat: 48.444604, lng: 17.7063855 },
    { lat: 48.4449491, lng: 17.7087392 },
    { lat: 48.4450856, lng: 17.7094099 },
    { lat: 48.4451249, lng: 17.710095 },
    { lat: 48.445461, lng: 17.7132349 },
    { lat: 48.4454165, lng: 17.713265 },
    { lat: 48.4458848, lng: 17.7174767 },
    { lat: 48.4458605, lng: 17.7182674 },
    { lat: 48.4458958, lng: 17.7185654 },
    { lat: 48.4459625, lng: 17.7186375 },
    { lat: 48.44612, lng: 17.7191748 },
    { lat: 48.446208, lng: 17.7196309 },
    { lat: 48.4462405, lng: 17.7205555 },
    { lat: 48.4461034, lng: 17.7213095 },
    { lat: 48.446611, lng: 17.7221224 },
    { lat: 48.447094, lng: 17.7247955 },
    { lat: 48.4471686, lng: 17.7255411 },
    { lat: 48.447394, lng: 17.7257346 },
    { lat: 48.4486848, lng: 17.7263785 },
    { lat: 48.4479641, lng: 17.7288512 },
    { lat: 48.4483475, lng: 17.7291503 },
    { lat: 48.4484521, lng: 17.7294379 },
    { lat: 48.4498334, lng: 17.7317564 },
    { lat: 48.4492672, lng: 17.7332454 },
    { lat: 48.4487732, lng: 17.7341282 },
    { lat: 48.4487341, lng: 17.7344452 },
    { lat: 48.4487515, lng: 17.734706 },
    { lat: 48.4489204, lng: 17.7351386 },
    { lat: 48.4490128, lng: 17.7350791 },
    { lat: 48.4491652, lng: 17.7351339 },
    { lat: 48.4492233, lng: 17.7353459 },
    { lat: 48.449221, lng: 17.7355994 },
    { lat: 48.4491246, lng: 17.7357004 },
    { lat: 48.4490939, lng: 17.7358953 },
    { lat: 48.4493082, lng: 17.7362587 },
    { lat: 48.4495033, lng: 17.736364 },
    { lat: 48.4500754, lng: 17.7364142 },
    { lat: 48.4505769, lng: 17.7361801 },
    { lat: 48.4507117, lng: 17.7359884 },
    { lat: 48.4508732, lng: 17.7360244 },
    { lat: 48.4511782, lng: 17.735953 },
    { lat: 48.4512758, lng: 17.7361072 },
    { lat: 48.4512643, lng: 17.7363236 },
    { lat: 48.4513264, lng: 17.7364899 },
    { lat: 48.451327, lng: 17.736663 },
    { lat: 48.4514463, lng: 17.7369853 },
    { lat: 48.451387, lng: 17.7373773 },
    { lat: 48.4515067, lng: 17.7380527 },
    { lat: 48.4516405, lng: 17.738354 },
    { lat: 48.451885, lng: 17.7384546 },
    { lat: 48.4521463, lng: 17.7386823 },
    { lat: 48.4521422, lng: 17.7391428 },
    { lat: 48.4520415, lng: 17.7393052 },
    { lat: 48.4517542, lng: 17.7392048 },
    { lat: 48.4515952, lng: 17.7392278 },
    { lat: 48.4514037, lng: 17.7390874 },
    { lat: 48.4512038, lng: 17.7390503 },
    { lat: 48.4509737, lng: 17.7392891 },
    { lat: 48.4507658, lng: 17.7393438 },
    { lat: 48.4507256, lng: 17.7393853 },
    { lat: 48.4507717, lng: 17.7398586 },
    { lat: 48.4507313, lng: 17.7399173 },
    { lat: 48.4504465, lng: 17.7399297 },
    { lat: 48.4503346, lng: 17.7400176 },
    { lat: 48.4501501, lng: 17.7399921 },
    { lat: 48.4500856, lng: 17.7399361 },
    { lat: 48.4500246, lng: 17.7396996 },
    { lat: 48.4498512, lng: 17.7395773 },
    { lat: 48.4495345, lng: 17.7396237 },
    { lat: 48.449483, lng: 17.7398322 },
    { lat: 48.4498159, lng: 17.7402679 },
    { lat: 48.4500504, lng: 17.7407554 },
    { lat: 48.450074, lng: 17.7409179 },
    { lat: 48.4500474, lng: 17.7410357 },
    { lat: 48.4498232, lng: 17.7411901 },
    { lat: 48.4496896, lng: 17.7413628 },
    { lat: 48.4496279, lng: 17.7418266 },
    { lat: 48.4497247, lng: 17.7420069 },
    { lat: 48.4497216, lng: 17.7421553 },
    { lat: 48.4495871, lng: 17.7424245 },
    { lat: 48.4496773, lng: 17.7425722 },
    { lat: 48.4500233, lng: 17.7427074 },
    { lat: 48.4504237, lng: 17.7429921 },
    { lat: 48.450827, lng: 17.7430514 },
    { lat: 48.4510163, lng: 17.7432095 },
    { lat: 48.4517764, lng: 17.7434336 },
    { lat: 48.4520607, lng: 17.7432452 },
    { lat: 48.4521438, lng: 17.7429665 },
    { lat: 48.4522788, lng: 17.7429036 },
    { lat: 48.4523373, lng: 17.7426642 },
    { lat: 48.4524536, lng: 17.7424783 },
    { lat: 48.4523493, lng: 17.742235 },
    { lat: 48.4524639, lng: 17.7420444 },
    { lat: 48.4525976, lng: 17.7419414 },
    { lat: 48.4528474, lng: 17.7418731 },
    { lat: 48.4535838, lng: 17.7422606 },
    { lat: 48.4537599, lng: 17.7422772 },
    { lat: 48.4538312, lng: 17.7421378 },
    { lat: 48.4537469, lng: 17.7418305 },
    { lat: 48.4536508, lng: 17.7410067 },
    { lat: 48.4537295, lng: 17.7407116 },
    { lat: 48.4538281, lng: 17.7406259 },
    { lat: 48.454177, lng: 17.7410086 },
    { lat: 48.4546232, lng: 17.741326 },
    { lat: 48.454951, lng: 17.7414773 },
    { lat: 48.4558145, lng: 17.7421298 },
    { lat: 48.4559061, lng: 17.7420778 },
    { lat: 48.4565139, lng: 17.7412819 },
    { lat: 48.456667, lng: 17.7412171 },
    { lat: 48.4569414, lng: 17.7417282 },
    { lat: 48.4570362, lng: 17.7422937 },
    { lat: 48.457114, lng: 17.7424233 },
    { lat: 48.4571835, lng: 17.7423766 },
    { lat: 48.4572626, lng: 17.7420497 },
    { lat: 48.4575184, lng: 17.7418396 },
    { lat: 48.4576164, lng: 17.7416911 },
    { lat: 48.457615, lng: 17.7414854 },
    { lat: 48.4574979, lng: 17.7409259 },
    { lat: 48.4575677, lng: 17.7406127 },
    { lat: 48.4577272, lng: 17.7405637 },
    { lat: 48.4581514, lng: 17.74116 },
    { lat: 48.4584458, lng: 17.7405148 },
    { lat: 48.4585822, lng: 17.7403834 },
    { lat: 48.4587384, lng: 17.7403921 },
    { lat: 48.4589264, lng: 17.7405671 },
    { lat: 48.4589283, lng: 17.7409603 },
    { lat: 48.4585977, lng: 17.7417481 },
    { lat: 48.4586189, lng: 17.7418181 },
    { lat: 48.458973, lng: 17.7420852 },
    { lat: 48.4590597, lng: 17.7425924 },
    { lat: 48.4591105, lng: 17.7426639 },
    { lat: 48.4592151, lng: 17.7426749 },
    { lat: 48.4594376, lng: 17.7425506 },
    { lat: 48.4597462, lng: 17.7430122 },
    { lat: 48.4597762, lng: 17.7431335 },
    { lat: 48.4598534, lng: 17.7431573 },
    { lat: 48.4599776, lng: 17.7428134 },
    { lat: 48.4599832, lng: 17.7422452 },
    { lat: 48.4601153, lng: 17.7421545 },
    { lat: 48.460349, lng: 17.7422613 },
    { lat: 48.4604275, lng: 17.7423834 },
    { lat: 48.4606664, lng: 17.7430667 },
    { lat: 48.4607215, lng: 17.7431195 },
    { lat: 48.4612182, lng: 17.7430259 },
    { lat: 48.4612369, lng: 17.7429656 },
    { lat: 48.4612256, lng: 17.742874 },
    { lat: 48.4610106, lng: 17.7426643 },
    { lat: 48.4608726, lng: 17.742441 },
    { lat: 48.4608645, lng: 17.742345 },
    { lat: 48.460936, lng: 17.7418602 },
    { lat: 48.4611415, lng: 17.7415867 },
    { lat: 48.4613995, lng: 17.7414507 },
    { lat: 48.4615859, lng: 17.7414317 },
    { lat: 48.4616174, lng: 17.7413743 },
    { lat: 48.4616682, lng: 17.7413964 },
    { lat: 48.461756, lng: 17.7416452 },
    { lat: 48.4619647, lng: 17.7419913 },
    { lat: 48.462212, lng: 17.7420642 },
    { lat: 48.4623578, lng: 17.7420317 },
    { lat: 48.4623927, lng: 17.7419368 },
    { lat: 48.4623805, lng: 17.7418318 },
    { lat: 48.4621888, lng: 17.7415626 },
    { lat: 48.4622271, lng: 17.7413413 },
    { lat: 48.4620019, lng: 17.7409321 },
    { lat: 48.4619765, lng: 17.7407005 },
    { lat: 48.4621465, lng: 17.7404192 },
    { lat: 48.4623241, lng: 17.7372526 },
    { lat: 48.4625897, lng: 17.7338783 },
    { lat: 48.4627506, lng: 17.7325421 },
    { lat: 48.462996, lng: 17.7307433 },
    { lat: 48.4632709, lng: 17.7293451 },
    { lat: 48.4636621, lng: 17.7267852 },
    { lat: 48.4639175, lng: 17.725843 },
    { lat: 48.4643894, lng: 17.7244221 },
    { lat: 48.4645388, lng: 17.7236797 },
    { lat: 48.4650925, lng: 17.7218838 },
    { lat: 48.4654912, lng: 17.7203391 },
    { lat: 48.4660314, lng: 17.7187268 },
    { lat: 48.4670665, lng: 17.7164839 },
    { lat: 48.4684005, lng: 17.7130737 },
    { lat: 48.4698445, lng: 17.7085633 },
    { lat: 48.4711245, lng: 17.7051072 },
    { lat: 48.4717198, lng: 17.7036227 },
    { lat: 48.4731096, lng: 17.7007501 },
    { lat: 48.4744621, lng: 17.6990579 },
    { lat: 48.4753662, lng: 17.6980749 },
    { lat: 48.4762794, lng: 17.6971831 },
    { lat: 48.4772745, lng: 17.6960267 },
    { lat: 48.4779345, lng: 17.695184 },
    { lat: 48.4799725, lng: 17.6919906 },
    { lat: 48.480867, lng: 17.691111 },
    { lat: 48.48085, lng: 17.691107 },
    { lat: 48.480742, lng: 17.691056 },
    { lat: 48.480581, lng: 17.690842 },
    { lat: 48.480295, lng: 17.690392 },
    { lat: 48.47985, lng: 17.689807 },
    { lat: 48.479404, lng: 17.689167 },
    { lat: 48.479173, lng: 17.688817 },
    { lat: 48.478931, lng: 17.688445 },
    { lat: 48.478653, lng: 17.688048 },
    { lat: 48.478492, lng: 17.687794 },
    { lat: 48.478262, lng: 17.687336 },
    { lat: 48.478067, lng: 17.68694 },
    { lat: 48.477808, lng: 17.686398 },
    { lat: 48.477653, lng: 17.686129 },
    { lat: 48.477446, lng: 17.685786 },
    { lat: 48.477231, lng: 17.685425 },
    { lat: 48.477025, lng: 17.685048 },
    { lat: 48.476873, lng: 17.684745 },
    { lat: 48.476861, lng: 17.684759 },
    { lat: 48.476839, lng: 17.684745 },
    { lat: 48.476705, lng: 17.684681 },
    { lat: 48.476621, lng: 17.684665 },
    { lat: 48.476524, lng: 17.684654 },
    { lat: 48.476341, lng: 17.684659 },
    { lat: 48.476089, lng: 17.68469 },
    { lat: 48.475769, lng: 17.684726 },
    { lat: 48.475484, lng: 17.684742 },
    { lat: 48.475255, lng: 17.684723 },
    { lat: 48.474846, lng: 17.684676 },
    { lat: 48.474621, lng: 17.68465 },
    { lat: 48.474295, lng: 17.68465 },
    { lat: 48.47394, lng: 17.684672 },
    { lat: 48.473652, lng: 17.684715 },
    { lat: 48.473467, lng: 17.684745 },
    { lat: 48.472784, lng: 17.684933 },
    { lat: 48.472266, lng: 17.685083 },
    { lat: 48.471931, lng: 17.685179 },
    { lat: 48.471606, lng: 17.685252 },
    { lat: 48.471188, lng: 17.685284 },
    { lat: 48.470859, lng: 17.685298 },
    { lat: 48.470602, lng: 17.685286 },
  ],
  HornéZelenice: [
    { lat: 48.3882624, lng: 17.7665119 },
    { lat: 48.3882696, lng: 17.7664836 },
    { lat: 48.3883159, lng: 17.7660992 },
    { lat: 48.3883496, lng: 17.7648321 },
    { lat: 48.3884963, lng: 17.7633139 },
    { lat: 48.3892799, lng: 17.761395 },
    { lat: 48.3895774, lng: 17.7607846 },
    { lat: 48.3900169, lng: 17.7602577 },
    { lat: 48.39107, lng: 17.7598873 },
    { lat: 48.3910255, lng: 17.7597539 },
    { lat: 48.3921215, lng: 17.7587068 },
    { lat: 48.3919888, lng: 17.7584777 },
    { lat: 48.392068, lng: 17.7561399 },
    { lat: 48.3933315, lng: 17.7560893 },
    { lat: 48.3934034, lng: 17.7544007 },
    { lat: 48.3944214, lng: 17.7543232 },
    { lat: 48.395082, lng: 17.7544705 },
    { lat: 48.3962148, lng: 17.7546053 },
    { lat: 48.3967968, lng: 17.7542482 },
    { lat: 48.3967718, lng: 17.7531525 },
    { lat: 48.3964576, lng: 17.752635 },
    { lat: 48.3966671, lng: 17.7522228 },
    { lat: 48.3976797, lng: 17.7507437 },
    { lat: 48.3977392, lng: 17.7505919 },
    { lat: 48.3978991, lng: 17.7500743 },
    { lat: 48.3979477, lng: 17.7497087 },
    { lat: 48.3979793, lng: 17.7489959 },
    { lat: 48.3979478, lng: 17.7482695 },
    { lat: 48.4004081, lng: 17.7464262 },
    { lat: 48.4001096, lng: 17.7453985 },
    { lat: 48.4006728, lng: 17.7451024 },
    { lat: 48.4010007, lng: 17.7450947 },
    { lat: 48.4016227, lng: 17.7440333 },
    { lat: 48.4022886, lng: 17.7414397 },
    { lat: 48.4025748, lng: 17.740666 },
    { lat: 48.4034393, lng: 17.7377607 },
    { lat: 48.4036632, lng: 17.7353786 },
    { lat: 48.4037422, lng: 17.734852 },
    { lat: 48.4038787, lng: 17.7345387 },
    { lat: 48.4049899, lng: 17.7330139 },
    { lat: 48.405983, lng: 17.7320383 },
    { lat: 48.4062671, lng: 17.7316332 },
    { lat: 48.4073572, lng: 17.7304225 },
    { lat: 48.4069022, lng: 17.7298953 },
    { lat: 48.4069996, lng: 17.7289329 },
    { lat: 48.4069767, lng: 17.7280363 },
    { lat: 48.4068107, lng: 17.727055 },
    { lat: 48.4065606, lng: 17.7263712 },
    { lat: 48.4061797, lng: 17.725819 },
    { lat: 48.4056274, lng: 17.7253409 },
    { lat: 48.4051942, lng: 17.7252573 },
    { lat: 48.4046358, lng: 17.7252829 },
    { lat: 48.404042, lng: 17.7256112 },
    { lat: 48.4036522, lng: 17.7259177 },
    { lat: 48.4031902, lng: 17.7266589 },
    { lat: 48.4021133, lng: 17.7292843 },
    { lat: 48.4012129, lng: 17.7308752 },
    { lat: 48.4006674, lng: 17.7319687 },
    { lat: 48.4005298, lng: 17.7320618 },
    { lat: 48.3996353, lng: 17.7320206 },
    { lat: 48.3994796, lng: 17.731966 },
    { lat: 48.3992062, lng: 17.7317094 },
    { lat: 48.3981555, lng: 17.731296 },
    { lat: 48.3976868, lng: 17.7314753 },
    { lat: 48.3970345, lng: 17.7313729 },
    { lat: 48.3959963, lng: 17.7308867 },
    { lat: 48.3956906, lng: 17.7308567 },
    { lat: 48.395569, lng: 17.7309515 },
    { lat: 48.3954187, lng: 17.7311878 },
    { lat: 48.3953506, lng: 17.7315654 },
    { lat: 48.3952226, lng: 17.7318291 },
    { lat: 48.3942492, lng: 17.7324459 },
    { lat: 48.3937096, lng: 17.7329043 },
    { lat: 48.3936213, lng: 17.7331361 },
    { lat: 48.39358, lng: 17.7334176 },
    { lat: 48.3935458, lng: 17.734048 },
    { lat: 48.3935919, lng: 17.7344287 },
    { lat: 48.393794, lng: 17.7360677 },
    { lat: 48.3929209, lng: 17.7369026 },
    { lat: 48.3918449, lng: 17.7375745 },
    { lat: 48.3919668, lng: 17.7381563 },
    { lat: 48.3918236, lng: 17.7385977 },
    { lat: 48.3918161, lng: 17.7391344 },
    { lat: 48.3917543, lng: 17.7391363 },
    { lat: 48.3913566, lng: 17.7397176 },
    { lat: 48.3898439, lng: 17.7423614 },
    { lat: 48.3887808, lng: 17.743992 },
    { lat: 48.3884188, lng: 17.7440717 },
    { lat: 48.3862189, lng: 17.7450483 },
    { lat: 48.3855983, lng: 17.7453647 },
    { lat: 48.3848787, lng: 17.7458664 },
    { lat: 48.384405, lng: 17.7463129 },
    { lat: 48.3812693, lng: 17.7496814 },
    { lat: 48.381331, lng: 17.7526522 },
    { lat: 48.3813899, lng: 17.7535333 },
    { lat: 48.3816646, lng: 17.7557446 },
    { lat: 48.381477, lng: 17.7568734 },
    { lat: 48.3815022, lng: 17.7576502 },
    { lat: 48.3817315, lng: 17.759074 },
    { lat: 48.3818262, lng: 17.7600056 },
    { lat: 48.3811684, lng: 17.7613298 },
    { lat: 48.3805687, lng: 17.7616175 },
    { lat: 48.3788455, lng: 17.7618492 },
    { lat: 48.3773623, lng: 17.7624563 },
    { lat: 48.3774239, lng: 17.7626877 },
    { lat: 48.3798681, lng: 17.7708072 },
    { lat: 48.381673, lng: 17.7738773 },
    { lat: 48.382239, lng: 17.7760624 },
    { lat: 48.3824502, lng: 17.7756619 },
    { lat: 48.3837233, lng: 17.7763307 },
    { lat: 48.3843859, lng: 17.776864 },
    { lat: 48.3847315, lng: 17.7759647 },
    { lat: 48.3851785, lng: 17.7748122 },
    { lat: 48.3876595, lng: 17.7696391 },
    { lat: 48.3881434, lng: 17.7675088 },
    { lat: 48.3882624, lng: 17.7665119 },
  ],
};

export default UnitsHlohovec;
