import { Fragment, React, useEffect, useRef } from "react";
import "../App.css";
import Navbar from "../components/Navbar";
import Map from "../components/Map/Map";
// import RightBar from "../components/RightBar";
import { useState, useMemo } from "react";
import { useLocalStorage } from "usehooks-ts";
import Introduction from "../components/Introduction";
import VerticalLinearStepper from "./VerticalLinearStepper";
import { v4 as uuidv4 } from "uuid";
import Cookies from "universal-cookie";
import PropertiesMenu from "./SwitchParts/SmallScreen/PropertiesMenu";
import SubjectsMenu from "./SwitchParts/SmallScreen/SubjectsMenu";
import HelperMenu from "./SwitchParts/SmallScreen/HelperMenu";
import OptionsMenu from "./SwitchParts/SmallScreen/OptionsMenu";
import LoginMenu from "./SwitchParts/SmallScreen/LoginMenu";
import WarningMessage from "./WarningMessage";
import HistoryPins from "./Pins/HistoryPins";
import HistoryPin from "./Pins/HistoryPin";
import ForSalePins from "./Pins/ForSalePins";
import ForSalePin from "./Pins/ForSalePin";
import REOfficesPins from "./Pins/REOfficesPins";
import REBrokersPins from "./Pins/REBrokersPins";
import FInstitutionsPins from "./Pins/FInstitutionsPins";
import FinBrokersPins from "./Pins/FinBrokersPins";
import AvgPriceMenu from "./SwitchParts/SmallScreen/AvgPriceMenu";

function Home({ isLogged, setIsLogged, infoUser }) {
  const latRef = useRef(48.747332);
  const longRef = useRef(21.92219);
  const isClickedRef = useRef(false);
  const [gotResponse, setGotResponse] = useState("false");
  const [navbarOpen, setNavbarOpen] = useState(false);
  // const [rightBarOpen, setRightBarOpen] = useState(false);
  const [mapActive, setMapActive] = useState(true);
  const [historyItems, setHistoryItems] = useLocalStorage("history", []);
  const [center, setCenter] = useState({ lat: 48.747332, lng: 21.92219 });
  const [map, setMap] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);
  const [introVisible, setIntroVisible] = useState(false);
  const [visibilityOfMarkers, setVisibilityOfMarkers] = useState("false");
  const [historyInfo, setHistoryInfo] = useState(false);
  const [showLV, setShowLV] = useState(false);
  const [openLeftBar, setLeftBar] = useState({ history: false, forSale: false, realEstate: false, finInst: false });
  const [responseForSale, setResponseForSale] = useState([]);
  const [viewSale, setViewSale] = useState({
    view: false,
    rated: false,
    shared: false,
    saved: false,
    my: false,
    index: "",
  });
  const [gallery, setGallery] = useState(false);
  const [isExpanded, setExpanded] = useState(-1);
  const indexCurrent = useRef(null);
  const [isHistory, setHistory] = useState(false);
  const [navSmall, setNavSmall] = useState({
    properties: false,
    subjects: false,
    helper: false,
    options: false,
    login: false,
  });
  const [subMenu, setSubMenu] = useState({
    lv: false,
    interest: false,
    owner: true,
    disp: false,
    rating: false,
    share: false,
  });
  const [forSale, setForSale] = useState({ index: -1, menu: false });
  const [historyMenu, setHistoryMenu] = useState(false);
  const [subjects, setSubjects] = useState({ ROffice: false, RBroker: false, FInstitution: false, FBroker: false });
  const indx = useRef(-2);
  const indxPrev = useRef(-2);
  const [blockOfFlats, setBlockOfFlats] = useState({ flat: null, floor: null, entrance: null });
  const [view, setView] = useState("hybrid");
  const [subjectsMenu, setSubjectsMenu] = useState({ REO: false, FI: false, FIB: false, REB: false, menu: "" });
  const [mouseOver, setMouseOver] = useState({
    previewFS: false,
    previewH: false,
    previewREO: false,
    previewREB: false,
    previewFI: false,
    previewFB: false,
    indexFS: null,
    indexH: null,
    indexREO: null,
    indexREB: null,
    indexFI: null,
    indexFB: null,
  });
  const [ignoreMessage, setIgnoreMessage] = useState(true);
  const [zoom, setZoom] = useState(11);
  const [highlight, setHighlight] = useState({
    forSale: false,
    history: false,
    reo: false,
    finIn: false,
    index: null,
    indexFI: null,
  });
  const [hideMarkers, setHideMarkers] = useState({
    REO: false,
    history: false,
    house: false,
    flat: false,
    FI: false,
  });
  const [filterSale, setFilterSale] = useState([]);
  const [filters, setFilters] = useState({
    minPrice: "",
    maxPrice: "",
    minArea: "",
    maxArea: "",
    type: "",
    location: "",
  });

  const [shareLoad, setShareLoad] = useState(false);
  const [PMResult, setPMResult] = useState({
    active: false,
    data: {},
    parent: "",
    dom: true,
    byt: false,
    pozemok: false,
    region: "",
  });
  const [clickedReg, setClickedReg] = useState(false);
  const [priceMap, setPriceMap] = useState({
    region: false,
    district: false,
    districtName: "",
    city: false,
    cityName: "",
  });
  const [PMHistory, setPMHistory] = useState({ region: "", district: "", city: "" });
  const [genAvgPrice, setGenAvgPrice] = useState({});
  const [optSubMenu, setOptSubMenu] = useState({ aboutUs: false, contact: false, privacy: false, helper: false });
  const [regDisUni, setRegDisUni] = useState({ region: false, district: false, unit: false });
  const [pick, setPick] = useState({ region: false, district: false });

  const cookies = new Cookies();

  useEffect(() => {
    if (cookies.get("sessionIdToken") === undefined) {
      cookies.set("sessionIdToken", uuidv4());
    }
  });

  const reversedHistoryItems = useMemo(() => {
    return [...historyItems].slice(0, 10);
  }, [historyItems]);

  function centralize() {
    if (historyItems.length > 0 && visibilityOfMarkers !== "historyOpened") {
      const bounds = new window.google.maps.LatLngBounds();
      historyItems.forEach((marker) => {
        bounds.extend(new window.google.maps.LatLng(marker.latAndLng.lat, marker.latAndLng.lng));
      });
      map.fitBounds(bounds);
    }
  }

  const previewFS = (index) => {
    if (!mouseOver.previewFS) {
      setMouseOver({
        ...mouseOver,
        previewFS: true,
        indexFS: index,
        indexH: null,
        indexREO: null,
        indexREB: null,
        indexFI: null,
        indexFB: null,
      });
    }
  };

  const previewH = (index) => {
    if (!mouseOver.previewH) {
      setMouseOver({
        ...mouseOver,
        previewH: true,
        indexH: index,
        indexREO: null,
        indexFS: null,
        indexREB: null,
        indexFI: null,
        indexFB: null,
      });
    }
  };

  const previewREO = (index) => {
    if (!mouseOver.previewREO) {
      setMouseOver({
        ...mouseOver,
        previewREO: true,
        indexREO: index,
        indexFS: null,
        indexH: null,
        indexREB: null,
        indexFI: null,
        indexFB: null,
      });
    }
  };

  const previewREB = (index) => {
    if (!mouseOver.previewREB) {
      setMouseOver({
        ...mouseOver,
        previewREB: true,
        indexREB: index,
        indexREO: null,
        indexFS: null,
        indexH: null,
        indexFI: null,
        indexFB: null,
      });
    }
  };

  const previewFI = (index) => {
    if (!mouseOver.previewFI) {
      setMouseOver({
        ...mouseOver,
        previewFI: true,
        indexFI: index,
        indexREB: null,
        indexREO: null,
        indexFS: null,
        indexH: null,
        indexFB: null,
      });
    }
  };

  const previewFB = (index) => {
    if (!mouseOver.previewFB) {
      setMouseOver({
        ...mouseOver,
        previewFB: true,
        indexFB: index,
        indexFI: null,
        indexREB: null,
        indexREO: null,
        indexFS: null,
        indexH: null,
      });
    }
  };

  return (
    <>
      {/* <Introduction setIntroVisible={setIntroVisible} introVisible={introVisible} /> */}
      <VerticalLinearStepper setIntroVisible={setIntroVisible} introVisible={introVisible} />
      {/* {showLV === false && !showHistoryProperty ? (
        <RightBar
          setRightBarOpen={setRightBarOpen}
          rightBarOpen={rightBarOpen}
          setIntroVisible={setIntroVisible}
          userOpen={userOpen}
          setUserOpen={setUserOpen}
          isLogged={isLogged}
          setIsLogged={setIsLogged}
          infoUser={infoUser}
        />
      ) : null} */}
      {isLogged && infoUser.role === 3 && infoUser.longitude === "" && infoUser.longitude === "" && ignoreMessage ? (
        <WarningMessage setIgnoreMessage={setIgnoreMessage} />
      ) : null}
      <Navbar
        navbarOpen={navbarOpen}
        // rightBarOpen={rightBarOpen}
        historyItems={historyItems}
        map={map}
        setCurrentIndex={setCurrentIndex}
        setVisibilityOfMarkers={setVisibilityOfMarkers}
        setResponseForSale={setResponseForSale}
        setViewSale={setViewSale}
        setCurrentItem={setCurrentItem}
        setGallery={setGallery}
        openLeftBar={openLeftBar}
        setLeftBar={setLeftBar}
        setNavSmall={setNavSmall}
        navSmall={navSmall}
        isLogged={isLogged}
        infoUser={infoUser}
        setForSale={setForSale}
        forSale={forSale}
        setHistory={setHistory}
        setHistoryMenu={setHistoryMenu}
        viewSale={viewSale}
        setSubMenu={setSubMenu}
        subMenu={subMenu}
        subjects={subjects}
        setSubjects={setSubjects}
        setFilterSale={setFilterSale}
        optSubMenu={optSubMenu}
        setOptSubMenu={setOptSubMenu}
        setMapActive={setMapActive}
        setPriceMap={setPriceMap}
        priceMap={priceMap}
        setGotResponse={setGotResponse}
        PMResult={PMResult}
        setPMResult={setPMResult}
        setZoom={setZoom}
      />
      <div
        onClick={() => setNavbarOpen(false)}
        className={`absolute w-full left-0 top-0 z-0 `} //${rightBarOpen ? "brightness-50 pointer-events-none" : ""}
      >
        <Map
          mapActive={mapActive}
          setHistoryItems={setHistoryItems}
          historyItems={historyItems}
          center={center}
          setCenter={setCenter}
          setMap={setMap}
          map={map}
          latRef={latRef}
          longRef={longRef}
          isClickedRef={isClickedRef}
          setVisibilityOfMarkers={setVisibilityOfMarkers}
          visibilityOfMarkers={visibilityOfMarkers}
          openLeftBar={openLeftBar}
          setLeftBar={setLeftBar}
          gotResponse={gotResponse}
          setGotResponse={setGotResponse}
          setHistoryInfo={setHistoryInfo}
          setShowLV={setShowLV}
          showLV={showLV}
          setNavSmall={setNavSmall}
          navSmall={navSmall}
          setHistory={setHistory}
          setViewSale={setViewSale}
          viewSale={viewSale}
          subMenu={subMenu}
          setSubMenu={setSubMenu}
          blockOfFlats={blockOfFlats}
          setBlockOfFlats={setBlockOfFlats}
          setSubjects={setSubjects}
          subjects={subjects}
          mouseOver={mouseOver}
          setMouseOver={setMouseOver}
          view={view}
          setView={setView}
          zoom={zoom}
          setZoom={setZoom}
          hideMarkers={hideMarkers}
          setHideMarkers={setHideMarkers}
          filters={filters}
          setFilters={setFilters}
          setFilterSale={setFilterSale}
          responseForSale={responseForSale}
          filterSale={filterSale}
          forSale={forSale}
          setForSale={setForSale}
          setResponseForSale={setResponseForSale}
          indx={indx}
          shareLoad={shareLoad}
          setShareLoad={setShareLoad}
          setHistoryMenu={setHistoryMenu}
          setPMResult={setPMResult}
          PMResult={PMResult}
          setClickedReg={setClickedReg}
          clickedReg={clickedReg}
          setPriceMap={setPriceMap}
          priceMap={priceMap}
          setGenAvgPrice={setGenAvgPrice}
          genAvgPrice={genAvgPrice}
          setRegDisUni={setRegDisUni}
          regDisUni={regDisUni}
          setMapActive={setMapActive}
          setPick={setPick}
          pick={pick}
          PMHistory={PMHistory}
          setPMHistory={setPMHistory}
          historyPins={
            <HistoryPins
              visibilityOfMarkers={visibilityOfMarkers}
              historyItems={historyItems}
              map={map}
              view={view}
              mouseOver={mouseOver}
              setMouseOver={setMouseOver}
              previewH={previewH}
              indx={indx}
              setCurrentIndex={setCurrentIndex}
              setVisibilityOfMarkers={setVisibilityOfMarkers}
              setSubMenu={setSubMenu}
              subMenu={subMenu}
              setHistoryMenu={setHistoryMenu}
              zoom={zoom}
              highlight={highlight}
              navSmall={navSmall}
              setNavSmall={setNavSmall}
              hideMarkers={hideMarkers}
              setHistory={setHistory}
            />
          }
          historyPin={
            <HistoryPin
              visibilityOfMarkers={visibilityOfMarkers}
              historyItems={historyItems}
              currentIndex={currentIndex}
              setHistoryInfo={setHistoryInfo}
              historyInfo={historyInfo}
            />
          }
          forSalePins={
            <ForSalePins
              viewSale={viewSale}
              view={view}
              mouseOver={mouseOver}
              setMouseOver={setMouseOver}
              previewFS={previewFS}
              responseForSale={responseForSale}
              setExpanded={setExpanded}
              setViewSale={setViewSale}
              setVisibilityOfMarkers={setVisibilityOfMarkers}
              setCurrentItem={setCurrentItem}
              isExpanded={isExpanded}
              setForSale={setForSale}
              forSale={forSale}
              indx={indx}
              zoom={zoom}
              highlight={highlight}
              navSmall={navSmall}
              setNavSmall={setNavSmall}
              hideMarkers={hideMarkers}
              filterSale={filterSale}
            />
          }
          forSalePin={
            <ForSalePin
              visibilityOfMarkers={visibilityOfMarkers}
              responseForSale={responseForSale}
              currentItem={currentItem}
              filterSale={filterSale}
            />
          }
          realEstatePins={
            <REOfficesPins
              subjects={subjects}
              mouseOver={mouseOver}
              setMouseOver={setMouseOver}
              view={view}
              previewREO={previewREO}
              zoom={zoom}
              highlight={highlight}
              hideMarkers={hideMarkers}
              navSmall={navSmall}
              setNavSmall={setNavSmall}
              indx={indx}
              setSubjects={setSubjects}
              subjectsMenu={subjectsMenu}
              setSubjectsMenu={setSubjectsMenu}
            />
          }
          REBrokersPins={
            <REBrokersPins
              subjects={subjects}
              mouseOver={mouseOver}
              setMouseOver={setMouseOver}
              view={view}
              previewREB={previewREB}
              navSmall={navSmall}
              setNavSmall={setNavSmall}
              indx={indx}
              setSubjects={setSubjects}
              subjectsMenu={subjectsMenu}
              setSubjectsMenu={setSubjectsMenu}
            />
          }
          FInstitutionPins={
            <FInstitutionsPins
              subjects={subjects}
              mouseOver={mouseOver}
              setMouseOver={setMouseOver}
              view={view}
              previewFI={previewFI}
              zoom={zoom}
              highlight={highlight}
              hideMarkers={hideMarkers}
              navSmall={navSmall}
              setNavSmall={setNavSmall}
              indx={indx}
              setSubjects={setSubjects}
              subjectsMenu={subjectsMenu}
              setSubjectsMenu={setSubjectsMenu}
            />
          }
          FinBrokersPins={
            <FinBrokersPins
              subjects={subjects}
              mouseOver={mouseOver}
              setMouseOver={setMouseOver}
              view={view}
              previewFB={previewFB}
              navSmall={navSmall}
              setNavSmall={setNavSmall}
              indx={indx}
              setSubjects={setSubjects}
              subjectsMenu={subjectsMenu}
              setSubjectsMenu={setSubjectsMenu}
            />
          }
        />
      </div>
      <PropertiesMenu
        navSmall={navSmall}
        setNavSmall={setNavSmall}
        isLogged={isLogged}
        map={map}
        setCurrentIndex={setCurrentIndex}
        setVisibilityOfMarkers={setVisibilityOfMarkers}
        reversedHistoryItems={reversedHistoryItems}
        centralize={centralize}
        visibilityOfMarkers={visibilityOfMarkers}
        setExpanded={setExpanded}
        isExpanded={isExpanded}
        indexCur={indexCurrent}
        setHistory={setHistory}
        isHistory={isHistory}
        viewSale={viewSale}
        setViewSale={setViewSale}
        openLeftBar={openLeftBar}
        responseForSale={responseForSale}
        setResponseForSale={setResponseForSale}
        setCurrentItem={setCurrentItem}
        setGallery={setGallery}
        gallery={gallery}
        subMenu={subMenu}
        setSubMenu={setSubMenu}
        setForSale={setForSale}
        forSale={forSale}
        setHistoryMenu={setHistoryMenu}
        historyMenu={historyMenu}
        indx={indx}
        blockOfFlats={blockOfFlats}
        setHighlight={setHighlight}
        highlight={highlight}
        subjects={subjects}
        indxPrev={indxPrev}
        filterSale={filterSale}
        setFilterSale={setFilterSale}
        filters={filters}
        setFilters={setFilters}
        shareLoad={shareLoad}
        setShareLoad={setShareLoad}
      />
      <SubjectsMenu
        navSmall={navSmall}
        setNavSmall={setNavSmall}
        subjects={subjects}
        setSubjects={setSubjects}
        map={map}
        setHighlight={setHighlight}
        highlight={highlight}
        setSubjectsMenu={setSubjectsMenu}
        subjectsMenu={subjectsMenu}
        indx={indx}
        subMenu={subMenu}
        setSubMenu={setSubMenu}
        setForSale={setForSale}
        forSale={forSale}
        viewSale={viewSale}
        setViewSale={setViewSale}
        indxPrev={indxPrev}
      />
      <HelperMenu navSmall={navSmall} setNavSmall={setNavSmall} />
      <OptionsMenu
        navSmall={navSmall}
        setNavSmall={setNavSmall}
        mapActive={mapActive}
        setMapActive={setMapActive}
        isLogged={isLogged}
        map={map}
        setZoom={setZoom}
        setHideMarkers={setHideMarkers}
        hideMarkers={hideMarkers}
        PMResult={PMResult}
        setPMResult={setPMResult}
        setClickedReg={setClickedReg}
        setPriceMap={setPriceMap}
        priceMap={priceMap}
        setGenAvgPrice={setGenAvgPrice}
        setOptSubMenu={setOptSubMenu}
        optSubMenu={optSubMenu}
        setIntroVisible={setIntroVisible}
      />
      <LoginMenu navSmall={navSmall} setNavSmall={setNavSmall} isLogged={isLogged} setIsLogged={setIsLogged} />
      <AvgPriceMenu
        PMResult={PMResult}
        setPMResult={setPMResult}
        setRegDisUni={setRegDisUni}
        regDisUni={regDisUni}
        setClickedReg={setClickedReg}
        setZoom={setZoom}
        setPriceMap={setPriceMap}
        priceMap={priceMap}
        genAvgPrice={genAvgPrice}
        setGenAvgPrice={setGenAvgPrice}
        map={map}
        setPick={setPick}
        pick={pick}
        PMHistory={PMHistory}
        setPMHistory={setPMHistory}
      />
    </>
  );
}

export default Home;
