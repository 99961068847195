import clsx from "clsx";
// import { useMediaQuery } from "usehooks-ts";

export default function Save({ size }) {
  // const isSmallDevice = useMediaQuery("only screen and (max-width : 640px)");

  return (
    <div
      className={clsx("px-4 w-full", {
        // "flex flex-col w-1/2": !isSmallDevice && size.full,
        // "w-full": isSmallDevice || !size.full,
      })}
    >
      <button className="w-full bg-sky-300 rounded-xl h-10 mt-2">Uložiť</button>
    </div>
  );
}
