import { useState } from 'react';
import Cookies from 'universal-cookie';

export default function Cookie() {

    const cookies = new Cookies();
    // eslint-disable-next-line
    const [accepted, setAccepted] = useState(false)

    function handleCookies() {
        cookies.set('accepted', { path: '/'});
        setAccepted(true)
    }

    return(
        <>
            {cookies.get('accepted') !== undefined ?
                null
            : 
            <div className='fixed bottom-1 w-screen flex justify-center z-50'> 
                <div className="bg-sky-100 w-11/12 sm:ml-16 p-2 rounded-lg grid grid-rows-2 lg:grid-cols-5">
                    <div className='lg:col-start-1 lg:col-span-4 text-black flex justify-center items-center'>
                        <p>Táto stránka používa súbory cookies, aby vám ponúkla lepší zážitok z prehliadania. Prehliadaním tejto webovej stránky súhlasíte s naším používaním cookies.	</p>
                    </div>
                    <div className='flex flex-row gap-2 justify-center items-center pt-2 lg:pt-0 lg:col-start-5'>
                        <a href="https://lotario.sk/ochrana-sukromia/" className='uppercase border-2 border-white p-1 w-24 flex justify-center text-black hover:bg-sky-200 truncate'>More info</a>
                        <button className='uppercase border-2 border-white p-1 w-24 flex justify-center text-black hover:bg-sky-200' onClick={handleCookies}>Accept</button>
                    </div>
                </div>
            </div>
            }
        </>
    );
}