import { motion } from "framer-motion";
import { useState } from "react";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEuro, faLink, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import Description from "../../../Images/description.png";
import Parameters from "../../../Images/parameters.png";

export default function Overview({ previous, forSaleItems, subMenu }) {
  // console.log("forSaleItems = ", forSaleItems);

  const [expandDesc, setExpandDesc] = useState(false);

  return subMenu.owner ? (
    <motion.div
      onClick={(e) => e.stopPropagation()}
      className="w-auto flex flex-col items-start overflow-x-scroll pt-2"
      initial={{ x: previous === "" ? null : -500 }} //{{ x: previous !== "lv" && previous !== "in" ? -500 : 500 }}
      animate={{ x: previous === "" ? null : 0 }}
      transition={{ duration: 0.5 }}
    >
      {/* location */}
      <div className="flex flex-row items-center w-full py-2 border-b border-opacity-50 border-b-sky-500">
        <div className="w-1/5 flex justify-center">
          <FontAwesomeIcon icon={faLocationDot} />
        </div>
        <div className="flex flex-row gap-1 w-4/5">
          <p>{forSaleItems?.street ? forSaleItems?.street : forSaleItems?.city}</p>
          <p>{forSaleItems?.number} ,</p>
          <p>{forSaleItems?.psc}</p>
          <p>{forSaleItems?.city}</p>
        </div>
      </div>
      {/* location */}
      {/* price */}
      <div className="flex flex-row w-full py-2 border-b border-opacity-50 border-b-sky-500">
        <div className="flex justify-center items-center w-1/5">
          <FontAwesomeIcon icon={faEuro} />
        </div>
        <div className="flex flex-col w-4/5">
          <div className="flex flex-row border-b border-opacity-50 border-b-sky-500">
            <p className="pb-1">Trhová cena: {forSaleItems?.avgPrice} €</p>
          </div>
          <div className="flex flex-row ">
            <p className="pt-1">Predajná cena: {forSaleItems?.price} €</p>
          </div>
        </div>
      </div>
      {/* price */}
      {/* parameters */}
      <div className="flex flex-row w-full py-2 border-b border-opacity-50 border-b-sky-500">
        <div className="flex justify-center items-center w-1/5">
          <img src={Parameters} alt="" className="w-5 h-5 " />
        </div>
        {forSaleItems?.parameters ? (
          <div className="flex flex-col w-4/5">
            <div className="flex flex-row border-b border-opacity-50 border-b-sky-500">
              <p className="pb-1">Rozloha: {JSON.parse(forSaleItems?.parameters).area} m²</p>
            </div>
            <div className="flex flex-row border-b border-opacity-50 border-b-sky-500">
              <p className="py-1">Stav: {JSON.parse(forSaleItems?.parameters).condition}</p>
            </div>
            <div className="flex flex-row">
              <p className="pt-1">Typ: {JSON.parse(forSaleItems?.parameters).type}</p>
            </div>
          </div>
        ) : null}
      </div>
      {/* parameters */}
      {/* description */}
      <div className="flex flex-row w-full py-2 border-b border-opacity-50 border-b-sky-500">
        <div className="flex justify-center items-center w-1/5">
          <img src={Description} alt="" className="w-4 h-4 " />
        </div>
        <div
          onClick={() => setExpandDesc(!expandDesc)}
          className={clsx("flex flex-col w-4/5", {
            "!line-clamp-4": !expandDesc,
          })}
        >
          {forSaleItems?.description}
        </div>
      </div>
      {/* description */}
      {/* url */}
      <div className="flex flex-row items-center w-full py-2 border-b border-opacity-50 border-b-sky-500">
        <div className="w-1/5 flex justify-center">
          <FontAwesomeIcon icon={faLink} />
        </div>
        <div className="flex flex-row gap-1 w-4/5 ">
          <input
            readOnly={true}
            onClick={(e) => navigator.clipboard.writeText(e.target.value)}
            className="truncate cursor-copy border-0 bg-sky-100 w-full border-transparent focus:border-transparent focus:ring-0"
            value={forSaleItems?.link}
          ></input>
        </div>
      </div>
      {/* url */}
    </motion.div>
  ) : null;
}
