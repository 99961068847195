import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Info from "./Info";

const Message = ({ averagePrice, Open, changeIsOpen, changeCategory, changeStatus, handleChange, changeArea }) => {
  library.add(faArrowLeft);

  function avgPrice() {
    if (averagePrice.avgPrice === undefined) {
      return 0;
    } else {
      return averagePrice.avgPrice;
    }
  }

  function countOfObjects() {
    if (averagePrice.countOfObjects === undefined) {
      return 0;
    } else {
      return averagePrice.countOfObjects;
    }
  }

  function relevancy() {
    if (averagePrice.relevancy === undefined) {
      return 0;
    } else {
      return averagePrice.relevancy;
    }
  }

  function close() {
    changeIsOpen(false);
    changeCategory("");
    changeStatus("");
    handleChange("");
    changeArea(150);
  }

  return (
    <div className="backdrop-blur-md min-h-screen text-center flex justify-start w-screen -mt-14 sm:-mt-28 sm:justify-center ">
      {Open === true ? (
        <div className="flex flex-col gap-8 justify-center items-end z-40 px-3 sm:pl-20 w-full">
          <div className="flex text-md justify-start leading-lg font-sans font-bold text-white">
            <button className="border-2 p-1 rounded-lg" title="Naspäť" onClick={close}>
              <FontAwesomeIcon icon="arrow-left" /> Naspäť
            </button>
          </div>
          {averagePrice.message === undefined && averagePrice.relevancy !== undefined ? (
            <>
              {/*<p className="w-11/12 mb-2 text-3xl leading-lg font-sans font-bold text-white">{`Priemerná cena je`} {avgPrice()} {`€. Vypočítaná na základe`} {countOfObjects()}
                        {` podobných nehnuteľností s presnosťou`} {relevancy()} {`%.`}</p> */}
              <div
                className={`w-full relative rounded-lg border-2 max-h-30 sm:max-h-16 overflow-hidden flex sm:flex-row justify-start`}
              >
                <div className="w-full flex flex-col items-center">
                  <p className={`w-full p-3 flex justify-start flex-col sm:flex-row font-sans font-bold text-white`}>
                    {`Priemerná cena je`} {avgPrice()} {`€. Vypočítaná na základe `} {countOfObjects()}
                    {` podobných nehnuteľností s presnosťou`} {relevancy()} {`%.`}
                  </p>
                  {1 ? <Info averagePrice={avgPrice} /> : null}
                </div>
                <div className="fixed flex justify-start items-center transform -translate-y-1/2 w-full px-3">
                  <h2 className="">
                    <span className="bg-white rounded-md px-2 py-1 text-sm font-medium text-sky-900">Hodnota</span>
                  </h2>
                </div>
              </div>
            </>
          ) : null}
          <Info averagePrice={averagePrice} />
        </div>
      ) : null}
    </div>
  );
};
export default Message;
