export const loginService = {
  setLogin: async (request) => {
    return await fetch(process.env.REACT_APP_AUTH_SERVICE + `login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request),
      credentials: "include",
    });
  },
};
