import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion";
import HistoryPropertyInfo from "../../History/HistoryPropertyInfo";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

export default function PropertyEssence({ setLvSubMenu, lvSubMenu, historyItem }) {
  return (
    <>
      <div>
        <div className="p-2 flex flex-row gap-x-2 font-bold">
          <p className="text-lg uppercase text-sky-500 text-start">Časť a: majetková podstata</p>
          <button
            onClick={() => setLvSubMenu({ ...lvSubMenu, partA: !lvSubMenu.partA })}
            className={`flex items-start z-10 w-8 p-1 text-black`}
          >
            {lvSubMenu.partA ? (
              <FontAwesomeIcon className="h-6" icon={faChevronUp} />
            ) : (
              <FontAwesomeIcon className="h-6" icon={faChevronDown} />
            )}{" "}
          </button>
        </div>
      </div>
      <AnimatePresence>
        {lvSubMenu.partA ? (
          <motion.div
            className="flex flex-col overflow-x-hidden px-2 w-full"
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            transition={{ duration: 0.5 }}
            exit={{ height: 0 }}
          >
            <HistoryPropertyInfo historyItem={historyItem} />
          </motion.div>
        ) : null}
      </AnimatePresence>
    </>
  );
}
