import { Fragment, React, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import owners from "../../Images/owners.png";
import { motion, AnimatePresence } from "framer-motion";

export default function HistoryOwner({ historyItem }) {
  library.add(faChevronDown);
  library.add(faChevronUp);

  const [isExpandedOwner, setIsExpandedOwner] = useState(true);
  const [owner, setOwner] = useState("auto");

  function sizeOwner() {
    setIsExpandedOwner(!isExpandedOwner);
    setOwner(0);
  }

  function uniqueID() {
    return Math.floor(Math.random() * Date.now() + Math.random());
  }

  function getTableBodyOwner() {
    if (historyItem?.response?.List?.byt instanceof Array) {
      return !historyItem ? null : (
        <tbody key={uniqueID()}>
          {historyItem?.response?.List?.byt?.map((item, index) => {
            return (
              <Fragment key={uniqueID()}>
                {item.hasOwnProperty("cislo_bytu") ? (
                  <tr key={index} className="border border-opacity-50 border-sky-500">
                    <td key={uniqueID()} className="text-center w-4">
                      Číslo bytu
                      <br />
                      {item.cislo_bytu}
                    </td>
                    <td key={uniqueID()} className="text-center w-6">
                      Vchod (číslo)
                      <br />
                      {item.vchod__cislo_}
                    </td>
                    <td key={uniqueID()} className="text-center w-8">
                      Poschodie
                      <br />
                      {item.poschodie}
                    </td>
                    <td key={uniqueID()} className="text-center w-8">
                      Súpisné číslo <br />
                      {item.supisne_cislo}
                    </td>
                    <td key={uniqueID()} className="text-center w-10">
                      Podiel priestoru na spoločných častiach a ...
                      <br />
                      {item.podiel_priestoru_na_spolocnych_castiach_a_}
                    </td>
                  </tr>
                ) : null}
                {item?.vlastnici instanceof Array ? (
                  //
                  <Fragment key={uniqueID()}>
                    {item?.vlastnici?.map((item, index) => {
                      return (
                        <Fragment key={uniqueID()}>
                          <tr key={uniqueID()} className="border border-opacity-50 border-sky-500">
                            <td className="text-center w-4">
                              Por.č. <br />
                              {item.por_c}
                            </td>
                            <td colSpan={3} className="text-center w-6">
                              Titul, priezvisko, meno, rodné meno, Miesto trvalého pobytu, Dátum narodenia <br />
                              {item.hasOwnProperty("nazov_sidlo_ico")
                                ? item.nazov_sidlo_ico
                                : item.titul_priezvisko_meno_rodne_meno_miesto_trvaleho_pobytu_datum_narodenia}
                            </td>
                            <td className="text-center w-8">
                              Spoluvlast. podiel <br />
                              {item.spoluvlast_podiel}
                            </td>
                          </tr>
                          {item.hasOwnProperty("titul_nadobudnutia") ? (
                            <tr key={uniqueID()} className="border border-opacity-50 border-sky-500">
                              <td className="w-8"></td>
                              <td colSpan={3} className="w-2">
                                Titul nadobudnutias <br />
                                {item.titul_nadobudnutia}
                              </td>
                            </tr>
                          ) : null}
                          {item.hasOwnProperty("ine_udaje") ? (
                            <tr key={uniqueID()} className="border border-opacity-50 border-sky-500">
                              <td className="w-8"></td>
                              <td className="w-2">
                                Iné údaje <br />
                                {item.ine_udaje}
                              </td>
                            </tr>
                          ) : null}
                          {item.hasOwnProperty("poznamky") ? (
                            <tr key={uniqueID()} className="border border-opacity-50 border-sky-500">
                              <td className="w-8"></td>
                              <td className="w-2">
                                Poznámky <br />
                                {item.poznamky}
                              </td>
                            </tr>
                          ) : null}
                        </Fragment>
                      );
                    })}
                  </Fragment>
                ) : //
                item.vlastnici.hasOwnProperty("por_c") ? (
                  <Fragment key={uniqueID()}>
                    <tr key={uniqueID()} className="border border-opacity-50 border-sky-500">
                      <td className="text-center w-4">
                        Por.č. <br />
                        {item.vlastnici.por_c}
                      </td>
                      <td colSpan={3} className="text-center w-6">
                        Titul, priezvisko, meno, rodné meno, Miesto trvalého pobytu, Dátum narodenia <br />
                        {item.vlastnici.hasOwnProperty("nazov_sidlo_ico")
                          ? item.vlastnici.nazov_sidlo_ico
                          : item.vlastnici.titul_priezvisko_meno_rodne_meno_miesto_trvaleho_pobytu_datum_narodenia}
                      </td>
                      <td className="text-center w-8">
                        Spoluvlast. podiel <br />
                        {item.vlastnici.spoluvlast_podiel}
                      </td>
                    </tr>
                  </Fragment>
                ) : null}
                {/*  */}
                {item.vlastnici.hasOwnProperty("titul_nadobudnutia") ? (
                  <tr key={uniqueID()} className="border border-opacity-50 border-sky-500">
                    <td className="w-8"></td>
                    <td colSpan={3} className="w-2">
                      Titul nadobudnutia <br />
                      {item.vlastnici.titul_nadobudnutia}
                    </td>
                  </tr>
                ) : null}
                {item.vlastnici.hasOwnProperty("ine_udaje") ? (
                  <tr key={uniqueID()} className="border border-opacity-50 border-sky-500">
                    <td className="w-8"></td>
                    <td className="w-2">
                      Iné údaje <br />
                      {item.vlastnici.ine_udaje}
                    </td>
                  </tr>
                ) : null}
                {item.vlastnici.hasOwnProperty("poznamky") ? (
                  <tr key={uniqueID()} className="border border-opacity-50 border-sky-500">
                    <td className="w-8"></td>
                    <td className="w-2">
                      Poznámky <br />
                      {item.vlastnici.poznamky}
                    </td>
                  </tr>
                ) : null}
              </Fragment>
            );
          })}
        </tbody>
      );
    }
    if (historyItem?.response?.List?.vlastnici instanceof Array) {
      return !historyItem ? null : (
        <tbody key={uniqueID()}>
          {historyItem?.response?.List?.vlastnici?.map((item, index) => {
            return (
              <Fragment key={uniqueID()}>
                {item.hasOwnProperty("por_c") ? (
                  <tr key={uniqueID()} className="border border-opacity-50 border-sky-500">
                    <td className="text-center w-8">
                      Por.č. <br />
                      {item.por_c}
                    </td>
                    <td className="text-center w-3/4">
                      Titul, priezvisko, meno, rodné meno, Miesto trvalého pobytu, Dátum narodenia <br />
                      {item.titul_priezvisko_meno_rodne_meno_miesto_trvaleho_pobytu_datum_narodenia_}
                    </td>
                    <td className="text-center">
                      Spoluvlast. podiel <br />
                      {item.spoluvlast_podiel}
                    </td>
                  </tr>
                ) : null}
                {item.hasOwnProperty("titul_nadobudnutia") ? (
                  <tr key={uniqueID()} className="border border-opacity-50 border-sky-500">
                    <td className="w-8"></td>
                    <td className="w-2">
                      Titul nadobudnutia <br />
                      {item.titul_nadobudnutia}
                    </td>
                  </tr>
                ) : null}
                {item.hasOwnProperty("ine_udaje") ? (
                  <tr key={uniqueID()} className="border border-opacity-50 border-sky-500">
                    <td className="w-8"></td>
                    <td className="w-2">
                      Iné údaje <br />
                      {item.ine_udaje}
                    </td>
                  </tr>
                ) : null}
                {item.hasOwnProperty("poznamky") ? (
                  <tr key={uniqueID()} className="border border-opacity-50 border-sky-500">
                    <td className="w-8"></td>
                    <td className="w-2">
                      Poznámky <br />
                      {item.poznamky}
                    </td>
                  </tr>
                ) : null}
              </Fragment>
            );
          })}
        </tbody>
      );
    }
    if (historyItem?.response?.List?.vlastnici instanceof Object) {
      return (
        <tbody>
          <>
            {historyItem?.response?.List?.vlastnici?.hasOwnProperty("por_c") ? (
              <tr key={uniqueID()} className="border border-opacity-50 border-sky-500">
                <td className="text-center w-8">
                  Por.č. <br />
                  {historyItem?.response?.List?.vlastnici?.por_c}
                </td>
                <td className="text-center w-3/4">
                  Titul, priezvisko, meno, rodné meno, Miesto trvalého pobytu, Dátum narodenia <br />
                  {
                    historyItem?.response?.List?.vlastnici
                      ?.titul_priezvisko_meno_rodne_meno_miesto_trvaleho_pobytu_datum_narodenia_
                  }
                </td>
                <td className="text-center">
                  Spoluvlast. podiel <br />
                  {historyItem?.response?.List?.vlastnici?.spoluvlast_podiel}
                </td>
              </tr>
            ) : null}
            {historyItem?.response?.List?.vlastnici?.hasOwnProperty("titul_nadobudnutia") ? (
              <tr key={uniqueID()} className="border border-opacity-50 border-sky-500">
                <td className="w-8"></td>
                <td className="w-2">
                  Titul nadobudnutia <br />
                  {historyItem?.response?.List?.vlastnici?.titul_nadobudnutia}
                </td>
              </tr>
            ) : null}
            {historyItem?.response?.List?.vlastnici?.hasOwnProperty("ine_udaje") ? (
              <tr key={uniqueID()} className="border border-opacity-50 border-sky-500">
                <td className="w-8"></td>
                <td className="w-2">
                  Iné údaje <br />
                  {historyItem?.response?.List?.vlastnici?.ine_udaje}
                </td>
              </tr>
            ) : null}
            {historyItem?.response?.List?.vlastnici?.hasOwnProperty("poznamky") ? (
              <tr key={uniqueID()} className="border border-opacity-50 border-sky-500">
                <td className="w-8"></td>
                <td className="w-2">
                  Poznámky <br />
                  {historyItem?.response?.List?.vlastnici?.poznamky}
                </td>
              </tr>
            ) : null}
          </>
        </tbody>
      );
    }
  }

  return (
    <>
      <div className="bg-transparent flex flex-row items-center">
        <img className="h-10" src={owners} alt="owners" />
        <p className="text-sky-500 font-medium text-base pr-2">Vlastníci</p>
        <button onClick={sizeOwner}>
          {isExpandedOwner ? <FontAwesomeIcon icon="chevron-up" /> : <FontAwesomeIcon icon="chevron-down" />}
        </button>
      </div>
      <AnimatePresence>
        {isExpandedOwner ? (
          <motion.div
            key={uniqueID()}
            className="flex flex-col overflow-scroll"
            initial={{ height: owner }}
            animate={{ height: "auto" }}
            transition={{ duration: 0.5 }}
            exit={{ height: 0 }}
          >
            <table key={uniqueID()} className="w-full table-fixed">
              {getTableBodyOwner()}
            </table>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </>
  );
}
