const UnitsGelnica = {
  Prakovce: [
    { lat: 48.763661, lng: 20.918822 },
    { lat: 48.7666312, lng: 20.918255 },
    { lat: 48.7736862, lng: 20.9164503 },
    { lat: 48.7773575, lng: 20.915669 },
    { lat: 48.7776213, lng: 20.9155455 },
    { lat: 48.7787709, lng: 20.9160254 },
    { lat: 48.77919, lng: 20.9161014 },
    { lat: 48.7797238, lng: 20.9159766 },
    { lat: 48.780022, lng: 20.9159421 },
    { lat: 48.7802943, lng: 20.9160017 },
    { lat: 48.7812181, lng: 20.9158606 },
    { lat: 48.7823106, lng: 20.9159495 },
    { lat: 48.7836045, lng: 20.9167284 },
    { lat: 48.7840543, lng: 20.9171169 },
    { lat: 48.7850698, lng: 20.9183215 },
    { lat: 48.7854071, lng: 20.918445 },
    { lat: 48.7860533, lng: 20.9182487 },
    { lat: 48.7863707, lng: 20.91791 },
    { lat: 48.7866871, lng: 20.917949 },
    { lat: 48.786869, lng: 20.9180699 },
    { lat: 48.7872624, lng: 20.9180534 },
    { lat: 48.7873478, lng: 20.9180461 },
    { lat: 48.7875061, lng: 20.9179003 },
    { lat: 48.7876719, lng: 20.9179778 },
    { lat: 48.7877516, lng: 20.9178378 },
    { lat: 48.788154, lng: 20.9180543 },
    { lat: 48.788257, lng: 20.9180378 },
    { lat: 48.7884873, lng: 20.9178237 },
    { lat: 48.7888542, lng: 20.9178552 },
    { lat: 48.789243, lng: 20.9175728 },
    { lat: 48.789659, lng: 20.9175274 },
    { lat: 48.7898958, lng: 20.9174034 },
    { lat: 48.7901849, lng: 20.9171347 },
    { lat: 48.7902327, lng: 20.9171337 },
    { lat: 48.7904055, lng: 20.9171301 },
    { lat: 48.7906226, lng: 20.9169923 },
    { lat: 48.7909255, lng: 20.9170627 },
    { lat: 48.7910918, lng: 20.917022 },
    { lat: 48.7912725, lng: 20.9168424 },
    { lat: 48.7914099, lng: 20.9168453 },
    { lat: 48.7915909, lng: 20.9171062 },
    { lat: 48.7922513, lng: 20.9173646 },
    { lat: 48.7924452, lng: 20.9172025 },
    { lat: 48.7927804, lng: 20.9171623 },
    { lat: 48.7931841, lng: 20.9174177 },
    { lat: 48.7932951, lng: 20.9173038 },
    { lat: 48.7939411, lng: 20.917503 },
    { lat: 48.7946048, lng: 20.9173199 },
    { lat: 48.7949918, lng: 20.9173467 },
    { lat: 48.7955241, lng: 20.9172819 },
    { lat: 48.7956079, lng: 20.9171926 },
    { lat: 48.7959698, lng: 20.9171753 },
    { lat: 48.7972077, lng: 20.9166742 },
    { lat: 48.797554, lng: 20.916661 },
    { lat: 48.7983832, lng: 20.9170474 },
    { lat: 48.7986414, lng: 20.9169628 },
    { lat: 48.7993033, lng: 20.916964 },
    { lat: 48.7993559, lng: 20.9172513 },
    { lat: 48.7998286, lng: 20.9172868 },
    { lat: 48.7999838, lng: 20.9175158 },
    { lat: 48.80009, lng: 20.9175611 },
    { lat: 48.8006869, lng: 20.9174396 },
    { lat: 48.8008621, lng: 20.9175571 },
    { lat: 48.8010235, lng: 20.9174378 },
    { lat: 48.8013898, lng: 20.9173834 },
    { lat: 48.8015428, lng: 20.9172837 },
    { lat: 48.8019272, lng: 20.9174335 },
    { lat: 48.8021442, lng: 20.9172957 },
    { lat: 48.8023636, lng: 20.9173583 },
    { lat: 48.8026683, lng: 20.9172794 },
    { lat: 48.8028895, lng: 20.9174605 },
    { lat: 48.8029858, lng: 20.9173519 },
    { lat: 48.8031933, lng: 20.9172934 },
    { lat: 48.8037302, lng: 20.9172961 },
    { lat: 48.8040343, lng: 20.9170312 },
    { lat: 48.8042093, lng: 20.9170183 },
    { lat: 48.8043269, lng: 20.9168336 },
    { lat: 48.8045956, lng: 20.9167517 },
    { lat: 48.8048213, lng: 20.9165392 },
    { lat: 48.805331, lng: 20.9165948 },
    { lat: 48.8056882, lng: 20.9163418 },
    { lat: 48.8058433, lng: 20.9164351 },
    { lat: 48.8061591, lng: 20.9163567 },
    { lat: 48.8065243, lng: 20.9157378 },
    { lat: 48.8069973, lng: 20.915678 },
    { lat: 48.8072109, lng: 20.9154375 },
    { lat: 48.807307, lng: 20.9166124 },
    { lat: 48.8082589, lng: 20.9169044 },
    { lat: 48.8093118, lng: 20.9174207 },
    { lat: 48.8095555, lng: 20.9176036 },
    { lat: 48.8107146, lng: 20.9190312 },
    { lat: 48.8115751, lng: 20.9213085 },
    { lat: 48.8122305, lng: 20.9223906 },
    { lat: 48.8132639, lng: 20.9237278 },
    { lat: 48.8133107, lng: 20.9235777 },
    { lat: 48.8133918, lng: 20.9235125 },
    { lat: 48.8196136, lng: 20.92283 },
    { lat: 48.8197102, lng: 20.9224571 },
    { lat: 48.8198608, lng: 20.9222858 },
    { lat: 48.8199637, lng: 20.9220286 },
    { lat: 48.8202786, lng: 20.9206226 },
    { lat: 48.8200319, lng: 20.9205485 },
    { lat: 48.8200513, lng: 20.9201566 },
    { lat: 48.819725, lng: 20.9197646 },
    { lat: 48.8196364, lng: 20.9195697 },
    { lat: 48.8196066, lng: 20.9193523 },
    { lat: 48.8176997, lng: 20.9178526 },
    { lat: 48.8172877, lng: 20.9174477 },
    { lat: 48.8168384, lng: 20.9168757 },
    { lat: 48.8161757, lng: 20.9158898 },
    { lat: 48.8155078, lng: 20.9144029 },
    { lat: 48.8154735, lng: 20.9139256 },
    { lat: 48.816856, lng: 20.9135673 },
    { lat: 48.8183621, lng: 20.9126673 },
    { lat: 48.8196557, lng: 20.9120426 },
    { lat: 48.820084, lng: 20.9103685 },
    { lat: 48.821065, lng: 20.9096848 },
    { lat: 48.8215553, lng: 20.9092027 },
    { lat: 48.8223152, lng: 20.9083433 },
    { lat: 48.82279, lng: 20.9076752 },
    { lat: 48.8232755, lng: 20.9073135 },
    { lat: 48.8234501, lng: 20.9069902 },
    { lat: 48.8237736, lng: 20.9066029 },
    { lat: 48.8243424, lng: 20.9061186 },
    { lat: 48.8247186, lng: 20.9056922 },
    { lat: 48.8248451, lng: 20.9054023 },
    { lat: 48.8256986, lng: 20.9040489 },
    { lat: 48.8263065, lng: 20.9028878 },
    { lat: 48.8265496, lng: 20.9022948 },
    { lat: 48.8277223, lng: 20.9010254 },
    { lat: 48.8282549, lng: 20.9010604 },
    { lat: 48.8286396, lng: 20.9015196 },
    { lat: 48.8287998, lng: 20.9013339 },
    { lat: 48.8292393, lng: 20.901016 },
    { lat: 48.8294666, lng: 20.9010148 },
    { lat: 48.8297646, lng: 20.9007033 },
    { lat: 48.8300383, lng: 20.9001157 },
    { lat: 48.8301388, lng: 20.8994284 },
    { lat: 48.8302144, lng: 20.8992378 },
    { lat: 48.8302595, lng: 20.8991335 },
    { lat: 48.8304649, lng: 20.8990215 },
    { lat: 48.8305779, lng: 20.8987029 },
    { lat: 48.8308924, lng: 20.8974534 },
    { lat: 48.8321779, lng: 20.8970217 },
    { lat: 48.8323437, lng: 20.8970352 },
    { lat: 48.8325188, lng: 20.8971852 },
    { lat: 48.8326165, lng: 20.8971784 },
    { lat: 48.8329619, lng: 20.8969721 },
    { lat: 48.8333118, lng: 20.8969052 },
    { lat: 48.8334396, lng: 20.8969538 },
    { lat: 48.8341388, lng: 20.8968226 },
    { lat: 48.8348067, lng: 20.8963075 },
    { lat: 48.8353036, lng: 20.8963871 },
    { lat: 48.8354586, lng: 20.896216 },
    { lat: 48.8356594, lng: 20.8961264 },
    { lat: 48.8358014, lng: 20.8958988 },
    { lat: 48.8362676, lng: 20.895875 },
    { lat: 48.83726, lng: 20.895598 },
    { lat: 48.8381013, lng: 20.8952426 },
    { lat: 48.8392544, lng: 20.8955591 },
    { lat: 48.839354, lng: 20.8957026 },
    { lat: 48.8394699, lng: 20.8958703 },
    { lat: 48.8400779, lng: 20.8962428 },
    { lat: 48.8405244, lng: 20.8960972 },
    { lat: 48.8406174, lng: 20.8961536 },
    { lat: 48.84131, lng: 20.8960325 },
    { lat: 48.8416406, lng: 20.896155 },
    { lat: 48.8418369, lng: 20.8963999 },
    { lat: 48.8431315, lng: 20.8964673 },
    { lat: 48.843396, lng: 20.896267 },
    { lat: 48.843942, lng: 20.8962387 },
    { lat: 48.8441634, lng: 20.8963619 },
    { lat: 48.8443245, lng: 20.8962651 },
    { lat: 48.8447183, lng: 20.896392 },
    { lat: 48.8454095, lng: 20.8963263 },
    { lat: 48.8455018, lng: 20.8962648 },
    { lat: 48.8458833, lng: 20.8962283 },
    { lat: 48.8462666, lng: 20.8959504 },
    { lat: 48.8467011, lng: 20.8958119 },
    { lat: 48.8467225, lng: 20.8958509 },
    { lat: 48.8469177, lng: 20.8952639 },
    { lat: 48.8471941, lng: 20.894984 },
    { lat: 48.8473519, lng: 20.8944924 },
    { lat: 48.8474737, lng: 20.8944329 },
    { lat: 48.847618, lng: 20.8944728 },
    { lat: 48.8478102, lng: 20.8946669 },
    { lat: 48.8479836, lng: 20.8946662 },
    { lat: 48.8481895, lng: 20.8943968 },
    { lat: 48.848465, lng: 20.8938253 },
    { lat: 48.8485828, lng: 20.8936828 },
    { lat: 48.8490455, lng: 20.8936538 },
    { lat: 48.8495558, lng: 20.8930662 },
    { lat: 48.8499804, lng: 20.8924147 },
    { lat: 48.8503319, lng: 20.8920192 },
    { lat: 48.8508119, lng: 20.8910771 },
    { lat: 48.851096, lng: 20.8901629 },
    { lat: 48.8516331, lng: 20.8893089 },
    { lat: 48.8520698, lng: 20.8891036 },
    { lat: 48.8522525, lng: 20.8888177 },
    { lat: 48.8524326, lng: 20.8882253 },
    { lat: 48.8529951, lng: 20.8870244 },
    { lat: 48.8537002, lng: 20.8868032 },
    { lat: 48.8541387, lng: 20.8858953 },
    { lat: 48.8546811, lng: 20.8853314 },
    { lat: 48.8556064, lng: 20.8841371 },
    { lat: 48.8559281, lng: 20.8835701 },
    { lat: 48.856188, lng: 20.882911 },
    { lat: 48.8565352, lng: 20.8813368 },
    { lat: 48.8569102, lng: 20.8801868 },
    { lat: 48.8577139, lng: 20.878728 },
    { lat: 48.8586124, lng: 20.8773801 },
    { lat: 48.859585, lng: 20.875718 },
    { lat: 48.859569, lng: 20.875401 },
    { lat: 48.859396, lng: 20.875014 },
    { lat: 48.859343, lng: 20.874721 },
    { lat: 48.859254, lng: 20.874348 },
    { lat: 48.859068, lng: 20.874111 },
    { lat: 48.85897, lng: 20.873813 },
    { lat: 48.856247, lng: 20.8746624 },
    { lat: 48.8546851, lng: 20.8751051 },
    { lat: 48.8541256, lng: 20.8752014 },
    { lat: 48.853526, lng: 20.8755747 },
    { lat: 48.8528747, lng: 20.8757183 },
    { lat: 48.8523772, lng: 20.8762484 },
    { lat: 48.8514113, lng: 20.8775007 },
    { lat: 48.8509936, lng: 20.877696 },
    { lat: 48.8502437, lng: 20.8777208 },
    { lat: 48.849587, lng: 20.8775427 },
    { lat: 48.849464, lng: 20.8775999 },
    { lat: 48.8490842, lng: 20.8774177 },
    { lat: 48.8488651, lng: 20.8772336 },
    { lat: 48.8486195, lng: 20.8772946 },
    { lat: 48.8485459, lng: 20.8772178 },
    { lat: 48.8484419, lng: 20.8772381 },
    { lat: 48.8484057, lng: 20.8771659 },
    { lat: 48.8482756, lng: 20.8771472 },
    { lat: 48.8482122, lng: 20.8772343 },
    { lat: 48.8480274, lng: 20.8770994 },
    { lat: 48.8478157, lng: 20.8770945 },
    { lat: 48.8477205, lng: 20.8772081 },
    { lat: 48.8475314, lng: 20.877209 },
    { lat: 48.8473983, lng: 20.8770968 },
    { lat: 48.8472143, lng: 20.8772842 },
    { lat: 48.8471301, lng: 20.8772551 },
    { lat: 48.8469638, lng: 20.8773932 },
    { lat: 48.8468858, lng: 20.8773539 },
    { lat: 48.8466013, lng: 20.8774909 },
    { lat: 48.8461844, lng: 20.8775793 },
    { lat: 48.8461162, lng: 20.8776943 },
    { lat: 48.8460073, lng: 20.8776689 },
    { lat: 48.8458916, lng: 20.8778395 },
    { lat: 48.8457181, lng: 20.8779667 },
    { lat: 48.8456749, lng: 20.8778893 },
    { lat: 48.8455095, lng: 20.8779566 },
    { lat: 48.8452207, lng: 20.8782043 },
    { lat: 48.8451539, lng: 20.8783268 },
    { lat: 48.8450409, lng: 20.8783746 },
    { lat: 48.844998, lng: 20.8785409 },
    { lat: 48.844825, lng: 20.8785176 },
    { lat: 48.8447469, lng: 20.8785716 },
    { lat: 48.8445839, lng: 20.8784761 },
    { lat: 48.844454, lng: 20.8785599 },
    { lat: 48.8443211, lng: 20.878531 },
    { lat: 48.8441573, lng: 20.8782143 },
    { lat: 48.8438695, lng: 20.878027 },
    { lat: 48.843761, lng: 20.8778483 },
    { lat: 48.8437693, lng: 20.8777583 },
    { lat: 48.8433993, lng: 20.8772784 },
    { lat: 48.8433847, lng: 20.8771115 },
    { lat: 48.843227, lng: 20.877011 },
    { lat: 48.8431931, lng: 20.876806 },
    { lat: 48.8428553, lng: 20.8764736 },
    { lat: 48.8427711, lng: 20.8765499 },
    { lat: 48.842418, lng: 20.8762592 },
    { lat: 48.8423544, lng: 20.8763088 },
    { lat: 48.842213, lng: 20.8761894 },
    { lat: 48.8421726, lng: 20.8762555 },
    { lat: 48.8421205, lng: 20.8762153 },
    { lat: 48.8418856, lng: 20.8763273 },
    { lat: 48.8416381, lng: 20.8762631 },
    { lat: 48.8414246, lng: 20.876442 },
    { lat: 48.8412484, lng: 20.8764211 },
    { lat: 48.8409249, lng: 20.8765415 },
    { lat: 48.8408896, lng: 20.8766608 },
    { lat: 48.8407515, lng: 20.8767345 },
    { lat: 48.8406763, lng: 20.8768744 },
    { lat: 48.8406867, lng: 20.8770129 },
    { lat: 48.8404913, lng: 20.8770643 },
    { lat: 48.8403366, lng: 20.8770167 },
    { lat: 48.8402089, lng: 20.8768706 },
    { lat: 48.8398151, lng: 20.8772066 },
    { lat: 48.8396091, lng: 20.8775636 },
    { lat: 48.8394652, lng: 20.8775699 },
    { lat: 48.8392777, lng: 20.8774505 },
    { lat: 48.8391683, lng: 20.8775438 },
    { lat: 48.8391469, lng: 20.8777105 },
    { lat: 48.8389735, lng: 20.8779389 },
    { lat: 48.8388313, lng: 20.8780279 },
    { lat: 48.8385261, lng: 20.8780214 },
    { lat: 48.8383421, lng: 20.8783395 },
    { lat: 48.8378737, lng: 20.8785366 },
    { lat: 48.8378427, lng: 20.8786155 },
    { lat: 48.8377608, lng: 20.8785866 },
    { lat: 48.8376456, lng: 20.8787421 },
    { lat: 48.8371953, lng: 20.8789352 },
    { lat: 48.8371553, lng: 20.8791192 },
    { lat: 48.8365839, lng: 20.8793297 },
    { lat: 48.836605, lng: 20.8794234 },
    { lat: 48.836562, lng: 20.8795107 },
    { lat: 48.836356, lng: 20.8796112 },
    { lat: 48.8362925, lng: 20.8795615 },
    { lat: 48.8361944, lng: 20.8796603 },
    { lat: 48.8360869, lng: 20.8796748 },
    { lat: 48.8360027, lng: 20.8798771 },
    { lat: 48.835936, lng: 20.8798023 },
    { lat: 48.8356164, lng: 20.8800385 },
    { lat: 48.8359241, lng: 20.8803442 },
    { lat: 48.8359519, lng: 20.8804685 },
    { lat: 48.8361181, lng: 20.8803894 },
    { lat: 48.8361479, lng: 20.8802388 },
    { lat: 48.8362726, lng: 20.8803356 },
    { lat: 48.8364214, lng: 20.8803393 },
    { lat: 48.8366944, lng: 20.8806188 },
    { lat: 48.836823, lng: 20.8806298 },
    { lat: 48.8368897, lng: 20.8808079 },
    { lat: 48.8370151, lng: 20.880916 },
    { lat: 48.8370801, lng: 20.8813058 },
    { lat: 48.8371513, lng: 20.8813481 },
    { lat: 48.8373048, lng: 20.8816219 },
    { lat: 48.837902, lng: 20.8818711 },
    { lat: 48.8383025, lng: 20.8818637 },
    { lat: 48.8387502, lng: 20.8817269 },
    { lat: 48.8389196, lng: 20.8818366 },
    { lat: 48.8390846, lng: 20.8817576 },
    { lat: 48.839384, lng: 20.8818238 },
    { lat: 48.8396584, lng: 20.8820815 },
    { lat: 48.8398942, lng: 20.8821985 },
    { lat: 48.8400861, lng: 20.8827749 },
    { lat: 48.840233, lng: 20.8830571 },
    { lat: 48.8392596, lng: 20.8874959 },
    { lat: 48.8383716, lng: 20.8866719 },
    { lat: 48.8380223, lng: 20.8864297 },
    { lat: 48.8377452, lng: 20.8863259 },
    { lat: 48.8372654, lng: 20.8866171 },
    { lat: 48.8369657, lng: 20.8869749 },
    { lat: 48.8365564, lng: 20.8871806 },
    { lat: 48.8343467, lng: 20.8876432 },
    { lat: 48.8330877, lng: 20.8873963 },
    { lat: 48.8326842, lng: 20.8876404 },
    { lat: 48.8322274, lng: 20.8882456 },
    { lat: 48.8317518, lng: 20.8885899 },
    { lat: 48.8307914, lng: 20.8890425 },
    { lat: 48.829515, lng: 20.8899084 },
    { lat: 48.8274698, lng: 20.8898465 },
    { lat: 48.8263289, lng: 20.8893621 },
    { lat: 48.8258452, lng: 20.8893695 },
    { lat: 48.8255388, lng: 20.8894255 },
    { lat: 48.8248568, lng: 20.8897273 },
    { lat: 48.8243618, lng: 20.8898067 },
    { lat: 48.8234874, lng: 20.890245 },
    { lat: 48.8229459, lng: 20.8903697 },
    { lat: 48.8223714, lng: 20.8906578 },
    { lat: 48.8219564, lng: 20.8907312 },
    { lat: 48.8210681, lng: 20.8911898 },
    { lat: 48.8199162, lng: 20.8890961 },
    { lat: 48.8202188, lng: 20.8877659 },
    { lat: 48.8205878, lng: 20.8867716 },
    { lat: 48.8209185, lng: 20.8861964 },
    { lat: 48.8216082, lng: 20.885626 },
    { lat: 48.8218359, lng: 20.8853743 },
    { lat: 48.8221632, lng: 20.885198 },
    { lat: 48.8221214, lng: 20.8844 },
    { lat: 48.8224187, lng: 20.8844089 },
    { lat: 48.8229103, lng: 20.8842298 },
    { lat: 48.8240196, lng: 20.8840138 },
    { lat: 48.8249454, lng: 20.8836354 },
    { lat: 48.8265036, lng: 20.8831167 },
    { lat: 48.8276852, lng: 20.8823849 },
    { lat: 48.8281619, lng: 20.8816718 },
    { lat: 48.8280967, lng: 20.8815457 },
    { lat: 48.8280921, lng: 20.8812428 },
    { lat: 48.827938, lng: 20.8806807 },
    { lat: 48.8278029, lng: 20.8804184 },
    { lat: 48.8275146, lng: 20.8800844 },
    { lat: 48.8273949, lng: 20.8797769 },
    { lat: 48.827364, lng: 20.8793959 },
    { lat: 48.8271389, lng: 20.8792346 },
    { lat: 48.8269649, lng: 20.8789459 },
    { lat: 48.8269731, lng: 20.8784959 },
    { lat: 48.8267989, lng: 20.8783405 },
    { lat: 48.8267542, lng: 20.8781486 },
    { lat: 48.8266007, lng: 20.8779081 },
    { lat: 48.8265856, lng: 20.8777292 },
    { lat: 48.826352, lng: 20.8769533 },
    { lat: 48.8261828, lng: 20.8768465 },
    { lat: 48.8261805, lng: 20.8767435 },
    { lat: 48.8263256, lng: 20.8763109 },
    { lat: 48.8262342, lng: 20.8758104 },
    { lat: 48.8260023, lng: 20.8756414 },
    { lat: 48.8259555, lng: 20.8753989 },
    { lat: 48.8256713, lng: 20.8753147 },
    { lat: 48.8255282, lng: 20.8751101 },
    { lat: 48.8255499, lng: 20.8746601 },
    { lat: 48.8251448, lng: 20.8743943 },
    { lat: 48.8249882, lng: 20.8741347 },
    { lat: 48.8249773, lng: 20.8737115 },
    { lat: 48.8249323, lng: 20.8735821 },
    { lat: 48.8246831, lng: 20.8733701 },
    { lat: 48.8244165, lng: 20.8730174 },
    { lat: 48.8241936, lng: 20.8729469 },
    { lat: 48.8240938, lng: 20.8726908 },
    { lat: 48.8240139, lng: 20.872727 },
    { lat: 48.8237436, lng: 20.8722302 },
    { lat: 48.8234675, lng: 20.8719229 },
    { lat: 48.8237743, lng: 20.8711479 },
    { lat: 48.8244694, lng: 20.8708723 },
    { lat: 48.8252952, lng: 20.8715203 },
    { lat: 48.8255945, lng: 20.8724375 },
    { lat: 48.8254396, lng: 20.8725853 },
    { lat: 48.8255436, lng: 20.8727901 },
    { lat: 48.8256784, lng: 20.8726484 },
    { lat: 48.8259611, lng: 20.8731154 },
    { lat: 48.8264011, lng: 20.8741076 },
    { lat: 48.8264862, lng: 20.8739934 },
    { lat: 48.8263366, lng: 20.8735364 },
    { lat: 48.825986, lng: 20.8700601 },
    { lat: 48.8256066, lng: 20.8686423 },
    { lat: 48.8255061, lng: 20.8643551 },
    { lat: 48.8254112, lng: 20.86296 },
    { lat: 48.8248946, lng: 20.8605978 },
    { lat: 48.8244486, lng: 20.8600323 },
    { lat: 48.8238947, lng: 20.8591785 },
    { lat: 48.8245483, lng: 20.8584062 },
    { lat: 48.8242109, lng: 20.8576965 },
    { lat: 48.8232663, lng: 20.8562461 },
    { lat: 48.8230382, lng: 20.8557643 },
    { lat: 48.8228883, lng: 20.855171 },
    { lat: 48.8228534, lng: 20.8548076 },
    { lat: 48.8229863, lng: 20.8544792 },
    { lat: 48.8219245, lng: 20.8526684 },
    { lat: 48.8201625, lng: 20.8493603 },
    { lat: 48.819175, lng: 20.8474258 },
    { lat: 48.8154406, lng: 20.8401628 },
    { lat: 48.8154168, lng: 20.8401893 },
    { lat: 48.8135618, lng: 20.8417923 },
    { lat: 48.8068329, lng: 20.8467098 },
    { lat: 48.8031573, lng: 20.8513795 },
    { lat: 48.8017584, lng: 20.8540123 },
    { lat: 48.8017887, lng: 20.8544699 },
    { lat: 48.8017132, lng: 20.8547575 },
    { lat: 48.8017124, lng: 20.8550297 },
    { lat: 48.801632, lng: 20.8554431 },
    { lat: 48.8016478, lng: 20.856036 },
    { lat: 48.8015966, lng: 20.8564478 },
    { lat: 48.8016256, lng: 20.8566651 },
    { lat: 48.8015981, lng: 20.8570479 },
    { lat: 48.8013795, lng: 20.8574358 },
    { lat: 48.801143, lng: 20.8580217 },
    { lat: 48.801037, lng: 20.858411 },
    { lat: 48.801043, lng: 20.8590822 },
    { lat: 48.800943, lng: 20.859184 },
    { lat: 48.7998875, lng: 20.8594897 },
    { lat: 48.7991429, lng: 20.8592429 },
    { lat: 48.7987106, lng: 20.8594033 },
    { lat: 48.798321, lng: 20.8593714 },
    { lat: 48.7980514, lng: 20.8594544 },
    { lat: 48.7974422, lng: 20.8594165 },
    { lat: 48.7964535, lng: 20.8588408 },
    { lat: 48.7961385, lng: 20.8587196 },
    { lat: 48.795654, lng: 20.8583427 },
    { lat: 48.7946405, lng: 20.8577737 },
    { lat: 48.7902681, lng: 20.854779 },
    { lat: 48.7901584, lng: 20.8547951 },
    { lat: 48.7898923, lng: 20.8551763 },
    { lat: 48.7897263, lng: 20.8555834 },
    { lat: 48.7894416, lng: 20.8558861 },
    { lat: 48.7889662, lng: 20.856139 },
    { lat: 48.7888482, lng: 20.8561448 },
    { lat: 48.7879992, lng: 20.8556753 },
    { lat: 48.786744, lng: 20.855804 },
    { lat: 48.785831, lng: 20.8556673 },
    { lat: 48.7851676, lng: 20.8564144 },
    { lat: 48.7849166, lng: 20.8568411 },
    { lat: 48.78432, lng: 20.858838 },
    { lat: 48.7837347, lng: 20.8602871 },
    { lat: 48.7825974, lng: 20.8607983 },
    { lat: 48.7811829, lng: 20.8602587 },
    { lat: 48.779453, lng: 20.859598 },
    { lat: 48.778958, lng: 20.859774 },
    { lat: 48.777145, lng: 20.862108 },
    { lat: 48.776817, lng: 20.862278 },
    { lat: 48.776277, lng: 20.863149 },
    { lat: 48.775971, lng: 20.863789 },
    { lat: 48.775454, lng: 20.86439 },
    { lat: 48.774139, lng: 20.864987 },
    { lat: 48.7735143, lng: 20.8660196 },
    { lat: 48.773089, lng: 20.868022 },
    { lat: 48.77286, lng: 20.868475 },
    { lat: 48.77192, lng: 20.870278 },
    { lat: 48.7709608, lng: 20.872832 },
    { lat: 48.77002, lng: 20.876763 },
    { lat: 48.769934, lng: 20.878378 },
    { lat: 48.769897, lng: 20.879099 },
    { lat: 48.769441, lng: 20.880763 },
    { lat: 48.769397, lng: 20.881431 },
    { lat: 48.769352, lng: 20.882082 },
    { lat: 48.76932, lng: 20.882636 },
    { lat: 48.769292, lng: 20.883112 },
    { lat: 48.769265, lng: 20.88452 },
    { lat: 48.769318, lng: 20.885568 },
    { lat: 48.769146, lng: 20.887358 },
    { lat: 48.769044, lng: 20.888439 },
    { lat: 48.768711, lng: 20.889689 },
    { lat: 48.768582, lng: 20.890179 },
    { lat: 48.768574, lng: 20.890294 },
    { lat: 48.768498, lng: 20.891506 },
    { lat: 48.768713, lng: 20.892593 },
    { lat: 48.769202, lng: 20.893481 },
    { lat: 48.769937, lng: 20.894819 },
    { lat: 48.770232, lng: 20.89574 },
    { lat: 48.77043, lng: 20.896359 },
    { lat: 48.770454, lng: 20.89639 },
    { lat: 48.770906, lng: 20.896979 },
    { lat: 48.770878, lng: 20.897143 },
    { lat: 48.770655, lng: 20.898461 },
    { lat: 48.770204, lng: 20.899145 },
    { lat: 48.768988, lng: 20.900981 },
    { lat: 48.768298, lng: 20.902217 },
    { lat: 48.767927, lng: 20.902961 },
    { lat: 48.767838, lng: 20.903132 },
    { lat: 48.767407, lng: 20.903772 },
    { lat: 48.767287, lng: 20.904144 },
    { lat: 48.766934, lng: 20.905947 },
    { lat: 48.766833, lng: 20.906432 },
    { lat: 48.766611, lng: 20.907294 },
    { lat: 48.766602, lng: 20.908026 },
    { lat: 48.766483, lng: 20.908989 },
    { lat: 48.766439, lng: 20.909539 },
    { lat: 48.766177, lng: 20.910385 },
    { lat: 48.766098, lng: 20.911114 },
    { lat: 48.765934, lng: 20.91226 },
    { lat: 48.765787, lng: 20.913699 },
    { lat: 48.765381, lng: 20.914561 },
    { lat: 48.765111, lng: 20.915481 },
    { lat: 48.764745, lng: 20.916996 },
    { lat: 48.764684, lng: 20.917068 },
    { lat: 48.764626, lng: 20.917136 },
    { lat: 48.764236, lng: 20.917592 },
    { lat: 48.763661, lng: 20.918822 },
  ],
  Smolník: [
    { lat: 48.680905, lng: 20.698685 },
    { lat: 48.680781, lng: 20.698781 },
    { lat: 48.680294, lng: 20.699297 },
    { lat: 48.679946, lng: 20.699638 },
    { lat: 48.679633, lng: 20.700004 },
    { lat: 48.678835, lng: 20.701138 },
    { lat: 48.678453, lng: 20.701671 },
    { lat: 48.677999, lng: 20.702196 },
    { lat: 48.67779, lng: 20.702621 },
    { lat: 48.677673, lng: 20.702739 },
    { lat: 48.677431, lng: 20.702865 },
    { lat: 48.677279, lng: 20.702999 },
    { lat: 48.676914, lng: 20.703432 },
    { lat: 48.676528, lng: 20.703859 },
    { lat: 48.676137, lng: 20.705119 },
    { lat: 48.6761287, lng: 20.7051457 },
    { lat: 48.675981, lng: 20.70562 },
    { lat: 48.675493, lng: 20.706356 },
    { lat: 48.675361, lng: 20.706489 },
    { lat: 48.67516, lng: 20.706937 },
    { lat: 48.674821, lng: 20.707903 },
    { lat: 48.674366, lng: 20.708776 },
    { lat: 48.673712, lng: 20.710483 },
    { lat: 48.673552, lng: 20.710961 },
    { lat: 48.673114, lng: 20.711436 },
    { lat: 48.672872, lng: 20.711743 },
    { lat: 48.672829, lng: 20.711954 },
    { lat: 48.672746, lng: 20.712252 },
    { lat: 48.672504, lng: 20.712659 },
    { lat: 48.672429, lng: 20.713152 },
    { lat: 48.672214, lng: 20.714098 },
    { lat: 48.67206, lng: 20.714323 },
    { lat: 48.671906, lng: 20.714781 },
    { lat: 48.671765, lng: 20.715288 },
    { lat: 48.671478, lng: 20.716126 },
    { lat: 48.67134, lng: 20.717023 },
    { lat: 48.67113, lng: 20.71793 },
    { lat: 48.671161, lng: 20.718669 },
    { lat: 48.671056, lng: 20.719485 },
    { lat: 48.671027, lng: 20.71951 },
    { lat: 48.6708299, lng: 20.719677 },
    { lat: 48.670694, lng: 20.720261 },
    { lat: 48.670446, lng: 20.721022 },
    { lat: 48.670227, lng: 20.721288 },
    { lat: 48.670221, lng: 20.722385 },
    { lat: 48.670257, lng: 20.722937 },
    { lat: 48.670391, lng: 20.724999 },
    { lat: 48.670159, lng: 20.725973 },
    { lat: 48.6699125, lng: 20.7269533 },
    { lat: 48.669184, lng: 20.728163 },
    { lat: 48.668366, lng: 20.729937 },
    { lat: 48.66826, lng: 20.730152 },
    { lat: 48.667426, lng: 20.731866 },
    { lat: 48.6675747, lng: 20.7322715 },
    { lat: 48.667953, lng: 20.733361 },
    { lat: 48.66959, lng: 20.734952 },
    { lat: 48.670021, lng: 20.735385 },
    { lat: 48.670115, lng: 20.73548 },
    { lat: 48.670485, lng: 20.735853 },
    { lat: 48.670902, lng: 20.736263 },
    { lat: 48.671324, lng: 20.736692 },
    { lat: 48.671337, lng: 20.736732 },
    { lat: 48.671772, lng: 20.737731 },
    { lat: 48.673214, lng: 20.740575 },
    { lat: 48.673219, lng: 20.7405849 },
    { lat: 48.673355, lng: 20.740777 },
    { lat: 48.673364, lng: 20.740802 },
    { lat: 48.673437, lng: 20.740807 },
    { lat: 48.673596, lng: 20.740879 },
    { lat: 48.673862, lng: 20.74107 },
    { lat: 48.674488, lng: 20.741813 },
    { lat: 48.674766, lng: 20.742075 },
    { lat: 48.675558, lng: 20.742993 },
    { lat: 48.675878, lng: 20.743227 },
    { lat: 48.676465, lng: 20.743275 },
    { lat: 48.676819, lng: 20.74329 },
    { lat: 48.677228, lng: 20.743275 },
    { lat: 48.677563, lng: 20.743219 },
    { lat: 48.677923, lng: 20.742993 },
    { lat: 48.678312, lng: 20.742576 },
    { lat: 48.678543, lng: 20.74254 },
    { lat: 48.679021, lng: 20.742625 },
    { lat: 48.679631, lng: 20.742626 },
    { lat: 48.680225, lng: 20.742663 },
    { lat: 48.681097, lng: 20.742486 },
    { lat: 48.681438, lng: 20.742302 },
    { lat: 48.68163, lng: 20.742164 },
    { lat: 48.681945, lng: 20.741763 },
    { lat: 48.682441, lng: 20.741608 },
    { lat: 48.682987, lng: 20.741218 },
    { lat: 48.683663, lng: 20.740646 },
    { lat: 48.684033, lng: 20.740193 },
    { lat: 48.684311, lng: 20.73992 },
    { lat: 48.684898, lng: 20.739425 },
    { lat: 48.685229, lng: 20.739095 },
    { lat: 48.685598, lng: 20.738909 },
    { lat: 48.685856, lng: 20.738915 },
    { lat: 48.686549, lng: 20.739172 },
    { lat: 48.687189, lng: 20.739552 },
    { lat: 48.687659, lng: 20.739943 },
    { lat: 48.688219, lng: 20.740603 },
    { lat: 48.688655, lng: 20.740913 },
    { lat: 48.68947, lng: 20.741958 },
    { lat: 48.689743, lng: 20.742364 },
    { lat: 48.689946, lng: 20.742792 },
    { lat: 48.690166, lng: 20.743216 },
    { lat: 48.690386, lng: 20.743838 },
    { lat: 48.690757, lng: 20.744576 },
    { lat: 48.690929, lng: 20.744788 },
    { lat: 48.691101, lng: 20.745092 },
    { lat: 48.691496, lng: 20.745391 },
    { lat: 48.692064, lng: 20.745799 },
    { lat: 48.692233, lng: 20.745915 },
    { lat: 48.692705, lng: 20.746196 },
    { lat: 48.693419, lng: 20.746347 },
    { lat: 48.693926, lng: 20.74626 },
    { lat: 48.694325, lng: 20.746299 },
    { lat: 48.694766, lng: 20.746528 },
    { lat: 48.695167, lng: 20.746865 },
    { lat: 48.695927, lng: 20.748017 },
    { lat: 48.696052, lng: 20.74818 },
    { lat: 48.696327, lng: 20.748481 },
    { lat: 48.696754, lng: 20.748826 },
    { lat: 48.697348, lng: 20.749116 },
    { lat: 48.698021, lng: 20.749387 },
    { lat: 48.698354, lng: 20.749522 },
    { lat: 48.698918, lng: 20.749661 },
    { lat: 48.699634, lng: 20.750166 },
    { lat: 48.699803, lng: 20.750243 },
    { lat: 48.700301, lng: 20.750389 },
    { lat: 48.700466, lng: 20.75039 },
    { lat: 48.700763, lng: 20.750254 },
    { lat: 48.700892, lng: 20.750255 },
    { lat: 48.700986, lng: 20.750306 },
    { lat: 48.701136, lng: 20.750234 },
    { lat: 48.702099, lng: 20.749997 },
    { lat: 48.702606, lng: 20.749976 },
    { lat: 48.702809, lng: 20.749898 },
    { lat: 48.703077, lng: 20.749604 },
    { lat: 48.703445, lng: 20.749264 },
    { lat: 48.703867, lng: 20.749 },
    { lat: 48.704619, lng: 20.748384 },
    { lat: 48.705157, lng: 20.747939 },
    { lat: 48.705587, lng: 20.748007 },
    { lat: 48.70569, lng: 20.748008 },
    { lat: 48.705783, lng: 20.747965 },
    { lat: 48.706118, lng: 20.747678 },
    { lat: 48.706507, lng: 20.74747 },
    { lat: 48.706654, lng: 20.747487 },
    { lat: 48.707084, lng: 20.747769 },
    { lat: 48.707707, lng: 20.748082 },
    { lat: 48.708025, lng: 20.7483 },
    { lat: 48.70839, lng: 20.748507 },
    { lat: 48.708566, lng: 20.748548 },
    { lat: 48.708715, lng: 20.74862 },
    { lat: 48.708872, lng: 20.748659 },
    { lat: 48.709046, lng: 20.748659 },
    { lat: 48.709171, lng: 20.748617 },
    { lat: 48.709735, lng: 20.748124 },
    { lat: 48.710535, lng: 20.748225 },
    { lat: 48.710844, lng: 20.748325 },
    { lat: 48.711258, lng: 20.748411 },
    { lat: 48.711518, lng: 20.748547 },
    { lat: 48.711871, lng: 20.748828 },
    { lat: 48.712065, lng: 20.748937 },
    { lat: 48.712264, lng: 20.748854 },
    { lat: 48.71301, lng: 20.748223 },
    { lat: 48.713846, lng: 20.747857 },
    { lat: 48.714472, lng: 20.74774 },
    { lat: 48.715042, lng: 20.747862 },
    { lat: 48.716106, lng: 20.748777 },
    { lat: 48.716524, lng: 20.749083 },
    { lat: 48.716908, lng: 20.7495 },
    { lat: 48.71759, lng: 20.750129 },
    { lat: 48.717697, lng: 20.750408 },
    { lat: 48.717742, lng: 20.750644 },
    { lat: 48.717893, lng: 20.751098 },
    { lat: 48.718085, lng: 20.751604 },
    { lat: 48.718209, lng: 20.752192 },
    { lat: 48.718371, lng: 20.752791 },
    { lat: 48.718387, lng: 20.753485 },
    { lat: 48.718515, lng: 20.754089 },
    { lat: 48.718817, lng: 20.754769 },
    { lat: 48.718842, lng: 20.755465 },
    { lat: 48.718983, lng: 20.755821 },
    { lat: 48.719165, lng: 20.756368 },
    { lat: 48.719226, lng: 20.756805 },
    { lat: 48.719194, lng: 20.757174 },
    { lat: 48.719204, lng: 20.757669 },
    { lat: 48.719311, lng: 20.757986 },
    { lat: 48.719389, lng: 20.758491 },
    { lat: 48.719516, lng: 20.75888 },
    { lat: 48.719773, lng: 20.759405 },
    { lat: 48.719857, lng: 20.760183 },
    { lat: 48.719908, lng: 20.760376 },
    { lat: 48.720044, lng: 20.760557 },
    { lat: 48.720218, lng: 20.760583 },
    { lat: 48.720603, lng: 20.760471 },
    { lat: 48.721208, lng: 20.761312 },
    { lat: 48.721335, lng: 20.761418 },
    { lat: 48.721557, lng: 20.761518 },
    { lat: 48.7215984, lng: 20.7615756 },
    { lat: 48.721723, lng: 20.761749 },
    { lat: 48.7217558, lng: 20.761766 },
    { lat: 48.722042, lng: 20.761938 },
    { lat: 48.722207, lng: 20.761929 },
    { lat: 48.722222, lng: 20.761931 },
    { lat: 48.722272, lng: 20.761938 },
    { lat: 48.722296, lng: 20.761941 },
    { lat: 48.722407, lng: 20.761956 },
    { lat: 48.722425, lng: 20.761959 },
    { lat: 48.722455, lng: 20.761972 },
    { lat: 48.722666, lng: 20.762065 },
    { lat: 48.72317, lng: 20.762569 },
    { lat: 48.723348, lng: 20.762864 },
    { lat: 48.723767, lng: 20.763278 },
    { lat: 48.724127, lng: 20.76342 },
    { lat: 48.724789, lng: 20.763899 },
    { lat: 48.725014, lng: 20.764005 },
    { lat: 48.725287, lng: 20.764393 },
    { lat: 48.725549, lng: 20.765045 },
    { lat: 48.725783, lng: 20.765454 },
    { lat: 48.725941, lng: 20.766346 },
    { lat: 48.726016, lng: 20.766505 },
    { lat: 48.726373, lng: 20.766819 },
    { lat: 48.726473, lng: 20.767395 },
    { lat: 48.726594, lng: 20.767769 },
    { lat: 48.726518, lng: 20.768483 },
    { lat: 48.726545, lng: 20.768956 },
    { lat: 48.726615, lng: 20.769313 },
    { lat: 48.726681, lng: 20.769654 },
    { lat: 48.726677, lng: 20.76975 },
    { lat: 48.7267575, lng: 20.7697244 },
    { lat: 48.726973, lng: 20.7694533 },
    { lat: 48.7270737, lng: 20.7690804 },
    { lat: 48.7275547, lng: 20.7687394 },
    { lat: 48.7278288, lng: 20.7683965 },
    { lat: 48.7281848, lng: 20.7683098 },
    { lat: 48.7285644, lng: 20.7680301 },
    { lat: 48.7290752, lng: 20.7677638 },
    { lat: 48.7290939, lng: 20.7676906 },
    { lat: 48.7295356, lng: 20.767735 },
    { lat: 48.7296691, lng: 20.7678815 },
    { lat: 48.7298796, lng: 20.767978 },
    { lat: 48.7301164, lng: 20.7681308 },
    { lat: 48.730421, lng: 20.7682557 },
    { lat: 48.7307916, lng: 20.7684419 },
    { lat: 48.7312995, lng: 20.7687792 },
    { lat: 48.7317455, lng: 20.7685995 },
    { lat: 48.7321085, lng: 20.7685188 },
    { lat: 48.7326194, lng: 20.7684146 },
    { lat: 48.7331252, lng: 20.768424 },
    { lat: 48.7338774, lng: 20.7682384 },
    { lat: 48.7348589, lng: 20.7679523 },
    { lat: 48.7351984, lng: 20.7677757 },
    { lat: 48.7355437, lng: 20.7676649 },
    { lat: 48.7357272, lng: 20.7676976 },
    { lat: 48.7358311, lng: 20.7677763 },
    { lat: 48.7360265, lng: 20.7680798 },
    { lat: 48.7361606, lng: 20.7680826 },
    { lat: 48.7364649, lng: 20.7678064 },
    { lat: 48.7365722, lng: 20.7675906 },
    { lat: 48.7366074, lng: 20.7673106 },
    { lat: 48.7371827, lng: 20.7659342 },
    { lat: 48.7375491, lng: 20.7655991 },
    { lat: 48.7376119, lng: 20.7656536 },
    { lat: 48.7376591, lng: 20.7658766 },
    { lat: 48.7377311, lng: 20.7659372 },
    { lat: 48.7380006, lng: 20.7656331 },
    { lat: 48.738305, lng: 20.7652595 },
    { lat: 48.7385545, lng: 20.7648011 },
    { lat: 48.7387829, lng: 20.7640573 },
    { lat: 48.7391392, lng: 20.7638179 },
    { lat: 48.7394701, lng: 20.763782 },
    { lat: 48.7396131, lng: 20.7636568 },
    { lat: 48.7394628, lng: 20.7609853 },
    { lat: 48.739447, lng: 20.7607171 },
    { lat: 48.7394238, lng: 20.7606161 },
    { lat: 48.7394845, lng: 20.7606451 },
    { lat: 48.7394976, lng: 20.7605324 },
    { lat: 48.7394132, lng: 20.7603392 },
    { lat: 48.7398916, lng: 20.7596366 },
    { lat: 48.7400423, lng: 20.7590581 },
    { lat: 48.740353, lng: 20.7588993 },
    { lat: 48.7405755, lng: 20.7586934 },
    { lat: 48.7409905, lng: 20.7586875 },
    { lat: 48.7419303, lng: 20.7582655 },
    { lat: 48.7420271, lng: 20.7583277 },
    { lat: 48.742512, lng: 20.7576805 },
    { lat: 48.7427246, lng: 20.7571601 },
    { lat: 48.7428676, lng: 20.7569999 },
    { lat: 48.7428205, lng: 20.7567821 },
    { lat: 48.7429992, lng: 20.7562367 },
    { lat: 48.7435301, lng: 20.7555158 },
    { lat: 48.7439626, lng: 20.7552641 },
    { lat: 48.7442224, lng: 20.7549725 },
    { lat: 48.7444562, lng: 20.7545153 },
    { lat: 48.7447243, lng: 20.7543267 },
    { lat: 48.7455963, lng: 20.7540603 },
    { lat: 48.7456109, lng: 20.7541886 },
    { lat: 48.7460999, lng: 20.7540732 },
    { lat: 48.7464598, lng: 20.7541923 },
    { lat: 48.7466393, lng: 20.75385 },
    { lat: 48.7470149, lng: 20.7533967 },
    { lat: 48.7476646, lng: 20.7528874 },
    { lat: 48.74772, lng: 20.7528597 },
    { lat: 48.7476894, lng: 20.7529228 },
    { lat: 48.7479098, lng: 20.7527827 },
    { lat: 48.7481343, lng: 20.7527667 },
    { lat: 48.7483889, lng: 20.7525814 },
    { lat: 48.7486677, lng: 20.7525682 },
    { lat: 48.7491496, lng: 20.7522854 },
    { lat: 48.7492352, lng: 20.7519307 },
    { lat: 48.7495024, lng: 20.7515945 },
    { lat: 48.7497258, lng: 20.7511587 },
    { lat: 48.7499565, lng: 20.7511259 },
    { lat: 48.7501849, lng: 20.7513126 },
    { lat: 48.7501882, lng: 20.7513747 },
    { lat: 48.7505202, lng: 20.7513087 },
    { lat: 48.7506007, lng: 20.7512901 },
    { lat: 48.7505787, lng: 20.7511816 },
    { lat: 48.7509804, lng: 20.751141 },
    { lat: 48.7510579, lng: 20.7510106 },
    { lat: 48.7512303, lng: 20.7510752 },
    { lat: 48.7516036, lng: 20.7508718 },
    { lat: 48.7516787, lng: 20.7507669 },
    { lat: 48.7518388, lng: 20.7507793 },
    { lat: 48.7518893, lng: 20.7507057 },
    { lat: 48.751978, lng: 20.7507666 },
    { lat: 48.7519509, lng: 20.7509526 },
    { lat: 48.7521521, lng: 20.7514215 },
    { lat: 48.7524689, lng: 20.7513407 },
    { lat: 48.7524985, lng: 20.7511884 },
    { lat: 48.7527339, lng: 20.7509426 },
    { lat: 48.7534964, lng: 20.7496717 },
    { lat: 48.7532386, lng: 20.7497342 },
    { lat: 48.7531235, lng: 20.7493136 },
    { lat: 48.7532186, lng: 20.7492331 },
    { lat: 48.7532001, lng: 20.7489967 },
    { lat: 48.7533826, lng: 20.7488389 },
    { lat: 48.7537646, lng: 20.7490778 },
    { lat: 48.7539838, lng: 20.7493869 },
    { lat: 48.7543413, lng: 20.7490477 },
    { lat: 48.7546454, lng: 20.749021 },
    { lat: 48.7549029, lng: 20.7488579 },
    { lat: 48.7552314, lng: 20.7497704 },
    { lat: 48.7556068, lng: 20.7497872 },
    { lat: 48.7554726, lng: 20.749433 },
    { lat: 48.7555449, lng: 20.7488949 },
    { lat: 48.7557307, lng: 20.7485452 },
    { lat: 48.756287, lng: 20.748424 },
    { lat: 48.7572006, lng: 20.7473281 },
    { lat: 48.7574793, lng: 20.7487484 },
    { lat: 48.7574375, lng: 20.7498304 },
    { lat: 48.7578506, lng: 20.7501463 },
    { lat: 48.7587915, lng: 20.7506859 },
    { lat: 48.7600487, lng: 20.7509959 },
    { lat: 48.7615095, lng: 20.7526108 },
    { lat: 48.762397, lng: 20.7531005 },
    { lat: 48.762808, lng: 20.7536461 },
    { lat: 48.7628737, lng: 20.7535623 },
    { lat: 48.7634806, lng: 20.7539206 },
    { lat: 48.7639336, lng: 20.7540585 },
    { lat: 48.764592, lng: 20.7544983 },
    { lat: 48.7651044, lng: 20.7549569 },
    { lat: 48.765632, lng: 20.7551346 },
    { lat: 48.7659591, lng: 20.7550233 },
    { lat: 48.7666188, lng: 20.7550864 },
    { lat: 48.7677419, lng: 20.7553692 },
    { lat: 48.7681176, lng: 20.7555805 },
    { lat: 48.7689806, lng: 20.7551545 },
    { lat: 48.7700235, lng: 20.7547842 },
    { lat: 48.7708812, lng: 20.755006 },
    { lat: 48.7721003, lng: 20.7550997 },
    { lat: 48.7734431, lng: 20.7554415 },
    { lat: 48.7744546, lng: 20.755335 },
    { lat: 48.7758219, lng: 20.755 },
    { lat: 48.7766602, lng: 20.7545091 },
    { lat: 48.7775652, lng: 20.7538005 },
    { lat: 48.7781853, lng: 20.75341 },
    { lat: 48.7788817, lng: 20.7531775 },
    { lat: 48.7788613, lng: 20.7528846 },
    { lat: 48.7785288, lng: 20.7525884 },
    { lat: 48.7790126, lng: 20.7519366 },
    { lat: 48.7796875, lng: 20.7514491 },
    { lat: 48.7794859, lng: 20.7511902 },
    { lat: 48.7793659, lng: 20.7511995 },
    { lat: 48.7791694, lng: 20.7503805 },
    { lat: 48.7788889, lng: 20.7499588 },
    { lat: 48.7788604, lng: 20.7494399 },
    { lat: 48.7788076, lng: 20.7492197 },
    { lat: 48.7786642, lng: 20.748986 },
    { lat: 48.7784934, lng: 20.748196 },
    { lat: 48.7785424, lng: 20.7479265 },
    { lat: 48.7785126, lng: 20.7477843 },
    { lat: 48.7784341, lng: 20.7477239 },
    { lat: 48.7783821, lng: 20.747469 },
    { lat: 48.7782505, lng: 20.7472758 },
    { lat: 48.7781002, lng: 20.746366 },
    { lat: 48.7781309, lng: 20.7461861 },
    { lat: 48.7780048, lng: 20.7459611 },
    { lat: 48.7779089, lng: 20.7456033 },
    { lat: 48.7778339, lng: 20.7450429 },
    { lat: 48.7777591, lng: 20.744807 },
    { lat: 48.7776674, lng: 20.7440274 },
    { lat: 48.7775882, lng: 20.7437885 },
    { lat: 48.7774705, lng: 20.7436164 },
    { lat: 48.7773779, lng: 20.7431584 },
    { lat: 48.7773713, lng: 20.7429095 },
    { lat: 48.7772781, lng: 20.7426625 },
    { lat: 48.7769915, lng: 20.7412062 },
    { lat: 48.7768864, lng: 20.7410041 },
    { lat: 48.7767976, lng: 20.7405919 },
    { lat: 48.7764144, lng: 20.7395618 },
    { lat: 48.7764129, lng: 20.7393935 },
    { lat: 48.7761809, lng: 20.7391949 },
    { lat: 48.7760889, lng: 20.7387947 },
    { lat: 48.7759565, lng: 20.7386148 },
    { lat: 48.7759079, lng: 20.7383543 },
    { lat: 48.7755147, lng: 20.7374549 },
    { lat: 48.7753903, lng: 20.737026 },
    { lat: 48.7749684, lng: 20.7362685 },
    { lat: 48.7748414, lng: 20.736111 },
    { lat: 48.774671, lng: 20.7360352 },
    { lat: 48.7745739, lng: 20.7357733 },
    { lat: 48.7743247, lng: 20.7354762 },
    { lat: 48.7738545, lng: 20.7351691 },
    { lat: 48.7735667, lng: 20.7345418 },
    { lat: 48.7717834, lng: 20.7298343 },
    { lat: 48.7713595, lng: 20.7295967 },
    { lat: 48.7712731, lng: 20.7294806 },
    { lat: 48.7697551, lng: 20.7239061 },
    { lat: 48.7693892, lng: 20.7223848 },
    { lat: 48.7689276, lng: 20.7216536 },
    { lat: 48.7688436, lng: 20.7213803 },
    { lat: 48.7684874, lng: 20.7207033 },
    { lat: 48.7681315, lng: 20.7198436 },
    { lat: 48.7679151, lng: 20.7190775 },
    { lat: 48.7679108, lng: 20.7185701 },
    { lat: 48.768022, lng: 20.7176175 },
    { lat: 48.7679396, lng: 20.7171984 },
    { lat: 48.7678077, lng: 20.716879 },
    { lat: 48.7679529, lng: 20.7160843 },
    { lat: 48.767951, lng: 20.715745 },
    { lat: 48.7680667, lng: 20.7149787 },
    { lat: 48.768136, lng: 20.7133785 },
    { lat: 48.7682896, lng: 20.7123861 },
    { lat: 48.7684082, lng: 20.712112 },
    { lat: 48.7683797, lng: 20.7116561 },
    { lat: 48.7684436, lng: 20.7109846 },
    { lat: 48.7683783, lng: 20.7104435 },
    { lat: 48.7684308, lng: 20.7102736 },
    { lat: 48.7684438, lng: 20.7098504 },
    { lat: 48.768341, lng: 20.7085747 },
    { lat: 48.7680981, lng: 20.7073515 },
    { lat: 48.7681058, lng: 20.7070636 },
    { lat: 48.7680591, lng: 20.7068276 },
    { lat: 48.7680968, lng: 20.7066868 },
    { lat: 48.7680844, lng: 20.7064302 },
    { lat: 48.7679342, lng: 20.7059753 },
    { lat: 48.7678617, lng: 20.7055847 },
    { lat: 48.7677756, lng: 20.7041803 },
    { lat: 48.7676151, lng: 20.7037826 },
    { lat: 48.7675338, lng: 20.7032686 },
    { lat: 48.7674016, lng: 20.7030656 },
    { lat: 48.7669411, lng: 20.7019612 },
    { lat: 48.766931, lng: 20.7018054 },
    { lat: 48.7668327, lng: 20.7016018 },
    { lat: 48.766668, lng: 20.7014343 },
    { lat: 48.76666, lng: 20.7011505 },
    { lat: 48.766435, lng: 20.7002753 },
    { lat: 48.76642, lng: 20.6996959 },
    { lat: 48.7662562, lng: 20.698669 },
    { lat: 48.766234, lng: 20.6978276 },
    { lat: 48.7661845, lng: 20.6975451 },
    { lat: 48.7662732, lng: 20.6961796 },
    { lat: 48.7662299, lng: 20.6953181 },
    { lat: 48.7661089, lng: 20.6942274 },
    { lat: 48.76612, lng: 20.6935278 },
    { lat: 48.7662509, lng: 20.692632 },
    { lat: 48.7662341, lng: 20.6912763 },
    { lat: 48.7660633, lng: 20.6904984 },
    { lat: 48.7640916, lng: 20.6862762 },
    { lat: 48.7660219, lng: 20.6844397 },
    { lat: 48.766927, lng: 20.6832381 },
    { lat: 48.7680456, lng: 20.6820065 },
    { lat: 48.767919, lng: 20.6812974 },
    { lat: 48.7680891, lng: 20.6805804 },
    { lat: 48.7677251, lng: 20.6741708 },
    { lat: 48.7677923, lng: 20.6728098 },
    { lat: 48.7682786, lng: 20.6699326 },
    { lat: 48.7681599, lng: 20.669255 },
    { lat: 48.7679244, lng: 20.6670303 },
    { lat: 48.7676934, lng: 20.6660261 },
    { lat: 48.7675089, lng: 20.6644318 },
    { lat: 48.7671185, lng: 20.6626578 },
    { lat: 48.7672982, lng: 20.6619546 },
    { lat: 48.7671726, lng: 20.6604386 },
    { lat: 48.7671804, lng: 20.6594726 },
    { lat: 48.7672523, lng: 20.658841 },
    { lat: 48.7672193, lng: 20.6582981 },
    { lat: 48.7674448, lng: 20.6578726 },
    { lat: 48.7676566, lng: 20.6573159 },
    { lat: 48.7666084, lng: 20.6570327 },
    { lat: 48.7663351, lng: 20.6565991 },
    { lat: 48.765381, lng: 20.6560277 },
    { lat: 48.7651672, lng: 20.655282 },
    { lat: 48.7646725, lng: 20.654987 },
    { lat: 48.7643535, lng: 20.6552542 },
    { lat: 48.763327, lng: 20.655795 },
    { lat: 48.7630777, lng: 20.6560012 },
    { lat: 48.7627837, lng: 20.6566781 },
    { lat: 48.7624058, lng: 20.6572496 },
    { lat: 48.7616496, lng: 20.6574493 },
    { lat: 48.7617243, lng: 20.6568058 },
    { lat: 48.7616612, lng: 20.6567693 },
    { lat: 48.7613677, lng: 20.6570633 },
    { lat: 48.7613587, lng: 20.6575608 },
    { lat: 48.7612062, lng: 20.6578899 },
    { lat: 48.7599264, lng: 20.6582399 },
    { lat: 48.7596968, lng: 20.6580865 },
    { lat: 48.7598435, lng: 20.6572868 },
    { lat: 48.7599349, lng: 20.6570416 },
    { lat: 48.7599581, lng: 20.6565601 },
    { lat: 48.7597024, lng: 20.6568685 },
    { lat: 48.7588231, lng: 20.6575858 },
    { lat: 48.7583262, lng: 20.6569517 },
    { lat: 48.7582246, lng: 20.6571631 },
    { lat: 48.7580324, lng: 20.657296 },
    { lat: 48.7576586, lng: 20.6572334 },
    { lat: 48.7575718, lng: 20.6571035 },
    { lat: 48.7571191, lng: 20.6568483 },
    { lat: 48.7566854, lng: 20.6567799 },
    { lat: 48.7565876, lng: 20.6566916 },
    { lat: 48.7564346, lng: 20.6563221 },
    { lat: 48.7562137, lng: 20.6561898 },
    { lat: 48.7560866, lng: 20.6556939 },
    { lat: 48.7562746, lng: 20.6555032 },
    { lat: 48.7563049, lng: 20.6553495 },
    { lat: 48.7561523, lng: 20.6540494 },
    { lat: 48.7560062, lng: 20.6538402 },
    { lat: 48.7558063, lng: 20.6538574 },
    { lat: 48.755467, lng: 20.654 },
    { lat: 48.755191, lng: 20.6538044 },
    { lat: 48.7548673, lng: 20.6539471 },
    { lat: 48.7548391, lng: 20.6540661 },
    { lat: 48.7546514, lng: 20.654174 },
    { lat: 48.754356, lng: 20.654567 },
    { lat: 48.7530731, lng: 20.6534291 },
    { lat: 48.7534248, lng: 20.6522841 },
    { lat: 48.7531492, lng: 20.6521662 },
    { lat: 48.7531653, lng: 20.6516454 },
    { lat: 48.7534074, lng: 20.6515555 },
    { lat: 48.7538366, lng: 20.6516863 },
    { lat: 48.7539589, lng: 20.6513291 },
    { lat: 48.7541067, lng: 20.6512491 },
    { lat: 48.7542061, lng: 20.6509436 },
    { lat: 48.754188, lng: 20.6506568 },
    { lat: 48.7547787, lng: 20.649371 },
    { lat: 48.7548853, lng: 20.6493644 },
    { lat: 48.7549172, lng: 20.6491876 },
    { lat: 48.7551068, lng: 20.6492005 },
    { lat: 48.7552385, lng: 20.6489541 },
    { lat: 48.7553919, lng: 20.6488643 },
    { lat: 48.7553531, lng: 20.6485083 },
    { lat: 48.7556991, lng: 20.6468348 },
    { lat: 48.7555153, lng: 20.6467676 },
    { lat: 48.7555025, lng: 20.6466309 },
    { lat: 48.7551871, lng: 20.6464686 },
    { lat: 48.7549324, lng: 20.6465557 },
    { lat: 48.7542914, lng: 20.6476306 },
    { lat: 48.7537543, lng: 20.6475091 },
    { lat: 48.7532451, lng: 20.6468165 },
    { lat: 48.7530451, lng: 20.646452 },
    { lat: 48.753052, lng: 20.6462966 },
    { lat: 48.7533011, lng: 20.6460277 },
    { lat: 48.7532214, lng: 20.6456918 },
    { lat: 48.7530083, lng: 20.6435947 },
    { lat: 48.7530297, lng: 20.6433204 },
    { lat: 48.7529822, lng: 20.6430646 },
    { lat: 48.7528276, lng: 20.6427741 },
    { lat: 48.7528165, lng: 20.6423126 },
    { lat: 48.7530093, lng: 20.6421625 },
    { lat: 48.7532158, lng: 20.6415853 },
    { lat: 48.7535255, lng: 20.6410897 },
    { lat: 48.753473, lng: 20.6408511 },
    { lat: 48.7537276, lng: 20.640297 },
    { lat: 48.7537085, lng: 20.6393345 },
    { lat: 48.7536138, lng: 20.6391754 },
    { lat: 48.7537817, lng: 20.6384379 },
    { lat: 48.7536803, lng: 20.6378458 },
    { lat: 48.7529908, lng: 20.6367085 },
    { lat: 48.7524915, lng: 20.6355164 },
    { lat: 48.7523574, lng: 20.6350773 },
    { lat: 48.7520717, lng: 20.6347128 },
    { lat: 48.750259, lng: 20.631279 },
    { lat: 48.7501682, lng: 20.6313624 },
    { lat: 48.749756, lng: 20.631741 },
    { lat: 48.749549, lng: 20.632004 },
    { lat: 48.74927, lng: 20.632324 },
    { lat: 48.749044, lng: 20.632549 },
    { lat: 48.748864, lng: 20.632753 },
    { lat: 48.748479, lng: 20.633155 },
    { lat: 48.748391, lng: 20.633289 },
    { lat: 48.74824, lng: 20.633549 },
    { lat: 48.748063, lng: 20.633718 },
    { lat: 48.747876, lng: 20.633959 },
    { lat: 48.747586, lng: 20.634081 },
    { lat: 48.74734, lng: 20.634453 },
    { lat: 48.747136, lng: 20.634533 },
    { lat: 48.746938, lng: 20.634741 },
    { lat: 48.74676, lng: 20.634975 },
    { lat: 48.746672, lng: 20.635178 },
    { lat: 48.746569, lng: 20.635246 },
    { lat: 48.746449, lng: 20.635299 },
    { lat: 48.74614, lng: 20.635328 },
    { lat: 48.745379, lng: 20.635859 },
    { lat: 48.745162, lng: 20.635933 },
    { lat: 48.745007, lng: 20.636138 },
    { lat: 48.744889, lng: 20.636297 },
    { lat: 48.744641, lng: 20.636247 },
    { lat: 48.744278, lng: 20.636363 },
    { lat: 48.744153, lng: 20.636374 },
    { lat: 48.744048, lng: 20.63646 },
    { lat: 48.743962, lng: 20.636568 },
    { lat: 48.743875, lng: 20.636752 },
    { lat: 48.743849, lng: 20.637433 },
    { lat: 48.744196, lng: 20.638971 },
    { lat: 48.744222, lng: 20.639147 },
    { lat: 48.744149, lng: 20.640056 },
    { lat: 48.744149, lng: 20.640336 },
    { lat: 48.744062, lng: 20.640751 },
    { lat: 48.743991, lng: 20.640993 },
    { lat: 48.743977, lng: 20.641148 },
    { lat: 48.744073, lng: 20.641528 },
    { lat: 48.744172, lng: 20.641763 },
    { lat: 48.744109, lng: 20.642006 },
    { lat: 48.743971, lng: 20.642264 },
    { lat: 48.743887, lng: 20.642369 },
    { lat: 48.743788, lng: 20.642401 },
    { lat: 48.743717, lng: 20.642366 },
    { lat: 48.743657, lng: 20.642284 },
    { lat: 48.743438, lng: 20.641845 },
    { lat: 48.743304, lng: 20.641583 },
    { lat: 48.743179, lng: 20.641368 },
    { lat: 48.74304, lng: 20.641163 },
    { lat: 48.742794, lng: 20.640841 },
    { lat: 48.742752, lng: 20.640787 },
    { lat: 48.74259, lng: 20.640664 },
    { lat: 48.742305, lng: 20.64072 },
    { lat: 48.742132, lng: 20.640934 },
    { lat: 48.742029, lng: 20.641133 },
    { lat: 48.741973, lng: 20.641297 },
    { lat: 48.741887, lng: 20.641556 },
    { lat: 48.741824, lng: 20.641911 },
    { lat: 48.741767, lng: 20.64192 },
    { lat: 48.74171, lng: 20.64196 },
    { lat: 48.741647, lng: 20.642178 },
    { lat: 48.741611, lng: 20.642599 },
    { lat: 48.741636, lng: 20.642905 },
    { lat: 48.741672, lng: 20.643158 },
    { lat: 48.741668, lng: 20.64372 },
    { lat: 48.74163, lng: 20.644049 },
    { lat: 48.741581, lng: 20.644322 },
    { lat: 48.741448, lng: 20.64475 },
    { lat: 48.741406, lng: 20.645444 },
    { lat: 48.741354, lng: 20.645816 },
    { lat: 48.741312, lng: 20.646069 },
    { lat: 48.741089, lng: 20.646706 },
    { lat: 48.741047, lng: 20.647157 },
    { lat: 48.740844, lng: 20.64743 },
    { lat: 48.740833, lng: 20.647755 },
    { lat: 48.740736, lng: 20.647867 },
    { lat: 48.740647, lng: 20.648091 },
    { lat: 48.740592, lng: 20.648311 },
    { lat: 48.740631, lng: 20.648689 },
    { lat: 48.740423, lng: 20.649189 },
    { lat: 48.740281, lng: 20.649377 },
    { lat: 48.740213, lng: 20.649537 },
    { lat: 48.740023, lng: 20.650488 },
    { lat: 48.739906, lng: 20.650837 },
    { lat: 48.739918, lng: 20.651144 },
    { lat: 48.739825, lng: 20.651465 },
    { lat: 48.739767, lng: 20.651582 },
    { lat: 48.739677, lng: 20.651688 },
    { lat: 48.739578, lng: 20.65174 },
    { lat: 48.739367, lng: 20.65203 },
    { lat: 48.738944, lng: 20.65234 },
    { lat: 48.7386112, lng: 20.6525371 },
    { lat: 48.7382058, lng: 20.6525338 },
    { lat: 48.7376856, lng: 20.6526256 },
    { lat: 48.7372467, lng: 20.6533812 },
    { lat: 48.7370308, lng: 20.6533866 },
    { lat: 48.7368353, lng: 20.6536164 },
    { lat: 48.7367232, lng: 20.6539759 },
    { lat: 48.7363956, lng: 20.654534 },
    { lat: 48.7361751, lng: 20.6546667 },
    { lat: 48.7360723, lng: 20.6550275 },
    { lat: 48.7358853, lng: 20.6551193 },
    { lat: 48.7357843, lng: 20.6554083 },
    { lat: 48.7356042, lng: 20.6555616 },
    { lat: 48.7353584, lng: 20.6555547 },
    { lat: 48.7352693, lng: 20.655708 },
    { lat: 48.7347757, lng: 20.6559406 },
    { lat: 48.7347117, lng: 20.6558806 },
    { lat: 48.734482, lng: 20.6559755 },
    { lat: 48.7340913, lng: 20.6562887 },
    { lat: 48.7340239, lng: 20.6564794 },
    { lat: 48.7338429, lng: 20.6566752 },
    { lat: 48.7337089, lng: 20.6567083 },
    { lat: 48.7334003, lng: 20.6570127 },
    { lat: 48.7329465, lng: 20.6575929 },
    { lat: 48.7327891, lng: 20.6577942 },
    { lat: 48.7321651, lng: 20.6587212 },
    { lat: 48.7308161, lng: 20.6591195 },
    { lat: 48.7296973, lng: 20.6596938 },
    { lat: 48.7293702, lng: 20.6594294 },
    { lat: 48.7278965, lng: 20.6586797 },
    { lat: 48.7268775, lng: 20.6580815 },
    { lat: 48.7258, lng: 20.6576747 },
    { lat: 48.7256065, lng: 20.6576785 },
    { lat: 48.7224377, lng: 20.6606838 },
    { lat: 48.7219116, lng: 20.6609586 },
    { lat: 48.7217237, lng: 20.6611442 },
    { lat: 48.7214316, lng: 20.6611967 },
    { lat: 48.7211042, lng: 20.6614512 },
    { lat: 48.7202811, lng: 20.6624132 },
    { lat: 48.7200311, lng: 20.6626313 },
    { lat: 48.7192473, lng: 20.663906 },
    { lat: 48.7191244, lng: 20.6639977 },
    { lat: 48.7188814, lng: 20.6638747 },
    { lat: 48.7184615, lng: 20.663848 },
    { lat: 48.7178104, lng: 20.6641765 },
    { lat: 48.7177001, lng: 20.6641805 },
    { lat: 48.7170876, lng: 20.6647411 },
    { lat: 48.7166752, lng: 20.6649819 },
    { lat: 48.7158036, lng: 20.6653192 },
    { lat: 48.7155082, lng: 20.665587 },
    { lat: 48.7152575, lng: 20.6656932 },
    { lat: 48.7150263, lng: 20.6659942 },
    { lat: 48.7147951, lng: 20.6664474 },
    { lat: 48.714546, lng: 20.6667225 },
    { lat: 48.7143533, lng: 20.6672171 },
    { lat: 48.7136318, lng: 20.6685713 },
    { lat: 48.7129491, lng: 20.6708712 },
    { lat: 48.7127112, lng: 20.67249 },
    { lat: 48.7123934, lng: 20.6730725 },
    { lat: 48.7121147, lng: 20.6733393 },
    { lat: 48.7117984, lng: 20.6739042 },
    { lat: 48.7115606, lng: 20.675052 },
    { lat: 48.7110634, lng: 20.6766307 },
    { lat: 48.710913, lng: 20.6774622 },
    { lat: 48.7109626, lng: 20.6778392 },
    { lat: 48.710881, lng: 20.6786979 },
    { lat: 48.7106045, lng: 20.680939 },
    { lat: 48.7104338, lng: 20.6815803 },
    { lat: 48.7099602, lng: 20.6824369 },
    { lat: 48.7090294, lng: 20.6829145 },
    { lat: 48.7066806, lng: 20.6837468 },
    { lat: 48.7033439, lng: 20.6834077 },
    { lat: 48.7026323, lng: 20.6834713 },
    { lat: 48.7022225, lng: 20.6823404 },
    { lat: 48.7019298, lng: 20.6812114 },
    { lat: 48.7011883, lng: 20.6788735 },
    { lat: 48.7008237, lng: 20.6779048 },
    { lat: 48.6983546, lng: 20.6774974 },
    { lat: 48.6973988, lng: 20.6774604 },
    { lat: 48.6960297, lng: 20.6771753 },
    { lat: 48.6953923, lng: 20.6771121 },
    { lat: 48.6949715, lng: 20.6772442 },
    { lat: 48.6947585, lng: 20.6771152 },
    { lat: 48.6945599, lng: 20.6768796 },
    { lat: 48.6941999, lng: 20.6761646 },
    { lat: 48.692557, lng: 20.677909 },
    { lat: 48.6920209, lng: 20.6781244 },
    { lat: 48.691298, lng: 20.6781213 },
    { lat: 48.6891036, lng: 20.6786165 },
    { lat: 48.6885075, lng: 20.6783486 },
    { lat: 48.687893, lng: 20.677787 },
    { lat: 48.6865, lng: 20.677313 },
    { lat: 48.6854262, lng: 20.6777672 },
    { lat: 48.68438, lng: 20.678267 },
    { lat: 48.683897, lng: 20.680165 },
    { lat: 48.683338, lng: 20.681392 },
    { lat: 48.682747, lng: 20.682594 },
    { lat: 48.682724, lng: 20.682641 },
    { lat: 48.682655, lng: 20.682782 },
    { lat: 48.68263, lng: 20.682814 },
    { lat: 48.681181, lng: 20.685919 },
    { lat: 48.680881, lng: 20.687832 },
    { lat: 48.68086, lng: 20.687829 },
    { lat: 48.6807, lng: 20.690524 },
    { lat: 48.680639, lng: 20.690961 },
    { lat: 48.680512, lng: 20.692582 },
    { lat: 48.680506, lng: 20.693061 },
    { lat: 48.680794, lng: 20.694121 },
    { lat: 48.680876, lng: 20.69464 },
    { lat: 48.680825, lng: 20.695336 },
    { lat: 48.680812, lng: 20.695522 },
    { lat: 48.680805, lng: 20.69588 },
    { lat: 48.680892, lng: 20.696256 },
    { lat: 48.680895, lng: 20.696483 },
    { lat: 48.680793, lng: 20.696836 },
    { lat: 48.68077, lng: 20.696964 },
    { lat: 48.680758, lng: 20.697369 },
    { lat: 48.6808, lng: 20.697669 },
    { lat: 48.6809876, lng: 20.6982848 },
    { lat: 48.680994, lng: 20.698375 },
    { lat: 48.680905, lng: 20.698685 },
  ],
  SmolníckaHuta: [
    { lat: 48.779453, lng: 20.859598 },
    { lat: 48.7811829, lng: 20.8602587 },
    { lat: 48.7825974, lng: 20.8607983 },
    { lat: 48.783079, lng: 20.8594848 },
    { lat: 48.78286, lng: 20.8583123 },
    { lat: 48.7827327, lng: 20.8568721 },
    { lat: 48.7828224, lng: 20.8558417 },
    { lat: 48.782888, lng: 20.8534582 },
    { lat: 48.7827883, lng: 20.8524866 },
    { lat: 48.7824942, lng: 20.8510752 },
    { lat: 48.7825046, lng: 20.848982 },
    { lat: 48.7823178, lng: 20.8478445 },
    { lat: 48.7819415, lng: 20.8466563 },
    { lat: 48.7817368, lng: 20.845809 },
    { lat: 48.7817597, lng: 20.8446775 },
    { lat: 48.7819095, lng: 20.8442177 },
    { lat: 48.7820621, lng: 20.8439434 },
    { lat: 48.7823002, lng: 20.8437347 },
    { lat: 48.7824946, lng: 20.8433942 },
    { lat: 48.7827929, lng: 20.8431997 },
    { lat: 48.7833391, lng: 20.8421265 },
    { lat: 48.7836362, lng: 20.8418293 },
    { lat: 48.7839446, lng: 20.8411132 },
    { lat: 48.7841169, lng: 20.8409529 },
    { lat: 48.7842714, lng: 20.840664 },
    { lat: 48.7843861, lng: 20.8405996 },
    { lat: 48.7844921, lng: 20.8401758 },
    { lat: 48.7849477, lng: 20.8390929 },
    { lat: 48.7852193, lng: 20.8386417 },
    { lat: 48.7854808, lng: 20.8379967 },
    { lat: 48.7853158, lng: 20.8371655 },
    { lat: 48.785392, lng: 20.8369621 },
    { lat: 48.7853383, lng: 20.8367718 },
    { lat: 48.7853941, lng: 20.8366606 },
    { lat: 48.7853039, lng: 20.8364596 },
    { lat: 48.7853529, lng: 20.8363126 },
    { lat: 48.7853428, lng: 20.8361517 },
    { lat: 48.7852272, lng: 20.8358342 },
    { lat: 48.7852382, lng: 20.8355656 },
    { lat: 48.7853007, lng: 20.8353872 },
    { lat: 48.7852891, lng: 20.8350526 },
    { lat: 48.785342, lng: 20.8348228 },
    { lat: 48.7854418, lng: 20.8346504 },
    { lat: 48.7855973, lng: 20.8345899 },
    { lat: 48.7856386, lng: 20.8343853 },
    { lat: 48.7857652, lng: 20.8342332 },
    { lat: 48.786074, lng: 20.833271 },
    { lat: 48.7861383, lng: 20.8328183 },
    { lat: 48.7858027, lng: 20.8314388 },
    { lat: 48.7857164, lng: 20.8313235 },
    { lat: 48.7856819, lng: 20.8311035 },
    { lat: 48.7855252, lng: 20.8309377 },
    { lat: 48.7852316, lng: 20.8299643 },
    { lat: 48.7852231, lng: 20.8291654 },
    { lat: 48.7851793, lng: 20.8290032 },
    { lat: 48.7850148, lng: 20.8287417 },
    { lat: 48.7849887, lng: 20.8286 },
    { lat: 48.7844012, lng: 20.8282156 },
    { lat: 48.7843803, lng: 20.828067 },
    { lat: 48.7839608, lng: 20.8273006 },
    { lat: 48.7840445, lng: 20.8267525 },
    { lat: 48.7841242, lng: 20.8267451 },
    { lat: 48.7843881, lng: 20.8261434 },
    { lat: 48.784597, lng: 20.8250913 },
    { lat: 48.7845245, lng: 20.8242639 },
    { lat: 48.7846453, lng: 20.8239777 },
    { lat: 48.7847514, lng: 20.82337 },
    { lat: 48.7847278, lng: 20.8232062 },
    { lat: 48.7847865, lng: 20.8230206 },
    { lat: 48.78471, lng: 20.8223987 },
    { lat: 48.784745, lng: 20.8221168 },
    { lat: 48.7846584, lng: 20.8217253 },
    { lat: 48.7847029, lng: 20.8215469 },
    { lat: 48.7848388, lng: 20.821343 },
    { lat: 48.7848167, lng: 20.8212223 },
    { lat: 48.7846503, lng: 20.8209406 },
    { lat: 48.7846199, lng: 20.8207796 },
    { lat: 48.7847556, lng: 20.8196215 },
    { lat: 48.7846482, lng: 20.8191563 },
    { lat: 48.7844959, lng: 20.8187854 },
    { lat: 48.784567, lng: 20.8185374 },
    { lat: 48.7844746, lng: 20.8180757 },
    { lat: 48.7843784, lng: 20.8179619 },
    { lat: 48.7841912, lng: 20.817502 },
    { lat: 48.7839593, lng: 20.8173359 },
    { lat: 48.7839099, lng: 20.8171763 },
    { lat: 48.7838839, lng: 20.8169524 },
    { lat: 48.7839429, lng: 20.8159247 },
    { lat: 48.7839361, lng: 20.8149475 },
    { lat: 48.7840161, lng: 20.8145942 },
    { lat: 48.784101, lng: 20.8137629 },
    { lat: 48.7840524, lng: 20.8135933 },
    { lat: 48.7841248, lng: 20.8131524 },
    { lat: 48.7844573, lng: 20.8125173 },
    { lat: 48.7844231, lng: 20.8123737 },
    { lat: 48.7844571, lng: 20.8121515 },
    { lat: 48.784637, lng: 20.8118569 },
    { lat: 48.7846311, lng: 20.8116507 },
    { lat: 48.7845272, lng: 20.811282 },
    { lat: 48.7845997, lng: 20.8111692 },
    { lat: 48.7846725, lng: 20.8103449 },
    { lat: 48.7848448, lng: 20.810183 },
    { lat: 48.7848021, lng: 20.8099333 },
    { lat: 48.7848061, lng: 20.8094975 },
    { lat: 48.7849661, lng: 20.808914 },
    { lat: 48.7851337, lng: 20.8084237 },
    { lat: 48.7854277, lng: 20.8080452 },
    { lat: 48.7855587, lng: 20.8077613 },
    { lat: 48.7855231, lng: 20.8076124 },
    { lat: 48.7856721, lng: 20.8073314 },
    { lat: 48.7856805, lng: 20.8069563 },
    { lat: 48.7857259, lng: 20.8068723 },
    { lat: 48.7858828, lng: 20.8067903 },
    { lat: 48.7860162, lng: 20.8064491 },
    { lat: 48.7861028, lng: 20.8061509 },
    { lat: 48.7860542, lng: 20.8058588 },
    { lat: 48.78626, lng: 20.8053316 },
    { lat: 48.7868811, lng: 20.8043551 },
    { lat: 48.7869842, lng: 20.8043686 },
    { lat: 48.7870513, lng: 20.8041027 },
    { lat: 48.7868977, lng: 20.8038618 },
    { lat: 48.7872979, lng: 20.8028425 },
    { lat: 48.7872175, lng: 20.8025391 },
    { lat: 48.7870595, lng: 20.8023285 },
    { lat: 48.7868508, lng: 20.8012203 },
    { lat: 48.7866392, lng: 20.8004407 },
    { lat: 48.7863242, lng: 20.7998968 },
    { lat: 48.7862263, lng: 20.7996369 },
    { lat: 48.7860077, lng: 20.7993409 },
    { lat: 48.7856217, lng: 20.7990218 },
    { lat: 48.785382, lng: 20.7980853 },
    { lat: 48.7849937, lng: 20.796994 },
    { lat: 48.7847656, lng: 20.7968729 },
    { lat: 48.7841402, lng: 20.7968918 },
    { lat: 48.7835426, lng: 20.7966307 },
    { lat: 48.7833212, lng: 20.7964195 },
    { lat: 48.7829926, lng: 20.7956469 },
    { lat: 48.7826405, lng: 20.795202 },
    { lat: 48.7828156, lng: 20.7949592 },
    { lat: 48.7828991, lng: 20.794677 },
    { lat: 48.7831504, lng: 20.7943931 },
    { lat: 48.7835043, lng: 20.7940857 },
    { lat: 48.7835644, lng: 20.7940634 },
    { lat: 48.7836522, lng: 20.7941578 },
    { lat: 48.783904, lng: 20.7940196 },
    { lat: 48.7842623, lng: 20.7936747 },
    { lat: 48.784368, lng: 20.7932836 },
    { lat: 48.7846354, lng: 20.7908784 },
    { lat: 48.7848968, lng: 20.7899804 },
    { lat: 48.7852024, lng: 20.7898089 },
    { lat: 48.7856909, lng: 20.7892526 },
    { lat: 48.785949, lng: 20.7891067 },
    { lat: 48.7862089, lng: 20.7891637 },
    { lat: 48.7864045, lng: 20.7889624 },
    { lat: 48.786746, lng: 20.7887452 },
    { lat: 48.7873694, lng: 20.7877087 },
    { lat: 48.7875233, lng: 20.787314 },
    { lat: 48.7877608, lng: 20.7873454 },
    { lat: 48.7879025, lng: 20.7871138 },
    { lat: 48.787918, lng: 20.786139 },
    { lat: 48.787568, lng: 20.7853048 },
    { lat: 48.7874236, lng: 20.7845535 },
    { lat: 48.7872928, lng: 20.7834566 },
    { lat: 48.7871774, lng: 20.7829568 },
    { lat: 48.7858461, lng: 20.7803888 },
    { lat: 48.7854927, lng: 20.7788518 },
    { lat: 48.7851381, lng: 20.7751162 },
    { lat: 48.7852629, lng: 20.7731239 },
    { lat: 48.7853793, lng: 20.7724616 },
    { lat: 48.7862804, lng: 20.7724729 },
    { lat: 48.7877002, lng: 20.7720944 },
    { lat: 48.7886067, lng: 20.7716896 },
    { lat: 48.7892033, lng: 20.7714964 },
    { lat: 48.7895696, lng: 20.7712853 },
    { lat: 48.7897771, lng: 20.7712625 },
    { lat: 48.7900839, lng: 20.7713418 },
    { lat: 48.7910345, lng: 20.7711633 },
    { lat: 48.7912574, lng: 20.770968 },
    { lat: 48.7914088, lng: 20.7709548 },
    { lat: 48.7915984, lng: 20.7708381 },
    { lat: 48.7914143, lng: 20.7700543 },
    { lat: 48.7912778, lng: 20.7695665 },
    { lat: 48.791124, lng: 20.7691547 },
    { lat: 48.7912699, lng: 20.7678259 },
    { lat: 48.7915028, lng: 20.7666058 },
    { lat: 48.7918682, lng: 20.7653079 },
    { lat: 48.7922345, lng: 20.7645212 },
    { lat: 48.7914579, lng: 20.7637879 },
    { lat: 48.7918165, lng: 20.7629104 },
    { lat: 48.7920554, lng: 20.7615037 },
    { lat: 48.7919839, lng: 20.7613949 },
    { lat: 48.7920596, lng: 20.7607915 },
    { lat: 48.791953, lng: 20.7602877 },
    { lat: 48.7917195, lng: 20.7599888 },
    { lat: 48.7917223, lng: 20.7598111 },
    { lat: 48.7913424, lng: 20.7592869 },
    { lat: 48.7909457, lng: 20.7597187 },
    { lat: 48.7907938, lng: 20.7597852 },
    { lat: 48.7906137, lng: 20.7600446 },
    { lat: 48.7904259, lng: 20.7598887 },
    { lat: 48.7902148, lng: 20.7599964 },
    { lat: 48.7901858, lng: 20.7600726 },
    { lat: 48.789959, lng: 20.7601219 },
    { lat: 48.7895628, lng: 20.7600239 },
    { lat: 48.7894606, lng: 20.7601306 },
    { lat: 48.7891248, lng: 20.7600863 },
    { lat: 48.7886262, lng: 20.7598676 },
    { lat: 48.7881044, lng: 20.75982 },
    { lat: 48.7880246, lng: 20.7597579 },
    { lat: 48.7877589, lng: 20.7592474 },
    { lat: 48.7876864, lng: 20.7589744 },
    { lat: 48.7872829, lng: 20.7587049 },
    { lat: 48.7871187, lng: 20.7587092 },
    { lat: 48.7870562, lng: 20.7585621 },
    { lat: 48.7868828, lng: 20.7585293 },
    { lat: 48.7866212, lng: 20.7583273 },
    { lat: 48.7861232, lng: 20.757738 },
    { lat: 48.7856266, lng: 20.7574471 },
    { lat: 48.7854308, lng: 20.7572262 },
    { lat: 48.7851305, lng: 20.7570116 },
    { lat: 48.7847781, lng: 20.7562414 },
    { lat: 48.7845779, lng: 20.7561169 },
    { lat: 48.7844746, lng: 20.7559035 },
    { lat: 48.7843151, lng: 20.7557821 },
    { lat: 48.7842363, lng: 20.7555586 },
    { lat: 48.7840807, lng: 20.7554551 },
    { lat: 48.78405, lng: 20.7553534 },
    { lat: 48.7838444, lng: 20.7551436 },
    { lat: 48.783729, lng: 20.7550612 },
    { lat: 48.783643, lng: 20.7551445 },
    { lat: 48.7832378, lng: 20.754696 },
    { lat: 48.7830718, lng: 20.7546183 },
    { lat: 48.7829663, lng: 20.7543684 },
    { lat: 48.7826573, lng: 20.754162 },
    { lat: 48.7824625, lng: 20.753648 },
    { lat: 48.782289, lng: 20.7535524 },
    { lat: 48.7820806, lng: 20.7535806 },
    { lat: 48.7818035, lng: 20.7534505 },
    { lat: 48.7816552, lng: 20.7532637 },
    { lat: 48.7814442, lng: 20.7528341 },
    { lat: 48.7811487, lng: 20.7525606 },
    { lat: 48.7809901, lng: 20.7525056 },
    { lat: 48.7808284, lng: 20.7523534 },
    { lat: 48.7805398, lng: 20.7523731 },
    { lat: 48.78037, lng: 20.7522854 },
    { lat: 48.780068, lng: 20.7518889 },
    { lat: 48.7797677, lng: 20.7517703 },
    { lat: 48.7796986, lng: 20.7515346 },
    { lat: 48.7796875, lng: 20.7514491 },
    { lat: 48.7790126, lng: 20.7519366 },
    { lat: 48.7785288, lng: 20.7525884 },
    { lat: 48.7788613, lng: 20.7528846 },
    { lat: 48.7788817, lng: 20.7531775 },
    { lat: 48.7781853, lng: 20.75341 },
    { lat: 48.7775652, lng: 20.7538005 },
    { lat: 48.7766602, lng: 20.7545091 },
    { lat: 48.7758219, lng: 20.755 },
    { lat: 48.7744546, lng: 20.755335 },
    { lat: 48.7734431, lng: 20.7554415 },
    { lat: 48.7721003, lng: 20.7550997 },
    { lat: 48.7708812, lng: 20.755006 },
    { lat: 48.7700235, lng: 20.7547842 },
    { lat: 48.7689806, lng: 20.7551545 },
    { lat: 48.7681176, lng: 20.7555805 },
    { lat: 48.7677419, lng: 20.7553692 },
    { lat: 48.7666188, lng: 20.7550864 },
    { lat: 48.7659591, lng: 20.7550233 },
    { lat: 48.765632, lng: 20.7551346 },
    { lat: 48.7651044, lng: 20.7549569 },
    { lat: 48.764592, lng: 20.7544983 },
    { lat: 48.7639336, lng: 20.7540585 },
    { lat: 48.7634806, lng: 20.7539206 },
    { lat: 48.7628737, lng: 20.7535623 },
    { lat: 48.762808, lng: 20.7536461 },
    { lat: 48.762397, lng: 20.7531005 },
    { lat: 48.7615095, lng: 20.7526108 },
    { lat: 48.7600487, lng: 20.7509959 },
    { lat: 48.7587915, lng: 20.7506859 },
    { lat: 48.7578506, lng: 20.7501463 },
    { lat: 48.7574375, lng: 20.7498304 },
    { lat: 48.7574793, lng: 20.7487484 },
    { lat: 48.7572006, lng: 20.7473281 },
    { lat: 48.756287, lng: 20.748424 },
    { lat: 48.7557307, lng: 20.7485452 },
    { lat: 48.7555449, lng: 20.7488949 },
    { lat: 48.7554726, lng: 20.749433 },
    { lat: 48.7556068, lng: 20.7497872 },
    { lat: 48.7552314, lng: 20.7497704 },
    { lat: 48.7549029, lng: 20.7488579 },
    { lat: 48.7546454, lng: 20.749021 },
    { lat: 48.7543413, lng: 20.7490477 },
    { lat: 48.7539838, lng: 20.7493869 },
    { lat: 48.7537646, lng: 20.7490778 },
    { lat: 48.7533826, lng: 20.7488389 },
    { lat: 48.7532001, lng: 20.7489967 },
    { lat: 48.7532186, lng: 20.7492331 },
    { lat: 48.7531235, lng: 20.7493136 },
    { lat: 48.7532386, lng: 20.7497342 },
    { lat: 48.7534964, lng: 20.7496717 },
    { lat: 48.7527339, lng: 20.7509426 },
    { lat: 48.7524985, lng: 20.7511884 },
    { lat: 48.7524689, lng: 20.7513407 },
    { lat: 48.7521521, lng: 20.7514215 },
    { lat: 48.7519509, lng: 20.7509526 },
    { lat: 48.751978, lng: 20.7507666 },
    { lat: 48.7518893, lng: 20.7507057 },
    { lat: 48.7518388, lng: 20.7507793 },
    { lat: 48.7516787, lng: 20.7507669 },
    { lat: 48.7516036, lng: 20.7508718 },
    { lat: 48.7512303, lng: 20.7510752 },
    { lat: 48.7510579, lng: 20.7510106 },
    { lat: 48.7509804, lng: 20.751141 },
    { lat: 48.7505787, lng: 20.7511816 },
    { lat: 48.7506007, lng: 20.7512901 },
    { lat: 48.7505202, lng: 20.7513087 },
    { lat: 48.7501882, lng: 20.7513747 },
    { lat: 48.7501849, lng: 20.7513126 },
    { lat: 48.7499565, lng: 20.7511259 },
    { lat: 48.7497258, lng: 20.7511587 },
    { lat: 48.7495024, lng: 20.7515945 },
    { lat: 48.7492352, lng: 20.7519307 },
    { lat: 48.7491496, lng: 20.7522854 },
    { lat: 48.7486677, lng: 20.7525682 },
    { lat: 48.7483889, lng: 20.7525814 },
    { lat: 48.7481343, lng: 20.7527667 },
    { lat: 48.7479098, lng: 20.7527827 },
    { lat: 48.7476894, lng: 20.7529228 },
    { lat: 48.74772, lng: 20.7528597 },
    { lat: 48.7476646, lng: 20.7528874 },
    { lat: 48.7470149, lng: 20.7533967 },
    { lat: 48.7466393, lng: 20.75385 },
    { lat: 48.7464598, lng: 20.7541923 },
    { lat: 48.7460999, lng: 20.7540732 },
    { lat: 48.7456109, lng: 20.7541886 },
    { lat: 48.7455963, lng: 20.7540603 },
    { lat: 48.7447243, lng: 20.7543267 },
    { lat: 48.7444562, lng: 20.7545153 },
    { lat: 48.7442224, lng: 20.7549725 },
    { lat: 48.7439626, lng: 20.7552641 },
    { lat: 48.7435301, lng: 20.7555158 },
    { lat: 48.7429992, lng: 20.7562367 },
    { lat: 48.7428205, lng: 20.7567821 },
    { lat: 48.7428676, lng: 20.7569999 },
    { lat: 48.7427246, lng: 20.7571601 },
    { lat: 48.742512, lng: 20.7576805 },
    { lat: 48.7420271, lng: 20.7583277 },
    { lat: 48.7419303, lng: 20.7582655 },
    { lat: 48.7409905, lng: 20.7586875 },
    { lat: 48.7405755, lng: 20.7586934 },
    { lat: 48.740353, lng: 20.7588993 },
    { lat: 48.7400423, lng: 20.7590581 },
    { lat: 48.7398916, lng: 20.7596366 },
    { lat: 48.7394132, lng: 20.7603392 },
    { lat: 48.7394976, lng: 20.7605324 },
    { lat: 48.7394845, lng: 20.7606451 },
    { lat: 48.7394238, lng: 20.7606161 },
    { lat: 48.739447, lng: 20.7607171 },
    { lat: 48.7394628, lng: 20.7609853 },
    { lat: 48.7396131, lng: 20.7636568 },
    { lat: 48.7394701, lng: 20.763782 },
    { lat: 48.7391392, lng: 20.7638179 },
    { lat: 48.7387829, lng: 20.7640573 },
    { lat: 48.7385545, lng: 20.7648011 },
    { lat: 48.738305, lng: 20.7652595 },
    { lat: 48.7380006, lng: 20.7656331 },
    { lat: 48.7377311, lng: 20.7659372 },
    { lat: 48.7376591, lng: 20.7658766 },
    { lat: 48.7376119, lng: 20.7656536 },
    { lat: 48.7375491, lng: 20.7655991 },
    { lat: 48.7371827, lng: 20.7659342 },
    { lat: 48.7366074, lng: 20.7673106 },
    { lat: 48.7365722, lng: 20.7675906 },
    { lat: 48.7364649, lng: 20.7678064 },
    { lat: 48.7361606, lng: 20.7680826 },
    { lat: 48.7360265, lng: 20.7680798 },
    { lat: 48.7358311, lng: 20.7677763 },
    { lat: 48.7357272, lng: 20.7676976 },
    { lat: 48.7355437, lng: 20.7676649 },
    { lat: 48.7351984, lng: 20.7677757 },
    { lat: 48.7348589, lng: 20.7679523 },
    { lat: 48.7338774, lng: 20.7682384 },
    { lat: 48.7331252, lng: 20.768424 },
    { lat: 48.7326194, lng: 20.7684146 },
    { lat: 48.7321085, lng: 20.7685188 },
    { lat: 48.7317455, lng: 20.7685995 },
    { lat: 48.7312995, lng: 20.7687792 },
    { lat: 48.7307916, lng: 20.7684419 },
    { lat: 48.730421, lng: 20.7682557 },
    { lat: 48.7301164, lng: 20.7681308 },
    { lat: 48.7298796, lng: 20.767978 },
    { lat: 48.7296691, lng: 20.7678815 },
    { lat: 48.7295356, lng: 20.767735 },
    { lat: 48.7290939, lng: 20.7676906 },
    { lat: 48.7290752, lng: 20.7677638 },
    { lat: 48.7285644, lng: 20.7680301 },
    { lat: 48.7281848, lng: 20.7683098 },
    { lat: 48.7278288, lng: 20.7683965 },
    { lat: 48.7275547, lng: 20.7687394 },
    { lat: 48.7270737, lng: 20.7690804 },
    { lat: 48.726973, lng: 20.7694533 },
    { lat: 48.7267575, lng: 20.7697244 },
    { lat: 48.726677, lng: 20.76975 },
    { lat: 48.726674, lng: 20.769746 },
    { lat: 48.726646, lng: 20.770239 },
    { lat: 48.726694, lng: 20.770644 },
    { lat: 48.726798, lng: 20.770978 },
    { lat: 48.72679, lng: 20.771585 },
    { lat: 48.727018, lng: 20.772506 },
    { lat: 48.727086, lng: 20.773136 },
    { lat: 48.727278, lng: 20.774014 },
    { lat: 48.727581, lng: 20.774697 },
    { lat: 48.727792, lng: 20.775104 },
    { lat: 48.727979, lng: 20.776169 },
    { lat: 48.728063, lng: 20.776448 },
    { lat: 48.727949, lng: 20.777336 },
    { lat: 48.728031, lng: 20.778375 },
    { lat: 48.728072, lng: 20.779128 },
    { lat: 48.728063, lng: 20.780801 },
    { lat: 48.728435, lng: 20.782165 },
    { lat: 48.728546, lng: 20.782498 },
    { lat: 48.728556, lng: 20.782921 },
    { lat: 48.728567, lng: 20.783701 },
    { lat: 48.728601, lng: 20.784043 },
    { lat: 48.728689, lng: 20.784723 },
    { lat: 48.728652, lng: 20.785693 },
    { lat: 48.728745, lng: 20.786277 },
    { lat: 48.728811, lng: 20.786495 },
    { lat: 48.728867, lng: 20.786758 },
    { lat: 48.729191, lng: 20.787742 },
    { lat: 48.729182, lng: 20.788205 },
    { lat: 48.729275, lng: 20.789338 },
    { lat: 48.729376, lng: 20.789948 },
    { lat: 48.729602, lng: 20.790578 },
    { lat: 48.729986, lng: 20.791258 },
    { lat: 48.730258, lng: 20.79182 },
    { lat: 48.730459, lng: 20.792348 },
    { lat: 48.730638, lng: 20.792692 },
    { lat: 48.730968, lng: 20.793645 },
    { lat: 48.73118, lng: 20.794219 },
    { lat: 48.731291, lng: 20.794334 },
    { lat: 48.731622, lng: 20.794391 },
    { lat: 48.732281, lng: 20.794435 },
    { lat: 48.73242, lng: 20.79456 },
    { lat: 48.732597, lng: 20.794932 },
    { lat: 48.732753, lng: 20.795166 },
    { lat: 48.732977, lng: 20.796044 },
    { lat: 48.733004, lng: 20.796679 },
    { lat: 48.733008, lng: 20.797318 },
    { lat: 48.732967, lng: 20.797693 },
    { lat: 48.732984, lng: 20.797973 },
    { lat: 48.732979, lng: 20.798374 },
    { lat: 48.733016, lng: 20.798568 },
    { lat: 48.733137, lng: 20.798848 },
    { lat: 48.733224, lng: 20.799181 },
    { lat: 48.73327, lng: 20.799727 },
    { lat: 48.733397, lng: 20.800185 },
    { lat: 48.733698, lng: 20.800645 },
    { lat: 48.734106, lng: 20.800704 },
    { lat: 48.73484, lng: 20.801201 },
    { lat: 48.73515, lng: 20.801709 },
    { lat: 48.735611, lng: 20.802587 },
    { lat: 48.735765, lng: 20.802788 },
    { lat: 48.73611, lng: 20.803616 },
    { lat: 48.73624, lng: 20.803877 },
    { lat: 48.736473, lng: 20.804412 },
    { lat: 48.736571, lng: 20.804711 },
    { lat: 48.736935, lng: 20.805569 },
    { lat: 48.73735, lng: 20.806527 },
    { lat: 48.737573, lng: 20.806803 },
    { lat: 48.737643, lng: 20.807075 },
    { lat: 48.737663, lng: 20.80739 },
    { lat: 48.737623, lng: 20.807767 },
    { lat: 48.737631, lng: 20.808119 },
    { lat: 48.737705, lng: 20.808532 },
    { lat: 48.737704, lng: 20.809225 },
    { lat: 48.737722, lng: 20.809399 },
    { lat: 48.737638, lng: 20.809858 },
    { lat: 48.737656, lng: 20.81049 },
    { lat: 48.737634, lng: 20.811063 },
    { lat: 48.737636, lng: 20.81173 },
    { lat: 48.737582, lng: 20.813339 },
    { lat: 48.737599, lng: 20.813493 },
    { lat: 48.737575, lng: 20.814164 },
    { lat: 48.737571, lng: 20.815047 },
    { lat: 48.737655, lng: 20.815238 },
    { lat: 48.73825, lng: 20.815793 },
    { lat: 48.73855, lng: 20.81581 },
    { lat: 48.739289, lng: 20.816165 },
    { lat: 48.739571, lng: 20.816368 },
    { lat: 48.73982, lng: 20.816485 },
    { lat: 48.740146, lng: 20.816613 },
    { lat: 48.740142, lng: 20.816635 },
    { lat: 48.739983, lng: 20.817624 },
    { lat: 48.739877, lng: 20.817962 },
    { lat: 48.739889, lng: 20.818016 },
    { lat: 48.740018, lng: 20.818612 },
    { lat: 48.739839, lng: 20.818668 },
    { lat: 48.739878, lng: 20.819079 },
    { lat: 48.740019, lng: 20.819195 },
    { lat: 48.740024, lng: 20.819409 },
    { lat: 48.739925, lng: 20.819901 },
    { lat: 48.739935, lng: 20.820015 },
    { lat: 48.739842, lng: 20.820294 },
    { lat: 48.739653, lng: 20.820708 },
    { lat: 48.739518, lng: 20.820694 },
    { lat: 48.739398, lng: 20.820604 },
    { lat: 48.739139, lng: 20.820594 },
    { lat: 48.739114, lng: 20.820391 },
    { lat: 48.738974, lng: 20.820353 },
    { lat: 48.73906, lng: 20.820631 },
    { lat: 48.739051, lng: 20.820953 },
    { lat: 48.738916, lng: 20.821147 },
    { lat: 48.738934, lng: 20.821252 },
    { lat: 48.738671, lng: 20.821769 },
    { lat: 48.738702, lng: 20.821925 },
    { lat: 48.738791, lng: 20.821945 },
    { lat: 48.738994, lng: 20.821791 },
    { lat: 48.739064, lng: 20.821653 },
    { lat: 48.739195, lng: 20.82153 },
    { lat: 48.739429, lng: 20.821645 },
    { lat: 48.739327, lng: 20.821765 },
    { lat: 48.739523, lng: 20.822159 },
    { lat: 48.739779, lng: 20.822345 },
    { lat: 48.739847, lng: 20.822532 },
    { lat: 48.739808, lng: 20.822588 },
    { lat: 48.739653, lng: 20.822937 },
    { lat: 48.739497, lng: 20.823216 },
    { lat: 48.739378, lng: 20.823051 },
    { lat: 48.739387, lng: 20.822907 },
    { lat: 48.739205, lng: 20.822847 },
    { lat: 48.739104, lng: 20.822596 },
    { lat: 48.739094, lng: 20.822571 },
    { lat: 48.739057, lng: 20.822566 },
    { lat: 48.738962, lng: 20.822735 },
    { lat: 48.738656, lng: 20.822533 },
    { lat: 48.738595, lng: 20.822314 },
    { lat: 48.738387, lng: 20.822385 },
    { lat: 48.738546, lng: 20.823292 },
    { lat: 48.738656, lng: 20.823321 },
    { lat: 48.738672, lng: 20.823433 },
    { lat: 48.738882, lng: 20.823511 },
    { lat: 48.738938, lng: 20.823532 },
    { lat: 48.73893, lng: 20.823796 },
    { lat: 48.739081, lng: 20.823985 },
    { lat: 48.739156, lng: 20.823864 },
    { lat: 48.739216, lng: 20.8237 },
    { lat: 48.739258, lng: 20.823728 },
    { lat: 48.739376, lng: 20.824045 },
    { lat: 48.739526, lng: 20.823914 },
    { lat: 48.739686, lng: 20.823976 },
    { lat: 48.739708, lng: 20.824148 },
    { lat: 48.739699, lng: 20.824268 },
    { lat: 48.739422, lng: 20.824396 },
    { lat: 48.739433, lng: 20.824608 },
    { lat: 48.739267, lng: 20.824611 },
    { lat: 48.739266, lng: 20.824763 },
    { lat: 48.739506, lng: 20.824951 },
    { lat: 48.739701, lng: 20.824919 },
    { lat: 48.740014, lng: 20.825257 },
    { lat: 48.740183, lng: 20.825256 },
    { lat: 48.740467, lng: 20.824981 },
    { lat: 48.740598, lng: 20.825006 },
    { lat: 48.740628, lng: 20.82517 },
    { lat: 48.740995, lng: 20.825308 },
    { lat: 48.741156, lng: 20.825296 },
    { lat: 48.741319, lng: 20.824901 },
    { lat: 48.741186, lng: 20.824706 },
    { lat: 48.74086, lng: 20.824589 },
    { lat: 48.741035, lng: 20.824498 },
    { lat: 48.741241, lng: 20.824545 },
    { lat: 48.74155, lng: 20.824887 },
    { lat: 48.741796, lng: 20.824989 },
    { lat: 48.742049, lng: 20.825367 },
    { lat: 48.742026, lng: 20.825125 },
    { lat: 48.742031, lng: 20.825002 },
    { lat: 48.742166, lng: 20.824587 },
    { lat: 48.742262, lng: 20.824365 },
    { lat: 48.742413, lng: 20.824339 },
    { lat: 48.742639, lng: 20.8248 },
    { lat: 48.742917, lng: 20.824701 },
    { lat: 48.742941, lng: 20.824552 },
    { lat: 48.743273, lng: 20.824326 },
    { lat: 48.74329, lng: 20.824314 },
    { lat: 48.743316, lng: 20.824456 },
    { lat: 48.743315, lng: 20.824996 },
    { lat: 48.743142, lng: 20.825725 },
    { lat: 48.743172, lng: 20.826492 },
    { lat: 48.743179, lng: 20.82675 },
    { lat: 48.743166, lng: 20.827069 },
    { lat: 48.743244, lng: 20.827404 },
    { lat: 48.743318, lng: 20.827726 },
    { lat: 48.743196, lng: 20.828306 },
    { lat: 48.743212, lng: 20.828456 },
    { lat: 48.743287, lng: 20.828732 },
    { lat: 48.743328, lng: 20.828992 },
    { lat: 48.743193, lng: 20.829364 },
    { lat: 48.742964, lng: 20.829867 },
    { lat: 48.742988, lng: 20.829882 },
    { lat: 48.743097, lng: 20.829952 },
    { lat: 48.743124, lng: 20.829969 },
    { lat: 48.743079, lng: 20.830415 },
    { lat: 48.743033, lng: 20.830741 },
    { lat: 48.743011, lng: 20.831021 },
    { lat: 48.743006, lng: 20.831215 },
    { lat: 48.74309, lng: 20.831562 },
    { lat: 48.743257, lng: 20.831941 },
    { lat: 48.743422, lng: 20.832348 },
    { lat: 48.743818, lng: 20.833067 },
    { lat: 48.744084, lng: 20.833568 },
    { lat: 48.744228, lng: 20.833743 },
    { lat: 48.744561, lng: 20.833951 },
    { lat: 48.745014, lng: 20.834256 },
    { lat: 48.745502, lng: 20.834487 },
    { lat: 48.745643, lng: 20.83467 },
    { lat: 48.745928, lng: 20.834873 },
    { lat: 48.746265, lng: 20.83506 },
    { lat: 48.746682, lng: 20.835092 },
    { lat: 48.746699, lng: 20.835022 },
    { lat: 48.746692, lng: 20.834664 },
    { lat: 48.746745, lng: 20.834505 },
    { lat: 48.747187, lng: 20.834645 },
    { lat: 48.747371, lng: 20.834815 },
    { lat: 48.7476, lng: 20.83516 },
    { lat: 48.747801, lng: 20.835518 },
    { lat: 48.74788, lng: 20.835707 },
    { lat: 48.748418, lng: 20.836574 },
    { lat: 48.748428, lng: 20.837213 },
    { lat: 48.748494, lng: 20.837662 },
    { lat: 48.748494, lng: 20.83802 },
    { lat: 48.748725, lng: 20.838409 },
    { lat: 48.748936, lng: 20.83866 },
    { lat: 48.749165, lng: 20.838835 },
    { lat: 48.749358, lng: 20.838962 },
    { lat: 48.749659, lng: 20.839102 },
    { lat: 48.749901, lng: 20.839257 },
    { lat: 48.750157, lng: 20.839573 },
    { lat: 48.75026, lng: 20.839609 },
    { lat: 48.750338, lng: 20.839624 },
    { lat: 48.750531, lng: 20.839621 },
    { lat: 48.75069, lng: 20.839738 },
    { lat: 48.750784, lng: 20.839904 },
    { lat: 48.750906, lng: 20.840213 },
    { lat: 48.751031, lng: 20.840401 },
    { lat: 48.751318, lng: 20.84061 },
    { lat: 48.751497, lng: 20.840664 },
    { lat: 48.751741, lng: 20.840872 },
    { lat: 48.752305, lng: 20.841266 },
    { lat: 48.752424, lng: 20.8414 },
    { lat: 48.752504, lng: 20.841419 },
    { lat: 48.752843, lng: 20.841627 },
    { lat: 48.752966, lng: 20.841837 },
    { lat: 48.753097, lng: 20.84195 },
    { lat: 48.753223, lng: 20.84212 },
    { lat: 48.753371, lng: 20.842438 },
    { lat: 48.753583, lng: 20.842591 },
    { lat: 48.753728, lng: 20.842648 },
    { lat: 48.753875, lng: 20.84264 },
    { lat: 48.754232, lng: 20.842531 },
    { lat: 48.754427, lng: 20.842549 },
    { lat: 48.756539, lng: 20.843739 },
    { lat: 48.757631, lng: 20.843536 },
    { lat: 48.758987, lng: 20.843132 },
    { lat: 48.759839, lng: 20.842971 },
    { lat: 48.760262, lng: 20.84277 },
    { lat: 48.761671, lng: 20.841781 },
    { lat: 48.7618472, lng: 20.8417255 },
    { lat: 48.762687, lng: 20.841461 },
    { lat: 48.763835, lng: 20.841196 },
    { lat: 48.764485, lng: 20.841709 },
    { lat: 48.765666, lng: 20.841995 },
    { lat: 48.768018, lng: 20.842169 },
    { lat: 48.768954, lng: 20.843055 },
    { lat: 48.769748, lng: 20.844488 },
    { lat: 48.770323, lng: 20.846137 },
    { lat: 48.770621, lng: 20.846726 },
    { lat: 48.771066, lng: 20.847603 },
    { lat: 48.771696, lng: 20.848395 },
    { lat: 48.772378, lng: 20.849003 },
    { lat: 48.772822, lng: 20.849814 },
    { lat: 48.773269, lng: 20.850304 },
    { lat: 48.774178, lng: 20.851342 },
    { lat: 48.775357, lng: 20.852687 },
    { lat: 48.775815, lng: 20.853379 },
    { lat: 48.775889, lng: 20.853752 },
    { lat: 48.776446, lng: 20.854357 },
    { lat: 48.776665, lng: 20.8548 },
    { lat: 48.776906, lng: 20.855264 },
    { lat: 48.777224, lng: 20.85607 },
    { lat: 48.777474, lng: 20.8565 },
    { lat: 48.779453, lng: 20.859598 },
  ],
  Helcmanovce: [
    { lat: 48.85897, lng: 20.873813 },
    { lat: 48.858786, lng: 20.87323 },
    { lat: 48.858526, lng: 20.872401 },
    { lat: 48.858407, lng: 20.872038 },
    { lat: 48.858234, lng: 20.871415 },
    { lat: 48.8579, lng: 20.87058 },
    { lat: 48.857749, lng: 20.870158 },
    { lat: 48.857504, lng: 20.869449 },
    { lat: 48.857154, lng: 20.868509 },
    { lat: 48.856959, lng: 20.868029 },
    { lat: 48.856769, lng: 20.867488 },
    { lat: 48.856646, lng: 20.867139 },
    { lat: 48.8565, lng: 20.866553 },
    { lat: 48.85642, lng: 20.866189 },
    { lat: 48.856253, lng: 20.865493 },
    { lat: 48.856204, lng: 20.864989 },
    { lat: 48.856172, lng: 20.864532 },
    { lat: 48.8561, lng: 20.863708 },
    { lat: 48.855997, lng: 20.863233 },
    { lat: 48.856004, lng: 20.863098 },
    { lat: 48.855887, lng: 20.862536 },
    { lat: 48.855882, lng: 20.862509 },
    { lat: 48.855828, lng: 20.862247 },
    { lat: 48.855773, lng: 20.862021 },
    { lat: 48.855034, lng: 20.859717 },
    { lat: 48.854717, lng: 20.859257 },
    { lat: 48.854584, lng: 20.859042 },
    { lat: 48.85437, lng: 20.858179 },
    { lat: 48.854342, lng: 20.857457 },
    { lat: 48.854147, lng: 20.856473 },
    { lat: 48.854131, lng: 20.855995 },
    { lat: 48.854076, lng: 20.855258 },
    { lat: 48.854018, lng: 20.854409 },
    { lat: 48.853724, lng: 20.853598 },
    { lat: 48.853523, lng: 20.853089 },
    { lat: 48.853421, lng: 20.852551 },
    { lat: 48.853303, lng: 20.851889 },
    { lat: 48.852917, lng: 20.851245 },
    { lat: 48.852648, lng: 20.850893 },
    { lat: 48.852404, lng: 20.850415 },
    { lat: 48.852338, lng: 20.8503 },
    { lat: 48.852191, lng: 20.849433 },
    { lat: 48.852033, lng: 20.848503 },
    { lat: 48.851921, lng: 20.846687 },
    { lat: 48.85151, lng: 20.846066 },
    { lat: 48.851086, lng: 20.845434 },
    { lat: 48.850747, lng: 20.84503 },
    { lat: 48.850422, lng: 20.844624 },
    { lat: 48.850156, lng: 20.844469 },
    { lat: 48.850046, lng: 20.844401 },
    { lat: 48.849761, lng: 20.843811 },
    { lat: 48.849464, lng: 20.84315 },
    { lat: 48.849163, lng: 20.842462 },
    { lat: 48.848913, lng: 20.841928 },
    { lat: 48.848922, lng: 20.841395 },
    { lat: 48.848929, lng: 20.840948 },
    { lat: 48.84895, lng: 20.840452 },
    { lat: 48.848979, lng: 20.839723 },
    { lat: 48.849023, lng: 20.838723 },
    { lat: 48.848991, lng: 20.838736 },
    { lat: 48.848958, lng: 20.838577 },
    { lat: 48.848878, lng: 20.838371 },
    { lat: 48.848752, lng: 20.837772 },
    { lat: 48.848617, lng: 20.837315 },
    { lat: 48.848535, lng: 20.836859 },
    { lat: 48.848438, lng: 20.836527 },
    { lat: 48.848378, lng: 20.836218 },
    { lat: 48.848378, lng: 20.835839 },
    { lat: 48.848331, lng: 20.83555 },
    { lat: 48.848274, lng: 20.835307 },
    { lat: 48.848307, lng: 20.835295 },
    { lat: 48.848293, lng: 20.834606 },
    { lat: 48.848283, lng: 20.833959 },
    { lat: 48.848273, lng: 20.83318 },
    { lat: 48.848373, lng: 20.832516 },
    { lat: 48.848475, lng: 20.831825 },
    { lat: 48.848565, lng: 20.831224 },
    { lat: 48.848653, lng: 20.830486 },
    { lat: 48.848759, lng: 20.829591 },
    { lat: 48.848793, lng: 20.829269 },
    { lat: 48.8462961, lng: 20.8301703 },
    { lat: 48.8435098, lng: 20.8299968 },
    { lat: 48.8411435, lng: 20.8300581 },
    { lat: 48.8386997, lng: 20.8294706 },
    { lat: 48.8367073, lng: 20.8285911 },
    { lat: 48.8356567, lng: 20.8288444 },
    { lat: 48.8300962, lng: 20.8314607 },
    { lat: 48.8268128, lng: 20.8354494 },
    { lat: 48.824526, lng: 20.8369306 },
    { lat: 48.8216534, lng: 20.8383682 },
    { lat: 48.8212229, lng: 20.8385837 },
    { lat: 48.8210984, lng: 20.8384606 },
    { lat: 48.8198453, lng: 20.8372212 },
    { lat: 48.8195375, lng: 20.8365162 },
    { lat: 48.8192198, lng: 20.8360533 },
    { lat: 48.8187541, lng: 20.836427 },
    { lat: 48.8180726, lng: 20.8372938 },
    { lat: 48.8156891, lng: 20.8398924 },
    { lat: 48.8154406, lng: 20.8401628 },
    { lat: 48.819175, lng: 20.8474258 },
    { lat: 48.8201625, lng: 20.8493603 },
    { lat: 48.8219245, lng: 20.8526684 },
    { lat: 48.8229863, lng: 20.8544792 },
    { lat: 48.8228534, lng: 20.8548076 },
    { lat: 48.8228883, lng: 20.855171 },
    { lat: 48.8230382, lng: 20.8557643 },
    { lat: 48.8232663, lng: 20.8562461 },
    { lat: 48.8242109, lng: 20.8576965 },
    { lat: 48.8245483, lng: 20.8584062 },
    { lat: 48.8238947, lng: 20.8591785 },
    { lat: 48.8244486, lng: 20.8600323 },
    { lat: 48.8248946, lng: 20.8605978 },
    { lat: 48.8254112, lng: 20.86296 },
    { lat: 48.8255061, lng: 20.8643551 },
    { lat: 48.8256066, lng: 20.8686423 },
    { lat: 48.825986, lng: 20.8700601 },
    { lat: 48.8263366, lng: 20.8735364 },
    { lat: 48.8264862, lng: 20.8739934 },
    { lat: 48.8264011, lng: 20.8741076 },
    { lat: 48.8259611, lng: 20.8731154 },
    { lat: 48.8256784, lng: 20.8726484 },
    { lat: 48.8255436, lng: 20.8727901 },
    { lat: 48.8254396, lng: 20.8725853 },
    { lat: 48.8255945, lng: 20.8724375 },
    { lat: 48.8252952, lng: 20.8715203 },
    { lat: 48.8244694, lng: 20.8708723 },
    { lat: 48.8237743, lng: 20.8711479 },
    { lat: 48.8234675, lng: 20.8719229 },
    { lat: 48.8237436, lng: 20.8722302 },
    { lat: 48.8240139, lng: 20.872727 },
    { lat: 48.8240938, lng: 20.8726908 },
    { lat: 48.8241936, lng: 20.8729469 },
    { lat: 48.8244165, lng: 20.8730174 },
    { lat: 48.8246831, lng: 20.8733701 },
    { lat: 48.8249323, lng: 20.8735821 },
    { lat: 48.8249773, lng: 20.8737115 },
    { lat: 48.8249882, lng: 20.8741347 },
    { lat: 48.8251448, lng: 20.8743943 },
    { lat: 48.8255499, lng: 20.8746601 },
    { lat: 48.8255282, lng: 20.8751101 },
    { lat: 48.8256713, lng: 20.8753147 },
    { lat: 48.8259555, lng: 20.8753989 },
    { lat: 48.8260023, lng: 20.8756414 },
    { lat: 48.8262342, lng: 20.8758104 },
    { lat: 48.8263256, lng: 20.8763109 },
    { lat: 48.8261805, lng: 20.8767435 },
    { lat: 48.8261828, lng: 20.8768465 },
    { lat: 48.826352, lng: 20.8769533 },
    { lat: 48.8265856, lng: 20.8777292 },
    { lat: 48.8266007, lng: 20.8779081 },
    { lat: 48.8267542, lng: 20.8781486 },
    { lat: 48.8267989, lng: 20.8783405 },
    { lat: 48.8269731, lng: 20.8784959 },
    { lat: 48.8269649, lng: 20.8789459 },
    { lat: 48.8271389, lng: 20.8792346 },
    { lat: 48.827364, lng: 20.8793959 },
    { lat: 48.8273949, lng: 20.8797769 },
    { lat: 48.8275146, lng: 20.8800844 },
    { lat: 48.8278029, lng: 20.8804184 },
    { lat: 48.827938, lng: 20.8806807 },
    { lat: 48.8280921, lng: 20.8812428 },
    { lat: 48.8280967, lng: 20.8815457 },
    { lat: 48.8281619, lng: 20.8816718 },
    { lat: 48.8276852, lng: 20.8823849 },
    { lat: 48.8265036, lng: 20.8831167 },
    { lat: 48.8249454, lng: 20.8836354 },
    { lat: 48.8240196, lng: 20.8840138 },
    { lat: 48.8229103, lng: 20.8842298 },
    { lat: 48.8224187, lng: 20.8844089 },
    { lat: 48.8221214, lng: 20.8844 },
    { lat: 48.8221632, lng: 20.885198 },
    { lat: 48.8218359, lng: 20.8853743 },
    { lat: 48.8216082, lng: 20.885626 },
    { lat: 48.8209185, lng: 20.8861964 },
    { lat: 48.8205878, lng: 20.8867716 },
    { lat: 48.8202188, lng: 20.8877659 },
    { lat: 48.8199162, lng: 20.8890961 },
    { lat: 48.8210681, lng: 20.8911898 },
    { lat: 48.8219564, lng: 20.8907312 },
    { lat: 48.8223714, lng: 20.8906578 },
    { lat: 48.8229459, lng: 20.8903697 },
    { lat: 48.8234874, lng: 20.890245 },
    { lat: 48.8243618, lng: 20.8898067 },
    { lat: 48.8248568, lng: 20.8897273 },
    { lat: 48.8255388, lng: 20.8894255 },
    { lat: 48.8258452, lng: 20.8893695 },
    { lat: 48.8263289, lng: 20.8893621 },
    { lat: 48.8274698, lng: 20.8898465 },
    { lat: 48.829515, lng: 20.8899084 },
    { lat: 48.8307914, lng: 20.8890425 },
    { lat: 48.8317518, lng: 20.8885899 },
    { lat: 48.8322274, lng: 20.8882456 },
    { lat: 48.8326842, lng: 20.8876404 },
    { lat: 48.8330877, lng: 20.8873963 },
    { lat: 48.8343467, lng: 20.8876432 },
    { lat: 48.8365564, lng: 20.8871806 },
    { lat: 48.8369657, lng: 20.8869749 },
    { lat: 48.8372654, lng: 20.8866171 },
    { lat: 48.8377452, lng: 20.8863259 },
    { lat: 48.8380223, lng: 20.8864297 },
    { lat: 48.8383716, lng: 20.8866719 },
    { lat: 48.8392596, lng: 20.8874959 },
    { lat: 48.840233, lng: 20.8830571 },
    { lat: 48.8400861, lng: 20.8827749 },
    { lat: 48.8398942, lng: 20.8821985 },
    { lat: 48.8396584, lng: 20.8820815 },
    { lat: 48.839384, lng: 20.8818238 },
    { lat: 48.8390846, lng: 20.8817576 },
    { lat: 48.8389196, lng: 20.8818366 },
    { lat: 48.8387502, lng: 20.8817269 },
    { lat: 48.8383025, lng: 20.8818637 },
    { lat: 48.837902, lng: 20.8818711 },
    { lat: 48.8373048, lng: 20.8816219 },
    { lat: 48.8371513, lng: 20.8813481 },
    { lat: 48.8370801, lng: 20.8813058 },
    { lat: 48.8370151, lng: 20.880916 },
    { lat: 48.8368897, lng: 20.8808079 },
    { lat: 48.836823, lng: 20.8806298 },
    { lat: 48.8366944, lng: 20.8806188 },
    { lat: 48.8364214, lng: 20.8803393 },
    { lat: 48.8362726, lng: 20.8803356 },
    { lat: 48.8361479, lng: 20.8802388 },
    { lat: 48.8361181, lng: 20.8803894 },
    { lat: 48.8359519, lng: 20.8804685 },
    { lat: 48.8359241, lng: 20.8803442 },
    { lat: 48.8356164, lng: 20.8800385 },
    { lat: 48.835936, lng: 20.8798023 },
    { lat: 48.8360027, lng: 20.8798771 },
    { lat: 48.8360869, lng: 20.8796748 },
    { lat: 48.8361944, lng: 20.8796603 },
    { lat: 48.8362925, lng: 20.8795615 },
    { lat: 48.836356, lng: 20.8796112 },
    { lat: 48.836562, lng: 20.8795107 },
    { lat: 48.836605, lng: 20.8794234 },
    { lat: 48.8365839, lng: 20.8793297 },
    { lat: 48.8371553, lng: 20.8791192 },
    { lat: 48.8371953, lng: 20.8789352 },
    { lat: 48.8376456, lng: 20.8787421 },
    { lat: 48.8377608, lng: 20.8785866 },
    { lat: 48.8378427, lng: 20.8786155 },
    { lat: 48.8378737, lng: 20.8785366 },
    { lat: 48.8383421, lng: 20.8783395 },
    { lat: 48.8385261, lng: 20.8780214 },
    { lat: 48.8388313, lng: 20.8780279 },
    { lat: 48.8389735, lng: 20.8779389 },
    { lat: 48.8391469, lng: 20.8777105 },
    { lat: 48.8391683, lng: 20.8775438 },
    { lat: 48.8392777, lng: 20.8774505 },
    { lat: 48.8394652, lng: 20.8775699 },
    { lat: 48.8396091, lng: 20.8775636 },
    { lat: 48.8398151, lng: 20.8772066 },
    { lat: 48.8402089, lng: 20.8768706 },
    { lat: 48.8403366, lng: 20.8770167 },
    { lat: 48.8404913, lng: 20.8770643 },
    { lat: 48.8406867, lng: 20.8770129 },
    { lat: 48.8406763, lng: 20.8768744 },
    { lat: 48.8407515, lng: 20.8767345 },
    { lat: 48.8408896, lng: 20.8766608 },
    { lat: 48.8409249, lng: 20.8765415 },
    { lat: 48.8412484, lng: 20.8764211 },
    { lat: 48.8414246, lng: 20.876442 },
    { lat: 48.8416381, lng: 20.8762631 },
    { lat: 48.8418856, lng: 20.8763273 },
    { lat: 48.8421205, lng: 20.8762153 },
    { lat: 48.8421726, lng: 20.8762555 },
    { lat: 48.842213, lng: 20.8761894 },
    { lat: 48.8423544, lng: 20.8763088 },
    { lat: 48.842418, lng: 20.8762592 },
    { lat: 48.8427711, lng: 20.8765499 },
    { lat: 48.8428553, lng: 20.8764736 },
    { lat: 48.8431931, lng: 20.876806 },
    { lat: 48.843227, lng: 20.877011 },
    { lat: 48.8433847, lng: 20.8771115 },
    { lat: 48.8433993, lng: 20.8772784 },
    { lat: 48.8437693, lng: 20.8777583 },
    { lat: 48.843761, lng: 20.8778483 },
    { lat: 48.8438695, lng: 20.878027 },
    { lat: 48.8441573, lng: 20.8782143 },
    { lat: 48.8443211, lng: 20.878531 },
    { lat: 48.844454, lng: 20.8785599 },
    { lat: 48.8445839, lng: 20.8784761 },
    { lat: 48.8447469, lng: 20.8785716 },
    { lat: 48.844825, lng: 20.8785176 },
    { lat: 48.844998, lng: 20.8785409 },
    { lat: 48.8450409, lng: 20.8783746 },
    { lat: 48.8451539, lng: 20.8783268 },
    { lat: 48.8452207, lng: 20.8782043 },
    { lat: 48.8455095, lng: 20.8779566 },
    { lat: 48.8456749, lng: 20.8778893 },
    { lat: 48.8457181, lng: 20.8779667 },
    { lat: 48.8458916, lng: 20.8778395 },
    { lat: 48.8460073, lng: 20.8776689 },
    { lat: 48.8461162, lng: 20.8776943 },
    { lat: 48.8461844, lng: 20.8775793 },
    { lat: 48.8466013, lng: 20.8774909 },
    { lat: 48.8468858, lng: 20.8773539 },
    { lat: 48.8469638, lng: 20.8773932 },
    { lat: 48.8471301, lng: 20.8772551 },
    { lat: 48.8472143, lng: 20.8772842 },
    { lat: 48.8473983, lng: 20.8770968 },
    { lat: 48.8475314, lng: 20.877209 },
    { lat: 48.8477205, lng: 20.8772081 },
    { lat: 48.8478157, lng: 20.8770945 },
    { lat: 48.8480274, lng: 20.8770994 },
    { lat: 48.8482122, lng: 20.8772343 },
    { lat: 48.8482756, lng: 20.8771472 },
    { lat: 48.8484057, lng: 20.8771659 },
    { lat: 48.8484419, lng: 20.8772381 },
    { lat: 48.8485459, lng: 20.8772178 },
    { lat: 48.8486195, lng: 20.8772946 },
    { lat: 48.8488651, lng: 20.8772336 },
    { lat: 48.8490842, lng: 20.8774177 },
    { lat: 48.849464, lng: 20.8775999 },
    { lat: 48.849587, lng: 20.8775427 },
    { lat: 48.8502437, lng: 20.8777208 },
    { lat: 48.8509936, lng: 20.877696 },
    { lat: 48.8514113, lng: 20.8775007 },
    { lat: 48.8523772, lng: 20.8762484 },
    { lat: 48.8528747, lng: 20.8757183 },
    { lat: 48.853526, lng: 20.8755747 },
    { lat: 48.8541256, lng: 20.8752014 },
    { lat: 48.8546851, lng: 20.8751051 },
    { lat: 48.856247, lng: 20.8746624 },
    { lat: 48.85897, lng: 20.873813 },
  ],
  VeľkýFolkmar: [
    { lat: 48.841163, lng: 20.9885923 },
    { lat: 48.841237, lng: 20.9891326 },
    { lat: 48.8411633, lng: 20.989249 },
    { lat: 48.841246, lng: 20.9894382 },
    { lat: 48.8412391, lng: 20.9895995 },
    { lat: 48.8410545, lng: 20.9894956 },
    { lat: 48.8403926, lng: 20.9897704 },
    { lat: 48.8402556, lng: 20.9893373 },
    { lat: 48.8400829, lng: 20.9890171 },
    { lat: 48.8397575, lng: 20.9892462 },
    { lat: 48.8397138, lng: 20.9890527 },
    { lat: 48.8395803, lng: 20.9891014 },
    { lat: 48.8392715, lng: 20.9889502 },
    { lat: 48.838259, lng: 20.9889289 },
    { lat: 48.8379667, lng: 20.9891123 },
    { lat: 48.8377304, lng: 20.9891131 },
    { lat: 48.8374804, lng: 20.9893133 },
    { lat: 48.8370942, lng: 20.9895099 },
    { lat: 48.8370015, lng: 20.989737 },
    { lat: 48.8367346, lng: 20.9897662 },
    { lat: 48.8361292, lng: 20.9901467 },
    { lat: 48.8359159, lng: 20.9905352 },
    { lat: 48.8356261, lng: 20.9907212 },
    { lat: 48.8352712, lng: 20.9911522 },
    { lat: 48.8351856, lng: 20.9913928 },
    { lat: 48.8348947, lng: 20.9915756 },
    { lat: 48.8347814, lng: 20.991829 },
    { lat: 48.834706, lng: 20.9924374 },
    { lat: 48.8345817, lng: 20.9928652 },
    { lat: 48.8343005, lng: 20.9934193 },
    { lat: 48.8339211, lng: 20.993785 },
    { lat: 48.8337658, lng: 20.9940594 },
    { lat: 48.8337344, lng: 20.9943372 },
    { lat: 48.8335057, lng: 20.9949094 },
    { lat: 48.8334313, lng: 20.9953817 },
    { lat: 48.8332034, lng: 20.9954672 },
    { lat: 48.8326587, lng: 20.996082 },
    { lat: 48.8323665, lng: 20.9955504 },
    { lat: 48.8318434, lng: 20.9948817 },
    { lat: 48.8317402, lng: 20.9946469 },
    { lat: 48.8314359, lng: 20.9941967 },
    { lat: 48.8312899, lng: 20.9941324 },
    { lat: 48.8310465, lng: 20.9938911 },
    { lat: 48.8304854, lng: 20.9947522 },
    { lat: 48.8304186, lng: 20.994917 },
    { lat: 48.8304227, lng: 20.9950403 },
    { lat: 48.8302667, lng: 20.9951864 },
    { lat: 48.830257, lng: 20.9953079 },
    { lat: 48.8301851, lng: 20.9954073 },
    { lat: 48.8299512, lng: 20.9963014 },
    { lat: 48.8299496, lng: 20.9969642 },
    { lat: 48.8298508, lng: 20.9975875 },
    { lat: 48.8297058, lng: 20.9977936 },
    { lat: 48.8296184, lng: 20.9977774 },
    { lat: 48.8294743, lng: 20.998148 },
    { lat: 48.8292502, lng: 20.9985176 },
    { lat: 48.829153, lng: 20.9990158 },
    { lat: 48.8283185, lng: 21.0000915 },
    { lat: 48.8282164, lng: 21.0003071 },
    { lat: 48.8282006, lng: 21.0005073 },
    { lat: 48.8280797, lng: 21.0008369 },
    { lat: 48.8280337, lng: 21.0008097 },
    { lat: 48.8278117, lng: 21.0010719 },
    { lat: 48.8275525, lng: 21.0016081 },
    { lat: 48.8273824, lng: 21.0021121 },
    { lat: 48.8268579, lng: 21.002762 },
    { lat: 48.826336, lng: 21.0048151 },
    { lat: 48.8260049, lng: 21.0052878 },
    { lat: 48.8256472, lng: 21.0062118 },
    { lat: 48.8253973, lng: 21.0065854 },
    { lat: 48.825284, lng: 21.0070423 },
    { lat: 48.8251507, lng: 21.0073346 },
    { lat: 48.8250124, lng: 21.0074747 },
    { lat: 48.8249144, lng: 21.008978 },
    { lat: 48.8247388, lng: 21.0095896 },
    { lat: 48.8245055, lng: 21.0107053 },
    { lat: 48.8244541, lng: 21.0113343 },
    { lat: 48.8240984, lng: 21.0128385 },
    { lat: 48.8242707, lng: 21.0128348 },
    { lat: 48.8245798, lng: 21.0129593 },
    { lat: 48.8248167, lng: 21.0132482 },
    { lat: 48.8247724, lng: 21.0141603 },
    { lat: 48.8249368, lng: 21.0145386 },
    { lat: 48.8248829, lng: 21.0146441 },
    { lat: 48.8247216, lng: 21.0145999 },
    { lat: 48.8244824, lng: 21.014858 },
    { lat: 48.8247114, lng: 21.01518 },
    { lat: 48.8246143, lng: 21.0153763 },
    { lat: 48.8246149, lng: 21.0158746 },
    { lat: 48.8245489, lng: 21.0158163 },
    { lat: 48.824528, lng: 21.0159004 },
    { lat: 48.8248188, lng: 21.0162846 },
    { lat: 48.8247408, lng: 21.0165169 },
    { lat: 48.8245357, lng: 21.016786 },
    { lat: 48.8241257, lng: 21.0167766 },
    { lat: 48.8241015, lng: 21.0170656 },
    { lat: 48.8242194, lng: 21.0171935 },
    { lat: 48.8239597, lng: 21.0176146 },
    { lat: 48.8235758, lng: 21.0179172 },
    { lat: 48.8235612, lng: 21.0180915 },
    { lat: 48.8234049, lng: 21.0182658 },
    { lat: 48.823185, lng: 21.0184199 },
    { lat: 48.8229843, lng: 21.0182068 },
    { lat: 48.8224387, lng: 21.0191056 },
    { lat: 48.8224097, lng: 21.019286 },
    { lat: 48.8222382, lng: 21.019614 },
    { lat: 48.8218848, lng: 21.0201924 },
    { lat: 48.8216565, lng: 21.0204093 },
    { lat: 48.8214862, lng: 21.0250229 },
    { lat: 48.8214264, lng: 21.0251563 },
    { lat: 48.8212505, lng: 21.0252803 },
    { lat: 48.8212432, lng: 21.0275938 },
    { lat: 48.8215845, lng: 21.0286375 },
    { lat: 48.8213999, lng: 21.0299026 },
    { lat: 48.8212768, lng: 21.0305433 },
    { lat: 48.8210035, lng: 21.0311993 },
    { lat: 48.8209586, lng: 21.0314416 },
    { lat: 48.8206393, lng: 21.0320695 },
    { lat: 48.8203488, lng: 21.0323089 },
    { lat: 48.8202382, lng: 21.0323008 },
    { lat: 48.8198943, lng: 21.0325231 },
    { lat: 48.8198396, lng: 21.0327775 },
    { lat: 48.8195365, lng: 21.0335518 },
    { lat: 48.8193964, lng: 21.0348412 },
    { lat: 48.8188382, lng: 21.0357082 },
    { lat: 48.8186627, lng: 21.0365985 },
    { lat: 48.8185587, lng: 21.036796 },
    { lat: 48.8181982, lng: 21.038808 },
    { lat: 48.817807, lng: 21.0395184 },
    { lat: 48.8177095, lng: 21.0398397 },
    { lat: 48.8176571, lng: 21.0411579 },
    { lat: 48.8175719, lng: 21.0417889 },
    { lat: 48.817547, lng: 21.0424701 },
    { lat: 48.817681, lng: 21.043022 },
    { lat: 48.817738, lng: 21.04316 },
    { lat: 48.817827, lng: 21.043269 },
    { lat: 48.818202, lng: 21.043442 },
    { lat: 48.818322, lng: 21.043559 },
    { lat: 48.818477, lng: 21.043806 },
    { lat: 48.818519, lng: 21.043967 },
    { lat: 48.818603, lng: 21.044145 },
    { lat: 48.81856, lng: 21.044232 },
    { lat: 48.818544, lng: 21.044362 },
    { lat: 48.818524, lng: 21.044441 },
    { lat: 48.818519, lng: 21.044541 },
    { lat: 48.818496, lng: 21.044759 },
    { lat: 48.818515, lng: 21.044856 },
    { lat: 48.818505, lng: 21.044968 },
    { lat: 48.818444, lng: 21.045117 },
    { lat: 48.818437, lng: 21.045466 },
    { lat: 48.818481, lng: 21.045546 },
    { lat: 48.818447, lng: 21.045724 },
    { lat: 48.818324, lng: 21.045947 },
    { lat: 48.818159, lng: 21.046512 },
    { lat: 48.81811, lng: 21.046609 },
    { lat: 48.818085, lng: 21.046679 },
    { lat: 48.818025, lng: 21.046908 },
    { lat: 48.818003, lng: 21.047048 },
    { lat: 48.817961, lng: 21.047149 },
    { lat: 48.817942, lng: 21.047337 },
    { lat: 48.817915, lng: 21.047485 },
    { lat: 48.817916, lng: 21.047753 },
    { lat: 48.817888, lng: 21.047825 },
    { lat: 48.817848, lng: 21.048171 },
    { lat: 48.81783, lng: 21.048328 },
    { lat: 48.817809, lng: 21.048422 },
    { lat: 48.817797, lng: 21.048672 },
    { lat: 48.817751, lng: 21.048789 },
    { lat: 48.817702, lng: 21.048881 },
    { lat: 48.817565, lng: 21.048985 },
    { lat: 48.81749, lng: 21.049012 },
    { lat: 48.817317, lng: 21.049173 },
    { lat: 48.817256, lng: 21.049289 },
    { lat: 48.817227, lng: 21.049471 },
    { lat: 48.81713, lng: 21.049686 },
    { lat: 48.817109, lng: 21.04977 },
    { lat: 48.817078, lng: 21.049935 },
    { lat: 48.817067, lng: 21.050211 },
    { lat: 48.817087, lng: 21.050348 },
    { lat: 48.817105, lng: 21.05046 },
    { lat: 48.817111, lng: 21.050605 },
    { lat: 48.817109, lng: 21.050698 },
    { lat: 48.817113, lng: 21.050759 },
    { lat: 48.817138, lng: 21.050938 },
    { lat: 48.81719, lng: 21.051235 },
    { lat: 48.817193, lng: 21.051312 },
    { lat: 48.817192, lng: 21.051448 },
    { lat: 48.81718, lng: 21.051507 },
    { lat: 48.817141, lng: 21.051721 },
    { lat: 48.817144, lng: 21.052019 },
    { lat: 48.817204, lng: 21.052134 },
    { lat: 48.81715, lng: 21.052359 },
    { lat: 48.81713, lng: 21.052599 },
    { lat: 48.817128, lng: 21.052732 },
    { lat: 48.817039, lng: 21.053036 },
    { lat: 48.816982, lng: 21.05359 },
    { lat: 48.816946, lng: 21.053681 },
    { lat: 48.81694, lng: 21.053883 },
    { lat: 48.816984, lng: 21.054158 },
    { lat: 48.817033, lng: 21.054264 },
    { lat: 48.817076, lng: 21.054426 },
    { lat: 48.817213, lng: 21.05478 },
    { lat: 48.817275, lng: 21.054915 },
    { lat: 48.8174, lng: 21.055072 },
    { lat: 48.817373, lng: 21.05533 },
    { lat: 48.817369, lng: 21.055447 },
    { lat: 48.817403, lng: 21.055693 },
    { lat: 48.817427, lng: 21.055806 },
    { lat: 48.817451, lng: 21.055916 },
    { lat: 48.817526, lng: 21.056027 },
    { lat: 48.817501, lng: 21.056346 },
    { lat: 48.817567, lng: 21.056525 },
    { lat: 48.817543, lng: 21.056595 },
    { lat: 48.817525, lng: 21.056698 },
    { lat: 48.817587, lng: 21.056769 },
    { lat: 48.817565, lng: 21.056888 },
    { lat: 48.817609, lng: 21.057034 },
    { lat: 48.817589, lng: 21.057147 },
    { lat: 48.817541, lng: 21.057299 },
    { lat: 48.81754, lng: 21.057443 },
    { lat: 48.817573, lng: 21.057485 },
    { lat: 48.81759, lng: 21.057498 },
    { lat: 48.817634, lng: 21.057524 },
    { lat: 48.817622, lng: 21.057616 },
    { lat: 48.817636, lng: 21.057773 },
    { lat: 48.817659, lng: 21.057844 },
    { lat: 48.817661, lng: 21.05789 },
    { lat: 48.817606, lng: 21.057925 },
    { lat: 48.81757, lng: 21.057947 },
    { lat: 48.817505, lng: 21.057991 },
    { lat: 48.817512, lng: 21.058066 },
    { lat: 48.817561, lng: 21.058144 },
    { lat: 48.817604, lng: 21.058258 },
    { lat: 48.817633, lng: 21.058432 },
    { lat: 48.817599, lng: 21.058585 },
    { lat: 48.817658, lng: 21.058881 },
    { lat: 48.817804, lng: 21.059078 },
    { lat: 48.817913, lng: 21.059138 },
    { lat: 48.818007, lng: 21.059144 },
    { lat: 48.818074, lng: 21.059193 },
    { lat: 48.818127, lng: 21.059213 },
    { lat: 48.818149, lng: 21.059262 },
    { lat: 48.818114, lng: 21.059416 },
    { lat: 48.818305, lng: 21.059465 },
    { lat: 48.818403, lng: 21.059726 },
    { lat: 48.818432, lng: 21.059876 },
    { lat: 48.818581, lng: 21.060157 },
    { lat: 48.81866, lng: 21.060257 },
    { lat: 48.818775, lng: 21.060331 },
    { lat: 48.819001, lng: 21.060663 },
    { lat: 48.819077, lng: 21.060737 },
    { lat: 48.81918, lng: 21.060812 },
    { lat: 48.819234, lng: 21.060843 },
    { lat: 48.819374, lng: 21.060982 },
    { lat: 48.819408, lng: 21.061065 },
    { lat: 48.81952, lng: 21.061187 },
    { lat: 48.819586, lng: 21.061249 },
    { lat: 48.819858, lng: 21.06147 },
    { lat: 48.81991, lng: 21.06151 },
    { lat: 48.820184, lng: 21.06197 },
    { lat: 48.820613, lng: 21.062193 },
    { lat: 48.820721, lng: 21.062279 },
    { lat: 48.820799, lng: 21.062375 },
    { lat: 48.820846, lng: 21.062493 },
    { lat: 48.820976, lng: 21.062639 },
    { lat: 48.821017, lng: 21.062697 },
    { lat: 48.82107, lng: 21.062701 },
    { lat: 48.821212, lng: 21.062852 },
    { lat: 48.821328, lng: 21.063079 },
    { lat: 48.821416, lng: 21.063149 },
    { lat: 48.821467, lng: 21.063232 },
    { lat: 48.821497, lng: 21.063393 },
    { lat: 48.821469, lng: 21.063507 },
    { lat: 48.821635, lng: 21.063847 },
    { lat: 48.821723, lng: 21.063923 },
    { lat: 48.821756, lng: 21.063997 },
    { lat: 48.82192, lng: 21.064173 },
    { lat: 48.821934, lng: 21.064152 },
    { lat: 48.82201, lng: 21.064062 },
    { lat: 48.822092, lng: 21.064073 },
    { lat: 48.822179, lng: 21.064 },
    { lat: 48.822336, lng: 21.063788 },
    { lat: 48.822421, lng: 21.063567 },
    { lat: 48.822449, lng: 21.063388 },
    { lat: 48.822489, lng: 21.063269 },
    { lat: 48.822474, lng: 21.063188 },
    { lat: 48.822491, lng: 21.06311 },
    { lat: 48.822503, lng: 21.063097 },
    { lat: 48.822545, lng: 21.063045 },
    { lat: 48.822572, lng: 21.062996 },
    { lat: 48.822843, lng: 21.062645 },
    { lat: 48.822973, lng: 21.062524 },
    { lat: 48.823167, lng: 21.062346 },
    { lat: 48.823321, lng: 21.062239 },
    { lat: 48.823656, lng: 21.06201 },
    { lat: 48.823887, lng: 21.061856 },
    { lat: 48.824839, lng: 21.061286 },
    { lat: 48.825289, lng: 21.060686 },
    { lat: 48.825602, lng: 21.06021 },
    { lat: 48.826013, lng: 21.059817 },
    { lat: 48.826021, lng: 21.059802 },
    { lat: 48.826152, lng: 21.059876 },
    { lat: 48.826434, lng: 21.059981 },
    { lat: 48.826629, lng: 21.060034 },
    { lat: 48.826909, lng: 21.060126 },
    { lat: 48.827192, lng: 21.06029 },
    { lat: 48.827247, lng: 21.060303 },
    { lat: 48.827513, lng: 21.060275 },
    { lat: 48.827968, lng: 21.060213 },
    { lat: 48.828215, lng: 21.060226 },
    { lat: 48.82863, lng: 21.060292 },
    { lat: 48.828919, lng: 21.060282 },
    { lat: 48.828915, lng: 21.060304 },
    { lat: 48.828847, lng: 21.060633 },
    { lat: 48.828816, lng: 21.060778 },
    { lat: 48.828795, lng: 21.060869 },
    { lat: 48.82878, lng: 21.060936 },
    { lat: 48.828743, lng: 21.061108 },
    { lat: 48.82941, lng: 21.060774 },
    { lat: 48.83023, lng: 21.060346 },
    { lat: 48.830299, lng: 21.060311 },
    { lat: 48.830261, lng: 21.059546 },
    { lat: 48.830269, lng: 21.05904 },
    { lat: 48.830304, lng: 21.058685 },
    { lat: 48.830326, lng: 21.058321 },
    { lat: 48.830373, lng: 21.057784 },
    { lat: 48.830401, lng: 21.057609 },
    { lat: 48.830548, lng: 21.056399 },
    { lat: 48.830645, lng: 21.056335 },
    { lat: 48.830795, lng: 21.055935 },
    { lat: 48.830865, lng: 21.055672 },
    { lat: 48.83099, lng: 21.055158 },
    { lat: 48.831171, lng: 21.05437 },
    { lat: 48.831388, lng: 21.053437 },
    { lat: 48.831446, lng: 21.053211 },
    { lat: 48.831551, lng: 21.053016 },
    { lat: 48.831642, lng: 21.052853 },
    { lat: 48.831834, lng: 21.053086 },
    { lat: 48.831854, lng: 21.053089 },
    { lat: 48.831974, lng: 21.053325 },
    { lat: 48.832054, lng: 21.053452 },
    { lat: 48.832095, lng: 21.053563 },
    { lat: 48.832265, lng: 21.053737 },
    { lat: 48.832355, lng: 21.053885 },
    { lat: 48.832533, lng: 21.054022 },
    { lat: 48.832608, lng: 21.054101 },
    { lat: 48.832707, lng: 21.054253 },
    { lat: 48.832741, lng: 21.054371 },
    { lat: 48.832814, lng: 21.054512 },
    { lat: 48.832937, lng: 21.054551 },
    { lat: 48.833069, lng: 21.054616 },
    { lat: 48.833068, lng: 21.054635 },
    { lat: 48.833039, lng: 21.054904 },
    { lat: 48.833019, lng: 21.055218 },
    { lat: 48.83298, lng: 21.055702 },
    { lat: 48.83296, lng: 21.056058 },
    { lat: 48.832905, lng: 21.056466 },
    { lat: 48.832856, lng: 21.056889 },
    { lat: 48.832833, lng: 21.057295 },
    { lat: 48.832817, lng: 21.057559 },
    { lat: 48.832782, lng: 21.057841 },
    { lat: 48.83274, lng: 21.058156 },
    { lat: 48.832896, lng: 21.058131 },
    { lat: 48.832899, lng: 21.058189 },
    { lat: 48.832993, lng: 21.058244 },
    { lat: 48.83312, lng: 21.05823 },
    { lat: 48.833279, lng: 21.058255 },
    { lat: 48.833541, lng: 21.058154 },
    { lat: 48.833553, lng: 21.058146 },
    { lat: 48.833567, lng: 21.058221 },
    { lat: 48.833633, lng: 21.058359 },
    { lat: 48.83363, lng: 21.058456 },
    { lat: 48.833713, lng: 21.058648 },
    { lat: 48.833768, lng: 21.058668 },
    { lat: 48.833846, lng: 21.05882 },
    { lat: 48.83394, lng: 21.058935 },
    { lat: 48.834035, lng: 21.059034 },
    { lat: 48.834119, lng: 21.05913 },
    { lat: 48.834095, lng: 21.059343 },
    { lat: 48.834102, lng: 21.059599 },
    { lat: 48.834091, lng: 21.059745 },
    { lat: 48.834131, lng: 21.060194 },
    { lat: 48.83407, lng: 21.060288 },
    { lat: 48.83412, lng: 21.060437 },
    { lat: 48.834172, lng: 21.060483 },
    { lat: 48.834201, lng: 21.060745 },
    { lat: 48.834176, lng: 21.060848 },
    { lat: 48.834184, lng: 21.061016 },
    { lat: 48.834224, lng: 21.061178 },
    { lat: 48.834215, lng: 21.061319 },
    { lat: 48.834242, lng: 21.061439 },
    { lat: 48.834272, lng: 21.06158 },
    { lat: 48.834231, lng: 21.061686 },
    { lat: 48.834149, lng: 21.061756 },
    { lat: 48.834119, lng: 21.061911 },
    { lat: 48.83414, lng: 21.06202 },
    { lat: 48.834121, lng: 21.062127 },
    { lat: 48.834044, lng: 21.062286 },
    { lat: 48.834006, lng: 21.062762 },
    { lat: 48.834012, lng: 21.062933 },
    { lat: 48.834022, lng: 21.063023 },
    { lat: 48.834054, lng: 21.063081 },
    { lat: 48.834034, lng: 21.06322 },
    { lat: 48.834114, lng: 21.063396 },
    { lat: 48.834156, lng: 21.063592 },
    { lat: 48.834155, lng: 21.063728 },
    { lat: 48.834187, lng: 21.063795 },
    { lat: 48.834302, lng: 21.063919 },
    { lat: 48.834353, lng: 21.064056 },
    { lat: 48.834364, lng: 21.064225 },
    { lat: 48.834318, lng: 21.064347 },
    { lat: 48.83435, lng: 21.064569 },
    { lat: 48.834418, lng: 21.064723 },
    { lat: 48.834458, lng: 21.065123 },
    { lat: 48.834431, lng: 21.065262 },
    { lat: 48.834452, lng: 21.06538 },
    { lat: 48.834427, lng: 21.065552 },
    { lat: 48.834396, lng: 21.065867 },
    { lat: 48.834444, lng: 21.066092 },
    { lat: 48.834484, lng: 21.066544 },
    { lat: 48.834578, lng: 21.066814 },
    { lat: 48.834649, lng: 21.066934 },
    { lat: 48.834729, lng: 21.067119 },
    { lat: 48.834815, lng: 21.067362 },
    { lat: 48.834999, lng: 21.067711 },
    { lat: 48.835006, lng: 21.067883 },
    { lat: 48.835036, lng: 21.067956 },
    { lat: 48.835077, lng: 21.068049 },
    { lat: 48.835144, lng: 21.068174 },
    { lat: 48.835275, lng: 21.068231 },
    { lat: 48.835384, lng: 21.068813 },
    { lat: 48.835444, lng: 21.069132 },
    { lat: 48.835613, lng: 21.06974 },
    { lat: 48.835975, lng: 21.070577 },
    { lat: 48.836182, lng: 21.070938 },
    { lat: 48.836451, lng: 21.071548 },
    { lat: 48.836507, lng: 21.072143 },
    { lat: 48.836654, lng: 21.072918 },
    { lat: 48.836934, lng: 21.073207 },
    { lat: 48.837143, lng: 21.073338 },
    { lat: 48.837423, lng: 21.073279 },
    { lat: 48.837931, lng: 21.073262 },
    { lat: 48.838197, lng: 21.073069 },
    { lat: 48.838273, lng: 21.072982 },
    { lat: 48.838489, lng: 21.072794 },
    { lat: 48.838711, lng: 21.072716 },
    { lat: 48.838959, lng: 21.072836 },
    { lat: 48.839207, lng: 21.07288 },
    { lat: 48.839548, lng: 21.072555 },
    { lat: 48.83978, lng: 21.07245 },
    { lat: 48.839983, lng: 21.072389 },
    { lat: 48.840544, lng: 21.072192 },
    { lat: 48.840834, lng: 21.072297 },
    { lat: 48.841053, lng: 21.072256 },
    { lat: 48.841159, lng: 21.072164 },
    { lat: 48.841476, lng: 21.072011 },
    { lat: 48.84168, lng: 21.071848 },
    { lat: 48.841885, lng: 21.071797 },
    { lat: 48.842348, lng: 21.071131 },
    { lat: 48.843048, lng: 21.070496 },
    { lat: 48.844337, lng: 21.071288 },
    { lat: 48.845109, lng: 21.071281 },
    { lat: 48.846036, lng: 21.071189 },
    { lat: 48.846404, lng: 21.071184 },
    { lat: 48.847176, lng: 21.071078 },
    { lat: 48.847672, lng: 21.070368 },
    { lat: 48.848666, lng: 21.06993 },
    { lat: 48.849013, lng: 21.069749 },
    { lat: 48.849301, lng: 21.069623 },
    { lat: 48.849747, lng: 21.069576 },
    { lat: 48.850163, lng: 21.069344 },
    { lat: 48.850294, lng: 21.069095 },
    { lat: 48.849196, lng: 21.06625 },
    { lat: 48.848888, lng: 21.065512 },
    { lat: 48.848681, lng: 21.06495 },
    { lat: 48.848457, lng: 21.064403 },
    { lat: 48.848362, lng: 21.063953 },
    { lat: 48.848374, lng: 21.063615 },
    { lat: 48.848455, lng: 21.063286 },
    { lat: 48.848829, lng: 21.062649 },
    { lat: 48.849066, lng: 21.062419 },
    { lat: 48.850434, lng: 21.061065 },
    { lat: 48.850749, lng: 21.06088 },
    { lat: 48.852223, lng: 21.061187 },
    { lat: 48.852688, lng: 21.06115 },
    { lat: 48.853063, lng: 21.061045 },
    { lat: 48.853435, lng: 21.060992 },
    { lat: 48.854217, lng: 21.061043 },
    { lat: 48.855159, lng: 21.061205 },
    { lat: 48.855563, lng: 21.061405 },
    { lat: 48.856061, lng: 21.06186 },
    { lat: 48.85647, lng: 21.061839 },
    { lat: 48.856847, lng: 21.061724 },
    { lat: 48.857069, lng: 21.061788 },
    { lat: 48.858386, lng: 21.061106 },
    { lat: 48.858687, lng: 21.059023 },
    { lat: 48.858888, lng: 21.058571 },
    { lat: 48.85909, lng: 21.057848 },
    { lat: 48.859227, lng: 21.056777 },
    { lat: 48.859424, lng: 21.056111 },
    { lat: 48.859726, lng: 21.05529 },
    { lat: 48.859815, lng: 21.054923 },
    { lat: 48.860088, lng: 21.054209 },
    { lat: 48.860337, lng: 21.053071 },
    { lat: 48.860434, lng: 21.052323 },
    { lat: 48.86071, lng: 21.051604 },
    { lat: 48.860923, lng: 21.050668 },
    { lat: 48.861173, lng: 21.049765 },
    { lat: 48.861429, lng: 21.049239 },
    { lat: 48.861507, lng: 21.048927 },
    { lat: 48.861631, lng: 21.048359 },
    { lat: 48.861727, lng: 21.047809 },
    { lat: 48.861813, lng: 21.047051 },
    { lat: 48.861768, lng: 21.045361 },
    { lat: 48.861796, lng: 21.044919 },
    { lat: 48.861896, lng: 21.044209 },
    { lat: 48.861887, lng: 21.043735 },
    { lat: 48.861796, lng: 21.043096 },
    { lat: 48.861849, lng: 21.042695 },
    { lat: 48.861983, lng: 21.042375 },
    { lat: 48.862427, lng: 21.041774 },
    { lat: 48.862621, lng: 21.041679 },
    { lat: 48.8625086, lng: 21.0409693 },
    { lat: 48.8624922, lng: 21.0398467 },
    { lat: 48.8626237, lng: 21.0395337 },
    { lat: 48.862779, lng: 21.0393916 },
    { lat: 48.8628236, lng: 21.0391401 },
    { lat: 48.8624509, lng: 21.0371699 },
    { lat: 48.8624877, lng: 21.0363679 },
    { lat: 48.8624545, lng: 21.0354557 },
    { lat: 48.8626666, lng: 21.0342683 },
    { lat: 48.8626276, lng: 21.0331485 },
    { lat: 48.8627753, lng: 21.031883 },
    { lat: 48.8626273, lng: 21.0304894 },
    { lat: 48.8625579, lng: 21.02858 },
    { lat: 48.8624447, lng: 21.0272223 },
    { lat: 48.8625867, lng: 21.0233843 },
    { lat: 48.8625796, lng: 21.0218306 },
    { lat: 48.8626714, lng: 21.0205465 },
    { lat: 48.8624719, lng: 21.0195138 },
    { lat: 48.862557, lng: 21.0190187 },
    { lat: 48.8630914, lng: 21.0179188 },
    { lat: 48.8633483, lng: 21.0177926 },
    { lat: 48.8634504, lng: 21.01754 },
    { lat: 48.8635771, lng: 21.0169388 },
    { lat: 48.8634906, lng: 21.0162962 },
    { lat: 48.8635235, lng: 21.0158629 },
    { lat: 48.8632284, lng: 21.0152357 },
    { lat: 48.8626309, lng: 21.0144862 },
    { lat: 48.8621782, lng: 21.012909 },
    { lat: 48.8625986, lng: 21.0124461 },
    { lat: 48.8630636, lng: 21.0121768 },
    { lat: 48.8627317, lng: 21.0104718 },
    { lat: 48.8623317, lng: 21.0111437 },
    { lat: 48.8620726, lng: 21.010626 },
    { lat: 48.8622177, lng: 21.0105203 },
    { lat: 48.8627271, lng: 21.0098605 },
    { lat: 48.8624787, lng: 21.0097656 },
    { lat: 48.862439, lng: 21.0095195 },
    { lat: 48.8619206, lng: 21.0088813 },
    { lat: 48.8612577, lng: 21.0077176 },
    { lat: 48.8609411, lng: 21.0069265 },
    { lat: 48.8609136, lng: 21.0059871 },
    { lat: 48.8607113, lng: 21.0054891 },
    { lat: 48.8602758, lng: 21.0046682 },
    { lat: 48.8602852, lng: 21.0045745 },
    { lat: 48.8599881, lng: 21.0035897 },
    { lat: 48.8596698, lng: 21.0030162 },
    { lat: 48.8592182, lng: 21.0024292 },
    { lat: 48.8590138, lng: 21.0025755 },
    { lat: 48.8587089, lng: 21.0024128 },
    { lat: 48.8583998, lng: 21.0017085 },
    { lat: 48.8582525, lng: 21.0009619 },
    { lat: 48.8580438, lng: 21.0007128 },
    { lat: 48.8577558, lng: 21.0006761 },
    { lat: 48.8574341, lng: 21.0003119 },
    { lat: 48.8572051, lng: 20.9997856 },
    { lat: 48.8568743, lng: 20.9995169 },
    { lat: 48.8568357, lng: 20.9989272 },
    { lat: 48.856441, lng: 20.9977452 },
    { lat: 48.8563685, lng: 20.9968442 },
    { lat: 48.8563736, lng: 20.9958073 },
    { lat: 48.856174, lng: 20.9948771 },
    { lat: 48.855944, lng: 20.9945636 },
    { lat: 48.8552391, lng: 20.9940081 },
    { lat: 48.8540974, lng: 20.9925637 },
    { lat: 48.8539323, lng: 20.9922367 },
    { lat: 48.8535606, lng: 20.9919587 },
    { lat: 48.852885, lng: 20.9927595 },
    { lat: 48.8524659, lng: 20.9925812 },
    { lat: 48.8522483, lng: 20.9923647 },
    { lat: 48.8515846, lng: 20.9922054 },
    { lat: 48.8512819, lng: 20.9923467 },
    { lat: 48.8509995, lng: 20.9923799 },
    { lat: 48.8509476, lng: 20.9924436 },
    { lat: 48.8509266, lng: 20.9927629 },
    { lat: 48.850698, lng: 20.9931315 },
    { lat: 48.8497514, lng: 20.9932996 },
    { lat: 48.8492495, lng: 20.9934793 },
    { lat: 48.8488585, lng: 20.9933932 },
    { lat: 48.8479912, lng: 20.9934051 },
    { lat: 48.8476701, lng: 20.9929907 },
    { lat: 48.8475172, lng: 20.9926524 },
    { lat: 48.8472529, lng: 20.9917788 },
    { lat: 48.8470644, lng: 20.9909451 },
    { lat: 48.8459564, lng: 20.9902553 },
    { lat: 48.8457682, lng: 20.9902485 },
    { lat: 48.8451957, lng: 20.9906655 },
    { lat: 48.8446093, lng: 20.9904921 },
    { lat: 48.8442072, lng: 20.990488 },
    { lat: 48.8439457, lng: 20.9904107 },
    { lat: 48.8420997, lng: 20.9894136 },
    { lat: 48.841163, lng: 20.9885923 },
  ],
  Kluknava: [
    { lat: 48.951331, lng: 20.939527 },
    { lat: 48.9511196, lng: 20.938925 },
    { lat: 48.9498283, lng: 20.936735 },
    { lat: 48.9495328, lng: 20.9361089 },
    { lat: 48.9492697, lng: 20.9351204 },
    { lat: 48.9492365, lng: 20.9349956 },
    { lat: 48.9490444, lng: 20.9346355 },
    { lat: 48.9486561, lng: 20.9343017 },
    { lat: 48.9481364, lng: 20.9335397 },
    { lat: 48.9481523, lng: 20.9324649 },
    { lat: 48.9478011, lng: 20.9322346 },
    { lat: 48.9475369, lng: 20.9319718 },
    { lat: 48.9472241, lng: 20.9317729 },
    { lat: 48.9469614, lng: 20.9317958 },
    { lat: 48.9468018, lng: 20.9320018 },
    { lat: 48.9462541, lng: 20.9319812 },
    { lat: 48.9461549, lng: 20.9319188 },
    { lat: 48.9456607, lng: 20.9316078 },
    { lat: 48.9454562, lng: 20.9316519 },
    { lat: 48.9452634, lng: 20.9315067 },
    { lat: 48.9446996, lng: 20.932347 },
    { lat: 48.9445873, lng: 20.9323195 },
    { lat: 48.9444849, lng: 20.9321556 },
    { lat: 48.9442054, lng: 20.9319796 },
    { lat: 48.9438704, lng: 20.9319915 },
    { lat: 48.9436726, lng: 20.9313616 },
    { lat: 48.9434579, lng: 20.9310989 },
    { lat: 48.9432071, lng: 20.930906 },
    { lat: 48.9420181, lng: 20.9307924 },
    { lat: 48.9403686, lng: 20.93017 },
    { lat: 48.939884, lng: 20.930153 },
    { lat: 48.9394845, lng: 20.9300203 },
    { lat: 48.9391505, lng: 20.9301328 },
    { lat: 48.9389882, lng: 20.9302578 },
    { lat: 48.9374689, lng: 20.9292898 },
    { lat: 48.9369627, lng: 20.9302387 },
    { lat: 48.9367472, lng: 20.9305278 },
    { lat: 48.9364105, lng: 20.9308539 },
    { lat: 48.9360416, lng: 20.9309952 },
    { lat: 48.9356148, lng: 20.9312588 },
    { lat: 48.9352147, lng: 20.9316774 },
    { lat: 48.9348276, lng: 20.9319487 },
    { lat: 48.934102, lng: 20.9321304 },
    { lat: 48.933904, lng: 20.9320698 },
    { lat: 48.9337507, lng: 20.9313883 },
    { lat: 48.9332769, lng: 20.9313888 },
    { lat: 48.9329833, lng: 20.931666 },
    { lat: 48.932753, lng: 20.9316401 },
    { lat: 48.932482, lng: 20.9314151 },
    { lat: 48.9323475, lng: 20.9314628 },
    { lat: 48.932271, lng: 20.9315664 },
    { lat: 48.9319832, lng: 20.9315446 },
    { lat: 48.931899, lng: 20.9317201 },
    { lat: 48.9317279, lng: 20.9318859 },
    { lat: 48.9315993, lng: 20.9318747 },
    { lat: 48.9315695, lng: 20.9320628 },
    { lat: 48.9312831, lng: 20.9324186 },
    { lat: 48.931126, lng: 20.932299 },
    { lat: 48.9310165, lng: 20.932354 },
    { lat: 48.9309875, lng: 20.9325302 },
    { lat: 48.9308245, lng: 20.9326001 },
    { lat: 48.9304756, lng: 20.9325698 },
    { lat: 48.9302262, lng: 20.9324194 },
    { lat: 48.9301189, lng: 20.9325043 },
    { lat: 48.929992, lng: 20.9327847 },
    { lat: 48.9299205, lng: 20.9328339 },
    { lat: 48.9297727, lng: 20.9327508 },
    { lat: 48.9293613, lng: 20.9328678 },
    { lat: 48.9288248, lng: 20.93292 },
    { lat: 48.9287913, lng: 20.9330604 },
    { lat: 48.9288524, lng: 20.933201 },
    { lat: 48.928835, lng: 20.9333223 },
    { lat: 48.9286657, lng: 20.9334446 },
    { lat: 48.9284682, lng: 20.933292 },
    { lat: 48.9283365, lng: 20.933291 },
    { lat: 48.9282044, lng: 20.9335157 },
    { lat: 48.9281042, lng: 20.9335438 },
    { lat: 48.9280117, lng: 20.9332696 },
    { lat: 48.9278938, lng: 20.933283 },
    { lat: 48.9278499, lng: 20.9334149 },
    { lat: 48.9276878, lng: 20.9334482 },
    { lat: 48.9276218, lng: 20.9335911 },
    { lat: 48.9272811, lng: 20.9338397 },
    { lat: 48.9269257, lng: 20.9336105 },
    { lat: 48.9265594, lng: 20.9337989 },
    { lat: 48.9265544, lng: 20.9339539 },
    { lat: 48.9264842, lng: 20.9340753 },
    { lat: 48.9262672, lng: 20.9342162 },
    { lat: 48.9258644, lng: 20.9340204 },
    { lat: 48.9257088, lng: 20.9338732 },
    { lat: 48.9252908, lng: 20.9339635 },
    { lat: 48.9247075, lng: 20.9339563 },
    { lat: 48.923645, lng: 20.933149 },
    { lat: 48.9236394, lng: 20.9332213 },
    { lat: 48.9235118, lng: 20.9331707 },
    { lat: 48.9235023, lng: 20.9327194 },
    { lat: 48.9231569, lng: 20.932289 },
    { lat: 48.9227098, lng: 20.9323479 },
    { lat: 48.9227197, lng: 20.9322069 },
    { lat: 48.9225918, lng: 20.9322533 },
    { lat: 48.9224585, lng: 20.9330127 },
    { lat: 48.9222818, lng: 20.9336495 },
    { lat: 48.9220194, lng: 20.9345169 },
    { lat: 48.9215859, lng: 20.9356842 },
    { lat: 48.920709, lng: 20.9344077 },
    { lat: 48.9207005, lng: 20.9342949 },
    { lat: 48.9192675, lng: 20.9319068 },
    { lat: 48.9184612, lng: 20.9308214 },
    { lat: 48.9191703, lng: 20.929475 },
    { lat: 48.9201602, lng: 20.9273853 },
    { lat: 48.9213186, lng: 20.9247502 },
    { lat: 48.921538, lng: 20.9240058 },
    { lat: 48.9217207, lng: 20.9232126 },
    { lat: 48.9218628, lng: 20.9224231 },
    { lat: 48.9202042, lng: 20.92093 },
    { lat: 48.9178792, lng: 20.9185675 },
    { lat: 48.9181835, lng: 20.9183804 },
    { lat: 48.9183962, lng: 20.9179158 },
    { lat: 48.9180481, lng: 20.9175041 },
    { lat: 48.9184236, lng: 20.9160219 },
    { lat: 48.9185838, lng: 20.9158964 },
    { lat: 48.9188885, lng: 20.9161909 },
    { lat: 48.9191574, lng: 20.9163511 },
    { lat: 48.9192513, lng: 20.9161699 },
    { lat: 48.9195318, lng: 20.9158711 },
    { lat: 48.9196484, lng: 20.9150904 },
    { lat: 48.9199279, lng: 20.9144689 },
    { lat: 48.920271, lng: 20.9148291 },
    { lat: 48.920384, lng: 20.9148783 },
    { lat: 48.9205145, lng: 20.9147755 },
    { lat: 48.9206391, lng: 20.9147998 },
    { lat: 48.9207277, lng: 20.9146913 },
    { lat: 48.9207384, lng: 20.914567 },
    { lat: 48.9208409, lng: 20.9146403 },
    { lat: 48.9205198, lng: 20.9123951 },
    { lat: 48.9202733, lng: 20.9127632 },
    { lat: 48.9202, lng: 20.9129553 },
    { lat: 48.9200402, lng: 20.9131259 },
    { lat: 48.9200107, lng: 20.9135878 },
    { lat: 48.9198809, lng: 20.9138426 },
    { lat: 48.9196584, lng: 20.9136183 },
    { lat: 48.919726, lng: 20.9135505 },
    { lat: 48.9197405, lng: 20.9134144 },
    { lat: 48.9195281, lng: 20.9130956 },
    { lat: 48.9191786, lng: 20.9127759 },
    { lat: 48.9191059, lng: 20.9125228 },
    { lat: 48.9187917, lng: 20.9119853 },
    { lat: 48.9188054, lng: 20.9118855 },
    { lat: 48.9190686, lng: 20.9114524 },
    { lat: 48.9191875, lng: 20.9113694 },
    { lat: 48.9193793, lng: 20.9113488 },
    { lat: 48.9195322, lng: 20.9111483 },
    { lat: 48.9193661, lng: 20.9108255 },
    { lat: 48.9192506, lng: 20.9107368 },
    { lat: 48.9191261, lng: 20.9105231 },
    { lat: 48.9191026, lng: 20.910356 },
    { lat: 48.9192482, lng: 20.9103032 },
    { lat: 48.9193832, lng: 20.9103696 },
    { lat: 48.9196228, lng: 20.9102973 },
    { lat: 48.91957, lng: 20.9100684 },
    { lat: 48.9195986, lng: 20.9099442 },
    { lat: 48.9197433, lng: 20.9098649 },
    { lat: 48.9197335, lng: 20.9097401 },
    { lat: 48.9196168, lng: 20.9097488 },
    { lat: 48.9194785, lng: 20.9094897 },
    { lat: 48.9196034, lng: 20.9092201 },
    { lat: 48.9197448, lng: 20.9091107 },
    { lat: 48.9198417, lng: 20.9088462 },
    { lat: 48.9197886, lng: 20.9084055 },
    { lat: 48.919555, lng: 20.908025 },
    { lat: 48.9197611, lng: 20.9077928 },
    { lat: 48.9198588, lng: 20.907754 },
    { lat: 48.9199674, lng: 20.9078046 },
    { lat: 48.9203157, lng: 20.9076834 },
    { lat: 48.9202528, lng: 20.9074202 },
    { lat: 48.9205325, lng: 20.9072378 },
    { lat: 48.9207847, lng: 20.9067103 },
    { lat: 48.9210031, lng: 20.9067097 },
    { lat: 48.9211139, lng: 20.906588 },
    { lat: 48.921097, lng: 20.9062908 },
    { lat: 48.921397, lng: 20.905877 },
    { lat: 48.921055, lng: 20.90614 },
    { lat: 48.920872, lng: 20.906334 },
    { lat: 48.920736, lng: 20.906607 },
    { lat: 48.920589, lng: 20.906799 },
    { lat: 48.920451, lng: 20.907075 },
    { lat: 48.920312, lng: 20.907094 },
    { lat: 48.920191, lng: 20.9072 },
    { lat: 48.920071, lng: 20.907262 },
    { lat: 48.919957, lng: 20.907373 },
    { lat: 48.919689, lng: 20.907671 },
    { lat: 48.919375, lng: 20.907759 },
    { lat: 48.91918, lng: 20.907876 },
    { lat: 48.919177, lng: 20.907856 },
    { lat: 48.918761, lng: 20.907802 },
    { lat: 48.918523, lng: 20.90778 },
    { lat: 48.918146, lng: 20.907693 },
    { lat: 48.917783, lng: 20.907673 },
    { lat: 48.917249, lng: 20.907564 },
    { lat: 48.916737, lng: 20.907449 },
    { lat: 48.91648, lng: 20.907424 },
    { lat: 48.915931, lng: 20.907351 },
    { lat: 48.915225, lng: 20.907268 },
    { lat: 48.914478, lng: 20.907179 },
    { lat: 48.914418, lng: 20.907169 },
    { lat: 48.914203, lng: 20.907137 },
    { lat: 48.913666, lng: 20.90816 },
    { lat: 48.913584, lng: 20.910994 },
    { lat: 48.913027, lng: 20.911612 },
    { lat: 48.912186, lng: 20.912538 },
    { lat: 48.909312, lng: 20.914596 },
    { lat: 48.909098, lng: 20.91452 },
    { lat: 48.908982, lng: 20.914486 },
    { lat: 48.908978, lng: 20.91473 },
    { lat: 48.909183, lng: 20.91511 },
    { lat: 48.909444, lng: 20.91555 },
    { lat: 48.909521, lng: 20.916099 },
    { lat: 48.909411, lng: 20.916646 },
    { lat: 48.909494, lng: 20.917515 },
    { lat: 48.909495, lng: 20.917661 },
    { lat: 48.90948, lng: 20.917839 },
    { lat: 48.909423, lng: 20.917885 },
    { lat: 48.90935, lng: 20.91788 },
    { lat: 48.909182, lng: 20.918017 },
    { lat: 48.908897, lng: 20.918195 },
    { lat: 48.908582, lng: 20.918318 },
    { lat: 48.908391, lng: 20.918284 },
    { lat: 48.90807, lng: 20.918515 },
    { lat: 48.907943, lng: 20.918712 },
    { lat: 48.90785, lng: 20.918905 },
    { lat: 48.907672, lng: 20.919014 },
    { lat: 48.907652, lng: 20.919156 },
    { lat: 48.907533, lng: 20.919277 },
    { lat: 48.907404, lng: 20.919449 },
    { lat: 48.907235, lng: 20.919724 },
    { lat: 48.907101, lng: 20.919817 },
    { lat: 48.907075, lng: 20.919836 },
    { lat: 48.90706, lng: 20.919835 },
    { lat: 48.907023, lng: 20.91996 },
    { lat: 48.907059, lng: 20.920025 },
    { lat: 48.907098, lng: 20.920149 },
    { lat: 48.907079, lng: 20.920247 },
    { lat: 48.90709, lng: 20.920318 },
    { lat: 48.907141, lng: 20.920451 },
    { lat: 48.907149, lng: 20.920531 },
    { lat: 48.907145, lng: 20.920654 },
    { lat: 48.907126, lng: 20.9208 },
    { lat: 48.907114, lng: 20.920938 },
    { lat: 48.907104, lng: 20.921068 },
    { lat: 48.907095, lng: 20.921068 },
    { lat: 48.907046, lng: 20.921061 },
    { lat: 48.90701, lng: 20.921049 },
    { lat: 48.907006, lng: 20.921063 },
    { lat: 48.906729, lng: 20.920913 },
    { lat: 48.906464, lng: 20.920903 },
    { lat: 48.906044, lng: 20.920887 },
    { lat: 48.905741, lng: 20.92085 },
    { lat: 48.905477, lng: 20.920734 },
    { lat: 48.90512, lng: 20.920746 },
    { lat: 48.904789, lng: 20.920759 },
    { lat: 48.904597, lng: 20.920693 },
    { lat: 48.904545, lng: 20.920649 },
    { lat: 48.904543, lng: 20.920668 },
    { lat: 48.904545, lng: 20.920716 },
    { lat: 48.904309, lng: 20.920502 },
    { lat: 48.904306, lng: 20.920377 },
    { lat: 48.904257, lng: 20.920038 },
    { lat: 48.904247, lng: 20.919733 },
    { lat: 48.904184, lng: 20.919519 },
    { lat: 48.904049, lng: 20.919408 },
    { lat: 48.903935, lng: 20.919235 },
    { lat: 48.903633, lng: 20.918889 },
    { lat: 48.90352, lng: 20.918544 },
    { lat: 48.903142, lng: 20.917679 },
    { lat: 48.903078, lng: 20.917596 },
    { lat: 48.90297, lng: 20.917662 },
    { lat: 48.902781, lng: 20.917471 },
    { lat: 48.902622, lng: 20.917376 },
    { lat: 48.90243, lng: 20.917278 },
    { lat: 48.902281, lng: 20.917041 },
    { lat: 48.902021, lng: 20.916802 },
    { lat: 48.902267, lng: 20.916558 },
    { lat: 48.901635, lng: 20.915846 },
    { lat: 48.900987, lng: 20.915119 },
    { lat: 48.900502, lng: 20.914803 },
    { lat: 48.899842, lng: 20.914374 },
    { lat: 48.899728, lng: 20.913967 },
    { lat: 48.899676, lng: 20.913728 },
    { lat: 48.899612, lng: 20.913592 },
    { lat: 48.899471, lng: 20.91322 },
    { lat: 48.899357, lng: 20.912866 },
    { lat: 48.899221, lng: 20.912578 },
    { lat: 48.899078, lng: 20.912052 },
    { lat: 48.89901, lng: 20.911697 },
    { lat: 48.899032, lng: 20.91168 },
    { lat: 48.898971, lng: 20.911374 },
    { lat: 48.898937, lng: 20.911095 },
    { lat: 48.898931, lng: 20.910932 },
    { lat: 48.898897, lng: 20.910695 },
    { lat: 48.898848, lng: 20.910551 },
    { lat: 48.898674, lng: 20.910313 },
    { lat: 48.898516, lng: 20.910221 },
    { lat: 48.898315, lng: 20.91015 },
    { lat: 48.898191, lng: 20.910007 },
    { lat: 48.898076, lng: 20.909698 },
    { lat: 48.89797, lng: 20.909526 },
    { lat: 48.897814, lng: 20.909389 },
    { lat: 48.897782, lng: 20.909352 },
    { lat: 48.897648, lng: 20.909285 },
    { lat: 48.897575, lng: 20.909255 },
    { lat: 48.897328, lng: 20.909301 },
    { lat: 48.897176, lng: 20.909296 },
    { lat: 48.896944, lng: 20.909127 },
    { lat: 48.896838, lng: 20.909045 },
    { lat: 48.896708, lng: 20.90895 },
    { lat: 48.896488, lng: 20.908917 },
    { lat: 48.896277, lng: 20.908829 },
    { lat: 48.89601, lng: 20.908759 },
    { lat: 48.895827, lng: 20.908733 },
    { lat: 48.895647, lng: 20.908641 },
    { lat: 48.895394, lng: 20.908511 },
    { lat: 48.895115, lng: 20.908318 },
    { lat: 48.894801, lng: 20.908085 },
    { lat: 48.894613, lng: 20.908159 },
    { lat: 48.894477, lng: 20.908119 },
    { lat: 48.894397, lng: 20.908046 },
    { lat: 48.894315, lng: 20.907822 },
    { lat: 48.8942, lng: 20.907654 },
    { lat: 48.893974, lng: 20.907676 },
    { lat: 48.893847, lng: 20.907674 },
    { lat: 48.893746, lng: 20.907621 },
    { lat: 48.893669, lng: 20.907442 },
    { lat: 48.8936, lng: 20.907172 },
    { lat: 48.893458, lng: 20.907032 },
    { lat: 48.893298, lng: 20.906974 },
    { lat: 48.893213, lng: 20.906859 },
    { lat: 48.893098, lng: 20.906612 },
    { lat: 48.892963, lng: 20.90633 },
    { lat: 48.89293, lng: 20.906307 },
    { lat: 48.892934, lng: 20.906289 },
    { lat: 48.892801, lng: 20.906149 },
    { lat: 48.892356, lng: 20.906067 },
    { lat: 48.8905592, lng: 20.9094954 },
    { lat: 48.8900822, lng: 20.9131861 },
    { lat: 48.8899358, lng: 20.9143197 },
    { lat: 48.8898461, lng: 20.9150134 },
    { lat: 48.8893852, lng: 20.9180658 },
    { lat: 48.8890356, lng: 20.9235539 },
    { lat: 48.8888995, lng: 20.9263847 },
    { lat: 48.8885619, lng: 20.9334054 },
    { lat: 48.8883093, lng: 20.9360323 },
    { lat: 48.8885319, lng: 20.9367004 },
    { lat: 48.8886287, lng: 20.9373711 },
    { lat: 48.8886467, lng: 20.937844 },
    { lat: 48.8885698, lng: 20.938498 },
    { lat: 48.8884711, lng: 20.9388797 },
    { lat: 48.8882936, lng: 20.9421486 },
    { lat: 48.8884642, lng: 20.9429394 },
    { lat: 48.8886089, lng: 20.9439011 },
    { lat: 48.8887289, lng: 20.9453287 },
    { lat: 48.8886801, lng: 20.9457884 },
    { lat: 48.8885147, lng: 20.9463273 },
    { lat: 48.8881416, lng: 20.9485986 },
    { lat: 48.8878855, lng: 20.9494602 },
    { lat: 48.8879764, lng: 20.9500891 },
    { lat: 48.888104, lng: 20.9535322 },
    { lat: 48.8884761, lng: 20.9543645 },
    { lat: 48.8885541, lng: 20.9546089 },
    { lat: 48.8885634, lng: 20.9548514 },
    { lat: 48.887756, lng: 20.9565958 },
    { lat: 48.8878342, lng: 20.956917 },
    { lat: 48.8878658, lng: 20.9569182 },
    { lat: 48.8878608, lng: 20.9569715 },
    { lat: 48.8879669, lng: 20.9570832 },
    { lat: 48.8884923, lng: 20.957584 },
    { lat: 48.8891071, lng: 20.9580615 },
    { lat: 48.8902461, lng: 20.9586276 },
    { lat: 48.8907672, lng: 20.959035 },
    { lat: 48.8923986, lng: 20.9615142 },
    { lat: 48.8929859, lng: 20.9629933 },
    { lat: 48.8931935, lng: 20.9636481 },
    { lat: 48.8929088, lng: 20.9647011 },
    { lat: 48.8936022, lng: 20.9646029 },
    { lat: 48.8936617, lng: 20.9654039 },
    { lat: 48.8935808, lng: 20.9678753 },
    { lat: 48.8940792, lng: 20.9680404 },
    { lat: 48.8946225, lng: 20.9684348 },
    { lat: 48.8947557, lng: 20.9686875 },
    { lat: 48.895648, lng: 20.9691486 },
    { lat: 48.8961487, lng: 20.969688 },
    { lat: 48.8962447, lng: 20.9700355 },
    { lat: 48.8964667, lng: 20.9702428 },
    { lat: 48.8966045, lng: 20.9702241 },
    { lat: 48.8966386, lng: 20.9704046 },
    { lat: 48.8973741, lng: 20.9709976 },
    { lat: 48.8975041, lng: 20.9712824 },
    { lat: 48.8976584, lng: 20.9713533 },
    { lat: 48.8979834, lng: 20.9719916 },
    { lat: 48.8991442, lng: 20.9735219 },
    { lat: 48.899767, lng: 20.9747203 },
    { lat: 48.8999266, lng: 20.9755635 },
    { lat: 48.9000139, lng: 20.9763599 },
    { lat: 48.8999225, lng: 20.9780537 },
    { lat: 48.8998561, lng: 20.9785903 },
    { lat: 48.8999118, lng: 20.9788462 },
    { lat: 48.8998975, lng: 20.9790912 },
    { lat: 48.8999861, lng: 20.9792512 },
    { lat: 48.9002153, lng: 20.9793793 },
    { lat: 48.901976, lng: 20.9798287 },
    { lat: 48.9024453, lng: 20.9802029 },
    { lat: 48.9026701, lng: 20.9802612 },
    { lat: 48.9030884, lng: 20.9806568 },
    { lat: 48.903313, lng: 20.9807069 },
    { lat: 48.9035771, lng: 20.9806628 },
    { lat: 48.9039478, lng: 20.9803708 },
    { lat: 48.9039613, lng: 20.9806642 },
    { lat: 48.9041651, lng: 20.9803725 },
    { lat: 48.9042829, lng: 20.9802551 },
    { lat: 48.904688, lng: 20.9803591 },
    { lat: 48.904885, lng: 20.9805601 },
    { lat: 48.9050954, lng: 20.9806611 },
    { lat: 48.9051987, lng: 20.9808246 },
    { lat: 48.905855, lng: 20.9813348 },
    { lat: 48.9059823, lng: 20.9816095 },
    { lat: 48.9058402, lng: 20.9819416 },
    { lat: 48.9057307, lng: 20.9832558 },
    { lat: 48.9057529, lng: 20.9834842 },
    { lat: 48.9058964, lng: 20.9839126 },
    { lat: 48.9065704, lng: 20.9842806 },
    { lat: 48.9067297, lng: 20.9844988 },
    { lat: 48.9067994, lng: 20.9847364 },
    { lat: 48.9068022, lng: 20.9849577 },
    { lat: 48.9071546, lng: 20.9849298 },
    { lat: 48.9072734, lng: 20.9849772 },
    { lat: 48.9082925, lng: 20.9858448 },
    { lat: 48.9088901, lng: 20.98598 },
    { lat: 48.9092719, lng: 20.9862207 },
    { lat: 48.9094833, lng: 20.9864579 },
    { lat: 48.9100403, lng: 20.9867883 },
    { lat: 48.9103811, lng: 20.9868525 },
    { lat: 48.9107218, lng: 20.9870151 },
    { lat: 48.9110525, lng: 20.9873785 },
    { lat: 48.9115056, lng: 20.9876728 },
    { lat: 48.9120657, lng: 20.9882409 },
    { lat: 48.9126126, lng: 20.9886745 },
    { lat: 48.9128057, lng: 20.9888275 },
    { lat: 48.9129816, lng: 20.9890779 },
    { lat: 48.9134491, lng: 20.9895003 },
    { lat: 48.9138105, lng: 20.9900492 },
    { lat: 48.9142758, lng: 20.9905416 },
    { lat: 48.9153166, lng: 20.991411 },
    { lat: 48.9158266, lng: 20.9917547 },
    { lat: 48.9162371, lng: 20.9921608 },
    { lat: 48.9167272, lng: 20.9928238 },
    { lat: 48.9168718, lng: 20.9931721 },
    { lat: 48.9174933, lng: 20.99393 },
    { lat: 48.9178732, lng: 20.9950087 },
    { lat: 48.9190561, lng: 20.9948035 },
    { lat: 48.9197297, lng: 20.9943393 },
    { lat: 48.9211211, lng: 20.9928892 },
    { lat: 48.9214471, lng: 20.9923276 },
    { lat: 48.9218972, lng: 20.9917783 },
    { lat: 48.923149, lng: 20.9912764 },
    { lat: 48.9241247, lng: 20.9909699 },
    { lat: 48.92498, lng: 20.9912387 },
    { lat: 48.925394, lng: 20.9915039 },
    { lat: 48.9268869, lng: 20.995166 },
    { lat: 48.9279234, lng: 20.9984356 },
    { lat: 48.9279819, lng: 20.9994584 },
    { lat: 48.927854, lng: 21.003636 },
    { lat: 48.928111, lng: 21.003125 },
    { lat: 48.929484, lng: 21.000261 },
    { lat: 48.930781, lng: 20.999089 },
    { lat: 48.932406, lng: 20.996336 },
    { lat: 48.933531, lng: 20.995067 },
    { lat: 48.93355, lng: 20.994572 },
    { lat: 48.933728, lng: 20.993817 },
    { lat: 48.933717, lng: 20.993228 },
    { lat: 48.933882, lng: 20.992963 },
    { lat: 48.934498, lng: 20.992198 },
    { lat: 48.934953, lng: 20.991384 },
    { lat: 48.935267, lng: 20.991156 },
    { lat: 48.936031, lng: 20.990168 },
    { lat: 48.937862, lng: 20.989858 },
    { lat: 48.938164, lng: 20.990485 },
    { lat: 48.938505, lng: 20.990572 },
    { lat: 48.93869, lng: 20.990122 },
    { lat: 48.939093, lng: 20.989785 },
    { lat: 48.939298, lng: 20.990491 },
    { lat: 48.940162, lng: 20.99055 },
    { lat: 48.94048, lng: 20.989592 },
    { lat: 48.941385, lng: 20.989513 },
    { lat: 48.942529, lng: 20.989072 },
    { lat: 48.942769, lng: 20.989064 },
    { lat: 48.944316, lng: 20.989018 },
    { lat: 48.944554, lng: 20.989029 },
    { lat: 48.944625, lng: 20.989033 },
    { lat: 48.945167, lng: 20.989059 },
    { lat: 48.945238, lng: 20.989062 },
    { lat: 48.945382, lng: 20.989069 },
    { lat: 48.945485, lng: 20.989074 },
    { lat: 48.945616, lng: 20.989081 },
    { lat: 48.946666, lng: 20.989452 },
    { lat: 48.946956, lng: 20.989426 },
    { lat: 48.94699, lng: 20.989423 },
    { lat: 48.947275, lng: 20.989397 },
    { lat: 48.948136, lng: 20.990253 },
    { lat: 48.948347, lng: 20.990321 },
    { lat: 48.948415, lng: 20.990301 },
    { lat: 48.948632, lng: 20.990361 },
    { lat: 48.948691, lng: 20.990415 },
    { lat: 48.94873, lng: 20.990451 },
    { lat: 48.948914, lng: 20.990619 },
    { lat: 48.948945, lng: 20.990647 },
    { lat: 48.949083, lng: 20.990774 },
    { lat: 48.949492, lng: 20.990835 },
    { lat: 48.94952, lng: 20.990821 },
    { lat: 48.949704, lng: 20.990728 },
    { lat: 48.949786, lng: 20.990618 },
    { lat: 48.950842, lng: 20.989207 },
    { lat: 48.951253, lng: 20.989083 },
    { lat: 48.951419, lng: 20.988897 },
    { lat: 48.951464, lng: 20.988846 },
    { lat: 48.952547, lng: 20.987632 },
    { lat: 48.953352, lng: 20.987106 },
    { lat: 48.953383, lng: 20.987086 },
    { lat: 48.953514, lng: 20.987 },
    { lat: 48.953669, lng: 20.986976 },
    { lat: 48.953701, lng: 20.986971 },
    { lat: 48.954402, lng: 20.986862 },
    { lat: 48.954438, lng: 20.986856 },
    { lat: 48.954614, lng: 20.986872 },
    { lat: 48.95464, lng: 20.986874 },
    { lat: 48.954939, lng: 20.9869 },
    { lat: 48.95556, lng: 20.986954 },
    { lat: 48.955691, lng: 20.986965 },
    { lat: 48.955729, lng: 20.98695 },
    { lat: 48.955928, lng: 20.986867 },
    { lat: 48.956107, lng: 20.986792 },
    { lat: 48.956148, lng: 20.986775 },
    { lat: 48.95656, lng: 20.986604 },
    { lat: 48.956588, lng: 20.986592 },
    { lat: 48.956993, lng: 20.986423 },
    { lat: 48.957013, lng: 20.986415 },
    { lat: 48.957163, lng: 20.986353 },
    { lat: 48.957497, lng: 20.986224 },
    { lat: 48.957518, lng: 20.986216 },
    { lat: 48.957639, lng: 20.98617 },
    { lat: 48.957658, lng: 20.986162 },
    { lat: 48.958929, lng: 20.985674 },
    { lat: 48.959052, lng: 20.985604 },
    { lat: 48.959187, lng: 20.985472 },
    { lat: 48.95919, lng: 20.985465 },
    { lat: 48.959305, lng: 20.985253 },
    { lat: 48.959313, lng: 20.985236 },
    { lat: 48.959319, lng: 20.98522 },
    { lat: 48.959397, lng: 20.985026 },
    { lat: 48.959405, lng: 20.984991 },
    { lat: 48.959419, lng: 20.984932 },
    { lat: 48.959482, lng: 20.984652 },
    { lat: 48.959503, lng: 20.984328 },
    { lat: 48.959454, lng: 20.983925 },
    { lat: 48.959451, lng: 20.9839 },
    { lat: 48.959434, lng: 20.983744 },
    { lat: 48.959418, lng: 20.983613 },
    { lat: 48.959367, lng: 20.983183 },
    { lat: 48.959359, lng: 20.983115 },
    { lat: 48.959324, lng: 20.982823 },
    { lat: 48.959305, lng: 20.982654 },
    { lat: 48.959296, lng: 20.982583 },
    { lat: 48.959292, lng: 20.98199 },
    { lat: 48.959291, lng: 20.981964 },
    { lat: 48.95929, lng: 20.981915 },
    { lat: 48.95929, lng: 20.981892 },
    { lat: 48.959293, lng: 20.980403 },
    { lat: 48.959292, lng: 20.980309 },
    { lat: 48.959292, lng: 20.980187 },
    { lat: 48.959293, lng: 20.979961 },
    { lat: 48.959293, lng: 20.979771 },
    { lat: 48.959292, lng: 20.979687 },
    { lat: 48.959294, lng: 20.979328 },
    { lat: 48.959295, lng: 20.979285 },
    { lat: 48.959295, lng: 20.979094 },
    { lat: 48.959297, lng: 20.978856 },
    { lat: 48.959301, lng: 20.977505 },
    { lat: 48.959303, lng: 20.977151 },
    { lat: 48.959386, lng: 20.976721 },
    { lat: 48.959764, lng: 20.97585 },
    { lat: 48.959789, lng: 20.975791 },
    { lat: 48.960047, lng: 20.975198 },
    { lat: 48.960133, lng: 20.974914 },
    { lat: 48.960164, lng: 20.974517 },
    { lat: 48.960168, lng: 20.974295 },
    { lat: 48.960182, lng: 20.973564 },
    { lat: 48.960186, lng: 20.97334 },
    { lat: 48.960189, lng: 20.973217 },
    { lat: 48.960196, lng: 20.972875 },
    { lat: 48.960188, lng: 20.972771 },
    { lat: 48.960185, lng: 20.972721 },
    { lat: 48.960182, lng: 20.972676 },
    { lat: 48.960138, lng: 20.972399 },
    { lat: 48.96001, lng: 20.972008 },
    { lat: 48.959789, lng: 20.971605 },
    { lat: 48.958955, lng: 20.970473 },
    { lat: 48.958889, lng: 20.970384 },
    { lat: 48.958578, lng: 20.96996 },
    { lat: 48.958398, lng: 20.969715 },
    { lat: 48.957968, lng: 20.969092 },
    { lat: 48.957606, lng: 20.968284 },
    { lat: 48.95756, lng: 20.968152 },
    { lat: 48.957539, lng: 20.96809 },
    { lat: 48.957462, lng: 20.967865 },
    { lat: 48.957365, lng: 20.967518 },
    { lat: 48.957094, lng: 20.966339 },
    { lat: 48.957043, lng: 20.966118 },
    { lat: 48.957032, lng: 20.966069 },
    { lat: 48.95702, lng: 20.966021 },
    { lat: 48.956991, lng: 20.965895 },
    { lat: 48.95698, lng: 20.965848 },
    { lat: 48.956965, lng: 20.965778 },
    { lat: 48.956814, lng: 20.965191 },
    { lat: 48.956796, lng: 20.965123 },
    { lat: 48.956754, lng: 20.964961 },
    { lat: 48.956633, lng: 20.96463 },
    { lat: 48.956611, lng: 20.964568 },
    { lat: 48.956607, lng: 20.96456 },
    { lat: 48.956501, lng: 20.964311 },
    { lat: 48.956451, lng: 20.964193 },
    { lat: 48.956447, lng: 20.964188 },
    { lat: 48.956423, lng: 20.964139 },
    { lat: 48.956392, lng: 20.964076 },
    { lat: 48.956367, lng: 20.964029 },
    { lat: 48.956349, lng: 20.963992 },
    { lat: 48.956182, lng: 20.963663 },
    { lat: 48.956167, lng: 20.963634 },
    { lat: 48.956148, lng: 20.963596 },
    { lat: 48.956067, lng: 20.963509 },
    { lat: 48.956032, lng: 20.96347 },
    { lat: 48.955968, lng: 20.9634 },
    { lat: 48.955707, lng: 20.963115 },
    { lat: 48.95566, lng: 20.963064 },
    { lat: 48.955613, lng: 20.963013 },
    { lat: 48.955595, lng: 20.962998 },
    { lat: 48.955344, lng: 20.96279 },
    { lat: 48.95516, lng: 20.962728 },
    { lat: 48.955051, lng: 20.962795 },
    { lat: 48.95487, lng: 20.962488 },
    { lat: 48.954855, lng: 20.962469 },
    { lat: 48.954835, lng: 20.962445 },
    { lat: 48.954817, lng: 20.962425 },
    { lat: 48.954865, lng: 20.962075 },
    { lat: 48.95486, lng: 20.962059 },
    { lat: 48.954776, lng: 20.961812 },
    { lat: 48.954749, lng: 20.96173 },
    { lat: 48.954735, lng: 20.96169 },
    { lat: 48.954695, lng: 20.961572 },
    { lat: 48.954669, lng: 20.961506 },
    { lat: 48.954623, lng: 20.961441 },
    { lat: 48.954498, lng: 20.961261 },
    { lat: 48.954476, lng: 20.961217 },
    { lat: 48.954351, lng: 20.960959 },
    { lat: 48.954224, lng: 20.960603 },
    { lat: 48.954155, lng: 20.959996 },
    { lat: 48.954157, lng: 20.959951 },
    { lat: 48.954166, lng: 20.959562 },
    { lat: 48.954347, lng: 20.959174 },
    { lat: 48.954402, lng: 20.959058 },
    { lat: 48.954726, lng: 20.95867 },
    { lat: 48.955275, lng: 20.958229 },
    { lat: 48.955406, lng: 20.958073 },
    { lat: 48.955494, lng: 20.957941 },
    { lat: 48.955569, lng: 20.957772 },
    { lat: 48.955592, lng: 20.957637 },
    { lat: 48.955623, lng: 20.95722 },
    { lat: 48.955509, lng: 20.957011 },
    { lat: 48.955481, lng: 20.956587 },
    { lat: 48.955532, lng: 20.956283 },
    { lat: 48.955496, lng: 20.956001 },
    { lat: 48.955392, lng: 20.955718 },
    { lat: 48.955406, lng: 20.955441 },
    { lat: 48.955419, lng: 20.955097 },
    { lat: 48.955359, lng: 20.954728 },
    { lat: 48.955341, lng: 20.95467 },
    { lat: 48.955267, lng: 20.954738 },
    { lat: 48.955011, lng: 20.954801 },
    { lat: 48.954776, lng: 20.954915 },
    { lat: 48.954602, lng: 20.955034 },
    { lat: 48.954498, lng: 20.954922 },
    { lat: 48.954316, lng: 20.954775 },
    { lat: 48.95423, lng: 20.954707 },
    { lat: 48.954049, lng: 20.954645 },
    { lat: 48.953941, lng: 20.954607 },
    { lat: 48.953682, lng: 20.954473 },
    { lat: 48.953506, lng: 20.954334 },
    { lat: 48.953325, lng: 20.954051 },
    { lat: 48.953244, lng: 20.953931 },
    { lat: 48.953207, lng: 20.953877 },
    { lat: 48.953051, lng: 20.953763 },
    { lat: 48.952819, lng: 20.953593 },
    { lat: 48.952668, lng: 20.953586 },
    { lat: 48.952176, lng: 20.953597 },
    { lat: 48.951943, lng: 20.953653 },
    { lat: 48.951921, lng: 20.953659 },
    { lat: 48.951683, lng: 20.953495 },
    { lat: 48.951296, lng: 20.953018 },
    { lat: 48.951229, lng: 20.952919 },
    { lat: 48.951044, lng: 20.952647 },
    { lat: 48.951021, lng: 20.952529 },
    { lat: 48.950988, lng: 20.952372 },
    { lat: 48.950998, lng: 20.952255 },
    { lat: 48.950999, lng: 20.952246 },
    { lat: 48.951, lng: 20.951883 },
    { lat: 48.950873, lng: 20.951642 },
    { lat: 48.950679, lng: 20.951526 },
    { lat: 48.950279, lng: 20.951364 },
    { lat: 48.949815, lng: 20.951408 },
    { lat: 48.949613, lng: 20.9514 },
    { lat: 48.949154, lng: 20.951178 },
    { lat: 48.949144, lng: 20.951173 },
    { lat: 48.949101, lng: 20.951137 },
    { lat: 48.948935, lng: 20.950991 },
    { lat: 48.948523, lng: 20.95063 },
    { lat: 48.948343, lng: 20.950546 },
    { lat: 48.94823, lng: 20.950493 },
    { lat: 48.947934, lng: 20.950415 },
    { lat: 48.947851, lng: 20.950393 },
    { lat: 48.947515, lng: 20.950082 },
    { lat: 48.947396, lng: 20.949972 },
    { lat: 48.947419, lng: 20.949921 },
    { lat: 48.947448, lng: 20.949857 },
    { lat: 48.948961, lng: 20.947898 },
    { lat: 48.94985, lng: 20.946588 },
    { lat: 48.950263, lng: 20.946003 },
    { lat: 48.950385, lng: 20.945656 },
    { lat: 48.950631, lng: 20.945079 },
    { lat: 48.950644, lng: 20.94507 },
    { lat: 48.950896, lng: 20.943667 },
    { lat: 48.950992, lng: 20.943036 },
    { lat: 48.951142, lng: 20.941381 },
    { lat: 48.951068, lng: 20.939952 },
    { lat: 48.951331, lng: 20.939527 },
  ],
  Margecany: [
    { lat: 48.881932, lng: 21.050176 },
    { lat: 48.88223, lng: 21.050199 },
    { lat: 48.882334, lng: 21.050491 },
    { lat: 48.8824095, lng: 21.0507301 },
    { lat: 48.882495, lng: 21.050986 },
    { lat: 48.88251, lng: 21.051159 },
    { lat: 48.882526, lng: 21.051339 },
    { lat: 48.882529, lng: 21.051387 },
    { lat: 48.88253, lng: 21.051717 },
    { lat: 48.882403, lng: 21.052232 },
    { lat: 48.882399, lng: 21.052591 },
    { lat: 48.882391, lng: 21.052905 },
    { lat: 48.882369, lng: 21.053092 },
    { lat: 48.882324, lng: 21.053246 },
    { lat: 48.882205, lng: 21.053501 },
    { lat: 48.881987, lng: 21.053632 },
    { lat: 48.881774, lng: 21.053975 },
    { lat: 48.881598, lng: 21.054362 },
    { lat: 48.881414, lng: 21.054705 },
    { lat: 48.881226, lng: 21.054887 },
    { lat: 48.881095, lng: 21.055095 },
    { lat: 48.881021, lng: 21.055333 },
    { lat: 48.88088, lng: 21.055578 },
    { lat: 48.880548, lng: 21.056339 },
    { lat: 48.88039, lng: 21.056609 },
    { lat: 48.880247, lng: 21.05693 },
    { lat: 48.880109, lng: 21.05715 },
    { lat: 48.879807, lng: 21.05735 },
    { lat: 48.879482, lng: 21.057821 },
    { lat: 48.879186, lng: 21.058144 },
    { lat: 48.879082, lng: 21.058313 },
    { lat: 48.878924, lng: 21.05853 },
    { lat: 48.878789, lng: 21.058598 },
    { lat: 48.878668, lng: 21.058987 },
    { lat: 48.878474, lng: 21.05909 },
    { lat: 48.877837, lng: 21.059759 },
    { lat: 48.877603, lng: 21.059796 },
    { lat: 48.877317, lng: 21.060061 },
    { lat: 48.877183, lng: 21.06023 },
    { lat: 48.876829, lng: 21.060493 },
    { lat: 48.87669, lng: 21.060536 },
    { lat: 48.876605, lng: 21.060598 },
    { lat: 48.876247, lng: 21.060845 },
    { lat: 48.875648, lng: 21.060923 },
    { lat: 48.875507, lng: 21.061145 },
    { lat: 48.875361, lng: 21.061276 },
    { lat: 48.875236, lng: 21.061463 },
    { lat: 48.875007, lng: 21.061595 },
    { lat: 48.874929, lng: 21.061719 },
    { lat: 48.874859, lng: 21.061983 },
    { lat: 48.874861, lng: 21.062306 },
    { lat: 48.87485, lng: 21.062664 },
    { lat: 48.874811, lng: 21.062892 },
    { lat: 48.874796, lng: 21.063088 },
    { lat: 48.87486, lng: 21.063316 },
    { lat: 48.874904, lng: 21.063368 },
    { lat: 48.87501, lng: 21.063494 },
    { lat: 48.87507, lng: 21.063612 },
    { lat: 48.875324, lng: 21.063784 },
    { lat: 48.875572, lng: 21.063956 },
    { lat: 48.87695, lng: 21.064936 },
    { lat: 48.877622, lng: 21.065389 },
    { lat: 48.87794, lng: 21.06559 },
    { lat: 48.878244, lng: 21.065802 },
    { lat: 48.878471, lng: 21.065697 },
    { lat: 48.878589, lng: 21.065675 },
    { lat: 48.878771, lng: 21.065655 },
    { lat: 48.878893, lng: 21.065536 },
    { lat: 48.87915, lng: 21.065592 },
    { lat: 48.879296, lng: 21.065573 },
    { lat: 48.879556, lng: 21.065378 },
    { lat: 48.87976, lng: 21.06535 },
    { lat: 48.879984, lng: 21.065449 },
    { lat: 48.880061, lng: 21.065438 },
    { lat: 48.880122, lng: 21.065415 },
    { lat: 48.880218, lng: 21.065368 },
    { lat: 48.88036, lng: 21.065296 },
    { lat: 48.880695, lng: 21.065191 },
    { lat: 48.881296, lng: 21.065002 },
    { lat: 48.881447, lng: 21.064984 },
    { lat: 48.881552, lng: 21.065015 },
    { lat: 48.881705, lng: 21.065026 },
    { lat: 48.88232, lng: 21.065028 },
    { lat: 48.882472, lng: 21.065065 },
    { lat: 48.882622, lng: 21.065148 },
    { lat: 48.882762, lng: 21.065294 },
    { lat: 48.882884, lng: 21.065428 },
    { lat: 48.883269, lng: 21.065565 },
    { lat: 48.883455, lng: 21.065566 },
    { lat: 48.883976, lng: 21.065518 },
    { lat: 48.884384, lng: 21.065499 },
    { lat: 48.884503, lng: 21.065447 },
    { lat: 48.884986, lng: 21.0652 },
    { lat: 48.885269, lng: 21.064959 },
    { lat: 48.885462, lng: 21.064829 },
    { lat: 48.885599, lng: 21.064766 },
    { lat: 48.88579, lng: 21.064602 },
    { lat: 48.885946, lng: 21.064532 },
    { lat: 48.886082, lng: 21.064481 },
    { lat: 48.886297, lng: 21.064408 },
    { lat: 48.886671, lng: 21.064193 },
    { lat: 48.88688, lng: 21.064114 },
    { lat: 48.887065, lng: 21.064136 },
    { lat: 48.887218, lng: 21.06424 },
    { lat: 48.887432, lng: 21.064396 },
    { lat: 48.887608, lng: 21.064486 },
    { lat: 48.887854, lng: 21.064545 },
    { lat: 48.888272, lng: 21.064718 },
    { lat: 48.888646, lng: 21.064815 },
    { lat: 48.889091, lng: 21.064946 },
    { lat: 48.889277, lng: 21.064945 },
    { lat: 48.889502, lng: 21.064823 },
    { lat: 48.889882, lng: 21.064484 },
    { lat: 48.890079, lng: 21.064396 },
    { lat: 48.8905, lng: 21.063955 },
    { lat: 48.890654, lng: 21.063679 },
    { lat: 48.890922, lng: 21.06335 },
    { lat: 48.891055, lng: 21.063251 },
    { lat: 48.891321, lng: 21.063177 },
    { lat: 48.891604, lng: 21.063011 },
    { lat: 48.891608, lng: 21.063064 },
    { lat: 48.891924, lng: 21.062847 },
    { lat: 48.892112, lng: 21.062632 },
    { lat: 48.89254, lng: 21.062318 },
    { lat: 48.892801, lng: 21.062068 },
    { lat: 48.892911, lng: 21.061956 },
    { lat: 48.892892, lng: 21.061908 },
    { lat: 48.893208, lng: 21.0617 },
    { lat: 48.893586, lng: 21.061426 },
    { lat: 48.893924, lng: 21.061154 },
    { lat: 48.894061, lng: 21.061085 },
    { lat: 48.894298, lng: 21.060934 },
    { lat: 48.894463, lng: 21.060807 },
    { lat: 48.894881, lng: 21.060342 },
    { lat: 48.895048, lng: 21.060266 },
    { lat: 48.895163, lng: 21.06027 },
    { lat: 48.89515, lng: 21.060332 },
    { lat: 48.895584, lng: 21.060301 },
    { lat: 48.895768, lng: 21.060362 },
    { lat: 48.895887, lng: 21.060352 },
    { lat: 48.896101, lng: 21.060072 },
    { lat: 48.896121, lng: 21.06004 },
    { lat: 48.896164, lng: 21.059961 },
    { lat: 48.896258, lng: 21.059883 },
    { lat: 48.89662, lng: 21.059401 },
    { lat: 48.896826, lng: 21.059242 },
    { lat: 48.897081, lng: 21.05901 },
    { lat: 48.897294, lng: 21.058728 },
    { lat: 48.897447, lng: 21.058561 },
    { lat: 48.897707, lng: 21.058134 },
    { lat: 48.897883, lng: 21.057949 },
    { lat: 48.898063, lng: 21.057796 },
    { lat: 48.898426, lng: 21.057626 },
    { lat: 48.898702, lng: 21.05754 },
    { lat: 48.898986, lng: 21.057508 },
    { lat: 48.899205, lng: 21.057537 },
    { lat: 48.899582, lng: 21.057558 },
    { lat: 48.900114, lng: 21.057448 },
    { lat: 48.900426, lng: 21.057479 },
    { lat: 48.900569, lng: 21.057389 },
    { lat: 48.900859, lng: 21.057017 },
    { lat: 48.901264, lng: 21.056752 },
    { lat: 48.901411, lng: 21.056597 },
    { lat: 48.901531, lng: 21.05635 },
    { lat: 48.90175, lng: 21.056177 },
    { lat: 48.90191, lng: 21.056098 },
    { lat: 48.902181, lng: 21.05584 },
    { lat: 48.902421, lng: 21.05576 },
    { lat: 48.902418, lng: 21.055688 },
    { lat: 48.90254, lng: 21.055597 },
    { lat: 48.902954, lng: 21.055531 },
    { lat: 48.903156, lng: 21.055535 },
    { lat: 48.903456, lng: 21.055557 },
    { lat: 48.903756, lng: 21.055672 },
    { lat: 48.904855, lng: 21.056319 },
    { lat: 48.905686, lng: 21.058186 },
    { lat: 48.905709, lng: 21.058046 },
    { lat: 48.905722, lng: 21.05782 },
    { lat: 48.905704, lng: 21.056985 },
    { lat: 48.905614, lng: 21.056464 },
    { lat: 48.905502, lng: 21.055939 },
    { lat: 48.905325, lng: 21.055475 },
    { lat: 48.904967, lng: 21.05448 },
    { lat: 48.904981, lng: 21.054378 },
    { lat: 48.904985, lng: 21.054344 },
    { lat: 48.904916, lng: 21.054297 },
    { lat: 48.904815, lng: 21.0539 },
    { lat: 48.904672, lng: 21.05345 },
    { lat: 48.904523, lng: 21.052451 },
    { lat: 48.904465, lng: 21.051882 },
    { lat: 48.904369, lng: 21.051295 },
    { lat: 48.9043, lng: 21.051106 },
    { lat: 48.904254, lng: 21.050846 },
    { lat: 48.904198, lng: 21.050653 },
    { lat: 48.904065, lng: 21.050152 },
    { lat: 48.903951, lng: 21.049581 },
    { lat: 48.903923, lng: 21.049249 },
    { lat: 48.903926, lng: 21.048713 },
    { lat: 48.903952, lng: 21.048307 },
    { lat: 48.904014, lng: 21.047594 },
    { lat: 48.904093, lng: 21.047097 },
    { lat: 48.904139, lng: 21.04691 },
    { lat: 48.904249, lng: 21.046606 },
    { lat: 48.904264, lng: 21.046502 },
    { lat: 48.904257, lng: 21.0464 },
    { lat: 48.904274, lng: 21.04602 },
    { lat: 48.904391, lng: 21.045606 },
    { lat: 48.904389, lng: 21.045418 },
    { lat: 48.904398, lng: 21.04516 },
    { lat: 48.904401, lng: 21.045089 },
    { lat: 48.904442, lng: 21.04446 },
    { lat: 48.904459, lng: 21.044257 },
    { lat: 48.90445, lng: 21.044124 },
    { lat: 48.904585, lng: 21.043524 },
    { lat: 48.904839, lng: 21.043675 },
    { lat: 48.90485, lng: 21.04367 },
    { lat: 48.904963, lng: 21.044093 },
    { lat: 48.904979, lng: 21.044093 },
    { lat: 48.904802, lng: 21.042602 },
    { lat: 48.904792, lng: 21.042595 },
    { lat: 48.904857, lng: 21.042068 },
    { lat: 48.904724, lng: 21.041308 },
    { lat: 48.90465, lng: 21.040837 },
    { lat: 48.904593, lng: 21.040313 },
    { lat: 48.904443, lng: 21.039828 },
    { lat: 48.904307, lng: 21.039526 },
    { lat: 48.904137, lng: 21.039077 },
    { lat: 48.903797, lng: 21.03781 },
    { lat: 48.903723, lng: 21.037688 },
    { lat: 48.903597, lng: 21.037542 },
    { lat: 48.903578, lng: 21.037483 },
    { lat: 48.903091, lng: 21.035999 },
    { lat: 48.903081, lng: 21.035999 },
    { lat: 48.903121, lng: 21.035434 },
    { lat: 48.903189, lng: 21.03477 },
    { lat: 48.903197, lng: 21.034768 },
    { lat: 48.903408, lng: 21.034322 },
    { lat: 48.903299, lng: 21.033966 },
    { lat: 48.903481, lng: 21.033331 },
    { lat: 48.903748, lng: 21.033113 },
    { lat: 48.903781, lng: 21.032491 },
    { lat: 48.903798, lng: 21.031496 },
    { lat: 48.903901, lng: 21.029937 },
    { lat: 48.904503, lng: 21.028181 },
    { lat: 48.904503, lng: 21.02805 },
    { lat: 48.904497, lng: 21.027968 },
    { lat: 48.903812, lng: 21.027046 },
    { lat: 48.903905, lng: 21.025381 },
    { lat: 48.904145, lng: 21.024876 },
    { lat: 48.904183, lng: 21.024773 },
    { lat: 48.904183, lng: 21.024728 },
    { lat: 48.904196, lng: 21.02468 },
    { lat: 48.904212, lng: 21.024642 },
    { lat: 48.904233, lng: 21.024608 },
    { lat: 48.904259, lng: 21.024561 },
    { lat: 48.904332, lng: 21.024445 },
    { lat: 48.904341, lng: 21.024431 },
    { lat: 48.904399, lng: 21.024337 },
    { lat: 48.904561, lng: 21.024193 },
    { lat: 48.904676, lng: 21.024091 },
    { lat: 48.904692, lng: 21.024077 },
    { lat: 48.904893, lng: 21.023765 },
    { lat: 48.905093, lng: 21.023307 },
    { lat: 48.905474, lng: 21.022438 },
    { lat: 48.905832, lng: 21.021637 },
    { lat: 48.906172, lng: 21.021283 },
    { lat: 48.906383, lng: 21.020579 },
    { lat: 48.906556, lng: 21.019876 },
    { lat: 48.906582, lng: 21.018904 },
    { lat: 48.906531, lng: 21.017679 },
    { lat: 48.906648, lng: 21.017747 },
    { lat: 48.906992, lng: 21.017949 },
    { lat: 48.90781, lng: 21.018426 },
    { lat: 48.908869, lng: 21.019512 },
    { lat: 48.908975, lng: 21.019673 },
    { lat: 48.909974, lng: 21.018572 },
    { lat: 48.910847, lng: 21.017674 },
    { lat: 48.911383, lng: 21.017156 },
    { lat: 48.911422, lng: 21.017118 },
    { lat: 48.911768, lng: 21.016783 },
    { lat: 48.911983, lng: 21.016529 },
    { lat: 48.912531, lng: 21.015973 },
    { lat: 48.912967, lng: 21.015597 },
    { lat: 48.913749, lng: 21.01509 },
    { lat: 48.914106, lng: 21.015248 },
    { lat: 48.915002, lng: 21.015267 },
    { lat: 48.915631, lng: 21.014824 },
    { lat: 48.916869, lng: 21.014577 },
    { lat: 48.917513, lng: 21.014318 },
    { lat: 48.918678, lng: 21.013905 },
    { lat: 48.919079, lng: 21.013775 },
    { lat: 48.919461, lng: 21.013724 },
    { lat: 48.919838, lng: 21.013677 },
    { lat: 48.920079, lng: 21.013626 },
    { lat: 48.920257, lng: 21.013589 },
    { lat: 48.920838, lng: 21.013466 },
    { lat: 48.921117, lng: 21.0134 },
    { lat: 48.921508, lng: 21.013357 },
    { lat: 48.921876, lng: 21.01322 },
    { lat: 48.922295, lng: 21.013103 },
    { lat: 48.922714, lng: 21.013119 },
    { lat: 48.923802, lng: 21.013514 },
    { lat: 48.923854, lng: 21.012915 },
    { lat: 48.923881, lng: 21.012701 },
    { lat: 48.924026, lng: 21.011847 },
    { lat: 48.924118, lng: 21.01159 },
    { lat: 48.924284, lng: 21.011193 },
    { lat: 48.924937, lng: 21.010151 },
    { lat: 48.924898, lng: 21.009873 },
    { lat: 48.925253, lng: 21.009044 },
    { lat: 48.925509, lng: 21.008448 },
    { lat: 48.925769, lng: 21.007744 },
    { lat: 48.926428, lng: 21.006447 },
    { lat: 48.927366, lng: 21.004599 },
    { lat: 48.927854, lng: 21.003636 },
    { lat: 48.9279819, lng: 20.9994584 },
    { lat: 48.9279234, lng: 20.9984356 },
    { lat: 48.9268869, lng: 20.995166 },
    { lat: 48.925394, lng: 20.9915039 },
    { lat: 48.92498, lng: 20.9912387 },
    { lat: 48.9241247, lng: 20.9909699 },
    { lat: 48.923149, lng: 20.9912764 },
    { lat: 48.9218972, lng: 20.9917783 },
    { lat: 48.9214471, lng: 20.9923276 },
    { lat: 48.9211211, lng: 20.9928892 },
    { lat: 48.9197297, lng: 20.9943393 },
    { lat: 48.9190561, lng: 20.9948035 },
    { lat: 48.9178732, lng: 20.9950087 },
    { lat: 48.9174933, lng: 20.99393 },
    { lat: 48.9168718, lng: 20.9931721 },
    { lat: 48.9167272, lng: 20.9928238 },
    { lat: 48.9162371, lng: 20.9921608 },
    { lat: 48.9158266, lng: 20.9917547 },
    { lat: 48.9153166, lng: 20.991411 },
    { lat: 48.9142758, lng: 20.9905416 },
    { lat: 48.9138105, lng: 20.9900492 },
    { lat: 48.9134491, lng: 20.9895003 },
    { lat: 48.9129816, lng: 20.9890779 },
    { lat: 48.9128057, lng: 20.9888275 },
    { lat: 48.9126126, lng: 20.9886745 },
    { lat: 48.9120657, lng: 20.9882409 },
    { lat: 48.9115056, lng: 20.9876728 },
    { lat: 48.9110525, lng: 20.9873785 },
    { lat: 48.9107218, lng: 20.9870151 },
    { lat: 48.9103811, lng: 20.9868525 },
    { lat: 48.9100403, lng: 20.9867883 },
    { lat: 48.9094833, lng: 20.9864579 },
    { lat: 48.9092719, lng: 20.9862207 },
    { lat: 48.9088901, lng: 20.98598 },
    { lat: 48.9082925, lng: 20.9858448 },
    { lat: 48.9072734, lng: 20.9849772 },
    { lat: 48.9071546, lng: 20.9849298 },
    { lat: 48.9068022, lng: 20.9849577 },
    { lat: 48.9067994, lng: 20.9847364 },
    { lat: 48.9067297, lng: 20.9844988 },
    { lat: 48.9065704, lng: 20.9842806 },
    { lat: 48.9058964, lng: 20.9839126 },
    { lat: 48.9057529, lng: 20.9834842 },
    { lat: 48.9057307, lng: 20.9832558 },
    { lat: 48.9058402, lng: 20.9819416 },
    { lat: 48.9059823, lng: 20.9816095 },
    { lat: 48.905855, lng: 20.9813348 },
    { lat: 48.9051987, lng: 20.9808246 },
    { lat: 48.9050954, lng: 20.9806611 },
    { lat: 48.904885, lng: 20.9805601 },
    { lat: 48.904688, lng: 20.9803591 },
    { lat: 48.9042829, lng: 20.9802551 },
    { lat: 48.9041651, lng: 20.9803725 },
    { lat: 48.9039613, lng: 20.9806642 },
    { lat: 48.9039478, lng: 20.9803708 },
    { lat: 48.9035771, lng: 20.9806628 },
    { lat: 48.903313, lng: 20.9807069 },
    { lat: 48.9030884, lng: 20.9806568 },
    { lat: 48.9026701, lng: 20.9802612 },
    { lat: 48.9024453, lng: 20.9802029 },
    { lat: 48.901976, lng: 20.9798287 },
    { lat: 48.9002153, lng: 20.9793793 },
    { lat: 48.8999861, lng: 20.9792512 },
    { lat: 48.8998975, lng: 20.9790912 },
    { lat: 48.8999118, lng: 20.9788462 },
    { lat: 48.8998561, lng: 20.9785903 },
    { lat: 48.8999225, lng: 20.9780537 },
    { lat: 48.9000139, lng: 20.9763599 },
    { lat: 48.8999266, lng: 20.9755635 },
    { lat: 48.899767, lng: 20.9747203 },
    { lat: 48.8991442, lng: 20.9735219 },
    { lat: 48.8979834, lng: 20.9719916 },
    { lat: 48.8976584, lng: 20.9713533 },
    { lat: 48.8975041, lng: 20.9712824 },
    { lat: 48.8973741, lng: 20.9709976 },
    { lat: 48.8966386, lng: 20.9704046 },
    { lat: 48.8966045, lng: 20.9702241 },
    { lat: 48.8964667, lng: 20.9702428 },
    { lat: 48.8962447, lng: 20.9700355 },
    { lat: 48.8961487, lng: 20.969688 },
    { lat: 48.895648, lng: 20.9691486 },
    { lat: 48.8947557, lng: 20.9686875 },
    { lat: 48.8946225, lng: 20.9684348 },
    { lat: 48.8940792, lng: 20.9680404 },
    { lat: 48.8935808, lng: 20.9678753 },
    { lat: 48.8934104, lng: 20.9677582 },
    { lat: 48.8930351, lng: 20.9676149 },
    { lat: 48.8923192, lng: 20.9675434 },
    { lat: 48.8921166, lng: 20.9675782 },
    { lat: 48.892196, lng: 20.9686452 },
    { lat: 48.892152, lng: 20.9706062 },
    { lat: 48.8919793, lng: 20.97147 },
    { lat: 48.8915681, lng: 20.9729128 },
    { lat: 48.8912006, lng: 20.9745226 },
    { lat: 48.8909125, lng: 20.9753351 },
    { lat: 48.8908585, lng: 20.97564 },
    { lat: 48.8908494, lng: 20.9766168 },
    { lat: 48.8906682, lng: 20.9780778 },
    { lat: 48.89055, lng: 20.9801459 },
    { lat: 48.8906487, lng: 20.9808798 },
    { lat: 48.8902538, lng: 20.983943 },
    { lat: 48.8900117, lng: 20.9850205 },
    { lat: 48.8897707, lng: 20.9853286 },
    { lat: 48.8890904, lng: 20.9856819 },
    { lat: 48.8886892, lng: 20.9857751 },
    { lat: 48.888516, lng: 20.9861853 },
    { lat: 48.8884551, lng: 20.9868226 },
    { lat: 48.8882606, lng: 20.987679 },
    { lat: 48.8875226, lng: 20.9892891 },
    { lat: 48.8871231, lng: 20.9900016 },
    { lat: 48.8865542, lng: 20.9905638 },
    { lat: 48.8854472, lng: 20.9921494 },
    { lat: 48.8850071, lng: 20.9921439 },
    { lat: 48.8847319, lng: 20.9923379 },
    { lat: 48.8844349, lng: 20.9927775 },
    { lat: 48.8844859, lng: 20.9933694 },
    { lat: 48.8841597, lng: 20.9933073 },
    { lat: 48.8838386, lng: 20.9935037 },
    { lat: 48.8831456, lng: 20.9937492 },
    { lat: 48.8825725, lng: 20.9941493 },
    { lat: 48.882314, lng: 20.994401 },
    { lat: 48.8821075, lng: 20.9944521 },
    { lat: 48.8819278, lng: 20.9946031 },
    { lat: 48.8818279, lng: 20.9948138 },
    { lat: 48.8816317, lng: 20.995791 },
    { lat: 48.8814647, lng: 20.9959478 },
    { lat: 48.8813807, lng: 20.9962664 },
    { lat: 48.8805821, lng: 20.9974381 },
    { lat: 48.8803699, lng: 20.9975916 },
    { lat: 48.8802101, lng: 20.9979992 },
    { lat: 48.8800553, lng: 20.9982541 },
    { lat: 48.8796404, lng: 20.9976187 },
    { lat: 48.8791876, lng: 20.9974042 },
    { lat: 48.8791876, lng: 20.9973413 },
    { lat: 48.8788098, lng: 20.9972856 },
    { lat: 48.878976, lng: 20.9976756 },
    { lat: 48.8794709, lng: 20.9984215 },
    { lat: 48.8804674, lng: 21.0002191 },
    { lat: 48.8815319, lng: 21.0021396 },
    { lat: 48.8825524, lng: 21.0030542 },
    { lat: 48.8827926, lng: 21.0033367 },
    { lat: 48.8835485, lng: 21.004967 },
    { lat: 48.8838949, lng: 21.0053649 },
    { lat: 48.884837, lng: 21.0058128 },
    { lat: 48.8849736, lng: 21.0059573 },
    { lat: 48.8878192, lng: 21.0105494 },
    { lat: 48.8879388, lng: 21.0111723 },
    { lat: 48.8877491, lng: 21.0130693 },
    { lat: 48.8877313, lng: 21.0138968 },
    { lat: 48.8877523, lng: 21.0142268 },
    { lat: 48.8879417, lng: 21.0151234 },
    { lat: 48.8881834, lng: 21.0158343 },
    { lat: 48.8886903, lng: 21.0168403 },
    { lat: 48.8890795, lng: 21.0177612 },
    { lat: 48.8895315, lng: 21.0197038 },
    { lat: 48.8898651, lng: 21.0203943 },
    { lat: 48.8902366, lng: 21.0210218 },
    { lat: 48.890723, lng: 21.0215069 },
    { lat: 48.8911999, lng: 21.0222449 },
    { lat: 48.8916411, lng: 21.0234159 },
    { lat: 48.8920079, lng: 21.0250564 },
    { lat: 48.8921, lng: 21.0254 },
    { lat: 48.8923468, lng: 21.026674 },
    { lat: 48.892374, lng: 21.0270944 },
    { lat: 48.8923328, lng: 21.0285153 },
    { lat: 48.8921154, lng: 21.03033 },
    { lat: 48.891685, lng: 21.0324126 },
    { lat: 48.8912087, lng: 21.0337492 },
    { lat: 48.8904541, lng: 21.0349713 },
    { lat: 48.8903499, lng: 21.0352269 },
    { lat: 48.8900841, lng: 21.0355649 },
    { lat: 48.8897888, lng: 21.0361686 },
    { lat: 48.8895161, lng: 21.0368823 },
    { lat: 48.8882913, lng: 21.0389081 },
    { lat: 48.8882316, lng: 21.0391458 },
    { lat: 48.8881541, lng: 21.0400518 },
    { lat: 48.8881646, lng: 21.0406815 },
    { lat: 48.8880827, lng: 21.0412377 },
    { lat: 48.8877875, lng: 21.0425767 },
    { lat: 48.8874233, lng: 21.043796 },
    { lat: 48.8866654, lng: 21.045057 },
    { lat: 48.8865728, lng: 21.045154 },
    { lat: 48.8864419, lng: 21.0451739 },
    { lat: 48.885083, lng: 21.0443642 },
    { lat: 48.8845106, lng: 21.0439319 },
    { lat: 48.8840058, lng: 21.0433644 },
    { lat: 48.8835856, lng: 21.0430057 },
    { lat: 48.8832078, lng: 21.042777 },
    { lat: 48.8829642, lng: 21.0427978 },
    { lat: 48.8823735, lng: 21.0430098 },
    { lat: 48.8821667, lng: 21.0431565 },
    { lat: 48.8819553, lng: 21.0434059 },
    { lat: 48.8816667, lng: 21.0439057 },
    { lat: 48.8812247, lng: 21.0453656 },
    { lat: 48.8812413, lng: 21.0466714 },
    { lat: 48.8814601, lng: 21.0475092 },
    { lat: 48.8816949, lng: 21.0486706 },
    { lat: 48.8816761, lng: 21.0501979 },
    { lat: 48.881932, lng: 21.050176 },
  ],
  Gelnica: [
    { lat: 48.8535606, lng: 20.9919587 },
    { lat: 48.853707, lng: 20.9915855 },
    { lat: 48.8538098, lng: 20.9913218 },
    { lat: 48.8540813, lng: 20.9908862 },
    { lat: 48.8543048, lng: 20.9902301 },
    { lat: 48.8557699, lng: 20.989025 },
    { lat: 48.8567628, lng: 20.9884909 },
    { lat: 48.8573725, lng: 20.9876295 },
    { lat: 48.8580973, lng: 20.9869807 },
    { lat: 48.8582524, lng: 20.9866032 },
    { lat: 48.8586147, lng: 20.9860562 },
    { lat: 48.859315, lng: 20.9853888 },
    { lat: 48.8598652, lng: 20.9849734 },
    { lat: 48.8608214, lng: 20.9847179 },
    { lat: 48.8618543, lng: 20.9840948 },
    { lat: 48.8624702, lng: 20.9832597 },
    { lat: 48.8627645, lng: 20.9827314 },
    { lat: 48.863121, lng: 20.9817701 },
    { lat: 48.8659365, lng: 20.981444 },
    { lat: 48.8661761, lng: 20.9812101 },
    { lat: 48.8666354, lng: 20.9804651 },
    { lat: 48.8675265, lng: 20.9794083 },
    { lat: 48.8676457, lng: 20.9789634 },
    { lat: 48.8678207, lng: 20.977963 },
    { lat: 48.8684594, lng: 20.9772657 },
    { lat: 48.8687188, lng: 20.976543 },
    { lat: 48.8694079, lng: 20.9757657 },
    { lat: 48.8692498, lng: 20.9754785 },
    { lat: 48.8693563, lng: 20.9752523 },
    { lat: 48.8697481, lng: 20.9744197 },
    { lat: 48.8698774, lng: 20.9742876 },
    { lat: 48.8702346, lng: 20.9734809 },
    { lat: 48.8704145, lng: 20.9726301 },
    { lat: 48.8705372, lng: 20.9724978 },
    { lat: 48.8708701, lng: 20.9723511 },
    { lat: 48.8709569, lng: 20.9721883 },
    { lat: 48.8712697, lng: 20.9718848 },
    { lat: 48.871298, lng: 20.971751 },
    { lat: 48.8714128, lng: 20.971698 },
    { lat: 48.872013, lng: 20.9714207 },
    { lat: 48.872755, lng: 20.9703768 },
    { lat: 48.8732486, lng: 20.9698642 },
    { lat: 48.8735948, lng: 20.9695048 },
    { lat: 48.8740023, lng: 20.968765 },
    { lat: 48.8751831, lng: 20.9671121 },
    { lat: 48.875521, lng: 20.966783 },
    { lat: 48.8764751, lng: 20.9662251 },
    { lat: 48.8780743, lng: 20.9636582 },
    { lat: 48.8778472, lng: 20.9632218 },
    { lat: 48.8751419, lng: 20.9603344 },
    { lat: 48.8748352, lng: 20.9598784 },
    { lat: 48.8746956, lng: 20.9598083 },
    { lat: 48.8743826, lng: 20.9587723 },
    { lat: 48.8742994, lng: 20.9584971 },
    { lat: 48.8738851, lng: 20.9575584 },
    { lat: 48.873188, lng: 20.9563616 },
    { lat: 48.8727359, lng: 20.9541455 },
    { lat: 48.8723785, lng: 20.9535583 },
    { lat: 48.8711697, lng: 20.9511999 },
    { lat: 48.8709627, lng: 20.9504992 },
    { lat: 48.8710231, lng: 20.9502515 },
    { lat: 48.8711428, lng: 20.9500592 },
    { lat: 48.8712217, lng: 20.9495887 },
    { lat: 48.8705029, lng: 20.9472538 },
    { lat: 48.8701599, lng: 20.9455809 },
    { lat: 48.8700241, lng: 20.943942 },
    { lat: 48.8703577, lng: 20.9429463 },
    { lat: 48.8703905, lng: 20.9423127 },
    { lat: 48.8706596, lng: 20.9415115 },
    { lat: 48.8707798, lng: 20.9406627 },
    { lat: 48.8707783, lng: 20.9403171 },
    { lat: 48.8709664, lng: 20.9392868 },
    { lat: 48.8709168, lng: 20.93868 },
    { lat: 48.8705339, lng: 20.938102 },
    { lat: 48.8704451, lng: 20.9378725 },
    { lat: 48.8705593, lng: 20.9359409 },
    { lat: 48.8705613, lng: 20.9356003 },
    { lat: 48.8704518, lng: 20.9350517 },
    { lat: 48.870515, lng: 20.9345241 },
    { lat: 48.8704857, lng: 20.9338496 },
    { lat: 48.8705138, lng: 20.9332821 },
    { lat: 48.8707147, lng: 20.932927 },
    { lat: 48.8708879, lng: 20.932152 },
    { lat: 48.8708867, lng: 20.9318146 },
    { lat: 48.8708135, lng: 20.9314119 },
    { lat: 48.8705709, lng: 20.9307542 },
    { lat: 48.8705489, lng: 20.930438 },
    { lat: 48.8708109, lng: 20.9293634 },
    { lat: 48.8706123, lng: 20.9287108 },
    { lat: 48.8705966, lng: 20.9284124 },
    { lat: 48.8706149, lng: 20.928184 },
    { lat: 48.8708553, lng: 20.9277027 },
    { lat: 48.8709708, lng: 20.9270195 },
    { lat: 48.8709964, lng: 20.925672 },
    { lat: 48.8711021, lng: 20.9248336 },
    { lat: 48.8712313, lng: 20.9242569 },
    { lat: 48.8713351, lng: 20.9232288 },
    { lat: 48.8715847, lng: 20.9215215 },
    { lat: 48.8718839, lng: 20.9199072 },
    { lat: 48.8721736, lng: 20.9171205 },
    { lat: 48.8722565, lng: 20.916641 },
    { lat: 48.8722165, lng: 20.9158882 },
    { lat: 48.8722717, lng: 20.9151213 },
    { lat: 48.8729889, lng: 20.91333 },
    { lat: 48.8731477, lng: 20.9130313 },
    { lat: 48.8732837, lng: 20.9125956 },
    { lat: 48.8733549, lng: 20.9114981 },
    { lat: 48.8737411, lng: 20.9103693 },
    { lat: 48.8737861, lng: 20.9099699 },
    { lat: 48.8741107, lng: 20.9092196 },
    { lat: 48.8749167, lng: 20.9080188 },
    { lat: 48.8757614, lng: 20.9063397 },
    { lat: 48.8771555, lng: 20.9043943 },
    { lat: 48.8777964, lng: 20.9039772 },
    { lat: 48.8784417, lng: 20.9029861 },
    { lat: 48.8787008, lng: 20.902029 },
    { lat: 48.8788087, lng: 20.9005351 },
    { lat: 48.8794566, lng: 20.8993214 },
    { lat: 48.8795812, lng: 20.8988797 },
    { lat: 48.8795983, lng: 20.8981871 },
    { lat: 48.8793385, lng: 20.8972646 },
    { lat: 48.8795044, lng: 20.8959875 },
    { lat: 48.8801937, lng: 20.8960954 },
    { lat: 48.8806137, lng: 20.8958335 },
    { lat: 48.8812654, lng: 20.8952355 },
    { lat: 48.8814345, lng: 20.8946794 },
    { lat: 48.8823596, lng: 20.8931496 },
    { lat: 48.8825708, lng: 20.8926392 },
    { lat: 48.8831172, lng: 20.8916899 },
    { lat: 48.8830818, lng: 20.8911487 },
    { lat: 48.8829317, lng: 20.890772 },
    { lat: 48.8827435, lng: 20.8906683 },
    { lat: 48.8827982, lng: 20.8905217 },
    { lat: 48.88285, lng: 20.890551 },
    { lat: 48.882868, lng: 20.890437 },
    { lat: 48.882884, lng: 20.89017 },
    { lat: 48.882889, lng: 20.890135 },
    { lat: 48.882725, lng: 20.889883 },
    { lat: 48.882681, lng: 20.889825 },
    { lat: 48.882624, lng: 20.889768 },
    { lat: 48.882539, lng: 20.889692 },
    { lat: 48.882348, lng: 20.889553 },
    { lat: 48.882148, lng: 20.889415 },
    { lat: 48.881975, lng: 20.88925 },
    { lat: 48.881756, lng: 20.889056 },
    { lat: 48.881649, lng: 20.888976 },
    { lat: 48.881482, lng: 20.888848 },
    { lat: 48.881142, lng: 20.888586 },
    { lat: 48.881062, lng: 20.888525 },
    { lat: 48.880879, lng: 20.888326 },
    { lat: 48.880768, lng: 20.888206 },
    { lat: 48.880582, lng: 20.887982 },
    { lat: 48.880442, lng: 20.887814 },
    { lat: 48.880357, lng: 20.887507 },
    { lat: 48.880347, lng: 20.887464 },
    { lat: 48.880328, lng: 20.88703 },
    { lat: 48.880303, lng: 20.886957 },
    { lat: 48.880274, lng: 20.886908 },
    { lat: 48.880243, lng: 20.886866 },
    { lat: 48.880207, lng: 20.886833 },
    { lat: 48.88017, lng: 20.886803 },
    { lat: 48.880086, lng: 20.886769 },
    { lat: 48.879965, lng: 20.88671 },
    { lat: 48.879878, lng: 20.886674 },
    { lat: 48.879742, lng: 20.886609 },
    { lat: 48.879647, lng: 20.886565 },
    { lat: 48.879464, lng: 20.886349 },
    { lat: 48.879424, lng: 20.886296 },
    { lat: 48.879264, lng: 20.885994 },
    { lat: 48.879087, lng: 20.885711 },
    { lat: 48.87886, lng: 20.885452 },
    { lat: 48.878755, lng: 20.885344 },
    { lat: 48.878637, lng: 20.885298 },
    { lat: 48.878388, lng: 20.885315 },
    { lat: 48.878116, lng: 20.885493 },
    { lat: 48.877942, lng: 20.885689 },
    { lat: 48.877909, lng: 20.88574 },
    { lat: 48.877465, lng: 20.886183 },
    { lat: 48.87726, lng: 20.886246 },
    { lat: 48.876708, lng: 20.88609 },
    { lat: 48.876615, lng: 20.886017 },
    { lat: 48.876062, lng: 20.885583 },
    { lat: 48.875891, lng: 20.885318 },
    { lat: 48.875763, lng: 20.885195 },
    { lat: 48.875714, lng: 20.885103 },
    { lat: 48.875528, lng: 20.884802 },
    { lat: 48.87519, lng: 20.88464 },
    { lat: 48.874994, lng: 20.884364 },
    { lat: 48.874984, lng: 20.88438 },
    { lat: 48.874936, lng: 20.884339 },
    { lat: 48.874788, lng: 20.884226 },
    { lat: 48.874545, lng: 20.884049 },
    { lat: 48.87413, lng: 20.883741 },
    { lat: 48.873856, lng: 20.883533 },
    { lat: 48.873485, lng: 20.883271 },
    { lat: 48.872984, lng: 20.882902 },
    { lat: 48.872549, lng: 20.882539 },
    { lat: 48.872427, lng: 20.882454 },
    { lat: 48.871993, lng: 20.882486 },
    { lat: 48.871399, lng: 20.881935 },
    { lat: 48.871235, lng: 20.881784 },
    { lat: 48.870907, lng: 20.881479 },
    { lat: 48.870782, lng: 20.88124 },
    { lat: 48.870649, lng: 20.880986 },
    { lat: 48.870378, lng: 20.880466 },
    { lat: 48.86975, lng: 20.880519 },
    { lat: 48.869373, lng: 20.880556 },
    { lat: 48.869091, lng: 20.88054 },
    { lat: 48.86903, lng: 20.880503 },
    { lat: 48.868599, lng: 20.880239 },
    { lat: 48.868478, lng: 20.880161 },
    { lat: 48.868419, lng: 20.880125 },
    { lat: 48.868074, lng: 20.879818 },
    { lat: 48.867693, lng: 20.879487 },
    { lat: 48.867567, lng: 20.879328 },
    { lat: 48.867277, lng: 20.878961 },
    { lat: 48.866773, lng: 20.87832 },
    { lat: 48.866041, lng: 20.877949 },
    { lat: 48.865598, lng: 20.877821 },
    { lat: 48.865222, lng: 20.877718 },
    { lat: 48.86487, lng: 20.877616 },
    { lat: 48.863961, lng: 20.877209 },
    { lat: 48.863909, lng: 20.877173 },
    { lat: 48.863588, lng: 20.876803 },
    { lat: 48.863059, lng: 20.876501 },
    { lat: 48.862481, lng: 20.876022 },
    { lat: 48.862416, lng: 20.875971 },
    { lat: 48.862055, lng: 20.875746 },
    { lat: 48.861717, lng: 20.875689 },
    { lat: 48.861121, lng: 20.87559 },
    { lat: 48.860581, lng: 20.875506 },
    { lat: 48.860326, lng: 20.875563 },
    { lat: 48.859662, lng: 20.875702 },
    { lat: 48.859585, lng: 20.875718 },
    { lat: 48.8586124, lng: 20.8773801 },
    { lat: 48.8577139, lng: 20.878728 },
    { lat: 48.8569102, lng: 20.8801868 },
    { lat: 48.8565352, lng: 20.8813368 },
    { lat: 48.856188, lng: 20.882911 },
    { lat: 48.8559281, lng: 20.8835701 },
    { lat: 48.8556064, lng: 20.8841371 },
    { lat: 48.8546811, lng: 20.8853314 },
    { lat: 48.8541387, lng: 20.8858953 },
    { lat: 48.8537002, lng: 20.8868032 },
    { lat: 48.8529951, lng: 20.8870244 },
    { lat: 48.8524326, lng: 20.8882253 },
    { lat: 48.8522525, lng: 20.8888177 },
    { lat: 48.8520698, lng: 20.8891036 },
    { lat: 48.8516331, lng: 20.8893089 },
    { lat: 48.851096, lng: 20.8901629 },
    { lat: 48.8508119, lng: 20.8910771 },
    { lat: 48.8503319, lng: 20.8920192 },
    { lat: 48.8499804, lng: 20.8924147 },
    { lat: 48.8495558, lng: 20.8930662 },
    { lat: 48.8490455, lng: 20.8936538 },
    { lat: 48.8485828, lng: 20.8936828 },
    { lat: 48.848465, lng: 20.8938253 },
    { lat: 48.8481895, lng: 20.8943968 },
    { lat: 48.8479836, lng: 20.8946662 },
    { lat: 48.8478102, lng: 20.8946669 },
    { lat: 48.847618, lng: 20.8944728 },
    { lat: 48.8474737, lng: 20.8944329 },
    { lat: 48.8473519, lng: 20.8944924 },
    { lat: 48.8471941, lng: 20.894984 },
    { lat: 48.8469177, lng: 20.8952639 },
    { lat: 48.8467225, lng: 20.8958509 },
    { lat: 48.8467011, lng: 20.8958119 },
    { lat: 48.8462666, lng: 20.8959504 },
    { lat: 48.8458833, lng: 20.8962283 },
    { lat: 48.8455018, lng: 20.8962648 },
    { lat: 48.8454095, lng: 20.8963263 },
    { lat: 48.8447183, lng: 20.896392 },
    { lat: 48.8443245, lng: 20.8962651 },
    { lat: 48.8441634, lng: 20.8963619 },
    { lat: 48.843942, lng: 20.8962387 },
    { lat: 48.843396, lng: 20.896267 },
    { lat: 48.8431315, lng: 20.8964673 },
    { lat: 48.8418369, lng: 20.8963999 },
    { lat: 48.8416406, lng: 20.896155 },
    { lat: 48.84131, lng: 20.8960325 },
    { lat: 48.8406174, lng: 20.8961536 },
    { lat: 48.8405244, lng: 20.8960972 },
    { lat: 48.8400779, lng: 20.8962428 },
    { lat: 48.8394699, lng: 20.8958703 },
    { lat: 48.839354, lng: 20.8957026 },
    { lat: 48.8392544, lng: 20.8955591 },
    { lat: 48.8381013, lng: 20.8952426 },
    { lat: 48.83726, lng: 20.895598 },
    { lat: 48.8362676, lng: 20.895875 },
    { lat: 48.8358014, lng: 20.8958988 },
    { lat: 48.8356594, lng: 20.8961264 },
    { lat: 48.8354586, lng: 20.896216 },
    { lat: 48.8353036, lng: 20.8963871 },
    { lat: 48.8348067, lng: 20.8963075 },
    { lat: 48.8341388, lng: 20.8968226 },
    { lat: 48.8334396, lng: 20.8969538 },
    { lat: 48.8333118, lng: 20.8969052 },
    { lat: 48.8329619, lng: 20.8969721 },
    { lat: 48.8326165, lng: 20.8971784 },
    { lat: 48.8325188, lng: 20.8971852 },
    { lat: 48.8323437, lng: 20.8970352 },
    { lat: 48.8321779, lng: 20.8970217 },
    { lat: 48.8308924, lng: 20.8974534 },
    { lat: 48.8305779, lng: 20.8987029 },
    { lat: 48.8304649, lng: 20.8990215 },
    { lat: 48.8302595, lng: 20.8991335 },
    { lat: 48.8302144, lng: 20.8992378 },
    { lat: 48.8301388, lng: 20.8994284 },
    { lat: 48.8300383, lng: 20.9001157 },
    { lat: 48.8297646, lng: 20.9007033 },
    { lat: 48.8294666, lng: 20.9010148 },
    { lat: 48.8292393, lng: 20.901016 },
    { lat: 48.8287998, lng: 20.9013339 },
    { lat: 48.8286396, lng: 20.9015196 },
    { lat: 48.8282549, lng: 20.9010604 },
    { lat: 48.8277223, lng: 20.9010254 },
    { lat: 48.8265496, lng: 20.9022948 },
    { lat: 48.8263065, lng: 20.9028878 },
    { lat: 48.8256986, lng: 20.9040489 },
    { lat: 48.8248451, lng: 20.9054023 },
    { lat: 48.8247186, lng: 20.9056922 },
    { lat: 48.8243424, lng: 20.9061186 },
    { lat: 48.8237736, lng: 20.9066029 },
    { lat: 48.8234501, lng: 20.9069902 },
    { lat: 48.8232755, lng: 20.9073135 },
    { lat: 48.82279, lng: 20.9076752 },
    { lat: 48.8223152, lng: 20.9083433 },
    { lat: 48.8215553, lng: 20.9092027 },
    { lat: 48.821065, lng: 20.9096848 },
    { lat: 48.820084, lng: 20.9103685 },
    { lat: 48.8196557, lng: 20.9120426 },
    { lat: 48.8183621, lng: 20.9126673 },
    { lat: 48.816856, lng: 20.9135673 },
    { lat: 48.8154735, lng: 20.9139256 },
    { lat: 48.8155078, lng: 20.9144029 },
    { lat: 48.8161757, lng: 20.9158898 },
    { lat: 48.8168384, lng: 20.9168757 },
    { lat: 48.8172877, lng: 20.9174477 },
    { lat: 48.8176997, lng: 20.9178526 },
    { lat: 48.8196066, lng: 20.9193523 },
    { lat: 48.8196364, lng: 20.9195697 },
    { lat: 48.819725, lng: 20.9197646 },
    { lat: 48.8200513, lng: 20.9201566 },
    { lat: 48.8200319, lng: 20.9205485 },
    { lat: 48.8202786, lng: 20.9206226 },
    { lat: 48.8199637, lng: 20.9220286 },
    { lat: 48.8198608, lng: 20.9222858 },
    { lat: 48.8197102, lng: 20.9224571 },
    { lat: 48.8196136, lng: 20.92283 },
    { lat: 48.8133918, lng: 20.9235125 },
    { lat: 48.8133107, lng: 20.9235777 },
    { lat: 48.8132639, lng: 20.9237278 },
    { lat: 48.8122305, lng: 20.9223906 },
    { lat: 48.8115751, lng: 20.9213085 },
    { lat: 48.8107146, lng: 20.9190312 },
    { lat: 48.8095555, lng: 20.9176036 },
    { lat: 48.8093118, lng: 20.9174207 },
    { lat: 48.8082589, lng: 20.9169044 },
    { lat: 48.807307, lng: 20.9166124 },
    { lat: 48.8072109, lng: 20.9154375 },
    { lat: 48.8069973, lng: 20.915678 },
    { lat: 48.8065243, lng: 20.9157378 },
    { lat: 48.8061591, lng: 20.9163567 },
    { lat: 48.8058433, lng: 20.9164351 },
    { lat: 48.8056882, lng: 20.9163418 },
    { lat: 48.805331, lng: 20.9165948 },
    { lat: 48.8048213, lng: 20.9165392 },
    { lat: 48.8045956, lng: 20.9167517 },
    { lat: 48.8043269, lng: 20.9168336 },
    { lat: 48.8042093, lng: 20.9170183 },
    { lat: 48.8040343, lng: 20.9170312 },
    { lat: 48.8037302, lng: 20.9172961 },
    { lat: 48.8031933, lng: 20.9172934 },
    { lat: 48.8029858, lng: 20.9173519 },
    { lat: 48.8028895, lng: 20.9174605 },
    { lat: 48.8026683, lng: 20.9172794 },
    { lat: 48.8023636, lng: 20.9173583 },
    { lat: 48.8021442, lng: 20.9172957 },
    { lat: 48.8019272, lng: 20.9174335 },
    { lat: 48.8015428, lng: 20.9172837 },
    { lat: 48.8013898, lng: 20.9173834 },
    { lat: 48.8010235, lng: 20.9174378 },
    { lat: 48.8008621, lng: 20.9175571 },
    { lat: 48.8006869, lng: 20.9174396 },
    { lat: 48.80009, lng: 20.9175611 },
    { lat: 48.7999838, lng: 20.9175158 },
    { lat: 48.7998286, lng: 20.9172868 },
    { lat: 48.7993559, lng: 20.9172513 },
    { lat: 48.7993033, lng: 20.916964 },
    { lat: 48.7986414, lng: 20.9169628 },
    { lat: 48.7983832, lng: 20.9170474 },
    { lat: 48.797554, lng: 20.916661 },
    { lat: 48.7972077, lng: 20.9166742 },
    { lat: 48.7959698, lng: 20.9171753 },
    { lat: 48.7956079, lng: 20.9171926 },
    { lat: 48.7955241, lng: 20.9172819 },
    { lat: 48.7949918, lng: 20.9173467 },
    { lat: 48.7946048, lng: 20.9173199 },
    { lat: 48.7939411, lng: 20.917503 },
    { lat: 48.7932951, lng: 20.9173038 },
    { lat: 48.7931841, lng: 20.9174177 },
    { lat: 48.7927804, lng: 20.9171623 },
    { lat: 48.7924452, lng: 20.9172025 },
    { lat: 48.7922513, lng: 20.9173646 },
    { lat: 48.7915909, lng: 20.9171062 },
    { lat: 48.7914099, lng: 20.9168453 },
    { lat: 48.7912725, lng: 20.9168424 },
    { lat: 48.7910918, lng: 20.917022 },
    { lat: 48.7909255, lng: 20.9170627 },
    { lat: 48.7906226, lng: 20.9169923 },
    { lat: 48.7904055, lng: 20.9171301 },
    { lat: 48.7902327, lng: 20.9171337 },
    { lat: 48.7901849, lng: 20.9171347 },
    { lat: 48.7898958, lng: 20.9174034 },
    { lat: 48.789659, lng: 20.9175274 },
    { lat: 48.789243, lng: 20.9175728 },
    { lat: 48.7888542, lng: 20.9178552 },
    { lat: 48.7884873, lng: 20.9178237 },
    { lat: 48.788257, lng: 20.9180378 },
    { lat: 48.788154, lng: 20.9180543 },
    { lat: 48.7877516, lng: 20.9178378 },
    { lat: 48.7876719, lng: 20.9179778 },
    { lat: 48.7875061, lng: 20.9179003 },
    { lat: 48.7873478, lng: 20.9180461 },
    { lat: 48.7872624, lng: 20.9180534 },
    { lat: 48.786869, lng: 20.9180699 },
    { lat: 48.7866871, lng: 20.917949 },
    { lat: 48.7863707, lng: 20.91791 },
    { lat: 48.7860533, lng: 20.9182487 },
    { lat: 48.7854071, lng: 20.918445 },
    { lat: 48.7850698, lng: 20.9183215 },
    { lat: 48.7840543, lng: 20.9171169 },
    { lat: 48.7836045, lng: 20.9167284 },
    { lat: 48.7823106, lng: 20.9159495 },
    { lat: 48.7812181, lng: 20.9158606 },
    { lat: 48.7802943, lng: 20.9160017 },
    { lat: 48.780022, lng: 20.9159421 },
    { lat: 48.7797238, lng: 20.9159766 },
    { lat: 48.77919, lng: 20.9161014 },
    { lat: 48.7787709, lng: 20.9160254 },
    { lat: 48.7776213, lng: 20.9155455 },
    { lat: 48.7773575, lng: 20.915669 },
    { lat: 48.7736862, lng: 20.9164503 },
    { lat: 48.7666312, lng: 20.918255 },
    { lat: 48.763661, lng: 20.918822 },
    { lat: 48.763715, lng: 20.920535 },
    { lat: 48.763953, lng: 20.922609 },
    { lat: 48.764386, lng: 20.923509 },
    { lat: 48.76563, lng: 20.926413 },
    { lat: 48.7658702, lng: 20.9279658 },
    { lat: 48.766026, lng: 20.928973 },
    { lat: 48.76608, lng: 20.930444 },
    { lat: 48.766155, lng: 20.931622 },
    { lat: 48.766332, lng: 20.93358 },
    { lat: 48.766553, lng: 20.934765 },
    { lat: 48.767035, lng: 20.937136 },
    { lat: 48.767586, lng: 20.938257 },
    { lat: 48.768659, lng: 20.939326 },
    { lat: 48.768911, lng: 20.939502 },
    { lat: 48.768985, lng: 20.939593 },
    { lat: 48.769073, lng: 20.939722 },
    { lat: 48.76916, lng: 20.939916 },
    { lat: 48.769219, lng: 20.940107 },
    { lat: 48.769256, lng: 20.940283 },
    { lat: 48.769388, lng: 20.940695 },
    { lat: 48.769644, lng: 20.941319 },
    { lat: 48.770388, lng: 20.942481 },
    { lat: 48.770811, lng: 20.943088 },
    { lat: 48.770868, lng: 20.943153 },
    { lat: 48.770995, lng: 20.943273 },
    { lat: 48.771726, lng: 20.944057 },
    { lat: 48.771803, lng: 20.944217 },
    { lat: 48.77204, lng: 20.944424 },
    { lat: 48.773123, lng: 20.945773 },
    { lat: 48.774206, lng: 20.946725 },
    { lat: 48.775097, lng: 20.9467 },
    { lat: 48.775111, lng: 20.946699 },
    { lat: 48.775264, lng: 20.947649 },
    { lat: 48.775418, lng: 20.948247 },
    { lat: 48.775488, lng: 20.948698 },
    { lat: 48.775486, lng: 20.949088 },
    { lat: 48.775473, lng: 20.94952 },
    { lat: 48.77554, lng: 20.950011 },
    { lat: 48.775697, lng: 20.950732 },
    { lat: 48.775756, lng: 20.951449 },
    { lat: 48.775885, lng: 20.952091 },
    { lat: 48.7758817, lng: 20.9521459 },
    { lat: 48.775953, lng: 20.952723 },
    { lat: 48.776096, lng: 20.953302 },
    { lat: 48.776443, lng: 20.95439 },
    { lat: 48.776572, lng: 20.954969 },
    { lat: 48.776592, lng: 20.954964 },
    { lat: 48.776636, lng: 20.954953 },
    { lat: 48.776788, lng: 20.956316 },
    { lat: 48.777016, lng: 20.957798 },
    { lat: 48.777399, lng: 20.960304 },
    { lat: 48.777837, lng: 20.962735 },
    { lat: 48.778066, lng: 20.964444 },
    { lat: 48.778437, lng: 20.96666 },
    { lat: 48.77853, lng: 20.9672407 },
    { lat: 48.7787114, lng: 20.9683728 },
    { lat: 48.778721, lng: 20.968433 },
    { lat: 48.778799, lng: 20.96917 },
    { lat: 48.778828, lng: 20.969906 },
    { lat: 48.778812, lng: 20.970666 },
    { lat: 48.778769, lng: 20.971431 },
    { lat: 48.778684, lng: 20.972263 },
    { lat: 48.778513, lng: 20.973262 },
    { lat: 48.778371, lng: 20.973792 },
    { lat: 48.777186, lng: 20.978866 },
    { lat: 48.777477, lng: 20.980531 },
    { lat: 48.7774767, lng: 20.9806436 },
    { lat: 48.7800716, lng: 20.9787977 },
    { lat: 48.7809652, lng: 20.9776468 },
    { lat: 48.7827573, lng: 20.9764027 },
    { lat: 48.7831334, lng: 20.9760293 },
    { lat: 48.7833676, lng: 20.9753269 },
    { lat: 48.7842041, lng: 20.9740146 },
    { lat: 48.785389, lng: 20.9727874 },
    { lat: 48.7858351, lng: 20.9720181 },
    { lat: 48.786018, lng: 20.9711222 },
    { lat: 48.78601, lng: 20.9705806 },
    { lat: 48.7858758, lng: 20.9699352 },
    { lat: 48.7859321, lng: 20.9682158 },
    { lat: 48.7859111, lng: 20.9672451 },
    { lat: 48.7858498, lng: 20.9666244 },
    { lat: 48.7857937, lng: 20.9664274 },
    { lat: 48.7858588, lng: 20.9662522 },
    { lat: 48.7858624, lng: 20.9659214 },
    { lat: 48.7857895, lng: 20.9638367 },
    { lat: 48.7860135, lng: 20.9629644 },
    { lat: 48.7862835, lng: 20.9626532 },
    { lat: 48.7865262, lng: 20.9619693 },
    { lat: 48.7866435, lng: 20.9618425 },
    { lat: 48.7866804, lng: 20.9614646 },
    { lat: 48.7865726, lng: 20.9602567 },
    { lat: 48.7866333, lng: 20.9600558 },
    { lat: 48.7865861, lng: 20.9599219 },
    { lat: 48.786585, lng: 20.9595212 },
    { lat: 48.7866814, lng: 20.9592026 },
    { lat: 48.7869452, lng: 20.9587804 },
    { lat: 48.7872294, lng: 20.9584556 },
    { lat: 48.7872772, lng: 20.9582064 },
    { lat: 48.7874534, lng: 20.9580515 },
    { lat: 48.7875701, lng: 20.9577767 },
    { lat: 48.7875877, lng: 20.9576254 },
    { lat: 48.7878581, lng: 20.9571614 },
    { lat: 48.7880223, lng: 20.9567561 },
    { lat: 48.7879959, lng: 20.9562677 },
    { lat: 48.7882655, lng: 20.955818 },
    { lat: 48.7885341, lng: 20.9551963 },
    { lat: 48.7888166, lng: 20.9547923 },
    { lat: 48.7891381, lng: 20.9544821 },
    { lat: 48.7893126, lng: 20.9539758 },
    { lat: 48.7897245, lng: 20.9538075 },
    { lat: 48.790095, lng: 20.9535096 },
    { lat: 48.7901439, lng: 20.9532891 },
    { lat: 48.7906268, lng: 20.9531568 },
    { lat: 48.7910018, lng: 20.9528614 },
    { lat: 48.7910925, lng: 20.9527084 },
    { lat: 48.791466, lng: 20.9527055 },
    { lat: 48.7916126, lng: 20.95261 },
    { lat: 48.7917176, lng: 20.952422 },
    { lat: 48.7918349, lng: 20.9524598 },
    { lat: 48.7920027, lng: 20.9524002 },
    { lat: 48.7920592, lng: 20.9523051 },
    { lat: 48.792457, lng: 20.9522532 },
    { lat: 48.7925662, lng: 20.9521809 },
    { lat: 48.7926772, lng: 20.9519661 },
    { lat: 48.793504, lng: 20.951511 },
    { lat: 48.7936772, lng: 20.9514442 },
    { lat: 48.7939961, lng: 20.9514586 },
    { lat: 48.7944706, lng: 20.9512748 },
    { lat: 48.7946439, lng: 20.951335 },
    { lat: 48.7947809, lng: 20.951291 },
    { lat: 48.7948628, lng: 20.9513575 },
    { lat: 48.7951124, lng: 20.9513787 },
    { lat: 48.7950657, lng: 20.952071 },
    { lat: 48.7949054, lng: 20.952798 },
    { lat: 48.7949023, lng: 20.9534744 },
    { lat: 48.7948178, lng: 20.9539799 },
    { lat: 48.7946937, lng: 20.954239 },
    { lat: 48.7946431, lng: 20.9547066 },
    { lat: 48.7941838, lng: 20.9572279 },
    { lat: 48.7942007, lng: 20.9574648 },
    { lat: 48.7943775, lng: 20.9579075 },
    { lat: 48.7943192, lng: 20.9583517 },
    { lat: 48.7942675, lng: 20.9607494 },
    { lat: 48.7948192, lng: 20.9641522 },
    { lat: 48.7967402, lng: 20.9647503 },
    { lat: 48.7970504, lng: 20.9649069 },
    { lat: 48.7974497, lng: 20.9652302 },
    { lat: 48.7981941, lng: 20.9655149 },
    { lat: 48.7994281, lng: 20.9663042 },
    { lat: 48.8001842, lng: 20.9664671 },
    { lat: 48.8013416, lng: 20.9657663 },
    { lat: 48.8019512, lng: 20.9652491 },
    { lat: 48.8023979, lng: 20.9652046 },
    { lat: 48.8025668, lng: 20.9650431 },
    { lat: 48.8029671, lng: 20.964895 },
    { lat: 48.8034716, lng: 20.9650382 },
    { lat: 48.8046597, lng: 20.9649177 },
    { lat: 48.8048619, lng: 20.9650047 },
    { lat: 48.8050596, lng: 20.9649307 },
    { lat: 48.8051597, lng: 20.9649602 },
    { lat: 48.8054502, lng: 20.9648323 },
    { lat: 48.8056709, lng: 20.9649007 },
    { lat: 48.8061079, lng: 20.9648059 },
    { lat: 48.8063495, lng: 20.9646246 },
    { lat: 48.806744, lng: 20.9645444 },
    { lat: 48.8070736, lng: 20.9642985 },
    { lat: 48.8078882, lng: 20.9641555 },
    { lat: 48.8081324, lng: 20.9640526 },
    { lat: 48.8088039, lng: 20.9635291 },
    { lat: 48.8090007, lng: 20.9634266 },
    { lat: 48.8093112, lng: 20.9633878 },
    { lat: 48.8095357, lng: 20.9630689 },
    { lat: 48.8106386, lng: 20.962321 },
    { lat: 48.8107294, lng: 20.9621069 },
    { lat: 48.8110853, lng: 20.9620089 },
    { lat: 48.8117411, lng: 20.9619984 },
    { lat: 48.8124167, lng: 20.9623481 },
    { lat: 48.8126335, lng: 20.9623618 },
    { lat: 48.8130692, lng: 20.9622363 },
    { lat: 48.8136116, lng: 20.9622995 },
    { lat: 48.8144611, lng: 20.9625934 },
    { lat: 48.8150561, lng: 20.9626121 },
    { lat: 48.8154403, lng: 20.9627584 },
    { lat: 48.815727, lng: 20.962784 },
    { lat: 48.8160815, lng: 20.9630536 },
    { lat: 48.8164847, lng: 20.963226 },
    { lat: 48.8166456, lng: 20.9631569 },
    { lat: 48.8169101, lng: 20.9631609 },
    { lat: 48.8174445, lng: 20.9635258 },
    { lat: 48.8177075, lng: 20.9635746 },
    { lat: 48.817977, lng: 20.9634961 },
    { lat: 48.8197121, lng: 20.9639859 },
    { lat: 48.8200124, lng: 20.9639439 },
    { lat: 48.8202009, lng: 20.9637917 },
    { lat: 48.8203681, lng: 20.9635346 },
    { lat: 48.8211082, lng: 20.962716 },
    { lat: 48.8224771, lng: 20.9619801 },
    { lat: 48.8229714, lng: 20.9617783 },
    { lat: 48.8238951, lng: 20.9612293 },
    { lat: 48.8243219, lng: 20.9610633 },
    { lat: 48.8247516, lng: 20.9607597 },
    { lat: 48.8248408, lng: 20.9607678 },
    { lat: 48.8253209, lng: 20.9611547 },
    { lat: 48.8256336, lng: 20.9615559 },
    { lat: 48.826702, lng: 20.9625697 },
    { lat: 48.8284269, lng: 20.9640344 },
    { lat: 48.8286377, lng: 20.9642809 },
    { lat: 48.8287635, lng: 20.9645438 },
    { lat: 48.8288887, lng: 20.9646571 },
    { lat: 48.8290235, lng: 20.9647458 },
    { lat: 48.8291668, lng: 20.9647294 },
    { lat: 48.8294009, lng: 20.9648241 },
    { lat: 48.829871, lng: 20.9651143 },
    { lat: 48.8306227, lng: 20.9652157 },
    { lat: 48.8309884, lng: 20.9654439 },
    { lat: 48.831751, lng: 20.9656388 },
    { lat: 48.8320567, lng: 20.966196 },
    { lat: 48.8325058, lng: 20.9673095 },
    { lat: 48.8328492, lng: 20.9679885 },
    { lat: 48.8328599, lng: 20.9682092 },
    { lat: 48.8335436, lng: 20.9693063 },
    { lat: 48.8339214, lng: 20.9701038 },
    { lat: 48.8341775, lng: 20.9704918 },
    { lat: 48.8345279, lng: 20.97131 },
    { lat: 48.8350994, lng: 20.9716815 },
    { lat: 48.8356495, lng: 20.971936 },
    { lat: 48.8359642, lng: 20.97223 },
    { lat: 48.836631, lng: 20.9739312 },
    { lat: 48.8372566, lng: 20.9743662 },
    { lat: 48.8381316, lng: 20.9754642 },
    { lat: 48.8385828, lng: 20.9759353 },
    { lat: 48.838967, lng: 20.9761815 },
    { lat: 48.8391094, lng: 20.9763364 },
    { lat: 48.8392308, lng: 20.976703 },
    { lat: 48.8392761, lng: 20.9780652 },
    { lat: 48.8399501, lng: 20.9814501 },
    { lat: 48.8400355, lng: 20.9822254 },
    { lat: 48.8400072, lng: 20.983169 },
    { lat: 48.8398354, lng: 20.9852159 },
    { lat: 48.8398617, lng: 20.9858374 },
    { lat: 48.8397836, lng: 20.9866752 },
    { lat: 48.8398336, lng: 20.9868593 },
    { lat: 48.8397977, lng: 20.9869282 },
    { lat: 48.8398209, lng: 20.9870543 },
    { lat: 48.8401869, lng: 20.9874631 },
    { lat: 48.840407, lng: 20.9876861 },
    { lat: 48.8409126, lng: 20.9880032 },
    { lat: 48.8409629, lng: 20.9881626 },
    { lat: 48.8414578, lng: 20.9879814 },
    { lat: 48.8414623, lng: 20.9884219 },
    { lat: 48.841163, lng: 20.9885923 },
    { lat: 48.8420997, lng: 20.9894136 },
    { lat: 48.8439457, lng: 20.9904107 },
    { lat: 48.8442072, lng: 20.990488 },
    { lat: 48.8446093, lng: 20.9904921 },
    { lat: 48.8451957, lng: 20.9906655 },
    { lat: 48.8457682, lng: 20.9902485 },
    { lat: 48.8459564, lng: 20.9902553 },
    { lat: 48.8470644, lng: 20.9909451 },
    { lat: 48.8472529, lng: 20.9917788 },
    { lat: 48.8475172, lng: 20.9926524 },
    { lat: 48.8476701, lng: 20.9929907 },
    { lat: 48.8479912, lng: 20.9934051 },
    { lat: 48.8488585, lng: 20.9933932 },
    { lat: 48.8492495, lng: 20.9934793 },
    { lat: 48.8497514, lng: 20.9932996 },
    { lat: 48.850698, lng: 20.9931315 },
    { lat: 48.8509266, lng: 20.9927629 },
    { lat: 48.8509476, lng: 20.9924436 },
    { lat: 48.8509995, lng: 20.9923799 },
    { lat: 48.8512819, lng: 20.9923467 },
    { lat: 48.8515846, lng: 20.9922054 },
    { lat: 48.8522483, lng: 20.9923647 },
    { lat: 48.8524659, lng: 20.9925812 },
    { lat: 48.852885, lng: 20.9927595 },
    { lat: 48.8535606, lng: 20.9919587 },
  ],
  Jaklovce: [
    { lat: 48.8920079, lng: 21.0250564 },
    { lat: 48.8916411, lng: 21.0234159 },
    { lat: 48.8911999, lng: 21.0222449 },
    { lat: 48.890723, lng: 21.0215069 },
    { lat: 48.8902366, lng: 21.0210218 },
    { lat: 48.8898651, lng: 21.0203943 },
    { lat: 48.8895315, lng: 21.0197038 },
    { lat: 48.8890795, lng: 21.0177612 },
    { lat: 48.8886903, lng: 21.0168403 },
    { lat: 48.8881834, lng: 21.0158343 },
    { lat: 48.8879417, lng: 21.0151234 },
    { lat: 48.8877523, lng: 21.0142268 },
    { lat: 48.8877313, lng: 21.0138968 },
    { lat: 48.8877491, lng: 21.0130693 },
    { lat: 48.8879388, lng: 21.0111723 },
    { lat: 48.8878192, lng: 21.0105494 },
    { lat: 48.8849736, lng: 21.0059573 },
    { lat: 48.884837, lng: 21.0058128 },
    { lat: 48.8838949, lng: 21.0053649 },
    { lat: 48.8835485, lng: 21.004967 },
    { lat: 48.8827926, lng: 21.0033367 },
    { lat: 48.8825524, lng: 21.0030542 },
    { lat: 48.8815319, lng: 21.0021396 },
    { lat: 48.8804674, lng: 21.0002191 },
    { lat: 48.8794709, lng: 20.9984215 },
    { lat: 48.878976, lng: 20.9976756 },
    { lat: 48.8788098, lng: 20.9972856 },
    { lat: 48.8791876, lng: 20.9973413 },
    { lat: 48.8791876, lng: 20.9974042 },
    { lat: 48.8796404, lng: 20.9976187 },
    { lat: 48.8800553, lng: 20.9982541 },
    { lat: 48.8802101, lng: 20.9979992 },
    { lat: 48.8803699, lng: 20.9975916 },
    { lat: 48.8805821, lng: 20.9974381 },
    { lat: 48.8813807, lng: 20.9962664 },
    { lat: 48.8814647, lng: 20.9959478 },
    { lat: 48.8816317, lng: 20.995791 },
    { lat: 48.8818279, lng: 20.9948138 },
    { lat: 48.8819278, lng: 20.9946031 },
    { lat: 48.8821075, lng: 20.9944521 },
    { lat: 48.882314, lng: 20.994401 },
    { lat: 48.8825725, lng: 20.9941493 },
    { lat: 48.8831456, lng: 20.9937492 },
    { lat: 48.8838386, lng: 20.9935037 },
    { lat: 48.8841597, lng: 20.9933073 },
    { lat: 48.8844859, lng: 20.9933694 },
    { lat: 48.8844349, lng: 20.9927775 },
    { lat: 48.8847319, lng: 20.9923379 },
    { lat: 48.8850071, lng: 20.9921439 },
    { lat: 48.8854472, lng: 20.9921494 },
    { lat: 48.8865542, lng: 20.9905638 },
    { lat: 48.8871231, lng: 20.9900016 },
    { lat: 48.8875226, lng: 20.9892891 },
    { lat: 48.8882606, lng: 20.987679 },
    { lat: 48.8884551, lng: 20.9868226 },
    { lat: 48.888516, lng: 20.9861853 },
    { lat: 48.8886892, lng: 20.9857751 },
    { lat: 48.8890904, lng: 20.9856819 },
    { lat: 48.8897707, lng: 20.9853286 },
    { lat: 48.8900117, lng: 20.9850205 },
    { lat: 48.8902538, lng: 20.983943 },
    { lat: 48.8906487, lng: 20.9808798 },
    { lat: 48.89055, lng: 20.9801459 },
    { lat: 48.8906682, lng: 20.9780778 },
    { lat: 48.8908494, lng: 20.9766168 },
    { lat: 48.8908585, lng: 20.97564 },
    { lat: 48.8909125, lng: 20.9753351 },
    { lat: 48.8912006, lng: 20.9745226 },
    { lat: 48.8915681, lng: 20.9729128 },
    { lat: 48.8919793, lng: 20.97147 },
    { lat: 48.892152, lng: 20.9706062 },
    { lat: 48.892196, lng: 20.9686452 },
    { lat: 48.8921166, lng: 20.9675782 },
    { lat: 48.8923192, lng: 20.9675434 },
    { lat: 48.8930351, lng: 20.9676149 },
    { lat: 48.8934104, lng: 20.9677582 },
    { lat: 48.8935808, lng: 20.9678753 },
    { lat: 48.8936617, lng: 20.9654039 },
    { lat: 48.8936022, lng: 20.9646029 },
    { lat: 48.8929088, lng: 20.9647011 },
    { lat: 48.8931935, lng: 20.9636481 },
    { lat: 48.8929859, lng: 20.9629933 },
    { lat: 48.8923986, lng: 20.9615142 },
    { lat: 48.8907672, lng: 20.959035 },
    { lat: 48.8902461, lng: 20.9586276 },
    { lat: 48.8891071, lng: 20.9580615 },
    { lat: 48.8884923, lng: 20.957584 },
    { lat: 48.8879669, lng: 20.9570832 },
    { lat: 48.8878608, lng: 20.9569715 },
    { lat: 48.8873744, lng: 20.9569634 },
    { lat: 48.8864363, lng: 20.9572211 },
    { lat: 48.8860272, lng: 20.9572389 },
    { lat: 48.8834409, lng: 20.9582542 },
    { lat: 48.8822788, lng: 20.9596873 },
    { lat: 48.8814, lng: 20.9605075 },
    { lat: 48.8799921, lng: 20.9604756 },
    { lat: 48.8791482, lng: 20.9620408 },
    { lat: 48.8787382, lng: 20.9630048 },
    { lat: 48.8780743, lng: 20.9636582 },
    { lat: 48.8764751, lng: 20.9662251 },
    { lat: 48.875521, lng: 20.966783 },
    { lat: 48.8751831, lng: 20.9671121 },
    { lat: 48.8740023, lng: 20.968765 },
    { lat: 48.8735948, lng: 20.9695048 },
    { lat: 48.8732486, lng: 20.9698642 },
    { lat: 48.872755, lng: 20.9703768 },
    { lat: 48.872013, lng: 20.9714207 },
    { lat: 48.8714128, lng: 20.971698 },
    { lat: 48.871298, lng: 20.971751 },
    { lat: 48.8712697, lng: 20.9718848 },
    { lat: 48.8709569, lng: 20.9721883 },
    { lat: 48.8708701, lng: 20.9723511 },
    { lat: 48.8705372, lng: 20.9724978 },
    { lat: 48.8704145, lng: 20.9726301 },
    { lat: 48.8702346, lng: 20.9734809 },
    { lat: 48.8698774, lng: 20.9742876 },
    { lat: 48.8697481, lng: 20.9744197 },
    { lat: 48.8693563, lng: 20.9752523 },
    { lat: 48.8692498, lng: 20.9754785 },
    { lat: 48.8694079, lng: 20.9757657 },
    { lat: 48.8687188, lng: 20.976543 },
    { lat: 48.8684594, lng: 20.9772657 },
    { lat: 48.8678207, lng: 20.977963 },
    { lat: 48.8676457, lng: 20.9789634 },
    { lat: 48.8675265, lng: 20.9794083 },
    { lat: 48.8666354, lng: 20.9804651 },
    { lat: 48.8661761, lng: 20.9812101 },
    { lat: 48.8659365, lng: 20.981444 },
    { lat: 48.863121, lng: 20.9817701 },
    { lat: 48.8627645, lng: 20.9827314 },
    { lat: 48.8624702, lng: 20.9832597 },
    { lat: 48.8618543, lng: 20.9840948 },
    { lat: 48.8608214, lng: 20.9847179 },
    { lat: 48.8598652, lng: 20.9849734 },
    { lat: 48.859315, lng: 20.9853888 },
    { lat: 48.8586147, lng: 20.9860562 },
    { lat: 48.8582524, lng: 20.9866032 },
    { lat: 48.8580973, lng: 20.9869807 },
    { lat: 48.8573725, lng: 20.9876295 },
    { lat: 48.8567628, lng: 20.9884909 },
    { lat: 48.8557699, lng: 20.989025 },
    { lat: 48.8543048, lng: 20.9902301 },
    { lat: 48.8540813, lng: 20.9908862 },
    { lat: 48.8538098, lng: 20.9913218 },
    { lat: 48.853707, lng: 20.9915855 },
    { lat: 48.8535606, lng: 20.9919587 },
    { lat: 48.8539323, lng: 20.9922367 },
    { lat: 48.8540974, lng: 20.9925637 },
    { lat: 48.8552391, lng: 20.9940081 },
    { lat: 48.855944, lng: 20.9945636 },
    { lat: 48.856174, lng: 20.9948771 },
    { lat: 48.8563736, lng: 20.9958073 },
    { lat: 48.8563685, lng: 20.9968442 },
    { lat: 48.856441, lng: 20.9977452 },
    { lat: 48.8568357, lng: 20.9989272 },
    { lat: 48.8568743, lng: 20.9995169 },
    { lat: 48.8572051, lng: 20.9997856 },
    { lat: 48.8574341, lng: 21.0003119 },
    { lat: 48.8577558, lng: 21.0006761 },
    { lat: 48.8580438, lng: 21.0007128 },
    { lat: 48.8582525, lng: 21.0009619 },
    { lat: 48.8583998, lng: 21.0017085 },
    { lat: 48.8587089, lng: 21.0024128 },
    { lat: 48.8590138, lng: 21.0025755 },
    { lat: 48.8592182, lng: 21.0024292 },
    { lat: 48.8596698, lng: 21.0030162 },
    { lat: 48.8599881, lng: 21.0035897 },
    { lat: 48.8602852, lng: 21.0045745 },
    { lat: 48.8602758, lng: 21.0046682 },
    { lat: 48.8607113, lng: 21.0054891 },
    { lat: 48.8609136, lng: 21.0059871 },
    { lat: 48.8609411, lng: 21.0069265 },
    { lat: 48.8612577, lng: 21.0077176 },
    { lat: 48.8619206, lng: 21.0088813 },
    { lat: 48.862439, lng: 21.0095195 },
    { lat: 48.8624787, lng: 21.0097656 },
    { lat: 48.8627271, lng: 21.0098605 },
    { lat: 48.8622177, lng: 21.0105203 },
    { lat: 48.8620726, lng: 21.010626 },
    { lat: 48.8623317, lng: 21.0111437 },
    { lat: 48.8627317, lng: 21.0104718 },
    { lat: 48.8630636, lng: 21.0121768 },
    { lat: 48.8625986, lng: 21.0124461 },
    { lat: 48.8621782, lng: 21.012909 },
    { lat: 48.8626309, lng: 21.0144862 },
    { lat: 48.8632284, lng: 21.0152357 },
    { lat: 48.8635235, lng: 21.0158629 },
    { lat: 48.8634906, lng: 21.0162962 },
    { lat: 48.8635771, lng: 21.0169388 },
    { lat: 48.8634504, lng: 21.01754 },
    { lat: 48.8633483, lng: 21.0177926 },
    { lat: 48.8630914, lng: 21.0179188 },
    { lat: 48.862557, lng: 21.0190187 },
    { lat: 48.8624719, lng: 21.0195138 },
    { lat: 48.8626714, lng: 21.0205465 },
    { lat: 48.8625796, lng: 21.0218306 },
    { lat: 48.8625867, lng: 21.0233843 },
    { lat: 48.8624447, lng: 21.0272223 },
    { lat: 48.8625579, lng: 21.02858 },
    { lat: 48.8626273, lng: 21.0304894 },
    { lat: 48.8627753, lng: 21.031883 },
    { lat: 48.8626276, lng: 21.0331485 },
    { lat: 48.8626666, lng: 21.0342683 },
    { lat: 48.8624545, lng: 21.0354557 },
    { lat: 48.8624877, lng: 21.0363679 },
    { lat: 48.8624509, lng: 21.0371699 },
    { lat: 48.8628236, lng: 21.0391401 },
    { lat: 48.862779, lng: 21.0393916 },
    { lat: 48.8626237, lng: 21.0395337 },
    { lat: 48.8624922, lng: 21.0398467 },
    { lat: 48.8625086, lng: 21.0409693 },
    { lat: 48.862621, lng: 21.041679 },
    { lat: 48.863646, lng: 21.041228 },
    { lat: 48.86378, lng: 21.041124 },
    { lat: 48.864087, lng: 21.040736 },
    { lat: 48.865295, lng: 21.039973 },
    { lat: 48.865503, lng: 21.039956 },
    { lat: 48.865555, lng: 21.040002 },
    { lat: 48.866009, lng: 21.040038 },
    { lat: 48.866773, lng: 21.040159 },
    { lat: 48.866965, lng: 21.040415 },
    { lat: 48.86745, lng: 21.04059 },
    { lat: 48.867871, lng: 21.040941 },
    { lat: 48.867934, lng: 21.041031 },
    { lat: 48.868291, lng: 21.04139 },
    { lat: 48.868387, lng: 21.0418 },
    { lat: 48.868513, lng: 21.042162 },
    { lat: 48.868818, lng: 21.04267 },
    { lat: 48.869015, lng: 21.042885 },
    { lat: 48.869354, lng: 21.043096 },
    { lat: 48.869815, lng: 21.043167 },
    { lat: 48.870316, lng: 21.043194 },
    { lat: 48.87068, lng: 21.043159 },
    { lat: 48.870998, lng: 21.043094 },
    { lat: 48.871242, lng: 21.043005 },
    { lat: 48.87209, lng: 21.042888 },
    { lat: 48.872294, lng: 21.042896 },
    { lat: 48.872631, lng: 21.042803 },
    { lat: 48.872826, lng: 21.042872 },
    { lat: 48.87295, lng: 21.042854 },
    { lat: 48.8731, lng: 21.04291 },
    { lat: 48.873358, lng: 21.043115 },
    { lat: 48.873883, lng: 21.04313 },
    { lat: 48.873967, lng: 21.043006 },
    { lat: 48.874162, lng: 21.042915 },
    { lat: 48.874568, lng: 21.042459 },
    { lat: 48.874793, lng: 21.042116 },
    { lat: 48.874976, lng: 21.041983 },
    { lat: 48.875279, lng: 21.041976 },
    { lat: 48.875509, lng: 21.042149 },
    { lat: 48.875558, lng: 21.042233 },
    { lat: 48.875641, lng: 21.042335 },
    { lat: 48.875728, lng: 21.042525 },
    { lat: 48.876258, lng: 21.043236 },
    { lat: 48.876575, lng: 21.043764 },
    { lat: 48.87709, lng: 21.044513 },
    { lat: 48.877267, lng: 21.045032 },
    { lat: 48.877277, lng: 21.045108 },
    { lat: 48.877305, lng: 21.045421 },
    { lat: 48.877336, lng: 21.045704 },
    { lat: 48.877384, lng: 21.046014 },
    { lat: 48.877467, lng: 21.046422 },
    { lat: 48.87753, lng: 21.046873 },
    { lat: 48.877619, lng: 21.047673 },
    { lat: 48.87762, lng: 21.048031 },
    { lat: 48.877694, lng: 21.048805 },
    { lat: 48.877704, lng: 21.04908 },
    { lat: 48.877811, lng: 21.049623 },
    { lat: 48.877869, lng: 21.049787 },
    { lat: 48.877911, lng: 21.049968 },
    { lat: 48.87808, lng: 21.05062 },
    { lat: 48.878238, lng: 21.051062 },
    { lat: 48.878365, lng: 21.051291 },
    { lat: 48.878652, lng: 21.051651 },
    { lat: 48.878804, lng: 21.051797 },
    { lat: 48.879333, lng: 21.052205 },
    { lat: 48.879779, lng: 21.052317 },
    { lat: 48.879947, lng: 21.052325 },
    { lat: 48.880353, lng: 21.05216 },
    { lat: 48.880546, lng: 21.052045 },
    { lat: 48.881368, lng: 21.051639 },
    { lat: 48.881595, lng: 21.051367 },
    { lat: 48.881806, lng: 21.051058 },
    { lat: 48.881909, lng: 21.050809 },
    { lat: 48.881932, lng: 21.050176 },
    { lat: 48.8816761, lng: 21.0501979 },
    { lat: 48.8816949, lng: 21.0486706 },
    { lat: 48.8814601, lng: 21.0475092 },
    { lat: 48.8812413, lng: 21.0466714 },
    { lat: 48.8812247, lng: 21.0453656 },
    { lat: 48.8816667, lng: 21.0439057 },
    { lat: 48.8819553, lng: 21.0434059 },
    { lat: 48.8821667, lng: 21.0431565 },
    { lat: 48.8823735, lng: 21.0430098 },
    { lat: 48.8829642, lng: 21.0427978 },
    { lat: 48.8832078, lng: 21.042777 },
    { lat: 48.8835856, lng: 21.0430057 },
    { lat: 48.8840058, lng: 21.0433644 },
    { lat: 48.8845106, lng: 21.0439319 },
    { lat: 48.885083, lng: 21.0443642 },
    { lat: 48.8864419, lng: 21.0451739 },
    { lat: 48.8865728, lng: 21.045154 },
    { lat: 48.8866654, lng: 21.045057 },
    { lat: 48.8874233, lng: 21.043796 },
    { lat: 48.8877875, lng: 21.0425767 },
    { lat: 48.8880827, lng: 21.0412377 },
    { lat: 48.8881646, lng: 21.0406815 },
    { lat: 48.8881541, lng: 21.0400518 },
    { lat: 48.8882316, lng: 21.0391458 },
    { lat: 48.8882913, lng: 21.0389081 },
    { lat: 48.8895161, lng: 21.0368823 },
    { lat: 48.8897888, lng: 21.0361686 },
    { lat: 48.8900841, lng: 21.0355649 },
    { lat: 48.8903499, lng: 21.0352269 },
    { lat: 48.8904541, lng: 21.0349713 },
    { lat: 48.8912087, lng: 21.0337492 },
    { lat: 48.891685, lng: 21.0324126 },
    { lat: 48.8921154, lng: 21.03033 },
    { lat: 48.8923328, lng: 21.0285153 },
    { lat: 48.892374, lng: 21.0270944 },
    { lat: 48.8923468, lng: 21.026674 },
    { lat: 48.8921, lng: 21.0254 },
    { lat: 48.8920079, lng: 21.0250564 },
  ],
  Závadka: [
    { lat: 48.895183, lng: 20.658152 },
    { lat: 48.895146, lng: 20.657532 },
    { lat: 48.895119, lng: 20.657115 },
    { lat: 48.895085, lng: 20.656557 },
    { lat: 48.895066, lng: 20.656259 },
    { lat: 48.895016, lng: 20.655502 },
    { lat: 48.894989, lng: 20.655119 },
    { lat: 48.89492, lng: 20.654066 },
    { lat: 48.894896, lng: 20.653752 },
    { lat: 48.894839, lng: 20.652919 },
    { lat: 48.89481, lng: 20.652477 },
    { lat: 48.894787, lng: 20.652131 },
    { lat: 48.894762, lng: 20.651747 },
    { lat: 48.894749, lng: 20.651575 },
    { lat: 48.894738, lng: 20.65142 },
    { lat: 48.894716, lng: 20.65109 },
    { lat: 48.894696, lng: 20.650938 },
    { lat: 48.894633, lng: 20.650334 },
    { lat: 48.894606, lng: 20.650034 },
    { lat: 48.894561, lng: 20.649589 },
    { lat: 48.894471, lng: 20.648728 },
    { lat: 48.89438, lng: 20.64782 },
    { lat: 48.894337, lng: 20.647088 },
    { lat: 48.894273, lng: 20.645957 },
    { lat: 48.894255, lng: 20.645628 },
    { lat: 48.894211, lng: 20.644859 },
    { lat: 48.894175, lng: 20.644293 },
    { lat: 48.894206, lng: 20.644289 },
    { lat: 48.894349, lng: 20.64427 },
    { lat: 48.894666, lng: 20.644235 },
    { lat: 48.894955, lng: 20.644204 },
    { lat: 48.895055, lng: 20.644201 },
    { lat: 48.89527, lng: 20.64418 },
    { lat: 48.895482, lng: 20.644153 },
    { lat: 48.895998, lng: 20.644095 },
    { lat: 48.896603, lng: 20.644027 },
    { lat: 48.896876, lng: 20.644 },
    { lat: 48.896982, lng: 20.643989 },
    { lat: 48.897037, lng: 20.643981 },
    { lat: 48.897454, lng: 20.643932 },
    { lat: 48.897954, lng: 20.643873 },
    { lat: 48.898401, lng: 20.643828 },
    { lat: 48.898751, lng: 20.643789 },
    { lat: 48.898936, lng: 20.643761 },
    { lat: 48.89896, lng: 20.643764 },
    { lat: 48.89893, lng: 20.643611 },
    { lat: 48.898874, lng: 20.643297 },
    { lat: 48.898821, lng: 20.643009 },
    { lat: 48.89877, lng: 20.642869 },
    { lat: 48.898638, lng: 20.642477 },
    { lat: 48.89851, lng: 20.642111 },
    { lat: 48.898362, lng: 20.641673 },
    { lat: 48.898263, lng: 20.641435 },
    { lat: 48.898186, lng: 20.641254 },
    { lat: 48.898127, lng: 20.641107 },
    { lat: 48.898066, lng: 20.640969 },
    { lat: 48.898051, lng: 20.640848 },
    { lat: 48.898066, lng: 20.640838 },
    { lat: 48.898139, lng: 20.640826 },
    { lat: 48.898269, lng: 20.640794 },
    { lat: 48.898332, lng: 20.640811 },
    { lat: 48.898461, lng: 20.640835 },
    { lat: 48.898934, lng: 20.64111 },
    { lat: 48.899037, lng: 20.641165 },
    { lat: 48.899055, lng: 20.641178 },
    { lat: 48.899075, lng: 20.640961 },
    { lat: 48.899048, lng: 20.640859 },
    { lat: 48.899002, lng: 20.64069 },
    { lat: 48.898959, lng: 20.640534 },
    { lat: 48.898856, lng: 20.640148 },
    { lat: 48.898815, lng: 20.640166 },
    { lat: 48.898796, lng: 20.64015 },
    { lat: 48.898665, lng: 20.639776 },
    { lat: 48.89862, lng: 20.639656 },
    { lat: 48.898556, lng: 20.639614 },
    { lat: 48.898526, lng: 20.639524 },
    { lat: 48.89849, lng: 20.639454 },
    { lat: 48.89845, lng: 20.639393 },
    { lat: 48.898358, lng: 20.639286 },
    { lat: 48.898297, lng: 20.63936 },
    { lat: 48.898193, lng: 20.639119 },
    { lat: 48.898197, lng: 20.639015 },
    { lat: 48.898193, lng: 20.638956 },
    { lat: 48.898178, lng: 20.638909 },
    { lat: 48.898156, lng: 20.638883 },
    { lat: 48.898129, lng: 20.638871 },
    { lat: 48.897995, lng: 20.638876 },
    { lat: 48.897928, lng: 20.63883 },
    { lat: 48.897807, lng: 20.638752 },
    { lat: 48.897769, lng: 20.638653 },
    { lat: 48.897683, lng: 20.638606 },
    { lat: 48.897575, lng: 20.638553 },
    { lat: 48.897516, lng: 20.638306 },
    { lat: 48.897594, lng: 20.638197 },
    { lat: 48.897546, lng: 20.638104 },
    { lat: 48.897443, lng: 20.637925 },
    { lat: 48.89746, lng: 20.637802 },
    { lat: 48.897363, lng: 20.637639 },
    { lat: 48.897345, lng: 20.637521 },
    { lat: 48.897197, lng: 20.637248 },
    { lat: 48.897102, lng: 20.63707 },
    { lat: 48.897067, lng: 20.637039 },
    { lat: 48.897034, lng: 20.637008 },
    { lat: 48.896923, lng: 20.636952 },
    { lat: 48.896677, lng: 20.636665 },
    { lat: 48.896617, lng: 20.636596 },
    { lat: 48.896563, lng: 20.636626 },
    { lat: 48.896499, lng: 20.636676 },
    { lat: 48.896467, lng: 20.63658 },
    { lat: 48.896388, lng: 20.636531 },
    { lat: 48.896385, lng: 20.636436 },
    { lat: 48.896332, lng: 20.636184 },
    { lat: 48.896275, lng: 20.636114 },
    { lat: 48.896197, lng: 20.635846 },
    { lat: 48.896164, lng: 20.635787 },
    { lat: 48.896088, lng: 20.635689 },
    { lat: 48.896205, lng: 20.635398 },
    { lat: 48.896224, lng: 20.635339 },
    { lat: 48.896301, lng: 20.63493 },
    { lat: 48.896324, lng: 20.634811 },
    { lat: 48.896331, lng: 20.634626 },
    { lat: 48.896341, lng: 20.634422 },
    { lat: 48.896331, lng: 20.634269 },
    { lat: 48.896321, lng: 20.634061 },
    { lat: 48.896338, lng: 20.633827 },
    { lat: 48.896345, lng: 20.633636 },
    { lat: 48.896342, lng: 20.633584 },
    { lat: 48.896302, lng: 20.633466 },
    { lat: 48.896267, lng: 20.633323 },
    { lat: 48.896294, lng: 20.633085 },
    { lat: 48.896282, lng: 20.632802 },
    { lat: 48.896269, lng: 20.63274 },
    { lat: 48.89622, lng: 20.63259 },
    { lat: 48.896169, lng: 20.632433 },
    { lat: 48.896091, lng: 20.6322 },
    { lat: 48.895991, lng: 20.631908 },
    { lat: 48.895926, lng: 20.631706 },
    { lat: 48.895909, lng: 20.631676 },
    { lat: 48.895854, lng: 20.631602 },
    { lat: 48.895808, lng: 20.631547 },
    { lat: 48.895746, lng: 20.631491 },
    { lat: 48.895686, lng: 20.631378 },
    { lat: 48.895615, lng: 20.631296 },
    { lat: 48.895533, lng: 20.631211 },
    { lat: 48.895466, lng: 20.631128 },
    { lat: 48.895387, lng: 20.631004 },
    { lat: 48.895308, lng: 20.63091 },
    { lat: 48.895241, lng: 20.630831 },
    { lat: 48.89519, lng: 20.630776 },
    { lat: 48.895139, lng: 20.630725 },
    { lat: 48.89504, lng: 20.630596 },
    { lat: 48.894961, lng: 20.630448 },
    { lat: 48.894895, lng: 20.63032 },
    { lat: 48.894842, lng: 20.630204 },
    { lat: 48.894813, lng: 20.630056 },
    { lat: 48.894779, lng: 20.629901 },
    { lat: 48.894744, lng: 20.629706 },
    { lat: 48.894608, lng: 20.629343 },
    { lat: 48.894524, lng: 20.629256 },
    { lat: 48.894507, lng: 20.629164 },
    { lat: 48.894546, lng: 20.628845 },
    { lat: 48.894612, lng: 20.628737 },
    { lat: 48.894634, lng: 20.628463 },
    { lat: 48.894571, lng: 20.628126 },
    { lat: 48.89456, lng: 20.627785 },
    { lat: 48.894615, lng: 20.627628 },
    { lat: 48.894609, lng: 20.627528 },
    { lat: 48.894546, lng: 20.627429 },
    { lat: 48.894443, lng: 20.627266 },
    { lat: 48.894342, lng: 20.627172 },
    { lat: 48.894294, lng: 20.626914 },
    { lat: 48.894325, lng: 20.626756 },
    { lat: 48.894326, lng: 20.626587 },
    { lat: 48.894392, lng: 20.626223 },
    { lat: 48.894493, lng: 20.62612 },
    { lat: 48.894532, lng: 20.62612 },
    { lat: 48.894654, lng: 20.62602 },
    { lat: 48.894711, lng: 20.625821 },
    { lat: 48.894704, lng: 20.625654 },
    { lat: 48.894697, lng: 20.625482 },
    { lat: 48.894685, lng: 20.625282 },
    { lat: 48.894667, lng: 20.625181 },
    { lat: 48.894642, lng: 20.625041 },
    { lat: 48.894602, lng: 20.62482 },
    { lat: 48.894583, lng: 20.624769 },
    { lat: 48.894547, lng: 20.624697 },
    { lat: 48.894545, lng: 20.624634 },
    { lat: 48.894527, lng: 20.624409 },
    { lat: 48.894525, lng: 20.624381 },
    { lat: 48.894559, lng: 20.624318 },
    { lat: 48.894686, lng: 20.624198 },
    { lat: 48.894846, lng: 20.624001 },
    { lat: 48.894952, lng: 20.62386 },
    { lat: 48.894995, lng: 20.623693 },
    { lat: 48.895104, lng: 20.623563 },
    { lat: 48.895183, lng: 20.623273 },
    { lat: 48.895212, lng: 20.623168 },
    { lat: 48.895263, lng: 20.62296 },
    { lat: 48.895271, lng: 20.622843 },
    { lat: 48.895273, lng: 20.622737 },
    { lat: 48.895272, lng: 20.62262 },
    { lat: 48.895258, lng: 20.622322 },
    { lat: 48.895252, lng: 20.622216 },
    { lat: 48.895233, lng: 20.622095 },
    { lat: 48.895248, lng: 20.621984 },
    { lat: 48.89527, lng: 20.621922 },
    { lat: 48.895309, lng: 20.621686 },
    { lat: 48.895321, lng: 20.621623 },
    { lat: 48.89533, lng: 20.621521 },
    { lat: 48.895333, lng: 20.621418 },
    { lat: 48.895326, lng: 20.621383 },
    { lat: 48.895318, lng: 20.621347 },
    { lat: 48.895281, lng: 20.621161 },
    { lat: 48.895239, lng: 20.620977 },
    { lat: 48.895154, lng: 20.620953 },
    { lat: 48.894946, lng: 20.62109 },
    { lat: 48.8948, lng: 20.621131 },
    { lat: 48.89476, lng: 20.62114 },
    { lat: 48.894657, lng: 20.621085 },
    { lat: 48.894646, lng: 20.620888 },
    { lat: 48.894672, lng: 20.620625 },
    { lat: 48.894734, lng: 20.620462 },
    { lat: 48.894719, lng: 20.620234 },
    { lat: 48.894678, lng: 20.62 },
    { lat: 48.894651, lng: 20.619683 },
    { lat: 48.89448, lng: 20.619632 },
    { lat: 48.894374, lng: 20.619652 },
    { lat: 48.894247, lng: 20.619514 },
    { lat: 48.894162, lng: 20.619474 },
    { lat: 48.894086, lng: 20.619362 },
    { lat: 48.893997, lng: 20.61923 },
    { lat: 48.893946, lng: 20.618847 },
    { lat: 48.89392, lng: 20.61858 },
    { lat: 48.893785, lng: 20.618536 },
    { lat: 48.893741, lng: 20.618394 },
    { lat: 48.893797, lng: 20.618259 },
    { lat: 48.893835, lng: 20.618152 },
    { lat: 48.893813, lng: 20.618064 },
    { lat: 48.893797, lng: 20.618002 },
    { lat: 48.893714, lng: 20.617938 },
    { lat: 48.893647, lng: 20.617892 },
    { lat: 48.893585, lng: 20.617739 },
    { lat: 48.893563, lng: 20.617576 },
    { lat: 48.893578, lng: 20.617391 },
    { lat: 48.893501, lng: 20.617269 },
    { lat: 48.893435, lng: 20.617202 },
    { lat: 48.893334, lng: 20.617106 },
    { lat: 48.893276, lng: 20.617052 },
    { lat: 48.893214, lng: 20.61687 },
    { lat: 48.893133, lng: 20.616632 },
    { lat: 48.89315, lng: 20.616296 },
    { lat: 48.893147, lng: 20.616037 },
    { lat: 48.893021, lng: 20.615833 },
    { lat: 48.89291, lng: 20.61574 },
    { lat: 48.892744, lng: 20.615603 },
    { lat: 48.892674, lng: 20.61525 },
    { lat: 48.892767, lng: 20.615014 },
    { lat: 48.892798, lng: 20.614713 },
    { lat: 48.892741, lng: 20.614574 },
    { lat: 48.892723, lng: 20.614298 },
    { lat: 48.892635, lng: 20.614134 },
    { lat: 48.892528, lng: 20.613945 },
    { lat: 48.892462, lng: 20.613695 },
    { lat: 48.892508, lng: 20.613402 },
    { lat: 48.892479, lng: 20.61292 },
    { lat: 48.892361, lng: 20.6125 },
    { lat: 48.892246, lng: 20.6123 },
    { lat: 48.892222, lng: 20.612066 },
    { lat: 48.892168, lng: 20.611552 },
    { lat: 48.892001, lng: 20.611451 },
    { lat: 48.892, lng: 20.611266 },
    { lat: 48.891985, lng: 20.611064 },
    { lat: 48.891889, lng: 20.610876 },
    { lat: 48.89186, lng: 20.610859 },
    { lat: 48.891736, lng: 20.610838 },
    { lat: 48.891632, lng: 20.610776 },
    { lat: 48.891581, lng: 20.610648 },
    { lat: 48.891583, lng: 20.610513 },
    { lat: 48.891483, lng: 20.61042 },
    { lat: 48.891426, lng: 20.610285 },
    { lat: 48.891395, lng: 20.610093 },
    { lat: 48.891353, lng: 20.609819 },
    { lat: 48.891465, lng: 20.609612 },
    { lat: 48.89152, lng: 20.609729 },
    { lat: 48.891618, lng: 20.609624 },
    { lat: 48.891452, lng: 20.609254 },
    { lat: 48.89135, lng: 20.60911 },
    { lat: 48.891294, lng: 20.60896 },
    { lat: 48.891192, lng: 20.608802 },
    { lat: 48.891148, lng: 20.60867 },
    { lat: 48.891157, lng: 20.608522 },
    { lat: 48.891066, lng: 20.608268 },
    { lat: 48.890958, lng: 20.607971 },
    { lat: 48.890861, lng: 20.607697 },
    { lat: 48.890759, lng: 20.607643 },
    { lat: 48.890694, lng: 20.607492 },
    { lat: 48.890653, lng: 20.607326 },
    { lat: 48.890537, lng: 20.607155 },
    { lat: 48.890425, lng: 20.607168 },
    { lat: 48.890343, lng: 20.607053 },
    { lat: 48.890235, lng: 20.606874 },
    { lat: 48.890128, lng: 20.606688 },
    { lat: 48.890002, lng: 20.606456 },
    { lat: 48.889849, lng: 20.606365 },
    { lat: 48.889827, lng: 20.606144 },
    { lat: 48.88975, lng: 20.606085 },
    { lat: 48.889716, lng: 20.606104 },
    { lat: 48.889596, lng: 20.606187 },
    { lat: 48.889528, lng: 20.606155 },
    { lat: 48.889456, lng: 20.606135 },
    { lat: 48.889401, lng: 20.60602 },
    { lat: 48.889414, lng: 20.605757 },
    { lat: 48.889374, lng: 20.605624 },
    { lat: 48.889247, lng: 20.605529 },
    { lat: 48.889193, lng: 20.605382 },
    { lat: 48.889187, lng: 20.605191 },
    { lat: 48.889221, lng: 20.60496 },
    { lat: 48.889095, lng: 20.604778 },
    { lat: 48.888948, lng: 20.604848 },
    { lat: 48.888822, lng: 20.60481 },
    { lat: 48.888681, lng: 20.604608 },
    { lat: 48.888547, lng: 20.604427 },
    { lat: 48.888492, lng: 20.604267 },
    { lat: 48.888404, lng: 20.604003 },
    { lat: 48.888327, lng: 20.603706 },
    { lat: 48.88825, lng: 20.6034 },
    { lat: 48.888194, lng: 20.603145 },
    { lat: 48.888014, lng: 20.603016 },
    { lat: 48.888005, lng: 20.602996 },
    { lat: 48.887998, lng: 20.602979 },
    { lat: 48.887965, lng: 20.603004 },
    { lat: 48.887975, lng: 20.603043 },
    { lat: 48.887957, lng: 20.603023 },
    { lat: 48.887813, lng: 20.602871 },
    { lat: 48.88766, lng: 20.602387 },
    { lat: 48.887529, lng: 20.601953 },
    { lat: 48.887411, lng: 20.601786 },
    { lat: 48.887405, lng: 20.601676 },
    { lat: 48.887342, lng: 20.601532 },
    { lat: 48.887283, lng: 20.601398 },
    { lat: 48.887132, lng: 20.601054 },
    { lat: 48.887033, lng: 20.601006 },
    { lat: 48.886955, lng: 20.60086 },
    { lat: 48.886908, lng: 20.600696 },
    { lat: 48.886801, lng: 20.600552 },
    { lat: 48.886728, lng: 20.600504 },
    { lat: 48.886575, lng: 20.600403 },
    { lat: 48.886501, lng: 20.600211 },
    { lat: 48.886418, lng: 20.600128 },
    { lat: 48.886237, lng: 20.599957 },
    { lat: 48.886168, lng: 20.599795 },
    { lat: 48.886075, lng: 20.599708 },
    { lat: 48.886, lng: 20.599379 },
    { lat: 48.885809, lng: 20.59908 },
    { lat: 48.885762, lng: 20.599006 },
    { lat: 48.885709, lng: 20.598927 },
    { lat: 48.885657, lng: 20.598849 },
    { lat: 48.885607, lng: 20.598772 },
    { lat: 48.885557, lng: 20.598691 },
    { lat: 48.885493, lng: 20.598657 },
    { lat: 48.885452, lng: 20.598627 },
    { lat: 48.885373, lng: 20.598544 },
    { lat: 48.885285, lng: 20.598462 },
    { lat: 48.885225, lng: 20.598408 },
    { lat: 48.885099, lng: 20.598329 },
    { lat: 48.884993, lng: 20.598265 },
    { lat: 48.884913, lng: 20.598223 },
    { lat: 48.884909, lng: 20.598074 },
    { lat: 48.884949, lng: 20.597996 },
    { lat: 48.885021, lng: 20.597846 },
    { lat: 48.885019, lng: 20.597699 },
    { lat: 48.884958, lng: 20.597478 },
    { lat: 48.884877, lng: 20.597361 },
    { lat: 48.884782, lng: 20.597172 },
    { lat: 48.884636, lng: 20.597018 },
    { lat: 48.884553, lng: 20.596834 },
    { lat: 48.884428, lng: 20.596647 },
    { lat: 48.884385, lng: 20.596408 },
    { lat: 48.884237, lng: 20.596053 },
    { lat: 48.884033, lng: 20.595981 },
    { lat: 48.883994, lng: 20.595946 },
    { lat: 48.883957, lng: 20.595897 },
    { lat: 48.883906, lng: 20.595735 },
    { lat: 48.883884, lng: 20.595704 },
    { lat: 48.88381, lng: 20.595636 },
    { lat: 48.883703, lng: 20.595577 },
    { lat: 48.883528, lng: 20.595564 },
    { lat: 48.883431, lng: 20.595553 },
    { lat: 48.883386, lng: 20.595547 },
    { lat: 48.883207, lng: 20.595586 },
    { lat: 48.883122, lng: 20.595489 },
    { lat: 48.883124, lng: 20.595355 },
    { lat: 48.883108, lng: 20.595289 },
    { lat: 48.883073, lng: 20.595189 },
    { lat: 48.883001, lng: 20.595017 },
    { lat: 48.882927, lng: 20.594928 },
    { lat: 48.882916, lng: 20.594805 },
    { lat: 48.882824, lng: 20.594842 },
    { lat: 48.88279, lng: 20.594812 },
    { lat: 48.882697, lng: 20.594735 },
    { lat: 48.882579, lng: 20.594634 },
    { lat: 48.882582, lng: 20.594461 },
    { lat: 48.88261, lng: 20.594328 },
    { lat: 48.88261, lng: 20.594278 },
    { lat: 48.8826, lng: 20.594225 },
    { lat: 48.882531, lng: 20.594187 },
    { lat: 48.882542, lng: 20.594032 },
    { lat: 48.882549, lng: 20.593881 },
    { lat: 48.882484, lng: 20.593855 },
    { lat: 48.882486, lng: 20.593759 },
    { lat: 48.882516, lng: 20.593653 },
    { lat: 48.882499, lng: 20.593556 },
    { lat: 48.882472, lng: 20.593443 },
    { lat: 48.882389, lng: 20.59344 },
    { lat: 48.882423, lng: 20.593265 },
    { lat: 48.882419, lng: 20.593165 },
    { lat: 48.882464, lng: 20.593085 },
    { lat: 48.882441, lng: 20.593055 },
    { lat: 48.882393, lng: 20.592988 },
    { lat: 48.8824, lng: 20.59289 },
    { lat: 48.882479, lng: 20.592851 },
    { lat: 48.882433, lng: 20.592727 },
    { lat: 48.882328, lng: 20.592754 },
    { lat: 48.882304, lng: 20.592668 },
    { lat: 48.882257, lng: 20.592623 },
    { lat: 48.88223, lng: 20.5924 },
    { lat: 48.882221, lng: 20.592354 },
    { lat: 48.882175, lng: 20.592299 },
    { lat: 48.882129, lng: 20.592242 },
    { lat: 48.882091, lng: 20.592185 },
    { lat: 48.882008, lng: 20.592032 },
    { lat: 48.882053, lng: 20.591957 },
    { lat: 48.882029, lng: 20.591889 },
    { lat: 48.881966, lng: 20.591789 },
    { lat: 48.881892, lng: 20.591669 },
    { lat: 48.881838, lng: 20.591567 },
    { lat: 48.881778, lng: 20.591427 },
    { lat: 48.881695, lng: 20.591203 },
    { lat: 48.881673, lng: 20.591019 },
    { lat: 48.881821, lng: 20.590969 },
    { lat: 48.881848, lng: 20.590843 },
    { lat: 48.881618, lng: 20.590622 },
    { lat: 48.881658, lng: 20.590538 },
    { lat: 48.881655, lng: 20.590407 },
    { lat: 48.881567, lng: 20.590305 },
    { lat: 48.881546, lng: 20.590073 },
    { lat: 48.881481, lng: 20.589972 },
    { lat: 48.881542, lng: 20.589775 },
    { lat: 48.881502, lng: 20.589485 },
    { lat: 48.881505, lng: 20.589349 },
    { lat: 48.881445, lng: 20.589107 },
    { lat: 48.881553, lng: 20.589028 },
    { lat: 48.881566, lng: 20.588942 },
    { lat: 48.881462, lng: 20.58884 },
    { lat: 48.881407, lng: 20.588673 },
    { lat: 48.881315, lng: 20.58856 },
    { lat: 48.881373, lng: 20.588336 },
    { lat: 48.881121, lng: 20.588122 },
    { lat: 48.881133, lng: 20.587756 },
    { lat: 48.881055, lng: 20.587687 },
    { lat: 48.880967, lng: 20.587665 },
    { lat: 48.880883, lng: 20.587484 },
    { lat: 48.880719, lng: 20.587255 },
    { lat: 48.880674, lng: 20.586686 },
    { lat: 48.880478, lng: 20.586539 },
    { lat: 48.880476, lng: 20.586454 },
    { lat: 48.880549, lng: 20.586415 },
    { lat: 48.880582, lng: 20.586328 },
    { lat: 48.880516, lng: 20.58616 },
    { lat: 48.880405, lng: 20.58611 },
    { lat: 48.880312, lng: 20.586062 },
    { lat: 48.880309, lng: 20.585829 },
    { lat: 48.880326, lng: 20.585768 },
    { lat: 48.880367, lng: 20.585539 },
    { lat: 48.880238, lng: 20.585451 },
    { lat: 48.880223, lng: 20.585581 },
    { lat: 48.880129, lng: 20.585348 },
    { lat: 48.880137, lng: 20.585227 },
    { lat: 48.880048, lng: 20.585236 },
    { lat: 48.879972, lng: 20.585207 },
    { lat: 48.879915, lng: 20.585215 },
    { lat: 48.879892, lng: 20.585107 },
    { lat: 48.879894, lng: 20.585087 },
    { lat: 48.879902, lng: 20.584971 },
    { lat: 48.87977, lng: 20.58488 },
    { lat: 48.879652, lng: 20.584706 },
    { lat: 48.879618, lng: 20.584686 },
    { lat: 48.879499, lng: 20.584625 },
    { lat: 48.879429, lng: 20.584598 },
    { lat: 48.879384, lng: 20.58464 },
    { lat: 48.879345, lng: 20.584718 },
    { lat: 48.879326, lng: 20.584777 },
    { lat: 48.879213, lng: 20.584788 },
    { lat: 48.879171, lng: 20.584847 },
    { lat: 48.879113, lng: 20.584788 },
    { lat: 48.879061, lng: 20.58483 },
    { lat: 48.878974, lng: 20.584784 },
    { lat: 48.878949, lng: 20.584717 },
    { lat: 48.878893, lng: 20.58469 },
    { lat: 48.878763, lng: 20.584634 },
    { lat: 48.878705, lng: 20.584686 },
    { lat: 48.878625, lng: 20.584645 },
    { lat: 48.878551, lng: 20.584691 },
    { lat: 48.878465, lng: 20.584713 },
    { lat: 48.878367, lng: 20.584669 },
    { lat: 48.878317, lng: 20.584673 },
    { lat: 48.878271, lng: 20.584621 },
    { lat: 48.878191, lng: 20.584633 },
    { lat: 48.878074, lng: 20.584681 },
    { lat: 48.878024, lng: 20.58467 },
    { lat: 48.877953, lng: 20.584653 },
    { lat: 48.877901, lng: 20.584604 },
    { lat: 48.877851, lng: 20.584709 },
    { lat: 48.877781, lng: 20.584793 },
    { lat: 48.877567, lng: 20.584663 },
    { lat: 48.877425, lng: 20.584585 },
    { lat: 48.877298, lng: 20.584514 },
    { lat: 48.877172, lng: 20.584419 },
    { lat: 48.877049, lng: 20.584311 },
    { lat: 48.87689, lng: 20.584158 },
    { lat: 48.876755, lng: 20.584058 },
    { lat: 48.876671, lng: 20.583993 },
    { lat: 48.876592, lng: 20.583864 },
    { lat: 48.876515, lng: 20.583895 },
    { lat: 48.876453, lng: 20.583666 },
    { lat: 48.876396, lng: 20.583556 },
    { lat: 48.876336, lng: 20.583483 },
    { lat: 48.876345, lng: 20.583434 },
    { lat: 48.876354, lng: 20.583364 },
    { lat: 48.876353, lng: 20.583326 },
    { lat: 48.876154, lng: 20.583238 },
    { lat: 48.876028, lng: 20.583189 },
    { lat: 48.875999, lng: 20.583139 },
    { lat: 48.87596, lng: 20.583054 },
    { lat: 48.875902, lng: 20.582916 },
    { lat: 48.875854, lng: 20.582927 },
    { lat: 48.875822, lng: 20.582897 },
    { lat: 48.875688, lng: 20.582779 },
    { lat: 48.875651, lng: 20.582723 },
    { lat: 48.875634, lng: 20.582623 },
    { lat: 48.875563, lng: 20.582543 },
    { lat: 48.875534, lng: 20.582427 },
    { lat: 48.875459, lng: 20.58244 },
    { lat: 48.875388, lng: 20.582506 },
    { lat: 48.875281, lng: 20.582464 },
    { lat: 48.87521, lng: 20.582479 },
    { lat: 48.875155, lng: 20.582379 },
    { lat: 48.875066, lng: 20.582276 },
    { lat: 48.875017, lng: 20.58229 },
    { lat: 48.874954, lng: 20.582233 },
    { lat: 48.874866, lng: 20.582256 },
    { lat: 48.874822, lng: 20.582236 },
    { lat: 48.874775, lng: 20.582268 },
    { lat: 48.874727, lng: 20.582242 },
    { lat: 48.874708, lng: 20.5822 },
    { lat: 48.874645, lng: 20.582077 },
    { lat: 48.874559, lng: 20.581944 },
    { lat: 48.8745, lng: 20.581978 },
    { lat: 48.874399, lng: 20.581888 },
    { lat: 48.874207, lng: 20.582037 },
    { lat: 48.874141, lng: 20.582002 },
    { lat: 48.874131, lng: 20.581997 },
    { lat: 48.874131, lng: 20.582007 },
    { lat: 48.874132, lng: 20.582035 },
    { lat: 48.874144, lng: 20.582052 },
    { lat: 48.874274, lng: 20.58233 },
    { lat: 48.874299, lng: 20.582398 },
    { lat: 48.874336, lng: 20.582415 },
    { lat: 48.874368, lng: 20.582419 },
    { lat: 48.874597, lng: 20.58253 },
    { lat: 48.874592, lng: 20.582547 },
    { lat: 48.874558, lng: 20.582629 },
    { lat: 48.874506, lng: 20.582852 },
    { lat: 48.874479, lng: 20.582976 },
    { lat: 48.874472, lng: 20.583004 },
    { lat: 48.874205, lng: 20.58291 },
    { lat: 48.874177, lng: 20.582994 },
    { lat: 48.874043, lng: 20.582925 },
    { lat: 48.87396, lng: 20.58288 },
    { lat: 48.873779, lng: 20.582783 },
    { lat: 48.873502, lng: 20.582575 },
    { lat: 48.873105, lng: 20.583467 },
    { lat: 48.873156, lng: 20.583881 },
    { lat: 48.873245, lng: 20.584122 },
    { lat: 48.873524, lng: 20.584918 },
    { lat: 48.873439, lng: 20.585153 },
    { lat: 48.873224, lng: 20.585271 },
    { lat: 48.873211, lng: 20.585291 },
    { lat: 48.873413, lng: 20.58558 },
    { lat: 48.87345, lng: 20.585629 },
    { lat: 48.873548, lng: 20.585762 },
    { lat: 48.873613, lng: 20.585865 },
    { lat: 48.873783, lng: 20.58615 },
    { lat: 48.873869, lng: 20.586691 },
    { lat: 48.873842, lng: 20.586998 },
    { lat: 48.873896, lng: 20.588008 },
    { lat: 48.873821, lng: 20.588316 },
    { lat: 48.873848, lng: 20.58851 },
    { lat: 48.873952, lng: 20.588732 },
    { lat: 48.873929, lng: 20.588734 },
    { lat: 48.873332, lng: 20.588725 },
    { lat: 48.87285, lng: 20.588719 },
    { lat: 48.872399, lng: 20.588697 },
    { lat: 48.872113, lng: 20.588512 },
    { lat: 48.871858, lng: 20.588442 },
    { lat: 48.871585, lng: 20.588199 },
    { lat: 48.871353, lng: 20.587824 },
    { lat: 48.870971, lng: 20.587586 },
    { lat: 48.870832, lng: 20.587496 },
    { lat: 48.871129, lng: 20.587116 },
    { lat: 48.871184, lng: 20.586847 },
    { lat: 48.871235, lng: 20.586458 },
    { lat: 48.871247, lng: 20.585985 },
    { lat: 48.871234, lng: 20.585815 },
    { lat: 48.8712, lng: 20.585374 },
    { lat: 48.87126, lng: 20.584863 },
    { lat: 48.871294, lng: 20.584594 },
    { lat: 48.871298, lng: 20.584315 },
    { lat: 48.871274, lng: 20.584159 },
    { lat: 48.871229, lng: 20.583948 },
    { lat: 48.871062, lng: 20.583513 },
    { lat: 48.870945, lng: 20.58316 },
    { lat: 48.870877, lng: 20.582921 },
    { lat: 48.870773, lng: 20.582601 },
    { lat: 48.870569, lng: 20.582016 },
    { lat: 48.870483, lng: 20.581839 },
    { lat: 48.870268, lng: 20.581454 },
    { lat: 48.869782, lng: 20.580588 },
    { lat: 48.86952, lng: 20.580114 },
    { lat: 48.869371, lng: 20.579817 },
    { lat: 48.869266, lng: 20.5797 },
    { lat: 48.869239, lng: 20.579666 },
    { lat: 48.869041, lng: 20.579509 },
    { lat: 48.868892, lng: 20.579385 },
    { lat: 48.868645, lng: 20.579314 },
    { lat: 48.86855, lng: 20.579254 },
    { lat: 48.868366, lng: 20.579133 },
    { lat: 48.868159, lng: 20.579008 },
    { lat: 48.867748, lng: 20.578727 },
    { lat: 48.867462, lng: 20.578878 },
    { lat: 48.867088, lng: 20.578918 },
    { lat: 48.866951, lng: 20.578998 },
    { lat: 48.866894, lng: 20.578998 },
    { lat: 48.86687, lng: 20.578997 },
    { lat: 48.866591, lng: 20.579193 },
    { lat: 48.866411, lng: 20.579337 },
    { lat: 48.866289, lng: 20.579443 },
    { lat: 48.866082, lng: 20.579576 },
    { lat: 48.865919, lng: 20.579686 },
    { lat: 48.865777, lng: 20.579761 },
    { lat: 48.865708, lng: 20.579798 },
    { lat: 48.865458, lng: 20.579908 },
    { lat: 48.865363, lng: 20.579951 },
    { lat: 48.865276, lng: 20.58002 },
    { lat: 48.865113, lng: 20.580147 },
    { lat: 48.864987, lng: 20.58023 },
    { lat: 48.865011, lng: 20.580091 },
    { lat: 48.864967, lng: 20.579955 },
    { lat: 48.864865, lng: 20.579843 },
    { lat: 48.864841, lng: 20.5798 },
    { lat: 48.864826, lng: 20.579774 },
    { lat: 48.864747, lng: 20.579594 },
    { lat: 48.864707, lng: 20.579463 },
    { lat: 48.864669, lng: 20.579257 },
    { lat: 48.86466, lng: 20.579047 },
    { lat: 48.864787, lng: 20.578494 },
    { lat: 48.864792, lng: 20.578473 },
    { lat: 48.864881, lng: 20.578119 },
    { lat: 48.864928, lng: 20.577842 },
    { lat: 48.864889, lng: 20.577855 },
    { lat: 48.864819, lng: 20.577879 },
    { lat: 48.864771, lng: 20.577896 },
    { lat: 48.864322, lng: 20.578058 },
    { lat: 48.863761, lng: 20.578256 },
    { lat: 48.863211, lng: 20.578454 },
    { lat: 48.863004, lng: 20.578527 },
    { lat: 48.862273, lng: 20.578794 },
    { lat: 48.861673, lng: 20.579018 },
    { lat: 48.861164, lng: 20.579212 },
    { lat: 48.860844, lng: 20.579329 },
    { lat: 48.86055, lng: 20.579462 },
    { lat: 48.860029, lng: 20.579691 },
    { lat: 48.859841, lng: 20.579773 },
    { lat: 48.859443, lng: 20.579877 },
    { lat: 48.859295, lng: 20.579917 },
    { lat: 48.859085, lng: 20.579994 },
    { lat: 48.858378, lng: 20.580244 },
    { lat: 48.858173, lng: 20.580318 },
    { lat: 48.857398, lng: 20.580628 },
    { lat: 48.857319, lng: 20.580607 },
    { lat: 48.857272, lng: 20.580599 },
    { lat: 48.856866, lng: 20.580522 },
    { lat: 48.856375, lng: 20.580442 },
    { lat: 48.856056, lng: 20.580384 },
    { lat: 48.855906, lng: 20.580372 },
    { lat: 48.855861, lng: 20.580527 },
    { lat: 48.85581, lng: 20.580702 },
    { lat: 48.8557, lng: 20.581111 },
    { lat: 48.855662, lng: 20.581249 },
    { lat: 48.85565, lng: 20.581472 },
    { lat: 48.855672, lng: 20.581654 },
    { lat: 48.855711, lng: 20.582013 },
    { lat: 48.8557, lng: 20.582264 },
    { lat: 48.855651, lng: 20.582673 },
    { lat: 48.855472, lng: 20.58328 },
    { lat: 48.855894, lng: 20.583744 },
    { lat: 48.855982, lng: 20.58384 },
    { lat: 48.856197, lng: 20.584075 },
    { lat: 48.856001, lng: 20.584354 },
    { lat: 48.855745, lng: 20.584716 },
    { lat: 48.855679, lng: 20.584805 },
    { lat: 48.855388, lng: 20.584518 },
    { lat: 48.855431, lng: 20.584268 },
    { lat: 48.855056, lng: 20.584259 },
    { lat: 48.85487, lng: 20.584452 },
    { lat: 48.854812, lng: 20.584479 },
    { lat: 48.854718, lng: 20.584536 },
    { lat: 48.854722, lng: 20.584889 },
    { lat: 48.854718, lng: 20.585401 },
    { lat: 48.854786, lng: 20.585636 },
    { lat: 48.85481, lng: 20.586043 },
    { lat: 48.85466, lng: 20.586109 },
    { lat: 48.854482, lng: 20.586197 },
    { lat: 48.854417, lng: 20.586253 },
    { lat: 48.854418, lng: 20.585854 },
    { lat: 48.854284, lng: 20.585427 },
    { lat: 48.854215, lng: 20.585426 },
    { lat: 48.854043, lng: 20.585097 },
    { lat: 48.853869, lng: 20.585208 },
    { lat: 48.8541611, lng: 20.5913013 },
    { lat: 48.8539356, lng: 20.5916648 },
    { lat: 48.8538281, lng: 20.5920804 },
    { lat: 48.8537972, lng: 20.5924673 },
    { lat: 48.8538422, lng: 20.5927109 },
    { lat: 48.8539273, lng: 20.5930041 },
    { lat: 48.8545067, lng: 20.5938242 },
    { lat: 48.8549128, lng: 20.5954529 },
    { lat: 48.8551659, lng: 20.595633 },
    { lat: 48.8556635, lng: 20.5955959 },
    { lat: 48.8564747, lng: 20.5961993 },
    { lat: 48.8560761, lng: 20.5970933 },
    { lat: 48.8563165, lng: 20.5975697 },
    { lat: 48.8566747, lng: 20.5970806 },
    { lat: 48.8570954, lng: 20.5974697 },
    { lat: 48.857422, lng: 20.5992368 },
    { lat: 48.8574503, lng: 20.6009638 },
    { lat: 48.8574885, lng: 20.6009935 },
    { lat: 48.8575229, lng: 20.6015945 },
    { lat: 48.8574518, lng: 20.601716 },
    { lat: 48.8574212, lng: 20.6020807 },
    { lat: 48.8573007, lng: 20.6022699 },
    { lat: 48.8573095, lng: 20.6024136 },
    { lat: 48.8572455, lng: 20.6025152 },
    { lat: 48.8571072, lng: 20.6035994 },
    { lat: 48.8569818, lng: 20.6038029 },
    { lat: 48.8569735, lng: 20.6039456 },
    { lat: 48.8569183, lng: 20.6040356 },
    { lat: 48.8569427, lng: 20.6041494 },
    { lat: 48.8568493, lng: 20.6043682 },
    { lat: 48.8568821, lng: 20.6044648 },
    { lat: 48.8568415, lng: 20.6045555 },
    { lat: 48.8568991, lng: 20.604679 },
    { lat: 48.8568575, lng: 20.6048402 },
    { lat: 48.8568983, lng: 20.6051882 },
    { lat: 48.8569654, lng: 20.6053596 },
    { lat: 48.8570872, lng: 20.6054913 },
    { lat: 48.8572432, lng: 20.6060614 },
    { lat: 48.8574088, lng: 20.6063132 },
    { lat: 48.8575758, lng: 20.6067207 },
    { lat: 48.8576327, lng: 20.606741 },
    { lat: 48.8576422, lng: 20.606905 },
    { lat: 48.8578435, lng: 20.6071785 },
    { lat: 48.8579679, lng: 20.607629 },
    { lat: 48.8580928, lng: 20.6083679 },
    { lat: 48.8584283, lng: 20.6088944 },
    { lat: 48.8584338, lng: 20.6089801 },
    { lat: 48.8585669, lng: 20.6090523 },
    { lat: 48.8587508, lng: 20.6096787 },
    { lat: 48.8587382, lng: 20.609856 },
    { lat: 48.858782, lng: 20.6099764 },
    { lat: 48.8587148, lng: 20.6101483 },
    { lat: 48.8588271, lng: 20.6104124 },
    { lat: 48.8587418, lng: 20.6106112 },
    { lat: 48.8587787, lng: 20.6107236 },
    { lat: 48.8587402, lng: 20.6109346 },
    { lat: 48.8587674, lng: 20.6110338 },
    { lat: 48.8586998, lng: 20.6111532 },
    { lat: 48.8587599, lng: 20.6114449 },
    { lat: 48.8586929, lng: 20.6116143 },
    { lat: 48.8586939, lng: 20.6119528 },
    { lat: 48.858475, lng: 20.6128342 },
    { lat: 48.8583467, lng: 20.6129325 },
    { lat: 48.8583081, lng: 20.6131113 },
    { lat: 48.8582078, lng: 20.6132587 },
    { lat: 48.8581845, lng: 20.613423 },
    { lat: 48.8578519, lng: 20.6137815 },
    { lat: 48.8576647, lng: 20.6140828 },
    { lat: 48.8576329, lng: 20.6143543 },
    { lat: 48.8575209, lng: 20.6144037 },
    { lat: 48.8575696, lng: 20.614504 },
    { lat: 48.8574136, lng: 20.6147616 },
    { lat: 48.8572354, lng: 20.6148498 },
    { lat: 48.8571979, lng: 20.6146799 },
    { lat: 48.8570963, lng: 20.6147095 },
    { lat: 48.8569853, lng: 20.6146283 },
    { lat: 48.8568854, lng: 20.614696 },
    { lat: 48.856825, lng: 20.6146774 },
    { lat: 48.8567651, lng: 20.6145466 },
    { lat: 48.8566056, lng: 20.614463 },
    { lat: 48.856244, lng: 20.6143934 },
    { lat: 48.8557341, lng: 20.6143638 },
    { lat: 48.8555112, lng: 20.6144629 },
    { lat: 48.8557017, lng: 20.6146557 },
    { lat: 48.8558697, lng: 20.6146296 },
    { lat: 48.8559083, lng: 20.6146993 },
    { lat: 48.8560617, lng: 20.6146475 },
    { lat: 48.8561771, lng: 20.6148767 },
    { lat: 48.8562811, lng: 20.6149524 },
    { lat: 48.8562958, lng: 20.6151113 },
    { lat: 48.8564217, lng: 20.61501 },
    { lat: 48.8565117, lng: 20.6151626 },
    { lat: 48.8567156, lng: 20.6151335 },
    { lat: 48.8568628, lng: 20.6151912 },
    { lat: 48.8568769, lng: 20.6150538 },
    { lat: 48.8570032, lng: 20.6152435 },
    { lat: 48.8570574, lng: 20.615274 },
    { lat: 48.857111, lng: 20.6152045 },
    { lat: 48.8571953, lng: 20.6152915 },
    { lat: 48.8571592, lng: 20.615568 },
    { lat: 48.8572433, lng: 20.6157154 },
    { lat: 48.8574068, lng: 20.615754 },
    { lat: 48.8574599, lng: 20.6158772 },
    { lat: 48.8574565, lng: 20.6160648 },
    { lat: 48.8575229, lng: 20.6161261 },
    { lat: 48.8575707, lng: 20.6164546 },
    { lat: 48.857701, lng: 20.6165993 },
    { lat: 48.8576806, lng: 20.6168087 },
    { lat: 48.8578769, lng: 20.6171005 },
    { lat: 48.8578928, lng: 20.6173516 },
    { lat: 48.8578531, lng: 20.617498 },
    { lat: 48.8580028, lng: 20.6178065 },
    { lat: 48.8579227, lng: 20.6180181 },
    { lat: 48.8581249, lng: 20.618407 },
    { lat: 48.8580825, lng: 20.618949 },
    { lat: 48.8580154, lng: 20.6191462 },
    { lat: 48.8580838, lng: 20.6193879 },
    { lat: 48.8580699, lng: 20.6195629 },
    { lat: 48.8582516, lng: 20.6197905 },
    { lat: 48.8583531, lng: 20.6202947 },
    { lat: 48.8586309, lng: 20.6207181 },
    { lat: 48.858632, lng: 20.6209064 },
    { lat: 48.8587642, lng: 20.6214149 },
    { lat: 48.8590205, lng: 20.621775 },
    { lat: 48.8588919, lng: 20.6219559 },
    { lat: 48.8587848, lng: 20.6223514 },
    { lat: 48.8582721, lng: 20.6230545 },
    { lat: 48.8576131, lng: 20.6241308 },
    { lat: 48.8559191, lng: 20.6302575 },
    { lat: 48.8552644, lng: 20.6320419 },
    { lat: 48.8551463, lng: 20.6327871 },
    { lat: 48.8550975, lng: 20.6337393 },
    { lat: 48.85506, lng: 20.6365281 },
    { lat: 48.8543583, lng: 20.6367426 },
    { lat: 48.854264, lng: 20.6370568 },
    { lat: 48.8540864, lng: 20.638838 },
    { lat: 48.8541189, lng: 20.639704 },
    { lat: 48.8544743, lng: 20.6410073 },
    { lat: 48.8541515, lng: 20.6416965 },
    { lat: 48.853545, lng: 20.6424557 },
    { lat: 48.8533059, lng: 20.6433146 },
    { lat: 48.8530865, lng: 20.6437918 },
    { lat: 48.8529016, lng: 20.6444656 },
    { lat: 48.8526743, lng: 20.6449388 },
    { lat: 48.8526414, lng: 20.6452447 },
    { lat: 48.8526813, lng: 20.6460668 },
    { lat: 48.8527319, lng: 20.6462878 },
    { lat: 48.8528745, lng: 20.6465584 },
    { lat: 48.8529957, lng: 20.6470407 },
    { lat: 48.8530081, lng: 20.6480435 },
    { lat: 48.852902, lng: 20.6494386 },
    { lat: 48.8536258, lng: 20.6505905 },
    { lat: 48.8540034, lng: 20.6506011 },
    { lat: 48.8542157, lng: 20.6507187 },
    { lat: 48.8541511, lng: 20.6516996 },
    { lat: 48.8539725, lng: 20.6529313 },
    { lat: 48.8536141, lng: 20.6532316 },
    { lat: 48.8530336, lng: 20.6542745 },
    { lat: 48.8523891, lng: 20.6556033 },
    { lat: 48.8520424, lng: 20.656171 },
    { lat: 48.8517414, lng: 20.6568773 },
    { lat: 48.8516655, lng: 20.6580203 },
    { lat: 48.85168, lng: 20.659219 },
    { lat: 48.851873, lng: 20.659721 },
    { lat: 48.852058, lng: 20.660212 },
    { lat: 48.852319, lng: 20.66048 },
    { lat: 48.852693, lng: 20.660868 },
    { lat: 48.852819, lng: 20.660995 },
    { lat: 48.853087, lng: 20.660929 },
    { lat: 48.853186, lng: 20.660877 },
    { lat: 48.853334, lng: 20.660791 },
    { lat: 48.853524, lng: 20.660761 },
    { lat: 48.853707, lng: 20.660692 },
    { lat: 48.853821, lng: 20.660629 },
    { lat: 48.854059, lng: 20.660546 },
    { lat: 48.854142, lng: 20.66052 },
    { lat: 48.854302, lng: 20.660487 },
    { lat: 48.854502, lng: 20.660448 },
    { lat: 48.854705, lng: 20.660404 },
    { lat: 48.854905, lng: 20.66033 },
    { lat: 48.855364, lng: 20.66016 },
    { lat: 48.855662, lng: 20.660007 },
    { lat: 48.856081, lng: 20.659801 },
    { lat: 48.85641, lng: 20.659641 },
    { lat: 48.856574, lng: 20.659561 },
    { lat: 48.856588, lng: 20.659555 },
    { lat: 48.856646, lng: 20.659498 },
    { lat: 48.856715, lng: 20.659503 },
    { lat: 48.856799, lng: 20.659392 },
    { lat: 48.856869, lng: 20.659294 },
    { lat: 48.856941, lng: 20.659276 },
    { lat: 48.857046, lng: 20.659255 },
    { lat: 48.857134, lng: 20.659182 },
    { lat: 48.857254, lng: 20.659099 },
    { lat: 48.857344, lng: 20.659113 },
    { lat: 48.857455, lng: 20.659131 },
    { lat: 48.857609, lng: 20.659135 },
    { lat: 48.857659, lng: 20.659104 },
    { lat: 48.857697, lng: 20.659038 },
    { lat: 48.857766, lng: 20.659092 },
    { lat: 48.857856, lng: 20.659161 },
    { lat: 48.857935, lng: 20.659182 },
    { lat: 48.857992, lng: 20.659144 },
    { lat: 48.858058, lng: 20.65918 },
    { lat: 48.858147, lng: 20.659166 },
    { lat: 48.858237, lng: 20.659205 },
    { lat: 48.85833, lng: 20.659269 },
    { lat: 48.858464, lng: 20.659308 },
    { lat: 48.858509, lng: 20.659316 },
    { lat: 48.858555, lng: 20.659269 },
    { lat: 48.858593, lng: 20.6592 },
    { lat: 48.858656, lng: 20.659242 },
    { lat: 48.858692, lng: 20.659295 },
    { lat: 48.858722, lng: 20.659299 },
    { lat: 48.858792, lng: 20.659307 },
    { lat: 48.858915, lng: 20.659319 },
    { lat: 48.85896, lng: 20.659361 },
    { lat: 48.859036, lng: 20.65929 },
    { lat: 48.859083, lng: 20.65927 },
    { lat: 48.859109, lng: 20.659284 },
    { lat: 48.859144, lng: 20.659278 },
    { lat: 48.859255, lng: 20.659158 },
    { lat: 48.859323, lng: 20.65914 },
    { lat: 48.859414, lng: 20.659009 },
    { lat: 48.859454, lng: 20.658892 },
    { lat: 48.859506, lng: 20.65883 },
    { lat: 48.859591, lng: 20.65875 },
    { lat: 48.859692, lng: 20.658676 },
    { lat: 48.85977, lng: 20.658661 },
    { lat: 48.859854, lng: 20.658571 },
    { lat: 48.859909, lng: 20.658331 },
    { lat: 48.85991, lng: 20.658275 },
    { lat: 48.859947, lng: 20.658194 },
    { lat: 48.860039, lng: 20.658124 },
    { lat: 48.860089, lng: 20.658037 },
    { lat: 48.860141, lng: 20.658027 },
    { lat: 48.860153, lng: 20.657995 },
    { lat: 48.860186, lng: 20.657977 },
    { lat: 48.860198, lng: 20.657787 },
    { lat: 48.860232, lng: 20.657612 },
    { lat: 48.860268, lng: 20.657418 },
    { lat: 48.860303, lng: 20.657197 },
    { lat: 48.860297, lng: 20.657072 },
    { lat: 48.860365, lng: 20.65688 },
    { lat: 48.860461, lng: 20.656601 },
    { lat: 48.86044, lng: 20.656426 },
    { lat: 48.860515, lng: 20.656311 },
    { lat: 48.860554, lng: 20.656209 },
    { lat: 48.860588, lng: 20.656175 },
    { lat: 48.860597, lng: 20.656106 },
    { lat: 48.86063, lng: 20.656031 },
    { lat: 48.860673, lng: 20.655906 },
    { lat: 48.860711, lng: 20.655842 },
    { lat: 48.860687, lng: 20.65577 },
    { lat: 48.860727, lng: 20.655616 },
    { lat: 48.86071, lng: 20.655386 },
    { lat: 48.860727, lng: 20.655298 },
    { lat: 48.86078, lng: 20.655249 },
    { lat: 48.860785, lng: 20.655141 },
    { lat: 48.860779, lng: 20.655015 },
    { lat: 48.860746, lng: 20.654995 },
    { lat: 48.860759, lng: 20.654947 },
    { lat: 48.860826, lng: 20.654718 },
    { lat: 48.860836, lng: 20.654729 },
    { lat: 48.860913, lng: 20.654815 },
    { lat: 48.861008, lng: 20.654927 },
    { lat: 48.861254, lng: 20.655216 },
    { lat: 48.861641, lng: 20.655675 },
    { lat: 48.862022, lng: 20.656125 },
    { lat: 48.862337, lng: 20.656495 },
    { lat: 48.862771, lng: 20.657017 },
    { lat: 48.863137, lng: 20.65745 },
    { lat: 48.863535, lng: 20.657918 },
    { lat: 48.863742, lng: 20.658369 },
    { lat: 48.863992, lng: 20.658915 },
    { lat: 48.864274, lng: 20.659523 },
    { lat: 48.86445, lng: 20.65972 },
    { lat: 48.864725, lng: 20.659538 },
    { lat: 48.865101, lng: 20.659287 },
    { lat: 48.865335, lng: 20.659037 },
    { lat: 48.865595, lng: 20.659065 },
    { lat: 48.866087, lng: 20.658931 },
    { lat: 48.86662, lng: 20.658781 },
    { lat: 48.866922, lng: 20.65871 },
    { lat: 48.867094, lng: 20.658589 },
    { lat: 48.867246, lng: 20.65848 },
    { lat: 48.867282, lng: 20.658479 },
    { lat: 48.867409, lng: 20.658472 },
    { lat: 48.867574, lng: 20.658468 },
    { lat: 48.867698, lng: 20.658472 },
    { lat: 48.867781, lng: 20.658475 },
    { lat: 48.868169, lng: 20.658287 },
    { lat: 48.868453, lng: 20.658155 },
    { lat: 48.868919, lng: 20.658177 },
    { lat: 48.869155, lng: 20.658367 },
    { lat: 48.869302, lng: 20.658494 },
    { lat: 48.869424, lng: 20.658638 },
    { lat: 48.869653, lng: 20.658695 },
    { lat: 48.869883, lng: 20.658938 },
    { lat: 48.869988, lng: 20.659124 },
    { lat: 48.870109, lng: 20.659209 },
    { lat: 48.87029, lng: 20.659331 },
    { lat: 48.87032, lng: 20.659467 },
    { lat: 48.870377, lng: 20.659473 },
    { lat: 48.870413, lng: 20.65955 },
    { lat: 48.870458, lng: 20.659579 },
    { lat: 48.870498, lng: 20.659673 },
    { lat: 48.870565, lng: 20.659612 },
    { lat: 48.8706, lng: 20.659678 },
    { lat: 48.870849, lng: 20.659855 },
    { lat: 48.870988, lng: 20.659917 },
    { lat: 48.871138, lng: 20.659984 },
    { lat: 48.871191, lng: 20.659935 },
    { lat: 48.871227, lng: 20.659987 },
    { lat: 48.871303, lng: 20.659977 },
    { lat: 48.871355, lng: 20.660028 },
    { lat: 48.871474, lng: 20.660029 },
    { lat: 48.871557, lng: 20.660126 },
    { lat: 48.871826, lng: 20.66022 },
    { lat: 48.871958, lng: 20.660249 },
    { lat: 48.872024, lng: 20.660301 },
    { lat: 48.872121, lng: 20.660314 },
    { lat: 48.872191, lng: 20.660403 },
    { lat: 48.872295, lng: 20.66036 },
    { lat: 48.872334, lng: 20.660315 },
    { lat: 48.872376, lng: 20.660381 },
    { lat: 48.872449, lng: 20.660398 },
    { lat: 48.87252, lng: 20.660511 },
    { lat: 48.872582, lng: 20.660475 },
    { lat: 48.872745, lng: 20.660483 },
    { lat: 48.872877, lng: 20.660309 },
    { lat: 48.873021, lng: 20.66039 },
    { lat: 48.873104, lng: 20.660284 },
    { lat: 48.873134, lng: 20.660216 },
    { lat: 48.873255, lng: 20.66031 },
    { lat: 48.873289, lng: 20.66042 },
    { lat: 48.873336, lng: 20.66039 },
    { lat: 48.873435, lng: 20.660458 },
    { lat: 48.873463, lng: 20.660553 },
    { lat: 48.873502, lng: 20.660586 },
    { lat: 48.873532, lng: 20.660662 },
    { lat: 48.873614, lng: 20.660702 },
    { lat: 48.873669, lng: 20.660716 },
    { lat: 48.873707, lng: 20.660789 },
    { lat: 48.87381, lng: 20.660741 },
    { lat: 48.873849, lng: 20.660786 },
    { lat: 48.874168, lng: 20.660838 },
    { lat: 48.874283, lng: 20.660765 },
    { lat: 48.874483, lng: 20.660741 },
    { lat: 48.874679, lng: 20.660719 },
    { lat: 48.874762, lng: 20.660805 },
    { lat: 48.874889, lng: 20.660678 },
    { lat: 48.874962, lng: 20.660746 },
    { lat: 48.875038, lng: 20.660849 },
    { lat: 48.875115, lng: 20.660919 },
    { lat: 48.875246, lng: 20.660891 },
    { lat: 48.875395, lng: 20.660952 },
    { lat: 48.875604, lng: 20.660987 },
    { lat: 48.875636, lng: 20.660935 },
    { lat: 48.875694, lng: 20.660872 },
    { lat: 48.87589, lng: 20.660852 },
    { lat: 48.875942, lng: 20.660903 },
    { lat: 48.875999, lng: 20.660858 },
    { lat: 48.876082, lng: 20.660749 },
    { lat: 48.87614, lng: 20.660766 },
    { lat: 48.876314, lng: 20.660709 },
    { lat: 48.87644, lng: 20.660663 },
    { lat: 48.876549, lng: 20.660678 },
    { lat: 48.876587, lng: 20.660614 },
    { lat: 48.876687, lng: 20.66056 },
    { lat: 48.876767, lng: 20.660599 },
    { lat: 48.876781, lng: 20.660596 },
    { lat: 48.876837, lng: 20.660588 },
    { lat: 48.876842, lng: 20.660487 },
    { lat: 48.876911, lng: 20.660435 },
    { lat: 48.876977, lng: 20.660348 },
    { lat: 48.87709, lng: 20.660411 },
    { lat: 48.877117, lng: 20.660313 },
    { lat: 48.87728, lng: 20.660181 },
    { lat: 48.87734, lng: 20.660237 },
    { lat: 48.877416, lng: 20.660216 },
    { lat: 48.877549, lng: 20.660105 },
    { lat: 48.877593, lng: 20.660017 },
    { lat: 48.877663, lng: 20.660057 },
    { lat: 48.87771, lng: 20.659991 },
    { lat: 48.87783, lng: 20.659992 },
    { lat: 48.877909, lng: 20.659991 },
    { lat: 48.877954, lng: 20.659946 },
    { lat: 48.878023, lng: 20.659885 },
    { lat: 48.878134, lng: 20.659804 },
    { lat: 48.878201, lng: 20.659756 },
    { lat: 48.878192, lng: 20.659741 },
    { lat: 48.878249, lng: 20.659681 },
    { lat: 48.878268, lng: 20.659639 },
    { lat: 48.878286, lng: 20.659629 },
    { lat: 48.878203, lng: 20.659416 },
    { lat: 48.878121, lng: 20.659209 },
    { lat: 48.878029, lng: 20.658792 },
    { lat: 48.877956, lng: 20.658373 },
    { lat: 48.877884, lng: 20.658273 },
    { lat: 48.877764, lng: 20.658309 },
    { lat: 48.877615, lng: 20.658148 },
    { lat: 48.877587, lng: 20.657913 },
    { lat: 48.877522, lng: 20.657792 },
    { lat: 48.877434, lng: 20.65778 },
    { lat: 48.877385, lng: 20.657702 },
    { lat: 48.877408, lng: 20.657567 },
    { lat: 48.877387, lng: 20.657324 },
    { lat: 48.877398, lng: 20.657309 },
    { lat: 48.877513, lng: 20.657156 },
    { lat: 48.877674, lng: 20.657233 },
    { lat: 48.877749, lng: 20.657265 },
    { lat: 48.878025, lng: 20.657406 },
    { lat: 48.87841, lng: 20.657595 },
    { lat: 48.878871, lng: 20.657817 },
    { lat: 48.879166, lng: 20.657966 },
    { lat: 48.879476, lng: 20.65811 },
    { lat: 48.879923, lng: 20.658332 },
    { lat: 48.880528, lng: 20.65863 },
    { lat: 48.880604, lng: 20.658676 },
    { lat: 48.880929, lng: 20.65883 },
    { lat: 48.881133, lng: 20.658931 },
    { lat: 48.881302, lng: 20.659005 },
    { lat: 48.881464, lng: 20.659084 },
    { lat: 48.881494, lng: 20.658925 },
    { lat: 48.881625, lng: 20.658321 },
    { lat: 48.882409, lng: 20.658291 },
    { lat: 48.882977, lng: 20.658273 },
    { lat: 48.883428, lng: 20.658252 },
    { lat: 48.883817, lng: 20.658242 },
    { lat: 48.884469, lng: 20.658301 },
    { lat: 48.885061, lng: 20.658347 },
    { lat: 48.885501, lng: 20.658382 },
    { lat: 48.885708, lng: 20.658398 },
    { lat: 48.886067, lng: 20.658425 },
    { lat: 48.886382, lng: 20.658452 },
    { lat: 48.886621, lng: 20.658368 },
    { lat: 48.88687, lng: 20.658277 },
    { lat: 48.8871, lng: 20.657971 },
    { lat: 48.887363, lng: 20.657614 },
    { lat: 48.887716, lng: 20.657139 },
    { lat: 48.888132, lng: 20.657275 },
    { lat: 48.88844, lng: 20.657539 },
    { lat: 48.888914, lng: 20.6575 },
    { lat: 48.889419, lng: 20.657449 },
    { lat: 48.889682, lng: 20.657422 },
    { lat: 48.889884, lng: 20.657342 },
    { lat: 48.890191, lng: 20.657403 },
    { lat: 48.890383, lng: 20.657102 },
    { lat: 48.890624, lng: 20.656725 },
    { lat: 48.890883, lng: 20.656674 },
    { lat: 48.89107, lng: 20.657186 },
    { lat: 48.891344, lng: 20.657941 },
    { lat: 48.891695, lng: 20.657646 },
    { lat: 48.891898, lng: 20.657713 },
    { lat: 48.892215, lng: 20.657812 },
    { lat: 48.892609, lng: 20.657942 },
    { lat: 48.892962, lng: 20.658058 },
    { lat: 48.893237, lng: 20.658148 },
    { lat: 48.893747, lng: 20.658148 },
    { lat: 48.894093, lng: 20.65815 },
    { lat: 48.8944, lng: 20.658147 },
    { lat: 48.89449, lng: 20.658107 },
    { lat: 48.894707, lng: 20.65819 },
    { lat: 48.894768, lng: 20.658212 },
    { lat: 48.894915, lng: 20.658247 },
    { lat: 48.895061, lng: 20.658285 },
    { lat: 48.895098, lng: 20.658242 },
    { lat: 48.895183, lng: 20.658152 },
  ],
  MníšeknadHnilcom: [
    { lat: 48.8154406, lng: 20.8401628 },
    { lat: 48.8156891, lng: 20.8398924 },
    { lat: 48.8180726, lng: 20.8372938 },
    { lat: 48.8187541, lng: 20.836427 },
    { lat: 48.8192198, lng: 20.8360533 },
    { lat: 48.8195375, lng: 20.8365162 },
    { lat: 48.8198453, lng: 20.8372212 },
    { lat: 48.8210984, lng: 20.8384606 },
    { lat: 48.8212229, lng: 20.8385837 },
    { lat: 48.8216534, lng: 20.8383682 },
    { lat: 48.824526, lng: 20.8369306 },
    { lat: 48.8268128, lng: 20.8354494 },
    { lat: 48.8300962, lng: 20.8314607 },
    { lat: 48.8356567, lng: 20.8288444 },
    { lat: 48.8367073, lng: 20.8285911 },
    { lat: 48.8386997, lng: 20.8294706 },
    { lat: 48.8411435, lng: 20.8300581 },
    { lat: 48.8435098, lng: 20.8299968 },
    { lat: 48.8462961, lng: 20.8301703 },
    { lat: 48.848793, lng: 20.829269 },
    { lat: 48.848692, lng: 20.828678 },
    { lat: 48.848586, lng: 20.828054 },
    { lat: 48.848419, lng: 20.827114 },
    { lat: 48.848375, lng: 20.826869 },
    { lat: 48.84824, lng: 20.826129 },
    { lat: 48.848055, lng: 20.825095 },
    { lat: 48.847932, lng: 20.824488 },
    { lat: 48.847876, lng: 20.824205 },
    { lat: 48.847722, lng: 20.823451 },
    { lat: 48.847601, lng: 20.822879 },
    { lat: 48.847446, lng: 20.82218 },
    { lat: 48.847339, lng: 20.821699 },
    { lat: 48.847263, lng: 20.821347 },
    { lat: 48.847243, lng: 20.821203 },
    { lat: 48.847169, lng: 20.820738 },
    { lat: 48.84714, lng: 20.820569 },
    { lat: 48.846859, lng: 20.819883 },
    { lat: 48.846561, lng: 20.819335 },
    { lat: 48.846374, lng: 20.818995 },
    { lat: 48.846348, lng: 20.818994 },
    { lat: 48.846279, lng: 20.818441 },
    { lat: 48.846156, lng: 20.818135 },
    { lat: 48.84596, lng: 20.817616 },
    { lat: 48.845915, lng: 20.817456 },
    { lat: 48.845815, lng: 20.817093 },
    { lat: 48.845733, lng: 20.816801 },
    { lat: 48.845672, lng: 20.81648 },
    { lat: 48.845571, lng: 20.815944 },
    { lat: 48.845509, lng: 20.815607 },
    { lat: 48.845485, lng: 20.81501 },
    { lat: 48.845411, lng: 20.814565 },
    { lat: 48.845303, lng: 20.814149 },
    { lat: 48.845167, lng: 20.813769 },
    { lat: 48.845107, lng: 20.813614 },
    { lat: 48.844975, lng: 20.813248 },
    { lat: 48.844814, lng: 20.812598 },
    { lat: 48.844758, lng: 20.812295 },
    { lat: 48.844712, lng: 20.812077 },
    { lat: 48.844582, lng: 20.811637 },
    { lat: 48.844388, lng: 20.811211 },
    { lat: 48.84433, lng: 20.810932 },
    { lat: 48.844285, lng: 20.810364 },
    { lat: 48.844299, lng: 20.810365 },
    { lat: 48.844305, lng: 20.810317 },
    { lat: 48.844476, lng: 20.80952 },
    { lat: 48.844247, lng: 20.808793 },
    { lat: 48.844126, lng: 20.808271 },
    { lat: 48.844108, lng: 20.808031 },
    { lat: 48.844076, lng: 20.807542 },
    { lat: 48.844066, lng: 20.80695 },
    { lat: 48.844063, lng: 20.806751 },
    { lat: 48.844049, lng: 20.806411 },
    { lat: 48.843985, lng: 20.805064 },
    { lat: 48.844017, lng: 20.803322 },
    { lat: 48.843954, lng: 20.802661 },
    { lat: 48.843897, lng: 20.802048 },
    { lat: 48.843795, lng: 20.801272 },
    { lat: 48.843765, lng: 20.800905 },
    { lat: 48.843741, lng: 20.800565 },
    { lat: 48.843752, lng: 20.799957 },
    { lat: 48.843798, lng: 20.799212 },
    { lat: 48.843865, lng: 20.798507 },
    { lat: 48.843762, lng: 20.797927 },
    { lat: 48.843637, lng: 20.797256 },
    { lat: 48.843417, lng: 20.796692 },
    { lat: 48.843223, lng: 20.796219 },
    { lat: 48.843192, lng: 20.796118 },
    { lat: 48.843078, lng: 20.795819 },
    { lat: 48.842975, lng: 20.795073 },
    { lat: 48.84288, lng: 20.794531 },
    { lat: 48.842809, lng: 20.794119 },
    { lat: 48.842533, lng: 20.793404 },
    { lat: 48.842447, lng: 20.793019 },
    { lat: 48.84229, lng: 20.792517 },
    { lat: 48.84221, lng: 20.792379 },
    { lat: 48.842059, lng: 20.79226 },
    { lat: 48.841787, lng: 20.791559 },
    { lat: 48.841515, lng: 20.79084 },
    { lat: 48.841453, lng: 20.790495 },
    { lat: 48.841299, lng: 20.789795 },
    { lat: 48.841138, lng: 20.789068 },
    { lat: 48.841003, lng: 20.788856 },
    { lat: 48.840776, lng: 20.788476 },
    { lat: 48.840326, lng: 20.787901 },
    { lat: 48.840138, lng: 20.787047 },
    { lat: 48.83999, lng: 20.786408 },
    { lat: 48.839893, lng: 20.785733 },
    { lat: 48.839908, lng: 20.785265 },
    { lat: 48.839912, lng: 20.784674 },
    { lat: 48.839914, lng: 20.784153 },
    { lat: 48.839838, lng: 20.783299 },
    { lat: 48.839849, lng: 20.783053 },
    { lat: 48.839914, lng: 20.782748 },
    { lat: 48.840047, lng: 20.782365 },
    { lat: 48.840187, lng: 20.781303 },
    { lat: 48.840247, lng: 20.780532 },
    { lat: 48.84022, lng: 20.780164 },
    { lat: 48.840216, lng: 20.77996 },
    { lat: 48.84012, lng: 20.779493 },
    { lat: 48.840013, lng: 20.778923 },
    { lat: 48.839924, lng: 20.778666 },
    { lat: 48.839761, lng: 20.778175 },
    { lat: 48.839561, lng: 20.777798 },
    { lat: 48.83913, lng: 20.777572 },
    { lat: 48.839054, lng: 20.777519 },
    { lat: 48.838917, lng: 20.777406 },
    { lat: 48.838829, lng: 20.777179 },
    { lat: 48.838702, lng: 20.777014 },
    { lat: 48.838642, lng: 20.776941 },
    { lat: 48.838472, lng: 20.776753 },
    { lat: 48.838198, lng: 20.776193 },
    { lat: 48.837961, lng: 20.775705 },
    { lat: 48.83777, lng: 20.775234 },
    { lat: 48.837559, lng: 20.774621 },
    { lat: 48.83742, lng: 20.774106 },
    { lat: 48.837274, lng: 20.773371 },
    { lat: 48.837109, lng: 20.77291 },
    { lat: 48.836741, lng: 20.772329 },
    { lat: 48.836432, lng: 20.771901 },
    { lat: 48.836382, lng: 20.771681 },
    { lat: 48.83629, lng: 20.771475 },
    { lat: 48.83607, lng: 20.771072 },
    { lat: 48.835932, lng: 20.770605 },
    { lat: 48.835738, lng: 20.770234 },
    { lat: 48.835505, lng: 20.769975 },
    { lat: 48.835318, lng: 20.769607 },
    { lat: 48.835163, lng: 20.768778 },
    { lat: 48.835111, lng: 20.76853 },
    { lat: 48.83485, lng: 20.768131 },
    { lat: 48.834626, lng: 20.767882 },
    { lat: 48.834596, lng: 20.767419 },
    { lat: 48.834556, lng: 20.766657 },
    { lat: 48.834439, lng: 20.765877 },
    { lat: 48.834221, lng: 20.764358 },
    { lat: 48.834194, lng: 20.764174 },
    { lat: 48.834078, lng: 20.763808 },
    { lat: 48.834068, lng: 20.763805 },
    { lat: 48.834057, lng: 20.763803 },
    { lat: 48.834082, lng: 20.763002 },
    { lat: 48.834165, lng: 20.762571 },
    { lat: 48.834151, lng: 20.762345 },
    { lat: 48.834009, lng: 20.761978 },
    { lat: 48.834026, lng: 20.761956 },
    { lat: 48.834055, lng: 20.760839 },
    { lat: 48.834266, lng: 20.759759 },
    { lat: 48.834332, lng: 20.75942 },
    { lat: 48.834491, lng: 20.758572 },
    { lat: 48.834613, lng: 20.757941 },
    { lat: 48.834694, lng: 20.757522 },
    { lat: 48.834773, lng: 20.757138 },
    { lat: 48.83445, lng: 20.756551 },
    { lat: 48.834291, lng: 20.756272 },
    { lat: 48.833941, lng: 20.754959 },
    { lat: 48.8339041, lng: 20.7548163 },
    { lat: 48.83379, lng: 20.754375 },
    { lat: 48.833631, lng: 20.753765 },
    { lat: 48.833585, lng: 20.75319 },
    { lat: 48.833516, lng: 20.752414 },
    { lat: 48.83336, lng: 20.751384 },
    { lat: 48.833256, lng: 20.750717 },
    { lat: 48.8321236, lng: 20.7513274 },
    { lat: 48.8314202, lng: 20.7512804 },
    { lat: 48.8290578, lng: 20.7511226 },
    { lat: 48.828032, lng: 20.7509514 },
    { lat: 48.8272817, lng: 20.7508055 },
    { lat: 48.8264765, lng: 20.7503225 },
    { lat: 48.8254334, lng: 20.750719 },
    { lat: 48.8242474, lng: 20.7507951 },
    { lat: 48.8228371, lng: 20.7499073 },
    { lat: 48.821977, lng: 20.7494905 },
    { lat: 48.8209693, lng: 20.7496805 },
    { lat: 48.8199651, lng: 20.749199 },
    { lat: 48.8184419, lng: 20.7490041 },
    { lat: 48.8174505, lng: 20.7487245 },
    { lat: 48.8161907, lng: 20.7509002 },
    { lat: 48.815608, lng: 20.7516498 },
    { lat: 48.81479, lng: 20.7522956 },
    { lat: 48.8141733, lng: 20.7531987 },
    { lat: 48.813327, lng: 20.7537834 },
    { lat: 48.8131261, lng: 20.7543093 },
    { lat: 48.8128887, lng: 20.7544081 },
    { lat: 48.8121686, lng: 20.7557635 },
    { lat: 48.8117787, lng: 20.7563632 },
    { lat: 48.8104349, lng: 20.7568483 },
    { lat: 48.8093273, lng: 20.7570479 },
    { lat: 48.8087763, lng: 20.7570281 },
    { lat: 48.8055991, lng: 20.758878 },
    { lat: 48.8052806, lng: 20.7596472 },
    { lat: 48.8042964, lng: 20.7607264 },
    { lat: 48.8041707, lng: 20.7607761 },
    { lat: 48.8035227, lng: 20.7618093 },
    { lat: 48.8020696, lng: 20.7636764 },
    { lat: 48.7995988, lng: 20.7663123 },
    { lat: 48.7985733, lng: 20.76699 },
    { lat: 48.7975362, lng: 20.7673951 },
    { lat: 48.7971518, lng: 20.7676349 },
    { lat: 48.7919082, lng: 20.7692395 },
    { lat: 48.7912778, lng: 20.7695665 },
    { lat: 48.7914143, lng: 20.7700543 },
    { lat: 48.7915984, lng: 20.7708381 },
    { lat: 48.7914088, lng: 20.7709548 },
    { lat: 48.7912574, lng: 20.770968 },
    { lat: 48.7910345, lng: 20.7711633 },
    { lat: 48.7900839, lng: 20.7713418 },
    { lat: 48.7897771, lng: 20.7712625 },
    { lat: 48.7895696, lng: 20.7712853 },
    { lat: 48.7892033, lng: 20.7714964 },
    { lat: 48.7886067, lng: 20.7716896 },
    { lat: 48.7877002, lng: 20.7720944 },
    { lat: 48.7862804, lng: 20.7724729 },
    { lat: 48.7853793, lng: 20.7724616 },
    { lat: 48.7852629, lng: 20.7731239 },
    { lat: 48.7851381, lng: 20.7751162 },
    { lat: 48.7854927, lng: 20.7788518 },
    { lat: 48.7858461, lng: 20.7803888 },
    { lat: 48.7871774, lng: 20.7829568 },
    { lat: 48.7872928, lng: 20.7834566 },
    { lat: 48.7874236, lng: 20.7845535 },
    { lat: 48.787568, lng: 20.7853048 },
    { lat: 48.787918, lng: 20.786139 },
    { lat: 48.7879025, lng: 20.7871138 },
    { lat: 48.7877608, lng: 20.7873454 },
    { lat: 48.7875233, lng: 20.787314 },
    { lat: 48.7873694, lng: 20.7877087 },
    { lat: 48.786746, lng: 20.7887452 },
    { lat: 48.7864045, lng: 20.7889624 },
    { lat: 48.7862089, lng: 20.7891637 },
    { lat: 48.785949, lng: 20.7891067 },
    { lat: 48.7856909, lng: 20.7892526 },
    { lat: 48.7852024, lng: 20.7898089 },
    { lat: 48.7848968, lng: 20.7899804 },
    { lat: 48.7846354, lng: 20.7908784 },
    { lat: 48.784368, lng: 20.7932836 },
    { lat: 48.7842623, lng: 20.7936747 },
    { lat: 48.783904, lng: 20.7940196 },
    { lat: 48.7836522, lng: 20.7941578 },
    { lat: 48.7835644, lng: 20.7940634 },
    { lat: 48.7835043, lng: 20.7940857 },
    { lat: 48.7831504, lng: 20.7943931 },
    { lat: 48.7828991, lng: 20.794677 },
    { lat: 48.7828156, lng: 20.7949592 },
    { lat: 48.7826405, lng: 20.795202 },
    { lat: 48.7829926, lng: 20.7956469 },
    { lat: 48.7833212, lng: 20.7964195 },
    { lat: 48.7835426, lng: 20.7966307 },
    { lat: 48.7841402, lng: 20.7968918 },
    { lat: 48.7847656, lng: 20.7968729 },
    { lat: 48.7849937, lng: 20.796994 },
    { lat: 48.785382, lng: 20.7980853 },
    { lat: 48.7856217, lng: 20.7990218 },
    { lat: 48.7860077, lng: 20.7993409 },
    { lat: 48.7862263, lng: 20.7996369 },
    { lat: 48.7863242, lng: 20.7998968 },
    { lat: 48.7866392, lng: 20.8004407 },
    { lat: 48.7868508, lng: 20.8012203 },
    { lat: 48.7870595, lng: 20.8023285 },
    { lat: 48.7872175, lng: 20.8025391 },
    { lat: 48.7872979, lng: 20.8028425 },
    { lat: 48.7868977, lng: 20.8038618 },
    { lat: 48.7870513, lng: 20.8041027 },
    { lat: 48.7869842, lng: 20.8043686 },
    { lat: 48.7868811, lng: 20.8043551 },
    { lat: 48.78626, lng: 20.8053316 },
    { lat: 48.7860542, lng: 20.8058588 },
    { lat: 48.7861028, lng: 20.8061509 },
    { lat: 48.7860162, lng: 20.8064491 },
    { lat: 48.7858828, lng: 20.8067903 },
    { lat: 48.7857259, lng: 20.8068723 },
    { lat: 48.7856805, lng: 20.8069563 },
    { lat: 48.7856721, lng: 20.8073314 },
    { lat: 48.7855231, lng: 20.8076124 },
    { lat: 48.7855587, lng: 20.8077613 },
    { lat: 48.7854277, lng: 20.8080452 },
    { lat: 48.7851337, lng: 20.8084237 },
    { lat: 48.7849661, lng: 20.808914 },
    { lat: 48.7848061, lng: 20.8094975 },
    { lat: 48.7848021, lng: 20.8099333 },
    { lat: 48.7848448, lng: 20.810183 },
    { lat: 48.7846725, lng: 20.8103449 },
    { lat: 48.7845997, lng: 20.8111692 },
    { lat: 48.7845272, lng: 20.811282 },
    { lat: 48.7846311, lng: 20.8116507 },
    { lat: 48.784637, lng: 20.8118569 },
    { lat: 48.7844571, lng: 20.8121515 },
    { lat: 48.7844231, lng: 20.8123737 },
    { lat: 48.7844573, lng: 20.8125173 },
    { lat: 48.7841248, lng: 20.8131524 },
    { lat: 48.7840524, lng: 20.8135933 },
    { lat: 48.784101, lng: 20.8137629 },
    { lat: 48.7840161, lng: 20.8145942 },
    { lat: 48.7839361, lng: 20.8149475 },
    { lat: 48.7839429, lng: 20.8159247 },
    { lat: 48.7838839, lng: 20.8169524 },
    { lat: 48.7839099, lng: 20.8171763 },
    { lat: 48.7839593, lng: 20.8173359 },
    { lat: 48.7841912, lng: 20.817502 },
    { lat: 48.7843784, lng: 20.8179619 },
    { lat: 48.7844746, lng: 20.8180757 },
    { lat: 48.784567, lng: 20.8185374 },
    { lat: 48.7844959, lng: 20.8187854 },
    { lat: 48.7846482, lng: 20.8191563 },
    { lat: 48.7847556, lng: 20.8196215 },
    { lat: 48.7846199, lng: 20.8207796 },
    { lat: 48.7846503, lng: 20.8209406 },
    { lat: 48.7848167, lng: 20.8212223 },
    { lat: 48.7848388, lng: 20.821343 },
    { lat: 48.7847029, lng: 20.8215469 },
    { lat: 48.7846584, lng: 20.8217253 },
    { lat: 48.784745, lng: 20.8221168 },
    { lat: 48.78471, lng: 20.8223987 },
    { lat: 48.7847865, lng: 20.8230206 },
    { lat: 48.7847278, lng: 20.8232062 },
    { lat: 48.7847514, lng: 20.82337 },
    { lat: 48.7846453, lng: 20.8239777 },
    { lat: 48.7845245, lng: 20.8242639 },
    { lat: 48.784597, lng: 20.8250913 },
    { lat: 48.7843881, lng: 20.8261434 },
    { lat: 48.7841242, lng: 20.8267451 },
    { lat: 48.7840445, lng: 20.8267525 },
    { lat: 48.7839608, lng: 20.8273006 },
    { lat: 48.7843803, lng: 20.828067 },
    { lat: 48.7844012, lng: 20.8282156 },
    { lat: 48.7849887, lng: 20.8286 },
    { lat: 48.7850148, lng: 20.8287417 },
    { lat: 48.7851793, lng: 20.8290032 },
    { lat: 48.7852231, lng: 20.8291654 },
    { lat: 48.7852316, lng: 20.8299643 },
    { lat: 48.7855252, lng: 20.8309377 },
    { lat: 48.7856819, lng: 20.8311035 },
    { lat: 48.7857164, lng: 20.8313235 },
    { lat: 48.7858027, lng: 20.8314388 },
    { lat: 48.7861383, lng: 20.8328183 },
    { lat: 48.786074, lng: 20.833271 },
    { lat: 48.7857652, lng: 20.8342332 },
    { lat: 48.7856386, lng: 20.8343853 },
    { lat: 48.7855973, lng: 20.8345899 },
    { lat: 48.7854418, lng: 20.8346504 },
    { lat: 48.785342, lng: 20.8348228 },
    { lat: 48.7852891, lng: 20.8350526 },
    { lat: 48.7853007, lng: 20.8353872 },
    { lat: 48.7852382, lng: 20.8355656 },
    { lat: 48.7852272, lng: 20.8358342 },
    { lat: 48.7853428, lng: 20.8361517 },
    { lat: 48.7853529, lng: 20.8363126 },
    { lat: 48.7853039, lng: 20.8364596 },
    { lat: 48.7853941, lng: 20.8366606 },
    { lat: 48.7853383, lng: 20.8367718 },
    { lat: 48.785392, lng: 20.8369621 },
    { lat: 48.7853158, lng: 20.8371655 },
    { lat: 48.7854808, lng: 20.8379967 },
    { lat: 48.7852193, lng: 20.8386417 },
    { lat: 48.7849477, lng: 20.8390929 },
    { lat: 48.7844921, lng: 20.8401758 },
    { lat: 48.7843861, lng: 20.8405996 },
    { lat: 48.7842714, lng: 20.840664 },
    { lat: 48.7841169, lng: 20.8409529 },
    { lat: 48.7839446, lng: 20.8411132 },
    { lat: 48.7836362, lng: 20.8418293 },
    { lat: 48.7833391, lng: 20.8421265 },
    { lat: 48.7827929, lng: 20.8431997 },
    { lat: 48.7824946, lng: 20.8433942 },
    { lat: 48.7823002, lng: 20.8437347 },
    { lat: 48.7820621, lng: 20.8439434 },
    { lat: 48.7819095, lng: 20.8442177 },
    { lat: 48.7817597, lng: 20.8446775 },
    { lat: 48.7817368, lng: 20.845809 },
    { lat: 48.7819415, lng: 20.8466563 },
    { lat: 48.7823178, lng: 20.8478445 },
    { lat: 48.7825046, lng: 20.848982 },
    { lat: 48.7824942, lng: 20.8510752 },
    { lat: 48.7827883, lng: 20.8524866 },
    { lat: 48.782888, lng: 20.8534582 },
    { lat: 48.7828224, lng: 20.8558417 },
    { lat: 48.7827327, lng: 20.8568721 },
    { lat: 48.78286, lng: 20.8583123 },
    { lat: 48.783079, lng: 20.8594848 },
    { lat: 48.7825974, lng: 20.8607983 },
    { lat: 48.7837347, lng: 20.8602871 },
    { lat: 48.78432, lng: 20.858838 },
    { lat: 48.7849166, lng: 20.8568411 },
    { lat: 48.7851676, lng: 20.8564144 },
    { lat: 48.785831, lng: 20.8556673 },
    { lat: 48.786744, lng: 20.855804 },
    { lat: 48.7879992, lng: 20.8556753 },
    { lat: 48.7888482, lng: 20.8561448 },
    { lat: 48.7889662, lng: 20.856139 },
    { lat: 48.7894416, lng: 20.8558861 },
    { lat: 48.7897263, lng: 20.8555834 },
    { lat: 48.7898923, lng: 20.8551763 },
    { lat: 48.7901584, lng: 20.8547951 },
    { lat: 48.7902681, lng: 20.854779 },
    { lat: 48.7946405, lng: 20.8577737 },
    { lat: 48.795654, lng: 20.8583427 },
    { lat: 48.7961385, lng: 20.8587196 },
    { lat: 48.7964535, lng: 20.8588408 },
    { lat: 48.7974422, lng: 20.8594165 },
    { lat: 48.7980514, lng: 20.8594544 },
    { lat: 48.798321, lng: 20.8593714 },
    { lat: 48.7987106, lng: 20.8594033 },
    { lat: 48.7991429, lng: 20.8592429 },
    { lat: 48.7998875, lng: 20.8594897 },
    { lat: 48.800943, lng: 20.859184 },
    { lat: 48.801043, lng: 20.8590822 },
    { lat: 48.801037, lng: 20.858411 },
    { lat: 48.801143, lng: 20.8580217 },
    { lat: 48.8013795, lng: 20.8574358 },
    { lat: 48.8015981, lng: 20.8570479 },
    { lat: 48.8016256, lng: 20.8566651 },
    { lat: 48.8015966, lng: 20.8564478 },
    { lat: 48.8016478, lng: 20.856036 },
    { lat: 48.801632, lng: 20.8554431 },
    { lat: 48.8017124, lng: 20.8550297 },
    { lat: 48.8017132, lng: 20.8547575 },
    { lat: 48.8017887, lng: 20.8544699 },
    { lat: 48.8017584, lng: 20.8540123 },
    { lat: 48.8031573, lng: 20.8513795 },
    { lat: 48.8068329, lng: 20.8467098 },
    { lat: 48.8135618, lng: 20.8417923 },
    { lat: 48.8154168, lng: 20.8401893 },
    { lat: 48.8154406, lng: 20.8401628 },
  ],
  Žakarovce: [
    { lat: 48.8878608, lng: 20.9569715 },
    { lat: 48.8878658, lng: 20.9569182 },
    { lat: 48.8878342, lng: 20.956917 },
    { lat: 48.887756, lng: 20.9565958 },
    { lat: 48.8885634, lng: 20.9548514 },
    { lat: 48.8885541, lng: 20.9546089 },
    { lat: 48.8884761, lng: 20.9543645 },
    { lat: 48.888104, lng: 20.9535322 },
    { lat: 48.8879764, lng: 20.9500891 },
    { lat: 48.8878855, lng: 20.9494602 },
    { lat: 48.8881416, lng: 20.9485986 },
    { lat: 48.8885147, lng: 20.9463273 },
    { lat: 48.8886801, lng: 20.9457884 },
    { lat: 48.8887289, lng: 20.9453287 },
    { lat: 48.8886089, lng: 20.9439011 },
    { lat: 48.8884642, lng: 20.9429394 },
    { lat: 48.8882936, lng: 20.9421486 },
    { lat: 48.8884711, lng: 20.9388797 },
    { lat: 48.8885698, lng: 20.938498 },
    { lat: 48.8886467, lng: 20.937844 },
    { lat: 48.8886287, lng: 20.9373711 },
    { lat: 48.8885319, lng: 20.9367004 },
    { lat: 48.8883093, lng: 20.9360323 },
    { lat: 48.8885619, lng: 20.9334054 },
    { lat: 48.8888995, lng: 20.9263847 },
    { lat: 48.8890356, lng: 20.9235539 },
    { lat: 48.8893852, lng: 20.9180658 },
    { lat: 48.8898461, lng: 20.9150134 },
    { lat: 48.8899358, lng: 20.9143197 },
    { lat: 48.8900822, lng: 20.9131861 },
    { lat: 48.8905592, lng: 20.9094954 },
    { lat: 48.892356, lng: 20.906067 },
    { lat: 48.892191, lng: 20.9058 },
    { lat: 48.892098, lng: 20.905808 },
    { lat: 48.892097, lng: 20.905666 },
    { lat: 48.892054, lng: 20.905617 },
    { lat: 48.891903, lng: 20.905301 },
    { lat: 48.891899, lng: 20.905292 },
    { lat: 48.891863, lng: 20.905112 },
    { lat: 48.891771, lng: 20.905073 },
    { lat: 48.891749, lng: 20.904913 },
    { lat: 48.891693, lng: 20.904987 },
    { lat: 48.891569, lng: 20.904965 },
    { lat: 48.891533, lng: 20.904871 },
    { lat: 48.891528, lng: 20.904857 },
    { lat: 48.891486, lng: 20.904814 },
    { lat: 48.89113, lng: 20.903853 },
    { lat: 48.890904, lng: 20.903199 },
    { lat: 48.89062, lng: 20.902844 },
    { lat: 48.889912, lng: 20.902047 },
    { lat: 48.889973, lng: 20.901872 },
    { lat: 48.889838, lng: 20.901662 },
    { lat: 48.889819, lng: 20.901655 },
    { lat: 48.889872, lng: 20.901344 },
    { lat: 48.88988, lng: 20.901072 },
    { lat: 48.889964, lng: 20.90049 },
    { lat: 48.889982, lng: 20.900502 },
    { lat: 48.890153, lng: 20.899863 },
    { lat: 48.889238, lng: 20.898766 },
    { lat: 48.889094, lng: 20.898355 },
    { lat: 48.889072, lng: 20.898189 },
    { lat: 48.888919, lng: 20.89756 },
    { lat: 48.888583, lng: 20.89666 },
    { lat: 48.887876, lng: 20.89631 },
    { lat: 48.887869, lng: 20.896307 },
    { lat: 48.887572, lng: 20.895674 },
    { lat: 48.886947, lng: 20.895136 },
    { lat: 48.886224, lng: 20.8945 },
    { lat: 48.886136, lng: 20.894423 },
    { lat: 48.88606, lng: 20.894276 },
    { lat: 48.886041, lng: 20.894239 },
    { lat: 48.885825, lng: 20.89382 },
    { lat: 48.884754, lng: 20.891745 },
    { lat: 48.88443, lng: 20.891535 },
    { lat: 48.884166, lng: 20.891365 },
    { lat: 48.883306, lng: 20.890816 },
    { lat: 48.883255, lng: 20.890782 },
    { lat: 48.88285, lng: 20.890551 },
    { lat: 48.8827982, lng: 20.8905217 },
    { lat: 48.8827435, lng: 20.8906683 },
    { lat: 48.8829317, lng: 20.890772 },
    { lat: 48.8830818, lng: 20.8911487 },
    { lat: 48.8831172, lng: 20.8916899 },
    { lat: 48.8825708, lng: 20.8926392 },
    { lat: 48.8823596, lng: 20.8931496 },
    { lat: 48.8814345, lng: 20.8946794 },
    { lat: 48.8812654, lng: 20.8952355 },
    { lat: 48.8806137, lng: 20.8958335 },
    { lat: 48.8801937, lng: 20.8960954 },
    { lat: 48.8795044, lng: 20.8959875 },
    { lat: 48.8793385, lng: 20.8972646 },
    { lat: 48.8795983, lng: 20.8981871 },
    { lat: 48.8795812, lng: 20.8988797 },
    { lat: 48.8794566, lng: 20.8993214 },
    { lat: 48.8788087, lng: 20.9005351 },
    { lat: 48.8787008, lng: 20.902029 },
    { lat: 48.8784417, lng: 20.9029861 },
    { lat: 48.8777964, lng: 20.9039772 },
    { lat: 48.8771555, lng: 20.9043943 },
    { lat: 48.8757614, lng: 20.9063397 },
    { lat: 48.8749167, lng: 20.9080188 },
    { lat: 48.8741107, lng: 20.9092196 },
    { lat: 48.8737861, lng: 20.9099699 },
    { lat: 48.8737411, lng: 20.9103693 },
    { lat: 48.8733549, lng: 20.9114981 },
    { lat: 48.8732837, lng: 20.9125956 },
    { lat: 48.8731477, lng: 20.9130313 },
    { lat: 48.8729889, lng: 20.91333 },
    { lat: 48.8722717, lng: 20.9151213 },
    { lat: 48.8722165, lng: 20.9158882 },
    { lat: 48.8722565, lng: 20.916641 },
    { lat: 48.8721736, lng: 20.9171205 },
    { lat: 48.8718839, lng: 20.9199072 },
    { lat: 48.8715847, lng: 20.9215215 },
    { lat: 48.8713351, lng: 20.9232288 },
    { lat: 48.8712313, lng: 20.9242569 },
    { lat: 48.8711021, lng: 20.9248336 },
    { lat: 48.8709964, lng: 20.925672 },
    { lat: 48.8709708, lng: 20.9270195 },
    { lat: 48.8708553, lng: 20.9277027 },
    { lat: 48.8706149, lng: 20.928184 },
    { lat: 48.8705966, lng: 20.9284124 },
    { lat: 48.8706123, lng: 20.9287108 },
    { lat: 48.8708109, lng: 20.9293634 },
    { lat: 48.8705489, lng: 20.930438 },
    { lat: 48.8705709, lng: 20.9307542 },
    { lat: 48.8708135, lng: 20.9314119 },
    { lat: 48.8708867, lng: 20.9318146 },
    { lat: 48.8708879, lng: 20.932152 },
    { lat: 48.8707147, lng: 20.932927 },
    { lat: 48.8705138, lng: 20.9332821 },
    { lat: 48.8704857, lng: 20.9338496 },
    { lat: 48.870515, lng: 20.9345241 },
    { lat: 48.8704518, lng: 20.9350517 },
    { lat: 48.8705613, lng: 20.9356003 },
    { lat: 48.8705593, lng: 20.9359409 },
    { lat: 48.8704451, lng: 20.9378725 },
    { lat: 48.8705339, lng: 20.938102 },
    { lat: 48.8709168, lng: 20.93868 },
    { lat: 48.8709664, lng: 20.9392868 },
    { lat: 48.8707783, lng: 20.9403171 },
    { lat: 48.8707798, lng: 20.9406627 },
    { lat: 48.8706596, lng: 20.9415115 },
    { lat: 48.8703905, lng: 20.9423127 },
    { lat: 48.8703577, lng: 20.9429463 },
    { lat: 48.8700241, lng: 20.943942 },
    { lat: 48.8701599, lng: 20.9455809 },
    { lat: 48.8705029, lng: 20.9472538 },
    { lat: 48.8712217, lng: 20.9495887 },
    { lat: 48.8711428, lng: 20.9500592 },
    { lat: 48.8710231, lng: 20.9502515 },
    { lat: 48.8709627, lng: 20.9504992 },
    { lat: 48.8711697, lng: 20.9511999 },
    { lat: 48.8723785, lng: 20.9535583 },
    { lat: 48.8727359, lng: 20.9541455 },
    { lat: 48.873188, lng: 20.9563616 },
    { lat: 48.8738851, lng: 20.9575584 },
    { lat: 48.8742994, lng: 20.9584971 },
    { lat: 48.8743826, lng: 20.9587723 },
    { lat: 48.8746956, lng: 20.9598083 },
    { lat: 48.8748352, lng: 20.9598784 },
    { lat: 48.8751419, lng: 20.9603344 },
    { lat: 48.8778472, lng: 20.9632218 },
    { lat: 48.8780743, lng: 20.9636582 },
    { lat: 48.8787382, lng: 20.9630048 },
    { lat: 48.8791482, lng: 20.9620408 },
    { lat: 48.8799921, lng: 20.9604756 },
    { lat: 48.8814, lng: 20.9605075 },
    { lat: 48.8822788, lng: 20.9596873 },
    { lat: 48.8834409, lng: 20.9582542 },
    { lat: 48.8860272, lng: 20.9572389 },
    { lat: 48.8864363, lng: 20.9572211 },
    { lat: 48.8873744, lng: 20.9569634 },
    { lat: 48.8878608, lng: 20.9569715 },
  ],
  Richnava: [
    { lat: 48.935962, lng: 20.894046 },
    { lat: 48.935893, lng: 20.893485 },
    { lat: 48.935864, lng: 20.89314 },
    { lat: 48.935174, lng: 20.891916 },
    { lat: 48.933707, lng: 20.890008 },
    { lat: 48.933613, lng: 20.889885 },
    { lat: 48.933364, lng: 20.889618 },
    { lat: 48.932845, lng: 20.889132 },
    { lat: 48.932843, lng: 20.88913 },
    { lat: 48.932579, lng: 20.888942 },
    { lat: 48.932559, lng: 20.888921 },
    { lat: 48.932304, lng: 20.888701 },
    { lat: 48.931453, lng: 20.888025 },
    { lat: 48.931175, lng: 20.887874 },
    { lat: 48.929351, lng: 20.886977 },
    { lat: 48.929338, lng: 20.88697 },
    { lat: 48.929316, lng: 20.886958 },
    { lat: 48.929314, lng: 20.886919 },
    { lat: 48.929282, lng: 20.886464 },
    { lat: 48.92923, lng: 20.886225 },
    { lat: 48.92909, lng: 20.885907 },
    { lat: 48.929029, lng: 20.885548 },
    { lat: 48.929041, lng: 20.885062 },
    { lat: 48.929046, lng: 20.884646 },
    { lat: 48.929049, lng: 20.884127 },
    { lat: 48.929091, lng: 20.883105 },
    { lat: 48.929095, lng: 20.882368 },
    { lat: 48.929061, lng: 20.881884 },
    { lat: 48.928784, lng: 20.881361 },
    { lat: 48.928707, lng: 20.881084 },
    { lat: 48.928699, lng: 20.880822 },
    { lat: 48.929006, lng: 20.879822 },
    { lat: 48.929144, lng: 20.87953 },
    { lat: 48.929415, lng: 20.879237 },
    { lat: 48.929651, lng: 20.878937 },
    { lat: 48.929885, lng: 20.878315 },
    { lat: 48.929896, lng: 20.87809 },
    { lat: 48.929881, lng: 20.877636 },
    { lat: 48.929854, lng: 20.877142 },
    { lat: 48.929835, lng: 20.877141 },
    { lat: 48.929753, lng: 20.87753 },
    { lat: 48.929599, lng: 20.87784 },
    { lat: 48.92934, lng: 20.878133 },
    { lat: 48.929146, lng: 20.878304 },
    { lat: 48.92896, lng: 20.8786 },
    { lat: 48.928764, lng: 20.878653 },
    { lat: 48.928501, lng: 20.878469 },
    { lat: 48.928263, lng: 20.87846 },
    { lat: 48.927973, lng: 20.878502 },
    { lat: 48.927645, lng: 20.878448 },
    { lat: 48.927438, lng: 20.878457 },
    { lat: 48.927137, lng: 20.878612 },
    { lat: 48.92713, lng: 20.8786 },
    { lat: 48.926915, lng: 20.878752 },
    { lat: 48.926524, lng: 20.878797 },
    { lat: 48.926524, lng: 20.87882 },
    { lat: 48.926483, lng: 20.878816 },
    { lat: 48.925236, lng: 20.878723 },
    { lat: 48.925231, lng: 20.878991 },
    { lat: 48.925231, lng: 20.879196 },
    { lat: 48.925212, lng: 20.879357 },
    { lat: 48.925165, lng: 20.87973 },
    { lat: 48.925156, lng: 20.879831 },
    { lat: 48.92507, lng: 20.880097 },
    { lat: 48.925093, lng: 20.880197 },
    { lat: 48.925072, lng: 20.880643 },
    { lat: 48.925034, lng: 20.88191 },
    { lat: 48.925047, lng: 20.882762 },
    { lat: 48.925182, lng: 20.884744 },
    { lat: 48.925137, lng: 20.88478 },
    { lat: 48.925118, lng: 20.884819 },
    { lat: 48.925085, lng: 20.884974 },
    { lat: 48.92508, lng: 20.885012 },
    { lat: 48.925032, lng: 20.88528 },
    { lat: 48.925111, lng: 20.88556 },
    { lat: 48.925301, lng: 20.88622 },
    { lat: 48.925358, lng: 20.887016 },
    { lat: 48.925351, lng: 20.8874 },
    { lat: 48.925266, lng: 20.887908 },
    { lat: 48.925228, lng: 20.888063 },
    { lat: 48.925149, lng: 20.888388 },
    { lat: 48.92513, lng: 20.88864 },
    { lat: 48.925107, lng: 20.888932 },
    { lat: 48.925163, lng: 20.889338 },
    { lat: 48.925269, lng: 20.890123 },
    { lat: 48.925284, lng: 20.890232 },
    { lat: 48.925558, lng: 20.890928 },
    { lat: 48.925682, lng: 20.89147 },
    { lat: 48.925791, lng: 20.892926 },
    { lat: 48.925787, lng: 20.893288 },
    { lat: 48.925667, lng: 20.894085 },
    { lat: 48.925638, lng: 20.894217 },
    { lat: 48.92561, lng: 20.894988 },
    { lat: 48.925755, lng: 20.89669 },
    { lat: 48.925619, lng: 20.898302 },
    { lat: 48.925536, lng: 20.898322 },
    { lat: 48.925529, lng: 20.898323 },
    { lat: 48.924782, lng: 20.898494 },
    { lat: 48.924765, lng: 20.898262 },
    { lat: 48.924626, lng: 20.89849 },
    { lat: 48.924367, lng: 20.898579 },
    { lat: 48.924201, lng: 20.898491 },
    { lat: 48.924131, lng: 20.898454 },
    { lat: 48.924023, lng: 20.898314 },
    { lat: 48.923965, lng: 20.898182 },
    { lat: 48.923855, lng: 20.897924 },
    { lat: 48.923765, lng: 20.898036 },
    { lat: 48.923653, lng: 20.898487 },
    { lat: 48.923629, lng: 20.898584 },
    { lat: 48.923593, lng: 20.898726 },
    { lat: 48.923572, lng: 20.899366 },
    { lat: 48.923522, lng: 20.899571 },
    { lat: 48.923586, lng: 20.899957 },
    { lat: 48.923755, lng: 20.900315 },
    { lat: 48.923829, lng: 20.900415 },
    { lat: 48.923904, lng: 20.900514 },
    { lat: 48.924017, lng: 20.900664 },
    { lat: 48.924273, lng: 20.900832 },
    { lat: 48.924503, lng: 20.90082 },
    { lat: 48.924731, lng: 20.900755 },
    { lat: 48.924812, lng: 20.90104 },
    { lat: 48.924823, lng: 20.901068 },
    { lat: 48.924897, lng: 20.901268 },
    { lat: 48.924913, lng: 20.901507 },
    { lat: 48.92487, lng: 20.901475 },
    { lat: 48.924696, lng: 20.901347 },
    { lat: 48.924692, lng: 20.901392 },
    { lat: 48.924686, lng: 20.901453 },
    { lat: 48.924704, lng: 20.901959 },
    { lat: 48.92462, lng: 20.901985 },
    { lat: 48.924448, lng: 20.902176 },
    { lat: 48.924374, lng: 20.902257 },
    { lat: 48.924209, lng: 20.902443 },
    { lat: 48.924158, lng: 20.902497 },
    { lat: 48.923911, lng: 20.902753 },
    { lat: 48.923847, lng: 20.902821 },
    { lat: 48.923844, lng: 20.902823 },
    { lat: 48.923576, lng: 20.903103 },
    { lat: 48.923476, lng: 20.902982 },
    { lat: 48.921236, lng: 20.904727 },
    { lat: 48.921429, lng: 20.905825 },
    { lat: 48.921397, lng: 20.905877 },
    { lat: 48.921097, lng: 20.9062908 },
    { lat: 48.9211139, lng: 20.906588 },
    { lat: 48.9210031, lng: 20.9067097 },
    { lat: 48.9207847, lng: 20.9067103 },
    { lat: 48.9205325, lng: 20.9072378 },
    { lat: 48.9202528, lng: 20.9074202 },
    { lat: 48.9203157, lng: 20.9076834 },
    { lat: 48.9199674, lng: 20.9078046 },
    { lat: 48.9198588, lng: 20.907754 },
    { lat: 48.9197611, lng: 20.9077928 },
    { lat: 48.919555, lng: 20.908025 },
    { lat: 48.9197886, lng: 20.9084055 },
    { lat: 48.9198417, lng: 20.9088462 },
    { lat: 48.9197448, lng: 20.9091107 },
    { lat: 48.9196034, lng: 20.9092201 },
    { lat: 48.9194785, lng: 20.9094897 },
    { lat: 48.9196168, lng: 20.9097488 },
    { lat: 48.9197335, lng: 20.9097401 },
    { lat: 48.9197433, lng: 20.9098649 },
    { lat: 48.9195986, lng: 20.9099442 },
    { lat: 48.91957, lng: 20.9100684 },
    { lat: 48.9196228, lng: 20.9102973 },
    { lat: 48.9193832, lng: 20.9103696 },
    { lat: 48.9192482, lng: 20.9103032 },
    { lat: 48.9191026, lng: 20.910356 },
    { lat: 48.9191261, lng: 20.9105231 },
    { lat: 48.9192506, lng: 20.9107368 },
    { lat: 48.9193661, lng: 20.9108255 },
    { lat: 48.9195322, lng: 20.9111483 },
    { lat: 48.9193793, lng: 20.9113488 },
    { lat: 48.9191875, lng: 20.9113694 },
    { lat: 48.9190686, lng: 20.9114524 },
    { lat: 48.9188054, lng: 20.9118855 },
    { lat: 48.9187917, lng: 20.9119853 },
    { lat: 48.9191059, lng: 20.9125228 },
    { lat: 48.9191786, lng: 20.9127759 },
    { lat: 48.9195281, lng: 20.9130956 },
    { lat: 48.9197405, lng: 20.9134144 },
    { lat: 48.919726, lng: 20.9135505 },
    { lat: 48.9196584, lng: 20.9136183 },
    { lat: 48.9198809, lng: 20.9138426 },
    { lat: 48.9200107, lng: 20.9135878 },
    { lat: 48.9200402, lng: 20.9131259 },
    { lat: 48.9202, lng: 20.9129553 },
    { lat: 48.9202733, lng: 20.9127632 },
    { lat: 48.9205198, lng: 20.9123951 },
    { lat: 48.9208409, lng: 20.9146403 },
    { lat: 48.9207384, lng: 20.914567 },
    { lat: 48.9207277, lng: 20.9146913 },
    { lat: 48.9206391, lng: 20.9147998 },
    { lat: 48.9205145, lng: 20.9147755 },
    { lat: 48.920384, lng: 20.9148783 },
    { lat: 48.920271, lng: 20.9148291 },
    { lat: 48.9199279, lng: 20.9144689 },
    { lat: 48.9196484, lng: 20.9150904 },
    { lat: 48.9195318, lng: 20.9158711 },
    { lat: 48.9192513, lng: 20.9161699 },
    { lat: 48.9191574, lng: 20.9163511 },
    { lat: 48.9188885, lng: 20.9161909 },
    { lat: 48.9185838, lng: 20.9158964 },
    { lat: 48.9184236, lng: 20.9160219 },
    { lat: 48.9180481, lng: 20.9175041 },
    { lat: 48.9183962, lng: 20.9179158 },
    { lat: 48.9181835, lng: 20.9183804 },
    { lat: 48.9178792, lng: 20.9185675 },
    { lat: 48.9202042, lng: 20.92093 },
    { lat: 48.9218628, lng: 20.9224231 },
    { lat: 48.9217207, lng: 20.9232126 },
    { lat: 48.921538, lng: 20.9240058 },
    { lat: 48.9213186, lng: 20.9247502 },
    { lat: 48.9201602, lng: 20.9273853 },
    { lat: 48.9191703, lng: 20.929475 },
    { lat: 48.9184612, lng: 20.9308214 },
    { lat: 48.9192675, lng: 20.9319068 },
    { lat: 48.9207005, lng: 20.9342949 },
    { lat: 48.920709, lng: 20.9344077 },
    { lat: 48.9215859, lng: 20.9356842 },
    { lat: 48.9220194, lng: 20.9345169 },
    { lat: 48.9222818, lng: 20.9336495 },
    { lat: 48.9224585, lng: 20.9330127 },
    { lat: 48.9225918, lng: 20.9322533 },
    { lat: 48.9227197, lng: 20.9322069 },
    { lat: 48.9227098, lng: 20.9323479 },
    { lat: 48.9231569, lng: 20.932289 },
    { lat: 48.9235023, lng: 20.9327194 },
    { lat: 48.9235118, lng: 20.9331707 },
    { lat: 48.9236394, lng: 20.9332213 },
    { lat: 48.923645, lng: 20.933149 },
    { lat: 48.9247075, lng: 20.9339563 },
    { lat: 48.9252908, lng: 20.9339635 },
    { lat: 48.9257088, lng: 20.9338732 },
    { lat: 48.9258644, lng: 20.9340204 },
    { lat: 48.9262672, lng: 20.9342162 },
    { lat: 48.9264842, lng: 20.9340753 },
    { lat: 48.9265544, lng: 20.9339539 },
    { lat: 48.9265594, lng: 20.9337989 },
    { lat: 48.9269257, lng: 20.9336105 },
    { lat: 48.9272811, lng: 20.9338397 },
    { lat: 48.9276218, lng: 20.9335911 },
    { lat: 48.9276878, lng: 20.9334482 },
    { lat: 48.9278499, lng: 20.9334149 },
    { lat: 48.9278938, lng: 20.933283 },
    { lat: 48.9280117, lng: 20.9332696 },
    { lat: 48.9281042, lng: 20.9335438 },
    { lat: 48.9282044, lng: 20.9335157 },
    { lat: 48.9283365, lng: 20.933291 },
    { lat: 48.9284682, lng: 20.933292 },
    { lat: 48.9286657, lng: 20.9334446 },
    { lat: 48.928835, lng: 20.9333223 },
    { lat: 48.9288524, lng: 20.933201 },
    { lat: 48.9287913, lng: 20.9330604 },
    { lat: 48.9288248, lng: 20.93292 },
    { lat: 48.9293613, lng: 20.9328678 },
    { lat: 48.9297727, lng: 20.9327508 },
    { lat: 48.9299205, lng: 20.9328339 },
    { lat: 48.929992, lng: 20.9327847 },
    { lat: 48.9301189, lng: 20.9325043 },
    { lat: 48.9302262, lng: 20.9324194 },
    { lat: 48.9304756, lng: 20.9325698 },
    { lat: 48.9308245, lng: 20.9326001 },
    { lat: 48.9309875, lng: 20.9325302 },
    { lat: 48.9310165, lng: 20.932354 },
    { lat: 48.931126, lng: 20.932299 },
    { lat: 48.9312831, lng: 20.9324186 },
    { lat: 48.9315695, lng: 20.9320628 },
    { lat: 48.9315993, lng: 20.9318747 },
    { lat: 48.9317279, lng: 20.9318859 },
    { lat: 48.931899, lng: 20.9317201 },
    { lat: 48.9319832, lng: 20.9315446 },
    { lat: 48.932271, lng: 20.9315664 },
    { lat: 48.9323475, lng: 20.9314628 },
    { lat: 48.932482, lng: 20.9314151 },
    { lat: 48.932753, lng: 20.9316401 },
    { lat: 48.9329833, lng: 20.931666 },
    { lat: 48.9332769, lng: 20.9313888 },
    { lat: 48.9337507, lng: 20.9313883 },
    { lat: 48.933904, lng: 20.9320698 },
    { lat: 48.934102, lng: 20.9321304 },
    { lat: 48.9348276, lng: 20.9319487 },
    { lat: 48.9352147, lng: 20.9316774 },
    { lat: 48.9356148, lng: 20.9312588 },
    { lat: 48.9360416, lng: 20.9309952 },
    { lat: 48.9364105, lng: 20.9308539 },
    { lat: 48.9367472, lng: 20.9305278 },
    { lat: 48.9369627, lng: 20.9302387 },
    { lat: 48.9374689, lng: 20.9292898 },
    { lat: 48.9389882, lng: 20.9302578 },
    { lat: 48.9391505, lng: 20.9301328 },
    { lat: 48.9394845, lng: 20.9300203 },
    { lat: 48.939884, lng: 20.930153 },
    { lat: 48.9403686, lng: 20.93017 },
    { lat: 48.9420181, lng: 20.9307924 },
    { lat: 48.9432071, lng: 20.930906 },
    { lat: 48.9434579, lng: 20.9310989 },
    { lat: 48.9436726, lng: 20.9313616 },
    { lat: 48.9438704, lng: 20.9319915 },
    { lat: 48.9442054, lng: 20.9319796 },
    { lat: 48.9444849, lng: 20.9321556 },
    { lat: 48.9445873, lng: 20.9323195 },
    { lat: 48.9446996, lng: 20.932347 },
    { lat: 48.9452634, lng: 20.9315067 },
    { lat: 48.9454562, lng: 20.9316519 },
    { lat: 48.9456607, lng: 20.9316078 },
    { lat: 48.9461549, lng: 20.9319188 },
    { lat: 48.9462541, lng: 20.9319812 },
    { lat: 48.9468018, lng: 20.9320018 },
    { lat: 48.9469614, lng: 20.9317958 },
    { lat: 48.9472241, lng: 20.9317729 },
    { lat: 48.9475369, lng: 20.9319718 },
    { lat: 48.9478011, lng: 20.9322346 },
    { lat: 48.9481523, lng: 20.9324649 },
    { lat: 48.9481364, lng: 20.9335397 },
    { lat: 48.9486561, lng: 20.9343017 },
    { lat: 48.9490444, lng: 20.9346355 },
    { lat: 48.9492365, lng: 20.9349956 },
    { lat: 48.9492697, lng: 20.9351204 },
    { lat: 48.9495328, lng: 20.9361089 },
    { lat: 48.9498283, lng: 20.936735 },
    { lat: 48.9511196, lng: 20.938925 },
    { lat: 48.951331, lng: 20.939527 },
    { lat: 48.952058, lng: 20.938351 },
    { lat: 48.952988, lng: 20.936562 },
    { lat: 48.953874, lng: 20.935155 },
    { lat: 48.954731, lng: 20.934534 },
    { lat: 48.953646, lng: 20.9321961 },
    { lat: 48.9529401, lng: 20.9308802 },
    { lat: 48.9524621, lng: 20.9303574 },
    { lat: 48.9524236, lng: 20.9301523 },
    { lat: 48.9520405, lng: 20.9296996 },
    { lat: 48.9519515, lng: 20.9294938 },
    { lat: 48.9515484, lng: 20.9290232 },
    { lat: 48.9511917, lng: 20.9287253 },
    { lat: 48.9502045, lng: 20.9282506 },
    { lat: 48.9500805, lng: 20.9280776 },
    { lat: 48.9499422, lng: 20.9280518 },
    { lat: 48.9499138, lng: 20.9279444 },
    { lat: 48.9493141, lng: 20.9274483 },
    { lat: 48.94906, lng: 20.926813 },
    { lat: 48.9489621, lng: 20.926412 },
    { lat: 48.9489818, lng: 20.9262629 },
    { lat: 48.9489182, lng: 20.9261447 },
    { lat: 48.9489339, lng: 20.9259802 },
    { lat: 48.948745, lng: 20.9251812 },
    { lat: 48.9486035, lng: 20.9248538 },
    { lat: 48.9486378, lng: 20.9244332 },
    { lat: 48.9485134, lng: 20.9241521 },
    { lat: 48.948549, lng: 20.9239037 },
    { lat: 48.9486658, lng: 20.923731 },
    { lat: 48.9487981, lng: 20.9228387 },
    { lat: 48.9487853, lng: 20.9210213 },
    { lat: 48.9486168, lng: 20.920528 },
    { lat: 48.948636, lng: 20.9202984 },
    { lat: 48.9482487, lng: 20.9200967 },
    { lat: 48.9482432, lng: 20.9199686 },
    { lat: 48.9480665, lng: 20.9201628 },
    { lat: 48.9480353, lng: 20.9205462 },
    { lat: 48.9478372, lng: 20.9206791 },
    { lat: 48.9476003, lng: 20.9206992 },
    { lat: 48.9475135, lng: 20.9205932 },
    { lat: 48.9472969, lng: 20.9206397 },
    { lat: 48.9472202, lng: 20.9207352 },
    { lat: 48.9468994, lng: 20.9205037 },
    { lat: 48.9467513, lng: 20.9204833 },
    { lat: 48.9466523, lng: 20.9203878 },
    { lat: 48.9465799, lng: 20.920141 },
    { lat: 48.9464731, lng: 20.9203122 },
    { lat: 48.9461378, lng: 20.9202444 },
    { lat: 48.9460813, lng: 20.9200737 },
    { lat: 48.9458304, lng: 20.9202439 },
    { lat: 48.9457466, lng: 20.9205011 },
    { lat: 48.9454508, lng: 20.9208355 },
    { lat: 48.9451874, lng: 20.9205691 },
    { lat: 48.9450711, lng: 20.9202888 },
    { lat: 48.9449425, lng: 20.9202795 },
    { lat: 48.9449131, lng: 20.9201443 },
    { lat: 48.9447928, lng: 20.9200161 },
    { lat: 48.9447065, lng: 20.9196246 },
    { lat: 48.9448035, lng: 20.9194939 },
    { lat: 48.9441376, lng: 20.9194765 },
    { lat: 48.9422755, lng: 20.9191616 },
    { lat: 48.9419472, lng: 20.9191437 },
    { lat: 48.9417805, lng: 20.9192082 },
    { lat: 48.9414944, lng: 20.9187947 },
    { lat: 48.9412548, lng: 20.9185626 },
    { lat: 48.9404481, lng: 20.9179399 },
    { lat: 48.9389591, lng: 20.9187784 },
    { lat: 48.9387509, lng: 20.9184451 },
    { lat: 48.9387303, lng: 20.918239 },
    { lat: 48.9383693, lng: 20.9175462 },
    { lat: 48.938244, lng: 20.9173029 },
    { lat: 48.9382187, lng: 20.9175881 },
    { lat: 48.9376332, lng: 20.918109 },
    { lat: 48.9371175, lng: 20.9184791 },
    { lat: 48.936906, lng: 20.9185439 },
    { lat: 48.9366652, lng: 20.9185149 },
    { lat: 48.9364781, lng: 20.9184353 },
    { lat: 48.9357202, lng: 20.9177602 },
    { lat: 48.935287, lng: 20.9174623 },
    { lat: 48.9349476, lng: 20.9172742 },
    { lat: 48.934695, lng: 20.9172308 },
    { lat: 48.9349798, lng: 20.9166975 },
    { lat: 48.9351503, lng: 20.9161426 },
    { lat: 48.9351235, lng: 20.9155156 },
    { lat: 48.9352527, lng: 20.9147083 },
    { lat: 48.9351965, lng: 20.9145104 },
    { lat: 48.9352738, lng: 20.9140626 },
    { lat: 48.9351998, lng: 20.9138754 },
    { lat: 48.9352011, lng: 20.913411 },
    { lat: 48.9357255, lng: 20.9111753 },
    { lat: 48.9359065, lng: 20.910801 },
    { lat: 48.9355858, lng: 20.9105349 },
    { lat: 48.9358257, lng: 20.9099699 },
    { lat: 48.9360422, lng: 20.9092843 },
    { lat: 48.9357906, lng: 20.9090007 },
    { lat: 48.9355754, lng: 20.9091592 },
    { lat: 48.9356611, lng: 20.9093981 },
    { lat: 48.9353098, lng: 20.9097942 },
    { lat: 48.9351026, lng: 20.9090581 },
    { lat: 48.9350103, lng: 20.9088877 },
    { lat: 48.9352849, lng: 20.908518 },
    { lat: 48.9351242, lng: 20.9083268 },
    { lat: 48.9347754, lng: 20.907436 },
    { lat: 48.9349777, lng: 20.906729 },
    { lat: 48.9354144, lng: 20.9071235 },
    { lat: 48.9356213, lng: 20.9061179 },
    { lat: 48.935914, lng: 20.9052187 },
    { lat: 48.935561, lng: 20.9045985 },
    { lat: 48.9354813, lng: 20.9027785 },
    { lat: 48.9355375, lng: 20.9021752 },
    { lat: 48.9356329, lng: 20.9018355 },
    { lat: 48.9356903, lng: 20.9010356 },
    { lat: 48.9359302, lng: 20.9002758 },
    { lat: 48.9357962, lng: 20.8998731 },
    { lat: 48.935717, lng: 20.8994264 },
    { lat: 48.9357997, lng: 20.8986154 },
    { lat: 48.9360593, lng: 20.897533 },
    { lat: 48.9362425, lng: 20.897031 },
    { lat: 48.9362813, lng: 20.8962472 },
    { lat: 48.935962, lng: 20.894046 },
  ],
  Henclová: [
    { lat: 48.747876, lng: 20.598016 },
    { lat: 48.7484445, lng: 20.5981308 },
    { lat: 48.7509737, lng: 20.5979588 },
    { lat: 48.7520461, lng: 20.5982512 },
    { lat: 48.7526977, lng: 20.5979024 },
    { lat: 48.7536199, lng: 20.596889 },
    { lat: 48.7546962, lng: 20.5967671 },
    { lat: 48.7549294, lng: 20.5969249 },
    { lat: 48.755371, lng: 20.596898 },
    { lat: 48.7554268, lng: 20.5967923 },
    { lat: 48.7560254, lng: 20.5966316 },
    { lat: 48.7563446, lng: 20.5963412 },
    { lat: 48.7565118, lng: 20.5963268 },
    { lat: 48.7567685, lng: 20.5961066 },
    { lat: 48.7570299, lng: 20.5960819 },
    { lat: 48.7571875, lng: 20.5959602 },
    { lat: 48.7573156, lng: 20.5960162 },
    { lat: 48.7575228, lng: 20.5956113 },
    { lat: 48.7579175, lng: 20.5955415 },
    { lat: 48.7581277, lng: 20.5956581 },
    { lat: 48.7583463, lng: 20.5956619 },
    { lat: 48.758387, lng: 20.5955087 },
    { lat: 48.7586542, lng: 20.5952411 },
    { lat: 48.7589455, lng: 20.595454 },
    { lat: 48.7591374, lng: 20.5954933 },
    { lat: 48.7593475, lng: 20.5954197 },
    { lat: 48.7595875, lng: 20.5956101 },
    { lat: 48.7601169, lng: 20.5958666 },
    { lat: 48.760407, lng: 20.5958502 },
    { lat: 48.7606111, lng: 20.5957424 },
    { lat: 48.7608681, lng: 20.5958733 },
    { lat: 48.7615439, lng: 20.5959077 },
    { lat: 48.7617438, lng: 20.5958649 },
    { lat: 48.7618749, lng: 20.5957198 },
    { lat: 48.7619827, lng: 20.5958117 },
    { lat: 48.7626961, lng: 20.5956749 },
    { lat: 48.762955, lng: 20.5955801 },
    { lat: 48.7633138, lng: 20.5953277 },
    { lat: 48.7638473, lng: 20.5951685 },
    { lat: 48.7642421, lng: 20.5952493 },
    { lat: 48.7649717, lng: 20.5950001 },
    { lat: 48.7653332, lng: 20.5944209 },
    { lat: 48.7661571, lng: 20.5926884 },
    { lat: 48.7675181, lng: 20.5905354 },
    { lat: 48.768924, lng: 20.5868434 },
    { lat: 48.7691387, lng: 20.5860645 },
    { lat: 48.7691681, lng: 20.5850848 },
    { lat: 48.7693196, lng: 20.5844882 },
    { lat: 48.7696529, lng: 20.5839969 },
    { lat: 48.7701036, lng: 20.58358 },
    { lat: 48.770338, lng: 20.583152 },
    { lat: 48.7704291, lng: 20.582691 },
    { lat: 48.7705669, lng: 20.5825813 },
    { lat: 48.7712374, lng: 20.582782 },
    { lat: 48.7712466, lng: 20.5827227 },
    { lat: 48.771596, lng: 20.5828914 },
    { lat: 48.7722376, lng: 20.5829193 },
    { lat: 48.7722248, lng: 20.582996 },
    { lat: 48.7727934, lng: 20.5832713 },
    { lat: 48.7732455, lng: 20.5837826 },
    { lat: 48.7745166, lng: 20.5847039 },
    { lat: 48.7746596, lng: 20.5851634 },
    { lat: 48.7750734, lng: 20.5859143 },
    { lat: 48.7757588, lng: 20.5864943 },
    { lat: 48.7764785, lng: 20.5870518 },
    { lat: 48.7768641, lng: 20.5871565 },
    { lat: 48.7772401, lng: 20.5870654 },
    { lat: 48.7773841, lng: 20.5870929 },
    { lat: 48.7745104, lng: 20.5948889 },
    { lat: 48.7742638, lng: 20.5948242 },
    { lat: 48.774163, lng: 20.5945971 },
    { lat: 48.7735328, lng: 20.5938671 },
    { lat: 48.7723457, lng: 20.5937285 },
    { lat: 48.7708223, lng: 20.5936955 },
    { lat: 48.7687201, lng: 20.5960567 },
    { lat: 48.7683047, lng: 20.5973242 },
    { lat: 48.767645, lng: 20.5986764 },
    { lat: 48.7680472, lng: 20.5991452 },
    { lat: 48.7682457, lng: 20.5992514 },
    { lat: 48.7685532, lng: 20.5990351 },
    { lat: 48.7686942, lng: 20.5990454 },
    { lat: 48.769066, lng: 20.5997285 },
    { lat: 48.7694013, lng: 20.5999103 },
    { lat: 48.7696782, lng: 20.5998125 },
    { lat: 48.7701808, lng: 20.6002916 },
    { lat: 48.770178, lng: 20.6003643 },
    { lat: 48.7715066, lng: 20.5999347 },
    { lat: 48.7721323, lng: 20.5990697 },
    { lat: 48.7776003, lng: 20.6010566 },
    { lat: 48.7806121, lng: 20.6020773 },
    { lat: 48.7839462, lng: 20.6037588 },
    { lat: 48.7862695, lng: 20.6026973 },
    { lat: 48.7861837, lng: 20.6022264 },
    { lat: 48.7856747, lng: 20.6016356 },
    { lat: 48.7864099, lng: 20.6005905 },
    { lat: 48.7868839, lng: 20.5998161 },
    { lat: 48.7866835, lng: 20.5987439 },
    { lat: 48.7865755, lng: 20.598403 },
    { lat: 48.7865984, lng: 20.5982611 },
    { lat: 48.7871263, lng: 20.5986586 },
    { lat: 48.787169, lng: 20.5988143 },
    { lat: 48.7871708, lng: 20.5992618 },
    { lat: 48.7873783, lng: 20.599885 },
    { lat: 48.7876603, lng: 20.6001452 },
    { lat: 48.7883193, lng: 20.6009873 },
    { lat: 48.7884225, lng: 20.6014739 },
    { lat: 48.7883949, lng: 20.601945 },
    { lat: 48.7885813, lng: 20.6022426 },
    { lat: 48.7895193, lng: 20.6028639 },
    { lat: 48.7899183, lng: 20.6029341 },
    { lat: 48.7901498, lng: 20.6032668 },
    { lat: 48.7903856, lng: 20.603431 },
    { lat: 48.7906197, lng: 20.6038013 },
    { lat: 48.7907781, lng: 20.6040091 },
    { lat: 48.7910753, lng: 20.6035747 },
    { lat: 48.7914357, lng: 20.6021902 },
    { lat: 48.7916142, lng: 20.5995583 },
    { lat: 48.7916609, lng: 20.599483 },
    { lat: 48.7914193, lng: 20.598939 },
    { lat: 48.7915953, lng: 20.5987976 },
    { lat: 48.7914638, lng: 20.598459 },
    { lat: 48.7909068, lng: 20.5980407 },
    { lat: 48.7893053, lng: 20.595994 },
    { lat: 48.7889015, lng: 20.5926811 },
    { lat: 48.7888983, lng: 20.5920655 },
    { lat: 48.7903306, lng: 20.5880459 },
    { lat: 48.7903826, lng: 20.5875017 },
    { lat: 48.7906923, lng: 20.5872872 },
    { lat: 48.79062, lng: 20.587099 },
    { lat: 48.790433, lng: 20.586909 },
    { lat: 48.7903263, lng: 20.5869455 },
    { lat: 48.7901548, lng: 20.5872484 },
    { lat: 48.7900274, lng: 20.5872741 },
    { lat: 48.7898895, lng: 20.5871932 },
    { lat: 48.7895955, lng: 20.5866719 },
    { lat: 48.789802, lng: 20.5866781 },
    { lat: 48.7899544, lng: 20.5865057 },
    { lat: 48.7900011, lng: 20.5865564 },
    { lat: 48.7901023, lng: 20.5863051 },
    { lat: 48.7899764, lng: 20.5860301 },
    { lat: 48.7900056, lng: 20.5855727 },
    { lat: 48.790065, lng: 20.5853774 },
    { lat: 48.7899101, lng: 20.5848388 },
    { lat: 48.7899718, lng: 20.5845269 },
    { lat: 48.7898776, lng: 20.584443 },
    { lat: 48.7900393, lng: 20.5838221 },
    { lat: 48.7906516, lng: 20.5836307 },
    { lat: 48.7908663, lng: 20.5837462 },
    { lat: 48.7909886, lng: 20.5832081 },
    { lat: 48.7910641, lng: 20.5832079 },
    { lat: 48.7912085, lng: 20.5829715 },
    { lat: 48.7913335, lng: 20.5830088 },
    { lat: 48.7915277, lng: 20.5827126 },
    { lat: 48.7918789, lng: 20.5823445 },
    { lat: 48.79204, lng: 20.5824088 },
    { lat: 48.7921058, lng: 20.5818102 },
    { lat: 48.7922768, lng: 20.5818023 },
    { lat: 48.792614, lng: 20.581635 },
    { lat: 48.7929084, lng: 20.5820781 },
    { lat: 48.7930362, lng: 20.581697 },
    { lat: 48.7931682, lng: 20.5817073 },
    { lat: 48.7934262, lng: 20.581215 },
    { lat: 48.7942907, lng: 20.5813655 },
    { lat: 48.7944678, lng: 20.5814988 },
    { lat: 48.7948643, lng: 20.5814433 },
    { lat: 48.7947664, lng: 20.581012 },
    { lat: 48.7949933, lng: 20.5808433 },
    { lat: 48.7947449, lng: 20.580491 },
    { lat: 48.7949004, lng: 20.5803387 },
    { lat: 48.7947299, lng: 20.5797745 },
    { lat: 48.7947783, lng: 20.579588 },
    { lat: 48.7944216, lng: 20.5788316 },
    { lat: 48.794188, lng: 20.5784781 },
    { lat: 48.7940328, lng: 20.5780227 },
    { lat: 48.7941017, lng: 20.5777848 },
    { lat: 48.7941064, lng: 20.5769634 },
    { lat: 48.7941888, lng: 20.5763862 },
    { lat: 48.7942141, lng: 20.5755081 },
    { lat: 48.7940891, lng: 20.5754783 },
    { lat: 48.7940188, lng: 20.5750657 },
    { lat: 48.7940253, lng: 20.5746024 },
    { lat: 48.7939149, lng: 20.5744478 },
    { lat: 48.7940974, lng: 20.5738357 },
    { lat: 48.7927549, lng: 20.5731793 },
    { lat: 48.7918324, lng: 20.5729524 },
    { lat: 48.7909824, lng: 20.5728329 },
    { lat: 48.7904086, lng: 20.5712227 },
    { lat: 48.7890964, lng: 20.5692813 },
    { lat: 48.7875832, lng: 20.5677178 },
    { lat: 48.7867148, lng: 20.5665139 },
    { lat: 48.7855868, lng: 20.5647222 },
    { lat: 48.7848682, lng: 20.5626049 },
    { lat: 48.78466, lng: 20.5608041 },
    { lat: 48.7842506, lng: 20.5589536 },
    { lat: 48.7837328, lng: 20.5560598 },
    { lat: 48.7835532, lng: 20.5535148 },
    { lat: 48.7830527, lng: 20.552491 },
    { lat: 48.7823251, lng: 20.5515983 },
    { lat: 48.7817911, lng: 20.5511687 },
    { lat: 48.780888, lng: 20.549318 },
    { lat: 48.779807, lng: 20.550035 },
    { lat: 48.778484, lng: 20.551214 },
    { lat: 48.777082, lng: 20.55258 },
    { lat: 48.776338, lng: 20.553205 },
    { lat: 48.775186, lng: 20.554188 },
    { lat: 48.774788, lng: 20.554224 },
    { lat: 48.774425, lng: 20.554179 },
    { lat: 48.774054, lng: 20.554095 },
    { lat: 48.773372, lng: 20.554128 },
    { lat: 48.77288, lng: 20.554211 },
    { lat: 48.772123, lng: 20.554178 },
    { lat: 48.771778, lng: 20.554324 },
    { lat: 48.771644, lng: 20.554438 },
    { lat: 48.771215, lng: 20.554269 },
    { lat: 48.77086, lng: 20.554242 },
    { lat: 48.770036, lng: 20.553871 },
    { lat: 48.769725, lng: 20.55399 },
    { lat: 48.76908, lng: 20.554203 },
    { lat: 48.768547, lng: 20.554645 },
    { lat: 48.767864, lng: 20.554679 },
    { lat: 48.767603, lng: 20.55465 },
    { lat: 48.766968, lng: 20.554955 },
    { lat: 48.766681, lng: 20.554883 },
    { lat: 48.766173, lng: 20.555045 },
    { lat: 48.765862, lng: 20.555352 },
    { lat: 48.765428, lng: 20.555338 },
    { lat: 48.765294, lng: 20.555239 },
    { lat: 48.765037, lng: 20.554986 },
    { lat: 48.764734, lng: 20.555069 },
    { lat: 48.764485, lng: 20.555012 },
    { lat: 48.764234, lng: 20.55491 },
    { lat: 48.763584, lng: 20.554916 },
    { lat: 48.763273, lng: 20.554853 },
    { lat: 48.762808, lng: 20.554656 },
    { lat: 48.762041, lng: 20.554168 },
    { lat: 48.761267, lng: 20.553794 },
    { lat: 48.757734, lng: 20.555885 },
    { lat: 48.757465, lng: 20.556156 },
    { lat: 48.757311, lng: 20.556217 },
    { lat: 48.756259, lng: 20.556694 },
    { lat: 48.754747, lng: 20.557511 },
    { lat: 48.754589, lng: 20.558246 },
    { lat: 48.754398, lng: 20.558658 },
    { lat: 48.753939, lng: 20.559329 },
    { lat: 48.753287, lng: 20.559754 },
    { lat: 48.753098, lng: 20.559922 },
    { lat: 48.75295, lng: 20.560103 },
    { lat: 48.752814, lng: 20.560328 },
    { lat: 48.752686, lng: 20.560444 },
    { lat: 48.752524, lng: 20.560705 },
    { lat: 48.752454, lng: 20.561011 },
    { lat: 48.751891, lng: 20.561448 },
    { lat: 48.751647, lng: 20.561527 },
    { lat: 48.750971, lng: 20.56194 },
    { lat: 48.750648, lng: 20.562321 },
    { lat: 48.749583, lng: 20.563085 },
    { lat: 48.748977, lng: 20.563374 },
    { lat: 48.748808, lng: 20.563423 },
    { lat: 48.748508, lng: 20.563574 },
    { lat: 48.748079, lng: 20.563886 },
    { lat: 48.747546, lng: 20.564023 },
    { lat: 48.747373, lng: 20.564101 },
    { lat: 48.747088, lng: 20.564466 },
    { lat: 48.746981, lng: 20.564665 },
    { lat: 48.746896, lng: 20.565018 },
    { lat: 48.74664, lng: 20.566841 },
    { lat: 48.746517, lng: 20.567463 },
    { lat: 48.746497, lng: 20.568102 },
    { lat: 48.746428, lng: 20.568636 },
    { lat: 48.746312, lng: 20.569255 },
    { lat: 48.746044, lng: 20.570146 },
    { lat: 48.745913, lng: 20.570813 },
    { lat: 48.745629, lng: 20.572407 },
    { lat: 48.744292, lng: 20.575697 },
    { lat: 48.745906, lng: 20.577394 },
    { lat: 48.747425, lng: 20.584021 },
    { lat: 48.748556, lng: 20.590901 },
    { lat: 48.748214, lng: 20.592942 },
    { lat: 48.748274, lng: 20.593819 },
    { lat: 48.748097, lng: 20.596853 },
    { lat: 48.747876, lng: 20.598016 },
  ],
  Úhorná: [
    { lat: 48.688531, lng: 20.655181 },
    { lat: 48.687068, lng: 20.657614 },
    { lat: 48.686792, lng: 20.658042 },
    { lat: 48.686784, lng: 20.658022 },
    { lat: 48.686596, lng: 20.658076 },
    { lat: 48.686435, lng: 20.658178 },
    { lat: 48.686243, lng: 20.658352 },
    { lat: 48.686045, lng: 20.658668 },
    { lat: 48.685836, lng: 20.659132 },
    { lat: 48.685771, lng: 20.659499 },
    { lat: 48.685757, lng: 20.659753 },
    { lat: 48.685812, lng: 20.660567 },
    { lat: 48.685935, lng: 20.661291 },
    { lat: 48.685869, lng: 20.662015 },
    { lat: 48.685815, lng: 20.662314 },
    { lat: 48.685786, lng: 20.662673 },
    { lat: 48.685749, lng: 20.662915 },
    { lat: 48.685692, lng: 20.663096 },
    { lat: 48.685582, lng: 20.663427 },
    { lat: 48.685471, lng: 20.663617 },
    { lat: 48.685374, lng: 20.663875 },
    { lat: 48.685263, lng: 20.664347 },
    { lat: 48.685147, lng: 20.664509 },
    { lat: 48.685007, lng: 20.664662 },
    { lat: 48.684848, lng: 20.664881 },
    { lat: 48.684653, lng: 20.665375 },
    { lat: 48.684653, lng: 20.665572 },
    { lat: 48.684725, lng: 20.666036 },
    { lat: 48.68472, lng: 20.666264 },
    { lat: 48.684655, lng: 20.666568 },
    { lat: 48.68455, lng: 20.666844 },
    { lat: 48.684433, lng: 20.66725 },
    { lat: 48.684227, lng: 20.667773 },
    { lat: 48.6841637, lng: 20.6682108 },
    { lat: 48.684162, lng: 20.668313 },
    { lat: 48.684207, lng: 20.6706 },
    { lat: 48.685672, lng: 20.673433 },
    { lat: 48.687893, lng: 20.677787 },
    { lat: 48.6885075, lng: 20.6783486 },
    { lat: 48.6891036, lng: 20.6786165 },
    { lat: 48.691298, lng: 20.6781213 },
    { lat: 48.6920209, lng: 20.6781244 },
    { lat: 48.692557, lng: 20.677909 },
    { lat: 48.6941999, lng: 20.6761646 },
    { lat: 48.6945599, lng: 20.6768796 },
    { lat: 48.6947585, lng: 20.6771152 },
    { lat: 48.6949715, lng: 20.6772442 },
    { lat: 48.6953923, lng: 20.6771121 },
    { lat: 48.6960297, lng: 20.6771753 },
    { lat: 48.6973988, lng: 20.6774604 },
    { lat: 48.6983546, lng: 20.6774974 },
    { lat: 48.7008237, lng: 20.6779048 },
    { lat: 48.7011883, lng: 20.6788735 },
    { lat: 48.7019298, lng: 20.6812114 },
    { lat: 48.7022225, lng: 20.6823404 },
    { lat: 48.7026323, lng: 20.6834713 },
    { lat: 48.7033439, lng: 20.6834077 },
    { lat: 48.7066806, lng: 20.6837468 },
    { lat: 48.7090294, lng: 20.6829145 },
    { lat: 48.7099602, lng: 20.6824369 },
    { lat: 48.7104338, lng: 20.6815803 },
    { lat: 48.7106045, lng: 20.680939 },
    { lat: 48.710881, lng: 20.6786979 },
    { lat: 48.7109626, lng: 20.6778392 },
    { lat: 48.710913, lng: 20.6774622 },
    { lat: 48.7110634, lng: 20.6766307 },
    { lat: 48.7115606, lng: 20.675052 },
    { lat: 48.7117984, lng: 20.6739042 },
    { lat: 48.7121147, lng: 20.6733393 },
    { lat: 48.7123934, lng: 20.6730725 },
    { lat: 48.7127112, lng: 20.67249 },
    { lat: 48.7129491, lng: 20.6708712 },
    { lat: 48.7136318, lng: 20.6685713 },
    { lat: 48.7143533, lng: 20.6672171 },
    { lat: 48.714546, lng: 20.6667225 },
    { lat: 48.7147951, lng: 20.6664474 },
    { lat: 48.7150263, lng: 20.6659942 },
    { lat: 48.7152575, lng: 20.6656932 },
    { lat: 48.7155082, lng: 20.665587 },
    { lat: 48.7158036, lng: 20.6653192 },
    { lat: 48.7166752, lng: 20.6649819 },
    { lat: 48.7170876, lng: 20.6647411 },
    { lat: 48.7177001, lng: 20.6641805 },
    { lat: 48.7178104, lng: 20.6641765 },
    { lat: 48.7184615, lng: 20.663848 },
    { lat: 48.7188814, lng: 20.6638747 },
    { lat: 48.7191244, lng: 20.6639977 },
    { lat: 48.7192473, lng: 20.663906 },
    { lat: 48.7200311, lng: 20.6626313 },
    { lat: 48.7202811, lng: 20.6624132 },
    { lat: 48.7211042, lng: 20.6614512 },
    { lat: 48.7214316, lng: 20.6611967 },
    { lat: 48.7217237, lng: 20.6611442 },
    { lat: 48.7219116, lng: 20.6609586 },
    { lat: 48.7224377, lng: 20.6606838 },
    { lat: 48.7256065, lng: 20.6576785 },
    { lat: 48.7258, lng: 20.6576747 },
    { lat: 48.7268775, lng: 20.6580815 },
    { lat: 48.7278965, lng: 20.6586797 },
    { lat: 48.7293702, lng: 20.6594294 },
    { lat: 48.7296973, lng: 20.6596938 },
    { lat: 48.7308161, lng: 20.6591195 },
    { lat: 48.7321651, lng: 20.6587212 },
    { lat: 48.7327891, lng: 20.6577942 },
    { lat: 48.7329465, lng: 20.6575929 },
    { lat: 48.7334003, lng: 20.6570127 },
    { lat: 48.7337089, lng: 20.6567083 },
    { lat: 48.7338429, lng: 20.6566752 },
    { lat: 48.7340239, lng: 20.6564794 },
    { lat: 48.7340913, lng: 20.6562887 },
    { lat: 48.734482, lng: 20.6559755 },
    { lat: 48.7347117, lng: 20.6558806 },
    { lat: 48.7347757, lng: 20.6559406 },
    { lat: 48.7352693, lng: 20.655708 },
    { lat: 48.7353584, lng: 20.6555547 },
    { lat: 48.7356042, lng: 20.6555616 },
    { lat: 48.7357843, lng: 20.6554083 },
    { lat: 48.7358853, lng: 20.6551193 },
    { lat: 48.7360723, lng: 20.6550275 },
    { lat: 48.7361751, lng: 20.6546667 },
    { lat: 48.7363956, lng: 20.654534 },
    { lat: 48.7367232, lng: 20.6539759 },
    { lat: 48.7368353, lng: 20.6536164 },
    { lat: 48.7370308, lng: 20.6533866 },
    { lat: 48.7372467, lng: 20.6533812 },
    { lat: 48.7376856, lng: 20.6526256 },
    { lat: 48.7382058, lng: 20.6525338 },
    { lat: 48.7386112, lng: 20.6525371 },
    { lat: 48.738944, lng: 20.65234 },
    { lat: 48.738838, lng: 20.652177 },
    { lat: 48.737102, lng: 20.650693 },
    { lat: 48.73665, lng: 20.650281 },
    { lat: 48.73454, lng: 20.647658 },
    { lat: 48.733871, lng: 20.648162 },
    { lat: 48.733862, lng: 20.648164 },
    { lat: 48.733846, lng: 20.648181 },
    { lat: 48.733237, lng: 20.647907 },
    { lat: 48.733097, lng: 20.647894 },
    { lat: 48.732631, lng: 20.64798 },
    { lat: 48.732369, lng: 20.647979 },
    { lat: 48.732177, lng: 20.648054 },
    { lat: 48.731975, lng: 20.648185 },
    { lat: 48.731821, lng: 20.648377 },
    { lat: 48.731511, lng: 20.648747 },
    { lat: 48.731299, lng: 20.649028 },
    { lat: 48.731185, lng: 20.649295 },
    { lat: 48.731111, lng: 20.649468 },
    { lat: 48.731057, lng: 20.649655 },
    { lat: 48.730924, lng: 20.650179 },
    { lat: 48.730896, lng: 20.650307 },
    { lat: 48.730878, lng: 20.650286 },
    { lat: 48.730799, lng: 20.650196 },
    { lat: 48.730067, lng: 20.649384 },
    { lat: 48.72944, lng: 20.648677 },
    { lat: 48.729156, lng: 20.648356 },
    { lat: 48.728756, lng: 20.647859 },
    { lat: 48.728345, lng: 20.647333 },
    { lat: 48.728, lng: 20.646887 },
    { lat: 48.727637, lng: 20.646422 },
    { lat: 48.727311, lng: 20.646006 },
    { lat: 48.726804, lng: 20.64536 },
    { lat: 48.726455, lng: 20.644912 },
    { lat: 48.725968, lng: 20.64527 },
    { lat: 48.725884, lng: 20.645332 },
    { lat: 48.725447, lng: 20.645657 },
    { lat: 48.725413, lng: 20.645682 },
    { lat: 48.725195, lng: 20.645835 },
    { lat: 48.724988, lng: 20.64598 },
    { lat: 48.724952, lng: 20.646005 },
    { lat: 48.724679, lng: 20.646194 },
    { lat: 48.724486, lng: 20.646328 },
    { lat: 48.724382, lng: 20.646402 },
    { lat: 48.724059, lng: 20.646631 },
    { lat: 48.723997, lng: 20.646675 },
    { lat: 48.72372, lng: 20.646869 },
    { lat: 48.723554, lng: 20.646985 },
    { lat: 48.723169, lng: 20.647255 },
    { lat: 48.723117, lng: 20.647292 },
    { lat: 48.722758, lng: 20.647544 },
    { lat: 48.72268, lng: 20.647599 },
    { lat: 48.72264, lng: 20.647627 },
    { lat: 48.722567, lng: 20.647678 },
    { lat: 48.722495, lng: 20.647728 },
    { lat: 48.722294, lng: 20.647868 },
    { lat: 48.722084, lng: 20.648015 },
    { lat: 48.722003, lng: 20.64819 },
    { lat: 48.721817, lng: 20.64864 },
    { lat: 48.72158, lng: 20.648889 },
    { lat: 48.721245, lng: 20.649096 },
    { lat: 48.721097, lng: 20.649186 },
    { lat: 48.72096, lng: 20.649271 },
    { lat: 48.720508, lng: 20.649253 },
    { lat: 48.720152, lng: 20.649239 },
    { lat: 48.72013, lng: 20.649231 },
    { lat: 48.719891, lng: 20.649141 },
    { lat: 48.719635, lng: 20.649045 },
    { lat: 48.718889, lng: 20.648764 },
    { lat: 48.718714, lng: 20.648698 },
    { lat: 48.71853, lng: 20.648775 },
    { lat: 48.718196, lng: 20.648916 },
    { lat: 48.717908, lng: 20.649037 },
    { lat: 48.717751, lng: 20.649102 },
    { lat: 48.717543, lng: 20.64919 },
    { lat: 48.71728, lng: 20.649311 },
    { lat: 48.717058, lng: 20.649413 },
    { lat: 48.716776, lng: 20.649486 },
    { lat: 48.716192, lng: 20.649637 },
    { lat: 48.715921, lng: 20.649707 },
    { lat: 48.715671, lng: 20.649772 },
    { lat: 48.714699, lng: 20.649126 },
    { lat: 48.714475, lng: 20.64907 },
    { lat: 48.713692, lng: 20.648903 },
    { lat: 48.712405, lng: 20.648628 },
    { lat: 48.712007, lng: 20.648172 },
    { lat: 48.711582, lng: 20.648001 },
    { lat: 48.711201, lng: 20.648042 },
    { lat: 48.710938, lng: 20.648075 },
    { lat: 48.710567, lng: 20.648121 },
    { lat: 48.710416, lng: 20.64814 },
    { lat: 48.709895, lng: 20.648206 },
    { lat: 48.709605, lng: 20.648242 },
    { lat: 48.709556, lng: 20.648249 },
    { lat: 48.709192, lng: 20.648296 },
    { lat: 48.709041, lng: 20.648314 },
    { lat: 48.708892, lng: 20.648333 },
    { lat: 48.708717, lng: 20.648355 },
    { lat: 48.708643, lng: 20.648365 },
    { lat: 48.708538, lng: 20.648378 },
    { lat: 48.708301, lng: 20.648408 },
    { lat: 48.708208, lng: 20.648424 },
    { lat: 48.708078, lng: 20.648447 },
    { lat: 48.707953, lng: 20.64847 },
    { lat: 48.707664, lng: 20.648522 },
    { lat: 48.707479, lng: 20.648554 },
    { lat: 48.707299, lng: 20.648586 },
    { lat: 48.707156, lng: 20.648612 },
    { lat: 48.707012, lng: 20.648637 },
    { lat: 48.706852, lng: 20.648666 },
    { lat: 48.706686, lng: 20.648695 },
    { lat: 48.706602, lng: 20.64871 },
    { lat: 48.706522, lng: 20.648725 },
    { lat: 48.706347, lng: 20.648756 },
    { lat: 48.706217, lng: 20.648779 },
    { lat: 48.706073, lng: 20.648804 },
    { lat: 48.705798, lng: 20.648853 },
    { lat: 48.705726, lng: 20.648865 },
    { lat: 48.705495, lng: 20.648903 },
    { lat: 48.705233, lng: 20.648945 },
    { lat: 48.704926, lng: 20.648996 },
    { lat: 48.704651, lng: 20.649047 },
    { lat: 48.704499, lng: 20.649075 },
    { lat: 48.704392, lng: 20.649091 },
    { lat: 48.704238, lng: 20.649114 },
    { lat: 48.704065, lng: 20.64914 },
    { lat: 48.703907, lng: 20.649164 },
    { lat: 48.703744, lng: 20.649183 },
    { lat: 48.703635, lng: 20.649196 },
    { lat: 48.703532, lng: 20.649208 },
    { lat: 48.703424, lng: 20.649221 },
    { lat: 48.70313, lng: 20.649256 },
    { lat: 48.702979, lng: 20.649274 },
    { lat: 48.702833, lng: 20.649291 },
    { lat: 48.702503, lng: 20.649331 },
    { lat: 48.702353, lng: 20.649348 },
    { lat: 48.702218, lng: 20.649365 },
    { lat: 48.702203, lng: 20.649366 },
    { lat: 48.702141, lng: 20.649366 },
    { lat: 48.702112, lng: 20.649366 },
    { lat: 48.701879, lng: 20.649365 },
    { lat: 48.701701, lng: 20.649365 },
    { lat: 48.701533, lng: 20.649364 },
    { lat: 48.701365, lng: 20.649364 },
    { lat: 48.701188, lng: 20.649363 },
    { lat: 48.700858, lng: 20.649363 },
    { lat: 48.700502, lng: 20.649361 },
    { lat: 48.700324, lng: 20.649361 },
    { lat: 48.700149, lng: 20.64936 },
    { lat: 48.699857, lng: 20.649359 },
    { lat: 48.699619, lng: 20.649358 },
    { lat: 48.699439, lng: 20.649358 },
    { lat: 48.699058, lng: 20.649357 },
    { lat: 48.69887, lng: 20.649423 },
    { lat: 48.698684, lng: 20.649488 },
    { lat: 48.698477, lng: 20.649562 },
    { lat: 48.698271, lng: 20.649634 },
    { lat: 48.698074, lng: 20.649703 },
    { lat: 48.697745, lng: 20.649819 },
    { lat: 48.69743, lng: 20.64993 },
    { lat: 48.697235, lng: 20.649999 },
    { lat: 48.697038, lng: 20.650068 },
    { lat: 48.696928, lng: 20.650106 },
    { lat: 48.696814, lng: 20.650146 },
    { lat: 48.696706, lng: 20.650185 },
    { lat: 48.696589, lng: 20.650226 },
    { lat: 48.696413, lng: 20.650287 },
    { lat: 48.696254, lng: 20.650343 },
    { lat: 48.696204, lng: 20.650361 },
    { lat: 48.696065, lng: 20.65041 },
    { lat: 48.695968, lng: 20.650452 },
    { lat: 48.69583, lng: 20.650515 },
    { lat: 48.695655, lng: 20.650593 },
    { lat: 48.695562, lng: 20.650635 },
    { lat: 48.695459, lng: 20.65068 },
    { lat: 48.695383, lng: 20.650714 },
    { lat: 48.695302, lng: 20.65075 },
    { lat: 48.695225, lng: 20.650784 },
    { lat: 48.695147, lng: 20.65082 },
    { lat: 48.694963, lng: 20.650902 },
    { lat: 48.694817, lng: 20.650967 },
    { lat: 48.694466, lng: 20.651123 },
    { lat: 48.694293, lng: 20.6512 },
    { lat: 48.694125, lng: 20.651275 },
    { lat: 48.693943, lng: 20.651356 },
    { lat: 48.693757, lng: 20.651439 },
    { lat: 48.693572, lng: 20.651522 },
    { lat: 48.693396, lng: 20.6516 },
    { lat: 48.6933, lng: 20.651643 },
    { lat: 48.693177, lng: 20.651698 },
    { lat: 48.693053, lng: 20.651787 },
    { lat: 48.692919, lng: 20.651883 },
    { lat: 48.69285, lng: 20.651932 },
    { lat: 48.69278, lng: 20.651983 },
    { lat: 48.69266, lng: 20.652069 },
    { lat: 48.69253, lng: 20.652162 },
    { lat: 48.691149, lng: 20.653153 },
    { lat: 48.690192, lng: 20.65392 },
    { lat: 48.688531, lng: 20.655181 },
  ],
  Švedlár: [
    { lat: 48.7796875, lng: 20.7514491 },
    { lat: 48.7796986, lng: 20.7515346 },
    { lat: 48.7797677, lng: 20.7517703 },
    { lat: 48.780068, lng: 20.7518889 },
    { lat: 48.78037, lng: 20.7522854 },
    { lat: 48.7805398, lng: 20.7523731 },
    { lat: 48.7808284, lng: 20.7523534 },
    { lat: 48.7809901, lng: 20.7525056 },
    { lat: 48.7811487, lng: 20.7525606 },
    { lat: 48.7814442, lng: 20.7528341 },
    { lat: 48.7816552, lng: 20.7532637 },
    { lat: 48.7818035, lng: 20.7534505 },
    { lat: 48.7820806, lng: 20.7535806 },
    { lat: 48.782289, lng: 20.7535524 },
    { lat: 48.7824625, lng: 20.753648 },
    { lat: 48.7826573, lng: 20.754162 },
    { lat: 48.7829663, lng: 20.7543684 },
    { lat: 48.7830718, lng: 20.7546183 },
    { lat: 48.7832378, lng: 20.754696 },
    { lat: 48.783643, lng: 20.7551445 },
    { lat: 48.783729, lng: 20.7550612 },
    { lat: 48.7838444, lng: 20.7551436 },
    { lat: 48.78405, lng: 20.7553534 },
    { lat: 48.7840807, lng: 20.7554551 },
    { lat: 48.7842363, lng: 20.7555586 },
    { lat: 48.7843151, lng: 20.7557821 },
    { lat: 48.7844746, lng: 20.7559035 },
    { lat: 48.7845779, lng: 20.7561169 },
    { lat: 48.7847781, lng: 20.7562414 },
    { lat: 48.7851305, lng: 20.7570116 },
    { lat: 48.7854308, lng: 20.7572262 },
    { lat: 48.7856266, lng: 20.7574471 },
    { lat: 48.7861232, lng: 20.757738 },
    { lat: 48.7866212, lng: 20.7583273 },
    { lat: 48.7868828, lng: 20.7585293 },
    { lat: 48.7870562, lng: 20.7585621 },
    { lat: 48.7871187, lng: 20.7587092 },
    { lat: 48.7872829, lng: 20.7587049 },
    { lat: 48.7876864, lng: 20.7589744 },
    { lat: 48.7877589, lng: 20.7592474 },
    { lat: 48.7880246, lng: 20.7597579 },
    { lat: 48.7881044, lng: 20.75982 },
    { lat: 48.7886262, lng: 20.7598676 },
    { lat: 48.7891248, lng: 20.7600863 },
    { lat: 48.7894606, lng: 20.7601306 },
    { lat: 48.7895628, lng: 20.7600239 },
    { lat: 48.789959, lng: 20.7601219 },
    { lat: 48.7901858, lng: 20.7600726 },
    { lat: 48.7902148, lng: 20.7599964 },
    { lat: 48.7904259, lng: 20.7598887 },
    { lat: 48.7906137, lng: 20.7600446 },
    { lat: 48.7907938, lng: 20.7597852 },
    { lat: 48.7909457, lng: 20.7597187 },
    { lat: 48.7913424, lng: 20.7592869 },
    { lat: 48.7917223, lng: 20.7598111 },
    { lat: 48.7917195, lng: 20.7599888 },
    { lat: 48.791953, lng: 20.7602877 },
    { lat: 48.7920596, lng: 20.7607915 },
    { lat: 48.7919839, lng: 20.7613949 },
    { lat: 48.7920554, lng: 20.7615037 },
    { lat: 48.7918165, lng: 20.7629104 },
    { lat: 48.7914579, lng: 20.7637879 },
    { lat: 48.7922345, lng: 20.7645212 },
    { lat: 48.7918682, lng: 20.7653079 },
    { lat: 48.7915028, lng: 20.7666058 },
    { lat: 48.7912699, lng: 20.7678259 },
    { lat: 48.791124, lng: 20.7691547 },
    { lat: 48.7912778, lng: 20.7695665 },
    { lat: 48.7919082, lng: 20.7692395 },
    { lat: 48.7971518, lng: 20.7676349 },
    { lat: 48.7975362, lng: 20.7673951 },
    { lat: 48.7985733, lng: 20.76699 },
    { lat: 48.7995988, lng: 20.7663123 },
    { lat: 48.8020696, lng: 20.7636764 },
    { lat: 48.8035227, lng: 20.7618093 },
    { lat: 48.8041707, lng: 20.7607761 },
    { lat: 48.8042964, lng: 20.7607264 },
    { lat: 48.8052806, lng: 20.7596472 },
    { lat: 48.8055991, lng: 20.758878 },
    { lat: 48.8087763, lng: 20.7570281 },
    { lat: 48.8093273, lng: 20.7570479 },
    { lat: 48.8104349, lng: 20.7568483 },
    { lat: 48.8117787, lng: 20.7563632 },
    { lat: 48.8121686, lng: 20.7557635 },
    { lat: 48.8128887, lng: 20.7544081 },
    { lat: 48.8131261, lng: 20.7543093 },
    { lat: 48.813327, lng: 20.7537834 },
    { lat: 48.8141733, lng: 20.7531987 },
    { lat: 48.81479, lng: 20.7522956 },
    { lat: 48.815608, lng: 20.7516498 },
    { lat: 48.8161907, lng: 20.7509002 },
    { lat: 48.8174505, lng: 20.7487245 },
    { lat: 48.8184419, lng: 20.7490041 },
    { lat: 48.8199651, lng: 20.749199 },
    { lat: 48.8209693, lng: 20.7496805 },
    { lat: 48.821977, lng: 20.7494905 },
    { lat: 48.8228371, lng: 20.7499073 },
    { lat: 48.8242474, lng: 20.7507951 },
    { lat: 48.8254334, lng: 20.750719 },
    { lat: 48.8264765, lng: 20.7503225 },
    { lat: 48.8272817, lng: 20.7508055 },
    { lat: 48.828032, lng: 20.7509514 },
    { lat: 48.8290578, lng: 20.7511226 },
    { lat: 48.8314202, lng: 20.7512804 },
    { lat: 48.8321236, lng: 20.7513274 },
    { lat: 48.833256, lng: 20.750717 },
    { lat: 48.833328, lng: 20.750624 },
    { lat: 48.833389, lng: 20.750537 },
    { lat: 48.833449, lng: 20.750454 },
    { lat: 48.833664, lng: 20.75016 },
    { lat: 48.833838, lng: 20.749786 },
    { lat: 48.833994, lng: 20.749335 },
    { lat: 48.834266, lng: 20.748445 },
    { lat: 48.83436, lng: 20.748159 },
    { lat: 48.834435, lng: 20.747928 },
    { lat: 48.834573, lng: 20.74764 },
    { lat: 48.834788, lng: 20.747192 },
    { lat: 48.834839, lng: 20.747108 },
    { lat: 48.835296, lng: 20.746572 },
    { lat: 48.835699, lng: 20.746145 },
    { lat: 48.835871, lng: 20.746104 },
    { lat: 48.836069, lng: 20.746057 },
    { lat: 48.836122, lng: 20.746073 },
    { lat: 48.836422, lng: 20.746168 },
    { lat: 48.836636, lng: 20.746235 },
    { lat: 48.836985, lng: 20.746436 },
    { lat: 48.837065, lng: 20.746483 },
    { lat: 48.837174, lng: 20.746551 },
    { lat: 48.837321, lng: 20.746487 },
    { lat: 48.837396, lng: 20.746452 },
    { lat: 48.837485, lng: 20.746418 },
    { lat: 48.837759, lng: 20.746265 },
    { lat: 48.837918, lng: 20.746156 },
    { lat: 48.838004, lng: 20.746064 },
    { lat: 48.838207, lng: 20.745924 },
    { lat: 48.838397, lng: 20.745789 },
    { lat: 48.838545, lng: 20.745676 },
    { lat: 48.838656, lng: 20.745473 },
    { lat: 48.838708, lng: 20.745254 },
    { lat: 48.838742, lng: 20.744826 },
    { lat: 48.838733, lng: 20.744535 },
    { lat: 48.838772, lng: 20.744172 },
    { lat: 48.838785, lng: 20.744108 },
    { lat: 48.838853, lng: 20.743795 },
    { lat: 48.838874, lng: 20.743746 },
    { lat: 48.838919, lng: 20.743642 },
    { lat: 48.839003, lng: 20.743408 },
    { lat: 48.839239, lng: 20.742874 },
    { lat: 48.839282, lng: 20.742675 },
    { lat: 48.839226, lng: 20.742092 },
    { lat: 48.839232, lng: 20.741972 },
    { lat: 48.839254, lng: 20.741856 },
    { lat: 48.839279, lng: 20.74174 },
    { lat: 48.839318, lng: 20.741705 },
    { lat: 48.839378, lng: 20.741642 },
    { lat: 48.839439, lng: 20.741583 },
    { lat: 48.83952, lng: 20.741504 },
    { lat: 48.840126, lng: 20.74092 },
    { lat: 48.840541, lng: 20.740515 },
    { lat: 48.840582, lng: 20.740478 },
    { lat: 48.841426, lng: 20.739522 },
    { lat: 48.842003, lng: 20.738857 },
    { lat: 48.842496, lng: 20.738292 },
    { lat: 48.842898, lng: 20.737824 },
    { lat: 48.843063, lng: 20.73765 },
    { lat: 48.843551, lng: 20.737147 },
    { lat: 48.843818, lng: 20.736879 },
    { lat: 48.843862, lng: 20.73679 },
    { lat: 48.843907, lng: 20.736692 },
    { lat: 48.84395, lng: 20.736598 },
    { lat: 48.843997, lng: 20.736503 },
    { lat: 48.84404, lng: 20.736408 },
    { lat: 48.844085, lng: 20.736314 },
    { lat: 48.844193, lng: 20.736076 },
    { lat: 48.844331, lng: 20.735785 },
    { lat: 48.8444, lng: 20.735638 },
    { lat: 48.844455, lng: 20.735011 },
    { lat: 48.844522, lng: 20.734241 },
    { lat: 48.844564, lng: 20.733805 },
    { lat: 48.844677, lng: 20.733314 },
    { lat: 48.844742, lng: 20.733037 },
    { lat: 48.844855, lng: 20.732556 },
    { lat: 48.844864, lng: 20.732193 },
    { lat: 48.844871, lng: 20.731762 },
    { lat: 48.844909, lng: 20.731371 },
    { lat: 48.845005, lng: 20.730505 },
    { lat: 48.84509, lng: 20.729673 },
    { lat: 48.845112, lng: 20.729565 },
    { lat: 48.84521, lng: 20.729065 },
    { lat: 48.845255, lng: 20.728586 },
    { lat: 48.845317, lng: 20.727876 },
    { lat: 48.84535, lng: 20.727499 },
    { lat: 48.845367, lng: 20.727267 },
    { lat: 48.84538, lng: 20.72716 },
    { lat: 48.845389, lng: 20.727047 },
    { lat: 48.845401, lng: 20.726935 },
    { lat: 48.845411, lng: 20.726816 },
    { lat: 48.845423, lng: 20.726706 },
    { lat: 48.845436, lng: 20.726443 },
    { lat: 48.845582, lng: 20.725986 },
    { lat: 48.845676, lng: 20.725691 },
    { lat: 48.845731, lng: 20.72552 },
    { lat: 48.846102, lng: 20.724368 },
    { lat: 48.846767, lng: 20.723533 },
    { lat: 48.846957, lng: 20.723296 },
    { lat: 48.848138, lng: 20.722222 },
    { lat: 48.84842, lng: 20.721946 },
    { lat: 48.848924, lng: 20.72143 },
    { lat: 48.84929, lng: 20.721049 },
    { lat: 48.849621, lng: 20.720648 },
    { lat: 48.849968, lng: 20.720226 },
    { lat: 48.850159, lng: 20.719956 },
    { lat: 48.850239, lng: 20.719841 },
    { lat: 48.850894, lng: 20.718915 },
    { lat: 48.85106, lng: 20.718681 },
    { lat: 48.851073, lng: 20.718656 },
    { lat: 48.851288, lng: 20.71819 },
    { lat: 48.851502, lng: 20.717733 },
    { lat: 48.851537, lng: 20.717652 },
    { lat: 48.85219, lng: 20.716708 },
    { lat: 48.852532, lng: 20.716206 },
    { lat: 48.852729, lng: 20.715947 },
    { lat: 48.853333, lng: 20.715127 },
    { lat: 48.853621, lng: 20.714685 },
    { lat: 48.853972, lng: 20.714142 },
    { lat: 48.854147, lng: 20.713874 },
    { lat: 48.854508, lng: 20.713334 },
    { lat: 48.855059, lng: 20.712242 },
    { lat: 48.855394, lng: 20.711562 },
    { lat: 48.855526, lng: 20.711294 },
    { lat: 48.855798, lng: 20.710949 },
    { lat: 48.856062, lng: 20.710624 },
    { lat: 48.85617, lng: 20.7104 },
    { lat: 48.856261, lng: 20.7094 },
    { lat: 48.856299, lng: 20.708997 },
    { lat: 48.856291, lng: 20.708242 },
    { lat: 48.856146, lng: 20.70694 },
    { lat: 48.856148, lng: 20.70652 },
    { lat: 48.856105, lng: 20.706173 },
    { lat: 48.856013, lng: 20.705174 },
    { lat: 48.856055, lng: 20.704822 },
    { lat: 48.856114, lng: 20.704319 },
    { lat: 48.856127, lng: 20.703633 },
    { lat: 48.856057, lng: 20.702819 },
    { lat: 48.856051, lng: 20.702155 },
    { lat: 48.855982, lng: 20.700882 },
    { lat: 48.855966, lng: 20.700559 },
    { lat: 48.855932, lng: 20.700229 },
    { lat: 48.855902, lng: 20.699938 },
    { lat: 48.855939, lng: 20.69953 },
    { lat: 48.855959, lng: 20.699315 },
    { lat: 48.856013, lng: 20.698848 },
    { lat: 48.856036, lng: 20.69866 },
    { lat: 48.856124, lng: 20.698009 },
    { lat: 48.855882, lng: 20.696915 },
    { lat: 48.856685, lng: 20.695273 },
    { lat: 48.856615, lng: 20.694714 },
    { lat: 48.857075, lng: 20.693949 },
    { lat: 48.857098, lng: 20.693908 },
    { lat: 48.857592, lng: 20.693086 },
    { lat: 48.858042, lng: 20.692309 },
    { lat: 48.858252, lng: 20.691948 },
    { lat: 48.858876, lng: 20.69158 },
    { lat: 48.859042, lng: 20.691311 },
    { lat: 48.85943, lng: 20.690684 },
    { lat: 48.859812, lng: 20.690056 },
    { lat: 48.860198, lng: 20.689394 },
    { lat: 48.860686, lng: 20.688557 },
    { lat: 48.861339, lng: 20.688076 },
    { lat: 48.861364, lng: 20.688068 },
    { lat: 48.861697, lng: 20.687423 },
    { lat: 48.861866, lng: 20.687105 },
    { lat: 48.861995, lng: 20.686859 },
    { lat: 48.862113, lng: 20.686634 },
    { lat: 48.862132, lng: 20.686173 },
    { lat: 48.862122, lng: 20.685067 },
    { lat: 48.862253, lng: 20.684504 },
    { lat: 48.862268, lng: 20.684503 },
    { lat: 48.862251, lng: 20.683903 },
    { lat: 48.862287, lng: 20.683417 },
    { lat: 48.862358, lng: 20.682885 },
    { lat: 48.862238, lng: 20.682051 },
    { lat: 48.862215, lng: 20.681942 },
    { lat: 48.862215, lng: 20.681919 },
    { lat: 48.862048, lng: 20.681243 },
    { lat: 48.861872, lng: 20.679967 },
    { lat: 48.861892, lng: 20.679971 },
    { lat: 48.861956, lng: 20.67894 },
    { lat: 48.862016, lng: 20.678653 },
    { lat: 48.862027, lng: 20.677748 },
    { lat: 48.862224, lng: 20.676874 },
    { lat: 48.862375, lng: 20.676208 },
    { lat: 48.862724, lng: 20.674829 },
    { lat: 48.862715, lng: 20.674821 },
    { lat: 48.862974, lng: 20.67388 },
    { lat: 48.862929, lng: 20.673489 },
    { lat: 48.862858, lng: 20.672804 },
    { lat: 48.862775, lng: 20.672139 },
    { lat: 48.862718, lng: 20.671715 },
    { lat: 48.862732, lng: 20.671542 },
    { lat: 48.862709, lng: 20.670793 },
    { lat: 48.86261, lng: 20.669983 },
    { lat: 48.862468, lng: 20.66964 },
    { lat: 48.86241, lng: 20.669518 },
    { lat: 48.862355, lng: 20.669423 },
    { lat: 48.862049, lng: 20.668982 },
    { lat: 48.861433, lng: 20.66836 },
    { lat: 48.861156, lng: 20.668167 },
    { lat: 48.860507, lng: 20.668027 },
    { lat: 48.860278, lng: 20.668096 },
    { lat: 48.860049, lng: 20.668119 },
    { lat: 48.859682, lng: 20.668115 },
    { lat: 48.859349, lng: 20.668027 },
    { lat: 48.859124, lng: 20.667902 },
    { lat: 48.858755, lng: 20.667621 },
    { lat: 48.858411, lng: 20.667474 },
    { lat: 48.858125, lng: 20.667331 },
    { lat: 48.85779, lng: 20.667084 },
    { lat: 48.857597, lng: 20.666881 },
    { lat: 48.857185, lng: 20.666437 },
    { lat: 48.856951, lng: 20.666012 },
    { lat: 48.856789, lng: 20.665769 },
    { lat: 48.856398, lng: 20.665318 },
    { lat: 48.856065, lng: 20.665034 },
    { lat: 48.855652, lng: 20.664657 },
    { lat: 48.855483, lng: 20.664395 },
    { lat: 48.85537, lng: 20.66422 },
    { lat: 48.855225, lng: 20.66395 },
    { lat: 48.855207, lng: 20.663962 },
    { lat: 48.8548, lng: 20.664164 },
    { lat: 48.854183, lng: 20.664459 },
    { lat: 48.853354, lng: 20.664877 },
    { lat: 48.852769, lng: 20.665167 },
    { lat: 48.852247, lng: 20.665431 },
    { lat: 48.851514, lng: 20.665792 },
    { lat: 48.850818, lng: 20.666173 },
    { lat: 48.850284, lng: 20.666463 },
    { lat: 48.849891, lng: 20.666679 },
    { lat: 48.849199, lng: 20.667053 },
    { lat: 48.848694, lng: 20.667328 },
    { lat: 48.848077, lng: 20.667666 },
    { lat: 48.847321, lng: 20.668078 },
    { lat: 48.846867, lng: 20.668316 },
    { lat: 48.846202, lng: 20.668668 },
    { lat: 48.846199, lng: 20.668643 },
    { lat: 48.846191, lng: 20.668612 },
    { lat: 48.8454664, lng: 20.6690936 },
    { lat: 48.8452378, lng: 20.6691546 },
    { lat: 48.8449913, lng: 20.6693121 },
    { lat: 48.8444601, lng: 20.6699457 },
    { lat: 48.8439201, lng: 20.6709891 },
    { lat: 48.8429813, lng: 20.6716501 },
    { lat: 48.8428922, lng: 20.6721528 },
    { lat: 48.8426834, lng: 20.6721371 },
    { lat: 48.8419414, lng: 20.6714713 },
    { lat: 48.8417913, lng: 20.6713067 },
    { lat: 48.8416548, lng: 20.6710453 },
    { lat: 48.8415211, lng: 20.6709952 },
    { lat: 48.8413688, lng: 20.6710756 },
    { lat: 48.8400187, lng: 20.6708548 },
    { lat: 48.8396624, lng: 20.6706472 },
    { lat: 48.8392853, lng: 20.6705839 },
    { lat: 48.839102, lng: 20.6705929 },
    { lat: 48.8385379, lng: 20.6709053 },
    { lat: 48.8375334, lng: 20.671176 },
    { lat: 48.8373904, lng: 20.6708661 },
    { lat: 48.8373119, lng: 20.6708086 },
    { lat: 48.8369713, lng: 20.6708231 },
    { lat: 48.8363494, lng: 20.6711245 },
    { lat: 48.8356726, lng: 20.6712771 },
    { lat: 48.8352307, lng: 20.6716951 },
    { lat: 48.8352046, lng: 20.671658 },
    { lat: 48.8345358, lng: 20.6725049 },
    { lat: 48.8340769, lng: 20.6728011 },
    { lat: 48.8337696, lng: 20.6733031 },
    { lat: 48.833768, lng: 20.6732246 },
    { lat: 48.8332873, lng: 20.6733517 },
    { lat: 48.832824, lng: 20.6736829 },
    { lat: 48.8318254, lng: 20.6740248 },
    { lat: 48.831595, lng: 20.6741638 },
    { lat: 48.8311648, lng: 20.6742539 },
    { lat: 48.8304429, lng: 20.6749351 },
    { lat: 48.8298961, lng: 20.6751022 },
    { lat: 48.8293757, lng: 20.6754055 },
    { lat: 48.8279835, lng: 20.6754797 },
    { lat: 48.8268006, lng: 20.6754273 },
    { lat: 48.824577, lng: 20.6749817 },
    { lat: 48.8242269, lng: 20.6750156 },
    { lat: 48.8240336, lng: 20.6750236 },
    { lat: 48.8238357, lng: 20.6750338 },
    { lat: 48.8230004, lng: 20.6749346 },
    { lat: 48.82244, lng: 20.6749449 },
    { lat: 48.8217434, lng: 20.675044 },
    { lat: 48.8213484, lng: 20.6748647 },
    { lat: 48.8210542, lng: 20.6748192 },
    { lat: 48.82055, lng: 20.67489 },
    { lat: 48.820352, lng: 20.6746115 },
    { lat: 48.8197198, lng: 20.6719559 },
    { lat: 48.8195654, lng: 20.6691292 },
    { lat: 48.8191558, lng: 20.6669416 },
    { lat: 48.8191339, lng: 20.6653669 },
    { lat: 48.819047, lng: 20.6649854 },
    { lat: 48.8187237, lng: 20.6644433 },
    { lat: 48.8183112, lng: 20.6639293 },
    { lat: 48.81761, lng: 20.6633091 },
    { lat: 48.8174098, lng: 20.6632203 },
    { lat: 48.8172199, lng: 20.664079 },
    { lat: 48.8172899, lng: 20.6640507 },
    { lat: 48.8172258, lng: 20.6643354 },
    { lat: 48.8172516, lng: 20.6648634 },
    { lat: 48.8171855, lng: 20.6660295 },
    { lat: 48.8160978, lng: 20.6668721 },
    { lat: 48.8160385, lng: 20.6672242 },
    { lat: 48.8158102, lng: 20.6676124 },
    { lat: 48.8155971, lng: 20.6681633 },
    { lat: 48.8146158, lng: 20.669807 },
    { lat: 48.8140488, lng: 20.6703809 },
    { lat: 48.8133504, lng: 20.6709303 },
    { lat: 48.8130271, lng: 20.6712751 },
    { lat: 48.8128095, lng: 20.6716424 },
    { lat: 48.8127797, lng: 20.6718749 },
    { lat: 48.8125697, lng: 20.6723922 },
    { lat: 48.8125071, lng: 20.6726528 },
    { lat: 48.8124975, lng: 20.6732676 },
    { lat: 48.8117808, lng: 20.6751268 },
    { lat: 48.8113525, lng: 20.6758414 },
    { lat: 48.810394, lng: 20.6764531 },
    { lat: 48.8098852, lng: 20.6767043 },
    { lat: 48.8096258, lng: 20.6770688 },
    { lat: 48.809404, lng: 20.6776335 },
    { lat: 48.8094238, lng: 20.6779691 },
    { lat: 48.8088895, lng: 20.677668 },
    { lat: 48.8088081, lng: 20.6779697 },
    { lat: 48.8087532, lng: 20.6783203 },
    { lat: 48.8091574, lng: 20.67958 },
    { lat: 48.8092753, lng: 20.6804197 },
    { lat: 48.8095475, lng: 20.6814453 },
    { lat: 48.8095419, lng: 20.682548 },
    { lat: 48.8098008, lng: 20.683684 },
    { lat: 48.8102137, lng: 20.6850888 },
    { lat: 48.8103292, lng: 20.6857994 },
    { lat: 48.8102815, lng: 20.6860419 },
    { lat: 48.8103147, lng: 20.6861517 },
    { lat: 48.8101984, lng: 20.6861705 },
    { lat: 48.8101511, lng: 20.6862344 },
    { lat: 48.8101537, lng: 20.6863403 },
    { lat: 48.810408, lng: 20.6878178 },
    { lat: 48.8104996, lng: 20.6912906 },
    { lat: 48.8104511, lng: 20.6922039 },
    { lat: 48.8104773, lng: 20.6928715 },
    { lat: 48.81046, lng: 20.6930801 },
    { lat: 48.8103137, lng: 20.6935539 },
    { lat: 48.8103334, lng: 20.6937284 },
    { lat: 48.810385, lng: 20.6937881 },
    { lat: 48.8104924, lng: 20.6936696 },
    { lat: 48.8106144, lng: 20.6960506 },
    { lat: 48.8106622, lng: 20.6987112 },
    { lat: 48.8104627, lng: 20.6980132 },
    { lat: 48.8103127, lng: 20.698099 },
    { lat: 48.810147, lng: 20.69737 },
    { lat: 48.8099412, lng: 20.6974061 },
    { lat: 48.8098587, lng: 20.6972379 },
    { lat: 48.8096962, lng: 20.6971322 },
    { lat: 48.8095376, lng: 20.696818 },
    { lat: 48.809196, lng: 20.6968731 },
    { lat: 48.8089697, lng: 20.6966195 },
    { lat: 48.8088113, lng: 20.6963456 },
    { lat: 48.8083587, lng: 20.6959648 },
    { lat: 48.8078712, lng: 20.695645 },
    { lat: 48.8075949, lng: 20.695853 },
    { lat: 48.8067066, lng: 20.6957246 },
    { lat: 48.8065002, lng: 20.6958132 },
    { lat: 48.8064738, lng: 20.6958908 },
    { lat: 48.8063932, lng: 20.6957149 },
    { lat: 48.8064562, lng: 20.6953993 },
    { lat: 48.8063445, lng: 20.6949785 },
    { lat: 48.8061935, lng: 20.6948805 },
    { lat: 48.8061366, lng: 20.6946751 },
    { lat: 48.8058843, lng: 20.6947598 },
    { lat: 48.8057606, lng: 20.6948013 },
    { lat: 48.805532, lng: 20.6946735 },
    { lat: 48.805308, lng: 20.6943269 },
    { lat: 48.8049258, lng: 20.6932096 },
    { lat: 48.8048606, lng: 20.6928982 },
    { lat: 48.8046773, lng: 20.6925275 },
    { lat: 48.8045307, lng: 20.6925784 },
    { lat: 48.8045025, lng: 20.6922046 },
    { lat: 48.8042928, lng: 20.6914986 },
    { lat: 48.8040365, lng: 20.6910996 },
    { lat: 48.8039509, lng: 20.6908181 },
    { lat: 48.8036954, lng: 20.6893066 },
    { lat: 48.8034081, lng: 20.6892672 },
    { lat: 48.8034135, lng: 20.6887261 },
    { lat: 48.8031547, lng: 20.687947 },
    { lat: 48.8029105, lng: 20.6884202 },
    { lat: 48.8023158, lng: 20.6891685 },
    { lat: 48.8022244, lng: 20.6888811 },
    { lat: 48.8022768, lng: 20.6886457 },
    { lat: 48.8021339, lng: 20.6882035 },
    { lat: 48.8025154, lng: 20.6872566 },
    { lat: 48.8028833, lng: 20.6858336 },
    { lat: 48.8020926, lng: 20.6839968 },
    { lat: 48.8018387, lng: 20.6835423 },
    { lat: 48.8015559, lng: 20.682687 },
    { lat: 48.8011788, lng: 20.6824676 },
    { lat: 48.800331, lng: 20.6817437 },
    { lat: 48.8000173, lng: 20.6806524 },
    { lat: 48.7997683, lng: 20.6801751 },
    { lat: 48.798365, lng: 20.6802914 },
    { lat: 48.7981053, lng: 20.6800806 },
    { lat: 48.7974287, lng: 20.6799833 },
    { lat: 48.7972896, lng: 20.6797809 },
    { lat: 48.7970475, lng: 20.6792469 },
    { lat: 48.7960386, lng: 20.6784528 },
    { lat: 48.7954754, lng: 20.6782869 },
    { lat: 48.7951179, lng: 20.6783038 },
    { lat: 48.7938703, lng: 20.6787673 },
    { lat: 48.7935762, lng: 20.6788221 },
    { lat: 48.7927199, lng: 20.6788043 },
    { lat: 48.7916466, lng: 20.6791359 },
    { lat: 48.7912411, lng: 20.677971 },
    { lat: 48.7910412, lng: 20.6770409 },
    { lat: 48.7911349, lng: 20.6762349 },
    { lat: 48.7912416, lng: 20.6759147 },
    { lat: 48.7911497, lng: 20.6754249 },
    { lat: 48.7912135, lng: 20.6752565 },
    { lat: 48.7908918, lng: 20.6749173 },
    { lat: 48.7908251, lng: 20.6749703 },
    { lat: 48.7905626, lng: 20.6746513 },
    { lat: 48.7906272, lng: 20.6740047 },
    { lat: 48.7904073, lng: 20.6734658 },
    { lat: 48.7901868, lng: 20.6727131 },
    { lat: 48.7902832, lng: 20.6725802 },
    { lat: 48.7899603, lng: 20.6723629 },
    { lat: 48.7898765, lng: 20.6724152 },
    { lat: 48.7898088, lng: 20.6721912 },
    { lat: 48.7898351, lng: 20.6719205 },
    { lat: 48.7898981, lng: 20.671855 },
    { lat: 48.7900575, lng: 20.6720618 },
    { lat: 48.7900799, lng: 20.6722138 },
    { lat: 48.7902207, lng: 20.672309 },
    { lat: 48.7903458, lng: 20.6725019 },
    { lat: 48.790596, lng: 20.6719475 },
    { lat: 48.7913483, lng: 20.6716725 },
    { lat: 48.7921997, lng: 20.6712696 },
    { lat: 48.7930363, lng: 20.6706149 },
    { lat: 48.7940552, lng: 20.670115 },
    { lat: 48.7947991, lng: 20.6693893 },
    { lat: 48.7951211, lng: 20.668483 },
    { lat: 48.7958045, lng: 20.6669212 },
    { lat: 48.7958288, lng: 20.6665275 },
    { lat: 48.7962644, lng: 20.6652111 },
    { lat: 48.7969493, lng: 20.6646623 },
    { lat: 48.7971697, lng: 20.6642431 },
    { lat: 48.7973122, lng: 20.6627962 },
    { lat: 48.7970398, lng: 20.6626405 },
    { lat: 48.7970064, lng: 20.6624261 },
    { lat: 48.7969184, lng: 20.6623602 },
    { lat: 48.7970503, lng: 20.6618647 },
    { lat: 48.7971792, lng: 20.6616637 },
    { lat: 48.797197, lng: 20.6614105 },
    { lat: 48.7969679, lng: 20.6609502 },
    { lat: 48.7969622, lng: 20.6605432 },
    { lat: 48.7970508, lng: 20.6603781 },
    { lat: 48.7973257, lng: 20.6607247 },
    { lat: 48.7974157, lng: 20.6610365 },
    { lat: 48.7975562, lng: 20.6612502 },
    { lat: 48.797839, lng: 20.6609137 },
    { lat: 48.7977952, lng: 20.6607338 },
    { lat: 48.7978995, lng: 20.6605966 },
    { lat: 48.7980891, lng: 20.6611712 },
    { lat: 48.7980261, lng: 20.6612943 },
    { lat: 48.798024, lng: 20.6614601 },
    { lat: 48.7981091, lng: 20.6614785 },
    { lat: 48.7982278, lng: 20.6619897 },
    { lat: 48.7984447, lng: 20.6623189 },
    { lat: 48.7983248, lng: 20.6624627 },
    { lat: 48.7984262, lng: 20.6629011 },
    { lat: 48.7986544, lng: 20.6632683 },
    { lat: 48.7990122, lng: 20.6633591 },
    { lat: 48.7990847, lng: 20.6636174 },
    { lat: 48.7992944, lng: 20.6637544 },
    { lat: 48.7994286, lng: 20.6641672 },
    { lat: 48.799502, lng: 20.6642333 },
    { lat: 48.7995238, lng: 20.6645561 },
    { lat: 48.7995666, lng: 20.6645583 },
    { lat: 48.7997645, lng: 20.6651169 },
    { lat: 48.8001724, lng: 20.6656929 },
    { lat: 48.8006886, lng: 20.6662717 },
    { lat: 48.8008708, lng: 20.6666746 },
    { lat: 48.8011695, lng: 20.6661178 },
    { lat: 48.8015142, lng: 20.6657773 },
    { lat: 48.80189, lng: 20.6660256 },
    { lat: 48.8019465, lng: 20.6664352 },
    { lat: 48.8019159, lng: 20.667403 },
    { lat: 48.8020116, lng: 20.6678069 },
    { lat: 48.8021224, lng: 20.6681339 },
    { lat: 48.8021571, lng: 20.6681003 },
    { lat: 48.802509, lng: 20.669247 },
    { lat: 48.803123, lng: 20.6703207 },
    { lat: 48.8035729, lng: 20.6708065 },
    { lat: 48.8041936, lng: 20.6712604 },
    { lat: 48.8049178, lng: 20.6719904 },
    { lat: 48.8056825, lng: 20.6724664 },
    { lat: 48.8062748, lng: 20.6730023 },
    { lat: 48.8065595, lng: 20.6730664 },
    { lat: 48.8067601, lng: 20.673011 },
    { lat: 48.8071384, lng: 20.6727252 },
    { lat: 48.8074005, lng: 20.6728437 },
    { lat: 48.807616, lng: 20.6731099 },
    { lat: 48.8078133, lng: 20.6736497 },
    { lat: 48.8080266, lng: 20.6734468 },
    { lat: 48.8078445, lng: 20.6728225 },
    { lat: 48.8078292, lng: 20.6725555 },
    { lat: 48.8076466, lng: 20.6721394 },
    { lat: 48.8076896, lng: 20.6719005 },
    { lat: 48.8078406, lng: 20.671682 },
    { lat: 48.8076501, lng: 20.6713638 },
    { lat: 48.807862, lng: 20.6707088 },
    { lat: 48.807688, lng: 20.6703763 },
    { lat: 48.8077791, lng: 20.670162 },
    { lat: 48.8079879, lng: 20.6700516 },
    { lat: 48.8078994, lng: 20.6697789 },
    { lat: 48.8079237, lng: 20.6695436 },
    { lat: 48.8082485, lng: 20.6690879 },
    { lat: 48.8080655, lng: 20.6688876 },
    { lat: 48.8082658, lng: 20.6687247 },
    { lat: 48.8082331, lng: 20.668502 },
    { lat: 48.8083591, lng: 20.6682873 },
    { lat: 48.8088906, lng: 20.6689765 },
    { lat: 48.8094686, lng: 20.6679538 },
    { lat: 48.8091449, lng: 20.6676236 },
    { lat: 48.8088456, lng: 20.6678169 },
    { lat: 48.8084763, lng: 20.667663 },
    { lat: 48.8083212, lng: 20.6673565 },
    { lat: 48.8084149, lng: 20.6671101 },
    { lat: 48.8088192, lng: 20.6663321 },
    { lat: 48.8093115, lng: 20.6667459 },
    { lat: 48.8095125, lng: 20.6667658 },
    { lat: 48.8098342, lng: 20.6665992 },
    { lat: 48.8101529, lng: 20.6670441 },
    { lat: 48.8109682, lng: 20.6657982 },
    { lat: 48.8112383, lng: 20.6647604 },
    { lat: 48.8114618, lng: 20.6643027 },
    { lat: 48.8118184, lng: 20.6631742 },
    { lat: 48.8118161, lng: 20.6627629 },
    { lat: 48.811734, lng: 20.662455 },
    { lat: 48.8117874, lng: 20.662247 },
    { lat: 48.8117271, lng: 20.6618226 },
    { lat: 48.8113701, lng: 20.6605115 },
    { lat: 48.8113623, lng: 20.6599792 },
    { lat: 48.8114377, lng: 20.6598855 },
    { lat: 48.8113055, lng: 20.6594624 },
    { lat: 48.8111015, lng: 20.6591756 },
    { lat: 48.8106987, lng: 20.6590221 },
    { lat: 48.8106538, lng: 20.6584375 },
    { lat: 48.8107405, lng: 20.6580607 },
    { lat: 48.8102356, lng: 20.6566072 },
    { lat: 48.8106061, lng: 20.6560797 },
    { lat: 48.8110217, lng: 20.655745 },
    { lat: 48.8110179, lng: 20.6556067 },
    { lat: 48.8108627, lng: 20.6554559 },
    { lat: 48.8108216, lng: 20.6552857 },
    { lat: 48.8106381, lng: 20.655063 },
    { lat: 48.8105933, lng: 20.6548224 },
    { lat: 48.8104659, lng: 20.6546254 },
    { lat: 48.8104125, lng: 20.6543944 },
    { lat: 48.8100986, lng: 20.6540896 },
    { lat: 48.8099437, lng: 20.6531362 },
    { lat: 48.8097324, lng: 20.652774 },
    { lat: 48.8096703, lng: 20.6522341 },
    { lat: 48.8094056, lng: 20.6516063 },
    { lat: 48.8093901, lng: 20.6496799 },
    { lat: 48.8094752, lng: 20.6493535 },
    { lat: 48.8094369, lng: 20.6491984 },
    { lat: 48.8094737, lng: 20.64916 },
    { lat: 48.8095717, lng: 20.6481833 },
    { lat: 48.8096236, lng: 20.6476664 },
    { lat: 48.8097125, lng: 20.6474765 },
    { lat: 48.8096617, lng: 20.6471593 },
    { lat: 48.8098124, lng: 20.6468121 },
    { lat: 48.8098747, lng: 20.6464544 },
    { lat: 48.8098146, lng: 20.6461868 },
    { lat: 48.8098491, lng: 20.6455236 },
    { lat: 48.8097595, lng: 20.6447602 },
    { lat: 48.8098169, lng: 20.6440707 },
    { lat: 48.8097524, lng: 20.643971 },
    { lat: 48.8096869, lng: 20.6435632 },
    { lat: 48.8096834, lng: 20.6429052 },
    { lat: 48.8094924, lng: 20.6424435 },
    { lat: 48.8095608, lng: 20.6422799 },
    { lat: 48.8095177, lng: 20.6411201 },
    { lat: 48.8094615, lng: 20.6410337 },
    { lat: 48.8094976, lng: 20.6409432 },
    { lat: 48.8093571, lng: 20.6404498 },
    { lat: 48.8093901, lng: 20.6396466 },
    { lat: 48.8091811, lng: 20.6387878 },
    { lat: 48.8087977, lng: 20.6351053 },
    { lat: 48.8085915, lng: 20.6338234 },
    { lat: 48.8080667, lng: 20.6330779 },
    { lat: 48.8075291, lng: 20.6310406 },
    { lat: 48.8073109, lng: 20.629683 },
    { lat: 48.8072037, lng: 20.6277806 },
    { lat: 48.8070592, lng: 20.6270901 },
    { lat: 48.8067584, lng: 20.6242635 },
    { lat: 48.8062103, lng: 20.6218443 },
    { lat: 48.8059215, lng: 20.6201565 },
    { lat: 48.8059817, lng: 20.6190282 },
    { lat: 48.8057179, lng: 20.6185541 },
    { lat: 48.8052067, lng: 20.6180549 },
    { lat: 48.8049126, lng: 20.617864 },
    { lat: 48.8040063, lng: 20.6164081 },
    { lat: 48.8036236, lng: 20.6158875 },
    { lat: 48.8033746, lng: 20.6152304 },
    { lat: 48.8032424, lng: 20.6150347 },
    { lat: 48.8031087, lng: 20.6144844 },
    { lat: 48.8026656, lng: 20.6132267 },
    { lat: 48.8028178, lng: 20.6111368 },
    { lat: 48.8027603, lng: 20.6096799 },
    { lat: 48.8027791, lng: 20.6075354 },
    { lat: 48.8024234, lng: 20.6072926 },
    { lat: 48.8020639, lng: 20.607377 },
    { lat: 48.8019929, lng: 20.6074379 },
    { lat: 48.8019245, lng: 20.6077529 },
    { lat: 48.8016236, lng: 20.608023 },
    { lat: 48.8011941, lng: 20.6079493 },
    { lat: 48.8011167, lng: 20.6080779 },
    { lat: 48.8010406, lng: 20.6080857 },
    { lat: 48.8005702, lng: 20.6077261 },
    { lat: 48.8002022, lng: 20.6072665 },
    { lat: 48.7993603, lng: 20.6069351 },
    { lat: 48.799316, lng: 20.607077 },
    { lat: 48.7993171, lng: 20.607354 },
    { lat: 48.7989875, lng: 20.6076376 },
    { lat: 48.7988625, lng: 20.6076698 },
    { lat: 48.7984192, lng: 20.6072769 },
    { lat: 48.7981874, lng: 20.6071881 },
    { lat: 48.7979673, lng: 20.6072682 },
    { lat: 48.7977953, lng: 20.6074855 },
    { lat: 48.7977226, lng: 20.6077454 },
    { lat: 48.7975668, lng: 20.6079481 },
    { lat: 48.7974835, lng: 20.6079558 },
    { lat: 48.7973969, lng: 20.6079233 },
    { lat: 48.7970766, lng: 20.6075691 },
    { lat: 48.7967942, lng: 20.6075116 },
    { lat: 48.7967181, lng: 20.6072805 },
    { lat: 48.7964919, lng: 20.6071269 },
    { lat: 48.7959043, lng: 20.6070375 },
    { lat: 48.7953418, lng: 20.6063696 },
    { lat: 48.7952494, lng: 20.6060881 },
    { lat: 48.7951536, lng: 20.6060229 },
    { lat: 48.7950387, lng: 20.6060878 },
    { lat: 48.7948966, lng: 20.6058329 },
    { lat: 48.7947764, lng: 20.6057776 },
    { lat: 48.794635, lng: 20.6058216 },
    { lat: 48.793809, lng: 20.6054575 },
    { lat: 48.7937209, lng: 20.6054827 },
    { lat: 48.7935669, lng: 20.6057687 },
    { lat: 48.7934099, lng: 20.6057847 },
    { lat: 48.7931725, lng: 20.6054525 },
    { lat: 48.7927539, lng: 20.6051776 },
    { lat: 48.7921448, lng: 20.6050249 },
    { lat: 48.7919168, lng: 20.6051004 },
    { lat: 48.7918524, lng: 20.6050608 },
    { lat: 48.791643, lng: 20.6048194 },
    { lat: 48.7913398, lng: 20.6047141 },
    { lat: 48.7910105, lng: 20.6044492 },
    { lat: 48.7909155, lng: 20.6041933 },
    { lat: 48.7907781, lng: 20.6040091 },
    { lat: 48.7906197, lng: 20.6038013 },
    { lat: 48.7903856, lng: 20.603431 },
    { lat: 48.7901498, lng: 20.6032668 },
    { lat: 48.7899183, lng: 20.6029341 },
    { lat: 48.7895193, lng: 20.6028639 },
    { lat: 48.7885813, lng: 20.6022426 },
    { lat: 48.7883949, lng: 20.601945 },
    { lat: 48.7884225, lng: 20.6014739 },
    { lat: 48.7883193, lng: 20.6009873 },
    { lat: 48.7876603, lng: 20.6001452 },
    { lat: 48.7873783, lng: 20.599885 },
    { lat: 48.7871708, lng: 20.5992618 },
    { lat: 48.787169, lng: 20.5988143 },
    { lat: 48.7871263, lng: 20.5986586 },
    { lat: 48.7865984, lng: 20.5982611 },
    { lat: 48.7865755, lng: 20.598403 },
    { lat: 48.7866835, lng: 20.5987439 },
    { lat: 48.7868839, lng: 20.5998161 },
    { lat: 48.7864099, lng: 20.6005905 },
    { lat: 48.7856747, lng: 20.6016356 },
    { lat: 48.7861837, lng: 20.6022264 },
    { lat: 48.7862695, lng: 20.6026973 },
    { lat: 48.7839462, lng: 20.6037588 },
    { lat: 48.7806121, lng: 20.6020773 },
    { lat: 48.7776003, lng: 20.6010566 },
    { lat: 48.7721323, lng: 20.5990697 },
    { lat: 48.7715066, lng: 20.5999347 },
    { lat: 48.770178, lng: 20.6003643 },
    { lat: 48.7701808, lng: 20.6002916 },
    { lat: 48.7696782, lng: 20.5998125 },
    { lat: 48.7694013, lng: 20.5999103 },
    { lat: 48.769066, lng: 20.5997285 },
    { lat: 48.7686942, lng: 20.5990454 },
    { lat: 48.7685532, lng: 20.5990351 },
    { lat: 48.7682457, lng: 20.5992514 },
    { lat: 48.7680472, lng: 20.5991452 },
    { lat: 48.767645, lng: 20.5986764 },
    { lat: 48.7683047, lng: 20.5973242 },
    { lat: 48.7687201, lng: 20.5960567 },
    { lat: 48.7708223, lng: 20.5936955 },
    { lat: 48.7723457, lng: 20.5937285 },
    { lat: 48.7735328, lng: 20.5938671 },
    { lat: 48.774163, lng: 20.5945971 },
    { lat: 48.7742638, lng: 20.5948242 },
    { lat: 48.7745104, lng: 20.5948889 },
    { lat: 48.7773841, lng: 20.5870929 },
    { lat: 48.7772401, lng: 20.5870654 },
    { lat: 48.7768641, lng: 20.5871565 },
    { lat: 48.7764785, lng: 20.5870518 },
    { lat: 48.7757588, lng: 20.5864943 },
    { lat: 48.7750734, lng: 20.5859143 },
    { lat: 48.7746596, lng: 20.5851634 },
    { lat: 48.7745166, lng: 20.5847039 },
    { lat: 48.7732455, lng: 20.5837826 },
    { lat: 48.7727934, lng: 20.5832713 },
    { lat: 48.7722248, lng: 20.582996 },
    { lat: 48.7722376, lng: 20.5829193 },
    { lat: 48.771596, lng: 20.5828914 },
    { lat: 48.7712466, lng: 20.5827227 },
    { lat: 48.7712374, lng: 20.582782 },
    { lat: 48.7705669, lng: 20.5825813 },
    { lat: 48.7704291, lng: 20.582691 },
    { lat: 48.770338, lng: 20.583152 },
    { lat: 48.7701036, lng: 20.58358 },
    { lat: 48.7696529, lng: 20.5839969 },
    { lat: 48.7693196, lng: 20.5844882 },
    { lat: 48.7691681, lng: 20.5850848 },
    { lat: 48.7691387, lng: 20.5860645 },
    { lat: 48.768924, lng: 20.5868434 },
    { lat: 48.7675181, lng: 20.5905354 },
    { lat: 48.7661571, lng: 20.5926884 },
    { lat: 48.7653332, lng: 20.5944209 },
    { lat: 48.7649717, lng: 20.5950001 },
    { lat: 48.7642421, lng: 20.5952493 },
    { lat: 48.7638473, lng: 20.5951685 },
    { lat: 48.7633138, lng: 20.5953277 },
    { lat: 48.762955, lng: 20.5955801 },
    { lat: 48.7626961, lng: 20.5956749 },
    { lat: 48.7619827, lng: 20.5958117 },
    { lat: 48.7618749, lng: 20.5957198 },
    { lat: 48.7617438, lng: 20.5958649 },
    { lat: 48.7615439, lng: 20.5959077 },
    { lat: 48.7608681, lng: 20.5958733 },
    { lat: 48.7606111, lng: 20.5957424 },
    { lat: 48.760407, lng: 20.5958502 },
    { lat: 48.7601169, lng: 20.5958666 },
    { lat: 48.7595875, lng: 20.5956101 },
    { lat: 48.7593475, lng: 20.5954197 },
    { lat: 48.7591374, lng: 20.5954933 },
    { lat: 48.7589455, lng: 20.595454 },
    { lat: 48.7586542, lng: 20.5952411 },
    { lat: 48.758387, lng: 20.5955087 },
    { lat: 48.7583463, lng: 20.5956619 },
    { lat: 48.7581277, lng: 20.5956581 },
    { lat: 48.7579175, lng: 20.5955415 },
    { lat: 48.7575228, lng: 20.5956113 },
    { lat: 48.7573156, lng: 20.5960162 },
    { lat: 48.7571875, lng: 20.5959602 },
    { lat: 48.7570299, lng: 20.5960819 },
    { lat: 48.7567685, lng: 20.5961066 },
    { lat: 48.7565118, lng: 20.5963268 },
    { lat: 48.7563446, lng: 20.5963412 },
    { lat: 48.7560254, lng: 20.5966316 },
    { lat: 48.7554268, lng: 20.5967923 },
    { lat: 48.755371, lng: 20.596898 },
    { lat: 48.7549294, lng: 20.5969249 },
    { lat: 48.7546962, lng: 20.5967671 },
    { lat: 48.7536199, lng: 20.596889 },
    { lat: 48.7526977, lng: 20.5979024 },
    { lat: 48.7520461, lng: 20.5982512 },
    { lat: 48.7509737, lng: 20.5979588 },
    { lat: 48.7484445, lng: 20.5981308 },
    { lat: 48.747876, lng: 20.598016 },
    { lat: 48.747609, lng: 20.600602 },
    { lat: 48.747677, lng: 20.601134 },
    { lat: 48.747614, lng: 20.6017 },
    { lat: 48.7478, lng: 20.60238 },
    { lat: 48.748027, lng: 20.603085 },
    { lat: 48.748042, lng: 20.603213 },
    { lat: 48.7481, lng: 20.603526 },
    { lat: 48.748141, lng: 20.603709 },
    { lat: 48.748293, lng: 20.604225 },
    { lat: 48.748533, lng: 20.604924 },
    { lat: 48.749553, lng: 20.60621 },
    { lat: 48.749599, lng: 20.606244 },
    { lat: 48.750536, lng: 20.607023 },
    { lat: 48.751294, lng: 20.607899 },
    { lat: 48.751594, lng: 20.608391 },
    { lat: 48.752722, lng: 20.610972 },
    { lat: 48.752735, lng: 20.611165 },
    { lat: 48.752749, lng: 20.611652 },
    { lat: 48.752882, lng: 20.61234 },
    { lat: 48.753217, lng: 20.613132 },
    { lat: 48.753782, lng: 20.61427 },
    { lat: 48.754186, lng: 20.614857 },
    { lat: 48.754405, lng: 20.616081 },
    { lat: 48.754637, lng: 20.616589 },
    { lat: 48.755084, lng: 20.61754 },
    { lat: 48.75492, lng: 20.617709 },
    { lat: 48.754358, lng: 20.618471 },
    { lat: 48.753986, lng: 20.619667 },
    { lat: 48.753926, lng: 20.619858 },
    { lat: 48.753791, lng: 20.620415 },
    { lat: 48.753606, lng: 20.621071 },
    { lat: 48.753068, lng: 20.622207 },
    { lat: 48.752793, lng: 20.622678 },
    { lat: 48.752487, lng: 20.623275 },
    { lat: 48.751989, lng: 20.624536 },
    { lat: 48.751441, lng: 20.626204 },
    { lat: 48.751093, lng: 20.627236 },
    { lat: 48.75082, lng: 20.627787 },
    { lat: 48.750561, lng: 20.628364 },
    { lat: 48.750333, lng: 20.628896 },
    { lat: 48.750185, lng: 20.62988 },
    { lat: 48.750204, lng: 20.631169 },
    { lat: 48.750236, lng: 20.631233 },
    { lat: 48.750259, lng: 20.631279 },
    { lat: 48.7520717, lng: 20.6347128 },
    { lat: 48.7523574, lng: 20.6350773 },
    { lat: 48.7524915, lng: 20.6355164 },
    { lat: 48.7529908, lng: 20.6367085 },
    { lat: 48.7536803, lng: 20.6378458 },
    { lat: 48.7537817, lng: 20.6384379 },
    { lat: 48.7536138, lng: 20.6391754 },
    { lat: 48.7537085, lng: 20.6393345 },
    { lat: 48.7537276, lng: 20.640297 },
    { lat: 48.753473, lng: 20.6408511 },
    { lat: 48.7535255, lng: 20.6410897 },
    { lat: 48.7532158, lng: 20.6415853 },
    { lat: 48.7530093, lng: 20.6421625 },
    { lat: 48.7528165, lng: 20.6423126 },
    { lat: 48.7528276, lng: 20.6427741 },
    { lat: 48.7529822, lng: 20.6430646 },
    { lat: 48.7530297, lng: 20.6433204 },
    { lat: 48.7530083, lng: 20.6435947 },
    { lat: 48.7532214, lng: 20.6456918 },
    { lat: 48.7533011, lng: 20.6460277 },
    { lat: 48.753052, lng: 20.6462966 },
    { lat: 48.7530451, lng: 20.646452 },
    { lat: 48.7532451, lng: 20.6468165 },
    { lat: 48.7537543, lng: 20.6475091 },
    { lat: 48.7542914, lng: 20.6476306 },
    { lat: 48.7549324, lng: 20.6465557 },
    { lat: 48.7551871, lng: 20.6464686 },
    { lat: 48.7555025, lng: 20.6466309 },
    { lat: 48.7555153, lng: 20.6467676 },
    { lat: 48.7556991, lng: 20.6468348 },
    { lat: 48.7553531, lng: 20.6485083 },
    { lat: 48.7553919, lng: 20.6488643 },
    { lat: 48.7552385, lng: 20.6489541 },
    { lat: 48.7551068, lng: 20.6492005 },
    { lat: 48.7549172, lng: 20.6491876 },
    { lat: 48.7548853, lng: 20.6493644 },
    { lat: 48.7547787, lng: 20.649371 },
    { lat: 48.754188, lng: 20.6506568 },
    { lat: 48.7542061, lng: 20.6509436 },
    { lat: 48.7541067, lng: 20.6512491 },
    { lat: 48.7539589, lng: 20.6513291 },
    { lat: 48.7538366, lng: 20.6516863 },
    { lat: 48.7534074, lng: 20.6515555 },
    { lat: 48.7531653, lng: 20.6516454 },
    { lat: 48.7531492, lng: 20.6521662 },
    { lat: 48.7534248, lng: 20.6522841 },
    { lat: 48.7530731, lng: 20.6534291 },
    { lat: 48.754356, lng: 20.654567 },
    { lat: 48.7546514, lng: 20.654174 },
    { lat: 48.7548391, lng: 20.6540661 },
    { lat: 48.7548673, lng: 20.6539471 },
    { lat: 48.755191, lng: 20.6538044 },
    { lat: 48.755467, lng: 20.654 },
    { lat: 48.7558063, lng: 20.6538574 },
    { lat: 48.7560062, lng: 20.6538402 },
    { lat: 48.7561523, lng: 20.6540494 },
    { lat: 48.7563049, lng: 20.6553495 },
    { lat: 48.7562746, lng: 20.6555032 },
    { lat: 48.7560866, lng: 20.6556939 },
    { lat: 48.7562137, lng: 20.6561898 },
    { lat: 48.7564346, lng: 20.6563221 },
    { lat: 48.7565876, lng: 20.6566916 },
    { lat: 48.7566854, lng: 20.6567799 },
    { lat: 48.7571191, lng: 20.6568483 },
    { lat: 48.7575718, lng: 20.6571035 },
    { lat: 48.7576586, lng: 20.6572334 },
    { lat: 48.7580324, lng: 20.657296 },
    { lat: 48.7582246, lng: 20.6571631 },
    { lat: 48.7583262, lng: 20.6569517 },
    { lat: 48.7588231, lng: 20.6575858 },
    { lat: 48.7597024, lng: 20.6568685 },
    { lat: 48.7599581, lng: 20.6565601 },
    { lat: 48.7599349, lng: 20.6570416 },
    { lat: 48.7598435, lng: 20.6572868 },
    { lat: 48.7596968, lng: 20.6580865 },
    { lat: 48.7599264, lng: 20.6582399 },
    { lat: 48.7612062, lng: 20.6578899 },
    { lat: 48.7613587, lng: 20.6575608 },
    { lat: 48.7613677, lng: 20.6570633 },
    { lat: 48.7616612, lng: 20.6567693 },
    { lat: 48.7617243, lng: 20.6568058 },
    { lat: 48.7616496, lng: 20.6574493 },
    { lat: 48.7624058, lng: 20.6572496 },
    { lat: 48.7627837, lng: 20.6566781 },
    { lat: 48.7630777, lng: 20.6560012 },
    { lat: 48.763327, lng: 20.655795 },
    { lat: 48.7643535, lng: 20.6552542 },
    { lat: 48.7646725, lng: 20.654987 },
    { lat: 48.7651672, lng: 20.655282 },
    { lat: 48.765381, lng: 20.6560277 },
    { lat: 48.7663351, lng: 20.6565991 },
    { lat: 48.7666084, lng: 20.6570327 },
    { lat: 48.7676566, lng: 20.6573159 },
    { lat: 48.7674448, lng: 20.6578726 },
    { lat: 48.7672193, lng: 20.6582981 },
    { lat: 48.7672523, lng: 20.658841 },
    { lat: 48.7671804, lng: 20.6594726 },
    { lat: 48.7671726, lng: 20.6604386 },
    { lat: 48.7672982, lng: 20.6619546 },
    { lat: 48.7671185, lng: 20.6626578 },
    { lat: 48.7675089, lng: 20.6644318 },
    { lat: 48.7676934, lng: 20.6660261 },
    { lat: 48.7679244, lng: 20.6670303 },
    { lat: 48.7681599, lng: 20.669255 },
    { lat: 48.7682786, lng: 20.6699326 },
    { lat: 48.7677923, lng: 20.6728098 },
    { lat: 48.7677251, lng: 20.6741708 },
    { lat: 48.7680891, lng: 20.6805804 },
    { lat: 48.767919, lng: 20.6812974 },
    { lat: 48.7680456, lng: 20.6820065 },
    { lat: 48.766927, lng: 20.6832381 },
    { lat: 48.7660219, lng: 20.6844397 },
    { lat: 48.7640916, lng: 20.6862762 },
    { lat: 48.7660633, lng: 20.6904984 },
    { lat: 48.7662341, lng: 20.6912763 },
    { lat: 48.7662509, lng: 20.692632 },
    { lat: 48.76612, lng: 20.6935278 },
    { lat: 48.7661089, lng: 20.6942274 },
    { lat: 48.7662299, lng: 20.6953181 },
    { lat: 48.7662732, lng: 20.6961796 },
    { lat: 48.7661845, lng: 20.6975451 },
    { lat: 48.766234, lng: 20.6978276 },
    { lat: 48.7662562, lng: 20.698669 },
    { lat: 48.76642, lng: 20.6996959 },
    { lat: 48.766435, lng: 20.7002753 },
    { lat: 48.76666, lng: 20.7011505 },
    { lat: 48.766668, lng: 20.7014343 },
    { lat: 48.7668327, lng: 20.7016018 },
    { lat: 48.766931, lng: 20.7018054 },
    { lat: 48.7669411, lng: 20.7019612 },
    { lat: 48.7674016, lng: 20.7030656 },
    { lat: 48.7675338, lng: 20.7032686 },
    { lat: 48.7676151, lng: 20.7037826 },
    { lat: 48.7677756, lng: 20.7041803 },
    { lat: 48.7678617, lng: 20.7055847 },
    { lat: 48.7679342, lng: 20.7059753 },
    { lat: 48.7680844, lng: 20.7064302 },
    { lat: 48.7680968, lng: 20.7066868 },
    { lat: 48.7680591, lng: 20.7068276 },
    { lat: 48.7681058, lng: 20.7070636 },
    { lat: 48.7680981, lng: 20.7073515 },
    { lat: 48.768341, lng: 20.7085747 },
    { lat: 48.7684438, lng: 20.7098504 },
    { lat: 48.7684308, lng: 20.7102736 },
    { lat: 48.7683783, lng: 20.7104435 },
    { lat: 48.7684436, lng: 20.7109846 },
    { lat: 48.7683797, lng: 20.7116561 },
    { lat: 48.7684082, lng: 20.712112 },
    { lat: 48.7682896, lng: 20.7123861 },
    { lat: 48.768136, lng: 20.7133785 },
    { lat: 48.7680667, lng: 20.7149787 },
    { lat: 48.767951, lng: 20.715745 },
    { lat: 48.7679529, lng: 20.7160843 },
    { lat: 48.7678077, lng: 20.716879 },
    { lat: 48.7679396, lng: 20.7171984 },
    { lat: 48.768022, lng: 20.7176175 },
    { lat: 48.7679108, lng: 20.7185701 },
    { lat: 48.7679151, lng: 20.7190775 },
    { lat: 48.7681315, lng: 20.7198436 },
    { lat: 48.7684874, lng: 20.7207033 },
    { lat: 48.7688436, lng: 20.7213803 },
    { lat: 48.7689276, lng: 20.7216536 },
    { lat: 48.7693892, lng: 20.7223848 },
    { lat: 48.7697551, lng: 20.7239061 },
    { lat: 48.7712731, lng: 20.7294806 },
    { lat: 48.7713595, lng: 20.7295967 },
    { lat: 48.7717834, lng: 20.7298343 },
    { lat: 48.7735667, lng: 20.7345418 },
    { lat: 48.7738545, lng: 20.7351691 },
    { lat: 48.7743247, lng: 20.7354762 },
    { lat: 48.7745739, lng: 20.7357733 },
    { lat: 48.774671, lng: 20.7360352 },
    { lat: 48.7748414, lng: 20.736111 },
    { lat: 48.7749684, lng: 20.7362685 },
    { lat: 48.7753903, lng: 20.737026 },
    { lat: 48.7755147, lng: 20.7374549 },
    { lat: 48.7759079, lng: 20.7383543 },
    { lat: 48.7759565, lng: 20.7386148 },
    { lat: 48.7760889, lng: 20.7387947 },
    { lat: 48.7761809, lng: 20.7391949 },
    { lat: 48.7764129, lng: 20.7393935 },
    { lat: 48.7764144, lng: 20.7395618 },
    { lat: 48.7767976, lng: 20.7405919 },
    { lat: 48.7768864, lng: 20.7410041 },
    { lat: 48.7769915, lng: 20.7412062 },
    { lat: 48.7772781, lng: 20.7426625 },
    { lat: 48.7773713, lng: 20.7429095 },
    { lat: 48.7773779, lng: 20.7431584 },
    { lat: 48.7774705, lng: 20.7436164 },
    { lat: 48.7775882, lng: 20.7437885 },
    { lat: 48.7776674, lng: 20.7440274 },
    { lat: 48.7777591, lng: 20.744807 },
    { lat: 48.7778339, lng: 20.7450429 },
    { lat: 48.7779089, lng: 20.7456033 },
    { lat: 48.7780048, lng: 20.7459611 },
    { lat: 48.7781309, lng: 20.7461861 },
    { lat: 48.7781002, lng: 20.746366 },
    { lat: 48.7782505, lng: 20.7472758 },
    { lat: 48.7783821, lng: 20.747469 },
    { lat: 48.7784341, lng: 20.7477239 },
    { lat: 48.7785126, lng: 20.7477843 },
    { lat: 48.7785424, lng: 20.7479265 },
    { lat: 48.7784934, lng: 20.748196 },
    { lat: 48.7786642, lng: 20.748986 },
    { lat: 48.7788076, lng: 20.7492197 },
    { lat: 48.7788604, lng: 20.7494399 },
    { lat: 48.7788889, lng: 20.7499588 },
    { lat: 48.7791694, lng: 20.7503805 },
    { lat: 48.7793659, lng: 20.7511995 },
    { lat: 48.7794859, lng: 20.7511902 },
    { lat: 48.7796875, lng: 20.7514491 },
  ],
  StaráVoda: [
    { lat: 48.8110217, lng: 20.655745 },
    { lat: 48.8106061, lng: 20.6560797 },
    { lat: 48.8102356, lng: 20.6566072 },
    { lat: 48.8107405, lng: 20.6580607 },
    { lat: 48.8106538, lng: 20.6584375 },
    { lat: 48.8106987, lng: 20.6590221 },
    { lat: 48.8111015, lng: 20.6591756 },
    { lat: 48.8113055, lng: 20.6594624 },
    { lat: 48.8114377, lng: 20.6598855 },
    { lat: 48.8113623, lng: 20.6599792 },
    { lat: 48.8113701, lng: 20.6605115 },
    { lat: 48.8117271, lng: 20.6618226 },
    { lat: 48.8117874, lng: 20.662247 },
    { lat: 48.811734, lng: 20.662455 },
    { lat: 48.8118161, lng: 20.6627629 },
    { lat: 48.8118184, lng: 20.6631742 },
    { lat: 48.8114618, lng: 20.6643027 },
    { lat: 48.8112383, lng: 20.6647604 },
    { lat: 48.8109682, lng: 20.6657982 },
    { lat: 48.8101529, lng: 20.6670441 },
    { lat: 48.8098342, lng: 20.6665992 },
    { lat: 48.8095125, lng: 20.6667658 },
    { lat: 48.8093115, lng: 20.6667459 },
    { lat: 48.8088192, lng: 20.6663321 },
    { lat: 48.8084149, lng: 20.6671101 },
    { lat: 48.8083212, lng: 20.6673565 },
    { lat: 48.8084763, lng: 20.667663 },
    { lat: 48.8088456, lng: 20.6678169 },
    { lat: 48.8091449, lng: 20.6676236 },
    { lat: 48.8094686, lng: 20.6679538 },
    { lat: 48.8088906, lng: 20.6689765 },
    { lat: 48.8083591, lng: 20.6682873 },
    { lat: 48.8082331, lng: 20.668502 },
    { lat: 48.8082658, lng: 20.6687247 },
    { lat: 48.8080655, lng: 20.6688876 },
    { lat: 48.8082485, lng: 20.6690879 },
    { lat: 48.8079237, lng: 20.6695436 },
    { lat: 48.8078994, lng: 20.6697789 },
    { lat: 48.8079879, lng: 20.6700516 },
    { lat: 48.8077791, lng: 20.670162 },
    { lat: 48.807688, lng: 20.6703763 },
    { lat: 48.807862, lng: 20.6707088 },
    { lat: 48.8076501, lng: 20.6713638 },
    { lat: 48.8078406, lng: 20.671682 },
    { lat: 48.8076896, lng: 20.6719005 },
    { lat: 48.8076466, lng: 20.6721394 },
    { lat: 48.8078292, lng: 20.6725555 },
    { lat: 48.8078445, lng: 20.6728225 },
    { lat: 48.8080266, lng: 20.6734468 },
    { lat: 48.8078133, lng: 20.6736497 },
    { lat: 48.807616, lng: 20.6731099 },
    { lat: 48.8074005, lng: 20.6728437 },
    { lat: 48.8071384, lng: 20.6727252 },
    { lat: 48.8067601, lng: 20.673011 },
    { lat: 48.8065595, lng: 20.6730664 },
    { lat: 48.8062748, lng: 20.6730023 },
    { lat: 48.8056825, lng: 20.6724664 },
    { lat: 48.8049178, lng: 20.6719904 },
    { lat: 48.8041936, lng: 20.6712604 },
    { lat: 48.8035729, lng: 20.6708065 },
    { lat: 48.803123, lng: 20.6703207 },
    { lat: 48.802509, lng: 20.669247 },
    { lat: 48.8021571, lng: 20.6681003 },
    { lat: 48.8021224, lng: 20.6681339 },
    { lat: 48.8020116, lng: 20.6678069 },
    { lat: 48.8019159, lng: 20.667403 },
    { lat: 48.8019465, lng: 20.6664352 },
    { lat: 48.80189, lng: 20.6660256 },
    { lat: 48.8015142, lng: 20.6657773 },
    { lat: 48.8011695, lng: 20.6661178 },
    { lat: 48.8008708, lng: 20.6666746 },
    { lat: 48.8006886, lng: 20.6662717 },
    { lat: 48.8001724, lng: 20.6656929 },
    { lat: 48.7997645, lng: 20.6651169 },
    { lat: 48.7995666, lng: 20.6645583 },
    { lat: 48.7995238, lng: 20.6645561 },
    { lat: 48.799502, lng: 20.6642333 },
    { lat: 48.7994286, lng: 20.6641672 },
    { lat: 48.7992944, lng: 20.6637544 },
    { lat: 48.7990847, lng: 20.6636174 },
    { lat: 48.7990122, lng: 20.6633591 },
    { lat: 48.7986544, lng: 20.6632683 },
    { lat: 48.7984262, lng: 20.6629011 },
    { lat: 48.7983248, lng: 20.6624627 },
    { lat: 48.7984447, lng: 20.6623189 },
    { lat: 48.7982278, lng: 20.6619897 },
    { lat: 48.7981091, lng: 20.6614785 },
    { lat: 48.798024, lng: 20.6614601 },
    { lat: 48.7980261, lng: 20.6612943 },
    { lat: 48.7980891, lng: 20.6611712 },
    { lat: 48.7978995, lng: 20.6605966 },
    { lat: 48.7977952, lng: 20.6607338 },
    { lat: 48.797839, lng: 20.6609137 },
    { lat: 48.7975562, lng: 20.6612502 },
    { lat: 48.7974157, lng: 20.6610365 },
    { lat: 48.7973257, lng: 20.6607247 },
    { lat: 48.7970508, lng: 20.6603781 },
    { lat: 48.7969622, lng: 20.6605432 },
    { lat: 48.7969679, lng: 20.6609502 },
    { lat: 48.797197, lng: 20.6614105 },
    { lat: 48.7971792, lng: 20.6616637 },
    { lat: 48.7970503, lng: 20.6618647 },
    { lat: 48.7969184, lng: 20.6623602 },
    { lat: 48.7970064, lng: 20.6624261 },
    { lat: 48.7970398, lng: 20.6626405 },
    { lat: 48.7973122, lng: 20.6627962 },
    { lat: 48.7971697, lng: 20.6642431 },
    { lat: 48.7969493, lng: 20.6646623 },
    { lat: 48.7962644, lng: 20.6652111 },
    { lat: 48.7958288, lng: 20.6665275 },
    { lat: 48.7958045, lng: 20.6669212 },
    { lat: 48.7951211, lng: 20.668483 },
    { lat: 48.7947991, lng: 20.6693893 },
    { lat: 48.7940552, lng: 20.670115 },
    { lat: 48.7930363, lng: 20.6706149 },
    { lat: 48.7921997, lng: 20.6712696 },
    { lat: 48.7913483, lng: 20.6716725 },
    { lat: 48.790596, lng: 20.6719475 },
    { lat: 48.7903458, lng: 20.6725019 },
    { lat: 48.7902207, lng: 20.672309 },
    { lat: 48.7900799, lng: 20.6722138 },
    { lat: 48.7900575, lng: 20.6720618 },
    { lat: 48.7898981, lng: 20.671855 },
    { lat: 48.7898351, lng: 20.6719205 },
    { lat: 48.7898088, lng: 20.6721912 },
    { lat: 48.7898765, lng: 20.6724152 },
    { lat: 48.7899603, lng: 20.6723629 },
    { lat: 48.7902832, lng: 20.6725802 },
    { lat: 48.7901868, lng: 20.6727131 },
    { lat: 48.7904073, lng: 20.6734658 },
    { lat: 48.7906272, lng: 20.6740047 },
    { lat: 48.7905626, lng: 20.6746513 },
    { lat: 48.7908251, lng: 20.6749703 },
    { lat: 48.7908918, lng: 20.6749173 },
    { lat: 48.7912135, lng: 20.6752565 },
    { lat: 48.7911497, lng: 20.6754249 },
    { lat: 48.7912416, lng: 20.6759147 },
    { lat: 48.7911349, lng: 20.6762349 },
    { lat: 48.7910412, lng: 20.6770409 },
    { lat: 48.7912411, lng: 20.677971 },
    { lat: 48.7916466, lng: 20.6791359 },
    { lat: 48.7927199, lng: 20.6788043 },
    { lat: 48.7935762, lng: 20.6788221 },
    { lat: 48.7938703, lng: 20.6787673 },
    { lat: 48.7951179, lng: 20.6783038 },
    { lat: 48.7954754, lng: 20.6782869 },
    { lat: 48.7960386, lng: 20.6784528 },
    { lat: 48.7970475, lng: 20.6792469 },
    { lat: 48.7972896, lng: 20.6797809 },
    { lat: 48.7974287, lng: 20.6799833 },
    { lat: 48.7981053, lng: 20.6800806 },
    { lat: 48.798365, lng: 20.6802914 },
    { lat: 48.7997683, lng: 20.6801751 },
    { lat: 48.8000173, lng: 20.6806524 },
    { lat: 48.800331, lng: 20.6817437 },
    { lat: 48.8011788, lng: 20.6824676 },
    { lat: 48.8015559, lng: 20.682687 },
    { lat: 48.8018387, lng: 20.6835423 },
    { lat: 48.8020926, lng: 20.6839968 },
    { lat: 48.8028833, lng: 20.6858336 },
    { lat: 48.8025154, lng: 20.6872566 },
    { lat: 48.8021339, lng: 20.6882035 },
    { lat: 48.8022768, lng: 20.6886457 },
    { lat: 48.8022244, lng: 20.6888811 },
    { lat: 48.8023158, lng: 20.6891685 },
    { lat: 48.8029105, lng: 20.6884202 },
    { lat: 48.8031547, lng: 20.687947 },
    { lat: 48.8034135, lng: 20.6887261 },
    { lat: 48.8034081, lng: 20.6892672 },
    { lat: 48.8036954, lng: 20.6893066 },
    { lat: 48.8039509, lng: 20.6908181 },
    { lat: 48.8040365, lng: 20.6910996 },
    { lat: 48.8042928, lng: 20.6914986 },
    { lat: 48.8045025, lng: 20.6922046 },
    { lat: 48.8045307, lng: 20.6925784 },
    { lat: 48.8046773, lng: 20.6925275 },
    { lat: 48.8048606, lng: 20.6928982 },
    { lat: 48.8049258, lng: 20.6932096 },
    { lat: 48.805308, lng: 20.6943269 },
    { lat: 48.805532, lng: 20.6946735 },
    { lat: 48.8057606, lng: 20.6948013 },
    { lat: 48.8058843, lng: 20.6947598 },
    { lat: 48.8061366, lng: 20.6946751 },
    { lat: 48.8061935, lng: 20.6948805 },
    { lat: 48.8063445, lng: 20.6949785 },
    { lat: 48.8064562, lng: 20.6953993 },
    { lat: 48.8063932, lng: 20.6957149 },
    { lat: 48.8064738, lng: 20.6958908 },
    { lat: 48.8065002, lng: 20.6958132 },
    { lat: 48.8067066, lng: 20.6957246 },
    { lat: 48.8075949, lng: 20.695853 },
    { lat: 48.8078712, lng: 20.695645 },
    { lat: 48.8083587, lng: 20.6959648 },
    { lat: 48.8088113, lng: 20.6963456 },
    { lat: 48.8089697, lng: 20.6966195 },
    { lat: 48.809196, lng: 20.6968731 },
    { lat: 48.8095376, lng: 20.696818 },
    { lat: 48.8096962, lng: 20.6971322 },
    { lat: 48.8098587, lng: 20.6972379 },
    { lat: 48.8099412, lng: 20.6974061 },
    { lat: 48.810147, lng: 20.69737 },
    { lat: 48.8103127, lng: 20.698099 },
    { lat: 48.8104627, lng: 20.6980132 },
    { lat: 48.8106622, lng: 20.6987112 },
    { lat: 48.8106144, lng: 20.6960506 },
    { lat: 48.8104924, lng: 20.6936696 },
    { lat: 48.810385, lng: 20.6937881 },
    { lat: 48.8103334, lng: 20.6937284 },
    { lat: 48.8103137, lng: 20.6935539 },
    { lat: 48.81046, lng: 20.6930801 },
    { lat: 48.8104773, lng: 20.6928715 },
    { lat: 48.8104511, lng: 20.6922039 },
    { lat: 48.8104996, lng: 20.6912906 },
    { lat: 48.810408, lng: 20.6878178 },
    { lat: 48.8101537, lng: 20.6863403 },
    { lat: 48.8101511, lng: 20.6862344 },
    { lat: 48.8101984, lng: 20.6861705 },
    { lat: 48.8103147, lng: 20.6861517 },
    { lat: 48.8102815, lng: 20.6860419 },
    { lat: 48.8103292, lng: 20.6857994 },
    { lat: 48.8102137, lng: 20.6850888 },
    { lat: 48.8098008, lng: 20.683684 },
    { lat: 48.8095419, lng: 20.682548 },
    { lat: 48.8095475, lng: 20.6814453 },
    { lat: 48.8092753, lng: 20.6804197 },
    { lat: 48.8091574, lng: 20.67958 },
    { lat: 48.8087532, lng: 20.6783203 },
    { lat: 48.8088081, lng: 20.6779697 },
    { lat: 48.8088895, lng: 20.677668 },
    { lat: 48.8094238, lng: 20.6779691 },
    { lat: 48.809404, lng: 20.6776335 },
    { lat: 48.8096258, lng: 20.6770688 },
    { lat: 48.8098852, lng: 20.6767043 },
    { lat: 48.810394, lng: 20.6764531 },
    { lat: 48.8113525, lng: 20.6758414 },
    { lat: 48.8117808, lng: 20.6751268 },
    { lat: 48.8124975, lng: 20.6732676 },
    { lat: 48.8125071, lng: 20.6726528 },
    { lat: 48.8125697, lng: 20.6723922 },
    { lat: 48.8127797, lng: 20.6718749 },
    { lat: 48.8128095, lng: 20.6716424 },
    { lat: 48.8130271, lng: 20.6712751 },
    { lat: 48.8133504, lng: 20.6709303 },
    { lat: 48.8140488, lng: 20.6703809 },
    { lat: 48.8146158, lng: 20.669807 },
    { lat: 48.8155971, lng: 20.6681633 },
    { lat: 48.8158102, lng: 20.6676124 },
    { lat: 48.8160385, lng: 20.6672242 },
    { lat: 48.8160978, lng: 20.6668721 },
    { lat: 48.8171855, lng: 20.6660295 },
    { lat: 48.8172516, lng: 20.6648634 },
    { lat: 48.8172258, lng: 20.6643354 },
    { lat: 48.8172899, lng: 20.6640507 },
    { lat: 48.8172199, lng: 20.664079 },
    { lat: 48.8174098, lng: 20.6632203 },
    { lat: 48.8173821, lng: 20.6632054 },
    { lat: 48.8171328, lng: 20.6630952 },
    { lat: 48.8169146, lng: 20.6626942 },
    { lat: 48.8168181, lng: 20.6627346 },
    { lat: 48.816614, lng: 20.6624778 },
    { lat: 48.8161061, lng: 20.6622486 },
    { lat: 48.8158284, lng: 20.6620125 },
    { lat: 48.8156853, lng: 20.6617925 },
    { lat: 48.8156801, lng: 20.6616483 },
    { lat: 48.8149386, lng: 20.6610961 },
    { lat: 48.8146888, lng: 20.6606891 },
    { lat: 48.8146661, lng: 20.6604995 },
    { lat: 48.8142578, lng: 20.6596627 },
    { lat: 48.8141816, lng: 20.6592953 },
    { lat: 48.8140253, lng: 20.6592057 },
    { lat: 48.813825, lng: 20.6588401 },
    { lat: 48.8136258, lng: 20.6587115 },
    { lat: 48.813312, lng: 20.6587238 },
    { lat: 48.8128876, lng: 20.6583191 },
    { lat: 48.8125646, lng: 20.6576289 },
    { lat: 48.8124979, lng: 20.6573122 },
    { lat: 48.8123097, lng: 20.6570581 },
    { lat: 48.8121475, lng: 20.6571454 },
    { lat: 48.811882, lng: 20.6569995 },
    { lat: 48.8112793, lng: 20.6562088 },
    { lat: 48.8112537, lng: 20.6559962 },
    { lat: 48.8110217, lng: 20.655745 },
  ],
  Hrišovce: [
    { lat: 48.935962, lng: 20.894046 },
    { lat: 48.9362813, lng: 20.8962472 },
    { lat: 48.9362425, lng: 20.897031 },
    { lat: 48.9360593, lng: 20.897533 },
    { lat: 48.9357997, lng: 20.8986154 },
    { lat: 48.935717, lng: 20.8994264 },
    { lat: 48.9357962, lng: 20.8998731 },
    { lat: 48.9359302, lng: 20.9002758 },
    { lat: 48.9356903, lng: 20.9010356 },
    { lat: 48.9356329, lng: 20.9018355 },
    { lat: 48.9355375, lng: 20.9021752 },
    { lat: 48.9354813, lng: 20.9027785 },
    { lat: 48.935561, lng: 20.9045985 },
    { lat: 48.935914, lng: 20.9052187 },
    { lat: 48.9356213, lng: 20.9061179 },
    { lat: 48.9354144, lng: 20.9071235 },
    { lat: 48.9349777, lng: 20.906729 },
    { lat: 48.9347754, lng: 20.907436 },
    { lat: 48.9351242, lng: 20.9083268 },
    { lat: 48.9352849, lng: 20.908518 },
    { lat: 48.9350103, lng: 20.9088877 },
    { lat: 48.9351026, lng: 20.9090581 },
    { lat: 48.9353098, lng: 20.9097942 },
    { lat: 48.9356611, lng: 20.9093981 },
    { lat: 48.9355754, lng: 20.9091592 },
    { lat: 48.9357906, lng: 20.9090007 },
    { lat: 48.9360422, lng: 20.9092843 },
    { lat: 48.9358257, lng: 20.9099699 },
    { lat: 48.9355858, lng: 20.9105349 },
    { lat: 48.9359065, lng: 20.910801 },
    { lat: 48.9357255, lng: 20.9111753 },
    { lat: 48.9352011, lng: 20.913411 },
    { lat: 48.9351998, lng: 20.9138754 },
    { lat: 48.9352738, lng: 20.9140626 },
    { lat: 48.9351965, lng: 20.9145104 },
    { lat: 48.9352527, lng: 20.9147083 },
    { lat: 48.9351235, lng: 20.9155156 },
    { lat: 48.9351503, lng: 20.9161426 },
    { lat: 48.9349798, lng: 20.9166975 },
    { lat: 48.934695, lng: 20.9172308 },
    { lat: 48.9349476, lng: 20.9172742 },
    { lat: 48.935287, lng: 20.9174623 },
    { lat: 48.9357202, lng: 20.9177602 },
    { lat: 48.9364781, lng: 20.9184353 },
    { lat: 48.9366652, lng: 20.9185149 },
    { lat: 48.936906, lng: 20.9185439 },
    { lat: 48.9371175, lng: 20.9184791 },
    { lat: 48.9376332, lng: 20.918109 },
    { lat: 48.9382187, lng: 20.9175881 },
    { lat: 48.938244, lng: 20.9173029 },
    { lat: 48.9383693, lng: 20.9175462 },
    { lat: 48.9387303, lng: 20.918239 },
    { lat: 48.9387509, lng: 20.9184451 },
    { lat: 48.9389591, lng: 20.9187784 },
    { lat: 48.9404481, lng: 20.9179399 },
    { lat: 48.9412548, lng: 20.9185626 },
    { lat: 48.9414944, lng: 20.9187947 },
    { lat: 48.9417805, lng: 20.9192082 },
    { lat: 48.9419472, lng: 20.9191437 },
    { lat: 48.9422755, lng: 20.9191616 },
    { lat: 48.9441376, lng: 20.9194765 },
    { lat: 48.9448035, lng: 20.9194939 },
    { lat: 48.9447065, lng: 20.9196246 },
    { lat: 48.9447928, lng: 20.9200161 },
    { lat: 48.9449131, lng: 20.9201443 },
    { lat: 48.9449425, lng: 20.9202795 },
    { lat: 48.9450711, lng: 20.9202888 },
    { lat: 48.9451874, lng: 20.9205691 },
    { lat: 48.9454508, lng: 20.9208355 },
    { lat: 48.9457466, lng: 20.9205011 },
    { lat: 48.9458304, lng: 20.9202439 },
    { lat: 48.9460813, lng: 20.9200737 },
    { lat: 48.9461378, lng: 20.9202444 },
    { lat: 48.9464731, lng: 20.9203122 },
    { lat: 48.9465799, lng: 20.920141 },
    { lat: 48.9466523, lng: 20.9203878 },
    { lat: 48.9467513, lng: 20.9204833 },
    { lat: 48.9468994, lng: 20.9205037 },
    { lat: 48.9472202, lng: 20.9207352 },
    { lat: 48.9472969, lng: 20.9206397 },
    { lat: 48.9475135, lng: 20.9205932 },
    { lat: 48.9476003, lng: 20.9206992 },
    { lat: 48.9478372, lng: 20.9206791 },
    { lat: 48.9480353, lng: 20.9205462 },
    { lat: 48.9480665, lng: 20.9201628 },
    { lat: 48.9482432, lng: 20.9199686 },
    { lat: 48.9482487, lng: 20.9200967 },
    { lat: 48.948636, lng: 20.9202984 },
    { lat: 48.9486168, lng: 20.920528 },
    { lat: 48.9487853, lng: 20.9210213 },
    { lat: 48.9487981, lng: 20.9228387 },
    { lat: 48.9486658, lng: 20.923731 },
    { lat: 48.948549, lng: 20.9239037 },
    { lat: 48.9485134, lng: 20.9241521 },
    { lat: 48.9486378, lng: 20.9244332 },
    { lat: 48.9486035, lng: 20.9248538 },
    { lat: 48.948745, lng: 20.9251812 },
    { lat: 48.9489339, lng: 20.9259802 },
    { lat: 48.9489182, lng: 20.9261447 },
    { lat: 48.9489818, lng: 20.9262629 },
    { lat: 48.9489621, lng: 20.926412 },
    { lat: 48.94906, lng: 20.926813 },
    { lat: 48.9493141, lng: 20.9274483 },
    { lat: 48.9499138, lng: 20.9279444 },
    { lat: 48.9499422, lng: 20.9280518 },
    { lat: 48.9500805, lng: 20.9280776 },
    { lat: 48.9502045, lng: 20.9282506 },
    { lat: 48.9511917, lng: 20.9287253 },
    { lat: 48.9515484, lng: 20.9290232 },
    { lat: 48.9519515, lng: 20.9294938 },
    { lat: 48.9520405, lng: 20.9296996 },
    { lat: 48.9524236, lng: 20.9301523 },
    { lat: 48.9524621, lng: 20.9303574 },
    { lat: 48.9529401, lng: 20.9308802 },
    { lat: 48.953646, lng: 20.9321961 },
    { lat: 48.954731, lng: 20.934534 },
    { lat: 48.955495, lng: 20.934107 },
    { lat: 48.955769, lng: 20.933777 },
    { lat: 48.95595, lng: 20.933557 },
    { lat: 48.957373, lng: 20.931836 },
    { lat: 48.957632, lng: 20.93121 },
    { lat: 48.958735, lng: 20.929229 },
    { lat: 48.959154, lng: 20.92836 },
    { lat: 48.959819, lng: 20.927288 },
    { lat: 48.961026, lng: 20.925294 },
    { lat: 48.961153, lng: 20.92505 },
    { lat: 48.961385, lng: 20.924602 },
    { lat: 48.961717, lng: 20.924005 },
    { lat: 48.961865, lng: 20.923782 },
    { lat: 48.961893, lng: 20.92375 },
    { lat: 48.962059, lng: 20.923446 },
    { lat: 48.962178, lng: 20.923051 },
    { lat: 48.962544, lng: 20.922495 },
    { lat: 48.962751, lng: 20.922074 },
    { lat: 48.963413, lng: 20.920588 },
    { lat: 48.963711, lng: 20.91969 },
    { lat: 48.963763, lng: 20.918947 },
    { lat: 48.964079, lng: 20.917387 },
    { lat: 48.964021, lng: 20.916789 },
    { lat: 48.96397, lng: 20.916463 },
    { lat: 48.964073, lng: 20.916119 },
    { lat: 48.964085, lng: 20.915749 },
    { lat: 48.964264, lng: 20.915269 },
    { lat: 48.964393, lng: 20.914201 },
    { lat: 48.964363, lng: 20.913901 },
    { lat: 48.964287, lng: 20.91296 },
    { lat: 48.964223, lng: 20.91229 },
    { lat: 48.964242, lng: 20.911594 },
    { lat: 48.964547, lng: 20.909215 },
    { lat: 48.964589, lng: 20.908577 },
    { lat: 48.964683, lng: 20.907576 },
    { lat: 48.964694, lng: 20.906967 },
    { lat: 48.964466, lng: 20.905809 },
    { lat: 48.964253, lng: 20.905466 },
    { lat: 48.964169, lng: 20.905293 },
    { lat: 48.964104, lng: 20.905225 },
    { lat: 48.963955, lng: 20.9047 },
    { lat: 48.963652, lng: 20.904104 },
    { lat: 48.963477, lng: 20.903271 },
    { lat: 48.96318, lng: 20.900987 },
    { lat: 48.963086, lng: 20.900257 },
    { lat: 48.96315, lng: 20.899107 },
    { lat: 48.962118, lng: 20.896494 },
    { lat: 48.962036, lng: 20.89639 },
    { lat: 48.961971, lng: 20.896098 },
    { lat: 48.961383, lng: 20.894947 },
    { lat: 48.961244, lng: 20.894488 },
    { lat: 48.960961, lng: 20.89304 },
    { lat: 48.960872, lng: 20.892437 },
    { lat: 48.959625, lng: 20.889836 },
    { lat: 48.958947, lng: 20.890077 },
    { lat: 48.957769, lng: 20.888781 },
    { lat: 48.956277, lng: 20.887073 },
    { lat: 48.95438, lng: 20.884962 },
    { lat: 48.953481, lng: 20.884753 },
    { lat: 48.952062, lng: 20.884699 },
    { lat: 48.951352, lng: 20.885599 },
    { lat: 48.950846, lng: 20.886239 },
    { lat: 48.950719, lng: 20.88631 },
    { lat: 48.950511, lng: 20.886365 },
    { lat: 48.950252, lng: 20.887121 },
    { lat: 48.950129, lng: 20.887437 },
    { lat: 48.949973, lng: 20.887962 },
    { lat: 48.949812, lng: 20.888137 },
    { lat: 48.949721, lng: 20.888357 },
    { lat: 48.949629, lng: 20.888795 },
    { lat: 48.949173, lng: 20.888705 },
    { lat: 48.949112, lng: 20.888692 },
    { lat: 48.9487534, lng: 20.8886219 },
    { lat: 48.948248, lng: 20.888523 },
    { lat: 48.947466, lng: 20.888566 },
    { lat: 48.947331, lng: 20.888578 },
    { lat: 48.945886, lng: 20.888427 },
    { lat: 48.945658, lng: 20.888223 },
    { lat: 48.943219, lng: 20.890833 },
    { lat: 48.941963, lng: 20.890379 },
    { lat: 48.939901, lng: 20.88872 },
    { lat: 48.939061, lng: 20.889542 },
    { lat: 48.938978, lng: 20.890338 },
    { lat: 48.938988, lng: 20.890862 },
    { lat: 48.939035, lng: 20.891159 },
    { lat: 48.939083, lng: 20.892151 },
    { lat: 48.93891, lng: 20.893066 },
    { lat: 48.93887, lng: 20.89339 },
    { lat: 48.937769, lng: 20.893783 },
    { lat: 48.937151, lng: 20.894083 },
    { lat: 48.935962, lng: 20.894046 },
  ],
  Kojšov: [
    { lat: 48.841163, lng: 20.9885923 },
    { lat: 48.8414623, lng: 20.9884219 },
    { lat: 48.8414578, lng: 20.9879814 },
    { lat: 48.8409629, lng: 20.9881626 },
    { lat: 48.8409126, lng: 20.9880032 },
    { lat: 48.840407, lng: 20.9876861 },
    { lat: 48.8401869, lng: 20.9874631 },
    { lat: 48.8398209, lng: 20.9870543 },
    { lat: 48.8397977, lng: 20.9869282 },
    { lat: 48.8398336, lng: 20.9868593 },
    { lat: 48.8397836, lng: 20.9866752 },
    { lat: 48.8398617, lng: 20.9858374 },
    { lat: 48.8398354, lng: 20.9852159 },
    { lat: 48.8400072, lng: 20.983169 },
    { lat: 48.8400355, lng: 20.9822254 },
    { lat: 48.8399501, lng: 20.9814501 },
    { lat: 48.8392761, lng: 20.9780652 },
    { lat: 48.8392308, lng: 20.976703 },
    { lat: 48.8391094, lng: 20.9763364 },
    { lat: 48.838967, lng: 20.9761815 },
    { lat: 48.8385828, lng: 20.9759353 },
    { lat: 48.8381316, lng: 20.9754642 },
    { lat: 48.8372566, lng: 20.9743662 },
    { lat: 48.836631, lng: 20.9739312 },
    { lat: 48.8359642, lng: 20.97223 },
    { lat: 48.8356495, lng: 20.971936 },
    { lat: 48.8350994, lng: 20.9716815 },
    { lat: 48.8345279, lng: 20.97131 },
    { lat: 48.8341775, lng: 20.9704918 },
    { lat: 48.8339214, lng: 20.9701038 },
    { lat: 48.8335436, lng: 20.9693063 },
    { lat: 48.8328599, lng: 20.9682092 },
    { lat: 48.8328492, lng: 20.9679885 },
    { lat: 48.8325058, lng: 20.9673095 },
    { lat: 48.8320567, lng: 20.966196 },
    { lat: 48.831751, lng: 20.9656388 },
    { lat: 48.8309884, lng: 20.9654439 },
    { lat: 48.8306227, lng: 20.9652157 },
    { lat: 48.829871, lng: 20.9651143 },
    { lat: 48.8294009, lng: 20.9648241 },
    { lat: 48.8291668, lng: 20.9647294 },
    { lat: 48.8290235, lng: 20.9647458 },
    { lat: 48.8288887, lng: 20.9646571 },
    { lat: 48.8287635, lng: 20.9645438 },
    { lat: 48.8286377, lng: 20.9642809 },
    { lat: 48.8284269, lng: 20.9640344 },
    { lat: 48.826702, lng: 20.9625697 },
    { lat: 48.8256336, lng: 20.9615559 },
    { lat: 48.8253209, lng: 20.9611547 },
    { lat: 48.8248408, lng: 20.9607678 },
    { lat: 48.8247516, lng: 20.9607597 },
    { lat: 48.8243219, lng: 20.9610633 },
    { lat: 48.8238951, lng: 20.9612293 },
    { lat: 48.8229714, lng: 20.9617783 },
    { lat: 48.8224771, lng: 20.9619801 },
    { lat: 48.8211082, lng: 20.962716 },
    { lat: 48.8203681, lng: 20.9635346 },
    { lat: 48.8202009, lng: 20.9637917 },
    { lat: 48.8200124, lng: 20.9639439 },
    { lat: 48.8197121, lng: 20.9639859 },
    { lat: 48.817977, lng: 20.9634961 },
    { lat: 48.8177075, lng: 20.9635746 },
    { lat: 48.8174445, lng: 20.9635258 },
    { lat: 48.8169101, lng: 20.9631609 },
    { lat: 48.8166456, lng: 20.9631569 },
    { lat: 48.8164847, lng: 20.963226 },
    { lat: 48.8160815, lng: 20.9630536 },
    { lat: 48.815727, lng: 20.962784 },
    { lat: 48.8154403, lng: 20.9627584 },
    { lat: 48.8150561, lng: 20.9626121 },
    { lat: 48.8144611, lng: 20.9625934 },
    { lat: 48.8136116, lng: 20.9622995 },
    { lat: 48.8130692, lng: 20.9622363 },
    { lat: 48.8126335, lng: 20.9623618 },
    { lat: 48.8124167, lng: 20.9623481 },
    { lat: 48.8117411, lng: 20.9619984 },
    { lat: 48.8110853, lng: 20.9620089 },
    { lat: 48.8107294, lng: 20.9621069 },
    { lat: 48.8106386, lng: 20.962321 },
    { lat: 48.8095357, lng: 20.9630689 },
    { lat: 48.8093112, lng: 20.9633878 },
    { lat: 48.8090007, lng: 20.9634266 },
    { lat: 48.8088039, lng: 20.9635291 },
    { lat: 48.8081324, lng: 20.9640526 },
    { lat: 48.8078882, lng: 20.9641555 },
    { lat: 48.8070736, lng: 20.9642985 },
    { lat: 48.806744, lng: 20.9645444 },
    { lat: 48.8063495, lng: 20.9646246 },
    { lat: 48.8061079, lng: 20.9648059 },
    { lat: 48.8056709, lng: 20.9649007 },
    { lat: 48.8054502, lng: 20.9648323 },
    { lat: 48.8051597, lng: 20.9649602 },
    { lat: 48.8050596, lng: 20.9649307 },
    { lat: 48.8048619, lng: 20.9650047 },
    { lat: 48.8046597, lng: 20.9649177 },
    { lat: 48.8034716, lng: 20.9650382 },
    { lat: 48.8029671, lng: 20.964895 },
    { lat: 48.8025668, lng: 20.9650431 },
    { lat: 48.8023979, lng: 20.9652046 },
    { lat: 48.8019512, lng: 20.9652491 },
    { lat: 48.8013416, lng: 20.9657663 },
    { lat: 48.8001842, lng: 20.9664671 },
    { lat: 48.7994281, lng: 20.9663042 },
    { lat: 48.7981941, lng: 20.9655149 },
    { lat: 48.7974497, lng: 20.9652302 },
    { lat: 48.7970504, lng: 20.9649069 },
    { lat: 48.7967402, lng: 20.9647503 },
    { lat: 48.7948192, lng: 20.9641522 },
    { lat: 48.7942675, lng: 20.9607494 },
    { lat: 48.7943192, lng: 20.9583517 },
    { lat: 48.7943775, lng: 20.9579075 },
    { lat: 48.7942007, lng: 20.9574648 },
    { lat: 48.7941838, lng: 20.9572279 },
    { lat: 48.7946431, lng: 20.9547066 },
    { lat: 48.7946937, lng: 20.954239 },
    { lat: 48.7948178, lng: 20.9539799 },
    { lat: 48.7949023, lng: 20.9534744 },
    { lat: 48.7949054, lng: 20.952798 },
    { lat: 48.7950657, lng: 20.952071 },
    { lat: 48.7951124, lng: 20.9513787 },
    { lat: 48.7948628, lng: 20.9513575 },
    { lat: 48.7947809, lng: 20.951291 },
    { lat: 48.7946439, lng: 20.951335 },
    { lat: 48.7944706, lng: 20.9512748 },
    { lat: 48.7939961, lng: 20.9514586 },
    { lat: 48.7936772, lng: 20.9514442 },
    { lat: 48.793504, lng: 20.951511 },
    { lat: 48.7926772, lng: 20.9519661 },
    { lat: 48.7925662, lng: 20.9521809 },
    { lat: 48.792457, lng: 20.9522532 },
    { lat: 48.7920592, lng: 20.9523051 },
    { lat: 48.7920027, lng: 20.9524002 },
    { lat: 48.7918349, lng: 20.9524598 },
    { lat: 48.7917176, lng: 20.952422 },
    { lat: 48.7916126, lng: 20.95261 },
    { lat: 48.791466, lng: 20.9527055 },
    { lat: 48.7910925, lng: 20.9527084 },
    { lat: 48.7910018, lng: 20.9528614 },
    { lat: 48.7906268, lng: 20.9531568 },
    { lat: 48.7901439, lng: 20.9532891 },
    { lat: 48.790095, lng: 20.9535096 },
    { lat: 48.7897245, lng: 20.9538075 },
    { lat: 48.7893126, lng: 20.9539758 },
    { lat: 48.7891381, lng: 20.9544821 },
    { lat: 48.7888166, lng: 20.9547923 },
    { lat: 48.7885341, lng: 20.9551963 },
    { lat: 48.7882655, lng: 20.955818 },
    { lat: 48.7879959, lng: 20.9562677 },
    { lat: 48.7880223, lng: 20.9567561 },
    { lat: 48.7878581, lng: 20.9571614 },
    { lat: 48.7875877, lng: 20.9576254 },
    { lat: 48.7875701, lng: 20.9577767 },
    { lat: 48.7874534, lng: 20.9580515 },
    { lat: 48.7872772, lng: 20.9582064 },
    { lat: 48.7872294, lng: 20.9584556 },
    { lat: 48.7869452, lng: 20.9587804 },
    { lat: 48.7866814, lng: 20.9592026 },
    { lat: 48.786585, lng: 20.9595212 },
    { lat: 48.7865861, lng: 20.9599219 },
    { lat: 48.7866333, lng: 20.9600558 },
    { lat: 48.7865726, lng: 20.9602567 },
    { lat: 48.7866804, lng: 20.9614646 },
    { lat: 48.7866435, lng: 20.9618425 },
    { lat: 48.7865262, lng: 20.9619693 },
    { lat: 48.7862835, lng: 20.9626532 },
    { lat: 48.7860135, lng: 20.9629644 },
    { lat: 48.7857895, lng: 20.9638367 },
    { lat: 48.7858624, lng: 20.9659214 },
    { lat: 48.7858588, lng: 20.9662522 },
    { lat: 48.7857937, lng: 20.9664274 },
    { lat: 48.7858498, lng: 20.9666244 },
    { lat: 48.7859111, lng: 20.9672451 },
    { lat: 48.7859321, lng: 20.9682158 },
    { lat: 48.7858758, lng: 20.9699352 },
    { lat: 48.78601, lng: 20.9705806 },
    { lat: 48.786018, lng: 20.9711222 },
    { lat: 48.7858351, lng: 20.9720181 },
    { lat: 48.785389, lng: 20.9727874 },
    { lat: 48.7842041, lng: 20.9740146 },
    { lat: 48.7833676, lng: 20.9753269 },
    { lat: 48.7831334, lng: 20.9760293 },
    { lat: 48.7827573, lng: 20.9764027 },
    { lat: 48.7809652, lng: 20.9776468 },
    { lat: 48.7800716, lng: 20.9787977 },
    { lat: 48.7774767, lng: 20.9806436 },
    { lat: 48.777477, lng: 20.980531 },
    { lat: 48.7774195, lng: 20.9806071 },
    { lat: 48.776641, lng: 20.981614 },
    { lat: 48.776059, lng: 20.983069 },
    { lat: 48.775919, lng: 20.983397 },
    { lat: 48.775095, lng: 20.986168 },
    { lat: 48.774989, lng: 20.98636 },
    { lat: 48.774804, lng: 20.987677 },
    { lat: 48.774658, lng: 20.988588 },
    { lat: 48.774286, lng: 20.98978 },
    { lat: 48.773864, lng: 20.990991 },
    { lat: 48.773628, lng: 20.991543 },
    { lat: 48.773549, lng: 20.992118 },
    { lat: 48.773375, lng: 20.993342 },
    { lat: 48.77311, lng: 20.994871 },
    { lat: 48.772979, lng: 20.99608 },
    { lat: 48.77277, lng: 20.996879 },
    { lat: 48.77243, lng: 20.997945 },
    { lat: 48.772391, lng: 20.998041 },
    { lat: 48.771925, lng: 20.998947 },
    { lat: 48.771633, lng: 20.999462 },
    { lat: 48.771605, lng: 20.999884 },
    { lat: 48.771361, lng: 21.000244 },
    { lat: 48.77122, lng: 21.00058 },
    { lat: 48.771043, lng: 21.001241 },
    { lat: 48.770845, lng: 21.001973 },
    { lat: 48.77038, lng: 21.002764 },
    { lat: 48.770426, lng: 21.003906 },
    { lat: 48.770567, lng: 21.004966 },
    { lat: 48.770655, lng: 21.005714 },
    { lat: 48.770754, lng: 21.006471 },
    { lat: 48.7707634, lng: 21.0066261 },
    { lat: 48.770804, lng: 21.007296 },
    { lat: 48.771142, lng: 21.008884 },
    { lat: 48.771221, lng: 21.009259 },
    { lat: 48.771314, lng: 21.009691 },
    { lat: 48.771671, lng: 21.011456 },
    { lat: 48.772099, lng: 21.012728 },
    { lat: 48.77222, lng: 21.013179 },
    { lat: 48.772592, lng: 21.014544 },
    { lat: 48.772689, lng: 21.015646 },
    { lat: 48.77266, lng: 21.016271 },
    { lat: 48.772662, lng: 21.016768 },
    { lat: 48.772663, lng: 21.01677 },
    { lat: 48.772539, lng: 21.017286 },
    { lat: 48.772519, lng: 21.01739 },
    { lat: 48.772482, lng: 21.017668 },
    { lat: 48.772376, lng: 21.018077 },
    { lat: 48.772344, lng: 21.018234 },
    { lat: 48.772365, lng: 21.01871 },
    { lat: 48.772456, lng: 21.018862 },
    { lat: 48.772497, lng: 21.018908 },
    { lat: 48.772715, lng: 21.019246 },
    { lat: 48.773025, lng: 21.019532 },
    { lat: 48.773296, lng: 21.019805 },
    { lat: 48.773439, lng: 21.019984 },
    { lat: 48.77357, lng: 21.020218 },
    { lat: 48.773668, lng: 21.020487 },
    { lat: 48.773766, lng: 21.021039 },
    { lat: 48.774017, lng: 21.021958 },
    { lat: 48.774113, lng: 21.022182 },
    { lat: 48.774236, lng: 21.022353 },
    { lat: 48.774391, lng: 21.02257 },
    { lat: 48.77455, lng: 21.022985 },
    { lat: 48.774682, lng: 21.023202 },
    { lat: 48.774764, lng: 21.023303 },
    { lat: 48.774903, lng: 21.023461 },
    { lat: 48.775131, lng: 21.023642 },
    { lat: 48.775247, lng: 21.02381 },
    { lat: 48.775343, lng: 21.024012 },
    { lat: 48.775468, lng: 21.024546 },
    { lat: 48.77567, lng: 21.025141 },
    { lat: 48.775725, lng: 21.025368 },
    { lat: 48.775806, lng: 21.026202 },
    { lat: 48.775831, lng: 21.026443 },
    { lat: 48.775882, lng: 21.026679 },
    { lat: 48.775941, lng: 21.027012 },
    { lat: 48.775971, lng: 21.027948 },
    { lat: 48.776002, lng: 21.028118 },
    { lat: 48.776076, lng: 21.028329 },
    { lat: 48.776173, lng: 21.028485 },
    { lat: 48.776439, lng: 21.028727 },
    { lat: 48.776547, lng: 21.028859 },
    { lat: 48.776968, lng: 21.029324 },
    { lat: 48.777267, lng: 21.029598 },
    { lat: 48.777458, lng: 21.029787 },
    { lat: 48.777722, lng: 21.030026 },
    { lat: 48.777938, lng: 21.030167 },
    { lat: 48.778044, lng: 21.030272 },
    { lat: 48.77824, lng: 21.030508 },
    { lat: 48.778344, lng: 21.030655 },
    { lat: 48.778415, lng: 21.03072 },
    { lat: 48.778501, lng: 21.030768 },
    { lat: 48.778612, lng: 21.030807 },
    { lat: 48.778643, lng: 21.030818 },
    { lat: 48.77873, lng: 21.030783 },
    { lat: 48.779055, lng: 21.030529 },
    { lat: 48.779277, lng: 21.030223 },
    { lat: 48.779361, lng: 21.030044 },
    { lat: 48.779492, lng: 21.029406 },
    { lat: 48.779525, lng: 21.029355 },
    { lat: 48.779671, lng: 21.029305 },
    { lat: 48.780024, lng: 21.029159 },
    { lat: 48.780202, lng: 21.029077 },
    { lat: 48.780546, lng: 21.028973 },
    { lat: 48.781094, lng: 21.028906 },
    { lat: 48.781409, lng: 21.028835 },
    { lat: 48.781611, lng: 21.028719 },
    { lat: 48.781832, lng: 21.028566 },
    { lat: 48.782303, lng: 21.028253 },
    { lat: 48.782507, lng: 21.028062 },
    { lat: 48.78352, lng: 21.026693 },
    { lat: 48.783754, lng: 21.026444 },
    { lat: 48.784253, lng: 21.026004 },
    { lat: 48.784895, lng: 21.025214 },
    { lat: 48.78517, lng: 21.024924 },
    { lat: 48.785501, lng: 21.024672 },
    { lat: 48.7857323, lng: 21.0245512 },
    { lat: 48.785997, lng: 21.024432 },
    { lat: 48.786473, lng: 21.024267 },
    { lat: 48.787303, lng: 21.023835 },
    { lat: 48.78757, lng: 21.023722 },
    { lat: 48.7878372, lng: 21.0236179 },
    { lat: 48.788147, lng: 21.023503 },
    { lat: 48.7882031, lng: 21.0235371 },
    { lat: 48.788238, lng: 21.023608 },
    { lat: 48.788379, lng: 21.023894 },
    { lat: 48.788469, lng: 21.024016 },
    { lat: 48.788673, lng: 21.024255 },
    { lat: 48.788759, lng: 21.024429 },
    { lat: 48.788953, lng: 21.025008 },
    { lat: 48.789546, lng: 21.02666 },
    { lat: 48.789598, lng: 21.02699 },
    { lat: 48.789869, lng: 21.028258 },
    { lat: 48.789922, lng: 21.028448 },
    { lat: 48.789984, lng: 21.02861 },
    { lat: 48.790071, lng: 21.028734 },
    { lat: 48.790218, lng: 21.028845 },
    { lat: 48.790341, lng: 21.02889 },
    { lat: 48.79064, lng: 21.028996 },
    { lat: 48.791021, lng: 21.029042 },
    { lat: 48.791141, lng: 21.029042 },
    { lat: 48.79129, lng: 21.029003 },
    { lat: 48.791525, lng: 21.028974 },
    { lat: 48.791675, lng: 21.028979 },
    { lat: 48.791919, lng: 21.028986 },
    { lat: 48.792113, lng: 21.029033 },
    { lat: 48.792287, lng: 21.029042 },
    { lat: 48.792509, lng: 21.028927 },
    { lat: 48.792731, lng: 21.028784 },
    { lat: 48.792879, lng: 21.028734 },
    { lat: 48.793192, lng: 21.028857 },
    { lat: 48.793387, lng: 21.028876 },
    { lat: 48.79375, lng: 21.028749 },
    { lat: 48.793969, lng: 21.028731 },
    { lat: 48.794305, lng: 21.028626 },
    { lat: 48.794465, lng: 21.028598 },
    { lat: 48.794894, lng: 21.028581 },
    { lat: 48.795374, lng: 21.028592 },
    { lat: 48.795986, lng: 21.028668 },
    { lat: 48.796139, lng: 21.028675 },
    { lat: 48.796304, lng: 21.02867 },
    { lat: 48.796386, lng: 21.02863 },
    { lat: 48.796526, lng: 21.028561 },
    { lat: 48.7969, lng: 21.02846 },
    { lat: 48.797634, lng: 21.028574 },
    { lat: 48.797868, lng: 21.028623 },
    { lat: 48.798053, lng: 21.028663 },
    { lat: 48.798322, lng: 21.028672 },
    { lat: 48.798467, lng: 21.028695 },
    { lat: 48.798609, lng: 21.028744 },
    { lat: 48.798945, lng: 21.028764 },
    { lat: 48.799034, lng: 21.028788 },
    { lat: 48.799182, lng: 21.028912 },
    { lat: 48.79947, lng: 21.029222 },
    { lat: 48.799893, lng: 21.029673 },
    { lat: 48.800348, lng: 21.030273 },
    { lat: 48.800451, lng: 21.030366 },
    { lat: 48.800573, lng: 21.030445 },
    { lat: 48.800675, lng: 21.030438 },
    { lat: 48.800747, lng: 21.030373 },
    { lat: 48.800958, lng: 21.030004 },
    { lat: 48.801275, lng: 21.029332 },
    { lat: 48.801565, lng: 21.028812 },
    { lat: 48.801869, lng: 21.0285 },
    { lat: 48.802479, lng: 21.027915 },
    { lat: 48.802659, lng: 21.02767 },
    { lat: 48.802851, lng: 21.02736 },
    { lat: 48.803331, lng: 21.026757 },
    { lat: 48.803609, lng: 21.026483 },
    { lat: 48.803719, lng: 21.026413 },
    { lat: 48.803953, lng: 21.026341 },
    { lat: 48.80417, lng: 21.026288 },
    { lat: 48.804547, lng: 21.02628 },
    { lat: 48.804656, lng: 21.026211 },
    { lat: 48.804675, lng: 21.026272 },
    { lat: 48.805081, lng: 21.026644 },
    { lat: 48.805302, lng: 21.02708 },
    { lat: 48.805423, lng: 21.027731 },
    { lat: 48.805436, lng: 21.027802 },
    { lat: 48.805447, lng: 21.027862 },
    { lat: 48.805607, lng: 21.028646 },
    { lat: 48.805611, lng: 21.029376 },
    { lat: 48.805371, lng: 21.030289 },
    { lat: 48.805107, lng: 21.03125 },
    { lat: 48.805107, lng: 21.032535 },
    { lat: 48.805383, lng: 21.033343 },
    { lat: 48.804951, lng: 21.034787 },
    { lat: 48.804881, lng: 21.03575 },
    { lat: 48.805381, lng: 21.036625 },
    { lat: 48.805964, lng: 21.03688 },
    { lat: 48.806487, lng: 21.037178 },
    { lat: 48.80691, lng: 21.037762 },
    { lat: 48.807303, lng: 21.038188 },
    { lat: 48.807426, lng: 21.039146 },
    { lat: 48.807557, lng: 21.040254 },
    { lat: 48.807556, lng: 21.0412 },
    { lat: 48.807771, lng: 21.042249 },
    { lat: 48.807629, lng: 21.042987 },
    { lat: 48.807579, lng: 21.043526 },
    { lat: 48.807545, lng: 21.044035 },
    { lat: 48.807876, lng: 21.044578 },
    { lat: 48.808316, lng: 21.045336 },
    { lat: 48.80862, lng: 21.046331 },
    { lat: 48.808823, lng: 21.046991 },
    { lat: 48.809166, lng: 21.047584 },
    { lat: 48.809555, lng: 21.047785 },
    { lat: 48.810119, lng: 21.047851 },
    { lat: 48.810953, lng: 21.047962 },
    { lat: 48.811687, lng: 21.047792 },
    { lat: 48.812247, lng: 21.04847 },
    { lat: 48.81316, lng: 21.048661 },
    { lat: 48.813571, lng: 21.048305 },
    { lat: 48.813998, lng: 21.047919 },
    { lat: 48.814468, lng: 21.047509 },
    { lat: 48.814563, lng: 21.046426 },
    { lat: 48.814856, lng: 21.045958 },
    { lat: 48.815194, lng: 21.045198 },
    { lat: 48.81553, lng: 21.044374 },
    { lat: 48.815716, lng: 21.043695 },
    { lat: 48.815824, lng: 21.043134 },
    { lat: 48.816012, lng: 21.042905 },
    { lat: 48.816258, lng: 21.042603 },
    { lat: 48.816559, lng: 21.042389 },
    { lat: 48.817003, lng: 21.043033 },
    { lat: 48.817397, lng: 21.043019 },
    { lat: 48.817406, lng: 21.043019 },
    { lat: 48.817476, lng: 21.043023 },
    { lat: 48.817483, lng: 21.043023 },
    { lat: 48.817553, lng: 21.043026 },
    { lat: 48.817566, lng: 21.043027 },
    { lat: 48.817655, lng: 21.043027 },
    { lat: 48.817681, lng: 21.043022 },
    { lat: 48.817547, lng: 21.0424701 },
    { lat: 48.8175719, lng: 21.0417889 },
    { lat: 48.8176571, lng: 21.0411579 },
    { lat: 48.8177095, lng: 21.0398397 },
    { lat: 48.817807, lng: 21.0395184 },
    { lat: 48.8181982, lng: 21.038808 },
    { lat: 48.8185587, lng: 21.036796 },
    { lat: 48.8186627, lng: 21.0365985 },
    { lat: 48.8188382, lng: 21.0357082 },
    { lat: 48.8193964, lng: 21.0348412 },
    { lat: 48.8195365, lng: 21.0335518 },
    { lat: 48.8198396, lng: 21.0327775 },
    { lat: 48.8198943, lng: 21.0325231 },
    { lat: 48.8202382, lng: 21.0323008 },
    { lat: 48.8203488, lng: 21.0323089 },
    { lat: 48.8206393, lng: 21.0320695 },
    { lat: 48.8209586, lng: 21.0314416 },
    { lat: 48.8210035, lng: 21.0311993 },
    { lat: 48.8212768, lng: 21.0305433 },
    { lat: 48.8213999, lng: 21.0299026 },
    { lat: 48.8215845, lng: 21.0286375 },
    { lat: 48.8212432, lng: 21.0275938 },
    { lat: 48.8212505, lng: 21.0252803 },
    { lat: 48.8214264, lng: 21.0251563 },
    { lat: 48.8214862, lng: 21.0250229 },
    { lat: 48.8216565, lng: 21.0204093 },
    { lat: 48.8218848, lng: 21.0201924 },
    { lat: 48.8222382, lng: 21.019614 },
    { lat: 48.8224097, lng: 21.019286 },
    { lat: 48.8224387, lng: 21.0191056 },
    { lat: 48.8229843, lng: 21.0182068 },
    { lat: 48.823185, lng: 21.0184199 },
    { lat: 48.8234049, lng: 21.0182658 },
    { lat: 48.8235612, lng: 21.0180915 },
    { lat: 48.8235758, lng: 21.0179172 },
    { lat: 48.8239597, lng: 21.0176146 },
    { lat: 48.8242194, lng: 21.0171935 },
    { lat: 48.8241015, lng: 21.0170656 },
    { lat: 48.8241257, lng: 21.0167766 },
    { lat: 48.8245357, lng: 21.016786 },
    { lat: 48.8247408, lng: 21.0165169 },
    { lat: 48.8248188, lng: 21.0162846 },
    { lat: 48.824528, lng: 21.0159004 },
    { lat: 48.8245489, lng: 21.0158163 },
    { lat: 48.8246149, lng: 21.0158746 },
    { lat: 48.8246143, lng: 21.0153763 },
    { lat: 48.8247114, lng: 21.01518 },
    { lat: 48.8244824, lng: 21.014858 },
    { lat: 48.8247216, lng: 21.0145999 },
    { lat: 48.8248829, lng: 21.0146441 },
    { lat: 48.8249368, lng: 21.0145386 },
    { lat: 48.8247724, lng: 21.0141603 },
    { lat: 48.8248167, lng: 21.0132482 },
    { lat: 48.8245798, lng: 21.0129593 },
    { lat: 48.8242707, lng: 21.0128348 },
    { lat: 48.8240984, lng: 21.0128385 },
    { lat: 48.8244541, lng: 21.0113343 },
    { lat: 48.8245055, lng: 21.0107053 },
    { lat: 48.8247388, lng: 21.0095896 },
    { lat: 48.8249144, lng: 21.008978 },
    { lat: 48.8250124, lng: 21.0074747 },
    { lat: 48.8251507, lng: 21.0073346 },
    { lat: 48.825284, lng: 21.0070423 },
    { lat: 48.8253973, lng: 21.0065854 },
    { lat: 48.8256472, lng: 21.0062118 },
    { lat: 48.8260049, lng: 21.0052878 },
    { lat: 48.826336, lng: 21.0048151 },
    { lat: 48.8268579, lng: 21.002762 },
    { lat: 48.8273824, lng: 21.0021121 },
    { lat: 48.8275525, lng: 21.0016081 },
    { lat: 48.8278117, lng: 21.0010719 },
    { lat: 48.8280337, lng: 21.0008097 },
    { lat: 48.8280797, lng: 21.0008369 },
    { lat: 48.8282006, lng: 21.0005073 },
    { lat: 48.8282164, lng: 21.0003071 },
    { lat: 48.8283185, lng: 21.0000915 },
    { lat: 48.829153, lng: 20.9990158 },
    { lat: 48.8292502, lng: 20.9985176 },
    { lat: 48.8294743, lng: 20.998148 },
    { lat: 48.8296184, lng: 20.9977774 },
    { lat: 48.8297058, lng: 20.9977936 },
    { lat: 48.8298508, lng: 20.9975875 },
    { lat: 48.8299496, lng: 20.9969642 },
    { lat: 48.8299512, lng: 20.9963014 },
    { lat: 48.8301851, lng: 20.9954073 },
    { lat: 48.830257, lng: 20.9953079 },
    { lat: 48.8302667, lng: 20.9951864 },
    { lat: 48.8304227, lng: 20.9950403 },
    { lat: 48.8304186, lng: 20.994917 },
    { lat: 48.8304854, lng: 20.9947522 },
    { lat: 48.8310465, lng: 20.9938911 },
    { lat: 48.8312899, lng: 20.9941324 },
    { lat: 48.8314359, lng: 20.9941967 },
    { lat: 48.8317402, lng: 20.9946469 },
    { lat: 48.8318434, lng: 20.9948817 },
    { lat: 48.8323665, lng: 20.9955504 },
    { lat: 48.8326587, lng: 20.996082 },
    { lat: 48.8332034, lng: 20.9954672 },
    { lat: 48.8334313, lng: 20.9953817 },
    { lat: 48.8335057, lng: 20.9949094 },
    { lat: 48.8337344, lng: 20.9943372 },
    { lat: 48.8337658, lng: 20.9940594 },
    { lat: 48.8339211, lng: 20.993785 },
    { lat: 48.8343005, lng: 20.9934193 },
    { lat: 48.8345817, lng: 20.9928652 },
    { lat: 48.834706, lng: 20.9924374 },
    { lat: 48.8347814, lng: 20.991829 },
    { lat: 48.8348947, lng: 20.9915756 },
    { lat: 48.8351856, lng: 20.9913928 },
    { lat: 48.8352712, lng: 20.9911522 },
    { lat: 48.8356261, lng: 20.9907212 },
    { lat: 48.8359159, lng: 20.9905352 },
    { lat: 48.8361292, lng: 20.9901467 },
    { lat: 48.8367346, lng: 20.9897662 },
    { lat: 48.8370015, lng: 20.989737 },
    { lat: 48.8370942, lng: 20.9895099 },
    { lat: 48.8374804, lng: 20.9893133 },
    { lat: 48.8377304, lng: 20.9891131 },
    { lat: 48.8379667, lng: 20.9891123 },
    { lat: 48.838259, lng: 20.9889289 },
    { lat: 48.8392715, lng: 20.9889502 },
    { lat: 48.8395803, lng: 20.9891014 },
    { lat: 48.8397138, lng: 20.9890527 },
    { lat: 48.8397575, lng: 20.9892462 },
    { lat: 48.8400829, lng: 20.9890171 },
    { lat: 48.8402556, lng: 20.9893373 },
    { lat: 48.8403926, lng: 20.9897704 },
    { lat: 48.8410545, lng: 20.9894956 },
    { lat: 48.8412391, lng: 20.9895995 },
    { lat: 48.841246, lng: 20.9894382 },
    { lat: 48.8411633, lng: 20.989249 },
    { lat: 48.841237, lng: 20.9891326 },
    { lat: 48.841163, lng: 20.9885923 },
  ],
  Nálepkovo: [
    { lat: 48.8110217, lng: 20.655745 },
    { lat: 48.8112537, lng: 20.6559962 },
    { lat: 48.8112793, lng: 20.6562088 },
    { lat: 48.811882, lng: 20.6569995 },
    { lat: 48.8121475, lng: 20.6571454 },
    { lat: 48.8123097, lng: 20.6570581 },
    { lat: 48.8124979, lng: 20.6573122 },
    { lat: 48.8125646, lng: 20.6576289 },
    { lat: 48.8128876, lng: 20.6583191 },
    { lat: 48.813312, lng: 20.6587238 },
    { lat: 48.8136258, lng: 20.6587115 },
    { lat: 48.813825, lng: 20.6588401 },
    { lat: 48.8140253, lng: 20.6592057 },
    { lat: 48.8141816, lng: 20.6592953 },
    { lat: 48.8142578, lng: 20.6596627 },
    { lat: 48.8146661, lng: 20.6604995 },
    { lat: 48.8146888, lng: 20.6606891 },
    { lat: 48.8149386, lng: 20.6610961 },
    { lat: 48.8156801, lng: 20.6616483 },
    { lat: 48.8156853, lng: 20.6617925 },
    { lat: 48.8158284, lng: 20.6620125 },
    { lat: 48.8161061, lng: 20.6622486 },
    { lat: 48.816614, lng: 20.6624778 },
    { lat: 48.8168181, lng: 20.6627346 },
    { lat: 48.8169146, lng: 20.6626942 },
    { lat: 48.8171328, lng: 20.6630952 },
    { lat: 48.8173821, lng: 20.6632054 },
    { lat: 48.8174098, lng: 20.6632203 },
    { lat: 48.81761, lng: 20.6633091 },
    { lat: 48.8183112, lng: 20.6639293 },
    { lat: 48.8187237, lng: 20.6644433 },
    { lat: 48.819047, lng: 20.6649854 },
    { lat: 48.8191339, lng: 20.6653669 },
    { lat: 48.8191558, lng: 20.6669416 },
    { lat: 48.8195654, lng: 20.6691292 },
    { lat: 48.8197198, lng: 20.6719559 },
    { lat: 48.820352, lng: 20.6746115 },
    { lat: 48.82055, lng: 20.67489 },
    { lat: 48.8210542, lng: 20.6748192 },
    { lat: 48.8213484, lng: 20.6748647 },
    { lat: 48.8217434, lng: 20.675044 },
    { lat: 48.82244, lng: 20.6749449 },
    { lat: 48.8230004, lng: 20.6749346 },
    { lat: 48.8238357, lng: 20.6750338 },
    { lat: 48.8240336, lng: 20.6750236 },
    { lat: 48.8242269, lng: 20.6750156 },
    { lat: 48.824577, lng: 20.6749817 },
    { lat: 48.8268006, lng: 20.6754273 },
    { lat: 48.8279835, lng: 20.6754797 },
    { lat: 48.8293757, lng: 20.6754055 },
    { lat: 48.8298961, lng: 20.6751022 },
    { lat: 48.8304429, lng: 20.6749351 },
    { lat: 48.8311648, lng: 20.6742539 },
    { lat: 48.831595, lng: 20.6741638 },
    { lat: 48.8318254, lng: 20.6740248 },
    { lat: 48.832824, lng: 20.6736829 },
    { lat: 48.8332873, lng: 20.6733517 },
    { lat: 48.833768, lng: 20.6732246 },
    { lat: 48.8337696, lng: 20.6733031 },
    { lat: 48.8340769, lng: 20.6728011 },
    { lat: 48.8345358, lng: 20.6725049 },
    { lat: 48.8352046, lng: 20.671658 },
    { lat: 48.8352307, lng: 20.6716951 },
    { lat: 48.8356726, lng: 20.6712771 },
    { lat: 48.8363494, lng: 20.6711245 },
    { lat: 48.8369713, lng: 20.6708231 },
    { lat: 48.8373119, lng: 20.6708086 },
    { lat: 48.8373904, lng: 20.6708661 },
    { lat: 48.8375334, lng: 20.671176 },
    { lat: 48.8385379, lng: 20.6709053 },
    { lat: 48.839102, lng: 20.6705929 },
    { lat: 48.8392853, lng: 20.6705839 },
    { lat: 48.8396624, lng: 20.6706472 },
    { lat: 48.8400187, lng: 20.6708548 },
    { lat: 48.8413688, lng: 20.6710756 },
    { lat: 48.8415211, lng: 20.6709952 },
    { lat: 48.8416548, lng: 20.6710453 },
    { lat: 48.8417913, lng: 20.6713067 },
    { lat: 48.8419414, lng: 20.6714713 },
    { lat: 48.8426834, lng: 20.6721371 },
    { lat: 48.8428922, lng: 20.6721528 },
    { lat: 48.8429813, lng: 20.6716501 },
    { lat: 48.8439201, lng: 20.6709891 },
    { lat: 48.8444601, lng: 20.6699457 },
    { lat: 48.8449913, lng: 20.6693121 },
    { lat: 48.8452378, lng: 20.6691546 },
    { lat: 48.8454664, lng: 20.6690936 },
    { lat: 48.846191, lng: 20.668612 },
    { lat: 48.846623, lng: 20.668129 },
    { lat: 48.846948, lng: 20.667588 },
    { lat: 48.847041, lng: 20.667431 },
    { lat: 48.847575, lng: 20.666684 },
    { lat: 48.848026, lng: 20.666047 },
    { lat: 48.848321, lng: 20.665729 },
    { lat: 48.848837, lng: 20.665147 },
    { lat: 48.84915, lng: 20.664363 },
    { lat: 48.849663, lng: 20.663496 },
    { lat: 48.849826, lng: 20.663198 },
    { lat: 48.849873, lng: 20.663018 },
    { lat: 48.850091, lng: 20.662399 },
    { lat: 48.850197, lng: 20.662196 },
    { lat: 48.850641, lng: 20.661518 },
    { lat: 48.850854, lng: 20.661156 },
    { lat: 48.850959, lng: 20.660895 },
    { lat: 48.851097, lng: 20.660575 },
    { lat: 48.851405, lng: 20.659858 },
    { lat: 48.85168, lng: 20.659219 },
    { lat: 48.8516655, lng: 20.6580203 },
    { lat: 48.8517414, lng: 20.6568773 },
    { lat: 48.8520424, lng: 20.656171 },
    { lat: 48.8523891, lng: 20.6556033 },
    { lat: 48.8530336, lng: 20.6542745 },
    { lat: 48.8536141, lng: 20.6532316 },
    { lat: 48.8539725, lng: 20.6529313 },
    { lat: 48.8541511, lng: 20.6516996 },
    { lat: 48.8542157, lng: 20.6507187 },
    { lat: 48.8540034, lng: 20.6506011 },
    { lat: 48.8536258, lng: 20.6505905 },
    { lat: 48.852902, lng: 20.6494386 },
    { lat: 48.8530081, lng: 20.6480435 },
    { lat: 48.8529957, lng: 20.6470407 },
    { lat: 48.8528745, lng: 20.6465584 },
    { lat: 48.8527319, lng: 20.6462878 },
    { lat: 48.8526813, lng: 20.6460668 },
    { lat: 48.8526414, lng: 20.6452447 },
    { lat: 48.8526743, lng: 20.6449388 },
    { lat: 48.8529016, lng: 20.6444656 },
    { lat: 48.8530865, lng: 20.6437918 },
    { lat: 48.8533059, lng: 20.6433146 },
    { lat: 48.853545, lng: 20.6424557 },
    { lat: 48.8541515, lng: 20.6416965 },
    { lat: 48.8544743, lng: 20.6410073 },
    { lat: 48.8541189, lng: 20.639704 },
    { lat: 48.8540864, lng: 20.638838 },
    { lat: 48.854264, lng: 20.6370568 },
    { lat: 48.8543583, lng: 20.6367426 },
    { lat: 48.85506, lng: 20.6365281 },
    { lat: 48.8550975, lng: 20.6337393 },
    { lat: 48.8551463, lng: 20.6327871 },
    { lat: 48.8552644, lng: 20.6320419 },
    { lat: 48.8559191, lng: 20.6302575 },
    { lat: 48.8576131, lng: 20.6241308 },
    { lat: 48.8582721, lng: 20.6230545 },
    { lat: 48.8587848, lng: 20.6223514 },
    { lat: 48.8588919, lng: 20.6219559 },
    { lat: 48.8590205, lng: 20.621775 },
    { lat: 48.8587642, lng: 20.6214149 },
    { lat: 48.858632, lng: 20.6209064 },
    { lat: 48.8586309, lng: 20.6207181 },
    { lat: 48.8583531, lng: 20.6202947 },
    { lat: 48.8582516, lng: 20.6197905 },
    { lat: 48.8580699, lng: 20.6195629 },
    { lat: 48.8580838, lng: 20.6193879 },
    { lat: 48.8580154, lng: 20.6191462 },
    { lat: 48.8580825, lng: 20.618949 },
    { lat: 48.8581249, lng: 20.618407 },
    { lat: 48.8579227, lng: 20.6180181 },
    { lat: 48.8580028, lng: 20.6178065 },
    { lat: 48.8578531, lng: 20.617498 },
    { lat: 48.8578928, lng: 20.6173516 },
    { lat: 48.8578769, lng: 20.6171005 },
    { lat: 48.8576806, lng: 20.6168087 },
    { lat: 48.857701, lng: 20.6165993 },
    { lat: 48.8575707, lng: 20.6164546 },
    { lat: 48.8575229, lng: 20.6161261 },
    { lat: 48.8574565, lng: 20.6160648 },
    { lat: 48.8574599, lng: 20.6158772 },
    { lat: 48.8574068, lng: 20.615754 },
    { lat: 48.8572433, lng: 20.6157154 },
    { lat: 48.8571592, lng: 20.615568 },
    { lat: 48.8571953, lng: 20.6152915 },
    { lat: 48.857111, lng: 20.6152045 },
    { lat: 48.8570574, lng: 20.615274 },
    { lat: 48.8570032, lng: 20.6152435 },
    { lat: 48.8568769, lng: 20.6150538 },
    { lat: 48.8568628, lng: 20.6151912 },
    { lat: 48.8567156, lng: 20.6151335 },
    { lat: 48.8565117, lng: 20.6151626 },
    { lat: 48.8564217, lng: 20.61501 },
    { lat: 48.8562958, lng: 20.6151113 },
    { lat: 48.8562811, lng: 20.6149524 },
    { lat: 48.8561771, lng: 20.6148767 },
    { lat: 48.8560617, lng: 20.6146475 },
    { lat: 48.8559083, lng: 20.6146993 },
    { lat: 48.8558697, lng: 20.6146296 },
    { lat: 48.8557017, lng: 20.6146557 },
    { lat: 48.8555112, lng: 20.6144629 },
    { lat: 48.8557341, lng: 20.6143638 },
    { lat: 48.856244, lng: 20.6143934 },
    { lat: 48.8566056, lng: 20.614463 },
    { lat: 48.8567651, lng: 20.6145466 },
    { lat: 48.856825, lng: 20.6146774 },
    { lat: 48.8568854, lng: 20.614696 },
    { lat: 48.8569853, lng: 20.6146283 },
    { lat: 48.8570963, lng: 20.6147095 },
    { lat: 48.8571979, lng: 20.6146799 },
    { lat: 48.8572354, lng: 20.6148498 },
    { lat: 48.8574136, lng: 20.6147616 },
    { lat: 48.8575696, lng: 20.614504 },
    { lat: 48.8575209, lng: 20.6144037 },
    { lat: 48.8576329, lng: 20.6143543 },
    { lat: 48.8576647, lng: 20.6140828 },
    { lat: 48.8578519, lng: 20.6137815 },
    { lat: 48.8581845, lng: 20.613423 },
    { lat: 48.8582078, lng: 20.6132587 },
    { lat: 48.8583081, lng: 20.6131113 },
    { lat: 48.8583467, lng: 20.6129325 },
    { lat: 48.858475, lng: 20.6128342 },
    { lat: 48.8586939, lng: 20.6119528 },
    { lat: 48.8586929, lng: 20.6116143 },
    { lat: 48.8587599, lng: 20.6114449 },
    { lat: 48.8586998, lng: 20.6111532 },
    { lat: 48.8587674, lng: 20.6110338 },
    { lat: 48.8587402, lng: 20.6109346 },
    { lat: 48.8587787, lng: 20.6107236 },
    { lat: 48.8587418, lng: 20.6106112 },
    { lat: 48.8588271, lng: 20.6104124 },
    { lat: 48.8587148, lng: 20.6101483 },
    { lat: 48.858782, lng: 20.6099764 },
    { lat: 48.8587382, lng: 20.609856 },
    { lat: 48.8587508, lng: 20.6096787 },
    { lat: 48.8585669, lng: 20.6090523 },
    { lat: 48.8584338, lng: 20.6089801 },
    { lat: 48.8584283, lng: 20.6088944 },
    { lat: 48.8580928, lng: 20.6083679 },
    { lat: 48.8579679, lng: 20.607629 },
    { lat: 48.8578435, lng: 20.6071785 },
    { lat: 48.8576422, lng: 20.606905 },
    { lat: 48.8576327, lng: 20.606741 },
    { lat: 48.8575758, lng: 20.6067207 },
    { lat: 48.8574088, lng: 20.6063132 },
    { lat: 48.8572432, lng: 20.6060614 },
    { lat: 48.8570872, lng: 20.6054913 },
    { lat: 48.8569654, lng: 20.6053596 },
    { lat: 48.8568983, lng: 20.6051882 },
    { lat: 48.8568575, lng: 20.6048402 },
    { lat: 48.8568991, lng: 20.604679 },
    { lat: 48.8568415, lng: 20.6045555 },
    { lat: 48.8568821, lng: 20.6044648 },
    { lat: 48.8568493, lng: 20.6043682 },
    { lat: 48.8569427, lng: 20.6041494 },
    { lat: 48.8569183, lng: 20.6040356 },
    { lat: 48.8569735, lng: 20.6039456 },
    { lat: 48.8569818, lng: 20.6038029 },
    { lat: 48.8571072, lng: 20.6035994 },
    { lat: 48.8572455, lng: 20.6025152 },
    { lat: 48.8573095, lng: 20.6024136 },
    { lat: 48.8573007, lng: 20.6022699 },
    { lat: 48.8574212, lng: 20.6020807 },
    { lat: 48.8574518, lng: 20.601716 },
    { lat: 48.8575229, lng: 20.6015945 },
    { lat: 48.8574885, lng: 20.6009935 },
    { lat: 48.8574503, lng: 20.6009638 },
    { lat: 48.857422, lng: 20.5992368 },
    { lat: 48.8570954, lng: 20.5974697 },
    { lat: 48.8566747, lng: 20.5970806 },
    { lat: 48.8563165, lng: 20.5975697 },
    { lat: 48.8560761, lng: 20.5970933 },
    { lat: 48.8564747, lng: 20.5961993 },
    { lat: 48.8556635, lng: 20.5955959 },
    { lat: 48.8551659, lng: 20.595633 },
    { lat: 48.8549128, lng: 20.5954529 },
    { lat: 48.8545067, lng: 20.5938242 },
    { lat: 48.8539273, lng: 20.5930041 },
    { lat: 48.8538422, lng: 20.5927109 },
    { lat: 48.8537972, lng: 20.5924673 },
    { lat: 48.8538281, lng: 20.5920804 },
    { lat: 48.8539356, lng: 20.5916648 },
    { lat: 48.8541611, lng: 20.5913013 },
    { lat: 48.853869, lng: 20.585208 },
    { lat: 48.853849, lng: 20.5849 },
    { lat: 48.854045, lng: 20.584717 },
    { lat: 48.854035, lng: 20.584499 },
    { lat: 48.854033, lng: 20.584484 },
    { lat: 48.854027, lng: 20.584388 },
    { lat: 48.854029, lng: 20.584366 },
    { lat: 48.854022, lng: 20.584231 },
    { lat: 48.85402, lng: 20.584196 },
    { lat: 48.854015, lng: 20.584077 },
    { lat: 48.854009, lng: 20.584019 },
    { lat: 48.853505, lng: 20.583004 },
    { lat: 48.853581, lng: 20.582921 },
    { lat: 48.853561, lng: 20.582763 },
    { lat: 48.853545, lng: 20.582637 },
    { lat: 48.85353, lng: 20.582505 },
    { lat: 48.853506, lng: 20.582353 },
    { lat: 48.853482, lng: 20.582193 },
    { lat: 48.853477, lng: 20.582155 },
    { lat: 48.853474, lng: 20.582001 },
    { lat: 48.853473, lng: 20.581825 },
    { lat: 48.853445, lng: 20.581548 },
    { lat: 48.853371, lng: 20.581312 },
    { lat: 48.853287, lng: 20.581135 },
    { lat: 48.852903, lng: 20.580131 },
    { lat: 48.852577, lng: 20.57916 },
    { lat: 48.852139, lng: 20.578409 },
    { lat: 48.851505, lng: 20.57702 },
    { lat: 48.851059, lng: 20.575991 },
    { lat: 48.850091, lng: 20.574767 },
    { lat: 48.849551, lng: 20.573602 },
    { lat: 48.849283, lng: 20.572854 },
    { lat: 48.848932, lng: 20.571875 },
    { lat: 48.84879, lng: 20.571331 },
    { lat: 48.848671, lng: 20.570862 },
    { lat: 48.84859, lng: 20.570527 },
    { lat: 48.848324, lng: 20.570487 },
    { lat: 48.848198, lng: 20.570544 },
    { lat: 48.847959, lng: 20.570448 },
    { lat: 48.847826, lng: 20.570484 },
    { lat: 48.847681, lng: 20.570864 },
    { lat: 48.847564, lng: 20.570911 },
    { lat: 48.847441, lng: 20.570895 },
    { lat: 48.846751, lng: 20.570801 },
    { lat: 48.846556, lng: 20.570747 },
    { lat: 48.845818, lng: 20.570465 },
    { lat: 48.845662, lng: 20.569876 },
    { lat: 48.845652, lng: 20.569715 },
    { lat: 48.84553, lng: 20.569501 },
    { lat: 48.845387, lng: 20.569301 },
    { lat: 48.845281, lng: 20.569159 },
    { lat: 48.844774, lng: 20.569128 },
    { lat: 48.844407, lng: 20.568832 },
    { lat: 48.844253, lng: 20.568815 },
    { lat: 48.844058, lng: 20.568876 },
    { lat: 48.843995, lng: 20.568846 },
    { lat: 48.843994, lng: 20.568497 },
    { lat: 48.843939, lng: 20.568387 },
    { lat: 48.843754, lng: 20.568346 },
    { lat: 48.843649, lng: 20.568822 },
    { lat: 48.843621, lng: 20.568946 },
    { lat: 48.843071, lng: 20.568783 },
    { lat: 48.842358, lng: 20.568988 },
    { lat: 48.841916, lng: 20.568752 },
    { lat: 48.841652, lng: 20.568688 },
    { lat: 48.841165, lng: 20.56857 },
    { lat: 48.840874, lng: 20.568323 },
    { lat: 48.840499, lng: 20.568006 },
    { lat: 48.839612, lng: 20.567094 },
    { lat: 48.838847, lng: 20.566291 },
    { lat: 48.837781, lng: 20.565411 },
    { lat: 48.83734, lng: 20.564562 },
    { lat: 48.83722, lng: 20.564333 },
    { lat: 48.836997, lng: 20.563903 },
    { lat: 48.836891, lng: 20.563622 },
    { lat: 48.836523, lng: 20.562631 },
    { lat: 48.836391, lng: 20.562236 },
    { lat: 48.83625, lng: 20.561957 },
    { lat: 48.835927, lng: 20.561604 },
    { lat: 48.835771, lng: 20.561345 },
    { lat: 48.83568, lng: 20.561181 },
    { lat: 48.835544, lng: 20.560822 },
    { lat: 48.835557, lng: 20.560132 },
    { lat: 48.835709, lng: 20.559373 },
    { lat: 48.835714, lng: 20.558829 },
    { lat: 48.835436, lng: 20.558493 },
    { lat: 48.834831, lng: 20.557759 },
    { lat: 48.834029, lng: 20.55706 },
    { lat: 48.833914, lng: 20.55694 },
    { lat: 48.833682, lng: 20.556701 },
    { lat: 48.833036, lng: 20.556291 },
    { lat: 48.832882, lng: 20.556194 },
    { lat: 48.832553, lng: 20.555297 },
    { lat: 48.832504, lng: 20.555169 },
    { lat: 48.832017, lng: 20.554545 },
    { lat: 48.832002, lng: 20.554519 },
    { lat: 48.831677, lng: 20.554066 },
    { lat: 48.831393, lng: 20.55367 },
    { lat: 48.831343, lng: 20.553641 },
    { lat: 48.83133, lng: 20.553496 },
    { lat: 48.831381, lng: 20.553327 },
    { lat: 48.831364, lng: 20.553175 },
    { lat: 48.83167, lng: 20.552804 },
    { lat: 48.831755, lng: 20.552565 },
    { lat: 48.831727, lng: 20.55241 },
    { lat: 48.83167, lng: 20.552373 },
    { lat: 48.831618, lng: 20.552223 },
    { lat: 48.831351, lng: 20.552028 },
    { lat: 48.830962, lng: 20.551649 },
    { lat: 48.830939, lng: 20.551628 },
    { lat: 48.830546, lng: 20.551251 },
    { lat: 48.83032, lng: 20.551234 },
    { lat: 48.8302, lng: 20.551183 },
    { lat: 48.829705, lng: 20.551267 },
    { lat: 48.829558, lng: 20.551371 },
    { lat: 48.829422, lng: 20.551455 },
    { lat: 48.82921, lng: 20.551159 },
    { lat: 48.82913, lng: 20.551136 },
    { lat: 48.829001, lng: 20.55114 },
    { lat: 48.828956, lng: 20.551063 },
    { lat: 48.828841, lng: 20.551022 },
    { lat: 48.828672, lng: 20.551052 },
    { lat: 48.828576, lng: 20.551017 },
    { lat: 48.828226, lng: 20.551059 },
    { lat: 48.828229, lng: 20.551077 },
    { lat: 48.828251, lng: 20.551161 },
    { lat: 48.828442, lng: 20.551796 },
    { lat: 48.828435, lng: 20.551874 },
    { lat: 48.828367, lng: 20.552673 },
    { lat: 48.828083, lng: 20.553049 },
    { lat: 48.82761, lng: 20.553713 },
    { lat: 48.827509, lng: 20.554871 },
    { lat: 48.827067, lng: 20.555003 },
    { lat: 48.826656, lng: 20.554519 },
    { lat: 48.82619, lng: 20.555503 },
    { lat: 48.825922, lng: 20.555725 },
    { lat: 48.82572, lng: 20.556338 },
    { lat: 48.825562, lng: 20.556163 },
    { lat: 48.825461, lng: 20.556119 },
    { lat: 48.825312, lng: 20.555939 },
    { lat: 48.824852, lng: 20.55562 },
    { lat: 48.824803, lng: 20.55551 },
    { lat: 48.824727, lng: 20.555528 },
    { lat: 48.824636, lng: 20.555602 },
    { lat: 48.824281, lng: 20.555504 },
    { lat: 48.823869, lng: 20.555249 },
    { lat: 48.823739, lng: 20.555289 },
    { lat: 48.823485, lng: 20.555204 },
    { lat: 48.823413, lng: 20.55522 },
    { lat: 48.823055, lng: 20.555329 },
    { lat: 48.8229, lng: 20.555326 },
    { lat: 48.82257, lng: 20.555002 },
    { lat: 48.822472, lng: 20.554846 },
    { lat: 48.822332, lng: 20.554701 },
    { lat: 48.822189, lng: 20.554657 },
    { lat: 48.821976, lng: 20.554576 },
    { lat: 48.82182, lng: 20.554418 },
    { lat: 48.82163, lng: 20.55459 },
    { lat: 48.821638, lng: 20.554366 },
    { lat: 48.821525, lng: 20.554267 },
    { lat: 48.821469, lng: 20.554145 },
    { lat: 48.821335, lng: 20.554046 },
    { lat: 48.821255, lng: 20.554038 },
    { lat: 48.821137, lng: 20.554098 },
    { lat: 48.82104, lng: 20.554192 },
    { lat: 48.820802, lng: 20.554282 },
    { lat: 48.820711, lng: 20.554355 },
    { lat: 48.820608, lng: 20.554428 },
    { lat: 48.820516, lng: 20.554477 },
    { lat: 48.820365, lng: 20.554627 },
    { lat: 48.820096, lng: 20.55491 },
    { lat: 48.820022, lng: 20.554752 },
    { lat: 48.819868, lng: 20.554695 },
    { lat: 48.819674, lng: 20.554569 },
    { lat: 48.819531, lng: 20.55447 },
    { lat: 48.819164, lng: 20.554168 },
    { lat: 48.818866, lng: 20.553594 },
    { lat: 48.81874, lng: 20.553304 },
    { lat: 48.818604, lng: 20.553497 },
    { lat: 48.818493, lng: 20.553708 },
    { lat: 48.81831, lng: 20.554024 },
    { lat: 48.818165, lng: 20.55423 },
    { lat: 48.817901, lng: 20.553873 },
    { lat: 48.817893, lng: 20.553853 },
    { lat: 48.817774, lng: 20.553968 },
    { lat: 48.817613, lng: 20.554101 },
    { lat: 48.817307, lng: 20.554345 },
    { lat: 48.817314, lng: 20.55436 },
    { lat: 48.817114, lng: 20.554817 },
    { lat: 48.817018, lng: 20.555028 },
    { lat: 48.816933, lng: 20.55496 },
    { lat: 48.816745, lng: 20.554805 },
    { lat: 48.816405, lng: 20.554548 },
    { lat: 48.816406, lng: 20.554523 },
    { lat: 48.816334, lng: 20.554521 },
    { lat: 48.816203, lng: 20.554495 },
    { lat: 48.816014, lng: 20.554316 },
    { lat: 48.815918, lng: 20.554322 },
    { lat: 48.815912, lng: 20.554241 },
    { lat: 48.81591, lng: 20.554216 },
    { lat: 48.815758, lng: 20.554247 },
    { lat: 48.815521, lng: 20.554249 },
    { lat: 48.815382, lng: 20.55412 },
    { lat: 48.815339, lng: 20.554101 },
    { lat: 48.815295, lng: 20.554093 },
    { lat: 48.815245, lng: 20.554084 },
    { lat: 48.815212, lng: 20.553964 },
    { lat: 48.815602, lng: 20.553737 },
    { lat: 48.815941, lng: 20.553546 },
    { lat: 48.816129, lng: 20.553457 },
    { lat: 48.816355, lng: 20.55337 },
    { lat: 48.816547, lng: 20.553283 },
    { lat: 48.816724, lng: 20.553146 },
    { lat: 48.816858, lng: 20.553023 },
    { lat: 48.817057, lng: 20.552774 },
    { lat: 48.817458, lng: 20.552473 },
    { lat: 48.817727, lng: 20.552306 },
    { lat: 48.818064, lng: 20.552105 },
    { lat: 48.818248, lng: 20.552026 },
    { lat: 48.818383, lng: 20.552016 },
    { lat: 48.818423, lng: 20.55201 },
    { lat: 48.818434, lng: 20.552008 },
    { lat: 48.818718, lng: 20.55177 },
    { lat: 48.818965, lng: 20.551521 },
    { lat: 48.81904, lng: 20.551372 },
    { lat: 48.819053, lng: 20.551113 },
    { lat: 48.819022, lng: 20.550797 },
    { lat: 48.818978, lng: 20.550571 },
    { lat: 48.818844, lng: 20.550248 },
    { lat: 48.818773, lng: 20.550029 },
    { lat: 48.818644, lng: 20.549461 },
    { lat: 48.818581, lng: 20.549223 },
    { lat: 48.818554, lng: 20.549025 },
    { lat: 48.818543, lng: 20.548798 },
    { lat: 48.818652, lng: 20.548495 },
    { lat: 48.818701, lng: 20.548328 },
    { lat: 48.818718, lng: 20.548192 },
    { lat: 48.818699, lng: 20.547929 },
    { lat: 48.818661, lng: 20.547553 },
    { lat: 48.818616, lng: 20.54733 },
    { lat: 48.818541, lng: 20.547015 },
    { lat: 48.818408, lng: 20.546641 },
    { lat: 48.818298, lng: 20.546533 },
    { lat: 48.818288, lng: 20.546519 },
    { lat: 48.818262, lng: 20.546489 },
    { lat: 48.818255, lng: 20.546476 },
    { lat: 48.818093, lng: 20.546339 },
    { lat: 48.818, lng: 20.546281 },
    { lat: 48.817818, lng: 20.546191 },
    { lat: 48.817748, lng: 20.546134 },
    { lat: 48.817671, lng: 20.546041 },
    { lat: 48.817764, lng: 20.545413 },
    { lat: 48.817707, lng: 20.544734 },
    { lat: 48.817642, lng: 20.544533 },
    { lat: 48.817544, lng: 20.544316 },
    { lat: 48.817301, lng: 20.543988 },
    { lat: 48.817183, lng: 20.543876 },
    { lat: 48.81706, lng: 20.543739 },
    { lat: 48.816941, lng: 20.543577 },
    { lat: 48.81686, lng: 20.54347 },
    { lat: 48.816831, lng: 20.543314 },
    { lat: 48.816794, lng: 20.54306 },
    { lat: 48.816783, lng: 20.542965 },
    { lat: 48.816776, lng: 20.542862 },
    { lat: 48.81679, lng: 20.542405 },
    { lat: 48.816821, lng: 20.542055 },
    { lat: 48.816847, lng: 20.54169 },
    { lat: 48.816881, lng: 20.541386 },
    { lat: 48.816895, lng: 20.541237 },
    { lat: 48.816902, lng: 20.541033 },
    { lat: 48.816902, lng: 20.540836 },
    { lat: 48.816885, lng: 20.540718 },
    { lat: 48.816814, lng: 20.540519 },
    { lat: 48.816743, lng: 20.54034 },
    { lat: 48.816621, lng: 20.540083 },
    { lat: 48.81651, lng: 20.539917 },
    { lat: 48.816426, lng: 20.53978 },
    { lat: 48.816369, lng: 20.539652 },
    { lat: 48.81629, lng: 20.539492 },
    { lat: 48.816244, lng: 20.539385 },
    { lat: 48.816209, lng: 20.539295 },
    { lat: 48.816166, lng: 20.539173 },
    { lat: 48.815991, lng: 20.538469 },
    { lat: 48.815917, lng: 20.538175 },
    { lat: 48.815842, lng: 20.537766 },
    { lat: 48.81578, lng: 20.53736 },
    { lat: 48.815592, lng: 20.536727 },
    { lat: 48.815483, lng: 20.536421 },
    { lat: 48.815389, lng: 20.536231 },
    { lat: 48.815123, lng: 20.53568 },
    { lat: 48.814966, lng: 20.535235 },
    { lat: 48.814838, lng: 20.534903 },
    { lat: 48.814795, lng: 20.534776 },
    { lat: 48.814734, lng: 20.534432 },
    { lat: 48.814647, lng: 20.533977 },
    { lat: 48.814544, lng: 20.533526 },
    { lat: 48.814487, lng: 20.53315 },
    { lat: 48.814462, lng: 20.532871 },
    { lat: 48.814415, lng: 20.531985 },
    { lat: 48.814394, lng: 20.531798 },
    { lat: 48.814364, lng: 20.531631 },
    { lat: 48.814339, lng: 20.531507 },
    { lat: 48.814292, lng: 20.531379 },
    { lat: 48.81421, lng: 20.531277 },
    { lat: 48.814128, lng: 20.531124 },
    { lat: 48.813873, lng: 20.530626 },
    { lat: 48.813627, lng: 20.530205 },
    { lat: 48.813471, lng: 20.529966 },
    { lat: 48.813391, lng: 20.530037 },
    { lat: 48.813321, lng: 20.5301 },
    { lat: 48.813203, lng: 20.530128 },
    { lat: 48.813043, lng: 20.530256 },
    { lat: 48.813003, lng: 20.530299 },
    { lat: 48.812855, lng: 20.530458 },
    { lat: 48.81259, lng: 20.530432 },
    { lat: 48.81257, lng: 20.530424 },
    { lat: 48.812494, lng: 20.530397 },
    { lat: 48.812475, lng: 20.53039 },
    { lat: 48.812413, lng: 20.530369 },
    { lat: 48.812245, lng: 20.530442 },
    { lat: 48.81186, lng: 20.530596 },
    { lat: 48.811713, lng: 20.530653 },
    { lat: 48.811531, lng: 20.53073 },
    { lat: 48.811257, lng: 20.530971 },
    { lat: 48.810895, lng: 20.531122 },
    { lat: 48.810362, lng: 20.531344 },
    { lat: 48.809921, lng: 20.53135 },
    { lat: 48.809599, lng: 20.531359 },
    { lat: 48.809035, lng: 20.531428 },
    { lat: 48.808771, lng: 20.531448 },
    { lat: 48.808522, lng: 20.531163 },
    { lat: 48.808259, lng: 20.530872 },
    { lat: 48.808027, lng: 20.530616 },
    { lat: 48.807939, lng: 20.530568 },
    { lat: 48.807621, lng: 20.530289 },
    { lat: 48.807248, lng: 20.5302 },
    { lat: 48.806913, lng: 20.530102 },
    { lat: 48.806569, lng: 20.529997 },
    { lat: 48.806237, lng: 20.52994 },
    { lat: 48.805836, lng: 20.529874 },
    { lat: 48.805627, lng: 20.529839 },
    { lat: 48.805451, lng: 20.529831 },
    { lat: 48.805267, lng: 20.529796 },
    { lat: 48.80491, lng: 20.52975 },
    { lat: 48.804663, lng: 20.529903 },
    { lat: 48.80447, lng: 20.53 },
    { lat: 48.804374, lng: 20.530021 },
    { lat: 48.80427, lng: 20.530025 },
    { lat: 48.804004, lng: 20.530053 },
    { lat: 48.803868, lng: 20.53004 },
    { lat: 48.803686, lng: 20.529987 },
    { lat: 48.803255, lng: 20.53033 },
    { lat: 48.803112, lng: 20.530512 },
    { lat: 48.802794, lng: 20.53057 },
    { lat: 48.802504, lng: 20.530519 },
    { lat: 48.802293, lng: 20.530469 },
    { lat: 48.802172, lng: 20.530359 },
    { lat: 48.802081, lng: 20.530291 },
    { lat: 48.801934, lng: 20.530158 },
    { lat: 48.801772, lng: 20.529735 },
    { lat: 48.801582, lng: 20.529634 },
    { lat: 48.801398, lng: 20.529626 },
    { lat: 48.80121, lng: 20.529621 },
    { lat: 48.801109, lng: 20.529609 },
    { lat: 48.801011, lng: 20.529621 },
    { lat: 48.800633, lng: 20.529721 },
    { lat: 48.800267, lng: 20.529754 },
    { lat: 48.799889, lng: 20.529888 },
    { lat: 48.799543, lng: 20.530019 },
    { lat: 48.799202, lng: 20.530097 },
    { lat: 48.798843, lng: 20.530175 },
    { lat: 48.798552, lng: 20.530246 },
    { lat: 48.798071, lng: 20.530534 },
    { lat: 48.797505, lng: 20.530766 },
    { lat: 48.794174, lng: 20.5336 },
    { lat: 48.78991, lng: 20.533409 },
    { lat: 48.789258, lng: 20.534679 },
    { lat: 48.787206, lng: 20.534112 },
    { lat: 48.785466, lng: 20.53427 },
    { lat: 48.78476, lng: 20.534765 },
    { lat: 48.784522, lng: 20.535441 },
    { lat: 48.78445, lng: 20.536391 },
    { lat: 48.78441, lng: 20.536909 },
    { lat: 48.784086, lng: 20.537938 },
    { lat: 48.783787, lng: 20.53916 },
    { lat: 48.78323, lng: 20.541608 },
    { lat: 48.782549, lng: 20.543388 },
    { lat: 48.782438, lng: 20.543676 },
    { lat: 48.782297, lng: 20.544084 },
    { lat: 48.781617, lng: 20.545961 },
    { lat: 48.780876, lng: 20.549042 },
    { lat: 48.780888, lng: 20.549318 },
    { lat: 48.7817911, lng: 20.5511687 },
    { lat: 48.7823251, lng: 20.5515983 },
    { lat: 48.7830527, lng: 20.552491 },
    { lat: 48.7835532, lng: 20.5535148 },
    { lat: 48.7837328, lng: 20.5560598 },
    { lat: 48.7842506, lng: 20.5589536 },
    { lat: 48.78466, lng: 20.5608041 },
    { lat: 48.7848682, lng: 20.5626049 },
    { lat: 48.7855868, lng: 20.5647222 },
    { lat: 48.7867148, lng: 20.5665139 },
    { lat: 48.7875832, lng: 20.5677178 },
    { lat: 48.7890964, lng: 20.5692813 },
    { lat: 48.7904086, lng: 20.5712227 },
    { lat: 48.7909824, lng: 20.5728329 },
    { lat: 48.7918324, lng: 20.5729524 },
    { lat: 48.7927549, lng: 20.5731793 },
    { lat: 48.7940974, lng: 20.5738357 },
    { lat: 48.7939149, lng: 20.5744478 },
    { lat: 48.7940253, lng: 20.5746024 },
    { lat: 48.7940188, lng: 20.5750657 },
    { lat: 48.7940891, lng: 20.5754783 },
    { lat: 48.7942141, lng: 20.5755081 },
    { lat: 48.7941888, lng: 20.5763862 },
    { lat: 48.7941064, lng: 20.5769634 },
    { lat: 48.7941017, lng: 20.5777848 },
    { lat: 48.7940328, lng: 20.5780227 },
    { lat: 48.794188, lng: 20.5784781 },
    { lat: 48.7944216, lng: 20.5788316 },
    { lat: 48.7947783, lng: 20.579588 },
    { lat: 48.7947299, lng: 20.5797745 },
    { lat: 48.7949004, lng: 20.5803387 },
    { lat: 48.7947449, lng: 20.580491 },
    { lat: 48.7949933, lng: 20.5808433 },
    { lat: 48.7947664, lng: 20.581012 },
    { lat: 48.7948643, lng: 20.5814433 },
    { lat: 48.7944678, lng: 20.5814988 },
    { lat: 48.7942907, lng: 20.5813655 },
    { lat: 48.7934262, lng: 20.581215 },
    { lat: 48.7931682, lng: 20.5817073 },
    { lat: 48.7930362, lng: 20.581697 },
    { lat: 48.7929084, lng: 20.5820781 },
    { lat: 48.792614, lng: 20.581635 },
    { lat: 48.7922768, lng: 20.5818023 },
    { lat: 48.7921058, lng: 20.5818102 },
    { lat: 48.79204, lng: 20.5824088 },
    { lat: 48.7918789, lng: 20.5823445 },
    { lat: 48.7915277, lng: 20.5827126 },
    { lat: 48.7913335, lng: 20.5830088 },
    { lat: 48.7912085, lng: 20.5829715 },
    { lat: 48.7910641, lng: 20.5832079 },
    { lat: 48.7909886, lng: 20.5832081 },
    { lat: 48.7908663, lng: 20.5837462 },
    { lat: 48.7906516, lng: 20.5836307 },
    { lat: 48.7900393, lng: 20.5838221 },
    { lat: 48.7898776, lng: 20.584443 },
    { lat: 48.7899718, lng: 20.5845269 },
    { lat: 48.7899101, lng: 20.5848388 },
    { lat: 48.790065, lng: 20.5853774 },
    { lat: 48.7900056, lng: 20.5855727 },
    { lat: 48.7899764, lng: 20.5860301 },
    { lat: 48.7901023, lng: 20.5863051 },
    { lat: 48.7900011, lng: 20.5865564 },
    { lat: 48.7899544, lng: 20.5865057 },
    { lat: 48.789802, lng: 20.5866781 },
    { lat: 48.7895955, lng: 20.5866719 },
    { lat: 48.7898895, lng: 20.5871932 },
    { lat: 48.7900274, lng: 20.5872741 },
    { lat: 48.7901548, lng: 20.5872484 },
    { lat: 48.7903263, lng: 20.5869455 },
    { lat: 48.790433, lng: 20.586909 },
    { lat: 48.79062, lng: 20.587099 },
    { lat: 48.7906923, lng: 20.5872872 },
    { lat: 48.7903826, lng: 20.5875017 },
    { lat: 48.7903306, lng: 20.5880459 },
    { lat: 48.7888983, lng: 20.5920655 },
    { lat: 48.7889015, lng: 20.5926811 },
    { lat: 48.7893053, lng: 20.595994 },
    { lat: 48.7909068, lng: 20.5980407 },
    { lat: 48.7914638, lng: 20.598459 },
    { lat: 48.7915953, lng: 20.5987976 },
    { lat: 48.7914193, lng: 20.598939 },
    { lat: 48.7916609, lng: 20.599483 },
    { lat: 48.7916142, lng: 20.5995583 },
    { lat: 48.7914357, lng: 20.6021902 },
    { lat: 48.7910753, lng: 20.6035747 },
    { lat: 48.7907781, lng: 20.6040091 },
    { lat: 48.7909155, lng: 20.6041933 },
    { lat: 48.7910105, lng: 20.6044492 },
    { lat: 48.7913398, lng: 20.6047141 },
    { lat: 48.791643, lng: 20.6048194 },
    { lat: 48.7918524, lng: 20.6050608 },
    { lat: 48.7919168, lng: 20.6051004 },
    { lat: 48.7921448, lng: 20.6050249 },
    { lat: 48.7927539, lng: 20.6051776 },
    { lat: 48.7931725, lng: 20.6054525 },
    { lat: 48.7934099, lng: 20.6057847 },
    { lat: 48.7935669, lng: 20.6057687 },
    { lat: 48.7937209, lng: 20.6054827 },
    { lat: 48.793809, lng: 20.6054575 },
    { lat: 48.794635, lng: 20.6058216 },
    { lat: 48.7947764, lng: 20.6057776 },
    { lat: 48.7948966, lng: 20.6058329 },
    { lat: 48.7950387, lng: 20.6060878 },
    { lat: 48.7951536, lng: 20.6060229 },
    { lat: 48.7952494, lng: 20.6060881 },
    { lat: 48.7953418, lng: 20.6063696 },
    { lat: 48.7959043, lng: 20.6070375 },
    { lat: 48.7964919, lng: 20.6071269 },
    { lat: 48.7967181, lng: 20.6072805 },
    { lat: 48.7967942, lng: 20.6075116 },
    { lat: 48.7970766, lng: 20.6075691 },
    { lat: 48.7973969, lng: 20.6079233 },
    { lat: 48.7974835, lng: 20.6079558 },
    { lat: 48.7975668, lng: 20.6079481 },
    { lat: 48.7977226, lng: 20.6077454 },
    { lat: 48.7977953, lng: 20.6074855 },
    { lat: 48.7979673, lng: 20.6072682 },
    { lat: 48.7981874, lng: 20.6071881 },
    { lat: 48.7984192, lng: 20.6072769 },
    { lat: 48.7988625, lng: 20.6076698 },
    { lat: 48.7989875, lng: 20.6076376 },
    { lat: 48.7993171, lng: 20.607354 },
    { lat: 48.799316, lng: 20.607077 },
    { lat: 48.7993603, lng: 20.6069351 },
    { lat: 48.8002022, lng: 20.6072665 },
    { lat: 48.8005702, lng: 20.6077261 },
    { lat: 48.8010406, lng: 20.6080857 },
    { lat: 48.8011167, lng: 20.6080779 },
    { lat: 48.8011941, lng: 20.6079493 },
    { lat: 48.8016236, lng: 20.608023 },
    { lat: 48.8019245, lng: 20.6077529 },
    { lat: 48.8019929, lng: 20.6074379 },
    { lat: 48.8020639, lng: 20.607377 },
    { lat: 48.8024234, lng: 20.6072926 },
    { lat: 48.8027791, lng: 20.6075354 },
    { lat: 48.8027603, lng: 20.6096799 },
    { lat: 48.8028178, lng: 20.6111368 },
    { lat: 48.8026656, lng: 20.6132267 },
    { lat: 48.8031087, lng: 20.6144844 },
    { lat: 48.8032424, lng: 20.6150347 },
    { lat: 48.8033746, lng: 20.6152304 },
    { lat: 48.8036236, lng: 20.6158875 },
    { lat: 48.8040063, lng: 20.6164081 },
    { lat: 48.8049126, lng: 20.617864 },
    { lat: 48.8052067, lng: 20.6180549 },
    { lat: 48.8057179, lng: 20.6185541 },
    { lat: 48.8059817, lng: 20.6190282 },
    { lat: 48.8059215, lng: 20.6201565 },
    { lat: 48.8062103, lng: 20.6218443 },
    { lat: 48.8067584, lng: 20.6242635 },
    { lat: 48.8070592, lng: 20.6270901 },
    { lat: 48.8072037, lng: 20.6277806 },
    { lat: 48.8073109, lng: 20.629683 },
    { lat: 48.8075291, lng: 20.6310406 },
    { lat: 48.8080667, lng: 20.6330779 },
    { lat: 48.8085915, lng: 20.6338234 },
    { lat: 48.8087977, lng: 20.6351053 },
    { lat: 48.8091811, lng: 20.6387878 },
    { lat: 48.8093901, lng: 20.6396466 },
    { lat: 48.8093571, lng: 20.6404498 },
    { lat: 48.8094976, lng: 20.6409432 },
    { lat: 48.8094615, lng: 20.6410337 },
    { lat: 48.8095177, lng: 20.6411201 },
    { lat: 48.8095608, lng: 20.6422799 },
    { lat: 48.8094924, lng: 20.6424435 },
    { lat: 48.8096834, lng: 20.6429052 },
    { lat: 48.8096869, lng: 20.6435632 },
    { lat: 48.8097524, lng: 20.643971 },
    { lat: 48.8098169, lng: 20.6440707 },
    { lat: 48.8097595, lng: 20.6447602 },
    { lat: 48.8098491, lng: 20.6455236 },
    { lat: 48.8098146, lng: 20.6461868 },
    { lat: 48.8098747, lng: 20.6464544 },
    { lat: 48.8098124, lng: 20.6468121 },
    { lat: 48.8096617, lng: 20.6471593 },
    { lat: 48.8097125, lng: 20.6474765 },
    { lat: 48.8096236, lng: 20.6476664 },
    { lat: 48.8095717, lng: 20.6481833 },
    { lat: 48.8094737, lng: 20.64916 },
    { lat: 48.8094369, lng: 20.6491984 },
    { lat: 48.8094752, lng: 20.6493535 },
    { lat: 48.8093901, lng: 20.6496799 },
    { lat: 48.8094056, lng: 20.6516063 },
    { lat: 48.8096703, lng: 20.6522341 },
    { lat: 48.8097324, lng: 20.652774 },
    { lat: 48.8099437, lng: 20.6531362 },
    { lat: 48.8100986, lng: 20.6540896 },
    { lat: 48.8104125, lng: 20.6543944 },
    { lat: 48.8104659, lng: 20.6546254 },
    { lat: 48.8105933, lng: 20.6548224 },
    { lat: 48.8106381, lng: 20.655063 },
    { lat: 48.8108216, lng: 20.6552857 },
    { lat: 48.8108627, lng: 20.6554559 },
    { lat: 48.8110179, lng: 20.6556067 },
    { lat: 48.8110217, lng: 20.655745 },
  ],
};
export default UnitsGelnica;
