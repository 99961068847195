const UnitsKrupina = {
  Terany: [
    { lat: 48.1770334, lng: 18.9268663 },
    { lat: 48.1776652, lng: 18.9270087 },
    { lat: 48.1784289, lng: 18.927361 },
    { lat: 48.1787594, lng: 18.9276249 },
    { lat: 48.1787504, lng: 18.9277104 },
    { lat: 48.1824502, lng: 18.9222641 },
    { lat: 48.1828879, lng: 18.9217008 },
    { lat: 48.1832144, lng: 18.9214535 },
    { lat: 48.1842349, lng: 18.9203832 },
    { lat: 48.1846203, lng: 18.919727 },
    { lat: 48.1849479, lng: 18.918952 },
    { lat: 48.185538, lng: 18.9180685 },
    { lat: 48.1857528, lng: 18.9179055 },
    { lat: 48.1861616, lng: 18.9177228 },
    { lat: 48.1868458, lng: 18.9171913 },
    { lat: 48.1871251, lng: 18.9171013 },
    { lat: 48.1874774, lng: 18.9168676 },
    { lat: 48.1872168, lng: 18.916107 },
    { lat: 48.1867153, lng: 18.9152605 },
    { lat: 48.1865357, lng: 18.9150597 },
    { lat: 48.1883976, lng: 18.9118853 },
    { lat: 48.1888214, lng: 18.9113397 },
    { lat: 48.1916993, lng: 18.909606 },
    { lat: 48.1919807, lng: 18.9092913 },
    { lat: 48.1925512, lng: 18.908417 },
    { lat: 48.1931702, lng: 18.9070263 },
    { lat: 48.1935181, lng: 18.9065594 },
    { lat: 48.1943249, lng: 18.9057006 },
    { lat: 48.1946622, lng: 18.9052462 },
    { lat: 48.1955991, lng: 18.9038945 },
    { lat: 48.1964494, lng: 18.9024853 },
    { lat: 48.1968459, lng: 18.9023036 },
    { lat: 48.1973752, lng: 18.9016788 },
    { lat: 48.1980616, lng: 18.901264 },
    { lat: 48.1985091, lng: 18.9006949 },
    { lat: 48.1990846, lng: 18.8994635 },
    { lat: 48.1991471, lng: 18.899493 },
    { lat: 48.1993602, lng: 18.8992784 },
    { lat: 48.1996887, lng: 18.8991519 },
    { lat: 48.1998256, lng: 18.8990324 },
    { lat: 48.2001257, lng: 18.898385 },
    { lat: 48.2001799, lng: 18.8980826 },
    { lat: 48.2003846, lng: 18.8975526 },
    { lat: 48.2005417, lng: 18.8969045 },
    { lat: 48.2008485, lng: 18.8961284 },
    { lat: 48.201485, lng: 18.8963951 },
    { lat: 48.2024387, lng: 18.8947636 },
    { lat: 48.2027821, lng: 18.8940366 },
    { lat: 48.2034748, lng: 18.8918758 },
    { lat: 48.2040006, lng: 18.889973 },
    { lat: 48.206369, lng: 18.8848223 },
    { lat: 48.204952, lng: 18.8832082 },
    { lat: 48.2043825, lng: 18.882622 },
    { lat: 48.2043153, lng: 18.8826511 },
    { lat: 48.2043636, lng: 18.882603 },
    { lat: 48.2018683, lng: 18.8807422 },
    { lat: 48.1995275, lng: 18.8782793 },
    { lat: 48.1974295, lng: 18.8772522 },
    { lat: 48.1965101, lng: 18.8768582 },
    { lat: 48.1941475, lng: 18.8786283 },
    { lat: 48.1940977, lng: 18.8787289 },
    { lat: 48.1937883, lng: 18.8785921 },
    { lat: 48.1936197, lng: 18.8789927 },
    { lat: 48.1933877, lng: 18.8792562 },
    { lat: 48.1932117, lng: 18.8792806 },
    { lat: 48.1930735, lng: 18.8793759 },
    { lat: 48.192866, lng: 18.8793082 },
    { lat: 48.1922867, lng: 18.8797273 },
    { lat: 48.1915856, lng: 18.8797999 },
    { lat: 48.1915238, lng: 18.8797145 },
    { lat: 48.1911823, lng: 18.8799356 },
    { lat: 48.1908831, lng: 18.8799715 },
    { lat: 48.1907048, lng: 18.8798375 },
    { lat: 48.1903818, lng: 18.8798914 },
    { lat: 48.1901606, lng: 18.879761 },
    { lat: 48.1901499, lng: 18.8795993 },
    { lat: 48.1901395, lng: 18.8792694 },
    { lat: 48.1902347, lng: 18.8791922 },
    { lat: 48.1903026, lng: 18.8788486 },
    { lat: 48.1904923, lng: 18.8783985 },
    { lat: 48.1904635, lng: 18.878345 },
    { lat: 48.1905479, lng: 18.8780835 },
    { lat: 48.1905007, lng: 18.8772179 },
    { lat: 48.1902911, lng: 18.8767048 },
    { lat: 48.1900298, lng: 18.8764939 },
    { lat: 48.1894154, lng: 18.8764292 },
    { lat: 48.1892513, lng: 18.8765098 },
    { lat: 48.1888048, lng: 18.8765746 },
    { lat: 48.1885201, lng: 18.8768025 },
    { lat: 48.1882892, lng: 18.8765693 },
    { lat: 48.1881704, lng: 18.8765401 },
    { lat: 48.1879774, lng: 18.8767089 },
    { lat: 48.1879427, lng: 18.8766267 },
    { lat: 48.1877009, lng: 18.8765531 },
    { lat: 48.1874407, lng: 18.8754958 },
    { lat: 48.1869568, lng: 18.8745239 },
    { lat: 48.1869149, lng: 18.8745168 },
    { lat: 48.1868953, lng: 18.8742961 },
    { lat: 48.1867713, lng: 18.8739932 },
    { lat: 48.1859487, lng: 18.8732895 },
    { lat: 48.1856804, lng: 18.8729671 },
    { lat: 48.1854149, lng: 18.8722882 },
    { lat: 48.1851147, lng: 18.8720024 },
    { lat: 48.1850114, lng: 18.8717738 },
    { lat: 48.1834671, lng: 18.8709618 },
    { lat: 48.1831289, lng: 18.8709204 },
    { lat: 48.1825746, lng: 18.8703292 },
    { lat: 48.1820351, lng: 18.8695177 },
    { lat: 48.1817701, lng: 18.8694518 },
    { lat: 48.1816555, lng: 18.8690949 },
    { lat: 48.1810999, lng: 18.868605 },
    { lat: 48.1808637, lng: 18.8685514 },
    { lat: 48.1807027, lng: 18.8686005 },
    { lat: 48.1806198, lng: 18.8684388 },
    { lat: 48.1805825, lng: 18.8681538 },
    { lat: 48.1803182, lng: 18.8677579 },
    { lat: 48.1797973, lng: 18.8687967 },
    { lat: 48.1775009, lng: 18.8718968 },
    { lat: 48.1763954, lng: 18.8740046 },
    { lat: 48.1750283, lng: 18.8756196 },
    { lat: 48.1744822, lng: 18.8764799 },
    { lat: 48.1764257, lng: 18.878914 },
    { lat: 48.1743287, lng: 18.8821223 },
    { lat: 48.1736154, lng: 18.883126 },
    { lat: 48.172855, lng: 18.8846142 },
    { lat: 48.17258, lng: 18.8850503 },
    { lat: 48.172538, lng: 18.8851969 },
    { lat: 48.1724884, lng: 18.8852822 },
    { lat: 48.172343, lng: 18.8853489 },
    { lat: 48.1724721, lng: 18.8859457 },
    { lat: 48.1749283, lng: 18.8890269 },
    { lat: 48.1736905, lng: 18.8922175 },
    { lat: 48.1725687, lng: 18.8950197 },
    { lat: 48.1725509, lng: 18.8950677 },
    { lat: 48.1721605, lng: 18.896058 },
    { lat: 48.1717262, lng: 18.8967934 },
    { lat: 48.1711295, lng: 18.8973336 },
    { lat: 48.1700576, lng: 18.8986231 },
    { lat: 48.1696329, lng: 18.8993047 },
    { lat: 48.1695073, lng: 18.8993391 },
    { lat: 48.1682651, lng: 18.898956 },
    { lat: 48.1682237, lng: 18.8992878 },
    { lat: 48.1686723, lng: 18.9003908 },
    { lat: 48.1687278, lng: 18.9008316 },
    { lat: 48.1687053, lng: 18.9025668 },
    { lat: 48.1687975, lng: 18.9028235 },
    { lat: 48.1686982, lng: 18.9029089 },
    { lat: 48.1687459, lng: 18.9032852 },
    { lat: 48.1687064, lng: 18.9034586 },
    { lat: 48.168726, lng: 18.9036181 },
    { lat: 48.1685521, lng: 18.9042402 },
    { lat: 48.1677356, lng: 18.9052632 },
    { lat: 48.1673618, lng: 18.9065743 },
    { lat: 48.1673048, lng: 18.9069104 },
    { lat: 48.166944, lng: 18.9078028 },
    { lat: 48.1661359, lng: 18.9112485 },
    { lat: 48.1663973, lng: 18.9152646 },
    { lat: 48.1664562, lng: 18.9190379 },
    { lat: 48.1665742, lng: 18.9196611 },
    { lat: 48.1666781, lng: 18.9207313 },
    { lat: 48.166434, lng: 18.9226168 },
    { lat: 48.1664967, lng: 18.9230888 },
    { lat: 48.1668907, lng: 18.9241884 },
    { lat: 48.1676833, lng: 18.9239883 },
    { lat: 48.1681708, lng: 18.9248485 },
    { lat: 48.1691017, lng: 18.9250574 },
    { lat: 48.1724817, lng: 18.926134 },
    { lat: 48.1728931, lng: 18.9261759 },
    { lat: 48.1732953, lng: 18.9264508 },
    { lat: 48.17421, lng: 18.9265878 },
    { lat: 48.1747498, lng: 18.9269292 },
    { lat: 48.1750722, lng: 18.9279546 },
    { lat: 48.1759463, lng: 18.9285679 },
    { lat: 48.1767521, lng: 18.9286465 },
    { lat: 48.1770144, lng: 18.9269617 },
    { lat: 48.1770334, lng: 18.9268663 },
  ],
  Litava: [
    { lat: 48.3194937, lng: 19.1645902 },
    { lat: 48.3194165, lng: 19.1645817 },
    { lat: 48.3192964, lng: 19.1642264 },
    { lat: 48.3189883, lng: 19.1639252 },
    { lat: 48.3188356, lng: 19.1638661 },
    { lat: 48.3187158, lng: 19.1639258 },
    { lat: 48.3185945, lng: 19.1638667 },
    { lat: 48.3185021, lng: 19.1637324 },
    { lat: 48.3183888, lng: 19.163382 },
    { lat: 48.3181215, lng: 19.1634695 },
    { lat: 48.3180925, lng: 19.1632938 },
    { lat: 48.3175393, lng: 19.1628227 },
    { lat: 48.3169013, lng: 19.1624789 },
    { lat: 48.3167799, lng: 19.1627131 },
    { lat: 48.3166032, lng: 19.1626084 },
    { lat: 48.3164403, lng: 19.1622086 },
    { lat: 48.3163081, lng: 19.1621351 },
    { lat: 48.3162221, lng: 19.1619921 },
    { lat: 48.3159613, lng: 19.1619577 },
    { lat: 48.3158545, lng: 19.161761 },
    { lat: 48.3157783, lng: 19.1619306 },
    { lat: 48.3156992, lng: 19.1619263 },
    { lat: 48.3156688, lng: 19.1618326 },
    { lat: 48.3151775, lng: 19.1616581 },
    { lat: 48.3149731, lng: 19.1614213 },
    { lat: 48.3148695, lng: 19.1614902 },
    { lat: 48.3147209, lng: 19.1614051 },
    { lat: 48.3146884, lng: 19.1610673 },
    { lat: 48.3143412, lng: 19.1609784 },
    { lat: 48.3142953, lng: 19.1608482 },
    { lat: 48.314144, lng: 19.1608895 },
    { lat: 48.3140446, lng: 19.1607462 },
    { lat: 48.3136887, lng: 19.1606161 },
    { lat: 48.3135614, lng: 19.1606625 },
    { lat: 48.3137215, lng: 19.1608919 },
    { lat: 48.3136361, lng: 19.1610792 },
    { lat: 48.3134326, lng: 19.1607951 },
    { lat: 48.3134328, lng: 19.1605943 },
    { lat: 48.3133616, lng: 19.1604971 },
    { lat: 48.3130035, lng: 19.1604402 },
    { lat: 48.312776, lng: 19.1602159 },
    { lat: 48.3127283, lng: 19.1604365 },
    { lat: 48.3126069, lng: 19.1603753 },
    { lat: 48.3125583, lng: 19.1602142 },
    { lat: 48.3125098, lng: 19.1603928 },
    { lat: 48.3124496, lng: 19.1603836 },
    { lat: 48.3123186, lng: 19.1601998 },
    { lat: 48.3123643, lng: 19.1600071 },
    { lat: 48.3121689, lng: 19.1600932 },
    { lat: 48.3119245, lng: 19.1600503 },
    { lat: 48.3118791, lng: 19.1600224 },
    { lat: 48.3119305, lng: 19.1598535 },
    { lat: 48.3117605, lng: 19.159763 },
    { lat: 48.3116672, lng: 19.1600648 },
    { lat: 48.3113409, lng: 19.1601015 },
    { lat: 48.3110481, lng: 19.1600189 },
    { lat: 48.310704, lng: 19.1598055 },
    { lat: 48.3107555, lng: 19.1595961 },
    { lat: 48.3105035, lng: 19.1593759 },
    { lat: 48.310532, lng: 19.1591804 },
    { lat: 48.310434, lng: 19.1590224 },
    { lat: 48.3102712, lng: 19.1592371 },
    { lat: 48.3098198, lng: 19.1587278 },
    { lat: 48.3091621, lng: 19.1582217 },
    { lat: 48.309079, lng: 19.1576353 },
    { lat: 48.308825, lng: 19.1574915 },
    { lat: 48.3088031, lng: 19.1573452 },
    { lat: 48.3084811, lng: 19.1574639 },
    { lat: 48.3084829, lng: 19.1570462 },
    { lat: 48.3084143, lng: 19.1569826 },
    { lat: 48.3081868, lng: 19.1572357 },
    { lat: 48.3065326, lng: 19.1564089 },
    { lat: 48.3056509, lng: 19.156177 },
    { lat: 48.3049639, lng: 19.1557372 },
    { lat: 48.3038088, lng: 19.1557434 },
    { lat: 48.303022, lng: 19.15545 },
    { lat: 48.3028869, lng: 19.1556594 },
    { lat: 48.3028061, lng: 19.1554602 },
    { lat: 48.3026282, lng: 19.1554212 },
    { lat: 48.3026295, lng: 19.1552657 },
    { lat: 48.3025128, lng: 19.1551859 },
    { lat: 48.3024452, lng: 19.1552079 },
    { lat: 48.3022921, lng: 19.1550072 },
    { lat: 48.3020739, lng: 19.1550627 },
    { lat: 48.301917, lng: 19.1554477 },
    { lat: 48.3017993, lng: 19.1553216 },
    { lat: 48.3016794, lng: 19.1554522 },
    { lat: 48.3013185, lng: 19.1552927 },
    { lat: 48.3012424, lng: 19.1549811 },
    { lat: 48.3011997, lng: 19.1549873 },
    { lat: 48.3011979, lng: 19.1550179 },
    { lat: 48.3011953, lng: 19.1550993 },
    { lat: 48.3009163, lng: 19.1552263 },
    { lat: 48.3006615, lng: 19.1550502 },
    { lat: 48.2994829, lng: 19.1542359 },
    { lat: 48.2989343, lng: 19.1540403 },
    { lat: 48.2988435, lng: 19.1539382 },
    { lat: 48.2987806, lng: 19.1539888 },
    { lat: 48.2986139, lng: 19.1539433 },
    { lat: 48.2985274, lng: 19.1539943 },
    { lat: 48.2984569, lng: 19.1539409 },
    { lat: 48.2983296, lng: 19.1539845 },
    { lat: 48.2982161, lng: 19.1538098 },
    { lat: 48.2982298, lng: 19.1536571 },
    { lat: 48.2981469, lng: 19.1535959 },
    { lat: 48.2980023, lng: 19.1536612 },
    { lat: 48.2978861, lng: 19.15391 },
    { lat: 48.2977134, lng: 19.1539239 },
    { lat: 48.2974983, lng: 19.1538176 },
    { lat: 48.2973147, lng: 19.1538688 },
    { lat: 48.2970737, lng: 19.1538238 },
    { lat: 48.297054, lng: 19.1538847 },
    { lat: 48.296876, lng: 19.1538391 },
    { lat: 48.2967735, lng: 19.1537294 },
    { lat: 48.2967126, lng: 19.153454 },
    { lat: 48.2965239, lng: 19.1535129 },
    { lat: 48.2964925, lng: 19.153356 },
    { lat: 48.2964073, lng: 19.1532763 },
    { lat: 48.2962436, lng: 19.1535438 },
    { lat: 48.2960823, lng: 19.1535639 },
    { lat: 48.2959366, lng: 19.153493 },
    { lat: 48.2957623, lng: 19.1537751 },
    { lat: 48.2956726, lng: 19.1536909 },
    { lat: 48.2956753, lng: 19.153498 },
    { lat: 48.29547, lng: 19.1533361 },
    { lat: 48.2954709, lng: 19.1534433 },
    { lat: 48.2954239, lng: 19.1534963 },
    { lat: 48.2953487, lng: 19.1535036 },
    { lat: 48.2952425, lng: 19.1534061 },
    { lat: 48.2951329, lng: 19.1534487 },
    { lat: 48.2949991, lng: 19.1532954 },
    { lat: 48.2949054, lng: 19.153045 },
    { lat: 48.2946203, lng: 19.1527971 },
    { lat: 48.2946187, lng: 19.1524882 },
    { lat: 48.2943709, lng: 19.1526322 },
    { lat: 48.2942604, lng: 19.1525624 },
    { lat: 48.2942013, lng: 19.1524175 },
    { lat: 48.2933066, lng: 19.1518778 },
    { lat: 48.2930832, lng: 19.1514644 },
    { lat: 48.2930589, lng: 19.1512264 },
    { lat: 48.2929328, lng: 19.1512096 },
    { lat: 48.2929469, lng: 19.1509461 },
    { lat: 48.2930321, lng: 19.1507773 },
    { lat: 48.2930382, lng: 19.1505568 },
    { lat: 48.2930071, lng: 19.1505015 },
    { lat: 48.2928326, lng: 19.1504855 },
    { lat: 48.2927538, lng: 19.1503007 },
    { lat: 48.2927539, lng: 19.1501226 },
    { lat: 48.2926133, lng: 19.1499938 },
    { lat: 48.2926908, lng: 19.1497604 },
    { lat: 48.2926733, lng: 19.1497025 },
    { lat: 48.2925811, lng: 19.1496632 },
    { lat: 48.2924821, lng: 19.1497337 },
    { lat: 48.2921538, lng: 19.1496408 },
    { lat: 48.2921361, lng: 19.1498265 },
    { lat: 48.2920608, lng: 19.1498554 },
    { lat: 48.2919142, lng: 19.149671 },
    { lat: 48.2918463, lng: 19.149531 },
    { lat: 48.2918358, lng: 19.1493438 },
    { lat: 48.2920097, lng: 19.1492777 },
    { lat: 48.2920037, lng: 19.1490659 },
    { lat: 48.2918196, lng: 19.1490181 },
    { lat: 48.2917699, lng: 19.1489437 },
    { lat: 48.2917734, lng: 19.1485153 },
    { lat: 48.2916273, lng: 19.1483422 },
    { lat: 48.2916035, lng: 19.148205 },
    { lat: 48.2913294, lng: 19.148066 },
    { lat: 48.2914112, lng: 19.1476714 },
    { lat: 48.2913882, lng: 19.1475221 },
    { lat: 48.291329, lng: 19.1474193 },
    { lat: 48.2912248, lng: 19.1474337 },
    { lat: 48.2911509, lng: 19.1475347 },
    { lat: 48.2910588, lng: 19.147431 },
    { lat: 48.2908936, lng: 19.1474618 },
    { lat: 48.2908667, lng: 19.1472623 },
    { lat: 48.2909143, lng: 19.1471975 },
    { lat: 48.2908968, lng: 19.1470505 },
    { lat: 48.2909453, lng: 19.1469385 },
    { lat: 48.2907354, lng: 19.146706 },
    { lat: 48.290639, lng: 19.1467627 },
    { lat: 48.2906235, lng: 19.1466317 },
    { lat: 48.2904358, lng: 19.1464579 },
    { lat: 48.2904367, lng: 19.1463856 },
    { lat: 48.2902985, lng: 19.1461486 },
    { lat: 48.2903047, lng: 19.1460913 },
    { lat: 48.2904152, lng: 19.146068 },
    { lat: 48.2904069, lng: 19.1458572 },
    { lat: 48.2902286, lng: 19.1456254 },
    { lat: 48.2898736, lng: 19.1454686 },
    { lat: 48.2897635, lng: 19.1456125 },
    { lat: 48.2898171, lng: 19.1462161 },
    { lat: 48.2897622, lng: 19.1466513 },
    { lat: 48.289308, lng: 19.1467908 },
    { lat: 48.2891671, lng: 19.1467024 },
    { lat: 48.2885309, lng: 19.1466498 },
    { lat: 48.2876449, lng: 19.1475369 },
    { lat: 48.2874195, lng: 19.1479217 },
    { lat: 48.2869698, lng: 19.1488319 },
    { lat: 48.2858498, lng: 19.1519364 },
    { lat: 48.2853687, lng: 19.1539393 },
    { lat: 48.2849321, lng: 19.1552961 },
    { lat: 48.2839752, lng: 19.1572789 },
    { lat: 48.283531, lng: 19.1578929 },
    { lat: 48.2829694, lng: 19.1584817 },
    { lat: 48.2824582, lng: 19.1588581 },
    { lat: 48.2819666, lng: 19.1591294 },
    { lat: 48.2816221, lng: 19.1594084 },
    { lat: 48.2812207, lng: 19.1598849 },
    { lat: 48.2810327, lng: 19.1602101 },
    { lat: 48.2807668, lng: 19.160852 },
    { lat: 48.2782389, lng: 19.164692 },
    { lat: 48.2779284, lng: 19.1656827 },
    { lat: 48.2778375, lng: 19.1661702 },
    { lat: 48.2775441, lng: 19.1697418 },
    { lat: 48.2773368, lng: 19.1699481 },
    { lat: 48.2771217, lng: 19.1699072 },
    { lat: 48.2770205, lng: 19.1700721 },
    { lat: 48.2766489, lng: 19.1700585 },
    { lat: 48.2761325, lng: 19.1697332 },
    { lat: 48.2757644, lng: 19.1699514 },
    { lat: 48.2752308, lng: 19.1701016 },
    { lat: 48.2751266, lng: 19.1699564 },
    { lat: 48.2748632, lng: 19.1699863 },
    { lat: 48.274707, lng: 19.1701077 },
    { lat: 48.2745946, lng: 19.1700469 },
    { lat: 48.2745194, lng: 19.1701023 },
    { lat: 48.2743987, lng: 19.1703939 },
    { lat: 48.2740467, lng: 19.1704605 },
    { lat: 48.27329, lng: 19.1703163 },
    { lat: 48.2729878, lng: 19.171203 },
    { lat: 48.2726974, lng: 19.1716935 },
    { lat: 48.272451, lng: 19.1725439 },
    { lat: 48.2724134, lng: 19.1730838 },
    { lat: 48.2707172, lng: 19.1740508 },
    { lat: 48.2707515, lng: 19.1740626 },
    { lat: 48.2703807, lng: 19.1749727 },
    { lat: 48.2700944, lng: 19.175957 },
    { lat: 48.2699738, lng: 19.1765465 },
    { lat: 48.2699002, lng: 19.1773189 },
    { lat: 48.269866, lng: 19.1784734 },
    { lat: 48.2697293, lng: 19.1794733 },
    { lat: 48.269752, lng: 19.1804608 },
    { lat: 48.2698195, lng: 19.1806837 },
    { lat: 48.2700444, lng: 19.1809475 },
    { lat: 48.2703193, lng: 19.1814436 },
    { lat: 48.2703581, lng: 19.1822107 },
    { lat: 48.2706087, lng: 19.1835636 },
    { lat: 48.2705018, lng: 19.1837722 },
    { lat: 48.2696193, lng: 19.184396 },
    { lat: 48.2691096, lng: 19.1844351 },
    { lat: 48.2687009, lng: 19.1849287 },
    { lat: 48.2684614, lng: 19.1861501 },
    { lat: 48.2677864, lng: 19.1882115 },
    { lat: 48.267514, lng: 19.1888573 },
    { lat: 48.2671911, lng: 19.1896066 },
    { lat: 48.266415, lng: 19.190831 },
    { lat: 48.2661914, lng: 19.1916423 },
    { lat: 48.2655652, lng: 19.1933273 },
    { lat: 48.2647798, lng: 19.1945689 },
    { lat: 48.2641824, lng: 19.1957165 },
    { lat: 48.2636196, lng: 19.1969455 },
    { lat: 48.2632838, lng: 19.1984112 },
    { lat: 48.2625436, lng: 19.2012619 },
    { lat: 48.2625118, lng: 19.2031369 },
    { lat: 48.2620307, lng: 19.2051606 },
    { lat: 48.2619992, lng: 19.2061723 },
    { lat: 48.2620431, lng: 19.2073827 },
    { lat: 48.2619269, lng: 19.2087154 },
    { lat: 48.2613789, lng: 19.2114192 },
    { lat: 48.2607408, lng: 19.2154103 },
    { lat: 48.2606689, lng: 19.2163139 },
    { lat: 48.2600374, lng: 19.2173421 },
    { lat: 48.2597832, lng: 19.2179527 },
    { lat: 48.2602173, lng: 19.2188725 },
    { lat: 48.260243, lng: 19.2192135 },
    { lat: 48.260187, lng: 19.2204603 },
    { lat: 48.2599953, lng: 19.2209674 },
    { lat: 48.2598926, lng: 19.2215617 },
    { lat: 48.2596549, lng: 19.222255 },
    { lat: 48.2595261, lng: 19.2238827 },
    { lat: 48.259137, lng: 19.224041 },
    { lat: 48.25911, lng: 19.224215 },
    { lat: 48.259075, lng: 19.224403 },
    { lat: 48.259064, lng: 19.224443 },
    { lat: 48.259042, lng: 19.224524 },
    { lat: 48.259029, lng: 19.224574 },
    { lat: 48.258929, lng: 19.225013 },
    { lat: 48.258861, lng: 19.225417 },
    { lat: 48.258841, lng: 19.22629 },
    { lat: 48.258846, lng: 19.226598 },
    { lat: 48.258833, lng: 19.226676 },
    { lat: 48.259291, lng: 19.227605 },
    { lat: 48.259667, lng: 19.228447 },
    { lat: 48.259666, lng: 19.228695 },
    { lat: 48.259662, lng: 19.228798 },
    { lat: 48.259647, lng: 19.228985 },
    { lat: 48.259634, lng: 19.229155 },
    { lat: 48.259615, lng: 19.229717 },
    { lat: 48.25963, lng: 19.230042 },
    { lat: 48.259635, lng: 19.23013 },
    { lat: 48.259646, lng: 19.230509 },
    { lat: 48.259845, lng: 19.231162 },
    { lat: 48.260153, lng: 19.231509 },
    { lat: 48.260354, lng: 19.231886 },
    { lat: 48.260598, lng: 19.232656 },
    { lat: 48.260968, lng: 19.233143 },
    { lat: 48.26106, lng: 19.233274 },
    { lat: 48.261372, lng: 19.233725 },
    { lat: 48.261415, lng: 19.233811 },
    { lat: 48.261984, lng: 19.233593 },
    { lat: 48.262266, lng: 19.233363 },
    { lat: 48.262616, lng: 19.233237 },
    { lat: 48.262769, lng: 19.233035 },
    { lat: 48.262944, lng: 19.23297 },
    { lat: 48.263438, lng: 19.232792 },
    { lat: 48.263758, lng: 19.232553 },
    { lat: 48.263864, lng: 19.232621 },
    { lat: 48.264133, lng: 19.232695 },
    { lat: 48.264408, lng: 19.23247 },
    { lat: 48.264724, lng: 19.23241 },
    { lat: 48.26508, lng: 19.232481 },
    { lat: 48.265239, lng: 19.232414 },
    { lat: 48.265272, lng: 19.232369 },
    { lat: 48.265288, lng: 19.231632 },
    { lat: 48.265358, lng: 19.231182 },
    { lat: 48.265331, lng: 19.23102 },
    { lat: 48.265414, lng: 19.230414 },
    { lat: 48.265441, lng: 19.230249 },
    { lat: 48.265523, lng: 19.229693 },
    { lat: 48.265503, lng: 19.229285 },
    { lat: 48.265606, lng: 19.228986 },
    { lat: 48.265842, lng: 19.228641 },
    { lat: 48.266032, lng: 19.228146 },
    { lat: 48.266234, lng: 19.227335 },
    { lat: 48.266284, lng: 19.227152 },
    { lat: 48.266414, lng: 19.226898 },
    { lat: 48.266554, lng: 19.226625 },
    { lat: 48.266667, lng: 19.226404 },
    { lat: 48.266786, lng: 19.226165 },
    { lat: 48.266851, lng: 19.226007 },
    { lat: 48.266938, lng: 19.225559 },
    { lat: 48.267103, lng: 19.22534 },
    { lat: 48.267154, lng: 19.225168 },
    { lat: 48.267241, lng: 19.224889 },
    { lat: 48.267393, lng: 19.224503 },
    { lat: 48.267555, lng: 19.224083 },
    { lat: 48.267796, lng: 19.223783 },
    { lat: 48.267817, lng: 19.223737 },
    { lat: 48.267989, lng: 19.223366 },
    { lat: 48.268161, lng: 19.223019 },
    { lat: 48.268298, lng: 19.22288 },
    { lat: 48.268438, lng: 19.222661 },
    { lat: 48.268525, lng: 19.222436 },
    { lat: 48.268564, lng: 19.222383 },
    { lat: 48.268858, lng: 19.222011 },
    { lat: 48.268993, lng: 19.221845 },
    { lat: 48.269051, lng: 19.221762 },
    { lat: 48.269132, lng: 19.221732 },
    { lat: 48.269341, lng: 19.221812 },
    { lat: 48.269367, lng: 19.221786 },
    { lat: 48.269415, lng: 19.221731 },
    { lat: 48.269465, lng: 19.221692 },
    { lat: 48.269487, lng: 19.221645 },
    { lat: 48.269538, lng: 19.221503 },
    { lat: 48.269606, lng: 19.221441 },
    { lat: 48.269769, lng: 19.221291 },
    { lat: 48.270103, lng: 19.22096 },
    { lat: 48.270257, lng: 19.220808 },
    { lat: 48.270319, lng: 19.220713 },
    { lat: 48.270518, lng: 19.2204 },
    { lat: 48.270696, lng: 19.220357 },
    { lat: 48.270776, lng: 19.220195 },
    { lat: 48.270886, lng: 19.220029 },
    { lat: 48.271013, lng: 19.219921 },
    { lat: 48.271314, lng: 19.219669 },
    { lat: 48.271549, lng: 19.219612 },
    { lat: 48.271686, lng: 19.219445 },
    { lat: 48.271876, lng: 19.219341 },
    { lat: 48.271973, lng: 19.219074 },
    { lat: 48.272144, lng: 19.218937 },
    { lat: 48.272336, lng: 19.218781 },
    { lat: 48.272688, lng: 19.2185 },
    { lat: 48.272737, lng: 19.218465 },
    { lat: 48.273082, lng: 19.217968 },
    { lat: 48.273229, lng: 19.217761 },
    { lat: 48.273198, lng: 19.217675 },
    { lat: 48.273439, lng: 19.217246 },
    { lat: 48.273676, lng: 19.216747 },
    { lat: 48.27365, lng: 19.216449 },
    { lat: 48.273684, lng: 19.216291 },
    { lat: 48.273649, lng: 19.216176 },
    { lat: 48.273637, lng: 19.215973 },
    { lat: 48.273755, lng: 19.215897 },
    { lat: 48.274207, lng: 19.215609 },
    { lat: 48.274303, lng: 19.2156 },
    { lat: 48.274339, lng: 19.215597 },
    { lat: 48.27446, lng: 19.215585 },
    { lat: 48.274537, lng: 19.215578 },
    { lat: 48.2746, lng: 19.215572 },
    { lat: 48.274675, lng: 19.215551 },
    { lat: 48.274945, lng: 19.215449 },
    { lat: 48.275346, lng: 19.215313 },
    { lat: 48.275487, lng: 19.215223 },
    { lat: 48.276099, lng: 19.21484 },
    { lat: 48.276237, lng: 19.214747 },
    { lat: 48.276524, lng: 19.214556 },
    { lat: 48.276604, lng: 19.214563 },
    { lat: 48.276758, lng: 19.214582 },
    { lat: 48.277231, lng: 19.214644 },
    { lat: 48.27734, lng: 19.214641 },
    { lat: 48.277967, lng: 19.214614 },
    { lat: 48.278136, lng: 19.214528 },
    { lat: 48.278363, lng: 19.214461 },
    { lat: 48.278632, lng: 19.214273 },
    { lat: 48.27864, lng: 19.21426 },
    { lat: 48.2787, lng: 19.214321 },
    { lat: 48.278937, lng: 19.214567 },
    { lat: 48.279195, lng: 19.214859 },
    { lat: 48.279273, lng: 19.214896 },
    { lat: 48.279322, lng: 19.214809 },
    { lat: 48.279337, lng: 19.214783 },
    { lat: 48.279506, lng: 19.214707 },
    { lat: 48.279715, lng: 19.214583 },
    { lat: 48.279822, lng: 19.214628 },
    { lat: 48.280001, lng: 19.214553 },
    { lat: 48.280076, lng: 19.21459 },
    { lat: 48.280132, lng: 19.214618 },
    { lat: 48.280275, lng: 19.214813 },
    { lat: 48.280328, lng: 19.214808 },
    { lat: 48.280412, lng: 19.214761 },
    { lat: 48.280555, lng: 19.214761 },
    { lat: 48.280716, lng: 19.214865 },
    { lat: 48.280831, lng: 19.214978 },
    { lat: 48.28088, lng: 19.215058 },
    { lat: 48.280976, lng: 19.215054 },
    { lat: 48.281068, lng: 19.215033 },
    { lat: 48.281104, lng: 19.215026 },
    { lat: 48.281136, lng: 19.214967 },
    { lat: 48.281164, lng: 19.214918 },
    { lat: 48.281294, lng: 19.214816 },
    { lat: 48.281454, lng: 19.214908 },
    { lat: 48.281521, lng: 19.21479 },
    { lat: 48.281652, lng: 19.214877 },
    { lat: 48.281665, lng: 19.215022 },
    { lat: 48.281821, lng: 19.214977 },
    { lat: 48.281831, lng: 19.214978 },
    { lat: 48.281843, lng: 19.21485 },
    { lat: 48.282043, lng: 19.214518 },
    { lat: 48.282218, lng: 19.214443 },
    { lat: 48.282342, lng: 19.214039 },
    { lat: 48.28238, lng: 19.213699 },
    { lat: 48.282517, lng: 19.213278 },
    { lat: 48.282599, lng: 19.212929 },
    { lat: 48.282993, lng: 19.212983 },
    { lat: 48.282991, lng: 19.212841 },
    { lat: 48.283191, lng: 19.212883 },
    { lat: 48.283433, lng: 19.212934 },
    { lat: 48.283568, lng: 19.212996 },
    { lat: 48.283816, lng: 19.213123 },
    { lat: 48.284028, lng: 19.213194 },
    { lat: 48.284325, lng: 19.213278 },
    { lat: 48.284359, lng: 19.213293 },
    { lat: 48.284932, lng: 19.213455 },
    { lat: 48.285097, lng: 19.213504 },
    { lat: 48.285517, lng: 19.213485 },
    { lat: 48.285516, lng: 19.213396 },
    { lat: 48.285502, lng: 19.212876 },
    { lat: 48.285693, lng: 19.212858 },
    { lat: 48.28567, lng: 19.212348 },
    { lat: 48.285744, lng: 19.212329 },
    { lat: 48.285866, lng: 19.2123 },
    { lat: 48.286119, lng: 19.212241 },
    { lat: 48.286141, lng: 19.212341 },
    { lat: 48.286198, lng: 19.212315 },
    { lat: 48.286573, lng: 19.212132 },
    { lat: 48.286906, lng: 19.211996 },
    { lat: 48.28696, lng: 19.211973 },
    { lat: 48.287263, lng: 19.21185 },
    { lat: 48.287701, lng: 19.211664 },
    { lat: 48.287753, lng: 19.211643 },
    { lat: 48.287973, lng: 19.211554 },
    { lat: 48.288299, lng: 19.211602 },
    { lat: 48.288408, lng: 19.211618 },
    { lat: 48.28865, lng: 19.211653 },
    { lat: 48.289132, lng: 19.211699 },
    { lat: 48.289656, lng: 19.211712 },
    { lat: 48.289861, lng: 19.211719 },
    { lat: 48.290024, lng: 19.211483 },
    { lat: 48.290296, lng: 19.211087 },
    { lat: 48.29031, lng: 19.211164 },
    { lat: 48.290372, lng: 19.211116 },
    { lat: 48.290482, lng: 19.211032 },
    { lat: 48.29076, lng: 19.21073 },
    { lat: 48.290905, lng: 19.210536 },
    { lat: 48.29125, lng: 19.210202 },
    { lat: 48.291612, lng: 19.209769 },
    { lat: 48.291981, lng: 19.209323 },
    { lat: 48.292398, lng: 19.209171 },
    { lat: 48.292453, lng: 19.209154 },
    { lat: 48.292925, lng: 19.209074 },
    { lat: 48.2932, lng: 19.209027 },
    { lat: 48.293466, lng: 19.208982 },
    { lat: 48.293906, lng: 19.20894 },
    { lat: 48.294554, lng: 19.208881 },
    { lat: 48.294702, lng: 19.208823 },
    { lat: 48.294766, lng: 19.208662 },
    { lat: 48.294927, lng: 19.208623 },
    { lat: 48.295031, lng: 19.208422 },
    { lat: 48.295065, lng: 19.208383 },
    { lat: 48.295238, lng: 19.208189 },
    { lat: 48.295318, lng: 19.208014 },
    { lat: 48.295402, lng: 19.207902 },
    { lat: 48.295833, lng: 19.207335 },
    { lat: 48.296039, lng: 19.206733 },
    { lat: 48.296603, lng: 19.205838 },
    { lat: 48.296382, lng: 19.204122 },
    { lat: 48.296335, lng: 19.203419 },
    { lat: 48.29633, lng: 19.203346 },
    { lat: 48.296328, lng: 19.203318 },
    { lat: 48.296412, lng: 19.203087 },
    { lat: 48.296542, lng: 19.202734 },
    { lat: 48.296636, lng: 19.202473 },
    { lat: 48.296657, lng: 19.202378 },
    { lat: 48.296868, lng: 19.201449 },
    { lat: 48.297139, lng: 19.201482 },
    { lat: 48.297731, lng: 19.201553 },
    { lat: 48.297854, lng: 19.200927 },
    { lat: 48.298124, lng: 19.200989 },
    { lat: 48.298226, lng: 19.200993 },
    { lat: 48.298612, lng: 19.201005 },
    { lat: 48.29862, lng: 19.201006 },
    { lat: 48.299093, lng: 19.201024 },
    { lat: 48.299343, lng: 19.201049 },
    { lat: 48.299889, lng: 19.201098 },
    { lat: 48.300108, lng: 19.201118 },
    { lat: 48.300291, lng: 19.201125 },
    { lat: 48.300336, lng: 19.201127 },
    { lat: 48.300605, lng: 19.20116 },
    { lat: 48.300801, lng: 19.201107 },
    { lat: 48.301017, lng: 19.201051 },
    { lat: 48.301143, lng: 19.201016 },
    { lat: 48.301184, lng: 19.201007 },
    { lat: 48.301256, lng: 19.201006 },
    { lat: 48.301309, lng: 19.201005 },
    { lat: 48.30137, lng: 19.201005 },
    { lat: 48.301423, lng: 19.201005 },
    { lat: 48.301476, lng: 19.201006 },
    { lat: 48.301532, lng: 19.201005 },
    { lat: 48.301579, lng: 19.201006 },
    { lat: 48.301626, lng: 19.201005 },
    { lat: 48.301698, lng: 19.201006 },
    { lat: 48.301742, lng: 19.201007 },
    { lat: 48.301787, lng: 19.201006 },
    { lat: 48.301867, lng: 19.201007 },
    { lat: 48.302022, lng: 19.201008 },
    { lat: 48.302079, lng: 19.201009 },
    { lat: 48.302305, lng: 19.201023 },
    { lat: 48.302365, lng: 19.201021 },
    { lat: 48.302633, lng: 19.201014 },
    { lat: 48.302737, lng: 19.201009 },
    { lat: 48.302955, lng: 19.201001 },
    { lat: 48.303145, lng: 19.200994 },
    { lat: 48.303313, lng: 19.20099 },
    { lat: 48.303413, lng: 19.200988 },
    { lat: 48.303508, lng: 19.200985 },
    { lat: 48.303515, lng: 19.200985 },
    { lat: 48.303606, lng: 19.200984 },
    { lat: 48.303709, lng: 19.20098 },
    { lat: 48.303811, lng: 19.200977 },
    { lat: 48.303916, lng: 19.200964 },
    { lat: 48.304006, lng: 19.200952 },
    { lat: 48.304391, lng: 19.200892 },
    { lat: 48.304481, lng: 19.200877 },
    { lat: 48.304779, lng: 19.200824 },
    { lat: 48.304989, lng: 19.200786 },
    { lat: 48.305185, lng: 19.200754 },
    { lat: 48.305212, lng: 19.20075 },
    { lat: 48.30521, lng: 19.20111 },
    { lat: 48.305242, lng: 19.201104 },
    { lat: 48.305557, lng: 19.201027 },
    { lat: 48.305575, lng: 19.201439 },
    { lat: 48.30573, lng: 19.201519 },
    { lat: 48.305929, lng: 19.201625 },
    { lat: 48.306, lng: 19.201667 },
    { lat: 48.306034, lng: 19.201689 },
    { lat: 48.306062, lng: 19.201705 },
    { lat: 48.306183, lng: 19.201777 },
    { lat: 48.306382, lng: 19.201898 },
    { lat: 48.306612, lng: 19.202042 },
    { lat: 48.306791, lng: 19.202141 },
    { lat: 48.306978, lng: 19.202243 },
    { lat: 48.307146, lng: 19.20234 },
    { lat: 48.307225, lng: 19.202371 },
    { lat: 48.307373, lng: 19.202435 },
    { lat: 48.307499, lng: 19.202487 },
    { lat: 48.307525, lng: 19.20249 },
    { lat: 48.307698, lng: 19.20254 },
    { lat: 48.307852, lng: 19.20258 },
    { lat: 48.307986, lng: 19.202615 },
    { lat: 48.308121, lng: 19.202655 },
    { lat: 48.308146, lng: 19.202663 },
    { lat: 48.308285, lng: 19.202713 },
    { lat: 48.308351, lng: 19.202737 },
    { lat: 48.308416, lng: 19.20276 },
    { lat: 48.308484, lng: 19.202774 },
    { lat: 48.308561, lng: 19.202792 },
    { lat: 48.308854, lng: 19.202892 },
    { lat: 48.309054, lng: 19.202963 },
    { lat: 48.309227, lng: 19.203022 },
    { lat: 48.309707, lng: 19.203165 },
    { lat: 48.309875, lng: 19.203219 },
    { lat: 48.309967, lng: 19.203242 },
    { lat: 48.310145, lng: 19.203286 },
    { lat: 48.310146, lng: 19.203286 },
    { lat: 48.310293, lng: 19.203323 },
    { lat: 48.310433, lng: 19.203355 },
    { lat: 48.310459, lng: 19.203361 },
    { lat: 48.310872, lng: 19.20343 },
    { lat: 48.311118, lng: 19.203426 },
    { lat: 48.311494, lng: 19.203343 },
    { lat: 48.311646, lng: 19.203311 },
    { lat: 48.311773, lng: 19.203285 },
    { lat: 48.312069, lng: 19.203221 },
    { lat: 48.312205, lng: 19.203191 },
    { lat: 48.312266, lng: 19.204071 },
    { lat: 48.312469, lng: 19.204014 },
    { lat: 48.31259, lng: 19.203976 },
    { lat: 48.31267, lng: 19.203965 },
    { lat: 48.312795, lng: 19.203943 },
    { lat: 48.313568, lng: 19.203808 },
    { lat: 48.313661, lng: 19.203786 },
    { lat: 48.313753, lng: 19.204047 },
    { lat: 48.313825, lng: 19.204262 },
    { lat: 48.313835, lng: 19.204305 },
    { lat: 48.313964, lng: 19.204832 },
    { lat: 48.314033, lng: 19.204941 },
    { lat: 48.314302, lng: 19.206143 },
    { lat: 48.314328, lng: 19.206354 },
    { lat: 48.314361, lng: 19.206585 },
    { lat: 48.314428, lng: 19.20761 },
    { lat: 48.314458, lng: 19.208143 },
    { lat: 48.314631, lng: 19.20813 },
    { lat: 48.31466, lng: 19.208128 },
    { lat: 48.3147536, lng: 19.2074166 },
    { lat: 48.3150384, lng: 19.2061499 },
    { lat: 48.3151946, lng: 19.2047366 },
    { lat: 48.3152578, lng: 19.2029497 },
    { lat: 48.3155704, lng: 19.2015344 },
    { lat: 48.3156687, lng: 19.2005977 },
    { lat: 48.3156312, lng: 19.1997661 },
    { lat: 48.3155499, lng: 19.1991893 },
    { lat: 48.3152511, lng: 19.1989103 },
    { lat: 48.3149881, lng: 19.1978281 },
    { lat: 48.3147513, lng: 19.1951475 },
    { lat: 48.3148144, lng: 19.1943448 },
    { lat: 48.3150839, lng: 19.1925116 },
    { lat: 48.3156052, lng: 19.1902554 },
    { lat: 48.3156628, lng: 19.1895686 },
    { lat: 48.3156342, lng: 19.1889882 },
    { lat: 48.3157014, lng: 19.1879073 },
    { lat: 48.3156774, lng: 19.1844672 },
    { lat: 48.3157819, lng: 19.184185 },
    { lat: 48.3163401, lng: 19.1831163 },
    { lat: 48.3166974, lng: 19.1818357 },
    { lat: 48.3170867, lng: 19.1819565 },
    { lat: 48.3172675, lng: 19.1810719 },
    { lat: 48.3175387, lng: 19.1794183 },
    { lat: 48.3171862, lng: 19.1793809 },
    { lat: 48.317516, lng: 19.1759673 },
    { lat: 48.3176825, lng: 19.175345 },
    { lat: 48.3178578, lng: 19.174943 },
    { lat: 48.3178359, lng: 19.1748445 },
    { lat: 48.3181271, lng: 19.1733894 },
    { lat: 48.3182462, lng: 19.1734038 },
    { lat: 48.318445, lng: 19.1732061 },
    { lat: 48.3186156, lng: 19.1725751 },
    { lat: 48.3186182, lng: 19.1725232 },
    { lat: 48.3186327, lng: 19.1722375 },
    { lat: 48.3185628, lng: 19.1716647 },
    { lat: 48.3185093, lng: 19.1716522 },
    { lat: 48.3184974, lng: 19.1710713 },
    { lat: 48.3190902, lng: 19.166577 },
    { lat: 48.3195049, lng: 19.1646587 },
    { lat: 48.3194937, lng: 19.1645902 },
  ],
  Trpín: [
    { lat: 48.3031962, lng: 19.1353207 },
    { lat: 48.3022013, lng: 19.1343827 },
    { lat: 48.3003628, lng: 19.132326 },
    { lat: 48.299537, lng: 19.1318147 },
    { lat: 48.2983115, lng: 19.1312946 },
    { lat: 48.2969228, lng: 19.1296453 },
    { lat: 48.2968073, lng: 19.1296129 },
    { lat: 48.296828, lng: 19.1295054 },
    { lat: 48.2963835, lng: 19.128977 },
    { lat: 48.2959482, lng: 19.1278885 },
    { lat: 48.2959, lng: 19.1276457 },
    { lat: 48.2958233, lng: 19.1276416 },
    { lat: 48.2955557, lng: 19.1271164 },
    { lat: 48.2953966, lng: 19.1270244 },
    { lat: 48.2953839, lng: 19.1267883 },
    { lat: 48.294657, lng: 19.1260256 },
    { lat: 48.293562, lng: 19.1247076 },
    { lat: 48.292956, lng: 19.1237247 },
    { lat: 48.2929147, lng: 19.1234596 },
    { lat: 48.2924919, lng: 19.1228931 },
    { lat: 48.2922107, lng: 19.1223649 },
    { lat: 48.2917281, lng: 19.1221155 },
    { lat: 48.2912778, lng: 19.1216742 },
    { lat: 48.2910486, lng: 19.1212836 },
    { lat: 48.29088, lng: 19.1212249 },
    { lat: 48.2906055, lng: 19.1212819 },
    { lat: 48.2905388, lng: 19.1209144 },
    { lat: 48.2902601, lng: 19.1208221 },
    { lat: 48.2902306, lng: 19.120613 },
    { lat: 48.2898825, lng: 19.1203281 },
    { lat: 48.2894882, lng: 19.1196961 },
    { lat: 48.2890349, lng: 19.1182258 },
    { lat: 48.2889999, lng: 19.1177508 },
    { lat: 48.2887489, lng: 19.1171056 },
    { lat: 48.2884365, lng: 19.11672 },
    { lat: 48.2881353, lng: 19.1166501 },
    { lat: 48.2879145, lng: 19.1159031 },
    { lat: 48.2857513, lng: 19.1154129 },
    { lat: 48.2848779, lng: 19.1148542 },
    { lat: 48.2839647, lng: 19.114449 },
    { lat: 48.2827916, lng: 19.1137594 },
    { lat: 48.2819778, lng: 19.1134666 },
    { lat: 48.2802551, lng: 19.1130598 },
    { lat: 48.2801502, lng: 19.1129459 },
    { lat: 48.2800138, lng: 19.112316 },
    { lat: 48.2798761, lng: 19.1119283 },
    { lat: 48.2793135, lng: 19.1112496 },
    { lat: 48.2788464, lng: 19.1106097 },
    { lat: 48.2781475, lng: 19.1104343 },
    { lat: 48.2775075, lng: 19.1103961 },
    { lat: 48.2768278, lng: 19.1108004 },
    { lat: 48.2762522, lng: 19.1117579 },
    { lat: 48.2756535, lng: 19.111824 },
    { lat: 48.2751849, lng: 19.1120574 },
    { lat: 48.2748544, lng: 19.1120998 },
    { lat: 48.2743065, lng: 19.1123718 },
    { lat: 48.2734575, lng: 19.1130459 },
    { lat: 48.2722749, lng: 19.1132069 },
    { lat: 48.2712641, lng: 19.1137098 },
    { lat: 48.2694676, lng: 19.1142403 },
    { lat: 48.2691411, lng: 19.1151715 },
    { lat: 48.2688343, lng: 19.1157122 },
    { lat: 48.2669527, lng: 19.115013 },
    { lat: 48.2655246, lng: 19.1168902 },
    { lat: 48.2648786, lng: 19.1185636 },
    { lat: 48.2659248, lng: 19.1195306 },
    { lat: 48.2655169, lng: 19.1215489 },
    { lat: 48.2654396, lng: 19.1225759 },
    { lat: 48.2653412, lng: 19.1229531 },
    { lat: 48.2659025, lng: 19.1231327 },
    { lat: 48.2668496, lng: 19.1238582 },
    { lat: 48.2670471, lng: 19.124697 },
    { lat: 48.2673354, lng: 19.1265889 },
    { lat: 48.2673212, lng: 19.1266878 },
    { lat: 48.2673102, lng: 19.1268073 },
    { lat: 48.2673827, lng: 19.1267029 },
    { lat: 48.2676918, lng: 19.1266825 },
    { lat: 48.2683352, lng: 19.1273752 },
    { lat: 48.2687982, lng: 19.1275973 },
    { lat: 48.2692303, lng: 19.1273749 },
    { lat: 48.2695746, lng: 19.1276142 },
    { lat: 48.2698935, lng: 19.1277006 },
    { lat: 48.2699501, lng: 19.1277494 },
    { lat: 48.2701682, lng: 19.1284384 },
    { lat: 48.2704358, lng: 19.1288109 },
    { lat: 48.2709378, lng: 19.1287501 },
    { lat: 48.2711717, lng: 19.1287845 },
    { lat: 48.2714147, lng: 19.12893 },
    { lat: 48.2716648, lng: 19.1292888 },
    { lat: 48.2721257, lng: 19.1292408 },
    { lat: 48.2724747, lng: 19.1290723 },
    { lat: 48.2726539, lng: 19.1290692 },
    { lat: 48.2732885, lng: 19.1292941 },
    { lat: 48.2735115, lng: 19.1295159 },
    { lat: 48.2737186, lng: 19.1300073 },
    { lat: 48.2742694, lng: 19.1308357 },
    { lat: 48.2746221, lng: 19.13074 },
    { lat: 48.2750556, lng: 19.1309779 },
    { lat: 48.275418, lng: 19.1314204 },
    { lat: 48.2760601, lng: 19.1313855 },
    { lat: 48.2761939, lng: 19.1314742 },
    { lat: 48.2766704, lng: 19.1318955 },
    { lat: 48.2767734, lng: 19.1325359 },
    { lat: 48.276866, lng: 19.1328356 },
    { lat: 48.2771709, lng: 19.1333168 },
    { lat: 48.2772234, lng: 19.1333088 },
    { lat: 48.2782354, lng: 19.1342777 },
    { lat: 48.2786243, lng: 19.1344813 },
    { lat: 48.2789192, lng: 19.1347219 },
    { lat: 48.2793783, lng: 19.1352694 },
    { lat: 48.2800593, lng: 19.1356797 },
    { lat: 48.2805898, lng: 19.1361679 },
    { lat: 48.2810942, lng: 19.136016 },
    { lat: 48.2813353, lng: 19.1360804 },
    { lat: 48.2816872, lng: 19.1363129 },
    { lat: 48.2820198, lng: 19.1362018 },
    { lat: 48.282402, lng: 19.1359751 },
    { lat: 48.2825994, lng: 19.136205 },
    { lat: 48.2827746, lng: 19.1365313 },
    { lat: 48.2829045, lng: 19.1365833 },
    { lat: 48.2831608, lng: 19.1363636 },
    { lat: 48.2832594, lng: 19.1360336 },
    { lat: 48.283644, lng: 19.1361942 },
    { lat: 48.2837715, lng: 19.1367392 },
    { lat: 48.2839043, lng: 19.1368249 },
    { lat: 48.2846512, lng: 19.1368852 },
    { lat: 48.284853, lng: 19.1372917 },
    { lat: 48.2853553, lng: 19.1376016 },
    { lat: 48.2853099, lng: 19.1377511 },
    { lat: 48.2856496, lng: 19.1382133 },
    { lat: 48.2856261, lng: 19.1384431 },
    { lat: 48.2857212, lng: 19.1384736 },
    { lat: 48.2858635, lng: 19.1384096 },
    { lat: 48.2860078, lng: 19.1389067 },
    { lat: 48.2862004, lng: 19.1390845 },
    { lat: 48.2864017, lng: 19.1394613 },
    { lat: 48.2866022, lng: 19.1394824 },
    { lat: 48.2867484, lng: 19.1393171 },
    { lat: 48.2867918, lng: 19.1394199 },
    { lat: 48.2867495, lng: 19.1396564 },
    { lat: 48.2869441, lng: 19.1397834 },
    { lat: 48.2872378, lng: 19.1398603 },
    { lat: 48.2873513, lng: 19.1400402 },
    { lat: 48.2874751, lng: 19.1400352 },
    { lat: 48.2877609, lng: 19.1405421 },
    { lat: 48.2879766, lng: 19.1405042 },
    { lat: 48.2882533, lng: 19.1406291 },
    { lat: 48.2883794, lng: 19.1410808 },
    { lat: 48.2885144, lng: 19.1412102 },
    { lat: 48.2886762, lng: 19.1416994 },
    { lat: 48.2885803, lng: 19.1417675 },
    { lat: 48.2885657, lng: 19.1422438 },
    { lat: 48.2886503, lng: 19.1429894 },
    { lat: 48.288754, lng: 19.1429226 },
    { lat: 48.2889546, lng: 19.1431477 },
    { lat: 48.2889648, lng: 19.1435575 },
    { lat: 48.2891926, lng: 19.1436967 },
    { lat: 48.2892698, lng: 19.1440714 },
    { lat: 48.2893772, lng: 19.1442591 },
    { lat: 48.2895417, lng: 19.144394 },
    { lat: 48.2897329, lng: 19.1443632 },
    { lat: 48.2898255, lng: 19.1445252 },
    { lat: 48.289728, lng: 19.1448731 },
    { lat: 48.2896948, lng: 19.1451848 },
    { lat: 48.2898736, lng: 19.1454686 },
    { lat: 48.2902286, lng: 19.1456254 },
    { lat: 48.2904069, lng: 19.1458572 },
    { lat: 48.2904152, lng: 19.146068 },
    { lat: 48.2903047, lng: 19.1460913 },
    { lat: 48.2902985, lng: 19.1461486 },
    { lat: 48.2904367, lng: 19.1463856 },
    { lat: 48.2904358, lng: 19.1464579 },
    { lat: 48.2906235, lng: 19.1466317 },
    { lat: 48.290639, lng: 19.1467627 },
    { lat: 48.2907354, lng: 19.146706 },
    { lat: 48.2909453, lng: 19.1469385 },
    { lat: 48.2908968, lng: 19.1470505 },
    { lat: 48.2909143, lng: 19.1471975 },
    { lat: 48.2908667, lng: 19.1472623 },
    { lat: 48.2908936, lng: 19.1474618 },
    { lat: 48.2910588, lng: 19.147431 },
    { lat: 48.2911509, lng: 19.1475347 },
    { lat: 48.2912248, lng: 19.1474337 },
    { lat: 48.291329, lng: 19.1474193 },
    { lat: 48.2913882, lng: 19.1475221 },
    { lat: 48.2914112, lng: 19.1476714 },
    { lat: 48.2913294, lng: 19.148066 },
    { lat: 48.2916035, lng: 19.148205 },
    { lat: 48.2916273, lng: 19.1483422 },
    { lat: 48.2917734, lng: 19.1485153 },
    { lat: 48.2917699, lng: 19.1489437 },
    { lat: 48.2918196, lng: 19.1490181 },
    { lat: 48.2920037, lng: 19.1490659 },
    { lat: 48.2920097, lng: 19.1492777 },
    { lat: 48.2918358, lng: 19.1493438 },
    { lat: 48.2918463, lng: 19.149531 },
    { lat: 48.2919142, lng: 19.149671 },
    { lat: 48.2920608, lng: 19.1498554 },
    { lat: 48.2921361, lng: 19.1498265 },
    { lat: 48.2921538, lng: 19.1496408 },
    { lat: 48.2924821, lng: 19.1497337 },
    { lat: 48.2925811, lng: 19.1496632 },
    { lat: 48.2926733, lng: 19.1497025 },
    { lat: 48.2926908, lng: 19.1497604 },
    { lat: 48.2926133, lng: 19.1499938 },
    { lat: 48.2927539, lng: 19.1501226 },
    { lat: 48.2927538, lng: 19.1503007 },
    { lat: 48.2928326, lng: 19.1504855 },
    { lat: 48.2930071, lng: 19.1505015 },
    { lat: 48.2930382, lng: 19.1505568 },
    { lat: 48.2930321, lng: 19.1507773 },
    { lat: 48.2929469, lng: 19.1509461 },
    { lat: 48.2929328, lng: 19.1512096 },
    { lat: 48.2930589, lng: 19.1512264 },
    { lat: 48.2930832, lng: 19.1514644 },
    { lat: 48.2933066, lng: 19.1518778 },
    { lat: 48.2942013, lng: 19.1524175 },
    { lat: 48.2942604, lng: 19.1525624 },
    { lat: 48.2943709, lng: 19.1526322 },
    { lat: 48.2946187, lng: 19.1524882 },
    { lat: 48.2946203, lng: 19.1527971 },
    { lat: 48.2949054, lng: 19.153045 },
    { lat: 48.2949991, lng: 19.1532954 },
    { lat: 48.2951329, lng: 19.1534487 },
    { lat: 48.2952425, lng: 19.1534061 },
    { lat: 48.2953487, lng: 19.1535036 },
    { lat: 48.2954239, lng: 19.1534963 },
    { lat: 48.2954709, lng: 19.1534433 },
    { lat: 48.29547, lng: 19.1533361 },
    { lat: 48.2956753, lng: 19.153498 },
    { lat: 48.2956726, lng: 19.1536909 },
    { lat: 48.2957623, lng: 19.1537751 },
    { lat: 48.2959366, lng: 19.153493 },
    { lat: 48.2960823, lng: 19.1535639 },
    { lat: 48.2962436, lng: 19.1535438 },
    { lat: 48.2964073, lng: 19.1532763 },
    { lat: 48.2964925, lng: 19.153356 },
    { lat: 48.2965239, lng: 19.1535129 },
    { lat: 48.2967126, lng: 19.153454 },
    { lat: 48.2967735, lng: 19.1537294 },
    { lat: 48.296876, lng: 19.1538391 },
    { lat: 48.297054, lng: 19.1538847 },
    { lat: 48.2970737, lng: 19.1538238 },
    { lat: 48.2973147, lng: 19.1538688 },
    { lat: 48.2974983, lng: 19.1538176 },
    { lat: 48.2977134, lng: 19.1539239 },
    { lat: 48.2978861, lng: 19.15391 },
    { lat: 48.2980023, lng: 19.1536612 },
    { lat: 48.2981469, lng: 19.1535959 },
    { lat: 48.2982298, lng: 19.1536571 },
    { lat: 48.2982161, lng: 19.1538098 },
    { lat: 48.2983296, lng: 19.1539845 },
    { lat: 48.2984569, lng: 19.1539409 },
    { lat: 48.2985274, lng: 19.1539943 },
    { lat: 48.2986139, lng: 19.1539433 },
    { lat: 48.2987806, lng: 19.1539888 },
    { lat: 48.2988435, lng: 19.1539382 },
    { lat: 48.2989343, lng: 19.1540403 },
    { lat: 48.2994829, lng: 19.1542359 },
    { lat: 48.3006615, lng: 19.1550502 },
    { lat: 48.3009163, lng: 19.1552263 },
    { lat: 48.3011953, lng: 19.1550993 },
    { lat: 48.3011979, lng: 19.1550179 },
    { lat: 48.3011997, lng: 19.1549873 },
    { lat: 48.3012306, lng: 19.1545628 },
    { lat: 48.301549, lng: 19.153594 },
    { lat: 48.3018686, lng: 19.1531987 },
    { lat: 48.3020415, lng: 19.1527496 },
    { lat: 48.3021814, lng: 19.1525272 },
    { lat: 48.3026293, lng: 19.1502126 },
    { lat: 48.3017969, lng: 19.1497513 },
    { lat: 48.3023642, lng: 19.1475069 },
    { lat: 48.3004839, lng: 19.1465337 },
    { lat: 48.3005817, lng: 19.1461382 },
    { lat: 48.3008867, lng: 19.1453589 },
    { lat: 48.3010965, lng: 19.1443176 },
    { lat: 48.301589, lng: 19.1429755 },
    { lat: 48.3024494, lng: 19.1399612 },
    { lat: 48.3027539, lng: 19.1385116 },
    { lat: 48.3025111, lng: 19.1385518 },
    { lat: 48.302841, lng: 19.1370242 },
    { lat: 48.3031962, lng: 19.1353207 },
  ],
  Sudince: [
    { lat: 48.1965101, lng: 18.8768582 },
    { lat: 48.1974295, lng: 18.8772522 },
    { lat: 48.1995275, lng: 18.8782793 },
    { lat: 48.2018683, lng: 18.8807422 },
    { lat: 48.2043636, lng: 18.882603 },
    { lat: 48.2043153, lng: 18.8826511 },
    { lat: 48.2043825, lng: 18.882622 },
    { lat: 48.204952, lng: 18.8832082 },
    { lat: 48.206369, lng: 18.8848223 },
    { lat: 48.2063702, lng: 18.8848222 },
    { lat: 48.2068338, lng: 18.8853677 },
    { lat: 48.2069183, lng: 18.8855374 },
    { lat: 48.2069027, lng: 18.8856061 },
    { lat: 48.2073291, lng: 18.8860895 },
    { lat: 48.2085274, lng: 18.8872318 },
    { lat: 48.2089502, lng: 18.887728 },
    { lat: 48.2092479, lng: 18.8878128 },
    { lat: 48.2097055, lng: 18.8882206 },
    { lat: 48.2097761, lng: 18.8881462 },
    { lat: 48.2100142, lng: 18.8885622 },
    { lat: 48.2102605, lng: 18.88881 },
    { lat: 48.2112525, lng: 18.889479 },
    { lat: 48.2116762, lng: 18.8896241 },
    { lat: 48.2120732, lng: 18.889429 },
    { lat: 48.2124133, lng: 18.8890769 },
    { lat: 48.2128506, lng: 18.88807 },
    { lat: 48.2146157, lng: 18.8848278 },
    { lat: 48.215278, lng: 18.8839057 },
    { lat: 48.2157903, lng: 18.8834093 },
    { lat: 48.2166896, lng: 18.8826876 },
    { lat: 48.2167767, lng: 18.8824776 },
    { lat: 48.2169301, lng: 18.8824788 },
    { lat: 48.2170889, lng: 18.8823009 },
    { lat: 48.2173068, lng: 18.8822164 },
    { lat: 48.2174928, lng: 18.8822789 },
    { lat: 48.2177795, lng: 18.8822441 },
    { lat: 48.2178217, lng: 18.8824927 },
    { lat: 48.2181185, lng: 18.882515 },
    { lat: 48.2183524, lng: 18.882614 },
    { lat: 48.2185511, lng: 18.8828323 },
    { lat: 48.2186769, lng: 18.8833014 },
    { lat: 48.2186779, lng: 18.8832984 },
    { lat: 48.2188543, lng: 18.8826555 },
    { lat: 48.2193174, lng: 18.8814386 },
    { lat: 48.219492, lng: 18.880721 },
    { lat: 48.2194683, lng: 18.8803514 },
    { lat: 48.2195427, lng: 18.8798969 },
    { lat: 48.2211034, lng: 18.874268 },
    { lat: 48.2211189, lng: 18.8724666 },
    { lat: 48.2203152, lng: 18.8716093 },
    { lat: 48.2208962, lng: 18.8703169 },
    { lat: 48.2206335, lng: 18.8702733 },
    { lat: 48.219443, lng: 18.8662205 },
    { lat: 48.2187666, lng: 18.8644704 },
    { lat: 48.2187053, lng: 18.8639041 },
    { lat: 48.2187097, lng: 18.8624135 },
    { lat: 48.2185795, lng: 18.8618842 },
    { lat: 48.2184674, lng: 18.8607291 },
    { lat: 48.2184437, lng: 18.8597814 },
    { lat: 48.2183232, lng: 18.8590724 },
    { lat: 48.2181952, lng: 18.8590379 },
    { lat: 48.2179456, lng: 18.859095 },
    { lat: 48.2175541, lng: 18.8587883 },
    { lat: 48.2174203, lng: 18.8588637 },
    { lat: 48.2172771, lng: 18.8590583 },
    { lat: 48.2172357, lng: 18.8592803 },
    { lat: 48.2170577, lng: 18.8593913 },
    { lat: 48.2164164, lng: 18.8588352 },
    { lat: 48.2160059, lng: 18.8589433 },
    { lat: 48.2156849, lng: 18.8596824 },
    { lat: 48.2153924, lng: 18.8596526 },
    { lat: 48.2150131, lng: 18.8594074 },
    { lat: 48.2145364, lng: 18.8593921 },
    { lat: 48.2136022, lng: 18.8594901 },
    { lat: 48.213322, lng: 18.8598248 },
    { lat: 48.2131205, lng: 18.8597674 },
    { lat: 48.2129022, lng: 18.85952 },
    { lat: 48.2124639, lng: 18.8596098 },
    { lat: 48.2122246, lng: 18.8595621 },
    { lat: 48.2117778, lng: 18.8589533 },
    { lat: 48.2114612, lng: 18.8587856 },
    { lat: 48.2103961, lng: 18.8586264 },
    { lat: 48.2102629, lng: 18.8585603 },
    { lat: 48.2097792, lng: 18.8587717 },
    { lat: 48.2092195, lng: 18.8583356 },
    { lat: 48.2089301, lng: 18.8581926 },
    { lat: 48.2079822, lng: 18.8581583 },
    { lat: 48.2074442, lng: 18.8584142 },
    { lat: 48.2067097, lng: 18.8583672 },
    { lat: 48.2065827, lng: 18.8584599 },
    { lat: 48.2066234, lng: 18.8586326 },
    { lat: 48.2065586, lng: 18.85897 },
    { lat: 48.2064239, lng: 18.8590024 },
    { lat: 48.2062451, lng: 18.8586864 },
    { lat: 48.2062659, lng: 18.858118 },
    { lat: 48.2060796, lng: 18.8578534 },
    { lat: 48.205827, lng: 18.8581336 },
    { lat: 48.2056865, lng: 18.8584435 },
    { lat: 48.2053146, lng: 18.8580996 },
    { lat: 48.2050167, lng: 18.8579707 },
    { lat: 48.204737, lng: 18.8581208 },
    { lat: 48.2046728, lng: 18.8578834 },
    { lat: 48.2045877, lng: 18.8577661 },
    { lat: 48.2040822, lng: 18.8575251 },
    { lat: 48.2038915, lng: 18.8578235 },
    { lat: 48.2036116, lng: 18.8579704 },
    { lat: 48.2035247, lng: 18.8581609 },
    { lat: 48.2030972, lng: 18.8594304 },
    { lat: 48.2030828, lng: 18.8597386 },
    { lat: 48.2028378, lng: 18.8603575 },
    { lat: 48.2030295, lng: 18.86159 },
    { lat: 48.202973, lng: 18.8619543 },
    { lat: 48.202974, lng: 18.8628372 },
    { lat: 48.2027712, lng: 18.863746 },
    { lat: 48.202756, lng: 18.8641483 },
    { lat: 48.2021967, lng: 18.8660655 },
    { lat: 48.202091, lng: 18.8666583 },
    { lat: 48.2014712, lng: 18.8675942 },
    { lat: 48.2002685, lng: 18.8697416 },
    { lat: 48.1996122, lng: 18.871212 },
    { lat: 48.1989612, lng: 18.8728477 },
    { lat: 48.1982052, lng: 18.8741585 },
    { lat: 48.1980914, lng: 18.8742011 },
    { lat: 48.1980527, lng: 18.8743474 },
    { lat: 48.1965179, lng: 18.8768305 },
    { lat: 48.1965101, lng: 18.8768582 },
  ],
  HontianskeNemce: [
    { lat: 48.331575, lng: 18.927535 },
    { lat: 48.330806, lng: 18.927085 },
    { lat: 48.329875, lng: 18.926751 },
    { lat: 48.329712, lng: 18.926926 },
    { lat: 48.329352, lng: 18.926758 },
    { lat: 48.328846, lng: 18.92708 },
    { lat: 48.328542, lng: 18.926412 },
    { lat: 48.328451, lng: 18.926389 },
    { lat: 48.328424, lng: 18.926317 },
    { lat: 48.32845, lng: 18.925936 },
    { lat: 48.328472, lng: 18.9255 },
    { lat: 48.328252, lng: 18.925296 },
    { lat: 48.328032, lng: 18.925091 },
    { lat: 48.3279928, lng: 18.9251229 },
    { lat: 48.327681, lng: 18.9254641 },
    { lat: 48.3271813, lng: 18.9263118 },
    { lat: 48.3270232, lng: 18.9266781 },
    { lat: 48.3266954, lng: 18.9277359 },
    { lat: 48.3265291, lng: 18.9280531 },
    { lat: 48.3262888, lng: 18.9283329 },
    { lat: 48.3259225, lng: 18.9289961 },
    { lat: 48.3254982, lng: 18.9301713 },
    { lat: 48.3252368, lng: 18.9310505 },
    { lat: 48.3247558, lng: 18.9322623 },
    { lat: 48.3237383, lng: 18.9339603 },
    { lat: 48.3230533, lng: 18.9346944 },
    { lat: 48.3227331, lng: 18.9356802 },
    { lat: 48.3225841, lng: 18.936315 },
    { lat: 48.3223508, lng: 18.9369517 },
    { lat: 48.3220547, lng: 18.9381222 },
    { lat: 48.3217259, lng: 18.9388528 },
    { lat: 48.3212086, lng: 18.939606 },
    { lat: 48.3207182, lng: 18.9401764 },
    { lat: 48.3201765, lng: 18.9405179 },
    { lat: 48.320094, lng: 18.9405136 },
    { lat: 48.3199275, lng: 18.9404096 },
    { lat: 48.3197829, lng: 18.9401202 },
    { lat: 48.3194861, lng: 18.9401156 },
    { lat: 48.3191569, lng: 18.9397455 },
    { lat: 48.3189595, lng: 18.9396114 },
    { lat: 48.3188563, lng: 18.9396219 },
    { lat: 48.3185288, lng: 18.9396768 },
    { lat: 48.3178258, lng: 18.9401064 },
    { lat: 48.3174622, lng: 18.9406451 },
    { lat: 48.3170741, lng: 18.9409454 },
    { lat: 48.3166934, lng: 18.940802 },
    { lat: 48.3154781, lng: 18.940805 },
    { lat: 48.3142201, lng: 18.9409654 },
    { lat: 48.3123418, lng: 18.9424184 },
    { lat: 48.3115322, lng: 18.9428991 },
    { lat: 48.3108245, lng: 18.9435416 },
    { lat: 48.3105721, lng: 18.9437018 },
    { lat: 48.3103835, lng: 18.9439574 },
    { lat: 48.3099243, lng: 18.9443923 },
    { lat: 48.3092582, lng: 18.9453168 },
    { lat: 48.3089875, lng: 18.9455798 },
    { lat: 48.3086547, lng: 18.9457517 },
    { lat: 48.3081479, lng: 18.9463323 },
    { lat: 48.3077127, lng: 18.9467272 },
    { lat: 48.3072472, lng: 18.9468872 },
    { lat: 48.3070058, lng: 18.947105 },
    { lat: 48.3066148, lng: 18.9476814 },
    { lat: 48.3059917, lng: 18.9483476 },
    { lat: 48.3056981, lng: 18.9485554 },
    { lat: 48.3055891, lng: 18.9485397 },
    { lat: 48.3053444, lng: 18.9487404 },
    { lat: 48.3042109, lng: 18.9492176 },
    { lat: 48.3039737, lng: 18.9494284 },
    { lat: 48.30378, lng: 18.9496973 },
    { lat: 48.302796, lng: 18.9505351 },
    { lat: 48.301621, lng: 18.9511704 },
    { lat: 48.2998848, lng: 18.9518148 },
    { lat: 48.2987236, lng: 18.9520606 },
    { lat: 48.2975952, lng: 18.9527889 },
    { lat: 48.2955251, lng: 18.9538237 },
    { lat: 48.2951847, lng: 18.9541547 },
    { lat: 48.2945374, lng: 18.9546362 },
    { lat: 48.2935821, lng: 18.9552885 },
    { lat: 48.2931417, lng: 18.9555205 },
    { lat: 48.2930821, lng: 18.9548415 },
    { lat: 48.2929267, lng: 18.954669 },
    { lat: 48.2925153, lng: 18.9546272 },
    { lat: 48.2916647, lng: 18.9547964 },
    { lat: 48.2913039, lng: 18.9547286 },
    { lat: 48.2909951, lng: 18.9552394 },
    { lat: 48.2907971, lng: 18.9553118 },
    { lat: 48.2906948, lng: 18.9556036 },
    { lat: 48.2904307, lng: 18.9557332 },
    { lat: 48.2902087, lng: 18.955711 },
    { lat: 48.2900982, lng: 18.9559755 },
    { lat: 48.2900432, lng: 18.9560041 },
    { lat: 48.2899632, lng: 18.9565731 },
    { lat: 48.289794, lng: 18.9566407 },
    { lat: 48.2896804, lng: 18.9568631 },
    { lat: 48.2894422, lng: 18.9569911 },
    { lat: 48.2894221, lng: 18.9571934 },
    { lat: 48.2893177, lng: 18.9572901 },
    { lat: 48.2892879, lng: 18.9572128 },
    { lat: 48.2892459, lng: 18.9572318 },
    { lat: 48.2891448, lng: 18.9575458 },
    { lat: 48.2888888, lng: 18.9579155 },
    { lat: 48.2886766, lng: 18.9579577 },
    { lat: 48.2885096, lng: 18.9580874 },
    { lat: 48.2883739, lng: 18.958057 },
    { lat: 48.2882882, lng: 18.9581883 },
    { lat: 48.2882649, lng: 18.9583514 },
    { lat: 48.2880329, lng: 18.9584843 },
    { lat: 48.2878561, lng: 18.9587323 },
    { lat: 48.2878396, lng: 18.9589814 },
    { lat: 48.2875734, lng: 18.9589607 },
    { lat: 48.2875783, lng: 18.9590968 },
    { lat: 48.2872575, lng: 18.9592647 },
    { lat: 48.2870919, lng: 18.959817 },
    { lat: 48.2870715, lng: 18.9600318 },
    { lat: 48.2868959, lng: 18.9601288 },
    { lat: 48.2868318, lng: 18.960441 },
    { lat: 48.2866098, lng: 18.9607052 },
    { lat: 48.2864049, lng: 18.9606017 },
    { lat: 48.2863059, lng: 18.9607133 },
    { lat: 48.2860388, lng: 18.961199 },
    { lat: 48.2856572, lng: 18.9616546 },
    { lat: 48.2852684, lng: 18.9624489 },
    { lat: 48.2848907, lng: 18.9633962 },
    { lat: 48.2841644, lng: 18.9637648 },
    { lat: 48.2837116, lng: 18.9641691 },
    { lat: 48.2836326, lng: 18.9641672 },
    { lat: 48.2834596, lng: 18.9643783 },
    { lat: 48.2833292, lng: 18.9644298 },
    { lat: 48.2828242, lng: 18.9649169 },
    { lat: 48.2820236, lng: 18.9659928 },
    { lat: 48.2812816, lng: 18.9666102 },
    { lat: 48.280815, lng: 18.9671397 },
    { lat: 48.2796493, lng: 18.9687286 },
    { lat: 48.2795703, lng: 18.9687076 },
    { lat: 48.27947, lng: 18.9688161 },
    { lat: 48.2789506, lng: 18.9695307 },
    { lat: 48.2786126, lng: 18.9697133 },
    { lat: 48.2785179, lng: 18.9696219 },
    { lat: 48.2782819, lng: 18.9697276 },
    { lat: 48.2781867, lng: 18.969895 },
    { lat: 48.2781203, lng: 18.9703362 },
    { lat: 48.2778405, lng: 18.9706859 },
    { lat: 48.2777536, lng: 18.9709231 },
    { lat: 48.2773047, lng: 18.9710763 },
    { lat: 48.2770077, lng: 18.9713112 },
    { lat: 48.2769131, lng: 18.9712668 },
    { lat: 48.2768692, lng: 18.9714258 },
    { lat: 48.2766813, lng: 18.9716276 },
    { lat: 48.2764566, lng: 18.9715122 },
    { lat: 48.2765, lng: 18.9717645 },
    { lat: 48.2764047, lng: 18.9718226 },
    { lat: 48.2761187, lng: 18.9718062 },
    { lat: 48.2760614, lng: 18.9719194 },
    { lat: 48.27594, lng: 18.9719945 },
    { lat: 48.2758752, lng: 18.9719369 },
    { lat: 48.2755394, lng: 18.9719638 },
    { lat: 48.275156, lng: 18.9722938 },
    { lat: 48.2750131, lng: 18.9720582 },
    { lat: 48.2748437, lng: 18.9724932 },
    { lat: 48.2745913, lng: 18.9723849 },
    { lat: 48.2742865, lng: 18.9724224 },
    { lat: 48.2740054, lng: 18.9728805 },
    { lat: 48.2736717, lng: 18.9729893 },
    { lat: 48.2729069, lng: 18.9728977 },
    { lat: 48.2726462, lng: 18.9732239 },
    { lat: 48.272194, lng: 18.9733541 },
    { lat: 48.2718917, lng: 18.9732816 },
    { lat: 48.2718915, lng: 18.9733244 },
    { lat: 48.2721429, lng: 18.9741361 },
    { lat: 48.2722087, lng: 18.974721 },
    { lat: 48.2725482, lng: 18.9761091 },
    { lat: 48.2725969, lng: 18.9767288 },
    { lat: 48.2725566, lng: 18.9776413 },
    { lat: 48.2722045, lng: 18.9805223 },
    { lat: 48.2724012, lng: 18.9830261 },
    { lat: 48.2725804, lng: 18.9842156 },
    { lat: 48.2725824, lng: 18.9845597 },
    { lat: 48.2727715, lng: 18.9855043 },
    { lat: 48.2729486, lng: 18.9871396 },
    { lat: 48.2730421, lng: 18.9874917 },
    { lat: 48.2733081, lng: 18.9880636 },
    { lat: 48.2729302, lng: 18.9890752 },
    { lat: 48.2728159, lng: 18.9898634 },
    { lat: 48.2727537, lng: 18.990015 },
    { lat: 48.2727883, lng: 18.9901834 },
    { lat: 48.2724626, lng: 18.9914035 },
    { lat: 48.2722991, lng: 18.9910917 },
    { lat: 48.272222, lng: 18.9910396 },
    { lat: 48.2719654, lng: 18.9909607 },
    { lat: 48.2716908, lng: 18.9909688 },
    { lat: 48.2711503, lng: 18.9913839 },
    { lat: 48.2702093, lng: 18.9924299 },
    { lat: 48.2698145, lng: 18.9926666 },
    { lat: 48.2698309, lng: 18.9929885 },
    { lat: 48.2697662, lng: 18.9940643 },
    { lat: 48.2700429, lng: 18.9944305 },
    { lat: 48.2701174, lng: 18.9952232 },
    { lat: 48.2700762, lng: 18.9953893 },
    { lat: 48.2702581, lng: 18.9958844 },
    { lat: 48.2702078, lng: 18.9961103 },
    { lat: 48.270383, lng: 18.9964109 },
    { lat: 48.2703408, lng: 18.996644 },
    { lat: 48.270401, lng: 18.9968742 },
    { lat: 48.2702733, lng: 18.9983038 },
    { lat: 48.2701493, lng: 18.9988389 },
    { lat: 48.2700435, lng: 18.9987749 },
    { lat: 48.2690337, lng: 19.0002628 },
    { lat: 48.2688105, lng: 19.0012835 },
    { lat: 48.2680293, lng: 19.0026592 },
    { lat: 48.267141, lng: 19.0046788 },
    { lat: 48.2658275, lng: 19.006204 },
    { lat: 48.2644995, lng: 19.0079127 },
    { lat: 48.2633541, lng: 19.0094448 },
    { lat: 48.2623713, lng: 19.0109841 },
    { lat: 48.2628606, lng: 19.0119594 },
    { lat: 48.263513, lng: 19.0132177 },
    { lat: 48.2646035, lng: 19.0149215 },
    { lat: 48.2653899, lng: 19.015757 },
    { lat: 48.2656358, lng: 19.0157657 },
    { lat: 48.2659928, lng: 19.0163935 },
    { lat: 48.2668067, lng: 19.0179861 },
    { lat: 48.2671168, lng: 19.01872 },
    { lat: 48.2681835, lng: 19.0206531 },
    { lat: 48.2687702, lng: 19.0216182 },
    { lat: 48.2692722, lng: 19.0221343 },
    { lat: 48.2720637, lng: 19.0242615 },
    { lat: 48.2735392, lng: 19.0254915 },
    { lat: 48.2740647, lng: 19.0256146 },
    { lat: 48.2751346, lng: 19.02639 },
    { lat: 48.2765723, lng: 19.0272619 },
    { lat: 48.2780267, lng: 19.0279689 },
    { lat: 48.2784478, lng: 19.0285361 },
    { lat: 48.2787274, lng: 19.0287797 },
    { lat: 48.2799965, lng: 19.029598 },
    { lat: 48.2805013, lng: 19.0298811 },
    { lat: 48.2817539, lng: 19.0298564 },
    { lat: 48.2828241, lng: 19.0305729 },
    { lat: 48.2844716, lng: 19.0311812 },
    { lat: 48.2844753, lng: 19.0311418 },
    { lat: 48.2845839, lng: 19.0292324 },
    { lat: 48.2847072, lng: 19.028133 },
    { lat: 48.2848961, lng: 19.0269415 },
    { lat: 48.2850766, lng: 19.026145 },
    { lat: 48.285232, lng: 19.0250253 },
    { lat: 48.2857635, lng: 19.0257273 },
    { lat: 48.2866901, lng: 19.0262583 },
    { lat: 48.2869344, lng: 19.0264496 },
    { lat: 48.2873073, lng: 19.0266231 },
    { lat: 48.2877926, lng: 19.026633 },
    { lat: 48.2909935, lng: 19.0260511 },
    { lat: 48.2923838, lng: 19.0251237 },
    { lat: 48.2933331, lng: 19.0248534 },
    { lat: 48.2939184, lng: 19.0243456 },
    { lat: 48.2949531, lng: 19.022872 },
    { lat: 48.2958831, lng: 19.02185 },
    { lat: 48.2958708, lng: 19.0218071 },
    { lat: 48.2959646, lng: 19.0217008 },
    { lat: 48.2966695, lng: 19.0215019 },
    { lat: 48.2968557, lng: 19.0210614 },
    { lat: 48.2973454, lng: 19.0206681 },
    { lat: 48.2974256, lng: 19.0206937 },
    { lat: 48.2976822, lng: 19.0204608 },
    { lat: 48.2978143, lng: 19.0202662 },
    { lat: 48.2981169, lng: 19.0194963 },
    { lat: 48.2982007, lng: 19.0194577 },
    { lat: 48.2984449, lng: 19.0189872 },
    { lat: 48.2984985, lng: 19.0186656 },
    { lat: 48.2984529, lng: 19.0185848 },
    { lat: 48.2983602, lng: 19.0185844 },
    { lat: 48.2983553, lng: 19.0183987 },
    { lat: 48.2985196, lng: 19.0179688 },
    { lat: 48.2986611, lng: 19.0179353 },
    { lat: 48.2987314, lng: 19.0177007 },
    { lat: 48.2988453, lng: 19.0175662 },
    { lat: 48.2991257, lng: 19.0174029 },
    { lat: 48.2992428, lng: 19.0172461 },
    { lat: 48.2996095, lng: 19.0169872 },
    { lat: 48.2997449, lng: 19.0166977 },
    { lat: 48.3000933, lng: 19.0166084 },
    { lat: 48.3001511, lng: 19.0161924 },
    { lat: 48.3006598, lng: 19.0155097 },
    { lat: 48.3008776, lng: 19.0153365 },
    { lat: 48.3009371, lng: 19.0150655 },
    { lat: 48.3011224, lng: 19.0146265 },
    { lat: 48.3015471, lng: 19.0142013 },
    { lat: 48.30178, lng: 19.0136552 },
    { lat: 48.3018955, lng: 19.0135574 },
    { lat: 48.3020036, lng: 19.0133556 },
    { lat: 48.3023826, lng: 19.0130896 },
    { lat: 48.3025388, lng: 19.0126578 },
    { lat: 48.3027848, lng: 19.0126407 },
    { lat: 48.3028672, lng: 19.0125321 },
    { lat: 48.3030376, lng: 19.0126454 },
    { lat: 48.3032933, lng: 19.0125497 },
    { lat: 48.3032967, lng: 19.0124862 },
    { lat: 48.3034685, lng: 19.0125605 },
    { lat: 48.3036093, lng: 19.0123146 },
    { lat: 48.3037567, lng: 19.012286 },
    { lat: 48.3037668, lng: 19.0121503 },
    { lat: 48.3038454, lng: 19.0121201 },
    { lat: 48.3038764, lng: 19.012 },
    { lat: 48.3039618, lng: 19.0119758 },
    { lat: 48.3040021, lng: 19.0117682 },
    { lat: 48.3044374, lng: 19.0111059 },
    { lat: 48.3045614, lng: 19.0108357 },
    { lat: 48.3048815, lng: 19.0104577 },
    { lat: 48.3049083, lng: 19.0103856 },
    { lat: 48.304864, lng: 19.0102476 },
    { lat: 48.3050891, lng: 19.0099963 },
    { lat: 48.3053316, lng: 19.0099282 },
    { lat: 48.3054978, lng: 19.0096932 },
    { lat: 48.3056113, lng: 19.0096671 },
    { lat: 48.3065805, lng: 19.0089908 },
    { lat: 48.307835, lng: 19.0087186 },
    { lat: 48.3084758, lng: 19.0083949 },
    { lat: 48.3086515, lng: 19.0083887 },
    { lat: 48.3088243, lng: 19.008149 },
    { lat: 48.3088727, lng: 19.0079288 },
    { lat: 48.3089444, lng: 19.0079415 },
    { lat: 48.308996, lng: 19.0078042 },
    { lat: 48.3093474, lng: 19.0074202 },
    { lat: 48.3096445, lng: 19.0067628 },
    { lat: 48.3099025, lng: 19.0065155 },
    { lat: 48.3100155, lng: 19.0063447 },
    { lat: 48.3100303, lng: 19.006236 },
    { lat: 48.3105617, lng: 19.0054244 },
    { lat: 48.3112508, lng: 19.0046064 },
    { lat: 48.3118324, lng: 19.0037167 },
    { lat: 48.311911, lng: 19.003576 },
    { lat: 48.3118904, lng: 19.00346 },
    { lat: 48.3121355, lng: 19.0030938 },
    { lat: 48.3123043, lng: 19.0027103 },
    { lat: 48.3121668, lng: 19.0025107 },
    { lat: 48.3121877, lng: 19.0024346 },
    { lat: 48.3129313, lng: 19.0015813 },
    { lat: 48.3133493, lng: 19.0011977 },
    { lat: 48.3135076, lng: 19.0007893 },
    { lat: 48.313337, lng: 19.0005392 },
    { lat: 48.313736, lng: 18.9998454 },
    { lat: 48.3134894, lng: 18.9993465 },
    { lat: 48.3140873, lng: 18.9984209 },
    { lat: 48.3142898, lng: 18.9975951 },
    { lat: 48.3142861, lng: 18.9971007 },
    { lat: 48.3144359, lng: 18.9964328 },
    { lat: 48.3144933, lng: 18.995834 },
    { lat: 48.3145695, lng: 18.9956241 },
    { lat: 48.3146596, lng: 18.9955146 },
    { lat: 48.3153344, lng: 18.9953013 },
    { lat: 48.315554, lng: 18.9951649 },
    { lat: 48.315722, lng: 18.9949423 },
    { lat: 48.3157694, lng: 18.9947463 },
    { lat: 48.3158849, lng: 18.9942439 },
    { lat: 48.3157009, lng: 18.9935548 },
    { lat: 48.3155593, lng: 18.9933206 },
    { lat: 48.3153894, lng: 18.9927977 },
    { lat: 48.3159432, lng: 18.9924484 },
    { lat: 48.3165076, lng: 18.9923281 },
    { lat: 48.3166385, lng: 18.9922624 },
    { lat: 48.3167004, lng: 18.9921526 },
    { lat: 48.3167868, lng: 18.9918119 },
    { lat: 48.3168333, lng: 18.9899808 },
    { lat: 48.3168926, lng: 18.9897735 },
    { lat: 48.3173953, lng: 18.9889507 },
    { lat: 48.3175504, lng: 18.9884997 },
    { lat: 48.3180069, lng: 18.9877038 },
    { lat: 48.3180351, lng: 18.9872379 },
    { lat: 48.3177842, lng: 18.9871814 },
    { lat: 48.3177472, lng: 18.9871651 },
    { lat: 48.3178529, lng: 18.9866949 },
    { lat: 48.3180466, lng: 18.985939 },
    { lat: 48.3181913, lng: 18.9855484 },
    { lat: 48.3183561, lng: 18.9853743 },
    { lat: 48.3185382, lng: 18.9846364 },
    { lat: 48.3188878, lng: 18.9838393 },
    { lat: 48.3191243, lng: 18.9831497 },
    { lat: 48.3192539, lng: 18.9829959 },
    { lat: 48.3193777, lng: 18.9829484 },
    { lat: 48.319462, lng: 18.9830071 },
    { lat: 48.3197099, lng: 18.9828933 },
    { lat: 48.3196738, lng: 18.9819455 },
    { lat: 48.3195936, lng: 18.9814574 },
    { lat: 48.3193414, lng: 18.9788146 },
    { lat: 48.3190033, lng: 18.977164 },
    { lat: 48.3196975, lng: 18.9770452 },
    { lat: 48.3204399, lng: 18.977277 },
    { lat: 48.3211635, lng: 18.977405 },
    { lat: 48.3224461, lng: 18.9767085 },
    { lat: 48.3229295, lng: 18.976593 },
    { lat: 48.3235234, lng: 18.9765857 },
    { lat: 48.3259325, lng: 18.9771758 },
    { lat: 48.3261698, lng: 18.9773395 },
    { lat: 48.3265886, lng: 18.9780354 },
    { lat: 48.3269598, lng: 18.9784565 },
    { lat: 48.3272249, lng: 18.9786822 },
    { lat: 48.3273886, lng: 18.9785989 },
    { lat: 48.3277803, lng: 18.9780605 },
    { lat: 48.3284337, lng: 18.9771622 },
    { lat: 48.3291972, lng: 18.9752879 },
    { lat: 48.3303883, lng: 18.9734445 },
    { lat: 48.330712, lng: 18.9724318 },
    { lat: 48.3308752, lng: 18.9715669 },
    { lat: 48.3309946, lng: 18.9706354 },
    { lat: 48.3311193, lng: 18.9685804 },
    { lat: 48.3311647, lng: 18.9684508 },
    { lat: 48.3313623, lng: 18.9676627 },
    { lat: 48.3317155, lng: 18.9669017 },
    { lat: 48.3324152, lng: 18.9655465 },
    { lat: 48.3326295, lng: 18.9653291 },
    { lat: 48.3330122, lng: 18.9652028 },
    { lat: 48.3332681, lng: 18.9648537 },
    { lat: 48.3334374, lng: 18.9643257 },
    { lat: 48.3335885, lng: 18.9636446 },
    { lat: 48.3337012, lng: 18.9624767 },
    { lat: 48.3338077, lng: 18.9620009 },
    { lat: 48.3348667, lng: 18.960337 },
    { lat: 48.3351782, lng: 18.9595917 },
    { lat: 48.3353069, lng: 18.9587834 },
    { lat: 48.3353846, lng: 18.9568888 },
    { lat: 48.335431, lng: 18.9567155 },
    { lat: 48.3356875, lng: 18.9564187 },
    { lat: 48.3360983, lng: 18.9561817 },
    { lat: 48.3362625, lng: 18.956021 },
    { lat: 48.336949, lng: 18.954503 },
    { lat: 48.3371326, lng: 18.9542265 },
    { lat: 48.337172, lng: 18.954212 },
    { lat: 48.337028, lng: 18.953996 },
    { lat: 48.337005, lng: 18.953962 },
    { lat: 48.336993, lng: 18.953943 },
    { lat: 48.336951, lng: 18.953881 },
    { lat: 48.336804, lng: 18.953731 },
    { lat: 48.336641, lng: 18.953741 },
    { lat: 48.336298, lng: 18.95316 },
    { lat: 48.336254, lng: 18.95304 },
    { lat: 48.33625, lng: 18.953023 },
    { lat: 48.336204, lng: 18.952849 },
    { lat: 48.336211, lng: 18.952734 },
    { lat: 48.336038, lng: 18.952701 },
    { lat: 48.335893, lng: 18.952545 },
    { lat: 48.335757, lng: 18.952397 },
    { lat: 48.335648, lng: 18.952205 },
    { lat: 48.3356, lng: 18.952043 },
    { lat: 48.335491, lng: 18.951863 },
    { lat: 48.33542, lng: 18.951608 },
    { lat: 48.335293, lng: 18.951295 },
    { lat: 48.335216, lng: 18.951199 },
    { lat: 48.33486, lng: 18.95066 },
    { lat: 48.334784, lng: 18.950366 },
    { lat: 48.334642, lng: 18.950076 },
    { lat: 48.334521, lng: 18.949901 },
    { lat: 48.334503, lng: 18.949776 },
    { lat: 48.334193, lng: 18.94934 },
    { lat: 48.334107, lng: 18.949034 },
    { lat: 48.33413, lng: 18.948795 },
    { lat: 48.334126, lng: 18.948599 },
    { lat: 48.334098, lng: 18.948364 },
    { lat: 48.334103, lng: 18.94788 },
    { lat: 48.334189, lng: 18.947567 },
    { lat: 48.33425, lng: 18.947103 },
    { lat: 48.334392, lng: 18.94666 },
    { lat: 48.334529, lng: 18.946355 },
    { lat: 48.334693, lng: 18.946272 },
    { lat: 48.334773, lng: 18.945964 },
    { lat: 48.334771, lng: 18.945632 },
    { lat: 48.33515, lng: 18.944918 },
    { lat: 48.335323, lng: 18.944538 },
    { lat: 48.335311, lng: 18.944289 },
    { lat: 48.335561, lng: 18.943826 },
    { lat: 48.335679, lng: 18.943696 },
    { lat: 48.335767, lng: 18.943888 },
    { lat: 48.33592, lng: 18.943543 },
    { lat: 48.336095, lng: 18.943185 },
    { lat: 48.336312, lng: 18.942787 },
    { lat: 48.336526, lng: 18.942132 },
    { lat: 48.336642, lng: 18.941786 },
    { lat: 48.336803, lng: 18.941498 },
    { lat: 48.336725, lng: 18.941423 },
    { lat: 48.337025, lng: 18.940855 },
    { lat: 48.337131, lng: 18.94055 },
    { lat: 48.337183, lng: 18.940457 },
    { lat: 48.336715, lng: 18.939564 },
    { lat: 48.336184, lng: 18.938552 },
    { lat: 48.335634, lng: 18.937155 },
    { lat: 48.335635, lng: 18.936631 },
    { lat: 48.335608, lng: 18.936559 },
    { lat: 48.335546, lng: 18.936094 },
    { lat: 48.335512, lng: 18.935561 },
    { lat: 48.335498, lng: 18.935485 },
    { lat: 48.335382, lng: 18.935108 },
    { lat: 48.335361, lng: 18.934904 },
    { lat: 48.335238, lng: 18.934319 },
    { lat: 48.335208, lng: 18.934177 },
    { lat: 48.33515, lng: 18.933632 },
    { lat: 48.335045, lng: 18.933268 },
    { lat: 48.3349, lng: 18.932859 },
    { lat: 48.334765, lng: 18.93252 },
    { lat: 48.334584, lng: 18.932141 },
    { lat: 48.33438, lng: 18.931587 },
    { lat: 48.334253, lng: 18.931278 },
    { lat: 48.334165, lng: 18.93109 },
    { lat: 48.333866, lng: 18.930544 },
    { lat: 48.333619, lng: 18.930067 },
    { lat: 48.333483, lng: 18.92964 },
    { lat: 48.333446, lng: 18.929473 },
    { lat: 48.333408, lng: 18.929307 },
    { lat: 48.332969, lng: 18.928797 },
    { lat: 48.332669, lng: 18.928509 },
    { lat: 48.332295, lng: 18.927969 },
    { lat: 48.332067, lng: 18.927862 },
    { lat: 48.331761, lng: 18.92783 },
    { lat: 48.331575, lng: 18.927535 },
  ],
  Žibritov: [
    { lat: 48.411295, lng: 18.985252 },
    { lat: 48.411136, lng: 18.984326 },
    { lat: 48.411136, lng: 18.984278 },
    { lat: 48.411136, lng: 18.983529 },
    { lat: 48.41114, lng: 18.983394 },
    { lat: 48.410881, lng: 18.982994 },
    { lat: 48.410412, lng: 18.98254 },
    { lat: 48.410201, lng: 18.982135 },
    { lat: 48.410118, lng: 18.981857 },
    { lat: 48.410102, lng: 18.981298 },
    { lat: 48.410146, lng: 18.98066 },
    { lat: 48.410257, lng: 18.980188 },
    { lat: 48.410433, lng: 18.979649 },
    { lat: 48.410373, lng: 18.979094 },
    { lat: 48.409969, lng: 18.979 },
    { lat: 48.409818, lng: 18.97883 },
    { lat: 48.409258, lng: 18.978214 },
    { lat: 48.409123, lng: 18.978137 },
    { lat: 48.408948, lng: 18.978003 },
    { lat: 48.408677, lng: 18.977794 },
    { lat: 48.408183, lng: 18.977685 },
    { lat: 48.408024, lng: 18.977607 },
    { lat: 48.407777, lng: 18.97753 },
    { lat: 48.407602, lng: 18.977365 },
    { lat: 48.407558, lng: 18.97724 },
    { lat: 48.407531, lng: 18.976781 },
    { lat: 48.407468, lng: 18.976714 },
    { lat: 48.407433, lng: 18.976644 },
    { lat: 48.407293, lng: 18.976599 },
    { lat: 48.407139, lng: 18.976497 },
    { lat: 48.407084, lng: 18.976424 },
    { lat: 48.406694, lng: 18.976019 },
    { lat: 48.406532, lng: 18.975938 },
    { lat: 48.406469, lng: 18.975852 },
    { lat: 48.406285, lng: 18.975439 },
    { lat: 48.406234, lng: 18.975387 },
    { lat: 48.406027, lng: 18.975297 },
    { lat: 48.405893, lng: 18.975204 },
    { lat: 48.405788, lng: 18.975078 },
    { lat: 48.405288, lng: 18.974543 },
    { lat: 48.405273, lng: 18.973794 },
    { lat: 48.405307, lng: 18.973411 },
    { lat: 48.405286, lng: 18.973231 },
    { lat: 48.405251, lng: 18.97311 },
    { lat: 48.405092, lng: 18.972741 },
    { lat: 48.405029, lng: 18.972565 },
    { lat: 48.404903, lng: 18.972164 },
    { lat: 48.404869, lng: 18.971991 },
    { lat: 48.404834, lng: 18.971812 },
    { lat: 48.404779, lng: 18.971581 },
    { lat: 48.404688, lng: 18.971313 },
    { lat: 48.404635, lng: 18.970993 },
    { lat: 48.404564, lng: 18.970811 },
    { lat: 48.404458, lng: 18.97067 },
    { lat: 48.404349, lng: 18.970519 },
    { lat: 48.404255, lng: 18.970387 },
    { lat: 48.404084, lng: 18.970226 },
    { lat: 48.404089, lng: 18.970205 },
    { lat: 48.404091, lng: 18.970199 },
    { lat: 48.404205, lng: 18.969543 },
    { lat: 48.404299, lng: 18.969158 },
    { lat: 48.404528, lng: 18.968276 },
    { lat: 48.404546, lng: 18.968213 },
    { lat: 48.404418, lng: 18.967404 },
    { lat: 48.404425, lng: 18.967178 },
    { lat: 48.404413, lng: 18.966952 },
    { lat: 48.404353, lng: 18.966754 },
    { lat: 48.404103, lng: 18.965707 },
    { lat: 48.403682, lng: 18.965219 },
    { lat: 48.403633, lng: 18.964986 },
    { lat: 48.403581, lng: 18.964757 },
    { lat: 48.40354, lng: 18.964072 },
    { lat: 48.403529, lng: 18.963933 },
    { lat: 48.403471, lng: 18.963717 },
    { lat: 48.403456, lng: 18.963186 },
    { lat: 48.403164, lng: 18.961791 },
    { lat: 48.40305, lng: 18.961276 },
    { lat: 48.402654, lng: 18.960641 },
    { lat: 48.402383, lng: 18.960503 },
    { lat: 48.402176, lng: 18.96049 },
    { lat: 48.401892, lng: 18.960271 },
    { lat: 48.400925, lng: 18.960962 },
    { lat: 48.400091, lng: 18.961606 },
    { lat: 48.400061, lng: 18.961628 },
    { lat: 48.400006, lng: 18.96167 },
    { lat: 48.399975, lng: 18.961694 },
    { lat: 48.399884, lng: 18.961761 },
    { lat: 48.399758, lng: 18.961717 },
    { lat: 48.398264, lng: 18.961222 },
    { lat: 48.397532, lng: 18.961144 },
    { lat: 48.397009, lng: 18.960781 },
    { lat: 48.396225, lng: 18.960229 },
    { lat: 48.394976, lng: 18.959284 },
    { lat: 48.394949, lng: 18.959264 },
    { lat: 48.394866, lng: 18.959201 },
    { lat: 48.393944, lng: 18.958507 },
    { lat: 48.392324, lng: 18.957628 },
    { lat: 48.39085, lng: 18.956956 },
    { lat: 48.390448, lng: 18.955677 },
    { lat: 48.390447, lng: 18.955672 },
    { lat: 48.390426, lng: 18.955616 },
    { lat: 48.389928, lng: 18.955433 },
    { lat: 48.389202, lng: 18.955374 },
    { lat: 48.389184, lng: 18.955365 },
    { lat: 48.389001, lng: 18.955436 },
    { lat: 48.388722, lng: 18.955465 },
    { lat: 48.388484, lng: 18.955477 },
    { lat: 48.388213, lng: 18.955483 },
    { lat: 48.387985, lng: 18.955575 },
    { lat: 48.387801, lng: 18.955641 },
    { lat: 48.387562, lng: 18.955748 },
    { lat: 48.38727, lng: 18.955694 },
    { lat: 48.386946, lng: 18.955566 },
    { lat: 48.386766, lng: 18.955675 },
    { lat: 48.386549, lng: 18.955741 },
    { lat: 48.386368, lng: 18.955793 },
    { lat: 48.386116, lng: 18.955877 },
    { lat: 48.385905, lng: 18.955973 },
    { lat: 48.385509, lng: 18.956238 },
    { lat: 48.385312, lng: 18.956446 },
    { lat: 48.385156, lng: 18.95661 },
    { lat: 48.38511, lng: 18.956636 },
    { lat: 48.385094, lng: 18.956645 },
    { lat: 48.385085, lng: 18.956665 },
    { lat: 48.384578, lng: 18.957719 },
    { lat: 48.383147, lng: 18.958643 },
    { lat: 48.382186, lng: 18.958962 },
    { lat: 48.381776, lng: 18.957667 },
    { lat: 48.381742, lng: 18.956816 },
    { lat: 48.381805, lng: 18.956011 },
    { lat: 48.381933, lng: 18.954722 },
    { lat: 48.382291, lng: 18.954115 },
    { lat: 48.38249, lng: 18.95359 },
    { lat: 48.382698, lng: 18.953046 },
    { lat: 48.382879, lng: 18.952352 },
    { lat: 48.383516, lng: 18.951406 },
    { lat: 48.384034, lng: 18.950489 },
    { lat: 48.384415, lng: 18.949637 },
    { lat: 48.384569, lng: 18.949293 },
    { lat: 48.384638, lng: 18.949132 },
    { lat: 48.385449, lng: 18.946494 },
    { lat: 48.386924, lng: 18.944121 },
    { lat: 48.38634, lng: 18.942936 },
    { lat: 48.385427, lng: 18.942604 },
    { lat: 48.383277, lng: 18.941787 },
    { lat: 48.382608, lng: 18.943225 },
    { lat: 48.381861, lng: 18.943835 },
    { lat: 48.381303, lng: 18.94445 },
    { lat: 48.380839, lng: 18.944733 },
    { lat: 48.380624, lng: 18.945113 },
    { lat: 48.380351, lng: 18.945535 },
    { lat: 48.37996, lng: 18.946143 },
    { lat: 48.379659, lng: 18.948557 },
    { lat: 48.37989, lng: 18.94965 },
    { lat: 48.379932, lng: 18.949889 },
    { lat: 48.379341, lng: 18.95101 },
    { lat: 48.378637, lng: 18.951468 },
    { lat: 48.377793, lng: 18.951808 },
    { lat: 48.377265, lng: 18.952077 },
    { lat: 48.376892, lng: 18.952643 },
    { lat: 48.376436, lng: 18.953416 },
    { lat: 48.376845, lng: 18.955003 },
    { lat: 48.3797242, lng: 18.958895 },
    { lat: 48.3798738, lng: 18.9590207 },
    { lat: 48.3802454, lng: 18.9596514 },
    { lat: 48.3803695, lng: 18.9599609 },
    { lat: 48.3804699, lng: 18.9600266 },
    { lat: 48.3811853, lng: 18.9614933 },
    { lat: 48.3816178, lng: 18.9624972 },
    { lat: 48.3821371, lng: 18.9627739 },
    { lat: 48.3824294, lng: 18.9631524 },
    { lat: 48.3828582, lng: 18.9643057 },
    { lat: 48.3830457, lng: 18.9655699 },
    { lat: 48.3825494, lng: 18.9659371 },
    { lat: 48.3820814, lng: 18.966821 },
    { lat: 48.3812625, lng: 18.9680912 },
    { lat: 48.3808893, lng: 18.9687773 },
    { lat: 48.3803831, lng: 18.9691318 },
    { lat: 48.3803383, lng: 18.9695764 },
    { lat: 48.3800497, lng: 18.9700643 },
    { lat: 48.3795211, lng: 18.9712148 },
    { lat: 48.3790333, lng: 18.9720158 },
    { lat: 48.3776651, lng: 18.973353 },
    { lat: 48.3768965, lng: 18.9739124 },
    { lat: 48.376047, lng: 18.9743443 },
    { lat: 48.3755699, lng: 18.9745152 },
    { lat: 48.3749424, lng: 18.9746367 },
    { lat: 48.3747385, lng: 18.974751 },
    { lat: 48.3743929, lng: 18.9751606 },
    { lat: 48.3739147, lng: 18.9760161 },
    { lat: 48.3736405, lng: 18.9763469 },
    { lat: 48.3729034, lng: 18.9774772 },
    { lat: 48.3722405, lng: 18.9789368 },
    { lat: 48.3717871, lng: 18.9793524 },
    { lat: 48.3715698, lng: 18.9794696 },
    { lat: 48.3707206, lng: 18.9797088 },
    { lat: 48.3699706, lng: 18.9801478 },
    { lat: 48.3699769, lng: 18.9804464 },
    { lat: 48.3700325, lng: 18.9833251 },
    { lat: 48.3703958, lng: 18.9837957 },
    { lat: 48.3707673, lng: 18.9839406 },
    { lat: 48.3714801, lng: 18.9840101 },
    { lat: 48.3719161, lng: 18.9843129 },
    { lat: 48.3726155, lng: 18.9844528 },
    { lat: 48.3736611, lng: 18.983884 },
    { lat: 48.3738343, lng: 18.9840978 },
    { lat: 48.3750193, lng: 18.9848511 },
    { lat: 48.3759685, lng: 18.9857283 },
    { lat: 48.3761605, lng: 18.9858488 },
    { lat: 48.3763822, lng: 18.9858829 },
    { lat: 48.3765557, lng: 18.9861474 },
    { lat: 48.3767657, lng: 18.9866623 },
    { lat: 48.3767897, lng: 18.9877069 },
    { lat: 48.3768892, lng: 18.9886629 },
    { lat: 48.3767776, lng: 18.9896815 },
    { lat: 48.3768165, lng: 18.9901342 },
    { lat: 48.3769128, lng: 18.9906743 },
    { lat: 48.3778601, lng: 18.9937575 },
    { lat: 48.3775144, lng: 18.9945642 },
    { lat: 48.3772405, lng: 18.9948322 },
    { lat: 48.3771026, lng: 18.9951782 },
    { lat: 48.3771732, lng: 18.995368 },
    { lat: 48.3774959, lng: 18.9972567 },
    { lat: 48.3776056, lng: 18.9975084 },
    { lat: 48.3784646, lng: 18.9985714 },
    { lat: 48.378584, lng: 18.998878 },
    { lat: 48.3795807, lng: 18.9998057 },
    { lat: 48.3801589, lng: 19.0012663 },
    { lat: 48.3812142, lng: 19.0033093 },
    { lat: 48.3813128, lng: 19.0030749 },
    { lat: 48.3814562, lng: 19.0029436 },
    { lat: 48.381668, lng: 19.00303 },
    { lat: 48.3818023, lng: 19.0029007 },
    { lat: 48.3821173, lng: 19.0017318 },
    { lat: 48.3823445, lng: 19.0018547 },
    { lat: 48.3826944, lng: 19.0015071 },
    { lat: 48.3828894, lng: 19.001438 },
    { lat: 48.3829314, lng: 19.0013115 },
    { lat: 48.3830022, lng: 19.0013286 },
    { lat: 48.3830653, lng: 19.0012632 },
    { lat: 48.3830847, lng: 19.0010701 },
    { lat: 48.3832046, lng: 19.0009418 },
    { lat: 48.3832939, lng: 19.0009699 },
    { lat: 48.3833656, lng: 19.0008918 },
    { lat: 48.3832989, lng: 19.0008033 },
    { lat: 48.3833403, lng: 19.000684 },
    { lat: 48.3834695, lng: 19.0006962 },
    { lat: 48.3836946, lng: 19.0004217 },
    { lat: 48.3838818, lng: 18.9997168 },
    { lat: 48.3838965, lng: 18.9993604 },
    { lat: 48.3839694, lng: 18.9992407 },
    { lat: 48.3840799, lng: 18.9986867 },
    { lat: 48.3841869, lng: 18.9986166 },
    { lat: 48.3842969, lng: 18.9983646 },
    { lat: 48.3845118, lng: 18.9975362 },
    { lat: 48.3844913, lng: 18.9972211 },
    { lat: 48.3848192, lng: 18.9966216 },
    { lat: 48.3848218, lng: 18.9967136 },
    { lat: 48.3849685, lng: 18.9966709 },
    { lat: 48.3861281, lng: 18.9967748 },
    { lat: 48.3863836, lng: 18.996521 },
    { lat: 48.3868148, lng: 18.9962651 },
    { lat: 48.3869847, lng: 18.9963442 },
    { lat: 48.3873007, lng: 18.996175 },
    { lat: 48.3875177, lng: 18.9962522 },
    { lat: 48.3886074, lng: 18.9969502 },
    { lat: 48.3900817, lng: 18.9982501 },
    { lat: 48.3904935, lng: 18.9988733 },
    { lat: 48.3907992, lng: 18.9995802 },
    { lat: 48.3917742, lng: 19.0011104 },
    { lat: 48.3920516, lng: 19.0019116 },
    { lat: 48.3923939, lng: 19.0022775 },
    { lat: 48.3929583, lng: 19.002491 },
    { lat: 48.3934729, lng: 19.0025473 },
    { lat: 48.3937714, lng: 19.0024971 },
    { lat: 48.3946945, lng: 19.002088 },
    { lat: 48.3951247, lng: 19.0012383 },
    { lat: 48.3955522, lng: 19.00082 },
    { lat: 48.3960411, lng: 19.0005259 },
    { lat: 48.396689, lng: 19.0003179 },
    { lat: 48.3973574, lng: 19.000313 },
    { lat: 48.3976092, lng: 19.0002099 },
    { lat: 48.3979085, lng: 18.9999532 },
    { lat: 48.3982601, lng: 18.9991738 },
    { lat: 48.398527, lng: 18.9983673 },
    { lat: 48.3994263, lng: 18.9970961 },
    { lat: 48.3995423, lng: 18.996827 },
    { lat: 48.3995884, lng: 18.9965574 },
    { lat: 48.3995659, lng: 18.9957386 },
    { lat: 48.4002863, lng: 18.9949399 },
    { lat: 48.4009067, lng: 18.9936815 },
    { lat: 48.4019135, lng: 18.9926196 },
    { lat: 48.4037375, lng: 18.9893345 },
    { lat: 48.4045177, lng: 18.9880284 },
    { lat: 48.4048056, lng: 18.9873946 },
    { lat: 48.4053949, lng: 18.9870104 },
    { lat: 48.4072309, lng: 18.9848955 },
    { lat: 48.4086556, lng: 18.9843776 },
    { lat: 48.4093733, lng: 18.9843206 },
    { lat: 48.4097326, lng: 18.9843801 },
    { lat: 48.411295, lng: 18.985252 },
  ],
  KráľovceKrnišov: [
    { lat: 48.3700325, lng: 18.9833251 },
    { lat: 48.3699769, lng: 18.9804464 },
    { lat: 48.3699706, lng: 18.9801478 },
    { lat: 48.3707206, lng: 18.9797088 },
    { lat: 48.3715698, lng: 18.9794696 },
    { lat: 48.3717871, lng: 18.9793524 },
    { lat: 48.3722405, lng: 18.9789368 },
    { lat: 48.3729034, lng: 18.9774772 },
    { lat: 48.3736405, lng: 18.9763469 },
    { lat: 48.3739147, lng: 18.9760161 },
    { lat: 48.3743929, lng: 18.9751606 },
    { lat: 48.3747385, lng: 18.974751 },
    { lat: 48.3749424, lng: 18.9746367 },
    { lat: 48.3755699, lng: 18.9745152 },
    { lat: 48.376047, lng: 18.9743443 },
    { lat: 48.3768965, lng: 18.9739124 },
    { lat: 48.3776651, lng: 18.973353 },
    { lat: 48.3790333, lng: 18.9720158 },
    { lat: 48.3795211, lng: 18.9712148 },
    { lat: 48.3800497, lng: 18.9700643 },
    { lat: 48.3803383, lng: 18.9695764 },
    { lat: 48.3803831, lng: 18.9691318 },
    { lat: 48.3808893, lng: 18.9687773 },
    { lat: 48.3812625, lng: 18.9680912 },
    { lat: 48.3820814, lng: 18.966821 },
    { lat: 48.3825494, lng: 18.9659371 },
    { lat: 48.3830457, lng: 18.9655699 },
    { lat: 48.3828582, lng: 18.9643057 },
    { lat: 48.3824294, lng: 18.9631524 },
    { lat: 48.3821371, lng: 18.9627739 },
    { lat: 48.3816178, lng: 18.9624972 },
    { lat: 48.3811853, lng: 18.9614933 },
    { lat: 48.3804699, lng: 18.9600266 },
    { lat: 48.3803695, lng: 18.9599609 },
    { lat: 48.3802454, lng: 18.9596514 },
    { lat: 48.3798738, lng: 18.9590207 },
    { lat: 48.3797242, lng: 18.958895 },
    { lat: 48.376845, lng: 18.955003 },
    { lat: 48.376577, lng: 18.955301 },
    { lat: 48.376469, lng: 18.955401 },
    { lat: 48.376281, lng: 18.955514 },
    { lat: 48.376098, lng: 18.955678 },
    { lat: 48.375448, lng: 18.955635 },
    { lat: 48.37513, lng: 18.955564 },
    { lat: 48.37451, lng: 18.955332 },
    { lat: 48.374093, lng: 18.955146 },
    { lat: 48.373889, lng: 18.95502 },
    { lat: 48.373829, lng: 18.955015 },
    { lat: 48.373692, lng: 18.955085 },
    { lat: 48.373633, lng: 18.955092 },
    { lat: 48.373466, lng: 18.955063 },
    { lat: 48.373223, lng: 18.954988 },
    { lat: 48.372927, lng: 18.954778 },
    { lat: 48.37277, lng: 18.954504 },
    { lat: 48.372434, lng: 18.95399 },
    { lat: 48.372178, lng: 18.953782 },
    { lat: 48.372145, lng: 18.953756 },
    { lat: 48.371905, lng: 18.953655 },
    { lat: 48.371663, lng: 18.953304 },
    { lat: 48.371413, lng: 18.953054 },
    { lat: 48.371149, lng: 18.952732 },
    { lat: 48.370827, lng: 18.952286 },
    { lat: 48.370516, lng: 18.952066 },
    { lat: 48.370334, lng: 18.951864 },
    { lat: 48.369983, lng: 18.951572 },
    { lat: 48.369645, lng: 18.951263 },
    { lat: 48.369386, lng: 18.951168 },
    { lat: 48.36912, lng: 18.951035 },
    { lat: 48.368596, lng: 18.950729 },
    { lat: 48.368315, lng: 18.950434 },
    { lat: 48.368083, lng: 18.950346 },
    { lat: 48.367669, lng: 18.949973 },
    { lat: 48.367569, lng: 18.949888 },
    { lat: 48.367299, lng: 18.949479 },
    { lat: 48.367195, lng: 18.949136 },
    { lat: 48.367191, lng: 18.949104 },
    { lat: 48.367202, lng: 18.948942 },
    { lat: 48.367184, lng: 18.948458 },
    { lat: 48.367136, lng: 18.947975 },
    { lat: 48.367146, lng: 18.947662 },
    { lat: 48.367204, lng: 18.947345 },
    { lat: 48.367281, lng: 18.947014 },
    { lat: 48.367241, lng: 18.946845 },
    { lat: 48.36707, lng: 18.946503 },
    { lat: 48.36698, lng: 18.946144 },
    { lat: 48.366933, lng: 18.945963 },
    { lat: 48.366882, lng: 18.94558 },
    { lat: 48.366886, lng: 18.94537 },
    { lat: 48.366946, lng: 18.945158 },
    { lat: 48.366954, lng: 18.944993 },
    { lat: 48.367041, lng: 18.944748 },
    { lat: 48.367078, lng: 18.944186 },
    { lat: 48.367035, lng: 18.943815 },
    { lat: 48.366807, lng: 18.942904 },
    { lat: 48.365854, lng: 18.941867 },
    { lat: 48.364514, lng: 18.940413 },
    { lat: 48.363865, lng: 18.939719 },
    { lat: 48.3638, lng: 18.939768 },
    { lat: 48.363777, lng: 18.939876 },
    { lat: 48.363667, lng: 18.939938 },
    { lat: 48.363559, lng: 18.940283 },
    { lat: 48.363112, lng: 18.940514 },
    { lat: 48.362971, lng: 18.940886 },
    { lat: 48.362801, lng: 18.941039 },
    { lat: 48.362636, lng: 18.941091 },
    { lat: 48.362432, lng: 18.941251 },
    { lat: 48.362296, lng: 18.941441 },
    { lat: 48.362273, lng: 18.941563 },
    { lat: 48.362206, lng: 18.941612 },
    { lat: 48.362194, lng: 18.941721 },
    { lat: 48.361999, lng: 18.942007 },
    { lat: 48.361984, lng: 18.942152 },
    { lat: 48.361839, lng: 18.942191 },
    { lat: 48.36182, lng: 18.942256 },
    { lat: 48.361759, lng: 18.942282 },
    { lat: 48.361396, lng: 18.942068 },
    { lat: 48.361338, lng: 18.942034 },
    { lat: 48.361083, lng: 18.942089 },
    { lat: 48.360984, lng: 18.942064 },
    { lat: 48.36088, lng: 18.942069 },
    { lat: 48.360795, lng: 18.942124 },
    { lat: 48.36068, lng: 18.94211 },
    { lat: 48.360573, lng: 18.942018 },
    { lat: 48.360284, lng: 18.942007 },
    { lat: 48.360094, lng: 18.941971 },
    { lat: 48.359949, lng: 18.941896 },
    { lat: 48.359659, lng: 18.941689 },
    { lat: 48.359522, lng: 18.941618 },
    { lat: 48.359456, lng: 18.941696 },
    { lat: 48.359389, lng: 18.941635 },
    { lat: 48.359248, lng: 18.941616 },
    { lat: 48.359006, lng: 18.941583 },
    { lat: 48.358858, lng: 18.941609 },
    { lat: 48.358754, lng: 18.941564 },
    { lat: 48.358713, lng: 18.941646 },
    { lat: 48.358584, lng: 18.941638 },
    { lat: 48.358401, lng: 18.941348 },
    { lat: 48.358283, lng: 18.941234 },
    { lat: 48.358262, lng: 18.941192 },
    { lat: 48.358218, lng: 18.941105 },
    { lat: 48.358215, lng: 18.941082 },
    { lat: 48.358312, lng: 18.940299 },
    { lat: 48.358331, lng: 18.940105 },
    { lat: 48.358345, lng: 18.939771 },
    { lat: 48.358344, lng: 18.939568 },
    { lat: 48.358293, lng: 18.939418 },
    { lat: 48.358297, lng: 18.938669 },
    { lat: 48.358083, lng: 18.938383 },
    { lat: 48.358047, lng: 18.938317 },
    { lat: 48.357941, lng: 18.938043 },
    { lat: 48.357678, lng: 18.937873 },
    { lat: 48.357605, lng: 18.937827 },
    { lat: 48.357551, lng: 18.937755 },
    { lat: 48.35748, lng: 18.937556 },
    { lat: 48.357379, lng: 18.937482 },
    { lat: 48.357116, lng: 18.937464 },
    { lat: 48.356821, lng: 18.937201 },
    { lat: 48.356677, lng: 18.937229 },
    { lat: 48.356497, lng: 18.937222 },
    { lat: 48.356453, lng: 18.937227 },
    { lat: 48.356173, lng: 18.93734 },
    { lat: 48.355976, lng: 18.937282 },
    { lat: 48.355879, lng: 18.937323 },
    { lat: 48.355774, lng: 18.937189 },
    { lat: 48.355689, lng: 18.937173 },
    { lat: 48.355505, lng: 18.937347 },
    { lat: 48.355193, lng: 18.93756 },
    { lat: 48.35513, lng: 18.937634 },
    { lat: 48.35494, lng: 18.937772 },
    { lat: 48.354871, lng: 18.937817 },
    { lat: 48.354798, lng: 18.93784 },
    { lat: 48.354761, lng: 18.938059 },
    { lat: 48.354636, lng: 18.938191 },
    { lat: 48.354627, lng: 18.93847 },
    { lat: 48.354586, lng: 18.938649 },
    { lat: 48.354292, lng: 18.939046 },
    { lat: 48.354231, lng: 18.939269 },
    { lat: 48.354056, lng: 18.93953 },
    { lat: 48.35398, lng: 18.939519 },
    { lat: 48.353937, lng: 18.939492 },
    { lat: 48.353808, lng: 18.93947 },
    { lat: 48.3537, lng: 18.93948 },
    { lat: 48.353528, lng: 18.939505 },
    { lat: 48.35345, lng: 18.939575 },
    { lat: 48.35332, lng: 18.939549 },
    { lat: 48.353005, lng: 18.939765 },
    { lat: 48.353011, lng: 18.939808 },
    { lat: 48.353019, lng: 18.939866 },
    { lat: 48.352818, lng: 18.940075 },
    { lat: 48.352545, lng: 18.940233 },
    { lat: 48.352489, lng: 18.940299 },
    { lat: 48.352463, lng: 18.940266 },
    { lat: 48.35238, lng: 18.940606 },
    { lat: 48.352285, lng: 18.940713 },
    { lat: 48.352251, lng: 18.940694 },
    { lat: 48.352075, lng: 18.940793 },
    { lat: 48.351976, lng: 18.9408 },
    { lat: 48.351926, lng: 18.941277 },
    { lat: 48.351849, lng: 18.94136 },
    { lat: 48.351674, lng: 18.941347 },
    { lat: 48.351635, lng: 18.941403 },
    { lat: 48.351591, lng: 18.941512 },
    { lat: 48.351571, lng: 18.941674 },
    { lat: 48.351535, lng: 18.941729 },
    { lat: 48.351488, lng: 18.941743 },
    { lat: 48.351407, lng: 18.941876 },
    { lat: 48.351388, lng: 18.941934 },
    { lat: 48.351417, lng: 18.941961 },
    { lat: 48.351363, lng: 18.942216 },
    { lat: 48.351279, lng: 18.942365 },
    { lat: 48.35114, lng: 18.942454 },
    { lat: 48.351077, lng: 18.942527 },
    { lat: 48.350921, lng: 18.942587 },
    { lat: 48.350823, lng: 18.942732 },
    { lat: 48.350695, lng: 18.942792 },
    { lat: 48.35059, lng: 18.942866 },
    { lat: 48.350587, lng: 18.942869 },
    { lat: 48.35041, lng: 18.943012 },
    { lat: 48.350315, lng: 18.943105 },
    { lat: 48.350208, lng: 18.94324 },
    { lat: 48.350191, lng: 18.943197 },
    { lat: 48.350129, lng: 18.943366 },
    { lat: 48.350069, lng: 18.943532 },
    { lat: 48.350021, lng: 18.943589 },
    { lat: 48.349824, lng: 18.943666 },
    { lat: 48.349754, lng: 18.943806 },
    { lat: 48.349614, lng: 18.943857 },
    { lat: 48.349562, lng: 18.944101 },
    { lat: 48.349458, lng: 18.944249 },
    { lat: 48.349399, lng: 18.94429 },
    { lat: 48.349337, lng: 18.944403 },
    { lat: 48.349312, lng: 18.944531 },
    { lat: 48.349214, lng: 18.944833 },
    { lat: 48.349118, lng: 18.944915 },
    { lat: 48.349031, lng: 18.944895 },
    { lat: 48.349002, lng: 18.944907 },
    { lat: 48.348966, lng: 18.944931 },
    { lat: 48.348869, lng: 18.945091 },
    { lat: 48.348765, lng: 18.945068 },
    { lat: 48.348705, lng: 18.945094 },
    { lat: 48.348584, lng: 18.945198 },
    { lat: 48.348515, lng: 18.945442 },
    { lat: 48.348491, lng: 18.945477 },
    { lat: 48.348474, lng: 18.94548 },
    { lat: 48.348429, lng: 18.945433 },
    { lat: 48.348333, lng: 18.945534 },
    { lat: 48.348131, lng: 18.94557 },
    { lat: 48.348081, lng: 18.945696 },
    { lat: 48.347757, lng: 18.945748 },
    { lat: 48.347556, lng: 18.945733 },
    { lat: 48.347446, lng: 18.945884 },
    { lat: 48.347288, lng: 18.94566 },
    { lat: 48.347097, lng: 18.945685 },
    { lat: 48.347079, lng: 18.945735 },
    { lat: 48.347058, lng: 18.945899 },
    { lat: 48.347021, lng: 18.946185 },
    { lat: 48.3469, lng: 18.946114 },
    { lat: 48.346666, lng: 18.94592 },
    { lat: 48.346435, lng: 18.945709 },
    { lat: 48.346272, lng: 18.945558 },
    { lat: 48.346237, lng: 18.945674 },
    { lat: 48.346123, lng: 18.94591 },
    { lat: 48.346002, lng: 18.946075 },
    { lat: 48.345902, lng: 18.945979 },
    { lat: 48.345889, lng: 18.945994 },
    { lat: 48.34565, lng: 18.946361 },
    { lat: 48.345343, lng: 18.946596 },
    { lat: 48.344912, lng: 18.946726 },
    { lat: 48.344624, lng: 18.946739 },
    { lat: 48.344528, lng: 18.946872 },
    { lat: 48.344449, lng: 18.947489 },
    { lat: 48.344379, lng: 18.948535 },
    { lat: 48.344397, lng: 18.949058 },
    { lat: 48.34425, lng: 18.949169 },
    { lat: 48.34413, lng: 18.949217 },
    { lat: 48.344062, lng: 18.949229 },
    { lat: 48.343882, lng: 18.949308 },
    { lat: 48.343418, lng: 18.949843 },
    { lat: 48.343057, lng: 18.95027 },
    { lat: 48.342781, lng: 18.950637 },
    { lat: 48.34227, lng: 18.951032 },
    { lat: 48.341974, lng: 18.951421 },
    { lat: 48.341793, lng: 18.951715 },
    { lat: 48.341594, lng: 18.951922 },
    { lat: 48.341514, lng: 18.951892 },
    { lat: 48.341286, lng: 18.952094 },
    { lat: 48.341025, lng: 18.952277 },
    { lat: 48.34098, lng: 18.952271 },
    { lat: 48.340671, lng: 18.952794 },
    { lat: 48.34031, lng: 18.95319 },
    { lat: 48.339927, lng: 18.953606 },
    { lat: 48.339836, lng: 18.953499 },
    { lat: 48.339655, lng: 18.95327 },
    { lat: 48.339339, lng: 18.953131 },
    { lat: 48.339257, lng: 18.953189 },
    { lat: 48.33907, lng: 18.953027 },
    { lat: 48.338855, lng: 18.952839 },
    { lat: 48.338639, lng: 18.953054 },
    { lat: 48.338406, lng: 18.953348 },
    { lat: 48.338098, lng: 18.953667 },
    { lat: 48.337918, lng: 18.953841 },
    { lat: 48.337707, lng: 18.953973 },
    { lat: 48.337315, lng: 18.954167 },
    { lat: 48.337172, lng: 18.954212 },
    { lat: 48.3371326, lng: 18.9542265 },
    { lat: 48.336949, lng: 18.954503 },
    { lat: 48.3362625, lng: 18.956021 },
    { lat: 48.3360983, lng: 18.9561817 },
    { lat: 48.3356875, lng: 18.9564187 },
    { lat: 48.335431, lng: 18.9567155 },
    { lat: 48.3353846, lng: 18.9568888 },
    { lat: 48.3353069, lng: 18.9587834 },
    { lat: 48.3351782, lng: 18.9595917 },
    { lat: 48.3348667, lng: 18.960337 },
    { lat: 48.3338077, lng: 18.9620009 },
    { lat: 48.3337012, lng: 18.9624767 },
    { lat: 48.3335885, lng: 18.9636446 },
    { lat: 48.3334374, lng: 18.9643257 },
    { lat: 48.3332681, lng: 18.9648537 },
    { lat: 48.3330122, lng: 18.9652028 },
    { lat: 48.3326295, lng: 18.9653291 },
    { lat: 48.3324152, lng: 18.9655465 },
    { lat: 48.3317155, lng: 18.9669017 },
    { lat: 48.3313623, lng: 18.9676627 },
    { lat: 48.3311647, lng: 18.9684508 },
    { lat: 48.3311193, lng: 18.9685804 },
    { lat: 48.3309946, lng: 18.9706354 },
    { lat: 48.3308752, lng: 18.9715669 },
    { lat: 48.330712, lng: 18.9724318 },
    { lat: 48.3303883, lng: 18.9734445 },
    { lat: 48.3291972, lng: 18.9752879 },
    { lat: 48.3284337, lng: 18.9771622 },
    { lat: 48.3277803, lng: 18.9780605 },
    { lat: 48.3273886, lng: 18.9785989 },
    { lat: 48.3272249, lng: 18.9786822 },
    { lat: 48.3269598, lng: 18.9784565 },
    { lat: 48.3265886, lng: 18.9780354 },
    { lat: 48.3261698, lng: 18.9773395 },
    { lat: 48.3259325, lng: 18.9771758 },
    { lat: 48.3235234, lng: 18.9765857 },
    { lat: 48.3229295, lng: 18.976593 },
    { lat: 48.3224461, lng: 18.9767085 },
    { lat: 48.3211635, lng: 18.977405 },
    { lat: 48.3204399, lng: 18.977277 },
    { lat: 48.3196975, lng: 18.9770452 },
    { lat: 48.3190033, lng: 18.977164 },
    { lat: 48.3193414, lng: 18.9788146 },
    { lat: 48.3195936, lng: 18.9814574 },
    { lat: 48.3196738, lng: 18.9819455 },
    { lat: 48.3197099, lng: 18.9828933 },
    { lat: 48.319462, lng: 18.9830071 },
    { lat: 48.3193777, lng: 18.9829484 },
    { lat: 48.3192539, lng: 18.9829959 },
    { lat: 48.3191243, lng: 18.9831497 },
    { lat: 48.3188878, lng: 18.9838393 },
    { lat: 48.3185382, lng: 18.9846364 },
    { lat: 48.3183561, lng: 18.9853743 },
    { lat: 48.3181913, lng: 18.9855484 },
    { lat: 48.3180466, lng: 18.985939 },
    { lat: 48.3178529, lng: 18.9866949 },
    { lat: 48.3177472, lng: 18.9871651 },
    { lat: 48.3177842, lng: 18.9871814 },
    { lat: 48.3180351, lng: 18.9872379 },
    { lat: 48.3180069, lng: 18.9877038 },
    { lat: 48.3175504, lng: 18.9884997 },
    { lat: 48.3173953, lng: 18.9889507 },
    { lat: 48.3168926, lng: 18.9897735 },
    { lat: 48.3168333, lng: 18.9899808 },
    { lat: 48.3167868, lng: 18.9918119 },
    { lat: 48.3167004, lng: 18.9921526 },
    { lat: 48.3166385, lng: 18.9922624 },
    { lat: 48.3165076, lng: 18.9923281 },
    { lat: 48.3159432, lng: 18.9924484 },
    { lat: 48.3153894, lng: 18.9927977 },
    { lat: 48.3155593, lng: 18.9933206 },
    { lat: 48.3157009, lng: 18.9935548 },
    { lat: 48.3158849, lng: 18.9942439 },
    { lat: 48.316009, lng: 18.9941581 },
    { lat: 48.3163708, lng: 18.9941117 },
    { lat: 48.316762, lng: 18.993888 },
    { lat: 48.3168829, lng: 18.9939149 },
    { lat: 48.3173642, lng: 18.9936706 },
    { lat: 48.3175962, lng: 18.9934007 },
    { lat: 48.3177029, lng: 18.99346 },
    { lat: 48.3199457, lng: 18.9924888 },
    { lat: 48.3204808, lng: 18.9921497 },
    { lat: 48.3221015, lng: 18.9914474 },
    { lat: 48.3244037, lng: 18.9898999 },
    { lat: 48.3261758, lng: 18.9902207 },
    { lat: 48.3263663, lng: 18.9903569 },
    { lat: 48.326487, lng: 18.9906676 },
    { lat: 48.3267499, lng: 18.9908224 },
    { lat: 48.3279947, lng: 18.9906502 },
    { lat: 48.3289174, lng: 18.9903219 },
    { lat: 48.3294911, lng: 18.9899897 },
    { lat: 48.3298414, lng: 18.9898942 },
    { lat: 48.3298535, lng: 18.9899332 },
    { lat: 48.3286553, lng: 18.9921919 },
    { lat: 48.3287936, lng: 18.9925835 },
    { lat: 48.3288426, lng: 18.9930821 },
    { lat: 48.3291026, lng: 18.9941135 },
    { lat: 48.3292912, lng: 18.9952268 },
    { lat: 48.3293872, lng: 18.9955584 },
    { lat: 48.3295403, lng: 18.9957346 },
    { lat: 48.3296948, lng: 18.996045 },
    { lat: 48.3297784, lng: 18.9963788 },
    { lat: 48.3299716, lng: 18.9972251 },
    { lat: 48.3301327, lng: 18.9983752 },
    { lat: 48.3309505, lng: 19.0002204 },
    { lat: 48.3307731, lng: 19.0004151 },
    { lat: 48.3307297, lng: 19.0003793 },
    { lat: 48.330401, lng: 19.0006572 },
    { lat: 48.3300367, lng: 19.0011396 },
    { lat: 48.3298993, lng: 19.0011472 },
    { lat: 48.3297068, lng: 19.0013953 },
    { lat: 48.3299304, lng: 19.0017768 },
    { lat: 48.3302986, lng: 19.0020989 },
    { lat: 48.3311772, lng: 19.0024841 },
    { lat: 48.331837, lng: 19.0030432 },
    { lat: 48.3325094, lng: 19.0033174 },
    { lat: 48.3331424, lng: 19.0039693 },
    { lat: 48.3335241, lng: 19.0040443 },
    { lat: 48.3337672, lng: 19.004216 },
    { lat: 48.3342099, lng: 19.0042065 },
    { lat: 48.3350879, lng: 19.0046428 },
    { lat: 48.3356326, lng: 19.0047113 },
    { lat: 48.3373204, lng: 19.0043328 },
    { lat: 48.3376959, lng: 19.0043583 },
    { lat: 48.3382545, lng: 19.0045683 },
    { lat: 48.3387051, lng: 19.0049129 },
    { lat: 48.3394378, lng: 19.0059319 },
    { lat: 48.3400407, lng: 19.0065246 },
    { lat: 48.3401312, lng: 19.0065525 },
    { lat: 48.3402629, lng: 19.0067451 },
    { lat: 48.3403501, lng: 19.0067979 },
    { lat: 48.3407972, lng: 19.0067436 },
    { lat: 48.3412129, lng: 19.006825 },
    { lat: 48.3422786, lng: 19.0071443 },
    { lat: 48.342726, lng: 19.0069617 },
    { lat: 48.342962, lng: 19.0070116 },
    { lat: 48.3432112, lng: 19.0072801 },
    { lat: 48.3434465, lng: 19.0074049 },
    { lat: 48.3436527, lng: 19.0076028 },
    { lat: 48.3437743, lng: 19.0078618 },
    { lat: 48.3437244, lng: 19.0079692 },
    { lat: 48.3438907, lng: 19.0082936 },
    { lat: 48.3445058, lng: 19.0078586 },
    { lat: 48.3450427, lng: 19.0067548 },
    { lat: 48.3456115, lng: 19.0059423 },
    { lat: 48.3459704, lng: 19.0056885 },
    { lat: 48.3463663, lng: 19.0052916 },
    { lat: 48.3467378, lng: 19.0047444 },
    { lat: 48.3467544, lng: 19.0042761 },
    { lat: 48.3465787, lng: 19.0037674 },
    { lat: 48.3465947, lng: 19.0035511 },
    { lat: 48.3467375, lng: 19.0030109 },
    { lat: 48.3474109, lng: 19.0015526 },
    { lat: 48.3476581, lng: 19.0011829 },
    { lat: 48.3477235, lng: 19.0009624 },
    { lat: 48.3479701, lng: 19.000512 },
    { lat: 48.3480793, lng: 19.0001093 },
    { lat: 48.3481564, lng: 18.999544 },
    { lat: 48.3481253, lng: 18.9993735 },
    { lat: 48.3481947, lng: 18.998964 },
    { lat: 48.3487155, lng: 18.9985662 },
    { lat: 48.349352, lng: 18.9977765 },
    { lat: 48.3508619, lng: 18.9965866 },
    { lat: 48.352469, lng: 18.9950887 },
    { lat: 48.3527755, lng: 18.9949093 },
    { lat: 48.3528258, lng: 18.9947234 },
    { lat: 48.3533238, lng: 18.9945827 },
    { lat: 48.3537156, lng: 18.9945693 },
    { lat: 48.3540563, lng: 18.9947082 },
    { lat: 48.3540705, lng: 18.9947006 },
    { lat: 48.3543892, lng: 18.9945144 },
    { lat: 48.3555756, lng: 18.994428 },
    { lat: 48.3561387, lng: 18.9949959 },
    { lat: 48.3565792, lng: 18.9947203 },
    { lat: 48.3569646, lng: 18.9943872 },
    { lat: 48.3573243, lng: 18.9942959 },
    { lat: 48.3588819, lng: 18.99497 },
    { lat: 48.3593097, lng: 18.9950475 },
    { lat: 48.3595504, lng: 18.9951684 },
    { lat: 48.359776, lng: 18.9955006 },
    { lat: 48.3600576, lng: 18.995608 },
    { lat: 48.3601692, lng: 18.9957619 },
    { lat: 48.3606825, lng: 18.9958569 },
    { lat: 48.3621961, lng: 18.9955341 },
    { lat: 48.362804, lng: 18.9952038 },
    { lat: 48.3627933, lng: 18.9945527 },
    { lat: 48.3628398, lng: 18.9944037 },
    { lat: 48.3636359, lng: 18.9934321 },
    { lat: 48.3640317, lng: 18.9925244 },
    { lat: 48.3644355, lng: 18.9921944 },
    { lat: 48.3647615, lng: 18.9920219 },
    { lat: 48.3650998, lng: 18.991647 },
    { lat: 48.3653708, lng: 18.9911879 },
    { lat: 48.3655979, lng: 18.9909098 },
    { lat: 48.3662534, lng: 18.9903878 },
    { lat: 48.3668387, lng: 18.9900139 },
    { lat: 48.3674556, lng: 18.9891098 },
    { lat: 48.3682488, lng: 18.9884571 },
    { lat: 48.3685601, lng: 18.988037 },
    { lat: 48.369735, lng: 18.9855613 },
    { lat: 48.3697761, lng: 18.9844031 },
    { lat: 48.3700325, lng: 18.9833251 },
  ],
  ZemianskyVrbovok: [
    { lat: 48.3196688, lng: 19.1301298 },
    { lat: 48.3196465, lng: 19.1300895 },
    { lat: 48.3195313, lng: 19.1300404 },
    { lat: 48.3193886, lng: 19.1302288 },
    { lat: 48.3191257, lng: 19.1298166 },
    { lat: 48.3189182, lng: 19.1297254 },
    { lat: 48.3188824, lng: 19.1298218 },
    { lat: 48.3187661, lng: 19.1297973 },
    { lat: 48.3186936, lng: 19.1299265 },
    { lat: 48.317756, lng: 19.129209 },
    { lat: 48.3176804, lng: 19.1289107 },
    { lat: 48.3174747, lng: 19.1288112 },
    { lat: 48.3173632, lng: 19.128902 },
    { lat: 48.3171624, lng: 19.1289018 },
    { lat: 48.3171077, lng: 19.12934 },
    { lat: 48.3168662, lng: 19.1294481 },
    { lat: 48.3167919, lng: 19.1294083 },
    { lat: 48.3167807, lng: 19.129225 },
    { lat: 48.3164548, lng: 19.1289101 },
    { lat: 48.3161594, lng: 19.1291784 },
    { lat: 48.3159801, lng: 19.128998 },
    { lat: 48.3159447, lng: 19.1291705 },
    { lat: 48.3157802, lng: 19.1289916 },
    { lat: 48.3156766, lng: 19.1292168 },
    { lat: 48.3154566, lng: 19.1292584 },
    { lat: 48.3151297, lng: 19.1291282 },
    { lat: 48.3150842, lng: 19.1288021 },
    { lat: 48.3147754, lng: 19.1288916 },
    { lat: 48.3146392, lng: 19.1287836 },
    { lat: 48.3144094, lng: 19.1287668 },
    { lat: 48.3138027, lng: 19.1291934 },
    { lat: 48.3136365, lng: 19.1289992 },
    { lat: 48.3136244, lng: 19.1287562 },
    { lat: 48.3137008, lng: 19.1286647 },
    { lat: 48.313561, lng: 19.1284141 },
    { lat: 48.313821, lng: 19.1282447 },
    { lat: 48.3137886, lng: 19.128141 },
    { lat: 48.3136266, lng: 19.1281464 },
    { lat: 48.31349, lng: 19.1283218 },
    { lat: 48.3133424, lng: 19.1283224 },
    { lat: 48.3132784, lng: 19.1282608 },
    { lat: 48.3133982, lng: 19.1281992 },
    { lat: 48.3133897, lng: 19.1280529 },
    { lat: 48.3132214, lng: 19.1280703 },
    { lat: 48.3132054, lng: 19.127611 },
    { lat: 48.3131583, lng: 19.1276145 },
    { lat: 48.3131114, lng: 19.127806 },
    { lat: 48.3130525, lng: 19.1277754 },
    { lat: 48.3130292, lng: 19.1276732 },
    { lat: 48.3128039, lng: 19.1278117 },
    { lat: 48.3126488, lng: 19.1276148 },
    { lat: 48.312637, lng: 19.1274915 },
    { lat: 48.3126802, lng: 19.1274105 },
    { lat: 48.312619, lng: 19.1273341 },
    { lat: 48.3126903, lng: 19.1272295 },
    { lat: 48.3126298, lng: 19.1271668 },
    { lat: 48.3123484, lng: 19.1272656 },
    { lat: 48.3123098, lng: 19.1271697 },
    { lat: 48.3121864, lng: 19.1271148 },
    { lat: 48.3121052, lng: 19.1272262 },
    { lat: 48.3120097, lng: 19.1272327 },
    { lat: 48.3119495, lng: 19.1269554 },
    { lat: 48.3119936, lng: 19.1268683 },
    { lat: 48.3119796, lng: 19.1266521 },
    { lat: 48.3118519, lng: 19.1264648 },
    { lat: 48.3119082, lng: 19.1262574 },
    { lat: 48.3118785, lng: 19.1260939 },
    { lat: 48.3117244, lng: 19.1260786 },
    { lat: 48.3117238, lng: 19.1263173 },
    { lat: 48.3116261, lng: 19.1263676 },
    { lat: 48.3115998, lng: 19.1264746 },
    { lat: 48.3114705, lng: 19.1266148 },
    { lat: 48.3113979, lng: 19.1269254 },
    { lat: 48.3113345, lng: 19.1269853 },
    { lat: 48.3113321, lng: 19.1267582 },
    { lat: 48.3112145, lng: 19.1266354 },
    { lat: 48.3111818, lng: 19.1264775 },
    { lat: 48.3112606, lng: 19.1263614 },
    { lat: 48.3112499, lng: 19.1260796 },
    { lat: 48.3108047, lng: 19.126059 },
    { lat: 48.3107708, lng: 19.125924 },
    { lat: 48.3108042, lng: 19.1258451 },
    { lat: 48.3107119, lng: 19.1256458 },
    { lat: 48.3106087, lng: 19.12586 },
    { lat: 48.3104612, lng: 19.1257065 },
    { lat: 48.3103843, lng: 19.1257909 },
    { lat: 48.3102408, lng: 19.1258069 },
    { lat: 48.3101841, lng: 19.1256886 },
    { lat: 48.3102266, lng: 19.1254979 },
    { lat: 48.3100411, lng: 19.1255106 },
    { lat: 48.3096605, lng: 19.1252253 },
    { lat: 48.3095906, lng: 19.1254257 },
    { lat: 48.3095451, lng: 19.1272968 },
    { lat: 48.308153, lng: 19.1302254 },
    { lat: 48.3075029, lng: 19.1321098 },
    { lat: 48.3065305, lng: 19.1339317 },
    { lat: 48.3063301, lng: 19.1344814 },
    { lat: 48.30602, lng: 19.1359307 },
    { lat: 48.3058353, lng: 19.1363643 },
    { lat: 48.3032283, lng: 19.1351974 },
    { lat: 48.3031962, lng: 19.1353207 },
    { lat: 48.302841, lng: 19.1370242 },
    { lat: 48.3025111, lng: 19.1385518 },
    { lat: 48.3027539, lng: 19.1385116 },
    { lat: 48.3024494, lng: 19.1399612 },
    { lat: 48.301589, lng: 19.1429755 },
    { lat: 48.3010965, lng: 19.1443176 },
    { lat: 48.3008867, lng: 19.1453589 },
    { lat: 48.3005817, lng: 19.1461382 },
    { lat: 48.3004839, lng: 19.1465337 },
    { lat: 48.3023642, lng: 19.1475069 },
    { lat: 48.3017969, lng: 19.1497513 },
    { lat: 48.3026293, lng: 19.1502126 },
    { lat: 48.3021814, lng: 19.1525272 },
    { lat: 48.3020415, lng: 19.1527496 },
    { lat: 48.3018686, lng: 19.1531987 },
    { lat: 48.301549, lng: 19.153594 },
    { lat: 48.3012306, lng: 19.1545628 },
    { lat: 48.3011997, lng: 19.1549873 },
    { lat: 48.3012424, lng: 19.1549811 },
    { lat: 48.3013185, lng: 19.1552927 },
    { lat: 48.3016794, lng: 19.1554522 },
    { lat: 48.3017993, lng: 19.1553216 },
    { lat: 48.301917, lng: 19.1554477 },
    { lat: 48.3020739, lng: 19.1550627 },
    { lat: 48.3022921, lng: 19.1550072 },
    { lat: 48.3024452, lng: 19.1552079 },
    { lat: 48.3025128, lng: 19.1551859 },
    { lat: 48.3026295, lng: 19.1552657 },
    { lat: 48.3026282, lng: 19.1554212 },
    { lat: 48.3028061, lng: 19.1554602 },
    { lat: 48.3028869, lng: 19.1556594 },
    { lat: 48.303022, lng: 19.15545 },
    { lat: 48.3038088, lng: 19.1557434 },
    { lat: 48.3049639, lng: 19.1557372 },
    { lat: 48.3056509, lng: 19.156177 },
    { lat: 48.3065326, lng: 19.1564089 },
    { lat: 48.3081868, lng: 19.1572357 },
    { lat: 48.3084143, lng: 19.1569826 },
    { lat: 48.3084829, lng: 19.1570462 },
    { lat: 48.3084811, lng: 19.1574639 },
    { lat: 48.3088031, lng: 19.1573452 },
    { lat: 48.308825, lng: 19.1574915 },
    { lat: 48.309079, lng: 19.1576353 },
    { lat: 48.3091621, lng: 19.1582217 },
    { lat: 48.3098198, lng: 19.1587278 },
    { lat: 48.3102712, lng: 19.1592371 },
    { lat: 48.310434, lng: 19.1590224 },
    { lat: 48.310532, lng: 19.1591804 },
    { lat: 48.3105035, lng: 19.1593759 },
    { lat: 48.3107555, lng: 19.1595961 },
    { lat: 48.310704, lng: 19.1598055 },
    { lat: 48.3110481, lng: 19.1600189 },
    { lat: 48.3113409, lng: 19.1601015 },
    { lat: 48.3116672, lng: 19.1600648 },
    { lat: 48.3117605, lng: 19.159763 },
    { lat: 48.3119305, lng: 19.1598535 },
    { lat: 48.3118791, lng: 19.1600224 },
    { lat: 48.3119245, lng: 19.1600503 },
    { lat: 48.3121689, lng: 19.1600932 },
    { lat: 48.3123643, lng: 19.1600071 },
    { lat: 48.3123186, lng: 19.1601998 },
    { lat: 48.3124496, lng: 19.1603836 },
    { lat: 48.3125098, lng: 19.1603928 },
    { lat: 48.3125583, lng: 19.1602142 },
    { lat: 48.3126069, lng: 19.1603753 },
    { lat: 48.3127283, lng: 19.1604365 },
    { lat: 48.312776, lng: 19.1602159 },
    { lat: 48.3130035, lng: 19.1604402 },
    { lat: 48.3133616, lng: 19.1604971 },
    { lat: 48.3134328, lng: 19.1605943 },
    { lat: 48.3134326, lng: 19.1607951 },
    { lat: 48.3136361, lng: 19.1610792 },
    { lat: 48.3137215, lng: 19.1608919 },
    { lat: 48.3135614, lng: 19.1606625 },
    { lat: 48.3136887, lng: 19.1606161 },
    { lat: 48.3140446, lng: 19.1607462 },
    { lat: 48.314144, lng: 19.1608895 },
    { lat: 48.3142953, lng: 19.1608482 },
    { lat: 48.3143412, lng: 19.1609784 },
    { lat: 48.3146884, lng: 19.1610673 },
    { lat: 48.3147209, lng: 19.1614051 },
    { lat: 48.3148695, lng: 19.1614902 },
    { lat: 48.3149731, lng: 19.1614213 },
    { lat: 48.3151775, lng: 19.1616581 },
    { lat: 48.3156688, lng: 19.1618326 },
    { lat: 48.3156992, lng: 19.1619263 },
    { lat: 48.3157783, lng: 19.1619306 },
    { lat: 48.3158545, lng: 19.161761 },
    { lat: 48.3159613, lng: 19.1619577 },
    { lat: 48.3162221, lng: 19.1619921 },
    { lat: 48.3163081, lng: 19.1621351 },
    { lat: 48.3164403, lng: 19.1622086 },
    { lat: 48.3166032, lng: 19.1626084 },
    { lat: 48.3167799, lng: 19.1627131 },
    { lat: 48.3169013, lng: 19.1624789 },
    { lat: 48.3175393, lng: 19.1628227 },
    { lat: 48.3180925, lng: 19.1632938 },
    { lat: 48.3181215, lng: 19.1634695 },
    { lat: 48.3183888, lng: 19.163382 },
    { lat: 48.3185021, lng: 19.1637324 },
    { lat: 48.3185945, lng: 19.1638667 },
    { lat: 48.3187158, lng: 19.1639258 },
    { lat: 48.3188356, lng: 19.1638661 },
    { lat: 48.3189883, lng: 19.1639252 },
    { lat: 48.3192964, lng: 19.1642264 },
    { lat: 48.3194165, lng: 19.1645817 },
    { lat: 48.3194937, lng: 19.1645902 },
    { lat: 48.3196493, lng: 19.164751 },
    { lat: 48.3196953, lng: 19.1651791 },
    { lat: 48.3198389, lng: 19.1656002 },
    { lat: 48.3201683, lng: 19.1658079 },
    { lat: 48.3203858, lng: 19.1660777 },
    { lat: 48.320563, lng: 19.1661307 },
    { lat: 48.3206955, lng: 19.1659154 },
    { lat: 48.3210952, lng: 19.1662206 },
    { lat: 48.3211362, lng: 19.166391 },
    { lat: 48.3212748, lng: 19.1663667 },
    { lat: 48.3214804, lng: 19.1664445 },
    { lat: 48.3216837, lng: 19.1669057 },
    { lat: 48.3221076, lng: 19.1673864 },
    { lat: 48.3222718, lng: 19.1674007 },
    { lat: 48.3223755, lng: 19.1676067 },
    { lat: 48.3226035, lng: 19.1677974 },
    { lat: 48.3226943, lng: 19.1677862 },
    { lat: 48.323248, lng: 19.1681101 },
    { lat: 48.323601, lng: 19.1684987 },
    { lat: 48.3236878, lng: 19.1684777 },
    { lat: 48.3237929, lng: 19.1686209 },
    { lat: 48.3242216, lng: 19.1687864 },
    { lat: 48.3243273, lng: 19.1688927 },
    { lat: 48.3243421, lng: 19.1692176 },
    { lat: 48.3245462, lng: 19.16949 },
    { lat: 48.3246885, lng: 19.169562 },
    { lat: 48.324845, lng: 19.1694465 },
    { lat: 48.3249532, lng: 19.1694944 },
    { lat: 48.3249418, lng: 19.1698761 },
    { lat: 48.3249785, lng: 19.169934 },
    { lat: 48.3254172, lng: 19.1701693 },
    { lat: 48.3256225, lng: 19.1703753 },
    { lat: 48.3258893, lng: 19.1704158 },
    { lat: 48.3259599, lng: 19.1705635 },
    { lat: 48.3259288, lng: 19.1706647 },
    { lat: 48.3259992, lng: 19.1708376 },
    { lat: 48.3261088, lng: 19.1709508 },
    { lat: 48.3261903, lng: 19.1708936 },
    { lat: 48.3263555, lng: 19.1710208 },
    { lat: 48.3265258, lng: 19.1710447 },
    { lat: 48.3265931, lng: 19.1712448 },
    { lat: 48.3267299, lng: 19.1713157 },
    { lat: 48.327009, lng: 19.1718356 },
    { lat: 48.3270258, lng: 19.1719888 },
    { lat: 48.3269171, lng: 19.1722074 },
    { lat: 48.3271829, lng: 19.1725686 },
    { lat: 48.3273631, lng: 19.1725008 },
    { lat: 48.327703, lng: 19.1728049 },
    { lat: 48.3278696, lng: 19.1730978 },
    { lat: 48.3281839, lng: 19.1733686 },
    { lat: 48.3282026, lng: 19.1732691 },
    { lat: 48.3282834, lng: 19.1732598 },
    { lat: 48.3285699, lng: 19.172737 },
    { lat: 48.3292199, lng: 19.172138 },
    { lat: 48.3297462, lng: 19.1720653 },
    { lat: 48.3305031, lng: 19.1717559 },
    { lat: 48.3314457, lng: 19.171049 },
    { lat: 48.3315287, lng: 19.1698986 },
    { lat: 48.3315057, lng: 19.1690035 },
    { lat: 48.3314394, lng: 19.1680727 },
    { lat: 48.3312744, lng: 19.1670573 },
    { lat: 48.3312267, lng: 19.1658666 },
    { lat: 48.3313905, lng: 19.1645737 },
    { lat: 48.332068, lng: 19.1632512 },
    { lat: 48.3322763, lng: 19.1627005 },
    { lat: 48.3324715, lng: 19.1619954 },
    { lat: 48.3328713, lng: 19.1610772 },
    { lat: 48.3332717, lng: 19.160943 },
    { lat: 48.3333126, lng: 19.1608563 },
    { lat: 48.3333131, lng: 19.1600942 },
    { lat: 48.3335248, lng: 19.1595409 },
    { lat: 48.3334545, lng: 19.157372 },
    { lat: 48.3335794, lng: 19.1566591 },
    { lat: 48.3336737, lng: 19.1564261 },
    { lat: 48.3356175, lng: 19.1539065 },
    { lat: 48.3359823, lng: 19.1537844 },
    { lat: 48.3365961, lng: 19.1537709 },
    { lat: 48.3371126, lng: 19.1536828 },
    { lat: 48.3373586, lng: 19.1535066 },
    { lat: 48.3375958, lng: 19.1530174 },
    { lat: 48.3378475, lng: 19.1523179 },
    { lat: 48.3381182, lng: 19.1508647 },
    { lat: 48.3382196, lng: 19.1506784 },
    { lat: 48.3385376, lng: 19.1505458 },
    { lat: 48.3385932, lng: 19.1501665 },
    { lat: 48.3385635, lng: 19.1499778 },
    { lat: 48.3387174, lng: 19.1497852 },
    { lat: 48.3387476, lng: 19.1495742 },
    { lat: 48.3386803, lng: 19.1492596 },
    { lat: 48.338686, lng: 19.1482183 },
    { lat: 48.3385055, lng: 19.1480131 },
    { lat: 48.3383817, lng: 19.1480167 },
    { lat: 48.3383641, lng: 19.147771 },
    { lat: 48.3382075, lng: 19.1475489 },
    { lat: 48.3381678, lng: 19.1473616 },
    { lat: 48.338109, lng: 19.1472913 },
    { lat: 48.3381078, lng: 19.1467433 },
    { lat: 48.337922, lng: 19.1465872 },
    { lat: 48.3378023, lng: 19.1464709 },
    { lat: 48.3377311, lng: 19.1465348 },
    { lat: 48.3376537, lng: 19.1464995 },
    { lat: 48.3376467, lng: 19.1463763 },
    { lat: 48.3375763, lng: 19.146392 },
    { lat: 48.3374124, lng: 19.1462016 },
    { lat: 48.3373446, lng: 19.1460816 },
    { lat: 48.3373331, lng: 19.1457175 },
    { lat: 48.3370121, lng: 19.1457233 },
    { lat: 48.3369657, lng: 19.1458298 },
    { lat: 48.3368339, lng: 19.1458108 },
    { lat: 48.3366605, lng: 19.145974 },
    { lat: 48.3365823, lng: 19.1459629 },
    { lat: 48.3360399, lng: 19.1453017 },
    { lat: 48.336011, lng: 19.1450626 },
    { lat: 48.3357842, lng: 19.144942 },
    { lat: 48.3357614, lng: 19.1448924 },
    { lat: 48.3358471, lng: 19.1446685 },
    { lat: 48.3356387, lng: 19.1443048 },
    { lat: 48.335483, lng: 19.1442138 },
    { lat: 48.3351954, lng: 19.1436875 },
    { lat: 48.3352188, lng: 19.1433423 },
    { lat: 48.3350507, lng: 19.1432952 },
    { lat: 48.3347028, lng: 19.1428933 },
    { lat: 48.334333, lng: 19.1422578 },
    { lat: 48.3341486, lng: 19.1420418 },
    { lat: 48.3338857, lng: 19.1419889 },
    { lat: 48.333674, lng: 19.1418124 },
    { lat: 48.3336028, lng: 19.1418335 },
    { lat: 48.3333398, lng: 19.141533 },
    { lat: 48.3329045, lng: 19.1413442 },
    { lat: 48.3323879, lng: 19.1408882 },
    { lat: 48.3316422, lng: 19.1404436 },
    { lat: 48.3314957, lng: 19.1408554 },
    { lat: 48.3311565, lng: 19.1407668 },
    { lat: 48.3309983, lng: 19.1408068 },
    { lat: 48.3308753, lng: 19.1406657 },
    { lat: 48.3307585, lng: 19.1406536 },
    { lat: 48.3306748, lng: 19.1403955 },
    { lat: 48.3301332, lng: 19.1403632 },
    { lat: 48.3301301, lng: 19.1399164 },
    { lat: 48.3300671, lng: 19.1398505 },
    { lat: 48.3295804, lng: 19.1397631 },
    { lat: 48.3295236, lng: 19.1396189 },
    { lat: 48.3296143, lng: 19.1396527 },
    { lat: 48.3296616, lng: 19.1395397 },
    { lat: 48.3295994, lng: 19.1394652 },
    { lat: 48.3295805, lng: 19.139247 },
    { lat: 48.3295012, lng: 19.139169 },
    { lat: 48.3294623, lng: 19.1389077 },
    { lat: 48.3293501, lng: 19.1390548 },
    { lat: 48.3292726, lng: 19.1389267 },
    { lat: 48.3293402, lng: 19.1387428 },
    { lat: 48.3293179, lng: 19.1385642 },
    { lat: 48.3292241, lng: 19.138517 },
    { lat: 48.3289991, lng: 19.1387097 },
    { lat: 48.3290175, lng: 19.1384894 },
    { lat: 48.3289485, lng: 19.1383001 },
    { lat: 48.328944, lng: 19.1380526 },
    { lat: 48.328864, lng: 19.1380538 },
    { lat: 48.3287366, lng: 19.1382572 },
    { lat: 48.3287177, lng: 19.1380152 },
    { lat: 48.3287801, lng: 19.1379969 },
    { lat: 48.32879, lng: 19.1378584 },
    { lat: 48.3286962, lng: 19.1377385 },
    { lat: 48.3286271, lng: 19.1377558 },
    { lat: 48.3284795, lng: 19.1376174 },
    { lat: 48.3284699, lng: 19.1374067 },
    { lat: 48.3284056, lng: 19.137382 },
    { lat: 48.3283923, lng: 19.1372711 },
    { lat: 48.3284503, lng: 19.1371021 },
    { lat: 48.328451, lng: 19.136865 },
    { lat: 48.3283941, lng: 19.1368083 },
    { lat: 48.3282937, lng: 19.1368978 },
    { lat: 48.3282181, lng: 19.1367178 },
    { lat: 48.3280916, lng: 19.1366203 },
    { lat: 48.3280383, lng: 19.1364548 },
    { lat: 48.327858, lng: 19.1363212 },
    { lat: 48.327748, lng: 19.1361036 },
    { lat: 48.3275826, lng: 19.1362217 },
    { lat: 48.327457, lng: 19.1360345 },
    { lat: 48.3274025, lng: 19.1358209 },
    { lat: 48.3273444, lng: 19.1358306 },
    { lat: 48.3272934, lng: 19.1357375 },
    { lat: 48.3272092, lng: 19.1358114 },
    { lat: 48.3270797, lng: 19.1357245 },
    { lat: 48.3270638, lng: 19.1355846 },
    { lat: 48.3269299, lng: 19.1356102 },
    { lat: 48.3269252, lng: 19.1354729 },
    { lat: 48.3271163, lng: 19.1353488 },
    { lat: 48.3271037, lng: 19.135137 },
    { lat: 48.3267628, lng: 19.134587 },
    { lat: 48.3266126, lng: 19.134602 },
    { lat: 48.3265098, lng: 19.1344623 },
    { lat: 48.3265181, lng: 19.1340402 },
    { lat: 48.3264767, lng: 19.1338634 },
    { lat: 48.3263023, lng: 19.1338053 },
    { lat: 48.3262587, lng: 19.1336993 },
    { lat: 48.3264328, lng: 19.1336405 },
    { lat: 48.3264577, lng: 19.1335538 },
    { lat: 48.3264274, lng: 19.1334427 },
    { lat: 48.3262399, lng: 19.1332965 },
    { lat: 48.3262156, lng: 19.1330838 },
    { lat: 48.3261037, lng: 19.132938 },
    { lat: 48.3258149, lng: 19.1328878 },
    { lat: 48.3257408, lng: 19.1330569 },
    { lat: 48.3257076, lng: 19.1330429 },
    { lat: 48.3256583, lng: 19.1329361 },
    { lat: 48.3256514, lng: 19.1325922 },
    { lat: 48.3254986, lng: 19.132423 },
    { lat: 48.3254252, lng: 19.1324914 },
    { lat: 48.325338, lng: 19.132211 },
    { lat: 48.3251924, lng: 19.132137 },
    { lat: 48.3250768, lng: 19.1322838 },
    { lat: 48.324935, lng: 19.1322019 },
    { lat: 48.3248496, lng: 19.1323829 },
    { lat: 48.3246357, lng: 19.1324146 },
    { lat: 48.3245459, lng: 19.132538 },
    { lat: 48.3245201, lng: 19.1324463 },
    { lat: 48.3245796, lng: 19.1323511 },
    { lat: 48.3245108, lng: 19.1322596 },
    { lat: 48.3243263, lng: 19.1323593 },
    { lat: 48.3242946, lng: 19.132558 },
    { lat: 48.3242146, lng: 19.132607 },
    { lat: 48.3241693, lng: 19.1324225 },
    { lat: 48.3243237, lng: 19.13226 },
    { lat: 48.3242942, lng: 19.1319842 },
    { lat: 48.3241916, lng: 19.1317831 },
    { lat: 48.3238387, lng: 19.1317362 },
    { lat: 48.3237116, lng: 19.1319881 },
    { lat: 48.3236632, lng: 19.1319708 },
    { lat: 48.3236244, lng: 19.1318459 },
    { lat: 48.3234406, lng: 19.13187 },
    { lat: 48.3234313, lng: 19.1317481 },
    { lat: 48.3233002, lng: 19.1316969 },
    { lat: 48.3232847, lng: 19.1318601 },
    { lat: 48.3231655, lng: 19.1319567 },
    { lat: 48.3231179, lng: 19.131883 },
    { lat: 48.3231691, lng: 19.1317813 },
    { lat: 48.3231223, lng: 19.1316105 },
    { lat: 48.3230704, lng: 19.1315894 },
    { lat: 48.3230016, lng: 19.1316778 },
    { lat: 48.322953, lng: 19.1315837 },
    { lat: 48.322971, lng: 19.1314702 },
    { lat: 48.3228493, lng: 19.1314037 },
    { lat: 48.3228717, lng: 19.1313314 },
    { lat: 48.3229856, lng: 19.1313552 },
    { lat: 48.3229999, lng: 19.1313018 },
    { lat: 48.3228629, lng: 19.1311776 },
    { lat: 48.3229259, lng: 19.1310877 },
    { lat: 48.322919, lng: 19.1310137 },
    { lat: 48.322818, lng: 19.1310218 },
    { lat: 48.3227865, lng: 19.1309577 },
    { lat: 48.3226946, lng: 19.130992 },
    { lat: 48.3225935, lng: 19.1308401 },
    { lat: 48.3225486, lng: 19.1308876 },
    { lat: 48.3224421, lng: 19.1308552 },
    { lat: 48.3223194, lng: 19.1304067 },
    { lat: 48.3221496, lng: 19.1306166 },
    { lat: 48.3220986, lng: 19.1306124 },
    { lat: 48.3219764, lng: 19.1303537 },
    { lat: 48.3219305, lng: 19.1303376 },
    { lat: 48.3218702, lng: 19.1304176 },
    { lat: 48.3217627, lng: 19.1303893 },
    { lat: 48.3216434, lng: 19.1302801 },
    { lat: 48.3216193, lng: 19.1301811 },
    { lat: 48.3215266, lng: 19.1303537 },
    { lat: 48.3214505, lng: 19.130386 },
    { lat: 48.321404, lng: 19.130564 },
    { lat: 48.321269, lng: 19.1306599 },
    { lat: 48.3210615, lng: 19.1305457 },
    { lat: 48.3209257, lng: 19.1303799 },
    { lat: 48.3209171, lng: 19.1300936 },
    { lat: 48.3208468, lng: 19.1300373 },
    { lat: 48.3205818, lng: 19.1302866 },
    { lat: 48.3205648, lng: 19.1303958 },
    { lat: 48.3202908, lng: 19.1301913 },
    { lat: 48.3200554, lng: 19.1302888 },
    { lat: 48.3198851, lng: 19.1301513 },
    { lat: 48.3196998, lng: 19.1302301 },
    { lat: 48.3196688, lng: 19.1301298 },
  ],
  DolnéMladonice: [
    { lat: 48.3291258, lng: 19.1142631 },
    { lat: 48.3291338, lng: 19.1146502 },
    { lat: 48.3289112, lng: 19.1150705 },
    { lat: 48.3296836, lng: 19.1161576 },
    { lat: 48.3298774, lng: 19.1166276 },
    { lat: 48.3298424, lng: 19.1172136 },
    { lat: 48.3295428, lng: 19.1174031 },
    { lat: 48.3290149, lng: 19.1181634 },
    { lat: 48.3287734, lng: 19.1186351 },
    { lat: 48.3283552, lng: 19.1197009 },
    { lat: 48.3281435, lng: 19.1205391 },
    { lat: 48.3276679, lng: 19.1204291 },
    { lat: 48.3273994, lng: 19.1234778 },
    { lat: 48.3267356, lng: 19.1231668 },
    { lat: 48.3264827, lng: 19.1231402 },
    { lat: 48.3259982, lng: 19.1237531 },
    { lat: 48.3254938, lng: 19.1241313 },
    { lat: 48.3248763, lng: 19.1247 },
    { lat: 48.3247392, lng: 19.1248687 },
    { lat: 48.3246865, lng: 19.1250779 },
    { lat: 48.3241187, lng: 19.1250611 },
    { lat: 48.3235544, lng: 19.1253429 },
    { lat: 48.3231833, lng: 19.125727 },
    { lat: 48.3221326, lng: 19.127037 },
    { lat: 48.3204162, lng: 19.1285914 },
    { lat: 48.3196688, lng: 19.1301298 },
    { lat: 48.3196998, lng: 19.1302301 },
    { lat: 48.3198851, lng: 19.1301513 },
    { lat: 48.3200554, lng: 19.1302888 },
    { lat: 48.3202908, lng: 19.1301913 },
    { lat: 48.3205648, lng: 19.1303958 },
    { lat: 48.3205818, lng: 19.1302866 },
    { lat: 48.3208468, lng: 19.1300373 },
    { lat: 48.3209171, lng: 19.1300936 },
    { lat: 48.3209257, lng: 19.1303799 },
    { lat: 48.3210615, lng: 19.1305457 },
    { lat: 48.321269, lng: 19.1306599 },
    { lat: 48.321404, lng: 19.130564 },
    { lat: 48.3214505, lng: 19.130386 },
    { lat: 48.3215266, lng: 19.1303537 },
    { lat: 48.3216193, lng: 19.1301811 },
    { lat: 48.3216434, lng: 19.1302801 },
    { lat: 48.3217627, lng: 19.1303893 },
    { lat: 48.3218702, lng: 19.1304176 },
    { lat: 48.3219305, lng: 19.1303376 },
    { lat: 48.3219764, lng: 19.1303537 },
    { lat: 48.3220986, lng: 19.1306124 },
    { lat: 48.3221496, lng: 19.1306166 },
    { lat: 48.3223194, lng: 19.1304067 },
    { lat: 48.3224421, lng: 19.1308552 },
    { lat: 48.3225486, lng: 19.1308876 },
    { lat: 48.3225935, lng: 19.1308401 },
    { lat: 48.3226946, lng: 19.130992 },
    { lat: 48.3227865, lng: 19.1309577 },
    { lat: 48.322818, lng: 19.1310218 },
    { lat: 48.322919, lng: 19.1310137 },
    { lat: 48.3229259, lng: 19.1310877 },
    { lat: 48.3228629, lng: 19.1311776 },
    { lat: 48.3229999, lng: 19.1313018 },
    { lat: 48.3229856, lng: 19.1313552 },
    { lat: 48.3228717, lng: 19.1313314 },
    { lat: 48.3228493, lng: 19.1314037 },
    { lat: 48.322971, lng: 19.1314702 },
    { lat: 48.322953, lng: 19.1315837 },
    { lat: 48.3230016, lng: 19.1316778 },
    { lat: 48.3230704, lng: 19.1315894 },
    { lat: 48.3231223, lng: 19.1316105 },
    { lat: 48.3231691, lng: 19.1317813 },
    { lat: 48.3231179, lng: 19.131883 },
    { lat: 48.3231655, lng: 19.1319567 },
    { lat: 48.3232847, lng: 19.1318601 },
    { lat: 48.3233002, lng: 19.1316969 },
    { lat: 48.3234313, lng: 19.1317481 },
    { lat: 48.3234406, lng: 19.13187 },
    { lat: 48.3236244, lng: 19.1318459 },
    { lat: 48.3236632, lng: 19.1319708 },
    { lat: 48.3237116, lng: 19.1319881 },
    { lat: 48.3238387, lng: 19.1317362 },
    { lat: 48.3241916, lng: 19.1317831 },
    { lat: 48.3242942, lng: 19.1319842 },
    { lat: 48.3243237, lng: 19.13226 },
    { lat: 48.3241693, lng: 19.1324225 },
    { lat: 48.3242146, lng: 19.132607 },
    { lat: 48.3242946, lng: 19.132558 },
    { lat: 48.3243263, lng: 19.1323593 },
    { lat: 48.3245108, lng: 19.1322596 },
    { lat: 48.3245796, lng: 19.1323511 },
    { lat: 48.3245201, lng: 19.1324463 },
    { lat: 48.3245459, lng: 19.132538 },
    { lat: 48.3246357, lng: 19.1324146 },
    { lat: 48.3248496, lng: 19.1323829 },
    { lat: 48.324935, lng: 19.1322019 },
    { lat: 48.3250768, lng: 19.1322838 },
    { lat: 48.3251924, lng: 19.132137 },
    { lat: 48.325338, lng: 19.132211 },
    { lat: 48.3254252, lng: 19.1324914 },
    { lat: 48.3254986, lng: 19.132423 },
    { lat: 48.3256514, lng: 19.1325922 },
    { lat: 48.3256583, lng: 19.1329361 },
    { lat: 48.3257076, lng: 19.1330429 },
    { lat: 48.3257408, lng: 19.1330569 },
    { lat: 48.3258149, lng: 19.1328878 },
    { lat: 48.3261037, lng: 19.132938 },
    { lat: 48.3262156, lng: 19.1330838 },
    { lat: 48.3262399, lng: 19.1332965 },
    { lat: 48.3264274, lng: 19.1334427 },
    { lat: 48.3264577, lng: 19.1335538 },
    { lat: 48.3264328, lng: 19.1336405 },
    { lat: 48.3262587, lng: 19.1336993 },
    { lat: 48.3263023, lng: 19.1338053 },
    { lat: 48.3264767, lng: 19.1338634 },
    { lat: 48.3265181, lng: 19.1340402 },
    { lat: 48.3265098, lng: 19.1344623 },
    { lat: 48.3266126, lng: 19.134602 },
    { lat: 48.3267628, lng: 19.134587 },
    { lat: 48.3271037, lng: 19.135137 },
    { lat: 48.3271163, lng: 19.1353488 },
    { lat: 48.3269252, lng: 19.1354729 },
    { lat: 48.3269299, lng: 19.1356102 },
    { lat: 48.3270638, lng: 19.1355846 },
    { lat: 48.3270797, lng: 19.1357245 },
    { lat: 48.3272092, lng: 19.1358114 },
    { lat: 48.3272934, lng: 19.1357375 },
    { lat: 48.3273444, lng: 19.1358306 },
    { lat: 48.3274025, lng: 19.1358209 },
    { lat: 48.327457, lng: 19.1360345 },
    { lat: 48.3275826, lng: 19.1362217 },
    { lat: 48.327748, lng: 19.1361036 },
    { lat: 48.327858, lng: 19.1363212 },
    { lat: 48.3280383, lng: 19.1364548 },
    { lat: 48.3280916, lng: 19.1366203 },
    { lat: 48.3282181, lng: 19.1367178 },
    { lat: 48.3282937, lng: 19.1368978 },
    { lat: 48.3283941, lng: 19.1368083 },
    { lat: 48.328451, lng: 19.136865 },
    { lat: 48.3284503, lng: 19.1371021 },
    { lat: 48.3283923, lng: 19.1372711 },
    { lat: 48.3284056, lng: 19.137382 },
    { lat: 48.3284699, lng: 19.1374067 },
    { lat: 48.3284795, lng: 19.1376174 },
    { lat: 48.3286271, lng: 19.1377558 },
    { lat: 48.3286962, lng: 19.1377385 },
    { lat: 48.32879, lng: 19.1378584 },
    { lat: 48.3287801, lng: 19.1379969 },
    { lat: 48.3287177, lng: 19.1380152 },
    { lat: 48.3287366, lng: 19.1382572 },
    { lat: 48.328864, lng: 19.1380538 },
    { lat: 48.328944, lng: 19.1380526 },
    { lat: 48.3289485, lng: 19.1383001 },
    { lat: 48.3290175, lng: 19.1384894 },
    { lat: 48.3289991, lng: 19.1387097 },
    { lat: 48.3292241, lng: 19.138517 },
    { lat: 48.3293179, lng: 19.1385642 },
    { lat: 48.3293402, lng: 19.1387428 },
    { lat: 48.3292726, lng: 19.1389267 },
    { lat: 48.3293501, lng: 19.1390548 },
    { lat: 48.3294623, lng: 19.1389077 },
    { lat: 48.3295012, lng: 19.139169 },
    { lat: 48.3295805, lng: 19.139247 },
    { lat: 48.3295994, lng: 19.1394652 },
    { lat: 48.3296616, lng: 19.1395397 },
    { lat: 48.3296143, lng: 19.1396527 },
    { lat: 48.3295236, lng: 19.1396189 },
    { lat: 48.3295804, lng: 19.1397631 },
    { lat: 48.3300671, lng: 19.1398505 },
    { lat: 48.3301301, lng: 19.1399164 },
    { lat: 48.3301332, lng: 19.1403632 },
    { lat: 48.3306748, lng: 19.1403955 },
    { lat: 48.3307585, lng: 19.1406536 },
    { lat: 48.3308753, lng: 19.1406657 },
    { lat: 48.3309983, lng: 19.1408068 },
    { lat: 48.3311565, lng: 19.1407668 },
    { lat: 48.3314957, lng: 19.1408554 },
    { lat: 48.3316422, lng: 19.1404436 },
    { lat: 48.3323879, lng: 19.1408882 },
    { lat: 48.3329045, lng: 19.1413442 },
    { lat: 48.3333398, lng: 19.141533 },
    { lat: 48.3336028, lng: 19.1418335 },
    { lat: 48.333674, lng: 19.1418124 },
    { lat: 48.3338857, lng: 19.1419889 },
    { lat: 48.3341486, lng: 19.1420418 },
    { lat: 48.334333, lng: 19.1422578 },
    { lat: 48.3347028, lng: 19.1428933 },
    { lat: 48.3350507, lng: 19.1432952 },
    { lat: 48.3352188, lng: 19.1433423 },
    { lat: 48.3351954, lng: 19.1436875 },
    { lat: 48.335483, lng: 19.1442138 },
    { lat: 48.3356387, lng: 19.1443048 },
    { lat: 48.3358471, lng: 19.1446685 },
    { lat: 48.3357614, lng: 19.1448924 },
    { lat: 48.3357842, lng: 19.144942 },
    { lat: 48.336011, lng: 19.1450626 },
    { lat: 48.3360399, lng: 19.1453017 },
    { lat: 48.3365823, lng: 19.1459629 },
    { lat: 48.3366605, lng: 19.145974 },
    { lat: 48.3368339, lng: 19.1458108 },
    { lat: 48.3369657, lng: 19.1458298 },
    { lat: 48.3370121, lng: 19.1457233 },
    { lat: 48.3373331, lng: 19.1457175 },
    { lat: 48.3373446, lng: 19.1460816 },
    { lat: 48.3374124, lng: 19.1462016 },
    { lat: 48.3375763, lng: 19.146392 },
    { lat: 48.3376467, lng: 19.1463763 },
    { lat: 48.3376537, lng: 19.1464995 },
    { lat: 48.3377311, lng: 19.1465348 },
    { lat: 48.3378023, lng: 19.1464709 },
    { lat: 48.337922, lng: 19.1465872 },
    { lat: 48.3372281, lng: 19.1449391 },
    { lat: 48.3376175, lng: 19.1442692 },
    { lat: 48.3383491, lng: 19.1433789 },
    { lat: 48.3388605, lng: 19.1417596 },
    { lat: 48.339266, lng: 19.1398451 },
    { lat: 48.3394246, lng: 19.1400003 },
    { lat: 48.3396043, lng: 19.1388524 },
    { lat: 48.3396255, lng: 19.1384358 },
    { lat: 48.3398865, lng: 19.1370194 },
    { lat: 48.3400296, lng: 19.1368797 },
    { lat: 48.3401433, lng: 19.1365396 },
    { lat: 48.34035, lng: 19.136182 },
    { lat: 48.3407843, lng: 19.1363223 },
    { lat: 48.3412709, lng: 19.1367501 },
    { lat: 48.3432963, lng: 19.1387827 },
    { lat: 48.34409, lng: 19.139306 },
    { lat: 48.3446517, lng: 19.139947 },
    { lat: 48.3455228, lng: 19.1405758 },
    { lat: 48.3458282, lng: 19.1410038 },
    { lat: 48.3459241, lng: 19.141256 },
    { lat: 48.3465865, lng: 19.1423363 },
    { lat: 48.3467251, lng: 19.1419022 },
    { lat: 48.3482187, lng: 19.1425374 },
    { lat: 48.3485629, lng: 19.1412472 },
    { lat: 48.3489026, lng: 19.1396442 },
    { lat: 48.3495117, lng: 19.1402165 },
    { lat: 48.3505332, lng: 19.1414445 },
    { lat: 48.3512405, lng: 19.1410036 },
    { lat: 48.3517156, lng: 19.1405132 },
    { lat: 48.3523205, lng: 19.1402733 },
    { lat: 48.3523379, lng: 19.1401725 },
    { lat: 48.3529488, lng: 19.1408052 },
    { lat: 48.3532071, lng: 19.1401026 },
    { lat: 48.3535404, lng: 19.140345 },
    { lat: 48.3543104, lng: 19.1410766 },
    { lat: 48.3544592, lng: 19.1403951 },
    { lat: 48.3557986, lng: 19.1412594 },
    { lat: 48.356328, lng: 19.1418415 },
    { lat: 48.3566845, lng: 19.1402562 },
    { lat: 48.3575844, lng: 19.1408539 },
    { lat: 48.3586165, lng: 19.1418381 },
    { lat: 48.3585078, lng: 19.142422 },
    { lat: 48.3611951, lng: 19.1436407 },
    { lat: 48.361106, lng: 19.144711 },
    { lat: 48.3607423, lng: 19.1462188 },
    { lat: 48.3619052, lng: 19.1473899 },
    { lat: 48.3628927, lng: 19.1477341 },
    { lat: 48.3628486, lng: 19.1483878 },
    { lat: 48.3636023, lng: 19.1484004 },
    { lat: 48.3635601, lng: 19.1491404 },
    { lat: 48.3639384, lng: 19.1492903 },
    { lat: 48.3642535, lng: 19.1496657 },
    { lat: 48.3646651, lng: 19.1502827 },
    { lat: 48.3654895, lng: 19.151155 },
    { lat: 48.3654662, lng: 19.1512324 },
    { lat: 48.3664672, lng: 19.1524363 },
    { lat: 48.3661535, lng: 19.1535246 },
    { lat: 48.3666647, lng: 19.1539444 },
    { lat: 48.3672298, lng: 19.1547698 },
    { lat: 48.3669677, lng: 19.1551674 },
    { lat: 48.3675353, lng: 19.1561599 },
    { lat: 48.3683149, lng: 19.157149 },
    { lat: 48.3681584, lng: 19.1578393 },
    { lat: 48.3680636, lng: 19.1578573 },
    { lat: 48.3681647, lng: 19.1587388 },
    { lat: 48.368134, lng: 19.1590308 },
    { lat: 48.3684035, lng: 19.159327 },
    { lat: 48.368357, lng: 19.1594793 },
    { lat: 48.3691877, lng: 19.1602096 },
    { lat: 48.3705501, lng: 19.1609272 },
    { lat: 48.3706725, lng: 19.1609181 },
    { lat: 48.3713238, lng: 19.1611852 },
    { lat: 48.3713623, lng: 19.1614635 },
    { lat: 48.3716195, lng: 19.1616473 },
    { lat: 48.3722897, lng: 19.1617527 },
    { lat: 48.3727999, lng: 19.161653 },
    { lat: 48.3732626, lng: 19.1632332 },
    { lat: 48.3742079, lng: 19.1645689 },
    { lat: 48.3744182, lng: 19.165105 },
    { lat: 48.3749878, lng: 19.1659592 },
    { lat: 48.3760294, lng: 19.1677382 },
    { lat: 48.3765222, lng: 19.1683805 },
    { lat: 48.3767619, lng: 19.1687804 },
    { lat: 48.3769764, lng: 19.1689721 },
    { lat: 48.3780125, lng: 19.1710497 },
    { lat: 48.3781401, lng: 19.1715347 },
    { lat: 48.3782126, lng: 19.1717965 },
    { lat: 48.3786322, lng: 19.1718701 },
    { lat: 48.3791574, lng: 19.1713166 },
    { lat: 48.3801023, lng: 19.1696562 },
    { lat: 48.3814149, lng: 19.1671743 },
    { lat: 48.3806565, lng: 19.165881 },
    { lat: 48.3801899, lng: 19.1661759 },
    { lat: 48.3799585, lng: 19.1656718 },
    { lat: 48.3797246, lng: 19.1649064 },
    { lat: 48.3802106, lng: 19.1644048 },
    { lat: 48.3797583, lng: 19.163326 },
    { lat: 48.378807, lng: 19.1616909 },
    { lat: 48.3784159, lng: 19.160255 },
    { lat: 48.3775881, lng: 19.1585388 },
    { lat: 48.3769844, lng: 19.1577288 },
    { lat: 48.3763166, lng: 19.1569664 },
    { lat: 48.3758148, lng: 19.1564636 },
    { lat: 48.375213, lng: 19.1559881 },
    { lat: 48.374033, lng: 19.1542653 },
    { lat: 48.3732697, lng: 19.153576 },
    { lat: 48.3727734, lng: 19.1530471 },
    { lat: 48.3723914, lng: 19.1521412 },
    { lat: 48.3722686, lng: 19.151393 },
    { lat: 48.3717543, lng: 19.1507288 },
    { lat: 48.370489, lng: 19.1499883 },
    { lat: 48.3701674, lng: 19.149687 },
    { lat: 48.3698905, lng: 19.1492425 },
    { lat: 48.3697447, lng: 19.1488464 },
    { lat: 48.3686167, lng: 19.1483122 },
    { lat: 48.3676922, lng: 19.1472192 },
    { lat: 48.3672634, lng: 19.1465732 },
    { lat: 48.3666982, lng: 19.1459277 },
    { lat: 48.3669501, lng: 19.1454159 },
    { lat: 48.3679495, lng: 19.1465193 },
    { lat: 48.3681092, lng: 19.1462381 },
    { lat: 48.3670275, lng: 19.145113 },
    { lat: 48.3663736, lng: 19.144337 },
    { lat: 48.3652179, lng: 19.1432221 },
    { lat: 48.3650439, lng: 19.1426226 },
    { lat: 48.364672, lng: 19.1422369 },
    { lat: 48.3640547, lng: 19.1419319 },
    { lat: 48.3637201, lng: 19.1424997 },
    { lat: 48.3628502, lng: 19.1413752 },
    { lat: 48.3621066, lng: 19.1408614 },
    { lat: 48.3613887, lng: 19.1405006 },
    { lat: 48.3611472, lng: 19.1399037 },
    { lat: 48.3607472, lng: 19.1394568 },
    { lat: 48.3605251, lng: 19.1389793 },
    { lat: 48.3601981, lng: 19.1387745 },
    { lat: 48.3599542, lng: 19.1384909 },
    { lat: 48.3601554, lng: 19.1379795 },
    { lat: 48.3594256, lng: 19.1372944 },
    { lat: 48.3581055, lng: 19.1363382 },
    { lat: 48.3565194, lng: 19.1352729 },
    { lat: 48.3560477, lng: 19.1351261 },
    { lat: 48.3559392, lng: 19.135034 },
    { lat: 48.3556159, lng: 19.1347406 },
    { lat: 48.3556629, lng: 19.134328 },
    { lat: 48.3548507, lng: 19.1333194 },
    { lat: 48.3543864, lng: 19.1329602 },
    { lat: 48.3536534, lng: 19.132249 },
    { lat: 48.3527543, lng: 19.1321731 },
    { lat: 48.3524903, lng: 19.1320081 },
    { lat: 48.3522738, lng: 19.131965 },
    { lat: 48.3510871, lng: 19.130589 },
    { lat: 48.350386, lng: 19.130272 },
    { lat: 48.3499097, lng: 19.1296955 },
    { lat: 48.3497365, lng: 19.1293155 },
    { lat: 48.3495918, lng: 19.1291701 },
    { lat: 48.349012, lng: 19.1288693 },
    { lat: 48.348375, lng: 19.1283264 },
    { lat: 48.3481914, lng: 19.1282637 },
    { lat: 48.3469511, lng: 19.1274181 },
    { lat: 48.3464734, lng: 19.1272891 },
    { lat: 48.345882, lng: 19.1269347 },
    { lat: 48.3454299, lng: 19.126571 },
    { lat: 48.3431005, lng: 19.1250988 },
    { lat: 48.342934, lng: 19.1247861 },
    { lat: 48.3427062, lng: 19.1245561 },
    { lat: 48.3410427, lng: 19.1221919 },
    { lat: 48.340488, lng: 19.1215563 },
    { lat: 48.3400052, lng: 19.1199882 },
    { lat: 48.3397568, lng: 19.1193939 },
    { lat: 48.3394242, lng: 19.1188486 },
    { lat: 48.3388628, lng: 19.1177433 },
    { lat: 48.3387123, lng: 19.1176188 },
    { lat: 48.3376845, lng: 19.1163604 },
    { lat: 48.3371299, lng: 19.1154815 },
    { lat: 48.3371697, lng: 19.1154398 },
    { lat: 48.3364783, lng: 19.114054 },
    { lat: 48.336401, lng: 19.1136141 },
    { lat: 48.3330173, lng: 19.110058 },
    { lat: 48.3329243, lng: 19.1101822 },
    { lat: 48.3325333, lng: 19.1105464 },
    { lat: 48.3315541, lng: 19.1116643 },
    { lat: 48.3309812, lng: 19.1128801 },
    { lat: 48.3305041, lng: 19.1144106 },
    { lat: 48.3297501, lng: 19.1150703 },
    { lat: 48.3293996, lng: 19.1145566 },
    { lat: 48.3291258, lng: 19.1142631 },
  ],
  Selce: [
    { lat: 48.253491, lng: 19.0209793 },
    { lat: 48.2534138, lng: 19.0210355 },
    { lat: 48.2513886, lng: 19.0229395 },
    { lat: 48.2503181, lng: 19.0246848 },
    { lat: 48.2499626, lng: 19.0251243 },
    { lat: 48.2498341, lng: 19.0253671 },
    { lat: 48.2494052, lng: 19.0254265 },
    { lat: 48.2490937, lng: 19.0265544 },
    { lat: 48.2485036, lng: 19.0280977 },
    { lat: 48.2477305, lng: 19.0296844 },
    { lat: 48.2475734, lng: 19.0301873 },
    { lat: 48.2472707, lng: 19.0307329 },
    { lat: 48.2461986, lng: 19.0313578 },
    { lat: 48.2455283, lng: 19.0321485 },
    { lat: 48.2447222, lng: 19.0318832 },
    { lat: 48.2438484, lng: 19.0321247 },
    { lat: 48.243466, lng: 19.031944 },
    { lat: 48.2430015, lng: 19.0328991 },
    { lat: 48.241864, lng: 19.0347466 },
    { lat: 48.2409723, lng: 19.0363536 },
    { lat: 48.2406073, lng: 19.0364714 },
    { lat: 48.2400215, lng: 19.0369016 },
    { lat: 48.2395544, lng: 19.0371629 },
    { lat: 48.2390973, lng: 19.0355985 },
    { lat: 48.2389005, lng: 19.0347179 },
    { lat: 48.2389228, lng: 19.0344441 },
    { lat: 48.2387846, lng: 19.0335208 },
    { lat: 48.2386324, lng: 19.0330546 },
    { lat: 48.2386402, lng: 19.03263 },
    { lat: 48.2382824, lng: 19.0304853 },
    { lat: 48.2383047, lng: 19.0298575 },
    { lat: 48.2379846, lng: 19.0292636 },
    { lat: 48.2376383, lng: 19.0283697 },
    { lat: 48.2376039, lng: 19.0281568 },
    { lat: 48.2374625, lng: 19.0279497 },
    { lat: 48.2374637, lng: 19.0277749 },
    { lat: 48.2373589, lng: 19.0274176 },
    { lat: 48.2372255, lng: 19.0266101 },
    { lat: 48.237207, lng: 19.0262677 },
    { lat: 48.2369811, lng: 19.0257502 },
    { lat: 48.2370168, lng: 19.0253656 },
    { lat: 48.2368676, lng: 19.0250936 },
    { lat: 48.2366315, lng: 19.0237274 },
    { lat: 48.236534, lng: 19.0233852 },
    { lat: 48.2363917, lng: 19.0232903 },
    { lat: 48.2363107, lng: 19.0228727 },
    { lat: 48.2364179, lng: 19.0223409 },
    { lat: 48.2360926, lng: 19.0224406 },
    { lat: 48.2359923, lng: 19.0225772 },
    { lat: 48.2355763, lng: 19.0224905 },
    { lat: 48.2353489, lng: 19.0223382 },
    { lat: 48.2352051, lng: 19.0224102 },
    { lat: 48.2347189, lng: 19.0224997 },
    { lat: 48.2344648, lng: 19.0227048 },
    { lat: 48.2340889, lng: 19.0232359 },
    { lat: 48.2339944, lng: 19.0235536 },
    { lat: 48.233817, lng: 19.0236552 },
    { lat: 48.2336262, lng: 19.023894 },
    { lat: 48.2333808, lng: 19.0243707 },
    { lat: 48.2331577, lng: 19.0246234 },
    { lat: 48.2330888, lng: 19.0247014 },
    { lat: 48.2327285, lng: 19.0249574 },
    { lat: 48.2325523, lng: 19.0252151 },
    { lat: 48.2324355, lng: 19.025246 },
    { lat: 48.2324023, lng: 19.0253262 },
    { lat: 48.2324569, lng: 19.0256669 },
    { lat: 48.2322048, lng: 19.0262572 },
    { lat: 48.2321347, lng: 19.0262489 },
    { lat: 48.2317774, lng: 19.0267881 },
    { lat: 48.23169, lng: 19.0268405 },
    { lat: 48.2310455, lng: 19.0278032 },
    { lat: 48.2307132, lng: 19.0286773 },
    { lat: 48.2298633, lng: 19.0302807 },
    { lat: 48.2292867, lng: 19.0312059 },
    { lat: 48.2285157, lng: 19.0321453 },
    { lat: 48.2281955, lng: 19.0324335 },
    { lat: 48.2279801, lng: 19.0328766 },
    { lat: 48.2279575, lng: 19.0338297 },
    { lat: 48.2276085, lng: 19.0347321 },
    { lat: 48.2269174, lng: 19.0356864 },
    { lat: 48.2269419, lng: 19.0357028 },
    { lat: 48.2272757, lng: 19.0359265 },
    { lat: 48.2276095, lng: 19.0363497 },
    { lat: 48.2276791, lng: 19.036567 },
    { lat: 48.2275434, lng: 19.0370732 },
    { lat: 48.2276466, lng: 19.0376184 },
    { lat: 48.2277812, lng: 19.0379621 },
    { lat: 48.2282347, lng: 19.0385418 },
    { lat: 48.2283919, lng: 19.0388422 },
    { lat: 48.2285323, lng: 19.0389231 },
    { lat: 48.2287147, lng: 19.038895 },
    { lat: 48.2292688, lng: 19.0383926 },
    { lat: 48.2296837, lng: 19.0382585 },
    { lat: 48.2298614, lng: 19.038273 },
    { lat: 48.2300579, lng: 19.0383893 },
    { lat: 48.2312071, lng: 19.0400216 },
    { lat: 48.231347, lng: 19.0408258 },
    { lat: 48.2314563, lng: 19.0410472 },
    { lat: 48.2322062, lng: 19.0419855 },
    { lat: 48.2326545, lng: 19.0423098 },
    { lat: 48.2328541, lng: 19.042244 },
    { lat: 48.233456, lng: 19.0425495 },
    { lat: 48.2338563, lng: 19.0431304 },
    { lat: 48.233996, lng: 19.0435524 },
    { lat: 48.2340992, lng: 19.0437009 },
    { lat: 48.2344735, lng: 19.0439675 },
    { lat: 48.2348534, lng: 19.0441441 },
    { lat: 48.2352409, lng: 19.0444499 },
    { lat: 48.2358204, lng: 19.0450264 },
    { lat: 48.236322, lng: 19.0450473 },
    { lat: 48.2368281, lng: 19.0446454 },
    { lat: 48.2372576, lng: 19.0445101 },
    { lat: 48.2376504, lng: 19.0447177 },
    { lat: 48.2379899, lng: 19.045592 },
    { lat: 48.2384218, lng: 19.0459038 },
    { lat: 48.2388469, lng: 19.0465272 },
    { lat: 48.2390661, lng: 19.0476529 },
    { lat: 48.2394415, lng: 19.047964 },
    { lat: 48.2400707, lng: 19.0481424 },
    { lat: 48.2405685, lng: 19.0486016 },
    { lat: 48.2406315, lng: 19.048488 },
    { lat: 48.2419422, lng: 19.0460005 },
    { lat: 48.2422072, lng: 19.0450243 },
    { lat: 48.2426571, lng: 19.0424854 },
    { lat: 48.2427225, lng: 19.041883 },
    { lat: 48.2431411, lng: 19.0418903 },
    { lat: 48.2455997, lng: 19.0422378 },
    { lat: 48.2459547, lng: 19.0425915 },
    { lat: 48.2469185, lng: 19.0433449 },
    { lat: 48.2471681, lng: 19.0434634 },
    { lat: 48.2482982, lng: 19.0444318 },
    { lat: 48.249744, lng: 19.0461989 },
    { lat: 48.2498043, lng: 19.0463881 },
    { lat: 48.2505503, lng: 19.0474273 },
    { lat: 48.2506052, lng: 19.0477328 },
    { lat: 48.2506104, lng: 19.0477053 },
    { lat: 48.2517727, lng: 19.0491531 },
    { lat: 48.2539398, lng: 19.0513386 },
    { lat: 48.2550026, lng: 19.0520704 },
    { lat: 48.2560639, lng: 19.0525912 },
    { lat: 48.2563511, lng: 19.0526507 },
    { lat: 48.2568796, lng: 19.0526223 },
    { lat: 48.2572561, lng: 19.0524654 },
    { lat: 48.2575934, lng: 19.0520659 },
    { lat: 48.2578319, lng: 19.0515864 },
    { lat: 48.2578484, lng: 19.0514225 },
    { lat: 48.258056, lng: 19.0512946 },
    { lat: 48.2580765, lng: 19.0510747 },
    { lat: 48.2582069, lng: 19.0509102 },
    { lat: 48.2582551, lng: 19.0507279 },
    { lat: 48.2582535, lng: 19.0504952 },
    { lat: 48.2585087, lng: 19.0497424 },
    { lat: 48.2584917, lng: 19.0494956 },
    { lat: 48.2587104, lng: 19.049364 },
    { lat: 48.2586447, lng: 19.0492458 },
    { lat: 48.2586775, lng: 19.0491561 },
    { lat: 48.2594032, lng: 19.0488131 },
    { lat: 48.2594271, lng: 19.0486172 },
    { lat: 48.2595989, lng: 19.0486041 },
    { lat: 48.2599626, lng: 19.048196 },
    { lat: 48.2601439, lng: 19.0478562 },
    { lat: 48.2603019, lng: 19.0472574 },
    { lat: 48.2605078, lng: 19.0468009 },
    { lat: 48.2608188, lng: 19.04584 },
    { lat: 48.260787, lng: 19.0453012 },
    { lat: 48.2609659, lng: 19.0448479 },
    { lat: 48.2608988, lng: 19.0444797 },
    { lat: 48.2610731, lng: 19.0442441 },
    { lat: 48.2611285, lng: 19.0440273 },
    { lat: 48.26129, lng: 19.043923 },
    { lat: 48.2613851, lng: 19.043569 },
    { lat: 48.2613986, lng: 19.043347 },
    { lat: 48.2621508, lng: 19.042215 },
    { lat: 48.2623562, lng: 19.0415498 },
    { lat: 48.2620254, lng: 19.0410959 },
    { lat: 48.2617467, lng: 19.041182 },
    { lat: 48.2613045, lng: 19.0410203 },
    { lat: 48.2612102, lng: 19.0407854 },
    { lat: 48.2608195, lng: 19.040685 },
    { lat: 48.2604429, lng: 19.0408141 },
    { lat: 48.2602083, lng: 19.0405906 },
    { lat: 48.2600447, lng: 19.040341 },
    { lat: 48.2599481, lng: 19.0401266 },
    { lat: 48.2597422, lng: 19.0391129 },
    { lat: 48.2597828, lng: 19.0387983 },
    { lat: 48.2599353, lng: 19.038475 },
    { lat: 48.2597514, lng: 19.0377999 },
    { lat: 48.2598572, lng: 19.0374404 },
    { lat: 48.2596759, lng: 19.0371125 },
    { lat: 48.2596605, lng: 19.0361653 },
    { lat: 48.2594681, lng: 19.0359023 },
    { lat: 48.259453, lng: 19.0358046 },
    { lat: 48.2597094, lng: 19.0353905 },
    { lat: 48.2599546, lng: 19.0347548 },
    { lat: 48.2602263, lng: 19.0345551 },
    { lat: 48.2602991, lng: 19.0340787 },
    { lat: 48.2604187, lng: 19.0340804 },
    { lat: 48.2606941, lng: 19.0335519 },
    { lat: 48.2610193, lng: 19.0332517 },
    { lat: 48.2616893, lng: 19.0323001 },
    { lat: 48.2620206, lng: 19.031983 },
    { lat: 48.2622708, lng: 19.0319375 },
    { lat: 48.2625406, lng: 19.0314812 },
    { lat: 48.2633043, lng: 19.0309516 },
    { lat: 48.2644824, lng: 19.0307065 },
    { lat: 48.2646181, lng: 19.0308479 },
    { lat: 48.264732, lng: 19.0308724 },
    { lat: 48.2648715, lng: 19.0307748 },
    { lat: 48.2652386, lng: 19.0307419 },
    { lat: 48.2654362, lng: 19.0303935 },
    { lat: 48.2654444, lng: 19.0303795 },
    { lat: 48.2654955, lng: 19.0298963 },
    { lat: 48.2654354, lng: 19.0293996 },
    { lat: 48.2652501, lng: 19.0288106 },
    { lat: 48.2650142, lng: 19.0284326 },
    { lat: 48.2645613, lng: 19.0279707 },
    { lat: 48.264022, lng: 19.0272508 },
    { lat: 48.263885, lng: 19.0265753 },
    { lat: 48.2637083, lng: 19.0260027 },
    { lat: 48.2634622, lng: 19.0256192 },
    { lat: 48.2626008, lng: 19.0251536 },
    { lat: 48.2611387, lng: 19.0249786 },
    { lat: 48.2605329, lng: 19.0251268 },
    { lat: 48.2599794, lng: 19.0239093 },
    { lat: 48.25968, lng: 19.0238284 },
    { lat: 48.2589564, lng: 19.0238121 },
    { lat: 48.2584152, lng: 19.0237194 },
    { lat: 48.2584125, lng: 19.0236009 },
    { lat: 48.2581355, lng: 19.0232085 },
    { lat: 48.2578871, lng: 19.023068 },
    { lat: 48.2577989, lng: 19.0234659 },
    { lat: 48.2576494, lng: 19.0233838 },
    { lat: 48.2574899, lng: 19.0234628 },
    { lat: 48.2574184, lng: 19.0232555 },
    { lat: 48.256985, lng: 19.0229136 },
    { lat: 48.2567227, lng: 19.0232275 },
    { lat: 48.2562066, lng: 19.0232127 },
    { lat: 48.2557715, lng: 19.0226378 },
    { lat: 48.2552543, lng: 19.0226468 },
    { lat: 48.2544563, lng: 19.0223832 },
    { lat: 48.253927, lng: 19.0220193 },
    { lat: 48.253491, lng: 19.0209793 },
  ],
  Drážovce: [
    { lat: 48.2336944, lng: 18.8962885 },
    { lat: 48.2339578, lng: 18.8968259 },
    { lat: 48.2340571, lng: 18.8973049 },
    { lat: 48.2342927, lng: 18.8974325 },
    { lat: 48.2345008, lng: 18.897421 },
    { lat: 48.2348227, lng: 18.8972127 },
    { lat: 48.2357826, lng: 18.8967863 },
    { lat: 48.2363951, lng: 18.8966383 },
    { lat: 48.2369804, lng: 18.8962462 },
    { lat: 48.237352, lng: 18.8958893 },
    { lat: 48.2377247, lng: 18.8956623 },
    { lat: 48.2380188, lng: 18.8950523 },
    { lat: 48.2389869, lng: 18.8943684 },
    { lat: 48.2393108, lng: 18.8940268 },
    { lat: 48.2394996, lng: 18.8935991 },
    { lat: 48.239838, lng: 18.8931901 },
    { lat: 48.2401754, lng: 18.8932173 },
    { lat: 48.2408059, lng: 18.8927 },
    { lat: 48.2414861, lng: 18.8924843 },
    { lat: 48.2421053, lng: 18.8940295 },
    { lat: 48.2435739, lng: 18.8968195 },
    { lat: 48.2437227, lng: 18.897186 },
    { lat: 48.2437332, lng: 18.8974749 },
    { lat: 48.2438625, lng: 18.8978599 },
    { lat: 48.2437251, lng: 18.8980337 },
    { lat: 48.2431355, lng: 18.8984971 },
    { lat: 48.243571, lng: 18.8997181 },
    { lat: 48.2439521, lng: 18.9001097 },
    { lat: 48.2440486, lng: 18.9004663 },
    { lat: 48.2445087, lng: 18.9011656 },
    { lat: 48.2441909, lng: 18.9024089 },
    { lat: 48.2440857, lng: 18.904076 },
    { lat: 48.2439483, lng: 18.9041015 },
    { lat: 48.2436846, lng: 18.9058878 },
    { lat: 48.2438163, lng: 18.9070806 },
    { lat: 48.2443886, lng: 18.908986 },
    { lat: 48.2442056, lng: 18.9090447 },
    { lat: 48.2440441, lng: 18.9088455 },
    { lat: 48.244041, lng: 18.9085905 },
    { lat: 48.2439466, lng: 18.9084189 },
    { lat: 48.2436949, lng: 18.9085461 },
    { lat: 48.2435199, lng: 18.9086747 },
    { lat: 48.2435054, lng: 18.9088515 },
    { lat: 48.2436848, lng: 18.9089849 },
    { lat: 48.2437454, lng: 18.9091091 },
    { lat: 48.2437493, lng: 18.9092487 },
    { lat: 48.243667, lng: 18.909444 },
    { lat: 48.2433803, lng: 18.9096333 },
    { lat: 48.2429876, lng: 18.9102583 },
    { lat: 48.2427456, lng: 18.9102266 },
    { lat: 48.2425279, lng: 18.9100736 },
    { lat: 48.2423586, lng: 18.9102 },
    { lat: 48.2421133, lng: 18.910624 },
    { lat: 48.2419364, lng: 18.911213 },
    { lat: 48.2420486, lng: 18.9118335 },
    { lat: 48.2418784, lng: 18.912271 },
    { lat: 48.2418251, lng: 18.9126377 },
    { lat: 48.2417982, lng: 18.9130546 },
    { lat: 48.2418282, lng: 18.9135475 },
    { lat: 48.2415461, lng: 18.913739 },
    { lat: 48.2414802, lng: 18.9138818 },
    { lat: 48.2414235, lng: 18.9143751 },
    { lat: 48.2415194, lng: 18.9144414 },
    { lat: 48.2421336, lng: 18.9156536 },
    { lat: 48.2419724, lng: 18.9158764 },
    { lat: 48.2424943, lng: 18.916091 },
    { lat: 48.2426521, lng: 18.9159348 },
    { lat: 48.2429358, lng: 18.9159284 },
    { lat: 48.2431552, lng: 18.9160494 },
    { lat: 48.2438551, lng: 18.9169361 },
    { lat: 48.2440564, lng: 18.9169255 },
    { lat: 48.2442633, lng: 18.9171117 },
    { lat: 48.2442817, lng: 18.9173572 },
    { lat: 48.2444615, lng: 18.9175397 },
    { lat: 48.2449753, lng: 18.918429 },
    { lat: 48.2451576, lng: 18.9184634 },
    { lat: 48.2452147, lng: 18.9188045 },
    { lat: 48.2454482, lng: 18.918805 },
    { lat: 48.2455807, lng: 18.9189906 },
    { lat: 48.2459255, lng: 18.9189881 },
    { lat: 48.2460543, lng: 18.9191887 },
    { lat: 48.2464071, lng: 18.9192313 },
    { lat: 48.2464677, lng: 18.9193976 },
    { lat: 48.2469288, lng: 18.9197874 },
    { lat: 48.2471052, lng: 18.9201696 },
    { lat: 48.2472341, lng: 18.9201546 },
    { lat: 48.2472934, lng: 18.920539 },
    { lat: 48.2474397, lng: 18.9208175 },
    { lat: 48.2473186, lng: 18.9213321 },
    { lat: 48.2477297, lng: 18.9222814 },
    { lat: 48.2479069, lng: 18.9225708 },
    { lat: 48.248062, lng: 18.9229752 },
    { lat: 48.2483575, lng: 18.9231052 },
    { lat: 48.2484926, lng: 18.9232551 },
    { lat: 48.2486427, lng: 18.9238048 },
    { lat: 48.2493338, lng: 18.9237591 },
    { lat: 48.2495552, lng: 18.9233924 },
    { lat: 48.2501233, lng: 18.9231515 },
    { lat: 48.2502143, lng: 18.9230327 },
    { lat: 48.2508324, lng: 18.9227545 },
    { lat: 48.2511757, lng: 18.9227621 },
    { lat: 48.2512994, lng: 18.9226512 },
    { lat: 48.2516769, lng: 18.922645 },
    { lat: 48.2518225, lng: 18.9224717 },
    { lat: 48.2520257, lng: 18.9225621 },
    { lat: 48.252235, lng: 18.9229465 },
    { lat: 48.2524766, lng: 18.9232582 },
    { lat: 48.2525126, lng: 18.9234309 },
    { lat: 48.252756, lng: 18.9237364 },
    { lat: 48.25324, lng: 18.9238063 },
    { lat: 48.2534209, lng: 18.9239647 },
    { lat: 48.2537959, lng: 18.9240437 },
    { lat: 48.2540311, lng: 18.9239489 },
    { lat: 48.2543088, lng: 18.9239786 },
    { lat: 48.2544318, lng: 18.9238936 },
    { lat: 48.2545938, lng: 18.9239516 },
    { lat: 48.254797, lng: 18.9242164 },
    { lat: 48.2548551, lng: 18.9243803 },
    { lat: 48.2548284, lng: 18.9245178 },
    { lat: 48.2548665, lng: 18.9247345 },
    { lat: 48.2554072, lng: 18.9255324 },
    { lat: 48.2558324, lng: 18.9264038 },
    { lat: 48.2557066, lng: 18.9265656 },
    { lat: 48.2566944, lng: 18.930653 },
    { lat: 48.25672, lng: 18.9309982 },
    { lat: 48.25673, lng: 18.9311053 },
    { lat: 48.2575209, lng: 18.9308946 },
    { lat: 48.2580085, lng: 18.9311453 },
    { lat: 48.2580764, lng: 18.9310373 },
    { lat: 48.2586734, lng: 18.9308292 },
    { lat: 48.2586892, lng: 18.9307004 },
    { lat: 48.2589494, lng: 18.9300832 },
    { lat: 48.2592147, lng: 18.9298027 },
    { lat: 48.2595105, lng: 18.9292844 },
    { lat: 48.2598053, lng: 18.9290535 },
    { lat: 48.2605328, lng: 18.9282521 },
    { lat: 48.2613561, lng: 18.9271822 },
    { lat: 48.2617096, lng: 18.9269541 },
    { lat: 48.2619365, lng: 18.9264151 },
    { lat: 48.2624266, lng: 18.9258399 },
    { lat: 48.2624127, lng: 18.92546 },
    { lat: 48.2625615, lng: 18.9251993 },
    { lat: 48.2625773, lng: 18.9248921 },
    { lat: 48.2626288, lng: 18.9248412 },
    { lat: 48.2629067, lng: 18.9239997 },
    { lat: 48.2629857, lng: 18.9239141 },
    { lat: 48.2631832, lng: 18.9232401 },
    { lat: 48.2631924, lng: 18.9228929 },
    { lat: 48.2615581, lng: 18.9202874 },
    { lat: 48.2615357, lng: 18.919681 },
    { lat: 48.2614816, lng: 18.9194834 },
    { lat: 48.2602634, lng: 18.9169445 },
    { lat: 48.2603098, lng: 18.9169515 },
    { lat: 48.259667, lng: 18.9147886 },
    { lat: 48.2587309, lng: 18.9119086 },
    { lat: 48.2589221, lng: 18.9117038 },
    { lat: 48.2591323, lng: 18.9105375 },
    { lat: 48.2591729, lng: 18.9091223 },
    { lat: 48.2591285, lng: 18.9088299 },
    { lat: 48.2592273, lng: 18.9082098 },
    { lat: 48.2591529, lng: 18.9076674 },
    { lat: 48.259351, lng: 18.9075967 },
    { lat: 48.2596923, lng: 18.9076292 },
    { lat: 48.2599241, lng: 18.9070552 },
    { lat: 48.2599781, lng: 18.906314 },
    { lat: 48.2599464, lng: 18.9054415 },
    { lat: 48.2596478, lng: 18.9042907 },
    { lat: 48.2595187, lng: 18.9030849 },
    { lat: 48.2591378, lng: 18.90039 },
    { lat: 48.2590956, lng: 18.8995994 },
    { lat: 48.2591876, lng: 18.8990908 },
    { lat: 48.2591344, lng: 18.8984339 },
    { lat: 48.2590859, lng: 18.8983475 },
    { lat: 48.2591064, lng: 18.8980273 },
    { lat: 48.2594683, lng: 18.8978072 },
    { lat: 48.2598345, lng: 18.8972597 },
    { lat: 48.2602375, lng: 18.8968511 },
    { lat: 48.2606401, lng: 18.8963131 },
    { lat: 48.2606852, lng: 18.8963999 },
    { lat: 48.2609937, lng: 18.8960412 },
    { lat: 48.2617005, lng: 18.8951918 },
    { lat: 48.2623503, lng: 18.8942389 },
    { lat: 48.2627307, lng: 18.8933569 },
    { lat: 48.2630312, lng: 18.891562 },
    { lat: 48.2632213, lng: 18.8911634 },
    { lat: 48.262967, lng: 18.8909123 },
    { lat: 48.2633852, lng: 18.8894778 },
    { lat: 48.2627359, lng: 18.888875 },
    { lat: 48.2632141, lng: 18.8881179 },
    { lat: 48.2631419, lng: 18.8875965 },
    { lat: 48.2630283, lng: 18.8875574 },
    { lat: 48.2628944, lng: 18.886954 },
    { lat: 48.2625233, lng: 18.8860202 },
    { lat: 48.2623521, lng: 18.8853087 },
    { lat: 48.2622298, lng: 18.8850841 },
    { lat: 48.2619452, lng: 18.8842039 },
    { lat: 48.2615976, lng: 18.8836142 },
    { lat: 48.2614525, lng: 18.8831866 },
    { lat: 48.2614487, lng: 18.8817036 },
    { lat: 48.2615066, lng: 18.8812778 },
    { lat: 48.2620199, lng: 18.879463 },
    { lat: 48.2621811, lng: 18.8784643 },
    { lat: 48.262357, lng: 18.8781394 },
    { lat: 48.2627657, lng: 18.8771121 },
    { lat: 48.2619279, lng: 18.875942 },
    { lat: 48.2618203, lng: 18.8756725 },
    { lat: 48.2613598, lng: 18.8749927 },
    { lat: 48.2607694, lng: 18.8739844 },
    { lat: 48.2594776, lng: 18.8727184 },
    { lat: 48.260115, lng: 18.872094 },
    { lat: 48.2593908, lng: 18.8706818 },
    { lat: 48.2594736, lng: 18.8705599 },
    { lat: 48.2589633, lng: 18.8695332 },
    { lat: 48.2586728, lng: 18.868657 },
    { lat: 48.257983, lng: 18.8671697 },
    { lat: 48.2576127, lng: 18.8649543 },
    { lat: 48.257494, lng: 18.8641058 },
    { lat: 48.2572669, lng: 18.8649441 },
    { lat: 48.256557, lng: 18.8661209 },
    { lat: 48.2562813, lng: 18.866995 },
    { lat: 48.2561523, lng: 18.8672054 },
    { lat: 48.2556667, lng: 18.8667559 },
    { lat: 48.2551976, lng: 18.8677742 },
    { lat: 48.2539887, lng: 18.8699133 },
    { lat: 48.2535052, lng: 18.8713912 },
    { lat: 48.2533836, lng: 18.8713714 },
    { lat: 48.2531491, lng: 18.8715909 },
    { lat: 48.2529177, lng: 18.8719113 },
    { lat: 48.2520681, lng: 18.8727674 },
    { lat: 48.2515606, lng: 18.8731543 },
    { lat: 48.2515442, lng: 18.8732744 },
    { lat: 48.2514696, lng: 18.8733567 },
    { lat: 48.251206, lng: 18.8733592 },
    { lat: 48.2509474, lng: 18.8735476 },
    { lat: 48.2504767, lng: 18.8737108 },
    { lat: 48.2502562, lng: 18.8736152 },
    { lat: 48.2500552, lng: 18.8738025 },
    { lat: 48.2498844, lng: 18.8737492 },
    { lat: 48.2497768, lng: 18.8738293 },
    { lat: 48.249494, lng: 18.8738539 },
    { lat: 48.2493197, lng: 18.8739956 },
    { lat: 48.24885, lng: 18.8741548 },
    { lat: 48.2483027, lng: 18.8750123 },
    { lat: 48.2480953, lng: 18.8755357 },
    { lat: 48.2477812, lng: 18.8760415 },
    { lat: 48.2475784, lng: 18.8765672 },
    { lat: 48.2473427, lng: 18.8769959 },
    { lat: 48.2470718, lng: 18.876886 },
    { lat: 48.2467843, lng: 18.8769953 },
    { lat: 48.2465849, lng: 18.8767829 },
    { lat: 48.2461414, lng: 18.8769476 },
    { lat: 48.2460581, lng: 18.8768451 },
    { lat: 48.2460209, lng: 18.8766245 },
    { lat: 48.245352, lng: 18.8767079 },
    { lat: 48.2452255, lng: 18.8774868 },
    { lat: 48.2452557, lng: 18.8778494 },
    { lat: 48.2449185, lng: 18.8780614 },
    { lat: 48.2433729, lng: 18.8783115 },
    { lat: 48.2433361, lng: 18.8793339 },
    { lat: 48.2433388, lng: 18.8818513 },
    { lat: 48.24323, lng: 18.8827246 },
    { lat: 48.2433211, lng: 18.8827515 },
    { lat: 48.2432112, lng: 18.883568 },
    { lat: 48.2430037, lng: 18.8845629 },
    { lat: 48.2425113, lng: 18.8858075 },
    { lat: 48.2398225, lng: 18.8880536 },
    { lat: 48.2390698, lng: 18.8885598 },
    { lat: 48.2389431, lng: 18.8884005 },
    { lat: 48.2385269, lng: 18.8891359 },
    { lat: 48.2381367, lng: 18.8900939 },
    { lat: 48.2374794, lng: 18.8898359 },
    { lat: 48.2373459, lng: 18.8901945 },
    { lat: 48.2370217, lng: 18.8907034 },
    { lat: 48.2369936, lng: 18.8908138 },
    { lat: 48.2370332, lng: 18.8910986 },
    { lat: 48.2367999, lng: 18.891947 },
    { lat: 48.2361527, lng: 18.893185 },
    { lat: 48.2360035, lng: 18.8932646 },
    { lat: 48.2353977, lng: 18.8940209 },
    { lat: 48.2348067, lng: 18.8952767 },
    { lat: 48.234086, lng: 18.8960587 },
    { lat: 48.2338078, lng: 18.8960387 },
    { lat: 48.2337349, lng: 18.896113 },
    { lat: 48.2336944, lng: 18.8962885 },
  ],
  HornéMladonice: [
    { lat: 48.3782126, lng: 19.1717965 },
    { lat: 48.3781401, lng: 19.1715347 },
    { lat: 48.3780125, lng: 19.1710497 },
    { lat: 48.3769764, lng: 19.1689721 },
    { lat: 48.3767619, lng: 19.1687804 },
    { lat: 48.3765222, lng: 19.1683805 },
    { lat: 48.3760294, lng: 19.1677382 },
    { lat: 48.3749878, lng: 19.1659592 },
    { lat: 48.3744182, lng: 19.165105 },
    { lat: 48.3742079, lng: 19.1645689 },
    { lat: 48.3732626, lng: 19.1632332 },
    { lat: 48.3727999, lng: 19.161653 },
    { lat: 48.3722897, lng: 19.1617527 },
    { lat: 48.3716195, lng: 19.1616473 },
    { lat: 48.3713623, lng: 19.1614635 },
    { lat: 48.3713238, lng: 19.1611852 },
    { lat: 48.3706725, lng: 19.1609181 },
    { lat: 48.3705501, lng: 19.1609272 },
    { lat: 48.3691877, lng: 19.1602096 },
    { lat: 48.368357, lng: 19.1594793 },
    { lat: 48.3684035, lng: 19.159327 },
    { lat: 48.368134, lng: 19.1590308 },
    { lat: 48.3681647, lng: 19.1587388 },
    { lat: 48.3680636, lng: 19.1578573 },
    { lat: 48.3681584, lng: 19.1578393 },
    { lat: 48.3683149, lng: 19.157149 },
    { lat: 48.3675353, lng: 19.1561599 },
    { lat: 48.3669677, lng: 19.1551674 },
    { lat: 48.3672298, lng: 19.1547698 },
    { lat: 48.3666647, lng: 19.1539444 },
    { lat: 48.3661535, lng: 19.1535246 },
    { lat: 48.3664672, lng: 19.1524363 },
    { lat: 48.3654662, lng: 19.1512324 },
    { lat: 48.3654895, lng: 19.151155 },
    { lat: 48.3646651, lng: 19.1502827 },
    { lat: 48.3642535, lng: 19.1496657 },
    { lat: 48.3639384, lng: 19.1492903 },
    { lat: 48.3635601, lng: 19.1491404 },
    { lat: 48.3636023, lng: 19.1484004 },
    { lat: 48.3628486, lng: 19.1483878 },
    { lat: 48.3628927, lng: 19.1477341 },
    { lat: 48.3619052, lng: 19.1473899 },
    { lat: 48.3607423, lng: 19.1462188 },
    { lat: 48.361106, lng: 19.144711 },
    { lat: 48.3611951, lng: 19.1436407 },
    { lat: 48.3585078, lng: 19.142422 },
    { lat: 48.3586165, lng: 19.1418381 },
    { lat: 48.3575844, lng: 19.1408539 },
    { lat: 48.3566845, lng: 19.1402562 },
    { lat: 48.356328, lng: 19.1418415 },
    { lat: 48.3557986, lng: 19.1412594 },
    { lat: 48.3544592, lng: 19.1403951 },
    { lat: 48.3543104, lng: 19.1410766 },
    { lat: 48.3535404, lng: 19.140345 },
    { lat: 48.3532071, lng: 19.1401026 },
    { lat: 48.3529488, lng: 19.1408052 },
    { lat: 48.3523379, lng: 19.1401725 },
    { lat: 48.3523205, lng: 19.1402733 },
    { lat: 48.3517156, lng: 19.1405132 },
    { lat: 48.3512405, lng: 19.1410036 },
    { lat: 48.3505332, lng: 19.1414445 },
    { lat: 48.3495117, lng: 19.1402165 },
    { lat: 48.3489026, lng: 19.1396442 },
    { lat: 48.3485629, lng: 19.1412472 },
    { lat: 48.3482187, lng: 19.1425374 },
    { lat: 48.3467251, lng: 19.1419022 },
    { lat: 48.3465865, lng: 19.1423363 },
    { lat: 48.3459241, lng: 19.141256 },
    { lat: 48.3458282, lng: 19.1410038 },
    { lat: 48.3455228, lng: 19.1405758 },
    { lat: 48.3446517, lng: 19.139947 },
    { lat: 48.34409, lng: 19.139306 },
    { lat: 48.3432963, lng: 19.1387827 },
    { lat: 48.3412709, lng: 19.1367501 },
    { lat: 48.3407843, lng: 19.1363223 },
    { lat: 48.34035, lng: 19.136182 },
    { lat: 48.3401433, lng: 19.1365396 },
    { lat: 48.3400296, lng: 19.1368797 },
    { lat: 48.3398865, lng: 19.1370194 },
    { lat: 48.3396255, lng: 19.1384358 },
    { lat: 48.3396043, lng: 19.1388524 },
    { lat: 48.3394246, lng: 19.1400003 },
    { lat: 48.339266, lng: 19.1398451 },
    { lat: 48.3388605, lng: 19.1417596 },
    { lat: 48.3383491, lng: 19.1433789 },
    { lat: 48.3376175, lng: 19.1442692 },
    { lat: 48.3372281, lng: 19.1449391 },
    { lat: 48.337922, lng: 19.1465872 },
    { lat: 48.3381078, lng: 19.1467433 },
    { lat: 48.338109, lng: 19.1472913 },
    { lat: 48.3381678, lng: 19.1473616 },
    { lat: 48.3382075, lng: 19.1475489 },
    { lat: 48.3383641, lng: 19.147771 },
    { lat: 48.3383817, lng: 19.1480167 },
    { lat: 48.3385055, lng: 19.1480131 },
    { lat: 48.338686, lng: 19.1482183 },
    { lat: 48.3386803, lng: 19.1492596 },
    { lat: 48.3387476, lng: 19.1495742 },
    { lat: 48.3387174, lng: 19.1497852 },
    { lat: 48.3385635, lng: 19.1499778 },
    { lat: 48.3385932, lng: 19.1501665 },
    { lat: 48.3385376, lng: 19.1505458 },
    { lat: 48.3382196, lng: 19.1506784 },
    { lat: 48.3381182, lng: 19.1508647 },
    { lat: 48.3378475, lng: 19.1523179 },
    { lat: 48.3375958, lng: 19.1530174 },
    { lat: 48.3373586, lng: 19.1535066 },
    { lat: 48.3371126, lng: 19.1536828 },
    { lat: 48.3365961, lng: 19.1537709 },
    { lat: 48.3359823, lng: 19.1537844 },
    { lat: 48.3356175, lng: 19.1539065 },
    { lat: 48.3336737, lng: 19.1564261 },
    { lat: 48.3335794, lng: 19.1566591 },
    { lat: 48.3334545, lng: 19.157372 },
    { lat: 48.3335248, lng: 19.1595409 },
    { lat: 48.3333131, lng: 19.1600942 },
    { lat: 48.3333126, lng: 19.1608563 },
    { lat: 48.3332717, lng: 19.160943 },
    { lat: 48.3328713, lng: 19.1610772 },
    { lat: 48.3324715, lng: 19.1619954 },
    { lat: 48.3322763, lng: 19.1627005 },
    { lat: 48.332068, lng: 19.1632512 },
    { lat: 48.3313905, lng: 19.1645737 },
    { lat: 48.3312267, lng: 19.1658666 },
    { lat: 48.3312744, lng: 19.1670573 },
    { lat: 48.3314394, lng: 19.1680727 },
    { lat: 48.3315057, lng: 19.1690035 },
    { lat: 48.3315287, lng: 19.1698986 },
    { lat: 48.3314457, lng: 19.171049 },
    { lat: 48.3305031, lng: 19.1717559 },
    { lat: 48.3297462, lng: 19.1720653 },
    { lat: 48.3292199, lng: 19.172138 },
    { lat: 48.3285699, lng: 19.172737 },
    { lat: 48.3282834, lng: 19.1732598 },
    { lat: 48.3285898, lng: 19.1736909 },
    { lat: 48.3291986, lng: 19.174308 },
    { lat: 48.3295781, lng: 19.1743885 },
    { lat: 48.3298198, lng: 19.174628 },
    { lat: 48.3299372, lng: 19.1749303 },
    { lat: 48.3301744, lng: 19.174983 },
    { lat: 48.3304455, lng: 19.1754077 },
    { lat: 48.3308406, lng: 19.1755541 },
    { lat: 48.3310314, lng: 19.1759251 },
    { lat: 48.3315141, lng: 19.1763618 },
    { lat: 48.3318045, lng: 19.1764685 },
    { lat: 48.3319533, lng: 19.1767184 },
    { lat: 48.3319384, lng: 19.1768762 },
    { lat: 48.3321191, lng: 19.1770613 },
    { lat: 48.3324522, lng: 19.1770289 },
    { lat: 48.3326334, lng: 19.1772494 },
    { lat: 48.3329744, lng: 19.1771942 },
    { lat: 48.3334111, lng: 19.1773647 },
    { lat: 48.3335283, lng: 19.1775911 },
    { lat: 48.3341714, lng: 19.1777614 },
    { lat: 48.3341151, lng: 19.1779933 },
    { lat: 48.3344193, lng: 19.1784899 },
    { lat: 48.3349074, lng: 19.1787401 },
    { lat: 48.3348847, lng: 19.178947 },
    { lat: 48.3352511, lng: 19.1793113 },
    { lat: 48.3354414, lng: 19.1791935 },
    { lat: 48.3357463, lng: 19.1794073 },
    { lat: 48.3360343, lng: 19.1795086 },
    { lat: 48.3363282, lng: 19.179803 },
    { lat: 48.3364093, lng: 19.1797749 },
    { lat: 48.3366255, lng: 19.1799351 },
    { lat: 48.3367967, lng: 19.1799771 },
    { lat: 48.3371491, lng: 19.1803784 },
    { lat: 48.3373619, lng: 19.1807172 },
    { lat: 48.3376049, lng: 19.180911 },
    { lat: 48.3378977, lng: 19.1809526 },
    { lat: 48.3381282, lng: 19.1813731 },
    { lat: 48.338348, lng: 19.1814621 },
    { lat: 48.3385554, lng: 19.1815769 },
    { lat: 48.338553, lng: 19.1815751 },
    { lat: 48.338484, lng: 19.1805169 },
    { lat: 48.3382814, lng: 19.1803169 },
    { lat: 48.3396268, lng: 19.1763526 },
    { lat: 48.3397499, lng: 19.1758092 },
    { lat: 48.3402659, lng: 19.1760297 },
    { lat: 48.3419256, lng: 19.1764042 },
    { lat: 48.3430586, lng: 19.1768596 },
    { lat: 48.3439192, lng: 19.1771244 },
    { lat: 48.3443197, lng: 19.1768106 },
    { lat: 48.3447212, lng: 19.1761034 },
    { lat: 48.3448748, lng: 19.1756983 },
    { lat: 48.3452878, lng: 19.1752977 },
    { lat: 48.3454923, lng: 19.1752148 },
    { lat: 48.3456306, lng: 19.1752745 },
    { lat: 48.3458777, lng: 19.1749147 },
    { lat: 48.3460073, lng: 19.1750537 },
    { lat: 48.346473, lng: 19.173958 },
    { lat: 48.3466743, lng: 19.173284 },
    { lat: 48.3471356, lng: 19.173585 },
    { lat: 48.3475297, lng: 19.1740129 },
    { lat: 48.3481856, lng: 19.1744411 },
    { lat: 48.3484461, lng: 19.1748084 },
    { lat: 48.3485077, lng: 19.174798 },
    { lat: 48.3486312, lng: 19.1743087 },
    { lat: 48.3497674, lng: 19.174489 },
    { lat: 48.3504185, lng: 19.1747119 },
    { lat: 48.3512406, lng: 19.1752188 },
    { lat: 48.3524877, lng: 19.1768485 },
    { lat: 48.3530305, lng: 19.177294 },
    { lat: 48.3540027, lng: 19.1776897 },
    { lat: 48.3545866, lng: 19.1781487 },
    { lat: 48.3547962, lng: 19.178465 },
    { lat: 48.3560867, lng: 19.179285 },
    { lat: 48.3581379, lng: 19.1803018 },
    { lat: 48.3590628, lng: 19.1806596 },
    { lat: 48.359945, lng: 19.1808067 },
    { lat: 48.3599487, lng: 19.1809282 },
    { lat: 48.3607336, lng: 19.1812771 },
    { lat: 48.3616094, lng: 19.1810686 },
    { lat: 48.362868, lng: 19.1806219 },
    { lat: 48.3633019, lng: 19.1807361 },
    { lat: 48.3637128, lng: 19.1807256 },
    { lat: 48.3642358, lng: 19.1808827 },
    { lat: 48.364764, lng: 19.1812382 },
    { lat: 48.3652528, lng: 19.182352 },
    { lat: 48.3655439, lng: 19.182838 },
    { lat: 48.3663078, lng: 19.1834027 },
    { lat: 48.3673902, lng: 19.1840462 },
    { lat: 48.3680119, lng: 19.1848125 },
    { lat: 48.3680382, lng: 19.184934 },
    { lat: 48.3685448, lng: 19.1852391 },
    { lat: 48.3688175, lng: 19.1850532 },
    { lat: 48.3694446, lng: 19.1844423 },
    { lat: 48.3702214, lng: 19.1846742 },
    { lat: 48.3703876, lng: 19.1845731 },
    { lat: 48.3708031, lng: 19.1838963 },
    { lat: 48.3711547, lng: 19.1836435 },
    { lat: 48.3713564, lng: 19.1833655 },
    { lat: 48.3732271, lng: 19.1837195 },
    { lat: 48.3736427, lng: 19.1836465 },
    { lat: 48.3755659, lng: 19.1830487 },
    { lat: 48.3759664, lng: 19.1826656 },
    { lat: 48.376023, lng: 19.1825104 },
    { lat: 48.3762054, lng: 19.182276 },
    { lat: 48.3765052, lng: 19.1815201 },
    { lat: 48.3766259, lng: 19.1814092 },
    { lat: 48.3774136, lng: 19.1810147 },
    { lat: 48.3779085, lng: 19.1806927 },
    { lat: 48.3793662, lng: 19.1804905 },
    { lat: 48.3796782, lng: 19.180303 },
    { lat: 48.3804601, lng: 19.1794931 },
    { lat: 48.38141, lng: 19.1790287 },
    { lat: 48.3815863, lng: 19.1790351 },
    { lat: 48.381875, lng: 19.1787863 },
    { lat: 48.3820593, lng: 19.1784761 },
    { lat: 48.3824562, lng: 19.177426 },
    { lat: 48.3822684, lng: 19.1770716 },
    { lat: 48.3817439, lng: 19.1761501 },
    { lat: 48.3819546, lng: 19.174617 },
    { lat: 48.3818166, lng: 19.1743334 },
    { lat: 48.3814371, lng: 19.1740869 },
    { lat: 48.3805413, lng: 19.1738887 },
    { lat: 48.3804796, lng: 19.1740558 },
    { lat: 48.3800659, lng: 19.1739635 },
    { lat: 48.3798932, lng: 19.1738493 },
    { lat: 48.3793009, lng: 19.1731953 },
    { lat: 48.3782413, lng: 19.1718951 },
    { lat: 48.3782126, lng: 19.1717965 },
  ],
  Domaníky: [
    { lat: 48.2392322, lng: 18.95857 },
    { lat: 48.239175, lng: 18.9593243 },
    { lat: 48.2391942, lng: 18.9597394 },
    { lat: 48.2388872, lng: 18.9619748 },
    { lat: 48.2388127, lng: 18.9621255 },
    { lat: 48.2385818, lng: 18.9632521 },
    { lat: 48.2384172, lng: 18.9638012 },
    { lat: 48.237609, lng: 18.965171 },
    { lat: 48.2373639, lng: 18.9654913 },
    { lat: 48.2373318, lng: 18.9654367 },
    { lat: 48.2371595, lng: 18.9657037 },
    { lat: 48.2369692, lng: 18.9656877 },
    { lat: 48.2367559, lng: 18.9661024 },
    { lat: 48.2367958, lng: 18.966397 },
    { lat: 48.2366282, lng: 18.9665146 },
    { lat: 48.2367025, lng: 18.9667518 },
    { lat: 48.236583, lng: 18.967062 },
    { lat: 48.2365746, lng: 18.9672256 },
    { lat: 48.2364679, lng: 18.9674522 },
    { lat: 48.2363812, lng: 18.9681625 },
    { lat: 48.2364094, lng: 18.9683573 },
    { lat: 48.2362894, lng: 18.9685023 },
    { lat: 48.2363945, lng: 18.9688602 },
    { lat: 48.2364696, lng: 18.9688508 },
    { lat: 48.2367764, lng: 18.9702621 },
    { lat: 48.2369369, lng: 18.9704902 },
    { lat: 48.2372437, lng: 18.9711517 },
    { lat: 48.2375717, lng: 18.9723598 },
    { lat: 48.2377607, lng: 18.972858 },
    { lat: 48.2379794, lng: 18.9732308 },
    { lat: 48.238028, lng: 18.9734508 },
    { lat: 48.2380422, lng: 18.9741693 },
    { lat: 48.2381363, lng: 18.9752369 },
    { lat: 48.2380226, lng: 18.9765187 },
    { lat: 48.2377116, lng: 18.9789863 },
    { lat: 48.2383518, lng: 18.9793359 },
    { lat: 48.2389355, lng: 18.9798133 },
    { lat: 48.2396702, lng: 18.980666 },
    { lat: 48.240639, lng: 18.9816174 },
    { lat: 48.2409868, lng: 18.9820476 },
    { lat: 48.2430671, lng: 18.9842508 },
    { lat: 48.2445663, lng: 18.9856751 },
    { lat: 48.2458049, lng: 18.9866372 },
    { lat: 48.2462778, lng: 18.9871299 },
    { lat: 48.2467791, lng: 18.9877629 },
    { lat: 48.2479078, lng: 18.9901039 },
    { lat: 48.2489444, lng: 18.9933997 },
    { lat: 48.2497022, lng: 18.9950595 },
    { lat: 48.2500347, lng: 18.9962972 },
    { lat: 48.2503156, lng: 18.9969174 },
    { lat: 48.2506434, lng: 18.9974891 },
    { lat: 48.2512941, lng: 18.9982209 },
    { lat: 48.252046, lng: 18.9987049 },
    { lat: 48.2536373, lng: 19.0004104 },
    { lat: 48.2536065, lng: 19.0008908 },
    { lat: 48.2536399, lng: 19.0017223 },
    { lat: 48.2540713, lng: 19.0018049 },
    { lat: 48.2549578, lng: 19.0018338 },
    { lat: 48.2550137, lng: 19.0018462 },
    { lat: 48.2555451, lng: 19.0017855 },
    { lat: 48.2556132, lng: 19.0020137 },
    { lat: 48.2560281, lng: 19.0020805 },
    { lat: 48.2566866, lng: 19.0025664 },
    { lat: 48.2584351, lng: 19.0036042 },
    { lat: 48.2588696, lng: 19.0037895 },
    { lat: 48.2593842, lng: 19.0041518 },
    { lat: 48.2597441, lng: 19.0051545 },
    { lat: 48.2605286, lng: 19.0070085 },
    { lat: 48.2610364, lng: 19.0076352 },
    { lat: 48.2622396, lng: 19.0106976 },
    { lat: 48.2623713, lng: 19.0109841 },
    { lat: 48.2633541, lng: 19.0094448 },
    { lat: 48.2644995, lng: 19.0079127 },
    { lat: 48.2658275, lng: 19.006204 },
    { lat: 48.267141, lng: 19.0046788 },
    { lat: 48.2680293, lng: 19.0026592 },
    { lat: 48.2688105, lng: 19.0012835 },
    { lat: 48.2690337, lng: 19.0002628 },
    { lat: 48.2700435, lng: 18.9987749 },
    { lat: 48.2701493, lng: 18.9988389 },
    { lat: 48.2702733, lng: 18.9983038 },
    { lat: 48.270401, lng: 18.9968742 },
    { lat: 48.2703408, lng: 18.996644 },
    { lat: 48.270383, lng: 18.9964109 },
    { lat: 48.2702078, lng: 18.9961103 },
    { lat: 48.2702581, lng: 18.9958844 },
    { lat: 48.2700762, lng: 18.9953893 },
    { lat: 48.2701174, lng: 18.9952232 },
    { lat: 48.2700429, lng: 18.9944305 },
    { lat: 48.2697662, lng: 18.9940643 },
    { lat: 48.2698309, lng: 18.9929885 },
    { lat: 48.2698145, lng: 18.9926666 },
    { lat: 48.2702093, lng: 18.9924299 },
    { lat: 48.2711503, lng: 18.9913839 },
    { lat: 48.2716908, lng: 18.9909688 },
    { lat: 48.2719654, lng: 18.9909607 },
    { lat: 48.272222, lng: 18.9910396 },
    { lat: 48.2722991, lng: 18.9910917 },
    { lat: 48.2724626, lng: 18.9914035 },
    { lat: 48.2727883, lng: 18.9901834 },
    { lat: 48.2727537, lng: 18.990015 },
    { lat: 48.2728159, lng: 18.9898634 },
    { lat: 48.2729302, lng: 18.9890752 },
    { lat: 48.2733081, lng: 18.9880636 },
    { lat: 48.2730421, lng: 18.9874917 },
    { lat: 48.2729486, lng: 18.9871396 },
    { lat: 48.2727715, lng: 18.9855043 },
    { lat: 48.2725824, lng: 18.9845597 },
    { lat: 48.2725804, lng: 18.9842156 },
    { lat: 48.2724012, lng: 18.9830261 },
    { lat: 48.2722045, lng: 18.9805223 },
    { lat: 48.2725566, lng: 18.9776413 },
    { lat: 48.2725969, lng: 18.9767288 },
    { lat: 48.2725482, lng: 18.9761091 },
    { lat: 48.2722087, lng: 18.974721 },
    { lat: 48.2721429, lng: 18.9741361 },
    { lat: 48.2718915, lng: 18.9733244 },
    { lat: 48.2718917, lng: 18.9732816 },
    { lat: 48.2713307, lng: 18.9733591 },
    { lat: 48.2711322, lng: 18.973492 },
    { lat: 48.2706643, lng: 18.973998 },
    { lat: 48.2694813, lng: 18.9736625 },
    { lat: 48.2691242, lng: 18.9733826 },
    { lat: 48.2689958, lng: 18.9733442 },
    { lat: 48.268776, lng: 18.9737159 },
    { lat: 48.2681234, lng: 18.9739006 },
    { lat: 48.2677848, lng: 18.9743759 },
    { lat: 48.2673255, lng: 18.9754064 },
    { lat: 48.2668166, lng: 18.9755731 },
    { lat: 48.266313, lng: 18.9751638 },
    { lat: 48.2656987, lng: 18.975147 },
    { lat: 48.2653512, lng: 18.9748755 },
    { lat: 48.2648279, lng: 18.9750283 },
    { lat: 48.2646765, lng: 18.9744936 },
    { lat: 48.2644241, lng: 18.9739249 },
    { lat: 48.2638986, lng: 18.9735274 },
    { lat: 48.2635514, lng: 18.9731748 },
    { lat: 48.2634965, lng: 18.9730495 },
    { lat: 48.262978, lng: 18.9735811 },
    { lat: 48.2625605, lng: 18.9738637 },
    { lat: 48.262426, lng: 18.974055 },
    { lat: 48.2620718, lng: 18.9743607 },
    { lat: 48.2613853, lng: 18.974695 },
    { lat: 48.260918, lng: 18.9744649 },
    { lat: 48.2605233, lng: 18.9743973 },
    { lat: 48.2602327, lng: 18.9745135 },
    { lat: 48.2600449, lng: 18.9747208 },
    { lat: 48.2592675, lng: 18.9747751 },
    { lat: 48.258552, lng: 18.9744978 },
    { lat: 48.2579663, lng: 18.9744242 },
    { lat: 48.2573169, lng: 18.9742472 },
    { lat: 48.2566208, lng: 18.9738636 },
    { lat: 48.2559057, lng: 18.9724537 },
    { lat: 48.2548874, lng: 18.9714353 },
    { lat: 48.2547525, lng: 18.9713801 },
    { lat: 48.2547362, lng: 18.9714768 },
    { lat: 48.2541213, lng: 18.9714278 },
    { lat: 48.2535992, lng: 18.9711616 },
    { lat: 48.2532468, lng: 18.9708193 },
    { lat: 48.252776, lng: 18.9702578 },
    { lat: 48.2522403, lng: 18.9693294 },
    { lat: 48.2513613, lng: 18.9681303 },
    { lat: 48.2493737, lng: 18.9655859 },
    { lat: 48.2485209, lng: 18.9642158 },
    { lat: 48.2447731, lng: 18.9614037 },
    { lat: 48.2442367, lng: 18.961278 },
    { lat: 48.2429456, lng: 18.9608167 },
    { lat: 48.2421657, lng: 18.9601859 },
    { lat: 48.2410242, lng: 18.959717 },
    { lat: 48.2394657, lng: 18.9588561 },
    { lat: 48.2392322, lng: 18.95857 },
  ],
  Dudince: [
    { lat: 48.167723, lng: 18.860163 },
    { lat: 48.167514, lng: 18.860336 },
    { lat: 48.167363, lng: 18.860611 },
    { lat: 48.167235, lng: 18.861437 },
    { lat: 48.167157, lng: 18.861862 },
    { lat: 48.167082, lng: 18.862217 },
    { lat: 48.16697, lng: 18.862559 },
    { lat: 48.166828, lng: 18.8628 },
    { lat: 48.166424, lng: 18.863518 },
    { lat: 48.166682, lng: 18.864054 },
    { lat: 48.166814, lng: 18.864247 },
    { lat: 48.167087, lng: 18.864644 },
    { lat: 48.167299, lng: 18.864981 },
    { lat: 48.167341, lng: 18.865247 },
    { lat: 48.167337, lng: 18.86533 },
    { lat: 48.167333, lng: 18.865448 },
    { lat: 48.167329, lng: 18.865543 },
    { lat: 48.167345, lng: 18.865611 },
    { lat: 48.167375, lng: 18.865925 },
    { lat: 48.167421, lng: 18.866459 },
    { lat: 48.167261, lng: 18.868062 },
    { lat: 48.167226, lng: 18.868511 },
    { lat: 48.167194, lng: 18.86978 },
    { lat: 48.167214, lng: 18.870015 },
    { lat: 48.167204, lng: 18.870294 },
    { lat: 48.16701, lng: 18.87127 },
    { lat: 48.166978, lng: 18.871514 },
    { lat: 48.166892, lng: 18.871571 },
    { lat: 48.166543, lng: 18.870791 },
    { lat: 48.166336, lng: 18.870359 },
    { lat: 48.166178, lng: 18.869999 },
    { lat: 48.166045, lng: 18.869748 },
    { lat: 48.165947, lng: 18.869581 },
    { lat: 48.165814, lng: 18.869389 },
    { lat: 48.165705, lng: 18.869253 },
    { lat: 48.165533, lng: 18.869023 },
    { lat: 48.165371, lng: 18.868783 },
    { lat: 48.165136, lng: 18.868443 },
    { lat: 48.164877, lng: 18.868768 },
    { lat: 48.164692, lng: 18.868982 },
    { lat: 48.164592, lng: 18.869093 },
    { lat: 48.164331, lng: 18.869409 },
    { lat: 48.164065, lng: 18.86973 },
    { lat: 48.163934, lng: 18.869865 },
    { lat: 48.163694, lng: 18.870113 },
    { lat: 48.163304, lng: 18.87051 },
    { lat: 48.163262, lng: 18.870563 },
    { lat: 48.162939, lng: 18.870722 },
    { lat: 48.16284, lng: 18.870729 },
    { lat: 48.162538, lng: 18.870775 },
    { lat: 48.162351, lng: 18.870768 },
    { lat: 48.16209, lng: 18.870762 },
    { lat: 48.161411, lng: 18.870737 },
    { lat: 48.161428, lng: 18.872744 },
    { lat: 48.161428, lng: 18.872903 },
    { lat: 48.161429, lng: 18.87298 },
    { lat: 48.16143, lng: 18.873062 },
    { lat: 48.16143, lng: 18.873134 },
    { lat: 48.161433, lng: 18.873444 },
    { lat: 48.16144, lng: 18.874139 },
    { lat: 48.161449, lng: 18.874249 },
    { lat: 48.161396, lng: 18.874386 },
    { lat: 48.161343, lng: 18.874613 },
    { lat: 48.160943, lng: 18.8751 },
    { lat: 48.160711, lng: 18.875374 },
    { lat: 48.160422, lng: 18.87561 },
    { lat: 48.160112, lng: 18.875819 },
    { lat: 48.159674, lng: 18.876008 },
    { lat: 48.159451, lng: 18.876115 },
    { lat: 48.159261, lng: 18.876213 },
    { lat: 48.159167, lng: 18.876324 },
    { lat: 48.159019, lng: 18.876324 },
    { lat: 48.158512, lng: 18.876503 },
    { lat: 48.158222, lng: 18.876716 },
    { lat: 48.157841, lng: 18.876879 },
    { lat: 48.157672, lng: 18.877104 },
    { lat: 48.157468, lng: 18.877154 },
    { lat: 48.157257, lng: 18.877384 },
    { lat: 48.157146, lng: 18.877558 },
    { lat: 48.156727, lng: 18.877748 },
    { lat: 48.15662, lng: 18.878168 },
    { lat: 48.156522, lng: 18.878607 },
    { lat: 48.156765, lng: 18.878412 },
    { lat: 48.157388, lng: 18.878314 },
    { lat: 48.158693, lng: 18.878252 },
    { lat: 48.158948, lng: 18.878341 },
    { lat: 48.159308, lng: 18.878224 },
    { lat: 48.160681, lng: 18.878163 },
    { lat: 48.160797, lng: 18.878153 },
    { lat: 48.160899, lng: 18.878144 },
    { lat: 48.16116, lng: 18.878291 },
    { lat: 48.161218, lng: 18.878346 },
    { lat: 48.161319, lng: 18.878488 },
    { lat: 48.161353, lng: 18.878506 },
    { lat: 48.161474, lng: 18.878833 },
    { lat: 48.161549, lng: 18.879088 },
    { lat: 48.161579, lng: 18.879186 },
    { lat: 48.161587, lng: 18.87929 },
    { lat: 48.161595, lng: 18.879387 },
    { lat: 48.161602, lng: 18.87948 },
    { lat: 48.161603, lng: 18.879599 },
    { lat: 48.1616, lng: 18.879712 },
    { lat: 48.161584, lng: 18.87983 },
    { lat: 48.161569, lng: 18.879956 },
    { lat: 48.161561, lng: 18.880051 },
    { lat: 48.161553, lng: 18.88017 },
    { lat: 48.161547, lng: 18.880266 },
    { lat: 48.161538, lng: 18.88033 },
    { lat: 48.16153, lng: 18.880391 },
    { lat: 48.161523, lng: 18.880424 },
    { lat: 48.161516, lng: 18.880456 },
    { lat: 48.161505, lng: 18.8805 },
    { lat: 48.161487, lng: 18.880603 },
    { lat: 48.16148, lng: 18.880669 },
    { lat: 48.161477, lng: 18.880702 },
    { lat: 48.161475, lng: 18.880731 },
    { lat: 48.161408, lng: 18.880823 },
    { lat: 48.161342, lng: 18.880915 },
    { lat: 48.161281, lng: 18.880999 },
    { lat: 48.161235, lng: 18.88106 },
    { lat: 48.161193, lng: 18.881121 },
    { lat: 48.161149, lng: 18.881175 },
    { lat: 48.16114, lng: 18.881191 },
    { lat: 48.16111, lng: 18.881244 },
    { lat: 48.161107, lng: 18.881249 },
    { lat: 48.160954, lng: 18.881485 },
    { lat: 48.160899, lng: 18.881497 },
    { lat: 48.160823, lng: 18.881491 },
    { lat: 48.160691, lng: 18.881508 },
    { lat: 48.160537, lng: 18.881539 },
    { lat: 48.16027, lng: 18.8816 },
    { lat: 48.16019, lng: 18.881569 },
    { lat: 48.160128, lng: 18.881687 },
    { lat: 48.160106, lng: 18.881757 },
    { lat: 48.160091, lng: 18.881808 },
    { lat: 48.160076, lng: 18.881859 },
    { lat: 48.160062, lng: 18.881922 },
    { lat: 48.160052, lng: 18.882023 },
    { lat: 48.160033, lng: 18.882099 },
    { lat: 48.160047, lng: 18.882194 },
    { lat: 48.160046, lng: 18.882201 },
    { lat: 48.160031, lng: 18.882256 },
    { lat: 48.160031, lng: 18.88226 },
    { lat: 48.160019, lng: 18.882257 },
    { lat: 48.160017, lng: 18.882264 },
    { lat: 48.160003, lng: 18.882322 },
    { lat: 48.16, lng: 18.882366 },
    { lat: 48.160014, lng: 18.882428 },
    { lat: 48.159967, lng: 18.882643 },
    { lat: 48.159946, lng: 18.882758 },
    { lat: 48.159918, lng: 18.882919 },
    { lat: 48.1598726, lng: 18.883078 },
    { lat: 48.159857, lng: 18.883194 },
    { lat: 48.159846, lng: 18.883244 },
    { lat: 48.159841, lng: 18.883288 },
    { lat: 48.159828, lng: 18.883348 },
    { lat: 48.15982, lng: 18.88341 },
    { lat: 48.159807, lng: 18.883471 },
    { lat: 48.159793, lng: 18.883653 },
    { lat: 48.159781, lng: 18.883788 },
    { lat: 48.159782, lng: 18.883993 },
    { lat: 48.159775, lng: 18.884101 },
    { lat: 48.159771, lng: 18.884139 },
    { lat: 48.159763, lng: 18.884182 },
    { lat: 48.159743, lng: 18.884257 },
    { lat: 48.159728, lng: 18.884324 },
    { lat: 48.159707, lng: 18.884517 },
    { lat: 48.159701, lng: 18.88465 },
    { lat: 48.159711, lng: 18.884798 },
    { lat: 48.159732, lng: 18.884905 },
    { lat: 48.159781, lng: 18.885014 },
    { lat: 48.159934, lng: 18.885172 },
    { lat: 48.160027, lng: 18.885261 },
    { lat: 48.160173, lng: 18.885401 },
    { lat: 48.159944, lng: 18.885753 },
    { lat: 48.159725, lng: 18.886079 },
    { lat: 48.159644, lng: 18.886136 },
    { lat: 48.159498, lng: 18.886232 },
    { lat: 48.159409, lng: 18.886266 },
    { lat: 48.159323, lng: 18.886258 },
    { lat: 48.159186, lng: 18.88622 },
    { lat: 48.159129, lng: 18.886255 },
    { lat: 48.159091, lng: 18.88628 },
    { lat: 48.158948, lng: 18.88637 },
    { lat: 48.159085, lng: 18.886499 },
    { lat: 48.159235, lng: 18.886641 },
    { lat: 48.15943, lng: 18.88686 },
    { lat: 48.159594, lng: 18.887032 },
    { lat: 48.159775, lng: 18.887196 },
    { lat: 48.159897, lng: 18.887286 },
    { lat: 48.160065, lng: 18.887393 },
    { lat: 48.1602, lng: 18.887465 },
    { lat: 48.160318, lng: 18.887489 },
    { lat: 48.160362, lng: 18.887499 },
    { lat: 48.160448, lng: 18.887519 },
    { lat: 48.16075, lng: 18.887558 },
    { lat: 48.161076, lng: 18.887547 },
    { lat: 48.161552, lng: 18.887481 },
    { lat: 48.162087, lng: 18.887396 },
    { lat: 48.16205, lng: 18.887514 },
    { lat: 48.161998, lng: 18.887681 },
    { lat: 48.161956, lng: 18.887673 },
    { lat: 48.161846, lng: 18.888049 },
    { lat: 48.161826, lng: 18.888111 },
    { lat: 48.161763, lng: 18.888278 },
    { lat: 48.161685, lng: 18.888494 },
    { lat: 48.161672, lng: 18.888569 },
    { lat: 48.161667, lng: 18.888582 },
    { lat: 48.161582, lng: 18.888797 },
    { lat: 48.161486, lng: 18.889285 },
    { lat: 48.161482, lng: 18.889302 },
    { lat: 48.161436, lng: 18.889414 },
    { lat: 48.161378, lng: 18.889836 },
    { lat: 48.161287, lng: 18.890359 },
    { lat: 48.16128, lng: 18.890563 },
    { lat: 48.161245, lng: 18.890892 },
    { lat: 48.16117, lng: 18.891281 },
    { lat: 48.161161, lng: 18.891333 },
    { lat: 48.161128, lng: 18.891495 },
    { lat: 48.161056, lng: 18.891814 },
    { lat: 48.161051, lng: 18.891933 },
    { lat: 48.161043, lng: 18.892104 },
    { lat: 48.160945, lng: 18.892576 },
    { lat: 48.160947, lng: 18.89264 },
    { lat: 48.160929, lng: 18.892815 },
    { lat: 48.160901, lng: 18.892889 },
    { lat: 48.160888, lng: 18.89333 },
    { lat: 48.160852, lng: 18.893558 },
    { lat: 48.160846, lng: 18.894602 },
    { lat: 48.160676, lng: 18.895128 },
    { lat: 48.160654, lng: 18.895163 },
    { lat: 48.160614, lng: 18.89516 },
    { lat: 48.16048, lng: 18.895412 },
    { lat: 48.160182, lng: 18.895964 },
    { lat: 48.159937, lng: 18.896059 },
    { lat: 48.159752, lng: 18.896461 },
    { lat: 48.159683, lng: 18.89653 },
    { lat: 48.159602, lng: 18.896707 },
    { lat: 48.159384, lng: 18.897479 },
    { lat: 48.159231, lng: 18.898178 },
    { lat: 48.159184, lng: 18.898303 },
    { lat: 48.158462, lng: 18.900228 },
    { lat: 48.159156, lng: 18.901604 },
    { lat: 48.159299, lng: 18.901822 },
    { lat: 48.159485, lng: 18.902361 },
    { lat: 48.159488, lng: 18.902495 },
    { lat: 48.159561, lng: 18.90277 },
    { lat: 48.159728, lng: 18.903 },
    { lat: 48.160007, lng: 18.903266 },
    { lat: 48.160108, lng: 18.903532 },
    { lat: 48.16044, lng: 18.904682 },
    { lat: 48.161098, lng: 18.906083 },
    { lat: 48.161043, lng: 18.907457 },
    { lat: 48.160696, lng: 18.908496 },
    { lat: 48.160095, lng: 18.909958 },
    { lat: 48.159742, lng: 18.910945 },
    { lat: 48.159868, lng: 18.911102 },
    { lat: 48.159828, lng: 18.911385 },
    { lat: 48.159829, lng: 18.911957 },
    { lat: 48.15986, lng: 18.912927 },
    { lat: 48.159865, lng: 18.913758 },
    { lat: 48.159828, lng: 18.914449 },
    { lat: 48.15964, lng: 18.915348 },
    { lat: 48.159313, lng: 18.916325 },
    { lat: 48.159258, lng: 18.916486 },
    { lat: 48.159032, lng: 18.917144 },
    { lat: 48.159004, lng: 18.917226 },
    { lat: 48.159009, lng: 18.91756 },
    { lat: 48.159083, lng: 18.918146 },
    { lat: 48.159184, lng: 18.918543 },
    { lat: 48.159502, lng: 18.919133 },
    { lat: 48.159989, lng: 18.920525 },
    { lat: 48.160141, lng: 18.920958 },
    { lat: 48.159924, lng: 18.923104 },
    { lat: 48.159725, lng: 18.924612 },
    { lat: 48.159019, lng: 18.929706 },
    { lat: 48.158809, lng: 18.931231 },
    { lat: 48.158803, lng: 18.931272 },
    { lat: 48.15875, lng: 18.93166 },
    { lat: 48.159183, lng: 18.931703 },
    { lat: 48.159362, lng: 18.931695 },
    { lat: 48.15941, lng: 18.931693 },
    { lat: 48.159544, lng: 18.931686 },
    { lat: 48.159618, lng: 18.931702 },
    { lat: 48.159839, lng: 18.931747 },
    { lat: 48.160121, lng: 18.931933 },
    { lat: 48.160207, lng: 18.931996 },
    { lat: 48.160398, lng: 18.932129 },
    { lat: 48.16079, lng: 18.932343 },
    { lat: 48.160914, lng: 18.932419 },
    { lat: 48.161577, lng: 18.932928 },
    { lat: 48.161746, lng: 18.932989 },
    { lat: 48.161784, lng: 18.933005 },
    { lat: 48.162017, lng: 18.933177 },
    { lat: 48.162152, lng: 18.933104 },
    { lat: 48.162267, lng: 18.933013 },
    { lat: 48.162388, lng: 18.932841 },
    { lat: 48.162379, lng: 18.932823 },
    { lat: 48.162626, lng: 18.932507 },
    { lat: 48.163194, lng: 18.9316 },
    { lat: 48.16391, lng: 18.931208 },
    { lat: 48.164229, lng: 18.930445 },
    { lat: 48.165153, lng: 18.929358 },
    { lat: 48.165223, lng: 18.929279 },
    { lat: 48.165745, lng: 18.928869 },
    { lat: 48.165874, lng: 18.928782 },
    { lat: 48.166027, lng: 18.928688 },
    { lat: 48.166169, lng: 18.928686 },
    { lat: 48.166243, lng: 18.928719 },
    { lat: 48.166322, lng: 18.928785 },
    { lat: 48.16636, lng: 18.928866 },
    { lat: 48.166394, lng: 18.929028 },
    { lat: 48.166454, lng: 18.929572 },
    { lat: 48.166644, lng: 18.930093 },
    { lat: 48.166829, lng: 18.930401 },
    { lat: 48.167248, lng: 18.930713 },
    { lat: 48.16765, lng: 18.931133 },
    { lat: 48.168192, lng: 18.931498 },
    { lat: 48.169614, lng: 18.931808 },
    { lat: 48.169974, lng: 18.931805 },
    { lat: 48.170351, lng: 18.931836 },
    { lat: 48.170638, lng: 18.931946 },
    { lat: 48.170775, lng: 18.931911 },
    { lat: 48.171064, lng: 18.932022 },
    { lat: 48.171144, lng: 18.932129 },
    { lat: 48.171165, lng: 18.932299 },
    { lat: 48.171451, lng: 18.932608 },
    { lat: 48.172222, lng: 18.933096 },
    { lat: 48.172577, lng: 18.933265 },
    { lat: 48.172762, lng: 18.933196 },
    { lat: 48.173309, lng: 18.933467 },
    { lat: 48.173658, lng: 18.933752 },
    { lat: 48.173733, lng: 18.933813 },
    { lat: 48.173753, lng: 18.933829 },
    { lat: 48.173795, lng: 18.93379 },
    { lat: 48.173837, lng: 18.93375 },
    { lat: 48.174009, lng: 18.933446 },
    { lat: 48.174032, lng: 18.933493 },
    { lat: 48.174121, lng: 18.933422 },
    { lat: 48.174266, lng: 18.934075 },
    { lat: 48.174263, lng: 18.93455 },
    { lat: 48.174262, lng: 18.934724 },
    { lat: 48.174212, lng: 18.935072 },
    { lat: 48.174112, lng: 18.935494 },
    { lat: 48.174443, lng: 18.935752 },
    { lat: 48.174609, lng: 18.93596 },
    { lat: 48.175058, lng: 18.936338 },
    { lat: 48.175291, lng: 18.936727 },
    { lat: 48.175549, lng: 18.937102 },
    { lat: 48.175812, lng: 18.937369 },
    { lat: 48.176092, lng: 18.937487 },
    { lat: 48.176361, lng: 18.93767 },
    { lat: 48.176608, lng: 18.938005 },
    { lat: 48.17684, lng: 18.938088 },
    { lat: 48.176863, lng: 18.938096 },
    { lat: 48.176878, lng: 18.938163 },
    { lat: 48.176926, lng: 18.938247 },
    { lat: 48.176956, lng: 18.938188 },
    { lat: 48.176993, lng: 18.938219 },
    { lat: 48.17702, lng: 18.938199 },
    { lat: 48.1769765, lng: 18.9380564 },
    { lat: 48.1769874, lng: 18.9376785 },
    { lat: 48.1768757, lng: 18.9370205 },
    { lat: 48.1769501, lng: 18.9364979 },
    { lat: 48.1769188, lng: 18.9354099 },
    { lat: 48.1769534, lng: 18.9345297 },
    { lat: 48.17706, lng: 18.9337967 },
    { lat: 48.177068, lng: 18.9333662 },
    { lat: 48.1771496, lng: 18.9332621 },
    { lat: 48.1772518, lng: 18.9328638 },
    { lat: 48.1772267, lng: 18.9326145 },
    { lat: 48.1773085, lng: 18.9322122 },
    { lat: 48.177271, lng: 18.9319445 },
    { lat: 48.177343, lng: 18.9316595 },
    { lat: 48.1774487, lng: 18.9315647 },
    { lat: 48.1773357, lng: 18.9313219 },
    { lat: 48.177289, lng: 18.9308984 },
    { lat: 48.1773347, lng: 18.9297747 },
    { lat: 48.1767521, lng: 18.9286465 },
    { lat: 48.1759463, lng: 18.9285679 },
    { lat: 48.1750722, lng: 18.9279546 },
    { lat: 48.1747498, lng: 18.9269292 },
    { lat: 48.17421, lng: 18.9265878 },
    { lat: 48.1732953, lng: 18.9264508 },
    { lat: 48.1728931, lng: 18.9261759 },
    { lat: 48.1724817, lng: 18.926134 },
    { lat: 48.1691017, lng: 18.9250574 },
    { lat: 48.1681708, lng: 18.9248485 },
    { lat: 48.1676833, lng: 18.9239883 },
    { lat: 48.1668907, lng: 18.9241884 },
    { lat: 48.1664967, lng: 18.9230888 },
    { lat: 48.166434, lng: 18.9226168 },
    { lat: 48.1666781, lng: 18.9207313 },
    { lat: 48.1665742, lng: 18.9196611 },
    { lat: 48.1664562, lng: 18.9190379 },
    { lat: 48.1663973, lng: 18.9152646 },
    { lat: 48.1661359, lng: 18.9112485 },
    { lat: 48.166944, lng: 18.9078028 },
    { lat: 48.1673048, lng: 18.9069104 },
    { lat: 48.1673618, lng: 18.9065743 },
    { lat: 48.1677356, lng: 18.9052632 },
    { lat: 48.1685521, lng: 18.9042402 },
    { lat: 48.168726, lng: 18.9036181 },
    { lat: 48.1687064, lng: 18.9034586 },
    { lat: 48.1687459, lng: 18.9032852 },
    { lat: 48.1686982, lng: 18.9029089 },
    { lat: 48.1687975, lng: 18.9028235 },
    { lat: 48.1687053, lng: 18.9025668 },
    { lat: 48.1687278, lng: 18.9008316 },
    { lat: 48.1686723, lng: 18.9003908 },
    { lat: 48.1682237, lng: 18.8992878 },
    { lat: 48.1682651, lng: 18.898956 },
    { lat: 48.1695073, lng: 18.8993391 },
    { lat: 48.1696329, lng: 18.8993047 },
    { lat: 48.1700576, lng: 18.8986231 },
    { lat: 48.1711295, lng: 18.8973336 },
    { lat: 48.1717262, lng: 18.8967934 },
    { lat: 48.1721605, lng: 18.896058 },
    { lat: 48.1725509, lng: 18.8950677 },
    { lat: 48.1725687, lng: 18.8950197 },
    { lat: 48.1736905, lng: 18.8922175 },
    { lat: 48.1749283, lng: 18.8890269 },
    { lat: 48.1724721, lng: 18.8859457 },
    { lat: 48.172343, lng: 18.8853489 },
    { lat: 48.1724884, lng: 18.8852822 },
    { lat: 48.172538, lng: 18.8851969 },
    { lat: 48.17258, lng: 18.8850503 },
    { lat: 48.172855, lng: 18.8846142 },
    { lat: 48.1736154, lng: 18.883126 },
    { lat: 48.1743287, lng: 18.8821223 },
    { lat: 48.1764257, lng: 18.878914 },
    { lat: 48.1744822, lng: 18.8764799 },
    { lat: 48.1750283, lng: 18.8756196 },
    { lat: 48.1763954, lng: 18.8740046 },
    { lat: 48.1775009, lng: 18.8718968 },
    { lat: 48.1797973, lng: 18.8687967 },
    { lat: 48.1803182, lng: 18.8677579 },
    { lat: 48.1803484, lng: 18.8676897 },
    { lat: 48.1800834, lng: 18.8673616 },
    { lat: 48.1799812, lng: 18.8671549 },
    { lat: 48.1801059, lng: 18.8669506 },
    { lat: 48.180273, lng: 18.8668251 },
    { lat: 48.1801531, lng: 18.8666062 },
    { lat: 48.1800164, lng: 18.8666854 },
    { lat: 48.1798955, lng: 18.866596 },
    { lat: 48.1797818, lng: 18.8666849 },
    { lat: 48.179698, lng: 18.8664393 },
    { lat: 48.1791801, lng: 18.8662817 },
    { lat: 48.1791566, lng: 18.8663301 },
    { lat: 48.1778898, lng: 18.8648326 },
    { lat: 48.1755922, lng: 18.8612194 },
    { lat: 48.1746089, lng: 18.8598628 },
    { lat: 48.1745536, lng: 18.859645 },
    { lat: 48.1732945, lng: 18.8577778 },
    { lat: 48.1730066, lng: 18.8577273 },
    { lat: 48.1729378, lng: 18.8576393 },
    { lat: 48.1726033, lng: 18.8577134 },
    { lat: 48.1725311, lng: 18.8575403 },
    { lat: 48.1723629, lng: 18.8574289 },
    { lat: 48.1713845, lng: 18.8573719 },
    { lat: 48.1705475, lng: 18.8583371 },
    { lat: 48.1696654, lng: 18.8610087 },
    { lat: 48.167723, lng: 18.860163 },
  ],
  Ladzany: [
    { lat: 48.3059, lng: 18.852358 },
    { lat: 48.305788, lng: 18.852422 },
    { lat: 48.305687, lng: 18.85207 },
    { lat: 48.305674, lng: 18.852094 },
    { lat: 48.305602, lng: 18.852025 },
    { lat: 48.305486, lng: 18.85193 },
    { lat: 48.305251, lng: 18.851863 },
    { lat: 48.305217, lng: 18.851868 },
    { lat: 48.305177, lng: 18.851866 },
    { lat: 48.305016, lng: 18.851813 },
    { lat: 48.304853, lng: 18.851874 },
    { lat: 48.304719, lng: 18.851947 },
    { lat: 48.304614, lng: 18.85206 },
    { lat: 48.304305, lng: 18.852107 },
    { lat: 48.304079, lng: 18.852156 },
    { lat: 48.303872, lng: 18.852188 },
    { lat: 48.303624, lng: 18.852101 },
    { lat: 48.30351, lng: 18.852067 },
    { lat: 48.303234, lng: 18.8519 },
    { lat: 48.303148, lng: 18.851829 },
    { lat: 48.302966, lng: 18.851622 },
    { lat: 48.302757, lng: 18.851437 },
    { lat: 48.302666, lng: 18.851416 },
    { lat: 48.302637, lng: 18.851373 },
    { lat: 48.302629, lng: 18.851304 },
    { lat: 48.302646, lng: 18.851143 },
    { lat: 48.302623, lng: 18.851026 },
    { lat: 48.302558, lng: 18.850828 },
    { lat: 48.302546, lng: 18.850729 },
    { lat: 48.302536, lng: 18.850577 },
    { lat: 48.302473, lng: 18.850344 },
    { lat: 48.302418, lng: 18.850183 },
    { lat: 48.302339, lng: 18.849985 },
    { lat: 48.302253, lng: 18.849367 },
    { lat: 48.302234, lng: 18.849219 },
    { lat: 48.302219, lng: 18.849132 },
    { lat: 48.302169, lng: 18.848951 },
    { lat: 48.302085, lng: 18.848566 },
    { lat: 48.302034, lng: 18.84855 },
    { lat: 48.301298, lng: 18.8482 },
    { lat: 48.301119, lng: 18.84819 },
    { lat: 48.30075, lng: 18.848158 },
    { lat: 48.300667, lng: 18.848037 },
    { lat: 48.300596, lng: 18.847856 },
    { lat: 48.3004, lng: 18.847317 },
    { lat: 48.300166, lng: 18.846861 },
    { lat: 48.300043, lng: 18.846557 },
    { lat: 48.299972, lng: 18.846466 },
    { lat: 48.299613, lng: 18.845982 },
    { lat: 48.299576, lng: 18.845943 },
    { lat: 48.299502, lng: 18.846164 },
    { lat: 48.299493, lng: 18.846262 },
    { lat: 48.299329, lng: 18.846847 },
    { lat: 48.299198, lng: 18.846915 },
    { lat: 48.299034, lng: 18.847011 },
    { lat: 48.298418, lng: 18.847307 },
    { lat: 48.298208, lng: 18.846822 },
    { lat: 48.29781, lng: 18.846283 },
    { lat: 48.297436, lng: 18.845353 },
    { lat: 48.297301, lng: 18.844786 },
    { lat: 48.297144, lng: 18.844154 },
    { lat: 48.297075, lng: 18.844198 },
    { lat: 48.296363, lng: 18.844451 },
    { lat: 48.295863, lng: 18.844615 },
    { lat: 48.295306, lng: 18.844885 },
    { lat: 48.295085, lng: 18.844829 },
    { lat: 48.294815, lng: 18.844219 },
    { lat: 48.294079, lng: 18.843689 },
    { lat: 48.293828, lng: 18.843687 },
    { lat: 48.29364, lng: 18.843748 },
    { lat: 48.292976, lng: 18.843791 },
    { lat: 48.292664, lng: 18.844047 },
    { lat: 48.292298, lng: 18.844321 },
    { lat: 48.291664, lng: 18.844534 },
    { lat: 48.290469, lng: 18.844658 },
    { lat: 48.290115, lng: 18.844539 },
    { lat: 48.289804, lng: 18.844036 },
    { lat: 48.289208, lng: 18.843051 },
    { lat: 48.289165, lng: 18.842913 },
    { lat: 48.288854, lng: 18.84207 },
    { lat: 48.288517, lng: 18.842039 },
    { lat: 48.288467, lng: 18.841846 },
    { lat: 48.288491, lng: 18.841666 },
    { lat: 48.288487, lng: 18.841597 },
    { lat: 48.288484, lng: 18.841584 },
    { lat: 48.288404, lng: 18.841653 },
    { lat: 48.288351, lng: 18.841637 },
    { lat: 48.288187, lng: 18.841617 },
    { lat: 48.288117, lng: 18.841665 },
    { lat: 48.28802, lng: 18.841687 },
    { lat: 48.287948, lng: 18.841638 },
    { lat: 48.28779, lng: 18.841586 },
    { lat: 48.287737, lng: 18.841626 },
    { lat: 48.287618, lng: 18.841589 },
    { lat: 48.287564, lng: 18.841656 },
    { lat: 48.287526, lng: 18.841783 },
    { lat: 48.287419, lng: 18.841766 },
    { lat: 48.287345, lng: 18.841703 },
    { lat: 48.287311, lng: 18.841718 },
    { lat: 48.287273, lng: 18.841704 },
    { lat: 48.287123, lng: 18.841633 },
    { lat: 48.287049, lng: 18.8417 },
    { lat: 48.286952, lng: 18.841673 },
    { lat: 48.2869, lng: 18.841683 },
    { lat: 48.28678, lng: 18.841604 },
    { lat: 48.286614, lng: 18.841503 },
    { lat: 48.286525, lng: 18.84151 },
    { lat: 48.286505, lng: 18.841466 },
    { lat: 48.286457, lng: 18.841456 },
    { lat: 48.28633, lng: 18.84135 },
    { lat: 48.286302, lng: 18.841293 },
    { lat: 48.28616, lng: 18.84116 },
    { lat: 48.286057, lng: 18.841007 },
    { lat: 48.28603, lng: 18.840891 },
    { lat: 48.285933, lng: 18.840772 },
    { lat: 48.285945, lng: 18.840678 },
    { lat: 48.285935, lng: 18.84056 },
    { lat: 48.28592, lng: 18.840508 },
    { lat: 48.285847, lng: 18.840401 },
    { lat: 48.285838, lng: 18.840233 },
    { lat: 48.285796, lng: 18.840065 },
    { lat: 48.285671, lng: 18.839885 },
    { lat: 48.285606, lng: 18.839823 },
    { lat: 48.2855, lng: 18.839796 },
    { lat: 48.285426, lng: 18.839928 },
    { lat: 48.285406, lng: 18.839997 },
    { lat: 48.285365, lng: 18.839992 },
    { lat: 48.285277, lng: 18.840069 },
    { lat: 48.285208, lng: 18.840024 },
    { lat: 48.285106, lng: 18.840091 },
    { lat: 48.285072, lng: 18.840136 },
    { lat: 48.285059, lng: 18.84016 },
    { lat: 48.28508, lng: 18.84019 },
    { lat: 48.285104, lng: 18.840203 },
    { lat: 48.285139, lng: 18.84022 },
    { lat: 48.285136, lng: 18.840242 },
    { lat: 48.285119, lng: 18.840269 },
    { lat: 48.284849, lng: 18.840342 },
    { lat: 48.284639, lng: 18.840404 },
    { lat: 48.28446, lng: 18.84033 },
    { lat: 48.284319, lng: 18.840506 },
    { lat: 48.284279, lng: 18.840523 },
    { lat: 48.284177, lng: 18.840464 },
    { lat: 48.284102, lng: 18.840592 },
    { lat: 48.284014, lng: 18.840578 },
    { lat: 48.283921, lng: 18.840709 },
    { lat: 48.2839, lng: 18.840699 },
    { lat: 48.283786, lng: 18.84066 },
    { lat: 48.28368, lng: 18.840581 },
    { lat: 48.283512, lng: 18.840544 },
    { lat: 48.283342, lng: 18.840443 },
    { lat: 48.283137, lng: 18.84052 },
    { lat: 48.283091, lng: 18.840649 },
    { lat: 48.283086, lng: 18.840663 },
    { lat: 48.283027, lng: 18.840503 },
    { lat: 48.282953, lng: 18.840389 },
    { lat: 48.282867, lng: 18.840349 },
    { lat: 48.282818, lng: 18.840283 },
    { lat: 48.282764, lng: 18.840283 },
    { lat: 48.282691, lng: 18.840419 },
    { lat: 48.282627, lng: 18.840446 },
    { lat: 48.282528, lng: 18.840491 },
    { lat: 48.282492, lng: 18.840471 },
    { lat: 48.282447, lng: 18.84039 },
    { lat: 48.282364, lng: 18.840316 },
    { lat: 48.282287, lng: 18.840306 },
    { lat: 48.282204, lng: 18.840249 },
    { lat: 48.282092, lng: 18.840419 },
    { lat: 48.282037, lng: 18.840457 },
    { lat: 48.281939, lng: 18.84041 },
    { lat: 48.281829, lng: 18.840477 },
    { lat: 48.281787, lng: 18.840435 },
    { lat: 48.281645, lng: 18.840504 },
    { lat: 48.281596, lng: 18.840494 },
    { lat: 48.281519, lng: 18.840472 },
    { lat: 48.281482, lng: 18.840534 },
    { lat: 48.281304, lng: 18.840601 },
    { lat: 48.281175, lng: 18.840604 },
    { lat: 48.281079, lng: 18.840804 },
    { lat: 48.28101, lng: 18.840841 },
    { lat: 48.280927, lng: 18.840834 },
    { lat: 48.280798, lng: 18.840906 },
    { lat: 48.280801, lng: 18.841118 },
    { lat: 48.280792, lng: 18.84116 },
    { lat: 48.280706, lng: 18.84121 },
    { lat: 48.280592, lng: 18.841168 },
    { lat: 48.280498, lng: 18.841348 },
    { lat: 48.28049, lng: 18.84139 },
    { lat: 48.280533, lng: 18.841591 },
    { lat: 48.280457, lng: 18.841762 },
    { lat: 48.280365, lng: 18.841996 },
    { lat: 48.280258, lng: 18.841966 },
    { lat: 48.280217, lng: 18.841954 },
    { lat: 48.280019, lng: 18.842029 },
    { lat: 48.27995, lng: 18.842101 },
    { lat: 48.279867, lng: 18.842096 },
    { lat: 48.279826, lng: 18.841982 },
    { lat: 48.279739, lng: 18.842041 },
    { lat: 48.279581, lng: 18.842177 },
    { lat: 48.27932, lng: 18.842405 },
    { lat: 48.27921, lng: 18.842442 },
    { lat: 48.279126, lng: 18.84257 },
    { lat: 48.279041, lng: 18.84265 },
    { lat: 48.278937, lng: 18.842734 },
    { lat: 48.278822, lng: 18.842865 },
    { lat: 48.278839, lng: 18.842982 },
    { lat: 48.278292, lng: 18.84318 },
    { lat: 48.277821, lng: 18.843065 },
    { lat: 48.277598, lng: 18.843103 },
    { lat: 48.277439, lng: 18.843159 },
    { lat: 48.277379, lng: 18.843168 },
    { lat: 48.277233, lng: 18.843152 },
    { lat: 48.276831, lng: 18.843358 },
    { lat: 48.276651, lng: 18.843433 },
    { lat: 48.27649, lng: 18.843529 },
    { lat: 48.276177, lng: 18.843754 },
    { lat: 48.275793, lng: 18.843911 },
    { lat: 48.275291, lng: 18.844106 },
    { lat: 48.27501, lng: 18.844087 },
    { lat: 48.274916, lng: 18.844061 },
    { lat: 48.27492, lng: 18.844026 },
    { lat: 48.274019, lng: 18.843648 },
    { lat: 48.273416, lng: 18.842971 },
    { lat: 48.272948, lng: 18.842335 },
    { lat: 48.272124, lng: 18.8412 },
    { lat: 48.271918, lng: 18.841149 },
    { lat: 48.271152, lng: 18.840968 },
    { lat: 48.270705, lng: 18.84076 },
    { lat: 48.26978, lng: 18.84049 },
    { lat: 48.269747, lng: 18.840481 },
    { lat: 48.269716, lng: 18.840469 },
    { lat: 48.26944, lng: 18.840454 },
    { lat: 48.26913, lng: 18.840422 },
    { lat: 48.268569, lng: 18.840495 },
    { lat: 48.268517, lng: 18.840504 },
    { lat: 48.26807, lng: 18.840583 },
    { lat: 48.2677842, lng: 18.8417387 },
    { lat: 48.2675597, lng: 18.8421449 },
    { lat: 48.2673952, lng: 18.8426002 },
    { lat: 48.2672529, lng: 18.8432452 },
    { lat: 48.2669443, lng: 18.843876 },
    { lat: 48.2664294, lng: 18.8447028 },
    { lat: 48.2655052, lng: 18.8468891 },
    { lat: 48.2640966, lng: 18.8485872 },
    { lat: 48.2636021, lng: 18.8489018 },
    { lat: 48.2632836, lng: 18.8492814 },
    { lat: 48.2630289, lng: 18.8496939 },
    { lat: 48.2625041, lng: 18.8502215 },
    { lat: 48.2613192, lng: 18.8519753 },
    { lat: 48.2606778, lng: 18.8532113 },
    { lat: 48.2603907, lng: 18.8546449 },
    { lat: 48.2601863, lng: 18.8551561 },
    { lat: 48.2598826, lng: 18.8556447 },
    { lat: 48.2582791, lng: 18.8575669 },
    { lat: 48.2583722, lng: 18.8580337 },
    { lat: 48.2585541, lng: 18.8598114 },
    { lat: 48.2578541, lng: 18.8603117 },
    { lat: 48.2574445, lng: 18.8609743 },
    { lat: 48.2577423, lng: 18.8613471 },
    { lat: 48.2578436, lng: 18.8619025 },
    { lat: 48.2578503, lng: 18.8624434 },
    { lat: 48.2577044, lng: 18.8636693 },
    { lat: 48.257494, lng: 18.8641058 },
    { lat: 48.2576127, lng: 18.8649543 },
    { lat: 48.257983, lng: 18.8671697 },
    { lat: 48.2586728, lng: 18.868657 },
    { lat: 48.2589633, lng: 18.8695332 },
    { lat: 48.2594736, lng: 18.8705599 },
    { lat: 48.2593908, lng: 18.8706818 },
    { lat: 48.260115, lng: 18.872094 },
    { lat: 48.2594776, lng: 18.8727184 },
    { lat: 48.2607694, lng: 18.8739844 },
    { lat: 48.2613598, lng: 18.8749927 },
    { lat: 48.2618203, lng: 18.8756725 },
    { lat: 48.2619279, lng: 18.875942 },
    { lat: 48.2627657, lng: 18.8771121 },
    { lat: 48.262357, lng: 18.8781394 },
    { lat: 48.2621811, lng: 18.8784643 },
    { lat: 48.2620199, lng: 18.879463 },
    { lat: 48.2615066, lng: 18.8812778 },
    { lat: 48.2614487, lng: 18.8817036 },
    { lat: 48.2614525, lng: 18.8831866 },
    { lat: 48.2615976, lng: 18.8836142 },
    { lat: 48.2619452, lng: 18.8842039 },
    { lat: 48.2622298, lng: 18.8850841 },
    { lat: 48.2623521, lng: 18.8853087 },
    { lat: 48.2625233, lng: 18.8860202 },
    { lat: 48.2628944, lng: 18.886954 },
    { lat: 48.2630283, lng: 18.8875574 },
    { lat: 48.2631419, lng: 18.8875965 },
    { lat: 48.2632141, lng: 18.8881179 },
    { lat: 48.2627359, lng: 18.888875 },
    { lat: 48.2633852, lng: 18.8894778 },
    { lat: 48.262967, lng: 18.8909123 },
    { lat: 48.2632213, lng: 18.8911634 },
    { lat: 48.2630312, lng: 18.891562 },
    { lat: 48.2627307, lng: 18.8933569 },
    { lat: 48.2623503, lng: 18.8942389 },
    { lat: 48.2617005, lng: 18.8951918 },
    { lat: 48.2609937, lng: 18.8960412 },
    { lat: 48.2606852, lng: 18.8963999 },
    { lat: 48.2606401, lng: 18.8963131 },
    { lat: 48.2602375, lng: 18.8968511 },
    { lat: 48.2598345, lng: 18.8972597 },
    { lat: 48.2594683, lng: 18.8978072 },
    { lat: 48.2591064, lng: 18.8980273 },
    { lat: 48.2590859, lng: 18.8983475 },
    { lat: 48.2591344, lng: 18.8984339 },
    { lat: 48.2591876, lng: 18.8990908 },
    { lat: 48.2590956, lng: 18.8995994 },
    { lat: 48.2591378, lng: 18.90039 },
    { lat: 48.2595187, lng: 18.9030849 },
    { lat: 48.2596478, lng: 18.9042907 },
    { lat: 48.2599464, lng: 18.9054415 },
    { lat: 48.2599781, lng: 18.906314 },
    { lat: 48.2599241, lng: 18.9070552 },
    { lat: 48.2596923, lng: 18.9076292 },
    { lat: 48.259351, lng: 18.9075967 },
    { lat: 48.2591529, lng: 18.9076674 },
    { lat: 48.2592273, lng: 18.9082098 },
    { lat: 48.2591285, lng: 18.9088299 },
    { lat: 48.2591729, lng: 18.9091223 },
    { lat: 48.2591323, lng: 18.9105375 },
    { lat: 48.2589221, lng: 18.9117038 },
    { lat: 48.2587309, lng: 18.9119086 },
    { lat: 48.259667, lng: 18.9147886 },
    { lat: 48.2603098, lng: 18.9169515 },
    { lat: 48.2602634, lng: 18.9169445 },
    { lat: 48.2614816, lng: 18.9194834 },
    { lat: 48.2615357, lng: 18.919681 },
    { lat: 48.2615581, lng: 18.9202874 },
    { lat: 48.2631924, lng: 18.9228929 },
    { lat: 48.2643703, lng: 18.9206151 },
    { lat: 48.2648965, lng: 18.919842 },
    { lat: 48.265751, lng: 18.9188374 },
    { lat: 48.2661377, lng: 18.9182695 },
    { lat: 48.2662613, lng: 18.918173 },
    { lat: 48.2671536, lng: 18.9178735 },
    { lat: 48.2676516, lng: 18.9176257 },
    { lat: 48.2676827, lng: 18.9176829 },
    { lat: 48.2677982, lng: 18.9176504 },
    { lat: 48.2687333, lng: 18.917067 },
    { lat: 48.2700876, lng: 18.9161051 },
    { lat: 48.27101, lng: 18.9153458 },
    { lat: 48.2719482, lng: 18.9144936 },
    { lat: 48.2734316, lng: 18.9128257 },
    { lat: 48.2743713, lng: 18.9117018 },
    { lat: 48.2751263, lng: 18.9106482 },
    { lat: 48.2762697, lng: 18.9092103 },
    { lat: 48.2763227, lng: 18.9092979 },
    { lat: 48.2766256, lng: 18.9091148 },
    { lat: 48.2770697, lng: 18.9087842 },
    { lat: 48.2775162, lng: 18.9083249 },
    { lat: 48.2775978, lng: 18.9084408 },
    { lat: 48.2779733, lng: 18.907875 },
    { lat: 48.2785164, lng: 18.9065811 },
    { lat: 48.2788247, lng: 18.9059831 },
    { lat: 48.2789502, lng: 18.9056584 },
    { lat: 48.2789577, lng: 18.9055029 },
    { lat: 48.2793089, lng: 18.9048383 },
    { lat: 48.2798076, lng: 18.9043201 },
    { lat: 48.2804145, lng: 18.9034736 },
    { lat: 48.2811646, lng: 18.9029339 },
    { lat: 48.2813935, lng: 18.9029133 },
    { lat: 48.281627, lng: 18.9030706 },
    { lat: 48.281727, lng: 18.9029298 },
    { lat: 48.281982, lng: 18.9027571 },
    { lat: 48.2821885, lng: 18.9024778 },
    { lat: 48.2823238, lng: 18.9024355 },
    { lat: 48.2824252, lng: 18.9024772 },
    { lat: 48.2824823, lng: 18.9022525 },
    { lat: 48.2830001, lng: 18.9021253 },
    { lat: 48.2832091, lng: 18.9021786 },
    { lat: 48.2833643, lng: 18.9019729 },
    { lat: 48.2835234, lng: 18.9019738 },
    { lat: 48.2837248, lng: 18.9017468 },
    { lat: 48.2840763, lng: 18.9016999 },
    { lat: 48.2845203, lng: 18.9014316 },
    { lat: 48.2847051, lng: 18.9014501 },
    { lat: 48.2847537, lng: 18.9013432 },
    { lat: 48.2848634, lng: 18.9013485 },
    { lat: 48.2849486, lng: 18.9014515 },
    { lat: 48.285082, lng: 18.9013273 },
    { lat: 48.2852094, lng: 18.9013267 },
    { lat: 48.2855487, lng: 18.9014316 },
    { lat: 48.2858075, lng: 18.9012472 },
    { lat: 48.2859987, lng: 18.9012834 },
    { lat: 48.286156, lng: 18.9010948 },
    { lat: 48.2862846, lng: 18.9011821 },
    { lat: 48.2863852, lng: 18.9010596 },
    { lat: 48.2863094, lng: 18.9009225 },
    { lat: 48.286335, lng: 18.9007652 },
    { lat: 48.2864492, lng: 18.900793 },
    { lat: 48.2866763, lng: 18.9006487 },
    { lat: 48.2869183, lng: 18.900663 },
    { lat: 48.287378, lng: 18.900875 },
    { lat: 48.2876724, lng: 18.9008107 },
    { lat: 48.287888, lng: 18.9009449 },
    { lat: 48.2880372, lng: 18.9009743 },
    { lat: 48.2882559, lng: 18.9009078 },
    { lat: 48.2884665, lng: 18.9010331 },
    { lat: 48.2886368, lng: 18.9009453 },
    { lat: 48.2887596, lng: 18.9009664 },
    { lat: 48.289097, lng: 18.9011204 },
    { lat: 48.2892098, lng: 18.901255 },
    { lat: 48.2893596, lng: 18.9015789 },
    { lat: 48.2894608, lng: 18.9016347 },
    { lat: 48.2896612, lng: 18.9015664 },
    { lat: 48.2902228, lng: 18.9016505 },
    { lat: 48.290408, lng: 18.9015036 },
    { lat: 48.2905748, lng: 18.9014821 },
    { lat: 48.2908015, lng: 18.9012875 },
    { lat: 48.2916672, lng: 18.9013224 },
    { lat: 48.2919116, lng: 18.9012104 },
    { lat: 48.2922022, lng: 18.9013136 },
    { lat: 48.2922825, lng: 18.9014906 },
    { lat: 48.2925531, lng: 18.9015554 },
    { lat: 48.2928239, lng: 18.9019516 },
    { lat: 48.2929755, lng: 18.9020055 },
    { lat: 48.2931425, lng: 18.9022467 },
    { lat: 48.2931517, lng: 18.9024471 },
    { lat: 48.2933559, lng: 18.9027944 },
    { lat: 48.2934696, lng: 18.903141 },
    { lat: 48.2936629, lng: 18.9033019 },
    { lat: 48.2937542, lng: 18.9032343 },
    { lat: 48.2939224, lng: 18.9036304 },
    { lat: 48.294106, lng: 18.9038676 },
    { lat: 48.2941592, lng: 18.9042615 },
    { lat: 48.2943876, lng: 18.9047973 },
    { lat: 48.2943973, lng: 18.9049619 },
    { lat: 48.2946667, lng: 18.9053519 },
    { lat: 48.2947756, lng: 18.905386 },
    { lat: 48.2948422, lng: 18.9054955 },
    { lat: 48.2949303, lng: 18.9054767 },
    { lat: 48.2952384, lng: 18.9058963 },
    { lat: 48.2956107, lng: 18.9060945 },
    { lat: 48.2957111, lng: 18.9060301 },
    { lat: 48.2960724, lng: 18.906391 },
    { lat: 48.2962525, lng: 18.9063602 },
    { lat: 48.2964254, lng: 18.9061726 },
    { lat: 48.2965602, lng: 18.9061428 },
    { lat: 48.296822, lng: 18.9062111 },
    { lat: 48.2969084, lng: 18.9063332 },
    { lat: 48.297045, lng: 18.9063382 },
    { lat: 48.2973272, lng: 18.9063202 },
    { lat: 48.2975845, lng: 18.9062149 },
    { lat: 48.2976368, lng: 18.9062679 },
    { lat: 48.2979109, lng: 18.9062669 },
    { lat: 48.2979431, lng: 18.9061962 },
    { lat: 48.2980592, lng: 18.9062146 },
    { lat: 48.2980966, lng: 18.9061114 },
    { lat: 48.2981021, lng: 18.9061958 },
    { lat: 48.2982675, lng: 18.9063171 },
    { lat: 48.2983789, lng: 18.9062249 },
    { lat: 48.2985942, lng: 18.9062253 },
    { lat: 48.2986754, lng: 18.9060509 },
    { lat: 48.2988053, lng: 18.9059887 },
    { lat: 48.2990261, lng: 18.9059644 },
    { lat: 48.2992267, lng: 18.906178 },
    { lat: 48.2994526, lng: 18.9060774 },
    { lat: 48.2995279, lng: 18.9061401 },
    { lat: 48.2997488, lng: 18.9060716 },
    { lat: 48.3002157, lng: 18.9062273 },
    { lat: 48.300501, lng: 18.9061594 },
    { lat: 48.3009175, lng: 18.9066235 },
    { lat: 48.3009084, lng: 18.9068413 },
    { lat: 48.30095, lng: 18.9069682 },
    { lat: 48.3012794, lng: 18.9074951 },
    { lat: 48.3015892, lng: 18.9078366 },
    { lat: 48.3016977, lng: 18.9080609 },
    { lat: 48.3017074, lng: 18.9081848 },
    { lat: 48.3018489, lng: 18.9083451 },
    { lat: 48.3021264, lng: 18.9088936 },
    { lat: 48.3024858, lng: 18.9091912 },
    { lat: 48.3030519, lng: 18.9092143 },
    { lat: 48.3031094, lng: 18.9091286 },
    { lat: 48.3036167, lng: 18.9088858 },
    { lat: 48.3043858, lng: 18.9090819 },
    { lat: 48.3047192, lng: 18.9090541 },
    { lat: 48.3050555, lng: 18.9088411 },
    { lat: 48.305206, lng: 18.9086129 },
    { lat: 48.3052847, lng: 18.9086487 },
    { lat: 48.3052874, lng: 18.9085496 },
    { lat: 48.3053977, lng: 18.9085262 },
    { lat: 48.3055104, lng: 18.9083721 },
    { lat: 48.3056326, lng: 18.9084447 },
    { lat: 48.3060735, lng: 18.908358 },
    { lat: 48.3061667, lng: 18.9082639 },
    { lat: 48.3062199, lng: 18.9081146 },
    { lat: 48.3066331, lng: 18.9081038 },
    { lat: 48.3068987, lng: 18.9077421 },
    { lat: 48.3070976, lng: 18.9073588 },
    { lat: 48.3071311, lng: 18.9070924 },
    { lat: 48.3074103, lng: 18.9070805 },
    { lat: 48.3075643, lng: 18.9069209 },
    { lat: 48.3078201, lng: 18.9068087 },
    { lat: 48.3079254, lng: 18.906885 },
    { lat: 48.3081594, lng: 18.9068082 },
    { lat: 48.3091258, lng: 18.9062647 },
    { lat: 48.3102863, lng: 18.9057392 },
    { lat: 48.310313, lng: 18.905758 },
    { lat: 48.310387, lng: 18.905499 },
    { lat: 48.310507, lng: 18.905147 },
    { lat: 48.310492, lng: 18.904866 },
    { lat: 48.310469, lng: 18.904421 },
    { lat: 48.310408, lng: 18.903869 },
    { lat: 48.310226, lng: 18.903572 },
    { lat: 48.31014, lng: 18.903311 },
    { lat: 48.310162, lng: 18.903309 },
    { lat: 48.310623, lng: 18.902647 },
    { lat: 48.310998, lng: 18.90202 },
    { lat: 48.311407, lng: 18.901742 },
    { lat: 48.311822, lng: 18.90137 },
    { lat: 48.312407, lng: 18.90083 },
    { lat: 48.312457, lng: 18.900743 },
    { lat: 48.312599, lng: 18.900564 },
    { lat: 48.313104, lng: 18.900208 },
    { lat: 48.31333, lng: 18.900168 },
    { lat: 48.313577, lng: 18.899889 },
    { lat: 48.313825, lng: 18.899664 },
    { lat: 48.314203, lng: 18.899776 },
    { lat: 48.31445, lng: 18.900023 },
    { lat: 48.314965, lng: 18.90022 },
    { lat: 48.315304, lng: 18.900208 },
    { lat: 48.316095, lng: 18.899574 },
    { lat: 48.315953, lng: 18.899254 },
    { lat: 48.315862, lng: 18.898736 },
    { lat: 48.315847, lng: 18.898521 },
    { lat: 48.315692, lng: 18.897726 },
    { lat: 48.315579, lng: 18.897469 },
    { lat: 48.31551, lng: 18.896817 },
    { lat: 48.315496, lng: 18.896196 },
    { lat: 48.315455, lng: 18.895796 },
    { lat: 48.315364, lng: 18.895737 },
    { lat: 48.315159, lng: 18.8951 },
    { lat: 48.315124, lng: 18.894752 },
    { lat: 48.31497, lng: 18.894196 },
    { lat: 48.314924, lng: 18.89403 },
    { lat: 48.314884, lng: 18.893697 },
    { lat: 48.314814, lng: 18.893533 },
    { lat: 48.314736, lng: 18.89335 },
    { lat: 48.314477, lng: 18.892801 },
    { lat: 48.313883, lng: 18.891547 },
    { lat: 48.313978, lng: 18.891323 },
    { lat: 48.314035, lng: 18.891334 },
    { lat: 48.314112, lng: 18.89146 },
    { lat: 48.314166, lng: 18.891431 },
    { lat: 48.314172, lng: 18.891372 },
    { lat: 48.314282, lng: 18.891368 },
    { lat: 48.314308, lng: 18.891178 },
    { lat: 48.314235, lng: 18.891028 },
    { lat: 48.314343, lng: 18.89103 },
    { lat: 48.314592, lng: 18.890686 },
    { lat: 48.314767, lng: 18.890808 },
    { lat: 48.314961, lng: 18.890812 },
    { lat: 48.315076, lng: 18.890653 },
    { lat: 48.315226, lng: 18.890267 },
    { lat: 48.315311, lng: 18.890242 },
    { lat: 48.315345, lng: 18.890142 },
    { lat: 48.315403, lng: 18.89014 },
    { lat: 48.315632, lng: 18.889714 },
    { lat: 48.31561, lng: 18.889562 },
    { lat: 48.315658, lng: 18.889411 },
    { lat: 48.315874, lng: 18.889311 },
    { lat: 48.31605, lng: 18.889208 },
    { lat: 48.316131, lng: 18.889024 },
    { lat: 48.316261, lng: 18.888879 },
    { lat: 48.316317, lng: 18.888672 },
    { lat: 48.316355, lng: 18.888582 },
    { lat: 48.316322, lng: 18.888348 },
    { lat: 48.316289, lng: 18.888058 },
    { lat: 48.316293, lng: 18.887838 },
    { lat: 48.316365, lng: 18.887734 },
    { lat: 48.316429, lng: 18.88789 },
    { lat: 48.316494, lng: 18.887772 },
    { lat: 48.31658, lng: 18.887778 },
    { lat: 48.316655, lng: 18.887688 },
    { lat: 48.316705, lng: 18.887495 },
    { lat: 48.316806, lng: 18.887379 },
    { lat: 48.316853, lng: 18.887523 },
    { lat: 48.316916, lng: 18.887516 },
    { lat: 48.317046, lng: 18.887462 },
    { lat: 48.317075, lng: 18.887396 },
    { lat: 48.317127, lng: 18.887445 },
    { lat: 48.317228, lng: 18.887463 },
    { lat: 48.317443, lng: 18.887269 },
    { lat: 48.317564, lng: 18.887314 },
    { lat: 48.317665, lng: 18.887184 },
    { lat: 48.317792, lng: 18.887181 },
    { lat: 48.317891, lng: 18.887207 },
    { lat: 48.317938, lng: 18.887042 },
    { lat: 48.318062, lng: 18.886982 },
    { lat: 48.318148, lng: 18.886837 },
    { lat: 48.318291, lng: 18.886936 },
    { lat: 48.318371, lng: 18.886911 },
    { lat: 48.318416, lng: 18.886956 },
    { lat: 48.318493, lng: 18.886871 },
    { lat: 48.318532, lng: 18.88679 },
    { lat: 48.318653, lng: 18.886793 },
    { lat: 48.318702, lng: 18.886694 },
    { lat: 48.318822, lng: 18.886654 },
    { lat: 48.318866, lng: 18.886524 },
    { lat: 48.318996, lng: 18.88662 },
    { lat: 48.319144, lng: 18.886517 },
    { lat: 48.319157, lng: 18.886375 },
    { lat: 48.319269, lng: 18.88628 },
    { lat: 48.319352, lng: 18.88627 },
    { lat: 48.319355, lng: 18.88618 },
    { lat: 48.319286, lng: 18.886137 },
    { lat: 48.319343, lng: 18.886073 },
    { lat: 48.319451, lng: 18.886132 },
    { lat: 48.319418, lng: 18.885957 },
    { lat: 48.319458, lng: 18.885961 },
    { lat: 48.319486, lng: 18.885908 },
    { lat: 48.319538, lng: 18.885936 },
    { lat: 48.319522, lng: 18.886018 },
    { lat: 48.319565, lng: 18.88609 },
    { lat: 48.319604, lng: 18.886044 },
    { lat: 48.319649, lng: 18.885978 },
    { lat: 48.319626, lng: 18.885924 },
    { lat: 48.319663, lng: 18.88583 },
    { lat: 48.319807, lng: 18.885881 },
    { lat: 48.319877, lng: 18.885782 },
    { lat: 48.319894, lng: 18.885631 },
    { lat: 48.320015, lng: 18.885752 },
    { lat: 48.32012, lng: 18.885696 },
    { lat: 48.320085, lng: 18.885594 },
    { lat: 48.320202, lng: 18.885438 },
    { lat: 48.320296, lng: 18.885505 },
    { lat: 48.320413, lng: 18.885351 },
    { lat: 48.320474, lng: 18.885487 },
    { lat: 48.32069, lng: 18.885587 },
    { lat: 48.320727, lng: 18.885512 },
    { lat: 48.320845, lng: 18.885606 },
    { lat: 48.320873, lng: 18.885706 },
    { lat: 48.321011, lng: 18.885535 },
    { lat: 48.321112, lng: 18.885611 },
    { lat: 48.321163, lng: 18.88553 },
    { lat: 48.321288, lng: 18.885565 },
    { lat: 48.32138, lng: 18.885513 },
    { lat: 48.321488, lng: 18.885299 },
    { lat: 48.32155, lng: 18.885189 },
    { lat: 48.32162, lng: 18.885107 },
    { lat: 48.321773, lng: 18.884861 },
    { lat: 48.321897, lng: 18.884523 },
    { lat: 48.322109, lng: 18.883492 },
    { lat: 48.322264, lng: 18.882424 },
    { lat: 48.322332, lng: 18.881949 },
    { lat: 48.322681, lng: 18.880171 },
    { lat: 48.320886, lng: 18.880566 },
    { lat: 48.318691, lng: 18.882208 },
    { lat: 48.318302, lng: 18.882706 },
    { lat: 48.316693, lng: 18.883891 },
    { lat: 48.315425, lng: 18.885143 },
    { lat: 48.315418, lng: 18.885174 },
    { lat: 48.315158, lng: 18.884896 },
    { lat: 48.314732, lng: 18.884691 },
    { lat: 48.313709, lng: 18.884603 },
    { lat: 48.313323, lng: 18.884641 },
    { lat: 48.313324, lng: 18.884608 },
    { lat: 48.313394, lng: 18.88434 },
    { lat: 48.313529, lng: 18.883496 },
    { lat: 48.313488, lng: 18.882878 },
    { lat: 48.310789, lng: 18.881936 },
    { lat: 48.310746, lng: 18.881909 },
    { lat: 48.309411, lng: 18.881517 },
    { lat: 48.308548, lng: 18.880944 },
    { lat: 48.307734, lng: 18.879531 },
    { lat: 48.307792, lng: 18.878876 },
    { lat: 48.308029, lng: 18.878348 },
    { lat: 48.308012, lng: 18.878293 },
    { lat: 48.3079669, lng: 18.878128 },
    { lat: 48.308237, lng: 18.877931 },
    { lat: 48.308385, lng: 18.877765 },
    { lat: 48.308523, lng: 18.87741 },
    { lat: 48.308542, lng: 18.87733 },
    { lat: 48.308508, lng: 18.87716 },
    { lat: 48.308314, lng: 18.876176 },
    { lat: 48.308387, lng: 18.875829 },
    { lat: 48.308408, lng: 18.875676 },
    { lat: 48.308823, lng: 18.875452 },
    { lat: 48.309021, lng: 18.875266 },
    { lat: 48.309307, lng: 18.875031 },
    { lat: 48.309696, lng: 18.874765 },
    { lat: 48.309861, lng: 18.874642 },
    { lat: 48.310152, lng: 18.874363 },
    { lat: 48.310372, lng: 18.874127 },
    { lat: 48.310676, lng: 18.873765 },
    { lat: 48.310749, lng: 18.873677 },
    { lat: 48.31114, lng: 18.873323 },
    { lat: 48.311193, lng: 18.873257 },
    { lat: 48.311207, lng: 18.873204 },
    { lat: 48.31141, lng: 18.872342 },
    { lat: 48.311985, lng: 18.871523 },
    { lat: 48.311946, lng: 18.87146 },
    { lat: 48.30877, lng: 18.867893 },
    { lat: 48.307571, lng: 18.866548 },
    { lat: 48.305738, lng: 18.863365 },
    { lat: 48.305745, lng: 18.863309 },
    { lat: 48.306106, lng: 18.862718 },
    { lat: 48.30502, lng: 18.860619 },
    { lat: 48.305016, lng: 18.858566 },
    { lat: 48.304978, lng: 18.858539 },
    { lat: 48.305594, lng: 18.85618 },
    { lat: 48.305943, lng: 18.854077 },
    { lat: 48.306027, lng: 18.853158 },
    { lat: 48.306061, lng: 18.852779 },
    { lat: 48.30594, lng: 18.852462 },
    { lat: 48.3059, lng: 18.852358 },
  ],
  Súdovce: [
    { lat: 48.2184431, lng: 18.849871 },
    { lat: 48.2184777, lng: 18.8498012 },
    { lat: 48.2187094, lng: 18.8492935 },
    { lat: 48.219933, lng: 18.8500295 },
    { lat: 48.2202404, lng: 18.8509001 },
    { lat: 48.2204096, lng: 18.8511398 },
    { lat: 48.2216147, lng: 18.8506591 },
    { lat: 48.2230125, lng: 18.8496986 },
    { lat: 48.2231065, lng: 18.8499875 },
    { lat: 48.2237143, lng: 18.8497716 },
    { lat: 48.2252773, lng: 18.8494299 },
    { lat: 48.2263443, lng: 18.8493219 },
    { lat: 48.2276281, lng: 18.8485358 },
    { lat: 48.2278728, lng: 18.8482596 },
    { lat: 48.2286838, lng: 18.8476584 },
    { lat: 48.2296091, lng: 18.8473044 },
    { lat: 48.2304698, lng: 18.8472024 },
    { lat: 48.2311368, lng: 18.8473828 },
    { lat: 48.2317383, lng: 18.8473695 },
    { lat: 48.2319811, lng: 18.8462651 },
    { lat: 48.2320472, lng: 18.8457409 },
    { lat: 48.2322867, lng: 18.8450716 },
    { lat: 48.2323129, lng: 18.8442133 },
    { lat: 48.2324548, lng: 18.8429627 },
    { lat: 48.2338624, lng: 18.8409897 },
    { lat: 48.2343518, lng: 18.8401067 },
    { lat: 48.2347112, lng: 18.8391105 },
    { lat: 48.2354666, lng: 18.8374499 },
    { lat: 48.2362166, lng: 18.836313 },
    { lat: 48.2361563, lng: 18.836065 },
    { lat: 48.2361639, lng: 18.8357582 },
    { lat: 48.2362987, lng: 18.835684 },
    { lat: 48.2361874, lng: 18.8354547 },
    { lat: 48.2358491, lng: 18.8351535 },
    { lat: 48.2355591, lng: 18.834783 },
    { lat: 48.235536, lng: 18.8343881 },
    { lat: 48.2351869, lng: 18.8341164 },
    { lat: 48.2349307, lng: 18.8337278 },
    { lat: 48.2349582, lng: 18.8334776 },
    { lat: 48.2348289, lng: 18.8330132 },
    { lat: 48.2345681, lng: 18.8327262 },
    { lat: 48.2342346, lng: 18.8325822 },
    { lat: 48.2342337, lng: 18.832415 },
    { lat: 48.2341514, lng: 18.832112 },
    { lat: 48.2338886, lng: 18.8319806 },
    { lat: 48.2337554, lng: 18.8312085 },
    { lat: 48.2335856, lng: 18.8310212 },
    { lat: 48.2336103, lng: 18.8308068 },
    { lat: 48.233813, lng: 18.8303302 },
    { lat: 48.233632, lng: 18.8297346 },
    { lat: 48.2342721, lng: 18.8297708 },
    { lat: 48.2342784, lng: 18.8300201 },
    { lat: 48.2348935, lng: 18.8299668 },
    { lat: 48.2354226, lng: 18.8295399 },
    { lat: 48.2356469, lng: 18.8294944 },
    { lat: 48.2359371, lng: 18.8297617 },
    { lat: 48.2361723, lng: 18.8303523 },
    { lat: 48.2363782, lng: 18.8304929 },
    { lat: 48.2366348, lng: 18.8304203 },
    { lat: 48.2369367, lng: 18.8301754 },
    { lat: 48.2375357, lng: 18.8301385 },
    { lat: 48.2380229, lng: 18.8298177 },
    { lat: 48.2387242, lng: 18.8295558 },
    { lat: 48.2386635, lng: 18.829453 },
    { lat: 48.2386501, lng: 18.8292826 },
    { lat: 48.2401635, lng: 18.8281452 },
    { lat: 48.2401871, lng: 18.8282712 },
    { lat: 48.2413767, lng: 18.8277541 },
    { lat: 48.2418974, lng: 18.8274491 },
    { lat: 48.2420253, lng: 18.8280174 },
    { lat: 48.242251, lng: 18.8272886 },
    { lat: 48.2425405, lng: 18.8267048 },
    { lat: 48.2428438, lng: 18.8256944 },
    { lat: 48.2429022, lng: 18.8252811 },
    { lat: 48.2430362, lng: 18.8250392 },
    { lat: 48.24313, lng: 18.8246369 },
    { lat: 48.2433312, lng: 18.8243875 },
    { lat: 48.2435176, lng: 18.8237295 },
    { lat: 48.243756, lng: 18.8233289 },
    { lat: 48.2438385, lng: 18.823033 },
    { lat: 48.2444617, lng: 18.8222746 },
    { lat: 48.2440738, lng: 18.8210481 },
    { lat: 48.2440883, lng: 18.8204255 },
    { lat: 48.2442286, lng: 18.8197503 },
    { lat: 48.2442565, lng: 18.8190769 },
    { lat: 48.2444274, lng: 18.8180389 },
    { lat: 48.2434157, lng: 18.8181016 },
    { lat: 48.2436164, lng: 18.8171988 },
    { lat: 48.2436277, lng: 18.8168967 },
    { lat: 48.2433376, lng: 18.8166157 },
    { lat: 48.2429152, lng: 18.8170947 },
    { lat: 48.2426796, lng: 18.8175484 },
    { lat: 48.2423862, lng: 18.8169225 },
    { lat: 48.2420265, lng: 18.8165386 },
    { lat: 48.2413943, lng: 18.8156258 },
    { lat: 48.2416403, lng: 18.8147686 },
    { lat: 48.2415824, lng: 18.8141613 },
    { lat: 48.2418008, lng: 18.8134496 },
    { lat: 48.241817, lng: 18.8126213 },
    { lat: 48.2423611, lng: 18.8117515 },
    { lat: 48.2429906, lng: 18.810473 },
    { lat: 48.2435715, lng: 18.8101946 },
    { lat: 48.2437978, lng: 18.8104035 },
    { lat: 48.2442746, lng: 18.8103586 },
    { lat: 48.2445031, lng: 18.8100259 },
    { lat: 48.2444761, lng: 18.8091093 },
    { lat: 48.2456632, lng: 18.8073877 },
    { lat: 48.2465567, lng: 18.808017 },
    { lat: 48.248094, lng: 18.809269 },
    { lat: 48.247947, lng: 18.809103 },
    { lat: 48.247878, lng: 18.808905 },
    { lat: 48.247565, lng: 18.808551 },
    { lat: 48.247454, lng: 18.808388 },
    { lat: 48.247327, lng: 18.808188 },
    { lat: 48.246931, lng: 18.807793 },
    { lat: 48.246887, lng: 18.807626 },
    { lat: 48.246615, lng: 18.807216 },
    { lat: 48.246562, lng: 18.807059 },
    { lat: 48.246261, lng: 18.806726 },
    { lat: 48.245574, lng: 18.806099 },
    { lat: 48.24552, lng: 18.805958 },
    { lat: 48.245366, lng: 18.805862 },
    { lat: 48.245284, lng: 18.805666 },
    { lat: 48.24505, lng: 18.805752 },
    { lat: 48.244726, lng: 18.806223 },
    { lat: 48.244487, lng: 18.806849 },
    { lat: 48.244195, lng: 18.807117 },
    { lat: 48.244015, lng: 18.807849 },
    { lat: 48.243779, lng: 18.80778 },
    { lat: 48.243675, lng: 18.807827 },
    { lat: 48.243531, lng: 18.808027 },
    { lat: 48.243326, lng: 18.808066 },
    { lat: 48.243176, lng: 18.807982 },
    { lat: 48.243008, lng: 18.808012 },
    { lat: 48.242586, lng: 18.808254 },
    { lat: 48.242549, lng: 18.808275 },
    { lat: 48.242002, lng: 18.808588 },
    { lat: 48.241944, lng: 18.808621 },
    { lat: 48.241811, lng: 18.808653 },
    { lat: 48.241487, lng: 18.80876 },
    { lat: 48.241243, lng: 18.809133 },
    { lat: 48.240902, lng: 18.809236 },
    { lat: 48.240775, lng: 18.809275 },
    { lat: 48.240546, lng: 18.809729 },
    { lat: 48.240071, lng: 18.810388 },
    { lat: 48.240239, lng: 18.811159 },
    { lat: 48.24013, lng: 18.811679 },
    { lat: 48.23986, lng: 18.812177 },
    { lat: 48.239778, lng: 18.812423 },
    { lat: 48.2395, lng: 18.812825 },
    { lat: 48.239294, lng: 18.813232 },
    { lat: 48.239117, lng: 18.813302 },
    { lat: 48.239073, lng: 18.813552 },
    { lat: 48.239089, lng: 18.813989 },
    { lat: 48.23895, lng: 18.81423 },
    { lat: 48.238833, lng: 18.814289 },
    { lat: 48.238792, lng: 18.814583 },
    { lat: 48.239074, lng: 18.814797 },
    { lat: 48.238979, lng: 18.815108 },
    { lat: 48.238968, lng: 18.815286 },
    { lat: 48.238769, lng: 18.815468 },
    { lat: 48.238881, lng: 18.816254 },
    { lat: 48.238867, lng: 18.816562 },
    { lat: 48.238792, lng: 18.816877 },
    { lat: 48.238777, lng: 18.817124 },
    { lat: 48.238626, lng: 18.817267 },
    { lat: 48.238391, lng: 18.817189 },
    { lat: 48.238268, lng: 18.817348 },
    { lat: 48.238201, lng: 18.817789 },
    { lat: 48.237963, lng: 18.817926 },
    { lat: 48.237825, lng: 18.818156 },
    { lat: 48.237606, lng: 18.818383 },
    { lat: 48.237447, lng: 18.81857 },
    { lat: 48.237344, lng: 18.818812 },
    { lat: 48.237201, lng: 18.818936 },
    { lat: 48.237065, lng: 18.818971 },
    { lat: 48.236823, lng: 18.819215 },
    { lat: 48.236709, lng: 18.819444 },
    { lat: 48.23671, lng: 18.819664 },
    { lat: 48.236648, lng: 18.819792 },
    { lat: 48.236473, lng: 18.819832 },
    { lat: 48.23636, lng: 18.819985 },
    { lat: 48.23621, lng: 18.820294 },
    { lat: 48.235885, lng: 18.820431 },
    { lat: 48.235724, lng: 18.820626 },
    { lat: 48.235666, lng: 18.820813 },
    { lat: 48.235564, lng: 18.820921 },
    { lat: 48.235439, lng: 18.820913 },
    { lat: 48.235291, lng: 18.820602 },
    { lat: 48.235183, lng: 18.820624 },
    { lat: 48.234986, lng: 18.820957 },
    { lat: 48.2347, lng: 18.821157 },
    { lat: 48.234569, lng: 18.820867 },
    { lat: 48.234454, lng: 18.820853 },
    { lat: 48.234353, lng: 18.821092 },
    { lat: 48.234227, lng: 18.821077 },
    { lat: 48.233833, lng: 18.820823 },
    { lat: 48.233676, lng: 18.820804 },
    { lat: 48.233583, lng: 18.821296 },
    { lat: 48.233469, lng: 18.821406 },
    { lat: 48.233155, lng: 18.821492 },
    { lat: 48.233044, lng: 18.821243 },
    { lat: 48.23304, lng: 18.820796 },
    { lat: 48.233122, lng: 18.820576 },
    { lat: 48.232945, lng: 18.820115 },
    { lat: 48.232957, lng: 18.819963 },
    { lat: 48.232815, lng: 18.819618 },
    { lat: 48.232755, lng: 18.81912 },
    { lat: 48.232798, lng: 18.818916 },
    { lat: 48.232745, lng: 18.818597 },
    { lat: 48.232729, lng: 18.818267 },
    { lat: 48.232773, lng: 18.818132 },
    { lat: 48.232733, lng: 18.817773 },
    { lat: 48.232836, lng: 18.817356 },
    { lat: 48.232871, lng: 18.816907 },
    { lat: 48.232881, lng: 18.816455 },
    { lat: 48.232847, lng: 18.816324 },
    { lat: 48.23292, lng: 18.816203 },
    { lat: 48.232867, lng: 18.81591 },
    { lat: 48.232967, lng: 18.81567 },
    { lat: 48.233004, lng: 18.81536 },
    { lat: 48.232951, lng: 18.815239 },
    { lat: 48.232947, lng: 18.815138 },
    { lat: 48.23293, lng: 18.814763 },
    { lat: 48.233016, lng: 18.814623 },
    { lat: 48.233056, lng: 18.814152 },
    { lat: 48.233097, lng: 18.813751 },
    { lat: 48.233063, lng: 18.813683 },
    { lat: 48.232624, lng: 18.812801 },
    { lat: 48.232455, lng: 18.812584 },
    { lat: 48.232292, lng: 18.812375 },
    { lat: 48.232221, lng: 18.812199 },
    { lat: 48.232121, lng: 18.81216 },
    { lat: 48.231616, lng: 18.811495 },
    { lat: 48.231818, lng: 18.810904 },
    { lat: 48.231667, lng: 18.810728 },
    { lat: 48.231635, lng: 18.810671 },
    { lat: 48.229912, lng: 18.808755 },
    { lat: 48.22924, lng: 18.810188 },
    { lat: 48.228249, lng: 18.812049 },
    { lat: 48.228206, lng: 18.812083 },
    { lat: 48.227451, lng: 18.811356 },
    { lat: 48.227013, lng: 18.812173 },
    { lat: 48.22587, lng: 18.813714 },
    { lat: 48.224756, lng: 18.81464 },
    { lat: 48.224733, lng: 18.814569 },
    { lat: 48.224722, lng: 18.814535 },
    { lat: 48.224149, lng: 18.814905 },
    { lat: 48.223907, lng: 18.815007 },
    { lat: 48.223308, lng: 18.815317 },
    { lat: 48.223124, lng: 18.815397 },
    { lat: 48.22266, lng: 18.815634 },
    { lat: 48.222459, lng: 18.815652 },
    { lat: 48.222093, lng: 18.8158 },
    { lat: 48.221724, lng: 18.816108 },
    { lat: 48.221527, lng: 18.816133 },
    { lat: 48.220499, lng: 18.816231 },
    { lat: 48.220411, lng: 18.816522 },
    { lat: 48.220183, lng: 18.816278 },
    { lat: 48.220108, lng: 18.816319 },
    { lat: 48.220034, lng: 18.816424 },
    { lat: 48.219897, lng: 18.81653 },
    { lat: 48.219738, lng: 18.81677 },
    { lat: 48.219707, lng: 18.816957 },
    { lat: 48.219597, lng: 18.817072 },
    { lat: 48.219553, lng: 18.817328 },
    { lat: 48.219458, lng: 18.817527 },
    { lat: 48.21945, lng: 18.817764 },
    { lat: 48.219342, lng: 18.818002 },
    { lat: 48.219334, lng: 18.818217 },
    { lat: 48.218942, lng: 18.818744 },
    { lat: 48.218788, lng: 18.818783 },
    { lat: 48.218624, lng: 18.819166 },
    { lat: 48.218323, lng: 18.81932 },
    { lat: 48.217718, lng: 18.819609 },
    { lat: 48.217399, lng: 18.819723 },
    { lat: 48.2171, lng: 18.819803 },
    { lat: 48.216674, lng: 18.820138 },
    { lat: 48.216368, lng: 18.820232 },
    { lat: 48.216291, lng: 18.820497 },
    { lat: 48.215987, lng: 18.82045 },
    { lat: 48.215936, lng: 18.820499 },
    { lat: 48.215935, lng: 18.820724 },
    { lat: 48.215709, lng: 18.820944 },
    { lat: 48.21562, lng: 18.821154 },
    { lat: 48.215472, lng: 18.821134 },
    { lat: 48.215359, lng: 18.821381 },
    { lat: 48.2153, lng: 18.821423 },
    { lat: 48.214968, lng: 18.821658 },
    { lat: 48.214792, lng: 18.821839 },
    { lat: 48.2146, lng: 18.821773 },
    { lat: 48.214477, lng: 18.821868 },
    { lat: 48.214556, lng: 18.822315 },
    { lat: 48.2145, lng: 18.822549 },
    { lat: 48.214021, lng: 18.822519 },
    { lat: 48.213797, lng: 18.822611 },
    { lat: 48.213874, lng: 18.823204 },
    { lat: 48.213562, lng: 18.823629 },
    { lat: 48.213234, lng: 18.823495 },
    { lat: 48.212973, lng: 18.823472 },
    { lat: 48.212867, lng: 18.823591 },
    { lat: 48.212733, lng: 18.824075 },
    { lat: 48.212508, lng: 18.824408 },
    { lat: 48.212299, lng: 18.824143 },
    { lat: 48.21225, lng: 18.824185 },
    { lat: 48.212232, lng: 18.824309 },
    { lat: 48.211842, lng: 18.824916 },
    { lat: 48.211841, lng: 18.82512 },
    { lat: 48.211931, lng: 18.825323 },
    { lat: 48.211761, lng: 18.825436 },
    { lat: 48.211611, lng: 18.825444 },
    { lat: 48.211446, lng: 18.825571 },
    { lat: 48.211438, lng: 18.82569 },
    { lat: 48.211169, lng: 18.826074 },
    { lat: 48.210951, lng: 18.826589 },
    { lat: 48.21077, lng: 18.826642 },
    { lat: 48.210655, lng: 18.826733 },
    { lat: 48.210581, lng: 18.826941 },
    { lat: 48.210539, lng: 18.827211 },
    { lat: 48.20984, lng: 18.827494 },
    { lat: 48.209732, lng: 18.827692 },
    { lat: 48.209694, lng: 18.828057 },
    { lat: 48.209554, lng: 18.828239 },
    { lat: 48.209358, lng: 18.82826 },
    { lat: 48.209019, lng: 18.828125 },
    { lat: 48.208933, lng: 18.828549 },
    { lat: 48.208665, lng: 18.828884 },
    { lat: 48.20849, lng: 18.828573 },
    { lat: 48.208217, lng: 18.828564 },
    { lat: 48.208209, lng: 18.82864 },
    { lat: 48.208394, lng: 18.829155 },
    { lat: 48.208277, lng: 18.829255 },
    { lat: 48.207757, lng: 18.829316 },
    { lat: 48.207857, lng: 18.829626 },
    { lat: 48.207854, lng: 18.829755 },
    { lat: 48.207406, lng: 18.830016 },
    { lat: 48.207183, lng: 18.830238 },
    { lat: 48.2071498, lng: 18.830333 },
    { lat: 48.2070563, lng: 18.8306008 },
    { lat: 48.206851, lng: 18.831189 },
    { lat: 48.206479, lng: 18.831385 },
    { lat: 48.206299, lng: 18.831701 },
    { lat: 48.206271, lng: 18.831632 },
    { lat: 48.205787, lng: 18.830488 },
    { lat: 48.204732, lng: 18.829064 },
    { lat: 48.204436, lng: 18.828665 },
    { lat: 48.204367, lng: 18.828571 },
    { lat: 48.204366, lng: 18.828569 },
    { lat: 48.20438, lng: 18.828554 },
    { lat: 48.204336, lng: 18.828429 },
    { lat: 48.204162, lng: 18.828185 },
    { lat: 48.203703, lng: 18.827418 },
    { lat: 48.203481, lng: 18.827056 },
    { lat: 48.203067, lng: 18.826265 },
    { lat: 48.202747, lng: 18.825757 },
    { lat: 48.2024, lng: 18.825208 },
    { lat: 48.202206, lng: 18.824951 },
    { lat: 48.201839, lng: 18.824463 },
    { lat: 48.201529, lng: 18.824127 },
    { lat: 48.2000897, lng: 18.8271584 },
    { lat: 48.2005842, lng: 18.8280055 },
    { lat: 48.1998886, lng: 18.8293626 },
    { lat: 48.1998957, lng: 18.8294562 },
    { lat: 48.1963093, lng: 18.8357206 },
    { lat: 48.196882, lng: 18.8386421 },
    { lat: 48.1973013, lng: 18.8411306 },
    { lat: 48.1972151, lng: 18.8415278 },
    { lat: 48.1969627, lng: 18.8436183 },
    { lat: 48.1968073, lng: 18.8442692 },
    { lat: 48.1968254, lng: 18.8443587 },
    { lat: 48.1974217, lng: 18.8457315 },
    { lat: 48.197544, lng: 18.8458465 },
    { lat: 48.1975958, lng: 18.8465566 },
    { lat: 48.1981398, lng: 18.8475751 },
    { lat: 48.198771, lng: 18.8480854 },
    { lat: 48.1994558, lng: 18.8490828 },
    { lat: 48.1999063, lng: 18.849352 },
    { lat: 48.2002813, lng: 18.8494276 },
    { lat: 48.2020871, lng: 18.8494765 },
    { lat: 48.2022186, lng: 18.8496234 },
    { lat: 48.2046907, lng: 18.8499509 },
    { lat: 48.2064089, lng: 18.8502633 },
    { lat: 48.206432, lng: 18.851395 },
    { lat: 48.2087019, lng: 18.8516982 },
    { lat: 48.209016, lng: 18.8518379 },
    { lat: 48.209455, lng: 18.8518868 },
    { lat: 48.2099047, lng: 18.8517528 },
    { lat: 48.2106519, lng: 18.8513143 },
    { lat: 48.2122581, lng: 18.8512565 },
    { lat: 48.2123364, lng: 18.8507262 },
    { lat: 48.2127059, lng: 18.8506119 },
    { lat: 48.2128934, lng: 18.8508339 },
    { lat: 48.2130394, lng: 18.8509051 },
    { lat: 48.2132554, lng: 18.8509611 },
    { lat: 48.2139558, lng: 18.8509657 },
    { lat: 48.2141688, lng: 18.8510712 },
    { lat: 48.2144936, lng: 18.8514157 },
    { lat: 48.2148275, lng: 18.8514155 },
    { lat: 48.2154638, lng: 18.851551 },
    { lat: 48.2159274, lng: 18.8517491 },
    { lat: 48.2164264, lng: 18.8513728 },
    { lat: 48.2177634, lng: 18.8506087 },
    { lat: 48.2182399, lng: 18.8499747 },
    { lat: 48.2182964, lng: 18.850065 },
    { lat: 48.2183276, lng: 18.8499489 },
    { lat: 48.2184431, lng: 18.849871 },
  ],
  Uňatín: [
    { lat: 48.2844716, lng: 19.0311812 },
    { lat: 48.2828241, lng: 19.0305729 },
    { lat: 48.2817539, lng: 19.0298564 },
    { lat: 48.2805013, lng: 19.0298811 },
    { lat: 48.2799965, lng: 19.029598 },
    { lat: 48.2787274, lng: 19.0287797 },
    { lat: 48.2784478, lng: 19.0285361 },
    { lat: 48.2780267, lng: 19.0279689 },
    { lat: 48.2765723, lng: 19.0272619 },
    { lat: 48.2751346, lng: 19.02639 },
    { lat: 48.2740647, lng: 19.0256146 },
    { lat: 48.2735392, lng: 19.0254915 },
    { lat: 48.2720637, lng: 19.0242615 },
    { lat: 48.2692722, lng: 19.0221343 },
    { lat: 48.2687702, lng: 19.0216182 },
    { lat: 48.2681835, lng: 19.0206531 },
    { lat: 48.2671168, lng: 19.01872 },
    { lat: 48.2668067, lng: 19.0179861 },
    { lat: 48.2659928, lng: 19.0163935 },
    { lat: 48.2656358, lng: 19.0157657 },
    { lat: 48.2653899, lng: 19.015757 },
    { lat: 48.2646035, lng: 19.0149215 },
    { lat: 48.263513, lng: 19.0132177 },
    { lat: 48.2628606, lng: 19.0119594 },
    { lat: 48.2623713, lng: 19.0109841 },
    { lat: 48.2622396, lng: 19.0106976 },
    { lat: 48.2610364, lng: 19.0076352 },
    { lat: 48.2605286, lng: 19.0070085 },
    { lat: 48.2597441, lng: 19.0051545 },
    { lat: 48.2593842, lng: 19.0041518 },
    { lat: 48.2588696, lng: 19.0037895 },
    { lat: 48.2584351, lng: 19.0036042 },
    { lat: 48.2566866, lng: 19.0025664 },
    { lat: 48.2560281, lng: 19.0020805 },
    { lat: 48.2556132, lng: 19.0020137 },
    { lat: 48.2555451, lng: 19.0017855 },
    { lat: 48.2550137, lng: 19.0018462 },
    { lat: 48.2551294, lng: 19.0021224 },
    { lat: 48.2555422, lng: 19.0031674 },
    { lat: 48.2555473, lng: 19.0034654 },
    { lat: 48.2556307, lng: 19.0035072 },
    { lat: 48.2557464, lng: 19.004388 },
    { lat: 48.2555046, lng: 19.0046633 },
    { lat: 48.2550909, lng: 19.0060607 },
    { lat: 48.2551656, lng: 19.0062014 },
    { lat: 48.2550056, lng: 19.0065809 },
    { lat: 48.2546716, lng: 19.0066835 },
    { lat: 48.254447, lng: 19.0065701 },
    { lat: 48.2542437, lng: 19.0073384 },
    { lat: 48.2536113, lng: 19.0079621 },
    { lat: 48.2533099, lng: 19.0078427 },
    { lat: 48.2517822, lng: 19.0068252 },
    { lat: 48.2511941, lng: 19.0065241 },
    { lat: 48.2505643, lng: 19.0063126 },
    { lat: 48.2502559, lng: 19.0057673 },
    { lat: 48.2498956, lng: 19.0056655 },
    { lat: 48.2497442, lng: 19.0049987 },
    { lat: 48.2491081, lng: 19.0048587 },
    { lat: 48.2488436, lng: 19.0047343 },
    { lat: 48.2486576, lng: 19.0045585 },
    { lat: 48.2474459, lng: 19.004612 },
    { lat: 48.2469835, lng: 19.0047168 },
    { lat: 48.245816, lng: 19.0052856 },
    { lat: 48.2455113, lng: 19.005636 },
    { lat: 48.245129, lng: 19.0064048 },
    { lat: 48.2441604, lng: 19.0073116 },
    { lat: 48.2440784, lng: 19.0070511 },
    { lat: 48.2437746, lng: 19.0074409 },
    { lat: 48.2433022, lng: 19.0077728 },
    { lat: 48.2426804, lng: 19.0080953 },
    { lat: 48.2419628, lng: 19.0078433 },
    { lat: 48.2404199, lng: 19.007879 },
    { lat: 48.2394462, lng: 19.0075767 },
    { lat: 48.2393552, lng: 19.0074502 },
    { lat: 48.2391455, lng: 19.0073408 },
    { lat: 48.2390624, lng: 19.0073651 },
    { lat: 48.2385608, lng: 19.0079037 },
    { lat: 48.2386139, lng: 19.008101 },
    { lat: 48.2385617, lng: 19.0086071 },
    { lat: 48.2389421, lng: 19.0092353 },
    { lat: 48.2389937, lng: 19.0094731 },
    { lat: 48.2384753, lng: 19.0100536 },
    { lat: 48.2390031, lng: 19.0106735 },
    { lat: 48.2392475, lng: 19.0107825 },
    { lat: 48.239525, lng: 19.0110508 },
    { lat: 48.239571, lng: 19.0109557 },
    { lat: 48.2398748, lng: 19.0108995 },
    { lat: 48.2401307, lng: 19.0109237 },
    { lat: 48.2404495, lng: 19.0110493 },
    { lat: 48.2407932, lng: 19.0116015 },
    { lat: 48.2410811, lng: 19.0110968 },
    { lat: 48.2414732, lng: 19.0111607 },
    { lat: 48.2419316, lng: 19.0118586 },
    { lat: 48.2421869, lng: 19.0114698 },
    { lat: 48.242379, lng: 19.0112996 },
    { lat: 48.2426684, lng: 19.0116465 },
    { lat: 48.2432855, lng: 19.0120314 },
    { lat: 48.2436404, lng: 19.011629 },
    { lat: 48.2438597, lng: 19.0115923 },
    { lat: 48.2442134, lng: 19.0116521 },
    { lat: 48.2444309, lng: 19.0113857 },
    { lat: 48.2448314, lng: 19.0111921 },
    { lat: 48.245043, lng: 19.0109858 },
    { lat: 48.2449905, lng: 19.0101301 },
    { lat: 48.2450991, lng: 19.0100261 },
    { lat: 48.2452867, lng: 19.0102583 },
    { lat: 48.245619, lng: 19.0102523 },
    { lat: 48.2456094, lng: 19.0103517 },
    { lat: 48.2457442, lng: 19.0107845 },
    { lat: 48.245925, lng: 19.0109673 },
    { lat: 48.2462337, lng: 19.0110771 },
    { lat: 48.2463349, lng: 19.0108041 },
    { lat: 48.2463118, lng: 19.0106384 },
    { lat: 48.2464493, lng: 19.0104805 },
    { lat: 48.2466893, lng: 19.0105437 },
    { lat: 48.2471535, lng: 19.0108908 },
    { lat: 48.2472106, lng: 19.0107972 },
    { lat: 48.2479353, lng: 19.0120103 },
    { lat: 48.2482251, lng: 19.012319 },
    { lat: 48.2484934, lng: 19.0128045 },
    { lat: 48.248707, lng: 19.0129963 },
    { lat: 48.2487819, lng: 19.0123181 },
    { lat: 48.2487249, lng: 19.0121721 },
    { lat: 48.2486504, lng: 19.0116376 },
    { lat: 48.2487261, lng: 19.011133 },
    { lat: 48.2489361, lng: 19.0110019 },
    { lat: 48.2493317, lng: 19.011469 },
    { lat: 48.2498767, lng: 19.0119647 },
    { lat: 48.2496391, lng: 19.0130336 },
    { lat: 48.2505628, lng: 19.013547 },
    { lat: 48.2508677, lng: 19.0138009 },
    { lat: 48.2509454, lng: 19.0137305 },
    { lat: 48.2515247, lng: 19.0147706 },
    { lat: 48.2516549, lng: 19.0152681 },
    { lat: 48.2515721, lng: 19.0157038 },
    { lat: 48.2516256, lng: 19.0159092 },
    { lat: 48.2517674, lng: 19.0160823 },
    { lat: 48.2520023, lng: 19.0161154 },
    { lat: 48.2525224, lng: 19.0157642 },
    { lat: 48.2527321, lng: 19.0156982 },
    { lat: 48.2529183, lng: 19.0159904 },
    { lat: 48.2529971, lng: 19.0162768 },
    { lat: 48.2528527, lng: 19.0165632 },
    { lat: 48.2527917, lng: 19.0170276 },
    { lat: 48.2527002, lng: 19.0170451 },
    { lat: 48.2527447, lng: 19.0173676 },
    { lat: 48.2529001, lng: 19.0177373 },
    { lat: 48.2529693, lng: 19.0183471 },
    { lat: 48.2533715, lng: 19.0185843 },
    { lat: 48.2538956, lng: 19.0185352 },
    { lat: 48.2541458, lng: 19.0186896 },
    { lat: 48.2538978, lng: 19.0192893 },
    { lat: 48.253557, lng: 19.0195308 },
    { lat: 48.2534518, lng: 19.0193889 },
    { lat: 48.2533062, lng: 19.0196528 },
    { lat: 48.2532454, lng: 19.0198737 },
    { lat: 48.2532612, lng: 19.0201433 },
    { lat: 48.2534338, lng: 19.0207712 },
    { lat: 48.253491, lng: 19.0209793 },
    { lat: 48.253927, lng: 19.0220193 },
    { lat: 48.2544563, lng: 19.0223832 },
    { lat: 48.2552543, lng: 19.0226468 },
    { lat: 48.2557715, lng: 19.0226378 },
    { lat: 48.2562066, lng: 19.0232127 },
    { lat: 48.2567227, lng: 19.0232275 },
    { lat: 48.256985, lng: 19.0229136 },
    { lat: 48.2574184, lng: 19.0232555 },
    { lat: 48.2574899, lng: 19.0234628 },
    { lat: 48.2576494, lng: 19.0233838 },
    { lat: 48.2577989, lng: 19.0234659 },
    { lat: 48.2578871, lng: 19.023068 },
    { lat: 48.2581355, lng: 19.0232085 },
    { lat: 48.2584125, lng: 19.0236009 },
    { lat: 48.2584152, lng: 19.0237194 },
    { lat: 48.2589564, lng: 19.0238121 },
    { lat: 48.25968, lng: 19.0238284 },
    { lat: 48.2599794, lng: 19.0239093 },
    { lat: 48.2605329, lng: 19.0251268 },
    { lat: 48.2611387, lng: 19.0249786 },
    { lat: 48.2626008, lng: 19.0251536 },
    { lat: 48.2634622, lng: 19.0256192 },
    { lat: 48.2637083, lng: 19.0260027 },
    { lat: 48.263885, lng: 19.0265753 },
    { lat: 48.264022, lng: 19.0272508 },
    { lat: 48.2645613, lng: 19.0279707 },
    { lat: 48.2650142, lng: 19.0284326 },
    { lat: 48.2652501, lng: 19.0288106 },
    { lat: 48.2654354, lng: 19.0293996 },
    { lat: 48.2654955, lng: 19.0298963 },
    { lat: 48.2654444, lng: 19.0303795 },
    { lat: 48.2654362, lng: 19.0303935 },
    { lat: 48.2654667, lng: 19.0303939 },
    { lat: 48.2655533, lng: 19.030481 },
    { lat: 48.26569, lng: 19.03253 },
    { lat: 48.2658156, lng: 19.0328961 },
    { lat: 48.2658775, lng: 19.0332928 },
    { lat: 48.2658725, lng: 19.0340432 },
    { lat: 48.2660607, lng: 19.0366196 },
    { lat: 48.2660653, lng: 19.0374255 },
    { lat: 48.266188, lng: 19.0379112 },
    { lat: 48.2664363, lng: 19.0383597 },
    { lat: 48.2664652, lng: 19.038333 },
    { lat: 48.2668174, lng: 19.038585 },
    { lat: 48.2672902, lng: 19.0387245 },
    { lat: 48.2675728, lng: 19.0389635 },
    { lat: 48.2675817, lng: 19.0391401 },
    { lat: 48.2682609, lng: 19.038995 },
    { lat: 48.269273, lng: 19.0386521 },
    { lat: 48.2694159, lng: 19.0384243 },
    { lat: 48.2704813, lng: 19.0380902 },
    { lat: 48.2709826, lng: 19.0377006 },
    { lat: 48.2710287, lng: 19.03781 },
    { lat: 48.2708433, lng: 19.0380671 },
    { lat: 48.2709742, lng: 19.03818 },
    { lat: 48.2709257, lng: 19.0397597 },
    { lat: 48.2709934, lng: 19.039988 },
    { lat: 48.2711592, lng: 19.039719 },
    { lat: 48.2714132, lng: 19.0406864 },
    { lat: 48.2722369, lng: 19.0432164 },
    { lat: 48.2723372, lng: 19.0442409 },
    { lat: 48.272481, lng: 19.0450113 },
    { lat: 48.2723808, lng: 19.0450563 },
    { lat: 48.2723326, lng: 19.0454213 },
    { lat: 48.2726895, lng: 19.0461017 },
    { lat: 48.2732955, lng: 19.0464941 },
    { lat: 48.2739402, lng: 19.0474466 },
    { lat: 48.2742675, lng: 19.0489254 },
    { lat: 48.2742277, lng: 19.0499254 },
    { lat: 48.2742929, lng: 19.0511688 },
    { lat: 48.2743299, lng: 19.0514784 },
    { lat: 48.2744898, lng: 19.0518536 },
    { lat: 48.2752167, lng: 19.052719 },
    { lat: 48.2754644, lng: 19.0540738 },
    { lat: 48.2756199, lng: 19.054341 },
    { lat: 48.2759468, lng: 19.0544512 },
    { lat: 48.2762476, lng: 19.0544242 },
    { lat: 48.2765516, lng: 19.0547968 },
    { lat: 48.276549, lng: 19.0551245 },
    { lat: 48.2764987, lng: 19.055247 },
    { lat: 48.2764931, lng: 19.0556048 },
    { lat: 48.2765334, lng: 19.0557991 },
    { lat: 48.2768374, lng: 19.0560135 },
    { lat: 48.2770768, lng: 19.0560671 },
    { lat: 48.2772613, lng: 19.056192 },
    { lat: 48.2774923, lng: 19.0565244 },
    { lat: 48.277813, lng: 19.0572759 },
    { lat: 48.278061, lng: 19.0574935 },
    { lat: 48.2785708, lng: 19.05748 },
    { lat: 48.2786872, lng: 19.0576183 },
    { lat: 48.2788514, lng: 19.0591727 },
    { lat: 48.2790617, lng: 19.0601243 },
    { lat: 48.2794377, lng: 19.0603391 },
    { lat: 48.2796357, lng: 19.0607984 },
    { lat: 48.2797095, lng: 19.0610994 },
    { lat: 48.2799431, lng: 19.0615947 },
    { lat: 48.2805279, lng: 19.0622807 },
    { lat: 48.2807881, lng: 19.061852 },
    { lat: 48.2809839, lng: 19.0617576 },
    { lat: 48.2811826, lng: 19.0620286 },
    { lat: 48.2816625, lng: 19.0629399 },
    { lat: 48.2818867, lng: 19.0630005 },
    { lat: 48.2822859, lng: 19.062737 },
    { lat: 48.2824704, lng: 19.0626853 },
    { lat: 48.2826237, lng: 19.0627918 },
    { lat: 48.2828477, lng: 19.0630323 },
    { lat: 48.2830265, lng: 19.0637622 },
    { lat: 48.2831384, lng: 19.0639484 },
    { lat: 48.2833071, lng: 19.0639637 },
    { lat: 48.2834544, lng: 19.0637962 },
    { lat: 48.2837467, lng: 19.0638025 },
    { lat: 48.2838491, lng: 19.0639126 },
    { lat: 48.2839156, lng: 19.0643791 },
    { lat: 48.2847716, lng: 19.0650373 },
    { lat: 48.284833, lng: 19.0656272 },
    { lat: 48.2849373, lng: 19.0660179 },
    { lat: 48.2852686, lng: 19.0660162 },
    { lat: 48.2853851, lng: 19.0660678 },
    { lat: 48.2855522, lng: 19.0662744 },
    { lat: 48.2855825, lng: 19.0668306 },
    { lat: 48.2856611, lng: 19.0670731 },
    { lat: 48.2858435, lng: 19.0672224 },
    { lat: 48.2859985, lng: 19.0676134 },
    { lat: 48.2861669, lng: 19.0678234 },
    { lat: 48.2864011, lng: 19.0679742 },
    { lat: 48.2865872, lng: 19.0681978 },
    { lat: 48.2871178, lng: 19.0692915 },
    { lat: 48.2870556, lng: 19.070586 },
    { lat: 48.2871069, lng: 19.0707976 },
    { lat: 48.2873221, lng: 19.0711553 },
    { lat: 48.2872743, lng: 19.0714814 },
    { lat: 48.2873278, lng: 19.0721639 },
    { lat: 48.2873269, lng: 19.0724794 },
    { lat: 48.2873599, lng: 19.0725532 },
    { lat: 48.2879881, lng: 19.0728493 },
    { lat: 48.2880471, lng: 19.0729478 },
    { lat: 48.2880479, lng: 19.0731003 },
    { lat: 48.2881083, lng: 19.0731579 },
    { lat: 48.2882526, lng: 19.0729756 },
    { lat: 48.2884317, lng: 19.0730166 },
    { lat: 48.2885282, lng: 19.0729636 },
    { lat: 48.2886147, lng: 19.0730012 },
    { lat: 48.2886663, lng: 19.0732237 },
    { lat: 48.2888449, lng: 19.0732521 },
    { lat: 48.2889798, lng: 19.0731997 },
    { lat: 48.2890588, lng: 19.0730895 },
    { lat: 48.2894351, lng: 19.073287 },
    { lat: 48.2898179, lng: 19.0731004 },
    { lat: 48.2900197, lng: 19.0731672 },
    { lat: 48.2902366, lng: 19.0731289 },
    { lat: 48.2903605, lng: 19.0732384 },
    { lat: 48.2906752, lng: 19.0732397 },
    { lat: 48.2907089, lng: 19.0732854 },
    { lat: 48.2906718, lng: 19.0734217 },
    { lat: 48.2906903, lng: 19.0735403 },
    { lat: 48.2910326, lng: 19.0734634 },
    { lat: 48.2912882, lng: 19.0737086 },
    { lat: 48.29141, lng: 19.0741993 },
    { lat: 48.2914767, lng: 19.0741819 },
    { lat: 48.2915418, lng: 19.0740209 },
    { lat: 48.2915615, lng: 19.0737351 },
    { lat: 48.2917509, lng: 19.0737154 },
    { lat: 48.2919967, lng: 19.0738296 },
    { lat: 48.2922158, lng: 19.0737465 },
    { lat: 48.2923523, lng: 19.07377 },
    { lat: 48.2924004, lng: 19.0739642 },
    { lat: 48.2925883, lng: 19.0743388 },
    { lat: 48.2927122, lng: 19.0744243 },
    { lat: 48.2929022, lng: 19.0743926 },
    { lat: 48.2932556, lng: 19.0746264 },
    { lat: 48.2933202, lng: 19.074498 },
    { lat: 48.2934971, lng: 19.0744741 },
    { lat: 48.2937194, lng: 19.0749234 },
    { lat: 48.2938362, lng: 19.0748738 },
    { lat: 48.2941217, lng: 19.0750777 },
    { lat: 48.2947467, lng: 19.075245 },
    { lat: 48.2957387, lng: 19.0753104 },
    { lat: 48.297102, lng: 19.075695 },
    { lat: 48.2974886, lng: 19.075674 },
    { lat: 48.2994525, lng: 19.0745011 },
    { lat: 48.2997349, lng: 19.0747851 },
    { lat: 48.3018126, lng: 19.0736141 },
    { lat: 48.3024534, lng: 19.0736476 },
    { lat: 48.3028618, lng: 19.0725979 },
    { lat: 48.3027928, lng: 19.0725473 },
    { lat: 48.3039903, lng: 19.0690794 },
    { lat: 48.304244, lng: 19.0696148 },
    { lat: 48.3052556, lng: 19.0685896 },
    { lat: 48.3054174, lng: 19.0682901 },
    { lat: 48.3056896, lng: 19.0675621 },
    { lat: 48.3062353, lng: 19.0664634 },
    { lat: 48.306314, lng: 19.0661454 },
    { lat: 48.3066272, lng: 19.0656694 },
    { lat: 48.3066474, lng: 19.0654652 },
    { lat: 48.307172, lng: 19.0647128 },
    { lat: 48.3075503, lng: 19.0642769 },
    { lat: 48.3082469, lng: 19.0635574 },
    { lat: 48.308683, lng: 19.063575 },
    { lat: 48.3094385, lng: 19.0632656 },
    { lat: 48.3109458, lng: 19.0628142 },
    { lat: 48.3115064, lng: 19.0630029 },
    { lat: 48.3118203, lng: 19.0633006 },
    { lat: 48.3121417, lng: 19.0633035 },
    { lat: 48.3122971, lng: 19.0634089 },
    { lat: 48.3123816, lng: 19.0628029 },
    { lat: 48.312513, lng: 19.0625277 },
    { lat: 48.3126905, lng: 19.062379 },
    { lat: 48.3127504, lng: 19.062114 },
    { lat: 48.3128951, lng: 19.0618058 },
    { lat: 48.31315, lng: 19.0614312 },
    { lat: 48.3131375, lng: 19.0612715 },
    { lat: 48.3132966, lng: 19.0611369 },
    { lat: 48.3134505, lng: 19.0608811 },
    { lat: 48.3134509, lng: 19.0606884 },
    { lat: 48.3134952, lng: 19.0606303 },
    { lat: 48.3135872, lng: 19.0606411 },
    { lat: 48.3137589, lng: 19.0605363 },
    { lat: 48.3139831, lng: 19.0602644 },
    { lat: 48.3140374, lng: 19.0602678 },
    { lat: 48.3141422, lng: 19.060065 },
    { lat: 48.3142187, lng: 19.0600822 },
    { lat: 48.3143272, lng: 19.0599105 },
    { lat: 48.3145389, lng: 19.0600608 },
    { lat: 48.3146735, lng: 19.0600261 },
    { lat: 48.314888, lng: 19.0601212 },
    { lat: 48.3149475, lng: 19.0600733 },
    { lat: 48.315209, lng: 19.0601138 },
    { lat: 48.3153567, lng: 19.0600032 },
    { lat: 48.3155184, lng: 19.0600377 },
    { lat: 48.315559, lng: 19.0599888 },
    { lat: 48.3155974, lng: 19.059767 },
    { lat: 48.3154547, lng: 19.0592182 },
    { lat: 48.3153464, lng: 19.0579185 },
    { lat: 48.3149783, lng: 19.057192 },
    { lat: 48.3148063, lng: 19.0570684 },
    { lat: 48.3147312, lng: 19.0568969 },
    { lat: 48.3144572, lng: 19.0566071 },
    { lat: 48.3135044, lng: 19.0560017 },
    { lat: 48.3131797, lng: 19.0557346 },
    { lat: 48.3125232, lng: 19.0553929 },
    { lat: 48.313024, lng: 19.0538075 },
    { lat: 48.3128583, lng: 19.0536056 },
    { lat: 48.3128031, lng: 19.0533625 },
    { lat: 48.3127314, lng: 19.053281 },
    { lat: 48.3122786, lng: 19.0532693 },
    { lat: 48.3121701, lng: 19.0535062 },
    { lat: 48.3122282, lng: 19.0543025 },
    { lat: 48.3121828, lng: 19.0544093 },
    { lat: 48.3121191, lng: 19.0544867 },
    { lat: 48.3119715, lng: 19.054309 },
    { lat: 48.3118606, lng: 19.0542801 },
    { lat: 48.3117185, lng: 19.0543656 },
    { lat: 48.3115873, lng: 19.0545849 },
    { lat: 48.3114538, lng: 19.0546652 },
    { lat: 48.3113591, lng: 19.0545767 },
    { lat: 48.3112685, lng: 19.0545877 },
    { lat: 48.3112703, lng: 19.0538423 },
    { lat: 48.3111719, lng: 19.0536335 },
    { lat: 48.3109499, lng: 19.0533902 },
    { lat: 48.310843, lng: 19.053084 },
    { lat: 48.310043, lng: 19.0526909 },
    { lat: 48.3096224, lng: 19.0523285 },
    { lat: 48.3094089, lng: 19.0526817 },
    { lat: 48.309114, lng: 19.052356 },
    { lat: 48.309478, lng: 19.0519942 },
    { lat: 48.3095146, lng: 19.0516911 },
    { lat: 48.3094616, lng: 19.0515342 },
    { lat: 48.3093295, lng: 19.0514858 },
    { lat: 48.3092629, lng: 19.0513744 },
    { lat: 48.3092577, lng: 19.0511356 },
    { lat: 48.3092116, lng: 19.0510044 },
    { lat: 48.3090888, lng: 19.0509624 },
    { lat: 48.3088719, lng: 19.0513993 },
    { lat: 48.3088786, lng: 19.0516432 },
    { lat: 48.3088189, lng: 19.0517796 },
    { lat: 48.3086983, lng: 19.0517791 },
    { lat: 48.3084969, lng: 19.0515009 },
    { lat: 48.3082899, lng: 19.0514192 },
    { lat: 48.3081872, lng: 19.0513034 },
    { lat: 48.3080398, lng: 19.0507528 },
    { lat: 48.3080234, lng: 19.050427 },
    { lat: 48.3081176, lng: 19.0501688 },
    { lat: 48.3080489, lng: 19.0499262 },
    { lat: 48.307917, lng: 19.0497471 },
    { lat: 48.3078526, lng: 19.0498139 },
    { lat: 48.307814, lng: 19.0500516 },
    { lat: 48.3075925, lng: 19.0500856 },
    { lat: 48.3074636, lng: 19.0499901 },
    { lat: 48.3075131, lng: 19.0495857 },
    { lat: 48.3074426, lng: 19.0493483 },
    { lat: 48.3074526, lng: 19.048789 },
    { lat: 48.3073685, lng: 19.0484844 },
    { lat: 48.3072172, lng: 19.0482109 },
    { lat: 48.3072498, lng: 19.0481225 },
    { lat: 48.3071752, lng: 19.0480077 },
    { lat: 48.3069312, lng: 19.0480885 },
    { lat: 48.3063811, lng: 19.0478893 },
    { lat: 48.3062673, lng: 19.0474003 },
    { lat: 48.3059817, lng: 19.0467872 },
    { lat: 48.3057974, lng: 19.046534 },
    { lat: 48.3055731, lng: 19.0465332 },
    { lat: 48.3054642, lng: 19.0466547 },
    { lat: 48.3051699, lng: 19.0473903 },
    { lat: 48.3049027, lng: 19.0475914 },
    { lat: 48.304673, lng: 19.047889 },
    { lat: 48.3044652, lng: 19.0480136 },
    { lat: 48.3044442, lng: 19.0479575 },
    { lat: 48.3042506, lng: 19.0482082 },
    { lat: 48.3034457, lng: 19.0497522 },
    { lat: 48.3034767, lng: 19.0500532 },
    { lat: 48.3033751, lng: 19.0503612 },
    { lat: 48.3031655, lng: 19.0506084 },
    { lat: 48.3025477, lng: 19.0509462 },
    { lat: 48.3021514, lng: 19.0509766 },
    { lat: 48.3017803, lng: 19.0513331 },
    { lat: 48.3017172, lng: 19.0515995 },
    { lat: 48.3016053, lng: 19.0516862 },
    { lat: 48.3010836, lng: 19.051779 },
    { lat: 48.3007139, lng: 19.0516952 },
    { lat: 48.3005787, lng: 19.0511135 },
    { lat: 48.3003722, lng: 19.0509787 },
    { lat: 48.2997713, lng: 19.0511577 },
    { lat: 48.2994644, lng: 19.0513727 },
    { lat: 48.2994039, lng: 19.0508261 },
    { lat: 48.299293, lng: 19.0505717 },
    { lat: 48.2991891, lng: 19.0499405 },
    { lat: 48.2991647, lng: 19.0495456 },
    { lat: 48.2992233, lng: 19.0491006 },
    { lat: 48.2992163, lng: 19.0486492 },
    { lat: 48.2991582, lng: 19.047789 },
    { lat: 48.2990299, lng: 19.0469232 },
    { lat: 48.2988179, lng: 19.0466545 },
    { lat: 48.2986093, lng: 19.0466558 },
    { lat: 48.2983795, lng: 19.0463257 },
    { lat: 48.2982085, lng: 19.0463965 },
    { lat: 48.2981631, lng: 19.046527 },
    { lat: 48.2979307, lng: 19.0466599 },
    { lat: 48.2978495, lng: 19.0469216 },
    { lat: 48.297601, lng: 19.0473396 },
    { lat: 48.2974503, lng: 19.047367 },
    { lat: 48.2972255, lng: 19.0475157 },
    { lat: 48.2971545, lng: 19.0476959 },
    { lat: 48.296998, lng: 19.0478804 },
    { lat: 48.2964139, lng: 19.0479201 },
    { lat: 48.2960533, lng: 19.0480361 },
    { lat: 48.2960081, lng: 19.0477867 },
    { lat: 48.2957235, lng: 19.0476674 },
    { lat: 48.2952538, lng: 19.0483032 },
    { lat: 48.2947807, lng: 19.0487775 },
    { lat: 48.294507, lng: 19.0489385 },
    { lat: 48.2944301, lng: 19.0489132 },
    { lat: 48.2942132, lng: 19.0490164 },
    { lat: 48.2939225, lng: 19.049219 },
    { lat: 48.2938023, lng: 19.049183 },
    { lat: 48.2933026, lng: 19.0486693 },
    { lat: 48.2931689, lng: 19.0482288 },
    { lat: 48.2931435, lng: 19.0478377 },
    { lat: 48.2930949, lng: 19.0477221 },
    { lat: 48.2926927, lng: 19.0474827 },
    { lat: 48.2920568, lng: 19.0476384 },
    { lat: 48.2916848, lng: 19.0469255 },
    { lat: 48.2916353, lng: 19.0467505 },
    { lat: 48.2914985, lng: 19.0466333 },
    { lat: 48.2913111, lng: 19.0463373 },
    { lat: 48.2910572, lng: 19.0455529 },
    { lat: 48.2910342, lng: 19.045318 },
    { lat: 48.2911024, lng: 19.0450174 },
    { lat: 48.2913773, lng: 19.0446141 },
    { lat: 48.2917318, lng: 19.0444652 },
    { lat: 48.2918999, lng: 19.0442666 },
    { lat: 48.2927501, lng: 19.0440465 },
    { lat: 48.2928391, lng: 19.0439553 },
    { lat: 48.2930264, lng: 19.0440251 },
    { lat: 48.2932551, lng: 19.0439134 },
    { lat: 48.2935479, lng: 19.0439698 },
    { lat: 48.2937688, lng: 19.044395 },
    { lat: 48.2939198, lng: 19.0444816 },
    { lat: 48.2941793, lng: 19.0444862 },
    { lat: 48.2943665, lng: 19.044194 },
    { lat: 48.2943817, lng: 19.0442752 },
    { lat: 48.2945921, lng: 19.044531 },
    { lat: 48.2948196, lng: 19.0445494 },
    { lat: 48.2949555, lng: 19.0444278 },
    { lat: 48.295382, lng: 19.0446828 },
    { lat: 48.2955397, lng: 19.0444971 },
    { lat: 48.295769, lng: 19.0435465 },
    { lat: 48.2960265, lng: 19.0427975 },
    { lat: 48.2959654, lng: 19.042417 },
    { lat: 48.2959808, lng: 19.0419405 },
    { lat: 48.2959132, lng: 19.0416281 },
    { lat: 48.2961259, lng: 19.0414187 },
    { lat: 48.2961571, lng: 19.0411436 },
    { lat: 48.2960906, lng: 19.0408325 },
    { lat: 48.2957734, lng: 19.040022 },
    { lat: 48.2957253, lng: 19.0396726 },
    { lat: 48.2958398, lng: 19.039597 },
    { lat: 48.2958374, lng: 19.0393713 },
    { lat: 48.2959631, lng: 19.0393167 },
    { lat: 48.2959684, lng: 19.0394432 },
    { lat: 48.2962371, lng: 19.0396503 },
    { lat: 48.2969468, lng: 19.0392357 },
    { lat: 48.29725, lng: 19.0389707 },
    { lat: 48.2978361, lng: 19.0386524 },
    { lat: 48.2980897, lng: 19.038449 },
    { lat: 48.2982064, lng: 19.0382385 },
    { lat: 48.298286, lng: 19.03792 },
    { lat: 48.2986002, lng: 19.0373542 },
    { lat: 48.2985919, lng: 19.0370127 },
    { lat: 48.2990627, lng: 19.0359996 },
    { lat: 48.2991628, lng: 19.035502 },
    { lat: 48.2990032, lng: 19.0347999 },
    { lat: 48.2988935, lng: 19.0346155 },
    { lat: 48.2990184, lng: 19.0344562 },
    { lat: 48.2991514, lng: 19.0338546 },
    { lat: 48.2993058, lng: 19.0338328 },
    { lat: 48.2993462, lng: 19.0336699 },
    { lat: 48.2991713, lng: 19.0334016 },
    { lat: 48.2992029, lng: 19.033312 },
    { lat: 48.2993423, lng: 19.0333039 },
    { lat: 48.2994676, lng: 19.0331505 },
    { lat: 48.2996436, lng: 19.0324658 },
    { lat: 48.2996306, lng: 19.0323193 },
    { lat: 48.2997692, lng: 19.0321183 },
    { lat: 48.2997527, lng: 19.0318344 },
    { lat: 48.2991475, lng: 19.0315517 },
    { lat: 48.298581, lng: 19.0310956 },
    { lat: 48.2976738, lng: 19.0306157 },
    { lat: 48.2956809, lng: 19.0293094 },
    { lat: 48.2953681, lng: 19.0291888 },
    { lat: 48.2949699, lng: 19.0292274 },
    { lat: 48.2928796, lng: 19.0300329 },
    { lat: 48.2920335, lng: 19.0306015 },
    { lat: 48.2907723, lng: 19.0317894 },
    { lat: 48.2887617, lng: 19.0323393 },
    { lat: 48.288025, lng: 19.0323753 },
    { lat: 48.2844786, lng: 19.0311825 },
    { lat: 48.2844716, lng: 19.0311812 },
  ],
  Sebechleby: [
    { lat: 48.2392322, lng: 18.95857 },
    { lat: 48.2394657, lng: 18.9588561 },
    { lat: 48.2410242, lng: 18.959717 },
    { lat: 48.2421657, lng: 18.9601859 },
    { lat: 48.2429456, lng: 18.9608167 },
    { lat: 48.2442367, lng: 18.961278 },
    { lat: 48.2447731, lng: 18.9614037 },
    { lat: 48.2485209, lng: 18.9642158 },
    { lat: 48.2493737, lng: 18.9655859 },
    { lat: 48.2513613, lng: 18.9681303 },
    { lat: 48.2522403, lng: 18.9693294 },
    { lat: 48.252776, lng: 18.9702578 },
    { lat: 48.2532468, lng: 18.9708193 },
    { lat: 48.2535992, lng: 18.9711616 },
    { lat: 48.2541213, lng: 18.9714278 },
    { lat: 48.2547362, lng: 18.9714768 },
    { lat: 48.2547525, lng: 18.9713801 },
    { lat: 48.2548874, lng: 18.9714353 },
    { lat: 48.2559057, lng: 18.9724537 },
    { lat: 48.2566208, lng: 18.9738636 },
    { lat: 48.2573169, lng: 18.9742472 },
    { lat: 48.2579663, lng: 18.9744242 },
    { lat: 48.258552, lng: 18.9744978 },
    { lat: 48.2592675, lng: 18.9747751 },
    { lat: 48.2600449, lng: 18.9747208 },
    { lat: 48.2602327, lng: 18.9745135 },
    { lat: 48.2605233, lng: 18.9743973 },
    { lat: 48.260918, lng: 18.9744649 },
    { lat: 48.2613853, lng: 18.974695 },
    { lat: 48.2620718, lng: 18.9743607 },
    { lat: 48.262426, lng: 18.974055 },
    { lat: 48.2625605, lng: 18.9738637 },
    { lat: 48.262978, lng: 18.9735811 },
    { lat: 48.2634965, lng: 18.9730495 },
    { lat: 48.2635514, lng: 18.9731748 },
    { lat: 48.2638986, lng: 18.9735274 },
    { lat: 48.2644241, lng: 18.9739249 },
    { lat: 48.2646765, lng: 18.9744936 },
    { lat: 48.2648279, lng: 18.9750283 },
    { lat: 48.2653512, lng: 18.9748755 },
    { lat: 48.2656987, lng: 18.975147 },
    { lat: 48.266313, lng: 18.9751638 },
    { lat: 48.2668166, lng: 18.9755731 },
    { lat: 48.2673255, lng: 18.9754064 },
    { lat: 48.2677848, lng: 18.9743759 },
    { lat: 48.2681234, lng: 18.9739006 },
    { lat: 48.268776, lng: 18.9737159 },
    { lat: 48.2689958, lng: 18.9733442 },
    { lat: 48.2691242, lng: 18.9733826 },
    { lat: 48.2694813, lng: 18.9736625 },
    { lat: 48.2706643, lng: 18.973998 },
    { lat: 48.2711322, lng: 18.973492 },
    { lat: 48.2713307, lng: 18.9733591 },
    { lat: 48.2718917, lng: 18.9732816 },
    { lat: 48.272194, lng: 18.9733541 },
    { lat: 48.2726462, lng: 18.9732239 },
    { lat: 48.2729069, lng: 18.9728977 },
    { lat: 48.2736717, lng: 18.9729893 },
    { lat: 48.2740054, lng: 18.9728805 },
    { lat: 48.2742865, lng: 18.9724224 },
    { lat: 48.2745913, lng: 18.9723849 },
    { lat: 48.2748437, lng: 18.9724932 },
    { lat: 48.2750131, lng: 18.9720582 },
    { lat: 48.275156, lng: 18.9722938 },
    { lat: 48.2755394, lng: 18.9719638 },
    { lat: 48.2758752, lng: 18.9719369 },
    { lat: 48.27594, lng: 18.9719945 },
    { lat: 48.2760614, lng: 18.9719194 },
    { lat: 48.2761187, lng: 18.9718062 },
    { lat: 48.2764047, lng: 18.9718226 },
    { lat: 48.2765, lng: 18.9717645 },
    { lat: 48.2764566, lng: 18.9715122 },
    { lat: 48.2766813, lng: 18.9716276 },
    { lat: 48.2768692, lng: 18.9714258 },
    { lat: 48.2769131, lng: 18.9712668 },
    { lat: 48.2770077, lng: 18.9713112 },
    { lat: 48.2773047, lng: 18.9710763 },
    { lat: 48.2777536, lng: 18.9709231 },
    { lat: 48.2778405, lng: 18.9706859 },
    { lat: 48.2781203, lng: 18.9703362 },
    { lat: 48.2781867, lng: 18.969895 },
    { lat: 48.2782819, lng: 18.9697276 },
    { lat: 48.2785179, lng: 18.9696219 },
    { lat: 48.2786126, lng: 18.9697133 },
    { lat: 48.2789506, lng: 18.9695307 },
    { lat: 48.27947, lng: 18.9688161 },
    { lat: 48.2795703, lng: 18.9687076 },
    { lat: 48.2796493, lng: 18.9687286 },
    { lat: 48.280815, lng: 18.9671397 },
    { lat: 48.2812816, lng: 18.9666102 },
    { lat: 48.2820236, lng: 18.9659928 },
    { lat: 48.2828242, lng: 18.9649169 },
    { lat: 48.2833292, lng: 18.9644298 },
    { lat: 48.2834596, lng: 18.9643783 },
    { lat: 48.2836326, lng: 18.9641672 },
    { lat: 48.2837116, lng: 18.9641691 },
    { lat: 48.2841644, lng: 18.9637648 },
    { lat: 48.2848907, lng: 18.9633962 },
    { lat: 48.2852684, lng: 18.9624489 },
    { lat: 48.2856572, lng: 18.9616546 },
    { lat: 48.2860388, lng: 18.961199 },
    { lat: 48.2863059, lng: 18.9607133 },
    { lat: 48.2864049, lng: 18.9606017 },
    { lat: 48.2866098, lng: 18.9607052 },
    { lat: 48.2868318, lng: 18.960441 },
    { lat: 48.2868959, lng: 18.9601288 },
    { lat: 48.2870715, lng: 18.9600318 },
    { lat: 48.2870919, lng: 18.959817 },
    { lat: 48.2872575, lng: 18.9592647 },
    { lat: 48.2875783, lng: 18.9590968 },
    { lat: 48.2875734, lng: 18.9589607 },
    { lat: 48.2878396, lng: 18.9589814 },
    { lat: 48.2878561, lng: 18.9587323 },
    { lat: 48.2880329, lng: 18.9584843 },
    { lat: 48.2882649, lng: 18.9583514 },
    { lat: 48.2882882, lng: 18.9581883 },
    { lat: 48.2883739, lng: 18.958057 },
    { lat: 48.2885096, lng: 18.9580874 },
    { lat: 48.2886766, lng: 18.9579577 },
    { lat: 48.2888888, lng: 18.9579155 },
    { lat: 48.2891448, lng: 18.9575458 },
    { lat: 48.2892459, lng: 18.9572318 },
    { lat: 48.2892879, lng: 18.9572128 },
    { lat: 48.2893177, lng: 18.9572901 },
    { lat: 48.2894221, lng: 18.9571934 },
    { lat: 48.2894422, lng: 18.9569911 },
    { lat: 48.2896804, lng: 18.9568631 },
    { lat: 48.289794, lng: 18.9566407 },
    { lat: 48.2899632, lng: 18.9565731 },
    { lat: 48.2900432, lng: 18.9560041 },
    { lat: 48.2900982, lng: 18.9559755 },
    { lat: 48.2902087, lng: 18.955711 },
    { lat: 48.2904307, lng: 18.9557332 },
    { lat: 48.2906948, lng: 18.9556036 },
    { lat: 48.2907971, lng: 18.9553118 },
    { lat: 48.2909951, lng: 18.9552394 },
    { lat: 48.2913039, lng: 18.9547286 },
    { lat: 48.2916647, lng: 18.9547964 },
    { lat: 48.2925153, lng: 18.9546272 },
    { lat: 48.2929267, lng: 18.954669 },
    { lat: 48.2930821, lng: 18.9548415 },
    { lat: 48.2931417, lng: 18.9555205 },
    { lat: 48.2935821, lng: 18.9552885 },
    { lat: 48.2945374, lng: 18.9546362 },
    { lat: 48.2951847, lng: 18.9541547 },
    { lat: 48.2955251, lng: 18.9538237 },
    { lat: 48.2975952, lng: 18.9527889 },
    { lat: 48.2987236, lng: 18.9520606 },
    { lat: 48.2998848, lng: 18.9518148 },
    { lat: 48.301621, lng: 18.9511704 },
    { lat: 48.302796, lng: 18.9505351 },
    { lat: 48.30378, lng: 18.9496973 },
    { lat: 48.3039737, lng: 18.9494284 },
    { lat: 48.3042109, lng: 18.9492176 },
    { lat: 48.3053444, lng: 18.9487404 },
    { lat: 48.3055891, lng: 18.9485397 },
    { lat: 48.3056981, lng: 18.9485554 },
    { lat: 48.3059917, lng: 18.9483476 },
    { lat: 48.3066148, lng: 18.9476814 },
    { lat: 48.3070058, lng: 18.947105 },
    { lat: 48.3072472, lng: 18.9468872 },
    { lat: 48.3077127, lng: 18.9467272 },
    { lat: 48.3081479, lng: 18.9463323 },
    { lat: 48.3086547, lng: 18.9457517 },
    { lat: 48.3089875, lng: 18.9455798 },
    { lat: 48.3092582, lng: 18.9453168 },
    { lat: 48.3099243, lng: 18.9443923 },
    { lat: 48.3103835, lng: 18.9439574 },
    { lat: 48.3105721, lng: 18.9437018 },
    { lat: 48.3108245, lng: 18.9435416 },
    { lat: 48.3115322, lng: 18.9428991 },
    { lat: 48.3123418, lng: 18.9424184 },
    { lat: 48.3142201, lng: 18.9409654 },
    { lat: 48.3154781, lng: 18.940805 },
    { lat: 48.3166934, lng: 18.940802 },
    { lat: 48.3170741, lng: 18.9409454 },
    { lat: 48.3174622, lng: 18.9406451 },
    { lat: 48.3178258, lng: 18.9401064 },
    { lat: 48.3185288, lng: 18.9396768 },
    { lat: 48.3188563, lng: 18.9396219 },
    { lat: 48.3189595, lng: 18.9396114 },
    { lat: 48.3191569, lng: 18.9397455 },
    { lat: 48.3194861, lng: 18.9401156 },
    { lat: 48.3197829, lng: 18.9401202 },
    { lat: 48.3199275, lng: 18.9404096 },
    { lat: 48.320094, lng: 18.9405136 },
    { lat: 48.3201765, lng: 18.9405179 },
    { lat: 48.3207182, lng: 18.9401764 },
    { lat: 48.3212086, lng: 18.939606 },
    { lat: 48.3217259, lng: 18.9388528 },
    { lat: 48.3220547, lng: 18.9381222 },
    { lat: 48.3223508, lng: 18.9369517 },
    { lat: 48.3225841, lng: 18.936315 },
    { lat: 48.3227331, lng: 18.9356802 },
    { lat: 48.3230533, lng: 18.9346944 },
    { lat: 48.3237383, lng: 18.9339603 },
    { lat: 48.3247558, lng: 18.9322623 },
    { lat: 48.3252368, lng: 18.9310505 },
    { lat: 48.3254982, lng: 18.9301713 },
    { lat: 48.3259225, lng: 18.9289961 },
    { lat: 48.3262888, lng: 18.9283329 },
    { lat: 48.3265291, lng: 18.9280531 },
    { lat: 48.3266954, lng: 18.9277359 },
    { lat: 48.3270232, lng: 18.9266781 },
    { lat: 48.3271813, lng: 18.9263118 },
    { lat: 48.327681, lng: 18.9254641 },
    { lat: 48.3279928, lng: 18.9251229 },
    { lat: 48.328032, lng: 18.925091 },
    { lat: 48.328096, lng: 18.924245 },
    { lat: 48.328098, lng: 18.923745 },
    { lat: 48.328395, lng: 18.922696 },
    { lat: 48.328427, lng: 18.922083 },
    { lat: 48.328312, lng: 18.921961 },
    { lat: 48.328259, lng: 18.921805 },
    { lat: 48.328071, lng: 18.921651 },
    { lat: 48.327888, lng: 18.921604 },
    { lat: 48.327695, lng: 18.921367 },
    { lat: 48.327557, lng: 18.921305 },
    { lat: 48.327419, lng: 18.921253 },
    { lat: 48.327326, lng: 18.921194 },
    { lat: 48.327276, lng: 18.921105 },
    { lat: 48.327185, lng: 18.921098 },
    { lat: 48.327079, lng: 18.920954 },
    { lat: 48.32683, lng: 18.920848 },
    { lat: 48.326685, lng: 18.920932 },
    { lat: 48.326658, lng: 18.920665 },
    { lat: 48.326518, lng: 18.920397 },
    { lat: 48.326436, lng: 18.920325 },
    { lat: 48.326356, lng: 18.920227 },
    { lat: 48.325959, lng: 18.92007 },
    { lat: 48.325887, lng: 18.919889 },
    { lat: 48.32562, lng: 18.91996 },
    { lat: 48.325406, lng: 18.919829 },
    { lat: 48.325184, lng: 18.919551 },
    { lat: 48.325151, lng: 18.919191 },
    { lat: 48.325031, lng: 18.918975 },
    { lat: 48.324791, lng: 18.918851 },
    { lat: 48.324763, lng: 18.918678 },
    { lat: 48.324591, lng: 18.918684 },
    { lat: 48.324466, lng: 18.918324 },
    { lat: 48.324436, lng: 18.917954 },
    { lat: 48.324261, lng: 18.917802 },
    { lat: 48.324269, lng: 18.917598 },
    { lat: 48.324122, lng: 18.917332 },
    { lat: 48.324113, lng: 18.917161 },
    { lat: 48.323881, lng: 18.916911 },
    { lat: 48.323743, lng: 18.916589 },
    { lat: 48.323603, lng: 18.916484 },
    { lat: 48.323564, lng: 18.916273 },
    { lat: 48.323301, lng: 18.915906 },
    { lat: 48.32318, lng: 18.915688 },
    { lat: 48.322944, lng: 18.915481 },
    { lat: 48.322807, lng: 18.915306 },
    { lat: 48.3224, lng: 18.915183 },
    { lat: 48.322318, lng: 18.915048 },
    { lat: 48.322009, lng: 18.914929 },
    { lat: 48.321874, lng: 18.914871 },
    { lat: 48.321864, lng: 18.91489 },
    { lat: 48.321761, lng: 18.914776 },
    { lat: 48.321573, lng: 18.91439 },
    { lat: 48.321527, lng: 18.914192 },
    { lat: 48.321455, lng: 18.914005 },
    { lat: 48.321227, lng: 18.913684 },
    { lat: 48.321198, lng: 18.91365 },
    { lat: 48.320931, lng: 18.914051 },
    { lat: 48.320781, lng: 18.914574 },
    { lat: 48.320467, lng: 18.914984 },
    { lat: 48.320131, lng: 18.915182 },
    { lat: 48.319941, lng: 18.91523 },
    { lat: 48.319937, lng: 18.915484 },
    { lat: 48.319701, lng: 18.915875 },
    { lat: 48.319615, lng: 18.916176 },
    { lat: 48.319584, lng: 18.916218 },
    { lat: 48.319309, lng: 18.916045 },
    { lat: 48.319175, lng: 18.916116 },
    { lat: 48.319005, lng: 18.916413 },
    { lat: 48.318903, lng: 18.916404 },
    { lat: 48.318719, lng: 18.916543 },
    { lat: 48.318589, lng: 18.91682 },
    { lat: 48.318207, lng: 18.916681 },
    { lat: 48.31812, lng: 18.916922 },
    { lat: 48.318232, lng: 18.917342 },
    { lat: 48.318128, lng: 18.91755 },
    { lat: 48.318011, lng: 18.917611 },
    { lat: 48.317856, lng: 18.917895 },
    { lat: 48.317302, lng: 18.918545 },
    { lat: 48.317042, lng: 18.918487 },
    { lat: 48.316669, lng: 18.918469 },
    { lat: 48.316516, lng: 18.918311 },
    { lat: 48.316274, lng: 18.918462 },
    { lat: 48.315972, lng: 18.918639 },
    { lat: 48.315752, lng: 18.918698 },
    { lat: 48.315224, lng: 18.919183 },
    { lat: 48.315002, lng: 18.919264 },
    { lat: 48.314869, lng: 18.919294 },
    { lat: 48.314745, lng: 18.919288 },
    { lat: 48.314727, lng: 18.919169 },
    { lat: 48.314689, lng: 18.918943 },
    { lat: 48.314657, lng: 18.918676 },
    { lat: 48.314559, lng: 18.918552 },
    { lat: 48.314489, lng: 18.918493 },
    { lat: 48.314475, lng: 18.918332 },
    { lat: 48.314479, lng: 18.91802 },
    { lat: 48.314464, lng: 18.917752 },
    { lat: 48.314421, lng: 18.91762 },
    { lat: 48.31432, lng: 18.917402 },
    { lat: 48.314349, lng: 18.917088 },
    { lat: 48.314308, lng: 18.916906 },
    { lat: 48.314289, lng: 18.916817 },
    { lat: 48.314232, lng: 18.916561 },
    { lat: 48.314248, lng: 18.916436 },
    { lat: 48.314184, lng: 18.916161 },
    { lat: 48.314124, lng: 18.915909 },
    { lat: 48.313827, lng: 18.914835 },
    { lat: 48.31371, lng: 18.914324 },
    { lat: 48.313577, lng: 18.914066 },
    { lat: 48.31349, lng: 18.913387 },
    { lat: 48.313249, lng: 18.913076 },
    { lat: 48.312985, lng: 18.912865 },
    { lat: 48.312707, lng: 18.912367 },
    { lat: 48.3123, lng: 18.911916 },
    { lat: 48.311798, lng: 18.910486 },
    { lat: 48.311637, lng: 18.910167 },
    { lat: 48.311319, lng: 18.90946 },
    { lat: 48.31105, lng: 18.908668 },
    { lat: 48.310861, lng: 18.90792 },
    { lat: 48.310496, lng: 18.906989 },
    { lat: 48.31038, lng: 18.906177 },
    { lat: 48.310313, lng: 18.905758 },
    { lat: 48.3102863, lng: 18.9057392 },
    { lat: 48.3091258, lng: 18.9062647 },
    { lat: 48.3081594, lng: 18.9068082 },
    { lat: 48.3079254, lng: 18.906885 },
    { lat: 48.3078201, lng: 18.9068087 },
    { lat: 48.3075643, lng: 18.9069209 },
    { lat: 48.3074103, lng: 18.9070805 },
    { lat: 48.3071311, lng: 18.9070924 },
    { lat: 48.3070976, lng: 18.9073588 },
    { lat: 48.3068987, lng: 18.9077421 },
    { lat: 48.3066331, lng: 18.9081038 },
    { lat: 48.3062199, lng: 18.9081146 },
    { lat: 48.3061667, lng: 18.9082639 },
    { lat: 48.3060735, lng: 18.908358 },
    { lat: 48.3056326, lng: 18.9084447 },
    { lat: 48.3055104, lng: 18.9083721 },
    { lat: 48.3053977, lng: 18.9085262 },
    { lat: 48.3052874, lng: 18.9085496 },
    { lat: 48.3052847, lng: 18.9086487 },
    { lat: 48.305206, lng: 18.9086129 },
    { lat: 48.3050555, lng: 18.9088411 },
    { lat: 48.3047192, lng: 18.9090541 },
    { lat: 48.3043858, lng: 18.9090819 },
    { lat: 48.3036167, lng: 18.9088858 },
    { lat: 48.3031094, lng: 18.9091286 },
    { lat: 48.3030519, lng: 18.9092143 },
    { lat: 48.3024858, lng: 18.9091912 },
    { lat: 48.3021264, lng: 18.9088936 },
    { lat: 48.3018489, lng: 18.9083451 },
    { lat: 48.3017074, lng: 18.9081848 },
    { lat: 48.3016977, lng: 18.9080609 },
    { lat: 48.3015892, lng: 18.9078366 },
    { lat: 48.3012794, lng: 18.9074951 },
    { lat: 48.30095, lng: 18.9069682 },
    { lat: 48.3009084, lng: 18.9068413 },
    { lat: 48.3009175, lng: 18.9066235 },
    { lat: 48.300501, lng: 18.9061594 },
    { lat: 48.3002157, lng: 18.9062273 },
    { lat: 48.2997488, lng: 18.9060716 },
    { lat: 48.2995279, lng: 18.9061401 },
    { lat: 48.2994526, lng: 18.9060774 },
    { lat: 48.2992267, lng: 18.906178 },
    { lat: 48.2990261, lng: 18.9059644 },
    { lat: 48.2988053, lng: 18.9059887 },
    { lat: 48.2986754, lng: 18.9060509 },
    { lat: 48.2985942, lng: 18.9062253 },
    { lat: 48.2983789, lng: 18.9062249 },
    { lat: 48.2982675, lng: 18.9063171 },
    { lat: 48.2981021, lng: 18.9061958 },
    { lat: 48.2980966, lng: 18.9061114 },
    { lat: 48.2980592, lng: 18.9062146 },
    { lat: 48.2979431, lng: 18.9061962 },
    { lat: 48.2979109, lng: 18.9062669 },
    { lat: 48.2976368, lng: 18.9062679 },
    { lat: 48.2975845, lng: 18.9062149 },
    { lat: 48.2973272, lng: 18.9063202 },
    { lat: 48.297045, lng: 18.9063382 },
    { lat: 48.2969084, lng: 18.9063332 },
    { lat: 48.296822, lng: 18.9062111 },
    { lat: 48.2965602, lng: 18.9061428 },
    { lat: 48.2964254, lng: 18.9061726 },
    { lat: 48.2962525, lng: 18.9063602 },
    { lat: 48.2960724, lng: 18.906391 },
    { lat: 48.2957111, lng: 18.9060301 },
    { lat: 48.2956107, lng: 18.9060945 },
    { lat: 48.2952384, lng: 18.9058963 },
    { lat: 48.2949303, lng: 18.9054767 },
    { lat: 48.2948422, lng: 18.9054955 },
    { lat: 48.2947756, lng: 18.905386 },
    { lat: 48.2946667, lng: 18.9053519 },
    { lat: 48.2943973, lng: 18.9049619 },
    { lat: 48.2943876, lng: 18.9047973 },
    { lat: 48.2941592, lng: 18.9042615 },
    { lat: 48.294106, lng: 18.9038676 },
    { lat: 48.2939224, lng: 18.9036304 },
    { lat: 48.2937542, lng: 18.9032343 },
    { lat: 48.2936629, lng: 18.9033019 },
    { lat: 48.2934696, lng: 18.903141 },
    { lat: 48.2933559, lng: 18.9027944 },
    { lat: 48.2931517, lng: 18.9024471 },
    { lat: 48.2931425, lng: 18.9022467 },
    { lat: 48.2929755, lng: 18.9020055 },
    { lat: 48.2928239, lng: 18.9019516 },
    { lat: 48.2925531, lng: 18.9015554 },
    { lat: 48.2922825, lng: 18.9014906 },
    { lat: 48.2922022, lng: 18.9013136 },
    { lat: 48.2919116, lng: 18.9012104 },
    { lat: 48.2916672, lng: 18.9013224 },
    { lat: 48.2908015, lng: 18.9012875 },
    { lat: 48.2905748, lng: 18.9014821 },
    { lat: 48.290408, lng: 18.9015036 },
    { lat: 48.2902228, lng: 18.9016505 },
    { lat: 48.2896612, lng: 18.9015664 },
    { lat: 48.2894608, lng: 18.9016347 },
    { lat: 48.2893596, lng: 18.9015789 },
    { lat: 48.2892098, lng: 18.901255 },
    { lat: 48.289097, lng: 18.9011204 },
    { lat: 48.2887596, lng: 18.9009664 },
    { lat: 48.2886368, lng: 18.9009453 },
    { lat: 48.2884665, lng: 18.9010331 },
    { lat: 48.2882559, lng: 18.9009078 },
    { lat: 48.2880372, lng: 18.9009743 },
    { lat: 48.287888, lng: 18.9009449 },
    { lat: 48.2876724, lng: 18.9008107 },
    { lat: 48.287378, lng: 18.900875 },
    { lat: 48.2869183, lng: 18.900663 },
    { lat: 48.2866763, lng: 18.9006487 },
    { lat: 48.2864492, lng: 18.900793 },
    { lat: 48.286335, lng: 18.9007652 },
    { lat: 48.2863094, lng: 18.9009225 },
    { lat: 48.2863852, lng: 18.9010596 },
    { lat: 48.2862846, lng: 18.9011821 },
    { lat: 48.286156, lng: 18.9010948 },
    { lat: 48.2859987, lng: 18.9012834 },
    { lat: 48.2858075, lng: 18.9012472 },
    { lat: 48.2855487, lng: 18.9014316 },
    { lat: 48.2852094, lng: 18.9013267 },
    { lat: 48.285082, lng: 18.9013273 },
    { lat: 48.2849486, lng: 18.9014515 },
    { lat: 48.2848634, lng: 18.9013485 },
    { lat: 48.2847537, lng: 18.9013432 },
    { lat: 48.2847051, lng: 18.9014501 },
    { lat: 48.2845203, lng: 18.9014316 },
    { lat: 48.2840763, lng: 18.9016999 },
    { lat: 48.2837248, lng: 18.9017468 },
    { lat: 48.2835234, lng: 18.9019738 },
    { lat: 48.2833643, lng: 18.9019729 },
    { lat: 48.2832091, lng: 18.9021786 },
    { lat: 48.2830001, lng: 18.9021253 },
    { lat: 48.2824823, lng: 18.9022525 },
    { lat: 48.2824252, lng: 18.9024772 },
    { lat: 48.2823238, lng: 18.9024355 },
    { lat: 48.2821885, lng: 18.9024778 },
    { lat: 48.281982, lng: 18.9027571 },
    { lat: 48.281727, lng: 18.9029298 },
    { lat: 48.281627, lng: 18.9030706 },
    { lat: 48.2813935, lng: 18.9029133 },
    { lat: 48.2811646, lng: 18.9029339 },
    { lat: 48.2804145, lng: 18.9034736 },
    { lat: 48.2798076, lng: 18.9043201 },
    { lat: 48.2793089, lng: 18.9048383 },
    { lat: 48.2789577, lng: 18.9055029 },
    { lat: 48.2789502, lng: 18.9056584 },
    { lat: 48.2788247, lng: 18.9059831 },
    { lat: 48.2785164, lng: 18.9065811 },
    { lat: 48.2779733, lng: 18.907875 },
    { lat: 48.2775978, lng: 18.9084408 },
    { lat: 48.2775162, lng: 18.9083249 },
    { lat: 48.2770697, lng: 18.9087842 },
    { lat: 48.2766256, lng: 18.9091148 },
    { lat: 48.2763227, lng: 18.9092979 },
    { lat: 48.2762697, lng: 18.9092103 },
    { lat: 48.2751263, lng: 18.9106482 },
    { lat: 48.2743713, lng: 18.9117018 },
    { lat: 48.2734316, lng: 18.9128257 },
    { lat: 48.2719482, lng: 18.9144936 },
    { lat: 48.27101, lng: 18.9153458 },
    { lat: 48.2700876, lng: 18.9161051 },
    { lat: 48.2687333, lng: 18.917067 },
    { lat: 48.2677982, lng: 18.9176504 },
    { lat: 48.2676827, lng: 18.9176829 },
    { lat: 48.2676516, lng: 18.9176257 },
    { lat: 48.2671536, lng: 18.9178735 },
    { lat: 48.2662613, lng: 18.918173 },
    { lat: 48.2661377, lng: 18.9182695 },
    { lat: 48.265751, lng: 18.9188374 },
    { lat: 48.2648965, lng: 18.919842 },
    { lat: 48.2643703, lng: 18.9206151 },
    { lat: 48.2631924, lng: 18.9228929 },
    { lat: 48.2631832, lng: 18.9232401 },
    { lat: 48.2629857, lng: 18.9239141 },
    { lat: 48.2629067, lng: 18.9239997 },
    { lat: 48.2626288, lng: 18.9248412 },
    { lat: 48.2625773, lng: 18.9248921 },
    { lat: 48.2625615, lng: 18.9251993 },
    { lat: 48.2624127, lng: 18.92546 },
    { lat: 48.2624266, lng: 18.9258399 },
    { lat: 48.2619365, lng: 18.9264151 },
    { lat: 48.2617096, lng: 18.9269541 },
    { lat: 48.2613561, lng: 18.9271822 },
    { lat: 48.2605328, lng: 18.9282521 },
    { lat: 48.2598053, lng: 18.9290535 },
    { lat: 48.2595105, lng: 18.9292844 },
    { lat: 48.2592147, lng: 18.9298027 },
    { lat: 48.2589494, lng: 18.9300832 },
    { lat: 48.2586892, lng: 18.9307004 },
    { lat: 48.2586734, lng: 18.9308292 },
    { lat: 48.2580764, lng: 18.9310373 },
    { lat: 48.2580085, lng: 18.9311453 },
    { lat: 48.2575209, lng: 18.9308946 },
    { lat: 48.25673, lng: 18.9311053 },
    { lat: 48.2562984, lng: 18.9313258 },
    { lat: 48.2557924, lng: 18.9319009 },
    { lat: 48.2557479, lng: 18.9321973 },
    { lat: 48.2558106, lng: 18.932759 },
    { lat: 48.2557352, lng: 18.9329368 },
    { lat: 48.2551977, lng: 18.9332038 },
    { lat: 48.2548228, lng: 18.9335211 },
    { lat: 48.2542854, lng: 18.9336161 },
    { lat: 48.2539562, lng: 18.9337929 },
    { lat: 48.2536678, lng: 18.9338411 },
    { lat: 48.253456, lng: 18.9340414 },
    { lat: 48.2522084, lng: 18.9364365 },
    { lat: 48.2519358, lng: 18.9368377 },
    { lat: 48.2516937, lng: 18.9370872 },
    { lat: 48.2513373, lng: 18.9374326 },
    { lat: 48.2509325, lng: 18.9376305 },
    { lat: 48.2506651, lng: 18.9378475 },
    { lat: 48.2496996, lng: 18.9388261 },
    { lat: 48.2494008, lng: 18.9388932 },
    { lat: 48.2488728, lng: 18.9387748 },
    { lat: 48.248695, lng: 18.938933 },
    { lat: 48.2485232, lng: 18.9393237 },
    { lat: 48.2484475, lng: 18.9394958 },
    { lat: 48.2480401, lng: 18.9397508 },
    { lat: 48.2479092, lng: 18.9397706 },
    { lat: 48.2469102, lng: 18.9393521 },
    { lat: 48.2465904, lng: 18.9390799 },
    { lat: 48.2461212, lng: 18.9392289 },
    { lat: 48.24552, lng: 18.9390514 },
    { lat: 48.2447614, lng: 18.938925 },
    { lat: 48.2435876, lng: 18.9397097 },
    { lat: 48.2425686, lng: 18.9402667 },
    { lat: 48.2408494, lng: 18.9403584 },
    { lat: 48.2399256, lng: 18.9402461 },
    { lat: 48.2389532, lng: 18.9408893 },
    { lat: 48.2389079, lng: 18.9425753 },
    { lat: 48.238628, lng: 18.9451507 },
    { lat: 48.2376839, lng: 18.9451886 },
    { lat: 48.2367992, lng: 18.9464454 },
    { lat: 48.2368534, lng: 18.946517 },
    { lat: 48.2364871, lng: 18.9478559 },
    { lat: 48.2364118, lng: 18.9495007 },
    { lat: 48.2370444, lng: 18.9498105 },
    { lat: 48.2379403, lng: 18.9501079 },
    { lat: 48.239055, lng: 18.9507137 },
    { lat: 48.2393869, lng: 18.9510285 },
    { lat: 48.2380639, lng: 18.957973 },
    { lat: 48.2392322, lng: 18.95857 },
  ],
  Rykynčice: [
    { lat: 48.2161716, lng: 19.0239838 },
    { lat: 48.2172206, lng: 19.0219706 },
    { lat: 48.2179114, lng: 19.0212302 },
    { lat: 48.2182839, lng: 19.0203775 },
    { lat: 48.2185619, lng: 19.0167988 },
    { lat: 48.2186701, lng: 19.0167055 },
    { lat: 48.2184567, lng: 19.0145498 },
    { lat: 48.2185615, lng: 19.0128911 },
    { lat: 48.2183925, lng: 19.0117836 },
    { lat: 48.2181822, lng: 19.0111055 },
    { lat: 48.2178195, lng: 19.0105394 },
    { lat: 48.2177041, lng: 19.010222 },
    { lat: 48.2173261, lng: 19.0075362 },
    { lat: 48.2172757, lng: 19.0058846 },
    { lat: 48.2178222, lng: 19.0044858 },
    { lat: 48.2177598, lng: 19.0042733 },
    { lat: 48.217615, lng: 19.0037805 },
    { lat: 48.2175469, lng: 19.0033293 },
    { lat: 48.2175154, lng: 19.0024491 },
    { lat: 48.2175398, lng: 19.0017781 },
    { lat: 48.2177532, lng: 19.0006539 },
    { lat: 48.2178736, lng: 19.0003633 },
    { lat: 48.2180785, lng: 18.9983692 },
    { lat: 48.2183871, lng: 18.9970789 },
    { lat: 48.2189387, lng: 18.9956051 },
    { lat: 48.2190224, lng: 18.9950356 },
    { lat: 48.2192658, lng: 18.9941726 },
    { lat: 48.2193584, lng: 18.9941939 },
    { lat: 48.2196581, lng: 18.9929981 },
    { lat: 48.2195329, lng: 18.9914961 },
    { lat: 48.2196726, lng: 18.9907662 },
    { lat: 48.2200819, lng: 18.990083 },
    { lat: 48.2216862, lng: 18.9866168 },
    { lat: 48.2231557, lng: 18.9839529 },
    { lat: 48.223095, lng: 18.983386 },
    { lat: 48.2233279, lng: 18.9830892 },
    { lat: 48.2236201, lng: 18.9830695 },
    { lat: 48.2239351, lng: 18.9825306 },
    { lat: 48.2234916, lng: 18.9822989 },
    { lat: 48.2225214, lng: 18.9808331 },
    { lat: 48.2227569, lng: 18.9804119 },
    { lat: 48.2236557, lng: 18.9792721 },
    { lat: 48.2243507, lng: 18.9788413 },
    { lat: 48.2256342, lng: 18.9768861 },
    { lat: 48.2260604, lng: 18.9762718 },
    { lat: 48.2251451, lng: 18.9746895 },
    { lat: 48.2240083, lng: 18.9731018 },
    { lat: 48.2229344, lng: 18.972037 },
    { lat: 48.2222489, lng: 18.9709602 },
    { lat: 48.220479, lng: 18.9688783 },
    { lat: 48.2182976, lng: 18.967652 },
    { lat: 48.2170066, lng: 18.9660266 },
    { lat: 48.2153156, lng: 18.9644299 },
    { lat: 48.2147046, lng: 18.9641264 },
    { lat: 48.2142434, lng: 18.9634858 },
    { lat: 48.2121474, lng: 18.96149 },
    { lat: 48.2107341, lng: 18.9604071 },
    { lat: 48.2101904, lng: 18.9600741 },
    { lat: 48.2080767, lng: 18.9585264 },
    { lat: 48.2083474, lng: 18.9572093 },
    { lat: 48.2085225, lng: 18.9563174 },
    { lat: 48.2085974, lng: 18.9556891 },
    { lat: 48.2086335, lng: 18.95499 },
    { lat: 48.2085423, lng: 18.9545109 },
    { lat: 48.2083141, lng: 18.954306 },
    { lat: 48.2079117, lng: 18.954108 },
    { lat: 48.207185, lng: 18.9538573 },
    { lat: 48.2065347, lng: 18.9534005 },
    { lat: 48.2061929, lng: 18.9527651 },
    { lat: 48.2055995, lng: 18.952036 },
    { lat: 48.2053674, lng: 18.9515346 },
    { lat: 48.2051943, lng: 18.9508653 },
    { lat: 48.2049525, lng: 18.9505057 },
    { lat: 48.2042892, lng: 18.9499318 },
    { lat: 48.2037445, lng: 18.9493374 },
    { lat: 48.2030407, lng: 18.9481917 },
    { lat: 48.2025459, lng: 18.9475849 },
    { lat: 48.2018293, lng: 18.9468513 },
    { lat: 48.2017179, lng: 18.946721 },
    { lat: 48.2014907, lng: 18.9466263 },
    { lat: 48.2008289, lng: 18.947916 },
    { lat: 48.200654, lng: 18.9480485 },
    { lat: 48.2002429, lng: 18.9486048 },
    { lat: 48.1999581, lng: 18.9490703 },
    { lat: 48.1998955, lng: 18.9492598 },
    { lat: 48.199495, lng: 18.9497379 },
    { lat: 48.1992402, lng: 18.949846 },
    { lat: 48.1990552, lng: 18.9496479 },
    { lat: 48.198944, lng: 18.9490857 },
    { lat: 48.1987141, lng: 18.9487361 },
    { lat: 48.198545, lng: 18.9486966 },
    { lat: 48.1979084, lng: 18.9477188 },
    { lat: 48.1977635, lng: 18.9475563 },
    { lat: 48.197592, lng: 18.9475301 },
    { lat: 48.1974113, lng: 18.9473067 },
    { lat: 48.1966187, lng: 18.946716 },
    { lat: 48.1964836, lng: 18.9463909 },
    { lat: 48.196364, lng: 18.9463043 },
    { lat: 48.1960207, lng: 18.9467542 },
    { lat: 48.1958176, lng: 18.9467504 },
    { lat: 48.1955912, lng: 18.9469524 },
    { lat: 48.1954609, lng: 18.9469816 },
    { lat: 48.1954023, lng: 18.9471792 },
    { lat: 48.1948912, lng: 18.9474169 },
    { lat: 48.1940524, lng: 18.9481193 },
    { lat: 48.193731, lng: 18.9479672 },
    { lat: 48.1933451, lng: 18.9475661 },
    { lat: 48.1929838, lng: 18.9473596 },
    { lat: 48.1926581, lng: 18.9473621 },
    { lat: 48.1912655, lng: 18.9461328 },
    { lat: 48.1909407, lng: 18.9459373 },
    { lat: 48.1903497, lng: 18.9459319 },
    { lat: 48.1900954, lng: 18.9460312 },
    { lat: 48.1895768, lng: 18.9460556 },
    { lat: 48.1890624, lng: 18.9457428 },
    { lat: 48.1886568, lng: 18.9453546 },
    { lat: 48.187906, lng: 18.9449676 },
    { lat: 48.1874665, lng: 18.9448384 },
    { lat: 48.1868654, lng: 18.9447928 },
    { lat: 48.1862714, lng: 18.9444041 },
    { lat: 48.1858113, lng: 18.9445126 },
    { lat: 48.1848414, lng: 18.9442007 },
    { lat: 48.1844206, lng: 18.9439581 },
    { lat: 48.1841399, lng: 18.9439583 },
    { lat: 48.1833672, lng: 18.9434255 },
    { lat: 48.1829744, lng: 18.9434884 },
    { lat: 48.1825234, lng: 18.9433793 },
    { lat: 48.1821871, lng: 18.9435474 },
    { lat: 48.1817921, lng: 18.9435855 },
    { lat: 48.1803093, lng: 18.9433399 },
    { lat: 48.1799615, lng: 18.943133 },
    { lat: 48.1785493, lng: 18.9430842 },
    { lat: 48.1783788, lng: 18.9434077 },
    { lat: 48.1779367, lng: 18.943513 },
    { lat: 48.177339, lng: 18.943341 },
    { lat: 48.177308, lng: 18.943348 },
    { lat: 48.177169, lng: 18.943387 },
    { lat: 48.176954, lng: 18.943594 },
    { lat: 48.176752, lng: 18.943832 },
    { lat: 48.176492, lng: 18.944034 },
    { lat: 48.176437, lng: 18.944125 },
    { lat: 48.176495, lng: 18.944282 },
    { lat: 48.176472, lng: 18.944348 },
    { lat: 48.17637, lng: 18.944382 },
    { lat: 48.176202, lng: 18.944361 },
    { lat: 48.176094, lng: 18.944421 },
    { lat: 48.176068, lng: 18.944473 },
    { lat: 48.176064, lng: 18.944596 },
    { lat: 48.176093, lng: 18.944672 },
    { lat: 48.176199, lng: 18.944801 },
    { lat: 48.176234, lng: 18.94492 },
    { lat: 48.176235, lng: 18.945003 },
    { lat: 48.176181, lng: 18.945084 },
    { lat: 48.176108, lng: 18.945126 },
    { lat: 48.176016, lng: 18.945146 },
    { lat: 48.175987, lng: 18.945377 },
    { lat: 48.176143, lng: 18.945585 },
    { lat: 48.176105, lng: 18.9458 },
    { lat: 48.175976, lng: 18.94592 },
    { lat: 48.175882, lng: 18.945965 },
    { lat: 48.175341, lng: 18.946131 },
    { lat: 48.17506, lng: 18.946381 },
    { lat: 48.174938, lng: 18.946619 },
    { lat: 48.174607, lng: 18.946569 },
    { lat: 48.17456, lng: 18.946767 },
    { lat: 48.17394, lng: 18.947602 },
    { lat: 48.173665, lng: 18.947863 },
    { lat: 48.173185, lng: 18.947883 },
    { lat: 48.173195, lng: 18.94791 },
    { lat: 48.173203, lng: 18.947927 },
    { lat: 48.173214, lng: 18.94827 },
    { lat: 48.173265, lng: 18.948362 },
    { lat: 48.173378, lng: 18.948457 },
    { lat: 48.173478, lng: 18.948599 },
    { lat: 48.173463, lng: 18.948754 },
    { lat: 48.173479, lng: 18.948895 },
    { lat: 48.173607, lng: 18.949079 },
    { lat: 48.173686, lng: 18.949129 },
    { lat: 48.173881, lng: 18.949146 },
    { lat: 48.173994, lng: 18.949266 },
    { lat: 48.174, lng: 18.949391 },
    { lat: 48.174087, lng: 18.94948 },
    { lat: 48.174184, lng: 18.949506 },
    { lat: 48.174284, lng: 18.949632 },
    { lat: 48.174273, lng: 18.949792 },
    { lat: 48.174439, lng: 18.949972 },
    { lat: 48.174463, lng: 18.95007 },
    { lat: 48.174698, lng: 18.950185 },
    { lat: 48.174796, lng: 18.95023 },
    { lat: 48.174931, lng: 18.950326 },
    { lat: 48.175124, lng: 18.950784 },
    { lat: 48.175205, lng: 18.950836 },
    { lat: 48.175278, lng: 18.950834 },
    { lat: 48.175297, lng: 18.950894 },
    { lat: 48.175199, lng: 18.950965 },
    { lat: 48.175216, lng: 18.951073 },
    { lat: 48.175388, lng: 18.951168 },
    { lat: 48.175464, lng: 18.951725 },
    { lat: 48.175703, lng: 18.95259 },
    { lat: 48.175999, lng: 18.953198 },
    { lat: 48.176282, lng: 18.953581 },
    { lat: 48.176376, lng: 18.953599 },
    { lat: 48.176465, lng: 18.953633 },
    { lat: 48.176651, lng: 18.95379 },
    { lat: 48.176853, lng: 18.953974 },
    { lat: 48.177186, lng: 18.954101 },
    { lat: 48.177377, lng: 18.954094 },
    { lat: 48.177837, lng: 18.954494 },
    { lat: 48.177909, lng: 18.954572 },
    { lat: 48.178068, lng: 18.954677 },
    { lat: 48.178476, lng: 18.955027 },
    { lat: 48.178657, lng: 18.955079 },
    { lat: 48.178751, lng: 18.955267 },
    { lat: 48.178927, lng: 18.955237 },
    { lat: 48.179128, lng: 18.955337 },
    { lat: 48.179198, lng: 18.955287 },
    { lat: 48.179547, lng: 18.955399 },
    { lat: 48.17983, lng: 18.955472 },
    { lat: 48.180202, lng: 18.955675 },
    { lat: 48.180398, lng: 18.955928 },
    { lat: 48.18057, lng: 18.956054 },
    { lat: 48.180877, lng: 18.95634 },
    { lat: 48.181111, lng: 18.95683 },
    { lat: 48.181247, lng: 18.956997 },
    { lat: 48.181451, lng: 18.957368 },
    { lat: 48.181547, lng: 18.957596 },
    { lat: 48.181632, lng: 18.957664 },
    { lat: 48.181659, lng: 18.957784 },
    { lat: 48.18178, lng: 18.95785 },
    { lat: 48.18192, lng: 18.958122 },
    { lat: 48.182135, lng: 18.958411 },
    { lat: 48.182353, lng: 18.958777 },
    { lat: 48.182364, lng: 18.958875 },
    { lat: 48.182458, lng: 18.959034 },
    { lat: 48.182477, lng: 18.959383 },
    { lat: 48.18243, lng: 18.959488 },
    { lat: 48.182465, lng: 18.959651 },
    { lat: 48.182437, lng: 18.959778 },
    { lat: 48.182496, lng: 18.960293 },
    { lat: 48.182665, lng: 18.961184 },
    { lat: 48.18268, lng: 18.961258 },
    { lat: 48.182372, lng: 18.961604 },
    { lat: 48.182102, lng: 18.962159 },
    { lat: 48.18194, lng: 18.962729 },
    { lat: 48.181703, lng: 18.963145 },
    { lat: 48.181542, lng: 18.964029 },
    { lat: 48.181365, lng: 18.964912 },
    { lat: 48.181059, lng: 18.965791 },
    { lat: 48.180871, lng: 18.966125 },
    { lat: 48.180727, lng: 18.966778 },
    { lat: 48.180711, lng: 18.967294 },
    { lat: 48.180823, lng: 18.967773 },
    { lat: 48.18091, lng: 18.967996 },
    { lat: 48.181014, lng: 18.968302 },
    { lat: 48.181117, lng: 18.968516 },
    { lat: 48.181281, lng: 18.968755 },
    { lat: 48.181516, lng: 18.968814 },
    { lat: 48.181827, lng: 18.969234 },
    { lat: 48.182045, lng: 18.969594 },
    { lat: 48.182298, lng: 18.97011 },
    { lat: 48.182346, lng: 18.970252 },
    { lat: 48.182391, lng: 18.970548 },
    { lat: 48.182486, lng: 18.970541 },
    { lat: 48.182882, lng: 18.971227 },
    { lat: 48.182912, lng: 18.971689 },
    { lat: 48.182973, lng: 18.971918 },
    { lat: 48.183119, lng: 18.972312 },
    { lat: 48.183152, lng: 18.972736 },
    { lat: 48.18318, lng: 18.974136 },
    { lat: 48.183194, lng: 18.974823 },
    { lat: 48.183129, lng: 18.974831 },
    { lat: 48.183134, lng: 18.974872 },
    { lat: 48.18315, lng: 18.974988 },
    { lat: 48.183165, lng: 18.975097 },
    { lat: 48.183166, lng: 18.975292 },
    { lat: 48.182897, lng: 18.97634 },
    { lat: 48.183061, lng: 18.976943 },
    { lat: 48.182872, lng: 18.977299 },
    { lat: 48.183092, lng: 18.977693 },
    { lat: 48.183314, lng: 18.977718 },
    { lat: 48.183535, lng: 18.977858 },
    { lat: 48.183689, lng: 18.978069 },
    { lat: 48.183798, lng: 18.978518 },
    { lat: 48.183728, lng: 18.979002 },
    { lat: 48.183756, lng: 18.979062 },
    { lat: 48.183822, lng: 18.979113 },
    { lat: 48.184091, lng: 18.979321 },
    { lat: 48.184303, lng: 18.979555 },
    { lat: 48.184384, lng: 18.979821 },
    { lat: 48.184408, lng: 18.98019 },
    { lat: 48.184785, lng: 18.980923 },
    { lat: 48.184881, lng: 18.981356 },
    { lat: 48.184997, lng: 18.982118 },
    { lat: 48.18522, lng: 18.982619 },
    { lat: 48.185754, lng: 18.983241 },
    { lat: 48.185883, lng: 18.983333 },
    { lat: 48.186127, lng: 18.983668 },
    { lat: 48.186071, lng: 18.984075 },
    { lat: 48.18639, lng: 18.984343 },
    { lat: 48.186685, lng: 18.985286 },
    { lat: 48.186913, lng: 18.985867 },
    { lat: 48.187177, lng: 18.986096 },
    { lat: 48.18735, lng: 18.986417 },
    { lat: 48.187359, lng: 18.986447 },
    { lat: 48.187417, lng: 18.986639 },
    { lat: 48.187415, lng: 18.986679 },
    { lat: 48.187399, lng: 18.986917 },
    { lat: 48.187236, lng: 18.988095 },
    { lat: 48.187121, lng: 18.989246 },
    { lat: 48.187118, lng: 18.989274 },
    { lat: 48.187159, lng: 18.990168 },
    { lat: 48.187161, lng: 18.990229 },
    { lat: 48.187243, lng: 18.991256 },
    { lat: 48.187571, lng: 18.991895 },
    { lat: 48.187873, lng: 18.992773 },
    { lat: 48.188429, lng: 18.993967 },
    { lat: 48.188447, lng: 18.994869 },
    { lat: 48.189043, lng: 18.999855 },
    { lat: 48.189108, lng: 19.000411 },
    { lat: 48.189088, lng: 19.001905 },
    { lat: 48.189127, lng: 19.002798 },
    { lat: 48.18917, lng: 19.003087 },
    { lat: 48.18944, lng: 19.00494 },
    { lat: 48.189511, lng: 19.006119 },
    { lat: 48.189618, lng: 19.007193 },
    { lat: 48.189682, lng: 19.008293 },
    { lat: 48.18971, lng: 19.008768 },
    { lat: 48.18971, lng: 19.00882 },
    { lat: 48.190005, lng: 19.008912 },
    { lat: 48.190361, lng: 19.009065 },
    { lat: 48.1911, lng: 19.008984 },
    { lat: 48.191245, lng: 19.008975 },
    { lat: 48.191442, lng: 19.008963 },
    { lat: 48.19195, lng: 19.008894 },
    { lat: 48.192183, lng: 19.008934 },
    { lat: 48.19236, lng: 19.008979 },
    { lat: 48.193323, lng: 19.008915 },
    { lat: 48.193336, lng: 19.009018 },
    { lat: 48.193373, lng: 19.011804 },
    { lat: 48.193372, lng: 19.014776 },
    { lat: 48.192618, lng: 19.016368 },
    { lat: 48.192571, lng: 19.016467 },
    { lat: 48.192421, lng: 19.016573 },
    { lat: 48.192303, lng: 19.016519 },
    { lat: 48.192287, lng: 19.016595 },
    { lat: 48.1925132, lng: 19.0166811 },
    { lat: 48.1925643, lng: 19.0167516 },
    { lat: 48.1926277, lng: 19.0170687 },
    { lat: 48.1928129, lng: 19.0175155 },
    { lat: 48.1932046, lng: 19.0179925 },
    { lat: 48.1936045, lng: 19.0180053 },
    { lat: 48.1940653, lng: 19.0175143 },
    { lat: 48.1945078, lng: 19.017324 },
    { lat: 48.1948367, lng: 19.0176484 },
    { lat: 48.1952758, lng: 19.0179705 },
    { lat: 48.1956065, lng: 19.0180452 },
    { lat: 48.1959391, lng: 19.0178208 },
    { lat: 48.1961921, lng: 19.0174983 },
    { lat: 48.1963424, lng: 19.0173925 },
    { lat: 48.19666, lng: 19.0173857 },
    { lat: 48.1968447, lng: 19.0175601 },
    { lat: 48.1968323, lng: 19.017934 },
    { lat: 48.1969183, lng: 19.0180083 },
    { lat: 48.1970788, lng: 19.0180137 },
    { lat: 48.1981525, lng: 19.0170267 },
    { lat: 48.1983143, lng: 19.0169252 },
    { lat: 48.1987647, lng: 19.0169132 },
    { lat: 48.1990603, lng: 19.0170933 },
    { lat: 48.1993424, lng: 19.01741 },
    { lat: 48.1995107, lng: 19.0177693 },
    { lat: 48.1997184, lng: 19.0179549 },
    { lat: 48.2001073, lng: 19.0180863 },
    { lat: 48.2005877, lng: 19.0179761 },
    { lat: 48.2009898, lng: 19.017437 },
    { lat: 48.2011856, lng: 19.0173616 },
    { lat: 48.2014671, lng: 19.0174003 },
    { lat: 48.2017888, lng: 19.0175842 },
    { lat: 48.2023539, lng: 19.0176534 },
    { lat: 48.2025566, lng: 19.0175169 },
    { lat: 48.203168, lng: 19.0168111 },
    { lat: 48.2033719, lng: 19.0166536 },
    { lat: 48.2033901, lng: 19.0162129 },
    { lat: 48.2036536, lng: 19.0160967 },
    { lat: 48.203872, lng: 19.0162241 },
    { lat: 48.2042716, lng: 19.0161235 },
    { lat: 48.2045565, lng: 19.0163367 },
    { lat: 48.2047097, lng: 19.0166221 },
    { lat: 48.2048024, lng: 19.0166702 },
    { lat: 48.2051436, lng: 19.0164624 },
    { lat: 48.2053312, lng: 19.0164704 },
    { lat: 48.2057909, lng: 19.0171295 },
    { lat: 48.206398, lng: 19.017317 },
    { lat: 48.2066143, lng: 19.0172687 },
    { lat: 48.2068931, lng: 19.0170963 },
    { lat: 48.2071598, lng: 19.0170878 },
    { lat: 48.2079374, lng: 19.0176602 },
    { lat: 48.2081487, lng: 19.0176235 },
    { lat: 48.2086514, lng: 19.0173531 },
    { lat: 48.2090491, lng: 19.0172155 },
    { lat: 48.209481, lng: 19.0173922 },
    { lat: 48.210095, lng: 19.0172926 },
    { lat: 48.2108329, lng: 19.0176607 },
    { lat: 48.2109979, lng: 19.017644 },
    { lat: 48.2111773, lng: 19.0174921 },
    { lat: 48.2113344, lng: 19.0175905 },
    { lat: 48.2117489, lng: 19.0182001 },
    { lat: 48.2119983, lng: 19.0186945 },
    { lat: 48.2122393, lng: 19.0195508 },
    { lat: 48.2126142, lng: 19.0201865 },
    { lat: 48.2127837, lng: 19.0203033 },
    { lat: 48.2131924, lng: 19.0204003 },
    { lat: 48.2135795, lng: 19.0208953 },
    { lat: 48.2139587, lng: 19.0209511 },
    { lat: 48.2143326, lng: 19.0211391 },
    { lat: 48.2153548, lng: 19.022639 },
    { lat: 48.2156564, lng: 19.022739 },
    { lat: 48.21608, lng: 19.0236871 },
    { lat: 48.2161716, lng: 19.0239838 },
  ],
  Lackov: [
    { lat: 48.3282834, lng: 19.1732598 },
    { lat: 48.3282026, lng: 19.1732691 },
    { lat: 48.3281839, lng: 19.1733686 },
    { lat: 48.3278696, lng: 19.1730978 },
    { lat: 48.327703, lng: 19.1728049 },
    { lat: 48.3273631, lng: 19.1725008 },
    { lat: 48.3271829, lng: 19.1725686 },
    { lat: 48.3269171, lng: 19.1722074 },
    { lat: 48.3270258, lng: 19.1719888 },
    { lat: 48.327009, lng: 19.1718356 },
    { lat: 48.3267299, lng: 19.1713157 },
    { lat: 48.3265931, lng: 19.1712448 },
    { lat: 48.3265258, lng: 19.1710447 },
    { lat: 48.3263555, lng: 19.1710208 },
    { lat: 48.3261903, lng: 19.1708936 },
    { lat: 48.3261088, lng: 19.1709508 },
    { lat: 48.3259992, lng: 19.1708376 },
    { lat: 48.3259288, lng: 19.1706647 },
    { lat: 48.3259599, lng: 19.1705635 },
    { lat: 48.3258893, lng: 19.1704158 },
    { lat: 48.3256225, lng: 19.1703753 },
    { lat: 48.3254172, lng: 19.1701693 },
    { lat: 48.3249785, lng: 19.169934 },
    { lat: 48.3249418, lng: 19.1698761 },
    { lat: 48.3249532, lng: 19.1694944 },
    { lat: 48.324845, lng: 19.1694465 },
    { lat: 48.3246885, lng: 19.169562 },
    { lat: 48.3245462, lng: 19.16949 },
    { lat: 48.3243421, lng: 19.1692176 },
    { lat: 48.3243273, lng: 19.1688927 },
    { lat: 48.3242216, lng: 19.1687864 },
    { lat: 48.3237929, lng: 19.1686209 },
    { lat: 48.3236878, lng: 19.1684777 },
    { lat: 48.323601, lng: 19.1684987 },
    { lat: 48.323248, lng: 19.1681101 },
    { lat: 48.3226943, lng: 19.1677862 },
    { lat: 48.3226035, lng: 19.1677974 },
    { lat: 48.3223755, lng: 19.1676067 },
    { lat: 48.3222718, lng: 19.1674007 },
    { lat: 48.3221076, lng: 19.1673864 },
    { lat: 48.3216837, lng: 19.1669057 },
    { lat: 48.3214804, lng: 19.1664445 },
    { lat: 48.3212748, lng: 19.1663667 },
    { lat: 48.3211362, lng: 19.166391 },
    { lat: 48.3210952, lng: 19.1662206 },
    { lat: 48.3206955, lng: 19.1659154 },
    { lat: 48.320563, lng: 19.1661307 },
    { lat: 48.3203858, lng: 19.1660777 },
    { lat: 48.3201683, lng: 19.1658079 },
    { lat: 48.3198389, lng: 19.1656002 },
    { lat: 48.3196953, lng: 19.1651791 },
    { lat: 48.3196493, lng: 19.164751 },
    { lat: 48.3194937, lng: 19.1645902 },
    { lat: 48.3195049, lng: 19.1646587 },
    { lat: 48.3190902, lng: 19.166577 },
    { lat: 48.3184974, lng: 19.1710713 },
    { lat: 48.3185093, lng: 19.1716522 },
    { lat: 48.3185628, lng: 19.1716647 },
    { lat: 48.3186327, lng: 19.1722375 },
    { lat: 48.3186182, lng: 19.1725232 },
    { lat: 48.3186156, lng: 19.1725751 },
    { lat: 48.318445, lng: 19.1732061 },
    { lat: 48.3182462, lng: 19.1734038 },
    { lat: 48.3181271, lng: 19.1733894 },
    { lat: 48.3178359, lng: 19.1748445 },
    { lat: 48.3178578, lng: 19.174943 },
    { lat: 48.3176825, lng: 19.175345 },
    { lat: 48.317516, lng: 19.1759673 },
    { lat: 48.3171862, lng: 19.1793809 },
    { lat: 48.3175387, lng: 19.1794183 },
    { lat: 48.3172675, lng: 19.1810719 },
    { lat: 48.3170867, lng: 19.1819565 },
    { lat: 48.3166974, lng: 19.1818357 },
    { lat: 48.3163401, lng: 19.1831163 },
    { lat: 48.3157819, lng: 19.184185 },
    { lat: 48.3156774, lng: 19.1844672 },
    { lat: 48.3157014, lng: 19.1879073 },
    { lat: 48.3156342, lng: 19.1889882 },
    { lat: 48.3156628, lng: 19.1895686 },
    { lat: 48.3156052, lng: 19.1902554 },
    { lat: 48.3150839, lng: 19.1925116 },
    { lat: 48.3148144, lng: 19.1943448 },
    { lat: 48.3147513, lng: 19.1951475 },
    { lat: 48.3149881, lng: 19.1978281 },
    { lat: 48.3152511, lng: 19.1989103 },
    { lat: 48.3155499, lng: 19.1991893 },
    { lat: 48.3156312, lng: 19.1997661 },
    { lat: 48.3156687, lng: 19.2005977 },
    { lat: 48.3155704, lng: 19.2015344 },
    { lat: 48.3152578, lng: 19.2029497 },
    { lat: 48.3151946, lng: 19.2047366 },
    { lat: 48.3150384, lng: 19.2061499 },
    { lat: 48.3147536, lng: 19.2074166 },
    { lat: 48.31466, lng: 19.208128 },
    { lat: 48.314651, lng: 19.208533 },
    { lat: 48.314809, lng: 19.208507 },
    { lat: 48.314926, lng: 19.208557 },
    { lat: 48.315087, lng: 19.208461 },
    { lat: 48.31514, lng: 19.208079 },
    { lat: 48.31528, lng: 19.208046 },
    { lat: 48.315289, lng: 19.208157 },
    { lat: 48.315541, lng: 19.208167 },
    { lat: 48.315651, lng: 19.208369 },
    { lat: 48.316021, lng: 19.208178 },
    { lat: 48.316107, lng: 19.208132 },
    { lat: 48.316188, lng: 19.208252 },
    { lat: 48.316172, lng: 19.208369 },
    { lat: 48.316117, lng: 19.208412 },
    { lat: 48.316156, lng: 19.208478 },
    { lat: 48.316279, lng: 19.208458 },
    { lat: 48.316312, lng: 19.208552 },
    { lat: 48.316193, lng: 19.208592 },
    { lat: 48.316204, lng: 19.20865 },
    { lat: 48.316322, lng: 19.208798 },
    { lat: 48.316377, lng: 19.208733 },
    { lat: 48.316402, lng: 19.20867 },
    { lat: 48.31662, lng: 19.208964 },
    { lat: 48.316723, lng: 19.208987 },
    { lat: 48.316894, lng: 19.209025 },
    { lat: 48.316902, lng: 19.208856 },
    { lat: 48.316972, lng: 19.208882 },
    { lat: 48.317163, lng: 19.208948 },
    { lat: 48.317273, lng: 19.209031 },
    { lat: 48.317337, lng: 19.209224 },
    { lat: 48.317255, lng: 19.209309 },
    { lat: 48.317391, lng: 19.209437 },
    { lat: 48.317464, lng: 19.209607 },
    { lat: 48.317576, lng: 19.209695 },
    { lat: 48.317898, lng: 19.209648 },
    { lat: 48.317924, lng: 19.20979 },
    { lat: 48.31799, lng: 19.209691 },
    { lat: 48.318197, lng: 19.209823 },
    { lat: 48.318328, lng: 19.209904 },
    { lat: 48.318363, lng: 19.210134 },
    { lat: 48.318433, lng: 19.210245 },
    { lat: 48.318542, lng: 19.210277 },
    { lat: 48.318647, lng: 19.210332 },
    { lat: 48.318696, lng: 19.21026 },
    { lat: 48.318814, lng: 19.210089 },
    { lat: 48.318899, lng: 19.210076 },
    { lat: 48.318882, lng: 19.210208 },
    { lat: 48.318937, lng: 19.210218 },
    { lat: 48.31903, lng: 19.210374 },
    { lat: 48.319147, lng: 19.210203 },
    { lat: 48.319225, lng: 19.210254 },
    { lat: 48.319181, lng: 19.210399 },
    { lat: 48.319285, lng: 19.210517 },
    { lat: 48.319363, lng: 19.210488 },
    { lat: 48.319415, lng: 19.210403 },
    { lat: 48.319465, lng: 19.210324 },
    { lat: 48.319494, lng: 19.210486 },
    { lat: 48.319517, lng: 19.210488 },
    { lat: 48.319609, lng: 19.210508 },
    { lat: 48.319613, lng: 19.21036 },
    { lat: 48.319719, lng: 19.210508 },
    { lat: 48.319838, lng: 19.210514 },
    { lat: 48.319832, lng: 19.210729 },
    { lat: 48.320094, lng: 19.210577 },
    { lat: 48.320139, lng: 19.210551 },
    { lat: 48.320113, lng: 19.210892 },
    { lat: 48.320287, lng: 19.211013 },
    { lat: 48.320355, lng: 19.210557 },
    { lat: 48.320502, lng: 19.210556 },
    { lat: 48.32065, lng: 19.210555 },
    { lat: 48.320702, lng: 19.210789 },
    { lat: 48.320905, lng: 19.210894 },
    { lat: 48.321175, lng: 19.210983 },
    { lat: 48.321187, lng: 19.2111 },
    { lat: 48.321129, lng: 19.211131 },
    { lat: 48.321095, lng: 19.211221 },
    { lat: 48.321125, lng: 19.211333 },
    { lat: 48.321171, lng: 19.211368 },
    { lat: 48.321316, lng: 19.211488 },
    { lat: 48.321397, lng: 19.211683 },
    { lat: 48.321495, lng: 19.211718 },
    { lat: 48.321546, lng: 19.211682 },
    { lat: 48.321566, lng: 19.211667 },
    { lat: 48.321658, lng: 19.211642 },
    { lat: 48.321695, lng: 19.211834 },
    { lat: 48.321864, lng: 19.211824 },
    { lat: 48.321977, lng: 19.211645 },
    { lat: 48.322115, lng: 19.211667 },
    { lat: 48.322138, lng: 19.211818 },
    { lat: 48.322216, lng: 19.21198 },
    { lat: 48.322294, lng: 19.212138 },
    { lat: 48.322409, lng: 19.212192 },
    { lat: 48.322541, lng: 19.212039 },
    { lat: 48.322733, lng: 19.21216 },
    { lat: 48.322851, lng: 19.21216 },
    { lat: 48.322881, lng: 19.212163 },
    { lat: 48.322959, lng: 19.212191 },
    { lat: 48.323038, lng: 19.212099 },
    { lat: 48.32303, lng: 19.212013 },
    { lat: 48.323118, lng: 19.211929 },
    { lat: 48.323332, lng: 19.21173 },
    { lat: 48.323307, lng: 19.211518 },
    { lat: 48.323415, lng: 19.21138 },
    { lat: 48.323527, lng: 19.211375 },
    { lat: 48.323599, lng: 19.211306 },
    { lat: 48.32358, lng: 19.211139 },
    { lat: 48.323634, lng: 19.211088 },
    { lat: 48.323821, lng: 19.210671 },
    { lat: 48.323886, lng: 19.21053 },
    { lat: 48.323883, lng: 19.21044 },
    { lat: 48.324018, lng: 19.210432 },
    { lat: 48.324083, lng: 19.210428 },
    { lat: 48.324189, lng: 19.210334 },
    { lat: 48.324235, lng: 19.210157 },
    { lat: 48.324329, lng: 19.209996 },
    { lat: 48.324402, lng: 19.210067 },
    { lat: 48.324439, lng: 19.210098 },
    { lat: 48.324473, lng: 19.209955 },
    { lat: 48.324521, lng: 19.209938 },
    { lat: 48.324688, lng: 19.209877 },
    { lat: 48.324803, lng: 19.20989 },
    { lat: 48.324933, lng: 19.209904 },
    { lat: 48.325065, lng: 19.209809 },
    { lat: 48.325245, lng: 19.209679 },
    { lat: 48.325353, lng: 19.20974 },
    { lat: 48.32541, lng: 19.209772 },
    { lat: 48.325446, lng: 19.209724 },
    { lat: 48.325558, lng: 19.209567 },
    { lat: 48.325548, lng: 19.209426 },
    { lat: 48.325629, lng: 19.209457 },
    { lat: 48.325868, lng: 19.209414 },
    { lat: 48.325853, lng: 19.209571 },
    { lat: 48.325927, lng: 19.209557 },
    { lat: 48.325961, lng: 19.209489 },
    { lat: 48.326049, lng: 19.209471 },
    { lat: 48.326235, lng: 19.20963 },
    { lat: 48.326329, lng: 19.209512 },
    { lat: 48.326493, lng: 19.209203 },
    { lat: 48.32661, lng: 19.209207 },
    { lat: 48.326592, lng: 19.209344 },
    { lat: 48.326733, lng: 19.209295 },
    { lat: 48.326852, lng: 19.209255 },
    { lat: 48.326932, lng: 19.209406 },
    { lat: 48.327162, lng: 19.20935 },
    { lat: 48.327192, lng: 19.209344 },
    { lat: 48.327216, lng: 19.209333 },
    { lat: 48.327274, lng: 19.209408 },
    { lat: 48.327354, lng: 19.209272 },
    { lat: 48.327589, lng: 19.209249 },
    { lat: 48.327646, lng: 19.209039 },
    { lat: 48.327758, lng: 19.209151 },
    { lat: 48.327888, lng: 19.209154 },
    { lat: 48.327928, lng: 19.209099 },
    { lat: 48.327954, lng: 19.208967 },
    { lat: 48.328094, lng: 19.208901 },
    { lat: 48.32808, lng: 19.208801 },
    { lat: 48.328282, lng: 19.208678 },
    { lat: 48.328323, lng: 19.208757 },
    { lat: 48.328261, lng: 19.208878 },
    { lat: 48.328374, lng: 19.20893 },
    { lat: 48.328402, lng: 19.20894 },
    { lat: 48.328458, lng: 19.208914 },
    { lat: 48.328524, lng: 19.208888 },
    { lat: 48.32868, lng: 19.208829 },
    { lat: 48.328799, lng: 19.208785 },
    { lat: 48.32883, lng: 19.208827 },
    { lat: 48.328872, lng: 19.208861 },
    { lat: 48.32892, lng: 19.20889 },
    { lat: 48.328969, lng: 19.208849 },
    { lat: 48.329245, lng: 19.208816 },
    { lat: 48.329302, lng: 19.208864 },
    { lat: 48.329319, lng: 19.208864 },
    { lat: 48.329469, lng: 19.208774 },
    { lat: 48.329573, lng: 19.208711 },
    { lat: 48.329618, lng: 19.208703 },
    { lat: 48.329736, lng: 19.208685 },
    { lat: 48.329808, lng: 19.208548 },
    { lat: 48.329804, lng: 19.208432 },
    { lat: 48.329936, lng: 19.208408 },
    { lat: 48.329945, lng: 19.208504 },
    { lat: 48.330006, lng: 19.208522 },
    { lat: 48.330028, lng: 19.208595 },
    { lat: 48.330075, lng: 19.208633 },
    { lat: 48.330084, lng: 19.208532 },
    { lat: 48.330163, lng: 19.208532 },
    { lat: 48.33019, lng: 19.208655 },
    { lat: 48.330317, lng: 19.208628 },
    { lat: 48.330304, lng: 19.208694 },
    { lat: 48.33044, lng: 19.208764 },
    { lat: 48.330514, lng: 19.208738 },
    { lat: 48.330588, lng: 19.208675 },
    { lat: 48.330642, lng: 19.208623 },
    { lat: 48.330638, lng: 19.208524 },
    { lat: 48.3307, lng: 19.208424 },
    { lat: 48.330794, lng: 19.208436 },
    { lat: 48.330936, lng: 19.208307 },
    { lat: 48.330968, lng: 19.208339 },
    { lat: 48.331069, lng: 19.20844 },
    { lat: 48.331137, lng: 19.208524 },
    { lat: 48.3311, lng: 19.208771 },
    { lat: 48.331329, lng: 19.208845 },
    { lat: 48.33142, lng: 19.208769 },
    { lat: 48.331519, lng: 19.20868 },
    { lat: 48.331578, lng: 19.20872 },
    { lat: 48.331725, lng: 19.208821 },
    { lat: 48.331845, lng: 19.208901 },
    { lat: 48.331952, lng: 19.208809 },
    { lat: 48.332061, lng: 19.20893 },
    { lat: 48.332204, lng: 19.209079 },
    { lat: 48.332299, lng: 19.209178 },
    { lat: 48.332531, lng: 19.209297 },
    { lat: 48.332664, lng: 19.20934 },
    { lat: 48.33275, lng: 19.209384 },
    { lat: 48.332867, lng: 19.209307 },
    { lat: 48.332926, lng: 19.209188 },
    { lat: 48.332952, lng: 19.209141 },
    { lat: 48.332959, lng: 19.209125 },
    { lat: 48.33305, lng: 19.209131 },
    { lat: 48.333128, lng: 19.209134 },
    { lat: 48.333122, lng: 19.209017 },
    { lat: 48.333359, lng: 19.208844 },
    { lat: 48.333467, lng: 19.208808 },
    { lat: 48.333599, lng: 19.208762 },
    { lat: 48.333723, lng: 19.208721 },
    { lat: 48.333789, lng: 19.208758 },
    { lat: 48.333918, lng: 19.208825 },
    { lat: 48.333964, lng: 19.208853 },
    { lat: 48.334013, lng: 19.208863 },
    { lat: 48.334283, lng: 19.208902 },
    { lat: 48.334397, lng: 19.208946 },
    { lat: 48.334474, lng: 19.208909 },
    { lat: 48.334572, lng: 19.208808 },
    { lat: 48.334734, lng: 19.20879 },
    { lat: 48.334833, lng: 19.208836 },
    { lat: 48.334935, lng: 19.208944 },
    { lat: 48.335051, lng: 19.209233 },
    { lat: 48.335095, lng: 19.209226 },
    { lat: 48.335223, lng: 19.209111 },
    { lat: 48.33538, lng: 19.209059 },
    { lat: 48.335497, lng: 19.209098 },
    { lat: 48.335603, lng: 19.209129 },
    { lat: 48.33571, lng: 19.209299 },
    { lat: 48.33594, lng: 19.209336 },
    { lat: 48.335977, lng: 19.209336 },
    { lat: 48.336006, lng: 19.209329 },
    { lat: 48.336086, lng: 19.209296 },
    { lat: 48.33612, lng: 19.209231 },
    { lat: 48.336253, lng: 19.209276 },
    { lat: 48.336448, lng: 19.209337 },
    { lat: 48.336523, lng: 19.209231 },
    { lat: 48.336575, lng: 19.209206 },
    { lat: 48.336703, lng: 19.20913 },
    { lat: 48.336768, lng: 19.209214 },
    { lat: 48.336879, lng: 19.209363 },
    { lat: 48.337002, lng: 19.209408 },
    { lat: 48.337073, lng: 19.209383 },
    { lat: 48.337196, lng: 19.209338 },
    { lat: 48.337338, lng: 19.209291 },
    { lat: 48.337327, lng: 19.209356 },
    { lat: 48.337378, lng: 19.209384 },
    { lat: 48.337502, lng: 19.20945 },
    { lat: 48.337558, lng: 19.209475 },
    { lat: 48.337712, lng: 19.209292 },
    { lat: 48.33781, lng: 19.20934 },
    { lat: 48.337891, lng: 19.20939 },
    { lat: 48.337994, lng: 19.20944 },
    { lat: 48.338046, lng: 19.209372 },
    { lat: 48.338094, lng: 19.209296 },
    { lat: 48.338148, lng: 19.209299 },
    { lat: 48.338246, lng: 19.209419 },
    { lat: 48.338318, lng: 19.209562 },
    { lat: 48.338321, lng: 19.209567 },
    { lat: 48.3384976, lng: 19.2088725 },
    { lat: 48.3384902, lng: 19.2078933 },
    { lat: 48.3384012, lng: 19.2077596 },
    { lat: 48.3383679, lng: 19.207403 },
    { lat: 48.33838, lng: 19.2066158 },
    { lat: 48.3378479, lng: 19.2055106 },
    { lat: 48.3379232, lng: 19.2048412 },
    { lat: 48.3379063, lng: 19.2042227 },
    { lat: 48.3379562, lng: 19.2035167 },
    { lat: 48.3381639, lng: 19.2017332 },
    { lat: 48.3381416, lng: 19.2016205 },
    { lat: 48.337868, lng: 19.2013737 },
    { lat: 48.337562, lng: 19.2012764 },
    { lat: 48.3374343, lng: 19.2011038 },
    { lat: 48.3373356, lng: 19.2007965 },
    { lat: 48.3370648, lng: 19.2007425 },
    { lat: 48.336928, lng: 19.2006467 },
    { lat: 48.3368031, lng: 19.2004217 },
    { lat: 48.3365057, lng: 19.2002385 },
    { lat: 48.3364943, lng: 19.1998245 },
    { lat: 48.336432, lng: 19.1996125 },
    { lat: 48.3358816, lng: 19.1990806 },
    { lat: 48.335365, lng: 19.1988631 },
    { lat: 48.3351519, lng: 19.1984505 },
    { lat: 48.3355507, lng: 19.1974137 },
    { lat: 48.3358683, lng: 19.1969981 },
    { lat: 48.3361502, lng: 19.1964939 },
    { lat: 48.3363778, lng: 19.1963107 },
    { lat: 48.3368399, lng: 19.1956619 },
    { lat: 48.3369495, lng: 19.1953933 },
    { lat: 48.3370689, lng: 19.1954396 },
    { lat: 48.3374001, lng: 19.1946313 },
    { lat: 48.3375289, lng: 19.1945706 },
    { lat: 48.3377434, lng: 19.1936352 },
    { lat: 48.3385914, lng: 19.1914644 },
    { lat: 48.3385041, lng: 19.1899643 },
    { lat: 48.3389605, lng: 19.1885677 },
    { lat: 48.3391953, lng: 19.1883178 },
    { lat: 48.3392385, lng: 19.1881925 },
    { lat: 48.3393694, lng: 19.1865439 },
    { lat: 48.3396598, lng: 19.1870382 },
    { lat: 48.3397505, lng: 19.1863624 },
    { lat: 48.3397728, lng: 19.1850553 },
    { lat: 48.3396803, lng: 19.1840458 },
    { lat: 48.3396046, lng: 19.1819158 },
    { lat: 48.3393129, lng: 19.1818536 },
    { lat: 48.3390858, lng: 19.1818852 },
    { lat: 48.338759, lng: 19.181571 },
    { lat: 48.3385554, lng: 19.1815769 },
    { lat: 48.338348, lng: 19.1814621 },
    { lat: 48.3381282, lng: 19.1813731 },
    { lat: 48.3378977, lng: 19.1809526 },
    { lat: 48.3376049, lng: 19.180911 },
    { lat: 48.3373619, lng: 19.1807172 },
    { lat: 48.3371491, lng: 19.1803784 },
    { lat: 48.3367967, lng: 19.1799771 },
    { lat: 48.3366255, lng: 19.1799351 },
    { lat: 48.3364093, lng: 19.1797749 },
    { lat: 48.3363282, lng: 19.179803 },
    { lat: 48.3360343, lng: 19.1795086 },
    { lat: 48.3357463, lng: 19.1794073 },
    { lat: 48.3354414, lng: 19.1791935 },
    { lat: 48.3352511, lng: 19.1793113 },
    { lat: 48.3348847, lng: 19.178947 },
    { lat: 48.3349074, lng: 19.1787401 },
    { lat: 48.3344193, lng: 19.1784899 },
    { lat: 48.3341151, lng: 19.1779933 },
    { lat: 48.3341714, lng: 19.1777614 },
    { lat: 48.3335283, lng: 19.1775911 },
    { lat: 48.3334111, lng: 19.1773647 },
    { lat: 48.3329744, lng: 19.1771942 },
    { lat: 48.3326334, lng: 19.1772494 },
    { lat: 48.3324522, lng: 19.1770289 },
    { lat: 48.3321191, lng: 19.1770613 },
    { lat: 48.3319384, lng: 19.1768762 },
    { lat: 48.3319533, lng: 19.1767184 },
    { lat: 48.3318045, lng: 19.1764685 },
    { lat: 48.3315141, lng: 19.1763618 },
    { lat: 48.3310314, lng: 19.1759251 },
    { lat: 48.3308406, lng: 19.1755541 },
    { lat: 48.3304455, lng: 19.1754077 },
    { lat: 48.3301744, lng: 19.174983 },
    { lat: 48.3299372, lng: 19.1749303 },
    { lat: 48.3298198, lng: 19.174628 },
    { lat: 48.3295781, lng: 19.1743885 },
    { lat: 48.3291986, lng: 19.174308 },
    { lat: 48.3285898, lng: 19.1736909 },
    { lat: 48.3282834, lng: 19.1732598 },
  ],
  Lišov: [
    { lat: 48.257494, lng: 18.8641058 },
    { lat: 48.2577044, lng: 18.8636693 },
    { lat: 48.2578503, lng: 18.8624434 },
    { lat: 48.2578436, lng: 18.8619025 },
    { lat: 48.2577423, lng: 18.8613471 },
    { lat: 48.2574445, lng: 18.8609743 },
    { lat: 48.2578541, lng: 18.8603117 },
    { lat: 48.2585541, lng: 18.8598114 },
    { lat: 48.2583722, lng: 18.8580337 },
    { lat: 48.2582791, lng: 18.8575669 },
    { lat: 48.2598826, lng: 18.8556447 },
    { lat: 48.2601863, lng: 18.8551561 },
    { lat: 48.2603907, lng: 18.8546449 },
    { lat: 48.2606778, lng: 18.8532113 },
    { lat: 48.2613192, lng: 18.8519753 },
    { lat: 48.2625041, lng: 18.8502215 },
    { lat: 48.2630289, lng: 18.8496939 },
    { lat: 48.2632836, lng: 18.8492814 },
    { lat: 48.2636021, lng: 18.8489018 },
    { lat: 48.2640966, lng: 18.8485872 },
    { lat: 48.2655052, lng: 18.8468891 },
    { lat: 48.2664294, lng: 18.8447028 },
    { lat: 48.2669443, lng: 18.843876 },
    { lat: 48.2672529, lng: 18.8432452 },
    { lat: 48.2673952, lng: 18.8426002 },
    { lat: 48.2675597, lng: 18.8421449 },
    { lat: 48.2677842, lng: 18.8417387 },
    { lat: 48.26807, lng: 18.840583 },
    { lat: 48.267739, lng: 18.839037 },
    { lat: 48.267233, lng: 18.837931 },
    { lat: 48.266586, lng: 18.83593 },
    { lat: 48.2665, lng: 18.835512 },
    { lat: 48.266489, lng: 18.835465 },
    { lat: 48.266427, lng: 18.835161 },
    { lat: 48.266277, lng: 18.834713 },
    { lat: 48.266271, lng: 18.834269 },
    { lat: 48.266261, lng: 18.83348 },
    { lat: 48.266109, lng: 18.832662 },
    { lat: 48.265978, lng: 18.831077 },
    { lat: 48.265931, lng: 18.830545 },
    { lat: 48.265956, lng: 18.830505 },
    { lat: 48.265946, lng: 18.83038 },
    { lat: 48.26594, lng: 18.830312 },
    { lat: 48.265813, lng: 18.828784 },
    { lat: 48.265837, lng: 18.827951 },
    { lat: 48.265559, lng: 18.826632 },
    { lat: 48.265368, lng: 18.826255 },
    { lat: 48.26526, lng: 18.826081 },
    { lat: 48.26521, lng: 18.826027 },
    { lat: 48.264881, lng: 18.825674 },
    { lat: 48.264333, lng: 18.825834 },
    { lat: 48.263757, lng: 18.824286 },
    { lat: 48.262912, lng: 18.822265 },
    { lat: 48.262512, lng: 18.821348 },
    { lat: 48.262151, lng: 18.8205 },
    { lat: 48.261447, lng: 18.818889 },
    { lat: 48.261244, lng: 18.818577 },
    { lat: 48.26091, lng: 18.817828 },
    { lat: 48.260715, lng: 18.817493 },
    { lat: 48.260547, lng: 18.817209 },
    { lat: 48.260297, lng: 18.817223 },
    { lat: 48.259984, lng: 18.817212 },
    { lat: 48.259581, lng: 18.816017 },
    { lat: 48.259152, lng: 18.815376 },
    { lat: 48.258777, lng: 18.814556 },
    { lat: 48.258515, lng: 18.814205 },
    { lat: 48.258241, lng: 18.814093 },
    { lat: 48.257867, lng: 18.81383 },
    { lat: 48.25782, lng: 18.813798 },
    { lat: 48.257308, lng: 18.813608 },
    { lat: 48.257055, lng: 18.813525 },
    { lat: 48.256566, lng: 18.813427 },
    { lat: 48.256204, lng: 18.813305 },
    { lat: 48.255807, lng: 18.813311 },
    { lat: 48.255724, lng: 18.813281 },
    { lat: 48.25518, lng: 18.813356 },
    { lat: 48.254561, lng: 18.813351 },
    { lat: 48.253843, lng: 18.813345 },
    { lat: 48.253543, lng: 18.8133 },
    { lat: 48.252748, lng: 18.81318 },
    { lat: 48.252746, lng: 18.81318 },
    { lat: 48.252539, lng: 18.81315 },
    { lat: 48.252142, lng: 18.813105 },
    { lat: 48.252036, lng: 18.813 },
    { lat: 48.251783, lng: 18.812943 },
    { lat: 48.251567, lng: 18.812847 },
    { lat: 48.251105, lng: 18.812905 },
    { lat: 48.25076, lng: 18.812563 },
    { lat: 48.250736, lng: 18.812539 },
    { lat: 48.250386, lng: 18.812198 },
    { lat: 48.249866, lng: 18.811475 },
    { lat: 48.249315, lng: 18.811081 },
    { lat: 48.248094, lng: 18.809269 },
    { lat: 48.2465567, lng: 18.808017 },
    { lat: 48.2456632, lng: 18.8073877 },
    { lat: 48.2444761, lng: 18.8091093 },
    { lat: 48.2445031, lng: 18.8100259 },
    { lat: 48.2442746, lng: 18.8103586 },
    { lat: 48.2437978, lng: 18.8104035 },
    { lat: 48.2435715, lng: 18.8101946 },
    { lat: 48.2429906, lng: 18.810473 },
    { lat: 48.2423611, lng: 18.8117515 },
    { lat: 48.241817, lng: 18.8126213 },
    { lat: 48.2418008, lng: 18.8134496 },
    { lat: 48.2415824, lng: 18.8141613 },
    { lat: 48.2416403, lng: 18.8147686 },
    { lat: 48.2413943, lng: 18.8156258 },
    { lat: 48.2420265, lng: 18.8165386 },
    { lat: 48.2423862, lng: 18.8169225 },
    { lat: 48.2426796, lng: 18.8175484 },
    { lat: 48.2429152, lng: 18.8170947 },
    { lat: 48.2433376, lng: 18.8166157 },
    { lat: 48.2436277, lng: 18.8168967 },
    { lat: 48.2436164, lng: 18.8171988 },
    { lat: 48.2434157, lng: 18.8181016 },
    { lat: 48.2444274, lng: 18.8180389 },
    { lat: 48.2442565, lng: 18.8190769 },
    { lat: 48.2442286, lng: 18.8197503 },
    { lat: 48.2440883, lng: 18.8204255 },
    { lat: 48.2440738, lng: 18.8210481 },
    { lat: 48.2444617, lng: 18.8222746 },
    { lat: 48.2438385, lng: 18.823033 },
    { lat: 48.243756, lng: 18.8233289 },
    { lat: 48.2435176, lng: 18.8237295 },
    { lat: 48.2433312, lng: 18.8243875 },
    { lat: 48.24313, lng: 18.8246369 },
    { lat: 48.2430362, lng: 18.8250392 },
    { lat: 48.2429022, lng: 18.8252811 },
    { lat: 48.2428438, lng: 18.8256944 },
    { lat: 48.2425405, lng: 18.8267048 },
    { lat: 48.242251, lng: 18.8272886 },
    { lat: 48.2420253, lng: 18.8280174 },
    { lat: 48.2418974, lng: 18.8274491 },
    { lat: 48.2413767, lng: 18.8277541 },
    { lat: 48.2401871, lng: 18.8282712 },
    { lat: 48.2401635, lng: 18.8281452 },
    { lat: 48.2386501, lng: 18.8292826 },
    { lat: 48.2386635, lng: 18.829453 },
    { lat: 48.2387242, lng: 18.8295558 },
    { lat: 48.2380229, lng: 18.8298177 },
    { lat: 48.2375357, lng: 18.8301385 },
    { lat: 48.2369367, lng: 18.8301754 },
    { lat: 48.2366348, lng: 18.8304203 },
    { lat: 48.2363782, lng: 18.8304929 },
    { lat: 48.2361723, lng: 18.8303523 },
    { lat: 48.2359371, lng: 18.8297617 },
    { lat: 48.2356469, lng: 18.8294944 },
    { lat: 48.2354226, lng: 18.8295399 },
    { lat: 48.2348935, lng: 18.8299668 },
    { lat: 48.2342784, lng: 18.8300201 },
    { lat: 48.2342721, lng: 18.8297708 },
    { lat: 48.233632, lng: 18.8297346 },
    { lat: 48.233813, lng: 18.8303302 },
    { lat: 48.2336103, lng: 18.8308068 },
    { lat: 48.2335856, lng: 18.8310212 },
    { lat: 48.2337554, lng: 18.8312085 },
    { lat: 48.2338886, lng: 18.8319806 },
    { lat: 48.2341514, lng: 18.832112 },
    { lat: 48.2342337, lng: 18.832415 },
    { lat: 48.2342346, lng: 18.8325822 },
    { lat: 48.2345681, lng: 18.8327262 },
    { lat: 48.2348289, lng: 18.8330132 },
    { lat: 48.2349582, lng: 18.8334776 },
    { lat: 48.2349307, lng: 18.8337278 },
    { lat: 48.2351869, lng: 18.8341164 },
    { lat: 48.235536, lng: 18.8343881 },
    { lat: 48.2355591, lng: 18.834783 },
    { lat: 48.2358491, lng: 18.8351535 },
    { lat: 48.2361874, lng: 18.8354547 },
    { lat: 48.2362987, lng: 18.835684 },
    { lat: 48.2361639, lng: 18.8357582 },
    { lat: 48.2361563, lng: 18.836065 },
    { lat: 48.2362166, lng: 18.836313 },
    { lat: 48.2354666, lng: 18.8374499 },
    { lat: 48.2347112, lng: 18.8391105 },
    { lat: 48.2343518, lng: 18.8401067 },
    { lat: 48.2338624, lng: 18.8409897 },
    { lat: 48.2324548, lng: 18.8429627 },
    { lat: 48.2323129, lng: 18.8442133 },
    { lat: 48.2322867, lng: 18.8450716 },
    { lat: 48.2320472, lng: 18.8457409 },
    { lat: 48.2319811, lng: 18.8462651 },
    { lat: 48.2317383, lng: 18.8473695 },
    { lat: 48.2311368, lng: 18.8473828 },
    { lat: 48.2304698, lng: 18.8472024 },
    { lat: 48.2296091, lng: 18.8473044 },
    { lat: 48.2286838, lng: 18.8476584 },
    { lat: 48.2278728, lng: 18.8482596 },
    { lat: 48.2276281, lng: 18.8485358 },
    { lat: 48.2263443, lng: 18.8493219 },
    { lat: 48.2252773, lng: 18.8494299 },
    { lat: 48.2237143, lng: 18.8497716 },
    { lat: 48.2231065, lng: 18.8499875 },
    { lat: 48.2230125, lng: 18.8496986 },
    { lat: 48.2216147, lng: 18.8506591 },
    { lat: 48.2204096, lng: 18.8511398 },
    { lat: 48.2202404, lng: 18.8509001 },
    { lat: 48.219933, lng: 18.8500295 },
    { lat: 48.2187094, lng: 18.8492935 },
    { lat: 48.2184777, lng: 18.8498012 },
    { lat: 48.2184431, lng: 18.849871 },
    { lat: 48.2186228, lng: 18.8505048 },
    { lat: 48.218858, lng: 18.8510971 },
    { lat: 48.2192391, lng: 18.8517016 },
    { lat: 48.2193447, lng: 18.8519732 },
    { lat: 48.2192536, lng: 18.8523219 },
    { lat: 48.2190537, lng: 18.8524677 },
    { lat: 48.219111, lng: 18.8526767 },
    { lat: 48.2188143, lng: 18.8539264 },
    { lat: 48.218907, lng: 18.8543207 },
    { lat: 48.2190355, lng: 18.856086 },
    { lat: 48.2191198, lng: 18.856428 },
    { lat: 48.2190531, lng: 18.8566801 },
    { lat: 48.21909, lng: 18.856912 },
    { lat: 48.2189798, lng: 18.8581084 },
    { lat: 48.2187236, lng: 18.8581433 },
    { lat: 48.2186106, lng: 18.8585077 },
    { lat: 48.2184704, lng: 18.8584806 },
    { lat: 48.2183679, lng: 18.8585668 },
    { lat: 48.2181056, lng: 18.8589868 },
    { lat: 48.2181952, lng: 18.8590379 },
    { lat: 48.2183232, lng: 18.8590724 },
    { lat: 48.2184437, lng: 18.8597814 },
    { lat: 48.2184674, lng: 18.8607291 },
    { lat: 48.2185795, lng: 18.8618842 },
    { lat: 48.2187097, lng: 18.8624135 },
    { lat: 48.2187053, lng: 18.8639041 },
    { lat: 48.2187666, lng: 18.8644704 },
    { lat: 48.219443, lng: 18.8662205 },
    { lat: 48.2206335, lng: 18.8702733 },
    { lat: 48.2208962, lng: 18.8703169 },
    { lat: 48.2203152, lng: 18.8716093 },
    { lat: 48.2211189, lng: 18.8724666 },
    { lat: 48.2211034, lng: 18.874268 },
    { lat: 48.2195427, lng: 18.8798969 },
    { lat: 48.2194683, lng: 18.8803514 },
    { lat: 48.219492, lng: 18.880721 },
    { lat: 48.2193174, lng: 18.8814386 },
    { lat: 48.2188543, lng: 18.8826555 },
    { lat: 48.2186779, lng: 18.8832984 },
    { lat: 48.2186769, lng: 18.8833014 },
    { lat: 48.2186697, lng: 18.8833132 },
    { lat: 48.218882, lng: 18.8835784 },
    { lat: 48.2191259, lng: 18.8836514 },
    { lat: 48.2193849, lng: 18.8838376 },
    { lat: 48.2200021, lng: 18.8839782 },
    { lat: 48.2202023, lng: 18.884207 },
    { lat: 48.2202891, lng: 18.8841403 },
    { lat: 48.2203421, lng: 18.8838785 },
    { lat: 48.2204569, lng: 18.8837877 },
    { lat: 48.2206711, lng: 18.883831 },
    { lat: 48.2212639, lng: 18.8841696 },
    { lat: 48.2215501, lng: 18.8841253 },
    { lat: 48.2220574, lng: 18.8850555 },
    { lat: 48.2223316, lng: 18.8854016 },
    { lat: 48.2226324, lng: 18.8855699 },
    { lat: 48.2230743, lng: 18.8856984 },
    { lat: 48.2235368, lng: 18.8865721 },
    { lat: 48.2249583, lng: 18.8881198 },
    { lat: 48.2252234, lng: 18.8885154 },
    { lat: 48.226031, lng: 18.8906867 },
    { lat: 48.2269544, lng: 18.8915956 },
    { lat: 48.229151, lng: 18.8939432 },
    { lat: 48.22931, lng: 18.8946147 },
    { lat: 48.2295351, lng: 18.8953004 },
    { lat: 48.2292711, lng: 18.8970336 },
    { lat: 48.2291015, lng: 18.8976577 },
    { lat: 48.2290522, lng: 18.8982685 },
    { lat: 48.2290473, lng: 18.8983076 },
    { lat: 48.2292971, lng: 18.8982949 },
    { lat: 48.2293701, lng: 18.898181 },
    { lat: 48.2296569, lng: 18.8981017 },
    { lat: 48.2297914, lng: 18.8978027 },
    { lat: 48.2299316, lng: 18.8977666 },
    { lat: 48.2300014, lng: 18.8980054 },
    { lat: 48.2300859, lng: 18.8980681 },
    { lat: 48.2304008, lng: 18.8978567 },
    { lat: 48.2306488, lng: 18.8979007 },
    { lat: 48.2310424, lng: 18.8978348 },
    { lat: 48.2311784, lng: 18.8979149 },
    { lat: 48.2314507, lng: 18.8977944 },
    { lat: 48.2315235, lng: 18.897654 },
    { lat: 48.2319823, lng: 18.8973471 },
    { lat: 48.2323031, lng: 18.897336 },
    { lat: 48.2326003, lng: 18.8970846 },
    { lat: 48.2327524, lng: 18.8970602 },
    { lat: 48.2329976, lng: 18.8968538 },
    { lat: 48.2331788, lng: 18.896849 },
    { lat: 48.2333971, lng: 18.8965569 },
    { lat: 48.2335534, lng: 18.8965914 },
    { lat: 48.2336557, lng: 18.8965007 },
    { lat: 48.2336705, lng: 18.8963935 },
    { lat: 48.2336944, lng: 18.8962885 },
    { lat: 48.2337349, lng: 18.896113 },
    { lat: 48.2338078, lng: 18.8960387 },
    { lat: 48.234086, lng: 18.8960587 },
    { lat: 48.2348067, lng: 18.8952767 },
    { lat: 48.2353977, lng: 18.8940209 },
    { lat: 48.2360035, lng: 18.8932646 },
    { lat: 48.2361527, lng: 18.893185 },
    { lat: 48.2367999, lng: 18.891947 },
    { lat: 48.2370332, lng: 18.8910986 },
    { lat: 48.2369936, lng: 18.8908138 },
    { lat: 48.2370217, lng: 18.8907034 },
    { lat: 48.2373459, lng: 18.8901945 },
    { lat: 48.2374794, lng: 18.8898359 },
    { lat: 48.2381367, lng: 18.8900939 },
    { lat: 48.2385269, lng: 18.8891359 },
    { lat: 48.2389431, lng: 18.8884005 },
    { lat: 48.2390698, lng: 18.8885598 },
    { lat: 48.2398225, lng: 18.8880536 },
    { lat: 48.2425113, lng: 18.8858075 },
    { lat: 48.2430037, lng: 18.8845629 },
    { lat: 48.2432112, lng: 18.883568 },
    { lat: 48.2433211, lng: 18.8827515 },
    { lat: 48.24323, lng: 18.8827246 },
    { lat: 48.2433388, lng: 18.8818513 },
    { lat: 48.2433361, lng: 18.8793339 },
    { lat: 48.2433729, lng: 18.8783115 },
    { lat: 48.2449185, lng: 18.8780614 },
    { lat: 48.2452557, lng: 18.8778494 },
    { lat: 48.2452255, lng: 18.8774868 },
    { lat: 48.245352, lng: 18.8767079 },
    { lat: 48.2460209, lng: 18.8766245 },
    { lat: 48.2460581, lng: 18.8768451 },
    { lat: 48.2461414, lng: 18.8769476 },
    { lat: 48.2465849, lng: 18.8767829 },
    { lat: 48.2467843, lng: 18.8769953 },
    { lat: 48.2470718, lng: 18.876886 },
    { lat: 48.2473427, lng: 18.8769959 },
    { lat: 48.2475784, lng: 18.8765672 },
    { lat: 48.2477812, lng: 18.8760415 },
    { lat: 48.2480953, lng: 18.8755357 },
    { lat: 48.2483027, lng: 18.8750123 },
    { lat: 48.24885, lng: 18.8741548 },
    { lat: 48.2493197, lng: 18.8739956 },
    { lat: 48.249494, lng: 18.8738539 },
    { lat: 48.2497768, lng: 18.8738293 },
    { lat: 48.2498844, lng: 18.8737492 },
    { lat: 48.2500552, lng: 18.8738025 },
    { lat: 48.2502562, lng: 18.8736152 },
    { lat: 48.2504767, lng: 18.8737108 },
    { lat: 48.2509474, lng: 18.8735476 },
    { lat: 48.251206, lng: 18.8733592 },
    { lat: 48.2514696, lng: 18.8733567 },
    { lat: 48.2515442, lng: 18.8732744 },
    { lat: 48.2515606, lng: 18.8731543 },
    { lat: 48.2520681, lng: 18.8727674 },
    { lat: 48.2529177, lng: 18.8719113 },
    { lat: 48.2531491, lng: 18.8715909 },
    { lat: 48.2533836, lng: 18.8713714 },
    { lat: 48.2535052, lng: 18.8713912 },
    { lat: 48.2539887, lng: 18.8699133 },
    { lat: 48.2551976, lng: 18.8677742 },
    { lat: 48.2556667, lng: 18.8667559 },
    { lat: 48.2561523, lng: 18.8672054 },
    { lat: 48.2562813, lng: 18.866995 },
    { lat: 48.256557, lng: 18.8661209 },
    { lat: 48.2572669, lng: 18.8649441 },
    { lat: 48.257494, lng: 18.8641058 },
  ],
  Drienovo: [
    { lat: 48.2269174, lng: 19.0356864 },
    { lat: 48.2266481, lng: 19.0355798 },
    { lat: 48.2263558, lng: 19.0352195 },
    { lat: 48.2261016, lng: 19.0343393 },
    { lat: 48.2259615, lng: 19.0341356 },
    { lat: 48.2256626, lng: 19.0339754 },
    { lat: 48.2255507, lng: 19.0339736 },
    { lat: 48.2253855, lng: 19.0340285 },
    { lat: 48.2252056, lng: 19.0342356 },
    { lat: 48.2249488, lng: 19.0342167 },
    { lat: 48.2246761, lng: 19.0339135 },
    { lat: 48.2244186, lng: 19.0335026 },
    { lat: 48.2236924, lng: 19.033188 },
    { lat: 48.2234117, lng: 19.0329459 },
    { lat: 48.2230651, lng: 19.0325122 },
    { lat: 48.2217697, lng: 19.0311574 },
    { lat: 48.221444, lng: 19.0305156 },
    { lat: 48.2212889, lng: 19.0300167 },
    { lat: 48.2209003, lng: 19.0292261 },
    { lat: 48.2207889, lng: 19.0288276 },
    { lat: 48.2207172, lng: 19.0280151 },
    { lat: 48.2205047, lng: 19.0277155 },
    { lat: 48.2202175, lng: 19.0274331 },
    { lat: 48.2189531, lng: 19.0264678 },
    { lat: 48.2182481, lng: 19.026175 },
    { lat: 48.2179714, lng: 19.02614 },
    { lat: 48.2177021, lng: 19.0256343 },
    { lat: 48.2176536, lng: 19.0253036 },
    { lat: 48.2174409, lng: 19.0249538 },
    { lat: 48.2167964, lng: 19.0242967 },
    { lat: 48.2166224, lng: 19.0242444 },
    { lat: 48.2163909, lng: 19.0242817 },
    { lat: 48.2162159, lng: 19.0241199 },
    { lat: 48.2161716, lng: 19.0239838 },
    { lat: 48.21608, lng: 19.0236871 },
    { lat: 48.2156564, lng: 19.022739 },
    { lat: 48.2153548, lng: 19.022639 },
    { lat: 48.2143326, lng: 19.0211391 },
    { lat: 48.2139587, lng: 19.0209511 },
    { lat: 48.2135795, lng: 19.0208953 },
    { lat: 48.2131924, lng: 19.0204003 },
    { lat: 48.2127837, lng: 19.0203033 },
    { lat: 48.2126142, lng: 19.0201865 },
    { lat: 48.2122393, lng: 19.0195508 },
    { lat: 48.2119983, lng: 19.0186945 },
    { lat: 48.2117489, lng: 19.0182001 },
    { lat: 48.2113344, lng: 19.0175905 },
    { lat: 48.2111773, lng: 19.0174921 },
    { lat: 48.2109979, lng: 19.017644 },
    { lat: 48.2108329, lng: 19.0176607 },
    { lat: 48.210095, lng: 19.0172926 },
    { lat: 48.209481, lng: 19.0173922 },
    { lat: 48.2090491, lng: 19.0172155 },
    { lat: 48.2086514, lng: 19.0173531 },
    { lat: 48.2081487, lng: 19.0176235 },
    { lat: 48.2079374, lng: 19.0176602 },
    { lat: 48.2071598, lng: 19.0170878 },
    { lat: 48.2068931, lng: 19.0170963 },
    { lat: 48.2066143, lng: 19.0172687 },
    { lat: 48.206398, lng: 19.017317 },
    { lat: 48.2057909, lng: 19.0171295 },
    { lat: 48.2053312, lng: 19.0164704 },
    { lat: 48.2051436, lng: 19.0164624 },
    { lat: 48.2048024, lng: 19.0166702 },
    { lat: 48.2047097, lng: 19.0166221 },
    { lat: 48.2045565, lng: 19.0163367 },
    { lat: 48.2042716, lng: 19.0161235 },
    { lat: 48.203872, lng: 19.0162241 },
    { lat: 48.2036536, lng: 19.0160967 },
    { lat: 48.2033901, lng: 19.0162129 },
    { lat: 48.2033719, lng: 19.0166536 },
    { lat: 48.203168, lng: 19.0168111 },
    { lat: 48.2025566, lng: 19.0175169 },
    { lat: 48.2023539, lng: 19.0176534 },
    { lat: 48.2017888, lng: 19.0175842 },
    { lat: 48.2014671, lng: 19.0174003 },
    { lat: 48.2011856, lng: 19.0173616 },
    { lat: 48.2009898, lng: 19.017437 },
    { lat: 48.2005877, lng: 19.0179761 },
    { lat: 48.2001073, lng: 19.0180863 },
    { lat: 48.1997184, lng: 19.0179549 },
    { lat: 48.1995107, lng: 19.0177693 },
    { lat: 48.1993424, lng: 19.01741 },
    { lat: 48.1990603, lng: 19.0170933 },
    { lat: 48.1987647, lng: 19.0169132 },
    { lat: 48.1983143, lng: 19.0169252 },
    { lat: 48.1981525, lng: 19.0170267 },
    { lat: 48.1970788, lng: 19.0180137 },
    { lat: 48.1969183, lng: 19.0180083 },
    { lat: 48.1968323, lng: 19.017934 },
    { lat: 48.1968447, lng: 19.0175601 },
    { lat: 48.19666, lng: 19.0173857 },
    { lat: 48.1963424, lng: 19.0173925 },
    { lat: 48.1961921, lng: 19.0174983 },
    { lat: 48.1959391, lng: 19.0178208 },
    { lat: 48.1956065, lng: 19.0180452 },
    { lat: 48.1952758, lng: 19.0179705 },
    { lat: 48.1948367, lng: 19.0176484 },
    { lat: 48.1945078, lng: 19.017324 },
    { lat: 48.1940653, lng: 19.0175143 },
    { lat: 48.1936045, lng: 19.0180053 },
    { lat: 48.1932046, lng: 19.0179925 },
    { lat: 48.1928129, lng: 19.0175155 },
    { lat: 48.1926277, lng: 19.0170687 },
    { lat: 48.1925643, lng: 19.0167516 },
    { lat: 48.1925132, lng: 19.0166811 },
    { lat: 48.192287, lng: 19.016595 },
    { lat: 48.192275, lng: 19.016655 },
    { lat: 48.192228, lng: 19.016901 },
    { lat: 48.192206, lng: 19.017008 },
    { lat: 48.192069, lng: 19.017107 },
    { lat: 48.191878, lng: 19.01712 },
    { lat: 48.191803, lng: 19.017164 },
    { lat: 48.191774, lng: 19.017224 },
    { lat: 48.191603, lng: 19.017276 },
    { lat: 48.191321, lng: 19.017363 },
    { lat: 48.191004, lng: 19.017462 },
    { lat: 48.190896, lng: 19.017443 },
    { lat: 48.190764, lng: 19.017611 },
    { lat: 48.190747, lng: 19.017885 },
    { lat: 48.190685, lng: 19.017991 },
    { lat: 48.190727, lng: 19.018046 },
    { lat: 48.190435, lng: 19.018316 },
    { lat: 48.189879, lng: 19.01888 },
    { lat: 48.189788, lng: 19.019001 },
    { lat: 48.189659, lng: 19.019231 },
    { lat: 48.189551, lng: 19.019495 },
    { lat: 48.189524, lng: 19.019597 },
    { lat: 48.189476, lng: 19.019812 },
    { lat: 48.189646, lng: 19.020772 },
    { lat: 48.18977, lng: 19.02144 },
    { lat: 48.189785, lng: 19.021512 },
    { lat: 48.189805, lng: 19.021618 },
    { lat: 48.189841, lng: 19.021806 },
    { lat: 48.189864, lng: 19.021862 },
    { lat: 48.190203, lng: 19.022909 },
    { lat: 48.190263, lng: 19.023098 },
    { lat: 48.190383, lng: 19.023408 },
    { lat: 48.190542, lng: 19.023759 },
    { lat: 48.190676, lng: 19.024012 },
    { lat: 48.190778, lng: 19.024355 },
    { lat: 48.190828, lng: 19.024588 },
    { lat: 48.190941, lng: 19.024975 },
    { lat: 48.191145, lng: 19.025571 },
    { lat: 48.19126, lng: 19.025834 },
    { lat: 48.191362, lng: 19.026211 },
    { lat: 48.19155, lng: 19.027014 },
    { lat: 48.191901, lng: 19.028391 },
    { lat: 48.192108, lng: 19.02899 },
    { lat: 48.192144, lng: 19.029195 },
    { lat: 48.192145, lng: 19.029715 },
    { lat: 48.192201, lng: 19.029962 },
    { lat: 48.192256, lng: 19.03026 },
    { lat: 48.192271, lng: 19.030327 },
    { lat: 48.192281, lng: 19.030386 },
    { lat: 48.192374, lng: 19.031238 },
    { lat: 48.192411, lng: 19.031664 },
    { lat: 48.192583, lng: 19.032454 },
    { lat: 48.192879, lng: 19.033197 },
    { lat: 48.193047, lng: 19.033632 },
    { lat: 48.193245, lng: 19.034119 },
    { lat: 48.193448, lng: 19.034408 },
    { lat: 48.193682, lng: 19.034631 },
    { lat: 48.193945, lng: 19.034882 },
    { lat: 48.194365, lng: 19.035552 },
    { lat: 48.194832, lng: 19.036194 },
    { lat: 48.19497, lng: 19.036546 },
    { lat: 48.195222, lng: 19.03723 },
    { lat: 48.195422, lng: 19.037784 },
    { lat: 48.195774, lng: 19.03854 },
    { lat: 48.195812, lng: 19.038627 },
    { lat: 48.19588, lng: 19.03881 },
    { lat: 48.195906, lng: 19.038947 },
    { lat: 48.195977, lng: 19.039378 },
    { lat: 48.196012, lng: 19.039485 },
    { lat: 48.196098, lng: 19.039627 },
    { lat: 48.196195, lng: 19.039776 },
    { lat: 48.196413, lng: 19.040048 },
    { lat: 48.196566, lng: 19.040249 },
    { lat: 48.197157, lng: 19.041319 },
    { lat: 48.197607, lng: 19.042284 },
    { lat: 48.197688, lng: 19.042448 },
    { lat: 48.198172, lng: 19.043034 },
    { lat: 48.198441, lng: 19.043283 },
    { lat: 48.198599, lng: 19.04338 },
    { lat: 48.199238, lng: 19.043536 },
    { lat: 48.199342, lng: 19.043581 },
    { lat: 48.199491, lng: 19.043782 },
    { lat: 48.19963, lng: 19.04404 },
    { lat: 48.199872, lng: 19.044172 },
    { lat: 48.200055, lng: 19.044183 },
    { lat: 48.200272, lng: 19.044542 },
    { lat: 48.20038, lng: 19.044667 },
    { lat: 48.200813, lng: 19.044894 },
    { lat: 48.201144, lng: 19.045164 },
    { lat: 48.20128, lng: 19.04524 },
    { lat: 48.201701, lng: 19.045525 },
    { lat: 48.201745, lng: 19.04556 },
    { lat: 48.201837, lng: 19.045645 },
    { lat: 48.20194, lng: 19.045759 },
    { lat: 48.202076, lng: 19.045935 },
    { lat: 48.202172, lng: 19.046044 },
    { lat: 48.202351, lng: 19.04624 },
    { lat: 48.202511, lng: 19.046461 },
    { lat: 48.202415, lng: 19.046573 },
    { lat: 48.202411, lng: 19.046612 },
    { lat: 48.202411, lng: 19.04671 },
    { lat: 48.202341, lng: 19.046706 },
    { lat: 48.202223, lng: 19.046636 },
    { lat: 48.202099, lng: 19.046588 },
    { lat: 48.201946, lng: 19.046458 },
    { lat: 48.201829, lng: 19.046386 },
    { lat: 48.201538, lng: 19.046237 },
    { lat: 48.201229, lng: 19.046024 },
    { lat: 48.201098, lng: 19.045895 },
    { lat: 48.201079, lng: 19.045962 },
    { lat: 48.201069, lng: 19.045987 },
    { lat: 48.201044, lng: 19.046073 },
    { lat: 48.201056, lng: 19.046081 },
    { lat: 48.201511, lng: 19.046414 },
    { lat: 48.202453, lng: 19.04716 },
    { lat: 48.202721, lng: 19.047369 },
    { lat: 48.202693, lng: 19.047436 },
    { lat: 48.203049, lng: 19.047815 },
    { lat: 48.203215, lng: 19.048153 },
    { lat: 48.203436, lng: 19.048561 },
    { lat: 48.20358, lng: 19.048777 },
    { lat: 48.203655, lng: 19.048932 },
    { lat: 48.203799, lng: 19.049358 },
    { lat: 48.20393, lng: 19.049612 },
    { lat: 48.204069, lng: 19.049761 },
    { lat: 48.204127, lng: 19.049835 },
    { lat: 48.204629, lng: 19.050141 },
    { lat: 48.205149, lng: 19.050515 },
    { lat: 48.205431, lng: 19.05086 },
    { lat: 48.205792, lng: 19.051645 },
    { lat: 48.205834, lng: 19.051832 },
    { lat: 48.205913, lng: 19.052032 },
    { lat: 48.205958, lng: 19.052188 },
    { lat: 48.206023, lng: 19.052249 },
    { lat: 48.206093, lng: 19.052305 },
    { lat: 48.206174, lng: 19.052411 },
    { lat: 48.206286, lng: 19.052456 },
    { lat: 48.206423, lng: 19.052531 },
    { lat: 48.206535, lng: 19.052646 },
    { lat: 48.206598, lng: 19.052731 },
    { lat: 48.206697, lng: 19.052918 },
    { lat: 48.206731, lng: 19.052979 },
    { lat: 48.206781, lng: 19.053099 },
    { lat: 48.206807, lng: 19.053189 },
    { lat: 48.206799, lng: 19.053266 },
    { lat: 48.206823, lng: 19.053296 },
    { lat: 48.206894, lng: 19.053353 },
    { lat: 48.206904, lng: 19.053372 },
    { lat: 48.20698, lng: 19.053472 },
    { lat: 48.207025, lng: 19.053559 },
    { lat: 48.207044, lng: 19.053613 },
    { lat: 48.207064, lng: 19.053666 },
    { lat: 48.20712, lng: 19.053756 },
    { lat: 48.207154, lng: 19.053835 },
    { lat: 48.207235, lng: 19.053826 },
    { lat: 48.207305, lng: 19.05392 },
    { lat: 48.207331, lng: 19.053998 },
    { lat: 48.207439, lng: 19.054054 },
    { lat: 48.207484, lng: 19.054082 },
    { lat: 48.207549, lng: 19.054129 },
    { lat: 48.207603, lng: 19.054188 },
    { lat: 48.207651, lng: 19.054202 },
    { lat: 48.207675, lng: 19.054202 },
    { lat: 48.207792, lng: 19.054168 },
    { lat: 48.207833, lng: 19.054182 },
    { lat: 48.207871, lng: 19.054166 },
    { lat: 48.207928, lng: 19.054185 },
    { lat: 48.208048, lng: 19.054266 },
    { lat: 48.208064, lng: 19.054271 },
    { lat: 48.208182, lng: 19.054316 },
    { lat: 48.208289, lng: 19.054357 },
    { lat: 48.208403, lng: 19.054364 },
    { lat: 48.208509, lng: 19.054403 },
    { lat: 48.208709, lng: 19.054438 },
    { lat: 48.208809, lng: 19.054406 },
    { lat: 48.208911, lng: 19.054432 },
    { lat: 48.209047, lng: 19.054566 },
    { lat: 48.209278, lng: 19.054791 },
    { lat: 48.209391, lng: 19.054846 },
    { lat: 48.209484, lng: 19.054904 },
    { lat: 48.209679, lng: 19.055054 },
    { lat: 48.209811, lng: 19.055227 },
    { lat: 48.20989, lng: 19.055356 },
    { lat: 48.210053, lng: 19.055715 },
    { lat: 48.210094, lng: 19.056039 },
    { lat: 48.210168, lng: 19.056423 },
    { lat: 48.210175, lng: 19.056649 },
    { lat: 48.210221, lng: 19.057056 },
    { lat: 48.210257, lng: 19.05708 },
    { lat: 48.210311, lng: 19.057181 },
    { lat: 48.210342, lng: 19.057233 },
    { lat: 48.210375, lng: 19.057288 },
    { lat: 48.210433, lng: 19.05736 },
    { lat: 48.21049, lng: 19.057418 },
    { lat: 48.210523, lng: 19.057469 },
    { lat: 48.210542, lng: 19.057469 },
    { lat: 48.210578, lng: 19.057502 },
    { lat: 48.210659, lng: 19.057629 },
    { lat: 48.210762, lng: 19.057804 },
    { lat: 48.2108, lng: 19.057867 },
    { lat: 48.21084, lng: 19.057936 },
    { lat: 48.210853, lng: 19.058058 },
    { lat: 48.210886, lng: 19.058111 },
    { lat: 48.210925, lng: 19.058153 },
    { lat: 48.210982, lng: 19.058188 },
    { lat: 48.211057, lng: 19.058332 },
    { lat: 48.2113345, lng: 19.0577616 },
    { lat: 48.2122401, lng: 19.0590848 },
    { lat: 48.2122964, lng: 19.0593057 },
    { lat: 48.2124066, lng: 19.0594997 },
    { lat: 48.2123879, lng: 19.0603604 },
    { lat: 48.2127062, lng: 19.0612134 },
    { lat: 48.2129674, lng: 19.0612956 },
    { lat: 48.2133146, lng: 19.061092 },
    { lat: 48.2140187, lng: 19.0609247 },
    { lat: 48.2142773, lng: 19.0611582 },
    { lat: 48.2145114, lng: 19.0615312 },
    { lat: 48.2146677, lng: 19.0620593 },
    { lat: 48.2150248, lng: 19.0628811 },
    { lat: 48.2157272, lng: 19.0630124 },
    { lat: 48.2158647, lng: 19.0636382 },
    { lat: 48.2158306, lng: 19.0641719 },
    { lat: 48.2159494, lng: 19.0643116 },
    { lat: 48.2165712, lng: 19.0656883 },
    { lat: 48.2165866, lng: 19.0661235 },
    { lat: 48.2166574, lng: 19.0661886 },
    { lat: 48.2170695, lng: 19.0662687 },
    { lat: 48.2179535, lng: 19.0663816 },
    { lat: 48.2180594, lng: 19.0662 },
    { lat: 48.2185738, lng: 19.0666743 },
    { lat: 48.2197402, lng: 19.0670436 },
    { lat: 48.219982, lng: 19.0669901 },
    { lat: 48.2200964, lng: 19.0670245 },
    { lat: 48.2202227, lng: 19.0672885 },
    { lat: 48.2202519, lng: 19.0676493 },
    { lat: 48.2201866, lng: 19.0681849 },
    { lat: 48.2202836, lng: 19.0682298 },
    { lat: 48.2207304, lng: 19.0691551 },
    { lat: 48.2214995, lng: 19.069494 },
    { lat: 48.2217603, lng: 19.0692118 },
    { lat: 48.2221194, lng: 19.0693346 },
    { lat: 48.2222406, lng: 19.0696334 },
    { lat: 48.2225825, lng: 19.0700424 },
    { lat: 48.2229905, lng: 19.0703757 },
    { lat: 48.2233818, lng: 19.0704468 },
    { lat: 48.2238534, lng: 19.0703172 },
    { lat: 48.2259175, lng: 19.0706493 },
    { lat: 48.2266527, lng: 19.0708315 },
    { lat: 48.227214, lng: 19.0713664 },
    { lat: 48.2276211, lng: 19.0730689 },
    { lat: 48.2274487, lng: 19.0733384 },
    { lat: 48.2280298, lng: 19.0737728 },
    { lat: 48.228411, lng: 19.0742453 },
    { lat: 48.2285046, lng: 19.0741131 },
    { lat: 48.2289829, lng: 19.0750132 },
    { lat: 48.2297254, lng: 19.0758103 },
    { lat: 48.2304234, lng: 19.0761499 },
    { lat: 48.230833, lng: 19.076225 },
    { lat: 48.2311836, lng: 19.0766048 },
    { lat: 48.231604, lng: 19.0767812 },
    { lat: 48.2319931, lng: 19.0770298 },
    { lat: 48.2321637, lng: 19.0774618 },
    { lat: 48.2324197, lng: 19.0778916 },
    { lat: 48.2323965, lng: 19.0781916 },
    { lat: 48.2326795, lng: 19.0788386 },
    { lat: 48.2329058, lng: 19.0787201 },
    { lat: 48.2331299, lng: 19.0791224 },
    { lat: 48.2332176, lng: 19.0793872 },
    { lat: 48.2332091, lng: 19.0806513 },
    { lat: 48.2331619, lng: 19.0809244 },
    { lat: 48.2339776, lng: 19.081359 },
    { lat: 48.2343286, lng: 19.081457 },
    { lat: 48.2345503, lng: 19.0818299 },
    { lat: 48.2348464, lng: 19.0819344 },
    { lat: 48.235262, lng: 19.0823067 },
    { lat: 48.2353892, lng: 19.0825063 },
    { lat: 48.2360079, lng: 19.0826138 },
    { lat: 48.2368999, lng: 19.0829323 },
    { lat: 48.2371845, lng: 19.0831882 },
    { lat: 48.2375446, lng: 19.0832183 },
    { lat: 48.2383362, lng: 19.0836501 },
    { lat: 48.2387299, lng: 19.0844361 },
    { lat: 48.2388207, lng: 19.0845182 },
    { lat: 48.2391209, lng: 19.0845021 },
    { lat: 48.2398231, lng: 19.0826557 },
    { lat: 48.239931, lng: 19.0822698 },
    { lat: 48.2400198, lng: 19.0826926 },
    { lat: 48.2403023, lng: 19.0829972 },
    { lat: 48.2406437, lng: 19.083016 },
    { lat: 48.2409446, lng: 19.0825863 },
    { lat: 48.2413791, lng: 19.0813618 },
    { lat: 48.2417305, lng: 19.0813773 },
    { lat: 48.242155, lng: 19.0804686 },
    { lat: 48.2425941, lng: 19.0798288 },
    { lat: 48.2427692, lng: 19.0793645 },
    { lat: 48.2429744, lng: 19.0784699 },
    { lat: 48.2431862, lng: 19.0779922 },
    { lat: 48.2438085, lng: 19.0772744 },
    { lat: 48.244093, lng: 19.0771734 },
    { lat: 48.2447309, lng: 19.077122 },
    { lat: 48.2451522, lng: 19.0767802 },
    { lat: 48.2455, lng: 19.0762783 },
    { lat: 48.2459461, lng: 19.0754165 },
    { lat: 48.2461425, lng: 19.07459 },
    { lat: 48.2463097, lng: 19.0744445 },
    { lat: 48.2464319, lng: 19.0740937 },
    { lat: 48.2465657, lng: 19.0741974 },
    { lat: 48.2468504, lng: 19.0708524 },
    { lat: 48.2467489, lng: 19.0702261 },
    { lat: 48.247066, lng: 19.0696268 },
    { lat: 48.2481261, lng: 19.0679298 },
    { lat: 48.2484377, lng: 19.0670003 },
    { lat: 48.2475001, lng: 19.0656896 },
    { lat: 48.2472852, lng: 19.0653163 },
    { lat: 48.2472755, lng: 19.0654571 },
    { lat: 48.2469076, lng: 19.0656762 },
    { lat: 48.2468257, lng: 19.0649466 },
    { lat: 48.2467635, lng: 19.064851 },
    { lat: 48.2468071, lng: 19.0647326 },
    { lat: 48.247036, lng: 19.0646427 },
    { lat: 48.2469936, lng: 19.0638908 },
    { lat: 48.246799, lng: 19.0636769 },
    { lat: 48.2466533, lng: 19.0636269 },
    { lat: 48.2466138, lng: 19.0634718 },
    { lat: 48.2465673, lng: 19.0631771 },
    { lat: 48.2466543, lng: 19.0631131 },
    { lat: 48.2467248, lng: 19.062698 },
    { lat: 48.246759, lng: 19.0626822 },
    { lat: 48.246607, lng: 19.0620612 },
    { lat: 48.2466661, lng: 19.0620022 },
    { lat: 48.2468506, lng: 19.0614161 },
    { lat: 48.2469683, lng: 19.0612439 },
    { lat: 48.2469331, lng: 19.0611736 },
    { lat: 48.2471016, lng: 19.0606459 },
    { lat: 48.2471159, lng: 19.0602835 },
    { lat: 48.2471833, lng: 19.060255 },
    { lat: 48.2472558, lng: 19.0597926 },
    { lat: 48.2469793, lng: 19.0594058 },
    { lat: 48.24653, lng: 19.0595058 },
    { lat: 48.246525, lng: 19.0594059 },
    { lat: 48.246302, lng: 19.0591018 },
    { lat: 48.2462494, lng: 19.0585949 },
    { lat: 48.2462646, lng: 19.0579352 },
    { lat: 48.2461859, lng: 19.057247 },
    { lat: 48.2460469, lng: 19.0569274 },
    { lat: 48.2460219, lng: 19.0566801 },
    { lat: 48.2459859, lng: 19.0566792 },
    { lat: 48.2457409, lng: 19.0548623 },
    { lat: 48.245702, lng: 19.0548554 },
    { lat: 48.2456793, lng: 19.0546058 },
    { lat: 48.2457249, lng: 19.0540552 },
    { lat: 48.2456903, lng: 19.0540856 },
    { lat: 48.2455349, lng: 19.0536657 },
    { lat: 48.245378, lng: 19.0536838 },
    { lat: 48.2451534, lng: 19.0525215 },
    { lat: 48.2448577, lng: 19.0521337 },
    { lat: 48.2443998, lng: 19.0517742 },
    { lat: 48.2444367, lng: 19.0516756 },
    { lat: 48.2442226, lng: 19.0515021 },
    { lat: 48.2441589, lng: 19.0513119 },
    { lat: 48.2439329, lng: 19.0511883 },
    { lat: 48.2438816, lng: 19.0512256 },
    { lat: 48.2438856, lng: 19.0513454 },
    { lat: 48.2435202, lng: 19.0515445 },
    { lat: 48.2433166, lng: 19.051331 },
    { lat: 48.2432364, lng: 19.0508371 },
    { lat: 48.24306, lng: 19.0503813 },
    { lat: 48.2424747, lng: 19.0499528 },
    { lat: 48.2421928, lng: 19.0496629 },
    { lat: 48.2416205, lng: 19.048446 },
    { lat: 48.2411729, lng: 19.0481119 },
    { lat: 48.2406326, lng: 19.0486209 },
    { lat: 48.2405685, lng: 19.0486016 },
    { lat: 48.2400707, lng: 19.0481424 },
    { lat: 48.2394415, lng: 19.047964 },
    { lat: 48.2390661, lng: 19.0476529 },
    { lat: 48.2388469, lng: 19.0465272 },
    { lat: 48.2384218, lng: 19.0459038 },
    { lat: 48.2379899, lng: 19.045592 },
    { lat: 48.2376504, lng: 19.0447177 },
    { lat: 48.2372576, lng: 19.0445101 },
    { lat: 48.2368281, lng: 19.0446454 },
    { lat: 48.236322, lng: 19.0450473 },
    { lat: 48.2358204, lng: 19.0450264 },
    { lat: 48.2352409, lng: 19.0444499 },
    { lat: 48.2348534, lng: 19.0441441 },
    { lat: 48.2344735, lng: 19.0439675 },
    { lat: 48.2340992, lng: 19.0437009 },
    { lat: 48.233996, lng: 19.0435524 },
    { lat: 48.2338563, lng: 19.0431304 },
    { lat: 48.233456, lng: 19.0425495 },
    { lat: 48.2328541, lng: 19.042244 },
    { lat: 48.2326545, lng: 19.0423098 },
    { lat: 48.2322062, lng: 19.0419855 },
    { lat: 48.2314563, lng: 19.0410472 },
    { lat: 48.231347, lng: 19.0408258 },
    { lat: 48.2312071, lng: 19.0400216 },
    { lat: 48.2300579, lng: 19.0383893 },
    { lat: 48.2298614, lng: 19.038273 },
    { lat: 48.2296837, lng: 19.0382585 },
    { lat: 48.2292688, lng: 19.0383926 },
    { lat: 48.2287147, lng: 19.038895 },
    { lat: 48.2285323, lng: 19.0389231 },
    { lat: 48.2283919, lng: 19.0388422 },
    { lat: 48.2282347, lng: 19.0385418 },
    { lat: 48.2277812, lng: 19.0379621 },
    { lat: 48.2276466, lng: 19.0376184 },
    { lat: 48.2275434, lng: 19.0370732 },
    { lat: 48.2276791, lng: 19.036567 },
    { lat: 48.2276095, lng: 19.0363497 },
    { lat: 48.2272757, lng: 19.0359265 },
    { lat: 48.2269419, lng: 19.0357028 },
    { lat: 48.2269174, lng: 19.0356864 },
  ],
  Čekovce: [
    { lat: 48.389737, lng: 19.170143 },
    { lat: 48.389717, lng: 19.170119 },
    { lat: 48.389678, lng: 19.170089 },
    { lat: 48.389656, lng: 19.17008 },
    { lat: 48.389616, lng: 19.17008 },
    { lat: 48.389456, lng: 19.170081 },
    { lat: 48.389032, lng: 19.170184 },
    { lat: 48.388825, lng: 19.170129 },
    { lat: 48.388794, lng: 19.170107 },
    { lat: 48.388794, lng: 19.17005 },
    { lat: 48.389082, lng: 19.169332 },
    { lat: 48.389221, lng: 19.168597 },
    { lat: 48.389244, lng: 19.168461 },
    { lat: 48.389269, lng: 19.168225 },
    { lat: 48.388739, lng: 19.16744 },
    { lat: 48.388361, lng: 19.166873 },
    { lat: 48.388368, lng: 19.166872 },
    { lat: 48.388152, lng: 19.166282 },
    { lat: 48.388001, lng: 19.16589 },
    { lat: 48.387994, lng: 19.165877 },
    { lat: 48.387739, lng: 19.165576 },
    { lat: 48.38767, lng: 19.165495 },
    { lat: 48.387239, lng: 19.165114 },
    { lat: 48.386861, lng: 19.164739 },
    { lat: 48.386754, lng: 19.164586 },
    { lat: 48.386571, lng: 19.164329 },
    { lat: 48.386279, lng: 19.163959 },
    { lat: 48.386179, lng: 19.163684 },
    { lat: 48.386123, lng: 19.163557 },
    { lat: 48.385997, lng: 19.163268 },
    { lat: 48.385831, lng: 19.162891 },
    { lat: 48.385818, lng: 19.162856 },
    { lat: 48.385763, lng: 19.162789 },
    { lat: 48.385578, lng: 19.162583 },
    { lat: 48.385498, lng: 19.1625 },
    { lat: 48.385269, lng: 19.162273 },
    { lat: 48.385164, lng: 19.16195 },
    { lat: 48.385009, lng: 19.161611 },
    { lat: 48.384917, lng: 19.161475 },
    { lat: 48.384725, lng: 19.161215 },
    { lat: 48.384543, lng: 19.161049 },
    { lat: 48.384216, lng: 19.160695 },
    { lat: 48.383815, lng: 19.160207 },
    { lat: 48.383743, lng: 19.160135 },
    { lat: 48.383244, lng: 19.159705 },
    { lat: 48.383677, lng: 19.159166 },
    { lat: 48.383934, lng: 19.158629 },
    { lat: 48.384063, lng: 19.1586 },
    { lat: 48.38421, lng: 19.158579 },
    { lat: 48.384858, lng: 19.158423 },
    { lat: 48.38529, lng: 19.15832 },
    { lat: 48.385699, lng: 19.15823 },
    { lat: 48.385974, lng: 19.158121 },
    { lat: 48.38625, lng: 19.158035 },
    { lat: 48.386237, lng: 19.157456 },
    { lat: 48.38632, lng: 19.157249 },
    { lat: 48.386345, lng: 19.157218 },
    { lat: 48.38642, lng: 19.157163 },
    { lat: 48.386587, lng: 19.157061 },
    { lat: 48.386659, lng: 19.157021 },
    { lat: 48.386816, lng: 19.156919 },
    { lat: 48.386921, lng: 19.156946 },
    { lat: 48.387274, lng: 19.156988 },
    { lat: 48.387423, lng: 19.156754 },
    { lat: 48.387618, lng: 19.15616 },
    { lat: 48.387757, lng: 19.155729 },
    { lat: 48.38778, lng: 19.155365 },
    { lat: 48.38805, lng: 19.155588 },
    { lat: 48.388358, lng: 19.155981 },
    { lat: 48.38849, lng: 19.156319 },
    { lat: 48.388593, lng: 19.156519 },
    { lat: 48.388693, lng: 19.156649 },
    { lat: 48.389049, lng: 19.156915 },
    { lat: 48.389156, lng: 19.156957 },
    { lat: 48.389375, lng: 19.157023 },
    { lat: 48.38939, lng: 19.157035 },
    { lat: 48.389534, lng: 19.157174 },
    { lat: 48.389606, lng: 19.157248 },
    { lat: 48.389895, lng: 19.157427 },
    { lat: 48.390059, lng: 19.157554 },
    { lat: 48.390256, lng: 19.157697 },
    { lat: 48.390369, lng: 19.157776 },
    { lat: 48.390522, lng: 19.157883 },
    { lat: 48.390668, lng: 19.157942 },
    { lat: 48.390806, lng: 19.157858 },
    { lat: 48.39084, lng: 19.157855 },
    { lat: 48.390839, lng: 19.157833 },
    { lat: 48.390838, lng: 19.157796 },
    { lat: 48.390755, lng: 19.157534 },
    { lat: 48.390659, lng: 19.157226 },
    { lat: 48.390578, lng: 19.15698 },
    { lat: 48.390455, lng: 19.156615 },
    { lat: 48.390433, lng: 19.156539 },
    { lat: 48.390389, lng: 19.156379 },
    { lat: 48.390382, lng: 19.156356 },
    { lat: 48.390367, lng: 19.156302 },
    { lat: 48.390362, lng: 19.155871 },
    { lat: 48.390349, lng: 19.155689 },
    { lat: 48.390307, lng: 19.15543 },
    { lat: 48.390205, lng: 19.155112 },
    { lat: 48.390094, lng: 19.154698 },
    { lat: 48.39005, lng: 19.154552 },
    { lat: 48.390001, lng: 19.154453 },
    { lat: 48.389986, lng: 19.15438 },
    { lat: 48.389945, lng: 19.154221 },
    { lat: 48.389915, lng: 19.154038 },
    { lat: 48.389843, lng: 19.153737 },
    { lat: 48.389835, lng: 19.153571 },
    { lat: 48.389808, lng: 19.153359 },
    { lat: 48.389784, lng: 19.15317 },
    { lat: 48.38974, lng: 19.153094 },
    { lat: 48.389686, lng: 19.152998 },
    { lat: 48.389596, lng: 19.152928 },
    { lat: 48.389532, lng: 19.152859 },
    { lat: 48.389456, lng: 19.152809 },
    { lat: 48.389399, lng: 19.15275 },
    { lat: 48.389327, lng: 19.152697 },
    { lat: 48.389279, lng: 19.152628 },
    { lat: 48.389163, lng: 19.152508 },
    { lat: 48.389124, lng: 19.152485 },
    { lat: 48.389089, lng: 19.152436 },
    { lat: 48.389021, lng: 19.152346 },
    { lat: 48.388895, lng: 19.152194 },
    { lat: 48.388815, lng: 19.152138 },
    { lat: 48.388714, lng: 19.152002 },
    { lat: 48.388505, lng: 19.151751 },
    { lat: 48.388383, lng: 19.151592 },
    { lat: 48.388158, lng: 19.151115 },
    { lat: 48.38809, lng: 19.150966 },
    { lat: 48.388085, lng: 19.150956 },
    { lat: 48.388076, lng: 19.150913 },
    { lat: 48.388007, lng: 19.150482 },
    { lat: 48.387996, lng: 19.150399 },
    { lat: 48.388002, lng: 19.15028 },
    { lat: 48.388013, lng: 19.150032 },
    { lat: 48.388024, lng: 19.149705 },
    { lat: 48.388024, lng: 19.148465 },
    { lat: 48.388027, lng: 19.148353 },
    { lat: 48.388059, lng: 19.148135 },
    { lat: 48.388119, lng: 19.147991 },
    { lat: 48.38813, lng: 19.147964 },
    { lat: 48.388139, lng: 19.147927 },
    { lat: 48.388148, lng: 19.147802 },
    { lat: 48.388262, lng: 19.14777 },
    { lat: 48.38838, lng: 19.147766 },
    { lat: 48.38845, lng: 19.147232 },
    { lat: 48.388513, lng: 19.147084 },
    { lat: 48.38865, lng: 19.146634 },
    { lat: 48.3886351, lng: 19.1465764 },
    { lat: 48.388614, lng: 19.146599 },
    { lat: 48.3884986, lng: 19.1465257 },
    { lat: 48.3883682, lng: 19.1463466 },
    { lat: 48.3882573, lng: 19.1463675 },
    { lat: 48.3882081, lng: 19.1464658 },
    { lat: 48.3877679, lng: 19.1464057 },
    { lat: 48.3866896, lng: 19.1456556 },
    { lat: 48.385175, lng: 19.143717 },
    { lat: 48.385202, lng: 19.143652 },
    { lat: 48.385315, lng: 19.143405 },
    { lat: 48.385519, lng: 19.142708 },
    { lat: 48.385556, lng: 19.142583 },
    { lat: 48.385668, lng: 19.141742 },
    { lat: 48.385696, lng: 19.14154 },
    { lat: 48.385789, lng: 19.140927 },
    { lat: 48.385934, lng: 19.140039 },
    { lat: 48.385821, lng: 19.139867 },
    { lat: 48.385701, lng: 19.139746 },
    { lat: 48.385886, lng: 19.139092 },
    { lat: 48.386034, lng: 19.13856 },
    { lat: 48.385991, lng: 19.1382 },
    { lat: 48.386115, lng: 19.138288 },
    { lat: 48.386109, lng: 19.138069 },
    { lat: 48.38613, lng: 19.137583 },
    { lat: 48.386142, lng: 19.137059 },
    { lat: 48.386098, lng: 19.136495 },
    { lat: 48.385937, lng: 19.136354 },
    { lat: 48.385934, lng: 19.136303 },
    { lat: 48.385976, lng: 19.135504 },
    { lat: 48.385905, lng: 19.135176 },
    { lat: 48.385875, lng: 19.135049 },
    { lat: 48.385886, lng: 19.134811 },
    { lat: 48.385913, lng: 19.134442 },
    { lat: 48.386298, lng: 19.132864 },
    { lat: 48.386548, lng: 19.131986 },
    { lat: 48.386489, lng: 19.131616 },
    { lat: 48.386474, lng: 19.13137 },
    { lat: 48.386404, lng: 19.13106 },
    { lat: 48.38639, lng: 19.131006 },
    { lat: 48.386591, lng: 19.12967 },
    { lat: 48.386577, lng: 19.129495 },
    { lat: 48.386515, lng: 19.129216 },
    { lat: 48.386509, lng: 19.129115 },
    { lat: 48.386509, lng: 19.129082 },
    { lat: 48.386511, lng: 19.129015 },
    { lat: 48.386515, lng: 19.128947 },
    { lat: 48.386532, lng: 19.128459 },
    { lat: 48.386857, lng: 19.128512 },
    { lat: 48.386871, lng: 19.128264 },
    { lat: 48.38694, lng: 19.127774 },
    { lat: 48.387298, lng: 19.12628 },
    { lat: 48.387353, lng: 19.12619 },
    { lat: 48.3871614, lng: 19.1260554 },
    { lat: 48.3870802, lng: 19.1258534 },
    { lat: 48.3868911, lng: 19.1257466 },
    { lat: 48.3868249, lng: 19.1255036 },
    { lat: 48.3865457, lng: 19.1252856 },
    { lat: 48.38653, lng: 19.1251934 },
    { lat: 48.3866017, lng: 19.1250045 },
    { lat: 48.3865798, lng: 19.1249019 },
    { lat: 48.3864951, lng: 19.1248341 },
    { lat: 48.386346, lng: 19.1248519 },
    { lat: 48.3862445, lng: 19.124759 },
    { lat: 48.3859642, lng: 19.1241531 },
    { lat: 48.3858083, lng: 19.1241908 },
    { lat: 48.3857401, lng: 19.1241346 },
    { lat: 48.3857051, lng: 19.1237984 },
    { lat: 48.3855928, lng: 19.1236681 },
    { lat: 48.3850844, lng: 19.1235116 },
    { lat: 48.3850187, lng: 19.1233668 },
    { lat: 48.3848548, lng: 19.1232923 },
    { lat: 48.3847427, lng: 19.1233705 },
    { lat: 48.3846575, lng: 19.1233339 },
    { lat: 48.3846055, lng: 19.1230404 },
    { lat: 48.3844891, lng: 19.1227619 },
    { lat: 48.3843149, lng: 19.1227737 },
    { lat: 48.3841776, lng: 19.1228682 },
    { lat: 48.3838952, lng: 19.1227899 },
    { lat: 48.3837821, lng: 19.1226242 },
    { lat: 48.3835232, lng: 19.122809 },
    { lat: 48.3833357, lng: 19.1225782 },
    { lat: 48.3830889, lng: 19.1226037 },
    { lat: 48.3828489, lng: 19.122447 },
    { lat: 48.3827678, lng: 19.1223376 },
    { lat: 48.3827487, lng: 19.122134 },
    { lat: 48.3826822, lng: 19.1220408 },
    { lat: 48.3825357, lng: 19.1219554 },
    { lat: 48.382359, lng: 19.121959 },
    { lat: 48.3821966, lng: 19.1215729 },
    { lat: 48.3821837, lng: 19.1214041 },
    { lat: 48.382103, lng: 19.1212784 },
    { lat: 48.3819923, lng: 19.1212745 },
    { lat: 48.3817431, lng: 19.1215223 },
    { lat: 48.3815018, lng: 19.1213412 },
    { lat: 48.3811871, lng: 19.1213592 },
    { lat: 48.381128, lng: 19.1212828 },
    { lat: 48.3811493, lng: 19.1208935 },
    { lat: 48.380814, lng: 19.1208169 },
    { lat: 48.3807711, lng: 19.120814 },
    { lat: 48.3806103, lng: 19.1211182 },
    { lat: 48.380529, lng: 19.1210947 },
    { lat: 48.380384, lng: 19.1205783 },
    { lat: 48.3803039, lng: 19.1204639 },
    { lat: 48.379841, lng: 19.1206322 },
    { lat: 48.3795689, lng: 19.1204425 },
    { lat: 48.3794805, lng: 19.1204797 },
    { lat: 48.3794437, lng: 19.1207796 },
    { lat: 48.3789936, lng: 19.1203471 },
    { lat: 48.3787811, lng: 19.1206777 },
    { lat: 48.3787187, lng: 19.1206425 },
    { lat: 48.3785875, lng: 19.1201806 },
    { lat: 48.3781032, lng: 19.120058 },
    { lat: 48.3779022, lng: 19.1199348 },
    { lat: 48.3777062, lng: 19.1196667 },
    { lat: 48.3775444, lng: 19.1195675 },
    { lat: 48.3774273, lng: 19.1194112 },
    { lat: 48.3770737, lng: 19.119465 },
    { lat: 48.3768329, lng: 19.1194062 },
    { lat: 48.3762457, lng: 19.1194516 },
    { lat: 48.3757358, lng: 19.1197386 },
    { lat: 48.3756705, lng: 19.1199934 },
    { lat: 48.375426, lng: 19.1200645 },
    { lat: 48.3751899, lng: 19.119986 },
    { lat: 48.374988, lng: 19.1197133 },
    { lat: 48.3746375, lng: 19.1195519 },
    { lat: 48.3745557, lng: 19.1192718 },
    { lat: 48.3741078, lng: 19.1187412 },
    { lat: 48.3742033, lng: 19.1185096 },
    { lat: 48.3739375, lng: 19.1180622 },
    { lat: 48.3737712, lng: 19.1179581 },
    { lat: 48.3729989, lng: 19.116602 },
    { lat: 48.3728164, lng: 19.1164481 },
    { lat: 48.3725285, lng: 19.116346 },
    { lat: 48.3722898, lng: 19.1163533 },
    { lat: 48.3720943, lng: 19.1157757 },
    { lat: 48.3715756, lng: 19.1155265 },
    { lat: 48.3713038, lng: 19.1155244 },
    { lat: 48.3711946, lng: 19.1154612 },
    { lat: 48.3710182, lng: 19.1151555 },
    { lat: 48.3705986, lng: 19.1147885 },
    { lat: 48.3704624, lng: 19.114863 },
    { lat: 48.3703749, lng: 19.1148228 },
    { lat: 48.3702967, lng: 19.1147277 },
    { lat: 48.3701358, lng: 19.1141689 },
    { lat: 48.370034, lng: 19.1140935 },
    { lat: 48.3698295, lng: 19.1141749 },
    { lat: 48.3696273, lng: 19.1137821 },
    { lat: 48.3694584, lng: 19.113627 },
    { lat: 48.3692521, lng: 19.1136955 },
    { lat: 48.3691417, lng: 19.1136055 },
    { lat: 48.3689908, lng: 19.1129328 },
    { lat: 48.3685713, lng: 19.112864 },
    { lat: 48.3684336, lng: 19.1129263 },
    { lat: 48.3683155, lng: 19.1131342 },
    { lat: 48.3681166, lng: 19.1126513 },
    { lat: 48.3680144, lng: 19.1126153 },
    { lat: 48.3678768, lng: 19.1127453 },
    { lat: 48.3672849, lng: 19.1124069 },
    { lat: 48.367039, lng: 19.1120654 },
    { lat: 48.3669047, lng: 19.111705 },
    { lat: 48.3667564, lng: 19.1117809 },
    { lat: 48.3667146, lng: 19.1117339 },
    { lat: 48.3666423, lng: 19.111346 },
    { lat: 48.3667202, lng: 19.1109419 },
    { lat: 48.3665242, lng: 19.1106267 },
    { lat: 48.3665605, lng: 19.1104057 },
    { lat: 48.3666532, lng: 19.1102524 },
    { lat: 48.3665733, lng: 19.1100281 },
    { lat: 48.3665247, lng: 19.1096889 },
    { lat: 48.3664576, lng: 19.109477 },
    { lat: 48.3662357, lng: 19.1094786 },
    { lat: 48.3660904, lng: 19.1089822 },
    { lat: 48.3659121, lng: 19.1086431 },
    { lat: 48.3658182, lng: 19.1085878 },
    { lat: 48.3653912, lng: 19.1086609 },
    { lat: 48.3652558, lng: 19.1086141 },
    { lat: 48.3650547, lng: 19.1083797 },
    { lat: 48.3650087, lng: 19.1081353 },
    { lat: 48.36483, lng: 19.1079678 },
    { lat: 48.3645179, lng: 19.1079955 },
    { lat: 48.364352, lng: 19.108079 },
    { lat: 48.3641063, lng: 19.1086001 },
    { lat: 48.3635873, lng: 19.1085493 },
    { lat: 48.3633778, lng: 19.1087352 },
    { lat: 48.3629841, lng: 19.109313 },
    { lat: 48.3626744, lng: 19.1094563 },
    { lat: 48.3625386, lng: 19.1096268 },
    { lat: 48.3624656, lng: 19.1096092 },
    { lat: 48.3621353, lng: 19.1091581 },
    { lat: 48.3619606, lng: 19.1088013 },
    { lat: 48.3618731, lng: 19.1083542 },
    { lat: 48.3616168, lng: 19.1082592 },
    { lat: 48.3615573, lng: 19.1081507 },
    { lat: 48.3615814, lng: 19.107707 },
    { lat: 48.3614547, lng: 19.10727 },
    { lat: 48.3611439, lng: 19.1070064 },
    { lat: 48.3608954, lng: 19.1063885 },
    { lat: 48.3604029, lng: 19.1058685 },
    { lat: 48.3603741, lng: 19.1056324 },
    { lat: 48.3604939, lng: 19.1052549 },
    { lat: 48.3604899, lng: 19.1051263 },
    { lat: 48.3604104, lng: 19.1049587 },
    { lat: 48.3600669, lng: 19.1044727 },
    { lat: 48.3599483, lng: 19.1043967 },
    { lat: 48.3597219, lng: 19.1044707 },
    { lat: 48.3595978, lng: 19.1043986 },
    { lat: 48.3596488, lng: 19.1038388 },
    { lat: 48.3592227, lng: 19.1034578 },
    { lat: 48.3591628, lng: 19.1032993 },
    { lat: 48.359133, lng: 19.1030141 },
    { lat: 48.3591806, lng: 19.1025256 },
    { lat: 48.3589214, lng: 19.1021879 },
    { lat: 48.3589512, lng: 19.1017502 },
    { lat: 48.3587397, lng: 19.1015553 },
    { lat: 48.3586903, lng: 19.101356 },
    { lat: 48.3584232, lng: 19.1007902 },
    { lat: 48.3583191, lng: 19.1004463 },
    { lat: 48.3583291, lng: 19.0998997 },
    { lat: 48.3582502, lng: 19.0997026 },
    { lat: 48.3581374, lng: 19.0996093 },
    { lat: 48.3579882, lng: 19.0996215 },
    { lat: 48.3577312, lng: 19.0997596 },
    { lat: 48.3576385, lng: 19.0996871 },
    { lat: 48.3575158, lng: 19.0992649 },
    { lat: 48.3575847, lng: 19.09888 },
    { lat: 48.357441, lng: 19.0988029 },
    { lat: 48.3572093, lng: 19.098951 },
    { lat: 48.3571358, lng: 19.0988979 },
    { lat: 48.3570716, lng: 19.0987194 },
    { lat: 48.3571389, lng: 19.0985594 },
    { lat: 48.3571193, lng: 19.098322 },
    { lat: 48.3570478, lng: 19.0981538 },
    { lat: 48.3569499, lng: 19.0981376 },
    { lat: 48.3568227, lng: 19.0983859 },
    { lat: 48.3567125, lng: 19.0983944 },
    { lat: 48.3564847, lng: 19.0981237 },
    { lat: 48.3562559, lng: 19.098013 },
    { lat: 48.3561821, lng: 19.0976842 },
    { lat: 48.3560691, lng: 19.0976345 },
    { lat: 48.3558108, lng: 19.0976771 },
    { lat: 48.3554905, lng: 19.0976309 },
    { lat: 48.3551889, lng: 19.0974798 },
    { lat: 48.3550411, lng: 19.0977046 },
    { lat: 48.3548722, lng: 19.0977098 },
    { lat: 48.3546253, lng: 19.0974813 },
    { lat: 48.3546113, lng: 19.097273 },
    { lat: 48.3545092, lng: 19.097102 },
    { lat: 48.3543628, lng: 19.0971702 },
    { lat: 48.3542573, lng: 19.0971346 },
    { lat: 48.3542467, lng: 19.0969922 },
    { lat: 48.3543183, lng: 19.096775 },
    { lat: 48.3542503, lng: 19.0964974 },
    { lat: 48.3541327, lng: 19.0964036 },
    { lat: 48.3538704, lng: 19.0963643 },
    { lat: 48.3537246, lng: 19.0961996 },
    { lat: 48.3537668, lng: 19.0958954 },
    { lat: 48.3537361, lng: 19.0957779 },
    { lat: 48.3535209, lng: 19.0957115 },
    { lat: 48.3531726, lng: 19.095985 },
    { lat: 48.3529812, lng: 19.0959647 },
    { lat: 48.3525615, lng: 19.0956448 },
    { lat: 48.3523231, lng: 19.09514 },
    { lat: 48.3521914, lng: 19.0950761 },
    { lat: 48.3520127, lng: 19.0951537 },
    { lat: 48.3518042, lng: 19.0948167 },
    { lat: 48.3517455, lng: 19.0942048 },
    { lat: 48.3517948, lng: 19.0939054 },
    { lat: 48.3517557, lng: 19.093532 },
    { lat: 48.3515631, lng: 19.0933549 },
    { lat: 48.3513622, lng: 19.0934622 },
    { lat: 48.3509457, lng: 19.0928899 },
    { lat: 48.350818, lng: 19.0923461 },
    { lat: 48.3506508, lng: 19.0920456 },
    { lat: 48.3502085, lng: 19.0916333 },
    { lat: 48.3500366, lng: 19.0911256 },
    { lat: 48.3499481, lng: 19.091048 },
    { lat: 48.3496507, lng: 19.0910061 },
    { lat: 48.3494172, lng: 19.0908243 },
    { lat: 48.3493244, lng: 19.0907065 },
    { lat: 48.34901, lng: 19.0899197 },
    { lat: 48.3486148, lng: 19.0896148 },
    { lat: 48.3483652, lng: 19.0890652 },
    { lat: 48.3481681, lng: 19.0887986 },
    { lat: 48.3480346, lng: 19.0887481 },
    { lat: 48.3479496, lng: 19.0887158 },
    { lat: 48.3476572, lng: 19.089178 },
    { lat: 48.3473606, lng: 19.0898746 },
    { lat: 48.3469156, lng: 19.0904893 },
    { lat: 48.3469873, lng: 19.0909537 },
    { lat: 48.3466948, lng: 19.0934867 },
    { lat: 48.3463543, lng: 19.0943217 },
    { lat: 48.346002, lng: 19.0949615 },
    { lat: 48.3453998, lng: 19.0963778 },
    { lat: 48.3447299, lng: 19.097386 },
    { lat: 48.3443938, lng: 19.0977467 },
    { lat: 48.3441945, lng: 19.0978606 },
    { lat: 48.3442428, lng: 19.09833 },
    { lat: 48.343352, lng: 19.0986681 },
    { lat: 48.3429064, lng: 19.0991573 },
    { lat: 48.3419576, lng: 19.0988073 },
    { lat: 48.341216, lng: 19.0983575 },
    { lat: 48.3402858, lng: 19.097908 },
    { lat: 48.3398393, lng: 19.099257 },
    { lat: 48.3398285, lng: 19.0999201 },
    { lat: 48.3398782, lng: 19.1004645 },
    { lat: 48.3401748, lng: 19.1008922 },
    { lat: 48.3396675, lng: 19.1015049 },
    { lat: 48.3378684, lng: 19.1029067 },
    { lat: 48.3376869, lng: 19.1038548 },
    { lat: 48.3372221, lng: 19.1041175 },
    { lat: 48.3370847, lng: 19.1037847 },
    { lat: 48.3341702, lng: 19.1098038 },
    { lat: 48.3333951, lng: 19.1091783 },
    { lat: 48.3330738, lng: 19.1099269 },
    { lat: 48.3330173, lng: 19.110058 },
    { lat: 48.336401, lng: 19.1136141 },
    { lat: 48.3364783, lng: 19.114054 },
    { lat: 48.3371697, lng: 19.1154398 },
    { lat: 48.3371299, lng: 19.1154815 },
    { lat: 48.3376845, lng: 19.1163604 },
    { lat: 48.3387123, lng: 19.1176188 },
    { lat: 48.3388628, lng: 19.1177433 },
    { lat: 48.3394242, lng: 19.1188486 },
    { lat: 48.3397568, lng: 19.1193939 },
    { lat: 48.3400052, lng: 19.1199882 },
    { lat: 48.340488, lng: 19.1215563 },
    { lat: 48.3410427, lng: 19.1221919 },
    { lat: 48.3427062, lng: 19.1245561 },
    { lat: 48.342934, lng: 19.1247861 },
    { lat: 48.3431005, lng: 19.1250988 },
    { lat: 48.3454299, lng: 19.126571 },
    { lat: 48.345882, lng: 19.1269347 },
    { lat: 48.3464734, lng: 19.1272891 },
    { lat: 48.3469511, lng: 19.1274181 },
    { lat: 48.3481914, lng: 19.1282637 },
    { lat: 48.348375, lng: 19.1283264 },
    { lat: 48.349012, lng: 19.1288693 },
    { lat: 48.3495918, lng: 19.1291701 },
    { lat: 48.3497365, lng: 19.1293155 },
    { lat: 48.3499097, lng: 19.1296955 },
    { lat: 48.350386, lng: 19.130272 },
    { lat: 48.3510871, lng: 19.130589 },
    { lat: 48.3522738, lng: 19.131965 },
    { lat: 48.3524903, lng: 19.1320081 },
    { lat: 48.3527543, lng: 19.1321731 },
    { lat: 48.3536534, lng: 19.132249 },
    { lat: 48.3543864, lng: 19.1329602 },
    { lat: 48.3548507, lng: 19.1333194 },
    { lat: 48.3556629, lng: 19.134328 },
    { lat: 48.3556159, lng: 19.1347406 },
    { lat: 48.3559392, lng: 19.135034 },
    { lat: 48.3560477, lng: 19.1351261 },
    { lat: 48.3565194, lng: 19.1352729 },
    { lat: 48.3581055, lng: 19.1363382 },
    { lat: 48.3594256, lng: 19.1372944 },
    { lat: 48.3601554, lng: 19.1379795 },
    { lat: 48.3599542, lng: 19.1384909 },
    { lat: 48.3601981, lng: 19.1387745 },
    { lat: 48.3605251, lng: 19.1389793 },
    { lat: 48.3607472, lng: 19.1394568 },
    { lat: 48.3611472, lng: 19.1399037 },
    { lat: 48.3613887, lng: 19.1405006 },
    { lat: 48.3621066, lng: 19.1408614 },
    { lat: 48.3628502, lng: 19.1413752 },
    { lat: 48.3637201, lng: 19.1424997 },
    { lat: 48.3640547, lng: 19.1419319 },
    { lat: 48.364672, lng: 19.1422369 },
    { lat: 48.3650439, lng: 19.1426226 },
    { lat: 48.3652179, lng: 19.1432221 },
    { lat: 48.3663736, lng: 19.144337 },
    { lat: 48.3670275, lng: 19.145113 },
    { lat: 48.3681092, lng: 19.1462381 },
    { lat: 48.3679495, lng: 19.1465193 },
    { lat: 48.3669501, lng: 19.1454159 },
    { lat: 48.3666982, lng: 19.1459277 },
    { lat: 48.3672634, lng: 19.1465732 },
    { lat: 48.3676922, lng: 19.1472192 },
    { lat: 48.3686167, lng: 19.1483122 },
    { lat: 48.3697447, lng: 19.1488464 },
    { lat: 48.3698905, lng: 19.1492425 },
    { lat: 48.3701674, lng: 19.149687 },
    { lat: 48.370489, lng: 19.1499883 },
    { lat: 48.3717543, lng: 19.1507288 },
    { lat: 48.3722686, lng: 19.151393 },
    { lat: 48.3723914, lng: 19.1521412 },
    { lat: 48.3727734, lng: 19.1530471 },
    { lat: 48.3732697, lng: 19.153576 },
    { lat: 48.374033, lng: 19.1542653 },
    { lat: 48.375213, lng: 19.1559881 },
    { lat: 48.3758148, lng: 19.1564636 },
    { lat: 48.3763166, lng: 19.1569664 },
    { lat: 48.3769844, lng: 19.1577288 },
    { lat: 48.3775881, lng: 19.1585388 },
    { lat: 48.3784159, lng: 19.160255 },
    { lat: 48.378807, lng: 19.1616909 },
    { lat: 48.3797583, lng: 19.163326 },
    { lat: 48.3802106, lng: 19.1644048 },
    { lat: 48.3797246, lng: 19.1649064 },
    { lat: 48.3799585, lng: 19.1656718 },
    { lat: 48.3801899, lng: 19.1661759 },
    { lat: 48.3806565, lng: 19.165881 },
    { lat: 48.3814149, lng: 19.1671743 },
    { lat: 48.3801023, lng: 19.1696562 },
    { lat: 48.3791574, lng: 19.1713166 },
    { lat: 48.3786322, lng: 19.1718701 },
    { lat: 48.3782126, lng: 19.1717965 },
    { lat: 48.3782413, lng: 19.1718951 },
    { lat: 48.3793009, lng: 19.1731953 },
    { lat: 48.3798932, lng: 19.1738493 },
    { lat: 48.3800659, lng: 19.1739635 },
    { lat: 48.3804796, lng: 19.1740558 },
    { lat: 48.3805413, lng: 19.1738887 },
    { lat: 48.3814371, lng: 19.1740869 },
    { lat: 48.3818166, lng: 19.1743334 },
    { lat: 48.3819546, lng: 19.174617 },
    { lat: 48.3817439, lng: 19.1761501 },
    { lat: 48.3822684, lng: 19.1770716 },
    { lat: 48.3824562, lng: 19.177426 },
    { lat: 48.3825908, lng: 19.1774348 },
    { lat: 48.3828418, lng: 19.177131 },
    { lat: 48.3832318, lng: 19.1768089 },
    { lat: 48.384357, lng: 19.1769272 },
    { lat: 48.3851364, lng: 19.176865 },
    { lat: 48.3856246, lng: 19.1766877 },
    { lat: 48.386019, lng: 19.1752909 },
    { lat: 48.3866168, lng: 19.1742449 },
    { lat: 48.3870532, lng: 19.1735849 },
    { lat: 48.3876894, lng: 19.1729968 },
    { lat: 48.387579, lng: 19.1728143 },
    { lat: 48.3887908, lng: 19.1719039 },
    { lat: 48.3891189, lng: 19.1710353 },
    { lat: 48.389737, lng: 19.170143 },
  ],
  ČabradskýVrbovok: [
    { lat: 48.2673102, lng: 19.1268073 },
    { lat: 48.2673212, lng: 19.1266878 },
    { lat: 48.2673354, lng: 19.1265889 },
    { lat: 48.2670471, lng: 19.124697 },
    { lat: 48.2668496, lng: 19.1238582 },
    { lat: 48.2659025, lng: 19.1231327 },
    { lat: 48.2653412, lng: 19.1229531 },
    { lat: 48.2654396, lng: 19.1225759 },
    { lat: 48.2655169, lng: 19.1215489 },
    { lat: 48.2659248, lng: 19.1195306 },
    { lat: 48.2648786, lng: 19.1185636 },
    { lat: 48.2655246, lng: 19.1168902 },
    { lat: 48.2669527, lng: 19.115013 },
    { lat: 48.2688343, lng: 19.1157122 },
    { lat: 48.2691411, lng: 19.1151715 },
    { lat: 48.2694676, lng: 19.1142403 },
    { lat: 48.2712641, lng: 19.1137098 },
    { lat: 48.2722749, lng: 19.1132069 },
    { lat: 48.2734575, lng: 19.1130459 },
    { lat: 48.2743065, lng: 19.1123718 },
    { lat: 48.2748544, lng: 19.1120998 },
    { lat: 48.2751849, lng: 19.1120574 },
    { lat: 48.2756535, lng: 19.111824 },
    { lat: 48.2762522, lng: 19.1117579 },
    { lat: 48.2768278, lng: 19.1108004 },
    { lat: 48.2771464, lng: 19.1101379 },
    { lat: 48.2772871, lng: 19.1095261 },
    { lat: 48.276892, lng: 19.1081388 },
    { lat: 48.2767398, lng: 19.1078031 },
    { lat: 48.2761003, lng: 19.1071883 },
    { lat: 48.2756164, lng: 19.1069559 },
    { lat: 48.2751441, lng: 19.1059915 },
    { lat: 48.2738186, lng: 19.1030001 },
    { lat: 48.2738047, lng: 19.1025838 },
    { lat: 48.2733352, lng: 19.1016962 },
    { lat: 48.2731313, lng: 19.1009774 },
    { lat: 48.2729524, lng: 19.1000193 },
    { lat: 48.2726497, lng: 19.0987921 },
    { lat: 48.2726751, lng: 19.0982658 },
    { lat: 48.2727881, lng: 19.0977959 },
    { lat: 48.2729927, lng: 19.0972679 },
    { lat: 48.2730311, lng: 19.096704 },
    { lat: 48.2729848, lng: 19.0961862 },
    { lat: 48.2728243, lng: 19.0956844 },
    { lat: 48.2723411, lng: 19.0949952 },
    { lat: 48.2716994, lng: 19.0944739 },
    { lat: 48.2712079, lng: 19.0938221 },
    { lat: 48.2710941, lng: 19.0935554 },
    { lat: 48.2712266, lng: 19.0929367 },
    { lat: 48.2713814, lng: 19.0925371 },
    { lat: 48.272264, lng: 19.0914115 },
    { lat: 48.2726639, lng: 19.090733 },
    { lat: 48.2730516, lng: 19.0901918 },
    { lat: 48.2735457, lng: 19.0897232 },
    { lat: 48.2736402, lng: 19.0895381 },
    { lat: 48.2742376, lng: 19.0890189 },
    { lat: 48.2743632, lng: 19.0888058 },
    { lat: 48.2742147, lng: 19.0887649 },
    { lat: 48.2741111, lng: 19.0886311 },
    { lat: 48.2738016, lng: 19.0879252 },
    { lat: 48.2737584, lng: 19.087154 },
    { lat: 48.2736979, lng: 19.08705 },
    { lat: 48.27336, lng: 19.0868315 },
    { lat: 48.2729636, lng: 19.0867519 },
    { lat: 48.2728425, lng: 19.0862698 },
    { lat: 48.272509, lng: 19.086204 },
    { lat: 48.2721005, lng: 19.0863785 },
    { lat: 48.2718918, lng: 19.0866027 },
    { lat: 48.2716737, lng: 19.0865441 },
    { lat: 48.2715562, lng: 19.0865856 },
    { lat: 48.2715004, lng: 19.0864613 },
    { lat: 48.2714652, lng: 19.0864993 },
    { lat: 48.2697163, lng: 19.0884971 },
    { lat: 48.2665486, lng: 19.0851529 },
    { lat: 48.2631507, lng: 19.0812968 },
    { lat: 48.2619396, lng: 19.078959 },
    { lat: 48.2617814, lng: 19.0768656 },
    { lat: 48.2610907, lng: 19.0754632 },
    { lat: 48.2602165, lng: 19.0738833 },
    { lat: 48.2600953, lng: 19.073494 },
    { lat: 48.258873, lng: 19.0736932 },
    { lat: 48.2586201, lng: 19.072095 },
    { lat: 48.2585268, lng: 19.0708654 },
    { lat: 48.2586067, lng: 19.0705698 },
    { lat: 48.2581033, lng: 19.0680521 },
    { lat: 48.2578643, lng: 19.0671355 },
    { lat: 48.2576001, lng: 19.0665246 },
    { lat: 48.2560161, lng: 19.0643131 },
    { lat: 48.255414, lng: 19.063392 },
    { lat: 48.2549161, lng: 19.0628225 },
    { lat: 48.2543803, lng: 19.0607798 },
    { lat: 48.2539419, lng: 19.0598456 },
    { lat: 48.2530236, lng: 19.0585401 },
    { lat: 48.2525346, lng: 19.0572727 },
    { lat: 48.2519857, lng: 19.056045 },
    { lat: 48.250494, lng: 19.0551972 },
    { lat: 48.2500477, lng: 19.0540846 },
    { lat: 48.2495383, lng: 19.0531942 },
    { lat: 48.2495305, lng: 19.0525902 },
    { lat: 48.2492161, lng: 19.0523948 },
    { lat: 48.2495107, lng: 19.0507254 },
    { lat: 48.2505385, lng: 19.0483523 },
    { lat: 48.2506066, lng: 19.0480273 },
    { lat: 48.2506052, lng: 19.0477328 },
    { lat: 48.2505503, lng: 19.0474273 },
    { lat: 48.2498043, lng: 19.0463881 },
    { lat: 48.249744, lng: 19.0461989 },
    { lat: 48.2482982, lng: 19.0444318 },
    { lat: 48.2471681, lng: 19.0434634 },
    { lat: 48.2469185, lng: 19.0433449 },
    { lat: 48.2459547, lng: 19.0425915 },
    { lat: 48.2455997, lng: 19.0422378 },
    { lat: 48.2431411, lng: 19.0418903 },
    { lat: 48.2427225, lng: 19.041883 },
    { lat: 48.2426571, lng: 19.0424854 },
    { lat: 48.2422072, lng: 19.0450243 },
    { lat: 48.2419422, lng: 19.0460005 },
    { lat: 48.2406315, lng: 19.048488 },
    { lat: 48.2405685, lng: 19.0486016 },
    { lat: 48.2406326, lng: 19.0486209 },
    { lat: 48.2411729, lng: 19.0481119 },
    { lat: 48.2416205, lng: 19.048446 },
    { lat: 48.2421928, lng: 19.0496629 },
    { lat: 48.2424747, lng: 19.0499528 },
    { lat: 48.24306, lng: 19.0503813 },
    { lat: 48.2432364, lng: 19.0508371 },
    { lat: 48.2433166, lng: 19.051331 },
    { lat: 48.2435202, lng: 19.0515445 },
    { lat: 48.2438856, lng: 19.0513454 },
    { lat: 48.2438816, lng: 19.0512256 },
    { lat: 48.2439329, lng: 19.0511883 },
    { lat: 48.2441589, lng: 19.0513119 },
    { lat: 48.2442226, lng: 19.0515021 },
    { lat: 48.2444367, lng: 19.0516756 },
    { lat: 48.2443998, lng: 19.0517742 },
    { lat: 48.2448577, lng: 19.0521337 },
    { lat: 48.2451534, lng: 19.0525215 },
    { lat: 48.245378, lng: 19.0536838 },
    { lat: 48.2455349, lng: 19.0536657 },
    { lat: 48.2456903, lng: 19.0540856 },
    { lat: 48.2457249, lng: 19.0540552 },
    { lat: 48.2456793, lng: 19.0546058 },
    { lat: 48.245702, lng: 19.0548554 },
    { lat: 48.2457409, lng: 19.0548623 },
    { lat: 48.2459859, lng: 19.0566792 },
    { lat: 48.2460219, lng: 19.0566801 },
    { lat: 48.2460469, lng: 19.0569274 },
    { lat: 48.2461859, lng: 19.057247 },
    { lat: 48.2462646, lng: 19.0579352 },
    { lat: 48.2462494, lng: 19.0585949 },
    { lat: 48.246302, lng: 19.0591018 },
    { lat: 48.246525, lng: 19.0594059 },
    { lat: 48.24653, lng: 19.0595058 },
    { lat: 48.2469793, lng: 19.0594058 },
    { lat: 48.2472558, lng: 19.0597926 },
    { lat: 48.2471833, lng: 19.060255 },
    { lat: 48.2471159, lng: 19.0602835 },
    { lat: 48.2471016, lng: 19.0606459 },
    { lat: 48.2469331, lng: 19.0611736 },
    { lat: 48.2469683, lng: 19.0612439 },
    { lat: 48.2468506, lng: 19.0614161 },
    { lat: 48.2466661, lng: 19.0620022 },
    { lat: 48.246607, lng: 19.0620612 },
    { lat: 48.246759, lng: 19.0626822 },
    { lat: 48.2467248, lng: 19.062698 },
    { lat: 48.2466543, lng: 19.0631131 },
    { lat: 48.2465673, lng: 19.0631771 },
    { lat: 48.2466138, lng: 19.0634718 },
    { lat: 48.2466533, lng: 19.0636269 },
    { lat: 48.246799, lng: 19.0636769 },
    { lat: 48.2469936, lng: 19.0638908 },
    { lat: 48.247036, lng: 19.0646427 },
    { lat: 48.2468071, lng: 19.0647326 },
    { lat: 48.2467635, lng: 19.064851 },
    { lat: 48.2468257, lng: 19.0649466 },
    { lat: 48.2469076, lng: 19.0656762 },
    { lat: 48.2472755, lng: 19.0654571 },
    { lat: 48.2472852, lng: 19.0653163 },
    { lat: 48.2475001, lng: 19.0656896 },
    { lat: 48.2484377, lng: 19.0670003 },
    { lat: 48.2481261, lng: 19.0679298 },
    { lat: 48.247066, lng: 19.0696268 },
    { lat: 48.2467489, lng: 19.0702261 },
    { lat: 48.2468504, lng: 19.0708524 },
    { lat: 48.2465657, lng: 19.0741974 },
    { lat: 48.2464319, lng: 19.0740937 },
    { lat: 48.2463097, lng: 19.0744445 },
    { lat: 48.2461425, lng: 19.07459 },
    { lat: 48.2459461, lng: 19.0754165 },
    { lat: 48.2455, lng: 19.0762783 },
    { lat: 48.2451522, lng: 19.0767802 },
    { lat: 48.2447309, lng: 19.077122 },
    { lat: 48.244093, lng: 19.0771734 },
    { lat: 48.2438085, lng: 19.0772744 },
    { lat: 48.2431862, lng: 19.0779922 },
    { lat: 48.2429744, lng: 19.0784699 },
    { lat: 48.2427692, lng: 19.0793645 },
    { lat: 48.2425941, lng: 19.0798288 },
    { lat: 48.242155, lng: 19.0804686 },
    { lat: 48.2417305, lng: 19.0813773 },
    { lat: 48.2413791, lng: 19.0813618 },
    { lat: 48.2409446, lng: 19.0825863 },
    { lat: 48.2406437, lng: 19.083016 },
    { lat: 48.2403023, lng: 19.0829972 },
    { lat: 48.2400198, lng: 19.0826926 },
    { lat: 48.239931, lng: 19.0822698 },
    { lat: 48.2398231, lng: 19.0826557 },
    { lat: 48.2391209, lng: 19.0845021 },
    { lat: 48.2388207, lng: 19.0845182 },
    { lat: 48.2387299, lng: 19.0844361 },
    { lat: 48.2383362, lng: 19.0836501 },
    { lat: 48.2375446, lng: 19.0832183 },
    { lat: 48.2371845, lng: 19.0831882 },
    { lat: 48.2368999, lng: 19.0829323 },
    { lat: 48.2360079, lng: 19.0826138 },
    { lat: 48.2353892, lng: 19.0825063 },
    { lat: 48.235262, lng: 19.0823067 },
    { lat: 48.2348464, lng: 19.0819344 },
    { lat: 48.2345503, lng: 19.0818299 },
    { lat: 48.2343286, lng: 19.081457 },
    { lat: 48.2339776, lng: 19.081359 },
    { lat: 48.2331619, lng: 19.0809244 },
    { lat: 48.2332091, lng: 19.0806513 },
    { lat: 48.2332176, lng: 19.0793872 },
    { lat: 48.2331299, lng: 19.0791224 },
    { lat: 48.2329058, lng: 19.0787201 },
    { lat: 48.2326795, lng: 19.0788386 },
    { lat: 48.2323965, lng: 19.0781916 },
    { lat: 48.2324197, lng: 19.0778916 },
    { lat: 48.2321637, lng: 19.0774618 },
    { lat: 48.2319931, lng: 19.0770298 },
    { lat: 48.231604, lng: 19.0767812 },
    { lat: 48.2311836, lng: 19.0766048 },
    { lat: 48.230833, lng: 19.076225 },
    { lat: 48.2304234, lng: 19.0761499 },
    { lat: 48.2297254, lng: 19.0758103 },
    { lat: 48.2289829, lng: 19.0750132 },
    { lat: 48.2285046, lng: 19.0741131 },
    { lat: 48.228411, lng: 19.0742453 },
    { lat: 48.2280298, lng: 19.0737728 },
    { lat: 48.2274487, lng: 19.0733384 },
    { lat: 48.2276211, lng: 19.0730689 },
    { lat: 48.227214, lng: 19.0713664 },
    { lat: 48.2266527, lng: 19.0708315 },
    { lat: 48.2259175, lng: 19.0706493 },
    { lat: 48.2238534, lng: 19.0703172 },
    { lat: 48.2233818, lng: 19.0704468 },
    { lat: 48.2229905, lng: 19.0703757 },
    { lat: 48.2225825, lng: 19.0700424 },
    { lat: 48.2222406, lng: 19.0696334 },
    { lat: 48.2221194, lng: 19.0693346 },
    { lat: 48.2217603, lng: 19.0692118 },
    { lat: 48.2214995, lng: 19.069494 },
    { lat: 48.2207304, lng: 19.0691551 },
    { lat: 48.2202836, lng: 19.0682298 },
    { lat: 48.2201866, lng: 19.0681849 },
    { lat: 48.2202519, lng: 19.0676493 },
    { lat: 48.2202227, lng: 19.0672885 },
    { lat: 48.2200964, lng: 19.0670245 },
    { lat: 48.219982, lng: 19.0669901 },
    { lat: 48.2197402, lng: 19.0670436 },
    { lat: 48.2185738, lng: 19.0666743 },
    { lat: 48.2180594, lng: 19.0662 },
    { lat: 48.2179535, lng: 19.0663816 },
    { lat: 48.2170695, lng: 19.0662687 },
    { lat: 48.2166574, lng: 19.0661886 },
    { lat: 48.2165866, lng: 19.0661235 },
    { lat: 48.2165712, lng: 19.0656883 },
    { lat: 48.2159494, lng: 19.0643116 },
    { lat: 48.2158306, lng: 19.0641719 },
    { lat: 48.2158647, lng: 19.0636382 },
    { lat: 48.2157272, lng: 19.0630124 },
    { lat: 48.2150248, lng: 19.0628811 },
    { lat: 48.2146677, lng: 19.0620593 },
    { lat: 48.2145114, lng: 19.0615312 },
    { lat: 48.2142773, lng: 19.0611582 },
    { lat: 48.2140187, lng: 19.0609247 },
    { lat: 48.2133146, lng: 19.061092 },
    { lat: 48.2129674, lng: 19.0612956 },
    { lat: 48.2127062, lng: 19.0612134 },
    { lat: 48.2123879, lng: 19.0603604 },
    { lat: 48.2124066, lng: 19.0594997 },
    { lat: 48.2122964, lng: 19.0593057 },
    { lat: 48.2122401, lng: 19.0590848 },
    { lat: 48.2113345, lng: 19.0577616 },
    { lat: 48.211057, lng: 19.058332 },
    { lat: 48.211094, lng: 19.05838 },
    { lat: 48.211246, lng: 19.058488 },
    { lat: 48.211488, lng: 19.058749 },
    { lat: 48.211745, lng: 19.058995 },
    { lat: 48.211773, lng: 19.059025 },
    { lat: 48.211969, lng: 19.059261 },
    { lat: 48.212042, lng: 19.059354 },
    { lat: 48.212076, lng: 19.059433 },
    { lat: 48.21218, lng: 19.059637 },
    { lat: 48.212166, lng: 19.060181 },
    { lat: 48.212342, lng: 19.060787 },
    { lat: 48.212607, lng: 19.061213 },
    { lat: 48.212983, lng: 19.0614 },
    { lat: 48.213638, lng: 19.061983 },
    { lat: 48.214055, lng: 19.062355 },
    { lat: 48.214499, lng: 19.062589 },
    { lat: 48.214852, lng: 19.062737 },
    { lat: 48.215003, lng: 19.06299 },
    { lat: 48.215027, lng: 19.062998 },
    { lat: 48.215269, lng: 19.063722 },
    { lat: 48.21517, lng: 19.064617 },
    { lat: 48.215218, lng: 19.064929 },
    { lat: 48.215427, lng: 19.065304 },
    { lat: 48.215742, lng: 19.065633 },
    { lat: 48.215852, lng: 19.065921 },
    { lat: 48.215988, lng: 19.066326 },
    { lat: 48.216203, lng: 19.066631 },
    { lat: 48.216598, lng: 19.067044 },
    { lat: 48.216951, lng: 19.067333 },
    { lat: 48.217055, lng: 19.067337 },
    { lat: 48.217119, lng: 19.067333 },
    { lat: 48.217118, lng: 19.067484 },
    { lat: 48.217912, lng: 19.067724 },
    { lat: 48.217987, lng: 19.067542 },
    { lat: 48.218189, lng: 19.067594 },
    { lat: 48.218205, lng: 19.067539 },
    { lat: 48.218556, lng: 19.067538 },
    { lat: 48.218547, lng: 19.067441 },
    { lat: 48.219027, lng: 19.067478 },
    { lat: 48.219041, lng: 19.067478 },
    { lat: 48.219139, lng: 19.067465 },
    { lat: 48.219591, lng: 19.067405 },
    { lat: 48.219714, lng: 19.06746 },
    { lat: 48.22001, lng: 19.067663 },
    { lat: 48.220089, lng: 19.06772 },
    { lat: 48.220176, lng: 19.067857 },
    { lat: 48.220128, lng: 19.068141 },
    { lat: 48.220167, lng: 19.068158 },
    { lat: 48.220092, lng: 19.068438 },
    { lat: 48.220041, lng: 19.068637 },
    { lat: 48.219936, lng: 19.06896 },
    { lat: 48.219925, lng: 19.06917 },
    { lat: 48.22, lng: 19.069404 },
    { lat: 48.220145, lng: 19.069534 },
    { lat: 48.220167, lng: 19.069564 },
    { lat: 48.220421, lng: 19.069957 },
    { lat: 48.220454, lng: 19.069897 },
    { lat: 48.220724, lng: 19.070194 },
    { lat: 48.221055, lng: 19.070349 },
    { lat: 48.221051, lng: 19.070402 },
    { lat: 48.221166, lng: 19.070501 },
    { lat: 48.22127, lng: 19.070591 },
    { lat: 48.22126, lng: 19.070622 },
    { lat: 48.221247, lng: 19.070662 },
    { lat: 48.221496, lng: 19.070808 },
    { lat: 48.22192, lng: 19.07102 },
    { lat: 48.221978, lng: 19.07107 },
    { lat: 48.222324, lng: 19.071377 },
    { lat: 48.222621, lng: 19.07152 },
    { lat: 48.222741, lng: 19.071513 },
    { lat: 48.222953, lng: 19.071503 },
    { lat: 48.223, lng: 19.071535 },
    { lat: 48.223026, lng: 19.071553 },
    { lat: 48.223192, lng: 19.071646 },
    { lat: 48.223587, lng: 19.071961 },
    { lat: 48.223703, lng: 19.072053 },
    { lat: 48.22398, lng: 19.072217 },
    { lat: 48.224329, lng: 19.072197 },
    { lat: 48.224346, lng: 19.072191 },
    { lat: 48.22444, lng: 19.072168 },
    { lat: 48.224595, lng: 19.072162 },
    { lat: 48.224649, lng: 19.072162 },
    { lat: 48.224976, lng: 19.072159 },
    { lat: 48.225139, lng: 19.072139 },
    { lat: 48.225437, lng: 19.072091 },
    { lat: 48.225858, lng: 19.072082 },
    { lat: 48.225887, lng: 19.072098 },
    { lat: 48.226234, lng: 19.072304 },
    { lat: 48.226369, lng: 19.072381 },
    { lat: 48.226506, lng: 19.072481 },
    { lat: 48.226591, lng: 19.072543 },
    { lat: 48.226772, lng: 19.072629 },
    { lat: 48.2268, lng: 19.07264 },
    { lat: 48.22698, lng: 19.072885 },
    { lat: 48.227057, lng: 19.07293 },
    { lat: 48.227057, lng: 19.072982 },
    { lat: 48.227046, lng: 19.073241 },
    { lat: 48.227047, lng: 19.073287 },
    { lat: 48.22695, lng: 19.07337 },
    { lat: 48.226707, lng: 19.073711 },
    { lat: 48.226591, lng: 19.073795 },
    { lat: 48.22649, lng: 19.07391 },
    { lat: 48.22617, lng: 19.07404 },
    { lat: 48.22592, lng: 19.074352 },
    { lat: 48.225838, lng: 19.074583 },
    { lat: 48.225715, lng: 19.074794 },
    { lat: 48.225598, lng: 19.075064 },
    { lat: 48.225606, lng: 19.07545 },
    { lat: 48.225487, lng: 19.075867 },
    { lat: 48.225379, lng: 19.076092 },
    { lat: 48.225301, lng: 19.076227 },
    { lat: 48.225105, lng: 19.076579 },
    { lat: 48.224933, lng: 19.077112 },
    { lat: 48.224835, lng: 19.077339 },
    { lat: 48.224755, lng: 19.077479 },
    { lat: 48.224488, lng: 19.077931 },
    { lat: 48.224336, lng: 19.078156 },
    { lat: 48.224214, lng: 19.078396 },
    { lat: 48.224009, lng: 19.078768 },
    { lat: 48.223815, lng: 19.079155 },
    { lat: 48.223702, lng: 19.079467 },
    { lat: 48.223549, lng: 19.079872 },
    { lat: 48.223426, lng: 19.080237 },
    { lat: 48.223368, lng: 19.080416 },
    { lat: 48.223283, lng: 19.080716 },
    { lat: 48.22312, lng: 19.081261 },
    { lat: 48.223005, lng: 19.081703 },
    { lat: 48.222956, lng: 19.082219 },
    { lat: 48.222987, lng: 19.082416 },
    { lat: 48.223005, lng: 19.082612 },
    { lat: 48.223073, lng: 19.083286 },
    { lat: 48.223098, lng: 19.083538 },
    { lat: 48.223163, lng: 19.084042 },
    { lat: 48.223202, lng: 19.084351 },
    { lat: 48.223203, lng: 19.084516 },
    { lat: 48.223197, lng: 19.08461 },
    { lat: 48.223194, lng: 19.084643 },
    { lat: 48.223119, lng: 19.085143 },
    { lat: 48.223064, lng: 19.085421 },
    { lat: 48.223017, lng: 19.085529 },
    { lat: 48.22296, lng: 19.08564 },
    { lat: 48.222897, lng: 19.085754 },
    { lat: 48.222812, lng: 19.085942 },
    { lat: 48.22278, lng: 19.085976 },
    { lat: 48.222708, lng: 19.086044 },
    { lat: 48.222683, lng: 19.086079 },
    { lat: 48.222635, lng: 19.08616 },
    { lat: 48.222414, lng: 19.086585 },
    { lat: 48.222256, lng: 19.086862 },
    { lat: 48.222195, lng: 19.086971 },
    { lat: 48.221977, lng: 19.087367 },
    { lat: 48.221604, lng: 19.087665 },
    { lat: 48.221452, lng: 19.087881 },
    { lat: 48.221376, lng: 19.088014 },
    { lat: 48.221078, lng: 19.088655 },
    { lat: 48.221021, lng: 19.08892 },
    { lat: 48.220988, lng: 19.08911 },
    { lat: 48.220975, lng: 19.08916 },
    { lat: 48.220789, lng: 19.089592 },
    { lat: 48.220663, lng: 19.089819 },
    { lat: 48.220555, lng: 19.089966 },
    { lat: 48.220441, lng: 19.090186 },
    { lat: 48.220299, lng: 19.090422 },
    { lat: 48.220221, lng: 19.090525 },
    { lat: 48.220156, lng: 19.090567 },
    { lat: 48.220127, lng: 19.090595 },
    { lat: 48.220025, lng: 19.090766 },
    { lat: 48.219892, lng: 19.091933 },
    { lat: 48.219859, lng: 19.09224 },
    { lat: 48.219877, lng: 19.092447 },
    { lat: 48.21989, lng: 19.092497 },
    { lat: 48.219963, lng: 19.092684 },
    { lat: 48.220008, lng: 19.093007 },
    { lat: 48.220064, lng: 19.093264 },
    { lat: 48.220158, lng: 19.093668 },
    { lat: 48.22023, lng: 19.094063 },
    { lat: 48.220412, lng: 19.094819 },
    { lat: 48.220443, lng: 19.094894 },
    { lat: 48.22052, lng: 19.095047 },
    { lat: 48.220629, lng: 19.095251 },
    { lat: 48.220742, lng: 19.095509 },
    { lat: 48.220841, lng: 19.095592 },
    { lat: 48.220998, lng: 19.095824 },
    { lat: 48.221001, lng: 19.095862 },
    { lat: 48.22091, lng: 19.096393 },
    { lat: 48.220819, lng: 19.096558 },
    { lat: 48.220798, lng: 19.096597 },
    { lat: 48.220573, lng: 19.096772 },
    { lat: 48.220376, lng: 19.096894 },
    { lat: 48.220295, lng: 19.096896 },
    { lat: 48.220117, lng: 19.096985 },
    { lat: 48.219335, lng: 19.097448 },
    { lat: 48.219019, lng: 19.097581 },
    { lat: 48.218807, lng: 19.097618 },
    { lat: 48.218835, lng: 19.097636 },
    { lat: 48.218761, lng: 19.097637 },
    { lat: 48.218787, lng: 19.097656 },
    { lat: 48.218575, lng: 19.097658 },
    { lat: 48.218465, lng: 19.09764 },
    { lat: 48.218241, lng: 19.097653 },
    { lat: 48.218058, lng: 19.097629 },
    { lat: 48.217934, lng: 19.097636 },
    { lat: 48.21764, lng: 19.097691 },
    { lat: 48.217553, lng: 19.097679 },
    { lat: 48.217206, lng: 19.097587 },
    { lat: 48.217055, lng: 19.097561 },
    { lat: 48.21638, lng: 19.09737 },
    { lat: 48.216227, lng: 19.09729 },
    { lat: 48.21606, lng: 19.097222 },
    { lat: 48.215651, lng: 19.096975 },
    { lat: 48.21518, lng: 19.096915 },
    { lat: 48.215182, lng: 19.096942 },
    { lat: 48.215161, lng: 19.096962 },
    { lat: 48.215233, lng: 19.097077 },
    { lat: 48.215259, lng: 19.0971 },
    { lat: 48.2155, lng: 19.097309 },
    { lat: 48.215511, lng: 19.097377 },
    { lat: 48.215484, lng: 19.097405 },
    { lat: 48.215484, lng: 19.097496 },
    { lat: 48.21555, lng: 19.097515 },
    { lat: 48.215636, lng: 19.097599 },
    { lat: 48.215714, lng: 19.097842 },
    { lat: 48.215874, lng: 19.097977 },
    { lat: 48.216056, lng: 19.098074 },
    { lat: 48.21636, lng: 19.098325 },
    { lat: 48.216363, lng: 19.098401 },
    { lat: 48.21639, lng: 19.098561 },
    { lat: 48.216446, lng: 19.098723 },
    { lat: 48.216551, lng: 19.098707 },
    { lat: 48.216671, lng: 19.098985 },
    { lat: 48.216643, lng: 19.099144 },
    { lat: 48.216615, lng: 19.099207 },
    { lat: 48.216743, lng: 19.099361 },
    { lat: 48.216724, lng: 19.099507 },
    { lat: 48.216706, lng: 19.099558 },
    { lat: 48.216708, lng: 19.099682 },
    { lat: 48.216758, lng: 19.099786 },
    { lat: 48.216867, lng: 19.099864 },
    { lat: 48.216961, lng: 19.099987 },
    { lat: 48.217011, lng: 19.100089 },
    { lat: 48.217089, lng: 19.100158 },
    { lat: 48.217179, lng: 19.10027 },
    { lat: 48.217147, lng: 19.100483 },
    { lat: 48.217168, lng: 19.100626 },
    { lat: 48.217166, lng: 19.100706 },
    { lat: 48.216876, lng: 19.100876 },
    { lat: 48.216814, lng: 19.100963 },
    { lat: 48.216759, lng: 19.101097 },
    { lat: 48.216773, lng: 19.10121 },
    { lat: 48.216733, lng: 19.101332 },
    { lat: 48.216636, lng: 19.101355 },
    { lat: 48.21644, lng: 19.101361 },
    { lat: 48.216403, lng: 19.101645 },
    { lat: 48.21636, lng: 19.101759 },
    { lat: 48.216206, lng: 19.101823 },
    { lat: 48.216134, lng: 19.101934 },
    { lat: 48.216062, lng: 19.101909 },
    { lat: 48.215926, lng: 19.101944 },
    { lat: 48.21586, lng: 19.102159 },
    { lat: 48.215806, lng: 19.102247 },
    { lat: 48.215762, lng: 19.102419 },
    { lat: 48.21567, lng: 19.102482 },
    { lat: 48.215582, lng: 19.102524 },
    { lat: 48.215408, lng: 19.102698 },
    { lat: 48.215382, lng: 19.102774 },
    { lat: 48.215284, lng: 19.102951 },
    { lat: 48.215228, lng: 19.102963 },
    { lat: 48.21508, lng: 19.102938 },
    { lat: 48.21507, lng: 19.103146 },
    { lat: 48.21495, lng: 19.103297 },
    { lat: 48.21486, lng: 19.103519 },
    { lat: 48.214779, lng: 19.103531 },
    { lat: 48.214652, lng: 19.103598 },
    { lat: 48.21463, lng: 19.103762 },
    { lat: 48.214365, lng: 19.104185 },
    { lat: 48.214194, lng: 19.104149 },
    { lat: 48.214192, lng: 19.104421 },
    { lat: 48.21397, lng: 19.104441 },
    { lat: 48.213895, lng: 19.104647 },
    { lat: 48.213671, lng: 19.104783 },
    { lat: 48.213636, lng: 19.104862 },
    { lat: 48.213563, lng: 19.104938 },
    { lat: 48.21335, lng: 19.104953 },
    { lat: 48.213283, lng: 19.105049 },
    { lat: 48.213148, lng: 19.105273 },
    { lat: 48.212962, lng: 19.105527 },
    { lat: 48.212952, lng: 19.105637 },
    { lat: 48.212985, lng: 19.105764 },
    { lat: 48.21299, lng: 19.105933 },
    { lat: 48.21292, lng: 19.106162 },
    { lat: 48.212853, lng: 19.106535 },
    { lat: 48.212859, lng: 19.106617 },
    { lat: 48.21282, lng: 19.106823 },
    { lat: 48.212892, lng: 19.10715 },
    { lat: 48.212898, lng: 19.107284 },
    { lat: 48.212959, lng: 19.107419 },
    { lat: 48.213128, lng: 19.108082 },
    { lat: 48.213122, lng: 19.108333 },
    { lat: 48.213138, lng: 19.108483 },
    { lat: 48.21309, lng: 19.10853 },
    { lat: 48.213083, lng: 19.108843 },
    { lat: 48.21304, lng: 19.109236 },
    { lat: 48.213097, lng: 19.109742 },
    { lat: 48.213125, lng: 19.110075 },
    { lat: 48.213284, lng: 19.110333 },
    { lat: 48.21327, lng: 19.110563 },
    { lat: 48.21326, lng: 19.11072 },
    { lat: 48.213297, lng: 19.110795 },
    { lat: 48.213453, lng: 19.111099 },
    { lat: 48.213586, lng: 19.111569 },
    { lat: 48.213581, lng: 19.111604 },
    { lat: 48.213574, lng: 19.111697 },
    { lat: 48.213481, lng: 19.113012 },
    { lat: 48.213484, lng: 19.114632 },
    { lat: 48.213483, lng: 19.114737 },
    { lat: 48.213483, lng: 19.114794 },
    { lat: 48.213625, lng: 19.114772 },
    { lat: 48.213662, lng: 19.11478 },
    { lat: 48.213737, lng: 19.114825 },
    { lat: 48.213881, lng: 19.115026 },
    { lat: 48.213947, lng: 19.115109 },
    { lat: 48.214019, lng: 19.115166 },
    { lat: 48.21407, lng: 19.115192 },
    { lat: 48.214435, lng: 19.115298 },
    { lat: 48.214601, lng: 19.115318 },
    { lat: 48.214799, lng: 19.115467 },
    { lat: 48.214871, lng: 19.115604 },
    { lat: 48.214899, lng: 19.115639 },
    { lat: 48.215113, lng: 19.115703 },
    { lat: 48.215246, lng: 19.115788 },
    { lat: 48.21544, lng: 19.115891 },
    { lat: 48.215584, lng: 19.116002 },
    { lat: 48.215808, lng: 19.116126 },
    { lat: 48.215878, lng: 19.116175 },
    { lat: 48.215899, lng: 19.116166 },
    { lat: 48.215921, lng: 19.116148 },
    { lat: 48.21594, lng: 19.116104 },
    { lat: 48.215963, lng: 19.116078 },
    { lat: 48.215978, lng: 19.11608 },
    { lat: 48.216325, lng: 19.116272 },
    { lat: 48.216424, lng: 19.116348 },
    { lat: 48.21654, lng: 19.116431 },
    { lat: 48.216691, lng: 19.116529 },
    { lat: 48.216816, lng: 19.116698 },
    { lat: 48.216846, lng: 19.116767 },
    { lat: 48.216864, lng: 19.116902 },
    { lat: 48.216874, lng: 19.116942 },
    { lat: 48.216916, lng: 19.116998 },
    { lat: 48.216939, lng: 19.117011 },
    { lat: 48.21699, lng: 19.116985 },
    { lat: 48.217018, lng: 19.116972 },
    { lat: 48.217126, lng: 19.116994 },
    { lat: 48.217158, lng: 19.11702 },
    { lat: 48.217171, lng: 19.117047 },
    { lat: 48.217209, lng: 19.117283 },
    { lat: 48.217224, lng: 19.117332 },
    { lat: 48.217257, lng: 19.117399 },
    { lat: 48.217337, lng: 19.117478 },
    { lat: 48.217405, lng: 19.117505 },
    { lat: 48.217462, lng: 19.117492 },
    { lat: 48.217494, lng: 19.117471 },
    { lat: 48.217512, lng: 19.117463 },
    { lat: 48.21755, lng: 19.117495 },
    { lat: 48.217574, lng: 19.117541 },
    { lat: 48.217592, lng: 19.117678 },
    { lat: 48.217625, lng: 19.117895 },
    { lat: 48.21765, lng: 19.117957 },
    { lat: 48.217703, lng: 19.117976 },
    { lat: 48.217801, lng: 19.117939 },
    { lat: 48.217835, lng: 19.117948 },
    { lat: 48.217958, lng: 19.118166 },
    { lat: 48.218019, lng: 19.118395 },
    { lat: 48.218074, lng: 19.11849 },
    { lat: 48.218254, lng: 19.118626 },
    { lat: 48.218965, lng: 19.119319 },
    { lat: 48.219112, lng: 19.119552 },
    { lat: 48.219192, lng: 19.119669 },
    { lat: 48.219424, lng: 19.120022 },
    { lat: 48.219633, lng: 19.120414 },
    { lat: 48.219785, lng: 19.120569 },
    { lat: 48.220011, lng: 19.120631 },
    { lat: 48.220113, lng: 19.120679 },
    { lat: 48.220184, lng: 19.120739 },
    { lat: 48.220287, lng: 19.120877 },
    { lat: 48.220464, lng: 19.121204 },
    { lat: 48.220508, lng: 19.121245 },
    { lat: 48.220601, lng: 19.121257 },
    { lat: 48.220732, lng: 19.121317 },
    { lat: 48.220785, lng: 19.121358 },
    { lat: 48.22081, lng: 19.12139 },
    { lat: 48.220872, lng: 19.12153 },
    { lat: 48.220906, lng: 19.121674 },
    { lat: 48.220951, lng: 19.121819 },
    { lat: 48.221024, lng: 19.121912 },
    { lat: 48.221082, lng: 19.121939 },
    { lat: 48.221148, lng: 19.121908 },
    { lat: 48.221186, lng: 19.121899 },
    { lat: 48.221248, lng: 19.121928 },
    { lat: 48.221327, lng: 19.12204 },
    { lat: 48.221378, lng: 19.122081 },
    { lat: 48.221474, lng: 19.122121 },
    { lat: 48.221521, lng: 19.122135 },
    { lat: 48.221569, lng: 19.122142 },
    { lat: 48.221607, lng: 19.122104 },
    { lat: 48.221683, lng: 19.121987 },
    { lat: 48.221718, lng: 19.121968 },
    { lat: 48.221767, lng: 19.121967 },
    { lat: 48.221802, lng: 19.122004 },
    { lat: 48.221859, lng: 19.122126 },
    { lat: 48.221933, lng: 19.122172 },
    { lat: 48.222136, lng: 19.122135 },
    { lat: 48.222257, lng: 19.122036 },
    { lat: 48.222323, lng: 19.12201 },
    { lat: 48.222419, lng: 19.122042 },
    { lat: 48.22248, lng: 19.122161 },
    { lat: 48.22254, lng: 19.122162 },
    { lat: 48.222666, lng: 19.122044 },
    { lat: 48.222746, lng: 19.122067 },
    { lat: 48.222893, lng: 19.122233 },
    { lat: 48.22302, lng: 19.122256 },
    { lat: 48.223179, lng: 19.122213 },
    { lat: 48.223257, lng: 19.12221 },
    { lat: 48.223491, lng: 19.122356 },
    { lat: 48.223599, lng: 19.122248 },
    { lat: 48.223685, lng: 19.12205 },
    { lat: 48.223731, lng: 19.122059 },
    { lat: 48.223767, lng: 19.122067 },
    { lat: 48.223932, lng: 19.122144 },
    { lat: 48.224062, lng: 19.122122 },
    { lat: 48.22415, lng: 19.122032 },
    { lat: 48.224393, lng: 19.121992 },
    { lat: 48.224479, lng: 19.121987 },
    { lat: 48.224567, lng: 19.122001 },
    { lat: 48.224592, lng: 19.122015 },
    { lat: 48.224616, lng: 19.122061 },
    { lat: 48.224661, lng: 19.122211 },
    { lat: 48.224945, lng: 19.122243 },
    { lat: 48.225179, lng: 19.122252 },
    { lat: 48.225254, lng: 19.122233 },
    { lat: 48.225288, lng: 19.12228 },
    { lat: 48.225334, lng: 19.122384 },
    { lat: 48.225963, lng: 19.122768 },
    { lat: 48.226073, lng: 19.122917 },
    { lat: 48.22624, lng: 19.122903 },
    { lat: 48.226449, lng: 19.123017 },
    { lat: 48.226645, lng: 19.12313 },
    { lat: 48.226788, lng: 19.123328 },
    { lat: 48.22683, lng: 19.123379 },
    { lat: 48.226913, lng: 19.123455 },
    { lat: 48.226901, lng: 19.123665 },
    { lat: 48.226851, lng: 19.123935 },
    { lat: 48.226843, lng: 19.123984 },
    { lat: 48.227018, lng: 19.12414 },
    { lat: 48.227119, lng: 19.124154 },
    { lat: 48.227171, lng: 19.12434 },
    { lat: 48.227207, lng: 19.124638 },
    { lat: 48.227198, lng: 19.124678 },
    { lat: 48.22715, lng: 19.124884 },
    { lat: 48.227164, lng: 19.125398 },
    { lat: 48.227372, lng: 19.125375 },
    { lat: 48.227453, lng: 19.125416 },
    { lat: 48.227521, lng: 19.125472 },
    { lat: 48.227697, lng: 19.125638 },
    { lat: 48.227765, lng: 19.125674 },
    { lat: 48.227881, lng: 19.125709 },
    { lat: 48.227872, lng: 19.125769 },
    { lat: 48.227862, lng: 19.125776 },
    { lat: 48.2279169, lng: 19.1258145 },
    { lat: 48.2288825, lng: 19.1238631 },
    { lat: 48.2297297, lng: 19.1227231 },
    { lat: 48.2300557, lng: 19.1223621 },
    { lat: 48.2313471, lng: 19.122602 },
    { lat: 48.2334332, lng: 19.1235844 },
    { lat: 48.234889, lng: 19.1237031 },
    { lat: 48.2355591, lng: 19.1230582 },
    { lat: 48.2356283, lng: 19.1240142 },
    { lat: 48.2354409, lng: 19.1241633 },
    { lat: 48.235633, lng: 19.1244007 },
    { lat: 48.2356032, lng: 19.1245822 },
    { lat: 48.2354974, lng: 19.1247043 },
    { lat: 48.2355091, lng: 19.1248247 },
    { lat: 48.2358168, lng: 19.125395 },
    { lat: 48.2358619, lng: 19.1258263 },
    { lat: 48.2359357, lng: 19.12606 },
    { lat: 48.2362354, lng: 19.1263055 },
    { lat: 48.2365092, lng: 19.1262809 },
    { lat: 48.2368111, lng: 19.1264137 },
    { lat: 48.2370343, lng: 19.126323 },
    { lat: 48.2372196, lng: 19.1265328 },
    { lat: 48.2374883, lng: 19.1269933 },
    { lat: 48.2376206, lng: 19.1270457 },
    { lat: 48.2377767, lng: 19.1269211 },
    { lat: 48.2379531, lng: 19.1269296 },
    { lat: 48.238354, lng: 19.1274882 },
    { lat: 48.2384877, lng: 19.1275701 },
    { lat: 48.2386946, lng: 19.1275113 },
    { lat: 48.2388756, lng: 19.1275497 },
    { lat: 48.2390161, lng: 19.12772 },
    { lat: 48.2401342, lng: 19.1282822 },
    { lat: 48.2406015, lng: 19.1287007 },
    { lat: 48.2408033, lng: 19.128791 },
    { lat: 48.2408778, lng: 19.1290861 },
    { lat: 48.2410736, lng: 19.1291477 },
    { lat: 48.2411652, lng: 19.1294688 },
    { lat: 48.241293, lng: 19.1295009 },
    { lat: 48.2415622, lng: 19.1298779 },
    { lat: 48.2416819, lng: 19.1299296 },
    { lat: 48.2417019, lng: 19.1302601 },
    { lat: 48.2417677, lng: 19.1304275 },
    { lat: 48.2419511, lng: 19.1306716 },
    { lat: 48.2421468, lng: 19.1308205 },
    { lat: 48.2424236, lng: 19.1308759 },
    { lat: 48.2425795, lng: 19.1313593 },
    { lat: 48.2427173, lng: 19.1314984 },
    { lat: 48.2428524, lng: 19.1313599 },
    { lat: 48.2432295, lng: 19.1315972 },
    { lat: 48.2433385, lng: 19.1315005 },
    { lat: 48.2435124, lng: 19.1314584 },
    { lat: 48.2437945, lng: 19.1315355 },
    { lat: 48.2440261, lng: 19.1313611 },
    { lat: 48.244147, lng: 19.1313702 },
    { lat: 48.2441146, lng: 19.1316009 },
    { lat: 48.2441574, lng: 19.1317595 },
    { lat: 48.2442688, lng: 19.1318228 },
    { lat: 48.2444692, lng: 19.1316589 },
    { lat: 48.2445898, lng: 19.1317232 },
    { lat: 48.2447302, lng: 19.1320747 },
    { lat: 48.2448454, lng: 19.1321244 },
    { lat: 48.245059, lng: 19.1319789 },
    { lat: 48.2451561, lng: 19.1320922 },
    { lat: 48.2452769, lng: 19.1324366 },
    { lat: 48.2454286, lng: 19.1323606 },
    { lat: 48.2457376, lng: 19.1325652 },
    { lat: 48.2458788, lng: 19.1328614 },
    { lat: 48.245985, lng: 19.1329369 },
    { lat: 48.2463271, lng: 19.1328559 },
    { lat: 48.2465665, lng: 19.1329579 },
    { lat: 48.2465938, lng: 19.13323 },
    { lat: 48.2467188, lng: 19.1334548 },
    { lat: 48.2469904, lng: 19.1334294 },
    { lat: 48.2471261, lng: 19.1334836 },
    { lat: 48.2472273, lng: 19.1337086 },
    { lat: 48.2474395, lng: 19.1338709 },
    { lat: 48.2475304, lng: 19.1341109 },
    { lat: 48.2476461, lng: 19.1341899 },
    { lat: 48.2478425, lng: 19.1339459 },
    { lat: 48.2479969, lng: 19.1340139 },
    { lat: 48.2480879, lng: 19.1341668 },
    { lat: 48.2486231, lng: 19.1346351 },
    { lat: 48.248699, lng: 19.1347784 },
    { lat: 48.2489598, lng: 19.134878 },
    { lat: 48.2492698, lng: 19.1347843 },
    { lat: 48.249467, lng: 19.1349208 },
    { lat: 48.2496941, lng: 19.1353407 },
    { lat: 48.2497621, lng: 19.1358237 },
    { lat: 48.2499318, lng: 19.136039 },
    { lat: 48.2502234, lng: 19.1361875 },
    { lat: 48.2504729, lng: 19.1360369 },
    { lat: 48.2504946, lng: 19.1359496 },
    { lat: 48.2506121, lng: 19.13532 },
    { lat: 48.2505552, lng: 19.1344958 },
    { lat: 48.250941, lng: 19.1332104 },
    { lat: 48.2515951, lng: 19.1305024 },
    { lat: 48.2528733, lng: 19.1278272 },
    { lat: 48.2530007, lng: 19.1277818 },
    { lat: 48.254331, lng: 19.1266737 },
    { lat: 48.2546424, lng: 19.1266552 },
    { lat: 48.2549459, lng: 19.1262558 },
    { lat: 48.2554665, lng: 19.1258909 },
    { lat: 48.2556743, lng: 19.1257838 },
    { lat: 48.2558202, lng: 19.1257915 },
    { lat: 48.2560684, lng: 19.1256392 },
    { lat: 48.2565882, lng: 19.1255266 },
    { lat: 48.2565294, lng: 19.1251614 },
    { lat: 48.2566329, lng: 19.1244989 },
    { lat: 48.2565745, lng: 19.1238743 },
    { lat: 48.2567597, lng: 19.1236317 },
    { lat: 48.2569425, lng: 19.1232275 },
    { lat: 48.2571344, lng: 19.1230507 },
    { lat: 48.2575343, lng: 19.1236073 },
    { lat: 48.2576515, lng: 19.1239024 },
    { lat: 48.2577998, lng: 19.1240956 },
    { lat: 48.2582204, lng: 19.1244129 },
    { lat: 48.2584606, lng: 19.124484 },
    { lat: 48.2586073, lng: 19.1245076 },
    { lat: 48.2588463, lng: 19.1241037 },
    { lat: 48.2591879, lng: 19.1239664 },
    { lat: 48.2597599, lng: 19.1243366 },
    { lat: 48.2601542, lng: 19.1243519 },
    { lat: 48.26039, lng: 19.124334 },
    { lat: 48.2607432, lng: 19.1240921 },
    { lat: 48.2614998, lng: 19.1239155 },
    { lat: 48.2624438, lng: 19.1239245 },
    { lat: 48.2630427, lng: 19.1242668 },
    { lat: 48.2635128, lng: 19.124447 },
    { lat: 48.2640364, lng: 19.1248184 },
    { lat: 48.2644552, lng: 19.1246738 },
    { lat: 48.2647796, lng: 19.1249178 },
    { lat: 48.2652924, lng: 19.1247971 },
    { lat: 48.2654778, lng: 19.1248114 },
    { lat: 48.2657787, lng: 19.1249889 },
    { lat: 48.2660017, lng: 19.1252304 },
    { lat: 48.2662657, lng: 19.1259588 },
    { lat: 48.2665088, lng: 19.1264507 },
    { lat: 48.2667038, lng: 19.1267661 },
    { lat: 48.2670517, lng: 19.1270722 },
    { lat: 48.267103, lng: 19.127064 },
    { lat: 48.2673102, lng: 19.1268073 },
  ],
  HontianskeMoravce: [
    { lat: 48.1901499, lng: 18.8795993 },
    { lat: 48.1901606, lng: 18.879761 },
    { lat: 48.1903818, lng: 18.8798914 },
    { lat: 48.1907048, lng: 18.8798375 },
    { lat: 48.1908831, lng: 18.8799715 },
    { lat: 48.1911823, lng: 18.8799356 },
    { lat: 48.1915238, lng: 18.8797145 },
    { lat: 48.1915856, lng: 18.8797999 },
    { lat: 48.1922867, lng: 18.8797273 },
    { lat: 48.192866, lng: 18.8793082 },
    { lat: 48.1930735, lng: 18.8793759 },
    { lat: 48.1932117, lng: 18.8792806 },
    { lat: 48.1933877, lng: 18.8792562 },
    { lat: 48.1936197, lng: 18.8789927 },
    { lat: 48.1937883, lng: 18.8785921 },
    { lat: 48.1940977, lng: 18.8787289 },
    { lat: 48.1941475, lng: 18.8786283 },
    { lat: 48.1965101, lng: 18.8768582 },
    { lat: 48.1965179, lng: 18.8768305 },
    { lat: 48.1980527, lng: 18.8743474 },
    { lat: 48.1980914, lng: 18.8742011 },
    { lat: 48.1982052, lng: 18.8741585 },
    { lat: 48.1989612, lng: 18.8728477 },
    { lat: 48.1996122, lng: 18.871212 },
    { lat: 48.2002685, lng: 18.8697416 },
    { lat: 48.2014712, lng: 18.8675942 },
    { lat: 48.202091, lng: 18.8666583 },
    { lat: 48.2021967, lng: 18.8660655 },
    { lat: 48.202756, lng: 18.8641483 },
    { lat: 48.2027712, lng: 18.863746 },
    { lat: 48.202974, lng: 18.8628372 },
    { lat: 48.202973, lng: 18.8619543 },
    { lat: 48.2030295, lng: 18.86159 },
    { lat: 48.2028378, lng: 18.8603575 },
    { lat: 48.2030828, lng: 18.8597386 },
    { lat: 48.2030972, lng: 18.8594304 },
    { lat: 48.2035247, lng: 18.8581609 },
    { lat: 48.2036116, lng: 18.8579704 },
    { lat: 48.2038915, lng: 18.8578235 },
    { lat: 48.2040822, lng: 18.8575251 },
    { lat: 48.2045877, lng: 18.8577661 },
    { lat: 48.2046728, lng: 18.8578834 },
    { lat: 48.204737, lng: 18.8581208 },
    { lat: 48.2050167, lng: 18.8579707 },
    { lat: 48.2053146, lng: 18.8580996 },
    { lat: 48.2056865, lng: 18.8584435 },
    { lat: 48.205827, lng: 18.8581336 },
    { lat: 48.2060796, lng: 18.8578534 },
    { lat: 48.2062659, lng: 18.858118 },
    { lat: 48.2062451, lng: 18.8586864 },
    { lat: 48.2064239, lng: 18.8590024 },
    { lat: 48.2065586, lng: 18.85897 },
    { lat: 48.2066234, lng: 18.8586326 },
    { lat: 48.2065827, lng: 18.8584599 },
    { lat: 48.2067097, lng: 18.8583672 },
    { lat: 48.2074442, lng: 18.8584142 },
    { lat: 48.2079822, lng: 18.8581583 },
    { lat: 48.2089301, lng: 18.8581926 },
    { lat: 48.2092195, lng: 18.8583356 },
    { lat: 48.2097792, lng: 18.8587717 },
    { lat: 48.2102629, lng: 18.8585603 },
    { lat: 48.2103961, lng: 18.8586264 },
    { lat: 48.2114612, lng: 18.8587856 },
    { lat: 48.2117778, lng: 18.8589533 },
    { lat: 48.2122246, lng: 18.8595621 },
    { lat: 48.2124639, lng: 18.8596098 },
    { lat: 48.2129022, lng: 18.85952 },
    { lat: 48.2131205, lng: 18.8597674 },
    { lat: 48.213322, lng: 18.8598248 },
    { lat: 48.2136022, lng: 18.8594901 },
    { lat: 48.2145364, lng: 18.8593921 },
    { lat: 48.2150131, lng: 18.8594074 },
    { lat: 48.2153924, lng: 18.8596526 },
    { lat: 48.2156849, lng: 18.8596824 },
    { lat: 48.2160059, lng: 18.8589433 },
    { lat: 48.2164164, lng: 18.8588352 },
    { lat: 48.2170577, lng: 18.8593913 },
    { lat: 48.2172357, lng: 18.8592803 },
    { lat: 48.2172771, lng: 18.8590583 },
    { lat: 48.2174203, lng: 18.8588637 },
    { lat: 48.2175541, lng: 18.8587883 },
    { lat: 48.2179456, lng: 18.859095 },
    { lat: 48.2181952, lng: 18.8590379 },
    { lat: 48.2181056, lng: 18.8589868 },
    { lat: 48.2183679, lng: 18.8585668 },
    { lat: 48.2184704, lng: 18.8584806 },
    { lat: 48.2186106, lng: 18.8585077 },
    { lat: 48.2187236, lng: 18.8581433 },
    { lat: 48.2189798, lng: 18.8581084 },
    { lat: 48.21909, lng: 18.856912 },
    { lat: 48.2190531, lng: 18.8566801 },
    { lat: 48.2191198, lng: 18.856428 },
    { lat: 48.2190355, lng: 18.856086 },
    { lat: 48.218907, lng: 18.8543207 },
    { lat: 48.2188143, lng: 18.8539264 },
    { lat: 48.219111, lng: 18.8526767 },
    { lat: 48.2190537, lng: 18.8524677 },
    { lat: 48.2192536, lng: 18.8523219 },
    { lat: 48.2193447, lng: 18.8519732 },
    { lat: 48.2192391, lng: 18.8517016 },
    { lat: 48.218858, lng: 18.8510971 },
    { lat: 48.2186228, lng: 18.8505048 },
    { lat: 48.2184431, lng: 18.849871 },
    { lat: 48.2183276, lng: 18.8499489 },
    { lat: 48.2182964, lng: 18.850065 },
    { lat: 48.2182399, lng: 18.8499747 },
    { lat: 48.2177634, lng: 18.8506087 },
    { lat: 48.2164264, lng: 18.8513728 },
    { lat: 48.2159274, lng: 18.8517491 },
    { lat: 48.2154638, lng: 18.851551 },
    { lat: 48.2148275, lng: 18.8514155 },
    { lat: 48.2144936, lng: 18.8514157 },
    { lat: 48.2141688, lng: 18.8510712 },
    { lat: 48.2139558, lng: 18.8509657 },
    { lat: 48.2132554, lng: 18.8509611 },
    { lat: 48.2130394, lng: 18.8509051 },
    { lat: 48.2128934, lng: 18.8508339 },
    { lat: 48.2127059, lng: 18.8506119 },
    { lat: 48.2123364, lng: 18.8507262 },
    { lat: 48.2122581, lng: 18.8512565 },
    { lat: 48.2106519, lng: 18.8513143 },
    { lat: 48.2099047, lng: 18.8517528 },
    { lat: 48.209455, lng: 18.8518868 },
    { lat: 48.209016, lng: 18.8518379 },
    { lat: 48.2087019, lng: 18.8516982 },
    { lat: 48.206432, lng: 18.851395 },
    { lat: 48.2064089, lng: 18.8502633 },
    { lat: 48.2046907, lng: 18.8499509 },
    { lat: 48.2022186, lng: 18.8496234 },
    { lat: 48.2020871, lng: 18.8494765 },
    { lat: 48.2002813, lng: 18.8494276 },
    { lat: 48.1999063, lng: 18.849352 },
    { lat: 48.1994558, lng: 18.8490828 },
    { lat: 48.198771, lng: 18.8480854 },
    { lat: 48.1981398, lng: 18.8475751 },
    { lat: 48.1975958, lng: 18.8465566 },
    { lat: 48.197544, lng: 18.8458465 },
    { lat: 48.1974217, lng: 18.8457315 },
    { lat: 48.1968254, lng: 18.8443587 },
    { lat: 48.1968073, lng: 18.8442692 },
    { lat: 48.1969627, lng: 18.8436183 },
    { lat: 48.1972151, lng: 18.8415278 },
    { lat: 48.1973013, lng: 18.8411306 },
    { lat: 48.196882, lng: 18.8386421 },
    { lat: 48.1963093, lng: 18.8357206 },
    { lat: 48.1998957, lng: 18.8294562 },
    { lat: 48.1998886, lng: 18.8293626 },
    { lat: 48.2005842, lng: 18.8280055 },
    { lat: 48.2000897, lng: 18.8271584 },
    { lat: 48.201529, lng: 18.824127 },
    { lat: 48.201537, lng: 18.824099 },
    { lat: 48.201554, lng: 18.824038 },
    { lat: 48.201899, lng: 18.822796 },
    { lat: 48.201995, lng: 18.822453 },
    { lat: 48.202599, lng: 18.820268 },
    { lat: 48.200811, lng: 18.819782 },
    { lat: 48.19936, lng: 18.819099 },
    { lat: 48.198937, lng: 18.818909 },
    { lat: 48.198558, lng: 18.81883 },
    { lat: 48.197094, lng: 18.818621 },
    { lat: 48.196892, lng: 18.818553 },
    { lat: 48.196758, lng: 18.818479 },
    { lat: 48.195603, lng: 18.817928 },
    { lat: 48.195568, lng: 18.81791 },
    { lat: 48.195527, lng: 18.817889 },
    { lat: 48.195498, lng: 18.817874 },
    { lat: 48.195425, lng: 18.817837 },
    { lat: 48.195375, lng: 18.817813 },
    { lat: 48.195388, lng: 18.817446 },
    { lat: 48.195509, lng: 18.817355 },
    { lat: 48.195678, lng: 18.817043 },
    { lat: 48.195807, lng: 18.816695 },
    { lat: 48.196008, lng: 18.815985 },
    { lat: 48.196069, lng: 18.815884 },
    { lat: 48.19643, lng: 18.814698 },
    { lat: 48.196501, lng: 18.814515 },
    { lat: 48.196636, lng: 18.814123 },
    { lat: 48.196709, lng: 18.814028 },
    { lat: 48.196701, lng: 18.814014 },
    { lat: 48.196562, lng: 18.813792 },
    { lat: 48.196077, lng: 18.813016 },
    { lat: 48.195998, lng: 18.812942 },
    { lat: 48.195878, lng: 18.812832 },
    { lat: 48.196532, lng: 18.810588 },
    { lat: 48.195529, lng: 18.809828 },
    { lat: 48.196066, lng: 18.807462 },
    { lat: 48.196071, lng: 18.807442 },
    { lat: 48.194869, lng: 18.806866 },
    { lat: 48.193854, lng: 18.806093 },
    { lat: 48.193688, lng: 18.805964 },
    { lat: 48.19359, lng: 18.805889 },
    { lat: 48.193545, lng: 18.805854 },
    { lat: 48.192065, lng: 18.804722 },
    { lat: 48.191569, lng: 18.804158 },
    { lat: 48.1909, lng: 18.803397 },
    { lat: 48.189801, lng: 18.802661 },
    { lat: 48.189284, lng: 18.802045 },
    { lat: 48.188626, lng: 18.801593 },
    { lat: 48.187722, lng: 18.800893 },
    { lat: 48.187929, lng: 18.800379 },
    { lat: 48.187187, lng: 18.799813 },
    { lat: 48.187371, lng: 18.798418 },
    { lat: 48.187383, lng: 18.798402 },
    { lat: 48.187293, lng: 18.798467 },
    { lat: 48.187255, lng: 18.798495 },
    { lat: 48.185488, lng: 18.799776 },
    { lat: 48.184768, lng: 18.800329 },
    { lat: 48.18459, lng: 18.800517 },
    { lat: 48.183764, lng: 18.801029 },
    { lat: 48.18229, lng: 18.801863 },
    { lat: 48.181907, lng: 18.801926 },
    { lat: 48.181623, lng: 18.801631 },
    { lat: 48.180695, lng: 18.8017 },
    { lat: 48.179765, lng: 18.802215 },
    { lat: 48.17886, lng: 18.80276 },
    { lat: 48.176246, lng: 18.802556 },
    { lat: 48.175395, lng: 18.802236 },
    { lat: 48.174709, lng: 18.801982 },
    { lat: 48.174389, lng: 18.801986 },
    { lat: 48.173634, lng: 18.802042 },
    { lat: 48.173137, lng: 18.802188 },
    { lat: 48.171846, lng: 18.802814 },
    { lat: 48.171374, lng: 18.803239 },
    { lat: 48.170902, lng: 18.803693 },
    { lat: 48.170879, lng: 18.804093 },
    { lat: 48.170529, lng: 18.804318 },
    { lat: 48.169449, lng: 18.80466 },
    { lat: 48.169434, lng: 18.804665 },
    { lat: 48.169632, lng: 18.805744 },
    { lat: 48.169217, lng: 18.806018 },
    { lat: 48.168591, lng: 18.806436 },
    { lat: 48.168446, lng: 18.80653 },
    { lat: 48.168066, lng: 18.806767 },
    { lat: 48.16769, lng: 18.807019 },
    { lat: 48.167101, lng: 18.807412 },
    { lat: 48.166675, lng: 18.80802 },
    { lat: 48.166367, lng: 18.808156 },
    { lat: 48.165309, lng: 18.808623 },
    { lat: 48.164829, lng: 18.808852 },
    { lat: 48.16502, lng: 18.80939 },
    { lat: 48.165231, lng: 18.810777 },
    { lat: 48.165392, lng: 18.811098 },
    { lat: 48.165525, lng: 18.811361 },
    { lat: 48.16572, lng: 18.81175 },
    { lat: 48.165463, lng: 18.81311 },
    { lat: 48.165796, lng: 18.818496 },
    { lat: 48.165894, lng: 18.819042 },
    { lat: 48.165926, lng: 18.81923 },
    { lat: 48.166069, lng: 18.820038 },
    { lat: 48.166111, lng: 18.820272 },
    { lat: 48.166276, lng: 18.821207 },
    { lat: 48.167176, lng: 18.822244 },
    { lat: 48.168474, lng: 18.823731 },
    { lat: 48.168636, lng: 18.823834 },
    { lat: 48.168873, lng: 18.824034 },
    { lat: 48.169811, lng: 18.823616 },
    { lat: 48.169907, lng: 18.82364 },
    { lat: 48.170312, lng: 18.823947 },
    { lat: 48.170384, lng: 18.824018 },
    { lat: 48.170432, lng: 18.824037 },
    { lat: 48.170648, lng: 18.82433 },
    { lat: 48.170706, lng: 18.824338 },
    { lat: 48.171014, lng: 18.824069 },
    { lat: 48.171395, lng: 18.82368 },
    { lat: 48.171657, lng: 18.823492 },
    { lat: 48.171905, lng: 18.823307 },
    { lat: 48.172512, lng: 18.823215 },
    { lat: 48.172795, lng: 18.823171 },
    { lat: 48.172822, lng: 18.823151 },
    { lat: 48.172844, lng: 18.823145 },
    { lat: 48.173391, lng: 18.823 },
    { lat: 48.173574, lng: 18.822952 },
    { lat: 48.173617, lng: 18.82294 },
    { lat: 48.173848, lng: 18.823112 },
    { lat: 48.174555, lng: 18.823641 },
    { lat: 48.175503, lng: 18.824167 },
    { lat: 48.175752, lng: 18.824306 },
    { lat: 48.175765, lng: 18.824351 },
    { lat: 48.175797, lng: 18.824457 },
    { lat: 48.175963, lng: 18.825021 },
    { lat: 48.176175, lng: 18.825742 },
    { lat: 48.176435, lng: 18.826621 },
    { lat: 48.176706, lng: 18.826976 },
    { lat: 48.176938, lng: 18.827279 },
    { lat: 48.17738, lng: 18.827859 },
    { lat: 48.177599, lng: 18.828147 },
    { lat: 48.177708, lng: 18.82827 },
    { lat: 48.177627, lng: 18.828863 },
    { lat: 48.177604, lng: 18.829034 },
    { lat: 48.177575, lng: 18.829208 },
    { lat: 48.17741, lng: 18.830389 },
    { lat: 48.177181, lng: 18.831846 },
    { lat: 48.177752, lng: 18.832451 },
    { lat: 48.177428, lng: 18.833229 },
    { lat: 48.177061, lng: 18.834137 },
    { lat: 48.176697, lng: 18.835041 },
    { lat: 48.176622, lng: 18.835268 },
    { lat: 48.176653, lng: 18.835345 },
    { lat: 48.176225, lng: 18.838764 },
    { lat: 48.176189, lng: 18.839027 },
    { lat: 48.176074, lng: 18.839833 },
    { lat: 48.17587, lng: 18.840238 },
    { lat: 48.175788, lng: 18.840539 },
    { lat: 48.175678, lng: 18.840833 },
    { lat: 48.175447, lng: 18.841216 },
    { lat: 48.175492, lng: 18.841717 },
    { lat: 48.17525, lng: 18.842114 },
    { lat: 48.17516, lng: 18.842279 },
    { lat: 48.175047, lng: 18.842573 },
    { lat: 48.174943, lng: 18.843117 },
    { lat: 48.174767, lng: 18.843662 },
    { lat: 48.174609, lng: 18.844004 },
    { lat: 48.174497, lng: 18.844309 },
    { lat: 48.174399, lng: 18.844589 },
    { lat: 48.174279, lng: 18.844862 },
    { lat: 48.174083, lng: 18.845225 },
    { lat: 48.173675, lng: 18.846144 },
    { lat: 48.173682, lng: 18.846181 },
    { lat: 48.173827, lng: 18.847236 },
    { lat: 48.173894, lng: 18.847319 },
    { lat: 48.173909, lng: 18.847409 },
    { lat: 48.17393, lng: 18.847541 },
    { lat: 48.17413, lng: 18.847965 },
    { lat: 48.173302, lng: 18.848622 },
    { lat: 48.172749, lng: 18.849181 },
    { lat: 48.17017, lng: 18.852019 },
    { lat: 48.169076, lng: 18.853276 },
    { lat: 48.167266, lng: 18.855338 },
    { lat: 48.167517, lng: 18.857983 },
    { lat: 48.167723, lng: 18.860163 },
    { lat: 48.1696654, lng: 18.8610087 },
    { lat: 48.1705475, lng: 18.8583371 },
    { lat: 48.1713845, lng: 18.8573719 },
    { lat: 48.1723629, lng: 18.8574289 },
    { lat: 48.1725311, lng: 18.8575403 },
    { lat: 48.1726033, lng: 18.8577134 },
    { lat: 48.1729378, lng: 18.8576393 },
    { lat: 48.1730066, lng: 18.8577273 },
    { lat: 48.1732945, lng: 18.8577778 },
    { lat: 48.1745536, lng: 18.859645 },
    { lat: 48.1746089, lng: 18.8598628 },
    { lat: 48.1755922, lng: 18.8612194 },
    { lat: 48.1778898, lng: 18.8648326 },
    { lat: 48.1791566, lng: 18.8663301 },
    { lat: 48.1791801, lng: 18.8662817 },
    { lat: 48.179698, lng: 18.8664393 },
    { lat: 48.1797818, lng: 18.8666849 },
    { lat: 48.1798955, lng: 18.866596 },
    { lat: 48.1800164, lng: 18.8666854 },
    { lat: 48.1801531, lng: 18.8666062 },
    { lat: 48.180273, lng: 18.8668251 },
    { lat: 48.1801059, lng: 18.8669506 },
    { lat: 48.1799812, lng: 18.8671549 },
    { lat: 48.1800834, lng: 18.8673616 },
    { lat: 48.1803484, lng: 18.8676897 },
    { lat: 48.1803182, lng: 18.8677579 },
    { lat: 48.1805825, lng: 18.8681538 },
    { lat: 48.1806198, lng: 18.8684388 },
    { lat: 48.1807027, lng: 18.8686005 },
    { lat: 48.1808637, lng: 18.8685514 },
    { lat: 48.1810999, lng: 18.868605 },
    { lat: 48.1816555, lng: 18.8690949 },
    { lat: 48.1817701, lng: 18.8694518 },
    { lat: 48.1820351, lng: 18.8695177 },
    { lat: 48.1825746, lng: 18.8703292 },
    { lat: 48.1831289, lng: 18.8709204 },
    { lat: 48.1834671, lng: 18.8709618 },
    { lat: 48.1850114, lng: 18.8717738 },
    { lat: 48.1851147, lng: 18.8720024 },
    { lat: 48.1854149, lng: 18.8722882 },
    { lat: 48.1856804, lng: 18.8729671 },
    { lat: 48.1859487, lng: 18.8732895 },
    { lat: 48.1867713, lng: 18.8739932 },
    { lat: 48.1868953, lng: 18.8742961 },
    { lat: 48.1869149, lng: 18.8745168 },
    { lat: 48.1869568, lng: 18.8745239 },
    { lat: 48.1874407, lng: 18.8754958 },
    { lat: 48.1877009, lng: 18.8765531 },
    { lat: 48.1879427, lng: 18.8766267 },
    { lat: 48.1879774, lng: 18.8767089 },
    { lat: 48.1881704, lng: 18.8765401 },
    { lat: 48.1882892, lng: 18.8765693 },
    { lat: 48.1885201, lng: 18.8768025 },
    { lat: 48.1888048, lng: 18.8765746 },
    { lat: 48.1892513, lng: 18.8765098 },
    { lat: 48.1894154, lng: 18.8764292 },
    { lat: 48.1900298, lng: 18.8764939 },
    { lat: 48.1902911, lng: 18.8767048 },
    { lat: 48.1905007, lng: 18.8772179 },
    { lat: 48.1905479, lng: 18.8780835 },
    { lat: 48.1904635, lng: 18.878345 },
    { lat: 48.1904923, lng: 18.8783985 },
    { lat: 48.1903026, lng: 18.8788486 },
    { lat: 48.1902347, lng: 18.8791922 },
    { lat: 48.1901395, lng: 18.8792694 },
    { lat: 48.1901499, lng: 18.8795993 },
  ],
  DolnýBadín: [
    { lat: 48.2742277, lng: 19.0499254 },
    { lat: 48.2742675, lng: 19.0489254 },
    { lat: 48.2739402, lng: 19.0474466 },
    { lat: 48.2732955, lng: 19.0464941 },
    { lat: 48.2726895, lng: 19.0461017 },
    { lat: 48.2723326, lng: 19.0454213 },
    { lat: 48.2723808, lng: 19.0450563 },
    { lat: 48.272481, lng: 19.0450113 },
    { lat: 48.2723372, lng: 19.0442409 },
    { lat: 48.2722369, lng: 19.0432164 },
    { lat: 48.2714132, lng: 19.0406864 },
    { lat: 48.2711592, lng: 19.039719 },
    { lat: 48.2709934, lng: 19.039988 },
    { lat: 48.2709257, lng: 19.0397597 },
    { lat: 48.2709742, lng: 19.03818 },
    { lat: 48.2708433, lng: 19.0380671 },
    { lat: 48.2710287, lng: 19.03781 },
    { lat: 48.2709826, lng: 19.0377006 },
    { lat: 48.2704813, lng: 19.0380902 },
    { lat: 48.2694159, lng: 19.0384243 },
    { lat: 48.269273, lng: 19.0386521 },
    { lat: 48.2682609, lng: 19.038995 },
    { lat: 48.2675817, lng: 19.0391401 },
    { lat: 48.2675728, lng: 19.0389635 },
    { lat: 48.2672902, lng: 19.0387245 },
    { lat: 48.2668174, lng: 19.038585 },
    { lat: 48.2664652, lng: 19.038333 },
    { lat: 48.2664363, lng: 19.0383597 },
    { lat: 48.266188, lng: 19.0379112 },
    { lat: 48.2660653, lng: 19.0374255 },
    { lat: 48.2660607, lng: 19.0366196 },
    { lat: 48.2658725, lng: 19.0340432 },
    { lat: 48.2658775, lng: 19.0332928 },
    { lat: 48.2658156, lng: 19.0328961 },
    { lat: 48.26569, lng: 19.03253 },
    { lat: 48.2655533, lng: 19.030481 },
    { lat: 48.2654667, lng: 19.0303939 },
    { lat: 48.2654362, lng: 19.0303935 },
    { lat: 48.2652386, lng: 19.0307419 },
    { lat: 48.2648715, lng: 19.0307748 },
    { lat: 48.264732, lng: 19.0308724 },
    { lat: 48.2646181, lng: 19.0308479 },
    { lat: 48.2644824, lng: 19.0307065 },
    { lat: 48.2633043, lng: 19.0309516 },
    { lat: 48.2625406, lng: 19.0314812 },
    { lat: 48.2622708, lng: 19.0319375 },
    { lat: 48.2620206, lng: 19.031983 },
    { lat: 48.2616893, lng: 19.0323001 },
    { lat: 48.2610193, lng: 19.0332517 },
    { lat: 48.2606941, lng: 19.0335519 },
    { lat: 48.2604187, lng: 19.0340804 },
    { lat: 48.2602991, lng: 19.0340787 },
    { lat: 48.2602263, lng: 19.0345551 },
    { lat: 48.2599546, lng: 19.0347548 },
    { lat: 48.2597094, lng: 19.0353905 },
    { lat: 48.259453, lng: 19.0358046 },
    { lat: 48.2594681, lng: 19.0359023 },
    { lat: 48.2596605, lng: 19.0361653 },
    { lat: 48.2596759, lng: 19.0371125 },
    { lat: 48.2598572, lng: 19.0374404 },
    { lat: 48.2597514, lng: 19.0377999 },
    { lat: 48.2599353, lng: 19.038475 },
    { lat: 48.2597828, lng: 19.0387983 },
    { lat: 48.2597422, lng: 19.0391129 },
    { lat: 48.2599481, lng: 19.0401266 },
    { lat: 48.2600447, lng: 19.040341 },
    { lat: 48.2602083, lng: 19.0405906 },
    { lat: 48.2604429, lng: 19.0408141 },
    { lat: 48.2608195, lng: 19.040685 },
    { lat: 48.2612102, lng: 19.0407854 },
    { lat: 48.2613045, lng: 19.0410203 },
    { lat: 48.2617467, lng: 19.041182 },
    { lat: 48.2620254, lng: 19.0410959 },
    { lat: 48.2623562, lng: 19.0415498 },
    { lat: 48.2621508, lng: 19.042215 },
    { lat: 48.2613986, lng: 19.043347 },
    { lat: 48.2613851, lng: 19.043569 },
    { lat: 48.26129, lng: 19.043923 },
    { lat: 48.2611285, lng: 19.0440273 },
    { lat: 48.2610731, lng: 19.0442441 },
    { lat: 48.2608988, lng: 19.0444797 },
    { lat: 48.2609659, lng: 19.0448479 },
    { lat: 48.260787, lng: 19.0453012 },
    { lat: 48.2608188, lng: 19.04584 },
    { lat: 48.2605078, lng: 19.0468009 },
    { lat: 48.2603019, lng: 19.0472574 },
    { lat: 48.2601439, lng: 19.0478562 },
    { lat: 48.2599626, lng: 19.048196 },
    { lat: 48.2595989, lng: 19.0486041 },
    { lat: 48.2594271, lng: 19.0486172 },
    { lat: 48.2594032, lng: 19.0488131 },
    { lat: 48.2586775, lng: 19.0491561 },
    { lat: 48.2586447, lng: 19.0492458 },
    { lat: 48.2587104, lng: 19.049364 },
    { lat: 48.2584917, lng: 19.0494956 },
    { lat: 48.2585087, lng: 19.0497424 },
    { lat: 48.2582535, lng: 19.0504952 },
    { lat: 48.2582551, lng: 19.0507279 },
    { lat: 48.2582069, lng: 19.0509102 },
    { lat: 48.2580765, lng: 19.0510747 },
    { lat: 48.258056, lng: 19.0512946 },
    { lat: 48.2578484, lng: 19.0514225 },
    { lat: 48.2578319, lng: 19.0515864 },
    { lat: 48.2575934, lng: 19.0520659 },
    { lat: 48.2572561, lng: 19.0524654 },
    { lat: 48.2568796, lng: 19.0526223 },
    { lat: 48.2563511, lng: 19.0526507 },
    { lat: 48.2560639, lng: 19.0525912 },
    { lat: 48.2550026, lng: 19.0520704 },
    { lat: 48.2539398, lng: 19.0513386 },
    { lat: 48.2517727, lng: 19.0491531 },
    { lat: 48.2506104, lng: 19.0477053 },
    { lat: 48.2506052, lng: 19.0477328 },
    { lat: 48.2506066, lng: 19.0480273 },
    { lat: 48.2505385, lng: 19.0483523 },
    { lat: 48.2495107, lng: 19.0507254 },
    { lat: 48.2492161, lng: 19.0523948 },
    { lat: 48.2495305, lng: 19.0525902 },
    { lat: 48.2495383, lng: 19.0531942 },
    { lat: 48.2500477, lng: 19.0540846 },
    { lat: 48.250494, lng: 19.0551972 },
    { lat: 48.2519857, lng: 19.056045 },
    { lat: 48.2525346, lng: 19.0572727 },
    { lat: 48.2530236, lng: 19.0585401 },
    { lat: 48.2539419, lng: 19.0598456 },
    { lat: 48.2543803, lng: 19.0607798 },
    { lat: 48.2549161, lng: 19.0628225 },
    { lat: 48.255414, lng: 19.063392 },
    { lat: 48.2560161, lng: 19.0643131 },
    { lat: 48.2576001, lng: 19.0665246 },
    { lat: 48.2578643, lng: 19.0671355 },
    { lat: 48.2581033, lng: 19.0680521 },
    { lat: 48.2586067, lng: 19.0705698 },
    { lat: 48.2585268, lng: 19.0708654 },
    { lat: 48.2586201, lng: 19.072095 },
    { lat: 48.258873, lng: 19.0736932 },
    { lat: 48.2600953, lng: 19.073494 },
    { lat: 48.2602165, lng: 19.0738833 },
    { lat: 48.2610907, lng: 19.0754632 },
    { lat: 48.2617814, lng: 19.0768656 },
    { lat: 48.2619396, lng: 19.078959 },
    { lat: 48.2631507, lng: 19.0812968 },
    { lat: 48.2665486, lng: 19.0851529 },
    { lat: 48.2697163, lng: 19.0884971 },
    { lat: 48.2714652, lng: 19.0864993 },
    { lat: 48.2715004, lng: 19.0864613 },
    { lat: 48.2717196, lng: 19.0836862 },
    { lat: 48.2717831, lng: 19.081136 },
    { lat: 48.2718855, lng: 19.0796519 },
    { lat: 48.2721927, lng: 19.0799067 },
    { lat: 48.2726269, lng: 19.0784974 },
    { lat: 48.2731028, lng: 19.0775352 },
    { lat: 48.2733635, lng: 19.0768943 },
    { lat: 48.2734801, lng: 19.0763188 },
    { lat: 48.2735845, lng: 19.0761955 },
    { lat: 48.273762, lng: 19.075422 },
    { lat: 48.2737365, lng: 19.075071 },
    { lat: 48.2737775, lng: 19.0748805 },
    { lat: 48.2738032, lng: 19.0741125 },
    { lat: 48.2734731, lng: 19.0724592 },
    { lat: 48.2734708, lng: 19.0716536 },
    { lat: 48.2735609, lng: 19.0715142 },
    { lat: 48.2737938, lng: 19.070566 },
    { lat: 48.2739628, lng: 19.0702241 },
    { lat: 48.2740122, lng: 19.069929 },
    { lat: 48.274231, lng: 19.0696435 },
    { lat: 48.2742699, lng: 19.0694069 },
    { lat: 48.2742125, lng: 19.0690039 },
    { lat: 48.2743025, lng: 19.0686653 },
    { lat: 48.2745042, lng: 19.068462 },
    { lat: 48.2746842, lng: 19.0680736 },
    { lat: 48.2748717, lng: 19.0680149 },
    { lat: 48.2749732, lng: 19.0677883 },
    { lat: 48.2751232, lng: 19.0676803 },
    { lat: 48.2755167, lng: 19.0676582 },
    { lat: 48.2757034, lng: 19.0672003 },
    { lat: 48.2757048, lng: 19.0669609 },
    { lat: 48.276029, lng: 19.0657857 },
    { lat: 48.2759696, lng: 19.0656363 },
    { lat: 48.2758134, lng: 19.0655577 },
    { lat: 48.275768, lng: 19.065462 },
    { lat: 48.2756842, lng: 19.0647367 },
    { lat: 48.2756973, lng: 19.0643027 },
    { lat: 48.2756388, lng: 19.0640142 },
    { lat: 48.2756458, lng: 19.0631912 },
    { lat: 48.2752362, lng: 19.0631179 },
    { lat: 48.2751372, lng: 19.0629677 },
    { lat: 48.275283, lng: 19.0620537 },
    { lat: 48.2747858, lng: 19.0615178 },
    { lat: 48.2742387, lng: 19.0612481 },
    { lat: 48.2741522, lng: 19.0608481 },
    { lat: 48.274163, lng: 19.0602806 },
    { lat: 48.2740557, lng: 19.0596511 },
    { lat: 48.2739827, lng: 19.0594789 },
    { lat: 48.2738469, lng: 19.059422 },
    { lat: 48.2735296, lng: 19.0591221 },
    { lat: 48.2734344, lng: 19.0586866 },
    { lat: 48.2734389, lng: 19.0580168 },
    { lat: 48.2733866, lng: 19.0577856 },
    { lat: 48.2732357, lng: 19.0577461 },
    { lat: 48.2729824, lng: 19.0574403 },
    { lat: 48.2729118, lng: 19.0572027 },
    { lat: 48.2729245, lng: 19.0570042 },
    { lat: 48.2730157, lng: 19.0567811 },
    { lat: 48.2729807, lng: 19.056555 },
    { lat: 48.272846, lng: 19.0563438 },
    { lat: 48.2729166, lng: 19.0559769 },
    { lat: 48.2730478, lng: 19.0556517 },
    { lat: 48.272984, lng: 19.0551461 },
    { lat: 48.2727238, lng: 19.0546808 },
    { lat: 48.272721, lng: 19.0542922 },
    { lat: 48.2729461, lng: 19.053973 },
    { lat: 48.2729534, lng: 19.0535142 },
    { lat: 48.2731622, lng: 19.0528245 },
    { lat: 48.2733107, lng: 19.0527064 },
    { lat: 48.2733935, lng: 19.0524518 },
    { lat: 48.2734113, lng: 19.0520873 },
    { lat: 48.2733378, lng: 19.0520822 },
    { lat: 48.2734856, lng: 19.0515951 },
    { lat: 48.2737051, lng: 19.0511172 },
    { lat: 48.2737023, lng: 19.0500792 },
    { lat: 48.2741681, lng: 19.049951 },
    { lat: 48.2742277, lng: 19.0499254 },
  ],
  Krupina: [
    { lat: 48.41928, lng: 19.105304 },
    { lat: 48.419204, lng: 19.104786 },
    { lat: 48.419236, lng: 19.104317 },
    { lat: 48.419024, lng: 19.103795 },
    { lat: 48.418988, lng: 19.103527 },
    { lat: 48.41898, lng: 19.103556 },
    { lat: 48.419147, lng: 19.102866 },
    { lat: 48.419376, lng: 19.102122 },
    { lat: 48.419714, lng: 19.10084 },
    { lat: 48.419646, lng: 19.100429 },
    { lat: 48.419535, lng: 19.10005 },
    { lat: 48.419457, lng: 19.099711 },
    { lat: 48.419441, lng: 19.099144 },
    { lat: 48.419527, lng: 19.098776 },
    { lat: 48.419447, lng: 19.098617 },
    { lat: 48.419384, lng: 19.0984 },
    { lat: 48.419262, lng: 19.097984 },
    { lat: 48.418906, lng: 19.097313 },
    { lat: 48.418884, lng: 19.097257 },
    { lat: 48.418288, lng: 19.096111 },
    { lat: 48.418002, lng: 19.095509 },
    { lat: 48.417902, lng: 19.095044 },
    { lat: 48.417836, lng: 19.094876 },
    { lat: 48.417875, lng: 19.094306 },
    { lat: 48.417779, lng: 19.093869 },
    { lat: 48.417478, lng: 19.093362 },
    { lat: 48.417429, lng: 19.093098 },
    { lat: 48.417011, lng: 19.091686 },
    { lat: 48.416472, lng: 19.091138 },
    { lat: 48.416416, lng: 19.091166 },
    { lat: 48.41595, lng: 19.090972 },
    { lat: 48.415644, lng: 19.091066 },
    { lat: 48.415429, lng: 19.091435 },
    { lat: 48.415398, lng: 19.091438 },
    { lat: 48.415264, lng: 19.091617 },
    { lat: 48.415062, lng: 19.091614 },
    { lat: 48.414372, lng: 19.091223 },
    { lat: 48.414038, lng: 19.090783 },
    { lat: 48.41403, lng: 19.090479 },
    { lat: 48.413201, lng: 19.089514 },
    { lat: 48.412542, lng: 19.089071 },
    { lat: 48.412292, lng: 19.088865 },
    { lat: 48.411892, lng: 19.088604 },
    { lat: 48.411513, lng: 19.088196 },
    { lat: 48.411392, lng: 19.087938 },
    { lat: 48.411262, lng: 19.087577 },
    { lat: 48.411318, lng: 19.086501 },
    { lat: 48.411338, lng: 19.086315 },
    { lat: 48.411647, lng: 19.085773 },
    { lat: 48.411683, lng: 19.085709 },
    { lat: 48.411686, lng: 19.0857 },
    { lat: 48.411724, lng: 19.085578 },
    { lat: 48.411748, lng: 19.085474 },
    { lat: 48.411766, lng: 19.085406 },
    { lat: 48.411781, lng: 19.085349 },
    { lat: 48.411788, lng: 19.085322 },
    { lat: 48.411753, lng: 19.08486 },
    { lat: 48.411897, lng: 19.083955 },
    { lat: 48.411957, lng: 19.08363 },
    { lat: 48.411966, lng: 19.083349 },
    { lat: 48.41195, lng: 19.08309 },
    { lat: 48.411933, lng: 19.082673 },
    { lat: 48.411904, lng: 19.082332 },
    { lat: 48.41191, lng: 19.082066 },
    { lat: 48.411901, lng: 19.081571 },
    { lat: 48.411853, lng: 19.08127 },
    { lat: 48.411958, lng: 19.080792 },
    { lat: 48.412084, lng: 19.080343 },
    { lat: 48.412356, lng: 19.079682 },
    { lat: 48.412372, lng: 19.079633 },
    { lat: 48.412384, lng: 19.07959 },
    { lat: 48.412807, lng: 19.078217 },
    { lat: 48.413295, lng: 19.077886 },
    { lat: 48.413332, lng: 19.077861 },
    { lat: 48.41354, lng: 19.07772 },
    { lat: 48.414143, lng: 19.076849 },
    { lat: 48.414347, lng: 19.076505 },
    { lat: 48.414623, lng: 19.076168 },
    { lat: 48.415022, lng: 19.075569 },
    { lat: 48.41512, lng: 19.075407 },
    { lat: 48.415303, lng: 19.075158 },
    { lat: 48.415476, lng: 19.074944 },
    { lat: 48.415504, lng: 19.074877 },
    { lat: 48.415556, lng: 19.07475 },
    { lat: 48.415609, lng: 19.07466 },
    { lat: 48.415667, lng: 19.074564 },
    { lat: 48.415812, lng: 19.074292 },
    { lat: 48.415831, lng: 19.07426 },
    { lat: 48.415723, lng: 19.074211 },
    { lat: 48.415616, lng: 19.074139 },
    { lat: 48.415613, lng: 19.07403 },
    { lat: 48.415564, lng: 19.073559 },
    { lat: 48.415564, lng: 19.073346 },
    { lat: 48.41551, lng: 19.073244 },
    { lat: 48.415564, lng: 19.073028 },
    { lat: 48.41546, lng: 19.07289 },
    { lat: 48.415408, lng: 19.072741 },
    { lat: 48.415351, lng: 19.072294 },
    { lat: 48.415349, lng: 19.072143 },
    { lat: 48.415237, lng: 19.071918 },
    { lat: 48.415021, lng: 19.071679 },
    { lat: 48.415014, lng: 19.071359 },
    { lat: 48.414941, lng: 19.071113 },
    { lat: 48.414831, lng: 19.070988 },
    { lat: 48.414731, lng: 19.070796 },
    { lat: 48.414646, lng: 19.070194 },
    { lat: 48.414458, lng: 19.069801 },
    { lat: 48.414431, lng: 19.069604 },
    { lat: 48.414356, lng: 19.06934 },
    { lat: 48.414311, lng: 19.069135 },
    { lat: 48.414367, lng: 19.06913 },
    { lat: 48.414465, lng: 19.068708 },
    { lat: 48.414907, lng: 19.068153 },
    { lat: 48.415602, lng: 19.067485 },
    { lat: 48.415581, lng: 19.067109 },
    { lat: 48.415658, lng: 19.066673 },
    { lat: 48.415671, lng: 19.066574 },
    { lat: 48.41568, lng: 19.066517 },
    { lat: 48.415723, lng: 19.066202 },
    { lat: 48.415792, lng: 19.06576 },
    { lat: 48.416007, lng: 19.065351 },
    { lat: 48.416126, lng: 19.065055 },
    { lat: 48.416206, lng: 19.064759 },
    { lat: 48.416425, lng: 19.06413 },
    { lat: 48.416578, lng: 19.063863 },
    { lat: 48.416714, lng: 19.063347 },
    { lat: 48.416799, lng: 19.062939 },
    { lat: 48.416947, lng: 19.062441 },
    { lat: 48.417142, lng: 19.062026 },
    { lat: 48.417275, lng: 19.061808 },
    { lat: 48.417439, lng: 19.061484 },
    { lat: 48.417699, lng: 19.060972 },
    { lat: 48.417983, lng: 19.059876 },
    { lat: 48.418106, lng: 19.059447 },
    { lat: 48.418231, lng: 19.059023 },
    { lat: 48.418415, lng: 19.058368 },
    { lat: 48.418429, lng: 19.058317 },
    { lat: 48.418671, lng: 19.057452 },
    { lat: 48.418912, lng: 19.056605 },
    { lat: 48.419044, lng: 19.056027 },
    { lat: 48.419153, lng: 19.055631 },
    { lat: 48.419158, lng: 19.055491 },
    { lat: 48.419796, lng: 19.054638 },
    { lat: 48.420103, lng: 19.054011 },
    { lat: 48.420532, lng: 19.052977 },
    { lat: 48.420622, lng: 19.052771 },
    { lat: 48.421311, lng: 19.052041 },
    { lat: 48.421468, lng: 19.051416 },
    { lat: 48.42173, lng: 19.050345 },
    { lat: 48.421755, lng: 19.050267 },
    { lat: 48.421775, lng: 19.050209 },
    { lat: 48.421798, lng: 19.050141 },
    { lat: 48.422376, lng: 19.048982 },
    { lat: 48.422473, lng: 19.048827 },
    { lat: 48.422997, lng: 19.048084 },
    { lat: 48.42336, lng: 19.047593 },
    { lat: 48.423742, lng: 19.047471 },
    { lat: 48.424437, lng: 19.04684 },
    { lat: 48.424475, lng: 19.046807 },
    { lat: 48.425091, lng: 19.046248 },
    { lat: 48.425175, lng: 19.046199 },
    { lat: 48.425527, lng: 19.045938 },
    { lat: 48.426724, lng: 19.045898 },
    { lat: 48.427125, lng: 19.045835 },
    { lat: 48.427207, lng: 19.045836 },
    { lat: 48.427671, lng: 19.045709 },
    { lat: 48.427988, lng: 19.045595 },
    { lat: 48.42813, lng: 19.045523 },
    { lat: 48.429477, lng: 19.044838 },
    { lat: 48.430279, lng: 19.044479 },
    { lat: 48.431784, lng: 19.043809 },
    { lat: 48.431939, lng: 19.043764 },
    { lat: 48.431978, lng: 19.043752 },
    { lat: 48.432291, lng: 19.04366 },
    { lat: 48.433104, lng: 19.043435 },
    { lat: 48.433699, lng: 19.042789 },
    { lat: 48.434539, lng: 19.042156 },
    { lat: 48.435406, lng: 19.041483 },
    { lat: 48.435948, lng: 19.041121 },
    { lat: 48.435986, lng: 19.041096 },
    { lat: 48.436156, lng: 19.040982 },
    { lat: 48.436909, lng: 19.040613 },
    { lat: 48.43705, lng: 19.040575 },
    { lat: 48.437536, lng: 19.040291 },
    { lat: 48.439107, lng: 19.03858 },
    { lat: 48.439476, lng: 19.038175 },
    { lat: 48.440238, lng: 19.03753 },
    { lat: 48.440401, lng: 19.037391 },
    { lat: 48.440437, lng: 19.037348 },
    { lat: 48.440472, lng: 19.037306 },
    { lat: 48.440509, lng: 19.037263 },
    { lat: 48.440725, lng: 19.037006 },
    { lat: 48.440126, lng: 19.035555 },
    { lat: 48.440033, lng: 19.035288 },
    { lat: 48.440013, lng: 19.035281 },
    { lat: 48.439974, lng: 19.035267 },
    { lat: 48.439913, lng: 19.035247 },
    { lat: 48.439799, lng: 19.034905 },
    { lat: 48.439437, lng: 19.033743 },
    { lat: 48.439328, lng: 19.033429 },
    { lat: 48.439013, lng: 19.032858 },
    { lat: 48.438936, lng: 19.032669 },
    { lat: 48.438963, lng: 19.031509 },
    { lat: 48.438977, lng: 19.031043 },
    { lat: 48.438845, lng: 19.030466 },
    { lat: 48.438796, lng: 19.029864 },
    { lat: 48.438752, lng: 19.029339 },
    { lat: 48.438708, lng: 19.028828 },
    { lat: 48.438689, lng: 19.028239 },
    { lat: 48.438692, lng: 19.026813 },
    { lat: 48.438674, lng: 19.026411 },
    { lat: 48.438537, lng: 19.025334 },
    { lat: 48.4384, lng: 19.024112 },
    { lat: 48.438187, lng: 19.02342 },
    { lat: 48.437991, lng: 19.022642 },
    { lat: 48.437977, lng: 19.022587 },
    { lat: 48.437878, lng: 19.022194 },
    { lat: 48.43781, lng: 19.021931 },
    { lat: 48.437368, lng: 19.020622 },
    { lat: 48.436926, lng: 19.019093 },
    { lat: 48.436751, lng: 19.018692 },
    { lat: 48.43619, lng: 19.017402 },
    { lat: 48.435928, lng: 19.016643 },
    { lat: 48.435761, lng: 19.016343 },
    { lat: 48.435558, lng: 19.015998 },
    { lat: 48.435312, lng: 19.015719 },
    { lat: 48.434721, lng: 19.014951 },
    { lat: 48.434552, lng: 19.014732 },
    { lat: 48.434514, lng: 19.014684 },
    { lat: 48.434048, lng: 19.014078 },
    { lat: 48.432905, lng: 19.013184 },
    { lat: 48.431756, lng: 19.012803 },
    { lat: 48.430562, lng: 19.012013 },
    { lat: 48.430205, lng: 19.011351 },
    { lat: 48.430174, lng: 19.011293 },
    { lat: 48.429635, lng: 19.010293 },
    { lat: 48.429003, lng: 19.009147 },
    { lat: 48.428741, lng: 19.008687 },
    { lat: 48.428717, lng: 19.008644 },
    { lat: 48.428712, lng: 19.008636 },
    { lat: 48.427567, lng: 19.006801 },
    { lat: 48.427333, lng: 19.006294 },
    { lat: 48.427311, lng: 19.006246 },
    { lat: 48.426824, lng: 19.005188 },
    { lat: 48.425806, lng: 19.003184 },
    { lat: 48.425783, lng: 19.003139 },
    { lat: 48.425731, lng: 19.003037 },
    { lat: 48.425106, lng: 19.00152 },
    { lat: 48.424978, lng: 19.001106 },
    { lat: 48.424963, lng: 19.001055 },
    { lat: 48.424817, lng: 19.000582 },
    { lat: 48.424739, lng: 19.00007 },
    { lat: 48.424665, lng: 18.999878 },
    { lat: 48.42418, lng: 18.998622 },
    { lat: 48.423999, lng: 18.998262 },
    { lat: 48.423485, lng: 18.997691 },
    { lat: 48.423372, lng: 18.997183 },
    { lat: 48.42291, lng: 18.996454 },
    { lat: 48.422875, lng: 18.995997 },
    { lat: 48.422871, lng: 18.995942 },
    { lat: 48.422841, lng: 18.995518 },
    { lat: 48.422324, lng: 18.995815 },
    { lat: 48.421496, lng: 18.995314 },
    { lat: 48.420985, lng: 18.994745 },
    { lat: 48.420802, lng: 18.994589 },
    { lat: 48.420514, lng: 18.99431 },
    { lat: 48.420328, lng: 18.994152 },
    { lat: 48.419895, lng: 18.99366 },
    { lat: 48.419883, lng: 18.993648 },
    { lat: 48.419589, lng: 18.993321 },
    { lat: 48.418433, lng: 18.992353 },
    { lat: 48.41765, lng: 18.991573 },
    { lat: 48.417521, lng: 18.991537 },
    { lat: 48.417382, lng: 18.99158 },
    { lat: 48.416237, lng: 18.991109 },
    { lat: 48.415517, lng: 18.990374 },
    { lat: 48.415262, lng: 18.989714 },
    { lat: 48.414727, lng: 18.98833 },
    { lat: 48.414382, lng: 18.987734 },
    { lat: 48.41307, lng: 18.986663 },
    { lat: 48.412545, lng: 18.98636 },
    { lat: 48.412236, lng: 18.985952 },
    { lat: 48.411603, lng: 18.985482 },
    { lat: 48.411295, lng: 18.985252 },
    { lat: 48.4097326, lng: 18.9843801 },
    { lat: 48.4093733, lng: 18.9843206 },
    { lat: 48.4086556, lng: 18.9843776 },
    { lat: 48.4072309, lng: 18.9848955 },
    { lat: 48.4053949, lng: 18.9870104 },
    { lat: 48.4048056, lng: 18.9873946 },
    { lat: 48.4045177, lng: 18.9880284 },
    { lat: 48.4037375, lng: 18.9893345 },
    { lat: 48.4019135, lng: 18.9926196 },
    { lat: 48.4009067, lng: 18.9936815 },
    { lat: 48.4002863, lng: 18.9949399 },
    { lat: 48.3995659, lng: 18.9957386 },
    { lat: 48.3995884, lng: 18.9965574 },
    { lat: 48.3995423, lng: 18.996827 },
    { lat: 48.3994263, lng: 18.9970961 },
    { lat: 48.398527, lng: 18.9983673 },
    { lat: 48.3982601, lng: 18.9991738 },
    { lat: 48.3979085, lng: 18.9999532 },
    { lat: 48.3976092, lng: 19.0002099 },
    { lat: 48.3973574, lng: 19.000313 },
    { lat: 48.396689, lng: 19.0003179 },
    { lat: 48.3960411, lng: 19.0005259 },
    { lat: 48.3955522, lng: 19.00082 },
    { lat: 48.3951247, lng: 19.0012383 },
    { lat: 48.3946945, lng: 19.002088 },
    { lat: 48.3937714, lng: 19.0024971 },
    { lat: 48.3934729, lng: 19.0025473 },
    { lat: 48.3929583, lng: 19.002491 },
    { lat: 48.3923939, lng: 19.0022775 },
    { lat: 48.3920516, lng: 19.0019116 },
    { lat: 48.3917742, lng: 19.0011104 },
    { lat: 48.3907992, lng: 18.9995802 },
    { lat: 48.3904935, lng: 18.9988733 },
    { lat: 48.3900817, lng: 18.9982501 },
    { lat: 48.3886074, lng: 18.9969502 },
    { lat: 48.3875177, lng: 18.9962522 },
    { lat: 48.3873007, lng: 18.996175 },
    { lat: 48.3869847, lng: 18.9963442 },
    { lat: 48.3868148, lng: 18.9962651 },
    { lat: 48.3863836, lng: 18.996521 },
    { lat: 48.3861281, lng: 18.9967748 },
    { lat: 48.3849685, lng: 18.9966709 },
    { lat: 48.3848218, lng: 18.9967136 },
    { lat: 48.3848192, lng: 18.9966216 },
    { lat: 48.3844913, lng: 18.9972211 },
    { lat: 48.3845118, lng: 18.9975362 },
    { lat: 48.3842969, lng: 18.9983646 },
    { lat: 48.3841869, lng: 18.9986166 },
    { lat: 48.3840799, lng: 18.9986867 },
    { lat: 48.3839694, lng: 18.9992407 },
    { lat: 48.3838965, lng: 18.9993604 },
    { lat: 48.3838818, lng: 18.9997168 },
    { lat: 48.3836946, lng: 19.0004217 },
    { lat: 48.3834695, lng: 19.0006962 },
    { lat: 48.3833403, lng: 19.000684 },
    { lat: 48.3832989, lng: 19.0008033 },
    { lat: 48.3833656, lng: 19.0008918 },
    { lat: 48.3832939, lng: 19.0009699 },
    { lat: 48.3832046, lng: 19.0009418 },
    { lat: 48.3830847, lng: 19.0010701 },
    { lat: 48.3830653, lng: 19.0012632 },
    { lat: 48.3830022, lng: 19.0013286 },
    { lat: 48.3829314, lng: 19.0013115 },
    { lat: 48.3828894, lng: 19.001438 },
    { lat: 48.3826944, lng: 19.0015071 },
    { lat: 48.3823445, lng: 19.0018547 },
    { lat: 48.3821173, lng: 19.0017318 },
    { lat: 48.3818023, lng: 19.0029007 },
    { lat: 48.381668, lng: 19.00303 },
    { lat: 48.3814562, lng: 19.0029436 },
    { lat: 48.3813128, lng: 19.0030749 },
    { lat: 48.3812142, lng: 19.0033093 },
    { lat: 48.3801589, lng: 19.0012663 },
    { lat: 48.3795807, lng: 18.9998057 },
    { lat: 48.378584, lng: 18.998878 },
    { lat: 48.3784646, lng: 18.9985714 },
    { lat: 48.3776056, lng: 18.9975084 },
    { lat: 48.3774959, lng: 18.9972567 },
    { lat: 48.3771732, lng: 18.995368 },
    { lat: 48.3771026, lng: 18.9951782 },
    { lat: 48.3772405, lng: 18.9948322 },
    { lat: 48.3775144, lng: 18.9945642 },
    { lat: 48.3778601, lng: 18.9937575 },
    { lat: 48.3769128, lng: 18.9906743 },
    { lat: 48.3768165, lng: 18.9901342 },
    { lat: 48.3767776, lng: 18.9896815 },
    { lat: 48.3768892, lng: 18.9886629 },
    { lat: 48.3767897, lng: 18.9877069 },
    { lat: 48.3767657, lng: 18.9866623 },
    { lat: 48.3765557, lng: 18.9861474 },
    { lat: 48.3763822, lng: 18.9858829 },
    { lat: 48.3761605, lng: 18.9858488 },
    { lat: 48.3759685, lng: 18.9857283 },
    { lat: 48.3750193, lng: 18.9848511 },
    { lat: 48.3738343, lng: 18.9840978 },
    { lat: 48.3736611, lng: 18.983884 },
    { lat: 48.3726155, lng: 18.9844528 },
    { lat: 48.3719161, lng: 18.9843129 },
    { lat: 48.3714801, lng: 18.9840101 },
    { lat: 48.3707673, lng: 18.9839406 },
    { lat: 48.3703958, lng: 18.9837957 },
    { lat: 48.3700325, lng: 18.9833251 },
    { lat: 48.3697761, lng: 18.9844031 },
    { lat: 48.369735, lng: 18.9855613 },
    { lat: 48.3685601, lng: 18.988037 },
    { lat: 48.3682488, lng: 18.9884571 },
    { lat: 48.3674556, lng: 18.9891098 },
    { lat: 48.3668387, lng: 18.9900139 },
    { lat: 48.3662534, lng: 18.9903878 },
    { lat: 48.3655979, lng: 18.9909098 },
    { lat: 48.3653708, lng: 18.9911879 },
    { lat: 48.3650998, lng: 18.991647 },
    { lat: 48.3647615, lng: 18.9920219 },
    { lat: 48.3644355, lng: 18.9921944 },
    { lat: 48.3640317, lng: 18.9925244 },
    { lat: 48.3636359, lng: 18.9934321 },
    { lat: 48.3628398, lng: 18.9944037 },
    { lat: 48.3627933, lng: 18.9945527 },
    { lat: 48.362804, lng: 18.9952038 },
    { lat: 48.3621961, lng: 18.9955341 },
    { lat: 48.3606825, lng: 18.9958569 },
    { lat: 48.3601692, lng: 18.9957619 },
    { lat: 48.3600576, lng: 18.995608 },
    { lat: 48.359776, lng: 18.9955006 },
    { lat: 48.3595504, lng: 18.9951684 },
    { lat: 48.3593097, lng: 18.9950475 },
    { lat: 48.3588819, lng: 18.99497 },
    { lat: 48.3573243, lng: 18.9942959 },
    { lat: 48.3569646, lng: 18.9943872 },
    { lat: 48.3565792, lng: 18.9947203 },
    { lat: 48.3561387, lng: 18.9949959 },
    { lat: 48.3555756, lng: 18.994428 },
    { lat: 48.3543892, lng: 18.9945144 },
    { lat: 48.3540705, lng: 18.9947006 },
    { lat: 48.3540563, lng: 18.9947082 },
    { lat: 48.3537156, lng: 18.9945693 },
    { lat: 48.3533238, lng: 18.9945827 },
    { lat: 48.3528258, lng: 18.9947234 },
    { lat: 48.3527755, lng: 18.9949093 },
    { lat: 48.352469, lng: 18.9950887 },
    { lat: 48.3508619, lng: 18.9965866 },
    { lat: 48.349352, lng: 18.9977765 },
    { lat: 48.3487155, lng: 18.9985662 },
    { lat: 48.3481947, lng: 18.998964 },
    { lat: 48.3481253, lng: 18.9993735 },
    { lat: 48.3481564, lng: 18.999544 },
    { lat: 48.3480793, lng: 19.0001093 },
    { lat: 48.3479701, lng: 19.000512 },
    { lat: 48.3477235, lng: 19.0009624 },
    { lat: 48.3476581, lng: 19.0011829 },
    { lat: 48.3474109, lng: 19.0015526 },
    { lat: 48.3467375, lng: 19.0030109 },
    { lat: 48.3465947, lng: 19.0035511 },
    { lat: 48.3465787, lng: 19.0037674 },
    { lat: 48.3467544, lng: 19.0042761 },
    { lat: 48.3467378, lng: 19.0047444 },
    { lat: 48.3463663, lng: 19.0052916 },
    { lat: 48.3459704, lng: 19.0056885 },
    { lat: 48.3456115, lng: 19.0059423 },
    { lat: 48.3450427, lng: 19.0067548 },
    { lat: 48.3445058, lng: 19.0078586 },
    { lat: 48.3438907, lng: 19.0082936 },
    { lat: 48.3432492, lng: 19.0088692 },
    { lat: 48.3423717, lng: 19.0097056 },
    { lat: 48.3405632, lng: 19.0125685 },
    { lat: 48.3392498, lng: 19.0144384 },
    { lat: 48.3389995, lng: 19.0147253 },
    { lat: 48.3386163, lng: 19.0150374 },
    { lat: 48.33792, lng: 19.015051 },
    { lat: 48.3364665, lng: 19.0153123 },
    { lat: 48.3361908, lng: 19.0168112 },
    { lat: 48.3357238, lng: 19.0185636 },
    { lat: 48.3356517, lng: 19.0193628 },
    { lat: 48.3352552, lng: 19.0199491 },
    { lat: 48.3350932, lng: 19.0203092 },
    { lat: 48.3344176, lng: 19.0213083 },
    { lat: 48.3338936, lng: 19.0219563 },
    { lat: 48.3336606, lng: 19.0224084 },
    { lat: 48.3333137, lng: 19.0227049 },
    { lat: 48.3329582, lng: 19.0232978 },
    { lat: 48.332752, lng: 19.0234808 },
    { lat: 48.3325423, lng: 19.0235678 },
    { lat: 48.3323295, lng: 19.0240201 },
    { lat: 48.3319587, lng: 19.0244659 },
    { lat: 48.3315395, lng: 19.024851 },
    { lat: 48.3313448, lng: 19.0249445 },
    { lat: 48.3309464, lng: 19.0252733 },
    { lat: 48.330156, lng: 19.0257851 },
    { lat: 48.329113, lng: 19.0263366 },
    { lat: 48.3289101, lng: 19.0270049 },
    { lat: 48.3287155, lng: 19.0281722 },
    { lat: 48.3284507, lng: 19.0289336 },
    { lat: 48.3282365, lng: 19.0298445 },
    { lat: 48.3277459, lng: 19.0304445 },
    { lat: 48.3273241, lng: 19.0311983 },
    { lat: 48.3268064, lng: 19.0323496 },
    { lat: 48.3264426, lng: 19.0333833 },
    { lat: 48.3262353, lng: 19.0345003 },
    { lat: 48.3259837, lng: 19.0351652 },
    { lat: 48.3254454, lng: 19.036002 },
    { lat: 48.3246402, lng: 19.0370256 },
    { lat: 48.3243798, lng: 19.0379813 },
    { lat: 48.3241532, lng: 19.0393232 },
    { lat: 48.3240333, lng: 19.0394931 },
    { lat: 48.3239084, lng: 19.040032 },
    { lat: 48.3236795, lng: 19.0402567 },
    { lat: 48.3230097, lng: 19.0404526 },
    { lat: 48.3226871, lng: 19.0406051 },
    { lat: 48.3221341, lng: 19.0410219 },
    { lat: 48.3218033, lng: 19.0410786 },
    { lat: 48.3211572, lng: 19.041547 },
    { lat: 48.320757, lng: 19.0420807 },
    { lat: 48.3207021, lng: 19.041976 },
    { lat: 48.3201836, lng: 19.0425603 },
    { lat: 48.3185799, lng: 19.0438247 },
    { lat: 48.3178391, lng: 19.0453371 },
    { lat: 48.3166322, lng: 19.0472718 },
    { lat: 48.3169832, lng: 19.0483028 },
    { lat: 48.3166254, lng: 19.0487645 },
    { lat: 48.3164294, lng: 19.0508451 },
    { lat: 48.3160347, lng: 19.0515586 },
    { lat: 48.3158244, lng: 19.0516601 },
    { lat: 48.3156881, lng: 19.0518622 },
    { lat: 48.3155968, lng: 19.0519073 },
    { lat: 48.315576, lng: 19.0523655 },
    { lat: 48.3145517, lng: 19.0543022 },
    { lat: 48.3146049, lng: 19.0543033 },
    { lat: 48.3147709, lng: 19.0542632 },
    { lat: 48.315049, lng: 19.0543673 },
    { lat: 48.3151749, lng: 19.0549655 },
    { lat: 48.3152683, lng: 19.0550952 },
    { lat: 48.3152929, lng: 19.0552256 },
    { lat: 48.3153425, lng: 19.0552026 },
    { lat: 48.3153615, lng: 19.054797 },
    { lat: 48.3153979, lng: 19.0547358 },
    { lat: 48.3159388, lng: 19.0548654 },
    { lat: 48.3160489, lng: 19.055081 },
    { lat: 48.3161677, lng: 19.0551544 },
    { lat: 48.3161768, lng: 19.0555366 },
    { lat: 48.3163205, lng: 19.0559981 },
    { lat: 48.3166488, lng: 19.0556871 },
    { lat: 48.3166174, lng: 19.0554424 },
    { lat: 48.3166898, lng: 19.0553849 },
    { lat: 48.3168935, lng: 19.0554412 },
    { lat: 48.3169857, lng: 19.0556167 },
    { lat: 48.3171578, lng: 19.055787 },
    { lat: 48.3171725, lng: 19.0560354 },
    { lat: 48.3171275, lng: 19.056127 },
    { lat: 48.3171183, lng: 19.0563434 },
    { lat: 48.3169863, lng: 19.0563874 },
    { lat: 48.3168985, lng: 19.0563052 },
    { lat: 48.3168069, lng: 19.0563199 },
    { lat: 48.3167304, lng: 19.0566108 },
    { lat: 48.3167756, lng: 19.0567258 },
    { lat: 48.3171909, lng: 19.0572019 },
    { lat: 48.3172926, lng: 19.0571851 },
    { lat: 48.3174658, lng: 19.0568211 },
    { lat: 48.3175759, lng: 19.0567874 },
    { lat: 48.3177573, lng: 19.0569224 },
    { lat: 48.3178913, lng: 19.0568969 },
    { lat: 48.3180895, lng: 19.056737 },
    { lat: 48.3183352, lng: 19.0563981 },
    { lat: 48.3187193, lng: 19.0564828 },
    { lat: 48.3187099, lng: 19.0567414 },
    { lat: 48.3187684, lng: 19.0568042 },
    { lat: 48.3188214, lng: 19.0567585 },
    { lat: 48.3188157, lng: 19.056694 },
    { lat: 48.3190945, lng: 19.0564321 },
    { lat: 48.319217, lng: 19.0564239 },
    { lat: 48.3193898, lng: 19.0565623 },
    { lat: 48.3194891, lng: 19.0569662 },
    { lat: 48.3196415, lng: 19.0567942 },
    { lat: 48.3197121, lng: 19.0568538 },
    { lat: 48.3197169, lng: 19.0570573 },
    { lat: 48.3197832, lng: 19.0572567 },
    { lat: 48.3197441, lng: 19.0577326 },
    { lat: 48.3199266, lng: 19.0576641 },
    { lat: 48.320047, lng: 19.0575215 },
    { lat: 48.3203352, lng: 19.0577609 },
    { lat: 48.3205411, lng: 19.0578201 },
    { lat: 48.3206245, lng: 19.0579318 },
    { lat: 48.3206736, lng: 19.0581232 },
    { lat: 48.3205444, lng: 19.058423 },
    { lat: 48.3203248, lng: 19.0585853 },
    { lat: 48.320095, lng: 19.0584976 },
    { lat: 48.3198562, lng: 19.0585272 },
    { lat: 48.3197913, lng: 19.0587354 },
    { lat: 48.3198667, lng: 19.0588901 },
    { lat: 48.3199711, lng: 19.0588386 },
    { lat: 48.320206, lng: 19.0589796 },
    { lat: 48.3204365, lng: 19.059301 },
    { lat: 48.3204028, lng: 19.059529 },
    { lat: 48.3201748, lng: 19.0595648 },
    { lat: 48.3200897, lng: 19.0596917 },
    { lat: 48.3199492, lng: 19.0601482 },
    { lat: 48.3199824, lng: 19.0603801 },
    { lat: 48.3200697, lng: 19.0605037 },
    { lat: 48.3201623, lng: 19.0608421 },
    { lat: 48.3202558, lng: 19.0609293 },
    { lat: 48.3203577, lng: 19.0609171 },
    { lat: 48.3203798, lng: 19.0609939 },
    { lat: 48.3202353, lng: 19.0613057 },
    { lat: 48.3202211, lng: 19.0615588 },
    { lat: 48.3202945, lng: 19.0619884 },
    { lat: 48.3203769, lng: 19.062148 },
    { lat: 48.3205146, lng: 19.0621522 },
    { lat: 48.3207376, lng: 19.0618985 },
    { lat: 48.3208104, lng: 19.0617773 },
    { lat: 48.3207771, lng: 19.0615887 },
    { lat: 48.3211422, lng: 19.0611587 },
    { lat: 48.321173, lng: 19.0609172 },
    { lat: 48.321311, lng: 19.0609278 },
    { lat: 48.3214432, lng: 19.0607566 },
    { lat: 48.3215338, lng: 19.0608292 },
    { lat: 48.3216079, lng: 19.0607096 },
    { lat: 48.3217199, lng: 19.060761 },
    { lat: 48.3217626, lng: 19.0610509 },
    { lat: 48.3219443, lng: 19.0611688 },
    { lat: 48.3220323, lng: 19.0617069 },
    { lat: 48.3222701, lng: 19.0617923 },
    { lat: 48.322332, lng: 19.0620612 },
    { lat: 48.322319, lng: 19.0623606 },
    { lat: 48.3224387, lng: 19.0627243 },
    { lat: 48.3223693, lng: 19.0629136 },
    { lat: 48.3223093, lng: 19.0633327 },
    { lat: 48.3223875, lng: 19.0635431 },
    { lat: 48.3237625, lng: 19.0654166 },
    { lat: 48.3244827, lng: 19.0658598 },
    { lat: 48.3253211, lng: 19.0662534 },
    { lat: 48.325533, lng: 19.0668583 },
    { lat: 48.325539, lng: 19.0673338 },
    { lat: 48.3257283, lng: 19.0674487 },
    { lat: 48.325777, lng: 19.0675615 },
    { lat: 48.3259136, lng: 19.0676092 },
    { lat: 48.3260268, lng: 19.0675078 },
    { lat: 48.3261605, lng: 19.0674965 },
    { lat: 48.3264042, lng: 19.0671434 },
    { lat: 48.326802, lng: 19.0669159 },
    { lat: 48.3272901, lng: 19.0670481 },
    { lat: 48.3273531, lng: 19.0669811 },
    { lat: 48.3286695, lng: 19.067399 },
    { lat: 48.3289338, lng: 19.0676745 },
    { lat: 48.3291342, lng: 19.0676709 },
    { lat: 48.3293269, lng: 19.0679616 },
    { lat: 48.3293546, lng: 19.0688031 },
    { lat: 48.3295244, lng: 19.0690826 },
    { lat: 48.3296189, lng: 19.0694174 },
    { lat: 48.3296461, lng: 19.0697321 },
    { lat: 48.3297147, lng: 19.0697527 },
    { lat: 48.3297031, lng: 19.0705756 },
    { lat: 48.3296168, lng: 19.0706293 },
    { lat: 48.3295639, lng: 19.0709446 },
    { lat: 48.3293858, lng: 19.0710583 },
    { lat: 48.3291815, lng: 19.0709648 },
    { lat: 48.3286134, lng: 19.0713479 },
    { lat: 48.3282225, lng: 19.071711 },
    { lat: 48.3280079, lng: 19.0717055 },
    { lat: 48.3279202, lng: 19.0717986 },
    { lat: 48.3277681, lng: 19.0722038 },
    { lat: 48.3279094, lng: 19.0727075 },
    { lat: 48.327837, lng: 19.0728355 },
    { lat: 48.3278503, lng: 19.0730356 },
    { lat: 48.3279108, lng: 19.073163 },
    { lat: 48.3280839, lng: 19.0732409 },
    { lat: 48.3282496, lng: 19.0738198 },
    { lat: 48.3285882, lng: 19.0737599 },
    { lat: 48.3287556, lng: 19.0738622 },
    { lat: 48.3289084, lng: 19.0742552 },
    { lat: 48.3289051, lng: 19.0744405 },
    { lat: 48.3287271, lng: 19.07471 },
    { lat: 48.3289284, lng: 19.0746543 },
    { lat: 48.329196, lng: 19.0747102 },
    { lat: 48.3293411, lng: 19.0749035 },
    { lat: 48.3292121, lng: 19.0752327 },
    { lat: 48.3295699, lng: 19.075554 },
    { lat: 48.3296008, lng: 19.075896 },
    { lat: 48.3297413, lng: 19.075957 },
    { lat: 48.3298166, lng: 19.0761766 },
    { lat: 48.3297898, lng: 19.0764525 },
    { lat: 48.3301046, lng: 19.0765487 },
    { lat: 48.3301847, lng: 19.0766378 },
    { lat: 48.3301592, lng: 19.0768033 },
    { lat: 48.3298968, lng: 19.077482 },
    { lat: 48.3304861, lng: 19.0777007 },
    { lat: 48.3305994, lng: 19.0779802 },
    { lat: 48.3306213, lng: 19.0786849 },
    { lat: 48.3308661, lng: 19.0790055 },
    { lat: 48.3309574, lng: 19.0793609 },
    { lat: 48.3313309, lng: 19.0796142 },
    { lat: 48.3315368, lng: 19.0795842 },
    { lat: 48.3316652, lng: 19.0793791 },
    { lat: 48.3318313, lng: 19.0793192 },
    { lat: 48.3323044, lng: 19.0794011 },
    { lat: 48.3328498, lng: 19.0797803 },
    { lat: 48.3332308, lng: 19.080317 },
    { lat: 48.3333172, lng: 19.0806686 },
    { lat: 48.3334528, lng: 19.080654 },
    { lat: 48.3335755, lng: 19.0805371 },
    { lat: 48.3336701, lng: 19.0806894 },
    { lat: 48.3337029, lng: 19.0808494 },
    { lat: 48.3336165, lng: 19.0811706 },
    { lat: 48.3336337, lng: 19.0814053 },
    { lat: 48.3338422, lng: 19.0813137 },
    { lat: 48.334054, lng: 19.0815333 },
    { lat: 48.3343273, lng: 19.0820835 },
    { lat: 48.3343739, lng: 19.0824506 },
    { lat: 48.3344291, lng: 19.0825401 },
    { lat: 48.3346485, lng: 19.082415 },
    { lat: 48.3348282, lng: 19.0822024 },
    { lat: 48.3351322, lng: 19.0821025 },
    { lat: 48.3356056, lng: 19.0818102 },
    { lat: 48.3366097, lng: 19.0815321 },
    { lat: 48.3369738, lng: 19.0819109 },
    { lat: 48.3372884, lng: 19.0821401 },
    { lat: 48.3376798, lng: 19.0822557 },
    { lat: 48.3377735, lng: 19.0825701 },
    { lat: 48.338159, lng: 19.0823232 },
    { lat: 48.3384476, lng: 19.0824104 },
    { lat: 48.3385325, lng: 19.0823512 },
    { lat: 48.3385847, lng: 19.0821355 },
    { lat: 48.338665, lng: 19.0820733 },
    { lat: 48.3390076, lng: 19.0822727 },
    { lat: 48.3392845, lng: 19.0821738 },
    { lat: 48.3393805, lng: 19.0823539 },
    { lat: 48.339486, lng: 19.0823711 },
    { lat: 48.3397215, lng: 19.0822325 },
    { lat: 48.3401217, lng: 19.082187 },
    { lat: 48.3406862, lng: 19.0825361 },
    { lat: 48.3408079, lng: 19.0827159 },
    { lat: 48.341514, lng: 19.0826773 },
    { lat: 48.3420699, lng: 19.0830178 },
    { lat: 48.3421988, lng: 19.0832272 },
    { lat: 48.3422936, lng: 19.083275 },
    { lat: 48.3424196, lng: 19.0832484 },
    { lat: 48.3425586, lng: 19.082779 },
    { lat: 48.3429598, lng: 19.082979 },
    { lat: 48.3427247, lng: 19.0838022 },
    { lat: 48.3433405, lng: 19.084187 },
    { lat: 48.3433356, lng: 19.0844934 },
    { lat: 48.3433784, lng: 19.0845612 },
    { lat: 48.3434972, lng: 19.0846176 },
    { lat: 48.3436691, lng: 19.0845592 },
    { lat: 48.3438362, lng: 19.0842725 },
    { lat: 48.3439147, lng: 19.0842416 },
    { lat: 48.3441793, lng: 19.0844099 },
    { lat: 48.3442919, lng: 19.0847491 },
    { lat: 48.3443556, lng: 19.0847791 },
    { lat: 48.3445308, lng: 19.0847023 },
    { lat: 48.3445062, lng: 19.0850644 },
    { lat: 48.3447951, lng: 19.0852048 },
    { lat: 48.3449681, lng: 19.0856776 },
    { lat: 48.3450958, lng: 19.0857839 },
    { lat: 48.3450357, lng: 19.0859803 },
    { lat: 48.345081, lng: 19.0861382 },
    { lat: 48.3451369, lng: 19.0861759 },
    { lat: 48.3453197, lng: 19.0860886 },
    { lat: 48.3453978, lng: 19.0859593 },
    { lat: 48.3455431, lng: 19.0859794 },
    { lat: 48.3459883, lng: 19.0864707 },
    { lat: 48.3460703, lng: 19.086694 },
    { lat: 48.3461639, lng: 19.086783 },
    { lat: 48.3466726, lng: 19.0870782 },
    { lat: 48.3470796, lng: 19.087099 },
    { lat: 48.3476142, lng: 19.0876458 },
    { lat: 48.3478879, lng: 19.0880441 },
    { lat: 48.3479, lng: 19.0881735 },
    { lat: 48.3477679, lng: 19.0884652 },
    { lat: 48.34778, lng: 19.0885936 },
    { lat: 48.3479496, lng: 19.0887158 },
    { lat: 48.3480346, lng: 19.0887481 },
    { lat: 48.3481681, lng: 19.0887986 },
    { lat: 48.3483652, lng: 19.0890652 },
    { lat: 48.3486148, lng: 19.0896148 },
    { lat: 48.34901, lng: 19.0899197 },
    { lat: 48.3493244, lng: 19.0907065 },
    { lat: 48.3494172, lng: 19.0908243 },
    { lat: 48.3496507, lng: 19.0910061 },
    { lat: 48.3499481, lng: 19.091048 },
    { lat: 48.3500366, lng: 19.0911256 },
    { lat: 48.3502085, lng: 19.0916333 },
    { lat: 48.3506508, lng: 19.0920456 },
    { lat: 48.350818, lng: 19.0923461 },
    { lat: 48.3509457, lng: 19.0928899 },
    { lat: 48.3513622, lng: 19.0934622 },
    { lat: 48.3515631, lng: 19.0933549 },
    { lat: 48.3517557, lng: 19.093532 },
    { lat: 48.3517948, lng: 19.0939054 },
    { lat: 48.3517455, lng: 19.0942048 },
    { lat: 48.3518042, lng: 19.0948167 },
    { lat: 48.3520127, lng: 19.0951537 },
    { lat: 48.3521914, lng: 19.0950761 },
    { lat: 48.3523231, lng: 19.09514 },
    { lat: 48.3525615, lng: 19.0956448 },
    { lat: 48.3529812, lng: 19.0959647 },
    { lat: 48.3531726, lng: 19.095985 },
    { lat: 48.3535209, lng: 19.0957115 },
    { lat: 48.3537361, lng: 19.0957779 },
    { lat: 48.3537668, lng: 19.0958954 },
    { lat: 48.3537246, lng: 19.0961996 },
    { lat: 48.3538704, lng: 19.0963643 },
    { lat: 48.3541327, lng: 19.0964036 },
    { lat: 48.3542503, lng: 19.0964974 },
    { lat: 48.3543183, lng: 19.096775 },
    { lat: 48.3542467, lng: 19.0969922 },
    { lat: 48.3542573, lng: 19.0971346 },
    { lat: 48.3543628, lng: 19.0971702 },
    { lat: 48.3545092, lng: 19.097102 },
    { lat: 48.3546113, lng: 19.097273 },
    { lat: 48.3546253, lng: 19.0974813 },
    { lat: 48.3548722, lng: 19.0977098 },
    { lat: 48.3550411, lng: 19.0977046 },
    { lat: 48.3551889, lng: 19.0974798 },
    { lat: 48.3554905, lng: 19.0976309 },
    { lat: 48.3558108, lng: 19.0976771 },
    { lat: 48.3560691, lng: 19.0976345 },
    { lat: 48.3561821, lng: 19.0976842 },
    { lat: 48.3562559, lng: 19.098013 },
    { lat: 48.3564847, lng: 19.0981237 },
    { lat: 48.3567125, lng: 19.0983944 },
    { lat: 48.3568227, lng: 19.0983859 },
    { lat: 48.3569499, lng: 19.0981376 },
    { lat: 48.3570478, lng: 19.0981538 },
    { lat: 48.3571193, lng: 19.098322 },
    { lat: 48.3571389, lng: 19.0985594 },
    { lat: 48.3570716, lng: 19.0987194 },
    { lat: 48.3571358, lng: 19.0988979 },
    { lat: 48.3572093, lng: 19.098951 },
    { lat: 48.357441, lng: 19.0988029 },
    { lat: 48.3575847, lng: 19.09888 },
    { lat: 48.3575158, lng: 19.0992649 },
    { lat: 48.3576385, lng: 19.0996871 },
    { lat: 48.3577312, lng: 19.0997596 },
    { lat: 48.3579882, lng: 19.0996215 },
    { lat: 48.3581374, lng: 19.0996093 },
    { lat: 48.3582502, lng: 19.0997026 },
    { lat: 48.3583291, lng: 19.0998997 },
    { lat: 48.3583191, lng: 19.1004463 },
    { lat: 48.3584232, lng: 19.1007902 },
    { lat: 48.3586903, lng: 19.101356 },
    { lat: 48.3587397, lng: 19.1015553 },
    { lat: 48.3589512, lng: 19.1017502 },
    { lat: 48.3589214, lng: 19.1021879 },
    { lat: 48.3591806, lng: 19.1025256 },
    { lat: 48.359133, lng: 19.1030141 },
    { lat: 48.3591628, lng: 19.1032993 },
    { lat: 48.3592227, lng: 19.1034578 },
    { lat: 48.3596488, lng: 19.1038388 },
    { lat: 48.3595978, lng: 19.1043986 },
    { lat: 48.3597219, lng: 19.1044707 },
    { lat: 48.3599483, lng: 19.1043967 },
    { lat: 48.3600669, lng: 19.1044727 },
    { lat: 48.3604104, lng: 19.1049587 },
    { lat: 48.3604899, lng: 19.1051263 },
    { lat: 48.3604939, lng: 19.1052549 },
    { lat: 48.3603741, lng: 19.1056324 },
    { lat: 48.3604029, lng: 19.1058685 },
    { lat: 48.3608954, lng: 19.1063885 },
    { lat: 48.3611439, lng: 19.1070064 },
    { lat: 48.3614547, lng: 19.10727 },
    { lat: 48.3615814, lng: 19.107707 },
    { lat: 48.3615573, lng: 19.1081507 },
    { lat: 48.3616168, lng: 19.1082592 },
    { lat: 48.3618731, lng: 19.1083542 },
    { lat: 48.3619606, lng: 19.1088013 },
    { lat: 48.3621353, lng: 19.1091581 },
    { lat: 48.3624656, lng: 19.1096092 },
    { lat: 48.3625386, lng: 19.1096268 },
    { lat: 48.3626744, lng: 19.1094563 },
    { lat: 48.3629841, lng: 19.109313 },
    { lat: 48.3633778, lng: 19.1087352 },
    { lat: 48.3635873, lng: 19.1085493 },
    { lat: 48.3641063, lng: 19.1086001 },
    { lat: 48.364352, lng: 19.108079 },
    { lat: 48.3645179, lng: 19.1079955 },
    { lat: 48.36483, lng: 19.1079678 },
    { lat: 48.3650087, lng: 19.1081353 },
    { lat: 48.3650547, lng: 19.1083797 },
    { lat: 48.3652558, lng: 19.1086141 },
    { lat: 48.3653912, lng: 19.1086609 },
    { lat: 48.3658182, lng: 19.1085878 },
    { lat: 48.3659121, lng: 19.1086431 },
    { lat: 48.3660904, lng: 19.1089822 },
    { lat: 48.3662357, lng: 19.1094786 },
    { lat: 48.3664576, lng: 19.109477 },
    { lat: 48.3665247, lng: 19.1096889 },
    { lat: 48.3665733, lng: 19.1100281 },
    { lat: 48.3666532, lng: 19.1102524 },
    { lat: 48.3665605, lng: 19.1104057 },
    { lat: 48.3665242, lng: 19.1106267 },
    { lat: 48.3667202, lng: 19.1109419 },
    { lat: 48.3666423, lng: 19.111346 },
    { lat: 48.3667146, lng: 19.1117339 },
    { lat: 48.3667564, lng: 19.1117809 },
    { lat: 48.3669047, lng: 19.111705 },
    { lat: 48.367039, lng: 19.1120654 },
    { lat: 48.3672849, lng: 19.1124069 },
    { lat: 48.3678768, lng: 19.1127453 },
    { lat: 48.3680144, lng: 19.1126153 },
    { lat: 48.3681166, lng: 19.1126513 },
    { lat: 48.3683155, lng: 19.1131342 },
    { lat: 48.3684336, lng: 19.1129263 },
    { lat: 48.3685713, lng: 19.112864 },
    { lat: 48.3689908, lng: 19.1129328 },
    { lat: 48.3691417, lng: 19.1136055 },
    { lat: 48.3692521, lng: 19.1136955 },
    { lat: 48.3694584, lng: 19.113627 },
    { lat: 48.3696273, lng: 19.1137821 },
    { lat: 48.3698295, lng: 19.1141749 },
    { lat: 48.370034, lng: 19.1140935 },
    { lat: 48.3701358, lng: 19.1141689 },
    { lat: 48.3702967, lng: 19.1147277 },
    { lat: 48.3703749, lng: 19.1148228 },
    { lat: 48.3704624, lng: 19.114863 },
    { lat: 48.3705986, lng: 19.1147885 },
    { lat: 48.3710182, lng: 19.1151555 },
    { lat: 48.3711946, lng: 19.1154612 },
    { lat: 48.3713038, lng: 19.1155244 },
    { lat: 48.3715756, lng: 19.1155265 },
    { lat: 48.3720943, lng: 19.1157757 },
    { lat: 48.3722898, lng: 19.1163533 },
    { lat: 48.3725285, lng: 19.116346 },
    { lat: 48.3728164, lng: 19.1164481 },
    { lat: 48.3729989, lng: 19.116602 },
    { lat: 48.3737712, lng: 19.1179581 },
    { lat: 48.3739375, lng: 19.1180622 },
    { lat: 48.3742033, lng: 19.1185096 },
    { lat: 48.3741078, lng: 19.1187412 },
    { lat: 48.3745557, lng: 19.1192718 },
    { lat: 48.3746375, lng: 19.1195519 },
    { lat: 48.374988, lng: 19.1197133 },
    { lat: 48.3751899, lng: 19.119986 },
    { lat: 48.375426, lng: 19.1200645 },
    { lat: 48.3756705, lng: 19.1199934 },
    { lat: 48.3757358, lng: 19.1197386 },
    { lat: 48.3762457, lng: 19.1194516 },
    { lat: 48.3768329, lng: 19.1194062 },
    { lat: 48.3770737, lng: 19.119465 },
    { lat: 48.3774273, lng: 19.1194112 },
    { lat: 48.3775444, lng: 19.1195675 },
    { lat: 48.3777062, lng: 19.1196667 },
    { lat: 48.3779022, lng: 19.1199348 },
    { lat: 48.3781032, lng: 19.120058 },
    { lat: 48.3785875, lng: 19.1201806 },
    { lat: 48.3787187, lng: 19.1206425 },
    { lat: 48.3787811, lng: 19.1206777 },
    { lat: 48.3789936, lng: 19.1203471 },
    { lat: 48.3794437, lng: 19.1207796 },
    { lat: 48.3794805, lng: 19.1204797 },
    { lat: 48.3795689, lng: 19.1204425 },
    { lat: 48.379841, lng: 19.1206322 },
    { lat: 48.3803039, lng: 19.1204639 },
    { lat: 48.380384, lng: 19.1205783 },
    { lat: 48.380529, lng: 19.1210947 },
    { lat: 48.3806103, lng: 19.1211182 },
    { lat: 48.3807711, lng: 19.120814 },
    { lat: 48.380814, lng: 19.1208169 },
    { lat: 48.3811493, lng: 19.1208935 },
    { lat: 48.381128, lng: 19.1212828 },
    { lat: 48.3811871, lng: 19.1213592 },
    { lat: 48.3815018, lng: 19.1213412 },
    { lat: 48.3817431, lng: 19.1215223 },
    { lat: 48.3819923, lng: 19.1212745 },
    { lat: 48.382103, lng: 19.1212784 },
    { lat: 48.3821837, lng: 19.1214041 },
    { lat: 48.3821966, lng: 19.1215729 },
    { lat: 48.382359, lng: 19.121959 },
    { lat: 48.3825357, lng: 19.1219554 },
    { lat: 48.3826822, lng: 19.1220408 },
    { lat: 48.3827487, lng: 19.122134 },
    { lat: 48.3827678, lng: 19.1223376 },
    { lat: 48.3828489, lng: 19.122447 },
    { lat: 48.3830889, lng: 19.1226037 },
    { lat: 48.3833357, lng: 19.1225782 },
    { lat: 48.3835232, lng: 19.122809 },
    { lat: 48.3837821, lng: 19.1226242 },
    { lat: 48.3838952, lng: 19.1227899 },
    { lat: 48.3841776, lng: 19.1228682 },
    { lat: 48.3843149, lng: 19.1227737 },
    { lat: 48.3844891, lng: 19.1227619 },
    { lat: 48.3846055, lng: 19.1230404 },
    { lat: 48.3846575, lng: 19.1233339 },
    { lat: 48.3847427, lng: 19.1233705 },
    { lat: 48.3848548, lng: 19.1232923 },
    { lat: 48.3850187, lng: 19.1233668 },
    { lat: 48.3850844, lng: 19.1235116 },
    { lat: 48.3855928, lng: 19.1236681 },
    { lat: 48.3857051, lng: 19.1237984 },
    { lat: 48.3857401, lng: 19.1241346 },
    { lat: 48.3858083, lng: 19.1241908 },
    { lat: 48.3859642, lng: 19.1241531 },
    { lat: 48.3862445, lng: 19.124759 },
    { lat: 48.386346, lng: 19.1248519 },
    { lat: 48.3864951, lng: 19.1248341 },
    { lat: 48.3865798, lng: 19.1249019 },
    { lat: 48.3866017, lng: 19.1250045 },
    { lat: 48.38653, lng: 19.1251934 },
    { lat: 48.3865457, lng: 19.1252856 },
    { lat: 48.3868249, lng: 19.1255036 },
    { lat: 48.3868911, lng: 19.1257466 },
    { lat: 48.3870802, lng: 19.1258534 },
    { lat: 48.3871614, lng: 19.1260554 },
    { lat: 48.387353, lng: 19.12619 },
    { lat: 48.387578, lng: 19.126522 },
    { lat: 48.387768, lng: 19.126683 },
    { lat: 48.388005, lng: 19.126887 },
    { lat: 48.388217, lng: 19.127045 },
    { lat: 48.388412, lng: 19.127213 },
    { lat: 48.388582, lng: 19.127321 },
    { lat: 48.388684, lng: 19.127275 },
    { lat: 48.388827, lng: 19.127387 },
    { lat: 48.388936, lng: 19.127492 },
    { lat: 48.3894, lng: 19.127583 },
    { lat: 48.389635, lng: 19.127518 },
    { lat: 48.390196, lng: 19.12744 },
    { lat: 48.390635, lng: 19.127315 },
    { lat: 48.390918, lng: 19.127235 },
    { lat: 48.391209, lng: 19.127284 },
    { lat: 48.391479, lng: 19.127487 },
    { lat: 48.392013, lng: 19.127706 },
    { lat: 48.39248, lng: 19.127596 },
    { lat: 48.392924, lng: 19.127605 },
    { lat: 48.392954, lng: 19.127018 },
    { lat: 48.393016, lng: 19.126955 },
    { lat: 48.393207, lng: 19.126441 },
    { lat: 48.393427, lng: 19.125771 },
    { lat: 48.393521, lng: 19.125588 },
    { lat: 48.393625, lng: 19.125138 },
    { lat: 48.393705, lng: 19.124958 },
    { lat: 48.393771, lng: 19.124789 },
    { lat: 48.393973, lng: 19.124162 },
    { lat: 48.394233, lng: 19.123558 },
    { lat: 48.394342, lng: 19.12357 },
    { lat: 48.394647, lng: 19.123468 },
    { lat: 48.394845, lng: 19.123423 },
    { lat: 48.395151, lng: 19.123322 },
    { lat: 48.395212, lng: 19.123179 },
    { lat: 48.395342, lng: 19.123052 },
    { lat: 48.395375, lng: 19.123047 },
    { lat: 48.395702, lng: 19.12277 },
    { lat: 48.395796, lng: 19.122651 },
    { lat: 48.395934, lng: 19.122482 },
    { lat: 48.396086, lng: 19.122436 },
    { lat: 48.39622, lng: 19.122075 },
    { lat: 48.396328, lng: 19.121858 },
    { lat: 48.396471, lng: 19.121573 },
    { lat: 48.396559, lng: 19.121391 },
    { lat: 48.396721, lng: 19.121559 },
    { lat: 48.396868, lng: 19.121713 },
    { lat: 48.397173, lng: 19.121374 },
    { lat: 48.397357, lng: 19.121121 },
    { lat: 48.397557, lng: 19.121075 },
    { lat: 48.397593, lng: 19.121005 },
    { lat: 48.397747, lng: 19.120483 },
    { lat: 48.397768, lng: 19.120398 },
    { lat: 48.398142, lng: 19.120707 },
    { lat: 48.398495, lng: 19.12038 },
    { lat: 48.398566, lng: 19.120358 },
    { lat: 48.398906, lng: 19.120193 },
    { lat: 48.399009, lng: 19.120033 },
    { lat: 48.399117, lng: 19.12008 },
    { lat: 48.399388, lng: 19.119922 },
    { lat: 48.399502, lng: 19.119843 },
    { lat: 48.399315, lng: 19.119316 },
    { lat: 48.399424, lng: 19.119194 },
    { lat: 48.399733, lng: 19.118851 },
    { lat: 48.39978, lng: 19.118797 },
    { lat: 48.399976, lng: 19.118744 },
    { lat: 48.400133, lng: 19.118701 },
    { lat: 48.400172, lng: 19.118635 },
    { lat: 48.400244, lng: 19.118522 },
    { lat: 48.400305, lng: 19.118455 },
    { lat: 48.400359, lng: 19.118355 },
    { lat: 48.400441, lng: 19.118281 },
    { lat: 48.400981, lng: 19.117853 },
    { lat: 48.401213, lng: 19.117744 },
    { lat: 48.401383, lng: 19.117742 },
    { lat: 48.401449, lng: 19.117802 },
    { lat: 48.401812, lng: 19.117911 },
    { lat: 48.401938, lng: 19.117962 },
    { lat: 48.402662, lng: 19.118318 },
    { lat: 48.402705, lng: 19.118339 },
    { lat: 48.403096, lng: 19.118532 },
    { lat: 48.403301, lng: 19.118523 },
    { lat: 48.403529, lng: 19.118411 },
    { lat: 48.403658, lng: 19.118427 },
    { lat: 48.404526, lng: 19.118211 },
    { lat: 48.404927, lng: 19.11789 },
    { lat: 48.40512, lng: 19.117924 },
    { lat: 48.405321, lng: 19.117797 },
    { lat: 48.405476, lng: 19.117694 },
    { lat: 48.405828, lng: 19.117624 },
    { lat: 48.406198, lng: 19.117322 },
    { lat: 48.406469, lng: 19.117207 },
    { lat: 48.40675, lng: 19.117018 },
    { lat: 48.406947, lng: 19.116847 },
    { lat: 48.407209, lng: 19.116706 },
    { lat: 48.407623, lng: 19.116548 },
    { lat: 48.40778, lng: 19.116488 },
    { lat: 48.407959, lng: 19.116598 },
    { lat: 48.408146, lng: 19.116584 },
    { lat: 48.408329, lng: 19.116665 },
    { lat: 48.408492, lng: 19.116676 },
    { lat: 48.408576, lng: 19.116756 },
    { lat: 48.408707, lng: 19.116673 },
    { lat: 48.409095, lng: 19.116654 },
    { lat: 48.409612, lng: 19.116651 },
    { lat: 48.409842, lng: 19.116534 },
    { lat: 48.410385, lng: 19.116674 },
    { lat: 48.410836, lng: 19.117139 },
    { lat: 48.411238, lng: 19.117347 },
    { lat: 48.411592, lng: 19.117428 },
    { lat: 48.411874, lng: 19.117555 },
    { lat: 48.412016, lng: 19.117448 },
    { lat: 48.412169, lng: 19.117282 },
    { lat: 48.412324, lng: 19.117178 },
    { lat: 48.412814, lng: 19.117195 },
    { lat: 48.413173, lng: 19.117165 },
    { lat: 48.413309, lng: 19.117071 },
    { lat: 48.413508, lng: 19.117139 },
    { lat: 48.413804, lng: 19.117053 },
    { lat: 48.414378, lng: 19.116923 },
    { lat: 48.414384, lng: 19.11699 },
    { lat: 48.414867, lng: 19.116589 },
    { lat: 48.415105, lng: 19.117245 },
    { lat: 48.41514, lng: 19.117257 },
    { lat: 48.41537, lng: 19.11734 },
    { lat: 48.415976, lng: 19.117461 },
    { lat: 48.415849, lng: 19.11686 },
    { lat: 48.415687, lng: 19.11567 },
    { lat: 48.415718, lng: 19.115628 },
    { lat: 48.415749, lng: 19.115586 },
    { lat: 48.4156994, lng: 19.1154811 },
    { lat: 48.415004, lng: 19.11412 },
    { lat: 48.415035, lng: 19.114059 },
    { lat: 48.415122, lng: 19.112991 },
    { lat: 48.415087, lng: 19.112551 },
    { lat: 48.414835, lng: 19.112298 },
    { lat: 48.414633, lng: 19.111953 },
    { lat: 48.414441, lng: 19.111165 },
    { lat: 48.414666, lng: 19.110551 },
    { lat: 48.414918, lng: 19.110513 },
    { lat: 48.415321, lng: 19.110717 },
    { lat: 48.41555, lng: 19.110718 },
    { lat: 48.415806, lng: 19.110476 },
    { lat: 48.416068, lng: 19.109958 },
    { lat: 48.416624, lng: 19.109884 },
    { lat: 48.416662, lng: 19.10988 },
    { lat: 48.417088, lng: 19.110202 },
    { lat: 48.417645, lng: 19.109776 },
    { lat: 48.418038, lng: 19.10922 },
    { lat: 48.418213, lng: 19.108823 },
    { lat: 48.418378, lng: 19.107853 },
    { lat: 48.418464, lng: 19.107578 },
    { lat: 48.418618, lng: 19.107449 },
    { lat: 48.418816, lng: 19.106996 },
    { lat: 48.419176, lng: 19.106663 },
    { lat: 48.419304, lng: 19.106382 },
    { lat: 48.419326, lng: 19.106026 },
    { lat: 48.41928, lng: 19.105304 },
  ],
  KozíVrbovok: [
    { lat: 48.2923085, lng: 19.0934911 },
    { lat: 48.2917946, lng: 19.0929329 },
    { lat: 48.2915835, lng: 19.0939858 },
    { lat: 48.2899861, lng: 19.0942573 },
    { lat: 48.2880494, lng: 19.0931116 },
    { lat: 48.2865911, lng: 19.0923359 },
    { lat: 48.2860844, lng: 19.0919682 },
    { lat: 48.2855356, lng: 19.0935669 },
    { lat: 48.2855852, lng: 19.0939528 },
    { lat: 48.2850687, lng: 19.0939455 },
    { lat: 48.2844986, lng: 19.0942475 },
    { lat: 48.2839689, lng: 19.0943822 },
    { lat: 48.2836278, lng: 19.0943926 },
    { lat: 48.2834124, lng: 19.0944841 },
    { lat: 48.2829622, lng: 19.0956692 },
    { lat: 48.282398, lng: 19.0967152 },
    { lat: 48.2823444, lng: 19.0969078 },
    { lat: 48.2819725, lng: 19.0974505 },
    { lat: 48.28127, lng: 19.0987775 },
    { lat: 48.2813636, lng: 19.0992253 },
    { lat: 48.2811138, lng: 19.0998654 },
    { lat: 48.2812372, lng: 19.1002366 },
    { lat: 48.2811077, lng: 19.1010755 },
    { lat: 48.2810796, lng: 19.1015933 },
    { lat: 48.281363, lng: 19.1025232 },
    { lat: 48.2816303, lng: 19.103945 },
    { lat: 48.2812995, lng: 19.1044313 },
    { lat: 48.2811942, lng: 19.104446 },
    { lat: 48.2809189, lng: 19.1048231 },
    { lat: 48.2805518, lng: 19.1051018 },
    { lat: 48.2806053, lng: 19.1053814 },
    { lat: 48.2803081, lng: 19.1052594 },
    { lat: 48.2801172, lng: 19.1054295 },
    { lat: 48.2799688, lng: 19.1058196 },
    { lat: 48.2793159, lng: 19.10609 },
    { lat: 48.2788949, lng: 19.1068232 },
    { lat: 48.2788422, lng: 19.1072142 },
    { lat: 48.2789468, lng: 19.1075005 },
    { lat: 48.2788634, lng: 19.1078599 },
    { lat: 48.278737, lng: 19.1079739 },
    { lat: 48.2787932, lng: 19.1081937 },
    { lat: 48.2787547, lng: 19.1084586 },
    { lat: 48.2788344, lng: 19.1091752 },
    { lat: 48.2788426, lng: 19.1102424 },
    { lat: 48.2788464, lng: 19.1106097 },
    { lat: 48.2793135, lng: 19.1112496 },
    { lat: 48.2798761, lng: 19.1119283 },
    { lat: 48.2800138, lng: 19.112316 },
    { lat: 48.2801502, lng: 19.1129459 },
    { lat: 48.2802551, lng: 19.1130598 },
    { lat: 48.2819778, lng: 19.1134666 },
    { lat: 48.2827916, lng: 19.1137594 },
    { lat: 48.2839647, lng: 19.114449 },
    { lat: 48.2848779, lng: 19.1148542 },
    { lat: 48.2857513, lng: 19.1154129 },
    { lat: 48.2879145, lng: 19.1159031 },
    { lat: 48.2881353, lng: 19.1166501 },
    { lat: 48.2884365, lng: 19.11672 },
    { lat: 48.2887489, lng: 19.1171056 },
    { lat: 48.2889999, lng: 19.1177508 },
    { lat: 48.2890349, lng: 19.1182258 },
    { lat: 48.2894882, lng: 19.1196961 },
    { lat: 48.2898825, lng: 19.1203281 },
    { lat: 48.2902306, lng: 19.120613 },
    { lat: 48.2902601, lng: 19.1208221 },
    { lat: 48.2905388, lng: 19.1209144 },
    { lat: 48.2906055, lng: 19.1212819 },
    { lat: 48.29088, lng: 19.1212249 },
    { lat: 48.2910486, lng: 19.1212836 },
    { lat: 48.2912778, lng: 19.1216742 },
    { lat: 48.2917281, lng: 19.1221155 },
    { lat: 48.2922107, lng: 19.1223649 },
    { lat: 48.2924919, lng: 19.1228931 },
    { lat: 48.2929147, lng: 19.1234596 },
    { lat: 48.292956, lng: 19.1237247 },
    { lat: 48.293562, lng: 19.1247076 },
    { lat: 48.294657, lng: 19.1260256 },
    { lat: 48.2953839, lng: 19.1267883 },
    { lat: 48.2953966, lng: 19.1270244 },
    { lat: 48.2955557, lng: 19.1271164 },
    { lat: 48.2958233, lng: 19.1276416 },
    { lat: 48.2959, lng: 19.1276457 },
    { lat: 48.2959482, lng: 19.1278885 },
    { lat: 48.2963835, lng: 19.128977 },
    { lat: 48.296828, lng: 19.1295054 },
    { lat: 48.2968073, lng: 19.1296129 },
    { lat: 48.2969228, lng: 19.1296453 },
    { lat: 48.2983115, lng: 19.1312946 },
    { lat: 48.299537, lng: 19.1318147 },
    { lat: 48.3003628, lng: 19.132326 },
    { lat: 48.3022013, lng: 19.1343827 },
    { lat: 48.3031962, lng: 19.1353207 },
    { lat: 48.3032283, lng: 19.1351974 },
    { lat: 48.3058353, lng: 19.1363643 },
    { lat: 48.30602, lng: 19.1359307 },
    { lat: 48.3063301, lng: 19.1344814 },
    { lat: 48.3065305, lng: 19.1339317 },
    { lat: 48.3075029, lng: 19.1321098 },
    { lat: 48.308153, lng: 19.1302254 },
    { lat: 48.3095451, lng: 19.1272968 },
    { lat: 48.3095906, lng: 19.1254257 },
    { lat: 48.3096605, lng: 19.1252253 },
    { lat: 48.3097023, lng: 19.1251111 },
    { lat: 48.3096175, lng: 19.1250172 },
    { lat: 48.3097576, lng: 19.1245242 },
    { lat: 48.3096335, lng: 19.12423 },
    { lat: 48.3089834, lng: 19.1236696 },
    { lat: 48.307947, lng: 19.1224888 },
    { lat: 48.3069178, lng: 19.1202981 },
    { lat: 48.3071677, lng: 19.1176443 },
    { lat: 48.307214, lng: 19.1162006 },
    { lat: 48.305655, lng: 19.1148266 },
    { lat: 48.30445, lng: 19.1130442 },
    { lat: 48.3049643, lng: 19.1108821 },
    { lat: 48.3050238, lng: 19.1104278 },
    { lat: 48.3046082, lng: 19.1099585 },
    { lat: 48.3033683, lng: 19.1092416 },
    { lat: 48.3032183, lng: 19.1091517 },
    { lat: 48.3027724, lng: 19.1092956 },
    { lat: 48.3011096, lng: 19.1091197 },
    { lat: 48.3005311, lng: 19.1087516 },
    { lat: 48.3003624, lng: 19.1085135 },
    { lat: 48.3002367, lng: 19.1080744 },
    { lat: 48.2989189, lng: 19.1066981 },
    { lat: 48.2985329, lng: 19.1064189 },
    { lat: 48.2986388, lng: 19.1057212 },
    { lat: 48.2984937, lng: 19.1051625 },
    { lat: 48.2973661, lng: 19.1033542 },
    { lat: 48.2968106, lng: 19.1027513 },
    { lat: 48.2959941, lng: 19.1020472 },
    { lat: 48.294839, lng: 19.1000071 },
    { lat: 48.2945317, lng: 19.0996575 },
    { lat: 48.2932334, lng: 19.0987219 },
    { lat: 48.2930403, lng: 19.0985114 },
    { lat: 48.2916773, lng: 19.0963961 },
    { lat: 48.292138, lng: 19.094293 },
    { lat: 48.2923085, lng: 19.0934911 },
  ],
  HontianskeTesáre: [
    { lat: 48.2392322, lng: 18.95857 },
    { lat: 48.2380639, lng: 18.957973 },
    { lat: 48.2393869, lng: 18.9510285 },
    { lat: 48.239055, lng: 18.9507137 },
    { lat: 48.2379403, lng: 18.9501079 },
    { lat: 48.2370444, lng: 18.9498105 },
    { lat: 48.2364118, lng: 18.9495007 },
    { lat: 48.2364871, lng: 18.9478559 },
    { lat: 48.2368534, lng: 18.946517 },
    { lat: 48.2367992, lng: 18.9464454 },
    { lat: 48.2376839, lng: 18.9451886 },
    { lat: 48.238628, lng: 18.9451507 },
    { lat: 48.2389079, lng: 18.9425753 },
    { lat: 48.2389532, lng: 18.9408893 },
    { lat: 48.2399256, lng: 18.9402461 },
    { lat: 48.2408494, lng: 18.9403584 },
    { lat: 48.2425686, lng: 18.9402667 },
    { lat: 48.2435876, lng: 18.9397097 },
    { lat: 48.2447614, lng: 18.938925 },
    { lat: 48.24552, lng: 18.9390514 },
    { lat: 48.2461212, lng: 18.9392289 },
    { lat: 48.2465904, lng: 18.9390799 },
    { lat: 48.2469102, lng: 18.9393521 },
    { lat: 48.2479092, lng: 18.9397706 },
    { lat: 48.2480401, lng: 18.9397508 },
    { lat: 48.2484475, lng: 18.9394958 },
    { lat: 48.2485232, lng: 18.9393237 },
    { lat: 48.248695, lng: 18.938933 },
    { lat: 48.2488728, lng: 18.9387748 },
    { lat: 48.2494008, lng: 18.9388932 },
    { lat: 48.2496996, lng: 18.9388261 },
    { lat: 48.2506651, lng: 18.9378475 },
    { lat: 48.2509325, lng: 18.9376305 },
    { lat: 48.2513373, lng: 18.9374326 },
    { lat: 48.2516937, lng: 18.9370872 },
    { lat: 48.2519358, lng: 18.9368377 },
    { lat: 48.2522084, lng: 18.9364365 },
    { lat: 48.253456, lng: 18.9340414 },
    { lat: 48.2536678, lng: 18.9338411 },
    { lat: 48.2539562, lng: 18.9337929 },
    { lat: 48.2542854, lng: 18.9336161 },
    { lat: 48.2548228, lng: 18.9335211 },
    { lat: 48.2551977, lng: 18.9332038 },
    { lat: 48.2557352, lng: 18.9329368 },
    { lat: 48.2558106, lng: 18.932759 },
    { lat: 48.2557479, lng: 18.9321973 },
    { lat: 48.2557924, lng: 18.9319009 },
    { lat: 48.2562984, lng: 18.9313258 },
    { lat: 48.25673, lng: 18.9311053 },
    { lat: 48.25672, lng: 18.9309982 },
    { lat: 48.2566944, lng: 18.930653 },
    { lat: 48.2557066, lng: 18.9265656 },
    { lat: 48.2558324, lng: 18.9264038 },
    { lat: 48.2554072, lng: 18.9255324 },
    { lat: 48.2548665, lng: 18.9247345 },
    { lat: 48.2548284, lng: 18.9245178 },
    { lat: 48.2548551, lng: 18.9243803 },
    { lat: 48.254797, lng: 18.9242164 },
    { lat: 48.2545938, lng: 18.9239516 },
    { lat: 48.2544318, lng: 18.9238936 },
    { lat: 48.2543088, lng: 18.9239786 },
    { lat: 48.2540311, lng: 18.9239489 },
    { lat: 48.2537959, lng: 18.9240437 },
    { lat: 48.2534209, lng: 18.9239647 },
    { lat: 48.25324, lng: 18.9238063 },
    { lat: 48.252756, lng: 18.9237364 },
    { lat: 48.2525126, lng: 18.9234309 },
    { lat: 48.2524766, lng: 18.9232582 },
    { lat: 48.252235, lng: 18.9229465 },
    { lat: 48.2520257, lng: 18.9225621 },
    { lat: 48.2518225, lng: 18.9224717 },
    { lat: 48.2516769, lng: 18.922645 },
    { lat: 48.2512994, lng: 18.9226512 },
    { lat: 48.2511757, lng: 18.9227621 },
    { lat: 48.2508324, lng: 18.9227545 },
    { lat: 48.2502143, lng: 18.9230327 },
    { lat: 48.2501233, lng: 18.9231515 },
    { lat: 48.2495552, lng: 18.9233924 },
    { lat: 48.2493338, lng: 18.9237591 },
    { lat: 48.2486427, lng: 18.9238048 },
    { lat: 48.2484926, lng: 18.9232551 },
    { lat: 48.2483575, lng: 18.9231052 },
    { lat: 48.248062, lng: 18.9229752 },
    { lat: 48.2479069, lng: 18.9225708 },
    { lat: 48.2477297, lng: 18.9222814 },
    { lat: 48.2473186, lng: 18.9213321 },
    { lat: 48.2474397, lng: 18.9208175 },
    { lat: 48.2472934, lng: 18.920539 },
    { lat: 48.2472341, lng: 18.9201546 },
    { lat: 48.2471052, lng: 18.9201696 },
    { lat: 48.2469288, lng: 18.9197874 },
    { lat: 48.2464677, lng: 18.9193976 },
    { lat: 48.2464071, lng: 18.9192313 },
    { lat: 48.2460543, lng: 18.9191887 },
    { lat: 48.2459255, lng: 18.9189881 },
    { lat: 48.2455807, lng: 18.9189906 },
    { lat: 48.2454482, lng: 18.918805 },
    { lat: 48.2452147, lng: 18.9188045 },
    { lat: 48.2451576, lng: 18.9184634 },
    { lat: 48.2449753, lng: 18.918429 },
    { lat: 48.2444615, lng: 18.9175397 },
    { lat: 48.2442817, lng: 18.9173572 },
    { lat: 48.2442633, lng: 18.9171117 },
    { lat: 48.2440564, lng: 18.9169255 },
    { lat: 48.2438551, lng: 18.9169361 },
    { lat: 48.2431552, lng: 18.9160494 },
    { lat: 48.2429358, lng: 18.9159284 },
    { lat: 48.2426521, lng: 18.9159348 },
    { lat: 48.2424943, lng: 18.916091 },
    { lat: 48.2419724, lng: 18.9158764 },
    { lat: 48.2421336, lng: 18.9156536 },
    { lat: 48.2415194, lng: 18.9144414 },
    { lat: 48.2414235, lng: 18.9143751 },
    { lat: 48.2414802, lng: 18.9138818 },
    { lat: 48.2415461, lng: 18.913739 },
    { lat: 48.2418282, lng: 18.9135475 },
    { lat: 48.2417982, lng: 18.9130546 },
    { lat: 48.2418251, lng: 18.9126377 },
    { lat: 48.2418784, lng: 18.912271 },
    { lat: 48.2420486, lng: 18.9118335 },
    { lat: 48.2419364, lng: 18.911213 },
    { lat: 48.2421133, lng: 18.910624 },
    { lat: 48.2423586, lng: 18.9102 },
    { lat: 48.2425279, lng: 18.9100736 },
    { lat: 48.2427456, lng: 18.9102266 },
    { lat: 48.2429876, lng: 18.9102583 },
    { lat: 48.2433803, lng: 18.9096333 },
    { lat: 48.243667, lng: 18.909444 },
    { lat: 48.2437493, lng: 18.9092487 },
    { lat: 48.2437454, lng: 18.9091091 },
    { lat: 48.2436848, lng: 18.9089849 },
    { lat: 48.2435054, lng: 18.9088515 },
    { lat: 48.2435199, lng: 18.9086747 },
    { lat: 48.2436949, lng: 18.9085461 },
    { lat: 48.2439466, lng: 18.9084189 },
    { lat: 48.244041, lng: 18.9085905 },
    { lat: 48.2440441, lng: 18.9088455 },
    { lat: 48.2442056, lng: 18.9090447 },
    { lat: 48.2443886, lng: 18.908986 },
    { lat: 48.2438163, lng: 18.9070806 },
    { lat: 48.2436846, lng: 18.9058878 },
    { lat: 48.2439483, lng: 18.9041015 },
    { lat: 48.2440857, lng: 18.904076 },
    { lat: 48.2441909, lng: 18.9024089 },
    { lat: 48.2445087, lng: 18.9011656 },
    { lat: 48.2440486, lng: 18.9004663 },
    { lat: 48.2439521, lng: 18.9001097 },
    { lat: 48.243571, lng: 18.8997181 },
    { lat: 48.2431355, lng: 18.8984971 },
    { lat: 48.2437251, lng: 18.8980337 },
    { lat: 48.2438625, lng: 18.8978599 },
    { lat: 48.2437332, lng: 18.8974749 },
    { lat: 48.2437227, lng: 18.897186 },
    { lat: 48.2435739, lng: 18.8968195 },
    { lat: 48.2421053, lng: 18.8940295 },
    { lat: 48.2414861, lng: 18.8924843 },
    { lat: 48.2408059, lng: 18.8927 },
    { lat: 48.2401754, lng: 18.8932173 },
    { lat: 48.239838, lng: 18.8931901 },
    { lat: 48.2394996, lng: 18.8935991 },
    { lat: 48.2393108, lng: 18.8940268 },
    { lat: 48.2389869, lng: 18.8943684 },
    { lat: 48.2380188, lng: 18.8950523 },
    { lat: 48.2377247, lng: 18.8956623 },
    { lat: 48.237352, lng: 18.8958893 },
    { lat: 48.2369804, lng: 18.8962462 },
    { lat: 48.2363951, lng: 18.8966383 },
    { lat: 48.2357826, lng: 18.8967863 },
    { lat: 48.2348227, lng: 18.8972127 },
    { lat: 48.2345008, lng: 18.897421 },
    { lat: 48.2342927, lng: 18.8974325 },
    { lat: 48.2340571, lng: 18.8973049 },
    { lat: 48.2339578, lng: 18.8968259 },
    { lat: 48.2336944, lng: 18.8962885 },
    { lat: 48.2336705, lng: 18.8963935 },
    { lat: 48.2336557, lng: 18.8965007 },
    { lat: 48.2335534, lng: 18.8965914 },
    { lat: 48.2333971, lng: 18.8965569 },
    { lat: 48.2331788, lng: 18.896849 },
    { lat: 48.2329976, lng: 18.8968538 },
    { lat: 48.2327524, lng: 18.8970602 },
    { lat: 48.2326003, lng: 18.8970846 },
    { lat: 48.2323031, lng: 18.897336 },
    { lat: 48.2319823, lng: 18.8973471 },
    { lat: 48.2315235, lng: 18.897654 },
    { lat: 48.2314507, lng: 18.8977944 },
    { lat: 48.2311784, lng: 18.8979149 },
    { lat: 48.2310424, lng: 18.8978348 },
    { lat: 48.2306488, lng: 18.8979007 },
    { lat: 48.2304008, lng: 18.8978567 },
    { lat: 48.2300859, lng: 18.8980681 },
    { lat: 48.2300014, lng: 18.8980054 },
    { lat: 48.2299316, lng: 18.8977666 },
    { lat: 48.2297914, lng: 18.8978027 },
    { lat: 48.2296569, lng: 18.8981017 },
    { lat: 48.2293701, lng: 18.898181 },
    { lat: 48.2292971, lng: 18.8982949 },
    { lat: 48.2290473, lng: 18.8983076 },
    { lat: 48.2290522, lng: 18.8982685 },
    { lat: 48.2291015, lng: 18.8976577 },
    { lat: 48.2292711, lng: 18.8970336 },
    { lat: 48.2295351, lng: 18.8953004 },
    { lat: 48.22931, lng: 18.8946147 },
    { lat: 48.229151, lng: 18.8939432 },
    { lat: 48.2269544, lng: 18.8915956 },
    { lat: 48.226031, lng: 18.8906867 },
    { lat: 48.2252234, lng: 18.8885154 },
    { lat: 48.2249583, lng: 18.8881198 },
    { lat: 48.2235368, lng: 18.8865721 },
    { lat: 48.2230743, lng: 18.8856984 },
    { lat: 48.2226324, lng: 18.8855699 },
    { lat: 48.2223316, lng: 18.8854016 },
    { lat: 48.2220574, lng: 18.8850555 },
    { lat: 48.2215501, lng: 18.8841253 },
    { lat: 48.2212639, lng: 18.8841696 },
    { lat: 48.2206711, lng: 18.883831 },
    { lat: 48.2204569, lng: 18.8837877 },
    { lat: 48.2203421, lng: 18.8838785 },
    { lat: 48.2202891, lng: 18.8841403 },
    { lat: 48.2202023, lng: 18.884207 },
    { lat: 48.2200021, lng: 18.8839782 },
    { lat: 48.2193849, lng: 18.8838376 },
    { lat: 48.2191259, lng: 18.8836514 },
    { lat: 48.218882, lng: 18.8835784 },
    { lat: 48.2186697, lng: 18.8833132 },
    { lat: 48.2186769, lng: 18.8833014 },
    { lat: 48.2185511, lng: 18.8828323 },
    { lat: 48.2183524, lng: 18.882614 },
    { lat: 48.2181185, lng: 18.882515 },
    { lat: 48.2178217, lng: 18.8824927 },
    { lat: 48.2177795, lng: 18.8822441 },
    { lat: 48.2174928, lng: 18.8822789 },
    { lat: 48.2173068, lng: 18.8822164 },
    { lat: 48.2170889, lng: 18.8823009 },
    { lat: 48.2169301, lng: 18.8824788 },
    { lat: 48.2167767, lng: 18.8824776 },
    { lat: 48.2166896, lng: 18.8826876 },
    { lat: 48.2157903, lng: 18.8834093 },
    { lat: 48.215278, lng: 18.8839057 },
    { lat: 48.2146157, lng: 18.8848278 },
    { lat: 48.2128506, lng: 18.88807 },
    { lat: 48.2124133, lng: 18.8890769 },
    { lat: 48.2120732, lng: 18.889429 },
    { lat: 48.2116762, lng: 18.8896241 },
    { lat: 48.2112525, lng: 18.889479 },
    { lat: 48.2102605, lng: 18.88881 },
    { lat: 48.2100142, lng: 18.8885622 },
    { lat: 48.2097761, lng: 18.8881462 },
    { lat: 48.2097055, lng: 18.8882206 },
    { lat: 48.2092479, lng: 18.8878128 },
    { lat: 48.2089502, lng: 18.887728 },
    { lat: 48.2085274, lng: 18.8872318 },
    { lat: 48.2073291, lng: 18.8860895 },
    { lat: 48.2069027, lng: 18.8856061 },
    { lat: 48.2069183, lng: 18.8855374 },
    { lat: 48.2068338, lng: 18.8853677 },
    { lat: 48.2063702, lng: 18.8848222 },
    { lat: 48.206369, lng: 18.8848223 },
    { lat: 48.2040006, lng: 18.889973 },
    { lat: 48.2034748, lng: 18.8918758 },
    { lat: 48.2027821, lng: 18.8940366 },
    { lat: 48.2024387, lng: 18.8947636 },
    { lat: 48.201485, lng: 18.8963951 },
    { lat: 48.2008485, lng: 18.8961284 },
    { lat: 48.2005417, lng: 18.8969045 },
    { lat: 48.2003846, lng: 18.8975526 },
    { lat: 48.2001799, lng: 18.8980826 },
    { lat: 48.2001257, lng: 18.898385 },
    { lat: 48.1998256, lng: 18.8990324 },
    { lat: 48.1996887, lng: 18.8991519 },
    { lat: 48.1993602, lng: 18.8992784 },
    { lat: 48.1991471, lng: 18.899493 },
    { lat: 48.1990846, lng: 18.8994635 },
    { lat: 48.1985091, lng: 18.9006949 },
    { lat: 48.1980616, lng: 18.901264 },
    { lat: 48.1973752, lng: 18.9016788 },
    { lat: 48.1968459, lng: 18.9023036 },
    { lat: 48.1964494, lng: 18.9024853 },
    { lat: 48.1955991, lng: 18.9038945 },
    { lat: 48.1946622, lng: 18.9052462 },
    { lat: 48.1943249, lng: 18.9057006 },
    { lat: 48.1935181, lng: 18.9065594 },
    { lat: 48.1931702, lng: 18.9070263 },
    { lat: 48.1925512, lng: 18.908417 },
    { lat: 48.1919807, lng: 18.9092913 },
    { lat: 48.1916993, lng: 18.909606 },
    { lat: 48.1888214, lng: 18.9113397 },
    { lat: 48.1883976, lng: 18.9118853 },
    { lat: 48.1865357, lng: 18.9150597 },
    { lat: 48.1867153, lng: 18.9152605 },
    { lat: 48.1872168, lng: 18.916107 },
    { lat: 48.1874774, lng: 18.9168676 },
    { lat: 48.1871251, lng: 18.9171013 },
    { lat: 48.1868458, lng: 18.9171913 },
    { lat: 48.1861616, lng: 18.9177228 },
    { lat: 48.1857528, lng: 18.9179055 },
    { lat: 48.185538, lng: 18.9180685 },
    { lat: 48.1849479, lng: 18.918952 },
    { lat: 48.1846203, lng: 18.919727 },
    { lat: 48.1842349, lng: 18.9203832 },
    { lat: 48.1832144, lng: 18.9214535 },
    { lat: 48.1828879, lng: 18.9217008 },
    { lat: 48.1824502, lng: 18.9222641 },
    { lat: 48.1787504, lng: 18.9277104 },
    { lat: 48.1787594, lng: 18.9276249 },
    { lat: 48.1784289, lng: 18.927361 },
    { lat: 48.1776652, lng: 18.9270087 },
    { lat: 48.1770334, lng: 18.9268663 },
    { lat: 48.1770144, lng: 18.9269617 },
    { lat: 48.1767521, lng: 18.9286465 },
    { lat: 48.1773347, lng: 18.9297747 },
    { lat: 48.177289, lng: 18.9308984 },
    { lat: 48.1773357, lng: 18.9313219 },
    { lat: 48.1774487, lng: 18.9315647 },
    { lat: 48.177343, lng: 18.9316595 },
    { lat: 48.177271, lng: 18.9319445 },
    { lat: 48.1773085, lng: 18.9322122 },
    { lat: 48.1772267, lng: 18.9326145 },
    { lat: 48.1772518, lng: 18.9328638 },
    { lat: 48.1771496, lng: 18.9332621 },
    { lat: 48.177068, lng: 18.9333662 },
    { lat: 48.17706, lng: 18.9337967 },
    { lat: 48.1769534, lng: 18.9345297 },
    { lat: 48.1769188, lng: 18.9354099 },
    { lat: 48.1769501, lng: 18.9364979 },
    { lat: 48.1768757, lng: 18.9370205 },
    { lat: 48.1769874, lng: 18.9376785 },
    { lat: 48.1769765, lng: 18.9380564 },
    { lat: 48.17702, lng: 18.938199 },
    { lat: 48.176972, lng: 18.938753 },
    { lat: 48.176953, lng: 18.93896 },
    { lat: 48.176955, lng: 18.939012 },
    { lat: 48.177034, lng: 18.939173 },
    { lat: 48.177035, lng: 18.939289 },
    { lat: 48.177032, lng: 18.939647 },
    { lat: 48.176979, lng: 18.940018 },
    { lat: 48.176944, lng: 18.940208 },
    { lat: 48.176971, lng: 18.940243 },
    { lat: 48.177061, lng: 18.940297 },
    { lat: 48.177087, lng: 18.940332 },
    { lat: 48.176985, lng: 18.941132 },
    { lat: 48.177091, lng: 18.941377 },
    { lat: 48.177137, lng: 18.941675 },
    { lat: 48.177266, lng: 18.941992 },
    { lat: 48.17732, lng: 18.942123 },
    { lat: 48.177352, lng: 18.942229 },
    { lat: 48.177365, lng: 18.942352 },
    { lat: 48.177342, lng: 18.94256 },
    { lat: 48.177417, lng: 18.942822 },
    { lat: 48.177429, lng: 18.942876 },
    { lat: 48.177413, lng: 18.942926 },
    { lat: 48.177378, lng: 18.942953 },
    { lat: 48.177218, lng: 18.943002 },
    { lat: 48.177211, lng: 18.943032 },
    { lat: 48.177242, lng: 18.943274 },
    { lat: 48.177282, lng: 18.943257 },
    { lat: 48.177339, lng: 18.943341 },
    { lat: 48.1779367, lng: 18.943513 },
    { lat: 48.1783788, lng: 18.9434077 },
    { lat: 48.1785493, lng: 18.9430842 },
    { lat: 48.1799615, lng: 18.943133 },
    { lat: 48.1803093, lng: 18.9433399 },
    { lat: 48.1817921, lng: 18.9435855 },
    { lat: 48.1821871, lng: 18.9435474 },
    { lat: 48.1825234, lng: 18.9433793 },
    { lat: 48.1829744, lng: 18.9434884 },
    { lat: 48.1833672, lng: 18.9434255 },
    { lat: 48.1841399, lng: 18.9439583 },
    { lat: 48.1844206, lng: 18.9439581 },
    { lat: 48.1848414, lng: 18.9442007 },
    { lat: 48.1858113, lng: 18.9445126 },
    { lat: 48.1862714, lng: 18.9444041 },
    { lat: 48.1868654, lng: 18.9447928 },
    { lat: 48.1874665, lng: 18.9448384 },
    { lat: 48.187906, lng: 18.9449676 },
    { lat: 48.1886568, lng: 18.9453546 },
    { lat: 48.1890624, lng: 18.9457428 },
    { lat: 48.1895768, lng: 18.9460556 },
    { lat: 48.1900954, lng: 18.9460312 },
    { lat: 48.1903497, lng: 18.9459319 },
    { lat: 48.1909407, lng: 18.9459373 },
    { lat: 48.1912655, lng: 18.9461328 },
    { lat: 48.1926581, lng: 18.9473621 },
    { lat: 48.1929838, lng: 18.9473596 },
    { lat: 48.1933451, lng: 18.9475661 },
    { lat: 48.193731, lng: 18.9479672 },
    { lat: 48.1940524, lng: 18.9481193 },
    { lat: 48.1948912, lng: 18.9474169 },
    { lat: 48.1954023, lng: 18.9471792 },
    { lat: 48.1954609, lng: 18.9469816 },
    { lat: 48.1955912, lng: 18.9469524 },
    { lat: 48.1958176, lng: 18.9467504 },
    { lat: 48.1960207, lng: 18.9467542 },
    { lat: 48.196364, lng: 18.9463043 },
    { lat: 48.1964836, lng: 18.9463909 },
    { lat: 48.1966187, lng: 18.946716 },
    { lat: 48.1974113, lng: 18.9473067 },
    { lat: 48.197592, lng: 18.9475301 },
    { lat: 48.1977635, lng: 18.9475563 },
    { lat: 48.1979084, lng: 18.9477188 },
    { lat: 48.198545, lng: 18.9486966 },
    { lat: 48.1987141, lng: 18.9487361 },
    { lat: 48.198944, lng: 18.9490857 },
    { lat: 48.1990552, lng: 18.9496479 },
    { lat: 48.1992402, lng: 18.949846 },
    { lat: 48.199495, lng: 18.9497379 },
    { lat: 48.1998955, lng: 18.9492598 },
    { lat: 48.1999581, lng: 18.9490703 },
    { lat: 48.2002429, lng: 18.9486048 },
    { lat: 48.200654, lng: 18.9480485 },
    { lat: 48.2008289, lng: 18.947916 },
    { lat: 48.2014907, lng: 18.9466263 },
    { lat: 48.2017179, lng: 18.946721 },
    { lat: 48.2018293, lng: 18.9468513 },
    { lat: 48.2025459, lng: 18.9475849 },
    { lat: 48.2030407, lng: 18.9481917 },
    { lat: 48.2037445, lng: 18.9493374 },
    { lat: 48.2042892, lng: 18.9499318 },
    { lat: 48.2049525, lng: 18.9505057 },
    { lat: 48.2051943, lng: 18.9508653 },
    { lat: 48.2053674, lng: 18.9515346 },
    { lat: 48.2055995, lng: 18.952036 },
    { lat: 48.2061929, lng: 18.9527651 },
    { lat: 48.2065347, lng: 18.9534005 },
    { lat: 48.207185, lng: 18.9538573 },
    { lat: 48.2079117, lng: 18.954108 },
    { lat: 48.2083141, lng: 18.954306 },
    { lat: 48.2085423, lng: 18.9545109 },
    { lat: 48.2086335, lng: 18.95499 },
    { lat: 48.2085974, lng: 18.9556891 },
    { lat: 48.2085225, lng: 18.9563174 },
    { lat: 48.2083474, lng: 18.9572093 },
    { lat: 48.2080767, lng: 18.9585264 },
    { lat: 48.2101904, lng: 18.9600741 },
    { lat: 48.2107341, lng: 18.9604071 },
    { lat: 48.2121474, lng: 18.96149 },
    { lat: 48.2142434, lng: 18.9634858 },
    { lat: 48.2147046, lng: 18.9641264 },
    { lat: 48.2153156, lng: 18.9644299 },
    { lat: 48.2170066, lng: 18.9660266 },
    { lat: 48.2182976, lng: 18.967652 },
    { lat: 48.220479, lng: 18.9688783 },
    { lat: 48.2222489, lng: 18.9709602 },
    { lat: 48.2229344, lng: 18.972037 },
    { lat: 48.2240083, lng: 18.9731018 },
    { lat: 48.2251451, lng: 18.9746895 },
    { lat: 48.2260604, lng: 18.9762718 },
    { lat: 48.2268868, lng: 18.9748814 },
    { lat: 48.2276644, lng: 18.9733777 },
    { lat: 48.2282939, lng: 18.9725731 },
    { lat: 48.2287553, lng: 18.9724433 },
    { lat: 48.2287601, lng: 18.972052 },
    { lat: 48.2298948, lng: 18.9711781 },
    { lat: 48.229959, lng: 18.9710483 },
    { lat: 48.2305151, lng: 18.9708292 },
    { lat: 48.2305188, lng: 18.970925 },
    { lat: 48.2311905, lng: 18.9705229 },
    { lat: 48.2316082, lng: 18.9707988 },
    { lat: 48.2320863, lng: 18.9706675 },
    { lat: 48.2326274, lng: 18.9702259 },
    { lat: 48.2331541, lng: 18.969346 },
    { lat: 48.2334418, lng: 18.9693267 },
    { lat: 48.2340063, lng: 18.9696074 },
    { lat: 48.235417, lng: 18.9716533 },
    { lat: 48.2362831, lng: 18.9722743 },
    { lat: 48.2366886, lng: 18.972708 },
    { lat: 48.2365256, lng: 18.9733323 },
    { lat: 48.2375958, lng: 18.9739288 },
    { lat: 48.2378987, lng: 18.9741646 },
    { lat: 48.2380422, lng: 18.9741693 },
    { lat: 48.238028, lng: 18.9734508 },
    { lat: 48.2379794, lng: 18.9732308 },
    { lat: 48.2377607, lng: 18.972858 },
    { lat: 48.2375717, lng: 18.9723598 },
    { lat: 48.2372437, lng: 18.9711517 },
    { lat: 48.2369369, lng: 18.9704902 },
    { lat: 48.2367764, lng: 18.9702621 },
    { lat: 48.2364696, lng: 18.9688508 },
    { lat: 48.2363945, lng: 18.9688602 },
    { lat: 48.2362894, lng: 18.9685023 },
    { lat: 48.2364094, lng: 18.9683573 },
    { lat: 48.2363812, lng: 18.9681625 },
    { lat: 48.2364679, lng: 18.9674522 },
    { lat: 48.2365746, lng: 18.9672256 },
    { lat: 48.236583, lng: 18.967062 },
    { lat: 48.2367025, lng: 18.9667518 },
    { lat: 48.2366282, lng: 18.9665146 },
    { lat: 48.2367958, lng: 18.966397 },
    { lat: 48.2367559, lng: 18.9661024 },
    { lat: 48.2369692, lng: 18.9656877 },
    { lat: 48.2371595, lng: 18.9657037 },
    { lat: 48.2373318, lng: 18.9654367 },
    { lat: 48.2373639, lng: 18.9654913 },
    { lat: 48.237609, lng: 18.965171 },
    { lat: 48.2384172, lng: 18.9638012 },
    { lat: 48.2385818, lng: 18.9632521 },
    { lat: 48.2388127, lng: 18.9621255 },
    { lat: 48.2388872, lng: 18.9619748 },
    { lat: 48.2391942, lng: 18.9597394 },
    { lat: 48.239175, lng: 18.9593243 },
    { lat: 48.2392322, lng: 18.95857 },
  ],
  Bzovík: [
    { lat: 48.3145517, lng: 19.0543022 },
    { lat: 48.3145763, lng: 19.0545173 },
    { lat: 48.3144537, lng: 19.0551082 },
    { lat: 48.3142907, lng: 19.0552074 },
    { lat: 48.3140713, lng: 19.0550353 },
    { lat: 48.3139914, lng: 19.054879 },
    { lat: 48.3139996, lng: 19.054775 },
    { lat: 48.3138487, lng: 19.0545095 },
    { lat: 48.3135894, lng: 19.0544652 },
    { lat: 48.3134589, lng: 19.0546089 },
    { lat: 48.3133989, lng: 19.0546037 },
    { lat: 48.3134448, lng: 19.0544185 },
    { lat: 48.3132421, lng: 19.0544231 },
    { lat: 48.3131811, lng: 19.0543514 },
    { lat: 48.3131844, lng: 19.0542391 },
    { lat: 48.3130264, lng: 19.05381 },
    { lat: 48.313024, lng: 19.0538075 },
    { lat: 48.3125232, lng: 19.0553929 },
    { lat: 48.3131797, lng: 19.0557346 },
    { lat: 48.3135044, lng: 19.0560017 },
    { lat: 48.3144572, lng: 19.0566071 },
    { lat: 48.3147312, lng: 19.0568969 },
    { lat: 48.3148063, lng: 19.0570684 },
    { lat: 48.3149783, lng: 19.057192 },
    { lat: 48.3153464, lng: 19.0579185 },
    { lat: 48.3154547, lng: 19.0592182 },
    { lat: 48.3155974, lng: 19.059767 },
    { lat: 48.315559, lng: 19.0599888 },
    { lat: 48.3155184, lng: 19.0600377 },
    { lat: 48.3153567, lng: 19.0600032 },
    { lat: 48.315209, lng: 19.0601138 },
    { lat: 48.3149475, lng: 19.0600733 },
    { lat: 48.314888, lng: 19.0601212 },
    { lat: 48.3146735, lng: 19.0600261 },
    { lat: 48.3145389, lng: 19.0600608 },
    { lat: 48.3143272, lng: 19.0599105 },
    { lat: 48.3142187, lng: 19.0600822 },
    { lat: 48.3141422, lng: 19.060065 },
    { lat: 48.3140374, lng: 19.0602678 },
    { lat: 48.3139831, lng: 19.0602644 },
    { lat: 48.3137589, lng: 19.0605363 },
    { lat: 48.3135872, lng: 19.0606411 },
    { lat: 48.3134952, lng: 19.0606303 },
    { lat: 48.3134509, lng: 19.0606884 },
    { lat: 48.3134505, lng: 19.0608811 },
    { lat: 48.3132966, lng: 19.0611369 },
    { lat: 48.3131375, lng: 19.0612715 },
    { lat: 48.31315, lng: 19.0614312 },
    { lat: 48.3128951, lng: 19.0618058 },
    { lat: 48.3127504, lng: 19.062114 },
    { lat: 48.3126905, lng: 19.062379 },
    { lat: 48.312513, lng: 19.0625277 },
    { lat: 48.3123816, lng: 19.0628029 },
    { lat: 48.3122971, lng: 19.0634089 },
    { lat: 48.3121417, lng: 19.0633035 },
    { lat: 48.3118203, lng: 19.0633006 },
    { lat: 48.3115064, lng: 19.0630029 },
    { lat: 48.3109458, lng: 19.0628142 },
    { lat: 48.3094385, lng: 19.0632656 },
    { lat: 48.308683, lng: 19.063575 },
    { lat: 48.3082469, lng: 19.0635574 },
    { lat: 48.3075503, lng: 19.0642769 },
    { lat: 48.307172, lng: 19.0647128 },
    { lat: 48.3066474, lng: 19.0654652 },
    { lat: 48.3066272, lng: 19.0656694 },
    { lat: 48.306314, lng: 19.0661454 },
    { lat: 48.3062353, lng: 19.0664634 },
    { lat: 48.3056896, lng: 19.0675621 },
    { lat: 48.3054174, lng: 19.0682901 },
    { lat: 48.3052556, lng: 19.0685896 },
    { lat: 48.304244, lng: 19.0696148 },
    { lat: 48.3039903, lng: 19.0690794 },
    { lat: 48.3027928, lng: 19.0725473 },
    { lat: 48.3028618, lng: 19.0725979 },
    { lat: 48.3024534, lng: 19.0736476 },
    { lat: 48.3018126, lng: 19.0736141 },
    { lat: 48.2997349, lng: 19.0747851 },
    { lat: 48.2994525, lng: 19.0745011 },
    { lat: 48.2974886, lng: 19.075674 },
    { lat: 48.297102, lng: 19.075695 },
    { lat: 48.2957387, lng: 19.0753104 },
    { lat: 48.2947467, lng: 19.075245 },
    { lat: 48.2941217, lng: 19.0750777 },
    { lat: 48.2938362, lng: 19.0748738 },
    { lat: 48.2937194, lng: 19.0749234 },
    { lat: 48.2934971, lng: 19.0744741 },
    { lat: 48.2933202, lng: 19.074498 },
    { lat: 48.2932556, lng: 19.0746264 },
    { lat: 48.2929022, lng: 19.0743926 },
    { lat: 48.2927122, lng: 19.0744243 },
    { lat: 48.2925883, lng: 19.0743388 },
    { lat: 48.2924004, lng: 19.0739642 },
    { lat: 48.2923523, lng: 19.07377 },
    { lat: 48.2922158, lng: 19.0737465 },
    { lat: 48.2919967, lng: 19.0738296 },
    { lat: 48.2917509, lng: 19.0737154 },
    { lat: 48.2915615, lng: 19.0737351 },
    { lat: 48.2915418, lng: 19.0740209 },
    { lat: 48.2914767, lng: 19.0741819 },
    { lat: 48.29141, lng: 19.0741993 },
    { lat: 48.2912882, lng: 19.0737086 },
    { lat: 48.2910326, lng: 19.0734634 },
    { lat: 48.2906903, lng: 19.0735403 },
    { lat: 48.2906718, lng: 19.0734217 },
    { lat: 48.2907089, lng: 19.0732854 },
    { lat: 48.2906752, lng: 19.0732397 },
    { lat: 48.2903605, lng: 19.0732384 },
    { lat: 48.2902366, lng: 19.0731289 },
    { lat: 48.2900197, lng: 19.0731672 },
    { lat: 48.2898179, lng: 19.0731004 },
    { lat: 48.2894351, lng: 19.073287 },
    { lat: 48.2890588, lng: 19.0730895 },
    { lat: 48.2889798, lng: 19.0731997 },
    { lat: 48.2888449, lng: 19.0732521 },
    { lat: 48.2886663, lng: 19.0732237 },
    { lat: 48.2886147, lng: 19.0730012 },
    { lat: 48.2885282, lng: 19.0729636 },
    { lat: 48.2884317, lng: 19.0730166 },
    { lat: 48.2882526, lng: 19.0729756 },
    { lat: 48.2881083, lng: 19.0731579 },
    { lat: 48.2880479, lng: 19.0731003 },
    { lat: 48.2880471, lng: 19.0729478 },
    { lat: 48.2879881, lng: 19.0728493 },
    { lat: 48.2873599, lng: 19.0725532 },
    { lat: 48.2873269, lng: 19.0724794 },
    { lat: 48.2873294, lng: 19.0728441 },
    { lat: 48.2874944, lng: 19.0731901 },
    { lat: 48.2877335, lng: 19.0739327 },
    { lat: 48.2877114, lng: 19.0740991 },
    { lat: 48.2875639, lng: 19.0741911 },
    { lat: 48.2873752, lng: 19.074027 },
    { lat: 48.2872868, lng: 19.0740592 },
    { lat: 48.2872615, lng: 19.0742087 },
    { lat: 48.2873293, lng: 19.0744109 },
    { lat: 48.2875621, lng: 19.0746239 },
    { lat: 48.2876088, lng: 19.0747688 },
    { lat: 48.2880315, lng: 19.0751738 },
    { lat: 48.288146, lng: 19.0751857 },
    { lat: 48.2883179, lng: 19.0757769 },
    { lat: 48.2886926, lng: 19.0758528 },
    { lat: 48.2889137, lng: 19.0762605 },
    { lat: 48.2890325, lng: 19.0762453 },
    { lat: 48.2891327, lng: 19.0763547 },
    { lat: 48.2892916, lng: 19.0766911 },
    { lat: 48.2893024, lng: 19.0771979 },
    { lat: 48.2894314, lng: 19.077474 },
    { lat: 48.2896725, lng: 19.0777863 },
    { lat: 48.2897057, lng: 19.0780205 },
    { lat: 48.289644, lng: 19.0782961 },
    { lat: 48.2894229, lng: 19.0787632 },
    { lat: 48.2894826, lng: 19.0795467 },
    { lat: 48.2891192, lng: 19.0799686 },
    { lat: 48.2889908, lng: 19.080193 },
    { lat: 48.289008, lng: 19.0803327 },
    { lat: 48.2892466, lng: 19.0805054 },
    { lat: 48.2895049, lng: 19.0808239 },
    { lat: 48.2896026, lng: 19.0810844 },
    { lat: 48.2898553, lng: 19.0809324 },
    { lat: 48.2899492, lng: 19.0809395 },
    { lat: 48.2900851, lng: 19.0810629 },
    { lat: 48.2902277, lng: 19.0812954 },
    { lat: 48.2901938, lng: 19.081542 },
    { lat: 48.2899676, lng: 19.0817049 },
    { lat: 48.2899839, lng: 19.0818978 },
    { lat: 48.2902997, lng: 19.0822583 },
    { lat: 48.2904947, lng: 19.0822137 },
    { lat: 48.2906779, lng: 19.0827853 },
    { lat: 48.2911624, lng: 19.0837917 },
    { lat: 48.2913189, lng: 19.0839684 },
    { lat: 48.291513, lng: 19.0840605 },
    { lat: 48.2916192, lng: 19.0843116 },
    { lat: 48.2917481, lng: 19.0843423 },
    { lat: 48.291738, lng: 19.084409 },
    { lat: 48.2918963, lng: 19.0848439 },
    { lat: 48.2927387, lng: 19.0852326 },
    { lat: 48.2940622, lng: 19.0851868 },
    { lat: 48.2941373, lng: 19.0853316 },
    { lat: 48.2944531, lng: 19.0855853 },
    { lat: 48.2946653, lng: 19.0856095 },
    { lat: 48.2947971, lng: 19.0857409 },
    { lat: 48.2950449, lng: 19.0864139 },
    { lat: 48.2951838, lng: 19.0864415 },
    { lat: 48.295421, lng: 19.0868747 },
    { lat: 48.2955602, lng: 19.0869981 },
    { lat: 48.2956286, lng: 19.0871476 },
    { lat: 48.295726, lng: 19.0871813 },
    { lat: 48.295943, lng: 19.0875941 },
    { lat: 48.2959162, lng: 19.0877554 },
    { lat: 48.2959767, lng: 19.0879507 },
    { lat: 48.2959795, lng: 19.0882549 },
    { lat: 48.2960399, lng: 19.0884237 },
    { lat: 48.2960986, lng: 19.0884256 },
    { lat: 48.2962537, lng: 19.0881932 },
    { lat: 48.296315, lng: 19.0882691 },
    { lat: 48.2963428, lng: 19.0885322 },
    { lat: 48.2962423, lng: 19.0891564 },
    { lat: 48.2962372, lng: 19.0895263 },
    { lat: 48.2963531, lng: 19.0897922 },
    { lat: 48.2964334, lng: 19.0898207 },
    { lat: 48.2969061, lng: 19.0910177 },
    { lat: 48.2971875, lng: 19.0913 },
    { lat: 48.2974281, lng: 19.0918076 },
    { lat: 48.2981887, lng: 19.0911211 },
    { lat: 48.2982782, lng: 19.0913135 },
    { lat: 48.2983991, lng: 19.0914075 },
    { lat: 48.2985625, lng: 19.0914047 },
    { lat: 48.2987595, lng: 19.0916265 },
    { lat: 48.2987428, lng: 19.0918743 },
    { lat: 48.298735, lng: 19.0919908 },
    { lat: 48.2988045, lng: 19.0920481 },
    { lat: 48.2989512, lng: 19.092028 },
    { lat: 48.298991, lng: 19.0918327 },
    { lat: 48.2992017, lng: 19.0916964 },
    { lat: 48.2992786, lng: 19.0915433 },
    { lat: 48.2994273, lng: 19.0914323 },
    { lat: 48.2996826, lng: 19.0915533 },
    { lat: 48.2997803, lng: 19.0917488 },
    { lat: 48.2998999, lng: 19.0917265 },
    { lat: 48.3000601, lng: 19.091798 },
    { lat: 48.3001777, lng: 19.0916456 },
    { lat: 48.3005559, lng: 19.0918806 },
    { lat: 48.3005498, lng: 19.0919613 },
    { lat: 48.300381, lng: 19.0920823 },
    { lat: 48.3004033, lng: 19.0921864 },
    { lat: 48.3005008, lng: 19.092243 },
    { lat: 48.3006065, lng: 19.09215 },
    { lat: 48.3008991, lng: 19.0922945 },
    { lat: 48.3009692, lng: 19.0926368 },
    { lat: 48.3012172, lng: 19.092839 },
    { lat: 48.3013637, lng: 19.0928151 },
    { lat: 48.3016038, lng: 19.0929248 },
    { lat: 48.3024132, lng: 19.0940956 },
    { lat: 48.3026096, lng: 19.0941433 },
    { lat: 48.3030363, lng: 19.0957539 },
    { lat: 48.3032155, lng: 19.0960669 },
    { lat: 48.3035866, lng: 19.0964536 },
    { lat: 48.3045916, lng: 19.0973221 },
    { lat: 48.3050913, lng: 19.0976204 },
    { lat: 48.3057416, lng: 19.0978822 },
    { lat: 48.3056592, lng: 19.0982424 },
    { lat: 48.3062327, lng: 19.0985048 },
    { lat: 48.306651, lng: 19.0985742 },
    { lat: 48.3068843, lng: 19.0982608 },
    { lat: 48.3076176, lng: 19.0986507 },
    { lat: 48.3081696, lng: 19.0988248 },
    { lat: 48.3082122, lng: 19.0989064 },
    { lat: 48.3081259, lng: 19.0992529 },
    { lat: 48.3086513, lng: 19.0994382 },
    { lat: 48.3089637, lng: 19.0992837 },
    { lat: 48.3090019, lng: 19.0990549 },
    { lat: 48.3093739, lng: 19.0989638 },
    { lat: 48.3096558, lng: 19.098578 },
    { lat: 48.3100691, lng: 19.0972636 },
    { lat: 48.3102377, lng: 19.0969192 },
    { lat: 48.3104274, lng: 19.0966956 },
    { lat: 48.3108128, lng: 19.0963346 },
    { lat: 48.3112405, lng: 19.0960481 },
    { lat: 48.3115677, lng: 19.0959216 },
    { lat: 48.3121652, lng: 19.0958091 },
    { lat: 48.3121271, lng: 19.0996876 },
    { lat: 48.3143614, lng: 19.100582 },
    { lat: 48.3146991, lng: 19.1006846 },
    { lat: 48.315231, lng: 19.1006581 },
    { lat: 48.3152632, lng: 19.1007392 },
    { lat: 48.3159105, lng: 19.1007878 },
    { lat: 48.3174791, lng: 19.1013382 },
    { lat: 48.3178969, lng: 19.0987814 },
    { lat: 48.3182939, lng: 19.0989436 },
    { lat: 48.3187124, lng: 19.0974166 },
    { lat: 48.3190859, lng: 19.0977805 },
    { lat: 48.3193288, lng: 19.098107 },
    { lat: 48.3195549, lng: 19.0985041 },
    { lat: 48.3198028, lng: 19.0990859 },
    { lat: 48.3200825, lng: 19.0994493 },
    { lat: 48.3210826, lng: 19.1001732 },
    { lat: 48.3213626, lng: 19.1011062 },
    { lat: 48.3221554, lng: 19.1012696 },
    { lat: 48.323056, lng: 19.1017146 },
    { lat: 48.3231538, lng: 19.1016214 },
    { lat: 48.3235275, lng: 19.1015386 },
    { lat: 48.3237174, lng: 19.1015711 },
    { lat: 48.325555, lng: 19.102542 },
    { lat: 48.3267305, lng: 19.1033032 },
    { lat: 48.326378, lng: 19.1063746 },
    { lat: 48.3261911, lng: 19.107507 },
    { lat: 48.3260114, lng: 19.1082229 },
    { lat: 48.325759, lng: 19.1087423 },
    { lat: 48.3252693, lng: 19.1093435 },
    { lat: 48.3254815, lng: 19.1094858 },
    { lat: 48.3255375, lng: 19.1095901 },
    { lat: 48.3254796, lng: 19.109694 },
    { lat: 48.3255472, lng: 19.1098313 },
    { lat: 48.3255924, lng: 19.109833 },
    { lat: 48.3256153, lng: 19.1097471 },
    { lat: 48.325783, lng: 19.1098091 },
    { lat: 48.3257846, lng: 19.1101571 },
    { lat: 48.3259175, lng: 19.110472 },
    { lat: 48.3258724, lng: 19.1107013 },
    { lat: 48.3259111, lng: 19.1108879 },
    { lat: 48.3260112, lng: 19.1109263 },
    { lat: 48.3259957, lng: 19.1111597 },
    { lat: 48.326168, lng: 19.1112891 },
    { lat: 48.326285, lng: 19.1111293 },
    { lat: 48.3264977, lng: 19.11112 },
    { lat: 48.3265453, lng: 19.1111713 },
    { lat: 48.3265091, lng: 19.1113018 },
    { lat: 48.3266884, lng: 19.1114153 },
    { lat: 48.3267458, lng: 19.1116621 },
    { lat: 48.3269396, lng: 19.1115497 },
    { lat: 48.3270753, lng: 19.1116694 },
    { lat: 48.3270926, lng: 19.1118797 },
    { lat: 48.3272425, lng: 19.1117686 },
    { lat: 48.3272672, lng: 19.1118328 },
    { lat: 48.3273598, lng: 19.1118121 },
    { lat: 48.3273975, lng: 19.1120059 },
    { lat: 48.3273535, lng: 19.1121597 },
    { lat: 48.3274349, lng: 19.1124141 },
    { lat: 48.3273418, lng: 19.1125361 },
    { lat: 48.3273244, lng: 19.11266 },
    { lat: 48.32741, lng: 19.1127293 },
    { lat: 48.3276054, lng: 19.1126911 },
    { lat: 48.3276698, lng: 19.1127849 },
    { lat: 48.3276659, lng: 19.1129103 },
    { lat: 48.3275667, lng: 19.1129342 },
    { lat: 48.32762, lng: 19.1131394 },
    { lat: 48.3276016, lng: 19.1133352 },
    { lat: 48.3277857, lng: 19.1133876 },
    { lat: 48.3279155, lng: 19.1132575 },
    { lat: 48.3281035, lng: 19.1137724 },
    { lat: 48.3285373, lng: 19.1143752 },
    { lat: 48.3289166, lng: 19.1139805 },
    { lat: 48.3289651, lng: 19.1140473 },
    { lat: 48.3291258, lng: 19.1142631 },
    { lat: 48.3293996, lng: 19.1145566 },
    { lat: 48.3297501, lng: 19.1150703 },
    { lat: 48.3305041, lng: 19.1144106 },
    { lat: 48.3309812, lng: 19.1128801 },
    { lat: 48.3315541, lng: 19.1116643 },
    { lat: 48.3325333, lng: 19.1105464 },
    { lat: 48.3329243, lng: 19.1101822 },
    { lat: 48.3330173, lng: 19.110058 },
    { lat: 48.3330738, lng: 19.1099269 },
    { lat: 48.3333951, lng: 19.1091783 },
    { lat: 48.3341702, lng: 19.1098038 },
    { lat: 48.3370847, lng: 19.1037847 },
    { lat: 48.3372221, lng: 19.1041175 },
    { lat: 48.3376869, lng: 19.1038548 },
    { lat: 48.3378684, lng: 19.1029067 },
    { lat: 48.3396675, lng: 19.1015049 },
    { lat: 48.3401748, lng: 19.1008922 },
    { lat: 48.3398782, lng: 19.1004645 },
    { lat: 48.3398285, lng: 19.0999201 },
    { lat: 48.3398393, lng: 19.099257 },
    { lat: 48.3402858, lng: 19.097908 },
    { lat: 48.341216, lng: 19.0983575 },
    { lat: 48.3419576, lng: 19.0988073 },
    { lat: 48.3429064, lng: 19.0991573 },
    { lat: 48.343352, lng: 19.0986681 },
    { lat: 48.3442428, lng: 19.09833 },
    { lat: 48.3441945, lng: 19.0978606 },
    { lat: 48.3443938, lng: 19.0977467 },
    { lat: 48.3447299, lng: 19.097386 },
    { lat: 48.3453998, lng: 19.0963778 },
    { lat: 48.346002, lng: 19.0949615 },
    { lat: 48.3463543, lng: 19.0943217 },
    { lat: 48.3466948, lng: 19.0934867 },
    { lat: 48.3469873, lng: 19.0909537 },
    { lat: 48.3469156, lng: 19.0904893 },
    { lat: 48.3473606, lng: 19.0898746 },
    { lat: 48.3476572, lng: 19.089178 },
    { lat: 48.3479496, lng: 19.0887158 },
    { lat: 48.34778, lng: 19.0885936 },
    { lat: 48.3477679, lng: 19.0884652 },
    { lat: 48.3479, lng: 19.0881735 },
    { lat: 48.3478879, lng: 19.0880441 },
    { lat: 48.3476142, lng: 19.0876458 },
    { lat: 48.3470796, lng: 19.087099 },
    { lat: 48.3466726, lng: 19.0870782 },
    { lat: 48.3461639, lng: 19.086783 },
    { lat: 48.3460703, lng: 19.086694 },
    { lat: 48.3459883, lng: 19.0864707 },
    { lat: 48.3455431, lng: 19.0859794 },
    { lat: 48.3453978, lng: 19.0859593 },
    { lat: 48.3453197, lng: 19.0860886 },
    { lat: 48.3451369, lng: 19.0861759 },
    { lat: 48.345081, lng: 19.0861382 },
    { lat: 48.3450357, lng: 19.0859803 },
    { lat: 48.3450958, lng: 19.0857839 },
    { lat: 48.3449681, lng: 19.0856776 },
    { lat: 48.3447951, lng: 19.0852048 },
    { lat: 48.3445062, lng: 19.0850644 },
    { lat: 48.3445308, lng: 19.0847023 },
    { lat: 48.3443556, lng: 19.0847791 },
    { lat: 48.3442919, lng: 19.0847491 },
    { lat: 48.3441793, lng: 19.0844099 },
    { lat: 48.3439147, lng: 19.0842416 },
    { lat: 48.3438362, lng: 19.0842725 },
    { lat: 48.3436691, lng: 19.0845592 },
    { lat: 48.3434972, lng: 19.0846176 },
    { lat: 48.3433784, lng: 19.0845612 },
    { lat: 48.3433356, lng: 19.0844934 },
    { lat: 48.3433405, lng: 19.084187 },
    { lat: 48.3427247, lng: 19.0838022 },
    { lat: 48.3429598, lng: 19.082979 },
    { lat: 48.3425586, lng: 19.082779 },
    { lat: 48.3424196, lng: 19.0832484 },
    { lat: 48.3422936, lng: 19.083275 },
    { lat: 48.3421988, lng: 19.0832272 },
    { lat: 48.3420699, lng: 19.0830178 },
    { lat: 48.341514, lng: 19.0826773 },
    { lat: 48.3408079, lng: 19.0827159 },
    { lat: 48.3406862, lng: 19.0825361 },
    { lat: 48.3401217, lng: 19.082187 },
    { lat: 48.3397215, lng: 19.0822325 },
    { lat: 48.339486, lng: 19.0823711 },
    { lat: 48.3393805, lng: 19.0823539 },
    { lat: 48.3392845, lng: 19.0821738 },
    { lat: 48.3390076, lng: 19.0822727 },
    { lat: 48.338665, lng: 19.0820733 },
    { lat: 48.3385847, lng: 19.0821355 },
    { lat: 48.3385325, lng: 19.0823512 },
    { lat: 48.3384476, lng: 19.0824104 },
    { lat: 48.338159, lng: 19.0823232 },
    { lat: 48.3377735, lng: 19.0825701 },
    { lat: 48.3376798, lng: 19.0822557 },
    { lat: 48.3372884, lng: 19.0821401 },
    { lat: 48.3369738, lng: 19.0819109 },
    { lat: 48.3366097, lng: 19.0815321 },
    { lat: 48.3356056, lng: 19.0818102 },
    { lat: 48.3351322, lng: 19.0821025 },
    { lat: 48.3348282, lng: 19.0822024 },
    { lat: 48.3346485, lng: 19.082415 },
    { lat: 48.3344291, lng: 19.0825401 },
    { lat: 48.3343739, lng: 19.0824506 },
    { lat: 48.3343273, lng: 19.0820835 },
    { lat: 48.334054, lng: 19.0815333 },
    { lat: 48.3338422, lng: 19.0813137 },
    { lat: 48.3336337, lng: 19.0814053 },
    { lat: 48.3336165, lng: 19.0811706 },
    { lat: 48.3337029, lng: 19.0808494 },
    { lat: 48.3336701, lng: 19.0806894 },
    { lat: 48.3335755, lng: 19.0805371 },
    { lat: 48.3334528, lng: 19.080654 },
    { lat: 48.3333172, lng: 19.0806686 },
    { lat: 48.3332308, lng: 19.080317 },
    { lat: 48.3328498, lng: 19.0797803 },
    { lat: 48.3323044, lng: 19.0794011 },
    { lat: 48.3318313, lng: 19.0793192 },
    { lat: 48.3316652, lng: 19.0793791 },
    { lat: 48.3315368, lng: 19.0795842 },
    { lat: 48.3313309, lng: 19.0796142 },
    { lat: 48.3309574, lng: 19.0793609 },
    { lat: 48.3308661, lng: 19.0790055 },
    { lat: 48.3306213, lng: 19.0786849 },
    { lat: 48.3305994, lng: 19.0779802 },
    { lat: 48.3304861, lng: 19.0777007 },
    { lat: 48.3298968, lng: 19.077482 },
    { lat: 48.3301592, lng: 19.0768033 },
    { lat: 48.3301847, lng: 19.0766378 },
    { lat: 48.3301046, lng: 19.0765487 },
    { lat: 48.3297898, lng: 19.0764525 },
    { lat: 48.3298166, lng: 19.0761766 },
    { lat: 48.3297413, lng: 19.075957 },
    { lat: 48.3296008, lng: 19.075896 },
    { lat: 48.3295699, lng: 19.075554 },
    { lat: 48.3292121, lng: 19.0752327 },
    { lat: 48.3293411, lng: 19.0749035 },
    { lat: 48.329196, lng: 19.0747102 },
    { lat: 48.3289284, lng: 19.0746543 },
    { lat: 48.3287271, lng: 19.07471 },
    { lat: 48.3289051, lng: 19.0744405 },
    { lat: 48.3289084, lng: 19.0742552 },
    { lat: 48.3287556, lng: 19.0738622 },
    { lat: 48.3285882, lng: 19.0737599 },
    { lat: 48.3282496, lng: 19.0738198 },
    { lat: 48.3280839, lng: 19.0732409 },
    { lat: 48.3279108, lng: 19.073163 },
    { lat: 48.3278503, lng: 19.0730356 },
    { lat: 48.327837, lng: 19.0728355 },
    { lat: 48.3279094, lng: 19.0727075 },
    { lat: 48.3277681, lng: 19.0722038 },
    { lat: 48.3279202, lng: 19.0717986 },
    { lat: 48.3280079, lng: 19.0717055 },
    { lat: 48.3282225, lng: 19.071711 },
    { lat: 48.3286134, lng: 19.0713479 },
    { lat: 48.3291815, lng: 19.0709648 },
    { lat: 48.3293858, lng: 19.0710583 },
    { lat: 48.3295639, lng: 19.0709446 },
    { lat: 48.3296168, lng: 19.0706293 },
    { lat: 48.3297031, lng: 19.0705756 },
    { lat: 48.3297147, lng: 19.0697527 },
    { lat: 48.3296461, lng: 19.0697321 },
    { lat: 48.3296189, lng: 19.0694174 },
    { lat: 48.3295244, lng: 19.0690826 },
    { lat: 48.3293546, lng: 19.0688031 },
    { lat: 48.3293269, lng: 19.0679616 },
    { lat: 48.3291342, lng: 19.0676709 },
    { lat: 48.3289338, lng: 19.0676745 },
    { lat: 48.3286695, lng: 19.067399 },
    { lat: 48.3273531, lng: 19.0669811 },
    { lat: 48.3272901, lng: 19.0670481 },
    { lat: 48.326802, lng: 19.0669159 },
    { lat: 48.3264042, lng: 19.0671434 },
    { lat: 48.3261605, lng: 19.0674965 },
    { lat: 48.3260268, lng: 19.0675078 },
    { lat: 48.3259136, lng: 19.0676092 },
    { lat: 48.325777, lng: 19.0675615 },
    { lat: 48.3257283, lng: 19.0674487 },
    { lat: 48.325539, lng: 19.0673338 },
    { lat: 48.325533, lng: 19.0668583 },
    { lat: 48.3253211, lng: 19.0662534 },
    { lat: 48.3244827, lng: 19.0658598 },
    { lat: 48.3237625, lng: 19.0654166 },
    { lat: 48.3223875, lng: 19.0635431 },
    { lat: 48.3223093, lng: 19.0633327 },
    { lat: 48.3223693, lng: 19.0629136 },
    { lat: 48.3224387, lng: 19.0627243 },
    { lat: 48.322319, lng: 19.0623606 },
    { lat: 48.322332, lng: 19.0620612 },
    { lat: 48.3222701, lng: 19.0617923 },
    { lat: 48.3220323, lng: 19.0617069 },
    { lat: 48.3219443, lng: 19.0611688 },
    { lat: 48.3217626, lng: 19.0610509 },
    { lat: 48.3217199, lng: 19.060761 },
    { lat: 48.3216079, lng: 19.0607096 },
    { lat: 48.3215338, lng: 19.0608292 },
    { lat: 48.3214432, lng: 19.0607566 },
    { lat: 48.321311, lng: 19.0609278 },
    { lat: 48.321173, lng: 19.0609172 },
    { lat: 48.3211422, lng: 19.0611587 },
    { lat: 48.3207771, lng: 19.0615887 },
    { lat: 48.3208104, lng: 19.0617773 },
    { lat: 48.3207376, lng: 19.0618985 },
    { lat: 48.3205146, lng: 19.0621522 },
    { lat: 48.3203769, lng: 19.062148 },
    { lat: 48.3202945, lng: 19.0619884 },
    { lat: 48.3202211, lng: 19.0615588 },
    { lat: 48.3202353, lng: 19.0613057 },
    { lat: 48.3203798, lng: 19.0609939 },
    { lat: 48.3203577, lng: 19.0609171 },
    { lat: 48.3202558, lng: 19.0609293 },
    { lat: 48.3201623, lng: 19.0608421 },
    { lat: 48.3200697, lng: 19.0605037 },
    { lat: 48.3199824, lng: 19.0603801 },
    { lat: 48.3199492, lng: 19.0601482 },
    { lat: 48.3200897, lng: 19.0596917 },
    { lat: 48.3201748, lng: 19.0595648 },
    { lat: 48.3204028, lng: 19.059529 },
    { lat: 48.3204365, lng: 19.059301 },
    { lat: 48.320206, lng: 19.0589796 },
    { lat: 48.3199711, lng: 19.0588386 },
    { lat: 48.3198667, lng: 19.0588901 },
    { lat: 48.3197913, lng: 19.0587354 },
    { lat: 48.3198562, lng: 19.0585272 },
    { lat: 48.320095, lng: 19.0584976 },
    { lat: 48.3203248, lng: 19.0585853 },
    { lat: 48.3205444, lng: 19.058423 },
    { lat: 48.3206736, lng: 19.0581232 },
    { lat: 48.3206245, lng: 19.0579318 },
    { lat: 48.3205411, lng: 19.0578201 },
    { lat: 48.3203352, lng: 19.0577609 },
    { lat: 48.320047, lng: 19.0575215 },
    { lat: 48.3199266, lng: 19.0576641 },
    { lat: 48.3197441, lng: 19.0577326 },
    { lat: 48.3197832, lng: 19.0572567 },
    { lat: 48.3197169, lng: 19.0570573 },
    { lat: 48.3197121, lng: 19.0568538 },
    { lat: 48.3196415, lng: 19.0567942 },
    { lat: 48.3194891, lng: 19.0569662 },
    { lat: 48.3193898, lng: 19.0565623 },
    { lat: 48.319217, lng: 19.0564239 },
    { lat: 48.3190945, lng: 19.0564321 },
    { lat: 48.3188157, lng: 19.056694 },
    { lat: 48.3188214, lng: 19.0567585 },
    { lat: 48.3187684, lng: 19.0568042 },
    { lat: 48.3187099, lng: 19.0567414 },
    { lat: 48.3187193, lng: 19.0564828 },
    { lat: 48.3183352, lng: 19.0563981 },
    { lat: 48.3180895, lng: 19.056737 },
    { lat: 48.3178913, lng: 19.0568969 },
    { lat: 48.3177573, lng: 19.0569224 },
    { lat: 48.3175759, lng: 19.0567874 },
    { lat: 48.3174658, lng: 19.0568211 },
    { lat: 48.3172926, lng: 19.0571851 },
    { lat: 48.3171909, lng: 19.0572019 },
    { lat: 48.3167756, lng: 19.0567258 },
    { lat: 48.3167304, lng: 19.0566108 },
    { lat: 48.3168069, lng: 19.0563199 },
    { lat: 48.3168985, lng: 19.0563052 },
    { lat: 48.3169863, lng: 19.0563874 },
    { lat: 48.3171183, lng: 19.0563434 },
    { lat: 48.3171275, lng: 19.056127 },
    { lat: 48.3171725, lng: 19.0560354 },
    { lat: 48.3171578, lng: 19.055787 },
    { lat: 48.3169857, lng: 19.0556167 },
    { lat: 48.3168935, lng: 19.0554412 },
    { lat: 48.3166898, lng: 19.0553849 },
    { lat: 48.3166174, lng: 19.0554424 },
    { lat: 48.3166488, lng: 19.0556871 },
    { lat: 48.3163205, lng: 19.0559981 },
    { lat: 48.3161768, lng: 19.0555366 },
    { lat: 48.3161677, lng: 19.0551544 },
    { lat: 48.3160489, lng: 19.055081 },
    { lat: 48.3159388, lng: 19.0548654 },
    { lat: 48.3153979, lng: 19.0547358 },
    { lat: 48.3153615, lng: 19.054797 },
    { lat: 48.3153425, lng: 19.0552026 },
    { lat: 48.3152929, lng: 19.0552256 },
    { lat: 48.3152683, lng: 19.0550952 },
    { lat: 48.3151749, lng: 19.0549655 },
    { lat: 48.315049, lng: 19.0543673 },
    { lat: 48.3147709, lng: 19.0542632 },
    { lat: 48.3146049, lng: 19.0543033 },
    { lat: 48.3145517, lng: 19.0543022 },
  ],
  Medovarce: [
    { lat: 48.2260604, lng: 18.9762718 },
    { lat: 48.2256342, lng: 18.9768861 },
    { lat: 48.2243507, lng: 18.9788413 },
    { lat: 48.2236557, lng: 18.9792721 },
    { lat: 48.2227569, lng: 18.9804119 },
    { lat: 48.2225214, lng: 18.9808331 },
    { lat: 48.2234916, lng: 18.9822989 },
    { lat: 48.2239351, lng: 18.9825306 },
    { lat: 48.2236201, lng: 18.9830695 },
    { lat: 48.2233279, lng: 18.9830892 },
    { lat: 48.223095, lng: 18.983386 },
    { lat: 48.2231557, lng: 18.9839529 },
    { lat: 48.2216862, lng: 18.9866168 },
    { lat: 48.2200819, lng: 18.990083 },
    { lat: 48.2196726, lng: 18.9907662 },
    { lat: 48.2195329, lng: 18.9914961 },
    { lat: 48.2196581, lng: 18.9929981 },
    { lat: 48.2193584, lng: 18.9941939 },
    { lat: 48.2192658, lng: 18.9941726 },
    { lat: 48.2190224, lng: 18.9950356 },
    { lat: 48.2189387, lng: 18.9956051 },
    { lat: 48.2183871, lng: 18.9970789 },
    { lat: 48.2180785, lng: 18.9983692 },
    { lat: 48.2178736, lng: 19.0003633 },
    { lat: 48.2177532, lng: 19.0006539 },
    { lat: 48.2175398, lng: 19.0017781 },
    { lat: 48.2175154, lng: 19.0024491 },
    { lat: 48.2175469, lng: 19.0033293 },
    { lat: 48.217615, lng: 19.0037805 },
    { lat: 48.2177598, lng: 19.0042733 },
    { lat: 48.2178222, lng: 19.0044858 },
    { lat: 48.2172757, lng: 19.0058846 },
    { lat: 48.2173261, lng: 19.0075362 },
    { lat: 48.2177041, lng: 19.010222 },
    { lat: 48.2178195, lng: 19.0105394 },
    { lat: 48.2181822, lng: 19.0111055 },
    { lat: 48.2183925, lng: 19.0117836 },
    { lat: 48.2185615, lng: 19.0128911 },
    { lat: 48.2184567, lng: 19.0145498 },
    { lat: 48.2186701, lng: 19.0167055 },
    { lat: 48.2185619, lng: 19.0167988 },
    { lat: 48.2182839, lng: 19.0203775 },
    { lat: 48.2179114, lng: 19.0212302 },
    { lat: 48.2172206, lng: 19.0219706 },
    { lat: 48.2161716, lng: 19.0239838 },
    { lat: 48.2162159, lng: 19.0241199 },
    { lat: 48.2163909, lng: 19.0242817 },
    { lat: 48.2166224, lng: 19.0242444 },
    { lat: 48.2167964, lng: 19.0242967 },
    { lat: 48.2174409, lng: 19.0249538 },
    { lat: 48.2176536, lng: 19.0253036 },
    { lat: 48.2177021, lng: 19.0256343 },
    { lat: 48.2179714, lng: 19.02614 },
    { lat: 48.2182481, lng: 19.026175 },
    { lat: 48.2189531, lng: 19.0264678 },
    { lat: 48.2202175, lng: 19.0274331 },
    { lat: 48.2205047, lng: 19.0277155 },
    { lat: 48.2207172, lng: 19.0280151 },
    { lat: 48.2207889, lng: 19.0288276 },
    { lat: 48.2209003, lng: 19.0292261 },
    { lat: 48.2212889, lng: 19.0300167 },
    { lat: 48.221444, lng: 19.0305156 },
    { lat: 48.2217697, lng: 19.0311574 },
    { lat: 48.2230651, lng: 19.0325122 },
    { lat: 48.2234117, lng: 19.0329459 },
    { lat: 48.2236924, lng: 19.033188 },
    { lat: 48.2244186, lng: 19.0335026 },
    { lat: 48.2246761, lng: 19.0339135 },
    { lat: 48.2249488, lng: 19.0342167 },
    { lat: 48.2252056, lng: 19.0342356 },
    { lat: 48.2253855, lng: 19.0340285 },
    { lat: 48.2255507, lng: 19.0339736 },
    { lat: 48.2256626, lng: 19.0339754 },
    { lat: 48.2259615, lng: 19.0341356 },
    { lat: 48.2261016, lng: 19.0343393 },
    { lat: 48.2263558, lng: 19.0352195 },
    { lat: 48.2266481, lng: 19.0355798 },
    { lat: 48.2269174, lng: 19.0356864 },
    { lat: 48.2276085, lng: 19.0347321 },
    { lat: 48.2279575, lng: 19.0338297 },
    { lat: 48.2279801, lng: 19.0328766 },
    { lat: 48.2281955, lng: 19.0324335 },
    { lat: 48.2285157, lng: 19.0321453 },
    { lat: 48.2292867, lng: 19.0312059 },
    { lat: 48.2298633, lng: 19.0302807 },
    { lat: 48.2307132, lng: 19.0286773 },
    { lat: 48.2310455, lng: 19.0278032 },
    { lat: 48.23169, lng: 19.0268405 },
    { lat: 48.2317774, lng: 19.0267881 },
    { lat: 48.2321347, lng: 19.0262489 },
    { lat: 48.2322048, lng: 19.0262572 },
    { lat: 48.2324569, lng: 19.0256669 },
    { lat: 48.2324023, lng: 19.0253262 },
    { lat: 48.2324355, lng: 19.025246 },
    { lat: 48.2325523, lng: 19.0252151 },
    { lat: 48.2327285, lng: 19.0249574 },
    { lat: 48.2330888, lng: 19.0247014 },
    { lat: 48.2331577, lng: 19.0246234 },
    { lat: 48.2333808, lng: 19.0243707 },
    { lat: 48.2336262, lng: 19.023894 },
    { lat: 48.233817, lng: 19.0236552 },
    { lat: 48.2339944, lng: 19.0235536 },
    { lat: 48.2340889, lng: 19.0232359 },
    { lat: 48.2344648, lng: 19.0227048 },
    { lat: 48.2347189, lng: 19.0224997 },
    { lat: 48.2352051, lng: 19.0224102 },
    { lat: 48.2353489, lng: 19.0223382 },
    { lat: 48.2355763, lng: 19.0224905 },
    { lat: 48.2359923, lng: 19.0225772 },
    { lat: 48.2360926, lng: 19.0224406 },
    { lat: 48.2364179, lng: 19.0223409 },
    { lat: 48.2363107, lng: 19.0228727 },
    { lat: 48.2363917, lng: 19.0232903 },
    { lat: 48.236534, lng: 19.0233852 },
    { lat: 48.2366315, lng: 19.0237274 },
    { lat: 48.2368676, lng: 19.0250936 },
    { lat: 48.2370168, lng: 19.0253656 },
    { lat: 48.2369811, lng: 19.0257502 },
    { lat: 48.237207, lng: 19.0262677 },
    { lat: 48.2372255, lng: 19.0266101 },
    { lat: 48.2373589, lng: 19.0274176 },
    { lat: 48.2374637, lng: 19.0277749 },
    { lat: 48.2374625, lng: 19.0279497 },
    { lat: 48.2376039, lng: 19.0281568 },
    { lat: 48.2376383, lng: 19.0283697 },
    { lat: 48.2379846, lng: 19.0292636 },
    { lat: 48.2383047, lng: 19.0298575 },
    { lat: 48.2382824, lng: 19.0304853 },
    { lat: 48.2386402, lng: 19.03263 },
    { lat: 48.2386324, lng: 19.0330546 },
    { lat: 48.2387846, lng: 19.0335208 },
    { lat: 48.2389228, lng: 19.0344441 },
    { lat: 48.2389005, lng: 19.0347179 },
    { lat: 48.2390973, lng: 19.0355985 },
    { lat: 48.2395544, lng: 19.0371629 },
    { lat: 48.2400215, lng: 19.0369016 },
    { lat: 48.2406073, lng: 19.0364714 },
    { lat: 48.2409723, lng: 19.0363536 },
    { lat: 48.241864, lng: 19.0347466 },
    { lat: 48.2430015, lng: 19.0328991 },
    { lat: 48.243466, lng: 19.031944 },
    { lat: 48.2438484, lng: 19.0321247 },
    { lat: 48.2447222, lng: 19.0318832 },
    { lat: 48.2455283, lng: 19.0321485 },
    { lat: 48.2461986, lng: 19.0313578 },
    { lat: 48.2472707, lng: 19.0307329 },
    { lat: 48.2475734, lng: 19.0301873 },
    { lat: 48.2477305, lng: 19.0296844 },
    { lat: 48.2485036, lng: 19.0280977 },
    { lat: 48.2490937, lng: 19.0265544 },
    { lat: 48.2494052, lng: 19.0254265 },
    { lat: 48.2498341, lng: 19.0253671 },
    { lat: 48.2499626, lng: 19.0251243 },
    { lat: 48.2503181, lng: 19.0246848 },
    { lat: 48.2513886, lng: 19.0229395 },
    { lat: 48.2534138, lng: 19.0210355 },
    { lat: 48.253491, lng: 19.0209793 },
    { lat: 48.2534338, lng: 19.0207712 },
    { lat: 48.2532612, lng: 19.0201433 },
    { lat: 48.2532454, lng: 19.0198737 },
    { lat: 48.2533062, lng: 19.0196528 },
    { lat: 48.2534518, lng: 19.0193889 },
    { lat: 48.253557, lng: 19.0195308 },
    { lat: 48.2538978, lng: 19.0192893 },
    { lat: 48.2541458, lng: 19.0186896 },
    { lat: 48.2538956, lng: 19.0185352 },
    { lat: 48.2533715, lng: 19.0185843 },
    { lat: 48.2529693, lng: 19.0183471 },
    { lat: 48.2529001, lng: 19.0177373 },
    { lat: 48.2527447, lng: 19.0173676 },
    { lat: 48.2527002, lng: 19.0170451 },
    { lat: 48.2527917, lng: 19.0170276 },
    { lat: 48.2528527, lng: 19.0165632 },
    { lat: 48.2529971, lng: 19.0162768 },
    { lat: 48.2529183, lng: 19.0159904 },
    { lat: 48.2527321, lng: 19.0156982 },
    { lat: 48.2525224, lng: 19.0157642 },
    { lat: 48.2520023, lng: 19.0161154 },
    { lat: 48.2517674, lng: 19.0160823 },
    { lat: 48.2516256, lng: 19.0159092 },
    { lat: 48.2515721, lng: 19.0157038 },
    { lat: 48.2516549, lng: 19.0152681 },
    { lat: 48.2515247, lng: 19.0147706 },
    { lat: 48.2509454, lng: 19.0137305 },
    { lat: 48.2508677, lng: 19.0138009 },
    { lat: 48.2505628, lng: 19.013547 },
    { lat: 48.2496391, lng: 19.0130336 },
    { lat: 48.2498767, lng: 19.0119647 },
    { lat: 48.2493317, lng: 19.011469 },
    { lat: 48.2489361, lng: 19.0110019 },
    { lat: 48.2487261, lng: 19.011133 },
    { lat: 48.2486504, lng: 19.0116376 },
    { lat: 48.2487249, lng: 19.0121721 },
    { lat: 48.2487819, lng: 19.0123181 },
    { lat: 48.248707, lng: 19.0129963 },
    { lat: 48.2484934, lng: 19.0128045 },
    { lat: 48.2482251, lng: 19.012319 },
    { lat: 48.2479353, lng: 19.0120103 },
    { lat: 48.2472106, lng: 19.0107972 },
    { lat: 48.2471535, lng: 19.0108908 },
    { lat: 48.2466893, lng: 19.0105437 },
    { lat: 48.2464493, lng: 19.0104805 },
    { lat: 48.2463118, lng: 19.0106384 },
    { lat: 48.2463349, lng: 19.0108041 },
    { lat: 48.2462337, lng: 19.0110771 },
    { lat: 48.245925, lng: 19.0109673 },
    { lat: 48.2457442, lng: 19.0107845 },
    { lat: 48.2456094, lng: 19.0103517 },
    { lat: 48.245619, lng: 19.0102523 },
    { lat: 48.2452867, lng: 19.0102583 },
    { lat: 48.2450991, lng: 19.0100261 },
    { lat: 48.2449905, lng: 19.0101301 },
    { lat: 48.245043, lng: 19.0109858 },
    { lat: 48.2448314, lng: 19.0111921 },
    { lat: 48.2444309, lng: 19.0113857 },
    { lat: 48.2442134, lng: 19.0116521 },
    { lat: 48.2438597, lng: 19.0115923 },
    { lat: 48.2436404, lng: 19.011629 },
    { lat: 48.2432855, lng: 19.0120314 },
    { lat: 48.2426684, lng: 19.0116465 },
    { lat: 48.242379, lng: 19.0112996 },
    { lat: 48.2421869, lng: 19.0114698 },
    { lat: 48.2419316, lng: 19.0118586 },
    { lat: 48.2414732, lng: 19.0111607 },
    { lat: 48.2410811, lng: 19.0110968 },
    { lat: 48.2407932, lng: 19.0116015 },
    { lat: 48.2404495, lng: 19.0110493 },
    { lat: 48.2401307, lng: 19.0109237 },
    { lat: 48.2398748, lng: 19.0108995 },
    { lat: 48.239571, lng: 19.0109557 },
    { lat: 48.239525, lng: 19.0110508 },
    { lat: 48.2392475, lng: 19.0107825 },
    { lat: 48.2390031, lng: 19.0106735 },
    { lat: 48.2384753, lng: 19.0100536 },
    { lat: 48.2389937, lng: 19.0094731 },
    { lat: 48.2389421, lng: 19.0092353 },
    { lat: 48.2385617, lng: 19.0086071 },
    { lat: 48.2386139, lng: 19.008101 },
    { lat: 48.2385608, lng: 19.0079037 },
    { lat: 48.2390624, lng: 19.0073651 },
    { lat: 48.2391455, lng: 19.0073408 },
    { lat: 48.2393552, lng: 19.0074502 },
    { lat: 48.2394462, lng: 19.0075767 },
    { lat: 48.2404199, lng: 19.007879 },
    { lat: 48.2419628, lng: 19.0078433 },
    { lat: 48.2426804, lng: 19.0080953 },
    { lat: 48.2433022, lng: 19.0077728 },
    { lat: 48.2437746, lng: 19.0074409 },
    { lat: 48.2440784, lng: 19.0070511 },
    { lat: 48.2441604, lng: 19.0073116 },
    { lat: 48.245129, lng: 19.0064048 },
    { lat: 48.2455113, lng: 19.005636 },
    { lat: 48.245816, lng: 19.0052856 },
    { lat: 48.2469835, lng: 19.0047168 },
    { lat: 48.2474459, lng: 19.004612 },
    { lat: 48.2486576, lng: 19.0045585 },
    { lat: 48.2488436, lng: 19.0047343 },
    { lat: 48.2491081, lng: 19.0048587 },
    { lat: 48.2497442, lng: 19.0049987 },
    { lat: 48.2498956, lng: 19.0056655 },
    { lat: 48.2502559, lng: 19.0057673 },
    { lat: 48.2505643, lng: 19.0063126 },
    { lat: 48.2511941, lng: 19.0065241 },
    { lat: 48.2517822, lng: 19.0068252 },
    { lat: 48.2533099, lng: 19.0078427 },
    { lat: 48.2536113, lng: 19.0079621 },
    { lat: 48.2542437, lng: 19.0073384 },
    { lat: 48.254447, lng: 19.0065701 },
    { lat: 48.2546716, lng: 19.0066835 },
    { lat: 48.2550056, lng: 19.0065809 },
    { lat: 48.2551656, lng: 19.0062014 },
    { lat: 48.2550909, lng: 19.0060607 },
    { lat: 48.2555046, lng: 19.0046633 },
    { lat: 48.2557464, lng: 19.004388 },
    { lat: 48.2556307, lng: 19.0035072 },
    { lat: 48.2555473, lng: 19.0034654 },
    { lat: 48.2555422, lng: 19.0031674 },
    { lat: 48.2551294, lng: 19.0021224 },
    { lat: 48.2550137, lng: 19.0018462 },
    { lat: 48.2549578, lng: 19.0018338 },
    { lat: 48.2540713, lng: 19.0018049 },
    { lat: 48.2536399, lng: 19.0017223 },
    { lat: 48.2536065, lng: 19.0008908 },
    { lat: 48.2536373, lng: 19.0004104 },
    { lat: 48.252046, lng: 18.9987049 },
    { lat: 48.2512941, lng: 18.9982209 },
    { lat: 48.2506434, lng: 18.9974891 },
    { lat: 48.2503156, lng: 18.9969174 },
    { lat: 48.2500347, lng: 18.9962972 },
    { lat: 48.2497022, lng: 18.9950595 },
    { lat: 48.2489444, lng: 18.9933997 },
    { lat: 48.2479078, lng: 18.9901039 },
    { lat: 48.2467791, lng: 18.9877629 },
    { lat: 48.2462778, lng: 18.9871299 },
    { lat: 48.2458049, lng: 18.9866372 },
    { lat: 48.2445663, lng: 18.9856751 },
    { lat: 48.2430671, lng: 18.9842508 },
    { lat: 48.2409868, lng: 18.9820476 },
    { lat: 48.240639, lng: 18.9816174 },
    { lat: 48.2396702, lng: 18.980666 },
    { lat: 48.2389355, lng: 18.9798133 },
    { lat: 48.2383518, lng: 18.9793359 },
    { lat: 48.2377116, lng: 18.9789863 },
    { lat: 48.2380226, lng: 18.9765187 },
    { lat: 48.2381363, lng: 18.9752369 },
    { lat: 48.2380422, lng: 18.9741693 },
    { lat: 48.2378987, lng: 18.9741646 },
    { lat: 48.2375958, lng: 18.9739288 },
    { lat: 48.2365256, lng: 18.9733323 },
    { lat: 48.2366886, lng: 18.972708 },
    { lat: 48.2362831, lng: 18.9722743 },
    { lat: 48.235417, lng: 18.9716533 },
    { lat: 48.2340063, lng: 18.9696074 },
    { lat: 48.2334418, lng: 18.9693267 },
    { lat: 48.2331541, lng: 18.969346 },
    { lat: 48.2326274, lng: 18.9702259 },
    { lat: 48.2320863, lng: 18.9706675 },
    { lat: 48.2316082, lng: 18.9707988 },
    { lat: 48.2311905, lng: 18.9705229 },
    { lat: 48.2305188, lng: 18.970925 },
    { lat: 48.2305151, lng: 18.9708292 },
    { lat: 48.229959, lng: 18.9710483 },
    { lat: 48.2298948, lng: 18.9711781 },
    { lat: 48.2287601, lng: 18.972052 },
    { lat: 48.2287553, lng: 18.9724433 },
    { lat: 48.2282939, lng: 18.9725731 },
    { lat: 48.2276644, lng: 18.9733777 },
    { lat: 48.2268868, lng: 18.9748814 },
    { lat: 48.2260604, lng: 18.9762718 },
  ],
  Jalšovík: [
    { lat: 48.3291258, lng: 19.1142631 },
    { lat: 48.3289651, lng: 19.1140473 },
    { lat: 48.3289166, lng: 19.1139805 },
    { lat: 48.3285373, lng: 19.1143752 },
    { lat: 48.3281035, lng: 19.1137724 },
    { lat: 48.3279155, lng: 19.1132575 },
    { lat: 48.3277857, lng: 19.1133876 },
    { lat: 48.3276016, lng: 19.1133352 },
    { lat: 48.32762, lng: 19.1131394 },
    { lat: 48.3275667, lng: 19.1129342 },
    { lat: 48.3276659, lng: 19.1129103 },
    { lat: 48.3276698, lng: 19.1127849 },
    { lat: 48.3276054, lng: 19.1126911 },
    { lat: 48.32741, lng: 19.1127293 },
    { lat: 48.3273244, lng: 19.11266 },
    { lat: 48.3273418, lng: 19.1125361 },
    { lat: 48.3274349, lng: 19.1124141 },
    { lat: 48.3273535, lng: 19.1121597 },
    { lat: 48.3273975, lng: 19.1120059 },
    { lat: 48.3273598, lng: 19.1118121 },
    { lat: 48.3272672, lng: 19.1118328 },
    { lat: 48.3272425, lng: 19.1117686 },
    { lat: 48.3270926, lng: 19.1118797 },
    { lat: 48.3270753, lng: 19.1116694 },
    { lat: 48.3269396, lng: 19.1115497 },
    { lat: 48.3267458, lng: 19.1116621 },
    { lat: 48.3266884, lng: 19.1114153 },
    { lat: 48.3265091, lng: 19.1113018 },
    { lat: 48.3265453, lng: 19.1111713 },
    { lat: 48.3264977, lng: 19.11112 },
    { lat: 48.326285, lng: 19.1111293 },
    { lat: 48.326168, lng: 19.1112891 },
    { lat: 48.3259957, lng: 19.1111597 },
    { lat: 48.3260112, lng: 19.1109263 },
    { lat: 48.3259111, lng: 19.1108879 },
    { lat: 48.3258724, lng: 19.1107013 },
    { lat: 48.3259175, lng: 19.110472 },
    { lat: 48.3257846, lng: 19.1101571 },
    { lat: 48.325783, lng: 19.1098091 },
    { lat: 48.3256153, lng: 19.1097471 },
    { lat: 48.3255924, lng: 19.109833 },
    { lat: 48.3255472, lng: 19.1098313 },
    { lat: 48.3254796, lng: 19.109694 },
    { lat: 48.3255375, lng: 19.1095901 },
    { lat: 48.3254815, lng: 19.1094858 },
    { lat: 48.3252693, lng: 19.1093435 },
    { lat: 48.325759, lng: 19.1087423 },
    { lat: 48.3260114, lng: 19.1082229 },
    { lat: 48.3261911, lng: 19.107507 },
    { lat: 48.326378, lng: 19.1063746 },
    { lat: 48.3267305, lng: 19.1033032 },
    { lat: 48.325555, lng: 19.102542 },
    { lat: 48.3237174, lng: 19.1015711 },
    { lat: 48.3235275, lng: 19.1015386 },
    { lat: 48.3231538, lng: 19.1016214 },
    { lat: 48.323056, lng: 19.1017146 },
    { lat: 48.3221554, lng: 19.1012696 },
    { lat: 48.3213626, lng: 19.1011062 },
    { lat: 48.3210826, lng: 19.1001732 },
    { lat: 48.3200825, lng: 19.0994493 },
    { lat: 48.3198028, lng: 19.0990859 },
    { lat: 48.3195549, lng: 19.0985041 },
    { lat: 48.3193288, lng: 19.098107 },
    { lat: 48.3190859, lng: 19.0977805 },
    { lat: 48.3187124, lng: 19.0974166 },
    { lat: 48.3182939, lng: 19.0989436 },
    { lat: 48.3178969, lng: 19.0987814 },
    { lat: 48.3174791, lng: 19.1013382 },
    { lat: 48.3159105, lng: 19.1007878 },
    { lat: 48.3152632, lng: 19.1007392 },
    { lat: 48.315231, lng: 19.1006581 },
    { lat: 48.3146991, lng: 19.1006846 },
    { lat: 48.3143614, lng: 19.100582 },
    { lat: 48.3121271, lng: 19.0996876 },
    { lat: 48.3121652, lng: 19.0958091 },
    { lat: 48.3115677, lng: 19.0959216 },
    { lat: 48.3112405, lng: 19.0960481 },
    { lat: 48.3108128, lng: 19.0963346 },
    { lat: 48.3104274, lng: 19.0966956 },
    { lat: 48.3102377, lng: 19.0969192 },
    { lat: 48.3100691, lng: 19.0972636 },
    { lat: 48.3096558, lng: 19.098578 },
    { lat: 48.3093739, lng: 19.0989638 },
    { lat: 48.3090019, lng: 19.0990549 },
    { lat: 48.3089637, lng: 19.0992837 },
    { lat: 48.3086513, lng: 19.0994382 },
    { lat: 48.3081259, lng: 19.0992529 },
    { lat: 48.3082122, lng: 19.0989064 },
    { lat: 48.3081696, lng: 19.0988248 },
    { lat: 48.3076176, lng: 19.0986507 },
    { lat: 48.3068843, lng: 19.0982608 },
    { lat: 48.306651, lng: 19.0985742 },
    { lat: 48.3062327, lng: 19.0985048 },
    { lat: 48.3056592, lng: 19.0982424 },
    { lat: 48.3057416, lng: 19.0978822 },
    { lat: 48.3050913, lng: 19.0976204 },
    { lat: 48.3045916, lng: 19.0973221 },
    { lat: 48.3035866, lng: 19.0964536 },
    { lat: 48.3032155, lng: 19.0960669 },
    { lat: 48.3030363, lng: 19.0957539 },
    { lat: 48.3026096, lng: 19.0941433 },
    { lat: 48.3024132, lng: 19.0940956 },
    { lat: 48.3016038, lng: 19.0929248 },
    { lat: 48.3013637, lng: 19.0928151 },
    { lat: 48.3012172, lng: 19.092839 },
    { lat: 48.3009692, lng: 19.0926368 },
    { lat: 48.3008991, lng: 19.0922945 },
    { lat: 48.3006065, lng: 19.09215 },
    { lat: 48.3005008, lng: 19.092243 },
    { lat: 48.3004033, lng: 19.0921864 },
    { lat: 48.300381, lng: 19.0920823 },
    { lat: 48.3005498, lng: 19.0919613 },
    { lat: 48.3005559, lng: 19.0918806 },
    { lat: 48.3001777, lng: 19.0916456 },
    { lat: 48.3000601, lng: 19.091798 },
    { lat: 48.2998999, lng: 19.0917265 },
    { lat: 48.2997803, lng: 19.0917488 },
    { lat: 48.2996826, lng: 19.0915533 },
    { lat: 48.2994273, lng: 19.0914323 },
    { lat: 48.2992786, lng: 19.0915433 },
    { lat: 48.2992017, lng: 19.0916964 },
    { lat: 48.298991, lng: 19.0918327 },
    { lat: 48.2989512, lng: 19.092028 },
    { lat: 48.2988045, lng: 19.0920481 },
    { lat: 48.298735, lng: 19.0919908 },
    { lat: 48.2987428, lng: 19.0918743 },
    { lat: 48.2987595, lng: 19.0916265 },
    { lat: 48.2985625, lng: 19.0914047 },
    { lat: 48.2983991, lng: 19.0914075 },
    { lat: 48.2982782, lng: 19.0913135 },
    { lat: 48.2981887, lng: 19.0911211 },
    { lat: 48.2974281, lng: 19.0918076 },
    { lat: 48.2972578, lng: 19.092816 },
    { lat: 48.2967825, lng: 19.0932307 },
    { lat: 48.2965196, lng: 19.0937882 },
    { lat: 48.2958695, lng: 19.0943368 },
    { lat: 48.2938225, lng: 19.0939604 },
    { lat: 48.292641, lng: 19.0934465 },
    { lat: 48.2925292, lng: 19.0936889 },
    { lat: 48.2923085, lng: 19.0934911 },
    { lat: 48.292138, lng: 19.094293 },
    { lat: 48.2916773, lng: 19.0963961 },
    { lat: 48.2930403, lng: 19.0985114 },
    { lat: 48.2932334, lng: 19.0987219 },
    { lat: 48.2945317, lng: 19.0996575 },
    { lat: 48.294839, lng: 19.1000071 },
    { lat: 48.2959941, lng: 19.1020472 },
    { lat: 48.2968106, lng: 19.1027513 },
    { lat: 48.2973661, lng: 19.1033542 },
    { lat: 48.2984937, lng: 19.1051625 },
    { lat: 48.2986388, lng: 19.1057212 },
    { lat: 48.2985329, lng: 19.1064189 },
    { lat: 48.2989189, lng: 19.1066981 },
    { lat: 48.3002367, lng: 19.1080744 },
    { lat: 48.3003624, lng: 19.1085135 },
    { lat: 48.3005311, lng: 19.1087516 },
    { lat: 48.3011096, lng: 19.1091197 },
    { lat: 48.3027724, lng: 19.1092956 },
    { lat: 48.3032183, lng: 19.1091517 },
    { lat: 48.3033683, lng: 19.1092416 },
    { lat: 48.3046082, lng: 19.1099585 },
    { lat: 48.3050238, lng: 19.1104278 },
    { lat: 48.3049643, lng: 19.1108821 },
    { lat: 48.30445, lng: 19.1130442 },
    { lat: 48.305655, lng: 19.1148266 },
    { lat: 48.307214, lng: 19.1162006 },
    { lat: 48.3071677, lng: 19.1176443 },
    { lat: 48.3069178, lng: 19.1202981 },
    { lat: 48.307947, lng: 19.1224888 },
    { lat: 48.3089834, lng: 19.1236696 },
    { lat: 48.3096335, lng: 19.12423 },
    { lat: 48.3097576, lng: 19.1245242 },
    { lat: 48.3096175, lng: 19.1250172 },
    { lat: 48.3097023, lng: 19.1251111 },
    { lat: 48.3096605, lng: 19.1252253 },
    { lat: 48.3100411, lng: 19.1255106 },
    { lat: 48.3102266, lng: 19.1254979 },
    { lat: 48.3101841, lng: 19.1256886 },
    { lat: 48.3102408, lng: 19.1258069 },
    { lat: 48.3103843, lng: 19.1257909 },
    { lat: 48.3104612, lng: 19.1257065 },
    { lat: 48.3106087, lng: 19.12586 },
    { lat: 48.3107119, lng: 19.1256458 },
    { lat: 48.3108042, lng: 19.1258451 },
    { lat: 48.3107708, lng: 19.125924 },
    { lat: 48.3108047, lng: 19.126059 },
    { lat: 48.3112499, lng: 19.1260796 },
    { lat: 48.3112606, lng: 19.1263614 },
    { lat: 48.3111818, lng: 19.1264775 },
    { lat: 48.3112145, lng: 19.1266354 },
    { lat: 48.3113321, lng: 19.1267582 },
    { lat: 48.3113345, lng: 19.1269853 },
    { lat: 48.3113979, lng: 19.1269254 },
    { lat: 48.3114705, lng: 19.1266148 },
    { lat: 48.3115998, lng: 19.1264746 },
    { lat: 48.3116261, lng: 19.1263676 },
    { lat: 48.3117238, lng: 19.1263173 },
    { lat: 48.3117244, lng: 19.1260786 },
    { lat: 48.3118785, lng: 19.1260939 },
    { lat: 48.3119082, lng: 19.1262574 },
    { lat: 48.3118519, lng: 19.1264648 },
    { lat: 48.3119796, lng: 19.1266521 },
    { lat: 48.3119936, lng: 19.1268683 },
    { lat: 48.3119495, lng: 19.1269554 },
    { lat: 48.3120097, lng: 19.1272327 },
    { lat: 48.3121052, lng: 19.1272262 },
    { lat: 48.3121864, lng: 19.1271148 },
    { lat: 48.3123098, lng: 19.1271697 },
    { lat: 48.3123484, lng: 19.1272656 },
    { lat: 48.3126298, lng: 19.1271668 },
    { lat: 48.3126903, lng: 19.1272295 },
    { lat: 48.312619, lng: 19.1273341 },
    { lat: 48.3126802, lng: 19.1274105 },
    { lat: 48.312637, lng: 19.1274915 },
    { lat: 48.3126488, lng: 19.1276148 },
    { lat: 48.3128039, lng: 19.1278117 },
    { lat: 48.3130292, lng: 19.1276732 },
    { lat: 48.3130525, lng: 19.1277754 },
    { lat: 48.3131114, lng: 19.127806 },
    { lat: 48.3131583, lng: 19.1276145 },
    { lat: 48.3132054, lng: 19.127611 },
    { lat: 48.3132214, lng: 19.1280703 },
    { lat: 48.3133897, lng: 19.1280529 },
    { lat: 48.3133982, lng: 19.1281992 },
    { lat: 48.3132784, lng: 19.1282608 },
    { lat: 48.3133424, lng: 19.1283224 },
    { lat: 48.31349, lng: 19.1283218 },
    { lat: 48.3136266, lng: 19.1281464 },
    { lat: 48.3137886, lng: 19.128141 },
    { lat: 48.313821, lng: 19.1282447 },
    { lat: 48.313561, lng: 19.1284141 },
    { lat: 48.3137008, lng: 19.1286647 },
    { lat: 48.3136244, lng: 19.1287562 },
    { lat: 48.3136365, lng: 19.1289992 },
    { lat: 48.3138027, lng: 19.1291934 },
    { lat: 48.3144094, lng: 19.1287668 },
    { lat: 48.3146392, lng: 19.1287836 },
    { lat: 48.3147754, lng: 19.1288916 },
    { lat: 48.3150842, lng: 19.1288021 },
    { lat: 48.3151297, lng: 19.1291282 },
    { lat: 48.3154566, lng: 19.1292584 },
    { lat: 48.3156766, lng: 19.1292168 },
    { lat: 48.3157802, lng: 19.1289916 },
    { lat: 48.3159447, lng: 19.1291705 },
    { lat: 48.3159801, lng: 19.128998 },
    { lat: 48.3161594, lng: 19.1291784 },
    { lat: 48.3164548, lng: 19.1289101 },
    { lat: 48.3167807, lng: 19.129225 },
    { lat: 48.3167919, lng: 19.1294083 },
    { lat: 48.3168662, lng: 19.1294481 },
    { lat: 48.3171077, lng: 19.12934 },
    { lat: 48.3171624, lng: 19.1289018 },
    { lat: 48.3173632, lng: 19.128902 },
    { lat: 48.3174747, lng: 19.1288112 },
    { lat: 48.3176804, lng: 19.1289107 },
    { lat: 48.317756, lng: 19.129209 },
    { lat: 48.3186936, lng: 19.1299265 },
    { lat: 48.3187661, lng: 19.1297973 },
    { lat: 48.3188824, lng: 19.1298218 },
    { lat: 48.3189182, lng: 19.1297254 },
    { lat: 48.3191257, lng: 19.1298166 },
    { lat: 48.3193886, lng: 19.1302288 },
    { lat: 48.3195313, lng: 19.1300404 },
    { lat: 48.3196465, lng: 19.1300895 },
    { lat: 48.3196688, lng: 19.1301298 },
    { lat: 48.3204162, lng: 19.1285914 },
    { lat: 48.3221326, lng: 19.127037 },
    { lat: 48.3231833, lng: 19.125727 },
    { lat: 48.3235544, lng: 19.1253429 },
    { lat: 48.3241187, lng: 19.1250611 },
    { lat: 48.3246865, lng: 19.1250779 },
    { lat: 48.3247392, lng: 19.1248687 },
    { lat: 48.3248763, lng: 19.1247 },
    { lat: 48.3254938, lng: 19.1241313 },
    { lat: 48.3259982, lng: 19.1237531 },
    { lat: 48.3264827, lng: 19.1231402 },
    { lat: 48.3267356, lng: 19.1231668 },
    { lat: 48.3273994, lng: 19.1234778 },
    { lat: 48.3276679, lng: 19.1204291 },
    { lat: 48.3281435, lng: 19.1205391 },
    { lat: 48.3283552, lng: 19.1197009 },
    { lat: 48.3287734, lng: 19.1186351 },
    { lat: 48.3290149, lng: 19.1181634 },
    { lat: 48.3295428, lng: 19.1174031 },
    { lat: 48.3298424, lng: 19.1172136 },
    { lat: 48.3298774, lng: 19.1166276 },
    { lat: 48.3296836, lng: 19.1161576 },
    { lat: 48.3289112, lng: 19.1150705 },
    { lat: 48.3291338, lng: 19.1146502 },
    { lat: 48.3291258, lng: 19.1142631 },
  ],
  Senohrad: [
    { lat: 48.3824562, lng: 19.177426 },
    { lat: 48.3820593, lng: 19.1784761 },
    { lat: 48.381875, lng: 19.1787863 },
    { lat: 48.3815863, lng: 19.1790351 },
    { lat: 48.38141, lng: 19.1790287 },
    { lat: 48.3804601, lng: 19.1794931 },
    { lat: 48.3796782, lng: 19.180303 },
    { lat: 48.3793662, lng: 19.1804905 },
    { lat: 48.3779085, lng: 19.1806927 },
    { lat: 48.3774136, lng: 19.1810147 },
    { lat: 48.3766259, lng: 19.1814092 },
    { lat: 48.3765052, lng: 19.1815201 },
    { lat: 48.3762054, lng: 19.182276 },
    { lat: 48.376023, lng: 19.1825104 },
    { lat: 48.3759664, lng: 19.1826656 },
    { lat: 48.3755659, lng: 19.1830487 },
    { lat: 48.3736427, lng: 19.1836465 },
    { lat: 48.3732271, lng: 19.1837195 },
    { lat: 48.3713564, lng: 19.1833655 },
    { lat: 48.3711547, lng: 19.1836435 },
    { lat: 48.3708031, lng: 19.1838963 },
    { lat: 48.3703876, lng: 19.1845731 },
    { lat: 48.3702214, lng: 19.1846742 },
    { lat: 48.3694446, lng: 19.1844423 },
    { lat: 48.3688175, lng: 19.1850532 },
    { lat: 48.3685448, lng: 19.1852391 },
    { lat: 48.3680382, lng: 19.184934 },
    { lat: 48.3680119, lng: 19.1848125 },
    { lat: 48.3673902, lng: 19.1840462 },
    { lat: 48.3663078, lng: 19.1834027 },
    { lat: 48.3655439, lng: 19.182838 },
    { lat: 48.3652528, lng: 19.182352 },
    { lat: 48.364764, lng: 19.1812382 },
    { lat: 48.3642358, lng: 19.1808827 },
    { lat: 48.3637128, lng: 19.1807256 },
    { lat: 48.3633019, lng: 19.1807361 },
    { lat: 48.362868, lng: 19.1806219 },
    { lat: 48.3616094, lng: 19.1810686 },
    { lat: 48.3607336, lng: 19.1812771 },
    { lat: 48.3599487, lng: 19.1809282 },
    { lat: 48.359945, lng: 19.1808067 },
    { lat: 48.3590628, lng: 19.1806596 },
    { lat: 48.3581379, lng: 19.1803018 },
    { lat: 48.3560867, lng: 19.179285 },
    { lat: 48.3547962, lng: 19.178465 },
    { lat: 48.3545866, lng: 19.1781487 },
    { lat: 48.3540027, lng: 19.1776897 },
    { lat: 48.3530305, lng: 19.177294 },
    { lat: 48.3524877, lng: 19.1768485 },
    { lat: 48.3512406, lng: 19.1752188 },
    { lat: 48.3504185, lng: 19.1747119 },
    { lat: 48.3497674, lng: 19.174489 },
    { lat: 48.3486312, lng: 19.1743087 },
    { lat: 48.3485077, lng: 19.174798 },
    { lat: 48.3484461, lng: 19.1748084 },
    { lat: 48.3481856, lng: 19.1744411 },
    { lat: 48.3475297, lng: 19.1740129 },
    { lat: 48.3471356, lng: 19.173585 },
    { lat: 48.3466743, lng: 19.173284 },
    { lat: 48.346473, lng: 19.173958 },
    { lat: 48.3460073, lng: 19.1750537 },
    { lat: 48.3458777, lng: 19.1749147 },
    { lat: 48.3456306, lng: 19.1752745 },
    { lat: 48.3454923, lng: 19.1752148 },
    { lat: 48.3452878, lng: 19.1752977 },
    { lat: 48.3448748, lng: 19.1756983 },
    { lat: 48.3447212, lng: 19.1761034 },
    { lat: 48.3443197, lng: 19.1768106 },
    { lat: 48.3439192, lng: 19.1771244 },
    { lat: 48.3430586, lng: 19.1768596 },
    { lat: 48.3419256, lng: 19.1764042 },
    { lat: 48.3402659, lng: 19.1760297 },
    { lat: 48.3397499, lng: 19.1758092 },
    { lat: 48.3396268, lng: 19.1763526 },
    { lat: 48.3382814, lng: 19.1803169 },
    { lat: 48.338484, lng: 19.1805169 },
    { lat: 48.338553, lng: 19.1815751 },
    { lat: 48.3385554, lng: 19.1815769 },
    { lat: 48.338759, lng: 19.181571 },
    { lat: 48.3390858, lng: 19.1818852 },
    { lat: 48.3393129, lng: 19.1818536 },
    { lat: 48.3396046, lng: 19.1819158 },
    { lat: 48.3396803, lng: 19.1840458 },
    { lat: 48.3397728, lng: 19.1850553 },
    { lat: 48.3397505, lng: 19.1863624 },
    { lat: 48.3396598, lng: 19.1870382 },
    { lat: 48.3393694, lng: 19.1865439 },
    { lat: 48.3392385, lng: 19.1881925 },
    { lat: 48.3391953, lng: 19.1883178 },
    { lat: 48.3389605, lng: 19.1885677 },
    { lat: 48.3385041, lng: 19.1899643 },
    { lat: 48.3385914, lng: 19.1914644 },
    { lat: 48.3377434, lng: 19.1936352 },
    { lat: 48.3375289, lng: 19.1945706 },
    { lat: 48.3374001, lng: 19.1946313 },
    { lat: 48.3370689, lng: 19.1954396 },
    { lat: 48.3369495, lng: 19.1953933 },
    { lat: 48.3368399, lng: 19.1956619 },
    { lat: 48.3363778, lng: 19.1963107 },
    { lat: 48.3361502, lng: 19.1964939 },
    { lat: 48.3358683, lng: 19.1969981 },
    { lat: 48.3355507, lng: 19.1974137 },
    { lat: 48.3351519, lng: 19.1984505 },
    { lat: 48.335365, lng: 19.1988631 },
    { lat: 48.3358816, lng: 19.1990806 },
    { lat: 48.336432, lng: 19.1996125 },
    { lat: 48.3364943, lng: 19.1998245 },
    { lat: 48.3365057, lng: 19.2002385 },
    { lat: 48.3368031, lng: 19.2004217 },
    { lat: 48.336928, lng: 19.2006467 },
    { lat: 48.3370648, lng: 19.2007425 },
    { lat: 48.3373356, lng: 19.2007965 },
    { lat: 48.3374343, lng: 19.2011038 },
    { lat: 48.337562, lng: 19.2012764 },
    { lat: 48.337868, lng: 19.2013737 },
    { lat: 48.3381416, lng: 19.2016205 },
    { lat: 48.3381639, lng: 19.2017332 },
    { lat: 48.3379562, lng: 19.2035167 },
    { lat: 48.3379063, lng: 19.2042227 },
    { lat: 48.3379232, lng: 19.2048412 },
    { lat: 48.3378479, lng: 19.2055106 },
    { lat: 48.33838, lng: 19.2066158 },
    { lat: 48.3383679, lng: 19.207403 },
    { lat: 48.3384012, lng: 19.2077596 },
    { lat: 48.3384902, lng: 19.2078933 },
    { lat: 48.3384976, lng: 19.2088725 },
    { lat: 48.338321, lng: 19.209567 },
    { lat: 48.338355, lng: 19.209604 },
    { lat: 48.338472, lng: 19.209712 },
    { lat: 48.338588, lng: 19.209578 },
    { lat: 48.338648, lng: 19.209604 },
    { lat: 48.338611, lng: 19.209763 },
    { lat: 48.33867, lng: 19.209784 },
    { lat: 48.338754, lng: 19.209669 },
    { lat: 48.338783, lng: 19.209758 },
    { lat: 48.338938, lng: 19.209882 },
    { lat: 48.338932, lng: 19.209784 },
    { lat: 48.339015, lng: 19.209772 },
    { lat: 48.339188, lng: 19.209857 },
    { lat: 48.339235, lng: 19.210126 },
    { lat: 48.33925, lng: 19.210309 },
    { lat: 48.339433, lng: 19.210418 },
    { lat: 48.339551, lng: 19.210335 },
    { lat: 48.339783, lng: 19.21039 },
    { lat: 48.339802, lng: 19.210463 },
    { lat: 48.339927, lng: 19.210569 },
    { lat: 48.340112, lng: 19.210779 },
    { lat: 48.340262, lng: 19.210814 },
    { lat: 48.340278, lng: 19.210845 },
    { lat: 48.340395, lng: 19.211069 },
    { lat: 48.340358, lng: 19.211176 },
    { lat: 48.340435, lng: 19.211376 },
    { lat: 48.340578, lng: 19.211392 },
    { lat: 48.340613, lng: 19.211466 },
    { lat: 48.340788, lng: 19.211578 },
    { lat: 48.340858, lng: 19.211496 },
    { lat: 48.341025, lng: 19.211545 },
    { lat: 48.341088, lng: 19.211814 },
    { lat: 48.341181, lng: 19.211833 },
    { lat: 48.341208, lng: 19.212074 },
    { lat: 48.341295, lng: 19.212308 },
    { lat: 48.341507, lng: 19.212687 },
    { lat: 48.341389, lng: 19.212754 },
    { lat: 48.341428, lng: 19.212959 },
    { lat: 48.341485, lng: 19.213058 },
    { lat: 48.341567, lng: 19.213051 },
    { lat: 48.341695, lng: 19.213488 },
    { lat: 48.341863, lng: 19.213804 },
    { lat: 48.341942, lng: 19.213832 },
    { lat: 48.341962, lng: 19.213841 },
    { lat: 48.342003, lng: 19.213933 },
    { lat: 48.341996, lng: 19.214059 },
    { lat: 48.342078, lng: 19.214157 },
    { lat: 48.342092, lng: 19.214158 },
    { lat: 48.342205, lng: 19.21419 },
    { lat: 48.342291, lng: 19.214787 },
    { lat: 48.342302, lng: 19.21486 },
    { lat: 48.342328, lng: 19.214944 },
    { lat: 48.342317, lng: 19.21506 },
    { lat: 48.342415, lng: 19.215373 },
    { lat: 48.342463, lng: 19.215457 },
    { lat: 48.342545, lng: 19.215811 },
    { lat: 48.342576, lng: 19.215897 },
    { lat: 48.342564, lng: 19.216108 },
    { lat: 48.342541, lng: 19.216513 },
    { lat: 48.342616, lng: 19.217111 },
    { lat: 48.342773, lng: 19.217731 },
    { lat: 48.342778, lng: 19.217745 },
    { lat: 48.342789, lng: 19.217942 },
    { lat: 48.342862, lng: 19.218271 },
    { lat: 48.342959, lng: 19.21871 },
    { lat: 48.342968, lng: 19.218751 },
    { lat: 48.343084, lng: 19.219227 },
    { lat: 48.343136, lng: 19.219566 },
    { lat: 48.343164, lng: 19.219705 },
    { lat: 48.343294, lng: 19.22035 },
    { lat: 48.343339, lng: 19.220513 },
    { lat: 48.343372, lng: 19.220633 },
    { lat: 48.343467, lng: 19.221032 },
    { lat: 48.343477, lng: 19.2211 },
    { lat: 48.343492, lng: 19.221202 },
    { lat: 48.34349, lng: 19.221443 },
    { lat: 48.343534, lng: 19.221922 },
    { lat: 48.343559, lng: 19.222054 },
    { lat: 48.343676, lng: 19.223057 },
    { lat: 48.343708, lng: 19.223278 },
    { lat: 48.343729, lng: 19.223633 },
    { lat: 48.343768, lng: 19.224076 },
    { lat: 48.343828, lng: 19.224431 },
    { lat: 48.343929, lng: 19.224819 },
    { lat: 48.344462, lng: 19.226176 },
    { lat: 48.344001, lng: 19.226375 },
    { lat: 48.343952, lng: 19.226396 },
    { lat: 48.343969, lng: 19.226457 },
    { lat: 48.344376, lng: 19.227857 },
    { lat: 48.344783, lng: 19.228495 },
    { lat: 48.345578, lng: 19.229712 },
    { lat: 48.345771, lng: 19.22998 },
    { lat: 48.346718, lng: 19.231156 },
    { lat: 48.346797, lng: 19.231254 },
    { lat: 48.347136, lng: 19.231697 },
    { lat: 48.347179, lng: 19.23179 },
    { lat: 48.347529, lng: 19.23201 },
    { lat: 48.347824, lng: 19.231724 },
    { lat: 48.347844, lng: 19.231625 },
    { lat: 48.347731, lng: 19.231395 },
    { lat: 48.347755, lng: 19.231173 },
    { lat: 48.347994, lng: 19.231153 },
    { lat: 48.34825, lng: 19.230969 },
    { lat: 48.348415, lng: 19.231045 },
    { lat: 48.34871, lng: 19.231066 },
    { lat: 48.348789, lng: 19.23104 },
    { lat: 48.348837, lng: 19.230995 },
    { lat: 48.348904, lng: 19.230868 },
    { lat: 48.348956, lng: 19.230782 },
    { lat: 48.349097, lng: 19.230583 },
    { lat: 48.34918, lng: 19.230396 },
    { lat: 48.349164, lng: 19.230266 },
    { lat: 48.349255, lng: 19.229991 },
    { lat: 48.349438, lng: 19.229853 },
    { lat: 48.349544, lng: 19.229906 },
    { lat: 48.349635, lng: 19.229892 },
    { lat: 48.349761, lng: 19.229829 },
    { lat: 48.349837, lng: 19.229816 },
    { lat: 48.349924, lng: 19.22971 },
    { lat: 48.350066, lng: 19.229694 },
    { lat: 48.350235, lng: 19.22965 },
    { lat: 48.350336, lng: 19.229602 },
    { lat: 48.350401, lng: 19.229541 },
    { lat: 48.350583, lng: 19.229454 },
    { lat: 48.351081, lng: 19.229386 },
    { lat: 48.35127, lng: 19.229356 },
    { lat: 48.351373, lng: 19.229209 },
    { lat: 48.351476, lng: 19.22916 },
    { lat: 48.351502, lng: 19.229175 },
    { lat: 48.351564, lng: 19.229265 },
    { lat: 48.35169, lng: 19.229446 },
    { lat: 48.351824, lng: 19.22953 },
    { lat: 48.351848, lng: 19.229514 },
    { lat: 48.351842, lng: 19.229406 },
    { lat: 48.35194, lng: 19.229379 },
    { lat: 48.351967, lng: 19.229313 },
    { lat: 48.352086, lng: 19.22941 },
    { lat: 48.35219, lng: 19.229369 },
    { lat: 48.3522, lng: 19.229227 },
    { lat: 48.35224, lng: 19.229223 },
    { lat: 48.352251, lng: 19.229193 },
    { lat: 48.352235, lng: 19.229125 },
    { lat: 48.352228, lng: 19.229084 },
    { lat: 48.352259, lng: 19.229081 },
    { lat: 48.352286, lng: 19.229117 },
    { lat: 48.352351, lng: 19.229102 },
    { lat: 48.352387, lng: 19.22912 },
    { lat: 48.352326, lng: 19.229189 },
    { lat: 48.352378, lng: 19.229263 },
    { lat: 48.352429, lng: 19.229241 },
    { lat: 48.352528, lng: 19.229189 },
    { lat: 48.352552, lng: 19.229198 },
    { lat: 48.352713, lng: 19.229239 },
    { lat: 48.352742, lng: 19.229057 },
    { lat: 48.352748, lng: 19.22904 },
    { lat: 48.352795, lng: 19.229109 },
    { lat: 48.352849, lng: 19.229158 },
    { lat: 48.352865, lng: 19.22915 },
    { lat: 48.352947, lng: 19.229114 },
    { lat: 48.353004, lng: 19.229088 },
    { lat: 48.353066, lng: 19.22906 },
    { lat: 48.35321, lng: 19.229105 },
    { lat: 48.353355, lng: 19.229236 },
    { lat: 48.353387, lng: 19.229264 },
    { lat: 48.353451, lng: 19.229321 },
    { lat: 48.353562, lng: 19.229677 },
    { lat: 48.353578, lng: 19.229731 },
    { lat: 48.353592, lng: 19.229754 },
    { lat: 48.353721, lng: 19.229751 },
    { lat: 48.353828, lng: 19.229683 },
    { lat: 48.353893, lng: 19.229677 },
    { lat: 48.354016, lng: 19.229835 },
    { lat: 48.354066, lng: 19.229807 },
    { lat: 48.354166, lng: 19.229655 },
    { lat: 48.354282, lng: 19.229618 },
    { lat: 48.354479, lng: 19.229704 },
    { lat: 48.354497, lng: 19.229688 },
    { lat: 48.354681, lng: 19.229533 },
    { lat: 48.354968, lng: 19.229422 },
    { lat: 48.35518, lng: 19.229451 },
    { lat: 48.35558, lng: 19.22969 },
    { lat: 48.355823, lng: 19.229792 },
    { lat: 48.355977, lng: 19.229857 },
    { lat: 48.356151, lng: 19.230069 },
    { lat: 48.356217, lng: 19.230117 },
    { lat: 48.356477, lng: 19.230305 },
    { lat: 48.356506, lng: 19.230263 },
    { lat: 48.356593, lng: 19.230139 },
    { lat: 48.356705, lng: 19.230252 },
    { lat: 48.356728, lng: 19.230267 },
    { lat: 48.356721, lng: 19.230423 },
    { lat: 48.35681, lng: 19.230464 },
    { lat: 48.356847, lng: 19.230638 },
    { lat: 48.356845, lng: 19.230668 },
    { lat: 48.356936, lng: 19.230671 },
    { lat: 48.35698, lng: 19.230639 },
    { lat: 48.356997, lng: 19.230551 },
    { lat: 48.357234, lng: 19.23051 },
    { lat: 48.357279, lng: 19.230644 },
    { lat: 48.357394, lng: 19.230802 },
    { lat: 48.357393, lng: 19.230917 },
    { lat: 48.357438, lng: 19.230996 },
    { lat: 48.357448, lng: 19.231089 },
    { lat: 48.357439, lng: 19.231172 },
    { lat: 48.357541, lng: 19.231323 },
    { lat: 48.357612, lng: 19.231356 },
    { lat: 48.357702, lng: 19.23144 },
    { lat: 48.357753, lng: 19.231488 },
    { lat: 48.357925, lng: 19.231704 },
    { lat: 48.358013, lng: 19.231751 },
    { lat: 48.35806, lng: 19.231823 },
    { lat: 48.358249, lng: 19.231902 },
    { lat: 48.358389, lng: 19.232069 },
    { lat: 48.358578, lng: 19.232144 },
    { lat: 48.358697, lng: 19.232041 },
    { lat: 48.358852, lng: 19.232093 },
    { lat: 48.358903, lng: 19.232159 },
    { lat: 48.358993, lng: 19.232208 },
    { lat: 48.359067, lng: 19.23218 },
    { lat: 48.359201, lng: 19.232235 },
    { lat: 48.359333, lng: 19.232255 },
    { lat: 48.359552, lng: 19.232482 },
    { lat: 48.359619, lng: 19.232493 },
    { lat: 48.359667, lng: 19.2326 },
    { lat: 48.359678, lng: 19.232583 },
    { lat: 48.359791, lng: 19.232396 },
    { lat: 48.362297, lng: 19.228276 },
    { lat: 48.36208, lng: 19.227464 },
    { lat: 48.361855, lng: 19.2265 },
    { lat: 48.36154, lng: 19.22545 },
    { lat: 48.364121, lng: 19.224781 },
    { lat: 48.364827, lng: 19.224623 },
    { lat: 48.364833, lng: 19.224416 },
    { lat: 48.364845, lng: 19.22424 },
    { lat: 48.364917, lng: 19.2233 },
    { lat: 48.364977, lng: 19.222437 },
    { lat: 48.365053, lng: 19.221262 },
    { lat: 48.365087, lng: 19.220798 },
    { lat: 48.365389, lng: 19.220858 },
    { lat: 48.365759, lng: 19.220909 },
    { lat: 48.366348, lng: 19.220939 },
    { lat: 48.366789, lng: 19.221016 },
    { lat: 48.367091, lng: 19.221055 },
    { lat: 48.367331, lng: 19.221073 },
    { lat: 48.367759, lng: 19.221145 },
    { lat: 48.367941, lng: 19.22115 },
    { lat: 48.3684, lng: 19.221209 },
    { lat: 48.36874, lng: 19.221268 },
    { lat: 48.369022, lng: 19.221272 },
    { lat: 48.369831, lng: 19.221335 },
    { lat: 48.36997, lng: 19.219583 },
    { lat: 48.370218, lng: 19.216456 },
    { lat: 48.370255, lng: 19.215988 },
    { lat: 48.370207, lng: 19.215951 },
    { lat: 48.370213, lng: 19.215865 },
    { lat: 48.370303, lng: 19.214603 },
    { lat: 48.370343, lng: 19.214043 },
    { lat: 48.370366, lng: 19.214039 },
    { lat: 48.370386, lng: 19.214019 },
    { lat: 48.370419, lng: 19.21393 },
    { lat: 48.370502, lng: 19.213705 },
    { lat: 48.370774, lng: 19.212962 },
    { lat: 48.370879, lng: 19.212081 },
    { lat: 48.370987, lng: 19.211309 },
    { lat: 48.371084, lng: 19.211191 },
    { lat: 48.371944, lng: 19.210134 },
    { lat: 48.372545, lng: 19.209395 },
    { lat: 48.372596, lng: 19.207992 },
    { lat: 48.372622, lng: 19.206718 },
    { lat: 48.372672, lng: 19.205519 },
    { lat: 48.372686, lng: 19.20484 },
    { lat: 48.372736, lng: 19.203631 },
    { lat: 48.372755, lng: 19.202643 },
    { lat: 48.372752, lng: 19.202169 },
    { lat: 48.372766, lng: 19.201667 },
    { lat: 48.3729, lng: 19.201725 },
    { lat: 48.373705, lng: 19.201983 },
    { lat: 48.37379, lng: 19.202002 },
    { lat: 48.373766, lng: 19.201606 },
    { lat: 48.374231, lng: 19.201562 },
    { lat: 48.374798, lng: 19.201612 },
    { lat: 48.375184, lng: 19.201694 },
    { lat: 48.375561, lng: 19.201867 },
    { lat: 48.375566, lng: 19.201261 },
    { lat: 48.377003, lng: 19.201251 },
    { lat: 48.377857, lng: 19.200576 },
    { lat: 48.379363, lng: 19.199894 },
    { lat: 48.379552, lng: 19.199772 },
    { lat: 48.379613, lng: 19.199732 },
    { lat: 48.379806, lng: 19.199611 },
    { lat: 48.379985, lng: 19.197519 },
    { lat: 48.38001, lng: 19.197236 },
    { lat: 48.380016, lng: 19.197172 },
    { lat: 48.380029, lng: 19.197105 },
    { lat: 48.380034, lng: 19.197063 },
    { lat: 48.380202, lng: 19.195562 },
    { lat: 48.380534, lng: 19.195749 },
    { lat: 48.380982, lng: 19.195914 },
    { lat: 48.381863, lng: 19.196273 },
    { lat: 48.382557, lng: 19.196575 },
    { lat: 48.382788, lng: 19.196676 },
    { lat: 48.383534, lng: 19.195114 },
    { lat: 48.383631, lng: 19.194911 },
    { lat: 48.384017, lng: 19.194093 },
    { lat: 48.384661, lng: 19.192709 },
    { lat: 48.385123, lng: 19.191671 },
    { lat: 48.385513, lng: 19.190803 },
    { lat: 48.385918, lng: 19.189908 },
    { lat: 48.386419, lng: 19.188792 },
    { lat: 48.386447, lng: 19.188729 },
    { lat: 48.386464, lng: 19.188692 },
    { lat: 48.386435, lng: 19.188679 },
    { lat: 48.386386, lng: 19.188656 },
    { lat: 48.386096, lng: 19.188516 },
    { lat: 48.385722, lng: 19.188343 },
    { lat: 48.385689, lng: 19.188328 },
    { lat: 48.386128, lng: 19.187912 },
    { lat: 48.386146, lng: 19.187894 },
    { lat: 48.38618, lng: 19.18786 },
    { lat: 48.38628, lng: 19.187762 },
    { lat: 48.386624, lng: 19.18737 },
    { lat: 48.387111, lng: 19.187085 },
    { lat: 48.387291, lng: 19.186253 },
    { lat: 48.387792, lng: 19.184723 },
    { lat: 48.388069, lng: 19.183559 },
    { lat: 48.388162, lng: 19.182816 },
    { lat: 48.38824, lng: 19.182198 },
    { lat: 48.388912, lng: 19.178925 },
    { lat: 48.388865, lng: 19.178619 },
    { lat: 48.388818, lng: 19.177832 },
    { lat: 48.38884, lng: 19.177523 },
    { lat: 48.388923, lng: 19.177008 },
    { lat: 48.389129, lng: 19.176152 },
    { lat: 48.38924, lng: 19.175914 },
    { lat: 48.389349, lng: 19.175794 },
    { lat: 48.389715, lng: 19.175588 },
    { lat: 48.390048, lng: 19.175387 },
    { lat: 48.39017, lng: 19.175299 },
    { lat: 48.390387, lng: 19.175076 },
    { lat: 48.390589, lng: 19.1749 },
    { lat: 48.390783, lng: 19.174775 },
    { lat: 48.391594, lng: 19.174518 },
    { lat: 48.391557, lng: 19.174376 },
    { lat: 48.391407, lng: 19.173936 },
    { lat: 48.391315, lng: 19.173644 },
    { lat: 48.390965, lng: 19.172606 },
    { lat: 48.390963, lng: 19.172584 },
    { lat: 48.390931, lng: 19.172422 },
    { lat: 48.3908249, lng: 19.1715633 },
    { lat: 48.3907387, lng: 19.1710687 },
    { lat: 48.390641, lng: 19.1702598 },
    { lat: 48.390576, lng: 19.170071 },
    { lat: 48.390496, lng: 19.169915 },
    { lat: 48.390223, lng: 19.169434 },
    { lat: 48.390189, lng: 19.169487 },
    { lat: 48.389737, lng: 19.170143 },
    { lat: 48.3891189, lng: 19.1710353 },
    { lat: 48.3887908, lng: 19.1719039 },
    { lat: 48.387579, lng: 19.1728143 },
    { lat: 48.3876894, lng: 19.1729968 },
    { lat: 48.3870532, lng: 19.1735849 },
    { lat: 48.3866168, lng: 19.1742449 },
    { lat: 48.386019, lng: 19.1752909 },
    { lat: 48.3856246, lng: 19.1766877 },
    { lat: 48.3851364, lng: 19.176865 },
    { lat: 48.384357, lng: 19.1769272 },
    { lat: 48.3832318, lng: 19.1768089 },
    { lat: 48.3828418, lng: 19.177131 },
    { lat: 48.3825908, lng: 19.1774348 },
    { lat: 48.3824562, lng: 19.177426 },
  ],
  Devičie: [
    { lat: 48.2844716, lng: 19.0311812 },
    { lat: 48.2844786, lng: 19.0311825 },
    { lat: 48.288025, lng: 19.0323753 },
    { lat: 48.2887617, lng: 19.0323393 },
    { lat: 48.2907723, lng: 19.0317894 },
    { lat: 48.2920335, lng: 19.0306015 },
    { lat: 48.2928796, lng: 19.0300329 },
    { lat: 48.2949699, lng: 19.0292274 },
    { lat: 48.2953681, lng: 19.0291888 },
    { lat: 48.2956809, lng: 19.0293094 },
    { lat: 48.2976738, lng: 19.0306157 },
    { lat: 48.298581, lng: 19.0310956 },
    { lat: 48.2991475, lng: 19.0315517 },
    { lat: 48.2997527, lng: 19.0318344 },
    { lat: 48.2997692, lng: 19.0321183 },
    { lat: 48.2996306, lng: 19.0323193 },
    { lat: 48.2996436, lng: 19.0324658 },
    { lat: 48.2994676, lng: 19.0331505 },
    { lat: 48.2993423, lng: 19.0333039 },
    { lat: 48.2992029, lng: 19.033312 },
    { lat: 48.2991713, lng: 19.0334016 },
    { lat: 48.2993462, lng: 19.0336699 },
    { lat: 48.2993058, lng: 19.0338328 },
    { lat: 48.2991514, lng: 19.0338546 },
    { lat: 48.2990184, lng: 19.0344562 },
    { lat: 48.2988935, lng: 19.0346155 },
    { lat: 48.2990032, lng: 19.0347999 },
    { lat: 48.2991628, lng: 19.035502 },
    { lat: 48.2990627, lng: 19.0359996 },
    { lat: 48.2985919, lng: 19.0370127 },
    { lat: 48.2986002, lng: 19.0373542 },
    { lat: 48.298286, lng: 19.03792 },
    { lat: 48.2982064, lng: 19.0382385 },
    { lat: 48.2980897, lng: 19.038449 },
    { lat: 48.2978361, lng: 19.0386524 },
    { lat: 48.29725, lng: 19.0389707 },
    { lat: 48.2969468, lng: 19.0392357 },
    { lat: 48.2962371, lng: 19.0396503 },
    { lat: 48.2959684, lng: 19.0394432 },
    { lat: 48.2959631, lng: 19.0393167 },
    { lat: 48.2958374, lng: 19.0393713 },
    { lat: 48.2958398, lng: 19.039597 },
    { lat: 48.2957253, lng: 19.0396726 },
    { lat: 48.2957734, lng: 19.040022 },
    { lat: 48.2960906, lng: 19.0408325 },
    { lat: 48.2961571, lng: 19.0411436 },
    { lat: 48.2961259, lng: 19.0414187 },
    { lat: 48.2959132, lng: 19.0416281 },
    { lat: 48.2959808, lng: 19.0419405 },
    { lat: 48.2959654, lng: 19.042417 },
    { lat: 48.2960265, lng: 19.0427975 },
    { lat: 48.295769, lng: 19.0435465 },
    { lat: 48.2955397, lng: 19.0444971 },
    { lat: 48.295382, lng: 19.0446828 },
    { lat: 48.2949555, lng: 19.0444278 },
    { lat: 48.2948196, lng: 19.0445494 },
    { lat: 48.2945921, lng: 19.044531 },
    { lat: 48.2943817, lng: 19.0442752 },
    { lat: 48.2943665, lng: 19.044194 },
    { lat: 48.2941793, lng: 19.0444862 },
    { lat: 48.2939198, lng: 19.0444816 },
    { lat: 48.2937688, lng: 19.044395 },
    { lat: 48.2935479, lng: 19.0439698 },
    { lat: 48.2932551, lng: 19.0439134 },
    { lat: 48.2930264, lng: 19.0440251 },
    { lat: 48.2928391, lng: 19.0439553 },
    { lat: 48.2927501, lng: 19.0440465 },
    { lat: 48.2918999, lng: 19.0442666 },
    { lat: 48.2917318, lng: 19.0444652 },
    { lat: 48.2913773, lng: 19.0446141 },
    { lat: 48.2911024, lng: 19.0450174 },
    { lat: 48.2910342, lng: 19.045318 },
    { lat: 48.2910572, lng: 19.0455529 },
    { lat: 48.2913111, lng: 19.0463373 },
    { lat: 48.2914985, lng: 19.0466333 },
    { lat: 48.2916353, lng: 19.0467505 },
    { lat: 48.2916848, lng: 19.0469255 },
    { lat: 48.2920568, lng: 19.0476384 },
    { lat: 48.2926927, lng: 19.0474827 },
    { lat: 48.2930949, lng: 19.0477221 },
    { lat: 48.2931435, lng: 19.0478377 },
    { lat: 48.2931689, lng: 19.0482288 },
    { lat: 48.2933026, lng: 19.0486693 },
    { lat: 48.2938023, lng: 19.049183 },
    { lat: 48.2939225, lng: 19.049219 },
    { lat: 48.2942132, lng: 19.0490164 },
    { lat: 48.2944301, lng: 19.0489132 },
    { lat: 48.294507, lng: 19.0489385 },
    { lat: 48.2947807, lng: 19.0487775 },
    { lat: 48.2952538, lng: 19.0483032 },
    { lat: 48.2957235, lng: 19.0476674 },
    { lat: 48.2960081, lng: 19.0477867 },
    { lat: 48.2960533, lng: 19.0480361 },
    { lat: 48.2964139, lng: 19.0479201 },
    { lat: 48.296998, lng: 19.0478804 },
    { lat: 48.2971545, lng: 19.0476959 },
    { lat: 48.2972255, lng: 19.0475157 },
    { lat: 48.2974503, lng: 19.047367 },
    { lat: 48.297601, lng: 19.0473396 },
    { lat: 48.2978495, lng: 19.0469216 },
    { lat: 48.2979307, lng: 19.0466599 },
    { lat: 48.2981631, lng: 19.046527 },
    { lat: 48.2982085, lng: 19.0463965 },
    { lat: 48.2983795, lng: 19.0463257 },
    { lat: 48.2986093, lng: 19.0466558 },
    { lat: 48.2988179, lng: 19.0466545 },
    { lat: 48.2990299, lng: 19.0469232 },
    { lat: 48.2991582, lng: 19.047789 },
    { lat: 48.2992163, lng: 19.0486492 },
    { lat: 48.2992233, lng: 19.0491006 },
    { lat: 48.2991647, lng: 19.0495456 },
    { lat: 48.2991891, lng: 19.0499405 },
    { lat: 48.299293, lng: 19.0505717 },
    { lat: 48.2994039, lng: 19.0508261 },
    { lat: 48.2994644, lng: 19.0513727 },
    { lat: 48.2997713, lng: 19.0511577 },
    { lat: 48.3003722, lng: 19.0509787 },
    { lat: 48.3005787, lng: 19.0511135 },
    { lat: 48.3007139, lng: 19.0516952 },
    { lat: 48.3010836, lng: 19.051779 },
    { lat: 48.3016053, lng: 19.0516862 },
    { lat: 48.3017172, lng: 19.0515995 },
    { lat: 48.3017803, lng: 19.0513331 },
    { lat: 48.3021514, lng: 19.0509766 },
    { lat: 48.3025477, lng: 19.0509462 },
    { lat: 48.3031655, lng: 19.0506084 },
    { lat: 48.3033751, lng: 19.0503612 },
    { lat: 48.3034767, lng: 19.0500532 },
    { lat: 48.3034457, lng: 19.0497522 },
    { lat: 48.3042506, lng: 19.0482082 },
    { lat: 48.3044442, lng: 19.0479575 },
    { lat: 48.3044652, lng: 19.0480136 },
    { lat: 48.304673, lng: 19.047889 },
    { lat: 48.3049027, lng: 19.0475914 },
    { lat: 48.3051699, lng: 19.0473903 },
    { lat: 48.3054642, lng: 19.0466547 },
    { lat: 48.3055731, lng: 19.0465332 },
    { lat: 48.3057974, lng: 19.046534 },
    { lat: 48.3059817, lng: 19.0467872 },
    { lat: 48.3062673, lng: 19.0474003 },
    { lat: 48.3063811, lng: 19.0478893 },
    { lat: 48.3069312, lng: 19.0480885 },
    { lat: 48.3071752, lng: 19.0480077 },
    { lat: 48.3072498, lng: 19.0481225 },
    { lat: 48.3072172, lng: 19.0482109 },
    { lat: 48.3073685, lng: 19.0484844 },
    { lat: 48.3074526, lng: 19.048789 },
    { lat: 48.3074426, lng: 19.0493483 },
    { lat: 48.3075131, lng: 19.0495857 },
    { lat: 48.3074636, lng: 19.0499901 },
    { lat: 48.3075925, lng: 19.0500856 },
    { lat: 48.307814, lng: 19.0500516 },
    { lat: 48.3078526, lng: 19.0498139 },
    { lat: 48.307917, lng: 19.0497471 },
    { lat: 48.3080489, lng: 19.0499262 },
    { lat: 48.3081176, lng: 19.0501688 },
    { lat: 48.3080234, lng: 19.050427 },
    { lat: 48.3080398, lng: 19.0507528 },
    { lat: 48.3081872, lng: 19.0513034 },
    { lat: 48.3082899, lng: 19.0514192 },
    { lat: 48.3084969, lng: 19.0515009 },
    { lat: 48.3086983, lng: 19.0517791 },
    { lat: 48.3088189, lng: 19.0517796 },
    { lat: 48.3088786, lng: 19.0516432 },
    { lat: 48.3088719, lng: 19.0513993 },
    { lat: 48.3090888, lng: 19.0509624 },
    { lat: 48.3092116, lng: 19.0510044 },
    { lat: 48.3092577, lng: 19.0511356 },
    { lat: 48.3092629, lng: 19.0513744 },
    { lat: 48.3093295, lng: 19.0514858 },
    { lat: 48.3094616, lng: 19.0515342 },
    { lat: 48.3095146, lng: 19.0516911 },
    { lat: 48.309478, lng: 19.0519942 },
    { lat: 48.309114, lng: 19.052356 },
    { lat: 48.3094089, lng: 19.0526817 },
    { lat: 48.3096224, lng: 19.0523285 },
    { lat: 48.310043, lng: 19.0526909 },
    { lat: 48.310843, lng: 19.053084 },
    { lat: 48.3109499, lng: 19.0533902 },
    { lat: 48.3111719, lng: 19.0536335 },
    { lat: 48.3112703, lng: 19.0538423 },
    { lat: 48.3112685, lng: 19.0545877 },
    { lat: 48.3113591, lng: 19.0545767 },
    { lat: 48.3114538, lng: 19.0546652 },
    { lat: 48.3115873, lng: 19.0545849 },
    { lat: 48.3117185, lng: 19.0543656 },
    { lat: 48.3118606, lng: 19.0542801 },
    { lat: 48.3119715, lng: 19.054309 },
    { lat: 48.3121191, lng: 19.0544867 },
    { lat: 48.3121828, lng: 19.0544093 },
    { lat: 48.3122282, lng: 19.0543025 },
    { lat: 48.3121701, lng: 19.0535062 },
    { lat: 48.3122786, lng: 19.0532693 },
    { lat: 48.3127314, lng: 19.053281 },
    { lat: 48.3128031, lng: 19.0533625 },
    { lat: 48.3128583, lng: 19.0536056 },
    { lat: 48.313024, lng: 19.0538075 },
    { lat: 48.3130264, lng: 19.05381 },
    { lat: 48.3131844, lng: 19.0542391 },
    { lat: 48.3131811, lng: 19.0543514 },
    { lat: 48.3132421, lng: 19.0544231 },
    { lat: 48.3134448, lng: 19.0544185 },
    { lat: 48.3133989, lng: 19.0546037 },
    { lat: 48.3134589, lng: 19.0546089 },
    { lat: 48.3135894, lng: 19.0544652 },
    { lat: 48.3138487, lng: 19.0545095 },
    { lat: 48.3139996, lng: 19.054775 },
    { lat: 48.3139914, lng: 19.054879 },
    { lat: 48.3140713, lng: 19.0550353 },
    { lat: 48.3142907, lng: 19.0552074 },
    { lat: 48.3144537, lng: 19.0551082 },
    { lat: 48.3145763, lng: 19.0545173 },
    { lat: 48.3145517, lng: 19.0543022 },
    { lat: 48.315576, lng: 19.0523655 },
    { lat: 48.3155968, lng: 19.0519073 },
    { lat: 48.3156881, lng: 19.0518622 },
    { lat: 48.3158244, lng: 19.0516601 },
    { lat: 48.3160347, lng: 19.0515586 },
    { lat: 48.3164294, lng: 19.0508451 },
    { lat: 48.3166254, lng: 19.0487645 },
    { lat: 48.3169832, lng: 19.0483028 },
    { lat: 48.3166322, lng: 19.0472718 },
    { lat: 48.3178391, lng: 19.0453371 },
    { lat: 48.3185799, lng: 19.0438247 },
    { lat: 48.3201836, lng: 19.0425603 },
    { lat: 48.3207021, lng: 19.041976 },
    { lat: 48.320757, lng: 19.0420807 },
    { lat: 48.3211572, lng: 19.041547 },
    { lat: 48.3218033, lng: 19.0410786 },
    { lat: 48.3221341, lng: 19.0410219 },
    { lat: 48.3226871, lng: 19.0406051 },
    { lat: 48.3230097, lng: 19.0404526 },
    { lat: 48.3236795, lng: 19.0402567 },
    { lat: 48.3239084, lng: 19.040032 },
    { lat: 48.3240333, lng: 19.0394931 },
    { lat: 48.3241532, lng: 19.0393232 },
    { lat: 48.3243798, lng: 19.0379813 },
    { lat: 48.3246402, lng: 19.0370256 },
    { lat: 48.3254454, lng: 19.036002 },
    { lat: 48.3259837, lng: 19.0351652 },
    { lat: 48.3262353, lng: 19.0345003 },
    { lat: 48.3264426, lng: 19.0333833 },
    { lat: 48.3268064, lng: 19.0323496 },
    { lat: 48.3273241, lng: 19.0311983 },
    { lat: 48.3277459, lng: 19.0304445 },
    { lat: 48.3282365, lng: 19.0298445 },
    { lat: 48.3284507, lng: 19.0289336 },
    { lat: 48.3287155, lng: 19.0281722 },
    { lat: 48.3289101, lng: 19.0270049 },
    { lat: 48.329113, lng: 19.0263366 },
    { lat: 48.330156, lng: 19.0257851 },
    { lat: 48.3309464, lng: 19.0252733 },
    { lat: 48.3313448, lng: 19.0249445 },
    { lat: 48.3315395, lng: 19.024851 },
    { lat: 48.3319587, lng: 19.0244659 },
    { lat: 48.3323295, lng: 19.0240201 },
    { lat: 48.3325423, lng: 19.0235678 },
    { lat: 48.332752, lng: 19.0234808 },
    { lat: 48.3329582, lng: 19.0232978 },
    { lat: 48.3333137, lng: 19.0227049 },
    { lat: 48.3336606, lng: 19.0224084 },
    { lat: 48.3338936, lng: 19.0219563 },
    { lat: 48.3344176, lng: 19.0213083 },
    { lat: 48.3350932, lng: 19.0203092 },
    { lat: 48.3352552, lng: 19.0199491 },
    { lat: 48.3356517, lng: 19.0193628 },
    { lat: 48.3357238, lng: 19.0185636 },
    { lat: 48.3361908, lng: 19.0168112 },
    { lat: 48.3364665, lng: 19.0153123 },
    { lat: 48.33792, lng: 19.015051 },
    { lat: 48.3386163, lng: 19.0150374 },
    { lat: 48.3389995, lng: 19.0147253 },
    { lat: 48.3392498, lng: 19.0144384 },
    { lat: 48.3405632, lng: 19.0125685 },
    { lat: 48.3423717, lng: 19.0097056 },
    { lat: 48.3432492, lng: 19.0088692 },
    { lat: 48.3438907, lng: 19.0082936 },
    { lat: 48.3437244, lng: 19.0079692 },
    { lat: 48.3437743, lng: 19.0078618 },
    { lat: 48.3436527, lng: 19.0076028 },
    { lat: 48.3434465, lng: 19.0074049 },
    { lat: 48.3432112, lng: 19.0072801 },
    { lat: 48.342962, lng: 19.0070116 },
    { lat: 48.342726, lng: 19.0069617 },
    { lat: 48.3422786, lng: 19.0071443 },
    { lat: 48.3412129, lng: 19.006825 },
    { lat: 48.3407972, lng: 19.0067436 },
    { lat: 48.3403501, lng: 19.0067979 },
    { lat: 48.3402629, lng: 19.0067451 },
    { lat: 48.3401312, lng: 19.0065525 },
    { lat: 48.3400407, lng: 19.0065246 },
    { lat: 48.3394378, lng: 19.0059319 },
    { lat: 48.3387051, lng: 19.0049129 },
    { lat: 48.3382545, lng: 19.0045683 },
    { lat: 48.3376959, lng: 19.0043583 },
    { lat: 48.3373204, lng: 19.0043328 },
    { lat: 48.3356326, lng: 19.0047113 },
    { lat: 48.3350879, lng: 19.0046428 },
    { lat: 48.3342099, lng: 19.0042065 },
    { lat: 48.3337672, lng: 19.004216 },
    { lat: 48.3335241, lng: 19.0040443 },
    { lat: 48.3331424, lng: 19.0039693 },
    { lat: 48.3325094, lng: 19.0033174 },
    { lat: 48.331837, lng: 19.0030432 },
    { lat: 48.3311772, lng: 19.0024841 },
    { lat: 48.3302986, lng: 19.0020989 },
    { lat: 48.3299304, lng: 19.0017768 },
    { lat: 48.3297068, lng: 19.0013953 },
    { lat: 48.3298993, lng: 19.0011472 },
    { lat: 48.3300367, lng: 19.0011396 },
    { lat: 48.330401, lng: 19.0006572 },
    { lat: 48.3307297, lng: 19.0003793 },
    { lat: 48.3307731, lng: 19.0004151 },
    { lat: 48.3309505, lng: 19.0002204 },
    { lat: 48.3301327, lng: 18.9983752 },
    { lat: 48.3299716, lng: 18.9972251 },
    { lat: 48.3297784, lng: 18.9963788 },
    { lat: 48.3296948, lng: 18.996045 },
    { lat: 48.3295403, lng: 18.9957346 },
    { lat: 48.3293872, lng: 18.9955584 },
    { lat: 48.3292912, lng: 18.9952268 },
    { lat: 48.3291026, lng: 18.9941135 },
    { lat: 48.3288426, lng: 18.9930821 },
    { lat: 48.3287936, lng: 18.9925835 },
    { lat: 48.3286553, lng: 18.9921919 },
    { lat: 48.3298535, lng: 18.9899332 },
    { lat: 48.3298414, lng: 18.9898942 },
    { lat: 48.3294911, lng: 18.9899897 },
    { lat: 48.3289174, lng: 18.9903219 },
    { lat: 48.3279947, lng: 18.9906502 },
    { lat: 48.3267499, lng: 18.9908224 },
    { lat: 48.326487, lng: 18.9906676 },
    { lat: 48.3263663, lng: 18.9903569 },
    { lat: 48.3261758, lng: 18.9902207 },
    { lat: 48.3244037, lng: 18.9898999 },
    { lat: 48.3221015, lng: 18.9914474 },
    { lat: 48.3204808, lng: 18.9921497 },
    { lat: 48.3199457, lng: 18.9924888 },
    { lat: 48.3177029, lng: 18.99346 },
    { lat: 48.3175962, lng: 18.9934007 },
    { lat: 48.3173642, lng: 18.9936706 },
    { lat: 48.3168829, lng: 18.9939149 },
    { lat: 48.316762, lng: 18.993888 },
    { lat: 48.3163708, lng: 18.9941117 },
    { lat: 48.316009, lng: 18.9941581 },
    { lat: 48.3158849, lng: 18.9942439 },
    { lat: 48.3157694, lng: 18.9947463 },
    { lat: 48.315722, lng: 18.9949423 },
    { lat: 48.315554, lng: 18.9951649 },
    { lat: 48.3153344, lng: 18.9953013 },
    { lat: 48.3146596, lng: 18.9955146 },
    { lat: 48.3145695, lng: 18.9956241 },
    { lat: 48.3144933, lng: 18.995834 },
    { lat: 48.3144359, lng: 18.9964328 },
    { lat: 48.3142861, lng: 18.9971007 },
    { lat: 48.3142898, lng: 18.9975951 },
    { lat: 48.3140873, lng: 18.9984209 },
    { lat: 48.3134894, lng: 18.9993465 },
    { lat: 48.313736, lng: 18.9998454 },
    { lat: 48.313337, lng: 19.0005392 },
    { lat: 48.3135076, lng: 19.0007893 },
    { lat: 48.3133493, lng: 19.0011977 },
    { lat: 48.3129313, lng: 19.0015813 },
    { lat: 48.3121877, lng: 19.0024346 },
    { lat: 48.3121668, lng: 19.0025107 },
    { lat: 48.3123043, lng: 19.0027103 },
    { lat: 48.3121355, lng: 19.0030938 },
    { lat: 48.3118904, lng: 19.00346 },
    { lat: 48.311911, lng: 19.003576 },
    { lat: 48.3118324, lng: 19.0037167 },
    { lat: 48.3112508, lng: 19.0046064 },
    { lat: 48.3105617, lng: 19.0054244 },
    { lat: 48.3100303, lng: 19.006236 },
    { lat: 48.3100155, lng: 19.0063447 },
    { lat: 48.3099025, lng: 19.0065155 },
    { lat: 48.3096445, lng: 19.0067628 },
    { lat: 48.3093474, lng: 19.0074202 },
    { lat: 48.308996, lng: 19.0078042 },
    { lat: 48.3089444, lng: 19.0079415 },
    { lat: 48.3088727, lng: 19.0079288 },
    { lat: 48.3088243, lng: 19.008149 },
    { lat: 48.3086515, lng: 19.0083887 },
    { lat: 48.3084758, lng: 19.0083949 },
    { lat: 48.307835, lng: 19.0087186 },
    { lat: 48.3065805, lng: 19.0089908 },
    { lat: 48.3056113, lng: 19.0096671 },
    { lat: 48.3054978, lng: 19.0096932 },
    { lat: 48.3053316, lng: 19.0099282 },
    { lat: 48.3050891, lng: 19.0099963 },
    { lat: 48.304864, lng: 19.0102476 },
    { lat: 48.3049083, lng: 19.0103856 },
    { lat: 48.3048815, lng: 19.0104577 },
    { lat: 48.3045614, lng: 19.0108357 },
    { lat: 48.3044374, lng: 19.0111059 },
    { lat: 48.3040021, lng: 19.0117682 },
    { lat: 48.3039618, lng: 19.0119758 },
    { lat: 48.3038764, lng: 19.012 },
    { lat: 48.3038454, lng: 19.0121201 },
    { lat: 48.3037668, lng: 19.0121503 },
    { lat: 48.3037567, lng: 19.012286 },
    { lat: 48.3036093, lng: 19.0123146 },
    { lat: 48.3034685, lng: 19.0125605 },
    { lat: 48.3032967, lng: 19.0124862 },
    { lat: 48.3032933, lng: 19.0125497 },
    { lat: 48.3030376, lng: 19.0126454 },
    { lat: 48.3028672, lng: 19.0125321 },
    { lat: 48.3027848, lng: 19.0126407 },
    { lat: 48.3025388, lng: 19.0126578 },
    { lat: 48.3023826, lng: 19.0130896 },
    { lat: 48.3020036, lng: 19.0133556 },
    { lat: 48.3018955, lng: 19.0135574 },
    { lat: 48.30178, lng: 19.0136552 },
    { lat: 48.3015471, lng: 19.0142013 },
    { lat: 48.3011224, lng: 19.0146265 },
    { lat: 48.3009371, lng: 19.0150655 },
    { lat: 48.3008776, lng: 19.0153365 },
    { lat: 48.3006598, lng: 19.0155097 },
    { lat: 48.3001511, lng: 19.0161924 },
    { lat: 48.3000933, lng: 19.0166084 },
    { lat: 48.2997449, lng: 19.0166977 },
    { lat: 48.2996095, lng: 19.0169872 },
    { lat: 48.2992428, lng: 19.0172461 },
    { lat: 48.2991257, lng: 19.0174029 },
    { lat: 48.2988453, lng: 19.0175662 },
    { lat: 48.2987314, lng: 19.0177007 },
    { lat: 48.2986611, lng: 19.0179353 },
    { lat: 48.2985196, lng: 19.0179688 },
    { lat: 48.2983553, lng: 19.0183987 },
    { lat: 48.2983602, lng: 19.0185844 },
    { lat: 48.2984529, lng: 19.0185848 },
    { lat: 48.2984985, lng: 19.0186656 },
    { lat: 48.2984449, lng: 19.0189872 },
    { lat: 48.2982007, lng: 19.0194577 },
    { lat: 48.2981169, lng: 19.0194963 },
    { lat: 48.2978143, lng: 19.0202662 },
    { lat: 48.2976822, lng: 19.0204608 },
    { lat: 48.2974256, lng: 19.0206937 },
    { lat: 48.2973454, lng: 19.0206681 },
    { lat: 48.2968557, lng: 19.0210614 },
    { lat: 48.2966695, lng: 19.0215019 },
    { lat: 48.2959646, lng: 19.0217008 },
    { lat: 48.2958708, lng: 19.0218071 },
    { lat: 48.2958831, lng: 19.02185 },
    { lat: 48.2949531, lng: 19.022872 },
    { lat: 48.2939184, lng: 19.0243456 },
    { lat: 48.2933331, lng: 19.0248534 },
    { lat: 48.2923838, lng: 19.0251237 },
    { lat: 48.2909935, lng: 19.0260511 },
    { lat: 48.2877926, lng: 19.026633 },
    { lat: 48.2873073, lng: 19.0266231 },
    { lat: 48.2869344, lng: 19.0264496 },
    { lat: 48.2866901, lng: 19.0262583 },
    { lat: 48.2857635, lng: 19.0257273 },
    { lat: 48.285232, lng: 19.0250253 },
    { lat: 48.2850766, lng: 19.026145 },
    { lat: 48.2848961, lng: 19.0269415 },
    { lat: 48.2847072, lng: 19.028133 },
    { lat: 48.2845839, lng: 19.0292324 },
    { lat: 48.2844753, lng: 19.0311418 },
    { lat: 48.2844716, lng: 19.0311812 },
  ],
  HornýBadín: [
    { lat: 48.2873269, lng: 19.0724794 },
    { lat: 48.2873278, lng: 19.0721639 },
    { lat: 48.2872743, lng: 19.0714814 },
    { lat: 48.2873221, lng: 19.0711553 },
    { lat: 48.2871069, lng: 19.0707976 },
    { lat: 48.2870556, lng: 19.070586 },
    { lat: 48.2871178, lng: 19.0692915 },
    { lat: 48.2865872, lng: 19.0681978 },
    { lat: 48.2864011, lng: 19.0679742 },
    { lat: 48.2861669, lng: 19.0678234 },
    { lat: 48.2859985, lng: 19.0676134 },
    { lat: 48.2858435, lng: 19.0672224 },
    { lat: 48.2856611, lng: 19.0670731 },
    { lat: 48.2855825, lng: 19.0668306 },
    { lat: 48.2855522, lng: 19.0662744 },
    { lat: 48.2853851, lng: 19.0660678 },
    { lat: 48.2852686, lng: 19.0660162 },
    { lat: 48.2849373, lng: 19.0660179 },
    { lat: 48.284833, lng: 19.0656272 },
    { lat: 48.2847716, lng: 19.0650373 },
    { lat: 48.2839156, lng: 19.0643791 },
    { lat: 48.2838491, lng: 19.0639126 },
    { lat: 48.2837467, lng: 19.0638025 },
    { lat: 48.2834544, lng: 19.0637962 },
    { lat: 48.2833071, lng: 19.0639637 },
    { lat: 48.2831384, lng: 19.0639484 },
    { lat: 48.2830265, lng: 19.0637622 },
    { lat: 48.2828477, lng: 19.0630323 },
    { lat: 48.2826237, lng: 19.0627918 },
    { lat: 48.2824704, lng: 19.0626853 },
    { lat: 48.2822859, lng: 19.062737 },
    { lat: 48.2818867, lng: 19.0630005 },
    { lat: 48.2816625, lng: 19.0629399 },
    { lat: 48.2811826, lng: 19.0620286 },
    { lat: 48.2809839, lng: 19.0617576 },
    { lat: 48.2807881, lng: 19.061852 },
    { lat: 48.2805279, lng: 19.0622807 },
    { lat: 48.2799431, lng: 19.0615947 },
    { lat: 48.2797095, lng: 19.0610994 },
    { lat: 48.2796357, lng: 19.0607984 },
    { lat: 48.2794377, lng: 19.0603391 },
    { lat: 48.2790617, lng: 19.0601243 },
    { lat: 48.2788514, lng: 19.0591727 },
    { lat: 48.2786872, lng: 19.0576183 },
    { lat: 48.2785708, lng: 19.05748 },
    { lat: 48.278061, lng: 19.0574935 },
    { lat: 48.277813, lng: 19.0572759 },
    { lat: 48.2774923, lng: 19.0565244 },
    { lat: 48.2772613, lng: 19.056192 },
    { lat: 48.2770768, lng: 19.0560671 },
    { lat: 48.2768374, lng: 19.0560135 },
    { lat: 48.2765334, lng: 19.0557991 },
    { lat: 48.2764931, lng: 19.0556048 },
    { lat: 48.2764987, lng: 19.055247 },
    { lat: 48.276549, lng: 19.0551245 },
    { lat: 48.2765516, lng: 19.0547968 },
    { lat: 48.2762476, lng: 19.0544242 },
    { lat: 48.2759468, lng: 19.0544512 },
    { lat: 48.2756199, lng: 19.054341 },
    { lat: 48.2754644, lng: 19.0540738 },
    { lat: 48.2752167, lng: 19.052719 },
    { lat: 48.2744898, lng: 19.0518536 },
    { lat: 48.2743299, lng: 19.0514784 },
    { lat: 48.2742929, lng: 19.0511688 },
    { lat: 48.2742277, lng: 19.0499254 },
    { lat: 48.2741681, lng: 19.049951 },
    { lat: 48.2737023, lng: 19.0500792 },
    { lat: 48.2737051, lng: 19.0511172 },
    { lat: 48.2734856, lng: 19.0515951 },
    { lat: 48.2733378, lng: 19.0520822 },
    { lat: 48.2734113, lng: 19.0520873 },
    { lat: 48.2733935, lng: 19.0524518 },
    { lat: 48.2733107, lng: 19.0527064 },
    { lat: 48.2731622, lng: 19.0528245 },
    { lat: 48.2729534, lng: 19.0535142 },
    { lat: 48.2729461, lng: 19.053973 },
    { lat: 48.272721, lng: 19.0542922 },
    { lat: 48.2727238, lng: 19.0546808 },
    { lat: 48.272984, lng: 19.0551461 },
    { lat: 48.2730478, lng: 19.0556517 },
    { lat: 48.2729166, lng: 19.0559769 },
    { lat: 48.272846, lng: 19.0563438 },
    { lat: 48.2729807, lng: 19.056555 },
    { lat: 48.2730157, lng: 19.0567811 },
    { lat: 48.2729245, lng: 19.0570042 },
    { lat: 48.2729118, lng: 19.0572027 },
    { lat: 48.2729824, lng: 19.0574403 },
    { lat: 48.2732357, lng: 19.0577461 },
    { lat: 48.2733866, lng: 19.0577856 },
    { lat: 48.2734389, lng: 19.0580168 },
    { lat: 48.2734344, lng: 19.0586866 },
    { lat: 48.2735296, lng: 19.0591221 },
    { lat: 48.2738469, lng: 19.059422 },
    { lat: 48.2739827, lng: 19.0594789 },
    { lat: 48.2740557, lng: 19.0596511 },
    { lat: 48.274163, lng: 19.0602806 },
    { lat: 48.2741522, lng: 19.0608481 },
    { lat: 48.2742387, lng: 19.0612481 },
    { lat: 48.2747858, lng: 19.0615178 },
    { lat: 48.275283, lng: 19.0620537 },
    { lat: 48.2751372, lng: 19.0629677 },
    { lat: 48.2752362, lng: 19.0631179 },
    { lat: 48.2756458, lng: 19.0631912 },
    { lat: 48.2756388, lng: 19.0640142 },
    { lat: 48.2756973, lng: 19.0643027 },
    { lat: 48.2756842, lng: 19.0647367 },
    { lat: 48.275768, lng: 19.065462 },
    { lat: 48.2758134, lng: 19.0655577 },
    { lat: 48.2759696, lng: 19.0656363 },
    { lat: 48.276029, lng: 19.0657857 },
    { lat: 48.2757048, lng: 19.0669609 },
    { lat: 48.2757034, lng: 19.0672003 },
    { lat: 48.2755167, lng: 19.0676582 },
    { lat: 48.2751232, lng: 19.0676803 },
    { lat: 48.2749732, lng: 19.0677883 },
    { lat: 48.2748717, lng: 19.0680149 },
    { lat: 48.2746842, lng: 19.0680736 },
    { lat: 48.2745042, lng: 19.068462 },
    { lat: 48.2743025, lng: 19.0686653 },
    { lat: 48.2742125, lng: 19.0690039 },
    { lat: 48.2742699, lng: 19.0694069 },
    { lat: 48.274231, lng: 19.0696435 },
    { lat: 48.2740122, lng: 19.069929 },
    { lat: 48.2739628, lng: 19.0702241 },
    { lat: 48.2737938, lng: 19.070566 },
    { lat: 48.2735609, lng: 19.0715142 },
    { lat: 48.2734708, lng: 19.0716536 },
    { lat: 48.2734731, lng: 19.0724592 },
    { lat: 48.2738032, lng: 19.0741125 },
    { lat: 48.2737775, lng: 19.0748805 },
    { lat: 48.2737365, lng: 19.075071 },
    { lat: 48.273762, lng: 19.075422 },
    { lat: 48.2735845, lng: 19.0761955 },
    { lat: 48.2734801, lng: 19.0763188 },
    { lat: 48.2733635, lng: 19.0768943 },
    { lat: 48.2731028, lng: 19.0775352 },
    { lat: 48.2726269, lng: 19.0784974 },
    { lat: 48.2721927, lng: 19.0799067 },
    { lat: 48.2718855, lng: 19.0796519 },
    { lat: 48.2717831, lng: 19.081136 },
    { lat: 48.2717196, lng: 19.0836862 },
    { lat: 48.2715004, lng: 19.0864613 },
    { lat: 48.2715562, lng: 19.0865856 },
    { lat: 48.2716737, lng: 19.0865441 },
    { lat: 48.2718918, lng: 19.0866027 },
    { lat: 48.2721005, lng: 19.0863785 },
    { lat: 48.272509, lng: 19.086204 },
    { lat: 48.2728425, lng: 19.0862698 },
    { lat: 48.2729636, lng: 19.0867519 },
    { lat: 48.27336, lng: 19.0868315 },
    { lat: 48.2736979, lng: 19.08705 },
    { lat: 48.2737584, lng: 19.087154 },
    { lat: 48.2738016, lng: 19.0879252 },
    { lat: 48.2741111, lng: 19.0886311 },
    { lat: 48.2742147, lng: 19.0887649 },
    { lat: 48.2743632, lng: 19.0888058 },
    { lat: 48.2742376, lng: 19.0890189 },
    { lat: 48.2736402, lng: 19.0895381 },
    { lat: 48.2735457, lng: 19.0897232 },
    { lat: 48.2730516, lng: 19.0901918 },
    { lat: 48.2726639, lng: 19.090733 },
    { lat: 48.272264, lng: 19.0914115 },
    { lat: 48.2713814, lng: 19.0925371 },
    { lat: 48.2712266, lng: 19.0929367 },
    { lat: 48.2710941, lng: 19.0935554 },
    { lat: 48.2712079, lng: 19.0938221 },
    { lat: 48.2716994, lng: 19.0944739 },
    { lat: 48.2723411, lng: 19.0949952 },
    { lat: 48.2728243, lng: 19.0956844 },
    { lat: 48.2729848, lng: 19.0961862 },
    { lat: 48.2730311, lng: 19.096704 },
    { lat: 48.2729927, lng: 19.0972679 },
    { lat: 48.2727881, lng: 19.0977959 },
    { lat: 48.2726751, lng: 19.0982658 },
    { lat: 48.2726497, lng: 19.0987921 },
    { lat: 48.2729524, lng: 19.1000193 },
    { lat: 48.2731313, lng: 19.1009774 },
    { lat: 48.2733352, lng: 19.1016962 },
    { lat: 48.2738047, lng: 19.1025838 },
    { lat: 48.2738186, lng: 19.1030001 },
    { lat: 48.2751441, lng: 19.1059915 },
    { lat: 48.2756164, lng: 19.1069559 },
    { lat: 48.2761003, lng: 19.1071883 },
    { lat: 48.2767398, lng: 19.1078031 },
    { lat: 48.276892, lng: 19.1081388 },
    { lat: 48.2772871, lng: 19.1095261 },
    { lat: 48.2771464, lng: 19.1101379 },
    { lat: 48.2768278, lng: 19.1108004 },
    { lat: 48.2775075, lng: 19.1103961 },
    { lat: 48.2781475, lng: 19.1104343 },
    { lat: 48.2788464, lng: 19.1106097 },
    { lat: 48.2788426, lng: 19.1102424 },
    { lat: 48.2788344, lng: 19.1091752 },
    { lat: 48.2787547, lng: 19.1084586 },
    { lat: 48.2787932, lng: 19.1081937 },
    { lat: 48.278737, lng: 19.1079739 },
    { lat: 48.2788634, lng: 19.1078599 },
    { lat: 48.2789468, lng: 19.1075005 },
    { lat: 48.2788422, lng: 19.1072142 },
    { lat: 48.2788949, lng: 19.1068232 },
    { lat: 48.2793159, lng: 19.10609 },
    { lat: 48.2799688, lng: 19.1058196 },
    { lat: 48.2801172, lng: 19.1054295 },
    { lat: 48.2803081, lng: 19.1052594 },
    { lat: 48.2806053, lng: 19.1053814 },
    { lat: 48.2805518, lng: 19.1051018 },
    { lat: 48.2809189, lng: 19.1048231 },
    { lat: 48.2811942, lng: 19.104446 },
    { lat: 48.2812995, lng: 19.1044313 },
    { lat: 48.2816303, lng: 19.103945 },
    { lat: 48.281363, lng: 19.1025232 },
    { lat: 48.2810796, lng: 19.1015933 },
    { lat: 48.2811077, lng: 19.1010755 },
    { lat: 48.2812372, lng: 19.1002366 },
    { lat: 48.2811138, lng: 19.0998654 },
    { lat: 48.2813636, lng: 19.0992253 },
    { lat: 48.28127, lng: 19.0987775 },
    { lat: 48.2819725, lng: 19.0974505 },
    { lat: 48.2823444, lng: 19.0969078 },
    { lat: 48.282398, lng: 19.0967152 },
    { lat: 48.2829622, lng: 19.0956692 },
    { lat: 48.2834124, lng: 19.0944841 },
    { lat: 48.2836278, lng: 19.0943926 },
    { lat: 48.2839689, lng: 19.0943822 },
    { lat: 48.2844986, lng: 19.0942475 },
    { lat: 48.2850687, lng: 19.0939455 },
    { lat: 48.2855852, lng: 19.0939528 },
    { lat: 48.2855356, lng: 19.0935669 },
    { lat: 48.2860844, lng: 19.0919682 },
    { lat: 48.2865911, lng: 19.0923359 },
    { lat: 48.2880494, lng: 19.0931116 },
    { lat: 48.2899861, lng: 19.0942573 },
    { lat: 48.2915835, lng: 19.0939858 },
    { lat: 48.2917946, lng: 19.0929329 },
    { lat: 48.2923085, lng: 19.0934911 },
    { lat: 48.2925292, lng: 19.0936889 },
    { lat: 48.292641, lng: 19.0934465 },
    { lat: 48.2938225, lng: 19.0939604 },
    { lat: 48.2958695, lng: 19.0943368 },
    { lat: 48.2965196, lng: 19.0937882 },
    { lat: 48.2967825, lng: 19.0932307 },
    { lat: 48.2972578, lng: 19.092816 },
    { lat: 48.2974281, lng: 19.0918076 },
    { lat: 48.2971875, lng: 19.0913 },
    { lat: 48.2969061, lng: 19.0910177 },
    { lat: 48.2964334, lng: 19.0898207 },
    { lat: 48.2963531, lng: 19.0897922 },
    { lat: 48.2962372, lng: 19.0895263 },
    { lat: 48.2962423, lng: 19.0891564 },
    { lat: 48.2963428, lng: 19.0885322 },
    { lat: 48.296315, lng: 19.0882691 },
    { lat: 48.2962537, lng: 19.0881932 },
    { lat: 48.2960986, lng: 19.0884256 },
    { lat: 48.2960399, lng: 19.0884237 },
    { lat: 48.2959795, lng: 19.0882549 },
    { lat: 48.2959767, lng: 19.0879507 },
    { lat: 48.2959162, lng: 19.0877554 },
    { lat: 48.295943, lng: 19.0875941 },
    { lat: 48.295726, lng: 19.0871813 },
    { lat: 48.2956286, lng: 19.0871476 },
    { lat: 48.2955602, lng: 19.0869981 },
    { lat: 48.295421, lng: 19.0868747 },
    { lat: 48.2951838, lng: 19.0864415 },
    { lat: 48.2950449, lng: 19.0864139 },
    { lat: 48.2947971, lng: 19.0857409 },
    { lat: 48.2946653, lng: 19.0856095 },
    { lat: 48.2944531, lng: 19.0855853 },
    { lat: 48.2941373, lng: 19.0853316 },
    { lat: 48.2940622, lng: 19.0851868 },
    { lat: 48.2927387, lng: 19.0852326 },
    { lat: 48.2918963, lng: 19.0848439 },
    { lat: 48.291738, lng: 19.084409 },
    { lat: 48.2917481, lng: 19.0843423 },
    { lat: 48.2916192, lng: 19.0843116 },
    { lat: 48.291513, lng: 19.0840605 },
    { lat: 48.2913189, lng: 19.0839684 },
    { lat: 48.2911624, lng: 19.0837917 },
    { lat: 48.2906779, lng: 19.0827853 },
    { lat: 48.2904947, lng: 19.0822137 },
    { lat: 48.2902997, lng: 19.0822583 },
    { lat: 48.2899839, lng: 19.0818978 },
    { lat: 48.2899676, lng: 19.0817049 },
    { lat: 48.2901938, lng: 19.081542 },
    { lat: 48.2902277, lng: 19.0812954 },
    { lat: 48.2900851, lng: 19.0810629 },
    { lat: 48.2899492, lng: 19.0809395 },
    { lat: 48.2898553, lng: 19.0809324 },
    { lat: 48.2896026, lng: 19.0810844 },
    { lat: 48.2895049, lng: 19.0808239 },
    { lat: 48.2892466, lng: 19.0805054 },
    { lat: 48.289008, lng: 19.0803327 },
    { lat: 48.2889908, lng: 19.080193 },
    { lat: 48.2891192, lng: 19.0799686 },
    { lat: 48.2894826, lng: 19.0795467 },
    { lat: 48.2894229, lng: 19.0787632 },
    { lat: 48.289644, lng: 19.0782961 },
    { lat: 48.2897057, lng: 19.0780205 },
    { lat: 48.2896725, lng: 19.0777863 },
    { lat: 48.2894314, lng: 19.077474 },
    { lat: 48.2893024, lng: 19.0771979 },
    { lat: 48.2892916, lng: 19.0766911 },
    { lat: 48.2891327, lng: 19.0763547 },
    { lat: 48.2890325, lng: 19.0762453 },
    { lat: 48.2889137, lng: 19.0762605 },
    { lat: 48.2886926, lng: 19.0758528 },
    { lat: 48.2883179, lng: 19.0757769 },
    { lat: 48.288146, lng: 19.0751857 },
    { lat: 48.2880315, lng: 19.0751738 },
    { lat: 48.2876088, lng: 19.0747688 },
    { lat: 48.2875621, lng: 19.0746239 },
    { lat: 48.2873293, lng: 19.0744109 },
    { lat: 48.2872615, lng: 19.0742087 },
    { lat: 48.2872868, lng: 19.0740592 },
    { lat: 48.2873752, lng: 19.074027 },
    { lat: 48.2875639, lng: 19.0741911 },
    { lat: 48.2877114, lng: 19.0740991 },
    { lat: 48.2877335, lng: 19.0739327 },
    { lat: 48.2874944, lng: 19.0731901 },
    { lat: 48.2873294, lng: 19.0728441 },
    { lat: 48.2873269, lng: 19.0724794 },
  ],
  Cerovo: [
    { lat: 48.2673102, lng: 19.1268073 },
    { lat: 48.267103, lng: 19.127064 },
    { lat: 48.2670517, lng: 19.1270722 },
    { lat: 48.2667038, lng: 19.1267661 },
    { lat: 48.2665088, lng: 19.1264507 },
    { lat: 48.2662657, lng: 19.1259588 },
    { lat: 48.2660017, lng: 19.1252304 },
    { lat: 48.2657787, lng: 19.1249889 },
    { lat: 48.2654778, lng: 19.1248114 },
    { lat: 48.2652924, lng: 19.1247971 },
    { lat: 48.2647796, lng: 19.1249178 },
    { lat: 48.2644552, lng: 19.1246738 },
    { lat: 48.2640364, lng: 19.1248184 },
    { lat: 48.2635128, lng: 19.124447 },
    { lat: 48.2630427, lng: 19.1242668 },
    { lat: 48.2624438, lng: 19.1239245 },
    { lat: 48.2614998, lng: 19.1239155 },
    { lat: 48.2607432, lng: 19.1240921 },
    { lat: 48.26039, lng: 19.124334 },
    { lat: 48.2601542, lng: 19.1243519 },
    { lat: 48.2597599, lng: 19.1243366 },
    { lat: 48.2591879, lng: 19.1239664 },
    { lat: 48.2588463, lng: 19.1241037 },
    { lat: 48.2586073, lng: 19.1245076 },
    { lat: 48.2584606, lng: 19.124484 },
    { lat: 48.2582204, lng: 19.1244129 },
    { lat: 48.2577998, lng: 19.1240956 },
    { lat: 48.2576515, lng: 19.1239024 },
    { lat: 48.2575343, lng: 19.1236073 },
    { lat: 48.2571344, lng: 19.1230507 },
    { lat: 48.2569425, lng: 19.1232275 },
    { lat: 48.2567597, lng: 19.1236317 },
    { lat: 48.2565745, lng: 19.1238743 },
    { lat: 48.2566329, lng: 19.1244989 },
    { lat: 48.2565294, lng: 19.1251614 },
    { lat: 48.2565882, lng: 19.1255266 },
    { lat: 48.2560684, lng: 19.1256392 },
    { lat: 48.2558202, lng: 19.1257915 },
    { lat: 48.2556743, lng: 19.1257838 },
    { lat: 48.2554665, lng: 19.1258909 },
    { lat: 48.2549459, lng: 19.1262558 },
    { lat: 48.2546424, lng: 19.1266552 },
    { lat: 48.254331, lng: 19.1266737 },
    { lat: 48.2530007, lng: 19.1277818 },
    { lat: 48.2528733, lng: 19.1278272 },
    { lat: 48.2515951, lng: 19.1305024 },
    { lat: 48.250941, lng: 19.1332104 },
    { lat: 48.2505552, lng: 19.1344958 },
    { lat: 48.2506121, lng: 19.13532 },
    { lat: 48.2504946, lng: 19.1359496 },
    { lat: 48.2504729, lng: 19.1360369 },
    { lat: 48.2502234, lng: 19.1361875 },
    { lat: 48.2499318, lng: 19.136039 },
    { lat: 48.2497621, lng: 19.1358237 },
    { lat: 48.2496941, lng: 19.1353407 },
    { lat: 48.249467, lng: 19.1349208 },
    { lat: 48.2492698, lng: 19.1347843 },
    { lat: 48.2489598, lng: 19.134878 },
    { lat: 48.248699, lng: 19.1347784 },
    { lat: 48.2486231, lng: 19.1346351 },
    { lat: 48.2480879, lng: 19.1341668 },
    { lat: 48.2479969, lng: 19.1340139 },
    { lat: 48.2478425, lng: 19.1339459 },
    { lat: 48.2476461, lng: 19.1341899 },
    { lat: 48.2475304, lng: 19.1341109 },
    { lat: 48.2474395, lng: 19.1338709 },
    { lat: 48.2472273, lng: 19.1337086 },
    { lat: 48.2471261, lng: 19.1334836 },
    { lat: 48.2469904, lng: 19.1334294 },
    { lat: 48.2467188, lng: 19.1334548 },
    { lat: 48.2465938, lng: 19.13323 },
    { lat: 48.2465665, lng: 19.1329579 },
    { lat: 48.2463271, lng: 19.1328559 },
    { lat: 48.245985, lng: 19.1329369 },
    { lat: 48.2458788, lng: 19.1328614 },
    { lat: 48.2457376, lng: 19.1325652 },
    { lat: 48.2454286, lng: 19.1323606 },
    { lat: 48.2452769, lng: 19.1324366 },
    { lat: 48.2451561, lng: 19.1320922 },
    { lat: 48.245059, lng: 19.1319789 },
    { lat: 48.2448454, lng: 19.1321244 },
    { lat: 48.2447302, lng: 19.1320747 },
    { lat: 48.2445898, lng: 19.1317232 },
    { lat: 48.2444692, lng: 19.1316589 },
    { lat: 48.2442688, lng: 19.1318228 },
    { lat: 48.2441574, lng: 19.1317595 },
    { lat: 48.2441146, lng: 19.1316009 },
    { lat: 48.244147, lng: 19.1313702 },
    { lat: 48.2440261, lng: 19.1313611 },
    { lat: 48.2437945, lng: 19.1315355 },
    { lat: 48.2435124, lng: 19.1314584 },
    { lat: 48.2433385, lng: 19.1315005 },
    { lat: 48.2432295, lng: 19.1315972 },
    { lat: 48.2428524, lng: 19.1313599 },
    { lat: 48.2427173, lng: 19.1314984 },
    { lat: 48.2425795, lng: 19.1313593 },
    { lat: 48.2424236, lng: 19.1308759 },
    { lat: 48.2421468, lng: 19.1308205 },
    { lat: 48.2419511, lng: 19.1306716 },
    { lat: 48.2417677, lng: 19.1304275 },
    { lat: 48.2417019, lng: 19.1302601 },
    { lat: 48.2416819, lng: 19.1299296 },
    { lat: 48.2415622, lng: 19.1298779 },
    { lat: 48.241293, lng: 19.1295009 },
    { lat: 48.2411652, lng: 19.1294688 },
    { lat: 48.2410736, lng: 19.1291477 },
    { lat: 48.2408778, lng: 19.1290861 },
    { lat: 48.2408033, lng: 19.128791 },
    { lat: 48.2406015, lng: 19.1287007 },
    { lat: 48.2401342, lng: 19.1282822 },
    { lat: 48.2390161, lng: 19.12772 },
    { lat: 48.2388756, lng: 19.1275497 },
    { lat: 48.2386946, lng: 19.1275113 },
    { lat: 48.2384877, lng: 19.1275701 },
    { lat: 48.238354, lng: 19.1274882 },
    { lat: 48.2379531, lng: 19.1269296 },
    { lat: 48.2377767, lng: 19.1269211 },
    { lat: 48.2376206, lng: 19.1270457 },
    { lat: 48.2374883, lng: 19.1269933 },
    { lat: 48.2372196, lng: 19.1265328 },
    { lat: 48.2370343, lng: 19.126323 },
    { lat: 48.2368111, lng: 19.1264137 },
    { lat: 48.2365092, lng: 19.1262809 },
    { lat: 48.2362354, lng: 19.1263055 },
    { lat: 48.2359357, lng: 19.12606 },
    { lat: 48.2358619, lng: 19.1258263 },
    { lat: 48.2358168, lng: 19.125395 },
    { lat: 48.2355091, lng: 19.1248247 },
    { lat: 48.2354974, lng: 19.1247043 },
    { lat: 48.2356032, lng: 19.1245822 },
    { lat: 48.235633, lng: 19.1244007 },
    { lat: 48.2354409, lng: 19.1241633 },
    { lat: 48.2356283, lng: 19.1240142 },
    { lat: 48.2355591, lng: 19.1230582 },
    { lat: 48.234889, lng: 19.1237031 },
    { lat: 48.2334332, lng: 19.1235844 },
    { lat: 48.2313471, lng: 19.122602 },
    { lat: 48.2300557, lng: 19.1223621 },
    { lat: 48.2297297, lng: 19.1227231 },
    { lat: 48.2288825, lng: 19.1238631 },
    { lat: 48.2279169, lng: 19.1258145 },
    { lat: 48.227862, lng: 19.125776 },
    { lat: 48.227826, lng: 19.125774 },
    { lat: 48.227814, lng: 19.126237 },
    { lat: 48.227806, lng: 19.126516 },
    { lat: 48.227837, lng: 19.126798 },
    { lat: 48.227952, lng: 19.1272 },
    { lat: 48.228074, lng: 19.12744 },
    { lat: 48.228372, lng: 19.12783 },
    { lat: 48.228871, lng: 19.128359 },
    { lat: 48.229441, lng: 19.12886 },
    { lat: 48.229653, lng: 19.128999 },
    { lat: 48.230022, lng: 19.129178 },
    { lat: 48.230424, lng: 19.129263 },
    { lat: 48.231341, lng: 19.129452 },
    { lat: 48.232031, lng: 19.129878 },
    { lat: 48.232299, lng: 19.130203 },
    { lat: 48.232738, lng: 19.131298 },
    { lat: 48.232775, lng: 19.131821 },
    { lat: 48.232737, lng: 19.132209 },
    { lat: 48.232684, lng: 19.132749 },
    { lat: 48.23261, lng: 19.1335 },
    { lat: 48.232596, lng: 19.133565 },
    { lat: 48.232526, lng: 19.133882 },
    { lat: 48.232466, lng: 19.134159 },
    { lat: 48.232455, lng: 19.134208 },
    { lat: 48.23241, lng: 19.134405 },
    { lat: 48.23241, lng: 19.134407 },
    { lat: 48.232378, lng: 19.13455 },
    { lat: 48.232349, lng: 19.134674 },
    { lat: 48.232312, lng: 19.134836 },
    { lat: 48.232292, lng: 19.134924 },
    { lat: 48.232288, lng: 19.134939 },
    { lat: 48.232273, lng: 19.135004 },
    { lat: 48.232238, lng: 19.135153 },
    { lat: 48.232232, lng: 19.135287 },
    { lat: 48.231754, lng: 19.135781 },
    { lat: 48.231419, lng: 19.135826 },
    { lat: 48.23129, lng: 19.135817 },
    { lat: 48.23127, lng: 19.135816 },
    { lat: 48.230607, lng: 19.135757 },
    { lat: 48.228832, lng: 19.135541 },
    { lat: 48.228763, lng: 19.135453 },
    { lat: 48.228589, lng: 19.135613 },
    { lat: 48.228466, lng: 19.135696 },
    { lat: 48.228341, lng: 19.135935 },
    { lat: 48.228154, lng: 19.136288 },
    { lat: 48.2281603, lng: 19.1363382 },
    { lat: 48.228174, lng: 19.136447 },
    { lat: 48.228011, lng: 19.1371 },
    { lat: 48.228063, lng: 19.137323 },
    { lat: 48.227804, lng: 19.137787 },
    { lat: 48.227694, lng: 19.137982 },
    { lat: 48.227489, lng: 19.138365 },
    { lat: 48.227355, lng: 19.138239 },
    { lat: 48.227113, lng: 19.138573 },
    { lat: 48.226713, lng: 19.139127 },
    { lat: 48.226643, lng: 19.139572 },
    { lat: 48.226698, lng: 19.139969 },
    { lat: 48.226738, lng: 19.140235 },
    { lat: 48.226705, lng: 19.140349 },
    { lat: 48.226497, lng: 19.141095 },
    { lat: 48.226436, lng: 19.141359 },
    { lat: 48.22627, lng: 19.142083 },
    { lat: 48.226258, lng: 19.142161 },
    { lat: 48.22623, lng: 19.142349 },
    { lat: 48.226157, lng: 19.142362 },
    { lat: 48.226244, lng: 19.143141 },
    { lat: 48.226341, lng: 19.143849 },
    { lat: 48.226429, lng: 19.144187 },
    { lat: 48.226558, lng: 19.144798 },
    { lat: 48.226703, lng: 19.14512 },
    { lat: 48.2269, lng: 19.145395 },
    { lat: 48.227048, lng: 19.145674 },
    { lat: 48.227123, lng: 19.145964 },
    { lat: 48.227126, lng: 19.146197 },
    { lat: 48.226998, lng: 19.146166 },
    { lat: 48.226956, lng: 19.14729 },
    { lat: 48.226544, lng: 19.148253 },
    { lat: 48.226698, lng: 19.14935 },
    { lat: 48.226365, lng: 19.149983 },
    { lat: 48.226026, lng: 19.150605 },
    { lat: 48.225987, lng: 19.150681 },
    { lat: 48.225988, lng: 19.150731 },
    { lat: 48.225789, lng: 19.150715 },
    { lat: 48.22555, lng: 19.150624 },
    { lat: 48.225418, lng: 19.150684 },
    { lat: 48.225246, lng: 19.150699 },
    { lat: 48.22484, lng: 19.150601 },
    { lat: 48.224501, lng: 19.150494 },
    { lat: 48.22409, lng: 19.150651 },
    { lat: 48.223851, lng: 19.150736 },
    { lat: 48.223661, lng: 19.150764 },
    { lat: 48.223162, lng: 19.150727 },
    { lat: 48.222667, lng: 19.150693 },
    { lat: 48.222378, lng: 19.150646 },
    { lat: 48.222243, lng: 19.150725 },
    { lat: 48.222124, lng: 19.151011 },
    { lat: 48.222015, lng: 19.151272 },
    { lat: 48.221855, lng: 19.15142 },
    { lat: 48.221507, lng: 19.151497 },
    { lat: 48.221279, lng: 19.151669 },
    { lat: 48.221049, lng: 19.151765 },
    { lat: 48.22084, lng: 19.151788 },
    { lat: 48.220662, lng: 19.152047 },
    { lat: 48.220495, lng: 19.152393 },
    { lat: 48.220417, lng: 19.152457 },
    { lat: 48.220127, lng: 19.152692 },
    { lat: 48.2200677, lng: 19.152789 },
    { lat: 48.21993, lng: 19.153014 },
    { lat: 48.219843, lng: 19.15323 },
    { lat: 48.21981, lng: 19.153468 },
    { lat: 48.219872, lng: 19.153816 },
    { lat: 48.219871, lng: 19.154181 },
    { lat: 48.21983, lng: 19.154538 },
    { lat: 48.219832, lng: 19.155835 },
    { lat: 48.21974, lng: 19.156106 },
    { lat: 48.219323, lng: 19.156752 },
    { lat: 48.219175, lng: 19.157157 },
    { lat: 48.219044, lng: 19.157628 },
    { lat: 48.218917, lng: 19.157987 },
    { lat: 48.218713, lng: 19.158436 },
    { lat: 48.218588, lng: 19.158619 },
    { lat: 48.218331, lng: 19.158889 },
    { lat: 48.217946, lng: 19.15952 },
    { lat: 48.217809, lng: 19.159694 },
    { lat: 48.217757, lng: 19.159925 },
    { lat: 48.217728, lng: 19.160283 },
    { lat: 48.217975, lng: 19.160993 },
    { lat: 48.218127, lng: 19.161335 },
    { lat: 48.21835, lng: 19.161735 },
    { lat: 48.218581, lng: 19.162158 },
    { lat: 48.218619, lng: 19.162106 },
    { lat: 48.218679, lng: 19.162019 },
    { lat: 48.218882, lng: 19.162218 },
    { lat: 48.219102, lng: 19.162517 },
    { lat: 48.219243, lng: 19.162765 },
    { lat: 48.219403, lng: 19.16299 },
    { lat: 48.219775, lng: 19.163651 },
    { lat: 48.219757, lng: 19.163749 },
    { lat: 48.220165, lng: 19.163953 },
    { lat: 48.220391, lng: 19.164066 },
    { lat: 48.220604, lng: 19.164249 },
    { lat: 48.220963, lng: 19.164361 },
    { lat: 48.220925, lng: 19.164881 },
    { lat: 48.220918, lng: 19.165252 },
    { lat: 48.220973, lng: 19.165508 },
    { lat: 48.221037, lng: 19.165681 },
    { lat: 48.221163, lng: 19.165786 },
    { lat: 48.221395, lng: 19.165957 },
    { lat: 48.221647, lng: 19.166123 },
    { lat: 48.222101, lng: 19.166514 },
    { lat: 48.22219, lng: 19.166751 },
    { lat: 48.222311, lng: 19.166942 },
    { lat: 48.222449, lng: 19.167107 },
    { lat: 48.22265, lng: 19.167261 },
    { lat: 48.222906, lng: 19.167431 },
    { lat: 48.223177, lng: 19.167573 },
    { lat: 48.223408, lng: 19.167576 },
    { lat: 48.223858, lng: 19.167284 },
    { lat: 48.224186, lng: 19.167191 },
    { lat: 48.224331, lng: 19.167278 },
    { lat: 48.224652, lng: 19.167511 },
    { lat: 48.224851, lng: 19.167571 },
    { lat: 48.225092, lng: 19.167663 },
    { lat: 48.22526, lng: 19.167621 },
    { lat: 48.225784, lng: 19.167421 },
    { lat: 48.225992, lng: 19.167312 },
    { lat: 48.226251, lng: 19.167268 },
    { lat: 48.22646, lng: 19.167402 },
    { lat: 48.226665, lng: 19.167641 },
    { lat: 48.226867, lng: 19.167941 },
    { lat: 48.227363, lng: 19.168276 },
    { lat: 48.227519, lng: 19.168294 },
    { lat: 48.227766, lng: 19.167984 },
    { lat: 48.227987, lng: 19.167885 },
    { lat: 48.2281, lng: 19.167937 },
    { lat: 48.228157, lng: 19.168047 },
    { lat: 48.228233, lng: 19.168481 },
    { lat: 48.228383, lng: 19.168599 },
    { lat: 48.228521, lng: 19.168462 },
    { lat: 48.228674, lng: 19.168422 },
    { lat: 48.228867, lng: 19.168269 },
    { lat: 48.22907, lng: 19.167934 },
    { lat: 48.229237, lng: 19.167696 },
    { lat: 48.229432, lng: 19.167523 },
    { lat: 48.229728, lng: 19.167587 },
    { lat: 48.230008, lng: 19.167668 },
    { lat: 48.230264, lng: 19.167719 },
    { lat: 48.230718, lng: 19.167827 },
    { lat: 48.230863, lng: 19.167922 },
    { lat: 48.231386, lng: 19.168282 },
    { lat: 48.231784, lng: 19.168706 },
    { lat: 48.232039, lng: 19.169009 },
    { lat: 48.232109, lng: 19.16929 },
    { lat: 48.232156, lng: 19.169483 },
    { lat: 48.232289, lng: 19.169795 },
    { lat: 48.232599, lng: 19.169965 },
    { lat: 48.232735, lng: 19.170269 },
    { lat: 48.23287, lng: 19.170392 },
    { lat: 48.233183, lng: 19.170452 },
    { lat: 48.233525, lng: 19.170497 },
    { lat: 48.23383, lng: 19.170547 },
    { lat: 48.234049, lng: 19.170543 },
    { lat: 48.234289, lng: 19.170553 },
    { lat: 48.234571, lng: 19.170511 },
    { lat: 48.23472, lng: 19.170525 },
    { lat: 48.234848, lng: 19.1704401 },
    { lat: 48.234904, lng: 19.170403 },
    { lat: 48.235081, lng: 19.170197 },
    { lat: 48.235325, lng: 19.169856 },
    { lat: 48.235647, lng: 19.169285 },
    { lat: 48.235895, lng: 19.169237 },
    { lat: 48.236052, lng: 19.169395 },
    { lat: 48.23615, lng: 19.16959 },
    { lat: 48.236233, lng: 19.169919 },
    { lat: 48.236447, lng: 19.170253 },
    { lat: 48.236636, lng: 19.17044 },
    { lat: 48.236939, lng: 19.170616 },
    { lat: 48.237298, lng: 19.170693 },
    { lat: 48.237541, lng: 19.170853 },
    { lat: 48.237793, lng: 19.17109 },
    { lat: 48.238052, lng: 19.171309 },
    { lat: 48.238208, lng: 19.171853 },
    { lat: 48.238276, lng: 19.172261 },
    { lat: 48.238425, lng: 19.172864 },
    { lat: 48.238563, lng: 19.173009 },
    { lat: 48.239012, lng: 19.173029 },
    { lat: 48.23918, lng: 19.173073 },
    { lat: 48.239312, lng: 19.173183 },
    { lat: 48.239363, lng: 19.173391 },
    { lat: 48.239446, lng: 19.173733 },
    { lat: 48.239507, lng: 19.174079 },
    { lat: 48.239691, lng: 19.174236 },
    { lat: 48.240005, lng: 19.174399 },
    { lat: 48.240244, lng: 19.174547 },
    { lat: 48.240363, lng: 19.174716 },
    { lat: 48.240421, lng: 19.175528 },
    { lat: 48.240464, lng: 19.175875 },
    { lat: 48.240546, lng: 19.17614 },
    { lat: 48.240427, lng: 19.176399 },
    { lat: 48.240472, lng: 19.176547 },
    { lat: 48.24046, lng: 19.176714 },
    { lat: 48.240397, lng: 19.176848 },
    { lat: 48.240065, lng: 19.177274 },
    { lat: 48.239948, lng: 19.177537 },
    { lat: 48.239778, lng: 19.177676 },
    { lat: 48.239588, lng: 19.177968 },
    { lat: 48.239314, lng: 19.17825 },
    { lat: 48.239237, lng: 19.178396 },
    { lat: 48.239171, lng: 19.178684 },
    { lat: 48.239031, lng: 19.179299 },
    { lat: 48.238968, lng: 19.179767 },
    { lat: 48.238749, lng: 19.180315 },
    { lat: 48.238623, lng: 19.180493 },
    { lat: 48.238501, lng: 19.180548 },
    { lat: 48.238033, lng: 19.180694 },
    { lat: 48.237722, lng: 19.180769 },
    { lat: 48.237607, lng: 19.180836 },
    { lat: 48.237498, lng: 19.180999 },
    { lat: 48.237312, lng: 19.181104 },
    { lat: 48.2372217, lng: 19.1811877 },
    { lat: 48.237071, lng: 19.181587 },
    { lat: 48.236905, lng: 19.18164 },
    { lat: 48.236618, lng: 19.181804 },
    { lat: 48.236297, lng: 19.181934 },
    { lat: 48.236061, lng: 19.182071 },
    { lat: 48.2357, lng: 19.182098 },
    { lat: 48.235513, lng: 19.182011 },
    { lat: 48.235182, lng: 19.181961 },
    { lat: 48.235, lng: 19.181917 },
    { lat: 48.234762, lng: 19.182336 },
    { lat: 48.234546, lng: 19.182624 },
    { lat: 48.234378, lng: 19.182712 },
    { lat: 48.234156, lng: 19.182735 },
    { lat: 48.233994, lng: 19.182856 },
    { lat: 48.233902, lng: 19.183026 },
    { lat: 48.233564, lng: 19.183102 },
    { lat: 48.233389, lng: 19.183092 },
    { lat: 48.233181, lng: 19.182962 },
    { lat: 48.233, lng: 19.183001 },
    { lat: 48.232767, lng: 19.183023 },
    { lat: 48.232528, lng: 19.183253 },
    { lat: 48.232287, lng: 19.183426 },
    { lat: 48.23213, lng: 19.183641 },
    { lat: 48.232089, lng: 19.183885 },
    { lat: 48.232041, lng: 19.184371 },
    { lat: 48.232001, lng: 19.184775 },
    { lat: 48.23193, lng: 19.185073 },
    { lat: 48.231819, lng: 19.185446 },
    { lat: 48.231775, lng: 19.185591 },
    { lat: 48.231754, lng: 19.185663 },
    { lat: 48.231667, lng: 19.185863 },
    { lat: 48.23159, lng: 19.186095 },
    { lat: 48.231501, lng: 19.186262 },
    { lat: 48.231397, lng: 19.186287 },
    { lat: 48.23137, lng: 19.186224 },
    { lat: 48.231326, lng: 19.186259 },
    { lat: 48.231271, lng: 19.186475 },
    { lat: 48.231386, lng: 19.186866 },
    { lat: 48.231375, lng: 19.187012 },
    { lat: 48.231405, lng: 19.187242 },
    { lat: 48.231365, lng: 19.187306 },
    { lat: 48.231595, lng: 19.187748 },
    { lat: 48.23177, lng: 19.187963 },
    { lat: 48.232178, lng: 19.188203 },
    { lat: 48.232286, lng: 19.188241 },
    { lat: 48.232483, lng: 19.188212 },
    { lat: 48.232594, lng: 19.188248 },
    { lat: 48.232772, lng: 19.188242 },
    { lat: 48.233112, lng: 19.188262 },
    { lat: 48.233418, lng: 19.18825 },
    { lat: 48.233507, lng: 19.18842 },
    { lat: 48.233833, lng: 19.18879 },
    { lat: 48.234107, lng: 19.188959 },
    { lat: 48.23433, lng: 19.189055 },
    { lat: 48.234393, lng: 19.189017 },
    { lat: 48.23454, lng: 19.188573 },
    { lat: 48.234654, lng: 19.188487 },
    { lat: 48.234772, lng: 19.188545 },
    { lat: 48.234838, lng: 19.188609 },
    { lat: 48.234972, lng: 19.188873 },
    { lat: 48.235106, lng: 19.189083 },
    { lat: 48.235402, lng: 19.189479 },
    { lat: 48.235792, lng: 19.189875 },
    { lat: 48.235985, lng: 19.190074 },
    { lat: 48.236087, lng: 19.19002 },
    { lat: 48.236149, lng: 19.18977 },
    { lat: 48.236247, lng: 19.189724 },
    { lat: 48.236545, lng: 19.189772 },
    { lat: 48.236639, lng: 19.189849 },
    { lat: 48.236639, lng: 19.190058 },
    { lat: 48.236787, lng: 19.190295 },
    { lat: 48.236868, lng: 19.190353 },
    { lat: 48.23696, lng: 19.190541 },
    { lat: 48.237079, lng: 19.190622 },
    { lat: 48.237443, lng: 19.190619 },
    { lat: 48.237607, lng: 19.190549 },
    { lat: 48.237686, lng: 19.190649 },
    { lat: 48.237895, lng: 19.190666 },
    { lat: 48.238137, lng: 19.190488 },
    { lat: 48.238304, lng: 19.190393 },
    { lat: 48.238415, lng: 19.190393 },
    { lat: 48.238482, lng: 19.190687 },
    { lat: 48.238598, lng: 19.191003 },
    { lat: 48.238696, lng: 19.191268 },
    { lat: 48.239001, lng: 19.191348 },
    { lat: 48.239134, lng: 19.191341 },
    { lat: 48.239389, lng: 19.191055 },
    { lat: 48.239577, lng: 19.191081 },
    { lat: 48.239701, lng: 19.191263 },
    { lat: 48.239762, lng: 19.191442 },
    { lat: 48.239767, lng: 19.191692 },
    { lat: 48.239873, lng: 19.192086 },
    { lat: 48.240018, lng: 19.192244 },
    { lat: 48.240042, lng: 19.192497 },
    { lat: 48.240049, lng: 19.192711 },
    { lat: 48.240031, lng: 19.192937 },
    { lat: 48.239918, lng: 19.193085 },
    { lat: 48.239866, lng: 19.193313 },
    { lat: 48.239912, lng: 19.19344 },
    { lat: 48.239975, lng: 19.1935016 },
    { lat: 48.240146, lng: 19.193555 },
    { lat: 48.240279, lng: 19.193607 },
    { lat: 48.240341, lng: 19.193722 },
    { lat: 48.240497, lng: 19.193844 },
    { lat: 48.240558, lng: 19.193985 },
    { lat: 48.240725, lng: 19.193934 },
    { lat: 48.240791, lng: 19.19351 },
    { lat: 48.240869, lng: 19.193441 },
    { lat: 48.240986, lng: 19.19349 },
    { lat: 48.241044, lng: 19.193758 },
    { lat: 48.241197, lng: 19.194043 },
    { lat: 48.241453, lng: 19.194294 },
    { lat: 48.241632, lng: 19.194395 },
    { lat: 48.241839, lng: 19.194368 },
    { lat: 48.24209, lng: 19.194492 },
    { lat: 48.242258, lng: 19.194593 },
    { lat: 48.242473, lng: 19.19455 },
    { lat: 48.242547, lng: 19.19473 },
    { lat: 48.242924, lng: 19.195118 },
    { lat: 48.243004, lng: 19.195184 },
    { lat: 48.243068, lng: 19.195325 },
    { lat: 48.243199, lng: 19.195424 },
    { lat: 48.243271, lng: 19.195557 },
    { lat: 48.243437, lng: 19.195816 },
    { lat: 48.243519, lng: 19.19598 },
    { lat: 48.243618, lng: 19.196288 },
    { lat: 48.243707, lng: 19.196396 },
    { lat: 48.243858, lng: 19.196766 },
    { lat: 48.243945, lng: 19.197118 },
    { lat: 48.244055, lng: 19.197361 },
    { lat: 48.244223, lng: 19.197392 },
    { lat: 48.24428, lng: 19.197471 },
    { lat: 48.244302, lng: 19.19748 },
    { lat: 48.244389, lng: 19.19757 },
    { lat: 48.244418, lng: 19.197711 },
    { lat: 48.244422, lng: 19.197851 },
    { lat: 48.244382, lng: 19.197991 },
    { lat: 48.244303, lng: 19.198143 },
    { lat: 48.244294, lng: 19.198186 },
    { lat: 48.244316, lng: 19.198309 },
    { lat: 48.244422, lng: 19.198383 },
    { lat: 48.244448, lng: 19.198533 },
    { lat: 48.244422, lng: 19.198617 },
    { lat: 48.244472, lng: 19.198887 },
    { lat: 48.244511, lng: 19.198939 },
    { lat: 48.244507, lng: 19.199103 },
    { lat: 48.244482, lng: 19.19934 },
    { lat: 48.244548, lng: 19.199495 },
    { lat: 48.244631, lng: 19.199614 },
    { lat: 48.244803, lng: 19.199779 },
    { lat: 48.244799, lng: 19.199995 },
    { lat: 48.244749, lng: 19.200103 },
    { lat: 48.244699, lng: 19.200151 },
    { lat: 48.244682, lng: 19.200227 },
    { lat: 48.24473, lng: 19.200582 },
    { lat: 48.244818, lng: 19.200671 },
    { lat: 48.244897, lng: 19.200862 },
    { lat: 48.244912, lng: 19.201026 },
    { lat: 48.244869, lng: 19.201232 },
    { lat: 48.24477, lng: 19.201395 },
    { lat: 48.244717, lng: 19.201924 },
    { lat: 48.244771, lng: 19.202222 },
    { lat: 48.244864, lng: 19.202288 },
    { lat: 48.244993, lng: 19.202594 },
    { lat: 48.245034, lng: 19.202802 },
    { lat: 48.245163, lng: 19.203147 },
    { lat: 48.245175, lng: 19.20334 },
    { lat: 48.245137, lng: 19.203467 },
    { lat: 48.245025, lng: 19.203426 },
    { lat: 48.24492, lng: 19.20344 },
    { lat: 48.244805, lng: 19.203613 },
    { lat: 48.245119, lng: 19.204078 },
    { lat: 48.245069, lng: 19.204592 },
    { lat: 48.245132, lng: 19.204774 },
    { lat: 48.245356, lng: 19.205109 },
    { lat: 48.245422, lng: 19.20525 },
    { lat: 48.245592, lng: 19.205216 },
    { lat: 48.245687, lng: 19.205163 },
    { lat: 48.245851, lng: 19.205345 },
    { lat: 48.245887, lng: 19.205559 },
    { lat: 48.24585, lng: 19.20566 },
    { lat: 48.245898, lng: 19.205711 },
    { lat: 48.246006, lng: 19.205929 },
    { lat: 48.246077, lng: 19.206198 },
    { lat: 48.246047, lng: 19.206399 },
    { lat: 48.246057, lng: 19.20651 },
    { lat: 48.246128, lng: 19.206681 },
    { lat: 48.246246, lng: 19.206792 },
    { lat: 48.246258, lng: 19.206888 },
    { lat: 48.24625, lng: 19.207089 },
    { lat: 48.246349, lng: 19.2072 },
    { lat: 48.246589, lng: 19.207285 },
    { lat: 48.246675, lng: 19.207546 },
    { lat: 48.246738, lng: 19.207603 },
    { lat: 48.246797, lng: 19.2076 },
    { lat: 48.246846, lng: 19.207482 },
    { lat: 48.24704, lng: 19.207372 },
    { lat: 48.247249, lng: 19.207455 },
    { lat: 48.247223, lng: 19.207549 },
    { lat: 48.24717, lng: 19.207782 },
    { lat: 48.24722, lng: 19.207897 },
    { lat: 48.247492, lng: 19.207723 },
    { lat: 48.247592, lng: 19.207847 },
    { lat: 48.247646, lng: 19.208022 },
    { lat: 48.247709, lng: 19.208021 },
    { lat: 48.247773, lng: 19.208069 },
    { lat: 48.247803, lng: 19.208226 },
    { lat: 48.247821, lng: 19.208228 },
    { lat: 48.247876, lng: 19.208309 },
    { lat: 48.248049, lng: 19.208548 },
    { lat: 48.248086, lng: 19.208652 },
    { lat: 48.248142, lng: 19.20873 },
    { lat: 48.248121, lng: 19.208851 },
    { lat: 48.248096, lng: 19.208945 },
    { lat: 48.248074, lng: 19.20906 },
    { lat: 48.248271, lng: 19.209428 },
    { lat: 48.248239, lng: 19.209774 },
    { lat: 48.248247, lng: 19.210062 },
    { lat: 48.248204, lng: 19.21012 },
    { lat: 48.248232, lng: 19.210348 },
    { lat: 48.248265, lng: 19.210419 },
    { lat: 48.248263, lng: 19.210622 },
    { lat: 48.248153, lng: 19.210739 },
    { lat: 48.248041, lng: 19.210746 },
    { lat: 48.248023, lng: 19.210814 },
    { lat: 48.248059, lng: 19.210849 },
    { lat: 48.248086, lng: 19.211017 },
    { lat: 48.247978, lng: 19.211296 },
    { lat: 48.247805, lng: 19.211355 },
    { lat: 48.247697, lng: 19.211635 },
    { lat: 48.247608, lng: 19.211664 },
    { lat: 48.247519, lng: 19.211679 },
    { lat: 48.247398, lng: 19.21193 },
    { lat: 48.247313, lng: 19.21193 },
    { lat: 48.247267, lng: 19.211992 },
    { lat: 48.247232, lng: 19.212427 },
    { lat: 48.247163, lng: 19.21253 },
    { lat: 48.247124, lng: 19.212661 },
    { lat: 48.246964, lng: 19.212699 },
    { lat: 48.24691, lng: 19.21304 },
    { lat: 48.246835, lng: 19.213069 },
    { lat: 48.246766, lng: 19.213039 },
    { lat: 48.24675, lng: 19.2131 },
    { lat: 48.246703, lng: 19.21307 },
    { lat: 48.24665, lng: 19.213189 },
    { lat: 48.246608, lng: 19.213208 },
    { lat: 48.246561, lng: 19.213431 },
    { lat: 48.246351, lng: 19.21361 },
    { lat: 48.24623, lng: 19.213585 },
    { lat: 48.24608, lng: 19.213769 },
    { lat: 48.246018, lng: 19.213648 },
    { lat: 48.245906, lng: 19.213701 },
    { lat: 48.2458, lng: 19.213679 },
    { lat: 48.245758, lng: 19.213757 },
    { lat: 48.245763, lng: 19.213896 },
    { lat: 48.245683, lng: 19.213955 },
    { lat: 48.245512, lng: 19.21374 },
    { lat: 48.245356, lng: 19.213827 },
    { lat: 48.245362, lng: 19.214038 },
    { lat: 48.245201, lng: 19.2142 },
    { lat: 48.245224, lng: 19.214221 },
    { lat: 48.245228, lng: 19.214181 },
    { lat: 48.245307, lng: 19.21423 },
    { lat: 48.245736, lng: 19.21498 },
    { lat: 48.245834, lng: 19.215529 },
    { lat: 48.245976, lng: 19.215881 },
    { lat: 48.24644, lng: 19.216274 },
    { lat: 48.246825, lng: 19.21669 },
    { lat: 48.247147, lng: 19.217039 },
    { lat: 48.247223, lng: 19.217121 },
    { lat: 48.247366, lng: 19.217374 },
    { lat: 48.247749, lng: 19.21773 },
    { lat: 48.24808, lng: 19.217813 },
    { lat: 48.248185, lng: 19.217957 },
    { lat: 48.248374, lng: 19.218617 },
    { lat: 48.248446, lng: 19.218763 },
    { lat: 48.249235, lng: 19.219599 },
    { lat: 48.249586, lng: 19.220197 },
    { lat: 48.249575, lng: 19.220214 },
    { lat: 48.249873, lng: 19.220912 },
    { lat: 48.249887, lng: 19.22094 },
    { lat: 48.250028, lng: 19.221352 },
    { lat: 48.250055, lng: 19.22143 },
    { lat: 48.250194, lng: 19.221638 },
    { lat: 48.25039, lng: 19.221811 },
    { lat: 48.25063, lng: 19.22199 },
    { lat: 48.250804, lng: 19.222119 },
    { lat: 48.25104, lng: 19.222223 },
    { lat: 48.251048, lng: 19.222224 },
    { lat: 48.251395, lng: 19.222269 },
    { lat: 48.251404, lng: 19.22227 },
    { lat: 48.251751, lng: 19.22237 },
    { lat: 48.251868, lng: 19.222406 },
    { lat: 48.252517, lng: 19.222608 },
    { lat: 48.252536, lng: 19.222624 },
    { lat: 48.252981, lng: 19.222839 },
    { lat: 48.253454, lng: 19.223068 },
    { lat: 48.253782, lng: 19.223226 },
    { lat: 48.253937, lng: 19.223287 },
    { lat: 48.254344, lng: 19.223335 },
    { lat: 48.254548, lng: 19.223359 },
    { lat: 48.254783, lng: 19.223387 },
    { lat: 48.255197, lng: 19.223436 },
    { lat: 48.255404, lng: 19.22346 },
    { lat: 48.25572, lng: 19.22349 },
    { lat: 48.256229, lng: 19.223539 },
    { lat: 48.256737, lng: 19.223592 },
    { lat: 48.256822, lng: 19.2236 },
    { lat: 48.257209, lng: 19.223635 },
    { lat: 48.257621, lng: 19.223672 },
    { lat: 48.25796, lng: 19.223768 },
    { lat: 48.25835, lng: 19.223877 },
    { lat: 48.25852, lng: 19.223913 },
    { lat: 48.258554, lng: 19.22392 },
    { lat: 48.259137, lng: 19.224041 },
    { lat: 48.2595261, lng: 19.2238827 },
    { lat: 48.2596549, lng: 19.222255 },
    { lat: 48.2598926, lng: 19.2215617 },
    { lat: 48.2599953, lng: 19.2209674 },
    { lat: 48.260187, lng: 19.2204603 },
    { lat: 48.260243, lng: 19.2192135 },
    { lat: 48.2602173, lng: 19.2188725 },
    { lat: 48.2597832, lng: 19.2179527 },
    { lat: 48.2600374, lng: 19.2173421 },
    { lat: 48.2606689, lng: 19.2163139 },
    { lat: 48.2607408, lng: 19.2154103 },
    { lat: 48.2613789, lng: 19.2114192 },
    { lat: 48.2619269, lng: 19.2087154 },
    { lat: 48.2620431, lng: 19.2073827 },
    { lat: 48.2619992, lng: 19.2061723 },
    { lat: 48.2620307, lng: 19.2051606 },
    { lat: 48.2625118, lng: 19.2031369 },
    { lat: 48.2625436, lng: 19.2012619 },
    { lat: 48.2632838, lng: 19.1984112 },
    { lat: 48.2636196, lng: 19.1969455 },
    { lat: 48.2641824, lng: 19.1957165 },
    { lat: 48.2647798, lng: 19.1945689 },
    { lat: 48.2655652, lng: 19.1933273 },
    { lat: 48.2661914, lng: 19.1916423 },
    { lat: 48.266415, lng: 19.190831 },
    { lat: 48.2671911, lng: 19.1896066 },
    { lat: 48.267514, lng: 19.1888573 },
    { lat: 48.2677864, lng: 19.1882115 },
    { lat: 48.2684614, lng: 19.1861501 },
    { lat: 48.2687009, lng: 19.1849287 },
    { lat: 48.2691096, lng: 19.1844351 },
    { lat: 48.2696193, lng: 19.184396 },
    { lat: 48.2705018, lng: 19.1837722 },
    { lat: 48.2706087, lng: 19.1835636 },
    { lat: 48.2703581, lng: 19.1822107 },
    { lat: 48.2703193, lng: 19.1814436 },
    { lat: 48.2700444, lng: 19.1809475 },
    { lat: 48.2698195, lng: 19.1806837 },
    { lat: 48.269752, lng: 19.1804608 },
    { lat: 48.2697293, lng: 19.1794733 },
    { lat: 48.269866, lng: 19.1784734 },
    { lat: 48.2699002, lng: 19.1773189 },
    { lat: 48.2699738, lng: 19.1765465 },
    { lat: 48.2700944, lng: 19.175957 },
    { lat: 48.2703807, lng: 19.1749727 },
    { lat: 48.2707515, lng: 19.1740626 },
    { lat: 48.2707172, lng: 19.1740508 },
    { lat: 48.2724134, lng: 19.1730838 },
    { lat: 48.272451, lng: 19.1725439 },
    { lat: 48.2726974, lng: 19.1716935 },
    { lat: 48.2729878, lng: 19.171203 },
    { lat: 48.27329, lng: 19.1703163 },
    { lat: 48.2740467, lng: 19.1704605 },
    { lat: 48.2743987, lng: 19.1703939 },
    { lat: 48.2745194, lng: 19.1701023 },
    { lat: 48.2745946, lng: 19.1700469 },
    { lat: 48.274707, lng: 19.1701077 },
    { lat: 48.2748632, lng: 19.1699863 },
    { lat: 48.2751266, lng: 19.1699564 },
    { lat: 48.2752308, lng: 19.1701016 },
    { lat: 48.2757644, lng: 19.1699514 },
    { lat: 48.2761325, lng: 19.1697332 },
    { lat: 48.2766489, lng: 19.1700585 },
    { lat: 48.2770205, lng: 19.1700721 },
    { lat: 48.2771217, lng: 19.1699072 },
    { lat: 48.2773368, lng: 19.1699481 },
    { lat: 48.2775441, lng: 19.1697418 },
    { lat: 48.2778375, lng: 19.1661702 },
    { lat: 48.2779284, lng: 19.1656827 },
    { lat: 48.2782389, lng: 19.164692 },
    { lat: 48.2807668, lng: 19.160852 },
    { lat: 48.2810327, lng: 19.1602101 },
    { lat: 48.2812207, lng: 19.1598849 },
    { lat: 48.2816221, lng: 19.1594084 },
    { lat: 48.2819666, lng: 19.1591294 },
    { lat: 48.2824582, lng: 19.1588581 },
    { lat: 48.2829694, lng: 19.1584817 },
    { lat: 48.283531, lng: 19.1578929 },
    { lat: 48.2839752, lng: 19.1572789 },
    { lat: 48.2849321, lng: 19.1552961 },
    { lat: 48.2853687, lng: 19.1539393 },
    { lat: 48.2858498, lng: 19.1519364 },
    { lat: 48.2869698, lng: 19.1488319 },
    { lat: 48.2874195, lng: 19.1479217 },
    { lat: 48.2876449, lng: 19.1475369 },
    { lat: 48.2885309, lng: 19.1466498 },
    { lat: 48.2891671, lng: 19.1467024 },
    { lat: 48.289308, lng: 19.1467908 },
    { lat: 48.2897622, lng: 19.1466513 },
    { lat: 48.2898171, lng: 19.1462161 },
    { lat: 48.2897635, lng: 19.1456125 },
    { lat: 48.2898736, lng: 19.1454686 },
    { lat: 48.2896948, lng: 19.1451848 },
    { lat: 48.289728, lng: 19.1448731 },
    { lat: 48.2898255, lng: 19.1445252 },
    { lat: 48.2897329, lng: 19.1443632 },
    { lat: 48.2895417, lng: 19.144394 },
    { lat: 48.2893772, lng: 19.1442591 },
    { lat: 48.2892698, lng: 19.1440714 },
    { lat: 48.2891926, lng: 19.1436967 },
    { lat: 48.2889648, lng: 19.1435575 },
    { lat: 48.2889546, lng: 19.1431477 },
    { lat: 48.288754, lng: 19.1429226 },
    { lat: 48.2886503, lng: 19.1429894 },
    { lat: 48.2885657, lng: 19.1422438 },
    { lat: 48.2885803, lng: 19.1417675 },
    { lat: 48.2886762, lng: 19.1416994 },
    { lat: 48.2885144, lng: 19.1412102 },
    { lat: 48.2883794, lng: 19.1410808 },
    { lat: 48.2882533, lng: 19.1406291 },
    { lat: 48.2879766, lng: 19.1405042 },
    { lat: 48.2877609, lng: 19.1405421 },
    { lat: 48.2874751, lng: 19.1400352 },
    { lat: 48.2873513, lng: 19.1400402 },
    { lat: 48.2872378, lng: 19.1398603 },
    { lat: 48.2869441, lng: 19.1397834 },
    { lat: 48.2867495, lng: 19.1396564 },
    { lat: 48.2867918, lng: 19.1394199 },
    { lat: 48.2867484, lng: 19.1393171 },
    { lat: 48.2866022, lng: 19.1394824 },
    { lat: 48.2864017, lng: 19.1394613 },
    { lat: 48.2862004, lng: 19.1390845 },
    { lat: 48.2860078, lng: 19.1389067 },
    { lat: 48.2858635, lng: 19.1384096 },
    { lat: 48.2857212, lng: 19.1384736 },
    { lat: 48.2856261, lng: 19.1384431 },
    { lat: 48.2856496, lng: 19.1382133 },
    { lat: 48.2853099, lng: 19.1377511 },
    { lat: 48.2853553, lng: 19.1376016 },
    { lat: 48.284853, lng: 19.1372917 },
    { lat: 48.2846512, lng: 19.1368852 },
    { lat: 48.2839043, lng: 19.1368249 },
    { lat: 48.2837715, lng: 19.1367392 },
    { lat: 48.283644, lng: 19.1361942 },
    { lat: 48.2832594, lng: 19.1360336 },
    { lat: 48.2831608, lng: 19.1363636 },
    { lat: 48.2829045, lng: 19.1365833 },
    { lat: 48.2827746, lng: 19.1365313 },
    { lat: 48.2825994, lng: 19.136205 },
    { lat: 48.282402, lng: 19.1359751 },
    { lat: 48.2820198, lng: 19.1362018 },
    { lat: 48.2816872, lng: 19.1363129 },
    { lat: 48.2813353, lng: 19.1360804 },
    { lat: 48.2810942, lng: 19.136016 },
    { lat: 48.2805898, lng: 19.1361679 },
    { lat: 48.2800593, lng: 19.1356797 },
    { lat: 48.2793783, lng: 19.1352694 },
    { lat: 48.2789192, lng: 19.1347219 },
    { lat: 48.2786243, lng: 19.1344813 },
    { lat: 48.2782354, lng: 19.1342777 },
    { lat: 48.2772234, lng: 19.1333088 },
    { lat: 48.2771709, lng: 19.1333168 },
    { lat: 48.276866, lng: 19.1328356 },
    { lat: 48.2767734, lng: 19.1325359 },
    { lat: 48.2766704, lng: 19.1318955 },
    { lat: 48.2761939, lng: 19.1314742 },
    { lat: 48.2760601, lng: 19.1313855 },
    { lat: 48.275418, lng: 19.1314204 },
    { lat: 48.2750556, lng: 19.1309779 },
    { lat: 48.2746221, lng: 19.13074 },
    { lat: 48.2742694, lng: 19.1308357 },
    { lat: 48.2737186, lng: 19.1300073 },
    { lat: 48.2735115, lng: 19.1295159 },
    { lat: 48.2732885, lng: 19.1292941 },
    { lat: 48.2726539, lng: 19.1290692 },
    { lat: 48.2724747, lng: 19.1290723 },
    { lat: 48.2721257, lng: 19.1292408 },
    { lat: 48.2716648, lng: 19.1292888 },
    { lat: 48.2714147, lng: 19.12893 },
    { lat: 48.2711717, lng: 19.1287845 },
    { lat: 48.2709378, lng: 19.1287501 },
    { lat: 48.2704358, lng: 19.1288109 },
    { lat: 48.2701682, lng: 19.1284384 },
    { lat: 48.2699501, lng: 19.1277494 },
    { lat: 48.2698935, lng: 19.1277006 },
    { lat: 48.2695746, lng: 19.1276142 },
    { lat: 48.2692303, lng: 19.1273749 },
    { lat: 48.2687982, lng: 19.1275973 },
    { lat: 48.2683352, lng: 19.1273752 },
    { lat: 48.2676918, lng: 19.1266825 },
    { lat: 48.2673827, lng: 19.1267029 },
    { lat: 48.2673102, lng: 19.1268073 },
  ],
};

export default UnitsKrupina;
