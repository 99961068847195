import { useState } from "react";
import { Polygon } from "@react-google-maps/api";
import UnitsBánovcenadBebravou from "../constants/Units/RegionTrencin/UnitsBanovceNadBebravou";
import UnitsIlava from "../constants/Units/RegionTrencin/UnitsIlava";
import UnitsMyjava from "../constants/Units/RegionTrencin/UnitsMyjava";
import UnitsNovéMestonadVáhom from "../constants/Units/RegionTrencin/UnitsNoveMestoNadvahom";
import UnitsPartizánske from "../constants/Units/RegionTrencin/unitsPartizanske";
import UnitsPovažskáBystrica from "../constants/Units/RegionTrencin/UnitsPovazskaBystrica";
import UnitsPrievidza from "../constants/Units/RegionTrencin/UnitsPrievidza";
import UnitsPúchov from "../constants/Units/RegionTrencin/UnitsPuchov";
import UnitsTrenčín from "../constants/Units/RegionTrencin/UnitsTrencin";
import { averagePriceService } from "../../services/averagePriceService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTags } from "@fortawesome/free-solid-svg-icons";

export default function UnitsofTrencinRegion({
  priceMap,
  genAvgPrice,
  setPMResult,
  PMResult,
  map,
  PMHistory,
  setPMHistory,
}) {
  const [showTooltip, setShowTooltip] = useState({ active: false, name: "", x: 0, y: 0 });

  const defaultColor = "#7551b0";
  const green3 = "#007500";
  const green1 = "#00A300";
  const green2 = "#2EFF2E";
  const yellow1 = "#D1D100";
  const yellow2 = "#FFFF2E";
  const orange1 = "#FFA500";
  // const orange2 = "#FFB52E";
  const red1 = "#FF0000";
  const red2 = "#D10000";

  const setColor = (key) => {
    // console.log("length = ", Object.keys(genAvgPrice).length);
    if (Object.keys(genAvgPrice).length > 0) {
      // for (const k of Object.keys(genAvgPrice)) {
      if (genAvgPrice.hasOwnProperty(key)) {
        if (genAvgPrice[key]?.avgBytM2 >= 700 && genAvgPrice[key]?.avgBytM2 <= 1000) {
          return green1;
        } else if (genAvgPrice[key]?.avgBytM2 > 1000 && genAvgPrice[key]?.avgBytM2 <= 1300) {
          return green2;
        } else if (genAvgPrice[key]?.avgBytM2 > 1300 && genAvgPrice[key]?.avgBytM2 <= 1600) {
          return yellow1;
        } else if (genAvgPrice[key]?.avgBytM2 > 1600 && genAvgPrice[key]?.avgBytM2 <= 1800) {
          return yellow2;
        } else if (genAvgPrice[key]?.avgBytM2 > 1800 && genAvgPrice[key]?.avgBytM2 <= 2000) {
          return orange1;
        } else if (genAvgPrice[key]?.avgBytM2 > 2000 && genAvgPrice[key]?.avgBytM2 <= 2100) {
          return red1;
        } else if (genAvgPrice[key]?.avgBytM2 > 2100) {
          return red2;
        }
        return green3;
      } else return defaultColor;
      // }
    } else return defaultColor;
  };

  const unitAvgPrice = async (parent, key) => {
    // console.log("parent = ", parent, "|  key = ", key);
    const request = await averagePriceService.getUnitAvgPrice(decodeURIComponent(parent), decodeURIComponent(key));
    const response = await request.json();
    setPMResult({ ...PMResult, active: true, data: response });
    // console.log("response = ", response);
  };

  const convertLatLngToPoint = (latLng, map) => {
    const scale = Math.pow(2, map.getZoom());
    const nw = new window.google.maps.LatLng(
      map.getBounds().getNorthEast().lat(),
      map.getBounds().getSouthWest().lng(),
    );
    const worldCoordinateNW = map.getProjection().fromLatLngToPoint(nw);
    const worldCoordinate = map.getProjection().fromLatLngToPoint(latLng);
    const pixelOffset = new window.google.maps.Point(
      Math.floor((worldCoordinate.x - worldCoordinateNW.x) * scale),
      Math.floor((worldCoordinate.y - worldCoordinateNW.y) * scale),
    );
    return pixelOffset;
  };

  return priceMap.city ? (
    <>
      {Object.keys(UnitsBánovcenadBebravou).map((key) => {
        if (priceMap.cityName === "DistrictBánovcenadBebravou") {
          return (
            <Polygon
              key={key}
              paths={UnitsBánovcenadBebravou[key]}
              options={{
                // strokeColor: "#2d3740",
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("BánovcenadBebravou", key);
                setPMHistory({ ...PMHistory, city: "BánovcenadBebravou " + key });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {Object.keys(UnitsIlava).map((key) => {
        if (priceMap.cityName === "DistrictIlava") {
          return (
            <Polygon
              key={key}
              paths={UnitsIlava[key]}
              options={{
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("Ilava", key);
                setPMHistory({ ...PMHistory, city: "Ilava " + key });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {Object.keys(UnitsMyjava).map((key) => {
        if (priceMap.cityName === "DistrictMyjava") {
          return (
            <Polygon
              key={key}
              paths={UnitsMyjava[key]}
              options={{
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("Myjava", key);
                setPMHistory({ ...PMHistory, city: "Myjava " + key });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {Object.keys(UnitsNovéMestonadVáhom).map((key) => {
        if (priceMap.cityName === "DistrictNovéMestonadVáhom") {
          return (
            <Polygon
              key={key}
              paths={UnitsNovéMestonadVáhom[key]}
              options={{
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("NovéMestonadVáhom", key);
                setPMHistory({ ...PMHistory, city: "NovéMestonadVáhom " + key });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {Object.keys(UnitsPartizánske).map((key) => {
        if (priceMap.cityName === "DistrictPartizánske") {
          return (
            <Polygon
              key={key}
              paths={UnitsPartizánske[key]}
              options={{
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("Partizánske", key);
                setPMHistory({ ...PMHistory, city: "Partizánske " + key });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {Object.keys(UnitsPovažskáBystrica).map((key) => {
        if (priceMap.cityName === "DistrictPovažskáBystrica") {
          return (
            <Polygon
              key={key}
              paths={UnitsPovažskáBystrica[key]}
              options={{
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("PovažskaBystrica", key);
                setPMHistory({ ...PMHistory, city: "PovažskaBystrica " + key });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {Object.keys(UnitsPrievidza).map((key) => {
        if (priceMap.cityName === "DistrictPrievidza") {
          return (
            <Polygon
              key={key}
              paths={UnitsPrievidza[key]}
              options={{
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("Prievidza", key);
                setPMHistory({ ...PMHistory, city: "Prievidza " + key });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {Object.keys(UnitsPúchov).map((key) => {
        if (priceMap.cityName === "DistrictPúchov") {
          return (
            <Polygon
              key={key}
              paths={UnitsPúchov[key]}
              options={{
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("Púchov", key);
                setPMHistory({ ...PMHistory, city: "Púchov " + key });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {Object.keys(UnitsTrenčín).map((key) => {
        if (priceMap.cityName === "DistrictTrenčín") {
          return (
            <Polygon
              key={key}
              paths={UnitsTrenčín[key]}
              options={{
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("Trenčín", key);
                setPMHistory({ ...PMHistory, city: "Trenčín " + key });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {showTooltip.active ? (
        <div
          className="absolute mb-2 h-auto w-auto p-2 bg-sky-200 text-center text-sm rounded-lg opacity-100 transition-opacity duration-300"
          style={{ top: showTooltip.y + 10, left: showTooltip.x + 10 }}
        >
          <div className="flex flex-col">
            <div className="flex flex-row gap-1 justify-between items-center">
              Trhová cena pre
              <FontAwesomeIcon icon={faTags} />
            </div>
            <div>
              {genAvgPrice[showTooltip.name.replace("Ext", "")]?.obec
                .replace("nad", " nad")
                .replace("pod", " pod")
                .replace("pri", " pri")
                .replace(/(?<!^)(?<=\p{L})(?=[A-Z])/gu, " ")
                .replace("Ext", "")
                .replace("1", "")}
            </div>
            <div>za dom: {genAvgPrice[showTooltip.name.replace("Ext", "")]?.avgDom} €</div>
            <div>za m² bytu: {genAvgPrice[showTooltip.name.replace("Ext", "")]?.avgBytM2} €</div>
            <div>za m² pozemku: {genAvgPrice[showTooltip.name.replace("Ext", "")]?.avgPozemokM2} €</div>
          </div>
        </div>
      ) : null}
    </>
  ) : null;
}
