import { faEuro, faMessage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";

export default function RInfo({ avgPrice, previous, subMenu }) {
  return subMenu.lv ? (
    <motion.div
      onClick={(e) => e.stopPropagation()}
      className="w-auto flex flex-col items-start overflow-x-scroll "
      initial={{ x: previous === "" ? null : -500 }}
      animate={{ x: previous === "" ? null : 0 }}
      transition={{ duration: previous === "" ? null : 0.5 }}
    >
      <div className="flex flex-row w-full py-2 border-b border-opacity-50 border-b-sky-500">
        <div className="flex justify-center items-center w-1/5">
          <FontAwesomeIcon icon={faEuro} />
        </div>
        <div className="flex flex-col w-4/5">
          <div className="flex flex-row">
            <p className="pb-1">Trhová cena: {avgPrice?.avgPrice} €</p>
          </div>
        </div>
      </div>
      <div className="flex flex-row w-full py-2 border-b border-opacity-50 border-b-sky-500">
        <div className="flex justify-center items-center w-1/5">
          <FontAwesomeIcon icon={faMessage} />
        </div>
        <div className="flex flex-col w-4/5">
          <div className="flex flex-row">
            <p className="pb-1">
              {" "}
              {`Priemerná cena bola`} {`vypočítaná na základe `} {avgPrice?.countOfObjects}
              {` podobných nehnuteľností s presnosťou`} {avgPrice?.relevancy} {`%.`}
            </p>
          </div>
        </div>
      </div>
    </motion.div>
  ) : null;
}
