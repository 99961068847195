import { AnimatePresence, motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faChartSimple,
  faChevronLeft,
  faChevronRight,
  faPhone,
  faShieldHalved,
  faWindowMaximize,
  faWindowMinimize,
  faWindowRestore,
  faQuestion,
  faMagnifyingGlass,
  faArrowsToCircle,
  faX,
  faInfo,
} from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "usehooks-ts";
import { useState } from "react";
import clsx from "clsx";
import AboutUs from "../../AboutUs";
import Contact from "../../Contact";
import Privacy from "../../Privacy";
// import { averagePriceService } from "../../../services/averagePriceService";

export default function OptionsMenu({
  navSmall,
  setNavSmall,
  mapActive,
  setMapActive,
  isLogged,
  map,
  setZoom,
  setHideMarkers,
  hideMarkers,
  PMResult,
  setPMResult,
  setClickedReg,
  setPriceMap,
  priceMap,
  setGenAvgPrice,
  setOptSubMenu,
  optSubMenu,
  setIntroVisible,
}) {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 640px)");
  const [size, setSize] = useState({ min: false, mid: true, full: false });

  //TO DO: move to Home.jsx then to Switch.jsx
  // const [subMenu, setSubMenu] = useState({ aboutUs: false, contact: false, privacy: false });

  // const mapOrCalc = () => {
  //   setNavSmall({ ...navSmall, options: false });
  //   if (mapActive) {
  //     setMapActive(false);
  //   } else setMapActive(true);
  // };

  const changeSize = () => {
    if (size.mid) {
      setSize({ ...size, mid: false, full: true, min: false });
    } else {
      setSize({ ...size, mid: true, full: false, min: false });
    }
  };

  const minimize = () => {
    if (size.mid) {
      setSize({ ...size, min: true, mid: false, full: true });
    }
    if (size.full) {
      setSize({ ...size, min: true, mid: true, full: false });
    }
  };

  // const getAvgPriceRegion = async (key) => {
  //   const parent = key;
  //   const request = await averagePriceService.getGeneralAvgPrice(decodeURIComponent(parent));
  //   const response = await request.json();
  //   setGenAvgPrice(response);
  // };

  const buttons = () => {
    return (
      <div className="w-full px-2 flex justify-center items-center flex-col gap-2">
        {/* <button onClick={mapOrCalc} className={clsx("h-10 w-full bg-blue-200 rounded-lg", {})}>
          {mapActive ? (
            <>
              <FontAwesomeIcon icon={faCalculator} /> Kalkulačka
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faGlobe} /> Mapa
            </>
          )}
        </button> */}
        {/* <button
          // onClick={() => navigate("/cenova-mapa")}
          onClick={() => {
            setClickedReg(false);
            setHideMarkers({ ...hideMarkers, REO: true, history: true, house: true, flat: true, FI: true });
            setPriceMap({
              ...priceMap,
              region: !priceMap.region,
              district: false,
              city: false,
              districtName: "",
              cityName: "",
            });
            map.panTo({ lat: 48.6667, lng: 18.5 });
            setZoom(8);
            setPMResult({ ...PMResult, region: "" });
            getAvgPriceRegion("");
            setMapActive(true);
            setNavSmall({ ...navSmall, options: false });
          }}
          className={clsx("h-10 w-full bg-green-200 rounded-lg", {})}
        >
          <FontAwesomeIcon icon={faChartLine} /> Cenová mapa
        </button> */}
        {isLogged ? (
          <button
            // onClick={() => navigate("/statistiky")}
            className={clsx("h-10 w-full bg-purple-200 rounded-lg", {})}
          >
            <FontAwesomeIcon icon={faChartSimple} /> Štatistiky
          </button>
        ) : null}
        <button
          onClick={() => setOptSubMenu({ ...optSubMenu, aboutUs: true, contact: false, privacy: false, helper: false })}
          className={clsx("h-10 w-full bg-cyan-200 rounded-lg", {})}
        >
          <FontAwesomeIcon icon={faAddressCard} /> O kompase
        </button>
        <button
          onClick={() => setOptSubMenu({ ...optSubMenu, aboutUs: false, contact: true, privacy: false, helper: false })}
          className={clsx("h-10 w-full bg-emerald-200 rounded-lg", {})}
        >
          <FontAwesomeIcon icon={faPhone} /> Kontak
        </button>
        <button
          onClick={() => setOptSubMenu({ ...optSubMenu, aboutUs: false, contact: false, privacy: true, helper: false })}
          className={clsx("h-10 w-full bg-orange-200 rounded-lg", {})}
        >
          <FontAwesomeIcon icon={faShieldHalved} /> Súkromie
        </button>
        <button
          onClick={() => setOptSubMenu({ ...optSubMenu, aboutUs: false, contact: false, privacy: false, helper: true })}
          className={clsx("h-10 w-full bg-blue-200 rounded-lg", {})}
        >
          <FontAwesomeIcon icon={faQuestion} className="w-8 h-[22px]" />
          Pomocník
        </button>
      </div>
    );
  };

  const getSubMenu = () => {
    if (optSubMenu.aboutUs) {
      return <AboutUs size={size} />;
    }
    if (optSubMenu.contact) {
      return <Contact size={size} />;
    }
    if (optSubMenu.privacy) {
      return <Privacy size={size} />;
    }
    if (optSubMenu.helper) {
      return (
        <div className="w-full px-2 flex justify-center items-center flex-col gap-2">
          <button
            className={clsx("h-10 w-full bg-blue-200 rounded-lg", {
              // "w-full": isSmallDevice || !size.full,
              // "w-1/2": !isSmallDevice && size.full,
            })}
          >
            <FontAwesomeIcon icon={faMagnifyingGlass} /> Vyhľadať
          </button>
          <button className={clsx("h-10 w-full bg-green-200 rounded-lg", {})}>
            <FontAwesomeIcon icon={faArrowsToCircle} /> Obsah
          </button>
          <button
            className={clsx("h-10 w-full bg-orange-200 rounded-lg flex justify-center items-center gap-1", {})}
            onClick={() => {
              setIntroVisible(true);
              setNavSmall({
                ...navSmall,
                properties: false,
                subjects: false,
                helper: false,
                options: false,
                login: false,
              });
            }}
          >
            <FontAwesomeIcon icon={faInfo} /> Intro
          </button>
        </div>
      );
    }
    return buttons();
  };

  const goBack = () => {
    if (optSubMenu.aboutUs || optSubMenu.contact || optSubMenu.privacy || optSubMenu.helper) {
      setOptSubMenu({ ...optSubMenu, aboutUs: false, contact: false, privacy: false, helper: false });
    }
  };

  return (
    <AnimatePresence>
      {navSmall.options ? ( //&& isSmallDevice
        <motion.div
          className={clsx(
            "transition-all duration-200 ease-linear overflow-auto absolute h-auto bg-sky-100 flex flex-col items-center gap-2 no-scrollbar select-none",
            {
              "!h-[calc(100%-64px)] !rounded-none": size.full && !size.min && isSmallDevice,
              "!h-[45%]": size.mid && !size.min && isSmallDevice,
              "!h-[10%]": size.min && isSmallDevice,
              "bottom-0 left-0 w-full pb-5 rounded-t-xl": isSmallDevice,
              "top-0 left-16 h-full": !isSmallDevice,
              "w-96": !isSmallDevice && size.mid,
              "w-[calc(100%-64px)]": !isSmallDevice && size.full,
            },
          )}
          initial={{ opacity: 0, scale: 1, y: isSmallDevice ? 300 : 0, x: !isSmallDevice ? -500 : 0 }}
          animate={{ opacity: 1, scale: 1, y: isSmallDevice ? 0 : 0, x: !isSmallDevice ? 0 : 0 }}
          exit={{ opacity: 1, scale: 1, y: isSmallDevice ? 350 : 0, x: !isSmallDevice ? -500 : 0 }}
          transition={{
            duration: 0.3,
            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          <div
            className={clsx(
              "sticky top-0 right-0 rounded-t-lg w-full h-8 bg-sky-100 border-b border-opacity-50 border-b-sky-500 z-10 grid",
              {
                "grid-cols-[10%_10%_50%_10%_10%_10%]": isSmallDevice,
                "grid-cols-[10%_10%_60%_10%_10%]": !isSmallDevice,
              },
            )}
          >
            <button
              disabled={
                optSubMenu.aboutUs || optSubMenu.contact || optSubMenu.privacy || optSubMenu.helper ? false : true
              }
              className="w-full h-full disabled:text-gray-300"
              onClick={() => goBack()}
            >
              <FontAwesomeIcon icon={faChevronLeft} className="w-5 h-4" />
            </button>
            <button
              disabled={true}
              className="w-full h-full border-x border-opacity-50 border-x-sky-500 disabled:text-gray-300"
            >
              <FontAwesomeIcon icon={faChevronRight} className="w-5 h-4" />
            </button>
            <div className="w-full h-full flex justify-start items-center px-1">Možnosti</div>
            {isSmallDevice ? (
              <button onClick={() => minimize()} className="w-full h-full border-l border-opacity-50 border-l-sky-500">
                <FontAwesomeIcon icon={faWindowMinimize} />
              </button>
            ) : null}
            <button onClick={() => changeSize()} className="w-full h-full border-x border-opacity-50 border-x-sky-500">
              {size.full ? <FontAwesomeIcon icon={faWindowRestore} /> : null}
              {size.mid ? <FontAwesomeIcon icon={faWindowMaximize} /> : null}
            </button>
            <button
              onClick={() => {
                setNavSmall({ ...navSmall, options: false });
                setOptSubMenu({ ...optSubMenu, aboutUs: false, contact: false, privacy: false });
              }}
              className="w-full h-full"
            >
              <FontAwesomeIcon icon={faX} />
            </button>
          </div>
          {getSubMenu()}
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
}
