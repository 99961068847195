export default function SelectEntrance({ propList, blockOfFlats, setBlockOfFlats }) {
  // console.log("propList entrance = ", propList);
  const getUniqueListBy = (arr, key) => {
    return [...new Map(arr?.map((item) => [item[key], item])).values()];
  };
  const arrEntrance = getUniqueListBy(propList?.List?.byt, "vchod__cislo_");

  return propList?.List?.hasOwnProperty("byt") ? (
    blockOfFlats.entrance === null ? (
      <div className="w-full flex flex-col justify-center items-center">
        <div>Zvoľ vchod</div>
        {arrEntrance?.map((value, index) => {
          return (
            <div
              onClick={() => setBlockOfFlats({ ...blockOfFlats, entrance: value?.vchod__cislo_ })}
              key={index}
              className="h-10 border-2 border-gray-300 w-[78%] rounded-lg mb-1 flex items-center justify-center cursor-pointer"
            >
              <div>{value?.vchod__cislo_}</div>
            </div>
          );
        })}
      </div>
    ) : null
  ) : null;
}
