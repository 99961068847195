import { AnimatePresence, motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBookmark,
  faChevronLeft,
  faChevronRight,
  faHistory,
  faHouse,
  faShare,
  faStarHalfAlt,
  faUser,
  faWindowMaximize,
  faWindowMinimize,
  faWindowRestore,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "usehooks-ts";
import { useState, useEffect, useRef } from "react";
import HistoryList from "../../History/HistoryList";
import ForSale from "../../ForSale/ForSale";
import clsx from "clsx";
import { forSaleService } from "../../../services/forSaleService";
import BlueCity from "../../../Images/blueCity.png";
import BlueHouse from "../../../Images/blueHouse.png";
import BlueFlat from "../../../Images/blueFlat.png";
import Rated from "./Parts/Rated";
import Shared from "./Parts/Shared";
import Saved from "./Parts/Saved";
import MyProperties from "./Parts/MyProperties";

export default function PropertiesMenu({
  navSmall,
  setNavSmall,
  isLogged,
  map,
  setCurrentIndex,
  setVisibilityOfMarkers,
  reversedHistoryItems,
  centralize,
  visibilityOfMarkers,
  setExpanded,
  isExpanded,
  indexCur,
  isHistory,
  setHistory,
  viewSale,
  setViewSale,
  openLeftBar,
  responseForSale,
  setResponseForSale,
  setCurrentItem,
  setGallery,
  gallery,
  setSubMenu,
  subMenu,
  forSale,
  setForSale,
  setHistoryMenu,
  historyMenu,
  indx,
  blockOfFlats,
  setHighlight,
  highlight,
  subjects,
  indxPrev,
  filterSale,
  setFilterSale,
  filters,
  setFilters,
  shareLoad,
  setShareLoad,
}) {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 640px)");
  const [lvSubMenu, setLvSubMenu] = useState({
    lv: false,
    partA: true,
    parcelC: true,
    stavby: true,
    tarchy: true,
    owner: true,
  });
  const [ownerMenu, setOwnerMenu] = useState({ sell: false });
  const [inputValues, setInputValues] = useState({
    writeMessage: "",
    arrangeVisit: "",
    determinePrice: "",
    book: "",
    review: "",
  });
  const [rating, setRating] = useState({ awesome: false, good: false, meh: false, bad: false });
  const [size, setSize] = useState({ min: false, mid: true, full: false });
  const idx = useRef();
  const [previousMenu, setPreviousMenu] = useState("");
  const canvasRef = useRef(null);
  const clicked = useRef(-1);

  useEffect(() => {
    if (forSale.menu) setPreviousMenu("forSaleMenu");
    if (isHistory) setPreviousMenu("isHistory");
    if (viewSale.view) setPreviousMenu("viewSale");
    if (viewSale.my) setPreviousMenu("my");
    if (viewSale.saved) setPreviousMenu("saved");
    if (viewSale.shared) setPreviousMenu("shared");
    if (viewSale.rated) setPreviousMenu("rated");
    const callSales = async () => {
      const response = await forSaleService.getAll();
      const resp = await response.json();
      setResponseForSale(resp);
    };
    callSales();
  }, [setResponseForSale, isHistory, viewSale, forSale]);

  function centralizeFS() {
    if (responseForSale?.items?.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      responseForSale?.items?.forEach((marker) => {
        bounds.extend(new window.google.maps.LatLng(marker.lat, marker.lng));
      });
      map.fitBounds(bounds);
    }
  }

  const close = () => {
    if (window.location.pathname.includes("share")) {
      window.history.replaceState(null, "", "/");
    }
    setSubMenu({ ...subMenu, lv: false, interest: false, owner: true, rating: false, share: false });
    setForSale({ ...forSale, menu: false });
    setHistory(false);
    setVisibilityOfMarkers("");
    setViewSale({ ...viewSale, view: false });
    setCurrentItem(null);
    setHistoryMenu(false);
    setNavSmall({ ...navSmall, properties: false });
    setFilterSale([]);
  };

  const goBack = () => {
    clicked.current = -1;
    setVisibilityOfMarkers("");
    if (window.location.pathname.includes("share")) {
      window.history.replaceState(null, "", "/");
    }
    if (subjects.ROffice || subjects.RBroker || subjects.FInstitution || subjects.FBroker) {
      indx.current = indxPrev.current;
      indxPrev.current = -2;
      setNavSmall({ ...navSmall, properties: false, subjects: true });
    }
    if (gallery) {
      setGallery(false);
      return;
    }
    if (forSale.menu) {
      setSubMenu({ ...subMenu, lv: false, interest: false, owner: true, rating: false, share: false });
      setForSale({ ...forSale, menu: false });
      return;
    }
    if (historyMenu) {
      setHistoryMenu(false);
      return;
    }
    if (isHistory) {
      setHistory(false);
      setSubMenu({ ...subMenu, lv: false, interest: false, owner: true, rating: false, share: false });
      setVisibilityOfMarkers("");
      return;
    }
    setViewSale({ ...viewSale, view: false });
    setCurrentItem(null);

    if (viewSale.view || viewSale.rated || viewSale.shared || viewSale.saved || viewSale.my) {
      setViewSale({ ...viewSale, view: false, rated: false, shared: false, saved: false, my: false });
    }
  };

  const buttons = () => {
    return (
      <div className="w-full px-2 flex justify-center items-center flex-col gap-2">
        {isLogged ? (
          <>
            <button
              onClick={() =>
                setViewSale({ ...viewSale, view: false, rated: false, shared: false, saved: false, my: true })
              }
              className={clsx("h-10 w-full bg-blue-200 rounded-lg", {})}
            >
              <FontAwesomeIcon icon={faUser} /> Moje
            </button>
            <button
              onClick={() =>
                setViewSale({ ...viewSale, view: false, rated: false, shared: false, saved: true, my: false })
              }
              className={clsx("h-10 w-full bg-cyan-200 rounded-lg", {})}
            >
              <FontAwesomeIcon icon={faBookmark} /> Uložené
            </button>
            <button
              onClick={() =>
                setViewSale({ ...viewSale, view: false, rated: false, shared: true, saved: false, my: false })
              }
              className={clsx("h-10 w-full bg-emerald-200 rounded-lg", {})}
            >
              <FontAwesomeIcon icon={faShare} /> Zdieľané
            </button>
            <button
              onClick={() =>
                setViewSale({ ...viewSale, view: false, rated: true, shared: false, saved: false, my: false })
              }
              className={clsx("h-10 w-full bg-orange-200 rounded-lg", {})}
            >
              <FontAwesomeIcon icon={faStarHalfAlt} /> Hodnotené
            </button>
          </>
        ) : null}
        <button
          onClick={() => {
            setViewSale({ ...viewSale, view: true, rated: false, shared: false, saved: false, my: false });
            centralizeFS();
          }}
          className={clsx("h-10 w-full bg-green-200 rounded-lg", {})}
        >
          <FontAwesomeIcon icon={faHouse} /> Na predaj
        </button>
        <button
          onClick={() => {
            setHistory(true);
            setSubMenu({ ...subMenu, lv: true, interest: false, owner: false, rating: false, share: false });
            setVisibilityOfMarkers("historyOpened");
            centralize();
            setViewSale({ ...viewSale, view: false });
            replaceImage();
          }}
          className={clsx("h-10 w-full bg-red-200 rounded-lg", {
            // "w-full": isSmallDevice || !size.full,
            // "w-1/2": !isSmallDevice && size.full,
          })}
        >
          <FontAwesomeIcon icon={faHistory} /> História
        </button>
      </div>
    );
  };

  const title = () => {
    if (forSale.menu) {
      return (
        (indx.current >= 0 ? JSON.parse(responseForSale.items[indx.current].parameters).type : "") +
        ", " +
        (indx.current >= 0 ? JSON.parse(responseForSale.items[indx.current].parameters).area + " m²" : "") +
        ", " +
        (indx.current >= 0 ? responseForSale.items[indx.current].city : "")
      );
    }
    if (viewSale.view) {
      return "Na predaj";
    }
    if (viewSale.rated) {
      return "Hodnotené";
    }
    if (viewSale.shared) {
      return "Zdieľané";
    }
    if (viewSale.saved) {
      return "Uložené";
    }
    if (viewSale.my) {
      return "Moje nehnuteľnosti";
    }
    if (historyMenu) {
      return (
        (indx.current >= 0 ? reversedHistoryItems[indx.current]?.location : "") +
        " " +
        (indx.current >= 0 ? reversedHistoryItems[indx.current]?.number : "") +
        ", " +
        (indx.current >= 0 ? reversedHistoryItems[indx.current].avgPrice.avgPrice + " €" : "")
      );
    }
    if (isHistory) {
      return "História";
    }
    return "Nehnuteľnosti";
  };

  // const [endPositionY, setEndPositionY] = useState(0);
  // const [startPositionY, setStartPositionY] = useState(0);

  const getList = () => {
    if (isHistory) {
      return (
        <HistoryList
          historyItems={reversedHistoryItems}
          extended={true}
          map={map}
          setCurrentIndex={setCurrentIndex}
          setVisibilityOfMarkers={setVisibilityOfMarkers}
          setLvSubMenu={setLvSubMenu}
          lvSubMenu={lvSubMenu}
          setSubMenu={setSubMenu}
          subMenu={subMenu}
          setInputValues={setInputValues}
          inputValues={inputValues}
          rating={rating}
          setRating={setRating}
          ownerMenu={ownerMenu}
          setOwnerMenu={setOwnerMenu}
          indexCur={indexCur}
          setHistoryMenu={setHistoryMenu}
          historyMenu={historyMenu}
          idx={idx}
          indx={indx}
          size={size}
          blockOfFlats={blockOfFlats}
          setHighlight={setHighlight}
          highlight={highlight}
        />
      );
    }
    if (viewSale.view) {
      return (
        <ForSale
          setVisibilityOfMarkers={setVisibilityOfMarkers}
          map={map}
          visibilityOfMarkers={visibilityOfMarkers}
          openLeftBar={openLeftBar}
          forSaleItems={responseForSale}
          setCurrentItem={setCurrentItem}
          isExpanded={isExpanded}
          setExpanded={setExpanded}
          subMenu={subMenu}
          setSubMenu={setSubMenu}
          inputValues={inputValues}
          setInputValues={setInputValues}
          setRating={setRating}
          rating={rating}
          forSale={forSale}
          setForSale={setForSale}
          lvSubMenu={lvSubMenu}
          setLvSubMenu={setLvSubMenu}
          setGallery={setGallery}
          gallery={gallery}
          idx={idx}
          indx={indx}
          size={size}
          clicked={clicked}
          setHighlight={setHighlight}
          highlight={highlight}
          filterSale={filterSale}
          setFilterSale={setFilterSale}
          filters={filters}
          setFilters={setFilters}
          shareLoad={shareLoad}
          setShareLoad={setShareLoad}
        />
      );
    }
    if (viewSale.rated) {
      return <Rated openLeftBar={openLeftBar} gallery={gallery} size={size} />;
    }
    if (viewSale.shared) {
      return <Shared openLeftBar={openLeftBar} gallery={gallery} size={size} />;
    }
    if (viewSale.saved) {
      return <Saved openLeftBar={openLeftBar} gallery={gallery} size={size} />;
    }
    if (viewSale.my) {
      return <MyProperties openLeftBar={openLeftBar} gallery={gallery} size={size} />;
    }
    return buttons();
  };

  const changeSize = () => {
    if (size.mid) {
      setSize({ ...size, mid: false, full: true, min: false });
    } else {
      setSize({ ...size, mid: true, full: false, min: false });
    }
  };

  const minimize = () => {
    if (size.mid) {
      setSize({ ...size, min: true, mid: false, full: true });
    }
    if (size.full) {
      setSize({ ...size, min: true, mid: true, full: false });
    }
  };

  const goForward = () => {
    console.log("previousMenu = ", previousMenu);
    if (previousMenu === "isHistory") {
      setHistory(true);
      setSubMenu({ ...subMenu, lv: true, interest: false, owner: false, rating: false, share: false });
      setVisibilityOfMarkers("historyOpened");
      centralize();
      setViewSale({ ...viewSale, view: false });
    }
    if (previousMenu === "viewSale") {
      setViewSale({ ...viewSale, view: true, rated: false, shared: false, saved: false, my: false });
      centralizeFS();
    }
    if (previousMenu === "my") {
      setViewSale({ ...viewSale, view: false, rated: false, shared: false, saved: false, my: true });
      centralizeFS();
    }
    if (previousMenu === "saved") {
      setViewSale({ ...viewSale, view: false, rated: false, shared: false, saved: true, my: false });
      centralizeFS();
    }
    if (previousMenu === "shared") {
      setViewSale({ ...viewSale, view: false, rated: false, shared: true, saved: false, my: false });
      centralizeFS();
    }
    if (previousMenu === "rated") {
      setViewSale({ ...viewSale, view: false, rated: true, shared: false, saved: false, my: false });
      centralizeFS();
    }
  };

  const replaceImage = () => {
    reversedHistoryItems.forEach((item, index) => {
      // console.log("item = ", item);
      const img = new Image();
      img.crossOrigin = "anonymous";

      img.onload = () => {
        const canvas = canvasRef.current;
        const context = canvas?.getContext("2d", { willReadFrequently: true });
        canvas.width = img.width;
        canvas.height = img.height;

        context.drawImage(img, 0, 0, img.width, img.height);
        const imageData = context.getImageData(0, 0, img.width, img.height);
        const data = imageData.data;

        let r = 0,
          g = 0,
          b = 0;
        const pixelCount = img.width * img.height;

        for (let i = 0; i < data.length; i += 4) {
          r += data[i];
          g += data[i + 1];
          b += data[i + 2];
        }

        r = Math.round(r / pixelCount);
        g = Math.round(g / pixelCount);
        b = Math.round(b / pixelCount);
        // console.log("r = ", r, " g = ", g, " b = ", b);
        // setAverageColor({ r, g, b });
        if (r >= 220 && r <= 230 && g >= 220 && g <= 230 && b >= 220 && b <= 230) {
          if (item?.response?.List?.table_stavby?.length !== undefined) {
            if (item?.response?.List?.table_stavby[0]?.druh_stavby?.includes("10")) {
              item.response.img = BlueHouse;
              return;
            }
            if (
              item?.response?.List?.table_stavby[0]?.druh_stavby?.includes("9") ||
              item?.response?.List?.table_stavby[0]?.druh_stavby?.includes("22")
            ) {
              item.response.img = BlueFlat;
              return;
            }
          } else {
            if (item?.response?.List?.table_stavby?.druh_stavby?.includes("10")) {
              item.response.img = BlueHouse;
              return;
            }
            if (
              item?.response?.List?.table_stavby?.druh_stavby?.includes("9") ||
              item?.response?.List?.table_stavby?.druh_stavby?.includes("22")
            ) {
              item.response.img = BlueFlat;
              return;
            }
          }
          item.response.img = BlueCity;
        }
      };

      img.src = reversedHistoryItems[index]?.response?.img;
    });
  };

  return (
    <AnimatePresence>
      {navSmall.properties ? ( //&& isSmallDevice
        <motion.div
          className={clsx(
            "transition-all duration-200 ease-linear overflow-auto absolute bg-sky-100 flex flex-col items-center select-none",
            {
              "!h-[calc(100%-64px)] !rounded-none": ((size.full && !size.min) || gallery) && isSmallDevice,
              "!h-[45%]": size.mid && !size.min && isSmallDevice,
              "!h-[10%]": size.min && isSmallDevice,
              "!py-0 !rounded-none": gallery,
              // "gap-1": !gallery,
              "gap-4": isHistory,
              "bottom-0 left-0 w-full pb-5 rounded-t-xl  no-scrollbar": isSmallDevice,
              "overflow-hidden": gallery,
              "top-0 left-16 h-full": !isSmallDevice,
              "w-96": !isSmallDevice && size.mid,
              "w-[calc(100%-64px)]": !isSmallDevice && (size.full || gallery),
            },
          )}
          initial={{ opacity: 0, scale: 1, y: isSmallDevice ? 300 : 0, x: !isSmallDevice ? -500 : 0 }}
          animate={{ opacity: 1, scale: 1, y: isSmallDevice ? 0 : 0, x: !isSmallDevice ? 0 : 0 }}
          exit={{ opacity: 1, scale: 1, y: isSmallDevice ? 350 : 0, x: !isSmallDevice ? -500 : 0 }}
          transition={{
            duration: 0.3,
            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          <div
            onClick={() => {
              indexCur.current = null;
            }}
            className={clsx(
              "sticky top-0 right-0 rounded-t-lg w-full h-8 bg-sky-100 border-b border-opacity-50 border-b-sky-500 z-10 grid",
              {
                "grid-cols-[10%_10%_50%_10%_10%_10%]": isSmallDevice,
                "grid-cols-[10%_10%_60%_10%_10%]": !isSmallDevice,
              },
            )}
          >
            <canvas ref={canvasRef} style={{ display: "none" }} />
            <button
              disabled={
                !forSale.menu &&
                !isHistory &&
                !viewSale.view &&
                !viewSale.rated &&
                !viewSale.shared &&
                !viewSale.saved &&
                !viewSale.my
                  ? true
                  : false
              }
              onClick={() => goBack()}
              className="w-full h-full disabled:text-gray-300"
            >
              <FontAwesomeIcon icon={faChevronLeft} className="w-5 h-4" />
            </button>
            <button
              disabled={
                (previousMenu !== "" && isHistory) ||
                (previousMenu !== "" && viewSale.view) ||
                (previousMenu !== "" && viewSale.my) ||
                (previousMenu !== "" && viewSale.saved) ||
                (previousMenu !== "" && viewSale.shared) ||
                (previousMenu !== "" && viewSale.rated)
                  ? true
                  : false
              }
              onClick={() => goForward()}
              className="w-full h-full border-x border-opacity-50 border-x-sky-500 disabled:text-gray-300"
            >
              <FontAwesomeIcon icon={faChevronRight} className="w-5 h-4" />
            </button>
            <div
              className={clsx("w-full h-full text-start pt-[2px] pl-1 overflow-hidden truncate ", {
                "text-xs flex items-center": forSale.menu,
              })}
            >
              {title()}
            </div>
            {isSmallDevice ? (
              <button onClick={() => minimize()} className="w-full h-full border-l border-opacity-50 border-l-sky-500">
                <FontAwesomeIcon icon={faWindowMinimize} />
              </button>
            ) : null}
            <button onClick={() => changeSize()} className="w-full h-full border-x border-opacity-50 border-x-sky-500">
              {size.full ? <FontAwesomeIcon icon={faWindowRestore} /> : null}
              {size.mid ? <FontAwesomeIcon icon={faWindowMaximize} /> : null}
            </button>
            <button onClick={() => close()} className="w-full h-full">
              <FontAwesomeIcon icon={faX} />
            </button>
          </div>
          <div className={clsx("w-full h-auto flex flex-col gap-4 py-2", {})}>{getList()}</div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
}
