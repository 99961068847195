import { useState } from "react";
import { loginService } from "../../services/loginService";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AkcenLogo from "../../Images/logo/akcen_logo_v3_black_trans_edit.png";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export default function Login({ setIsLogged, isParam, setInfoUser, infoUser }) {
  const [user, setUser] = useState({ email: "", password: "" });
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const login = async () => {
    const request = JSON.parse(JSON.stringify({ username: user.email, password: user.password }));
    // console.log("request = ", request);
    const response = await loginService.setLogin(request);
    const userInfo = await response.json();
    // console.log("userInfo = ", userInfo);
    // console.log("response = ", response.headers);
    // const getCookie = (name) => {
    //   const value = `; ${response.cookie}`;
    //   const parts = value.split(`; ${name}=`);
    //   console.log("parts = ", parts.length);
    //   if (parts.length === 2) return parts.pop().split(";").shift();
    // };

    // const jsessionid = getCookie("JSESSIONID");
    // console.log("jsessionid = ", jsessionid);

    // for (var pair of response.headers.entries()) {
    //   console.log("Set-Cookie = ", pair);
    // }
    // console.log("header = ", response.headers.get("content-type"));
    // console.log("set-cookie = ", response.headers.get("set-cookie"));
    // console.log("userInfo = ", userInfo);
    if (userInfo.errorMessage !== null) {
      setErrorMessage(userInfo.errorMessage);
    }

    if (userInfo?.username) {
      const userData = JSON.stringify({
        rt: userInfo?.refreshToken,
        m: userInfo?.user?.email,
        l: true,
        p: userInfo?.user?.picture,
        n: userInfo?.user?.givenName,
        sn: userInfo?.user?.familyName,
        ph1: userInfo?.user?.phone1,
        ph2: userInfo?.user?.phone2,
        role: userInfo?.user?.roleId,
        auth: userInfo?.authToken,
      });
      localStorage.setItem("userData", userData);
      localStorage.setItem("rt", userInfo.refreshToken);
      if (localStorage.userData) {
        const storage = JSON.parse(localStorage.getItem("userData"));
        setIsLogged(storage.l);
        setInfoUser({
          ...infoUser,
          email: storage.m,
          refreshTOken: storage.rt,
          photo: storage.p,
          name: storage.n,
          surname: storage.sn,
          phone1: storage.ph1,
          phone2: storage.ph2,
          role: storage.role,
        });
      }
      //   setIsLogged(true);
      if (isParam) {
        navigate("/");
      } else {
        navigate("/");
      }
    }
  };

  return (
    <>
      <div className="w-full absolute top-0 left-0 p-2 flex justify-between items-center">
        <a href="/">
          <img src={AkcenLogo} alt="Akcen" className="w-10 h-10" />
        </a>
        <a
          href="/"
          className="bg-sky-100 rounded-full w-7 h-7 border-2 border-gray-300 flex justify-center items-center"
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </a>
      </div>
      <div className="w-full h-screen flex flex-col justify-start items-center p-2 pt-10 sm:p-10 gap-8">
        <div className="w-full sm:w-[448px] h-auto rounded-2xl flex flex-col justify-start items-center p-8 gap-2 shadow-md">
          <h1 className="text-2xl">REKOM prihlásenie</h1>
          {/* Email */}
          <div className="w-full flex flex-col">
            <div className="flex justify-between">
              <p className="w-1/2">E-mail </p>
              <p>*</p>
            </div>
            <input
              name="email"
              value={user.email}
              onChange={(event) => {
                setUser({ ...user, email: event.target.value });
                //   validateEmail(event);
              }}
              className="bg-white border-gray-300 rounded-lg shadow-sm outline-none appearance-none focus:border-sky-500"
              type="email"
            />
          </div>
          {/* Email */}
          {/* Heslo */}
          <div className="w-full flex flex-col">
            <div className="flex justify-between">
              <p className="w-1/2">Heslo </p>
              <p>*</p>
            </div>
            <input
              name="password"
              value={user.password}
              onChange={(event) => {
                setUser({ ...user, password: event.target.value });
                //   validatePassword(event);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  login();
                }
              }}
              min={10}
              className="bg-white border-gray-300 rounded-lg shadow-sm outline-none appearance-none focus:border-sky-500"
              type="password"
            />
          </div>
          {/* Heslo */}
          <div className="w-full h-8 flex justify-center items-center text-red-500">
            {errorMessage !== "" ? errorMessage : null}
          </div>
          <button
            className="bg-sky-100 w-auto h-auto rounded-2xl p-2 hover:bg-sky-200 disabled:opacity-30 select-none"
            disabled={user.email === "" || user.password === ""}
            onClick={() => login()}
          >
            Prihlásiť sa
          </button>
        </div>
        {/* <div className="flex flex-col items-center gap-4">
          <div className="flex flex-row gap-4">
            <p>Zabudnuté heslo?</p>
            <a href="/obnova-hesla" className="underline underline-offset-1">
              Obnoviť heslo
            </a>
          </div>
          <div className="flex flex-row gap-4">
            <p>Ešte nemáte účet?</p>
            <a href="/registracia" className="underline underline-offset-1">
              Vytvoriť účet
            </a>
          </div>
        </div> */}
      </div>
    </>
  );
}
