const UnitsTrnava = {
  Borová: [
    { lat: 48.400263, lng: 17.411213 },
    { lat: 48.400237, lng: 17.411264 },
    { lat: 48.400287, lng: 17.411342 },
    { lat: 48.400455, lng: 17.411549 },
    { lat: 48.400582, lng: 17.411758 },
    { lat: 48.400785, lng: 17.412007 },
    { lat: 48.400928, lng: 17.41229 },
    { lat: 48.401206, lng: 17.412654 },
    { lat: 48.401347, lng: 17.413179 },
    { lat: 48.401437, lng: 17.41349 },
    { lat: 48.401547, lng: 17.413827 },
    { lat: 48.40162, lng: 17.414131 },
    { lat: 48.401632, lng: 17.414391 },
    { lat: 48.401672, lng: 17.414622 },
    { lat: 48.401662, lng: 17.414941 },
    { lat: 48.40168, lng: 17.415057 },
    { lat: 48.401736, lng: 17.415177 },
    { lat: 48.40186, lng: 17.41533 },
    { lat: 48.401928, lng: 17.415415 },
    { lat: 48.401901, lng: 17.415432 },
    { lat: 48.401651, lng: 17.415628 },
    { lat: 48.401397, lng: 17.415834 },
    { lat: 48.401263, lng: 17.415947 },
    { lat: 48.401157, lng: 17.416039 },
    { lat: 48.400996, lng: 17.416171 },
    { lat: 48.400918, lng: 17.416235 },
    { lat: 48.400754, lng: 17.416371 },
    { lat: 48.400539, lng: 17.416542 },
    { lat: 48.400292, lng: 17.416742 },
    { lat: 48.400139, lng: 17.41687 },
    { lat: 48.399929, lng: 17.417006 },
    { lat: 48.399718, lng: 17.417139 },
    { lat: 48.399565, lng: 17.417234 },
    { lat: 48.399426, lng: 17.41732 },
    { lat: 48.399188, lng: 17.417438 },
    { lat: 48.398975, lng: 17.417544 },
    { lat: 48.39873, lng: 17.41766 },
    { lat: 48.398502, lng: 17.417771 },
    { lat: 48.398157, lng: 17.417834 },
    { lat: 48.397782, lng: 17.417898 },
    { lat: 48.39734, lng: 17.417976 },
    { lat: 48.397071, lng: 17.418016 },
    { lat: 48.396804, lng: 17.418025 },
    { lat: 48.396404, lng: 17.418017 },
    { lat: 48.39611, lng: 17.418032 },
    { lat: 48.396031, lng: 17.418039 },
    { lat: 48.395895, lng: 17.418501 },
    { lat: 48.395789, lng: 17.418858 },
    { lat: 48.395623, lng: 17.419354 },
    { lat: 48.395481, lng: 17.419753 },
    { lat: 48.39518, lng: 17.419523 },
    { lat: 48.394787, lng: 17.419228 },
    { lat: 48.394425, lng: 17.418951 },
    { lat: 48.394113, lng: 17.418717 },
    { lat: 48.394098, lng: 17.418707 },
    { lat: 48.393962, lng: 17.419077 },
    { lat: 48.393868, lng: 17.419349 },
    { lat: 48.393774, lng: 17.419587 },
    { lat: 48.393588, lng: 17.419956 },
    { lat: 48.393461, lng: 17.420239 },
    { lat: 48.393408, lng: 17.420385 },
    { lat: 48.393229, lng: 17.420881 },
    { lat: 48.393166, lng: 17.421056 },
    { lat: 48.393078, lng: 17.421263 },
    { lat: 48.392969, lng: 17.421554 },
    { lat: 48.392912, lng: 17.421687 },
    { lat: 48.392872, lng: 17.421755 },
    { lat: 48.392723, lng: 17.422086 },
    { lat: 48.392577, lng: 17.422411 },
    { lat: 48.39248, lng: 17.422625 },
    { lat: 48.392409, lng: 17.42283 },
    { lat: 48.392369, lng: 17.422922 },
    { lat: 48.392244, lng: 17.423226 },
    { lat: 48.392216, lng: 17.423331 },
    { lat: 48.392068, lng: 17.423678 },
    { lat: 48.391913, lng: 17.42403 },
    { lat: 48.391794, lng: 17.424292 },
    { lat: 48.391737, lng: 17.424427 },
    { lat: 48.391486, lng: 17.4244 },
    { lat: 48.391348, lng: 17.424297 },
    { lat: 48.391063, lng: 17.424061 },
    { lat: 48.390835, lng: 17.423881 },
    { lat: 48.390573, lng: 17.423691 },
    { lat: 48.390207, lng: 17.42342 },
    { lat: 48.389915, lng: 17.423202 },
    { lat: 48.389747, lng: 17.423067 },
    { lat: 48.389596, lng: 17.422947 },
    { lat: 48.38933, lng: 17.422724 },
    { lat: 48.38899, lng: 17.422452 },
    { lat: 48.388855, lng: 17.422359 },
    { lat: 48.388533, lng: 17.422078 },
    { lat: 48.388319, lng: 17.421903 },
    { lat: 48.38814, lng: 17.421766 },
    { lat: 48.387927, lng: 17.421611 },
    { lat: 48.387657, lng: 17.421397 },
    { lat: 48.387338, lng: 17.421144 },
    { lat: 48.387186, lng: 17.421019 },
    { lat: 48.387058, lng: 17.420914 },
    { lat: 48.386837, lng: 17.420745 },
    { lat: 48.386653, lng: 17.420609 },
    { lat: 48.386478, lng: 17.420931 },
    { lat: 48.386308, lng: 17.421251 },
    { lat: 48.386138, lng: 17.421576 },
    { lat: 48.385876, lng: 17.422067 },
    { lat: 48.385639, lng: 17.42256 },
    { lat: 48.385475, lng: 17.422896 },
    { lat: 48.385314, lng: 17.423222 },
    { lat: 48.385075, lng: 17.423706 },
    { lat: 48.384993, lng: 17.423869 },
    { lat: 48.384855, lng: 17.424225 },
    { lat: 48.384703, lng: 17.424568 },
    { lat: 48.384697, lng: 17.424582 },
    { lat: 48.384566, lng: 17.424891 },
    { lat: 48.384436, lng: 17.425204 },
    { lat: 48.384213, lng: 17.425711 },
    { lat: 48.383964, lng: 17.426288 },
    { lat: 48.383777, lng: 17.426707 },
    { lat: 48.383632, lng: 17.427028 },
    { lat: 48.383462, lng: 17.427343 },
    { lat: 48.383214, lng: 17.427795 },
    { lat: 48.383046, lng: 17.428096 },
    { lat: 48.382878, lng: 17.428413 },
    { lat: 48.382576, lng: 17.428807 },
    { lat: 48.382279, lng: 17.429206 },
    { lat: 48.3817, lng: 17.429977 },
    { lat: 48.381415, lng: 17.430381 },
    { lat: 48.381136, lng: 17.430761 },
    { lat: 48.380879, lng: 17.431119 },
    { lat: 48.380639, lng: 17.430639 },
    { lat: 48.380447, lng: 17.430217 },
    { lat: 48.38027, lng: 17.42981 },
    { lat: 48.380045, lng: 17.429314 },
    { lat: 48.379904, lng: 17.42899 },
    { lat: 48.379792, lng: 17.428736 },
    { lat: 48.379684, lng: 17.428463 },
    { lat: 48.37947, lng: 17.427946 },
    { lat: 48.379366, lng: 17.427712 },
    { lat: 48.379247, lng: 17.427419 },
    { lat: 48.379099, lng: 17.427042 },
    { lat: 48.378962, lng: 17.426663 },
    { lat: 48.378779, lng: 17.426158 },
    { lat: 48.378557, lng: 17.425591 },
    { lat: 48.378495, lng: 17.42548 },
    { lat: 48.378448, lng: 17.425489 },
    { lat: 48.378151, lng: 17.425565 },
    { lat: 48.377786, lng: 17.42565 },
    { lat: 48.377478, lng: 17.425728 },
    { lat: 48.37739, lng: 17.42575 },
    { lat: 48.377055, lng: 17.425818 },
    { lat: 48.376771, lng: 17.42589 },
    { lat: 48.376727, lng: 17.425902 },
    { lat: 48.376503, lng: 17.42596 },
    { lat: 48.37641, lng: 17.42598 },
    { lat: 48.376279, lng: 17.426007 },
    { lat: 48.376048, lng: 17.426066 },
    { lat: 48.375995, lng: 17.426071 },
    { lat: 48.37576, lng: 17.426122 },
    { lat: 48.375497, lng: 17.426181 },
    { lat: 48.375187, lng: 17.426253 },
    { lat: 48.374989, lng: 17.426309 },
    { lat: 48.374915, lng: 17.426431 },
    { lat: 48.374867, lng: 17.42651 },
    { lat: 48.374748, lng: 17.426704 },
    { lat: 48.374581, lng: 17.426945 },
    { lat: 48.374418, lng: 17.427176 },
    { lat: 48.374204, lng: 17.42755 },
    { lat: 48.373974, lng: 17.427898 },
    { lat: 48.37383, lng: 17.428093 },
    { lat: 48.373765, lng: 17.428144 },
    { lat: 48.373737, lng: 17.428166 },
    { lat: 48.373725, lng: 17.428177 },
    { lat: 48.373492, lng: 17.428537 },
    { lat: 48.373309, lng: 17.428865 },
    { lat: 48.373147, lng: 17.429227 },
    { lat: 48.372961, lng: 17.429647 },
    { lat: 48.372734, lng: 17.430147 },
    { lat: 48.372537, lng: 17.430602 },
    { lat: 48.372319, lng: 17.431128 },
    { lat: 48.372182, lng: 17.431417 },
    { lat: 48.372047, lng: 17.431813 },
    { lat: 48.371945, lng: 17.432139 },
    { lat: 48.371904, lng: 17.432323 },
    { lat: 48.371831, lng: 17.432523 },
    { lat: 48.371769, lng: 17.432755 },
    { lat: 48.371644, lng: 17.433139 },
    { lat: 48.371581, lng: 17.433299 },
    { lat: 48.371545, lng: 17.43341 },
    { lat: 48.371467, lng: 17.433657 },
    { lat: 48.371336, lng: 17.434021 },
    { lat: 48.37119, lng: 17.434431 },
    { lat: 48.371066, lng: 17.434769 },
    { lat: 48.370997, lng: 17.434996 },
    { lat: 48.370876, lng: 17.435328 },
    { lat: 48.370742, lng: 17.435682 },
    { lat: 48.370626, lng: 17.435985 },
    { lat: 48.370492, lng: 17.436316 },
    { lat: 48.370367, lng: 17.436622 },
    { lat: 48.370209, lng: 17.437008 },
    { lat: 48.370147, lng: 17.437216 },
    { lat: 48.370093, lng: 17.437279 },
    { lat: 48.370136, lng: 17.437361 },
    { lat: 48.370186, lng: 17.437461 },
    { lat: 48.370402, lng: 17.437873 },
    { lat: 48.370604, lng: 17.438254 },
    { lat: 48.37081, lng: 17.438643 },
    { lat: 48.371034, lng: 17.439078 },
    { lat: 48.371238, lng: 17.439466 },
    { lat: 48.371466, lng: 17.439908 },
    { lat: 48.371664, lng: 17.440293 },
    { lat: 48.371837, lng: 17.440616 },
    { lat: 48.372037, lng: 17.441012 },
    { lat: 48.372235, lng: 17.441397 },
    { lat: 48.372439, lng: 17.441791 },
    { lat: 48.372458, lng: 17.441826 },
    { lat: 48.372465, lng: 17.441842 },
    { lat: 48.372504, lng: 17.441916 },
    { lat: 48.372619, lng: 17.442139 },
    { lat: 48.372814, lng: 17.44252 },
    { lat: 48.37303, lng: 17.442925 },
    { lat: 48.373225, lng: 17.443298 },
    { lat: 48.37341, lng: 17.443655 },
    { lat: 48.373617, lng: 17.444057 },
    { lat: 48.373756, lng: 17.444317 },
    { lat: 48.373864, lng: 17.444519 },
    { lat: 48.374136, lng: 17.445048 },
    { lat: 48.374317, lng: 17.445379 },
    { lat: 48.374348, lng: 17.445435 },
    { lat: 48.374288, lng: 17.445551 },
    { lat: 48.374224, lng: 17.445675 },
    { lat: 48.374055, lng: 17.445991 },
    { lat: 48.373877, lng: 17.446329 },
    { lat: 48.373721, lng: 17.446616 },
    { lat: 48.373671, lng: 17.446713 },
    { lat: 48.373547, lng: 17.446949 },
    { lat: 48.37338, lng: 17.447263 },
    { lat: 48.373343, lng: 17.447333 },
    { lat: 48.373278, lng: 17.447461 },
    { lat: 48.373138, lng: 17.44773 },
    { lat: 48.373276, lng: 17.447877 },
    { lat: 48.373296, lng: 17.447898 },
    { lat: 48.373314, lng: 17.44793 },
    { lat: 48.373268, lng: 17.448033 },
    { lat: 48.373116, lng: 17.448301 },
    { lat: 48.373059, lng: 17.448432 },
    { lat: 48.372955, lng: 17.448601 },
    { lat: 48.37291, lng: 17.448727 },
    { lat: 48.372777, lng: 17.448969 },
    { lat: 48.372639, lng: 17.449076 },
    { lat: 48.372546, lng: 17.44922 },
    { lat: 48.372488, lng: 17.449405 },
    { lat: 48.372493, lng: 17.449411 },
    { lat: 48.372502, lng: 17.449422 },
    { lat: 48.372527, lng: 17.449451 },
    { lat: 48.372672, lng: 17.449621 },
    { lat: 48.372929, lng: 17.449948 },
    { lat: 48.373179, lng: 17.450268 },
    { lat: 48.373412, lng: 17.450551 },
    { lat: 48.373621, lng: 17.450825 },
    { lat: 48.373742, lng: 17.451 },
    { lat: 48.373948, lng: 17.4513 },
    { lat: 48.374021, lng: 17.451417 },
    { lat: 48.374242, lng: 17.451771 },
    { lat: 48.374481, lng: 17.452155 },
    { lat: 48.374494, lng: 17.452167 },
    { lat: 48.3746598, lng: 17.4519563 },
    { lat: 48.3754858, lng: 17.4512252 },
    { lat: 48.3757328, lng: 17.4509051 },
    { lat: 48.3768868, lng: 17.4484843 },
    { lat: 48.3775508, lng: 17.447258 },
    { lat: 48.3798758, lng: 17.4499631 },
    { lat: 48.3798569, lng: 17.4500039 },
    { lat: 48.3799152, lng: 17.4500132 },
    { lat: 48.3806917, lng: 17.4511093 },
    { lat: 48.3817978, lng: 17.4523583 },
    { lat: 48.3823502, lng: 17.4529026 },
    { lat: 48.3832546, lng: 17.4539681 },
    { lat: 48.384583, lng: 17.4553701 },
    { lat: 48.3853616, lng: 17.4564988 },
    { lat: 48.3860472, lng: 17.4577618 },
    { lat: 48.3866228, lng: 17.458559 },
    { lat: 48.3874035, lng: 17.4594061 },
    { lat: 48.3879348, lng: 17.4601845 },
    { lat: 48.3884289, lng: 17.4610334 },
    { lat: 48.3884599, lng: 17.4610069 },
    { lat: 48.3888021, lng: 17.4607088 },
    { lat: 48.3894478, lng: 17.4600003 },
    { lat: 48.3904444, lng: 17.4588176 },
    { lat: 48.3894981, lng: 17.4573834 },
    { lat: 48.3888315, lng: 17.4562575 },
    { lat: 48.3892739, lng: 17.4550764 },
    { lat: 48.3896056, lng: 17.4539522 },
    { lat: 48.389806, lng: 17.4528898 },
    { lat: 48.389775, lng: 17.4527425 },
    { lat: 48.3898339, lng: 17.4517994 },
    { lat: 48.3900657, lng: 17.450839 },
    { lat: 48.3901725, lng: 17.4504809 },
    { lat: 48.3911571, lng: 17.4480645 },
    { lat: 48.3913033, lng: 17.4475642 },
    { lat: 48.3913277, lng: 17.4470468 },
    { lat: 48.3912422, lng: 17.4464928 },
    { lat: 48.3912708, lng: 17.4463987 },
    { lat: 48.3915756, lng: 17.4453945 },
    { lat: 48.3917859, lng: 17.4444948 },
    { lat: 48.3918367, lng: 17.4442773 },
    { lat: 48.392551, lng: 17.4425553 },
    { lat: 48.3929484, lng: 17.4412391 },
    { lat: 48.3931343, lng: 17.4405489 },
    { lat: 48.3932169, lng: 17.4399739 },
    { lat: 48.3933856, lng: 17.4394931 },
    { lat: 48.3938524, lng: 17.438306 },
    { lat: 48.3941983, lng: 17.4375612 },
    { lat: 48.3950634, lng: 17.4359681 },
    { lat: 48.3954996, lng: 17.4354667 },
    { lat: 48.3958256, lng: 17.4347247 },
    { lat: 48.3958711, lng: 17.4344842 },
    { lat: 48.396194, lng: 17.4339062 },
    { lat: 48.3965637, lng: 17.4333903 },
    { lat: 48.3968483, lng: 17.4328296 },
    { lat: 48.3976203, lng: 17.4323492 },
    { lat: 48.397756, lng: 17.4322107 },
    { lat: 48.3981019, lng: 17.431641 },
    { lat: 48.3983531, lng: 17.4314384 },
    { lat: 48.3984095, lng: 17.4312443 },
    { lat: 48.3987708, lng: 17.4308219 },
    { lat: 48.3988848, lng: 17.4303678 },
    { lat: 48.3990662, lng: 17.4299774 },
    { lat: 48.3993645, lng: 17.4296304 },
    { lat: 48.3995112, lng: 17.4295571 },
    { lat: 48.4000359, lng: 17.4289207 },
    { lat: 48.4001159, lng: 17.4289016 },
    { lat: 48.4002563, lng: 17.4287311 },
    { lat: 48.400396, lng: 17.4287616 },
    { lat: 48.4005526, lng: 17.428669 },
    { lat: 48.4006289, lng: 17.4285893 },
    { lat: 48.4006543, lng: 17.4284591 },
    { lat: 48.4011295, lng: 17.4283144 },
    { lat: 48.4012213, lng: 17.4280916 },
    { lat: 48.4013131, lng: 17.4280434 },
    { lat: 48.401358, lng: 17.4278478 },
    { lat: 48.4014716, lng: 17.4277708 },
    { lat: 48.4015451, lng: 17.4276346 },
    { lat: 48.4018486, lng: 17.4275217 },
    { lat: 48.4020406, lng: 17.4273138 },
    { lat: 48.4021818, lng: 17.4272431 },
    { lat: 48.402468, lng: 17.4271818 },
    { lat: 48.4029408, lng: 17.4269138 },
    { lat: 48.4031719, lng: 17.4269223 },
    { lat: 48.40338, lng: 17.4266826 },
    { lat: 48.4037023, lng: 17.4266185 },
    { lat: 48.404046, lng: 17.4261877 },
    { lat: 48.4042024, lng: 17.4261104 },
    { lat: 48.4045364, lng: 17.4262618 },
    { lat: 48.4051581, lng: 17.4264068 },
    { lat: 48.4053799, lng: 17.426433 },
    { lat: 48.4054428, lng: 17.42634 },
    { lat: 48.4052115, lng: 17.4260794 },
    { lat: 48.4052749, lng: 17.4258046 },
    { lat: 48.4051275, lng: 17.4255869 },
    { lat: 48.4046907, lng: 17.4251519 },
    { lat: 48.4045752, lng: 17.4246438 },
    { lat: 48.4045389, lng: 17.4240324 },
    { lat: 48.404617, lng: 17.4238048 },
    { lat: 48.404612, lng: 17.4226318 },
    { lat: 48.4047466, lng: 17.4221956 },
    { lat: 48.404765, lng: 17.4211556 },
    { lat: 48.404732, lng: 17.4208541 },
    { lat: 48.4047888, lng: 17.4205991 },
    { lat: 48.4049076, lng: 17.4203748 },
    { lat: 48.4049621, lng: 17.4199087 },
    { lat: 48.4050579, lng: 17.4197465 },
    { lat: 48.4050061, lng: 17.4193673 },
    { lat: 48.4051461, lng: 17.4187038 },
    { lat: 48.4051464, lng: 17.4183941 },
    { lat: 48.4052068, lng: 17.4181759 },
    { lat: 48.4051924, lng: 17.4180924 },
    { lat: 48.4050676, lng: 17.4179595 },
    { lat: 48.404998, lng: 17.417223 },
    { lat: 48.404788, lng: 17.417068 },
    { lat: 48.404597, lng: 17.416939 },
    { lat: 48.404519, lng: 17.416907 },
    { lat: 48.40441, lng: 17.416997 },
    { lat: 48.404239, lng: 17.416946 },
    { lat: 48.404, lng: 17.416894 },
    { lat: 48.403948, lng: 17.416872 },
    { lat: 48.403732, lng: 17.416781 },
    { lat: 48.40369, lng: 17.416752 },
    { lat: 48.403519, lng: 17.416625 },
    { lat: 48.403452, lng: 17.416522 },
    { lat: 48.403422, lng: 17.416475 },
    { lat: 48.40339, lng: 17.416427 },
    { lat: 48.403363, lng: 17.416385 },
    { lat: 48.403281, lng: 17.416225 },
    { lat: 48.403172, lng: 17.41601 },
    { lat: 48.40315, lng: 17.415981 },
    { lat: 48.403063, lng: 17.415846 },
    { lat: 48.402988, lng: 17.415741 },
    { lat: 48.402738, lng: 17.415472 },
    { lat: 48.40261, lng: 17.415216 },
    { lat: 48.402563, lng: 17.415105 },
    { lat: 48.402542, lng: 17.415009 },
    { lat: 48.402419, lng: 17.414641 },
    { lat: 48.402309, lng: 17.414243 },
    { lat: 48.402237, lng: 17.413841 },
    { lat: 48.402196, lng: 17.41362 },
    { lat: 48.402152, lng: 17.413401 },
    { lat: 48.402036, lng: 17.413099 },
    { lat: 48.401852, lng: 17.412674 },
    { lat: 48.401733, lng: 17.412367 },
    { lat: 48.401639, lng: 17.412161 },
    { lat: 48.401481, lng: 17.411871 },
    { lat: 48.401247, lng: 17.411548 },
    { lat: 48.401074, lng: 17.411376 },
    { lat: 48.400899, lng: 17.411138 },
    { lat: 48.400763, lng: 17.410866 },
    { lat: 48.400578, lng: 17.410615 },
    { lat: 48.40039, lng: 17.41098 },
    { lat: 48.400263, lng: 17.411213 },
  ],
  HrnčiarovcenadParnou: [
    { lat: 48.3373658, lng: 17.5932942 },
    { lat: 48.337495, lng: 17.5931409 },
    { lat: 48.3381527, lng: 17.5919718 },
    { lat: 48.3383784, lng: 17.5912199 },
    { lat: 48.3386322, lng: 17.5905917 },
    { lat: 48.3391446, lng: 17.5896768 },
    { lat: 48.3393357, lng: 17.5892162 },
    { lat: 48.3399763, lng: 17.5882085 },
    { lat: 48.3405733, lng: 17.5870241 },
    { lat: 48.3411969, lng: 17.5860493 },
    { lat: 48.3417935, lng: 17.5849867 },
    { lat: 48.3423696, lng: 17.5835962 },
    { lat: 48.3427873, lng: 17.581797 },
    { lat: 48.3429238, lng: 17.5813863 },
    { lat: 48.3431202, lng: 17.5810278 },
    { lat: 48.3439276, lng: 17.5799421 },
    { lat: 48.3443592, lng: 17.5790013 },
    { lat: 48.344647, lng: 17.57864 },
    { lat: 48.345287, lng: 17.5781759 },
    { lat: 48.345844, lng: 17.5774475 },
    { lat: 48.3466984, lng: 17.5754664 },
    { lat: 48.3467122, lng: 17.5753434 },
    { lat: 48.3482736, lng: 17.5733653 },
    { lat: 48.3492106, lng: 17.5723265 },
    { lat: 48.349295, lng: 17.5722554 },
    { lat: 48.3494817, lng: 17.5720982 },
    { lat: 48.349562, lng: 17.5718876 },
    { lat: 48.3493354, lng: 17.5715348 },
    { lat: 48.3507172, lng: 17.569378 },
    { lat: 48.3512968, lng: 17.5686902 },
    { lat: 48.3518004, lng: 17.5682944 },
    { lat: 48.3540396, lng: 17.5658937 },
    { lat: 48.3539018, lng: 17.5656102 },
    { lat: 48.3539993, lng: 17.5654929 },
    { lat: 48.3551219, lng: 17.5641419 },
    { lat: 48.3554854, lng: 17.5637994 },
    { lat: 48.3560767, lng: 17.563103 },
    { lat: 48.3559418, lng: 17.5628269 },
    { lat: 48.3565793, lng: 17.5621602 },
    { lat: 48.3568695, lng: 17.5627652 },
    { lat: 48.3580695, lng: 17.561443 },
    { lat: 48.3581717, lng: 17.5613596 },
    { lat: 48.3586234, lng: 17.5609909 },
    { lat: 48.359135, lng: 17.5603459 },
    { lat: 48.3599659, lng: 17.5592982 },
    { lat: 48.3601363, lng: 17.5592084 },
    { lat: 48.3605928, lng: 17.5587848 },
    { lat: 48.3609883, lng: 17.5589128 },
    { lat: 48.3610861, lng: 17.5588725 },
    { lat: 48.361354, lng: 17.558575 },
    { lat: 48.3615319, lng: 17.5580683 },
    { lat: 48.361837, lng: 17.5579838 },
    { lat: 48.362033, lng: 17.5576542 },
    { lat: 48.362204, lng: 17.5578041 },
    { lat: 48.3624548, lng: 17.5576286 },
    { lat: 48.3624729, lng: 17.557381 },
    { lat: 48.3623428, lng: 17.5570733 },
    { lat: 48.362476, lng: 17.5564704 },
    { lat: 48.3628256, lng: 17.5565162 },
    { lat: 48.3628037, lng: 17.5563322 },
    { lat: 48.3629479, lng: 17.5561844 },
    { lat: 48.3627743, lng: 17.5559593 },
    { lat: 48.362921, lng: 17.5557426 },
    { lat: 48.3630785, lng: 17.5558412 },
    { lat: 48.3631454, lng: 17.5558071 },
    { lat: 48.363227, lng: 17.5556359 },
    { lat: 48.3631005, lng: 17.5554772 },
    { lat: 48.3632269, lng: 17.5553693 },
    { lat: 48.3632624, lng: 17.5551802 },
    { lat: 48.3630065, lng: 17.5550446 },
    { lat: 48.3630208, lng: 17.5547184 },
    { lat: 48.3631589, lng: 17.5544933 },
    { lat: 48.3631951, lng: 17.5545119 },
    { lat: 48.3632073, lng: 17.5547566 },
    { lat: 48.3633913, lng: 17.5547924 },
    { lat: 48.3634689, lng: 17.5543601 },
    { lat: 48.3637752, lng: 17.5541873 },
    { lat: 48.3625648, lng: 17.5525238 },
    { lat: 48.3620008, lng: 17.5518759 },
    { lat: 48.3611263, lng: 17.5506991 },
    { lat: 48.3609462, lng: 17.5505662 },
    { lat: 48.3609392, lng: 17.5505072 },
    { lat: 48.3607901, lng: 17.5504369 },
    { lat: 48.360676, lng: 17.5505261 },
    { lat: 48.3607354, lng: 17.5510499 },
    { lat: 48.3606255, lng: 17.5511157 },
    { lat: 48.3602991, lng: 17.5508837 },
    { lat: 48.3602673, lng: 17.5511493 },
    { lat: 48.360374, lng: 17.5516183 },
    { lat: 48.3602561, lng: 17.5517361 },
    { lat: 48.3599972, lng: 17.5517462 },
    { lat: 48.3598872, lng: 17.5519008 },
    { lat: 48.3598395, lng: 17.5527315 },
    { lat: 48.3597392, lng: 17.5530185 },
    { lat: 48.3597418, lng: 17.5533079 },
    { lat: 48.3596136, lng: 17.5535102 },
    { lat: 48.3593034, lng: 17.5536214 },
    { lat: 48.3582653, lng: 17.5511159 },
    { lat: 48.3580897, lng: 17.5504512 },
    { lat: 48.3551143, lng: 17.5443736 },
    { lat: 48.3522858, lng: 17.5396323 },
    { lat: 48.3508719, lng: 17.5373649 },
    { lat: 48.3490329, lng: 17.5340481 },
    { lat: 48.3476454, lng: 17.531364 },
    { lat: 48.3470695, lng: 17.530376 },
    { lat: 48.3458193, lng: 17.5286226 },
    { lat: 48.3464403, lng: 17.5280758 },
    { lat: 48.3476055, lng: 17.5274333 },
    { lat: 48.3477902, lng: 17.5272828 },
    { lat: 48.3480377, lng: 17.5269348 },
    { lat: 48.3484136, lng: 17.5265428 },
    { lat: 48.3495072, lng: 17.5265474 },
    { lat: 48.35002, lng: 17.5263363 },
    { lat: 48.3501709, lng: 17.5261715 },
    { lat: 48.3504343, lng: 17.525698 },
    { lat: 48.3507819, lng: 17.5253043 },
    { lat: 48.3509244, lng: 17.5249899 },
    { lat: 48.3510415, lng: 17.5248974 },
    { lat: 48.3512483, lng: 17.5244036 },
    { lat: 48.3514889, lng: 17.5241766 },
    { lat: 48.3516418, lng: 17.5238996 },
    { lat: 48.3519356, lng: 17.5235499 },
    { lat: 48.3522145, lng: 17.5230361 },
    { lat: 48.3525368, lng: 17.5227075 },
    { lat: 48.3526826, lng: 17.522656 },
    { lat: 48.3527255, lng: 17.5225671 },
    { lat: 48.3523952, lng: 17.5220977 },
    { lat: 48.3518042, lng: 17.5213348 },
    { lat: 48.3497498, lng: 17.518062 },
    { lat: 48.3485837, lng: 17.5164096 },
    { lat: 48.347488, lng: 17.5150677 },
    { lat: 48.3465766, lng: 17.5140945 },
    { lat: 48.3463379, lng: 17.5139297 },
    { lat: 48.346327, lng: 17.5139551 },
    { lat: 48.3456443, lng: 17.5144219 },
    { lat: 48.3443642, lng: 17.5154569 },
    { lat: 48.3421431, lng: 17.5187252 },
    { lat: 48.3398237, lng: 17.521061 },
    { lat: 48.3375383, lng: 17.524299 },
    { lat: 48.3356551, lng: 17.5266067 },
    { lat: 48.3353185, lng: 17.5270988 },
    { lat: 48.3333962, lng: 17.5292881 },
    { lat: 48.3308413, lng: 17.532571 },
    { lat: 48.3264108, lng: 17.5356661 },
    { lat: 48.3238076, lng: 17.5382647 },
    { lat: 48.322285, lng: 17.5397474 },
    { lat: 48.3216436, lng: 17.5404558 },
    { lat: 48.3195061, lng: 17.5433621 },
    { lat: 48.3188568, lng: 17.5439835 },
    { lat: 48.3181622, lng: 17.5450094 },
    { lat: 48.3173643, lng: 17.5460658 },
    { lat: 48.3161181, lng: 17.5480222 },
    { lat: 48.3157483, lng: 17.5487397 },
    { lat: 48.3151811, lng: 17.5495678 },
    { lat: 48.3138851, lng: 17.5508131 },
    { lat: 48.313106, lng: 17.5518974 },
    { lat: 48.3121081, lng: 17.5525625 },
    { lat: 48.3108317, lng: 17.5537633 },
    { lat: 48.3137602, lng: 17.5593696 },
    { lat: 48.316233, lng: 17.5645845 },
    { lat: 48.3178018, lng: 17.5671142 },
    { lat: 48.3221174, lng: 17.573804 },
    { lat: 48.3242333, lng: 17.5766091 },
    { lat: 48.3263162, lng: 17.579679 },
    { lat: 48.3276135, lng: 17.581371 },
    { lat: 48.3294162, lng: 17.5835978 },
    { lat: 48.3303556, lng: 17.5847582 },
    { lat: 48.3306846, lng: 17.5841744 },
    { lat: 48.33512, lng: 17.5890014 },
    { lat: 48.334775, lng: 17.5897557 },
    { lat: 48.3353406, lng: 17.5904527 },
    { lat: 48.3370304, lng: 17.592811 },
    { lat: 48.3373658, lng: 17.5932942 },
  ],
  HornáKrupá: [
    { lat: 48.5140739, lng: 17.5049421 },
    { lat: 48.5139102, lng: 17.5052618 },
    { lat: 48.5136715, lng: 17.5053779 },
    { lat: 48.5135862, lng: 17.5052983 },
    { lat: 48.5131404, lng: 17.5054444 },
    { lat: 48.5126381, lng: 17.5059605 },
    { lat: 48.512259, lng: 17.5064463 },
    { lat: 48.5120101, lng: 17.5065436 },
    { lat: 48.5116709, lng: 17.5068025 },
    { lat: 48.5115994, lng: 17.5069581 },
    { lat: 48.5112428, lng: 17.5072801 },
    { lat: 48.5111034, lng: 17.5075738 },
    { lat: 48.5108198, lng: 17.507676 },
    { lat: 48.5103568, lng: 17.5081035 },
    { lat: 48.5096288, lng: 17.508993 },
    { lat: 48.5093133, lng: 17.5091629 },
    { lat: 48.5085705, lng: 17.5098235 },
    { lat: 48.5083078, lng: 17.5099202 },
    { lat: 48.5082071, lng: 17.5100213 },
    { lat: 48.5080636, lng: 17.5103904 },
    { lat: 48.5077429, lng: 17.5108346 },
    { lat: 48.5070977, lng: 17.5115711 },
    { lat: 48.5066355, lng: 17.5119755 },
    { lat: 48.5063919, lng: 17.5123529 },
    { lat: 48.5059004, lng: 17.5129126 },
    { lat: 48.5054817, lng: 17.5132203 },
    { lat: 48.5052824, lng: 17.5135126 },
    { lat: 48.5046343, lng: 17.5140115 },
    { lat: 48.5040239, lng: 17.5152596 },
    { lat: 48.5038488, lng: 17.5154007 },
    { lat: 48.5035813, lng: 17.5157936 },
    { lat: 48.5032822, lng: 17.5159907 },
    { lat: 48.5032264, lng: 17.5161252 },
    { lat: 48.503, lng: 17.5163117 },
    { lat: 48.5026757, lng: 17.5169255 },
    { lat: 48.5019471, lng: 17.5176133 },
    { lat: 48.5015346, lng: 17.5183178 },
    { lat: 48.5015857, lng: 17.5184101 },
    { lat: 48.5027024, lng: 17.5202506 },
    { lat: 48.5041619, lng: 17.5225049 },
    { lat: 48.5044794, lng: 17.5231286 },
    { lat: 48.5049799, lng: 17.5245692 },
    { lat: 48.504931, lng: 17.5246008 },
    { lat: 48.5060282, lng: 17.5289896 },
    { lat: 48.5060534, lng: 17.529137 },
    { lat: 48.5060138, lng: 17.5292769 },
    { lat: 48.5064825, lng: 17.5314946 },
    { lat: 48.5062983, lng: 17.5317947 },
    { lat: 48.5064287, lng: 17.5320697 },
    { lat: 48.5065072, lng: 17.5324697 },
    { lat: 48.5070525, lng: 17.5384494 },
    { lat: 48.5074363, lng: 17.540028 },
    { lat: 48.5075856, lng: 17.5404399 },
    { lat: 48.5076518, lng: 17.5410914 },
    { lat: 48.5077818, lng: 17.5415919 },
    { lat: 48.5079087, lng: 17.5417047 },
    { lat: 48.5078927, lng: 17.5422858 },
    { lat: 48.5077988, lng: 17.5428157 },
    { lat: 48.5074776, lng: 17.5456536 },
    { lat: 48.5075174, lng: 17.5462273 },
    { lat: 48.5075999, lng: 17.5466546 },
    { lat: 48.5079327, lng: 17.5477328 },
    { lat: 48.5082842, lng: 17.5487709 },
    { lat: 48.5085295, lng: 17.5492717 },
    { lat: 48.5087206, lng: 17.5491331 },
    { lat: 48.5089509, lng: 17.5490625 },
    { lat: 48.5090978, lng: 17.548868 },
    { lat: 48.5092361, lng: 17.5488057 },
    { lat: 48.5094494, lng: 17.5485165 },
    { lat: 48.5098117, lng: 17.54823 },
    { lat: 48.5099625, lng: 17.5482753 },
    { lat: 48.5101663, lng: 17.5482128 },
    { lat: 48.5103771, lng: 17.5483125 },
    { lat: 48.5105501, lng: 17.5481373 },
    { lat: 48.5109969, lng: 17.5479737 },
    { lat: 48.5113073, lng: 17.5476673 },
    { lat: 48.5119821, lng: 17.5471809 },
    { lat: 48.5120662, lng: 17.5469929 },
    { lat: 48.5121551, lng: 17.5469495 },
    { lat: 48.5125453, lng: 17.5464463 },
    { lat: 48.5128062, lng: 17.5459312 },
    { lat: 48.5131788, lng: 17.5455189 },
    { lat: 48.5137886, lng: 17.5443306 },
    { lat: 48.5143497, lng: 17.5437757 },
    { lat: 48.5146712, lng: 17.5433605 },
    { lat: 48.515327, lng: 17.5422555 },
    { lat: 48.5155914, lng: 17.5420953 },
    { lat: 48.5160176, lng: 17.5420475 },
    { lat: 48.5167072, lng: 17.5414374 },
    { lat: 48.5177793, lng: 17.5403252 },
    { lat: 48.5183795, lng: 17.5396232 },
    { lat: 48.518411, lng: 17.5396568 },
    { lat: 48.5184554, lng: 17.5395742 },
    { lat: 48.519472, lng: 17.5381026 },
    { lat: 48.5198551, lng: 17.5378935 },
    { lat: 48.5199613, lng: 17.5377514 },
    { lat: 48.5203471, lng: 17.536982 },
    { lat: 48.5209813, lng: 17.5360841 },
    { lat: 48.5230811, lng: 17.5340498 },
    { lat: 48.5254481, lng: 17.5320605 },
    { lat: 48.5270848, lng: 17.5305059 },
    { lat: 48.5288991, lng: 17.528669 },
    { lat: 48.530399, lng: 17.5267384 },
    { lat: 48.5315819, lng: 17.5255408 },
    { lat: 48.5328343, lng: 17.524672 },
    { lat: 48.533766, lng: 17.5236424 },
    { lat: 48.5342328, lng: 17.5229568 },
    { lat: 48.5350934, lng: 17.5214128 },
    { lat: 48.5355007, lng: 17.519723 },
    { lat: 48.5356341, lng: 17.5188571 },
    { lat: 48.5358231, lng: 17.5181545 },
    { lat: 48.5362049, lng: 17.5172338 },
    { lat: 48.5368975, lng: 17.5159816 },
    { lat: 48.5370601, lng: 17.5159267 },
    { lat: 48.5379975, lng: 17.5150956 },
    { lat: 48.538914, lng: 17.5145932 },
    { lat: 48.5397051, lng: 17.5142482 },
    { lat: 48.5409127, lng: 17.5133484 },
    { lat: 48.5413271, lng: 17.5129591 },
    { lat: 48.5413301, lng: 17.5127573 },
    { lat: 48.5417309, lng: 17.5123294 },
    { lat: 48.5414749, lng: 17.5116437 },
    { lat: 48.5421444, lng: 17.5111096 },
    { lat: 48.5423895, lng: 17.5108115 },
    { lat: 48.5424948, lng: 17.5107606 },
    { lat: 48.5428989, lng: 17.510247 },
    { lat: 48.5431631, lng: 17.5100473 },
    { lat: 48.5437213, lng: 17.5098942 },
    { lat: 48.5440334, lng: 17.5096348 },
    { lat: 48.5445514, lng: 17.5094341 },
    { lat: 48.5450044, lng: 17.5091711 },
    { lat: 48.5451742, lng: 17.508434 },
    { lat: 48.5452718, lng: 17.5066703 },
    { lat: 48.5454655, lng: 17.505018 },
    { lat: 48.5453648, lng: 17.5050173 },
    { lat: 48.5453295, lng: 17.505134 },
    { lat: 48.5451507, lng: 17.5050772 },
    { lat: 48.54516, lng: 17.5049746 },
    { lat: 48.5451188, lng: 17.5049485 },
    { lat: 48.5449316, lng: 17.5051634 },
    { lat: 48.544833, lng: 17.5050162 },
    { lat: 48.5448382, lng: 17.50492 },
    { lat: 48.5447601, lng: 17.5050079 },
    { lat: 48.5447066, lng: 17.5049818 },
    { lat: 48.5446731, lng: 17.5050579 },
    { lat: 48.5444037, lng: 17.5050673 },
    { lat: 48.5443402, lng: 17.5049735 },
    { lat: 48.544235, lng: 17.5049717 },
    { lat: 48.5441685, lng: 17.5050647 },
    { lat: 48.5440596, lng: 17.504986 },
    { lat: 48.544026, lng: 17.5051124 },
    { lat: 48.5439581, lng: 17.5050934 },
    { lat: 48.5438965, lng: 17.5049772 },
    { lat: 48.5438546, lng: 17.5052224 },
    { lat: 48.5436652, lng: 17.5053518 },
    { lat: 48.5435546, lng: 17.5051236 },
    { lat: 48.5434386, lng: 17.5050937 },
    { lat: 48.543257, lng: 17.5051518 },
    { lat: 48.5431805, lng: 17.5050648 },
    { lat: 48.543055, lng: 17.5050124 },
    { lat: 48.5430005, lng: 17.5050595 },
    { lat: 48.5425927, lng: 17.504899 },
    { lat: 48.5422221, lng: 17.504684 },
    { lat: 48.5420434, lng: 17.5046607 },
    { lat: 48.5419578, lng: 17.5048074 },
    { lat: 48.5418268, lng: 17.5047191 },
    { lat: 48.5415267, lng: 17.5048299 },
    { lat: 48.5414505, lng: 17.5047158 },
    { lat: 48.5412971, lng: 17.5046999 },
    { lat: 48.5412751, lng: 17.5047633 },
    { lat: 48.5409857, lng: 17.5047753 },
    { lat: 48.5409267, lng: 17.5046659 },
    { lat: 48.5406699, lng: 17.5046214 },
    { lat: 48.5406296, lng: 17.5044341 },
    { lat: 48.5404573, lng: 17.5045118 },
    { lat: 48.5403977, lng: 17.5046209 },
    { lat: 48.5403275, lng: 17.5046368 },
    { lat: 48.5402976, lng: 17.5045766 },
    { lat: 48.5400558, lng: 17.5046958 },
    { lat: 48.5400252, lng: 17.5048203 },
    { lat: 48.5398929, lng: 17.5048352 },
    { lat: 48.5397947, lng: 17.5047125 },
    { lat: 48.5397067, lng: 17.5047327 },
    { lat: 48.539516, lng: 17.5045554 },
    { lat: 48.539511, lng: 17.5047433 },
    { lat: 48.5390712, lng: 17.505194 },
    { lat: 48.5391228, lng: 17.5053497 },
    { lat: 48.539076, lng: 17.5055368 },
    { lat: 48.5389614, lng: 17.5056357 },
    { lat: 48.5389356, lng: 17.5057952 },
    { lat: 48.5387029, lng: 17.5058492 },
    { lat: 48.5387456, lng: 17.505951 },
    { lat: 48.5386204, lng: 17.5060771 },
    { lat: 48.538571, lng: 17.5062592 },
    { lat: 48.5383917, lng: 17.506406 },
    { lat: 48.5382884, lng: 17.5067484 },
    { lat: 48.5382108, lng: 17.5067561 },
    { lat: 48.538125, lng: 17.5066495 },
    { lat: 48.5380646, lng: 17.5066746 },
    { lat: 48.5378751, lng: 17.5065396 },
    { lat: 48.5377708, lng: 17.5066217 },
    { lat: 48.5377507, lng: 17.5067293 },
    { lat: 48.5376454, lng: 17.5067446 },
    { lat: 48.5376276, lng: 17.5069581 },
    { lat: 48.5374606, lng: 17.5072271 },
    { lat: 48.5369549, lng: 17.5073573 },
    { lat: 48.5369637, lng: 17.5073874 },
    { lat: 48.5369305, lng: 17.5074492 },
    { lat: 48.5363683, lng: 17.5076667 },
    { lat: 48.5363151, lng: 17.5076812 },
    { lat: 48.5362972, lng: 17.5075968 },
    { lat: 48.5357998, lng: 17.507605 },
    { lat: 48.5354144, lng: 17.5078147 },
    { lat: 48.5344352, lng: 17.5052842 },
    { lat: 48.5282689, lng: 17.50104 },
    { lat: 48.5278562, lng: 17.4995118 },
    { lat: 48.5274135, lng: 17.4996354 },
    { lat: 48.5262217, lng: 17.5003018 },
    { lat: 48.5244759, lng: 17.5010641 },
    { lat: 48.5233487, lng: 17.5013268 },
    { lat: 48.5227153, lng: 17.5011702 },
    { lat: 48.5219062, lng: 17.5007551 },
    { lat: 48.5205565, lng: 17.5007026 },
    { lat: 48.5192655, lng: 17.5002059 },
    { lat: 48.5191543, lng: 17.5004244 },
    { lat: 48.5180699, lng: 17.5011314 },
    { lat: 48.5165493, lng: 17.5027828 },
    { lat: 48.5163175, lng: 17.503169 },
    { lat: 48.5159085, lng: 17.5035727 },
    { lat: 48.515667, lng: 17.503593 },
    { lat: 48.5153519, lng: 17.5040355 },
    { lat: 48.5150806, lng: 17.5039422 },
    { lat: 48.5149752, lng: 17.5040765 },
    { lat: 48.5150406, lng: 17.5042191 },
    { lat: 48.515022, lng: 17.5042991 },
    { lat: 48.5145983, lng: 17.5047896 },
    { lat: 48.5145361, lng: 17.5048235 },
    { lat: 48.5143939, lng: 17.5046511 },
    { lat: 48.5142497, lng: 17.5048486 },
    { lat: 48.5140739, lng: 17.5049421 },
  ],
  Malženice: [
    { lat: 48.470602, lng: 17.685286 },
    { lat: 48.470859, lng: 17.685298 },
    { lat: 48.471188, lng: 17.685284 },
    { lat: 48.471606, lng: 17.685252 },
    { lat: 48.471931, lng: 17.685179 },
    { lat: 48.472266, lng: 17.685083 },
    { lat: 48.472784, lng: 17.684933 },
    { lat: 48.473467, lng: 17.684745 },
    { lat: 48.473652, lng: 17.684715 },
    { lat: 48.47394, lng: 17.684672 },
    { lat: 48.474295, lng: 17.68465 },
    { lat: 48.474621, lng: 17.68465 },
    { lat: 48.474846, lng: 17.684676 },
    { lat: 48.475255, lng: 17.684723 },
    { lat: 48.475484, lng: 17.684742 },
    { lat: 48.475769, lng: 17.684726 },
    { lat: 48.476089, lng: 17.68469 },
    { lat: 48.476341, lng: 17.684659 },
    { lat: 48.476524, lng: 17.684654 },
    { lat: 48.476621, lng: 17.684665 },
    { lat: 48.476705, lng: 17.684681 },
    { lat: 48.476839, lng: 17.684745 },
    { lat: 48.476861, lng: 17.684759 },
    { lat: 48.4767983, lng: 17.6845723 },
    { lat: 48.4747792, lng: 17.6801108 },
    { lat: 48.4744675, lng: 17.6793321 },
    { lat: 48.4717484, lng: 17.6717165 },
    { lat: 48.4707589, lng: 17.6694314 },
    { lat: 48.4700569, lng: 17.6676288 },
    { lat: 48.4688003, lng: 17.6649574 },
    { lat: 48.4674967, lng: 17.6625099 },
    { lat: 48.4668714, lng: 17.6616448 },
    { lat: 48.4668194, lng: 17.662278 },
    { lat: 48.4669158, lng: 17.662374 },
    { lat: 48.466912, lng: 17.6626043 },
    { lat: 48.4665291, lng: 17.6627282 },
    { lat: 48.4664306, lng: 17.6629044 },
    { lat: 48.4663086, lng: 17.6628654 },
    { lat: 48.466159, lng: 17.6631474 },
    { lat: 48.4659326, lng: 17.6630453 },
    { lat: 48.4658371, lng: 17.6632359 },
    { lat: 48.4656989, lng: 17.6632096 },
    { lat: 48.4655522, lng: 17.6634104 },
    { lat: 48.4654433, lng: 17.6634208 },
    { lat: 48.4653609, lng: 17.6636014 },
    { lat: 48.4652569, lng: 17.6636706 },
    { lat: 48.4651924, lng: 17.6638697 },
    { lat: 48.4650453, lng: 17.6640075 },
    { lat: 48.4650608, lng: 17.6640726 },
    { lat: 48.4647037, lng: 17.6643411 },
    { lat: 48.4644562, lng: 17.6646818 },
    { lat: 48.4642486, lng: 17.664392 },
    { lat: 48.4640433, lng: 17.6646508 },
    { lat: 48.4637004, lng: 17.6652707 },
    { lat: 48.4632943, lng: 17.6645006 },
    { lat: 48.4627936, lng: 17.6638947 },
    { lat: 48.4625375, lng: 17.6634844 },
    { lat: 48.4623211, lng: 17.6628053 },
    { lat: 48.4623074, lng: 17.662529 },
    { lat: 48.462037, lng: 17.6620884 },
    { lat: 48.4611702, lng: 17.6596773 },
    { lat: 48.460493, lng: 17.6584173 },
    { lat: 48.4588804, lng: 17.6559063 },
    { lat: 48.4572149, lng: 17.6537262 },
    { lat: 48.4567647, lng: 17.6530625 },
    { lat: 48.4551362, lng: 17.6501741 },
    { lat: 48.4535018, lng: 17.6479534 },
    { lat: 48.4522855, lng: 17.6460754 },
    { lat: 48.4516351, lng: 17.6449401 },
    { lat: 48.4508002, lng: 17.6432942 },
    { lat: 48.4489784, lng: 17.6402308 },
    { lat: 48.4467095, lng: 17.6369134 },
    { lat: 48.4455519, lng: 17.6349672 },
    { lat: 48.4448859, lng: 17.6350599 },
    { lat: 48.4436115, lng: 17.6328946 },
    { lat: 48.4433885, lng: 17.6325603 },
    { lat: 48.4433426, lng: 17.632565 },
    { lat: 48.4342461, lng: 17.6374068 },
    { lat: 48.4332993, lng: 17.6377025 },
    { lat: 48.4315509, lng: 17.6385389 },
    { lat: 48.430396, lng: 17.6388861 },
    { lat: 48.4290094, lng: 17.6394425 },
    { lat: 48.4272788, lng: 17.6402763 },
    { lat: 48.4250139, lng: 17.6412344 },
    { lat: 48.4241453, lng: 17.6417691 },
    { lat: 48.4237227, lng: 17.6421045 },
    { lat: 48.4232584, lng: 17.642357 },
    { lat: 48.4224261, lng: 17.6426793 },
    { lat: 48.4221891, lng: 17.6427698 },
    { lat: 48.4222618, lng: 17.6429756 },
    { lat: 48.4231751, lng: 17.6448785 },
    { lat: 48.4245751, lng: 17.6475221 },
    { lat: 48.4251972, lng: 17.6487981 },
    { lat: 48.4255641, lng: 17.6496783 },
    { lat: 48.4259983, lng: 17.6510037 },
    { lat: 48.4259934, lng: 17.6515938 },
    { lat: 48.4261037, lng: 17.6524521 },
    { lat: 48.4262155, lng: 17.6539113 },
    { lat: 48.4262262, lng: 17.6576849 },
    { lat: 48.4262636, lng: 17.6583199 },
    { lat: 48.4256564, lng: 17.6590002 },
    { lat: 48.4251979, lng: 17.659514 },
    { lat: 48.4253954, lng: 17.6600723 },
    { lat: 48.4272049, lng: 17.6651851 },
    { lat: 48.427887, lng: 17.66842 },
    { lat: 48.4296425, lng: 17.6755152 },
    { lat: 48.4300915, lng: 17.6778595 },
    { lat: 48.4305791, lng: 17.6800708 },
    { lat: 48.4306978, lng: 17.680609 },
    { lat: 48.4327004, lng: 17.6853591 },
    { lat: 48.4339608, lng: 17.6859442 },
    { lat: 48.432634, lng: 17.689601 },
    { lat: 48.432729, lng: 17.689598 },
    { lat: 48.43282, lng: 17.689538 },
    { lat: 48.432854, lng: 17.689514 },
    { lat: 48.432966, lng: 17.68938 },
    { lat: 48.433214, lng: 17.689181 },
    { lat: 48.433436, lng: 17.68891 },
    { lat: 48.43394, lng: 17.688737 },
    { lat: 48.434254, lng: 17.688621 },
    { lat: 48.435139, lng: 17.688386 },
    { lat: 48.435752, lng: 17.688195 },
    { lat: 48.435895, lng: 17.688522 },
    { lat: 48.436133, lng: 17.688396 },
    { lat: 48.436265, lng: 17.688386 },
    { lat: 48.436522, lng: 17.68826 },
    { lat: 48.436611, lng: 17.688216 },
    { lat: 48.436928, lng: 17.688064 },
    { lat: 48.438269, lng: 17.687338 },
    { lat: 48.439356, lng: 17.686573 },
    { lat: 48.439533, lng: 17.686458 },
    { lat: 48.439893, lng: 17.686252 },
    { lat: 48.440268, lng: 17.68605 },
    { lat: 48.440748, lng: 17.685679 },
    { lat: 48.441272, lng: 17.685261 },
    { lat: 48.442339, lng: 17.684418 },
    { lat: 48.443009, lng: 17.683893 },
    { lat: 48.443192, lng: 17.683765 },
    { lat: 48.443306, lng: 17.683661 },
    { lat: 48.443808, lng: 17.683312 },
    { lat: 48.443963, lng: 17.683543 },
    { lat: 48.444381, lng: 17.684158 },
    { lat: 48.444706, lng: 17.68465 },
    { lat: 48.445667, lng: 17.686146 },
    { lat: 48.445977, lng: 17.686594 },
    { lat: 48.446157, lng: 17.686806 },
    { lat: 48.446176, lng: 17.686802 },
    { lat: 48.446238, lng: 17.68679 },
    { lat: 48.446312, lng: 17.686776 },
    { lat: 48.446363, lng: 17.686768 },
    { lat: 48.447006, lng: 17.687059 },
    { lat: 48.447581, lng: 17.687124 },
    { lat: 48.447836, lng: 17.68715 },
    { lat: 48.448051, lng: 17.687146 },
    { lat: 48.44857, lng: 17.68717 },
    { lat: 48.449287, lng: 17.687255 },
    { lat: 48.44961, lng: 17.687299 },
    { lat: 48.450363, lng: 17.687408 },
    { lat: 48.450573, lng: 17.687456 },
    { lat: 48.450753, lng: 17.687576 },
    { lat: 48.451104, lng: 17.687944 },
    { lat: 48.45132, lng: 17.688154 },
    { lat: 48.451473, lng: 17.688287 },
    { lat: 48.451789, lng: 17.688563 },
    { lat: 48.452311, lng: 17.688955 },
    { lat: 48.452607, lng: 17.689192 },
    { lat: 48.453789, lng: 17.690107 },
    { lat: 48.453773, lng: 17.690132 },
    { lat: 48.453842, lng: 17.69019 },
    { lat: 48.454083, lng: 17.690186 },
    { lat: 48.455099, lng: 17.689714 },
    { lat: 48.455501, lng: 17.689501 },
    { lat: 48.455568, lng: 17.689439 },
    { lat: 48.455662, lng: 17.689411 },
    { lat: 48.455942, lng: 17.689235 },
    { lat: 48.45605, lng: 17.689146 },
    { lat: 48.456322, lng: 17.688974 },
    { lat: 48.456344, lng: 17.68895 },
    { lat: 48.456355, lng: 17.688938 },
    { lat: 48.456404, lng: 17.688884 },
    { lat: 48.456429, lng: 17.688858 },
    { lat: 48.456459, lng: 17.688824 },
    { lat: 48.456927, lng: 17.688503 },
    { lat: 48.457099, lng: 17.688435 },
    { lat: 48.457737, lng: 17.688263 },
    { lat: 48.458123, lng: 17.688276 },
    { lat: 48.458159, lng: 17.688287 },
    { lat: 48.458394, lng: 17.688356 },
    { lat: 48.458467, lng: 17.688379 },
    { lat: 48.458525, lng: 17.688421 },
    { lat: 48.458759, lng: 17.688571 },
    { lat: 48.459117, lng: 17.688774 },
    { lat: 48.459304, lng: 17.688825 },
    { lat: 48.45978, lng: 17.688886 },
    { lat: 48.460883, lng: 17.688481 },
    { lat: 48.461297, lng: 17.688315 },
    { lat: 48.462295, lng: 17.687876 },
    { lat: 48.462932, lng: 17.687535 },
    { lat: 48.4634315, lng: 17.6872698 },
    { lat: 48.463806, lng: 17.687071 },
    { lat: 48.464545, lng: 17.686589 },
    { lat: 48.465235, lng: 17.686143 },
    { lat: 48.46581, lng: 17.685755 },
    { lat: 48.466111, lng: 17.68558 },
    { lat: 48.467337, lng: 17.684834 },
    { lat: 48.46804, lng: 17.684372 },
    { lat: 48.468866, lng: 17.683787 },
    { lat: 48.469684, lng: 17.683153 },
    { lat: 48.470029, lng: 17.683953 },
    { lat: 48.470602, lng: 17.685286 },
  ],
  HornéOrešany: [
    { lat: 48.4518655, lng: 17.3464378 },
    { lat: 48.4520505, lng: 17.3464352 },
    { lat: 48.4522256, lng: 17.3465399 },
    { lat: 48.4522935, lng: 17.3467821 },
    { lat: 48.4523223, lng: 17.3472586 },
    { lat: 48.4522708, lng: 17.3475573 },
    { lat: 48.4521979, lng: 17.3477389 },
    { lat: 48.4520027, lng: 17.3479502 },
    { lat: 48.4519881, lng: 17.3481434 },
    { lat: 48.4521359, lng: 17.3484348 },
    { lat: 48.4521663, lng: 17.3486758 },
    { lat: 48.4522775, lng: 17.3486999 },
    { lat: 48.4524524, lng: 17.3490588 },
    { lat: 48.4529208, lng: 17.3493113 },
    { lat: 48.4529369, lng: 17.3496445 },
    { lat: 48.4531456, lng: 17.3496941 },
    { lat: 48.4531155, lng: 17.3502191 },
    { lat: 48.4531851, lng: 17.3504551 },
    { lat: 48.4530568, lng: 17.3508758 },
    { lat: 48.4531039, lng: 17.3510784 },
    { lat: 48.4532196, lng: 17.3511214 },
    { lat: 48.4533721, lng: 17.3517096 },
    { lat: 48.4536258, lng: 17.3518394 },
    { lat: 48.4536749, lng: 17.3523833 },
    { lat: 48.4536244, lng: 17.3525968 },
    { lat: 48.4534861, lng: 17.3524186 },
    { lat: 48.453395, lng: 17.3525271 },
    { lat: 48.4535517, lng: 17.3527623 },
    { lat: 48.4536014, lng: 17.3529724 },
    { lat: 48.4535641, lng: 17.3531263 },
    { lat: 48.4535156, lng: 17.3531274 },
    { lat: 48.4534971, lng: 17.3529664 },
    { lat: 48.4534396, lng: 17.3528806 },
    { lat: 48.4533988, lng: 17.3529994 },
    { lat: 48.4533535, lng: 17.3531897 },
    { lat: 48.4533835, lng: 17.3535436 },
    { lat: 48.4533025, lng: 17.3536524 },
    { lat: 48.4532856, lng: 17.3538431 },
    { lat: 48.4533129, lng: 17.3542095 },
    { lat: 48.4532453, lng: 17.3544547 },
    { lat: 48.4530616, lng: 17.3547544 },
    { lat: 48.4530078, lng: 17.3550221 },
    { lat: 48.4530573, lng: 17.3552439 },
    { lat: 48.4530478, lng: 17.3556164 },
    { lat: 48.4531066, lng: 17.3556875 },
    { lat: 48.4531207, lng: 17.3559028 },
    { lat: 48.4530351, lng: 17.3559222 },
    { lat: 48.4529236, lng: 17.3558248 },
    { lat: 48.4527934, lng: 17.3559774 },
    { lat: 48.4527839, lng: 17.3561944 },
    { lat: 48.4527312, lng: 17.356304 },
    { lat: 48.4527757, lng: 17.3565006 },
    { lat: 48.4526257, lng: 17.3571796 },
    { lat: 48.4527777, lng: 17.357154 },
    { lat: 48.4528726, lng: 17.3572588 },
    { lat: 48.4528774, lng: 17.3575076 },
    { lat: 48.4528389, lng: 17.3576431 },
    { lat: 48.4526974, lng: 17.3577415 },
    { lat: 48.4527263, lng: 17.3581337 },
    { lat: 48.4524005, lng: 17.3580739 },
    { lat: 48.4524152, lng: 17.3584375 },
    { lat: 48.4525005, lng: 17.3585694 },
    { lat: 48.4525366, lng: 17.3587602 },
    { lat: 48.4522928, lng: 17.3587452 },
    { lat: 48.4521534, lng: 17.3589778 },
    { lat: 48.4520238, lng: 17.3590702 },
    { lat: 48.4521181, lng: 17.359198 },
    { lat: 48.4522369, lng: 17.3591328 },
    { lat: 48.4524617, lng: 17.359374 },
    { lat: 48.4524156, lng: 17.3595854 },
    { lat: 48.45258, lng: 17.3598195 },
    { lat: 48.4527124, lng: 17.359669 },
    { lat: 48.452761, lng: 17.3597028 },
    { lat: 48.4527499, lng: 17.3597944 },
    { lat: 48.4529348, lng: 17.3599436 },
    { lat: 48.4528931, lng: 17.3601348 },
    { lat: 48.452796, lng: 17.3600143 },
    { lat: 48.4525421, lng: 17.36002 },
    { lat: 48.4525752, lng: 17.3604397 },
    { lat: 48.4524838, lng: 17.3607367 },
    { lat: 48.4525819, lng: 17.3608913 },
    { lat: 48.4528197, lng: 17.3610381 },
    { lat: 48.4528709, lng: 17.3611636 },
    { lat: 48.4528686, lng: 17.3613703 },
    { lat: 48.4526904, lng: 17.3618609 },
    { lat: 48.4528761, lng: 17.3619033 },
    { lat: 48.4530724, lng: 17.3617954 },
    { lat: 48.4531335, lng: 17.3618832 },
    { lat: 48.4528985, lng: 17.3621776 },
    { lat: 48.4527682, lng: 17.3621356 },
    { lat: 48.4526839, lng: 17.3623501 },
    { lat: 48.4528075, lng: 17.3623906 },
    { lat: 48.4530569, lng: 17.3626147 },
    { lat: 48.4529872, lng: 17.363085 },
    { lat: 48.4530067, lng: 17.3633003 },
    { lat: 48.4529107, lng: 17.3633671 },
    { lat: 48.452813, lng: 17.3632737 },
    { lat: 48.452704, lng: 17.3633153 },
    { lat: 48.4528283, lng: 17.3636966 },
    { lat: 48.4530296, lng: 17.3637887 },
    { lat: 48.4530793, lng: 17.3638899 },
    { lat: 48.4530604, lng: 17.3639681 },
    { lat: 48.4529734, lng: 17.3640158 },
    { lat: 48.4530218, lng: 17.3644136 },
    { lat: 48.4531438, lng: 17.3641536 },
    { lat: 48.4533494, lng: 17.3642753 },
    { lat: 48.4534939, lng: 17.3639441 },
    { lat: 48.4535863, lng: 17.3639596 },
    { lat: 48.4538569, lng: 17.3642464 },
    { lat: 48.4539152, lng: 17.3644987 },
    { lat: 48.4538924, lng: 17.364687 },
    { lat: 48.4538181, lng: 17.364709 },
    { lat: 48.4538154, lng: 17.3648386 },
    { lat: 48.4540252, lng: 17.3649739 },
    { lat: 48.4540508, lng: 17.3654533 },
    { lat: 48.4539874, lng: 17.3657445 },
    { lat: 48.4538536, lng: 17.3659111 },
    { lat: 48.4538109, lng: 17.3660541 },
    { lat: 48.453971, lng: 17.3661543 },
    { lat: 48.4543439, lng: 17.3666235 },
    { lat: 48.4544667, lng: 17.3664635 },
    { lat: 48.4545368, lng: 17.3665652 },
    { lat: 48.4545367, lng: 17.3666332 },
    { lat: 48.4544734, lng: 17.3666682 },
    { lat: 48.454434, lng: 17.3671205 },
    { lat: 48.4546276, lng: 17.3672838 },
    { lat: 48.4546693, lng: 17.367804 },
    { lat: 48.4548582, lng: 17.3678068 },
    { lat: 48.4549672, lng: 17.3677117 },
    { lat: 48.4551001, lng: 17.3678249 },
    { lat: 48.4551129, lng: 17.3679205 },
    { lat: 48.4549892, lng: 17.3682045 },
    { lat: 48.4548951, lng: 17.3682361 },
    { lat: 48.4547585, lng: 17.3685111 },
    { lat: 48.4549376, lng: 17.368521 },
    { lat: 48.4552936, lng: 17.368855 },
    { lat: 48.4556821, lng: 17.3688387 },
    { lat: 48.4557386, lng: 17.3690443 },
    { lat: 48.4558015, lng: 17.3691097 },
    { lat: 48.4557377, lng: 17.3695351 },
    { lat: 48.4558641, lng: 17.3695692 },
    { lat: 48.4560101, lng: 17.36931 },
    { lat: 48.4560594, lng: 17.3693223 },
    { lat: 48.4561104, lng: 17.3694656 },
    { lat: 48.4562392, lng: 17.3695488 },
    { lat: 48.4562457, lng: 17.369635 },
    { lat: 48.4560408, lng: 17.3698684 },
    { lat: 48.4559244, lng: 17.3701122 },
    { lat: 48.456225, lng: 17.3703558 },
    { lat: 48.456285, lng: 17.3705651 },
    { lat: 48.4563627, lng: 17.3706138 },
    { lat: 48.4564092, lng: 17.3704949 },
    { lat: 48.4564056, lng: 17.3703709 },
    { lat: 48.4563224, lng: 17.3702931 },
    { lat: 48.4563399, lng: 17.3701428 },
    { lat: 48.4563724, lng: 17.3701413 },
    { lat: 48.4565515, lng: 17.3702007 },
    { lat: 48.456671, lng: 17.3706154 },
    { lat: 48.4567823, lng: 17.3704307 },
    { lat: 48.4567382, lng: 17.3700653 },
    { lat: 48.4567845, lng: 17.3699998 },
    { lat: 48.4568869, lng: 17.370147 },
    { lat: 48.4570058, lng: 17.3701007 },
    { lat: 48.4572271, lng: 17.3701521 },
    { lat: 48.4571868, lng: 17.3703644 },
    { lat: 48.4573517, lng: 17.3705195 },
    { lat: 48.4575971, lng: 17.3702286 },
    { lat: 48.4576698, lng: 17.3702186 },
    { lat: 48.4576841, lng: 17.3702819 },
    { lat: 48.4579611, lng: 17.3701476 },
    { lat: 48.4580703, lng: 17.3701418 },
    { lat: 48.4581741, lng: 17.3704016 },
    { lat: 48.4582795, lng: 17.370337 },
    { lat: 48.4583271, lng: 17.3701677 },
    { lat: 48.4582982, lng: 17.3700182 },
    { lat: 48.4583398, lng: 17.3699441 },
    { lat: 48.4588257, lng: 17.3702776 },
    { lat: 48.4589656, lng: 17.3704498 },
    { lat: 48.4590685, lng: 17.3704482 },
    { lat: 48.4594414, lng: 17.3707296 },
    { lat: 48.4595924, lng: 17.3710206 },
    { lat: 48.4595363, lng: 17.3711801 },
    { lat: 48.4596112, lng: 17.3712719 },
    { lat: 48.4596935, lng: 17.371653 },
    { lat: 48.459632, lng: 17.3719414 },
    { lat: 48.4593496, lng: 17.3721296 },
    { lat: 48.4593148, lng: 17.3723216 },
    { lat: 48.4594982, lng: 17.3725903 },
    { lat: 48.4596857, lng: 17.3727299 },
    { lat: 48.4597408, lng: 17.3730877 },
    { lat: 48.4596347, lng: 17.3734371 },
    { lat: 48.4595572, lng: 17.3735478 },
    { lat: 48.4595798, lng: 17.3738441 },
    { lat: 48.4596296, lng: 17.3740016 },
    { lat: 48.4597337, lng: 17.3738852 },
    { lat: 48.4597577, lng: 17.3741297 },
    { lat: 48.4599032, lng: 17.3743876 },
    { lat: 48.4600079, lng: 17.3743299 },
    { lat: 48.4603064, lng: 17.3743528 },
    { lat: 48.4605657, lng: 17.374725 },
    { lat: 48.4606229, lng: 17.3746504 },
    { lat: 48.4606361, lng: 17.3743844 },
    { lat: 48.4607311, lng: 17.3743857 },
    { lat: 48.4608503, lng: 17.3746024 },
    { lat: 48.460836, lng: 17.3747326 },
    { lat: 48.4610449, lng: 17.3747819 },
    { lat: 48.4613417, lng: 17.3749852 },
    { lat: 48.4615033, lng: 17.3752501 },
    { lat: 48.4618888, lng: 17.375477 },
    { lat: 48.4620524, lng: 17.3756672 },
    { lat: 48.4623934, lng: 17.3758223 },
    { lat: 48.4625009, lng: 17.3757716 },
    { lat: 48.4626451, lng: 17.3754209 },
    { lat: 48.4627658, lng: 17.3753106 },
    { lat: 48.4632464, lng: 17.3753886 },
    { lat: 48.4634416, lng: 17.3753325 },
    { lat: 48.4637866, lng: 17.3754476 },
    { lat: 48.4639256, lng: 17.3757581 },
    { lat: 48.4640686, lng: 17.3763766 },
    { lat: 48.4642036, lng: 17.3764543 },
    { lat: 48.4644377, lng: 17.3764437 },
    { lat: 48.4644263, lng: 17.3766322 },
    { lat: 48.4645051, lng: 17.3768719 },
    { lat: 48.4646192, lng: 17.3769929 },
    { lat: 48.464849, lng: 17.3769989 },
    { lat: 48.4649692, lng: 17.3771809 },
    { lat: 48.4648156, lng: 17.377269 },
    { lat: 48.464759, lng: 17.3771623 },
    { lat: 48.4647237, lng: 17.3773654 },
    { lat: 48.4652041, lng: 17.3774766 },
    { lat: 48.4644672, lng: 17.3786328 },
    { lat: 48.4626609, lng: 17.3806989 },
    { lat: 48.4624615, lng: 17.3811741 },
    { lat: 48.4619652, lng: 17.3818446 },
    { lat: 48.4618278, lng: 17.3825122 },
    { lat: 48.4613689, lng: 17.3832198 },
    { lat: 48.4613308, lng: 17.3833461 },
    { lat: 48.4611757, lng: 17.3834945 },
    { lat: 48.4611068, lng: 17.3838449 },
    { lat: 48.4609912, lng: 17.3840462 },
    { lat: 48.4608423, lng: 17.3845521 },
    { lat: 48.4605942, lng: 17.3848899 },
    { lat: 48.4603121, lng: 17.3849962 },
    { lat: 48.4601832, lng: 17.3853955 },
    { lat: 48.4600725, lng: 17.3854983 },
    { lat: 48.459969, lng: 17.3858032 },
    { lat: 48.459871, lng: 17.385945 },
    { lat: 48.4590972, lng: 17.3863269 },
    { lat: 48.4587411, lng: 17.3863714 },
    { lat: 48.4586469, lng: 17.3866266 },
    { lat: 48.4584828, lng: 17.386535 },
    { lat: 48.4584701, lng: 17.3873803 },
    { lat: 48.4582482, lng: 17.3876886 },
    { lat: 48.45786, lng: 17.3878858 },
    { lat: 48.4578323, lng: 17.3880301 },
    { lat: 48.4577664, lng: 17.3880626 },
    { lat: 48.4577251, lng: 17.3881911 },
    { lat: 48.4576881, lng: 17.3884883 },
    { lat: 48.4576203, lng: 17.3885424 },
    { lat: 48.4575663, lng: 17.3889282 },
    { lat: 48.4573342, lng: 17.3894395 },
    { lat: 48.4568855, lng: 17.3897135 },
    { lat: 48.4568012, lng: 17.3898437 },
    { lat: 48.4567521, lng: 17.390268 },
    { lat: 48.4565057, lng: 17.3912414 },
    { lat: 48.4559137, lng: 17.3921418 },
    { lat: 48.4556574, lng: 17.3930675 },
    { lat: 48.4552053, lng: 17.3932534 },
    { lat: 48.454103, lng: 17.3989119 },
    { lat: 48.4542458, lng: 17.4011825 },
    { lat: 48.4546992, lng: 17.4018494 },
    { lat: 48.455008, lng: 17.402414 },
    { lat: 48.4554007, lng: 17.4035057 },
    { lat: 48.4554146, lng: 17.4040335 },
    { lat: 48.4559007, lng: 17.4045117 },
    { lat: 48.4563314, lng: 17.4051279 },
    { lat: 48.4580804, lng: 17.4077667 },
    { lat: 48.4585391, lng: 17.4087091 },
    { lat: 48.4585718, lng: 17.410017 },
    { lat: 48.4581677, lng: 17.4125301 },
    { lat: 48.4585968, lng: 17.4133341 },
    { lat: 48.4592865, lng: 17.4141274 },
    { lat: 48.4592191, lng: 17.414955 },
    { lat: 48.4592542, lng: 17.4158295 },
    { lat: 48.4590592, lng: 17.4173185 },
    { lat: 48.4590697, lng: 17.4183695 },
    { lat: 48.4589522, lng: 17.4187205 },
    { lat: 48.4588952, lng: 17.4192697 },
    { lat: 48.4582082, lng: 17.4198228 },
    { lat: 48.4578404, lng: 17.4208953 },
    { lat: 48.4574988, lng: 17.4215669 },
    { lat: 48.4562093, lng: 17.4236018 },
    { lat: 48.4555082, lng: 17.4242334 },
    { lat: 48.455372, lng: 17.4244333 },
    { lat: 48.4549979, lng: 17.4250424 },
    { lat: 48.4542089, lng: 17.4265346 },
    { lat: 48.4540907, lng: 17.4266469 },
    { lat: 48.4534747, lng: 17.4277573 },
    { lat: 48.4531796, lng: 17.4281893 },
    { lat: 48.4530113, lng: 17.4282591 },
    { lat: 48.4528474, lng: 17.4279941 },
    { lat: 48.4525786, lng: 17.4278043 },
    { lat: 48.4523728, lng: 17.4274326 },
    { lat: 48.45201, lng: 17.4277582 },
    { lat: 48.4517755, lng: 17.4282389 },
    { lat: 48.450805, lng: 17.4295811 },
    { lat: 48.4507436, lng: 17.429642 },
    { lat: 48.4505485, lng: 17.4295975 },
    { lat: 48.4503878, lng: 17.429817 },
    { lat: 48.4502897, lng: 17.4297855 },
    { lat: 48.4501653, lng: 17.4299733 },
    { lat: 48.4501684, lng: 17.4300764 },
    { lat: 48.4501094, lng: 17.4300904 },
    { lat: 48.4500062, lng: 17.4299959 },
    { lat: 48.4499271, lng: 17.4304311 },
    { lat: 48.4496947, lng: 17.430559 },
    { lat: 48.4495935, lng: 17.4304617 },
    { lat: 48.4494921, lng: 17.430463 },
    { lat: 48.4494503, lng: 17.4307441 },
    { lat: 48.4492648, lng: 17.4308612 },
    { lat: 48.4492321, lng: 17.4309492 },
    { lat: 48.4492807, lng: 17.4310745 },
    { lat: 48.4491016, lng: 17.4312554 },
    { lat: 48.4490849, lng: 17.4314503 },
    { lat: 48.448972, lng: 17.431645 },
    { lat: 48.4487008, lng: 17.4317814 },
    { lat: 48.4486866, lng: 17.4320527 },
    { lat: 48.448572, lng: 17.4323224 },
    { lat: 48.448593, lng: 17.4325086 },
    { lat: 48.4485554, lng: 17.4325753 },
    { lat: 48.4483582, lng: 17.4326336 },
    { lat: 48.4482304, lng: 17.4325274 },
    { lat: 48.4481424, lng: 17.432319 },
    { lat: 48.4479904, lng: 17.4323436 },
    { lat: 48.4478618, lng: 17.4325542 },
    { lat: 48.4476459, lng: 17.4327632 },
    { lat: 48.4476779, lng: 17.4328375 },
    { lat: 48.447751, lng: 17.4328325 },
    { lat: 48.4477924, lng: 17.4331762 },
    { lat: 48.4475701, lng: 17.4332307 },
    { lat: 48.4475258, lng: 17.4333147 },
    { lat: 48.4475344, lng: 17.4335027 },
    { lat: 48.447478, lng: 17.4335889 },
    { lat: 48.4472217, lng: 17.4335388 },
    { lat: 48.4471212, lng: 17.4337861 },
    { lat: 48.4469872, lng: 17.4335466 },
    { lat: 48.44677, lng: 17.4336616 },
    { lat: 48.4466968, lng: 17.4338946 },
    { lat: 48.4463893, lng: 17.4340626 },
    { lat: 48.4464041, lng: 17.4342593 },
    { lat: 48.4463424, lng: 17.4343878 },
    { lat: 48.4463172, lng: 17.4346284 },
    { lat: 48.4461807, lng: 17.4348045 },
    { lat: 48.4461671, lng: 17.4355212 },
    { lat: 48.4460112, lng: 17.4355005 },
    { lat: 48.4459238, lng: 17.4352877 },
    { lat: 48.445822, lng: 17.4360714 },
    { lat: 48.4457712, lng: 17.436157 },
    { lat: 48.4458364, lng: 17.4365033 },
    { lat: 48.4457312, lng: 17.436678 },
    { lat: 48.4457112, lng: 17.4368033 },
    { lat: 48.4457891, lng: 17.436819 },
    { lat: 48.4459712, lng: 17.4372271 },
    { lat: 48.4458149, lng: 17.4373253 },
    { lat: 48.4456821, lng: 17.437281 },
    { lat: 48.4456136, lng: 17.4375653 },
    { lat: 48.4458031, lng: 17.4376149 },
    { lat: 48.4457321, lng: 17.4377771 },
    { lat: 48.4456188, lng: 17.4378395 },
    { lat: 48.445405, lng: 17.4376779 },
    { lat: 48.4452429, lng: 17.4380004 },
    { lat: 48.4453468, lng: 17.4381915 },
    { lat: 48.4453561, lng: 17.4383211 },
    { lat: 48.4454219, lng: 17.4383421 },
    { lat: 48.4453733, lng: 17.4384449 },
    { lat: 48.4452506, lng: 17.4384535 },
    { lat: 48.4452385, lng: 17.4385264 },
    { lat: 48.4453037, lng: 17.4385902 },
    { lat: 48.4453398, lng: 17.4387326 },
    { lat: 48.4452559, lng: 17.4389536 },
    { lat: 48.4452735, lng: 17.439193 },
    { lat: 48.4453638, lng: 17.4391218 },
    { lat: 48.445388, lng: 17.4391635 },
    { lat: 48.4453157, lng: 17.4395646 },
    { lat: 48.4454159, lng: 17.4396133 },
    { lat: 48.4454275, lng: 17.4397749 },
    { lat: 48.445202, lng: 17.4396584 },
    { lat: 48.4451356, lng: 17.4395218 },
    { lat: 48.4449508, lng: 17.439441 },
    { lat: 48.4447999, lng: 17.4396081 },
    { lat: 48.4447685, lng: 17.439506 },
    { lat: 48.444651, lng: 17.4394534 },
    { lat: 48.4444839, lng: 17.4391018 },
    { lat: 48.444373, lng: 17.4390799 },
    { lat: 48.4442396, lng: 17.4392206 },
    { lat: 48.4441262, lng: 17.4392311 },
    { lat: 48.4440337, lng: 17.4394417 },
    { lat: 48.4440717, lng: 17.4402753 },
    { lat: 48.4442188, lng: 17.4404534 },
    { lat: 48.4442278, lng: 17.4406994 },
    { lat: 48.4440254, lng: 17.440784 },
    { lat: 48.443846, lng: 17.4407319 },
    { lat: 48.4434938, lng: 17.4409625 },
    { lat: 48.4433699, lng: 17.4408657 },
    { lat: 48.4433083, lng: 17.4409785 },
    { lat: 48.4432669, lng: 17.4413673 },
    { lat: 48.4431337, lng: 17.441614 },
    { lat: 48.4428509, lng: 17.4417502 },
    { lat: 48.442662, lng: 17.4420062 },
    { lat: 48.442512, lng: 17.44208 },
    { lat: 48.4423782, lng: 17.4420347 },
    { lat: 48.4424717, lng: 17.4427877 },
    { lat: 48.4425878, lng: 17.4432037 },
    { lat: 48.443311, lng: 17.4444173 },
    { lat: 48.4438437, lng: 17.4455652 },
    { lat: 48.4450133, lng: 17.4477825 },
    { lat: 48.4451946, lng: 17.4482299 },
    { lat: 48.4479018, lng: 17.4531918 },
    { lat: 48.4484319, lng: 17.4544779 },
    { lat: 48.4492316, lng: 17.4557597 },
    { lat: 48.451251, lng: 17.4593545 },
    { lat: 48.4519225, lng: 17.4604377 },
    { lat: 48.4518941, lng: 17.4604514 },
    { lat: 48.4530851, lng: 17.462583 },
    { lat: 48.4538142, lng: 17.4641062 },
    { lat: 48.4538508, lng: 17.4642699 },
    { lat: 48.4555848, lng: 17.4677363 },
    { lat: 48.456503, lng: 17.4693713 },
    { lat: 48.4560102, lng: 17.4701399 },
    { lat: 48.456357, lng: 17.4706133 },
    { lat: 48.4577497, lng: 17.4725868 },
    { lat: 48.4586218, lng: 17.4736252 },
    { lat: 48.4593382, lng: 17.4724725 },
    { lat: 48.4606532, lng: 17.4700487 },
    { lat: 48.4616902, lng: 17.4676794 },
    { lat: 48.462781, lng: 17.4661335 },
    { lat: 48.4630413, lng: 17.4656832 },
    { lat: 48.463475, lng: 17.4648056 },
    { lat: 48.4642894, lng: 17.4633485 },
    { lat: 48.4648041, lng: 17.462184 },
    { lat: 48.4650562, lng: 17.4619598 },
    { lat: 48.4652479, lng: 17.4616917 },
    { lat: 48.4656631, lng: 17.4608943 },
    { lat: 48.4662875, lng: 17.4601707 },
    { lat: 48.4665177, lng: 17.4600301 },
    { lat: 48.4666947, lng: 17.4601286 },
    { lat: 48.4670531, lng: 17.4600821 },
    { lat: 48.4687475, lng: 17.4576618 },
    { lat: 48.4702662, lng: 17.4560072 },
    { lat: 48.4712582, lng: 17.4548033 },
    { lat: 48.472507, lng: 17.4523937 },
    { lat: 48.4733098, lng: 17.4514265 },
    { lat: 48.4740149, lng: 17.4502919 },
    { lat: 48.4742227, lng: 17.4497996 },
    { lat: 48.4753438, lng: 17.4480203 },
    { lat: 48.4759303, lng: 17.4467093 },
    { lat: 48.4762033, lng: 17.4459693 },
    { lat: 48.4768394, lng: 17.4446051 },
    { lat: 48.477339, lng: 17.4430885 },
    { lat: 48.4774484, lng: 17.442455 },
    { lat: 48.4779668, lng: 17.4412303 },
    { lat: 48.4787, lng: 17.4403192 },
    { lat: 48.4797809, lng: 17.4388319 },
    { lat: 48.4808338, lng: 17.4385373 },
    { lat: 48.4817, lng: 17.438868 },
    { lat: 48.4820516, lng: 17.4385764 },
    { lat: 48.4824933, lng: 17.4379323 },
    { lat: 48.4825144, lng: 17.4379072 },
    { lat: 48.4829746, lng: 17.4373356 },
    { lat: 48.4831101, lng: 17.437073 },
    { lat: 48.4831642, lng: 17.4365841 },
    { lat: 48.4836894, lng: 17.435234 },
    { lat: 48.4846749, lng: 17.4335279 },
    { lat: 48.484945, lng: 17.433163 },
    { lat: 48.4856097, lng: 17.4315803 },
    { lat: 48.4855379, lng: 17.4314466 },
    { lat: 48.4855743, lng: 17.43047 },
    { lat: 48.4859187, lng: 17.4304343 },
    { lat: 48.485938, lng: 17.4295795 },
    { lat: 48.4858458, lng: 17.4287816 },
    { lat: 48.4854058, lng: 17.4283524 },
    { lat: 48.4853485, lng: 17.4283401 },
    { lat: 48.4824599, lng: 17.427737 },
    { lat: 48.4817065, lng: 17.4274719 },
    { lat: 48.4815783, lng: 17.4262393 },
    { lat: 48.4816012, lng: 17.4259308 },
    { lat: 48.481659, lng: 17.4257593 },
    { lat: 48.4815208, lng: 17.4253345 },
    { lat: 48.4815426, lng: 17.4251811 },
    { lat: 48.4816682, lng: 17.4251506 },
    { lat: 48.4816857, lng: 17.4250512 },
    { lat: 48.4815743, lng: 17.4249198 },
    { lat: 48.4815947, lng: 17.4247961 },
    { lat: 48.4814193, lng: 17.4245145 },
    { lat: 48.4813935, lng: 17.4243603 },
    { lat: 48.4815549, lng: 17.4243238 },
    { lat: 48.4815859, lng: 17.4240858 },
    { lat: 48.4814253, lng: 17.4238561 },
    { lat: 48.4813021, lng: 17.4234519 },
    { lat: 48.4811775, lng: 17.4234096 },
    { lat: 48.4810476, lng: 17.4229751 },
    { lat: 48.4808158, lng: 17.4228477 },
    { lat: 48.4808185, lng: 17.4227512 },
    { lat: 48.4807565, lng: 17.4226634 },
    { lat: 48.4806837, lng: 17.422709 },
    { lat: 48.4805943, lng: 17.4225996 },
    { lat: 48.4806494, lng: 17.422405 },
    { lat: 48.4805544, lng: 17.4222284 },
    { lat: 48.4806036, lng: 17.4220632 },
    { lat: 48.4802407, lng: 17.421238 },
    { lat: 48.4798626, lng: 17.4198594 },
    { lat: 48.479434, lng: 17.4191542 },
    { lat: 48.4793308, lng: 17.4188169 },
    { lat: 48.4793464, lng: 17.4185676 },
    { lat: 48.4788016, lng: 17.4178481 },
    { lat: 48.4787988, lng: 17.4177488 },
    { lat: 48.4785761, lng: 17.4175557 },
    { lat: 48.4785425, lng: 17.4173684 },
    { lat: 48.4786153, lng: 17.417042 },
    { lat: 48.4787205, lng: 17.4168906 },
    { lat: 48.4788695, lng: 17.4168001 },
    { lat: 48.4790564, lng: 17.4164413 },
    { lat: 48.4790549, lng: 17.4161204 },
    { lat: 48.4791105, lng: 17.4159528 },
    { lat: 48.4788978, lng: 17.4155447 },
    { lat: 48.4788815, lng: 17.4154307 },
    { lat: 48.4789729, lng: 17.4150802 },
    { lat: 48.4789424, lng: 17.4144517 },
    { lat: 48.4789748, lng: 17.4142721 },
    { lat: 48.4790281, lng: 17.4142771 },
    { lat: 48.4790736, lng: 17.4138269 },
    { lat: 48.4790273, lng: 17.4137554 },
    { lat: 48.4790793, lng: 17.4136011 },
    { lat: 48.4789803, lng: 17.413224 },
    { lat: 48.478884, lng: 17.4130445 },
    { lat: 48.4785501, lng: 17.4131364 },
    { lat: 48.4784549, lng: 17.4130817 },
    { lat: 48.4783938, lng: 17.4129379 },
    { lat: 48.478332, lng: 17.4124048 },
    { lat: 48.4782649, lng: 17.4122736 },
    { lat: 48.4782542, lng: 17.4120908 },
    { lat: 48.4781458, lng: 17.4118646 },
    { lat: 48.4780972, lng: 17.4114972 },
    { lat: 48.4779656, lng: 17.4110188 },
    { lat: 48.477978, lng: 17.4107928 },
    { lat: 48.4780469, lng: 17.4107385 },
    { lat: 48.4780552, lng: 17.4106394 },
    { lat: 48.4780067, lng: 17.410413 },
    { lat: 48.4780204, lng: 17.41026 },
    { lat: 48.477964, lng: 17.4101569 },
    { lat: 48.4779485, lng: 17.4098804 },
    { lat: 48.477797, lng: 17.4093907 },
    { lat: 48.4778743, lng: 17.4090166 },
    { lat: 48.477965, lng: 17.40883 },
    { lat: 48.4779705, lng: 17.4086889 },
    { lat: 48.4781981, lng: 17.4084171 },
    { lat: 48.4782943, lng: 17.4079466 },
    { lat: 48.4783758, lng: 17.4078823 },
    { lat: 48.4784107, lng: 17.4077046 },
    { lat: 48.4789023, lng: 17.406802 },
    { lat: 48.4789442, lng: 17.4065257 },
    { lat: 48.4788836, lng: 17.4062177 },
    { lat: 48.4789021, lng: 17.4060687 },
    { lat: 48.4786947, lng: 17.4060934 },
    { lat: 48.4782316, lng: 17.4058138 },
    { lat: 48.4781302, lng: 17.4056069 },
    { lat: 48.4782937, lng: 17.4050112 },
    { lat: 48.4774551, lng: 17.4044087 },
    { lat: 48.4778556, lng: 17.402868 },
    { lat: 48.4778161, lng: 17.4026079 },
    { lat: 48.4779558, lng: 17.4022884 },
    { lat: 48.4778941, lng: 17.4018439 },
    { lat: 48.4776884, lng: 17.4014935 },
    { lat: 48.4772798, lng: 17.4000491 },
    { lat: 48.4772893, lng: 17.399273 },
    { lat: 48.4775394, lng: 17.398461 },
    { lat: 48.477533, lng: 17.3980846 },
    { lat: 48.4773665, lng: 17.3975544 },
    { lat: 48.4772989, lng: 17.3971404 },
    { lat: 48.4771516, lng: 17.3968359 },
    { lat: 48.4767324, lng: 17.3966425 },
    { lat: 48.4764683, lng: 17.3950958 },
    { lat: 48.4757886, lng: 17.3942839 },
    { lat: 48.4754323, lng: 17.3936296 },
    { lat: 48.4714872, lng: 17.393305 },
    { lat: 48.4714289, lng: 17.3932416 },
    { lat: 48.4712434, lng: 17.3934283 },
    { lat: 48.4706586, lng: 17.3931386 },
    { lat: 48.471637, lng: 17.3893312 },
    { lat: 48.471759, lng: 17.3892631 },
    { lat: 48.4718034, lng: 17.3887989 },
    { lat: 48.4721353, lng: 17.3875797 },
    { lat: 48.4720764, lng: 17.3862532 },
    { lat: 48.4720804, lng: 17.3851342 },
    { lat: 48.4718376, lng: 17.3823899 },
    { lat: 48.471677, lng: 17.382384 },
    { lat: 48.4714784, lng: 17.3822491 },
    { lat: 48.4710568, lng: 17.382226 },
    { lat: 48.4708298, lng: 17.3820013 },
    { lat: 48.470687, lng: 17.3817148 },
    { lat: 48.4703588, lng: 17.3814804 },
    { lat: 48.470219, lng: 17.3815366 },
    { lat: 48.4699693, lng: 17.3812234 },
    { lat: 48.4693677, lng: 17.3810894 },
    { lat: 48.4692212, lng: 17.380959 },
    { lat: 48.4688126, lng: 17.3803905 },
    { lat: 48.4687077, lng: 17.3796424 },
    { lat: 48.4687673, lng: 17.3796056 },
    { lat: 48.4687879, lng: 17.3792968 },
    { lat: 48.4688861, lng: 17.3790157 },
    { lat: 48.4693275, lng: 17.3785287 },
    { lat: 48.4694968, lng: 17.3781606 },
    { lat: 48.4696394, lng: 17.3777303 },
    { lat: 48.4699525, lng: 17.3758092 },
    { lat: 48.4702445, lng: 17.3752063 },
    { lat: 48.4703321, lng: 17.3747307 },
    { lat: 48.4703034, lng: 17.3737521 },
    { lat: 48.4700649, lng: 17.3732658 },
    { lat: 48.4698395, lng: 17.3719197 },
    { lat: 48.4695215, lng: 17.3716668 },
    { lat: 48.4695067, lng: 17.3712646 },
    { lat: 48.4694634, lng: 17.3711197 },
    { lat: 48.4692623, lng: 17.3709642 },
    { lat: 48.469175, lng: 17.3707689 },
    { lat: 48.4689333, lng: 17.370424 },
    { lat: 48.4688276, lng: 17.3703591 },
    { lat: 48.4687261, lng: 17.370153 },
    { lat: 48.4686376, lng: 17.3697136 },
    { lat: 48.4684668, lng: 17.3693473 },
    { lat: 48.4683814, lng: 17.3686768 },
    { lat: 48.4682132, lng: 17.3684521 },
    { lat: 48.4678867, lng: 17.3676363 },
    { lat: 48.4677578, lng: 17.3669769 },
    { lat: 48.4673449, lng: 17.3659802 },
    { lat: 48.4668668, lng: 17.3649349 },
    { lat: 48.4668016, lng: 17.3648704 },
    { lat: 48.4661756, lng: 17.3647267 },
    { lat: 48.4659689, lng: 17.3646059 },
    { lat: 48.46567, lng: 17.3646467 },
    { lat: 48.4652834, lng: 17.3645225 },
    { lat: 48.4649185, lng: 17.3645884 },
    { lat: 48.4646192, lng: 17.3644855 },
    { lat: 48.4643536, lng: 17.3645902 },
    { lat: 48.4640675, lng: 17.3644793 },
    { lat: 48.4637797, lng: 17.3645192 },
    { lat: 48.4632721, lng: 17.364684 },
    { lat: 48.4630578, lng: 17.3644982 },
    { lat: 48.4628298, lng: 17.3644136 },
    { lat: 48.4624972, lng: 17.36418 },
    { lat: 48.4622803, lng: 17.3641636 },
    { lat: 48.4618048, lng: 17.363939 },
    { lat: 48.4615825, lng: 17.363666 },
    { lat: 48.4614775, lng: 17.3637 },
    { lat: 48.4613176, lng: 17.363604 },
    { lat: 48.4611907, lng: 17.3633747 },
    { lat: 48.4610499, lng: 17.3632595 },
    { lat: 48.4605067, lng: 17.3632089 },
    { lat: 48.4603037, lng: 17.363126 },
    { lat: 48.4600345, lng: 17.362723 },
    { lat: 48.4598349, lng: 17.3626236 },
    { lat: 48.4596028, lng: 17.3619392 },
    { lat: 48.4595408, lng: 17.361405 },
    { lat: 48.4595892, lng: 17.3607797 },
    { lat: 48.4596282, lng: 17.3606159 },
    { lat: 48.4599013, lng: 17.3601247 },
    { lat: 48.4599782, lng: 17.3596234 },
    { lat: 48.4602312, lng: 17.3587049 },
    { lat: 48.4602205, lng: 17.3585406 },
    { lat: 48.4603941, lng: 17.3581535 },
    { lat: 48.4604128, lng: 17.357908 },
    { lat: 48.4607715, lng: 17.3573786 },
    { lat: 48.4608334, lng: 17.3571157 },
    { lat: 48.4608112, lng: 17.3568624 },
    { lat: 48.4608461, lng: 17.3567223 },
    { lat: 48.4614905, lng: 17.3561084 },
    { lat: 48.4615001, lng: 17.3556133 },
    { lat: 48.4616005, lng: 17.355422 },
    { lat: 48.4612289, lng: 17.3546786 },
    { lat: 48.4611939, lng: 17.3546794 },
    { lat: 48.461118, lng: 17.3543102 },
    { lat: 48.4611445, lng: 17.353729 },
    { lat: 48.4612531, lng: 17.3533521 },
    { lat: 48.4611356, lng: 17.3527815 },
    { lat: 48.4613723, lng: 17.3518747 },
    { lat: 48.4613705, lng: 17.3517425 },
    { lat: 48.4612756, lng: 17.3514992 },
    { lat: 48.461315, lng: 17.3512037 },
    { lat: 48.4612347, lng: 17.350786 },
    { lat: 48.4612244, lng: 17.3502473 },
    { lat: 48.4611243, lng: 17.3499759 },
    { lat: 48.461115, lng: 17.3494381 },
    { lat: 48.4609699, lng: 17.3481847 },
    { lat: 48.4608054, lng: 17.3478984 },
    { lat: 48.4607605, lng: 17.3472113 },
    { lat: 48.4609081, lng: 17.3461162 },
    { lat: 48.4612802, lng: 17.3453775 },
    { lat: 48.4613781, lng: 17.3450456 },
    { lat: 48.4612854, lng: 17.3447831 },
    { lat: 48.4612678, lng: 17.3445676 },
    { lat: 48.4614702, lng: 17.3439992 },
    { lat: 48.4614227, lng: 17.3435332 },
    { lat: 48.4614505, lng: 17.3425757 },
    { lat: 48.4616299, lng: 17.3417608 },
    { lat: 48.4616418, lng: 17.3413701 },
    { lat: 48.4618549, lng: 17.340278 },
    { lat: 48.4618125, lng: 17.339544 },
    { lat: 48.461843, lng: 17.3393871 },
    { lat: 48.4620218, lng: 17.3391681 },
    { lat: 48.4621049, lng: 17.3388501 },
    { lat: 48.4621738, lng: 17.3382089 },
    { lat: 48.4624119, lng: 17.3379121 },
    { lat: 48.4625678, lng: 17.3371876 },
    { lat: 48.4624939, lng: 17.3369547 },
    { lat: 48.4624561, lng: 17.3366012 },
    { lat: 48.4623509, lng: 17.3363936 },
    { lat: 48.4623012, lng: 17.3353044 },
    { lat: 48.4623663, lng: 17.3352293 },
    { lat: 48.4624479, lng: 17.3347001 },
    { lat: 48.4625754, lng: 17.3345243 },
    { lat: 48.462574, lng: 17.334228 },
    { lat: 48.4626742, lng: 17.3341003 },
    { lat: 48.4627614, lng: 17.3336746 },
    { lat: 48.4628103, lng: 17.3335748 },
    { lat: 48.462914, lng: 17.3335214 },
    { lat: 48.4630868, lng: 17.3328274 },
    { lat: 48.4629954, lng: 17.3326566 },
    { lat: 48.4630264, lng: 17.3324253 },
    { lat: 48.4628919, lng: 17.3323007 },
    { lat: 48.4627459, lng: 17.3320048 },
    { lat: 48.4628065, lng: 17.3319798 },
    { lat: 48.4628922, lng: 17.3314955 },
    { lat: 48.4629901, lng: 17.3313351 },
    { lat: 48.4629502, lng: 17.3307253 },
    { lat: 48.462848, lng: 17.3306657 },
    { lat: 48.4628143, lng: 17.3305305 },
    { lat: 48.4628942, lng: 17.3303193 },
    { lat: 48.462844, lng: 17.330257 },
    { lat: 48.4628942, lng: 17.3300584 },
    { lat: 48.4628757, lng: 17.3297437 },
    { lat: 48.4627732, lng: 17.3296781 },
    { lat: 48.4627516, lng: 17.3293118 },
    { lat: 48.4625675, lng: 17.3290028 },
    { lat: 48.4624819, lng: 17.3285919 },
    { lat: 48.4622335, lng: 17.327972 },
    { lat: 48.462214, lng: 17.3276243 },
    { lat: 48.4619835, lng: 17.326574 },
    { lat: 48.4619451, lng: 17.3259519 },
    { lat: 48.4620471, lng: 17.325593 },
    { lat: 48.4619297, lng: 17.3254744 },
    { lat: 48.4620023, lng: 17.3252199 },
    { lat: 48.4621709, lng: 17.3249835 },
    { lat: 48.4621463, lng: 17.3247988 },
    { lat: 48.4622283, lng: 17.3244461 },
    { lat: 48.4621833, lng: 17.324226 },
    { lat: 48.4623001, lng: 17.3241799 },
    { lat: 48.4623685, lng: 17.3242594 },
    { lat: 48.462493, lng: 17.3242812 },
    { lat: 48.4625753, lng: 17.3241398 },
    { lat: 48.46257, lng: 17.3240762 },
    { lat: 48.4624036, lng: 17.3240183 },
    { lat: 48.4623959, lng: 17.3239525 },
    { lat: 48.4624559, lng: 17.3238849 },
    { lat: 48.4625473, lng: 17.3238859 },
    { lat: 48.4623714, lng: 17.323596 },
    { lat: 48.462474, lng: 17.3234209 },
    { lat: 48.4621829, lng: 17.3227202 },
    { lat: 48.4619829, lng: 17.3213928 },
    { lat: 48.4617574, lng: 17.3205022 },
    { lat: 48.4606825, lng: 17.3173072 },
    { lat: 48.4605705, lng: 17.3166504 },
    { lat: 48.4605141, lng: 17.3157367 },
    { lat: 48.460232, lng: 17.3149699 },
    { lat: 48.459578, lng: 17.3152616 },
    { lat: 48.4590818, lng: 17.3153444 },
    { lat: 48.45896, lng: 17.3151721 },
    { lat: 48.458772, lng: 17.3150448 },
    { lat: 48.4586835, lng: 17.3150928 },
    { lat: 48.4585931, lng: 17.315039 },
    { lat: 48.4585169, lng: 17.3148958 },
    { lat: 48.4584058, lng: 17.3149045 },
    { lat: 48.4583081, lng: 17.3147762 },
    { lat: 48.4576103, lng: 17.3147467 },
    { lat: 48.4570834, lng: 17.3149106 },
    { lat: 48.4569585, lng: 17.3148679 },
    { lat: 48.4565887, lng: 17.3150687 },
    { lat: 48.4565142, lng: 17.3149662 },
    { lat: 48.4564226, lng: 17.3149976 },
    { lat: 48.4564147, lng: 17.3151525 },
    { lat: 48.4563307, lng: 17.3152685 },
    { lat: 48.4562552, lng: 17.3150808 },
    { lat: 48.4561473, lng: 17.3149697 },
    { lat: 48.4558824, lng: 17.3151126 },
    { lat: 48.4556843, lng: 17.3150049 },
    { lat: 48.455646, lng: 17.315158 },
    { lat: 48.455507, lng: 17.315309 },
    { lat: 48.455368, lng: 17.315464 },
    { lat: 48.455322, lng: 17.315636 },
    { lat: 48.455222, lng: 17.315738 },
    { lat: 48.455121, lng: 17.315967 },
    { lat: 48.455095, lng: 17.315972 },
    { lat: 48.454983, lng: 17.315911 },
    { lat: 48.45485, lng: 17.316071 },
    { lat: 48.454738, lng: 17.316209 },
    { lat: 48.454699, lng: 17.31621 },
    { lat: 48.454625, lng: 17.316186 },
    { lat: 48.454566, lng: 17.316318 },
    { lat: 48.454393, lng: 17.316286 },
    { lat: 48.454426, lng: 17.316387 },
    { lat: 48.454317, lng: 17.3166 },
    { lat: 48.454302, lng: 17.316647 },
    { lat: 48.454259, lng: 17.316966 },
    { lat: 48.454022, lng: 17.317127 },
    { lat: 48.454076, lng: 17.317279 },
    { lat: 48.454096, lng: 17.317362 },
    { lat: 48.454087, lng: 17.317419 },
    { lat: 48.453907, lng: 17.317698 },
    { lat: 48.453839, lng: 17.317691 },
    { lat: 48.453825, lng: 17.317689 },
    { lat: 48.453831, lng: 17.317588 },
    { lat: 48.45377, lng: 17.317592 },
    { lat: 48.453675, lng: 17.317671 },
    { lat: 48.453566, lng: 17.317649 },
    { lat: 48.45344, lng: 17.317505 },
    { lat: 48.453473, lng: 17.317332 },
    { lat: 48.453338, lng: 17.317156 },
    { lat: 48.453243, lng: 17.317134 },
    { lat: 48.453214, lng: 17.317108 },
    { lat: 48.453195, lng: 17.317055 },
    { lat: 48.453204, lng: 17.316961 },
    { lat: 48.453061, lng: 17.316977 },
    { lat: 48.452899, lng: 17.31699 },
    { lat: 48.452786, lng: 17.316964 },
    { lat: 48.452715, lng: 17.317246 },
    { lat: 48.452688, lng: 17.317237 },
    { lat: 48.452672, lng: 17.317163 },
    { lat: 48.452643, lng: 17.316959 },
    { lat: 48.452394, lng: 17.31677 },
    { lat: 48.452233, lng: 17.316672 },
    { lat: 48.452061, lng: 17.316568 },
    { lat: 48.452, lng: 17.316567 },
    { lat: 48.451862, lng: 17.316678 },
    { lat: 48.45175, lng: 17.316901 },
    { lat: 48.451533, lng: 17.316894 },
    { lat: 48.451526, lng: 17.317222 },
    { lat: 48.451515, lng: 17.31812 },
    { lat: 48.451507, lng: 17.318584 },
    { lat: 48.451505, lng: 17.319009 },
    { lat: 48.451507, lng: 17.319503 },
    { lat: 48.451509, lng: 17.320477 },
    { lat: 48.451509, lng: 17.320868 },
    { lat: 48.451516, lng: 17.321355 },
    { lat: 48.451515, lng: 17.321839 },
    { lat: 48.451512, lng: 17.322274 },
    { lat: 48.451515, lng: 17.322669 },
    { lat: 48.451514, lng: 17.323206 },
    { lat: 48.451523, lng: 17.323648 },
    { lat: 48.451529, lng: 17.324033 },
    { lat: 48.451534, lng: 17.324443 },
    { lat: 48.451535, lng: 17.324842 },
    { lat: 48.451534, lng: 17.325275 },
    { lat: 48.451536, lng: 17.325685 },
    { lat: 48.451542, lng: 17.32619 },
    { lat: 48.451542, lng: 17.32662 },
    { lat: 48.451547, lng: 17.327125 },
    { lat: 48.451556, lng: 17.327527 },
    { lat: 48.451561, lng: 17.327918 },
    { lat: 48.451566, lng: 17.328313 },
    { lat: 48.451574, lng: 17.328719 },
    { lat: 48.451586, lng: 17.329131 },
    { lat: 48.451602, lng: 17.329657 },
    { lat: 48.451617, lng: 17.329984 },
    { lat: 48.451624, lng: 17.330461 },
    { lat: 48.451634, lng: 17.330949 },
    { lat: 48.45164, lng: 17.331307 },
    { lat: 48.451658, lng: 17.331751 },
    { lat: 48.451669, lng: 17.332284 },
    { lat: 48.451686, lng: 17.332777 },
    { lat: 48.451698, lng: 17.333246 },
    { lat: 48.451705, lng: 17.333679 },
    { lat: 48.451718, lng: 17.334092 },
    { lat: 48.451723, lng: 17.334538 },
    { lat: 48.451729, lng: 17.33497 },
    { lat: 48.45173, lng: 17.335323 },
    { lat: 48.451737, lng: 17.335767 },
    { lat: 48.451739, lng: 17.336167 },
    { lat: 48.451741, lng: 17.336542 },
    { lat: 48.451744, lng: 17.337044 },
    { lat: 48.451748, lng: 17.337491 },
    { lat: 48.45175, lng: 17.337971 },
    { lat: 48.451756, lng: 17.338401 },
    { lat: 48.451761, lng: 17.338851 },
    { lat: 48.451766, lng: 17.339301 },
    { lat: 48.451771, lng: 17.339709 },
    { lat: 48.451781, lng: 17.340146 },
    { lat: 48.451786, lng: 17.340568 },
    { lat: 48.451791, lng: 17.341013 },
    { lat: 48.451797, lng: 17.341447 },
    { lat: 48.451803, lng: 17.341976 },
    { lat: 48.45181, lng: 17.342403 },
    { lat: 48.451815, lng: 17.342829 },
    { lat: 48.451829, lng: 17.343756 },
    { lat: 48.451835, lng: 17.344168 },
    { lat: 48.451843, lng: 17.344562 },
    { lat: 48.45185, lng: 17.344875 },
    { lat: 48.451856, lng: 17.345349 },
    { lat: 48.451871, lng: 17.345753 },
    { lat: 48.451879, lng: 17.346194 },
    { lat: 48.4518655, lng: 17.3464378 },
  ],
  SuchánadParnou: [
    { lat: 48.3882744, lng: 17.4756244 },
    { lat: 48.3884012, lng: 17.4758917 },
    { lat: 48.3887036, lng: 17.4766601 },
    { lat: 48.3887944, lng: 17.4771608 },
    { lat: 48.3889793, lng: 17.4777874 },
    { lat: 48.3895082, lng: 17.4791126 },
    { lat: 48.3900264, lng: 17.4802347 },
    { lat: 48.3904303, lng: 17.4816156 },
    { lat: 48.3916292, lng: 17.4845819 },
    { lat: 48.391985, lng: 17.4853384 },
    { lat: 48.3938259, lng: 17.4888733 },
    { lat: 48.3958041, lng: 17.4921085 },
    { lat: 48.3974578, lng: 17.4945066 },
    { lat: 48.3989929, lng: 17.4971712 },
    { lat: 48.3996255, lng: 17.4983712 },
    { lat: 48.3999398, lng: 17.4988184 },
    { lat: 48.40115, lng: 17.501475 },
    { lat: 48.4024863, lng: 17.5038602 },
    { lat: 48.4029526, lng: 17.5036802 },
    { lat: 48.4031662, lng: 17.5035058 },
    { lat: 48.4035452, lng: 17.5033927 },
    { lat: 48.4038183, lng: 17.5031074 },
    { lat: 48.403928, lng: 17.5030585 },
    { lat: 48.4043281, lng: 17.5030796 },
    { lat: 48.4047155, lng: 17.5029753 },
    { lat: 48.4050414, lng: 17.5026684 },
    { lat: 48.4051197, lng: 17.5026548 },
    { lat: 48.405362, lng: 17.5026129 },
    { lat: 48.4061749, lng: 17.5022726 },
    { lat: 48.4063619, lng: 17.502123 },
    { lat: 48.4066797, lng: 17.5018688 },
    { lat: 48.407244, lng: 17.5011716 },
    { lat: 48.4072917, lng: 17.5013887 },
    { lat: 48.4072096, lng: 17.5015192 },
    { lat: 48.4072303, lng: 17.5016822 },
    { lat: 48.4073567, lng: 17.5018573 },
    { lat: 48.4073828, lng: 17.5019844 },
    { lat: 48.4072135, lng: 17.5021073 },
    { lat: 48.4072311, lng: 17.5021531 },
    { lat: 48.407372, lng: 17.5021334 },
    { lat: 48.4077163, lng: 17.5018285 },
    { lat: 48.408301, lng: 17.5038025 },
    { lat: 48.408654, lng: 17.5037938 },
    { lat: 48.4091212, lng: 17.5045481 },
    { lat: 48.409697, lng: 17.5057976 },
    { lat: 48.4104554, lng: 17.5068654 },
    { lat: 48.4120512, lng: 17.5084526 },
    { lat: 48.4118671, lng: 17.5089498 },
    { lat: 48.4118049, lng: 17.5096342 },
    { lat: 48.4122106, lng: 17.5108426 },
    { lat: 48.4125655, lng: 17.5111994 },
    { lat: 48.4128579, lng: 17.5114934 },
    { lat: 48.4132053, lng: 17.5118427 },
    { lat: 48.4139179, lng: 17.5127105 },
    { lat: 48.4159487, lng: 17.5151839 },
    { lat: 48.416251, lng: 17.515552 },
    { lat: 48.416257, lng: 17.5153645 },
    { lat: 48.416315, lng: 17.5151762 },
    { lat: 48.4165676, lng: 17.5147999 },
    { lat: 48.4173296, lng: 17.5144583 },
    { lat: 48.4177919, lng: 17.5143407 },
    { lat: 48.4180134, lng: 17.5141815 },
    { lat: 48.4186998, lng: 17.5132623 },
    { lat: 48.4196206, lng: 17.5123285 },
    { lat: 48.4209112, lng: 17.5115307 },
    { lat: 48.4213838, lng: 17.5110782 },
    { lat: 48.4217665, lng: 17.5105131 },
    { lat: 48.4230541, lng: 17.5098883 },
    { lat: 48.4240483, lng: 17.5094058 },
    { lat: 48.4246658, lng: 17.5088228 },
    { lat: 48.4249344, lng: 17.5084821 },
    { lat: 48.425125, lng: 17.5081236 },
    { lat: 48.4255463, lng: 17.5069591 },
    { lat: 48.426167, lng: 17.5056459 },
    { lat: 48.4262959, lng: 17.5052599 },
    { lat: 48.4265544, lng: 17.5040951 },
    { lat: 48.4272376, lng: 17.5031412 },
    { lat: 48.427633, lng: 17.5026997 },
    { lat: 48.4279438, lng: 17.5022085 },
    { lat: 48.4281042, lng: 17.5018567 },
    { lat: 48.4287435, lng: 17.5009219 },
    { lat: 48.4294602, lng: 17.5001032 },
    { lat: 48.4298932, lng: 17.4997579 },
    { lat: 48.4315509, lng: 17.498785 },
    { lat: 48.4321413, lng: 17.4983586 },
    { lat: 48.4331979, lng: 17.4978395 },
    { lat: 48.4340178, lng: 17.4971693 },
    { lat: 48.4345422, lng: 17.4968726 },
    { lat: 48.43545, lng: 17.4960777 },
    { lat: 48.4362183, lng: 17.4954999 },
    { lat: 48.4366841, lng: 17.4950168 },
    { lat: 48.4369317, lng: 17.4948764 },
    { lat: 48.4381111, lng: 17.4940549 },
    { lat: 48.4399809, lng: 17.4924961 },
    { lat: 48.4427535, lng: 17.4896571 },
    { lat: 48.4462202, lng: 17.4867973 },
    { lat: 48.4475207, lng: 17.4844987 },
    { lat: 48.4470042, lng: 17.4825373 },
    { lat: 48.4466877, lng: 17.4818134 },
    { lat: 48.4463195, lng: 17.4811247 },
    { lat: 48.4461192, lng: 17.4808727 },
    { lat: 48.4461528, lng: 17.480817 },
    { lat: 48.4458853, lng: 17.4805171 },
    { lat: 48.4450702, lng: 17.4794692 },
    { lat: 48.4442975, lng: 17.4782666 },
    { lat: 48.4435072, lng: 17.4771639 },
    { lat: 48.4429007, lng: 17.4772348 },
    { lat: 48.4418862, lng: 17.4776157 },
    { lat: 48.4413669, lng: 17.4779912 },
    { lat: 48.4406372, lng: 17.4781123 },
    { lat: 48.4402029, lng: 17.4789105 },
    { lat: 48.4401169, lng: 17.4792598 },
    { lat: 48.4399611, lng: 17.4794539 },
    { lat: 48.4396809, lng: 17.4795766 },
    { lat: 48.4394959, lng: 17.4794925 },
    { lat: 48.4388347, lng: 17.4797292 },
    { lat: 48.4380493, lng: 17.4805313 },
    { lat: 48.4374827, lng: 17.4814891 },
    { lat: 48.4372428, lng: 17.4821317 },
    { lat: 48.4370008, lng: 17.4825129 },
    { lat: 48.4376003, lng: 17.4840777 },
    { lat: 48.437247, lng: 17.484115 },
    { lat: 48.4369151, lng: 17.4845757 },
    { lat: 48.4370145, lng: 17.4848227 },
    { lat: 48.4345936, lng: 17.48684 },
    { lat: 48.4336141, lng: 17.4878745 },
    { lat: 48.4331941, lng: 17.4885431 },
    { lat: 48.4322785, lng: 17.4865245 },
    { lat: 48.4317719, lng: 17.4856661 },
    { lat: 48.4316191, lng: 17.4859258 },
    { lat: 48.4315154, lng: 17.4859468 },
    { lat: 48.4314895, lng: 17.486127 },
    { lat: 48.431412, lng: 17.4860628 },
    { lat: 48.4313867, lng: 17.4861436 },
    { lat: 48.4309567, lng: 17.4865861 },
    { lat: 48.430812, lng: 17.4866561 },
    { lat: 48.4301103, lng: 17.4874409 },
    { lat: 48.4286302, lng: 17.4844743 },
    { lat: 48.4281045, lng: 17.4831131 },
    { lat: 48.4278345, lng: 17.4832159 },
    { lat: 48.4279322, lng: 17.4839272 },
    { lat: 48.4278602, lng: 17.4846392 },
    { lat: 48.4279318, lng: 17.4851896 },
    { lat: 48.4279328, lng: 17.4856112 },
    { lat: 48.4278031, lng: 17.4862459 },
    { lat: 48.4277118, lng: 17.4861947 },
    { lat: 48.42518, lng: 17.4808326 },
    { lat: 48.4255732, lng: 17.4797612 },
    { lat: 48.4258015, lng: 17.4793259 },
    { lat: 48.4231645, lng: 17.4737904 },
    { lat: 48.422006, lng: 17.4752328 },
    { lat: 48.421685, lng: 17.4746311 },
    { lat: 48.4198115, lng: 17.4721737 },
    { lat: 48.4121535, lng: 17.4675632 },
    { lat: 48.4124832, lng: 17.4670894 },
    { lat: 48.4125305, lng: 17.4669265 },
    { lat: 48.4125178, lng: 17.4654999 },
    { lat: 48.4124411, lng: 17.465275 },
    { lat: 48.4121083, lng: 17.4649806 },
    { lat: 48.4123164, lng: 17.4637943 },
    { lat: 48.4127221, lng: 17.4626039 },
    { lat: 48.4128168, lng: 17.4621109 },
    { lat: 48.4128811, lng: 17.4614083 },
    { lat: 48.4129966, lng: 17.4610957 },
    { lat: 48.4131928, lng: 17.4607565 },
    { lat: 48.4132902, lng: 17.4599357 },
    { lat: 48.4135447, lng: 17.459294 },
    { lat: 48.4137089, lng: 17.4582683 },
    { lat: 48.4136569, lng: 17.4576516 },
    { lat: 48.4136817, lng: 17.4566334 },
    { lat: 48.4138746, lng: 17.4558257 },
    { lat: 48.4139263, lng: 17.45449 },
    { lat: 48.4140566, lng: 17.4533206 },
    { lat: 48.414015, lng: 17.4528136 },
    { lat: 48.4138368, lng: 17.452312 },
    { lat: 48.4138931, lng: 17.4510989 },
    { lat: 48.4137704, lng: 17.4504036 },
    { lat: 48.4140231, lng: 17.4494557 },
    { lat: 48.4138479, lng: 17.4493809 },
    { lat: 48.4132628, lng: 17.4491898 },
    { lat: 48.4130143, lng: 17.4483649 },
    { lat: 48.4129007, lng: 17.4478283 },
    { lat: 48.4125847, lng: 17.4476926 },
    { lat: 48.4124258, lng: 17.4478565 },
    { lat: 48.4123193, lng: 17.4480622 },
    { lat: 48.4118631, lng: 17.4471862 },
    { lat: 48.4117399, lng: 17.4470818 },
    { lat: 48.4116578, lng: 17.4468954 },
    { lat: 48.4112505, lng: 17.4464101 },
    { lat: 48.41122, lng: 17.4463043 },
    { lat: 48.4106827, lng: 17.4456623 },
    { lat: 48.4105031, lng: 17.4450837 },
    { lat: 48.410513, lng: 17.4448178 },
    { lat: 48.4103838, lng: 17.4445295 },
    { lat: 48.4099662, lng: 17.4449006 },
    { lat: 48.4093963, lng: 17.4422321 },
    { lat: 48.4092341, lng: 17.4422049 },
    { lat: 48.4090378, lng: 17.4415954 },
    { lat: 48.4090195, lng: 17.4411349 },
    { lat: 48.4074434, lng: 17.440603 },
    { lat: 48.4074091, lng: 17.4412806 },
    { lat: 48.4071337, lng: 17.442386 },
    { lat: 48.4070401, lng: 17.4426151 },
    { lat: 48.4070113, lng: 17.4430593 },
    { lat: 48.4067491, lng: 17.4434605 },
    { lat: 48.4058934, lng: 17.4443756 },
    { lat: 48.4054881, lng: 17.4439071 },
    { lat: 48.4051569, lng: 17.4445145 },
    { lat: 48.4045252, lng: 17.4461388 },
    { lat: 48.4044618, lng: 17.4472922 },
    { lat: 48.4050785, lng: 17.4479912 },
    { lat: 48.4049358, lng: 17.4483553 },
    { lat: 48.4040255, lng: 17.4501417 },
    { lat: 48.4049275, lng: 17.4512201 },
    { lat: 48.4046708, lng: 17.4518791 },
    { lat: 48.4046545, lng: 17.4527651 },
    { lat: 48.4054174, lng: 17.4537397 },
    { lat: 48.4051912, lng: 17.4544164 },
    { lat: 48.4074969, lng: 17.457072 },
    { lat: 48.4066125, lng: 17.4612098 },
    { lat: 48.4065653, lng: 17.4620911 },
    { lat: 48.3981558, lng: 17.4583402 },
    { lat: 48.3952421, lng: 17.4598158 },
    { lat: 48.3957699, lng: 17.4604336 },
    { lat: 48.391607, lng: 17.4653134 },
    { lat: 48.3915946, lng: 17.4653305 },
    { lat: 48.3917017, lng: 17.4656074 },
    { lat: 48.3914093, lng: 17.4665393 },
    { lat: 48.3911866, lng: 17.4678715 },
    { lat: 48.3912266, lng: 17.4704238 },
    { lat: 48.3908259, lng: 17.4723777 },
    { lat: 48.3905914, lng: 17.4728058 },
    { lat: 48.3893517, lng: 17.4746559 },
    { lat: 48.389137, lng: 17.4749159 },
    { lat: 48.3882744, lng: 17.4756244 },
  ],
  Naháč: [
    { lat: 48.5636094, lng: 17.5358262 },
    { lat: 48.5642737, lng: 17.5351161 },
    { lat: 48.5643769, lng: 17.5350056 },
    { lat: 48.5647484, lng: 17.5346087 },
    { lat: 48.5651383, lng: 17.5344173 },
    { lat: 48.5655816, lng: 17.533707 },
    { lat: 48.565884, lng: 17.5332226 },
    { lat: 48.5661304, lng: 17.5328277 },
    { lat: 48.5665633, lng: 17.5324087 },
    { lat: 48.5675402, lng: 17.5321778 },
    { lat: 48.5683252, lng: 17.5316277 },
    { lat: 48.5688181, lng: 17.5309979 },
    { lat: 48.5690153, lng: 17.530681 },
    { lat: 48.5720158, lng: 17.5258591 },
    { lat: 48.5751471, lng: 17.5207972 },
    { lat: 48.5761864, lng: 17.5191476 },
    { lat: 48.5768994, lng: 17.5155085 },
    { lat: 48.5775196, lng: 17.5123427 },
    { lat: 48.5775456, lng: 17.5122099 },
    { lat: 48.5772993, lng: 17.5105764 },
    { lat: 48.5772957, lng: 17.5101814 },
    { lat: 48.5774331, lng: 17.5088262 },
    { lat: 48.5774433, lng: 17.508726 },
    { lat: 48.5774898, lng: 17.5086292 },
    { lat: 48.5779958, lng: 17.5075771 },
    { lat: 48.5786138, lng: 17.5070853 },
    { lat: 48.5794268, lng: 17.50673 },
    { lat: 48.5797454, lng: 17.5065198 },
    { lat: 48.5797352, lng: 17.5063715 },
    { lat: 48.5796044, lng: 17.5044695 },
    { lat: 48.5795044, lng: 17.5036619 },
    { lat: 48.5795293, lng: 17.5036197 },
    { lat: 48.5802789, lng: 17.5023473 },
    { lat: 48.5808848, lng: 17.5013999 },
    { lat: 48.5816095, lng: 17.5012888 },
    { lat: 48.5820299, lng: 17.5014162 },
    { lat: 48.5824212, lng: 17.5014069 },
    { lat: 48.5829552, lng: 17.501354 },
    { lat: 48.583238, lng: 17.5012018 },
    { lat: 48.5833904, lng: 17.5008629 },
    { lat: 48.5838862, lng: 17.500352 },
    { lat: 48.584026, lng: 17.4996212 },
    { lat: 48.5844523, lng: 17.4989922 },
    { lat: 48.584531, lng: 17.4987571 },
    { lat: 48.5845822, lng: 17.4982678 },
    { lat: 48.5845876, lng: 17.4972561 },
    { lat: 48.5863794, lng: 17.4969136 },
    { lat: 48.5871386, lng: 17.4967685 },
    { lat: 48.5871728, lng: 17.496762 },
    { lat: 48.5878315, lng: 17.4964029 },
    { lat: 48.5884606, lng: 17.49606 },
    { lat: 48.5891657, lng: 17.4958367 },
    { lat: 48.589527, lng: 17.495946 },
    { lat: 48.589534, lng: 17.495904 },
    { lat: 48.58922, lng: 17.494698 },
    { lat: 48.58898, lng: 17.493764 },
    { lat: 48.588788, lng: 17.493018 },
    { lat: 48.588783, lng: 17.492984 },
    { lat: 48.588748, lng: 17.492773 },
    { lat: 48.588777, lng: 17.492135 },
    { lat: 48.589029, lng: 17.490203 },
    { lat: 48.58904, lng: 17.489826 },
    { lat: 48.589148, lng: 17.489449 },
    { lat: 48.58949, lng: 17.488673 },
    { lat: 48.589495, lng: 17.488683 },
    { lat: 48.589493, lng: 17.488553 },
    { lat: 48.589471, lng: 17.488225 },
    { lat: 48.589391, lng: 17.488237 },
    { lat: 48.589084, lng: 17.487942 },
    { lat: 48.588787, lng: 17.487821 },
    { lat: 48.588744, lng: 17.487713 },
    { lat: 48.588788, lng: 17.487559 },
    { lat: 48.588683, lng: 17.487458 },
    { lat: 48.588623, lng: 17.487371 },
    { lat: 48.588479, lng: 17.487393 },
    { lat: 48.588321, lng: 17.486927 },
    { lat: 48.588297, lng: 17.48686 },
    { lat: 48.588105, lng: 17.486449 },
    { lat: 48.587985, lng: 17.486331 },
    { lat: 48.587816, lng: 17.486129 },
    { lat: 48.58784, lng: 17.48605 },
    { lat: 48.587889, lng: 17.48603 },
    { lat: 48.587866, lng: 17.485948 },
    { lat: 48.587798, lng: 17.485988 },
    { lat: 48.587682, lng: 17.485984 },
    { lat: 48.587649, lng: 17.485926 },
    { lat: 48.587619, lng: 17.485749 },
    { lat: 48.587325, lng: 17.485448 },
    { lat: 48.587116, lng: 17.485476 },
    { lat: 48.58703, lng: 17.485595 },
    { lat: 48.586847, lng: 17.485499 },
    { lat: 48.58662, lng: 17.485367 },
    { lat: 48.586603, lng: 17.485368 },
    { lat: 48.586524, lng: 17.485265 },
    { lat: 48.5864, lng: 17.485261 },
    { lat: 48.586275, lng: 17.48533 },
    { lat: 48.585955, lng: 17.485128 },
    { lat: 48.585853, lng: 17.485102 },
    { lat: 48.585758, lng: 17.484974 },
    { lat: 48.585557, lng: 17.484801 },
    { lat: 48.585506, lng: 17.48469 },
    { lat: 48.58543, lng: 17.484163 },
    { lat: 48.585418, lng: 17.484125 },
    { lat: 48.585336, lng: 17.484037 },
    { lat: 48.585213, lng: 17.484034 },
    { lat: 48.585055, lng: 17.483873 },
    { lat: 48.584838, lng: 17.483801 },
    { lat: 48.584781, lng: 17.483711 },
    { lat: 48.584615, lng: 17.48357 },
    { lat: 48.584434, lng: 17.483556 },
    { lat: 48.58429, lng: 17.483597 },
    { lat: 48.5841, lng: 17.483699 },
    { lat: 48.584049, lng: 17.483729 },
    { lat: 48.583872, lng: 17.483794 },
    { lat: 48.58381, lng: 17.483817 },
    { lat: 48.583768, lng: 17.483704 },
    { lat: 48.583671, lng: 17.483816 },
    { lat: 48.583462, lng: 17.483597 },
    { lat: 48.583473, lng: 17.483297 },
    { lat: 48.58345, lng: 17.483037 },
    { lat: 48.58334, lng: 17.482826 },
    { lat: 48.583253, lng: 17.482573 },
    { lat: 48.583146, lng: 17.482492 },
    { lat: 48.582988, lng: 17.482298 },
    { lat: 48.582904, lng: 17.482228 },
    { lat: 48.582733, lng: 17.481919 },
    { lat: 48.582619, lng: 17.481688 },
    { lat: 48.582596, lng: 17.481632 },
    { lat: 48.582564, lng: 17.481539 },
    { lat: 48.582547, lng: 17.481489 },
    { lat: 48.582506, lng: 17.481422 },
    { lat: 48.582402, lng: 17.481391 },
    { lat: 48.582416, lng: 17.48113 },
    { lat: 48.58231, lng: 17.480408 },
    { lat: 48.582203, lng: 17.48038 },
    { lat: 48.582174, lng: 17.479819 },
    { lat: 48.58206, lng: 17.479488 },
    { lat: 48.582038, lng: 17.479418 },
    { lat: 48.582146, lng: 17.479313 },
    { lat: 48.582102, lng: 17.479201 },
    { lat: 48.5821, lng: 17.478629 },
    { lat: 48.58203, lng: 17.478619 },
    { lat: 48.582183, lng: 17.478401 },
    { lat: 48.582181, lng: 17.478269 },
    { lat: 48.582246, lng: 17.478166 },
    { lat: 48.582315, lng: 17.477979 },
    { lat: 48.582214, lng: 17.477771 },
    { lat: 48.582368, lng: 17.477119 },
    { lat: 48.582443, lng: 17.477077 },
    { lat: 48.582463, lng: 17.477011 },
    { lat: 48.582522, lng: 17.476827 },
    { lat: 48.582568, lng: 17.476407 },
    { lat: 48.582646, lng: 17.47634 },
    { lat: 48.582786, lng: 17.476095 },
    { lat: 48.5827, lng: 17.476158 },
    { lat: 48.582642, lng: 17.47605 },
    { lat: 48.582705, lng: 17.475783 },
    { lat: 48.582714, lng: 17.475651 },
    { lat: 48.582669, lng: 17.475307 },
    { lat: 48.582677, lng: 17.47502 },
    { lat: 48.582571, lng: 17.474821 },
    { lat: 48.582584, lng: 17.474489 },
    { lat: 48.582529, lng: 17.474337 },
    { lat: 48.582374, lng: 17.473903 },
    { lat: 48.58229, lng: 17.472208 },
    { lat: 48.582155, lng: 17.471242 },
    { lat: 48.582078, lng: 17.470691 },
    { lat: 48.581991, lng: 17.470569 },
    { lat: 48.581799, lng: 17.4703 },
    { lat: 48.581422, lng: 17.470158 },
    { lat: 48.581271, lng: 17.470182 },
    { lat: 48.581245, lng: 17.470185 },
    { lat: 48.581207, lng: 17.470191 },
    { lat: 48.580907, lng: 17.470227 },
    { lat: 48.579985, lng: 17.47097 },
    { lat: 48.579541, lng: 17.471331 },
    { lat: 48.578765, lng: 17.470865 },
    { lat: 48.578733, lng: 17.470863 },
    { lat: 48.578731, lng: 17.470863 },
    { lat: 48.578498, lng: 17.47085 },
    { lat: 48.578188, lng: 17.470832 },
    { lat: 48.577576, lng: 17.470983 },
    { lat: 48.576751, lng: 17.47118 },
    { lat: 48.576179, lng: 17.470696 },
    { lat: 48.575944, lng: 17.470497 },
    { lat: 48.575907, lng: 17.470466 },
    { lat: 48.575257, lng: 17.470512 },
    { lat: 48.575199, lng: 17.470644 },
    { lat: 48.574951, lng: 17.470671 },
    { lat: 48.574808, lng: 17.470856 },
    { lat: 48.574739, lng: 17.470767 },
    { lat: 48.574652, lng: 17.470831 },
    { lat: 48.574601, lng: 17.470869 },
    { lat: 48.574528, lng: 17.470923 },
    { lat: 48.574022, lng: 17.471797 },
    { lat: 48.573946, lng: 17.471928 },
    { lat: 48.573937, lng: 17.471946 },
    { lat: 48.5734491, lng: 17.4729479 },
    { lat: 48.5726374, lng: 17.4739426 },
    { lat: 48.5717531, lng: 17.4747859 },
    { lat: 48.571656, lng: 17.475121 },
    { lat: 48.5707943, lng: 17.4757235 },
    { lat: 48.5704073, lng: 17.4759039 },
    { lat: 48.5699517, lng: 17.4765165 },
    { lat: 48.569509, lng: 17.4775561 },
    { lat: 48.5693682, lng: 17.4780548 },
    { lat: 48.5695054, lng: 17.4794737 },
    { lat: 48.5697472, lng: 17.4803226 },
    { lat: 48.5694721, lng: 17.4815825 },
    { lat: 48.5691234, lng: 17.4825243 },
    { lat: 48.5686171, lng: 17.4833653 },
    { lat: 48.5682748, lng: 17.483701 },
    { lat: 48.5678837, lng: 17.4848229 },
    { lat: 48.567091, lng: 17.4854986 },
    { lat: 48.5665745, lng: 17.4862654 },
    { lat: 48.5664234, lng: 17.4859019 },
    { lat: 48.5658394, lng: 17.4852807 },
    { lat: 48.5657841, lng: 17.4867282 },
    { lat: 48.5659217, lng: 17.4872374 },
    { lat: 48.5657072, lng: 17.4881211 },
    { lat: 48.5653303, lng: 17.4909723 },
    { lat: 48.5643573, lng: 17.4930814 },
    { lat: 48.5637207, lng: 17.4939507 },
    { lat: 48.5632218, lng: 17.4949094 },
    { lat: 48.5623557, lng: 17.4954444 },
    { lat: 48.561571, lng: 17.4958006 },
    { lat: 48.5604974, lng: 17.4965506 },
    { lat: 48.5599925, lng: 17.4965305 },
    { lat: 48.559855, lng: 17.4966602 },
    { lat: 48.5595018, lng: 17.4974063 },
    { lat: 48.5591931, lng: 17.4973644 },
    { lat: 48.5574196, lng: 17.4965787 },
    { lat: 48.5561341, lng: 17.496276 },
    { lat: 48.5556898, lng: 17.4960221 },
    { lat: 48.5550875, lng: 17.4959622 },
    { lat: 48.5543631, lng: 17.4961296 },
    { lat: 48.5521004, lng: 17.4972886 },
    { lat: 48.550942, lng: 17.4984083 },
    { lat: 48.5505142, lng: 17.4983238 },
    { lat: 48.5498299, lng: 17.4984989 },
    { lat: 48.5487787, lng: 17.498982 },
    { lat: 48.5477663, lng: 17.4993259 },
    { lat: 48.5475744, lng: 17.4995604 },
    { lat: 48.5466828, lng: 17.4998318 },
    { lat: 48.5458625, lng: 17.5003214 },
    { lat: 48.5451494, lng: 17.5005455 },
    { lat: 48.5446996, lng: 17.5009286 },
    { lat: 48.5433769, lng: 17.5017019 },
    { lat: 48.5424263, lng: 17.5024333 },
    { lat: 48.5416463, lng: 17.5026151 },
    { lat: 48.5400614, lng: 17.5028403 },
    { lat: 48.5397089, lng: 17.5030129 },
    { lat: 48.5390451, lng: 17.5036739 },
    { lat: 48.5378842, lng: 17.5054438 },
    { lat: 48.5368561, lng: 17.506851 },
    { lat: 48.5369516, lng: 17.5073389 },
    { lat: 48.5369549, lng: 17.5073573 },
    { lat: 48.5374606, lng: 17.5072271 },
    { lat: 48.5376276, lng: 17.5069581 },
    { lat: 48.5376454, lng: 17.5067446 },
    { lat: 48.5377507, lng: 17.5067293 },
    { lat: 48.5377708, lng: 17.5066217 },
    { lat: 48.5378751, lng: 17.5065396 },
    { lat: 48.5380646, lng: 17.5066746 },
    { lat: 48.538125, lng: 17.5066495 },
    { lat: 48.5382108, lng: 17.5067561 },
    { lat: 48.5382884, lng: 17.5067484 },
    { lat: 48.5383917, lng: 17.506406 },
    { lat: 48.538571, lng: 17.5062592 },
    { lat: 48.5386204, lng: 17.5060771 },
    { lat: 48.5387456, lng: 17.505951 },
    { lat: 48.5387029, lng: 17.5058492 },
    { lat: 48.5389356, lng: 17.5057952 },
    { lat: 48.5389614, lng: 17.5056357 },
    { lat: 48.539076, lng: 17.5055368 },
    { lat: 48.5391228, lng: 17.5053497 },
    { lat: 48.5390712, lng: 17.505194 },
    { lat: 48.539511, lng: 17.5047433 },
    { lat: 48.539516, lng: 17.5045554 },
    { lat: 48.5397067, lng: 17.5047327 },
    { lat: 48.5397947, lng: 17.5047125 },
    { lat: 48.5398929, lng: 17.5048352 },
    { lat: 48.5400252, lng: 17.5048203 },
    { lat: 48.5400558, lng: 17.5046958 },
    { lat: 48.5402976, lng: 17.5045766 },
    { lat: 48.5403275, lng: 17.5046368 },
    { lat: 48.5403977, lng: 17.5046209 },
    { lat: 48.5404573, lng: 17.5045118 },
    { lat: 48.5406296, lng: 17.5044341 },
    { lat: 48.5406699, lng: 17.5046214 },
    { lat: 48.5409267, lng: 17.5046659 },
    { lat: 48.5409857, lng: 17.5047753 },
    { lat: 48.5412751, lng: 17.5047633 },
    { lat: 48.5412971, lng: 17.5046999 },
    { lat: 48.5414505, lng: 17.5047158 },
    { lat: 48.5415267, lng: 17.5048299 },
    { lat: 48.5418268, lng: 17.5047191 },
    { lat: 48.5419578, lng: 17.5048074 },
    { lat: 48.5420434, lng: 17.5046607 },
    { lat: 48.5422221, lng: 17.504684 },
    { lat: 48.5425927, lng: 17.504899 },
    { lat: 48.5430005, lng: 17.5050595 },
    { lat: 48.543055, lng: 17.5050124 },
    { lat: 48.5431805, lng: 17.5050648 },
    { lat: 48.543257, lng: 17.5051518 },
    { lat: 48.5434386, lng: 17.5050937 },
    { lat: 48.5435546, lng: 17.5051236 },
    { lat: 48.5436652, lng: 17.5053518 },
    { lat: 48.5438546, lng: 17.5052224 },
    { lat: 48.5438965, lng: 17.5049772 },
    { lat: 48.5439581, lng: 17.5050934 },
    { lat: 48.544026, lng: 17.5051124 },
    { lat: 48.5440596, lng: 17.504986 },
    { lat: 48.5441685, lng: 17.5050647 },
    { lat: 48.544235, lng: 17.5049717 },
    { lat: 48.5443402, lng: 17.5049735 },
    { lat: 48.5444037, lng: 17.5050673 },
    { lat: 48.5446731, lng: 17.5050579 },
    { lat: 48.5447066, lng: 17.5049818 },
    { lat: 48.5447601, lng: 17.5050079 },
    { lat: 48.5448382, lng: 17.50492 },
    { lat: 48.544833, lng: 17.5050162 },
    { lat: 48.5449316, lng: 17.5051634 },
    { lat: 48.5451188, lng: 17.5049485 },
    { lat: 48.54516, lng: 17.5049746 },
    { lat: 48.5451507, lng: 17.5050772 },
    { lat: 48.5453295, lng: 17.505134 },
    { lat: 48.5453648, lng: 17.5050173 },
    { lat: 48.5454655, lng: 17.505018 },
    { lat: 48.5452718, lng: 17.5066703 },
    { lat: 48.5451742, lng: 17.508434 },
    { lat: 48.5450044, lng: 17.5091711 },
    { lat: 48.5445514, lng: 17.5094341 },
    { lat: 48.5440334, lng: 17.5096348 },
    { lat: 48.5437213, lng: 17.5098942 },
    { lat: 48.5431631, lng: 17.5100473 },
    { lat: 48.5428989, lng: 17.510247 },
    { lat: 48.5424948, lng: 17.5107606 },
    { lat: 48.5423895, lng: 17.5108115 },
    { lat: 48.5421444, lng: 17.5111096 },
    { lat: 48.5414749, lng: 17.5116437 },
    { lat: 48.5417309, lng: 17.5123294 },
    { lat: 48.5413301, lng: 17.5127573 },
    { lat: 48.5413271, lng: 17.5129591 },
    { lat: 48.5409127, lng: 17.5133484 },
    { lat: 48.5397051, lng: 17.5142482 },
    { lat: 48.538914, lng: 17.5145932 },
    { lat: 48.5379975, lng: 17.5150956 },
    { lat: 48.5370601, lng: 17.5159267 },
    { lat: 48.5368975, lng: 17.5159816 },
    { lat: 48.5362049, lng: 17.5172338 },
    { lat: 48.5358231, lng: 17.5181545 },
    { lat: 48.5356341, lng: 17.5188571 },
    { lat: 48.5355007, lng: 17.519723 },
    { lat: 48.5350934, lng: 17.5214128 },
    { lat: 48.5342328, lng: 17.5229568 },
    { lat: 48.533766, lng: 17.5236424 },
    { lat: 48.5328343, lng: 17.524672 },
    { lat: 48.5315819, lng: 17.5255408 },
    { lat: 48.530399, lng: 17.5267384 },
    { lat: 48.5288991, lng: 17.528669 },
    { lat: 48.5270848, lng: 17.5305059 },
    { lat: 48.5254481, lng: 17.5320605 },
    { lat: 48.5230811, lng: 17.5340498 },
    { lat: 48.5209813, lng: 17.5360841 },
    { lat: 48.5203471, lng: 17.536982 },
    { lat: 48.5199613, lng: 17.5377514 },
    { lat: 48.5198551, lng: 17.5378935 },
    { lat: 48.519472, lng: 17.5381026 },
    { lat: 48.5184554, lng: 17.5395742 },
    { lat: 48.518411, lng: 17.5396568 },
    { lat: 48.5185011, lng: 17.5398829 },
    { lat: 48.5189725, lng: 17.5410824 },
    { lat: 48.5192606, lng: 17.5414929 },
    { lat: 48.5195634, lng: 17.5417781 },
    { lat: 48.5213726, lng: 17.5427213 },
    { lat: 48.5219411, lng: 17.5430841 },
    { lat: 48.5222222, lng: 17.5431216 },
    { lat: 48.5226348, lng: 17.5433386 },
    { lat: 48.5233112, lng: 17.5444764 },
    { lat: 48.5244512, lng: 17.5456229 },
    { lat: 48.526151, lng: 17.5476014 },
    { lat: 48.5262768, lng: 17.5475373 },
    { lat: 48.5263669, lng: 17.5473569 },
    { lat: 48.5264693, lng: 17.5473489 },
    { lat: 48.5265243, lng: 17.5471675 },
    { lat: 48.5268527, lng: 17.5468602 },
    { lat: 48.5269617, lng: 17.5465506 },
    { lat: 48.5270382, lng: 17.5464576 },
    { lat: 48.5271383, lng: 17.5459512 },
    { lat: 48.5272478, lng: 17.545774 },
    { lat: 48.5276578, lng: 17.5454521 },
    { lat: 48.5277557, lng: 17.5452171 },
    { lat: 48.5278517, lng: 17.5452346 },
    { lat: 48.5281816, lng: 17.5449705 },
    { lat: 48.5282958, lng: 17.5447407 },
    { lat: 48.5384145, lng: 17.557155 },
    { lat: 48.5384339, lng: 17.5571216 },
    { lat: 48.5388987, lng: 17.5566168 },
    { lat: 48.5391749, lng: 17.5561623 },
    { lat: 48.5393993, lng: 17.5560859 },
    { lat: 48.5397613, lng: 17.5555805 },
    { lat: 48.5401821, lng: 17.5551113 },
    { lat: 48.5413726, lng: 17.5543482 },
    { lat: 48.542125, lng: 17.554034 },
    { lat: 48.5444599, lng: 17.5524576 },
    { lat: 48.5453342, lng: 17.5518047 },
    { lat: 48.5453888, lng: 17.5511693 },
    { lat: 48.5455909, lng: 17.5507403 },
    { lat: 48.5461795, lng: 17.5500137 },
    { lat: 48.5466824, lng: 17.54872 },
    { lat: 48.547328, lng: 17.5459561 },
    { lat: 48.5477442, lng: 17.5444905 },
    { lat: 48.5480569, lng: 17.543724 },
    { lat: 48.5482866, lng: 17.5432682 },
    { lat: 48.5490059, lng: 17.5422158 },
    { lat: 48.5490628, lng: 17.5417771 },
    { lat: 48.54914, lng: 17.5417346 },
    { lat: 48.5501788, lng: 17.5402914 },
    { lat: 48.5513978, lng: 17.5389648 },
    { lat: 48.5515196, lng: 17.5386403 },
    { lat: 48.5515616, lng: 17.5383587 },
    { lat: 48.5510812, lng: 17.5375543 },
    { lat: 48.5514126, lng: 17.5372047 },
    { lat: 48.5515961, lng: 17.5371433 },
    { lat: 48.5519122, lng: 17.5368036 },
    { lat: 48.5518726, lng: 17.5366603 },
    { lat: 48.5519315, lng: 17.5366451 },
    { lat: 48.5521701, lng: 17.5368106 },
    { lat: 48.5523049, lng: 17.5365862 },
    { lat: 48.5524811, lng: 17.5359994 },
    { lat: 48.552555, lng: 17.5362016 },
    { lat: 48.552637, lng: 17.5362475 },
    { lat: 48.5528594, lng: 17.5359117 },
    { lat: 48.5530723, lng: 17.5353527 },
    { lat: 48.5532377, lng: 17.5352704 },
    { lat: 48.553485, lng: 17.5347193 },
    { lat: 48.5535011, lng: 17.5343698 },
    { lat: 48.5539026, lng: 17.5334368 },
    { lat: 48.5541084, lng: 17.5333508 },
    { lat: 48.554508, lng: 17.5329955 },
    { lat: 48.5547089, lng: 17.5329387 },
    { lat: 48.5551122, lng: 17.5332078 },
    { lat: 48.5553346, lng: 17.5336138 },
    { lat: 48.5554737, lng: 17.5336532 },
    { lat: 48.5554457, lng: 17.5339942 },
    { lat: 48.5556325, lng: 17.5340371 },
    { lat: 48.5556892, lng: 17.534113 },
    { lat: 48.5560156, lng: 17.5340064 },
    { lat: 48.5560669, lng: 17.5342439 },
    { lat: 48.5559574, lng: 17.5342112 },
    { lat: 48.5558737, lng: 17.5343509 },
    { lat: 48.5560053, lng: 17.5347155 },
    { lat: 48.5561634, lng: 17.5347319 },
    { lat: 48.556232, lng: 17.5347823 },
    { lat: 48.5562448, lng: 17.5348767 },
    { lat: 48.5563803, lng: 17.5348037 },
    { lat: 48.5564146, lng: 17.5351498 },
    { lat: 48.5564711, lng: 17.5352907 },
    { lat: 48.5566101, lng: 17.5353113 },
    { lat: 48.5566913, lng: 17.5351659 },
    { lat: 48.5567507, lng: 17.535229 },
    { lat: 48.5568097, lng: 17.5353609 },
    { lat: 48.5567699, lng: 17.5354975 },
    { lat: 48.5568344, lng: 17.5358208 },
    { lat: 48.556986, lng: 17.5360396 },
    { lat: 48.5571193, lng: 17.5360742 },
    { lat: 48.5570798, lng: 17.5379774 },
    { lat: 48.5570071, lng: 17.5388403 },
    { lat: 48.5569525, lng: 17.538871 },
    { lat: 48.556894, lng: 17.5392095 },
    { lat: 48.5569368, lng: 17.5393506 },
    { lat: 48.5569502, lng: 17.5397408 },
    { lat: 48.5568777, lng: 17.5400557 },
    { lat: 48.5570027, lng: 17.5404262 },
    { lat: 48.5570171, lng: 17.5409175 },
    { lat: 48.5571007, lng: 17.5410791 },
    { lat: 48.5570114, lng: 17.5424487 },
    { lat: 48.5578705, lng: 17.5422166 },
    { lat: 48.5584162, lng: 17.542093 },
    { lat: 48.5590138, lng: 17.5418097 },
    { lat: 48.5596207, lng: 17.5414057 },
    { lat: 48.5623936, lng: 17.5379471 },
    { lat: 48.5627142, lng: 17.5370214 },
    { lat: 48.5628886, lng: 17.5366711 },
    { lat: 48.5635012, lng: 17.5358989 },
    { lat: 48.5636094, lng: 17.5358262 },
  ],
  Boleráz: [
    { lat: 48.491078, lng: 17.4508283 },
    { lat: 48.4910451, lng: 17.4508246 },
    { lat: 48.4910322, lng: 17.4507499 },
    { lat: 48.4908833, lng: 17.4507164 },
    { lat: 48.4907948, lng: 17.4505687 },
    { lat: 48.4908008, lng: 17.4505041 },
    { lat: 48.4908646, lng: 17.4505842 },
    { lat: 48.4909254, lng: 17.4505276 },
    { lat: 48.4909853, lng: 17.4503006 },
    { lat: 48.4908844, lng: 17.4502263 },
    { lat: 48.4909072, lng: 17.4500877 },
    { lat: 48.490814, lng: 17.4500631 },
    { lat: 48.4908335, lng: 17.449925 },
    { lat: 48.4907444, lng: 17.4498931 },
    { lat: 48.4907472, lng: 17.4497781 },
    { lat: 48.4906395, lng: 17.4497706 },
    { lat: 48.4906368, lng: 17.4495706 },
    { lat: 48.490583, lng: 17.4495079 },
    { lat: 48.4904184, lng: 17.44969 },
    { lat: 48.4904507, lng: 17.4494015 },
    { lat: 48.4903308, lng: 17.4494129 },
    { lat: 48.4902619, lng: 17.4495006 },
    { lat: 48.4902178, lng: 17.4493785 },
    { lat: 48.4901538, lng: 17.4494362 },
    { lat: 48.4900981, lng: 17.4493603 },
    { lat: 48.4900962, lng: 17.4490854 },
    { lat: 48.4902072, lng: 17.4489866 },
    { lat: 48.4902063, lng: 17.448902 },
    { lat: 48.4900887, lng: 17.4488283 },
    { lat: 48.490099, lng: 17.4487424 },
    { lat: 48.4901682, lng: 17.4486782 },
    { lat: 48.4901051, lng: 17.4484699 },
    { lat: 48.4901638, lng: 17.448294 },
    { lat: 48.4901403, lng: 17.448124 },
    { lat: 48.4900629, lng: 17.4480096 },
    { lat: 48.4901622, lng: 17.4479008 },
    { lat: 48.4901647, lng: 17.4477484 },
    { lat: 48.4899624, lng: 17.4475874 },
    { lat: 48.4900575, lng: 17.4474147 },
    { lat: 48.4899614, lng: 17.4472927 },
    { lat: 48.4896755, lng: 17.4473073 },
    { lat: 48.4896244, lng: 17.4470218 },
    { lat: 48.4894444, lng: 17.4469095 },
    { lat: 48.4893303, lng: 17.4470151 },
    { lat: 48.4892627, lng: 17.446895 },
    { lat: 48.4892749, lng: 17.4467177 },
    { lat: 48.4892004, lng: 17.4466307 },
    { lat: 48.489205, lng: 17.4464559 },
    { lat: 48.4889224, lng: 17.4465217 },
    { lat: 48.4888403, lng: 17.4462976 },
    { lat: 48.4888041, lng: 17.4464378 },
    { lat: 48.4886929, lng: 17.4465519 },
    { lat: 48.4886599, lng: 17.4464257 },
    { lat: 48.4885686, lng: 17.4464483 },
    { lat: 48.488465, lng: 17.4463794 },
    { lat: 48.4884139, lng: 17.4462739 },
    { lat: 48.4884492, lng: 17.4461524 },
    { lat: 48.4885589, lng: 17.4461218 },
    { lat: 48.4884969, lng: 17.4459119 },
    { lat: 48.4883485, lng: 17.4458534 },
    { lat: 48.4883033, lng: 17.445766 },
    { lat: 48.4881075, lng: 17.4457571 },
    { lat: 48.4880059, lng: 17.445935 },
    { lat: 48.4877589, lng: 17.4457831 },
    { lat: 48.4877296, lng: 17.4456438 },
    { lat: 48.487657, lng: 17.4458314 },
    { lat: 48.4875005, lng: 17.4458207 },
    { lat: 48.4874665, lng: 17.4457477 },
    { lat: 48.4875283, lng: 17.4456903 },
    { lat: 48.487445, lng: 17.445591 },
    { lat: 48.487415, lng: 17.4457392 },
    { lat: 48.4872773, lng: 17.4457066 },
    { lat: 48.4872685, lng: 17.4456388 },
    { lat: 48.4873656, lng: 17.4454463 },
    { lat: 48.4871578, lng: 17.4451487 },
    { lat: 48.4871365, lng: 17.4453618 },
    { lat: 48.4870872, lng: 17.4454004 },
    { lat: 48.4869321, lng: 17.4451859 },
    { lat: 48.4868309, lng: 17.4453163 },
    { lat: 48.4867411, lng: 17.4452369 },
    { lat: 48.4865714, lng: 17.4452851 },
    { lat: 48.4864567, lng: 17.4454157 },
    { lat: 48.4861946, lng: 17.4453945 },
    { lat: 48.4860543, lng: 17.4452519 },
    { lat: 48.4859864, lng: 17.4450724 },
    { lat: 48.4858804, lng: 17.4450259 },
    { lat: 48.4857997, lng: 17.4448415 },
    { lat: 48.4856629, lng: 17.4449081 },
    { lat: 48.4853958, lng: 17.444884 },
    { lat: 48.4852212, lng: 17.4449444 },
    { lat: 48.4851542, lng: 17.4447078 },
    { lat: 48.485055, lng: 17.4447486 },
    { lat: 48.4847388, lng: 17.4446089 },
    { lat: 48.4846479, lng: 17.4444249 },
    { lat: 48.4844312, lng: 17.444532 },
    { lat: 48.4844466, lng: 17.4443496 },
    { lat: 48.4844161, lng: 17.4443159 },
    { lat: 48.4841417, lng: 17.4444214 },
    { lat: 48.4841216, lng: 17.4443001 },
    { lat: 48.4838239, lng: 17.4442577 },
    { lat: 48.4836964, lng: 17.443784 },
    { lat: 48.483663, lng: 17.4434421 },
    { lat: 48.4836955, lng: 17.4433662 },
    { lat: 48.4834887, lng: 17.443261 },
    { lat: 48.4833887, lng: 17.4430054 },
    { lat: 48.4832374, lng: 17.4428687 },
    { lat: 48.483071, lng: 17.4425633 },
    { lat: 48.4830365, lng: 17.442149 },
    { lat: 48.4830862, lng: 17.4418195 },
    { lat: 48.4828818, lng: 17.4413983 },
    { lat: 48.4828888, lng: 17.4410509 },
    { lat: 48.482943, lng: 17.4409639 },
    { lat: 48.483019, lng: 17.4401273 },
    { lat: 48.4831595, lng: 17.4399758 },
    { lat: 48.4831694, lng: 17.4398682 },
    { lat: 48.4831149, lng: 17.4397566 },
    { lat: 48.4831453, lng: 17.4396856 },
    { lat: 48.4831064, lng: 17.4395556 },
    { lat: 48.4830473, lng: 17.4394606 },
    { lat: 48.4830108, lng: 17.4395806 },
    { lat: 48.4828087, lng: 17.4393171 },
    { lat: 48.4829109, lng: 17.4391889 },
    { lat: 48.4829108, lng: 17.439043 },
    { lat: 48.4827859, lng: 17.4386849 },
    { lat: 48.48279, lng: 17.4380838 },
    { lat: 48.4824933, lng: 17.4379323 },
    { lat: 48.4820516, lng: 17.4385764 },
    { lat: 48.4817, lng: 17.438868 },
    { lat: 48.4808338, lng: 17.4385373 },
    { lat: 48.4797809, lng: 17.4388319 },
    { lat: 48.4787, lng: 17.4403192 },
    { lat: 48.4779668, lng: 17.4412303 },
    { lat: 48.4774484, lng: 17.442455 },
    { lat: 48.477339, lng: 17.4430885 },
    { lat: 48.4768394, lng: 17.4446051 },
    { lat: 48.4762033, lng: 17.4459693 },
    { lat: 48.4759303, lng: 17.4467093 },
    { lat: 48.4753438, lng: 17.4480203 },
    { lat: 48.4742227, lng: 17.4497996 },
    { lat: 48.4740149, lng: 17.4502919 },
    { lat: 48.4733098, lng: 17.4514265 },
    { lat: 48.472507, lng: 17.4523937 },
    { lat: 48.4712582, lng: 17.4548033 },
    { lat: 48.4702662, lng: 17.4560072 },
    { lat: 48.4687475, lng: 17.4576618 },
    { lat: 48.4670531, lng: 17.4600821 },
    { lat: 48.4666947, lng: 17.4601286 },
    { lat: 48.4665177, lng: 17.4600301 },
    { lat: 48.4662875, lng: 17.4601707 },
    { lat: 48.4656631, lng: 17.4608943 },
    { lat: 48.4652479, lng: 17.4616917 },
    { lat: 48.4650562, lng: 17.4619598 },
    { lat: 48.4648041, lng: 17.462184 },
    { lat: 48.4642894, lng: 17.4633485 },
    { lat: 48.463475, lng: 17.4648056 },
    { lat: 48.4630413, lng: 17.4656832 },
    { lat: 48.462781, lng: 17.4661335 },
    { lat: 48.4616902, lng: 17.4676794 },
    { lat: 48.4606532, lng: 17.4700487 },
    { lat: 48.4593382, lng: 17.4724725 },
    { lat: 48.4586218, lng: 17.4736252 },
    { lat: 48.4577497, lng: 17.4725868 },
    { lat: 48.456357, lng: 17.4706133 },
    { lat: 48.4560102, lng: 17.4701399 },
    { lat: 48.4559961, lng: 17.4701629 },
    { lat: 48.4558884, lng: 17.4703511 },
    { lat: 48.4558752, lng: 17.470341 },
    { lat: 48.4492728, lng: 17.4653162 },
    { lat: 48.449114, lng: 17.4656032 },
    { lat: 48.4493116, lng: 17.4659719 },
    { lat: 48.4498392, lng: 17.4670106 },
    { lat: 48.44914, lng: 17.4678201 },
    { lat: 48.4475892, lng: 17.4689952 },
    { lat: 48.4471881, lng: 17.4691662 },
    { lat: 48.4467549, lng: 17.4692444 },
    { lat: 48.4460586, lng: 17.4696234 },
    { lat: 48.4455633, lng: 17.4698045 },
    { lat: 48.4444435, lng: 17.4706945 },
    { lat: 48.4454473, lng: 17.4723943 },
    { lat: 48.4452076, lng: 17.4737439 },
    { lat: 48.4443847, lng: 17.4745431 },
    { lat: 48.4449834, lng: 17.4756173 },
    { lat: 48.4438563, lng: 17.4768145 },
    { lat: 48.4460942, lng: 17.4797604 },
    { lat: 48.4461528, lng: 17.480817 },
    { lat: 48.4461192, lng: 17.4808727 },
    { lat: 48.4463195, lng: 17.4811247 },
    { lat: 48.4466877, lng: 17.4818134 },
    { lat: 48.4470042, lng: 17.4825373 },
    { lat: 48.4475207, lng: 17.4844987 },
    { lat: 48.4462202, lng: 17.4867973 },
    { lat: 48.4427535, lng: 17.4896571 },
    { lat: 48.4399809, lng: 17.4924961 },
    { lat: 48.4381111, lng: 17.4940549 },
    { lat: 48.4369317, lng: 17.4948764 },
    { lat: 48.4366841, lng: 17.4950168 },
    { lat: 48.436943, lng: 17.4958287 },
    { lat: 48.4370892, lng: 17.4965459 },
    { lat: 48.43735, lng: 17.4974578 },
    { lat: 48.4371603, lng: 17.4977967 },
    { lat: 48.4379223, lng: 17.4997138 },
    { lat: 48.4384662, lng: 17.4991234 },
    { lat: 48.4390062, lng: 17.4987899 },
    { lat: 48.4392533, lng: 17.4987168 },
    { lat: 48.4397473, lng: 17.5001008 },
    { lat: 48.4400281, lng: 17.5012229 },
    { lat: 48.439639, lng: 17.5016374 },
    { lat: 48.4418075, lng: 17.5051881 },
    { lat: 48.4437954, lng: 17.5082302 },
    { lat: 48.4454885, lng: 17.5109726 },
    { lat: 48.4469845, lng: 17.5135109 },
    { lat: 48.4469796, lng: 17.5137706 },
    { lat: 48.4482493, lng: 17.5160216 },
    { lat: 48.447936, lng: 17.5166669 },
    { lat: 48.4473063, lng: 17.5174009 },
    { lat: 48.446818, lng: 17.5176073 },
    { lat: 48.4467128, lng: 17.5177619 },
    { lat: 48.4465657, lng: 17.517989 },
    { lat: 48.4466689, lng: 17.5181712 },
    { lat: 48.4476766, lng: 17.5199286 },
    { lat: 48.4512148, lng: 17.5252423 },
    { lat: 48.4522889, lng: 17.5270299 },
    { lat: 48.4522012, lng: 17.5272325 },
    { lat: 48.4542239, lng: 17.530436 },
    { lat: 48.4551116, lng: 17.531842 },
    { lat: 48.4548168, lng: 17.5322658 },
    { lat: 48.4561603, lng: 17.5343177 },
    { lat: 48.4569403, lng: 17.5353397 },
    { lat: 48.4569485, lng: 17.5356106 },
    { lat: 48.4581945, lng: 17.5371615 },
    { lat: 48.4592874, lng: 17.5388576 },
    { lat: 48.4607972, lng: 17.5409698 },
    { lat: 48.4614656, lng: 17.5400799 },
    { lat: 48.4641344, lng: 17.5375083 },
    { lat: 48.4653137, lng: 17.5362373 },
    { lat: 48.4665368, lng: 17.5345702 },
    { lat: 48.467276, lng: 17.5331976 },
    { lat: 48.4672938, lng: 17.5331392 },
    { lat: 48.4681438, lng: 17.5342164 },
    { lat: 48.4692873, lng: 17.5357339 },
    { lat: 48.4707904, lng: 17.5380931 },
    { lat: 48.4725883, lng: 17.5414647 },
    { lat: 48.4732943, lng: 17.5413297 },
    { lat: 48.4751528, lng: 17.5394746 },
    { lat: 48.4774335, lng: 17.5352067 },
    { lat: 48.4775486, lng: 17.5347295 },
    { lat: 48.4775659, lng: 17.5339736 },
    { lat: 48.4777041, lng: 17.5335326 },
    { lat: 48.4789417, lng: 17.5297177 },
    { lat: 48.4795856, lng: 17.5262303 },
    { lat: 48.4803556, lng: 17.5239561 },
    { lat: 48.4811759, lng: 17.5227956 },
    { lat: 48.4820023, lng: 17.5205769 },
    { lat: 48.4824358, lng: 17.5196002 },
    { lat: 48.4834998, lng: 17.5179384 },
    { lat: 48.4850671, lng: 17.5169289 },
    { lat: 48.4865663, lng: 17.5161909 },
    { lat: 48.4883393, lng: 17.5159093 },
    { lat: 48.4907395, lng: 17.5148339 },
    { lat: 48.4918399, lng: 17.5138838 },
    { lat: 48.4917762, lng: 17.5138955 },
    { lat: 48.4910007, lng: 17.5124673 },
    { lat: 48.4920162, lng: 17.5116224 },
    { lat: 48.492341, lng: 17.5110827 },
    { lat: 48.4923627, lng: 17.510841 },
    { lat: 48.4924445, lng: 17.5105846 },
    { lat: 48.4930358, lng: 17.5099373 },
    { lat: 48.4923791, lng: 17.5090809 },
    { lat: 48.4916747, lng: 17.5079006 },
    { lat: 48.4913338, lng: 17.5065247 },
    { lat: 48.4902554, lng: 17.5051657 },
    { lat: 48.490229, lng: 17.5049826 },
    { lat: 48.4902877, lng: 17.5048232 },
    { lat: 48.4901857, lng: 17.5045157 },
    { lat: 48.4900444, lng: 17.5043008 },
    { lat: 48.4901459, lng: 17.5037154 },
    { lat: 48.4900771, lng: 17.5032579 },
    { lat: 48.4897681, lng: 17.503036 },
    { lat: 48.4895755, lng: 17.5025152 },
    { lat: 48.4894344, lng: 17.5023408 },
    { lat: 48.4891195, lng: 17.5021308 },
    { lat: 48.489028, lng: 17.5020066 },
    { lat: 48.4888552, lng: 17.502006 },
    { lat: 48.4885894, lng: 17.5015389 },
    { lat: 48.4884606, lng: 17.5016266 },
    { lat: 48.4880833, lng: 17.5016064 },
    { lat: 48.4878568, lng: 17.5008033 },
    { lat: 48.4875424, lng: 17.5003721 },
    { lat: 48.4872763, lng: 17.5001849 },
    { lat: 48.4872233, lng: 17.5000455 },
    { lat: 48.4872743, lng: 17.4996956 },
    { lat: 48.4872343, lng: 17.4995807 },
    { lat: 48.4868109, lng: 17.4988588 },
    { lat: 48.4866856, lng: 17.4988787 },
    { lat: 48.4864789, lng: 17.498737 },
    { lat: 48.4864463, lng: 17.4985972 },
    { lat: 48.4862809, lng: 17.4983599 },
    { lat: 48.4860682, lng: 17.4975964 },
    { lat: 48.4860585, lng: 17.496789 },
    { lat: 48.4859643, lng: 17.4963743 },
    { lat: 48.4858864, lng: 17.4962193 },
    { lat: 48.4858279, lng: 17.4961855 },
    { lat: 48.4856808, lng: 17.4963559 },
    { lat: 48.4856021, lng: 17.4958696 },
    { lat: 48.4854749, lng: 17.4958058 },
    { lat: 48.4851184, lng: 17.4958482 },
    { lat: 48.4843809, lng: 17.494065 },
    { lat: 48.4841169, lng: 17.4937668 },
    { lat: 48.4840664, lng: 17.4936314 },
    { lat: 48.483944, lng: 17.4935726 },
    { lat: 48.4838915, lng: 17.4934061 },
    { lat: 48.4837753, lng: 17.4934774 },
    { lat: 48.4837409, lng: 17.4934158 },
    { lat: 48.4836679, lng: 17.4931046 },
    { lat: 48.4837119, lng: 17.4930327 },
    { lat: 48.4836473, lng: 17.4929071 },
    { lat: 48.4835286, lng: 17.4929236 },
    { lat: 48.4834409, lng: 17.4926483 },
    { lat: 48.4832626, lng: 17.4927361 },
    { lat: 48.4831529, lng: 17.4926448 },
    { lat: 48.4830145, lng: 17.4923345 },
    { lat: 48.4828657, lng: 17.4921998 },
    { lat: 48.4828803, lng: 17.4919353 },
    { lat: 48.4827768, lng: 17.4917456 },
    { lat: 48.4826671, lng: 17.4917954 },
    { lat: 48.4823918, lng: 17.4916434 },
    { lat: 48.4823349, lng: 17.4910877 },
    { lat: 48.4822399, lng: 17.4909271 },
    { lat: 48.4822432, lng: 17.4906973 },
    { lat: 48.481441, lng: 17.4891241 },
    { lat: 48.481181, lng: 17.4888884 },
    { lat: 48.4812281, lng: 17.4886199 },
    { lat: 48.4808518, lng: 17.4885275 },
    { lat: 48.4807149, lng: 17.4883989 },
    { lat: 48.4818609, lng: 17.4875845 },
    { lat: 48.4814703, lng: 17.4865821 },
    { lat: 48.4807269, lng: 17.4851849 },
    { lat: 48.4802965, lng: 17.4845321 },
    { lat: 48.4798999, lng: 17.4840721 },
    { lat: 48.4807069, lng: 17.4817384 },
    { lat: 48.4811986, lng: 17.48067 },
    { lat: 48.4815117, lng: 17.4796331 },
    { lat: 48.4817457, lng: 17.4783522 },
    { lat: 48.4818002, lng: 17.4783749 },
    { lat: 48.4826273, lng: 17.4780274 },
    { lat: 48.4827715, lng: 17.4771254 },
    { lat: 48.4830236, lng: 17.4755804 },
    { lat: 48.4831577, lng: 17.4749967 },
    { lat: 48.4833821, lng: 17.4743924 },
    { lat: 48.4839302, lng: 17.474149 },
    { lat: 48.4839718, lng: 17.4741804 },
    { lat: 48.4841116, lng: 17.4739664 },
    { lat: 48.4839383, lng: 17.4738029 },
    { lat: 48.4839664, lng: 17.4735881 },
    { lat: 48.483735, lng: 17.4736074 },
    { lat: 48.483701, lng: 17.4735529 },
    { lat: 48.4836843, lng: 17.4732043 },
    { lat: 48.4836334, lng: 17.4730964 },
    { lat: 48.4837529, lng: 17.4724632 },
    { lat: 48.4840328, lng: 17.4724396 },
    { lat: 48.4841693, lng: 17.472508 },
    { lat: 48.4841405, lng: 17.4726753 },
    { lat: 48.4842741, lng: 17.4728403 },
    { lat: 48.4843135, lng: 17.4727673 },
    { lat: 48.4843249, lng: 17.4725032 },
    { lat: 48.484371, lng: 17.4724837 },
    { lat: 48.4844248, lng: 17.4724777 },
    { lat: 48.4844742, lng: 17.4726649 },
    { lat: 48.4845408, lng: 17.4726994 },
    { lat: 48.4846954, lng: 17.4726271 },
    { lat: 48.4847832, lng: 17.4726933 },
    { lat: 48.4848543, lng: 17.4726567 },
    { lat: 48.4848399, lng: 17.4721522 },
    { lat: 48.4847336, lng: 17.4719757 },
    { lat: 48.4846205, lng: 17.4719345 },
    { lat: 48.4845764, lng: 17.4717049 },
    { lat: 48.4846783, lng: 17.4715014 },
    { lat: 48.4848058, lng: 17.4714964 },
    { lat: 48.4848415, lng: 17.4714029 },
    { lat: 48.4848052, lng: 17.4712441 },
    { lat: 48.4848397, lng: 17.4710924 },
    { lat: 48.4847757, lng: 17.4708542 },
    { lat: 48.4844636, lng: 17.4706379 },
    { lat: 48.4844895, lng: 17.4701934 },
    { lat: 48.4845547, lng: 17.470122 },
    { lat: 48.484632, lng: 17.4701642 },
    { lat: 48.4846487, lng: 17.4698933 },
    { lat: 48.4847166, lng: 17.4698792 },
    { lat: 48.4847594, lng: 17.4704234 },
    { lat: 48.4849434, lng: 17.4697732 },
    { lat: 48.4848037, lng: 17.4696017 },
    { lat: 48.4848699, lng: 17.4693279 },
    { lat: 48.4849971, lng: 17.4692015 },
    { lat: 48.4850745, lng: 17.4693149 },
    { lat: 48.4851625, lng: 17.4692964 },
    { lat: 48.4852468, lng: 17.4694116 },
    { lat: 48.4851493, lng: 17.4690399 },
    { lat: 48.4851872, lng: 17.4689596 },
    { lat: 48.4853518, lng: 17.4690079 },
    { lat: 48.485306, lng: 17.468472 },
    { lat: 48.4853593, lng: 17.4683333 },
    { lat: 48.4855271, lng: 17.4683445 },
    { lat: 48.485921, lng: 17.4687621 },
    { lat: 48.4861372, lng: 17.468683 },
    { lat: 48.4862224, lng: 17.4685656 },
    { lat: 48.4862038, lng: 17.4684519 },
    { lat: 48.4860293, lng: 17.4684068 },
    { lat: 48.4859107, lng: 17.4681086 },
    { lat: 48.4857567, lng: 17.4680449 },
    { lat: 48.4856693, lng: 17.4680087 },
    { lat: 48.4855455, lng: 17.46789 },
    { lat: 48.4856771, lng: 17.4674597 },
    { lat: 48.4856632, lng: 17.4672435 },
    { lat: 48.4857403, lng: 17.4670717 },
    { lat: 48.4856674, lng: 17.4668698 },
    { lat: 48.485985, lng: 17.4663829 },
    { lat: 48.4861805, lng: 17.4664352 },
    { lat: 48.4863168, lng: 17.4666226 },
    { lat: 48.4863686, lng: 17.4665852 },
    { lat: 48.4863643, lng: 17.4664153 },
    { lat: 48.4862754, lng: 17.4663143 },
    { lat: 48.4863617, lng: 17.4661462 },
    { lat: 48.4863884, lng: 17.466084 },
    { lat: 48.4864796, lng: 17.4659923 },
    { lat: 48.486486, lng: 17.4661801 },
    { lat: 48.4865506, lng: 17.4662387 },
    { lat: 48.4866558, lng: 17.4658723 },
    { lat: 48.4871254, lng: 17.4663409 },
    { lat: 48.4879586, lng: 17.4634709 },
    { lat: 48.4876299, lng: 17.4632613 },
    { lat: 48.4877132, lng: 17.4628704 },
    { lat: 48.4878843, lng: 17.4613316 },
    { lat: 48.4880679, lng: 17.4608666 },
    { lat: 48.4888663, lng: 17.4597055 },
    { lat: 48.4889564, lng: 17.4592997 },
    { lat: 48.4889839, lng: 17.4587587 },
    { lat: 48.4887376, lng: 17.4570899 },
    { lat: 48.4888561, lng: 17.4561423 },
    { lat: 48.4897422, lng: 17.4544143 },
    { lat: 48.48984, lng: 17.4540721 },
    { lat: 48.4899066, lng: 17.453298 },
    { lat: 48.4900153, lng: 17.4529354 },
    { lat: 48.491078, lng: 17.4508283 },
  ],
  Lošonec: [
    { lat: 48.477824, lng: 17.314371 },
    { lat: 48.477587, lng: 17.314372 },
    { lat: 48.47715, lng: 17.314464 },
    { lat: 48.477079, lng: 17.314479 },
    { lat: 48.476632, lng: 17.314037 },
    { lat: 48.476311, lng: 17.313714 },
    { lat: 48.47615, lng: 17.313556 },
    { lat: 48.47589, lng: 17.313298 },
    { lat: 48.475542, lng: 17.313182 },
    { lat: 48.475315, lng: 17.313107 },
    { lat: 48.474965, lng: 17.312771 },
    { lat: 48.474799, lng: 17.312611 },
    { lat: 48.474637, lng: 17.31229 },
    { lat: 48.474513, lng: 17.312065 },
    { lat: 48.474303, lng: 17.311691 },
    { lat: 48.473553, lng: 17.31138 },
    { lat: 48.473311, lng: 17.311049 },
    { lat: 48.472928, lng: 17.310308 },
    { lat: 48.472875, lng: 17.310205 },
    { lat: 48.472571, lng: 17.309699 },
    { lat: 48.471889, lng: 17.309137 },
    { lat: 48.471207, lng: 17.308336 },
    { lat: 48.470581, lng: 17.307562 },
    { lat: 48.469291, lng: 17.306048 },
    { lat: 48.468602, lng: 17.305251 },
    { lat: 48.467895, lng: 17.304451 },
    { lat: 48.467579, lng: 17.304097 },
    { lat: 48.466943, lng: 17.303377 },
    { lat: 48.466634, lng: 17.303018 },
    { lat: 48.466617, lng: 17.303 },
    { lat: 48.466482, lng: 17.302834 },
    { lat: 48.466457, lng: 17.302804 },
    { lat: 48.465954, lng: 17.302193 },
    { lat: 48.465943, lng: 17.302179 },
    { lat: 48.465422, lng: 17.302777 },
    { lat: 48.465235, lng: 17.302918 },
    { lat: 48.465199, lng: 17.302945 },
    { lat: 48.464804, lng: 17.303341 },
    { lat: 48.464383, lng: 17.303782 },
    { lat: 48.463898, lng: 17.304087 },
    { lat: 48.463362, lng: 17.303939 },
    { lat: 48.462772, lng: 17.304719 },
    { lat: 48.462463, lng: 17.305167 },
    { lat: 48.462443, lng: 17.305198 },
    { lat: 48.462182, lng: 17.305578 },
    { lat: 48.46152, lng: 17.306507 },
    { lat: 48.460914, lng: 17.307383 },
    { lat: 48.460727, lng: 17.307649 },
    { lat: 48.460237, lng: 17.308335 },
    { lat: 48.459679, lng: 17.309139 },
    { lat: 48.459033, lng: 17.310031 },
    { lat: 48.459014, lng: 17.310057 },
    { lat: 48.458987, lng: 17.310093 },
    { lat: 48.458349, lng: 17.310938 },
    { lat: 48.457733, lng: 17.311816 },
    { lat: 48.45717, lng: 17.312609 },
    { lat: 48.456453, lng: 17.313649 },
    { lat: 48.455974, lng: 17.31431 },
    { lat: 48.455909, lng: 17.314531 },
    { lat: 48.455783, lng: 17.314771 },
    { lat: 48.455668, lng: 17.314979 },
    { lat: 48.455688, lng: 17.31499 },
    { lat: 48.4556843, lng: 17.3150049 },
    { lat: 48.4558824, lng: 17.3151126 },
    { lat: 48.4561473, lng: 17.3149697 },
    { lat: 48.4562552, lng: 17.3150808 },
    { lat: 48.4563307, lng: 17.3152685 },
    { lat: 48.4564147, lng: 17.3151525 },
    { lat: 48.4564226, lng: 17.3149976 },
    { lat: 48.4565142, lng: 17.3149662 },
    { lat: 48.4565887, lng: 17.3150687 },
    { lat: 48.4569585, lng: 17.3148679 },
    { lat: 48.4570834, lng: 17.3149106 },
    { lat: 48.4576103, lng: 17.3147467 },
    { lat: 48.4583081, lng: 17.3147762 },
    { lat: 48.4584058, lng: 17.3149045 },
    { lat: 48.4585169, lng: 17.3148958 },
    { lat: 48.4585931, lng: 17.315039 },
    { lat: 48.4586835, lng: 17.3150928 },
    { lat: 48.458772, lng: 17.3150448 },
    { lat: 48.45896, lng: 17.3151721 },
    { lat: 48.4590818, lng: 17.3153444 },
    { lat: 48.459578, lng: 17.3152616 },
    { lat: 48.460232, lng: 17.3149699 },
    { lat: 48.4605141, lng: 17.3157367 },
    { lat: 48.4605705, lng: 17.3166504 },
    { lat: 48.4606825, lng: 17.3173072 },
    { lat: 48.4617574, lng: 17.3205022 },
    { lat: 48.4619829, lng: 17.3213928 },
    { lat: 48.4621829, lng: 17.3227202 },
    { lat: 48.462474, lng: 17.3234209 },
    { lat: 48.4623714, lng: 17.323596 },
    { lat: 48.4625473, lng: 17.3238859 },
    { lat: 48.4624559, lng: 17.3238849 },
    { lat: 48.4623959, lng: 17.3239525 },
    { lat: 48.4624036, lng: 17.3240183 },
    { lat: 48.46257, lng: 17.3240762 },
    { lat: 48.4625753, lng: 17.3241398 },
    { lat: 48.462493, lng: 17.3242812 },
    { lat: 48.4623685, lng: 17.3242594 },
    { lat: 48.4623001, lng: 17.3241799 },
    { lat: 48.4621833, lng: 17.324226 },
    { lat: 48.4622283, lng: 17.3244461 },
    { lat: 48.4621463, lng: 17.3247988 },
    { lat: 48.4621709, lng: 17.3249835 },
    { lat: 48.4620023, lng: 17.3252199 },
    { lat: 48.4619297, lng: 17.3254744 },
    { lat: 48.4620471, lng: 17.325593 },
    { lat: 48.4619451, lng: 17.3259519 },
    { lat: 48.4619835, lng: 17.326574 },
    { lat: 48.462214, lng: 17.3276243 },
    { lat: 48.4622335, lng: 17.327972 },
    { lat: 48.4624819, lng: 17.3285919 },
    { lat: 48.4625675, lng: 17.3290028 },
    { lat: 48.4627516, lng: 17.3293118 },
    { lat: 48.4627732, lng: 17.3296781 },
    { lat: 48.4628757, lng: 17.3297437 },
    { lat: 48.4628942, lng: 17.3300584 },
    { lat: 48.462844, lng: 17.330257 },
    { lat: 48.4628942, lng: 17.3303193 },
    { lat: 48.4628143, lng: 17.3305305 },
    { lat: 48.462848, lng: 17.3306657 },
    { lat: 48.4629502, lng: 17.3307253 },
    { lat: 48.4629901, lng: 17.3313351 },
    { lat: 48.4628922, lng: 17.3314955 },
    { lat: 48.4628065, lng: 17.3319798 },
    { lat: 48.4627459, lng: 17.3320048 },
    { lat: 48.4628919, lng: 17.3323007 },
    { lat: 48.4630264, lng: 17.3324253 },
    { lat: 48.4629954, lng: 17.3326566 },
    { lat: 48.4630868, lng: 17.3328274 },
    { lat: 48.462914, lng: 17.3335214 },
    { lat: 48.4628103, lng: 17.3335748 },
    { lat: 48.4627614, lng: 17.3336746 },
    { lat: 48.4626742, lng: 17.3341003 },
    { lat: 48.462574, lng: 17.334228 },
    { lat: 48.4625754, lng: 17.3345243 },
    { lat: 48.4624479, lng: 17.3347001 },
    { lat: 48.4623663, lng: 17.3352293 },
    { lat: 48.4623012, lng: 17.3353044 },
    { lat: 48.4623509, lng: 17.3363936 },
    { lat: 48.4624561, lng: 17.3366012 },
    { lat: 48.4624939, lng: 17.3369547 },
    { lat: 48.4625678, lng: 17.3371876 },
    { lat: 48.4624119, lng: 17.3379121 },
    { lat: 48.4621738, lng: 17.3382089 },
    { lat: 48.4621049, lng: 17.3388501 },
    { lat: 48.4620218, lng: 17.3391681 },
    { lat: 48.461843, lng: 17.3393871 },
    { lat: 48.4618125, lng: 17.339544 },
    { lat: 48.4618549, lng: 17.340278 },
    { lat: 48.4616418, lng: 17.3413701 },
    { lat: 48.4616299, lng: 17.3417608 },
    { lat: 48.4614505, lng: 17.3425757 },
    { lat: 48.4614227, lng: 17.3435332 },
    { lat: 48.4614702, lng: 17.3439992 },
    { lat: 48.4612678, lng: 17.3445676 },
    { lat: 48.4612854, lng: 17.3447831 },
    { lat: 48.4613781, lng: 17.3450456 },
    { lat: 48.4612802, lng: 17.3453775 },
    { lat: 48.4609081, lng: 17.3461162 },
    { lat: 48.4607605, lng: 17.3472113 },
    { lat: 48.4608054, lng: 17.3478984 },
    { lat: 48.4609699, lng: 17.3481847 },
    { lat: 48.461115, lng: 17.3494381 },
    { lat: 48.4611243, lng: 17.3499759 },
    { lat: 48.4612244, lng: 17.3502473 },
    { lat: 48.4612347, lng: 17.350786 },
    { lat: 48.461315, lng: 17.3512037 },
    { lat: 48.4612756, lng: 17.3514992 },
    { lat: 48.4613705, lng: 17.3517425 },
    { lat: 48.4613723, lng: 17.3518747 },
    { lat: 48.4611356, lng: 17.3527815 },
    { lat: 48.4612531, lng: 17.3533521 },
    { lat: 48.4611445, lng: 17.353729 },
    { lat: 48.461118, lng: 17.3543102 },
    { lat: 48.4611939, lng: 17.3546794 },
    { lat: 48.4612289, lng: 17.3546786 },
    { lat: 48.4616005, lng: 17.355422 },
    { lat: 48.4615001, lng: 17.3556133 },
    { lat: 48.4614905, lng: 17.3561084 },
    { lat: 48.4608461, lng: 17.3567223 },
    { lat: 48.4608112, lng: 17.3568624 },
    { lat: 48.4608334, lng: 17.3571157 },
    { lat: 48.4607715, lng: 17.3573786 },
    { lat: 48.4604128, lng: 17.357908 },
    { lat: 48.4603941, lng: 17.3581535 },
    { lat: 48.4602205, lng: 17.3585406 },
    { lat: 48.4602312, lng: 17.3587049 },
    { lat: 48.4599782, lng: 17.3596234 },
    { lat: 48.4599013, lng: 17.3601247 },
    { lat: 48.4596282, lng: 17.3606159 },
    { lat: 48.4595892, lng: 17.3607797 },
    { lat: 48.4595408, lng: 17.361405 },
    { lat: 48.4596028, lng: 17.3619392 },
    { lat: 48.4598349, lng: 17.3626236 },
    { lat: 48.4600345, lng: 17.362723 },
    { lat: 48.4603037, lng: 17.363126 },
    { lat: 48.4605067, lng: 17.3632089 },
    { lat: 48.4610499, lng: 17.3632595 },
    { lat: 48.4611907, lng: 17.3633747 },
    { lat: 48.4613176, lng: 17.363604 },
    { lat: 48.4614775, lng: 17.3637 },
    { lat: 48.4615825, lng: 17.363666 },
    { lat: 48.4618048, lng: 17.363939 },
    { lat: 48.4622803, lng: 17.3641636 },
    { lat: 48.4624972, lng: 17.36418 },
    { lat: 48.4628298, lng: 17.3644136 },
    { lat: 48.4630578, lng: 17.3644982 },
    { lat: 48.4632721, lng: 17.364684 },
    { lat: 48.4637797, lng: 17.3645192 },
    { lat: 48.4640675, lng: 17.3644793 },
    { lat: 48.4643536, lng: 17.3645902 },
    { lat: 48.4646192, lng: 17.3644855 },
    { lat: 48.4649185, lng: 17.3645884 },
    { lat: 48.4652834, lng: 17.3645225 },
    { lat: 48.46567, lng: 17.3646467 },
    { lat: 48.4659689, lng: 17.3646059 },
    { lat: 48.4661756, lng: 17.3647267 },
    { lat: 48.4668016, lng: 17.3648704 },
    { lat: 48.4668668, lng: 17.3649349 },
    { lat: 48.4673449, lng: 17.3659802 },
    { lat: 48.4677578, lng: 17.3669769 },
    { lat: 48.4678867, lng: 17.3676363 },
    { lat: 48.4682132, lng: 17.3684521 },
    { lat: 48.4683814, lng: 17.3686768 },
    { lat: 48.4684668, lng: 17.3693473 },
    { lat: 48.4686376, lng: 17.3697136 },
    { lat: 48.4687261, lng: 17.370153 },
    { lat: 48.4688276, lng: 17.3703591 },
    { lat: 48.4689333, lng: 17.370424 },
    { lat: 48.469175, lng: 17.3707689 },
    { lat: 48.4692623, lng: 17.3709642 },
    { lat: 48.4694634, lng: 17.3711197 },
    { lat: 48.4695067, lng: 17.3712646 },
    { lat: 48.4695215, lng: 17.3716668 },
    { lat: 48.4698395, lng: 17.3719197 },
    { lat: 48.4700649, lng: 17.3732658 },
    { lat: 48.4703034, lng: 17.3737521 },
    { lat: 48.4703321, lng: 17.3747307 },
    { lat: 48.4702445, lng: 17.3752063 },
    { lat: 48.4699525, lng: 17.3758092 },
    { lat: 48.4696394, lng: 17.3777303 },
    { lat: 48.4694968, lng: 17.3781606 },
    { lat: 48.4693275, lng: 17.3785287 },
    { lat: 48.4688861, lng: 17.3790157 },
    { lat: 48.4687879, lng: 17.3792968 },
    { lat: 48.4687673, lng: 17.3796056 },
    { lat: 48.4687077, lng: 17.3796424 },
    { lat: 48.4688126, lng: 17.3803905 },
    { lat: 48.4692212, lng: 17.380959 },
    { lat: 48.4693677, lng: 17.3810894 },
    { lat: 48.4699693, lng: 17.3812234 },
    { lat: 48.470219, lng: 17.3815366 },
    { lat: 48.4703588, lng: 17.3814804 },
    { lat: 48.470687, lng: 17.3817148 },
    { lat: 48.4708298, lng: 17.3820013 },
    { lat: 48.4710568, lng: 17.382226 },
    { lat: 48.4714784, lng: 17.3822491 },
    { lat: 48.471677, lng: 17.382384 },
    { lat: 48.4718376, lng: 17.3823899 },
    { lat: 48.4720804, lng: 17.3851342 },
    { lat: 48.4720764, lng: 17.3862532 },
    { lat: 48.4721353, lng: 17.3875797 },
    { lat: 48.4718034, lng: 17.3887989 },
    { lat: 48.471759, lng: 17.3892631 },
    { lat: 48.471637, lng: 17.3893312 },
    { lat: 48.4706586, lng: 17.3931386 },
    { lat: 48.4712434, lng: 17.3934283 },
    { lat: 48.4714289, lng: 17.3932416 },
    { lat: 48.4714872, lng: 17.393305 },
    { lat: 48.4754323, lng: 17.3936296 },
    { lat: 48.4757886, lng: 17.3942839 },
    { lat: 48.4764683, lng: 17.3950958 },
    { lat: 48.4767324, lng: 17.3966425 },
    { lat: 48.4771516, lng: 17.3968359 },
    { lat: 48.4772989, lng: 17.3971404 },
    { lat: 48.4773665, lng: 17.3975544 },
    { lat: 48.477533, lng: 17.3980846 },
    { lat: 48.4775394, lng: 17.398461 },
    { lat: 48.4772893, lng: 17.399273 },
    { lat: 48.4772798, lng: 17.4000491 },
    { lat: 48.4776884, lng: 17.4014935 },
    { lat: 48.4778941, lng: 17.4018439 },
    { lat: 48.4779558, lng: 17.4022884 },
    { lat: 48.4778161, lng: 17.4026079 },
    { lat: 48.4778556, lng: 17.402868 },
    { lat: 48.4774551, lng: 17.4044087 },
    { lat: 48.4782937, lng: 17.4050112 },
    { lat: 48.4781302, lng: 17.4056069 },
    { lat: 48.4782316, lng: 17.4058138 },
    { lat: 48.4786947, lng: 17.4060934 },
    { lat: 48.4789021, lng: 17.4060687 },
    { lat: 48.4788836, lng: 17.4062177 },
    { lat: 48.4789442, lng: 17.4065257 },
    { lat: 48.4789023, lng: 17.406802 },
    { lat: 48.4784107, lng: 17.4077046 },
    { lat: 48.4783758, lng: 17.4078823 },
    { lat: 48.4782943, lng: 17.4079466 },
    { lat: 48.4781981, lng: 17.4084171 },
    { lat: 48.4779705, lng: 17.4086889 },
    { lat: 48.477965, lng: 17.40883 },
    { lat: 48.4778743, lng: 17.4090166 },
    { lat: 48.477797, lng: 17.4093907 },
    { lat: 48.4779485, lng: 17.4098804 },
    { lat: 48.477964, lng: 17.4101569 },
    { lat: 48.4780204, lng: 17.41026 },
    { lat: 48.4780067, lng: 17.410413 },
    { lat: 48.4780552, lng: 17.4106394 },
    { lat: 48.4780469, lng: 17.4107385 },
    { lat: 48.477978, lng: 17.4107928 },
    { lat: 48.4779656, lng: 17.4110188 },
    { lat: 48.4780972, lng: 17.4114972 },
    { lat: 48.4781458, lng: 17.4118646 },
    { lat: 48.4782542, lng: 17.4120908 },
    { lat: 48.4782649, lng: 17.4122736 },
    { lat: 48.478332, lng: 17.4124048 },
    { lat: 48.4783938, lng: 17.4129379 },
    { lat: 48.4784549, lng: 17.4130817 },
    { lat: 48.4785501, lng: 17.4131364 },
    { lat: 48.478884, lng: 17.4130445 },
    { lat: 48.4789803, lng: 17.413224 },
    { lat: 48.4790793, lng: 17.4136011 },
    { lat: 48.4790273, lng: 17.4137554 },
    { lat: 48.4790736, lng: 17.4138269 },
    { lat: 48.4790281, lng: 17.4142771 },
    { lat: 48.4789748, lng: 17.4142721 },
    { lat: 48.4789424, lng: 17.4144517 },
    { lat: 48.4789729, lng: 17.4150802 },
    { lat: 48.4788815, lng: 17.4154307 },
    { lat: 48.4788978, lng: 17.4155447 },
    { lat: 48.4791105, lng: 17.4159528 },
    { lat: 48.4790549, lng: 17.4161204 },
    { lat: 48.4790564, lng: 17.4164413 },
    { lat: 48.4788695, lng: 17.4168001 },
    { lat: 48.4787205, lng: 17.4168906 },
    { lat: 48.4786153, lng: 17.417042 },
    { lat: 48.4785425, lng: 17.4173684 },
    { lat: 48.4785761, lng: 17.4175557 },
    { lat: 48.4787988, lng: 17.4177488 },
    { lat: 48.4788016, lng: 17.4178481 },
    { lat: 48.4793464, lng: 17.4185676 },
    { lat: 48.4793308, lng: 17.4188169 },
    { lat: 48.479434, lng: 17.4191542 },
    { lat: 48.4798626, lng: 17.4198594 },
    { lat: 48.4802407, lng: 17.421238 },
    { lat: 48.4806036, lng: 17.4220632 },
    { lat: 48.4805544, lng: 17.4222284 },
    { lat: 48.4806494, lng: 17.422405 },
    { lat: 48.4805943, lng: 17.4225996 },
    { lat: 48.4806837, lng: 17.422709 },
    { lat: 48.4807565, lng: 17.4226634 },
    { lat: 48.4808185, lng: 17.4227512 },
    { lat: 48.4808158, lng: 17.4228477 },
    { lat: 48.4810476, lng: 17.4229751 },
    { lat: 48.4811775, lng: 17.4234096 },
    { lat: 48.4813021, lng: 17.4234519 },
    { lat: 48.4814253, lng: 17.4238561 },
    { lat: 48.4815859, lng: 17.4240858 },
    { lat: 48.4815549, lng: 17.4243238 },
    { lat: 48.4813935, lng: 17.4243603 },
    { lat: 48.4814193, lng: 17.4245145 },
    { lat: 48.4815947, lng: 17.4247961 },
    { lat: 48.4815743, lng: 17.4249198 },
    { lat: 48.4816857, lng: 17.4250512 },
    { lat: 48.4816682, lng: 17.4251506 },
    { lat: 48.4815426, lng: 17.4251811 },
    { lat: 48.4815208, lng: 17.4253345 },
    { lat: 48.481659, lng: 17.4257593 },
    { lat: 48.4816012, lng: 17.4259308 },
    { lat: 48.4815783, lng: 17.4262393 },
    { lat: 48.4817065, lng: 17.4274719 },
    { lat: 48.4824599, lng: 17.427737 },
    { lat: 48.4853485, lng: 17.4283401 },
    { lat: 48.4854058, lng: 17.4283524 },
    { lat: 48.4857968, lng: 17.4280226 },
    { lat: 48.4858941, lng: 17.427851 },
    { lat: 48.4862794, lng: 17.4256157 },
    { lat: 48.4862237, lng: 17.4255925 },
    { lat: 48.4859072, lng: 17.4216537 },
    { lat: 48.4859002, lng: 17.4174938 },
    { lat: 48.4859137, lng: 17.4171597 },
    { lat: 48.4860051, lng: 17.4167945 },
    { lat: 48.4860726, lng: 17.4162281 },
    { lat: 48.4860133, lng: 17.4138998 },
    { lat: 48.4861459, lng: 17.4131395 },
    { lat: 48.4867006, lng: 17.4111673 },
    { lat: 48.486838, lng: 17.4110232 },
    { lat: 48.4870919, lng: 17.4110377 },
    { lat: 48.4872584, lng: 17.4102741 },
    { lat: 48.48753, lng: 17.4093951 },
    { lat: 48.4876934, lng: 17.4086915 },
    { lat: 48.487851, lng: 17.4076344 },
    { lat: 48.4883459, lng: 17.4061209 },
    { lat: 48.4884472, lng: 17.406063 },
    { lat: 48.4887382, lng: 17.4056735 },
    { lat: 48.4890143, lng: 17.4050722 },
    { lat: 48.4891001, lng: 17.4044091 },
    { lat: 48.4895172, lng: 17.4034022 },
    { lat: 48.4900427, lng: 17.4032137 },
    { lat: 48.4904041, lng: 17.4027096 },
    { lat: 48.490982, lng: 17.4021968 },
    { lat: 48.4914276, lng: 17.4017159 },
    { lat: 48.4915482, lng: 17.4014892 },
    { lat: 48.4915594, lng: 17.4015189 },
    { lat: 48.4916836, lng: 17.4014337 },
    { lat: 48.4924966, lng: 17.4008344 },
    { lat: 48.4937677, lng: 17.4002526 },
    { lat: 48.4939438, lng: 17.4002489 },
    { lat: 48.4941797, lng: 17.3999661 },
    { lat: 48.4948477, lng: 17.3984593 },
    { lat: 48.4954311, lng: 17.3975614 },
    { lat: 48.4967152, lng: 17.3958679 },
    { lat: 48.4971561, lng: 17.3949367 },
    { lat: 48.4974344, lng: 17.3937067 },
    { lat: 48.4977219, lng: 17.3928258 },
    { lat: 48.4977848, lng: 17.3917094 },
    { lat: 48.4977072, lng: 17.3911371 },
    { lat: 48.4977565, lng: 17.3908031 },
    { lat: 48.4978726, lng: 17.3900159 },
    { lat: 48.4982957, lng: 17.3892236 },
    { lat: 48.4986579, lng: 17.3879296 },
    { lat: 48.4986736, lng: 17.3870241 },
    { lat: 48.4967413, lng: 17.3873271 },
    { lat: 48.4964424, lng: 17.3840808 },
    { lat: 48.496781, lng: 17.3833468 },
    { lat: 48.4970537, lng: 17.3821707 },
    { lat: 48.497159, lng: 17.3813386 },
    { lat: 48.4972001, lng: 17.3805294 },
    { lat: 48.4975743, lng: 17.3787806 },
    { lat: 48.4974912, lng: 17.3784752 },
    { lat: 48.4976815, lng: 17.3783944 },
    { lat: 48.4978923, lng: 17.3776572 },
    { lat: 48.4980412, lng: 17.3775341 },
    { lat: 48.498395, lng: 17.3767039 },
    { lat: 48.4985335, lng: 17.3761948 },
    { lat: 48.4991985, lng: 17.3729639 },
    { lat: 48.4992794, lng: 17.3725709 },
    { lat: 48.4998739, lng: 17.3714312 },
    { lat: 48.4999881, lng: 17.3706871 },
    { lat: 48.500056, lng: 17.3696973 },
    { lat: 48.4997027, lng: 17.3698947 },
    { lat: 48.4992675, lng: 17.369628 },
    { lat: 48.4991374, lng: 17.3694171 },
    { lat: 48.4993398, lng: 17.3686305 },
    { lat: 48.4994258, lng: 17.3682961 },
    { lat: 48.4998122, lng: 17.3679161 },
    { lat: 48.5003715, lng: 17.3665309 },
    { lat: 48.5011811, lng: 17.3653978 },
    { lat: 48.5024834, lng: 17.3640967 },
    { lat: 48.5028375, lng: 17.3633766 },
    { lat: 48.5052071, lng: 17.3604229 },
    { lat: 48.506589, lng: 17.355047 },
    { lat: 48.506583, lng: 17.355036 },
    { lat: 48.506444, lng: 17.354845 },
    { lat: 48.505422, lng: 17.353521 },
    { lat: 48.505283, lng: 17.353361 },
    { lat: 48.505248, lng: 17.353325 },
    { lat: 48.504462, lng: 17.352354 },
    { lat: 48.504025, lng: 17.351779 },
    { lat: 48.503662, lng: 17.351279 },
    { lat: 48.503026, lng: 17.350395 },
    { lat: 48.501891, lng: 17.348976 },
    { lat: 48.501343, lng: 17.348002 },
    { lat: 48.501325, lng: 17.347963 },
    { lat: 48.501095, lng: 17.346989 },
    { lat: 48.500816, lng: 17.346078 },
    { lat: 48.500758, lng: 17.345778 },
    { lat: 48.500757, lng: 17.345623 },
    { lat: 48.500662, lng: 17.345175 },
    { lat: 48.500522, lng: 17.34487 },
    { lat: 48.500339, lng: 17.344471 },
    { lat: 48.500131, lng: 17.344533 },
    { lat: 48.499799, lng: 17.343945 },
    { lat: 48.499561, lng: 17.343375 },
    { lat: 48.499257, lng: 17.342498 },
    { lat: 48.499227, lng: 17.341946 },
    { lat: 48.498989, lng: 17.341473 },
    { lat: 48.498954, lng: 17.341402 },
    { lat: 48.498704, lng: 17.340679 },
    { lat: 48.498516, lng: 17.340268 },
    { lat: 48.498516, lng: 17.340038 },
    { lat: 48.498205, lng: 17.338908 },
    { lat: 48.498156, lng: 17.33861 },
    { lat: 48.497977, lng: 17.338098 },
    { lat: 48.497969, lng: 17.337806 },
    { lat: 48.497946, lng: 17.33732 },
    { lat: 48.497687, lng: 17.33661 },
    { lat: 48.497505, lng: 17.336467 },
    { lat: 48.496722, lng: 17.335976 },
    { lat: 48.496434, lng: 17.335932 },
    { lat: 48.495492, lng: 17.336409 },
    { lat: 48.494736, lng: 17.337396 },
    { lat: 48.494565, lng: 17.337868 },
    { lat: 48.49448, lng: 17.338292 },
    { lat: 48.494446, lng: 17.338755 },
    { lat: 48.494397, lng: 17.33888 },
    { lat: 48.494149, lng: 17.339229 },
    { lat: 48.493941, lng: 17.339536 },
    { lat: 48.493735, lng: 17.339972 },
    { lat: 48.493591, lng: 17.340591 },
    { lat: 48.493312, lng: 17.341349 },
    { lat: 48.493097, lng: 17.34155 },
    { lat: 48.492516, lng: 17.341872 },
    { lat: 48.49229, lng: 17.342066 },
    { lat: 48.492044, lng: 17.342025 },
    { lat: 48.491771, lng: 17.341941 },
    { lat: 48.491679, lng: 17.341867 },
    { lat: 48.491415, lng: 17.341466 },
    { lat: 48.491169, lng: 17.341337 },
    { lat: 48.491101, lng: 17.341196 },
    { lat: 48.491107, lng: 17.339974 },
    { lat: 48.491076, lng: 17.339341 },
    { lat: 48.490961, lng: 17.338538 },
    { lat: 48.490698, lng: 17.337946 },
    { lat: 48.490244, lng: 17.337309 },
    { lat: 48.490107, lng: 17.337234 },
    { lat: 48.489213, lng: 17.337472 },
    { lat: 48.488968, lng: 17.337638 },
    { lat: 48.488283, lng: 17.337748 },
    { lat: 48.488063, lng: 17.337667 },
    { lat: 48.488055, lng: 17.337643 },
    { lat: 48.487786, lng: 17.336819 },
    { lat: 48.487306, lng: 17.336209 },
    { lat: 48.487087, lng: 17.336077 },
    { lat: 48.487055, lng: 17.335102 },
    { lat: 48.487097, lng: 17.33469 },
    { lat: 48.486921, lng: 17.334406 },
    { lat: 48.486427, lng: 17.334137 },
    { lat: 48.485827, lng: 17.334028 },
    { lat: 48.485191, lng: 17.334423 },
    { lat: 48.484859, lng: 17.335479 },
    { lat: 48.484734, lng: 17.335622 },
    { lat: 48.484389, lng: 17.335564 },
    { lat: 48.483848, lng: 17.335673 },
    { lat: 48.483278, lng: 17.33578 },
    { lat: 48.482596, lng: 17.33545 },
    { lat: 48.483141, lng: 17.333987 },
    { lat: 48.483337, lng: 17.333394 },
    { lat: 48.483566, lng: 17.332393 },
    { lat: 48.48379, lng: 17.331756 },
    { lat: 48.483871, lng: 17.331528 },
    { lat: 48.483888, lng: 17.331473 },
    { lat: 48.484023, lng: 17.331076 },
    { lat: 48.483772, lng: 17.330588 },
    { lat: 48.483561, lng: 17.330204 },
    { lat: 48.482889, lng: 17.329967 },
    { lat: 48.482542, lng: 17.329757 },
    { lat: 48.482501, lng: 17.329793 },
    { lat: 48.481929, lng: 17.32974 },
    { lat: 48.481744, lng: 17.32965 },
    { lat: 48.481537, lng: 17.329465 },
    { lat: 48.481345, lng: 17.329432 },
    { lat: 48.481081, lng: 17.32973 },
    { lat: 48.480577, lng: 17.329801 },
    { lat: 48.480408, lng: 17.3293716 },
    { lat: 48.4803545, lng: 17.3292026 },
    { lat: 48.480243, lng: 17.328886 },
    { lat: 48.480289, lng: 17.3287 },
    { lat: 48.480346, lng: 17.328331 },
    { lat: 48.480398, lng: 17.328148 },
    { lat: 48.480584, lng: 17.327753 },
    { lat: 48.48074, lng: 17.327482 },
    { lat: 48.48093, lng: 17.327069 },
    { lat: 48.480911, lng: 17.326794 },
    { lat: 48.48091, lng: 17.326306 },
    { lat: 48.480867, lng: 17.325588 },
    { lat: 48.480938, lng: 17.325387 },
    { lat: 48.480998, lng: 17.325142 },
    { lat: 48.481055, lng: 17.324973 },
    { lat: 48.481052, lng: 17.324805 },
    { lat: 48.481051, lng: 17.324746 },
    { lat: 48.481045, lng: 17.324335 },
    { lat: 48.480913, lng: 17.324051 },
    { lat: 48.480359, lng: 17.323203 },
    { lat: 48.48032, lng: 17.323113 },
    { lat: 48.480301, lng: 17.323068 },
    { lat: 48.480251, lng: 17.322949 },
    { lat: 48.480178, lng: 17.322716 },
    { lat: 48.480113, lng: 17.322237 },
    { lat: 48.480097, lng: 17.322029 },
    { lat: 48.480084, lng: 17.322029 },
    { lat: 48.480093, lng: 17.321826 },
    { lat: 48.480064, lng: 17.321251 },
    { lat: 48.480094, lng: 17.320763 },
    { lat: 48.480135, lng: 17.320554 },
    { lat: 48.480153, lng: 17.32022 },
    { lat: 48.480395, lng: 17.319822 },
    { lat: 48.480517, lng: 17.319623 },
    { lat: 48.480842, lng: 17.31919 },
    { lat: 48.481188, lng: 17.318693 },
    { lat: 48.48128, lng: 17.318374 },
    { lat: 48.481281, lng: 17.317996 },
    { lat: 48.481256, lng: 17.317428 },
    { lat: 48.481212, lng: 17.317154 },
    { lat: 48.481207, lng: 17.316632 },
    { lat: 48.481167, lng: 17.315853 },
    { lat: 48.481116, lng: 17.315154 },
    { lat: 48.481109, lng: 17.314666 },
    { lat: 48.481076, lng: 17.314128 },
    { lat: 48.480786, lng: 17.314028 },
    { lat: 48.480643, lng: 17.314084 },
    { lat: 48.480467, lng: 17.314034 },
    { lat: 48.480406, lng: 17.314016 },
    { lat: 48.480324, lng: 17.313993 },
    { lat: 48.480052, lng: 17.31398 },
    { lat: 48.479326, lng: 17.313892 },
    { lat: 48.478744, lng: 17.313914 },
    { lat: 48.478339, lng: 17.3141 },
    { lat: 48.477824, lng: 17.314371 },
  ],
  Smolenice: [
    { lat: 48.528669, lng: 17.4463397 },
    { lat: 48.528621, lng: 17.4455756 },
    { lat: 48.5287837, lng: 17.4444184 },
    { lat: 48.5287258, lng: 17.4439066 },
    { lat: 48.529006, lng: 17.4433291 },
    { lat: 48.5291675, lng: 17.4427853 },
    { lat: 48.5293793, lng: 17.4423046 },
    { lat: 48.5294565, lng: 17.4419224 },
    { lat: 48.5297137, lng: 17.4411829 },
    { lat: 48.5297626, lng: 17.4405899 },
    { lat: 48.5294917, lng: 17.4403124 },
    { lat: 48.5288621, lng: 17.4403412 },
    { lat: 48.5285526, lng: 17.4399895 },
    { lat: 48.5284257, lng: 17.4398088 },
    { lat: 48.5281934, lng: 17.4391643 },
    { lat: 48.5280432, lng: 17.4383233 },
    { lat: 48.5275168, lng: 17.4379002 },
    { lat: 48.5272451, lng: 17.4378758 },
    { lat: 48.5267783, lng: 17.4378339 },
    { lat: 48.5265779, lng: 17.437954 },
    { lat: 48.5262174, lng: 17.4379807 },
    { lat: 48.5261034, lng: 17.4377624 },
    { lat: 48.5260529, lng: 17.4376972 },
    { lat: 48.525895, lng: 17.4369378 },
    { lat: 48.525937, lng: 17.4364692 },
    { lat: 48.5251562, lng: 17.4343107 },
    { lat: 48.5249197, lng: 17.434056 },
    { lat: 48.5245042, lng: 17.4339689 },
    { lat: 48.5243574, lng: 17.4336044 },
    { lat: 48.5239074, lng: 17.4330157 },
    { lat: 48.5236254, lng: 17.43234 },
    { lat: 48.5235835, lng: 17.4321117 },
    { lat: 48.523785, lng: 17.4307194 },
    { lat: 48.5239614, lng: 17.430374 },
    { lat: 48.5240929, lng: 17.4301165 },
    { lat: 48.5241823, lng: 17.4298441 },
    { lat: 48.5242365, lng: 17.4296789 },
    { lat: 48.5244974, lng: 17.4293468 },
    { lat: 48.524732, lng: 17.4289042 },
    { lat: 48.5247975, lng: 17.4282394 },
    { lat: 48.5252332, lng: 17.4271673 },
    { lat: 48.5253154, lng: 17.4268155 },
    { lat: 48.525376, lng: 17.426556 },
    { lat: 48.5254136, lng: 17.4263951 },
    { lat: 48.5252193, lng: 17.4248515 },
    { lat: 48.5252737, lng: 17.4237885 },
    { lat: 48.5254876, lng: 17.4225697 },
    { lat: 48.5255072, lng: 17.4217565 },
    { lat: 48.5256247, lng: 17.4208457 },
    { lat: 48.5260401, lng: 17.4192851 },
    { lat: 48.5261949, lng: 17.4188858 },
    { lat: 48.5259839, lng: 17.4183819 },
    { lat: 48.5258385, lng: 17.4178089 },
    { lat: 48.5258081, lng: 17.4176832 },
    { lat: 48.5258021, lng: 17.4172829 },
    { lat: 48.5258522, lng: 17.4167799 },
    { lat: 48.5258185, lng: 17.4156286 },
    { lat: 48.5256385, lng: 17.4143669 },
    { lat: 48.5257141, lng: 17.413981 },
    { lat: 48.525524, lng: 17.4137648 },
    { lat: 48.5254942, lng: 17.4134613 },
    { lat: 48.5251648, lng: 17.4123348 },
    { lat: 48.5250131, lng: 17.4121179 },
    { lat: 48.5246647, lng: 17.4111489 },
    { lat: 48.5247351, lng: 17.4103853 },
    { lat: 48.5247056, lng: 17.4100871 },
    { lat: 48.5246844, lng: 17.4099167 },
    { lat: 48.5245256, lng: 17.4096457 },
    { lat: 48.5245292, lng: 17.4094054 },
    { lat: 48.5244175, lng: 17.40911 },
    { lat: 48.5247016, lng: 17.4084562 },
    { lat: 48.5248826, lng: 17.4077459 },
    { lat: 48.524971, lng: 17.4070673 },
    { lat: 48.5245246, lng: 17.4040603 },
    { lat: 48.5245117, lng: 17.4035844 },
    { lat: 48.5246019, lng: 17.4031792 },
    { lat: 48.5245019, lng: 17.4027713 },
    { lat: 48.5244429, lng: 17.4020495 },
    { lat: 48.5243558, lng: 17.4017683 },
    { lat: 48.5243258, lng: 17.4012361 },
    { lat: 48.524164, lng: 17.4007803 },
    { lat: 48.5242027, lng: 17.4004532 },
    { lat: 48.5240973, lng: 17.3999919 },
    { lat: 48.524134, lng: 17.3995825 },
    { lat: 48.5240291, lng: 17.3991329 },
    { lat: 48.52372, lng: 17.3964518 },
    { lat: 48.5237606, lng: 17.3961586 },
    { lat: 48.5236796, lng: 17.3959535 },
    { lat: 48.5235912, lng: 17.3951808 },
    { lat: 48.5232351, lng: 17.3952809 },
    { lat: 48.5226529, lng: 17.395256 },
    { lat: 48.5216127, lng: 17.3949428 },
    { lat: 48.5209623, lng: 17.395137 },
    { lat: 48.5199886, lng: 17.3948736 },
    { lat: 48.5192632, lng: 17.3941591 },
    { lat: 48.5191731, lng: 17.3940585 },
    { lat: 48.5189455, lng: 17.3937171 },
    { lat: 48.5187971, lng: 17.393363 },
    { lat: 48.5179856, lng: 17.392763 },
    { lat: 48.517174, lng: 17.3924223 },
    { lat: 48.5170916, lng: 17.3906306 },
    { lat: 48.5170165, lng: 17.390549 },
    { lat: 48.5167826, lng: 17.3883051 },
    { lat: 48.5166732, lng: 17.3880094 },
    { lat: 48.5166196, lng: 17.3873709 },
    { lat: 48.5165982, lng: 17.3871159 },
    { lat: 48.5162746, lng: 17.3856095 },
    { lat: 48.5162285, lng: 17.3850747 },
    { lat: 48.5160845, lng: 17.3844753 },
    { lat: 48.5160232, lng: 17.3832866 },
    { lat: 48.5159643, lng: 17.3831271 },
    { lat: 48.5160082, lng: 17.3829678 },
    { lat: 48.5159616, lng: 17.38267 },
    { lat: 48.5161463, lng: 17.3820878 },
    { lat: 48.5160677, lng: 17.3818503 },
    { lat: 48.5160615, lng: 17.3814935 },
    { lat: 48.5159877, lng: 17.3810341 },
    { lat: 48.5158524, lng: 17.3805186 },
    { lat: 48.5158331, lng: 17.3800137 },
    { lat: 48.5157111, lng: 17.3796662 },
    { lat: 48.5156356, lng: 17.3791817 },
    { lat: 48.5155349, lng: 17.3771947 },
    { lat: 48.5155435, lng: 17.3765297 },
    { lat: 48.515409, lng: 17.3752249 },
    { lat: 48.5152922, lng: 17.3746265 },
    { lat: 48.5147377, lng: 17.3725603 },
    { lat: 48.5146357, lng: 17.3717745 },
    { lat: 48.5144158, lng: 17.3707929 },
    { lat: 48.5140904, lng: 17.3697679 },
    { lat: 48.5139608, lng: 17.3692166 },
    { lat: 48.5139052, lng: 17.3680816 },
    { lat: 48.5143474, lng: 17.3669982 },
    { lat: 48.5143862, lng: 17.3658599 },
    { lat: 48.5142888, lng: 17.3654724 },
    { lat: 48.5140799, lng: 17.3639111 },
    { lat: 48.5138923, lng: 17.3631454 },
    { lat: 48.5137736, lng: 17.361916 },
    { lat: 48.513432, lng: 17.360507 },
    { lat: 48.511864, lng: 17.360181 },
    { lat: 48.511019, lng: 17.360745 },
    { lat: 48.510299, lng: 17.359919 },
    { lat: 48.509662, lng: 17.358986 },
    { lat: 48.509011, lng: 17.358181 },
    { lat: 48.50815, lng: 17.357116 },
    { lat: 48.507706, lng: 17.356488 },
    { lat: 48.507185, lng: 17.355819 },
    { lat: 48.506589, lng: 17.355047 },
    { lat: 48.5052071, lng: 17.3604229 },
    { lat: 48.5028375, lng: 17.3633766 },
    { lat: 48.5024834, lng: 17.3640967 },
    { lat: 48.5011811, lng: 17.3653978 },
    { lat: 48.5003715, lng: 17.3665309 },
    { lat: 48.4998122, lng: 17.3679161 },
    { lat: 48.4994258, lng: 17.3682961 },
    { lat: 48.4993398, lng: 17.3686305 },
    { lat: 48.4991374, lng: 17.3694171 },
    { lat: 48.4992675, lng: 17.369628 },
    { lat: 48.4997027, lng: 17.3698947 },
    { lat: 48.500056, lng: 17.3696973 },
    { lat: 48.4999881, lng: 17.3706871 },
    { lat: 48.4998739, lng: 17.3714312 },
    { lat: 48.4992794, lng: 17.3725709 },
    { lat: 48.4991985, lng: 17.3729639 },
    { lat: 48.4985335, lng: 17.3761948 },
    { lat: 48.498395, lng: 17.3767039 },
    { lat: 48.4980412, lng: 17.3775341 },
    { lat: 48.4978923, lng: 17.3776572 },
    { lat: 48.4976815, lng: 17.3783944 },
    { lat: 48.4974912, lng: 17.3784752 },
    { lat: 48.4975743, lng: 17.3787806 },
    { lat: 48.4972001, lng: 17.3805294 },
    { lat: 48.497159, lng: 17.3813386 },
    { lat: 48.4970537, lng: 17.3821707 },
    { lat: 48.496781, lng: 17.3833468 },
    { lat: 48.4964424, lng: 17.3840808 },
    { lat: 48.4967413, lng: 17.3873271 },
    { lat: 48.4986736, lng: 17.3870241 },
    { lat: 48.4986579, lng: 17.3879296 },
    { lat: 48.4982957, lng: 17.3892236 },
    { lat: 48.4978726, lng: 17.3900159 },
    { lat: 48.4977565, lng: 17.3908031 },
    { lat: 48.4977072, lng: 17.3911371 },
    { lat: 48.4977848, lng: 17.3917094 },
    { lat: 48.4977219, lng: 17.3928258 },
    { lat: 48.4974344, lng: 17.3937067 },
    { lat: 48.4971561, lng: 17.3949367 },
    { lat: 48.4967152, lng: 17.3958679 },
    { lat: 48.4954311, lng: 17.3975614 },
    { lat: 48.4948477, lng: 17.3984593 },
    { lat: 48.4941797, lng: 17.3999661 },
    { lat: 48.4939438, lng: 17.4002489 },
    { lat: 48.4937677, lng: 17.4002526 },
    { lat: 48.4924966, lng: 17.4008344 },
    { lat: 48.4916836, lng: 17.4014337 },
    { lat: 48.4915594, lng: 17.4015189 },
    { lat: 48.4915482, lng: 17.4014892 },
    { lat: 48.4914276, lng: 17.4017159 },
    { lat: 48.490982, lng: 17.4021968 },
    { lat: 48.4904041, lng: 17.4027096 },
    { lat: 48.4900427, lng: 17.4032137 },
    { lat: 48.4895172, lng: 17.4034022 },
    { lat: 48.4891001, lng: 17.4044091 },
    { lat: 48.4890143, lng: 17.4050722 },
    { lat: 48.4887382, lng: 17.4056735 },
    { lat: 48.4884472, lng: 17.406063 },
    { lat: 48.4883459, lng: 17.4061209 },
    { lat: 48.487851, lng: 17.4076344 },
    { lat: 48.4876934, lng: 17.4086915 },
    { lat: 48.48753, lng: 17.4093951 },
    { lat: 48.4872584, lng: 17.4102741 },
    { lat: 48.4870919, lng: 17.4110377 },
    { lat: 48.486838, lng: 17.4110232 },
    { lat: 48.4867006, lng: 17.4111673 },
    { lat: 48.4861459, lng: 17.4131395 },
    { lat: 48.4860133, lng: 17.4138998 },
    { lat: 48.4860726, lng: 17.4162281 },
    { lat: 48.4860051, lng: 17.4167945 },
    { lat: 48.4859137, lng: 17.4171597 },
    { lat: 48.4859002, lng: 17.4174938 },
    { lat: 48.4859072, lng: 17.4216537 },
    { lat: 48.4862237, lng: 17.4255925 },
    { lat: 48.4862794, lng: 17.4256157 },
    { lat: 48.4858941, lng: 17.427851 },
    { lat: 48.4857968, lng: 17.4280226 },
    { lat: 48.4854058, lng: 17.4283524 },
    { lat: 48.4858458, lng: 17.4287816 },
    { lat: 48.485938, lng: 17.4295795 },
    { lat: 48.4859187, lng: 17.4304343 },
    { lat: 48.4855743, lng: 17.43047 },
    { lat: 48.4855379, lng: 17.4314466 },
    { lat: 48.4856097, lng: 17.4315803 },
    { lat: 48.484945, lng: 17.433163 },
    { lat: 48.4846749, lng: 17.4335279 },
    { lat: 48.4836894, lng: 17.435234 },
    { lat: 48.4831642, lng: 17.4365841 },
    { lat: 48.4831101, lng: 17.437073 },
    { lat: 48.4829746, lng: 17.4373356 },
    { lat: 48.4825144, lng: 17.4379072 },
    { lat: 48.4824933, lng: 17.4379323 },
    { lat: 48.48279, lng: 17.4380838 },
    { lat: 48.4827859, lng: 17.4386849 },
    { lat: 48.4829108, lng: 17.439043 },
    { lat: 48.4829109, lng: 17.4391889 },
    { lat: 48.4828087, lng: 17.4393171 },
    { lat: 48.4830108, lng: 17.4395806 },
    { lat: 48.4830473, lng: 17.4394606 },
    { lat: 48.4831064, lng: 17.4395556 },
    { lat: 48.4831453, lng: 17.4396856 },
    { lat: 48.4831149, lng: 17.4397566 },
    { lat: 48.4831694, lng: 17.4398682 },
    { lat: 48.4831595, lng: 17.4399758 },
    { lat: 48.483019, lng: 17.4401273 },
    { lat: 48.482943, lng: 17.4409639 },
    { lat: 48.4828888, lng: 17.4410509 },
    { lat: 48.4828818, lng: 17.4413983 },
    { lat: 48.4830862, lng: 17.4418195 },
    { lat: 48.4830365, lng: 17.442149 },
    { lat: 48.483071, lng: 17.4425633 },
    { lat: 48.4832374, lng: 17.4428687 },
    { lat: 48.4833887, lng: 17.4430054 },
    { lat: 48.4834887, lng: 17.443261 },
    { lat: 48.4836955, lng: 17.4433662 },
    { lat: 48.483663, lng: 17.4434421 },
    { lat: 48.4836964, lng: 17.443784 },
    { lat: 48.4838239, lng: 17.4442577 },
    { lat: 48.4841216, lng: 17.4443001 },
    { lat: 48.4841417, lng: 17.4444214 },
    { lat: 48.4844161, lng: 17.4443159 },
    { lat: 48.4844466, lng: 17.4443496 },
    { lat: 48.4844312, lng: 17.444532 },
    { lat: 48.4846479, lng: 17.4444249 },
    { lat: 48.4847388, lng: 17.4446089 },
    { lat: 48.485055, lng: 17.4447486 },
    { lat: 48.4851542, lng: 17.4447078 },
    { lat: 48.4852212, lng: 17.4449444 },
    { lat: 48.4853958, lng: 17.444884 },
    { lat: 48.4856629, lng: 17.4449081 },
    { lat: 48.4857997, lng: 17.4448415 },
    { lat: 48.4858804, lng: 17.4450259 },
    { lat: 48.4859864, lng: 17.4450724 },
    { lat: 48.4860543, lng: 17.4452519 },
    { lat: 48.4861946, lng: 17.4453945 },
    { lat: 48.4864567, lng: 17.4454157 },
    { lat: 48.4865714, lng: 17.4452851 },
    { lat: 48.4867411, lng: 17.4452369 },
    { lat: 48.4868309, lng: 17.4453163 },
    { lat: 48.4869321, lng: 17.4451859 },
    { lat: 48.4870872, lng: 17.4454004 },
    { lat: 48.4871365, lng: 17.4453618 },
    { lat: 48.4871578, lng: 17.4451487 },
    { lat: 48.4873656, lng: 17.4454463 },
    { lat: 48.4872685, lng: 17.4456388 },
    { lat: 48.4872773, lng: 17.4457066 },
    { lat: 48.487415, lng: 17.4457392 },
    { lat: 48.487445, lng: 17.445591 },
    { lat: 48.4875283, lng: 17.4456903 },
    { lat: 48.4874665, lng: 17.4457477 },
    { lat: 48.4875005, lng: 17.4458207 },
    { lat: 48.487657, lng: 17.4458314 },
    { lat: 48.4877296, lng: 17.4456438 },
    { lat: 48.4877589, lng: 17.4457831 },
    { lat: 48.4880059, lng: 17.445935 },
    { lat: 48.4881075, lng: 17.4457571 },
    { lat: 48.4883033, lng: 17.445766 },
    { lat: 48.4883485, lng: 17.4458534 },
    { lat: 48.4884969, lng: 17.4459119 },
    { lat: 48.4885589, lng: 17.4461218 },
    { lat: 48.4884492, lng: 17.4461524 },
    { lat: 48.4884139, lng: 17.4462739 },
    { lat: 48.488465, lng: 17.4463794 },
    { lat: 48.4885686, lng: 17.4464483 },
    { lat: 48.4886599, lng: 17.4464257 },
    { lat: 48.4886929, lng: 17.4465519 },
    { lat: 48.4888041, lng: 17.4464378 },
    { lat: 48.4888403, lng: 17.4462976 },
    { lat: 48.4889224, lng: 17.4465217 },
    { lat: 48.489205, lng: 17.4464559 },
    { lat: 48.4892004, lng: 17.4466307 },
    { lat: 48.4892749, lng: 17.4467177 },
    { lat: 48.4892627, lng: 17.446895 },
    { lat: 48.4893303, lng: 17.4470151 },
    { lat: 48.4894444, lng: 17.4469095 },
    { lat: 48.4896244, lng: 17.4470218 },
    { lat: 48.4896755, lng: 17.4473073 },
    { lat: 48.4899614, lng: 17.4472927 },
    { lat: 48.4900575, lng: 17.4474147 },
    { lat: 48.4899624, lng: 17.4475874 },
    { lat: 48.4901647, lng: 17.4477484 },
    { lat: 48.4901622, lng: 17.4479008 },
    { lat: 48.4900629, lng: 17.4480096 },
    { lat: 48.4901403, lng: 17.448124 },
    { lat: 48.4901638, lng: 17.448294 },
    { lat: 48.4901051, lng: 17.4484699 },
    { lat: 48.4901682, lng: 17.4486782 },
    { lat: 48.490099, lng: 17.4487424 },
    { lat: 48.4900887, lng: 17.4488283 },
    { lat: 48.4902063, lng: 17.448902 },
    { lat: 48.4902072, lng: 17.4489866 },
    { lat: 48.4900962, lng: 17.4490854 },
    { lat: 48.4900981, lng: 17.4493603 },
    { lat: 48.4901538, lng: 17.4494362 },
    { lat: 48.4902178, lng: 17.4493785 },
    { lat: 48.4902619, lng: 17.4495006 },
    { lat: 48.4903308, lng: 17.4494129 },
    { lat: 48.4904507, lng: 17.4494015 },
    { lat: 48.4904184, lng: 17.44969 },
    { lat: 48.490583, lng: 17.4495079 },
    { lat: 48.4906368, lng: 17.4495706 },
    { lat: 48.4906395, lng: 17.4497706 },
    { lat: 48.4907472, lng: 17.4497781 },
    { lat: 48.4907444, lng: 17.4498931 },
    { lat: 48.4908335, lng: 17.449925 },
    { lat: 48.490814, lng: 17.4500631 },
    { lat: 48.4909072, lng: 17.4500877 },
    { lat: 48.4908844, lng: 17.4502263 },
    { lat: 48.4909853, lng: 17.4503006 },
    { lat: 48.4909254, lng: 17.4505276 },
    { lat: 48.4908646, lng: 17.4505842 },
    { lat: 48.4908008, lng: 17.4505041 },
    { lat: 48.4907948, lng: 17.4505687 },
    { lat: 48.4908833, lng: 17.4507164 },
    { lat: 48.4910322, lng: 17.4507499 },
    { lat: 48.4910451, lng: 17.4508246 },
    { lat: 48.491078, lng: 17.4508283 },
    { lat: 48.4900153, lng: 17.4529354 },
    { lat: 48.4899066, lng: 17.453298 },
    { lat: 48.48984, lng: 17.4540721 },
    { lat: 48.4897422, lng: 17.4544143 },
    { lat: 48.4888561, lng: 17.4561423 },
    { lat: 48.4887376, lng: 17.4570899 },
    { lat: 48.4889839, lng: 17.4587587 },
    { lat: 48.4889564, lng: 17.4592997 },
    { lat: 48.4888663, lng: 17.4597055 },
    { lat: 48.4880679, lng: 17.4608666 },
    { lat: 48.4878843, lng: 17.4613316 },
    { lat: 48.4877132, lng: 17.4628704 },
    { lat: 48.4876299, lng: 17.4632613 },
    { lat: 48.4879586, lng: 17.4634709 },
    { lat: 48.4871254, lng: 17.4663409 },
    { lat: 48.4866558, lng: 17.4658723 },
    { lat: 48.4865506, lng: 17.4662387 },
    { lat: 48.486486, lng: 17.4661801 },
    { lat: 48.4864796, lng: 17.4659923 },
    { lat: 48.4863884, lng: 17.466084 },
    { lat: 48.4863617, lng: 17.4661462 },
    { lat: 48.4862754, lng: 17.4663143 },
    { lat: 48.4863643, lng: 17.4664153 },
    { lat: 48.4863686, lng: 17.4665852 },
    { lat: 48.4863168, lng: 17.4666226 },
    { lat: 48.4861805, lng: 17.4664352 },
    { lat: 48.485985, lng: 17.4663829 },
    { lat: 48.4856674, lng: 17.4668698 },
    { lat: 48.4857403, lng: 17.4670717 },
    { lat: 48.4856632, lng: 17.4672435 },
    { lat: 48.4856771, lng: 17.4674597 },
    { lat: 48.4855455, lng: 17.46789 },
    { lat: 48.4856693, lng: 17.4680087 },
    { lat: 48.4857567, lng: 17.4680449 },
    { lat: 48.4859107, lng: 17.4681086 },
    { lat: 48.4860293, lng: 17.4684068 },
    { lat: 48.4862038, lng: 17.4684519 },
    { lat: 48.4862224, lng: 17.4685656 },
    { lat: 48.4861372, lng: 17.468683 },
    { lat: 48.485921, lng: 17.4687621 },
    { lat: 48.4855271, lng: 17.4683445 },
    { lat: 48.4853593, lng: 17.4683333 },
    { lat: 48.485306, lng: 17.468472 },
    { lat: 48.4853518, lng: 17.4690079 },
    { lat: 48.4851872, lng: 17.4689596 },
    { lat: 48.4851493, lng: 17.4690399 },
    { lat: 48.4852468, lng: 17.4694116 },
    { lat: 48.4851625, lng: 17.4692964 },
    { lat: 48.4850745, lng: 17.4693149 },
    { lat: 48.4849971, lng: 17.4692015 },
    { lat: 48.4848699, lng: 17.4693279 },
    { lat: 48.4848037, lng: 17.4696017 },
    { lat: 48.4849434, lng: 17.4697732 },
    { lat: 48.4847594, lng: 17.4704234 },
    { lat: 48.4847166, lng: 17.4698792 },
    { lat: 48.4846487, lng: 17.4698933 },
    { lat: 48.484632, lng: 17.4701642 },
    { lat: 48.4845547, lng: 17.470122 },
    { lat: 48.4844895, lng: 17.4701934 },
    { lat: 48.4844636, lng: 17.4706379 },
    { lat: 48.4847757, lng: 17.4708542 },
    { lat: 48.4848397, lng: 17.4710924 },
    { lat: 48.4848052, lng: 17.4712441 },
    { lat: 48.4848415, lng: 17.4714029 },
    { lat: 48.4848058, lng: 17.4714964 },
    { lat: 48.4846783, lng: 17.4715014 },
    { lat: 48.4845764, lng: 17.4717049 },
    { lat: 48.4846205, lng: 17.4719345 },
    { lat: 48.4847336, lng: 17.4719757 },
    { lat: 48.4848399, lng: 17.4721522 },
    { lat: 48.4848543, lng: 17.4726567 },
    { lat: 48.4847832, lng: 17.4726933 },
    { lat: 48.4846954, lng: 17.4726271 },
    { lat: 48.4845408, lng: 17.4726994 },
    { lat: 48.4844742, lng: 17.4726649 },
    { lat: 48.4844248, lng: 17.4724777 },
    { lat: 48.484371, lng: 17.4724837 },
    { lat: 48.4843249, lng: 17.4725032 },
    { lat: 48.4843135, lng: 17.4727673 },
    { lat: 48.4842741, lng: 17.4728403 },
    { lat: 48.4841405, lng: 17.4726753 },
    { lat: 48.4841693, lng: 17.472508 },
    { lat: 48.4840328, lng: 17.4724396 },
    { lat: 48.4837529, lng: 17.4724632 },
    { lat: 48.4836334, lng: 17.4730964 },
    { lat: 48.4836843, lng: 17.4732043 },
    { lat: 48.483701, lng: 17.4735529 },
    { lat: 48.483735, lng: 17.4736074 },
    { lat: 48.4839664, lng: 17.4735881 },
    { lat: 48.4839383, lng: 17.4738029 },
    { lat: 48.4841116, lng: 17.4739664 },
    { lat: 48.4839718, lng: 17.4741804 },
    { lat: 48.4839302, lng: 17.474149 },
    { lat: 48.4833821, lng: 17.4743924 },
    { lat: 48.4831577, lng: 17.4749967 },
    { lat: 48.4830236, lng: 17.4755804 },
    { lat: 48.4827715, lng: 17.4771254 },
    { lat: 48.4826273, lng: 17.4780274 },
    { lat: 48.4830934, lng: 17.4786051 },
    { lat: 48.4827825, lng: 17.4791632 },
    { lat: 48.4830847, lng: 17.4801966 },
    { lat: 48.4830077, lng: 17.4804726 },
    { lat: 48.4830738, lng: 17.4807824 },
    { lat: 48.4831756, lng: 17.4809605 },
    { lat: 48.4832914, lng: 17.4810057 },
    { lat: 48.4835597, lng: 17.480876 },
    { lat: 48.4838528, lng: 17.4810442 },
    { lat: 48.4839177, lng: 17.4810337 },
    { lat: 48.4839762, lng: 17.4809241 },
    { lat: 48.4841159, lng: 17.4809167 },
    { lat: 48.4840253, lng: 17.4810216 },
    { lat: 48.4841299, lng: 17.4813496 },
    { lat: 48.4842107, lng: 17.481392 },
    { lat: 48.4843965, lng: 17.4818051 },
    { lat: 48.4845448, lng: 17.4818295 },
    { lat: 48.4847786, lng: 17.4812836 },
    { lat: 48.4847434, lng: 17.4812293 },
    { lat: 48.4846458, lng: 17.481257 },
    { lat: 48.484638, lng: 17.4811681 },
    { lat: 48.4848119, lng: 17.4810129 },
    { lat: 48.4849152, lng: 17.4810549 },
    { lat: 48.485254, lng: 17.4809316 },
    { lat: 48.4852278, lng: 17.4806628 },
    { lat: 48.485366, lng: 17.4805452 },
    { lat: 48.4853676, lng: 17.4802007 },
    { lat: 48.485459, lng: 17.4800763 },
    { lat: 48.4855162, lng: 17.4798242 },
    { lat: 48.4857293, lng: 17.479925 },
    { lat: 48.4858614, lng: 17.4802216 },
    { lat: 48.4859704, lng: 17.4802161 },
    { lat: 48.4860261, lng: 17.480062 },
    { lat: 48.4861851, lng: 17.4800575 },
    { lat: 48.4861995, lng: 17.4798585 },
    { lat: 48.4861535, lng: 17.4797427 },
    { lat: 48.4862086, lng: 17.4796311 },
    { lat: 48.4861919, lng: 17.4794772 },
    { lat: 48.4862703, lng: 17.4794114 },
    { lat: 48.4862398, lng: 17.4792909 },
    { lat: 48.4862839, lng: 17.4792029 },
    { lat: 48.4863624, lng: 17.4791773 },
    { lat: 48.4864958, lng: 17.4792487 },
    { lat: 48.4864837, lng: 17.4791487 },
    { lat: 48.4866161, lng: 17.4790479 },
    { lat: 48.4868858, lng: 17.4791854 },
    { lat: 48.487009, lng: 17.4790628 },
    { lat: 48.4870716, lng: 17.4792264 },
    { lat: 48.4871559, lng: 17.4791173 },
    { lat: 48.4872838, lng: 17.478679 },
    { lat: 48.4874758, lng: 17.478681 },
    { lat: 48.4875279, lng: 17.4785959 },
    { lat: 48.487469, lng: 17.4783641 },
    { lat: 48.4876153, lng: 17.4783532 },
    { lat: 48.4877461, lng: 17.4782117 },
    { lat: 48.4880356, lng: 17.4783566 },
    { lat: 48.488128, lng: 17.4781962 },
    { lat: 48.4882366, lng: 17.4781448 },
    { lat: 48.4884443, lng: 17.4781987 },
    { lat: 48.488599, lng: 17.4778469 },
    { lat: 48.4887946, lng: 17.4777078 },
    { lat: 48.4887483, lng: 17.4775845 },
    { lat: 48.4892212, lng: 17.4774226 },
    { lat: 48.4900731, lng: 17.4768275 },
    { lat: 48.490154, lng: 17.4769447 },
    { lat: 48.4903694, lng: 17.4775539 },
    { lat: 48.4901964, lng: 17.4777271 },
    { lat: 48.4901591, lng: 17.4780085 },
    { lat: 48.4903914, lng: 17.4782322 },
    { lat: 48.4904362, lng: 17.478016 },
    { lat: 48.4906296, lng: 17.4778248 },
    { lat: 48.4906625, lng: 17.4778802 },
    { lat: 48.4906556, lng: 17.4780902 },
    { lat: 48.4908668, lng: 17.4780026 },
    { lat: 48.4907669, lng: 17.4781869 },
    { lat: 48.4908691, lng: 17.4781977 },
    { lat: 48.4909601, lng: 17.4783654 },
    { lat: 48.4910689, lng: 17.4781619 },
    { lat: 48.4911455, lng: 17.4782815 },
    { lat: 48.4912748, lng: 17.4783418 },
    { lat: 48.491344, lng: 17.4782773 },
    { lat: 48.4913029, lng: 17.4780575 },
    { lat: 48.4913968, lng: 17.4780275 },
    { lat: 48.491525, lng: 17.477823 },
    { lat: 48.491676, lng: 17.4781187 },
    { lat: 48.4917328, lng: 17.4780001 },
    { lat: 48.4918261, lng: 17.4780631 },
    { lat: 48.4919107, lng: 17.4780251 },
    { lat: 48.4919032, lng: 17.4779636 },
    { lat: 48.4917993, lng: 17.4779082 },
    { lat: 48.4917875, lng: 17.4777237 },
    { lat: 48.4919273, lng: 17.4776524 },
    { lat: 48.4919756, lng: 17.4774866 },
    { lat: 48.4921363, lng: 17.4776349 },
    { lat: 48.4921508, lng: 17.4777532 },
    { lat: 48.4923736, lng: 17.4775307 },
    { lat: 48.4925248, lng: 17.4778096 },
    { lat: 48.4926705, lng: 17.4778977 },
    { lat: 48.4928331, lng: 17.4776694 },
    { lat: 48.4928255, lng: 17.4779739 },
    { lat: 48.4930206, lng: 17.4780908 },
    { lat: 48.4931319, lng: 17.4779778 },
    { lat: 48.4931318, lng: 17.4774477 },
    { lat: 48.4931943, lng: 17.4775262 },
    { lat: 48.4936204, lng: 17.4774244 },
    { lat: 48.4937441, lng: 17.4773274 },
    { lat: 48.4937883, lng: 17.4774698 },
    { lat: 48.4940464, lng: 17.4774466 },
    { lat: 48.4941011, lng: 17.4773842 },
    { lat: 48.4941589, lng: 17.4770852 },
    { lat: 48.4943712, lng: 17.4772965 },
    { lat: 48.4945464, lng: 17.4771244 },
    { lat: 48.4946516, lng: 17.4772152 },
    { lat: 48.4946566, lng: 17.4773965 },
    { lat: 48.4947525, lng: 17.4777468 },
    { lat: 48.4948648, lng: 17.47779 },
    { lat: 48.4949719, lng: 17.4775401 },
    { lat: 48.4951568, lng: 17.4776407 },
    { lat: 48.4951453, lng: 17.477746 },
    { lat: 48.4952477, lng: 17.4778447 },
    { lat: 48.4953989, lng: 17.477648 },
    { lat: 48.4954204, lng: 17.4774009 },
    { lat: 48.495506, lng: 17.4772592 },
    { lat: 48.4956192, lng: 17.4772606 },
    { lat: 48.4958419, lng: 17.4771246 },
    { lat: 48.4960034, lng: 17.4771251 },
    { lat: 48.4959918, lng: 17.4772763 },
    { lat: 48.4961182, lng: 17.4774363 },
    { lat: 48.4962177, lng: 17.4771196 },
    { lat: 48.4962562, lng: 17.4771225 },
    { lat: 48.496329, lng: 17.477394 },
    { lat: 48.4963668, lng: 17.4774013 },
    { lat: 48.4964742, lng: 17.4772454 },
    { lat: 48.496507, lng: 17.4770003 },
    { lat: 48.4965661, lng: 17.4769355 },
    { lat: 48.4966103, lng: 17.4770786 },
    { lat: 48.4967064, lng: 17.4771871 },
    { lat: 48.4971006, lng: 17.4772741 },
    { lat: 48.4972144, lng: 17.4771632 },
    { lat: 48.4974436, lng: 17.4772677 },
    { lat: 48.4975194, lng: 17.4771837 },
    { lat: 48.4976327, lng: 17.4772421 },
    { lat: 48.4976409, lng: 17.4771415 },
    { lat: 48.4977914, lng: 17.4770933 },
    { lat: 48.4980092, lng: 17.4773708 },
    { lat: 48.4981839, lng: 17.4772938 },
    { lat: 48.4983875, lng: 17.4773864 },
    { lat: 48.4984546, lng: 17.4772185 },
    { lat: 48.4984109, lng: 17.477066 },
    { lat: 48.4984354, lng: 17.4769902 },
    { lat: 48.4986144, lng: 17.4767512 },
    { lat: 48.4987851, lng: 17.4767553 },
    { lat: 48.4988632, lng: 17.4765976 },
    { lat: 48.4988956, lng: 17.4767008 },
    { lat: 48.4990356, lng: 17.4767688 },
    { lat: 48.4991321, lng: 17.4766718 },
    { lat: 48.4992343, lng: 17.4767342 },
    { lat: 48.4996047, lng: 17.4765394 },
    { lat: 48.5000673, lng: 17.4765851 },
    { lat: 48.5001765, lng: 17.4763652 },
    { lat: 48.500283, lng: 17.4763024 },
    { lat: 48.5003755, lng: 17.4760372 },
    { lat: 48.5005204, lng: 17.4759203 },
    { lat: 48.5005405, lng: 17.4758273 },
    { lat: 48.5007776, lng: 17.4757715 },
    { lat: 48.5008496, lng: 17.4756991 },
    { lat: 48.5009249, lng: 17.4757612 },
    { lat: 48.5011001, lng: 17.4757111 },
    { lat: 48.5011158, lng: 17.4759222 },
    { lat: 48.5011662, lng: 17.4759148 },
    { lat: 48.5012736, lng: 17.4760923 },
    { lat: 48.5013898, lng: 17.4757923 },
    { lat: 48.5014181, lng: 17.4758102 },
    { lat: 48.5014364, lng: 17.4759037 },
    { lat: 48.5016383, lng: 17.4760553 },
    { lat: 48.5015794, lng: 17.4762466 },
    { lat: 48.5016236, lng: 17.4763719 },
    { lat: 48.5016966, lng: 17.4762253 },
    { lat: 48.5018182, lng: 17.476432 },
    { lat: 48.5018908, lng: 17.476435 },
    { lat: 48.5018847, lng: 17.4761997 },
    { lat: 48.5019576, lng: 17.4760855 },
    { lat: 48.5020974, lng: 17.476327 },
    { lat: 48.5021143, lng: 17.4764687 },
    { lat: 48.5021611, lng: 17.4764755 },
    { lat: 48.5024427, lng: 17.4763751 },
    { lat: 48.5024966, lng: 17.476212 },
    { lat: 48.5026072, lng: 17.4761432 },
    { lat: 48.502645, lng: 17.4756399 },
    { lat: 48.503418, lng: 17.4752758 },
    { lat: 48.5035075, lng: 17.4755823 },
    { lat: 48.5035515, lng: 17.4755648 },
    { lat: 48.5037231, lng: 17.4757372 },
    { lat: 48.5038067, lng: 17.4757023 },
    { lat: 48.503915, lng: 17.4759287 },
    { lat: 48.5038926, lng: 17.4762147 },
    { lat: 48.5040496, lng: 17.4762489 },
    { lat: 48.5041539, lng: 17.4760824 },
    { lat: 48.5042848, lng: 17.4760609 },
    { lat: 48.5043818, lng: 17.4759535 },
    { lat: 48.5043639, lng: 17.4758838 },
    { lat: 48.5042626, lng: 17.4759103 },
    { lat: 48.5042246, lng: 17.4758446 },
    { lat: 48.504222, lng: 17.4755575 },
    { lat: 48.5043338, lng: 17.4755591 },
    { lat: 48.5043994, lng: 17.4756628 },
    { lat: 48.5044392, lng: 17.4753874 },
    { lat: 48.5044859, lng: 17.4753222 },
    { lat: 48.5046965, lng: 17.4754028 },
    { lat: 48.5048958, lng: 17.475215 },
    { lat: 48.5049556, lng: 17.4752497 },
    { lat: 48.5049511, lng: 17.4753907 },
    { lat: 48.5050147, lng: 17.4753964 },
    { lat: 48.5051271, lng: 17.4748238 },
    { lat: 48.5052445, lng: 17.4737843 },
    { lat: 48.5058215, lng: 17.4736256 },
    { lat: 48.5059571, lng: 17.4736967 },
    { lat: 48.5061014, lng: 17.4739386 },
    { lat: 48.5062378, lng: 17.4740053 },
    { lat: 48.5062777, lng: 17.4739561 },
    { lat: 48.5066678, lng: 17.4742819 },
    { lat: 48.5069384, lng: 17.474608 },
    { lat: 48.5070732, lng: 17.4748808 },
    { lat: 48.5072402, lng: 17.4747715 },
    { lat: 48.5072459, lng: 17.4746695 },
    { lat: 48.5073301, lng: 17.4745358 },
    { lat: 48.5079086, lng: 17.4744001 },
    { lat: 48.508137, lng: 17.4739096 },
    { lat: 48.5089999, lng: 17.4727328 },
    { lat: 48.5091221, lng: 17.4726125 },
    { lat: 48.5090234, lng: 17.4717096 },
    { lat: 48.5095279, lng: 17.4707526 },
    { lat: 48.5097631, lng: 17.4709663 },
    { lat: 48.5102645, lng: 17.4709319 },
    { lat: 48.5110279, lng: 17.4712986 },
    { lat: 48.5115927, lng: 17.4705967 },
    { lat: 48.5130147, lng: 17.4692783 },
    { lat: 48.5138707, lng: 17.4689967 },
    { lat: 48.513796, lng: 17.4686784 },
    { lat: 48.5160307, lng: 17.465925 },
    { lat: 48.5159513, lng: 17.4657617 },
    { lat: 48.515963, lng: 17.4649075 },
    { lat: 48.5161193, lng: 17.4645642 },
    { lat: 48.517108, lng: 17.4633384 },
    { lat: 48.5175531, lng: 17.4641151 },
    { lat: 48.5178454, lng: 17.4634954 },
    { lat: 48.51802, lng: 17.4626257 },
    { lat: 48.5185684, lng: 17.4621619 },
    { lat: 48.518721, lng: 17.4621464 },
    { lat: 48.5187563, lng: 17.4620444 },
    { lat: 48.5186379, lng: 17.4617659 },
    { lat: 48.5187911, lng: 17.461356 },
    { lat: 48.5188828, lng: 17.461254 },
    { lat: 48.5190423, lng: 17.4614153 },
    { lat: 48.5190699, lng: 17.4613696 },
    { lat: 48.5190341, lng: 17.4612177 },
    { lat: 48.5190718, lng: 17.4609792 },
    { lat: 48.5192904, lng: 17.461092 },
    { lat: 48.5194353, lng: 17.4609936 },
    { lat: 48.5194515, lng: 17.4608926 },
    { lat: 48.5193664, lng: 17.4608166 },
    { lat: 48.5194763, lng: 17.4606823 },
    { lat: 48.5193928, lng: 17.4602449 },
    { lat: 48.5194494, lng: 17.4601234 },
    { lat: 48.5195401, lng: 17.460077 },
    { lat: 48.5195511, lng: 17.4599116 },
    { lat: 48.5193744, lng: 17.4589384 },
    { lat: 48.5194402, lng: 17.4588201 },
    { lat: 48.5195612, lng: 17.4587701 },
    { lat: 48.5195991, lng: 17.4586744 },
    { lat: 48.519653, lng: 17.4587065 },
    { lat: 48.5198835, lng: 17.4584086 },
    { lat: 48.5200429, lng: 17.458587 },
    { lat: 48.5201131, lng: 17.4585376 },
    { lat: 48.520207, lng: 17.4582927 },
    { lat: 48.5202374, lng: 17.4584122 },
    { lat: 48.5202809, lng: 17.4583856 },
    { lat: 48.5203604, lng: 17.4584634 },
    { lat: 48.5205782, lng: 17.4582514 },
    { lat: 48.5206347, lng: 17.4584082 },
    { lat: 48.5206823, lng: 17.458413 },
    { lat: 48.5206511, lng: 17.458103 },
    { lat: 48.5207174, lng: 17.4578357 },
    { lat: 48.5207941, lng: 17.4578135 },
    { lat: 48.5209013, lng: 17.4576041 },
    { lat: 48.5209608, lng: 17.4577009 },
    { lat: 48.521097, lng: 17.4576258 },
    { lat: 48.5212404, lng: 17.4572572 },
    { lat: 48.5215848, lng: 17.4575191 },
    { lat: 48.5218293, lng: 17.4575455 },
    { lat: 48.5218125, lng: 17.4573873 },
    { lat: 48.5215255, lng: 17.457123 },
    { lat: 48.5215893, lng: 17.4569887 },
    { lat: 48.52183, lng: 17.4569034 },
    { lat: 48.5218081, lng: 17.4570715 },
    { lat: 48.5219597, lng: 17.4569892 },
    { lat: 48.522, lng: 17.4571957 },
    { lat: 48.5221271, lng: 17.4571704 },
    { lat: 48.5230219, lng: 17.4560329 },
    { lat: 48.5240657, lng: 17.4557176 },
    { lat: 48.5242629, lng: 17.4551651 },
    { lat: 48.5249364, lng: 17.4546451 },
    { lat: 48.5262807, lng: 17.4530849 },
    { lat: 48.5265874, lng: 17.4529739 },
    { lat: 48.5267413, lng: 17.4530498 },
    { lat: 48.5269936, lng: 17.4529172 },
    { lat: 48.5270006, lng: 17.4530769 },
    { lat: 48.5271795, lng: 17.4528007 },
    { lat: 48.5273433, lng: 17.4527308 },
    { lat: 48.5275113, lng: 17.4527822 },
    { lat: 48.5274031, lng: 17.4522735 },
    { lat: 48.5272097, lng: 17.4519572 },
    { lat: 48.5270907, lng: 17.4518427 },
    { lat: 48.5270143, lng: 17.4518666 },
    { lat: 48.5269808, lng: 17.4517493 },
    { lat: 48.5268709, lng: 17.4516385 },
    { lat: 48.5269722, lng: 17.4512998 },
    { lat: 48.5268466, lng: 17.4507851 },
    { lat: 48.5268694, lng: 17.4502641 },
    { lat: 48.5265298, lng: 17.4496548 },
    { lat: 48.5263643, lng: 17.4488227 },
    { lat: 48.5261423, lng: 17.4484436 },
    { lat: 48.5261406, lng: 17.4483647 },
    { lat: 48.5264312, lng: 17.4481584 },
    { lat: 48.5265696, lng: 17.44815 },
    { lat: 48.527176, lng: 17.4484449 },
    { lat: 48.5279623, lng: 17.448678 },
    { lat: 48.5281477, lng: 17.448419 },
    { lat: 48.5284728, lng: 17.4477483 },
    { lat: 48.5287128, lng: 17.4470901 },
    { lat: 48.5287372, lng: 17.4468738 },
    { lat: 48.5286798, lng: 17.4464012 },
    { lat: 48.528669, lng: 17.4463397 },
  ],
  Majcichov: [
    { lat: 48.2901292, lng: 17.6477043 },
    { lat: 48.2901011, lng: 17.6474189 },
    { lat: 48.290249, lng: 17.6465198 },
    { lat: 48.2902784, lng: 17.6457636 },
    { lat: 48.2903647, lng: 17.6454139 },
    { lat: 48.2905215, lng: 17.6451414 },
    { lat: 48.291476, lng: 17.6438933 },
    { lat: 48.2929696, lng: 17.6398857 },
    { lat: 48.2930151, lng: 17.6396765 },
    { lat: 48.2918348, lng: 17.6331982 },
    { lat: 48.2930597, lng: 17.632855 },
    { lat: 48.2933745, lng: 17.6327062 },
    { lat: 48.293543, lng: 17.6327441 },
    { lat: 48.2938816, lng: 17.6326026 },
    { lat: 48.2943191, lng: 17.6322485 },
    { lat: 48.2949361, lng: 17.6320438 },
    { lat: 48.2950657, lng: 17.6319492 },
    { lat: 48.2952765, lng: 17.6321031 },
    { lat: 48.2955192, lng: 17.6321201 },
    { lat: 48.2957031, lng: 17.6320281 },
    { lat: 48.295965, lng: 17.6315429 },
    { lat: 48.2959031, lng: 17.6314381 },
    { lat: 48.2957026, lng: 17.6303708 },
    { lat: 48.297568, lng: 17.6311812 },
    { lat: 48.3023093, lng: 17.6315889 },
    { lat: 48.3069324, lng: 17.6294256 },
    { lat: 48.3102856, lng: 17.6224683 },
    { lat: 48.3104689, lng: 17.6216308 },
    { lat: 48.3105367, lng: 17.6208273 },
    { lat: 48.3136529, lng: 17.6133279 },
    { lat: 48.313898, lng: 17.6134886 },
    { lat: 48.3140223, lng: 17.6134362 },
    { lat: 48.314102, lng: 17.6134809 },
    { lat: 48.3141038, lng: 17.6136364 },
    { lat: 48.3141415, lng: 17.61368 },
    { lat: 48.314281, lng: 17.6135424 },
    { lat: 48.3143302, lng: 17.6135542 },
    { lat: 48.3143731, lng: 17.6138589 },
    { lat: 48.3145006, lng: 17.6139813 },
    { lat: 48.3149826, lng: 17.6136925 },
    { lat: 48.3154035, lng: 17.6138158 },
    { lat: 48.3154479, lng: 17.6137663 },
    { lat: 48.3156486, lng: 17.6128328 },
    { lat: 48.3156366, lng: 17.6125681 },
    { lat: 48.3156936, lng: 17.61256 },
    { lat: 48.3167995, lng: 17.6068658 },
    { lat: 48.315268, lng: 17.6056825 },
    { lat: 48.3142733, lng: 17.6047937 },
    { lat: 48.3136923, lng: 17.6027119 },
    { lat: 48.3127578, lng: 17.60065 },
    { lat: 48.3114593, lng: 17.5963665 },
    { lat: 48.3110629, lng: 17.5948865 },
    { lat: 48.3106369, lng: 17.5935999 },
    { lat: 48.3101655, lng: 17.5923591 },
    { lat: 48.309756, lng: 17.5910648 },
    { lat: 48.3094659, lng: 17.590247 },
    { lat: 48.3093314, lng: 17.5899962 },
    { lat: 48.3093136, lng: 17.5899643 },
    { lat: 48.3084744, lng: 17.5899448 },
    { lat: 48.3073891, lng: 17.5901738 },
    { lat: 48.3029084, lng: 17.5917081 },
    { lat: 48.3007183, lng: 17.5922188 },
    { lat: 48.2975226, lng: 17.5935027 },
    { lat: 48.2942502, lng: 17.5944127 },
    { lat: 48.2908953, lng: 17.5949669 },
    { lat: 48.2885443, lng: 17.5945212 },
    { lat: 48.288042, lng: 17.5943466 },
    { lat: 48.28722, lng: 17.5938838 },
    { lat: 48.2861333, lng: 17.5934353 },
    { lat: 48.2849321, lng: 17.5927237 },
    { lat: 48.2844943, lng: 17.5925339 },
    { lat: 48.2838487, lng: 17.5923609 },
    { lat: 48.2828022, lng: 17.5922425 },
    { lat: 48.2816271, lng: 17.5920002 },
    { lat: 48.2799577, lng: 17.5917616 },
    { lat: 48.2794803, lng: 17.5903569 },
    { lat: 48.2781399, lng: 17.587116 },
    { lat: 48.2772553, lng: 17.5851188 },
    { lat: 48.2760959, lng: 17.582917 },
    { lat: 48.2760792, lng: 17.582885 },
    { lat: 48.2723465, lng: 17.5873452 },
    { lat: 48.2705163, lng: 17.5892716 },
    { lat: 48.2688375, lng: 17.5908442 },
    { lat: 48.2672839, lng: 17.5924844 },
    { lat: 48.2664205, lng: 17.593545 },
    { lat: 48.2657444, lng: 17.5944928 },
    { lat: 48.2650093, lng: 17.5960628 },
    { lat: 48.26493, lng: 17.596234 },
    { lat: 48.265753, lng: 17.59744 },
    { lat: 48.266392, lng: 17.598596 },
    { lat: 48.267015, lng: 17.599818 },
    { lat: 48.267875, lng: 17.601696 },
    { lat: 48.270416, lng: 17.606821 },
    { lat: 48.269403, lng: 17.609321 },
    { lat: 48.266677, lng: 17.61681 },
    { lat: 48.267988, lng: 17.618436 },
    { lat: 48.268442, lng: 17.620784 },
    { lat: 48.268527, lng: 17.622695 },
    { lat: 48.268425, lng: 17.624535 },
    { lat: 48.268136, lng: 17.625599 },
    { lat: 48.268549, lng: 17.630053 },
    { lat: 48.268588, lng: 17.633024 },
    { lat: 48.268957, lng: 17.633638 },
    { lat: 48.268776, lng: 17.634098 },
    { lat: 48.269106, lng: 17.635248 },
    { lat: 48.269081, lng: 17.637106 },
    { lat: 48.269327, lng: 17.638594 },
    { lat: 48.2693, lng: 17.640529 },
    { lat: 48.269344, lng: 17.641636 },
    { lat: 48.269302, lng: 17.642765 },
    { lat: 48.26936, lng: 17.644431 },
    { lat: 48.269388, lng: 17.647517 },
    { lat: 48.269321, lng: 17.652683 },
    { lat: 48.268965, lng: 17.652451 },
    { lat: 48.268162, lng: 17.652285 },
    { lat: 48.267726, lng: 17.652454 },
    { lat: 48.26735, lng: 17.652782 },
    { lat: 48.266925, lng: 17.653025 },
    { lat: 48.266344, lng: 17.653118 },
    { lat: 48.266087, lng: 17.653022 },
    { lat: 48.265542, lng: 17.652339 },
    { lat: 48.265279, lng: 17.652161 },
    { lat: 48.26512, lng: 17.651941 },
    { lat: 48.264975, lng: 17.651909 },
    { lat: 48.264784, lng: 17.652039 },
    { lat: 48.26474, lng: 17.652208 },
    { lat: 48.264729, lng: 17.652485 },
    { lat: 48.264944, lng: 17.653077 },
    { lat: 48.264971, lng: 17.653504 },
    { lat: 48.264872, lng: 17.65416 },
    { lat: 48.264755, lng: 17.654501 },
    { lat: 48.265359, lng: 17.654396 },
    { lat: 48.265738, lng: 17.65463 },
    { lat: 48.2658, lng: 17.655125 },
    { lat: 48.265795, lng: 17.655479 },
    { lat: 48.265953, lng: 17.656308 },
    { lat: 48.267461, lng: 17.655993 },
    { lat: 48.267876, lng: 17.655784 },
    { lat: 48.269621, lng: 17.654485 },
    { lat: 48.270143, lng: 17.654225 },
    { lat: 48.270773, lng: 17.654101 },
    { lat: 48.271456, lng: 17.654592 },
    { lat: 48.271859, lng: 17.655101 },
    { lat: 48.272104, lng: 17.65556 },
    { lat: 48.27267, lng: 17.657229 },
    { lat: 48.272719, lng: 17.657185 },
    { lat: 48.273068, lng: 17.657514 },
    { lat: 48.273323, lng: 17.657465 },
    { lat: 48.273655, lng: 17.657263 },
    { lat: 48.274031, lng: 17.656796 },
    { lat: 48.273978, lng: 17.656272 },
    { lat: 48.274498, lng: 17.655876 },
    { lat: 48.27581, lng: 17.655763 },
    { lat: 48.276073, lng: 17.655829 },
    { lat: 48.276294, lng: 17.655954 },
    { lat: 48.276483, lng: 17.65616 },
    { lat: 48.276919, lng: 17.657186 },
    { lat: 48.278799, lng: 17.656017 },
    { lat: 48.279267, lng: 17.655383 },
    { lat: 48.2793124, lng: 17.6553269 },
    { lat: 48.2793998, lng: 17.6536968 },
    { lat: 48.278939, lng: 17.6535526 },
    { lat: 48.2788011, lng: 17.6534437 },
    { lat: 48.2783707, lng: 17.6526161 },
    { lat: 48.2783323, lng: 17.652434 },
    { lat: 48.2783477, lng: 17.6523549 },
    { lat: 48.2785887, lng: 17.6520746 },
    { lat: 48.2787652, lng: 17.6516675 },
    { lat: 48.2788653, lng: 17.6512653 },
    { lat: 48.278813, lng: 17.6509908 },
    { lat: 48.2787114, lng: 17.6508648 },
    { lat: 48.2782137, lng: 17.6505281 },
    { lat: 48.2781999, lng: 17.6503811 },
    { lat: 48.2782147, lng: 17.6500781 },
    { lat: 48.2786191, lng: 17.649506 },
    { lat: 48.2787582, lng: 17.6493846 },
    { lat: 48.2787033, lng: 17.6490847 },
    { lat: 48.2801146, lng: 17.6471262 },
    { lat: 48.2803979, lng: 17.6468713 },
    { lat: 48.280978, lng: 17.6486275 },
    { lat: 48.2817006, lng: 17.647908 },
    { lat: 48.2830907, lng: 17.6470839 },
    { lat: 48.2830025, lng: 17.6457531 },
    { lat: 48.2830401, lng: 17.6453841 },
    { lat: 48.2831727, lng: 17.6449417 },
    { lat: 48.2833912, lng: 17.6444843 },
    { lat: 48.2841943, lng: 17.6435006 },
    { lat: 48.2843804, lng: 17.6438735 },
    { lat: 48.2847073, lng: 17.6441885 },
    { lat: 48.2848093, lng: 17.6442302 },
    { lat: 48.2849355, lng: 17.6441716 },
    { lat: 48.2850007, lng: 17.6440422 },
    { lat: 48.2852678, lng: 17.6429582 },
    { lat: 48.2855745, lng: 17.6427522 },
    { lat: 48.286495, lng: 17.6415918 },
    { lat: 48.2865506, lng: 17.6412185 },
    { lat: 48.2867021, lng: 17.6407911 },
    { lat: 48.2869394, lng: 17.6408473 },
    { lat: 48.2877732, lng: 17.6413587 },
    { lat: 48.2880694, lng: 17.6417934 },
    { lat: 48.2883115, lng: 17.6425534 },
    { lat: 48.2882495, lng: 17.643328 },
    { lat: 48.2880879, lng: 17.6437202 },
    { lat: 48.2879903, lng: 17.6437475 },
    { lat: 48.2876451, lng: 17.6435045 },
    { lat: 48.287463, lng: 17.6435167 },
    { lat: 48.2872882, lng: 17.6433936 },
    { lat: 48.2871234, lng: 17.643486 },
    { lat: 48.2870641, lng: 17.6436525 },
    { lat: 48.2871004, lng: 17.6438388 },
    { lat: 48.2872728, lng: 17.6440293 },
    { lat: 48.2873919, lng: 17.6440192 },
    { lat: 48.2875933, lng: 17.6442031 },
    { lat: 48.2878574, lng: 17.6442899 },
    { lat: 48.2880116, lng: 17.6444103 },
    { lat: 48.2884373, lng: 17.6450196 },
    { lat: 48.2887254, lng: 17.6459922 },
    { lat: 48.288923, lng: 17.6464061 },
    { lat: 48.2889469, lng: 17.6469483 },
    { lat: 48.2890578, lng: 17.6475985 },
    { lat: 48.2892001, lng: 17.6479752 },
    { lat: 48.2894076, lng: 17.648115 },
    { lat: 48.2898119, lng: 17.6478987 },
    { lat: 48.2901292, lng: 17.6477043 },
  ],
  HornéDubové: [
    { lat: 48.5225358, lng: 17.575883 },
    { lat: 48.5229439, lng: 17.5754056 },
    { lat: 48.5235947, lng: 17.5747003 },
    { lat: 48.5240048, lng: 17.5741128 },
    { lat: 48.5250815, lng: 17.5720857 },
    { lat: 48.5255264, lng: 17.5711398 },
    { lat: 48.5262281, lng: 17.5699519 },
    { lat: 48.5270207, lng: 17.5678065 },
    { lat: 48.5278892, lng: 17.5659019 },
    { lat: 48.5290722, lng: 17.5647698 },
    { lat: 48.5303786, lng: 17.5636955 },
    { lat: 48.5316817, lng: 17.5622154 },
    { lat: 48.5329984, lng: 17.5610917 },
    { lat: 48.5352502, lng: 17.5597908 },
    { lat: 48.5384145, lng: 17.557155 },
    { lat: 48.5282958, lng: 17.5447407 },
    { lat: 48.5281816, lng: 17.5449705 },
    { lat: 48.5278517, lng: 17.5452346 },
    { lat: 48.5277557, lng: 17.5452171 },
    { lat: 48.5276578, lng: 17.5454521 },
    { lat: 48.5272478, lng: 17.545774 },
    { lat: 48.5271383, lng: 17.5459512 },
    { lat: 48.5270382, lng: 17.5464576 },
    { lat: 48.5269617, lng: 17.5465506 },
    { lat: 48.5268527, lng: 17.5468602 },
    { lat: 48.5265243, lng: 17.5471675 },
    { lat: 48.5264693, lng: 17.5473489 },
    { lat: 48.5263669, lng: 17.5473569 },
    { lat: 48.5262768, lng: 17.5475373 },
    { lat: 48.526151, lng: 17.5476014 },
    { lat: 48.5244512, lng: 17.5456229 },
    { lat: 48.5233112, lng: 17.5444764 },
    { lat: 48.5226348, lng: 17.5433386 },
    { lat: 48.5222222, lng: 17.5431216 },
    { lat: 48.5219411, lng: 17.5430841 },
    { lat: 48.5213726, lng: 17.5427213 },
    { lat: 48.5195634, lng: 17.5417781 },
    { lat: 48.5192606, lng: 17.5414929 },
    { lat: 48.5189725, lng: 17.5410824 },
    { lat: 48.5185011, lng: 17.5398829 },
    { lat: 48.518411, lng: 17.5396568 },
    { lat: 48.5183795, lng: 17.5396232 },
    { lat: 48.5177793, lng: 17.5403252 },
    { lat: 48.5167072, lng: 17.5414374 },
    { lat: 48.5160176, lng: 17.5420475 },
    { lat: 48.5155914, lng: 17.5420953 },
    { lat: 48.515327, lng: 17.5422555 },
    { lat: 48.5146712, lng: 17.5433605 },
    { lat: 48.5143497, lng: 17.5437757 },
    { lat: 48.5137886, lng: 17.5443306 },
    { lat: 48.5131788, lng: 17.5455189 },
    { lat: 48.5128062, lng: 17.5459312 },
    { lat: 48.5125453, lng: 17.5464463 },
    { lat: 48.5121551, lng: 17.5469495 },
    { lat: 48.5120662, lng: 17.5469929 },
    { lat: 48.5119821, lng: 17.5471809 },
    { lat: 48.5113073, lng: 17.5476673 },
    { lat: 48.5109969, lng: 17.5479737 },
    { lat: 48.5105501, lng: 17.5481373 },
    { lat: 48.5103771, lng: 17.5483125 },
    { lat: 48.5101663, lng: 17.5482128 },
    { lat: 48.5099625, lng: 17.5482753 },
    { lat: 48.5098117, lng: 17.54823 },
    { lat: 48.5094494, lng: 17.5485165 },
    { lat: 48.5092361, lng: 17.5488057 },
    { lat: 48.5090978, lng: 17.548868 },
    { lat: 48.5089509, lng: 17.5490625 },
    { lat: 48.5087206, lng: 17.5491331 },
    { lat: 48.5085295, lng: 17.5492717 },
    { lat: 48.5081535, lng: 17.5496121 },
    { lat: 48.5072928, lng: 17.5505499 },
    { lat: 48.507038, lng: 17.5512529 },
    { lat: 48.5066238, lng: 17.5517177 },
    { lat: 48.5062717, lng: 17.5522398 },
    { lat: 48.5060302, lng: 17.5524351 },
    { lat: 48.5059712, lng: 17.5525754 },
    { lat: 48.5059116, lng: 17.5525943 },
    { lat: 48.5056831, lng: 17.5524476 },
    { lat: 48.5053289, lng: 17.5530594 },
    { lat: 48.5051587, lng: 17.5534731 },
    { lat: 48.5048873, lng: 17.5537856 },
    { lat: 48.5046952, lng: 17.5541779 },
    { lat: 48.5042213, lng: 17.5545942 },
    { lat: 48.5040288, lng: 17.5550289 },
    { lat: 48.5033771, lng: 17.5555958 },
    { lat: 48.5032845, lng: 17.5557587 },
    { lat: 48.503224, lng: 17.5560843 },
    { lat: 48.5029512, lng: 17.5562528 },
    { lat: 48.5027472, lng: 17.5565438 },
    { lat: 48.5024738, lng: 17.5568113 },
    { lat: 48.5023621, lng: 17.5573441 },
    { lat: 48.5020841, lng: 17.5576432 },
    { lat: 48.5016518, lng: 17.557951 },
    { lat: 48.5012792, lng: 17.5586832 },
    { lat: 48.5010152, lng: 17.5588308 },
    { lat: 48.5008681, lng: 17.5591076 },
    { lat: 48.5006246, lng: 17.5593662 },
    { lat: 48.5003371, lng: 17.5598888 },
    { lat: 48.500135, lng: 17.5599435 },
    { lat: 48.5000138, lng: 17.5600627 },
    { lat: 48.4998652, lng: 17.5603732 },
    { lat: 48.4998635, lng: 17.5604354 },
    { lat: 48.4999498, lng: 17.5605131 },
    { lat: 48.499746, lng: 17.5608066 },
    { lat: 48.4994805, lng: 17.56086 },
    { lat: 48.4992309, lng: 17.5612526 },
    { lat: 48.4990332, lng: 17.5613023 },
    { lat: 48.5004107, lng: 17.5632931 },
    { lat: 48.5002326, lng: 17.5634443 },
    { lat: 48.5001612, lng: 17.5635788 },
    { lat: 48.5000966, lng: 17.5638819 },
    { lat: 48.499979, lng: 17.5641426 },
    { lat: 48.500676, lng: 17.5652177 },
    { lat: 48.5026679, lng: 17.5681029 },
    { lat: 48.5030396, lng: 17.5685542 },
    { lat: 48.5032645, lng: 17.5683766 },
    { lat: 48.5035325, lng: 17.5686519 },
    { lat: 48.5040724, lng: 17.569651 },
    { lat: 48.5050782, lng: 17.5709835 },
    { lat: 48.5064127, lng: 17.5724958 },
    { lat: 48.5064924, lng: 17.572989 },
    { lat: 48.5063932, lng: 17.5732055 },
    { lat: 48.5071253, lng: 17.574139 },
    { lat: 48.5078171, lng: 17.5749345 },
    { lat: 48.5089935, lng: 17.5760355 },
    { lat: 48.5098224, lng: 17.5770386 },
    { lat: 48.5102612, lng: 17.5772984 },
    { lat: 48.5119613, lng: 17.5791301 },
    { lat: 48.5132449, lng: 17.5808058 },
    { lat: 48.5148976, lng: 17.5831738 },
    { lat: 48.5162107, lng: 17.5848336 },
    { lat: 48.5169711, lng: 17.5840149 },
    { lat: 48.5178027, lng: 17.5828099 },
    { lat: 48.5189051, lng: 17.5805427 },
    { lat: 48.5205639, lng: 17.5784692 },
    { lat: 48.5225252, lng: 17.5758955 },
    { lat: 48.5225358, lng: 17.575883 },
  ],
  DolnéLovčice: [
    { lat: 48.367531, lng: 17.702225 },
    { lat: 48.3677843, lng: 17.7017007 },
    { lat: 48.3679807, lng: 17.7004474 },
    { lat: 48.3680423, lng: 17.6983576 },
    { lat: 48.3682492, lng: 17.6971139 },
    { lat: 48.3692661, lng: 17.6942276 },
    { lat: 48.3693294, lng: 17.6937933 },
    { lat: 48.3694111, lng: 17.6926064 },
    { lat: 48.3693507, lng: 17.6909875 },
    { lat: 48.3693472, lng: 17.6886146 },
    { lat: 48.3696078, lng: 17.684289 },
    { lat: 48.3697551, lng: 17.682904 },
    { lat: 48.3698192, lng: 17.6806056 },
    { lat: 48.3699588, lng: 17.678667 },
    { lat: 48.3701149, lng: 17.677373 },
    { lat: 48.3701118, lng: 17.6771948 },
    { lat: 48.370015, lng: 17.6768754 },
    { lat: 48.3696085, lng: 17.6767125 },
    { lat: 48.3696744, lng: 17.6758517 },
    { lat: 48.3698743, lng: 17.6744109 },
    { lat: 48.3697981, lng: 17.6740775 },
    { lat: 48.3702157, lng: 17.6727911 },
    { lat: 48.3704153, lng: 17.6724462 },
    { lat: 48.3706286, lng: 17.6722243 },
    { lat: 48.3716273, lng: 17.670107 },
    { lat: 48.371507, lng: 17.6699155 },
    { lat: 48.3715966, lng: 17.6696156 },
    { lat: 48.3716426, lng: 17.6690505 },
    { lat: 48.371733, lng: 17.6688541 },
    { lat: 48.3727304, lng: 17.6682802 },
    { lat: 48.3732766, lng: 17.667739 },
    { lat: 48.3739083, lng: 17.6666271 },
    { lat: 48.3741129, lng: 17.6664321 },
    { lat: 48.3742374, lng: 17.6660934 },
    { lat: 48.3744526, lng: 17.6659256 },
    { lat: 48.3749141, lng: 17.6650547 },
    { lat: 48.3778307, lng: 17.6612332 },
    { lat: 48.3804228, lng: 17.6591193 },
    { lat: 48.3804974, lng: 17.6588108 },
    { lat: 48.380527, lng: 17.6588158 },
    { lat: 48.3805673, lng: 17.6587753 },
    { lat: 48.3801451, lng: 17.6563281 },
    { lat: 48.3800797, lng: 17.6553371 },
    { lat: 48.3799555, lng: 17.654564 },
    { lat: 48.3781199, lng: 17.6509708 },
    { lat: 48.3799166, lng: 17.6471031 },
    { lat: 48.3856815, lng: 17.6367079 },
    { lat: 48.3838239, lng: 17.6341535 },
    { lat: 48.3837893, lng: 17.6341069 },
    { lat: 48.3827118, lng: 17.6358808 },
    { lat: 48.3806592, lng: 17.6383277 },
    { lat: 48.3798017, lng: 17.6395222 },
    { lat: 48.3783759, lng: 17.6411274 },
    { lat: 48.3776435, lng: 17.6420857 },
    { lat: 48.3766134, lng: 17.6435908 },
    { lat: 48.3747238, lng: 17.6455274 },
    { lat: 48.3742503, lng: 17.645897 },
    { lat: 48.3735418, lng: 17.6467168 },
    { lat: 48.3725926, lng: 17.6476604 },
    { lat: 48.3712806, lng: 17.6491163 },
    { lat: 48.3700586, lng: 17.6506786 },
    { lat: 48.3696499, lng: 17.6513643 },
    { lat: 48.3688337, lng: 17.6524445 },
    { lat: 48.3685421, lng: 17.6529097 },
    { lat: 48.3680848, lng: 17.6538283 },
    { lat: 48.3680395, lng: 17.6540219 },
    { lat: 48.3670439, lng: 17.6557935 },
    { lat: 48.3660377, lng: 17.6570694 },
    { lat: 48.3643385, lng: 17.6597172 },
    { lat: 48.3636375, lng: 17.6606921 },
    { lat: 48.3630749, lng: 17.6613393 },
    { lat: 48.3625295, lng: 17.6621328 },
    { lat: 48.3613821, lng: 17.6643096 },
    { lat: 48.3594676, lng: 17.6690049 },
    { lat: 48.359022, lng: 17.6703646 },
    { lat: 48.3586367, lng: 17.6717311 },
    { lat: 48.3584185, lng: 17.6737298 },
    { lat: 48.3585576, lng: 17.6754654 },
    { lat: 48.3586356, lng: 17.6776851 },
    { lat: 48.3586947, lng: 17.6778731 },
    { lat: 48.3587979, lng: 17.6791462 },
    { lat: 48.3589443, lng: 17.6819186 },
    { lat: 48.3589523, lng: 17.6821032 },
    { lat: 48.3588432, lng: 17.6821118 },
    { lat: 48.358893, lng: 17.6823089 },
    { lat: 48.3590425, lng: 17.6841954 },
    { lat: 48.359026, lng: 17.6853246 },
    { lat: 48.3586936, lng: 17.6850636 },
    { lat: 48.3586962, lng: 17.6869686 },
    { lat: 48.3584043, lng: 17.6883495 },
    { lat: 48.3583204, lng: 17.6892872 },
    { lat: 48.358303, lng: 17.6901647 },
    { lat: 48.358386, lng: 17.6905734 },
    { lat: 48.3581987, lng: 17.6909157 },
    { lat: 48.3582487, lng: 17.6915541 },
    { lat: 48.3580648, lng: 17.6922456 },
    { lat: 48.3581045, lng: 17.6929543 },
    { lat: 48.3580207, lng: 17.6934734 },
    { lat: 48.3581417, lng: 17.6939673 },
    { lat: 48.3581187, lng: 17.6948084 },
    { lat: 48.3570965, lng: 17.6974668 },
    { lat: 48.3569895, lng: 17.698228 },
    { lat: 48.3566017, lng: 17.6994345 },
    { lat: 48.3566106, lng: 17.6999043 },
    { lat: 48.3566248, lng: 17.7000411 },
    { lat: 48.3567058, lng: 17.7003457 },
    { lat: 48.3567664, lng: 17.7005736 },
    { lat: 48.3567472, lng: 17.701352 },
    { lat: 48.356565, lng: 17.701827 },
    { lat: 48.356632, lng: 17.701854 },
    { lat: 48.356736, lng: 17.701893 },
    { lat: 48.35696, lng: 17.702163 },
    { lat: 48.357393, lng: 17.702387 },
    { lat: 48.357687, lng: 17.702472 },
    { lat: 48.357872, lng: 17.702436 },
    { lat: 48.357997, lng: 17.702362 },
    { lat: 48.358247, lng: 17.702156 },
    { lat: 48.358524, lng: 17.7018 },
    { lat: 48.358978, lng: 17.701339 },
    { lat: 48.3593516, lng: 17.7009402 },
    { lat: 48.360211, lng: 17.700865 },
    { lat: 48.360622, lng: 17.701019 },
    { lat: 48.360968, lng: 17.701496 },
    { lat: 48.361193, lng: 17.70183 },
    { lat: 48.361351, lng: 17.70198 },
    { lat: 48.361543, lng: 17.702089 },
    { lat: 48.361905, lng: 17.702238 },
    { lat: 48.362149, lng: 17.70228 },
    { lat: 48.362918, lng: 17.702252 },
    { lat: 48.363076, lng: 17.702292 },
    { lat: 48.36355, lng: 17.702269 },
    { lat: 48.363575, lng: 17.702228 },
    { lat: 48.363996, lng: 17.701552 },
    { lat: 48.364263, lng: 17.701444 },
    { lat: 48.364534, lng: 17.701513 },
    { lat: 48.364756, lng: 17.701748 },
    { lat: 48.365109, lng: 17.702113 },
    { lat: 48.36536, lng: 17.70231 },
    { lat: 48.36564, lng: 17.702533 },
    { lat: 48.365908, lng: 17.702591 },
    { lat: 48.36635, lng: 17.702716 },
    { lat: 48.366669, lng: 17.702813 },
    { lat: 48.366606, lng: 17.701893 },
    { lat: 48.36707, lng: 17.701865 },
    { lat: 48.367452, lng: 17.701814 },
    { lat: 48.367531, lng: 17.702225 },
  ],
  DobráVoda: [
    { lat: 48.63665, lng: 17.578487 },
    { lat: 48.636832, lng: 17.578388 },
    { lat: 48.637177, lng: 17.578273 },
    { lat: 48.637477, lng: 17.578081 },
    { lat: 48.637713, lng: 17.577928 },
    { lat: 48.637734, lng: 17.577876 },
    { lat: 48.637871, lng: 17.577688 },
    { lat: 48.637991, lng: 17.577553 },
    { lat: 48.63809, lng: 17.577385 },
    { lat: 48.638201, lng: 17.577131 },
    { lat: 48.638355, lng: 17.576915 },
    { lat: 48.638684, lng: 17.576704 },
    { lat: 48.638825, lng: 17.5765 },
    { lat: 48.639184, lng: 17.5757964 },
    { lat: 48.639198, lng: 17.575769 },
    { lat: 48.639446, lng: 17.575408 },
    { lat: 48.63968, lng: 17.5752 },
    { lat: 48.6399564, lng: 17.5748543 },
    { lat: 48.640079, lng: 17.574701 },
    { lat: 48.640313, lng: 17.574468 },
    { lat: 48.640751, lng: 17.574094 },
    { lat: 48.640931, lng: 17.573948 },
    { lat: 48.641045, lng: 17.57381 },
    { lat: 48.641259, lng: 17.573394 },
    { lat: 48.641517, lng: 17.572927 },
    { lat: 48.641678, lng: 17.572542 },
    { lat: 48.641808, lng: 17.572363 },
    { lat: 48.642253, lng: 17.571987 },
    { lat: 48.642461, lng: 17.571149 },
    { lat: 48.642559, lng: 17.570719 },
    { lat: 48.642797, lng: 17.569775 },
    { lat: 48.643161, lng: 17.568795 },
    { lat: 48.64319, lng: 17.568452 },
    { lat: 48.643087, lng: 17.566789 },
    { lat: 48.643168, lng: 17.566287 },
    { lat: 48.643189, lng: 17.5659 },
    { lat: 48.643274, lng: 17.565597 },
    { lat: 48.6434, lng: 17.565384 },
    { lat: 48.643519, lng: 17.565213 },
    { lat: 48.643941, lng: 17.564702 },
    { lat: 48.644504, lng: 17.564047 },
    { lat: 48.644654, lng: 17.563606 },
    { lat: 48.644835, lng: 17.562783 },
    { lat: 48.644864, lng: 17.562033 },
    { lat: 48.644862, lng: 17.561698 },
    { lat: 48.644838, lng: 17.561278 },
    { lat: 48.644797, lng: 17.561115 },
    { lat: 48.644573, lng: 17.560908 },
    { lat: 48.644208, lng: 17.560894 },
    { lat: 48.643901, lng: 17.560853 },
    { lat: 48.643701, lng: 17.560795 },
    { lat: 48.642975, lng: 17.560191 },
    { lat: 48.642458, lng: 17.560111 },
    { lat: 48.642211, lng: 17.559965 },
    { lat: 48.641918, lng: 17.559679 },
    { lat: 48.641658, lng: 17.559356 },
    { lat: 48.641549, lng: 17.559136 },
    { lat: 48.64124, lng: 17.557949 },
    { lat: 48.641038, lng: 17.556944 },
    { lat: 48.640888, lng: 17.556534 },
    { lat: 48.640904, lng: 17.556524 },
    { lat: 48.64087, lng: 17.556392 },
    { lat: 48.64079, lng: 17.556094 },
    { lat: 48.640649, lng: 17.55593 },
    { lat: 48.639983, lng: 17.555399 },
    { lat: 48.639657, lng: 17.555286 },
    { lat: 48.639471, lng: 17.555175 },
    { lat: 48.638641, lng: 17.554 },
    { lat: 48.638402, lng: 17.553387 },
    { lat: 48.638446, lng: 17.552604 },
    { lat: 48.638626, lng: 17.551907 },
    { lat: 48.638688, lng: 17.551301 },
    { lat: 48.638686, lng: 17.550392 },
    { lat: 48.638778, lng: 17.549654 },
    { lat: 48.638943, lng: 17.548719 },
    { lat: 48.639175, lng: 17.547603 },
    { lat: 48.639323, lng: 17.547122 },
    { lat: 48.639345, lng: 17.54684 },
    { lat: 48.63924, lng: 17.546539 },
    { lat: 48.638964, lng: 17.546162 },
    { lat: 48.638184, lng: 17.545565 },
    { lat: 48.638065, lng: 17.545477 },
    { lat: 48.637315, lng: 17.545556 },
    { lat: 48.636847, lng: 17.545438 },
    { lat: 48.636495, lng: 17.545349 },
    { lat: 48.636426, lng: 17.545332 },
    { lat: 48.635112, lng: 17.54515 },
    { lat: 48.63499, lng: 17.545078 },
    { lat: 48.634586, lng: 17.544542 },
    { lat: 48.634223, lng: 17.544279 },
    { lat: 48.633789, lng: 17.544064 },
    { lat: 48.632727, lng: 17.543449 },
    { lat: 48.632499, lng: 17.5433 },
    { lat: 48.632071, lng: 17.543278 },
    { lat: 48.631797, lng: 17.543403 },
    { lat: 48.631538, lng: 17.543427 },
    { lat: 48.631223, lng: 17.543366 },
    { lat: 48.631042, lng: 17.543419 },
    { lat: 48.630862, lng: 17.543421 },
    { lat: 48.63067, lng: 17.543541 },
    { lat: 48.630554, lng: 17.543551 },
    { lat: 48.630318, lng: 17.543519 },
    { lat: 48.630081, lng: 17.5434209 },
    { lat: 48.629958, lng: 17.54337 },
    { lat: 48.629795, lng: 17.543249 },
    { lat: 48.629609, lng: 17.542478 },
    { lat: 48.629491, lng: 17.542263 },
    { lat: 48.629273, lng: 17.541945 },
    { lat: 48.629056, lng: 17.541756 },
    { lat: 48.628839, lng: 17.541349 },
    { lat: 48.628922, lng: 17.540943 },
    { lat: 48.629045, lng: 17.540523 },
    { lat: 48.629209, lng: 17.540107 },
    { lat: 48.629371, lng: 17.539861 },
    { lat: 48.629738, lng: 17.539419 },
    { lat: 48.629954, lng: 17.539103 },
    { lat: 48.630126, lng: 17.538742 },
    { lat: 48.630198, lng: 17.538499 },
    { lat: 48.630266, lng: 17.538328 },
    { lat: 48.630076, lng: 17.537974 },
    { lat: 48.629967, lng: 17.537868 },
    { lat: 48.629818, lng: 17.537778 },
    { lat: 48.629586, lng: 17.537652 },
    { lat: 48.629448, lng: 17.537528 },
    { lat: 48.629369, lng: 17.537356 },
    { lat: 48.629322, lng: 17.537122 },
    { lat: 48.629341, lng: 17.536816 },
    { lat: 48.629415, lng: 17.536305 },
    { lat: 48.629443, lng: 17.53599 },
    { lat: 48.629447, lng: 17.535731 },
    { lat: 48.629422, lng: 17.535452 },
    { lat: 48.629406, lng: 17.5352 },
    { lat: 48.629296, lng: 17.534691 },
    { lat: 48.629199, lng: 17.534527 },
    { lat: 48.629063, lng: 17.534378 },
    { lat: 48.6289, lng: 17.534253 },
    { lat: 48.628718, lng: 17.534158 },
    { lat: 48.628332, lng: 17.534123 },
    { lat: 48.627912, lng: 17.53428 },
    { lat: 48.627443, lng: 17.534335 },
    { lat: 48.627122, lng: 17.534288 },
    { lat: 48.626804, lng: 17.534122 },
    { lat: 48.626712, lng: 17.533952 },
    { lat: 48.626639, lng: 17.533615 },
    { lat: 48.626618, lng: 17.533121 },
    { lat: 48.626607, lng: 17.532916 },
    { lat: 48.626604, lng: 17.532788 },
    { lat: 48.626583, lng: 17.532614 },
    { lat: 48.626479, lng: 17.532377 },
    { lat: 48.626354, lng: 17.5322 },
    { lat: 48.626197, lng: 17.532068 },
    { lat: 48.625775, lng: 17.531861 },
    { lat: 48.625624, lng: 17.531743 },
    { lat: 48.625457, lng: 17.531506 },
    { lat: 48.625441, lng: 17.531289 },
    { lat: 48.625472, lng: 17.53116 },
    { lat: 48.626504, lng: 17.529087 },
    { lat: 48.626652, lng: 17.528428 },
    { lat: 48.626607, lng: 17.527628 },
    { lat: 48.6264788, lng: 17.5273499 },
    { lat: 48.626289, lng: 17.527127 },
    { lat: 48.626094, lng: 17.526922 },
    { lat: 48.625518, lng: 17.52648 },
    { lat: 48.625415, lng: 17.52636 },
    { lat: 48.625326, lng: 17.526065 },
    { lat: 48.625288, lng: 17.52564 },
    { lat: 48.625349, lng: 17.525155 },
    { lat: 48.625289, lng: 17.524795 },
    { lat: 48.625307, lng: 17.524385 },
    { lat: 48.625269, lng: 17.524215 },
    { lat: 48.624801, lng: 17.523444 },
    { lat: 48.624635, lng: 17.523107 },
    { lat: 48.624369, lng: 17.522857 },
    { lat: 48.624313, lng: 17.522827 },
    { lat: 48.623995, lng: 17.522796 },
    { lat: 48.623839, lng: 17.522761 },
    { lat: 48.623687, lng: 17.522785 },
    { lat: 48.62317, lng: 17.523 },
    { lat: 48.623021, lng: 17.522995 },
    { lat: 48.622952, lng: 17.52297 },
    { lat: 48.622817, lng: 17.522886 },
    { lat: 48.622698, lng: 17.522747 },
    { lat: 48.622673, lng: 17.522634 },
    { lat: 48.622649, lng: 17.522469 },
    { lat: 48.622655, lng: 17.52196 },
    { lat: 48.622599, lng: 17.521619 },
    { lat: 48.622547, lng: 17.521386 },
    { lat: 48.622461, lng: 17.521195 },
    { lat: 48.62231, lng: 17.521025 },
    { lat: 48.62145, lng: 17.520254 },
    { lat: 48.621177, lng: 17.520061 },
    { lat: 48.620912, lng: 17.519902 },
    { lat: 48.620884, lng: 17.51984 },
    { lat: 48.620863, lng: 17.519792 },
    { lat: 48.620755, lng: 17.519698 },
    { lat: 48.620255, lng: 17.519306 },
    { lat: 48.619595, lng: 17.518698 },
    { lat: 48.618651, lng: 17.517993 },
    { lat: 48.618212, lng: 17.517605 },
    { lat: 48.617114, lng: 17.516599 },
    { lat: 48.616475, lng: 17.516026 },
    { lat: 48.615452, lng: 17.514853 },
    { lat: 48.614576, lng: 17.513759 },
    { lat: 48.614029, lng: 17.513055 },
    { lat: 48.613778, lng: 17.51274 },
    { lat: 48.613612, lng: 17.51255 },
    { lat: 48.613009, lng: 17.511916 },
    { lat: 48.612428, lng: 17.511271 },
    { lat: 48.611676, lng: 17.510567 },
    { lat: 48.610668, lng: 17.509654 },
    { lat: 48.609873, lng: 17.508834 },
    { lat: 48.609069, lng: 17.507928 },
    { lat: 48.60851, lng: 17.507271 },
    { lat: 48.607841, lng: 17.50649 },
    { lat: 48.606689, lng: 17.505562 },
    { lat: 48.606645, lng: 17.505528 },
    { lat: 48.606032, lng: 17.505017 },
    { lat: 48.605402, lng: 17.50497 },
    { lat: 48.602945, lng: 17.503399 },
    { lat: 48.602848, lng: 17.503333 },
    { lat: 48.600273, lng: 17.50036 },
    { lat: 48.599075, lng: 17.499102 },
    { lat: 48.598698, lng: 17.498695 },
    { lat: 48.597519, lng: 17.497356 },
    { lat: 48.597173, lng: 17.496976 },
    { lat: 48.596147, lng: 17.496679 },
    { lat: 48.5959626, lng: 17.4967308 },
    { lat: 48.595082, lng: 17.497153 },
    { lat: 48.59501, lng: 17.497254 },
    { lat: 48.594571, lng: 17.49762 },
    { lat: 48.594242, lng: 17.497853 },
    { lat: 48.593415, lng: 17.498016 },
    { lat: 48.593053, lng: 17.498101 },
    { lat: 48.5924692, lng: 17.4982045 },
    { lat: 48.592224, lng: 17.498248 },
    { lat: 48.5921162, lng: 17.49813 },
    { lat: 48.592056, lng: 17.498064 },
    { lat: 48.5913471, lng: 17.4974612 },
    { lat: 48.591328, lng: 17.497445 },
    { lat: 48.591033, lng: 17.497097 },
    { lat: 48.590841, lng: 17.496858 },
    { lat: 48.590502, lng: 17.496511 },
    { lat: 48.590301, lng: 17.496369 },
    { lat: 48.589527, lng: 17.495946 },
    { lat: 48.5891657, lng: 17.4958367 },
    { lat: 48.5884606, lng: 17.49606 },
    { lat: 48.5878315, lng: 17.4964029 },
    { lat: 48.5871728, lng: 17.496762 },
    { lat: 48.5871386, lng: 17.4967685 },
    { lat: 48.5863794, lng: 17.4969136 },
    { lat: 48.5845876, lng: 17.4972561 },
    { lat: 48.5845822, lng: 17.4982678 },
    { lat: 48.584531, lng: 17.4987571 },
    { lat: 48.5844523, lng: 17.4989922 },
    { lat: 48.584026, lng: 17.4996212 },
    { lat: 48.5838862, lng: 17.500352 },
    { lat: 48.5833904, lng: 17.5008629 },
    { lat: 48.583238, lng: 17.5012018 },
    { lat: 48.5829552, lng: 17.501354 },
    { lat: 48.5824212, lng: 17.5014069 },
    { lat: 48.5820299, lng: 17.5014162 },
    { lat: 48.5816095, lng: 17.5012888 },
    { lat: 48.5808848, lng: 17.5013999 },
    { lat: 48.5802789, lng: 17.5023473 },
    { lat: 48.5795293, lng: 17.5036197 },
    { lat: 48.5795044, lng: 17.5036619 },
    { lat: 48.5796044, lng: 17.5044695 },
    { lat: 48.5797352, lng: 17.5063715 },
    { lat: 48.5797454, lng: 17.5065198 },
    { lat: 48.5794268, lng: 17.50673 },
    { lat: 48.5786138, lng: 17.5070853 },
    { lat: 48.5779958, lng: 17.5075771 },
    { lat: 48.5774898, lng: 17.5086292 },
    { lat: 48.5774433, lng: 17.508726 },
    { lat: 48.5774331, lng: 17.5088262 },
    { lat: 48.5772957, lng: 17.5101814 },
    { lat: 48.5772993, lng: 17.5105764 },
    { lat: 48.5775456, lng: 17.5122099 },
    { lat: 48.5775196, lng: 17.5123427 },
    { lat: 48.5768994, lng: 17.5155085 },
    { lat: 48.5761864, lng: 17.5191476 },
    { lat: 48.5751471, lng: 17.5207972 },
    { lat: 48.5720158, lng: 17.5258591 },
    { lat: 48.5690153, lng: 17.530681 },
    { lat: 48.5688181, lng: 17.5309979 },
    { lat: 48.5683252, lng: 17.5316277 },
    { lat: 48.5675402, lng: 17.5321778 },
    { lat: 48.5665633, lng: 17.5324087 },
    { lat: 48.5661304, lng: 17.5328277 },
    { lat: 48.565884, lng: 17.5332226 },
    { lat: 48.5655816, lng: 17.533707 },
    { lat: 48.5651383, lng: 17.5344173 },
    { lat: 48.5647484, lng: 17.5346087 },
    { lat: 48.5643769, lng: 17.5350056 },
    { lat: 48.5642737, lng: 17.5351161 },
    { lat: 48.5636094, lng: 17.5358262 },
    { lat: 48.5636323, lng: 17.5358654 },
    { lat: 48.5644215, lng: 17.5404332 },
    { lat: 48.5686514, lng: 17.5461378 },
    { lat: 48.5676976, lng: 17.548564 },
    { lat: 48.567707, lng: 17.5485782 },
    { lat: 48.5678135, lng: 17.5487394 },
    { lat: 48.567825, lng: 17.5490245 },
    { lat: 48.5677165, lng: 17.5493999 },
    { lat: 48.5677505, lng: 17.5494353 },
    { lat: 48.5675626, lng: 17.5499098 },
    { lat: 48.5679673, lng: 17.551251 },
    { lat: 48.5676826, lng: 17.5521759 },
    { lat: 48.5677391, lng: 17.5522796 },
    { lat: 48.5682906, lng: 17.5535731 },
    { lat: 48.5683428, lng: 17.5539535 },
    { lat: 48.5684805, lng: 17.5543776 },
    { lat: 48.5684626, lng: 17.5544354 },
    { lat: 48.568621, lng: 17.5549938 },
    { lat: 48.5687453, lng: 17.5552412 },
    { lat: 48.5689311, lng: 17.5552334 },
    { lat: 48.5698446, lng: 17.5545743 },
    { lat: 48.5702861, lng: 17.5546634 },
    { lat: 48.5711336, lng: 17.5555612 },
    { lat: 48.5719742, lng: 17.5577242 },
    { lat: 48.5723935, lng: 17.5595859 },
    { lat: 48.5726942, lng: 17.5597341 },
    { lat: 48.5725951, lng: 17.5598429 },
    { lat: 48.5725217, lng: 17.5600788 },
    { lat: 48.5720376, lng: 17.5600446 },
    { lat: 48.5721168, lng: 17.561083 },
    { lat: 48.5722697, lng: 17.5614832 },
    { lat: 48.5722885, lng: 17.5618147 },
    { lat: 48.572393, lng: 17.5617012 },
    { lat: 48.5723974, lng: 17.561789 },
    { lat: 48.5723851, lng: 17.5619661 },
    { lat: 48.5723357, lng: 17.5621275 },
    { lat: 48.572123, lng: 17.562823 },
    { lat: 48.572185, lng: 17.5631774 },
    { lat: 48.5723531, lng: 17.5636211 },
    { lat: 48.5727389, lng: 17.5643291 },
    { lat: 48.5727584, lng: 17.5645285 },
    { lat: 48.5728686, lng: 17.564718 },
    { lat: 48.5730032, lng: 17.5648481 },
    { lat: 48.5732845, lng: 17.5649746 },
    { lat: 48.5736198, lng: 17.5652769 },
    { lat: 48.573807, lng: 17.5656473 },
    { lat: 48.5740928, lng: 17.565808 },
    { lat: 48.5743112, lng: 17.5661033 },
    { lat: 48.5745653, lng: 17.5668353 },
    { lat: 48.5745952, lng: 17.5673416 },
    { lat: 48.5746649, lng: 17.5676251 },
    { lat: 48.5749246, lng: 17.5682657 },
    { lat: 48.5751349, lng: 17.568468 },
    { lat: 48.5752297, lng: 17.5688584 },
    { lat: 48.5754084, lng: 17.5692182 },
    { lat: 48.5755482, lng: 17.5697341 },
    { lat: 48.5754826, lng: 17.5700559 },
    { lat: 48.5753367, lng: 17.5702819 },
    { lat: 48.575341, lng: 17.570507 },
    { lat: 48.575361, lng: 17.570497 },
    { lat: 48.575623, lng: 17.570379 },
    { lat: 48.575673, lng: 17.56993 },
    { lat: 48.575599, lng: 17.569049 },
    { lat: 48.575505, lng: 17.568931 },
    { lat: 48.57542, lng: 17.568681 },
    { lat: 48.575413, lng: 17.568491 },
    { lat: 48.57527, lng: 17.568276 },
    { lat: 48.575048, lng: 17.567824 },
    { lat: 48.575029, lng: 17.567563 },
    { lat: 48.574882, lng: 17.567249 },
    { lat: 48.574842, lng: 17.566957 },
    { lat: 48.574832, lng: 17.566778 },
    { lat: 48.574753, lng: 17.566518 },
    { lat: 48.574637, lng: 17.566413 },
    { lat: 48.574516, lng: 17.565803 },
    { lat: 48.574234, lng: 17.565509 },
    { lat: 48.574003, lng: 17.5651 },
    { lat: 48.5736, lng: 17.564535 },
    { lat: 48.573355, lng: 17.564402 },
    { lat: 48.572857, lng: 17.564303 },
    { lat: 48.572805, lng: 17.564239 },
    { lat: 48.57278, lng: 17.564207 },
    { lat: 48.572763, lng: 17.564186 },
    { lat: 48.573265, lng: 17.562437 },
    { lat: 48.573331, lng: 17.562313 },
    { lat: 48.573605, lng: 17.56155 },
    { lat: 48.573664, lng: 17.56117 },
    { lat: 48.573747, lng: 17.560902 },
    { lat: 48.574341, lng: 17.559434 },
    { lat: 48.574535, lng: 17.559008 },
    { lat: 48.574627, lng: 17.558889 },
    { lat: 48.574814, lng: 17.558529 },
    { lat: 48.574975, lng: 17.558306 },
    { lat: 48.575183, lng: 17.558139 },
    { lat: 48.575689, lng: 17.558021 },
    { lat: 48.575822, lng: 17.558186 },
    { lat: 48.575905, lng: 17.55819 },
    { lat: 48.57607, lng: 17.558068 },
    { lat: 48.576326, lng: 17.558005 },
    { lat: 48.576482, lng: 17.558174 },
    { lat: 48.576761, lng: 17.55837 },
    { lat: 48.576866, lng: 17.558174 },
    { lat: 48.577194, lng: 17.558618 },
    { lat: 48.577631, lng: 17.559045 },
    { lat: 48.57775, lng: 17.558698 },
    { lat: 48.578099, lng: 17.557953 },
    { lat: 48.578319, lng: 17.557597 },
    { lat: 48.578389, lng: 17.557998 },
    { lat: 48.578476, lng: 17.558132 },
    { lat: 48.578576, lng: 17.558203 },
    { lat: 48.578773, lng: 17.558001 },
    { lat: 48.578844, lng: 17.55811 },
    { lat: 48.578994, lng: 17.558014 },
    { lat: 48.579064, lng: 17.558235 },
    { lat: 48.579251, lng: 17.558292 },
    { lat: 48.579475, lng: 17.558017 },
    { lat: 48.580305, lng: 17.557144 },
    { lat: 48.581616, lng: 17.556971 },
    { lat: 48.581695, lng: 17.557056 },
    { lat: 48.581856, lng: 17.557189 },
    { lat: 48.582027, lng: 17.55724 },
    { lat: 48.58239, lng: 17.557558 },
    { lat: 48.58244, lng: 17.557538 },
    { lat: 48.582501, lng: 17.557492 },
    { lat: 48.58255, lng: 17.557456 },
    { lat: 48.582553, lng: 17.557442 },
    { lat: 48.582963, lng: 17.557581 },
    { lat: 48.583428, lng: 17.557737 },
    { lat: 48.583605, lng: 17.557888 },
    { lat: 48.584035, lng: 17.558106 },
    { lat: 48.584115, lng: 17.558158 },
    { lat: 48.584314, lng: 17.558271 },
    { lat: 48.584553, lng: 17.55842 },
    { lat: 48.584708, lng: 17.558531 },
    { lat: 48.585013, lng: 17.558891 },
    { lat: 48.585274, lng: 17.559208 },
    { lat: 48.585303, lng: 17.559244 },
    { lat: 48.585318, lng: 17.559245 },
    { lat: 48.585662, lng: 17.559239 },
    { lat: 48.586403, lng: 17.559175 },
    { lat: 48.586462, lng: 17.559184 },
    { lat: 48.586706, lng: 17.559221 },
    { lat: 48.586856, lng: 17.559298 },
    { lat: 48.587108, lng: 17.559461 },
    { lat: 48.587431, lng: 17.559771 },
    { lat: 48.587752, lng: 17.560014 },
    { lat: 48.588516, lng: 17.560061 },
    { lat: 48.588661, lng: 17.56012 },
    { lat: 48.588837, lng: 17.560171 },
    { lat: 48.588987, lng: 17.560308 },
    { lat: 48.589304, lng: 17.560595 },
    { lat: 48.58957, lng: 17.560681 },
    { lat: 48.58972, lng: 17.560785 },
    { lat: 48.58983, lng: 17.560859 },
    { lat: 48.589889, lng: 17.560899 },
    { lat: 48.589956, lng: 17.560983 },
    { lat: 48.590039, lng: 17.561217 },
    { lat: 48.590057, lng: 17.561269 },
    { lat: 48.590085, lng: 17.561422 },
    { lat: 48.590207, lng: 17.561694 },
    { lat: 48.590345, lng: 17.561807 },
    { lat: 48.590614, lng: 17.562149 },
    { lat: 48.591293, lng: 17.562384 },
    { lat: 48.591337, lng: 17.562376 },
    { lat: 48.591433, lng: 17.562436 },
    { lat: 48.591604, lng: 17.562501 },
    { lat: 48.591889, lng: 17.562637 },
    { lat: 48.591886, lng: 17.562655 },
    { lat: 48.591907, lng: 17.562668 },
    { lat: 48.592593, lng: 17.563387 },
    { lat: 48.592661, lng: 17.563651 },
    { lat: 48.592817, lng: 17.563774 },
    { lat: 48.592874, lng: 17.563847 },
    { lat: 48.59289, lng: 17.56394 },
    { lat: 48.592988, lng: 17.56399 },
    { lat: 48.593044, lng: 17.564192 },
    { lat: 48.593085, lng: 17.56421 },
    { lat: 48.593138, lng: 17.564267 },
    { lat: 48.593229, lng: 17.564501 },
    { lat: 48.59333, lng: 17.564507 },
    { lat: 48.593423, lng: 17.564618 },
    { lat: 48.59346, lng: 17.564706 },
    { lat: 48.593578, lng: 17.564776 },
    { lat: 48.593703, lng: 17.564976 },
    { lat: 48.59381, lng: 17.565137 },
    { lat: 48.594042, lng: 17.565349 },
    { lat: 48.594113, lng: 17.56543 },
    { lat: 48.594114, lng: 17.565568 },
    { lat: 48.594158, lng: 17.565722 },
    { lat: 48.594179, lng: 17.56601 },
    { lat: 48.594168, lng: 17.566175 },
    { lat: 48.594227, lng: 17.566486 },
    { lat: 48.594257, lng: 17.5667 },
    { lat: 48.59437, lng: 17.566836 },
    { lat: 48.594375, lng: 17.567209 },
    { lat: 48.594398, lng: 17.567405 },
    { lat: 48.59444, lng: 17.567548 },
    { lat: 48.594382, lng: 17.567734 },
    { lat: 48.594385, lng: 17.567802 },
    { lat: 48.594312, lng: 17.567815 },
    { lat: 48.593844, lng: 17.567912 },
    { lat: 48.593576, lng: 17.567764 },
    { lat: 48.593397, lng: 17.567799 },
    { lat: 48.593239, lng: 17.567874 },
    { lat: 48.59311, lng: 17.568609 },
    { lat: 48.592969, lng: 17.568986 },
    { lat: 48.592858, lng: 17.569362 },
    { lat: 48.592772, lng: 17.569605 },
    { lat: 48.59277, lng: 17.569806 },
    { lat: 48.592991, lng: 17.570029 },
    { lat: 48.593023, lng: 17.570142 },
    { lat: 48.593036, lng: 17.570191 },
    { lat: 48.593125, lng: 17.570301 },
    { lat: 48.593406, lng: 17.570283 },
    { lat: 48.593566, lng: 17.570616 },
    { lat: 48.593785, lng: 17.570677 },
    { lat: 48.593881, lng: 17.570758 },
    { lat: 48.593944, lng: 17.57104 },
    { lat: 48.594033, lng: 17.571255 },
    { lat: 48.594134, lng: 17.571478 },
    { lat: 48.594158, lng: 17.571603 },
    { lat: 48.594265, lng: 17.571645 },
    { lat: 48.59437, lng: 17.5718 },
    { lat: 48.594184, lng: 17.573816 },
    { lat: 48.594898, lng: 17.574002 },
    { lat: 48.594938, lng: 17.576647 },
    { lat: 48.596394, lng: 17.576423 },
    { lat: 48.59887, lng: 17.576051 },
    { lat: 48.601189, lng: 17.573737 },
    { lat: 48.601298, lng: 17.573882 },
    { lat: 48.601271, lng: 17.573984 },
    { lat: 48.601455, lng: 17.574168 },
    { lat: 48.601618, lng: 17.574224 },
    { lat: 48.601952, lng: 17.574303 },
    { lat: 48.602179, lng: 17.574088 },
    { lat: 48.602259, lng: 17.57411 },
    { lat: 48.602385, lng: 17.574326 },
    { lat: 48.602894, lng: 17.57493 },
    { lat: 48.603185, lng: 17.575035 },
    { lat: 48.603494, lng: 17.574842 },
    { lat: 48.603625, lng: 17.574827 },
    { lat: 48.603904, lng: 17.574985 },
    { lat: 48.606056, lng: 17.5724 },
    { lat: 48.607491, lng: 17.56978 },
    { lat: 48.60924, lng: 17.571504 },
    { lat: 48.610913, lng: 17.571876 },
    { lat: 48.612556, lng: 17.574762 },
    { lat: 48.614224, lng: 17.577693 },
    { lat: 48.614858, lng: 17.578462 },
    { lat: 48.615389, lng: 17.579007 },
    { lat: 48.615769, lng: 17.579789 },
    { lat: 48.615795, lng: 17.579762 },
    { lat: 48.6162079, lng: 17.5794732 },
    { lat: 48.616354, lng: 17.579225 },
    { lat: 48.616781, lng: 17.579038 },
    { lat: 48.616994, lng: 17.580484 },
    { lat: 48.617058, lng: 17.580922 },
    { lat: 48.617339, lng: 17.5813 },
    { lat: 48.617414, lng: 17.581511 },
    { lat: 48.617664, lng: 17.581848 },
    { lat: 48.617785, lng: 17.582093 },
    { lat: 48.618044, lng: 17.582496 },
    { lat: 48.6179753, lng: 17.5834012 },
    { lat: 48.617965, lng: 17.583537 },
    { lat: 48.617994, lng: 17.58363 },
    { lat: 48.618461, lng: 17.584166 },
    { lat: 48.618604, lng: 17.584137 },
    { lat: 48.618902, lng: 17.584006 },
    { lat: 48.619066, lng: 17.58396 },
    { lat: 48.619147, lng: 17.58394 },
    { lat: 48.619235, lng: 17.5838426 },
    { lat: 48.619302, lng: 17.58376 },
    { lat: 48.6195, lng: 17.583435 },
    { lat: 48.620423, lng: 17.582067 },
    { lat: 48.620849, lng: 17.581339 },
    { lat: 48.620979, lng: 17.580902 },
    { lat: 48.62106, lng: 17.58075 },
    { lat: 48.621166, lng: 17.580296 },
    { lat: 48.6212376, lng: 17.5800826 },
    { lat: 48.621292, lng: 17.579892 },
    { lat: 48.621323, lng: 17.579787 },
    { lat: 48.621439, lng: 17.579557 },
    { lat: 48.621574, lng: 17.579096 },
    { lat: 48.621687, lng: 17.578819 },
    { lat: 48.621822, lng: 17.57855 },
    { lat: 48.621924, lng: 17.578285 },
    { lat: 48.622458, lng: 17.577482 },
    { lat: 48.622566, lng: 17.577407 },
    { lat: 48.622943, lng: 17.577351 },
    { lat: 48.623134, lng: 17.577282 },
    { lat: 48.623796, lng: 17.576903 },
    { lat: 48.623908, lng: 17.576872 },
    { lat: 48.624257, lng: 17.576631 },
    { lat: 48.624395, lng: 17.57654 },
    { lat: 48.624511, lng: 17.576477 },
    { lat: 48.624849, lng: 17.576243 },
    { lat: 48.625051, lng: 17.576156 },
    { lat: 48.625204, lng: 17.576124 },
    { lat: 48.625707, lng: 17.575893 },
    { lat: 48.625859, lng: 17.575873 },
    { lat: 48.626112, lng: 17.575714 },
    { lat: 48.62631, lng: 17.575701 },
    { lat: 48.626594, lng: 17.575746 },
    { lat: 48.626886, lng: 17.575857 },
    { lat: 48.627049, lng: 17.575886 },
    { lat: 48.627265, lng: 17.575968 },
    { lat: 48.627505, lng: 17.5761 },
    { lat: 48.627899, lng: 17.576372 },
    { lat: 48.62809, lng: 17.576577 },
    { lat: 48.628657, lng: 17.577335 },
    { lat: 48.6288, lng: 17.577498 },
    { lat: 48.629108, lng: 17.577633 },
    { lat: 48.629301, lng: 17.577824 },
    { lat: 48.629498, lng: 17.577914 },
    { lat: 48.629604, lng: 17.578189 },
    { lat: 48.630129, lng: 17.578293 },
    { lat: 48.630893, lng: 17.578579 },
    { lat: 48.6311, lng: 17.578509 },
    { lat: 48.631362, lng: 17.578684 },
    { lat: 48.63154, lng: 17.578755 },
    { lat: 48.631786, lng: 17.57879 },
    { lat: 48.63195, lng: 17.578775 },
    { lat: 48.632228, lng: 17.578944 },
    { lat: 48.6323, lng: 17.578921 },
    { lat: 48.632433, lng: 17.57887 },
    { lat: 48.632896, lng: 17.579087 },
    { lat: 48.633294, lng: 17.579086 },
    { lat: 48.633553, lng: 17.579097 },
    { lat: 48.633693, lng: 17.579064 },
    { lat: 48.633928, lng: 17.578975 },
    { lat: 48.634092, lng: 17.578975 },
    { lat: 48.634299, lng: 17.578936 },
    { lat: 48.634575, lng: 17.578972 },
    { lat: 48.634934, lng: 17.578931 },
    { lat: 48.635207, lng: 17.578792 },
    { lat: 48.635556, lng: 17.578714 },
    { lat: 48.635861, lng: 17.578612 },
    { lat: 48.63597, lng: 17.578601 },
    { lat: 48.636124, lng: 17.578611 },
    { lat: 48.63621, lng: 17.578579 },
    { lat: 48.636423, lng: 17.578557 },
    { lat: 48.63665, lng: 17.578487 },
  ],
  Pavlice: [
    { lat: 48.2548797, lng: 17.5452047 },
    { lat: 48.253823, lng: 17.546312 },
    { lat: 48.251889, lng: 17.548101 },
    { lat: 48.251008, lng: 17.549018 },
    { lat: 48.248308, lng: 17.552219 },
    { lat: 48.2466021, lng: 17.5543623 },
    { lat: 48.246557, lng: 17.554419 },
    { lat: 48.244669, lng: 17.556408 },
    { lat: 48.241847, lng: 17.559181 },
    { lat: 48.239763, lng: 17.561468 },
    { lat: 48.241757, lng: 17.564641 },
    { lat: 48.24504, lng: 17.569708 },
    { lat: 48.246233, lng: 17.571402 },
    { lat: 48.24817, lng: 17.573954 },
    { lat: 48.248002, lng: 17.574225 },
    { lat: 48.250042, lng: 17.576835 },
    { lat: 48.24988, lng: 17.577362 },
    { lat: 48.249887, lng: 17.57785 },
    { lat: 48.250221, lng: 17.579908 },
    { lat: 48.250236, lng: 17.580442 },
    { lat: 48.250181, lng: 17.58111 },
    { lat: 48.250557, lng: 17.581662 },
    { lat: 48.250792, lng: 17.582247 },
    { lat: 48.250749, lng: 17.58275 },
    { lat: 48.250873, lng: 17.583143 },
    { lat: 48.255182, lng: 17.588737 },
    { lat: 48.25754, lng: 17.591181 },
    { lat: 48.258153, lng: 17.591989 },
    { lat: 48.259493, lng: 17.591962 },
    { lat: 48.264464, lng: 17.597072 },
    { lat: 48.26493, lng: 17.596234 },
    { lat: 48.2650093, lng: 17.5960628 },
    { lat: 48.2657444, lng: 17.5944928 },
    { lat: 48.2664205, lng: 17.593545 },
    { lat: 48.2672839, lng: 17.5924844 },
    { lat: 48.2688375, lng: 17.5908442 },
    { lat: 48.2705163, lng: 17.5892716 },
    { lat: 48.2723465, lng: 17.5873452 },
    { lat: 48.2760792, lng: 17.582885 },
    { lat: 48.2767444, lng: 17.5818909 },
    { lat: 48.2769569, lng: 17.5814841 },
    { lat: 48.2757119, lng: 17.579553 },
    { lat: 48.2740478, lng: 17.576626 },
    { lat: 48.2733417, lng: 17.5755728 },
    { lat: 48.2718624, lng: 17.5745059 },
    { lat: 48.2715083, lng: 17.5746205 },
    { lat: 48.2711358, lng: 17.5748725 },
    { lat: 48.2710282, lng: 17.5746737 },
    { lat: 48.2703425, lng: 17.5748452 },
    { lat: 48.2700374, lng: 17.5734745 },
    { lat: 48.2697806, lng: 17.5727033 },
    { lat: 48.2683031, lng: 17.5701961 },
    { lat: 48.2684794, lng: 17.5674283 },
    { lat: 48.2684484, lng: 17.566672 },
    { lat: 48.2675527, lng: 17.5654824 },
    { lat: 48.2682019, lng: 17.5647691 },
    { lat: 48.2673634, lng: 17.5636944 },
    { lat: 48.2642699, lng: 17.5592915 },
    { lat: 48.2635426, lng: 17.5580497 },
    { lat: 48.262619, lng: 17.5567381 },
    { lat: 48.2606097, lng: 17.5536532 },
    { lat: 48.2602056, lng: 17.5528941 },
    { lat: 48.2591925, lng: 17.5514375 },
    { lat: 48.2580333, lng: 17.5499448 },
    { lat: 48.2553962, lng: 17.5458694 },
    { lat: 48.2549041, lng: 17.5452375 },
    { lat: 48.2548797, lng: 17.5452047 },
  ],
  DolnéOrešany: [
    { lat: 48.4245438, lng: 17.4316421 },
    { lat: 48.4256118, lng: 17.4328049 },
    { lat: 48.4252041, lng: 17.4336271 },
    { lat: 48.4274359, lng: 17.4362343 },
    { lat: 48.4267454, lng: 17.4391824 },
    { lat: 48.4265028, lng: 17.440499 },
    { lat: 48.4273474, lng: 17.4412312 },
    { lat: 48.429233, lng: 17.4431126 },
    { lat: 48.4302859, lng: 17.4411017 },
    { lat: 48.4309652, lng: 17.440061 },
    { lat: 48.4312454, lng: 17.4394852 },
    { lat: 48.4315827, lng: 17.4385324 },
    { lat: 48.4320899, lng: 17.4389905 },
    { lat: 48.4336783, lng: 17.4407958 },
    { lat: 48.4334778, lng: 17.4415072 },
    { lat: 48.4347298, lng: 17.442794 },
    { lat: 48.4348743, lng: 17.4429646 },
    { lat: 48.4349105, lng: 17.4430899 },
    { lat: 48.435762, lng: 17.4440506 },
    { lat: 48.4386782, lng: 17.447029 },
    { lat: 48.438562, lng: 17.447191 },
    { lat: 48.4384922, lng: 17.4475245 },
    { lat: 48.438242, lng: 17.4480048 },
    { lat: 48.4386229, lng: 17.448888 },
    { lat: 48.4387275, lng: 17.4489873 },
    { lat: 48.4394782, lng: 17.4505032 },
    { lat: 48.4405126, lng: 17.4519383 },
    { lat: 48.4408515, lng: 17.4526239 },
    { lat: 48.4410744, lng: 17.4532075 },
    { lat: 48.4414528, lng: 17.4549783 },
    { lat: 48.4417139, lng: 17.454262 },
    { lat: 48.4420563, lng: 17.453934 },
    { lat: 48.4421011, lng: 17.4540164 },
    { lat: 48.4422207, lng: 17.4539439 },
    { lat: 48.4423911, lng: 17.4541169 },
    { lat: 48.4427691, lng: 17.4541807 },
    { lat: 48.4430125, lng: 17.4539983 },
    { lat: 48.4433988, lng: 17.4544871 },
    { lat: 48.4441728, lng: 17.4552489 },
    { lat: 48.4447948, lng: 17.4562234 },
    { lat: 48.4449673, lng: 17.4565356 },
    { lat: 48.4449011, lng: 17.4566145 },
    { lat: 48.4452872, lng: 17.457314 },
    { lat: 48.4457944, lng: 17.4586126 },
    { lat: 48.4461577, lng: 17.458204 },
    { lat: 48.4482931, lng: 17.462007 },
    { lat: 48.4454134, lng: 17.4647532 },
    { lat: 48.4433623, lng: 17.4664694 },
    { lat: 48.4436208, lng: 17.4670302 },
    { lat: 48.4443378, lng: 17.468239 },
    { lat: 48.4451691, lng: 17.4675904 },
    { lat: 48.4459342, lng: 17.4672135 },
    { lat: 48.4476278, lng: 17.466028 },
    { lat: 48.4478377, lng: 17.4658143 },
    { lat: 48.4483355, lng: 17.4650543 },
    { lat: 48.4486697, lng: 17.464999 },
    { lat: 48.4489506, lng: 17.4653442 },
    { lat: 48.449114, lng: 17.4656032 },
    { lat: 48.4492728, lng: 17.4653162 },
    { lat: 48.4558752, lng: 17.470341 },
    { lat: 48.4558884, lng: 17.4703511 },
    { lat: 48.4559961, lng: 17.4701629 },
    { lat: 48.4560102, lng: 17.4701399 },
    { lat: 48.456503, lng: 17.4693713 },
    { lat: 48.4555848, lng: 17.4677363 },
    { lat: 48.4538508, lng: 17.4642699 },
    { lat: 48.4538142, lng: 17.4641062 },
    { lat: 48.4530851, lng: 17.462583 },
    { lat: 48.4518941, lng: 17.4604514 },
    { lat: 48.4519225, lng: 17.4604377 },
    { lat: 48.451251, lng: 17.4593545 },
    { lat: 48.4492316, lng: 17.4557597 },
    { lat: 48.4484319, lng: 17.4544779 },
    { lat: 48.4479018, lng: 17.4531918 },
    { lat: 48.4451946, lng: 17.4482299 },
    { lat: 48.4450133, lng: 17.4477825 },
    { lat: 48.4438437, lng: 17.4455652 },
    { lat: 48.443311, lng: 17.4444173 },
    { lat: 48.4425878, lng: 17.4432037 },
    { lat: 48.4424717, lng: 17.4427877 },
    { lat: 48.4423782, lng: 17.4420347 },
    { lat: 48.442512, lng: 17.44208 },
    { lat: 48.442662, lng: 17.4420062 },
    { lat: 48.4428509, lng: 17.4417502 },
    { lat: 48.4431337, lng: 17.441614 },
    { lat: 48.4432669, lng: 17.4413673 },
    { lat: 48.4433083, lng: 17.4409785 },
    { lat: 48.4433699, lng: 17.4408657 },
    { lat: 48.4434938, lng: 17.4409625 },
    { lat: 48.443846, lng: 17.4407319 },
    { lat: 48.4440254, lng: 17.440784 },
    { lat: 48.4442278, lng: 17.4406994 },
    { lat: 48.4442188, lng: 17.4404534 },
    { lat: 48.4440717, lng: 17.4402753 },
    { lat: 48.4440337, lng: 17.4394417 },
    { lat: 48.4441262, lng: 17.4392311 },
    { lat: 48.4442396, lng: 17.4392206 },
    { lat: 48.444373, lng: 17.4390799 },
    { lat: 48.4444839, lng: 17.4391018 },
    { lat: 48.444651, lng: 17.4394534 },
    { lat: 48.4447685, lng: 17.439506 },
    { lat: 48.4447999, lng: 17.4396081 },
    { lat: 48.4449508, lng: 17.439441 },
    { lat: 48.4451356, lng: 17.4395218 },
    { lat: 48.445202, lng: 17.4396584 },
    { lat: 48.4454275, lng: 17.4397749 },
    { lat: 48.4454159, lng: 17.4396133 },
    { lat: 48.4453157, lng: 17.4395646 },
    { lat: 48.445388, lng: 17.4391635 },
    { lat: 48.4453638, lng: 17.4391218 },
    { lat: 48.4452735, lng: 17.439193 },
    { lat: 48.4452559, lng: 17.4389536 },
    { lat: 48.4453398, lng: 17.4387326 },
    { lat: 48.4453037, lng: 17.4385902 },
    { lat: 48.4452385, lng: 17.4385264 },
    { lat: 48.4452506, lng: 17.4384535 },
    { lat: 48.4453733, lng: 17.4384449 },
    { lat: 48.4454219, lng: 17.4383421 },
    { lat: 48.4453561, lng: 17.4383211 },
    { lat: 48.4453468, lng: 17.4381915 },
    { lat: 48.4452429, lng: 17.4380004 },
    { lat: 48.445405, lng: 17.4376779 },
    { lat: 48.4456188, lng: 17.4378395 },
    { lat: 48.4457321, lng: 17.4377771 },
    { lat: 48.4458031, lng: 17.4376149 },
    { lat: 48.4456136, lng: 17.4375653 },
    { lat: 48.4456821, lng: 17.437281 },
    { lat: 48.4458149, lng: 17.4373253 },
    { lat: 48.4459712, lng: 17.4372271 },
    { lat: 48.4457891, lng: 17.436819 },
    { lat: 48.4457112, lng: 17.4368033 },
    { lat: 48.4457312, lng: 17.436678 },
    { lat: 48.4458364, lng: 17.4365033 },
    { lat: 48.4457712, lng: 17.436157 },
    { lat: 48.445822, lng: 17.4360714 },
    { lat: 48.4459238, lng: 17.4352877 },
    { lat: 48.4460112, lng: 17.4355005 },
    { lat: 48.4461671, lng: 17.4355212 },
    { lat: 48.4461807, lng: 17.4348045 },
    { lat: 48.4463172, lng: 17.4346284 },
    { lat: 48.4463424, lng: 17.4343878 },
    { lat: 48.4464041, lng: 17.4342593 },
    { lat: 48.4463893, lng: 17.4340626 },
    { lat: 48.4466968, lng: 17.4338946 },
    { lat: 48.44677, lng: 17.4336616 },
    { lat: 48.4469872, lng: 17.4335466 },
    { lat: 48.4471212, lng: 17.4337861 },
    { lat: 48.4472217, lng: 17.4335388 },
    { lat: 48.447478, lng: 17.4335889 },
    { lat: 48.4475344, lng: 17.4335027 },
    { lat: 48.4475258, lng: 17.4333147 },
    { lat: 48.4475701, lng: 17.4332307 },
    { lat: 48.4477924, lng: 17.4331762 },
    { lat: 48.447751, lng: 17.4328325 },
    { lat: 48.4476779, lng: 17.4328375 },
    { lat: 48.4476459, lng: 17.4327632 },
    { lat: 48.4478618, lng: 17.4325542 },
    { lat: 48.4479904, lng: 17.4323436 },
    { lat: 48.4481424, lng: 17.432319 },
    { lat: 48.4482304, lng: 17.4325274 },
    { lat: 48.4483582, lng: 17.4326336 },
    { lat: 48.4485554, lng: 17.4325753 },
    { lat: 48.448593, lng: 17.4325086 },
    { lat: 48.448572, lng: 17.4323224 },
    { lat: 48.4486866, lng: 17.4320527 },
    { lat: 48.4487008, lng: 17.4317814 },
    { lat: 48.448972, lng: 17.431645 },
    { lat: 48.4490849, lng: 17.4314503 },
    { lat: 48.4491016, lng: 17.4312554 },
    { lat: 48.4492807, lng: 17.4310745 },
    { lat: 48.4492321, lng: 17.4309492 },
    { lat: 48.4492648, lng: 17.4308612 },
    { lat: 48.4494503, lng: 17.4307441 },
    { lat: 48.4494921, lng: 17.430463 },
    { lat: 48.4495935, lng: 17.4304617 },
    { lat: 48.4496947, lng: 17.430559 },
    { lat: 48.4499271, lng: 17.4304311 },
    { lat: 48.4500062, lng: 17.4299959 },
    { lat: 48.4501094, lng: 17.4300904 },
    { lat: 48.4501684, lng: 17.4300764 },
    { lat: 48.4501653, lng: 17.4299733 },
    { lat: 48.4502897, lng: 17.4297855 },
    { lat: 48.4503878, lng: 17.429817 },
    { lat: 48.4505485, lng: 17.4295975 },
    { lat: 48.4507436, lng: 17.429642 },
    { lat: 48.450805, lng: 17.4295811 },
    { lat: 48.4517755, lng: 17.4282389 },
    { lat: 48.45201, lng: 17.4277582 },
    { lat: 48.4523728, lng: 17.4274326 },
    { lat: 48.4525786, lng: 17.4278043 },
    { lat: 48.4528474, lng: 17.4279941 },
    { lat: 48.4530113, lng: 17.4282591 },
    { lat: 48.4531796, lng: 17.4281893 },
    { lat: 48.4534747, lng: 17.4277573 },
    { lat: 48.4540907, lng: 17.4266469 },
    { lat: 48.4542089, lng: 17.4265346 },
    { lat: 48.4549979, lng: 17.4250424 },
    { lat: 48.455372, lng: 17.4244333 },
    { lat: 48.4555082, lng: 17.4242334 },
    { lat: 48.4562093, lng: 17.4236018 },
    { lat: 48.4574988, lng: 17.4215669 },
    { lat: 48.4578404, lng: 17.4208953 },
    { lat: 48.4582082, lng: 17.4198228 },
    { lat: 48.4588952, lng: 17.4192697 },
    { lat: 48.4589522, lng: 17.4187205 },
    { lat: 48.4590697, lng: 17.4183695 },
    { lat: 48.4590592, lng: 17.4173185 },
    { lat: 48.4592542, lng: 17.4158295 },
    { lat: 48.4592191, lng: 17.414955 },
    { lat: 48.4592865, lng: 17.4141274 },
    { lat: 48.4585968, lng: 17.4133341 },
    { lat: 48.4581677, lng: 17.4125301 },
    { lat: 48.4585718, lng: 17.410017 },
    { lat: 48.4585391, lng: 17.4087091 },
    { lat: 48.4580804, lng: 17.4077667 },
    { lat: 48.4563314, lng: 17.4051279 },
    { lat: 48.4559007, lng: 17.4045117 },
    { lat: 48.4554146, lng: 17.4040335 },
    { lat: 48.4554007, lng: 17.4035057 },
    { lat: 48.455008, lng: 17.402414 },
    { lat: 48.4546992, lng: 17.4018494 },
    { lat: 48.4542458, lng: 17.4011825 },
    { lat: 48.454103, lng: 17.3989119 },
    { lat: 48.4552053, lng: 17.3932534 },
    { lat: 48.4556574, lng: 17.3930675 },
    { lat: 48.4559137, lng: 17.3921418 },
    { lat: 48.4565057, lng: 17.3912414 },
    { lat: 48.4567521, lng: 17.390268 },
    { lat: 48.4568012, lng: 17.3898437 },
    { lat: 48.4568855, lng: 17.3897135 },
    { lat: 48.4573342, lng: 17.3894395 },
    { lat: 48.4575663, lng: 17.3889282 },
    { lat: 48.4576203, lng: 17.3885424 },
    { lat: 48.4576881, lng: 17.3884883 },
    { lat: 48.4577251, lng: 17.3881911 },
    { lat: 48.4577664, lng: 17.3880626 },
    { lat: 48.4578323, lng: 17.3880301 },
    { lat: 48.45786, lng: 17.3878858 },
    { lat: 48.4582482, lng: 17.3876886 },
    { lat: 48.4584701, lng: 17.3873803 },
    { lat: 48.4584828, lng: 17.386535 },
    { lat: 48.4586469, lng: 17.3866266 },
    { lat: 48.4587411, lng: 17.3863714 },
    { lat: 48.4590972, lng: 17.3863269 },
    { lat: 48.459871, lng: 17.385945 },
    { lat: 48.459969, lng: 17.3858032 },
    { lat: 48.4600725, lng: 17.3854983 },
    { lat: 48.4601832, lng: 17.3853955 },
    { lat: 48.4603121, lng: 17.3849962 },
    { lat: 48.4605942, lng: 17.3848899 },
    { lat: 48.4608423, lng: 17.3845521 },
    { lat: 48.4609912, lng: 17.3840462 },
    { lat: 48.4611068, lng: 17.3838449 },
    { lat: 48.4611757, lng: 17.3834945 },
    { lat: 48.4613308, lng: 17.3833461 },
    { lat: 48.4613689, lng: 17.3832198 },
    { lat: 48.4618278, lng: 17.3825122 },
    { lat: 48.4619652, lng: 17.3818446 },
    { lat: 48.4624615, lng: 17.3811741 },
    { lat: 48.4626609, lng: 17.3806989 },
    { lat: 48.4644672, lng: 17.3786328 },
    { lat: 48.4652041, lng: 17.3774766 },
    { lat: 48.4647237, lng: 17.3773654 },
    { lat: 48.464759, lng: 17.3771623 },
    { lat: 48.4648156, lng: 17.377269 },
    { lat: 48.4649692, lng: 17.3771809 },
    { lat: 48.464849, lng: 17.3769989 },
    { lat: 48.4646192, lng: 17.3769929 },
    { lat: 48.4645051, lng: 17.3768719 },
    { lat: 48.4644263, lng: 17.3766322 },
    { lat: 48.4644377, lng: 17.3764437 },
    { lat: 48.4642036, lng: 17.3764543 },
    { lat: 48.4640686, lng: 17.3763766 },
    { lat: 48.4639256, lng: 17.3757581 },
    { lat: 48.4637866, lng: 17.3754476 },
    { lat: 48.4634416, lng: 17.3753325 },
    { lat: 48.4632464, lng: 17.3753886 },
    { lat: 48.4627658, lng: 17.3753106 },
    { lat: 48.4626451, lng: 17.3754209 },
    { lat: 48.4625009, lng: 17.3757716 },
    { lat: 48.4623934, lng: 17.3758223 },
    { lat: 48.4620524, lng: 17.3756672 },
    { lat: 48.4618888, lng: 17.375477 },
    { lat: 48.4615033, lng: 17.3752501 },
    { lat: 48.4613417, lng: 17.3749852 },
    { lat: 48.4610449, lng: 17.3747819 },
    { lat: 48.460836, lng: 17.3747326 },
    { lat: 48.4608503, lng: 17.3746024 },
    { lat: 48.4607311, lng: 17.3743857 },
    { lat: 48.4606361, lng: 17.3743844 },
    { lat: 48.4606229, lng: 17.3746504 },
    { lat: 48.4605657, lng: 17.374725 },
    { lat: 48.4603064, lng: 17.3743528 },
    { lat: 48.4600079, lng: 17.3743299 },
    { lat: 48.4599032, lng: 17.3743876 },
    { lat: 48.4597577, lng: 17.3741297 },
    { lat: 48.4597337, lng: 17.3738852 },
    { lat: 48.4596296, lng: 17.3740016 },
    { lat: 48.4595798, lng: 17.3738441 },
    { lat: 48.4595572, lng: 17.3735478 },
    { lat: 48.4596347, lng: 17.3734371 },
    { lat: 48.4597408, lng: 17.3730877 },
    { lat: 48.4596857, lng: 17.3727299 },
    { lat: 48.4594982, lng: 17.3725903 },
    { lat: 48.4593148, lng: 17.3723216 },
    { lat: 48.4593496, lng: 17.3721296 },
    { lat: 48.459632, lng: 17.3719414 },
    { lat: 48.4596935, lng: 17.371653 },
    { lat: 48.4596112, lng: 17.3712719 },
    { lat: 48.4595363, lng: 17.3711801 },
    { lat: 48.4595924, lng: 17.3710206 },
    { lat: 48.4594414, lng: 17.3707296 },
    { lat: 48.4590685, lng: 17.3704482 },
    { lat: 48.4589656, lng: 17.3704498 },
    { lat: 48.4588257, lng: 17.3702776 },
    { lat: 48.4583398, lng: 17.3699441 },
    { lat: 48.4582982, lng: 17.3700182 },
    { lat: 48.4583271, lng: 17.3701677 },
    { lat: 48.4582795, lng: 17.370337 },
    { lat: 48.4581741, lng: 17.3704016 },
    { lat: 48.4580703, lng: 17.3701418 },
    { lat: 48.4579611, lng: 17.3701476 },
    { lat: 48.4576841, lng: 17.3702819 },
    { lat: 48.4576698, lng: 17.3702186 },
    { lat: 48.4575971, lng: 17.3702286 },
    { lat: 48.4573517, lng: 17.3705195 },
    { lat: 48.4571868, lng: 17.3703644 },
    { lat: 48.4572271, lng: 17.3701521 },
    { lat: 48.4570058, lng: 17.3701007 },
    { lat: 48.4568869, lng: 17.370147 },
    { lat: 48.4567845, lng: 17.3699998 },
    { lat: 48.4567382, lng: 17.3700653 },
    { lat: 48.4567823, lng: 17.3704307 },
    { lat: 48.456671, lng: 17.3706154 },
    { lat: 48.4565515, lng: 17.3702007 },
    { lat: 48.4563724, lng: 17.3701413 },
    { lat: 48.4563399, lng: 17.3701428 },
    { lat: 48.4563224, lng: 17.3702931 },
    { lat: 48.4564056, lng: 17.3703709 },
    { lat: 48.4564092, lng: 17.3704949 },
    { lat: 48.4563627, lng: 17.3706138 },
    { lat: 48.456285, lng: 17.3705651 },
    { lat: 48.456225, lng: 17.3703558 },
    { lat: 48.4559244, lng: 17.3701122 },
    { lat: 48.4560408, lng: 17.3698684 },
    { lat: 48.4562457, lng: 17.369635 },
    { lat: 48.4562392, lng: 17.3695488 },
    { lat: 48.4561104, lng: 17.3694656 },
    { lat: 48.4560594, lng: 17.3693223 },
    { lat: 48.4560101, lng: 17.36931 },
    { lat: 48.4558641, lng: 17.3695692 },
    { lat: 48.4557377, lng: 17.3695351 },
    { lat: 48.4558015, lng: 17.3691097 },
    { lat: 48.4557386, lng: 17.3690443 },
    { lat: 48.4556821, lng: 17.3688387 },
    { lat: 48.4552936, lng: 17.368855 },
    { lat: 48.4549376, lng: 17.368521 },
    { lat: 48.4547585, lng: 17.3685111 },
    { lat: 48.4548951, lng: 17.3682361 },
    { lat: 48.4549892, lng: 17.3682045 },
    { lat: 48.4551129, lng: 17.3679205 },
    { lat: 48.4551001, lng: 17.3678249 },
    { lat: 48.4549672, lng: 17.3677117 },
    { lat: 48.4548582, lng: 17.3678068 },
    { lat: 48.4546693, lng: 17.367804 },
    { lat: 48.4546276, lng: 17.3672838 },
    { lat: 48.454434, lng: 17.3671205 },
    { lat: 48.4544734, lng: 17.3666682 },
    { lat: 48.4545367, lng: 17.3666332 },
    { lat: 48.4545368, lng: 17.3665652 },
    { lat: 48.4544667, lng: 17.3664635 },
    { lat: 48.4543439, lng: 17.3666235 },
    { lat: 48.453971, lng: 17.3661543 },
    { lat: 48.4538109, lng: 17.3660541 },
    { lat: 48.4538536, lng: 17.3659111 },
    { lat: 48.4539874, lng: 17.3657445 },
    { lat: 48.4540508, lng: 17.3654533 },
    { lat: 48.4540252, lng: 17.3649739 },
    { lat: 48.4538154, lng: 17.3648386 },
    { lat: 48.4538181, lng: 17.364709 },
    { lat: 48.4538924, lng: 17.364687 },
    { lat: 48.4539152, lng: 17.3644987 },
    { lat: 48.4538569, lng: 17.3642464 },
    { lat: 48.4535863, lng: 17.3639596 },
    { lat: 48.4534939, lng: 17.3639441 },
    { lat: 48.4533494, lng: 17.3642753 },
    { lat: 48.4531438, lng: 17.3641536 },
    { lat: 48.4530218, lng: 17.3644136 },
    { lat: 48.4529734, lng: 17.3640158 },
    { lat: 48.4530604, lng: 17.3639681 },
    { lat: 48.4530793, lng: 17.3638899 },
    { lat: 48.4530296, lng: 17.3637887 },
    { lat: 48.4528283, lng: 17.3636966 },
    { lat: 48.452704, lng: 17.3633153 },
    { lat: 48.452813, lng: 17.3632737 },
    { lat: 48.4529107, lng: 17.3633671 },
    { lat: 48.4530067, lng: 17.3633003 },
    { lat: 48.4529872, lng: 17.363085 },
    { lat: 48.4530569, lng: 17.3626147 },
    { lat: 48.4528075, lng: 17.3623906 },
    { lat: 48.4526839, lng: 17.3623501 },
    { lat: 48.4527682, lng: 17.3621356 },
    { lat: 48.4528985, lng: 17.3621776 },
    { lat: 48.4531335, lng: 17.3618832 },
    { lat: 48.4530724, lng: 17.3617954 },
    { lat: 48.4528761, lng: 17.3619033 },
    { lat: 48.4526904, lng: 17.3618609 },
    { lat: 48.4528686, lng: 17.3613703 },
    { lat: 48.4528709, lng: 17.3611636 },
    { lat: 48.4528197, lng: 17.3610381 },
    { lat: 48.4525819, lng: 17.3608913 },
    { lat: 48.4524838, lng: 17.3607367 },
    { lat: 48.4525752, lng: 17.3604397 },
    { lat: 48.4525421, lng: 17.36002 },
    { lat: 48.452796, lng: 17.3600143 },
    { lat: 48.4528931, lng: 17.3601348 },
    { lat: 48.4529348, lng: 17.3599436 },
    { lat: 48.4527499, lng: 17.3597944 },
    { lat: 48.452761, lng: 17.3597028 },
    { lat: 48.4527124, lng: 17.359669 },
    { lat: 48.45258, lng: 17.3598195 },
    { lat: 48.4524156, lng: 17.3595854 },
    { lat: 48.4524617, lng: 17.359374 },
    { lat: 48.4522369, lng: 17.3591328 },
    { lat: 48.4521181, lng: 17.359198 },
    { lat: 48.4520238, lng: 17.3590702 },
    { lat: 48.4521534, lng: 17.3589778 },
    { lat: 48.4522928, lng: 17.3587452 },
    { lat: 48.4525366, lng: 17.3587602 },
    { lat: 48.4525005, lng: 17.3585694 },
    { lat: 48.4524152, lng: 17.3584375 },
    { lat: 48.4524005, lng: 17.3580739 },
    { lat: 48.4527263, lng: 17.3581337 },
    { lat: 48.4526974, lng: 17.3577415 },
    { lat: 48.4528389, lng: 17.3576431 },
    { lat: 48.4528774, lng: 17.3575076 },
    { lat: 48.4528726, lng: 17.3572588 },
    { lat: 48.4527777, lng: 17.357154 },
    { lat: 48.4526257, lng: 17.3571796 },
    { lat: 48.4527757, lng: 17.3565006 },
    { lat: 48.4527312, lng: 17.356304 },
    { lat: 48.4527839, lng: 17.3561944 },
    { lat: 48.4527934, lng: 17.3559774 },
    { lat: 48.4529236, lng: 17.3558248 },
    { lat: 48.4530351, lng: 17.3559222 },
    { lat: 48.4531207, lng: 17.3559028 },
    { lat: 48.4531066, lng: 17.3556875 },
    { lat: 48.4530478, lng: 17.3556164 },
    { lat: 48.4530573, lng: 17.3552439 },
    { lat: 48.4530078, lng: 17.3550221 },
    { lat: 48.4530616, lng: 17.3547544 },
    { lat: 48.4532453, lng: 17.3544547 },
    { lat: 48.4533129, lng: 17.3542095 },
    { lat: 48.4532856, lng: 17.3538431 },
    { lat: 48.4533025, lng: 17.3536524 },
    { lat: 48.4533835, lng: 17.3535436 },
    { lat: 48.4533535, lng: 17.3531897 },
    { lat: 48.4533988, lng: 17.3529994 },
    { lat: 48.4534396, lng: 17.3528806 },
    { lat: 48.4534971, lng: 17.3529664 },
    { lat: 48.4535156, lng: 17.3531274 },
    { lat: 48.4535641, lng: 17.3531263 },
    { lat: 48.4536014, lng: 17.3529724 },
    { lat: 48.4535517, lng: 17.3527623 },
    { lat: 48.453395, lng: 17.3525271 },
    { lat: 48.4534861, lng: 17.3524186 },
    { lat: 48.4536244, lng: 17.3525968 },
    { lat: 48.4536749, lng: 17.3523833 },
    { lat: 48.4536258, lng: 17.3518394 },
    { lat: 48.4533721, lng: 17.3517096 },
    { lat: 48.4532196, lng: 17.3511214 },
    { lat: 48.4531039, lng: 17.3510784 },
    { lat: 48.4530568, lng: 17.3508758 },
    { lat: 48.4531851, lng: 17.3504551 },
    { lat: 48.4531155, lng: 17.3502191 },
    { lat: 48.4531456, lng: 17.3496941 },
    { lat: 48.4529369, lng: 17.3496445 },
    { lat: 48.4529208, lng: 17.3493113 },
    { lat: 48.4524524, lng: 17.3490588 },
    { lat: 48.4522775, lng: 17.3486999 },
    { lat: 48.4521663, lng: 17.3486758 },
    { lat: 48.4521359, lng: 17.3484348 },
    { lat: 48.4519881, lng: 17.3481434 },
    { lat: 48.4520027, lng: 17.3479502 },
    { lat: 48.4521979, lng: 17.3477389 },
    { lat: 48.4522708, lng: 17.3475573 },
    { lat: 48.4523223, lng: 17.3472586 },
    { lat: 48.4522935, lng: 17.3467821 },
    { lat: 48.4522256, lng: 17.3465399 },
    { lat: 48.4520505, lng: 17.3464352 },
    { lat: 48.4518655, lng: 17.3464378 },
    { lat: 48.4517538, lng: 17.346742 },
    { lat: 48.451598, lng: 17.346999 },
    { lat: 48.451497, lng: 17.347165 },
    { lat: 48.451394, lng: 17.347232 },
    { lat: 48.451254, lng: 17.347298 },
    { lat: 48.451222, lng: 17.347301 },
    { lat: 48.451107, lng: 17.347171 },
    { lat: 48.450963, lng: 17.347023 },
    { lat: 48.450912, lng: 17.346973 },
    { lat: 48.45088, lng: 17.346981 },
    { lat: 48.450842, lng: 17.34711 },
    { lat: 48.450815, lng: 17.347126 },
    { lat: 48.450746, lng: 17.346981 },
    { lat: 48.450616, lng: 17.347113 },
    { lat: 48.450586, lng: 17.347116 },
    { lat: 48.450571, lng: 17.347107 },
    { lat: 48.450498, lng: 17.346946 },
    { lat: 48.450483, lng: 17.346928 },
    { lat: 48.450462, lng: 17.346931 },
    { lat: 48.450237, lng: 17.346927 },
    { lat: 48.45003, lng: 17.347128 },
    { lat: 48.449776, lng: 17.347378 },
    { lat: 48.449687, lng: 17.347358 },
    { lat: 48.449675, lng: 17.347333 },
    { lat: 48.449681, lng: 17.3473 },
    { lat: 48.44974, lng: 17.347234 },
    { lat: 48.44975, lng: 17.347198 },
    { lat: 48.449725, lng: 17.347133 },
    { lat: 48.449703, lng: 17.347108 },
    { lat: 48.449678, lng: 17.347107 },
    { lat: 48.449536, lng: 17.347145 },
    { lat: 48.449324, lng: 17.347212 },
    { lat: 48.449271, lng: 17.347151 },
    { lat: 48.449258, lng: 17.347157 },
    { lat: 48.449222, lng: 17.347172 },
    { lat: 48.448983, lng: 17.347394 },
    { lat: 48.448745, lng: 17.347614 },
    { lat: 48.448552, lng: 17.347884 },
    { lat: 48.44835, lng: 17.348143 },
    { lat: 48.448295, lng: 17.348388 },
    { lat: 48.448237, lng: 17.348633 },
    { lat: 48.448055, lng: 17.348985 },
    { lat: 48.447876, lng: 17.349358 },
    { lat: 48.447661, lng: 17.349819 },
    { lat: 48.447495, lng: 17.350206 },
    { lat: 48.447278, lng: 17.350719 },
    { lat: 48.447074, lng: 17.351018 },
    { lat: 48.446828, lng: 17.351374 },
    { lat: 48.44662, lng: 17.351682 },
    { lat: 48.446424, lng: 17.351985 },
    { lat: 48.446421, lng: 17.351992 },
    { lat: 48.446266, lng: 17.352282 },
    { lat: 48.446251, lng: 17.352599 },
    { lat: 48.446202, lng: 17.35287 },
    { lat: 48.446055, lng: 17.353117 },
    { lat: 48.445962, lng: 17.353335 },
    { lat: 48.445848, lng: 17.353496 },
    { lat: 48.445674, lng: 17.353744 },
    { lat: 48.445385, lng: 17.353963 },
    { lat: 48.445328, lng: 17.354067 },
    { lat: 48.445307, lng: 17.354104 },
    { lat: 48.445236, lng: 17.354234 },
    { lat: 48.445118, lng: 17.354633 },
    { lat: 48.444994, lng: 17.355139 },
    { lat: 48.445016, lng: 17.355445 },
    { lat: 48.445007, lng: 17.355527 },
    { lat: 48.4449981, lng: 17.3556043 },
    { lat: 48.444999, lng: 17.35576 },
    { lat: 48.444989, lng: 17.35601 },
    { lat: 48.444961, lng: 17.356476 },
    { lat: 48.444908, lng: 17.356966 },
    { lat: 48.444911, lng: 17.357231 },
    { lat: 48.444995, lng: 17.357691 },
    { lat: 48.44375, lng: 17.357636 },
    { lat: 48.443235, lng: 17.358074 },
    { lat: 48.442913, lng: 17.358359 },
    { lat: 48.442629, lng: 17.358614 },
    { lat: 48.442245, lng: 17.358966 },
    { lat: 48.44187, lng: 17.3593 },
    { lat: 48.44158, lng: 17.359561 },
    { lat: 48.441272, lng: 17.359838 },
    { lat: 48.440957, lng: 17.360118 },
    { lat: 48.440657, lng: 17.360389 },
    { lat: 48.440339, lng: 17.360678 },
    { lat: 48.440008, lng: 17.360976 },
    { lat: 48.439574, lng: 17.361364 },
    { lat: 48.439187, lng: 17.361709 },
    { lat: 48.438859, lng: 17.361636 },
    { lat: 48.438548, lng: 17.361562 },
    { lat: 48.438565, lng: 17.362025 },
    { lat: 48.438578, lng: 17.362425 },
    { lat: 48.438602, lng: 17.36292 },
    { lat: 48.43862, lng: 17.363423 },
    { lat: 48.438639, lng: 17.363914 },
    { lat: 48.438656, lng: 17.364343 },
    { lat: 48.438668, lng: 17.364602 },
    { lat: 48.438683, lng: 17.365163 },
    { lat: 48.438697, lng: 17.365749 },
    { lat: 48.438714, lng: 17.366296 },
    { lat: 48.438722, lng: 17.366743 },
    { lat: 48.438739, lng: 17.367203 },
    { lat: 48.43875, lng: 17.367671 },
    { lat: 48.438763, lng: 17.368113 },
    { lat: 48.438774, lng: 17.368545 },
    { lat: 48.438788, lng: 17.369048 },
    { lat: 48.438809, lng: 17.369701 },
    { lat: 48.438823, lng: 17.370125 },
    { lat: 48.438838, lng: 17.37059 },
    { lat: 48.438857, lng: 17.371128 },
    { lat: 48.438875, lng: 17.371618 },
    { lat: 48.438897, lng: 17.372286 },
    { lat: 48.438907, lng: 17.372643 },
    { lat: 48.438917, lng: 17.373151 },
    { lat: 48.438933, lng: 17.373654 },
    { lat: 48.438944, lng: 17.374216 },
    { lat: 48.438954, lng: 17.374692 },
    { lat: 48.438955, lng: 17.374745 },
    { lat: 48.438958, lng: 17.374848 },
    { lat: 48.438965, lng: 17.375368 },
    { lat: 48.438969, lng: 17.375882 },
    { lat: 48.438974, lng: 17.376395 },
    { lat: 48.438978, lng: 17.377153 },
    { lat: 48.439058, lng: 17.377695 },
    { lat: 48.439172, lng: 17.37846 },
    { lat: 48.439088, lng: 17.379021 },
    { lat: 48.438984, lng: 17.379716 },
    { lat: 48.438971, lng: 17.380295 },
    { lat: 48.438959, lng: 17.380879 },
    { lat: 48.438945, lng: 17.381368 },
    { lat: 48.43894, lng: 17.381489 },
    { lat: 48.438928, lng: 17.381882 },
    { lat: 48.438921, lng: 17.382291 },
    { lat: 48.438924, lng: 17.382824 },
    { lat: 48.438925, lng: 17.383403 },
    { lat: 48.438936, lng: 17.384372 },
    { lat: 48.438941, lng: 17.384855 },
    { lat: 48.438934, lng: 17.385261 },
    { lat: 48.438929, lng: 17.385682 },
    { lat: 48.438921, lng: 17.386046 },
    { lat: 48.438911, lng: 17.386522 },
    { lat: 48.4389, lng: 17.38701 },
    { lat: 48.438892, lng: 17.387396 },
    { lat: 48.438879, lng: 17.387913 },
    { lat: 48.438864, lng: 17.388452 },
    { lat: 48.438851, lng: 17.389028 },
    { lat: 48.438849, lng: 17.389223 },
    { lat: 48.43883, lng: 17.389706 },
    { lat: 48.438815, lng: 17.390108 },
    { lat: 48.438797, lng: 17.390593 },
    { lat: 48.438775, lng: 17.390922 },
    { lat: 48.438749, lng: 17.391203 },
    { lat: 48.438718, lng: 17.391644 },
    { lat: 48.438685, lng: 17.392166 },
    { lat: 48.438639, lng: 17.392492 },
    { lat: 48.4385, lng: 17.392927 },
    { lat: 48.438447, lng: 17.393016 },
    { lat: 48.438384, lng: 17.393128 },
    { lat: 48.438223, lng: 17.393354 },
    { lat: 48.438062, lng: 17.393571 },
    { lat: 48.437846, lng: 17.39384 },
    { lat: 48.437628, lng: 17.394104 },
    { lat: 48.437566, lng: 17.393873 },
    { lat: 48.43733, lng: 17.393817 },
    { lat: 48.437172, lng: 17.393783 },
    { lat: 48.436949, lng: 17.393516 },
    { lat: 48.436716, lng: 17.393251 },
    { lat: 48.436494, lng: 17.392998 },
    { lat: 48.436361, lng: 17.392862 },
    { lat: 48.436271, lng: 17.392603 },
    { lat: 48.436129, lng: 17.392486 },
    { lat: 48.436061, lng: 17.392491 },
    { lat: 48.436007, lng: 17.392495 },
    { lat: 48.435832, lng: 17.392362 },
    { lat: 48.435678, lng: 17.392248 },
    { lat: 48.435564, lng: 17.392054 },
    { lat: 48.435516, lng: 17.391972 },
    { lat: 48.435377, lng: 17.391835 },
    { lat: 48.435238, lng: 17.391697 },
    { lat: 48.434982, lng: 17.391433 },
    { lat: 48.434722, lng: 17.391146 },
    { lat: 48.434476, lng: 17.390882 },
    { lat: 48.434221, lng: 17.390596 },
    { lat: 48.434177, lng: 17.390556 },
    { lat: 48.434167, lng: 17.390547 },
    { lat: 48.43413, lng: 17.39077 },
    { lat: 48.434103, lng: 17.39096 },
    { lat: 48.434017, lng: 17.391138 },
    { lat: 48.433948, lng: 17.391274 },
    { lat: 48.433886, lng: 17.391513 },
    { lat: 48.433839, lng: 17.391698 },
    { lat: 48.433785, lng: 17.391942 },
    { lat: 48.43375, lng: 17.392087 },
    { lat: 48.433646, lng: 17.392217 },
    { lat: 48.433547, lng: 17.392342 },
    { lat: 48.433446, lng: 17.392639 },
    { lat: 48.433369, lng: 17.39286 },
    { lat: 48.433261, lng: 17.393109 },
    { lat: 48.433212, lng: 17.393213 },
    { lat: 48.433103, lng: 17.393486 },
    { lat: 48.433, lng: 17.39374 },
    { lat: 48.432975, lng: 17.393825 },
    { lat: 48.432953, lng: 17.393868 },
    { lat: 48.432945, lng: 17.393883 },
    { lat: 48.4325489, lng: 17.3945827 },
    { lat: 48.4323369, lng: 17.395302 },
    { lat: 48.4320248, lng: 17.3958859 },
    { lat: 48.4318348, lng: 17.3964237 },
    { lat: 48.4316848, lng: 17.3966349 },
    { lat: 48.431571, lng: 17.3969539 },
    { lat: 48.4311971, lng: 17.3972488 },
    { lat: 48.4311182, lng: 17.3973902 },
    { lat: 48.4306013, lng: 17.3975371 },
    { lat: 48.4302634, lng: 17.3977054 },
    { lat: 48.4297138, lng: 17.3983216 },
    { lat: 48.4296761, lng: 17.398506 },
    { lat: 48.4297074, lng: 17.3986611 },
    { lat: 48.4296216, lng: 17.3988163 },
    { lat: 48.4298019, lng: 17.3996979 },
    { lat: 48.4298308, lng: 17.4001933 },
    { lat: 48.4299046, lng: 17.400445 },
    { lat: 48.4284928, lng: 17.4027935 },
    { lat: 48.4272843, lng: 17.4046413 },
    { lat: 48.427748, lng: 17.4052437 },
    { lat: 48.4280458, lng: 17.4047844 },
    { lat: 48.4285362, lng: 17.4052073 },
    { lat: 48.4284483, lng: 17.405542 },
    { lat: 48.4282144, lng: 17.4060777 },
    { lat: 48.4289077, lng: 17.4068222 },
    { lat: 48.4292255, lng: 17.4064464 },
    { lat: 48.4294983, lng: 17.4069079 },
    { lat: 48.4298838, lng: 17.4074184 },
    { lat: 48.4290053, lng: 17.4094632 },
    { lat: 48.4288019, lng: 17.4092874 },
    { lat: 48.4287244, lng: 17.4094498 },
    { lat: 48.4286684, lng: 17.4102074 },
    { lat: 48.4284884, lng: 17.4104094 },
    { lat: 48.4281757, lng: 17.4106034 },
    { lat: 48.4275867, lng: 17.4114663 },
    { lat: 48.4270958, lng: 17.4119777 },
    { lat: 48.4270159, lng: 17.4121071 },
    { lat: 48.4267404, lng: 17.4129621 },
    { lat: 48.4264734, lng: 17.4127092 },
    { lat: 48.4258052, lng: 17.4141476 },
    { lat: 48.425901, lng: 17.4143363 },
    { lat: 48.4266014, lng: 17.4152446 },
    { lat: 48.4260053, lng: 17.4161888 },
    { lat: 48.4284002, lng: 17.41871 },
    { lat: 48.4284037, lng: 17.4191984 },
    { lat: 48.4282604, lng: 17.4202315 },
    { lat: 48.4279207, lng: 17.4210027 },
    { lat: 48.4276514, lng: 17.422078 },
    { lat: 48.4276554, lng: 17.4224177 },
    { lat: 48.4274694, lng: 17.4227369 },
    { lat: 48.4273394, lng: 17.4227865 },
    { lat: 48.4270689, lng: 17.4233036 },
    { lat: 48.4269428, lng: 17.4244276 },
    { lat: 48.4266903, lng: 17.4250829 },
    { lat: 48.4262628, lng: 17.425927 },
    { lat: 48.4254055, lng: 17.427902 },
    { lat: 48.4252856, lng: 17.4283878 },
    { lat: 48.4241592, lng: 17.4311613 },
    { lat: 48.4245441, lng: 17.4315581 },
    { lat: 48.4245438, lng: 17.4316421 },
  ],
  Buková: [
    { lat: 48.55888, lng: 17.381357 },
    { lat: 48.558772, lng: 17.381219 },
    { lat: 48.558692, lng: 17.381123 },
    { lat: 48.558501, lng: 17.381001 },
    { lat: 48.55831, lng: 17.380963 },
    { lat: 48.557977, lng: 17.380814 },
    { lat: 48.557899, lng: 17.380969 },
    { lat: 48.557826, lng: 17.381101 },
    { lat: 48.557733, lng: 17.381188 },
    { lat: 48.557566, lng: 17.381414 },
    { lat: 48.557433, lng: 17.381591 },
    { lat: 48.557357, lng: 17.381691 },
    { lat: 48.557153, lng: 17.38201 },
    { lat: 48.557127, lng: 17.382041 },
    { lat: 48.557039, lng: 17.382101 },
    { lat: 48.556935, lng: 17.382235 },
    { lat: 48.556812, lng: 17.382443 },
    { lat: 48.556805, lng: 17.382612 },
    { lat: 48.556716, lng: 17.382705 },
    { lat: 48.556511, lng: 17.382696 },
    { lat: 48.55621, lng: 17.382877 },
    { lat: 48.555963, lng: 17.382835 },
    { lat: 48.555831, lng: 17.38281 },
    { lat: 48.555706, lng: 17.382802 },
    { lat: 48.55565, lng: 17.382791 },
    { lat: 48.555466, lng: 17.382546 },
    { lat: 48.55545, lng: 17.382381 },
    { lat: 48.555378, lng: 17.382318 },
    { lat: 48.555321, lng: 17.382052 },
    { lat: 48.555255, lng: 17.381776 },
    { lat: 48.555169, lng: 17.381475 },
    { lat: 48.555085, lng: 17.381355 },
    { lat: 48.554961, lng: 17.381254 },
    { lat: 48.554847, lng: 17.381127 },
    { lat: 48.554791, lng: 17.380966 },
    { lat: 48.55467, lng: 17.380875 },
    { lat: 48.554653, lng: 17.380787 },
    { lat: 48.55473, lng: 17.380617 },
    { lat: 48.554691, lng: 17.380557 },
    { lat: 48.554673, lng: 17.380485 },
    { lat: 48.554634, lng: 17.380443 },
    { lat: 48.554627, lng: 17.380367 },
    { lat: 48.554651, lng: 17.380211 },
    { lat: 48.554688, lng: 17.38006 },
    { lat: 48.554705, lng: 17.379933 },
    { lat: 48.554712, lng: 17.379856 },
    { lat: 48.554686, lng: 17.379708 },
    { lat: 48.554698, lng: 17.379632 },
    { lat: 48.554702, lng: 17.379568 },
    { lat: 48.554628, lng: 17.379369 },
    { lat: 48.554558, lng: 17.379305 },
    { lat: 48.554521, lng: 17.379244 },
    { lat: 48.554507, lng: 17.378963 },
    { lat: 48.554478, lng: 17.378871 },
    { lat: 48.554554, lng: 17.378743 },
    { lat: 48.554387, lng: 17.378139 },
    { lat: 48.554384, lng: 17.377957 },
    { lat: 48.554309, lng: 17.377633 },
    { lat: 48.554272, lng: 17.377587 },
    { lat: 48.554241, lng: 17.377559 },
    { lat: 48.55418, lng: 17.377309 },
    { lat: 48.554036, lng: 17.377094 },
    { lat: 48.553781, lng: 17.376524 },
    { lat: 48.553657, lng: 17.376422 },
    { lat: 48.5534, lng: 17.376119 },
    { lat: 48.553343, lng: 17.375997 },
    { lat: 48.553295, lng: 17.375718 },
    { lat: 48.553237, lng: 17.37574 },
    { lat: 48.552909, lng: 17.37544 },
    { lat: 48.552666, lng: 17.375006 },
    { lat: 48.55268, lng: 17.374889 },
    { lat: 48.552643, lng: 17.37484 },
    { lat: 48.552514, lng: 17.374733 },
    { lat: 48.552499, lng: 17.374537 },
    { lat: 48.552534, lng: 17.3743 },
    { lat: 48.55242, lng: 17.374135 },
    { lat: 48.552348, lng: 17.373726 },
    { lat: 48.552293, lng: 17.373697 },
    { lat: 48.552108, lng: 17.37335 },
    { lat: 48.551953, lng: 17.373123 },
    { lat: 48.551915, lng: 17.37282 },
    { lat: 48.551816, lng: 17.372618 },
    { lat: 48.551756, lng: 17.372362 },
    { lat: 48.551644, lng: 17.372188 },
    { lat: 48.551585, lng: 17.372046 },
    { lat: 48.551511, lng: 17.371903 },
    { lat: 48.551488, lng: 17.371791 },
    { lat: 48.551329, lng: 17.371237 },
    { lat: 48.551194, lng: 17.371219 },
    { lat: 48.55108, lng: 17.371031 },
    { lat: 48.550997, lng: 17.371022 },
    { lat: 48.550709, lng: 17.370831 },
    { lat: 48.550649, lng: 17.370938 },
    { lat: 48.550543, lng: 17.37089 },
    { lat: 48.550464, lng: 17.370782 },
    { lat: 48.55031, lng: 17.370717 },
    { lat: 48.55015, lng: 17.370793 },
    { lat: 48.549911, lng: 17.370311 },
    { lat: 48.549703, lng: 17.369684 },
    { lat: 48.549498, lng: 17.369943 },
    { lat: 48.549431, lng: 17.370029 },
    { lat: 48.549286, lng: 17.370215 },
    { lat: 48.548819, lng: 17.369969 },
    { lat: 48.548657, lng: 17.369768 },
    { lat: 48.548499, lng: 17.369677 },
    { lat: 48.548396, lng: 17.369376 },
    { lat: 48.548327, lng: 17.369091 },
    { lat: 48.548322, lng: 17.368811 },
    { lat: 48.548212, lng: 17.368186 },
    { lat: 48.548077, lng: 17.368202 },
    { lat: 48.548028, lng: 17.368123 },
    { lat: 48.54757, lng: 17.367524 },
    { lat: 48.547439, lng: 17.367353 },
    { lat: 48.547228, lng: 17.367186 },
    { lat: 48.546917, lng: 17.367005 },
    { lat: 48.54667, lng: 17.367105 },
    { lat: 48.546453, lng: 17.366819 },
    { lat: 48.54632, lng: 17.366645 },
    { lat: 48.546204, lng: 17.366483 },
    { lat: 48.545984, lng: 17.366204 },
    { lat: 48.545918, lng: 17.365848 },
    { lat: 48.545827, lng: 17.365557 },
    { lat: 48.545722, lng: 17.365372 },
    { lat: 48.545525, lng: 17.365228 },
    { lat: 48.545188, lng: 17.364831 },
    { lat: 48.545009, lng: 17.364244 },
    { lat: 48.544946, lng: 17.364082 },
    { lat: 48.544733, lng: 17.363549 },
    { lat: 48.544427, lng: 17.362795 },
    { lat: 48.544165, lng: 17.362539 },
    { lat: 48.543859, lng: 17.362451 },
    { lat: 48.543333, lng: 17.36202 },
    { lat: 48.542749, lng: 17.361546 },
    { lat: 48.542582, lng: 17.361383 },
    { lat: 48.542299, lng: 17.361205 },
    { lat: 48.542052, lng: 17.360992 },
    { lat: 48.54191, lng: 17.360827 },
    { lat: 48.541697, lng: 17.360575 },
    { lat: 48.54151, lng: 17.360381 },
    { lat: 48.541416, lng: 17.360363 },
    { lat: 48.541064, lng: 17.360026 },
    { lat: 48.540974, lng: 17.359883 },
    { lat: 48.540892, lng: 17.359785 },
    { lat: 48.540734, lng: 17.359478 },
    { lat: 48.540559, lng: 17.359278 },
    { lat: 48.540354, lng: 17.359091 },
    { lat: 48.540258, lng: 17.359043 },
    { lat: 48.539799, lng: 17.358834 },
    { lat: 48.539276, lng: 17.358597 },
    { lat: 48.539158, lng: 17.358542 },
    { lat: 48.538757, lng: 17.358219 },
    { lat: 48.538719, lng: 17.358179 },
    { lat: 48.538231, lng: 17.358189 },
    { lat: 48.538024, lng: 17.358116 },
    { lat: 48.537892, lng: 17.358067 },
    { lat: 48.537684, lng: 17.357922 },
    { lat: 48.53757, lng: 17.357909 },
    { lat: 48.537395, lng: 17.35783 },
    { lat: 48.537186, lng: 17.357661 },
    { lat: 48.536978, lng: 17.357725 },
    { lat: 48.53671, lng: 17.357707 },
    { lat: 48.53652, lng: 17.357668 },
    { lat: 48.536408, lng: 17.357582 },
    { lat: 48.53636, lng: 17.357376 },
    { lat: 48.536427, lng: 17.357148 },
    { lat: 48.53636, lng: 17.356972 },
    { lat: 48.536358, lng: 17.356846 },
    { lat: 48.536331, lng: 17.356819 },
    { lat: 48.5363689, lng: 17.3567231 },
    { lat: 48.536376, lng: 17.356414 },
    { lat: 48.536326, lng: 17.356413 },
    { lat: 48.536123, lng: 17.356323 },
    { lat: 48.535327, lng: 17.355953 },
    { lat: 48.535185, lng: 17.355865 },
    { lat: 48.534799, lng: 17.355544 },
    { lat: 48.534533, lng: 17.35537 },
    { lat: 48.534365, lng: 17.355211 },
    { lat: 48.533744, lng: 17.354776 },
    { lat: 48.533179, lng: 17.354285 },
    { lat: 48.533008, lng: 17.354304 },
    { lat: 48.532871, lng: 17.354358 },
    { lat: 48.532753, lng: 17.354331 },
    { lat: 48.532726, lng: 17.354278 },
    { lat: 48.532698, lng: 17.354319 },
    { lat: 48.532632, lng: 17.354357 },
    { lat: 48.532583, lng: 17.354426 },
    { lat: 48.532504, lng: 17.354393 },
    { lat: 48.532396, lng: 17.354325 },
    { lat: 48.532267, lng: 17.354444 },
    { lat: 48.532163, lng: 17.354429 },
    { lat: 48.532047, lng: 17.354553 },
    { lat: 48.532005, lng: 17.35472 },
    { lat: 48.531977, lng: 17.354793 },
    { lat: 48.531852, lng: 17.354849 },
    { lat: 48.531699, lng: 17.354777 },
    { lat: 48.531694, lng: 17.354557 },
    { lat: 48.531579, lng: 17.354535 },
    { lat: 48.531137, lng: 17.354851 },
    { lat: 48.530744, lng: 17.354964 },
    { lat: 48.530535, lng: 17.354889 },
    { lat: 48.530324, lng: 17.354885 },
    { lat: 48.530188, lng: 17.354781 },
    { lat: 48.530038, lng: 17.354876 },
    { lat: 48.529847, lng: 17.354975 },
    { lat: 48.529746, lng: 17.355062 },
    { lat: 48.52943, lng: 17.354828 },
    { lat: 48.52949, lng: 17.354695 },
    { lat: 48.529402, lng: 17.354596 },
    { lat: 48.529278, lng: 17.354548 },
    { lat: 48.529119, lng: 17.354418 },
    { lat: 48.528875, lng: 17.354452 },
    { lat: 48.528747, lng: 17.354438 },
    { lat: 48.528652, lng: 17.354502 },
    { lat: 48.528391, lng: 17.354541 },
    { lat: 48.528147, lng: 17.354587 },
    { lat: 48.527982, lng: 17.354738 },
    { lat: 48.52783, lng: 17.354964 },
    { lat: 48.527718, lng: 17.354989 },
    { lat: 48.527576, lng: 17.354999 },
    { lat: 48.527382, lng: 17.354855 },
    { lat: 48.527225, lng: 17.354672 },
    { lat: 48.527067, lng: 17.354471 },
    { lat: 48.526834, lng: 17.354184 },
    { lat: 48.526645, lng: 17.353958 },
    { lat: 48.526471, lng: 17.353701 },
    { lat: 48.526182, lng: 17.353236 },
    { lat: 48.525962, lng: 17.352945 },
    { lat: 48.52591, lng: 17.352678 },
    { lat: 48.525733, lng: 17.352473 },
    { lat: 48.525422, lng: 17.352442 },
    { lat: 48.525368, lng: 17.352373 },
    { lat: 48.524853, lng: 17.352113 },
    { lat: 48.524658, lng: 17.351943 },
    { lat: 48.524232, lng: 17.352001 },
    { lat: 48.523738, lng: 17.35231 },
    { lat: 48.522932, lng: 17.352411 },
    { lat: 48.52187, lng: 17.352818 },
    { lat: 48.521745, lng: 17.352808 },
    { lat: 48.521506, lng: 17.352973 },
    { lat: 48.5213, lng: 17.353116 },
    { lat: 48.5211, lng: 17.35316 },
    { lat: 48.520932, lng: 17.35312 },
    { lat: 48.520781, lng: 17.353049 },
    { lat: 48.520485, lng: 17.353089 },
    { lat: 48.520177, lng: 17.353182 },
    { lat: 48.520018, lng: 17.353258 },
    { lat: 48.519867, lng: 17.353185 },
    { lat: 48.519161, lng: 17.352853 },
    { lat: 48.518737, lng: 17.35267 },
    { lat: 48.518143, lng: 17.352534 },
    { lat: 48.517533, lng: 17.352552 },
    { lat: 48.517513, lng: 17.351805 },
    { lat: 48.517413, lng: 17.35129 },
    { lat: 48.51711, lng: 17.351096 },
    { lat: 48.517027, lng: 17.350855 },
    { lat: 48.516784, lng: 17.35059 },
    { lat: 48.516742, lng: 17.350346 },
    { lat: 48.516642, lng: 17.350019 },
    { lat: 48.516629, lng: 17.34975 },
    { lat: 48.516447, lng: 17.348922 },
    { lat: 48.516372, lng: 17.348163 },
    { lat: 48.516464, lng: 17.347839 },
    { lat: 48.516466, lng: 17.347598 },
    { lat: 48.516632, lng: 17.347223 },
    { lat: 48.51662, lng: 17.34696 },
    { lat: 48.516528, lng: 17.346692 },
    { lat: 48.516194, lng: 17.34658 },
    { lat: 48.516084, lng: 17.346337 },
    { lat: 48.515843, lng: 17.346233 },
    { lat: 48.5156, lng: 17.345837 },
    { lat: 48.515217, lng: 17.344845 },
    { lat: 48.515004, lng: 17.344707 },
    { lat: 48.514825, lng: 17.344368 },
    { lat: 48.514643, lng: 17.343771 },
    { lat: 48.514591, lng: 17.343525 },
    { lat: 48.514576, lng: 17.34338 },
    { lat: 48.514612, lng: 17.343213 },
    { lat: 48.514577, lng: 17.342862 },
    { lat: 48.514587, lng: 17.342662 },
    { lat: 48.514772, lng: 17.342358 },
    { lat: 48.514658, lng: 17.341329 },
    { lat: 48.514567, lng: 17.340898 },
    { lat: 48.514371, lng: 17.340646 },
    { lat: 48.514245, lng: 17.340215 },
    { lat: 48.514367, lng: 17.34005 },
    { lat: 48.514353, lng: 17.339666 },
    { lat: 48.514203, lng: 17.339525 },
    { lat: 48.514024, lng: 17.339089 },
    { lat: 48.513951, lng: 17.339073 },
    { lat: 48.513755, lng: 17.339374 },
    { lat: 48.513714, lng: 17.339598 },
    { lat: 48.513644, lng: 17.339881 },
    { lat: 48.513626, lng: 17.340351 },
    { lat: 48.513606, lng: 17.340562 },
    { lat: 48.51364, lng: 17.340926 },
    { lat: 48.513719, lng: 17.341038 },
    { lat: 48.513775, lng: 17.341309 },
    { lat: 48.51382, lng: 17.341379 },
    { lat: 48.513759, lng: 17.341528 },
    { lat: 48.513619, lng: 17.34176 },
    { lat: 48.513527, lng: 17.342004 },
    { lat: 48.51346, lng: 17.342252 },
    { lat: 48.513322, lng: 17.342843 },
    { lat: 48.513352, lng: 17.343144 },
    { lat: 48.513384, lng: 17.343293 },
    { lat: 48.513281, lng: 17.343607 },
    { lat: 48.513181, lng: 17.344011 },
    { lat: 48.513335, lng: 17.344245 },
    { lat: 48.513314, lng: 17.344436 },
    { lat: 48.513332, lng: 17.344749 },
    { lat: 48.513291, lng: 17.344868 },
    { lat: 48.513176, lng: 17.345064 },
    { lat: 48.513101, lng: 17.345247 },
    { lat: 48.512979, lng: 17.345498 },
    { lat: 48.512812, lng: 17.34567 },
    { lat: 48.512703, lng: 17.34596 },
    { lat: 48.512615, lng: 17.345968 },
    { lat: 48.512576, lng: 17.346096 },
    { lat: 48.512579, lng: 17.346163 },
    { lat: 48.512517, lng: 17.347108 },
    { lat: 48.512496, lng: 17.347937 },
    { lat: 48.512395, lng: 17.348988 },
    { lat: 48.5123764, lng: 17.3491739 },
    { lat: 48.512245, lng: 17.350489 },
    { lat: 48.512183, lng: 17.351224 },
    { lat: 48.511912, lng: 17.353439 },
    { lat: 48.511691, lng: 17.355219 },
    { lat: 48.51221, lng: 17.356533 },
    { lat: 48.512453, lng: 17.357694 },
    { lat: 48.512806, lng: 17.358337 },
    { lat: 48.513168, lng: 17.359729 },
    { lat: 48.513432, lng: 17.360507 },
    { lat: 48.5137736, lng: 17.361916 },
    { lat: 48.5138923, lng: 17.3631454 },
    { lat: 48.5140799, lng: 17.3639111 },
    { lat: 48.5142888, lng: 17.3654724 },
    { lat: 48.5143862, lng: 17.3658599 },
    { lat: 48.5143474, lng: 17.3669982 },
    { lat: 48.5139052, lng: 17.3680816 },
    { lat: 48.5139608, lng: 17.3692166 },
    { lat: 48.5140904, lng: 17.3697679 },
    { lat: 48.5144158, lng: 17.3707929 },
    { lat: 48.5146357, lng: 17.3717745 },
    { lat: 48.5147377, lng: 17.3725603 },
    { lat: 48.5152922, lng: 17.3746265 },
    { lat: 48.515409, lng: 17.3752249 },
    { lat: 48.5155435, lng: 17.3765297 },
    { lat: 48.5155349, lng: 17.3771947 },
    { lat: 48.5156356, lng: 17.3791817 },
    { lat: 48.5157111, lng: 17.3796662 },
    { lat: 48.5158331, lng: 17.3800137 },
    { lat: 48.5158524, lng: 17.3805186 },
    { lat: 48.5159877, lng: 17.3810341 },
    { lat: 48.5160615, lng: 17.3814935 },
    { lat: 48.5160677, lng: 17.3818503 },
    { lat: 48.5161463, lng: 17.3820878 },
    { lat: 48.5159616, lng: 17.38267 },
    { lat: 48.5160082, lng: 17.3829678 },
    { lat: 48.5159643, lng: 17.3831271 },
    { lat: 48.5160232, lng: 17.3832866 },
    { lat: 48.5160845, lng: 17.3844753 },
    { lat: 48.5162285, lng: 17.3850747 },
    { lat: 48.5162746, lng: 17.3856095 },
    { lat: 48.5165982, lng: 17.3871159 },
    { lat: 48.5166196, lng: 17.3873709 },
    { lat: 48.5166732, lng: 17.3880094 },
    { lat: 48.5167826, lng: 17.3883051 },
    { lat: 48.5170165, lng: 17.390549 },
    { lat: 48.5170916, lng: 17.3906306 },
    { lat: 48.517174, lng: 17.3924223 },
    { lat: 48.5179856, lng: 17.392763 },
    { lat: 48.5187971, lng: 17.393363 },
    { lat: 48.5189455, lng: 17.3937171 },
    { lat: 48.5191731, lng: 17.3940585 },
    { lat: 48.5192632, lng: 17.3941591 },
    { lat: 48.5199886, lng: 17.3948736 },
    { lat: 48.5209623, lng: 17.395137 },
    { lat: 48.5216127, lng: 17.3949428 },
    { lat: 48.5226529, lng: 17.395256 },
    { lat: 48.5232351, lng: 17.3952809 },
    { lat: 48.5235912, lng: 17.3951808 },
    { lat: 48.5236796, lng: 17.3959535 },
    { lat: 48.5237606, lng: 17.3961586 },
    { lat: 48.52372, lng: 17.3964518 },
    { lat: 48.5240291, lng: 17.3991329 },
    { lat: 48.524134, lng: 17.3995825 },
    { lat: 48.5240973, lng: 17.3999919 },
    { lat: 48.5242027, lng: 17.4004532 },
    { lat: 48.524164, lng: 17.4007803 },
    { lat: 48.5243258, lng: 17.4012361 },
    { lat: 48.5243558, lng: 17.4017683 },
    { lat: 48.5244429, lng: 17.4020495 },
    { lat: 48.5245019, lng: 17.4027713 },
    { lat: 48.5246019, lng: 17.4031792 },
    { lat: 48.5245117, lng: 17.4035844 },
    { lat: 48.5245246, lng: 17.4040603 },
    { lat: 48.524971, lng: 17.4070673 },
    { lat: 48.5248826, lng: 17.4077459 },
    { lat: 48.5247016, lng: 17.4084562 },
    { lat: 48.5244175, lng: 17.40911 },
    { lat: 48.5245292, lng: 17.4094054 },
    { lat: 48.5245256, lng: 17.4096457 },
    { lat: 48.5246844, lng: 17.4099167 },
    { lat: 48.5247056, lng: 17.4100871 },
    { lat: 48.5247351, lng: 17.4103853 },
    { lat: 48.5246647, lng: 17.4111489 },
    { lat: 48.5250131, lng: 17.4121179 },
    { lat: 48.5251648, lng: 17.4123348 },
    { lat: 48.5254942, lng: 17.4134613 },
    { lat: 48.525524, lng: 17.4137648 },
    { lat: 48.5257141, lng: 17.413981 },
    { lat: 48.5256385, lng: 17.4143669 },
    { lat: 48.5258185, lng: 17.4156286 },
    { lat: 48.5258522, lng: 17.4167799 },
    { lat: 48.5258021, lng: 17.4172829 },
    { lat: 48.5258081, lng: 17.4176832 },
    { lat: 48.5258385, lng: 17.4178089 },
    { lat: 48.5259839, lng: 17.4183819 },
    { lat: 48.5261949, lng: 17.4188858 },
    { lat: 48.5260401, lng: 17.4192851 },
    { lat: 48.5256247, lng: 17.4208457 },
    { lat: 48.5255072, lng: 17.4217565 },
    { lat: 48.5254876, lng: 17.4225697 },
    { lat: 48.5252737, lng: 17.4237885 },
    { lat: 48.5252193, lng: 17.4248515 },
    { lat: 48.5254136, lng: 17.4263951 },
    { lat: 48.525376, lng: 17.426556 },
    { lat: 48.5253154, lng: 17.4268155 },
    { lat: 48.5252332, lng: 17.4271673 },
    { lat: 48.5247975, lng: 17.4282394 },
    { lat: 48.524732, lng: 17.4289042 },
    { lat: 48.5244974, lng: 17.4293468 },
    { lat: 48.5242365, lng: 17.4296789 },
    { lat: 48.5241823, lng: 17.4298441 },
    { lat: 48.5240929, lng: 17.4301165 },
    { lat: 48.5239614, lng: 17.430374 },
    { lat: 48.523785, lng: 17.4307194 },
    { lat: 48.5235835, lng: 17.4321117 },
    { lat: 48.5236254, lng: 17.43234 },
    { lat: 48.5239074, lng: 17.4330157 },
    { lat: 48.5243574, lng: 17.4336044 },
    { lat: 48.5245042, lng: 17.4339689 },
    { lat: 48.5249197, lng: 17.434056 },
    { lat: 48.5251562, lng: 17.4343107 },
    { lat: 48.525937, lng: 17.4364692 },
    { lat: 48.525895, lng: 17.4369378 },
    { lat: 48.5260529, lng: 17.4376972 },
    { lat: 48.5261034, lng: 17.4377624 },
    { lat: 48.5262174, lng: 17.4379807 },
    { lat: 48.5265779, lng: 17.437954 },
    { lat: 48.5267783, lng: 17.4378339 },
    { lat: 48.5272451, lng: 17.4378758 },
    { lat: 48.5275168, lng: 17.4379002 },
    { lat: 48.5280432, lng: 17.4383233 },
    { lat: 48.5281934, lng: 17.4391643 },
    { lat: 48.5284257, lng: 17.4398088 },
    { lat: 48.5285526, lng: 17.4399895 },
    { lat: 48.5288621, lng: 17.4403412 },
    { lat: 48.5294917, lng: 17.4403124 },
    { lat: 48.5297626, lng: 17.4405899 },
    { lat: 48.5297137, lng: 17.4411829 },
    { lat: 48.5294565, lng: 17.4419224 },
    { lat: 48.5293793, lng: 17.4423046 },
    { lat: 48.5291675, lng: 17.4427853 },
    { lat: 48.529006, lng: 17.4433291 },
    { lat: 48.5287258, lng: 17.4439066 },
    { lat: 48.5287837, lng: 17.4444184 },
    { lat: 48.528621, lng: 17.4455756 },
    { lat: 48.528669, lng: 17.4463397 },
    { lat: 48.5288905, lng: 17.4464834 },
    { lat: 48.5292907, lng: 17.4465558 },
    { lat: 48.5297784, lng: 17.4463784 },
    { lat: 48.5301611, lng: 17.4463226 },
    { lat: 48.5305023, lng: 17.4471169 },
    { lat: 48.5322093, lng: 17.4453316 },
    { lat: 48.5324963, lng: 17.4451241 },
    { lat: 48.5332043, lng: 17.4448939 },
    { lat: 48.5339444, lng: 17.4444423 },
    { lat: 48.5342372, lng: 17.4441487 },
    { lat: 48.5346667, lng: 17.4440122 },
    { lat: 48.5351859, lng: 17.4436912 },
    { lat: 48.5355393, lng: 17.4430591 },
    { lat: 48.5357243, lng: 17.4425991 },
    { lat: 48.5359486, lng: 17.4422738 },
    { lat: 48.5362834, lng: 17.4419868 },
    { lat: 48.5364663, lng: 17.4420692 },
    { lat: 48.5367281, lng: 17.4414892 },
    { lat: 48.5373797, lng: 17.4404169 },
    { lat: 48.537436, lng: 17.4400213 },
    { lat: 48.5375154, lng: 17.4396141 },
    { lat: 48.5376273, lng: 17.4394744 },
    { lat: 48.5380096, lng: 17.4392543 },
    { lat: 48.5380653, lng: 17.4393473 },
    { lat: 48.5382678, lng: 17.4393901 },
    { lat: 48.5383716, lng: 17.4391614 },
    { lat: 48.5386648, lng: 17.4390983 },
    { lat: 48.5390374, lng: 17.4388532 },
    { lat: 48.5395155, lng: 17.4380852 },
    { lat: 48.5396002, lng: 17.437736 },
    { lat: 48.5399537, lng: 17.4373671 },
    { lat: 48.5402027, lng: 17.4367099 },
    { lat: 48.5405426, lng: 17.436673 },
    { lat: 48.5406221, lng: 17.4367687 },
    { lat: 48.540803, lng: 17.4366307 },
    { lat: 48.5415103, lng: 17.4368456 },
    { lat: 48.5415523, lng: 17.4367076 },
    { lat: 48.5418276, lng: 17.4364913 },
    { lat: 48.5422052, lng: 17.4364115 },
    { lat: 48.5425042, lng: 17.4364248 },
    { lat: 48.542946, lng: 17.4361806 },
    { lat: 48.543281, lng: 17.4360875 },
    { lat: 48.5433952, lng: 17.435967 },
    { lat: 48.5434758, lng: 17.4359877 },
    { lat: 48.5435685, lng: 17.4359025 },
    { lat: 48.5436508, lng: 17.435883 },
    { lat: 48.5437252, lng: 17.4359512 },
    { lat: 48.5438619, lng: 17.4358468 },
    { lat: 48.5443042, lng: 17.4357347 },
    { lat: 48.5445918, lng: 17.435589 },
    { lat: 48.5447779, lng: 17.4356006 },
    { lat: 48.5447573, lng: 17.4355281 },
    { lat: 48.5463681, lng: 17.4338486 },
    { lat: 48.5465714, lng: 17.4333569 },
    { lat: 48.5463174, lng: 17.4330632 },
    { lat: 48.5470364, lng: 17.4319494 },
    { lat: 48.5474773, lng: 17.4321322 },
    { lat: 48.5476279, lng: 17.4321562 },
    { lat: 48.5480384, lng: 17.4320409 },
    { lat: 48.5481094, lng: 17.4323011 },
    { lat: 48.5482109, lng: 17.432231 },
    { lat: 48.5482789, lng: 17.4325468 },
    { lat: 48.5483366, lng: 17.4326207 },
    { lat: 48.5483591, lng: 17.4324627 },
    { lat: 48.5482678, lng: 17.431868 },
    { lat: 48.5484691, lng: 17.4317134 },
    { lat: 48.5487082, lng: 17.4313569 },
    { lat: 48.5488137, lng: 17.4312801 },
    { lat: 48.5488236, lng: 17.4309596 },
    { lat: 48.5487847, lng: 17.4308812 },
    { lat: 48.548915, lng: 17.4303987 },
    { lat: 48.5490797, lng: 17.430185 },
    { lat: 48.5490899, lng: 17.4298523 },
    { lat: 48.549199, lng: 17.4295651 },
    { lat: 48.5494876, lng: 17.4293981 },
    { lat: 48.5497707, lng: 17.4289866 },
    { lat: 48.5498169, lng: 17.4287792 },
    { lat: 48.5498918, lng: 17.4287297 },
    { lat: 48.5498851, lng: 17.4282398 },
    { lat: 48.5500056, lng: 17.4280796 },
    { lat: 48.5500316, lng: 17.4278697 },
    { lat: 48.5501359, lng: 17.4276623 },
    { lat: 48.5501204, lng: 17.4274928 },
    { lat: 48.5501654, lng: 17.4274741 },
    { lat: 48.5502014, lng: 17.4276467 },
    { lat: 48.5503033, lng: 17.4276853 },
    { lat: 48.5504171, lng: 17.4275053 },
    { lat: 48.5503989, lng: 17.4272752 },
    { lat: 48.5504935, lng: 17.4272738 },
    { lat: 48.5505925, lng: 17.4270733 },
    { lat: 48.5507915, lng: 17.4269547 },
    { lat: 48.5512132, lng: 17.4263664 },
    { lat: 48.5512623, lng: 17.4263389 },
    { lat: 48.5513595, lng: 17.4265136 },
    { lat: 48.5514965, lng: 17.4262935 },
    { lat: 48.5515894, lng: 17.4260161 },
    { lat: 48.5516768, lng: 17.4260579 },
    { lat: 48.5517823, lng: 17.4259252 },
    { lat: 48.5518774, lng: 17.4258941 },
    { lat: 48.5519572, lng: 17.4260279 },
    { lat: 48.5523394, lng: 17.4259823 },
    { lat: 48.5524534, lng: 17.4258565 },
    { lat: 48.5526145, lng: 17.4258518 },
    { lat: 48.5529027, lng: 17.4263283 },
    { lat: 48.5529777, lng: 17.4262978 },
    { lat: 48.5532005, lng: 17.4265671 },
    { lat: 48.5534503, lng: 17.426466 },
    { lat: 48.5538213, lng: 17.4264901 },
    { lat: 48.5538735, lng: 17.4264076 },
    { lat: 48.5542126, lng: 17.4264114 },
    { lat: 48.554305, lng: 17.426328 },
    { lat: 48.554328, lng: 17.426299 },
    { lat: 48.554343, lng: 17.426207 },
    { lat: 48.554386, lng: 17.426164 },
    { lat: 48.554431, lng: 17.426184 },
    { lat: 48.554545, lng: 17.426034 },
    { lat: 48.554652, lng: 17.426011 },
    { lat: 48.55468, lng: 17.426015 },
    { lat: 48.554686, lng: 17.426062 },
    { lat: 48.554702, lng: 17.426072 },
    { lat: 48.554736, lng: 17.426018 },
    { lat: 48.554752, lng: 17.426013 },
    { lat: 48.554796, lng: 17.426046 },
    { lat: 48.554854, lng: 17.425969 },
    { lat: 48.554909, lng: 17.425951 },
    { lat: 48.554965, lng: 17.425827 },
    { lat: 48.555009, lng: 17.42582 },
    { lat: 48.555022, lng: 17.425789 },
    { lat: 48.55508, lng: 17.425751 },
    { lat: 48.555109, lng: 17.425795 },
    { lat: 48.555155, lng: 17.425758 },
    { lat: 48.555297, lng: 17.425742 },
    { lat: 48.555481, lng: 17.425591 },
    { lat: 48.555668, lng: 17.425543 },
    { lat: 48.555715, lng: 17.425608 },
    { lat: 48.555819, lng: 17.425596 },
    { lat: 48.55593, lng: 17.425673 },
    { lat: 48.556027, lng: 17.425713 },
    { lat: 48.556055, lng: 17.425748 },
    { lat: 48.556071, lng: 17.425844 },
    { lat: 48.556099, lng: 17.425908 },
    { lat: 48.556129, lng: 17.425933 },
    { lat: 48.556338, lng: 17.425992 },
    { lat: 48.556408, lng: 17.426117 },
    { lat: 48.556557, lng: 17.426279 },
    { lat: 48.556585, lng: 17.42645 },
    { lat: 48.556883, lng: 17.426606 },
    { lat: 48.557039, lng: 17.426678 },
    { lat: 48.557147, lng: 17.426607 },
    { lat: 48.557178, lng: 17.426571 },
    { lat: 48.557217, lng: 17.426488 },
    { lat: 48.557292, lng: 17.426268 },
    { lat: 48.557354, lng: 17.426166 },
    { lat: 48.557475, lng: 17.425952 },
    { lat: 48.557615, lng: 17.425721 },
    { lat: 48.557706, lng: 17.425495 },
    { lat: 48.557752, lng: 17.425239 },
    { lat: 48.557808, lng: 17.425115 },
    { lat: 48.557902, lng: 17.42492 },
    { lat: 48.557998, lng: 17.42486 },
    { lat: 48.558033, lng: 17.424744 },
    { lat: 48.558066, lng: 17.424557 },
    { lat: 48.55811, lng: 17.424289 },
    { lat: 48.558207, lng: 17.424059 },
    { lat: 48.558206, lng: 17.423853 },
    { lat: 48.558223, lng: 17.423652 },
    { lat: 48.558268, lng: 17.423379 },
    { lat: 48.558304, lng: 17.423187 },
    { lat: 48.558413, lng: 17.42287 },
    { lat: 48.558423, lng: 17.422359 },
    { lat: 48.558433, lng: 17.4219 },
    { lat: 48.558342, lng: 17.421757 },
    { lat: 48.558224, lng: 17.421576 },
    { lat: 48.55824, lng: 17.421388 },
    { lat: 48.558264, lng: 17.421218 },
    { lat: 48.558288, lng: 17.421016 },
    { lat: 48.558228, lng: 17.420707 },
    { lat: 48.558225, lng: 17.420627 },
    { lat: 48.558206, lng: 17.420419 },
    { lat: 48.558198, lng: 17.420183 },
    { lat: 48.558164, lng: 17.420097 },
    { lat: 48.558192, lng: 17.419776 },
    { lat: 48.558175, lng: 17.419609 },
    { lat: 48.558159, lng: 17.419318 },
    { lat: 48.558163, lng: 17.419072 },
    { lat: 48.558102, lng: 17.418924 },
    { lat: 48.558078, lng: 17.418737 },
    { lat: 48.558103, lng: 17.41855 },
    { lat: 48.558094, lng: 17.41842 },
    { lat: 48.558118, lng: 17.418205 },
    { lat: 48.558075, lng: 17.418037 },
    { lat: 48.558075, lng: 17.417872 },
    { lat: 48.558074, lng: 17.417819 },
    { lat: 48.558073, lng: 17.417757 },
    { lat: 48.558035, lng: 17.417721 },
    { lat: 48.557953, lng: 17.417679 },
    { lat: 48.557943, lng: 17.417646 },
    { lat: 48.558018, lng: 17.417408 },
    { lat: 48.558032, lng: 17.417351 },
    { lat: 48.55803, lng: 17.416981 },
    { lat: 48.55797, lng: 17.416807 },
    { lat: 48.558012, lng: 17.416659 },
    { lat: 48.558034, lng: 17.416545 },
    { lat: 48.558055, lng: 17.416486 },
    { lat: 48.558111, lng: 17.416269 },
    { lat: 48.55811, lng: 17.416205 },
    { lat: 48.558162, lng: 17.416096 },
    { lat: 48.558175, lng: 17.416032 },
    { lat: 48.558185, lng: 17.415958 },
    { lat: 48.55823, lng: 17.415891 },
    { lat: 48.558265, lng: 17.41568 },
    { lat: 48.558327, lng: 17.415514 },
    { lat: 48.558329, lng: 17.415395 },
    { lat: 48.558486, lng: 17.414996 },
    { lat: 48.558513, lng: 17.414852 },
    { lat: 48.558617, lng: 17.414659 },
    { lat: 48.558645, lng: 17.414395 },
    { lat: 48.558812, lng: 17.414085 },
    { lat: 48.558937, lng: 17.413864 },
    { lat: 48.558953, lng: 17.41367 },
    { lat: 48.558975, lng: 17.413366 },
    { lat: 48.559051, lng: 17.413213 },
    { lat: 48.559127, lng: 17.413082 },
    { lat: 48.559212, lng: 17.412896 },
    { lat: 48.559274, lng: 17.412645 },
    { lat: 48.559391, lng: 17.412509 },
    { lat: 48.5597, lng: 17.41204 },
    { lat: 48.559802, lng: 17.411836 },
    { lat: 48.559785, lng: 17.41162 },
    { lat: 48.559731, lng: 17.411469 },
    { lat: 48.55971, lng: 17.4113 },
    { lat: 48.559679, lng: 17.411202 },
    { lat: 48.559667, lng: 17.411018 },
    { lat: 48.55962, lng: 17.410827 },
    { lat: 48.559531, lng: 17.410646 },
    { lat: 48.559444, lng: 17.410505 },
    { lat: 48.559353, lng: 17.410214 },
    { lat: 48.559351, lng: 17.40995 },
    { lat: 48.559359, lng: 17.409541 },
    { lat: 48.55939, lng: 17.409341 },
    { lat: 48.559497, lng: 17.40898 },
    { lat: 48.559447, lng: 17.408635 },
    { lat: 48.559613, lng: 17.408397 },
    { lat: 48.559632, lng: 17.408262 },
    { lat: 48.559658, lng: 17.408118 },
    { lat: 48.559724, lng: 17.408003 },
    { lat: 48.559746, lng: 17.407949 },
    { lat: 48.559804, lng: 17.407882 },
    { lat: 48.559882, lng: 17.407827 },
    { lat: 48.560006, lng: 17.407853 },
    { lat: 48.560134, lng: 17.407988 },
    { lat: 48.560541, lng: 17.407686 },
    { lat: 48.560745, lng: 17.407613 },
    { lat: 48.560867, lng: 17.407536 },
    { lat: 48.560918, lng: 17.407456 },
    { lat: 48.561227, lng: 17.406606 },
    { lat: 48.561179, lng: 17.406515 },
    { lat: 48.561402, lng: 17.406123 },
    { lat: 48.561508, lng: 17.405935 },
    { lat: 48.562123, lng: 17.405003 },
    { lat: 48.561822, lng: 17.404946 },
    { lat: 48.561699, lng: 17.404827 },
    { lat: 48.561703, lng: 17.404698 },
    { lat: 48.561669, lng: 17.404573 },
    { lat: 48.561575, lng: 17.404513 },
    { lat: 48.561498, lng: 17.404539 },
    { lat: 48.561661, lng: 17.404274 },
    { lat: 48.561782, lng: 17.404048 },
    { lat: 48.561905, lng: 17.403669 },
    { lat: 48.561869, lng: 17.403607 },
    { lat: 48.561861, lng: 17.403556 },
    { lat: 48.561844, lng: 17.403457 },
    { lat: 48.561837, lng: 17.403348 },
    { lat: 48.561839, lng: 17.40319 },
    { lat: 48.561845, lng: 17.403032 },
    { lat: 48.561875, lng: 17.402842 },
    { lat: 48.561879, lng: 17.402816 },
    { lat: 48.561912, lng: 17.40272 },
    { lat: 48.561904, lng: 17.402318 },
    { lat: 48.56185, lng: 17.40208 },
    { lat: 48.561856, lng: 17.401992 },
    { lat: 48.561879, lng: 17.401804 },
    { lat: 48.561859, lng: 17.401587 },
    { lat: 48.561781, lng: 17.401039 },
    { lat: 48.561776, lng: 17.400903 },
    { lat: 48.56176, lng: 17.400511 },
    { lat: 48.561768, lng: 17.400092 },
    { lat: 48.561771, lng: 17.400082 },
    { lat: 48.561715, lng: 17.399511 },
    { lat: 48.561711, lng: 17.399477 },
    { lat: 48.561719, lng: 17.399477 },
    { lat: 48.561685, lng: 17.399137 },
    { lat: 48.561644, lng: 17.399071 },
    { lat: 48.561543, lng: 17.398951 },
    { lat: 48.561463, lng: 17.398866 },
    { lat: 48.561376, lng: 17.398774 },
    { lat: 48.561283, lng: 17.398583 },
    { lat: 48.561197, lng: 17.398294 },
    { lat: 48.561457, lng: 17.398086 },
    { lat: 48.561648, lng: 17.397963 },
    { lat: 48.561821, lng: 17.397764 },
    { lat: 48.562114, lng: 17.39755 },
    { lat: 48.561865, lng: 17.396849 },
    { lat: 48.561726, lng: 17.396548 },
    { lat: 48.561572, lng: 17.396279 },
    { lat: 48.561564, lng: 17.396266 },
    { lat: 48.561742, lng: 17.395859 },
    { lat: 48.561952, lng: 17.39531 },
    { lat: 48.562084, lng: 17.394878 },
    { lat: 48.562369, lng: 17.394185 },
    { lat: 48.562441, lng: 17.393763 },
    { lat: 48.562479, lng: 17.393514 },
    { lat: 48.562507, lng: 17.393289 },
    { lat: 48.562589, lng: 17.392987 },
    { lat: 48.562675, lng: 17.39268 },
    { lat: 48.562637, lng: 17.392466 },
    { lat: 48.562521, lng: 17.392243 },
    { lat: 48.562495, lng: 17.39206 },
    { lat: 48.562366, lng: 17.391073 },
    { lat: 48.562382, lng: 17.391082 },
    { lat: 48.562516, lng: 17.391153 },
    { lat: 48.562581, lng: 17.391165 },
    { lat: 48.562627, lng: 17.391036 },
    { lat: 48.562622, lng: 17.390904 },
    { lat: 48.562689, lng: 17.390684 },
    { lat: 48.56276, lng: 17.390531 },
    { lat: 48.562856, lng: 17.390264 },
    { lat: 48.562872, lng: 17.390158 },
    { lat: 48.562939, lng: 17.38997 },
    { lat: 48.562868, lng: 17.389822 },
    { lat: 48.562702, lng: 17.389459 },
    { lat: 48.562561, lng: 17.389149 },
    { lat: 48.562447, lng: 17.388903 },
    { lat: 48.562328, lng: 17.388691 },
    { lat: 48.562331, lng: 17.388596 },
    { lat: 48.562092, lng: 17.388202 },
    { lat: 48.561936, lng: 17.387825 },
    { lat: 48.561817, lng: 17.387545 },
    { lat: 48.561731, lng: 17.387373 },
    { lat: 48.561669, lng: 17.387221 },
    { lat: 48.561497, lng: 17.386881 },
    { lat: 48.561372, lng: 17.386615 },
    { lat: 48.561227, lng: 17.386302 },
    { lat: 48.56108, lng: 17.385991 },
    { lat: 48.560979, lng: 17.385812 },
    { lat: 48.560836, lng: 17.385583 },
    { lat: 48.56042, lng: 17.385635 },
    { lat: 48.560415, lng: 17.385554 },
    { lat: 48.560371, lng: 17.384705 },
    { lat: 48.560289, lng: 17.384132 },
    { lat: 48.560268, lng: 17.38397 },
    { lat: 48.560214, lng: 17.38383 },
    { lat: 48.560207, lng: 17.383778 },
    { lat: 48.560107, lng: 17.383445 },
    { lat: 48.560161, lng: 17.383258 },
    { lat: 48.560264, lng: 17.382817 },
    { lat: 48.560257, lng: 17.382668 },
    { lat: 48.560172, lng: 17.382388 },
    { lat: 48.559797, lng: 17.382479 },
    { lat: 48.559592, lng: 17.382593 },
    { lat: 48.559483, lng: 17.382566 },
    { lat: 48.559242, lng: 17.382541 },
    { lat: 48.559161, lng: 17.382176 },
    { lat: 48.558823, lng: 17.38183 },
    { lat: 48.55888, lng: 17.381357 },
  ],
  Opoj: [
    { lat: 48.3228112, lng: 17.6219028 },
    { lat: 48.3210023, lng: 17.6210116 },
    { lat: 48.3192912, lng: 17.6212007 },
    { lat: 48.3190533, lng: 17.6205093 },
    { lat: 48.3189919, lng: 17.6186927 },
    { lat: 48.3187313, lng: 17.6182862 },
    { lat: 48.3185364, lng: 17.6181673 },
    { lat: 48.3182257, lng: 17.6180964 },
    { lat: 48.3178319, lng: 17.6177237 },
    { lat: 48.3176412, lng: 17.6172598 },
    { lat: 48.317098, lng: 17.6163127 },
    { lat: 48.3169661, lng: 17.615778 },
    { lat: 48.3165526, lng: 17.6151661 },
    { lat: 48.3167063, lng: 17.6148829 },
    { lat: 48.3172175, lng: 17.6152708 },
    { lat: 48.3178642, lng: 17.6137078 },
    { lat: 48.317637, lng: 17.6135275 },
    { lat: 48.3174496, lng: 17.6135463 },
    { lat: 48.3173227, lng: 17.6137353 },
    { lat: 48.3171775, lng: 17.613707 },
    { lat: 48.3167431, lng: 17.6134079 },
    { lat: 48.3165244, lng: 17.6131108 },
    { lat: 48.3158917, lng: 17.6126766 },
    { lat: 48.3156936, lng: 17.61256 },
    { lat: 48.3156366, lng: 17.6125681 },
    { lat: 48.3156486, lng: 17.6128328 },
    { lat: 48.3154479, lng: 17.6137663 },
    { lat: 48.3154035, lng: 17.6138158 },
    { lat: 48.3149826, lng: 17.6136925 },
    { lat: 48.3145006, lng: 17.6139813 },
    { lat: 48.3143731, lng: 17.6138589 },
    { lat: 48.3143302, lng: 17.6135542 },
    { lat: 48.314281, lng: 17.6135424 },
    { lat: 48.3141415, lng: 17.61368 },
    { lat: 48.3141038, lng: 17.6136364 },
    { lat: 48.314102, lng: 17.6134809 },
    { lat: 48.3140223, lng: 17.6134362 },
    { lat: 48.313898, lng: 17.6134886 },
    { lat: 48.3136529, lng: 17.6133279 },
    { lat: 48.3105367, lng: 17.6208273 },
    { lat: 48.3104689, lng: 17.6216308 },
    { lat: 48.3102856, lng: 17.6224683 },
    { lat: 48.3069324, lng: 17.6294256 },
    { lat: 48.3023093, lng: 17.6315889 },
    { lat: 48.297568, lng: 17.6311812 },
    { lat: 48.2957026, lng: 17.6303708 },
    { lat: 48.2959031, lng: 17.6314381 },
    { lat: 48.295965, lng: 17.6315429 },
    { lat: 48.2957031, lng: 17.6320281 },
    { lat: 48.2955192, lng: 17.6321201 },
    { lat: 48.2952765, lng: 17.6321031 },
    { lat: 48.2950657, lng: 17.6319492 },
    { lat: 48.2949361, lng: 17.6320438 },
    { lat: 48.2943191, lng: 17.6322485 },
    { lat: 48.2938816, lng: 17.6326026 },
    { lat: 48.293543, lng: 17.6327441 },
    { lat: 48.2933745, lng: 17.6327062 },
    { lat: 48.2930597, lng: 17.632855 },
    { lat: 48.2918348, lng: 17.6331982 },
    { lat: 48.2930151, lng: 17.6396765 },
    { lat: 48.2929696, lng: 17.6398857 },
    { lat: 48.291476, lng: 17.6438933 },
    { lat: 48.2905215, lng: 17.6451414 },
    { lat: 48.2903647, lng: 17.6454139 },
    { lat: 48.2902784, lng: 17.6457636 },
    { lat: 48.290249, lng: 17.6465198 },
    { lat: 48.2901011, lng: 17.6474189 },
    { lat: 48.2901292, lng: 17.6477043 },
    { lat: 48.2903271, lng: 17.6482288 },
    { lat: 48.2905428, lng: 17.6493404 },
    { lat: 48.2909766, lng: 17.6504407 },
    { lat: 48.2916464, lng: 17.6516102 },
    { lat: 48.2924031, lng: 17.6526203 },
    { lat: 48.2930955, lng: 17.6543313 },
    { lat: 48.2932596, lng: 17.6552701 },
    { lat: 48.2932835, lng: 17.6564081 },
    { lat: 48.2933702, lng: 17.65696 },
    { lat: 48.2932871, lng: 17.6577774 },
    { lat: 48.2932762, lng: 17.6585047 },
    { lat: 48.2935203, lng: 17.6600187 },
    { lat: 48.2936091, lng: 17.6610182 },
    { lat: 48.2951994, lng: 17.660182 },
    { lat: 48.2960578, lng: 17.6595713 },
    { lat: 48.2983143, lng: 17.6573121 },
    { lat: 48.3011124, lng: 17.6542587 },
    { lat: 48.3020108, lng: 17.6531031 },
    { lat: 48.3025153, lng: 17.6523085 },
    { lat: 48.3034544, lng: 17.6511857 },
    { lat: 48.304289, lng: 17.6499855 },
    { lat: 48.3053787, lng: 17.6479725 },
    { lat: 48.3059935, lng: 17.6471391 },
    { lat: 48.3061008, lng: 17.6471941 },
    { lat: 48.3065305, lng: 17.6465379 },
    { lat: 48.3075422, lng: 17.6458034 },
    { lat: 48.3079407, lng: 17.6454247 },
    { lat: 48.3082374, lng: 17.6450398 },
    { lat: 48.3084805, lng: 17.6444546 },
    { lat: 48.3085525, lng: 17.644072 },
    { lat: 48.308545, lng: 17.6435761 },
    { lat: 48.3083754, lng: 17.6428031 },
    { lat: 48.3083865, lng: 17.6421709 },
    { lat: 48.3077384, lng: 17.6420458 },
    { lat: 48.3084751, lng: 17.6407247 },
    { lat: 48.3088512, lng: 17.640208 },
    { lat: 48.3094611, lng: 17.6395815 },
    { lat: 48.3103734, lng: 17.638305 },
    { lat: 48.3117103, lng: 17.635972 },
    { lat: 48.3122722, lng: 17.6351391 },
    { lat: 48.3142436, lng: 17.6324859 },
    { lat: 48.3150742, lng: 17.6311334 },
    { lat: 48.3161299, lng: 17.6297693 },
    { lat: 48.3171385, lng: 17.6281229 },
    { lat: 48.3179193, lng: 17.6270399 },
    { lat: 48.3188898, lng: 17.6259204 },
    { lat: 48.3207819, lng: 17.6242285 },
    { lat: 48.322791, lng: 17.6219234 },
    { lat: 48.3228112, lng: 17.6219028 },
  ],
  Šelpice: [
    { lat: 48.4012614, lng: 17.5458333 },
    { lat: 48.4035936, lng: 17.5496343 },
    { lat: 48.4051892, lng: 17.5520816 },
    { lat: 48.4053177, lng: 17.5520785 },
    { lat: 48.4053365, lng: 17.5523921 },
    { lat: 48.405467, lng: 17.5529186 },
    { lat: 48.406305, lng: 17.5547336 },
    { lat: 48.4064255, lng: 17.5549758 },
    { lat: 48.4064936, lng: 17.5550161 },
    { lat: 48.4069101, lng: 17.5549049 },
    { lat: 48.4072266, lng: 17.5543953 },
    { lat: 48.4074973, lng: 17.5543362 },
    { lat: 48.4080722, lng: 17.5539447 },
    { lat: 48.4082181, lng: 17.5537925 },
    { lat: 48.4083711, lng: 17.5535862 },
    { lat: 48.4084816, lng: 17.5534131 },
    { lat: 48.4095336, lng: 17.5535388 },
    { lat: 48.4097833, lng: 17.5536658 },
    { lat: 48.4102411, lng: 17.5535304 },
    { lat: 48.4104229, lng: 17.5538861 },
    { lat: 48.4105268, lng: 17.5539231 },
    { lat: 48.4106395, lng: 17.5537932 },
    { lat: 48.4107903, lng: 17.5537691 },
    { lat: 48.411021, lng: 17.5539507 },
    { lat: 48.4113928, lng: 17.5533332 },
    { lat: 48.411967, lng: 17.5535164 },
    { lat: 48.4122997, lng: 17.5531218 },
    { lat: 48.4124421, lng: 17.5532108 },
    { lat: 48.4125268, lng: 17.5534777 },
    { lat: 48.4126611, lng: 17.5535999 },
    { lat: 48.4128135, lng: 17.5533168 },
    { lat: 48.4131584, lng: 17.553129 },
    { lat: 48.4133954, lng: 17.5527879 },
    { lat: 48.4133911, lng: 17.5524377 },
    { lat: 48.4134309, lng: 17.5522631 },
    { lat: 48.4136583, lng: 17.5520734 },
    { lat: 48.4141301, lng: 17.5510552 },
    { lat: 48.4142029, lng: 17.5508938 },
    { lat: 48.4143844, lng: 17.5506891 },
    { lat: 48.414603, lng: 17.5504678 },
    { lat: 48.4149671, lng: 17.5502393 },
    { lat: 48.4153953, lng: 17.5500151 },
    { lat: 48.4156134, lng: 17.5499099 },
    { lat: 48.416047, lng: 17.5499301 },
    { lat: 48.416296, lng: 17.5497771 },
    { lat: 48.4166627, lng: 17.5493106 },
    { lat: 48.4166952, lng: 17.5492907 },
    { lat: 48.4171633, lng: 17.5487666 },
    { lat: 48.4175462, lng: 17.5482524 },
    { lat: 48.4179737, lng: 17.5477442 },
    { lat: 48.4181375, lng: 17.5473948 },
    { lat: 48.418304, lng: 17.5472015 },
    { lat: 48.4185101, lng: 17.5471065 },
    { lat: 48.4188973, lng: 17.5468558 },
    { lat: 48.4193455, lng: 17.5468703 },
    { lat: 48.4192878, lng: 17.5466578 },
    { lat: 48.4199223, lng: 17.5451976 },
    { lat: 48.4202634, lng: 17.5444735 },
    { lat: 48.4206803, lng: 17.5438885 },
    { lat: 48.4219656, lng: 17.5427564 },
    { lat: 48.4228654, lng: 17.5417937 },
    { lat: 48.4240398, lng: 17.5407453 },
    { lat: 48.4244845, lng: 17.5398203 },
    { lat: 48.4245941, lng: 17.5397494 },
    { lat: 48.4246945, lng: 17.5396944 },
    { lat: 48.4252728, lng: 17.5397649 },
    { lat: 48.4274362, lng: 17.5400288 },
    { lat: 48.427633, lng: 17.5403501 },
    { lat: 48.4307538, lng: 17.5375919 },
    { lat: 48.4327566, lng: 17.5390001 },
    { lat: 48.4332183, lng: 17.5387701 },
    { lat: 48.4333831, lng: 17.5384858 },
    { lat: 48.4339706, lng: 17.5369461 },
    { lat: 48.4338457, lng: 17.5360985 },
    { lat: 48.4339348, lng: 17.5354765 },
    { lat: 48.4338608, lng: 17.5346344 },
    { lat: 48.4336491, lng: 17.5341684 },
    { lat: 48.4337791, lng: 17.5337983 },
    { lat: 48.4341178, lng: 17.5335131 },
    { lat: 48.4344589, lng: 17.5333545 },
    { lat: 48.4345752, lng: 17.5331748 },
    { lat: 48.4345059, lng: 17.5329743 },
    { lat: 48.434125, lng: 17.532597 },
    { lat: 48.4340895, lng: 17.532392 },
    { lat: 48.4341144, lng: 17.5322184 },
    { lat: 48.4345237, lng: 17.531642 },
    { lat: 48.434656, lng: 17.5312894 },
    { lat: 48.4349993, lng: 17.5311095 },
    { lat: 48.4350979, lng: 17.5311222 },
    { lat: 48.4355982, lng: 17.5311869 },
    { lat: 48.4363384, lng: 17.530629 },
    { lat: 48.4366019, lng: 17.5302635 },
    { lat: 48.4370469, lng: 17.5297862 },
    { lat: 48.4376079, lng: 17.5293907 },
    { lat: 48.4376791, lng: 17.5292488 },
    { lat: 48.4378287, lng: 17.5291679 },
    { lat: 48.4378654, lng: 17.52902 },
    { lat: 48.4378135, lng: 17.5289485 },
    { lat: 48.4378437, lng: 17.5288908 },
    { lat: 48.4380708, lng: 17.5286085 },
    { lat: 48.4383453, lng: 17.5284173 },
    { lat: 48.4384069, lng: 17.5283531 },
    { lat: 48.4384766, lng: 17.5281912 },
    { lat: 48.4385924, lng: 17.5282008 },
    { lat: 48.438735, lng: 17.527958 },
    { lat: 48.4388993, lng: 17.527929 },
    { lat: 48.43895, lng: 17.5278402 },
    { lat: 48.4391639, lng: 17.5277205 },
    { lat: 48.4392554, lng: 17.5277753 },
    { lat: 48.439358, lng: 17.5275432 },
    { lat: 48.4395293, lng: 17.5275217 },
    { lat: 48.4396726, lng: 17.527234 },
    { lat: 48.4397533, lng: 17.5273486 },
    { lat: 48.4398034, lng: 17.5272329 },
    { lat: 48.4399431, lng: 17.527227 },
    { lat: 48.4400501, lng: 17.5268673 },
    { lat: 48.4402571, lng: 17.526962 },
    { lat: 48.4404933, lng: 17.5271929 },
    { lat: 48.4406905, lng: 17.5268487 },
    { lat: 48.4407346, lng: 17.5266752 },
    { lat: 48.4409388, lng: 17.5265305 },
    { lat: 48.4410113, lng: 17.5263572 },
    { lat: 48.441111, lng: 17.5262724 },
    { lat: 48.4411679, lng: 17.5259587 },
    { lat: 48.4413828, lng: 17.5258935 },
    { lat: 48.4416142, lng: 17.5253612 },
    { lat: 48.4417719, lng: 17.5254445 },
    { lat: 48.4418751, lng: 17.525203 },
    { lat: 48.4420577, lng: 17.5250384 },
    { lat: 48.4420805, lng: 17.5248807 },
    { lat: 48.4423137, lng: 17.5247479 },
    { lat: 48.4424063, lng: 17.5246054 },
    { lat: 48.442492, lng: 17.5247087 },
    { lat: 48.4428029, lng: 17.524735 },
    { lat: 48.4429848, lng: 17.5245409 },
    { lat: 48.4429962, lng: 17.5242963 },
    { lat: 48.442929, lng: 17.524126 },
    { lat: 48.4429978, lng: 17.5240347 },
    { lat: 48.4430843, lng: 17.5241342 },
    { lat: 48.4432129, lng: 17.5240421 },
    { lat: 48.4433835, lng: 17.5240794 },
    { lat: 48.4434733, lng: 17.5238576 },
    { lat: 48.4434569, lng: 17.5235868 },
    { lat: 48.4436407, lng: 17.523544 },
    { lat: 48.444088, lng: 17.5222015 },
    { lat: 48.4443604, lng: 17.521586 },
    { lat: 48.4448929, lng: 17.5206374 },
    { lat: 48.4453375, lng: 17.5199945 },
    { lat: 48.4465657, lng: 17.517989 },
    { lat: 48.4467128, lng: 17.5177619 },
    { lat: 48.446818, lng: 17.5176073 },
    { lat: 48.4473063, lng: 17.5174009 },
    { lat: 48.447936, lng: 17.5166669 },
    { lat: 48.4482493, lng: 17.5160216 },
    { lat: 48.4469796, lng: 17.5137706 },
    { lat: 48.4469845, lng: 17.5135109 },
    { lat: 48.4454885, lng: 17.5109726 },
    { lat: 48.4437954, lng: 17.5082302 },
    { lat: 48.4418075, lng: 17.5051881 },
    { lat: 48.439639, lng: 17.5016374 },
    { lat: 48.4400281, lng: 17.5012229 },
    { lat: 48.4397473, lng: 17.5001008 },
    { lat: 48.4392533, lng: 17.4987168 },
    { lat: 48.4390062, lng: 17.4987899 },
    { lat: 48.4384662, lng: 17.4991234 },
    { lat: 48.4379223, lng: 17.4997138 },
    { lat: 48.4371603, lng: 17.4977967 },
    { lat: 48.43735, lng: 17.4974578 },
    { lat: 48.4370892, lng: 17.4965459 },
    { lat: 48.436943, lng: 17.4958287 },
    { lat: 48.4366841, lng: 17.4950168 },
    { lat: 48.4362183, lng: 17.4954999 },
    { lat: 48.43545, lng: 17.4960777 },
    { lat: 48.4345422, lng: 17.4968726 },
    { lat: 48.4340178, lng: 17.4971693 },
    { lat: 48.4331979, lng: 17.4978395 },
    { lat: 48.4321413, lng: 17.4983586 },
    { lat: 48.4315509, lng: 17.498785 },
    { lat: 48.4298932, lng: 17.4997579 },
    { lat: 48.4294602, lng: 17.5001032 },
    { lat: 48.4287435, lng: 17.5009219 },
    { lat: 48.4281042, lng: 17.5018567 },
    { lat: 48.4279438, lng: 17.5022085 },
    { lat: 48.427633, lng: 17.5026997 },
    { lat: 48.4272376, lng: 17.5031412 },
    { lat: 48.4265544, lng: 17.5040951 },
    { lat: 48.4262959, lng: 17.5052599 },
    { lat: 48.426167, lng: 17.5056459 },
    { lat: 48.4255463, lng: 17.5069591 },
    { lat: 48.425125, lng: 17.5081236 },
    { lat: 48.4249344, lng: 17.5084821 },
    { lat: 48.4246658, lng: 17.5088228 },
    { lat: 48.4240483, lng: 17.5094058 },
    { lat: 48.4230541, lng: 17.5098883 },
    { lat: 48.4217665, lng: 17.5105131 },
    { lat: 48.4213838, lng: 17.5110782 },
    { lat: 48.4209112, lng: 17.5115307 },
    { lat: 48.4196206, lng: 17.5123285 },
    { lat: 48.4186998, lng: 17.5132623 },
    { lat: 48.4180134, lng: 17.5141815 },
    { lat: 48.4177919, lng: 17.5143407 },
    { lat: 48.4173296, lng: 17.5144583 },
    { lat: 48.4165676, lng: 17.5147999 },
    { lat: 48.416315, lng: 17.5151762 },
    { lat: 48.416257, lng: 17.5153645 },
    { lat: 48.416251, lng: 17.515552 },
    { lat: 48.4161336, lng: 17.5159397 },
    { lat: 48.4156336, lng: 17.5168117 },
    { lat: 48.4142968, lng: 17.5196102 },
    { lat: 48.4127134, lng: 17.522583 },
    { lat: 48.4122194, lng: 17.5237078 },
    { lat: 48.4115461, lng: 17.5248011 },
    { lat: 48.4109024, lng: 17.5260025 },
    { lat: 48.4104509, lng: 17.5271947 },
    { lat: 48.4099158, lng: 17.5292335 },
    { lat: 48.4094006, lng: 17.5306995 },
    { lat: 48.4095286, lng: 17.5310074 },
    { lat: 48.4064476, lng: 17.5374873 },
    { lat: 48.4048898, lng: 17.5396244 },
    { lat: 48.4047445, lng: 17.5395944 },
    { lat: 48.4031175, lng: 17.5425079 },
    { lat: 48.4012614, lng: 17.5458333 },
  ],
  Zvončín: [
    { lat: 48.4012614, lng: 17.5458333 },
    { lat: 48.4031175, lng: 17.5425079 },
    { lat: 48.4047445, lng: 17.5395944 },
    { lat: 48.4048898, lng: 17.5396244 },
    { lat: 48.4064476, lng: 17.5374873 },
    { lat: 48.4095286, lng: 17.5310074 },
    { lat: 48.4094006, lng: 17.5306995 },
    { lat: 48.4099158, lng: 17.5292335 },
    { lat: 48.4104509, lng: 17.5271947 },
    { lat: 48.4109024, lng: 17.5260025 },
    { lat: 48.4115461, lng: 17.5248011 },
    { lat: 48.4122194, lng: 17.5237078 },
    { lat: 48.4127134, lng: 17.522583 },
    { lat: 48.4142968, lng: 17.5196102 },
    { lat: 48.4156336, lng: 17.5168117 },
    { lat: 48.4161336, lng: 17.5159397 },
    { lat: 48.416251, lng: 17.515552 },
    { lat: 48.4159487, lng: 17.5151839 },
    { lat: 48.4139179, lng: 17.5127105 },
    { lat: 48.4132053, lng: 17.5118427 },
    { lat: 48.4128579, lng: 17.5114934 },
    { lat: 48.4125655, lng: 17.5111994 },
    { lat: 48.4122106, lng: 17.5108426 },
    { lat: 48.4118049, lng: 17.5096342 },
    { lat: 48.4118671, lng: 17.5089498 },
    { lat: 48.4120512, lng: 17.5084526 },
    { lat: 48.4104554, lng: 17.5068654 },
    { lat: 48.409697, lng: 17.5057976 },
    { lat: 48.4091212, lng: 17.5045481 },
    { lat: 48.408654, lng: 17.5037938 },
    { lat: 48.408301, lng: 17.5038025 },
    { lat: 48.4077163, lng: 17.5018285 },
    { lat: 48.407372, lng: 17.5021334 },
    { lat: 48.4072311, lng: 17.5021531 },
    { lat: 48.4072135, lng: 17.5021073 },
    { lat: 48.4073828, lng: 17.5019844 },
    { lat: 48.4073567, lng: 17.5018573 },
    { lat: 48.4072303, lng: 17.5016822 },
    { lat: 48.4072096, lng: 17.5015192 },
    { lat: 48.4072917, lng: 17.5013887 },
    { lat: 48.407244, lng: 17.5011716 },
    { lat: 48.4066797, lng: 17.5018688 },
    { lat: 48.4063619, lng: 17.502123 },
    { lat: 48.4061749, lng: 17.5022726 },
    { lat: 48.405362, lng: 17.5026129 },
    { lat: 48.4051197, lng: 17.5026548 },
    { lat: 48.4050414, lng: 17.5026684 },
    { lat: 48.4047155, lng: 17.5029753 },
    { lat: 48.4043281, lng: 17.5030796 },
    { lat: 48.403928, lng: 17.5030585 },
    { lat: 48.4038183, lng: 17.5031074 },
    { lat: 48.4035452, lng: 17.5033927 },
    { lat: 48.4031662, lng: 17.5035058 },
    { lat: 48.4029526, lng: 17.5036802 },
    { lat: 48.4024863, lng: 17.5038602 },
    { lat: 48.40115, lng: 17.501475 },
    { lat: 48.3999398, lng: 17.4988184 },
    { lat: 48.3996255, lng: 17.4983712 },
    { lat: 48.3989929, lng: 17.4971712 },
    { lat: 48.3974578, lng: 17.4945066 },
    { lat: 48.3958041, lng: 17.4921085 },
    { lat: 48.3938259, lng: 17.4888733 },
    { lat: 48.391985, lng: 17.4853384 },
    { lat: 48.3916292, lng: 17.4845819 },
    { lat: 48.3904303, lng: 17.4816156 },
    { lat: 48.3900264, lng: 17.4802347 },
    { lat: 48.3895082, lng: 17.4791126 },
    { lat: 48.3889793, lng: 17.4777874 },
    { lat: 48.3887944, lng: 17.4771608 },
    { lat: 48.3887036, lng: 17.4766601 },
    { lat: 48.3884012, lng: 17.4758917 },
    { lat: 48.3882744, lng: 17.4756244 },
    { lat: 48.3880436, lng: 17.4759883 },
    { lat: 48.3875521, lng: 17.4764564 },
    { lat: 48.3874334, lng: 17.4765091 },
    { lat: 48.3872011, lng: 17.4768643 },
    { lat: 48.3868035, lng: 17.477144 },
    { lat: 48.3864027, lng: 17.4773232 },
    { lat: 48.3856233, lng: 17.4780075 },
    { lat: 48.3838927, lng: 17.4799529 },
    { lat: 48.3833124, lng: 17.4804651 },
    { lat: 48.3823027, lng: 17.4815912 },
    { lat: 48.3816806, lng: 17.4821044 },
    { lat: 48.3809781, lng: 17.4830171 },
    { lat: 48.3808217, lng: 17.4833791 },
    { lat: 48.3806405, lng: 17.4836348 },
    { lat: 48.3797675, lng: 17.4846006 },
    { lat: 48.379155, lng: 17.4856183 },
    { lat: 48.3790466, lng: 17.485916 },
    { lat: 48.3788719, lng: 17.4860074 },
    { lat: 48.3788516, lng: 17.4863446 },
    { lat: 48.3784981, lng: 17.4866625 },
    { lat: 48.3782605, lng: 17.4867257 },
    { lat: 48.37823, lng: 17.4867564 },
    { lat: 48.3781258, lng: 17.4868613 },
    { lat: 48.378053, lng: 17.4870101 },
    { lat: 48.3783167, lng: 17.4874416 },
    { lat: 48.3783365, lng: 17.4874657 },
    { lat: 48.3799058, lng: 17.4893944 },
    { lat: 48.3816523, lng: 17.4922707 },
    { lat: 48.3835852, lng: 17.4966835 },
    { lat: 48.3856607, lng: 17.5016136 },
    { lat: 48.386987, lng: 17.5042315 },
    { lat: 48.3880929, lng: 17.5079064 },
    { lat: 48.3893036, lng: 17.5116633 },
    { lat: 48.3903307, lng: 17.5147284 },
    { lat: 48.3908673, lng: 17.5159846 },
    { lat: 48.3908583, lng: 17.516181 },
    { lat: 48.3906657, lng: 17.5162595 },
    { lat: 48.3913937, lng: 17.5177376 },
    { lat: 48.3921116, lng: 17.5196027 },
    { lat: 48.3922426, lng: 17.5196748 },
    { lat: 48.3926468, lng: 17.5203966 },
    { lat: 48.3930471, lng: 17.5212546 },
    { lat: 48.394109, lng: 17.5231669 },
    { lat: 48.3957216, lng: 17.525838 },
    { lat: 48.3990665, lng: 17.5305646 },
    { lat: 48.3995432, lng: 17.5313443 },
    { lat: 48.3987668, lng: 17.5336205 },
    { lat: 48.3967376, lng: 17.5382282 },
    { lat: 48.3977834, lng: 17.5398078 },
    { lat: 48.4012614, lng: 17.5458333 },
  ],
  BohdanovcenadTrnavou: [
    { lat: 48.4607972, lng: 17.5409698 },
    { lat: 48.4592874, lng: 17.5388576 },
    { lat: 48.4581945, lng: 17.5371615 },
    { lat: 48.4569485, lng: 17.5356106 },
    { lat: 48.4569403, lng: 17.5353397 },
    { lat: 48.4561603, lng: 17.5343177 },
    { lat: 48.4548168, lng: 17.5322658 },
    { lat: 48.4551116, lng: 17.531842 },
    { lat: 48.4542239, lng: 17.530436 },
    { lat: 48.4522012, lng: 17.5272325 },
    { lat: 48.4522889, lng: 17.5270299 },
    { lat: 48.4512148, lng: 17.5252423 },
    { lat: 48.4476766, lng: 17.5199286 },
    { lat: 48.4466689, lng: 17.5181712 },
    { lat: 48.4465657, lng: 17.517989 },
    { lat: 48.4453375, lng: 17.5199945 },
    { lat: 48.4448929, lng: 17.5206374 },
    { lat: 48.4443604, lng: 17.521586 },
    { lat: 48.444088, lng: 17.5222015 },
    { lat: 48.4436407, lng: 17.523544 },
    { lat: 48.4434569, lng: 17.5235868 },
    { lat: 48.4434733, lng: 17.5238576 },
    { lat: 48.4433835, lng: 17.5240794 },
    { lat: 48.4432129, lng: 17.5240421 },
    { lat: 48.4430843, lng: 17.5241342 },
    { lat: 48.4429978, lng: 17.5240347 },
    { lat: 48.442929, lng: 17.524126 },
    { lat: 48.4429962, lng: 17.5242963 },
    { lat: 48.4429848, lng: 17.5245409 },
    { lat: 48.4428029, lng: 17.524735 },
    { lat: 48.442492, lng: 17.5247087 },
    { lat: 48.4424063, lng: 17.5246054 },
    { lat: 48.4423137, lng: 17.5247479 },
    { lat: 48.4420805, lng: 17.5248807 },
    { lat: 48.4420577, lng: 17.5250384 },
    { lat: 48.4418751, lng: 17.525203 },
    { lat: 48.4417719, lng: 17.5254445 },
    { lat: 48.4416142, lng: 17.5253612 },
    { lat: 48.4413828, lng: 17.5258935 },
    { lat: 48.4411679, lng: 17.5259587 },
    { lat: 48.441111, lng: 17.5262724 },
    { lat: 48.4410113, lng: 17.5263572 },
    { lat: 48.4409388, lng: 17.5265305 },
    { lat: 48.4407346, lng: 17.5266752 },
    { lat: 48.4406905, lng: 17.5268487 },
    { lat: 48.4404933, lng: 17.5271929 },
    { lat: 48.4402571, lng: 17.526962 },
    { lat: 48.4400501, lng: 17.5268673 },
    { lat: 48.4399431, lng: 17.527227 },
    { lat: 48.4398034, lng: 17.5272329 },
    { lat: 48.4397533, lng: 17.5273486 },
    { lat: 48.4396726, lng: 17.527234 },
    { lat: 48.4395293, lng: 17.5275217 },
    { lat: 48.439358, lng: 17.5275432 },
    { lat: 48.4392554, lng: 17.5277753 },
    { lat: 48.4391639, lng: 17.5277205 },
    { lat: 48.43895, lng: 17.5278402 },
    { lat: 48.4388993, lng: 17.527929 },
    { lat: 48.438735, lng: 17.527958 },
    { lat: 48.4385924, lng: 17.5282008 },
    { lat: 48.4384766, lng: 17.5281912 },
    { lat: 48.4384069, lng: 17.5283531 },
    { lat: 48.4383453, lng: 17.5284173 },
    { lat: 48.4380708, lng: 17.5286085 },
    { lat: 48.4378437, lng: 17.5288908 },
    { lat: 48.4378135, lng: 17.5289485 },
    { lat: 48.4378654, lng: 17.52902 },
    { lat: 48.4378287, lng: 17.5291679 },
    { lat: 48.4376791, lng: 17.5292488 },
    { lat: 48.4376079, lng: 17.5293907 },
    { lat: 48.4370469, lng: 17.5297862 },
    { lat: 48.4366019, lng: 17.5302635 },
    { lat: 48.4363384, lng: 17.530629 },
    { lat: 48.4355982, lng: 17.5311869 },
    { lat: 48.4350979, lng: 17.5311222 },
    { lat: 48.4349993, lng: 17.5311095 },
    { lat: 48.434656, lng: 17.5312894 },
    { lat: 48.4345237, lng: 17.531642 },
    { lat: 48.4341144, lng: 17.5322184 },
    { lat: 48.4340895, lng: 17.532392 },
    { lat: 48.434125, lng: 17.532597 },
    { lat: 48.4345059, lng: 17.5329743 },
    { lat: 48.4345752, lng: 17.5331748 },
    { lat: 48.4344589, lng: 17.5333545 },
    { lat: 48.4341178, lng: 17.5335131 },
    { lat: 48.4337791, lng: 17.5337983 },
    { lat: 48.4336491, lng: 17.5341684 },
    { lat: 48.4338608, lng: 17.5346344 },
    { lat: 48.4339348, lng: 17.5354765 },
    { lat: 48.4338457, lng: 17.5360985 },
    { lat: 48.4339706, lng: 17.5369461 },
    { lat: 48.4333831, lng: 17.5384858 },
    { lat: 48.4332183, lng: 17.5387701 },
    { lat: 48.4327566, lng: 17.5390001 },
    { lat: 48.4307538, lng: 17.5375919 },
    { lat: 48.427633, lng: 17.5403501 },
    { lat: 48.4274362, lng: 17.5400288 },
    { lat: 48.4252728, lng: 17.5397649 },
    { lat: 48.4246945, lng: 17.5396944 },
    { lat: 48.4245941, lng: 17.5397494 },
    { lat: 48.4244845, lng: 17.5398203 },
    { lat: 48.4240398, lng: 17.5407453 },
    { lat: 48.4228654, lng: 17.5417937 },
    { lat: 48.4219656, lng: 17.5427564 },
    { lat: 48.4206803, lng: 17.5438885 },
    { lat: 48.4202634, lng: 17.5444735 },
    { lat: 48.4199223, lng: 17.5451976 },
    { lat: 48.4192878, lng: 17.5466578 },
    { lat: 48.4193455, lng: 17.5468703 },
    { lat: 48.4188973, lng: 17.5468558 },
    { lat: 48.4185101, lng: 17.5471065 },
    { lat: 48.418304, lng: 17.5472015 },
    { lat: 48.4181375, lng: 17.5473948 },
    { lat: 48.4179737, lng: 17.5477442 },
    { lat: 48.4175462, lng: 17.5482524 },
    { lat: 48.4171633, lng: 17.5487666 },
    { lat: 48.4166952, lng: 17.5492907 },
    { lat: 48.4166627, lng: 17.5493106 },
    { lat: 48.416296, lng: 17.5497771 },
    { lat: 48.416047, lng: 17.5499301 },
    { lat: 48.4156134, lng: 17.5499099 },
    { lat: 48.4153953, lng: 17.5500151 },
    { lat: 48.4149671, lng: 17.5502393 },
    { lat: 48.414603, lng: 17.5504678 },
    { lat: 48.4143844, lng: 17.5506891 },
    { lat: 48.4142029, lng: 17.5508938 },
    { lat: 48.4141301, lng: 17.5510552 },
    { lat: 48.4136583, lng: 17.5520734 },
    { lat: 48.4134309, lng: 17.5522631 },
    { lat: 48.4133911, lng: 17.5524377 },
    { lat: 48.4133954, lng: 17.5527879 },
    { lat: 48.4131584, lng: 17.553129 },
    { lat: 48.4128135, lng: 17.5533168 },
    { lat: 48.4126611, lng: 17.5535999 },
    { lat: 48.4125268, lng: 17.5534777 },
    { lat: 48.4124421, lng: 17.5532108 },
    { lat: 48.4122997, lng: 17.5531218 },
    { lat: 48.411967, lng: 17.5535164 },
    { lat: 48.4113928, lng: 17.5533332 },
    { lat: 48.411021, lng: 17.5539507 },
    { lat: 48.4107903, lng: 17.5537691 },
    { lat: 48.4106395, lng: 17.5537932 },
    { lat: 48.4105268, lng: 17.5539231 },
    { lat: 48.4104229, lng: 17.5538861 },
    { lat: 48.4102411, lng: 17.5535304 },
    { lat: 48.4097833, lng: 17.5536658 },
    { lat: 48.4095336, lng: 17.5535388 },
    { lat: 48.4084816, lng: 17.5534131 },
    { lat: 48.4083711, lng: 17.5535862 },
    { lat: 48.4082181, lng: 17.5537925 },
    { lat: 48.4080722, lng: 17.5539447 },
    { lat: 48.4074973, lng: 17.5543362 },
    { lat: 48.4072266, lng: 17.5543953 },
    { lat: 48.4069101, lng: 17.5549049 },
    { lat: 48.4064936, lng: 17.5550161 },
    { lat: 48.4064255, lng: 17.5549758 },
    { lat: 48.4076671, lng: 17.557682 },
    { lat: 48.4128701, lng: 17.5648412 },
    { lat: 48.4129767, lng: 17.565 },
    { lat: 48.4134576, lng: 17.5657161 },
    { lat: 48.4158357, lng: 17.5692919 },
    { lat: 48.4178254, lng: 17.5727444 },
    { lat: 48.4187519, lng: 17.5742123 },
    { lat: 48.4196684, lng: 17.5755426 },
    { lat: 48.4206122, lng: 17.5766981 },
    { lat: 48.4215906, lng: 17.5781843 },
    { lat: 48.4219826, lng: 17.5790267 },
    { lat: 48.4223666, lng: 17.5798659 },
    { lat: 48.4226821, lng: 17.5795681 },
    { lat: 48.422799, lng: 17.5796139 },
    { lat: 48.4229252, lng: 17.5795205 },
    { lat: 48.4231675, lng: 17.5790838 },
    { lat: 48.4234733, lng: 17.5787066 },
    { lat: 48.4238501, lng: 17.5779349 },
    { lat: 48.4238999, lng: 17.5773646 },
    { lat: 48.4240454, lng: 17.5770391 },
    { lat: 48.4240211, lng: 17.5769775 },
    { lat: 48.4243442, lng: 17.5767141 },
    { lat: 48.4248389, lng: 17.5766256 },
    { lat: 48.4244806, lng: 17.5761023 },
    { lat: 48.4249928, lng: 17.5751145 },
    { lat: 48.4254677, lng: 17.5744834 },
    { lat: 48.4258635, lng: 17.5738508 },
    { lat: 48.4262039, lng: 17.5729154 },
    { lat: 48.4263801, lng: 17.5722185 },
    { lat: 48.4265525, lng: 17.5719823 },
    { lat: 48.4275039, lng: 17.5711183 },
    { lat: 48.428303, lng: 17.5702022 },
    { lat: 48.4292312, lng: 17.5698268 },
    { lat: 48.4300423, lng: 17.5698863 },
    { lat: 48.4303381, lng: 17.5703638 },
    { lat: 48.4316239, lng: 17.5702683 },
    { lat: 48.4318802, lng: 17.5705897 },
    { lat: 48.4324592, lng: 17.5703166 },
    { lat: 48.4330365, lng: 17.5696102 },
    { lat: 48.4337084, lng: 17.5693579 },
    { lat: 48.4345756, lng: 17.5689115 },
    { lat: 48.4347743, lng: 17.568339 },
    { lat: 48.4349327, lng: 17.5676314 },
    { lat: 48.4349074, lng: 17.5666101 },
    { lat: 48.4356022, lng: 17.5658673 },
    { lat: 48.4362612, lng: 17.5654165 },
    { lat: 48.4371086, lng: 17.564995 },
    { lat: 48.4378328, lng: 17.5642002 },
    { lat: 48.4387243, lng: 17.5637613 },
    { lat: 48.4395934, lng: 17.563008 },
    { lat: 48.4403886, lng: 17.5619799 },
    { lat: 48.441794, lng: 17.5598173 },
    { lat: 48.4427888, lng: 17.558623 },
    { lat: 48.4434697, lng: 17.5573569 },
    { lat: 48.4439946, lng: 17.5561986 },
    { lat: 48.4438817, lng: 17.5560415 },
    { lat: 48.444186, lng: 17.5554786 },
    { lat: 48.4456331, lng: 17.55339 },
    { lat: 48.4460615, lng: 17.5529316 },
    { lat: 48.4500816, lng: 17.5573741 },
    { lat: 48.4504792, lng: 17.5578933 },
    { lat: 48.451692, lng: 17.5544615 },
    { lat: 48.4522107, lng: 17.5532565 },
    { lat: 48.454073, lng: 17.550839 },
    { lat: 48.4549037, lng: 17.550016 },
    { lat: 48.4556669, lng: 17.5488547 },
    { lat: 48.4566594, lng: 17.5477722 },
    { lat: 48.4571469, lng: 17.5471055 },
    { lat: 48.4586206, lng: 17.544353 },
    { lat: 48.4592342, lng: 17.5433824 },
    { lat: 48.4602215, lng: 17.5421617 },
    { lat: 48.4604292, lng: 17.5417362 },
    { lat: 48.4607972, lng: 17.5409698 },
  ],
  Kátlovce: [
    { lat: 48.5162107, lng: 17.5848336 },
    { lat: 48.5160954, lng: 17.5849552 },
    { lat: 48.5150216, lng: 17.5864406 },
    { lat: 48.5132512, lng: 17.5891628 },
    { lat: 48.5115331, lng: 17.5912472 },
    { lat: 48.5103104, lng: 17.5928644 },
    { lat: 48.5094965, lng: 17.5941364 },
    { lat: 48.5084888, lng: 17.5962949 },
    { lat: 48.5075695, lng: 17.5984077 },
    { lat: 48.5063107, lng: 17.6009733 },
    { lat: 48.5059163, lng: 17.6019202 },
    { lat: 48.5055146, lng: 17.6031423 },
    { lat: 48.5051442, lng: 17.6039473 },
    { lat: 48.504853, lng: 17.6041476 },
    { lat: 48.5046148, lng: 17.6039701 },
    { lat: 48.5040289, lng: 17.6040818 },
    { lat: 48.5037909, lng: 17.6045328 },
    { lat: 48.5030482, lng: 17.6063483 },
    { lat: 48.5024238, lng: 17.6075333 },
    { lat: 48.5020828, lng: 17.6079797 },
    { lat: 48.5016064, lng: 17.6084478 },
    { lat: 48.5013442, lng: 17.6088947 },
    { lat: 48.5010322, lng: 17.6098211 },
    { lat: 48.5007906, lng: 17.6109522 },
    { lat: 48.5005981, lng: 17.6115522 },
    { lat: 48.5003914, lng: 17.6120353 },
    { lat: 48.4999924, lng: 17.6126971 },
    { lat: 48.4994855, lng: 17.6140411 },
    { lat: 48.4995976, lng: 17.6142249 },
    { lat: 48.5007188, lng: 17.6159309 },
    { lat: 48.5014796, lng: 17.6168604 },
    { lat: 48.5022912, lng: 17.6175911 },
    { lat: 48.5028834, lng: 17.6182104 },
    { lat: 48.5026063, lng: 17.6191019 },
    { lat: 48.503894, lng: 17.6218198 },
    { lat: 48.5039762, lng: 17.6216365 },
    { lat: 48.5048525, lng: 17.6236344 },
    { lat: 48.5050024, lng: 17.6235009 },
    { lat: 48.5054112, lng: 17.6231411 },
    { lat: 48.5059907, lng: 17.6232359 },
    { lat: 48.5063346, lng: 17.6230681 },
    { lat: 48.5066861, lng: 17.6237181 },
    { lat: 48.5068511, lng: 17.6239182 },
    { lat: 48.5071186, lng: 17.6246739 },
    { lat: 48.5077602, lng: 17.6262054 },
    { lat: 48.5091594, lng: 17.6287754 },
    { lat: 48.5102151, lng: 17.6305194 },
    { lat: 48.5112833, lng: 17.6320669 },
    { lat: 48.5121763, lng: 17.6332512 },
    { lat: 48.5133672, lng: 17.6351714 },
    { lat: 48.5138542, lng: 17.6362069 },
    { lat: 48.5144126, lng: 17.6376547 },
    { lat: 48.5148744, lng: 17.6386469 },
    { lat: 48.5155157, lng: 17.6397991 },
    { lat: 48.5163258, lng: 17.6410666 },
    { lat: 48.5164448, lng: 17.6411826 },
    { lat: 48.516453, lng: 17.641169 },
    { lat: 48.516681, lng: 17.640791 },
    { lat: 48.51762, lng: 17.639265 },
    { lat: 48.518026, lng: 17.638677 },
    { lat: 48.518754, lng: 17.637771 },
    { lat: 48.51918, lng: 17.637308 },
    { lat: 48.519494, lng: 17.636957 },
    { lat: 48.520251, lng: 17.636122 },
    { lat: 48.520617, lng: 17.635719 },
    { lat: 48.520936, lng: 17.635421 },
    { lat: 48.521475, lng: 17.634906 },
    { lat: 48.522022, lng: 17.634397 },
    { lat: 48.522505, lng: 17.633941 },
    { lat: 48.522932, lng: 17.633543 },
    { lat: 48.523139, lng: 17.633367 },
    { lat: 48.524178, lng: 17.632496 },
    { lat: 48.524253, lng: 17.632607 },
    { lat: 48.524372, lng: 17.632769 },
    { lat: 48.524554, lng: 17.632961 },
    { lat: 48.524959, lng: 17.633254 },
    { lat: 48.525279, lng: 17.633498 },
    { lat: 48.525329, lng: 17.633537 },
    { lat: 48.525664, lng: 17.633822 },
    { lat: 48.525935, lng: 17.634057 },
    { lat: 48.526088, lng: 17.634173 },
    { lat: 48.526444, lng: 17.634485 },
    { lat: 48.527037, lng: 17.635048 },
    { lat: 48.527263, lng: 17.635246 },
    { lat: 48.527541, lng: 17.635447 },
    { lat: 48.528161, lng: 17.635818 },
    { lat: 48.528482, lng: 17.635853 },
    { lat: 48.528485, lng: 17.635812 },
    { lat: 48.528887, lng: 17.634291 },
    { lat: 48.529095, lng: 17.633697 },
    { lat: 48.5295, lng: 17.632548 },
    { lat: 48.52977, lng: 17.632783 },
    { lat: 48.530048, lng: 17.632013 },
    { lat: 48.530465, lng: 17.631288 },
    { lat: 48.530491, lng: 17.631246 },
    { lat: 48.530752, lng: 17.630794 },
    { lat: 48.531084, lng: 17.631293 },
    { lat: 48.531945, lng: 17.630507 },
    { lat: 48.532528, lng: 17.629855 },
    { lat: 48.532551, lng: 17.629829 },
    { lat: 48.53316, lng: 17.62915 },
    { lat: 48.533446, lng: 17.629713 },
    { lat: 48.534317, lng: 17.628966 },
    { lat: 48.534198, lng: 17.628667 },
    { lat: 48.533661, lng: 17.627336 },
    { lat: 48.533629, lng: 17.627255 },
    { lat: 48.53281, lng: 17.62522 },
    { lat: 48.533236, lng: 17.624911 },
    { lat: 48.533409, lng: 17.624785 },
    { lat: 48.533692, lng: 17.625562 },
    { lat: 48.533931, lng: 17.625365 },
    { lat: 48.534161, lng: 17.625175 },
    { lat: 48.534407, lng: 17.624973 },
    { lat: 48.534658, lng: 17.624767 },
    { lat: 48.534243, lng: 17.623296 },
    { lat: 48.534457, lng: 17.623157 },
    { lat: 48.53464, lng: 17.623038 },
    { lat: 48.534676, lng: 17.623014 },
    { lat: 48.534746, lng: 17.62297 },
    { lat: 48.534779, lng: 17.622948 },
    { lat: 48.534854, lng: 17.622899 },
    { lat: 48.535275, lng: 17.624068 },
    { lat: 48.5357, lng: 17.625095 },
    { lat: 48.535731, lng: 17.62517 },
    { lat: 48.535801, lng: 17.62534 },
    { lat: 48.53581, lng: 17.625331 },
    { lat: 48.53585, lng: 17.625292 },
    { lat: 48.535892, lng: 17.625251 },
    { lat: 48.535929, lng: 17.625214 },
    { lat: 48.535974, lng: 17.625168 },
    { lat: 48.536028, lng: 17.625115 },
    { lat: 48.536049, lng: 17.625094 },
    { lat: 48.536075, lng: 17.625068 },
    { lat: 48.536156, lng: 17.624988 },
    { lat: 48.536198, lng: 17.624946 },
    { lat: 48.536242, lng: 17.624902 },
    { lat: 48.536286, lng: 17.624859 },
    { lat: 48.536369, lng: 17.624776 },
    { lat: 48.536405, lng: 17.62474 },
    { lat: 48.536448, lng: 17.624699 },
    { lat: 48.536484, lng: 17.624662 },
    { lat: 48.536537, lng: 17.624609 },
    { lat: 48.53658, lng: 17.624567 },
    { lat: 48.536629, lng: 17.624518 },
    { lat: 48.536676, lng: 17.624471 },
    { lat: 48.536723, lng: 17.624425 },
    { lat: 48.536774, lng: 17.624374 },
    { lat: 48.536814, lng: 17.624334 },
    { lat: 48.536901, lng: 17.624566 },
    { lat: 48.536915, lng: 17.624553 },
    { lat: 48.536991, lng: 17.624484 },
    { lat: 48.537039, lng: 17.62444 },
    { lat: 48.537095, lng: 17.624389 },
    { lat: 48.537205, lng: 17.624288 },
    { lat: 48.537478, lng: 17.624039 },
    { lat: 48.537503, lng: 17.624016 },
    { lat: 48.53753, lng: 17.623992 },
    { lat: 48.537571, lng: 17.623955 },
    { lat: 48.537609, lng: 17.623919 },
    { lat: 48.537627, lng: 17.623903 },
    { lat: 48.537189, lng: 17.62285 },
    { lat: 48.537163, lng: 17.622788 },
    { lat: 48.536564, lng: 17.62135 },
    { lat: 48.536802, lng: 17.621195 },
    { lat: 48.536846, lng: 17.621168 },
    { lat: 48.536878, lng: 17.621147 },
    { lat: 48.536919, lng: 17.621121 },
    { lat: 48.53697, lng: 17.621088 },
    { lat: 48.537015, lng: 17.62106 },
    { lat: 48.537057, lng: 17.621033 },
    { lat: 48.537093, lng: 17.62101 },
    { lat: 48.537132, lng: 17.620985 },
    { lat: 48.537172, lng: 17.620959 },
    { lat: 48.537269, lng: 17.620897 },
    { lat: 48.537317, lng: 17.620866 },
    { lat: 48.537363, lng: 17.620836 },
    { lat: 48.537431, lng: 17.620793 },
    { lat: 48.537492, lng: 17.620755 },
    { lat: 48.537512, lng: 17.620748 },
    { lat: 48.537563, lng: 17.620732 },
    { lat: 48.537577, lng: 17.620727 },
    { lat: 48.537608, lng: 17.620718 },
    { lat: 48.537695, lng: 17.62069 },
    { lat: 48.537752, lng: 17.620673 },
    { lat: 48.537805, lng: 17.620656 },
    { lat: 48.538054, lng: 17.620578 },
    { lat: 48.538063, lng: 17.620575 },
    { lat: 48.538294, lng: 17.62104 },
    { lat: 48.538315, lng: 17.621081 },
    { lat: 48.53832, lng: 17.621091 },
    { lat: 48.538358, lng: 17.621019 },
    { lat: 48.538397, lng: 17.620949 },
    { lat: 48.538438, lng: 17.620871 },
    { lat: 48.538447, lng: 17.620853 },
    { lat: 48.53843, lng: 17.620835 },
    { lat: 48.538742, lng: 17.619244 },
    { lat: 48.538804, lng: 17.618998 },
    { lat: 48.539055, lng: 17.61815 },
    { lat: 48.539058, lng: 17.618125 },
    { lat: 48.539065, lng: 17.618054 },
    { lat: 48.539118, lng: 17.617854 },
    { lat: 48.539153, lng: 17.617686 },
    { lat: 48.539163, lng: 17.617509 },
    { lat: 48.539171, lng: 17.617467 },
    { lat: 48.539228, lng: 17.61717 },
    { lat: 48.539424, lng: 17.616483 },
    { lat: 48.539487, lng: 17.616198 },
    { lat: 48.539645, lng: 17.615667 },
    { lat: 48.539689, lng: 17.615232 },
    { lat: 48.539693, lng: 17.615121 },
    { lat: 48.539711, lng: 17.614931 },
    { lat: 48.539742, lng: 17.614744 },
    { lat: 48.539765, lng: 17.614671 },
    { lat: 48.539876, lng: 17.614458 },
    { lat: 48.539918, lng: 17.614387 },
    { lat: 48.539992, lng: 17.614273 },
    { lat: 48.540118, lng: 17.614104 },
    { lat: 48.540193, lng: 17.614003 },
    { lat: 48.540222, lng: 17.613957 },
    { lat: 48.540276, lng: 17.613816 },
    { lat: 48.54043, lng: 17.613557 },
    { lat: 48.540644, lng: 17.613204 },
    { lat: 48.540902, lng: 17.612794 },
    { lat: 48.541069, lng: 17.61253 },
    { lat: 48.541144, lng: 17.612427 },
    { lat: 48.541244, lng: 17.612246 },
    { lat: 48.541319, lng: 17.612136 },
    { lat: 48.541412, lng: 17.611988 },
    { lat: 48.541655, lng: 17.611527 },
    { lat: 48.541727, lng: 17.611388 },
    { lat: 48.541992, lng: 17.610936 },
    { lat: 48.542017, lng: 17.610892 },
    { lat: 48.5419733, lng: 17.6108373 },
    { lat: 48.5415818, lng: 17.6090862 },
    { lat: 48.5330136, lng: 17.6070249 },
    { lat: 48.5329832, lng: 17.6069697 },
    { lat: 48.5326497, lng: 17.6071983 },
    { lat: 48.532036, lng: 17.6072264 },
    { lat: 48.5314935, lng: 17.6076302 },
    { lat: 48.5309615, lng: 17.5976736 },
    { lat: 48.5305123, lng: 17.5898555 },
    { lat: 48.5225358, lng: 17.575883 },
    { lat: 48.5225252, lng: 17.5758955 },
    { lat: 48.5205639, lng: 17.5784692 },
    { lat: 48.5189051, lng: 17.5805427 },
    { lat: 48.5178027, lng: 17.5828099 },
    { lat: 48.5169711, lng: 17.5840149 },
    { lat: 48.5162107, lng: 17.5848336 },
  ],
  Ružindol: [
    { lat: 48.3632189, lng: 17.5142388 },
    { lat: 48.3633997, lng: 17.5137145 },
    { lat: 48.3650784, lng: 17.5103509 },
    { lat: 48.3656747, lng: 17.5084607 },
    { lat: 48.3661913, lng: 17.5071627 },
    { lat: 48.3665508, lng: 17.5064453 },
    { lat: 48.3675647, lng: 17.5048908 },
    { lat: 48.3694581, lng: 17.5017209 },
    { lat: 48.3695445, lng: 17.5015762 },
    { lat: 48.3703549, lng: 17.503579 },
    { lat: 48.3715683, lng: 17.5026348 },
    { lat: 48.3714101, lng: 17.5012042 },
    { lat: 48.3723329, lng: 17.500676 },
    { lat: 48.3720647, lng: 17.4989061 },
    { lat: 48.3719446, lng: 17.4983913 },
    { lat: 48.3755414, lng: 17.4932446 },
    { lat: 48.3751447, lng: 17.4926047 },
    { lat: 48.3756309, lng: 17.4920495 },
    { lat: 48.3762165, lng: 17.4911808 },
    { lat: 48.3768861, lng: 17.4897878 },
    { lat: 48.3783022, lng: 17.4874444 },
    { lat: 48.3783167, lng: 17.4874416 },
    { lat: 48.378053, lng: 17.4870101 },
    { lat: 48.3781258, lng: 17.4868613 },
    { lat: 48.37823, lng: 17.4867564 },
    { lat: 48.3782605, lng: 17.4867257 },
    { lat: 48.3784981, lng: 17.4866625 },
    { lat: 48.3788516, lng: 17.4863446 },
    { lat: 48.3788719, lng: 17.4860074 },
    { lat: 48.3790466, lng: 17.485916 },
    { lat: 48.379155, lng: 17.4856183 },
    { lat: 48.3797675, lng: 17.4846006 },
    { lat: 48.3806405, lng: 17.4836348 },
    { lat: 48.3808217, lng: 17.4833791 },
    { lat: 48.3809781, lng: 17.4830171 },
    { lat: 48.3816806, lng: 17.4821044 },
    { lat: 48.3823027, lng: 17.4815912 },
    { lat: 48.3833124, lng: 17.4804651 },
    { lat: 48.3838927, lng: 17.4799529 },
    { lat: 48.3856233, lng: 17.4780075 },
    { lat: 48.3864027, lng: 17.4773232 },
    { lat: 48.3868035, lng: 17.477144 },
    { lat: 48.3872011, lng: 17.4768643 },
    { lat: 48.3874334, lng: 17.4765091 },
    { lat: 48.3875521, lng: 17.4764564 },
    { lat: 48.3880436, lng: 17.4759883 },
    { lat: 48.3882744, lng: 17.4756244 },
    { lat: 48.389137, lng: 17.4749159 },
    { lat: 48.3893517, lng: 17.4746559 },
    { lat: 48.3905914, lng: 17.4728058 },
    { lat: 48.3908259, lng: 17.4723777 },
    { lat: 48.3912266, lng: 17.4704238 },
    { lat: 48.3911866, lng: 17.4678715 },
    { lat: 48.3914093, lng: 17.4665393 },
    { lat: 48.3917017, lng: 17.4656074 },
    { lat: 48.3915946, lng: 17.4653305 },
    { lat: 48.391607, lng: 17.4653134 },
    { lat: 48.3908616, lng: 17.4642447 },
    { lat: 48.3889655, lng: 17.4618968 },
    { lat: 48.3885163, lng: 17.461181 },
    { lat: 48.3884289, lng: 17.4610334 },
    { lat: 48.3879348, lng: 17.4601845 },
    { lat: 48.3874035, lng: 17.4594061 },
    { lat: 48.3866228, lng: 17.458559 },
    { lat: 48.3860472, lng: 17.4577618 },
    { lat: 48.3853616, lng: 17.4564988 },
    { lat: 48.384583, lng: 17.4553701 },
    { lat: 48.3832546, lng: 17.4539681 },
    { lat: 48.3823502, lng: 17.4529026 },
    { lat: 48.3817978, lng: 17.4523583 },
    { lat: 48.3806917, lng: 17.4511093 },
    { lat: 48.3799152, lng: 17.4500132 },
    { lat: 48.3798569, lng: 17.4500039 },
    { lat: 48.3798758, lng: 17.4499631 },
    { lat: 48.3775508, lng: 17.447258 },
    { lat: 48.3768868, lng: 17.4484843 },
    { lat: 48.3757328, lng: 17.4509051 },
    { lat: 48.3754858, lng: 17.4512252 },
    { lat: 48.3746598, lng: 17.4519563 },
    { lat: 48.374494, lng: 17.452167 },
    { lat: 48.374471, lng: 17.452203 },
    { lat: 48.374432, lng: 17.452256 },
    { lat: 48.374401, lng: 17.452297 },
    { lat: 48.374396, lng: 17.452302 },
    { lat: 48.374367, lng: 17.452338 },
    { lat: 48.374363, lng: 17.452343 },
    { lat: 48.374333, lng: 17.452384 },
    { lat: 48.374328, lng: 17.452391 },
    { lat: 48.374293, lng: 17.452435 },
    { lat: 48.374264, lng: 17.452473 },
    { lat: 48.374229, lng: 17.452518 },
    { lat: 48.374224, lng: 17.452523 },
    { lat: 48.374221, lng: 17.452527 },
    { lat: 48.374192, lng: 17.452562 },
    { lat: 48.374189, lng: 17.452567 },
    { lat: 48.374155, lng: 17.452608 },
    { lat: 48.374117, lng: 17.452652 },
    { lat: 48.374087, lng: 17.452696 },
    { lat: 48.374051, lng: 17.452728 },
    { lat: 48.374008, lng: 17.452773 },
    { lat: 48.373949, lng: 17.452826 },
    { lat: 48.373911, lng: 17.452865 },
    { lat: 48.373854, lng: 17.452921 },
    { lat: 48.373816, lng: 17.452956 },
    { lat: 48.373796, lng: 17.452976 },
    { lat: 48.373792, lng: 17.45298 },
    { lat: 48.373774, lng: 17.452998 },
    { lat: 48.373698, lng: 17.453069 },
    { lat: 48.373623, lng: 17.453144 },
    { lat: 48.373606, lng: 17.453163 },
    { lat: 48.3736, lng: 17.453169 },
    { lat: 48.373586, lng: 17.453185 },
    { lat: 48.373566, lng: 17.453205 },
    { lat: 48.373542, lng: 17.453224 },
    { lat: 48.373508, lng: 17.453261 },
    { lat: 48.373464, lng: 17.453302 },
    { lat: 48.373427, lng: 17.453336 },
    { lat: 48.37339, lng: 17.453372 },
    { lat: 48.373355, lng: 17.453406 },
    { lat: 48.373318, lng: 17.453442 },
    { lat: 48.37328, lng: 17.453479 },
    { lat: 48.373243, lng: 17.453515 },
    { lat: 48.373223, lng: 17.453533 },
    { lat: 48.373206, lng: 17.453549 },
    { lat: 48.37317, lng: 17.453581 },
    { lat: 48.373097, lng: 17.453659 },
    { lat: 48.373059, lng: 17.453702 },
    { lat: 48.373018, lng: 17.45374 },
    { lat: 48.372999, lng: 17.453763 },
    { lat: 48.372982, lng: 17.453782 },
    { lat: 48.37295, lng: 17.453816 },
    { lat: 48.372913, lng: 17.453859 },
    { lat: 48.372873, lng: 17.453898 },
    { lat: 48.372806, lng: 17.453966 },
    { lat: 48.372771, lng: 17.454002 },
    { lat: 48.372733, lng: 17.454039 },
    { lat: 48.372699, lng: 17.454083 },
    { lat: 48.372666, lng: 17.454113 },
    { lat: 48.372662, lng: 17.454116 },
    { lat: 48.372634, lng: 17.45415 },
    { lat: 48.372601, lng: 17.454181 },
    { lat: 48.372586, lng: 17.454199 },
    { lat: 48.372566, lng: 17.454218 },
    { lat: 48.372527, lng: 17.454263 },
    { lat: 48.37249, lng: 17.4543 },
    { lat: 48.372468, lng: 17.454321 },
    { lat: 48.37245, lng: 17.454339 },
    { lat: 48.372411, lng: 17.454383 },
    { lat: 48.372267, lng: 17.45453 },
    { lat: 48.37222, lng: 17.454577 },
    { lat: 48.372181, lng: 17.45461 },
    { lat: 48.372168, lng: 17.454628 },
    { lat: 48.372127, lng: 17.454671 },
    { lat: 48.372038, lng: 17.454761 },
    { lat: 48.371957, lng: 17.454845 },
    { lat: 48.371916, lng: 17.454884 },
    { lat: 48.371881, lng: 17.454916 },
    { lat: 48.371809, lng: 17.454987 },
    { lat: 48.371738, lng: 17.455062 },
    { lat: 48.371663, lng: 17.455136 },
    { lat: 48.371517, lng: 17.455282 },
    { lat: 48.371485, lng: 17.455314 },
    { lat: 48.371449, lng: 17.455352 },
    { lat: 48.371295, lng: 17.455508 },
    { lat: 48.371207, lng: 17.455595 },
    { lat: 48.371141, lng: 17.455663 },
    { lat: 48.371106, lng: 17.455692 },
    { lat: 48.371067, lng: 17.455734 },
    { lat: 48.370996, lng: 17.455811 },
    { lat: 48.370952, lng: 17.455857 },
    { lat: 48.37091, lng: 17.455896 },
    { lat: 48.37087, lng: 17.455935 },
    { lat: 48.370797, lng: 17.456005 },
    { lat: 48.370758, lng: 17.456046 },
    { lat: 48.370646, lng: 17.456158 },
    { lat: 48.370613, lng: 17.456192 },
    { lat: 48.370578, lng: 17.456226 },
    { lat: 48.37054, lng: 17.45626 },
    { lat: 48.370451, lng: 17.45635 },
    { lat: 48.37042, lng: 17.456388 },
    { lat: 48.37039, lng: 17.456431 },
    { lat: 48.370333, lng: 17.45651 },
    { lat: 48.370269, lng: 17.456602 },
    { lat: 48.370229, lng: 17.456657 },
    { lat: 48.370192, lng: 17.45671 },
    { lat: 48.37007, lng: 17.456885 },
    { lat: 48.369997, lng: 17.456981 },
    { lat: 48.369964, lng: 17.457027 },
    { lat: 48.369936, lng: 17.457063 },
    { lat: 48.369903, lng: 17.457116 },
    { lat: 48.369801, lng: 17.457258 },
    { lat: 48.369769, lng: 17.457303 },
    { lat: 48.369736, lng: 17.457345 },
    { lat: 48.369666, lng: 17.457441 },
    { lat: 48.369599, lng: 17.457534 },
    { lat: 48.369572, lng: 17.457575 },
    { lat: 48.369542, lng: 17.45762 },
    { lat: 48.369478, lng: 17.4577 },
    { lat: 48.369445, lng: 17.457745 },
    { lat: 48.369411, lng: 17.457799 },
    { lat: 48.36931, lng: 17.457938 },
    { lat: 48.369278, lng: 17.457983 },
    { lat: 48.369244, lng: 17.458036 },
    { lat: 48.369209, lng: 17.458087 },
    { lat: 48.369179, lng: 17.458122 },
    { lat: 48.369142, lng: 17.458178 },
    { lat: 48.36911, lng: 17.458218 },
    { lat: 48.36908, lng: 17.458259 },
    { lat: 48.369074, lng: 17.458267 },
    { lat: 48.368984, lng: 17.458399 },
    { lat: 48.36895, lng: 17.458442 },
    { lat: 48.368917, lng: 17.458488 },
    { lat: 48.368904, lng: 17.458505 },
    { lat: 48.36888, lng: 17.458532 },
    { lat: 48.368806, lng: 17.458611 },
    { lat: 48.368769, lng: 17.45865 },
    { lat: 48.36873, lng: 17.458691 },
    { lat: 48.36865, lng: 17.458768 },
    { lat: 48.368612, lng: 17.458815 },
    { lat: 48.368572, lng: 17.458854 },
    { lat: 48.368459, lng: 17.458979 },
    { lat: 48.368424, lng: 17.459017 },
    { lat: 48.368346, lng: 17.4591 },
    { lat: 48.368278, lng: 17.459179 },
    { lat: 48.368133, lng: 17.459335 },
    { lat: 48.368097, lng: 17.45938 },
    { lat: 48.368061, lng: 17.459417 },
    { lat: 48.368023, lng: 17.459453 },
    { lat: 48.367996, lng: 17.459494 },
    { lat: 48.367924, lng: 17.459587 },
    { lat: 48.367852, lng: 17.459672 },
    { lat: 48.367819, lng: 17.459716 },
    { lat: 48.367777, lng: 17.459771 },
    { lat: 48.367745, lng: 17.459808 },
    { lat: 48.367715, lng: 17.459853 },
    { lat: 48.367676, lng: 17.459903 },
    { lat: 48.367576, lng: 17.460021 },
    { lat: 48.367432, lng: 17.460209 },
    { lat: 48.367365, lng: 17.460296 },
    { lat: 48.36733, lng: 17.460344 },
    { lat: 48.367295, lng: 17.46039 },
    { lat: 48.367215, lng: 17.460489 },
    { lat: 48.367143, lng: 17.460552 },
    { lat: 48.367077, lng: 17.460637 },
    { lat: 48.367048, lng: 17.460672 },
    { lat: 48.367045, lng: 17.460678 },
    { lat: 48.367017, lng: 17.460728 },
    { lat: 48.366979, lng: 17.460771 },
    { lat: 48.366942, lng: 17.460816 },
    { lat: 48.366876, lng: 17.460904 },
    { lat: 48.366812, lng: 17.46099 },
    { lat: 48.36674, lng: 17.461079 },
    { lat: 48.36666, lng: 17.461157 },
    { lat: 48.366628, lng: 17.461202 },
    { lat: 48.366608, lng: 17.461221 },
    { lat: 48.366591, lng: 17.461239 },
    { lat: 48.366519, lng: 17.461332 },
    { lat: 48.366439, lng: 17.461414 },
    { lat: 48.366372, lng: 17.461504 },
    { lat: 48.366291, lng: 17.461588 },
    { lat: 48.366223, lng: 17.461656 },
    { lat: 48.36615, lng: 17.46174 },
    { lat: 48.366143, lng: 17.461749 },
    { lat: 48.366037, lng: 17.46186 },
    { lat: 48.365963, lng: 17.461938 },
    { lat: 48.365957, lng: 17.461944 },
    { lat: 48.365838, lng: 17.462051 },
    { lat: 48.365758, lng: 17.462123 },
    { lat: 48.365716, lng: 17.462157 },
    { lat: 48.365676, lng: 17.462192 },
    { lat: 48.36551, lng: 17.462336 },
    { lat: 48.365353, lng: 17.462474 },
    { lat: 48.365311, lng: 17.462513 },
    { lat: 48.365272, lng: 17.462542 },
    { lat: 48.365239, lng: 17.462571 },
    { lat: 48.365196, lng: 17.46262 },
    { lat: 48.365112, lng: 17.462686 },
    { lat: 48.365019, lng: 17.462766 },
    { lat: 48.364985, lng: 17.462802 },
    { lat: 48.364944, lng: 17.462841 },
    { lat: 48.364873, lng: 17.462907 },
    { lat: 48.364805, lng: 17.462989 },
    { lat: 48.364738, lng: 17.463077 },
    { lat: 48.364668, lng: 17.463171 },
    { lat: 48.364602, lng: 17.463249 },
    { lat: 48.364598, lng: 17.463254 },
    { lat: 48.364564, lng: 17.463299 },
    { lat: 48.364531, lng: 17.463345 },
    { lat: 48.364464, lng: 17.463436 },
    { lat: 48.364323, lng: 17.463614 },
    { lat: 48.364292, lng: 17.463652 },
    { lat: 48.364258, lng: 17.463703 },
    { lat: 48.364196, lng: 17.463777 },
    { lat: 48.364162, lng: 17.463817 },
    { lat: 48.364128, lng: 17.463856 },
    { lat: 48.364089, lng: 17.463916 },
    { lat: 48.364056, lng: 17.463946 },
    { lat: 48.364021, lng: 17.463992 },
    { lat: 48.364016, lng: 17.463998 },
    { lat: 48.363962, lng: 17.46408 },
    { lat: 48.363925, lng: 17.464107 },
    { lat: 48.363794, lng: 17.464207 },
    { lat: 48.363746, lng: 17.46426 },
    { lat: 48.363705, lng: 17.46428 },
    { lat: 48.363685, lng: 17.46433 },
    { lat: 48.36361, lng: 17.464442 },
    { lat: 48.363547, lng: 17.464534 },
    { lat: 48.363515, lng: 17.464575 },
    { lat: 48.36348, lng: 17.464634 },
    { lat: 48.363454, lng: 17.464666 },
    { lat: 48.363421, lng: 17.464723 },
    { lat: 48.363361, lng: 17.464812 },
    { lat: 48.363332, lng: 17.464858 },
    { lat: 48.363305, lng: 17.464906 },
    { lat: 48.36317, lng: 17.465092 },
    { lat: 48.363012, lng: 17.465341 },
    { lat: 48.363002, lng: 17.46536 },
    { lat: 48.362992, lng: 17.465377 },
    { lat: 48.362952, lng: 17.465446 },
    { lat: 48.362947, lng: 17.465456 },
    { lat: 48.362927, lng: 17.465482 },
    { lat: 48.362909, lng: 17.465508 },
    { lat: 48.362898, lng: 17.465514 },
    { lat: 48.362872, lng: 17.465558 },
    { lat: 48.362847, lng: 17.465596 },
    { lat: 48.362784, lng: 17.465668 },
    { lat: 48.362527, lng: 17.465962 },
    { lat: 48.362461, lng: 17.466033 },
    { lat: 48.362389, lng: 17.466117 },
    { lat: 48.362355, lng: 17.466156 },
    { lat: 48.362285, lng: 17.466233 },
    { lat: 48.362181, lng: 17.466346 },
    { lat: 48.362123, lng: 17.466416 },
    { lat: 48.361992, lng: 17.466554 },
    { lat: 48.361931, lng: 17.466624 },
    { lat: 48.361862, lng: 17.466701 },
    { lat: 48.361738, lng: 17.466838 },
    { lat: 48.361614, lng: 17.466973 },
    { lat: 48.361485, lng: 17.467114 },
    { lat: 48.361243, lng: 17.467398 },
    { lat: 48.361184, lng: 17.467468 },
    { lat: 48.361123, lng: 17.46754 },
    { lat: 48.360997, lng: 17.467685 },
    { lat: 48.360876, lng: 17.467827 },
    { lat: 48.360745, lng: 17.467979 },
    { lat: 48.360628, lng: 17.468111 },
    { lat: 48.360567, lng: 17.468186 },
    { lat: 48.360505, lng: 17.468261 },
    { lat: 48.360377, lng: 17.46841 },
    { lat: 48.360257, lng: 17.468547 },
    { lat: 48.360192, lng: 17.468624 },
    { lat: 48.36013, lng: 17.468626 },
    { lat: 48.360041, lng: 17.468631 },
    { lat: 48.359984, lng: 17.468633 },
    { lat: 48.359711, lng: 17.468442 },
    { lat: 48.35949, lng: 17.468287 },
    { lat: 48.359323, lng: 17.467981 },
    { lat: 48.359206, lng: 17.467777 },
    { lat: 48.359198, lng: 17.467763 },
    { lat: 48.359173, lng: 17.46772 },
    { lat: 48.359134, lng: 17.467657 },
    { lat: 48.359058, lng: 17.467524 },
    { lat: 48.358917, lng: 17.467275 },
    { lat: 48.358736, lng: 17.467473 },
    { lat: 48.358674, lng: 17.467536 },
    { lat: 48.358605, lng: 17.46762 },
    { lat: 48.358542, lng: 17.467688 },
    { lat: 48.358424, lng: 17.467821 },
    { lat: 48.358357, lng: 17.467888 },
    { lat: 48.358183, lng: 17.468072 },
    { lat: 48.358069, lng: 17.468195 },
    { lat: 48.358007, lng: 17.46826 },
    { lat: 48.357953, lng: 17.468318 },
    { lat: 48.357887, lng: 17.468391 },
    { lat: 48.357763, lng: 17.468632 },
    { lat: 48.357675, lng: 17.468806 },
    { lat: 48.357636, lng: 17.468888 },
    { lat: 48.35759, lng: 17.468979 },
    { lat: 48.357548, lng: 17.469064 },
    { lat: 48.357414, lng: 17.469329 },
    { lat: 48.357375, lng: 17.469412 },
    { lat: 48.357332, lng: 17.469496 },
    { lat: 48.357251, lng: 17.469672 },
    { lat: 48.357162, lng: 17.469838 },
    { lat: 48.357121, lng: 17.469921 },
    { lat: 48.357079, lng: 17.470005 },
    { lat: 48.357036, lng: 17.470088 },
    { lat: 48.356989, lng: 17.470182 },
    { lat: 48.35695, lng: 17.470267 },
    { lat: 48.356907, lng: 17.470345 },
    { lat: 48.356842, lng: 17.470477 },
    { lat: 48.356765, lng: 17.470627 },
    { lat: 48.356727, lng: 17.47071 },
    { lat: 48.356683, lng: 17.470798 },
    { lat: 48.356643, lng: 17.470879 },
    { lat: 48.356601, lng: 17.470964 },
    { lat: 48.356555, lng: 17.471057 },
    { lat: 48.356508, lng: 17.47115 },
    { lat: 48.356429, lng: 17.471305 },
    { lat: 48.356343, lng: 17.471474 },
    { lat: 48.356224, lng: 17.471708 },
    { lat: 48.356135, lng: 17.471874 },
    { lat: 48.356093, lng: 17.471958 },
    { lat: 48.356054, lng: 17.472043 },
    { lat: 48.35597, lng: 17.472205 },
    { lat: 48.355923, lng: 17.472299 },
    { lat: 48.355883, lng: 17.472375 },
    { lat: 48.355814, lng: 17.472513 },
    { lat: 48.355752, lng: 17.472634 },
    { lat: 48.355709, lng: 17.472719 },
    { lat: 48.355627, lng: 17.472882 },
    { lat: 48.355543, lng: 17.473042 },
    { lat: 48.355446, lng: 17.473235 },
    { lat: 48.355373, lng: 17.473384 },
    { lat: 48.355332, lng: 17.473463 },
    { lat: 48.355291, lng: 17.473544 },
    { lat: 48.355209, lng: 17.473712 },
    { lat: 48.355205, lng: 17.47372 },
    { lat: 48.355124, lng: 17.473884 },
    { lat: 48.355041, lng: 17.474041 },
    { lat: 48.354998, lng: 17.47413 },
    { lat: 48.354958, lng: 17.474208 },
    { lat: 48.354953, lng: 17.47422 },
    { lat: 48.354917, lng: 17.474289 },
    { lat: 48.354872, lng: 17.474367 },
    { lat: 48.354822, lng: 17.474449 },
    { lat: 48.354774, lng: 17.474525 },
    { lat: 48.354691, lng: 17.474653 },
    { lat: 48.354685, lng: 17.474662 },
    { lat: 48.354583, lng: 17.474801 },
    { lat: 48.354479, lng: 17.474935 },
    { lat: 48.354459, lng: 17.474971 },
    { lat: 48.354352, lng: 17.475118 },
    { lat: 48.354307, lng: 17.475177 },
    { lat: 48.354252, lng: 17.475252 },
    { lat: 48.354156, lng: 17.475389 },
    { lat: 48.354062, lng: 17.47553 },
    { lat: 48.353969, lng: 17.475657 },
    { lat: 48.353911, lng: 17.475727 },
    { lat: 48.353868, lng: 17.475791 },
    { lat: 48.353814, lng: 17.475866 },
    { lat: 48.353769, lng: 17.475931 },
    { lat: 48.353673, lng: 17.476067 },
    { lat: 48.353624, lng: 17.476137 },
    { lat: 48.353574, lng: 17.476199 },
    { lat: 48.353476, lng: 17.476336 },
    { lat: 48.353411, lng: 17.476428 },
    { lat: 48.353383, lng: 17.476467 },
    { lat: 48.353366, lng: 17.476492 },
    { lat: 48.35333, lng: 17.476528 },
    { lat: 48.353298, lng: 17.476572 },
    { lat: 48.353282, lng: 17.476593 },
    { lat: 48.353275, lng: 17.476602 },
    { lat: 48.35318, lng: 17.476724 },
    { lat: 48.353078, lng: 17.476843 },
    { lat: 48.352982, lng: 17.476968 },
    { lat: 48.352969, lng: 17.47698 },
    { lat: 48.352956, lng: 17.476998 },
    { lat: 48.352876, lng: 17.477105 },
    { lat: 48.352785, lng: 17.477234 },
    { lat: 48.35269, lng: 17.477371 },
    { lat: 48.35258, lng: 17.477524 },
    { lat: 48.352469, lng: 17.4776425 },
    { lat: 48.3510735, lng: 17.480353 },
    { lat: 48.3493817, lng: 17.4832536 },
    { lat: 48.3457194, lng: 17.4907855 },
    { lat: 48.345053, lng: 17.4919976 },
    { lat: 48.3434049, lng: 17.4955638 },
    { lat: 48.3430753, lng: 17.496288 },
    { lat: 48.3419464, lng: 17.4993939 },
    { lat: 48.3411383, lng: 17.5011637 },
    { lat: 48.3406561, lng: 17.5020997 },
    { lat: 48.3394631, lng: 17.506146 },
    { lat: 48.3406657, lng: 17.5075711 },
    { lat: 48.341204, lng: 17.5083234 },
    { lat: 48.3416037, lng: 17.5087816 },
    { lat: 48.3462443, lng: 17.5138601 },
    { lat: 48.3463379, lng: 17.5139297 },
    { lat: 48.3465766, lng: 17.5140945 },
    { lat: 48.347488, lng: 17.5150677 },
    { lat: 48.3485837, lng: 17.5164096 },
    { lat: 48.3497498, lng: 17.518062 },
    { lat: 48.3518042, lng: 17.5213348 },
    { lat: 48.3523952, lng: 17.5220977 },
    { lat: 48.3527255, lng: 17.5225671 },
    { lat: 48.3528179, lng: 17.5224391 },
    { lat: 48.3529413, lng: 17.5224775 },
    { lat: 48.3532222, lng: 17.5222446 },
    { lat: 48.3534749, lng: 17.521922 },
    { lat: 48.3538654, lng: 17.521248 },
    { lat: 48.3540663, lng: 17.5205221 },
    { lat: 48.3540756, lng: 17.5204903 },
    { lat: 48.3552157, lng: 17.518946 },
    { lat: 48.355448, lng: 17.5187446 },
    { lat: 48.3558527, lng: 17.5185926 },
    { lat: 48.3571271, lng: 17.5186005 },
    { lat: 48.3587054, lng: 17.51838 },
    { lat: 48.3593547, lng: 17.5180078 },
    { lat: 48.3606317, lng: 17.5170494 },
    { lat: 48.3615873, lng: 17.516188 },
    { lat: 48.3624078, lng: 17.5153337 },
    { lat: 48.3625159, lng: 17.5153096 },
    { lat: 48.3627661, lng: 17.5155474 },
    { lat: 48.3632189, lng: 17.5142388 },
  ],
  Cífer: [
    { lat: 48.3238076, lng: 17.5382647 },
    { lat: 48.3264108, lng: 17.5356661 },
    { lat: 48.3308413, lng: 17.532571 },
    { lat: 48.3333962, lng: 17.5292881 },
    { lat: 48.3353185, lng: 17.5270988 },
    { lat: 48.3356551, lng: 17.5266067 },
    { lat: 48.3375383, lng: 17.524299 },
    { lat: 48.3398237, lng: 17.521061 },
    { lat: 48.3421431, lng: 17.5187252 },
    { lat: 48.3443642, lng: 17.5154569 },
    { lat: 48.3456443, lng: 17.5144219 },
    { lat: 48.346327, lng: 17.5139551 },
    { lat: 48.3463379, lng: 17.5139297 },
    { lat: 48.3462443, lng: 17.5138601 },
    { lat: 48.3416037, lng: 17.5087816 },
    { lat: 48.341204, lng: 17.5083234 },
    { lat: 48.3406657, lng: 17.5075711 },
    { lat: 48.3394631, lng: 17.506146 },
    { lat: 48.3406561, lng: 17.5020997 },
    { lat: 48.3411383, lng: 17.5011637 },
    { lat: 48.3419464, lng: 17.4993939 },
    { lat: 48.3430753, lng: 17.496288 },
    { lat: 48.3434049, lng: 17.4955638 },
    { lat: 48.345053, lng: 17.4919976 },
    { lat: 48.3457194, lng: 17.4907855 },
    { lat: 48.3493817, lng: 17.4832536 },
    { lat: 48.3510735, lng: 17.480353 },
    { lat: 48.352469, lng: 17.4776425 },
    { lat: 48.35258, lng: 17.477524 },
    { lat: 48.352433, lng: 17.477423 },
    { lat: 48.352205, lng: 17.477172 },
    { lat: 48.35197, lng: 17.476827 },
    { lat: 48.351737, lng: 17.476543 },
    { lat: 48.351515, lng: 17.476295 },
    { lat: 48.351251, lng: 17.475974 },
    { lat: 48.351172, lng: 17.475901 },
    { lat: 48.350885, lng: 17.475672 },
    { lat: 48.350397, lng: 17.475193 },
    { lat: 48.350032, lng: 17.474954 },
    { lat: 48.349574, lng: 17.474615 },
    { lat: 48.349258, lng: 17.474398 },
    { lat: 48.348981, lng: 17.474192 },
    { lat: 48.348792, lng: 17.47407 },
    { lat: 48.348593, lng: 17.473937 },
    { lat: 48.347883, lng: 17.473651 },
    { lat: 48.346813, lng: 17.47331 },
    { lat: 48.34634, lng: 17.473218 },
    { lat: 48.345765, lng: 17.47322 },
    { lat: 48.345654, lng: 17.473225 },
    { lat: 48.345214, lng: 17.473292 },
    { lat: 48.344733, lng: 17.473445 },
    { lat: 48.344647, lng: 17.47347 },
    { lat: 48.344401, lng: 17.473506 },
    { lat: 48.344091, lng: 17.473527 },
    { lat: 48.343774, lng: 17.47349 },
    { lat: 48.343423, lng: 17.473426 },
    { lat: 48.34267, lng: 17.473339 },
    { lat: 48.341649, lng: 17.473221 },
    { lat: 48.341321, lng: 17.473057 },
    { lat: 48.341076, lng: 17.472891 },
    { lat: 48.340855, lng: 17.472685 },
    { lat: 48.340566, lng: 17.472485 },
    { lat: 48.340404, lng: 17.472344 },
    { lat: 48.340032, lng: 17.471865 },
    { lat: 48.33962, lng: 17.471385 },
    { lat: 48.339272, lng: 17.470771 },
    { lat: 48.339081, lng: 17.470541 },
    { lat: 48.338558, lng: 17.469582 },
    { lat: 48.338294, lng: 17.469189 },
    { lat: 48.338069, lng: 17.468888 },
    { lat: 48.337705, lng: 17.468519 },
    { lat: 48.33761, lng: 17.46834 },
    { lat: 48.336991, lng: 17.467314 },
    { lat: 48.336554, lng: 17.466723 },
    { lat: 48.336327, lng: 17.466546 },
    { lat: 48.335843, lng: 17.466305 },
    { lat: 48.335588, lng: 17.466133 },
    { lat: 48.335239, lng: 17.465802 },
    { lat: 48.335034, lng: 17.465629 },
    { lat: 48.334706, lng: 17.465387 },
    { lat: 48.334308, lng: 17.465068 },
    { lat: 48.334288, lng: 17.465052 },
    { lat: 48.333041, lng: 17.464544 },
    { lat: 48.332763, lng: 17.464389 },
    { lat: 48.332395, lng: 17.464142 },
    { lat: 48.331289, lng: 17.463284 },
    { lat: 48.330735, lng: 17.462877 },
    { lat: 48.330566, lng: 17.462784 },
    { lat: 48.330207, lng: 17.462693 },
    { lat: 48.329614, lng: 17.46261 },
    { lat: 48.328727, lng: 17.462678 },
    { lat: 48.328098, lng: 17.462714 },
    { lat: 48.327539, lng: 17.462745 },
    { lat: 48.327439, lng: 17.462748 },
    { lat: 48.327433, lng: 17.462748 },
    { lat: 48.326755, lng: 17.462716 },
    { lat: 48.326395, lng: 17.462669 },
    { lat: 48.326006, lng: 17.462585 },
    { lat: 48.32579, lng: 17.462517 },
    { lat: 48.325588, lng: 17.462426 },
    { lat: 48.325276, lng: 17.462236 },
    { lat: 48.325131, lng: 17.462121 },
    { lat: 48.32516, lng: 17.462072 },
    { lat: 48.325208, lng: 17.461951 },
    { lat: 48.325209, lng: 17.461784 },
    { lat: 48.32521, lng: 17.461738 },
    { lat: 48.32523, lng: 17.461722 },
    { lat: 48.325248, lng: 17.461708 },
    { lat: 48.325365, lng: 17.461612 },
    { lat: 48.325396, lng: 17.461542 },
    { lat: 48.325452, lng: 17.461418 },
    { lat: 48.325502, lng: 17.461204 },
    { lat: 48.325574, lng: 17.461008 },
    { lat: 48.325556, lng: 17.460977 },
    { lat: 48.325529, lng: 17.460965 },
    { lat: 48.325617, lng: 17.460746 },
    { lat: 48.32566, lng: 17.46058 },
    { lat: 48.32569, lng: 17.460591 },
    { lat: 48.325892, lng: 17.460625 },
    { lat: 48.325954, lng: 17.460535 },
    { lat: 48.326001, lng: 17.460377 },
    { lat: 48.326009, lng: 17.460194 },
    { lat: 48.325993, lng: 17.459835 },
    { lat: 48.325902, lng: 17.459794 },
    { lat: 48.325869, lng: 17.459778 },
    { lat: 48.325901, lng: 17.459622 },
    { lat: 48.325988, lng: 17.45933 },
    { lat: 48.325962, lng: 17.459312 },
    { lat: 48.325814, lng: 17.459196 },
    { lat: 48.325713, lng: 17.459059 },
    { lat: 48.325684, lng: 17.458878 },
    { lat: 48.325697, lng: 17.458785 },
    { lat: 48.325724, lng: 17.458777 },
    { lat: 48.325768, lng: 17.458704 },
    { lat: 48.325785, lng: 17.458723 },
    { lat: 48.325872, lng: 17.458611 },
    { lat: 48.325952, lng: 17.458469 },
    { lat: 48.326108, lng: 17.458545 },
    { lat: 48.326144, lng: 17.458558 },
    { lat: 48.326281, lng: 17.457926 },
    { lat: 48.326318, lng: 17.457945 },
    { lat: 48.326871, lng: 17.458185 },
    { lat: 48.326908, lng: 17.458078 },
    { lat: 48.326938, lng: 17.457984 },
    { lat: 48.326995, lng: 17.457812 },
    { lat: 48.32699, lng: 17.457776 },
    { lat: 48.326882, lng: 17.457721 },
    { lat: 48.326941, lng: 17.45768 },
    { lat: 48.326993, lng: 17.457665 },
    { lat: 48.327087, lng: 17.457662 },
    { lat: 48.32713, lng: 17.457674 },
    { lat: 48.327323, lng: 17.457702 },
    { lat: 48.327365, lng: 17.457632 },
    { lat: 48.327405, lng: 17.457596 },
    { lat: 48.32742, lng: 17.457532 },
    { lat: 48.327406, lng: 17.457476 },
    { lat: 48.327395, lng: 17.457446 },
    { lat: 48.327332, lng: 17.457338 },
    { lat: 48.327303, lng: 17.45727 },
    { lat: 48.327288, lng: 17.457192 },
    { lat: 48.327287, lng: 17.45717 },
    { lat: 48.327218, lng: 17.457014 },
    { lat: 48.327266, lng: 17.45695 },
    { lat: 48.327158, lng: 17.456882 },
    { lat: 48.327167, lng: 17.456826 },
    { lat: 48.327179, lng: 17.456759 },
    { lat: 48.327205, lng: 17.456615 },
    { lat: 48.327222, lng: 17.456519 },
    { lat: 48.327019, lng: 17.456412 },
    { lat: 48.327043, lng: 17.456298 },
    { lat: 48.327002, lng: 17.456262 },
    { lat: 48.326955, lng: 17.456223 },
    { lat: 48.326876, lng: 17.456093 },
    { lat: 48.326801, lng: 17.456048 },
    { lat: 48.326725, lng: 17.455992 },
    { lat: 48.326712, lng: 17.45598 },
    { lat: 48.326669, lng: 17.455928 },
    { lat: 48.326655, lng: 17.455913 },
    { lat: 48.326554, lng: 17.455792 },
    { lat: 48.326481, lng: 17.45569 },
    { lat: 48.326386, lng: 17.455584 },
    { lat: 48.32628, lng: 17.455495 },
    { lat: 48.326024, lng: 17.455259 },
    { lat: 48.32605, lng: 17.455184 },
    { lat: 48.326018, lng: 17.455119 },
    { lat: 48.325944, lng: 17.455022 },
    { lat: 48.325912, lng: 17.454993 },
    { lat: 48.325862, lng: 17.45499 },
    { lat: 48.325839, lng: 17.454984 },
    { lat: 48.325385, lng: 17.454672 },
    { lat: 48.324998, lng: 17.454378 },
    { lat: 48.324312, lng: 17.453771 },
    { lat: 48.324243, lng: 17.453715 },
    { lat: 48.323256, lng: 17.452798 },
    { lat: 48.322536, lng: 17.452119 },
    { lat: 48.320801, lng: 17.450425 },
    { lat: 48.319614, lng: 17.449266 },
    { lat: 48.319463, lng: 17.44931 },
    { lat: 48.3192, lng: 17.449453 },
    { lat: 48.319058, lng: 17.449577 },
    { lat: 48.318984, lng: 17.449641 },
    { lat: 48.318817, lng: 17.449826 },
    { lat: 48.318514, lng: 17.450161 },
    { lat: 48.318115, lng: 17.450637 },
    { lat: 48.318014, lng: 17.450757 },
    { lat: 48.317852, lng: 17.450929 },
    { lat: 48.317649, lng: 17.451192 },
    { lat: 48.317334, lng: 17.451691 },
    { lat: 48.317278, lng: 17.451779 },
    { lat: 48.317062, lng: 17.452125 },
    { lat: 48.316989, lng: 17.452227 },
    { lat: 48.316777, lng: 17.452526 },
    { lat: 48.316666, lng: 17.452682 },
    { lat: 48.316568, lng: 17.452801 },
    { lat: 48.316258, lng: 17.453221 },
    { lat: 48.316237, lng: 17.453248 },
    { lat: 48.31591, lng: 17.45386 },
    { lat: 48.315843, lng: 17.45398 },
    { lat: 48.315662, lng: 17.454459 },
    { lat: 48.315605, lng: 17.45459 },
    { lat: 48.31526, lng: 17.455452 },
    { lat: 48.315212, lng: 17.455563 },
    { lat: 48.315017, lng: 17.45598 },
    { lat: 48.314782, lng: 17.45641 },
    { lat: 48.31454, lng: 17.45678 },
    { lat: 48.314313, lng: 17.457077 },
    { lat: 48.314116, lng: 17.457328 },
    { lat: 48.314085, lng: 17.457367 },
    { lat: 48.313838, lng: 17.457675 },
    { lat: 48.31368, lng: 17.457939 },
    { lat: 48.313529, lng: 17.458237 },
    { lat: 48.313395, lng: 17.458515 },
    { lat: 48.313315, lng: 17.458693 },
    { lat: 48.313026, lng: 17.459314 },
    { lat: 48.312931, lng: 17.45952 },
    { lat: 48.312572, lng: 17.460255 },
    { lat: 48.312371, lng: 17.460047 },
    { lat: 48.311722, lng: 17.459422 },
    { lat: 48.311456, lng: 17.459143 },
    { lat: 48.311378, lng: 17.459057 },
    { lat: 48.310942, lng: 17.458658 },
    { lat: 48.310728, lng: 17.458457 },
    { lat: 48.310549, lng: 17.458291 },
    { lat: 48.310224, lng: 17.458021 },
    { lat: 48.30996, lng: 17.457804 },
    { lat: 48.30965, lng: 17.457596 },
    { lat: 48.309374, lng: 17.457885 },
    { lat: 48.309271, lng: 17.457959 },
    { lat: 48.309205, lng: 17.458 },
    { lat: 48.309142, lng: 17.458019 },
    { lat: 48.309134, lng: 17.458021 },
    { lat: 48.309126, lng: 17.458023 },
    { lat: 48.308946, lng: 17.45813 },
    { lat: 48.308845, lng: 17.458178 },
    { lat: 48.308655, lng: 17.458242 },
    { lat: 48.308181, lng: 17.458402 },
    { lat: 48.307932, lng: 17.458559 },
    { lat: 48.307855, lng: 17.458612 },
    { lat: 48.30769, lng: 17.458726 },
    { lat: 48.307603, lng: 17.458785 },
    { lat: 48.307528, lng: 17.458827 },
    { lat: 48.307516, lng: 17.458834 },
    { lat: 48.307435, lng: 17.458886 },
    { lat: 48.307275, lng: 17.458986 },
    { lat: 48.306997, lng: 17.4591 },
    { lat: 48.306911, lng: 17.459136 },
    { lat: 48.306732, lng: 17.459227 },
    { lat: 48.306638, lng: 17.459273 },
    { lat: 48.30654, lng: 17.459323 },
    { lat: 48.306451, lng: 17.459368 },
    { lat: 48.306289, lng: 17.459449 },
    { lat: 48.306038, lng: 17.459575 },
    { lat: 48.305957, lng: 17.459619 },
    { lat: 48.305813, lng: 17.459697 },
    { lat: 48.305778, lng: 17.459762 },
    { lat: 48.306102, lng: 17.460173 },
    { lat: 48.306514, lng: 17.460707 },
    { lat: 48.306839, lng: 17.461128 },
    { lat: 48.307355, lng: 17.46179 },
    { lat: 48.307844, lng: 17.462318 },
    { lat: 48.308071, lng: 17.46257 },
    { lat: 48.308278, lng: 17.462792 },
    { lat: 48.308605, lng: 17.463147 },
    { lat: 48.308839, lng: 17.463372 },
    { lat: 48.309362, lng: 17.46378 },
    { lat: 48.309586, lng: 17.463956 },
    { lat: 48.309738, lng: 17.464089 },
    { lat: 48.310383, lng: 17.464604 },
    { lat: 48.310543, lng: 17.464738 },
    { lat: 48.310435, lng: 17.465013 },
    { lat: 48.31027, lng: 17.465435 },
    { lat: 48.310094, lng: 17.465881 },
    { lat: 48.309959, lng: 17.466231 },
    { lat: 48.309819, lng: 17.466306 },
    { lat: 48.3098062, lng: 17.4663139 },
    { lat: 48.309364, lng: 17.466587 },
    { lat: 48.309094, lng: 17.466753 },
    { lat: 48.308811, lng: 17.466928 },
    { lat: 48.308537, lng: 17.467097 },
    { lat: 48.308348, lng: 17.467214 },
    { lat: 48.30802, lng: 17.467414 },
    { lat: 48.307619, lng: 17.467655 },
    { lat: 48.307267, lng: 17.467872 },
    { lat: 48.306952, lng: 17.468064 },
    { lat: 48.30665, lng: 17.468243 },
    { lat: 48.306328, lng: 17.468443 },
    { lat: 48.305743, lng: 17.468802 },
    { lat: 48.305505, lng: 17.468943 },
    { lat: 48.305256, lng: 17.469099 },
    { lat: 48.304978, lng: 17.469271 },
    { lat: 48.304754, lng: 17.46941 },
    { lat: 48.304468, lng: 17.469587 },
    { lat: 48.304396, lng: 17.469631 },
    { lat: 48.304202, lng: 17.469753 },
    { lat: 48.304019, lng: 17.46986 },
    { lat: 48.3034, lng: 17.47023 },
    { lat: 48.302908, lng: 17.470589 },
    { lat: 48.302402, lng: 17.470958 },
    { lat: 48.302016, lng: 17.471238 },
    { lat: 48.301656, lng: 17.471507 },
    { lat: 48.301471, lng: 17.471647 },
    { lat: 48.301524, lng: 17.471647 },
    { lat: 48.301402, lng: 17.471735 },
    { lat: 48.300828, lng: 17.471689 },
    { lat: 48.30053, lng: 17.471631 },
    { lat: 48.30046, lng: 17.471713 },
    { lat: 48.300484, lng: 17.471722 },
    { lat: 48.300261, lng: 17.471972 },
    { lat: 48.300098, lng: 17.472237 },
    { lat: 48.299929, lng: 17.472511 },
    { lat: 48.29955, lng: 17.473142 },
    { lat: 48.299344, lng: 17.473485 },
    { lat: 48.299166, lng: 17.473799 },
    { lat: 48.298973, lng: 17.474123 },
    { lat: 48.298809, lng: 17.474409 },
    { lat: 48.298565, lng: 17.474828 },
    { lat: 48.298386, lng: 17.475122 },
    { lat: 48.298211, lng: 17.475426 },
    { lat: 48.298039, lng: 17.475726 },
    { lat: 48.297847, lng: 17.476048 },
    { lat: 48.297641, lng: 17.476412 },
    { lat: 48.297527, lng: 17.476621 },
    { lat: 48.297443, lng: 17.476839 },
    { lat: 48.297298, lng: 17.477222 },
    { lat: 48.297178, lng: 17.477548 },
    { lat: 48.297032, lng: 17.477916 },
    { lat: 48.296928, lng: 17.478174 },
    { lat: 48.296778, lng: 17.478469 },
    { lat: 48.296636, lng: 17.478762 },
    { lat: 48.296501, lng: 17.479032 },
    { lat: 48.296345, lng: 17.47934 },
    { lat: 48.29618, lng: 17.47968 },
    { lat: 48.296037, lng: 17.47997 },
    { lat: 48.295834, lng: 17.480348 },
    { lat: 48.295702, lng: 17.480606 },
    { lat: 48.295557, lng: 17.48089 },
    { lat: 48.295416, lng: 17.481163 },
    { lat: 48.295221, lng: 17.481458 },
    { lat: 48.295045, lng: 17.481721 },
    { lat: 48.294895, lng: 17.481959 },
    { lat: 48.294672, lng: 17.482313 },
    { lat: 48.294463, lng: 17.48264 },
    { lat: 48.294259, lng: 17.482953 },
    { lat: 48.293984, lng: 17.483353 },
    { lat: 48.293795, lng: 17.483632 },
    { lat: 48.293646, lng: 17.483845 },
    { lat: 48.293469, lng: 17.484066 },
    { lat: 48.293259, lng: 17.484342 },
    { lat: 48.293068, lng: 17.484583 },
    { lat: 48.292945, lng: 17.484747 },
    { lat: 48.292757, lng: 17.485027 },
    { lat: 48.292596, lng: 17.485277 },
    { lat: 48.292448, lng: 17.485598 },
    { lat: 48.292307, lng: 17.485892 },
    { lat: 48.292187, lng: 17.48613 },
    { lat: 48.292043, lng: 17.486438 },
    { lat: 48.291901, lng: 17.486731 },
    { lat: 48.291788, lng: 17.486966 },
    { lat: 48.291671, lng: 17.487218 },
    { lat: 48.291534, lng: 17.487496 },
    { lat: 48.291318, lng: 17.488103 },
    { lat: 48.291231, lng: 17.488404 },
    { lat: 48.29112, lng: 17.488767 },
    { lat: 48.291017, lng: 17.489093 },
    { lat: 48.290945, lng: 17.489334 },
    { lat: 48.290809, lng: 17.489718 },
    { lat: 48.290695, lng: 17.490051 },
    { lat: 48.290595, lng: 17.490335 },
    { lat: 48.290503, lng: 17.490567 },
    { lat: 48.290392, lng: 17.49087 },
    { lat: 48.290242, lng: 17.491273 },
    { lat: 48.290087, lng: 17.491678 },
    { lat: 48.289979, lng: 17.492053 },
    { lat: 48.28985, lng: 17.492476 },
    { lat: 48.28973, lng: 17.492917 },
    { lat: 48.28961, lng: 17.493312 },
    { lat: 48.289503, lng: 17.493656 },
    { lat: 48.289384, lng: 17.49407 },
    { lat: 48.28921, lng: 17.493839 },
    { lat: 48.288966, lng: 17.493507 },
    { lat: 48.288808, lng: 17.493304 },
    { lat: 48.288782, lng: 17.49335 },
    { lat: 48.288796, lng: 17.49337 },
    { lat: 48.288668, lng: 17.493511 },
    { lat: 48.288405, lng: 17.493948 },
    { lat: 48.288145, lng: 17.494416 },
    { lat: 48.2881379, lng: 17.4944301 },
    { lat: 48.287949, lng: 17.494805 },
    { lat: 48.287722, lng: 17.49518 },
    { lat: 48.287496, lng: 17.49558 },
    { lat: 48.28744, lng: 17.49574 },
    { lat: 48.289668, lng: 17.498195 },
    { lat: 48.289744, lng: 17.498293 },
    { lat: 48.289798, lng: 17.499105 },
    { lat: 48.289814, lng: 17.499912 },
    { lat: 48.289785, lng: 17.500163 },
    { lat: 48.289721, lng: 17.500449 },
    { lat: 48.289622, lng: 17.500768 },
    { lat: 48.289463, lng: 17.501058 },
    { lat: 48.289321, lng: 17.501315 },
    { lat: 48.289156, lng: 17.501605 },
    { lat: 48.288931, lng: 17.501979 },
    { lat: 48.288445, lng: 17.502698 },
    { lat: 48.288263, lng: 17.502952 },
    { lat: 48.287981, lng: 17.503261 },
    { lat: 48.287703, lng: 17.50353 },
    { lat: 48.287441, lng: 17.503733 },
    { lat: 48.287336, lng: 17.503815 },
    { lat: 48.28716, lng: 17.503913 },
    { lat: 48.286953, lng: 17.504069 },
    { lat: 48.286809, lng: 17.504177 },
    { lat: 48.286531, lng: 17.504459 },
    { lat: 48.286307, lng: 17.504674 },
    { lat: 48.286167, lng: 17.504816 },
    { lat: 48.28613, lng: 17.504853 },
    { lat: 48.285841, lng: 17.505142 },
    { lat: 48.285477, lng: 17.505544 },
    { lat: 48.284883, lng: 17.506182 },
    { lat: 48.2848728, lng: 17.5061924 },
    { lat: 48.2848863, lng: 17.5062085 },
    { lat: 48.2858106, lng: 17.5072729 },
    { lat: 48.2885116, lng: 17.5109979 },
    { lat: 48.2906516, lng: 17.5137826 },
    { lat: 48.2932711, lng: 17.5168404 },
    { lat: 48.2945137, lng: 17.5183999 },
    { lat: 48.2955362, lng: 17.5195516 },
    { lat: 48.2967404, lng: 17.5212174 },
    { lat: 48.2969146, lng: 17.5213619 },
    { lat: 48.2970385, lng: 17.5216145 },
    { lat: 48.2971119, lng: 17.5217641 },
    { lat: 48.2972545, lng: 17.5219256 },
    { lat: 48.2972867, lng: 17.5220969 },
    { lat: 48.2973639, lng: 17.5221198 },
    { lat: 48.2974071, lng: 17.5222803 },
    { lat: 48.2976676, lng: 17.5227008 },
    { lat: 48.2999553, lng: 17.5261015 },
    { lat: 48.303176, lng: 17.5303948 },
    { lat: 48.305247, lng: 17.5329547 },
    { lat: 48.3088454, lng: 17.5375753 },
    { lat: 48.3093328, lng: 17.5382741 },
    { lat: 48.3099627, lng: 17.5393161 },
    { lat: 48.3109534, lng: 17.5407385 },
    { lat: 48.3114235, lng: 17.5415041 },
    { lat: 48.3117634, lng: 17.5421585 },
    { lat: 48.3117704, lng: 17.5422678 },
    { lat: 48.3118669, lng: 17.5424849 },
    { lat: 48.3126609, lng: 17.5436738 },
    { lat: 48.314832, lng: 17.547388 },
    { lat: 48.3157483, lng: 17.5487397 },
    { lat: 48.3161181, lng: 17.5480222 },
    { lat: 48.3173643, lng: 17.5460658 },
    { lat: 48.3181622, lng: 17.5450094 },
    { lat: 48.3188568, lng: 17.5439835 },
    { lat: 48.3195061, lng: 17.5433621 },
    { lat: 48.3216436, lng: 17.5404558 },
    { lat: 48.322285, lng: 17.5397474 },
    { lat: 48.3238076, lng: 17.5382647 },
  ],
  JaslovskéBohunice: [
    { lat: 48.4726332, lng: 17.6095246 },
    { lat: 48.4721851, lng: 17.6098873 },
    { lat: 48.4721225, lng: 17.6100041 },
    { lat: 48.4720556, lng: 17.6104107 },
    { lat: 48.4719311, lng: 17.6106594 },
    { lat: 48.4718307, lng: 17.6107324 },
    { lat: 48.4717229, lng: 17.6107086 },
    { lat: 48.4714863, lng: 17.6109114 },
    { lat: 48.4711886, lng: 17.6109905 },
    { lat: 48.4709729, lng: 17.6114201 },
    { lat: 48.4709321, lng: 17.6118472 },
    { lat: 48.4706139, lng: 17.6122455 },
    { lat: 48.4703196, lng: 17.6124497 },
    { lat: 48.469894, lng: 17.6129025 },
    { lat: 48.4690495, lng: 17.614124 },
    { lat: 48.4685322, lng: 17.6146229 },
    { lat: 48.4673576, lng: 17.6160555 },
    { lat: 48.4671168, lng: 17.6162822 },
    { lat: 48.4663081, lng: 17.6167457 },
    { lat: 48.4654369, lng: 17.6169722 },
    { lat: 48.4646809, lng: 17.617337 },
    { lat: 48.4638928, lng: 17.6181284 },
    { lat: 48.4633931, lng: 17.6183692 },
    { lat: 48.4623911, lng: 17.6184915 },
    { lat: 48.4617894, lng: 17.6193192 },
    { lat: 48.4606894, lng: 17.6211098 },
    { lat: 48.4604041, lng: 17.6214389 },
    { lat: 48.4589884, lng: 17.622108 },
    { lat: 48.4587643, lng: 17.6223342 },
    { lat: 48.4581845, lng: 17.6226304 },
    { lat: 48.4579701, lng: 17.6228132 },
    { lat: 48.4578828, lng: 17.6230394 },
    { lat: 48.4573429, lng: 17.6236323 },
    { lat: 48.457468, lng: 17.6240592 },
    { lat: 48.4574247, lng: 17.6241045 },
    { lat: 48.4575198, lng: 17.6246129 },
    { lat: 48.4575307, lng: 17.6250382 },
    { lat: 48.4575445, lng: 17.6250956 },
    { lat: 48.4563452, lng: 17.6259029 },
    { lat: 48.4550363, lng: 17.6265624 },
    { lat: 48.4541843, lng: 17.6271811 },
    { lat: 48.4536457, lng: 17.6276318 },
    { lat: 48.452905, lng: 17.6284585 },
    { lat: 48.452546, lng: 17.6287819 },
    { lat: 48.4507904, lng: 17.6302254 },
    { lat: 48.4496427, lng: 17.631422 },
    { lat: 48.4492536, lng: 17.6319884 },
    { lat: 48.4489151, lng: 17.6323665 },
    { lat: 48.4473993, lng: 17.633298 },
    { lat: 48.4468021, lng: 17.6337459 },
    { lat: 48.445749, lng: 17.6348884 },
    { lat: 48.4456377, lng: 17.634933 },
    { lat: 48.4455519, lng: 17.6349672 },
    { lat: 48.4467095, lng: 17.6369134 },
    { lat: 48.4489784, lng: 17.6402308 },
    { lat: 48.4508002, lng: 17.6432942 },
    { lat: 48.4516351, lng: 17.6449401 },
    { lat: 48.4522855, lng: 17.6460754 },
    { lat: 48.4535018, lng: 17.6479534 },
    { lat: 48.4551362, lng: 17.6501741 },
    { lat: 48.4567647, lng: 17.6530625 },
    { lat: 48.4572149, lng: 17.6537262 },
    { lat: 48.4588804, lng: 17.6559063 },
    { lat: 48.460493, lng: 17.6584173 },
    { lat: 48.4611702, lng: 17.6596773 },
    { lat: 48.462037, lng: 17.6620884 },
    { lat: 48.4623074, lng: 17.662529 },
    { lat: 48.4623211, lng: 17.6628053 },
    { lat: 48.4625375, lng: 17.6634844 },
    { lat: 48.4627936, lng: 17.6638947 },
    { lat: 48.4632943, lng: 17.6645006 },
    { lat: 48.4637004, lng: 17.6652707 },
    { lat: 48.4640433, lng: 17.6646508 },
    { lat: 48.4642486, lng: 17.664392 },
    { lat: 48.4644562, lng: 17.6646818 },
    { lat: 48.4647037, lng: 17.6643411 },
    { lat: 48.4650608, lng: 17.6640726 },
    { lat: 48.4650453, lng: 17.6640075 },
    { lat: 48.4651924, lng: 17.6638697 },
    { lat: 48.4652569, lng: 17.6636706 },
    { lat: 48.4653609, lng: 17.6636014 },
    { lat: 48.4654433, lng: 17.6634208 },
    { lat: 48.4655522, lng: 17.6634104 },
    { lat: 48.4656989, lng: 17.6632096 },
    { lat: 48.4658371, lng: 17.6632359 },
    { lat: 48.4659326, lng: 17.6630453 },
    { lat: 48.466159, lng: 17.6631474 },
    { lat: 48.4663086, lng: 17.6628654 },
    { lat: 48.4664306, lng: 17.6629044 },
    { lat: 48.4665291, lng: 17.6627282 },
    { lat: 48.466912, lng: 17.6626043 },
    { lat: 48.4669158, lng: 17.662374 },
    { lat: 48.4668194, lng: 17.662278 },
    { lat: 48.4668714, lng: 17.6616448 },
    { lat: 48.4674967, lng: 17.6625099 },
    { lat: 48.4688003, lng: 17.6649574 },
    { lat: 48.4700569, lng: 17.6676288 },
    { lat: 48.4707589, lng: 17.6694314 },
    { lat: 48.4717484, lng: 17.6717165 },
    { lat: 48.4744675, lng: 17.6793321 },
    { lat: 48.4747792, lng: 17.6801108 },
    { lat: 48.4767983, lng: 17.6845723 },
    { lat: 48.476861, lng: 17.684759 },
    { lat: 48.476873, lng: 17.684745 },
    { lat: 48.477025, lng: 17.685048 },
    { lat: 48.477231, lng: 17.685425 },
    { lat: 48.477446, lng: 17.685786 },
    { lat: 48.477653, lng: 17.686129 },
    { lat: 48.477808, lng: 17.686398 },
    { lat: 48.478067, lng: 17.68694 },
    { lat: 48.478262, lng: 17.687336 },
    { lat: 48.478492, lng: 17.687794 },
    { lat: 48.478653, lng: 17.688048 },
    { lat: 48.478931, lng: 17.688445 },
    { lat: 48.479173, lng: 17.688817 },
    { lat: 48.479404, lng: 17.689167 },
    { lat: 48.47985, lng: 17.689807 },
    { lat: 48.480295, lng: 17.690392 },
    { lat: 48.480581, lng: 17.690842 },
    { lat: 48.480742, lng: 17.691056 },
    { lat: 48.48085, lng: 17.691107 },
    { lat: 48.480867, lng: 17.691111 },
    { lat: 48.481012, lng: 17.691104 },
    { lat: 48.481187, lng: 17.691094 },
    { lat: 48.481392, lng: 17.69105 },
    { lat: 48.481541, lng: 17.690982 },
    { lat: 48.481941, lng: 17.690678 },
    { lat: 48.482424, lng: 17.690192 },
    { lat: 48.482926, lng: 17.689723 },
    { lat: 48.483347, lng: 17.68936 },
    { lat: 48.483668, lng: 17.689121 },
    { lat: 48.483724, lng: 17.689046 },
    { lat: 48.484049, lng: 17.688805 },
    { lat: 48.484403, lng: 17.688446 },
    { lat: 48.484964, lng: 17.687752 },
    { lat: 48.485003, lng: 17.687732 },
    { lat: 48.485219, lng: 17.687486 },
    { lat: 48.485367, lng: 17.68731 },
    { lat: 48.485457, lng: 17.68723 },
    { lat: 48.485522, lng: 17.687189 },
    { lat: 48.485557, lng: 17.687147 },
    { lat: 48.485815, lng: 17.687373 },
    { lat: 48.486195, lng: 17.687704 },
    { lat: 48.48647, lng: 17.687919 },
    { lat: 48.486487, lng: 17.687873 },
    { lat: 48.486501, lng: 17.687894 },
    { lat: 48.486559, lng: 17.687695 },
    { lat: 48.486614, lng: 17.687521 },
    { lat: 48.486722, lng: 17.68725 },
    { lat: 48.486973, lng: 17.686796 },
    { lat: 48.487113, lng: 17.686595 },
    { lat: 48.48745, lng: 17.686127 },
    { lat: 48.487725, lng: 17.685691 },
    { lat: 48.488026, lng: 17.685196 },
    { lat: 48.488357, lng: 17.68465 },
    { lat: 48.488643, lng: 17.684293 },
    { lat: 48.488783, lng: 17.684152 },
    { lat: 48.489077, lng: 17.683841 },
    { lat: 48.489362, lng: 17.683533 },
    { lat: 48.489632, lng: 17.68325 },
    { lat: 48.490007, lng: 17.682912 },
    { lat: 48.490231, lng: 17.682736 },
    { lat: 48.4904349, lng: 17.6825906 },
    { lat: 48.490447, lng: 17.682582 },
    { lat: 48.490873, lng: 17.682218 },
    { lat: 48.4917403, lng: 17.6814852 },
    { lat: 48.491937, lng: 17.681287 },
    { lat: 48.492228, lng: 17.681028 },
    { lat: 48.492318, lng: 17.680927 },
    { lat: 48.492447, lng: 17.680736 },
    { lat: 48.492622, lng: 17.680472 },
    { lat: 48.492733, lng: 17.680366 },
    { lat: 48.492876, lng: 17.680259 },
    { lat: 48.492982, lng: 17.680195 },
    { lat: 48.493337, lng: 17.679981 },
    { lat: 48.493539, lng: 17.679822 },
    { lat: 48.494014, lng: 17.679439 },
    { lat: 48.494334, lng: 17.679117 },
    { lat: 48.494389, lng: 17.679038 },
    { lat: 48.494595, lng: 17.678743 },
    { lat: 48.494662, lng: 17.678647 },
    { lat: 48.494857, lng: 17.678436 },
    { lat: 48.495143, lng: 17.678069 },
    { lat: 48.495493, lng: 17.677622 },
    { lat: 48.495959, lng: 17.677143 },
    { lat: 48.496199, lng: 17.67688 },
    { lat: 48.496308, lng: 17.676743 },
    { lat: 48.496386, lng: 17.676599 },
    { lat: 48.496559, lng: 17.676226 },
    { lat: 48.496706, lng: 17.67597 },
    { lat: 48.496777, lng: 17.675862 },
    { lat: 48.497049, lng: 17.675485 },
    { lat: 48.497091, lng: 17.675399 },
    { lat: 48.497528, lng: 17.674787 },
    { lat: 48.497589, lng: 17.674715 },
    { lat: 48.498042, lng: 17.674239 },
    { lat: 48.498354, lng: 17.673823 },
    { lat: 48.498374, lng: 17.673797 },
    { lat: 48.4985674, lng: 17.6735446 },
    { lat: 48.4977052, lng: 17.6722102 },
    { lat: 48.497424, lng: 17.6714159 },
    { lat: 48.497266, lng: 17.6711452 },
    { lat: 48.4954194, lng: 17.6688758 },
    { lat: 48.4945187, lng: 17.6676152 },
    { lat: 48.4937018, lng: 17.6663064 },
    { lat: 48.4920856, lng: 17.663302 },
    { lat: 48.4910416, lng: 17.6606511 },
    { lat: 48.4901912, lng: 17.6578694 },
    { lat: 48.4894131, lng: 17.6548905 },
    { lat: 48.4893131, lng: 17.6539764 },
    { lat: 48.4892429, lng: 17.6527113 },
    { lat: 48.489085, lng: 17.6518292 },
    { lat: 48.4889738, lng: 17.6514594 },
    { lat: 48.488696, lng: 17.6505755 },
    { lat: 48.4874695, lng: 17.6482449 },
    { lat: 48.4867763, lng: 17.6467507 },
    { lat: 48.4867202, lng: 17.6464867 },
    { lat: 48.4865083, lng: 17.6459903 },
    { lat: 48.4867718, lng: 17.6455995 },
    { lat: 48.4867442, lng: 17.6454457 },
    { lat: 48.4869588, lng: 17.6451391 },
    { lat: 48.4861533, lng: 17.6433526 },
    { lat: 48.4865676, lng: 17.6422883 },
    { lat: 48.487502, lng: 17.6409266 },
    { lat: 48.4876935, lng: 17.6406488 },
    { lat: 48.487792, lng: 17.6405103 },
    { lat: 48.4890975, lng: 17.6387118 },
    { lat: 48.4892025, lng: 17.6384609 },
    { lat: 48.4894425, lng: 17.6381652 },
    { lat: 48.4897194, lng: 17.6379696 },
    { lat: 48.4905599, lng: 17.6378147 },
    { lat: 48.4908402, lng: 17.6376745 },
    { lat: 48.4910458, lng: 17.6374253 },
    { lat: 48.4913353, lng: 17.6373963 },
    { lat: 48.4914745, lng: 17.6371291 },
    { lat: 48.4918128, lng: 17.6367813 },
    { lat: 48.491941, lng: 17.6367546 },
    { lat: 48.4920685, lng: 17.6368258 },
    { lat: 48.4924179, lng: 17.6365648 },
    { lat: 48.4926811, lng: 17.6366182 },
    { lat: 48.4928451, lng: 17.6365126 },
    { lat: 48.4933108, lng: 17.6359104 },
    { lat: 48.4934013, lng: 17.6356255 },
    { lat: 48.4935839, lng: 17.6353679 },
    { lat: 48.4936106, lng: 17.635111 },
    { lat: 48.4937479, lng: 17.6349387 },
    { lat: 48.4939069, lng: 17.6345235 },
    { lat: 48.4940072, lng: 17.6340514 },
    { lat: 48.4939881, lng: 17.6339278 },
    { lat: 48.4943101, lng: 17.6333017 },
    { lat: 48.4943251, lng: 17.6328738 },
    { lat: 48.4944553, lng: 17.6325912 },
    { lat: 48.4948324, lng: 17.6324475 },
    { lat: 48.4951725, lng: 17.6321614 },
    { lat: 48.4943434, lng: 17.6309517 },
    { lat: 48.4944223, lng: 17.6307874 },
    { lat: 48.4945984, lng: 17.6306979 },
    { lat: 48.4948205, lng: 17.6306927 },
    { lat: 48.4949886, lng: 17.6305634 },
    { lat: 48.4951203, lng: 17.6303578 },
    { lat: 48.4953947, lng: 17.6302703 },
    { lat: 48.4955567, lng: 17.6300996 },
    { lat: 48.4957848, lng: 17.6297037 },
    { lat: 48.4959679, lng: 17.6296164 },
    { lat: 48.4968055, lng: 17.6297369 },
    { lat: 48.4971256, lng: 17.629423 },
    { lat: 48.4971737, lng: 17.6295214 },
    { lat: 48.4972266, lng: 17.6296294 },
    { lat: 48.4973603, lng: 17.6295808 },
    { lat: 48.4975586, lng: 17.6295024 },
    { lat: 48.4977714, lng: 17.6292767 },
    { lat: 48.4981932, lng: 17.6290537 },
    { lat: 48.4983099, lng: 17.6287711 },
    { lat: 48.4986127, lng: 17.6285404 },
    { lat: 48.4989028, lng: 17.628612 },
    { lat: 48.4993637, lng: 17.6283436 },
    { lat: 48.4994992, lng: 17.6283984 },
    { lat: 48.4997295, lng: 17.6281481 },
    { lat: 48.4999564, lng: 17.6283248 },
    { lat: 48.5001604, lng: 17.6282837 },
    { lat: 48.5004833, lng: 17.6279771 },
    { lat: 48.5005488, lng: 17.6280483 },
    { lat: 48.5008166, lng: 17.6278557 },
    { lat: 48.5010755, lng: 17.6277875 },
    { lat: 48.5013459, lng: 17.6276131 },
    { lat: 48.5027039, lng: 17.6262239 },
    { lat: 48.5036393, lng: 17.6248841 },
    { lat: 48.5048525, lng: 17.6236344 },
    { lat: 48.5039762, lng: 17.6216365 },
    { lat: 48.503894, lng: 17.6218198 },
    { lat: 48.5026063, lng: 17.6191019 },
    { lat: 48.5028834, lng: 17.6182104 },
    { lat: 48.5022912, lng: 17.6175911 },
    { lat: 48.5014796, lng: 17.6168604 },
    { lat: 48.5007188, lng: 17.6159309 },
    { lat: 48.4995976, lng: 17.6142249 },
    { lat: 48.4994855, lng: 17.6140411 },
    { lat: 48.4993633, lng: 17.6143441 },
    { lat: 48.4986362, lng: 17.615331 },
    { lat: 48.4981912, lng: 17.6157793 },
    { lat: 48.4979706, lng: 17.6161318 },
    { lat: 48.4975261, lng: 17.6165334 },
    { lat: 48.4959775, lng: 17.6183033 },
    { lat: 48.4952522, lng: 17.6193938 },
    { lat: 48.4945679, lng: 17.620597 },
    { lat: 48.4943909, lng: 17.6210149 },
    { lat: 48.493918, lng: 17.6206023 },
    { lat: 48.4930435, lng: 17.6219813 },
    { lat: 48.4906287, lng: 17.6195262 },
    { lat: 48.4894819, lng: 17.6182268 },
    { lat: 48.4893593, lng: 17.61789 },
    { lat: 48.4878318, lng: 17.6161362 },
    { lat: 48.486372, lng: 17.6139318 },
    { lat: 48.4853475, lng: 17.6126358 },
    { lat: 48.484207, lng: 17.6115429 },
    { lat: 48.4828515, lng: 17.6103874 },
    { lat: 48.4820149, lng: 17.6094951 },
    { lat: 48.4803638, lng: 17.6072159 },
    { lat: 48.47869, lng: 17.6050572 },
    { lat: 48.4782046, lng: 17.6043209 },
    { lat: 48.4770631, lng: 17.6025897 },
    { lat: 48.4761363, lng: 17.6038148 },
    { lat: 48.475037, lng: 17.6048435 },
    { lat: 48.4736773, lng: 17.6063121 },
    { lat: 48.474506, lng: 17.6075288 },
    { lat: 48.4731649, lng: 17.6090922 },
    { lat: 48.4727828, lng: 17.609357 },
    { lat: 48.4726332, lng: 17.6095246 },
  ],
  Zeleneč: [
    { lat: 48.3117704, lng: 17.5422678 },
    { lat: 48.3079942, lng: 17.5457208 },
    { lat: 48.3082262, lng: 17.5458897 },
    { lat: 48.304901, lng: 17.5495298 },
    { lat: 48.3027374, lng: 17.5519961 },
    { lat: 48.3022266, lng: 17.5512286 },
    { lat: 48.3012166, lng: 17.5524288 },
    { lat: 48.2998092, lng: 17.5535754 },
    { lat: 48.2976878, lng: 17.5501454 },
    { lat: 48.2970998, lng: 17.5493366 },
    { lat: 48.2965627, lng: 17.5488815 },
    { lat: 48.2953425, lng: 17.5517096 },
    { lat: 48.293659, lng: 17.555932 },
    { lat: 48.2936415, lng: 17.5559767 },
    { lat: 48.2942225, lng: 17.5571189 },
    { lat: 48.2944832, lng: 17.5584473 },
    { lat: 48.2953987, lng: 17.5601789 },
    { lat: 48.2955037, lng: 17.5602671 },
    { lat: 48.2959377, lng: 17.5610371 },
    { lat: 48.3080046, lng: 17.5829455 },
    { lat: 48.307997, lng: 17.5830381 },
    { lat: 48.3078511, lng: 17.583211 },
    { lat: 48.3097275, lng: 17.5897791 },
    { lat: 48.309398, lng: 17.5899268 },
    { lat: 48.3093136, lng: 17.5899643 },
    { lat: 48.3093314, lng: 17.5899962 },
    { lat: 48.3094659, lng: 17.590247 },
    { lat: 48.309756, lng: 17.5910648 },
    { lat: 48.3101655, lng: 17.5923591 },
    { lat: 48.3106369, lng: 17.5935999 },
    { lat: 48.3110629, lng: 17.5948865 },
    { lat: 48.3114593, lng: 17.5963665 },
    { lat: 48.3127578, lng: 17.60065 },
    { lat: 48.3136923, lng: 17.6027119 },
    { lat: 48.3142733, lng: 17.6047937 },
    { lat: 48.315268, lng: 17.6056825 },
    { lat: 48.3167995, lng: 17.6068658 },
    { lat: 48.3156936, lng: 17.61256 },
    { lat: 48.3158917, lng: 17.6126766 },
    { lat: 48.3165244, lng: 17.6131108 },
    { lat: 48.3167431, lng: 17.6134079 },
    { lat: 48.3171775, lng: 17.613707 },
    { lat: 48.3173227, lng: 17.6137353 },
    { lat: 48.3174496, lng: 17.6135463 },
    { lat: 48.317637, lng: 17.6135275 },
    { lat: 48.3178642, lng: 17.6137078 },
    { lat: 48.3172175, lng: 17.6152708 },
    { lat: 48.3167063, lng: 17.6148829 },
    { lat: 48.3165526, lng: 17.6151661 },
    { lat: 48.3169661, lng: 17.615778 },
    { lat: 48.317098, lng: 17.6163127 },
    { lat: 48.3176412, lng: 17.6172598 },
    { lat: 48.3178319, lng: 17.6177237 },
    { lat: 48.3182257, lng: 17.6180964 },
    { lat: 48.3185364, lng: 17.6181673 },
    { lat: 48.3187313, lng: 17.6182862 },
    { lat: 48.3189919, lng: 17.6186927 },
    { lat: 48.3190533, lng: 17.6205093 },
    { lat: 48.3192912, lng: 17.6212007 },
    { lat: 48.3210023, lng: 17.6210116 },
    { lat: 48.3228112, lng: 17.6219028 },
    { lat: 48.3228705, lng: 17.621931 },
    { lat: 48.3231921, lng: 17.6221224 },
    { lat: 48.3237482, lng: 17.6220978 },
    { lat: 48.3241942, lng: 17.6221868 },
    { lat: 48.3258227, lng: 17.6234968 },
    { lat: 48.3268907, lng: 17.6246923 },
    { lat: 48.3276499, lng: 17.6239996 },
    { lat: 48.3314117, lng: 17.6205698 },
    { lat: 48.3290539, lng: 17.6161653 },
    { lat: 48.3288817, lng: 17.6160499 },
    { lat: 48.328623, lng: 17.6156363 },
    { lat: 48.3277915, lng: 17.6160049 },
    { lat: 48.3278251, lng: 17.615393 },
    { lat: 48.3265759, lng: 17.6128136 },
    { lat: 48.3250656, lng: 17.6125895 },
    { lat: 48.3250527, lng: 17.6127794 },
    { lat: 48.3249727, lng: 17.6127645 },
    { lat: 48.3242499, lng: 17.6146398 },
    { lat: 48.3239429, lng: 17.6150169 },
    { lat: 48.3185992, lng: 17.6130454 },
    { lat: 48.3244892, lng: 17.6082191 },
    { lat: 48.3251956, lng: 17.6106618 },
    { lat: 48.3252846, lng: 17.6108347 },
    { lat: 48.3254551, lng: 17.6108374 },
    { lat: 48.3261015, lng: 17.6104164 },
    { lat: 48.3262518, lng: 17.6105973 },
    { lat: 48.3272141, lng: 17.6123497 },
    { lat: 48.3288676, lng: 17.6157191 },
    { lat: 48.3292111, lng: 17.6153275 },
    { lat: 48.3270447, lng: 17.610975 },
    { lat: 48.3278682, lng: 17.6101019 },
    { lat: 48.3278987, lng: 17.6099783 },
    { lat: 48.3306549, lng: 17.6070424 },
    { lat: 48.3311531, lng: 17.6079052 },
    { lat: 48.3311932, lng: 17.6080741 },
    { lat: 48.3330854, lng: 17.6074436 },
    { lat: 48.3348859, lng: 17.6066273 },
    { lat: 48.3353817, lng: 17.6064872 },
    { lat: 48.3356222, lng: 17.6063083 },
    { lat: 48.3367324, lng: 17.6052738 },
    { lat: 48.3371722, lng: 17.6047627 },
    { lat: 48.3378331, lng: 17.603786 },
    { lat: 48.3409879, lng: 17.6001564 },
    { lat: 48.3414097, lng: 17.5995072 },
    { lat: 48.3419836, lng: 17.5982964 },
    { lat: 48.3418671, lng: 17.5981689 },
    { lat: 48.3394032, lng: 17.5955268 },
    { lat: 48.3376587, lng: 17.5937824 },
    { lat: 48.3373658, lng: 17.5932942 },
    { lat: 48.3370304, lng: 17.592811 },
    { lat: 48.3353406, lng: 17.5904527 },
    { lat: 48.334775, lng: 17.5897557 },
    { lat: 48.33512, lng: 17.5890014 },
    { lat: 48.3306846, lng: 17.5841744 },
    { lat: 48.3303556, lng: 17.5847582 },
    { lat: 48.3294162, lng: 17.5835978 },
    { lat: 48.3276135, lng: 17.581371 },
    { lat: 48.3263162, lng: 17.579679 },
    { lat: 48.3242333, lng: 17.5766091 },
    { lat: 48.3221174, lng: 17.573804 },
    { lat: 48.3178018, lng: 17.5671142 },
    { lat: 48.316233, lng: 17.5645845 },
    { lat: 48.3137602, lng: 17.5593696 },
    { lat: 48.3108317, lng: 17.5537633 },
    { lat: 48.3121081, lng: 17.5525625 },
    { lat: 48.313106, lng: 17.5518974 },
    { lat: 48.3138851, lng: 17.5508131 },
    { lat: 48.3151811, lng: 17.5495678 },
    { lat: 48.3157483, lng: 17.5487397 },
    { lat: 48.314832, lng: 17.547388 },
    { lat: 48.3126609, lng: 17.5436738 },
    { lat: 48.3118669, lng: 17.5424849 },
    { lat: 48.3117704, lng: 17.5422678 },
  ],
  Šúrovce: [
    { lat: 48.3143927, lng: 17.6861605 },
    { lat: 48.3143063, lng: 17.6871619 },
    { lat: 48.3140123, lng: 17.6879523 },
    { lat: 48.3133096, lng: 17.6880163 },
    { lat: 48.312446, lng: 17.6883079 },
    { lat: 48.311985, lng: 17.6890639 },
    { lat: 48.3112157, lng: 17.6905201 },
    { lat: 48.3101727, lng: 17.6919165 },
    { lat: 48.3095151, lng: 17.6929558 },
    { lat: 48.30932, lng: 17.693266 },
    { lat: 48.310203, lng: 17.694446 },
    { lat: 48.311516, lng: 17.696451 },
    { lat: 48.313106, lng: 17.698677 },
    { lat: 48.313862, lng: 17.700031 },
    { lat: 48.314544, lng: 17.701746 },
    { lat: 48.31553, lng: 17.703955 },
    { lat: 48.315823, lng: 17.704176 },
    { lat: 48.316112, lng: 17.704692 },
    { lat: 48.316225, lng: 17.705258 },
    { lat: 48.315695, lng: 17.70804 },
    { lat: 48.315786, lng: 17.708743 },
    { lat: 48.316598, lng: 17.711966 },
    { lat: 48.316955, lng: 17.71269 },
    { lat: 48.317421, lng: 17.713309 },
    { lat: 48.317618, lng: 17.713751 },
    { lat: 48.317757, lng: 17.714216 },
    { lat: 48.317893, lng: 17.715191 },
    { lat: 48.317954, lng: 17.71702 },
    { lat: 48.31802, lng: 17.717825 },
    { lat: 48.318186, lng: 17.719039 },
    { lat: 48.318483, lng: 17.720671 },
    { lat: 48.319078, lng: 17.726065 },
    { lat: 48.319594, lng: 17.728814 },
    { lat: 48.320142, lng: 17.730061 },
    { lat: 48.320779, lng: 17.729398 },
    { lat: 48.321537, lng: 17.728884 },
    { lat: 48.322315, lng: 17.728097 },
    { lat: 48.322754, lng: 17.729297 },
    { lat: 48.322617, lng: 17.730052 },
    { lat: 48.321679, lng: 17.733814 },
    { lat: 48.321456, lng: 17.734462 },
    { lat: 48.320489, lng: 17.73522 },
    { lat: 48.320316, lng: 17.735722 },
    { lat: 48.320187, lng: 17.737378 },
    { lat: 48.320358, lng: 17.738766 },
    { lat: 48.320052, lng: 17.741935 },
    { lat: 48.318469, lng: 17.74221 },
    { lat: 48.317526, lng: 17.742964 },
    { lat: 48.316107, lng: 17.743201 },
    { lat: 48.315792, lng: 17.74413 },
    { lat: 48.316127, lng: 17.745871 },
    { lat: 48.31706, lng: 17.749781 },
    { lat: 48.317897, lng: 17.750063 },
    { lat: 48.319298, lng: 17.75042 },
    { lat: 48.327042, lng: 17.751386 },
    { lat: 48.327797, lng: 17.751896 },
    { lat: 48.328659, lng: 17.751573 },
    { lat: 48.329196, lng: 17.751192 },
    { lat: 48.329656, lng: 17.750635 },
    { lat: 48.33001, lng: 17.753172 },
    { lat: 48.330661, lng: 17.753389 },
    { lat: 48.331201, lng: 17.753686 },
    { lat: 48.33141, lng: 17.753644 },
    { lat: 48.331855, lng: 17.754113 },
    { lat: 48.33204, lng: 17.754142 },
    { lat: 48.332662, lng: 17.754512 },
    { lat: 48.333187, lng: 17.754619 },
    { lat: 48.333553, lng: 17.754596 },
    { lat: 48.334193, lng: 17.754836 },
    { lat: 48.335269, lng: 17.754921 },
    { lat: 48.336238, lng: 17.755595 },
    { lat: 48.33677, lng: 17.75608 },
    { lat: 48.33714, lng: 17.756573 },
    { lat: 48.337834, lng: 17.757065 },
    { lat: 48.339671, lng: 17.757342 },
    { lat: 48.3405, lng: 17.757749 },
    { lat: 48.340822, lng: 17.758001 },
    { lat: 48.340893, lng: 17.757556 },
    { lat: 48.340968, lng: 17.757159 },
    { lat: 48.341002, lng: 17.757162 },
    { lat: 48.341033, lng: 17.757176 },
    { lat: 48.341299, lng: 17.757031 },
    { lat: 48.341529, lng: 17.75688 },
    { lat: 48.341855, lng: 17.756656 },
    { lat: 48.3421, lng: 17.756376 },
    { lat: 48.342332, lng: 17.756126 },
    { lat: 48.342419, lng: 17.755965 },
    { lat: 48.342637, lng: 17.755344 },
    { lat: 48.342731, lng: 17.75499 },
    { lat: 48.342748, lng: 17.754617 },
    { lat: 48.342766, lng: 17.754195 },
    { lat: 48.342678, lng: 17.753645 },
    { lat: 48.342538, lng: 17.753398 },
    { lat: 48.342342, lng: 17.753061 },
    { lat: 48.342345, lng: 17.752619 },
    { lat: 48.342342, lng: 17.752541 },
    { lat: 48.342336, lng: 17.752513 },
    { lat: 48.342329, lng: 17.752434 },
    { lat: 48.342281, lng: 17.751918 },
    { lat: 48.342232, lng: 17.751415 },
    { lat: 48.342197, lng: 17.751009 },
    { lat: 48.342429, lng: 17.750455 },
    { lat: 48.342595, lng: 17.750097 },
    { lat: 48.342735, lng: 17.749813 },
    { lat: 48.34277, lng: 17.749739 },
    { lat: 48.34286, lng: 17.749531 },
    { lat: 48.34301, lng: 17.749183 },
    { lat: 48.343176, lng: 17.748804 },
    { lat: 48.34334, lng: 17.748423 },
    { lat: 48.343514, lng: 17.748022 },
    { lat: 48.343664, lng: 17.747686 },
    { lat: 48.343746, lng: 17.747492 },
    { lat: 48.343997, lng: 17.746919 },
    { lat: 48.344166, lng: 17.746531 },
    { lat: 48.34453, lng: 17.745699 },
    { lat: 48.344714, lng: 17.745276 },
    { lat: 48.344897, lng: 17.744861 },
    { lat: 48.345101, lng: 17.744408 },
    { lat: 48.345248, lng: 17.744086 },
    { lat: 48.3454, lng: 17.743756 },
    { lat: 48.345573, lng: 17.743359 },
    { lat: 48.345769, lng: 17.742939 },
    { lat: 48.345779, lng: 17.742915 },
    { lat: 48.345894, lng: 17.74266 },
    { lat: 48.345932, lng: 17.74259 },
    { lat: 48.346052, lng: 17.742322 },
    { lat: 48.346242, lng: 17.741889 },
    { lat: 48.346431, lng: 17.741458 },
    { lat: 48.346612, lng: 17.741045 },
    { lat: 48.346767, lng: 17.740669 },
    { lat: 48.346925, lng: 17.74034 },
    { lat: 48.347188, lng: 17.739784 },
    { lat: 48.347273, lng: 17.739599 },
    { lat: 48.347317, lng: 17.739509 },
    { lat: 48.347414, lng: 17.739273 },
    { lat: 48.347587, lng: 17.738835 },
    { lat: 48.347745, lng: 17.738437 },
    { lat: 48.347904, lng: 17.738032 },
    { lat: 48.348006, lng: 17.737779 },
    { lat: 48.348022, lng: 17.737737 },
    { lat: 48.348052, lng: 17.737658 },
    { lat: 48.348147, lng: 17.737417 },
    { lat: 48.348315, lng: 17.736965 },
    { lat: 48.348471, lng: 17.736538 },
    { lat: 48.348668, lng: 17.736001 },
    { lat: 48.34878, lng: 17.7357 },
    { lat: 48.348795, lng: 17.735656 },
    { lat: 48.348804, lng: 17.735632 },
    { lat: 48.348824, lng: 17.735587 },
    { lat: 48.348839, lng: 17.735524 },
    { lat: 48.34897, lng: 17.73519 },
    { lat: 48.349159, lng: 17.734672 },
    { lat: 48.349301, lng: 17.734285 },
    { lat: 48.349498, lng: 17.734232 },
    { lat: 48.349797, lng: 17.734175 },
    { lat: 48.350122, lng: 17.734086 },
    { lat: 48.3504, lng: 17.733489 },
    { lat: 48.350628, lng: 17.733009 },
    { lat: 48.350836, lng: 17.732559 },
    { lat: 48.350887, lng: 17.732109 },
    { lat: 48.350943, lng: 17.731602 },
    { lat: 48.351003, lng: 17.731101 },
    { lat: 48.351083, lng: 17.73038 },
    { lat: 48.351148, lng: 17.729807 },
    { lat: 48.351239, lng: 17.728995 },
    { lat: 48.351317, lng: 17.7283 },
    { lat: 48.351387, lng: 17.727673 },
    { lat: 48.351475, lng: 17.726905 },
    { lat: 48.351515, lng: 17.726542 },
    { lat: 48.351407, lng: 17.726495 },
    { lat: 48.351421, lng: 17.726026 },
    { lat: 48.351471, lng: 17.725261 },
    { lat: 48.351516, lng: 17.724538 },
    { lat: 48.351561, lng: 17.723812 },
    { lat: 48.351599, lng: 17.723207 },
    { lat: 48.351688, lng: 17.722763 },
    { lat: 48.351792, lng: 17.72222 },
    { lat: 48.352018, lng: 17.721298 },
    { lat: 48.35206, lng: 17.721079 },
    { lat: 48.352215, lng: 17.720271 },
    { lat: 48.352378, lng: 17.719555 },
    { lat: 48.35246, lng: 17.719228 },
    { lat: 48.352552, lng: 17.718808 },
    { lat: 48.352713, lng: 17.718129 },
    { lat: 48.352862, lng: 17.71764 },
    { lat: 48.353006, lng: 17.717154 },
    { lat: 48.353105, lng: 17.716806 },
    { lat: 48.353115, lng: 17.716784 },
    { lat: 48.353299, lng: 17.716409 },
    { lat: 48.353404, lng: 17.71619 },
    { lat: 48.353569, lng: 17.715869 },
    { lat: 48.353663, lng: 17.7157 },
    { lat: 48.353913, lng: 17.715217 },
    { lat: 48.354137, lng: 17.714796 },
    { lat: 48.354225, lng: 17.714575 },
    { lat: 48.354475, lng: 17.713963 },
    { lat: 48.354551, lng: 17.713769 },
    { lat: 48.354774, lng: 17.713201 },
    { lat: 48.355006, lng: 17.712616 },
    { lat: 48.355225, lng: 17.712124 },
    { lat: 48.355445, lng: 17.711626 },
    { lat: 48.355596, lng: 17.711283 },
    { lat: 48.355857, lng: 17.710683 },
    { lat: 48.356085, lng: 17.710156 },
    { lat: 48.356331, lng: 17.709572 },
    { lat: 48.356182, lng: 17.709122 },
    { lat: 48.356274, lng: 17.708687 },
    { lat: 48.356326, lng: 17.708196 },
    { lat: 48.356484, lng: 17.707891 },
    { lat: 48.356627, lng: 17.707681 },
    { lat: 48.356605, lng: 17.707441 },
    { lat: 48.35652, lng: 17.707279 },
    { lat: 48.356404, lng: 17.707055 },
    { lat: 48.356301, lng: 17.706931 },
    { lat: 48.35621, lng: 17.706629 },
    { lat: 48.356123, lng: 17.706399 },
    { lat: 48.356125, lng: 17.70632 },
    { lat: 48.356151, lng: 17.706249 },
    { lat: 48.356189, lng: 17.706208 },
    { lat: 48.356265, lng: 17.706178 },
    { lat: 48.356339, lng: 17.706114 },
    { lat: 48.356374, lng: 17.70604 },
    { lat: 48.356397, lng: 17.70603 },
    { lat: 48.35658, lng: 17.70603 },
    { lat: 48.35661, lng: 17.70598 },
    { lat: 48.356598, lng: 17.705922 },
    { lat: 48.356511, lng: 17.705713 },
    { lat: 48.356486, lng: 17.705625 },
    { lat: 48.356426, lng: 17.705495 },
    { lat: 48.356376, lng: 17.705321 },
    { lat: 48.356344, lng: 17.705191 },
    { lat: 48.356304, lng: 17.704783 },
    { lat: 48.356307, lng: 17.70473 },
    { lat: 48.356337, lng: 17.704595 },
    { lat: 48.35636, lng: 17.704546 },
    { lat: 48.356413, lng: 17.704427 },
    { lat: 48.356417, lng: 17.704366 },
    { lat: 48.356398, lng: 17.704303 },
    { lat: 48.356359, lng: 17.704217 },
    { lat: 48.356326, lng: 17.704015 },
    { lat: 48.356274, lng: 17.703948 },
    { lat: 48.356205, lng: 17.703908 },
    { lat: 48.356057, lng: 17.70384 },
    { lat: 48.356038, lng: 17.703789 },
    { lat: 48.356065, lng: 17.703722 },
    { lat: 48.356178, lng: 17.703585 },
    { lat: 48.356212, lng: 17.703504 },
    { lat: 48.356223, lng: 17.703429 },
    { lat: 48.356206, lng: 17.703327 },
    { lat: 48.356177, lng: 17.703217 },
    { lat: 48.356058, lng: 17.703008 },
    { lat: 48.356048, lng: 17.702942 },
    { lat: 48.356158, lng: 17.702695 },
    { lat: 48.35615, lng: 17.702626 },
    { lat: 48.356041, lng: 17.70238 },
    { lat: 48.355937, lng: 17.7022 },
    { lat: 48.355918, lng: 17.702149 },
    { lat: 48.355934, lng: 17.702074 },
    { lat: 48.356022, lng: 17.701961 },
    { lat: 48.356154, lng: 17.701922 },
    { lat: 48.356347, lng: 17.7019 },
    { lat: 48.356457, lng: 17.70184 },
    { lat: 48.356565, lng: 17.701827 },
    { lat: 48.3567472, lng: 17.701352 },
    { lat: 48.3567664, lng: 17.7005736 },
    { lat: 48.3567058, lng: 17.7003457 },
    { lat: 48.3566248, lng: 17.7000411 },
    { lat: 48.3565424, lng: 17.6999487 },
    { lat: 48.3564358, lng: 17.6999423 },
    { lat: 48.3563441, lng: 17.6995572 },
    { lat: 48.3561235, lng: 17.6990291 },
    { lat: 48.3559972, lng: 17.6985061 },
    { lat: 48.3558377, lng: 17.6984252 },
    { lat: 48.3552473, lng: 17.69861 },
    { lat: 48.354919, lng: 17.6988847 },
    { lat: 48.3545634, lng: 17.6989421 },
    { lat: 48.3541141, lng: 17.6986732 },
    { lat: 48.354093, lng: 17.6985314 },
    { lat: 48.3539237, lng: 17.6983695 },
    { lat: 48.3538599, lng: 17.698071 },
    { lat: 48.353704, lng: 17.6980872 },
    { lat: 48.3535385, lng: 17.6983856 },
    { lat: 48.3534977, lng: 17.6986902 },
    { lat: 48.3535917, lng: 17.6988016 },
    { lat: 48.3535657, lng: 17.6989305 },
    { lat: 48.3534885, lng: 17.6990141 },
    { lat: 48.353431, lng: 17.6992679 },
    { lat: 48.3533423, lng: 17.6992942 },
    { lat: 48.35325, lng: 17.6992265 },
    { lat: 48.3531076, lng: 17.6989338 },
    { lat: 48.3528048, lng: 17.6976111 },
    { lat: 48.3525785, lng: 17.6975108 },
    { lat: 48.3523174, lng: 17.6976337 },
    { lat: 48.3521483, lng: 17.6976194 },
    { lat: 48.3519422, lng: 17.6972664 },
    { lat: 48.3519697, lng: 17.6970542 },
    { lat: 48.3519341, lng: 17.6969541 },
    { lat: 48.3517492, lng: 17.6966674 },
    { lat: 48.3514996, lng: 17.6965586 },
    { lat: 48.3512173, lng: 17.6969405 },
    { lat: 48.3509796, lng: 17.6969305 },
    { lat: 48.3508082, lng: 17.6965911 },
    { lat: 48.3508132, lng: 17.6963796 },
    { lat: 48.350245, lng: 17.6965222 },
    { lat: 48.350033, lng: 17.6963816 },
    { lat: 48.3499788, lng: 17.6962522 },
    { lat: 48.3494379, lng: 17.6961265 },
    { lat: 48.3493262, lng: 17.6962742 },
    { lat: 48.3494033, lng: 17.6969105 },
    { lat: 48.3493721, lng: 17.6969912 },
    { lat: 48.3491179, lng: 17.6969708 },
    { lat: 48.348893, lng: 17.6968923 },
    { lat: 48.3478511, lng: 17.6960629 },
    { lat: 48.3477353, lng: 17.6960351 },
    { lat: 48.3476525, lng: 17.6960119 },
    { lat: 48.3468614, lng: 17.6962189 },
    { lat: 48.346627, lng: 17.6961893 },
    { lat: 48.3461052, lng: 17.6957518 },
    { lat: 48.3456294, lng: 17.6952206 },
    { lat: 48.3455453, lng: 17.6951596 },
    { lat: 48.3453282, lng: 17.6951535 },
    { lat: 48.3450304, lng: 17.6960262 },
    { lat: 48.3449304, lng: 17.6961081 },
    { lat: 48.344703, lng: 17.6960807 },
    { lat: 48.3446359, lng: 17.6960358 },
    { lat: 48.3444408, lng: 17.6955873 },
    { lat: 48.3440134, lng: 17.6953087 },
    { lat: 48.3438283, lng: 17.6950721 },
    { lat: 48.3437282, lng: 17.694772 },
    { lat: 48.3439331, lng: 17.6946889 },
    { lat: 48.3439542, lng: 17.6945575 },
    { lat: 48.3439228, lng: 17.6943415 },
    { lat: 48.343735, lng: 17.6941386 },
    { lat: 48.3437175, lng: 17.6935498 },
    { lat: 48.3435859, lng: 17.6933794 },
    { lat: 48.3433827, lng: 17.6933604 },
    { lat: 48.3433066, lng: 17.6932441 },
    { lat: 48.3428378, lng: 17.6930618 },
    { lat: 48.342185, lng: 17.6928564 },
    { lat: 48.3420778, lng: 17.6928782 },
    { lat: 48.3418177, lng: 17.6924193 },
    { lat: 48.3414854, lng: 17.6921782 },
    { lat: 48.3413447, lng: 17.6918956 },
    { lat: 48.3410257, lng: 17.6917963 },
    { lat: 48.3408782, lng: 17.6918206 },
    { lat: 48.3407159, lng: 17.6917713 },
    { lat: 48.3406419, lng: 17.6915599 },
    { lat: 48.3406584, lng: 17.6914653 },
    { lat: 48.340864, lng: 17.6912299 },
    { lat: 48.340874, lng: 17.6910496 },
    { lat: 48.3407739, lng: 17.6906548 },
    { lat: 48.3405907, lng: 17.6905053 },
    { lat: 48.3401595, lng: 17.6905988 },
    { lat: 48.3396385, lng: 17.6902653 },
    { lat: 48.3394819, lng: 17.689909 },
    { lat: 48.3394786, lng: 17.6896604 },
    { lat: 48.3394173, lng: 17.6894887 },
    { lat: 48.3393602, lng: 17.6893697 },
    { lat: 48.3391645, lng: 17.6892364 },
    { lat: 48.339075, lng: 17.6890346 },
    { lat: 48.3390366, lng: 17.688693 },
    { lat: 48.3390798, lng: 17.6880626 },
    { lat: 48.339031, lng: 17.6879137 },
    { lat: 48.3388655, lng: 17.6876882 },
    { lat: 48.338703, lng: 17.6876518 },
    { lat: 48.3384351, lng: 17.687359 },
    { lat: 48.3387327, lng: 17.6871877 },
    { lat: 48.3387781, lng: 17.687028 },
    { lat: 48.3389883, lng: 17.6868139 },
    { lat: 48.3391456, lng: 17.686768 },
    { lat: 48.3394003, lng: 17.6864174 },
    { lat: 48.3396104, lng: 17.6856408 },
    { lat: 48.3399183, lng: 17.6856884 },
    { lat: 48.3402592, lng: 17.6854176 },
    { lat: 48.3405769, lng: 17.6853875 },
    { lat: 48.3407449, lng: 17.6851825 },
    { lat: 48.3406794, lng: 17.6841196 },
    { lat: 48.3405787, lng: 17.6835918 },
    { lat: 48.3405071, lng: 17.6834216 },
    { lat: 48.3403242, lng: 17.6832592 },
    { lat: 48.3403126, lng: 17.6830365 },
    { lat: 48.3404818, lng: 17.6827263 },
    { lat: 48.3404498, lng: 17.6824338 },
    { lat: 48.3403805, lng: 17.6823545 },
    { lat: 48.3401787, lng: 17.6824489 },
    { lat: 48.3401011, lng: 17.6822185 },
    { lat: 48.3400362, lng: 17.6821566 },
    { lat: 48.3396712, lng: 17.6820064 },
    { lat: 48.3396536, lng: 17.681112 },
    { lat: 48.3395375, lng: 17.6807355 },
    { lat: 48.3394649, lng: 17.6806034 },
    { lat: 48.3391263, lng: 17.6803352 },
    { lat: 48.3387126, lng: 17.6797507 },
    { lat: 48.3385033, lng: 17.6795821 },
    { lat: 48.3384702, lng: 17.6792337 },
    { lat: 48.3382259, lng: 17.6788269 },
    { lat: 48.3378265, lng: 17.6788338 },
    { lat: 48.3376925, lng: 17.6786774 },
    { lat: 48.3373704, lng: 17.6790513 },
    { lat: 48.3373069, lng: 17.6787579 },
    { lat: 48.3372528, lng: 17.6786885 },
    { lat: 48.3369165, lng: 17.6786528 },
    { lat: 48.3367767, lng: 17.6782416 },
    { lat: 48.3364488, lng: 17.678578 },
    { lat: 48.3363711, lng: 17.6785279 },
    { lat: 48.3363786, lng: 17.6776709 },
    { lat: 48.3361315, lng: 17.6775109 },
    { lat: 48.3358515, lng: 17.6774946 },
    { lat: 48.3357571, lng: 17.6773925 },
    { lat: 48.3356859, lng: 17.6772308 },
    { lat: 48.3357149, lng: 17.6768279 },
    { lat: 48.3356346, lng: 17.67677 },
    { lat: 48.3353272, lng: 17.6768402 },
    { lat: 48.3351921, lng: 17.6767914 },
    { lat: 48.3350646, lng: 17.6763427 },
    { lat: 48.3348431, lng: 17.6765198 },
    { lat: 48.3347688, lng: 17.676504 },
    { lat: 48.3348463, lng: 17.6760832 },
    { lat: 48.3350196, lng: 17.6761266 },
    { lat: 48.3350441, lng: 17.6760671 },
    { lat: 48.3350346, lng: 17.6759541 },
    { lat: 48.3348399, lng: 17.6757988 },
    { lat: 48.3348026, lng: 17.6756912 },
    { lat: 48.3349887, lng: 17.6754169 },
    { lat: 48.3351564, lng: 17.6752833 },
    { lat: 48.3350844, lng: 17.6751054 },
    { lat: 48.334682, lng: 17.6747007 },
    { lat: 48.3347183, lng: 17.6743443 },
    { lat: 48.3349141, lng: 17.6740286 },
    { lat: 48.3348988, lng: 17.67398 },
    { lat: 48.3346163, lng: 17.6738328 },
    { lat: 48.3344099, lng: 17.6734953 },
    { lat: 48.3342528, lng: 17.6734198 },
    { lat: 48.3342298, lng: 17.6733393 },
    { lat: 48.3341803, lng: 17.6730897 },
    { lat: 48.3341941, lng: 17.672899 },
    { lat: 48.3343725, lng: 17.6722819 },
    { lat: 48.3345162, lng: 17.6720197 },
    { lat: 48.3345027, lng: 17.6718555 },
    { lat: 48.3343607, lng: 17.6716629 },
    { lat: 48.3341751, lng: 17.6717097 },
    { lat: 48.3340396, lng: 17.6716542 },
    { lat: 48.3335092, lng: 17.6719135 },
    { lat: 48.3331257, lng: 17.6716499 },
    { lat: 48.3329894, lng: 17.6713991 },
    { lat: 48.332984, lng: 17.6712415 },
    { lat: 48.3331516, lng: 17.6708003 },
    { lat: 48.3332493, lng: 17.6707365 },
    { lat: 48.333265, lng: 17.6706473 },
    { lat: 48.3330032, lng: 17.6704486 },
    { lat: 48.3326729, lng: 17.6703514 },
    { lat: 48.3325308, lng: 17.6697032 },
    { lat: 48.3324957, lng: 17.6696482 },
    { lat: 48.3323733, lng: 17.669696 },
    { lat: 48.3319766, lng: 17.6700152 },
    { lat: 48.3311216, lng: 17.6710362 },
    { lat: 48.3297277, lng: 17.6734087 },
    { lat: 48.328889, lng: 17.6749829 },
    { lat: 48.3285618, lng: 17.6754182 },
    { lat: 48.3267203, lng: 17.6775654 },
    { lat: 48.3260443, lng: 17.6781515 },
    { lat: 48.3246176, lng: 17.6797473 },
    { lat: 48.3242861, lng: 17.6791954 },
    { lat: 48.324094, lng: 17.6789755 },
    { lat: 48.3238234, lng: 17.6788 },
    { lat: 48.3232264, lng: 17.6786445 },
    { lat: 48.3229185, lng: 17.6787946 },
    { lat: 48.3226508, lng: 17.6793857 },
    { lat: 48.3222528, lng: 17.680986 },
    { lat: 48.3213048, lng: 17.682091 },
    { lat: 48.3211098, lng: 17.6824771 },
    { lat: 48.3208928, lng: 17.6830706 },
    { lat: 48.3207326, lng: 17.6832708 },
    { lat: 48.3205483, lng: 17.6833773 },
    { lat: 48.32029, lng: 17.6832341 },
    { lat: 48.3201966, lng: 17.6830425 },
    { lat: 48.3199661, lng: 17.6823899 },
    { lat: 48.3199463, lng: 17.682271 },
    { lat: 48.3199858, lng: 17.6820732 },
    { lat: 48.3202208, lng: 17.6814619 },
    { lat: 48.320941, lng: 17.6801214 },
    { lat: 48.3216176, lng: 17.6794771 },
    { lat: 48.3219237, lng: 17.6789363 },
    { lat: 48.3220373, lng: 17.6784783 },
    { lat: 48.3220392, lng: 17.6782747 },
    { lat: 48.3220295, lng: 17.6780476 },
    { lat: 48.3218908, lng: 17.6777453 },
    { lat: 48.3216502, lng: 17.6774358 },
    { lat: 48.321236, lng: 17.6771868 },
    { lat: 48.3207942, lng: 17.677216 },
    { lat: 48.3203771, lng: 17.6775876 },
    { lat: 48.3198338, lng: 17.678271 },
    { lat: 48.3195443, lng: 17.6784803 },
    { lat: 48.3181848, lng: 17.6787314 },
    { lat: 48.317949, lng: 17.6790051 },
    { lat: 48.3178578, lng: 17.6793692 },
    { lat: 48.3178527, lng: 17.6796331 },
    { lat: 48.3179239, lng: 17.6803205 },
    { lat: 48.3183132, lng: 17.6811455 },
    { lat: 48.3183439, lng: 17.6813306 },
    { lat: 48.3182457, lng: 17.6817999 },
    { lat: 48.3181534, lng: 17.6819473 },
    { lat: 48.3179315, lng: 17.6821023 },
    { lat: 48.3175825, lng: 17.6820081 },
    { lat: 48.3166565, lng: 17.6813579 },
    { lat: 48.3162268, lng: 17.6808436 },
    { lat: 48.3156901, lng: 17.6805654 },
    { lat: 48.3156524, lng: 17.6809929 },
    { lat: 48.3157788, lng: 17.681731 },
    { lat: 48.315675, lng: 17.6824735 },
    { lat: 48.3155553, lng: 17.6828138 },
    { lat: 48.3150404, lng: 17.6835549 },
    { lat: 48.3146758, lng: 17.6842957 },
    { lat: 48.3144665, lng: 17.6850132 },
    { lat: 48.314397, lng: 17.6860127 },
    { lat: 48.3143927, lng: 17.6861605 },
  ],
  Zavar: [
    { lat: 48.3476307, lng: 17.6408926 },
    { lat: 48.347993, lng: 17.6417504 },
    { lat: 48.3481962, lng: 17.6422551 },
    { lat: 48.3471718, lng: 17.6439931 },
    { lat: 48.3461454, lng: 17.6458797 },
    { lat: 48.3459767, lng: 17.6461627 },
    { lat: 48.3457608, lng: 17.6463717 },
    { lat: 48.3453373, lng: 17.6474152 },
    { lat: 48.3435511, lng: 17.6509575 },
    { lat: 48.3424393, lng: 17.6534309 },
    { lat: 48.3413713, lng: 17.6560217 },
    { lat: 48.3405961, lng: 17.6582262 },
    { lat: 48.3391328, lng: 17.6632324 },
    { lat: 48.3391258, lng: 17.6638796 },
    { lat: 48.3390201, lng: 17.6645142 },
    { lat: 48.339047, lng: 17.6648908 },
    { lat: 48.3387448, lng: 17.6659782 },
    { lat: 48.336906, lng: 17.669069 },
    { lat: 48.3355552, lng: 17.6710298 },
    { lat: 48.3342298, lng: 17.6733393 },
    { lat: 48.3342528, lng: 17.6734198 },
    { lat: 48.3344099, lng: 17.6734953 },
    { lat: 48.3346163, lng: 17.6738328 },
    { lat: 48.3348988, lng: 17.67398 },
    { lat: 48.3349141, lng: 17.6740286 },
    { lat: 48.3347183, lng: 17.6743443 },
    { lat: 48.334682, lng: 17.6747007 },
    { lat: 48.3350844, lng: 17.6751054 },
    { lat: 48.3351564, lng: 17.6752833 },
    { lat: 48.3349887, lng: 17.6754169 },
    { lat: 48.3348026, lng: 17.6756912 },
    { lat: 48.3348399, lng: 17.6757988 },
    { lat: 48.3350346, lng: 17.6759541 },
    { lat: 48.3350441, lng: 17.6760671 },
    { lat: 48.3350196, lng: 17.6761266 },
    { lat: 48.3348463, lng: 17.6760832 },
    { lat: 48.3347688, lng: 17.676504 },
    { lat: 48.3348431, lng: 17.6765198 },
    { lat: 48.3350646, lng: 17.6763427 },
    { lat: 48.3351921, lng: 17.6767914 },
    { lat: 48.3353272, lng: 17.6768402 },
    { lat: 48.3356346, lng: 17.67677 },
    { lat: 48.3357149, lng: 17.6768279 },
    { lat: 48.3356859, lng: 17.6772308 },
    { lat: 48.3357571, lng: 17.6773925 },
    { lat: 48.3358515, lng: 17.6774946 },
    { lat: 48.3361315, lng: 17.6775109 },
    { lat: 48.3363786, lng: 17.6776709 },
    { lat: 48.3363711, lng: 17.6785279 },
    { lat: 48.3364488, lng: 17.678578 },
    { lat: 48.3367767, lng: 17.6782416 },
    { lat: 48.3369165, lng: 17.6786528 },
    { lat: 48.3372528, lng: 17.6786885 },
    { lat: 48.3373069, lng: 17.6787579 },
    { lat: 48.3373704, lng: 17.6790513 },
    { lat: 48.3376925, lng: 17.6786774 },
    { lat: 48.3378265, lng: 17.6788338 },
    { lat: 48.3382259, lng: 17.6788269 },
    { lat: 48.3384702, lng: 17.6792337 },
    { lat: 48.3385033, lng: 17.6795821 },
    { lat: 48.3387126, lng: 17.6797507 },
    { lat: 48.3391263, lng: 17.6803352 },
    { lat: 48.3394649, lng: 17.6806034 },
    { lat: 48.3395375, lng: 17.6807355 },
    { lat: 48.3396536, lng: 17.681112 },
    { lat: 48.3396712, lng: 17.6820064 },
    { lat: 48.3400362, lng: 17.6821566 },
    { lat: 48.3401011, lng: 17.6822185 },
    { lat: 48.3401787, lng: 17.6824489 },
    { lat: 48.3403805, lng: 17.6823545 },
    { lat: 48.3404498, lng: 17.6824338 },
    { lat: 48.3404818, lng: 17.6827263 },
    { lat: 48.3403126, lng: 17.6830365 },
    { lat: 48.3403242, lng: 17.6832592 },
    { lat: 48.3405071, lng: 17.6834216 },
    { lat: 48.3405787, lng: 17.6835918 },
    { lat: 48.3406794, lng: 17.6841196 },
    { lat: 48.3407449, lng: 17.6851825 },
    { lat: 48.3405769, lng: 17.6853875 },
    { lat: 48.3402592, lng: 17.6854176 },
    { lat: 48.3399183, lng: 17.6856884 },
    { lat: 48.3396104, lng: 17.6856408 },
    { lat: 48.3394003, lng: 17.6864174 },
    { lat: 48.3391456, lng: 17.686768 },
    { lat: 48.3389883, lng: 17.6868139 },
    { lat: 48.3387781, lng: 17.687028 },
    { lat: 48.3387327, lng: 17.6871877 },
    { lat: 48.3384351, lng: 17.687359 },
    { lat: 48.338703, lng: 17.6876518 },
    { lat: 48.3388655, lng: 17.6876882 },
    { lat: 48.339031, lng: 17.6879137 },
    { lat: 48.3390798, lng: 17.6880626 },
    { lat: 48.3390366, lng: 17.688693 },
    { lat: 48.339075, lng: 17.6890346 },
    { lat: 48.3391645, lng: 17.6892364 },
    { lat: 48.3393602, lng: 17.6893697 },
    { lat: 48.3394173, lng: 17.6894887 },
    { lat: 48.3394786, lng: 17.6896604 },
    { lat: 48.3394819, lng: 17.689909 },
    { lat: 48.3396385, lng: 17.6902653 },
    { lat: 48.3401595, lng: 17.6905988 },
    { lat: 48.3405907, lng: 17.6905053 },
    { lat: 48.3407739, lng: 17.6906548 },
    { lat: 48.340874, lng: 17.6910496 },
    { lat: 48.340864, lng: 17.6912299 },
    { lat: 48.3406584, lng: 17.6914653 },
    { lat: 48.3406419, lng: 17.6915599 },
    { lat: 48.3407159, lng: 17.6917713 },
    { lat: 48.3408782, lng: 17.6918206 },
    { lat: 48.3410257, lng: 17.6917963 },
    { lat: 48.3413447, lng: 17.6918956 },
    { lat: 48.3414854, lng: 17.6921782 },
    { lat: 48.3418177, lng: 17.6924193 },
    { lat: 48.3420778, lng: 17.6928782 },
    { lat: 48.342185, lng: 17.6928564 },
    { lat: 48.3428378, lng: 17.6930618 },
    { lat: 48.3433066, lng: 17.6932441 },
    { lat: 48.3433827, lng: 17.6933604 },
    { lat: 48.3435859, lng: 17.6933794 },
    { lat: 48.3437175, lng: 17.6935498 },
    { lat: 48.343735, lng: 17.6941386 },
    { lat: 48.3439228, lng: 17.6943415 },
    { lat: 48.3439542, lng: 17.6945575 },
    { lat: 48.3439331, lng: 17.6946889 },
    { lat: 48.3437282, lng: 17.694772 },
    { lat: 48.3438283, lng: 17.6950721 },
    { lat: 48.3440134, lng: 17.6953087 },
    { lat: 48.3444408, lng: 17.6955873 },
    { lat: 48.3446359, lng: 17.6960358 },
    { lat: 48.344703, lng: 17.6960807 },
    { lat: 48.3449304, lng: 17.6961081 },
    { lat: 48.3450304, lng: 17.6960262 },
    { lat: 48.3453282, lng: 17.6951535 },
    { lat: 48.3455453, lng: 17.6951596 },
    { lat: 48.3456294, lng: 17.6952206 },
    { lat: 48.3461052, lng: 17.6957518 },
    { lat: 48.346627, lng: 17.6961893 },
    { lat: 48.3468614, lng: 17.6962189 },
    { lat: 48.3476525, lng: 17.6960119 },
    { lat: 48.3477353, lng: 17.6960351 },
    { lat: 48.3478511, lng: 17.6960629 },
    { lat: 48.348893, lng: 17.6968923 },
    { lat: 48.3491179, lng: 17.6969708 },
    { lat: 48.3493721, lng: 17.6969912 },
    { lat: 48.3494033, lng: 17.6969105 },
    { lat: 48.3493262, lng: 17.6962742 },
    { lat: 48.3494379, lng: 17.6961265 },
    { lat: 48.3499788, lng: 17.6962522 },
    { lat: 48.350033, lng: 17.6963816 },
    { lat: 48.350245, lng: 17.6965222 },
    { lat: 48.3508132, lng: 17.6963796 },
    { lat: 48.3508082, lng: 17.6965911 },
    { lat: 48.3509796, lng: 17.6969305 },
    { lat: 48.3512173, lng: 17.6969405 },
    { lat: 48.3514996, lng: 17.6965586 },
    { lat: 48.3517492, lng: 17.6966674 },
    { lat: 48.3519341, lng: 17.6969541 },
    { lat: 48.3519697, lng: 17.6970542 },
    { lat: 48.3519422, lng: 17.6972664 },
    { lat: 48.3521483, lng: 17.6976194 },
    { lat: 48.3523174, lng: 17.6976337 },
    { lat: 48.3525785, lng: 17.6975108 },
    { lat: 48.3528048, lng: 17.6976111 },
    { lat: 48.3531076, lng: 17.6989338 },
    { lat: 48.35325, lng: 17.6992265 },
    { lat: 48.3533423, lng: 17.6992942 },
    { lat: 48.353431, lng: 17.6992679 },
    { lat: 48.3534885, lng: 17.6990141 },
    { lat: 48.3535657, lng: 17.6989305 },
    { lat: 48.3535917, lng: 17.6988016 },
    { lat: 48.3534977, lng: 17.6986902 },
    { lat: 48.3535385, lng: 17.6983856 },
    { lat: 48.353704, lng: 17.6980872 },
    { lat: 48.3538599, lng: 17.698071 },
    { lat: 48.3539237, lng: 17.6983695 },
    { lat: 48.354093, lng: 17.6985314 },
    { lat: 48.3541141, lng: 17.6986732 },
    { lat: 48.3545634, lng: 17.6989421 },
    { lat: 48.354919, lng: 17.6988847 },
    { lat: 48.3552473, lng: 17.69861 },
    { lat: 48.3558377, lng: 17.6984252 },
    { lat: 48.3559972, lng: 17.6985061 },
    { lat: 48.3561235, lng: 17.6990291 },
    { lat: 48.3563441, lng: 17.6995572 },
    { lat: 48.3564358, lng: 17.6999423 },
    { lat: 48.3565424, lng: 17.6999487 },
    { lat: 48.3566248, lng: 17.7000411 },
    { lat: 48.3566106, lng: 17.6999043 },
    { lat: 48.3566017, lng: 17.6994345 },
    { lat: 48.3569895, lng: 17.698228 },
    { lat: 48.3570965, lng: 17.6974668 },
    { lat: 48.3581187, lng: 17.6948084 },
    { lat: 48.3581417, lng: 17.6939673 },
    { lat: 48.3580207, lng: 17.6934734 },
    { lat: 48.3581045, lng: 17.6929543 },
    { lat: 48.3580648, lng: 17.6922456 },
    { lat: 48.3582487, lng: 17.6915541 },
    { lat: 48.3581987, lng: 17.6909157 },
    { lat: 48.358386, lng: 17.6905734 },
    { lat: 48.358303, lng: 17.6901647 },
    { lat: 48.3583204, lng: 17.6892872 },
    { lat: 48.3584043, lng: 17.6883495 },
    { lat: 48.3586962, lng: 17.6869686 },
    { lat: 48.3586936, lng: 17.6850636 },
    { lat: 48.359026, lng: 17.6853246 },
    { lat: 48.3590425, lng: 17.6841954 },
    { lat: 48.358893, lng: 17.6823089 },
    { lat: 48.3588432, lng: 17.6821118 },
    { lat: 48.3589523, lng: 17.6821032 },
    { lat: 48.3589443, lng: 17.6819186 },
    { lat: 48.3587979, lng: 17.6791462 },
    { lat: 48.3586947, lng: 17.6778731 },
    { lat: 48.3586356, lng: 17.6776851 },
    { lat: 48.3585576, lng: 17.6754654 },
    { lat: 48.3584185, lng: 17.6737298 },
    { lat: 48.3586367, lng: 17.6717311 },
    { lat: 48.359022, lng: 17.6703646 },
    { lat: 48.3594676, lng: 17.6690049 },
    { lat: 48.3613821, lng: 17.6643096 },
    { lat: 48.3625295, lng: 17.6621328 },
    { lat: 48.3630749, lng: 17.6613393 },
    { lat: 48.3636375, lng: 17.6606921 },
    { lat: 48.3643385, lng: 17.6597172 },
    { lat: 48.3660377, lng: 17.6570694 },
    { lat: 48.3670439, lng: 17.6557935 },
    { lat: 48.3680395, lng: 17.6540219 },
    { lat: 48.3680848, lng: 17.6538283 },
    { lat: 48.3685421, lng: 17.6529097 },
    { lat: 48.3688337, lng: 17.6524445 },
    { lat: 48.3696499, lng: 17.6513643 },
    { lat: 48.3700586, lng: 17.6506786 },
    { lat: 48.3712806, lng: 17.6491163 },
    { lat: 48.3725926, lng: 17.6476604 },
    { lat: 48.3735418, lng: 17.6467168 },
    { lat: 48.3742503, lng: 17.645897 },
    { lat: 48.3747238, lng: 17.6455274 },
    { lat: 48.3766134, lng: 17.6435908 },
    { lat: 48.3776435, lng: 17.6420857 },
    { lat: 48.3783759, lng: 17.6411274 },
    { lat: 48.3798017, lng: 17.6395222 },
    { lat: 48.3806592, lng: 17.6383277 },
    { lat: 48.3827118, lng: 17.6358808 },
    { lat: 48.3837893, lng: 17.6341069 },
    { lat: 48.3837705, lng: 17.6340765 },
    { lat: 48.3812415, lng: 17.6300428 },
    { lat: 48.3783484, lng: 17.6254175 },
    { lat: 48.3761007, lng: 17.6220701 },
    { lat: 48.3748987, lng: 17.6222244 },
    { lat: 48.3736753, lng: 17.6222159 },
    { lat: 48.3727521, lng: 17.6221346 },
    { lat: 48.3709222, lng: 17.6218172 },
    { lat: 48.3695121, lng: 17.621392 },
    { lat: 48.3676462, lng: 17.6224076 },
    { lat: 48.3673209, lng: 17.6225333 },
    { lat: 48.364745, lng: 17.6246427 },
    { lat: 48.3630611, lng: 17.6262728 },
    { lat: 48.3620169, lng: 17.6278544 },
    { lat: 48.3619613, lng: 17.6279368 },
    { lat: 48.3614108, lng: 17.6287357 },
    { lat: 48.3603715, lng: 17.6299294 },
    { lat: 48.3579846, lng: 17.6317291 },
    { lat: 48.357067, lng: 17.6325596 },
    { lat: 48.3553281, lng: 17.6339055 },
    { lat: 48.3532981, lng: 17.6361009 },
    { lat: 48.3521102, lng: 17.6371417 },
    { lat: 48.3512532, lng: 17.638038 },
    { lat: 48.3476307, lng: 17.6408926 },
  ],
  Brestovany: [
    { lat: 48.380527, lng: 17.6588158 },
    { lat: 48.3804974, lng: 17.6588108 },
    { lat: 48.3804228, lng: 17.6591193 },
    { lat: 48.3778307, lng: 17.6612332 },
    { lat: 48.3749141, lng: 17.6650547 },
    { lat: 48.3744526, lng: 17.6659256 },
    { lat: 48.3742374, lng: 17.6660934 },
    { lat: 48.3741129, lng: 17.6664321 },
    { lat: 48.3739083, lng: 17.6666271 },
    { lat: 48.3732766, lng: 17.667739 },
    { lat: 48.3727304, lng: 17.6682802 },
    { lat: 48.371733, lng: 17.6688541 },
    { lat: 48.3716426, lng: 17.6690505 },
    { lat: 48.3715966, lng: 17.6696156 },
    { lat: 48.371507, lng: 17.6699155 },
    { lat: 48.3716273, lng: 17.670107 },
    { lat: 48.3706286, lng: 17.6722243 },
    { lat: 48.3704153, lng: 17.6724462 },
    { lat: 48.3702157, lng: 17.6727911 },
    { lat: 48.3697981, lng: 17.6740775 },
    { lat: 48.3698743, lng: 17.6744109 },
    { lat: 48.3696744, lng: 17.6758517 },
    { lat: 48.3696085, lng: 17.6767125 },
    { lat: 48.370015, lng: 17.6768754 },
    { lat: 48.3701118, lng: 17.6771948 },
    { lat: 48.3701149, lng: 17.677373 },
    { lat: 48.3699588, lng: 17.678667 },
    { lat: 48.3698192, lng: 17.6806056 },
    { lat: 48.3697551, lng: 17.682904 },
    { lat: 48.3696078, lng: 17.684289 },
    { lat: 48.3693472, lng: 17.6886146 },
    { lat: 48.3693507, lng: 17.6909875 },
    { lat: 48.3694111, lng: 17.6926064 },
    { lat: 48.3693294, lng: 17.6937933 },
    { lat: 48.3692661, lng: 17.6942276 },
    { lat: 48.3682492, lng: 17.6971139 },
    { lat: 48.3680423, lng: 17.6983576 },
    { lat: 48.3679807, lng: 17.7004474 },
    { lat: 48.3677843, lng: 17.7017007 },
    { lat: 48.367531, lng: 17.702225 },
    { lat: 48.367809, lng: 17.702618 },
    { lat: 48.36807, lng: 17.702998 },
    { lat: 48.368385, lng: 17.703425 },
    { lat: 48.36858, lng: 17.703905 },
    { lat: 48.368782, lng: 17.704393 },
    { lat: 48.36868, lng: 17.704879 },
    { lat: 48.368553, lng: 17.705453 },
    { lat: 48.368415, lng: 17.706034 },
    { lat: 48.368268, lng: 17.706766 },
    { lat: 48.368198, lng: 17.707233 },
    { lat: 48.368095, lng: 17.707912 },
    { lat: 48.368026, lng: 17.708395 },
    { lat: 48.367891, lng: 17.709249 },
    { lat: 48.367812, lng: 17.709751 },
    { lat: 48.367737, lng: 17.710202 },
    { lat: 48.367651, lng: 17.710766 },
    { lat: 48.367997, lng: 17.711303 },
    { lat: 48.368202, lng: 17.711614 },
    { lat: 48.368535, lng: 17.711904 },
    { lat: 48.368672, lng: 17.712004 },
    { lat: 48.369067, lng: 17.712393 },
    { lat: 48.369208, lng: 17.712591 },
    { lat: 48.369339, lng: 17.712822 },
    { lat: 48.369582, lng: 17.713317 },
    { lat: 48.369748, lng: 17.713824 },
    { lat: 48.369869, lng: 17.714183 },
    { lat: 48.37002, lng: 17.714443 },
    { lat: 48.370137, lng: 17.714688 },
    { lat: 48.370263, lng: 17.714931 },
    { lat: 48.370425, lng: 17.715343 },
    { lat: 48.370612, lng: 17.715818 },
    { lat: 48.370598, lng: 17.715833 },
    { lat: 48.370936, lng: 17.716208 },
    { lat: 48.371086, lng: 17.716351 },
    { lat: 48.371398, lng: 17.716626 },
    { lat: 48.371969, lng: 17.717002 },
    { lat: 48.372148, lng: 17.717248 },
    { lat: 48.372253, lng: 17.717884 },
    { lat: 48.372484, lng: 17.718543 },
    { lat: 48.372767, lng: 17.719143 },
    { lat: 48.372924, lng: 17.719484 },
    { lat: 48.372933, lng: 17.720408 },
    { lat: 48.372943, lng: 17.721484 },
    { lat: 48.372926, lng: 17.721612 },
    { lat: 48.372961, lng: 17.72163 },
    { lat: 48.372983, lng: 17.721548 },
    { lat: 48.373198, lng: 17.721377 },
    { lat: 48.373329, lng: 17.721369 },
    { lat: 48.37349, lng: 17.721148 },
    { lat: 48.373804, lng: 17.721128 },
    { lat: 48.374044, lng: 17.721028 },
    { lat: 48.374111, lng: 17.721096 },
    { lat: 48.37421, lng: 17.721379 },
    { lat: 48.374352, lng: 17.72155 },
    { lat: 48.374279, lng: 17.721781 },
    { lat: 48.374306, lng: 17.721918 },
    { lat: 48.374321, lng: 17.721995 },
    { lat: 48.374332, lng: 17.722323 },
    { lat: 48.374334, lng: 17.722377 },
    { lat: 48.374343, lng: 17.722456 },
    { lat: 48.374347, lng: 17.722507 },
    { lat: 48.374353, lng: 17.722563 },
    { lat: 48.374399, lng: 17.722754 },
    { lat: 48.374364, lng: 17.72288 },
    { lat: 48.37475, lng: 17.723176 },
    { lat: 48.374805, lng: 17.723 },
    { lat: 48.374901, lng: 17.722998 },
    { lat: 48.374998, lng: 17.722995 },
    { lat: 48.375089, lng: 17.723126 },
    { lat: 48.375092, lng: 17.723131 },
    { lat: 48.37516, lng: 17.723161 },
    { lat: 48.375263, lng: 17.723147 },
    { lat: 48.375277, lng: 17.723119 },
    { lat: 48.37533, lng: 17.723024 },
    { lat: 48.375404, lng: 17.722687 },
    { lat: 48.375718, lng: 17.722608 },
    { lat: 48.375945, lng: 17.722828 },
    { lat: 48.376136, lng: 17.722712 },
    { lat: 48.37625, lng: 17.722711 },
    { lat: 48.376306, lng: 17.722903 },
    { lat: 48.376287, lng: 17.723072 },
    { lat: 48.376323, lng: 17.723235 },
    { lat: 48.376391, lng: 17.723366 },
    { lat: 48.376578, lng: 17.72345 },
    { lat: 48.37667, lng: 17.72362 },
    { lat: 48.376964, lng: 17.723685 },
    { lat: 48.376906, lng: 17.723902 },
    { lat: 48.376875, lng: 17.724019 },
    { lat: 48.376871, lng: 17.724044 },
    { lat: 48.37686, lng: 17.724107 },
    { lat: 48.376855, lng: 17.724138 },
    { lat: 48.377041, lng: 17.724216 },
    { lat: 48.377208, lng: 17.724319 },
    { lat: 48.377219, lng: 17.724358 },
    { lat: 48.377232, lng: 17.724444 },
    { lat: 48.377366, lng: 17.724385 },
    { lat: 48.377396, lng: 17.724372 },
    { lat: 48.377447, lng: 17.72435 },
    { lat: 48.37753, lng: 17.724312 },
    { lat: 48.377587, lng: 17.724234 },
    { lat: 48.378017, lng: 17.72395 },
    { lat: 48.377994, lng: 17.723843 },
    { lat: 48.378026, lng: 17.723531 },
    { lat: 48.378042, lng: 17.723361 },
    { lat: 48.378191, lng: 17.723033 },
    { lat: 48.378333, lng: 17.722756 },
    { lat: 48.378494, lng: 17.722474 },
    { lat: 48.378859, lng: 17.72176 },
    { lat: 48.378934, lng: 17.721642 },
    { lat: 48.379122, lng: 17.721263 },
    { lat: 48.379146, lng: 17.721109 },
    { lat: 48.379349, lng: 17.721056 },
    { lat: 48.379754, lng: 17.72071 },
    { lat: 48.379894, lng: 17.720616 },
    { lat: 48.380044, lng: 17.720376 },
    { lat: 48.380172, lng: 17.720246 },
    { lat: 48.38037, lng: 17.720151 },
    { lat: 48.380815, lng: 17.719999 },
    { lat: 48.380997, lng: 17.720019 },
    { lat: 48.381311, lng: 17.719942 },
    { lat: 48.381513, lng: 17.719786 },
    { lat: 48.381607, lng: 17.71964 },
    { lat: 48.381695, lng: 17.719374 },
    { lat: 48.381797, lng: 17.719259 },
    { lat: 48.381851, lng: 17.718981 },
    { lat: 48.382135, lng: 17.719112 },
    { lat: 48.382529, lng: 17.71912 },
    { lat: 48.382865, lng: 17.719118 },
    { lat: 48.383165, lng: 17.719032 },
    { lat: 48.383411, lng: 17.718921 },
    { lat: 48.383791, lng: 17.719052 },
    { lat: 48.383985, lng: 17.718912 },
    { lat: 48.384147, lng: 17.718662 },
    { lat: 48.384296, lng: 17.718755 },
    { lat: 48.38437, lng: 17.718718 },
    { lat: 48.384455, lng: 17.71852 },
    { lat: 48.384732, lng: 17.71827 },
    { lat: 48.385199, lng: 17.718488 },
    { lat: 48.385356, lng: 17.718919 },
    { lat: 48.385201, lng: 17.719201 },
    { lat: 48.385186, lng: 17.719337 },
    { lat: 48.385332, lng: 17.719613 },
    { lat: 48.38546, lng: 17.719946 },
    { lat: 48.385644, lng: 17.720188 },
    { lat: 48.385823, lng: 17.720107 },
    { lat: 48.385843, lng: 17.719966 },
    { lat: 48.38601, lng: 17.719817 },
    { lat: 48.386081, lng: 17.719539 },
    { lat: 48.385972, lng: 17.719077 },
    { lat: 48.386175, lng: 17.718222 },
    { lat: 48.386689, lng: 17.718038 },
    { lat: 48.386835, lng: 17.718173 },
    { lat: 48.387045, lng: 17.718223 },
    { lat: 48.38745, lng: 17.718425 },
    { lat: 48.387785, lng: 17.718669 },
    { lat: 48.387921, lng: 17.719058 },
    { lat: 48.38808, lng: 17.718909 },
    { lat: 48.388235, lng: 17.718866 },
    { lat: 48.388464, lng: 17.718808 },
    { lat: 48.388614, lng: 17.718844 },
    { lat: 48.388609, lng: 17.7189 },
    { lat: 48.388679, lng: 17.719045 },
    { lat: 48.388813, lng: 17.719147 },
    { lat: 48.389177, lng: 17.719344 },
    { lat: 48.389183, lng: 17.719644 },
    { lat: 48.389283, lng: 17.719787 },
    { lat: 48.389455, lng: 17.719816 },
    { lat: 48.389467, lng: 17.719687 },
    { lat: 48.389651, lng: 17.719144 },
    { lat: 48.389819, lng: 17.719122 },
    { lat: 48.389917, lng: 17.719389 },
    { lat: 48.389947, lng: 17.719428 },
    { lat: 48.390052, lng: 17.719548 },
    { lat: 48.390147, lng: 17.719499 },
    { lat: 48.390222, lng: 17.719492 },
    { lat: 48.390312, lng: 17.719548 },
    { lat: 48.390398, lng: 17.719689 },
    { lat: 48.390412, lng: 17.71978 },
    { lat: 48.390562, lng: 17.720222 },
    { lat: 48.390721, lng: 17.720812 },
    { lat: 48.390799, lng: 17.720979 },
    { lat: 48.390951, lng: 17.72122 },
    { lat: 48.391125, lng: 17.721355 },
    { lat: 48.39144, lng: 17.721297 },
    { lat: 48.391502, lng: 17.720972 },
    { lat: 48.391603, lng: 17.720846 },
    { lat: 48.391782, lng: 17.720716 },
    { lat: 48.391977, lng: 17.720699 },
    { lat: 48.39225, lng: 17.720839 },
    { lat: 48.392295, lng: 17.720843 },
    { lat: 48.392398, lng: 17.72085 },
    { lat: 48.392548, lng: 17.720367 },
    { lat: 48.392599, lng: 17.720169 },
    { lat: 48.392707, lng: 17.719869 },
    { lat: 48.392817, lng: 17.719735 },
    { lat: 48.393048, lng: 17.719836 },
    { lat: 48.393326, lng: 17.720391 },
    { lat: 48.393399, lng: 17.720567 },
    { lat: 48.393469, lng: 17.720753 },
    { lat: 48.3937604, lng: 17.720041 },
    { lat: 48.3942234, lng: 17.7188248 },
    { lat: 48.3944744, lng: 17.7172858 },
    { lat: 48.3947155, lng: 17.7167113 },
    { lat: 48.3949984, lng: 17.7158503 },
    { lat: 48.3952511, lng: 17.7153598 },
    { lat: 48.3953827, lng: 17.7149495 },
    { lat: 48.3954455, lng: 17.7133444 },
    { lat: 48.3959421, lng: 17.7115787 },
    { lat: 48.3962662, lng: 17.711038 },
    { lat: 48.3964973, lng: 17.7107598 },
    { lat: 48.3966131, lng: 17.7102596 },
    { lat: 48.3970644, lng: 17.7105811 },
    { lat: 48.3973325, lng: 17.7108817 },
    { lat: 48.3974544, lng: 17.7108444 },
    { lat: 48.3975999, lng: 17.7106044 },
    { lat: 48.3976467, lng: 17.7101587 },
    { lat: 48.3978635, lng: 17.7098512 },
    { lat: 48.3982025, lng: 17.7095467 },
    { lat: 48.39827, lng: 17.7093991 },
    { lat: 48.3986545, lng: 17.7058142 },
    { lat: 48.3988457, lng: 17.704481 },
    { lat: 48.3984746, lng: 17.7043575 },
    { lat: 48.3984737, lng: 17.7042898 },
    { lat: 48.4004557, lng: 17.6960118 },
    { lat: 48.4009479, lng: 17.6919705 },
    { lat: 48.4006199, lng: 17.6915828 },
    { lat: 48.4007664, lng: 17.6899817 },
    { lat: 48.4008499, lng: 17.6878853 },
    { lat: 48.4009958, lng: 17.6858782 },
    { lat: 48.401477, lng: 17.6833858 },
    { lat: 48.402027, lng: 17.6795017 },
    { lat: 48.4024701, lng: 17.6774128 },
    { lat: 48.4031837, lng: 17.6746642 },
    { lat: 48.4042452, lng: 17.67196 },
    { lat: 48.4042711, lng: 17.6712541 },
    { lat: 48.4042271, lng: 17.670728 },
    { lat: 48.4030408, lng: 17.6675403 },
    { lat: 48.4019478, lng: 17.6634298 },
    { lat: 48.4010609, lng: 17.6615804 },
    { lat: 48.4001, lng: 17.6600494 },
    { lat: 48.4000643, lng: 17.6599297 },
    { lat: 48.4000531, lng: 17.6598414 },
    { lat: 48.4003566, lng: 17.6595121 },
    { lat: 48.4002499, lng: 17.6589455 },
    { lat: 48.3999021, lng: 17.6560078 },
    { lat: 48.3996576, lng: 17.654862 },
    { lat: 48.4009809, lng: 17.6537975 },
    { lat: 48.4013869, lng: 17.6533066 },
    { lat: 48.4023125, lng: 17.6519125 },
    { lat: 48.4035615, lng: 17.6502927 },
    { lat: 48.4045491, lng: 17.6483996 },
    { lat: 48.4059865, lng: 17.6461653 },
    { lat: 48.4058878, lng: 17.6460147 },
    { lat: 48.4045912, lng: 17.6439953 },
    { lat: 48.4040354, lng: 17.6428047 },
    { lat: 48.4040365, lng: 17.6428021 },
    { lat: 48.4042328, lng: 17.6423496 },
    { lat: 48.4051794, lng: 17.6407002 },
    { lat: 48.4059208, lng: 17.639636 },
    { lat: 48.4067482, lng: 17.6382121 },
    { lat: 48.4070496, lng: 17.637363 },
    { lat: 48.4072091, lng: 17.6365578 },
    { lat: 48.4070118, lng: 17.6363331 },
    { lat: 48.406928, lng: 17.636523 },
    { lat: 48.4067139, lng: 17.6367847 },
    { lat: 48.4052883, lng: 17.6377263 },
    { lat: 48.4026427, lng: 17.6401524 },
    { lat: 48.4020574, lng: 17.6410985 },
    { lat: 48.4010346, lng: 17.6423623 },
    { lat: 48.3997249, lng: 17.6442353 },
    { lat: 48.3977557, lng: 17.6472049 },
    { lat: 48.3954119, lng: 17.6500162 },
    { lat: 48.3946554, lng: 17.6507734 },
    { lat: 48.3936797, lng: 17.6513296 },
    { lat: 48.3929408, lng: 17.6518622 },
    { lat: 48.3922669, lng: 17.6523337 },
    { lat: 48.3904031, lng: 17.6534236 },
    { lat: 48.3878951, lng: 17.6554744 },
    { lat: 48.386871, lng: 17.6565914 },
    { lat: 48.386375, lng: 17.6570359 },
    { lat: 48.3844451, lng: 17.6585622 },
    { lat: 48.3834817, lng: 17.6592247 },
    { lat: 48.3830142, lng: 17.6594257 },
    { lat: 48.3824937, lng: 17.657693 },
    { lat: 48.3813791, lng: 17.6584692 },
    { lat: 48.3805969, lng: 17.6588322 },
    { lat: 48.380527, lng: 17.6588158 },
  ],
  Košolná: [
    { lat: 48.4245438, lng: 17.4316421 },
    { lat: 48.424388, lng: 17.4319408 },
    { lat: 48.4233408, lng: 17.4340849 },
    { lat: 48.422615, lng: 17.4345659 },
    { lat: 48.4224985, lng: 17.4345622 },
    { lat: 48.42195, lng: 17.4352158 },
    { lat: 48.4220386, lng: 17.435382 },
    { lat: 48.4207006, lng: 17.4372576 },
    { lat: 48.4199042, lng: 17.4382732 },
    { lat: 48.4189551, lng: 17.4399106 },
    { lat: 48.4184319, lng: 17.4395755 },
    { lat: 48.4181341, lng: 17.4398784 },
    { lat: 48.4171256, lng: 17.4411026 },
    { lat: 48.4167506, lng: 17.4414705 },
    { lat: 48.4168535, lng: 17.4416973 },
    { lat: 48.4156511, lng: 17.4428622 },
    { lat: 48.4141593, lng: 17.4450178 },
    { lat: 48.4145622, lng: 17.4456243 },
    { lat: 48.414169, lng: 17.4476014 },
    { lat: 48.4140231, lng: 17.4494557 },
    { lat: 48.4137704, lng: 17.4504036 },
    { lat: 48.4138931, lng: 17.4510989 },
    { lat: 48.4138368, lng: 17.452312 },
    { lat: 48.414015, lng: 17.4528136 },
    { lat: 48.4140566, lng: 17.4533206 },
    { lat: 48.4139263, lng: 17.45449 },
    { lat: 48.4138746, lng: 17.4558257 },
    { lat: 48.4136817, lng: 17.4566334 },
    { lat: 48.4136569, lng: 17.4576516 },
    { lat: 48.4137089, lng: 17.4582683 },
    { lat: 48.4135447, lng: 17.459294 },
    { lat: 48.4132902, lng: 17.4599357 },
    { lat: 48.4131928, lng: 17.4607565 },
    { lat: 48.4129966, lng: 17.4610957 },
    { lat: 48.4128811, lng: 17.4614083 },
    { lat: 48.4128168, lng: 17.4621109 },
    { lat: 48.4127221, lng: 17.4626039 },
    { lat: 48.4123164, lng: 17.4637943 },
    { lat: 48.4121083, lng: 17.4649806 },
    { lat: 48.4124411, lng: 17.465275 },
    { lat: 48.4125178, lng: 17.4654999 },
    { lat: 48.4125305, lng: 17.4669265 },
    { lat: 48.4124832, lng: 17.4670894 },
    { lat: 48.4121535, lng: 17.4675632 },
    { lat: 48.4198115, lng: 17.4721737 },
    { lat: 48.421685, lng: 17.4746311 },
    { lat: 48.422006, lng: 17.4752328 },
    { lat: 48.4231645, lng: 17.4737904 },
    { lat: 48.4258015, lng: 17.4793259 },
    { lat: 48.4255732, lng: 17.4797612 },
    { lat: 48.42518, lng: 17.4808326 },
    { lat: 48.4277118, lng: 17.4861947 },
    { lat: 48.4278031, lng: 17.4862459 },
    { lat: 48.4279328, lng: 17.4856112 },
    { lat: 48.4279318, lng: 17.4851896 },
    { lat: 48.4278602, lng: 17.4846392 },
    { lat: 48.4279322, lng: 17.4839272 },
    { lat: 48.4278345, lng: 17.4832159 },
    { lat: 48.4281045, lng: 17.4831131 },
    { lat: 48.4286302, lng: 17.4844743 },
    { lat: 48.4301103, lng: 17.4874409 },
    { lat: 48.430812, lng: 17.4866561 },
    { lat: 48.4309567, lng: 17.4865861 },
    { lat: 48.4313867, lng: 17.4861436 },
    { lat: 48.431412, lng: 17.4860628 },
    { lat: 48.4314895, lng: 17.486127 },
    { lat: 48.4315154, lng: 17.4859468 },
    { lat: 48.4316191, lng: 17.4859258 },
    { lat: 48.4317719, lng: 17.4856661 },
    { lat: 48.4322785, lng: 17.4865245 },
    { lat: 48.4331941, lng: 17.4885431 },
    { lat: 48.4336141, lng: 17.4878745 },
    { lat: 48.4345936, lng: 17.48684 },
    { lat: 48.4370145, lng: 17.4848227 },
    { lat: 48.4369151, lng: 17.4845757 },
    { lat: 48.437247, lng: 17.484115 },
    { lat: 48.4376003, lng: 17.4840777 },
    { lat: 48.4370008, lng: 17.4825129 },
    { lat: 48.4372428, lng: 17.4821317 },
    { lat: 48.4374827, lng: 17.4814891 },
    { lat: 48.4380493, lng: 17.4805313 },
    { lat: 48.4388347, lng: 17.4797292 },
    { lat: 48.4394959, lng: 17.4794925 },
    { lat: 48.4396809, lng: 17.4795766 },
    { lat: 48.4399611, lng: 17.4794539 },
    { lat: 48.4401169, lng: 17.4792598 },
    { lat: 48.4402029, lng: 17.4789105 },
    { lat: 48.4406372, lng: 17.4781123 },
    { lat: 48.4413669, lng: 17.4779912 },
    { lat: 48.4418862, lng: 17.4776157 },
    { lat: 48.4429007, lng: 17.4772348 },
    { lat: 48.4435072, lng: 17.4771639 },
    { lat: 48.4442975, lng: 17.4782666 },
    { lat: 48.4450702, lng: 17.4794692 },
    { lat: 48.4458853, lng: 17.4805171 },
    { lat: 48.4461528, lng: 17.480817 },
    { lat: 48.4460942, lng: 17.4797604 },
    { lat: 48.4438563, lng: 17.4768145 },
    { lat: 48.4449834, lng: 17.4756173 },
    { lat: 48.4443847, lng: 17.4745431 },
    { lat: 48.4452076, lng: 17.4737439 },
    { lat: 48.4454473, lng: 17.4723943 },
    { lat: 48.4444435, lng: 17.4706945 },
    { lat: 48.4455633, lng: 17.4698045 },
    { lat: 48.4460586, lng: 17.4696234 },
    { lat: 48.4467549, lng: 17.4692444 },
    { lat: 48.4471881, lng: 17.4691662 },
    { lat: 48.4475892, lng: 17.4689952 },
    { lat: 48.44914, lng: 17.4678201 },
    { lat: 48.4498392, lng: 17.4670106 },
    { lat: 48.4493116, lng: 17.4659719 },
    { lat: 48.449114, lng: 17.4656032 },
    { lat: 48.4489506, lng: 17.4653442 },
    { lat: 48.4486697, lng: 17.464999 },
    { lat: 48.4483355, lng: 17.4650543 },
    { lat: 48.4478377, lng: 17.4658143 },
    { lat: 48.4476278, lng: 17.466028 },
    { lat: 48.4459342, lng: 17.4672135 },
    { lat: 48.4451691, lng: 17.4675904 },
    { lat: 48.4443378, lng: 17.468239 },
    { lat: 48.4436208, lng: 17.4670302 },
    { lat: 48.4433623, lng: 17.4664694 },
    { lat: 48.4454134, lng: 17.4647532 },
    { lat: 48.4482931, lng: 17.462007 },
    { lat: 48.4461577, lng: 17.458204 },
    { lat: 48.4457944, lng: 17.4586126 },
    { lat: 48.4452872, lng: 17.457314 },
    { lat: 48.4449011, lng: 17.4566145 },
    { lat: 48.4449673, lng: 17.4565356 },
    { lat: 48.4447948, lng: 17.4562234 },
    { lat: 48.4441728, lng: 17.4552489 },
    { lat: 48.4433988, lng: 17.4544871 },
    { lat: 48.4430125, lng: 17.4539983 },
    { lat: 48.4427691, lng: 17.4541807 },
    { lat: 48.4423911, lng: 17.4541169 },
    { lat: 48.4422207, lng: 17.4539439 },
    { lat: 48.4421011, lng: 17.4540164 },
    { lat: 48.4420563, lng: 17.453934 },
    { lat: 48.4417139, lng: 17.454262 },
    { lat: 48.4414528, lng: 17.4549783 },
    { lat: 48.4410744, lng: 17.4532075 },
    { lat: 48.4408515, lng: 17.4526239 },
    { lat: 48.4405126, lng: 17.4519383 },
    { lat: 48.4394782, lng: 17.4505032 },
    { lat: 48.4387275, lng: 17.4489873 },
    { lat: 48.4386229, lng: 17.448888 },
    { lat: 48.438242, lng: 17.4480048 },
    { lat: 48.4384922, lng: 17.4475245 },
    { lat: 48.438562, lng: 17.447191 },
    { lat: 48.4386782, lng: 17.447029 },
    { lat: 48.435762, lng: 17.4440506 },
    { lat: 48.4349105, lng: 17.4430899 },
    { lat: 48.4348743, lng: 17.4429646 },
    { lat: 48.4347298, lng: 17.442794 },
    { lat: 48.4334778, lng: 17.4415072 },
    { lat: 48.4336783, lng: 17.4407958 },
    { lat: 48.4320899, lng: 17.4389905 },
    { lat: 48.4315827, lng: 17.4385324 },
    { lat: 48.4312454, lng: 17.4394852 },
    { lat: 48.4309652, lng: 17.440061 },
    { lat: 48.4302859, lng: 17.4411017 },
    { lat: 48.429233, lng: 17.4431126 },
    { lat: 48.4273474, lng: 17.4412312 },
    { lat: 48.4265028, lng: 17.440499 },
    { lat: 48.4267454, lng: 17.4391824 },
    { lat: 48.4274359, lng: 17.4362343 },
    { lat: 48.4252041, lng: 17.4336271 },
    { lat: 48.4256118, lng: 17.4328049 },
    { lat: 48.4245438, lng: 17.4316421 },
  ],
  Špačince: [
    { lat: 48.4721994, lng: 17.5974153 },
    { lat: 48.4718228, lng: 17.5968897 },
    { lat: 48.4705294, lng: 17.5950748 },
    { lat: 48.4690302, lng: 17.5933819 },
    { lat: 48.4686848, lng: 17.5935775 },
    { lat: 48.4686001, lng: 17.5939198 },
    { lat: 48.4679796, lng: 17.593195 },
    { lat: 48.4671627, lng: 17.5917937 },
    { lat: 48.4666274, lng: 17.5910251 },
    { lat: 48.4661866, lng: 17.5900882 },
    { lat: 48.4609174, lng: 17.5896216 },
    { lat: 48.4604309, lng: 17.5904279 },
    { lat: 48.4602619, lng: 17.5902212 },
    { lat: 48.4600554, lng: 17.5901703 },
    { lat: 48.4598703, lng: 17.5899224 },
    { lat: 48.4597013, lng: 17.5895687 },
    { lat: 48.4592419, lng: 17.5892344 },
    { lat: 48.4592412, lng: 17.5891474 },
    { lat: 48.4590379, lng: 17.5891135 },
    { lat: 48.4588806, lng: 17.5889459 },
    { lat: 48.4579168, lng: 17.5898482 },
    { lat: 48.4564526, lng: 17.5873571 },
    { lat: 48.4556249, lng: 17.5857134 },
    { lat: 48.4549896, lng: 17.5846854 },
    { lat: 48.4573802, lng: 17.5817917 },
    { lat: 48.4566588, lng: 17.5803637 },
    { lat: 48.4555662, lng: 17.5786957 },
    { lat: 48.4546362, lng: 17.5775996 },
    { lat: 48.4517499, lng: 17.5739256 },
    { lat: 48.4515594, lng: 17.5730913 },
    { lat: 48.4513934, lng: 17.572881 },
    { lat: 48.4502612, lng: 17.5707131 },
    { lat: 48.4497104, lng: 17.5697736 },
    { lat: 48.4474468, lng: 17.5663626 },
    { lat: 48.4472308, lng: 17.5661236 },
    { lat: 48.445909, lng: 17.5642453 },
    { lat: 48.4457079, lng: 17.563816 },
    { lat: 48.4455553, lng: 17.5640111 },
    { lat: 48.445537, lng: 17.564151 },
    { lat: 48.4452455, lng: 17.5643618 },
    { lat: 48.4449588, lng: 17.5647365 },
    { lat: 48.4447833, lng: 17.5648566 },
    { lat: 48.44464, lng: 17.5651606 },
    { lat: 48.4444391, lng: 17.5652869 },
    { lat: 48.4441871, lng: 17.5656754 },
    { lat: 48.4439223, lng: 17.5658244 },
    { lat: 48.4436363, lng: 17.5661438 },
    { lat: 48.4434861, lng: 17.566499 },
    { lat: 48.4432636, lng: 17.5665501 },
    { lat: 48.4430041, lng: 17.5667352 },
    { lat: 48.4427669, lng: 17.5670694 },
    { lat: 48.4425376, lng: 17.5670506 },
    { lat: 48.4422403, lng: 17.5673664 },
    { lat: 48.4420001, lng: 17.5673176 },
    { lat: 48.4418549, lng: 17.5677374 },
    { lat: 48.441396, lng: 17.5680169 },
    { lat: 48.4411579, lng: 17.5684981 },
    { lat: 48.4405105, lng: 17.5689905 },
    { lat: 48.4404651, lng: 17.5691667 },
    { lat: 48.4404641, lng: 17.5694905 },
    { lat: 48.4403391, lng: 17.5696023 },
    { lat: 48.4400664, lng: 17.5697007 },
    { lat: 48.4393146, lng: 17.5703279 },
    { lat: 48.4390614, lng: 17.5704474 },
    { lat: 48.4386672, lng: 17.5710348 },
    { lat: 48.4382851, lng: 17.5711745 },
    { lat: 48.4378579, lng: 17.5714868 },
    { lat: 48.4374163, lng: 17.5720048 },
    { lat: 48.4370851, lng: 17.5727304 },
    { lat: 48.4368221, lng: 17.5730878 },
    { lat: 48.4366864, lng: 17.5731385 },
    { lat: 48.4364886, lng: 17.5733325 },
    { lat: 48.4359845, lng: 17.574411 },
    { lat: 48.4356929, lng: 17.574462 },
    { lat: 48.4355286, lng: 17.5746477 },
    { lat: 48.4347878, lng: 17.5749333 },
    { lat: 48.434529, lng: 17.5753572 },
    { lat: 48.4341707, lng: 17.5756936 },
    { lat: 48.4336421, lng: 17.5764929 },
    { lat: 48.4315216, lng: 17.5728257 },
    { lat: 48.4316855, lng: 17.5725246 },
    { lat: 48.4303381, lng: 17.5703638 },
    { lat: 48.4300423, lng: 17.5698863 },
    { lat: 48.4292312, lng: 17.5698268 },
    { lat: 48.428303, lng: 17.5702022 },
    { lat: 48.4275039, lng: 17.5711183 },
    { lat: 48.4265525, lng: 17.5719823 },
    { lat: 48.4263801, lng: 17.5722185 },
    { lat: 48.4262039, lng: 17.5729154 },
    { lat: 48.4258635, lng: 17.5738508 },
    { lat: 48.4254677, lng: 17.5744834 },
    { lat: 48.4249928, lng: 17.5751145 },
    { lat: 48.4244806, lng: 17.5761023 },
    { lat: 48.4248389, lng: 17.5766256 },
    { lat: 48.4243442, lng: 17.5767141 },
    { lat: 48.4240211, lng: 17.5769775 },
    { lat: 48.4240454, lng: 17.5770391 },
    { lat: 48.4238999, lng: 17.5773646 },
    { lat: 48.4238501, lng: 17.5779349 },
    { lat: 48.4234733, lng: 17.5787066 },
    { lat: 48.4231675, lng: 17.5790838 },
    { lat: 48.4229252, lng: 17.5795205 },
    { lat: 48.422799, lng: 17.5796139 },
    { lat: 48.4226821, lng: 17.5795681 },
    { lat: 48.4223666, lng: 17.5798659 },
    { lat: 48.4227532, lng: 17.5806732 },
    { lat: 48.4243523, lng: 17.5841901 },
    { lat: 48.4252497, lng: 17.5864312 },
    { lat: 48.4267971, lng: 17.5897258 },
    { lat: 48.4270583, lng: 17.5904989 },
    { lat: 48.4269907, lng: 17.5905337 },
    { lat: 48.4270516, lng: 17.5907117 },
    { lat: 48.4278428, lng: 17.5922066 },
    { lat: 48.425738, lng: 17.5941211 },
    { lat: 48.4247759, lng: 17.5950846 },
    { lat: 48.4246526, lng: 17.5953162 },
    { lat: 48.4244662, lng: 17.5959763 },
    { lat: 48.4234894, lng: 17.5983 },
    { lat: 48.4232833, lng: 17.5990411 },
    { lat: 48.4229622, lng: 17.6010201 },
    { lat: 48.4224332, lng: 17.602765 },
    { lat: 48.4225897, lng: 17.6034178 },
    { lat: 48.4221359, lng: 17.6072718 },
    { lat: 48.4218075, lng: 17.6085806 },
    { lat: 48.4210854, lng: 17.6106642 },
    { lat: 48.4206815, lng: 17.6111906 },
    { lat: 48.4183383, lng: 17.6134827 },
    { lat: 48.4146539, lng: 17.6173594 },
    { lat: 48.413708, lng: 17.6185634 },
    { lat: 48.4122573, lng: 17.6208481 },
    { lat: 48.4146557, lng: 17.623915 },
    { lat: 48.4182677, lng: 17.628988 },
    { lat: 48.4213269, lng: 17.6334464 },
    { lat: 48.4219464, lng: 17.6343922 },
    { lat: 48.422794, lng: 17.6358387 },
    { lat: 48.4230792, lng: 17.6361935 },
    { lat: 48.4228564, lng: 17.6364373 },
    { lat: 48.4223676, lng: 17.636656 },
    { lat: 48.4223743, lng: 17.6371207 },
    { lat: 48.422298, lng: 17.6374351 },
    { lat: 48.4220647, lng: 17.6375862 },
    { lat: 48.421832, lng: 17.6375122 },
    { lat: 48.4216401, lng: 17.6375842 },
    { lat: 48.4214326, lng: 17.6375162 },
    { lat: 48.4211839, lng: 17.6377314 },
    { lat: 48.4210044, lng: 17.6380529 },
    { lat: 48.4206951, lng: 17.638127 },
    { lat: 48.4205236, lng: 17.6382907 },
    { lat: 48.4205656, lng: 17.6389591 },
    { lat: 48.4209853, lng: 17.6396553 },
    { lat: 48.4221891, lng: 17.6427698 },
    { lat: 48.4224261, lng: 17.6426793 },
    { lat: 48.4232584, lng: 17.642357 },
    { lat: 48.4237227, lng: 17.6421045 },
    { lat: 48.4241453, lng: 17.6417691 },
    { lat: 48.4250139, lng: 17.6412344 },
    { lat: 48.4272788, lng: 17.6402763 },
    { lat: 48.4290094, lng: 17.6394425 },
    { lat: 48.430396, lng: 17.6388861 },
    { lat: 48.4315509, lng: 17.6385389 },
    { lat: 48.4332993, lng: 17.6377025 },
    { lat: 48.4342461, lng: 17.6374068 },
    { lat: 48.4433426, lng: 17.632565 },
    { lat: 48.4433885, lng: 17.6325603 },
    { lat: 48.4436115, lng: 17.6328946 },
    { lat: 48.4448859, lng: 17.6350599 },
    { lat: 48.4455519, lng: 17.6349672 },
    { lat: 48.4456377, lng: 17.634933 },
    { lat: 48.445749, lng: 17.6348884 },
    { lat: 48.4468021, lng: 17.6337459 },
    { lat: 48.4473993, lng: 17.633298 },
    { lat: 48.4489151, lng: 17.6323665 },
    { lat: 48.4492536, lng: 17.6319884 },
    { lat: 48.4496427, lng: 17.631422 },
    { lat: 48.4507904, lng: 17.6302254 },
    { lat: 48.452546, lng: 17.6287819 },
    { lat: 48.452905, lng: 17.6284585 },
    { lat: 48.4536457, lng: 17.6276318 },
    { lat: 48.4541843, lng: 17.6271811 },
    { lat: 48.4550363, lng: 17.6265624 },
    { lat: 48.4563452, lng: 17.6259029 },
    { lat: 48.4575445, lng: 17.6250956 },
    { lat: 48.4575307, lng: 17.6250382 },
    { lat: 48.4575198, lng: 17.6246129 },
    { lat: 48.4574247, lng: 17.6241045 },
    { lat: 48.457468, lng: 17.6240592 },
    { lat: 48.4573429, lng: 17.6236323 },
    { lat: 48.4578828, lng: 17.6230394 },
    { lat: 48.4579701, lng: 17.6228132 },
    { lat: 48.4581845, lng: 17.6226304 },
    { lat: 48.4587643, lng: 17.6223342 },
    { lat: 48.4589884, lng: 17.622108 },
    { lat: 48.4604041, lng: 17.6214389 },
    { lat: 48.4606894, lng: 17.6211098 },
    { lat: 48.4617894, lng: 17.6193192 },
    { lat: 48.4623911, lng: 17.6184915 },
    { lat: 48.4633931, lng: 17.6183692 },
    { lat: 48.4638928, lng: 17.6181284 },
    { lat: 48.4646809, lng: 17.617337 },
    { lat: 48.4654369, lng: 17.6169722 },
    { lat: 48.4663081, lng: 17.6167457 },
    { lat: 48.4671168, lng: 17.6162822 },
    { lat: 48.4673576, lng: 17.6160555 },
    { lat: 48.4685322, lng: 17.6146229 },
    { lat: 48.4690495, lng: 17.614124 },
    { lat: 48.469894, lng: 17.6129025 },
    { lat: 48.4703196, lng: 17.6124497 },
    { lat: 48.4706139, lng: 17.6122455 },
    { lat: 48.4709321, lng: 17.6118472 },
    { lat: 48.4709729, lng: 17.6114201 },
    { lat: 48.4711886, lng: 17.6109905 },
    { lat: 48.4714863, lng: 17.6109114 },
    { lat: 48.4717229, lng: 17.6107086 },
    { lat: 48.4718307, lng: 17.6107324 },
    { lat: 48.4719311, lng: 17.6106594 },
    { lat: 48.4720556, lng: 17.6104107 },
    { lat: 48.4721225, lng: 17.6100041 },
    { lat: 48.4721851, lng: 17.6098873 },
    { lat: 48.4726332, lng: 17.6095246 },
    { lat: 48.4727828, lng: 17.609357 },
    { lat: 48.4731649, lng: 17.6090922 },
    { lat: 48.474506, lng: 17.6075288 },
    { lat: 48.4736773, lng: 17.6063121 },
    { lat: 48.475037, lng: 17.6048435 },
    { lat: 48.4761363, lng: 17.6038148 },
    { lat: 48.4770631, lng: 17.6025897 },
    { lat: 48.4764821, lng: 17.6018829 },
    { lat: 48.4746934, lng: 17.6000932 },
    { lat: 48.4735183, lng: 17.5990612 },
    { lat: 48.4728193, lng: 17.5982549 },
    { lat: 48.4721994, lng: 17.5974153 },
  ],
  Radošovce: [
    { lat: 48.513166, lng: 17.662121 },
    { lat: 48.51323, lng: 17.662024 },
    { lat: 48.513256, lng: 17.661984 },
    { lat: 48.513358, lng: 17.66183 },
    { lat: 48.513462, lng: 17.661674 },
    { lat: 48.513582, lng: 17.661493 },
    { lat: 48.513605, lng: 17.66144 },
    { lat: 48.513604, lng: 17.661429 },
    { lat: 48.513598, lng: 17.661421 },
    { lat: 48.513571, lng: 17.661388 },
    { lat: 48.513564, lng: 17.66138 },
    { lat: 48.513073, lng: 17.66081 },
    { lat: 48.512601, lng: 17.660204 },
    { lat: 48.512091, lng: 17.659586 },
    { lat: 48.511644, lng: 17.659041 },
    { lat: 48.511108, lng: 17.658358 },
    { lat: 48.510913, lng: 17.658119 },
    { lat: 48.510571, lng: 17.65771 },
    { lat: 48.510333, lng: 17.657404 },
    { lat: 48.51009, lng: 17.657081 },
    { lat: 48.50973, lng: 17.656719 },
    { lat: 48.509503, lng: 17.656573 },
    { lat: 48.509483, lng: 17.65656 },
    { lat: 48.509694, lng: 17.655546 },
    { lat: 48.510293, lng: 17.653741 },
    { lat: 48.51039, lng: 17.653461 },
    { lat: 48.510706, lng: 17.652393 },
    { lat: 48.510805, lng: 17.652143 },
    { lat: 48.512142, lng: 17.648697 },
    { lat: 48.512937, lng: 17.647069 },
    { lat: 48.513228, lng: 17.646469 },
    { lat: 48.513449, lng: 17.646062 },
    { lat: 48.51378, lng: 17.645441 },
    { lat: 48.51469, lng: 17.644046 },
    { lat: 48.515282, lng: 17.643118 },
    { lat: 48.515551, lng: 17.642689 },
    { lat: 48.515839, lng: 17.642256 },
    { lat: 48.515941, lng: 17.6421 },
    { lat: 48.516307, lng: 17.641417 },
    { lat: 48.516337, lng: 17.641361 },
    { lat: 48.516366, lng: 17.641313 },
    { lat: 48.5164448, lng: 17.6411826 },
    { lat: 48.5163258, lng: 17.6410666 },
    { lat: 48.5155157, lng: 17.6397991 },
    { lat: 48.5148744, lng: 17.6386469 },
    { lat: 48.5144126, lng: 17.6376547 },
    { lat: 48.5138542, lng: 17.6362069 },
    { lat: 48.5133672, lng: 17.6351714 },
    { lat: 48.5121763, lng: 17.6332512 },
    { lat: 48.5112833, lng: 17.6320669 },
    { lat: 48.5102151, lng: 17.6305194 },
    { lat: 48.5091594, lng: 17.6287754 },
    { lat: 48.5077602, lng: 17.6262054 },
    { lat: 48.5071186, lng: 17.6246739 },
    { lat: 48.5068511, lng: 17.6239182 },
    { lat: 48.5066861, lng: 17.6237181 },
    { lat: 48.5063346, lng: 17.6230681 },
    { lat: 48.5059907, lng: 17.6232359 },
    { lat: 48.5054112, lng: 17.6231411 },
    { lat: 48.5050024, lng: 17.6235009 },
    { lat: 48.5048525, lng: 17.6236344 },
    { lat: 48.5036393, lng: 17.6248841 },
    { lat: 48.5027039, lng: 17.6262239 },
    { lat: 48.5013459, lng: 17.6276131 },
    { lat: 48.5010755, lng: 17.6277875 },
    { lat: 48.5008166, lng: 17.6278557 },
    { lat: 48.5005488, lng: 17.6280483 },
    { lat: 48.5004833, lng: 17.6279771 },
    { lat: 48.5001604, lng: 17.6282837 },
    { lat: 48.4999564, lng: 17.6283248 },
    { lat: 48.4997295, lng: 17.6281481 },
    { lat: 48.4994992, lng: 17.6283984 },
    { lat: 48.4993637, lng: 17.6283436 },
    { lat: 48.4989028, lng: 17.628612 },
    { lat: 48.4986127, lng: 17.6285404 },
    { lat: 48.4983099, lng: 17.6287711 },
    { lat: 48.4981932, lng: 17.6290537 },
    { lat: 48.4977714, lng: 17.6292767 },
    { lat: 48.4975586, lng: 17.6295024 },
    { lat: 48.4973603, lng: 17.6295808 },
    { lat: 48.4972266, lng: 17.6296294 },
    { lat: 48.4971737, lng: 17.6295214 },
    { lat: 48.4971256, lng: 17.629423 },
    { lat: 48.4968055, lng: 17.6297369 },
    { lat: 48.4959679, lng: 17.6296164 },
    { lat: 48.4957848, lng: 17.6297037 },
    { lat: 48.4955567, lng: 17.6300996 },
    { lat: 48.4953947, lng: 17.6302703 },
    { lat: 48.4951203, lng: 17.6303578 },
    { lat: 48.4949886, lng: 17.6305634 },
    { lat: 48.4948205, lng: 17.6306927 },
    { lat: 48.4945984, lng: 17.6306979 },
    { lat: 48.4944223, lng: 17.6307874 },
    { lat: 48.4943434, lng: 17.6309517 },
    { lat: 48.4951725, lng: 17.6321614 },
    { lat: 48.4948324, lng: 17.6324475 },
    { lat: 48.4944553, lng: 17.6325912 },
    { lat: 48.4943251, lng: 17.6328738 },
    { lat: 48.4943101, lng: 17.6333017 },
    { lat: 48.4939881, lng: 17.6339278 },
    { lat: 48.4940072, lng: 17.6340514 },
    { lat: 48.4939069, lng: 17.6345235 },
    { lat: 48.4937479, lng: 17.6349387 },
    { lat: 48.4936106, lng: 17.635111 },
    { lat: 48.4935839, lng: 17.6353679 },
    { lat: 48.4934013, lng: 17.6356255 },
    { lat: 48.4933108, lng: 17.6359104 },
    { lat: 48.4928451, lng: 17.6365126 },
    { lat: 48.4926811, lng: 17.6366182 },
    { lat: 48.4924179, lng: 17.6365648 },
    { lat: 48.4920685, lng: 17.6368258 },
    { lat: 48.491941, lng: 17.6367546 },
    { lat: 48.4918128, lng: 17.6367813 },
    { lat: 48.4914745, lng: 17.6371291 },
    { lat: 48.4913353, lng: 17.6373963 },
    { lat: 48.4910458, lng: 17.6374253 },
    { lat: 48.4908402, lng: 17.6376745 },
    { lat: 48.4905599, lng: 17.6378147 },
    { lat: 48.4897194, lng: 17.6379696 },
    { lat: 48.4894425, lng: 17.6381652 },
    { lat: 48.4892025, lng: 17.6384609 },
    { lat: 48.4890975, lng: 17.6387118 },
    { lat: 48.487792, lng: 17.6405103 },
    { lat: 48.4876935, lng: 17.6406488 },
    { lat: 48.487502, lng: 17.6409266 },
    { lat: 48.4865676, lng: 17.6422883 },
    { lat: 48.4861533, lng: 17.6433526 },
    { lat: 48.4869588, lng: 17.6451391 },
    { lat: 48.4867442, lng: 17.6454457 },
    { lat: 48.4867718, lng: 17.6455995 },
    { lat: 48.4865083, lng: 17.6459903 },
    { lat: 48.4867202, lng: 17.6464867 },
    { lat: 48.4867763, lng: 17.6467507 },
    { lat: 48.4874695, lng: 17.6482449 },
    { lat: 48.488696, lng: 17.6505755 },
    { lat: 48.4889738, lng: 17.6514594 },
    { lat: 48.489085, lng: 17.6518292 },
    { lat: 48.4892429, lng: 17.6527113 },
    { lat: 48.4893131, lng: 17.6539764 },
    { lat: 48.4894131, lng: 17.6548905 },
    { lat: 48.4901912, lng: 17.6578694 },
    { lat: 48.4910416, lng: 17.6606511 },
    { lat: 48.4920856, lng: 17.663302 },
    { lat: 48.4937018, lng: 17.6663064 },
    { lat: 48.4945187, lng: 17.6676152 },
    { lat: 48.4954194, lng: 17.6688758 },
    { lat: 48.497266, lng: 17.6711452 },
    { lat: 48.497424, lng: 17.6714159 },
    { lat: 48.4977052, lng: 17.6722102 },
    { lat: 48.4985674, lng: 17.6735446 },
    { lat: 48.498577, lng: 17.673532 },
    { lat: 48.499073, lng: 17.672964 },
    { lat: 48.499263, lng: 17.672747 },
    { lat: 48.499646, lng: 17.672232 },
    { lat: 48.499986, lng: 17.671762 },
    { lat: 48.50025, lng: 17.671516 },
    { lat: 48.500576, lng: 17.671214 },
    { lat: 48.500869, lng: 17.67093 },
    { lat: 48.501189, lng: 17.67062 },
    { lat: 48.501473, lng: 17.670355 },
    { lat: 48.501729, lng: 17.670094 },
    { lat: 48.501955, lng: 17.669838 },
    { lat: 48.502228, lng: 17.669569 },
    { lat: 48.5026, lng: 17.669191 },
    { lat: 48.502981, lng: 17.668816 },
    { lat: 48.503364, lng: 17.668441 },
    { lat: 48.503634, lng: 17.668238 },
    { lat: 48.503856, lng: 17.668075 },
    { lat: 48.504092, lng: 17.667933 },
    { lat: 48.504184, lng: 17.667902 },
    { lat: 48.504339, lng: 17.667824 },
    { lat: 48.504508, lng: 17.667662 },
    { lat: 48.504904, lng: 17.667279 },
    { lat: 48.50511, lng: 17.666998 },
    { lat: 48.505505, lng: 17.666472 },
    { lat: 48.505641, lng: 17.66639 },
    { lat: 48.505992, lng: 17.666227 },
    { lat: 48.506523, lng: 17.665988 },
    { lat: 48.50695, lng: 17.665788 },
    { lat: 48.507353, lng: 17.665611 },
    { lat: 48.507772, lng: 17.665422 },
    { lat: 48.50805, lng: 17.665231 },
    { lat: 48.508329, lng: 17.664978 },
    { lat: 48.508826, lng: 17.664515 },
    { lat: 48.509048, lng: 17.664379 },
    { lat: 48.509598, lng: 17.663987 },
    { lat: 48.509977, lng: 17.663715 },
    { lat: 48.51045, lng: 17.663388 },
    { lat: 48.510982, lng: 17.663009 },
    { lat: 48.511572, lng: 17.662758 },
    { lat: 48.512041, lng: 17.66258 },
    { lat: 48.512543, lng: 17.662372 },
    { lat: 48.513166, lng: 17.662121 },
  ],
  Bučany: [
    { lat: 48.419363, lng: 17.730542 },
    { lat: 48.419366, lng: 17.730491 },
    { lat: 48.420254, lng: 17.729325 },
    { lat: 48.420318, lng: 17.729241 },
    { lat: 48.420912, lng: 17.72846 },
    { lat: 48.42079, lng: 17.727488 },
    { lat: 48.42074, lng: 17.727074 },
    { lat: 48.42073, lng: 17.726993 },
    { lat: 48.420728, lng: 17.726974 },
    { lat: 48.420707, lng: 17.726801 },
    { lat: 48.422537, lng: 17.726867 },
    { lat: 48.4225, lng: 17.726648 },
    { lat: 48.422445, lng: 17.726452 },
    { lat: 48.422254, lng: 17.72603 },
    { lat: 48.422176, lng: 17.72551 },
    { lat: 48.422098, lng: 17.725239 },
    { lat: 48.421928, lng: 17.724835 },
    { lat: 48.421911, lng: 17.724712 },
    { lat: 48.42191, lng: 17.724704 },
    { lat: 48.421912, lng: 17.724647 },
    { lat: 48.421937, lng: 17.723239 },
    { lat: 48.422049, lng: 17.72325 },
    { lat: 48.422446, lng: 17.723708 },
    { lat: 48.423054, lng: 17.724479 },
    { lat: 48.423505, lng: 17.725535 },
    { lat: 48.42355, lng: 17.725639 },
    { lat: 48.423743, lng: 17.724628 },
    { lat: 48.426774, lng: 17.723343 },
    { lat: 48.426906, lng: 17.722108 },
    { lat: 48.427479, lng: 17.722234 },
    { lat: 48.427479, lng: 17.722024 },
    { lat: 48.427479, lng: 17.721933 },
    { lat: 48.427477, lng: 17.719669 },
    { lat: 48.427387, lng: 17.718099 },
    { lat: 48.427226, lng: 17.715292 },
    { lat: 48.427213, lng: 17.713542 },
    { lat: 48.427228, lng: 17.713516 },
    { lat: 48.427337, lng: 17.713332 },
    { lat: 48.427354, lng: 17.713305 },
    { lat: 48.428309, lng: 17.711691 },
    { lat: 48.428421, lng: 17.711129 },
    { lat: 48.427868, lng: 17.709486 },
    { lat: 48.427835, lng: 17.70939 },
    { lat: 48.427765, lng: 17.709179 },
    { lat: 48.427846, lng: 17.708286 },
    { lat: 48.427856, lng: 17.708189 },
    { lat: 48.427862, lng: 17.708139 },
    { lat: 48.427865, lng: 17.708104 },
    { lat: 48.427345, lng: 17.708038 },
    { lat: 48.427213, lng: 17.707988 },
    { lat: 48.427274, lng: 17.707456 },
    { lat: 48.427402, lng: 17.706378 },
    { lat: 48.427596, lng: 17.705061 },
    { lat: 48.42775, lng: 17.703763 },
    { lat: 48.4277, lng: 17.702158 },
    { lat: 48.427677, lng: 17.701286 },
    { lat: 48.427674, lng: 17.701234 },
    { lat: 48.427673, lng: 17.701219 },
    { lat: 48.427591, lng: 17.699776 },
    { lat: 48.427582, lng: 17.699627 },
    { lat: 48.427556, lng: 17.699076 },
    { lat: 48.427545, lng: 17.698916 },
    { lat: 48.42754, lng: 17.698836 },
    { lat: 48.427532, lng: 17.698706 },
    { lat: 48.427523, lng: 17.69863 },
    { lat: 48.427524, lng: 17.698555 },
    { lat: 48.427364, lng: 17.698516 },
    { lat: 48.427165, lng: 17.698466 },
    { lat: 48.427167, lng: 17.698411 },
    { lat: 48.427179, lng: 17.698339 },
    { lat: 48.427181, lng: 17.698281 },
    { lat: 48.427165, lng: 17.698241 },
    { lat: 48.427072, lng: 17.698016 },
    { lat: 48.427036, lng: 17.69793 },
    { lat: 48.427012, lng: 17.697868 },
    { lat: 48.428047, lng: 17.696829 },
    { lat: 48.427779, lng: 17.696396 },
    { lat: 48.427681, lng: 17.696213 },
    { lat: 48.427696, lng: 17.696154 },
    { lat: 48.427999, lng: 17.695813 },
    { lat: 48.428163, lng: 17.695542 },
    { lat: 48.428192, lng: 17.695509 },
    { lat: 48.428477, lng: 17.695208 },
    { lat: 48.428594, lng: 17.695059 },
    { lat: 48.428701, lng: 17.694831 },
    { lat: 48.428932, lng: 17.69456 },
    { lat: 48.429061, lng: 17.694272 },
    { lat: 48.429197, lng: 17.694119 },
    { lat: 48.429351, lng: 17.694095 },
    { lat: 48.429441, lng: 17.693949 },
    { lat: 48.429556, lng: 17.693549 },
    { lat: 48.429847, lng: 17.693059 },
    { lat: 48.429865, lng: 17.693012 },
    { lat: 48.429899, lng: 17.692868 },
    { lat: 48.42999, lng: 17.692756 },
    { lat: 48.430182, lng: 17.692654 },
    { lat: 48.430335, lng: 17.692499 },
    { lat: 48.430584, lng: 17.692328 },
    { lat: 48.430779, lng: 17.691938 },
    { lat: 48.430957, lng: 17.691863 },
    { lat: 48.431184, lng: 17.691743 },
    { lat: 48.43126, lng: 17.691621 },
    { lat: 48.431446, lng: 17.691215 },
    { lat: 48.431485, lng: 17.691189 },
    { lat: 48.431705, lng: 17.691084 },
    { lat: 48.431763, lng: 17.690954 },
    { lat: 48.431877, lng: 17.690473 },
    { lat: 48.432037, lng: 17.690269 },
    { lat: 48.432163, lng: 17.690172 },
    { lat: 48.432233, lng: 17.689983 },
    { lat: 48.432246, lng: 17.68983 },
    { lat: 48.432404, lng: 17.689682 },
    { lat: 48.432557, lng: 17.689629 },
    { lat: 48.432634, lng: 17.689601 },
    { lat: 48.4339608, lng: 17.6859442 },
    { lat: 48.4327004, lng: 17.6853591 },
    { lat: 48.4306978, lng: 17.680609 },
    { lat: 48.4305791, lng: 17.6800708 },
    { lat: 48.4300915, lng: 17.6778595 },
    { lat: 48.4296425, lng: 17.6755152 },
    { lat: 48.427887, lng: 17.66842 },
    { lat: 48.4272049, lng: 17.6651851 },
    { lat: 48.4253954, lng: 17.6600723 },
    { lat: 48.4251979, lng: 17.659514 },
    { lat: 48.4105059, lng: 17.6519162 },
    { lat: 48.4089386, lng: 17.649845 },
    { lat: 48.4079913, lng: 17.6484779 },
    { lat: 48.407573, lng: 17.6491004 },
    { lat: 48.4075246, lng: 17.6490308 },
    { lat: 48.4075658, lng: 17.6489859 },
    { lat: 48.4071518, lng: 17.6479303 },
    { lat: 48.4066213, lng: 17.6470335 },
    { lat: 48.4059865, lng: 17.6461653 },
    { lat: 48.4045491, lng: 17.6483996 },
    { lat: 48.4035615, lng: 17.6502927 },
    { lat: 48.4023125, lng: 17.6519125 },
    { lat: 48.4013869, lng: 17.6533066 },
    { lat: 48.4009809, lng: 17.6537975 },
    { lat: 48.3996576, lng: 17.654862 },
    { lat: 48.3999021, lng: 17.6560078 },
    { lat: 48.4002499, lng: 17.6589455 },
    { lat: 48.4003566, lng: 17.6595121 },
    { lat: 48.4000531, lng: 17.6598414 },
    { lat: 48.4000643, lng: 17.6599297 },
    { lat: 48.4001, lng: 17.6600494 },
    { lat: 48.4010609, lng: 17.6615804 },
    { lat: 48.4019478, lng: 17.6634298 },
    { lat: 48.4030408, lng: 17.6675403 },
    { lat: 48.4042271, lng: 17.670728 },
    { lat: 48.4042711, lng: 17.6712541 },
    { lat: 48.4042452, lng: 17.67196 },
    { lat: 48.4031837, lng: 17.6746642 },
    { lat: 48.4024701, lng: 17.6774128 },
    { lat: 48.402027, lng: 17.6795017 },
    { lat: 48.401477, lng: 17.6833858 },
    { lat: 48.4009958, lng: 17.6858782 },
    { lat: 48.4008499, lng: 17.6878853 },
    { lat: 48.4007664, lng: 17.6899817 },
    { lat: 48.4006199, lng: 17.6915828 },
    { lat: 48.4009479, lng: 17.6919705 },
    { lat: 48.4004557, lng: 17.6960118 },
    { lat: 48.3984737, lng: 17.7042898 },
    { lat: 48.3984746, lng: 17.7043575 },
    { lat: 48.3988457, lng: 17.704481 },
    { lat: 48.3986545, lng: 17.7058142 },
    { lat: 48.39827, lng: 17.7093991 },
    { lat: 48.3982025, lng: 17.7095467 },
    { lat: 48.3978635, lng: 17.7098512 },
    { lat: 48.3976467, lng: 17.7101587 },
    { lat: 48.3975999, lng: 17.7106044 },
    { lat: 48.3974544, lng: 17.7108444 },
    { lat: 48.3973325, lng: 17.7108817 },
    { lat: 48.3970644, lng: 17.7105811 },
    { lat: 48.3966131, lng: 17.7102596 },
    { lat: 48.3964973, lng: 17.7107598 },
    { lat: 48.3962662, lng: 17.711038 },
    { lat: 48.3959421, lng: 17.7115787 },
    { lat: 48.3954455, lng: 17.7133444 },
    { lat: 48.3953827, lng: 17.7149495 },
    { lat: 48.3952511, lng: 17.7153598 },
    { lat: 48.3949984, lng: 17.7158503 },
    { lat: 48.3947155, lng: 17.7167113 },
    { lat: 48.3944744, lng: 17.7172858 },
    { lat: 48.3942234, lng: 17.7188248 },
    { lat: 48.3937604, lng: 17.720041 },
    { lat: 48.393469, lng: 17.720753 },
    { lat: 48.393431, lng: 17.720876 },
    { lat: 48.393494, lng: 17.720909 },
    { lat: 48.395564, lng: 17.72199 },
    { lat: 48.395588, lng: 17.722003 },
    { lat: 48.397472, lng: 17.722987 },
    { lat: 48.397516, lng: 17.723025 },
    { lat: 48.39756, lng: 17.72307 },
    { lat: 48.397781, lng: 17.723155 },
    { lat: 48.397972, lng: 17.723183 },
    { lat: 48.398055, lng: 17.723167 },
    { lat: 48.398137, lng: 17.723017 },
    { lat: 48.398271, lng: 17.722902 },
    { lat: 48.398441, lng: 17.723059 },
    { lat: 48.398558, lng: 17.723226 },
    { lat: 48.398902, lng: 17.723197 },
    { lat: 48.39902, lng: 17.723083 },
    { lat: 48.399194, lng: 17.723084 },
    { lat: 48.399402, lng: 17.722979 },
    { lat: 48.399595, lng: 17.722971 },
    { lat: 48.399784, lng: 17.722872 },
    { lat: 48.399988, lng: 17.722865 },
    { lat: 48.400224, lng: 17.722892 },
    { lat: 48.400308, lng: 17.722778 },
    { lat: 48.40032, lng: 17.722705 },
    { lat: 48.400409, lng: 17.722342 },
    { lat: 48.400471, lng: 17.722159 },
    { lat: 48.400525, lng: 17.722127 },
    { lat: 48.400836, lng: 17.722149 },
    { lat: 48.400877, lng: 17.722077 },
    { lat: 48.400907, lng: 17.721862 },
    { lat: 48.401047, lng: 17.721491 },
    { lat: 48.40141, lng: 17.721253 },
    { lat: 48.401642, lng: 17.721357 },
    { lat: 48.401897, lng: 17.721121 },
    { lat: 48.401968, lng: 17.721079 },
    { lat: 48.402228, lng: 17.721427 },
    { lat: 48.40234, lng: 17.721625 },
    { lat: 48.402357, lng: 17.721653 },
    { lat: 48.402366, lng: 17.721677 },
    { lat: 48.402371, lng: 17.721689 },
    { lat: 48.402432, lng: 17.721838 },
    { lat: 48.403285, lng: 17.721275 },
    { lat: 48.403473, lng: 17.721152 },
    { lat: 48.404863, lng: 17.720163 },
    { lat: 48.405572, lng: 17.719716 },
    { lat: 48.405626, lng: 17.719683 },
    { lat: 48.40569, lng: 17.719642 },
    { lat: 48.405715, lng: 17.719626 },
    { lat: 48.405747, lng: 17.719606 },
    { lat: 48.405769, lng: 17.719592 },
    { lat: 48.405871, lng: 17.719527 },
    { lat: 48.406228, lng: 17.719126 },
    { lat: 48.406363, lng: 17.719241 },
    { lat: 48.406392, lng: 17.719262 },
    { lat: 48.406864, lng: 17.719593 },
    { lat: 48.406882, lng: 17.719615 },
    { lat: 48.40699, lng: 17.719747 },
    { lat: 48.407067, lng: 17.719772 },
    { lat: 48.407125, lng: 17.71978 },
    { lat: 48.407217, lng: 17.719791 },
    { lat: 48.407399, lng: 17.719894 },
    { lat: 48.407527, lng: 17.720167 },
    { lat: 48.407503, lng: 17.720458 },
    { lat: 48.407551, lng: 17.720696 },
    { lat: 48.407584, lng: 17.720708 },
    { lat: 48.407801, lng: 17.720565 },
    { lat: 48.40797, lng: 17.720814 },
    { lat: 48.408015, lng: 17.720828 },
    { lat: 48.408175, lng: 17.720877 },
    { lat: 48.408203, lng: 17.720868 },
    { lat: 48.408285, lng: 17.720842 },
    { lat: 48.408456, lng: 17.720668 },
    { lat: 48.408536, lng: 17.720587 },
    { lat: 48.408587, lng: 17.720573 },
    { lat: 48.408636, lng: 17.720622 },
    { lat: 48.408642, lng: 17.720645 },
    { lat: 48.408673, lng: 17.720768 },
    { lat: 48.408697, lng: 17.720867 },
    { lat: 48.408746, lng: 17.721059 },
    { lat: 48.408878, lng: 17.721077 },
    { lat: 48.408945, lng: 17.721056 },
    { lat: 48.409252, lng: 17.720732 },
    { lat: 48.409379, lng: 17.720597 },
    { lat: 48.409487, lng: 17.720483 },
    { lat: 48.409618, lng: 17.720474 },
    { lat: 48.40966, lng: 17.72053 },
    { lat: 48.409726, lng: 17.720617 },
    { lat: 48.409802, lng: 17.720717 },
    { lat: 48.409838, lng: 17.720799 },
    { lat: 48.409889, lng: 17.720916 },
    { lat: 48.40992, lng: 17.721232 },
    { lat: 48.409926, lng: 17.721445 },
    { lat: 48.40993, lng: 17.721561 },
    { lat: 48.409936, lng: 17.721848 },
    { lat: 48.409918, lng: 17.721874 },
    { lat: 48.409833, lng: 17.722001 },
    { lat: 48.409738, lng: 17.722113 },
    { lat: 48.409673, lng: 17.722234 },
    { lat: 48.409693, lng: 17.722417 },
    { lat: 48.409877, lng: 17.722526 },
    { lat: 48.40999, lng: 17.722911 },
    { lat: 48.410072, lng: 17.723086 },
    { lat: 48.4101935, lng: 17.723332 },
    { lat: 48.410315, lng: 17.723578 },
    { lat: 48.410398, lng: 17.723576 },
    { lat: 48.41048, lng: 17.723574 },
    { lat: 48.410589, lng: 17.723861 },
    { lat: 48.410735, lng: 17.72402 },
    { lat: 48.410769, lng: 17.724064 },
    { lat: 48.410865, lng: 17.724154 },
    { lat: 48.411014, lng: 17.724293 },
    { lat: 48.411218, lng: 17.724494 },
    { lat: 48.411407, lng: 17.724672 },
    { lat: 48.411521, lng: 17.724631 },
    { lat: 48.411576, lng: 17.724352 },
    { lat: 48.411608, lng: 17.724182 },
    { lat: 48.411708, lng: 17.723665 },
    { lat: 48.411788, lng: 17.723588 },
    { lat: 48.411914, lng: 17.723676 },
    { lat: 48.41194, lng: 17.72371 },
    { lat: 48.41198, lng: 17.723764 },
    { lat: 48.412033, lng: 17.72384 },
    { lat: 48.4121, lng: 17.723978 },
    { lat: 48.412163, lng: 17.724106 },
    { lat: 48.412241, lng: 17.724274 },
    { lat: 48.412445, lng: 17.724224 },
    { lat: 48.412646, lng: 17.724083 },
    { lat: 48.412737, lng: 17.723967 },
    { lat: 48.41274, lng: 17.723862 },
    { lat: 48.412741, lng: 17.723709 },
    { lat: 48.41278, lng: 17.723535 },
    { lat: 48.412816, lng: 17.723487 },
    { lat: 48.412863, lng: 17.723447 },
    { lat: 48.412941, lng: 17.723418 },
    { lat: 48.412999, lng: 17.723389 },
    { lat: 48.413127, lng: 17.723337 },
    { lat: 48.413176, lng: 17.723317 },
    { lat: 48.413306, lng: 17.723333 },
    { lat: 48.413404, lng: 17.723445 },
    { lat: 48.413423, lng: 17.72361 },
    { lat: 48.41344, lng: 17.723747 },
    { lat: 48.413444, lng: 17.723779 },
    { lat: 48.41347, lng: 17.723894 },
    { lat: 48.413519, lng: 17.72405 },
    { lat: 48.413609, lng: 17.724071 },
    { lat: 48.413621, lng: 17.724074 },
    { lat: 48.413624, lng: 17.724105 },
    { lat: 48.413649, lng: 17.72434 },
    { lat: 48.413766, lng: 17.724601 },
    { lat: 48.413884, lng: 17.724815 },
    { lat: 48.413934, lng: 17.725085 },
    { lat: 48.41401, lng: 17.725195 },
    { lat: 48.414102, lng: 17.725272 },
    { lat: 48.414192, lng: 17.725414 },
    { lat: 48.414238, lng: 17.725459 },
    { lat: 48.414348, lng: 17.725559 },
    { lat: 48.414508, lng: 17.725672 },
    { lat: 48.414625, lng: 17.725692 },
    { lat: 48.414703, lng: 17.725691 },
    { lat: 48.414747, lng: 17.725691 },
    { lat: 48.4148, lng: 17.72577 },
    { lat: 48.414835, lng: 17.725822 },
    { lat: 48.414848, lng: 17.725933 },
    { lat: 48.41486, lng: 17.726045 },
    { lat: 48.414975, lng: 17.726151 },
    { lat: 48.415056, lng: 17.72613 },
    { lat: 48.415086, lng: 17.726152 },
    { lat: 48.415116, lng: 17.726157 },
    { lat: 48.415185, lng: 17.726169 },
    { lat: 48.415251, lng: 17.726151 },
    { lat: 48.415339, lng: 17.726212 },
    { lat: 48.415499, lng: 17.726301 },
    { lat: 48.415628, lng: 17.726439 },
    { lat: 48.41573, lng: 17.726607 },
    { lat: 48.415759, lng: 17.726785 },
    { lat: 48.415809, lng: 17.726937 },
    { lat: 48.415838, lng: 17.727057 },
    { lat: 48.415909, lng: 17.727173 },
    { lat: 48.416237, lng: 17.727368 },
    { lat: 48.4162633, lng: 17.7274134 },
    { lat: 48.4163, lng: 17.727477 },
    { lat: 48.416357, lng: 17.727769 },
    { lat: 48.416424, lng: 17.728048 },
    { lat: 48.41662, lng: 17.728241 },
    { lat: 48.416685, lng: 17.72836 },
    { lat: 48.416747, lng: 17.728609 },
    { lat: 48.416765, lng: 17.728686 },
    { lat: 48.416849, lng: 17.72894 },
    { lat: 48.416951, lng: 17.729048 },
    { lat: 48.417028, lng: 17.729174 },
    { lat: 48.417042, lng: 17.729348 },
    { lat: 48.417147, lng: 17.729584 },
    { lat: 48.417246, lng: 17.729655 },
    { lat: 48.417464, lng: 17.729705 },
    { lat: 48.417513, lng: 17.729748 },
    { lat: 48.417622, lng: 17.729907 },
    { lat: 48.417771, lng: 17.730121 },
    { lat: 48.417924, lng: 17.730283 },
    { lat: 48.418148, lng: 17.730406 },
    { lat: 48.418236, lng: 17.730715 },
    { lat: 48.418385, lng: 17.731102 },
    { lat: 48.418547, lng: 17.731254 },
    { lat: 48.418707, lng: 17.731184 },
    { lat: 48.419297, lng: 17.730573 },
    { lat: 48.419363, lng: 17.730542 },
  ],
  Dechtice: [
    { lat: 48.575341, lng: 17.570507 },
    { lat: 48.5753367, lng: 17.5702819 },
    { lat: 48.5754826, lng: 17.5700559 },
    { lat: 48.5755482, lng: 17.5697341 },
    { lat: 48.5754084, lng: 17.5692182 },
    { lat: 48.5752297, lng: 17.5688584 },
    { lat: 48.5751349, lng: 17.568468 },
    { lat: 48.5749246, lng: 17.5682657 },
    { lat: 48.5746649, lng: 17.5676251 },
    { lat: 48.5745952, lng: 17.5673416 },
    { lat: 48.5745653, lng: 17.5668353 },
    { lat: 48.5743112, lng: 17.5661033 },
    { lat: 48.5740928, lng: 17.565808 },
    { lat: 48.573807, lng: 17.5656473 },
    { lat: 48.5736198, lng: 17.5652769 },
    { lat: 48.5732845, lng: 17.5649746 },
    { lat: 48.5730032, lng: 17.5648481 },
    { lat: 48.5728686, lng: 17.564718 },
    { lat: 48.5727584, lng: 17.5645285 },
    { lat: 48.5727389, lng: 17.5643291 },
    { lat: 48.5723531, lng: 17.5636211 },
    { lat: 48.572185, lng: 17.5631774 },
    { lat: 48.572123, lng: 17.562823 },
    { lat: 48.5723357, lng: 17.5621275 },
    { lat: 48.5723851, lng: 17.5619661 },
    { lat: 48.5723974, lng: 17.561789 },
    { lat: 48.572393, lng: 17.5617012 },
    { lat: 48.5722885, lng: 17.5618147 },
    { lat: 48.5722697, lng: 17.5614832 },
    { lat: 48.5721168, lng: 17.561083 },
    { lat: 48.5720376, lng: 17.5600446 },
    { lat: 48.5725217, lng: 17.5600788 },
    { lat: 48.5725951, lng: 17.5598429 },
    { lat: 48.5726942, lng: 17.5597341 },
    { lat: 48.5723935, lng: 17.5595859 },
    { lat: 48.5719742, lng: 17.5577242 },
    { lat: 48.5711336, lng: 17.5555612 },
    { lat: 48.5702861, lng: 17.5546634 },
    { lat: 48.5698446, lng: 17.5545743 },
    { lat: 48.5689311, lng: 17.5552334 },
    { lat: 48.5687453, lng: 17.5552412 },
    { lat: 48.568621, lng: 17.5549938 },
    { lat: 48.5684626, lng: 17.5544354 },
    { lat: 48.5684805, lng: 17.5543776 },
    { lat: 48.5683428, lng: 17.5539535 },
    { lat: 48.5682906, lng: 17.5535731 },
    { lat: 48.5677391, lng: 17.5522796 },
    { lat: 48.5676826, lng: 17.5521759 },
    { lat: 48.5679673, lng: 17.551251 },
    { lat: 48.5675626, lng: 17.5499098 },
    { lat: 48.5677505, lng: 17.5494353 },
    { lat: 48.5677165, lng: 17.5493999 },
    { lat: 48.567825, lng: 17.5490245 },
    { lat: 48.5678135, lng: 17.5487394 },
    { lat: 48.567707, lng: 17.5485782 },
    { lat: 48.5676976, lng: 17.548564 },
    { lat: 48.5686514, lng: 17.5461378 },
    { lat: 48.5644215, lng: 17.5404332 },
    { lat: 48.5636323, lng: 17.5358654 },
    { lat: 48.5636094, lng: 17.5358262 },
    { lat: 48.5635012, lng: 17.5358989 },
    { lat: 48.5628886, lng: 17.5366711 },
    { lat: 48.5627142, lng: 17.5370214 },
    { lat: 48.5623936, lng: 17.5379471 },
    { lat: 48.5596207, lng: 17.5414057 },
    { lat: 48.5590138, lng: 17.5418097 },
    { lat: 48.5584162, lng: 17.542093 },
    { lat: 48.5578705, lng: 17.5422166 },
    { lat: 48.5570114, lng: 17.5424487 },
    { lat: 48.5571007, lng: 17.5410791 },
    { lat: 48.5570171, lng: 17.5409175 },
    { lat: 48.5570027, lng: 17.5404262 },
    { lat: 48.5568777, lng: 17.5400557 },
    { lat: 48.5569502, lng: 17.5397408 },
    { lat: 48.5569368, lng: 17.5393506 },
    { lat: 48.556894, lng: 17.5392095 },
    { lat: 48.5569525, lng: 17.538871 },
    { lat: 48.5570071, lng: 17.5388403 },
    { lat: 48.5570798, lng: 17.5379774 },
    { lat: 48.5571193, lng: 17.5360742 },
    { lat: 48.556986, lng: 17.5360396 },
    { lat: 48.5568344, lng: 17.5358208 },
    { lat: 48.5567699, lng: 17.5354975 },
    { lat: 48.5568097, lng: 17.5353609 },
    { lat: 48.5567507, lng: 17.535229 },
    { lat: 48.5566913, lng: 17.5351659 },
    { lat: 48.5566101, lng: 17.5353113 },
    { lat: 48.5564711, lng: 17.5352907 },
    { lat: 48.5564146, lng: 17.5351498 },
    { lat: 48.5563803, lng: 17.5348037 },
    { lat: 48.5562448, lng: 17.5348767 },
    { lat: 48.556232, lng: 17.5347823 },
    { lat: 48.5561634, lng: 17.5347319 },
    { lat: 48.5560053, lng: 17.5347155 },
    { lat: 48.5558737, lng: 17.5343509 },
    { lat: 48.5559574, lng: 17.5342112 },
    { lat: 48.5560669, lng: 17.5342439 },
    { lat: 48.5560156, lng: 17.5340064 },
    { lat: 48.5556892, lng: 17.534113 },
    { lat: 48.5556325, lng: 17.5340371 },
    { lat: 48.5554457, lng: 17.5339942 },
    { lat: 48.5554737, lng: 17.5336532 },
    { lat: 48.5553346, lng: 17.5336138 },
    { lat: 48.5551122, lng: 17.5332078 },
    { lat: 48.5547089, lng: 17.5329387 },
    { lat: 48.554508, lng: 17.5329955 },
    { lat: 48.5541084, lng: 17.5333508 },
    { lat: 48.5539026, lng: 17.5334368 },
    { lat: 48.5535011, lng: 17.5343698 },
    { lat: 48.553485, lng: 17.5347193 },
    { lat: 48.5532377, lng: 17.5352704 },
    { lat: 48.5530723, lng: 17.5353527 },
    { lat: 48.5528594, lng: 17.5359117 },
    { lat: 48.552637, lng: 17.5362475 },
    { lat: 48.552555, lng: 17.5362016 },
    { lat: 48.5524811, lng: 17.5359994 },
    { lat: 48.5523049, lng: 17.5365862 },
    { lat: 48.5521701, lng: 17.5368106 },
    { lat: 48.5519315, lng: 17.5366451 },
    { lat: 48.5518726, lng: 17.5366603 },
    { lat: 48.5519122, lng: 17.5368036 },
    { lat: 48.5515961, lng: 17.5371433 },
    { lat: 48.5514126, lng: 17.5372047 },
    { lat: 48.5510812, lng: 17.5375543 },
    { lat: 48.5515616, lng: 17.5383587 },
    { lat: 48.5515196, lng: 17.5386403 },
    { lat: 48.5513978, lng: 17.5389648 },
    { lat: 48.5501788, lng: 17.5402914 },
    { lat: 48.54914, lng: 17.5417346 },
    { lat: 48.5490628, lng: 17.5417771 },
    { lat: 48.5490059, lng: 17.5422158 },
    { lat: 48.5482866, lng: 17.5432682 },
    { lat: 48.5480569, lng: 17.543724 },
    { lat: 48.5477442, lng: 17.5444905 },
    { lat: 48.547328, lng: 17.5459561 },
    { lat: 48.5466824, lng: 17.54872 },
    { lat: 48.5461795, lng: 17.5500137 },
    { lat: 48.5455909, lng: 17.5507403 },
    { lat: 48.5453888, lng: 17.5511693 },
    { lat: 48.5453342, lng: 17.5518047 },
    { lat: 48.5444599, lng: 17.5524576 },
    { lat: 48.542125, lng: 17.554034 },
    { lat: 48.5413726, lng: 17.5543482 },
    { lat: 48.5401821, lng: 17.5551113 },
    { lat: 48.5397613, lng: 17.5555805 },
    { lat: 48.5393993, lng: 17.5560859 },
    { lat: 48.5391749, lng: 17.5561623 },
    { lat: 48.5388987, lng: 17.5566168 },
    { lat: 48.5384339, lng: 17.5571216 },
    { lat: 48.5384145, lng: 17.557155 },
    { lat: 48.5352502, lng: 17.5597908 },
    { lat: 48.5329984, lng: 17.5610917 },
    { lat: 48.5316817, lng: 17.5622154 },
    { lat: 48.5303786, lng: 17.5636955 },
    { lat: 48.5290722, lng: 17.5647698 },
    { lat: 48.5278892, lng: 17.5659019 },
    { lat: 48.5270207, lng: 17.5678065 },
    { lat: 48.5262281, lng: 17.5699519 },
    { lat: 48.5255264, lng: 17.5711398 },
    { lat: 48.5250815, lng: 17.5720857 },
    { lat: 48.5240048, lng: 17.5741128 },
    { lat: 48.5235947, lng: 17.5747003 },
    { lat: 48.5229439, lng: 17.5754056 },
    { lat: 48.5225358, lng: 17.575883 },
    { lat: 48.5305123, lng: 17.5898555 },
    { lat: 48.5309615, lng: 17.5976736 },
    { lat: 48.5314935, lng: 17.6076302 },
    { lat: 48.532036, lng: 17.6072264 },
    { lat: 48.5326497, lng: 17.6071983 },
    { lat: 48.5329832, lng: 17.6069697 },
    { lat: 48.5330136, lng: 17.6070249 },
    { lat: 48.5415818, lng: 17.6090862 },
    { lat: 48.5419733, lng: 17.6108373 },
    { lat: 48.542017, lng: 17.610892 },
    { lat: 48.542049, lng: 17.611019 },
    { lat: 48.542313, lng: 17.61179 },
    { lat: 48.543234, lng: 17.614359 },
    { lat: 48.543257, lng: 17.614342 },
    { lat: 48.543272, lng: 17.614324 },
    { lat: 48.543845, lng: 17.613653 },
    { lat: 48.544325, lng: 17.613091 },
    { lat: 48.544562, lng: 17.612813 },
    { lat: 48.544804, lng: 17.612529 },
    { lat: 48.545051, lng: 17.61224 },
    { lat: 48.545282, lng: 17.61197 },
    { lat: 48.545517, lng: 17.611694 },
    { lat: 48.545764, lng: 17.611407 },
    { lat: 48.546241, lng: 17.610851 },
    { lat: 48.546482, lng: 17.61057 },
    { lat: 48.546722, lng: 17.610291 },
    { lat: 48.547194, lng: 17.609742 },
    { lat: 48.547435, lng: 17.60946 },
    { lat: 48.54767, lng: 17.609173 },
    { lat: 48.547897, lng: 17.608897 },
    { lat: 48.548137, lng: 17.608617 },
    { lat: 48.548165, lng: 17.608584 },
    { lat: 48.548277, lng: 17.608454 },
    { lat: 48.548559, lng: 17.60812 },
    { lat: 48.548781, lng: 17.607861 },
    { lat: 48.549011, lng: 17.607593 },
    { lat: 48.549694, lng: 17.606792 },
    { lat: 48.549924, lng: 17.606524 },
    { lat: 48.550148, lng: 17.606262 },
    { lat: 48.55016, lng: 17.606248 },
    { lat: 48.550602, lng: 17.605732 },
    { lat: 48.551065, lng: 17.605189 },
    { lat: 48.551508, lng: 17.604673 },
    { lat: 48.551732, lng: 17.604411 },
    { lat: 48.552192, lng: 17.603875 },
    { lat: 48.552652, lng: 17.603339 },
    { lat: 48.553214, lng: 17.602686 },
    { lat: 48.553277, lng: 17.602566 },
    { lat: 48.553673, lng: 17.601808 },
    { lat: 48.553691, lng: 17.601772 },
    { lat: 48.554208, lng: 17.600996 },
    { lat: 48.554545, lng: 17.600483 },
    { lat: 48.554946, lng: 17.599919 },
    { lat: 48.555335, lng: 17.599376 },
    { lat: 48.555631, lng: 17.598908 },
    { lat: 48.555803, lng: 17.598696 },
    { lat: 48.556057, lng: 17.598386 },
    { lat: 48.55638, lng: 17.598091 },
    { lat: 48.556424, lng: 17.598047 },
    { lat: 48.55685, lng: 17.597609 },
    { lat: 48.558188, lng: 17.597347 },
    { lat: 48.558691, lng: 17.597249 },
    { lat: 48.55957, lng: 17.596698 },
    { lat: 48.560284, lng: 17.595992 },
    { lat: 48.561134, lng: 17.595151 },
    { lat: 48.561713, lng: 17.594376 },
    { lat: 48.562178, lng: 17.593362 },
    { lat: 48.562424, lng: 17.592483 },
    { lat: 48.562438, lng: 17.592462 },
    { lat: 48.562458, lng: 17.592434 },
    { lat: 48.562546, lng: 17.592629 },
    { lat: 48.562612, lng: 17.5928 },
    { lat: 48.562723, lng: 17.59295 },
    { lat: 48.562781, lng: 17.593156 },
    { lat: 48.562905, lng: 17.59323 },
    { lat: 48.563012, lng: 17.5932 },
    { lat: 48.563371, lng: 17.593147 },
    { lat: 48.5640486, lng: 17.5929464 },
    { lat: 48.5649689, lng: 17.5923268 },
    { lat: 48.564999, lng: 17.59221 },
    { lat: 48.565915, lng: 17.591426 },
    { lat: 48.566101, lng: 17.591324 },
    { lat: 48.566426, lng: 17.591303 },
    { lat: 48.566596, lng: 17.591131 },
    { lat: 48.567104, lng: 17.591134 },
    { lat: 48.567233, lng: 17.590975 },
    { lat: 48.567201, lng: 17.590849 },
    { lat: 48.56708, lng: 17.59037 },
    { lat: 48.5672793, lng: 17.5902102 },
    { lat: 48.5681, lng: 17.589552 },
    { lat: 48.56795, lng: 17.589043 },
    { lat: 48.567812, lng: 17.588574 },
    { lat: 48.568204, lng: 17.588205 },
    { lat: 48.5691897, lng: 17.5881938 },
    { lat: 48.5697825, lng: 17.5877701 },
    { lat: 48.5692181, lng: 17.5871719 },
    { lat: 48.5686047, lng: 17.5859295 },
    { lat: 48.5683835, lng: 17.5860971 },
    { lat: 48.569205, lng: 17.585152 },
    { lat: 48.569335, lng: 17.584944 },
    { lat: 48.569317, lng: 17.584901 },
    { lat: 48.56868, lng: 17.583902 },
    { lat: 48.569113, lng: 17.583208 },
    { lat: 48.569221, lng: 17.583036 },
    { lat: 48.569294, lng: 17.582915 },
    { lat: 48.569454, lng: 17.582649 },
    { lat: 48.569612, lng: 17.582431 },
    { lat: 48.569728, lng: 17.582315 },
    { lat: 48.56982, lng: 17.582261 },
    { lat: 48.569859, lng: 17.582212 },
    { lat: 48.569929, lng: 17.582124 },
    { lat: 48.569819, lng: 17.581901 },
    { lat: 48.570085, lng: 17.581617 },
    { lat: 48.570307, lng: 17.581315 },
    { lat: 48.570461, lng: 17.581182 },
    { lat: 48.570548, lng: 17.581068 },
    { lat: 48.570644, lng: 17.580841 },
    { lat: 48.570903, lng: 17.580461 },
    { lat: 48.571154, lng: 17.58017 },
    { lat: 48.571244, lng: 17.580031 },
    { lat: 48.571349, lng: 17.579867 },
    { lat: 48.571462, lng: 17.579739 },
    { lat: 48.571576, lng: 17.579611 },
    { lat: 48.57164, lng: 17.579538 },
    { lat: 48.571679, lng: 17.579492 },
    { lat: 48.571711, lng: 17.579456 },
    { lat: 48.571757, lng: 17.579404 },
    { lat: 48.571807, lng: 17.579347 },
    { lat: 48.571923, lng: 17.579505 },
    { lat: 48.572096, lng: 17.579861 },
    { lat: 48.572384, lng: 17.580408 },
    { lat: 48.572573, lng: 17.580834 },
    { lat: 48.57272, lng: 17.581104 },
    { lat: 48.572776, lng: 17.581108 },
    { lat: 48.572812, lng: 17.581108 },
    { lat: 48.573, lng: 17.580855 },
    { lat: 48.573374, lng: 17.580439 },
    { lat: 48.573869, lng: 17.579496 },
    { lat: 48.573881, lng: 17.579507 },
    { lat: 48.573921, lng: 17.579493 },
    { lat: 48.574393, lng: 17.579322 },
    { lat: 48.574456, lng: 17.579182 },
    { lat: 48.574461, lng: 17.579169 },
    { lat: 48.574489, lng: 17.579104 },
    { lat: 48.574539, lng: 17.578924 },
    { lat: 48.574527, lng: 17.57871 },
    { lat: 48.574435, lng: 17.578405 },
    { lat: 48.574469, lng: 17.578303 },
    { lat: 48.574549, lng: 17.57806 },
    { lat: 48.5746, lng: 17.577958 },
    { lat: 48.574629, lng: 17.577896 },
    { lat: 48.574652, lng: 17.577733 },
    { lat: 48.574658, lng: 17.577694 },
    { lat: 48.574574, lng: 17.577615 },
    { lat: 48.574538, lng: 17.577577 },
    { lat: 48.574557, lng: 17.57741 },
    { lat: 48.574631, lng: 17.57716 },
    { lat: 48.574715, lng: 17.577116 },
    { lat: 48.574777, lng: 17.576647 },
    { lat: 48.574817, lng: 17.576585 },
    { lat: 48.574844, lng: 17.576431 },
    { lat: 48.574956, lng: 17.576281 },
    { lat: 48.574988, lng: 17.576181 },
    { lat: 48.575087, lng: 17.575946 },
    { lat: 48.575129, lng: 17.57583 },
    { lat: 48.575146, lng: 17.575785 },
    { lat: 48.575156, lng: 17.575756 },
    { lat: 48.575127, lng: 17.57564 },
    { lat: 48.575141, lng: 17.57559 },
    { lat: 48.575155, lng: 17.575537 },
    { lat: 48.575255, lng: 17.575411 },
    { lat: 48.57538, lng: 17.575114 },
    { lat: 48.575465, lng: 17.575086 },
    { lat: 48.575601, lng: 17.574985 },
    { lat: 48.575655, lng: 17.574844 },
    { lat: 48.575707, lng: 17.57448 },
    { lat: 48.57575, lng: 17.574339 },
    { lat: 48.575801, lng: 17.574046 },
    { lat: 48.575828, lng: 17.573718 },
    { lat: 48.575855, lng: 17.573666 },
    { lat: 48.575924, lng: 17.573662 },
    { lat: 48.576008, lng: 17.573286 },
    { lat: 48.576009, lng: 17.573286 },
    { lat: 48.575986, lng: 17.573118 },
    { lat: 48.576018, lng: 17.572963 },
    { lat: 48.57603, lng: 17.572749 },
    { lat: 48.576034, lng: 17.572703 },
    { lat: 48.576079, lng: 17.572592 },
    { lat: 48.576053, lng: 17.572352 },
    { lat: 48.576049, lng: 17.572128 },
    { lat: 48.576048, lng: 17.5721 },
    { lat: 48.575974, lng: 17.571945 },
    { lat: 48.575895, lng: 17.571759 },
    { lat: 48.57586, lng: 17.571653 },
    { lat: 48.575841, lng: 17.571614 },
    { lat: 48.575669, lng: 17.571288 },
    { lat: 48.575574, lng: 17.571106 },
    { lat: 48.575529, lng: 17.57093 },
    { lat: 48.575477, lng: 17.570799 },
    { lat: 48.575341, lng: 17.570507 },
  ],
  Voderady: [
    { lat: 48.3093136, lng: 17.5899643 },
    { lat: 48.309398, lng: 17.5899268 },
    { lat: 48.3097275, lng: 17.5897791 },
    { lat: 48.3078511, lng: 17.583211 },
    { lat: 48.307997, lng: 17.5830381 },
    { lat: 48.3080046, lng: 17.5829455 },
    { lat: 48.2959377, lng: 17.5610371 },
    { lat: 48.2955037, lng: 17.5602671 },
    { lat: 48.2953987, lng: 17.5601789 },
    { lat: 48.2944832, lng: 17.5584473 },
    { lat: 48.2942225, lng: 17.5571189 },
    { lat: 48.2936415, lng: 17.5559767 },
    { lat: 48.2931081, lng: 17.5555452 },
    { lat: 48.2928334, lng: 17.5552749 },
    { lat: 48.2910918, lng: 17.5529477 },
    { lat: 48.2874828, lng: 17.5480254 },
    { lat: 48.2866656, lng: 17.5470595 },
    { lat: 48.2849273, lng: 17.545622 },
    { lat: 48.284344, lng: 17.5452209 },
    { lat: 48.283819, lng: 17.5434422 },
    { lat: 48.2836179, lng: 17.5427607 },
    { lat: 48.2826523, lng: 17.5432043 },
    { lat: 48.2817703, lng: 17.5420184 },
    { lat: 48.2796092, lng: 17.5398115 },
    { lat: 48.2775797, lng: 17.5375661 },
    { lat: 48.2753887, lng: 17.5349476 },
    { lat: 48.2726241, lng: 17.5319402 },
    { lat: 48.2697216, lng: 17.52837 },
    { lat: 48.267822, lng: 17.530573 },
    { lat: 48.265772, lng: 17.533412 },
    { lat: 48.264754, lng: 17.534555 },
    { lat: 48.2633249, lng: 17.5363241 },
    { lat: 48.259653, lng: 17.53971 },
    { lat: 48.255641, lng: 17.5443294 },
    { lat: 48.25489, lng: 17.545194 },
    { lat: 48.2548797, lng: 17.5452047 },
    { lat: 48.2549041, lng: 17.5452375 },
    { lat: 48.2553962, lng: 17.5458694 },
    { lat: 48.2580333, lng: 17.5499448 },
    { lat: 48.2591925, lng: 17.5514375 },
    { lat: 48.2602056, lng: 17.5528941 },
    { lat: 48.2606097, lng: 17.5536532 },
    { lat: 48.262619, lng: 17.5567381 },
    { lat: 48.2635426, lng: 17.5580497 },
    { lat: 48.2642699, lng: 17.5592915 },
    { lat: 48.2673634, lng: 17.5636944 },
    { lat: 48.2682019, lng: 17.5647691 },
    { lat: 48.2675527, lng: 17.5654824 },
    { lat: 48.2684484, lng: 17.566672 },
    { lat: 48.2684794, lng: 17.5674283 },
    { lat: 48.2683031, lng: 17.5701961 },
    { lat: 48.2697806, lng: 17.5727033 },
    { lat: 48.2700374, lng: 17.5734745 },
    { lat: 48.2703425, lng: 17.5748452 },
    { lat: 48.2710282, lng: 17.5746737 },
    { lat: 48.2711358, lng: 17.5748725 },
    { lat: 48.2715083, lng: 17.5746205 },
    { lat: 48.2718624, lng: 17.5745059 },
    { lat: 48.2733417, lng: 17.5755728 },
    { lat: 48.2740478, lng: 17.576626 },
    { lat: 48.2757119, lng: 17.579553 },
    { lat: 48.2769569, lng: 17.5814841 },
    { lat: 48.2767444, lng: 17.5818909 },
    { lat: 48.2760792, lng: 17.582885 },
    { lat: 48.2760959, lng: 17.582917 },
    { lat: 48.2772553, lng: 17.5851188 },
    { lat: 48.2781399, lng: 17.587116 },
    { lat: 48.2794803, lng: 17.5903569 },
    { lat: 48.2799577, lng: 17.5917616 },
    { lat: 48.2816271, lng: 17.5920002 },
    { lat: 48.2828022, lng: 17.5922425 },
    { lat: 48.2838487, lng: 17.5923609 },
    { lat: 48.2844943, lng: 17.5925339 },
    { lat: 48.2849321, lng: 17.5927237 },
    { lat: 48.2861333, lng: 17.5934353 },
    { lat: 48.28722, lng: 17.5938838 },
    { lat: 48.288042, lng: 17.5943466 },
    { lat: 48.2885443, lng: 17.5945212 },
    { lat: 48.2908953, lng: 17.5949669 },
    { lat: 48.2942502, lng: 17.5944127 },
    { lat: 48.2975226, lng: 17.5935027 },
    { lat: 48.3007183, lng: 17.5922188 },
    { lat: 48.3029084, lng: 17.5917081 },
    { lat: 48.3073891, lng: 17.5901738 },
    { lat: 48.3084744, lng: 17.5899448 },
    { lat: 48.3093136, lng: 17.5899643 },
  ],
  Trnava: [
    { lat: 48.4251979, lng: 17.659514 },
    { lat: 48.4256564, lng: 17.6590002 },
    { lat: 48.4262636, lng: 17.6583199 },
    { lat: 48.4262262, lng: 17.6576849 },
    { lat: 48.4262155, lng: 17.6539113 },
    { lat: 48.4261037, lng: 17.6524521 },
    { lat: 48.4259934, lng: 17.6515938 },
    { lat: 48.4259983, lng: 17.6510037 },
    { lat: 48.4255641, lng: 17.6496783 },
    { lat: 48.4251972, lng: 17.6487981 },
    { lat: 48.4245751, lng: 17.6475221 },
    { lat: 48.4231751, lng: 17.6448785 },
    { lat: 48.4222618, lng: 17.6429756 },
    { lat: 48.4221891, lng: 17.6427698 },
    { lat: 48.4209853, lng: 17.6396553 },
    { lat: 48.4205656, lng: 17.6389591 },
    { lat: 48.4205236, lng: 17.6382907 },
    { lat: 48.4206951, lng: 17.638127 },
    { lat: 48.4210044, lng: 17.6380529 },
    { lat: 48.4211839, lng: 17.6377314 },
    { lat: 48.4214326, lng: 17.6375162 },
    { lat: 48.4216401, lng: 17.6375842 },
    { lat: 48.421832, lng: 17.6375122 },
    { lat: 48.4220647, lng: 17.6375862 },
    { lat: 48.422298, lng: 17.6374351 },
    { lat: 48.4223743, lng: 17.6371207 },
    { lat: 48.4223676, lng: 17.636656 },
    { lat: 48.4228564, lng: 17.6364373 },
    { lat: 48.4230792, lng: 17.6361935 },
    { lat: 48.422794, lng: 17.6358387 },
    { lat: 48.4219464, lng: 17.6343922 },
    { lat: 48.4213269, lng: 17.6334464 },
    { lat: 48.4182677, lng: 17.628988 },
    { lat: 48.4146557, lng: 17.623915 },
    { lat: 48.4122573, lng: 17.6208481 },
    { lat: 48.413708, lng: 17.6185634 },
    { lat: 48.4146539, lng: 17.6173594 },
    { lat: 48.4183383, lng: 17.6134827 },
    { lat: 48.4206815, lng: 17.6111906 },
    { lat: 48.4210854, lng: 17.6106642 },
    { lat: 48.4218075, lng: 17.6085806 },
    { lat: 48.4221359, lng: 17.6072718 },
    { lat: 48.4225897, lng: 17.6034178 },
    { lat: 48.4224332, lng: 17.602765 },
    { lat: 48.4229622, lng: 17.6010201 },
    { lat: 48.4232833, lng: 17.5990411 },
    { lat: 48.4234894, lng: 17.5983 },
    { lat: 48.4244662, lng: 17.5959763 },
    { lat: 48.4246526, lng: 17.5953162 },
    { lat: 48.4247759, lng: 17.5950846 },
    { lat: 48.425738, lng: 17.5941211 },
    { lat: 48.4278428, lng: 17.5922066 },
    { lat: 48.4270516, lng: 17.5907117 },
    { lat: 48.4269907, lng: 17.5905337 },
    { lat: 48.4270583, lng: 17.5904989 },
    { lat: 48.4267971, lng: 17.5897258 },
    { lat: 48.4252497, lng: 17.5864312 },
    { lat: 48.4243523, lng: 17.5841901 },
    { lat: 48.4227532, lng: 17.5806732 },
    { lat: 48.4223666, lng: 17.5798659 },
    { lat: 48.4219826, lng: 17.5790267 },
    { lat: 48.4215906, lng: 17.5781843 },
    { lat: 48.4206122, lng: 17.5766981 },
    { lat: 48.4196684, lng: 17.5755426 },
    { lat: 48.4187519, lng: 17.5742123 },
    { lat: 48.4178254, lng: 17.5727444 },
    { lat: 48.4158357, lng: 17.5692919 },
    { lat: 48.4134576, lng: 17.5657161 },
    { lat: 48.4129767, lng: 17.565 },
    { lat: 48.4128701, lng: 17.5648412 },
    { lat: 48.4076671, lng: 17.557682 },
    { lat: 48.4064255, lng: 17.5549758 },
    { lat: 48.406305, lng: 17.5547336 },
    { lat: 48.405467, lng: 17.5529186 },
    { lat: 48.4053365, lng: 17.5523921 },
    { lat: 48.4053177, lng: 17.5520785 },
    { lat: 48.4051892, lng: 17.5520816 },
    { lat: 48.4035936, lng: 17.5496343 },
    { lat: 48.4012614, lng: 17.5458333 },
    { lat: 48.3977834, lng: 17.5398078 },
    { lat: 48.3967376, lng: 17.5382282 },
    { lat: 48.3987668, lng: 17.5336205 },
    { lat: 48.3995432, lng: 17.5313443 },
    { lat: 48.3990665, lng: 17.5305646 },
    { lat: 48.3957216, lng: 17.525838 },
    { lat: 48.394109, lng: 17.5231669 },
    { lat: 48.3930471, lng: 17.5212546 },
    { lat: 48.3926468, lng: 17.5203966 },
    { lat: 48.3922426, lng: 17.5196748 },
    { lat: 48.3921116, lng: 17.5196027 },
    { lat: 48.3913937, lng: 17.5177376 },
    { lat: 48.3906657, lng: 17.5162595 },
    { lat: 48.3908583, lng: 17.516181 },
    { lat: 48.3908673, lng: 17.5159846 },
    { lat: 48.3903307, lng: 17.5147284 },
    { lat: 48.3893036, lng: 17.5116633 },
    { lat: 48.3880929, lng: 17.5079064 },
    { lat: 48.386987, lng: 17.5042315 },
    { lat: 48.3856607, lng: 17.5016136 },
    { lat: 48.3835852, lng: 17.4966835 },
    { lat: 48.3816523, lng: 17.4922707 },
    { lat: 48.3799058, lng: 17.4893944 },
    { lat: 48.3783365, lng: 17.4874657 },
    { lat: 48.3783167, lng: 17.4874416 },
    { lat: 48.3783022, lng: 17.4874444 },
    { lat: 48.3768861, lng: 17.4897878 },
    { lat: 48.3762165, lng: 17.4911808 },
    { lat: 48.3756309, lng: 17.4920495 },
    { lat: 48.3751447, lng: 17.4926047 },
    { lat: 48.3755414, lng: 17.4932446 },
    { lat: 48.3719446, lng: 17.4983913 },
    { lat: 48.3720647, lng: 17.4989061 },
    { lat: 48.3723329, lng: 17.500676 },
    { lat: 48.3714101, lng: 17.5012042 },
    { lat: 48.3715683, lng: 17.5026348 },
    { lat: 48.3703549, lng: 17.503579 },
    { lat: 48.3695445, lng: 17.5015762 },
    { lat: 48.3694581, lng: 17.5017209 },
    { lat: 48.3675647, lng: 17.5048908 },
    { lat: 48.3665508, lng: 17.5064453 },
    { lat: 48.3661913, lng: 17.5071627 },
    { lat: 48.3656747, lng: 17.5084607 },
    { lat: 48.3650784, lng: 17.5103509 },
    { lat: 48.3633997, lng: 17.5137145 },
    { lat: 48.3632189, lng: 17.5142388 },
    { lat: 48.3640899, lng: 17.5155328 },
    { lat: 48.3644047, lng: 17.5161814 },
    { lat: 48.3645948, lng: 17.5168075 },
    { lat: 48.3647434, lng: 17.5170809 },
    { lat: 48.3656445, lng: 17.5184043 },
    { lat: 48.3671621, lng: 17.5210324 },
    { lat: 48.3677251, lng: 17.5218727 },
    { lat: 48.3688106, lng: 17.523184 },
    { lat: 48.3693261, lng: 17.5237217 },
    { lat: 48.3698768, lng: 17.5240705 },
    { lat: 48.370275, lng: 17.5245218 },
    { lat: 48.3708567, lng: 17.5255338 },
    { lat: 48.3719127, lng: 17.5276203 },
    { lat: 48.3750672, lng: 17.5241924 },
    { lat: 48.3762921, lng: 17.5286251 },
    { lat: 48.3759457, lng: 17.5298343 },
    { lat: 48.3747111, lng: 17.5330496 },
    { lat: 48.3743259, lng: 17.5334406 },
    { lat: 48.3737449, lng: 17.5345641 },
    { lat: 48.3738557, lng: 17.5354898 },
    { lat: 48.3736375, lng: 17.5370965 },
    { lat: 48.3736409, lng: 17.537681 },
    { lat: 48.3733416, lng: 17.5402139 },
    { lat: 48.3729747, lng: 17.540948 },
    { lat: 48.3726841, lng: 17.5417159 },
    { lat: 48.3724503, lng: 17.5420691 },
    { lat: 48.372276, lng: 17.5421712 },
    { lat: 48.3721843, lng: 17.5423098 },
    { lat: 48.3719, lng: 17.5430744 },
    { lat: 48.3688995, lng: 17.5422077 },
    { lat: 48.3672932, lng: 17.5404814 },
    { lat: 48.3665328, lng: 17.5394295 },
    { lat: 48.3660677, lng: 17.5383702 },
    { lat: 48.365602, lng: 17.5378695 },
    { lat: 48.3643577, lng: 17.5361177 },
    { lat: 48.3619426, lng: 17.5322508 },
    { lat: 48.3618833, lng: 17.5319408 },
    { lat: 48.3616248, lng: 17.5315358 },
    { lat: 48.3604509, lng: 17.5297953 },
    { lat: 48.359609, lng: 17.5285472 },
    { lat: 48.3588281, lng: 17.5276368 },
    { lat: 48.3579835, lng: 17.5263993 },
    { lat: 48.3564883, lng: 17.5239935 },
    { lat: 48.3545487, lng: 17.5210727 },
    { lat: 48.3540756, lng: 17.5204903 },
    { lat: 48.3540663, lng: 17.5205221 },
    { lat: 48.3538654, lng: 17.521248 },
    { lat: 48.3534749, lng: 17.521922 },
    { lat: 48.3532222, lng: 17.5222446 },
    { lat: 48.3529413, lng: 17.5224775 },
    { lat: 48.3528179, lng: 17.5224391 },
    { lat: 48.3527255, lng: 17.5225671 },
    { lat: 48.3526826, lng: 17.522656 },
    { lat: 48.3525368, lng: 17.5227075 },
    { lat: 48.3522145, lng: 17.5230361 },
    { lat: 48.3519356, lng: 17.5235499 },
    { lat: 48.3516418, lng: 17.5238996 },
    { lat: 48.3514889, lng: 17.5241766 },
    { lat: 48.3512483, lng: 17.5244036 },
    { lat: 48.3510415, lng: 17.5248974 },
    { lat: 48.3509244, lng: 17.5249899 },
    { lat: 48.3507819, lng: 17.5253043 },
    { lat: 48.3504343, lng: 17.525698 },
    { lat: 48.3501709, lng: 17.5261715 },
    { lat: 48.35002, lng: 17.5263363 },
    { lat: 48.3495072, lng: 17.5265474 },
    { lat: 48.3484136, lng: 17.5265428 },
    { lat: 48.3480377, lng: 17.5269348 },
    { lat: 48.3477902, lng: 17.5272828 },
    { lat: 48.3476055, lng: 17.5274333 },
    { lat: 48.3464403, lng: 17.5280758 },
    { lat: 48.3458193, lng: 17.5286226 },
    { lat: 48.3470695, lng: 17.530376 },
    { lat: 48.3476454, lng: 17.531364 },
    { lat: 48.3490329, lng: 17.5340481 },
    { lat: 48.3508719, lng: 17.5373649 },
    { lat: 48.3522858, lng: 17.5396323 },
    { lat: 48.3551143, lng: 17.5443736 },
    { lat: 48.3580897, lng: 17.5504512 },
    { lat: 48.3582653, lng: 17.5511159 },
    { lat: 48.3593034, lng: 17.5536214 },
    { lat: 48.3596136, lng: 17.5535102 },
    { lat: 48.3597418, lng: 17.5533079 },
    { lat: 48.3597392, lng: 17.5530185 },
    { lat: 48.3598395, lng: 17.5527315 },
    { lat: 48.3598872, lng: 17.5519008 },
    { lat: 48.3599972, lng: 17.5517462 },
    { lat: 48.3602561, lng: 17.5517361 },
    { lat: 48.360374, lng: 17.5516183 },
    { lat: 48.3602673, lng: 17.5511493 },
    { lat: 48.3602991, lng: 17.5508837 },
    { lat: 48.3606255, lng: 17.5511157 },
    { lat: 48.3607354, lng: 17.5510499 },
    { lat: 48.360676, lng: 17.5505261 },
    { lat: 48.3607901, lng: 17.5504369 },
    { lat: 48.3609392, lng: 17.5505072 },
    { lat: 48.3609462, lng: 17.5505662 },
    { lat: 48.3611263, lng: 17.5506991 },
    { lat: 48.3620008, lng: 17.5518759 },
    { lat: 48.3625648, lng: 17.5525238 },
    { lat: 48.3637752, lng: 17.5541873 },
    { lat: 48.3634689, lng: 17.5543601 },
    { lat: 48.3633913, lng: 17.5547924 },
    { lat: 48.3632073, lng: 17.5547566 },
    { lat: 48.3631951, lng: 17.5545119 },
    { lat: 48.3631589, lng: 17.5544933 },
    { lat: 48.3630208, lng: 17.5547184 },
    { lat: 48.3630065, lng: 17.5550446 },
    { lat: 48.3632624, lng: 17.5551802 },
    { lat: 48.3632269, lng: 17.5553693 },
    { lat: 48.3631005, lng: 17.5554772 },
    { lat: 48.363227, lng: 17.5556359 },
    { lat: 48.3631454, lng: 17.5558071 },
    { lat: 48.3630785, lng: 17.5558412 },
    { lat: 48.362921, lng: 17.5557426 },
    { lat: 48.3627743, lng: 17.5559593 },
    { lat: 48.3629479, lng: 17.5561844 },
    { lat: 48.3628037, lng: 17.5563322 },
    { lat: 48.3628256, lng: 17.5565162 },
    { lat: 48.362476, lng: 17.5564704 },
    { lat: 48.3623428, lng: 17.5570733 },
    { lat: 48.3624729, lng: 17.557381 },
    { lat: 48.3624548, lng: 17.5576286 },
    { lat: 48.362204, lng: 17.5578041 },
    { lat: 48.362033, lng: 17.5576542 },
    { lat: 48.361837, lng: 17.5579838 },
    { lat: 48.3615319, lng: 17.5580683 },
    { lat: 48.361354, lng: 17.558575 },
    { lat: 48.3610861, lng: 17.5588725 },
    { lat: 48.3609883, lng: 17.5589128 },
    { lat: 48.3605928, lng: 17.5587848 },
    { lat: 48.3601363, lng: 17.5592084 },
    { lat: 48.3599659, lng: 17.5592982 },
    { lat: 48.359135, lng: 17.5603459 },
    { lat: 48.3586234, lng: 17.5609909 },
    { lat: 48.3581717, lng: 17.5613596 },
    { lat: 48.3580695, lng: 17.561443 },
    { lat: 48.3568695, lng: 17.5627652 },
    { lat: 48.3565793, lng: 17.5621602 },
    { lat: 48.3559418, lng: 17.5628269 },
    { lat: 48.3560767, lng: 17.563103 },
    { lat: 48.3554854, lng: 17.5637994 },
    { lat: 48.3551219, lng: 17.5641419 },
    { lat: 48.3539993, lng: 17.5654929 },
    { lat: 48.3539018, lng: 17.5656102 },
    { lat: 48.3540396, lng: 17.5658937 },
    { lat: 48.3518004, lng: 17.5682944 },
    { lat: 48.3512968, lng: 17.5686902 },
    { lat: 48.3507172, lng: 17.569378 },
    { lat: 48.3493354, lng: 17.5715348 },
    { lat: 48.349562, lng: 17.5718876 },
    { lat: 48.3494817, lng: 17.5720982 },
    { lat: 48.349295, lng: 17.5722554 },
    { lat: 48.3492106, lng: 17.5723265 },
    { lat: 48.3482736, lng: 17.5733653 },
    { lat: 48.3467122, lng: 17.5753434 },
    { lat: 48.3466984, lng: 17.5754664 },
    { lat: 48.345844, lng: 17.5774475 },
    { lat: 48.345287, lng: 17.5781759 },
    { lat: 48.344647, lng: 17.57864 },
    { lat: 48.3443592, lng: 17.5790013 },
    { lat: 48.3439276, lng: 17.5799421 },
    { lat: 48.3431202, lng: 17.5810278 },
    { lat: 48.3429238, lng: 17.5813863 },
    { lat: 48.3427873, lng: 17.581797 },
    { lat: 48.3423696, lng: 17.5835962 },
    { lat: 48.3417935, lng: 17.5849867 },
    { lat: 48.3411969, lng: 17.5860493 },
    { lat: 48.3405733, lng: 17.5870241 },
    { lat: 48.3399763, lng: 17.5882085 },
    { lat: 48.3393357, lng: 17.5892162 },
    { lat: 48.3391446, lng: 17.5896768 },
    { lat: 48.3386322, lng: 17.5905917 },
    { lat: 48.3383784, lng: 17.5912199 },
    { lat: 48.3381527, lng: 17.5919718 },
    { lat: 48.337495, lng: 17.5931409 },
    { lat: 48.3373658, lng: 17.5932942 },
    { lat: 48.3376587, lng: 17.5937824 },
    { lat: 48.3394032, lng: 17.5955268 },
    { lat: 48.3418671, lng: 17.5981689 },
    { lat: 48.3419836, lng: 17.5982964 },
    { lat: 48.3414097, lng: 17.5995072 },
    { lat: 48.3409879, lng: 17.6001564 },
    { lat: 48.3378331, lng: 17.603786 },
    { lat: 48.3371722, lng: 17.6047627 },
    { lat: 48.3367324, lng: 17.6052738 },
    { lat: 48.3356222, lng: 17.6063083 },
    { lat: 48.3353817, lng: 17.6064872 },
    { lat: 48.3348859, lng: 17.6066273 },
    { lat: 48.3330854, lng: 17.6074436 },
    { lat: 48.3311932, lng: 17.6080741 },
    { lat: 48.3311531, lng: 17.6079052 },
    { lat: 48.3306549, lng: 17.6070424 },
    { lat: 48.3278987, lng: 17.6099783 },
    { lat: 48.3278682, lng: 17.6101019 },
    { lat: 48.3270447, lng: 17.610975 },
    { lat: 48.3292111, lng: 17.6153275 },
    { lat: 48.3288676, lng: 17.6157191 },
    { lat: 48.3272141, lng: 17.6123497 },
    { lat: 48.3262518, lng: 17.6105973 },
    { lat: 48.3261015, lng: 17.6104164 },
    { lat: 48.3254551, lng: 17.6108374 },
    { lat: 48.3252846, lng: 17.6108347 },
    { lat: 48.3251956, lng: 17.6106618 },
    { lat: 48.3244892, lng: 17.6082191 },
    { lat: 48.3185992, lng: 17.6130454 },
    { lat: 48.3239429, lng: 17.6150169 },
    { lat: 48.3242499, lng: 17.6146398 },
    { lat: 48.3249727, lng: 17.6127645 },
    { lat: 48.3250527, lng: 17.6127794 },
    { lat: 48.3250656, lng: 17.6125895 },
    { lat: 48.3265759, lng: 17.6128136 },
    { lat: 48.3278251, lng: 17.615393 },
    { lat: 48.3277915, lng: 17.6160049 },
    { lat: 48.328623, lng: 17.6156363 },
    { lat: 48.3288817, lng: 17.6160499 },
    { lat: 48.3290539, lng: 17.6161653 },
    { lat: 48.3314117, lng: 17.6205698 },
    { lat: 48.3276499, lng: 17.6239996 },
    { lat: 48.3268907, lng: 17.6246923 },
    { lat: 48.3270631, lng: 17.6249194 },
    { lat: 48.3274161, lng: 17.6252342 },
    { lat: 48.3277701, lng: 17.6254235 },
    { lat: 48.3282411, lng: 17.625477 },
    { lat: 48.3285864, lng: 17.6254213 },
    { lat: 48.328599, lng: 17.6254798 },
    { lat: 48.3291663, lng: 17.6256652 },
    { lat: 48.3300784, lng: 17.6265568 },
    { lat: 48.3322297, lng: 17.6298395 },
    { lat: 48.3325466, lng: 17.6304068 },
    { lat: 48.3332006, lng: 17.6320228 },
    { lat: 48.3332319, lng: 17.6321245 },
    { lat: 48.3332771, lng: 17.6322686 },
    { lat: 48.333778, lng: 17.6321997 },
    { lat: 48.3348925, lng: 17.6320465 },
    { lat: 48.3353818, lng: 17.6320876 },
    { lat: 48.3369909, lng: 17.6331823 },
    { lat: 48.3404563, lng: 17.6357484 },
    { lat: 48.3436416, lng: 17.6380066 },
    { lat: 48.3445454, lng: 17.6396311 },
    { lat: 48.3450946, lng: 17.6408194 },
    { lat: 48.3456216, lng: 17.6421086 },
    { lat: 48.3457118, lng: 17.6421239 },
    { lat: 48.3464304, lng: 17.6415927 },
    { lat: 48.3476307, lng: 17.6408926 },
    { lat: 48.3512532, lng: 17.638038 },
    { lat: 48.3521102, lng: 17.6371417 },
    { lat: 48.3532981, lng: 17.6361009 },
    { lat: 48.3553281, lng: 17.6339055 },
    { lat: 48.357067, lng: 17.6325596 },
    { lat: 48.3579846, lng: 17.6317291 },
    { lat: 48.3603715, lng: 17.6299294 },
    { lat: 48.3614108, lng: 17.6287357 },
    { lat: 48.3619613, lng: 17.6279368 },
    { lat: 48.3620169, lng: 17.6278544 },
    { lat: 48.3630611, lng: 17.6262728 },
    { lat: 48.364745, lng: 17.6246427 },
    { lat: 48.3673209, lng: 17.6225333 },
    { lat: 48.3676462, lng: 17.6224076 },
    { lat: 48.3695121, lng: 17.621392 },
    { lat: 48.3709222, lng: 17.6218172 },
    { lat: 48.3727521, lng: 17.6221346 },
    { lat: 48.3736753, lng: 17.6222159 },
    { lat: 48.3748987, lng: 17.6222244 },
    { lat: 48.3761007, lng: 17.6220701 },
    { lat: 48.3783484, lng: 17.6254175 },
    { lat: 48.3812415, lng: 17.6300428 },
    { lat: 48.3837705, lng: 17.6340765 },
    { lat: 48.3837893, lng: 17.6341069 },
    { lat: 48.3838239, lng: 17.6341535 },
    { lat: 48.3856815, lng: 17.6367079 },
    { lat: 48.3799166, lng: 17.6471031 },
    { lat: 48.3781199, lng: 17.6509708 },
    { lat: 48.3799555, lng: 17.654564 },
    { lat: 48.3800797, lng: 17.6553371 },
    { lat: 48.3801451, lng: 17.6563281 },
    { lat: 48.3805673, lng: 17.6587753 },
    { lat: 48.380527, lng: 17.6588158 },
    { lat: 48.3805969, lng: 17.6588322 },
    { lat: 48.3813791, lng: 17.6584692 },
    { lat: 48.3824937, lng: 17.657693 },
    { lat: 48.3830142, lng: 17.6594257 },
    { lat: 48.3834817, lng: 17.6592247 },
    { lat: 48.3844451, lng: 17.6585622 },
    { lat: 48.386375, lng: 17.6570359 },
    { lat: 48.386871, lng: 17.6565914 },
    { lat: 48.3878951, lng: 17.6554744 },
    { lat: 48.3904031, lng: 17.6534236 },
    { lat: 48.3922669, lng: 17.6523337 },
    { lat: 48.3929408, lng: 17.6518622 },
    { lat: 48.3936797, lng: 17.6513296 },
    { lat: 48.3946554, lng: 17.6507734 },
    { lat: 48.3954119, lng: 17.6500162 },
    { lat: 48.3977557, lng: 17.6472049 },
    { lat: 48.3997249, lng: 17.6442353 },
    { lat: 48.4010346, lng: 17.6423623 },
    { lat: 48.4020574, lng: 17.6410985 },
    { lat: 48.4026427, lng: 17.6401524 },
    { lat: 48.4052883, lng: 17.6377263 },
    { lat: 48.4067139, lng: 17.6367847 },
    { lat: 48.406928, lng: 17.636523 },
    { lat: 48.4070118, lng: 17.6363331 },
    { lat: 48.4072091, lng: 17.6365578 },
    { lat: 48.4070496, lng: 17.637363 },
    { lat: 48.4067482, lng: 17.6382121 },
    { lat: 48.4059208, lng: 17.639636 },
    { lat: 48.4051794, lng: 17.6407002 },
    { lat: 48.4042328, lng: 17.6423496 },
    { lat: 48.4040365, lng: 17.6428021 },
    { lat: 48.4040354, lng: 17.6428047 },
    { lat: 48.4045912, lng: 17.6439953 },
    { lat: 48.4058878, lng: 17.6460147 },
    { lat: 48.4059865, lng: 17.6461653 },
    { lat: 48.4066213, lng: 17.6470335 },
    { lat: 48.4071518, lng: 17.6479303 },
    { lat: 48.4075658, lng: 17.6489859 },
    { lat: 48.4075246, lng: 17.6490308 },
    { lat: 48.407573, lng: 17.6491004 },
    { lat: 48.4079913, lng: 17.6484779 },
    { lat: 48.4089386, lng: 17.649845 },
    { lat: 48.4105059, lng: 17.6519162 },
    { lat: 48.4251979, lng: 17.659514 },
  ],
  BielyKostol: [
    { lat: 48.3726841, lng: 17.5417159 },
    { lat: 48.3729747, lng: 17.540948 },
    { lat: 48.3733416, lng: 17.5402139 },
    { lat: 48.3736409, lng: 17.537681 },
    { lat: 48.3736375, lng: 17.5370965 },
    { lat: 48.3738557, lng: 17.5354898 },
    { lat: 48.3737449, lng: 17.5345641 },
    { lat: 48.3743259, lng: 17.5334406 },
    { lat: 48.3747111, lng: 17.5330496 },
    { lat: 48.3759457, lng: 17.5298343 },
    { lat: 48.3762921, lng: 17.5286251 },
    { lat: 48.3750672, lng: 17.5241924 },
    { lat: 48.3719127, lng: 17.5276203 },
    { lat: 48.3708567, lng: 17.5255338 },
    { lat: 48.370275, lng: 17.5245218 },
    { lat: 48.3698768, lng: 17.5240705 },
    { lat: 48.3693261, lng: 17.5237217 },
    { lat: 48.3688106, lng: 17.523184 },
    { lat: 48.3677251, lng: 17.5218727 },
    { lat: 48.3671621, lng: 17.5210324 },
    { lat: 48.3656445, lng: 17.5184043 },
    { lat: 48.3647434, lng: 17.5170809 },
    { lat: 48.3645948, lng: 17.5168075 },
    { lat: 48.3644047, lng: 17.5161814 },
    { lat: 48.3640899, lng: 17.5155328 },
    { lat: 48.3632189, lng: 17.5142388 },
    { lat: 48.3627661, lng: 17.5155474 },
    { lat: 48.3625159, lng: 17.5153096 },
    { lat: 48.3624078, lng: 17.5153337 },
    { lat: 48.3615873, lng: 17.516188 },
    { lat: 48.3606317, lng: 17.5170494 },
    { lat: 48.3593547, lng: 17.5180078 },
    { lat: 48.3587054, lng: 17.51838 },
    { lat: 48.3571271, lng: 17.5186005 },
    { lat: 48.3558527, lng: 17.5185926 },
    { lat: 48.355448, lng: 17.5187446 },
    { lat: 48.3552157, lng: 17.518946 },
    { lat: 48.3540756, lng: 17.5204903 },
    { lat: 48.3545487, lng: 17.5210727 },
    { lat: 48.3564883, lng: 17.5239935 },
    { lat: 48.3579835, lng: 17.5263993 },
    { lat: 48.3588281, lng: 17.5276368 },
    { lat: 48.359609, lng: 17.5285472 },
    { lat: 48.3604509, lng: 17.5297953 },
    { lat: 48.3616248, lng: 17.5315358 },
    { lat: 48.3618833, lng: 17.5319408 },
    { lat: 48.3619426, lng: 17.5322508 },
    { lat: 48.3643577, lng: 17.5361177 },
    { lat: 48.365602, lng: 17.5378695 },
    { lat: 48.3660677, lng: 17.5383702 },
    { lat: 48.3665328, lng: 17.5394295 },
    { lat: 48.3672932, lng: 17.5404814 },
    { lat: 48.3688995, lng: 17.5422077 },
    { lat: 48.3719, lng: 17.5430744 },
    { lat: 48.3721843, lng: 17.5423098 },
    { lat: 48.372276, lng: 17.5421712 },
    { lat: 48.3724503, lng: 17.5420691 },
    { lat: 48.3726841, lng: 17.5417159 },
  ],
  Dlhá: [
    { lat: 48.3884289, lng: 17.4610334 },
    { lat: 48.3885163, lng: 17.461181 },
    { lat: 48.3889655, lng: 17.4618968 },
    { lat: 48.3908616, lng: 17.4642447 },
    { lat: 48.391607, lng: 17.4653134 },
    { lat: 48.3957699, lng: 17.4604336 },
    { lat: 48.3952421, lng: 17.4598158 },
    { lat: 48.3981558, lng: 17.4583402 },
    { lat: 48.4065653, lng: 17.4620911 },
    { lat: 48.4066125, lng: 17.4612098 },
    { lat: 48.4074969, lng: 17.457072 },
    { lat: 48.4051912, lng: 17.4544164 },
    { lat: 48.4054174, lng: 17.4537397 },
    { lat: 48.4046545, lng: 17.4527651 },
    { lat: 48.4046708, lng: 17.4518791 },
    { lat: 48.4049275, lng: 17.4512201 },
    { lat: 48.4040255, lng: 17.4501417 },
    { lat: 48.4049358, lng: 17.4483553 },
    { lat: 48.4050785, lng: 17.4479912 },
    { lat: 48.4044618, lng: 17.4472922 },
    { lat: 48.4045252, lng: 17.4461388 },
    { lat: 48.4051569, lng: 17.4445145 },
    { lat: 48.4054881, lng: 17.4439071 },
    { lat: 48.4058934, lng: 17.4443756 },
    { lat: 48.4067491, lng: 17.4434605 },
    { lat: 48.4070113, lng: 17.4430593 },
    { lat: 48.4070401, lng: 17.4426151 },
    { lat: 48.4071337, lng: 17.442386 },
    { lat: 48.4074091, lng: 17.4412806 },
    { lat: 48.4074434, lng: 17.440603 },
    { lat: 48.4090195, lng: 17.4411349 },
    { lat: 48.4090378, lng: 17.4415954 },
    { lat: 48.4092341, lng: 17.4422049 },
    { lat: 48.4093963, lng: 17.4422321 },
    { lat: 48.4099662, lng: 17.4449006 },
    { lat: 48.4103838, lng: 17.4445295 },
    { lat: 48.410513, lng: 17.4448178 },
    { lat: 48.4105031, lng: 17.4450837 },
    { lat: 48.4106827, lng: 17.4456623 },
    { lat: 48.41122, lng: 17.4463043 },
    { lat: 48.4112505, lng: 17.4464101 },
    { lat: 48.4116578, lng: 17.4468954 },
    { lat: 48.4117399, lng: 17.4470818 },
    { lat: 48.4118631, lng: 17.4471862 },
    { lat: 48.4123193, lng: 17.4480622 },
    { lat: 48.4124258, lng: 17.4478565 },
    { lat: 48.4125847, lng: 17.4476926 },
    { lat: 48.4129007, lng: 17.4478283 },
    { lat: 48.4130143, lng: 17.4483649 },
    { lat: 48.4132628, lng: 17.4491898 },
    { lat: 48.4138479, lng: 17.4493809 },
    { lat: 48.4140231, lng: 17.4494557 },
    { lat: 48.414169, lng: 17.4476014 },
    { lat: 48.4145622, lng: 17.4456243 },
    { lat: 48.4141593, lng: 17.4450178 },
    { lat: 48.4156511, lng: 17.4428622 },
    { lat: 48.4168535, lng: 17.4416973 },
    { lat: 48.4167506, lng: 17.4414705 },
    { lat: 48.4171256, lng: 17.4411026 },
    { lat: 48.4181341, lng: 17.4398784 },
    { lat: 48.4184319, lng: 17.4395755 },
    { lat: 48.4189551, lng: 17.4399106 },
    { lat: 48.4199042, lng: 17.4382732 },
    { lat: 48.4207006, lng: 17.4372576 },
    { lat: 48.4220386, lng: 17.435382 },
    { lat: 48.42195, lng: 17.4352158 },
    { lat: 48.4224985, lng: 17.4345622 },
    { lat: 48.422615, lng: 17.4345659 },
    { lat: 48.4233408, lng: 17.4340849 },
    { lat: 48.424388, lng: 17.4319408 },
    { lat: 48.4245438, lng: 17.4316421 },
    { lat: 48.4245441, lng: 17.4315581 },
    { lat: 48.4241592, lng: 17.4311613 },
    { lat: 48.4252856, lng: 17.4283878 },
    { lat: 48.4254055, lng: 17.427902 },
    { lat: 48.4262628, lng: 17.425927 },
    { lat: 48.4266903, lng: 17.4250829 },
    { lat: 48.4269428, lng: 17.4244276 },
    { lat: 48.4270689, lng: 17.4233036 },
    { lat: 48.4273394, lng: 17.4227865 },
    { lat: 48.4274694, lng: 17.4227369 },
    { lat: 48.4276554, lng: 17.4224177 },
    { lat: 48.4276514, lng: 17.422078 },
    { lat: 48.4279207, lng: 17.4210027 },
    { lat: 48.4282604, lng: 17.4202315 },
    { lat: 48.4284037, lng: 17.4191984 },
    { lat: 48.4284002, lng: 17.41871 },
    { lat: 48.4260053, lng: 17.4161888 },
    { lat: 48.4266014, lng: 17.4152446 },
    { lat: 48.425901, lng: 17.4143363 },
    { lat: 48.4258052, lng: 17.4141476 },
    { lat: 48.4264734, lng: 17.4127092 },
    { lat: 48.4267404, lng: 17.4129621 },
    { lat: 48.4270159, lng: 17.4121071 },
    { lat: 48.4270958, lng: 17.4119777 },
    { lat: 48.4275867, lng: 17.4114663 },
    { lat: 48.4281757, lng: 17.4106034 },
    { lat: 48.4284884, lng: 17.4104094 },
    { lat: 48.4286684, lng: 17.4102074 },
    { lat: 48.4287244, lng: 17.4094498 },
    { lat: 48.4288019, lng: 17.4092874 },
    { lat: 48.4290053, lng: 17.4094632 },
    { lat: 48.4298838, lng: 17.4074184 },
    { lat: 48.4294983, lng: 17.4069079 },
    { lat: 48.4292255, lng: 17.4064464 },
    { lat: 48.4289077, lng: 17.4068222 },
    { lat: 48.4282144, lng: 17.4060777 },
    { lat: 48.4284483, lng: 17.405542 },
    { lat: 48.4285362, lng: 17.4052073 },
    { lat: 48.4280458, lng: 17.4047844 },
    { lat: 48.427748, lng: 17.4052437 },
    { lat: 48.4272843, lng: 17.4046413 },
    { lat: 48.4284928, lng: 17.4027935 },
    { lat: 48.4299046, lng: 17.400445 },
    { lat: 48.4298308, lng: 17.4001933 },
    { lat: 48.4298019, lng: 17.3996979 },
    { lat: 48.4296216, lng: 17.3988163 },
    { lat: 48.4297074, lng: 17.3986611 },
    { lat: 48.4296761, lng: 17.398506 },
    { lat: 48.4297138, lng: 17.3983216 },
    { lat: 48.4302634, lng: 17.3977054 },
    { lat: 48.4306013, lng: 17.3975371 },
    { lat: 48.4311182, lng: 17.3973902 },
    { lat: 48.4311971, lng: 17.3972488 },
    { lat: 48.431571, lng: 17.3969539 },
    { lat: 48.4316848, lng: 17.3966349 },
    { lat: 48.4318348, lng: 17.3964237 },
    { lat: 48.4320248, lng: 17.3958859 },
    { lat: 48.4323369, lng: 17.395302 },
    { lat: 48.4325489, lng: 17.3945827 },
    { lat: 48.432945, lng: 17.393883 },
    { lat: 48.432638, lng: 17.393454 },
    { lat: 48.432265, lng: 17.392927 },
    { lat: 48.432292, lng: 17.392913 },
    { lat: 48.432359, lng: 17.392821 },
    { lat: 48.432454, lng: 17.392559 },
    { lat: 48.432538, lng: 17.392 },
    { lat: 48.43265, lng: 17.391728 },
    { lat: 48.432732, lng: 17.391458 },
    { lat: 48.432884, lng: 17.390972 },
    { lat: 48.432858, lng: 17.390662 },
    { lat: 48.432586, lng: 17.390472 },
    { lat: 48.432202, lng: 17.390186 },
    { lat: 48.432115, lng: 17.390397 },
    { lat: 48.4318, lng: 17.390087 },
    { lat: 48.431597, lng: 17.389901 },
    { lat: 48.4315008, lng: 17.3898097 },
    { lat: 48.431341, lng: 17.389658 },
    { lat: 48.43106, lng: 17.38947 },
    { lat: 48.430872, lng: 17.389341 },
    { lat: 48.430787, lng: 17.388885 },
    { lat: 48.430674, lng: 17.388277 },
    { lat: 48.430524, lng: 17.387484 },
    { lat: 48.430457, lng: 17.387372 },
    { lat: 48.430617, lng: 17.387048 },
    { lat: 48.430574, lng: 17.386871 },
    { lat: 48.430556, lng: 17.386568 },
    { lat: 48.430794, lng: 17.386103 },
    { lat: 48.431023, lng: 17.385663 },
    { lat: 48.431153, lng: 17.385408 },
    { lat: 48.430799, lng: 17.385216 },
    { lat: 48.430392, lng: 17.385008 },
    { lat: 48.430304, lng: 17.384967 },
    { lat: 48.430176, lng: 17.384894 },
    { lat: 48.430073, lng: 17.385091 },
    { lat: 48.429995, lng: 17.385152 },
    { lat: 48.429842, lng: 17.385256 },
    { lat: 48.429701, lng: 17.385613 },
    { lat: 48.429665, lng: 17.385553 },
    { lat: 48.429477, lng: 17.385305 },
    { lat: 48.4293, lng: 17.385069 },
    { lat: 48.42934, lng: 17.384642 },
    { lat: 48.429335, lng: 17.384266 },
    { lat: 48.429109, lng: 17.384079 },
    { lat: 48.428934, lng: 17.383941 },
    { lat: 48.428929, lng: 17.383708 },
    { lat: 48.428896, lng: 17.383279 },
    { lat: 48.428924, lng: 17.382885 },
    { lat: 48.428979, lng: 17.382244 },
    { lat: 48.429101, lng: 17.381897 },
    { lat: 48.429222, lng: 17.38155 },
    { lat: 48.429493, lng: 17.380915 },
    { lat: 48.429351, lng: 17.380784 },
    { lat: 48.429456, lng: 17.380505 },
    { lat: 48.429339, lng: 17.380351 },
    { lat: 48.429196, lng: 17.379908 },
    { lat: 48.429181, lng: 17.379882 },
    { lat: 48.428989, lng: 17.379984 },
    { lat: 48.428949, lng: 17.38007 },
    { lat: 48.428943, lng: 17.380209 },
    { lat: 48.42881, lng: 17.380508 },
    { lat: 48.428785, lng: 17.38063 },
    { lat: 48.42877, lng: 17.38065 },
    { lat: 48.428635, lng: 17.380749 },
    { lat: 48.428345, lng: 17.381196 },
    { lat: 48.428112, lng: 17.381544 },
    { lat: 48.428006, lng: 17.381696 },
    { lat: 48.427839, lng: 17.381847 },
    { lat: 48.427655, lng: 17.382222 },
    { lat: 48.427482, lng: 17.382522 },
    { lat: 48.427359, lng: 17.382724 },
    { lat: 48.427302, lng: 17.382742 },
    { lat: 48.427203, lng: 17.382877 },
    { lat: 48.427108, lng: 17.383169 },
    { lat: 48.426917, lng: 17.383446 },
    { lat: 48.426762, lng: 17.383782 },
    { lat: 48.426505, lng: 17.384165 },
    { lat: 48.42634, lng: 17.384527 },
    { lat: 48.426166, lng: 17.38458 },
    { lat: 48.425886, lng: 17.384753 },
    { lat: 48.425641, lng: 17.384943 },
    { lat: 48.425428, lng: 17.385072 },
    { lat: 48.42516, lng: 17.385107 },
    { lat: 48.424993, lng: 17.385132 },
    { lat: 48.424849, lng: 17.385092 },
    { lat: 48.42479, lng: 17.385021 },
    { lat: 48.424606, lng: 17.384947 },
    { lat: 48.42443, lng: 17.384882 },
    { lat: 48.424366, lng: 17.384816 },
    { lat: 48.42425, lng: 17.384834 },
    { lat: 48.424173, lng: 17.384944 },
    { lat: 48.423998, lng: 17.385028 },
    { lat: 48.423763, lng: 17.385228 },
    { lat: 48.423707, lng: 17.385341 },
    { lat: 48.423784, lng: 17.385822 },
    { lat: 48.423832, lng: 17.386089 },
    { lat: 48.423514, lng: 17.386238 },
    { lat: 48.423097, lng: 17.386431 },
    { lat: 48.422876, lng: 17.386579 },
    { lat: 48.422786, lng: 17.386647 },
    { lat: 48.42252, lng: 17.386866 },
    { lat: 48.422322, lng: 17.387034 },
    { lat: 48.422225, lng: 17.386925 },
    { lat: 48.422051, lng: 17.38681 },
    { lat: 48.421715, lng: 17.386539 },
    { lat: 48.42148, lng: 17.386778 },
    { lat: 48.421261, lng: 17.386999 },
    { lat: 48.421188, lng: 17.387086 },
    { lat: 48.421065, lng: 17.3872 },
    { lat: 48.420972, lng: 17.38734 },
    { lat: 48.420843, lng: 17.387495 },
    { lat: 48.420689, lng: 17.387739 },
    { lat: 48.420609, lng: 17.388188 },
    { lat: 48.420402, lng: 17.388574 },
    { lat: 48.420246, lng: 17.388856 },
    { lat: 48.419993, lng: 17.389217 },
    { lat: 48.419787, lng: 17.389539 },
    { lat: 48.419646, lng: 17.389762 },
    { lat: 48.419517, lng: 17.389602 },
    { lat: 48.419329, lng: 17.389606 },
    { lat: 48.419213, lng: 17.389779 },
    { lat: 48.419236, lng: 17.390248 },
    { lat: 48.419183, lng: 17.390328 },
    { lat: 48.419221, lng: 17.390523 },
    { lat: 48.419115, lng: 17.390584 },
    { lat: 48.4191, lng: 17.390685 },
    { lat: 48.419142, lng: 17.390935 },
    { lat: 48.419151, lng: 17.391115 },
    { lat: 48.419079, lng: 17.391266 },
    { lat: 48.419158, lng: 17.391703 },
    { lat: 48.419219, lng: 17.392073 },
    { lat: 48.419332, lng: 17.392194 },
    { lat: 48.41931, lng: 17.392545 },
    { lat: 48.419325, lng: 17.392683 },
    { lat: 48.419233, lng: 17.39294 },
    { lat: 48.419212, lng: 17.393354 },
    { lat: 48.419201, lng: 17.393662 },
    { lat: 48.418982, lng: 17.394051 },
    { lat: 48.418957, lng: 17.394223 },
    { lat: 48.418936, lng: 17.394409 },
    { lat: 48.418958, lng: 17.394556 },
    { lat: 48.418976, lng: 17.394812 },
    { lat: 48.418984, lng: 17.394939 },
    { lat: 48.41903, lng: 17.395151 },
    { lat: 48.419077, lng: 17.395147 },
    { lat: 48.419102, lng: 17.39537 },
    { lat: 48.419036, lng: 17.395358 },
    { lat: 48.419016, lng: 17.395544 },
    { lat: 48.419016, lng: 17.395647 },
    { lat: 48.419034, lng: 17.395771 },
    { lat: 48.41897, lng: 17.396009 },
    { lat: 48.418912, lng: 17.396434 },
    { lat: 48.418998, lng: 17.3966 },
    { lat: 48.419101, lng: 17.396799 },
    { lat: 48.418905, lng: 17.396981 },
    { lat: 48.418784, lng: 17.397056 },
    { lat: 48.41874, lng: 17.39733 },
    { lat: 48.418791, lng: 17.39751 },
    { lat: 48.418823, lng: 17.397692 },
    { lat: 48.418788, lng: 17.397883 },
    { lat: 48.418859, lng: 17.398184 },
    { lat: 48.418753, lng: 17.39843 },
    { lat: 48.418692, lng: 17.398712 },
    { lat: 48.418591, lng: 17.398975 },
    { lat: 48.418563, lng: 17.399204 },
    { lat: 48.418514, lng: 17.399573 },
    { lat: 48.418568, lng: 17.399961 },
    { lat: 48.418655, lng: 17.400233 },
    { lat: 48.418549, lng: 17.400484 },
    { lat: 48.418436, lng: 17.400724 },
    { lat: 48.418469, lng: 17.401051 },
    { lat: 48.418405, lng: 17.401351 },
    { lat: 48.418368, lng: 17.401545 },
    { lat: 48.418362, lng: 17.401689 },
    { lat: 48.418362, lng: 17.40183 },
    { lat: 48.418209, lng: 17.402025 },
    { lat: 48.418263, lng: 17.402169 },
    { lat: 48.418218, lng: 17.402436 },
    { lat: 48.418085, lng: 17.402563 },
    { lat: 48.418052, lng: 17.402641 },
    { lat: 48.418103, lng: 17.402715 },
    { lat: 48.418173, lng: 17.402764 },
    { lat: 48.418065, lng: 17.402925 },
    { lat: 48.417962, lng: 17.403051 },
    { lat: 48.418008, lng: 17.403383 },
    { lat: 48.417809, lng: 17.403725 },
    { lat: 48.417656, lng: 17.404064 },
    { lat: 48.417628, lng: 17.404131 },
    { lat: 48.417371, lng: 17.404386 },
    { lat: 48.417128, lng: 17.40438 },
    { lat: 48.416912, lng: 17.40416 },
    { lat: 48.416609, lng: 17.403824 },
    { lat: 48.416459, lng: 17.403679 },
    { lat: 48.416048, lng: 17.403651 },
    { lat: 48.415976, lng: 17.404077 },
    { lat: 48.415796, lng: 17.404439 },
    { lat: 48.415352, lng: 17.404743 },
    { lat: 48.41528, lng: 17.404669 },
    { lat: 48.415046, lng: 17.404894 },
    { lat: 48.414713, lng: 17.405058 },
    { lat: 48.414578, lng: 17.405047 },
    { lat: 48.414374, lng: 17.404953 },
    { lat: 48.414416, lng: 17.404866 },
    { lat: 48.414, lng: 17.404721 },
    { lat: 48.413587, lng: 17.404574 },
    { lat: 48.413357, lng: 17.404498 },
    { lat: 48.413263, lng: 17.404887 },
    { lat: 48.413098, lng: 17.404764 },
    { lat: 48.413154, lng: 17.404477 },
    { lat: 48.413199, lng: 17.404339 },
    { lat: 48.413383, lng: 17.403814 },
    { lat: 48.413499, lng: 17.403405 },
    { lat: 48.413359, lng: 17.40332 },
    { lat: 48.413271, lng: 17.403309 },
    { lat: 48.413187, lng: 17.403422 },
    { lat: 48.413076, lng: 17.403634 },
    { lat: 48.412972, lng: 17.40371 },
    { lat: 48.412894, lng: 17.403805 },
    { lat: 48.412862, lng: 17.403905 },
    { lat: 48.412701, lng: 17.404014 },
    { lat: 48.412536, lng: 17.403977 },
    { lat: 48.41243, lng: 17.40388 },
    { lat: 48.412404, lng: 17.403683 },
    { lat: 48.41244, lng: 17.403472 },
    { lat: 48.412245, lng: 17.403285 },
    { lat: 48.412279, lng: 17.403155 },
    { lat: 48.412223, lng: 17.403009 },
    { lat: 48.412222, lng: 17.402842 },
    { lat: 48.412019, lng: 17.402618 },
    { lat: 48.411971, lng: 17.402521 },
    { lat: 48.41178, lng: 17.402315 },
    { lat: 48.411671, lng: 17.402215 },
    { lat: 48.411382, lng: 17.402025 },
    { lat: 48.410999, lng: 17.401798 },
    { lat: 48.410915, lng: 17.401751 },
    { lat: 48.410716, lng: 17.401605 },
    { lat: 48.410594, lng: 17.40189 },
    { lat: 48.410313, lng: 17.402515 },
    { lat: 48.41026, lng: 17.402639 },
    { lat: 48.410038, lng: 17.403205 },
    { lat: 48.409782, lng: 17.402938 },
    { lat: 48.409654, lng: 17.402733 },
    { lat: 48.409578, lng: 17.402731 },
    { lat: 48.409419, lng: 17.402972 },
    { lat: 48.409235, lng: 17.40339 },
    { lat: 48.409116, lng: 17.403679 },
    { lat: 48.409043, lng: 17.403562 },
    { lat: 48.408849, lng: 17.403029 },
    { lat: 48.408628, lng: 17.402426 },
    { lat: 48.408421, lng: 17.401852 },
    { lat: 48.408338, lng: 17.401596 },
    { lat: 48.4081, lng: 17.401006 },
    { lat: 48.407883, lng: 17.400536 },
    { lat: 48.40776, lng: 17.400264 },
    { lat: 48.407729, lng: 17.400197 },
    { lat: 48.407498, lng: 17.400502 },
    { lat: 48.40735, lng: 17.400707 },
    { lat: 48.407216, lng: 17.401136 },
    { lat: 48.407187, lng: 17.401078 },
    { lat: 48.406935, lng: 17.400672 },
    { lat: 48.406719, lng: 17.400331 },
    { lat: 48.406433, lng: 17.400676 },
    { lat: 48.406233, lng: 17.400916 },
    { lat: 48.406487, lng: 17.401446 },
    { lat: 48.406808, lng: 17.402104 },
    { lat: 48.407097, lng: 17.402748 },
    { lat: 48.407335, lng: 17.403336 },
    { lat: 48.407556, lng: 17.403927 },
    { lat: 48.407734, lng: 17.404434 },
    { lat: 48.407928, lng: 17.405152 },
    { lat: 48.408164, lng: 17.405851 },
    { lat: 48.408293, lng: 17.40615 },
    { lat: 48.408306, lng: 17.40632 },
    { lat: 48.40828, lng: 17.406434 },
    { lat: 48.408265, lng: 17.406561 },
    { lat: 48.408189, lng: 17.406797 },
    { lat: 48.408094, lng: 17.407366 },
    { lat: 48.40804, lng: 17.407681 },
    { lat: 48.407985, lng: 17.407859 },
    { lat: 48.407925, lng: 17.407961 },
    { lat: 48.407914, lng: 17.407991 },
    { lat: 48.407777, lng: 17.408366 },
    { lat: 48.407574, lng: 17.408916 },
    { lat: 48.407325, lng: 17.409557 },
    { lat: 48.407114, lng: 17.410149 },
    { lat: 48.40692, lng: 17.410715 },
    { lat: 48.406745, lng: 17.411144 },
    { lat: 48.406477, lng: 17.411846 },
    { lat: 48.406439, lng: 17.411941 },
    { lat: 48.40631, lng: 17.412371 },
    { lat: 48.406102, lng: 17.412832 },
    { lat: 48.406027, lng: 17.413078 },
    { lat: 48.405939, lng: 17.413631 },
    { lat: 48.405814, lng: 17.414176 },
    { lat: 48.405657, lng: 17.414764 },
    { lat: 48.405597, lng: 17.414971 },
    { lat: 48.40536, lng: 17.415568 },
    { lat: 48.405274, lng: 17.41588 },
    { lat: 48.405157, lng: 17.416487 },
    { lat: 48.405299, lng: 17.416726 },
    { lat: 48.405151, lng: 17.416995 },
    { lat: 48.405099, lng: 17.417079 },
    { lat: 48.404998, lng: 17.417223 },
    { lat: 48.4050676, lng: 17.4179595 },
    { lat: 48.4051924, lng: 17.4180924 },
    { lat: 48.4052068, lng: 17.4181759 },
    { lat: 48.4051464, lng: 17.4183941 },
    { lat: 48.4051461, lng: 17.4187038 },
    { lat: 48.4050061, lng: 17.4193673 },
    { lat: 48.4050579, lng: 17.4197465 },
    { lat: 48.4049621, lng: 17.4199087 },
    { lat: 48.4049076, lng: 17.4203748 },
    { lat: 48.4047888, lng: 17.4205991 },
    { lat: 48.404732, lng: 17.4208541 },
    { lat: 48.404765, lng: 17.4211556 },
    { lat: 48.4047466, lng: 17.4221956 },
    { lat: 48.404612, lng: 17.4226318 },
    { lat: 48.404617, lng: 17.4238048 },
    { lat: 48.4045389, lng: 17.4240324 },
    { lat: 48.4045752, lng: 17.4246438 },
    { lat: 48.4046907, lng: 17.4251519 },
    { lat: 48.4051275, lng: 17.4255869 },
    { lat: 48.4052749, lng: 17.4258046 },
    { lat: 48.4052115, lng: 17.4260794 },
    { lat: 48.4054428, lng: 17.42634 },
    { lat: 48.4053799, lng: 17.426433 },
    { lat: 48.4051581, lng: 17.4264068 },
    { lat: 48.4045364, lng: 17.4262618 },
    { lat: 48.4042024, lng: 17.4261104 },
    { lat: 48.404046, lng: 17.4261877 },
    { lat: 48.4037023, lng: 17.4266185 },
    { lat: 48.40338, lng: 17.4266826 },
    { lat: 48.4031719, lng: 17.4269223 },
    { lat: 48.4029408, lng: 17.4269138 },
    { lat: 48.402468, lng: 17.4271818 },
    { lat: 48.4021818, lng: 17.4272431 },
    { lat: 48.4020406, lng: 17.4273138 },
    { lat: 48.4018486, lng: 17.4275217 },
    { lat: 48.4015451, lng: 17.4276346 },
    { lat: 48.4014716, lng: 17.4277708 },
    { lat: 48.401358, lng: 17.4278478 },
    { lat: 48.4013131, lng: 17.4280434 },
    { lat: 48.4012213, lng: 17.4280916 },
    { lat: 48.4011295, lng: 17.4283144 },
    { lat: 48.4006543, lng: 17.4284591 },
    { lat: 48.4006289, lng: 17.4285893 },
    { lat: 48.4005526, lng: 17.428669 },
    { lat: 48.400396, lng: 17.4287616 },
    { lat: 48.4002563, lng: 17.4287311 },
    { lat: 48.4001159, lng: 17.4289016 },
    { lat: 48.4000359, lng: 17.4289207 },
    { lat: 48.3995112, lng: 17.4295571 },
    { lat: 48.3993645, lng: 17.4296304 },
    { lat: 48.3990662, lng: 17.4299774 },
    { lat: 48.3988848, lng: 17.4303678 },
    { lat: 48.3987708, lng: 17.4308219 },
    { lat: 48.3984095, lng: 17.4312443 },
    { lat: 48.3983531, lng: 17.4314384 },
    { lat: 48.3981019, lng: 17.431641 },
    { lat: 48.397756, lng: 17.4322107 },
    { lat: 48.3976203, lng: 17.4323492 },
    { lat: 48.3968483, lng: 17.4328296 },
    { lat: 48.3965637, lng: 17.4333903 },
    { lat: 48.396194, lng: 17.4339062 },
    { lat: 48.3958711, lng: 17.4344842 },
    { lat: 48.3958256, lng: 17.4347247 },
    { lat: 48.3954996, lng: 17.4354667 },
    { lat: 48.3950634, lng: 17.4359681 },
    { lat: 48.3941983, lng: 17.4375612 },
    { lat: 48.3938524, lng: 17.438306 },
    { lat: 48.3933856, lng: 17.4394931 },
    { lat: 48.3932169, lng: 17.4399739 },
    { lat: 48.3931343, lng: 17.4405489 },
    { lat: 48.3929484, lng: 17.4412391 },
    { lat: 48.392551, lng: 17.4425553 },
    { lat: 48.3918367, lng: 17.4442773 },
    { lat: 48.3917859, lng: 17.4444948 },
    { lat: 48.3915756, lng: 17.4453945 },
    { lat: 48.3912708, lng: 17.4463987 },
    { lat: 48.3912422, lng: 17.4464928 },
    { lat: 48.3913277, lng: 17.4470468 },
    { lat: 48.3913033, lng: 17.4475642 },
    { lat: 48.3911571, lng: 17.4480645 },
    { lat: 48.3901725, lng: 17.4504809 },
    { lat: 48.3900657, lng: 17.450839 },
    { lat: 48.3898339, lng: 17.4517994 },
    { lat: 48.389775, lng: 17.4527425 },
    { lat: 48.389806, lng: 17.4528898 },
    { lat: 48.3896056, lng: 17.4539522 },
    { lat: 48.3892739, lng: 17.4550764 },
    { lat: 48.3888315, lng: 17.4562575 },
    { lat: 48.3894981, lng: 17.4573834 },
    { lat: 48.3904444, lng: 17.4588176 },
    { lat: 48.3894478, lng: 17.4600003 },
    { lat: 48.3888021, lng: 17.4607088 },
    { lat: 48.3884599, lng: 17.4610069 },
    { lat: 48.3884289, lng: 17.4610334 },
  ],
  DolnáKrupá: [
    { lat: 48.4918399, lng: 17.5138838 },
    { lat: 48.4907395, lng: 17.5148339 },
    { lat: 48.4883393, lng: 17.5159093 },
    { lat: 48.4865663, lng: 17.5161909 },
    { lat: 48.4850671, lng: 17.5169289 },
    { lat: 48.4834998, lng: 17.5179384 },
    { lat: 48.4824358, lng: 17.5196002 },
    { lat: 48.4820023, lng: 17.5205769 },
    { lat: 48.4811759, lng: 17.5227956 },
    { lat: 48.4803556, lng: 17.5239561 },
    { lat: 48.4795856, lng: 17.5262303 },
    { lat: 48.4789417, lng: 17.5297177 },
    { lat: 48.4777041, lng: 17.5335326 },
    { lat: 48.4775659, lng: 17.5339736 },
    { lat: 48.4775486, lng: 17.5347295 },
    { lat: 48.4774335, lng: 17.5352067 },
    { lat: 48.4751528, lng: 17.5394746 },
    { lat: 48.4732943, lng: 17.5413297 },
    { lat: 48.4725883, lng: 17.5414647 },
    { lat: 48.4707904, lng: 17.5380931 },
    { lat: 48.4692873, lng: 17.5357339 },
    { lat: 48.4681438, lng: 17.5342164 },
    { lat: 48.4672938, lng: 17.5331392 },
    { lat: 48.467276, lng: 17.5331976 },
    { lat: 48.4665368, lng: 17.5345702 },
    { lat: 48.4653137, lng: 17.5362373 },
    { lat: 48.4641344, lng: 17.5375083 },
    { lat: 48.4614656, lng: 17.5400799 },
    { lat: 48.4607972, lng: 17.5409698 },
    { lat: 48.4604292, lng: 17.5417362 },
    { lat: 48.4602215, lng: 17.5421617 },
    { lat: 48.4592342, lng: 17.5433824 },
    { lat: 48.4586206, lng: 17.544353 },
    { lat: 48.4571469, lng: 17.5471055 },
    { lat: 48.4566594, lng: 17.5477722 },
    { lat: 48.4556669, lng: 17.5488547 },
    { lat: 48.4549037, lng: 17.550016 },
    { lat: 48.454073, lng: 17.550839 },
    { lat: 48.4522107, lng: 17.5532565 },
    { lat: 48.451692, lng: 17.5544615 },
    { lat: 48.4504792, lng: 17.5578933 },
    { lat: 48.4500816, lng: 17.5573741 },
    { lat: 48.4460615, lng: 17.5529316 },
    { lat: 48.4456331, lng: 17.55339 },
    { lat: 48.444186, lng: 17.5554786 },
    { lat: 48.4438817, lng: 17.5560415 },
    { lat: 48.4439946, lng: 17.5561986 },
    { lat: 48.4434697, lng: 17.5573569 },
    { lat: 48.4427888, lng: 17.558623 },
    { lat: 48.441794, lng: 17.5598173 },
    { lat: 48.4403886, lng: 17.5619799 },
    { lat: 48.4395934, lng: 17.563008 },
    { lat: 48.4387243, lng: 17.5637613 },
    { lat: 48.4378328, lng: 17.5642002 },
    { lat: 48.4371086, lng: 17.564995 },
    { lat: 48.4362612, lng: 17.5654165 },
    { lat: 48.4356022, lng: 17.5658673 },
    { lat: 48.4349074, lng: 17.5666101 },
    { lat: 48.4349327, lng: 17.5676314 },
    { lat: 48.4347743, lng: 17.568339 },
    { lat: 48.4345756, lng: 17.5689115 },
    { lat: 48.4337084, lng: 17.5693579 },
    { lat: 48.4330365, lng: 17.5696102 },
    { lat: 48.4324592, lng: 17.5703166 },
    { lat: 48.4318802, lng: 17.5705897 },
    { lat: 48.4316239, lng: 17.5702683 },
    { lat: 48.4303381, lng: 17.5703638 },
    { lat: 48.4316855, lng: 17.5725246 },
    { lat: 48.4315216, lng: 17.5728257 },
    { lat: 48.4336421, lng: 17.5764929 },
    { lat: 48.4341707, lng: 17.5756936 },
    { lat: 48.434529, lng: 17.5753572 },
    { lat: 48.4347878, lng: 17.5749333 },
    { lat: 48.4355286, lng: 17.5746477 },
    { lat: 48.4356929, lng: 17.574462 },
    { lat: 48.4359845, lng: 17.574411 },
    { lat: 48.4364886, lng: 17.5733325 },
    { lat: 48.4366864, lng: 17.5731385 },
    { lat: 48.4368221, lng: 17.5730878 },
    { lat: 48.4370851, lng: 17.5727304 },
    { lat: 48.4374163, lng: 17.5720048 },
    { lat: 48.4378579, lng: 17.5714868 },
    { lat: 48.4382851, lng: 17.5711745 },
    { lat: 48.4386672, lng: 17.5710348 },
    { lat: 48.4390614, lng: 17.5704474 },
    { lat: 48.4393146, lng: 17.5703279 },
    { lat: 48.4400664, lng: 17.5697007 },
    { lat: 48.4403391, lng: 17.5696023 },
    { lat: 48.4404641, lng: 17.5694905 },
    { lat: 48.4404651, lng: 17.5691667 },
    { lat: 48.4405105, lng: 17.5689905 },
    { lat: 48.4411579, lng: 17.5684981 },
    { lat: 48.441396, lng: 17.5680169 },
    { lat: 48.4418549, lng: 17.5677374 },
    { lat: 48.4420001, lng: 17.5673176 },
    { lat: 48.4422403, lng: 17.5673664 },
    { lat: 48.4425376, lng: 17.5670506 },
    { lat: 48.4427669, lng: 17.5670694 },
    { lat: 48.4430041, lng: 17.5667352 },
    { lat: 48.4432636, lng: 17.5665501 },
    { lat: 48.4434861, lng: 17.566499 },
    { lat: 48.4436363, lng: 17.5661438 },
    { lat: 48.4439223, lng: 17.5658244 },
    { lat: 48.4441871, lng: 17.5656754 },
    { lat: 48.4444391, lng: 17.5652869 },
    { lat: 48.44464, lng: 17.5651606 },
    { lat: 48.4447833, lng: 17.5648566 },
    { lat: 48.4449588, lng: 17.5647365 },
    { lat: 48.4452455, lng: 17.5643618 },
    { lat: 48.445537, lng: 17.564151 },
    { lat: 48.4455553, lng: 17.5640111 },
    { lat: 48.4457079, lng: 17.563816 },
    { lat: 48.445909, lng: 17.5642453 },
    { lat: 48.4472308, lng: 17.5661236 },
    { lat: 48.4474468, lng: 17.5663626 },
    { lat: 48.4497104, lng: 17.5697736 },
    { lat: 48.4502612, lng: 17.5707131 },
    { lat: 48.4513934, lng: 17.572881 },
    { lat: 48.4515594, lng: 17.5730913 },
    { lat: 48.4517499, lng: 17.5739256 },
    { lat: 48.4546362, lng: 17.5775996 },
    { lat: 48.4555662, lng: 17.5786957 },
    { lat: 48.4566588, lng: 17.5803637 },
    { lat: 48.4573802, lng: 17.5817917 },
    { lat: 48.4549896, lng: 17.5846854 },
    { lat: 48.4556249, lng: 17.5857134 },
    { lat: 48.4564526, lng: 17.5873571 },
    { lat: 48.4579168, lng: 17.5898482 },
    { lat: 48.4588806, lng: 17.5889459 },
    { lat: 48.4590379, lng: 17.5891135 },
    { lat: 48.4592412, lng: 17.5891474 },
    { lat: 48.4592419, lng: 17.5892344 },
    { lat: 48.4597013, lng: 17.5895687 },
    { lat: 48.4598703, lng: 17.5899224 },
    { lat: 48.4600554, lng: 17.5901703 },
    { lat: 48.4602619, lng: 17.5902212 },
    { lat: 48.4604309, lng: 17.5904279 },
    { lat: 48.4609174, lng: 17.5896216 },
    { lat: 48.4661866, lng: 17.5900882 },
    { lat: 48.4666274, lng: 17.5910251 },
    { lat: 48.4671627, lng: 17.5917937 },
    { lat: 48.4679796, lng: 17.593195 },
    { lat: 48.4686001, lng: 17.5939198 },
    { lat: 48.4686848, lng: 17.5935775 },
    { lat: 48.4690302, lng: 17.5933819 },
    { lat: 48.4705294, lng: 17.5950748 },
    { lat: 48.4718228, lng: 17.5968897 },
    { lat: 48.4721994, lng: 17.5974153 },
    { lat: 48.475101, lng: 17.5948477 },
    { lat: 48.4778586, lng: 17.5909116 },
    { lat: 48.4784113, lng: 17.5900439 },
    { lat: 48.4786504, lng: 17.5895926 },
    { lat: 48.4789267, lng: 17.5888908 },
    { lat: 48.4791977, lng: 17.5876028 },
    { lat: 48.4792921, lng: 17.5873259 },
    { lat: 48.4796601, lng: 17.587416 },
    { lat: 48.4799693, lng: 17.587411 },
    { lat: 48.4802235, lng: 17.5873782 },
    { lat: 48.4807488, lng: 17.5871646 },
    { lat: 48.480925, lng: 17.587006 },
    { lat: 48.4813978, lng: 17.5861418 },
    { lat: 48.4818612, lng: 17.5854868 },
    { lat: 48.4827746, lng: 17.5845731 },
    { lat: 48.4834337, lng: 17.5837997 },
    { lat: 48.4837635, lng: 17.5834994 },
    { lat: 48.4850352, lng: 17.5825718 },
    { lat: 48.4860511, lng: 17.5819021 },
    { lat: 48.4876479, lng: 17.5798688 },
    { lat: 48.4879826, lng: 17.5795068 },
    { lat: 48.4881637, lng: 17.5793109 },
    { lat: 48.4888532, lng: 17.5783567 },
    { lat: 48.4901419, lng: 17.5770579 },
    { lat: 48.4905254, lng: 17.5763725 },
    { lat: 48.4908533, lng: 17.5759155 },
    { lat: 48.4917302, lng: 17.5751551 },
    { lat: 48.4939143, lng: 17.572783 },
    { lat: 48.4943804, lng: 17.5719408 },
    { lat: 48.4950002, lng: 17.5705656 },
    { lat: 48.4955187, lng: 17.5696404 },
    { lat: 48.4965792, lng: 17.5682333 },
    { lat: 48.4973863, lng: 17.5675154 },
    { lat: 48.4981296, lng: 17.5666298 },
    { lat: 48.4989433, lng: 17.5657992 },
    { lat: 48.4994015, lng: 17.5650113 },
    { lat: 48.4997987, lng: 17.5645431 },
    { lat: 48.4999215, lng: 17.5642705 },
    { lat: 48.499979, lng: 17.5641426 },
    { lat: 48.5000966, lng: 17.5638819 },
    { lat: 48.5001612, lng: 17.5635788 },
    { lat: 48.5002326, lng: 17.5634443 },
    { lat: 48.5004107, lng: 17.5632931 },
    { lat: 48.4990332, lng: 17.5613023 },
    { lat: 48.4992309, lng: 17.5612526 },
    { lat: 48.4994805, lng: 17.56086 },
    { lat: 48.499746, lng: 17.5608066 },
    { lat: 48.4999498, lng: 17.5605131 },
    { lat: 48.4998635, lng: 17.5604354 },
    { lat: 48.4998652, lng: 17.5603732 },
    { lat: 48.5000138, lng: 17.5600627 },
    { lat: 48.500135, lng: 17.5599435 },
    { lat: 48.5003371, lng: 17.5598888 },
    { lat: 48.5006246, lng: 17.5593662 },
    { lat: 48.5008681, lng: 17.5591076 },
    { lat: 48.5010152, lng: 17.5588308 },
    { lat: 48.5012792, lng: 17.5586832 },
    { lat: 48.5016518, lng: 17.557951 },
    { lat: 48.5020841, lng: 17.5576432 },
    { lat: 48.5023621, lng: 17.5573441 },
    { lat: 48.5024738, lng: 17.5568113 },
    { lat: 48.5027472, lng: 17.5565438 },
    { lat: 48.5029512, lng: 17.5562528 },
    { lat: 48.503224, lng: 17.5560843 },
    { lat: 48.5032845, lng: 17.5557587 },
    { lat: 48.5033771, lng: 17.5555958 },
    { lat: 48.5040288, lng: 17.5550289 },
    { lat: 48.5042213, lng: 17.5545942 },
    { lat: 48.5046952, lng: 17.5541779 },
    { lat: 48.5048873, lng: 17.5537856 },
    { lat: 48.5051587, lng: 17.5534731 },
    { lat: 48.5053289, lng: 17.5530594 },
    { lat: 48.5056831, lng: 17.5524476 },
    { lat: 48.5059116, lng: 17.5525943 },
    { lat: 48.5059712, lng: 17.5525754 },
    { lat: 48.5060302, lng: 17.5524351 },
    { lat: 48.5062717, lng: 17.5522398 },
    { lat: 48.5066238, lng: 17.5517177 },
    { lat: 48.507038, lng: 17.5512529 },
    { lat: 48.5072928, lng: 17.5505499 },
    { lat: 48.5081535, lng: 17.5496121 },
    { lat: 48.5085295, lng: 17.5492717 },
    { lat: 48.5082842, lng: 17.5487709 },
    { lat: 48.5079327, lng: 17.5477328 },
    { lat: 48.5075999, lng: 17.5466546 },
    { lat: 48.5075174, lng: 17.5462273 },
    { lat: 48.5074776, lng: 17.5456536 },
    { lat: 48.5077988, lng: 17.5428157 },
    { lat: 48.5078927, lng: 17.5422858 },
    { lat: 48.5079087, lng: 17.5417047 },
    { lat: 48.5077818, lng: 17.5415919 },
    { lat: 48.5076518, lng: 17.5410914 },
    { lat: 48.5075856, lng: 17.5404399 },
    { lat: 48.5074363, lng: 17.540028 },
    { lat: 48.5070525, lng: 17.5384494 },
    { lat: 48.5065072, lng: 17.5324697 },
    { lat: 48.5064287, lng: 17.5320697 },
    { lat: 48.5062983, lng: 17.5317947 },
    { lat: 48.5064825, lng: 17.5314946 },
    { lat: 48.5060138, lng: 17.5292769 },
    { lat: 48.5060534, lng: 17.529137 },
    { lat: 48.5060282, lng: 17.5289896 },
    { lat: 48.504931, lng: 17.5246008 },
    { lat: 48.5049799, lng: 17.5245692 },
    { lat: 48.5044794, lng: 17.5231286 },
    { lat: 48.5041619, lng: 17.5225049 },
    { lat: 48.5027024, lng: 17.5202506 },
    { lat: 48.5015857, lng: 17.5184101 },
    { lat: 48.5015346, lng: 17.5183178 },
    { lat: 48.501464, lng: 17.5183461 },
    { lat: 48.5012912, lng: 17.518609 },
    { lat: 48.5008139, lng: 17.5189368 },
    { lat: 48.5005549, lng: 17.5192829 },
    { lat: 48.500323, lng: 17.5198074 },
    { lat: 48.5000856, lng: 17.5201967 },
    { lat: 48.4995012, lng: 17.520948 },
    { lat: 48.4991641, lng: 17.5214888 },
    { lat: 48.4980488, lng: 17.5197779 },
    { lat: 48.4957848, lng: 17.5159929 },
    { lat: 48.4954299, lng: 17.5163625 },
    { lat: 48.4937414, lng: 17.5145334 },
    { lat: 48.4931816, lng: 17.5151426 },
    { lat: 48.4926951, lng: 17.5150926 },
    { lat: 48.4919046, lng: 17.5138712 },
    { lat: 48.4918399, lng: 17.5138838 },
  ],
  Vlčkovce: [
    { lat: 48.304345, lng: 17.687868 },
    { lat: 48.3043689, lng: 17.687775 },
    { lat: 48.3065836, lng: 17.6806871 },
    { lat: 48.3070204, lng: 17.6790804 },
    { lat: 48.3071536, lng: 17.6783052 },
    { lat: 48.3075882, lng: 17.6766406 },
    { lat: 48.3074634, lng: 17.6764886 },
    { lat: 48.3080628, lng: 17.6745543 },
    { lat: 48.3085009, lng: 17.6743412 },
    { lat: 48.3090032, lng: 17.6736885 },
    { lat: 48.3095502, lng: 17.67193 },
    { lat: 48.309763, lng: 17.6715967 },
    { lat: 48.310035, lng: 17.6713421 },
    { lat: 48.3104044, lng: 17.6712181 },
    { lat: 48.3105501, lng: 17.6712355 },
    { lat: 48.3105493, lng: 17.6703241 },
    { lat: 48.3106405, lng: 17.6698508 },
    { lat: 48.3107425, lng: 17.6689749 },
    { lat: 48.3107258, lng: 17.6686364 },
    { lat: 48.310781, lng: 17.6683626 },
    { lat: 48.3110493, lng: 17.6678137 },
    { lat: 48.3113524, lng: 17.6667615 },
    { lat: 48.3118882, lng: 17.665258 },
    { lat: 48.3113705, lng: 17.6647436 },
    { lat: 48.3114008, lng: 17.6646496 },
    { lat: 48.3112688, lng: 17.6641868 },
    { lat: 48.3112197, lng: 17.6634911 },
    { lat: 48.3112108, lng: 17.6631863 },
    { lat: 48.3112973, lng: 17.662985 },
    { lat: 48.3111894, lng: 17.6627583 },
    { lat: 48.311325, lng: 17.6626158 },
    { lat: 48.3113354, lng: 17.6624561 },
    { lat: 48.3112557, lng: 17.6621212 },
    { lat: 48.3113519, lng: 17.6620186 },
    { lat: 48.3114234, lng: 17.6622227 },
    { lat: 48.3116386, lng: 17.6623414 },
    { lat: 48.3117285, lng: 17.6623152 },
    { lat: 48.3117435, lng: 17.6621208 },
    { lat: 48.311876, lng: 17.6620216 },
    { lat: 48.3119639, lng: 17.6620355 },
    { lat: 48.3120341, lng: 17.6618564 },
    { lat: 48.3123792, lng: 17.6619415 },
    { lat: 48.3127975, lng: 17.6605122 },
    { lat: 48.3129263, lng: 17.6596832 },
    { lat: 48.3130664, lng: 17.659092 },
    { lat: 48.3134184, lng: 17.6579523 },
    { lat: 48.3136735, lng: 17.6564946 },
    { lat: 48.314128, lng: 17.6544362 },
    { lat: 48.3157181, lng: 17.648237 },
    { lat: 48.3164215, lng: 17.6452748 },
    { lat: 48.3163763, lng: 17.6451652 },
    { lat: 48.3190647, lng: 17.64258 },
    { lat: 48.3274403, lng: 17.6361414 },
    { lat: 48.3332006, lng: 17.6320228 },
    { lat: 48.3325466, lng: 17.6304068 },
    { lat: 48.3322297, lng: 17.6298395 },
    { lat: 48.3300784, lng: 17.6265568 },
    { lat: 48.3291663, lng: 17.6256652 },
    { lat: 48.328599, lng: 17.6254798 },
    { lat: 48.3285864, lng: 17.6254213 },
    { lat: 48.3282411, lng: 17.625477 },
    { lat: 48.3277701, lng: 17.6254235 },
    { lat: 48.3274161, lng: 17.6252342 },
    { lat: 48.3270631, lng: 17.6249194 },
    { lat: 48.3268907, lng: 17.6246923 },
    { lat: 48.3258227, lng: 17.6234968 },
    { lat: 48.3241942, lng: 17.6221868 },
    { lat: 48.3237482, lng: 17.6220978 },
    { lat: 48.3231921, lng: 17.6221224 },
    { lat: 48.3228705, lng: 17.621931 },
    { lat: 48.3228112, lng: 17.6219028 },
    { lat: 48.322791, lng: 17.6219234 },
    { lat: 48.3207819, lng: 17.6242285 },
    { lat: 48.3188898, lng: 17.6259204 },
    { lat: 48.3179193, lng: 17.6270399 },
    { lat: 48.3171385, lng: 17.6281229 },
    { lat: 48.3161299, lng: 17.6297693 },
    { lat: 48.3150742, lng: 17.6311334 },
    { lat: 48.3142436, lng: 17.6324859 },
    { lat: 48.3122722, lng: 17.6351391 },
    { lat: 48.3117103, lng: 17.635972 },
    { lat: 48.3103734, lng: 17.638305 },
    { lat: 48.3094611, lng: 17.6395815 },
    { lat: 48.3088512, lng: 17.640208 },
    { lat: 48.3084751, lng: 17.6407247 },
    { lat: 48.3077384, lng: 17.6420458 },
    { lat: 48.3083865, lng: 17.6421709 },
    { lat: 48.3083754, lng: 17.6428031 },
    { lat: 48.308545, lng: 17.6435761 },
    { lat: 48.3085525, lng: 17.644072 },
    { lat: 48.3084805, lng: 17.6444546 },
    { lat: 48.3082374, lng: 17.6450398 },
    { lat: 48.3079407, lng: 17.6454247 },
    { lat: 48.3075422, lng: 17.6458034 },
    { lat: 48.3065305, lng: 17.6465379 },
    { lat: 48.3061008, lng: 17.6471941 },
    { lat: 48.3059935, lng: 17.6471391 },
    { lat: 48.3053787, lng: 17.6479725 },
    { lat: 48.304289, lng: 17.6499855 },
    { lat: 48.3034544, lng: 17.6511857 },
    { lat: 48.3025153, lng: 17.6523085 },
    { lat: 48.3020108, lng: 17.6531031 },
    { lat: 48.3011124, lng: 17.6542587 },
    { lat: 48.2983143, lng: 17.6573121 },
    { lat: 48.2960578, lng: 17.6595713 },
    { lat: 48.2951994, lng: 17.660182 },
    { lat: 48.2936091, lng: 17.6610182 },
    { lat: 48.2935203, lng: 17.6600187 },
    { lat: 48.2932762, lng: 17.6585047 },
    { lat: 48.2932871, lng: 17.6577774 },
    { lat: 48.2933702, lng: 17.65696 },
    { lat: 48.2932835, lng: 17.6564081 },
    { lat: 48.2932596, lng: 17.6552701 },
    { lat: 48.2930955, lng: 17.6543313 },
    { lat: 48.2924031, lng: 17.6526203 },
    { lat: 48.2916464, lng: 17.6516102 },
    { lat: 48.2909766, lng: 17.6504407 },
    { lat: 48.2905428, lng: 17.6493404 },
    { lat: 48.2903271, lng: 17.6482288 },
    { lat: 48.2901292, lng: 17.6477043 },
    { lat: 48.2898119, lng: 17.6478987 },
    { lat: 48.2894076, lng: 17.648115 },
    { lat: 48.2892001, lng: 17.6479752 },
    { lat: 48.2890578, lng: 17.6475985 },
    { lat: 48.2889469, lng: 17.6469483 },
    { lat: 48.288923, lng: 17.6464061 },
    { lat: 48.2887254, lng: 17.6459922 },
    { lat: 48.2884373, lng: 17.6450196 },
    { lat: 48.2880116, lng: 17.6444103 },
    { lat: 48.2878574, lng: 17.6442899 },
    { lat: 48.2875933, lng: 17.6442031 },
    { lat: 48.2873919, lng: 17.6440192 },
    { lat: 48.2872728, lng: 17.6440293 },
    { lat: 48.2871004, lng: 17.6438388 },
    { lat: 48.2870641, lng: 17.6436525 },
    { lat: 48.2871234, lng: 17.643486 },
    { lat: 48.2872882, lng: 17.6433936 },
    { lat: 48.287463, lng: 17.6435167 },
    { lat: 48.2876451, lng: 17.6435045 },
    { lat: 48.2879903, lng: 17.6437475 },
    { lat: 48.2880879, lng: 17.6437202 },
    { lat: 48.2882495, lng: 17.643328 },
    { lat: 48.2883115, lng: 17.6425534 },
    { lat: 48.2880694, lng: 17.6417934 },
    { lat: 48.2877732, lng: 17.6413587 },
    { lat: 48.2869394, lng: 17.6408473 },
    { lat: 48.2867021, lng: 17.6407911 },
    { lat: 48.2865506, lng: 17.6412185 },
    { lat: 48.286495, lng: 17.6415918 },
    { lat: 48.2855745, lng: 17.6427522 },
    { lat: 48.2852678, lng: 17.6429582 },
    { lat: 48.2850007, lng: 17.6440422 },
    { lat: 48.2849355, lng: 17.6441716 },
    { lat: 48.2848093, lng: 17.6442302 },
    { lat: 48.2847073, lng: 17.6441885 },
    { lat: 48.2843804, lng: 17.6438735 },
    { lat: 48.2841943, lng: 17.6435006 },
    { lat: 48.2833912, lng: 17.6444843 },
    { lat: 48.2831727, lng: 17.6449417 },
    { lat: 48.2830401, lng: 17.6453841 },
    { lat: 48.2830025, lng: 17.6457531 },
    { lat: 48.2830907, lng: 17.6470839 },
    { lat: 48.2817006, lng: 17.647908 },
    { lat: 48.280978, lng: 17.6486275 },
    { lat: 48.2803979, lng: 17.6468713 },
    { lat: 48.2801146, lng: 17.6471262 },
    { lat: 48.2787033, lng: 17.6490847 },
    { lat: 48.2787582, lng: 17.6493846 },
    { lat: 48.2786191, lng: 17.649506 },
    { lat: 48.2782147, lng: 17.6500781 },
    { lat: 48.2781999, lng: 17.6503811 },
    { lat: 48.2782137, lng: 17.6505281 },
    { lat: 48.2787114, lng: 17.6508648 },
    { lat: 48.278813, lng: 17.6509908 },
    { lat: 48.2788653, lng: 17.6512653 },
    { lat: 48.2787652, lng: 17.6516675 },
    { lat: 48.2785887, lng: 17.6520746 },
    { lat: 48.2783477, lng: 17.6523549 },
    { lat: 48.2783323, lng: 17.652434 },
    { lat: 48.2783707, lng: 17.6526161 },
    { lat: 48.2788011, lng: 17.6534437 },
    { lat: 48.278939, lng: 17.6535526 },
    { lat: 48.2793998, lng: 17.6536968 },
    { lat: 48.2793124, lng: 17.6553269 },
    { lat: 48.279267, lng: 17.655383 },
    { lat: 48.279417, lng: 17.658929 },
    { lat: 48.279504, lng: 17.659547 },
    { lat: 48.279718, lng: 17.662277 },
    { lat: 48.279786, lng: 17.663898 },
    { lat: 48.280036, lng: 17.667061 },
    { lat: 48.280257, lng: 17.669329 },
    { lat: 48.280703, lng: 17.672575 },
    { lat: 48.280694, lng: 17.674966 },
    { lat: 48.280744, lng: 17.676571 },
    { lat: 48.280911, lng: 17.678081 },
    { lat: 48.281105, lng: 17.67881 },
    { lat: 48.282011, lng: 17.680482 },
    { lat: 48.283831, lng: 17.683206 },
    { lat: 48.283772, lng: 17.684336 },
    { lat: 48.28314, lng: 17.68445 },
    { lat: 48.283446, lng: 17.686968 },
    { lat: 48.283544, lng: 17.688175 },
    { lat: 48.283484, lng: 17.688866 },
    { lat: 48.283622, lng: 17.689408 },
    { lat: 48.284291, lng: 17.690342 },
    { lat: 48.284766, lng: 17.690634 },
    { lat: 48.285244, lng: 17.690771 },
    { lat: 48.285382, lng: 17.690695 },
    { lat: 48.285576, lng: 17.690337 },
    { lat: 48.285585, lng: 17.689544 },
    { lat: 48.285536, lng: 17.688882 },
    { lat: 48.285669, lng: 17.688433 },
    { lat: 48.285868, lng: 17.688007 },
    { lat: 48.286285, lng: 17.687601 },
    { lat: 48.286566, lng: 17.687503 },
    { lat: 48.287003, lng: 17.687615 },
    { lat: 48.287131, lng: 17.6877 },
    { lat: 48.287439, lng: 17.688082 },
    { lat: 48.28767, lng: 17.688653 },
    { lat: 48.287703, lng: 17.688987 },
    { lat: 48.287651, lng: 17.689691 },
    { lat: 48.287684, lng: 17.690517 },
    { lat: 48.287655, lng: 17.691169 },
    { lat: 48.287775, lng: 17.691674 },
    { lat: 48.287904, lng: 17.691952 },
    { lat: 48.288143, lng: 17.692059 },
    { lat: 48.288757, lng: 17.691937 },
    { lat: 48.289542, lng: 17.690946 },
    { lat: 48.289733, lng: 17.690798 },
    { lat: 48.28997, lng: 17.690774 },
    { lat: 48.290449, lng: 17.690918 },
    { lat: 48.290735, lng: 17.691334 },
    { lat: 48.291514, lng: 17.693501 },
    { lat: 48.292178, lng: 17.69418 },
    { lat: 48.292767, lng: 17.694451 },
    { lat: 48.293904, lng: 17.694524 },
    { lat: 48.294646, lng: 17.695029 },
    { lat: 48.294869, lng: 17.695393 },
    { lat: 48.295328, lng: 17.696452 },
    { lat: 48.295386, lng: 17.696721 },
    { lat: 48.295364, lng: 17.697357 },
    { lat: 48.295276, lng: 17.69787 },
    { lat: 48.295146, lng: 17.698283 },
    { lat: 48.294566, lng: 17.701621 },
    { lat: 48.294604, lng: 17.702123 },
    { lat: 48.294683, lng: 17.702389 },
    { lat: 48.29491, lng: 17.702771 },
    { lat: 48.29518, lng: 17.703051 },
    { lat: 48.295415, lng: 17.703106 },
    { lat: 48.295605, lng: 17.703073 },
    { lat: 48.295887, lng: 17.702781 },
    { lat: 48.296072, lng: 17.702253 },
    { lat: 48.296439, lng: 17.700714 },
    { lat: 48.296784, lng: 17.699943 },
    { lat: 48.296944, lng: 17.699705 },
    { lat: 48.297327, lng: 17.699461 },
    { lat: 48.297955, lng: 17.699374 },
    { lat: 48.298294, lng: 17.699231 },
    { lat: 48.300268, lng: 17.697629 },
    { lat: 48.30056, lng: 17.697203 },
    { lat: 48.300993, lng: 17.696196 },
    { lat: 48.301317, lng: 17.69578 },
    { lat: 48.300529, lng: 17.6945 },
    { lat: 48.302133, lng: 17.691232 },
    { lat: 48.302696, lng: 17.689692 },
    { lat: 48.303217, lng: 17.688925 },
    { lat: 48.303925, lng: 17.687416 },
    { lat: 48.304345, lng: 17.687868 },
  ],
  DolnéDubové: [
    { lat: 48.4994855, lng: 17.6140411 },
    { lat: 48.4999924, lng: 17.6126971 },
    { lat: 48.5003914, lng: 17.6120353 },
    { lat: 48.5005981, lng: 17.6115522 },
    { lat: 48.5007906, lng: 17.6109522 },
    { lat: 48.5010322, lng: 17.6098211 },
    { lat: 48.5013442, lng: 17.6088947 },
    { lat: 48.5016064, lng: 17.6084478 },
    { lat: 48.5020828, lng: 17.6079797 },
    { lat: 48.5024238, lng: 17.6075333 },
    { lat: 48.5030482, lng: 17.6063483 },
    { lat: 48.5037909, lng: 17.6045328 },
    { lat: 48.5040289, lng: 17.6040818 },
    { lat: 48.5046148, lng: 17.6039701 },
    { lat: 48.504853, lng: 17.6041476 },
    { lat: 48.5051442, lng: 17.6039473 },
    { lat: 48.5055146, lng: 17.6031423 },
    { lat: 48.5059163, lng: 17.6019202 },
    { lat: 48.5063107, lng: 17.6009733 },
    { lat: 48.5075695, lng: 17.5984077 },
    { lat: 48.5084888, lng: 17.5962949 },
    { lat: 48.5094965, lng: 17.5941364 },
    { lat: 48.5103104, lng: 17.5928644 },
    { lat: 48.5115331, lng: 17.5912472 },
    { lat: 48.5132512, lng: 17.5891628 },
    { lat: 48.5150216, lng: 17.5864406 },
    { lat: 48.5160954, lng: 17.5849552 },
    { lat: 48.5162107, lng: 17.5848336 },
    { lat: 48.5148976, lng: 17.5831738 },
    { lat: 48.5132449, lng: 17.5808058 },
    { lat: 48.5119613, lng: 17.5791301 },
    { lat: 48.5102612, lng: 17.5772984 },
    { lat: 48.5098224, lng: 17.5770386 },
    { lat: 48.5089935, lng: 17.5760355 },
    { lat: 48.5078171, lng: 17.5749345 },
    { lat: 48.5071253, lng: 17.574139 },
    { lat: 48.5063932, lng: 17.5732055 },
    { lat: 48.5064924, lng: 17.572989 },
    { lat: 48.5064127, lng: 17.5724958 },
    { lat: 48.5050782, lng: 17.5709835 },
    { lat: 48.5040724, lng: 17.569651 },
    { lat: 48.5035325, lng: 17.5686519 },
    { lat: 48.5032645, lng: 17.5683766 },
    { lat: 48.5030396, lng: 17.5685542 },
    { lat: 48.5026679, lng: 17.5681029 },
    { lat: 48.500676, lng: 17.5652177 },
    { lat: 48.499979, lng: 17.5641426 },
    { lat: 48.4999215, lng: 17.5642705 },
    { lat: 48.4997987, lng: 17.5645431 },
    { lat: 48.4994015, lng: 17.5650113 },
    { lat: 48.4989433, lng: 17.5657992 },
    { lat: 48.4981296, lng: 17.5666298 },
    { lat: 48.4973863, lng: 17.5675154 },
    { lat: 48.4965792, lng: 17.5682333 },
    { lat: 48.4955187, lng: 17.5696404 },
    { lat: 48.4950002, lng: 17.5705656 },
    { lat: 48.4943804, lng: 17.5719408 },
    { lat: 48.4939143, lng: 17.572783 },
    { lat: 48.4917302, lng: 17.5751551 },
    { lat: 48.4908533, lng: 17.5759155 },
    { lat: 48.4905254, lng: 17.5763725 },
    { lat: 48.4901419, lng: 17.5770579 },
    { lat: 48.4888532, lng: 17.5783567 },
    { lat: 48.4881637, lng: 17.5793109 },
    { lat: 48.4879826, lng: 17.5795068 },
    { lat: 48.4876479, lng: 17.5798688 },
    { lat: 48.4860511, lng: 17.5819021 },
    { lat: 48.4850352, lng: 17.5825718 },
    { lat: 48.4837635, lng: 17.5834994 },
    { lat: 48.4834337, lng: 17.5837997 },
    { lat: 48.4827746, lng: 17.5845731 },
    { lat: 48.4818612, lng: 17.5854868 },
    { lat: 48.4813978, lng: 17.5861418 },
    { lat: 48.480925, lng: 17.587006 },
    { lat: 48.4807488, lng: 17.5871646 },
    { lat: 48.4802235, lng: 17.5873782 },
    { lat: 48.4799693, lng: 17.587411 },
    { lat: 48.4796601, lng: 17.587416 },
    { lat: 48.4792921, lng: 17.5873259 },
    { lat: 48.4791977, lng: 17.5876028 },
    { lat: 48.4789267, lng: 17.5888908 },
    { lat: 48.4786504, lng: 17.5895926 },
    { lat: 48.4784113, lng: 17.5900439 },
    { lat: 48.4778586, lng: 17.5909116 },
    { lat: 48.475101, lng: 17.5948477 },
    { lat: 48.4721994, lng: 17.5974153 },
    { lat: 48.4728193, lng: 17.5982549 },
    { lat: 48.4735183, lng: 17.5990612 },
    { lat: 48.4746934, lng: 17.6000932 },
    { lat: 48.4764821, lng: 17.6018829 },
    { lat: 48.4770631, lng: 17.6025897 },
    { lat: 48.4782046, lng: 17.6043209 },
    { lat: 48.47869, lng: 17.6050572 },
    { lat: 48.4803638, lng: 17.6072159 },
    { lat: 48.4820149, lng: 17.6094951 },
    { lat: 48.4828515, lng: 17.6103874 },
    { lat: 48.484207, lng: 17.6115429 },
    { lat: 48.4853475, lng: 17.6126358 },
    { lat: 48.486372, lng: 17.6139318 },
    { lat: 48.4878318, lng: 17.6161362 },
    { lat: 48.4893593, lng: 17.61789 },
    { lat: 48.4894819, lng: 17.6182268 },
    { lat: 48.4906287, lng: 17.6195262 },
    { lat: 48.4930435, lng: 17.6219813 },
    { lat: 48.493918, lng: 17.6206023 },
    { lat: 48.4943909, lng: 17.6210149 },
    { lat: 48.4945679, lng: 17.620597 },
    { lat: 48.4952522, lng: 17.6193938 },
    { lat: 48.4959775, lng: 17.6183033 },
    { lat: 48.4975261, lng: 17.6165334 },
    { lat: 48.4979706, lng: 17.6161318 },
    { lat: 48.4981912, lng: 17.6157793 },
    { lat: 48.4986362, lng: 17.615331 },
    { lat: 48.4993633, lng: 17.6143441 },
    { lat: 48.4994855, lng: 17.6140411 },
  ],
  Bíňovce: [
    { lat: 48.5015346, lng: 17.5183178 },
    { lat: 48.5019471, lng: 17.5176133 },
    { lat: 48.5026757, lng: 17.5169255 },
    { lat: 48.503, lng: 17.5163117 },
    { lat: 48.5032264, lng: 17.5161252 },
    { lat: 48.5032822, lng: 17.5159907 },
    { lat: 48.5035813, lng: 17.5157936 },
    { lat: 48.5038488, lng: 17.5154007 },
    { lat: 48.5040239, lng: 17.5152596 },
    { lat: 48.5046343, lng: 17.5140115 },
    { lat: 48.5052824, lng: 17.5135126 },
    { lat: 48.5054817, lng: 17.5132203 },
    { lat: 48.5059004, lng: 17.5129126 },
    { lat: 48.5063919, lng: 17.5123529 },
    { lat: 48.5066355, lng: 17.5119755 },
    { lat: 48.5070977, lng: 17.5115711 },
    { lat: 48.5077429, lng: 17.5108346 },
    { lat: 48.5080636, lng: 17.5103904 },
    { lat: 48.5082071, lng: 17.5100213 },
    { lat: 48.5083078, lng: 17.5099202 },
    { lat: 48.5085705, lng: 17.5098235 },
    { lat: 48.5093133, lng: 17.5091629 },
    { lat: 48.5096288, lng: 17.508993 },
    { lat: 48.5103568, lng: 17.5081035 },
    { lat: 48.5108198, lng: 17.507676 },
    { lat: 48.5111034, lng: 17.5075738 },
    { lat: 48.5112428, lng: 17.5072801 },
    { lat: 48.5115994, lng: 17.5069581 },
    { lat: 48.5116709, lng: 17.5068025 },
    { lat: 48.5120101, lng: 17.5065436 },
    { lat: 48.512259, lng: 17.5064463 },
    { lat: 48.5126381, lng: 17.5059605 },
    { lat: 48.5131404, lng: 17.5054444 },
    { lat: 48.5135862, lng: 17.5052983 },
    { lat: 48.5136715, lng: 17.5053779 },
    { lat: 48.5139102, lng: 17.5052618 },
    { lat: 48.5140739, lng: 17.5049421 },
    { lat: 48.5129346, lng: 17.502401 },
    { lat: 48.5118664, lng: 17.4992573 },
    { lat: 48.5111345, lng: 17.498215 },
    { lat: 48.5113376, lng: 17.4978226 },
    { lat: 48.5115, lng: 17.4972203 },
    { lat: 48.5114304, lng: 17.4969789 },
    { lat: 48.5114718, lng: 17.4967061 },
    { lat: 48.5115477, lng: 17.496605 },
    { lat: 48.5118163, lng: 17.4958242 },
    { lat: 48.5120463, lng: 17.494775 },
    { lat: 48.5120312, lng: 17.4944356 },
    { lat: 48.5118552, lng: 17.4939596 },
    { lat: 48.5117404, lng: 17.4934715 },
    { lat: 48.5115474, lng: 17.4930151 },
    { lat: 48.5110464, lng: 17.4922642 },
    { lat: 48.5106287, lng: 17.4917716 },
    { lat: 48.510434, lng: 17.4910772 },
    { lat: 48.5104176, lng: 17.4909096 },
    { lat: 48.5104933, lng: 17.4905026 },
    { lat: 48.51099, lng: 17.4880987 },
    { lat: 48.5109216, lng: 17.4875622 },
    { lat: 48.5104668, lng: 17.4863473 },
    { lat: 48.5102455, lng: 17.4859078 },
    { lat: 48.5098343, lng: 17.4853962 },
    { lat: 48.5096592, lng: 17.4853069 },
    { lat: 48.5093853, lng: 17.4854026 },
    { lat: 48.5090056, lng: 17.4848867 },
    { lat: 48.5088316, lng: 17.4843246 },
    { lat: 48.5087273, lng: 17.4817615 },
    { lat: 48.5087479, lng: 17.4814116 },
    { lat: 48.5088021, lng: 17.4812534 },
    { lat: 48.5086992, lng: 17.480795 },
    { lat: 48.5087605, lng: 17.480374 },
    { lat: 48.5086923, lng: 17.4798578 },
    { lat: 48.5086637, lng: 17.4790079 },
    { lat: 48.5087508, lng: 17.4785871 },
    { lat: 48.5089487, lng: 17.4780978 },
    { lat: 48.5090695, lng: 17.477302 },
    { lat: 48.5092404, lng: 17.4769406 },
    { lat: 48.5091945, lng: 17.4760657 },
    { lat: 48.5088843, lng: 17.4757208 },
    { lat: 48.5088715, lng: 17.4752026 },
    { lat: 48.5086648, lng: 17.4750795 },
    { lat: 48.5084954, lng: 17.4747789 },
    { lat: 48.5086408, lng: 17.4740013 },
    { lat: 48.5089186, lng: 17.4733287 },
    { lat: 48.5090081, lng: 17.473211 },
    { lat: 48.5089776, lng: 17.4731236 },
    { lat: 48.5090939, lng: 17.4728231 },
    { lat: 48.5091221, lng: 17.4726125 },
    { lat: 48.5089999, lng: 17.4727328 },
    { lat: 48.508137, lng: 17.4739096 },
    { lat: 48.5079086, lng: 17.4744001 },
    { lat: 48.5073301, lng: 17.4745358 },
    { lat: 48.5072459, lng: 17.4746695 },
    { lat: 48.5072402, lng: 17.4747715 },
    { lat: 48.5070732, lng: 17.4748808 },
    { lat: 48.5069384, lng: 17.474608 },
    { lat: 48.5066678, lng: 17.4742819 },
    { lat: 48.5062777, lng: 17.4739561 },
    { lat: 48.5062378, lng: 17.4740053 },
    { lat: 48.5061014, lng: 17.4739386 },
    { lat: 48.5059571, lng: 17.4736967 },
    { lat: 48.5058215, lng: 17.4736256 },
    { lat: 48.5052445, lng: 17.4737843 },
    { lat: 48.5051271, lng: 17.4748238 },
    { lat: 48.5050147, lng: 17.4753964 },
    { lat: 48.5049511, lng: 17.4753907 },
    { lat: 48.5049556, lng: 17.4752497 },
    { lat: 48.5048958, lng: 17.475215 },
    { lat: 48.5046965, lng: 17.4754028 },
    { lat: 48.5044859, lng: 17.4753222 },
    { lat: 48.5044392, lng: 17.4753874 },
    { lat: 48.5043994, lng: 17.4756628 },
    { lat: 48.5043338, lng: 17.4755591 },
    { lat: 48.504222, lng: 17.4755575 },
    { lat: 48.5042246, lng: 17.4758446 },
    { lat: 48.5042626, lng: 17.4759103 },
    { lat: 48.5043639, lng: 17.4758838 },
    { lat: 48.5043818, lng: 17.4759535 },
    { lat: 48.5042848, lng: 17.4760609 },
    { lat: 48.5041539, lng: 17.4760824 },
    { lat: 48.5040496, lng: 17.4762489 },
    { lat: 48.5038926, lng: 17.4762147 },
    { lat: 48.503915, lng: 17.4759287 },
    { lat: 48.5038067, lng: 17.4757023 },
    { lat: 48.5037231, lng: 17.4757372 },
    { lat: 48.5035515, lng: 17.4755648 },
    { lat: 48.5035075, lng: 17.4755823 },
    { lat: 48.503418, lng: 17.4752758 },
    { lat: 48.502645, lng: 17.4756399 },
    { lat: 48.5026072, lng: 17.4761432 },
    { lat: 48.5024966, lng: 17.476212 },
    { lat: 48.5024427, lng: 17.4763751 },
    { lat: 48.5021611, lng: 17.4764755 },
    { lat: 48.5021143, lng: 17.4764687 },
    { lat: 48.5020974, lng: 17.476327 },
    { lat: 48.5019576, lng: 17.4760855 },
    { lat: 48.5018847, lng: 17.4761997 },
    { lat: 48.5018908, lng: 17.476435 },
    { lat: 48.5018182, lng: 17.476432 },
    { lat: 48.5016966, lng: 17.4762253 },
    { lat: 48.5016236, lng: 17.4763719 },
    { lat: 48.5015794, lng: 17.4762466 },
    { lat: 48.5016383, lng: 17.4760553 },
    { lat: 48.5014364, lng: 17.4759037 },
    { lat: 48.5014181, lng: 17.4758102 },
    { lat: 48.5013898, lng: 17.4757923 },
    { lat: 48.5012736, lng: 17.4760923 },
    { lat: 48.5011662, lng: 17.4759148 },
    { lat: 48.5011158, lng: 17.4759222 },
    { lat: 48.5011001, lng: 17.4757111 },
    { lat: 48.5009249, lng: 17.4757612 },
    { lat: 48.5008496, lng: 17.4756991 },
    { lat: 48.5007776, lng: 17.4757715 },
    { lat: 48.5005405, lng: 17.4758273 },
    { lat: 48.5005204, lng: 17.4759203 },
    { lat: 48.5003755, lng: 17.4760372 },
    { lat: 48.500283, lng: 17.4763024 },
    { lat: 48.5001765, lng: 17.4763652 },
    { lat: 48.5000673, lng: 17.4765851 },
    { lat: 48.4996047, lng: 17.4765394 },
    { lat: 48.4992343, lng: 17.4767342 },
    { lat: 48.4991321, lng: 17.4766718 },
    { lat: 48.4990356, lng: 17.4767688 },
    { lat: 48.4988956, lng: 17.4767008 },
    { lat: 48.4988632, lng: 17.4765976 },
    { lat: 48.4987851, lng: 17.4767553 },
    { lat: 48.4986144, lng: 17.4767512 },
    { lat: 48.4984354, lng: 17.4769902 },
    { lat: 48.4984109, lng: 17.477066 },
    { lat: 48.4984546, lng: 17.4772185 },
    { lat: 48.4983875, lng: 17.4773864 },
    { lat: 48.4981839, lng: 17.4772938 },
    { lat: 48.4980092, lng: 17.4773708 },
    { lat: 48.4977914, lng: 17.4770933 },
    { lat: 48.4976409, lng: 17.4771415 },
    { lat: 48.4976327, lng: 17.4772421 },
    { lat: 48.4975194, lng: 17.4771837 },
    { lat: 48.4974436, lng: 17.4772677 },
    { lat: 48.4972144, lng: 17.4771632 },
    { lat: 48.4971006, lng: 17.4772741 },
    { lat: 48.4967064, lng: 17.4771871 },
    { lat: 48.4966103, lng: 17.4770786 },
    { lat: 48.4965661, lng: 17.4769355 },
    { lat: 48.496507, lng: 17.4770003 },
    { lat: 48.4964742, lng: 17.4772454 },
    { lat: 48.4963668, lng: 17.4774013 },
    { lat: 48.496329, lng: 17.477394 },
    { lat: 48.4962562, lng: 17.4771225 },
    { lat: 48.4962177, lng: 17.4771196 },
    { lat: 48.4961182, lng: 17.4774363 },
    { lat: 48.4959918, lng: 17.4772763 },
    { lat: 48.4960034, lng: 17.4771251 },
    { lat: 48.4958419, lng: 17.4771246 },
    { lat: 48.4956192, lng: 17.4772606 },
    { lat: 48.495506, lng: 17.4772592 },
    { lat: 48.4954204, lng: 17.4774009 },
    { lat: 48.4953989, lng: 17.477648 },
    { lat: 48.4952477, lng: 17.4778447 },
    { lat: 48.4951453, lng: 17.477746 },
    { lat: 48.4951568, lng: 17.4776407 },
    { lat: 48.4949719, lng: 17.4775401 },
    { lat: 48.4948648, lng: 17.47779 },
    { lat: 48.4947525, lng: 17.4777468 },
    { lat: 48.4946566, lng: 17.4773965 },
    { lat: 48.4946516, lng: 17.4772152 },
    { lat: 48.4945464, lng: 17.4771244 },
    { lat: 48.4943712, lng: 17.4772965 },
    { lat: 48.4941589, lng: 17.4770852 },
    { lat: 48.4941011, lng: 17.4773842 },
    { lat: 48.4940464, lng: 17.4774466 },
    { lat: 48.4937883, lng: 17.4774698 },
    { lat: 48.4937441, lng: 17.4773274 },
    { lat: 48.4936204, lng: 17.4774244 },
    { lat: 48.4931943, lng: 17.4775262 },
    { lat: 48.4931318, lng: 17.4774477 },
    { lat: 48.4931319, lng: 17.4779778 },
    { lat: 48.4930206, lng: 17.4780908 },
    { lat: 48.4928255, lng: 17.4779739 },
    { lat: 48.4928331, lng: 17.4776694 },
    { lat: 48.4926705, lng: 17.4778977 },
    { lat: 48.4925248, lng: 17.4778096 },
    { lat: 48.4923736, lng: 17.4775307 },
    { lat: 48.4921508, lng: 17.4777532 },
    { lat: 48.4921363, lng: 17.4776349 },
    { lat: 48.4919756, lng: 17.4774866 },
    { lat: 48.4919273, lng: 17.4776524 },
    { lat: 48.4917875, lng: 17.4777237 },
    { lat: 48.4917993, lng: 17.4779082 },
    { lat: 48.4919032, lng: 17.4779636 },
    { lat: 48.4919107, lng: 17.4780251 },
    { lat: 48.4918261, lng: 17.4780631 },
    { lat: 48.4917328, lng: 17.4780001 },
    { lat: 48.491676, lng: 17.4781187 },
    { lat: 48.491525, lng: 17.477823 },
    { lat: 48.4913968, lng: 17.4780275 },
    { lat: 48.4913029, lng: 17.4780575 },
    { lat: 48.491344, lng: 17.4782773 },
    { lat: 48.4912748, lng: 17.4783418 },
    { lat: 48.4911455, lng: 17.4782815 },
    { lat: 48.4910689, lng: 17.4781619 },
    { lat: 48.4909601, lng: 17.4783654 },
    { lat: 48.4908691, lng: 17.4781977 },
    { lat: 48.4907669, lng: 17.4781869 },
    { lat: 48.4908668, lng: 17.4780026 },
    { lat: 48.4906556, lng: 17.4780902 },
    { lat: 48.4906625, lng: 17.4778802 },
    { lat: 48.4906296, lng: 17.4778248 },
    { lat: 48.4904362, lng: 17.478016 },
    { lat: 48.4903914, lng: 17.4782322 },
    { lat: 48.4901591, lng: 17.4780085 },
    { lat: 48.4901964, lng: 17.4777271 },
    { lat: 48.4903694, lng: 17.4775539 },
    { lat: 48.490154, lng: 17.4769447 },
    { lat: 48.4900731, lng: 17.4768275 },
    { lat: 48.4892212, lng: 17.4774226 },
    { lat: 48.4887483, lng: 17.4775845 },
    { lat: 48.4887946, lng: 17.4777078 },
    { lat: 48.488599, lng: 17.4778469 },
    { lat: 48.4884443, lng: 17.4781987 },
    { lat: 48.4882366, lng: 17.4781448 },
    { lat: 48.488128, lng: 17.4781962 },
    { lat: 48.4880356, lng: 17.4783566 },
    { lat: 48.4877461, lng: 17.4782117 },
    { lat: 48.4876153, lng: 17.4783532 },
    { lat: 48.487469, lng: 17.4783641 },
    { lat: 48.4875279, lng: 17.4785959 },
    { lat: 48.4874758, lng: 17.478681 },
    { lat: 48.4872838, lng: 17.478679 },
    { lat: 48.4871559, lng: 17.4791173 },
    { lat: 48.4870716, lng: 17.4792264 },
    { lat: 48.487009, lng: 17.4790628 },
    { lat: 48.4868858, lng: 17.4791854 },
    { lat: 48.4866161, lng: 17.4790479 },
    { lat: 48.4864837, lng: 17.4791487 },
    { lat: 48.4864958, lng: 17.4792487 },
    { lat: 48.4863624, lng: 17.4791773 },
    { lat: 48.4862839, lng: 17.4792029 },
    { lat: 48.4862398, lng: 17.4792909 },
    { lat: 48.4862703, lng: 17.4794114 },
    { lat: 48.4861919, lng: 17.4794772 },
    { lat: 48.4862086, lng: 17.4796311 },
    { lat: 48.4861535, lng: 17.4797427 },
    { lat: 48.4861995, lng: 17.4798585 },
    { lat: 48.4861851, lng: 17.4800575 },
    { lat: 48.4860261, lng: 17.480062 },
    { lat: 48.4859704, lng: 17.4802161 },
    { lat: 48.4858614, lng: 17.4802216 },
    { lat: 48.4857293, lng: 17.479925 },
    { lat: 48.4855162, lng: 17.4798242 },
    { lat: 48.485459, lng: 17.4800763 },
    { lat: 48.4853676, lng: 17.4802007 },
    { lat: 48.485366, lng: 17.4805452 },
    { lat: 48.4852278, lng: 17.4806628 },
    { lat: 48.485254, lng: 17.4809316 },
    { lat: 48.4849152, lng: 17.4810549 },
    { lat: 48.4848119, lng: 17.4810129 },
    { lat: 48.484638, lng: 17.4811681 },
    { lat: 48.4846458, lng: 17.481257 },
    { lat: 48.4847434, lng: 17.4812293 },
    { lat: 48.4847786, lng: 17.4812836 },
    { lat: 48.4845448, lng: 17.4818295 },
    { lat: 48.4843965, lng: 17.4818051 },
    { lat: 48.4842107, lng: 17.481392 },
    { lat: 48.4841299, lng: 17.4813496 },
    { lat: 48.4840253, lng: 17.4810216 },
    { lat: 48.4841159, lng: 17.4809167 },
    { lat: 48.4839762, lng: 17.4809241 },
    { lat: 48.4839177, lng: 17.4810337 },
    { lat: 48.4838528, lng: 17.4810442 },
    { lat: 48.4835597, lng: 17.480876 },
    { lat: 48.4832914, lng: 17.4810057 },
    { lat: 48.4831756, lng: 17.4809605 },
    { lat: 48.4830738, lng: 17.4807824 },
    { lat: 48.4830077, lng: 17.4804726 },
    { lat: 48.4830847, lng: 17.4801966 },
    { lat: 48.4827825, lng: 17.4791632 },
    { lat: 48.4830934, lng: 17.4786051 },
    { lat: 48.4826273, lng: 17.4780274 },
    { lat: 48.4818002, lng: 17.4783749 },
    { lat: 48.4817457, lng: 17.4783522 },
    { lat: 48.4815117, lng: 17.4796331 },
    { lat: 48.4811986, lng: 17.48067 },
    { lat: 48.4807069, lng: 17.4817384 },
    { lat: 48.4798999, lng: 17.4840721 },
    { lat: 48.4802965, lng: 17.4845321 },
    { lat: 48.4807269, lng: 17.4851849 },
    { lat: 48.4814703, lng: 17.4865821 },
    { lat: 48.4818609, lng: 17.4875845 },
    { lat: 48.4807149, lng: 17.4883989 },
    { lat: 48.4808518, lng: 17.4885275 },
    { lat: 48.4812281, lng: 17.4886199 },
    { lat: 48.481181, lng: 17.4888884 },
    { lat: 48.481441, lng: 17.4891241 },
    { lat: 48.4822432, lng: 17.4906973 },
    { lat: 48.4822399, lng: 17.4909271 },
    { lat: 48.4823349, lng: 17.4910877 },
    { lat: 48.4823918, lng: 17.4916434 },
    { lat: 48.4826671, lng: 17.4917954 },
    { lat: 48.4827768, lng: 17.4917456 },
    { lat: 48.4828803, lng: 17.4919353 },
    { lat: 48.4828657, lng: 17.4921998 },
    { lat: 48.4830145, lng: 17.4923345 },
    { lat: 48.4831529, lng: 17.4926448 },
    { lat: 48.4832626, lng: 17.4927361 },
    { lat: 48.4834409, lng: 17.4926483 },
    { lat: 48.4835286, lng: 17.4929236 },
    { lat: 48.4836473, lng: 17.4929071 },
    { lat: 48.4837119, lng: 17.4930327 },
    { lat: 48.4836679, lng: 17.4931046 },
    { lat: 48.4837409, lng: 17.4934158 },
    { lat: 48.4837753, lng: 17.4934774 },
    { lat: 48.4838915, lng: 17.4934061 },
    { lat: 48.483944, lng: 17.4935726 },
    { lat: 48.4840664, lng: 17.4936314 },
    { lat: 48.4841169, lng: 17.4937668 },
    { lat: 48.4843809, lng: 17.494065 },
    { lat: 48.4851184, lng: 17.4958482 },
    { lat: 48.4854749, lng: 17.4958058 },
    { lat: 48.4856021, lng: 17.4958696 },
    { lat: 48.4856808, lng: 17.4963559 },
    { lat: 48.4858279, lng: 17.4961855 },
    { lat: 48.4858864, lng: 17.4962193 },
    { lat: 48.4859643, lng: 17.4963743 },
    { lat: 48.4860585, lng: 17.496789 },
    { lat: 48.4860682, lng: 17.4975964 },
    { lat: 48.4862809, lng: 17.4983599 },
    { lat: 48.4864463, lng: 17.4985972 },
    { lat: 48.4864789, lng: 17.498737 },
    { lat: 48.4866856, lng: 17.4988787 },
    { lat: 48.4868109, lng: 17.4988588 },
    { lat: 48.4872343, lng: 17.4995807 },
    { lat: 48.4872743, lng: 17.4996956 },
    { lat: 48.4872233, lng: 17.5000455 },
    { lat: 48.4872763, lng: 17.5001849 },
    { lat: 48.4875424, lng: 17.5003721 },
    { lat: 48.4878568, lng: 17.5008033 },
    { lat: 48.4880833, lng: 17.5016064 },
    { lat: 48.4884606, lng: 17.5016266 },
    { lat: 48.4885894, lng: 17.5015389 },
    { lat: 48.4888552, lng: 17.502006 },
    { lat: 48.489028, lng: 17.5020066 },
    { lat: 48.4891195, lng: 17.5021308 },
    { lat: 48.4894344, lng: 17.5023408 },
    { lat: 48.4895755, lng: 17.5025152 },
    { lat: 48.4897681, lng: 17.503036 },
    { lat: 48.4900771, lng: 17.5032579 },
    { lat: 48.4901459, lng: 17.5037154 },
    { lat: 48.4900444, lng: 17.5043008 },
    { lat: 48.4901857, lng: 17.5045157 },
    { lat: 48.4902877, lng: 17.5048232 },
    { lat: 48.490229, lng: 17.5049826 },
    { lat: 48.4902554, lng: 17.5051657 },
    { lat: 48.4913338, lng: 17.5065247 },
    { lat: 48.4916747, lng: 17.5079006 },
    { lat: 48.4923791, lng: 17.5090809 },
    { lat: 48.4930358, lng: 17.5099373 },
    { lat: 48.4924445, lng: 17.5105846 },
    { lat: 48.4923627, lng: 17.510841 },
    { lat: 48.492341, lng: 17.5110827 },
    { lat: 48.4920162, lng: 17.5116224 },
    { lat: 48.4910007, lng: 17.5124673 },
    { lat: 48.4917762, lng: 17.5138955 },
    { lat: 48.4918399, lng: 17.5138838 },
    { lat: 48.4919046, lng: 17.5138712 },
    { lat: 48.4926951, lng: 17.5150926 },
    { lat: 48.4931816, lng: 17.5151426 },
    { lat: 48.4937414, lng: 17.5145334 },
    { lat: 48.4954299, lng: 17.5163625 },
    { lat: 48.4957848, lng: 17.5159929 },
    { lat: 48.4980488, lng: 17.5197779 },
    { lat: 48.4991641, lng: 17.5214888 },
    { lat: 48.4995012, lng: 17.520948 },
    { lat: 48.5000856, lng: 17.5201967 },
    { lat: 48.500323, lng: 17.5198074 },
    { lat: 48.5005549, lng: 17.5192829 },
    { lat: 48.5008139, lng: 17.5189368 },
    { lat: 48.5012912, lng: 17.518609 },
    { lat: 48.501464, lng: 17.5183461 },
    { lat: 48.5015346, lng: 17.5183178 },
  ],
  SlovenskáNováVes: [
    { lat: 48.3117704, lng: 17.5422678 },
    { lat: 48.3117634, lng: 17.5421585 },
    { lat: 48.3114235, lng: 17.5415041 },
    { lat: 48.3109534, lng: 17.5407385 },
    { lat: 48.3099627, lng: 17.5393161 },
    { lat: 48.3093328, lng: 17.5382741 },
    { lat: 48.3088454, lng: 17.5375753 },
    { lat: 48.305247, lng: 17.5329547 },
    { lat: 48.303176, lng: 17.5303948 },
    { lat: 48.2999553, lng: 17.5261015 },
    { lat: 48.2976676, lng: 17.5227008 },
    { lat: 48.2974071, lng: 17.5222803 },
    { lat: 48.2973639, lng: 17.5221198 },
    { lat: 48.2972867, lng: 17.5220969 },
    { lat: 48.2972545, lng: 17.5219256 },
    { lat: 48.2971119, lng: 17.5217641 },
    { lat: 48.2970385, lng: 17.5216145 },
    { lat: 48.2969146, lng: 17.5213619 },
    { lat: 48.2967404, lng: 17.5212174 },
    { lat: 48.2955362, lng: 17.5195516 },
    { lat: 48.2945137, lng: 17.5183999 },
    { lat: 48.2932711, lng: 17.5168404 },
    { lat: 48.2906516, lng: 17.5137826 },
    { lat: 48.2885116, lng: 17.5109979 },
    { lat: 48.2858106, lng: 17.5072729 },
    { lat: 48.2848863, lng: 17.5062085 },
    { lat: 48.2848728, lng: 17.5061924 },
    { lat: 48.279077, lng: 17.513744 },
    { lat: 48.276893, lng: 17.517034 },
    { lat: 48.2763542, lng: 17.5178316 },
    { lat: 48.274387, lng: 17.520861 },
    { lat: 48.272867, lng: 17.523485 },
    { lat: 48.2728118, lng: 17.5235718 },
    { lat: 48.271054, lng: 17.526425 },
    { lat: 48.270335, lng: 17.527507 },
    { lat: 48.2697281, lng: 17.5283655 },
    { lat: 48.2697216, lng: 17.52837 },
    { lat: 48.2726241, lng: 17.5319402 },
    { lat: 48.2753887, lng: 17.5349476 },
    { lat: 48.2775797, lng: 17.5375661 },
    { lat: 48.2796092, lng: 17.5398115 },
    { lat: 48.2817703, lng: 17.5420184 },
    { lat: 48.2826523, lng: 17.5432043 },
    { lat: 48.2836179, lng: 17.5427607 },
    { lat: 48.283819, lng: 17.5434422 },
    { lat: 48.284344, lng: 17.5452209 },
    { lat: 48.2849273, lng: 17.545622 },
    { lat: 48.2866656, lng: 17.5470595 },
    { lat: 48.2874828, lng: 17.5480254 },
    { lat: 48.2910918, lng: 17.5529477 },
    { lat: 48.2928334, lng: 17.5552749 },
    { lat: 48.2931081, lng: 17.5555452 },
    { lat: 48.2936415, lng: 17.5559767 },
    { lat: 48.293659, lng: 17.555932 },
    { lat: 48.2953425, lng: 17.5517096 },
    { lat: 48.2965627, lng: 17.5488815 },
    { lat: 48.2970998, lng: 17.5493366 },
    { lat: 48.2976878, lng: 17.5501454 },
    { lat: 48.2998092, lng: 17.5535754 },
    { lat: 48.3012166, lng: 17.5524288 },
    { lat: 48.3022266, lng: 17.5512286 },
    { lat: 48.3027374, lng: 17.5519961 },
    { lat: 48.304901, lng: 17.5495298 },
    { lat: 48.3082262, lng: 17.5458897 },
    { lat: 48.3079942, lng: 17.5457208 },
    { lat: 48.3117704, lng: 17.5422678 },
  ],
  Trstín: [
    { lat: 48.5369549, lng: 17.5073573 },
    { lat: 48.5369516, lng: 17.5073389 },
    { lat: 48.5368561, lng: 17.506851 },
    { lat: 48.5378842, lng: 17.5054438 },
    { lat: 48.5390451, lng: 17.5036739 },
    { lat: 48.5397089, lng: 17.5030129 },
    { lat: 48.5400614, lng: 17.5028403 },
    { lat: 48.5416463, lng: 17.5026151 },
    { lat: 48.5424263, lng: 17.5024333 },
    { lat: 48.5433769, lng: 17.5017019 },
    { lat: 48.5446996, lng: 17.5009286 },
    { lat: 48.5451494, lng: 17.5005455 },
    { lat: 48.5458625, lng: 17.5003214 },
    { lat: 48.5466828, lng: 17.4998318 },
    { lat: 48.5475744, lng: 17.4995604 },
    { lat: 48.5477663, lng: 17.4993259 },
    { lat: 48.5487787, lng: 17.498982 },
    { lat: 48.5498299, lng: 17.4984989 },
    { lat: 48.5505142, lng: 17.4983238 },
    { lat: 48.550942, lng: 17.4984083 },
    { lat: 48.5521004, lng: 17.4972886 },
    { lat: 48.5543631, lng: 17.4961296 },
    { lat: 48.5550875, lng: 17.4959622 },
    { lat: 48.5556898, lng: 17.4960221 },
    { lat: 48.5561341, lng: 17.496276 },
    { lat: 48.5574196, lng: 17.4965787 },
    { lat: 48.5591931, lng: 17.4973644 },
    { lat: 48.5595018, lng: 17.4974063 },
    { lat: 48.559855, lng: 17.4966602 },
    { lat: 48.5599925, lng: 17.4965305 },
    { lat: 48.5604974, lng: 17.4965506 },
    { lat: 48.561571, lng: 17.4958006 },
    { lat: 48.5623557, lng: 17.4954444 },
    { lat: 48.5632218, lng: 17.4949094 },
    { lat: 48.5637207, lng: 17.4939507 },
    { lat: 48.5643573, lng: 17.4930814 },
    { lat: 48.5653303, lng: 17.4909723 },
    { lat: 48.5657072, lng: 17.4881211 },
    { lat: 48.5659217, lng: 17.4872374 },
    { lat: 48.5657841, lng: 17.4867282 },
    { lat: 48.5658394, lng: 17.4852807 },
    { lat: 48.5664234, lng: 17.4859019 },
    { lat: 48.5665745, lng: 17.4862654 },
    { lat: 48.567091, lng: 17.4854986 },
    { lat: 48.5678837, lng: 17.4848229 },
    { lat: 48.5682748, lng: 17.483701 },
    { lat: 48.5686171, lng: 17.4833653 },
    { lat: 48.5691234, lng: 17.4825243 },
    { lat: 48.5694721, lng: 17.4815825 },
    { lat: 48.5697472, lng: 17.4803226 },
    { lat: 48.5695054, lng: 17.4794737 },
    { lat: 48.5693682, lng: 17.4780548 },
    { lat: 48.569509, lng: 17.4775561 },
    { lat: 48.5699517, lng: 17.4765165 },
    { lat: 48.5704073, lng: 17.4759039 },
    { lat: 48.5707943, lng: 17.4757235 },
    { lat: 48.571656, lng: 17.475121 },
    { lat: 48.5717531, lng: 17.4747859 },
    { lat: 48.5726374, lng: 17.4739426 },
    { lat: 48.5734491, lng: 17.4729479 },
    { lat: 48.573937, lng: 17.471946 },
    { lat: 48.573919, lng: 17.47191 },
    { lat: 48.573807, lng: 17.471897 },
    { lat: 48.573695, lng: 17.471962 },
    { lat: 48.573482, lng: 17.471835 },
    { lat: 48.573385, lng: 17.471854 },
    { lat: 48.573361, lng: 17.471851 },
    { lat: 48.573258, lng: 17.471829 },
    { lat: 48.57311, lng: 17.471752 },
    { lat: 48.572987, lng: 17.471623 },
    { lat: 48.572863, lng: 17.471216 },
    { lat: 48.572794, lng: 17.471218 },
    { lat: 48.572643, lng: 17.47128 },
    { lat: 48.5725, lng: 17.47128 },
    { lat: 48.572401, lng: 17.471348 },
    { lat: 48.572283, lng: 17.471268 },
    { lat: 48.57217, lng: 17.471265 },
    { lat: 48.571954, lng: 17.471134 },
    { lat: 48.571929, lng: 17.471129 },
    { lat: 48.571747, lng: 17.471146 },
    { lat: 48.571612, lng: 17.471115 },
    { lat: 48.571502, lng: 17.471156 },
    { lat: 48.571444, lng: 17.471081 },
    { lat: 48.571395, lng: 17.47106 },
    { lat: 48.57134, lng: 17.470965 },
    { lat: 48.571259, lng: 17.470984 },
    { lat: 48.571194, lng: 17.470889 },
    { lat: 48.571103, lng: 17.470883 },
    { lat: 48.571063, lng: 17.470692 },
    { lat: 48.570975, lng: 17.47068 },
    { lat: 48.570908, lng: 17.47073 },
    { lat: 48.570732, lng: 17.470503 },
    { lat: 48.570697, lng: 17.470011 },
    { lat: 48.570621, lng: 17.46991 },
    { lat: 48.570502, lng: 17.469992 },
    { lat: 48.570427, lng: 17.469927 },
    { lat: 48.570277, lng: 17.470182 },
    { lat: 48.570121, lng: 17.4701 },
    { lat: 48.569955, lng: 17.469635 },
    { lat: 48.57001, lng: 17.469446 },
    { lat: 48.570079, lng: 17.46915 },
    { lat: 48.570054, lng: 17.468999 },
    { lat: 48.569991, lng: 17.468789 },
    { lat: 48.570038, lng: 17.468554 },
    { lat: 48.569953, lng: 17.468452 },
    { lat: 48.569978, lng: 17.468201 },
    { lat: 48.569921, lng: 17.467928 },
    { lat: 48.569856, lng: 17.467956 },
    { lat: 48.569757, lng: 17.467833 },
    { lat: 48.569724, lng: 17.467646 },
    { lat: 48.569695, lng: 17.467486 },
    { lat: 48.569393, lng: 17.467479 },
    { lat: 48.569396, lng: 17.467452 },
    { lat: 48.569422, lng: 17.467161 },
    { lat: 48.569292, lng: 17.467108 },
    { lat: 48.569121, lng: 17.466786 },
    { lat: 48.569102, lng: 17.466751 },
    { lat: 48.569033, lng: 17.46662 },
    { lat: 48.568956, lng: 17.466479 },
    { lat: 48.568898, lng: 17.466457 },
    { lat: 48.568823, lng: 17.466429 },
    { lat: 48.568765, lng: 17.466389 },
    { lat: 48.568624, lng: 17.466291 },
    { lat: 48.568614, lng: 17.466284 },
    { lat: 48.568507, lng: 17.466214 },
    { lat: 48.568347, lng: 17.466011 },
    { lat: 48.568274, lng: 17.465917 },
    { lat: 48.568255, lng: 17.465892 },
    { lat: 48.568244, lng: 17.465877 },
    { lat: 48.568262, lng: 17.465712 },
    { lat: 48.568272, lng: 17.465612 },
    { lat: 48.568053, lng: 17.465525 },
    { lat: 48.567977, lng: 17.46535 },
    { lat: 48.567804, lng: 17.465221 },
    { lat: 48.567655, lng: 17.46528 },
    { lat: 48.567611, lng: 17.465297 },
    { lat: 48.567583, lng: 17.465059 },
    { lat: 48.567554, lng: 17.4648 },
    { lat: 48.567122, lng: 17.464504 },
    { lat: 48.566948, lng: 17.464546 },
    { lat: 48.566735, lng: 17.464282 },
    { lat: 48.566689, lng: 17.464225 },
    { lat: 48.566658, lng: 17.464187 },
    { lat: 48.566643, lng: 17.464167 },
    { lat: 48.56613, lng: 17.46345 },
    { lat: 48.565895, lng: 17.463118 },
    { lat: 48.565756, lng: 17.462978 },
    { lat: 48.565201, lng: 17.462415 },
    { lat: 48.565187, lng: 17.462415 },
    { lat: 48.565064, lng: 17.462416 },
    { lat: 48.564907, lng: 17.462123 },
    { lat: 48.564781, lng: 17.46197 },
    { lat: 48.564643, lng: 17.461924 },
    { lat: 48.564546, lng: 17.461892 },
    { lat: 48.56445, lng: 17.461686 },
    { lat: 48.564381, lng: 17.461466 },
    { lat: 48.56442, lng: 17.461392 },
    { lat: 48.564268, lng: 17.461186 },
    { lat: 48.563751, lng: 17.460484 },
    { lat: 48.563501, lng: 17.460142 },
    { lat: 48.563096, lng: 17.459824 },
    { lat: 48.56275, lng: 17.459583 },
    { lat: 48.562728, lng: 17.459573 },
    { lat: 48.562695, lng: 17.459555 },
    { lat: 48.562547, lng: 17.459478 },
    { lat: 48.562006, lng: 17.459197 },
    { lat: 48.561976, lng: 17.459182 },
    { lat: 48.561901, lng: 17.459143 },
    { lat: 48.561602, lng: 17.458978 },
    { lat: 48.561309, lng: 17.458753 },
    { lat: 48.561204, lng: 17.458824 },
    { lat: 48.561089, lng: 17.458718 },
    { lat: 48.560977, lng: 17.45885 },
    { lat: 48.56086, lng: 17.458784 },
    { lat: 48.560836, lng: 17.458663 },
    { lat: 48.560763, lng: 17.458433 },
    { lat: 48.560674, lng: 17.458143 },
    { lat: 48.560625, lng: 17.457842 },
    { lat: 48.560479, lng: 17.457547 },
    { lat: 48.560381, lng: 17.457349 },
    { lat: 48.560362, lng: 17.45731 },
    { lat: 48.560334, lng: 17.457266 },
    { lat: 48.560218, lng: 17.45708 },
    { lat: 48.560096, lng: 17.456888 },
    { lat: 48.560025, lng: 17.456808 },
    { lat: 48.55974, lng: 17.4565 },
    { lat: 48.559707, lng: 17.456455 },
    { lat: 48.559574, lng: 17.456274 },
    { lat: 48.559621, lng: 17.456146 },
    { lat: 48.559639, lng: 17.456104 },
    { lat: 48.55968, lng: 17.456005 },
    { lat: 48.559769, lng: 17.455787 },
    { lat: 48.55985, lng: 17.455743 },
    { lat: 48.559989, lng: 17.45533 },
    { lat: 48.560094, lng: 17.455079 },
    { lat: 48.560133, lng: 17.454985 },
    { lat: 48.56038, lng: 17.454845 },
    { lat: 48.560571, lng: 17.454693 },
    { lat: 48.560698, lng: 17.454566 },
    { lat: 48.560795, lng: 17.454601 },
    { lat: 48.560865, lng: 17.454584 },
    { lat: 48.560916, lng: 17.454514 },
    { lat: 48.56094, lng: 17.454446 },
    { lat: 48.561136, lng: 17.454311 },
    { lat: 48.561207, lng: 17.45421 },
    { lat: 48.561303, lng: 17.454213 },
    { lat: 48.561332, lng: 17.454142 },
    { lat: 48.561465, lng: 17.454087 },
    { lat: 48.561525, lng: 17.454044 },
    { lat: 48.5617, lng: 17.453965 },
    { lat: 48.561723, lng: 17.453999 },
    { lat: 48.561793, lng: 17.453924 },
    { lat: 48.56193, lng: 17.453853 },
    { lat: 48.562029, lng: 17.453831 },
    { lat: 48.562114, lng: 17.453784 },
    { lat: 48.562199, lng: 17.45378 },
    { lat: 48.562506, lng: 17.453647 },
    { lat: 48.562675, lng: 17.453598 },
    { lat: 48.562769, lng: 17.453585 },
    { lat: 48.562943, lng: 17.453433 },
    { lat: 48.563226, lng: 17.453327 },
    { lat: 48.56345, lng: 17.453295 },
    { lat: 48.563521, lng: 17.453221 },
    { lat: 48.563689, lng: 17.453172 },
    { lat: 48.563836, lng: 17.453047 },
    { lat: 48.563919, lng: 17.453063 },
    { lat: 48.564032, lng: 17.453006 },
    { lat: 48.564148, lng: 17.452924 },
    { lat: 48.564264, lng: 17.452866 },
    { lat: 48.564409, lng: 17.452759 },
    { lat: 48.564844, lng: 17.452413 },
    { lat: 48.565251, lng: 17.451905 },
    { lat: 48.56537, lng: 17.451801 },
    { lat: 48.565452, lng: 17.451589 },
    { lat: 48.565454, lng: 17.451271 },
    { lat: 48.565506, lng: 17.451044 },
    { lat: 48.56548, lng: 17.450298 },
    { lat: 48.565494, lng: 17.450003 },
    { lat: 48.565761, lng: 17.449032 },
    { lat: 48.56581, lng: 17.448998 },
    { lat: 48.565858, lng: 17.448964 },
    { lat: 48.565867, lng: 17.448943 },
    { lat: 48.565874, lng: 17.448928 },
    { lat: 48.565892, lng: 17.448871 },
    { lat: 48.565914, lng: 17.448815 },
    { lat: 48.565924, lng: 17.448794 },
    { lat: 48.565919, lng: 17.448753 },
    { lat: 48.565873, lng: 17.44857 },
    { lat: 48.565903, lng: 17.448389 },
    { lat: 48.566159, lng: 17.447527 },
    { lat: 48.566202, lng: 17.447349 },
    { lat: 48.566242, lng: 17.447218 },
    { lat: 48.566263, lng: 17.447171 },
    { lat: 48.566471, lng: 17.446809 },
    { lat: 48.566391, lng: 17.446525 },
    { lat: 48.566361, lng: 17.446348 },
    { lat: 48.566275, lng: 17.446188 },
    { lat: 48.566255, lng: 17.446016 },
    { lat: 48.566172, lng: 17.445717 },
    { lat: 48.566147, lng: 17.445677 },
    { lat: 48.566068, lng: 17.445619 },
    { lat: 48.566005, lng: 17.445551 },
    { lat: 48.565788, lng: 17.445401 },
    { lat: 48.565735, lng: 17.445345 },
    { lat: 48.565607, lng: 17.445301 },
    { lat: 48.565516, lng: 17.445235 },
    { lat: 48.565481, lng: 17.445207 },
    { lat: 48.565413, lng: 17.445152 },
    { lat: 48.565334, lng: 17.445083 },
    { lat: 48.565252, lng: 17.444944 },
    { lat: 48.565106, lng: 17.444786 },
    { lat: 48.565056, lng: 17.444659 },
    { lat: 48.564996, lng: 17.444607 },
    { lat: 48.56497, lng: 17.444518 },
    { lat: 48.564819, lng: 17.444375 },
    { lat: 48.564759, lng: 17.444143 },
    { lat: 48.564791, lng: 17.443883 },
    { lat: 48.564677, lng: 17.443642 },
    { lat: 48.564674, lng: 17.44343 },
    { lat: 48.564581, lng: 17.443289 },
    { lat: 48.564625, lng: 17.443248 },
    { lat: 48.564705, lng: 17.443126 },
    { lat: 48.564712, lng: 17.443024 },
    { lat: 48.564729, lng: 17.442884 },
    { lat: 48.564752, lng: 17.44281 },
    { lat: 48.564772, lng: 17.442438 },
    { lat: 48.564793, lng: 17.442358 },
    { lat: 48.564797, lng: 17.442242 },
    { lat: 48.564755, lng: 17.441983 },
    { lat: 48.564752, lng: 17.441717 },
    { lat: 48.564707, lng: 17.441562 },
    { lat: 48.564695, lng: 17.441519 },
    { lat: 48.564686, lng: 17.441491 },
    { lat: 48.56461, lng: 17.441317 },
    { lat: 48.564539, lng: 17.441275 },
    { lat: 48.564415, lng: 17.44094 },
    { lat: 48.564293, lng: 17.440667 },
    { lat: 48.564276, lng: 17.440486 },
    { lat: 48.564196, lng: 17.440188 },
    { lat: 48.564128, lng: 17.440099 },
    { lat: 48.564067, lng: 17.439956 },
    { lat: 48.564058, lng: 17.439796 },
    { lat: 48.564018, lng: 17.439668 },
    { lat: 48.563992, lng: 17.439409 },
    { lat: 48.563886, lng: 17.439207 },
    { lat: 48.563786, lng: 17.438904 },
    { lat: 48.563648, lng: 17.438579 },
    { lat: 48.563532, lng: 17.438274 },
    { lat: 48.563406, lng: 17.438198 },
    { lat: 48.563396, lng: 17.438084 },
    { lat: 48.563309, lng: 17.437944 },
    { lat: 48.56323, lng: 17.437865 },
    { lat: 48.563151, lng: 17.437763 },
    { lat: 48.563037, lng: 17.437662 },
    { lat: 48.563007, lng: 17.437474 },
    { lat: 48.562989, lng: 17.437286 },
    { lat: 48.562918, lng: 17.437182 },
    { lat: 48.562824, lng: 17.43696 },
    { lat: 48.562585, lng: 17.436711 },
    { lat: 48.562506, lng: 17.436559 },
    { lat: 48.562349, lng: 17.436636 },
    { lat: 48.562283, lng: 17.43661 },
    { lat: 48.562268, lng: 17.436607 },
    { lat: 48.562202, lng: 17.436627 },
    { lat: 48.562163, lng: 17.436702 },
    { lat: 48.562107, lng: 17.436673 },
    { lat: 48.561984, lng: 17.436665 },
    { lat: 48.56178, lng: 17.43657 },
    { lat: 48.561698, lng: 17.436402 },
    { lat: 48.561567, lng: 17.436167 },
    { lat: 48.561501, lng: 17.435891 },
    { lat: 48.561355, lng: 17.435497 },
    { lat: 48.561262, lng: 17.435255 },
    { lat: 48.561071, lng: 17.434859 },
    { lat: 48.560838, lng: 17.434487 },
    { lat: 48.560765, lng: 17.434371 },
    { lat: 48.560584, lng: 17.434046 },
    { lat: 48.560287, lng: 17.433451 },
    { lat: 48.560266, lng: 17.433415 },
    { lat: 48.560194, lng: 17.433348 },
    { lat: 48.560116, lng: 17.433276 },
    { lat: 48.559971, lng: 17.433077 },
    { lat: 48.559933, lng: 17.43271 },
    { lat: 48.559873, lng: 17.432373 },
    { lat: 48.559832, lng: 17.432088 },
    { lat: 48.559819, lng: 17.431996 },
    { lat: 48.559822, lng: 17.431824 },
    { lat: 48.55982, lng: 17.431781 },
    { lat: 48.559829, lng: 17.431646 },
    { lat: 48.559818, lng: 17.43159 },
    { lat: 48.559767, lng: 17.431383 },
    { lat: 48.559745, lng: 17.431331 },
    { lat: 48.559632, lng: 17.43107 },
    { lat: 48.559626, lng: 17.431058 },
    { lat: 48.559614, lng: 17.430993 },
    { lat: 48.559586, lng: 17.430843 },
    { lat: 48.559583, lng: 17.430826 },
    { lat: 48.559583, lng: 17.43082 },
    { lat: 48.559583, lng: 17.430819 },
    { lat: 48.55958, lng: 17.430809 },
    { lat: 48.559575, lng: 17.430772 },
    { lat: 48.559561, lng: 17.430692 },
    { lat: 48.559565, lng: 17.430668 },
    { lat: 48.55957, lng: 17.430628 },
    { lat: 48.559581, lng: 17.430552 },
    { lat: 48.559579, lng: 17.430538 },
    { lat: 48.559554, lng: 17.430402 },
    { lat: 48.559512, lng: 17.430181 },
    { lat: 48.559486, lng: 17.430076 },
    { lat: 48.55948, lng: 17.430047 },
    { lat: 48.5594144, lng: 17.4297473 },
    { lat: 48.559358, lng: 17.4296365 },
    { lat: 48.559338, lng: 17.429599 },
    { lat: 48.559293, lng: 17.429494 },
    { lat: 48.559215, lng: 17.429313 },
    { lat: 48.559181, lng: 17.429237 },
    { lat: 48.559199, lng: 17.429141 },
    { lat: 48.559206, lng: 17.429114 },
    { lat: 48.559088, lng: 17.429067 },
    { lat: 48.558918, lng: 17.428789 },
    { lat: 48.55872, lng: 17.428552 },
    { lat: 48.558516, lng: 17.428392 },
    { lat: 48.558364, lng: 17.428336 },
    { lat: 48.558147, lng: 17.428258 },
    { lat: 48.55798, lng: 17.428175 },
    { lat: 48.557818, lng: 17.428112 },
    { lat: 48.557675, lng: 17.427959 },
    { lat: 48.557598, lng: 17.427877 },
    { lat: 48.557433, lng: 17.427749 },
    { lat: 48.557395, lng: 17.427719 },
    { lat: 48.557358, lng: 17.427691 },
    { lat: 48.557226, lng: 17.427595 },
    { lat: 48.55695, lng: 17.42737 },
    { lat: 48.556861, lng: 17.427301 },
    { lat: 48.556788, lng: 17.427245 },
    { lat: 48.556731, lng: 17.427202 },
    { lat: 48.556582, lng: 17.427023 },
    { lat: 48.556402, lng: 17.426419 },
    { lat: 48.556251, lng: 17.426173 },
    { lat: 48.55624, lng: 17.426188 },
    { lat: 48.556145, lng: 17.426042 },
    { lat: 48.555939, lng: 17.426041 },
    { lat: 48.555901, lng: 17.426019 },
    { lat: 48.555841, lng: 17.426027 },
    { lat: 48.555505, lng: 17.426072 },
    { lat: 48.555471, lng: 17.426114 },
    { lat: 48.555256, lng: 17.426193 },
    { lat: 48.555164, lng: 17.426146 },
    { lat: 48.554532, lng: 17.426248 },
    { lat: 48.554315, lng: 17.426352 },
    { lat: 48.554305, lng: 17.426328 },
    { lat: 48.5542126, lng: 17.4264114 },
    { lat: 48.5538735, lng: 17.4264076 },
    { lat: 48.5538213, lng: 17.4264901 },
    { lat: 48.5534503, lng: 17.426466 },
    { lat: 48.5532005, lng: 17.4265671 },
    { lat: 48.5529777, lng: 17.4262978 },
    { lat: 48.5529027, lng: 17.4263283 },
    { lat: 48.5526145, lng: 17.4258518 },
    { lat: 48.5524534, lng: 17.4258565 },
    { lat: 48.5523394, lng: 17.4259823 },
    { lat: 48.5519572, lng: 17.4260279 },
    { lat: 48.5518774, lng: 17.4258941 },
    { lat: 48.5517823, lng: 17.4259252 },
    { lat: 48.5516768, lng: 17.4260579 },
    { lat: 48.5515894, lng: 17.4260161 },
    { lat: 48.5514965, lng: 17.4262935 },
    { lat: 48.5513595, lng: 17.4265136 },
    { lat: 48.5512623, lng: 17.4263389 },
    { lat: 48.5512132, lng: 17.4263664 },
    { lat: 48.5507915, lng: 17.4269547 },
    { lat: 48.5505925, lng: 17.4270733 },
    { lat: 48.5504935, lng: 17.4272738 },
    { lat: 48.5503989, lng: 17.4272752 },
    { lat: 48.5504171, lng: 17.4275053 },
    { lat: 48.5503033, lng: 17.4276853 },
    { lat: 48.5502014, lng: 17.4276467 },
    { lat: 48.5501654, lng: 17.4274741 },
    { lat: 48.5501204, lng: 17.4274928 },
    { lat: 48.5501359, lng: 17.4276623 },
    { lat: 48.5500316, lng: 17.4278697 },
    { lat: 48.5500056, lng: 17.4280796 },
    { lat: 48.5498851, lng: 17.4282398 },
    { lat: 48.5498918, lng: 17.4287297 },
    { lat: 48.5498169, lng: 17.4287792 },
    { lat: 48.5497707, lng: 17.4289866 },
    { lat: 48.5494876, lng: 17.4293981 },
    { lat: 48.549199, lng: 17.4295651 },
    { lat: 48.5490899, lng: 17.4298523 },
    { lat: 48.5490797, lng: 17.430185 },
    { lat: 48.548915, lng: 17.4303987 },
    { lat: 48.5487847, lng: 17.4308812 },
    { lat: 48.5488236, lng: 17.4309596 },
    { lat: 48.5488137, lng: 17.4312801 },
    { lat: 48.5487082, lng: 17.4313569 },
    { lat: 48.5484691, lng: 17.4317134 },
    { lat: 48.5482678, lng: 17.431868 },
    { lat: 48.5483591, lng: 17.4324627 },
    { lat: 48.5483366, lng: 17.4326207 },
    { lat: 48.5482789, lng: 17.4325468 },
    { lat: 48.5482109, lng: 17.432231 },
    { lat: 48.5481094, lng: 17.4323011 },
    { lat: 48.5480384, lng: 17.4320409 },
    { lat: 48.5476279, lng: 17.4321562 },
    { lat: 48.5474773, lng: 17.4321322 },
    { lat: 48.5470364, lng: 17.4319494 },
    { lat: 48.5463174, lng: 17.4330632 },
    { lat: 48.5465714, lng: 17.4333569 },
    { lat: 48.5463681, lng: 17.4338486 },
    { lat: 48.5447573, lng: 17.4355281 },
    { lat: 48.5447779, lng: 17.4356006 },
    { lat: 48.5445918, lng: 17.435589 },
    { lat: 48.5443042, lng: 17.4357347 },
    { lat: 48.5438619, lng: 17.4358468 },
    { lat: 48.5437252, lng: 17.4359512 },
    { lat: 48.5436508, lng: 17.435883 },
    { lat: 48.5435685, lng: 17.4359025 },
    { lat: 48.5434758, lng: 17.4359877 },
    { lat: 48.5433952, lng: 17.435967 },
    { lat: 48.543281, lng: 17.4360875 },
    { lat: 48.542946, lng: 17.4361806 },
    { lat: 48.5425042, lng: 17.4364248 },
    { lat: 48.5422052, lng: 17.4364115 },
    { lat: 48.5418276, lng: 17.4364913 },
    { lat: 48.5415523, lng: 17.4367076 },
    { lat: 48.5415103, lng: 17.4368456 },
    { lat: 48.540803, lng: 17.4366307 },
    { lat: 48.5406221, lng: 17.4367687 },
    { lat: 48.5405426, lng: 17.436673 },
    { lat: 48.5402027, lng: 17.4367099 },
    { lat: 48.5399537, lng: 17.4373671 },
    { lat: 48.5396002, lng: 17.437736 },
    { lat: 48.5395155, lng: 17.4380852 },
    { lat: 48.5390374, lng: 17.4388532 },
    { lat: 48.5386648, lng: 17.4390983 },
    { lat: 48.5383716, lng: 17.4391614 },
    { lat: 48.5382678, lng: 17.4393901 },
    { lat: 48.5380653, lng: 17.4393473 },
    { lat: 48.5380096, lng: 17.4392543 },
    { lat: 48.5376273, lng: 17.4394744 },
    { lat: 48.5375154, lng: 17.4396141 },
    { lat: 48.537436, lng: 17.4400213 },
    { lat: 48.5373797, lng: 17.4404169 },
    { lat: 48.5367281, lng: 17.4414892 },
    { lat: 48.5364663, lng: 17.4420692 },
    { lat: 48.5362834, lng: 17.4419868 },
    { lat: 48.5359486, lng: 17.4422738 },
    { lat: 48.5357243, lng: 17.4425991 },
    { lat: 48.5355393, lng: 17.4430591 },
    { lat: 48.5351859, lng: 17.4436912 },
    { lat: 48.5346667, lng: 17.4440122 },
    { lat: 48.5342372, lng: 17.4441487 },
    { lat: 48.5339444, lng: 17.4444423 },
    { lat: 48.5332043, lng: 17.4448939 },
    { lat: 48.5324963, lng: 17.4451241 },
    { lat: 48.5322093, lng: 17.4453316 },
    { lat: 48.5305023, lng: 17.4471169 },
    { lat: 48.5301611, lng: 17.4463226 },
    { lat: 48.5297784, lng: 17.4463784 },
    { lat: 48.5292907, lng: 17.4465558 },
    { lat: 48.5288905, lng: 17.4464834 },
    { lat: 48.528669, lng: 17.4463397 },
    { lat: 48.5286798, lng: 17.4464012 },
    { lat: 48.5287372, lng: 17.4468738 },
    { lat: 48.5287128, lng: 17.4470901 },
    { lat: 48.5284728, lng: 17.4477483 },
    { lat: 48.5281477, lng: 17.448419 },
    { lat: 48.5279623, lng: 17.448678 },
    { lat: 48.527176, lng: 17.4484449 },
    { lat: 48.5265696, lng: 17.44815 },
    { lat: 48.5264312, lng: 17.4481584 },
    { lat: 48.5261406, lng: 17.4483647 },
    { lat: 48.5261423, lng: 17.4484436 },
    { lat: 48.5263643, lng: 17.4488227 },
    { lat: 48.5265298, lng: 17.4496548 },
    { lat: 48.5268694, lng: 17.4502641 },
    { lat: 48.5268466, lng: 17.4507851 },
    { lat: 48.5269722, lng: 17.4512998 },
    { lat: 48.5268709, lng: 17.4516385 },
    { lat: 48.5269808, lng: 17.4517493 },
    { lat: 48.5270143, lng: 17.4518666 },
    { lat: 48.5270907, lng: 17.4518427 },
    { lat: 48.5272097, lng: 17.4519572 },
    { lat: 48.5274031, lng: 17.4522735 },
    { lat: 48.5275113, lng: 17.4527822 },
    { lat: 48.5273433, lng: 17.4527308 },
    { lat: 48.5271795, lng: 17.4528007 },
    { lat: 48.5270006, lng: 17.4530769 },
    { lat: 48.5269936, lng: 17.4529172 },
    { lat: 48.5267413, lng: 17.4530498 },
    { lat: 48.5265874, lng: 17.4529739 },
    { lat: 48.5262807, lng: 17.4530849 },
    { lat: 48.5249364, lng: 17.4546451 },
    { lat: 48.5242629, lng: 17.4551651 },
    { lat: 48.5240657, lng: 17.4557176 },
    { lat: 48.5230219, lng: 17.4560329 },
    { lat: 48.5221271, lng: 17.4571704 },
    { lat: 48.522, lng: 17.4571957 },
    { lat: 48.5219597, lng: 17.4569892 },
    { lat: 48.5218081, lng: 17.4570715 },
    { lat: 48.52183, lng: 17.4569034 },
    { lat: 48.5215893, lng: 17.4569887 },
    { lat: 48.5215255, lng: 17.457123 },
    { lat: 48.5218125, lng: 17.4573873 },
    { lat: 48.5218293, lng: 17.4575455 },
    { lat: 48.5215848, lng: 17.4575191 },
    { lat: 48.5212404, lng: 17.4572572 },
    { lat: 48.521097, lng: 17.4576258 },
    { lat: 48.5209608, lng: 17.4577009 },
    { lat: 48.5209013, lng: 17.4576041 },
    { lat: 48.5207941, lng: 17.4578135 },
    { lat: 48.5207174, lng: 17.4578357 },
    { lat: 48.5206511, lng: 17.458103 },
    { lat: 48.5206823, lng: 17.458413 },
    { lat: 48.5206347, lng: 17.4584082 },
    { lat: 48.5205782, lng: 17.4582514 },
    { lat: 48.5203604, lng: 17.4584634 },
    { lat: 48.5202809, lng: 17.4583856 },
    { lat: 48.5202374, lng: 17.4584122 },
    { lat: 48.520207, lng: 17.4582927 },
    { lat: 48.5201131, lng: 17.4585376 },
    { lat: 48.5200429, lng: 17.458587 },
    { lat: 48.5198835, lng: 17.4584086 },
    { lat: 48.519653, lng: 17.4587065 },
    { lat: 48.5195991, lng: 17.4586744 },
    { lat: 48.5195612, lng: 17.4587701 },
    { lat: 48.5194402, lng: 17.4588201 },
    { lat: 48.5193744, lng: 17.4589384 },
    { lat: 48.5195511, lng: 17.4599116 },
    { lat: 48.5195401, lng: 17.460077 },
    { lat: 48.5194494, lng: 17.4601234 },
    { lat: 48.5193928, lng: 17.4602449 },
    { lat: 48.5194763, lng: 17.4606823 },
    { lat: 48.5193664, lng: 17.4608166 },
    { lat: 48.5194515, lng: 17.4608926 },
    { lat: 48.5194353, lng: 17.4609936 },
    { lat: 48.5192904, lng: 17.461092 },
    { lat: 48.5190718, lng: 17.4609792 },
    { lat: 48.5190341, lng: 17.4612177 },
    { lat: 48.5190699, lng: 17.4613696 },
    { lat: 48.5190423, lng: 17.4614153 },
    { lat: 48.5188828, lng: 17.461254 },
    { lat: 48.5187911, lng: 17.461356 },
    { lat: 48.5186379, lng: 17.4617659 },
    { lat: 48.5187563, lng: 17.4620444 },
    { lat: 48.518721, lng: 17.4621464 },
    { lat: 48.5185684, lng: 17.4621619 },
    { lat: 48.51802, lng: 17.4626257 },
    { lat: 48.5178454, lng: 17.4634954 },
    { lat: 48.5175531, lng: 17.4641151 },
    { lat: 48.517108, lng: 17.4633384 },
    { lat: 48.5161193, lng: 17.4645642 },
    { lat: 48.515963, lng: 17.4649075 },
    { lat: 48.5159513, lng: 17.4657617 },
    { lat: 48.5160307, lng: 17.465925 },
    { lat: 48.513796, lng: 17.4686784 },
    { lat: 48.5138707, lng: 17.4689967 },
    { lat: 48.5130147, lng: 17.4692783 },
    { lat: 48.5115927, lng: 17.4705967 },
    { lat: 48.5110279, lng: 17.4712986 },
    { lat: 48.5102645, lng: 17.4709319 },
    { lat: 48.5097631, lng: 17.4709663 },
    { lat: 48.5095279, lng: 17.4707526 },
    { lat: 48.5090234, lng: 17.4717096 },
    { lat: 48.5091221, lng: 17.4726125 },
    { lat: 48.5090939, lng: 17.4728231 },
    { lat: 48.5089776, lng: 17.4731236 },
    { lat: 48.5090081, lng: 17.473211 },
    { lat: 48.5089186, lng: 17.4733287 },
    { lat: 48.5086408, lng: 17.4740013 },
    { lat: 48.5084954, lng: 17.4747789 },
    { lat: 48.5086648, lng: 17.4750795 },
    { lat: 48.5088715, lng: 17.4752026 },
    { lat: 48.5088843, lng: 17.4757208 },
    { lat: 48.5091945, lng: 17.4760657 },
    { lat: 48.5092404, lng: 17.4769406 },
    { lat: 48.5090695, lng: 17.477302 },
    { lat: 48.5089487, lng: 17.4780978 },
    { lat: 48.5087508, lng: 17.4785871 },
    { lat: 48.5086637, lng: 17.4790079 },
    { lat: 48.5086923, lng: 17.4798578 },
    { lat: 48.5087605, lng: 17.480374 },
    { lat: 48.5086992, lng: 17.480795 },
    { lat: 48.5088021, lng: 17.4812534 },
    { lat: 48.5087479, lng: 17.4814116 },
    { lat: 48.5087273, lng: 17.4817615 },
    { lat: 48.5088316, lng: 17.4843246 },
    { lat: 48.5090056, lng: 17.4848867 },
    { lat: 48.5093853, lng: 17.4854026 },
    { lat: 48.5096592, lng: 17.4853069 },
    { lat: 48.5098343, lng: 17.4853962 },
    { lat: 48.5102455, lng: 17.4859078 },
    { lat: 48.5104668, lng: 17.4863473 },
    { lat: 48.5109216, lng: 17.4875622 },
    { lat: 48.51099, lng: 17.4880987 },
    { lat: 48.5104933, lng: 17.4905026 },
    { lat: 48.5104176, lng: 17.4909096 },
    { lat: 48.510434, lng: 17.4910772 },
    { lat: 48.5106287, lng: 17.4917716 },
    { lat: 48.5110464, lng: 17.4922642 },
    { lat: 48.5115474, lng: 17.4930151 },
    { lat: 48.5117404, lng: 17.4934715 },
    { lat: 48.5118552, lng: 17.4939596 },
    { lat: 48.5120312, lng: 17.4944356 },
    { lat: 48.5120463, lng: 17.494775 },
    { lat: 48.5118163, lng: 17.4958242 },
    { lat: 48.5115477, lng: 17.496605 },
    { lat: 48.5114718, lng: 17.4967061 },
    { lat: 48.5114304, lng: 17.4969789 },
    { lat: 48.5115, lng: 17.4972203 },
    { lat: 48.5113376, lng: 17.4978226 },
    { lat: 48.5111345, lng: 17.498215 },
    { lat: 48.5118664, lng: 17.4992573 },
    { lat: 48.5129346, lng: 17.502401 },
    { lat: 48.5140739, lng: 17.5049421 },
    { lat: 48.5142497, lng: 17.5048486 },
    { lat: 48.5143939, lng: 17.5046511 },
    { lat: 48.5145361, lng: 17.5048235 },
    { lat: 48.5145983, lng: 17.5047896 },
    { lat: 48.515022, lng: 17.5042991 },
    { lat: 48.5150406, lng: 17.5042191 },
    { lat: 48.5149752, lng: 17.5040765 },
    { lat: 48.5150806, lng: 17.5039422 },
    { lat: 48.5153519, lng: 17.5040355 },
    { lat: 48.515667, lng: 17.503593 },
    { lat: 48.5159085, lng: 17.5035727 },
    { lat: 48.5163175, lng: 17.503169 },
    { lat: 48.5165493, lng: 17.5027828 },
    { lat: 48.5180699, lng: 17.5011314 },
    { lat: 48.5191543, lng: 17.5004244 },
    { lat: 48.5192655, lng: 17.5002059 },
    { lat: 48.5205565, lng: 17.5007026 },
    { lat: 48.5219062, lng: 17.5007551 },
    { lat: 48.5227153, lng: 17.5011702 },
    { lat: 48.5233487, lng: 17.5013268 },
    { lat: 48.5244759, lng: 17.5010641 },
    { lat: 48.5262217, lng: 17.5003018 },
    { lat: 48.5274135, lng: 17.4996354 },
    { lat: 48.5278562, lng: 17.4995118 },
    { lat: 48.5282689, lng: 17.50104 },
    { lat: 48.5344352, lng: 17.5052842 },
    { lat: 48.5354144, lng: 17.5078147 },
    { lat: 48.5357998, lng: 17.507605 },
    { lat: 48.5362972, lng: 17.5075968 },
    { lat: 48.5363151, lng: 17.5076812 },
    { lat: 48.5363683, lng: 17.5076667 },
    { lat: 48.5369305, lng: 17.5074492 },
    { lat: 48.5369637, lng: 17.5073874 },
    { lat: 48.5369549, lng: 17.5073573 },
  ],
  KrižovanynadDudváhom: [
    { lat: 48.3342298, lng: 17.6733393 },
    { lat: 48.3355552, lng: 17.6710298 },
    { lat: 48.336906, lng: 17.669069 },
    { lat: 48.3387448, lng: 17.6659782 },
    { lat: 48.339047, lng: 17.6648908 },
    { lat: 48.3390201, lng: 17.6645142 },
    { lat: 48.3391258, lng: 17.6638796 },
    { lat: 48.3391328, lng: 17.6632324 },
    { lat: 48.3405961, lng: 17.6582262 },
    { lat: 48.3413713, lng: 17.6560217 },
    { lat: 48.3424393, lng: 17.6534309 },
    { lat: 48.3435511, lng: 17.6509575 },
    { lat: 48.3453373, lng: 17.6474152 },
    { lat: 48.3457608, lng: 17.6463717 },
    { lat: 48.3459767, lng: 17.6461627 },
    { lat: 48.3461454, lng: 17.6458797 },
    { lat: 48.3471718, lng: 17.6439931 },
    { lat: 48.3481962, lng: 17.6422551 },
    { lat: 48.347993, lng: 17.6417504 },
    { lat: 48.3476307, lng: 17.6408926 },
    { lat: 48.3464304, lng: 17.6415927 },
    { lat: 48.3457118, lng: 17.6421239 },
    { lat: 48.3456216, lng: 17.6421086 },
    { lat: 48.3450946, lng: 17.6408194 },
    { lat: 48.3445454, lng: 17.6396311 },
    { lat: 48.3436416, lng: 17.6380066 },
    { lat: 48.3404563, lng: 17.6357484 },
    { lat: 48.3369909, lng: 17.6331823 },
    { lat: 48.3353818, lng: 17.6320876 },
    { lat: 48.3348925, lng: 17.6320465 },
    { lat: 48.333778, lng: 17.6321997 },
    { lat: 48.3332771, lng: 17.6322686 },
    { lat: 48.3332319, lng: 17.6321245 },
    { lat: 48.3332006, lng: 17.6320228 },
    { lat: 48.3274403, lng: 17.6361414 },
    { lat: 48.3190647, lng: 17.64258 },
    { lat: 48.3163763, lng: 17.6451652 },
    { lat: 48.3164215, lng: 17.6452748 },
    { lat: 48.3157181, lng: 17.648237 },
    { lat: 48.314128, lng: 17.6544362 },
    { lat: 48.3136735, lng: 17.6564946 },
    { lat: 48.3134184, lng: 17.6579523 },
    { lat: 48.3130664, lng: 17.659092 },
    { lat: 48.3129263, lng: 17.6596832 },
    { lat: 48.3127975, lng: 17.6605122 },
    { lat: 48.3123792, lng: 17.6619415 },
    { lat: 48.3120341, lng: 17.6618564 },
    { lat: 48.3119639, lng: 17.6620355 },
    { lat: 48.311876, lng: 17.6620216 },
    { lat: 48.3117435, lng: 17.6621208 },
    { lat: 48.3117285, lng: 17.6623152 },
    { lat: 48.3116386, lng: 17.6623414 },
    { lat: 48.3114234, lng: 17.6622227 },
    { lat: 48.3113519, lng: 17.6620186 },
    { lat: 48.3112557, lng: 17.6621212 },
    { lat: 48.3113354, lng: 17.6624561 },
    { lat: 48.311325, lng: 17.6626158 },
    { lat: 48.3111894, lng: 17.6627583 },
    { lat: 48.3112973, lng: 17.662985 },
    { lat: 48.3112108, lng: 17.6631863 },
    { lat: 48.3112197, lng: 17.6634911 },
    { lat: 48.3112688, lng: 17.6641868 },
    { lat: 48.3114008, lng: 17.6646496 },
    { lat: 48.3113705, lng: 17.6647436 },
    { lat: 48.3118882, lng: 17.665258 },
    { lat: 48.3113524, lng: 17.6667615 },
    { lat: 48.3110493, lng: 17.6678137 },
    { lat: 48.310781, lng: 17.6683626 },
    { lat: 48.3107258, lng: 17.6686364 },
    { lat: 48.3107425, lng: 17.6689749 },
    { lat: 48.3106405, lng: 17.6698508 },
    { lat: 48.3105493, lng: 17.6703241 },
    { lat: 48.3105501, lng: 17.6712355 },
    { lat: 48.3104044, lng: 17.6712181 },
    { lat: 48.310035, lng: 17.6713421 },
    { lat: 48.309763, lng: 17.6715967 },
    { lat: 48.3095502, lng: 17.67193 },
    { lat: 48.3090032, lng: 17.6736885 },
    { lat: 48.3085009, lng: 17.6743412 },
    { lat: 48.3080628, lng: 17.6745543 },
    { lat: 48.3074634, lng: 17.6764886 },
    { lat: 48.3075882, lng: 17.6766406 },
    { lat: 48.3071536, lng: 17.6783052 },
    { lat: 48.3070204, lng: 17.6790804 },
    { lat: 48.3065836, lng: 17.6806871 },
    { lat: 48.3043689, lng: 17.687775 },
    { lat: 48.304345, lng: 17.687868 },
    { lat: 48.305077, lng: 17.688858 },
    { lat: 48.305608, lng: 17.689683 },
    { lat: 48.306158, lng: 17.690859 },
    { lat: 48.307501, lng: 17.693488 },
    { lat: 48.307615, lng: 17.693904 },
    { lat: 48.307921, lng: 17.694459 },
    { lat: 48.30932, lng: 17.693266 },
    { lat: 48.3095151, lng: 17.6929558 },
    { lat: 48.3101727, lng: 17.6919165 },
    { lat: 48.3112157, lng: 17.6905201 },
    { lat: 48.311985, lng: 17.6890639 },
    { lat: 48.312446, lng: 17.6883079 },
    { lat: 48.3133096, lng: 17.6880163 },
    { lat: 48.3140123, lng: 17.6879523 },
    { lat: 48.3143063, lng: 17.6871619 },
    { lat: 48.3143927, lng: 17.6861605 },
    { lat: 48.314397, lng: 17.6860127 },
    { lat: 48.3144665, lng: 17.6850132 },
    { lat: 48.3146758, lng: 17.6842957 },
    { lat: 48.3150404, lng: 17.6835549 },
    { lat: 48.3155553, lng: 17.6828138 },
    { lat: 48.315675, lng: 17.6824735 },
    { lat: 48.3157788, lng: 17.681731 },
    { lat: 48.3156524, lng: 17.6809929 },
    { lat: 48.3156901, lng: 17.6805654 },
    { lat: 48.3162268, lng: 17.6808436 },
    { lat: 48.3166565, lng: 17.6813579 },
    { lat: 48.3175825, lng: 17.6820081 },
    { lat: 48.3179315, lng: 17.6821023 },
    { lat: 48.3181534, lng: 17.6819473 },
    { lat: 48.3182457, lng: 17.6817999 },
    { lat: 48.3183439, lng: 17.6813306 },
    { lat: 48.3183132, lng: 17.6811455 },
    { lat: 48.3179239, lng: 17.6803205 },
    { lat: 48.3178527, lng: 17.6796331 },
    { lat: 48.3178578, lng: 17.6793692 },
    { lat: 48.317949, lng: 17.6790051 },
    { lat: 48.3181848, lng: 17.6787314 },
    { lat: 48.3195443, lng: 17.6784803 },
    { lat: 48.3198338, lng: 17.678271 },
    { lat: 48.3203771, lng: 17.6775876 },
    { lat: 48.3207942, lng: 17.677216 },
    { lat: 48.321236, lng: 17.6771868 },
    { lat: 48.3216502, lng: 17.6774358 },
    { lat: 48.3218908, lng: 17.6777453 },
    { lat: 48.3220295, lng: 17.6780476 },
    { lat: 48.3220392, lng: 17.6782747 },
    { lat: 48.3220373, lng: 17.6784783 },
    { lat: 48.3219237, lng: 17.6789363 },
    { lat: 48.3216176, lng: 17.6794771 },
    { lat: 48.320941, lng: 17.6801214 },
    { lat: 48.3202208, lng: 17.6814619 },
    { lat: 48.3199858, lng: 17.6820732 },
    { lat: 48.3199463, lng: 17.682271 },
    { lat: 48.3199661, lng: 17.6823899 },
    { lat: 48.3201966, lng: 17.6830425 },
    { lat: 48.32029, lng: 17.6832341 },
    { lat: 48.3205483, lng: 17.6833773 },
    { lat: 48.3207326, lng: 17.6832708 },
    { lat: 48.3208928, lng: 17.6830706 },
    { lat: 48.3211098, lng: 17.6824771 },
    { lat: 48.3213048, lng: 17.682091 },
    { lat: 48.3222528, lng: 17.680986 },
    { lat: 48.3226508, lng: 17.6793857 },
    { lat: 48.3229185, lng: 17.6787946 },
    { lat: 48.3232264, lng: 17.6786445 },
    { lat: 48.3238234, lng: 17.6788 },
    { lat: 48.324094, lng: 17.6789755 },
    { lat: 48.3242861, lng: 17.6791954 },
    { lat: 48.3246176, lng: 17.6797473 },
    { lat: 48.3260443, lng: 17.6781515 },
    { lat: 48.3267203, lng: 17.6775654 },
    { lat: 48.3285618, lng: 17.6754182 },
    { lat: 48.328889, lng: 17.6749829 },
    { lat: 48.3297277, lng: 17.6734087 },
    { lat: 48.3311216, lng: 17.6710362 },
    { lat: 48.3319766, lng: 17.6700152 },
    { lat: 48.3323733, lng: 17.669696 },
    { lat: 48.3324957, lng: 17.6696482 },
    { lat: 48.3325308, lng: 17.6697032 },
    { lat: 48.3326729, lng: 17.6703514 },
    { lat: 48.3330032, lng: 17.6704486 },
    { lat: 48.333265, lng: 17.6706473 },
    { lat: 48.3332493, lng: 17.6707365 },
    { lat: 48.3331516, lng: 17.6708003 },
    { lat: 48.332984, lng: 17.6712415 },
    { lat: 48.3329894, lng: 17.6713991 },
    { lat: 48.3331257, lng: 17.6716499 },
    { lat: 48.3335092, lng: 17.6719135 },
    { lat: 48.3340396, lng: 17.6716542 },
    { lat: 48.3341751, lng: 17.6717097 },
    { lat: 48.3343607, lng: 17.6716629 },
    { lat: 48.3345027, lng: 17.6718555 },
    { lat: 48.3345162, lng: 17.6720197 },
    { lat: 48.3343725, lng: 17.6722819 },
    { lat: 48.3341941, lng: 17.672899 },
    { lat: 48.3341803, lng: 17.6730897 },
    { lat: 48.3342298, lng: 17.6733393 },
  ],
};

export default UnitsTrnava;
