const UnitsŽiarNadHronom = {
  DolnáŽdaňa: [
    { lat: 48.5375649, lng: 18.7367026 },
    { lat: 48.5375456, lng: 18.7367629 },
    { lat: 48.5379, lng: 18.7400481 },
    { lat: 48.5389976, lng: 18.7410949 },
    { lat: 48.5393527, lng: 18.741369 },
    { lat: 48.5399628, lng: 18.7417501 },
    { lat: 48.5406818, lng: 18.7425514 },
    { lat: 48.540943, lng: 18.7432229 },
    { lat: 48.5410994, lng: 18.7449003 },
    { lat: 48.5409377, lng: 18.7459137 },
    { lat: 48.5405544, lng: 18.7471551 },
    { lat: 48.5403374, lng: 18.7475694 },
    { lat: 48.5403057, lng: 18.7476305 },
    { lat: 48.5402891, lng: 18.747662 },
    { lat: 48.5400198, lng: 18.7481773 },
    { lat: 48.5385961, lng: 18.7501936 },
    { lat: 48.5371008, lng: 18.7516679 },
    { lat: 48.5369497, lng: 18.7518167 },
    { lat: 48.5369082, lng: 18.7520732 },
    { lat: 48.5366553, lng: 18.7536519 },
    { lat: 48.5367742, lng: 18.7549796 },
    { lat: 48.5372235, lng: 18.7568054 },
    { lat: 48.53803, lng: 18.7588813 },
    { lat: 48.5380082, lng: 18.7589159 },
    { lat: 48.5377101, lng: 18.7593797 },
    { lat: 48.5376894, lng: 18.7594117 },
    { lat: 48.5384139, lng: 18.7606605 },
    { lat: 48.5396374, lng: 18.7620902 },
    { lat: 48.541096, lng: 18.765216 },
    { lat: 48.5408569, lng: 18.765456 },
    { lat: 48.5403388, lng: 18.7659771 },
    { lat: 48.5403044, lng: 18.7660114 },
    { lat: 48.5403515, lng: 18.7663884 },
    { lat: 48.5404446, lng: 18.766594 },
    { lat: 48.540465, lng: 18.7666406 },
    { lat: 48.5404958, lng: 18.7667099 },
    { lat: 48.5405116, lng: 18.7667308 },
    { lat: 48.5405566, lng: 18.7667955 },
    { lat: 48.5406576, lng: 18.7669368 },
    { lat: 48.5407358, lng: 18.7670481 },
    { lat: 48.5408442, lng: 18.7672223 },
    { lat: 48.541242, lng: 18.7675993 },
    { lat: 48.5413476, lng: 18.7677244 },
    { lat: 48.5413979, lng: 18.7677775 },
    { lat: 48.5418078, lng: 18.7682374 },
    { lat: 48.5417561, lng: 18.7691835 },
    { lat: 48.5419595, lng: 18.7696392 },
    { lat: 48.5420354, lng: 18.7699867 },
    { lat: 48.5420953, lng: 18.7701622 },
    { lat: 48.5421526, lng: 18.7703275 },
    { lat: 48.542194, lng: 18.7704479 },
    { lat: 48.5430665, lng: 18.7720732 },
    { lat: 48.5437707, lng: 18.773234 },
    { lat: 48.5441269, lng: 18.7746431 },
    { lat: 48.5442549, lng: 18.7757066 },
    { lat: 48.5442481, lng: 18.7762412 },
    { lat: 48.5443014, lng: 18.7761626 },
    { lat: 48.5443403, lng: 18.7761064 },
    { lat: 48.545603, lng: 18.77426 },
    { lat: 48.5456261, lng: 18.7742263 },
    { lat: 48.5465632, lng: 18.7728558 },
    { lat: 48.5474292, lng: 18.7715895 },
    { lat: 48.5474345, lng: 18.7715821 },
    { lat: 48.550118, lng: 18.7728363 },
    { lat: 48.550497, lng: 18.7729651 },
    { lat: 48.5507035, lng: 18.7730767 },
    { lat: 48.5510862, lng: 18.7733215 },
    { lat: 48.5520826, lng: 18.7741439 },
    { lat: 48.5526521, lng: 18.7746144 },
    { lat: 48.5527957, lng: 18.7745146 },
    { lat: 48.5531421, lng: 18.7742846 },
    { lat: 48.5536796, lng: 18.7739324 },
    { lat: 48.5539216, lng: 18.773776 },
    { lat: 48.554093, lng: 18.7736658 },
    { lat: 48.5542226, lng: 18.773582 },
    { lat: 48.5543339, lng: 18.7735096 },
    { lat: 48.5544562, lng: 18.7734309 },
    { lat: 48.5545837, lng: 18.7733491 },
    { lat: 48.5547343, lng: 18.7732517 },
    { lat: 48.5549099, lng: 18.7731386 },
    { lat: 48.5550932, lng: 18.7730205 },
    { lat: 48.5553037, lng: 18.7728847 },
    { lat: 48.5555141, lng: 18.7727484 },
    { lat: 48.5557464, lng: 18.7725989 },
    { lat: 48.5559863, lng: 18.7724442 },
    { lat: 48.5560725, lng: 18.7723884 },
    { lat: 48.5561216, lng: 18.7723565 },
    { lat: 48.5562459, lng: 18.7722762 },
    { lat: 48.5565065, lng: 18.7721083 },
    { lat: 48.5567956, lng: 18.7719217 },
    { lat: 48.5571238, lng: 18.7717102 },
    { lat: 48.5574577, lng: 18.7714948 },
    { lat: 48.5578033, lng: 18.7712715 },
    { lat: 48.5582234, lng: 18.7710006 },
    { lat: 48.5586487, lng: 18.7707258 },
    { lat: 48.5589693, lng: 18.7705194 },
    { lat: 48.5591732, lng: 18.7703875 },
    { lat: 48.5593281, lng: 18.7702878 },
    { lat: 48.5596794, lng: 18.7700611 },
    { lat: 48.5600229, lng: 18.7698391 },
    { lat: 48.5603227, lng: 18.7696456 },
    { lat: 48.5605737, lng: 18.7694842 },
    { lat: 48.5606729, lng: 18.76942 },
    { lat: 48.5606484, lng: 18.769341 },
    { lat: 48.5604232, lng: 18.7685968 },
    { lat: 48.5599755, lng: 18.7671238 },
    { lat: 48.5599634, lng: 18.7670872 },
    { lat: 48.5593972, lng: 18.7652229 },
    { lat: 48.5593903, lng: 18.7652002 },
    { lat: 48.5593834, lng: 18.7651757 },
    { lat: 48.5593768, lng: 18.7651587 },
    { lat: 48.5593634, lng: 18.7651151 },
    { lat: 48.5589916, lng: 18.7639268 },
    { lat: 48.5589807, lng: 18.7638893 },
    { lat: 48.5584789, lng: 18.7622882 },
    { lat: 48.5584666, lng: 18.7622477 },
    { lat: 48.5584624, lng: 18.7622318 },
    { lat: 48.5584551, lng: 18.7622003 },
    { lat: 48.5584523, lng: 18.7621875 },
    { lat: 48.5581624, lng: 18.7611851 },
    { lat: 48.5579847, lng: 18.7605727 },
    { lat: 48.5578549, lng: 18.7601244 },
    { lat: 48.5578467, lng: 18.7600934 },
    { lat: 48.5578258, lng: 18.7600207 },
    { lat: 48.5576591, lng: 18.7594444 },
    { lat: 48.5572561, lng: 18.7580523 },
    { lat: 48.5572437, lng: 18.758009 },
    { lat: 48.5572217, lng: 18.7579326 },
    { lat: 48.5572092, lng: 18.7578882 },
    { lat: 48.5571625, lng: 18.7577286 },
    { lat: 48.55715, lng: 18.7576856 },
    { lat: 48.557141, lng: 18.7576436 },
    { lat: 48.5571214, lng: 18.7575505 },
    { lat: 48.5570783, lng: 18.7573958 },
    { lat: 48.5570668, lng: 18.7573499 },
    { lat: 48.5568235, lng: 18.7563303 },
    { lat: 48.5568056, lng: 18.7563328 },
    { lat: 48.5567701, lng: 18.7563448 },
    { lat: 48.5565833, lng: 18.7563961 },
    { lat: 48.5565167, lng: 18.7564144 },
    { lat: 48.5565139, lng: 18.7563613 },
    { lat: 48.5565077, lng: 18.756267 },
    { lat: 48.5565073, lng: 18.7562596 },
    { lat: 48.5565041, lng: 18.7562158 },
    { lat: 48.5564707, lng: 18.7558845 },
    { lat: 48.556468, lng: 18.7558556 },
    { lat: 48.5564649, lng: 18.7558179 },
    { lat: 48.5564625, lng: 18.7557961 },
    { lat: 48.5564582, lng: 18.7557546 },
    { lat: 48.5564483, lng: 18.7556529 },
    { lat: 48.5564703, lng: 18.755536 },
    { lat: 48.5568168, lng: 18.7537711 },
    { lat: 48.5568267, lng: 18.7532338 },
    { lat: 48.5567085, lng: 18.7529372 },
    { lat: 48.5564355, lng: 18.7522529 },
    { lat: 48.5561727, lng: 18.751591 },
    { lat: 48.5562501, lng: 18.7514546 },
    { lat: 48.5569016, lng: 18.7503181 },
    { lat: 48.5570885, lng: 18.7499916 },
    { lat: 48.5570143, lng: 18.74995 },
    { lat: 48.5557684, lng: 18.7492529 },
    { lat: 48.5557289, lng: 18.7492306 },
    { lat: 48.5555034, lng: 18.7491046 },
    { lat: 48.5555172, lng: 18.7490246 },
    { lat: 48.5555584, lng: 18.7487808 },
    { lat: 48.5556336, lng: 18.7483478 },
    { lat: 48.5556621, lng: 18.7481806 },
    { lat: 48.5556854, lng: 18.7480461 },
    { lat: 48.5557577, lng: 18.7476213 },
    { lat: 48.5558199, lng: 18.7472639 },
    { lat: 48.5558534, lng: 18.7470669 },
    { lat: 48.5558869, lng: 18.7468692 },
    { lat: 48.5559181, lng: 18.74669 },
    { lat: 48.556037, lng: 18.7459947 },
    { lat: 48.5560863, lng: 18.7458183 },
    { lat: 48.5560342, lng: 18.7457939 },
    { lat: 48.5559141, lng: 18.7457389 },
    { lat: 48.5557835, lng: 18.7456784 },
    { lat: 48.5556459, lng: 18.7456152 },
    { lat: 48.5554967, lng: 18.7455469 },
    { lat: 48.5553524, lng: 18.7454806 },
    { lat: 48.5551339, lng: 18.7453801 },
    { lat: 48.5549919, lng: 18.7453181 },
    { lat: 48.5547955, lng: 18.7452331 },
    { lat: 48.5550285, lng: 18.7438175 },
    { lat: 48.5555804, lng: 18.740458 },
    { lat: 48.5555941, lng: 18.7403769 },
    { lat: 48.5556469, lng: 18.7400534 },
    { lat: 48.5556827, lng: 18.7398366 },
    { lat: 48.5553671, lng: 18.7336671 },
    { lat: 48.5553724, lng: 18.7332125 },
    { lat: 48.5553989, lng: 18.7330093 },
    { lat: 48.555407, lng: 18.7329524 },
    { lat: 48.5554166, lng: 18.7328758 },
    { lat: 48.5555032, lng: 18.7321904 },
    { lat: 48.5551083, lng: 18.7314636 },
    { lat: 48.5550887, lng: 18.7309028 },
    { lat: 48.5549943, lng: 18.7281656 },
    { lat: 48.55485, lng: 18.7261499 },
    { lat: 48.5548443, lng: 18.7260826 },
    { lat: 48.5557219, lng: 18.7259388 },
    { lat: 48.556351, lng: 18.7258502 },
    { lat: 48.5568544, lng: 18.7257525 },
    { lat: 48.5575942, lng: 18.7254106 },
    { lat: 48.5576748, lng: 18.7249968 },
    { lat: 48.5578225, lng: 18.7244863 },
    { lat: 48.5584291, lng: 18.7231273 },
    { lat: 48.5583349, lng: 18.7215622 },
    { lat: 48.55853, lng: 18.7198205 },
    { lat: 48.5585269, lng: 18.7193175 },
    { lat: 48.558524, lng: 18.7188813 },
    { lat: 48.5581639, lng: 18.7178368 },
    { lat: 48.5578373, lng: 18.7174197 },
    { lat: 48.5575368, lng: 18.716755 },
    { lat: 48.5575138, lng: 18.7167443 },
    { lat: 48.5575001, lng: 18.7167391 },
    { lat: 48.5574702, lng: 18.7167285 },
    { lat: 48.55691, lng: 18.7165444 },
    { lat: 48.5564782, lng: 18.7164648 },
    { lat: 48.5563381, lng: 18.7164389 },
    { lat: 48.5558521, lng: 18.7164406 },
    { lat: 48.5551691, lng: 18.7164055 },
    { lat: 48.5546847, lng: 18.7161845 },
    { lat: 48.5539166, lng: 18.7150985 },
    { lat: 48.5535308, lng: 18.7130622 },
    { lat: 48.5538314, lng: 18.7117781 },
    { lat: 48.5542618, lng: 18.7107522 },
    { lat: 48.55422, lng: 18.7098213 },
    { lat: 48.5536729, lng: 18.7087365 },
    { lat: 48.5533704, lng: 18.7081376 },
    { lat: 48.5532737, lng: 18.7070624 },
    { lat: 48.5532872, lng: 18.7070013 },
    { lat: 48.5530405, lng: 18.7070504 },
    { lat: 48.5524902, lng: 18.7068392 },
    { lat: 48.5522458, lng: 18.7070574 },
    { lat: 48.552001, lng: 18.707098 },
    { lat: 48.5518597, lng: 18.7072397 },
    { lat: 48.550884, lng: 18.7072604 },
    { lat: 48.5496045, lng: 18.7076285 },
    { lat: 48.5489616, lng: 18.7081992 },
    { lat: 48.548581, lng: 18.7081483 },
    { lat: 48.5481072, lng: 18.7082906 },
    { lat: 48.5474457, lng: 18.7082595 },
    { lat: 48.5471325, lng: 18.7083916 },
    { lat: 48.5470884, lng: 18.7084101 },
    { lat: 48.5461772, lng: 18.708795 },
    { lat: 48.544328, lng: 18.7107314 },
    { lat: 48.5440776, lng: 18.7112179 },
    { lat: 48.5441232, lng: 18.71254 },
    { lat: 48.5439845, lng: 18.7131757 },
    { lat: 48.5438557, lng: 18.7137667 },
    { lat: 48.5437229, lng: 18.7145754 },
    { lat: 48.5433005, lng: 18.7147552 },
    { lat: 48.5422825, lng: 18.715634 },
    { lat: 48.5414043, lng: 18.7179714 },
    { lat: 48.5406864, lng: 18.7188085 },
    { lat: 48.5414446, lng: 18.7200898 },
    { lat: 48.5426439, lng: 18.7204137 },
    { lat: 48.5435905, lng: 18.7201479 },
    { lat: 48.5439886, lng: 18.7195504 },
    { lat: 48.544432, lng: 18.7200006 },
    { lat: 48.5444332, lng: 18.7204044 },
    { lat: 48.5440971, lng: 18.7216366 },
    { lat: 48.5437096, lng: 18.7224737 },
    { lat: 48.5434273, lng: 18.7230815 },
    { lat: 48.5430405, lng: 18.7239332 },
    { lat: 48.5427763, lng: 18.7244569 },
    { lat: 48.5427943, lng: 18.7244771 },
    { lat: 48.5428099, lng: 18.7244937 },
    { lat: 48.5429105, lng: 18.7247105 },
    { lat: 48.5432799, lng: 18.7255081 },
    { lat: 48.5436591, lng: 18.7264497 },
    { lat: 48.5442954, lng: 18.7272803 },
    { lat: 48.5443964, lng: 18.728716 },
    { lat: 48.544491, lng: 18.7291392 },
    { lat: 48.544568, lng: 18.7294852 },
    { lat: 48.5449876, lng: 18.7300187 },
    { lat: 48.5450783, lng: 18.7301342 },
    { lat: 48.5448713, lng: 18.7303972 },
    { lat: 48.544583, lng: 18.7308515 },
    { lat: 48.5438824, lng: 18.7318245 },
    { lat: 48.5432703, lng: 18.7332766 },
    { lat: 48.5432422, lng: 18.7333441 },
    { lat: 48.5429909, lng: 18.7339398 },
    { lat: 48.5427514, lng: 18.7345085 },
    { lat: 48.5427493, lng: 18.734513 },
    { lat: 48.5426142, lng: 18.734835 },
    { lat: 48.542513, lng: 18.735075 },
    { lat: 48.5425089, lng: 18.7350833 },
    { lat: 48.5424769, lng: 18.7351605 },
    { lat: 48.5421578, lng: 18.7359491 },
    { lat: 48.542071, lng: 18.736162 },
    { lat: 48.5410445, lng: 18.7384951 },
    { lat: 48.5409378, lng: 18.7387386 },
    { lat: 48.5408796, lng: 18.7388751 },
    { lat: 48.5406322, lng: 18.7390593 },
    { lat: 48.5406311, lng: 18.7390601 },
    { lat: 48.5403497, lng: 18.7392847 },
    { lat: 48.540016, lng: 18.7387305 },
    { lat: 48.5398763, lng: 18.7384996 },
    { lat: 48.5397773, lng: 18.7383339 },
    { lat: 48.5397146, lng: 18.7382798 },
    { lat: 48.5386933, lng: 18.737393 },
    { lat: 48.5385755, lng: 18.7373199 },
    { lat: 48.5385721, lng: 18.7373171 },
    { lat: 48.5385359, lng: 18.7372948 },
    { lat: 48.5385289, lng: 18.7372914 },
    { lat: 48.538163, lng: 18.7370674 },
    { lat: 48.5375649, lng: 18.7367026 },
  ],
  Lutila: [
    { lat: 48.6510524, lng: 18.8247931 },
    { lat: 48.6509058, lng: 18.8240163 },
    { lat: 48.6508758, lng: 18.8233095 },
    { lat: 48.6507197, lng: 18.8222189 },
    { lat: 48.6506386, lng: 18.8216604 },
    { lat: 48.6502828, lng: 18.8212355 },
    { lat: 48.6499149, lng: 18.8209728 },
    { lat: 48.6496003, lng: 18.8204318 },
    { lat: 48.6493048, lng: 18.8205157 },
    { lat: 48.6488437, lng: 18.8202143 },
    { lat: 48.64852, lng: 18.8198679 },
    { lat: 48.6483185, lng: 18.8195281 },
    { lat: 48.6482369, lng: 18.8193912 },
    { lat: 48.648055, lng: 18.8190837 },
    { lat: 48.6478274, lng: 18.818441 },
    { lat: 48.6475858, lng: 18.8175925 },
    { lat: 48.6475639, lng: 18.8175159 },
    { lat: 48.6474701, lng: 18.8176836 },
    { lat: 48.6473813, lng: 18.8178432 },
    { lat: 48.6472708, lng: 18.8179726 },
    { lat: 48.6471847, lng: 18.8180749 },
    { lat: 48.647016, lng: 18.8182101 },
    { lat: 48.6468493, lng: 18.8183444 },
    { lat: 48.6466819, lng: 18.8183101 },
    { lat: 48.6463758, lng: 18.8183675 },
    { lat: 48.6460929, lng: 18.8182167 },
    { lat: 48.6456357, lng: 18.817973 },
    { lat: 48.6454138, lng: 18.8176969 },
    { lat: 48.6453135, lng: 18.8176322 },
    { lat: 48.6451152, lng: 18.8175042 },
    { lat: 48.6449225, lng: 18.8166863 },
    { lat: 48.64468, lng: 18.8159748 },
    { lat: 48.6446486, lng: 18.8157411 },
    { lat: 48.6443656, lng: 18.8147695 },
    { lat: 48.6441068, lng: 18.8140215 },
    { lat: 48.644316, lng: 18.8137506 },
    { lat: 48.6445958, lng: 18.8133889 },
    { lat: 48.6448447, lng: 18.8133596 },
    { lat: 48.6449387, lng: 18.8133492 },
    { lat: 48.6449425, lng: 18.8132457 },
    { lat: 48.6449165, lng: 18.8132042 },
    { lat: 48.6448266, lng: 18.8130591 },
    { lat: 48.6447406, lng: 18.812922 },
    { lat: 48.6443147, lng: 18.8122388 },
    { lat: 48.643878, lng: 18.8114325 },
    { lat: 48.6435685, lng: 18.8116191 },
    { lat: 48.6434856, lng: 18.8118457 },
    { lat: 48.6432764, lng: 18.8122664 },
    { lat: 48.6432518, lng: 18.8123146 },
    { lat: 48.6430572, lng: 18.8124526 },
    { lat: 48.6429748, lng: 18.812512 },
    { lat: 48.6428851, lng: 18.8125469 },
    { lat: 48.6425966, lng: 18.8126607 },
    { lat: 48.642504, lng: 18.8126974 },
    { lat: 48.6420788, lng: 18.8127408 },
    { lat: 48.6419476, lng: 18.812151 },
    { lat: 48.6419387, lng: 18.8121075 },
    { lat: 48.6419195, lng: 18.8120206 },
    { lat: 48.6418971, lng: 18.8119801 },
    { lat: 48.6418838, lng: 18.811965 },
    { lat: 48.6418369, lng: 18.8119099 },
    { lat: 48.6419288, lng: 18.8117228 },
    { lat: 48.6420872, lng: 18.8115207 },
    { lat: 48.6421617, lng: 18.8113507 },
    { lat: 48.6423752, lng: 18.8112037 },
    { lat: 48.6424026, lng: 18.8111266 },
    { lat: 48.6423702, lng: 18.8110674 },
    { lat: 48.642349, lng: 18.8109818 },
    { lat: 48.6423276, lng: 18.8108984 },
    { lat: 48.6422086, lng: 18.8104341 },
    { lat: 48.6415405, lng: 18.8095155 },
    { lat: 48.6415018, lng: 18.809359 },
    { lat: 48.6413487, lng: 18.8087396 },
    { lat: 48.6408831, lng: 18.8076844 },
    { lat: 48.6408494, lng: 18.807601 },
    { lat: 48.640572, lng: 18.8069095 },
    { lat: 48.6401572, lng: 18.8059455 },
    { lat: 48.6398577, lng: 18.8048085 },
    { lat: 48.6400796, lng: 18.8043941 },
    { lat: 48.6405497, lng: 18.8040251 },
    { lat: 48.6405862, lng: 18.8039918 },
    { lat: 48.6407908, lng: 18.8038052 },
    { lat: 48.6410168, lng: 18.8035997 },
    { lat: 48.6410685, lng: 18.8035525 },
    { lat: 48.6411786, lng: 18.8033946 },
    { lat: 48.6413087, lng: 18.8032076 },
    { lat: 48.6413187, lng: 18.8031822 },
    { lat: 48.6414603, lng: 18.8028294 },
    { lat: 48.6415211, lng: 18.8026788 },
    { lat: 48.6416049, lng: 18.8024681 },
    { lat: 48.6416808, lng: 18.8022797 },
    { lat: 48.6416862, lng: 18.8022559 },
    { lat: 48.6417281, lng: 18.8020637 },
    { lat: 48.6417592, lng: 18.8019256 },
    { lat: 48.6418494, lng: 18.8015144 },
    { lat: 48.6418555, lng: 18.8014584 },
    { lat: 48.6418951, lng: 18.8010732 },
    { lat: 48.6419416, lng: 18.8006262 },
    { lat: 48.6419614, lng: 18.8005119 },
    { lat: 48.6419825, lng: 18.8003953 },
    { lat: 48.6422223, lng: 18.7990545 },
    { lat: 48.6423497, lng: 18.7986852 },
    { lat: 48.6423988, lng: 18.7984391 },
    { lat: 48.6426772, lng: 18.7982194 },
    { lat: 48.6427269, lng: 18.7981796 },
    { lat: 48.6428616, lng: 18.797972 },
    { lat: 48.642996, lng: 18.7977635 },
    { lat: 48.6432526, lng: 18.7974287 },
    { lat: 48.643395, lng: 18.7972423 },
    { lat: 48.6431617, lng: 18.7968362 },
    { lat: 48.6431196, lng: 18.7967624 },
    { lat: 48.642896, lng: 18.7962603 },
    { lat: 48.6425667, lng: 18.7956167 },
    { lat: 48.6425475, lng: 18.7954853 },
    { lat: 48.6424369, lng: 18.7951615 },
    { lat: 48.6417132, lng: 18.7934671 },
    { lat: 48.641494, lng: 18.792855 },
    { lat: 48.6408951, lng: 18.7916717 },
    { lat: 48.640668, lng: 18.7909728 },
    { lat: 48.6402758, lng: 18.7902901 },
    { lat: 48.64011, lng: 18.7902243 },
    { lat: 48.6397571, lng: 18.7899466 },
    { lat: 48.6392276, lng: 18.7888806 },
    { lat: 48.6392013, lng: 18.7888292 },
    { lat: 48.6390883, lng: 18.7886747 },
    { lat: 48.6390538, lng: 18.7885997 },
    { lat: 48.6390309, lng: 18.7885496 },
    { lat: 48.6389609, lng: 18.7884417 },
    { lat: 48.6387131, lng: 18.788789 },
    { lat: 48.638572, lng: 18.7895636 },
    { lat: 48.6382571, lng: 18.7898372 },
    { lat: 48.637976, lng: 18.7902159 },
    { lat: 48.6374807, lng: 18.7905592 },
    { lat: 48.637324, lng: 18.7909861 },
    { lat: 48.6367358, lng: 18.7917306 },
    { lat: 48.6365178, lng: 18.7922368 },
    { lat: 48.6362734, lng: 18.792548 },
    { lat: 48.6359377, lng: 18.7929418 },
    { lat: 48.6357313, lng: 18.7928937 },
    { lat: 48.6354405, lng: 18.7929636 },
    { lat: 48.6349311, lng: 18.793786 },
    { lat: 48.6345072, lng: 18.7942191 },
    { lat: 48.6345999, lng: 18.794869 },
    { lat: 48.634532, lng: 18.795726 },
    { lat: 48.6346123, lng: 18.7960755 },
    { lat: 48.6340074, lng: 18.7965184 },
    { lat: 48.6337854, lng: 18.7971434 },
    { lat: 48.6333347, lng: 18.7976892 },
    { lat: 48.6331978, lng: 18.7980253 },
    { lat: 48.6326346, lng: 18.7984489 },
    { lat: 48.6322342, lng: 18.7989434 },
    { lat: 48.6315589, lng: 18.7995262 },
    { lat: 48.6308478, lng: 18.8001792 },
    { lat: 48.630619, lng: 18.800354 },
    { lat: 48.6304833, lng: 18.8007381 },
    { lat: 48.6302658, lng: 18.8008478 },
    { lat: 48.6300586, lng: 18.801174 },
    { lat: 48.6297024, lng: 18.801307 },
    { lat: 48.6291836, lng: 18.8022989 },
    { lat: 48.6284389, lng: 18.8027619 },
    { lat: 48.6280953, lng: 18.8027711 },
    { lat: 48.6276225, lng: 18.8034302 },
    { lat: 48.6271694, lng: 18.8032218 },
    { lat: 48.6271313, lng: 18.8032067 },
    { lat: 48.6271164, lng: 18.803201 },
    { lat: 48.6270946, lng: 18.8031698 },
    { lat: 48.6270715, lng: 18.803139 },
    { lat: 48.6270481, lng: 18.8031022 },
    { lat: 48.6268413, lng: 18.8034808 },
    { lat: 48.6267247, lng: 18.8036872 },
    { lat: 48.6261335, lng: 18.8044153 },
    { lat: 48.6260327, lng: 18.8046885 },
    { lat: 48.6259943, lng: 18.8047933 },
    { lat: 48.6259468, lng: 18.80516 },
    { lat: 48.6259178, lng: 18.8055306 },
    { lat: 48.6258636, lng: 18.8062197 },
    { lat: 48.6256591, lng: 18.8068147 },
    { lat: 48.6254789, lng: 18.8071424 },
    { lat: 48.6255179, lng: 18.8078029 },
    { lat: 48.6250306, lng: 18.8084487 },
    { lat: 48.6247484, lng: 18.8090879 },
    { lat: 48.6244571, lng: 18.8096002 },
    { lat: 48.6242903, lng: 18.8098182 },
    { lat: 48.6238006, lng: 18.8104574 },
    { lat: 48.6236392, lng: 18.8108014 },
    { lat: 48.6236574, lng: 18.811624 },
    { lat: 48.6236229, lng: 18.8116338 },
    { lat: 48.6233905, lng: 18.8115645 },
    { lat: 48.6232554, lng: 18.8119131 },
    { lat: 48.6226228, lng: 18.8131257 },
    { lat: 48.6226083, lng: 18.8135345 },
    { lat: 48.6223864, lng: 18.814339 },
    { lat: 48.6219892, lng: 18.8149418 },
    { lat: 48.6219095, lng: 18.8150536 },
    { lat: 48.6213461, lng: 18.8159036 },
    { lat: 48.6211737, lng: 18.8162538 },
    { lat: 48.6207413, lng: 18.8168469 },
    { lat: 48.6206203, lng: 18.8172548 },
    { lat: 48.6204931, lng: 18.8176869 },
    { lat: 48.620301, lng: 18.8180907 },
    { lat: 48.6194007, lng: 18.8195745 },
    { lat: 48.6191173, lng: 18.8199368 },
    { lat: 48.6190013, lng: 18.8202392 },
    { lat: 48.6186484, lng: 18.8207352 },
    { lat: 48.6181179, lng: 18.8214825 },
    { lat: 48.6155613, lng: 18.8189955 },
    { lat: 48.6153618, lng: 18.8187825 },
    { lat: 48.6145605, lng: 18.8171482 },
    { lat: 48.6139703, lng: 18.8179838 },
    { lat: 48.612964, lng: 18.8194878 },
    { lat: 48.6124597, lng: 18.820456 },
    { lat: 48.6124329, lng: 18.8204171 },
    { lat: 48.6123781, lng: 18.8203598 },
    { lat: 48.6123416, lng: 18.8203125 },
    { lat: 48.6114006, lng: 18.8178802 },
    { lat: 48.6106923, lng: 18.815978 },
    { lat: 48.6087131, lng: 18.8173784 },
    { lat: 48.6072223, lng: 18.818975 },
    { lat: 48.606185, lng: 18.8199095 },
    { lat: 48.6061589, lng: 18.8202508 },
    { lat: 48.6055738, lng: 18.8208162 },
    { lat: 48.6043836, lng: 18.8217556 },
    { lat: 48.6047262, lng: 18.8236112 },
    { lat: 48.6047558, lng: 18.8237662 },
    { lat: 48.6047887, lng: 18.823944 },
    { lat: 48.6049584, lng: 18.8248421 },
    { lat: 48.6050475, lng: 18.8253183 },
    { lat: 48.6054533, lng: 18.8272402 },
    { lat: 48.6056207, lng: 18.8280491 },
    { lat: 48.6057334, lng: 18.8286322 },
    { lat: 48.6058152, lng: 18.8291645 },
    { lat: 48.605964, lng: 18.8301776 },
    { lat: 48.6060053, lng: 18.8303855 },
    { lat: 48.6060843, lng: 18.8305592 },
    { lat: 48.6061626, lng: 18.8306572 },
    { lat: 48.6062581, lng: 18.830735 },
    { lat: 48.6062898, lng: 18.8307619 },
    { lat: 48.6063616, lng: 18.8308271 },
    { lat: 48.6064732, lng: 18.8308627 },
    { lat: 48.606918, lng: 18.8310641 },
    { lat: 48.6072709, lng: 18.8312382 },
    { lat: 48.6075227, lng: 18.8313958 },
    { lat: 48.6076834, lng: 18.8315132 },
    { lat: 48.6078124, lng: 18.8316312 },
    { lat: 48.6080989, lng: 18.8319589 },
    { lat: 48.6084669, lng: 18.8324188 },
    { lat: 48.6086156, lng: 18.8325708 },
    { lat: 48.6087896, lng: 18.8327494 },
    { lat: 48.6087977, lng: 18.8330317 },
    { lat: 48.6087993, lng: 18.8331077 },
    { lat: 48.6086582, lng: 18.8333197 },
    { lat: 48.6084266, lng: 18.8335966 },
    { lat: 48.6081586, lng: 18.8339045 },
    { lat: 48.607807, lng: 18.8343217 },
    { lat: 48.6077273, lng: 18.8344152 },
    { lat: 48.6071794, lng: 18.8351974 },
    { lat: 48.6068194, lng: 18.8358842 },
    { lat: 48.6069376, lng: 18.836032 },
    { lat: 48.6071065, lng: 18.8362433 },
    { lat: 48.607585, lng: 18.8367065 },
    { lat: 48.6073727, lng: 18.8370045 },
    { lat: 48.6072457, lng: 18.8371838 },
    { lat: 48.6068454, lng: 18.837533 },
    { lat: 48.6066435, lng: 18.8377668 },
    { lat: 48.6063957, lng: 18.8383178 },
    { lat: 48.6063795, lng: 18.8383521 },
    { lat: 48.6063192, lng: 18.8384501 },
    { lat: 48.6062338, lng: 18.8385856 },
    { lat: 48.6061122, lng: 18.8387814 },
    { lat: 48.6058389, lng: 18.8390788 },
    { lat: 48.6056672, lng: 18.8392039 },
    { lat: 48.6054933, lng: 18.839285 },
    { lat: 48.6052622, lng: 18.839285 },
    { lat: 48.6049781, lng: 18.8391151 },
    { lat: 48.6046287, lng: 18.8391639 },
    { lat: 48.6040431, lng: 18.8393073 },
    { lat: 48.6039082, lng: 18.839531 },
    { lat: 48.603837, lng: 18.8397247 },
    { lat: 48.6038121, lng: 18.8400448 },
    { lat: 48.6037349, lng: 18.8404052 },
    { lat: 48.6037119, lng: 18.8406121 },
    { lat: 48.6035548, lng: 18.8414074 },
    { lat: 48.6034649, lng: 18.8415427 },
    { lat: 48.6034492, lng: 18.841567 },
    { lat: 48.6032749, lng: 18.8421826 },
    { lat: 48.6032038, lng: 18.8425274 },
    { lat: 48.6031414, lng: 18.842719 },
    { lat: 48.6032439, lng: 18.8428679 },
    { lat: 48.6033429, lng: 18.8430119 },
    { lat: 48.6034387, lng: 18.843142 },
    { lat: 48.6034108, lng: 18.8433455 },
    { lat: 48.6033998, lng: 18.8434338 },
    { lat: 48.6033929, lng: 18.8434784 },
    { lat: 48.6033466, lng: 18.8436471 },
    { lat: 48.60332, lng: 18.8437443 },
    { lat: 48.6033179, lng: 18.8437492 },
    { lat: 48.6032995, lng: 18.8438682 },
    { lat: 48.6032834, lng: 18.8439727 },
    { lat: 48.6032708, lng: 18.8440569 },
    { lat: 48.6032708, lng: 18.8440583 },
    { lat: 48.603235, lng: 18.844127 },
    { lat: 48.6031961, lng: 18.8442028 },
    { lat: 48.6031581, lng: 18.8442758 },
    { lat: 48.6031276, lng: 18.8443168 },
    { lat: 48.6030949, lng: 18.8443609 },
    { lat: 48.6030517, lng: 18.8444191 },
    { lat: 48.6030128, lng: 18.8444725 },
    { lat: 48.6030086, lng: 18.8444776 },
    { lat: 48.6029522, lng: 18.8445417 },
    { lat: 48.6029042, lng: 18.8445958 },
    { lat: 48.6028958, lng: 18.8446064 },
    { lat: 48.6028926, lng: 18.8446093 },
    { lat: 48.6028375, lng: 18.8446592 },
    { lat: 48.6027881, lng: 18.8447039 },
    { lat: 48.6027828, lng: 18.844711 },
    { lat: 48.6027351, lng: 18.8447715 },
    { lat: 48.6026917, lng: 18.8448268 },
    { lat: 48.6026443, lng: 18.844888 },
    { lat: 48.6025987, lng: 18.8449461 },
    { lat: 48.6025956, lng: 18.8449515 },
    { lat: 48.6025541, lng: 18.8450209 },
    { lat: 48.6025168, lng: 18.8450841 },
    { lat: 48.6024755, lng: 18.8451535 },
    { lat: 48.6024744, lng: 18.8451554 },
    { lat: 48.6024347, lng: 18.8452185 },
    { lat: 48.6023899, lng: 18.8452897 },
    { lat: 48.6023504, lng: 18.8453528 },
    { lat: 48.6023077, lng: 18.8454209 },
    { lat: 48.6022234, lng: 18.8455541 },
    { lat: 48.6022192, lng: 18.8455596 },
    { lat: 48.6021685, lng: 18.8456265 },
    { lat: 48.6021453, lng: 18.8456571 },
    { lat: 48.6021488, lng: 18.8457472 },
    { lat: 48.6021657, lng: 18.8460947 },
    { lat: 48.6022593, lng: 18.8462678 },
    { lat: 48.6027516, lng: 18.8469336 },
    { lat: 48.602874, lng: 18.847098 },
    { lat: 48.6045163, lng: 18.8493168 },
    { lat: 48.6045413, lng: 18.8493672 },
    { lat: 48.6046985, lng: 18.8496765 },
    { lat: 48.6051239, lng: 18.8501193 },
    { lat: 48.6054567, lng: 18.8503173 },
    { lat: 48.6055801, lng: 18.8503901 },
    { lat: 48.6062095, lng: 18.8511299 },
    { lat: 48.6066799, lng: 18.8517852 },
    { lat: 48.6068828, lng: 18.8514608 },
    { lat: 48.6069332, lng: 18.8515162 },
    { lat: 48.6070719, lng: 18.8516712 },
    { lat: 48.6070909, lng: 18.8516914 },
    { lat: 48.607895, lng: 18.8525824 },
    { lat: 48.6082924, lng: 18.852896 },
    { lat: 48.6100197, lng: 18.8535822 },
    { lat: 48.6102817, lng: 18.8537455 },
    { lat: 48.6104822, lng: 18.8542396 },
    { lat: 48.6107518, lng: 18.8547847 },
    { lat: 48.6111199, lng: 18.8551621 },
    { lat: 48.6112639, lng: 18.8562209 },
    { lat: 48.6120295, lng: 18.8569818 },
    { lat: 48.6127653, lng: 18.8591454 },
    { lat: 48.6132814, lng: 18.8598122 },
    { lat: 48.6138036, lng: 18.8606643 },
    { lat: 48.6143122, lng: 18.8617101 },
    { lat: 48.6152117, lng: 18.8632897 },
    { lat: 48.6156651, lng: 18.8645523 },
    { lat: 48.6154743, lng: 18.8658146 },
    { lat: 48.6158631, lng: 18.8672032 },
    { lat: 48.6173409, lng: 18.869847 },
    { lat: 48.6174562, lng: 18.8702479 },
    { lat: 48.6181576, lng: 18.8726647 },
    { lat: 48.6191874, lng: 18.8740646 },
    { lat: 48.6192587, lng: 18.8741367 },
    { lat: 48.6193527, lng: 18.8742315 },
    { lat: 48.6197552, lng: 18.8745341 },
    { lat: 48.6202709, lng: 18.8753491 },
    { lat: 48.6206082, lng: 18.8758091 },
    { lat: 48.6208635, lng: 18.8760571 },
    { lat: 48.6211645, lng: 18.8761664 },
    { lat: 48.6217704, lng: 18.8761724 },
    { lat: 48.6220845, lng: 18.8762054 },
    { lat: 48.6224337, lng: 18.8758955 },
    { lat: 48.622698, lng: 18.8759069 },
    { lat: 48.6230284, lng: 18.8759489 },
    { lat: 48.6234023, lng: 18.8760468 },
    { lat: 48.6238884, lng: 18.876377 },
    { lat: 48.6242035, lng: 18.8763884 },
    { lat: 48.6258145, lng: 18.8760535 },
    { lat: 48.6265988, lng: 18.8757388 },
    { lat: 48.6273453, lng: 18.8754111 },
    { lat: 48.6274181, lng: 18.8753797 },
    { lat: 48.6277634, lng: 18.875492 },
    { lat: 48.6279864, lng: 18.8756194 },
    { lat: 48.6289903, lng: 18.8766078 },
    { lat: 48.6293724, lng: 18.8768883 },
    { lat: 48.6311422, lng: 18.8777288 },
    { lat: 48.6316258, lng: 18.8779425 },
    { lat: 48.632699, lng: 18.878418 },
    { lat: 48.6338194, lng: 18.8782795 },
    { lat: 48.6343132, lng: 18.878277 },
    { lat: 48.6344317, lng: 18.8783041 },
    { lat: 48.6344758, lng: 18.8783067 },
    { lat: 48.6347225, lng: 18.8783437 },
    { lat: 48.6363229, lng: 18.878984 },
    { lat: 48.6365343, lng: 18.8790593 },
    { lat: 48.6371753, lng: 18.879288 },
    { lat: 48.6378324, lng: 18.8789298 },
    { lat: 48.638084, lng: 18.8789348 },
    { lat: 48.6384198, lng: 18.8791039 },
    { lat: 48.639009, lng: 18.8796186 },
    { lat: 48.6403127, lng: 18.8802988 },
    { lat: 48.6403553, lng: 18.8808843 },
    { lat: 48.6400298, lng: 18.8816338 },
    { lat: 48.6395945, lng: 18.8819388 },
    { lat: 48.63959, lng: 18.8825523 },
    { lat: 48.6395867, lng: 18.8826849 },
    { lat: 48.6400304, lng: 18.8828025 },
    { lat: 48.6402772, lng: 18.8827549 },
    { lat: 48.6408796, lng: 18.8828257 },
    { lat: 48.6413864, lng: 18.8828563 },
    { lat: 48.6420385, lng: 18.8828225 },
    { lat: 48.6421773, lng: 18.8827373 },
    { lat: 48.6441289, lng: 18.8829682 },
    { lat: 48.644435, lng: 18.8830667 },
    { lat: 48.6457526, lng: 18.8841081 },
    { lat: 48.6459834, lng: 18.8846654 },
    { lat: 48.6460502, lng: 18.8849582 },
    { lat: 48.6466341, lng: 18.8854112 },
    { lat: 48.6474361, lng: 18.8854853 },
    { lat: 48.6477012, lng: 18.8855961 },
    { lat: 48.648202, lng: 18.8865582 },
    { lat: 48.6483371, lng: 18.8875158 },
    { lat: 48.6500922, lng: 18.8858664 },
    { lat: 48.6505019, lng: 18.8858522 },
    { lat: 48.6512441, lng: 18.8857243 },
    { lat: 48.6521282, lng: 18.8856408 },
    { lat: 48.6528704, lng: 18.8857782 },
    { lat: 48.6532576, lng: 18.8859371 },
    { lat: 48.6537814, lng: 18.8859504 },
    { lat: 48.6537693, lng: 18.8859122 },
    { lat: 48.6532375, lng: 18.8841916 },
    { lat: 48.6525769, lng: 18.882055 },
    { lat: 48.6540553, lng: 18.876773 },
    { lat: 48.6537673, lng: 18.8760237 },
    { lat: 48.6536864, lng: 18.8759181 },
    { lat: 48.6534749, lng: 18.875643 },
    { lat: 48.6526024, lng: 18.8749494 },
    { lat: 48.6522707, lng: 18.874992 },
    { lat: 48.6519813, lng: 18.8748684 },
    { lat: 48.6513286, lng: 18.8749995 },
    { lat: 48.6507101, lng: 18.8740335 },
    { lat: 48.6500646, lng: 18.8732789 },
    { lat: 48.6493354, lng: 18.8727225 },
    { lat: 48.64939, lng: 18.8727093 },
    { lat: 48.649961, lng: 18.8723219 },
    { lat: 48.6501459, lng: 18.8720594 },
    { lat: 48.6503899, lng: 18.8718302 },
    { lat: 48.6508549, lng: 18.8716642 },
    { lat: 48.6510708, lng: 18.8714997 },
    { lat: 48.6511626, lng: 18.8712453 },
    { lat: 48.6504474, lng: 18.8707169 },
    { lat: 48.6501484, lng: 18.8702985 },
    { lat: 48.6501371, lng: 18.8702128 },
    { lat: 48.6503735, lng: 18.8696568 },
    { lat: 48.6507456, lng: 18.8691583 },
    { lat: 48.6509001, lng: 18.8688102 },
    { lat: 48.6512746, lng: 18.8684002 },
    { lat: 48.651615, lng: 18.8683287 },
    { lat: 48.6517848, lng: 18.8679082 },
    { lat: 48.651826, lng: 18.8675713 },
    { lat: 48.6518687, lng: 18.8675242 },
    { lat: 48.6521422, lng: 18.8676225 },
    { lat: 48.6524, lng: 18.8678332 },
    { lat: 48.6524131, lng: 18.8678445 },
    { lat: 48.6525802, lng: 18.867981 },
    { lat: 48.653137, lng: 18.8678472 },
    { lat: 48.6537099, lng: 18.8672604 },
    { lat: 48.6537392, lng: 18.8670626 },
    { lat: 48.6537147, lng: 18.8669592 },
    { lat: 48.6536204, lng: 18.8665524 },
    { lat: 48.6536605, lng: 18.8665198 },
    { lat: 48.6539167, lng: 18.8663067 },
    { lat: 48.6546819, lng: 18.86523 },
    { lat: 48.654829, lng: 18.8650225 },
    { lat: 48.6553663, lng: 18.8641192 },
    { lat: 48.6558465, lng: 18.8632209 },
    { lat: 48.6563656, lng: 18.8622937 },
    { lat: 48.6568149, lng: 18.8613701 },
    { lat: 48.6570086, lng: 18.8609955 },
    { lat: 48.6570352, lng: 18.8609425 },
    { lat: 48.6570569, lng: 18.8609017 },
    { lat: 48.657105, lng: 18.860808 },
    { lat: 48.6574611, lng: 18.8602834 },
    { lat: 48.6574951, lng: 18.86011 },
    { lat: 48.6575582, lng: 18.859585 },
    { lat: 48.6578351, lng: 18.8589231 },
    { lat: 48.6583874, lng: 18.8580954 },
    { lat: 48.6589985, lng: 18.8571797 },
    { lat: 48.659407, lng: 18.8563999 },
    { lat: 48.6597483, lng: 18.8557393 },
    { lat: 48.6600906, lng: 18.8554056 },
    { lat: 48.6601952, lng: 18.8545287 },
    { lat: 48.6601806, lng: 18.8534646 },
    { lat: 48.6601603, lng: 18.8534231 },
    { lat: 48.6598993, lng: 18.8531929 },
    { lat: 48.6598705, lng: 18.8531396 },
    { lat: 48.6600577, lng: 18.8520745 },
    { lat: 48.6600979, lng: 18.8516963 },
    { lat: 48.6603083, lng: 18.8510793 },
    { lat: 48.6599691, lng: 18.8505854 },
    { lat: 48.6608238, lng: 18.850107 },
    { lat: 48.6605091, lng: 18.8495164 },
    { lat: 48.6604299, lng: 18.8492957 },
    { lat: 48.6603103, lng: 18.8487731 },
    { lat: 48.6601467, lng: 18.847401 },
    { lat: 48.6599673, lng: 18.8467252 },
    { lat: 48.6596069, lng: 18.8463006 },
    { lat: 48.6591012, lng: 18.8458143 },
    { lat: 48.6588309, lng: 18.8444724 },
    { lat: 48.6585451, lng: 18.8435282 },
    { lat: 48.6580971, lng: 18.8415046 },
    { lat: 48.6577416, lng: 18.8402209 },
    { lat: 48.657676, lng: 18.8401495 },
    { lat: 48.6573362, lng: 18.8397721 },
    { lat: 48.6573013, lng: 18.8397334 },
    { lat: 48.6572499, lng: 18.8396764 },
    { lat: 48.6570025, lng: 18.8384944 },
    { lat: 48.6567281, lng: 18.8381193 },
    { lat: 48.6567233, lng: 18.8381134 },
    { lat: 48.6565773, lng: 18.837997 },
    { lat: 48.6564245, lng: 18.8378749 },
    { lat: 48.6562738, lng: 18.8377547 },
    { lat: 48.6561163, lng: 18.8376296 },
    { lat: 48.6559657, lng: 18.8375095 },
    { lat: 48.6557858, lng: 18.8373663 },
    { lat: 48.6555365, lng: 18.8371723 },
    { lat: 48.6554904, lng: 18.837131 },
    { lat: 48.6551069, lng: 18.8367798 },
    { lat: 48.6550759, lng: 18.8365742 },
    { lat: 48.6548199, lng: 18.8364682 },
    { lat: 48.6545745, lng: 18.8363254 },
    { lat: 48.6541122, lng: 18.8359775 },
    { lat: 48.6539604, lng: 18.8355954 },
    { lat: 48.6539357, lng: 18.8354881 },
    { lat: 48.6539258, lng: 18.8354293 },
    { lat: 48.6537408, lng: 18.8349705 },
    { lat: 48.6533699, lng: 18.834737 },
    { lat: 48.6531706, lng: 18.8343722 },
    { lat: 48.6530758, lng: 18.8339163 },
    { lat: 48.6530065, lng: 18.8337116 },
    { lat: 48.6524813, lng: 18.8329817 },
    { lat: 48.6522757, lng: 18.8328859 },
    { lat: 48.6522132, lng: 18.832857 },
    { lat: 48.6520912, lng: 18.8326781 },
    { lat: 48.6519761, lng: 18.8323508 },
    { lat: 48.6519405, lng: 18.8321033 },
    { lat: 48.6519532, lng: 18.8318022 },
    { lat: 48.6521098, lng: 18.8314958 },
    { lat: 48.6523387, lng: 18.8311281 },
    { lat: 48.6529459, lng: 18.8304911 },
    { lat: 48.6526934, lng: 18.8302964 },
    { lat: 48.6526471, lng: 18.8299477 },
    { lat: 48.6526418, lng: 18.829152 },
    { lat: 48.6522558, lng: 18.8279791 },
    { lat: 48.6518892, lng: 18.8272602 },
    { lat: 48.6513946, lng: 18.8265974 },
    { lat: 48.6512109, lng: 18.8258431 },
    { lat: 48.6510524, lng: 18.8247931 },
  ],
  Vyhne: [
    { lat: 48.480838, lng: 18.778353 },
    { lat: 48.480859, lng: 18.778691 },
    { lat: 48.480805, lng: 18.779343 },
    { lat: 48.480763, lng: 18.779442 },
    { lat: 48.480435, lng: 18.780166 },
    { lat: 48.480275, lng: 18.780569 },
    { lat: 48.480302, lng: 18.781149 },
    { lat: 48.480385, lng: 18.781689 },
    { lat: 48.480458, lng: 18.782236 },
    { lat: 48.480511, lng: 18.78275 },
    { lat: 48.4808068, lng: 18.7836941 },
    { lat: 48.480885, lng: 18.784036 },
    { lat: 48.481172, lng: 18.784438 },
    { lat: 48.481633, lng: 18.785206 },
    { lat: 48.481787, lng: 18.78539 },
    { lat: 48.48177, lng: 18.786353 },
    { lat: 48.481764, lng: 18.787169 },
    { lat: 48.481345, lng: 18.787596 },
    { lat: 48.480897, lng: 18.788371 },
    { lat: 48.480825, lng: 18.78879 },
    { lat: 48.480757, lng: 18.789271 },
    { lat: 48.480433, lng: 18.789954 },
    { lat: 48.48015, lng: 18.790294 },
    { lat: 48.47991, lng: 18.790739 },
    { lat: 48.479789, lng: 18.791223 },
    { lat: 48.479472, lng: 18.791835 },
    { lat: 48.478916, lng: 18.792541 },
    { lat: 48.478757, lng: 18.793291 },
    { lat: 48.478475, lng: 18.793901 },
    { lat: 48.478175, lng: 18.794372 },
    { lat: 48.477606, lng: 18.795266 },
    { lat: 48.477473, lng: 18.795645 },
    { lat: 48.477299, lng: 18.796317 },
    { lat: 48.477204, lng: 18.79668 },
    { lat: 48.477197, lng: 18.796938 },
    { lat: 48.477215, lng: 18.797195 },
    { lat: 48.477315, lng: 18.79763 },
    { lat: 48.477344, lng: 18.797617 },
    { lat: 48.477511, lng: 18.79755 },
    { lat: 48.477682, lng: 18.797685 },
    { lat: 48.477798, lng: 18.798583 },
    { lat: 48.477711, lng: 18.799635 },
    { lat: 48.477537, lng: 18.800066 },
    { lat: 48.477521, lng: 18.800107 },
    { lat: 48.4779267, lng: 18.8004701 },
    { lat: 48.478044, lng: 18.800575 },
    { lat: 48.478377, lng: 18.800759 },
    { lat: 48.478742, lng: 18.800822 },
    { lat: 48.479288, lng: 18.801012 },
    { lat: 48.479823, lng: 18.801017 },
    { lat: 48.48008, lng: 18.801036 },
    { lat: 48.4804116, lng: 18.8012193 },
    { lat: 48.480496, lng: 18.801266 },
    { lat: 48.480914, lng: 18.801359 },
    { lat: 48.481295, lng: 18.801513 },
    { lat: 48.481645, lng: 18.801481 },
    { lat: 48.481951, lng: 18.801481 },
    { lat: 48.482218, lng: 18.801923 },
    { lat: 48.48255, lng: 18.802368 },
    { lat: 48.482884, lng: 18.802854 },
    { lat: 48.483209, lng: 18.803112 },
    { lat: 48.483487, lng: 18.803303 },
    { lat: 48.483912, lng: 18.803484 },
    { lat: 48.483929, lng: 18.803915 },
    { lat: 48.483863, lng: 18.804419 },
    { lat: 48.483738, lng: 18.805074 },
    { lat: 48.483664, lng: 18.805549 },
    { lat: 48.483605, lng: 18.805779 },
    { lat: 48.483407, lng: 18.806275 },
    { lat: 48.483417, lng: 18.806609 },
    { lat: 48.483677, lng: 18.807031 },
    { lat: 48.483953, lng: 18.807737 },
    { lat: 48.484253, lng: 18.808318 },
    { lat: 48.484271, lng: 18.808748 },
    { lat: 48.484193, lng: 18.809027 },
    { lat: 48.483928, lng: 18.809632 },
    { lat: 48.48318, lng: 18.810247 },
    { lat: 48.482793, lng: 18.810755 },
    { lat: 48.482334, lng: 18.811007 },
    { lat: 48.482034, lng: 18.811406 },
    { lat: 48.481816, lng: 18.811728 },
    { lat: 48.481751, lng: 18.81213 },
    { lat: 48.481762, lng: 18.812614 },
    { lat: 48.481866, lng: 18.813644 },
    { lat: 48.481789, lng: 18.814122 },
    { lat: 48.481668, lng: 18.815188 },
    { lat: 48.481643, lng: 18.815466 },
    { lat: 48.481636, lng: 18.815547 },
    { lat: 48.481951, lng: 18.815289 },
    { lat: 48.482316, lng: 18.815526 },
    { lat: 48.482414, lng: 18.815515 },
    { lat: 48.482668, lng: 18.815357 },
    { lat: 48.482925, lng: 18.815241 },
    { lat: 48.483179, lng: 18.815178 },
    { lat: 48.483313, lng: 18.815138 },
    { lat: 48.483513, lng: 18.81503 },
    { lat: 48.483708, lng: 18.814951 },
    { lat: 48.48391, lng: 18.814806 },
    { lat: 48.484108, lng: 18.814739 },
    { lat: 48.48423, lng: 18.814767 },
    { lat: 48.484359, lng: 18.814702 },
    { lat: 48.484455, lng: 18.814818 },
    { lat: 48.484614, lng: 18.8149 },
    { lat: 48.484757, lng: 18.814926 },
    { lat: 48.485419, lng: 18.815048 },
    { lat: 48.485541, lng: 18.815103 },
    { lat: 48.4856288, lng: 18.8151562 },
    { lat: 48.485863, lng: 18.815298 },
    { lat: 48.486217, lng: 18.815583 },
    { lat: 48.48637, lng: 18.81563 },
    { lat: 48.487034, lng: 18.815707 },
    { lat: 48.487104, lng: 18.815645 },
    { lat: 48.487137, lng: 18.815641 },
    { lat: 48.487179, lng: 18.815627 },
    { lat: 48.487816, lng: 18.815021 },
    { lat: 48.488252, lng: 18.814436 },
    { lat: 48.488328, lng: 18.814199 },
    { lat: 48.488748, lng: 18.814052 },
    { lat: 48.489039, lng: 18.813858 },
    { lat: 48.489404, lng: 18.813897 },
    { lat: 48.489444, lng: 18.814028 },
    { lat: 48.489535, lng: 18.814338 },
    { lat: 48.489812, lng: 18.814828 },
    { lat: 48.489921, lng: 18.81539 },
    { lat: 48.489894, lng: 18.815734 },
    { lat: 48.489887, lng: 18.816212 },
    { lat: 48.490198, lng: 18.817057 },
    { lat: 48.490224, lng: 18.817431 },
    { lat: 48.490152, lng: 18.81787 },
    { lat: 48.489944, lng: 18.818567 },
    { lat: 48.490034, lng: 18.819152 },
    { lat: 48.490398, lng: 18.81991 },
    { lat: 48.490629, lng: 18.820089 },
    { lat: 48.491044, lng: 18.820339 },
    { lat: 48.491499, lng: 18.820313 },
    { lat: 48.491732, lng: 18.820364 },
    { lat: 48.491973, lng: 18.820462 },
    { lat: 48.4919657, lng: 18.8204982 },
    { lat: 48.491847, lng: 18.821085 },
    { lat: 48.491707, lng: 18.821723 },
    { lat: 48.4917065, lng: 18.821865 },
    { lat: 48.491705, lng: 18.822257 },
    { lat: 48.491738, lng: 18.822792 },
    { lat: 48.491775, lng: 18.823314 },
    { lat: 48.492237, lng: 18.82409 },
    { lat: 48.49239, lng: 18.82447 },
    { lat: 48.492567, lng: 18.82471 },
    { lat: 48.4929, lng: 18.824675 },
    { lat: 48.493321, lng: 18.825023 },
    { lat: 48.493361, lng: 18.825523 },
    { lat: 48.49329, lng: 18.82616 },
    { lat: 48.493161, lng: 18.826862 },
    { lat: 48.492932, lng: 18.827899 },
    { lat: 48.492862, lng: 18.828451 },
    { lat: 48.492837, lng: 18.828754 },
    { lat: 48.492872, lng: 18.829121 },
    { lat: 48.493014, lng: 18.829557 },
    { lat: 48.492644, lng: 18.830158 },
    { lat: 48.492223, lng: 18.830376 },
    { lat: 48.492118, lng: 18.83042 },
    { lat: 48.491974, lng: 18.830753 },
    { lat: 48.491971, lng: 18.831125 },
    { lat: 48.492049, lng: 18.83139 },
    { lat: 48.492151, lng: 18.831837 },
    { lat: 48.492357, lng: 18.832288 },
    { lat: 48.492404, lng: 18.832559 },
    { lat: 48.492526, lng: 18.833079 },
    { lat: 48.492528, lng: 18.833401 },
    { lat: 48.492583, lng: 18.833805 },
    { lat: 48.492559, lng: 18.83453 },
    { lat: 48.492991, lng: 18.834799 },
    { lat: 48.493397, lng: 18.834961 },
    { lat: 48.493641, lng: 18.834845 },
    { lat: 48.49399, lng: 18.834966 },
    { lat: 48.49422, lng: 18.835002 },
    { lat: 48.494427, lng: 18.834942 },
    { lat: 48.494724, lng: 18.834748 },
    { lat: 48.4949859, lng: 18.8342592 },
    { lat: 48.4951612, lng: 18.8339238 },
    { lat: 48.4957448, lng: 18.8332451 },
    { lat: 48.4963182, lng: 18.8326729 },
    { lat: 48.4966219, lng: 18.8321161 },
    { lat: 48.4976155, lng: 18.8298082 },
    { lat: 48.4976746, lng: 18.8297542 },
    { lat: 48.4979395, lng: 18.8295565 },
    { lat: 48.4979873, lng: 18.829521 },
    { lat: 48.4980632, lng: 18.829464 },
    { lat: 48.4982202, lng: 18.8292667 },
    { lat: 48.4982843, lng: 18.8291861 },
    { lat: 48.4984822, lng: 18.8288719 },
    { lat: 48.4986838, lng: 18.8285433 },
    { lat: 48.498855, lng: 18.8271784 },
    { lat: 48.4992234, lng: 18.8264422 },
    { lat: 48.4992804, lng: 18.8261485 },
    { lat: 48.4993544, lng: 18.8262269 },
    { lat: 48.4995064, lng: 18.8261794 },
    { lat: 48.499635, lng: 18.8261389 },
    { lat: 48.4997975, lng: 18.8260112 },
    { lat: 48.5000489, lng: 18.8259463 },
    { lat: 48.5005112, lng: 18.8259442 },
    { lat: 48.5013916, lng: 18.8267165 },
    { lat: 48.5012083, lng: 18.8279343 },
    { lat: 48.501356, lng: 18.8288157 },
    { lat: 48.50169, lng: 18.8290582 },
    { lat: 48.5018864, lng: 18.8291039 },
    { lat: 48.50244, lng: 18.8287352 },
    { lat: 48.5024205, lng: 18.8278873 },
    { lat: 48.5024441, lng: 18.8268736 },
    { lat: 48.5024629, lng: 18.8268457 },
    { lat: 48.5024943, lng: 18.8268004 },
    { lat: 48.5025605, lng: 18.8267062 },
    { lat: 48.5021384, lng: 18.8258999 },
    { lat: 48.5024404, lng: 18.8258325 },
    { lat: 48.5028135, lng: 18.8258716 },
    { lat: 48.5036871, lng: 18.8261021 },
    { lat: 48.5045002, lng: 18.8262551 },
    { lat: 48.5045566, lng: 18.8262765 },
    { lat: 48.5045959, lng: 18.8262733 },
    { lat: 48.5050822, lng: 18.8263644 },
    { lat: 48.5055216, lng: 18.8262704 },
    { lat: 48.5057712, lng: 18.8263863 },
    { lat: 48.5060559, lng: 18.8266339 },
    { lat: 48.507413, lng: 18.8264701 },
    { lat: 48.5075242, lng: 18.826527 },
    { lat: 48.507598, lng: 18.8264063 },
    { lat: 48.5076232, lng: 18.8261974 },
    { lat: 48.5076548, lng: 18.8259611 },
    { lat: 48.5077354, lng: 18.8256044 },
    { lat: 48.5079136, lng: 18.8254326 },
    { lat: 48.5079919, lng: 18.8253981 },
    { lat: 48.5081686, lng: 18.825284 },
    { lat: 48.5083273, lng: 18.825215 },
    { lat: 48.5084766, lng: 18.8252009 },
    { lat: 48.5086411, lng: 18.8251334 },
    { lat: 48.5088298, lng: 18.8250144 },
    { lat: 48.5091084, lng: 18.8247968 },
    { lat: 48.5091377, lng: 18.8247741 },
    { lat: 48.5092759, lng: 18.8245921 },
    { lat: 48.5093032, lng: 18.824555 },
    { lat: 48.5094866, lng: 18.8242887 },
    { lat: 48.5095789, lng: 18.8241547 },
    { lat: 48.5098762, lng: 18.8237585 },
    { lat: 48.5100896, lng: 18.8234192 },
    { lat: 48.5102484, lng: 18.8232425 },
    { lat: 48.5106009, lng: 18.8229337 },
    { lat: 48.5111257, lng: 18.822554 },
    { lat: 48.5117508, lng: 18.8222132 },
    { lat: 48.5123008, lng: 18.8218632 },
    { lat: 48.5125902, lng: 18.821554 },
    { lat: 48.5125388, lng: 18.8208743 },
    { lat: 48.5125258, lng: 18.8206255 },
    { lat: 48.5124974, lng: 18.8204112 },
    { lat: 48.5124587, lng: 18.8201185 },
    { lat: 48.512836, lng: 18.8193774 },
    { lat: 48.5128574, lng: 18.8193343 },
    { lat: 48.5127634, lng: 18.8190463 },
    { lat: 48.5128416, lng: 18.8189414 },
    { lat: 48.5131215, lng: 18.8184964 },
    { lat: 48.5132633, lng: 18.8182078 },
    { lat: 48.5135783, lng: 18.8176576 },
    { lat: 48.5139655, lng: 18.8171494 },
    { lat: 48.5139742, lng: 18.8170989 },
    { lat: 48.5140717, lng: 18.8168266 },
    { lat: 48.5143385, lng: 18.8163021 },
    { lat: 48.5144406, lng: 18.8162035 },
    { lat: 48.5147163, lng: 18.8159585 },
    { lat: 48.5152116, lng: 18.8156604 },
    { lat: 48.5154405, lng: 18.8163727 },
    { lat: 48.5156252, lng: 18.8169266 },
    { lat: 48.5158029, lng: 18.8176315 },
    { lat: 48.516317, lng: 18.8183616 },
    { lat: 48.5165971, lng: 18.8183665 },
    { lat: 48.5167681, lng: 18.8183829 },
    { lat: 48.5169016, lng: 18.8184954 },
    { lat: 48.5169312, lng: 18.8185869 },
    { lat: 48.5174228, lng: 18.8185436 },
    { lat: 48.5180417, lng: 18.8184994 },
    { lat: 48.5180929, lng: 18.818552 },
    { lat: 48.5181638, lng: 18.8189989 },
    { lat: 48.5183135, lng: 18.8192956 },
    { lat: 48.5183676, lng: 18.8194033 },
    { lat: 48.518434, lng: 18.8193578 },
    { lat: 48.5187029, lng: 18.8193892 },
    { lat: 48.5193448, lng: 18.8194131 },
    { lat: 48.5197802, lng: 18.8196774 },
    { lat: 48.5200547, lng: 18.8200525 },
    { lat: 48.5202999, lng: 18.8201497 },
    { lat: 48.5205923, lng: 18.8201961 },
    { lat: 48.5208492, lng: 18.8205819 },
    { lat: 48.5211151, lng: 18.8212454 },
    { lat: 48.5216625, lng: 18.8217102 },
    { lat: 48.5218593, lng: 18.8219743 },
    { lat: 48.5218737, lng: 18.8220157 },
    { lat: 48.5219996, lng: 18.8223533 },
    { lat: 48.5223329, lng: 18.8226229 },
    { lat: 48.5225995, lng: 18.8228377 },
    { lat: 48.5229768, lng: 18.8231418 },
    { lat: 48.5231943, lng: 18.8230527 },
    { lat: 48.5231202, lng: 18.8213554 },
    { lat: 48.5232142, lng: 18.8205232 },
    { lat: 48.5234579, lng: 18.8200269 },
    { lat: 48.523537, lng: 18.8193384 },
    { lat: 48.5235461, lng: 18.8192555 },
    { lat: 48.5236344, lng: 18.818487 },
    { lat: 48.5234713, lng: 18.817501 },
    { lat: 48.5226915, lng: 18.8157882 },
    { lat: 48.5224973, lng: 18.8146839 },
    { lat: 48.5223559, lng: 18.8138789 },
    { lat: 48.5217041, lng: 18.8125234 },
    { lat: 48.5209827, lng: 18.8110218 },
    { lat: 48.520793, lng: 18.8107782 },
    { lat: 48.5205454, lng: 18.8104675 },
    { lat: 48.5205228, lng: 18.810425 },
    { lat: 48.5203394, lng: 18.8100858 },
    { lat: 48.5202719, lng: 18.8099614 },
    { lat: 48.5197912, lng: 18.8090709 },
    { lat: 48.5196479, lng: 18.808618 },
    { lat: 48.5195032, lng: 18.8080935 },
    { lat: 48.5193985, lng: 18.807562 },
    { lat: 48.519272, lng: 18.8072754 },
    { lat: 48.5192169, lng: 18.8071482 },
    { lat: 48.5186852, lng: 18.8067745 },
    { lat: 48.5182537, lng: 18.8055979 },
    { lat: 48.5179262, lng: 18.8049225 },
    { lat: 48.5177515, lng: 18.8040636 },
    { lat: 48.5180387, lng: 18.8037147 },
    { lat: 48.5181868, lng: 18.803547 },
    { lat: 48.5186094, lng: 18.8031329 },
    { lat: 48.5189653, lng: 18.8029074 },
    { lat: 48.519293, lng: 18.8022584 },
    { lat: 48.5196126, lng: 18.8013191 },
    { lat: 48.5195414, lng: 18.800821 },
    { lat: 48.519472, lng: 18.8003376 },
    { lat: 48.5191316, lng: 18.7988792 },
    { lat: 48.5189982, lng: 18.7985496 },
    { lat: 48.5189096, lng: 18.7983019 },
    { lat: 48.5188467, lng: 18.798049 },
    { lat: 48.5188283, lng: 18.7977421 },
    { lat: 48.5188457, lng: 18.797385 },
    { lat: 48.5197823, lng: 18.7949248 },
    { lat: 48.5196916, lng: 18.7945518 },
    { lat: 48.5196351, lng: 18.7942679 },
    { lat: 48.5195715, lng: 18.7939161 },
    { lat: 48.5195128, lng: 18.7936592 },
    { lat: 48.5194342, lng: 18.7932591 },
    { lat: 48.519333, lng: 18.7929617 },
    { lat: 48.5192218, lng: 18.7926718 },
    { lat: 48.5190832, lng: 18.7923725 },
    { lat: 48.5189433, lng: 18.79207 },
    { lat: 48.518818, lng: 18.7918086 },
    { lat: 48.5186819, lng: 18.7915384 },
    { lat: 48.5185386, lng: 18.7913207 },
    { lat: 48.5183565, lng: 18.7910532 },
    { lat: 48.5183269, lng: 18.7910069 },
    { lat: 48.5182901, lng: 18.7909494 },
    { lat: 48.5181687, lng: 18.7907618 },
    { lat: 48.5180617, lng: 18.7906149 },
    { lat: 48.5180143, lng: 18.7905497 },
    { lat: 48.5179206, lng: 18.7904207 },
    { lat: 48.5179406, lng: 18.790354 },
    { lat: 48.5180109, lng: 18.7901194 },
    { lat: 48.5179734, lng: 18.7899001 },
    { lat: 48.5180573, lng: 18.7895643 },
    { lat: 48.5181039, lng: 18.789272 },
    { lat: 48.5181035, lng: 18.7889224 },
    { lat: 48.5181262, lng: 18.7887123 },
    { lat: 48.5181728, lng: 18.7885512 },
    { lat: 48.5181884, lng: 18.7884972 },
    { lat: 48.5182657, lng: 18.7883176 },
    { lat: 48.5182659, lng: 18.7881514 },
    { lat: 48.5182503, lng: 18.7879807 },
    { lat: 48.5182666, lng: 18.7879633 },
    { lat: 48.5183313, lng: 18.7878939 },
    { lat: 48.5184068, lng: 18.7877608 },
    { lat: 48.5184707, lng: 18.78756 },
    { lat: 48.5185371, lng: 18.7872814 },
    { lat: 48.5185933, lng: 18.7870193 },
    { lat: 48.5186166, lng: 18.7866893 },
    { lat: 48.5186317, lng: 18.7864193 },
    { lat: 48.5186481, lng: 18.7861519 },
    { lat: 48.5186946, lng: 18.785968 },
    { lat: 48.5187068, lng: 18.7859197 },
    { lat: 48.5187124, lng: 18.7858974 },
    { lat: 48.5187987, lng: 18.7854571 },
    { lat: 48.5188442, lng: 18.7853165 },
    { lat: 48.518854, lng: 18.7852854 },
    { lat: 48.5188791, lng: 18.7852059 },
    { lat: 48.5190792, lng: 18.7848104 },
    { lat: 48.5191886, lng: 18.7845946 },
    { lat: 48.5192041, lng: 18.7845643 },
    { lat: 48.5192308, lng: 18.7845155 },
    { lat: 48.519755, lng: 18.783547 },
    { lat: 48.5198008, lng: 18.7834548 },
    { lat: 48.5197179, lng: 18.7833569 },
    { lat: 48.5199496, lng: 18.7827035 },
    { lat: 48.5199988, lng: 18.7820708 },
    { lat: 48.5196186, lng: 18.7818562 },
    { lat: 48.5191696, lng: 18.7817044 },
    { lat: 48.5191352, lng: 18.7816925 },
    { lat: 48.5189377, lng: 18.7816265 },
    { lat: 48.5189633, lng: 18.7811023 },
    { lat: 48.5189221, lng: 18.7808127 },
    { lat: 48.5189113, lng: 18.7807371 },
    { lat: 48.5188652, lng: 18.7804185 },
    { lat: 48.5191462, lng: 18.7802079 },
    { lat: 48.5192155, lng: 18.7801562 },
    { lat: 48.5194315, lng: 18.7799946 },
    { lat: 48.5199511, lng: 18.7791539 },
    { lat: 48.5199735, lng: 18.7786137 },
    { lat: 48.519979, lng: 18.7784626 },
    { lat: 48.5199924, lng: 18.7781163 },
    { lat: 48.5193045, lng: 18.7761241 },
    { lat: 48.5192233, lng: 18.7759762 },
    { lat: 48.5190429, lng: 18.7755499 },
    { lat: 48.5188985, lng: 18.7752655 },
    { lat: 48.5188744, lng: 18.7752179 },
    { lat: 48.5188431, lng: 18.7751558 },
    { lat: 48.5183, lng: 18.7763226 },
    { lat: 48.5180728, lng: 18.7764014 },
    { lat: 48.5171442, lng: 18.7761743 },
    { lat: 48.5165266, lng: 18.7761804 },
    { lat: 48.5158702, lng: 18.7772106 },
    { lat: 48.5157147, lng: 18.7778972 },
    { lat: 48.5154785, lng: 18.7782309 },
    { lat: 48.5155673, lng: 18.7793827 },
    { lat: 48.5154737, lng: 18.779894 },
    { lat: 48.515298, lng: 18.7801355 },
    { lat: 48.5149467, lng: 18.7798274 },
    { lat: 48.5146211, lng: 18.7794007 },
    { lat: 48.5142883, lng: 18.7789065 },
    { lat: 48.5139429, lng: 18.7785166 },
    { lat: 48.5137998, lng: 18.7784351 },
    { lat: 48.5134605, lng: 18.7781114 },
    { lat: 48.5133511, lng: 18.7781127 },
    { lat: 48.5132182, lng: 18.7780969 },
    { lat: 48.5130109, lng: 18.7781231 },
    { lat: 48.5125606, lng: 18.7781793 },
    { lat: 48.5126214, lng: 18.7778352 },
    { lat: 48.5126703, lng: 18.7775651 },
    { lat: 48.51267, lng: 18.7775139 },
    { lat: 48.5126711, lng: 18.7774729 },
    { lat: 48.5126709, lng: 18.777384 },
    { lat: 48.5126719, lng: 18.7773568 },
    { lat: 48.5125991, lng: 18.7772585 },
    { lat: 48.5125892, lng: 18.7772454 },
    { lat: 48.5125638, lng: 18.7772115 },
    { lat: 48.5123975, lng: 18.7770065 },
    { lat: 48.5123242, lng: 18.7769036 },
    { lat: 48.5123034, lng: 18.7768712 },
    { lat: 48.5122852, lng: 18.7768439 },
    { lat: 48.5122274, lng: 18.7767789 },
    { lat: 48.512149, lng: 18.7767225 },
    { lat: 48.5120586, lng: 18.7766568 },
    { lat: 48.5121768, lng: 18.7759658 },
    { lat: 48.51228, lng: 18.7756779 },
    { lat: 48.5123546, lng: 18.7753585 },
    { lat: 48.512422, lng: 18.7751847 },
    { lat: 48.5124746, lng: 18.7750486 },
    { lat: 48.51252, lng: 18.7750083 },
    { lat: 48.5126347, lng: 18.7748978 },
    { lat: 48.5118982, lng: 18.7742317 },
    { lat: 48.5117708, lng: 18.7734812 },
    { lat: 48.5115282, lng: 18.77303 },
    { lat: 48.5113493, lng: 18.7727839 },
    { lat: 48.5112043, lng: 18.7725114 },
    { lat: 48.5108014, lng: 18.771952 },
    { lat: 48.5104837, lng: 18.771551 },
    { lat: 48.510362, lng: 18.771148 },
    { lat: 48.5103219, lng: 18.7702573 },
    { lat: 48.5101083, lng: 18.7695402 },
    { lat: 48.510008, lng: 18.7691367 },
    { lat: 48.5098761, lng: 18.7688164 },
    { lat: 48.5098254, lng: 18.7683266 },
    { lat: 48.5097907, lng: 18.7678379 },
    { lat: 48.5097774, lng: 18.7677623 },
    { lat: 48.5097628, lng: 18.7676943 },
    { lat: 48.5101319, lng: 18.7664178 },
    { lat: 48.5102068, lng: 18.765508 },
    { lat: 48.5099835, lng: 18.7642294 },
    { lat: 48.5097104, lng: 18.7637552 },
    { lat: 48.5091889, lng: 18.762656 },
    { lat: 48.5088944, lng: 18.7620777 },
    { lat: 48.5083224, lng: 18.7613007 },
    { lat: 48.5079224, lng: 18.7606277 },
    { lat: 48.5075473, lng: 18.7599503 },
    { lat: 48.5071976, lng: 18.7592442 },
    { lat: 48.5069887, lng: 18.758623 },
    { lat: 48.5066197, lng: 18.7582934 },
    { lat: 48.5063546, lng: 18.7579954 },
    { lat: 48.505598, lng: 18.7571026 },
    { lat: 48.5041212, lng: 18.7587962 },
    { lat: 48.5031453, lng: 18.7596032 },
    { lat: 48.5028659, lng: 18.7594763 },
    { lat: 48.5023363, lng: 18.759039 },
    { lat: 48.5022486, lng: 18.758935 },
    { lat: 48.5010802, lng: 18.7592334 },
    { lat: 48.5005958, lng: 18.7590968 },
    { lat: 48.5000116, lng: 18.7589431 },
    { lat: 48.4996098, lng: 18.7587287 },
    { lat: 48.4990358, lng: 18.7585535 },
    { lat: 48.4987988, lng: 18.75851 },
    { lat: 48.4978353, lng: 18.7589521 },
    { lat: 48.4976314, lng: 18.7589758 },
    { lat: 48.4971263, lng: 18.7589776 },
    { lat: 48.4970384, lng: 18.7589774 },
    { lat: 48.4966821, lng: 18.7589784 },
    { lat: 48.4962605, lng: 18.7587064 },
    { lat: 48.4952881, lng: 18.7581888 },
    { lat: 48.4949779, lng: 18.7578046 },
    { lat: 48.4946816, lng: 18.7570197 },
    { lat: 48.4942401, lng: 18.7569247 },
    { lat: 48.4938796, lng: 18.7568018 },
    { lat: 48.4925454, lng: 18.7553103 },
    { lat: 48.4920423, lng: 18.7559451 },
    { lat: 48.4916561, lng: 18.756301 },
    { lat: 48.490723, lng: 18.756455 },
    { lat: 48.490429, lng: 18.757146 },
    { lat: 48.490145, lng: 18.75769 },
    { lat: 48.490017, lng: 18.758085 },
    { lat: 48.489886, lng: 18.758696 },
    { lat: 48.489581, lng: 18.759129 },
    { lat: 48.489312, lng: 18.759457 },
    { lat: 48.489051, lng: 18.759882 },
    { lat: 48.488832, lng: 18.760536 },
    { lat: 48.488623, lng: 18.761363 },
    { lat: 48.488186, lng: 18.762342 },
    { lat: 48.487884, lng: 18.763011 },
    { lat: 48.487884, lng: 18.763451 },
    { lat: 48.487883, lng: 18.763915 },
    { lat: 48.487949, lng: 18.764737 },
    { lat: 48.487872, lng: 18.76494 },
    { lat: 48.487752, lng: 18.765256 },
    { lat: 48.487607, lng: 18.765901 },
    { lat: 48.487461, lng: 18.766451 },
    { lat: 48.487068, lng: 18.767179 },
    { lat: 48.486904, lng: 18.767438 },
    { lat: 48.486628, lng: 18.76781 },
    { lat: 48.486556, lng: 18.767988 },
    { lat: 48.486371, lng: 18.768694 },
    { lat: 48.486309, lng: 18.768923 },
    { lat: 48.485935, lng: 18.769196 },
    { lat: 48.48492, lng: 18.769461 },
    { lat: 48.484543, lng: 18.769787 },
    { lat: 48.484552, lng: 18.769965 },
    { lat: 48.48408, lng: 18.770642 },
    { lat: 48.484106, lng: 18.770798 },
    { lat: 48.484011, lng: 18.771066 },
    { lat: 48.483999, lng: 18.771253 },
    { lat: 48.483993, lng: 18.771358 },
    { lat: 48.483696, lng: 18.771745 },
    { lat: 48.482825, lng: 18.772873 },
    { lat: 48.482716, lng: 18.772986 },
    { lat: 48.482686, lng: 18.773017 },
    { lat: 48.482639, lng: 18.773107 },
    { lat: 48.482481, lng: 18.77341 },
    { lat: 48.48247, lng: 18.773431 },
    { lat: 48.482431, lng: 18.773533 },
    { lat: 48.482345, lng: 18.773762 },
    { lat: 48.482288, lng: 18.773916 },
    { lat: 48.482221, lng: 18.773994 },
    { lat: 48.482194, lng: 18.774093 },
    { lat: 48.482176, lng: 18.774153 },
    { lat: 48.48209, lng: 18.774458 },
    { lat: 48.482076, lng: 18.774507 },
    { lat: 48.482035, lng: 18.774653 },
    { lat: 48.482022, lng: 18.774698 },
    { lat: 48.481967, lng: 18.774888 },
    { lat: 48.481873, lng: 18.775105 },
    { lat: 48.481762, lng: 18.775357 },
    { lat: 48.481749, lng: 18.775387 },
    { lat: 48.481677, lng: 18.775551 },
    { lat: 48.481534, lng: 18.775886 },
    { lat: 48.481353, lng: 18.776462 },
    { lat: 48.481144, lng: 18.777052 },
    { lat: 48.480815, lng: 18.777883 },
    { lat: 48.480838, lng: 18.778353 },
  ],
  JanovaLehota: [
    { lat: 48.6357313, lng: 18.7928937 },
    { lat: 48.6359377, lng: 18.7929418 },
    { lat: 48.6362734, lng: 18.792548 },
    { lat: 48.6365178, lng: 18.7922368 },
    { lat: 48.6367358, lng: 18.7917306 },
    { lat: 48.637324, lng: 18.7909861 },
    { lat: 48.6374807, lng: 18.7905592 },
    { lat: 48.637976, lng: 18.7902159 },
    { lat: 48.6382571, lng: 18.7898372 },
    { lat: 48.638572, lng: 18.7895636 },
    { lat: 48.6387131, lng: 18.788789 },
    { lat: 48.6389609, lng: 18.7884417 },
    { lat: 48.6390309, lng: 18.7885496 },
    { lat: 48.6390538, lng: 18.7885997 },
    { lat: 48.6390883, lng: 18.7886747 },
    { lat: 48.6392013, lng: 18.7888292 },
    { lat: 48.6392276, lng: 18.7888806 },
    { lat: 48.6397571, lng: 18.7899466 },
    { lat: 48.64011, lng: 18.7902243 },
    { lat: 48.6402758, lng: 18.7902901 },
    { lat: 48.640668, lng: 18.7909728 },
    { lat: 48.6408951, lng: 18.7916717 },
    { lat: 48.641494, lng: 18.792855 },
    { lat: 48.6417132, lng: 18.7934671 },
    { lat: 48.6424369, lng: 18.7951615 },
    { lat: 48.6425475, lng: 18.7954853 },
    { lat: 48.6425667, lng: 18.7956167 },
    { lat: 48.642896, lng: 18.7962603 },
    { lat: 48.6431196, lng: 18.7967624 },
    { lat: 48.6431617, lng: 18.7968362 },
    { lat: 48.643395, lng: 18.7972423 },
    { lat: 48.6438917, lng: 18.7966065 },
    { lat: 48.6449633, lng: 18.7952345 },
    { lat: 48.6450674, lng: 18.7955423 },
    { lat: 48.6466729, lng: 18.7949746 },
    { lat: 48.647093, lng: 18.7959355 },
    { lat: 48.6473876, lng: 18.7966057 },
    { lat: 48.6475693, lng: 18.7970198 },
    { lat: 48.647884, lng: 18.7974926 },
    { lat: 48.6479364, lng: 18.79746 },
    { lat: 48.6481144, lng: 18.7973492 },
    { lat: 48.6481199, lng: 18.7973454 },
    { lat: 48.6483704, lng: 18.7971793 },
    { lat: 48.6484969, lng: 18.7970953 },
    { lat: 48.6485906, lng: 18.7970332 },
    { lat: 48.6487041, lng: 18.7969576 },
    { lat: 48.648815, lng: 18.7968837 },
    { lat: 48.6490528, lng: 18.7967259 },
    { lat: 48.6491617, lng: 18.7966534 },
    { lat: 48.6492596, lng: 18.7965882 },
    { lat: 48.6494601, lng: 18.7964548 },
    { lat: 48.6495638, lng: 18.7963861 },
    { lat: 48.649712, lng: 18.7962874 },
    { lat: 48.6502011, lng: 18.795962 },
    { lat: 48.650047, lng: 18.7966155 },
    { lat: 48.650086, lng: 18.7972917 },
    { lat: 48.6500926, lng: 18.7974186 },
    { lat: 48.6501292, lng: 18.797385 },
    { lat: 48.6501787, lng: 18.7973391 },
    { lat: 48.6502152, lng: 18.7973052 },
    { lat: 48.650276, lng: 18.7972598 },
    { lat: 48.650709, lng: 18.7969367 },
    { lat: 48.6508252, lng: 18.7968497 },
    { lat: 48.6509553, lng: 18.7967529 },
    { lat: 48.6511356, lng: 18.796618 },
    { lat: 48.65123, lng: 18.7965482 },
    { lat: 48.6513287, lng: 18.7964744 },
    { lat: 48.6514297, lng: 18.7963989 },
    { lat: 48.6514958, lng: 18.7963493 },
    { lat: 48.6515718, lng: 18.7962931 },
    { lat: 48.6516348, lng: 18.796246 },
    { lat: 48.6517378, lng: 18.7961688 },
    { lat: 48.6518952, lng: 18.7960513 },
    { lat: 48.6520416, lng: 18.7959418 },
    { lat: 48.6521144, lng: 18.7958878 },
    { lat: 48.6522002, lng: 18.7958241 },
    { lat: 48.6523978, lng: 18.795676 },
    { lat: 48.6524605, lng: 18.795629 },
    { lat: 48.6525258, lng: 18.7955809 },
    { lat: 48.6526202, lng: 18.79551 },
    { lat: 48.6526886, lng: 18.7954591 },
    { lat: 48.6528046, lng: 18.7953728 },
    { lat: 48.6528742, lng: 18.7953203 },
    { lat: 48.6530097, lng: 18.7952189 },
    { lat: 48.6532388, lng: 18.7950486 },
    { lat: 48.6532724, lng: 18.7950235 },
    { lat: 48.6532893, lng: 18.7950033 },
    { lat: 48.6533681, lng: 18.794914 },
    { lat: 48.6534278, lng: 18.7948455 },
    { lat: 48.6534905, lng: 18.7947742 },
    { lat: 48.6535619, lng: 18.794694 },
    { lat: 48.6536417, lng: 18.7946028 },
    { lat: 48.6537365, lng: 18.7944948 },
    { lat: 48.6538257, lng: 18.7943925 },
    { lat: 48.6539151, lng: 18.7942909 },
    { lat: 48.653996, lng: 18.7941982 },
    { lat: 48.6540779, lng: 18.7941056 },
    { lat: 48.6541557, lng: 18.7940168 },
    { lat: 48.6542322, lng: 18.7939296 },
    { lat: 48.6543067, lng: 18.7938444 },
    { lat: 48.6543823, lng: 18.7937583 },
    { lat: 48.6544567, lng: 18.7936734 },
    { lat: 48.6545323, lng: 18.7935874 },
    { lat: 48.6546037, lng: 18.7935054 },
    { lat: 48.6546759, lng: 18.7934237 },
    { lat: 48.6547462, lng: 18.7933436 },
    { lat: 48.654808, lng: 18.7932734 },
    { lat: 48.6548675, lng: 18.7932053 },
    { lat: 48.6549261, lng: 18.7931384 },
    { lat: 48.6549857, lng: 18.793071 },
    { lat: 48.655039, lng: 18.7930105 },
    { lat: 48.655371, lng: 18.7926316 },
    { lat: 48.6554475, lng: 18.7925444 },
    { lat: 48.6558923, lng: 18.7920372 },
    { lat: 48.6569766, lng: 18.7933823 },
    { lat: 48.657929, lng: 18.7924904 },
    { lat: 48.6588019, lng: 18.791737 },
    { lat: 48.6590742, lng: 18.791469 },
    { lat: 48.6591129, lng: 18.7914309 },
    { lat: 48.6603825, lng: 18.790181 },
    { lat: 48.6606602, lng: 18.7899074 },
    { lat: 48.6607345, lng: 18.7898593 },
    { lat: 48.6623087, lng: 18.7888469 },
    { lat: 48.662947, lng: 18.7892811 },
    { lat: 48.6632945, lng: 18.789586 },
    { lat: 48.6637378, lng: 18.7896076 },
    { lat: 48.6641418, lng: 18.7910305 },
    { lat: 48.664862, lng: 18.79051 },
    { lat: 48.6652823, lng: 18.7905044 },
    { lat: 48.6659631, lng: 18.7904559 },
    { lat: 48.6671568, lng: 18.7903351 },
    { lat: 48.667658, lng: 18.7903433 },
    { lat: 48.6679107, lng: 18.7903909 },
    { lat: 48.6689812, lng: 18.7908054 },
    { lat: 48.6690446, lng: 18.7908303 },
    { lat: 48.6695824, lng: 18.7910643 },
    { lat: 48.6700499, lng: 18.7912275 },
    { lat: 48.6706302, lng: 18.7915193 },
    { lat: 48.6718108, lng: 18.7922963 },
    { lat: 48.6722221, lng: 18.7922258 },
    { lat: 48.672903, lng: 18.7915964 },
    { lat: 48.6737624, lng: 18.7911452 },
    { lat: 48.6744449, lng: 18.7909559 },
    { lat: 48.6745837, lng: 18.7910663 },
    { lat: 48.6752609, lng: 18.7915521 },
    { lat: 48.6762838, lng: 18.7923262 },
    { lat: 48.6766236, lng: 18.7924437 },
    { lat: 48.6776147, lng: 18.7928281 },
    { lat: 48.6778826, lng: 18.7927319 },
    { lat: 48.678713, lng: 18.792393 },
    { lat: 48.6792409, lng: 18.792033 },
    { lat: 48.6797545, lng: 18.7916734 },
    { lat: 48.6797793, lng: 18.7916554 },
    { lat: 48.6802037, lng: 18.7912711 },
    { lat: 48.6805018, lng: 18.7911916 },
    { lat: 48.6809441, lng: 18.7910695 },
    { lat: 48.6811794, lng: 18.7908016 },
    { lat: 48.6813, lng: 18.7906102 },
    { lat: 48.6816355, lng: 18.790018 },
    { lat: 48.6817791, lng: 18.7899163 },
    { lat: 48.6820869, lng: 18.7898931 },
    { lat: 48.6822028, lng: 18.7898903 },
    { lat: 48.6824537, lng: 18.789663 },
    { lat: 48.6832148, lng: 18.7895324 },
    { lat: 48.6834198, lng: 18.7895065 },
    { lat: 48.683774, lng: 18.7893361 },
    { lat: 48.6840527, lng: 18.7891243 },
    { lat: 48.68479, lng: 18.7883007 },
    { lat: 48.6852612, lng: 18.7878682 },
    { lat: 48.685612, lng: 18.7875904 },
    { lat: 48.6867651, lng: 18.7865057 },
    { lat: 48.6869567, lng: 18.78633 },
    { lat: 48.6871225, lng: 18.7862649 },
    { lat: 48.6879212, lng: 18.7854991 },
    { lat: 48.688296, lng: 18.785097 },
    { lat: 48.6887357, lng: 18.7850105 },
    { lat: 48.6897134, lng: 18.7846199 },
    { lat: 48.6904547, lng: 18.7840925 },
    { lat: 48.6907638, lng: 18.7838771 },
    { lat: 48.6916922, lng: 18.7824018 },
    { lat: 48.6930279, lng: 18.7817227 },
    { lat: 48.6935698, lng: 18.7810267 },
    { lat: 48.693826, lng: 18.780564 },
    { lat: 48.693868, lng: 18.779728 },
    { lat: 48.693992, lng: 18.778875 },
    { lat: 48.694123, lng: 18.778275 },
    { lat: 48.694367, lng: 18.777807 },
    { lat: 48.694652, lng: 18.777508 },
    { lat: 48.694902, lng: 18.776992 },
    { lat: 48.695127, lng: 18.777009 },
    { lat: 48.695254, lng: 18.776763 },
    { lat: 48.696048, lng: 18.775142 },
    { lat: 48.695995, lng: 18.773657 },
    { lat: 48.696, lng: 18.772923 },
    { lat: 48.696025, lng: 18.771926 },
    { lat: 48.696164, lng: 18.771686 },
    { lat: 48.696552, lng: 18.771574 },
    { lat: 48.696783, lng: 18.771321 },
    { lat: 48.696874, lng: 18.770559 },
    { lat: 48.697029, lng: 18.769825 },
    { lat: 48.696959, lng: 18.768532 },
    { lat: 48.697002, lng: 18.767674 },
    { lat: 48.697104, lng: 18.767017 },
    { lat: 48.697243, lng: 18.766321 },
    { lat: 48.697253, lng: 18.764199 },
    { lat: 48.697279, lng: 18.763367 },
    { lat: 48.69718, lng: 18.762776 },
    { lat: 48.696846, lng: 18.762396 },
    { lat: 48.696341, lng: 18.761985 },
    { lat: 48.695536, lng: 18.761947 },
    { lat: 48.694864, lng: 18.761297 },
    { lat: 48.694164, lng: 18.760672 },
    { lat: 48.69339, lng: 18.760185 },
    { lat: 48.692953, lng: 18.759639 },
    { lat: 48.692651, lng: 18.759107 },
    { lat: 48.692536, lng: 18.758742 },
    { lat: 48.692584, lng: 18.758698 },
    { lat: 48.692413, lng: 18.758111 },
    { lat: 48.692451, lng: 18.757715 },
    { lat: 48.69254, lng: 18.757392 },
    { lat: 48.692644, lng: 18.757036 },
    { lat: 48.692562, lng: 18.756457 },
    { lat: 48.692138, lng: 18.755888 },
    { lat: 48.692042, lng: 18.755702 },
    { lat: 48.691853, lng: 18.75515 },
    { lat: 48.691781, lng: 18.754474 },
    { lat: 48.691349, lng: 18.754157 },
    { lat: 48.691187, lng: 18.753958 },
    { lat: 48.690532, lng: 18.753435 },
    { lat: 48.690148, lng: 18.752972 },
    { lat: 48.689403, lng: 18.753174 },
    { lat: 48.689268, lng: 18.75301 },
    { lat: 48.688782, lng: 18.752509 },
    { lat: 48.688163, lng: 18.752153 },
    { lat: 48.687716, lng: 18.751768 },
    { lat: 48.687215, lng: 18.751136 },
    { lat: 48.687275, lng: 18.750632 },
    { lat: 48.687187, lng: 18.750456 },
    { lat: 48.686867, lng: 18.750117 },
    { lat: 48.686588, lng: 18.750026 },
    { lat: 48.686096, lng: 18.749991 },
    { lat: 48.685282, lng: 18.750021 },
    { lat: 48.685252, lng: 18.750098 },
    { lat: 48.685149, lng: 18.750466 },
    { lat: 48.684967, lng: 18.750759 },
    { lat: 48.68438, lng: 18.751393 },
    { lat: 48.684025, lng: 18.751668 },
    { lat: 48.683874, lng: 18.751693 },
    { lat: 48.683518, lng: 18.751733 },
    { lat: 48.683305, lng: 18.751711 },
    { lat: 48.68302, lng: 18.751615 },
    { lat: 48.682471, lng: 18.751614 },
    { lat: 48.681671, lng: 18.751112 },
    { lat: 48.681558, lng: 18.750955 },
    { lat: 48.681322, lng: 18.750688 },
    { lat: 48.68107, lng: 18.750336 },
    { lat: 48.680609, lng: 18.749546 },
    { lat: 48.680338, lng: 18.749188 },
    { lat: 48.680099, lng: 18.748987 },
    { lat: 48.679761, lng: 18.748848 },
    { lat: 48.679345, lng: 18.748905 },
    { lat: 48.679004, lng: 18.74886 },
    { lat: 48.6788, lng: 18.748766 },
    { lat: 48.678518, lng: 18.748786 },
    { lat: 48.677755, lng: 18.748773 },
    { lat: 48.67736, lng: 18.748807 },
    { lat: 48.677094, lng: 18.748708 },
    { lat: 48.67689, lng: 18.748723 },
    { lat: 48.676516, lng: 18.74879 },
    { lat: 48.676324, lng: 18.748707 },
    { lat: 48.676263, lng: 18.7487 },
    { lat: 48.675375, lng: 18.747947 },
    { lat: 48.67504, lng: 18.747586 },
    { lat: 48.674607, lng: 18.747444 },
    { lat: 48.674464, lng: 18.747363 },
    { lat: 48.674091, lng: 18.74724 },
    { lat: 48.673736, lng: 18.747411 },
    { lat: 48.673435, lng: 18.747492 },
    { lat: 48.673158, lng: 18.747585 },
    { lat: 48.672704, lng: 18.747546 },
    { lat: 48.672422, lng: 18.74744 },
    { lat: 48.672205, lng: 18.747203 },
    { lat: 48.671629, lng: 18.746821 },
    { lat: 48.671475, lng: 18.746805 },
    { lat: 48.671484, lng: 18.746926 },
    { lat: 48.670449, lng: 18.746837 },
    { lat: 48.67033, lng: 18.746691 },
    { lat: 48.66983, lng: 18.746817 },
    { lat: 48.669572, lng: 18.746932 },
    { lat: 48.669196, lng: 18.746866 },
    { lat: 48.669014, lng: 18.746942 },
    { lat: 48.668703, lng: 18.74692 },
    { lat: 48.668572, lng: 18.746768 },
    { lat: 48.668169, lng: 18.746444 },
    { lat: 48.667554, lng: 18.74564 },
    { lat: 48.667223, lng: 18.745201 },
    { lat: 48.667092, lng: 18.744662 },
    { lat: 48.667006, lng: 18.744462 },
    { lat: 48.667007, lng: 18.744102 },
    { lat: 48.666941, lng: 18.743881 },
    { lat: 48.666722, lng: 18.743566 },
    { lat: 48.666594, lng: 18.743463 },
    { lat: 48.666064, lng: 18.74267 },
    { lat: 48.665984, lng: 18.742541 },
    { lat: 48.665612, lng: 18.741946 },
    { lat: 48.665568, lng: 18.741875 },
    { lat: 48.665099, lng: 18.741377 },
    { lat: 48.664889, lng: 18.741544 },
    { lat: 48.664698, lng: 18.741698 },
    { lat: 48.664557, lng: 18.741926 },
    { lat: 48.664491, lng: 18.742032 },
    { lat: 48.66407, lng: 18.742203 },
    { lat: 48.663646, lng: 18.742017 },
    { lat: 48.663477, lng: 18.741943 },
    { lat: 48.663267, lng: 18.741851 },
    { lat: 48.662036, lng: 18.741352 },
    { lat: 48.661078, lng: 18.740125 },
    { lat: 48.66016, lng: 18.739199 },
    { lat: 48.658746, lng: 18.736976 },
    { lat: 48.658258, lng: 18.735925 },
    { lat: 48.657907, lng: 18.735406 },
    { lat: 48.657871, lng: 18.735352 },
    { lat: 48.657847, lng: 18.735317 },
    { lat: 48.657826, lng: 18.735286 },
    { lat: 48.65779, lng: 18.735233 },
    { lat: 48.657764, lng: 18.735204 },
    { lat: 48.657709, lng: 18.735141 },
    { lat: 48.657459, lng: 18.734861 },
    { lat: 48.65723, lng: 18.734603 },
    { lat: 48.657102, lng: 18.734686 },
    { lat: 48.65706, lng: 18.734712 },
    { lat: 48.656992, lng: 18.734755 },
    { lat: 48.656933, lng: 18.734811 },
    { lat: 48.656814, lng: 18.734926 },
    { lat: 48.656785, lng: 18.734954 },
    { lat: 48.656651, lng: 18.735084 },
    { lat: 48.656624, lng: 18.735151 },
    { lat: 48.656593, lng: 18.735201 },
    { lat: 48.656324, lng: 18.735037 },
    { lat: 48.656149, lng: 18.734837 },
    { lat: 48.656018, lng: 18.734488 },
    { lat: 48.655956, lng: 18.734264 },
    { lat: 48.65592, lng: 18.734286 },
    { lat: 48.6555265, lng: 18.7346076 },
    { lat: 48.6556482, lng: 18.7349699 },
    { lat: 48.6557967, lng: 18.7352163 },
    { lat: 48.6558186, lng: 18.735376 },
    { lat: 48.6556816, lng: 18.7354478 },
    { lat: 48.6556763, lng: 18.7354552 },
    { lat: 48.6555087, lng: 18.7355489 },
    { lat: 48.6554867, lng: 18.7355612 },
    { lat: 48.6554156, lng: 18.7356015 },
    { lat: 48.6553405, lng: 18.7355046 },
    { lat: 48.6551675, lng: 18.7352815 },
    { lat: 48.6550867, lng: 18.7355227 },
    { lat: 48.655136, lng: 18.7357942 },
    { lat: 48.6552705, lng: 18.7360805 },
    { lat: 48.6553309, lng: 18.7364763 },
    { lat: 48.6550458, lng: 18.7374573 },
    { lat: 48.6547326, lng: 18.7379962 },
    { lat: 48.6545708, lng: 18.7379668 },
    { lat: 48.6542874, lng: 18.7379161 },
    { lat: 48.6544327, lng: 18.7380851 },
    { lat: 48.6544766, lng: 18.7381925 },
    { lat: 48.6545079, lng: 18.7382708 },
    { lat: 48.6545599, lng: 18.7388459 },
    { lat: 48.6541222, lng: 18.7400618 },
    { lat: 48.654086, lng: 18.741 },
    { lat: 48.6537037, lng: 18.7415336 },
    { lat: 48.6540956, lng: 18.7426756 },
    { lat: 48.6536074, lng: 18.7437445 },
    { lat: 48.6536127, lng: 18.7442927 },
    { lat: 48.6536282, lng: 18.7448409 },
    { lat: 48.6536046, lng: 18.744971 },
    { lat: 48.6535784, lng: 18.7451158 },
    { lat: 48.6535583, lng: 18.7452277 },
    { lat: 48.653621, lng: 18.7456667 },
    { lat: 48.6536423, lng: 18.7458147 },
    { lat: 48.6537101, lng: 18.7462852 },
    { lat: 48.6538109, lng: 18.7469772 },
    { lat: 48.6537583, lng: 18.7474971 },
    { lat: 48.6536131, lng: 18.7477603 },
    { lat: 48.6532292, lng: 18.7484563 },
    { lat: 48.6532053, lng: 18.7491986 },
    { lat: 48.652926, lng: 18.7500629 },
    { lat: 48.6527444, lng: 18.7504457 },
    { lat: 48.6525556, lng: 18.7509712 },
    { lat: 48.6525959, lng: 18.7510078 },
    { lat: 48.6526326, lng: 18.7510412 },
    { lat: 48.6526752, lng: 18.7510785 },
    { lat: 48.6527182, lng: 18.7511044 },
    { lat: 48.6528239, lng: 18.7511826 },
    { lat: 48.6529038, lng: 18.7512458 },
    { lat: 48.6532322, lng: 18.7515045 },
    { lat: 48.6532853, lng: 18.7515463 },
    { lat: 48.6533341, lng: 18.7515996 },
    { lat: 48.653388, lng: 18.751658 },
    { lat: 48.6534061, lng: 18.751678 },
    { lat: 48.6535256, lng: 18.752062 },
    { lat: 48.6533112, lng: 18.7526157 },
    { lat: 48.6531178, lng: 18.7528657 },
    { lat: 48.6528888, lng: 18.7533534 },
    { lat: 48.6529411, lng: 18.753596 },
    { lat: 48.6528894, lng: 18.753558 },
    { lat: 48.6527228, lng: 18.7534368 },
    { lat: 48.6527029, lng: 18.7534217 },
    { lat: 48.652683, lng: 18.753407 },
    { lat: 48.6526438, lng: 18.7534356 },
    { lat: 48.6525763, lng: 18.7536074 },
    { lat: 48.6524988, lng: 18.7538036 },
    { lat: 48.6524909, lng: 18.7538074 },
    { lat: 48.6524481, lng: 18.7538297 },
    { lat: 48.6524031, lng: 18.7538529 },
    { lat: 48.6523646, lng: 18.7538725 },
    { lat: 48.6523207, lng: 18.7538956 },
    { lat: 48.6522996, lng: 18.7538985 },
    { lat: 48.6522907, lng: 18.7538996 },
    { lat: 48.6521138, lng: 18.7539251 },
    { lat: 48.6520779, lng: 18.7539301 },
    { lat: 48.6520601, lng: 18.753933 },
    { lat: 48.652051, lng: 18.7539341 },
    { lat: 48.6520409, lng: 18.7539364 },
    { lat: 48.6519966, lng: 18.7539474 },
    { lat: 48.651963, lng: 18.7539557 },
    { lat: 48.6519728, lng: 18.7542663 },
    { lat: 48.651963, lng: 18.7543406 },
    { lat: 48.6517175, lng: 18.7549425 },
    { lat: 48.6515348, lng: 18.7550315 },
    { lat: 48.651198, lng: 18.7557435 },
    { lat: 48.6512017, lng: 18.7558351 },
    { lat: 48.6512029, lng: 18.7558574 },
    { lat: 48.6512095, lng: 18.7560228 },
    { lat: 48.6512133, lng: 18.7560784 },
    { lat: 48.6512181, lng: 18.7561466 },
    { lat: 48.6512102, lng: 18.7564004 },
    { lat: 48.6509311, lng: 18.7570142 },
    { lat: 48.6506173, lng: 18.7576472 },
    { lat: 48.6503564, lng: 18.7583679 },
    { lat: 48.6503656, lng: 18.7584117 },
    { lat: 48.6503795, lng: 18.7584839 },
    { lat: 48.6504518, lng: 18.7586806 },
    { lat: 48.6503603, lng: 18.7592762 },
    { lat: 48.6503829, lng: 18.7593862 },
    { lat: 48.6504393, lng: 18.7593855 },
    { lat: 48.6503588, lng: 18.7598898 },
    { lat: 48.6503171, lng: 18.7599758 },
    { lat: 48.6502154, lng: 18.7601238 },
    { lat: 48.6500512, lng: 18.7604966 },
    { lat: 48.6500247, lng: 18.7607423 },
    { lat: 48.6500111, lng: 18.7611249 },
    { lat: 48.6499551, lng: 18.761113 },
    { lat: 48.6497518, lng: 18.7619809 },
    { lat: 48.649721, lng: 18.7623209 },
    { lat: 48.649993, lng: 18.7622358 },
    { lat: 48.649984, lng: 18.7633072 },
    { lat: 48.6499836, lng: 18.7633226 },
    { lat: 48.6498965, lng: 18.7637246 },
    { lat: 48.649341, lng: 18.7652111 },
    { lat: 48.6492377, lng: 18.7654567 },
    { lat: 48.6490932, lng: 18.7657968 },
    { lat: 48.648949, lng: 18.7661407 },
    { lat: 48.6489449, lng: 18.7661484 },
    { lat: 48.6488983, lng: 18.766251 },
    { lat: 48.6488903, lng: 18.766269 },
    { lat: 48.6488348, lng: 18.7663909 },
    { lat: 48.6487823, lng: 18.7665077 },
    { lat: 48.6487761, lng: 18.7665195 },
    { lat: 48.6487603, lng: 18.7665607 },
    { lat: 48.6487382, lng: 18.7666125 },
    { lat: 48.6487096, lng: 18.7667112 },
    { lat: 48.6485365, lng: 18.7673015 },
    { lat: 48.6485326, lng: 18.7673126 },
    { lat: 48.6484896, lng: 18.7674604 },
    { lat: 48.6484485, lng: 18.7676005 },
    { lat: 48.6484074, lng: 18.7677399 },
    { lat: 48.6483623, lng: 18.7678933 },
    { lat: 48.6483163, lng: 18.7680482 },
    { lat: 48.6482357, lng: 18.7681631 },
    { lat: 48.6481527, lng: 18.7682801 },
    { lat: 48.6480697, lng: 18.7683963 },
    { lat: 48.6480099, lng: 18.7684808 },
    { lat: 48.6478613, lng: 18.768315 },
    { lat: 48.6478028, lng: 18.76847 },
    { lat: 48.6477634, lng: 18.7685746 },
    { lat: 48.6477229, lng: 18.7687071 },
    { lat: 48.6473536, lng: 18.7695973 },
    { lat: 48.6470064, lng: 18.770631 },
    { lat: 48.6469623, lng: 18.771332 },
    { lat: 48.6468529, lng: 18.7724079 },
    { lat: 48.646848, lng: 18.7725079 },
    { lat: 48.6468533, lng: 18.7726075 },
    { lat: 48.6468804, lng: 18.7727366 },
    { lat: 48.6468787, lng: 18.7728306 },
    { lat: 48.6468753, lng: 18.7730489 },
    { lat: 48.6468729, lng: 18.7731747 },
    { lat: 48.6463008, lng: 18.7742392 },
    { lat: 48.645435, lng: 18.7755072 },
    { lat: 48.6450594, lng: 18.7756991 },
    { lat: 48.6444264, lng: 18.7763169 },
    { lat: 48.6435283, lng: 18.7768455 },
    { lat: 48.6431946, lng: 18.777339 },
    { lat: 48.6425377, lng: 18.7775877 },
    { lat: 48.6419401, lng: 18.7781919 },
    { lat: 48.6404641, lng: 18.7797602 },
    { lat: 48.6402617, lng: 18.7800739 },
    { lat: 48.6402862, lng: 18.7803245 },
    { lat: 48.640409, lng: 18.7805348 },
    { lat: 48.6405416, lng: 18.7807643 },
    { lat: 48.6402711, lng: 18.7812391 },
    { lat: 48.6396391, lng: 18.783054 },
    { lat: 48.63985, lng: 18.783461 },
    { lat: 48.6393235, lng: 18.7842623 },
    { lat: 48.6387526, lng: 18.7849035 },
    { lat: 48.6380486, lng: 18.7858384 },
    { lat: 48.6378714, lng: 18.7860746 },
    { lat: 48.6383896, lng: 18.7873116 },
    { lat: 48.6384462, lng: 18.7874446 },
    { lat: 48.6384528, lng: 18.787462 },
    { lat: 48.6384658, lng: 18.787495 },
    { lat: 48.6384957, lng: 18.7875685 },
    { lat: 48.638515, lng: 18.7876168 },
    { lat: 48.6377689, lng: 18.7880746 },
    { lat: 48.6375333, lng: 18.7882282 },
    { lat: 48.6371353, lng: 18.7884875 },
    { lat: 48.6363184, lng: 18.7898547 },
    { lat: 48.6355593, lng: 18.7912278 },
    { lat: 48.6351522, lng: 18.7919809 },
    { lat: 48.6357313, lng: 18.7928937 },
  ],
  Repište: [
    { lat: 48.511986, lng: 18.862766 },
    { lat: 48.5119857, lng: 18.8627111 },
    { lat: 48.5119965, lng: 18.8626327 },
    { lat: 48.5120084, lng: 18.8625388 },
    { lat: 48.5121609, lng: 18.8623491 },
    { lat: 48.5123444, lng: 18.8622777 },
    { lat: 48.5123754, lng: 18.8622659 },
    { lat: 48.512381, lng: 18.8622638 },
    { lat: 48.5126159, lng: 18.8620335 },
    { lat: 48.5128612, lng: 18.8617187 },
    { lat: 48.5131229, lng: 18.8613938 },
    { lat: 48.5132908, lng: 18.861283 },
    { lat: 48.5133319, lng: 18.8612501 },
    { lat: 48.5133634, lng: 18.8612254 },
    { lat: 48.5133763, lng: 18.8612146 },
    { lat: 48.5134196, lng: 18.8611793 },
    { lat: 48.5135025, lng: 18.8610817 },
    { lat: 48.5137016, lng: 18.8609857 },
    { lat: 48.5139754, lng: 18.8608976 },
    { lat: 48.5142012, lng: 18.860791 },
    { lat: 48.514394, lng: 18.8606176 },
    { lat: 48.5145967, lng: 18.860439 },
    { lat: 48.5148502, lng: 18.8601983 },
    { lat: 48.515121, lng: 18.8598979 },
    { lat: 48.5153277, lng: 18.8597924 },
    { lat: 48.5154695, lng: 18.8597245 },
    { lat: 48.5158203, lng: 18.8595538 },
    { lat: 48.5160264, lng: 18.8594826 },
    { lat: 48.516222, lng: 18.8595759 },
    { lat: 48.5165005, lng: 18.8599706 },
    { lat: 48.5167071, lng: 18.8602134 },
    { lat: 48.5168802, lng: 18.8603534 },
    { lat: 48.5172477, lng: 18.8605673 },
    { lat: 48.5174461, lng: 18.8605881 },
    { lat: 48.5176319, lng: 18.8605399 },
    { lat: 48.5179586, lng: 18.8604691 },
    { lat: 48.5181133, lng: 18.8605359 },
    { lat: 48.5183431, lng: 18.8606443 },
    { lat: 48.5183881, lng: 18.8607056 },
    { lat: 48.5184799, lng: 18.8605617 },
    { lat: 48.5185393, lng: 18.8604672 },
    { lat: 48.5187956, lng: 18.8600611 },
    { lat: 48.518892, lng: 18.8599015 },
    { lat: 48.5189148, lng: 18.8598625 },
    { lat: 48.5189761, lng: 18.8597607 },
    { lat: 48.5189577, lng: 18.859734 },
    { lat: 48.518899, lng: 18.8596453 },
    { lat: 48.5188957, lng: 18.8595823 },
    { lat: 48.5188712, lng: 18.8595664 },
    { lat: 48.5187985, lng: 18.8594035 },
    { lat: 48.5187866, lng: 18.8592146 },
    { lat: 48.5187308, lng: 18.8586638 },
    { lat: 48.5187303, lng: 18.8586322 },
    { lat: 48.5185529, lng: 18.8582778 },
    { lat: 48.5184385, lng: 18.8580494 },
    { lat: 48.518106, lng: 18.8575537 },
    { lat: 48.5180588, lng: 18.8570371 },
    { lat: 48.5181238, lng: 18.8567911 },
    { lat: 48.5181941, lng: 18.8566477 },
    { lat: 48.5183352, lng: 18.8565226 },
    { lat: 48.5185972, lng: 18.8562893 },
    { lat: 48.5189249, lng: 18.8559975 },
    { lat: 48.5189543, lng: 18.8559574 },
    { lat: 48.5190723, lng: 18.8557969 },
    { lat: 48.519325, lng: 18.8554528 },
    { lat: 48.5194479, lng: 18.8552754 },
    { lat: 48.5194659, lng: 18.8552533 },
    { lat: 48.5195982, lng: 18.8550901 },
    { lat: 48.5197362, lng: 18.854922 },
    { lat: 48.5197722, lng: 18.8548776 },
    { lat: 48.5198028, lng: 18.8548391 },
    { lat: 48.5199687, lng: 18.8546442 },
    { lat: 48.5200101, lng: 18.8545956 },
    { lat: 48.5200707, lng: 18.8545248 },
    { lat: 48.5200747, lng: 18.8545161 },
    { lat: 48.5201055, lng: 18.854457 },
    { lat: 48.5201084, lng: 18.8544499 },
    { lat: 48.520238, lng: 18.8544023 },
    { lat: 48.5203044, lng: 18.8543343 },
    { lat: 48.52033, lng: 18.8543078 },
    { lat: 48.5204036, lng: 18.8542472 },
    { lat: 48.5211962, lng: 18.8536804 },
    { lat: 48.5215922, lng: 18.8534029 },
    { lat: 48.5225155, lng: 18.8529003 },
    { lat: 48.5231106, lng: 18.8527206 },
    { lat: 48.523154, lng: 18.8519952 },
    { lat: 48.5233376, lng: 18.8517733 },
    { lat: 48.5236252, lng: 18.8515138 },
    { lat: 48.5237173, lng: 18.8512913 },
    { lat: 48.5238573, lng: 18.8512076 },
    { lat: 48.5241057, lng: 18.8511025 },
    { lat: 48.5243161, lng: 18.8509009 },
    { lat: 48.5244907, lng: 18.8507242 },
    { lat: 48.5248331, lng: 18.8506742 },
    { lat: 48.5254541, lng: 18.8506017 },
    { lat: 48.5257486, lng: 18.8505392 },
    { lat: 48.5259067, lng: 18.8506298 },
    { lat: 48.5260184, lng: 18.8507827 },
    { lat: 48.5261086, lng: 18.850914 },
    { lat: 48.5267188, lng: 18.851242 },
    { lat: 48.5272468, lng: 18.8510723 },
    { lat: 48.5276031, lng: 18.8509221 },
    { lat: 48.5279225, lng: 18.8507101 },
    { lat: 48.5283234, lng: 18.8504192 },
    { lat: 48.5285862, lng: 18.8503729 },
    { lat: 48.5288035, lng: 18.8502811 },
    { lat: 48.5292229, lng: 18.849889 },
    { lat: 48.5295171, lng: 18.8493904 },
    { lat: 48.5298556, lng: 18.8490467 },
    { lat: 48.5302601, lng: 18.8483696 },
    { lat: 48.5303712, lng: 18.8481626 },
    { lat: 48.5304863, lng: 18.8479494 },
    { lat: 48.5309371, lng: 18.8470762 },
    { lat: 48.5309619, lng: 18.846926 },
    { lat: 48.5308825, lng: 18.8467635 },
    { lat: 48.5308525, lng: 18.8465988 },
    { lat: 48.5309014, lng: 18.8458498 },
    { lat: 48.5310317, lng: 18.8443907 },
    { lat: 48.5315213, lng: 18.8438953 },
    { lat: 48.5318378, lng: 18.8425685 },
    { lat: 48.5320989, lng: 18.8417098 },
    { lat: 48.5325482, lng: 18.8406186 },
    { lat: 48.5327106, lng: 18.8393196 },
    { lat: 48.5327355, lng: 18.8383695 },
    { lat: 48.5326541, lng: 18.8379764 },
    { lat: 48.5330101, lng: 18.8374939 },
    { lat: 48.5343115, lng: 18.8362399 },
    { lat: 48.5351301, lng: 18.8357432 },
    { lat: 48.5353742, lng: 18.8352756 },
    { lat: 48.5356809, lng: 18.8348398 },
    { lat: 48.5365569, lng: 18.8345782 },
    { lat: 48.53713, lng: 18.8336547 },
    { lat: 48.5371036, lng: 18.8328447 },
    { lat: 48.5371376, lng: 18.832266 },
    { lat: 48.5372701, lng: 18.8318654 },
    { lat: 48.5373918, lng: 18.8314752 },
    { lat: 48.537876, lng: 18.8304433 },
    { lat: 48.5381046, lng: 18.8298341 },
    { lat: 48.5383291, lng: 18.8291873 },
    { lat: 48.5385043, lng: 18.8286321 },
    { lat: 48.5385367, lng: 18.8281764 },
    { lat: 48.5384499, lng: 18.8273323 },
    { lat: 48.5383111, lng: 18.8261215 },
    { lat: 48.5385147, lng: 18.8251604 },
    { lat: 48.5390238, lng: 18.8236842 },
    { lat: 48.5391327, lng: 18.8241894 },
    { lat: 48.5391676, lng: 18.8249446 },
    { lat: 48.5392798, lng: 18.824826 },
    { lat: 48.5395714, lng: 18.8246207 },
    { lat: 48.5395898, lng: 18.8246075 },
    { lat: 48.5396528, lng: 18.8245612 },
    { lat: 48.5396818, lng: 18.8245364 },
    { lat: 48.5397576, lng: 18.8244723 },
    { lat: 48.5398148, lng: 18.8243595 },
    { lat: 48.5398522, lng: 18.8242334 },
    { lat: 48.5397541, lng: 18.8238439 },
    { lat: 48.5394718, lng: 18.8232767 },
    { lat: 48.5392087, lng: 18.8227763 },
    { lat: 48.5391131, lng: 18.8223666 },
    { lat: 48.5389239, lng: 18.8221795 },
    { lat: 48.5388523, lng: 18.8225997 },
    { lat: 48.5388196, lng: 18.8227975 },
    { lat: 48.5383579, lng: 18.824019 },
    { lat: 48.5383027, lng: 18.8243824 },
    { lat: 48.5382861, lng: 18.8244982 },
    { lat: 48.5380201, lng: 18.8250386 },
    { lat: 48.5378516, lng: 18.8261335 },
    { lat: 48.5380377, lng: 18.8266968 },
    { lat: 48.5380014, lng: 18.8272096 },
    { lat: 48.5378614, lng: 18.8276838 },
    { lat: 48.5378015, lng: 18.8282428 },
    { lat: 48.5375769, lng: 18.8287341 },
    { lat: 48.5374294, lng: 18.8291266 },
    { lat: 48.5371089, lng: 18.8295321 },
    { lat: 48.5367362, lng: 18.8300203 },
    { lat: 48.5365309, lng: 18.8301026 },
    { lat: 48.5362843, lng: 18.8300681 },
    { lat: 48.53607, lng: 18.8301962 },
    { lat: 48.535727, lng: 18.8305834 },
    { lat: 48.5353728, lng: 18.8306876 },
    { lat: 48.535023, lng: 18.8309617 },
    { lat: 48.533974, lng: 18.8314316 },
    { lat: 48.5337125, lng: 18.8313939 },
    { lat: 48.5331626, lng: 18.8313149 },
    { lat: 48.5328146, lng: 18.8310419 },
    { lat: 48.5327377, lng: 18.8297207 },
    { lat: 48.5327056, lng: 18.8293014 },
    { lat: 48.5327833, lng: 18.827572 },
    { lat: 48.532721, lng: 18.8270022 },
    { lat: 48.5327485, lng: 18.826276 },
    { lat: 48.5326277, lng: 18.8258858 },
    { lat: 48.5321744, lng: 18.8252827 },
    { lat: 48.5319805, lng: 18.8253476 },
    { lat: 48.5319109, lng: 18.8252868 },
    { lat: 48.5317382, lng: 18.8248544 },
    { lat: 48.5311395, lng: 18.8244446 },
    { lat: 48.5307931, lng: 18.8235798 },
    { lat: 48.5302225, lng: 18.8232347 },
    { lat: 48.529634, lng: 18.8223965 },
    { lat: 48.5290351, lng: 18.8213354 },
    { lat: 48.5285902, lng: 18.8211515 },
    { lat: 48.5281918, lng: 18.820907 },
    { lat: 48.5277281, lng: 18.8213127 },
    { lat: 48.5276587, lng: 18.8213462 },
    { lat: 48.5274849, lng: 18.8214298 },
    { lat: 48.5259767, lng: 18.8221556 },
    { lat: 48.5253847, lng: 18.8226922 },
    { lat: 48.5250072, lng: 18.8230208 },
    { lat: 48.5246485, lng: 18.8232564 },
    { lat: 48.5239707, lng: 18.8230642 },
    { lat: 48.5236025, lng: 18.8230502 },
    { lat: 48.523284, lng: 18.8230203 },
    { lat: 48.5231943, lng: 18.8230527 },
    { lat: 48.5229768, lng: 18.8231418 },
    { lat: 48.5225995, lng: 18.8228377 },
    { lat: 48.5223329, lng: 18.8226229 },
    { lat: 48.5219996, lng: 18.8223533 },
    { lat: 48.5218737, lng: 18.8220157 },
    { lat: 48.5218593, lng: 18.8219743 },
    { lat: 48.5216625, lng: 18.8217102 },
    { lat: 48.5211151, lng: 18.8212454 },
    { lat: 48.5208492, lng: 18.8205819 },
    { lat: 48.5205923, lng: 18.8201961 },
    { lat: 48.5202999, lng: 18.8201497 },
    { lat: 48.5200547, lng: 18.8200525 },
    { lat: 48.5197802, lng: 18.8196774 },
    { lat: 48.5193448, lng: 18.8194131 },
    { lat: 48.5187029, lng: 18.8193892 },
    { lat: 48.518434, lng: 18.8193578 },
    { lat: 48.5183676, lng: 18.8194033 },
    { lat: 48.5183135, lng: 18.8192956 },
    { lat: 48.5181638, lng: 18.8189989 },
    { lat: 48.5180929, lng: 18.818552 },
    { lat: 48.5180417, lng: 18.8184994 },
    { lat: 48.5174228, lng: 18.8185436 },
    { lat: 48.5169312, lng: 18.8185869 },
    { lat: 48.5169016, lng: 18.8184954 },
    { lat: 48.5167681, lng: 18.8183829 },
    { lat: 48.5165971, lng: 18.8183665 },
    { lat: 48.516317, lng: 18.8183616 },
    { lat: 48.5158029, lng: 18.8176315 },
    { lat: 48.5156252, lng: 18.8169266 },
    { lat: 48.5154405, lng: 18.8163727 },
    { lat: 48.5152116, lng: 18.8156604 },
    { lat: 48.5147163, lng: 18.8159585 },
    { lat: 48.5144406, lng: 18.8162035 },
    { lat: 48.5143385, lng: 18.8163021 },
    { lat: 48.5140717, lng: 18.8168266 },
    { lat: 48.5139742, lng: 18.8170989 },
    { lat: 48.5139655, lng: 18.8171494 },
    { lat: 48.5135783, lng: 18.8176576 },
    { lat: 48.5132633, lng: 18.8182078 },
    { lat: 48.5131215, lng: 18.8184964 },
    { lat: 48.5128416, lng: 18.8189414 },
    { lat: 48.5127634, lng: 18.8190463 },
    { lat: 48.5128574, lng: 18.8193343 },
    { lat: 48.512836, lng: 18.8193774 },
    { lat: 48.5124587, lng: 18.8201185 },
    { lat: 48.5124974, lng: 18.8204112 },
    { lat: 48.5125258, lng: 18.8206255 },
    { lat: 48.5125388, lng: 18.8208743 },
    { lat: 48.5125902, lng: 18.821554 },
    { lat: 48.5123008, lng: 18.8218632 },
    { lat: 48.5117508, lng: 18.8222132 },
    { lat: 48.5111257, lng: 18.822554 },
    { lat: 48.5106009, lng: 18.8229337 },
    { lat: 48.5102484, lng: 18.8232425 },
    { lat: 48.5100896, lng: 18.8234192 },
    { lat: 48.5098762, lng: 18.8237585 },
    { lat: 48.5095789, lng: 18.8241547 },
    { lat: 48.5094866, lng: 18.8242887 },
    { lat: 48.5093032, lng: 18.824555 },
    { lat: 48.5092759, lng: 18.8245921 },
    { lat: 48.5091377, lng: 18.8247741 },
    { lat: 48.5091084, lng: 18.8247968 },
    { lat: 48.5088298, lng: 18.8250144 },
    { lat: 48.5086411, lng: 18.8251334 },
    { lat: 48.5084766, lng: 18.8252009 },
    { lat: 48.5083273, lng: 18.825215 },
    { lat: 48.5081686, lng: 18.825284 },
    { lat: 48.5079919, lng: 18.8253981 },
    { lat: 48.5079136, lng: 18.8254326 },
    { lat: 48.5077354, lng: 18.8256044 },
    { lat: 48.5076548, lng: 18.8259611 },
    { lat: 48.5076232, lng: 18.8261974 },
    { lat: 48.507598, lng: 18.8264063 },
    { lat: 48.5075242, lng: 18.826527 },
    { lat: 48.507413, lng: 18.8264701 },
    { lat: 48.5060559, lng: 18.8266339 },
    { lat: 48.5057712, lng: 18.8263863 },
    { lat: 48.5055216, lng: 18.8262704 },
    { lat: 48.5050822, lng: 18.8263644 },
    { lat: 48.5045959, lng: 18.8262733 },
    { lat: 48.5045566, lng: 18.8262765 },
    { lat: 48.5045002, lng: 18.8262551 },
    { lat: 48.5036871, lng: 18.8261021 },
    { lat: 48.5028135, lng: 18.8258716 },
    { lat: 48.5024404, lng: 18.8258325 },
    { lat: 48.5021384, lng: 18.8258999 },
    { lat: 48.5025605, lng: 18.8267062 },
    { lat: 48.5024943, lng: 18.8268004 },
    { lat: 48.5024629, lng: 18.8268457 },
    { lat: 48.5024441, lng: 18.8268736 },
    { lat: 48.5024205, lng: 18.8278873 },
    { lat: 48.50244, lng: 18.8287352 },
    { lat: 48.5018864, lng: 18.8291039 },
    { lat: 48.50169, lng: 18.8290582 },
    { lat: 48.501356, lng: 18.8288157 },
    { lat: 48.5012083, lng: 18.8279343 },
    { lat: 48.5013916, lng: 18.8267165 },
    { lat: 48.5005112, lng: 18.8259442 },
    { lat: 48.5000489, lng: 18.8259463 },
    { lat: 48.4997975, lng: 18.8260112 },
    { lat: 48.499635, lng: 18.8261389 },
    { lat: 48.4995064, lng: 18.8261794 },
    { lat: 48.4993544, lng: 18.8262269 },
    { lat: 48.4992804, lng: 18.8261485 },
    { lat: 48.4992234, lng: 18.8264422 },
    { lat: 48.498855, lng: 18.8271784 },
    { lat: 48.4986838, lng: 18.8285433 },
    { lat: 48.4984822, lng: 18.8288719 },
    { lat: 48.4982843, lng: 18.8291861 },
    { lat: 48.4982202, lng: 18.8292667 },
    { lat: 48.4980632, lng: 18.829464 },
    { lat: 48.4979873, lng: 18.829521 },
    { lat: 48.4979395, lng: 18.8295565 },
    { lat: 48.4976746, lng: 18.8297542 },
    { lat: 48.4976155, lng: 18.8298082 },
    { lat: 48.4966219, lng: 18.8321161 },
    { lat: 48.4963182, lng: 18.8326729 },
    { lat: 48.4957448, lng: 18.8332451 },
    { lat: 48.4951612, lng: 18.8339238 },
    { lat: 48.4949859, lng: 18.8342592 },
    { lat: 48.494724, lng: 18.834748 },
    { lat: 48.494648, lng: 18.834943 },
    { lat: 48.494613, lng: 18.835031 },
    { lat: 48.494582, lng: 18.835108 },
    { lat: 48.49436, lng: 18.835836 },
    { lat: 48.494255, lng: 18.836547 },
    { lat: 48.494249, lng: 18.837049 },
    { lat: 48.494288, lng: 18.83842 },
    { lat: 48.494291, lng: 18.838946 },
    { lat: 48.494172, lng: 18.839471 },
    { lat: 48.494157, lng: 18.839538 },
    { lat: 48.494216, lng: 18.840207 },
    { lat: 48.494173, lng: 18.840333 },
    { lat: 48.494113, lng: 18.840427 },
    { lat: 48.494036, lng: 18.840544 },
    { lat: 48.494016, lng: 18.840686 },
    { lat: 48.493911, lng: 18.840905 },
    { lat: 48.493924, lng: 18.841045 },
    { lat: 48.493832, lng: 18.841126 },
    { lat: 48.493827, lng: 18.841331 },
    { lat: 48.493718, lng: 18.841699 },
    { lat: 48.493757, lng: 18.84188 },
    { lat: 48.493904, lng: 18.841961 },
    { lat: 48.493927, lng: 18.842161 },
    { lat: 48.493957, lng: 18.842265 },
    { lat: 48.494035, lng: 18.842415 },
    { lat: 48.494085, lng: 18.842499 },
    { lat: 48.49409, lng: 18.842533 },
    { lat: 48.494105, lng: 18.842628 },
    { lat: 48.494106, lng: 18.842643 },
    { lat: 48.494111, lng: 18.842663 },
    { lat: 48.494116, lng: 18.84269 },
    { lat: 48.494256, lng: 18.843011 },
    { lat: 48.494376, lng: 18.843178 },
    { lat: 48.494382, lng: 18.843187 },
    { lat: 48.494508, lng: 18.843334 },
    { lat: 48.494518, lng: 18.843423 },
    { lat: 48.494922, lng: 18.843582 },
    { lat: 48.495086, lng: 18.843511 },
    { lat: 48.495376, lng: 18.843254 },
    { lat: 48.495529, lng: 18.843181 },
    { lat: 48.495676, lng: 18.843176 },
    { lat: 48.495869, lng: 18.843272 },
    { lat: 48.496247, lng: 18.843601 },
    { lat: 48.496629, lng: 18.845292 },
    { lat: 48.496761, lng: 18.845809 },
    { lat: 48.496968, lng: 18.846519 },
    { lat: 48.497277, lng: 18.847077 },
    { lat: 48.497618, lng: 18.8473699 },
    { lat: 48.4979, lng: 18.847612 },
    { lat: 48.498124, lng: 18.848032 },
    { lat: 48.497671, lng: 18.848624 },
    { lat: 48.497328, lng: 18.8495 },
    { lat: 48.4973732, lng: 18.8496155 },
    { lat: 48.4974208, lng: 18.8497369 },
    { lat: 48.497495, lng: 18.8499263 },
    { lat: 48.4975801, lng: 18.8501437 },
    { lat: 48.4976544, lng: 18.8503335 },
    { lat: 48.497711, lng: 18.850478 },
    { lat: 48.497489, lng: 18.850969 },
    { lat: 48.497443, lng: 18.85107 },
    { lat: 48.496901, lng: 18.852267 },
    { lat: 48.496771, lng: 18.853847 },
    { lat: 48.497202, lng: 18.854611 },
    { lat: 48.497232, lng: 18.854663 },
    { lat: 48.497641, lng: 18.855386 },
    { lat: 48.497822, lng: 18.855893 },
    { lat: 48.497827, lng: 18.855924 },
    { lat: 48.497833, lng: 18.855964 },
    { lat: 48.497914, lng: 18.856464 },
    { lat: 48.498218, lng: 18.857624 },
    { lat: 48.498278, lng: 18.8577 },
    { lat: 48.498299, lng: 18.857728 },
    { lat: 48.498805, lng: 18.858383 },
    { lat: 48.499421, lng: 18.85906 },
    { lat: 48.501156, lng: 18.860262 },
    { lat: 48.501874, lng: 18.861641 },
    { lat: 48.501921, lng: 18.86166 },
    { lat: 48.504627, lng: 18.862719 },
    { lat: 48.506431, lng: 18.863882 },
    { lat: 48.507137, lng: 18.863801 },
    { lat: 48.507365, lng: 18.863697 },
    { lat: 48.50795, lng: 18.862978 },
    { lat: 48.5080338, lng: 18.862909 },
    { lat: 48.508431, lng: 18.862582 },
    { lat: 48.508734, lng: 18.862497 },
    { lat: 48.509357, lng: 18.862497 },
    { lat: 48.510522, lng: 18.86242 },
    { lat: 48.511582, lng: 18.862639 },
    { lat: 48.511986, lng: 18.862766 },
  ],
  HronskáDúbrava: [
    { lat: 48.571279, lng: 18.997852 },
    { lat: 48.571373, lng: 18.99786 },
    { lat: 48.571544, lng: 18.997884 },
    { lat: 48.571562, lng: 18.997887 },
    { lat: 48.571695, lng: 18.997911 },
    { lat: 48.571754, lng: 18.997948 },
    { lat: 48.572029, lng: 18.998125 },
    { lat: 48.571995, lng: 18.998401 },
    { lat: 48.571987, lng: 18.998439 },
    { lat: 48.571983, lng: 18.998494 },
    { lat: 48.571977, lng: 18.998516 },
    { lat: 48.57241, lng: 18.998546 },
    { lat: 48.572449, lng: 18.998553 },
    { lat: 48.572714, lng: 18.998592 },
    { lat: 48.57277, lng: 18.998601 },
    { lat: 48.572865, lng: 18.998612 },
    { lat: 48.572895, lng: 18.998619 },
    { lat: 48.573094, lng: 18.998695 },
    { lat: 48.573785, lng: 18.999013 },
    { lat: 48.573817, lng: 18.999026 },
    { lat: 48.574098, lng: 18.999136 },
    { lat: 48.574183, lng: 18.999187 },
    { lat: 48.574502, lng: 18.999422 },
    { lat: 48.574621, lng: 18.999474 },
    { lat: 48.575155, lng: 18.999574 },
    { lat: 48.575233, lng: 18.999612 },
    { lat: 48.575289, lng: 18.999711 },
    { lat: 48.575378, lng: 18.999806 },
    { lat: 48.575808, lng: 18.999779 },
    { lat: 48.575938, lng: 18.999788 },
    { lat: 48.576107, lng: 18.999771 },
    { lat: 48.57624, lng: 18.999901 },
    { lat: 48.576317, lng: 19.000047 },
    { lat: 48.576425, lng: 19.000286 },
    { lat: 48.576505, lng: 19.000512 },
    { lat: 48.576594, lng: 19.000762 },
    { lat: 48.576749, lng: 19.000961 },
    { lat: 48.576934, lng: 19.001168 },
    { lat: 48.577123, lng: 19.001358 },
    { lat: 48.577287, lng: 19.001466 },
    { lat: 48.577416, lng: 19.001644 },
    { lat: 48.577528, lng: 19.001845 },
    { lat: 48.577639, lng: 19.002114 },
    { lat: 48.577718, lng: 19.002334 },
    { lat: 48.577806, lng: 19.002425 },
    { lat: 48.577954, lng: 19.002513 },
    { lat: 48.578132, lng: 19.002607 },
    { lat: 48.578333, lng: 19.002834 },
    { lat: 48.578564, lng: 19.003076 },
    { lat: 48.578812, lng: 19.003284 },
    { lat: 48.579029, lng: 19.003513 },
    { lat: 48.579186, lng: 19.003778 },
    { lat: 48.579442, lng: 19.003981 },
    { lat: 48.579691, lng: 19.004088 },
    { lat: 48.579871, lng: 19.004291 },
    { lat: 48.580037, lng: 19.004471 },
    { lat: 48.58026, lng: 19.004574 },
    { lat: 48.580388, lng: 19.004654 },
    { lat: 48.580457, lng: 19.004823 },
    { lat: 48.580562, lng: 19.005035 },
    { lat: 48.580697, lng: 19.005235 },
    { lat: 48.581004, lng: 19.005374 },
    { lat: 48.581287, lng: 19.005462 },
    { lat: 48.581517, lng: 19.005677 },
    { lat: 48.581712, lng: 19.005977 },
    { lat: 48.581799, lng: 19.006123 },
    { lat: 48.58189, lng: 19.00645 },
    { lat: 48.581949, lng: 19.006773 },
    { lat: 48.582073, lng: 19.007091 },
    { lat: 48.582181, lng: 19.007369 },
    { lat: 48.582281, lng: 19.007686 },
    { lat: 48.582386, lng: 19.007899 },
    { lat: 48.582536, lng: 19.008085 },
    { lat: 48.582753, lng: 19.008354 },
    { lat: 48.582799, lng: 19.00847 },
    { lat: 48.582833, lng: 19.008697 },
    { lat: 48.582855, lng: 19.00895 },
    { lat: 48.582923, lng: 19.009187 },
    { lat: 48.583033, lng: 19.009396 },
    { lat: 48.583131, lng: 19.009664 },
    { lat: 48.583201, lng: 19.009996 },
    { lat: 48.583218, lng: 19.010318 },
    { lat: 48.583178, lng: 19.010529 },
    { lat: 48.58311, lng: 19.010825 },
    { lat: 48.583077, lng: 19.011101 },
    { lat: 48.582961, lng: 19.011381 },
    { lat: 48.582823, lng: 19.011581 },
    { lat: 48.582685, lng: 19.011791 },
    { lat: 48.582685, lng: 19.012008 },
    { lat: 48.582752, lng: 19.012183 },
    { lat: 48.58286, lng: 19.012282 },
    { lat: 48.582972, lng: 19.01237 },
    { lat: 48.583147, lng: 19.012372 },
    { lat: 48.583334, lng: 19.012302 },
    { lat: 48.583523, lng: 19.0122 },
    { lat: 48.583681, lng: 19.012118 },
    { lat: 48.583864, lng: 19.012049 },
    { lat: 48.584071, lng: 19.011982 },
    { lat: 48.584238, lng: 19.011962 },
    { lat: 48.584342, lng: 19.012022 },
    { lat: 48.58447, lng: 19.012179 },
    { lat: 48.584656, lng: 19.0123 },
    { lat: 48.584865, lng: 19.012291 },
    { lat: 48.584998, lng: 19.01221 },
    { lat: 48.585154, lng: 19.01216 },
    { lat: 48.585238, lng: 19.012188 },
    { lat: 48.58532, lng: 19.012299 },
    { lat: 48.585425, lng: 19.012431 },
    { lat: 48.585552, lng: 19.012475 },
    { lat: 48.585719, lng: 19.012488 },
    { lat: 48.585979, lng: 19.012513 },
    { lat: 48.586184, lng: 19.012558 },
    { lat: 48.586476, lng: 19.012688 },
    { lat: 48.586638, lng: 19.012723 },
    { lat: 48.586945, lng: 19.012746 },
    { lat: 48.587064, lng: 19.012789 },
    { lat: 48.587202, lng: 19.012882 },
    { lat: 48.58729, lng: 19.012944 },
    { lat: 48.587484, lng: 19.013031 },
    { lat: 48.587735, lng: 19.013109 },
    { lat: 48.587913, lng: 19.013146 },
    { lat: 48.588094, lng: 19.013199 },
    { lat: 48.588292, lng: 19.013146 },
    { lat: 48.588407, lng: 19.013071 },
    { lat: 48.588548, lng: 19.012916 },
    { lat: 48.58879, lng: 19.012743 },
    { lat: 48.589015, lng: 19.012588 },
    { lat: 48.589245, lng: 19.012528 },
    { lat: 48.589401, lng: 19.012456 },
    { lat: 48.589573, lng: 19.01229 },
    { lat: 48.589773, lng: 19.0122 },
    { lat: 48.589936, lng: 19.012041 },
    { lat: 48.59008, lng: 19.011986 },
    { lat: 48.59028, lng: 19.011806 },
    { lat: 48.590504, lng: 19.011648 },
    { lat: 48.590846, lng: 19.011525 },
    { lat: 48.590991, lng: 19.011472 },
    { lat: 48.591171, lng: 19.011357 },
    { lat: 48.591337, lng: 19.011175 },
    { lat: 48.591587, lng: 19.010785 },
    { lat: 48.59175, lng: 19.010537 },
    { lat: 48.591906, lng: 19.010378 },
    { lat: 48.592038, lng: 19.010356 },
    { lat: 48.592184, lng: 19.010337 },
    { lat: 48.592434, lng: 19.010139 },
    { lat: 48.592641, lng: 19.010123 },
    { lat: 48.592846, lng: 19.01015 },
    { lat: 48.593048, lng: 19.010187 },
    { lat: 48.593226, lng: 19.010209 },
    { lat: 48.59335, lng: 19.010197 },
    { lat: 48.593434, lng: 19.010055 },
    { lat: 48.593464, lng: 19.009907 },
    { lat: 48.593472, lng: 19.009869 },
    { lat: 48.593481, lng: 19.00983 },
    { lat: 48.593494, lng: 19.009755 },
    { lat: 48.593423, lng: 19.009617 },
    { lat: 48.593301, lng: 19.00951 },
    { lat: 48.593233, lng: 19.009429 },
    { lat: 48.593215, lng: 19.00929 },
    { lat: 48.593237, lng: 19.009072 },
    { lat: 48.59327, lng: 19.00877 },
    { lat: 48.593272, lng: 19.008398 },
    { lat: 48.593333, lng: 19.007896 },
    { lat: 48.593368, lng: 19.007547 },
    { lat: 48.593396, lng: 19.007298 },
    { lat: 48.593452, lng: 19.006989 },
    { lat: 48.593539, lng: 19.006673 },
    { lat: 48.593678, lng: 19.006349 },
    { lat: 48.593832, lng: 19.006008 },
    { lat: 48.593905, lng: 19.005765 },
    { lat: 48.59393, lng: 19.005477 },
    { lat: 48.593979, lng: 19.005211 },
    { lat: 48.594084, lng: 19.004958 },
    { lat: 48.594144, lng: 19.004732 },
    { lat: 48.594144, lng: 19.004517 },
    { lat: 48.59426, lng: 19.004117 },
    { lat: 48.594321, lng: 19.003922 },
    { lat: 48.594361, lng: 19.003693 },
    { lat: 48.594405, lng: 19.003609 },
    { lat: 48.594437, lng: 19.003462 },
    { lat: 48.59443, lng: 19.003306 },
    { lat: 48.594441, lng: 19.003165 },
    { lat: 48.594539, lng: 19.002965 },
    { lat: 48.594574, lng: 19.002758 },
    { lat: 48.594548, lng: 19.002567 },
    { lat: 48.594434, lng: 19.00222 },
    { lat: 48.594298, lng: 19.001806 },
    { lat: 48.594293, lng: 19.001573 },
    { lat: 48.594211, lng: 19.0012 },
    { lat: 48.594177, lng: 19.000798 },
    { lat: 48.594232, lng: 19.000531 },
    { lat: 48.594245, lng: 19.000267 },
    { lat: 48.594598, lng: 18.999591 },
    { lat: 48.595189, lng: 18.999208 },
    { lat: 48.59654, lng: 18.997868 },
    { lat: 48.596554, lng: 18.997749 },
    { lat: 48.597049, lng: 18.997283 },
    { lat: 48.598144, lng: 18.996544 },
    { lat: 48.598267, lng: 18.996142 },
    { lat: 48.5976712, lng: 18.9953048 },
    { lat: 48.5975166, lng: 18.9950793 },
    { lat: 48.5974081, lng: 18.9946494 },
    { lat: 48.5972556, lng: 18.9941826 },
    { lat: 48.597083, lng: 18.9938915 },
    { lat: 48.5970559, lng: 18.9938458 },
    { lat: 48.5971307, lng: 18.9936719 },
    { lat: 48.597337, lng: 18.9934708 },
    { lat: 48.5975441, lng: 18.9933105 },
    { lat: 48.5977826, lng: 18.9931875 },
    { lat: 48.597997, lng: 18.993082 },
    { lat: 48.5980899, lng: 18.9930232 },
    { lat: 48.5983347, lng: 18.9928689 },
    { lat: 48.5985552, lng: 18.9926611 },
    { lat: 48.5988052, lng: 18.9923872 },
    { lat: 48.5989538, lng: 18.9922294 },
    { lat: 48.5990732, lng: 18.9920493 },
    { lat: 48.5991603, lng: 18.9918754 },
    { lat: 48.5993349, lng: 18.9914516 },
    { lat: 48.599372, lng: 18.9913808 },
    { lat: 48.5994143, lng: 18.9913025 },
    { lat: 48.5995664, lng: 18.9910125 },
    { lat: 48.599815, lng: 18.9906443 },
    { lat: 48.5999741, lng: 18.9904237 },
    { lat: 48.6000245, lng: 18.990332 },
    { lat: 48.6000021, lng: 18.9884427 },
    { lat: 48.600318, lng: 18.9877579 },
    { lat: 48.5997584, lng: 18.9869088 },
    { lat: 48.5996686, lng: 18.9867581 },
    { lat: 48.5995969, lng: 18.9866364 },
    { lat: 48.5994131, lng: 18.9863245 },
    { lat: 48.5990557, lng: 18.985772 },
    { lat: 48.5989561, lng: 18.9854949 },
    { lat: 48.5988267, lng: 18.9849659 },
    { lat: 48.5982059, lng: 18.9844242 },
    { lat: 48.5974014, lng: 18.9827673 },
    { lat: 48.5971701, lng: 18.9826085 },
    { lat: 48.5967841, lng: 18.9824236 },
    { lat: 48.5965281, lng: 18.982134 },
    { lat: 48.596359, lng: 18.9819755 },
    { lat: 48.5963279, lng: 18.9819456 },
    { lat: 48.5962924, lng: 18.9819123 },
    { lat: 48.5959422, lng: 18.9812079 },
    { lat: 48.5957044, lng: 18.9809458 },
    { lat: 48.5955276, lng: 18.9812369 },
    { lat: 48.5953736, lng: 18.9814898 },
    { lat: 48.5952327, lng: 18.9816457 },
    { lat: 48.5950643, lng: 18.9817499 },
    { lat: 48.5949985, lng: 18.9817664 },
    { lat: 48.5947481, lng: 18.9816724 },
    { lat: 48.5945165, lng: 18.981552 },
    { lat: 48.5943579, lng: 18.9814478 },
    { lat: 48.5941546, lng: 18.981311 },
    { lat: 48.5938704, lng: 18.9810414 },
    { lat: 48.5935575, lng: 18.9808079 },
    { lat: 48.5932631, lng: 18.9805848 },
    { lat: 48.5929621, lng: 18.9804083 },
    { lat: 48.5927816, lng: 18.9803608 },
    { lat: 48.5927689, lng: 18.9803583 },
    { lat: 48.5927327, lng: 18.9803515 },
    { lat: 48.59235, lng: 18.9802778 },
    { lat: 48.5922273, lng: 18.9802349 },
    { lat: 48.5919242, lng: 18.9800171 },
    { lat: 48.591228, lng: 18.9794745 },
    { lat: 48.5907586, lng: 18.9791366 },
    { lat: 48.5898181, lng: 18.9785344 },
    { lat: 48.5891366, lng: 18.9781465 },
    { lat: 48.5888296, lng: 18.9791158 },
    { lat: 48.588812, lng: 18.9791702 },
    { lat: 48.5885362, lng: 18.9800422 },
    { lat: 48.5881564, lng: 18.9798902 },
    { lat: 48.58764, lng: 18.9796264 },
    { lat: 48.5874013, lng: 18.9794538 },
    { lat: 48.5870416, lng: 18.9792749 },
    { lat: 48.5868158, lng: 18.9790482 },
    { lat: 48.5866116, lng: 18.978737 },
    { lat: 48.586419, lng: 18.9783643 },
    { lat: 48.5860771, lng: 18.9776239 },
    { lat: 48.5859532, lng: 18.977474 },
    { lat: 48.5855195, lng: 18.9770807 },
    { lat: 48.5853409, lng: 18.9769607 },
    { lat: 48.5848309, lng: 18.9768458 },
    { lat: 48.5847615, lng: 18.9767865 },
    { lat: 48.5847533, lng: 18.9771607 },
    { lat: 48.5846246, lng: 18.9772628 },
    { lat: 48.5843649, lng: 18.9780574 },
    { lat: 48.5841726, lng: 18.9788192 },
    { lat: 48.5839529, lng: 18.9797117 },
    { lat: 48.583689, lng: 18.9806414 },
    { lat: 48.5835469, lng: 18.981151 },
    { lat: 48.5833551, lng: 18.981812 },
    { lat: 48.5834274, lng: 18.9819471 },
    { lat: 48.5833309, lng: 18.9831587 },
    { lat: 48.5832515, lng: 18.9834563 },
    { lat: 48.5831631, lng: 18.9837578 },
    { lat: 48.5830757, lng: 18.9840798 },
    { lat: 48.5828194, lng: 18.9847408 },
    { lat: 48.5826618, lng: 18.9846568 },
    { lat: 48.5826108, lng: 18.9846288 },
    { lat: 48.5823838, lng: 18.9845097 },
    { lat: 48.582298, lng: 18.9844623 },
    { lat: 48.582226, lng: 18.9844218 },
    { lat: 48.5822157, lng: 18.9844194 },
    { lat: 48.5817338, lng: 18.9842115 },
    { lat: 48.5817143, lng: 18.9842031 },
    { lat: 48.5816254, lng: 18.9841649 },
    { lat: 48.5810434, lng: 18.9839119 },
    { lat: 48.5807943, lng: 18.9838039 },
    { lat: 48.5806836, lng: 18.9837555 },
    { lat: 48.5806387, lng: 18.9837599 },
    { lat: 48.5805477, lng: 18.9838993 },
    { lat: 48.5804953, lng: 18.9839777 },
    { lat: 48.5799154, lng: 18.9848604 },
    { lat: 48.5794011, lng: 18.9853673 },
    { lat: 48.5791864, lng: 18.9854907 },
    { lat: 48.5788476, lng: 18.9856572 },
    { lat: 48.5786428, lng: 18.9862217 },
    { lat: 48.5785597, lng: 18.9864499 },
    { lat: 48.5779503, lng: 18.987081 },
    { lat: 48.5772232, lng: 18.9875255 },
    { lat: 48.5766501, lng: 18.9877878 },
    { lat: 48.5761204, lng: 18.9879941 },
    { lat: 48.5756359, lng: 18.9880653 },
    { lat: 48.5751357, lng: 18.9881386 },
    { lat: 48.5749016, lng: 18.9883013 },
    { lat: 48.5745593, lng: 18.9885551 },
    { lat: 48.5745288, lng: 18.9885778 },
    { lat: 48.5737967, lng: 18.9891282 },
    { lat: 48.573511, lng: 18.989339 },
    { lat: 48.573127, lng: 18.989541 },
    { lat: 48.572649, lng: 18.989645 },
    { lat: 48.571443, lng: 18.990324 },
    { lat: 48.571267, lng: 18.990531 },
    { lat: 48.571237, lng: 18.990564 },
    { lat: 48.570547, lng: 18.991454 },
    { lat: 48.570469, lng: 18.9919 },
    { lat: 48.570498, lng: 18.992482 },
    { lat: 48.570513, lng: 18.992736 },
    { lat: 48.570623, lng: 18.995182 },
    { lat: 48.570641, lng: 18.996105 },
    { lat: 48.570577, lng: 18.99705 },
    { lat: 48.570512, lng: 18.997791 },
    { lat: 48.570924, lng: 18.997824 },
    { lat: 48.571016, lng: 18.997831 },
    { lat: 48.57106, lng: 18.997834 },
    { lat: 48.571096, lng: 18.997837 },
    { lat: 48.571153, lng: 18.997842 },
    { lat: 48.571279, lng: 18.997852 },
  ],
  Slaská: [
    { lat: 48.6608238, lng: 18.850107 },
    { lat: 48.6608936, lng: 18.8505821 },
    { lat: 48.6627434, lng: 18.8498504 },
    { lat: 48.6626653, lng: 18.8507821 },
    { lat: 48.6629507, lng: 18.8513045 },
    { lat: 48.6637423, lng: 18.852153 },
    { lat: 48.6644318, lng: 18.8525313 },
    { lat: 48.6648953, lng: 18.8527526 },
    { lat: 48.6656124, lng: 18.8525737 },
    { lat: 48.6655455, lng: 18.8523076 },
    { lat: 48.6655798, lng: 18.8519262 },
    { lat: 48.6656983, lng: 18.8514912 },
    { lat: 48.665868, lng: 18.8514608 },
    { lat: 48.6662108, lng: 18.8514146 },
    { lat: 48.6664676, lng: 18.8513899 },
    { lat: 48.666493, lng: 18.8514429 },
    { lat: 48.6675104, lng: 18.8512078 },
    { lat: 48.6673456, lng: 18.8511195 },
    { lat: 48.6675004, lng: 18.8510377 },
    { lat: 48.6676585, lng: 18.8507147 },
    { lat: 48.6678103, lng: 18.8495784 },
    { lat: 48.6680515, lng: 18.8492274 },
    { lat: 48.6682599, lng: 18.8489227 },
    { lat: 48.6685256, lng: 18.8488459 },
    { lat: 48.6689092, lng: 18.8490899 },
    { lat: 48.6692173, lng: 18.8497031 },
    { lat: 48.6697863, lng: 18.8494771 },
    { lat: 48.6699785, lng: 18.8486174 },
    { lat: 48.6699667, lng: 18.848303 },
    { lat: 48.6702506, lng: 18.8477775 },
    { lat: 48.6702536, lng: 18.8470978 },
    { lat: 48.6708545, lng: 18.846005 },
    { lat: 48.6708543, lng: 18.8449418 },
    { lat: 48.670864, lng: 18.8448633 },
    { lat: 48.6710296, lng: 18.8436021 },
    { lat: 48.6719697, lng: 18.8440513 },
    { lat: 48.6720479, lng: 18.8440371 },
    { lat: 48.6723914, lng: 18.8436632 },
    { lat: 48.6732436, lng: 18.8421137 },
    { lat: 48.6741927, lng: 18.8425626 },
    { lat: 48.6757667, lng: 18.8416251 },
    { lat: 48.6765176, lng: 18.8419925 },
    { lat: 48.6771856, lng: 18.8420886 },
    { lat: 48.6776427, lng: 18.8420313 },
    { lat: 48.678189, lng: 18.8415855 },
    { lat: 48.6783869, lng: 18.841683 },
    { lat: 48.6796035, lng: 18.8408773 },
    { lat: 48.6800323, lng: 18.8406667 },
    { lat: 48.68063, lng: 18.8403001 },
    { lat: 48.6804713, lng: 18.8401512 },
    { lat: 48.680483, lng: 18.8400952 },
    { lat: 48.6811538, lng: 18.8400246 },
    { lat: 48.6811669, lng: 18.8399774 },
    { lat: 48.6811756, lng: 18.8399499 },
    { lat: 48.6813288, lng: 18.8400963 },
    { lat: 48.68139, lng: 18.840124 },
    { lat: 48.6814705, lng: 18.8401323 },
    { lat: 48.6815331, lng: 18.8401859 },
    { lat: 48.6815937, lng: 18.8403104 },
    { lat: 48.6816879, lng: 18.8406504 },
    { lat: 48.6817023, lng: 18.8407308 },
    { lat: 48.6817702, lng: 18.8408644 },
    { lat: 48.6825852, lng: 18.8413606 },
    { lat: 48.6831241, lng: 18.8415737 },
    { lat: 48.6834117, lng: 18.8415335 },
    { lat: 48.6842398, lng: 18.8416713 },
    { lat: 48.6844016, lng: 18.8415069 },
    { lat: 48.6845666, lng: 18.8415816 },
    { lat: 48.6847676, lng: 18.8417797 },
    { lat: 48.6852048, lng: 18.841795 },
    { lat: 48.685436, lng: 18.8416652 },
    { lat: 48.6856209, lng: 18.8417536 },
    { lat: 48.6859592, lng: 18.8418206 },
    { lat: 48.6861379, lng: 18.8419804 },
    { lat: 48.6867358, lng: 18.8422457 },
    { lat: 48.6870597, lng: 18.8427491 },
    { lat: 48.6874459, lng: 18.8426734 },
    { lat: 48.6876284, lng: 18.8427346 },
    { lat: 48.6877585, lng: 18.8428105 },
    { lat: 48.6878712, lng: 18.8427422 },
    { lat: 48.6880351, lng: 18.8426618 },
    { lat: 48.6883163, lng: 18.8428455 },
    { lat: 48.6887943, lng: 18.842735 },
    { lat: 48.6894904, lng: 18.8433495 },
    { lat: 48.6894915, lng: 18.8433701 },
    { lat: 48.689496, lng: 18.8434352 },
    { lat: 48.6895033, lng: 18.8435757 },
    { lat: 48.6897017, lng: 18.8436825 },
    { lat: 48.6897059, lng: 18.8437862 },
    { lat: 48.6899057, lng: 18.8436787 },
    { lat: 48.6898935, lng: 18.8436219 },
    { lat: 48.6909004, lng: 18.8440545 },
    { lat: 48.6909638, lng: 18.844038 },
    { lat: 48.6910597, lng: 18.8440153 },
    { lat: 48.6911149, lng: 18.8441001 },
    { lat: 48.6915356, lng: 18.8450585 },
    { lat: 48.6920342, lng: 18.8444756 },
    { lat: 48.6932143, lng: 18.8448591 },
    { lat: 48.6938099, lng: 18.8445151 },
    { lat: 48.6944578, lng: 18.8448057 },
    { lat: 48.6951505, lng: 18.8450959 },
    { lat: 48.6950618, lng: 18.8439111 },
    { lat: 48.695222, lng: 18.8438468 },
    { lat: 48.6956349, lng: 18.8436533 },
    { lat: 48.6966167, lng: 18.8440175 },
    { lat: 48.6968374, lng: 18.843835 },
    { lat: 48.6971634, lng: 18.8438189 },
    { lat: 48.6976163, lng: 18.8440276 },
    { lat: 48.6984021, lng: 18.8438703 },
    { lat: 48.6986154, lng: 18.8438557 },
    { lat: 48.6995285, lng: 18.843825 },
    { lat: 48.6998741, lng: 18.8438141 },
    { lat: 48.7005445, lng: 18.8437825 },
    { lat: 48.7006317, lng: 18.8437914 },
    { lat: 48.7009089, lng: 18.8437004 },
    { lat: 48.7010334, lng: 18.8437107 },
    { lat: 48.7018836, lng: 18.8435546 },
    { lat: 48.7029247, lng: 18.8432771 },
    { lat: 48.7032169, lng: 18.8432171 },
    { lat: 48.7036086, lng: 18.8430747 },
    { lat: 48.7038786, lng: 18.8428856 },
    { lat: 48.7040966, lng: 18.842743 },
    { lat: 48.7041171, lng: 18.842701 },
    { lat: 48.7041455, lng: 18.8426419 },
    { lat: 48.7041696, lng: 18.8426062 },
    { lat: 48.7046207, lng: 18.8420412 },
    { lat: 48.7048417, lng: 18.8418244 },
    { lat: 48.704843, lng: 18.841786 },
    { lat: 48.7050544, lng: 18.8418163 },
    { lat: 48.7052421, lng: 18.8417819 },
    { lat: 48.705431, lng: 18.8415553 },
    { lat: 48.7055662, lng: 18.84151 },
    { lat: 48.7058388, lng: 18.8417012 },
    { lat: 48.7061364, lng: 18.8416349 },
    { lat: 48.7064366, lng: 18.8414644 },
    { lat: 48.706664, lng: 18.8417373 },
    { lat: 48.706876, lng: 18.8416529 },
    { lat: 48.7069198, lng: 18.8416913 },
    { lat: 48.7070305, lng: 18.8416107 },
    { lat: 48.7072563, lng: 18.8414818 },
    { lat: 48.7075187, lng: 18.8418477 },
    { lat: 48.7078314, lng: 18.8418946 },
    { lat: 48.7080273, lng: 18.8419553 },
    { lat: 48.7081837, lng: 18.8418857 },
    { lat: 48.708293, lng: 18.8416421 },
    { lat: 48.7086717, lng: 18.841358 },
    { lat: 48.7086855, lng: 18.8413656 },
    { lat: 48.7087241, lng: 18.8413889 },
    { lat: 48.7087609, lng: 18.8414013 },
    { lat: 48.7088107, lng: 18.8414061 },
    { lat: 48.708904, lng: 18.8414233 },
    { lat: 48.7093029, lng: 18.841265 },
    { lat: 48.7094837, lng: 18.8413146 },
    { lat: 48.7097908, lng: 18.8417595 },
    { lat: 48.7099918, lng: 18.8418078 },
    { lat: 48.710189, lng: 18.8415866 },
    { lat: 48.7105401, lng: 18.8416379 },
    { lat: 48.7109529, lng: 18.8415329 },
    { lat: 48.7113582, lng: 18.8415605 },
    { lat: 48.7116082, lng: 18.8415159 },
    { lat: 48.7119372, lng: 18.8415301 },
    { lat: 48.7122934, lng: 18.8415058 },
    { lat: 48.7125153, lng: 18.841437 },
    { lat: 48.7128623, lng: 18.8413623 },
    { lat: 48.7128844, lng: 18.8413154 },
    { lat: 48.7129233, lng: 18.8410176 },
    { lat: 48.7129106, lng: 18.840927 },
    { lat: 48.7128937, lng: 18.8407744 },
    { lat: 48.7128868, lng: 18.8406645 },
    { lat: 48.7128782, lng: 18.8405213 },
    { lat: 48.712878, lng: 18.8403856 },
    { lat: 48.7129397, lng: 18.8399666 },
    { lat: 48.7129735, lng: 18.8398359 },
    { lat: 48.7130062, lng: 18.8397277 },
    { lat: 48.7130839, lng: 18.8394628 },
    { lat: 48.7131154, lng: 18.8393552 },
    { lat: 48.7131461, lng: 18.8392512 },
    { lat: 48.7132051, lng: 18.8390625 },
    { lat: 48.7132645, lng: 18.8389055 },
    { lat: 48.7133274, lng: 18.8387688 },
    { lat: 48.7133986, lng: 18.8386382 },
    { lat: 48.7135169, lng: 18.8384428 },
    { lat: 48.7135772, lng: 18.8383478 },
    { lat: 48.7136512, lng: 18.8382073 },
    { lat: 48.7136858, lng: 18.8381364 },
    { lat: 48.7137326, lng: 18.8380366 },
    { lat: 48.7137714, lng: 18.8379377 },
    { lat: 48.7138328, lng: 18.8378179 },
    { lat: 48.7138914, lng: 18.8377089 },
    { lat: 48.7139514, lng: 18.8376039 },
    { lat: 48.7140104, lng: 18.8375016 },
    { lat: 48.7140687, lng: 18.8374104 },
    { lat: 48.7141093, lng: 18.8373467 },
    { lat: 48.7141645, lng: 18.8372762 },
    { lat: 48.7142244, lng: 18.8372134 },
    { lat: 48.7142711, lng: 18.8371582 },
    { lat: 48.7143677, lng: 18.8370657 },
    { lat: 48.7144106, lng: 18.8370242 },
    { lat: 48.7144771, lng: 18.8369335 },
    { lat: 48.7145328, lng: 18.8368372 },
    { lat: 48.7145598, lng: 18.8367701 },
    { lat: 48.714869, lng: 18.8360775 },
    { lat: 48.7149045, lng: 18.8359829 },
    { lat: 48.714966, lng: 18.8358603 },
    { lat: 48.7150142, lng: 18.8357692 },
    { lat: 48.7150627, lng: 18.8356621 },
    { lat: 48.7150863, lng: 18.8355925 },
    { lat: 48.7152911, lng: 18.8350011 },
    { lat: 48.7153353, lng: 18.8347867 },
    { lat: 48.7153625, lng: 18.834596 },
    { lat: 48.7153796, lng: 18.8344475 },
    { lat: 48.7154064, lng: 18.8342047 },
    { lat: 48.7154217, lng: 18.8340405 },
    { lat: 48.7154608, lng: 18.8336234 },
    { lat: 48.7154798, lng: 18.8334669 },
    { lat: 48.7155136, lng: 18.8333343 },
    { lat: 48.7155523, lng: 18.8331708 },
    { lat: 48.7156161, lng: 18.8328794 },
    { lat: 48.7157502, lng: 18.8323364 },
    { lat: 48.7157729, lng: 18.8322733 },
    { lat: 48.7157888, lng: 18.8321875 },
    { lat: 48.7158258, lng: 18.832031 },
    { lat: 48.7158593, lng: 18.8318938 },
    { lat: 48.7158903, lng: 18.8317766 },
    { lat: 48.715926, lng: 18.8316403 },
    { lat: 48.7159759, lng: 18.8314686 },
    { lat: 48.7160058, lng: 18.8313758 },
    { lat: 48.7160622, lng: 18.831202 },
    { lat: 48.7161132, lng: 18.831056 },
    { lat: 48.716161, lng: 18.8309342 },
    { lat: 48.71626, lng: 18.8307114 },
    { lat: 48.7163231, lng: 18.83058 },
    { lat: 48.7163725, lng: 18.830466 },
    { lat: 48.7163949, lng: 18.830333 },
    { lat: 48.7164113, lng: 18.8301718 },
    { lat: 48.7164284, lng: 18.8300469 },
    { lat: 48.7164291, lng: 18.8299515 },
    { lat: 48.7164122, lng: 18.8298663 },
    { lat: 48.7163968, lng: 18.829785 },
    { lat: 48.7163481, lng: 18.8296058 },
    { lat: 48.7163278, lng: 18.8295204 },
    { lat: 48.7163265, lng: 18.8294087 },
    { lat: 48.7163246, lng: 18.8293504 },
    { lat: 48.71634, lng: 18.8291054 },
    { lat: 48.7163431, lng: 18.8289929 },
    { lat: 48.7163464, lng: 18.8288836 },
    { lat: 48.7163563, lng: 18.828746 },
    { lat: 48.7163715, lng: 18.8286249 },
    { lat: 48.7163985, lng: 18.8284092 },
    { lat: 48.7164134, lng: 18.8282186 },
    { lat: 48.716424, lng: 18.8280777 },
    { lat: 48.7164444, lng: 18.8276007 },
    { lat: 48.7164507, lng: 18.8273328 },
    { lat: 48.7164569, lng: 18.8272332 },
    { lat: 48.7164814, lng: 18.8270767 },
    { lat: 48.7165075, lng: 18.8269294 },
    { lat: 48.7165473, lng: 18.8267589 },
    { lat: 48.7165907, lng: 18.8265992 },
    { lat: 48.7166195, lng: 18.8265025 },
    { lat: 48.7168112, lng: 18.8259813 },
    { lat: 48.7168529, lng: 18.8258527 },
    { lat: 48.7168716, lng: 18.825758 },
    { lat: 48.7168982, lng: 18.8256606 },
    { lat: 48.7169522, lng: 18.8255042 },
    { lat: 48.7169939, lng: 18.8253739 },
    { lat: 48.7170493, lng: 18.8252259 },
    { lat: 48.7171029, lng: 18.8250856 },
    { lat: 48.7171685, lng: 18.8249153 },
    { lat: 48.7172445, lng: 18.8247313 },
    { lat: 48.7173243, lng: 18.8245297 },
    { lat: 48.7175699, lng: 18.8239652 },
    { lat: 48.7176462, lng: 18.8238055 },
    { lat: 48.7179534, lng: 18.8237468 },
    { lat: 48.7181431, lng: 18.8234511 },
    { lat: 48.7181713, lng: 18.8234077 },
    { lat: 48.7181871, lng: 18.8233841 },
    { lat: 48.718431, lng: 18.8231301 },
    { lat: 48.7188411, lng: 18.8226921 },
    { lat: 48.7195495, lng: 18.8213754 },
    { lat: 48.7195591, lng: 18.8211939 },
    { lat: 48.719584, lng: 18.8209767 },
    { lat: 48.7198619, lng: 18.8190173 },
    { lat: 48.7205374, lng: 18.8159639 },
    { lat: 48.7211834, lng: 18.8140381 },
    { lat: 48.7212586, lng: 18.8135763 },
    { lat: 48.7214117, lng: 18.8126424 },
    { lat: 48.7214359, lng: 18.8124995 },
    { lat: 48.7215275, lng: 18.8119447 },
    { lat: 48.721545, lng: 18.811879 },
    { lat: 48.721018, lng: 18.811694 },
    { lat: 48.72046, lng: 18.811614 },
    { lat: 48.719875, lng: 18.811503 },
    { lat: 48.719681, lng: 18.811456 },
    { lat: 48.719208, lng: 18.811222 },
    { lat: 48.718653, lng: 18.811132 },
    { lat: 48.718241, lng: 18.810773 },
    { lat: 48.717974, lng: 18.810706 },
    { lat: 48.717884, lng: 18.810805 },
    { lat: 48.717727, lng: 18.810824 },
    { lat: 48.717555, lng: 18.810708 },
    { lat: 48.717156, lng: 18.810149 },
    { lat: 48.71669, lng: 18.809318 },
    { lat: 48.716493, lng: 18.808845 },
    { lat: 48.716078, lng: 18.808212 },
    { lat: 48.715787, lng: 18.807995 },
    { lat: 48.715436, lng: 18.807703 },
    { lat: 48.715116, lng: 18.807616 },
    { lat: 48.714851, lng: 18.807486 },
    { lat: 48.714122, lng: 18.807118 },
    { lat: 48.713803, lng: 18.806958 },
    { lat: 48.713609, lng: 18.807022 },
    { lat: 48.713106, lng: 18.807052 },
    { lat: 48.712562, lng: 18.807038 },
    { lat: 48.712378, lng: 18.807027 },
    { lat: 48.712116, lng: 18.806912 },
    { lat: 48.71199, lng: 18.806719 },
    { lat: 48.711806, lng: 18.806625 },
    { lat: 48.711662, lng: 18.806459 },
    { lat: 48.711502, lng: 18.806055 },
    { lat: 48.711241, lng: 18.805629 },
    { lat: 48.710709, lng: 18.805391 },
    { lat: 48.710596, lng: 18.805176 },
    { lat: 48.710491, lng: 18.804836 },
    { lat: 48.71042, lng: 18.804351 },
    { lat: 48.710024, lng: 18.803061 },
    { lat: 48.709802, lng: 18.802503 },
    { lat: 48.709218, lng: 18.801737 },
    { lat: 48.709207, lng: 18.801753 },
    { lat: 48.7091858, lng: 18.8017809 },
    { lat: 48.7088705, lng: 18.8020059 },
    { lat: 48.7088588, lng: 18.8020634 },
    { lat: 48.7087795, lng: 18.8024449 },
    { lat: 48.7087629, lng: 18.8025161 },
    { lat: 48.7085784, lng: 18.8032994 },
    { lat: 48.7082425, lng: 18.8043592 },
    { lat: 48.7077134, lng: 18.8045936 },
    { lat: 48.7074265, lng: 18.8048424 },
    { lat: 48.7070473, lng: 18.8054871 },
    { lat: 48.705879, lng: 18.8069117 },
    { lat: 48.7056319, lng: 18.8070789 },
    { lat: 48.7051325, lng: 18.8076672 },
    { lat: 48.7047252, lng: 18.8077268 },
    { lat: 48.7043889, lng: 18.8084142 },
    { lat: 48.7037297, lng: 18.8087462 },
    { lat: 48.7027724, lng: 18.8083833 },
    { lat: 48.7024978, lng: 18.8083275 },
    { lat: 48.7016956, lng: 18.8082346 },
    { lat: 48.7011784, lng: 18.808565 },
    { lat: 48.7005117, lng: 18.8093613 },
    { lat: 48.7001565, lng: 18.80951 },
    { lat: 48.7000211, lng: 18.8095722 },
    { lat: 48.69979, lng: 18.8098128 },
    { lat: 48.6993768, lng: 18.8106017 },
    { lat: 48.6990177, lng: 18.8108301 },
    { lat: 48.6988997, lng: 18.8109052 },
    { lat: 48.6988082, lng: 18.8109635 },
    { lat: 48.698509, lng: 18.8111302 },
    { lat: 48.6983455, lng: 18.8111943 },
    { lat: 48.698074, lng: 18.8112202 },
    { lat: 48.696756, lng: 18.8111647 },
    { lat: 48.6957586, lng: 18.8107849 },
    { lat: 48.6952406, lng: 18.8103447 },
    { lat: 48.6938527, lng: 18.8107581 },
    { lat: 48.6930759, lng: 18.8112828 },
    { lat: 48.6928943, lng: 18.8113669 },
    { lat: 48.6928148, lng: 18.811442 },
    { lat: 48.6917871, lng: 18.8129268 },
    { lat: 48.6910863, lng: 18.8138018 },
    { lat: 48.6904978, lng: 18.8139579 },
    { lat: 48.689745, lng: 18.8141578 },
    { lat: 48.6890557, lng: 18.8143929 },
    { lat: 48.6888574, lng: 18.8144611 },
    { lat: 48.6887558, lng: 18.8144158 },
    { lat: 48.6884592, lng: 18.8142837 },
    { lat: 48.6884413, lng: 18.8142027 },
    { lat: 48.6884194, lng: 18.8141057 },
    { lat: 48.6882115, lng: 18.8140747 },
    { lat: 48.6879797, lng: 18.8140192 },
    { lat: 48.6876432, lng: 18.8139689 },
    { lat: 48.6874232, lng: 18.8139244 },
    { lat: 48.6863404, lng: 18.8136825 },
    { lat: 48.6861955, lng: 18.8134335 },
    { lat: 48.6860891, lng: 18.8132506 },
    { lat: 48.6854458, lng: 18.8128964 },
    { lat: 48.6847714, lng: 18.8128107 },
    { lat: 48.6842338, lng: 18.8130942 },
    { lat: 48.6840649, lng: 18.8132324 },
    { lat: 48.683885, lng: 18.8135208 },
    { lat: 48.6830423, lng: 18.8144197 },
    { lat: 48.682508, lng: 18.8146653 },
    { lat: 48.6824023, lng: 18.8147139 },
    { lat: 48.6822396, lng: 18.8145563 },
    { lat: 48.6817562, lng: 18.8142594 },
    { lat: 48.6803729, lng: 18.8136158 },
    { lat: 48.6796482, lng: 18.8136257 },
    { lat: 48.6794459, lng: 18.8135523 },
    { lat: 48.6783776, lng: 18.8137169 },
    { lat: 48.6778962, lng: 18.814237 },
    { lat: 48.6775657, lng: 18.8146456 },
    { lat: 48.6763019, lng: 18.8149123 },
    { lat: 48.6757218, lng: 18.8150345 },
    { lat: 48.6749558, lng: 18.8148312 },
    { lat: 48.6747361, lng: 18.814598 },
    { lat: 48.674236, lng: 18.8143913 },
    { lat: 48.6740591, lng: 18.8142447 },
    { lat: 48.6732917, lng: 18.8138661 },
    { lat: 48.673001, lng: 18.8138068 },
    { lat: 48.6724574, lng: 18.8135693 },
    { lat: 48.6718617, lng: 18.8136288 },
    { lat: 48.6718161, lng: 18.8145015 },
    { lat: 48.6712516, lng: 18.8144884 },
    { lat: 48.6712383, lng: 18.8145371 },
    { lat: 48.6712144, lng: 18.8146181 },
    { lat: 48.6711409, lng: 18.8148758 },
    { lat: 48.6710814, lng: 18.8151798 },
    { lat: 48.6710733, lng: 18.8152196 },
    { lat: 48.6710632, lng: 18.8152672 },
    { lat: 48.6708924, lng: 18.8153621 },
    { lat: 48.6707125, lng: 18.8154616 },
    { lat: 48.670547, lng: 18.8155534 },
    { lat: 48.6703892, lng: 18.8156406 },
    { lat: 48.6702773, lng: 18.8157024 },
    { lat: 48.6701623, lng: 18.8157664 },
    { lat: 48.6700417, lng: 18.8158332 },
    { lat: 48.6699223, lng: 18.8158995 },
    { lat: 48.6698302, lng: 18.8159508 },
    { lat: 48.669747, lng: 18.8159963 },
    { lat: 48.6696614, lng: 18.8160436 },
    { lat: 48.669587, lng: 18.8160853 },
    { lat: 48.6695146, lng: 18.8161253 },
    { lat: 48.6694466, lng: 18.8161626 },
    { lat: 48.6693557, lng: 18.8162131 },
    { lat: 48.6689957, lng: 18.815776 },
    { lat: 48.6689223, lng: 18.8156877 },
    { lat: 48.6689115, lng: 18.8156744 },
    { lat: 48.6685045, lng: 18.8151798 },
    { lat: 48.6684914, lng: 18.8151642 },
    { lat: 48.6680015, lng: 18.8146378 },
    { lat: 48.6679716, lng: 18.8146049 },
    { lat: 48.6678665, lng: 18.8144926 },
    { lat: 48.6678909, lng: 18.8143976 },
    { lat: 48.6677209, lng: 18.8144692 },
    { lat: 48.6676846, lng: 18.8144846 },
    { lat: 48.6676634, lng: 18.8144893 },
    { lat: 48.6674458, lng: 18.8145366 },
    { lat: 48.6672463, lng: 18.8145799 },
    { lat: 48.6662935, lng: 18.8155983 },
    { lat: 48.6662004, lng: 18.8158659 },
    { lat: 48.6662312, lng: 18.8159175 },
    { lat: 48.6662734, lng: 18.8159878 },
    { lat: 48.6664453, lng: 18.8162795 },
    { lat: 48.6666074, lng: 18.8163611 },
    { lat: 48.6671602, lng: 18.8165384 },
    { lat: 48.6672696, lng: 18.8168428 },
    { lat: 48.6671121, lng: 18.8174331 },
    { lat: 48.667222, lng: 18.8176605 },
    { lat: 48.6671707, lng: 18.817714 },
    { lat: 48.6665971, lng: 18.8181521 },
    { lat: 48.6662345, lng: 18.8180536 },
    { lat: 48.666069, lng: 18.8181243 },
    { lat: 48.6656027, lng: 18.8185907 },
    { lat: 48.6655427, lng: 18.8186035 },
    { lat: 48.6654433, lng: 18.8186246 },
    { lat: 48.6652035, lng: 18.8186753 },
    { lat: 48.6650306, lng: 18.8187119 },
    { lat: 48.664532, lng: 18.8188171 },
    { lat: 48.6644619, lng: 18.8188319 },
    { lat: 48.6643659, lng: 18.8188522 },
    { lat: 48.6638697, lng: 18.8189572 },
    { lat: 48.6632718, lng: 18.8190836 },
    { lat: 48.6631402, lng: 18.8191117 },
    { lat: 48.6625344, lng: 18.8192397 },
    { lat: 48.6622836, lng: 18.8192927 },
    { lat: 48.6621518, lng: 18.8193201 },
    { lat: 48.6613889, lng: 18.8194818 },
    { lat: 48.6613578, lng: 18.819488 },
    { lat: 48.6612893, lng: 18.8195821 },
    { lat: 48.661138, lng: 18.8197941 },
    { lat: 48.6610596, lng: 18.8199311 },
    { lat: 48.6610443, lng: 18.8199651 },
    { lat: 48.6609601, lng: 18.8201666 },
    { lat: 48.6609141, lng: 18.8202766 },
    { lat: 48.6608966, lng: 18.8203129 },
    { lat: 48.660843, lng: 18.8204281 },
    { lat: 48.6608064, lng: 18.8205045 },
    { lat: 48.6607852, lng: 18.8205341 },
    { lat: 48.6607306, lng: 18.8206109 },
    { lat: 48.6606739, lng: 18.8206918 },
    { lat: 48.6606078, lng: 18.8207838 },
    { lat: 48.6605469, lng: 18.8208703 },
    { lat: 48.6604596, lng: 18.8209919 },
    { lat: 48.660443, lng: 18.8209985 },
    { lat: 48.6603037, lng: 18.8210518 },
    { lat: 48.6601666, lng: 18.8211041 },
    { lat: 48.6600074, lng: 18.8211648 },
    { lat: 48.659845, lng: 18.8212269 },
    { lat: 48.6596846, lng: 18.8212873 },
    { lat: 48.6595199, lng: 18.8213504 },
    { lat: 48.6593552, lng: 18.8214131 },
    { lat: 48.6591937, lng: 18.8214748 },
    { lat: 48.6591561, lng: 18.8214889 },
    { lat: 48.6590156, lng: 18.8215409 },
    { lat: 48.6588031, lng: 18.82162 },
    { lat: 48.6587655, lng: 18.8216337 },
    { lat: 48.658708, lng: 18.8216552 },
    { lat: 48.658635, lng: 18.821682 },
    { lat: 48.658501, lng: 18.8217315 },
    { lat: 48.658156, lng: 18.8218591 },
    { lat: 48.6580851, lng: 18.8218861 },
    { lat: 48.6579956, lng: 18.8219188 },
    { lat: 48.6579071, lng: 18.8219515 },
    { lat: 48.6578197, lng: 18.821984 },
    { lat: 48.6576725, lng: 18.8220386 },
    { lat: 48.6574833, lng: 18.8221085 },
    { lat: 48.6572797, lng: 18.8221837 },
    { lat: 48.6571991, lng: 18.8222139 },
    { lat: 48.6564656, lng: 18.8224858 },
    { lat: 48.656413, lng: 18.8225156 },
    { lat: 48.656321, lng: 18.8225686 },
    { lat: 48.6562947, lng: 18.8225839 },
    { lat: 48.6562697, lng: 18.8225983 },
    { lat: 48.6562224, lng: 18.8226253 },
    { lat: 48.6561905, lng: 18.822639 },
    { lat: 48.6560624, lng: 18.8226938 },
    { lat: 48.6557724, lng: 18.8228196 },
    { lat: 48.6556654, lng: 18.8228658 },
    { lat: 48.6552871, lng: 18.82292 },
    { lat: 48.6551619, lng: 18.8229385 },
    { lat: 48.6551373, lng: 18.8229418 },
    { lat: 48.6550127, lng: 18.8229779 },
    { lat: 48.6547017, lng: 18.823067 },
    { lat: 48.6546807, lng: 18.823073 },
    { lat: 48.6545596, lng: 18.8231751 },
    { lat: 48.6545066, lng: 18.8232196 },
    { lat: 48.6543772, lng: 18.8233284 },
    { lat: 48.6536531, lng: 18.8237629 },
    { lat: 48.6532816, lng: 18.8237528 },
    { lat: 48.652654, lng: 18.8238704 },
    { lat: 48.652699, lng: 18.8239546 },
    { lat: 48.6524144, lng: 18.824294 },
    { lat: 48.6520815, lng: 18.8243082 },
    { lat: 48.6519284, lng: 18.8243806 },
    { lat: 48.6516622, lng: 18.8245057 },
    { lat: 48.6511196, lng: 18.8247616 },
    { lat: 48.6510524, lng: 18.8247931 },
    { lat: 48.6512109, lng: 18.8258431 },
    { lat: 48.6513946, lng: 18.8265974 },
    { lat: 48.6518892, lng: 18.8272602 },
    { lat: 48.6522558, lng: 18.8279791 },
    { lat: 48.6526418, lng: 18.829152 },
    { lat: 48.6526471, lng: 18.8299477 },
    { lat: 48.6526934, lng: 18.8302964 },
    { lat: 48.6529459, lng: 18.8304911 },
    { lat: 48.6523387, lng: 18.8311281 },
    { lat: 48.6521098, lng: 18.8314958 },
    { lat: 48.6519532, lng: 18.8318022 },
    { lat: 48.6519405, lng: 18.8321033 },
    { lat: 48.6519761, lng: 18.8323508 },
    { lat: 48.6520912, lng: 18.8326781 },
    { lat: 48.6522132, lng: 18.832857 },
    { lat: 48.6522757, lng: 18.8328859 },
    { lat: 48.6524813, lng: 18.8329817 },
    { lat: 48.6530065, lng: 18.8337116 },
    { lat: 48.6530758, lng: 18.8339163 },
    { lat: 48.6531706, lng: 18.8343722 },
    { lat: 48.6533699, lng: 18.834737 },
    { lat: 48.6537408, lng: 18.8349705 },
    { lat: 48.6539258, lng: 18.8354293 },
    { lat: 48.6539357, lng: 18.8354881 },
    { lat: 48.6539604, lng: 18.8355954 },
    { lat: 48.6541122, lng: 18.8359775 },
    { lat: 48.6545745, lng: 18.8363254 },
    { lat: 48.6548199, lng: 18.8364682 },
    { lat: 48.6550759, lng: 18.8365742 },
    { lat: 48.6551069, lng: 18.8367798 },
    { lat: 48.6554904, lng: 18.837131 },
    { lat: 48.6555365, lng: 18.8371723 },
    { lat: 48.6557858, lng: 18.8373663 },
    { lat: 48.6559657, lng: 18.8375095 },
    { lat: 48.6561163, lng: 18.8376296 },
    { lat: 48.6562738, lng: 18.8377547 },
    { lat: 48.6564245, lng: 18.8378749 },
    { lat: 48.6565773, lng: 18.837997 },
    { lat: 48.6567233, lng: 18.8381134 },
    { lat: 48.6567281, lng: 18.8381193 },
    { lat: 48.6570025, lng: 18.8384944 },
    { lat: 48.6572499, lng: 18.8396764 },
    { lat: 48.6573013, lng: 18.8397334 },
    { lat: 48.6573362, lng: 18.8397721 },
    { lat: 48.657676, lng: 18.8401495 },
    { lat: 48.6577416, lng: 18.8402209 },
    { lat: 48.6580971, lng: 18.8415046 },
    { lat: 48.6585451, lng: 18.8435282 },
    { lat: 48.6588309, lng: 18.8444724 },
    { lat: 48.6591012, lng: 18.8458143 },
    { lat: 48.6596069, lng: 18.8463006 },
    { lat: 48.6599673, lng: 18.8467252 },
    { lat: 48.6601467, lng: 18.847401 },
    { lat: 48.6603103, lng: 18.8487731 },
    { lat: 48.6604299, lng: 18.8492957 },
    { lat: 48.6605091, lng: 18.8495164 },
    { lat: 48.6608238, lng: 18.850107 },
  ],
  Prestavlky: [
    { lat: 48.5735822, lng: 18.7840619 },
    { lat: 48.5736995, lng: 18.7840821 },
    { lat: 48.5737415, lng: 18.7840899 },
    { lat: 48.5738667, lng: 18.7841109 },
    { lat: 48.5742106, lng: 18.783983 },
    { lat: 48.5742484, lng: 18.7839688 },
    { lat: 48.5743237, lng: 18.7837921 },
    { lat: 48.5743788, lng: 18.783662 },
    { lat: 48.5745105, lng: 18.7836363 },
    { lat: 48.5746813, lng: 18.7836038 },
    { lat: 48.5747641, lng: 18.7835879 },
    { lat: 48.5747931, lng: 18.7835823 },
    { lat: 48.5748444, lng: 18.7835729 },
    { lat: 48.5752391, lng: 18.7836786 },
    { lat: 48.5753156, lng: 18.7836987 },
    { lat: 48.5754205, lng: 18.7836527 },
    { lat: 48.5756826, lng: 18.7835366 },
    { lat: 48.5757433, lng: 18.7832527 },
    { lat: 48.5757658, lng: 18.783146 },
    { lat: 48.5757887, lng: 18.7830429 },
    { lat: 48.5758818, lng: 18.7830556 },
    { lat: 48.5759625, lng: 18.7830673 },
    { lat: 48.5759773, lng: 18.7830691 },
    { lat: 48.5760489, lng: 18.7830791 },
    { lat: 48.5762089, lng: 18.783101 },
    { lat: 48.5762624, lng: 18.7831081 },
    { lat: 48.5763816, lng: 18.783038 },
    { lat: 48.5764965, lng: 18.7829708 },
    { lat: 48.5764403, lng: 18.7846723 },
    { lat: 48.5765434, lng: 18.7851319 },
    { lat: 48.5764512, lng: 18.7852661 },
    { lat: 48.5764546, lng: 18.785651 },
    { lat: 48.5764968, lng: 18.7858101 },
    { lat: 48.5766952, lng: 18.7865603 },
    { lat: 48.5767063, lng: 18.7865996 },
    { lat: 48.5767288, lng: 18.7866642 },
    { lat: 48.5768217, lng: 18.7874241 },
    { lat: 48.5768396, lng: 18.7875719 },
    { lat: 48.5770243, lng: 18.7890691 },
    { lat: 48.576893, lng: 18.7903266 },
    { lat: 48.5768969, lng: 18.7904639 },
    { lat: 48.5768981, lng: 18.7905083 },
    { lat: 48.5769006, lng: 18.7905796 },
    { lat: 48.5769029, lng: 18.7906676 },
    { lat: 48.5769059, lng: 18.7907656 },
    { lat: 48.5769093, lng: 18.7908521 },
    { lat: 48.5769465, lng: 18.7920752 },
    { lat: 48.5774868, lng: 18.7924645 },
    { lat: 48.5778059, lng: 18.7927213 },
    { lat: 48.5779385, lng: 18.7924975 },
    { lat: 48.5781684, lng: 18.7923266 },
    { lat: 48.5782683, lng: 18.7923784 },
    { lat: 48.5783738, lng: 18.7925657 },
    { lat: 48.578592, lng: 18.7927697 },
    { lat: 48.5789092, lng: 18.7932668 },
    { lat: 48.5790625, lng: 18.7933511 },
    { lat: 48.5791288, lng: 18.7937991 },
    { lat: 48.5792668, lng: 18.7939568 },
    { lat: 48.5794452, lng: 18.7939832 },
    { lat: 48.5795437, lng: 18.7937525 },
    { lat: 48.5796635, lng: 18.7941275 },
    { lat: 48.5796932, lng: 18.7942197 },
    { lat: 48.5801883, lng: 18.7943698 },
    { lat: 48.580549, lng: 18.7939561 },
    { lat: 48.5806733, lng: 18.7938123 },
    { lat: 48.5807022, lng: 18.7939357 },
    { lat: 48.5808137, lng: 18.7944245 },
    { lat: 48.581021, lng: 18.7944454 },
    { lat: 48.5814973, lng: 18.7944927 },
    { lat: 48.5816156, lng: 18.7952657 },
    { lat: 48.5822992, lng: 18.795228 },
    { lat: 48.5822761, lng: 18.7944864 },
    { lat: 48.5822669, lng: 18.7941577 },
    { lat: 48.5824134, lng: 18.7940099 },
    { lat: 48.5824929, lng: 18.7939303 },
    { lat: 48.5828666, lng: 18.7935541 },
    { lat: 48.5829846, lng: 18.7936309 },
    { lat: 48.583057, lng: 18.7936785 },
    { lat: 48.5831037, lng: 18.7937086 },
    { lat: 48.5832835, lng: 18.7938254 },
    { lat: 48.583413, lng: 18.7939093 },
    { lat: 48.5836042, lng: 18.7932985 },
    { lat: 48.5837764, lng: 18.7934411 },
    { lat: 48.5840995, lng: 18.7937071 },
    { lat: 48.5842586, lng: 18.7938388 },
    { lat: 48.5842463, lng: 18.794163 },
    { lat: 48.5842417, lng: 18.7942931 },
    { lat: 48.5842397, lng: 18.7943404 },
    { lat: 48.5842851, lng: 18.794367 },
    { lat: 48.5847436, lng: 18.7946397 },
    { lat: 48.584796, lng: 18.7946698 },
    { lat: 48.5849938, lng: 18.7947869 },
    { lat: 48.5852894, lng: 18.7949626 },
    { lat: 48.5853202, lng: 18.7949447 },
    { lat: 48.585399, lng: 18.7948982 },
    { lat: 48.58547, lng: 18.7948556 },
    { lat: 48.5856539, lng: 18.7947471 },
    { lat: 48.5857515, lng: 18.7944188 },
    { lat: 48.5857639, lng: 18.7943757 },
    { lat: 48.5860409, lng: 18.7943858 },
    { lat: 48.5861978, lng: 18.7941737 },
    { lat: 48.5861963, lng: 18.7941044 },
    { lat: 48.5861947, lng: 18.793966 },
    { lat: 48.5861869, lng: 18.7935157 },
    { lat: 48.5863543, lng: 18.7932478 },
    { lat: 48.58663, lng: 18.7932559 },
    { lat: 48.5867079, lng: 18.7932584 },
    { lat: 48.5869015, lng: 18.7932495 },
    { lat: 48.5870801, lng: 18.7931723 },
    { lat: 48.5874838, lng: 18.7934943 },
    { lat: 48.5878549, lng: 18.7936271 },
    { lat: 48.5880416, lng: 18.793618 },
    { lat: 48.5880496, lng: 18.7936178 },
    { lat: 48.588117, lng: 18.7936144 },
    { lat: 48.5881788, lng: 18.7936113 },
    { lat: 48.5882575, lng: 18.7936072 },
    { lat: 48.5884366, lng: 18.7935161 },
    { lat: 48.5890016, lng: 18.7930655 },
    { lat: 48.5890265, lng: 18.793045 },
    { lat: 48.5890435, lng: 18.7927253 },
    { lat: 48.5890503, lng: 18.7926151 },
    { lat: 48.5893801, lng: 18.7921768 },
    { lat: 48.5894466, lng: 18.7920891 },
    { lat: 48.5895908, lng: 18.7919152 },
    { lat: 48.5895984, lng: 18.791821 },
    { lat: 48.5896051, lng: 18.7917361 },
    { lat: 48.5897914, lng: 18.7915258 },
    { lat: 48.5900066, lng: 18.7914987 },
    { lat: 48.5900938, lng: 18.7910573 },
    { lat: 48.5902756, lng: 18.790693 },
    { lat: 48.5905095, lng: 18.790701 },
    { lat: 48.5907527, lng: 18.7909545 },
    { lat: 48.5910864, lng: 18.7910597 },
    { lat: 48.5912036, lng: 18.7909506 },
    { lat: 48.5913262, lng: 18.7908366 },
    { lat: 48.5913475, lng: 18.7906848 },
    { lat: 48.5916716, lng: 18.7904805 },
    { lat: 48.5920299, lng: 18.7905575 },
    { lat: 48.592217, lng: 18.7904928 },
    { lat: 48.5923494, lng: 18.7905447 },
    { lat: 48.5924165, lng: 18.7904676 },
    { lat: 48.5924023, lng: 18.790196 },
    { lat: 48.5924647, lng: 18.790035 },
    { lat: 48.5925331, lng: 18.7900055 },
    { lat: 48.5926077, lng: 18.790116 },
    { lat: 48.5925764, lng: 18.79029 },
    { lat: 48.5926868, lng: 18.7904168 },
    { lat: 48.5928646, lng: 18.7903913 },
    { lat: 48.5928811, lng: 18.7901891 },
    { lat: 48.5928833, lng: 18.7901679 },
    { lat: 48.5928942, lng: 18.7901584 },
    { lat: 48.5930016, lng: 18.7900583 },
    { lat: 48.5930709, lng: 18.7899139 },
    { lat: 48.5930785, lng: 18.7898471 },
    { lat: 48.5930551, lng: 18.7896811 },
    { lat: 48.5931476, lng: 18.789571 },
    { lat: 48.5931227, lng: 18.7894211 },
    { lat: 48.5931943, lng: 18.7892804 },
    { lat: 48.5932766, lng: 18.7885927 },
    { lat: 48.5933738, lng: 18.7883606 },
    { lat: 48.5935014, lng: 18.7879332 },
    { lat: 48.5933909, lng: 18.7878495 },
    { lat: 48.5934454, lng: 18.787662 },
    { lat: 48.5937178, lng: 18.7874585 },
    { lat: 48.5939546, lng: 18.7871182 },
    { lat: 48.5940972, lng: 18.7872312 },
    { lat: 48.5941724, lng: 18.7871827 },
    { lat: 48.5942854, lng: 18.7867339 },
    { lat: 48.5942688, lng: 18.7865941 },
    { lat: 48.5942585, lng: 18.7865675 },
    { lat: 48.594234, lng: 18.786506 },
    { lat: 48.5941458, lng: 18.7862888 },
    { lat: 48.5940269, lng: 18.7860633 },
    { lat: 48.5938563, lng: 18.785975 },
    { lat: 48.5939486, lng: 18.7858197 },
    { lat: 48.59424, lng: 18.7856766 },
    { lat: 48.5943113, lng: 18.7855751 },
    { lat: 48.5942621, lng: 18.7852821 },
    { lat: 48.5944189, lng: 18.7851134 },
    { lat: 48.5944234, lng: 18.785006 },
    { lat: 48.5943308, lng: 18.7846376 },
    { lat: 48.5943652, lng: 18.7844112 },
    { lat: 48.5945965, lng: 18.7845639 },
    { lat: 48.5946406, lng: 18.7845258 },
    { lat: 48.5946647, lng: 18.7843178 },
    { lat: 48.5947534, lng: 18.7842032 },
    { lat: 48.5947583, lng: 18.7839311 },
    { lat: 48.5948432, lng: 18.7837671 },
    { lat: 48.594769, lng: 18.7836406 },
    { lat: 48.594789, lng: 18.7835035 },
    { lat: 48.5949007, lng: 18.7835297 },
    { lat: 48.5949993, lng: 18.7833241 },
    { lat: 48.5950424, lng: 18.7831551 },
    { lat: 48.595014, lng: 18.7829159 },
    { lat: 48.595139, lng: 18.7825476 },
    { lat: 48.5951722, lng: 18.7824496 },
    { lat: 48.5951915, lng: 18.7821526 },
    { lat: 48.5951618, lng: 18.7817582 },
    { lat: 48.5949874, lng: 18.7816579 },
    { lat: 48.5950684, lng: 18.7813568 },
    { lat: 48.5950341, lng: 18.7806182 },
    { lat: 48.59554, lng: 18.780438 },
    { lat: 48.5956046, lng: 18.7799942 },
    { lat: 48.5954926, lng: 18.7797928 },
    { lat: 48.5955762, lng: 18.7796243 },
    { lat: 48.5956901, lng: 18.7794543 },
    { lat: 48.5956986, lng: 18.779247 },
    { lat: 48.5958754, lng: 18.7789879 },
    { lat: 48.5959401, lng: 18.778739 },
    { lat: 48.5958784, lng: 18.7784859 },
    { lat: 48.595953, lng: 18.7782736 },
    { lat: 48.5959948, lng: 18.7780406 },
    { lat: 48.5958825, lng: 18.777598 },
    { lat: 48.5959843, lng: 18.7775623 },
    { lat: 48.596173, lng: 18.7774197 },
    { lat: 48.5962537, lng: 18.7770905 },
    { lat: 48.5961208, lng: 18.7768146 },
    { lat: 48.5964911, lng: 18.7767333 },
    { lat: 48.5966153, lng: 18.776525 },
    { lat: 48.5965349, lng: 18.7762367 },
    { lat: 48.5963711, lng: 18.7760379 },
    { lat: 48.5965834, lng: 18.7756805 },
    { lat: 48.5966134, lng: 18.7754371 },
    { lat: 48.5965423, lng: 18.7752397 },
    { lat: 48.5966108, lng: 18.774592 },
    { lat: 48.5964671, lng: 18.7743716 },
    { lat: 48.5965422, lng: 18.7741478 },
    { lat: 48.596536, lng: 18.7739437 },
    { lat: 48.5966477, lng: 18.773816 },
    { lat: 48.5967063, lng: 18.773732 },
    { lat: 48.596764, lng: 18.7735388 },
    { lat: 48.5969397, lng: 18.7734729 },
    { lat: 48.5970642, lng: 18.7732431 },
    { lat: 48.5970811, lng: 18.7729461 },
    { lat: 48.5968658, lng: 18.7726035 },
    { lat: 48.5970468, lng: 18.7725049 },
    { lat: 48.5973285, lng: 18.7720301 },
    { lat: 48.5974687, lng: 18.771972 },
    { lat: 48.5976655, lng: 18.7716442 },
    { lat: 48.5978495, lng: 18.7713454 },
    { lat: 48.5979104, lng: 18.7710025 },
    { lat: 48.5979314, lng: 18.7705229 },
    { lat: 48.5980204, lng: 18.7703491 },
    { lat: 48.598052, lng: 18.7702865 },
    { lat: 48.5980664, lng: 18.7702581 },
    { lat: 48.5980675, lng: 18.7702159 },
    { lat: 48.5980671, lng: 18.7701472 },
    { lat: 48.5980688, lng: 18.7699628 },
    { lat: 48.598201, lng: 18.7697086 },
    { lat: 48.5981391, lng: 18.7693501 },
    { lat: 48.5982056, lng: 18.7685587 },
    { lat: 48.5980413, lng: 18.7680896 },
    { lat: 48.5979088, lng: 18.7678895 },
    { lat: 48.5977615, lng: 18.7678965 },
    { lat: 48.5977704, lng: 18.7674657 },
    { lat: 48.5978205, lng: 18.7672403 },
    { lat: 48.5978866, lng: 18.766953 },
    { lat: 48.5978336, lng: 18.7667809 },
    { lat: 48.5979496, lng: 18.7664805 },
    { lat: 48.5979506, lng: 18.7663514 },
    { lat: 48.5980354, lng: 18.7662248 },
    { lat: 48.5980571, lng: 18.7659104 },
    { lat: 48.5980127, lng: 18.7656225 },
    { lat: 48.5980478, lng: 18.7655179 },
    { lat: 48.5982042, lng: 18.7653605 },
    { lat: 48.5981469, lng: 18.7650436 },
    { lat: 48.5982771, lng: 18.7647565 },
    { lat: 48.5982042, lng: 18.7645268 },
    { lat: 48.5984241, lng: 18.7643363 },
    { lat: 48.5984506, lng: 18.7637659 },
    { lat: 48.5985885, lng: 18.7636671 },
    { lat: 48.598801, lng: 18.7636306 },
    { lat: 48.5989907, lng: 18.7634019 },
    { lat: 48.5991889, lng: 18.7630945 },
    { lat: 48.5991974, lng: 18.7628758 },
    { lat: 48.5994268, lng: 18.7627122 },
    { lat: 48.5994609, lng: 18.7625218 },
    { lat: 48.5995208, lng: 18.7623529 },
    { lat: 48.5997125, lng: 18.7620132 },
    { lat: 48.599742, lng: 18.7614802 },
    { lat: 48.5998263, lng: 18.7613881 },
    { lat: 48.5998521, lng: 18.761152 },
    { lat: 48.599839, lng: 18.7608814 },
    { lat: 48.6000931, lng: 18.7605912 },
    { lat: 48.6001086, lng: 18.7603912 },
    { lat: 48.6002671, lng: 18.7602336 },
    { lat: 48.6002919, lng: 18.7599966 },
    { lat: 48.6003738, lng: 18.7595193 },
    { lat: 48.6004916, lng: 18.7592518 },
    { lat: 48.6004886, lng: 18.7588097 },
    { lat: 48.6005883, lng: 18.7586477 },
    { lat: 48.6012098, lng: 18.7582863 },
    { lat: 48.6012675, lng: 18.7579684 },
    { lat: 48.6014865, lng: 18.7576945 },
    { lat: 48.6014981, lng: 18.7575506 },
    { lat: 48.6017387, lng: 18.7573453 },
    { lat: 48.6017195, lng: 18.7572582 },
    { lat: 48.6017989, lng: 18.7571158 },
    { lat: 48.6018478, lng: 18.7570611 },
    { lat: 48.6021774, lng: 18.7566838 },
    { lat: 48.6022156, lng: 18.7564668 },
    { lat: 48.6023034, lng: 18.7560989 },
    { lat: 48.6023611, lng: 18.7557806 },
    { lat: 48.602471, lng: 18.7554724 },
    { lat: 48.6025437, lng: 18.7550938 },
    { lat: 48.6025563, lng: 18.7550374 },
    { lat: 48.6025783, lng: 18.7549407 },
    { lat: 48.6026319, lng: 18.7547825 },
    { lat: 48.6027518, lng: 18.7545105 },
    { lat: 48.6029296, lng: 18.7542476 },
    { lat: 48.6030919, lng: 18.7540317 },
    { lat: 48.6032225, lng: 18.753897 },
    { lat: 48.6033563, lng: 18.7537859 },
    { lat: 48.6039218, lng: 18.7535395 },
    { lat: 48.6040796, lng: 18.7533456 },
    { lat: 48.6042037, lng: 18.7531539 },
    { lat: 48.6042617, lng: 18.7530533 },
    { lat: 48.6046377, lng: 18.7523999 },
    { lat: 48.6047924, lng: 18.7522331 },
    { lat: 48.6050932, lng: 18.7519938 },
    { lat: 48.6053211, lng: 18.751737 },
    { lat: 48.6053859, lng: 18.7516433 },
    { lat: 48.6055073, lng: 18.7513786 },
    { lat: 48.6056737, lng: 18.7508548 },
    { lat: 48.6058167, lng: 18.7504887 },
    { lat: 48.6059098, lng: 18.7503945 },
    { lat: 48.6059496, lng: 18.750187 },
    { lat: 48.6059876, lng: 18.7500734 },
    { lat: 48.606107, lng: 18.7499222 },
    { lat: 48.6062335, lng: 18.7496248 },
    { lat: 48.6063871, lng: 18.7494581 },
    { lat: 48.606649, lng: 18.7489955 },
    { lat: 48.6068319, lng: 18.7486143 },
    { lat: 48.6069113, lng: 18.7484537 },
    { lat: 48.6069702, lng: 18.7483066 },
    { lat: 48.6069831, lng: 18.7482729 },
    { lat: 48.6070153, lng: 18.7481814 },
    { lat: 48.607021, lng: 18.7481187 },
    { lat: 48.6070277, lng: 18.7480534 },
    { lat: 48.6070317, lng: 18.7479998 },
    { lat: 48.6070696, lng: 18.7475627 },
    { lat: 48.6071335, lng: 18.7472774 },
    { lat: 48.6072215, lng: 18.7470245 },
    { lat: 48.6073331, lng: 18.7468511 },
    { lat: 48.6074401, lng: 18.7467217 },
    { lat: 48.6077096, lng: 18.74653 },
    { lat: 48.6078073, lng: 18.7464157 },
    { lat: 48.6080018, lng: 18.7461247 },
    { lat: 48.6081325, lng: 18.7458659 },
    { lat: 48.608256, lng: 18.7456857 },
    { lat: 48.6083537, lng: 18.7454858 },
    { lat: 48.6084625, lng: 18.7452832 },
    { lat: 48.6086152, lng: 18.7450563 },
    { lat: 48.6087479, lng: 18.7449246 },
    { lat: 48.6088538, lng: 18.7448566 },
    { lat: 48.6090588, lng: 18.7447254 },
    { lat: 48.6092703, lng: 18.7445646 },
    { lat: 48.6093126, lng: 18.744532 },
    { lat: 48.6095285, lng: 18.7443479 },
    { lat: 48.6095722, lng: 18.7442179 },
    { lat: 48.6096138, lng: 18.7441053 },
    { lat: 48.6096826, lng: 18.7439587 },
    { lat: 48.6098998, lng: 18.7437138 },
    { lat: 48.6099768, lng: 18.7435916 },
    { lat: 48.6100919, lng: 18.7433821 },
    { lat: 48.6101433, lng: 18.7432415 },
    { lat: 48.6101999, lng: 18.7430348 },
    { lat: 48.6103459, lng: 18.7425764 },
    { lat: 48.610387, lng: 18.7423943 },
    { lat: 48.6104259, lng: 18.7421064 },
    { lat: 48.610427, lng: 18.7420417 },
    { lat: 48.6104312, lng: 18.7418228 },
    { lat: 48.6104573, lng: 18.7415014 },
    { lat: 48.6104898, lng: 18.7412017 },
    { lat: 48.6105207, lng: 18.7409136 },
    { lat: 48.6105256, lng: 18.740599 },
    { lat: 48.610481, lng: 18.7402651 },
    { lat: 48.6104509, lng: 18.739979 },
    { lat: 48.610431, lng: 18.7396432 },
    { lat: 48.6104464, lng: 18.7394863 },
    { lat: 48.6105686, lng: 18.7390675 },
    { lat: 48.6107941, lng: 18.7380239 },
    { lat: 48.6109741, lng: 18.7372047 },
    { lat: 48.6110569, lng: 18.7368943 },
    { lat: 48.6111849, lng: 18.7364548 },
    { lat: 48.6112984, lng: 18.7362148 },
    { lat: 48.6117267, lng: 18.7356141 },
    { lat: 48.6121478, lng: 18.7351753 },
    { lat: 48.6122826, lng: 18.735035 },
    { lat: 48.6125373, lng: 18.7344177 },
    { lat: 48.6127272, lng: 18.7339561 },
    { lat: 48.6131518, lng: 18.7333929 },
    { lat: 48.6133171, lng: 18.7325065 },
    { lat: 48.6133166, lng: 18.7318818 },
    { lat: 48.6132283, lng: 18.7311305 },
    { lat: 48.6131966, lng: 18.7306172 },
    { lat: 48.6132556, lng: 18.7302619 },
    { lat: 48.6133115, lng: 18.7301051 },
    { lat: 48.613351, lng: 18.7299977 },
    { lat: 48.613403, lng: 18.7299351 },
    { lat: 48.6135671, lng: 18.7297371 },
    { lat: 48.6140005, lng: 18.7294636 },
    { lat: 48.6143946, lng: 18.7293017 },
    { lat: 48.6147451, lng: 18.7292362 },
    { lat: 48.6147909, lng: 18.7290172 },
    { lat: 48.6148081, lng: 18.7289346 },
    { lat: 48.6148539, lng: 18.7287156 },
    { lat: 48.6148534, lng: 18.7283863 },
    { lat: 48.6151896, lng: 18.7277499 },
    { lat: 48.6155344, lng: 18.7272346 },
    { lat: 48.6157134, lng: 18.7267818 },
    { lat: 48.6158484, lng: 18.7261803 },
    { lat: 48.6158899, lng: 18.7257276 },
    { lat: 48.6159937, lng: 18.7252621 },
    { lat: 48.6161312, lng: 18.7250466 },
    { lat: 48.6162541, lng: 18.7242184 },
    { lat: 48.6164254, lng: 18.724021 },
    { lat: 48.6166451, lng: 18.7236598 },
    { lat: 48.6166702, lng: 18.7236197 },
    { lat: 48.616978, lng: 18.7232343 },
    { lat: 48.6169435, lng: 18.7229266 },
    { lat: 48.6163867, lng: 18.7228683 },
    { lat: 48.6158484, lng: 18.7229454 },
    { lat: 48.6155943, lng: 18.7229172 },
    { lat: 48.6155579, lng: 18.7229131 },
    { lat: 48.6149736, lng: 18.7228492 },
    { lat: 48.6146128, lng: 18.7226988 },
    { lat: 48.613671, lng: 18.7228895 },
    { lat: 48.6134061, lng: 18.7229792 },
    { lat: 48.61275, lng: 18.7231966 },
    { lat: 48.6122432, lng: 18.7233148 },
    { lat: 48.6113203, lng: 18.7236923 },
    { lat: 48.6110629, lng: 18.7238111 },
    { lat: 48.6101255, lng: 18.7237634 },
    { lat: 48.6083914, lng: 18.7251158 },
    { lat: 48.6078849, lng: 18.7254702 },
    { lat: 48.6074999, lng: 18.7253356 },
    { lat: 48.6073034, lng: 18.725267 },
    { lat: 48.6066759, lng: 18.7250472 },
    { lat: 48.6059078, lng: 18.7246634 },
    { lat: 48.6052763, lng: 18.7244719 },
    { lat: 48.6044907, lng: 18.7243949 },
    { lat: 48.604207, lng: 18.7242991 },
    { lat: 48.6036185, lng: 18.7243647 },
    { lat: 48.6032307, lng: 18.7242404 },
    { lat: 48.6018815, lng: 18.7250638 },
    { lat: 48.6016865, lng: 18.7254468 },
    { lat: 48.6008485, lng: 18.7264664 },
    { lat: 48.6006698, lng: 18.7271789 },
    { lat: 48.5998031, lng: 18.7263395 },
    { lat: 48.5993026, lng: 18.7258137 },
    { lat: 48.5991331, lng: 18.725636 },
    { lat: 48.5983735, lng: 18.7247959 },
    { lat: 48.5983531, lng: 18.7247727 },
    { lat: 48.5982525, lng: 18.7246623 },
    { lat: 48.597607, lng: 18.7239755 },
    { lat: 48.5971882, lng: 18.7234973 },
    { lat: 48.5967299, lng: 18.7236616 },
    { lat: 48.5967581, lng: 18.7239168 },
    { lat: 48.5966656, lng: 18.7249573 },
    { lat: 48.5964033, lng: 18.7260485 },
    { lat: 48.596393, lng: 18.7260914 },
    { lat: 48.596369, lng: 18.7261912 },
    { lat: 48.5961893, lng: 18.7269921 },
    { lat: 48.5960314, lng: 18.7275651 },
    { lat: 48.5959939, lng: 18.7277328 },
    { lat: 48.5959823, lng: 18.7277887 },
    { lat: 48.5958975, lng: 18.7281684 },
    { lat: 48.5956627, lng: 18.7291615 },
    { lat: 48.594908, lng: 18.7288381 },
    { lat: 48.5948295, lng: 18.7288273 },
    { lat: 48.5937669, lng: 18.7286749 },
    { lat: 48.5930165, lng: 18.7286239 },
    { lat: 48.5922454, lng: 18.7286064 },
    { lat: 48.5919656, lng: 18.7286019 },
    { lat: 48.5917904, lng: 18.727746 },
    { lat: 48.5913296, lng: 18.7278165 },
    { lat: 48.5902947, lng: 18.7273184 },
    { lat: 48.5899474, lng: 18.7275057 },
    { lat: 48.5898833, lng: 18.7276398 },
    { lat: 48.5895936, lng: 18.7282365 },
    { lat: 48.5888593, lng: 18.7280443 },
    { lat: 48.588356, lng: 18.7279293 },
    { lat: 48.5877943, lng: 18.727654 },
    { lat: 48.587612, lng: 18.7285743 },
    { lat: 48.587571, lng: 18.7290983 },
    { lat: 48.5874858, lng: 18.7298537 },
    { lat: 48.5870142, lng: 18.7302981 },
    { lat: 48.5867505, lng: 18.7305119 },
    { lat: 48.5866287, lng: 18.7306217 },
    { lat: 48.5863146, lng: 18.7309064 },
    { lat: 48.586056, lng: 18.7309639 },
    { lat: 48.585933, lng: 18.7311123 },
    { lat: 48.5857342, lng: 18.7314244 },
    { lat: 48.5855809, lng: 18.7319166 },
    { lat: 48.5854652, lng: 18.7322874 },
    { lat: 48.5853249, lng: 18.7322765 },
    { lat: 48.585274, lng: 18.7322524 },
    { lat: 48.5852449, lng: 18.7322384 },
    { lat: 48.584702, lng: 18.7319743 },
    { lat: 48.5842353, lng: 18.7311671 },
    { lat: 48.5839747, lng: 18.7308259 },
    { lat: 48.5837229, lng: 18.7305862 },
    { lat: 48.5835411, lng: 18.7303492 },
    { lat: 48.5834789, lng: 18.7302377 },
    { lat: 48.5831893, lng: 18.7300076 },
    { lat: 48.5830391, lng: 18.729751 },
    { lat: 48.5829164, lng: 18.7292654 },
    { lat: 48.5827756, lng: 18.7289268 },
    { lat: 48.5826347, lng: 18.7286546 },
    { lat: 48.5823953, lng: 18.7283508 },
    { lat: 48.5821993, lng: 18.7287142 },
    { lat: 48.5821362, lng: 18.7288432 },
    { lat: 48.5816876, lng: 18.7297211 },
    { lat: 48.5816671, lng: 18.7297799 },
    { lat: 48.5814022, lng: 18.7303147 },
    { lat: 48.581385, lng: 18.730351 },
    { lat: 48.5811421, lng: 18.7308553 },
    { lat: 48.5807639, lng: 18.7316132 },
    { lat: 48.5806987, lng: 18.7317429 },
    { lat: 48.5802122, lng: 18.7327154 },
    { lat: 48.5800552, lng: 18.7330313 },
    { lat: 48.5800523, lng: 18.7330384 },
    { lat: 48.5800149, lng: 18.7331227 },
    { lat: 48.5799626, lng: 18.7332409 },
    { lat: 48.5799241, lng: 18.7333217 },
    { lat: 48.5798875, lng: 18.7333977 },
    { lat: 48.5798052, lng: 18.7335672 },
    { lat: 48.5797339, lng: 18.7337546 },
    { lat: 48.579693, lng: 18.733898 },
    { lat: 48.5795964, lng: 18.7342248 },
    { lat: 48.5794823, lng: 18.7346036 },
    { lat: 48.5794101, lng: 18.7347933 },
    { lat: 48.5792726, lng: 18.7351831 },
    { lat: 48.5790248, lng: 18.735911 },
    { lat: 48.578911, lng: 18.7362306 },
    { lat: 48.5788024, lng: 18.7364819 },
    { lat: 48.5786195, lng: 18.7369456 },
    { lat: 48.5785434, lng: 18.7371463 },
    { lat: 48.5784366, lng: 18.7375805 },
    { lat: 48.5783665, lng: 18.7379608 },
    { lat: 48.5783035, lng: 18.7384096 },
    { lat: 48.5783229, lng: 18.7386053 },
    { lat: 48.5783674, lng: 18.7388699 },
    { lat: 48.5783203, lng: 18.7400473 },
    { lat: 48.5781784, lng: 18.7409031 },
    { lat: 48.5782394, lng: 18.7411394 },
    { lat: 48.5783668, lng: 18.7415422 },
    { lat: 48.5784618, lng: 18.7419524 },
    { lat: 48.5784276, lng: 18.7442512 },
    { lat: 48.578426, lng: 18.744328 },
    { lat: 48.5782732, lng: 18.7451459 },
    { lat: 48.5782574, lng: 18.7452319 },
    { lat: 48.578219, lng: 18.7453828 },
    { lat: 48.577546, lng: 18.74805 },
    { lat: 48.5775253, lng: 18.7481291 },
    { lat: 48.577503, lng: 18.7481759 },
    { lat: 48.5774623, lng: 18.7482595 },
    { lat: 48.577441, lng: 18.7483044 },
    { lat: 48.5774308, lng: 18.7483252 },
    { lat: 48.5764997, lng: 18.7502533 },
    { lat: 48.5762673, lng: 18.7506986 },
    { lat: 48.5758423, lng: 18.7515142 },
    { lat: 48.5757736, lng: 18.751646 },
    { lat: 48.5753009, lng: 18.7525393 },
    { lat: 48.5750474, lng: 18.7532463 },
    { lat: 48.5749933, lng: 18.7533725 },
    { lat: 48.5744296, lng: 18.7547004 },
    { lat: 48.5740108, lng: 18.756382 },
    { lat: 48.5739755, lng: 18.756523 },
    { lat: 48.5739689, lng: 18.7565505 },
    { lat: 48.5739493, lng: 18.7566273 },
    { lat: 48.5739279, lng: 18.7567132 },
    { lat: 48.5737515, lng: 18.7574099 },
    { lat: 48.5737318, lng: 18.7574838 },
    { lat: 48.5737028, lng: 18.7575987 },
    { lat: 48.5737012, lng: 18.7576071 },
    { lat: 48.5736673, lng: 18.757759 },
    { lat: 48.5736338, lng: 18.7579108 },
    { lat: 48.5735973, lng: 18.7580754 },
    { lat: 48.5735255, lng: 18.7584031 },
    { lat: 48.5734706, lng: 18.7586482 },
    { lat: 48.5734253, lng: 18.7588549 },
    { lat: 48.5733487, lng: 18.7591999 },
    { lat: 48.5731729, lng: 18.7599912 },
    { lat: 48.5731599, lng: 18.7600458 },
    { lat: 48.5730803, lng: 18.7603741 },
    { lat: 48.5729682, lng: 18.7608369 },
    { lat: 48.5728554, lng: 18.7613059 },
    { lat: 48.5727184, lng: 18.7618725 },
    { lat: 48.5725824, lng: 18.7624379 },
    { lat: 48.572414, lng: 18.7631343 },
    { lat: 48.5724131, lng: 18.7631411 },
    { lat: 48.5723883, lng: 18.7632488 },
    { lat: 48.5723469, lng: 18.7634265 },
    { lat: 48.5721578, lng: 18.7642457 },
    { lat: 48.5715983, lng: 18.7669585 },
    { lat: 48.5715383, lng: 18.7672117 },
    { lat: 48.5714678, lng: 18.7672857 },
    { lat: 48.5713919, lng: 18.7673649 },
    { lat: 48.5712452, lng: 18.7678547 },
    { lat: 48.5707531, lng: 18.7694953 },
    { lat: 48.5705489, lng: 18.770177 },
    { lat: 48.5704031, lng: 18.7706649 },
    { lat: 48.5702851, lng: 18.7710589 },
    { lat: 48.5701834, lng: 18.7713983 },
    { lat: 48.5700339, lng: 18.771898 },
    { lat: 48.5699052, lng: 18.7723261 },
    { lat: 48.5697615, lng: 18.7728054 },
    { lat: 48.5696339, lng: 18.7732316 },
    { lat: 48.5695169, lng: 18.7736227 },
    { lat: 48.569373, lng: 18.7741035 },
    { lat: 48.569256, lng: 18.7744921 },
    { lat: 48.5691446, lng: 18.7748636 },
    { lat: 48.5691428, lng: 18.7748699 },
    { lat: 48.569039, lng: 18.7752148 },
    { lat: 48.5689279, lng: 18.7755863 },
    { lat: 48.5687851, lng: 18.7760649 },
    { lat: 48.5687871, lng: 18.7762558 },
    { lat: 48.5688452, lng: 18.7763945 },
    { lat: 48.5688368, lng: 18.7764283 },
    { lat: 48.5688005, lng: 18.7765729 },
    { lat: 48.5687552, lng: 18.7769789 },
    { lat: 48.5688006, lng: 18.7772398 },
    { lat: 48.5689184, lng: 18.7774853 },
    { lat: 48.5685957, lng: 18.7774598 },
    { lat: 48.5684979, lng: 18.777981 },
    { lat: 48.5684441, lng: 18.7782659 },
    { lat: 48.568965, lng: 18.7784785 },
    { lat: 48.5689583, lng: 18.7790779 },
    { lat: 48.568264, lng: 18.7792352 },
    { lat: 48.5682582, lng: 18.7792551 },
    { lat: 48.568067, lng: 18.7798662 },
    { lat: 48.5679474, lng: 18.7802511 },
    { lat: 48.5678452, lng: 18.7805777 },
    { lat: 48.568046, lng: 18.7807108 },
    { lat: 48.568081, lng: 18.7807337 },
    { lat: 48.568268, lng: 18.7808573 },
    { lat: 48.5691573, lng: 18.7814462 },
    { lat: 48.5693803, lng: 18.781594 },
    { lat: 48.5693931, lng: 18.7815993 },
    { lat: 48.5694266, lng: 18.7816163 },
    { lat: 48.5694401, lng: 18.7816389 },
    { lat: 48.5698953, lng: 18.7823818 },
    { lat: 48.5702482, lng: 18.7829582 },
    { lat: 48.5703805, lng: 18.7830303 },
    { lat: 48.5704664, lng: 18.7830771 },
    { lat: 48.5706799, lng: 18.7834878 },
    { lat: 48.5708654, lng: 18.7834785 },
    { lat: 48.5709745, lng: 18.783172 },
    { lat: 48.5712349, lng: 18.7835351 },
    { lat: 48.5712385, lng: 18.7835415 },
    { lat: 48.5714928, lng: 18.783874 },
    { lat: 48.5719271, lng: 18.7840454 },
    { lat: 48.5721694, lng: 18.7837006 },
    { lat: 48.5725516, lng: 18.7836123 },
    { lat: 48.5729001, lng: 18.783013 },
    { lat: 48.5731526, lng: 18.7834366 },
    { lat: 48.573406, lng: 18.7838618 },
    { lat: 48.5734838, lng: 18.7839928 },
    { lat: 48.5735238, lng: 18.7840204 },
    { lat: 48.5735505, lng: 18.7840393 },
    { lat: 48.5735822, lng: 18.7840619 },
  ],
  Nevoľné: [
    { lat: 48.6678543, lng: 18.9289265 },
    { lat: 48.667838, lng: 18.9292034 },
    { lat: 48.6677416, lng: 18.9311345 },
    { lat: 48.6676504, lng: 18.9319916 },
    { lat: 48.6676251, lng: 18.9321633 },
    { lat: 48.6659195, lng: 18.9344059 },
    { lat: 48.6634394, lng: 18.9376667 },
    { lat: 48.6634054, lng: 18.9377102 },
    { lat: 48.6633517, lng: 18.9377821 },
    { lat: 48.6639552, lng: 18.9399468 },
    { lat: 48.6640676, lng: 18.9402098 },
    { lat: 48.6641672, lng: 18.9404144 },
    { lat: 48.6646797, lng: 18.9415713 },
    { lat: 48.6647081, lng: 18.9416415 },
    { lat: 48.664738, lng: 18.9417194 },
    { lat: 48.6646994, lng: 18.9433921 },
    { lat: 48.6646903, lng: 18.9435009 },
    { lat: 48.6632621, lng: 18.9450753 },
    { lat: 48.6630886, lng: 18.945323 },
    { lat: 48.6632719, lng: 18.9458638 },
    { lat: 48.6635109, lng: 18.9457975 },
    { lat: 48.6637143, lng: 18.9456943 },
    { lat: 48.6638621, lng: 18.9456325 },
    { lat: 48.6639612, lng: 18.9456516 },
    { lat: 48.6642216, lng: 18.9455603 },
    { lat: 48.6644192, lng: 18.9455431 },
    { lat: 48.6645674, lng: 18.945641 },
    { lat: 48.6646661, lng: 18.945677 },
    { lat: 48.6647684, lng: 18.945714 },
    { lat: 48.6653372, lng: 18.9458832 },
    { lat: 48.6655354, lng: 18.9459418 },
    { lat: 48.6656066, lng: 18.9459674 },
    { lat: 48.6660603, lng: 18.9461076 },
    { lat: 48.6660958, lng: 18.9461652 },
    { lat: 48.6666222, lng: 18.9468673 },
    { lat: 48.6666828, lng: 18.9469487 },
    { lat: 48.6680313, lng: 18.9484099 },
    { lat: 48.6684142, lng: 18.9490645 },
    { lat: 48.6686601, lng: 18.9494857 },
    { lat: 48.6689886, lng: 18.9500475 },
    { lat: 48.6690327, lng: 18.9502454 },
    { lat: 48.6691055, lng: 18.9502604 },
    { lat: 48.6695027, lng: 18.9504408 },
    { lat: 48.6699157, lng: 18.9507609 },
    { lat: 48.6704457, lng: 18.9507389 },
    { lat: 48.6709773, lng: 18.9508123 },
    { lat: 48.6714064, lng: 18.9512493 },
    { lat: 48.6717357, lng: 18.9518043 },
    { lat: 48.6719116, lng: 18.9521763 },
    { lat: 48.6719583, lng: 18.9522761 },
    { lat: 48.6720744, lng: 18.9524306 },
    { lat: 48.6721396, lng: 18.9525144 },
    { lat: 48.6722115, lng: 18.9526173 },
    { lat: 48.672271, lng: 18.9527006 },
    { lat: 48.6731703, lng: 18.9542232 },
    { lat: 48.6733305, lng: 18.9544872 },
    { lat: 48.6734974, lng: 18.9547753 },
    { lat: 48.6737933, lng: 18.9550934 },
    { lat: 48.6742579, lng: 18.9554286 },
    { lat: 48.6744653, lng: 18.9556931 },
    { lat: 48.6747476, lng: 18.9559465 },
    { lat: 48.6748703, lng: 18.9560089 },
    { lat: 48.6750382, lng: 18.9560957 },
    { lat: 48.6753635, lng: 18.9563487 },
    { lat: 48.6758001, lng: 18.9568164 },
    { lat: 48.6759815, lng: 18.9571274 },
    { lat: 48.6761542, lng: 18.957549 },
    { lat: 48.6763221, lng: 18.9578574 },
    { lat: 48.6765695, lng: 18.9582204 },
    { lat: 48.6766838, lng: 18.9582981 },
    { lat: 48.6769228, lng: 18.9584498 },
    { lat: 48.6771242, lng: 18.9584657 },
    { lat: 48.6775367, lng: 18.9586358 },
    { lat: 48.6777742, lng: 18.9590263 },
    { lat: 48.6782444, lng: 18.9597374 },
    { lat: 48.6785165, lng: 18.9602317 },
    { lat: 48.6787296, lng: 18.9608074 },
    { lat: 48.6790201, lng: 18.9617755 },
    { lat: 48.6793487, lng: 18.9625184 },
    { lat: 48.6794452, lng: 18.9626414 },
    { lat: 48.6796821, lng: 18.9629079 },
    { lat: 48.6800383, lng: 18.9631951 },
    { lat: 48.6802447, lng: 18.9634199 },
    { lat: 48.68108, lng: 18.9640123 },
    { lat: 48.681321, lng: 18.9642491 },
    { lat: 48.6813947, lng: 18.9643037 },
    { lat: 48.6815351, lng: 18.9644494 },
    { lat: 48.6817022, lng: 18.9646969 },
    { lat: 48.6819175, lng: 18.9651636 },
    { lat: 48.6820459, lng: 18.9656239 },
    { lat: 48.682131, lng: 18.9660344 },
    { lat: 48.6823029, lng: 18.9663762 },
    { lat: 48.6823696, lng: 18.9665587 },
    { lat: 48.6824246, lng: 18.9667942 },
    { lat: 48.682453, lng: 18.966888 },
    { lat: 48.682486, lng: 18.9670258 },
    { lat: 48.6827139, lng: 18.9674989 },
    { lat: 48.6828004, lng: 18.9676248 },
    { lat: 48.682983, lng: 18.9678235 },
    { lat: 48.683153, lng: 18.9680175 },
    { lat: 48.6834577, lng: 18.968355 },
    { lat: 48.6838783, lng: 18.9685314 },
    { lat: 48.6844988, lng: 18.9685622 },
    { lat: 48.6849753, lng: 18.9685323 },
    { lat: 48.6861529, lng: 18.968232 },
    { lat: 48.6860794, lng: 18.9674508 },
    { lat: 48.6860688, lng: 18.9673996 },
    { lat: 48.6860023, lng: 18.9666865 },
    { lat: 48.6860196, lng: 18.9645239 },
    { lat: 48.6860192, lng: 18.964474 },
    { lat: 48.686024, lng: 18.9643921 },
    { lat: 48.6864401, lng: 18.9630824 },
    { lat: 48.6862749, lng: 18.9624075 },
    { lat: 48.6859887, lng: 18.9617003 },
    { lat: 48.6857845, lng: 18.9617419 },
    { lat: 48.6856388, lng: 18.9612503 },
    { lat: 48.6855788, lng: 18.9607359 },
    { lat: 48.6853265, lng: 18.9602107 },
    { lat: 48.6854941, lng: 18.9592482 },
    { lat: 48.6855453, lng: 18.9582859 },
    { lat: 48.6854595, lng: 18.9579512 },
    { lat: 48.6853322, lng: 18.9577055 },
    { lat: 48.68494, lng: 18.9571123 },
    { lat: 48.6845954, lng: 18.9569243 },
    { lat: 48.684494, lng: 18.9568348 },
    { lat: 48.6844011, lng: 18.9567606 },
    { lat: 48.6842911, lng: 18.9567252 },
    { lat: 48.6841043, lng: 18.9567309 },
    { lat: 48.6836687, lng: 18.9565481 },
    { lat: 48.6835101, lng: 18.9562911 },
    { lat: 48.6833642, lng: 18.9560376 },
    { lat: 48.6831634, lng: 18.9557929 },
    { lat: 48.683041, lng: 18.9556252 },
    { lat: 48.6828749, lng: 18.9556395 },
    { lat: 48.6826896, lng: 18.9556974 },
    { lat: 48.6826791, lng: 18.955269 },
    { lat: 48.6825575, lng: 18.9546545 },
    { lat: 48.6824643, lng: 18.9536002 },
    { lat: 48.682411, lng: 18.9529524 },
    { lat: 48.6825215, lng: 18.9524653 },
    { lat: 48.6824567, lng: 18.9520554 },
    { lat: 48.6824835, lng: 18.9516116 },
    { lat: 48.6824733, lng: 18.9511204 },
    { lat: 48.6824767, lng: 18.9502176 },
    { lat: 48.6825118, lng: 18.9501101 },
    { lat: 48.6825281, lng: 18.949234 },
    { lat: 48.682529, lng: 18.9484318 },
    { lat: 48.6824533, lng: 18.9480552 },
    { lat: 48.6822581, lng: 18.9469704 },
    { lat: 48.6821677, lng: 18.9465231 },
    { lat: 48.6818818, lng: 18.9460222 },
    { lat: 48.6816016, lng: 18.9456149 },
    { lat: 48.6811014, lng: 18.9452032 },
    { lat: 48.6805598, lng: 18.94504 },
    { lat: 48.6801572, lng: 18.9448642 },
    { lat: 48.6798471, lng: 18.9445104 },
    { lat: 48.6797951, lng: 18.9439306 },
    { lat: 48.6795634, lng: 18.9438533 },
    { lat: 48.6793556, lng: 18.9438044 },
    { lat: 48.679312, lng: 18.9437877 },
    { lat: 48.6791161, lng: 18.9436624 },
    { lat: 48.6787944, lng: 18.9436093 },
    { lat: 48.6781084, lng: 18.9438436 },
    { lat: 48.6774309, lng: 18.9436524 },
    { lat: 48.6769775, lng: 18.9434936 },
    { lat: 48.6766323, lng: 18.9432669 },
    { lat: 48.6763027, lng: 18.9429064 },
    { lat: 48.6760043, lng: 18.9423422 },
    { lat: 48.6757984, lng: 18.9419516 },
    { lat: 48.6756246, lng: 18.9415505 },
    { lat: 48.6758012, lng: 18.9408363 },
    { lat: 48.6762511, lng: 18.9398417 },
    { lat: 48.6765587, lng: 18.9388016 },
    { lat: 48.6767521, lng: 18.9381522 },
    { lat: 48.6766888, lng: 18.9373108 },
    { lat: 48.6767649, lng: 18.9367928 },
    { lat: 48.6767761, lng: 18.9362599 },
    { lat: 48.6767896, lng: 18.9355572 },
    { lat: 48.676735, lng: 18.9352614 },
    { lat: 48.6766993, lng: 18.9350672 },
    { lat: 48.6766767, lng: 18.9350459 },
    { lat: 48.6766559, lng: 18.9349884 },
    { lat: 48.6766474, lng: 18.9348931 },
    { lat: 48.6766791, lng: 18.9347185 },
    { lat: 48.6766495, lng: 18.9346017 },
    { lat: 48.676614, lng: 18.9344571 },
    { lat: 48.6765614, lng: 18.9343098 },
    { lat: 48.6765543, lng: 18.934129 },
    { lat: 48.6765493, lng: 18.9340975 },
    { lat: 48.6766325, lng: 18.9337185 },
    { lat: 48.6767343, lng: 18.9331958 },
    { lat: 48.6767422, lng: 18.9324902 },
    { lat: 48.6767369, lng: 18.9318154 },
    { lat: 48.6767137, lng: 18.9317996 },
    { lat: 48.6766206, lng: 18.9317673 },
    { lat: 48.67657, lng: 18.9316783 },
    { lat: 48.6765475, lng: 18.9316356 },
    { lat: 48.6764482, lng: 18.931482 },
    { lat: 48.6759927, lng: 18.9312394 },
    { lat: 48.6752108, lng: 18.9308532 },
    { lat: 48.6749945, lng: 18.9307461 },
    { lat: 48.6747947, lng: 18.9307894 },
    { lat: 48.6745227, lng: 18.9309588 },
    { lat: 48.674484, lng: 18.9309976 },
    { lat: 48.6740985, lng: 18.9312202 },
    { lat: 48.6738359, lng: 18.931598 },
    { lat: 48.6737007, lng: 18.9317718 },
    { lat: 48.673589, lng: 18.9319261 },
    { lat: 48.6730921, lng: 18.9322627 },
    { lat: 48.6730625, lng: 18.932279 },
    { lat: 48.6730317, lng: 18.9322958 },
    { lat: 48.6724636, lng: 18.9325208 },
    { lat: 48.6719071, lng: 18.932352 },
    { lat: 48.6714032, lng: 18.932155 },
    { lat: 48.6711237, lng: 18.9320257 },
    { lat: 48.6707802, lng: 18.931903 },
    { lat: 48.6702523, lng: 18.9314113 },
    { lat: 48.670016, lng: 18.9305192 },
    { lat: 48.6698277, lng: 18.9299476 },
    { lat: 48.6696089, lng: 18.9296792 },
    { lat: 48.6692425, lng: 18.9293727 },
    { lat: 48.6690157, lng: 18.9293909 },
    { lat: 48.668492, lng: 18.9293375 },
    { lat: 48.6678543, lng: 18.9289265 },
  ],
  HornáŽdaňa: [
    { lat: 48.5956627, lng: 18.7291615 },
    { lat: 48.5958975, lng: 18.7281684 },
    { lat: 48.5959823, lng: 18.7277887 },
    { lat: 48.5959939, lng: 18.7277328 },
    { lat: 48.5960314, lng: 18.7275651 },
    { lat: 48.5961893, lng: 18.7269921 },
    { lat: 48.596369, lng: 18.7261912 },
    { lat: 48.596393, lng: 18.7260914 },
    { lat: 48.5964033, lng: 18.7260485 },
    { lat: 48.5966656, lng: 18.7249573 },
    { lat: 48.5967581, lng: 18.7239168 },
    { lat: 48.5967299, lng: 18.7236616 },
    { lat: 48.5962252, lng: 18.7236943 },
    { lat: 48.5956554, lng: 18.7237074 },
    { lat: 48.5952437, lng: 18.7236673 },
    { lat: 48.5948017, lng: 18.7234958 },
    { lat: 48.5947729, lng: 18.7234843 },
    { lat: 48.5945299, lng: 18.72339 },
    { lat: 48.5939704, lng: 18.7230481 },
    { lat: 48.5932605, lng: 18.7226567 },
    { lat: 48.5926832, lng: 18.7222746 },
    { lat: 48.5914189, lng: 18.7202778 },
    { lat: 48.5908392, lng: 18.7191739 },
    { lat: 48.5905826, lng: 18.7188005 },
    { lat: 48.5904171, lng: 18.7185508 },
    { lat: 48.5901038, lng: 18.7180272 },
    { lat: 48.589876, lng: 18.7175153 },
    { lat: 48.5898559, lng: 18.7174793 },
    { lat: 48.5898286, lng: 18.7174331 },
    { lat: 48.5898015, lng: 18.7173858 },
    { lat: 48.5897751, lng: 18.7173384 },
    { lat: 48.5897567, lng: 18.7173071 },
    { lat: 48.5896584, lng: 18.7171334 },
    { lat: 48.5896149, lng: 18.7170581 },
    { lat: 48.5895887, lng: 18.7170135 },
    { lat: 48.589401, lng: 18.7166839 },
    { lat: 48.5893643, lng: 18.7166106 },
    { lat: 48.5897279, lng: 18.7159376 },
    { lat: 48.5898225, lng: 18.7157039 },
    { lat: 48.5898615, lng: 18.7154587 },
    { lat: 48.5898498, lng: 18.7151759 },
    { lat: 48.5898236, lng: 18.714915 },
    { lat: 48.5898351, lng: 18.7146458 },
    { lat: 48.5899614, lng: 18.713648 },
    { lat: 48.5900041, lng: 18.7133888 },
    { lat: 48.590075, lng: 18.7130899 },
    { lat: 48.5902875, lng: 18.712314 },
    { lat: 48.5903443, lng: 18.712067 },
    { lat: 48.5902981, lng: 18.7119405 },
    { lat: 48.590235, lng: 18.7117938 },
    { lat: 48.5901378, lng: 18.7116175 },
    { lat: 48.5900732, lng: 18.7114657 },
    { lat: 48.5900281, lng: 18.7112756 },
    { lat: 48.5899973, lng: 18.7109715 },
    { lat: 48.5899763, lng: 18.7107061 },
    { lat: 48.5899762, lng: 18.7104283 },
    { lat: 48.5899852, lng: 18.7103616 },
    { lat: 48.5900234, lng: 18.71004 },
    { lat: 48.590012, lng: 18.7097859 },
    { lat: 48.589967, lng: 18.7096998 },
    { lat: 48.5898672, lng: 18.7095441 },
    { lat: 48.5895959, lng: 18.7092366 },
    { lat: 48.5894435, lng: 18.7090634 },
    { lat: 48.5893568, lng: 18.708936 },
    { lat: 48.5891794, lng: 18.7087426 },
    { lat: 48.5890819, lng: 18.7085834 },
    { lat: 48.5890144, lng: 18.708417 },
    { lat: 48.5888072, lng: 18.7076577 },
    { lat: 48.5887633, lng: 18.7074913 },
    { lat: 48.5887478, lng: 18.7073282 },
    { lat: 48.5887753, lng: 18.7063623 },
    { lat: 48.5887587, lng: 18.705965 },
    { lat: 48.5887157, lng: 18.7055801 },
    { lat: 48.5886158, lng: 18.7047821 },
    { lat: 48.5884684, lng: 18.7038773 },
    { lat: 48.5884638, lng: 18.7035159 },
    { lat: 48.5885021, lng: 18.7027122 },
    { lat: 48.5884701, lng: 18.7020891 },
    { lat: 48.5886051, lng: 18.7016606 },
    { lat: 48.5885949, lng: 18.7013177 },
    { lat: 48.5885875, lng: 18.701055 },
    { lat: 48.5883637, lng: 18.7006649 },
    { lat: 48.5878826, lng: 18.7004624 },
    { lat: 48.5878595, lng: 18.6998778 },
    { lat: 48.5881075, lng: 18.6985222 },
    { lat: 48.5886046, lng: 18.696932 },
    { lat: 48.5891259, lng: 18.6958361 },
    { lat: 48.5895568, lng: 18.69493 },
    { lat: 48.5899061, lng: 18.6939675 },
    { lat: 48.590028, lng: 18.6931696 },
    { lat: 48.589737, lng: 18.6929324 },
    { lat: 48.5897205, lng: 18.6929194 },
    { lat: 48.5896767, lng: 18.6928834 },
    { lat: 48.5895989, lng: 18.6928202 },
    { lat: 48.589049, lng: 18.6910757 },
    { lat: 48.5891034, lng: 18.6906341 },
    { lat: 48.5891307, lng: 18.6904053 },
    { lat: 48.5893605, lng: 18.6885214 },
    { lat: 48.5891939, lng: 18.6867119 },
    { lat: 48.5890956, lng: 18.6865599 },
    { lat: 48.5890844, lng: 18.6865417 },
    { lat: 48.5890733, lng: 18.6865242 },
    { lat: 48.58856, lng: 18.685699 },
    { lat: 48.587642, lng: 18.685931 },
    { lat: 48.587389, lng: 18.686527 },
    { lat: 48.58695, lng: 18.686571 },
    { lat: 48.586237, lng: 18.686517 },
    { lat: 48.585489, lng: 18.686865 },
    { lat: 48.585136, lng: 18.687022 },
    { lat: 48.584769, lng: 18.687164 },
    { lat: 48.584306, lng: 18.687241 },
    { lat: 48.583748, lng: 18.687853 },
    { lat: 48.583405, lng: 18.688395 },
    { lat: 48.583333, lng: 18.688498 },
    { lat: 48.583064, lng: 18.688824 },
    { lat: 48.582893, lng: 18.688988 },
    { lat: 48.582683, lng: 18.689073 },
    { lat: 48.582502, lng: 18.689044 },
    { lat: 48.582243, lng: 18.688923 },
    { lat: 48.582024, lng: 18.688779 },
    { lat: 48.581577, lng: 18.689072 },
    { lat: 48.581282, lng: 18.688943 },
    { lat: 48.580708, lng: 18.688997 },
    { lat: 48.579521, lng: 18.690402 },
    { lat: 48.579109, lng: 18.690516 },
    { lat: 48.578408, lng: 18.69106 },
    { lat: 48.578128, lng: 18.691405 },
    { lat: 48.577614, lng: 18.69205 },
    { lat: 48.577279, lng: 18.692491 },
    { lat: 48.577267, lng: 18.692506 },
    { lat: 48.577244, lng: 18.692523 },
    { lat: 48.576786, lng: 18.692855 },
    { lat: 48.576505, lng: 18.693226 },
    { lat: 48.576269, lng: 18.693261 },
    { lat: 48.576103, lng: 18.693292 },
    { lat: 48.575765, lng: 18.693895 },
    { lat: 48.575534, lng: 18.694428 },
    { lat: 48.575444, lng: 18.695135 },
    { lat: 48.575149, lng: 18.695474 },
    { lat: 48.575118, lng: 18.69627 },
    { lat: 48.574904, lng: 18.696907 },
    { lat: 48.574702, lng: 18.697105 },
    { lat: 48.574426, lng: 18.697296 },
    { lat: 48.574357, lng: 18.697503 },
    { lat: 48.574391, lng: 18.697619 },
    { lat: 48.574127, lng: 18.698049 },
    { lat: 48.574085, lng: 18.698355 },
    { lat: 48.573678, lng: 18.698711 },
    { lat: 48.573628, lng: 18.698911 },
    { lat: 48.573635, lng: 18.699 },
    { lat: 48.573661, lng: 18.699353 },
    { lat: 48.573402, lng: 18.699838 },
    { lat: 48.573025, lng: 18.700135 },
    { lat: 48.573025, lng: 18.70013 },
    { lat: 48.572509, lng: 18.700919 },
    { lat: 48.572292, lng: 18.701652 },
    { lat: 48.572077, lng: 18.701951 },
    { lat: 48.571777, lng: 18.702349 },
    { lat: 48.5703249, lng: 18.7039249 },
    { lat: 48.570449, lng: 18.703668 },
    { lat: 48.569799, lng: 18.702941 },
    { lat: 48.569354, lng: 18.702568 },
    { lat: 48.569062, lng: 18.702324 },
    { lat: 48.569059, lng: 18.702322 },
    { lat: 48.569057, lng: 18.702319 },
    { lat: 48.568862, lng: 18.702156 },
    { lat: 48.568146, lng: 18.701556 },
    { lat: 48.566789, lng: 18.702226 },
    { lat: 48.566265, lng: 18.70283 },
    { lat: 48.565628, lng: 18.703198 },
    { lat: 48.565314, lng: 18.703139 },
    { lat: 48.564931, lng: 18.702594 },
    { lat: 48.564212, lng: 18.702117 },
    { lat: 48.564103, lng: 18.702045 },
    { lat: 48.56376, lng: 18.70191 },
    { lat: 48.562973, lng: 18.701602 },
    { lat: 48.56234, lng: 18.701612 },
    { lat: 48.561863, lng: 18.701289 },
    { lat: 48.561071, lng: 18.700808 },
    { lat: 48.560849, lng: 18.700714 },
    { lat: 48.559092, lng: 18.701012 },
    { lat: 48.557171, lng: 18.700951 },
    { lat: 48.55696, lng: 18.700836 },
    { lat: 48.556574, lng: 18.700816 },
    { lat: 48.555074, lng: 18.700453 },
    { lat: 48.554997, lng: 18.700434 },
    { lat: 48.55462, lng: 18.700326 },
    { lat: 48.5545444, lng: 18.7012777 },
    { lat: 48.5542784, lng: 18.7024896 },
    { lat: 48.5538655, lng: 18.7040112 },
    { lat: 48.5538476, lng: 18.7040789 },
    { lat: 48.5537523, lng: 18.7044283 },
    { lat: 48.5535563, lng: 18.705149 },
    { lat: 48.5535521, lng: 18.705262 },
    { lat: 48.5535284, lng: 18.7057931 },
    { lat: 48.5535263, lng: 18.7058998 },
    { lat: 48.5534931, lng: 18.7064439 },
    { lat: 48.5533442, lng: 18.7068649 },
    { lat: 48.5532872, lng: 18.7070013 },
    { lat: 48.5532737, lng: 18.7070624 },
    { lat: 48.5533704, lng: 18.7081376 },
    { lat: 48.5536729, lng: 18.7087365 },
    { lat: 48.55422, lng: 18.7098213 },
    { lat: 48.5542618, lng: 18.7107522 },
    { lat: 48.5538314, lng: 18.7117781 },
    { lat: 48.5535308, lng: 18.7130622 },
    { lat: 48.5539166, lng: 18.7150985 },
    { lat: 48.5546847, lng: 18.7161845 },
    { lat: 48.5551691, lng: 18.7164055 },
    { lat: 48.5558521, lng: 18.7164406 },
    { lat: 48.5563381, lng: 18.7164389 },
    { lat: 48.5564782, lng: 18.7164648 },
    { lat: 48.55691, lng: 18.7165444 },
    { lat: 48.5574702, lng: 18.7167285 },
    { lat: 48.5575001, lng: 18.7167391 },
    { lat: 48.5575138, lng: 18.7167443 },
    { lat: 48.5575368, lng: 18.716755 },
    { lat: 48.5578373, lng: 18.7174197 },
    { lat: 48.5581639, lng: 18.7178368 },
    { lat: 48.558524, lng: 18.7188813 },
    { lat: 48.5585269, lng: 18.7193175 },
    { lat: 48.55853, lng: 18.7198205 },
    { lat: 48.5583349, lng: 18.7215622 },
    { lat: 48.5584291, lng: 18.7231273 },
    { lat: 48.5578225, lng: 18.7244863 },
    { lat: 48.5576748, lng: 18.7249968 },
    { lat: 48.5575942, lng: 18.7254106 },
    { lat: 48.5568544, lng: 18.7257525 },
    { lat: 48.556351, lng: 18.7258502 },
    { lat: 48.5557219, lng: 18.7259388 },
    { lat: 48.5548443, lng: 18.7260826 },
    { lat: 48.55485, lng: 18.7261499 },
    { lat: 48.5549943, lng: 18.7281656 },
    { lat: 48.5550887, lng: 18.7309028 },
    { lat: 48.5551083, lng: 18.7314636 },
    { lat: 48.5555032, lng: 18.7321904 },
    { lat: 48.5554166, lng: 18.7328758 },
    { lat: 48.555407, lng: 18.7329524 },
    { lat: 48.5553989, lng: 18.7330093 },
    { lat: 48.5553724, lng: 18.7332125 },
    { lat: 48.5553671, lng: 18.7336671 },
    { lat: 48.5556827, lng: 18.7398366 },
    { lat: 48.5556469, lng: 18.7400534 },
    { lat: 48.5555941, lng: 18.7403769 },
    { lat: 48.5555804, lng: 18.740458 },
    { lat: 48.5550285, lng: 18.7438175 },
    { lat: 48.5547955, lng: 18.7452331 },
    { lat: 48.5549919, lng: 18.7453181 },
    { lat: 48.5551339, lng: 18.7453801 },
    { lat: 48.5553524, lng: 18.7454806 },
    { lat: 48.5554967, lng: 18.7455469 },
    { lat: 48.5556459, lng: 18.7456152 },
    { lat: 48.5557835, lng: 18.7456784 },
    { lat: 48.5559141, lng: 18.7457389 },
    { lat: 48.5560342, lng: 18.7457939 },
    { lat: 48.5560863, lng: 18.7458183 },
    { lat: 48.556037, lng: 18.7459947 },
    { lat: 48.5559181, lng: 18.74669 },
    { lat: 48.5558869, lng: 18.7468692 },
    { lat: 48.5558534, lng: 18.7470669 },
    { lat: 48.5558199, lng: 18.7472639 },
    { lat: 48.5557577, lng: 18.7476213 },
    { lat: 48.5556854, lng: 18.7480461 },
    { lat: 48.5556621, lng: 18.7481806 },
    { lat: 48.5556336, lng: 18.7483478 },
    { lat: 48.5555584, lng: 18.7487808 },
    { lat: 48.5555172, lng: 18.7490246 },
    { lat: 48.5555034, lng: 18.7491046 },
    { lat: 48.5557289, lng: 18.7492306 },
    { lat: 48.5557684, lng: 18.7492529 },
    { lat: 48.5570143, lng: 18.74995 },
    { lat: 48.5570885, lng: 18.7499916 },
    { lat: 48.5569016, lng: 18.7503181 },
    { lat: 48.5562501, lng: 18.7514546 },
    { lat: 48.5561727, lng: 18.751591 },
    { lat: 48.5564355, lng: 18.7522529 },
    { lat: 48.5567085, lng: 18.7529372 },
    { lat: 48.5568267, lng: 18.7532338 },
    { lat: 48.5568168, lng: 18.7537711 },
    { lat: 48.5564703, lng: 18.755536 },
    { lat: 48.5564483, lng: 18.7556529 },
    { lat: 48.5564582, lng: 18.7557546 },
    { lat: 48.5564625, lng: 18.7557961 },
    { lat: 48.5564649, lng: 18.7558179 },
    { lat: 48.556468, lng: 18.7558556 },
    { lat: 48.5564707, lng: 18.7558845 },
    { lat: 48.5565041, lng: 18.7562158 },
    { lat: 48.5565073, lng: 18.7562596 },
    { lat: 48.5565077, lng: 18.756267 },
    { lat: 48.5565139, lng: 18.7563613 },
    { lat: 48.5565167, lng: 18.7564144 },
    { lat: 48.5565833, lng: 18.7563961 },
    { lat: 48.5567701, lng: 18.7563448 },
    { lat: 48.5568056, lng: 18.7563328 },
    { lat: 48.5568235, lng: 18.7563303 },
    { lat: 48.5570668, lng: 18.7573499 },
    { lat: 48.5570783, lng: 18.7573958 },
    { lat: 48.5571214, lng: 18.7575505 },
    { lat: 48.557141, lng: 18.7576436 },
    { lat: 48.55715, lng: 18.7576856 },
    { lat: 48.5571625, lng: 18.7577286 },
    { lat: 48.5572092, lng: 18.7578882 },
    { lat: 48.5572217, lng: 18.7579326 },
    { lat: 48.5572437, lng: 18.758009 },
    { lat: 48.5572561, lng: 18.7580523 },
    { lat: 48.5576591, lng: 18.7594444 },
    { lat: 48.5578258, lng: 18.7600207 },
    { lat: 48.5578467, lng: 18.7600934 },
    { lat: 48.5578549, lng: 18.7601244 },
    { lat: 48.5579847, lng: 18.7605727 },
    { lat: 48.5581624, lng: 18.7611851 },
    { lat: 48.5584523, lng: 18.7621875 },
    { lat: 48.5584551, lng: 18.7622003 },
    { lat: 48.5584624, lng: 18.7622318 },
    { lat: 48.5584666, lng: 18.7622477 },
    { lat: 48.5584789, lng: 18.7622882 },
    { lat: 48.5589807, lng: 18.7638893 },
    { lat: 48.5589916, lng: 18.7639268 },
    { lat: 48.5593634, lng: 18.7651151 },
    { lat: 48.5593768, lng: 18.7651587 },
    { lat: 48.5593834, lng: 18.7651757 },
    { lat: 48.5593903, lng: 18.7652002 },
    { lat: 48.5593972, lng: 18.7652229 },
    { lat: 48.5599634, lng: 18.7670872 },
    { lat: 48.5599755, lng: 18.7671238 },
    { lat: 48.5604232, lng: 18.7685968 },
    { lat: 48.5606484, lng: 18.769341 },
    { lat: 48.5606729, lng: 18.76942 },
    { lat: 48.560746, lng: 18.7695423 },
    { lat: 48.560773, lng: 18.7695892 },
    { lat: 48.5609103, lng: 18.769817 },
    { lat: 48.5610353, lng: 18.7700278 },
    { lat: 48.5611972, lng: 18.7702975 },
    { lat: 48.5613569, lng: 18.7705664 },
    { lat: 48.5614805, lng: 18.7707724 },
    { lat: 48.5616005, lng: 18.7709731 },
    { lat: 48.5617972, lng: 18.7713027 },
    { lat: 48.5619999, lng: 18.7716423 },
    { lat: 48.5644639, lng: 18.775769 },
    { lat: 48.5644761, lng: 18.7757907 },
    { lat: 48.5645005, lng: 18.7758013 },
    { lat: 48.5645144, lng: 18.7758082 },
    { lat: 48.5645964, lng: 18.775374 },
    { lat: 48.5646278, lng: 18.7752019 },
    { lat: 48.5648733, lng: 18.774511 },
    { lat: 48.5649099, lng: 18.7744105 },
    { lat: 48.5652114, lng: 18.7735616 },
    { lat: 48.5661714, lng: 18.7729035 },
    { lat: 48.5667667, lng: 18.7724955 },
    { lat: 48.5671435, lng: 18.7722372 },
    { lat: 48.5685453, lng: 18.7712766 },
    { lat: 48.568738, lng: 18.771145 },
    { lat: 48.5688174, lng: 18.7710906 },
    { lat: 48.5688675, lng: 18.7710561 },
    { lat: 48.5689757, lng: 18.7709691 },
    { lat: 48.5690394, lng: 18.7709181 },
    { lat: 48.5690279, lng: 18.7712965 },
    { lat: 48.5690236, lng: 18.7713853 },
    { lat: 48.5689894, lng: 18.7720631 },
    { lat: 48.5689861, lng: 18.7721319 },
    { lat: 48.5689733, lng: 18.7723816 },
    { lat: 48.5689712, lng: 18.7724445 },
    { lat: 48.5689627, lng: 18.7726079 },
    { lat: 48.5689568, lng: 18.7727322 },
    { lat: 48.5689265, lng: 18.7733553 },
    { lat: 48.5689176, lng: 18.7735273 },
    { lat: 48.5689071, lng: 18.7737362 },
    { lat: 48.5688977, lng: 18.773921 },
    { lat: 48.5688872, lng: 18.7741306 },
    { lat: 48.5688754, lng: 18.7743773 },
    { lat: 48.5688729, lng: 18.7744335 },
    { lat: 48.5688596, lng: 18.7746968 },
    { lat: 48.5688219, lng: 18.7754587 },
    { lat: 48.5688171, lng: 18.7755593 },
    { lat: 48.5688131, lng: 18.7756357 },
    { lat: 48.5687851, lng: 18.7760649 },
    { lat: 48.5689279, lng: 18.7755863 },
    { lat: 48.569039, lng: 18.7752148 },
    { lat: 48.5691428, lng: 18.7748699 },
    { lat: 48.5691446, lng: 18.7748636 },
    { lat: 48.569256, lng: 18.7744921 },
    { lat: 48.569373, lng: 18.7741035 },
    { lat: 48.5695169, lng: 18.7736227 },
    { lat: 48.5696339, lng: 18.7732316 },
    { lat: 48.5697615, lng: 18.7728054 },
    { lat: 48.5699052, lng: 18.7723261 },
    { lat: 48.5700339, lng: 18.771898 },
    { lat: 48.5701834, lng: 18.7713983 },
    { lat: 48.5702851, lng: 18.7710589 },
    { lat: 48.5704031, lng: 18.7706649 },
    { lat: 48.5705489, lng: 18.770177 },
    { lat: 48.5707531, lng: 18.7694953 },
    { lat: 48.5712452, lng: 18.7678547 },
    { lat: 48.5713919, lng: 18.7673649 },
    { lat: 48.5714678, lng: 18.7672857 },
    { lat: 48.5715383, lng: 18.7672117 },
    { lat: 48.5715983, lng: 18.7669585 },
    { lat: 48.5721578, lng: 18.7642457 },
    { lat: 48.5723469, lng: 18.7634265 },
    { lat: 48.5723883, lng: 18.7632488 },
    { lat: 48.5724131, lng: 18.7631411 },
    { lat: 48.572414, lng: 18.7631343 },
    { lat: 48.5725824, lng: 18.7624379 },
    { lat: 48.5727184, lng: 18.7618725 },
    { lat: 48.5728554, lng: 18.7613059 },
    { lat: 48.5729682, lng: 18.7608369 },
    { lat: 48.5730803, lng: 18.7603741 },
    { lat: 48.5731599, lng: 18.7600458 },
    { lat: 48.5731729, lng: 18.7599912 },
    { lat: 48.5733487, lng: 18.7591999 },
    { lat: 48.5734253, lng: 18.7588549 },
    { lat: 48.5734706, lng: 18.7586482 },
    { lat: 48.5735255, lng: 18.7584031 },
    { lat: 48.5735973, lng: 18.7580754 },
    { lat: 48.5736338, lng: 18.7579108 },
    { lat: 48.5736673, lng: 18.757759 },
    { lat: 48.5737012, lng: 18.7576071 },
    { lat: 48.5737028, lng: 18.7575987 },
    { lat: 48.5737318, lng: 18.7574838 },
    { lat: 48.5737515, lng: 18.7574099 },
    { lat: 48.5739279, lng: 18.7567132 },
    { lat: 48.5739493, lng: 18.7566273 },
    { lat: 48.5739689, lng: 18.7565505 },
    { lat: 48.5739755, lng: 18.756523 },
    { lat: 48.5740108, lng: 18.756382 },
    { lat: 48.5744296, lng: 18.7547004 },
    { lat: 48.5749933, lng: 18.7533725 },
    { lat: 48.5750474, lng: 18.7532463 },
    { lat: 48.5753009, lng: 18.7525393 },
    { lat: 48.5757736, lng: 18.751646 },
    { lat: 48.5758423, lng: 18.7515142 },
    { lat: 48.5762673, lng: 18.7506986 },
    { lat: 48.5764997, lng: 18.7502533 },
    { lat: 48.5774308, lng: 18.7483252 },
    { lat: 48.577441, lng: 18.7483044 },
    { lat: 48.5774623, lng: 18.7482595 },
    { lat: 48.577503, lng: 18.7481759 },
    { lat: 48.5775253, lng: 18.7481291 },
    { lat: 48.577546, lng: 18.74805 },
    { lat: 48.578219, lng: 18.7453828 },
    { lat: 48.5782574, lng: 18.7452319 },
    { lat: 48.5782732, lng: 18.7451459 },
    { lat: 48.578426, lng: 18.744328 },
    { lat: 48.5784276, lng: 18.7442512 },
    { lat: 48.5784618, lng: 18.7419524 },
    { lat: 48.5783668, lng: 18.7415422 },
    { lat: 48.5782394, lng: 18.7411394 },
    { lat: 48.5781784, lng: 18.7409031 },
    { lat: 48.5783203, lng: 18.7400473 },
    { lat: 48.5783674, lng: 18.7388699 },
    { lat: 48.5783229, lng: 18.7386053 },
    { lat: 48.5783035, lng: 18.7384096 },
    { lat: 48.5783665, lng: 18.7379608 },
    { lat: 48.5784366, lng: 18.7375805 },
    { lat: 48.5785434, lng: 18.7371463 },
    { lat: 48.5786195, lng: 18.7369456 },
    { lat: 48.5788024, lng: 18.7364819 },
    { lat: 48.578911, lng: 18.7362306 },
    { lat: 48.5790248, lng: 18.735911 },
    { lat: 48.5792726, lng: 18.7351831 },
    { lat: 48.5794101, lng: 18.7347933 },
    { lat: 48.5794823, lng: 18.7346036 },
    { lat: 48.5795964, lng: 18.7342248 },
    { lat: 48.579693, lng: 18.733898 },
    { lat: 48.5797339, lng: 18.7337546 },
    { lat: 48.5798052, lng: 18.7335672 },
    { lat: 48.5798875, lng: 18.7333977 },
    { lat: 48.5799241, lng: 18.7333217 },
    { lat: 48.5799626, lng: 18.7332409 },
    { lat: 48.5800149, lng: 18.7331227 },
    { lat: 48.5800523, lng: 18.7330384 },
    { lat: 48.5800552, lng: 18.7330313 },
    { lat: 48.5802122, lng: 18.7327154 },
    { lat: 48.5806987, lng: 18.7317429 },
    { lat: 48.5807639, lng: 18.7316132 },
    { lat: 48.5811421, lng: 18.7308553 },
    { lat: 48.581385, lng: 18.730351 },
    { lat: 48.5814022, lng: 18.7303147 },
    { lat: 48.5816671, lng: 18.7297799 },
    { lat: 48.5816876, lng: 18.7297211 },
    { lat: 48.5821362, lng: 18.7288432 },
    { lat: 48.5821993, lng: 18.7287142 },
    { lat: 48.5823953, lng: 18.7283508 },
    { lat: 48.5826347, lng: 18.7286546 },
    { lat: 48.5827756, lng: 18.7289268 },
    { lat: 48.5829164, lng: 18.7292654 },
    { lat: 48.5830391, lng: 18.729751 },
    { lat: 48.5831893, lng: 18.7300076 },
    { lat: 48.5834789, lng: 18.7302377 },
    { lat: 48.5835411, lng: 18.7303492 },
    { lat: 48.5837229, lng: 18.7305862 },
    { lat: 48.5839747, lng: 18.7308259 },
    { lat: 48.5842353, lng: 18.7311671 },
    { lat: 48.584702, lng: 18.7319743 },
    { lat: 48.5852449, lng: 18.7322384 },
    { lat: 48.585274, lng: 18.7322524 },
    { lat: 48.5853249, lng: 18.7322765 },
    { lat: 48.5854652, lng: 18.7322874 },
    { lat: 48.5855809, lng: 18.7319166 },
    { lat: 48.5857342, lng: 18.7314244 },
    { lat: 48.585933, lng: 18.7311123 },
    { lat: 48.586056, lng: 18.7309639 },
    { lat: 48.5863146, lng: 18.7309064 },
    { lat: 48.5866287, lng: 18.7306217 },
    { lat: 48.5867505, lng: 18.7305119 },
    { lat: 48.5870142, lng: 18.7302981 },
    { lat: 48.5874858, lng: 18.7298537 },
    { lat: 48.587571, lng: 18.7290983 },
    { lat: 48.587612, lng: 18.7285743 },
    { lat: 48.5877943, lng: 18.727654 },
    { lat: 48.588356, lng: 18.7279293 },
    { lat: 48.5888593, lng: 18.7280443 },
    { lat: 48.5895936, lng: 18.7282365 },
    { lat: 48.5898833, lng: 18.7276398 },
    { lat: 48.5899474, lng: 18.7275057 },
    { lat: 48.5902947, lng: 18.7273184 },
    { lat: 48.5913296, lng: 18.7278165 },
    { lat: 48.5917904, lng: 18.727746 },
    { lat: 48.5919656, lng: 18.7286019 },
    { lat: 48.5922454, lng: 18.7286064 },
    { lat: 48.5930165, lng: 18.7286239 },
    { lat: 48.5937669, lng: 18.7286749 },
    { lat: 48.5948295, lng: 18.7288273 },
    { lat: 48.594908, lng: 18.7288381 },
    { lat: 48.5956627, lng: 18.7291615 },
  ],
  DolnáTrnávka: [
    { lat: 48.5735822, lng: 18.7840619 },
    { lat: 48.5735505, lng: 18.7840393 },
    { lat: 48.5735238, lng: 18.7840204 },
    { lat: 48.5734838, lng: 18.7839928 },
    { lat: 48.573406, lng: 18.7838618 },
    { lat: 48.5731526, lng: 18.7834366 },
    { lat: 48.5729001, lng: 18.783013 },
    { lat: 48.5725516, lng: 18.7836123 },
    { lat: 48.5721694, lng: 18.7837006 },
    { lat: 48.5719271, lng: 18.7840454 },
    { lat: 48.5714928, lng: 18.783874 },
    { lat: 48.5712385, lng: 18.7835415 },
    { lat: 48.5712349, lng: 18.7835351 },
    { lat: 48.5709745, lng: 18.783172 },
    { lat: 48.5708654, lng: 18.7834785 },
    { lat: 48.5706799, lng: 18.7834878 },
    { lat: 48.5704664, lng: 18.7830771 },
    { lat: 48.5703805, lng: 18.7830303 },
    { lat: 48.5702482, lng: 18.7829582 },
    { lat: 48.5698953, lng: 18.7823818 },
    { lat: 48.5694401, lng: 18.7816389 },
    { lat: 48.5694266, lng: 18.7816163 },
    { lat: 48.5693931, lng: 18.7815993 },
    { lat: 48.5693803, lng: 18.781594 },
    { lat: 48.5691573, lng: 18.7814462 },
    { lat: 48.568268, lng: 18.7808573 },
    { lat: 48.568081, lng: 18.7807337 },
    { lat: 48.568046, lng: 18.7807108 },
    { lat: 48.5678452, lng: 18.7805777 },
    { lat: 48.5679474, lng: 18.7802511 },
    { lat: 48.568067, lng: 18.7798662 },
    { lat: 48.5682582, lng: 18.7792551 },
    { lat: 48.568264, lng: 18.7792352 },
    { lat: 48.5689583, lng: 18.7790779 },
    { lat: 48.568965, lng: 18.7784785 },
    { lat: 48.5684441, lng: 18.7782659 },
    { lat: 48.5684979, lng: 18.777981 },
    { lat: 48.5685957, lng: 18.7774598 },
    { lat: 48.5689184, lng: 18.7774853 },
    { lat: 48.5688006, lng: 18.7772398 },
    { lat: 48.5687552, lng: 18.7769789 },
    { lat: 48.5688005, lng: 18.7765729 },
    { lat: 48.5688368, lng: 18.7764283 },
    { lat: 48.5688452, lng: 18.7763945 },
    { lat: 48.5687871, lng: 18.7762558 },
    { lat: 48.5687851, lng: 18.7760649 },
    { lat: 48.5688131, lng: 18.7756357 },
    { lat: 48.5688171, lng: 18.7755593 },
    { lat: 48.5688219, lng: 18.7754587 },
    { lat: 48.5688596, lng: 18.7746968 },
    { lat: 48.5688729, lng: 18.7744335 },
    { lat: 48.5688754, lng: 18.7743773 },
    { lat: 48.5688872, lng: 18.7741306 },
    { lat: 48.5688977, lng: 18.773921 },
    { lat: 48.5689071, lng: 18.7737362 },
    { lat: 48.5689176, lng: 18.7735273 },
    { lat: 48.5689265, lng: 18.7733553 },
    { lat: 48.5689568, lng: 18.7727322 },
    { lat: 48.5689627, lng: 18.7726079 },
    { lat: 48.5689712, lng: 18.7724445 },
    { lat: 48.5689733, lng: 18.7723816 },
    { lat: 48.5689861, lng: 18.7721319 },
    { lat: 48.5689894, lng: 18.7720631 },
    { lat: 48.5690236, lng: 18.7713853 },
    { lat: 48.5690279, lng: 18.7712965 },
    { lat: 48.5690394, lng: 18.7709181 },
    { lat: 48.5689757, lng: 18.7709691 },
    { lat: 48.5688675, lng: 18.7710561 },
    { lat: 48.5688174, lng: 18.7710906 },
    { lat: 48.568738, lng: 18.771145 },
    { lat: 48.5685453, lng: 18.7712766 },
    { lat: 48.5671435, lng: 18.7722372 },
    { lat: 48.5667667, lng: 18.7724955 },
    { lat: 48.5661714, lng: 18.7729035 },
    { lat: 48.5652114, lng: 18.7735616 },
    { lat: 48.5649099, lng: 18.7744105 },
    { lat: 48.5648733, lng: 18.774511 },
    { lat: 48.5646278, lng: 18.7752019 },
    { lat: 48.5645964, lng: 18.775374 },
    { lat: 48.5645144, lng: 18.7758082 },
    { lat: 48.5645005, lng: 18.7758013 },
    { lat: 48.5644761, lng: 18.7757907 },
    { lat: 48.5644639, lng: 18.775769 },
    { lat: 48.5619999, lng: 18.7716423 },
    { lat: 48.5617972, lng: 18.7713027 },
    { lat: 48.5616005, lng: 18.7709731 },
    { lat: 48.5614805, lng: 18.7707724 },
    { lat: 48.5613569, lng: 18.7705664 },
    { lat: 48.5611972, lng: 18.7702975 },
    { lat: 48.5610353, lng: 18.7700278 },
    { lat: 48.5609103, lng: 18.769817 },
    { lat: 48.560773, lng: 18.7695892 },
    { lat: 48.560746, lng: 18.7695423 },
    { lat: 48.5606729, lng: 18.76942 },
    { lat: 48.5605737, lng: 18.7694842 },
    { lat: 48.5603227, lng: 18.7696456 },
    { lat: 48.5600229, lng: 18.7698391 },
    { lat: 48.5596794, lng: 18.7700611 },
    { lat: 48.5593281, lng: 18.7702878 },
    { lat: 48.5591732, lng: 18.7703875 },
    { lat: 48.5589693, lng: 18.7705194 },
    { lat: 48.5586487, lng: 18.7707258 },
    { lat: 48.5582234, lng: 18.7710006 },
    { lat: 48.5578033, lng: 18.7712715 },
    { lat: 48.5574577, lng: 18.7714948 },
    { lat: 48.5571238, lng: 18.7717102 },
    { lat: 48.5567956, lng: 18.7719217 },
    { lat: 48.5565065, lng: 18.7721083 },
    { lat: 48.5562459, lng: 18.7722762 },
    { lat: 48.5561216, lng: 18.7723565 },
    { lat: 48.5560725, lng: 18.7723884 },
    { lat: 48.5559863, lng: 18.7724442 },
    { lat: 48.5557464, lng: 18.7725989 },
    { lat: 48.5555141, lng: 18.7727484 },
    { lat: 48.5553037, lng: 18.7728847 },
    { lat: 48.5550932, lng: 18.7730205 },
    { lat: 48.5549099, lng: 18.7731386 },
    { lat: 48.5547343, lng: 18.7732517 },
    { lat: 48.5545837, lng: 18.7733491 },
    { lat: 48.5544562, lng: 18.7734309 },
    { lat: 48.5543339, lng: 18.7735096 },
    { lat: 48.5542226, lng: 18.773582 },
    { lat: 48.554093, lng: 18.7736658 },
    { lat: 48.5539216, lng: 18.773776 },
    { lat: 48.5536796, lng: 18.7739324 },
    { lat: 48.5531421, lng: 18.7742846 },
    { lat: 48.5527957, lng: 18.7745146 },
    { lat: 48.5526521, lng: 18.7746144 },
    { lat: 48.5536549, lng: 18.7754788 },
    { lat: 48.5547457, lng: 18.7766816 },
    { lat: 48.5547766, lng: 18.7767345 },
    { lat: 48.5547932, lng: 18.7767717 },
    { lat: 48.5515883, lng: 18.7793664 },
    { lat: 48.551555, lng: 18.7793981 },
    { lat: 48.5493902, lng: 18.7811611 },
    { lat: 48.5493435, lng: 18.7811973 },
    { lat: 48.5492672, lng: 18.7812704 },
    { lat: 48.5492416, lng: 18.7812952 },
    { lat: 48.5488427, lng: 18.7816692 },
    { lat: 48.549234, lng: 18.7822888 },
    { lat: 48.5493806, lng: 18.7835295 },
    { lat: 48.5493244, lng: 18.784478 },
    { lat: 48.5492233, lng: 18.7852567 },
    { lat: 48.5495179, lng: 18.786294 },
    { lat: 48.549605, lng: 18.7864278 },
    { lat: 48.54967, lng: 18.7865268 },
    { lat: 48.5499387, lng: 18.7868318 },
    { lat: 48.5501694, lng: 18.7875984 },
    { lat: 48.5503064, lng: 18.7880558 },
    { lat: 48.5504602, lng: 18.7881489 },
    { lat: 48.5505683, lng: 18.7885333 },
    { lat: 48.5506885, lng: 18.7889546 },
    { lat: 48.5509577, lng: 18.7889923 },
    { lat: 48.5512468, lng: 18.7888948 },
    { lat: 48.5515402, lng: 18.7886801 },
    { lat: 48.5517882, lng: 18.7885925 },
    { lat: 48.5519093, lng: 18.7886905 },
    { lat: 48.5520218, lng: 18.7890046 },
    { lat: 48.5521985, lng: 18.7891074 },
    { lat: 48.5523989, lng: 18.789075 },
    { lat: 48.5525232, lng: 18.7888872 },
    { lat: 48.5527226, lng: 18.7891432 },
    { lat: 48.553033, lng: 18.7891496 },
    { lat: 48.5531159, lng: 18.7892878 },
    { lat: 48.5532097, lng: 18.7893144 },
    { lat: 48.5532323, lng: 18.7896584 },
    { lat: 48.5534118, lng: 18.7898555 },
    { lat: 48.5537162, lng: 18.7898965 },
    { lat: 48.5538414, lng: 18.7898107 },
    { lat: 48.5539176, lng: 18.7897582 },
    { lat: 48.5539835, lng: 18.7897888 },
    { lat: 48.5540401, lng: 18.7898137 },
    { lat: 48.5542202, lng: 18.7898959 },
    { lat: 48.5544186, lng: 18.7898491 },
    { lat: 48.5546368, lng: 18.7901377 },
    { lat: 48.5548113, lng: 18.7902832 },
    { lat: 48.5547905, lng: 18.7903835 },
    { lat: 48.5547443, lng: 18.7906183 },
    { lat: 48.5547257, lng: 18.7907148 },
    { lat: 48.5549216, lng: 18.7909211 },
    { lat: 48.5551148, lng: 18.7910187 },
    { lat: 48.5552356, lng: 18.7914924 },
    { lat: 48.5554346, lng: 18.7917171 },
    { lat: 48.5556761, lng: 18.7916997 },
    { lat: 48.5559025, lng: 18.7913706 },
    { lat: 48.5560367, lng: 18.7916949 },
    { lat: 48.5562221, lng: 18.7916799 },
    { lat: 48.5567187, lng: 18.7914296 },
    { lat: 48.557066, lng: 18.791496 },
    { lat: 48.5571345, lng: 18.7913821 },
    { lat: 48.5572145, lng: 18.7912498 },
    { lat: 48.557338, lng: 18.7910446 },
    { lat: 48.5574034, lng: 18.7909813 },
    { lat: 48.5575664, lng: 18.7908223 },
    { lat: 48.5577613, lng: 18.7908628 },
    { lat: 48.5577731, lng: 18.7907454 },
    { lat: 48.5578123, lng: 18.7903523 },
    { lat: 48.5578456, lng: 18.7904288 },
    { lat: 48.55791, lng: 18.7904941 },
    { lat: 48.55799, lng: 18.7905554 },
    { lat: 48.5580428, lng: 18.7905979 },
    { lat: 48.5580818, lng: 18.7906295 },
    { lat: 48.558306, lng: 18.7907545 },
    { lat: 48.5583837, lng: 18.7906673 },
    { lat: 48.5584916, lng: 18.7904233 },
    { lat: 48.5586607, lng: 18.7903986 },
    { lat: 48.5587291, lng: 18.7904139 },
    { lat: 48.5588256, lng: 18.7904925 },
    { lat: 48.5589439, lng: 18.7907471 },
    { lat: 48.5590146, lng: 18.790869 },
    { lat: 48.5591006, lng: 18.7909577 },
    { lat: 48.5592145, lng: 18.7910666 },
    { lat: 48.5592741, lng: 18.7911478 },
    { lat: 48.5593153, lng: 18.7913113 },
    { lat: 48.5593324, lng: 18.7914039 },
    { lat: 48.5593576, lng: 18.791496 },
    { lat: 48.5594539, lng: 18.791566 },
    { lat: 48.5595487, lng: 18.7916744 },
    { lat: 48.5595912, lng: 18.7917562 },
    { lat: 48.5596494, lng: 18.7919194 },
    { lat: 48.5597121, lng: 18.7920828 },
    { lat: 48.5599045, lng: 18.7921368 },
    { lat: 48.5601047, lng: 18.7923222 },
    { lat: 48.560592, lng: 18.7927555 },
    { lat: 48.5609834, lng: 18.7931442 },
    { lat: 48.5612326, lng: 18.793592 },
    { lat: 48.561461, lng: 18.7938204 },
    { lat: 48.562285, lng: 18.7949533 },
    { lat: 48.5623424, lng: 18.79508 },
    { lat: 48.5623719, lng: 18.7951459 },
    { lat: 48.5623938, lng: 18.7951771 },
    { lat: 48.5624291, lng: 18.7952288 },
    { lat: 48.5625755, lng: 18.7949874 },
    { lat: 48.5628091, lng: 18.7946014 },
    { lat: 48.5630069, lng: 18.7947822 },
    { lat: 48.5632544, lng: 18.7950084 },
    { lat: 48.5632871, lng: 18.7949433 },
    { lat: 48.5633299, lng: 18.7948588 },
    { lat: 48.5633801, lng: 18.7947606 },
    { lat: 48.5634464, lng: 18.7946295 },
    { lat: 48.5634138, lng: 18.7943955 },
    { lat: 48.5633567, lng: 18.7939918 },
    { lat: 48.5635721, lng: 18.7937989 },
    { lat: 48.5636852, lng: 18.7934828 },
    { lat: 48.5638445, lng: 18.7932085 },
    { lat: 48.5638749, lng: 18.7931444 },
    { lat: 48.5639233, lng: 18.7930749 },
    { lat: 48.5639476, lng: 18.793088 },
    { lat: 48.5640158, lng: 18.7931226 },
    { lat: 48.5640917, lng: 18.7930206 },
    { lat: 48.5641563, lng: 18.79281 },
    { lat: 48.5642341, lng: 18.7927252 },
    { lat: 48.5642355, lng: 18.7925816 },
    { lat: 48.5641863, lng: 18.7923514 },
    { lat: 48.5644158, lng: 18.7921044 },
    { lat: 48.5645707, lng: 18.7920065 },
    { lat: 48.5646311, lng: 18.7917401 },
    { lat: 48.56483, lng: 18.7915078 },
    { lat: 48.5649465, lng: 18.7913472 },
    { lat: 48.5650876, lng: 18.7914543 },
    { lat: 48.5651486, lng: 18.7915008 },
    { lat: 48.565312, lng: 18.7913279 },
    { lat: 48.5653549, lng: 18.791326 },
    { lat: 48.5654978, lng: 18.79132 },
    { lat: 48.5655925, lng: 18.7911916 },
    { lat: 48.5656558, lng: 18.7909583 },
    { lat: 48.5657023, lng: 18.7909844 },
    { lat: 48.5658336, lng: 18.7910581 },
    { lat: 48.5659109, lng: 18.79105 },
    { lat: 48.5659423, lng: 18.7910228 },
    { lat: 48.5660448, lng: 18.7909354 },
    { lat: 48.5661213, lng: 18.7906507 },
    { lat: 48.5662854, lng: 18.7907063 },
    { lat: 48.5664416, lng: 18.7906759 },
    { lat: 48.5665684, lng: 18.7906195 },
    { lat: 48.5666251, lng: 18.7904301 },
    { lat: 48.5666264, lng: 18.7902427 },
    { lat: 48.5666262, lng: 18.7901099 },
    { lat: 48.5667519, lng: 18.789862 },
    { lat: 48.5668892, lng: 18.7898969 },
    { lat: 48.5669746, lng: 18.7898088 },
    { lat: 48.5669783, lng: 18.7895773 },
    { lat: 48.5669706, lng: 18.7894532 },
    { lat: 48.567027, lng: 18.7894294 },
    { lat: 48.5671498, lng: 18.789513 },
    { lat: 48.5672177, lng: 18.7894967 },
    { lat: 48.5672784, lng: 18.7894514 },
    { lat: 48.5673873, lng: 18.7894185 },
    { lat: 48.5674355, lng: 18.7892657 },
    { lat: 48.5676196, lng: 18.7892031 },
    { lat: 48.5676572, lng: 18.7891908 },
    { lat: 48.567704, lng: 18.7890286 },
    { lat: 48.5677376, lng: 18.788895 },
    { lat: 48.5678123, lng: 18.788728 },
    { lat: 48.5679013, lng: 18.7888356 },
    { lat: 48.5679904, lng: 18.7889424 },
    { lat: 48.5681334, lng: 18.7888303 },
    { lat: 48.5683295, lng: 18.788677 },
    { lat: 48.5683559, lng: 18.7889459 },
    { lat: 48.5685202, lng: 18.7889836 },
    { lat: 48.5685556, lng: 18.7889915 },
    { lat: 48.5687221, lng: 18.7891108 },
    { lat: 48.5688456, lng: 18.7891641 },
    { lat: 48.5689584, lng: 18.7893177 },
    { lat: 48.5691483, lng: 18.78896 },
    { lat: 48.5691874, lng: 18.7888862 },
    { lat: 48.5692057, lng: 18.7888524 },
    { lat: 48.5692263, lng: 18.7888125 },
    { lat: 48.5691597, lng: 18.788552 },
    { lat: 48.5689216, lng: 18.7884864 },
    { lat: 48.5689632, lng: 18.7882895 },
    { lat: 48.568994, lng: 18.788147 },
    { lat: 48.5689159, lng: 18.7879512 },
    { lat: 48.5690699, lng: 18.7879627 },
    { lat: 48.569104, lng: 18.7879651 },
    { lat: 48.5692504, lng: 18.7877051 },
    { lat: 48.5693332, lng: 18.7877127 },
    { lat: 48.5693156, lng: 18.7878711 },
    { lat: 48.5693027, lng: 18.7879909 },
    { lat: 48.5694297, lng: 18.7881146 },
    { lat: 48.5694489, lng: 18.7881333 },
    { lat: 48.569638, lng: 18.7879142 },
    { lat: 48.569876, lng: 18.7879146 },
    { lat: 48.5699326, lng: 18.788024 },
    { lat: 48.5700341, lng: 18.7882205 },
    { lat: 48.570146, lng: 18.7881367 },
    { lat: 48.5700408, lng: 18.7875521 },
    { lat: 48.5699463, lng: 18.7872771 },
    { lat: 48.569889, lng: 18.7871094 },
    { lat: 48.5700406, lng: 18.7871839 },
    { lat: 48.5700928, lng: 18.7872101 },
    { lat: 48.570181, lng: 18.7869589 },
    { lat: 48.5703141, lng: 18.7868921 },
    { lat: 48.570493, lng: 18.7870997 },
    { lat: 48.5706038, lng: 18.7867212 },
    { lat: 48.5706317, lng: 18.7866263 },
    { lat: 48.570683, lng: 18.7864479 },
    { lat: 48.5707401, lng: 18.7860323 },
    { lat: 48.5707779, lng: 18.7857628 },
    { lat: 48.5710293, lng: 18.7856947 },
    { lat: 48.5710402, lng: 18.7860958 },
    { lat: 48.571218, lng: 18.7864567 },
    { lat: 48.57132, lng: 18.7863163 },
    { lat: 48.5714083, lng: 18.7861925 },
    { lat: 48.571688, lng: 18.7860417 },
    { lat: 48.5718371, lng: 18.7859612 },
    { lat: 48.5719918, lng: 18.785878 },
    { lat: 48.5721482, lng: 18.7852935 },
    { lat: 48.5722387, lng: 18.7849551 },
    { lat: 48.5722541, lng: 18.78471 },
    { lat: 48.5725758, lng: 18.7848678 },
    { lat: 48.5728866, lng: 18.7846855 },
    { lat: 48.5734948, lng: 18.7843276 },
    { lat: 48.5735162, lng: 18.7842628 },
    { lat: 48.5735822, lng: 18.7840619 },
  ],
  Pitelová: [
    { lat: 48.5861546, lng: 18.9241867 },
    { lat: 48.5859448, lng: 18.9246008 },
    { lat: 48.5859419, lng: 18.9246083 },
    { lat: 48.5859058, lng: 18.9246737 },
    { lat: 48.5859039, lng: 18.9246828 },
    { lat: 48.5855292, lng: 18.9262123 },
    { lat: 48.5855209, lng: 18.9263362 },
    { lat: 48.5855112, lng: 18.9264149 },
    { lat: 48.5854449, lng: 18.9270103 },
    { lat: 48.5850613, lng: 18.9304361 },
    { lat: 48.5850478, lng: 18.9305228 },
    { lat: 48.584847, lng: 18.9325715 },
    { lat: 48.5848305, lng: 18.9327105 },
    { lat: 48.5843526, lng: 18.936785 },
    { lat: 48.5835194, lng: 18.939848 },
    { lat: 48.5836735, lng: 18.9407856 },
    { lat: 48.5835665, lng: 18.9408554 },
    { lat: 48.5835223, lng: 18.940874 },
    { lat: 48.5827987, lng: 18.941097 },
    { lat: 48.5825169, lng: 18.9411693 },
    { lat: 48.5824867, lng: 18.9411735 },
    { lat: 48.5824444, lng: 18.9411824 },
    { lat: 48.5822657, lng: 18.9411964 },
    { lat: 48.5822321, lng: 18.9411992 },
    { lat: 48.5809261, lng: 18.9413033 },
    { lat: 48.5805802, lng: 18.942588 },
    { lat: 48.5808526, lng: 18.9447567 },
    { lat: 48.5809727, lng: 18.9453232 },
    { lat: 48.5810308, lng: 18.9453136 },
    { lat: 48.5816656, lng: 18.9454632 },
    { lat: 48.5831269, lng: 18.9452464 },
    { lat: 48.5836816, lng: 18.9455306 },
    { lat: 48.5837187, lng: 18.9455502 },
    { lat: 48.5842234, lng: 18.9458085 },
    { lat: 48.5842521, lng: 18.9457742 },
    { lat: 48.584336, lng: 18.9456252 },
    { lat: 48.5844124, lng: 18.9454896 },
    { lat: 48.5844456, lng: 18.9454316 },
    { lat: 48.5844486, lng: 18.9454259 },
    { lat: 48.5847306, lng: 18.9449665 },
    { lat: 48.584764, lng: 18.9449124 },
    { lat: 48.5856534, lng: 18.9457215 },
    { lat: 48.5857525, lng: 18.9458118 },
    { lat: 48.5859834, lng: 18.9461785 },
    { lat: 48.5865033, lng: 18.9471958 },
    { lat: 48.5868996, lng: 18.9479561 },
    { lat: 48.5869909, lng: 18.9485303 },
    { lat: 48.5871467, lng: 18.9490831 },
    { lat: 48.5874104, lng: 18.9497455 },
    { lat: 48.587421, lng: 18.9497767 },
    { lat: 48.5874962, lng: 18.9500134 },
    { lat: 48.5875045, lng: 18.9500413 },
    { lat: 48.5877156, lng: 18.9500253 },
    { lat: 48.5879075, lng: 18.9498976 },
    { lat: 48.5879338, lng: 18.9498807 },
    { lat: 48.587937, lng: 18.9498785 },
    { lat: 48.5879716, lng: 18.9498467 },
    { lat: 48.5879747, lng: 18.9498442 },
    { lat: 48.5880159, lng: 18.9498305 },
    { lat: 48.5880247, lng: 18.9498274 },
    { lat: 48.5880321, lng: 18.9498201 },
    { lat: 48.5880988, lng: 18.9498038 },
    { lat: 48.5881085, lng: 18.9497938 },
    { lat: 48.5881675, lng: 18.9497563 },
    { lat: 48.5882416, lng: 18.949684 },
    { lat: 48.5882535, lng: 18.9496726 },
    { lat: 48.5882774, lng: 18.949678 },
    { lat: 48.5883503, lng: 18.9495834 },
    { lat: 48.5883632, lng: 18.9495673 },
    { lat: 48.5883908, lng: 18.9495345 },
    { lat: 48.5884295, lng: 18.9494773 },
    { lat: 48.588461, lng: 18.9494516 },
    { lat: 48.5884842, lng: 18.9494207 },
    { lat: 48.5885908, lng: 18.949236 },
    { lat: 48.5886802, lng: 18.949107 },
    { lat: 48.5888282, lng: 18.9489616 },
    { lat: 48.5889873, lng: 18.948853 },
    { lat: 48.5891925, lng: 18.9487202 },
    { lat: 48.5893219, lng: 18.9486276 },
    { lat: 48.5894735, lng: 18.9485224 },
    { lat: 48.589595, lng: 18.9484555 },
    { lat: 48.5899427, lng: 18.948329 },
    { lat: 48.590075, lng: 18.9482039 },
    { lat: 48.5901556, lng: 18.9480599 },
    { lat: 48.5903465, lng: 18.9478704 },
    { lat: 48.5905516, lng: 18.9477557 },
    { lat: 48.5907131, lng: 18.9475179 },
    { lat: 48.590795, lng: 18.9472246 },
    { lat: 48.5908018, lng: 18.947181 },
    { lat: 48.590814, lng: 18.9471116 },
    { lat: 48.590825, lng: 18.9470815 },
    { lat: 48.5909194, lng: 18.9468978 },
    { lat: 48.5909655, lng: 18.9467888 },
    { lat: 48.5909795, lng: 18.9467761 },
    { lat: 48.5909953, lng: 18.9467525 },
    { lat: 48.5910944, lng: 18.9465971 },
    { lat: 48.5911769, lng: 18.9464689 },
    { lat: 48.5918132, lng: 18.9459455 },
    { lat: 48.5920456, lng: 18.9459709 },
    { lat: 48.5921687, lng: 18.9459067 },
    { lat: 48.5924499, lng: 18.9462263 },
    { lat: 48.5929404, lng: 18.9462043 },
    { lat: 48.5935154, lng: 18.9456046 },
    { lat: 48.5936775, lng: 18.9456018 },
    { lat: 48.5939873, lng: 18.9457322 },
    { lat: 48.5945428, lng: 18.9454368 },
    { lat: 48.5945848, lng: 18.9455089 },
    { lat: 48.5947303, lng: 18.9455301 },
    { lat: 48.5950494, lng: 18.945576 },
    { lat: 48.59563, lng: 18.9456592 },
    { lat: 48.5956927, lng: 18.945652 },
    { lat: 48.597087, lng: 18.9454857 },
    { lat: 48.5978999, lng: 18.9448922 },
    { lat: 48.5984008, lng: 18.9449426 },
    { lat: 48.5989508, lng: 18.9452663 },
    { lat: 48.5992065, lng: 18.9453271 },
    { lat: 48.5997306, lng: 18.9452356 },
    { lat: 48.6004757, lng: 18.945455 },
    { lat: 48.6005157, lng: 18.9454667 },
    { lat: 48.6008021, lng: 18.9454434 },
    { lat: 48.6012546, lng: 18.9457346 },
    { lat: 48.6013446, lng: 18.9457078 },
    { lat: 48.6016683, lng: 18.9452263 },
    { lat: 48.6023023, lng: 18.9456539 },
    { lat: 48.6024321, lng: 18.9458069 },
    { lat: 48.6028758, lng: 18.9457108 },
    { lat: 48.6030009, lng: 18.9456842 },
    { lat: 48.603194, lng: 18.945427 },
    { lat: 48.6037382, lng: 18.9452896 },
    { lat: 48.6038738, lng: 18.9454688 },
    { lat: 48.6039334, lng: 18.9454665 },
    { lat: 48.6039689, lng: 18.9455454 },
    { lat: 48.6039934, lng: 18.9455806 },
    { lat: 48.604276, lng: 18.9457284 },
    { lat: 48.6048597, lng: 18.9458515 },
    { lat: 48.6058468, lng: 18.946128 },
    { lat: 48.6061214, lng: 18.9469989 },
    { lat: 48.6068626, lng: 18.947604 },
    { lat: 48.6077229, lng: 18.9479135 },
    { lat: 48.6083905, lng: 18.9482905 },
    { lat: 48.6088887, lng: 18.9492567 },
    { lat: 48.6094441, lng: 18.949935 },
    { lat: 48.6095182, lng: 18.9499706 },
    { lat: 48.6095402, lng: 18.9499848 },
    { lat: 48.6102743, lng: 18.9503588 },
    { lat: 48.6105463, lng: 18.9508499 },
    { lat: 48.6111453, lng: 18.9514531 },
    { lat: 48.6116144, lng: 18.9517845 },
    { lat: 48.6116386, lng: 18.9517714 },
    { lat: 48.6117754, lng: 18.9516921 },
    { lat: 48.6118501, lng: 18.9515641 },
    { lat: 48.6118793, lng: 18.9514741 },
    { lat: 48.6119195, lng: 18.9513785 },
    { lat: 48.6119517, lng: 18.9513003 },
    { lat: 48.6120187, lng: 18.9512238 },
    { lat: 48.612095, lng: 18.9511729 },
    { lat: 48.6122887, lng: 18.951098 },
    { lat: 48.6124136, lng: 18.95105 },
    { lat: 48.6125716, lng: 18.9510762 },
    { lat: 48.6125974, lng: 18.9510967 },
    { lat: 48.6127357, lng: 18.9512218 },
    { lat: 48.6128565, lng: 18.9513578 },
    { lat: 48.6130716, lng: 18.9514621 },
    { lat: 48.6131323, lng: 18.951526 },
    { lat: 48.6131625, lng: 18.9515396 },
    { lat: 48.6134397, lng: 18.9520914 },
    { lat: 48.6134497, lng: 18.9521117 },
    { lat: 48.6138499, lng: 18.9521974 },
    { lat: 48.6149528, lng: 18.9518032 },
    { lat: 48.6153315, lng: 18.9516687 },
    { lat: 48.6153694, lng: 18.9516575 },
    { lat: 48.6155697, lng: 18.9515869 },
    { lat: 48.6160566, lng: 18.9518446 },
    { lat: 48.6171609, lng: 18.9522738 },
    { lat: 48.6173425, lng: 18.952253 },
    { lat: 48.6173453, lng: 18.9520214 },
    { lat: 48.61735, lng: 18.9517152 },
    { lat: 48.6173833, lng: 18.9515955 },
    { lat: 48.6173762, lng: 18.9514783 },
    { lat: 48.6173923, lng: 18.9513974 },
    { lat: 48.6173725, lng: 18.9511841 },
    { lat: 48.6174083, lng: 18.9510028 },
    { lat: 48.6173783, lng: 18.9509012 },
    { lat: 48.6173646, lng: 18.9507659 },
    { lat: 48.6173991, lng: 18.9494755 },
    { lat: 48.6174531, lng: 18.9490298 },
    { lat: 48.6174835, lng: 18.9485652 },
    { lat: 48.6174493, lng: 18.9476779 },
    { lat: 48.6174281, lng: 18.9474797 },
    { lat: 48.6173915, lng: 18.9471429 },
    { lat: 48.6174403, lng: 18.9467471 },
    { lat: 48.6173976, lng: 18.9462214 },
    { lat: 48.6171411, lng: 18.9452855 },
    { lat: 48.6171605, lng: 18.9449575 },
    { lat: 48.6173697, lng: 18.9440894 },
    { lat: 48.617382, lng: 18.9435128 },
    { lat: 48.6175758, lng: 18.9432683 },
    { lat: 48.6178288, lng: 18.9428151 },
    { lat: 48.6178892, lng: 18.9425394 },
    { lat: 48.6179795, lng: 18.9425179 },
    { lat: 48.6181329, lng: 18.9422331 },
    { lat: 48.6181043, lng: 18.942024 },
    { lat: 48.6180004, lng: 18.9418918 },
    { lat: 48.61785, lng: 18.9414182 },
    { lat: 48.6177265, lng: 18.9412754 },
    { lat: 48.6177031, lng: 18.9405095 },
    { lat: 48.6176378, lng: 18.9403546 },
    { lat: 48.6174706, lng: 18.9402172 },
    { lat: 48.6174083, lng: 18.9397951 },
    { lat: 48.6172647, lng: 18.9393649 },
    { lat: 48.6170209, lng: 18.9391399 },
    { lat: 48.6169118, lng: 18.9387941 },
    { lat: 48.6167251, lng: 18.9383822 },
    { lat: 48.6168156, lng: 18.9380605 },
    { lat: 48.6168061, lng: 18.93781 },
    { lat: 48.6167706, lng: 18.9375544 },
    { lat: 48.6166803, lng: 18.9372905 },
    { lat: 48.6163726, lng: 18.9370252 },
    { lat: 48.6163466, lng: 18.9368504 },
    { lat: 48.6163697, lng: 18.9362448 },
    { lat: 48.6163136, lng: 18.9356747 },
    { lat: 48.6163059, lng: 18.9350171 },
    { lat: 48.6159361, lng: 18.9338952 },
    { lat: 48.615853, lng: 18.9323181 },
    { lat: 48.6158962, lng: 18.9323027 },
    { lat: 48.6160214, lng: 18.9319054 },
    { lat: 48.6160334, lng: 18.9318766 },
    { lat: 48.6161703, lng: 18.9314448 },
    { lat: 48.6165617, lng: 18.9300828 },
    { lat: 48.6167881, lng: 18.9293512 },
    { lat: 48.6168328, lng: 18.929259 },
    { lat: 48.616857, lng: 18.9292245 },
    { lat: 48.6170585, lng: 18.9288628 },
    { lat: 48.6175336, lng: 18.9276411 },
    { lat: 48.6175922, lng: 18.927419 },
    { lat: 48.6176152, lng: 18.927339 },
    { lat: 48.617748, lng: 18.9268499 },
    { lat: 48.6178812, lng: 18.9264149 },
    { lat: 48.6179879, lng: 18.9261185 },
    { lat: 48.6181228, lng: 18.9258276 },
    { lat: 48.618368, lng: 18.9254387 },
    { lat: 48.6186675, lng: 18.9249908 },
    { lat: 48.6186745, lng: 18.924842 },
    { lat: 48.6188563, lng: 18.9244103 },
    { lat: 48.6193009, lng: 18.9236671 },
    { lat: 48.6197481, lng: 18.9228448 },
    { lat: 48.6202882, lng: 18.9217906 },
    { lat: 48.6197759, lng: 18.9212788 },
    { lat: 48.6195099, lng: 18.92139 },
    { lat: 48.6193901, lng: 18.9214079 },
    { lat: 48.6191231, lng: 18.9212562 },
    { lat: 48.6189702, lng: 18.9210895 },
    { lat: 48.6188744, lng: 18.9208063 },
    { lat: 48.618816, lng: 18.920456 },
    { lat: 48.6187873, lng: 18.9200075 },
    { lat: 48.6196067, lng: 18.9191866 },
    { lat: 48.6194455, lng: 18.9171238 },
    { lat: 48.6203607, lng: 18.9152717 },
    { lat: 48.6204687, lng: 18.9149184 },
    { lat: 48.6205247, lng: 18.9146474 },
    { lat: 48.6205681, lng: 18.9143052 },
    { lat: 48.6206515, lng: 18.9137334 },
    { lat: 48.6205775, lng: 18.9124277 },
    { lat: 48.620515, lng: 18.9120205 },
    { lat: 48.6205312, lng: 18.9117011 },
    { lat: 48.6209411, lng: 18.9111859 },
    { lat: 48.6211319, lng: 18.9100713 },
    { lat: 48.6211927, lng: 18.9097402 },
    { lat: 48.6210647, lng: 18.9090705 },
    { lat: 48.6208605, lng: 18.9093553 },
    { lat: 48.6207075, lng: 18.9094035 },
    { lat: 48.6202789, lng: 18.909444 },
    { lat: 48.620121, lng: 18.909357 },
    { lat: 48.6200477, lng: 18.9092914 },
    { lat: 48.6197737, lng: 18.9089428 },
    { lat: 48.6193155, lng: 18.9084501 },
    { lat: 48.6191171, lng: 18.9081444 },
    { lat: 48.6181654, lng: 18.9068277 },
    { lat: 48.617623, lng: 18.9063698 },
    { lat: 48.6168259, lng: 18.9057564 },
    { lat: 48.6159907, lng: 18.905739 },
    { lat: 48.6157984, lng: 18.9058813 },
    { lat: 48.6153245, lng: 18.9063387 },
    { lat: 48.6151842, lng: 18.9064994 },
    { lat: 48.6148845, lng: 18.9069261 },
    { lat: 48.614635, lng: 18.9074218 },
    { lat: 48.6144184, lng: 18.907791 },
    { lat: 48.6143079, lng: 18.9080124 },
    { lat: 48.6141778, lng: 18.9084641 },
    { lat: 48.6141771, lng: 18.9089527 },
    { lat: 48.614299, lng: 18.9107537 },
    { lat: 48.6142341, lng: 18.9111365 },
    { lat: 48.6138806, lng: 18.9117844 },
    { lat: 48.6135999, lng: 18.9122259 },
    { lat: 48.613212, lng: 18.9127733 },
    { lat: 48.6130424, lng: 18.9130518 },
    { lat: 48.6127492, lng: 18.9135992 },
    { lat: 48.6126307, lng: 18.9134214 },
    { lat: 48.612383, lng: 18.9131848 },
    { lat: 48.6122028, lng: 18.9130805 },
    { lat: 48.6119872, lng: 18.9127472 },
    { lat: 48.6117315, lng: 18.9124906 },
    { lat: 48.6114378, lng: 18.9122374 },
    { lat: 48.6111526, lng: 18.9120847 },
    { lat: 48.6102642, lng: 18.911736 },
    { lat: 48.6099591, lng: 18.9116804 },
    { lat: 48.6093822, lng: 18.9116214 },
    { lat: 48.6088428, lng: 18.9116132 },
    { lat: 48.6085942, lng: 18.9114049 },
    { lat: 48.6083292, lng: 18.911142 },
    { lat: 48.6082072, lng: 18.9109834 },
    { lat: 48.6078462, lng: 18.9107416 },
    { lat: 48.6070822, lng: 18.9099388 },
    { lat: 48.6063741, lng: 18.9086442 },
    { lat: 48.6059539, lng: 18.9075771 },
    { lat: 48.6057409, lng: 18.9072492 },
    { lat: 48.6054973, lng: 18.9070504 },
    { lat: 48.6050906, lng: 18.906839 },
    { lat: 48.6050192, lng: 18.9067226 },
    { lat: 48.6046935, lng: 18.9061786 },
    { lat: 48.604661, lng: 18.9057214 },
    { lat: 48.6046735, lng: 18.9052628 },
    { lat: 48.6045644, lng: 18.9045024 },
    { lat: 48.6044061, lng: 18.904144 },
    { lat: 48.6040916, lng: 18.9038812 },
    { lat: 48.6040368, lng: 18.9038459 },
    { lat: 48.6038995, lng: 18.9035698 },
    { lat: 48.6035026, lng: 18.9029978 },
    { lat: 48.6032478, lng: 18.9027387 },
    { lat: 48.6023847, lng: 18.9021806 },
    { lat: 48.6022735, lng: 18.9020576 },
    { lat: 48.6021482, lng: 18.9018588 },
    { lat: 48.6020034, lng: 18.9015434 },
    { lat: 48.6017662, lng: 18.9009206 },
    { lat: 48.6015549, lng: 18.9002988 },
    { lat: 48.6014155, lng: 18.899956 },
    { lat: 48.6011947, lng: 18.8991496 },
    { lat: 48.6010643, lng: 18.8985917 },
    { lat: 48.6010531, lng: 18.8981772 },
    { lat: 48.6010746, lng: 18.8977825 },
    { lat: 48.6010579, lng: 18.8977652 },
    { lat: 48.6009049, lng: 18.8975989 },
    { lat: 48.6007999, lng: 18.8977883 },
    { lat: 48.600589, lng: 18.8984464 },
    { lat: 48.6005545, lng: 18.8986767 },
    { lat: 48.6005348, lng: 18.8990598 },
    { lat: 48.6005345, lng: 18.8998839 },
    { lat: 48.6004637, lng: 18.9003727 },
    { lat: 48.6004531, lng: 18.9006893 },
    { lat: 48.6004959, lng: 18.9010615 },
    { lat: 48.6004492, lng: 18.90127 },
    { lat: 48.6000706, lng: 18.9022406 },
    { lat: 48.5995304, lng: 18.9028721 },
    { lat: 48.5993043, lng: 18.9036498 },
    { lat: 48.5990257, lng: 18.9046763 },
    { lat: 48.5975735, lng: 18.9055997 },
    { lat: 48.5972202, lng: 18.9056356 },
    { lat: 48.5969893, lng: 18.9056203 },
    { lat: 48.5961163, lng: 18.9054834 },
    { lat: 48.5955349, lng: 18.9053391 },
    { lat: 48.5948999, lng: 18.9054465 },
    { lat: 48.5942991, lng: 18.9055121 },
    { lat: 48.5940492, lng: 18.9055869 },
    { lat: 48.5937931, lng: 18.9057163 },
    { lat: 48.5936308, lng: 18.9058268 },
    { lat: 48.5935158, lng: 18.9059582 },
    { lat: 48.5933227, lng: 18.9062187 },
    { lat: 48.5931359, lng: 18.9065547 },
    { lat: 48.5928858, lng: 18.9071271 },
    { lat: 48.5925315, lng: 18.9072309 },
    { lat: 48.5914257, lng: 18.9069324 },
    { lat: 48.5911953, lng: 18.9068169 },
    { lat: 48.5909153, lng: 18.9066338 },
    { lat: 48.5906425, lng: 18.9064612 },
    { lat: 48.5902846, lng: 18.9064945 },
    { lat: 48.5896581, lng: 18.9064147 },
    { lat: 48.5893278, lng: 18.9063724 },
    { lat: 48.5892926, lng: 18.9063912 },
    { lat: 48.5890249, lng: 18.906535 },
    { lat: 48.5887017, lng: 18.906696 },
    { lat: 48.588679, lng: 18.9066901 },
    { lat: 48.5885579, lng: 18.9067698 },
    { lat: 48.5885021, lng: 18.9068073 },
    { lat: 48.5885142, lng: 18.9068622 },
    { lat: 48.5885047, lng: 18.9068982 },
    { lat: 48.5884305, lng: 18.9070369 },
    { lat: 48.588153, lng: 18.9071224 },
    { lat: 48.587859, lng: 18.9072131 },
    { lat: 48.5880336, lng: 18.9075449 },
    { lat: 48.5885839, lng: 18.9085911 },
    { lat: 48.5890926, lng: 18.9095581 },
    { lat: 48.5905301, lng: 18.9124237 },
    { lat: 48.5913051, lng: 18.9139687 },
    { lat: 48.5911906, lng: 18.9144853 },
    { lat: 48.5904147, lng: 18.9152259 },
    { lat: 48.5902346, lng: 18.9153869 },
    { lat: 48.5884566, lng: 18.9172133 },
    { lat: 48.5875234, lng: 18.9195471 },
    { lat: 48.5872303, lng: 18.9210444 },
    { lat: 48.5872276, lng: 18.9210575 },
    { lat: 48.5872087, lng: 18.9211726 },
    { lat: 48.5872061, lng: 18.9211857 },
    { lat: 48.5867709, lng: 18.9230325 },
    { lat: 48.5861546, lng: 18.9241867 },
  ],
  Lovča: [
    { lat: 48.5925259, lng: 18.8067577 },
    { lat: 48.5925256, lng: 18.8067299 },
    { lat: 48.5925252, lng: 18.8067 },
    { lat: 48.5925227, lng: 18.8065888 },
    { lat: 48.5925169, lng: 18.8062839 },
    { lat: 48.5925089, lng: 18.8059136 },
    { lat: 48.5924887, lng: 18.804945 },
    { lat: 48.5924671, lng: 18.8039327 },
    { lat: 48.5923838, lng: 18.8020628 },
    { lat: 48.5922546, lng: 18.8008452 },
    { lat: 48.5922095, lng: 18.799768 },
    { lat: 48.5921839, lng: 18.7991267 },
    { lat: 48.5921789, lng: 18.7990126 },
    { lat: 48.5920424, lng: 18.7990965 },
    { lat: 48.5920332, lng: 18.799073 },
    { lat: 48.5916957, lng: 18.7982241 },
    { lat: 48.5909709, lng: 18.7963391 },
    { lat: 48.5909266, lng: 18.7962243 },
    { lat: 48.590727, lng: 18.7957077 },
    { lat: 48.590363, lng: 18.794769 },
    { lat: 48.5900928, lng: 18.7939784 },
    { lat: 48.5894932, lng: 18.7922243 },
    { lat: 48.5894466, lng: 18.7920891 },
    { lat: 48.5893801, lng: 18.7921768 },
    { lat: 48.5890503, lng: 18.7926151 },
    { lat: 48.5890435, lng: 18.7927253 },
    { lat: 48.5890265, lng: 18.793045 },
    { lat: 48.5890016, lng: 18.7930655 },
    { lat: 48.5884366, lng: 18.7935161 },
    { lat: 48.5882575, lng: 18.7936072 },
    { lat: 48.5881788, lng: 18.7936113 },
    { lat: 48.588117, lng: 18.7936144 },
    { lat: 48.5880496, lng: 18.7936178 },
    { lat: 48.5880416, lng: 18.793618 },
    { lat: 48.5878549, lng: 18.7936271 },
    { lat: 48.5874838, lng: 18.7934943 },
    { lat: 48.5870801, lng: 18.7931723 },
    { lat: 48.5869015, lng: 18.7932495 },
    { lat: 48.5867079, lng: 18.7932584 },
    { lat: 48.58663, lng: 18.7932559 },
    { lat: 48.5863543, lng: 18.7932478 },
    { lat: 48.5861869, lng: 18.7935157 },
    { lat: 48.5861947, lng: 18.793966 },
    { lat: 48.5861963, lng: 18.7941044 },
    { lat: 48.5861978, lng: 18.7941737 },
    { lat: 48.5860409, lng: 18.7943858 },
    { lat: 48.5857639, lng: 18.7943757 },
    { lat: 48.5857515, lng: 18.7944188 },
    { lat: 48.5856539, lng: 18.7947471 },
    { lat: 48.58547, lng: 18.7948556 },
    { lat: 48.585399, lng: 18.7948982 },
    { lat: 48.5853202, lng: 18.7949447 },
    { lat: 48.5852894, lng: 18.7949626 },
    { lat: 48.5849938, lng: 18.7947869 },
    { lat: 48.584796, lng: 18.7946698 },
    { lat: 48.5847436, lng: 18.7946397 },
    { lat: 48.5842851, lng: 18.794367 },
    { lat: 48.5842397, lng: 18.7943404 },
    { lat: 48.5842417, lng: 18.7942931 },
    { lat: 48.5842463, lng: 18.794163 },
    { lat: 48.5842586, lng: 18.7938388 },
    { lat: 48.5840995, lng: 18.7937071 },
    { lat: 48.5837764, lng: 18.7934411 },
    { lat: 48.5836042, lng: 18.7932985 },
    { lat: 48.583413, lng: 18.7939093 },
    { lat: 48.5832835, lng: 18.7938254 },
    { lat: 48.5831037, lng: 18.7937086 },
    { lat: 48.583057, lng: 18.7936785 },
    { lat: 48.5829846, lng: 18.7936309 },
    { lat: 48.5828666, lng: 18.7935541 },
    { lat: 48.5824929, lng: 18.7939303 },
    { lat: 48.5824134, lng: 18.7940099 },
    { lat: 48.5822669, lng: 18.7941577 },
    { lat: 48.5822761, lng: 18.7944864 },
    { lat: 48.5822992, lng: 18.795228 },
    { lat: 48.5816156, lng: 18.7952657 },
    { lat: 48.5814973, lng: 18.7944927 },
    { lat: 48.581021, lng: 18.7944454 },
    { lat: 48.5808137, lng: 18.7944245 },
    { lat: 48.5807022, lng: 18.7939357 },
    { lat: 48.5806733, lng: 18.7938123 },
    { lat: 48.580549, lng: 18.7939561 },
    { lat: 48.5801883, lng: 18.7943698 },
    { lat: 48.5796932, lng: 18.7942197 },
    { lat: 48.5796635, lng: 18.7941275 },
    { lat: 48.5795437, lng: 18.7937525 },
    { lat: 48.5794452, lng: 18.7939832 },
    { lat: 48.5792668, lng: 18.7939568 },
    { lat: 48.5791288, lng: 18.7937991 },
    { lat: 48.5790625, lng: 18.7933511 },
    { lat: 48.5789092, lng: 18.7932668 },
    { lat: 48.578592, lng: 18.7927697 },
    { lat: 48.5783738, lng: 18.7925657 },
    { lat: 48.5782683, lng: 18.7923784 },
    { lat: 48.5781684, lng: 18.7923266 },
    { lat: 48.5779385, lng: 18.7924975 },
    { lat: 48.5778059, lng: 18.7927213 },
    { lat: 48.5774868, lng: 18.7924645 },
    { lat: 48.5769465, lng: 18.7920752 },
    { lat: 48.5769093, lng: 18.7908521 },
    { lat: 48.5769059, lng: 18.7907656 },
    { lat: 48.5769029, lng: 18.7906676 },
    { lat: 48.5769006, lng: 18.7905796 },
    { lat: 48.5768981, lng: 18.7905083 },
    { lat: 48.5768969, lng: 18.7904639 },
    { lat: 48.576893, lng: 18.7903266 },
    { lat: 48.5770243, lng: 18.7890691 },
    { lat: 48.5768396, lng: 18.7875719 },
    { lat: 48.5768217, lng: 18.7874241 },
    { lat: 48.5767288, lng: 18.7866642 },
    { lat: 48.5767063, lng: 18.7865996 },
    { lat: 48.5766952, lng: 18.7865603 },
    { lat: 48.5764968, lng: 18.7858101 },
    { lat: 48.5764546, lng: 18.785651 },
    { lat: 48.5764512, lng: 18.7852661 },
    { lat: 48.5765434, lng: 18.7851319 },
    { lat: 48.5764403, lng: 18.7846723 },
    { lat: 48.5764965, lng: 18.7829708 },
    { lat: 48.5763816, lng: 18.783038 },
    { lat: 48.5762624, lng: 18.7831081 },
    { lat: 48.5762089, lng: 18.783101 },
    { lat: 48.5760489, lng: 18.7830791 },
    { lat: 48.5759773, lng: 18.7830691 },
    { lat: 48.5759625, lng: 18.7830673 },
    { lat: 48.5758818, lng: 18.7830556 },
    { lat: 48.5757887, lng: 18.7830429 },
    { lat: 48.5757658, lng: 18.783146 },
    { lat: 48.5757433, lng: 18.7832527 },
    { lat: 48.5756826, lng: 18.7835366 },
    { lat: 48.5754205, lng: 18.7836527 },
    { lat: 48.5753156, lng: 18.7836987 },
    { lat: 48.5752391, lng: 18.7836786 },
    { lat: 48.5748444, lng: 18.7835729 },
    { lat: 48.5747931, lng: 18.7835823 },
    { lat: 48.5747641, lng: 18.7835879 },
    { lat: 48.5746813, lng: 18.7836038 },
    { lat: 48.5745105, lng: 18.7836363 },
    { lat: 48.5743788, lng: 18.783662 },
    { lat: 48.5743237, lng: 18.7837921 },
    { lat: 48.5742484, lng: 18.7839688 },
    { lat: 48.5742106, lng: 18.783983 },
    { lat: 48.5738667, lng: 18.7841109 },
    { lat: 48.5737415, lng: 18.7840899 },
    { lat: 48.5736995, lng: 18.7840821 },
    { lat: 48.5735822, lng: 18.7840619 },
    { lat: 48.5735162, lng: 18.7842628 },
    { lat: 48.5734948, lng: 18.7843276 },
    { lat: 48.5728866, lng: 18.7846855 },
    { lat: 48.5725758, lng: 18.7848678 },
    { lat: 48.5722541, lng: 18.78471 },
    { lat: 48.5722387, lng: 18.7849551 },
    { lat: 48.5721482, lng: 18.7852935 },
    { lat: 48.5719918, lng: 18.785878 },
    { lat: 48.5718371, lng: 18.7859612 },
    { lat: 48.571688, lng: 18.7860417 },
    { lat: 48.5714083, lng: 18.7861925 },
    { lat: 48.57132, lng: 18.7863163 },
    { lat: 48.571218, lng: 18.7864567 },
    { lat: 48.5710402, lng: 18.7860958 },
    { lat: 48.5710293, lng: 18.7856947 },
    { lat: 48.5707779, lng: 18.7857628 },
    { lat: 48.5707401, lng: 18.7860323 },
    { lat: 48.570683, lng: 18.7864479 },
    { lat: 48.5706317, lng: 18.7866263 },
    { lat: 48.5706038, lng: 18.7867212 },
    { lat: 48.570493, lng: 18.7870997 },
    { lat: 48.5703141, lng: 18.7868921 },
    { lat: 48.570181, lng: 18.7869589 },
    { lat: 48.5700928, lng: 18.7872101 },
    { lat: 48.5700406, lng: 18.7871839 },
    { lat: 48.569889, lng: 18.7871094 },
    { lat: 48.5699463, lng: 18.7872771 },
    { lat: 48.5700408, lng: 18.7875521 },
    { lat: 48.570146, lng: 18.7881367 },
    { lat: 48.5700341, lng: 18.7882205 },
    { lat: 48.5699326, lng: 18.788024 },
    { lat: 48.569876, lng: 18.7879146 },
    { lat: 48.569638, lng: 18.7879142 },
    { lat: 48.5694489, lng: 18.7881333 },
    { lat: 48.5694297, lng: 18.7881146 },
    { lat: 48.5693027, lng: 18.7879909 },
    { lat: 48.5693156, lng: 18.7878711 },
    { lat: 48.5693332, lng: 18.7877127 },
    { lat: 48.5692504, lng: 18.7877051 },
    { lat: 48.569104, lng: 18.7879651 },
    { lat: 48.5690699, lng: 18.7879627 },
    { lat: 48.5689159, lng: 18.7879512 },
    { lat: 48.568994, lng: 18.788147 },
    { lat: 48.5689632, lng: 18.7882895 },
    { lat: 48.5689216, lng: 18.7884864 },
    { lat: 48.5691597, lng: 18.788552 },
    { lat: 48.5692263, lng: 18.7888125 },
    { lat: 48.5692057, lng: 18.7888524 },
    { lat: 48.5691874, lng: 18.7888862 },
    { lat: 48.5691483, lng: 18.78896 },
    { lat: 48.5689584, lng: 18.7893177 },
    { lat: 48.5688456, lng: 18.7891641 },
    { lat: 48.5687221, lng: 18.7891108 },
    { lat: 48.5685556, lng: 18.7889915 },
    { lat: 48.5685202, lng: 18.7889836 },
    { lat: 48.5683559, lng: 18.7889459 },
    { lat: 48.5683295, lng: 18.788677 },
    { lat: 48.5681334, lng: 18.7888303 },
    { lat: 48.5679904, lng: 18.7889424 },
    { lat: 48.5679013, lng: 18.7888356 },
    { lat: 48.5678123, lng: 18.788728 },
    { lat: 48.5677376, lng: 18.788895 },
    { lat: 48.567704, lng: 18.7890286 },
    { lat: 48.5676572, lng: 18.7891908 },
    { lat: 48.5676196, lng: 18.7892031 },
    { lat: 48.5674355, lng: 18.7892657 },
    { lat: 48.5673873, lng: 18.7894185 },
    { lat: 48.5672784, lng: 18.7894514 },
    { lat: 48.5672177, lng: 18.7894967 },
    { lat: 48.5671498, lng: 18.789513 },
    { lat: 48.567027, lng: 18.7894294 },
    { lat: 48.5669706, lng: 18.7894532 },
    { lat: 48.5669783, lng: 18.7895773 },
    { lat: 48.5669746, lng: 18.7898088 },
    { lat: 48.5668892, lng: 18.7898969 },
    { lat: 48.5667519, lng: 18.789862 },
    { lat: 48.5666262, lng: 18.7901099 },
    { lat: 48.5666264, lng: 18.7902427 },
    { lat: 48.5666251, lng: 18.7904301 },
    { lat: 48.5665684, lng: 18.7906195 },
    { lat: 48.5664416, lng: 18.7906759 },
    { lat: 48.5662854, lng: 18.7907063 },
    { lat: 48.5661213, lng: 18.7906507 },
    { lat: 48.5660448, lng: 18.7909354 },
    { lat: 48.5659423, lng: 18.7910228 },
    { lat: 48.5659109, lng: 18.79105 },
    { lat: 48.5658336, lng: 18.7910581 },
    { lat: 48.5657023, lng: 18.7909844 },
    { lat: 48.5656558, lng: 18.7909583 },
    { lat: 48.5655925, lng: 18.7911916 },
    { lat: 48.5654978, lng: 18.79132 },
    { lat: 48.5653549, lng: 18.791326 },
    { lat: 48.565312, lng: 18.7913279 },
    { lat: 48.5651486, lng: 18.7915008 },
    { lat: 48.5650876, lng: 18.7914543 },
    { lat: 48.5649465, lng: 18.7913472 },
    { lat: 48.56483, lng: 18.7915078 },
    { lat: 48.5646311, lng: 18.7917401 },
    { lat: 48.5645707, lng: 18.7920065 },
    { lat: 48.5644158, lng: 18.7921044 },
    { lat: 48.5641863, lng: 18.7923514 },
    { lat: 48.5642355, lng: 18.7925816 },
    { lat: 48.5642341, lng: 18.7927252 },
    { lat: 48.5641563, lng: 18.79281 },
    { lat: 48.5640917, lng: 18.7930206 },
    { lat: 48.5640158, lng: 18.7931226 },
    { lat: 48.5639476, lng: 18.793088 },
    { lat: 48.5639233, lng: 18.7930749 },
    { lat: 48.5638749, lng: 18.7931444 },
    { lat: 48.5638445, lng: 18.7932085 },
    { lat: 48.5636852, lng: 18.7934828 },
    { lat: 48.5635721, lng: 18.7937989 },
    { lat: 48.5633567, lng: 18.7939918 },
    { lat: 48.5634138, lng: 18.7943955 },
    { lat: 48.5634464, lng: 18.7946295 },
    { lat: 48.5633801, lng: 18.7947606 },
    { lat: 48.5633299, lng: 18.7948588 },
    { lat: 48.5632871, lng: 18.7949433 },
    { lat: 48.5632544, lng: 18.7950084 },
    { lat: 48.5630069, lng: 18.7947822 },
    { lat: 48.5628091, lng: 18.7946014 },
    { lat: 48.5625755, lng: 18.7949874 },
    { lat: 48.5624291, lng: 18.7952288 },
    { lat: 48.5623938, lng: 18.7951771 },
    { lat: 48.5623719, lng: 18.7951459 },
    { lat: 48.5623424, lng: 18.79508 },
    { lat: 48.562285, lng: 18.7949533 },
    { lat: 48.561461, lng: 18.7938204 },
    { lat: 48.5612326, lng: 18.793592 },
    { lat: 48.5609834, lng: 18.7931442 },
    { lat: 48.560592, lng: 18.7927555 },
    { lat: 48.5601047, lng: 18.7923222 },
    { lat: 48.5599045, lng: 18.7921368 },
    { lat: 48.5597121, lng: 18.7920828 },
    { lat: 48.5596494, lng: 18.7919194 },
    { lat: 48.5595912, lng: 18.7917562 },
    { lat: 48.5595487, lng: 18.7916744 },
    { lat: 48.5594539, lng: 18.791566 },
    { lat: 48.5593576, lng: 18.791496 },
    { lat: 48.5593324, lng: 18.7914039 },
    { lat: 48.5593153, lng: 18.7913113 },
    { lat: 48.5592741, lng: 18.7911478 },
    { lat: 48.5592145, lng: 18.7910666 },
    { lat: 48.5591006, lng: 18.7909577 },
    { lat: 48.5590146, lng: 18.790869 },
    { lat: 48.5589439, lng: 18.7907471 },
    { lat: 48.5588256, lng: 18.7904925 },
    { lat: 48.5587291, lng: 18.7904139 },
    { lat: 48.5586607, lng: 18.7903986 },
    { lat: 48.5584916, lng: 18.7904233 },
    { lat: 48.5583837, lng: 18.7906673 },
    { lat: 48.558306, lng: 18.7907545 },
    { lat: 48.5580818, lng: 18.7906295 },
    { lat: 48.5580428, lng: 18.7905979 },
    { lat: 48.55799, lng: 18.7905554 },
    { lat: 48.55791, lng: 18.7904941 },
    { lat: 48.5578456, lng: 18.7904288 },
    { lat: 48.5578123, lng: 18.7903523 },
    { lat: 48.5577731, lng: 18.7907454 },
    { lat: 48.5577613, lng: 18.7908628 },
    { lat: 48.5575664, lng: 18.7908223 },
    { lat: 48.5574034, lng: 18.7909813 },
    { lat: 48.557338, lng: 18.7910446 },
    { lat: 48.5572145, lng: 18.7912498 },
    { lat: 48.5571345, lng: 18.7913821 },
    { lat: 48.557066, lng: 18.791496 },
    { lat: 48.5567187, lng: 18.7914296 },
    { lat: 48.5562221, lng: 18.7916799 },
    { lat: 48.5560367, lng: 18.7916949 },
    { lat: 48.5559025, lng: 18.7913706 },
    { lat: 48.5556761, lng: 18.7916997 },
    { lat: 48.5554346, lng: 18.7917171 },
    { lat: 48.5552356, lng: 18.7914924 },
    { lat: 48.5551148, lng: 18.7910187 },
    { lat: 48.5549216, lng: 18.7909211 },
    { lat: 48.5547257, lng: 18.7907148 },
    { lat: 48.5547443, lng: 18.7906183 },
    { lat: 48.5547905, lng: 18.7903835 },
    { lat: 48.5548113, lng: 18.7902832 },
    { lat: 48.5546368, lng: 18.7901377 },
    { lat: 48.5544186, lng: 18.7898491 },
    { lat: 48.5542202, lng: 18.7898959 },
    { lat: 48.5540401, lng: 18.7898137 },
    { lat: 48.5539835, lng: 18.7897888 },
    { lat: 48.5539176, lng: 18.7897582 },
    { lat: 48.5538414, lng: 18.7898107 },
    { lat: 48.5537162, lng: 18.7898965 },
    { lat: 48.5534118, lng: 18.7898555 },
    { lat: 48.5532323, lng: 18.7896584 },
    { lat: 48.5532097, lng: 18.7893144 },
    { lat: 48.5531159, lng: 18.7892878 },
    { lat: 48.553033, lng: 18.7891496 },
    { lat: 48.5527226, lng: 18.7891432 },
    { lat: 48.5525232, lng: 18.7888872 },
    { lat: 48.5523989, lng: 18.789075 },
    { lat: 48.5521985, lng: 18.7891074 },
    { lat: 48.5520218, lng: 18.7890046 },
    { lat: 48.5519093, lng: 18.7886905 },
    { lat: 48.5517882, lng: 18.7885925 },
    { lat: 48.5515402, lng: 18.7886801 },
    { lat: 48.5512468, lng: 18.7888948 },
    { lat: 48.5509577, lng: 18.7889923 },
    { lat: 48.5506885, lng: 18.7889546 },
    { lat: 48.5505683, lng: 18.7885333 },
    { lat: 48.5504602, lng: 18.7881489 },
    { lat: 48.5503064, lng: 18.7880558 },
    { lat: 48.5501694, lng: 18.7875984 },
    { lat: 48.5499387, lng: 18.7868318 },
    { lat: 48.54967, lng: 18.7865268 },
    { lat: 48.549605, lng: 18.7864278 },
    { lat: 48.5495179, lng: 18.786294 },
    { lat: 48.5492233, lng: 18.7852567 },
    { lat: 48.5485692, lng: 18.7875732 },
    { lat: 48.5480831, lng: 18.7898481 },
    { lat: 48.5477505, lng: 18.7911837 },
    { lat: 48.547813, lng: 18.7931647 },
    { lat: 48.5481462, lng: 18.7937999 },
    { lat: 48.5498274, lng: 18.7949354 },
    { lat: 48.5506895, lng: 18.7956756 },
    { lat: 48.5509566, lng: 18.7967892 },
    { lat: 48.5510557, lng: 18.7972477 },
    { lat: 48.5511292, lng: 18.7975874 },
    { lat: 48.551011, lng: 18.798277 },
    { lat: 48.5505396, lng: 18.7995472 },
    { lat: 48.549849, lng: 18.8003579 },
    { lat: 48.5496119, lng: 18.8009332 },
    { lat: 48.5495592, lng: 18.8012399 },
    { lat: 48.5495208, lng: 18.8023104 },
    { lat: 48.5492322, lng: 18.8034132 },
    { lat: 48.5488684, lng: 18.8039331 },
    { lat: 48.5487666, lng: 18.8043178 },
    { lat: 48.5483841, lng: 18.8049833 },
    { lat: 48.5480845, lng: 18.8058724 },
    { lat: 48.5478681, lng: 18.806689 },
    { lat: 48.5478794, lng: 18.8073571 },
    { lat: 48.5479755, lng: 18.8078788 },
    { lat: 48.5481951, lng: 18.8082176 },
    { lat: 48.5486171, lng: 18.8085251 },
    { lat: 48.5486801, lng: 18.808477 },
    { lat: 48.5489148, lng: 18.808415 },
    { lat: 48.5492831, lng: 18.8082143 },
    { lat: 48.5500767, lng: 18.8078868 },
    { lat: 48.550588, lng: 18.8080439 },
    { lat: 48.5510321, lng: 18.8080634 },
    { lat: 48.5513353, lng: 18.8082982 },
    { lat: 48.5516014, lng: 18.8089201 },
    { lat: 48.5517633, lng: 18.809558 },
    { lat: 48.5523494, lng: 18.811188 },
    { lat: 48.5525541, lng: 18.8116733 },
    { lat: 48.5529994, lng: 18.8122526 },
    { lat: 48.5534581, lng: 18.8126143 },
    { lat: 48.5537808, lng: 18.8127714 },
    { lat: 48.55452, lng: 18.8128206 },
    { lat: 48.5550252, lng: 18.8127806 },
    { lat: 48.5558831, lng: 18.8121487 },
    { lat: 48.5563696, lng: 18.811101 },
    { lat: 48.5563752, lng: 18.8098936 },
    { lat: 48.5565129, lng: 18.8088851 },
    { lat: 48.5566848, lng: 18.8084404 },
    { lat: 48.5571862, lng: 18.8078091 },
    { lat: 48.5576249, lng: 18.8077014 },
    { lat: 48.5578964, lng: 18.8077613 },
    { lat: 48.5581782, lng: 18.8080155 },
    { lat: 48.5582108, lng: 18.8080597 },
    { lat: 48.5586648, lng: 18.8087394 },
    { lat: 48.558841, lng: 18.8091738 },
    { lat: 48.559051, lng: 18.8102335 },
    { lat: 48.5589767, lng: 18.8110768 },
    { lat: 48.559032, lng: 18.8134924 },
    { lat: 48.5589784, lng: 18.8150113 },
    { lat: 48.5592516, lng: 18.817023 },
    { lat: 48.5597565, lng: 18.817732 },
    { lat: 48.5599282, lng: 18.817974 },
    { lat: 48.560182, lng: 18.8185986 },
    { lat: 48.5606106, lng: 18.818882 },
    { lat: 48.5608825, lng: 18.8189085 },
    { lat: 48.5616303, lng: 18.818933 },
    { lat: 48.5620116, lng: 18.8188048 },
    { lat: 48.5621512, lng: 18.8187576 },
    { lat: 48.5623063, lng: 18.8187058 },
    { lat: 48.5631066, lng: 18.8184367 },
    { lat: 48.5633669, lng: 18.8183037 },
    { lat: 48.5640424, lng: 18.8179583 },
    { lat: 48.5653051, lng: 18.8178658 },
    { lat: 48.5658294, lng: 18.8179965 },
    { lat: 48.5658409, lng: 18.8179998 },
    { lat: 48.5660066, lng: 18.8179773 },
    { lat: 48.5661067, lng: 18.8180797 },
    { lat: 48.5663078, lng: 18.8182606 },
    { lat: 48.5666343, lng: 18.8186387 },
    { lat: 48.5673345, lng: 18.8189605 },
    { lat: 48.5675711, lng: 18.8191072 },
    { lat: 48.5678353, lng: 18.819197 },
    { lat: 48.5683216, lng: 18.8196594 },
    { lat: 48.5688066, lng: 18.8199818 },
    { lat: 48.5695516, lng: 18.8203645 },
    { lat: 48.5697871, lng: 18.8204893 },
    { lat: 48.5700763, lng: 18.8207837 },
    { lat: 48.5702125, lng: 18.8209078 },
    { lat: 48.570426, lng: 18.821063 },
    { lat: 48.5708461, lng: 18.8214009 },
    { lat: 48.5710219, lng: 18.8215695 },
    { lat: 48.5712234, lng: 18.8216941 },
    { lat: 48.5715679, lng: 18.8220695 },
    { lat: 48.5717551, lng: 18.8222873 },
    { lat: 48.5720904, lng: 18.8226168 },
    { lat: 48.5723335, lng: 18.8228689 },
    { lat: 48.5725789, lng: 18.8231617 },
    { lat: 48.5726772, lng: 18.8233829 },
    { lat: 48.5731727, lng: 18.8242105 },
    { lat: 48.5735954, lng: 18.8246179 },
    { lat: 48.5739226, lng: 18.8249017 },
    { lat: 48.5743596, lng: 18.8250426 },
    { lat: 48.5746525, lng: 18.8250602 },
    { lat: 48.5749638, lng: 18.8249984 },
    { lat: 48.5757616, lng: 18.8257631 },
    { lat: 48.5758199, lng: 18.825884 },
    { lat: 48.5759007, lng: 18.8260525 },
    { lat: 48.5762181, lng: 18.8267103 },
    { lat: 48.5763016, lng: 18.8268828 },
    { lat: 48.5763574, lng: 18.8271098 },
    { lat: 48.5764975, lng: 18.8276765 },
    { lat: 48.5765927, lng: 18.827902 },
    { lat: 48.5768498, lng: 18.8285086 },
    { lat: 48.576825, lng: 18.8289396 },
    { lat: 48.5763889, lng: 18.8302024 },
    { lat: 48.5760306, lng: 18.830921 },
    { lat: 48.5759255, lng: 18.8311311 },
    { lat: 48.5756792, lng: 18.831641 },
    { lat: 48.5756235, lng: 18.8317641 },
    { lat: 48.5750065, lng: 18.8331247 },
    { lat: 48.5747035, lng: 18.8341477 },
    { lat: 48.5745226, lng: 18.835175 },
    { lat: 48.5746585, lng: 18.835207 },
    { lat: 48.5759739, lng: 18.8344624 },
    { lat: 48.5763857, lng: 18.8342288 },
    { lat: 48.5764426, lng: 18.8341968 },
    { lat: 48.5769226, lng: 18.8339253 },
    { lat: 48.5769772, lng: 18.8338942 },
    { lat: 48.5770364, lng: 18.8338609 },
    { lat: 48.5770463, lng: 18.8338551 },
    { lat: 48.5772806, lng: 18.8337223 },
    { lat: 48.5772916, lng: 18.833716 },
    { lat: 48.5772781, lng: 18.8336289 },
    { lat: 48.5772331, lng: 18.8333508 },
    { lat: 48.5772008, lng: 18.8331438 },
    { lat: 48.5773018, lng: 18.8327405 },
    { lat: 48.5774606, lng: 18.8318763 },
    { lat: 48.5774874, lng: 18.8317823 },
    { lat: 48.5775985, lng: 18.8313835 },
    { lat: 48.5777616, lng: 18.8309856 },
    { lat: 48.577771, lng: 18.8309724 },
    { lat: 48.57781, lng: 18.8309183 },
    { lat: 48.5778488, lng: 18.8308631 },
    { lat: 48.5778877, lng: 18.8308086 },
    { lat: 48.5778495, lng: 18.8307455 },
    { lat: 48.5777863, lng: 18.8306348 },
    { lat: 48.5777824, lng: 18.8306274 },
    { lat: 48.5777638, lng: 18.8305929 },
    { lat: 48.5777052, lng: 18.8304898 },
    { lat: 48.5776952, lng: 18.8304465 },
    { lat: 48.5776337, lng: 18.8301778 },
    { lat: 48.5772664, lng: 18.8285807 },
    { lat: 48.5772827, lng: 18.8285731 },
    { lat: 48.5775172, lng: 18.8284616 },
    { lat: 48.5777649, lng: 18.8283436 },
    { lat: 48.5778023, lng: 18.8283263 },
    { lat: 48.5779078, lng: 18.8282757 },
    { lat: 48.5779364, lng: 18.8282624 },
    { lat: 48.578063, lng: 18.8282021 },
    { lat: 48.5781189, lng: 18.8281912 },
    { lat: 48.578169, lng: 18.8281821 },
    { lat: 48.5782842, lng: 18.8281603 },
    { lat: 48.578349, lng: 18.828148 },
    { lat: 48.578399, lng: 18.8281381 },
    { lat: 48.5788001, lng: 18.8280621 },
    { lat: 48.5792477, lng: 18.8279774 },
    { lat: 48.5792, lng: 18.8271725 },
    { lat: 48.5791946, lng: 18.8270681 },
    { lat: 48.5792324, lng: 18.8264738 },
    { lat: 48.57968, lng: 18.8265155 },
    { lat: 48.5800551, lng: 18.8265491 },
    { lat: 48.5801818, lng: 18.8265604 },
    { lat: 48.5802614, lng: 18.8265677 },
    { lat: 48.5803227, lng: 18.8266195 },
    { lat: 48.580567, lng: 18.8268278 },
    { lat: 48.5806743, lng: 18.8269187 },
    { lat: 48.5807639, lng: 18.8269953 },
    { lat: 48.5809187, lng: 18.8270012 },
    { lat: 48.581017, lng: 18.8270056 },
    { lat: 48.5813708, lng: 18.8270199 },
    { lat: 48.5814661, lng: 18.8270304 },
    { lat: 48.5816306, lng: 18.8270486 },
    { lat: 48.5819963, lng: 18.8270106 },
    { lat: 48.5821622, lng: 18.8269934 },
    { lat: 48.5823996, lng: 18.8269415 },
    { lat: 48.5826393, lng: 18.8268892 },
    { lat: 48.5828805, lng: 18.8269058 },
    { lat: 48.5829699, lng: 18.8269116 },
    { lat: 48.5834455, lng: 18.8267986 },
    { lat: 48.5836104, lng: 18.8267602 },
    { lat: 48.5840426, lng: 18.8265533 },
    { lat: 48.5846976, lng: 18.8259005 },
    { lat: 48.58496, lng: 18.8254812 },
    { lat: 48.585247, lng: 18.8252349 },
    { lat: 48.5857982, lng: 18.8244536 },
    { lat: 48.5859254, lng: 18.8242733 },
    { lat: 48.5860998, lng: 18.8239213 },
    { lat: 48.5861682, lng: 18.8237829 },
    { lat: 48.5863056, lng: 18.8237794 },
    { lat: 48.5863394, lng: 18.8238424 },
    { lat: 48.5863643, lng: 18.82389 },
    { lat: 48.5864044, lng: 18.823965 },
    { lat: 48.5865004, lng: 18.8241428 },
    { lat: 48.5868178, lng: 18.824737 },
    { lat: 48.5869098, lng: 18.824834 },
    { lat: 48.5870541, lng: 18.8249871 },
    { lat: 48.5872929, lng: 18.8248498 },
    { lat: 48.5875562, lng: 18.8253803 },
    { lat: 48.5878594, lng: 18.8256382 },
    { lat: 48.5879862, lng: 18.8258633 },
    { lat: 48.5890222, lng: 18.8250359 },
    { lat: 48.5884867, lng: 18.8244098 },
    { lat: 48.5882984, lng: 18.8241066 },
    { lat: 48.5881993, lng: 18.8235936 },
    { lat: 48.5893005, lng: 18.8226986 },
    { lat: 48.5892771, lng: 18.822336 },
    { lat: 48.5891413, lng: 18.821894 },
    { lat: 48.589182, lng: 18.8218076 },
    { lat: 48.5894027, lng: 18.8221697 },
    { lat: 48.5897297, lng: 18.8219524 },
    { lat: 48.5899616, lng: 18.8220125 },
    { lat: 48.5915806, lng: 18.8204266 },
    { lat: 48.5916105, lng: 18.8203974 },
    { lat: 48.5920934, lng: 18.8199237 },
    { lat: 48.5946909, lng: 18.8173868 },
    { lat: 48.5948797, lng: 18.8172019 },
    { lat: 48.594297, lng: 18.81589 },
    { lat: 48.5937923, lng: 18.8144766 },
    { lat: 48.5937752, lng: 18.8144294 },
    { lat: 48.5937605, lng: 18.8143881 },
    { lat: 48.5937592, lng: 18.81438 },
    { lat: 48.5935442, lng: 18.8132913 },
    { lat: 48.5934069, lng: 18.812474 },
    { lat: 48.5935533, lng: 18.8120826 },
    { lat: 48.593574, lng: 18.8120263 },
    { lat: 48.5934631, lng: 18.8115461 },
    { lat: 48.5932071, lng: 18.8109852 },
    { lat: 48.5931128, lng: 18.8107759 },
    { lat: 48.5929956, lng: 18.8105184 },
    { lat: 48.5922734, lng: 18.8094298 },
    { lat: 48.5916832, lng: 18.8087124 },
    { lat: 48.591466, lng: 18.808658 },
    { lat: 48.5911265, lng: 18.808204 },
    { lat: 48.5911553, lng: 18.8078906 },
    { lat: 48.5908542, lng: 18.807375 },
    { lat: 48.590837, lng: 18.8073454 },
    { lat: 48.5907784, lng: 18.8072408 },
    { lat: 48.5907587, lng: 18.8072079 },
    { lat: 48.5907377, lng: 18.8071716 },
    { lat: 48.5907252, lng: 18.8071492 },
    { lat: 48.5909646, lng: 18.8068347 },
    { lat: 48.5909812, lng: 18.8069357 },
    { lat: 48.5910024, lng: 18.8070147 },
    { lat: 48.5910643, lng: 18.8072496 },
    { lat: 48.5913344, lng: 18.8074113 },
    { lat: 48.5913878, lng: 18.807443 },
    { lat: 48.5915494, lng: 18.8071894 },
    { lat: 48.5915889, lng: 18.8071277 },
    { lat: 48.5917353, lng: 18.8070583 },
    { lat: 48.5918328, lng: 18.8071105 },
    { lat: 48.5921541, lng: 18.8072796 },
    { lat: 48.5922017, lng: 18.8073049 },
    { lat: 48.592282, lng: 18.8071183 },
    { lat: 48.5923946, lng: 18.8068545 },
    { lat: 48.5925259, lng: 18.8067577 },
  ],
  TrnaváHora: [
    { lat: 48.5875045, lng: 18.9500413 },
    { lat: 48.5874962, lng: 18.9500134 },
    { lat: 48.587421, lng: 18.9497767 },
    { lat: 48.5874104, lng: 18.9497455 },
    { lat: 48.5871467, lng: 18.9490831 },
    { lat: 48.5869909, lng: 18.9485303 },
    { lat: 48.5868996, lng: 18.9479561 },
    { lat: 48.5865033, lng: 18.9471958 },
    { lat: 48.5859834, lng: 18.9461785 },
    { lat: 48.5857525, lng: 18.9458118 },
    { lat: 48.5856534, lng: 18.9457215 },
    { lat: 48.584764, lng: 18.9449124 },
    { lat: 48.5847306, lng: 18.9449665 },
    { lat: 48.5844486, lng: 18.9454259 },
    { lat: 48.5844456, lng: 18.9454316 },
    { lat: 48.5844124, lng: 18.9454896 },
    { lat: 48.584336, lng: 18.9456252 },
    { lat: 48.5842521, lng: 18.9457742 },
    { lat: 48.5842234, lng: 18.9458085 },
    { lat: 48.5837187, lng: 18.9455502 },
    { lat: 48.5836816, lng: 18.9455306 },
    { lat: 48.5831269, lng: 18.9452464 },
    { lat: 48.5816656, lng: 18.9454632 },
    { lat: 48.5810308, lng: 18.9453136 },
    { lat: 48.5809727, lng: 18.9453232 },
    { lat: 48.5808526, lng: 18.9447567 },
    { lat: 48.5805802, lng: 18.942588 },
    { lat: 48.5809261, lng: 18.9413033 },
    { lat: 48.5822321, lng: 18.9411992 },
    { lat: 48.5822657, lng: 18.9411964 },
    { lat: 48.5824444, lng: 18.9411824 },
    { lat: 48.5824867, lng: 18.9411735 },
    { lat: 48.5825169, lng: 18.9411693 },
    { lat: 48.5827987, lng: 18.941097 },
    { lat: 48.5835223, lng: 18.940874 },
    { lat: 48.5835665, lng: 18.9408554 },
    { lat: 48.5836735, lng: 18.9407856 },
    { lat: 48.5835194, lng: 18.939848 },
    { lat: 48.5843526, lng: 18.936785 },
    { lat: 48.5848305, lng: 18.9327105 },
    { lat: 48.584847, lng: 18.9325715 },
    { lat: 48.5850478, lng: 18.9305228 },
    { lat: 48.5850613, lng: 18.9304361 },
    { lat: 48.5854449, lng: 18.9270103 },
    { lat: 48.5855112, lng: 18.9264149 },
    { lat: 48.5855209, lng: 18.9263362 },
    { lat: 48.5855292, lng: 18.9262123 },
    { lat: 48.5859039, lng: 18.9246828 },
    { lat: 48.5859058, lng: 18.9246737 },
    { lat: 48.5859419, lng: 18.9246083 },
    { lat: 48.5859448, lng: 18.9246008 },
    { lat: 48.5861546, lng: 18.9241867 },
    { lat: 48.5860707, lng: 18.9241128 },
    { lat: 48.5857255, lng: 18.9238215 },
    { lat: 48.5856677, lng: 18.9237737 },
    { lat: 48.5856265, lng: 18.9237387 },
    { lat: 48.5855652, lng: 18.9236867 },
    { lat: 48.585537, lng: 18.9236611 },
    { lat: 48.58516, lng: 18.9233464 },
    { lat: 48.5844198, lng: 18.92303 },
    { lat: 48.5840278, lng: 18.9228634 },
    { lat: 48.583423, lng: 18.9227448 },
    { lat: 48.5829848, lng: 18.9229042 },
    { lat: 48.5823841, lng: 18.9232438 },
    { lat: 48.582333, lng: 18.9236055 },
    { lat: 48.581836, lng: 18.9238746 },
    { lat: 48.581501, lng: 18.9245123 },
    { lat: 48.5808837, lng: 18.9251163 },
    { lat: 48.5801418, lng: 18.9260385 },
    { lat: 48.5794046, lng: 18.9257804 },
    { lat: 48.5789483, lng: 18.9257232 },
    { lat: 48.5785595, lng: 18.9255695 },
    { lat: 48.5780338, lng: 18.9255477 },
    { lat: 48.5777495, lng: 18.925629 },
    { lat: 48.5774113, lng: 18.9254102 },
    { lat: 48.576842, lng: 18.9249752 },
    { lat: 48.5757284, lng: 18.9242582 },
    { lat: 48.5751699, lng: 18.9240979 },
    { lat: 48.5746423, lng: 18.9241676 },
    { lat: 48.5744575, lng: 18.9243006 },
    { lat: 48.5740475, lng: 18.9243082 },
    { lat: 48.5734724, lng: 18.9245703 },
    { lat: 48.5726717, lng: 18.924764 },
    { lat: 48.571507, lng: 18.9255139 },
    { lat: 48.5709448, lng: 18.9258293 },
    { lat: 48.5706018, lng: 18.9258672 },
    { lat: 48.5703366, lng: 18.9255339 },
    { lat: 48.5701651, lng: 18.9255991 },
    { lat: 48.5701373, lng: 18.9257169 },
    { lat: 48.5695718, lng: 18.9264977 },
    { lat: 48.5693034, lng: 18.9268245 },
    { lat: 48.5689142, lng: 18.9270638 },
    { lat: 48.5685819, lng: 18.9271809 },
    { lat: 48.5680345, lng: 18.9276295 },
    { lat: 48.5678706, lng: 18.9277963 },
    { lat: 48.5674576, lng: 18.929 },
    { lat: 48.5673931, lng: 18.92957 },
    { lat: 48.5673232, lng: 18.9296771 },
    { lat: 48.5666713, lng: 18.9307969 },
    { lat: 48.5663262, lng: 18.9311908 },
    { lat: 48.5655468, lng: 18.9323538 },
    { lat: 48.5648777, lng: 18.9330927 },
    { lat: 48.5644938, lng: 18.9341808 },
    { lat: 48.564088, lng: 18.9340496 },
    { lat: 48.5631815, lng: 18.9346876 },
    { lat: 48.563129, lng: 18.934907 },
    { lat: 48.564305, lng: 18.936811 },
    { lat: 48.564642, lng: 18.938428 },
    { lat: 48.564981, lng: 18.939059 },
    { lat: 48.565226, lng: 18.939509 },
    { lat: 48.565509, lng: 18.940036 },
    { lat: 48.565844, lng: 18.941347 },
    { lat: 48.565805, lng: 18.94207 },
    { lat: 48.565798, lng: 18.94212 },
    { lat: 48.565792, lng: 18.942244 },
    { lat: 48.565825, lng: 18.943706 },
    { lat: 48.565254, lng: 18.945008 },
    { lat: 48.564949, lng: 18.945594 },
    { lat: 48.564, lng: 18.947115 },
    { lat: 48.564193, lng: 18.947313 },
    { lat: 48.564135, lng: 18.947568 },
    { lat: 48.563629, lng: 18.948551 },
    { lat: 48.564435, lng: 18.949024 },
    { lat: 48.564694, lng: 18.949502 },
    { lat: 48.5649, lng: 18.949892 },
    { lat: 48.565186, lng: 18.950175 },
    { lat: 48.565405, lng: 18.950257 },
    { lat: 48.565628, lng: 18.95073 },
    { lat: 48.566477, lng: 18.951141 },
    { lat: 48.567135, lng: 18.951447 },
    { lat: 48.567319, lng: 18.951896 },
    { lat: 48.567342, lng: 18.952266 },
    { lat: 48.567198, lng: 18.95257 },
    { lat: 48.567137, lng: 18.952692 },
    { lat: 48.566817, lng: 18.952471 },
    { lat: 48.566676, lng: 18.952403 },
    { lat: 48.566464, lng: 18.952437 },
    { lat: 48.566171, lng: 18.952041 },
    { lat: 48.565718, lng: 18.951851 },
    { lat: 48.565475, lng: 18.951603 },
    { lat: 48.564907, lng: 18.951373 },
    { lat: 48.564626, lng: 18.951695 },
    { lat: 48.564457, lng: 18.951617 },
    { lat: 48.563788, lng: 18.951552 },
    { lat: 48.56303, lng: 18.951299 },
    { lat: 48.563001, lng: 18.951117 },
    { lat: 48.562731, lng: 18.950683 },
    { lat: 48.562674, lng: 18.950964 },
    { lat: 48.562558, lng: 18.950972 },
    { lat: 48.562334, lng: 18.951112 },
    { lat: 48.562099, lng: 18.951026 },
    { lat: 48.561865, lng: 18.951062 },
    { lat: 48.561505, lng: 18.951273 },
    { lat: 48.560987, lng: 18.951844 },
    { lat: 48.560489, lng: 18.951903 },
    { lat: 48.559794, lng: 18.952111 },
    { lat: 48.559017, lng: 18.951966 },
    { lat: 48.558264, lng: 18.952057 },
    { lat: 48.55777, lng: 18.952044 },
    { lat: 48.557728, lng: 18.954058 },
    { lat: 48.557724, lng: 18.954102 },
    { lat: 48.557685, lng: 18.954936 },
    { lat: 48.55765, lng: 18.955434 },
    { lat: 48.55748, lng: 18.956201 },
    { lat: 48.557412, lng: 18.956891 },
    { lat: 48.557292, lng: 18.957507 },
    { lat: 48.557243, lng: 18.958094 },
    { lat: 48.557148, lng: 18.959736 },
    { lat: 48.557016, lng: 18.960578 },
    { lat: 48.556688, lng: 18.961637 },
    { lat: 48.55666, lng: 18.963883 },
    { lat: 48.556521, lng: 18.96523 },
    { lat: 48.55649, lng: 18.967261 },
    { lat: 48.556908, lng: 18.96782 },
    { lat: 48.5576, lng: 18.968354 },
    { lat: 48.557838, lng: 18.968713 },
    { lat: 48.558331, lng: 18.969131 },
    { lat: 48.558634, lng: 18.971122 },
    { lat: 48.558624, lng: 18.971722 },
    { lat: 48.558848, lng: 18.972075 },
    { lat: 48.559415, lng: 18.972908 },
    { lat: 48.560302, lng: 18.973612 },
    { lat: 48.560945, lng: 18.974144 },
    { lat: 48.561304, lng: 18.97417 },
    { lat: 48.561562, lng: 18.974134 },
    { lat: 48.56244, lng: 18.974588 },
    { lat: 48.562685, lng: 18.974686 },
    { lat: 48.56305, lng: 18.97528 },
    { lat: 48.563275, lng: 18.975444 },
    { lat: 48.563989, lng: 18.975415 },
    { lat: 48.564397, lng: 18.975555 },
    { lat: 48.565261, lng: 18.975291 },
    { lat: 48.565823, lng: 18.975665 },
    { lat: 48.566405, lng: 18.975617 },
    { lat: 48.566745, lng: 18.975727 },
    { lat: 48.566774, lng: 18.976406 },
    { lat: 48.567055, lng: 18.977243 },
    { lat: 48.566973, lng: 18.978115 },
    { lat: 48.56672, lng: 18.979625 },
    { lat: 48.566559, lng: 18.980235 },
    { lat: 48.567118, lng: 18.980869 },
    { lat: 48.567555, lng: 18.981087 },
    { lat: 48.568766, lng: 18.981365 },
    { lat: 48.569135, lng: 18.981551 },
    { lat: 48.569943, lng: 18.98251 },
    { lat: 48.570093, lng: 18.98292 },
    { lat: 48.570832, lng: 18.984406 },
    { lat: 48.571262, lng: 18.985102 },
    { lat: 48.572481, lng: 18.98618 },
    { lat: 48.572517, lng: 18.986216 },
    { lat: 48.572782, lng: 18.986461 },
    { lat: 48.57344, lng: 18.987163 },
    { lat: 48.573529, lng: 18.987223 },
    { lat: 48.573528, lng: 18.987268 },
    { lat: 48.573297, lng: 18.987693 },
    { lat: 48.573267, lng: 18.987912 },
    { lat: 48.573239, lng: 18.988121 },
    { lat: 48.573273, lng: 18.988255 },
    { lat: 48.573416, lng: 18.988916 },
    { lat: 48.573511, lng: 18.989339 },
    { lat: 48.5737967, lng: 18.9891282 },
    { lat: 48.5745288, lng: 18.9885778 },
    { lat: 48.5745593, lng: 18.9885551 },
    { lat: 48.5749016, lng: 18.9883013 },
    { lat: 48.5751357, lng: 18.9881386 },
    { lat: 48.5756359, lng: 18.9880653 },
    { lat: 48.5761204, lng: 18.9879941 },
    { lat: 48.5766501, lng: 18.9877878 },
    { lat: 48.5772232, lng: 18.9875255 },
    { lat: 48.5779503, lng: 18.987081 },
    { lat: 48.5785597, lng: 18.9864499 },
    { lat: 48.5786428, lng: 18.9862217 },
    { lat: 48.5788476, lng: 18.9856572 },
    { lat: 48.5791864, lng: 18.9854907 },
    { lat: 48.5794011, lng: 18.9853673 },
    { lat: 48.5799154, lng: 18.9848604 },
    { lat: 48.5804953, lng: 18.9839777 },
    { lat: 48.5805477, lng: 18.9838993 },
    { lat: 48.5806387, lng: 18.9837599 },
    { lat: 48.5806836, lng: 18.9837555 },
    { lat: 48.5807943, lng: 18.9838039 },
    { lat: 48.5810434, lng: 18.9839119 },
    { lat: 48.5816254, lng: 18.9841649 },
    { lat: 48.5817143, lng: 18.9842031 },
    { lat: 48.5817338, lng: 18.9842115 },
    { lat: 48.5822157, lng: 18.9844194 },
    { lat: 48.582226, lng: 18.9844218 },
    { lat: 48.582298, lng: 18.9844623 },
    { lat: 48.5823838, lng: 18.9845097 },
    { lat: 48.5826108, lng: 18.9846288 },
    { lat: 48.5826618, lng: 18.9846568 },
    { lat: 48.5828194, lng: 18.9847408 },
    { lat: 48.5830757, lng: 18.9840798 },
    { lat: 48.5831631, lng: 18.9837578 },
    { lat: 48.5832515, lng: 18.9834563 },
    { lat: 48.5833309, lng: 18.9831587 },
    { lat: 48.5834274, lng: 18.9819471 },
    { lat: 48.5833551, lng: 18.981812 },
    { lat: 48.5835469, lng: 18.981151 },
    { lat: 48.583689, lng: 18.9806414 },
    { lat: 48.5839529, lng: 18.9797117 },
    { lat: 48.5841726, lng: 18.9788192 },
    { lat: 48.5843649, lng: 18.9780574 },
    { lat: 48.5846246, lng: 18.9772628 },
    { lat: 48.5847533, lng: 18.9771607 },
    { lat: 48.5847615, lng: 18.9767865 },
    { lat: 48.5848309, lng: 18.9768458 },
    { lat: 48.5853409, lng: 18.9769607 },
    { lat: 48.5855195, lng: 18.9770807 },
    { lat: 48.5859532, lng: 18.977474 },
    { lat: 48.5860771, lng: 18.9776239 },
    { lat: 48.586419, lng: 18.9783643 },
    { lat: 48.5866116, lng: 18.978737 },
    { lat: 48.5868158, lng: 18.9790482 },
    { lat: 48.5870416, lng: 18.9792749 },
    { lat: 48.5874013, lng: 18.9794538 },
    { lat: 48.58764, lng: 18.9796264 },
    { lat: 48.5881564, lng: 18.9798902 },
    { lat: 48.5885362, lng: 18.9800422 },
    { lat: 48.588812, lng: 18.9791702 },
    { lat: 48.5888296, lng: 18.9791158 },
    { lat: 48.5891366, lng: 18.9781465 },
    { lat: 48.5898181, lng: 18.9785344 },
    { lat: 48.5907586, lng: 18.9791366 },
    { lat: 48.591228, lng: 18.9794745 },
    { lat: 48.5919242, lng: 18.9800171 },
    { lat: 48.5922273, lng: 18.9802349 },
    { lat: 48.59235, lng: 18.9802778 },
    { lat: 48.5927327, lng: 18.9803515 },
    { lat: 48.5927689, lng: 18.9803583 },
    { lat: 48.5927816, lng: 18.9803608 },
    { lat: 48.5929621, lng: 18.9804083 },
    { lat: 48.5932631, lng: 18.9805848 },
    { lat: 48.5935575, lng: 18.9808079 },
    { lat: 48.5938704, lng: 18.9810414 },
    { lat: 48.5941546, lng: 18.981311 },
    { lat: 48.5943579, lng: 18.9814478 },
    { lat: 48.5945165, lng: 18.981552 },
    { lat: 48.5947481, lng: 18.9816724 },
    { lat: 48.5949985, lng: 18.9817664 },
    { lat: 48.5950643, lng: 18.9817499 },
    { lat: 48.5952327, lng: 18.9816457 },
    { lat: 48.5953736, lng: 18.9814898 },
    { lat: 48.5955276, lng: 18.9812369 },
    { lat: 48.5957044, lng: 18.9809458 },
    { lat: 48.5959422, lng: 18.9812079 },
    { lat: 48.5962924, lng: 18.9819123 },
    { lat: 48.5963279, lng: 18.9819456 },
    { lat: 48.596359, lng: 18.9819755 },
    { lat: 48.5965281, lng: 18.982134 },
    { lat: 48.5967841, lng: 18.9824236 },
    { lat: 48.5971701, lng: 18.9826085 },
    { lat: 48.5974014, lng: 18.9827673 },
    { lat: 48.5982059, lng: 18.9844242 },
    { lat: 48.5988267, lng: 18.9849659 },
    { lat: 48.5989561, lng: 18.9854949 },
    { lat: 48.5990557, lng: 18.985772 },
    { lat: 48.5994131, lng: 18.9863245 },
    { lat: 48.5995969, lng: 18.9866364 },
    { lat: 48.5996686, lng: 18.9867581 },
    { lat: 48.5997584, lng: 18.9869088 },
    { lat: 48.600318, lng: 18.9877579 },
    { lat: 48.6000021, lng: 18.9884427 },
    { lat: 48.6000245, lng: 18.990332 },
    { lat: 48.5999741, lng: 18.9904237 },
    { lat: 48.599815, lng: 18.9906443 },
    { lat: 48.5995664, lng: 18.9910125 },
    { lat: 48.5994143, lng: 18.9913025 },
    { lat: 48.599372, lng: 18.9913808 },
    { lat: 48.5993349, lng: 18.9914516 },
    { lat: 48.5991603, lng: 18.9918754 },
    { lat: 48.5990732, lng: 18.9920493 },
    { lat: 48.5989538, lng: 18.9922294 },
    { lat: 48.5988052, lng: 18.9923872 },
    { lat: 48.5985552, lng: 18.9926611 },
    { lat: 48.5983347, lng: 18.9928689 },
    { lat: 48.5980899, lng: 18.9930232 },
    { lat: 48.597997, lng: 18.993082 },
    { lat: 48.5977826, lng: 18.9931875 },
    { lat: 48.5975441, lng: 18.9933105 },
    { lat: 48.597337, lng: 18.9934708 },
    { lat: 48.5971307, lng: 18.9936719 },
    { lat: 48.5970559, lng: 18.9938458 },
    { lat: 48.597083, lng: 18.9938915 },
    { lat: 48.5972556, lng: 18.9941826 },
    { lat: 48.5974081, lng: 18.9946494 },
    { lat: 48.5975166, lng: 18.9950793 },
    { lat: 48.5976712, lng: 18.9953048 },
    { lat: 48.598267, lng: 18.996142 },
    { lat: 48.598307, lng: 18.996057 },
    { lat: 48.599014, lng: 18.995159 },
    { lat: 48.600032, lng: 18.994075 },
    { lat: 48.600347, lng: 18.993651 },
    { lat: 48.600709, lng: 18.993372 },
    { lat: 48.601168, lng: 18.993522 },
    { lat: 48.601533, lng: 18.993252 },
    { lat: 48.601735, lng: 18.992636 },
    { lat: 48.601931, lng: 18.992338 },
    { lat: 48.602431, lng: 18.991467 },
    { lat: 48.602622, lng: 18.989774 },
    { lat: 48.603342, lng: 18.989921 },
    { lat: 48.604407, lng: 18.989371 },
    { lat: 48.604935, lng: 18.989008 },
    { lat: 48.605281, lng: 18.988819 },
    { lat: 48.606128, lng: 18.988502 },
    { lat: 48.607682, lng: 18.987876 },
    { lat: 48.608332, lng: 18.987779 },
    { lat: 48.608358, lng: 18.987775 },
    { lat: 48.608677, lng: 18.987469 },
    { lat: 48.608862, lng: 18.987367 },
    { lat: 48.610353, lng: 18.986638 },
    { lat: 48.610532, lng: 18.986578 },
    { lat: 48.610641, lng: 18.986697 },
    { lat: 48.611009, lng: 18.986563 },
    { lat: 48.611242, lng: 18.986245 },
    { lat: 48.61152, lng: 18.985983 },
    { lat: 48.611611, lng: 18.985793 },
    { lat: 48.611965, lng: 18.985802 },
    { lat: 48.612572, lng: 18.98621 },
    { lat: 48.612898, lng: 18.986212 },
    { lat: 48.613304, lng: 18.98638 },
    { lat: 48.613661, lng: 18.986541 },
    { lat: 48.613814, lng: 18.986701 },
    { lat: 48.614322, lng: 18.987245 },
    { lat: 48.615048, lng: 18.987874 },
    { lat: 48.615674, lng: 18.988101 },
    { lat: 48.615846, lng: 18.988184 },
    { lat: 48.615959, lng: 18.988241 },
    { lat: 48.616291, lng: 18.988227 },
    { lat: 48.616495, lng: 18.988164 },
    { lat: 48.61692, lng: 18.988083 },
    { lat: 48.617977, lng: 18.987998 },
    { lat: 48.618403, lng: 18.987972 },
    { lat: 48.61872, lng: 18.987949 },
    { lat: 48.619399, lng: 18.988 },
    { lat: 48.620911, lng: 18.988216 },
    { lat: 48.621483, lng: 18.988383 },
    { lat: 48.62204, lng: 18.988303 },
    { lat: 48.622485, lng: 18.988294 },
    { lat: 48.62291, lng: 18.988384 },
    { lat: 48.623288, lng: 18.988472 },
    { lat: 48.624222, lng: 18.988557 },
    { lat: 48.625114, lng: 18.989108 },
    { lat: 48.626211, lng: 18.989679 },
    { lat: 48.627252, lng: 18.989558 },
    { lat: 48.627688, lng: 18.989564 },
    { lat: 48.628219, lng: 18.989674 },
    { lat: 48.628419, lng: 18.9898 },
    { lat: 48.629181, lng: 18.99 },
    { lat: 48.629323, lng: 18.989952 },
    { lat: 48.6296831, lng: 18.9896672 },
    { lat: 48.6297433, lng: 18.9894336 },
    { lat: 48.6299401, lng: 18.9886463 },
    { lat: 48.630023, lng: 18.9882536 },
    { lat: 48.6301029, lng: 18.9878733 },
    { lat: 48.6301886, lng: 18.9874286 },
    { lat: 48.630164, lng: 18.9871898 },
    { lat: 48.630236, lng: 18.9868079 },
    { lat: 48.6303411, lng: 18.9863221 },
    { lat: 48.6307273, lng: 18.98558 },
    { lat: 48.6307374, lng: 18.985114 },
    { lat: 48.6307014, lng: 18.9842449 },
    { lat: 48.6307683, lng: 18.9840091 },
    { lat: 48.6314997, lng: 18.9838027 },
    { lat: 48.6316229, lng: 18.9835432 },
    { lat: 48.631469, lng: 18.9833974 },
    { lat: 48.631396, lng: 18.982961 },
    { lat: 48.6312221, lng: 18.9826018 },
    { lat: 48.6313195, lng: 18.9824541 },
    { lat: 48.6315286, lng: 18.9826398 },
    { lat: 48.6317677, lng: 18.9823096 },
    { lat: 48.6318406, lng: 18.9819023 },
    { lat: 48.6317329, lng: 18.9814888 },
    { lat: 48.6318964, lng: 18.9815123 },
    { lat: 48.6320479, lng: 18.9814292 },
    { lat: 48.6321775, lng: 18.9812752 },
    { lat: 48.6321754, lng: 18.9804567 },
    { lat: 48.6317914, lng: 18.9800217 },
    { lat: 48.6317546, lng: 18.9796748 },
    { lat: 48.6315153, lng: 18.9795118 },
    { lat: 48.631599, lng: 18.9791881 },
    { lat: 48.6317985, lng: 18.9791625 },
    { lat: 48.6318116, lng: 18.9792427 },
    { lat: 48.6318934, lng: 18.9792122 },
    { lat: 48.6318257, lng: 18.978767 },
    { lat: 48.6320021, lng: 18.9785316 },
    { lat: 48.6319607, lng: 18.9782709 },
    { lat: 48.6318095, lng: 18.9780477 },
    { lat: 48.6318188, lng: 18.9778535 },
    { lat: 48.6318233, lng: 18.9777842 },
    { lat: 48.6318293, lng: 18.9776142 },
    { lat: 48.6317171, lng: 18.9773174 },
    { lat: 48.6318874, lng: 18.9771633 },
    { lat: 48.632005, lng: 18.9771707 },
    { lat: 48.632142, lng: 18.9770907 },
    { lat: 48.6319862, lng: 18.976619 },
    { lat: 48.6320184, lng: 18.9760995 },
    { lat: 48.6320761, lng: 18.9759292 },
    { lat: 48.6320955, lng: 18.9758407 },
    { lat: 48.6322331, lng: 18.9754434 },
    { lat: 48.6321258, lng: 18.9749543 },
    { lat: 48.6319425, lng: 18.9744946 },
    { lat: 48.6318711, lng: 18.9738663 },
    { lat: 48.6314444, lng: 18.9732402 },
    { lat: 48.6314696, lng: 18.9730669 },
    { lat: 48.6314101, lng: 18.9725833 },
    { lat: 48.6315476, lng: 18.972453 },
    { lat: 48.6313106, lng: 18.9723371 },
    { lat: 48.6311477, lng: 18.9717721 },
    { lat: 48.6311026, lng: 18.971484 },
    { lat: 48.6312106, lng: 18.9712587 },
    { lat: 48.6311525, lng: 18.9710911 },
    { lat: 48.6311367, lng: 18.9710445 },
    { lat: 48.6310773, lng: 18.9708753 },
    { lat: 48.6310202, lng: 18.9707482 },
    { lat: 48.6308081, lng: 18.9702787 },
    { lat: 48.6304722, lng: 18.9693987 },
    { lat: 48.6303821, lng: 18.9688221 },
    { lat: 48.6303265, lng: 18.9679275 },
    { lat: 48.6303032, lng: 18.9672066 },
    { lat: 48.6303091, lng: 18.9670804 },
    { lat: 48.630344, lng: 18.9663924 },
    { lat: 48.630298, lng: 18.9655765 },
    { lat: 48.6300735, lng: 18.9642014 },
    { lat: 48.6301555, lng: 18.9629322 },
    { lat: 48.6301382, lng: 18.9622801 },
    { lat: 48.6299632, lng: 18.9611702 },
    { lat: 48.6298589, lng: 18.9609171 },
    { lat: 48.6298237, lng: 18.9608249 },
    { lat: 48.6297087, lng: 18.9605378 },
    { lat: 48.6296579, lng: 18.9603148 },
    { lat: 48.6296641, lng: 18.9601673 },
    { lat: 48.629763, lng: 18.9595873 },
    { lat: 48.6297752, lng: 18.9593385 },
    { lat: 48.6296929, lng: 18.9584338 },
    { lat: 48.6296515, lng: 18.9579748 },
    { lat: 48.6296093, lng: 18.9571699 },
    { lat: 48.6296924, lng: 18.9564135 },
    { lat: 48.629685, lng: 18.9557343 },
    { lat: 48.6297271, lng: 18.9555869 },
    { lat: 48.6296971, lng: 18.955508 },
    { lat: 48.6297561, lng: 18.9553404 },
    { lat: 48.6297466, lng: 18.9553305 },
    { lat: 48.6295705, lng: 18.9553466 },
    { lat: 48.629414, lng: 18.9553744 },
    { lat: 48.6292001, lng: 18.9552934 },
    { lat: 48.62903, lng: 18.9551827 },
    { lat: 48.6288265, lng: 18.9551724 },
    { lat: 48.6286849, lng: 18.9551618 },
    { lat: 48.6285188, lng: 18.9550224 },
    { lat: 48.6282426, lng: 18.9548877 },
    { lat: 48.6281474, lng: 18.954856 },
    { lat: 48.6278506, lng: 18.9546969 },
    { lat: 48.6276539, lng: 18.9546912 },
    { lat: 48.6275321, lng: 18.9546875 },
    { lat: 48.6273021, lng: 18.9544444 },
    { lat: 48.6271984, lng: 18.9543342 },
    { lat: 48.6271584, lng: 18.9543242 },
    { lat: 48.6269984, lng: 18.9542815 },
    { lat: 48.6269948, lng: 18.9542805 },
    { lat: 48.6267333, lng: 18.9541476 },
    { lat: 48.6266929, lng: 18.954086 },
    { lat: 48.6266223, lng: 18.9539819 },
    { lat: 48.6264685, lng: 18.9537538 },
    { lat: 48.6264304, lng: 18.9537109 },
    { lat: 48.6263236, lng: 18.9535632 },
    { lat: 48.6261898, lng: 18.9532612 },
    { lat: 48.625987, lng: 18.9534209 },
    { lat: 48.6256852, lng: 18.9535814 },
    { lat: 48.6253322, lng: 18.953581 },
    { lat: 48.624972, lng: 18.9532821 },
    { lat: 48.6247661, lng: 18.9532012 },
    { lat: 48.6245808, lng: 18.9532602 },
    { lat: 48.6243906, lng: 18.9534701 },
    { lat: 48.6243027, lng: 18.9535106 },
    { lat: 48.6241704, lng: 18.9533944 },
    { lat: 48.6239045, lng: 18.9533757 },
    { lat: 48.6236714, lng: 18.953339 },
    { lat: 48.6234647, lng: 18.9533541 },
    { lat: 48.6233186, lng: 18.9534277 },
    { lat: 48.6231357, lng: 18.9536678 },
    { lat: 48.6227465, lng: 18.9537953 },
    { lat: 48.6224999, lng: 18.9537776 },
    { lat: 48.6222966, lng: 18.9535724 },
    { lat: 48.6219751, lng: 18.9532177 },
    { lat: 48.6218321, lng: 18.9531783 },
    { lat: 48.6217499, lng: 18.9532256 },
    { lat: 48.6216889, lng: 18.9532199 },
    { lat: 48.62137, lng: 18.9533136 },
    { lat: 48.6211429, lng: 18.9533688 },
    { lat: 48.62105, lng: 18.95334 },
    { lat: 48.6208036, lng: 18.9532628 },
    { lat: 48.6206205, lng: 18.9532071 },
    { lat: 48.6205936, lng: 18.9531464 },
    { lat: 48.6205508, lng: 18.9531934 },
    { lat: 48.6203623, lng: 18.9531423 },
    { lat: 48.6203195, lng: 18.9531234 },
    { lat: 48.6200718, lng: 18.9529978 },
    { lat: 48.6193951, lng: 18.9524602 },
    { lat: 48.6192765, lng: 18.9524144 },
    { lat: 48.6192597, lng: 18.9523943 },
    { lat: 48.6190378, lng: 18.952398 },
    { lat: 48.6186733, lng: 18.9524791 },
    { lat: 48.618324, lng: 18.952597 },
    { lat: 48.6181341, lng: 18.9525656 },
    { lat: 48.6174901, lng: 18.952119 },
    { lat: 48.6173425, lng: 18.952253 },
    { lat: 48.6171609, lng: 18.9522738 },
    { lat: 48.6160566, lng: 18.9518446 },
    { lat: 48.6155697, lng: 18.9515869 },
    { lat: 48.6153694, lng: 18.9516575 },
    { lat: 48.6153315, lng: 18.9516687 },
    { lat: 48.6149528, lng: 18.9518032 },
    { lat: 48.6138499, lng: 18.9521974 },
    { lat: 48.6134497, lng: 18.9521117 },
    { lat: 48.6134397, lng: 18.9520914 },
    { lat: 48.6131625, lng: 18.9515396 },
    { lat: 48.6131323, lng: 18.951526 },
    { lat: 48.6130716, lng: 18.9514621 },
    { lat: 48.6128565, lng: 18.9513578 },
    { lat: 48.6127357, lng: 18.9512218 },
    { lat: 48.6125974, lng: 18.9510967 },
    { lat: 48.6125716, lng: 18.9510762 },
    { lat: 48.6124136, lng: 18.95105 },
    { lat: 48.6122887, lng: 18.951098 },
    { lat: 48.612095, lng: 18.9511729 },
    { lat: 48.6120187, lng: 18.9512238 },
    { lat: 48.6119517, lng: 18.9513003 },
    { lat: 48.6119195, lng: 18.9513785 },
    { lat: 48.6118793, lng: 18.9514741 },
    { lat: 48.6118501, lng: 18.9515641 },
    { lat: 48.6117754, lng: 18.9516921 },
    { lat: 48.6116386, lng: 18.9517714 },
    { lat: 48.6116144, lng: 18.9517845 },
    { lat: 48.6111453, lng: 18.9514531 },
    { lat: 48.6105463, lng: 18.9508499 },
    { lat: 48.6102743, lng: 18.9503588 },
    { lat: 48.6095402, lng: 18.9499848 },
    { lat: 48.6095182, lng: 18.9499706 },
    { lat: 48.6094441, lng: 18.949935 },
    { lat: 48.6088887, lng: 18.9492567 },
    { lat: 48.6083905, lng: 18.9482905 },
    { lat: 48.6077229, lng: 18.9479135 },
    { lat: 48.6068626, lng: 18.947604 },
    { lat: 48.6061214, lng: 18.9469989 },
    { lat: 48.6058468, lng: 18.946128 },
    { lat: 48.6048597, lng: 18.9458515 },
    { lat: 48.604276, lng: 18.9457284 },
    { lat: 48.6039934, lng: 18.9455806 },
    { lat: 48.6039689, lng: 18.9455454 },
    { lat: 48.6039334, lng: 18.9454665 },
    { lat: 48.6038738, lng: 18.9454688 },
    { lat: 48.6037382, lng: 18.9452896 },
    { lat: 48.603194, lng: 18.945427 },
    { lat: 48.6030009, lng: 18.9456842 },
    { lat: 48.6028758, lng: 18.9457108 },
    { lat: 48.6024321, lng: 18.9458069 },
    { lat: 48.6023023, lng: 18.9456539 },
    { lat: 48.6016683, lng: 18.9452263 },
    { lat: 48.6013446, lng: 18.9457078 },
    { lat: 48.6012546, lng: 18.9457346 },
    { lat: 48.6008021, lng: 18.9454434 },
    { lat: 48.6005157, lng: 18.9454667 },
    { lat: 48.6004757, lng: 18.945455 },
    { lat: 48.5997306, lng: 18.9452356 },
    { lat: 48.5992065, lng: 18.9453271 },
    { lat: 48.5989508, lng: 18.9452663 },
    { lat: 48.5984008, lng: 18.9449426 },
    { lat: 48.5978999, lng: 18.9448922 },
    { lat: 48.597087, lng: 18.9454857 },
    { lat: 48.5956927, lng: 18.945652 },
    { lat: 48.59563, lng: 18.9456592 },
    { lat: 48.5950494, lng: 18.945576 },
    { lat: 48.5947303, lng: 18.9455301 },
    { lat: 48.5945848, lng: 18.9455089 },
    { lat: 48.5945428, lng: 18.9454368 },
    { lat: 48.5939873, lng: 18.9457322 },
    { lat: 48.5936775, lng: 18.9456018 },
    { lat: 48.5935154, lng: 18.9456046 },
    { lat: 48.5929404, lng: 18.9462043 },
    { lat: 48.5924499, lng: 18.9462263 },
    { lat: 48.5921687, lng: 18.9459067 },
    { lat: 48.5920456, lng: 18.9459709 },
    { lat: 48.5918132, lng: 18.9459455 },
    { lat: 48.5911769, lng: 18.9464689 },
    { lat: 48.5910944, lng: 18.9465971 },
    { lat: 48.5909953, lng: 18.9467525 },
    { lat: 48.5909795, lng: 18.9467761 },
    { lat: 48.5909655, lng: 18.9467888 },
    { lat: 48.5909194, lng: 18.9468978 },
    { lat: 48.590825, lng: 18.9470815 },
    { lat: 48.590814, lng: 18.9471116 },
    { lat: 48.5908018, lng: 18.947181 },
    { lat: 48.590795, lng: 18.9472246 },
    { lat: 48.5907131, lng: 18.9475179 },
    { lat: 48.5905516, lng: 18.9477557 },
    { lat: 48.5903465, lng: 18.9478704 },
    { lat: 48.5901556, lng: 18.9480599 },
    { lat: 48.590075, lng: 18.9482039 },
    { lat: 48.5899427, lng: 18.948329 },
    { lat: 48.589595, lng: 18.9484555 },
    { lat: 48.5894735, lng: 18.9485224 },
    { lat: 48.5893219, lng: 18.9486276 },
    { lat: 48.5891925, lng: 18.9487202 },
    { lat: 48.5889873, lng: 18.948853 },
    { lat: 48.5888282, lng: 18.9489616 },
    { lat: 48.5886802, lng: 18.949107 },
    { lat: 48.5885908, lng: 18.949236 },
    { lat: 48.5884842, lng: 18.9494207 },
    { lat: 48.588461, lng: 18.9494516 },
    { lat: 48.5884295, lng: 18.9494773 },
    { lat: 48.5883908, lng: 18.9495345 },
    { lat: 48.5883632, lng: 18.9495673 },
    { lat: 48.5883503, lng: 18.9495834 },
    { lat: 48.5882774, lng: 18.949678 },
    { lat: 48.5882535, lng: 18.9496726 },
    { lat: 48.5882416, lng: 18.949684 },
    { lat: 48.5881675, lng: 18.9497563 },
    { lat: 48.5881085, lng: 18.9497938 },
    { lat: 48.5880988, lng: 18.9498038 },
    { lat: 48.5880321, lng: 18.9498201 },
    { lat: 48.5880247, lng: 18.9498274 },
    { lat: 48.5880159, lng: 18.9498305 },
    { lat: 48.5879747, lng: 18.9498442 },
    { lat: 48.5879716, lng: 18.9498467 },
    { lat: 48.587937, lng: 18.9498785 },
    { lat: 48.5879338, lng: 18.9498807 },
    { lat: 48.5879075, lng: 18.9498976 },
    { lat: 48.5877156, lng: 18.9500253 },
    { lat: 48.5875045, lng: 18.9500413 },
  ],
  Bzenica: [
    { lat: 48.506961, lng: 18.729254 },
    { lat: 48.504326, lng: 18.730029 },
    { lat: 48.502119, lng: 18.730678 },
    { lat: 48.501125, lng: 18.730979 },
    { lat: 48.501027, lng: 18.731017 },
    { lat: 48.500813, lng: 18.731098 },
    { lat: 48.500653, lng: 18.731159 },
    { lat: 48.500048, lng: 18.731314 },
    { lat: 48.497561, lng: 18.730711 },
    { lat: 48.497496, lng: 18.73091 },
    { lat: 48.49745, lng: 18.731049 },
    { lat: 48.497424, lng: 18.731131 },
    { lat: 48.497387, lng: 18.731249 },
    { lat: 48.497285, lng: 18.731562 },
    { lat: 48.497171, lng: 18.731913 },
    { lat: 48.497131, lng: 18.732039 },
    { lat: 48.497063, lng: 18.732126 },
    { lat: 48.497061, lng: 18.732127 },
    { lat: 48.496622, lng: 18.732689 },
    { lat: 48.49662, lng: 18.73269 },
    { lat: 48.496588, lng: 18.732732 },
    { lat: 48.495616, lng: 18.73203 },
    { lat: 48.494278, lng: 18.73154 },
    { lat: 48.493245, lng: 18.731663 },
    { lat: 48.492566, lng: 18.731713 },
    { lat: 48.490079, lng: 18.73225 },
    { lat: 48.490083, lng: 18.732315 },
    { lat: 48.490137, lng: 18.733866 },
    { lat: 48.490138, lng: 18.734007 },
    { lat: 48.489764, lng: 18.735404 },
    { lat: 48.489718, lng: 18.735553 },
    { lat: 48.489572, lng: 18.736162 },
    { lat: 48.489517, lng: 18.736501 },
    { lat: 48.489495, lng: 18.736831 },
    { lat: 48.489243, lng: 18.73823 },
    { lat: 48.488924, lng: 18.739027 },
    { lat: 48.488716, lng: 18.739704 },
    { lat: 48.488698, lng: 18.740249 },
    { lat: 48.488619, lng: 18.740859 },
    { lat: 48.488617, lng: 18.74097 },
    { lat: 48.488584, lng: 18.74146 },
    { lat: 48.488544, lng: 18.741658 },
    { lat: 48.488605, lng: 18.74188 },
    { lat: 48.488615, lng: 18.742043 },
    { lat: 48.488624, lng: 18.742192 },
    { lat: 48.488665, lng: 18.743197 },
    { lat: 48.488665, lng: 18.743217 },
    { lat: 48.488698, lng: 18.744171 },
    { lat: 48.488924, lng: 18.745032 },
    { lat: 48.489176, lng: 18.746423 },
    { lat: 48.4896335, lng: 18.7470692 },
    { lat: 48.489928, lng: 18.747485 },
    { lat: 48.489866, lng: 18.748047 },
    { lat: 48.489852, lng: 18.74859 },
    { lat: 48.489892, lng: 18.749131 },
    { lat: 48.490292, lng: 18.751458 },
    { lat: 48.490056, lng: 18.752332 },
    { lat: 48.490302, lng: 18.754551 },
    { lat: 48.490044, lng: 18.755215 },
    { lat: 48.490031, lng: 18.756191 },
    { lat: 48.490723, lng: 18.756455 },
    { lat: 48.4916561, lng: 18.756301 },
    { lat: 48.4920423, lng: 18.7559451 },
    { lat: 48.4925454, lng: 18.7553103 },
    { lat: 48.4938796, lng: 18.7568018 },
    { lat: 48.4942401, lng: 18.7569247 },
    { lat: 48.4946816, lng: 18.7570197 },
    { lat: 48.4949779, lng: 18.7578046 },
    { lat: 48.4952881, lng: 18.7581888 },
    { lat: 48.4962605, lng: 18.7587064 },
    { lat: 48.4966821, lng: 18.7589784 },
    { lat: 48.4970384, lng: 18.7589774 },
    { lat: 48.4971263, lng: 18.7589776 },
    { lat: 48.4976314, lng: 18.7589758 },
    { lat: 48.4978353, lng: 18.7589521 },
    { lat: 48.4987988, lng: 18.75851 },
    { lat: 48.4990358, lng: 18.7585535 },
    { lat: 48.4996098, lng: 18.7587287 },
    { lat: 48.5000116, lng: 18.7589431 },
    { lat: 48.5005958, lng: 18.7590968 },
    { lat: 48.5010802, lng: 18.7592334 },
    { lat: 48.5022486, lng: 18.758935 },
    { lat: 48.5023363, lng: 18.759039 },
    { lat: 48.5028659, lng: 18.7594763 },
    { lat: 48.5031453, lng: 18.7596032 },
    { lat: 48.5041212, lng: 18.7587962 },
    { lat: 48.505598, lng: 18.7571026 },
    { lat: 48.5063546, lng: 18.7579954 },
    { lat: 48.5066197, lng: 18.7582934 },
    { lat: 48.5069887, lng: 18.758623 },
    { lat: 48.5071976, lng: 18.7592442 },
    { lat: 48.5075473, lng: 18.7599503 },
    { lat: 48.5079224, lng: 18.7606277 },
    { lat: 48.5083224, lng: 18.7613007 },
    { lat: 48.5088944, lng: 18.7620777 },
    { lat: 48.5091889, lng: 18.762656 },
    { lat: 48.5097104, lng: 18.7637552 },
    { lat: 48.5099835, lng: 18.7642294 },
    { lat: 48.5102068, lng: 18.765508 },
    { lat: 48.5101319, lng: 18.7664178 },
    { lat: 48.5097628, lng: 18.7676943 },
    { lat: 48.5097774, lng: 18.7677623 },
    { lat: 48.5097907, lng: 18.7678379 },
    { lat: 48.5098254, lng: 18.7683266 },
    { lat: 48.5098761, lng: 18.7688164 },
    { lat: 48.510008, lng: 18.7691367 },
    { lat: 48.5101083, lng: 18.7695402 },
    { lat: 48.5103219, lng: 18.7702573 },
    { lat: 48.510362, lng: 18.771148 },
    { lat: 48.5104837, lng: 18.771551 },
    { lat: 48.5108014, lng: 18.771952 },
    { lat: 48.5112043, lng: 18.7725114 },
    { lat: 48.5113493, lng: 18.7727839 },
    { lat: 48.5115282, lng: 18.77303 },
    { lat: 48.5117708, lng: 18.7734812 },
    { lat: 48.5118982, lng: 18.7742317 },
    { lat: 48.5126347, lng: 18.7748978 },
    { lat: 48.51252, lng: 18.7750083 },
    { lat: 48.5124746, lng: 18.7750486 },
    { lat: 48.512422, lng: 18.7751847 },
    { lat: 48.5123546, lng: 18.7753585 },
    { lat: 48.51228, lng: 18.7756779 },
    { lat: 48.5121768, lng: 18.7759658 },
    { lat: 48.5120586, lng: 18.7766568 },
    { lat: 48.512149, lng: 18.7767225 },
    { lat: 48.5122274, lng: 18.7767789 },
    { lat: 48.5122852, lng: 18.7768439 },
    { lat: 48.5123034, lng: 18.7768712 },
    { lat: 48.5123242, lng: 18.7769036 },
    { lat: 48.5123975, lng: 18.7770065 },
    { lat: 48.5125638, lng: 18.7772115 },
    { lat: 48.5125892, lng: 18.7772454 },
    { lat: 48.5125991, lng: 18.7772585 },
    { lat: 48.5126719, lng: 18.7773568 },
    { lat: 48.5126709, lng: 18.777384 },
    { lat: 48.5126711, lng: 18.7774729 },
    { lat: 48.51267, lng: 18.7775139 },
    { lat: 48.5126703, lng: 18.7775651 },
    { lat: 48.5126214, lng: 18.7778352 },
    { lat: 48.5125606, lng: 18.7781793 },
    { lat: 48.5130109, lng: 18.7781231 },
    { lat: 48.5132182, lng: 18.7780969 },
    { lat: 48.5133511, lng: 18.7781127 },
    { lat: 48.5134605, lng: 18.7781114 },
    { lat: 48.5137998, lng: 18.7784351 },
    { lat: 48.5139429, lng: 18.7785166 },
    { lat: 48.5142883, lng: 18.7789065 },
    { lat: 48.5146211, lng: 18.7794007 },
    { lat: 48.5149467, lng: 18.7798274 },
    { lat: 48.515298, lng: 18.7801355 },
    { lat: 48.5154737, lng: 18.779894 },
    { lat: 48.5155673, lng: 18.7793827 },
    { lat: 48.5154785, lng: 18.7782309 },
    { lat: 48.5157147, lng: 18.7778972 },
    { lat: 48.5158702, lng: 18.7772106 },
    { lat: 48.5165266, lng: 18.7761804 },
    { lat: 48.5171442, lng: 18.7761743 },
    { lat: 48.5180728, lng: 18.7764014 },
    { lat: 48.5183, lng: 18.7763226 },
    { lat: 48.5188431, lng: 18.7751558 },
    { lat: 48.5188744, lng: 18.7752179 },
    { lat: 48.5188985, lng: 18.7752655 },
    { lat: 48.5190429, lng: 18.7755499 },
    { lat: 48.5192233, lng: 18.7759762 },
    { lat: 48.5193045, lng: 18.7761241 },
    { lat: 48.5199924, lng: 18.7781163 },
    { lat: 48.519979, lng: 18.7784626 },
    { lat: 48.5199735, lng: 18.7786137 },
    { lat: 48.5199511, lng: 18.7791539 },
    { lat: 48.5194315, lng: 18.7799946 },
    { lat: 48.5192155, lng: 18.7801562 },
    { lat: 48.5191462, lng: 18.7802079 },
    { lat: 48.5188652, lng: 18.7804185 },
    { lat: 48.5189113, lng: 18.7807371 },
    { lat: 48.5189221, lng: 18.7808127 },
    { lat: 48.5189633, lng: 18.7811023 },
    { lat: 48.5189377, lng: 18.7816265 },
    { lat: 48.5191352, lng: 18.7816925 },
    { lat: 48.5191696, lng: 18.7817044 },
    { lat: 48.5196186, lng: 18.7818562 },
    { lat: 48.5199988, lng: 18.7820708 },
    { lat: 48.5199496, lng: 18.7827035 },
    { lat: 48.5197179, lng: 18.7833569 },
    { lat: 48.5198008, lng: 18.7834548 },
    { lat: 48.5198319, lng: 18.7834891 },
    { lat: 48.5202671, lng: 18.7832729 },
    { lat: 48.5205628, lng: 18.7830185 },
    { lat: 48.5208, lng: 18.7827238 },
    { lat: 48.5212009, lng: 18.7825425 },
    { lat: 48.522733, lng: 18.7801766 },
    { lat: 48.5224769, lng: 18.779702 },
    { lat: 48.5222104, lng: 18.7795474 },
    { lat: 48.5218964, lng: 18.7791935 },
    { lat: 48.5217393, lng: 18.7789508 },
    { lat: 48.5217109, lng: 18.7784345 },
    { lat: 48.5217839, lng: 18.7776129 },
    { lat: 48.5216215, lng: 18.7771847 },
    { lat: 48.5214514, lng: 18.7767205 },
    { lat: 48.5213958, lng: 18.7763063 },
    { lat: 48.5213392, lng: 18.7755308 },
    { lat: 48.521328, lng: 18.7748029 },
    { lat: 48.5213642, lng: 18.7745478 },
    { lat: 48.5216317, lng: 18.7741035 },
    { lat: 48.5217673, lng: 18.7726956 },
    { lat: 48.521756, lng: 18.772269 },
    { lat: 48.5219908, lng: 18.7721026 },
    { lat: 48.522514, lng: 18.7717381 },
    { lat: 48.5229487, lng: 18.7713603 },
    { lat: 48.523431, lng: 18.7709921 },
    { lat: 48.5237495, lng: 18.7706146 },
    { lat: 48.523774, lng: 18.7705857 },
    { lat: 48.5239522, lng: 18.7703752 },
    { lat: 48.5244349, lng: 18.7705039 },
    { lat: 48.5249259, lng: 18.7705968 },
    { lat: 48.525357, lng: 18.7706644 },
    { lat: 48.5257515, lng: 18.771178 },
    { lat: 48.5260635, lng: 18.7714876 },
    { lat: 48.5265776, lng: 18.7719566 },
    { lat: 48.5267605, lng: 18.7720468 },
    { lat: 48.5273172, lng: 18.7723187 },
    { lat: 48.5275063, lng: 18.7723736 },
    { lat: 48.5280034, lng: 18.7727113 },
    { lat: 48.5282321, lng: 18.7727509 },
    { lat: 48.5282545, lng: 18.7725981 },
    { lat: 48.5285312, lng: 18.7721352 },
    { lat: 48.5286764, lng: 18.7718734 },
    { lat: 48.5287008, lng: 18.7717175 },
    { lat: 48.528592, lng: 18.7713843 },
    { lat: 48.528349, lng: 18.7706915 },
    { lat: 48.5283997, lng: 18.7703044 },
    { lat: 48.5288248, lng: 18.7698109 },
    { lat: 48.5288222, lng: 18.7696134 },
    { lat: 48.5288218, lng: 18.7696064 },
    { lat: 48.5287935, lng: 18.7693881 },
    { lat: 48.5289197, lng: 18.7692791 },
    { lat: 48.5290975, lng: 18.7691251 },
    { lat: 48.5292598, lng: 18.7688634 },
    { lat: 48.5293688, lng: 18.7687476 },
    { lat: 48.5294893, lng: 18.7683396 },
    { lat: 48.5295295, lng: 18.7682249 },
    { lat: 48.5296441, lng: 18.768045 },
    { lat: 48.5297473, lng: 18.7679715 },
    { lat: 48.5298799, lng: 18.7678788 },
    { lat: 48.5300037, lng: 18.7678092 },
    { lat: 48.5301462, lng: 18.767689 },
    { lat: 48.5302611, lng: 18.7675148 },
    { lat: 48.5303152, lng: 18.7674307 },
    { lat: 48.5304112, lng: 18.7673684 },
    { lat: 48.5304927, lng: 18.7673718 },
    { lat: 48.530541, lng: 18.767324 },
    { lat: 48.5307351, lng: 18.7672866 },
    { lat: 48.5308352, lng: 18.7672387 },
    { lat: 48.5309069, lng: 18.7672102 },
    { lat: 48.530998, lng: 18.7671402 },
    { lat: 48.5310444, lng: 18.767034 },
    { lat: 48.531145, lng: 18.7669116 },
    { lat: 48.5313414, lng: 18.7666796 },
    { lat: 48.531357, lng: 18.7666529 },
    { lat: 48.5314193, lng: 18.7665746 },
    { lat: 48.5314496, lng: 18.7665482 },
    { lat: 48.5315041, lng: 18.7664485 },
    { lat: 48.5321385, lng: 18.7656706 },
    { lat: 48.5321418, lng: 18.765667 },
    { lat: 48.5331537, lng: 18.764435 },
    { lat: 48.5334007, lng: 18.7641354 },
    { lat: 48.5335353, lng: 18.7639727 },
    { lat: 48.5341141, lng: 18.7632701 },
    { lat: 48.5341339, lng: 18.7632207 },
    { lat: 48.5342924, lng: 18.7630623 },
    { lat: 48.536259, lng: 18.7606214 },
    { lat: 48.5363459, lng: 18.7605177 },
    { lat: 48.5363858, lng: 18.7604806 },
    { lat: 48.5364254, lng: 18.7604427 },
    { lat: 48.5364936, lng: 18.7603873 },
    { lat: 48.536524, lng: 18.7603626 },
    { lat: 48.5368287, lng: 18.7601141 },
    { lat: 48.5368871, lng: 18.7600665 },
    { lat: 48.5376894, lng: 18.7594117 },
    { lat: 48.5377101, lng: 18.7593797 },
    { lat: 48.5380082, lng: 18.7589159 },
    { lat: 48.53803, lng: 18.7588813 },
    { lat: 48.5372235, lng: 18.7568054 },
    { lat: 48.5367742, lng: 18.7549796 },
    { lat: 48.5366553, lng: 18.7536519 },
    { lat: 48.5369082, lng: 18.7520732 },
    { lat: 48.5369497, lng: 18.7518167 },
    { lat: 48.5371008, lng: 18.7516679 },
    { lat: 48.5385961, lng: 18.7501936 },
    { lat: 48.5400198, lng: 18.7481773 },
    { lat: 48.5402891, lng: 18.747662 },
    { lat: 48.5403057, lng: 18.7476305 },
    { lat: 48.5403374, lng: 18.7475694 },
    { lat: 48.5405544, lng: 18.7471551 },
    { lat: 48.5409377, lng: 18.7459137 },
    { lat: 48.5410994, lng: 18.7449003 },
    { lat: 48.540943, lng: 18.7432229 },
    { lat: 48.5406818, lng: 18.7425514 },
    { lat: 48.5399628, lng: 18.7417501 },
    { lat: 48.5393527, lng: 18.741369 },
    { lat: 48.5389976, lng: 18.7410949 },
    { lat: 48.5379, lng: 18.7400481 },
    { lat: 48.5375456, lng: 18.7367629 },
    { lat: 48.5375649, lng: 18.7367026 },
    { lat: 48.538163, lng: 18.7370674 },
    { lat: 48.5385289, lng: 18.7372914 },
    { lat: 48.5385359, lng: 18.7372948 },
    { lat: 48.5385721, lng: 18.7373171 },
    { lat: 48.5385755, lng: 18.7373199 },
    { lat: 48.5386933, lng: 18.737393 },
    { lat: 48.5397146, lng: 18.7382798 },
    { lat: 48.5397773, lng: 18.7383339 },
    { lat: 48.5398763, lng: 18.7384996 },
    { lat: 48.540016, lng: 18.7387305 },
    { lat: 48.5403497, lng: 18.7392847 },
    { lat: 48.5406311, lng: 18.7390601 },
    { lat: 48.5406322, lng: 18.7390593 },
    { lat: 48.5408796, lng: 18.7388751 },
    { lat: 48.5409378, lng: 18.7387386 },
    { lat: 48.5410445, lng: 18.7384951 },
    { lat: 48.542071, lng: 18.736162 },
    { lat: 48.5421578, lng: 18.7359491 },
    { lat: 48.5424769, lng: 18.7351605 },
    { lat: 48.5425089, lng: 18.7350833 },
    { lat: 48.542513, lng: 18.735075 },
    { lat: 48.5426142, lng: 18.734835 },
    { lat: 48.5427493, lng: 18.734513 },
    { lat: 48.5427514, lng: 18.7345085 },
    { lat: 48.5429909, lng: 18.7339398 },
    { lat: 48.5432422, lng: 18.7333441 },
    { lat: 48.5432703, lng: 18.7332766 },
    { lat: 48.5438824, lng: 18.7318245 },
    { lat: 48.544583, lng: 18.7308515 },
    { lat: 48.5448713, lng: 18.7303972 },
    { lat: 48.5450783, lng: 18.7301342 },
    { lat: 48.5449876, lng: 18.7300187 },
    { lat: 48.544568, lng: 18.7294852 },
    { lat: 48.544491, lng: 18.7291392 },
    { lat: 48.5443964, lng: 18.728716 },
    { lat: 48.5442954, lng: 18.7272803 },
    { lat: 48.5436591, lng: 18.7264497 },
    { lat: 48.5432799, lng: 18.7255081 },
    { lat: 48.5429105, lng: 18.7247105 },
    { lat: 48.5428099, lng: 18.7244937 },
    { lat: 48.5427943, lng: 18.7244771 },
    { lat: 48.5427763, lng: 18.7244569 },
    { lat: 48.5430405, lng: 18.7239332 },
    { lat: 48.5434273, lng: 18.7230815 },
    { lat: 48.5437096, lng: 18.7224737 },
    { lat: 48.5440971, lng: 18.7216366 },
    { lat: 48.5444332, lng: 18.7204044 },
    { lat: 48.544432, lng: 18.7200006 },
    { lat: 48.5439886, lng: 18.7195504 },
    { lat: 48.5435905, lng: 18.7201479 },
    { lat: 48.5426439, lng: 18.7204137 },
    { lat: 48.5414446, lng: 18.7200898 },
    { lat: 48.5406864, lng: 18.7188085 },
    { lat: 48.5414043, lng: 18.7179714 },
    { lat: 48.5422825, lng: 18.715634 },
    { lat: 48.5433005, lng: 18.7147552 },
    { lat: 48.5437229, lng: 18.7145754 },
    { lat: 48.5438557, lng: 18.7137667 },
    { lat: 48.5439845, lng: 18.7131757 },
    { lat: 48.5441232, lng: 18.71254 },
    { lat: 48.5440776, lng: 18.7112179 },
    { lat: 48.544328, lng: 18.7107314 },
    { lat: 48.5461772, lng: 18.708795 },
    { lat: 48.5470884, lng: 18.7084101 },
    { lat: 48.5471325, lng: 18.7083916 },
    { lat: 48.5474457, lng: 18.7082595 },
    { lat: 48.5481072, lng: 18.7082906 },
    { lat: 48.548581, lng: 18.7081483 },
    { lat: 48.5489616, lng: 18.7081992 },
    { lat: 48.5496045, lng: 18.7076285 },
    { lat: 48.550884, lng: 18.7072604 },
    { lat: 48.5518597, lng: 18.7072397 },
    { lat: 48.552001, lng: 18.707098 },
    { lat: 48.5522458, lng: 18.7070574 },
    { lat: 48.5524902, lng: 18.7068392 },
    { lat: 48.5530405, lng: 18.7070504 },
    { lat: 48.5532872, lng: 18.7070013 },
    { lat: 48.5533442, lng: 18.7068649 },
    { lat: 48.5534931, lng: 18.7064439 },
    { lat: 48.5535263, lng: 18.7058998 },
    { lat: 48.5535284, lng: 18.7057931 },
    { lat: 48.5535521, lng: 18.705262 },
    { lat: 48.5535563, lng: 18.705149 },
    { lat: 48.5537523, lng: 18.7044283 },
    { lat: 48.5538476, lng: 18.7040789 },
    { lat: 48.5538655, lng: 18.7040112 },
    { lat: 48.5542784, lng: 18.7024896 },
    { lat: 48.5545444, lng: 18.7012777 },
    { lat: 48.55462, lng: 18.700326 },
    { lat: 48.553204, lng: 18.69985 },
    { lat: 48.55264, lng: 18.699432 },
    { lat: 48.552236, lng: 18.699332 },
    { lat: 48.551808, lng: 18.699346 },
    { lat: 48.551478, lng: 18.699427 },
    { lat: 48.551155, lng: 18.699072 },
    { lat: 48.550181, lng: 18.698517 },
    { lat: 48.549245, lng: 18.698061 },
    { lat: 48.548705, lng: 18.698051 },
    { lat: 48.547606, lng: 18.698222 },
    { lat: 48.546481, lng: 18.698304 },
    { lat: 48.543436, lng: 18.698528 },
    { lat: 48.542843, lng: 18.698573 },
    { lat: 48.542682, lng: 18.698681 },
    { lat: 48.542569, lng: 18.698594 },
    { lat: 48.542266, lng: 18.698537 },
    { lat: 48.54222, lng: 18.698209 },
    { lat: 48.542144, lng: 18.698236 },
    { lat: 48.542083, lng: 18.698456 },
    { lat: 48.542151, lng: 18.699321 },
    { lat: 48.542156, lng: 18.699385 },
    { lat: 48.54209, lng: 18.699566 },
    { lat: 48.541906, lng: 18.700066 },
    { lat: 48.541803, lng: 18.700219 },
    { lat: 48.541652, lng: 18.700602 },
    { lat: 48.541578, lng: 18.70073 },
    { lat: 48.541459, lng: 18.700819 },
    { lat: 48.541292, lng: 18.700857 },
    { lat: 48.541238, lng: 18.70084 },
    { lat: 48.541175, lng: 18.700828 },
    { lat: 48.540858, lng: 18.700835 },
    { lat: 48.540664, lng: 18.700824 },
    { lat: 48.540406, lng: 18.700759 },
    { lat: 48.540329, lng: 18.700696 },
    { lat: 48.540172, lng: 18.700592 },
    { lat: 48.540132, lng: 18.700524 },
    { lat: 48.540027, lng: 18.700342 },
    { lat: 48.539766, lng: 18.700035 },
    { lat: 48.539672, lng: 18.699766 },
    { lat: 48.539638, lng: 18.699644 },
    { lat: 48.539352, lng: 18.699547 },
    { lat: 48.539355, lng: 18.699557 },
    { lat: 48.53892, lng: 18.699352 },
    { lat: 48.538603, lng: 18.699132 },
    { lat: 48.538477, lng: 18.699048 },
    { lat: 48.538386, lng: 18.698988 },
    { lat: 48.538313, lng: 18.698862 },
    { lat: 48.538267, lng: 18.698734 },
    { lat: 48.538191, lng: 18.69843 },
    { lat: 48.538169, lng: 18.698355 },
    { lat: 48.538118, lng: 18.698368 },
    { lat: 48.538004, lng: 18.698165 },
    { lat: 48.537547, lng: 18.698042 },
    { lat: 48.537366, lng: 18.69852 },
    { lat: 48.537245, lng: 18.698584 },
    { lat: 48.537181, lng: 18.698592 },
    { lat: 48.53716, lng: 18.698258 },
    { lat: 48.537094, lng: 18.698062 },
    { lat: 48.536944, lng: 18.697796 },
    { lat: 48.536806, lng: 18.697709 },
    { lat: 48.536643, lng: 18.697636 },
    { lat: 48.536406, lng: 18.697693 },
    { lat: 48.536391, lng: 18.697806 },
    { lat: 48.536405, lng: 18.697942 },
    { lat: 48.536428, lng: 18.698185 },
    { lat: 48.536126, lng: 18.698002 },
    { lat: 48.535877, lng: 18.697875 },
    { lat: 48.53557, lng: 18.697598 },
    { lat: 48.535317, lng: 18.697193 },
    { lat: 48.535173, lng: 18.696779 },
    { lat: 48.535014, lng: 18.696301 },
    { lat: 48.534881, lng: 18.696286 },
    { lat: 48.534858, lng: 18.695899 },
    { lat: 48.534395, lng: 18.695425 },
    { lat: 48.534089, lng: 18.69553 },
    { lat: 48.533971, lng: 18.695524 },
    { lat: 48.533943, lng: 18.696119 },
    { lat: 48.53341, lng: 18.696028 },
    { lat: 48.533359, lng: 18.696179 },
    { lat: 48.533024, lng: 18.696206 },
    { lat: 48.532818, lng: 18.696432 },
    { lat: 48.532638, lng: 18.696553 },
    { lat: 48.532299, lng: 18.696389 },
    { lat: 48.532077, lng: 18.698101 },
    { lat: 48.531922, lng: 18.699342 },
    { lat: 48.531894, lng: 18.699564 },
    { lat: 48.531829, lng: 18.699736 },
    { lat: 48.531613, lng: 18.700307 },
    { lat: 48.53153, lng: 18.700958 },
    { lat: 48.531544, lng: 18.701551 },
    { lat: 48.531248, lng: 18.70366 },
    { lat: 48.531223, lng: 18.703835 },
    { lat: 48.531181, lng: 18.704019 },
    { lat: 48.531038, lng: 18.704646 },
    { lat: 48.530946, lng: 18.705046 },
    { lat: 48.53061, lng: 18.706327 },
    { lat: 48.530369, lng: 18.706628 },
    { lat: 48.530361, lng: 18.706638 },
    { lat: 48.529802, lng: 18.707331 },
    { lat: 48.529413, lng: 18.707814 },
    { lat: 48.529331, lng: 18.707837 },
    { lat: 48.529313, lng: 18.707842 },
    { lat: 48.528587, lng: 18.708911 },
    { lat: 48.528419, lng: 18.70907 },
    { lat: 48.52836, lng: 18.709125 },
    { lat: 48.528366, lng: 18.710508 },
    { lat: 48.528751, lng: 18.711631 },
    { lat: 48.528864, lng: 18.712026 },
    { lat: 48.529363, lng: 18.712954 },
    { lat: 48.529314, lng: 18.71328 },
    { lat: 48.529277, lng: 18.713523 },
    { lat: 48.52921, lng: 18.713942 },
    { lat: 48.52919, lng: 18.71407 },
    { lat: 48.529136, lng: 18.714406 },
    { lat: 48.529076, lng: 18.714761 },
    { lat: 48.529264, lng: 18.714965 },
    { lat: 48.529466, lng: 18.715184 },
    { lat: 48.529303, lng: 18.715389 },
    { lat: 48.529121, lng: 18.715472 },
    { lat: 48.528912, lng: 18.715568 },
    { lat: 48.528929, lng: 18.715621 },
    { lat: 48.528861, lng: 18.715775 },
    { lat: 48.528491, lng: 18.717501 },
    { lat: 48.52818, lng: 18.718408 },
    { lat: 48.527969, lng: 18.719024 },
    { lat: 48.527815, lng: 18.719637 },
    { lat: 48.527814, lng: 18.719645 },
    { lat: 48.527809, lng: 18.719714 },
    { lat: 48.527757, lng: 18.720057 },
    { lat: 48.527734, lng: 18.720212 },
    { lat: 48.527727, lng: 18.720258 },
    { lat: 48.527769, lng: 18.720334 },
    { lat: 48.527772, lng: 18.72034 },
    { lat: 48.52784, lng: 18.720463 },
    { lat: 48.527913, lng: 18.720579 },
    { lat: 48.52796, lng: 18.720781 },
    { lat: 48.528049, lng: 18.721165 },
    { lat: 48.527744, lng: 18.72159 },
    { lat: 48.527726, lng: 18.721614 },
    { lat: 48.527632, lng: 18.721666 },
    { lat: 48.527427, lng: 18.721779 },
    { lat: 48.527408, lng: 18.72179 },
    { lat: 48.527423, lng: 18.721841 },
    { lat: 48.527416, lng: 18.721845 },
    { lat: 48.527282, lng: 18.721911 },
    { lat: 48.526912, lng: 18.722092 },
    { lat: 48.526705, lng: 18.722338 },
    { lat: 48.526568, lng: 18.7225 },
    { lat: 48.526461, lng: 18.722626 },
    { lat: 48.526436, lng: 18.722656 },
    { lat: 48.526407, lng: 18.722708 },
    { lat: 48.526362, lng: 18.72279 },
    { lat: 48.526, lng: 18.723448 },
    { lat: 48.525932, lng: 18.72357 },
    { lat: 48.523836, lng: 18.725092 },
    { lat: 48.5232, lng: 18.725967 },
    { lat: 48.521766, lng: 18.726977 },
    { lat: 48.521532, lng: 18.728129 },
    { lat: 48.521667, lng: 18.730546 },
    { lat: 48.521316, lng: 18.7316 },
    { lat: 48.521203, lng: 18.731911 },
    { lat: 48.52048, lng: 18.733213 },
    { lat: 48.519468, lng: 18.734516 },
    { lat: 48.519425, lng: 18.734562 },
    { lat: 48.518861, lng: 18.734934 },
    { lat: 48.518582, lng: 18.735117 },
    { lat: 48.518512, lng: 18.735163 },
    { lat: 48.518207, lng: 18.735364 },
    { lat: 48.518042, lng: 18.735472 },
    { lat: 48.516737, lng: 18.735928 },
    { lat: 48.514507, lng: 18.737027 },
    { lat: 48.514483, lng: 18.736967 },
    { lat: 48.514207, lng: 18.736278 },
    { lat: 48.514156, lng: 18.736153 },
    { lat: 48.513637, lng: 18.734999 },
    { lat: 48.513153, lng: 18.734228 },
    { lat: 48.512726, lng: 18.733723 },
    { lat: 48.512633, lng: 18.733613 },
    { lat: 48.512502, lng: 18.733522 },
    { lat: 48.512406, lng: 18.733457 },
    { lat: 48.511714, lng: 18.732981 },
    { lat: 48.511229, lng: 18.732796 },
    { lat: 48.511228, lng: 18.732667 },
    { lat: 48.5112388, lng: 18.7325256 },
    { lat: 48.511298, lng: 18.73178 },
    { lat: 48.511369, lng: 18.73089 },
    { lat: 48.511345, lng: 18.730651 },
    { lat: 48.511312, lng: 18.730325 },
    { lat: 48.510869, lng: 18.730511 },
    { lat: 48.509884, lng: 18.729591 },
    { lat: 48.508437, lng: 18.729395 },
    { lat: 48.506961, lng: 18.729254 },
  ],
  Krahule: [
    { lat: 48.741154, lng: 18.923515 },
    { lat: 48.7410388, lng: 18.9232251 },
    { lat: 48.7410259, lng: 18.9231891 },
    { lat: 48.7410192, lng: 18.9231691 },
    { lat: 48.7410178, lng: 18.923165 },
    { lat: 48.7409151, lng: 18.9231838 },
    { lat: 48.7408761, lng: 18.9231055 },
    { lat: 48.7406821, lng: 18.9227077 },
    { lat: 48.7405818, lng: 18.9226005 },
    { lat: 48.7405242, lng: 18.9225547 },
    { lat: 48.7404619, lng: 18.9224598 },
    { lat: 48.7403953, lng: 18.9222794 },
    { lat: 48.7401811, lng: 18.921844 },
    { lat: 48.7401407, lng: 18.9216679 },
    { lat: 48.7400945, lng: 18.9215165 },
    { lat: 48.74002, lng: 18.921337 },
    { lat: 48.7399414, lng: 18.9213004 },
    { lat: 48.7396422, lng: 18.9212022 },
    { lat: 48.7395656, lng: 18.9211826 },
    { lat: 48.7391958, lng: 18.921363 },
    { lat: 48.7386772, lng: 18.9215377 },
    { lat: 48.7384272, lng: 18.9216287 },
    { lat: 48.7381651, lng: 18.9216411 },
    { lat: 48.73794, lng: 18.9216532 },
    { lat: 48.737821, lng: 18.9217053 },
    { lat: 48.7376798, lng: 18.9217669 },
    { lat: 48.7374588, lng: 18.9217641 },
    { lat: 48.7372187, lng: 18.9218562 },
    { lat: 48.7366328, lng: 18.9221722 },
    { lat: 48.7361366, lng: 18.9221706 },
    { lat: 48.7360283, lng: 18.9221919 },
    { lat: 48.7358539, lng: 18.9223066 },
    { lat: 48.735709, lng: 18.9225825 },
    { lat: 48.7354278, lng: 18.9231262 },
    { lat: 48.7353399, lng: 18.9232154 },
    { lat: 48.7350549, lng: 18.9234173 },
    { lat: 48.7348785, lng: 18.923519 },
    { lat: 48.7347296, lng: 18.9236072 },
    { lat: 48.7344492, lng: 18.9236578 },
    { lat: 48.7344321, lng: 18.9235419 },
    { lat: 48.7343177, lng: 18.9234896 },
    { lat: 48.7340731, lng: 18.9233785 },
    { lat: 48.7337575, lng: 18.9235594 },
    { lat: 48.7335912, lng: 18.9236153 },
    { lat: 48.7334849, lng: 18.923607 },
    { lat: 48.7331313, lng: 18.9236173 },
    { lat: 48.7328993, lng: 18.9236222 },
    { lat: 48.7327589, lng: 18.9236308 },
    { lat: 48.7327273, lng: 18.923632 },
    { lat: 48.732504, lng: 18.9236728 },
    { lat: 48.7321608, lng: 18.9237536 },
    { lat: 48.7319822, lng: 18.9238091 },
    { lat: 48.7318621, lng: 18.9238457 },
    { lat: 48.7316756, lng: 18.9238578 },
    { lat: 48.7315364, lng: 18.9238466 },
    { lat: 48.7311976, lng: 18.9237041 },
    { lat: 48.7311897, lng: 18.92379 },
    { lat: 48.731049, lng: 18.9252276 },
    { lat: 48.7312282, lng: 18.9254478 },
    { lat: 48.7312291, lng: 18.9255523 },
    { lat: 48.7312283, lng: 18.9256056 },
    { lat: 48.731182, lng: 18.9256892 },
    { lat: 48.7311193, lng: 18.9259891 },
    { lat: 48.7310626, lng: 18.9262 },
    { lat: 48.7310997, lng: 18.9264414 },
    { lat: 48.7311081, lng: 18.9264704 },
    { lat: 48.731097, lng: 18.9266103 },
    { lat: 48.7311461, lng: 18.9267114 },
    { lat: 48.7311796, lng: 18.9270096 },
    { lat: 48.7311836, lng: 18.9271352 },
    { lat: 48.7311232, lng: 18.9272523 },
    { lat: 48.7309755, lng: 18.9275188 },
    { lat: 48.7309177, lng: 18.9277342 },
    { lat: 48.7308701, lng: 18.9281247 },
    { lat: 48.7308389, lng: 18.9282616 },
    { lat: 48.7308439, lng: 18.92836 },
    { lat: 48.7309914, lng: 18.9284674 },
    { lat: 48.7310079, lng: 18.9284797 },
    { lat: 48.731007, lng: 18.9285095 },
    { lat: 48.731006, lng: 18.928531 },
    { lat: 48.7309912, lng: 18.9285738 },
    { lat: 48.7309809, lng: 18.9286129 },
    { lat: 48.7309641, lng: 18.9286638 },
    { lat: 48.7309709, lng: 18.9287505 },
    { lat: 48.73086, lng: 18.9289845 },
    { lat: 48.7307316, lng: 18.9291863 },
    { lat: 48.7302553, lng: 18.9294873 },
    { lat: 48.7302127, lng: 18.9295548 },
    { lat: 48.7302571, lng: 18.9298079 },
    { lat: 48.7302476, lng: 18.930219 },
    { lat: 48.7301176, lng: 18.9304306 },
    { lat: 48.7301235, lng: 18.9304585 },
    { lat: 48.7301755, lng: 18.9306799 },
    { lat: 48.7299739, lng: 18.930776 },
    { lat: 48.7297807, lng: 18.9308978 },
    { lat: 48.7296744, lng: 18.9310452 },
    { lat: 48.7295825, lng: 18.9311238 },
    { lat: 48.7295046, lng: 18.9310336 },
    { lat: 48.7294375, lng: 18.9308904 },
    { lat: 48.7293688, lng: 18.930517 },
    { lat: 48.7293033, lng: 18.9303818 },
    { lat: 48.7292947, lng: 18.9302792 },
    { lat: 48.7292262, lng: 18.9301772 },
    { lat: 48.7291835, lng: 18.9299558 },
    { lat: 48.7291434, lng: 18.9298137 },
    { lat: 48.7290845, lng: 18.929698 },
    { lat: 48.7290225, lng: 18.9296317 },
    { lat: 48.7289316, lng: 18.9296416 },
    { lat: 48.7288094, lng: 18.9294798 },
    { lat: 48.7286723, lng: 18.9293526 },
    { lat: 48.7286354, lng: 18.9292494 },
    { lat: 48.7285387, lng: 18.9291236 },
    { lat: 48.7285254, lng: 18.9291283 },
    { lat: 48.7284679, lng: 18.9291719 },
    { lat: 48.7282107, lng: 18.9293212 },
    { lat: 48.7281002, lng: 18.9294994 },
    { lat: 48.7279847, lng: 18.9296426 },
    { lat: 48.7279013, lng: 18.9296662 },
    { lat: 48.7277453, lng: 18.929748 },
    { lat: 48.7276483, lng: 18.9297451 },
    { lat: 48.7275758, lng: 18.9298068 },
    { lat: 48.7275453, lng: 18.9297828 },
    { lat: 48.7273383, lng: 18.9296163 },
    { lat: 48.7271361, lng: 18.9296078 },
    { lat: 48.7271218, lng: 18.929683 },
    { lat: 48.7270174, lng: 18.929847 },
    { lat: 48.7268161, lng: 18.929943 },
    { lat: 48.726785, lng: 18.9299548 },
    { lat: 48.726732, lng: 18.9298643 },
    { lat: 48.7266032, lng: 18.9296839 },
    { lat: 48.7264155, lng: 18.9294087 },
    { lat: 48.726192, lng: 18.9294041 },
    { lat: 48.7260818, lng: 18.929367 },
    { lat: 48.7259843, lng: 18.9293305 },
    { lat: 48.7258704, lng: 18.929376 },
    { lat: 48.7255447, lng: 18.9295772 },
    { lat: 48.7254652, lng: 18.9296547 },
    { lat: 48.7252971, lng: 18.9296754 },
    { lat: 48.7252622, lng: 18.9296756 },
    { lat: 48.7251808, lng: 18.9296701 },
    { lat: 48.7251078, lng: 18.9296315 },
    { lat: 48.724914, lng: 18.9296127 },
    { lat: 48.7248401, lng: 18.9296877 },
    { lat: 48.7247146, lng: 18.9298585 },
    { lat: 48.7246953, lng: 18.9298782 },
    { lat: 48.7245542, lng: 18.9301175 },
    { lat: 48.7245246, lng: 18.930181 },
    { lat: 48.7244891, lng: 18.930258 },
    { lat: 48.7243671, lng: 18.9304501 },
    { lat: 48.7243412, lng: 18.9305627 },
    { lat: 48.724331, lng: 18.9307796 },
    { lat: 48.7242242, lng: 18.9308296 },
    { lat: 48.7240394, lng: 18.9313611 },
    { lat: 48.7238925, lng: 18.9317979 },
    { lat: 48.7237964, lng: 18.9321219 },
    { lat: 48.7237588, lng: 18.9321802 },
    { lat: 48.7237085, lng: 18.9322565 },
    { lat: 48.7236907, lng: 18.9323514 },
    { lat: 48.7235517, lng: 18.9323876 },
    { lat: 48.7235027, lng: 18.9326205 },
    { lat: 48.7235411, lng: 18.9327763 },
    { lat: 48.7236175, lng: 18.9329256 },
    { lat: 48.7237638, lng: 18.9333156 },
    { lat: 48.7237074, lng: 18.9333629 },
    { lat: 48.723639, lng: 18.9334345 },
    { lat: 48.7235606, lng: 18.9335083 },
    { lat: 48.7234944, lng: 18.933582 },
    { lat: 48.7234748, lng: 18.933619 },
    { lat: 48.7234254, lng: 18.9337115 },
    { lat: 48.7234091, lng: 18.9337438 },
    { lat: 48.7234089, lng: 18.9337627 },
    { lat: 48.723409, lng: 18.9337874 },
    { lat: 48.7234104, lng: 18.9338575 },
    { lat: 48.7234099, lng: 18.9338697 },
    { lat: 48.7232922, lng: 18.933907 },
    { lat: 48.7232711, lng: 18.9340245 },
    { lat: 48.7231356, lng: 18.9340828 },
    { lat: 48.7230446, lng: 18.9341145 },
    { lat: 48.722939, lng: 18.9341432 },
    { lat: 48.7228577, lng: 18.934207 },
    { lat: 48.7227691, lng: 18.9341752 },
    { lat: 48.7226625, lng: 18.934183 },
    { lat: 48.7225951, lng: 18.9341634 },
    { lat: 48.7225855, lng: 18.9341738 },
    { lat: 48.7225278, lng: 18.9342373 },
    { lat: 48.7223636, lng: 18.9343361 },
    { lat: 48.7223496, lng: 18.9343477 },
    { lat: 48.7222414, lng: 18.9344121 },
    { lat: 48.7221575, lng: 18.9344501 },
    { lat: 48.7220576, lng: 18.9344832 },
    { lat: 48.7219844, lng: 18.9345075 },
    { lat: 48.7216689, lng: 18.9344629 },
    { lat: 48.7216047, lng: 18.9344889 },
    { lat: 48.7214602, lng: 18.9344858 },
    { lat: 48.7212852, lng: 18.9345691 },
    { lat: 48.721071, lng: 18.9346137 },
    { lat: 48.7209626, lng: 18.9347628 },
    { lat: 48.7209489, lng: 18.9347804 },
    { lat: 48.7209152, lng: 18.934829 },
    { lat: 48.7209224, lng: 18.9349692 },
    { lat: 48.7209152, lng: 18.9350043 },
    { lat: 48.7208825, lng: 18.9351181 },
    { lat: 48.7208728, lng: 18.9351453 },
    { lat: 48.7208155, lng: 18.935282 },
    { lat: 48.7208059, lng: 18.9353156 },
    { lat: 48.7207885, lng: 18.935374 },
    { lat: 48.720773, lng: 18.9354212 },
    { lat: 48.7207192, lng: 18.9355382 },
    { lat: 48.7205218, lng: 18.9353191 },
    { lat: 48.7203979, lng: 18.9353221 },
    { lat: 48.7203182, lng: 18.9352379 },
    { lat: 48.720247, lng: 18.9350787 },
    { lat: 48.720155, lng: 18.9349327 },
    { lat: 48.7199166, lng: 18.9346591 },
    { lat: 48.7200751, lng: 18.9340989 },
    { lat: 48.7200808, lng: 18.9340533 },
    { lat: 48.7200707, lng: 18.9337913 },
    { lat: 48.7200445, lng: 18.9335008 },
    { lat: 48.7200258, lng: 18.9333109 },
    { lat: 48.7199375, lng: 18.932887 },
    { lat: 48.7199066, lng: 18.9324985 },
    { lat: 48.7198547, lng: 18.9321264 },
    { lat: 48.719796, lng: 18.9323461 },
    { lat: 48.7196732, lng: 18.9325008 },
    { lat: 48.7195174, lng: 18.9328268 },
    { lat: 48.718978, lng: 18.9324662 },
    { lat: 48.7187665, lng: 18.9322977 },
    { lat: 48.7183902, lng: 18.9320414 },
    { lat: 48.7180972, lng: 18.9318653 },
    { lat: 48.7177686, lng: 18.9316355 },
    { lat: 48.7175663, lng: 18.9315602 },
    { lat: 48.7170701, lng: 18.9313146 },
    { lat: 48.7170709, lng: 18.9314645 },
    { lat: 48.7170233, lng: 18.9316568 },
    { lat: 48.7169608, lng: 18.9318251 },
    { lat: 48.71692, lng: 18.9318634 },
    { lat: 48.7168019, lng: 18.931849 },
    { lat: 48.7166308, lng: 18.9318533 },
    { lat: 48.7165584, lng: 18.9319167 },
    { lat: 48.7164891, lng: 18.9320355 },
    { lat: 48.7162107, lng: 18.9323015 },
    { lat: 48.7160966, lng: 18.9323898 },
    { lat: 48.7160208, lng: 18.9325807 },
    { lat: 48.7160275, lng: 18.9326014 },
    { lat: 48.7160866, lng: 18.9327859 },
    { lat: 48.7159521, lng: 18.9328912 },
    { lat: 48.7158462, lng: 18.932936 },
    { lat: 48.715702, lng: 18.9329861 },
    { lat: 48.7157052, lng: 18.9330493 },
    { lat: 48.715672, lng: 18.9330603 },
    { lat: 48.7155492, lng: 18.9331071 },
    { lat: 48.7155132, lng: 18.933262 },
    { lat: 48.715396, lng: 18.9334388 },
    { lat: 48.7154791, lng: 18.9335434 },
    { lat: 48.7155609, lng: 18.9335763 },
    { lat: 48.7155816, lng: 18.9337603 },
    { lat: 48.7154874, lng: 18.9338177 },
    { lat: 48.7153898, lng: 18.9338902 },
    { lat: 48.7151588, lng: 18.9338278 },
    { lat: 48.7151372, lng: 18.9338903 },
    { lat: 48.715034, lng: 18.9339238 },
    { lat: 48.7149701, lng: 18.9340405 },
    { lat: 48.714882, lng: 18.9340369 },
    { lat: 48.7148401, lng: 18.9340125 },
    { lat: 48.7148317, lng: 18.9339135 },
    { lat: 48.7147503, lng: 18.9339512 },
    { lat: 48.7146654, lng: 18.9339451 },
    { lat: 48.7145095, lng: 18.9338729 },
    { lat: 48.7144416, lng: 18.9338023 },
    { lat: 48.7142637, lng: 18.9338298 },
    { lat: 48.7142777, lng: 18.9339289 },
    { lat: 48.7143237, lng: 18.9342316 },
    { lat: 48.7143141, lng: 18.9345291 },
    { lat: 48.7143128, lng: 18.934726 },
    { lat: 48.7143071, lng: 18.9349469 },
    { lat: 48.7142037, lng: 18.9351515 },
    { lat: 48.7141638, lng: 18.9351429 },
    { lat: 48.71411, lng: 18.9352145 },
    { lat: 48.7140675, lng: 18.9351781 },
    { lat: 48.7140429, lng: 18.9352231 },
    { lat: 48.7140119, lng: 18.9353009 },
    { lat: 48.7139451, lng: 18.9354083 },
    { lat: 48.7138549, lng: 18.935562 },
    { lat: 48.7137639, lng: 18.935613 },
    { lat: 48.713702, lng: 18.9357477 },
    { lat: 48.7136931, lng: 18.9357712 },
    { lat: 48.7136307, lng: 18.9360298 },
    { lat: 48.7136199, lng: 18.9361932 },
    { lat: 48.7136723, lng: 18.9362717 },
    { lat: 48.7136711, lng: 18.9363129 },
    { lat: 48.7136633, lng: 18.936488 },
    { lat: 48.7136172, lng: 18.9365563 },
    { lat: 48.7136322, lng: 18.9366984 },
    { lat: 48.7138026, lng: 18.9368795 },
    { lat: 48.7137889, lng: 18.9369165 },
    { lat: 48.7137719, lng: 18.9369813 },
    { lat: 48.7138048, lng: 18.9371396 },
    { lat: 48.7138735, lng: 18.9371626 },
    { lat: 48.7138553, lng: 18.937204 },
    { lat: 48.7137545, lng: 18.93746 },
    { lat: 48.7136788, lng: 18.9374759 },
    { lat: 48.713731, lng: 18.9379069 },
    { lat: 48.7136895, lng: 18.938042 },
    { lat: 48.7137596, lng: 18.9381974 },
    { lat: 48.7138582, lng: 18.9384719 },
    { lat: 48.713944, lng: 18.9386764 },
    { lat: 48.7140862, lng: 18.9388748 },
    { lat: 48.7142154, lng: 18.9390865 },
    { lat: 48.7143456, lng: 18.9391662 },
    { lat: 48.7146772, lng: 18.9394968 },
    { lat: 48.714819, lng: 18.9395806 },
    { lat: 48.7151364, lng: 18.9398556 },
    { lat: 48.7152513, lng: 18.9397462 },
    { lat: 48.7154481, lng: 18.9399357 },
    { lat: 48.7155589, lng: 18.9401391 },
    { lat: 48.715544, lng: 18.940334 },
    { lat: 48.7154202, lng: 18.9406248 },
    { lat: 48.7154286, lng: 18.9406313 },
    { lat: 48.7155628, lng: 18.9407425 },
    { lat: 48.7155874, lng: 18.9407624 },
    { lat: 48.7156012, lng: 18.9407908 },
    { lat: 48.7156288, lng: 18.9408462 },
    { lat: 48.7156365, lng: 18.9408624 },
    { lat: 48.7156595, lng: 18.9409615 },
    { lat: 48.7156717, lng: 18.9409797 },
    { lat: 48.7158833, lng: 18.941236 },
    { lat: 48.7159817, lng: 18.9413313 },
    { lat: 48.7161472, lng: 18.9414356 },
    { lat: 48.7163161, lng: 18.9414766 },
    { lat: 48.7163396, lng: 18.9414731 },
    { lat: 48.7166647, lng: 18.9414135 },
    { lat: 48.7167118, lng: 18.941434 },
    { lat: 48.7168853, lng: 18.9415599 },
    { lat: 48.7168731, lng: 18.9415663 },
    { lat: 48.71679, lng: 18.9416131 },
    { lat: 48.7167357, lng: 18.9417676 },
    { lat: 48.716724, lng: 18.9420239 },
    { lat: 48.7165341, lng: 18.9427666 },
    { lat: 48.7165564, lng: 18.9428518 },
    { lat: 48.7165205, lng: 18.9429635 },
    { lat: 48.716563, lng: 18.9430692 },
    { lat: 48.7165447, lng: 18.9431731 },
    { lat: 48.7169996, lng: 18.9431615 },
    { lat: 48.7170414, lng: 18.9431599 },
    { lat: 48.7171352, lng: 18.9431882 },
    { lat: 48.7171564, lng: 18.9432064 },
    { lat: 48.7171658, lng: 18.9432139 },
    { lat: 48.7172533, lng: 18.9433169 },
    { lat: 48.7177378, lng: 18.9434868 },
    { lat: 48.7177749, lng: 18.9435057 },
    { lat: 48.7178736, lng: 18.9435828 },
    { lat: 48.7180497, lng: 18.9436772 },
    { lat: 48.718084, lng: 18.9437746 },
    { lat: 48.7180963, lng: 18.9438182 },
    { lat: 48.7181071, lng: 18.9438716 },
    { lat: 48.7181087, lng: 18.9438839 },
    { lat: 48.7179996, lng: 18.9440844 },
    { lat: 48.7179642, lng: 18.9442331 },
    { lat: 48.7179751, lng: 18.9442247 },
    { lat: 48.7179648, lng: 18.9442695 },
    { lat: 48.717955, lng: 18.9442946 },
    { lat: 48.7178925, lng: 18.9443975 },
    { lat: 48.7179421, lng: 18.9446624 },
    { lat: 48.7180546, lng: 18.9447889 },
    { lat: 48.7182277, lng: 18.9447588 },
    { lat: 48.7184174, lng: 18.9446981 },
    { lat: 48.7185536, lng: 18.9444687 },
    { lat: 48.7187226, lng: 18.9443815 },
    { lat: 48.7189134, lng: 18.9442736 },
    { lat: 48.719068, lng: 18.9442324 },
    { lat: 48.7192657, lng: 18.944259 },
    { lat: 48.7193441, lng: 18.944232 },
    { lat: 48.7194718, lng: 18.9441679 },
    { lat: 48.719629, lng: 18.9440674 },
    { lat: 48.720007, lng: 18.9437848 },
    { lat: 48.7200954, lng: 18.9438377 },
    { lat: 48.7202709, lng: 18.9440544 },
    { lat: 48.7204328, lng: 18.9441098 },
    { lat: 48.7205528, lng: 18.9442304 },
    { lat: 48.7208004, lng: 18.9445076 },
    { lat: 48.720665, lng: 18.9445952 },
    { lat: 48.7205098, lng: 18.9447347 },
    { lat: 48.7205251, lng: 18.9449246 },
    { lat: 48.7206433, lng: 18.9449448 },
    { lat: 48.7206393, lng: 18.9450413 },
    { lat: 48.7205638, lng: 18.9451729 },
    { lat: 48.7204078, lng: 18.9452789 },
    { lat: 48.7202157, lng: 18.9453809 },
    { lat: 48.7200439, lng: 18.945482 },
    { lat: 48.7198851, lng: 18.9455976 },
    { lat: 48.7197817, lng: 18.9456904 },
    { lat: 48.7197611, lng: 18.9457092 },
    { lat: 48.7197591, lng: 18.9458001 },
    { lat: 48.7198222, lng: 18.9458449 },
    { lat: 48.7198232, lng: 18.945908 },
    { lat: 48.7197452, lng: 18.9460124 },
    { lat: 48.7196929, lng: 18.9460942 },
    { lat: 48.719671, lng: 18.9461935 },
    { lat: 48.7196695, lng: 18.9463219 },
    { lat: 48.7197161, lng: 18.9463736 },
    { lat: 48.7197213, lng: 18.9464766 },
    { lat: 48.719723, lng: 18.9465317 },
    { lat: 48.7197241, lng: 18.9465726 },
    { lat: 48.7197435, lng: 18.9466429 },
    { lat: 48.7197523, lng: 18.9466851 },
    { lat: 48.7197448, lng: 18.946868 },
    { lat: 48.7197687, lng: 18.9470074 },
    { lat: 48.7197915, lng: 18.9470779 },
    { lat: 48.7198035, lng: 18.9471144 },
    { lat: 48.719817, lng: 18.9471574 },
    { lat: 48.7198275, lng: 18.947188 },
    { lat: 48.7197931, lng: 18.9473095 },
    { lat: 48.7197827, lng: 18.9473471 },
    { lat: 48.7194773, lng: 18.9476597 },
    { lat: 48.7194797, lng: 18.9477298 },
    { lat: 48.7194837, lng: 18.9478536 },
    { lat: 48.7194869, lng: 18.9479168 },
    { lat: 48.7194834, lng: 18.9482243 },
    { lat: 48.7195354, lng: 18.9482693 },
    { lat: 48.719546, lng: 18.9482788 },
    { lat: 48.7195781, lng: 18.948334 },
    { lat: 48.7196649, lng: 18.9484881 },
    { lat: 48.7197747, lng: 18.9485142 },
    { lat: 48.719971, lng: 18.9486074 },
    { lat: 48.7201612, lng: 18.9487146 },
    { lat: 48.720239, lng: 18.9487798 },
    { lat: 48.7203146, lng: 18.9488035 },
    { lat: 48.7203205, lng: 18.9489381 },
    { lat: 48.7203383, lng: 18.9489807 },
    { lat: 48.7203933, lng: 18.9492164 },
    { lat: 48.7203845, lng: 18.9493124 },
    { lat: 48.7204312, lng: 18.9493855 },
    { lat: 48.720368, lng: 18.9497824 },
    { lat: 48.7203893, lng: 18.9498467 },
    { lat: 48.7203988, lng: 18.9498806 },
    { lat: 48.7202865, lng: 18.9499298 },
    { lat: 48.7200987, lng: 18.9500105 },
    { lat: 48.7199384, lng: 18.9502245 },
    { lat: 48.7199394, lng: 18.9504436 },
    { lat: 48.7199236, lng: 18.950709 },
    { lat: 48.7199917, lng: 18.9509371 },
    { lat: 48.7198227, lng: 18.9511174 },
    { lat: 48.7196784, lng: 18.9511882 },
    { lat: 48.7194773, lng: 18.9513533 },
    { lat: 48.7194347, lng: 18.9514036 },
    { lat: 48.7193229, lng: 18.9514213 },
    { lat: 48.7191716, lng: 18.9513721 },
    { lat: 48.7190306, lng: 18.9514392 },
    { lat: 48.7188081, lng: 18.9513898 },
    { lat: 48.7187841, lng: 18.9514693 },
    { lat: 48.7186285, lng: 18.9516674 },
    { lat: 48.7185089, lng: 18.9518227 },
    { lat: 48.7184288, lng: 18.9519763 },
    { lat: 48.7182412, lng: 18.952167 },
    { lat: 48.7180668, lng: 18.952329 },
    { lat: 48.7179765, lng: 18.9524152 },
    { lat: 48.7179177, lng: 18.9526995 },
    { lat: 48.7178685, lng: 18.952772 },
    { lat: 48.7178428, lng: 18.9528471 },
    { lat: 48.7178938, lng: 18.9530926 },
    { lat: 48.7179035, lng: 18.9531939 },
    { lat: 48.7177632, lng: 18.9533852 },
    { lat: 48.7177275, lng: 18.9536121 },
    { lat: 48.7178042, lng: 18.9537914 },
    { lat: 48.7178693, lng: 18.9538741 },
    { lat: 48.7179192, lng: 18.9539262 },
    { lat: 48.7181146, lng: 18.9542641 },
    { lat: 48.7180715, lng: 18.9542813 },
    { lat: 48.7178218, lng: 18.9542482 },
    { lat: 48.7176079, lng: 18.9542759 },
    { lat: 48.7175334, lng: 18.9542089 },
    { lat: 48.7174833, lng: 18.954265 },
    { lat: 48.7174762, lng: 18.9543933 },
    { lat: 48.7173967, lng: 18.9544893 },
    { lat: 48.7172764, lng: 18.9544966 },
    { lat: 48.7169512, lng: 18.9546443 },
    { lat: 48.7165913, lng: 18.954749 },
    { lat: 48.7164734, lng: 18.9549813 },
    { lat: 48.716694, lng: 18.9551709 },
    { lat: 48.7168086, lng: 18.955319 },
    { lat: 48.7168535, lng: 18.9554908 },
    { lat: 48.7169308, lng: 18.9553947 },
    { lat: 48.7170515, lng: 18.9553282 },
    { lat: 48.7173828, lng: 18.9551904 },
    { lat: 48.7175089, lng: 18.9551893 },
    { lat: 48.7176699, lng: 18.955141 },
    { lat: 48.7176597, lng: 18.9550929 },
    { lat: 48.7179196, lng: 18.955038 },
    { lat: 48.7181812, lng: 18.954928 },
    { lat: 48.71826, lng: 18.9549717 },
    { lat: 48.7183793, lng: 18.9549249 },
    { lat: 48.7184323, lng: 18.9548099 },
    { lat: 48.7187049, lng: 18.9550278 },
    { lat: 48.718734, lng: 18.955199 },
    { lat: 48.7188859, lng: 18.9551303 },
    { lat: 48.7189637, lng: 18.9551951 },
    { lat: 48.7188952, lng: 18.9553124 },
    { lat: 48.7190237, lng: 18.9553999 },
    { lat: 48.71904, lng: 18.9554323 },
    { lat: 48.7191634, lng: 18.9556854 },
    { lat: 48.7192663, lng: 18.9559787 },
    { lat: 48.7192922, lng: 18.9560003 },
    { lat: 48.7194807, lng: 18.956158 },
    { lat: 48.71967, lng: 18.956271 },
    { lat: 48.7198147, lng: 18.9561881 },
    { lat: 48.7198741, lng: 18.9560241 },
    { lat: 48.7199886, lng: 18.9560593 },
    { lat: 48.7200764, lng: 18.9562991 },
    { lat: 48.720296, lng: 18.9561611 },
    { lat: 48.720549, lng: 18.9562342 },
    { lat: 48.7206005, lng: 18.9562764 },
    { lat: 48.7206513, lng: 18.9563422 },
    { lat: 48.7206986, lng: 18.9564967 },
    { lat: 48.7205491, lng: 18.9566601 },
    { lat: 48.7204172, lng: 18.9567512 },
    { lat: 48.7202817, lng: 18.9567894 },
    { lat: 48.7201273, lng: 18.956992 },
    { lat: 48.720153, lng: 18.9574268 },
    { lat: 48.7201588, lng: 18.9574768 },
    { lat: 48.7201717, lng: 18.9576427 },
    { lat: 48.7202199, lng: 18.9577896 },
    { lat: 48.7203018, lng: 18.9578729 },
    { lat: 48.7205369, lng: 18.9578406 },
    { lat: 48.7206378, lng: 18.9579149 },
    { lat: 48.7207147, lng: 18.9577871 },
    { lat: 48.7210098, lng: 18.9581124 },
    { lat: 48.7208636, lng: 18.9577005 },
    { lat: 48.7211724, lng: 18.9574075 },
    { lat: 48.7212804, lng: 18.9571393 },
    { lat: 48.7214144, lng: 18.9571584 },
    { lat: 48.7214024, lng: 18.9569866 },
    { lat: 48.7215096, lng: 18.9568798 },
    { lat: 48.7216275, lng: 18.9568704 },
    { lat: 48.7217441, lng: 18.956859 },
    { lat: 48.7217655, lng: 18.9568565 },
    { lat: 48.7219112, lng: 18.9568839 },
    { lat: 48.7219836, lng: 18.9569312 },
    { lat: 48.7220093, lng: 18.956795 },
    { lat: 48.7220492, lng: 18.9567807 },
    { lat: 48.7220855, lng: 18.9568954 },
    { lat: 48.7221652, lng: 18.9569115 },
    { lat: 48.7221593, lng: 18.9567711 },
    { lat: 48.7221911, lng: 18.9567331 },
    { lat: 48.722288, lng: 18.9568218 },
    { lat: 48.7222748, lng: 18.9566034 },
    { lat: 48.722273, lng: 18.9565922 },
    { lat: 48.7224895, lng: 18.9565463 },
    { lat: 48.7225293, lng: 18.9566652 },
    { lat: 48.7226646, lng: 18.9567338 },
    { lat: 48.7227939, lng: 18.9565713 },
    { lat: 48.7229618, lng: 18.9565014 },
    { lat: 48.7233602, lng: 18.9560476 },
    { lat: 48.7235406, lng: 18.9561324 },
    { lat: 48.7238159, lng: 18.9562278 },
    { lat: 48.7239646, lng: 18.9560656 },
    { lat: 48.7240579, lng: 18.9558619 },
    { lat: 48.7240678, lng: 18.9558382 },
    { lat: 48.7241529, lng: 18.9555827 },
    { lat: 48.7241823, lng: 18.9555632 },
    { lat: 48.7242783, lng: 18.9554992 },
    { lat: 48.7242968, lng: 18.9554856 },
    { lat: 48.7243154, lng: 18.9554523 },
    { lat: 48.7243412, lng: 18.9553833 },
    { lat: 48.7244788, lng: 18.9554516 },
    { lat: 48.7245148, lng: 18.9554716 },
    { lat: 48.7244491, lng: 18.9556425 },
    { lat: 48.7245834, lng: 18.9556672 },
    { lat: 48.7248553, lng: 18.9552917 },
    { lat: 48.7251119, lng: 18.9551522 },
    { lat: 48.72517, lng: 18.9552494 },
    { lat: 48.7251785, lng: 18.9552631 },
    { lat: 48.7253444, lng: 18.9552688 },
    { lat: 48.7253942, lng: 18.9554483 },
    { lat: 48.7254922, lng: 18.9555537 },
    { lat: 48.7254914, lng: 18.9556956 },
    { lat: 48.7255598, lng: 18.9557315 },
    { lat: 48.7255939, lng: 18.9558718 },
    { lat: 48.725655, lng: 18.9559394 },
    { lat: 48.7257643, lng: 18.9563366 },
    { lat: 48.7257429, lng: 18.9566262 },
    { lat: 48.7258839, lng: 18.9567613 },
    { lat: 48.7259667, lng: 18.9566599 },
    { lat: 48.7260915, lng: 18.9564339 },
    { lat: 48.7263165, lng: 18.956797 },
    { lat: 48.7261617, lng: 18.9569925 },
    { lat: 48.7260966, lng: 18.9572404 },
    { lat: 48.7260274, lng: 18.9573185 },
    { lat: 48.7259742, lng: 18.9574068 },
    { lat: 48.7259692, lng: 18.9576195 },
    { lat: 48.7258743, lng: 18.9577469 },
    { lat: 48.7259048, lng: 18.9578359 },
    { lat: 48.7259182, lng: 18.9578575 },
    { lat: 48.7260168, lng: 18.9579982 },
    { lat: 48.7262072, lng: 18.9582857 },
    { lat: 48.7262714, lng: 18.9584382 },
    { lat: 48.7263164, lng: 18.9585466 },
    { lat: 48.7263413, lng: 18.958618 },
    { lat: 48.7263958, lng: 18.958867 },
    { lat: 48.7264817, lng: 18.9590505 },
    { lat: 48.7265891, lng: 18.9591877 },
    { lat: 48.7267317, lng: 18.959219 },
    { lat: 48.7267775, lng: 18.9593019 },
    { lat: 48.7267704, lng: 18.9595148 },
    { lat: 48.7268291, lng: 18.9596944 },
    { lat: 48.7268283, lng: 18.9599666 },
    { lat: 48.726848, lng: 18.9602425 },
    { lat: 48.7268743, lng: 18.9603416 },
    { lat: 48.7270014, lng: 18.9605315 },
    { lat: 48.7270111, lng: 18.9605457 },
    { lat: 48.7270385, lng: 18.9607546 },
    { lat: 48.72704, lng: 18.9607598 },
    { lat: 48.7271249, lng: 18.9609664 },
    { lat: 48.7272088, lng: 18.961042 },
    { lat: 48.7272157, lng: 18.9611112 },
    { lat: 48.7272245, lng: 18.9611962 },
    { lat: 48.7272098, lng: 18.9615251 },
    { lat: 48.7271799, lng: 18.9616017 },
    { lat: 48.7271422, lng: 18.9615954 },
    { lat: 48.7268074, lng: 18.9615054 },
    { lat: 48.7267636, lng: 18.9617097 },
    { lat: 48.7269346, lng: 18.9617688 },
    { lat: 48.726953, lng: 18.9620645 },
    { lat: 48.7269588, lng: 18.9621335 },
    { lat: 48.7269097, lng: 18.9627895 },
    { lat: 48.726987, lng: 18.9630637 },
    { lat: 48.7270597, lng: 18.963317 },
    { lat: 48.7273382, lng: 18.96341 },
    { lat: 48.7273647, lng: 18.9634205 },
    { lat: 48.727691, lng: 18.9636301 },
    { lat: 48.7277398, lng: 18.9638373 },
    { lat: 48.7278683, lng: 18.9641006 },
    { lat: 48.7281204, lng: 18.9642274 },
    { lat: 48.7283888, lng: 18.9643148 },
    { lat: 48.7284898, lng: 18.9643923 },
    { lat: 48.728544, lng: 18.9644403 },
    { lat: 48.7285394, lng: 18.9646384 },
    { lat: 48.7285652, lng: 18.9647221 },
    { lat: 48.7286178, lng: 18.9654278 },
    { lat: 48.7286714, lng: 18.9653709 },
    { lat: 48.7287297, lng: 18.9653894 },
    { lat: 48.7289853, lng: 18.9654712 },
    { lat: 48.7291545, lng: 18.9650501 },
    { lat: 48.7291513, lng: 18.9648094 },
    { lat: 48.7292401, lng: 18.9648463 },
    { lat: 48.7293536, lng: 18.9653074 },
    { lat: 48.7294298, lng: 18.965631 },
    { lat: 48.7294756, lng: 18.9657103 },
    { lat: 48.729567, lng: 18.9658897 },
    { lat: 48.7294685, lng: 18.9659058 },
    { lat: 48.7293272, lng: 18.9658726 },
    { lat: 48.7296221, lng: 18.966129 },
    { lat: 48.7297072, lng: 18.9665195 },
    { lat: 48.729835, lng: 18.9670133 },
    { lat: 48.7297244, lng: 18.9671468 },
    { lat: 48.7299084, lng: 18.9674162 },
    { lat: 48.7299644, lng: 18.9676061 },
    { lat: 48.7300771, lng: 18.9677412 },
    { lat: 48.7301684, lng: 18.9678099 },
    { lat: 48.7299928, lng: 18.9680771 },
    { lat: 48.7301436, lng: 18.9681857 },
    { lat: 48.7303018, lng: 18.9682998 },
    { lat: 48.7303829, lng: 18.9683247 },
    { lat: 48.7304619, lng: 18.968368 },
    { lat: 48.7305711, lng: 18.9684346 },
    { lat: 48.7306341, lng: 18.9684523 },
    { lat: 48.7306982, lng: 18.9684724 },
    { lat: 48.7308094, lng: 18.9685723 },
    { lat: 48.7308235, lng: 18.9685857 },
    { lat: 48.730983, lng: 18.968595 },
    { lat: 48.7311536, lng: 18.968557 },
    { lat: 48.7313034, lng: 18.9686004 },
    { lat: 48.7314424, lng: 18.9686067 },
    { lat: 48.7314141, lng: 18.9684907 },
    { lat: 48.7314068, lng: 18.9684591 },
    { lat: 48.7315754, lng: 18.9682713 },
    { lat: 48.7318872, lng: 18.9684187 },
    { lat: 48.7321008, lng: 18.9683403 },
    { lat: 48.7321206, lng: 18.9683295 },
    { lat: 48.7323922, lng: 18.9683694 },
    { lat: 48.7324386, lng: 18.9683765 },
    { lat: 48.7325298, lng: 18.9683245 },
    { lat: 48.7326882, lng: 18.96847 },
    { lat: 48.7329024, lng: 18.9684923 },
    { lat: 48.7330393, lng: 18.9685039 },
    { lat: 48.7332509, lng: 18.9684926 },
    { lat: 48.7334352, lng: 18.9685962 },
    { lat: 48.7338162, lng: 18.9691013 },
    { lat: 48.7338537, lng: 18.9691555 },
    { lat: 48.7339568, lng: 18.9691625 },
    { lat: 48.7340229, lng: 18.9694449 },
    { lat: 48.7342142, lng: 18.9696805 },
    { lat: 48.7343293, lng: 18.9697704 },
    { lat: 48.7344128, lng: 18.9698825 },
    { lat: 48.7345332, lng: 18.9699878 },
    { lat: 48.7345326, lng: 18.9699775 },
    { lat: 48.7346423, lng: 18.9700948 },
    { lat: 48.7347571, lng: 18.970224 },
    { lat: 48.734926, lng: 18.9704898 },
    { lat: 48.7350541, lng: 18.9706996 },
    { lat: 48.7353996, lng: 18.9710874 },
    { lat: 48.7354689, lng: 18.971164 },
    { lat: 48.7357235, lng: 18.9715163 },
    { lat: 48.7358009, lng: 18.9714199 },
    { lat: 48.7358031, lng: 18.9714167 },
    { lat: 48.7358766, lng: 18.97147 },
    { lat: 48.7359002, lng: 18.9714866 },
    { lat: 48.7359864, lng: 18.9718545 },
    { lat: 48.7360791, lng: 18.9721491 },
    { lat: 48.7361837, lng: 18.9724738 },
    { lat: 48.7362144, lng: 18.9726128 },
    { lat: 48.7362331, lng: 18.9726938 },
    { lat: 48.7363192, lng: 18.973101 },
    { lat: 48.7363656, lng: 18.9734142 },
    { lat: 48.7362437, lng: 18.9734787 },
    { lat: 48.736023, lng: 18.9734182 },
    { lat: 48.7359385, lng: 18.9735316 },
    { lat: 48.735888, lng: 18.9736271 },
    { lat: 48.7357959, lng: 18.9737203 },
    { lat: 48.7357144, lng: 18.9739584 },
    { lat: 48.7356949, lng: 18.9740867 },
    { lat: 48.7356512, lng: 18.9742018 },
    { lat: 48.73568, lng: 18.9743038 },
    { lat: 48.7356421, lng: 18.9744225 },
    { lat: 48.7355731, lng: 18.9745537 },
    { lat: 48.7355589, lng: 18.9746743 },
    { lat: 48.7354274, lng: 18.9749239 },
    { lat: 48.735417, lng: 18.9750308 },
    { lat: 48.7355298, lng: 18.9751199 },
    { lat: 48.7355442, lng: 18.9752504 },
    { lat: 48.7356259, lng: 18.9753066 },
    { lat: 48.7356606, lng: 18.975543 },
    { lat: 48.7358129, lng: 18.9755685 },
    { lat: 48.7357491, lng: 18.9756009 },
    { lat: 48.7356596, lng: 18.9756117 },
    { lat: 48.7355304, lng: 18.9755991 },
    { lat: 48.7354614, lng: 18.9756811 },
    { lat: 48.7352858, lng: 18.9755785 },
    { lat: 48.7352598, lng: 18.9757091 },
    { lat: 48.7351619, lng: 18.9761162 },
    { lat: 48.7350834, lng: 18.976301 },
    { lat: 48.7350661, lng: 18.9764919 },
    { lat: 48.7350465, lng: 18.9765753 },
    { lat: 48.7351241, lng: 18.9767267 },
    { lat: 48.735149, lng: 18.9768167 },
    { lat: 48.7351437, lng: 18.9768901 },
    { lat: 48.7351864, lng: 18.976978 },
    { lat: 48.7353182, lng: 18.9770417 },
    { lat: 48.7354923, lng: 18.977093 },
    { lat: 48.735657, lng: 18.9772057 },
    { lat: 48.7357496, lng: 18.9772557 },
    { lat: 48.7360515, lng: 18.9774932 },
    { lat: 48.7360574, lng: 18.9775858 },
    { lat: 48.7361474, lng: 18.9776732 },
    { lat: 48.7362846, lng: 18.9778452 },
    { lat: 48.7364486, lng: 18.9780976 },
    { lat: 48.7365412, lng: 18.978168 },
    { lat: 48.7366332, lng: 18.9784252 },
    { lat: 48.7367827, lng: 18.9784197 },
    { lat: 48.7370333, lng: 18.9784892 },
    { lat: 48.7371836, lng: 18.9786754 },
    { lat: 48.7371944, lng: 18.9786888 },
    { lat: 48.7372649, lng: 18.9788539 },
    { lat: 48.7372662, lng: 18.9789916 },
    { lat: 48.7373195, lng: 18.9790862 },
    { lat: 48.7374144, lng: 18.9791359 },
    { lat: 48.7375128, lng: 18.9791431 },
    { lat: 48.7375523, lng: 18.9792985 },
    { lat: 48.7376608, lng: 18.979327 },
    { lat: 48.7376873, lng: 18.9793382 },
    { lat: 48.7377919, lng: 18.9793786 },
    { lat: 48.7378248, lng: 18.979538 },
    { lat: 48.7378175, lng: 18.9796132 },
    { lat: 48.7379056, lng: 18.9796634 },
    { lat: 48.7379599, lng: 18.9797557 },
    { lat: 48.7380789, lng: 18.9798347 },
    { lat: 48.7381021, lng: 18.9798485 },
    { lat: 48.7381803, lng: 18.979853 },
    { lat: 48.7382668, lng: 18.9799373 },
    { lat: 48.7382905, lng: 18.9800928 },
    { lat: 48.7383463, lng: 18.9802378 },
    { lat: 48.7383975, lng: 18.9804469 },
    { lat: 48.7384637, lng: 18.9805978 },
    { lat: 48.7385466, lng: 18.9806996 },
    { lat: 48.7386322, lng: 18.9808305 },
    { lat: 48.7387394, lng: 18.9808295 },
    { lat: 48.7388226, lng: 18.9809152 },
    { lat: 48.7388647, lng: 18.9811907 },
    { lat: 48.7389216, lng: 18.9812216 },
    { lat: 48.7389477, lng: 18.9813358 },
    { lat: 48.739031, lng: 18.981424 },
    { lat: 48.7391132, lng: 18.9813756 },
    { lat: 48.7392121, lng: 18.9813702 },
    { lat: 48.7392548, lng: 18.9813681 },
    { lat: 48.7392718, lng: 18.9813679 },
    { lat: 48.7392996, lng: 18.9818516 },
    { lat: 48.7392858, lng: 18.9820928 },
    { lat: 48.7392585, lng: 18.9825554 },
    { lat: 48.7392708, lng: 18.9826647 },
    { lat: 48.7393322, lng: 18.9828079 },
    { lat: 48.7394551, lng: 18.9830984 },
    { lat: 48.7394706, lng: 18.9832688 },
    { lat: 48.7394834, lng: 18.9834102 },
    { lat: 48.7394765, lng: 18.9834731 },
    { lat: 48.7394887, lng: 18.9834917 },
    { lat: 48.7395257, lng: 18.9835498 },
    { lat: 48.7395547, lng: 18.9835955 },
    { lat: 48.7395536, lng: 18.983686 },
    { lat: 48.7395512, lng: 18.9838385 },
    { lat: 48.7395948, lng: 18.9839206 },
    { lat: 48.73963, lng: 18.9839244 },
    { lat: 48.7396601, lng: 18.984007 },
    { lat: 48.7396594, lng: 18.9841939 },
    { lat: 48.7396741, lng: 18.9841939 },
    { lat: 48.7396713, lng: 18.9842275 },
    { lat: 48.7394649, lng: 18.9847797 },
    { lat: 48.7394477, lng: 18.9848235 },
    { lat: 48.7394287, lng: 18.9848257 },
    { lat: 48.7394468, lng: 18.9852004 },
    { lat: 48.7394602, lng: 18.9854893 },
    { lat: 48.7394473, lng: 18.9856126 },
    { lat: 48.7395051, lng: 18.9860452 },
    { lat: 48.7395159, lng: 18.986234 },
    { lat: 48.7395996, lng: 18.9867066 },
    { lat: 48.739615, lng: 18.9869641 },
    { lat: 48.7396031, lng: 18.9871319 },
    { lat: 48.7396225, lng: 18.9877384 },
    { lat: 48.7396573, lng: 18.9879822 },
    { lat: 48.7397293, lng: 18.9883129 },
    { lat: 48.739745, lng: 18.9886654 },
    { lat: 48.7397793, lng: 18.9888536 },
    { lat: 48.7398499, lng: 18.9890244 },
    { lat: 48.7398878, lng: 18.9890836 },
    { lat: 48.7403326, lng: 18.9897873 },
    { lat: 48.7403749, lng: 18.9898856 },
    { lat: 48.7405753, lng: 18.9907741 },
    { lat: 48.7407079, lng: 18.9921904 },
    { lat: 48.7407377, lng: 18.9922659 },
    { lat: 48.7410965, lng: 18.9931611 },
    { lat: 48.7414962, lng: 18.9938186 },
    { lat: 48.7417471, lng: 18.9940989 },
    { lat: 48.7419842, lng: 18.9944427 },
    { lat: 48.7421709, lng: 18.9949033 },
    { lat: 48.7425017, lng: 18.9954456 },
    { lat: 48.7426844, lng: 18.9956485 },
    { lat: 48.7427375, lng: 18.9956931 },
    { lat: 48.7429211, lng: 18.9958483 },
    { lat: 48.7431565, lng: 18.9959294 },
    { lat: 48.7432894, lng: 18.9959097 },
    { lat: 48.7434148, lng: 18.9958467 },
    { lat: 48.7438771, lng: 18.9956922 },
    { lat: 48.744149, lng: 18.9954707 },
    { lat: 48.7443186, lng: 18.9952797 },
    { lat: 48.7444751, lng: 18.9950751 },
    { lat: 48.744613, lng: 18.9948595 },
    { lat: 48.7447324, lng: 18.9946317 },
    { lat: 48.7449734, lng: 18.9944492 },
    { lat: 48.7452043, lng: 18.9944232 },
    { lat: 48.7453452, lng: 18.9944437 },
    { lat: 48.7461218, lng: 18.994688 },
    { lat: 48.7461725, lng: 18.9947286 },
    { lat: 48.7462112, lng: 18.9947595 },
    { lat: 48.7465626, lng: 18.9952201 },
    { lat: 48.7466317, lng: 18.9952911 },
    { lat: 48.7469627, lng: 18.9955533 },
    { lat: 48.747103, lng: 18.9956939 },
    { lat: 48.7472101, lng: 18.9957594 },
    { lat: 48.7473756, lng: 18.9957992 },
    { lat: 48.7477071, lng: 18.9958238 },
    { lat: 48.7481996, lng: 18.9955754 },
    { lat: 48.7482663, lng: 18.9955813 },
    { lat: 48.748279, lng: 18.995528 },
    { lat: 48.748314, lng: 18.99547 },
    { lat: 48.748328, lng: 18.995435 },
    { lat: 48.74854, lng: 18.995082 },
    { lat: 48.748566, lng: 18.994936 },
    { lat: 48.748727, lng: 18.994681 },
    { lat: 48.748785, lng: 18.994438 },
    { lat: 48.749012, lng: 18.993903 },
    { lat: 48.74912, lng: 18.993775 },
    { lat: 48.749265, lng: 18.993526 },
    { lat: 48.749327, lng: 18.993404 },
    { lat: 48.749334, lng: 18.993393 },
    { lat: 48.749374, lng: 18.993357 },
    { lat: 48.749439, lng: 18.9933 },
    { lat: 48.74954, lng: 18.993012 },
    { lat: 48.748989, lng: 18.992677 },
    { lat: 48.748833, lng: 18.992398 },
    { lat: 48.748586, lng: 18.992278 },
    { lat: 48.748544, lng: 18.992262 },
    { lat: 48.747903, lng: 18.992132 },
    { lat: 48.747727, lng: 18.992096 },
    { lat: 48.747656, lng: 18.992099 },
    { lat: 48.74739, lng: 18.991783 },
    { lat: 48.747205, lng: 18.99153 },
    { lat: 48.746846, lng: 18.991397 },
    { lat: 48.746704, lng: 18.991172 },
    { lat: 48.746521, lng: 18.990544 },
    { lat: 48.74626, lng: 18.989858 },
    { lat: 48.746185, lng: 18.98976 },
    { lat: 48.746081, lng: 18.989583 },
    { lat: 48.745891, lng: 18.98916 },
    { lat: 48.745773, lng: 18.988813 },
    { lat: 48.745699, lng: 18.988715 },
    { lat: 48.745659, lng: 18.988663 },
    { lat: 48.74562, lng: 18.988219 },
    { lat: 48.745709, lng: 18.987987 },
    { lat: 48.745702, lng: 18.987669 },
    { lat: 48.745745, lng: 18.987505 },
    { lat: 48.745739, lng: 18.987434 },
    { lat: 48.745726, lng: 18.98737 },
    { lat: 48.745624, lng: 18.986795 },
    { lat: 48.745577, lng: 18.986628 },
    { lat: 48.745581, lng: 18.986466 },
    { lat: 48.745624, lng: 18.986189 },
    { lat: 48.745644, lng: 18.986121 },
    { lat: 48.745632, lng: 18.986054 },
    { lat: 48.745593, lng: 18.985925 },
    { lat: 48.745577, lng: 18.985744 },
    { lat: 48.745623, lng: 18.985609 },
    { lat: 48.745605, lng: 18.985378 },
    { lat: 48.745586, lng: 18.985234 },
    { lat: 48.74554, lng: 18.985018 },
    { lat: 48.745474, lng: 18.984889 },
    { lat: 48.745518, lng: 18.984494 },
    { lat: 48.745594, lng: 18.984153 },
    { lat: 48.74555, lng: 18.983724 },
    { lat: 48.745697, lng: 18.983189 },
    { lat: 48.74571, lng: 18.982758 },
    { lat: 48.74587, lng: 18.982115 },
    { lat: 48.74599, lng: 18.98201 },
    { lat: 48.746217, lng: 18.981402 },
    { lat: 48.746318, lng: 18.981276 },
    { lat: 48.746508, lng: 18.981191 },
    { lat: 48.746611, lng: 18.980993 },
    { lat: 48.74668, lng: 18.980924 },
    { lat: 48.746795, lng: 18.980747 },
    { lat: 48.746881, lng: 18.980734 },
    { lat: 48.747094, lng: 18.980306 },
    { lat: 48.747327, lng: 18.979834 },
    { lat: 48.747316, lng: 18.979778 },
    { lat: 48.74731, lng: 18.979749 },
    { lat: 48.747443, lng: 18.979272 },
    { lat: 48.747567, lng: 18.979061 },
    { lat: 48.7476, lng: 18.979024 },
    { lat: 48.747731, lng: 18.97878 },
    { lat: 48.747766, lng: 18.978296 },
    { lat: 48.747946, lng: 18.97778 },
    { lat: 48.74801, lng: 18.977055 },
    { lat: 48.748097, lng: 18.976853 },
    { lat: 48.748057, lng: 18.97641 },
    { lat: 48.74818, lng: 18.975485 },
    { lat: 48.748143, lng: 18.974807 },
    { lat: 48.748036, lng: 18.974414 },
    { lat: 48.747928, lng: 18.974021 },
    { lat: 48.74794, lng: 18.973904 },
    { lat: 48.7479, lng: 18.973805 },
    { lat: 48.747876, lng: 18.973733 },
    { lat: 48.747787, lng: 18.97349 },
    { lat: 48.747783, lng: 18.972916 },
    { lat: 48.747863, lng: 18.972251 },
    { lat: 48.747872, lng: 18.972234 },
    { lat: 48.747901, lng: 18.972197 },
    { lat: 48.748049, lng: 18.971496 },
    { lat: 48.748065, lng: 18.971391 },
    { lat: 48.748051, lng: 18.971246 },
    { lat: 48.747841, lng: 18.970551 },
    { lat: 48.74771, lng: 18.97041 },
    { lat: 48.747622, lng: 18.970243 },
    { lat: 48.747621, lng: 18.970087 },
    { lat: 48.747467, lng: 18.969722 },
    { lat: 48.747471, lng: 18.968706 },
    { lat: 48.747378, lng: 18.968319 },
    { lat: 48.74726, lng: 18.967856 },
    { lat: 48.747289, lng: 18.967673 },
    { lat: 48.747189, lng: 18.967503 },
    { lat: 48.747213, lng: 18.967276 },
    { lat: 48.747173, lng: 18.967088 },
    { lat: 48.747009, lng: 18.966873 },
    { lat: 48.746899, lng: 18.966518 },
    { lat: 48.746905, lng: 18.966419 },
    { lat: 48.746911, lng: 18.96634 },
    { lat: 48.746898, lng: 18.966324 },
    { lat: 48.746682, lng: 18.966066 },
    { lat: 48.746672, lng: 18.966039 },
    { lat: 48.746659, lng: 18.966002 },
    { lat: 48.746587, lng: 18.965818 },
    { lat: 48.746605, lng: 18.965614 },
    { lat: 48.74639, lng: 18.964897 },
    { lat: 48.746393, lng: 18.964511 },
    { lat: 48.746318, lng: 18.964362 },
    { lat: 48.746281, lng: 18.96436 },
    { lat: 48.746235, lng: 18.964339 },
    { lat: 48.746185, lng: 18.964224 },
    { lat: 48.746165, lng: 18.964171 },
    { lat: 48.746089, lng: 18.963996 },
    { lat: 48.746078, lng: 18.963921 },
    { lat: 48.746069, lng: 18.963864 },
    { lat: 48.746046, lng: 18.963713 },
    { lat: 48.746004, lng: 18.963537 },
    { lat: 48.745958, lng: 18.963309 },
    { lat: 48.746427, lng: 18.962328 },
    { lat: 48.746455, lng: 18.962265 },
    { lat: 48.746481, lng: 18.962207 },
    { lat: 48.746735, lng: 18.961853 },
    { lat: 48.746802, lng: 18.961816 },
    { lat: 48.746835, lng: 18.961816 },
    { lat: 48.746889, lng: 18.961779 },
    { lat: 48.746902, lng: 18.96177 },
    { lat: 48.746958, lng: 18.96168 },
    { lat: 48.746992, lng: 18.961624 },
    { lat: 48.7469962, lng: 18.9615848 },
    { lat: 48.747029, lng: 18.961276 },
    { lat: 48.747096, lng: 18.960883 },
    { lat: 48.747127, lng: 18.960151 },
    { lat: 48.747087, lng: 18.959933 },
    { lat: 48.747087, lng: 18.959696 },
    { lat: 48.747061, lng: 18.959465 },
    { lat: 48.747082, lng: 18.95934 },
    { lat: 48.747112, lng: 18.959161 },
    { lat: 48.747136, lng: 18.958892 },
    { lat: 48.747203, lng: 18.958639 },
    { lat: 48.747312, lng: 18.958236 },
    { lat: 48.747318, lng: 18.958035 },
    { lat: 48.747355, lng: 18.957769 },
    { lat: 48.747358, lng: 18.957755 },
    { lat: 48.747398, lng: 18.95758 },
    { lat: 48.747427, lng: 18.957361 },
    { lat: 48.747505, lng: 18.957332 },
    { lat: 48.74757, lng: 18.957086 },
    { lat: 48.747697, lng: 18.956865 },
    { lat: 48.74788, lng: 18.956363 },
    { lat: 48.747882, lng: 18.956359 },
    { lat: 48.748068, lng: 18.956187 },
    { lat: 48.748198, lng: 18.955976 },
    { lat: 48.748302, lng: 18.955632 },
    { lat: 48.748419, lng: 18.955108 },
    { lat: 48.748426, lng: 18.955046 },
    { lat: 48.748505, lng: 18.954748 },
    { lat: 48.748514, lng: 18.954356 },
    { lat: 48.748459, lng: 18.95422 },
    { lat: 48.74858, lng: 18.954109 },
    { lat: 48.748571, lng: 18.953844 },
    { lat: 48.748477, lng: 18.95358 },
    { lat: 48.748478, lng: 18.953189 },
    { lat: 48.748375, lng: 18.952994 },
    { lat: 48.74835, lng: 18.952948 },
    { lat: 48.748353, lng: 18.952918 },
    { lat: 48.748366, lng: 18.952792 },
    { lat: 48.748329, lng: 18.952698 },
    { lat: 48.748325, lng: 18.952488 },
    { lat: 48.748322, lng: 18.952236 },
    { lat: 48.748256, lng: 18.951864 },
    { lat: 48.748327, lng: 18.951774 },
    { lat: 48.748232, lng: 18.951644 },
    { lat: 48.748278, lng: 18.951578 },
    { lat: 48.748195, lng: 18.951378 },
    { lat: 48.748239, lng: 18.951161 },
    { lat: 48.748282, lng: 18.951136 },
    { lat: 48.748347, lng: 18.950934 },
    { lat: 48.748272, lng: 18.950688 },
    { lat: 48.748261, lng: 18.95054 },
    { lat: 48.748321, lng: 18.950401 },
    { lat: 48.748307, lng: 18.950189 },
    { lat: 48.748303, lng: 18.950134 },
    { lat: 48.748302, lng: 18.950126 },
    { lat: 48.748381, lng: 18.949862 },
    { lat: 48.748446, lng: 18.949789 },
    { lat: 48.748467, lng: 18.94956 },
    { lat: 48.748577, lng: 18.949437 },
    { lat: 48.74844, lng: 18.949279 },
    { lat: 48.748492, lng: 18.948913 },
    { lat: 48.74849, lng: 18.948615 },
    { lat: 48.748423, lng: 18.948294 },
    { lat: 48.74847, lng: 18.948246 },
    { lat: 48.748485, lng: 18.948023 },
    { lat: 48.748434, lng: 18.947958 },
    { lat: 48.748451, lng: 18.947692 },
    { lat: 48.748566, lng: 18.947422 },
    { lat: 48.748558, lng: 18.947413 },
    { lat: 48.748552, lng: 18.947406 },
    { lat: 48.74843, lng: 18.94725 },
    { lat: 48.748405, lng: 18.947219 },
    { lat: 48.748379, lng: 18.947187 },
    { lat: 48.748357, lng: 18.947159 },
    { lat: 48.748283, lng: 18.947068 },
    { lat: 48.748247, lng: 18.947024 },
    { lat: 48.748246, lng: 18.947023 },
    { lat: 48.748234, lng: 18.947008 },
    { lat: 48.748232, lng: 18.947 },
    { lat: 48.748232, lng: 18.946995 },
    { lat: 48.748231, lng: 18.946988 },
    { lat: 48.748226, lng: 18.94695 },
    { lat: 48.748201, lng: 18.94679 },
    { lat: 48.748069, lng: 18.945928 },
    { lat: 48.748065, lng: 18.9459 },
    { lat: 48.748044, lng: 18.945412 },
    { lat: 48.748043, lng: 18.945375 },
    { lat: 48.748034, lng: 18.945178 },
    { lat: 48.74807, lng: 18.944927 },
    { lat: 48.748092, lng: 18.944764 },
    { lat: 48.74809, lng: 18.944742 },
    { lat: 48.748443, lng: 18.944639 },
    { lat: 48.748761, lng: 18.944287 },
    { lat: 48.749039, lng: 18.943942 },
    { lat: 48.749155, lng: 18.943706 },
    { lat: 48.749277, lng: 18.943416 },
    { lat: 48.74936, lng: 18.942801 },
    { lat: 48.749348, lng: 18.942797 },
    { lat: 48.749222, lng: 18.942787 },
    { lat: 48.748889, lng: 18.942686 },
    { lat: 48.748673, lng: 18.942537 },
    { lat: 48.748463, lng: 18.942341 },
    { lat: 48.74821, lng: 18.942143 },
    { lat: 48.747876, lng: 18.941825 },
    { lat: 48.747831, lng: 18.941637 },
    { lat: 48.747623, lng: 18.941268 },
    { lat: 48.747547, lng: 18.941181 },
    { lat: 48.747353, lng: 18.94112 },
    { lat: 48.746863, lng: 18.940829 },
    { lat: 48.745734, lng: 18.938358 },
    { lat: 48.745597, lng: 18.937907 },
    { lat: 48.745412, lng: 18.937299 },
    { lat: 48.745301, lng: 18.936712 },
    { lat: 48.745428, lng: 18.936523 },
    { lat: 48.745464, lng: 18.936374 },
    { lat: 48.745492, lng: 18.936079 },
    { lat: 48.745536, lng: 18.935966 },
    { lat: 48.7455, lng: 18.93583 },
    { lat: 48.745487, lng: 18.935674 },
    { lat: 48.745471, lng: 18.935497 },
    { lat: 48.745455, lng: 18.935392 },
    { lat: 48.745419, lng: 18.935256 },
    { lat: 48.745375, lng: 18.935089 },
    { lat: 48.745122, lng: 18.934961 },
    { lat: 48.745009, lng: 18.934858 },
    { lat: 48.744837, lng: 18.934701 },
    { lat: 48.744591, lng: 18.934426 },
    { lat: 48.744511, lng: 18.934357 },
    { lat: 48.744408, lng: 18.934241 },
    { lat: 48.744256, lng: 18.93434 },
    { lat: 48.74412, lng: 18.934194 },
    { lat: 48.743987, lng: 18.93415 },
    { lat: 48.744011, lng: 18.93405 },
    { lat: 48.744085, lng: 18.933745 },
    { lat: 48.744266, lng: 18.933728 },
    { lat: 48.744769, lng: 18.933604 },
    { lat: 48.744969, lng: 18.933688 },
    { lat: 48.745028, lng: 18.933635 },
    { lat: 48.745071, lng: 18.933466 },
    { lat: 48.745159, lng: 18.933276 },
    { lat: 48.745185, lng: 18.933222 },
    { lat: 48.745094, lng: 18.933127 },
    { lat: 48.745231, lng: 18.933072 },
    { lat: 48.745228, lng: 18.933055 },
    { lat: 48.745212, lng: 18.932958 },
    { lat: 48.744909, lng: 18.932723 },
    { lat: 48.744727, lng: 18.932696 },
    { lat: 48.74454, lng: 18.932598 },
    { lat: 48.744158, lng: 18.932811 },
    { lat: 48.744049, lng: 18.932898 },
    { lat: 48.743881, lng: 18.932904 },
    { lat: 48.743781, lng: 18.932933 },
    { lat: 48.743675, lng: 18.932868 },
    { lat: 48.743651, lng: 18.932854 },
    { lat: 48.74359, lng: 18.93276 },
    { lat: 48.743712, lng: 18.932309 },
    { lat: 48.743864, lng: 18.932115 },
    { lat: 48.743898, lng: 18.931904 },
    { lat: 48.743878, lng: 18.931524 },
    { lat: 48.743554, lng: 18.93133 },
    { lat: 48.743283, lng: 18.931322 },
    { lat: 48.743191, lng: 18.931266 },
    { lat: 48.743105, lng: 18.931193 },
    { lat: 48.743049, lng: 18.931041 },
    { lat: 48.742686, lng: 18.930894 },
    { lat: 48.742547, lng: 18.930798 },
    { lat: 48.742401, lng: 18.930613 },
    { lat: 48.742176, lng: 18.930153 },
    { lat: 48.742325, lng: 18.930057 },
    { lat: 48.742428, lng: 18.930026 },
    { lat: 48.742432, lng: 18.930028 },
    { lat: 48.742519, lng: 18.930074 },
    { lat: 48.742557, lng: 18.930068 },
    { lat: 48.742673, lng: 18.929868 },
    { lat: 48.742748, lng: 18.929759 },
    { lat: 48.742706, lng: 18.929605 },
    { lat: 48.742602, lng: 18.929584 },
    { lat: 48.742416, lng: 18.929377 },
    { lat: 48.742351, lng: 18.929144 },
    { lat: 48.742357, lng: 18.929087 },
    { lat: 48.742391, lng: 18.928752 },
    { lat: 48.742391, lng: 18.928439 },
    { lat: 48.74231, lng: 18.928192 },
    { lat: 48.742341, lng: 18.927971 },
    { lat: 48.742288, lng: 18.927738 },
    { lat: 48.742246, lng: 18.927487 },
    { lat: 48.742288, lng: 18.927254 },
    { lat: 48.742288, lng: 18.92717 },
    { lat: 48.742288, lng: 18.927118 },
    { lat: 48.742288, lng: 18.926617 },
    { lat: 48.742263, lng: 18.926559 },
    { lat: 48.742246, lng: 18.926521 },
    { lat: 48.742242, lng: 18.926517 },
    { lat: 48.742198, lng: 18.926472 },
    { lat: 48.742127, lng: 18.926473 },
    { lat: 48.741922, lng: 18.926083 },
    { lat: 48.741922, lng: 18.926036 },
    { lat: 48.741927, lng: 18.925954 },
    { lat: 48.742123, lng: 18.925913 },
    { lat: 48.742139, lng: 18.925908 },
    { lat: 48.742194, lng: 18.925889 },
    { lat: 48.742395, lng: 18.925658 },
    { lat: 48.742432, lng: 18.925263 },
    { lat: 48.742452, lng: 18.92504 },
    { lat: 48.742459, lng: 18.924961 },
    { lat: 48.742529, lng: 18.924862 },
    { lat: 48.74249, lng: 18.924614 },
    { lat: 48.74242, lng: 18.92466 },
    { lat: 48.742284, lng: 18.92475 },
    { lat: 48.742194, lng: 18.924733 },
    { lat: 48.742187, lng: 18.924734 },
    { lat: 48.74186, lng: 18.92481 },
    { lat: 48.741724, lng: 18.924801 },
    { lat: 48.741594, lng: 18.924796 },
    { lat: 48.741544, lng: 18.924637 },
    { lat: 48.741479, lng: 18.924238 },
    { lat: 48.741515, lng: 18.924075 },
    { lat: 48.741467, lng: 18.923873 },
    { lat: 48.741317, lng: 18.92374 },
    { lat: 48.741193, lng: 18.923578 },
    { lat: 48.741154, lng: 18.923515 },
  ],
  BartošovaLehôtka: [
    { lat: 48.6678543, lng: 18.9289265 },
    { lat: 48.6677215, lng: 18.9288435 },
    { lat: 48.6670134, lng: 18.9283415 },
    { lat: 48.6667185, lng: 18.9281751 },
    { lat: 48.6663872, lng: 18.9278049 },
    { lat: 48.6661353, lng: 18.9272411 },
    { lat: 48.6652762, lng: 18.9257462 },
    { lat: 48.6652611, lng: 18.9251013 },
    { lat: 48.6647578, lng: 18.9226703 },
    { lat: 48.6645785, lng: 18.9217257 },
    { lat: 48.6645412, lng: 18.9215239 },
    { lat: 48.6645037, lng: 18.9213217 },
    { lat: 48.6644017, lng: 18.9207605 },
    { lat: 48.6636821, lng: 18.9206013 },
    { lat: 48.6635208, lng: 18.9205384 },
    { lat: 48.6634216, lng: 18.9203592 },
    { lat: 48.6633646, lng: 18.9201276 },
    { lat: 48.6632716, lng: 18.9194162 },
    { lat: 48.6628836, lng: 18.9168852 },
    { lat: 48.6627529, lng: 18.9163376 },
    { lat: 48.6621524, lng: 18.9152721 },
    { lat: 48.6619457, lng: 18.9151069 },
    { lat: 48.6613398, lng: 18.9131258 },
    { lat: 48.6612358, lng: 18.9130752 },
    { lat: 48.6611468, lng: 18.9126159 },
    { lat: 48.6611058, lng: 18.9124976 },
    { lat: 48.6610179, lng: 18.9121245 },
    { lat: 48.6610101, lng: 18.9120829 },
    { lat: 48.6609332, lng: 18.9117274 },
    { lat: 48.6608178, lng: 18.9112369 },
    { lat: 48.6608068, lng: 18.911153 },
    { lat: 48.6607678, lng: 18.9110559 },
    { lat: 48.6607358, lng: 18.9109801 },
    { lat: 48.6607204, lng: 18.910942 },
    { lat: 48.660719, lng: 18.9109375 },
    { lat: 48.6607114, lng: 18.9109188 },
    { lat: 48.6606891, lng: 18.9108629 },
    { lat: 48.6606321, lng: 18.9107176 },
    { lat: 48.6605984, lng: 18.9106331 },
    { lat: 48.6605825, lng: 18.9105205 },
    { lat: 48.6604939, lng: 18.9098681 },
    { lat: 48.6604399, lng: 18.9094789 },
    { lat: 48.6603045, lng: 18.90849 },
    { lat: 48.6602989, lng: 18.9084461 },
    { lat: 48.6601491, lng: 18.9082054 },
    { lat: 48.6599836, lng: 18.9079055 },
    { lat: 48.6597636, lng: 18.907504 },
    { lat: 48.6597318, lng: 18.9044318 },
    { lat: 48.659646, lng: 18.9038159 },
    { lat: 48.6593784, lng: 18.9023375 },
    { lat: 48.6592805, lng: 18.9010732 },
    { lat: 48.6593981, lng: 18.9009246 },
    { lat: 48.659638, lng: 18.9005263 },
    { lat: 48.6603739, lng: 18.8990047 },
    { lat: 48.6604237, lng: 18.8988116 },
    { lat: 48.6608428, lng: 18.8972077 },
    { lat: 48.661083, lng: 18.8963977 },
    { lat: 48.6611609, lng: 18.8960251 },
    { lat: 48.6612144, lng: 18.8944334 },
    { lat: 48.6613724, lng: 18.8935606 },
    { lat: 48.6615438, lng: 18.8918113 },
    { lat: 48.6616858, lng: 18.8912888 },
    { lat: 48.6617584, lng: 18.8906793 },
    { lat: 48.6614853, lng: 18.8890153 },
    { lat: 48.6614324, lng: 18.8882293 },
    { lat: 48.6613941, lng: 18.8881632 },
    { lat: 48.6613668, lng: 18.8881147 },
    { lat: 48.6610123, lng: 18.8880646 },
    { lat: 48.6609454, lng: 18.8880365 },
    { lat: 48.6607442, lng: 18.88785 },
    { lat: 48.6605441, lng: 18.8873371 },
    { lat: 48.6602356, lng: 18.8871697 },
    { lat: 48.6599615, lng: 18.8869262 },
    { lat: 48.6597561, lng: 18.8868601 },
    { lat: 48.6592922, lng: 18.8867409 },
    { lat: 48.6588476, lng: 18.8868024 },
    { lat: 48.6584565, lng: 18.8869803 },
    { lat: 48.6575096, lng: 18.8871158 },
    { lat: 48.6571908, lng: 18.8871415 },
    { lat: 48.6565016, lng: 18.8870969 },
    { lat: 48.6563259, lng: 18.8871208 },
    { lat: 48.6549616, lng: 18.8864886 },
    { lat: 48.6537814, lng: 18.8859504 },
    { lat: 48.6532576, lng: 18.8859371 },
    { lat: 48.6528704, lng: 18.8857782 },
    { lat: 48.6521282, lng: 18.8856408 },
    { lat: 48.6512441, lng: 18.8857243 },
    { lat: 48.6505019, lng: 18.8858522 },
    { lat: 48.6500922, lng: 18.8858664 },
    { lat: 48.6483371, lng: 18.8875158 },
    { lat: 48.648202, lng: 18.8865582 },
    { lat: 48.6477012, lng: 18.8855961 },
    { lat: 48.6474361, lng: 18.8854853 },
    { lat: 48.6470444, lng: 18.8862381 },
    { lat: 48.6468261, lng: 18.8869249 },
    { lat: 48.6463709, lng: 18.8883533 },
    { lat: 48.6461759, lng: 18.8893407 },
    { lat: 48.6461285, lng: 18.890062 },
    { lat: 48.6456663, lng: 18.8906999 },
    { lat: 48.645141, lng: 18.8910511 },
    { lat: 48.6448411, lng: 18.8910963 },
    { lat: 48.6447764, lng: 18.8911064 },
    { lat: 48.6439608, lng: 18.8912307 },
    { lat: 48.6431182, lng: 18.8913595 },
    { lat: 48.6421374, lng: 18.8920829 },
    { lat: 48.6418213, lng: 18.8923193 },
    { lat: 48.6417822, lng: 18.8929175 },
    { lat: 48.6417642, lng: 18.8931774 },
    { lat: 48.6417232, lng: 18.8932803 },
    { lat: 48.6416863, lng: 18.8933716 },
    { lat: 48.6415984, lng: 18.8935916 },
    { lat: 48.6414366, lng: 18.8939967 },
    { lat: 48.6408412, lng: 18.8943489 },
    { lat: 48.6400343, lng: 18.8951902 },
    { lat: 48.638954, lng: 18.8952081 },
    { lat: 48.6384804, lng: 18.8951 },
    { lat: 48.6378306, lng: 18.8955089 },
    { lat: 48.6375639, lng: 18.8955188 },
    { lat: 48.6373849, lng: 18.8955251 },
    { lat: 48.6368449, lng: 18.8956794 },
    { lat: 48.6366075, lng: 18.8956666 },
    { lat: 48.636324, lng: 18.8962913 },
    { lat: 48.6362799, lng: 18.8963813 },
    { lat: 48.6364251, lng: 18.8973136 },
    { lat: 48.6365238, lng: 18.8983121 },
    { lat: 48.6365945, lng: 18.8992916 },
    { lat: 48.6365893, lng: 18.8996263 },
    { lat: 48.6366418, lng: 18.9003435 },
    { lat: 48.6366362, lng: 18.9007342 },
    { lat: 48.6366354, lng: 18.9007877 },
    { lat: 48.6366342, lng: 18.9008524 },
    { lat: 48.6366322, lng: 18.9009425 },
    { lat: 48.6367036, lng: 18.9011901 },
    { lat: 48.6367383, lng: 18.9015151 },
    { lat: 48.6365244, lng: 18.9020239 },
    { lat: 48.6365023, lng: 18.9020771 },
    { lat: 48.6365182, lng: 18.902235 },
    { lat: 48.6365352, lng: 18.9023895 },
    { lat: 48.6365405, lng: 18.9024481 },
    { lat: 48.6365615, lng: 18.902703 },
    { lat: 48.6360135, lng: 18.9044566 },
    { lat: 48.6352423, lng: 18.9065857 },
    { lat: 48.634857, lng: 18.9077303 },
    { lat: 48.6345865, lng: 18.9084802 },
    { lat: 48.6344997, lng: 18.9087874 },
    { lat: 48.63444, lng: 18.9091843 },
    { lat: 48.634154, lng: 18.9116045 },
    { lat: 48.6340838, lng: 18.9120137 },
    { lat: 48.6340367, lng: 18.9122827 },
    { lat: 48.634079, lng: 18.9124727 },
    { lat: 48.6345901, lng: 18.9129837 },
    { lat: 48.6353431, lng: 18.9137691 },
    { lat: 48.6356554, lng: 18.9140951 },
    { lat: 48.636958, lng: 18.9152223 },
    { lat: 48.6384087, lng: 18.9154553 },
    { lat: 48.6388636, lng: 18.9154841 },
    { lat: 48.6390258, lng: 18.9155468 },
    { lat: 48.6395827, lng: 18.9158728 },
    { lat: 48.6407557, lng: 18.916781 },
    { lat: 48.6413218, lng: 18.9166065 },
    { lat: 48.6416604, lng: 18.9164589 },
    { lat: 48.6422258, lng: 18.9166663 },
    { lat: 48.6436746, lng: 18.9176957 },
    { lat: 48.6441762, lng: 18.9185053 },
    { lat: 48.6445698, lng: 18.9191688 },
    { lat: 48.6447675, lng: 18.9198342 },
    { lat: 48.6451111, lng: 18.9201171 },
    { lat: 48.645306, lng: 18.9202869 },
    { lat: 48.6456233, lng: 18.9203825 },
    { lat: 48.6457366, lng: 18.9205044 },
    { lat: 48.6457937, lng: 18.9207381 },
    { lat: 48.6458153, lng: 18.9209848 },
    { lat: 48.6457944, lng: 18.9213003 },
    { lat: 48.6458112, lng: 18.9214531 },
    { lat: 48.6458484, lng: 18.9217832 },
    { lat: 48.6460623, lng: 18.9221932 },
    { lat: 48.6460846, lng: 18.922232 },
    { lat: 48.646229, lng: 18.9224783 },
    { lat: 48.6463384, lng: 18.9227639 },
    { lat: 48.6468882, lng: 18.9241406 },
    { lat: 48.6469256, lng: 18.9241017 },
    { lat: 48.6470913, lng: 18.924476 },
    { lat: 48.6477546, lng: 18.9248557 },
    { lat: 48.6488444, lng: 18.9245791 },
    { lat: 48.6489144, lng: 18.9245581 },
    { lat: 48.6490573, lng: 18.9245289 },
    { lat: 48.6491001, lng: 18.9257388 },
    { lat: 48.6492089, lng: 18.9269863 },
    { lat: 48.6494351, lng: 18.9276381 },
    { lat: 48.6497522, lng: 18.9284776 },
    { lat: 48.6499238, lng: 18.9294514 },
    { lat: 48.6500485, lng: 18.9299689 },
    { lat: 48.6499069, lng: 18.9308391 },
    { lat: 48.6496534, lng: 18.931809 },
    { lat: 48.6496979, lng: 18.9330562 },
    { lat: 48.6495104, lng: 18.9338443 },
    { lat: 48.6496003, lng: 18.9346497 },
    { lat: 48.6496832, lng: 18.935411 },
    { lat: 48.6500641, lng: 18.9358034 },
    { lat: 48.6504486, lng: 18.9359811 },
    { lat: 48.6509739, lng: 18.9366381 },
    { lat: 48.651191, lng: 18.9367391 },
    { lat: 48.6516337, lng: 18.9367335 },
    { lat: 48.6520489, lng: 18.9371352 },
    { lat: 48.6528039, lng: 18.9376748 },
    { lat: 48.6530914, lng: 18.9382501 },
    { lat: 48.6532643, lng: 18.9384108 },
    { lat: 48.6534856, lng: 18.9381503 },
    { lat: 48.6535445, lng: 18.9380689 },
    { lat: 48.6535872, lng: 18.9381328 },
    { lat: 48.6536966, lng: 18.9381967 },
    { lat: 48.6538195, lng: 18.9381974 },
    { lat: 48.653893, lng: 18.9382673 },
    { lat: 48.6540731, lng: 18.938287 },
    { lat: 48.6541702, lng: 18.9382222 },
    { lat: 48.6542503, lng: 18.9383105 },
    { lat: 48.6543977, lng: 18.938369 },
    { lat: 48.6545348, lng: 18.9383623 },
    { lat: 48.6547305, lng: 18.9382892 },
    { lat: 48.6547856, lng: 18.9383502 },
    { lat: 48.6549228, lng: 18.9384968 },
    { lat: 48.6549836, lng: 18.9385172 },
    { lat: 48.6550498, lng: 18.938467 },
    { lat: 48.6551886, lng: 18.9385561 },
    { lat: 48.6556027, lng: 18.9386063 },
    { lat: 48.655956, lng: 18.9390138 },
    { lat: 48.6561767, lng: 18.93938 },
    { lat: 48.6565391, lng: 18.9396335 },
    { lat: 48.657003, lng: 18.9396661 },
    { lat: 48.6579858, lng: 18.9396151 },
    { lat: 48.6586437, lng: 18.9396437 },
    { lat: 48.6590972, lng: 18.9394525 },
    { lat: 48.6595138, lng: 18.9393969 },
    { lat: 48.6598276, lng: 18.939579 },
    { lat: 48.6600951, lng: 18.9395607 },
    { lat: 48.6604713, lng: 18.9394644 },
    { lat: 48.6607788, lng: 18.9393519 },
    { lat: 48.661222, lng: 18.939239 },
    { lat: 48.6614861, lng: 18.9391372 },
    { lat: 48.6615359, lng: 18.9391172 },
    { lat: 48.6617314, lng: 18.938971 },
    { lat: 48.6620279, lng: 18.9387959 },
    { lat: 48.6622216, lng: 18.9383696 },
    { lat: 48.6625023, lng: 18.9381286 },
    { lat: 48.6633517, lng: 18.9377821 },
    { lat: 48.6634054, lng: 18.9377102 },
    { lat: 48.6634394, lng: 18.9376667 },
    { lat: 48.6659195, lng: 18.9344059 },
    { lat: 48.6676251, lng: 18.9321633 },
    { lat: 48.6676504, lng: 18.9319916 },
    { lat: 48.6677416, lng: 18.9311345 },
    { lat: 48.667838, lng: 18.9292034 },
    { lat: 48.6678543, lng: 18.9289265 },
  ],
  LovčicaTrubín: [
    { lat: 48.65592, lng: 18.734286 },
    { lat: 48.655904, lng: 18.734183 },
    { lat: 48.655858, lng: 18.733882 },
    { lat: 48.65573, lng: 18.733068 },
    { lat: 48.655642, lng: 18.732861 },
    { lat: 48.655608, lng: 18.73266 },
    { lat: 48.655569, lng: 18.732425 },
    { lat: 48.655565, lng: 18.732402 },
    { lat: 48.655364, lng: 18.73181 },
    { lat: 48.655338, lng: 18.731735 },
    { lat: 48.655277, lng: 18.731295 },
    { lat: 48.655215, lng: 18.73086 },
    { lat: 48.654862, lng: 18.729679 },
    { lat: 48.654856, lng: 18.729232 },
    { lat: 48.654851, lng: 18.729223 },
    { lat: 48.654788, lng: 18.729095 },
    { lat: 48.654431, lng: 18.729165 },
    { lat: 48.654343, lng: 18.729311 },
    { lat: 48.654343, lng: 18.729312 },
    { lat: 48.653953, lng: 18.728592 },
    { lat: 48.654131, lng: 18.727929 },
    { lat: 48.653899, lng: 18.727152 },
    { lat: 48.653847, lng: 18.726977 },
    { lat: 48.653854, lng: 18.726738 },
    { lat: 48.653861, lng: 18.726511 },
    { lat: 48.653829, lng: 18.725746 },
    { lat: 48.653787, lng: 18.725541 },
    { lat: 48.653664, lng: 18.72494 },
    { lat: 48.653646, lng: 18.724853 },
    { lat: 48.653343, lng: 18.724082 },
    { lat: 48.653404, lng: 18.723795 },
    { lat: 48.653418, lng: 18.723728 },
    { lat: 48.653379, lng: 18.7237 },
    { lat: 48.652979, lng: 18.7234 },
    { lat: 48.652779, lng: 18.723208 },
    { lat: 48.652497, lng: 18.722802 },
    { lat: 48.652467, lng: 18.722749 },
    { lat: 48.651964, lng: 18.721851 },
    { lat: 48.651427, lng: 18.72117 },
    { lat: 48.650748, lng: 18.720447 },
    { lat: 48.650243, lng: 18.7197 },
    { lat: 48.650052, lng: 18.719333 },
    { lat: 48.65003, lng: 18.719293 },
    { lat: 48.650019, lng: 18.71927 },
    { lat: 48.649825, lng: 18.718859 },
    { lat: 48.649719, lng: 18.718659 },
    { lat: 48.649487, lng: 18.718235 },
    { lat: 48.649361, lng: 18.718005 },
    { lat: 48.649285, lng: 18.717781 },
    { lat: 48.649214, lng: 18.717575 },
    { lat: 48.649172, lng: 18.717259 },
    { lat: 48.649116, lng: 18.716831 },
    { lat: 48.649021, lng: 18.715963 },
    { lat: 48.64895, lng: 18.715314 },
    { lat: 48.648834, lng: 18.715012 },
    { lat: 48.648664, lng: 18.714771 },
    { lat: 48.648616, lng: 18.714645 },
    { lat: 48.648581, lng: 18.714552 },
    { lat: 48.648559, lng: 18.714491 },
    { lat: 48.648438, lng: 18.71436 },
    { lat: 48.648277, lng: 18.714173 },
    { lat: 48.648033, lng: 18.714067 },
    { lat: 48.647793, lng: 18.713885 },
    { lat: 48.647656, lng: 18.713894 },
    { lat: 48.647368, lng: 18.714042 },
    { lat: 48.647314, lng: 18.714029 },
    { lat: 48.647279, lng: 18.714021 },
    { lat: 48.647224, lng: 18.713979 },
    { lat: 48.647101, lng: 18.713884 },
    { lat: 48.647008, lng: 18.713852 },
    { lat: 48.64657, lng: 18.713912 },
    { lat: 48.646473, lng: 18.714019 },
    { lat: 48.646447, lng: 18.714139 },
    { lat: 48.646418, lng: 18.714265 },
    { lat: 48.646179, lng: 18.714445 },
    { lat: 48.646034, lng: 18.714364 },
    { lat: 48.645959, lng: 18.714323 },
    { lat: 48.645712, lng: 18.714498 },
    { lat: 48.645561, lng: 18.714807 },
    { lat: 48.644949, lng: 18.715333 },
    { lat: 48.644732, lng: 18.715595 },
    { lat: 48.644589, lng: 18.715767 },
    { lat: 48.64442, lng: 18.715888 },
    { lat: 48.644306, lng: 18.715927 },
    { lat: 48.644068, lng: 18.716007 },
    { lat: 48.643972, lng: 18.716077 },
    { lat: 48.643779, lng: 18.716215 },
    { lat: 48.643474, lng: 18.71626 },
    { lat: 48.64324, lng: 18.716408 },
    { lat: 48.6428, lng: 18.716373 },
    { lat: 48.642717, lng: 18.716367 },
    { lat: 48.642428, lng: 18.716453 },
    { lat: 48.641893, lng: 18.716461 },
    { lat: 48.641673, lng: 18.71654 },
    { lat: 48.641608, lng: 18.716563 },
    { lat: 48.641002, lng: 18.71687 },
    { lat: 48.640576, lng: 18.716978 },
    { lat: 48.640422, lng: 18.716976 },
    { lat: 48.639996, lng: 18.716969 },
    { lat: 48.639796, lng: 18.716929 },
    { lat: 48.639615, lng: 18.716893 },
    { lat: 48.639303, lng: 18.716891 },
    { lat: 48.639165, lng: 18.71689 },
    { lat: 48.638685, lng: 18.716478 },
    { lat: 48.6386, lng: 18.716423 },
    { lat: 48.63851, lng: 18.716371 },
    { lat: 48.638473, lng: 18.716367 },
    { lat: 48.638357, lng: 18.716355 },
    { lat: 48.638153, lng: 18.716333 },
    { lat: 48.637858, lng: 18.716463 },
    { lat: 48.637833, lng: 18.716474 },
    { lat: 48.637735, lng: 18.71656 },
    { lat: 48.637263, lng: 18.716979 },
    { lat: 48.637261, lng: 18.716982 },
    { lat: 48.637052, lng: 18.717194 },
    { lat: 48.63639, lng: 18.717764 },
    { lat: 48.635809, lng: 18.718076 },
    { lat: 48.635662, lng: 18.71806 },
    { lat: 48.635108, lng: 18.718374 },
    { lat: 48.634928, lng: 18.718545 },
    { lat: 48.634431, lng: 18.719163 },
    { lat: 48.634126, lng: 18.719581 },
    { lat: 48.633849, lng: 18.719754 },
    { lat: 48.633256, lng: 18.71985 },
    { lat: 48.632963, lng: 18.719763 },
    { lat: 48.632603, lng: 18.719752 },
    { lat: 48.632159, lng: 18.719586 },
    { lat: 48.631649, lng: 18.719534 },
    { lat: 48.630579, lng: 18.719071 },
    { lat: 48.630263, lng: 18.718661 },
    { lat: 48.629786, lng: 18.718457 },
    { lat: 48.629523, lng: 18.718367 },
    { lat: 48.629366, lng: 18.718203 },
    { lat: 48.629253, lng: 18.718203 },
    { lat: 48.628725, lng: 18.718389 },
    { lat: 48.62813, lng: 18.718373 },
    { lat: 48.627953, lng: 18.718351 },
    { lat: 48.627867, lng: 18.718255 },
    { lat: 48.627668, lng: 18.717884 },
    { lat: 48.627278, lng: 18.71753 },
    { lat: 48.626793, lng: 18.717252 },
    { lat: 48.626472, lng: 18.716405 },
    { lat: 48.626462, lng: 18.716378 },
    { lat: 48.6264249, lng: 18.7164491 },
    { lat: 48.6262944, lng: 18.7167273 },
    { lat: 48.6262781, lng: 18.7167631 },
    { lat: 48.6262305, lng: 18.7168641 },
    { lat: 48.6258117, lng: 18.7173892 },
    { lat: 48.6252547, lng: 18.7179006 },
    { lat: 48.6250317, lng: 18.71833 },
    { lat: 48.625026, lng: 18.7187974 },
    { lat: 48.6246448, lng: 18.7197107 },
    { lat: 48.6246673, lng: 18.7197953 },
    { lat: 48.6247952, lng: 18.7200997 },
    { lat: 48.6249547, lng: 18.7214265 },
    { lat: 48.6249615, lng: 18.7214289 },
    { lat: 48.6250907, lng: 18.7224621 },
    { lat: 48.6251867, lng: 18.7230152 },
    { lat: 48.6253371, lng: 18.7239403 },
    { lat: 48.6252191, lng: 18.7243299 },
    { lat: 48.6246286, lng: 18.7244026 },
    { lat: 48.6241867, lng: 18.7242559 },
    { lat: 48.623536, lng: 18.7241915 },
    { lat: 48.622739, lng: 18.7242837 },
    { lat: 48.6220783, lng: 18.7237553 },
    { lat: 48.6213402, lng: 18.7228534 },
    { lat: 48.6211458, lng: 18.722632 },
    { lat: 48.6206834, lng: 18.7222686 },
    { lat: 48.6202908, lng: 18.7226057 },
    { lat: 48.6199004, lng: 18.7227141 },
    { lat: 48.6188914, lng: 18.7229967 },
    { lat: 48.6176063, lng: 18.7230277 },
    { lat: 48.6169435, lng: 18.7229266 },
    { lat: 48.616978, lng: 18.7232343 },
    { lat: 48.6166702, lng: 18.7236197 },
    { lat: 48.6166451, lng: 18.7236598 },
    { lat: 48.6164254, lng: 18.724021 },
    { lat: 48.6162541, lng: 18.7242184 },
    { lat: 48.6161312, lng: 18.7250466 },
    { lat: 48.6159937, lng: 18.7252621 },
    { lat: 48.6158899, lng: 18.7257276 },
    { lat: 48.6158484, lng: 18.7261803 },
    { lat: 48.6157134, lng: 18.7267818 },
    { lat: 48.6155344, lng: 18.7272346 },
    { lat: 48.6151896, lng: 18.7277499 },
    { lat: 48.6148534, lng: 18.7283863 },
    { lat: 48.6148539, lng: 18.7287156 },
    { lat: 48.6148081, lng: 18.7289346 },
    { lat: 48.6147909, lng: 18.7290172 },
    { lat: 48.6147451, lng: 18.7292362 },
    { lat: 48.6143946, lng: 18.7293017 },
    { lat: 48.6140005, lng: 18.7294636 },
    { lat: 48.6135671, lng: 18.7297371 },
    { lat: 48.613403, lng: 18.7299351 },
    { lat: 48.613351, lng: 18.7299977 },
    { lat: 48.6133115, lng: 18.7301051 },
    { lat: 48.6132556, lng: 18.7302619 },
    { lat: 48.6131966, lng: 18.7306172 },
    { lat: 48.6132283, lng: 18.7311305 },
    { lat: 48.6133166, lng: 18.7318818 },
    { lat: 48.6133171, lng: 18.7325065 },
    { lat: 48.6131518, lng: 18.7333929 },
    { lat: 48.6127272, lng: 18.7339561 },
    { lat: 48.6125373, lng: 18.7344177 },
    { lat: 48.6122826, lng: 18.735035 },
    { lat: 48.6121478, lng: 18.7351753 },
    { lat: 48.6117267, lng: 18.7356141 },
    { lat: 48.6112984, lng: 18.7362148 },
    { lat: 48.6111849, lng: 18.7364548 },
    { lat: 48.6110569, lng: 18.7368943 },
    { lat: 48.6109741, lng: 18.7372047 },
    { lat: 48.6107941, lng: 18.7380239 },
    { lat: 48.6105686, lng: 18.7390675 },
    { lat: 48.6104464, lng: 18.7394863 },
    { lat: 48.610431, lng: 18.7396432 },
    { lat: 48.6104509, lng: 18.739979 },
    { lat: 48.610481, lng: 18.7402651 },
    { lat: 48.6105256, lng: 18.740599 },
    { lat: 48.6105207, lng: 18.7409136 },
    { lat: 48.6104898, lng: 18.7412017 },
    { lat: 48.6104573, lng: 18.7415014 },
    { lat: 48.6104312, lng: 18.7418228 },
    { lat: 48.610427, lng: 18.7420417 },
    { lat: 48.6104259, lng: 18.7421064 },
    { lat: 48.610387, lng: 18.7423943 },
    { lat: 48.6103459, lng: 18.7425764 },
    { lat: 48.6101999, lng: 18.7430348 },
    { lat: 48.6101433, lng: 18.7432415 },
    { lat: 48.6100919, lng: 18.7433821 },
    { lat: 48.6099768, lng: 18.7435916 },
    { lat: 48.6098998, lng: 18.7437138 },
    { lat: 48.6096826, lng: 18.7439587 },
    { lat: 48.6096138, lng: 18.7441053 },
    { lat: 48.6095722, lng: 18.7442179 },
    { lat: 48.6095285, lng: 18.7443479 },
    { lat: 48.6093126, lng: 18.744532 },
    { lat: 48.6092703, lng: 18.7445646 },
    { lat: 48.6090588, lng: 18.7447254 },
    { lat: 48.6088538, lng: 18.7448566 },
    { lat: 48.6087479, lng: 18.7449246 },
    { lat: 48.6086152, lng: 18.7450563 },
    { lat: 48.6084625, lng: 18.7452832 },
    { lat: 48.6083537, lng: 18.7454858 },
    { lat: 48.608256, lng: 18.7456857 },
    { lat: 48.6081325, lng: 18.7458659 },
    { lat: 48.6080018, lng: 18.7461247 },
    { lat: 48.6078073, lng: 18.7464157 },
    { lat: 48.6077096, lng: 18.74653 },
    { lat: 48.6074401, lng: 18.7467217 },
    { lat: 48.6073331, lng: 18.7468511 },
    { lat: 48.6072215, lng: 18.7470245 },
    { lat: 48.6071335, lng: 18.7472774 },
    { lat: 48.6070696, lng: 18.7475627 },
    { lat: 48.6070317, lng: 18.7479998 },
    { lat: 48.6070277, lng: 18.7480534 },
    { lat: 48.607021, lng: 18.7481187 },
    { lat: 48.6070153, lng: 18.7481814 },
    { lat: 48.6069831, lng: 18.7482729 },
    { lat: 48.6069702, lng: 18.7483066 },
    { lat: 48.6069113, lng: 18.7484537 },
    { lat: 48.6068319, lng: 18.7486143 },
    { lat: 48.606649, lng: 18.7489955 },
    { lat: 48.6063871, lng: 18.7494581 },
    { lat: 48.6062335, lng: 18.7496248 },
    { lat: 48.606107, lng: 18.7499222 },
    { lat: 48.6059876, lng: 18.7500734 },
    { lat: 48.6059496, lng: 18.750187 },
    { lat: 48.6059098, lng: 18.7503945 },
    { lat: 48.6058167, lng: 18.7504887 },
    { lat: 48.6056737, lng: 18.7508548 },
    { lat: 48.6055073, lng: 18.7513786 },
    { lat: 48.6053859, lng: 18.7516433 },
    { lat: 48.6053211, lng: 18.751737 },
    { lat: 48.6050932, lng: 18.7519938 },
    { lat: 48.6047924, lng: 18.7522331 },
    { lat: 48.6046377, lng: 18.7523999 },
    { lat: 48.6042617, lng: 18.7530533 },
    { lat: 48.6042037, lng: 18.7531539 },
    { lat: 48.6040796, lng: 18.7533456 },
    { lat: 48.6039218, lng: 18.7535395 },
    { lat: 48.6033563, lng: 18.7537859 },
    { lat: 48.6032225, lng: 18.753897 },
    { lat: 48.6030919, lng: 18.7540317 },
    { lat: 48.6029296, lng: 18.7542476 },
    { lat: 48.6027518, lng: 18.7545105 },
    { lat: 48.6026319, lng: 18.7547825 },
    { lat: 48.6025783, lng: 18.7549407 },
    { lat: 48.6025563, lng: 18.7550374 },
    { lat: 48.6025437, lng: 18.7550938 },
    { lat: 48.602471, lng: 18.7554724 },
    { lat: 48.6023611, lng: 18.7557806 },
    { lat: 48.6023034, lng: 18.7560989 },
    { lat: 48.6022156, lng: 18.7564668 },
    { lat: 48.6021774, lng: 18.7566838 },
    { lat: 48.6018478, lng: 18.7570611 },
    { lat: 48.6017989, lng: 18.7571158 },
    { lat: 48.6017195, lng: 18.7572582 },
    { lat: 48.6017387, lng: 18.7573453 },
    { lat: 48.6014981, lng: 18.7575506 },
    { lat: 48.6014865, lng: 18.7576945 },
    { lat: 48.6012675, lng: 18.7579684 },
    { lat: 48.6012098, lng: 18.7582863 },
    { lat: 48.6005883, lng: 18.7586477 },
    { lat: 48.6004886, lng: 18.7588097 },
    { lat: 48.6004916, lng: 18.7592518 },
    { lat: 48.6003738, lng: 18.7595193 },
    { lat: 48.6002919, lng: 18.7599966 },
    { lat: 48.6002671, lng: 18.7602336 },
    { lat: 48.6001086, lng: 18.7603912 },
    { lat: 48.6000931, lng: 18.7605912 },
    { lat: 48.599839, lng: 18.7608814 },
    { lat: 48.5998521, lng: 18.761152 },
    { lat: 48.5998263, lng: 18.7613881 },
    { lat: 48.599742, lng: 18.7614802 },
    { lat: 48.5997125, lng: 18.7620132 },
    { lat: 48.5995208, lng: 18.7623529 },
    { lat: 48.5994609, lng: 18.7625218 },
    { lat: 48.5994268, lng: 18.7627122 },
    { lat: 48.5991974, lng: 18.7628758 },
    { lat: 48.5991889, lng: 18.7630945 },
    { lat: 48.5989907, lng: 18.7634019 },
    { lat: 48.598801, lng: 18.7636306 },
    { lat: 48.5985885, lng: 18.7636671 },
    { lat: 48.5984506, lng: 18.7637659 },
    { lat: 48.5984241, lng: 18.7643363 },
    { lat: 48.5982042, lng: 18.7645268 },
    { lat: 48.5982771, lng: 18.7647565 },
    { lat: 48.5981469, lng: 18.7650436 },
    { lat: 48.5982042, lng: 18.7653605 },
    { lat: 48.5980478, lng: 18.7655179 },
    { lat: 48.5980127, lng: 18.7656225 },
    { lat: 48.5980571, lng: 18.7659104 },
    { lat: 48.5980354, lng: 18.7662248 },
    { lat: 48.5979506, lng: 18.7663514 },
    { lat: 48.5979496, lng: 18.7664805 },
    { lat: 48.5978336, lng: 18.7667809 },
    { lat: 48.5978866, lng: 18.766953 },
    { lat: 48.5978205, lng: 18.7672403 },
    { lat: 48.5977704, lng: 18.7674657 },
    { lat: 48.5977615, lng: 18.7678965 },
    { lat: 48.5979088, lng: 18.7678895 },
    { lat: 48.5980413, lng: 18.7680896 },
    { lat: 48.5982056, lng: 18.7685587 },
    { lat: 48.5981391, lng: 18.7693501 },
    { lat: 48.598201, lng: 18.7697086 },
    { lat: 48.5980688, lng: 18.7699628 },
    { lat: 48.5980671, lng: 18.7701472 },
    { lat: 48.5980675, lng: 18.7702159 },
    { lat: 48.5980664, lng: 18.7702581 },
    { lat: 48.598052, lng: 18.7702865 },
    { lat: 48.5980204, lng: 18.7703491 },
    { lat: 48.5979314, lng: 18.7705229 },
    { lat: 48.5979104, lng: 18.7710025 },
    { lat: 48.5978495, lng: 18.7713454 },
    { lat: 48.5976655, lng: 18.7716442 },
    { lat: 48.5974687, lng: 18.771972 },
    { lat: 48.5973285, lng: 18.7720301 },
    { lat: 48.5970468, lng: 18.7725049 },
    { lat: 48.5968658, lng: 18.7726035 },
    { lat: 48.5970811, lng: 18.7729461 },
    { lat: 48.5970642, lng: 18.7732431 },
    { lat: 48.5969397, lng: 18.7734729 },
    { lat: 48.596764, lng: 18.7735388 },
    { lat: 48.5967063, lng: 18.773732 },
    { lat: 48.5966477, lng: 18.773816 },
    { lat: 48.596536, lng: 18.7739437 },
    { lat: 48.5965422, lng: 18.7741478 },
    { lat: 48.5964671, lng: 18.7743716 },
    { lat: 48.5966108, lng: 18.774592 },
    { lat: 48.5965423, lng: 18.7752397 },
    { lat: 48.5966134, lng: 18.7754371 },
    { lat: 48.5965834, lng: 18.7756805 },
    { lat: 48.5963711, lng: 18.7760379 },
    { lat: 48.5965349, lng: 18.7762367 },
    { lat: 48.5966153, lng: 18.776525 },
    { lat: 48.5964911, lng: 18.7767333 },
    { lat: 48.5961208, lng: 18.7768146 },
    { lat: 48.5962537, lng: 18.7770905 },
    { lat: 48.596173, lng: 18.7774197 },
    { lat: 48.5959843, lng: 18.7775623 },
    { lat: 48.5958825, lng: 18.777598 },
    { lat: 48.5959948, lng: 18.7780406 },
    { lat: 48.595953, lng: 18.7782736 },
    { lat: 48.5958784, lng: 18.7784859 },
    { lat: 48.5959401, lng: 18.778739 },
    { lat: 48.5958754, lng: 18.7789879 },
    { lat: 48.5956986, lng: 18.779247 },
    { lat: 48.5956901, lng: 18.7794543 },
    { lat: 48.5955762, lng: 18.7796243 },
    { lat: 48.5954926, lng: 18.7797928 },
    { lat: 48.5956046, lng: 18.7799942 },
    { lat: 48.59554, lng: 18.780438 },
    { lat: 48.5950341, lng: 18.7806182 },
    { lat: 48.5950684, lng: 18.7813568 },
    { lat: 48.5949874, lng: 18.7816579 },
    { lat: 48.5951618, lng: 18.7817582 },
    { lat: 48.5951915, lng: 18.7821526 },
    { lat: 48.5951722, lng: 18.7824496 },
    { lat: 48.595139, lng: 18.7825476 },
    { lat: 48.595014, lng: 18.7829159 },
    { lat: 48.5950424, lng: 18.7831551 },
    { lat: 48.5949993, lng: 18.7833241 },
    { lat: 48.5949007, lng: 18.7835297 },
    { lat: 48.594789, lng: 18.7835035 },
    { lat: 48.594769, lng: 18.7836406 },
    { lat: 48.5948432, lng: 18.7837671 },
    { lat: 48.5947583, lng: 18.7839311 },
    { lat: 48.5947534, lng: 18.7842032 },
    { lat: 48.5946647, lng: 18.7843178 },
    { lat: 48.5946406, lng: 18.7845258 },
    { lat: 48.5945965, lng: 18.7845639 },
    { lat: 48.5943652, lng: 18.7844112 },
    { lat: 48.5943308, lng: 18.7846376 },
    { lat: 48.5944234, lng: 18.785006 },
    { lat: 48.5944189, lng: 18.7851134 },
    { lat: 48.5942621, lng: 18.7852821 },
    { lat: 48.5943113, lng: 18.7855751 },
    { lat: 48.59424, lng: 18.7856766 },
    { lat: 48.5939486, lng: 18.7858197 },
    { lat: 48.5938563, lng: 18.785975 },
    { lat: 48.5940269, lng: 18.7860633 },
    { lat: 48.5941458, lng: 18.7862888 },
    { lat: 48.594234, lng: 18.786506 },
    { lat: 48.5942585, lng: 18.7865675 },
    { lat: 48.5942688, lng: 18.7865941 },
    { lat: 48.5942854, lng: 18.7867339 },
    { lat: 48.5941724, lng: 18.7871827 },
    { lat: 48.5940972, lng: 18.7872312 },
    { lat: 48.5939546, lng: 18.7871182 },
    { lat: 48.5937178, lng: 18.7874585 },
    { lat: 48.5934454, lng: 18.787662 },
    { lat: 48.5933909, lng: 18.7878495 },
    { lat: 48.5935014, lng: 18.7879332 },
    { lat: 48.5933738, lng: 18.7883606 },
    { lat: 48.5932766, lng: 18.7885927 },
    { lat: 48.5931943, lng: 18.7892804 },
    { lat: 48.5931227, lng: 18.7894211 },
    { lat: 48.5931476, lng: 18.789571 },
    { lat: 48.5930551, lng: 18.7896811 },
    { lat: 48.5930785, lng: 18.7898471 },
    { lat: 48.5930709, lng: 18.7899139 },
    { lat: 48.5930016, lng: 18.7900583 },
    { lat: 48.5928942, lng: 18.7901584 },
    { lat: 48.5928833, lng: 18.7901679 },
    { lat: 48.5928811, lng: 18.7901891 },
    { lat: 48.5928646, lng: 18.7903913 },
    { lat: 48.5926868, lng: 18.7904168 },
    { lat: 48.5925764, lng: 18.79029 },
    { lat: 48.5926077, lng: 18.790116 },
    { lat: 48.5925331, lng: 18.7900055 },
    { lat: 48.5924647, lng: 18.790035 },
    { lat: 48.5924023, lng: 18.790196 },
    { lat: 48.5924165, lng: 18.7904676 },
    { lat: 48.5923494, lng: 18.7905447 },
    { lat: 48.592217, lng: 18.7904928 },
    { lat: 48.5920299, lng: 18.7905575 },
    { lat: 48.5916716, lng: 18.7904805 },
    { lat: 48.5913475, lng: 18.7906848 },
    { lat: 48.5913262, lng: 18.7908366 },
    { lat: 48.5912036, lng: 18.7909506 },
    { lat: 48.5910864, lng: 18.7910597 },
    { lat: 48.5907527, lng: 18.7909545 },
    { lat: 48.5905095, lng: 18.790701 },
    { lat: 48.5902756, lng: 18.790693 },
    { lat: 48.5900938, lng: 18.7910573 },
    { lat: 48.5900066, lng: 18.7914987 },
    { lat: 48.5897914, lng: 18.7915258 },
    { lat: 48.5896051, lng: 18.7917361 },
    { lat: 48.5895984, lng: 18.791821 },
    { lat: 48.5895908, lng: 18.7919152 },
    { lat: 48.5894466, lng: 18.7920891 },
    { lat: 48.5894932, lng: 18.7922243 },
    { lat: 48.5900928, lng: 18.7939784 },
    { lat: 48.590363, lng: 18.794769 },
    { lat: 48.590727, lng: 18.7957077 },
    { lat: 48.5909266, lng: 18.7962243 },
    { lat: 48.5909709, lng: 18.7963391 },
    { lat: 48.5916957, lng: 18.7982241 },
    { lat: 48.5920332, lng: 18.799073 },
    { lat: 48.5920424, lng: 18.7990965 },
    { lat: 48.5921789, lng: 18.7990126 },
    { lat: 48.5921839, lng: 18.7991267 },
    { lat: 48.5922095, lng: 18.799768 },
    { lat: 48.5922546, lng: 18.8008452 },
    { lat: 48.5923838, lng: 18.8020628 },
    { lat: 48.5924671, lng: 18.8039327 },
    { lat: 48.5924887, lng: 18.804945 },
    { lat: 48.5925089, lng: 18.8059136 },
    { lat: 48.5925169, lng: 18.8062839 },
    { lat: 48.5925227, lng: 18.8065888 },
    { lat: 48.5925252, lng: 18.8067 },
    { lat: 48.5925256, lng: 18.8067299 },
    { lat: 48.5925259, lng: 18.8067577 },
    { lat: 48.5923946, lng: 18.8068545 },
    { lat: 48.592282, lng: 18.8071183 },
    { lat: 48.5922017, lng: 18.8073049 },
    { lat: 48.5921541, lng: 18.8072796 },
    { lat: 48.5918328, lng: 18.8071105 },
    { lat: 48.5917353, lng: 18.8070583 },
    { lat: 48.5915889, lng: 18.8071277 },
    { lat: 48.5915494, lng: 18.8071894 },
    { lat: 48.5913878, lng: 18.807443 },
    { lat: 48.5913344, lng: 18.8074113 },
    { lat: 48.5910643, lng: 18.8072496 },
    { lat: 48.5910024, lng: 18.8070147 },
    { lat: 48.5909812, lng: 18.8069357 },
    { lat: 48.5909646, lng: 18.8068347 },
    { lat: 48.5907252, lng: 18.8071492 },
    { lat: 48.5907377, lng: 18.8071716 },
    { lat: 48.5907587, lng: 18.8072079 },
    { lat: 48.5907784, lng: 18.8072408 },
    { lat: 48.590837, lng: 18.8073454 },
    { lat: 48.5908542, lng: 18.807375 },
    { lat: 48.5911553, lng: 18.8078906 },
    { lat: 48.5911265, lng: 18.808204 },
    { lat: 48.591466, lng: 18.808658 },
    { lat: 48.5916832, lng: 18.8087124 },
    { lat: 48.5922734, lng: 18.8094298 },
    { lat: 48.5929956, lng: 18.8105184 },
    { lat: 48.5931128, lng: 18.8107759 },
    { lat: 48.5932071, lng: 18.8109852 },
    { lat: 48.5934631, lng: 18.8115461 },
    { lat: 48.593574, lng: 18.8120263 },
    { lat: 48.5935533, lng: 18.8120826 },
    { lat: 48.5934069, lng: 18.812474 },
    { lat: 48.5935442, lng: 18.8132913 },
    { lat: 48.5937592, lng: 18.81438 },
    { lat: 48.5937605, lng: 18.8143881 },
    { lat: 48.5937752, lng: 18.8144294 },
    { lat: 48.5937923, lng: 18.8144766 },
    { lat: 48.594297, lng: 18.81589 },
    { lat: 48.5948797, lng: 18.8172019 },
    { lat: 48.5954081, lng: 18.8181424 },
    { lat: 48.5954703, lng: 18.8182522 },
    { lat: 48.5958061, lng: 18.8180762 },
    { lat: 48.5963125, lng: 18.817818 },
    { lat: 48.5969182, lng: 18.817521 },
    { lat: 48.596991, lng: 18.8174895 },
    { lat: 48.5975063, lng: 18.8172075 },
    { lat: 48.5978096, lng: 18.8170567 },
    { lat: 48.5987644, lng: 18.8165297 },
    { lat: 48.5995563, lng: 18.8160156 },
    { lat: 48.5999299, lng: 18.8157433 },
    { lat: 48.6000923, lng: 18.8156363 },
    { lat: 48.6006352, lng: 18.8152768 },
    { lat: 48.6009078, lng: 18.8151208 },
    { lat: 48.6013513, lng: 18.8162065 },
    { lat: 48.6013683, lng: 18.816228 },
    { lat: 48.6014734, lng: 18.8163619 },
    { lat: 48.601506, lng: 18.8164033 },
    { lat: 48.6016158, lng: 18.8165424 },
    { lat: 48.6024736, lng: 18.8159542 },
    { lat: 48.602503, lng: 18.8159336 },
    { lat: 48.6025313, lng: 18.8159142 },
    { lat: 48.6028674, lng: 18.8163681 },
    { lat: 48.6038406, lng: 18.8156785 },
    { lat: 48.6044526, lng: 18.8152837 },
    { lat: 48.6060814, lng: 18.8196321 },
    { lat: 48.6061339, lng: 18.8197731 },
    { lat: 48.606185, lng: 18.8199095 },
    { lat: 48.6072223, lng: 18.818975 },
    { lat: 48.6087131, lng: 18.8173784 },
    { lat: 48.6106923, lng: 18.815978 },
    { lat: 48.6114006, lng: 18.8178802 },
    { lat: 48.6123416, lng: 18.8203125 },
    { lat: 48.6123781, lng: 18.8203598 },
    { lat: 48.6124329, lng: 18.8204171 },
    { lat: 48.6124597, lng: 18.820456 },
    { lat: 48.612964, lng: 18.8194878 },
    { lat: 48.6139703, lng: 18.8179838 },
    { lat: 48.6145605, lng: 18.8171482 },
    { lat: 48.6153618, lng: 18.8187825 },
    { lat: 48.6155613, lng: 18.8189955 },
    { lat: 48.6181179, lng: 18.8214825 },
    { lat: 48.6186484, lng: 18.8207352 },
    { lat: 48.6190013, lng: 18.8202392 },
    { lat: 48.6191173, lng: 18.8199368 },
    { lat: 48.6194007, lng: 18.8195745 },
    { lat: 48.620301, lng: 18.8180907 },
    { lat: 48.6204931, lng: 18.8176869 },
    { lat: 48.6206203, lng: 18.8172548 },
    { lat: 48.6207413, lng: 18.8168469 },
    { lat: 48.6211737, lng: 18.8162538 },
    { lat: 48.6213461, lng: 18.8159036 },
    { lat: 48.6219095, lng: 18.8150536 },
    { lat: 48.6219892, lng: 18.8149418 },
    { lat: 48.6223864, lng: 18.814339 },
    { lat: 48.6226083, lng: 18.8135345 },
    { lat: 48.6226228, lng: 18.8131257 },
    { lat: 48.6232554, lng: 18.8119131 },
    { lat: 48.6233905, lng: 18.8115645 },
    { lat: 48.6236229, lng: 18.8116338 },
    { lat: 48.6236574, lng: 18.811624 },
    { lat: 48.6236392, lng: 18.8108014 },
    { lat: 48.6238006, lng: 18.8104574 },
    { lat: 48.6242903, lng: 18.8098182 },
    { lat: 48.6244571, lng: 18.8096002 },
    { lat: 48.6247484, lng: 18.8090879 },
    { lat: 48.6250306, lng: 18.8084487 },
    { lat: 48.6255179, lng: 18.8078029 },
    { lat: 48.6254789, lng: 18.8071424 },
    { lat: 48.6256591, lng: 18.8068147 },
    { lat: 48.6258636, lng: 18.8062197 },
    { lat: 48.6259178, lng: 18.8055306 },
    { lat: 48.6259468, lng: 18.80516 },
    { lat: 48.6259943, lng: 18.8047933 },
    { lat: 48.6260327, lng: 18.8046885 },
    { lat: 48.6261335, lng: 18.8044153 },
    { lat: 48.6267247, lng: 18.8036872 },
    { lat: 48.6268413, lng: 18.8034808 },
    { lat: 48.6270481, lng: 18.8031022 },
    { lat: 48.6270715, lng: 18.803139 },
    { lat: 48.6270946, lng: 18.8031698 },
    { lat: 48.6271164, lng: 18.803201 },
    { lat: 48.6271313, lng: 18.8032067 },
    { lat: 48.6271694, lng: 18.8032218 },
    { lat: 48.6276225, lng: 18.8034302 },
    { lat: 48.6280953, lng: 18.8027711 },
    { lat: 48.6284389, lng: 18.8027619 },
    { lat: 48.6291836, lng: 18.8022989 },
    { lat: 48.6297024, lng: 18.801307 },
    { lat: 48.6300586, lng: 18.801174 },
    { lat: 48.6302658, lng: 18.8008478 },
    { lat: 48.6304833, lng: 18.8007381 },
    { lat: 48.630619, lng: 18.800354 },
    { lat: 48.6308478, lng: 18.8001792 },
    { lat: 48.6315589, lng: 18.7995262 },
    { lat: 48.6322342, lng: 18.7989434 },
    { lat: 48.6326346, lng: 18.7984489 },
    { lat: 48.6331978, lng: 18.7980253 },
    { lat: 48.6333347, lng: 18.7976892 },
    { lat: 48.6337854, lng: 18.7971434 },
    { lat: 48.6340074, lng: 18.7965184 },
    { lat: 48.6346123, lng: 18.7960755 },
    { lat: 48.634532, lng: 18.795726 },
    { lat: 48.6345999, lng: 18.794869 },
    { lat: 48.6345072, lng: 18.7942191 },
    { lat: 48.6349311, lng: 18.793786 },
    { lat: 48.6354405, lng: 18.7929636 },
    { lat: 48.6357313, lng: 18.7928937 },
    { lat: 48.6351522, lng: 18.7919809 },
    { lat: 48.6355593, lng: 18.7912278 },
    { lat: 48.6363184, lng: 18.7898547 },
    { lat: 48.6371353, lng: 18.7884875 },
    { lat: 48.6375333, lng: 18.7882282 },
    { lat: 48.6377689, lng: 18.7880746 },
    { lat: 48.638515, lng: 18.7876168 },
    { lat: 48.6384957, lng: 18.7875685 },
    { lat: 48.6384658, lng: 18.787495 },
    { lat: 48.6384528, lng: 18.787462 },
    { lat: 48.6384462, lng: 18.7874446 },
    { lat: 48.6383896, lng: 18.7873116 },
    { lat: 48.6378714, lng: 18.7860746 },
    { lat: 48.6380486, lng: 18.7858384 },
    { lat: 48.6387526, lng: 18.7849035 },
    { lat: 48.6393235, lng: 18.7842623 },
    { lat: 48.63985, lng: 18.783461 },
    { lat: 48.6396391, lng: 18.783054 },
    { lat: 48.6402711, lng: 18.7812391 },
    { lat: 48.6405416, lng: 18.7807643 },
    { lat: 48.640409, lng: 18.7805348 },
    { lat: 48.6402862, lng: 18.7803245 },
    { lat: 48.6402617, lng: 18.7800739 },
    { lat: 48.6404641, lng: 18.7797602 },
    { lat: 48.6419401, lng: 18.7781919 },
    { lat: 48.6425377, lng: 18.7775877 },
    { lat: 48.6431946, lng: 18.777339 },
    { lat: 48.6435283, lng: 18.7768455 },
    { lat: 48.6444264, lng: 18.7763169 },
    { lat: 48.6450594, lng: 18.7756991 },
    { lat: 48.645435, lng: 18.7755072 },
    { lat: 48.6463008, lng: 18.7742392 },
    { lat: 48.6468729, lng: 18.7731747 },
    { lat: 48.6468753, lng: 18.7730489 },
    { lat: 48.6468787, lng: 18.7728306 },
    { lat: 48.6468804, lng: 18.7727366 },
    { lat: 48.6468533, lng: 18.7726075 },
    { lat: 48.646848, lng: 18.7725079 },
    { lat: 48.6468529, lng: 18.7724079 },
    { lat: 48.6469623, lng: 18.771332 },
    { lat: 48.6470064, lng: 18.770631 },
    { lat: 48.6473536, lng: 18.7695973 },
    { lat: 48.6477229, lng: 18.7687071 },
    { lat: 48.6477634, lng: 18.7685746 },
    { lat: 48.6478028, lng: 18.76847 },
    { lat: 48.6478613, lng: 18.768315 },
    { lat: 48.6480099, lng: 18.7684808 },
    { lat: 48.6480697, lng: 18.7683963 },
    { lat: 48.6481527, lng: 18.7682801 },
    { lat: 48.6482357, lng: 18.7681631 },
    { lat: 48.6483163, lng: 18.7680482 },
    { lat: 48.6483623, lng: 18.7678933 },
    { lat: 48.6484074, lng: 18.7677399 },
    { lat: 48.6484485, lng: 18.7676005 },
    { lat: 48.6484896, lng: 18.7674604 },
    { lat: 48.6485326, lng: 18.7673126 },
    { lat: 48.6485365, lng: 18.7673015 },
    { lat: 48.6487096, lng: 18.7667112 },
    { lat: 48.6487382, lng: 18.7666125 },
    { lat: 48.6487603, lng: 18.7665607 },
    { lat: 48.6487761, lng: 18.7665195 },
    { lat: 48.6487823, lng: 18.7665077 },
    { lat: 48.6488348, lng: 18.7663909 },
    { lat: 48.6488903, lng: 18.766269 },
    { lat: 48.6488983, lng: 18.766251 },
    { lat: 48.6489449, lng: 18.7661484 },
    { lat: 48.648949, lng: 18.7661407 },
    { lat: 48.6490932, lng: 18.7657968 },
    { lat: 48.6492377, lng: 18.7654567 },
    { lat: 48.649341, lng: 18.7652111 },
    { lat: 48.6498965, lng: 18.7637246 },
    { lat: 48.6499836, lng: 18.7633226 },
    { lat: 48.649984, lng: 18.7633072 },
    { lat: 48.649993, lng: 18.7622358 },
    { lat: 48.649721, lng: 18.7623209 },
    { lat: 48.6497518, lng: 18.7619809 },
    { lat: 48.6499551, lng: 18.761113 },
    { lat: 48.6500111, lng: 18.7611249 },
    { lat: 48.6500247, lng: 18.7607423 },
    { lat: 48.6500512, lng: 18.7604966 },
    { lat: 48.6502154, lng: 18.7601238 },
    { lat: 48.6503171, lng: 18.7599758 },
    { lat: 48.6503588, lng: 18.7598898 },
    { lat: 48.6504393, lng: 18.7593855 },
    { lat: 48.6503829, lng: 18.7593862 },
    { lat: 48.6503603, lng: 18.7592762 },
    { lat: 48.6504518, lng: 18.7586806 },
    { lat: 48.6503795, lng: 18.7584839 },
    { lat: 48.6503656, lng: 18.7584117 },
    { lat: 48.6503564, lng: 18.7583679 },
    { lat: 48.6506173, lng: 18.7576472 },
    { lat: 48.6509311, lng: 18.7570142 },
    { lat: 48.6512102, lng: 18.7564004 },
    { lat: 48.6512181, lng: 18.7561466 },
    { lat: 48.6512133, lng: 18.7560784 },
    { lat: 48.6512095, lng: 18.7560228 },
    { lat: 48.6512029, lng: 18.7558574 },
    { lat: 48.6512017, lng: 18.7558351 },
    { lat: 48.651198, lng: 18.7557435 },
    { lat: 48.6515348, lng: 18.7550315 },
    { lat: 48.6517175, lng: 18.7549425 },
    { lat: 48.651963, lng: 18.7543406 },
    { lat: 48.6519728, lng: 18.7542663 },
    { lat: 48.651963, lng: 18.7539557 },
    { lat: 48.6519966, lng: 18.7539474 },
    { lat: 48.6520409, lng: 18.7539364 },
    { lat: 48.652051, lng: 18.7539341 },
    { lat: 48.6520601, lng: 18.753933 },
    { lat: 48.6520779, lng: 18.7539301 },
    { lat: 48.6521138, lng: 18.7539251 },
    { lat: 48.6522907, lng: 18.7538996 },
    { lat: 48.6522996, lng: 18.7538985 },
    { lat: 48.6523207, lng: 18.7538956 },
    { lat: 48.6523646, lng: 18.7538725 },
    { lat: 48.6524031, lng: 18.7538529 },
    { lat: 48.6524481, lng: 18.7538297 },
    { lat: 48.6524909, lng: 18.7538074 },
    { lat: 48.6524988, lng: 18.7538036 },
    { lat: 48.6525763, lng: 18.7536074 },
    { lat: 48.6526438, lng: 18.7534356 },
    { lat: 48.652683, lng: 18.753407 },
    { lat: 48.6527029, lng: 18.7534217 },
    { lat: 48.6527228, lng: 18.7534368 },
    { lat: 48.6528894, lng: 18.753558 },
    { lat: 48.6529411, lng: 18.753596 },
    { lat: 48.6528888, lng: 18.7533534 },
    { lat: 48.6531178, lng: 18.7528657 },
    { lat: 48.6533112, lng: 18.7526157 },
    { lat: 48.6535256, lng: 18.752062 },
    { lat: 48.6534061, lng: 18.751678 },
    { lat: 48.653388, lng: 18.751658 },
    { lat: 48.6533341, lng: 18.7515996 },
    { lat: 48.6532853, lng: 18.7515463 },
    { lat: 48.6532322, lng: 18.7515045 },
    { lat: 48.6529038, lng: 18.7512458 },
    { lat: 48.6528239, lng: 18.7511826 },
    { lat: 48.6527182, lng: 18.7511044 },
    { lat: 48.6526752, lng: 18.7510785 },
    { lat: 48.6526326, lng: 18.7510412 },
    { lat: 48.6525959, lng: 18.7510078 },
    { lat: 48.6525556, lng: 18.7509712 },
    { lat: 48.6527444, lng: 18.7504457 },
    { lat: 48.652926, lng: 18.7500629 },
    { lat: 48.6532053, lng: 18.7491986 },
    { lat: 48.6532292, lng: 18.7484563 },
    { lat: 48.6536131, lng: 18.7477603 },
    { lat: 48.6537583, lng: 18.7474971 },
    { lat: 48.6538109, lng: 18.7469772 },
    { lat: 48.6537101, lng: 18.7462852 },
    { lat: 48.6536423, lng: 18.7458147 },
    { lat: 48.653621, lng: 18.7456667 },
    { lat: 48.6535583, lng: 18.7452277 },
    { lat: 48.6535784, lng: 18.7451158 },
    { lat: 48.6536046, lng: 18.744971 },
    { lat: 48.6536282, lng: 18.7448409 },
    { lat: 48.6536127, lng: 18.7442927 },
    { lat: 48.6536074, lng: 18.7437445 },
    { lat: 48.6540956, lng: 18.7426756 },
    { lat: 48.6537037, lng: 18.7415336 },
    { lat: 48.654086, lng: 18.741 },
    { lat: 48.6541222, lng: 18.7400618 },
    { lat: 48.6545599, lng: 18.7388459 },
    { lat: 48.6545079, lng: 18.7382708 },
    { lat: 48.6544766, lng: 18.7381925 },
    { lat: 48.6544327, lng: 18.7380851 },
    { lat: 48.6542874, lng: 18.7379161 },
    { lat: 48.6545708, lng: 18.7379668 },
    { lat: 48.6547326, lng: 18.7379962 },
    { lat: 48.6550458, lng: 18.7374573 },
    { lat: 48.6553309, lng: 18.7364763 },
    { lat: 48.6552705, lng: 18.7360805 },
    { lat: 48.655136, lng: 18.7357942 },
    { lat: 48.6550867, lng: 18.7355227 },
    { lat: 48.6551675, lng: 18.7352815 },
    { lat: 48.6553405, lng: 18.7355046 },
    { lat: 48.6554156, lng: 18.7356015 },
    { lat: 48.6554867, lng: 18.7355612 },
    { lat: 48.6555087, lng: 18.7355489 },
    { lat: 48.6556763, lng: 18.7354552 },
    { lat: 48.6556816, lng: 18.7354478 },
    { lat: 48.6558186, lng: 18.735376 },
    { lat: 48.6557967, lng: 18.7352163 },
    { lat: 48.6556482, lng: 18.7349699 },
    { lat: 48.6555265, lng: 18.7346076 },
    { lat: 48.65592, lng: 18.734286 },
  ],
  Kosorín: [
    { lat: 48.693826, lng: 18.780564 },
    { lat: 48.6935698, lng: 18.7810267 },
    { lat: 48.6930279, lng: 18.7817227 },
    { lat: 48.6916922, lng: 18.7824018 },
    { lat: 48.6907638, lng: 18.7838771 },
    { lat: 48.6904547, lng: 18.7840925 },
    { lat: 48.6897134, lng: 18.7846199 },
    { lat: 48.6887357, lng: 18.7850105 },
    { lat: 48.688296, lng: 18.785097 },
    { lat: 48.6879212, lng: 18.7854991 },
    { lat: 48.6871225, lng: 18.7862649 },
    { lat: 48.6869567, lng: 18.78633 },
    { lat: 48.6867651, lng: 18.7865057 },
    { lat: 48.685612, lng: 18.7875904 },
    { lat: 48.6852612, lng: 18.7878682 },
    { lat: 48.68479, lng: 18.7883007 },
    { lat: 48.6840527, lng: 18.7891243 },
    { lat: 48.683774, lng: 18.7893361 },
    { lat: 48.6834198, lng: 18.7895065 },
    { lat: 48.6832148, lng: 18.7895324 },
    { lat: 48.6824537, lng: 18.789663 },
    { lat: 48.6822028, lng: 18.7898903 },
    { lat: 48.6820869, lng: 18.7898931 },
    { lat: 48.6817791, lng: 18.7899163 },
    { lat: 48.6816355, lng: 18.790018 },
    { lat: 48.6813, lng: 18.7906102 },
    { lat: 48.6811794, lng: 18.7908016 },
    { lat: 48.6809441, lng: 18.7910695 },
    { lat: 48.6805018, lng: 18.7911916 },
    { lat: 48.6802037, lng: 18.7912711 },
    { lat: 48.6797793, lng: 18.7916554 },
    { lat: 48.6797545, lng: 18.7916734 },
    { lat: 48.6792409, lng: 18.792033 },
    { lat: 48.678713, lng: 18.792393 },
    { lat: 48.6778826, lng: 18.7927319 },
    { lat: 48.6776147, lng: 18.7928281 },
    { lat: 48.6766236, lng: 18.7924437 },
    { lat: 48.6762838, lng: 18.7923262 },
    { lat: 48.6752609, lng: 18.7915521 },
    { lat: 48.6745837, lng: 18.7910663 },
    { lat: 48.6744449, lng: 18.7909559 },
    { lat: 48.6737624, lng: 18.7911452 },
    { lat: 48.672903, lng: 18.7915964 },
    { lat: 48.6722221, lng: 18.7922258 },
    { lat: 48.6718108, lng: 18.7922963 },
    { lat: 48.6706302, lng: 18.7915193 },
    { lat: 48.6700499, lng: 18.7912275 },
    { lat: 48.6695824, lng: 18.7910643 },
    { lat: 48.6690446, lng: 18.7908303 },
    { lat: 48.6689812, lng: 18.7908054 },
    { lat: 48.6679107, lng: 18.7903909 },
    { lat: 48.667658, lng: 18.7903433 },
    { lat: 48.6671568, lng: 18.7903351 },
    { lat: 48.6659631, lng: 18.7904559 },
    { lat: 48.6652823, lng: 18.7905044 },
    { lat: 48.664862, lng: 18.79051 },
    { lat: 48.6641418, lng: 18.7910305 },
    { lat: 48.6637378, lng: 18.7896076 },
    { lat: 48.6632945, lng: 18.789586 },
    { lat: 48.662947, lng: 18.7892811 },
    { lat: 48.6623087, lng: 18.7888469 },
    { lat: 48.6607345, lng: 18.7898593 },
    { lat: 48.6606602, lng: 18.7899074 },
    { lat: 48.6603825, lng: 18.790181 },
    { lat: 48.6591129, lng: 18.7914309 },
    { lat: 48.6590742, lng: 18.791469 },
    { lat: 48.6588019, lng: 18.791737 },
    { lat: 48.657929, lng: 18.7924904 },
    { lat: 48.6569766, lng: 18.7933823 },
    { lat: 48.6558923, lng: 18.7920372 },
    { lat: 48.6554475, lng: 18.7925444 },
    { lat: 48.655371, lng: 18.7926316 },
    { lat: 48.655039, lng: 18.7930105 },
    { lat: 48.6549857, lng: 18.793071 },
    { lat: 48.6549261, lng: 18.7931384 },
    { lat: 48.6548675, lng: 18.7932053 },
    { lat: 48.654808, lng: 18.7932734 },
    { lat: 48.6547462, lng: 18.7933436 },
    { lat: 48.6546759, lng: 18.7934237 },
    { lat: 48.6546037, lng: 18.7935054 },
    { lat: 48.6545323, lng: 18.7935874 },
    { lat: 48.6544567, lng: 18.7936734 },
    { lat: 48.6543823, lng: 18.7937583 },
    { lat: 48.6543067, lng: 18.7938444 },
    { lat: 48.6542322, lng: 18.7939296 },
    { lat: 48.6541557, lng: 18.7940168 },
    { lat: 48.6540779, lng: 18.7941056 },
    { lat: 48.653996, lng: 18.7941982 },
    { lat: 48.6539151, lng: 18.7942909 },
    { lat: 48.6538257, lng: 18.7943925 },
    { lat: 48.6537365, lng: 18.7944948 },
    { lat: 48.6536417, lng: 18.7946028 },
    { lat: 48.6535619, lng: 18.794694 },
    { lat: 48.6534905, lng: 18.7947742 },
    { lat: 48.6534278, lng: 18.7948455 },
    { lat: 48.6533681, lng: 18.794914 },
    { lat: 48.6532893, lng: 18.7950033 },
    { lat: 48.6532724, lng: 18.7950235 },
    { lat: 48.6532388, lng: 18.7950486 },
    { lat: 48.6530097, lng: 18.7952189 },
    { lat: 48.6528742, lng: 18.7953203 },
    { lat: 48.6528046, lng: 18.7953728 },
    { lat: 48.6526886, lng: 18.7954591 },
    { lat: 48.6526202, lng: 18.79551 },
    { lat: 48.6525258, lng: 18.7955809 },
    { lat: 48.6524605, lng: 18.795629 },
    { lat: 48.6523978, lng: 18.795676 },
    { lat: 48.6522002, lng: 18.7958241 },
    { lat: 48.6521144, lng: 18.7958878 },
    { lat: 48.6520416, lng: 18.7959418 },
    { lat: 48.6518952, lng: 18.7960513 },
    { lat: 48.6517378, lng: 18.7961688 },
    { lat: 48.6516348, lng: 18.796246 },
    { lat: 48.6515718, lng: 18.7962931 },
    { lat: 48.6514958, lng: 18.7963493 },
    { lat: 48.6514297, lng: 18.7963989 },
    { lat: 48.6513287, lng: 18.7964744 },
    { lat: 48.65123, lng: 18.7965482 },
    { lat: 48.6511356, lng: 18.796618 },
    { lat: 48.6509553, lng: 18.7967529 },
    { lat: 48.6508252, lng: 18.7968497 },
    { lat: 48.650709, lng: 18.7969367 },
    { lat: 48.650276, lng: 18.7972598 },
    { lat: 48.6502152, lng: 18.7973052 },
    { lat: 48.6501787, lng: 18.7973391 },
    { lat: 48.6501292, lng: 18.797385 },
    { lat: 48.6500926, lng: 18.7974186 },
    { lat: 48.650086, lng: 18.7972917 },
    { lat: 48.650047, lng: 18.7966155 },
    { lat: 48.6502011, lng: 18.795962 },
    { lat: 48.649712, lng: 18.7962874 },
    { lat: 48.6495638, lng: 18.7963861 },
    { lat: 48.6494601, lng: 18.7964548 },
    { lat: 48.6492596, lng: 18.7965882 },
    { lat: 48.6491617, lng: 18.7966534 },
    { lat: 48.6490528, lng: 18.7967259 },
    { lat: 48.648815, lng: 18.7968837 },
    { lat: 48.6487041, lng: 18.7969576 },
    { lat: 48.6485906, lng: 18.7970332 },
    { lat: 48.6484969, lng: 18.7970953 },
    { lat: 48.6483704, lng: 18.7971793 },
    { lat: 48.6481199, lng: 18.7973454 },
    { lat: 48.6481144, lng: 18.7973492 },
    { lat: 48.6479364, lng: 18.79746 },
    { lat: 48.647884, lng: 18.7974926 },
    { lat: 48.6475693, lng: 18.7970198 },
    { lat: 48.6473876, lng: 18.7966057 },
    { lat: 48.647093, lng: 18.7959355 },
    { lat: 48.6466729, lng: 18.7949746 },
    { lat: 48.6450674, lng: 18.7955423 },
    { lat: 48.6449633, lng: 18.7952345 },
    { lat: 48.6438917, lng: 18.7966065 },
    { lat: 48.643395, lng: 18.7972423 },
    { lat: 48.6432526, lng: 18.7974287 },
    { lat: 48.642996, lng: 18.7977635 },
    { lat: 48.6428616, lng: 18.797972 },
    { lat: 48.6427269, lng: 18.7981796 },
    { lat: 48.6426772, lng: 18.7982194 },
    { lat: 48.6423988, lng: 18.7984391 },
    { lat: 48.6423497, lng: 18.7986852 },
    { lat: 48.6422223, lng: 18.7990545 },
    { lat: 48.6419825, lng: 18.8003953 },
    { lat: 48.6419614, lng: 18.8005119 },
    { lat: 48.6419416, lng: 18.8006262 },
    { lat: 48.6418951, lng: 18.8010732 },
    { lat: 48.6418555, lng: 18.8014584 },
    { lat: 48.6418494, lng: 18.8015144 },
    { lat: 48.6417592, lng: 18.8019256 },
    { lat: 48.6417281, lng: 18.8020637 },
    { lat: 48.6416862, lng: 18.8022559 },
    { lat: 48.6416808, lng: 18.8022797 },
    { lat: 48.6416049, lng: 18.8024681 },
    { lat: 48.6415211, lng: 18.8026788 },
    { lat: 48.6414603, lng: 18.8028294 },
    { lat: 48.6413187, lng: 18.8031822 },
    { lat: 48.6413087, lng: 18.8032076 },
    { lat: 48.6411786, lng: 18.8033946 },
    { lat: 48.6410685, lng: 18.8035525 },
    { lat: 48.6410168, lng: 18.8035997 },
    { lat: 48.6407908, lng: 18.8038052 },
    { lat: 48.6405862, lng: 18.8039918 },
    { lat: 48.6405497, lng: 18.8040251 },
    { lat: 48.6400796, lng: 18.8043941 },
    { lat: 48.6398577, lng: 18.8048085 },
    { lat: 48.6401572, lng: 18.8059455 },
    { lat: 48.640572, lng: 18.8069095 },
    { lat: 48.6408494, lng: 18.807601 },
    { lat: 48.6408831, lng: 18.8076844 },
    { lat: 48.6413487, lng: 18.8087396 },
    { lat: 48.6415018, lng: 18.809359 },
    { lat: 48.6415405, lng: 18.8095155 },
    { lat: 48.6422086, lng: 18.8104341 },
    { lat: 48.6423276, lng: 18.8108984 },
    { lat: 48.642349, lng: 18.8109818 },
    { lat: 48.6423702, lng: 18.8110674 },
    { lat: 48.6424026, lng: 18.8111266 },
    { lat: 48.6423752, lng: 18.8112037 },
    { lat: 48.6421617, lng: 18.8113507 },
    { lat: 48.6420872, lng: 18.8115207 },
    { lat: 48.6419288, lng: 18.8117228 },
    { lat: 48.6418369, lng: 18.8119099 },
    { lat: 48.6418838, lng: 18.811965 },
    { lat: 48.6418971, lng: 18.8119801 },
    { lat: 48.6419195, lng: 18.8120206 },
    { lat: 48.6419387, lng: 18.8121075 },
    { lat: 48.6419476, lng: 18.812151 },
    { lat: 48.6420788, lng: 18.8127408 },
    { lat: 48.642504, lng: 18.8126974 },
    { lat: 48.6425966, lng: 18.8126607 },
    { lat: 48.6428851, lng: 18.8125469 },
    { lat: 48.6429748, lng: 18.812512 },
    { lat: 48.6430572, lng: 18.8124526 },
    { lat: 48.6432518, lng: 18.8123146 },
    { lat: 48.6432764, lng: 18.8122664 },
    { lat: 48.6434856, lng: 18.8118457 },
    { lat: 48.6435685, lng: 18.8116191 },
    { lat: 48.643878, lng: 18.8114325 },
    { lat: 48.6443147, lng: 18.8122388 },
    { lat: 48.6447406, lng: 18.812922 },
    { lat: 48.6448266, lng: 18.8130591 },
    { lat: 48.6449165, lng: 18.8132042 },
    { lat: 48.6449425, lng: 18.8132457 },
    { lat: 48.6449387, lng: 18.8133492 },
    { lat: 48.6448447, lng: 18.8133596 },
    { lat: 48.6445958, lng: 18.8133889 },
    { lat: 48.644316, lng: 18.8137506 },
    { lat: 48.6441068, lng: 18.8140215 },
    { lat: 48.6443656, lng: 18.8147695 },
    { lat: 48.6446486, lng: 18.8157411 },
    { lat: 48.64468, lng: 18.8159748 },
    { lat: 48.6449225, lng: 18.8166863 },
    { lat: 48.6451152, lng: 18.8175042 },
    { lat: 48.6453135, lng: 18.8176322 },
    { lat: 48.6454138, lng: 18.8176969 },
    { lat: 48.6456357, lng: 18.817973 },
    { lat: 48.6460929, lng: 18.8182167 },
    { lat: 48.6463758, lng: 18.8183675 },
    { lat: 48.6466819, lng: 18.8183101 },
    { lat: 48.6468493, lng: 18.8183444 },
    { lat: 48.647016, lng: 18.8182101 },
    { lat: 48.6471847, lng: 18.8180749 },
    { lat: 48.6472708, lng: 18.8179726 },
    { lat: 48.6473813, lng: 18.8178432 },
    { lat: 48.6474701, lng: 18.8176836 },
    { lat: 48.6475639, lng: 18.8175159 },
    { lat: 48.6475858, lng: 18.8175925 },
    { lat: 48.6478274, lng: 18.818441 },
    { lat: 48.648055, lng: 18.8190837 },
    { lat: 48.6482369, lng: 18.8193912 },
    { lat: 48.6483185, lng: 18.8195281 },
    { lat: 48.64852, lng: 18.8198679 },
    { lat: 48.6488437, lng: 18.8202143 },
    { lat: 48.6493048, lng: 18.8205157 },
    { lat: 48.6496003, lng: 18.8204318 },
    { lat: 48.6499149, lng: 18.8209728 },
    { lat: 48.6502828, lng: 18.8212355 },
    { lat: 48.6506386, lng: 18.8216604 },
    { lat: 48.6507197, lng: 18.8222189 },
    { lat: 48.6508758, lng: 18.8233095 },
    { lat: 48.6509058, lng: 18.8240163 },
    { lat: 48.6510524, lng: 18.8247931 },
    { lat: 48.6511196, lng: 18.8247616 },
    { lat: 48.6516622, lng: 18.8245057 },
    { lat: 48.6519284, lng: 18.8243806 },
    { lat: 48.6520815, lng: 18.8243082 },
    { lat: 48.6524144, lng: 18.824294 },
    { lat: 48.652699, lng: 18.8239546 },
    { lat: 48.652654, lng: 18.8238704 },
    { lat: 48.6532816, lng: 18.8237528 },
    { lat: 48.6536531, lng: 18.8237629 },
    { lat: 48.6543772, lng: 18.8233284 },
    { lat: 48.6545066, lng: 18.8232196 },
    { lat: 48.6545596, lng: 18.8231751 },
    { lat: 48.6546807, lng: 18.823073 },
    { lat: 48.6547017, lng: 18.823067 },
    { lat: 48.6550127, lng: 18.8229779 },
    { lat: 48.6551373, lng: 18.8229418 },
    { lat: 48.6551619, lng: 18.8229385 },
    { lat: 48.6552871, lng: 18.82292 },
    { lat: 48.6556654, lng: 18.8228658 },
    { lat: 48.6557724, lng: 18.8228196 },
    { lat: 48.6560624, lng: 18.8226938 },
    { lat: 48.6561905, lng: 18.822639 },
    { lat: 48.6562224, lng: 18.8226253 },
    { lat: 48.6562697, lng: 18.8225983 },
    { lat: 48.6562947, lng: 18.8225839 },
    { lat: 48.656321, lng: 18.8225686 },
    { lat: 48.656413, lng: 18.8225156 },
    { lat: 48.6564656, lng: 18.8224858 },
    { lat: 48.6571991, lng: 18.8222139 },
    { lat: 48.6572797, lng: 18.8221837 },
    { lat: 48.6574833, lng: 18.8221085 },
    { lat: 48.6576725, lng: 18.8220386 },
    { lat: 48.6578197, lng: 18.821984 },
    { lat: 48.6579071, lng: 18.8219515 },
    { lat: 48.6579956, lng: 18.8219188 },
    { lat: 48.6580851, lng: 18.8218861 },
    { lat: 48.658156, lng: 18.8218591 },
    { lat: 48.658501, lng: 18.8217315 },
    { lat: 48.658635, lng: 18.821682 },
    { lat: 48.658708, lng: 18.8216552 },
    { lat: 48.6587655, lng: 18.8216337 },
    { lat: 48.6588031, lng: 18.82162 },
    { lat: 48.6590156, lng: 18.8215409 },
    { lat: 48.6591561, lng: 18.8214889 },
    { lat: 48.6591937, lng: 18.8214748 },
    { lat: 48.6593552, lng: 18.8214131 },
    { lat: 48.6595199, lng: 18.8213504 },
    { lat: 48.6596846, lng: 18.8212873 },
    { lat: 48.659845, lng: 18.8212269 },
    { lat: 48.6600074, lng: 18.8211648 },
    { lat: 48.6601666, lng: 18.8211041 },
    { lat: 48.6603037, lng: 18.8210518 },
    { lat: 48.660443, lng: 18.8209985 },
    { lat: 48.6604596, lng: 18.8209919 },
    { lat: 48.6605469, lng: 18.8208703 },
    { lat: 48.6606078, lng: 18.8207838 },
    { lat: 48.6606739, lng: 18.8206918 },
    { lat: 48.6607306, lng: 18.8206109 },
    { lat: 48.6607852, lng: 18.8205341 },
    { lat: 48.6608064, lng: 18.8205045 },
    { lat: 48.660843, lng: 18.8204281 },
    { lat: 48.6608966, lng: 18.8203129 },
    { lat: 48.6609141, lng: 18.8202766 },
    { lat: 48.6609601, lng: 18.8201666 },
    { lat: 48.6610443, lng: 18.8199651 },
    { lat: 48.6610596, lng: 18.8199311 },
    { lat: 48.661138, lng: 18.8197941 },
    { lat: 48.6612893, lng: 18.8195821 },
    { lat: 48.6613578, lng: 18.819488 },
    { lat: 48.6613889, lng: 18.8194818 },
    { lat: 48.6621518, lng: 18.8193201 },
    { lat: 48.6622836, lng: 18.8192927 },
    { lat: 48.6625344, lng: 18.8192397 },
    { lat: 48.6631402, lng: 18.8191117 },
    { lat: 48.6632718, lng: 18.8190836 },
    { lat: 48.6638697, lng: 18.8189572 },
    { lat: 48.6643659, lng: 18.8188522 },
    { lat: 48.6644619, lng: 18.8188319 },
    { lat: 48.664532, lng: 18.8188171 },
    { lat: 48.6650306, lng: 18.8187119 },
    { lat: 48.6652035, lng: 18.8186753 },
    { lat: 48.6654433, lng: 18.8186246 },
    { lat: 48.6655427, lng: 18.8186035 },
    { lat: 48.6656027, lng: 18.8185907 },
    { lat: 48.666069, lng: 18.8181243 },
    { lat: 48.6662345, lng: 18.8180536 },
    { lat: 48.6665971, lng: 18.8181521 },
    { lat: 48.6671707, lng: 18.817714 },
    { lat: 48.667222, lng: 18.8176605 },
    { lat: 48.6671121, lng: 18.8174331 },
    { lat: 48.6672696, lng: 18.8168428 },
    { lat: 48.6671602, lng: 18.8165384 },
    { lat: 48.6666074, lng: 18.8163611 },
    { lat: 48.6664453, lng: 18.8162795 },
    { lat: 48.6662734, lng: 18.8159878 },
    { lat: 48.6662312, lng: 18.8159175 },
    { lat: 48.6662004, lng: 18.8158659 },
    { lat: 48.6662935, lng: 18.8155983 },
    { lat: 48.6672463, lng: 18.8145799 },
    { lat: 48.6674458, lng: 18.8145366 },
    { lat: 48.6676634, lng: 18.8144893 },
    { lat: 48.6676846, lng: 18.8144846 },
    { lat: 48.6677209, lng: 18.8144692 },
    { lat: 48.6678909, lng: 18.8143976 },
    { lat: 48.6678665, lng: 18.8144926 },
    { lat: 48.6679716, lng: 18.8146049 },
    { lat: 48.6680015, lng: 18.8146378 },
    { lat: 48.6684914, lng: 18.8151642 },
    { lat: 48.6685045, lng: 18.8151798 },
    { lat: 48.6689115, lng: 18.8156744 },
    { lat: 48.6689223, lng: 18.8156877 },
    { lat: 48.6689957, lng: 18.815776 },
    { lat: 48.6693557, lng: 18.8162131 },
    { lat: 48.6694466, lng: 18.8161626 },
    { lat: 48.6695146, lng: 18.8161253 },
    { lat: 48.669587, lng: 18.8160853 },
    { lat: 48.6696614, lng: 18.8160436 },
    { lat: 48.669747, lng: 18.8159963 },
    { lat: 48.6698302, lng: 18.8159508 },
    { lat: 48.6699223, lng: 18.8158995 },
    { lat: 48.6700417, lng: 18.8158332 },
    { lat: 48.6701623, lng: 18.8157664 },
    { lat: 48.6702773, lng: 18.8157024 },
    { lat: 48.6703892, lng: 18.8156406 },
    { lat: 48.670547, lng: 18.8155534 },
    { lat: 48.6707125, lng: 18.8154616 },
    { lat: 48.6708924, lng: 18.8153621 },
    { lat: 48.6710632, lng: 18.8152672 },
    { lat: 48.6710733, lng: 18.8152196 },
    { lat: 48.6710814, lng: 18.8151798 },
    { lat: 48.6711409, lng: 18.8148758 },
    { lat: 48.6712144, lng: 18.8146181 },
    { lat: 48.6712383, lng: 18.8145371 },
    { lat: 48.6712516, lng: 18.8144884 },
    { lat: 48.6718161, lng: 18.8145015 },
    { lat: 48.6718617, lng: 18.8136288 },
    { lat: 48.6724574, lng: 18.8135693 },
    { lat: 48.673001, lng: 18.8138068 },
    { lat: 48.6732917, lng: 18.8138661 },
    { lat: 48.6740591, lng: 18.8142447 },
    { lat: 48.674236, lng: 18.8143913 },
    { lat: 48.6747361, lng: 18.814598 },
    { lat: 48.6749558, lng: 18.8148312 },
    { lat: 48.6757218, lng: 18.8150345 },
    { lat: 48.6763019, lng: 18.8149123 },
    { lat: 48.6775657, lng: 18.8146456 },
    { lat: 48.6778962, lng: 18.814237 },
    { lat: 48.6783776, lng: 18.8137169 },
    { lat: 48.6794459, lng: 18.8135523 },
    { lat: 48.6796482, lng: 18.8136257 },
    { lat: 48.6803729, lng: 18.8136158 },
    { lat: 48.6817562, lng: 18.8142594 },
    { lat: 48.6822396, lng: 18.8145563 },
    { lat: 48.6824023, lng: 18.8147139 },
    { lat: 48.682508, lng: 18.8146653 },
    { lat: 48.6830423, lng: 18.8144197 },
    { lat: 48.683885, lng: 18.8135208 },
    { lat: 48.6840649, lng: 18.8132324 },
    { lat: 48.6842338, lng: 18.8130942 },
    { lat: 48.6847714, lng: 18.8128107 },
    { lat: 48.6854458, lng: 18.8128964 },
    { lat: 48.6860891, lng: 18.8132506 },
    { lat: 48.6861955, lng: 18.8134335 },
    { lat: 48.6863404, lng: 18.8136825 },
    { lat: 48.6874232, lng: 18.8139244 },
    { lat: 48.6876432, lng: 18.8139689 },
    { lat: 48.6879797, lng: 18.8140192 },
    { lat: 48.6882115, lng: 18.8140747 },
    { lat: 48.6884194, lng: 18.8141057 },
    { lat: 48.6884413, lng: 18.8142027 },
    { lat: 48.6884592, lng: 18.8142837 },
    { lat: 48.6887558, lng: 18.8144158 },
    { lat: 48.6888574, lng: 18.8144611 },
    { lat: 48.6890557, lng: 18.8143929 },
    { lat: 48.689745, lng: 18.8141578 },
    { lat: 48.6904978, lng: 18.8139579 },
    { lat: 48.6910863, lng: 18.8138018 },
    { lat: 48.6917871, lng: 18.8129268 },
    { lat: 48.6928148, lng: 18.811442 },
    { lat: 48.6928943, lng: 18.8113669 },
    { lat: 48.6930759, lng: 18.8112828 },
    { lat: 48.6938527, lng: 18.8107581 },
    { lat: 48.6952406, lng: 18.8103447 },
    { lat: 48.6957586, lng: 18.8107849 },
    { lat: 48.696756, lng: 18.8111647 },
    { lat: 48.698074, lng: 18.8112202 },
    { lat: 48.6983455, lng: 18.8111943 },
    { lat: 48.698509, lng: 18.8111302 },
    { lat: 48.6988082, lng: 18.8109635 },
    { lat: 48.6988997, lng: 18.8109052 },
    { lat: 48.6990177, lng: 18.8108301 },
    { lat: 48.6993768, lng: 18.8106017 },
    { lat: 48.69979, lng: 18.8098128 },
    { lat: 48.7000211, lng: 18.8095722 },
    { lat: 48.7001565, lng: 18.80951 },
    { lat: 48.7005117, lng: 18.8093613 },
    { lat: 48.7011784, lng: 18.808565 },
    { lat: 48.7016956, lng: 18.8082346 },
    { lat: 48.7024978, lng: 18.8083275 },
    { lat: 48.7027724, lng: 18.8083833 },
    { lat: 48.7037297, lng: 18.8087462 },
    { lat: 48.7043889, lng: 18.8084142 },
    { lat: 48.7047252, lng: 18.8077268 },
    { lat: 48.7051325, lng: 18.8076672 },
    { lat: 48.7056319, lng: 18.8070789 },
    { lat: 48.705879, lng: 18.8069117 },
    { lat: 48.7070473, lng: 18.8054871 },
    { lat: 48.7074265, lng: 18.8048424 },
    { lat: 48.7077134, lng: 18.8045936 },
    { lat: 48.7082425, lng: 18.8043592 },
    { lat: 48.7085784, lng: 18.8032994 },
    { lat: 48.7087629, lng: 18.8025161 },
    { lat: 48.7087795, lng: 18.8024449 },
    { lat: 48.7088588, lng: 18.8020634 },
    { lat: 48.7088705, lng: 18.8020059 },
    { lat: 48.7091858, lng: 18.8017809 },
    { lat: 48.709207, lng: 18.801753 },
    { lat: 48.708776, lng: 18.801456 },
    { lat: 48.708587, lng: 18.801226 },
    { lat: 48.708391, lng: 18.800909 },
    { lat: 48.707919, lng: 18.800633 },
    { lat: 48.707556, lng: 18.800401 },
    { lat: 48.707152, lng: 18.800339 },
    { lat: 48.706808, lng: 18.800264 },
    { lat: 48.706623, lng: 18.800212 },
    { lat: 48.705953, lng: 18.799736 },
    { lat: 48.705542, lng: 18.799475 },
    { lat: 48.705269, lng: 18.799492 },
    { lat: 48.70501, lng: 18.799325 },
    { lat: 48.704566, lng: 18.798941 },
    { lat: 48.704329, lng: 18.798649 },
    { lat: 48.704089, lng: 18.79814 },
    { lat: 48.703998, lng: 18.798058 },
    { lat: 48.70354, lng: 18.797782 },
    { lat: 48.703251, lng: 18.797497 },
    { lat: 48.702986, lng: 18.797317 },
    { lat: 48.702253, lng: 18.796863 },
    { lat: 48.701956, lng: 18.796715 },
    { lat: 48.701808, lng: 18.796666 },
    { lat: 48.701599, lng: 18.796604 },
    { lat: 48.701287, lng: 18.796546 },
    { lat: 48.701168, lng: 18.796443 },
    { lat: 48.700687, lng: 18.796202 },
    { lat: 48.700615, lng: 18.796142 },
    { lat: 48.700476, lng: 18.795278 },
    { lat: 48.700406, lng: 18.795028 },
    { lat: 48.700331, lng: 18.794494 },
    { lat: 48.70018, lng: 18.794163 },
    { lat: 48.699921, lng: 18.793731 },
    { lat: 48.699676, lng: 18.793286 },
    { lat: 48.699224, lng: 18.792788 },
    { lat: 48.698963, lng: 18.79247 },
    { lat: 48.698412, lng: 18.791846 },
    { lat: 48.698377, lng: 18.79171 },
    { lat: 48.69839, lng: 18.7917 },
    { lat: 48.698317, lng: 18.791535 },
    { lat: 48.698285, lng: 18.790622 },
    { lat: 48.697826, lng: 18.789335 },
    { lat: 48.697734, lng: 18.788956 },
    { lat: 48.697414, lng: 18.788485 },
    { lat: 48.696989, lng: 18.78767 },
    { lat: 48.696482, lng: 18.78672 },
    { lat: 48.696423, lng: 18.786463 },
    { lat: 48.696263, lng: 18.786054 },
    { lat: 48.696245, lng: 18.785842 },
    { lat: 48.696089, lng: 18.785345 },
    { lat: 48.695996, lng: 18.785184 },
    { lat: 48.695744, lng: 18.784848 },
    { lat: 48.695493, lng: 18.784354 },
    { lat: 48.695346, lng: 18.783979 },
    { lat: 48.69532, lng: 18.783769 },
    { lat: 48.694852, lng: 18.783178 },
    { lat: 48.694651, lng: 18.782287 },
    { lat: 48.694556, lng: 18.782043 },
    { lat: 48.694247, lng: 18.78168 },
    { lat: 48.694039, lng: 18.781199 },
    { lat: 48.693834, lng: 18.780573 },
    { lat: 48.693826, lng: 18.780564 },
  ],
  DolnáVes: [
    { lat: 48.6625421, lng: 18.8901382 },
    { lat: 48.6625152, lng: 18.8900964 },
    { lat: 48.6617679, lng: 18.8889204 },
    { lat: 48.6617214, lng: 18.8888242 },
    { lat: 48.6614324, lng: 18.8882293 },
    { lat: 48.6614853, lng: 18.8890153 },
    { lat: 48.6617584, lng: 18.8906793 },
    { lat: 48.6616858, lng: 18.8912888 },
    { lat: 48.6615438, lng: 18.8918113 },
    { lat: 48.6613724, lng: 18.8935606 },
    { lat: 48.6612144, lng: 18.8944334 },
    { lat: 48.6611609, lng: 18.8960251 },
    { lat: 48.661083, lng: 18.8963977 },
    { lat: 48.6608428, lng: 18.8972077 },
    { lat: 48.6604237, lng: 18.8988116 },
    { lat: 48.6603739, lng: 18.8990047 },
    { lat: 48.659638, lng: 18.9005263 },
    { lat: 48.6593981, lng: 18.9009246 },
    { lat: 48.6592805, lng: 18.9010732 },
    { lat: 48.6593784, lng: 18.9023375 },
    { lat: 48.659646, lng: 18.9038159 },
    { lat: 48.6597318, lng: 18.9044318 },
    { lat: 48.6597636, lng: 18.907504 },
    { lat: 48.6599836, lng: 18.9079055 },
    { lat: 48.6601491, lng: 18.9082054 },
    { lat: 48.6602989, lng: 18.9084461 },
    { lat: 48.6603045, lng: 18.90849 },
    { lat: 48.6604399, lng: 18.9094789 },
    { lat: 48.6604939, lng: 18.9098681 },
    { lat: 48.6605825, lng: 18.9105205 },
    { lat: 48.6605984, lng: 18.9106331 },
    { lat: 48.6606321, lng: 18.9107176 },
    { lat: 48.6606891, lng: 18.9108629 },
    { lat: 48.6607114, lng: 18.9109188 },
    { lat: 48.660719, lng: 18.9109375 },
    { lat: 48.6607204, lng: 18.910942 },
    { lat: 48.6607358, lng: 18.9109801 },
    { lat: 48.6607678, lng: 18.9110559 },
    { lat: 48.6608068, lng: 18.911153 },
    { lat: 48.6608178, lng: 18.9112369 },
    { lat: 48.6609332, lng: 18.9117274 },
    { lat: 48.6610101, lng: 18.9120829 },
    { lat: 48.6610179, lng: 18.9121245 },
    { lat: 48.6611058, lng: 18.9124976 },
    { lat: 48.6611468, lng: 18.9126159 },
    { lat: 48.6612358, lng: 18.9130752 },
    { lat: 48.6613398, lng: 18.9131258 },
    { lat: 48.6619457, lng: 18.9151069 },
    { lat: 48.6621524, lng: 18.9152721 },
    { lat: 48.6627529, lng: 18.9163376 },
    { lat: 48.6628836, lng: 18.9168852 },
    { lat: 48.6632716, lng: 18.9194162 },
    { lat: 48.6633646, lng: 18.9201276 },
    { lat: 48.6634216, lng: 18.9203592 },
    { lat: 48.6635208, lng: 18.9205384 },
    { lat: 48.6636821, lng: 18.9206013 },
    { lat: 48.6644017, lng: 18.9207605 },
    { lat: 48.6645037, lng: 18.9213217 },
    { lat: 48.6645412, lng: 18.9215239 },
    { lat: 48.6645785, lng: 18.9217257 },
    { lat: 48.6647578, lng: 18.9226703 },
    { lat: 48.6652611, lng: 18.9251013 },
    { lat: 48.6652762, lng: 18.9257462 },
    { lat: 48.6661353, lng: 18.9272411 },
    { lat: 48.6663872, lng: 18.9278049 },
    { lat: 48.6667185, lng: 18.9281751 },
    { lat: 48.6670134, lng: 18.9283415 },
    { lat: 48.6677215, lng: 18.9288435 },
    { lat: 48.6678543, lng: 18.9289265 },
    { lat: 48.668492, lng: 18.9293375 },
    { lat: 48.6690157, lng: 18.9293909 },
    { lat: 48.6692425, lng: 18.9293727 },
    { lat: 48.6696089, lng: 18.9296792 },
    { lat: 48.6698277, lng: 18.9299476 },
    { lat: 48.670016, lng: 18.9305192 },
    { lat: 48.6702523, lng: 18.9314113 },
    { lat: 48.6707802, lng: 18.931903 },
    { lat: 48.6711237, lng: 18.9320257 },
    { lat: 48.6714032, lng: 18.932155 },
    { lat: 48.6719071, lng: 18.932352 },
    { lat: 48.6724636, lng: 18.9325208 },
    { lat: 48.6730317, lng: 18.9322958 },
    { lat: 48.6730625, lng: 18.932279 },
    { lat: 48.6730921, lng: 18.9322627 },
    { lat: 48.673589, lng: 18.9319261 },
    { lat: 48.6737007, lng: 18.9317718 },
    { lat: 48.6738359, lng: 18.931598 },
    { lat: 48.6740985, lng: 18.9312202 },
    { lat: 48.674484, lng: 18.9309976 },
    { lat: 48.6745227, lng: 18.9309588 },
    { lat: 48.6747947, lng: 18.9307894 },
    { lat: 48.6749945, lng: 18.9307461 },
    { lat: 48.6752108, lng: 18.9308532 },
    { lat: 48.6759927, lng: 18.9312394 },
    { lat: 48.6764482, lng: 18.931482 },
    { lat: 48.6765475, lng: 18.9316356 },
    { lat: 48.67657, lng: 18.9316783 },
    { lat: 48.6766206, lng: 18.9317673 },
    { lat: 48.6767137, lng: 18.9317996 },
    { lat: 48.676724, lng: 18.9316268 },
    { lat: 48.6766837, lng: 18.9312362 },
    { lat: 48.67665, lng: 18.9307956 },
    { lat: 48.6766605, lng: 18.9304511 },
    { lat: 48.6767377, lng: 18.9297985 },
    { lat: 48.6766133, lng: 18.9291544 },
    { lat: 48.6764791, lng: 18.9288682 },
    { lat: 48.6763282, lng: 18.9285191 },
    { lat: 48.6762615, lng: 18.9279414 },
    { lat: 48.6760951, lng: 18.9273803 },
    { lat: 48.6758438, lng: 18.9268117 },
    { lat: 48.6753781, lng: 18.9261518 },
    { lat: 48.6743244, lng: 18.9244186 },
    { lat: 48.6745453, lng: 18.9240671 },
    { lat: 48.6748306, lng: 18.9235807 },
    { lat: 48.6748328, lng: 18.9228112 },
    { lat: 48.6748648, lng: 18.922754 },
    { lat: 48.6753381, lng: 18.9214739 },
    { lat: 48.6752511, lng: 18.919753 },
    { lat: 48.675029, lng: 18.9180393 },
    { lat: 48.6749178, lng: 18.9171001 },
    { lat: 48.6745722, lng: 18.9170474 },
    { lat: 48.6727936, lng: 18.9167723 },
    { lat: 48.6715428, lng: 18.9164591 },
    { lat: 48.6696891, lng: 18.915118 },
    { lat: 48.6695849, lng: 18.9150664 },
    { lat: 48.6691591, lng: 18.914854 },
    { lat: 48.6691257, lng: 18.9145529 },
    { lat: 48.6697312, lng: 18.9134978 },
    { lat: 48.6702796, lng: 18.9128022 },
    { lat: 48.6703227, lng: 18.9123495 },
    { lat: 48.6705221, lng: 18.9122337 },
    { lat: 48.6705534, lng: 18.9119236 },
    { lat: 48.670498, lng: 18.9115655 },
    { lat: 48.6704232, lng: 18.9112735 },
    { lat: 48.6703615, lng: 18.9110385 },
    { lat: 48.6702908, lng: 18.9107614 },
    { lat: 48.6703053, lng: 18.9106944 },
    { lat: 48.67033, lng: 18.9105813 },
    { lat: 48.670382, lng: 18.9103833 },
    { lat: 48.6704254, lng: 18.9102216 },
    { lat: 48.6704214, lng: 18.9102085 },
    { lat: 48.6703988, lng: 18.9101398 },
    { lat: 48.6703545, lng: 18.9100059 },
    { lat: 48.670336, lng: 18.9099478 },
    { lat: 48.6703303, lng: 18.9099299 },
    { lat: 48.6703224, lng: 18.9099051 },
    { lat: 48.670317, lng: 18.9098858 },
    { lat: 48.6703033, lng: 18.9098406 },
    { lat: 48.6702827, lng: 18.9097706 },
    { lat: 48.6702097, lng: 18.9095312 },
    { lat: 48.6700863, lng: 18.9094165 },
    { lat: 48.6700546, lng: 18.9093864 },
    { lat: 48.6700087, lng: 18.9093732 },
    { lat: 48.6700016, lng: 18.9093683 },
    { lat: 48.6699489, lng: 18.9093306 },
    { lat: 48.6699291, lng: 18.9093179 },
    { lat: 48.6698689, lng: 18.9092865 },
    { lat: 48.6698587, lng: 18.9092848 },
    { lat: 48.6698406, lng: 18.9092819 },
    { lat: 48.6698094, lng: 18.909292 },
    { lat: 48.6697723, lng: 18.9092735 },
    { lat: 48.6697434, lng: 18.9092583 },
    { lat: 48.6697097, lng: 18.9092594 },
    { lat: 48.6696887, lng: 18.9092473 },
    { lat: 48.6696606, lng: 18.9092459 },
    { lat: 48.6695905, lng: 18.9092206 },
    { lat: 48.6695513, lng: 18.9092259 },
    { lat: 48.6695272, lng: 18.9092398 },
    { lat: 48.6694933, lng: 18.9092605 },
    { lat: 48.6694557, lng: 18.9092542 },
    { lat: 48.6694056, lng: 18.9092679 },
    { lat: 48.6693708, lng: 18.9093127 },
    { lat: 48.6693434, lng: 18.9093508 },
    { lat: 48.6693178, lng: 18.9093802 },
    { lat: 48.6692835, lng: 18.9093935 },
    { lat: 48.6692459, lng: 18.9093847 },
    { lat: 48.6691929, lng: 18.9094063 },
    { lat: 48.6689946, lng: 18.9094092 },
    { lat: 48.6688354, lng: 18.9094278 },
    { lat: 48.6688142, lng: 18.909411 },
    { lat: 48.6687768, lng: 18.9093819 },
    { lat: 48.668746, lng: 18.9093541 },
    { lat: 48.668597, lng: 18.9092406 },
    { lat: 48.6685734, lng: 18.909223 },
    { lat: 48.6685536, lng: 18.9089696 },
    { lat: 48.6684346, lng: 18.9073989 },
    { lat: 48.6682384, lng: 18.9071761 },
    { lat: 48.6679984, lng: 18.9071603 },
    { lat: 48.6678324, lng: 18.9070464 },
    { lat: 48.6668147, lng: 18.906527 },
    { lat: 48.6665302, lng: 18.9063003 },
    { lat: 48.6658495, lng: 18.9055846 },
    { lat: 48.665794, lng: 18.9053589 },
    { lat: 48.6657781, lng: 18.9052926 },
    { lat: 48.6656508, lng: 18.9047881 },
    { lat: 48.6653521, lng: 18.9040248 },
    { lat: 48.6653187, lng: 18.9037929 },
    { lat: 48.6655193, lng: 18.9031092 },
    { lat: 48.6663643, lng: 18.901411 },
    { lat: 48.6663941, lng: 18.9011121 },
    { lat: 48.6664533, lng: 18.9005319 },
    { lat: 48.6664396, lng: 18.90048 },
    { lat: 48.6663795, lng: 18.9002363 },
    { lat: 48.6656034, lng: 18.8998104 },
    { lat: 48.663754, lng: 18.9012975 },
    { lat: 48.6620019, lng: 18.902706 },
    { lat: 48.662005, lng: 18.9026564 },
    { lat: 48.6620514, lng: 18.9014732 },
    { lat: 48.6620608, lng: 18.9014164 },
    { lat: 48.6621468, lng: 18.9009173 },
    { lat: 48.6623121, lng: 18.9004913 },
    { lat: 48.6627685, lng: 18.9000084 },
    { lat: 48.6629594, lng: 18.8994891 },
    { lat: 48.6630468, lng: 18.8990391 },
    { lat: 48.662979, lng: 18.8985294 },
    { lat: 48.6628541, lng: 18.8979249 },
    { lat: 48.6625901, lng: 18.8970869 },
    { lat: 48.6624904, lng: 18.8966613 },
    { lat: 48.6624938, lng: 18.8962233 },
    { lat: 48.6627768, lng: 18.8946467 },
    { lat: 48.6628197, lng: 18.8938152 },
    { lat: 48.6627709, lng: 18.8933083 },
    { lat: 48.6627481, lng: 18.8932361 },
    { lat: 48.6621118, lng: 18.8930122 },
    { lat: 48.6620259, lng: 18.8926799 },
    { lat: 48.6621274, lng: 18.8919978 },
    { lat: 48.6623616, lng: 18.8912291 },
    { lat: 48.6625421, lng: 18.8901382 },
  ],
  HliníknadHronom: [
    { lat: 48.5376894, lng: 18.7594117 },
    { lat: 48.5368871, lng: 18.7600665 },
    { lat: 48.5368287, lng: 18.7601141 },
    { lat: 48.536524, lng: 18.7603626 },
    { lat: 48.5364936, lng: 18.7603873 },
    { lat: 48.5364254, lng: 18.7604427 },
    { lat: 48.5363858, lng: 18.7604806 },
    { lat: 48.5363459, lng: 18.7605177 },
    { lat: 48.536259, lng: 18.7606214 },
    { lat: 48.5342924, lng: 18.7630623 },
    { lat: 48.5341339, lng: 18.7632207 },
    { lat: 48.5341141, lng: 18.7632701 },
    { lat: 48.5335353, lng: 18.7639727 },
    { lat: 48.5334007, lng: 18.7641354 },
    { lat: 48.5331537, lng: 18.764435 },
    { lat: 48.5321418, lng: 18.765667 },
    { lat: 48.5321385, lng: 18.7656706 },
    { lat: 48.5315041, lng: 18.7664485 },
    { lat: 48.5314496, lng: 18.7665482 },
    { lat: 48.5314193, lng: 18.7665746 },
    { lat: 48.531357, lng: 18.7666529 },
    { lat: 48.5313414, lng: 18.7666796 },
    { lat: 48.531145, lng: 18.7669116 },
    { lat: 48.5310444, lng: 18.767034 },
    { lat: 48.530998, lng: 18.7671402 },
    { lat: 48.5309069, lng: 18.7672102 },
    { lat: 48.5308352, lng: 18.7672387 },
    { lat: 48.5307351, lng: 18.7672866 },
    { lat: 48.530541, lng: 18.767324 },
    { lat: 48.5304927, lng: 18.7673718 },
    { lat: 48.5304112, lng: 18.7673684 },
    { lat: 48.5303152, lng: 18.7674307 },
    { lat: 48.5302611, lng: 18.7675148 },
    { lat: 48.5301462, lng: 18.767689 },
    { lat: 48.5300037, lng: 18.7678092 },
    { lat: 48.5298799, lng: 18.7678788 },
    { lat: 48.5297473, lng: 18.7679715 },
    { lat: 48.5296441, lng: 18.768045 },
    { lat: 48.5295295, lng: 18.7682249 },
    { lat: 48.5294893, lng: 18.7683396 },
    { lat: 48.5293688, lng: 18.7687476 },
    { lat: 48.5292598, lng: 18.7688634 },
    { lat: 48.5290975, lng: 18.7691251 },
    { lat: 48.5289197, lng: 18.7692791 },
    { lat: 48.5287935, lng: 18.7693881 },
    { lat: 48.5288218, lng: 18.7696064 },
    { lat: 48.5288222, lng: 18.7696134 },
    { lat: 48.5288248, lng: 18.7698109 },
    { lat: 48.5283997, lng: 18.7703044 },
    { lat: 48.528349, lng: 18.7706915 },
    { lat: 48.528592, lng: 18.7713843 },
    { lat: 48.5287008, lng: 18.7717175 },
    { lat: 48.5286764, lng: 18.7718734 },
    { lat: 48.5285312, lng: 18.7721352 },
    { lat: 48.5282545, lng: 18.7725981 },
    { lat: 48.5282321, lng: 18.7727509 },
    { lat: 48.5280034, lng: 18.7727113 },
    { lat: 48.5275063, lng: 18.7723736 },
    { lat: 48.5273172, lng: 18.7723187 },
    { lat: 48.5267605, lng: 18.7720468 },
    { lat: 48.5265776, lng: 18.7719566 },
    { lat: 48.5260635, lng: 18.7714876 },
    { lat: 48.5257515, lng: 18.771178 },
    { lat: 48.525357, lng: 18.7706644 },
    { lat: 48.5249259, lng: 18.7705968 },
    { lat: 48.5244349, lng: 18.7705039 },
    { lat: 48.5239522, lng: 18.7703752 },
    { lat: 48.523774, lng: 18.7705857 },
    { lat: 48.5237495, lng: 18.7706146 },
    { lat: 48.523431, lng: 18.7709921 },
    { lat: 48.5229487, lng: 18.7713603 },
    { lat: 48.522514, lng: 18.7717381 },
    { lat: 48.5219908, lng: 18.7721026 },
    { lat: 48.521756, lng: 18.772269 },
    { lat: 48.5217673, lng: 18.7726956 },
    { lat: 48.5216317, lng: 18.7741035 },
    { lat: 48.5213642, lng: 18.7745478 },
    { lat: 48.521328, lng: 18.7748029 },
    { lat: 48.5213392, lng: 18.7755308 },
    { lat: 48.5213958, lng: 18.7763063 },
    { lat: 48.5214514, lng: 18.7767205 },
    { lat: 48.5216215, lng: 18.7771847 },
    { lat: 48.5217839, lng: 18.7776129 },
    { lat: 48.5217109, lng: 18.7784345 },
    { lat: 48.5217393, lng: 18.7789508 },
    { lat: 48.5218964, lng: 18.7791935 },
    { lat: 48.5222104, lng: 18.7795474 },
    { lat: 48.5224769, lng: 18.779702 },
    { lat: 48.522733, lng: 18.7801766 },
    { lat: 48.5212009, lng: 18.7825425 },
    { lat: 48.5208, lng: 18.7827238 },
    { lat: 48.5205628, lng: 18.7830185 },
    { lat: 48.5202671, lng: 18.7832729 },
    { lat: 48.5198319, lng: 18.7834891 },
    { lat: 48.5198008, lng: 18.7834548 },
    { lat: 48.519755, lng: 18.783547 },
    { lat: 48.5192308, lng: 18.7845155 },
    { lat: 48.5192041, lng: 18.7845643 },
    { lat: 48.5191886, lng: 18.7845946 },
    { lat: 48.5190792, lng: 18.7848104 },
    { lat: 48.5188791, lng: 18.7852059 },
    { lat: 48.518854, lng: 18.7852854 },
    { lat: 48.5188442, lng: 18.7853165 },
    { lat: 48.5187987, lng: 18.7854571 },
    { lat: 48.5187124, lng: 18.7858974 },
    { lat: 48.5187068, lng: 18.7859197 },
    { lat: 48.5186946, lng: 18.785968 },
    { lat: 48.5186481, lng: 18.7861519 },
    { lat: 48.5186317, lng: 18.7864193 },
    { lat: 48.5186166, lng: 18.7866893 },
    { lat: 48.5185933, lng: 18.7870193 },
    { lat: 48.5185371, lng: 18.7872814 },
    { lat: 48.5184707, lng: 18.78756 },
    { lat: 48.5184068, lng: 18.7877608 },
    { lat: 48.5183313, lng: 18.7878939 },
    { lat: 48.5182666, lng: 18.7879633 },
    { lat: 48.5182503, lng: 18.7879807 },
    { lat: 48.5182659, lng: 18.7881514 },
    { lat: 48.5182657, lng: 18.7883176 },
    { lat: 48.5181884, lng: 18.7884972 },
    { lat: 48.5181728, lng: 18.7885512 },
    { lat: 48.5181262, lng: 18.7887123 },
    { lat: 48.5181035, lng: 18.7889224 },
    { lat: 48.5181039, lng: 18.789272 },
    { lat: 48.5180573, lng: 18.7895643 },
    { lat: 48.5179734, lng: 18.7899001 },
    { lat: 48.5180109, lng: 18.7901194 },
    { lat: 48.5179406, lng: 18.790354 },
    { lat: 48.5179206, lng: 18.7904207 },
    { lat: 48.5180143, lng: 18.7905497 },
    { lat: 48.5180617, lng: 18.7906149 },
    { lat: 48.5181687, lng: 18.7907618 },
    { lat: 48.5182901, lng: 18.7909494 },
    { lat: 48.5183269, lng: 18.7910069 },
    { lat: 48.5183565, lng: 18.7910532 },
    { lat: 48.5185386, lng: 18.7913207 },
    { lat: 48.5186819, lng: 18.7915384 },
    { lat: 48.518818, lng: 18.7918086 },
    { lat: 48.5189433, lng: 18.79207 },
    { lat: 48.5190832, lng: 18.7923725 },
    { lat: 48.5192218, lng: 18.7926718 },
    { lat: 48.519333, lng: 18.7929617 },
    { lat: 48.5194342, lng: 18.7932591 },
    { lat: 48.5195128, lng: 18.7936592 },
    { lat: 48.5195715, lng: 18.7939161 },
    { lat: 48.5196351, lng: 18.7942679 },
    { lat: 48.5196916, lng: 18.7945518 },
    { lat: 48.5197823, lng: 18.7949248 },
    { lat: 48.5188457, lng: 18.797385 },
    { lat: 48.5188283, lng: 18.7977421 },
    { lat: 48.5188467, lng: 18.798049 },
    { lat: 48.5189096, lng: 18.7983019 },
    { lat: 48.5189982, lng: 18.7985496 },
    { lat: 48.5191316, lng: 18.7988792 },
    { lat: 48.519472, lng: 18.8003376 },
    { lat: 48.5195414, lng: 18.800821 },
    { lat: 48.5196126, lng: 18.8013191 },
    { lat: 48.519293, lng: 18.8022584 },
    { lat: 48.5189653, lng: 18.8029074 },
    { lat: 48.5186094, lng: 18.8031329 },
    { lat: 48.5181868, lng: 18.803547 },
    { lat: 48.5180387, lng: 18.8037147 },
    { lat: 48.5177515, lng: 18.8040636 },
    { lat: 48.5179262, lng: 18.8049225 },
    { lat: 48.5182537, lng: 18.8055979 },
    { lat: 48.5186852, lng: 18.8067745 },
    { lat: 48.5192169, lng: 18.8071482 },
    { lat: 48.519272, lng: 18.8072754 },
    { lat: 48.5193985, lng: 18.807562 },
    { lat: 48.5195032, lng: 18.8080935 },
    { lat: 48.5196479, lng: 18.808618 },
    { lat: 48.5197912, lng: 18.8090709 },
    { lat: 48.5202719, lng: 18.8099614 },
    { lat: 48.5203394, lng: 18.8100858 },
    { lat: 48.5205228, lng: 18.810425 },
    { lat: 48.5205454, lng: 18.8104675 },
    { lat: 48.520793, lng: 18.8107782 },
    { lat: 48.5209827, lng: 18.8110218 },
    { lat: 48.5217041, lng: 18.8125234 },
    { lat: 48.5223559, lng: 18.8138789 },
    { lat: 48.5224973, lng: 18.8146839 },
    { lat: 48.5226915, lng: 18.8157882 },
    { lat: 48.5234713, lng: 18.817501 },
    { lat: 48.5236344, lng: 18.818487 },
    { lat: 48.5235461, lng: 18.8192555 },
    { lat: 48.523537, lng: 18.8193384 },
    { lat: 48.5234579, lng: 18.8200269 },
    { lat: 48.5232142, lng: 18.8205232 },
    { lat: 48.5231202, lng: 18.8213554 },
    { lat: 48.5231943, lng: 18.8230527 },
    { lat: 48.523284, lng: 18.8230203 },
    { lat: 48.5236025, lng: 18.8230502 },
    { lat: 48.5239707, lng: 18.8230642 },
    { lat: 48.5246485, lng: 18.8232564 },
    { lat: 48.5250072, lng: 18.8230208 },
    { lat: 48.5253847, lng: 18.8226922 },
    { lat: 48.5259767, lng: 18.8221556 },
    { lat: 48.5274849, lng: 18.8214298 },
    { lat: 48.5276587, lng: 18.8213462 },
    { lat: 48.5277281, lng: 18.8213127 },
    { lat: 48.5281918, lng: 18.820907 },
    { lat: 48.5285902, lng: 18.8211515 },
    { lat: 48.5290351, lng: 18.8213354 },
    { lat: 48.529634, lng: 18.8223965 },
    { lat: 48.5302225, lng: 18.8232347 },
    { lat: 48.5307931, lng: 18.8235798 },
    { lat: 48.5311395, lng: 18.8244446 },
    { lat: 48.5317382, lng: 18.8248544 },
    { lat: 48.5319109, lng: 18.8252868 },
    { lat: 48.5319805, lng: 18.8253476 },
    { lat: 48.5321744, lng: 18.8252827 },
    { lat: 48.5326277, lng: 18.8258858 },
    { lat: 48.5327485, lng: 18.826276 },
    { lat: 48.532721, lng: 18.8270022 },
    { lat: 48.5327833, lng: 18.827572 },
    { lat: 48.5327056, lng: 18.8293014 },
    { lat: 48.5327377, lng: 18.8297207 },
    { lat: 48.5328146, lng: 18.8310419 },
    { lat: 48.5331626, lng: 18.8313149 },
    { lat: 48.5337125, lng: 18.8313939 },
    { lat: 48.533974, lng: 18.8314316 },
    { lat: 48.535023, lng: 18.8309617 },
    { lat: 48.5353728, lng: 18.8306876 },
    { lat: 48.535727, lng: 18.8305834 },
    { lat: 48.53607, lng: 18.8301962 },
    { lat: 48.5362843, lng: 18.8300681 },
    { lat: 48.5365309, lng: 18.8301026 },
    { lat: 48.5367362, lng: 18.8300203 },
    { lat: 48.5371089, lng: 18.8295321 },
    { lat: 48.5374294, lng: 18.8291266 },
    { lat: 48.5375769, lng: 18.8287341 },
    { lat: 48.5378015, lng: 18.8282428 },
    { lat: 48.5378614, lng: 18.8276838 },
    { lat: 48.5380014, lng: 18.8272096 },
    { lat: 48.5380377, lng: 18.8266968 },
    { lat: 48.5378516, lng: 18.8261335 },
    { lat: 48.5380201, lng: 18.8250386 },
    { lat: 48.5382861, lng: 18.8244982 },
    { lat: 48.5383027, lng: 18.8243824 },
    { lat: 48.5383579, lng: 18.824019 },
    { lat: 48.5388196, lng: 18.8227975 },
    { lat: 48.5388523, lng: 18.8225997 },
    { lat: 48.5389239, lng: 18.8221795 },
    { lat: 48.5386905, lng: 18.8220715 },
    { lat: 48.5385804, lng: 18.8220586 },
    { lat: 48.5384126, lng: 18.822039 },
    { lat: 48.5384035, lng: 18.8220422 },
    { lat: 48.5383771, lng: 18.8220518 },
    { lat: 48.538371, lng: 18.8220432 },
    { lat: 48.5382814, lng: 18.8220973 },
    { lat: 48.5382884, lng: 18.8221057 },
    { lat: 48.5382615, lng: 18.8221288 },
    { lat: 48.5382475, lng: 18.8221408 },
    { lat: 48.5380203, lng: 18.8222164 },
    { lat: 48.538012, lng: 18.8222107 },
    { lat: 48.5378208, lng: 18.8220693 },
    { lat: 48.537652, lng: 18.8219455 },
    { lat: 48.5375383, lng: 18.8217953 },
    { lat: 48.5374741, lng: 18.8217118 },
    { lat: 48.5374148, lng: 18.8216353 },
    { lat: 48.5372854, lng: 18.8214883 },
    { lat: 48.5371342, lng: 18.8213166 },
    { lat: 48.5370274, lng: 18.8210186 },
    { lat: 48.5369361, lng: 18.8207818 },
    { lat: 48.5366628, lng: 18.8203456 },
    { lat: 48.5364909, lng: 18.8201169 },
    { lat: 48.5365216, lng: 18.8198453 },
    { lat: 48.536531, lng: 18.819763 },
    { lat: 48.5365589, lng: 18.8195188 },
    { lat: 48.5365868, lng: 18.8191935 },
    { lat: 48.5365928, lng: 18.8191337 },
    { lat: 48.5366082, lng: 18.8189519 },
    { lat: 48.536665, lng: 18.8185754 },
    { lat: 48.5366567, lng: 18.818417 },
    { lat: 48.5366449, lng: 18.8182127 },
    { lat: 48.536643, lng: 18.8181767 },
    { lat: 48.5366389, lng: 18.8181618 },
    { lat: 48.536594, lng: 18.8179939 },
    { lat: 48.5365868, lng: 18.8179659 },
    { lat: 48.5365435, lng: 18.8179315 },
    { lat: 48.5364949, lng: 18.8175633 },
    { lat: 48.5364992, lng: 18.8171931 },
    { lat: 48.5367561, lng: 18.8166712 },
    { lat: 48.5369082, lng: 18.8163888 },
    { lat: 48.5369165, lng: 18.8161174 },
    { lat: 48.5368994, lng: 18.8155978 },
    { lat: 48.5368507, lng: 18.815204 },
    { lat: 48.536815, lng: 18.8150406 },
    { lat: 48.5368108, lng: 18.8150244 },
    { lat: 48.5366979, lng: 18.8145691 },
    { lat: 48.5366856, lng: 18.8142247 },
    { lat: 48.5365837, lng: 18.8139803 },
    { lat: 48.5364396, lng: 18.8137287 },
    { lat: 48.5363863, lng: 18.8134408 },
    { lat: 48.5363801, lng: 18.8134084 },
    { lat: 48.5363307, lng: 18.8131332 },
    { lat: 48.536253, lng: 18.8127674 },
    { lat: 48.5361569, lng: 18.8124176 },
    { lat: 48.5359999, lng: 18.8120683 },
    { lat: 48.5358947, lng: 18.8118677 },
    { lat: 48.5358327, lng: 18.8114374 },
    { lat: 48.5358495, lng: 18.8113076 },
    { lat: 48.5358716, lng: 18.8111474 },
    { lat: 48.5358804, lng: 18.8110816 },
    { lat: 48.5359748, lng: 18.8109426 },
    { lat: 48.5360316, lng: 18.8109088 },
    { lat: 48.5360775, lng: 18.8108998 },
    { lat: 48.5360957, lng: 18.8109059 },
    { lat: 48.536092, lng: 18.8109205 },
    { lat: 48.5361728, lng: 18.8109106 },
    { lat: 48.5361764, lng: 18.8108948 },
    { lat: 48.5361958, lng: 18.8108769 },
    { lat: 48.5363135, lng: 18.8104117 },
    { lat: 48.5364741, lng: 18.8101621 },
    { lat: 48.5365459, lng: 18.8098767 },
    { lat: 48.536572, lng: 18.8095758 },
    { lat: 48.5366649, lng: 18.8092836 },
    { lat: 48.5367192, lng: 18.8091149 },
    { lat: 48.5367081, lng: 18.8089269 },
    { lat: 48.536701, lng: 18.8089168 },
    { lat: 48.5365999, lng: 18.8087743 },
    { lat: 48.5364512, lng: 18.808565 },
    { lat: 48.5363426, lng: 18.8083864 },
    { lat: 48.5363083, lng: 18.8083307 },
    { lat: 48.5360381, lng: 18.8079092 },
    { lat: 48.53598, lng: 18.8077926 },
    { lat: 48.5358085, lng: 18.8074475 },
    { lat: 48.5356291, lng: 18.8069899 },
    { lat: 48.5355728, lng: 18.8067333 },
    { lat: 48.5355697, lng: 18.8064611 },
    { lat: 48.5356354, lng: 18.8061007 },
    { lat: 48.5357154, lng: 18.8056699 },
    { lat: 48.535786, lng: 18.8053576 },
    { lat: 48.5358085, lng: 18.8053581 },
    { lat: 48.5358114, lng: 18.8051532 },
    { lat: 48.5357887, lng: 18.8051523 },
    { lat: 48.5357668, lng: 18.8048425 },
    { lat: 48.5357832, lng: 18.804489 },
    { lat: 48.5357902, lng: 18.8042124 },
    { lat: 48.5358832, lng: 18.8038985 },
    { lat: 48.5360906, lng: 18.803618 },
    { lat: 48.5363347, lng: 18.8034139 },
    { lat: 48.536641, lng: 18.8031679 },
    { lat: 48.5366892, lng: 18.8031181 },
    { lat: 48.5368082, lng: 18.8030438 },
    { lat: 48.5370178, lng: 18.8030616 },
    { lat: 48.537036, lng: 18.8030658 },
    { lat: 48.5372138, lng: 18.8031223 },
    { lat: 48.537512, lng: 18.8031751 },
    { lat: 48.5377607, lng: 18.8031636 },
    { lat: 48.5379719, lng: 18.8030407 },
    { lat: 48.5381258, lng: 18.8029651 },
    { lat: 48.5385222, lng: 18.802782 },
    { lat: 48.5388081, lng: 18.8026644 },
    { lat: 48.5393314, lng: 18.8024285 },
    { lat: 48.5394741, lng: 18.8024418 },
    { lat: 48.5396691, lng: 18.8024606 },
    { lat: 48.5399731, lng: 18.8024753 },
    { lat: 48.540072, lng: 18.8024244 },
    { lat: 48.5403575, lng: 18.8022766 },
    { lat: 48.541589, lng: 18.8017626 },
    { lat: 48.5416473, lng: 18.801736 },
    { lat: 48.5417661, lng: 18.8017429 },
    { lat: 48.5418036, lng: 18.8017452 },
    { lat: 48.541815, lng: 18.8017474 },
    { lat: 48.5418627, lng: 18.8017556 },
    { lat: 48.5419083, lng: 18.8017637 },
    { lat: 48.5420317, lng: 18.8017288 },
    { lat: 48.5421204, lng: 18.8017029 },
    { lat: 48.542176, lng: 18.8016871 },
    { lat: 48.5422037, lng: 18.8016791 },
    { lat: 48.5422776, lng: 18.8016244 },
    { lat: 48.5422917, lng: 18.8016139 },
    { lat: 48.542322, lng: 18.8015914 },
    { lat: 48.5423634, lng: 18.8015605 },
    { lat: 48.5423653, lng: 18.8015571 },
    { lat: 48.5424095, lng: 18.8014934 },
    { lat: 48.5424482, lng: 18.8014376 },
    { lat: 48.5424932, lng: 18.8013732 },
    { lat: 48.5425006, lng: 18.8013602 },
    { lat: 48.5425521, lng: 18.8012694 },
    { lat: 48.54261, lng: 18.8011671 },
    { lat: 48.5426344, lng: 18.8011183 },
    { lat: 48.5426621, lng: 18.8010655 },
    { lat: 48.5428175, lng: 18.800762 },
    { lat: 48.5428514, lng: 18.800696 },
    { lat: 48.5428983, lng: 18.8006033 },
    { lat: 48.5429433, lng: 18.800515 },
    { lat: 48.5431216, lng: 18.801397 },
    { lat: 48.5433446, lng: 18.8023819 },
    { lat: 48.5433563, lng: 18.8024346 },
    { lat: 48.5433654, lng: 18.8024762 },
    { lat: 48.5435846, lng: 18.8034313 },
    { lat: 48.5435873, lng: 18.8034605 },
    { lat: 48.5435822, lng: 18.8034711 },
    { lat: 48.5436207, lng: 18.8035199 },
    { lat: 48.543708, lng: 18.803548 },
    { lat: 48.5437405, lng: 18.8035235 },
    { lat: 48.5437365, lng: 18.8035111 },
    { lat: 48.5437996, lng: 18.8035335 },
    { lat: 48.5440739, lng: 18.8035817 },
    { lat: 48.5443723, lng: 18.8036576 },
    { lat: 48.5445477, lng: 18.8037122 },
    { lat: 48.5447074, lng: 18.8037705 },
    { lat: 48.5451077, lng: 18.8038972 },
    { lat: 48.5453594, lng: 18.8039246 },
    { lat: 48.5455292, lng: 18.8040208 },
    { lat: 48.5457061, lng: 18.8040599 },
    { lat: 48.5461014, lng: 18.8039862 },
    { lat: 48.5463345, lng: 18.8039347 },
    { lat: 48.5464625, lng: 18.8039063 },
    { lat: 48.5466454, lng: 18.8038664 },
    { lat: 48.5473592, lng: 18.8036295 },
    { lat: 48.5478106, lng: 18.8036755 },
    { lat: 48.5482133, lng: 18.8034006 },
    { lat: 48.5486454, lng: 18.8036599 },
    { lat: 48.5488684, lng: 18.8039331 },
    { lat: 48.5492322, lng: 18.8034132 },
    { lat: 48.5495208, lng: 18.8023104 },
    { lat: 48.5495592, lng: 18.8012399 },
    { lat: 48.5496119, lng: 18.8009332 },
    { lat: 48.549849, lng: 18.8003579 },
    { lat: 48.5505396, lng: 18.7995472 },
    { lat: 48.551011, lng: 18.798277 },
    { lat: 48.5511292, lng: 18.7975874 },
    { lat: 48.5510557, lng: 18.7972477 },
    { lat: 48.5509566, lng: 18.7967892 },
    { lat: 48.5506895, lng: 18.7956756 },
    { lat: 48.5498274, lng: 18.7949354 },
    { lat: 48.5481462, lng: 18.7937999 },
    { lat: 48.547813, lng: 18.7931647 },
    { lat: 48.5477505, lng: 18.7911837 },
    { lat: 48.5480831, lng: 18.7898481 },
    { lat: 48.5485692, lng: 18.7875732 },
    { lat: 48.5492233, lng: 18.7852567 },
    { lat: 48.5493244, lng: 18.784478 },
    { lat: 48.5493806, lng: 18.7835295 },
    { lat: 48.549234, lng: 18.7822888 },
    { lat: 48.5488427, lng: 18.7816692 },
    { lat: 48.5492416, lng: 18.7812952 },
    { lat: 48.5492672, lng: 18.7812704 },
    { lat: 48.5493435, lng: 18.7811973 },
    { lat: 48.5493902, lng: 18.7811611 },
    { lat: 48.551555, lng: 18.7793981 },
    { lat: 48.5515883, lng: 18.7793664 },
    { lat: 48.5547932, lng: 18.7767717 },
    { lat: 48.5547766, lng: 18.7767345 },
    { lat: 48.5547457, lng: 18.7766816 },
    { lat: 48.5536549, lng: 18.7754788 },
    { lat: 48.5526521, lng: 18.7746144 },
    { lat: 48.5520826, lng: 18.7741439 },
    { lat: 48.5510862, lng: 18.7733215 },
    { lat: 48.5507035, lng: 18.7730767 },
    { lat: 48.550497, lng: 18.7729651 },
    { lat: 48.550118, lng: 18.7728363 },
    { lat: 48.5474345, lng: 18.7715821 },
    { lat: 48.5474292, lng: 18.7715895 },
    { lat: 48.5465632, lng: 18.7728558 },
    { lat: 48.5456261, lng: 18.7742263 },
    { lat: 48.545603, lng: 18.77426 },
    { lat: 48.5443403, lng: 18.7761064 },
    { lat: 48.5443014, lng: 18.7761626 },
    { lat: 48.5442481, lng: 18.7762412 },
    { lat: 48.5442549, lng: 18.7757066 },
    { lat: 48.5441269, lng: 18.7746431 },
    { lat: 48.5437707, lng: 18.773234 },
    { lat: 48.5430665, lng: 18.7720732 },
    { lat: 48.542194, lng: 18.7704479 },
    { lat: 48.5421526, lng: 18.7703275 },
    { lat: 48.5420953, lng: 18.7701622 },
    { lat: 48.5420354, lng: 18.7699867 },
    { lat: 48.5419595, lng: 18.7696392 },
    { lat: 48.5417561, lng: 18.7691835 },
    { lat: 48.5418078, lng: 18.7682374 },
    { lat: 48.5413979, lng: 18.7677775 },
    { lat: 48.5413476, lng: 18.7677244 },
    { lat: 48.541242, lng: 18.7675993 },
    { lat: 48.5408442, lng: 18.7672223 },
    { lat: 48.5407358, lng: 18.7670481 },
    { lat: 48.5406576, lng: 18.7669368 },
    { lat: 48.5405566, lng: 18.7667955 },
    { lat: 48.5405116, lng: 18.7667308 },
    { lat: 48.5404958, lng: 18.7667099 },
    { lat: 48.540465, lng: 18.7666406 },
    { lat: 48.5404446, lng: 18.766594 },
    { lat: 48.5403515, lng: 18.7663884 },
    { lat: 48.5403044, lng: 18.7660114 },
    { lat: 48.5403388, lng: 18.7659771 },
    { lat: 48.5408569, lng: 18.765456 },
    { lat: 48.541096, lng: 18.765216 },
    { lat: 48.5396374, lng: 18.7620902 },
    { lat: 48.5384139, lng: 18.7606605 },
    { lat: 48.5376894, lng: 18.7594117 },
  ],
  KremnickéBane: [
    { lat: 48.750219, lng: 18.907995 },
    { lat: 48.750497, lng: 18.907147 },
    { lat: 48.750415, lng: 18.906112 },
    { lat: 48.750112, lng: 18.905662 },
    { lat: 48.750199, lng: 18.905417 },
    { lat: 48.750555, lng: 18.905375 },
    { lat: 48.750623, lng: 18.904534 },
    { lat: 48.750697, lng: 18.904003 },
    { lat: 48.750788, lng: 18.903373 },
    { lat: 48.750807, lng: 18.90271 },
    { lat: 48.750789, lng: 18.902013 },
    { lat: 48.750807, lng: 18.901285 },
    { lat: 48.750822, lng: 18.901265 },
    { lat: 48.750858, lng: 18.901251 },
    { lat: 48.75096, lng: 18.901099 },
    { lat: 48.750987, lng: 18.900934 },
    { lat: 48.750991, lng: 18.900697 },
    { lat: 48.751059, lng: 18.900209 },
    { lat: 48.751091, lng: 18.900099 },
    { lat: 48.751179, lng: 18.899905 },
    { lat: 48.751331, lng: 18.899627 },
    { lat: 48.75161, lng: 18.899286 },
    { lat: 48.751749, lng: 18.899064 },
    { lat: 48.751804, lng: 18.898936 },
    { lat: 48.751837, lng: 18.898795 },
    { lat: 48.751865, lng: 18.898614 },
    { lat: 48.751872, lng: 18.89842 },
    { lat: 48.751871, lng: 18.898257 },
    { lat: 48.751811, lng: 18.897925 },
    { lat: 48.751552, lng: 18.897269 },
    { lat: 48.751457, lng: 18.896995 },
    { lat: 48.751379, lng: 18.896747 },
    { lat: 48.751271, lng: 18.896433 },
    { lat: 48.751198, lng: 18.896246 },
    { lat: 48.751144, lng: 18.896083 },
    { lat: 48.751085, lng: 18.89587 },
    { lat: 48.75104, lng: 18.895668 },
    { lat: 48.750993, lng: 18.89552 },
    { lat: 48.750895, lng: 18.895295 },
    { lat: 48.750522, lng: 18.894909 },
    { lat: 48.750253, lng: 18.894728 },
    { lat: 48.750303, lng: 18.89435 },
    { lat: 48.750448, lng: 18.893507 },
    { lat: 48.750473, lng: 18.892936 },
    { lat: 48.750741, lng: 18.892523 },
    { lat: 48.751094, lng: 18.891856 },
    { lat: 48.751139, lng: 18.891837 },
    { lat: 48.751386, lng: 18.891814 },
    { lat: 48.751821, lng: 18.891833 },
    { lat: 48.751972, lng: 18.891866 },
    { lat: 48.752176, lng: 18.891973 },
    { lat: 48.752537, lng: 18.892194 },
    { lat: 48.752715, lng: 18.892319 },
    { lat: 48.752847, lng: 18.892363 },
    { lat: 48.753151, lng: 18.892474 },
    { lat: 48.753379, lng: 18.892656 },
    { lat: 48.753502, lng: 18.892725 },
    { lat: 48.75394, lng: 18.892845 },
    { lat: 48.754147, lng: 18.892943 },
    { lat: 48.754392, lng: 18.892997 },
    { lat: 48.75458, lng: 18.893055 },
    { lat: 48.754897, lng: 18.893145 },
    { lat: 48.755174, lng: 18.893161 },
    { lat: 48.75535, lng: 18.893185 },
    { lat: 48.755452, lng: 18.893151 },
    { lat: 48.75558, lng: 18.893047 },
    { lat: 48.755691, lng: 18.892942 },
    { lat: 48.755849, lng: 18.892837 },
    { lat: 48.756211, lng: 18.892688 },
    { lat: 48.756528, lng: 18.892525 },
    { lat: 48.75666, lng: 18.892337 },
    { lat: 48.756734, lng: 18.892285 },
    { lat: 48.757062, lng: 18.892155 },
    { lat: 48.757176, lng: 18.89208 },
    { lat: 48.757331, lng: 18.891938 },
    { lat: 48.757512, lng: 18.891851 },
    { lat: 48.757683, lng: 18.891181 },
    { lat: 48.758105, lng: 18.891114 },
    { lat: 48.758389, lng: 18.890569 },
    { lat: 48.758718, lng: 18.889787 },
    { lat: 48.75915, lng: 18.890163 },
    { lat: 48.759695, lng: 18.890126 },
    { lat: 48.760139, lng: 18.890204 },
    { lat: 48.760181, lng: 18.890125 },
    { lat: 48.760487, lng: 18.889608 },
    { lat: 48.760254, lng: 18.889542 },
    { lat: 48.76006, lng: 18.889472 },
    { lat: 48.759986, lng: 18.888162 },
    { lat: 48.759922, lng: 18.887572 },
    { lat: 48.760218, lng: 18.886374 },
    { lat: 48.760486, lng: 18.885298 },
    { lat: 48.76089, lng: 18.884053 },
    { lat: 48.7606816, lng: 18.8845561 },
    { lat: 48.7600428, lng: 18.8859029 },
    { lat: 48.7595468, lng: 18.8871321 },
    { lat: 48.7593567, lng: 18.8873399 },
    { lat: 48.7589141, lng: 18.8876794 },
    { lat: 48.7584941, lng: 18.8873371 },
    { lat: 48.7581492, lng: 18.88699 },
    { lat: 48.7577178, lng: 18.8874171 },
    { lat: 48.7574006, lng: 18.8875395 },
    { lat: 48.757104, lng: 18.8877355 },
    { lat: 48.7566107, lng: 18.8881395 },
    { lat: 48.7560823, lng: 18.8875367 },
    { lat: 48.7553589, lng: 18.887456 },
    { lat: 48.7545216, lng: 18.8872051 },
    { lat: 48.7542749, lng: 18.8869876 },
    { lat: 48.7541772, lng: 18.8869776 },
    { lat: 48.7539757, lng: 18.8868077 },
    { lat: 48.7537973, lng: 18.8867336 },
    { lat: 48.7527174, lng: 18.8862175 },
    { lat: 48.7524944, lng: 18.8858286 },
    { lat: 48.7522767, lng: 18.8856291 },
    { lat: 48.7522583, lng: 18.8855119 },
    { lat: 48.7521256, lng: 18.8855404 },
    { lat: 48.7517926, lng: 18.88503 },
    { lat: 48.750658, lng: 18.8848042 },
    { lat: 48.7493372, lng: 18.8845988 },
    { lat: 48.748913, lng: 18.8843311 },
    { lat: 48.748649, lng: 18.8842843 },
    { lat: 48.7476088, lng: 18.884121 },
    { lat: 48.7474736, lng: 18.8840551 },
    { lat: 48.7473003, lng: 18.8837378 },
    { lat: 48.7471412, lng: 18.8835575 },
    { lat: 48.7468882, lng: 18.8832 },
    { lat: 48.7461706, lng: 18.8828608 },
    { lat: 48.745912, lng: 18.8827662 },
    { lat: 48.7457252, lng: 18.8826867 },
    { lat: 48.7455337, lng: 18.8826017 },
    { lat: 48.7451402, lng: 18.8824291 },
    { lat: 48.7447964, lng: 18.8822552 },
    { lat: 48.7439395, lng: 18.8817745 },
    { lat: 48.7429248, lng: 18.8810991 },
    { lat: 48.7423585, lng: 18.8806332 },
    { lat: 48.7421655, lng: 18.8805644 },
    { lat: 48.7421006, lng: 18.880576 },
    { lat: 48.7420426, lng: 18.8805878 },
    { lat: 48.7418151, lng: 18.8814449 },
    { lat: 48.7416513, lng: 18.8817468 },
    { lat: 48.7415278, lng: 18.8821697 },
    { lat: 48.7414582, lng: 18.882727 },
    { lat: 48.741344, lng: 18.8831791 },
    { lat: 48.7413243, lng: 18.8832793 },
    { lat: 48.7411168, lng: 18.8843741 },
    { lat: 48.7410487, lng: 18.8846475 },
    { lat: 48.7406831, lng: 18.8861106 },
    { lat: 48.740535, lng: 18.8861888 },
    { lat: 48.7399543, lng: 18.8876547 },
    { lat: 48.7399376, lng: 18.8876834 },
    { lat: 48.7397928, lng: 18.8879373 },
    { lat: 48.7396004, lng: 18.8879453 },
    { lat: 48.7391308, lng: 18.8872132 },
    { lat: 48.7389422, lng: 18.887011 },
    { lat: 48.7388915, lng: 18.8870077 },
    { lat: 48.7388731, lng: 18.8870028 },
    { lat: 48.7388103, lng: 18.8869869 },
    { lat: 48.738784, lng: 18.88698 },
    { lat: 48.7383829, lng: 18.8868997 },
    { lat: 48.7381637, lng: 18.8868458 },
    { lat: 48.7380313, lng: 18.8867942 },
    { lat: 48.7378731, lng: 18.8867218 },
    { lat: 48.7374956, lng: 18.8866604 },
    { lat: 48.7373621, lng: 18.8866972 },
    { lat: 48.7368447, lng: 18.8865467 },
    { lat: 48.73666, lng: 18.8865705 },
    { lat: 48.7361403, lng: 18.8862238 },
    { lat: 48.7358367, lng: 18.8858887 },
    { lat: 48.7355858, lng: 18.8866434 },
    { lat: 48.7353497, lng: 18.886853 },
    { lat: 48.7352295, lng: 18.8869957 },
    { lat: 48.735145, lng: 18.8869524 },
    { lat: 48.7347862, lng: 18.8871429 },
    { lat: 48.734652, lng: 18.8872294 },
    { lat: 48.7339075, lng: 18.8871795 },
    { lat: 48.7337365, lng: 18.8871678 },
    { lat: 48.7332225, lng: 18.8866661 },
    { lat: 48.7328316, lng: 18.8867424 },
    { lat: 48.7324639, lng: 18.887305 },
    { lat: 48.7323483, lng: 18.8873404 },
    { lat: 48.7320507, lng: 18.8871647 },
    { lat: 48.7319092, lng: 18.887266 },
    { lat: 48.7316652, lng: 18.8873458 },
    { lat: 48.731655, lng: 18.8873434 },
    { lat: 48.7315856, lng: 18.8873312 },
    { lat: 48.7311963, lng: 18.8872602 },
    { lat: 48.7307244, lng: 18.8876861 },
    { lat: 48.7306741, lng: 18.8876496 },
    { lat: 48.7306378, lng: 18.8876231 },
    { lat: 48.7297509, lng: 18.8869788 },
    { lat: 48.7296241, lng: 18.8865312 },
    { lat: 48.7294518, lng: 18.8861622 },
    { lat: 48.7294493, lng: 18.8861564 },
    { lat: 48.7292232, lng: 18.8864716 },
    { lat: 48.7289471, lng: 18.8867587 },
    { lat: 48.728667, lng: 18.8869267 },
    { lat: 48.7281888, lng: 18.8860511 },
    { lat: 48.7280587, lng: 18.8854511 },
    { lat: 48.7277522, lng: 18.8844239 },
    { lat: 48.7274909, lng: 18.88452 },
    { lat: 48.7271444, lng: 18.8846197 },
    { lat: 48.726691, lng: 18.8846424 },
    { lat: 48.7265566, lng: 18.8852718 },
    { lat: 48.726357, lng: 18.8857088 },
    { lat: 48.7260245, lng: 18.886588 },
    { lat: 48.7253704, lng: 18.8872838 },
    { lat: 48.7252551, lng: 18.8872795 },
    { lat: 48.7248181, lng: 18.8878606 },
    { lat: 48.7250254, lng: 18.8886015 },
    { lat: 48.7247161, lng: 18.8889229 },
    { lat: 48.7246776, lng: 18.8889628 },
    { lat: 48.7246521, lng: 18.8889919 },
    { lat: 48.7241179, lng: 18.8895886 },
    { lat: 48.7239958, lng: 18.8897372 },
    { lat: 48.7237559, lng: 18.8901343 },
    { lat: 48.7236422, lng: 18.8902938 },
    { lat: 48.7235376, lng: 18.8904061 },
    { lat: 48.7232992, lng: 18.8908369 },
    { lat: 48.7233938, lng: 18.8909858 },
    { lat: 48.7233622, lng: 18.8910524 },
    { lat: 48.7232066, lng: 18.8913806 },
    { lat: 48.7230924, lng: 18.8915301 },
    { lat: 48.7232441, lng: 18.8917134 },
    { lat: 48.7233234, lng: 18.8918083 },
    { lat: 48.7234276, lng: 18.8919275 },
    { lat: 48.7235666, lng: 18.8920661 },
    { lat: 48.7236894, lng: 18.8922174 },
    { lat: 48.7238642, lng: 18.8924129 },
    { lat: 48.7240901, lng: 18.8926664 },
    { lat: 48.7240103, lng: 18.8929104 },
    { lat: 48.723911, lng: 18.8931485 },
    { lat: 48.7238532, lng: 18.8933434 },
    { lat: 48.7237876, lng: 18.8935333 },
    { lat: 48.7237053, lng: 18.8937515 },
    { lat: 48.7236416, lng: 18.8938961 },
    { lat: 48.7235985, lng: 18.8940001 },
    { lat: 48.7234409, lng: 18.8943122 },
    { lat: 48.7232496, lng: 18.8945404 },
    { lat: 48.7226595, lng: 18.8950154 },
    { lat: 48.7225288, lng: 18.8951065 },
    { lat: 48.7221141, lng: 18.8953959 },
    { lat: 48.7221909, lng: 18.8957679 },
    { lat: 48.7222032, lng: 18.8959186 },
    { lat: 48.7219793, lng: 18.8959941 },
    { lat: 48.7219063, lng: 18.8961099 },
    { lat: 48.7218561, lng: 18.8961668 },
    { lat: 48.7219978, lng: 18.8965973 },
    { lat: 48.722141, lng: 18.8968183 },
    { lat: 48.7223962, lng: 18.8971722 },
    { lat: 48.7229876, lng: 18.8975359 },
    { lat: 48.7229779, lng: 18.8978535 },
    { lat: 48.7231224, lng: 18.8981179 },
    { lat: 48.7233634, lng: 18.8983978 },
    { lat: 48.7234546, lng: 18.8987874 },
    { lat: 48.7234364, lng: 18.8992481 },
    { lat: 48.7235126, lng: 18.8998977 },
    { lat: 48.723299, lng: 18.899931 },
    { lat: 48.7232192, lng: 18.900245 },
    { lat: 48.7231782, lng: 18.9003666 },
    { lat: 48.7231264, lng: 18.9004987 },
    { lat: 48.7226731, lng: 18.9010479 },
    { lat: 48.7224454, lng: 18.9011375 },
    { lat: 48.7222979, lng: 18.9013841 },
    { lat: 48.7221604, lng: 18.9013606 },
    { lat: 48.7219967, lng: 18.9015316 },
    { lat: 48.7218922, lng: 18.9017145 },
    { lat: 48.7219056, lng: 18.9020633 },
    { lat: 48.7221881, lng: 18.9024058 },
    { lat: 48.7225369, lng: 18.9026784 },
    { lat: 48.7225972, lng: 18.9028171 },
    { lat: 48.7229665, lng: 18.903424 },
    { lat: 48.7231191, lng: 18.9039343 },
    { lat: 48.7233, lng: 18.9045173 },
    { lat: 48.7232344, lng: 18.904905 },
    { lat: 48.7232325, lng: 18.9049799 },
    { lat: 48.7232319, lng: 18.9050131 },
    { lat: 48.7232253, lng: 18.9054321 },
    { lat: 48.7231654, lng: 18.9056059 },
    { lat: 48.7231402, lng: 18.9056431 },
    { lat: 48.7228794, lng: 18.9056805 },
    { lat: 48.7228124, lng: 18.9056926 },
    { lat: 48.7226921, lng: 18.9058114 },
    { lat: 48.7225021, lng: 18.9061051 },
    { lat: 48.722427, lng: 18.9062664 },
    { lat: 48.7221849, lng: 18.9064708 },
    { lat: 48.7220361, lng: 18.9066711 },
    { lat: 48.7219823, lng: 18.9067399 },
    { lat: 48.7215702, lng: 18.9071032 },
    { lat: 48.721163, lng: 18.9072588 },
    { lat: 48.7211639, lng: 18.9074686 },
    { lat: 48.7211594, lng: 18.9075374 },
    { lat: 48.7211465, lng: 18.9077242 },
    { lat: 48.7212894, lng: 18.9078299 },
    { lat: 48.7213468, lng: 18.9078939 },
    { lat: 48.7213695, lng: 18.9079859 },
    { lat: 48.7213752, lng: 18.9080095 },
    { lat: 48.7213915, lng: 18.9080408 },
    { lat: 48.7214302, lng: 18.9081141 },
    { lat: 48.7215254, lng: 18.9082986 },
    { lat: 48.7220287, lng: 18.9085943 },
    { lat: 48.7221228, lng: 18.9086675 },
    { lat: 48.7224529, lng: 18.9093637 },
    { lat: 48.7225463, lng: 18.9095588 },
    { lat: 48.7225678, lng: 18.9096042 },
    { lat: 48.7225981, lng: 18.9096895 },
    { lat: 48.7226916, lng: 18.9100603 },
    { lat: 48.7228018, lng: 18.9104953 },
    { lat: 48.7228346, lng: 18.910675 },
    { lat: 48.7228597, lng: 18.9108131 },
    { lat: 48.7228689, lng: 18.9108588 },
    { lat: 48.7228971, lng: 18.911013 },
    { lat: 48.7229006, lng: 18.9110347 },
    { lat: 48.7229392, lng: 18.9113951 },
    { lat: 48.7235088, lng: 18.9117734 },
    { lat: 48.7239211, lng: 18.9120079 },
    { lat: 48.7240926, lng: 18.9121628 },
    { lat: 48.7244524, lng: 18.9120339 },
    { lat: 48.7245686, lng: 18.9121195 },
    { lat: 48.7246086, lng: 18.9120013 },
    { lat: 48.724688, lng: 18.9119891 },
    { lat: 48.7251333, lng: 18.9122495 },
    { lat: 48.7253262, lng: 18.9123615 },
    { lat: 48.725399, lng: 18.912308 },
    { lat: 48.7254091, lng: 18.9124811 },
    { lat: 48.7254523, lng: 18.9125767 },
    { lat: 48.7255452, lng: 18.9127848 },
    { lat: 48.7255202, lng: 18.9128237 },
    { lat: 48.7254951, lng: 18.9128635 },
    { lat: 48.7253533, lng: 18.9130894 },
    { lat: 48.7252812, lng: 18.9131982 },
    { lat: 48.7251321, lng: 18.9135667 },
    { lat: 48.7251511, lng: 18.913628 },
    { lat: 48.7251603, lng: 18.9136555 },
    { lat: 48.725171, lng: 18.9136893 },
    { lat: 48.7251873, lng: 18.9137445 },
    { lat: 48.7251899, lng: 18.9137506 },
    { lat: 48.7252049, lng: 18.9137978 },
    { lat: 48.7252288, lng: 18.9138732 },
    { lat: 48.7252667, lng: 18.9139934 },
    { lat: 48.7247034, lng: 18.9147961 },
    { lat: 48.7254596, lng: 18.9154704 },
    { lat: 48.7260552, lng: 18.9157838 },
    { lat: 48.7261864, lng: 18.9158128 },
    { lat: 48.726516, lng: 18.915886 },
    { lat: 48.7265171, lng: 18.9158858 },
    { lat: 48.7267487, lng: 18.9159369 },
    { lat: 48.7267509, lng: 18.9159374 },
    { lat: 48.7267841, lng: 18.9159446 },
    { lat: 48.727981, lng: 18.9183148 },
    { lat: 48.7271993, lng: 18.9196274 },
    { lat: 48.7272575, lng: 18.9196645 },
    { lat: 48.7273835, lng: 18.9197458 },
    { lat: 48.7275292, lng: 18.9198398 },
    { lat: 48.7278036, lng: 18.9200449 },
    { lat: 48.7279571, lng: 18.9201355 },
    { lat: 48.7282132, lng: 18.9200069 },
    { lat: 48.7283243, lng: 18.920085 },
    { lat: 48.7284298, lng: 18.9197887 },
    { lat: 48.7285739, lng: 18.919718 },
    { lat: 48.7286783, lng: 18.9197318 },
    { lat: 48.7287306, lng: 18.9197382 },
    { lat: 48.7290325, lng: 18.9197775 },
    { lat: 48.7296589, lng: 18.9198996 },
    { lat: 48.7298031, lng: 18.9200291 },
    { lat: 48.7298303, lng: 18.9200345 },
    { lat: 48.7299226, lng: 18.9200527 },
    { lat: 48.7301995, lng: 18.9201078 },
    { lat: 48.7304428, lng: 18.9200361 },
    { lat: 48.7306404, lng: 18.919978 },
    { lat: 48.7306969, lng: 18.9199606 },
    { lat: 48.7313666, lng: 18.9197583 },
    { lat: 48.7319202, lng: 18.9197368 },
    { lat: 48.7324534, lng: 18.9199341 },
    { lat: 48.7325026, lng: 18.9200551 },
    { lat: 48.7329448, lng: 18.9202134 },
    { lat: 48.7331341, lng: 18.9222179 },
    { lat: 48.7331313, lng: 18.9236173 },
    { lat: 48.7334849, lng: 18.923607 },
    { lat: 48.7335912, lng: 18.9236153 },
    { lat: 48.7337575, lng: 18.9235594 },
    { lat: 48.7340731, lng: 18.9233785 },
    { lat: 48.7343177, lng: 18.9234896 },
    { lat: 48.7344321, lng: 18.9235419 },
    { lat: 48.7344492, lng: 18.9236578 },
    { lat: 48.7347296, lng: 18.9236072 },
    { lat: 48.7348785, lng: 18.923519 },
    { lat: 48.7350549, lng: 18.9234173 },
    { lat: 48.7353399, lng: 18.9232154 },
    { lat: 48.7354278, lng: 18.9231262 },
    { lat: 48.735709, lng: 18.9225825 },
    { lat: 48.7358539, lng: 18.9223066 },
    { lat: 48.7360283, lng: 18.9221919 },
    { lat: 48.7361366, lng: 18.9221706 },
    { lat: 48.7366328, lng: 18.9221722 },
    { lat: 48.7372187, lng: 18.9218562 },
    { lat: 48.7374588, lng: 18.9217641 },
    { lat: 48.7376798, lng: 18.9217669 },
    { lat: 48.737821, lng: 18.9217053 },
    { lat: 48.73794, lng: 18.9216532 },
    { lat: 48.7381651, lng: 18.9216411 },
    { lat: 48.7384272, lng: 18.9216287 },
    { lat: 48.7386772, lng: 18.9215377 },
    { lat: 48.7391958, lng: 18.921363 },
    { lat: 48.7395656, lng: 18.9211826 },
    { lat: 48.7396422, lng: 18.9212022 },
    { lat: 48.7399414, lng: 18.9213004 },
    { lat: 48.74002, lng: 18.921337 },
    { lat: 48.7400945, lng: 18.9215165 },
    { lat: 48.7401407, lng: 18.9216679 },
    { lat: 48.7401811, lng: 18.921844 },
    { lat: 48.7403953, lng: 18.9222794 },
    { lat: 48.7404619, lng: 18.9224598 },
    { lat: 48.7405242, lng: 18.9225547 },
    { lat: 48.7405818, lng: 18.9226005 },
    { lat: 48.7406821, lng: 18.9227077 },
    { lat: 48.7408761, lng: 18.9231055 },
    { lat: 48.7409151, lng: 18.9231838 },
    { lat: 48.7410178, lng: 18.923165 },
    { lat: 48.7410192, lng: 18.9231691 },
    { lat: 48.7410259, lng: 18.9231891 },
    { lat: 48.7410388, lng: 18.9232251 },
    { lat: 48.741154, lng: 18.923515 },
    { lat: 48.741156, lng: 18.923513 },
    { lat: 48.741438, lng: 18.9233 },
    { lat: 48.741438, lng: 18.923278 },
    { lat: 48.741525, lng: 18.923239 },
    { lat: 48.741624, lng: 18.92316 },
    { lat: 48.741819, lng: 18.922955 },
    { lat: 48.742004, lng: 18.922788 },
    { lat: 48.742143, lng: 18.922688 },
    { lat: 48.742262, lng: 18.922577 },
    { lat: 48.742331, lng: 18.922506 },
    { lat: 48.742496, lng: 18.922388 },
    { lat: 48.742633, lng: 18.922222 },
    { lat: 48.74267, lng: 18.922045 },
    { lat: 48.742662, lng: 18.921716 },
    { lat: 48.742677, lng: 18.921614 },
    { lat: 48.742771, lng: 18.92123 },
    { lat: 48.7428, lng: 18.921039 },
    { lat: 48.742811, lng: 18.920883 },
    { lat: 48.742819, lng: 18.920486 },
    { lat: 48.742833, lng: 18.920147 },
    { lat: 48.742846, lng: 18.919946 },
    { lat: 48.742871, lng: 18.919792 },
    { lat: 48.742911, lng: 18.919607 },
    { lat: 48.742935, lng: 18.919483 },
    { lat: 48.742973, lng: 18.919196 },
    { lat: 48.743006, lng: 18.918942 },
    { lat: 48.743043, lng: 18.918725 },
    { lat: 48.743086, lng: 18.918539 },
    { lat: 48.743131, lng: 18.918287 },
    { lat: 48.74315, lng: 18.918178 },
    { lat: 48.743168, lng: 18.918048 },
    { lat: 48.743179, lng: 18.91794 },
    { lat: 48.74318, lng: 18.91788 },
    { lat: 48.743181, lng: 18.917821 },
    { lat: 48.743181, lng: 18.917719 },
    { lat: 48.743202, lng: 18.917437 },
    { lat: 48.743271, lng: 18.91692 },
    { lat: 48.743284, lng: 18.916695 },
    { lat: 48.743303, lng: 18.91636 },
    { lat: 48.743272, lng: 18.916026 },
    { lat: 48.743309, lng: 18.915381 },
    { lat: 48.743497, lng: 18.915022 },
    { lat: 48.743513, lng: 18.91496 },
    { lat: 48.74334, lng: 18.91461 },
    { lat: 48.743361, lng: 18.914601 },
    { lat: 48.743653, lng: 18.914492 },
    { lat: 48.744021, lng: 18.91429 },
    { lat: 48.744264, lng: 18.913896 },
    { lat: 48.744411, lng: 18.913756 },
    { lat: 48.744631, lng: 18.913642 },
    { lat: 48.7449, lng: 18.91348 },
    { lat: 48.745123, lng: 18.913307 },
    { lat: 48.74527, lng: 18.913127 },
    { lat: 48.745582, lng: 18.912705 },
    { lat: 48.745718, lng: 18.912552 },
    { lat: 48.745918, lng: 18.912508 },
    { lat: 48.746291, lng: 18.912372 },
    { lat: 48.746543, lng: 18.912276 },
    { lat: 48.746907, lng: 18.91211 },
    { lat: 48.747143, lng: 18.911994 },
    { lat: 48.747295, lng: 18.911949 },
    { lat: 48.747609, lng: 18.911858 },
    { lat: 48.747896, lng: 18.911888 },
    { lat: 48.747964, lng: 18.911864 },
    { lat: 48.7483, lng: 18.911732 },
    { lat: 48.748567, lng: 18.911536 },
    { lat: 48.74882, lng: 18.911369 },
    { lat: 48.749059, lng: 18.911198 },
    { lat: 48.749609, lng: 18.91074 },
    { lat: 48.749753, lng: 18.910617 },
    { lat: 48.749991, lng: 18.910419 },
    { lat: 48.750324, lng: 18.910137 },
    { lat: 48.750638, lng: 18.909836 },
    { lat: 48.750637, lng: 18.909813 },
    { lat: 48.750619, lng: 18.90927 },
    { lat: 48.7503, lng: 18.90826 },
    { lat: 48.750219, lng: 18.907995 },
  ],
  LehôtkapodBrehmi: [
    { lat: 48.5488684, lng: 18.8039331 },
    { lat: 48.5486454, lng: 18.8036599 },
    { lat: 48.5482133, lng: 18.8034006 },
    { lat: 48.5478106, lng: 18.8036755 },
    { lat: 48.5473592, lng: 18.8036295 },
    { lat: 48.5466454, lng: 18.8038664 },
    { lat: 48.5464625, lng: 18.8039063 },
    { lat: 48.5463345, lng: 18.8039347 },
    { lat: 48.5461014, lng: 18.8039862 },
    { lat: 48.5457061, lng: 18.8040599 },
    { lat: 48.5455292, lng: 18.8040208 },
    { lat: 48.5453594, lng: 18.8039246 },
    { lat: 48.5451077, lng: 18.8038972 },
    { lat: 48.5447074, lng: 18.8037705 },
    { lat: 48.5445477, lng: 18.8037122 },
    { lat: 48.5443723, lng: 18.8036576 },
    { lat: 48.5440739, lng: 18.8035817 },
    { lat: 48.5437996, lng: 18.8035335 },
    { lat: 48.5437365, lng: 18.8035111 },
    { lat: 48.5437405, lng: 18.8035235 },
    { lat: 48.543708, lng: 18.803548 },
    { lat: 48.5436207, lng: 18.8035199 },
    { lat: 48.5435822, lng: 18.8034711 },
    { lat: 48.5435873, lng: 18.8034605 },
    { lat: 48.5435846, lng: 18.8034313 },
    { lat: 48.5433654, lng: 18.8024762 },
    { lat: 48.5433563, lng: 18.8024346 },
    { lat: 48.5433446, lng: 18.8023819 },
    { lat: 48.5431216, lng: 18.801397 },
    { lat: 48.5429433, lng: 18.800515 },
    { lat: 48.5428983, lng: 18.8006033 },
    { lat: 48.5428514, lng: 18.800696 },
    { lat: 48.5428175, lng: 18.800762 },
    { lat: 48.5426621, lng: 18.8010655 },
    { lat: 48.5426344, lng: 18.8011183 },
    { lat: 48.54261, lng: 18.8011671 },
    { lat: 48.5425521, lng: 18.8012694 },
    { lat: 48.5425006, lng: 18.8013602 },
    { lat: 48.5424932, lng: 18.8013732 },
    { lat: 48.5424482, lng: 18.8014376 },
    { lat: 48.5424095, lng: 18.8014934 },
    { lat: 48.5423653, lng: 18.8015571 },
    { lat: 48.5423634, lng: 18.8015605 },
    { lat: 48.542322, lng: 18.8015914 },
    { lat: 48.5422917, lng: 18.8016139 },
    { lat: 48.5422776, lng: 18.8016244 },
    { lat: 48.5422037, lng: 18.8016791 },
    { lat: 48.542176, lng: 18.8016871 },
    { lat: 48.5421204, lng: 18.8017029 },
    { lat: 48.5420317, lng: 18.8017288 },
    { lat: 48.5419083, lng: 18.8017637 },
    { lat: 48.5418627, lng: 18.8017556 },
    { lat: 48.541815, lng: 18.8017474 },
    { lat: 48.5418036, lng: 18.8017452 },
    { lat: 48.5417661, lng: 18.8017429 },
    { lat: 48.5416473, lng: 18.801736 },
    { lat: 48.541589, lng: 18.8017626 },
    { lat: 48.5403575, lng: 18.8022766 },
    { lat: 48.540072, lng: 18.8024244 },
    { lat: 48.5399731, lng: 18.8024753 },
    { lat: 48.5396691, lng: 18.8024606 },
    { lat: 48.5394741, lng: 18.8024418 },
    { lat: 48.5393314, lng: 18.8024285 },
    { lat: 48.5388081, lng: 18.8026644 },
    { lat: 48.5385222, lng: 18.802782 },
    { lat: 48.5381258, lng: 18.8029651 },
    { lat: 48.5379719, lng: 18.8030407 },
    { lat: 48.5377607, lng: 18.8031636 },
    { lat: 48.537512, lng: 18.8031751 },
    { lat: 48.5372138, lng: 18.8031223 },
    { lat: 48.537036, lng: 18.8030658 },
    { lat: 48.5370178, lng: 18.8030616 },
    { lat: 48.5368082, lng: 18.8030438 },
    { lat: 48.5366892, lng: 18.8031181 },
    { lat: 48.536641, lng: 18.8031679 },
    { lat: 48.5363347, lng: 18.8034139 },
    { lat: 48.5360906, lng: 18.803618 },
    { lat: 48.5358832, lng: 18.8038985 },
    { lat: 48.5357902, lng: 18.8042124 },
    { lat: 48.5357832, lng: 18.804489 },
    { lat: 48.5357668, lng: 18.8048425 },
    { lat: 48.5357887, lng: 18.8051523 },
    { lat: 48.5358114, lng: 18.8051532 },
    { lat: 48.5358085, lng: 18.8053581 },
    { lat: 48.535786, lng: 18.8053576 },
    { lat: 48.5357154, lng: 18.8056699 },
    { lat: 48.5356354, lng: 18.8061007 },
    { lat: 48.5355697, lng: 18.8064611 },
    { lat: 48.5355728, lng: 18.8067333 },
    { lat: 48.5356291, lng: 18.8069899 },
    { lat: 48.5358085, lng: 18.8074475 },
    { lat: 48.53598, lng: 18.8077926 },
    { lat: 48.5360381, lng: 18.8079092 },
    { lat: 48.5363083, lng: 18.8083307 },
    { lat: 48.5363426, lng: 18.8083864 },
    { lat: 48.5364512, lng: 18.808565 },
    { lat: 48.5365999, lng: 18.8087743 },
    { lat: 48.536701, lng: 18.8089168 },
    { lat: 48.5367081, lng: 18.8089269 },
    { lat: 48.5367192, lng: 18.8091149 },
    { lat: 48.5366649, lng: 18.8092836 },
    { lat: 48.536572, lng: 18.8095758 },
    { lat: 48.5365459, lng: 18.8098767 },
    { lat: 48.5364741, lng: 18.8101621 },
    { lat: 48.5363135, lng: 18.8104117 },
    { lat: 48.5361958, lng: 18.8108769 },
    { lat: 48.5361764, lng: 18.8108948 },
    { lat: 48.5361728, lng: 18.8109106 },
    { lat: 48.536092, lng: 18.8109205 },
    { lat: 48.5360957, lng: 18.8109059 },
    { lat: 48.5360775, lng: 18.8108998 },
    { lat: 48.5360316, lng: 18.8109088 },
    { lat: 48.5359748, lng: 18.8109426 },
    { lat: 48.5358804, lng: 18.8110816 },
    { lat: 48.5358716, lng: 18.8111474 },
    { lat: 48.5358495, lng: 18.8113076 },
    { lat: 48.5358327, lng: 18.8114374 },
    { lat: 48.5358947, lng: 18.8118677 },
    { lat: 48.5359999, lng: 18.8120683 },
    { lat: 48.5361569, lng: 18.8124176 },
    { lat: 48.536253, lng: 18.8127674 },
    { lat: 48.5363307, lng: 18.8131332 },
    { lat: 48.5363801, lng: 18.8134084 },
    { lat: 48.5363863, lng: 18.8134408 },
    { lat: 48.5364396, lng: 18.8137287 },
    { lat: 48.5365837, lng: 18.8139803 },
    { lat: 48.5366856, lng: 18.8142247 },
    { lat: 48.5366979, lng: 18.8145691 },
    { lat: 48.5368108, lng: 18.8150244 },
    { lat: 48.536815, lng: 18.8150406 },
    { lat: 48.5368507, lng: 18.815204 },
    { lat: 48.5368994, lng: 18.8155978 },
    { lat: 48.5369165, lng: 18.8161174 },
    { lat: 48.5369082, lng: 18.8163888 },
    { lat: 48.5367561, lng: 18.8166712 },
    { lat: 48.5364992, lng: 18.8171931 },
    { lat: 48.5364949, lng: 18.8175633 },
    { lat: 48.5365435, lng: 18.8179315 },
    { lat: 48.5365868, lng: 18.8179659 },
    { lat: 48.536594, lng: 18.8179939 },
    { lat: 48.5366389, lng: 18.8181618 },
    { lat: 48.536643, lng: 18.8181767 },
    { lat: 48.5366449, lng: 18.8182127 },
    { lat: 48.5366567, lng: 18.818417 },
    { lat: 48.536665, lng: 18.8185754 },
    { lat: 48.5366082, lng: 18.8189519 },
    { lat: 48.5365928, lng: 18.8191337 },
    { lat: 48.5365868, lng: 18.8191935 },
    { lat: 48.5365589, lng: 18.8195188 },
    { lat: 48.536531, lng: 18.819763 },
    { lat: 48.5365216, lng: 18.8198453 },
    { lat: 48.5364909, lng: 18.8201169 },
    { lat: 48.5366628, lng: 18.8203456 },
    { lat: 48.5369361, lng: 18.8207818 },
    { lat: 48.5370274, lng: 18.8210186 },
    { lat: 48.5371342, lng: 18.8213166 },
    { lat: 48.5372854, lng: 18.8214883 },
    { lat: 48.5374148, lng: 18.8216353 },
    { lat: 48.5374741, lng: 18.8217118 },
    { lat: 48.5375383, lng: 18.8217953 },
    { lat: 48.537652, lng: 18.8219455 },
    { lat: 48.5378208, lng: 18.8220693 },
    { lat: 48.538012, lng: 18.8222107 },
    { lat: 48.5380203, lng: 18.8222164 },
    { lat: 48.5382475, lng: 18.8221408 },
    { lat: 48.5382615, lng: 18.8221288 },
    { lat: 48.5382884, lng: 18.8221057 },
    { lat: 48.5382814, lng: 18.8220973 },
    { lat: 48.538371, lng: 18.8220432 },
    { lat: 48.5383771, lng: 18.8220518 },
    { lat: 48.5384035, lng: 18.8220422 },
    { lat: 48.5384126, lng: 18.822039 },
    { lat: 48.5385804, lng: 18.8220586 },
    { lat: 48.5386905, lng: 18.8220715 },
    { lat: 48.5389239, lng: 18.8221795 },
    { lat: 48.5391131, lng: 18.8223666 },
    { lat: 48.5392087, lng: 18.8227763 },
    { lat: 48.5394718, lng: 18.8232767 },
    { lat: 48.5397541, lng: 18.8238439 },
    { lat: 48.5398522, lng: 18.8242334 },
    { lat: 48.5398975, lng: 18.8242372 },
    { lat: 48.5401921, lng: 18.8245653 },
    { lat: 48.5400894, lng: 18.8246721 },
    { lat: 48.5401554, lng: 18.8248817 },
    { lat: 48.5401843, lng: 18.8250641 },
    { lat: 48.5403292, lng: 18.8252042 },
    { lat: 48.540622, lng: 18.8253037 },
    { lat: 48.5408463, lng: 18.8258436 },
    { lat: 48.5408723, lng: 18.8262558 },
    { lat: 48.5409611, lng: 18.8264677 },
    { lat: 48.5412369, lng: 18.8266945 },
    { lat: 48.5414125, lng: 18.8271836 },
    { lat: 48.5416273, lng: 18.8274094 },
    { lat: 48.5417781, lng: 18.8280075 },
    { lat: 48.5420614, lng: 18.8288355 },
    { lat: 48.5419251, lng: 18.8293129 },
    { lat: 48.5419346, lng: 18.8295804 },
    { lat: 48.5421551, lng: 18.8300077 },
    { lat: 48.5423756, lng: 18.8304282 },
    { lat: 48.5424957, lng: 18.8308695 },
    { lat: 48.5426158, lng: 18.8310553 },
    { lat: 48.5427497, lng: 18.8311579 },
    { lat: 48.542998, lng: 18.8312705 },
    { lat: 48.5430006, lng: 18.8315795 },
    { lat: 48.5428613, lng: 18.8317846 },
    { lat: 48.5427608, lng: 18.8320185 },
    { lat: 48.542792, lng: 18.832354 },
    { lat: 48.5428925, lng: 18.8326634 },
    { lat: 48.5430302, lng: 18.8328578 },
    { lat: 48.5429485, lng: 18.8331312 },
    { lat: 48.5428997, lng: 18.8332095 },
    { lat: 48.5428589, lng: 18.8333795 },
    { lat: 48.5428621, lng: 18.8335944 },
    { lat: 48.5430952, lng: 18.8338462 },
    { lat: 48.543185, lng: 18.8342893 },
    { lat: 48.5432923, lng: 18.8344488 },
    { lat: 48.5433835, lng: 18.8345758 },
    { lat: 48.5433276, lng: 18.834713 },
    { lat: 48.5432692, lng: 18.8347626 },
    { lat: 48.5432114, lng: 18.8348417 },
    { lat: 48.5432258, lng: 18.834882 },
    { lat: 48.5431794, lng: 18.8349839 },
    { lat: 48.5431714, lng: 18.8349617 },
    { lat: 48.5431647, lng: 18.8349436 },
    { lat: 48.543086, lng: 18.8351825 },
    { lat: 48.5431434, lng: 18.8354408 },
    { lat: 48.5431531, lng: 18.8354948 },
    { lat: 48.5431637, lng: 18.8355256 },
    { lat: 48.5433239, lng: 18.8359611 },
    { lat: 48.5433917, lng: 18.836483 },
    { lat: 48.5433303, lng: 18.8369724 },
    { lat: 48.5432503, lng: 18.837837 },
    { lat: 48.5429145, lng: 18.8386881 },
    { lat: 48.5428441, lng: 18.8390249 },
    { lat: 48.5426886, lng: 18.8393507 },
    { lat: 48.5426004, lng: 18.83978 },
    { lat: 48.5424706, lng: 18.8401469 },
    { lat: 48.5422745, lng: 18.8402327 },
    { lat: 48.5421818, lng: 18.8402728 },
    { lat: 48.541918, lng: 18.8405972 },
    { lat: 48.5419239, lng: 18.8408613 },
    { lat: 48.5419256, lng: 18.8410013 },
    { lat: 48.5419337, lng: 18.8414847 },
    { lat: 48.5419573, lng: 18.8418061 },
    { lat: 48.5420017, lng: 18.842009 },
    { lat: 48.5418395, lng: 18.8424029 },
    { lat: 48.5418446, lng: 18.8428503 },
    { lat: 48.5418274, lng: 18.8431455 },
    { lat: 48.5417463, lng: 18.8432345 },
    { lat: 48.5415668, lng: 18.8432724 },
    { lat: 48.5414254, lng: 18.8434574 },
    { lat: 48.5414061, lng: 18.8436718 },
    { lat: 48.5413973, lng: 18.8437671 },
    { lat: 48.5414196, lng: 18.844431 },
    { lat: 48.5414943, lng: 18.8445453 },
    { lat: 48.5416129, lng: 18.8448085 },
    { lat: 48.5416166, lng: 18.8448162 },
    { lat: 48.5416346, lng: 18.8448568 },
    { lat: 48.541678, lng: 18.8449549 },
    { lat: 48.5417004, lng: 18.8452319 },
    { lat: 48.5416135, lng: 18.8456642 },
    { lat: 48.5413813, lng: 18.8460792 },
    { lat: 48.5413089, lng: 18.8464443 },
    { lat: 48.5413177, lng: 18.8469582 },
    { lat: 48.5413227, lng: 18.8476187 },
    { lat: 48.5411963, lng: 18.8478956 },
    { lat: 48.5413151, lng: 18.8479719 },
    { lat: 48.5412698, lng: 18.8479862 },
    { lat: 48.5412437, lng: 18.8480476 },
    { lat: 48.5412516, lng: 18.8482225 },
    { lat: 48.5408649, lng: 18.8490922 },
    { lat: 48.5405474, lng: 18.8496207 },
    { lat: 48.5404317, lng: 18.8498675 },
    { lat: 48.5402812, lng: 18.850572 },
    { lat: 48.5397454, lng: 18.8514159 },
    { lat: 48.539838, lng: 18.8515519 },
    { lat: 48.5398392, lng: 18.8517478 },
    { lat: 48.5404965, lng: 18.8521553 },
    { lat: 48.5409723, lng: 18.8528931 },
    { lat: 48.5411228, lng: 18.8539878 },
    { lat: 48.5410791, lng: 18.8544025 },
    { lat: 48.5418665, lng: 18.8543446 },
    { lat: 48.5422296, lng: 18.8542573 },
    { lat: 48.5429953, lng: 18.8538439 },
    { lat: 48.5439508, lng: 18.8543529 },
    { lat: 48.544366, lng: 18.854467 },
    { lat: 48.5446047, lng: 18.8535403 },
    { lat: 48.5447414, lng: 18.8530096 },
    { lat: 48.5449296, lng: 18.8524038 },
    { lat: 48.5449839, lng: 18.852402 },
    { lat: 48.5452986, lng: 18.8517336 },
    { lat: 48.5455713, lng: 18.8481939 },
    { lat: 48.5455282, lng: 18.8475605 },
    { lat: 48.5460417, lng: 18.8452717 },
    { lat: 48.5455397, lng: 18.8424117 },
    { lat: 48.5459418, lng: 18.8413834 },
    { lat: 48.5463736, lng: 18.8406238 },
    { lat: 48.5468433, lng: 18.8403089 },
    { lat: 48.5470455, lng: 18.8399273 },
    { lat: 48.547305, lng: 18.8397137 },
    { lat: 48.5475953, lng: 18.8392676 },
    { lat: 48.5479313, lng: 18.8389859 },
    { lat: 48.5480985, lng: 18.838711 },
    { lat: 48.5487728, lng: 18.8382967 },
    { lat: 48.5490039, lng: 18.8373042 },
    { lat: 48.5489351, lng: 18.8371073 },
    { lat: 48.5489981, lng: 18.8369515 },
    { lat: 48.5489663, lng: 18.8363021 },
    { lat: 48.5497126, lng: 18.8351461 },
    { lat: 48.5498201, lng: 18.8347871 },
    { lat: 48.5502163, lng: 18.8341256 },
    { lat: 48.5503497, lng: 18.8328121 },
    { lat: 48.5501995, lng: 18.832181 },
    { lat: 48.5501273, lng: 18.8319411 },
    { lat: 48.5500864, lng: 18.8318051 },
    { lat: 48.5499511, lng: 18.831137 },
    { lat: 48.5499547, lng: 18.8307533 },
    { lat: 48.5500497, lng: 18.8302818 },
    { lat: 48.5500696, lng: 18.8302544 },
    { lat: 48.5502139, lng: 18.8300556 },
    { lat: 48.550296, lng: 18.8298579 },
    { lat: 48.5505268, lng: 18.8296583 },
    { lat: 48.5506471, lng: 18.8291501 },
    { lat: 48.5508235, lng: 18.8290128 },
    { lat: 48.55111, lng: 18.8285571 },
    { lat: 48.5511737, lng: 18.828521 },
    { lat: 48.5513728, lng: 18.8284082 },
    { lat: 48.5516127, lng: 18.8281007 },
    { lat: 48.5517862, lng: 18.8280553 },
    { lat: 48.5518164, lng: 18.8280474 },
    { lat: 48.5519111, lng: 18.8278148 },
    { lat: 48.5520462, lng: 18.8274828 },
    { lat: 48.5521731, lng: 18.8273782 },
    { lat: 48.5522193, lng: 18.8273402 },
    { lat: 48.5523387, lng: 18.8270171 },
    { lat: 48.552684, lng: 18.8266516 },
    { lat: 48.5528146, lng: 18.82611 },
    { lat: 48.5528365, lng: 18.8260769 },
    { lat: 48.552984, lng: 18.8258544 },
    { lat: 48.5530457, lng: 18.8257612 },
    { lat: 48.5530943, lng: 18.8255958 },
    { lat: 48.5532861, lng: 18.8249433 },
    { lat: 48.5533024, lng: 18.8249278 },
    { lat: 48.5533857, lng: 18.8248486 },
    { lat: 48.5534763, lng: 18.8247623 },
    { lat: 48.5536652, lng: 18.8244744 },
    { lat: 48.5538008, lng: 18.8244331 },
    { lat: 48.553724, lng: 18.8243019 },
    { lat: 48.5538993, lng: 18.8240744 },
    { lat: 48.5539606, lng: 18.8239952 },
    { lat: 48.5539869, lng: 18.8239607 },
    { lat: 48.5542554, lng: 18.8232053 },
    { lat: 48.5542977, lng: 18.8230885 },
    { lat: 48.5543508, lng: 18.8229387 },
    { lat: 48.5544049, lng: 18.822875 },
    { lat: 48.5546642, lng: 18.8225734 },
    { lat: 48.5548834, lng: 18.8223182 },
    { lat: 48.5549429, lng: 18.8222481 },
    { lat: 48.5549556, lng: 18.8222338 },
    { lat: 48.5549832, lng: 18.8222014 },
    { lat: 48.5552554, lng: 18.8218836 },
    { lat: 48.5556519, lng: 18.8214223 },
    { lat: 48.5560399, lng: 18.820969 },
    { lat: 48.5560886, lng: 18.8209124 },
    { lat: 48.5561662, lng: 18.8208224 },
    { lat: 48.5580829, lng: 18.8185884 },
    { lat: 48.5580955, lng: 18.8185723 },
    { lat: 48.5581392, lng: 18.8185219 },
    { lat: 48.5586844, lng: 18.8178856 },
    { lat: 48.5588498, lng: 18.817448 },
    { lat: 48.5592516, lng: 18.817023 },
    { lat: 48.5589784, lng: 18.8150113 },
    { lat: 48.559032, lng: 18.8134924 },
    { lat: 48.5589767, lng: 18.8110768 },
    { lat: 48.559051, lng: 18.8102335 },
    { lat: 48.558841, lng: 18.8091738 },
    { lat: 48.5586648, lng: 18.8087394 },
    { lat: 48.5582108, lng: 18.8080597 },
    { lat: 48.5581782, lng: 18.8080155 },
    { lat: 48.5578964, lng: 18.8077613 },
    { lat: 48.5576249, lng: 18.8077014 },
    { lat: 48.5571862, lng: 18.8078091 },
    { lat: 48.5566848, lng: 18.8084404 },
    { lat: 48.5565129, lng: 18.8088851 },
    { lat: 48.5563752, lng: 18.8098936 },
    { lat: 48.5563696, lng: 18.811101 },
    { lat: 48.5558831, lng: 18.8121487 },
    { lat: 48.5550252, lng: 18.8127806 },
    { lat: 48.55452, lng: 18.8128206 },
    { lat: 48.5537808, lng: 18.8127714 },
    { lat: 48.5534581, lng: 18.8126143 },
    { lat: 48.5529994, lng: 18.8122526 },
    { lat: 48.5525541, lng: 18.8116733 },
    { lat: 48.5523494, lng: 18.811188 },
    { lat: 48.5517633, lng: 18.809558 },
    { lat: 48.5516014, lng: 18.8089201 },
    { lat: 48.5513353, lng: 18.8082982 },
    { lat: 48.5510321, lng: 18.8080634 },
    { lat: 48.550588, lng: 18.8080439 },
    { lat: 48.5500767, lng: 18.8078868 },
    { lat: 48.5492831, lng: 18.8082143 },
    { lat: 48.5489148, lng: 18.808415 },
    { lat: 48.5486801, lng: 18.808477 },
    { lat: 48.5486171, lng: 18.8085251 },
    { lat: 48.5481951, lng: 18.8082176 },
    { lat: 48.5479755, lng: 18.8078788 },
    { lat: 48.5478794, lng: 18.8073571 },
    { lat: 48.5478681, lng: 18.806689 },
    { lat: 48.5480845, lng: 18.8058724 },
    { lat: 48.5483841, lng: 18.8049833 },
    { lat: 48.5487666, lng: 18.8043178 },
    { lat: 48.5488684, lng: 18.8039331 },
  ],
  Prochot: [
    { lat: 48.614634, lng: 18.668983 },
    { lat: 48.614614, lng: 18.669018 },
    { lat: 48.614583, lng: 18.669081 },
    { lat: 48.614474, lng: 18.669265 },
    { lat: 48.613912, lng: 18.670082 },
    { lat: 48.6128, lng: 18.672061 },
    { lat: 48.611928, lng: 18.673364 },
    { lat: 48.611583, lng: 18.674442 },
    { lat: 48.611249, lng: 18.675045 },
    { lat: 48.611031, lng: 18.675392 },
    { lat: 48.610157, lng: 18.676153 },
    { lat: 48.609577, lng: 18.676111 },
    { lat: 48.608886, lng: 18.67673 },
    { lat: 48.607451, lng: 18.677654 },
    { lat: 48.606323, lng: 18.677808 },
    { lat: 48.605916, lng: 18.677358 },
    { lat: 48.605582, lng: 18.6778 },
    { lat: 48.605126, lng: 18.677453 },
    { lat: 48.60421, lng: 18.677008 },
    { lat: 48.603911, lng: 18.677295 },
    { lat: 48.603593, lng: 18.677591 },
    { lat: 48.603165, lng: 18.677893 },
    { lat: 48.602996, lng: 18.678001 },
    { lat: 48.602774, lng: 18.677958 },
    { lat: 48.601944, lng: 18.677799 },
    { lat: 48.601614, lng: 18.677828 },
    { lat: 48.600809, lng: 18.677882 },
    { lat: 48.600423, lng: 18.677712 },
    { lat: 48.599834, lng: 18.678088 },
    { lat: 48.599711, lng: 18.678189 },
    { lat: 48.599342, lng: 18.678723 },
    { lat: 48.599139, lng: 18.678844 },
    { lat: 48.598447, lng: 18.679457 },
    { lat: 48.598366, lng: 18.679536 },
    { lat: 48.597778, lng: 18.679725 },
    { lat: 48.597449, lng: 18.680045 },
    { lat: 48.596957, lng: 18.680779 },
    { lat: 48.596628, lng: 18.681152 },
    { lat: 48.596329, lng: 18.681423 },
    { lat: 48.596003, lng: 18.681644 },
    { lat: 48.595074, lng: 18.682144 },
    { lat: 48.593931, lng: 18.682826 },
    { lat: 48.593615, lng: 18.682925 },
    { lat: 48.593191, lng: 18.682957 },
    { lat: 48.592917, lng: 18.683385 },
    { lat: 48.592463, lng: 18.683648 },
    { lat: 48.592037, lng: 18.683842 },
    { lat: 48.591023, lng: 18.685023 },
    { lat: 48.590987, lng: 18.685065 },
    { lat: 48.590877, lng: 18.685197 },
    { lat: 48.588958, lng: 18.68561 },
    { lat: 48.58856, lng: 18.685699 },
    { lat: 48.5890733, lng: 18.6865242 },
    { lat: 48.5890844, lng: 18.6865417 },
    { lat: 48.5890956, lng: 18.6865599 },
    { lat: 48.5891939, lng: 18.6867119 },
    { lat: 48.5893605, lng: 18.6885214 },
    { lat: 48.5891307, lng: 18.6904053 },
    { lat: 48.5891034, lng: 18.6906341 },
    { lat: 48.589049, lng: 18.6910757 },
    { lat: 48.5895989, lng: 18.6928202 },
    { lat: 48.5896767, lng: 18.6928834 },
    { lat: 48.5897205, lng: 18.6929194 },
    { lat: 48.589737, lng: 18.6929324 },
    { lat: 48.590028, lng: 18.6931696 },
    { lat: 48.5899061, lng: 18.6939675 },
    { lat: 48.5895568, lng: 18.69493 },
    { lat: 48.5891259, lng: 18.6958361 },
    { lat: 48.5886046, lng: 18.696932 },
    { lat: 48.5881075, lng: 18.6985222 },
    { lat: 48.5878595, lng: 18.6998778 },
    { lat: 48.5878826, lng: 18.7004624 },
    { lat: 48.5883637, lng: 18.7006649 },
    { lat: 48.5885875, lng: 18.701055 },
    { lat: 48.5885949, lng: 18.7013177 },
    { lat: 48.5886051, lng: 18.7016606 },
    { lat: 48.5884701, lng: 18.7020891 },
    { lat: 48.5885021, lng: 18.7027122 },
    { lat: 48.5884638, lng: 18.7035159 },
    { lat: 48.5884684, lng: 18.7038773 },
    { lat: 48.5886158, lng: 18.7047821 },
    { lat: 48.5887157, lng: 18.7055801 },
    { lat: 48.5887587, lng: 18.705965 },
    { lat: 48.5887753, lng: 18.7063623 },
    { lat: 48.5887478, lng: 18.7073282 },
    { lat: 48.5887633, lng: 18.7074913 },
    { lat: 48.5888072, lng: 18.7076577 },
    { lat: 48.5890144, lng: 18.708417 },
    { lat: 48.5890819, lng: 18.7085834 },
    { lat: 48.5891794, lng: 18.7087426 },
    { lat: 48.5893568, lng: 18.708936 },
    { lat: 48.5894435, lng: 18.7090634 },
    { lat: 48.5895959, lng: 18.7092366 },
    { lat: 48.5898672, lng: 18.7095441 },
    { lat: 48.589967, lng: 18.7096998 },
    { lat: 48.590012, lng: 18.7097859 },
    { lat: 48.5900234, lng: 18.71004 },
    { lat: 48.5899852, lng: 18.7103616 },
    { lat: 48.5899762, lng: 18.7104283 },
    { lat: 48.5899763, lng: 18.7107061 },
    { lat: 48.5899973, lng: 18.7109715 },
    { lat: 48.5900281, lng: 18.7112756 },
    { lat: 48.5900732, lng: 18.7114657 },
    { lat: 48.5901378, lng: 18.7116175 },
    { lat: 48.590235, lng: 18.7117938 },
    { lat: 48.5902981, lng: 18.7119405 },
    { lat: 48.5903443, lng: 18.712067 },
    { lat: 48.5902875, lng: 18.712314 },
    { lat: 48.590075, lng: 18.7130899 },
    { lat: 48.5900041, lng: 18.7133888 },
    { lat: 48.5899614, lng: 18.713648 },
    { lat: 48.5898351, lng: 18.7146458 },
    { lat: 48.5898236, lng: 18.714915 },
    { lat: 48.5898498, lng: 18.7151759 },
    { lat: 48.5898615, lng: 18.7154587 },
    { lat: 48.5898225, lng: 18.7157039 },
    { lat: 48.5897279, lng: 18.7159376 },
    { lat: 48.5893643, lng: 18.7166106 },
    { lat: 48.589401, lng: 18.7166839 },
    { lat: 48.5895887, lng: 18.7170135 },
    { lat: 48.5896149, lng: 18.7170581 },
    { lat: 48.5896584, lng: 18.7171334 },
    { lat: 48.5897567, lng: 18.7173071 },
    { lat: 48.5897751, lng: 18.7173384 },
    { lat: 48.5898015, lng: 18.7173858 },
    { lat: 48.5898286, lng: 18.7174331 },
    { lat: 48.5898559, lng: 18.7174793 },
    { lat: 48.589876, lng: 18.7175153 },
    { lat: 48.5901038, lng: 18.7180272 },
    { lat: 48.5904171, lng: 18.7185508 },
    { lat: 48.5905826, lng: 18.7188005 },
    { lat: 48.5908392, lng: 18.7191739 },
    { lat: 48.5914189, lng: 18.7202778 },
    { lat: 48.5926832, lng: 18.7222746 },
    { lat: 48.5932605, lng: 18.7226567 },
    { lat: 48.5939704, lng: 18.7230481 },
    { lat: 48.5945299, lng: 18.72339 },
    { lat: 48.5947729, lng: 18.7234843 },
    { lat: 48.5948017, lng: 18.7234958 },
    { lat: 48.5952437, lng: 18.7236673 },
    { lat: 48.5956554, lng: 18.7237074 },
    { lat: 48.5962252, lng: 18.7236943 },
    { lat: 48.5967299, lng: 18.7236616 },
    { lat: 48.5971882, lng: 18.7234973 },
    { lat: 48.597607, lng: 18.7239755 },
    { lat: 48.5982525, lng: 18.7246623 },
    { lat: 48.5983531, lng: 18.7247727 },
    { lat: 48.5983735, lng: 18.7247959 },
    { lat: 48.5991331, lng: 18.725636 },
    { lat: 48.5993026, lng: 18.7258137 },
    { lat: 48.5998031, lng: 18.7263395 },
    { lat: 48.6006698, lng: 18.7271789 },
    { lat: 48.6008485, lng: 18.7264664 },
    { lat: 48.6016865, lng: 18.7254468 },
    { lat: 48.6018815, lng: 18.7250638 },
    { lat: 48.6032307, lng: 18.7242404 },
    { lat: 48.6036185, lng: 18.7243647 },
    { lat: 48.604207, lng: 18.7242991 },
    { lat: 48.6044907, lng: 18.7243949 },
    { lat: 48.6052763, lng: 18.7244719 },
    { lat: 48.6059078, lng: 18.7246634 },
    { lat: 48.6066759, lng: 18.7250472 },
    { lat: 48.6073034, lng: 18.725267 },
    { lat: 48.6074999, lng: 18.7253356 },
    { lat: 48.6078849, lng: 18.7254702 },
    { lat: 48.6083914, lng: 18.7251158 },
    { lat: 48.6101255, lng: 18.7237634 },
    { lat: 48.6110629, lng: 18.7238111 },
    { lat: 48.6113203, lng: 18.7236923 },
    { lat: 48.6122432, lng: 18.7233148 },
    { lat: 48.61275, lng: 18.7231966 },
    { lat: 48.6134061, lng: 18.7229792 },
    { lat: 48.613671, lng: 18.7228895 },
    { lat: 48.6146128, lng: 18.7226988 },
    { lat: 48.6149736, lng: 18.7228492 },
    { lat: 48.6155579, lng: 18.7229131 },
    { lat: 48.6155943, lng: 18.7229172 },
    { lat: 48.6158484, lng: 18.7229454 },
    { lat: 48.6163867, lng: 18.7228683 },
    { lat: 48.6169435, lng: 18.7229266 },
    { lat: 48.6176063, lng: 18.7230277 },
    { lat: 48.6188914, lng: 18.7229967 },
    { lat: 48.6199004, lng: 18.7227141 },
    { lat: 48.6202908, lng: 18.7226057 },
    { lat: 48.6206834, lng: 18.7222686 },
    { lat: 48.6211458, lng: 18.722632 },
    { lat: 48.6213402, lng: 18.7228534 },
    { lat: 48.6220783, lng: 18.7237553 },
    { lat: 48.622739, lng: 18.7242837 },
    { lat: 48.623536, lng: 18.7241915 },
    { lat: 48.6241867, lng: 18.7242559 },
    { lat: 48.6246286, lng: 18.7244026 },
    { lat: 48.6252191, lng: 18.7243299 },
    { lat: 48.6253371, lng: 18.7239403 },
    { lat: 48.6251867, lng: 18.7230152 },
    { lat: 48.6250907, lng: 18.7224621 },
    { lat: 48.6249615, lng: 18.7214289 },
    { lat: 48.6249547, lng: 18.7214265 },
    { lat: 48.6247952, lng: 18.7200997 },
    { lat: 48.6246673, lng: 18.7197953 },
    { lat: 48.6246448, lng: 18.7197107 },
    { lat: 48.625026, lng: 18.7187974 },
    { lat: 48.6250317, lng: 18.71833 },
    { lat: 48.6252547, lng: 18.7179006 },
    { lat: 48.6258117, lng: 18.7173892 },
    { lat: 48.6262305, lng: 18.7168641 },
    { lat: 48.6262781, lng: 18.7167631 },
    { lat: 48.6262944, lng: 18.7167273 },
    { lat: 48.6264249, lng: 18.7164491 },
    { lat: 48.626462, lng: 18.716378 },
    { lat: 48.626595, lng: 18.71608 },
    { lat: 48.62662, lng: 18.715877 },
    { lat: 48.6266, lng: 18.715634 },
    { lat: 48.626536, lng: 18.715422 },
    { lat: 48.626397, lng: 18.715117 },
    { lat: 48.626392, lng: 18.715084 },
    { lat: 48.626356, lng: 18.714827 },
    { lat: 48.626355, lng: 18.714672 },
    { lat: 48.626429, lng: 18.714462 },
    { lat: 48.626543, lng: 18.714305 },
    { lat: 48.626683, lng: 18.714192 },
    { lat: 48.626957, lng: 18.713987 },
    { lat: 48.627596, lng: 18.713646 },
    { lat: 48.627767, lng: 18.712735 },
    { lat: 48.627867, lng: 18.712272 },
    { lat: 48.627959, lng: 18.712034 },
    { lat: 48.628074, lng: 18.711737 },
    { lat: 48.628074, lng: 18.711736 },
    { lat: 48.628118, lng: 18.710955 },
    { lat: 48.628195, lng: 18.710066 },
    { lat: 48.628215, lng: 18.709262 },
    { lat: 48.628241, lng: 18.708411 },
    { lat: 48.628097, lng: 18.707792 },
    { lat: 48.628185, lng: 18.707096 },
    { lat: 48.628201, lng: 18.706975 },
    { lat: 48.628241, lng: 18.706623 },
    { lat: 48.628381, lng: 18.70615 },
    { lat: 48.628636, lng: 18.705632 },
    { lat: 48.628937, lng: 18.705103 },
    { lat: 48.629665, lng: 18.704378 },
    { lat: 48.629898, lng: 18.703995 },
    { lat: 48.630158, lng: 18.703565 },
    { lat: 48.630183, lng: 18.703486 },
    { lat: 48.630412, lng: 18.702787 },
    { lat: 48.630465, lng: 18.702142 },
    { lat: 48.630727, lng: 18.70142 },
    { lat: 48.630738, lng: 18.701391 },
    { lat: 48.630749, lng: 18.701362 },
    { lat: 48.630755, lng: 18.701344 },
    { lat: 48.630837, lng: 18.701277 },
    { lat: 48.631136, lng: 18.701001 },
    { lat: 48.63148, lng: 18.700486 },
    { lat: 48.6318, lng: 18.700273 },
    { lat: 48.632555, lng: 18.69968 },
    { lat: 48.632671, lng: 18.699508 },
    { lat: 48.632991, lng: 18.698931 },
    { lat: 48.632941, lng: 18.698254 },
    { lat: 48.633103, lng: 18.697067 },
    { lat: 48.633003, lng: 18.696507 },
    { lat: 48.632945, lng: 18.696092 },
    { lat: 48.632923, lng: 18.695409 },
    { lat: 48.632831, lng: 18.695017 },
    { lat: 48.632761, lng: 18.6947 },
    { lat: 48.632617, lng: 18.694454 },
    { lat: 48.632557, lng: 18.694136 },
    { lat: 48.632403, lng: 18.693432 },
    { lat: 48.632132, lng: 18.69263 },
    { lat: 48.631953, lng: 18.692443 },
    { lat: 48.631758, lng: 18.691553 },
    { lat: 48.631507, lng: 18.691007 },
    { lat: 48.63132, lng: 18.690603 },
    { lat: 48.631596, lng: 18.689718 },
    { lat: 48.631495, lng: 18.688653 },
    { lat: 48.631655, lng: 18.68788 },
    { lat: 48.631857, lng: 18.686912 },
    { lat: 48.632228, lng: 18.6865169 },
    { lat: 48.632464, lng: 18.686165 },
    { lat: 48.632368, lng: 18.685243 },
    { lat: 48.63236, lng: 18.685173 },
    { lat: 48.632197, lng: 18.684853 },
    { lat: 48.632109, lng: 18.684682 },
    { lat: 48.632073, lng: 18.683992 },
    { lat: 48.631999, lng: 18.683701 },
    { lat: 48.631904, lng: 18.683327 },
    { lat: 48.631879, lng: 18.682941 },
    { lat: 48.631879, lng: 18.682793 },
    { lat: 48.632088, lng: 18.681719 },
    { lat: 48.632171, lng: 18.681076 },
    { lat: 48.632251, lng: 18.680243 },
    { lat: 48.63248, lng: 18.679468 },
    { lat: 48.632678, lng: 18.678054 },
    { lat: 48.632988, lng: 18.677329 },
    { lat: 48.633211, lng: 18.676393 },
    { lat: 48.633485, lng: 18.675596 },
    { lat: 48.633578, lng: 18.675038 },
    { lat: 48.6336462, lng: 18.6744174 },
    { lat: 48.633753, lng: 18.674089 },
    { lat: 48.633921, lng: 18.673245 },
    { lat: 48.633904, lng: 18.672749 },
    { lat: 48.634079, lng: 18.6721 },
    { lat: 48.634129, lng: 18.671874 },
    { lat: 48.634092, lng: 18.671459 },
    { lat: 48.634258, lng: 18.670804 },
    { lat: 48.634286, lng: 18.670693 },
    { lat: 48.634288, lng: 18.670688 },
    { lat: 48.63469, lng: 18.669338 },
    { lat: 48.63481, lng: 18.668792 },
    { lat: 48.635073, lng: 18.667068 },
    { lat: 48.635481, lng: 18.6664 },
    { lat: 48.635625, lng: 18.666228 },
    { lat: 48.636151, lng: 18.665655 },
    { lat: 48.636774, lng: 18.664477 },
    { lat: 48.63699, lng: 18.663858 },
    { lat: 48.637189, lng: 18.663286 },
    { lat: 48.637517, lng: 18.662682 },
    { lat: 48.637932, lng: 18.661909 },
    { lat: 48.638094, lng: 18.661701 },
    { lat: 48.638145, lng: 18.661633 },
    { lat: 48.638146, lng: 18.661632 },
    { lat: 48.638268, lng: 18.661477 },
    { lat: 48.638676, lng: 18.660419 },
    { lat: 48.638974, lng: 18.659881 },
    { lat: 48.639223, lng: 18.659468 },
    { lat: 48.63929, lng: 18.659277 },
    { lat: 48.639698, lng: 18.657015 },
    { lat: 48.639755, lng: 18.656724 },
    { lat: 48.63992, lng: 18.656502 },
    { lat: 48.639279, lng: 18.655825 },
    { lat: 48.638629, lng: 18.654814 },
    { lat: 48.638463, lng: 18.653487 },
    { lat: 48.638292, lng: 18.65282 },
    { lat: 48.636919, lng: 18.650361 },
    { lat: 48.636841, lng: 18.650248 },
    { lat: 48.636218, lng: 18.649561 },
    { lat: 48.635615, lng: 18.649084 },
    { lat: 48.63541, lng: 18.648959 },
    { lat: 48.634991, lng: 18.648568 },
    { lat: 48.634611, lng: 18.648376 },
    { lat: 48.63426, lng: 18.64814 },
    { lat: 48.633788, lng: 18.646734 },
    { lat: 48.633712, lng: 18.645988 },
    { lat: 48.633209, lng: 18.645409 },
    { lat: 48.632421, lng: 18.6542 },
    { lat: 48.631633, lng: 18.654654 },
    { lat: 48.6308, lng: 18.654794 },
    { lat: 48.629885, lng: 18.655436 },
    { lat: 48.627844, lng: 18.657361 },
    { lat: 48.6273, lng: 18.658377 },
    { lat: 48.626675, lng: 18.658725 },
    { lat: 48.625963, lng: 18.659599 },
    { lat: 48.625745, lng: 18.659746 },
    { lat: 48.624414, lng: 18.660957 },
    { lat: 48.624188, lng: 18.66098 },
    { lat: 48.622846, lng: 18.661212 },
    { lat: 48.62192, lng: 18.661863 },
    { lat: 48.621201, lng: 18.662259 },
    { lat: 48.620637, lng: 18.66268 },
    { lat: 48.620582, lng: 18.662718 },
    { lat: 48.619448, lng: 18.66358 },
    { lat: 48.619423, lng: 18.663599 },
    { lat: 48.617951, lng: 18.665136 },
    { lat: 48.61602, lng: 18.667153 },
    { lat: 48.615688, lng: 18.667501 },
    { lat: 48.615163, lng: 18.667871 },
    { lat: 48.614655, lng: 18.668949 },
    { lat: 48.614634, lng: 18.668983 },
  ],
  Kunešov: [
    { lat: 48.76089, lng: 18.884053 },
    { lat: 48.760985, lng: 18.883817 },
    { lat: 48.761596, lng: 18.884468 },
    { lat: 48.761955, lng: 18.885078 },
    { lat: 48.762408, lng: 18.885445 },
    { lat: 48.76276, lng: 18.88515 },
    { lat: 48.763002, lng: 18.884899 },
    { lat: 48.763333, lng: 18.884533 },
    { lat: 48.764131, lng: 18.884335 },
    { lat: 48.764865, lng: 18.884029 },
    { lat: 48.765218, lng: 18.883761 },
    { lat: 48.765706, lng: 18.883997 },
    { lat: 48.766245, lng: 18.884044 },
    { lat: 48.768183, lng: 18.884376 },
    { lat: 48.768455, lng: 18.884487 },
    { lat: 48.768725, lng: 18.88409 },
    { lat: 48.769162, lng: 18.883515 },
    { lat: 48.76921, lng: 18.883462 },
    { lat: 48.769399, lng: 18.882562 },
    { lat: 48.769532, lng: 18.882137 },
    { lat: 48.769768, lng: 18.881367 },
    { lat: 48.769508, lng: 18.880615 },
    { lat: 48.769463, lng: 18.878512 },
    { lat: 48.769315, lng: 18.877025 },
    { lat: 48.76913, lng: 18.87623 },
    { lat: 48.768915, lng: 18.875708 },
    { lat: 48.76907, lng: 18.875214 },
    { lat: 48.769386, lng: 18.873509 },
    { lat: 48.769047, lng: 18.872962 },
    { lat: 48.768859, lng: 18.872798 },
    { lat: 48.768424, lng: 18.872134 },
    { lat: 48.767707, lng: 18.871631 },
    { lat: 48.767339, lng: 18.87137 },
    { lat: 48.767182, lng: 18.871192 },
    { lat: 48.76676, lng: 18.87044 },
    { lat: 48.767146, lng: 18.86906 },
    { lat: 48.76712, lng: 18.868656 },
    { lat: 48.767404, lng: 18.867969 },
    { lat: 48.767341, lng: 18.867373 },
    { lat: 48.767169, lng: 18.866643 },
    { lat: 48.766296, lng: 18.864803 },
    { lat: 48.76552, lng: 18.864295 },
    { lat: 48.765185, lng: 18.863826 },
    { lat: 48.764638, lng: 18.863354 },
    { lat: 48.764509, lng: 18.862141 },
    { lat: 48.764048, lng: 18.860611 },
    { lat: 48.763996, lng: 18.860439 },
    { lat: 48.763865, lng: 18.860094 },
    { lat: 48.763786, lng: 18.859139 },
    { lat: 48.763741, lng: 18.859036 },
    { lat: 48.763688, lng: 18.858916 },
    { lat: 48.763658, lng: 18.858662 },
    { lat: 48.763647, lng: 18.858527 },
    { lat: 48.763625, lng: 18.858315 },
    { lat: 48.763489, lng: 18.856978 },
    { lat: 48.763444, lng: 18.856014 },
    { lat: 48.763456, lng: 18.855477 },
    { lat: 48.763459, lng: 18.855357 },
    { lat: 48.763462, lng: 18.855214 },
    { lat: 48.763456, lng: 18.854901 },
    { lat: 48.763456, lng: 18.854894 },
    { lat: 48.763452, lng: 18.85478 },
    { lat: 48.763452, lng: 18.854777 },
    { lat: 48.763446, lng: 18.854572 },
    { lat: 48.763444, lng: 18.854531 },
    { lat: 48.763245, lng: 18.854378 },
    { lat: 48.762861, lng: 18.854253 },
    { lat: 48.762207, lng: 18.854256 },
    { lat: 48.761746, lng: 18.85434 },
    { lat: 48.761455, lng: 18.854389 },
    { lat: 48.761017, lng: 18.854449 },
    { lat: 48.760641, lng: 18.854594 },
    { lat: 48.760275, lng: 18.854582 },
    { lat: 48.760096, lng: 18.854522 },
    { lat: 48.75963, lng: 18.854266 },
    { lat: 48.759607, lng: 18.854254 },
    { lat: 48.759523, lng: 18.854247 },
    { lat: 48.759366, lng: 18.854234 },
    { lat: 48.759289, lng: 18.854265 },
    { lat: 48.759202, lng: 18.854304 },
    { lat: 48.759111, lng: 18.854337 },
    { lat: 48.759028, lng: 18.854311 },
    { lat: 48.75883, lng: 18.854103 },
    { lat: 48.758758, lng: 18.853995 },
    { lat: 48.758752, lng: 18.853986 },
    { lat: 48.758465, lng: 18.853556 },
    { lat: 48.758373, lng: 18.853418 },
    { lat: 48.758246, lng: 18.853002 },
    { lat: 48.758093, lng: 18.852495 },
    { lat: 48.757904, lng: 18.851887 },
    { lat: 48.757785, lng: 18.851601 },
    { lat: 48.757561, lng: 18.851068 },
    { lat: 48.757495, lng: 18.85093 },
    { lat: 48.757108, lng: 18.8501 },
    { lat: 48.757005, lng: 18.849874 },
    { lat: 48.756786, lng: 18.8494 },
    { lat: 48.756741, lng: 18.849109 },
    { lat: 48.7567, lng: 18.848914 },
    { lat: 48.756587, lng: 18.848762 },
    { lat: 48.756243, lng: 18.848392 },
    { lat: 48.756002, lng: 18.847831 },
    { lat: 48.755826, lng: 18.847376 },
    { lat: 48.755542, lng: 18.847141 },
    { lat: 48.755364, lng: 18.847042 },
    { lat: 48.75505, lng: 18.846973 },
    { lat: 48.754662, lng: 18.846961 },
    { lat: 48.754345, lng: 18.847022 },
    { lat: 48.754133, lng: 18.846929 },
    { lat: 48.75404, lng: 18.846744 },
    { lat: 48.754015, lng: 18.846499 },
    { lat: 48.753985, lng: 18.846344 },
    { lat: 48.753817, lng: 18.846232 },
    { lat: 48.753709, lng: 18.846033 },
    { lat: 48.753659, lng: 18.845784 },
    { lat: 48.753596, lng: 18.845708 },
    { lat: 48.753459, lng: 18.845524 },
    { lat: 48.753289, lng: 18.844895 },
    { lat: 48.753123, lng: 18.844759 },
    { lat: 48.752982, lng: 18.844731 },
    { lat: 48.752844, lng: 18.844762 },
    { lat: 48.752691, lng: 18.84487 },
    { lat: 48.75251, lng: 18.844854 },
    { lat: 48.752419, lng: 18.844776 },
    { lat: 48.75225, lng: 18.844532 },
    { lat: 48.751921, lng: 18.844197 },
    { lat: 48.751876, lng: 18.84402 },
    { lat: 48.751168, lng: 18.843455 },
    { lat: 48.751079, lng: 18.843356 },
    { lat: 48.750721, lng: 18.842973 },
    { lat: 48.750177, lng: 18.842387 },
    { lat: 48.750185, lng: 18.84236 },
    { lat: 48.75024, lng: 18.842185 },
    { lat: 48.750262, lng: 18.84212 },
    { lat: 48.750524, lng: 18.841257 },
    { lat: 48.750339, lng: 18.840343 },
    { lat: 48.749644, lng: 18.839579 },
    { lat: 48.749495, lng: 18.839246 },
    { lat: 48.7494, lng: 18.838805 },
    { lat: 48.749315, lng: 18.838569 },
    { lat: 48.749241, lng: 18.838338 },
    { lat: 48.749075, lng: 18.83694 },
    { lat: 48.748688, lng: 18.835741 },
    { lat: 48.74868, lng: 18.835388 },
    { lat: 48.74841, lng: 18.834768 },
    { lat: 48.748339, lng: 18.83439 },
    { lat: 48.748418, lng: 18.833765 },
    { lat: 48.748033, lng: 18.832758 },
    { lat: 48.747918, lng: 18.832329 },
    { lat: 48.747306, lng: 18.831598 },
    { lat: 48.747037, lng: 18.830873 },
    { lat: 48.746906, lng: 18.830653 },
    { lat: 48.746627, lng: 18.830172 },
    { lat: 48.74603, lng: 18.829653 },
    { lat: 48.744619, lng: 18.827914 },
    { lat: 48.744147, lng: 18.826841 },
    { lat: 48.744057, lng: 18.826348 },
    { lat: 48.743964, lng: 18.825363 },
    { lat: 48.743874, lng: 18.825015 },
    { lat: 48.743637, lng: 18.825389 },
    { lat: 48.74317, lng: 18.825816 },
    { lat: 48.742209, lng: 18.826019 },
    { lat: 48.74204, lng: 18.826089 },
    { lat: 48.741944, lng: 18.826128 },
    { lat: 48.741816, lng: 18.826186 },
    { lat: 48.741582, lng: 18.826291 },
    { lat: 48.74097, lng: 18.826282 },
    { lat: 48.740764, lng: 18.826279 },
    { lat: 48.739328, lng: 18.826868 },
    { lat: 48.738966, lng: 18.826757 },
    { lat: 48.738693, lng: 18.826674 },
    { lat: 48.73833, lng: 18.826456 },
    { lat: 48.738252, lng: 18.826409 },
    { lat: 48.738179, lng: 18.826382 },
    { lat: 48.737839, lng: 18.826252 },
    { lat: 48.736853, lng: 18.826328 },
    { lat: 48.736615, lng: 18.826383 },
    { lat: 48.736373, lng: 18.826438 },
    { lat: 48.735873, lng: 18.825789 },
    { lat: 48.735803, lng: 18.825334 },
    { lat: 48.735801, lng: 18.825324 },
    { lat: 48.735489, lng: 18.824698 },
    { lat: 48.735357, lng: 18.824224 },
    { lat: 48.735161, lng: 18.824015 },
    { lat: 48.734978, lng: 18.82382 },
    { lat: 48.734736, lng: 18.823176 },
    { lat: 48.73457, lng: 18.822685 },
    { lat: 48.734468, lng: 18.822454 },
    { lat: 48.73438, lng: 18.821645 },
    { lat: 48.733446, lng: 18.821065 },
    { lat: 48.733148, lng: 18.820721 },
    { lat: 48.732646, lng: 18.820234 },
    { lat: 48.732354, lng: 18.820012 },
    { lat: 48.731609, lng: 18.820036 },
    { lat: 48.731046, lng: 18.819135 },
    { lat: 48.730208, lng: 18.818079 },
    { lat: 48.729707, lng: 18.817561 },
    { lat: 48.729293, lng: 18.817564 },
    { lat: 48.728329, lng: 18.817145 },
    { lat: 48.728076, lng: 18.817226 },
    { lat: 48.728026, lng: 18.817235 },
    { lat: 48.72792, lng: 18.817252 },
    { lat: 48.727233, lng: 18.817365 },
    { lat: 48.727019, lng: 18.817345 },
    { lat: 48.726284, lng: 18.816784 },
    { lat: 48.724349, lng: 18.814765 },
    { lat: 48.723785, lng: 18.814123 },
    { lat: 48.723479, lng: 18.813809 },
    { lat: 48.723228, lng: 18.813196 },
    { lat: 48.722761, lng: 18.812518 },
    { lat: 48.722021, lng: 18.811922 },
    { lat: 48.721545, lng: 18.811879 },
    { lat: 48.7215275, lng: 18.8119447 },
    { lat: 48.7214359, lng: 18.8124995 },
    { lat: 48.7214117, lng: 18.8126424 },
    { lat: 48.7212586, lng: 18.8135763 },
    { lat: 48.7211834, lng: 18.8140381 },
    { lat: 48.7205374, lng: 18.8159639 },
    { lat: 48.7198619, lng: 18.8190173 },
    { lat: 48.719584, lng: 18.8209767 },
    { lat: 48.7195591, lng: 18.8211939 },
    { lat: 48.7195495, lng: 18.8213754 },
    { lat: 48.7188411, lng: 18.8226921 },
    { lat: 48.718431, lng: 18.8231301 },
    { lat: 48.7181871, lng: 18.8233841 },
    { lat: 48.7181713, lng: 18.8234077 },
    { lat: 48.7181431, lng: 18.8234511 },
    { lat: 48.7179534, lng: 18.8237468 },
    { lat: 48.7176462, lng: 18.8238055 },
    { lat: 48.7175699, lng: 18.8239652 },
    { lat: 48.7173243, lng: 18.8245297 },
    { lat: 48.7172445, lng: 18.8247313 },
    { lat: 48.7171685, lng: 18.8249153 },
    { lat: 48.7171029, lng: 18.8250856 },
    { lat: 48.7170493, lng: 18.8252259 },
    { lat: 48.7169939, lng: 18.8253739 },
    { lat: 48.7169522, lng: 18.8255042 },
    { lat: 48.7168982, lng: 18.8256606 },
    { lat: 48.7168716, lng: 18.825758 },
    { lat: 48.7168529, lng: 18.8258527 },
    { lat: 48.7168112, lng: 18.8259813 },
    { lat: 48.7166195, lng: 18.8265025 },
    { lat: 48.7165907, lng: 18.8265992 },
    { lat: 48.7165473, lng: 18.8267589 },
    { lat: 48.7165075, lng: 18.8269294 },
    { lat: 48.7164814, lng: 18.8270767 },
    { lat: 48.7164569, lng: 18.8272332 },
    { lat: 48.7164507, lng: 18.8273328 },
    { lat: 48.7164444, lng: 18.8276007 },
    { lat: 48.716424, lng: 18.8280777 },
    { lat: 48.7164134, lng: 18.8282186 },
    { lat: 48.7163985, lng: 18.8284092 },
    { lat: 48.7163715, lng: 18.8286249 },
    { lat: 48.7163563, lng: 18.828746 },
    { lat: 48.7163464, lng: 18.8288836 },
    { lat: 48.7163431, lng: 18.8289929 },
    { lat: 48.71634, lng: 18.8291054 },
    { lat: 48.7163246, lng: 18.8293504 },
    { lat: 48.7163265, lng: 18.8294087 },
    { lat: 48.7163278, lng: 18.8295204 },
    { lat: 48.7163481, lng: 18.8296058 },
    { lat: 48.7163968, lng: 18.829785 },
    { lat: 48.7164122, lng: 18.8298663 },
    { lat: 48.7164291, lng: 18.8299515 },
    { lat: 48.7164284, lng: 18.8300469 },
    { lat: 48.7164113, lng: 18.8301718 },
    { lat: 48.7163949, lng: 18.830333 },
    { lat: 48.7163725, lng: 18.830466 },
    { lat: 48.7163231, lng: 18.83058 },
    { lat: 48.71626, lng: 18.8307114 },
    { lat: 48.716161, lng: 18.8309342 },
    { lat: 48.7161132, lng: 18.831056 },
    { lat: 48.7160622, lng: 18.831202 },
    { lat: 48.7160058, lng: 18.8313758 },
    { lat: 48.7159759, lng: 18.8314686 },
    { lat: 48.715926, lng: 18.8316403 },
    { lat: 48.7158903, lng: 18.8317766 },
    { lat: 48.7158593, lng: 18.8318938 },
    { lat: 48.7158258, lng: 18.832031 },
    { lat: 48.7157888, lng: 18.8321875 },
    { lat: 48.7157729, lng: 18.8322733 },
    { lat: 48.7157502, lng: 18.8323364 },
    { lat: 48.7156161, lng: 18.8328794 },
    { lat: 48.7155523, lng: 18.8331708 },
    { lat: 48.7155136, lng: 18.8333343 },
    { lat: 48.7154798, lng: 18.8334669 },
    { lat: 48.7154608, lng: 18.8336234 },
    { lat: 48.7154217, lng: 18.8340405 },
    { lat: 48.7154064, lng: 18.8342047 },
    { lat: 48.7153796, lng: 18.8344475 },
    { lat: 48.7153625, lng: 18.834596 },
    { lat: 48.7153353, lng: 18.8347867 },
    { lat: 48.7152911, lng: 18.8350011 },
    { lat: 48.7150863, lng: 18.8355925 },
    { lat: 48.7150627, lng: 18.8356621 },
    { lat: 48.7150142, lng: 18.8357692 },
    { lat: 48.714966, lng: 18.8358603 },
    { lat: 48.7149045, lng: 18.8359829 },
    { lat: 48.714869, lng: 18.8360775 },
    { lat: 48.7145598, lng: 18.8367701 },
    { lat: 48.7145328, lng: 18.8368372 },
    { lat: 48.7144771, lng: 18.8369335 },
    { lat: 48.7144106, lng: 18.8370242 },
    { lat: 48.7143677, lng: 18.8370657 },
    { lat: 48.7142711, lng: 18.8371582 },
    { lat: 48.7142244, lng: 18.8372134 },
    { lat: 48.7141645, lng: 18.8372762 },
    { lat: 48.7141093, lng: 18.8373467 },
    { lat: 48.7140687, lng: 18.8374104 },
    { lat: 48.7140104, lng: 18.8375016 },
    { lat: 48.7139514, lng: 18.8376039 },
    { lat: 48.7138914, lng: 18.8377089 },
    { lat: 48.7138328, lng: 18.8378179 },
    { lat: 48.7137714, lng: 18.8379377 },
    { lat: 48.7137326, lng: 18.8380366 },
    { lat: 48.7136858, lng: 18.8381364 },
    { lat: 48.7136512, lng: 18.8382073 },
    { lat: 48.7135772, lng: 18.8383478 },
    { lat: 48.7135169, lng: 18.8384428 },
    { lat: 48.7133986, lng: 18.8386382 },
    { lat: 48.7133274, lng: 18.8387688 },
    { lat: 48.7132645, lng: 18.8389055 },
    { lat: 48.7132051, lng: 18.8390625 },
    { lat: 48.7131461, lng: 18.8392512 },
    { lat: 48.7131154, lng: 18.8393552 },
    { lat: 48.7130839, lng: 18.8394628 },
    { lat: 48.7130062, lng: 18.8397277 },
    { lat: 48.7129735, lng: 18.8398359 },
    { lat: 48.7129397, lng: 18.8399666 },
    { lat: 48.712878, lng: 18.8403856 },
    { lat: 48.7128782, lng: 18.8405213 },
    { lat: 48.7128868, lng: 18.8406645 },
    { lat: 48.7128937, lng: 18.8407744 },
    { lat: 48.7129106, lng: 18.840927 },
    { lat: 48.7129233, lng: 18.8410176 },
    { lat: 48.7128844, lng: 18.8413154 },
    { lat: 48.7128623, lng: 18.8413623 },
    { lat: 48.7128388, lng: 18.8414097 },
    { lat: 48.7126381, lng: 18.8420648 },
    { lat: 48.7120493, lng: 18.8433389 },
    { lat: 48.7113393, lng: 18.8451536 },
    { lat: 48.7112755, lng: 18.8452715 },
    { lat: 48.7111326, lng: 18.8455598 },
    { lat: 48.7105841, lng: 18.8463328 },
    { lat: 48.710307, lng: 18.8474043 },
    { lat: 48.7100722, lng: 18.8479669 },
    { lat: 48.7098945, lng: 18.8480808 },
    { lat: 48.7095121, lng: 18.849601 },
    { lat: 48.7091735, lng: 18.8503994 },
    { lat: 48.7087701, lng: 18.8525811 },
    { lat: 48.7079613, lng: 18.8539035 },
    { lat: 48.7075913, lng: 18.85416 },
    { lat: 48.7072589, lng: 18.8545357 },
    { lat: 48.7064228, lng: 18.8550497 },
    { lat: 48.7061237, lng: 18.8562629 },
    { lat: 48.7067376, lng: 18.8562499 },
    { lat: 48.7067702, lng: 18.8567691 },
    { lat: 48.7068337, lng: 18.8571025 },
    { lat: 48.7067385, lng: 18.8573126 },
    { lat: 48.7071938, lng: 18.8581564 },
    { lat: 48.7072763, lng: 18.859011 },
    { lat: 48.7081853, lng: 18.8582523 },
    { lat: 48.7083318, lng: 18.8589698 },
    { lat: 48.7086667, lng: 18.8593179 },
    { lat: 48.7089596, lng: 18.8593969 },
    { lat: 48.7091359, lng: 18.8597545 },
    { lat: 48.7090796, lng: 18.8601689 },
    { lat: 48.708364, lng: 18.8610071 },
    { lat: 48.7083072, lng: 18.8612148 },
    { lat: 48.7078764, lng: 18.8614328 },
    { lat: 48.7079134, lng: 18.8617333 },
    { lat: 48.7080316, lng: 18.8620121 },
    { lat: 48.7080405, lng: 18.8620339 },
    { lat: 48.7079144, lng: 18.8624744 },
    { lat: 48.707977, lng: 18.8627879 },
    { lat: 48.7078763, lng: 18.8630662 },
    { lat: 48.7082031, lng: 18.8630622 },
    { lat: 48.7085247, lng: 18.8625651 },
    { lat: 48.7088289, lng: 18.8624521 },
    { lat: 48.708921, lng: 18.8624237 },
    { lat: 48.7096558, lng: 18.8631981 },
    { lat: 48.7097793, lng: 18.8639968 },
    { lat: 48.7097813, lng: 18.8645568 },
    { lat: 48.7098679, lng: 18.8652952 },
    { lat: 48.709869, lng: 18.8654665 },
    { lat: 48.7099079, lng: 18.8660046 },
    { lat: 48.7100023, lng: 18.8664958 },
    { lat: 48.7100388, lng: 18.8666172 },
    { lat: 48.7103739, lng: 18.8674677 },
    { lat: 48.7104601, lng: 18.8676771 },
    { lat: 48.7106059, lng: 18.8678145 },
    { lat: 48.7105324, lng: 18.8678971 },
    { lat: 48.7102579, lng: 18.8680402 },
    { lat: 48.7104342, lng: 18.8684828 },
    { lat: 48.7106262, lng: 18.8687279 },
    { lat: 48.7105813, lng: 18.8689067 },
    { lat: 48.7107971, lng: 18.8692647 },
    { lat: 48.710947, lng: 18.8691332 },
    { lat: 48.7110362, lng: 18.8693108 },
    { lat: 48.7111441, lng: 18.8694555 },
    { lat: 48.7113927, lng: 18.8697936 },
    { lat: 48.7117668, lng: 18.8701829 },
    { lat: 48.7122301, lng: 18.8704389 },
    { lat: 48.7127308, lng: 18.870814 },
    { lat: 48.7129568, lng: 18.8712144 },
    { lat: 48.7132476, lng: 18.8715822 },
    { lat: 48.7136439, lng: 18.8718543 },
    { lat: 48.713948, lng: 18.8719598 },
    { lat: 48.71419, lng: 18.872037 },
    { lat: 48.7142719, lng: 18.8720952 },
    { lat: 48.7145883, lng: 18.8723249 },
    { lat: 48.7151649, lng: 18.8727929 },
    { lat: 48.7155221, lng: 18.8729825 },
    { lat: 48.7158757, lng: 18.8732376 },
    { lat: 48.7162364, lng: 18.8733686 },
    { lat: 48.7165602, lng: 18.8736296 },
    { lat: 48.7167496, lng: 18.8739351 },
    { lat: 48.7168892, lng: 18.8740211 },
    { lat: 48.7169893, lng: 18.8739272 },
    { lat: 48.7170937, lng: 18.8738542 },
    { lat: 48.7173892, lng: 18.8738104 },
    { lat: 48.7177052, lng: 18.8739231 },
    { lat: 48.7176358, lng: 18.8745701 },
    { lat: 48.7178807, lng: 18.8745938 },
    { lat: 48.7179272, lng: 18.8746858 },
    { lat: 48.7179714, lng: 18.8747777 },
    { lat: 48.718061, lng: 18.874965 },
    { lat: 48.7180692, lng: 18.8756057 },
    { lat: 48.7181704, lng: 18.8765368 },
    { lat: 48.7184505, lng: 18.8770512 },
    { lat: 48.7187013, lng: 18.8771895 },
    { lat: 48.7188208, lng: 18.8776925 },
    { lat: 48.7187527, lng: 18.8778344 },
    { lat: 48.7183285, lng: 18.8780058 },
    { lat: 48.7185031, lng: 18.8791615 },
    { lat: 48.7186631, lng: 18.8790705 },
    { lat: 48.7188773, lng: 18.8791367 },
    { lat: 48.7190665, lng: 18.879002 },
    { lat: 48.7192957, lng: 18.879071 },
    { lat: 48.7193196, lng: 18.8793153 },
    { lat: 48.7202093, lng: 18.8797747 },
    { lat: 48.7204481, lng: 18.8801669 },
    { lat: 48.7210223, lng: 18.8808506 },
    { lat: 48.7210767, lng: 18.8808557 },
    { lat: 48.7211762, lng: 18.880879 },
    { lat: 48.7213493, lng: 18.8806714 },
    { lat: 48.7213854, lng: 18.8808725 },
    { lat: 48.7217217, lng: 18.8809829 },
    { lat: 48.7217285, lng: 18.8814231 },
    { lat: 48.7211242, lng: 18.88125 },
    { lat: 48.721029, lng: 18.8818098 },
    { lat: 48.7208634, lng: 18.8817921 },
    { lat: 48.7205598, lng: 18.8818525 },
    { lat: 48.7205383, lng: 18.8824843 },
    { lat: 48.7206746, lng: 18.8833546 },
    { lat: 48.7208686, lng: 18.8845852 },
    { lat: 48.7212693, lng: 18.8846991 },
    { lat: 48.7213344, lng: 18.8846496 },
    { lat: 48.722174, lng: 18.8846833 },
    { lat: 48.722385, lng: 18.884601 },
    { lat: 48.7227397, lng: 18.8850056 },
    { lat: 48.7223682, lng: 18.8855875 },
    { lat: 48.7226055, lng: 18.8857485 },
    { lat: 48.7225408, lng: 18.8861165 },
    { lat: 48.7227947, lng: 18.8863176 },
    { lat: 48.7230145, lng: 18.8866839 },
    { lat: 48.7231408, lng: 18.8867522 },
    { lat: 48.7238552, lng: 18.8874014 },
    { lat: 48.7241008, lng: 18.8874389 },
    { lat: 48.7245042, lng: 18.8872793 },
    { lat: 48.7248181, lng: 18.8878606 },
    { lat: 48.7252551, lng: 18.8872795 },
    { lat: 48.7253704, lng: 18.8872838 },
    { lat: 48.7260245, lng: 18.886588 },
    { lat: 48.726357, lng: 18.8857088 },
    { lat: 48.7265566, lng: 18.8852718 },
    { lat: 48.726691, lng: 18.8846424 },
    { lat: 48.7271444, lng: 18.8846197 },
    { lat: 48.7274909, lng: 18.88452 },
    { lat: 48.7277522, lng: 18.8844239 },
    { lat: 48.7280587, lng: 18.8854511 },
    { lat: 48.7281888, lng: 18.8860511 },
    { lat: 48.728667, lng: 18.8869267 },
    { lat: 48.7289471, lng: 18.8867587 },
    { lat: 48.7292232, lng: 18.8864716 },
    { lat: 48.7294493, lng: 18.8861564 },
    { lat: 48.7294518, lng: 18.8861622 },
    { lat: 48.7296241, lng: 18.8865312 },
    { lat: 48.7297509, lng: 18.8869788 },
    { lat: 48.7306378, lng: 18.8876231 },
    { lat: 48.7306741, lng: 18.8876496 },
    { lat: 48.7307244, lng: 18.8876861 },
    { lat: 48.7311963, lng: 18.8872602 },
    { lat: 48.7315856, lng: 18.8873312 },
    { lat: 48.731655, lng: 18.8873434 },
    { lat: 48.7316652, lng: 18.8873458 },
    { lat: 48.7319092, lng: 18.887266 },
    { lat: 48.7320507, lng: 18.8871647 },
    { lat: 48.7323483, lng: 18.8873404 },
    { lat: 48.7324639, lng: 18.887305 },
    { lat: 48.7328316, lng: 18.8867424 },
    { lat: 48.7332225, lng: 18.8866661 },
    { lat: 48.7337365, lng: 18.8871678 },
    { lat: 48.7339075, lng: 18.8871795 },
    { lat: 48.734652, lng: 18.8872294 },
    { lat: 48.7347862, lng: 18.8871429 },
    { lat: 48.735145, lng: 18.8869524 },
    { lat: 48.7352295, lng: 18.8869957 },
    { lat: 48.7353497, lng: 18.886853 },
    { lat: 48.7355858, lng: 18.8866434 },
    { lat: 48.7358367, lng: 18.8858887 },
    { lat: 48.7361403, lng: 18.8862238 },
    { lat: 48.73666, lng: 18.8865705 },
    { lat: 48.7368447, lng: 18.8865467 },
    { lat: 48.7373621, lng: 18.8866972 },
    { lat: 48.7374956, lng: 18.8866604 },
    { lat: 48.7378731, lng: 18.8867218 },
    { lat: 48.7380313, lng: 18.8867942 },
    { lat: 48.7381637, lng: 18.8868458 },
    { lat: 48.7383829, lng: 18.8868997 },
    { lat: 48.738784, lng: 18.88698 },
    { lat: 48.7388103, lng: 18.8869869 },
    { lat: 48.7388731, lng: 18.8870028 },
    { lat: 48.7388915, lng: 18.8870077 },
    { lat: 48.7389422, lng: 18.887011 },
    { lat: 48.7391308, lng: 18.8872132 },
    { lat: 48.7396004, lng: 18.8879453 },
    { lat: 48.7397928, lng: 18.8879373 },
    { lat: 48.7399376, lng: 18.8876834 },
    { lat: 48.7399543, lng: 18.8876547 },
    { lat: 48.740535, lng: 18.8861888 },
    { lat: 48.7406831, lng: 18.8861106 },
    { lat: 48.7410487, lng: 18.8846475 },
    { lat: 48.7411168, lng: 18.8843741 },
    { lat: 48.7413243, lng: 18.8832793 },
    { lat: 48.741344, lng: 18.8831791 },
    { lat: 48.7414582, lng: 18.882727 },
    { lat: 48.7415278, lng: 18.8821697 },
    { lat: 48.7416513, lng: 18.8817468 },
    { lat: 48.7418151, lng: 18.8814449 },
    { lat: 48.7420426, lng: 18.8805878 },
    { lat: 48.7421006, lng: 18.880576 },
    { lat: 48.7421655, lng: 18.8805644 },
    { lat: 48.7423585, lng: 18.8806332 },
    { lat: 48.7429248, lng: 18.8810991 },
    { lat: 48.7439395, lng: 18.8817745 },
    { lat: 48.7447964, lng: 18.8822552 },
    { lat: 48.7451402, lng: 18.8824291 },
    { lat: 48.7455337, lng: 18.8826017 },
    { lat: 48.7457252, lng: 18.8826867 },
    { lat: 48.745912, lng: 18.8827662 },
    { lat: 48.7461706, lng: 18.8828608 },
    { lat: 48.7468882, lng: 18.8832 },
    { lat: 48.7471412, lng: 18.8835575 },
    { lat: 48.7473003, lng: 18.8837378 },
    { lat: 48.7474736, lng: 18.8840551 },
    { lat: 48.7476088, lng: 18.884121 },
    { lat: 48.748649, lng: 18.8842843 },
    { lat: 48.748913, lng: 18.8843311 },
    { lat: 48.7493372, lng: 18.8845988 },
    { lat: 48.750658, lng: 18.8848042 },
    { lat: 48.7517926, lng: 18.88503 },
    { lat: 48.7521256, lng: 18.8855404 },
    { lat: 48.7522583, lng: 18.8855119 },
    { lat: 48.7522767, lng: 18.8856291 },
    { lat: 48.7524944, lng: 18.8858286 },
    { lat: 48.7527174, lng: 18.8862175 },
    { lat: 48.7537973, lng: 18.8867336 },
    { lat: 48.7539757, lng: 18.8868077 },
    { lat: 48.7541772, lng: 18.8869776 },
    { lat: 48.7542749, lng: 18.8869876 },
    { lat: 48.7545216, lng: 18.8872051 },
    { lat: 48.7553589, lng: 18.887456 },
    { lat: 48.7560823, lng: 18.8875367 },
    { lat: 48.7566107, lng: 18.8881395 },
    { lat: 48.757104, lng: 18.8877355 },
    { lat: 48.7574006, lng: 18.8875395 },
    { lat: 48.7577178, lng: 18.8874171 },
    { lat: 48.7581492, lng: 18.88699 },
    { lat: 48.7584941, lng: 18.8873371 },
    { lat: 48.7589141, lng: 18.8876794 },
    { lat: 48.7593567, lng: 18.8873399 },
    { lat: 48.7595468, lng: 18.8871321 },
    { lat: 48.7600428, lng: 18.8859029 },
    { lat: 48.7606816, lng: 18.8845561 },
    { lat: 48.76089, lng: 18.884053 },
  ],
  Kopernica: [
    { lat: 48.7061237, lng: 18.8562629 },
    { lat: 48.7064228, lng: 18.8550497 },
    { lat: 48.7072589, lng: 18.8545357 },
    { lat: 48.7075913, lng: 18.85416 },
    { lat: 48.7079613, lng: 18.8539035 },
    { lat: 48.7087701, lng: 18.8525811 },
    { lat: 48.7091735, lng: 18.8503994 },
    { lat: 48.7095121, lng: 18.849601 },
    { lat: 48.7098945, lng: 18.8480808 },
    { lat: 48.7100722, lng: 18.8479669 },
    { lat: 48.710307, lng: 18.8474043 },
    { lat: 48.7105841, lng: 18.8463328 },
    { lat: 48.7111326, lng: 18.8455598 },
    { lat: 48.7112755, lng: 18.8452715 },
    { lat: 48.7113393, lng: 18.8451536 },
    { lat: 48.7120493, lng: 18.8433389 },
    { lat: 48.7126381, lng: 18.8420648 },
    { lat: 48.7128388, lng: 18.8414097 },
    { lat: 48.7128623, lng: 18.8413623 },
    { lat: 48.7125153, lng: 18.841437 },
    { lat: 48.7122934, lng: 18.8415058 },
    { lat: 48.7119372, lng: 18.8415301 },
    { lat: 48.7116082, lng: 18.8415159 },
    { lat: 48.7113582, lng: 18.8415605 },
    { lat: 48.7109529, lng: 18.8415329 },
    { lat: 48.7105401, lng: 18.8416379 },
    { lat: 48.710189, lng: 18.8415866 },
    { lat: 48.7099918, lng: 18.8418078 },
    { lat: 48.7097908, lng: 18.8417595 },
    { lat: 48.7094837, lng: 18.8413146 },
    { lat: 48.7093029, lng: 18.841265 },
    { lat: 48.708904, lng: 18.8414233 },
    { lat: 48.7088107, lng: 18.8414061 },
    { lat: 48.7087609, lng: 18.8414013 },
    { lat: 48.7087241, lng: 18.8413889 },
    { lat: 48.7086855, lng: 18.8413656 },
    { lat: 48.7086717, lng: 18.841358 },
    { lat: 48.708293, lng: 18.8416421 },
    { lat: 48.7081837, lng: 18.8418857 },
    { lat: 48.7080273, lng: 18.8419553 },
    { lat: 48.7078314, lng: 18.8418946 },
    { lat: 48.7075187, lng: 18.8418477 },
    { lat: 48.7072563, lng: 18.8414818 },
    { lat: 48.7070305, lng: 18.8416107 },
    { lat: 48.7069198, lng: 18.8416913 },
    { lat: 48.706876, lng: 18.8416529 },
    { lat: 48.706664, lng: 18.8417373 },
    { lat: 48.7064366, lng: 18.8414644 },
    { lat: 48.7061364, lng: 18.8416349 },
    { lat: 48.7058388, lng: 18.8417012 },
    { lat: 48.7055662, lng: 18.84151 },
    { lat: 48.705431, lng: 18.8415553 },
    { lat: 48.7052421, lng: 18.8417819 },
    { lat: 48.7050544, lng: 18.8418163 },
    { lat: 48.704843, lng: 18.841786 },
    { lat: 48.7048417, lng: 18.8418244 },
    { lat: 48.7046207, lng: 18.8420412 },
    { lat: 48.7041696, lng: 18.8426062 },
    { lat: 48.7041455, lng: 18.8426419 },
    { lat: 48.7041171, lng: 18.842701 },
    { lat: 48.7040966, lng: 18.842743 },
    { lat: 48.7038786, lng: 18.8428856 },
    { lat: 48.7036086, lng: 18.8430747 },
    { lat: 48.7032169, lng: 18.8432171 },
    { lat: 48.7029247, lng: 18.8432771 },
    { lat: 48.7018836, lng: 18.8435546 },
    { lat: 48.7010334, lng: 18.8437107 },
    { lat: 48.7009089, lng: 18.8437004 },
    { lat: 48.7006317, lng: 18.8437914 },
    { lat: 48.7005445, lng: 18.8437825 },
    { lat: 48.6998741, lng: 18.8438141 },
    { lat: 48.6995285, lng: 18.843825 },
    { lat: 48.6986154, lng: 18.8438557 },
    { lat: 48.6984021, lng: 18.8438703 },
    { lat: 48.6976163, lng: 18.8440276 },
    { lat: 48.6971634, lng: 18.8438189 },
    { lat: 48.6968374, lng: 18.843835 },
    { lat: 48.6966167, lng: 18.8440175 },
    { lat: 48.6956349, lng: 18.8436533 },
    { lat: 48.695222, lng: 18.8438468 },
    { lat: 48.6950618, lng: 18.8439111 },
    { lat: 48.6951505, lng: 18.8450959 },
    { lat: 48.6944578, lng: 18.8448057 },
    { lat: 48.6938099, lng: 18.8445151 },
    { lat: 48.6932143, lng: 18.8448591 },
    { lat: 48.6920342, lng: 18.8444756 },
    { lat: 48.6915356, lng: 18.8450585 },
    { lat: 48.6911149, lng: 18.8441001 },
    { lat: 48.6910597, lng: 18.8440153 },
    { lat: 48.6909638, lng: 18.844038 },
    { lat: 48.6909004, lng: 18.8440545 },
    { lat: 48.6898935, lng: 18.8436219 },
    { lat: 48.6899057, lng: 18.8436787 },
    { lat: 48.6897059, lng: 18.8437862 },
    { lat: 48.6897017, lng: 18.8436825 },
    { lat: 48.6895033, lng: 18.8435757 },
    { lat: 48.689496, lng: 18.8434352 },
    { lat: 48.6894915, lng: 18.8433701 },
    { lat: 48.6894904, lng: 18.8433495 },
    { lat: 48.6887943, lng: 18.842735 },
    { lat: 48.6883163, lng: 18.8428455 },
    { lat: 48.6880351, lng: 18.8426618 },
    { lat: 48.6878712, lng: 18.8427422 },
    { lat: 48.6877585, lng: 18.8428105 },
    { lat: 48.6876284, lng: 18.8427346 },
    { lat: 48.6874459, lng: 18.8426734 },
    { lat: 48.6870597, lng: 18.8427491 },
    { lat: 48.6867358, lng: 18.8422457 },
    { lat: 48.6861379, lng: 18.8419804 },
    { lat: 48.6859592, lng: 18.8418206 },
    { lat: 48.6856209, lng: 18.8417536 },
    { lat: 48.685436, lng: 18.8416652 },
    { lat: 48.6852048, lng: 18.841795 },
    { lat: 48.6847676, lng: 18.8417797 },
    { lat: 48.6845666, lng: 18.8415816 },
    { lat: 48.6844016, lng: 18.8415069 },
    { lat: 48.6842398, lng: 18.8416713 },
    { lat: 48.6834117, lng: 18.8415335 },
    { lat: 48.6831241, lng: 18.8415737 },
    { lat: 48.6825852, lng: 18.8413606 },
    { lat: 48.6817702, lng: 18.8408644 },
    { lat: 48.6817023, lng: 18.8407308 },
    { lat: 48.6816879, lng: 18.8406504 },
    { lat: 48.6815937, lng: 18.8403104 },
    { lat: 48.6815331, lng: 18.8401859 },
    { lat: 48.6814705, lng: 18.8401323 },
    { lat: 48.68139, lng: 18.840124 },
    { lat: 48.6813288, lng: 18.8400963 },
    { lat: 48.6811756, lng: 18.8399499 },
    { lat: 48.6811669, lng: 18.8399774 },
    { lat: 48.6811538, lng: 18.8400246 },
    { lat: 48.680483, lng: 18.8400952 },
    { lat: 48.6804713, lng: 18.8401512 },
    { lat: 48.68063, lng: 18.8403001 },
    { lat: 48.6800323, lng: 18.8406667 },
    { lat: 48.6796035, lng: 18.8408773 },
    { lat: 48.6783869, lng: 18.841683 },
    { lat: 48.678189, lng: 18.8415855 },
    { lat: 48.6776427, lng: 18.8420313 },
    { lat: 48.6771856, lng: 18.8420886 },
    { lat: 48.6765176, lng: 18.8419925 },
    { lat: 48.6757667, lng: 18.8416251 },
    { lat: 48.6741927, lng: 18.8425626 },
    { lat: 48.6732436, lng: 18.8421137 },
    { lat: 48.6723914, lng: 18.8436632 },
    { lat: 48.6720479, lng: 18.8440371 },
    { lat: 48.6719697, lng: 18.8440513 },
    { lat: 48.6710296, lng: 18.8436021 },
    { lat: 48.670864, lng: 18.8448633 },
    { lat: 48.6708543, lng: 18.8449418 },
    { lat: 48.6708545, lng: 18.846005 },
    { lat: 48.6702536, lng: 18.8470978 },
    { lat: 48.6702506, lng: 18.8477775 },
    { lat: 48.6699667, lng: 18.848303 },
    { lat: 48.6699785, lng: 18.8486174 },
    { lat: 48.6697863, lng: 18.8494771 },
    { lat: 48.6692173, lng: 18.8497031 },
    { lat: 48.6689092, lng: 18.8490899 },
    { lat: 48.6685256, lng: 18.8488459 },
    { lat: 48.6682599, lng: 18.8489227 },
    { lat: 48.6680515, lng: 18.8492274 },
    { lat: 48.6678103, lng: 18.8495784 },
    { lat: 48.6676585, lng: 18.8507147 },
    { lat: 48.6675004, lng: 18.8510377 },
    { lat: 48.6673456, lng: 18.8511195 },
    { lat: 48.6675104, lng: 18.8512078 },
    { lat: 48.666493, lng: 18.8514429 },
    { lat: 48.6664676, lng: 18.8513899 },
    { lat: 48.6662108, lng: 18.8514146 },
    { lat: 48.665868, lng: 18.8514608 },
    { lat: 48.6656983, lng: 18.8514912 },
    { lat: 48.6655798, lng: 18.8519262 },
    { lat: 48.6655455, lng: 18.8523076 },
    { lat: 48.6656124, lng: 18.8525737 },
    { lat: 48.6648953, lng: 18.8527526 },
    { lat: 48.6644318, lng: 18.8525313 },
    { lat: 48.6637423, lng: 18.852153 },
    { lat: 48.6629507, lng: 18.8513045 },
    { lat: 48.6626653, lng: 18.8507821 },
    { lat: 48.6627434, lng: 18.8498504 },
    { lat: 48.6608936, lng: 18.8505821 },
    { lat: 48.6608238, lng: 18.850107 },
    { lat: 48.6599691, lng: 18.8505854 },
    { lat: 48.6603083, lng: 18.8510793 },
    { lat: 48.6600979, lng: 18.8516963 },
    { lat: 48.6600577, lng: 18.8520745 },
    { lat: 48.6598705, lng: 18.8531396 },
    { lat: 48.6598993, lng: 18.8531929 },
    { lat: 48.6601603, lng: 18.8534231 },
    { lat: 48.6601806, lng: 18.8534646 },
    { lat: 48.6601952, lng: 18.8545287 },
    { lat: 48.6600906, lng: 18.8554056 },
    { lat: 48.6597483, lng: 18.8557393 },
    { lat: 48.659407, lng: 18.8563999 },
    { lat: 48.6589985, lng: 18.8571797 },
    { lat: 48.6583874, lng: 18.8580954 },
    { lat: 48.6578351, lng: 18.8589231 },
    { lat: 48.6575582, lng: 18.859585 },
    { lat: 48.6574951, lng: 18.86011 },
    { lat: 48.6574611, lng: 18.8602834 },
    { lat: 48.657105, lng: 18.860808 },
    { lat: 48.6570569, lng: 18.8609017 },
    { lat: 48.6570352, lng: 18.8609425 },
    { lat: 48.6570086, lng: 18.8609955 },
    { lat: 48.6568149, lng: 18.8613701 },
    { lat: 48.6563656, lng: 18.8622937 },
    { lat: 48.6558465, lng: 18.8632209 },
    { lat: 48.6553663, lng: 18.8641192 },
    { lat: 48.654829, lng: 18.8650225 },
    { lat: 48.6546819, lng: 18.86523 },
    { lat: 48.6539167, lng: 18.8663067 },
    { lat: 48.6536605, lng: 18.8665198 },
    { lat: 48.6536204, lng: 18.8665524 },
    { lat: 48.6537147, lng: 18.8669592 },
    { lat: 48.6537392, lng: 18.8670626 },
    { lat: 48.6537099, lng: 18.8672604 },
    { lat: 48.653137, lng: 18.8678472 },
    { lat: 48.6525802, lng: 18.867981 },
    { lat: 48.6524131, lng: 18.8678445 },
    { lat: 48.6524, lng: 18.8678332 },
    { lat: 48.6521422, lng: 18.8676225 },
    { lat: 48.6518687, lng: 18.8675242 },
    { lat: 48.651826, lng: 18.8675713 },
    { lat: 48.6517848, lng: 18.8679082 },
    { lat: 48.651615, lng: 18.8683287 },
    { lat: 48.6512746, lng: 18.8684002 },
    { lat: 48.6509001, lng: 18.8688102 },
    { lat: 48.6507456, lng: 18.8691583 },
    { lat: 48.6503735, lng: 18.8696568 },
    { lat: 48.6501371, lng: 18.8702128 },
    { lat: 48.6501484, lng: 18.8702985 },
    { lat: 48.6504474, lng: 18.8707169 },
    { lat: 48.6511626, lng: 18.8712453 },
    { lat: 48.6510708, lng: 18.8714997 },
    { lat: 48.6508549, lng: 18.8716642 },
    { lat: 48.6503899, lng: 18.8718302 },
    { lat: 48.6501459, lng: 18.8720594 },
    { lat: 48.649961, lng: 18.8723219 },
    { lat: 48.64939, lng: 18.8727093 },
    { lat: 48.6493354, lng: 18.8727225 },
    { lat: 48.6500646, lng: 18.8732789 },
    { lat: 48.6507101, lng: 18.8740335 },
    { lat: 48.6513286, lng: 18.8749995 },
    { lat: 48.6519813, lng: 18.8748684 },
    { lat: 48.6522707, lng: 18.874992 },
    { lat: 48.6526024, lng: 18.8749494 },
    { lat: 48.6534749, lng: 18.875643 },
    { lat: 48.6536864, lng: 18.8759181 },
    { lat: 48.6537673, lng: 18.8760237 },
    { lat: 48.6540553, lng: 18.876773 },
    { lat: 48.6525769, lng: 18.882055 },
    { lat: 48.6532375, lng: 18.8841916 },
    { lat: 48.6537693, lng: 18.8859122 },
    { lat: 48.6537814, lng: 18.8859504 },
    { lat: 48.6549616, lng: 18.8864886 },
    { lat: 48.6563259, lng: 18.8871208 },
    { lat: 48.6565016, lng: 18.8870969 },
    { lat: 48.6571908, lng: 18.8871415 },
    { lat: 48.6575096, lng: 18.8871158 },
    { lat: 48.6584565, lng: 18.8869803 },
    { lat: 48.6588476, lng: 18.8868024 },
    { lat: 48.6592922, lng: 18.8867409 },
    { lat: 48.6597561, lng: 18.8868601 },
    { lat: 48.6599615, lng: 18.8869262 },
    { lat: 48.6602356, lng: 18.8871697 },
    { lat: 48.6605441, lng: 18.8873371 },
    { lat: 48.6607442, lng: 18.88785 },
    { lat: 48.6609454, lng: 18.8880365 },
    { lat: 48.6610123, lng: 18.8880646 },
    { lat: 48.6613668, lng: 18.8881147 },
    { lat: 48.6613941, lng: 18.8881632 },
    { lat: 48.6614324, lng: 18.8882293 },
    { lat: 48.6617214, lng: 18.8888242 },
    { lat: 48.6617679, lng: 18.8889204 },
    { lat: 48.6625152, lng: 18.8900964 },
    { lat: 48.6625421, lng: 18.8901382 },
    { lat: 48.6626892, lng: 18.8903873 },
    { lat: 48.6628564, lng: 18.8904997 },
    { lat: 48.6633861, lng: 18.890563 },
    { lat: 48.663406, lng: 18.8908389 },
    { lat: 48.6634989, lng: 18.8912204 },
    { lat: 48.6637027, lng: 18.8918239 },
    { lat: 48.6640283, lng: 18.8922836 },
    { lat: 48.6644613, lng: 18.8924315 },
    { lat: 48.664782, lng: 18.8923778 },
    { lat: 48.6650668, lng: 18.8924356 },
    { lat: 48.6654853, lng: 18.892679 },
    { lat: 48.66584, lng: 18.8931054 },
    { lat: 48.6660531, lng: 18.8931692 },
    { lat: 48.6670136, lng: 18.8934727 },
    { lat: 48.6673111, lng: 18.8935366 },
    { lat: 48.6674124, lng: 18.8935322 },
    { lat: 48.6674606, lng: 18.8935266 },
    { lat: 48.6680401, lng: 18.8934828 },
    { lat: 48.66886, lng: 18.893571 },
    { lat: 48.6690697, lng: 18.8936148 },
    { lat: 48.6693787, lng: 18.8937462 },
    { lat: 48.6695445, lng: 18.8937489 },
    { lat: 48.6702904, lng: 18.893763 },
    { lat: 48.6706463, lng: 18.8938604 },
    { lat: 48.6709151, lng: 18.8938481 },
    { lat: 48.6713313, lng: 18.8937646 },
    { lat: 48.6715359, lng: 18.8938187 },
    { lat: 48.6723376, lng: 18.8938823 },
    { lat: 48.672929, lng: 18.8939599 },
    { lat: 48.6731457, lng: 18.8940935 },
    { lat: 48.673389, lng: 18.8940471 },
    { lat: 48.6736537, lng: 18.8940168 },
    { lat: 48.6738644, lng: 18.8941251 },
    { lat: 48.674158, lng: 18.8942009 },
    { lat: 48.674618, lng: 18.8937594 },
    { lat: 48.6750378, lng: 18.8934133 },
    { lat: 48.6754414, lng: 18.8932807 },
    { lat: 48.6760049, lng: 18.8928402 },
    { lat: 48.6764455, lng: 18.8933576 },
    { lat: 48.6769322, lng: 18.8933943 },
    { lat: 48.6771089, lng: 18.8933239 },
    { lat: 48.6773784, lng: 18.8930375 },
    { lat: 48.6773671, lng: 18.8929964 },
    { lat: 48.6772445, lng: 18.8922993 },
    { lat: 48.6779698, lng: 18.8913218 },
    { lat: 48.6781411, lng: 18.8913167 },
    { lat: 48.6785041, lng: 18.890964 },
    { lat: 48.6786012, lng: 18.890924 },
    { lat: 48.6787857, lng: 18.8908956 },
    { lat: 48.6788465, lng: 18.8908506 },
    { lat: 48.6791459, lng: 18.8904476 },
    { lat: 48.6795799, lng: 18.8899192 },
    { lat: 48.6799084, lng: 18.8895534 },
    { lat: 48.6801535, lng: 18.8895817 },
    { lat: 48.6806289, lng: 18.8901242 },
    { lat: 48.6807206, lng: 18.890152 },
    { lat: 48.6808291, lng: 18.8901103 },
    { lat: 48.6811084, lng: 18.8901242 },
    { lat: 48.6815131, lng: 18.8902797 },
    { lat: 48.6819082, lng: 18.8905516 },
    { lat: 48.6821262, lng: 18.8907554 },
    { lat: 48.6823663, lng: 18.8911005 },
    { lat: 48.6831624, lng: 18.8908639 },
    { lat: 48.6833863, lng: 18.8907887 },
    { lat: 48.6836738, lng: 18.8908966 },
    { lat: 48.6838072, lng: 18.8909219 },
    { lat: 48.6843338, lng: 18.8914938 },
    { lat: 48.6844721, lng: 18.8916849 },
    { lat: 48.6845441, lng: 18.8917881 },
    { lat: 48.6846362, lng: 18.8921313 },
    { lat: 48.684743, lng: 18.8919935 },
    { lat: 48.6853431, lng: 18.8923052 },
    { lat: 48.6856231, lng: 18.8924665 },
    { lat: 48.6857158, lng: 18.8923392 },
    { lat: 48.6862364, lng: 18.8913499 },
    { lat: 48.6863382, lng: 18.891137 },
    { lat: 48.6864843, lng: 18.8908219 },
    { lat: 48.6865855, lng: 18.8905538 },
    { lat: 48.6867089, lng: 18.8902095 },
    { lat: 48.6869909, lng: 18.8897303 },
    { lat: 48.687538, lng: 18.8890815 },
    { lat: 48.6876353, lng: 18.8888235 },
    { lat: 48.6882546, lng: 18.8871636 },
    { lat: 48.6885728, lng: 18.886511 },
    { lat: 48.6886966, lng: 18.8862912 },
    { lat: 48.6887247, lng: 18.8860017 },
    { lat: 48.6888854, lng: 18.8852512 },
    { lat: 48.6892428, lng: 18.8843502 },
    { lat: 48.6893792, lng: 18.88411 },
    { lat: 48.6894123, lng: 18.8840551 },
    { lat: 48.6898129, lng: 18.8836218 },
    { lat: 48.6899985, lng: 18.8832434 },
    { lat: 48.6901435, lng: 18.8833188 },
    { lat: 48.6907805, lng: 18.8825547 },
    { lat: 48.6908369, lng: 18.8825106 },
    { lat: 48.6908349, lng: 18.8824726 },
    { lat: 48.6909779, lng: 18.8823359 },
    { lat: 48.6911243, lng: 18.8820906 },
    { lat: 48.6911299, lng: 18.88209 },
    { lat: 48.6913637, lng: 18.8817023 },
    { lat: 48.6914058, lng: 18.8813419 },
    { lat: 48.6916839, lng: 18.8808281 },
    { lat: 48.6918423, lng: 18.8805332 },
    { lat: 48.6919815, lng: 18.8804372 },
    { lat: 48.6922123, lng: 18.8805817 },
    { lat: 48.6927533, lng: 18.880971 },
    { lat: 48.6933528, lng: 18.8813548 },
    { lat: 48.6936943, lng: 18.8816129 },
    { lat: 48.6940046, lng: 18.8817517 },
    { lat: 48.6946808, lng: 18.882095 },
    { lat: 48.6947222, lng: 18.8820459 },
    { lat: 48.6947646, lng: 18.8816622 },
    { lat: 48.6947736, lng: 18.8816176 },
    { lat: 48.694891, lng: 18.8813829 },
    { lat: 48.6950071, lng: 18.8812057 },
    { lat: 48.6952149, lng: 18.8809061 },
    { lat: 48.6956719, lng: 18.8801049 },
    { lat: 48.6957832, lng: 18.8798573 },
    { lat: 48.6960017, lng: 18.8795671 },
    { lat: 48.6963131, lng: 18.8792417 },
    { lat: 48.6964481, lng: 18.8790419 },
    { lat: 48.696673, lng: 18.8787667 },
    { lat: 48.6972463, lng: 18.8781859 },
    { lat: 48.6972321, lng: 18.8780855 },
    { lat: 48.6974393, lng: 18.8781268 },
    { lat: 48.6975572, lng: 18.877939 },
    { lat: 48.6976208, lng: 18.8778619 },
    { lat: 48.6977194, lng: 18.8777618 },
    { lat: 48.6978621, lng: 18.8772481 },
    { lat: 48.6979048, lng: 18.8768305 },
    { lat: 48.6980027, lng: 18.8763692 },
    { lat: 48.6981239, lng: 18.8760497 },
    { lat: 48.6983358, lng: 18.8757424 },
    { lat: 48.6983116, lng: 18.875539 },
    { lat: 48.698558, lng: 18.8752868 },
    { lat: 48.6986406, lng: 18.8751836 },
    { lat: 48.698799, lng: 18.8751306 },
    { lat: 48.6993433, lng: 18.8751418 },
    { lat: 48.6992596, lng: 18.8750082 },
    { lat: 48.6992409, lng: 18.8745135 },
    { lat: 48.6992148, lng: 18.8738102 },
    { lat: 48.6990954, lng: 18.8731607 },
    { lat: 48.6990722, lng: 18.8730171 },
    { lat: 48.6989887, lng: 18.872686 },
    { lat: 48.6989443, lng: 18.8722204 },
    { lat: 48.6988188, lng: 18.872151 },
    { lat: 48.6988839, lng: 18.8720105 },
    { lat: 48.6988942, lng: 18.8718401 },
    { lat: 48.6988424, lng: 18.8716477 },
    { lat: 48.698832, lng: 18.8714466 },
    { lat: 48.6988219, lng: 18.8712275 },
    { lat: 48.6988669, lng: 18.8708777 },
    { lat: 48.699183, lng: 18.8708405 },
    { lat: 48.6993958, lng: 18.8708975 },
    { lat: 48.6994727, lng: 18.8708574 },
    { lat: 48.6995197, lng: 18.8708297 },
    { lat: 48.6996728, lng: 18.8710222 },
    { lat: 48.6997681, lng: 18.8710781 },
    { lat: 48.6998823, lng: 18.8711257 },
    { lat: 48.700067, lng: 18.8710325 },
    { lat: 48.7003149, lng: 18.8709012 },
    { lat: 48.7005307, lng: 18.8710396 },
    { lat: 48.7006219, lng: 18.8709932 },
    { lat: 48.7006825, lng: 18.8710328 },
    { lat: 48.7007184, lng: 18.8713559 },
    { lat: 48.7007692, lng: 18.8714438 },
    { lat: 48.7013646, lng: 18.8715746 },
    { lat: 48.70137, lng: 18.8717667 },
    { lat: 48.7016781, lng: 18.8717293 },
    { lat: 48.7019336, lng: 18.8715442 },
    { lat: 48.7020558, lng: 18.8716408 },
    { lat: 48.7022097, lng: 18.8721549 },
    { lat: 48.7023257, lng: 18.8722826 },
    { lat: 48.7025775, lng: 18.8722212 },
    { lat: 48.7028022, lng: 18.8720744 },
    { lat: 48.7030564, lng: 18.8723243 },
    { lat: 48.7030832, lng: 18.8726053 },
    { lat: 48.7034102, lng: 18.8726053 },
    { lat: 48.7036226, lng: 18.8728059 },
    { lat: 48.7037123, lng: 18.8730163 },
    { lat: 48.7039217, lng: 18.8731633 },
    { lat: 48.7041784, lng: 18.8732205 },
    { lat: 48.704193, lng: 18.8731545 },
    { lat: 48.7042339, lng: 18.8730733 },
    { lat: 48.7042619, lng: 18.8730065 },
    { lat: 48.7045305, lng: 18.8723736 },
    { lat: 48.7046742, lng: 18.8718387 },
    { lat: 48.7045415, lng: 18.871255 },
    { lat: 48.7045648, lng: 18.8707699 },
    { lat: 48.7046657, lng: 18.8702332 },
    { lat: 48.7047774, lng: 18.8696471 },
    { lat: 48.70496, lng: 18.868466 },
    { lat: 48.7051774, lng: 18.8669363 },
    { lat: 48.7052914, lng: 18.8663481 },
    { lat: 48.7054311, lng: 18.865171 },
    { lat: 48.7054368, lng: 18.8646448 },
    { lat: 48.7054538, lng: 18.8644939 },
    { lat: 48.7055243, lng: 18.8639197 },
    { lat: 48.7055251, lng: 18.8636533 },
    { lat: 48.7055231, lng: 18.8630242 },
    { lat: 48.705522, lng: 18.8611729 },
    { lat: 48.7057782, lng: 18.8593661 },
    { lat: 48.7058305, lng: 18.8583718 },
    { lat: 48.7058862, lng: 18.8572931 },
    { lat: 48.7061237, lng: 18.8562629 },
  ],
  Jastrabá: [
    { lat: 48.6630886, lng: 18.945323 },
    { lat: 48.6632621, lng: 18.9450753 },
    { lat: 48.6646903, lng: 18.9435009 },
    { lat: 48.6646994, lng: 18.9433921 },
    { lat: 48.664738, lng: 18.9417194 },
    { lat: 48.6647081, lng: 18.9416415 },
    { lat: 48.6646797, lng: 18.9415713 },
    { lat: 48.6641672, lng: 18.9404144 },
    { lat: 48.6640676, lng: 18.9402098 },
    { lat: 48.6639552, lng: 18.9399468 },
    { lat: 48.6633517, lng: 18.9377821 },
    { lat: 48.6625023, lng: 18.9381286 },
    { lat: 48.6622216, lng: 18.9383696 },
    { lat: 48.6620279, lng: 18.9387959 },
    { lat: 48.6617314, lng: 18.938971 },
    { lat: 48.6615359, lng: 18.9391172 },
    { lat: 48.6614861, lng: 18.9391372 },
    { lat: 48.661222, lng: 18.939239 },
    { lat: 48.6607788, lng: 18.9393519 },
    { lat: 48.6604713, lng: 18.9394644 },
    { lat: 48.6600951, lng: 18.9395607 },
    { lat: 48.6598276, lng: 18.939579 },
    { lat: 48.6595138, lng: 18.9393969 },
    { lat: 48.6590972, lng: 18.9394525 },
    { lat: 48.6586437, lng: 18.9396437 },
    { lat: 48.6579858, lng: 18.9396151 },
    { lat: 48.657003, lng: 18.9396661 },
    { lat: 48.6565391, lng: 18.9396335 },
    { lat: 48.6561767, lng: 18.93938 },
    { lat: 48.655956, lng: 18.9390138 },
    { lat: 48.6556027, lng: 18.9386063 },
    { lat: 48.6551886, lng: 18.9385561 },
    { lat: 48.6550498, lng: 18.938467 },
    { lat: 48.6549836, lng: 18.9385172 },
    { lat: 48.6549228, lng: 18.9384968 },
    { lat: 48.6547856, lng: 18.9383502 },
    { lat: 48.6547305, lng: 18.9382892 },
    { lat: 48.6545348, lng: 18.9383623 },
    { lat: 48.6543977, lng: 18.938369 },
    { lat: 48.6542503, lng: 18.9383105 },
    { lat: 48.6541702, lng: 18.9382222 },
    { lat: 48.6540731, lng: 18.938287 },
    { lat: 48.653893, lng: 18.9382673 },
    { lat: 48.6538195, lng: 18.9381974 },
    { lat: 48.6536966, lng: 18.9381967 },
    { lat: 48.6535872, lng: 18.9381328 },
    { lat: 48.6535445, lng: 18.9380689 },
    { lat: 48.6534856, lng: 18.9381503 },
    { lat: 48.6532643, lng: 18.9384108 },
    { lat: 48.6530914, lng: 18.9382501 },
    { lat: 48.6528039, lng: 18.9376748 },
    { lat: 48.6520489, lng: 18.9371352 },
    { lat: 48.6516337, lng: 18.9367335 },
    { lat: 48.651191, lng: 18.9367391 },
    { lat: 48.6509739, lng: 18.9366381 },
    { lat: 48.6504486, lng: 18.9359811 },
    { lat: 48.6500641, lng: 18.9358034 },
    { lat: 48.6496832, lng: 18.935411 },
    { lat: 48.6496003, lng: 18.9346497 },
    { lat: 48.6495104, lng: 18.9338443 },
    { lat: 48.6496979, lng: 18.9330562 },
    { lat: 48.6496534, lng: 18.931809 },
    { lat: 48.6499069, lng: 18.9308391 },
    { lat: 48.6500485, lng: 18.9299689 },
    { lat: 48.6499238, lng: 18.9294514 },
    { lat: 48.6497522, lng: 18.9284776 },
    { lat: 48.6494351, lng: 18.9276381 },
    { lat: 48.6492089, lng: 18.9269863 },
    { lat: 48.6491001, lng: 18.9257388 },
    { lat: 48.6490573, lng: 18.9245289 },
    { lat: 48.6489144, lng: 18.9245581 },
    { lat: 48.6488444, lng: 18.9245791 },
    { lat: 48.6477546, lng: 18.9248557 },
    { lat: 48.6470913, lng: 18.924476 },
    { lat: 48.6469256, lng: 18.9241017 },
    { lat: 48.6468882, lng: 18.9241406 },
    { lat: 48.6463384, lng: 18.9227639 },
    { lat: 48.646229, lng: 18.9224783 },
    { lat: 48.6460846, lng: 18.922232 },
    { lat: 48.6460623, lng: 18.9221932 },
    { lat: 48.6458484, lng: 18.9217832 },
    { lat: 48.6458112, lng: 18.9214531 },
    { lat: 48.6457944, lng: 18.9213003 },
    { lat: 48.6458153, lng: 18.9209848 },
    { lat: 48.6457937, lng: 18.9207381 },
    { lat: 48.6457366, lng: 18.9205044 },
    { lat: 48.6456233, lng: 18.9203825 },
    { lat: 48.645306, lng: 18.9202869 },
    { lat: 48.6451111, lng: 18.9201171 },
    { lat: 48.6447675, lng: 18.9198342 },
    { lat: 48.6445698, lng: 18.9191688 },
    { lat: 48.6441762, lng: 18.9185053 },
    { lat: 48.6436746, lng: 18.9176957 },
    { lat: 48.6422258, lng: 18.9166663 },
    { lat: 48.6416604, lng: 18.9164589 },
    { lat: 48.6413218, lng: 18.9166065 },
    { lat: 48.6407557, lng: 18.916781 },
    { lat: 48.6395827, lng: 18.9158728 },
    { lat: 48.6390258, lng: 18.9155468 },
    { lat: 48.6388636, lng: 18.9154841 },
    { lat: 48.6384087, lng: 18.9154553 },
    { lat: 48.636958, lng: 18.9152223 },
    { lat: 48.6356554, lng: 18.9140951 },
    { lat: 48.6353431, lng: 18.9137691 },
    { lat: 48.6345901, lng: 18.9129837 },
    { lat: 48.6344464, lng: 18.9137423 },
    { lat: 48.6339308, lng: 18.9145045 },
    { lat: 48.6336082, lng: 18.9148071 },
    { lat: 48.6335023, lng: 18.9148523 },
    { lat: 48.6332853, lng: 18.914863 },
    { lat: 48.6331368, lng: 18.9148701 },
    { lat: 48.6330903, lng: 18.9148673 },
    { lat: 48.6330744, lng: 18.9148652 },
    { lat: 48.6330281, lng: 18.9148607 },
    { lat: 48.6326585, lng: 18.914824 },
    { lat: 48.6320566, lng: 18.9146962 },
    { lat: 48.6312958, lng: 18.9144807 },
    { lat: 48.6310595, lng: 18.9143611 },
    { lat: 48.6308234, lng: 18.9142414 },
    { lat: 48.6299052, lng: 18.9137766 },
    { lat: 48.6293879, lng: 18.914658 },
    { lat: 48.6293383, lng: 18.9147904 },
    { lat: 48.6292997, lng: 18.9148954 },
    { lat: 48.6289632, lng: 18.9158048 },
    { lat: 48.6287403, lng: 18.9164072 },
    { lat: 48.628052, lng: 18.9176482 },
    { lat: 48.6276491, lng: 18.9180574 },
    { lat: 48.6275714, lng: 18.9181513 },
    { lat: 48.6261083, lng: 18.9199165 },
    { lat: 48.6252208, lng: 18.9205943 },
    { lat: 48.6221878, lng: 18.9213233 },
    { lat: 48.6220541, lng: 18.9213557 },
    { lat: 48.6215999, lng: 18.9213599 },
    { lat: 48.6209335, lng: 18.921293 },
    { lat: 48.6206428, lng: 18.9214296 },
    { lat: 48.6205047, lng: 18.9214831 },
    { lat: 48.6203997, lng: 18.9215909 },
    { lat: 48.6202882, lng: 18.9217906 },
    { lat: 48.6197481, lng: 18.9228448 },
    { lat: 48.6193009, lng: 18.9236671 },
    { lat: 48.6188563, lng: 18.9244103 },
    { lat: 48.6186745, lng: 18.924842 },
    { lat: 48.6186675, lng: 18.9249908 },
    { lat: 48.618368, lng: 18.9254387 },
    { lat: 48.6181228, lng: 18.9258276 },
    { lat: 48.6179879, lng: 18.9261185 },
    { lat: 48.6178812, lng: 18.9264149 },
    { lat: 48.617748, lng: 18.9268499 },
    { lat: 48.6176152, lng: 18.927339 },
    { lat: 48.6175922, lng: 18.927419 },
    { lat: 48.6175336, lng: 18.9276411 },
    { lat: 48.6170585, lng: 18.9288628 },
    { lat: 48.616857, lng: 18.9292245 },
    { lat: 48.6168328, lng: 18.929259 },
    { lat: 48.6167881, lng: 18.9293512 },
    { lat: 48.6165617, lng: 18.9300828 },
    { lat: 48.6161703, lng: 18.9314448 },
    { lat: 48.6160334, lng: 18.9318766 },
    { lat: 48.6160214, lng: 18.9319054 },
    { lat: 48.6158962, lng: 18.9323027 },
    { lat: 48.615853, lng: 18.9323181 },
    { lat: 48.6159361, lng: 18.9338952 },
    { lat: 48.6163059, lng: 18.9350171 },
    { lat: 48.6163136, lng: 18.9356747 },
    { lat: 48.6163697, lng: 18.9362448 },
    { lat: 48.6163466, lng: 18.9368504 },
    { lat: 48.6163726, lng: 18.9370252 },
    { lat: 48.6166803, lng: 18.9372905 },
    { lat: 48.6167706, lng: 18.9375544 },
    { lat: 48.6168061, lng: 18.93781 },
    { lat: 48.6168156, lng: 18.9380605 },
    { lat: 48.6167251, lng: 18.9383822 },
    { lat: 48.6169118, lng: 18.9387941 },
    { lat: 48.6170209, lng: 18.9391399 },
    { lat: 48.6172647, lng: 18.9393649 },
    { lat: 48.6174083, lng: 18.9397951 },
    { lat: 48.6174706, lng: 18.9402172 },
    { lat: 48.6176378, lng: 18.9403546 },
    { lat: 48.6177031, lng: 18.9405095 },
    { lat: 48.6177265, lng: 18.9412754 },
    { lat: 48.61785, lng: 18.9414182 },
    { lat: 48.6180004, lng: 18.9418918 },
    { lat: 48.6181043, lng: 18.942024 },
    { lat: 48.6181329, lng: 18.9422331 },
    { lat: 48.6179795, lng: 18.9425179 },
    { lat: 48.6178892, lng: 18.9425394 },
    { lat: 48.6178288, lng: 18.9428151 },
    { lat: 48.6175758, lng: 18.9432683 },
    { lat: 48.617382, lng: 18.9435128 },
    { lat: 48.6173697, lng: 18.9440894 },
    { lat: 48.6171605, lng: 18.9449575 },
    { lat: 48.6171411, lng: 18.9452855 },
    { lat: 48.6173976, lng: 18.9462214 },
    { lat: 48.6174403, lng: 18.9467471 },
    { lat: 48.6173915, lng: 18.9471429 },
    { lat: 48.6174281, lng: 18.9474797 },
    { lat: 48.6174493, lng: 18.9476779 },
    { lat: 48.6174835, lng: 18.9485652 },
    { lat: 48.6174531, lng: 18.9490298 },
    { lat: 48.6173991, lng: 18.9494755 },
    { lat: 48.6173646, lng: 18.9507659 },
    { lat: 48.6173783, lng: 18.9509012 },
    { lat: 48.6174083, lng: 18.9510028 },
    { lat: 48.6173725, lng: 18.9511841 },
    { lat: 48.6173923, lng: 18.9513974 },
    { lat: 48.6173762, lng: 18.9514783 },
    { lat: 48.6173833, lng: 18.9515955 },
    { lat: 48.61735, lng: 18.9517152 },
    { lat: 48.6173453, lng: 18.9520214 },
    { lat: 48.6173425, lng: 18.952253 },
    { lat: 48.6174901, lng: 18.952119 },
    { lat: 48.6181341, lng: 18.9525656 },
    { lat: 48.618324, lng: 18.952597 },
    { lat: 48.6186733, lng: 18.9524791 },
    { lat: 48.6190378, lng: 18.952398 },
    { lat: 48.6192597, lng: 18.9523943 },
    { lat: 48.6192765, lng: 18.9524144 },
    { lat: 48.6193951, lng: 18.9524602 },
    { lat: 48.6200718, lng: 18.9529978 },
    { lat: 48.6203195, lng: 18.9531234 },
    { lat: 48.6203623, lng: 18.9531423 },
    { lat: 48.6205508, lng: 18.9531934 },
    { lat: 48.6205936, lng: 18.9531464 },
    { lat: 48.6206205, lng: 18.9532071 },
    { lat: 48.6208036, lng: 18.9532628 },
    { lat: 48.62105, lng: 18.95334 },
    { lat: 48.6211429, lng: 18.9533688 },
    { lat: 48.62137, lng: 18.9533136 },
    { lat: 48.6216889, lng: 18.9532199 },
    { lat: 48.6217499, lng: 18.9532256 },
    { lat: 48.6218321, lng: 18.9531783 },
    { lat: 48.6219751, lng: 18.9532177 },
    { lat: 48.6222966, lng: 18.9535724 },
    { lat: 48.6224999, lng: 18.9537776 },
    { lat: 48.6227465, lng: 18.9537953 },
    { lat: 48.6231357, lng: 18.9536678 },
    { lat: 48.6233186, lng: 18.9534277 },
    { lat: 48.6234647, lng: 18.9533541 },
    { lat: 48.6236714, lng: 18.953339 },
    { lat: 48.6239045, lng: 18.9533757 },
    { lat: 48.6241704, lng: 18.9533944 },
    { lat: 48.6243027, lng: 18.9535106 },
    { lat: 48.6243906, lng: 18.9534701 },
    { lat: 48.6245808, lng: 18.9532602 },
    { lat: 48.6247661, lng: 18.9532012 },
    { lat: 48.624972, lng: 18.9532821 },
    { lat: 48.6253322, lng: 18.953581 },
    { lat: 48.6256852, lng: 18.9535814 },
    { lat: 48.625987, lng: 18.9534209 },
    { lat: 48.6261898, lng: 18.9532612 },
    { lat: 48.6263236, lng: 18.9535632 },
    { lat: 48.6264304, lng: 18.9537109 },
    { lat: 48.6264685, lng: 18.9537538 },
    { lat: 48.6266223, lng: 18.9539819 },
    { lat: 48.6266929, lng: 18.954086 },
    { lat: 48.6267333, lng: 18.9541476 },
    { lat: 48.6269948, lng: 18.9542805 },
    { lat: 48.6269984, lng: 18.9542815 },
    { lat: 48.6271584, lng: 18.9543242 },
    { lat: 48.6271984, lng: 18.9543342 },
    { lat: 48.6273021, lng: 18.9544444 },
    { lat: 48.6275321, lng: 18.9546875 },
    { lat: 48.6276539, lng: 18.9546912 },
    { lat: 48.6278506, lng: 18.9546969 },
    { lat: 48.6281474, lng: 18.954856 },
    { lat: 48.6282426, lng: 18.9548877 },
    { lat: 48.6285188, lng: 18.9550224 },
    { lat: 48.6286849, lng: 18.9551618 },
    { lat: 48.6288265, lng: 18.9551724 },
    { lat: 48.62903, lng: 18.9551827 },
    { lat: 48.6292001, lng: 18.9552934 },
    { lat: 48.629414, lng: 18.9553744 },
    { lat: 48.6295705, lng: 18.9553466 },
    { lat: 48.6297466, lng: 18.9553305 },
    { lat: 48.6297561, lng: 18.9553404 },
    { lat: 48.6297434, lng: 18.9552453 },
    { lat: 48.6301092, lng: 18.9551672 },
    { lat: 48.6305404, lng: 18.9552405 },
    { lat: 48.6308204, lng: 18.9550942 },
    { lat: 48.6310383, lng: 18.9549884 },
    { lat: 48.6312639, lng: 18.9550798 },
    { lat: 48.6316068, lng: 18.9552575 },
    { lat: 48.6319253, lng: 18.955424 },
    { lat: 48.6322305, lng: 18.9555715 },
    { lat: 48.6326464, lng: 18.955726 },
    { lat: 48.6330868, lng: 18.9556934 },
    { lat: 48.6335684, lng: 18.9557185 },
    { lat: 48.6336625, lng: 18.9557282 },
    { lat: 48.6339368, lng: 18.9557548 },
    { lat: 48.6342323, lng: 18.9556706 },
    { lat: 48.6345003, lng: 18.9556164 },
    { lat: 48.6345347, lng: 18.9556095 },
    { lat: 48.6347853, lng: 18.9555712 },
    { lat: 48.634872, lng: 18.9555247 },
    { lat: 48.6350411, lng: 18.9554352 },
    { lat: 48.6350729, lng: 18.9554148 },
    { lat: 48.6351303, lng: 18.9553924 },
    { lat: 48.6355567, lng: 18.9550424 },
    { lat: 48.6360796, lng: 18.9549507 },
    { lat: 48.6365284, lng: 18.9549492 },
    { lat: 48.6367105, lng: 18.9549388 },
    { lat: 48.6368735, lng: 18.9548864 },
    { lat: 48.6370145, lng: 18.9547324 },
    { lat: 48.6370934, lng: 18.9544633 },
    { lat: 48.6372858, lng: 18.9543251 },
    { lat: 48.6374675, lng: 18.9542606 },
    { lat: 48.6377327, lng: 18.9543339 },
    { lat: 48.6381911, lng: 18.9543658 },
    { lat: 48.6384098, lng: 18.9543226 },
    { lat: 48.638708, lng: 18.9543547 },
    { lat: 48.6390535, lng: 18.9544953 },
    { lat: 48.6393272, lng: 18.9546236 },
    { lat: 48.6395723, lng: 18.9548094 },
    { lat: 48.6396973, lng: 18.9550284 },
    { lat: 48.6400787, lng: 18.956049 },
    { lat: 48.6403437, lng: 18.9561865 },
    { lat: 48.6405849, lng: 18.9562031 },
    { lat: 48.6407638, lng: 18.9565937 },
    { lat: 48.6409407, lng: 18.9568566 },
    { lat: 48.6412186, lng: 18.9570654 },
    { lat: 48.6414074, lng: 18.957252 },
    { lat: 48.6415945, lng: 18.9574275 },
    { lat: 48.6417698, lng: 18.9573961 },
    { lat: 48.6420627, lng: 18.9572131 },
    { lat: 48.6422035, lng: 18.9571889 },
    { lat: 48.6423768, lng: 18.9572472 },
    { lat: 48.6426244, lng: 18.9572612 },
    { lat: 48.6430257, lng: 18.9570817 },
    { lat: 48.6433323, lng: 18.956752 },
    { lat: 48.6436553, lng: 18.9563401 },
    { lat: 48.6440774, lng: 18.956018 },
    { lat: 48.6442649, lng: 18.9558447 },
    { lat: 48.6442765, lng: 18.9555435 },
    { lat: 48.6445997, lng: 18.9552692 },
    { lat: 48.6448608, lng: 18.9549968 },
    { lat: 48.6450002, lng: 18.9549236 },
    { lat: 48.6452063, lng: 18.9546975 },
    { lat: 48.6455402, lng: 18.954786 },
    { lat: 48.6457236, lng: 18.9545789 },
    { lat: 48.6460635, lng: 18.9541687 },
    { lat: 48.646609, lng: 18.9537698 },
    { lat: 48.6470317, lng: 18.9535014 },
    { lat: 48.6472293, lng: 18.9534845 },
    { lat: 48.6474444, lng: 18.9533896 },
    { lat: 48.6475143, lng: 18.9534568 },
    { lat: 48.647665, lng: 18.9536051 },
    { lat: 48.647988, lng: 18.9538371 },
    { lat: 48.6484218, lng: 18.9537625 },
    { lat: 48.6488409, lng: 18.9540875 },
    { lat: 48.6491635, lng: 18.9541124 },
    { lat: 48.6494379, lng: 18.953965 },
    { lat: 48.649619, lng: 18.9536067 },
    { lat: 48.6496815, lng: 18.9533904 },
    { lat: 48.6496158, lng: 18.9528575 },
    { lat: 48.6494879, lng: 18.9519856 },
    { lat: 48.6497251, lng: 18.9514613 },
    { lat: 48.6500436, lng: 18.9512071 },
    { lat: 48.6504506, lng: 18.9514034 },
    { lat: 48.6509105, lng: 18.9516655 },
    { lat: 48.6512415, lng: 18.9519901 },
    { lat: 48.6521495, lng: 18.9522636 },
    { lat: 48.6524818, lng: 18.9522321 },
    { lat: 48.6527384, lng: 18.9522244 },
    { lat: 48.6530034, lng: 18.9522446 },
    { lat: 48.6532108, lng: 18.9521385 },
    { lat: 48.6534059, lng: 18.9519839 },
    { lat: 48.6539491, lng: 18.9516876 },
    { lat: 48.6542573, lng: 18.9516988 },
    { lat: 48.6544674, lng: 18.9516166 },
    { lat: 48.6545723, lng: 18.9515513 },
    { lat: 48.654805, lng: 18.9513381 },
    { lat: 48.6550898, lng: 18.951238 },
    { lat: 48.6550858, lng: 18.9512053 },
    { lat: 48.6550807, lng: 18.9511517 },
    { lat: 48.6550791, lng: 18.9511401 },
    { lat: 48.65506, lng: 18.9510096 },
    { lat: 48.6550228, lng: 18.9507447 },
    { lat: 48.6553396, lng: 18.9505906 },
    { lat: 48.6553177, lng: 18.9504665 },
    { lat: 48.6555817, lng: 18.9505143 },
    { lat: 48.6560687, lng: 18.9501827 },
    { lat: 48.656401, lng: 18.9499576 },
    { lat: 48.6569313, lng: 18.9497647 },
    { lat: 48.6576641, lng: 18.9494983 },
    { lat: 48.6579264, lng: 18.9494026 },
    { lat: 48.6584934, lng: 18.9491972 },
    { lat: 48.65854, lng: 18.9477499 },
    { lat: 48.6592656, lng: 18.947561 },
    { lat: 48.6592089, lng: 18.9468707 },
    { lat: 48.6595792, lng: 18.946635 },
    { lat: 48.660062, lng: 18.9463549 },
    { lat: 48.6604337, lng: 18.94595 },
    { lat: 48.6607614, lng: 18.9454126 },
    { lat: 48.6615166, lng: 18.9454281 },
    { lat: 48.6617393, lng: 18.9455503 },
    { lat: 48.6620086, lng: 18.9456784 },
    { lat: 48.6623206, lng: 18.9456499 },
    { lat: 48.6626871, lng: 18.9454971 },
    { lat: 48.6630886, lng: 18.945323 },
  ],
  ŽiarnadHronom: [
    { lat: 48.5805106, lng: 18.858798 },
    { lat: 48.5804399, lng: 18.858978 },
    { lat: 48.5804031, lng: 18.859071 },
    { lat: 48.5803759, lng: 18.8591572 },
    { lat: 48.5803168, lng: 18.8593437 },
    { lat: 48.5802461, lng: 18.8595653 },
    { lat: 48.5801093, lng: 18.8601249 },
    { lat: 48.5799668, lng: 18.8608589 },
    { lat: 48.5799193, lng: 18.8611586 },
    { lat: 48.5799025, lng: 18.8612689 },
    { lat: 48.5798319, lng: 18.8612551 },
    { lat: 48.57976, lng: 18.8612411 },
    { lat: 48.5792743, lng: 18.8611378 },
    { lat: 48.5789828, lng: 18.8610809 },
    { lat: 48.5788757, lng: 18.8610605 },
    { lat: 48.57878, lng: 18.8610422 },
    { lat: 48.578764, lng: 18.8610163 },
    { lat: 48.5786554, lng: 18.8607036 },
    { lat: 48.5785052, lng: 18.8603969 },
    { lat: 48.5784428, lng: 18.8603227 },
    { lat: 48.5783764, lng: 18.8602411 },
    { lat: 48.5782912, lng: 18.860139 },
    { lat: 48.5781688, lng: 18.8599974 },
    { lat: 48.5780875, lng: 18.8598867 },
    { lat: 48.5780739, lng: 18.859863 },
    { lat: 48.578222, lng: 18.859697 },
    { lat: 48.5782687, lng: 18.859641 },
    { lat: 48.5781353, lng: 18.8593746 },
    { lat: 48.5781184, lng: 18.8593937 },
    { lat: 48.5780884, lng: 18.8594272 },
    { lat: 48.578087, lng: 18.8594227 },
    { lat: 48.5780328, lng: 18.8594836 },
    { lat: 48.5780062, lng: 18.8595141 },
    { lat: 48.5779037, lng: 18.8596291 },
    { lat: 48.5778941, lng: 18.8596399 },
    { lat: 48.5778392, lng: 18.8596015 },
    { lat: 48.5777808, lng: 18.8595603 },
    { lat: 48.5777666, lng: 18.8595502 },
    { lat: 48.5777096, lng: 18.8595163 },
    { lat: 48.5775829, lng: 18.8596584 },
    { lat: 48.5775854, lng: 18.8596642 },
    { lat: 48.5776031, lng: 18.8597005 },
    { lat: 48.5775635, lng: 18.8597444 },
    { lat: 48.577546, lng: 18.8597072 },
    { lat: 48.5775423, lng: 18.8597034 },
    { lat: 48.577502, lng: 18.8596202 },
    { lat: 48.577449, lng: 18.8595143 },
    { lat: 48.5773949, lng: 18.8594035 },
    { lat: 48.5773872, lng: 18.8593887 },
    { lat: 48.5772706, lng: 18.8593537 },
    { lat: 48.5771914, lng: 18.8593299 },
    { lat: 48.5772178, lng: 18.8593838 },
    { lat: 48.5771231, lng: 18.85949 },
    { lat: 48.5770338, lng: 18.8593132 },
    { lat: 48.5770237, lng: 18.8593144 },
    { lat: 48.5770181, lng: 18.8593151 },
    { lat: 48.5770148, lng: 18.8593155 },
    { lat: 48.5770125, lng: 18.8593157 },
    { lat: 48.5769958, lng: 18.8593177 },
    { lat: 48.5769948, lng: 18.8593207 },
    { lat: 48.5769855, lng: 18.8593374 },
    { lat: 48.5769617, lng: 18.8593353 },
    { lat: 48.576956, lng: 18.8593345 },
    { lat: 48.5769485, lng: 18.859345 },
    { lat: 48.5768971, lng: 18.8592424 },
    { lat: 48.5768941, lng: 18.8592467 },
    { lat: 48.5768865, lng: 18.8592555 },
    { lat: 48.57688, lng: 18.859263 },
    { lat: 48.5768758, lng: 18.8592678 },
    { lat: 48.5768205, lng: 18.859332 },
    { lat: 48.576444, lng: 18.859767 },
    { lat: 48.5764065, lng: 18.8596952 },
    { lat: 48.5761904, lng: 18.859945 },
    { lat: 48.5762268, lng: 18.8600169 },
    { lat: 48.5761704, lng: 18.8600823 },
    { lat: 48.5761757, lng: 18.8600931 },
    { lat: 48.5763802, lng: 18.8604959 },
    { lat: 48.5764431, lng: 18.8606203 },
    { lat: 48.5764152, lng: 18.8607792 },
    { lat: 48.5764178, lng: 18.8607838 },
    { lat: 48.5764135, lng: 18.8607883 },
    { lat: 48.5763917, lng: 18.860913 },
    { lat: 48.5763858, lng: 18.8609504 },
    { lat: 48.5763842, lng: 18.8609655 },
    { lat: 48.5763838, lng: 18.8609752 },
    { lat: 48.5763685, lng: 18.8612273 },
    { lat: 48.5763378, lng: 18.8617191 },
    { lat: 48.5762919, lng: 18.861946 },
    { lat: 48.5762766, lng: 18.8620212 },
    { lat: 48.576275, lng: 18.8620306 },
    { lat: 48.5762568, lng: 18.8621172 },
    { lat: 48.5762543, lng: 18.8621324 },
    { lat: 48.5762275, lng: 18.862267 },
    { lat: 48.5762538, lng: 18.8623187 },
    { lat: 48.5762977, lng: 18.8624043 },
    { lat: 48.5762285, lng: 18.8624833 },
    { lat: 48.5762094, lng: 18.8625059 },
    { lat: 48.5761699, lng: 18.8625515 },
    { lat: 48.5761557, lng: 18.862624 },
    { lat: 48.5761513, lng: 18.8626477 },
    { lat: 48.5761385, lng: 18.8627058 },
    { lat: 48.5761323, lng: 18.8627372 },
    { lat: 48.5761169, lng: 18.8628316 },
    { lat: 48.5761132, lng: 18.862848 },
    { lat: 48.5760962, lng: 18.8629515 },
    { lat: 48.5760771, lng: 18.8630617 },
    { lat: 48.5760695, lng: 18.8630704 },
    { lat: 48.5760732, lng: 18.8630789 },
    { lat: 48.5760586, lng: 18.8631653 },
    { lat: 48.5765002, lng: 18.8640256 },
    { lat: 48.5765182, lng: 18.8640046 },
    { lat: 48.5765508, lng: 18.8640677 },
    { lat: 48.576566, lng: 18.8640976 },
    { lat: 48.5765447, lng: 18.8641229 },
    { lat: 48.5765686, lng: 18.8641707 },
    { lat: 48.5765899, lng: 18.8642123 },
    { lat: 48.5765942, lng: 18.8642083 },
    { lat: 48.5769376, lng: 18.8638075 },
    { lat: 48.5770393, lng: 18.8640052 },
    { lat: 48.5769255, lng: 18.864139 },
    { lat: 48.5770195, lng: 18.8643209 },
    { lat: 48.5769293, lng: 18.864427 },
    { lat: 48.576894, lng: 18.8643571 },
    { lat: 48.576879, lng: 18.8643734 },
    { lat: 48.5768903, lng: 18.8643949 },
    { lat: 48.5768691, lng: 18.8644191 },
    { lat: 48.5768578, lng: 18.8643973 },
    { lat: 48.5767505, lng: 18.8645242 },
    { lat: 48.5766927, lng: 18.8644121 },
    { lat: 48.5765577, lng: 18.8645701 },
    { lat: 48.5765546, lng: 18.8645737 },
    { lat: 48.5760364, lng: 18.8635625 },
    { lat: 48.5760268, lng: 18.8637466 },
    { lat: 48.5760326, lng: 18.8637944 },
    { lat: 48.5760342, lng: 18.8638245 },
    { lat: 48.5760401, lng: 18.8639395 },
    { lat: 48.5760457, lng: 18.8640471 },
    { lat: 48.5760634, lng: 18.8643196 },
    { lat: 48.5761099, lng: 18.8644149 },
    { lat: 48.5762029, lng: 18.8645763 },
    { lat: 48.5763607, lng: 18.8648582 },
    { lat: 48.5765062, lng: 18.8649906 },
    { lat: 48.5765702, lng: 18.8650486 },
    { lat: 48.576756, lng: 18.8652187 },
    { lat: 48.5767628, lng: 18.8653493 },
    { lat: 48.5767784, lng: 18.8656045 },
    { lat: 48.5767931, lng: 18.8658485 },
    { lat: 48.5767954, lng: 18.865933 },
    { lat: 48.5768005, lng: 18.866296 },
    { lat: 48.5768229, lng: 18.8663763 },
    { lat: 48.5768327, lng: 18.8664157 },
    { lat: 48.5770152, lng: 18.8671107 },
    { lat: 48.577064, lng: 18.8673087 },
    { lat: 48.5771414, lng: 18.8672376 },
    { lat: 48.5771566, lng: 18.8672269 },
    { lat: 48.5772132, lng: 18.8671878 },
    { lat: 48.5772155, lng: 18.8671861 },
    { lat: 48.5772219, lng: 18.8671815 },
    { lat: 48.5772218, lng: 18.8671811 },
    { lat: 48.5772316, lng: 18.8671739 },
    { lat: 48.5772785, lng: 18.8671396 },
    { lat: 48.5773193, lng: 18.867123 },
    { lat: 48.577346, lng: 18.8671145 },
    { lat: 48.5774093, lng: 18.8670953 },
    { lat: 48.5774281, lng: 18.8670895 },
    { lat: 48.5774749, lng: 18.8670812 },
    { lat: 48.5775285, lng: 18.867071 },
    { lat: 48.5775409, lng: 18.8670677 },
    { lat: 48.5775775, lng: 18.8670581 },
    { lat: 48.5776276, lng: 18.8670451 },
    { lat: 48.5776956, lng: 18.8670527 },
    { lat: 48.5777319, lng: 18.8670563 },
    { lat: 48.5777951, lng: 18.8670577 },
    { lat: 48.5778223, lng: 18.8670581 },
    { lat: 48.5778726, lng: 18.8669204 },
    { lat: 48.5778775, lng: 18.8669067 },
    { lat: 48.5779292, lng: 18.8667514 },
    { lat: 48.5779956, lng: 18.8665538 },
    { lat: 48.5780015, lng: 18.8665353 },
    { lat: 48.5780708, lng: 18.866328 },
    { lat: 48.5781165, lng: 18.8661884 },
    { lat: 48.5782104, lng: 18.8661072 },
    { lat: 48.5782508, lng: 18.8660807 },
    { lat: 48.5782716, lng: 18.8660676 },
    { lat: 48.578326, lng: 18.8660313 },
    { lat: 48.578337, lng: 18.8660243 },
    { lat: 48.5783578, lng: 18.8660112 },
    { lat: 48.578374, lng: 18.8660003 },
    { lat: 48.5785431, lng: 18.8658896 },
    { lat: 48.5785758, lng: 18.865822 },
    { lat: 48.5786316, lng: 18.865704 },
    { lat: 48.5786654, lng: 18.8655725 },
    { lat: 48.5787198, lng: 18.8653592 },
    { lat: 48.5788012, lng: 18.8649287 },
    { lat: 48.5788183, lng: 18.8648031 },
    { lat: 48.5789017, lng: 18.8645604 },
    { lat: 48.5789915, lng: 18.8644694 },
    { lat: 48.5791448, lng: 18.8643142 },
    { lat: 48.5792067, lng: 18.8642261 },
    { lat: 48.5792396, lng: 18.8641189 },
    { lat: 48.5792875, lng: 18.8640852 },
    { lat: 48.579471, lng: 18.8645165 },
    { lat: 48.579666, lng: 18.8649733 },
    { lat: 48.5797816, lng: 18.8651787 },
    { lat: 48.5798749, lng: 18.8651741 },
    { lat: 48.5798783, lng: 18.8651723 },
    { lat: 48.5799108, lng: 18.8654747 },
    { lat: 48.5800215, lng: 18.8660646 },
    { lat: 48.5802152, lng: 18.8669082 },
    { lat: 48.5802194, lng: 18.8669248 },
    { lat: 48.5803264, lng: 18.8672929 },
    { lat: 48.5803319, lng: 18.8673122 },
    { lat: 48.5804629, lng: 18.8677291 },
    { lat: 48.5805048, lng: 18.8678641 },
    { lat: 48.5805145, lng: 18.867895 },
    { lat: 48.5806003, lng: 18.868183 },
    { lat: 48.5806223, lng: 18.8682573 },
    { lat: 48.5806557, lng: 18.8681626 },
    { lat: 48.5806908, lng: 18.8682525 },
    { lat: 48.5807011, lng: 18.868278 },
    { lat: 48.5807533, lng: 18.8684139 },
    { lat: 48.5807625, lng: 18.8684403 },
    { lat: 48.5808138, lng: 18.8684951 },
    { lat: 48.5807371, lng: 18.8686665 },
    { lat: 48.5807075, lng: 18.8687295 },
    { lat: 48.5805908, lng: 18.8689815 },
    { lat: 48.5803701, lng: 18.8695505 },
    { lat: 48.5801387, lng: 18.8705489 },
    { lat: 48.5799237, lng: 18.8717536 },
    { lat: 48.5798939, lng: 18.8722012 },
    { lat: 48.5800361, lng: 18.8727471 },
    { lat: 48.5800502, lng: 18.8728003 },
    { lat: 48.58028, lng: 18.8736885 },
    { lat: 48.5805048, lng: 18.8740691 },
    { lat: 48.5806309, lng: 18.8742819 },
    { lat: 48.5814466, lng: 18.875186 },
    { lat: 48.5818545, lng: 18.8749596 },
    { lat: 48.5822713, lng: 18.874407 },
    { lat: 48.5833153, lng: 18.8732063 },
    { lat: 48.5839857, lng: 18.8727617 },
    { lat: 48.5843938, lng: 18.8729751 },
    { lat: 48.5844167, lng: 18.8729845 },
    { lat: 48.5847567, lng: 18.8731254 },
    { lat: 48.5848258, lng: 18.8731547 },
    { lat: 48.5852323, lng: 18.8732522 },
    { lat: 48.5857195, lng: 18.8734072 },
    { lat: 48.5862949, lng: 18.8735404 },
    { lat: 48.5862982, lng: 18.8735165 },
    { lat: 48.5863019, lng: 18.8734787 },
    { lat: 48.5863083, lng: 18.8734281 },
    { lat: 48.5867647, lng: 18.8734264 },
    { lat: 48.5883104, lng: 18.8738123 },
    { lat: 48.5889896, lng: 18.8742122 },
    { lat: 48.5898798, lng: 18.8753328 },
    { lat: 48.5900631, lng: 18.8757387 },
    { lat: 48.5901138, lng: 18.8761053 },
    { lat: 48.5902125, lng: 18.8769457 },
    { lat: 48.5904044, lng: 18.8785862 },
    { lat: 48.5906508, lng: 18.8798655 },
    { lat: 48.5908442, lng: 18.8807903 },
    { lat: 48.5909456, lng: 18.8820535 },
    { lat: 48.5909123, lng: 18.8829582 },
    { lat: 48.5907401, lng: 18.884364 },
    { lat: 48.5905968, lng: 18.8850148 },
    { lat: 48.5905183, lng: 18.8855277 },
    { lat: 48.5903657, lng: 18.8866986 },
    { lat: 48.5902168, lng: 18.8871324 },
    { lat: 48.5900898, lng: 18.8873998 },
    { lat: 48.5899044, lng: 18.8876357 },
    { lat: 48.5896303, lng: 18.887567 },
    { lat: 48.5886062, lng: 18.8875394 },
    { lat: 48.5882257, lng: 18.8875291 },
    { lat: 48.5880563, lng: 18.8875244 },
    { lat: 48.587848, lng: 18.887577 },
    { lat: 48.5877841, lng: 18.8876027 },
    { lat: 48.5877629, lng: 18.887608 },
    { lat: 48.587724, lng: 18.8876186 },
    { lat: 48.5876748, lng: 18.887649 },
    { lat: 48.5875101, lng: 18.8876498 },
    { lat: 48.5874865, lng: 18.8876511 },
    { lat: 48.587427, lng: 18.8876556 },
    { lat: 48.5874047, lng: 18.8876575 },
    { lat: 48.5867084, lng: 18.8878475 },
    { lat: 48.5867014, lng: 18.8877981 },
    { lat: 48.5866943, lng: 18.8877483 },
    { lat: 48.5866879, lng: 18.887711 },
    { lat: 48.5866823, lng: 18.8876667 },
    { lat: 48.586681, lng: 18.8876644 },
    { lat: 48.5844308, lng: 18.888089 },
    { lat: 48.5841901, lng: 18.8881499 },
    { lat: 48.5840531, lng: 18.8881547 },
    { lat: 48.5838175, lng: 18.8883297 },
    { lat: 48.5833804, lng: 18.8886228 },
    { lat: 48.5828722, lng: 18.8892188 },
    { lat: 48.5824903, lng: 18.8899049 },
    { lat: 48.5818617, lng: 18.8917482 },
    { lat: 48.5817241, lng: 18.8924698 },
    { lat: 48.5816286, lng: 18.8935034 },
    { lat: 48.5815822, lng: 18.8939608 },
    { lat: 48.5814941, lng: 18.8942001 },
    { lat: 48.581079, lng: 18.8946315 },
    { lat: 48.5810011, lng: 18.8945399 },
    { lat: 48.5808754, lng: 18.8943566 },
    { lat: 48.5808451, lng: 18.8944488 },
    { lat: 48.5808319, lng: 18.8944995 },
    { lat: 48.5808212, lng: 18.8945528 },
    { lat: 48.5808113, lng: 18.8945991 },
    { lat: 48.5808048, lng: 18.8946515 },
    { lat: 48.580799, lng: 18.8947541 },
    { lat: 48.5799248, lng: 18.8949024 },
    { lat: 48.5798623, lng: 18.8950045 },
    { lat: 48.5795437, lng: 18.8948791 },
    { lat: 48.579092, lng: 18.8948903 },
    { lat: 48.5787808, lng: 18.8947815 },
    { lat: 48.5784142, lng: 18.894802 },
    { lat: 48.5782524, lng: 18.8947241 },
    { lat: 48.5781485, lng: 18.8947216 },
    { lat: 48.5780169, lng: 18.8947481 },
    { lat: 48.5777847, lng: 18.8949473 },
    { lat: 48.5775559, lng: 18.8951696 },
    { lat: 48.5774701, lng: 18.8952341 },
    { lat: 48.5774178, lng: 18.8952908 },
    { lat: 48.5772002, lng: 18.8953327 },
    { lat: 48.5770992, lng: 18.8952989 },
    { lat: 48.5770393, lng: 18.8952098 },
    { lat: 48.5769526, lng: 18.8947977 },
    { lat: 48.5763432, lng: 18.8943064 },
    { lat: 48.5760405, lng: 18.8944719 },
    { lat: 48.5759384, lng: 18.8945273 },
    { lat: 48.5755699, lng: 18.8948588 },
    { lat: 48.5755237, lng: 18.8948554 },
    { lat: 48.5752372, lng: 18.8948344 },
    { lat: 48.5751904, lng: 18.8949079 },
    { lat: 48.5751758, lng: 18.8949086 },
    { lat: 48.5751646, lng: 18.894911 },
    { lat: 48.5751355, lng: 18.8949624 },
    { lat: 48.5751088, lng: 18.8949659 },
    { lat: 48.5750732, lng: 18.8949348 },
    { lat: 48.5750363, lng: 18.8949171 },
    { lat: 48.5749938, lng: 18.8949032 },
    { lat: 48.5748922, lng: 18.8948578 },
    { lat: 48.5747388, lng: 18.8948978 },
    { lat: 48.5746256, lng: 18.8949342 },
    { lat: 48.5746112, lng: 18.8949395 },
    { lat: 48.5745007, lng: 18.8950282 },
    { lat: 48.5743914, lng: 18.8951169 },
    { lat: 48.5741836, lng: 18.8952851 },
    { lat: 48.5741417, lng: 18.8953925 },
    { lat: 48.5739737, lng: 18.8958285 },
    { lat: 48.5733324, lng: 18.8965979 },
    { lat: 48.5729482, lng: 18.8961889 },
    { lat: 48.5725384, lng: 18.8959403 },
    { lat: 48.5725182, lng: 18.8960306 },
    { lat: 48.5725066, lng: 18.8960886 },
    { lat: 48.5723329, lng: 18.8959547 },
    { lat: 48.5723062, lng: 18.8959408 },
    { lat: 48.5722232, lng: 18.8959035 },
    { lat: 48.5722128, lng: 18.8959457 },
    { lat: 48.5721793, lng: 18.8959286 },
    { lat: 48.5721637, lng: 18.8959959 },
    { lat: 48.5721627, lng: 18.8960007 },
    { lat: 48.5721542, lng: 18.8960312 },
    { lat: 48.571643, lng: 18.8957646 },
    { lat: 48.5715013, lng: 18.8956822 },
    { lat: 48.5712686, lng: 18.8956984 },
    { lat: 48.569703, lng: 18.8959918 },
    { lat: 48.5680877, lng: 18.8962827 },
    { lat: 48.5679858, lng: 18.8963011 },
    { lat: 48.5678584, lng: 18.8963235 },
    { lat: 48.5676769, lng: 18.8963917 },
    { lat: 48.5675585, lng: 18.8965181 },
    { lat: 48.5673029, lng: 18.8968133 },
    { lat: 48.5669906, lng: 18.8971635 },
    { lat: 48.5661566, lng: 18.8983099 },
    { lat: 48.5657212, lng: 18.8990729 },
    { lat: 48.5655501, lng: 18.8993613 },
    { lat: 48.5643518, lng: 18.9014666 },
    { lat: 48.5642859, lng: 18.901568 },
    { lat: 48.5640348, lng: 18.9019701 },
    { lat: 48.5637283, lng: 18.9024961 },
    { lat: 48.5636652, lng: 18.9026932 },
    { lat: 48.5636495, lng: 18.9027406 },
    { lat: 48.5636277, lng: 18.9028219 },
    { lat: 48.5635012, lng: 18.9036925 },
    { lat: 48.5634494, lng: 18.9039121 },
    { lat: 48.5632586, lng: 18.9042114 },
    { lat: 48.5626399, lng: 18.9049661 },
    { lat: 48.5621963, lng: 18.9055025 },
    { lat: 48.5617523, lng: 18.9060335 },
    { lat: 48.5615301, lng: 18.9061649 },
    { lat: 48.5612639, lng: 18.9062711 },
    { lat: 48.5608784, lng: 18.9063378 },
    { lat: 48.5606081, lng: 18.9066332 },
    { lat: 48.5596922, lng: 18.9076319 },
    { lat: 48.5589617, lng: 18.9084036 },
    { lat: 48.5586214, lng: 18.9087573 },
    { lat: 48.5584509, lng: 18.9088416 },
    { lat: 48.5581281, lng: 18.9088551 },
    { lat: 48.5580327, lng: 18.9088424 },
    { lat: 48.5578248, lng: 18.9088144 },
    { lat: 48.5575873, lng: 18.9088001 },
    { lat: 48.5575126, lng: 18.9087957 },
    { lat: 48.557257, lng: 18.9088423 },
    { lat: 48.5570572, lng: 18.908972 },
    { lat: 48.5567866, lng: 18.9092567 },
    { lat: 48.5565186, lng: 18.9096123 },
    { lat: 48.5561938, lng: 18.9099638 },
    { lat: 48.5560129, lng: 18.9104569 },
    { lat: 48.555858, lng: 18.9108903 },
    { lat: 48.5556816, lng: 18.911274 },
    { lat: 48.5553083, lng: 18.9117521 },
    { lat: 48.5549275, lng: 18.9122205 },
    { lat: 48.5546428, lng: 18.9128001 },
    { lat: 48.5545265, lng: 18.9131489 },
    { lat: 48.5545503, lng: 18.9134772 },
    { lat: 48.5545941, lng: 18.913934 },
    { lat: 48.5545203, lng: 18.9141028 },
    { lat: 48.554397, lng: 18.9143151 },
    { lat: 48.5541878, lng: 18.9145196 },
    { lat: 48.5541468, lng: 18.9145593 },
    { lat: 48.5541193, lng: 18.9145714 },
    { lat: 48.5536537, lng: 18.9147713 },
    { lat: 48.5535562, lng: 18.9148691 },
    { lat: 48.5533106, lng: 18.9151156 },
    { lat: 48.5529441, lng: 18.915289 },
    { lat: 48.552623, lng: 18.9154678 },
    { lat: 48.5525855, lng: 18.9154839 },
    { lat: 48.5521209, lng: 18.9156833 },
    { lat: 48.5519815, lng: 18.9158216 },
    { lat: 48.5519132, lng: 18.9158762 },
    { lat: 48.5518612, lng: 18.9159179 },
    { lat: 48.5518121, lng: 18.9159275 },
    { lat: 48.5516848, lng: 18.915953 },
    { lat: 48.5514395, lng: 18.9160023 },
    { lat: 48.5510182, lng: 18.9159237 },
    { lat: 48.5505129, lng: 18.9160307 },
    { lat: 48.5501905, lng: 18.9160099 },
    { lat: 48.5500658, lng: 18.9159988 },
    { lat: 48.5499288, lng: 18.9159664 },
    { lat: 48.5495599, lng: 18.9159611 },
    { lat: 48.5490048, lng: 18.9162393 },
    { lat: 48.5485555, lng: 18.9163839 },
    { lat: 48.548017, lng: 18.9167193 },
    { lat: 48.5480482, lng: 18.9165168 },
    { lat: 48.5476899, lng: 18.9159091 },
    { lat: 48.5464039, lng: 18.9151065 },
    { lat: 48.5459219, lng: 18.9148766 },
    { lat: 48.5456843, lng: 18.9150164 },
    { lat: 48.5450461, lng: 18.9146635 },
    { lat: 48.5445562, lng: 18.9143484 },
    { lat: 48.5442013, lng: 18.9140685 },
    { lat: 48.543911, lng: 18.9133124 },
    { lat: 48.5438551, lng: 18.9132549 },
    { lat: 48.5427833, lng: 18.9122811 },
    { lat: 48.5419962, lng: 18.9116467 },
    { lat: 48.5415257, lng: 18.9107402 },
    { lat: 48.5411565, lng: 18.9096596 },
    { lat: 48.541239, lng: 18.908811 },
    { lat: 48.540918, lng: 18.910061 },
    { lat: 48.540737, lng: 18.911357 },
    { lat: 48.540451, lng: 18.912961 },
    { lat: 48.540604, lng: 18.913291 },
    { lat: 48.540696, lng: 18.913942 },
    { lat: 48.541377, lng: 18.914603 },
    { lat: 48.541799, lng: 18.915004 },
    { lat: 48.542407, lng: 18.915306 },
    { lat: 48.543245, lng: 18.915388 },
    { lat: 48.543956, lng: 18.915696 },
    { lat: 48.5439912, lng: 18.9157333 },
    { lat: 48.544359, lng: 18.916123 },
    { lat: 48.545081, lng: 18.916563 },
    { lat: 48.545589, lng: 18.916711 },
    { lat: 48.545841, lng: 18.917237 },
    { lat: 48.546004, lng: 18.917778 },
    { lat: 48.546065, lng: 18.917988 },
    { lat: 48.546506, lng: 18.918875 },
    { lat: 48.5467403, lng: 18.9188572 },
    { lat: 48.546954, lng: 18.918841 },
    { lat: 48.547032, lng: 18.918835 },
    { lat: 48.547233, lng: 18.91882 },
    { lat: 48.547295, lng: 18.918815 },
    { lat: 48.547348, lng: 18.918811 },
    { lat: 48.54736, lng: 18.918897 },
    { lat: 48.547415, lng: 18.919227 },
    { lat: 48.547497, lng: 18.919671 },
    { lat: 48.547489, lng: 18.919982 },
    { lat: 48.547689, lng: 18.920141 },
    { lat: 48.547705, lng: 18.920154 },
    { lat: 48.548123, lng: 18.92064 },
    { lat: 48.548244, lng: 18.921002 },
    { lat: 48.54849, lng: 18.921353 },
    { lat: 48.548857, lng: 18.921625 },
    { lat: 48.548898, lng: 18.921655 },
    { lat: 48.549314, lng: 18.921964 },
    { lat: 48.549751, lng: 18.922083 },
    { lat: 48.550029, lng: 18.922061 },
    { lat: 48.550277, lng: 18.921755 },
    { lat: 48.550307, lng: 18.921713 },
    { lat: 48.55032, lng: 18.921702 },
    { lat: 48.550477, lng: 18.921578 },
    { lat: 48.551162, lng: 18.921596 },
    { lat: 48.55185, lng: 18.92163 },
    { lat: 48.552468, lng: 18.922201 },
    { lat: 48.552567, lng: 18.922292 },
    { lat: 48.552796, lng: 18.922504 },
    { lat: 48.552702, lng: 18.922597 },
    { lat: 48.552791, lng: 18.922944 },
    { lat: 48.552791, lng: 18.923207 },
    { lat: 48.552705, lng: 18.92337 },
    { lat: 48.552781, lng: 18.923798 },
    { lat: 48.553309, lng: 18.924171 },
    { lat: 48.553454, lng: 18.92455 },
    { lat: 48.553681, lng: 18.924916 },
    { lat: 48.553848, lng: 18.925 },
    { lat: 48.554228, lng: 18.92507 },
    { lat: 48.554464, lng: 18.9253 },
    { lat: 48.554671, lng: 18.925763 },
    { lat: 48.554705, lng: 18.925843 },
    { lat: 48.555075, lng: 18.927574 },
    { lat: 48.55514, lng: 18.927859 },
    { lat: 48.555459, lng: 18.928035 },
    { lat: 48.555679, lng: 18.92807 },
    { lat: 48.555866, lng: 18.928017 },
    { lat: 48.556217, lng: 18.928126 },
    { lat: 48.556304, lng: 18.928263 },
    { lat: 48.556956, lng: 18.929214 },
    { lat: 48.55719, lng: 18.929485 },
    { lat: 48.557238, lng: 18.929523 },
    { lat: 48.557701, lng: 18.929902 },
    { lat: 48.55783, lng: 18.93067 },
    { lat: 48.557769, lng: 18.931224 },
    { lat: 48.558007, lng: 18.931522 },
    { lat: 48.558365, lng: 18.932254 },
    { lat: 48.558786, lng: 18.93271 },
    { lat: 48.559206, lng: 18.933296 },
    { lat: 48.559516, lng: 18.933254 },
    { lat: 48.559586, lng: 18.933265 },
    { lat: 48.559635, lng: 18.933272 },
    { lat: 48.559958, lng: 18.933323 },
    { lat: 48.560484, lng: 18.933537 },
    { lat: 48.560728, lng: 18.933588 },
    { lat: 48.560765, lng: 18.933596 },
    { lat: 48.560911, lng: 18.933626 },
    { lat: 48.561422, lng: 18.933834 },
    { lat: 48.561771, lng: 18.933813 },
    { lat: 48.562249, lng: 18.93396 },
    { lat: 48.562467, lng: 18.93424 },
    { lat: 48.563129, lng: 18.934907 },
    { lat: 48.5631815, lng: 18.9346876 },
    { lat: 48.564088, lng: 18.9340496 },
    { lat: 48.5644938, lng: 18.9341808 },
    { lat: 48.5648777, lng: 18.9330927 },
    { lat: 48.5655468, lng: 18.9323538 },
    { lat: 48.5663262, lng: 18.9311908 },
    { lat: 48.5666713, lng: 18.9307969 },
    { lat: 48.5673232, lng: 18.9296771 },
    { lat: 48.5673931, lng: 18.92957 },
    { lat: 48.5674576, lng: 18.929 },
    { lat: 48.5678706, lng: 18.9277963 },
    { lat: 48.5680345, lng: 18.9276295 },
    { lat: 48.5685819, lng: 18.9271809 },
    { lat: 48.5689142, lng: 18.9270638 },
    { lat: 48.5693034, lng: 18.9268245 },
    { lat: 48.5695718, lng: 18.9264977 },
    { lat: 48.5701373, lng: 18.9257169 },
    { lat: 48.5701651, lng: 18.9255991 },
    { lat: 48.5703366, lng: 18.9255339 },
    { lat: 48.5706018, lng: 18.9258672 },
    { lat: 48.5709448, lng: 18.9258293 },
    { lat: 48.571507, lng: 18.9255139 },
    { lat: 48.5726717, lng: 18.924764 },
    { lat: 48.5734724, lng: 18.9245703 },
    { lat: 48.5740475, lng: 18.9243082 },
    { lat: 48.5744575, lng: 18.9243006 },
    { lat: 48.5746423, lng: 18.9241676 },
    { lat: 48.5751699, lng: 18.9240979 },
    { lat: 48.5757284, lng: 18.9242582 },
    { lat: 48.576842, lng: 18.9249752 },
    { lat: 48.5774113, lng: 18.9254102 },
    { lat: 48.5777495, lng: 18.925629 },
    { lat: 48.5780338, lng: 18.9255477 },
    { lat: 48.5785595, lng: 18.9255695 },
    { lat: 48.5789483, lng: 18.9257232 },
    { lat: 48.5794046, lng: 18.9257804 },
    { lat: 48.5801418, lng: 18.9260385 },
    { lat: 48.5808837, lng: 18.9251163 },
    { lat: 48.581501, lng: 18.9245123 },
    { lat: 48.581836, lng: 18.9238746 },
    { lat: 48.582333, lng: 18.9236055 },
    { lat: 48.5823841, lng: 18.9232438 },
    { lat: 48.5829848, lng: 18.9229042 },
    { lat: 48.583423, lng: 18.9227448 },
    { lat: 48.5840278, lng: 18.9228634 },
    { lat: 48.5844198, lng: 18.92303 },
    { lat: 48.58516, lng: 18.9233464 },
    { lat: 48.585537, lng: 18.9236611 },
    { lat: 48.5855652, lng: 18.9236867 },
    { lat: 48.5856265, lng: 18.9237387 },
    { lat: 48.5856677, lng: 18.9237737 },
    { lat: 48.5857255, lng: 18.9238215 },
    { lat: 48.5860707, lng: 18.9241128 },
    { lat: 48.5861546, lng: 18.9241867 },
    { lat: 48.5867709, lng: 18.9230325 },
    { lat: 48.5872061, lng: 18.9211857 },
    { lat: 48.5872087, lng: 18.9211726 },
    { lat: 48.5872276, lng: 18.9210575 },
    { lat: 48.5872303, lng: 18.9210444 },
    { lat: 48.5875234, lng: 18.9195471 },
    { lat: 48.5884566, lng: 18.9172133 },
    { lat: 48.5902346, lng: 18.9153869 },
    { lat: 48.5904147, lng: 18.9152259 },
    { lat: 48.5911906, lng: 18.9144853 },
    { lat: 48.5913051, lng: 18.9139687 },
    { lat: 48.5905301, lng: 18.9124237 },
    { lat: 48.5890926, lng: 18.9095581 },
    { lat: 48.5885839, lng: 18.9085911 },
    { lat: 48.5880336, lng: 18.9075449 },
    { lat: 48.587859, lng: 18.9072131 },
    { lat: 48.588153, lng: 18.9071224 },
    { lat: 48.5884305, lng: 18.9070369 },
    { lat: 48.5885047, lng: 18.9068982 },
    { lat: 48.5885142, lng: 18.9068622 },
    { lat: 48.5885021, lng: 18.9068073 },
    { lat: 48.5885579, lng: 18.9067698 },
    { lat: 48.588679, lng: 18.9066901 },
    { lat: 48.5886533, lng: 18.9064759 },
    { lat: 48.5886451, lng: 18.9064092 },
    { lat: 48.5886338, lng: 18.9063165 },
    { lat: 48.5886315, lng: 18.9062078 },
    { lat: 48.5886251, lng: 18.9058003 },
    { lat: 48.5886183, lng: 18.9056451 },
    { lat: 48.5886034, lng: 18.9054001 },
    { lat: 48.5885911, lng: 18.9052255 },
    { lat: 48.5885907, lng: 18.9052181 },
    { lat: 48.5885776, lng: 18.9050287 },
    { lat: 48.5885687, lng: 18.9048546 },
    { lat: 48.5885681, lng: 18.9048201 },
    { lat: 48.5885619, lng: 18.9046584 },
    { lat: 48.5885582, lng: 18.9045634 },
    { lat: 48.5885556, lng: 18.9044721 },
    { lat: 48.5885614, lng: 18.9041032 },
    { lat: 48.5885547, lng: 18.9037517 },
    { lat: 48.5885543, lng: 18.9033504 },
    { lat: 48.5885532, lng: 18.9031767 },
    { lat: 48.5885518, lng: 18.9029713 },
    { lat: 48.5885523, lng: 18.9029627 },
    { lat: 48.5885532, lng: 18.9026499 },
    { lat: 48.5885546, lng: 18.9021949 },
    { lat: 48.5885505, lng: 18.9018303 },
    { lat: 48.5889663, lng: 18.9019989 },
    { lat: 48.589095, lng: 18.9019625 },
    { lat: 48.5891568, lng: 18.9019143 },
    { lat: 48.5894062, lng: 18.9017655 },
    { lat: 48.5895202, lng: 18.9016798 },
    { lat: 48.5899794, lng: 18.9013322 },
    { lat: 48.591722, lng: 18.8995258 },
    { lat: 48.5918505, lng: 18.8993928 },
    { lat: 48.5931233, lng: 18.8980744 },
    { lat: 48.5931022, lng: 18.8980177 },
    { lat: 48.5930701, lng: 18.897941 },
    { lat: 48.5923335, lng: 18.8961654 },
    { lat: 48.5922718, lng: 18.8960177 },
    { lat: 48.5922429, lng: 18.8959573 },
    { lat: 48.5926016, lng: 18.8956325 },
    { lat: 48.5928944, lng: 18.8952979 },
    { lat: 48.5928829, lng: 18.8952074 },
    { lat: 48.5928799, lng: 18.8951913 },
    { lat: 48.5928721, lng: 18.8951506 },
    { lat: 48.5928307, lng: 18.8949146 },
    { lat: 48.5928333, lng: 18.894878 },
    { lat: 48.5928404, lng: 18.8947325 },
    { lat: 48.5928388, lng: 18.8946554 },
    { lat: 48.592907, lng: 18.8945573 },
    { lat: 48.5932944, lng: 18.8940004 },
    { lat: 48.5934032, lng: 18.8938384 },
    { lat: 48.5943767, lng: 18.8924452 },
    { lat: 48.5947525, lng: 18.8919067 },
    { lat: 48.5950284, lng: 18.8915064 },
    { lat: 48.5952726, lng: 18.8903827 },
    { lat: 48.5957482, lng: 18.8897217 },
    { lat: 48.5959804, lng: 18.8891705 },
    { lat: 48.5963329, lng: 18.8883323 },
    { lat: 48.5964259, lng: 18.8872741 },
    { lat: 48.5966768, lng: 18.8871773 },
    { lat: 48.5969009, lng: 18.8869736 },
    { lat: 48.5970508, lng: 18.8866678 },
    { lat: 48.5971189, lng: 18.8864575 },
    { lat: 48.597461, lng: 18.8856336 },
    { lat: 48.5981473, lng: 18.8848848 },
    { lat: 48.5992171, lng: 18.8835932 },
    { lat: 48.5995577, lng: 18.8833077 },
    { lat: 48.5998361, lng: 18.8830431 },
    { lat: 48.5998797, lng: 18.8829959 },
    { lat: 48.6000388, lng: 18.8828643 },
    { lat: 48.5981371, lng: 18.8821123 },
    { lat: 48.5979746, lng: 18.8820462 },
    { lat: 48.596735, lng: 18.8815563 },
    { lat: 48.5964725, lng: 18.8814518 },
    { lat: 48.5964183, lng: 18.8814307 },
    { lat: 48.5963471, lng: 18.8814024 },
    { lat: 48.5964716, lng: 18.881324 },
    { lat: 48.5965611, lng: 18.8812672 },
    { lat: 48.5970776, lng: 18.8809422 },
    { lat: 48.5979363, lng: 18.879992 },
    { lat: 48.5988034, lng: 18.8794463 },
    { lat: 48.6000539, lng: 18.877724 },
    { lat: 48.6001192, lng: 18.8776343 },
    { lat: 48.6004822, lng: 18.8771342 },
    { lat: 48.600938, lng: 18.8770507 },
    { lat: 48.6016739, lng: 18.8763443 },
    { lat: 48.6017009, lng: 18.8763187 },
    { lat: 48.6017634, lng: 18.8763527 },
    { lat: 48.6018377, lng: 18.876391 },
    { lat: 48.6021251, lng: 18.8765428 },
    { lat: 48.6030412, lng: 18.8753972 },
    { lat: 48.6032869, lng: 18.8750901 },
    { lat: 48.6039183, lng: 18.8745199 },
    { lat: 48.6050251, lng: 18.8740324 },
    { lat: 48.6052456, lng: 18.8738678 },
    { lat: 48.6054816, lng: 18.8736566 },
    { lat: 48.6059017, lng: 18.8734087 },
    { lat: 48.6060168, lng: 18.8732786 },
    { lat: 48.6061751, lng: 18.873027 },
    { lat: 48.6063604, lng: 18.8727936 },
    { lat: 48.6066754, lng: 18.872475 },
    { lat: 48.6070086, lng: 18.8720285 },
    { lat: 48.6072977, lng: 18.8717519 },
    { lat: 48.6080725, lng: 18.8713635 },
    { lat: 48.6084776, lng: 18.8710864 },
    { lat: 48.6087658, lng: 18.8707243 },
    { lat: 48.6089154, lng: 18.8703522 },
    { lat: 48.6090644, lng: 18.8701814 },
    { lat: 48.6094381, lng: 18.8698591 },
    { lat: 48.6106833, lng: 18.8688623 },
    { lat: 48.6110753, lng: 18.8684427 },
    { lat: 48.611869, lng: 18.867266 },
    { lat: 48.612159, lng: 18.8670522 },
    { lat: 48.6124328, lng: 18.8668496 },
    { lat: 48.6130465, lng: 18.86655 },
    { lat: 48.6136534, lng: 18.8663837 },
    { lat: 48.6154743, lng: 18.8658146 },
    { lat: 48.6156651, lng: 18.8645523 },
    { lat: 48.6152117, lng: 18.8632897 },
    { lat: 48.6143122, lng: 18.8617101 },
    { lat: 48.6138036, lng: 18.8606643 },
    { lat: 48.6132814, lng: 18.8598122 },
    { lat: 48.6127653, lng: 18.8591454 },
    { lat: 48.6120295, lng: 18.8569818 },
    { lat: 48.6112639, lng: 18.8562209 },
    { lat: 48.6111199, lng: 18.8551621 },
    { lat: 48.6107518, lng: 18.8547847 },
    { lat: 48.6104822, lng: 18.8542396 },
    { lat: 48.6102817, lng: 18.8537455 },
    { lat: 48.6100197, lng: 18.8535822 },
    { lat: 48.6082924, lng: 18.852896 },
    { lat: 48.607895, lng: 18.8525824 },
    { lat: 48.6070909, lng: 18.8516914 },
    { lat: 48.6070719, lng: 18.8516712 },
    { lat: 48.6069332, lng: 18.8515162 },
    { lat: 48.6068828, lng: 18.8514608 },
    { lat: 48.6066799, lng: 18.8517852 },
    { lat: 48.6062095, lng: 18.8511299 },
    { lat: 48.6055801, lng: 18.8503901 },
    { lat: 48.6054567, lng: 18.8503173 },
    { lat: 48.6051239, lng: 18.8501193 },
    { lat: 48.6046985, lng: 18.8496765 },
    { lat: 48.6045413, lng: 18.8493672 },
    { lat: 48.6045163, lng: 18.8493168 },
    { lat: 48.602874, lng: 18.847098 },
    { lat: 48.6027516, lng: 18.8469336 },
    { lat: 48.6022593, lng: 18.8462678 },
    { lat: 48.6021657, lng: 18.8460947 },
    { lat: 48.6021488, lng: 18.8457472 },
    { lat: 48.6021453, lng: 18.8456571 },
    { lat: 48.6021685, lng: 18.8456265 },
    { lat: 48.6022192, lng: 18.8455596 },
    { lat: 48.6022234, lng: 18.8455541 },
    { lat: 48.6023077, lng: 18.8454209 },
    { lat: 48.6023504, lng: 18.8453528 },
    { lat: 48.6023899, lng: 18.8452897 },
    { lat: 48.6024347, lng: 18.8452185 },
    { lat: 48.6024744, lng: 18.8451554 },
    { lat: 48.6024755, lng: 18.8451535 },
    { lat: 48.6025168, lng: 18.8450841 },
    { lat: 48.6025541, lng: 18.8450209 },
    { lat: 48.6025956, lng: 18.8449515 },
    { lat: 48.6025987, lng: 18.8449461 },
    { lat: 48.6026443, lng: 18.844888 },
    { lat: 48.6026917, lng: 18.8448268 },
    { lat: 48.6027351, lng: 18.8447715 },
    { lat: 48.6027828, lng: 18.844711 },
    { lat: 48.6027881, lng: 18.8447039 },
    { lat: 48.6028375, lng: 18.8446592 },
    { lat: 48.6028926, lng: 18.8446093 },
    { lat: 48.6028958, lng: 18.8446064 },
    { lat: 48.6029042, lng: 18.8445958 },
    { lat: 48.6029522, lng: 18.8445417 },
    { lat: 48.6030086, lng: 18.8444776 },
    { lat: 48.6030128, lng: 18.8444725 },
    { lat: 48.6030517, lng: 18.8444191 },
    { lat: 48.6030949, lng: 18.8443609 },
    { lat: 48.6031276, lng: 18.8443168 },
    { lat: 48.6031581, lng: 18.8442758 },
    { lat: 48.6031961, lng: 18.8442028 },
    { lat: 48.603235, lng: 18.844127 },
    { lat: 48.6032708, lng: 18.8440583 },
    { lat: 48.6032708, lng: 18.8440569 },
    { lat: 48.6032834, lng: 18.8439727 },
    { lat: 48.6032995, lng: 18.8438682 },
    { lat: 48.6033179, lng: 18.8437492 },
    { lat: 48.60332, lng: 18.8437443 },
    { lat: 48.6033466, lng: 18.8436471 },
    { lat: 48.6033929, lng: 18.8434784 },
    { lat: 48.6033998, lng: 18.8434338 },
    { lat: 48.6034108, lng: 18.8433455 },
    { lat: 48.6034387, lng: 18.843142 },
    { lat: 48.6033429, lng: 18.8430119 },
    { lat: 48.6032439, lng: 18.8428679 },
    { lat: 48.6031414, lng: 18.842719 },
    { lat: 48.6032038, lng: 18.8425274 },
    { lat: 48.6032749, lng: 18.8421826 },
    { lat: 48.6034492, lng: 18.841567 },
    { lat: 48.6034649, lng: 18.8415427 },
    { lat: 48.6035548, lng: 18.8414074 },
    { lat: 48.6037119, lng: 18.8406121 },
    { lat: 48.6037349, lng: 18.8404052 },
    { lat: 48.6038121, lng: 18.8400448 },
    { lat: 48.603837, lng: 18.8397247 },
    { lat: 48.6039082, lng: 18.839531 },
    { lat: 48.6040431, lng: 18.8393073 },
    { lat: 48.6046287, lng: 18.8391639 },
    { lat: 48.6049781, lng: 18.8391151 },
    { lat: 48.6052622, lng: 18.839285 },
    { lat: 48.6054933, lng: 18.839285 },
    { lat: 48.6056672, lng: 18.8392039 },
    { lat: 48.6058389, lng: 18.8390788 },
    { lat: 48.6061122, lng: 18.8387814 },
    { lat: 48.6062338, lng: 18.8385856 },
    { lat: 48.6063192, lng: 18.8384501 },
    { lat: 48.6063795, lng: 18.8383521 },
    { lat: 48.6063957, lng: 18.8383178 },
    { lat: 48.6066435, lng: 18.8377668 },
    { lat: 48.6068454, lng: 18.837533 },
    { lat: 48.6072457, lng: 18.8371838 },
    { lat: 48.6073727, lng: 18.8370045 },
    { lat: 48.607585, lng: 18.8367065 },
    { lat: 48.6071065, lng: 18.8362433 },
    { lat: 48.6069376, lng: 18.836032 },
    { lat: 48.6068194, lng: 18.8358842 },
    { lat: 48.6071794, lng: 18.8351974 },
    { lat: 48.6077273, lng: 18.8344152 },
    { lat: 48.607807, lng: 18.8343217 },
    { lat: 48.6081586, lng: 18.8339045 },
    { lat: 48.6084266, lng: 18.8335966 },
    { lat: 48.6086582, lng: 18.8333197 },
    { lat: 48.6087993, lng: 18.8331077 },
    { lat: 48.6087977, lng: 18.8330317 },
    { lat: 48.6087896, lng: 18.8327494 },
    { lat: 48.6086156, lng: 18.8325708 },
    { lat: 48.6084669, lng: 18.8324188 },
    { lat: 48.6080989, lng: 18.8319589 },
    { lat: 48.6078124, lng: 18.8316312 },
    { lat: 48.6076834, lng: 18.8315132 },
    { lat: 48.6075227, lng: 18.8313958 },
    { lat: 48.6072709, lng: 18.8312382 },
    { lat: 48.606918, lng: 18.8310641 },
    { lat: 48.6064732, lng: 18.8308627 },
    { lat: 48.6063616, lng: 18.8308271 },
    { lat: 48.6062898, lng: 18.8307619 },
    { lat: 48.6062581, lng: 18.830735 },
    { lat: 48.6061626, lng: 18.8306572 },
    { lat: 48.6060843, lng: 18.8305592 },
    { lat: 48.6060053, lng: 18.8303855 },
    { lat: 48.605964, lng: 18.8301776 },
    { lat: 48.6058152, lng: 18.8291645 },
    { lat: 48.6057334, lng: 18.8286322 },
    { lat: 48.6056207, lng: 18.8280491 },
    { lat: 48.6054533, lng: 18.8272402 },
    { lat: 48.6050475, lng: 18.8253183 },
    { lat: 48.6049584, lng: 18.8248421 },
    { lat: 48.6047887, lng: 18.823944 },
    { lat: 48.6047558, lng: 18.8237662 },
    { lat: 48.6047262, lng: 18.8236112 },
    { lat: 48.6043836, lng: 18.8217556 },
    { lat: 48.6055738, lng: 18.8208162 },
    { lat: 48.6061589, lng: 18.8202508 },
    { lat: 48.606185, lng: 18.8199095 },
    { lat: 48.6061339, lng: 18.8197731 },
    { lat: 48.6060814, lng: 18.8196321 },
    { lat: 48.6044526, lng: 18.8152837 },
    { lat: 48.6038406, lng: 18.8156785 },
    { lat: 48.6028674, lng: 18.8163681 },
    { lat: 48.6025313, lng: 18.8159142 },
    { lat: 48.602503, lng: 18.8159336 },
    { lat: 48.6024736, lng: 18.8159542 },
    { lat: 48.6016158, lng: 18.8165424 },
    { lat: 48.601506, lng: 18.8164033 },
    { lat: 48.6014734, lng: 18.8163619 },
    { lat: 48.6013683, lng: 18.816228 },
    { lat: 48.6013513, lng: 18.8162065 },
    { lat: 48.6009078, lng: 18.8151208 },
    { lat: 48.6006352, lng: 18.8152768 },
    { lat: 48.6000923, lng: 18.8156363 },
    { lat: 48.5999299, lng: 18.8157433 },
    { lat: 48.5995563, lng: 18.8160156 },
    { lat: 48.5987644, lng: 18.8165297 },
    { lat: 48.5978096, lng: 18.8170567 },
    { lat: 48.5975063, lng: 18.8172075 },
    { lat: 48.596991, lng: 18.8174895 },
    { lat: 48.5969182, lng: 18.817521 },
    { lat: 48.5963125, lng: 18.817818 },
    { lat: 48.5958061, lng: 18.8180762 },
    { lat: 48.5954703, lng: 18.8182522 },
    { lat: 48.5954081, lng: 18.8181424 },
    { lat: 48.5948797, lng: 18.8172019 },
    { lat: 48.5946909, lng: 18.8173868 },
    { lat: 48.5920934, lng: 18.8199237 },
    { lat: 48.5916105, lng: 18.8203974 },
    { lat: 48.5915806, lng: 18.8204266 },
    { lat: 48.5899616, lng: 18.8220125 },
    { lat: 48.5897297, lng: 18.8219524 },
    { lat: 48.5894027, lng: 18.8221697 },
    { lat: 48.589182, lng: 18.8218076 },
    { lat: 48.5891413, lng: 18.821894 },
    { lat: 48.5892771, lng: 18.822336 },
    { lat: 48.5893005, lng: 18.8226986 },
    { lat: 48.5881993, lng: 18.8235936 },
    { lat: 48.5882984, lng: 18.8241066 },
    { lat: 48.5884867, lng: 18.8244098 },
    { lat: 48.5890222, lng: 18.8250359 },
    { lat: 48.5879862, lng: 18.8258633 },
    { lat: 48.5878594, lng: 18.8256382 },
    { lat: 48.5875562, lng: 18.8253803 },
    { lat: 48.5872929, lng: 18.8248498 },
    { lat: 48.5870541, lng: 18.8249871 },
    { lat: 48.5869098, lng: 18.824834 },
    { lat: 48.5868178, lng: 18.824737 },
    { lat: 48.5865004, lng: 18.8241428 },
    { lat: 48.5864044, lng: 18.823965 },
    { lat: 48.5863643, lng: 18.82389 },
    { lat: 48.5863394, lng: 18.8238424 },
    { lat: 48.5863056, lng: 18.8237794 },
    { lat: 48.5861682, lng: 18.8237829 },
    { lat: 48.5860998, lng: 18.8239213 },
    { lat: 48.5859254, lng: 18.8242733 },
    { lat: 48.5857982, lng: 18.8244536 },
    { lat: 48.585247, lng: 18.8252349 },
    { lat: 48.58496, lng: 18.8254812 },
    { lat: 48.5846976, lng: 18.8259005 },
    { lat: 48.5840426, lng: 18.8265533 },
    { lat: 48.5836104, lng: 18.8267602 },
    { lat: 48.5834455, lng: 18.8267986 },
    { lat: 48.5829699, lng: 18.8269116 },
    { lat: 48.5828805, lng: 18.8269058 },
    { lat: 48.5826393, lng: 18.8268892 },
    { lat: 48.5823996, lng: 18.8269415 },
    { lat: 48.5821622, lng: 18.8269934 },
    { lat: 48.5819963, lng: 18.8270106 },
    { lat: 48.5816306, lng: 18.8270486 },
    { lat: 48.5814661, lng: 18.8270304 },
    { lat: 48.5813708, lng: 18.8270199 },
    { lat: 48.581017, lng: 18.8270056 },
    { lat: 48.5809187, lng: 18.8270012 },
    { lat: 48.5807639, lng: 18.8269953 },
    { lat: 48.5806743, lng: 18.8269187 },
    { lat: 48.580567, lng: 18.8268278 },
    { lat: 48.5803227, lng: 18.8266195 },
    { lat: 48.5802614, lng: 18.8265677 },
    { lat: 48.5801818, lng: 18.8265604 },
    { lat: 48.5800551, lng: 18.8265491 },
    { lat: 48.57968, lng: 18.8265155 },
    { lat: 48.5792324, lng: 18.8264738 },
    { lat: 48.5791946, lng: 18.8270681 },
    { lat: 48.5792, lng: 18.8271725 },
    { lat: 48.5792477, lng: 18.8279774 },
    { lat: 48.5788001, lng: 18.8280621 },
    { lat: 48.578399, lng: 18.8281381 },
    { lat: 48.578349, lng: 18.828148 },
    { lat: 48.5782842, lng: 18.8281603 },
    { lat: 48.578169, lng: 18.8281821 },
    { lat: 48.5781189, lng: 18.8281912 },
    { lat: 48.578063, lng: 18.8282021 },
    { lat: 48.5779364, lng: 18.8282624 },
    { lat: 48.5779078, lng: 18.8282757 },
    { lat: 48.5778023, lng: 18.8283263 },
    { lat: 48.5777649, lng: 18.8283436 },
    { lat: 48.5775172, lng: 18.8284616 },
    { lat: 48.5772827, lng: 18.8285731 },
    { lat: 48.5772664, lng: 18.8285807 },
    { lat: 48.5776337, lng: 18.8301778 },
    { lat: 48.5776952, lng: 18.8304465 },
    { lat: 48.5777052, lng: 18.8304898 },
    { lat: 48.5777638, lng: 18.8305929 },
    { lat: 48.5777824, lng: 18.8306274 },
    { lat: 48.5777863, lng: 18.8306348 },
    { lat: 48.5778495, lng: 18.8307455 },
    { lat: 48.5778877, lng: 18.8308086 },
    { lat: 48.5778488, lng: 18.8308631 },
    { lat: 48.57781, lng: 18.8309183 },
    { lat: 48.577771, lng: 18.8309724 },
    { lat: 48.5777616, lng: 18.8309856 },
    { lat: 48.5775985, lng: 18.8313835 },
    { lat: 48.5774874, lng: 18.8317823 },
    { lat: 48.5774606, lng: 18.8318763 },
    { lat: 48.5773018, lng: 18.8327405 },
    { lat: 48.5772008, lng: 18.8331438 },
    { lat: 48.5772331, lng: 18.8333508 },
    { lat: 48.5772781, lng: 18.8336289 },
    { lat: 48.5772916, lng: 18.833716 },
    { lat: 48.5772806, lng: 18.8337223 },
    { lat: 48.5770463, lng: 18.8338551 },
    { lat: 48.5770364, lng: 18.8338609 },
    { lat: 48.5769772, lng: 18.8338942 },
    { lat: 48.5769226, lng: 18.8339253 },
    { lat: 48.5764426, lng: 18.8341968 },
    { lat: 48.5763857, lng: 18.8342288 },
    { lat: 48.5759739, lng: 18.8344624 },
    { lat: 48.5746585, lng: 18.835207 },
    { lat: 48.5745226, lng: 18.835175 },
    { lat: 48.5747035, lng: 18.8341477 },
    { lat: 48.5750065, lng: 18.8331247 },
    { lat: 48.5756235, lng: 18.8317641 },
    { lat: 48.5756792, lng: 18.831641 },
    { lat: 48.5759255, lng: 18.8311311 },
    { lat: 48.5760306, lng: 18.830921 },
    { lat: 48.5763889, lng: 18.8302024 },
    { lat: 48.576825, lng: 18.8289396 },
    { lat: 48.5768498, lng: 18.8285086 },
    { lat: 48.5765927, lng: 18.827902 },
    { lat: 48.5764975, lng: 18.8276765 },
    { lat: 48.5763574, lng: 18.8271098 },
    { lat: 48.5763016, lng: 18.8268828 },
    { lat: 48.5762181, lng: 18.8267103 },
    { lat: 48.5759007, lng: 18.8260525 },
    { lat: 48.5758199, lng: 18.825884 },
    { lat: 48.5757616, lng: 18.8257631 },
    { lat: 48.5749638, lng: 18.8249984 },
    { lat: 48.5746525, lng: 18.8250602 },
    { lat: 48.5743596, lng: 18.8250426 },
    { lat: 48.5739226, lng: 18.8249017 },
    { lat: 48.5735954, lng: 18.8246179 },
    { lat: 48.5731727, lng: 18.8242105 },
    { lat: 48.5726772, lng: 18.8233829 },
    { lat: 48.5725789, lng: 18.8231617 },
    { lat: 48.5723335, lng: 18.8228689 },
    { lat: 48.5720904, lng: 18.8226168 },
    { lat: 48.5717551, lng: 18.8222873 },
    { lat: 48.5715679, lng: 18.8220695 },
    { lat: 48.5712234, lng: 18.8216941 },
    { lat: 48.5710219, lng: 18.8215695 },
    { lat: 48.5708461, lng: 18.8214009 },
    { lat: 48.570426, lng: 18.821063 },
    { lat: 48.5702125, lng: 18.8209078 },
    { lat: 48.5700763, lng: 18.8207837 },
    { lat: 48.5697871, lng: 18.8204893 },
    { lat: 48.5695516, lng: 18.8203645 },
    { lat: 48.5688066, lng: 18.8199818 },
    { lat: 48.5683216, lng: 18.8196594 },
    { lat: 48.5678353, lng: 18.819197 },
    { lat: 48.5675711, lng: 18.8191072 },
    { lat: 48.5673345, lng: 18.8189605 },
    { lat: 48.5666343, lng: 18.8186387 },
    { lat: 48.5663078, lng: 18.8182606 },
    { lat: 48.5661067, lng: 18.8180797 },
    { lat: 48.5660066, lng: 18.8179773 },
    { lat: 48.5658409, lng: 18.8179998 },
    { lat: 48.5658294, lng: 18.8179965 },
    { lat: 48.5653051, lng: 18.8178658 },
    { lat: 48.5640424, lng: 18.8179583 },
    { lat: 48.5633669, lng: 18.8183037 },
    { lat: 48.5631066, lng: 18.8184367 },
    { lat: 48.5623063, lng: 18.8187058 },
    { lat: 48.5621512, lng: 18.8187576 },
    { lat: 48.5620116, lng: 18.8188048 },
    { lat: 48.5616303, lng: 18.818933 },
    { lat: 48.5608825, lng: 18.8189085 },
    { lat: 48.5606106, lng: 18.818882 },
    { lat: 48.560182, lng: 18.8185986 },
    { lat: 48.5599282, lng: 18.817974 },
    { lat: 48.5597565, lng: 18.817732 },
    { lat: 48.5592516, lng: 18.817023 },
    { lat: 48.5588498, lng: 18.817448 },
    { lat: 48.5586844, lng: 18.8178856 },
    { lat: 48.5581392, lng: 18.8185219 },
    { lat: 48.5580955, lng: 18.8185723 },
    { lat: 48.5580829, lng: 18.8185884 },
    { lat: 48.5561662, lng: 18.8208224 },
    { lat: 48.5560886, lng: 18.8209124 },
    { lat: 48.5560399, lng: 18.820969 },
    { lat: 48.5556519, lng: 18.8214223 },
    { lat: 48.5552554, lng: 18.8218836 },
    { lat: 48.5549832, lng: 18.8222014 },
    { lat: 48.5549556, lng: 18.8222338 },
    { lat: 48.5549429, lng: 18.8222481 },
    { lat: 48.5548834, lng: 18.8223182 },
    { lat: 48.5546642, lng: 18.8225734 },
    { lat: 48.5544049, lng: 18.822875 },
    { lat: 48.5543508, lng: 18.8229387 },
    { lat: 48.5542977, lng: 18.8230885 },
    { lat: 48.5542554, lng: 18.8232053 },
    { lat: 48.5539869, lng: 18.8239607 },
    { lat: 48.5539606, lng: 18.8239952 },
    { lat: 48.5538993, lng: 18.8240744 },
    { lat: 48.553724, lng: 18.8243019 },
    { lat: 48.5538008, lng: 18.8244331 },
    { lat: 48.5536652, lng: 18.8244744 },
    { lat: 48.5534763, lng: 18.8247623 },
    { lat: 48.5533857, lng: 18.8248486 },
    { lat: 48.5533024, lng: 18.8249278 },
    { lat: 48.5532861, lng: 18.8249433 },
    { lat: 48.5530943, lng: 18.8255958 },
    { lat: 48.5530457, lng: 18.8257612 },
    { lat: 48.552984, lng: 18.8258544 },
    { lat: 48.5528365, lng: 18.8260769 },
    { lat: 48.5528146, lng: 18.82611 },
    { lat: 48.552684, lng: 18.8266516 },
    { lat: 48.5523387, lng: 18.8270171 },
    { lat: 48.5522193, lng: 18.8273402 },
    { lat: 48.5521731, lng: 18.8273782 },
    { lat: 48.5520462, lng: 18.8274828 },
    { lat: 48.5519111, lng: 18.8278148 },
    { lat: 48.5518164, lng: 18.8280474 },
    { lat: 48.5517862, lng: 18.8280553 },
    { lat: 48.5516127, lng: 18.8281007 },
    { lat: 48.5513728, lng: 18.8284082 },
    { lat: 48.5511737, lng: 18.828521 },
    { lat: 48.55111, lng: 18.8285571 },
    { lat: 48.5508235, lng: 18.8290128 },
    { lat: 48.5506471, lng: 18.8291501 },
    { lat: 48.5505268, lng: 18.8296583 },
    { lat: 48.550296, lng: 18.8298579 },
    { lat: 48.5502139, lng: 18.8300556 },
    { lat: 48.5500696, lng: 18.8302544 },
    { lat: 48.5500497, lng: 18.8302818 },
    { lat: 48.5499547, lng: 18.8307533 },
    { lat: 48.5499511, lng: 18.831137 },
    { lat: 48.5500864, lng: 18.8318051 },
    { lat: 48.5501273, lng: 18.8319411 },
    { lat: 48.5501995, lng: 18.832181 },
    { lat: 48.5503497, lng: 18.8328121 },
    { lat: 48.5502163, lng: 18.8341256 },
    { lat: 48.5498201, lng: 18.8347871 },
    { lat: 48.5497126, lng: 18.8351461 },
    { lat: 48.5489663, lng: 18.8363021 },
    { lat: 48.5489981, lng: 18.8369515 },
    { lat: 48.5489351, lng: 18.8371073 },
    { lat: 48.5490039, lng: 18.8373042 },
    { lat: 48.5487728, lng: 18.8382967 },
    { lat: 48.5480985, lng: 18.838711 },
    { lat: 48.5479313, lng: 18.8389859 },
    { lat: 48.5475953, lng: 18.8392676 },
    { lat: 48.547305, lng: 18.8397137 },
    { lat: 48.5470455, lng: 18.8399273 },
    { lat: 48.5468433, lng: 18.8403089 },
    { lat: 48.5463736, lng: 18.8406238 },
    { lat: 48.5459418, lng: 18.8413834 },
    { lat: 48.5455397, lng: 18.8424117 },
    { lat: 48.5460417, lng: 18.8452717 },
    { lat: 48.5455282, lng: 18.8475605 },
    { lat: 48.5455713, lng: 18.8481939 },
    { lat: 48.5452986, lng: 18.8517336 },
    { lat: 48.5449839, lng: 18.852402 },
    { lat: 48.5449296, lng: 18.8524038 },
    { lat: 48.5447414, lng: 18.8530096 },
    { lat: 48.5446047, lng: 18.8535403 },
    { lat: 48.544366, lng: 18.854467 },
    { lat: 48.5439508, lng: 18.8543529 },
    { lat: 48.5429953, lng: 18.8538439 },
    { lat: 48.5422296, lng: 18.8542573 },
    { lat: 48.5418665, lng: 18.8543446 },
    { lat: 48.5410791, lng: 18.8544025 },
    { lat: 48.5411228, lng: 18.8539878 },
    { lat: 48.5409723, lng: 18.8528931 },
    { lat: 48.5404965, lng: 18.8521553 },
    { lat: 48.5398392, lng: 18.8517478 },
    { lat: 48.539838, lng: 18.8515519 },
    { lat: 48.5397454, lng: 18.8514159 },
    { lat: 48.5397322, lng: 18.8514427 },
    { lat: 48.5397228, lng: 18.8514577 },
    { lat: 48.5388404, lng: 18.8530527 },
    { lat: 48.5384827, lng: 18.8536759 },
    { lat: 48.5380849, lng: 18.8545508 },
    { lat: 48.5381268, lng: 18.8550977 },
    { lat: 48.5380995, lng: 18.8554813 },
    { lat: 48.5380358, lng: 18.8558838 },
    { lat: 48.5376711, lng: 18.8567014 },
    { lat: 48.5376291, lng: 18.8572564 },
    { lat: 48.5375665, lng: 18.8576566 },
    { lat: 48.537468, lng: 18.8580628 },
    { lat: 48.5372495, lng: 18.8585241 },
    { lat: 48.5370259, lng: 18.8589956 },
    { lat: 48.5370055, lng: 18.8590396 },
    { lat: 48.5369297, lng: 18.8592093 },
    { lat: 48.5367747, lng: 18.8593738 },
    { lat: 48.5362344, lng: 18.8596065 },
    { lat: 48.536132, lng: 18.8596747 },
    { lat: 48.5360263, lng: 18.8597445 },
    { lat: 48.5360657, lng: 18.8598768 },
    { lat: 48.5360943, lng: 18.8599265 },
    { lat: 48.536123, lng: 18.8599779 },
    { lat: 48.5361204, lng: 18.86006 },
    { lat: 48.5362248, lng: 18.8601587 },
    { lat: 48.5363073, lng: 18.8600974 },
    { lat: 48.5363648, lng: 18.8600761 },
    { lat: 48.536437, lng: 18.8600544 },
    { lat: 48.5364546, lng: 18.8601345 },
    { lat: 48.5365021, lng: 18.8602666 },
    { lat: 48.5365817, lng: 18.8604978 },
    { lat: 48.5365845, lng: 18.8605067 },
    { lat: 48.5366519, lng: 18.8606984 },
    { lat: 48.5367324, lng: 18.8609252 },
    { lat: 48.536958, lng: 18.861143 },
    { lat: 48.5370214, lng: 18.8609758 },
    { lat: 48.5371942, lng: 18.8612398 },
    { lat: 48.5373496, lng: 18.8613435 },
    { lat: 48.5375922, lng: 18.8623664 },
    { lat: 48.5373909, lng: 18.8637059 },
    { lat: 48.5375242, lng: 18.8657287 },
    { lat: 48.5371737, lng: 18.8662965 },
    { lat: 48.5372421, lng: 18.8670086 },
    { lat: 48.5371319, lng: 18.8683427 },
    { lat: 48.537192, lng: 18.869022 },
    { lat: 48.5373821, lng: 18.8705108 },
    { lat: 48.5376188, lng: 18.8710982 },
    { lat: 48.5377512, lng: 18.8732833 },
    { lat: 48.5378187, lng: 18.8737604 },
    { lat: 48.5374314, lng: 18.8746189 },
    { lat: 48.5372238, lng: 18.8753143 },
    { lat: 48.5371774, lng: 18.8770088 },
    { lat: 48.537453, lng: 18.8777589 },
    { lat: 48.537778, lng: 18.8796784 },
    { lat: 48.5375564, lng: 18.8816987 },
    { lat: 48.5371522, lng: 18.8827965 },
    { lat: 48.5370009, lng: 18.8834935 },
    { lat: 48.5370287, lng: 18.8838535 },
    { lat: 48.5370167, lng: 18.8839275 },
    { lat: 48.5366727, lng: 18.8859973 },
    { lat: 48.536419, lng: 18.8871966 },
    { lat: 48.5361695, lng: 18.8881299 },
    { lat: 48.5367053, lng: 18.8899031 },
    { lat: 48.5368648, lng: 18.8904569 },
    { lat: 48.5369921, lng: 18.8911098 },
    { lat: 48.5368288, lng: 18.892381 },
    { lat: 48.5368544, lng: 18.8933568 },
    { lat: 48.5372637, lng: 18.8942083 },
    { lat: 48.5366195, lng: 18.8950309 },
    { lat: 48.5361125, lng: 18.8967454 },
    { lat: 48.5364406, lng: 18.8986429 },
    { lat: 48.5359419, lng: 18.899928 },
    { lat: 48.5360134, lng: 18.9002011 },
    { lat: 48.5364123, lng: 18.9007419 },
    { lat: 48.5367777, lng: 18.9009838 },
    { lat: 48.5368395, lng: 18.9011726 },
    { lat: 48.5367942, lng: 18.901739 },
    { lat: 48.5368023, lng: 18.9021796 },
    { lat: 48.536787, lng: 18.902542 },
    { lat: 48.536743, lng: 18.906955 },
    { lat: 48.537898, lng: 18.907534 },
    { lat: 48.538468, lng: 18.907459 },
    { lat: 48.5386322, lng: 18.9069922 },
    { lat: 48.538879, lng: 18.9063115 },
    { lat: 48.5391227, lng: 18.9055693 },
    { lat: 48.5392274, lng: 18.9047576 },
    { lat: 48.5392525, lng: 18.9047407 },
    { lat: 48.5393832, lng: 18.9046511 },
    { lat: 48.539742, lng: 18.904896 },
    { lat: 48.5398844, lng: 18.9043349 },
    { lat: 48.5399192, lng: 18.9041992 },
    { lat: 48.5396679, lng: 18.903544 },
    { lat: 48.53967, lng: 18.903521 },
    { lat: 48.5397084, lng: 18.9031062 },
    { lat: 48.5397464, lng: 18.9027061 },
    { lat: 48.5395885, lng: 18.9023581 },
    { lat: 48.5391253, lng: 18.9016597 },
    { lat: 48.5383912, lng: 18.9010275 },
    { lat: 48.5386464, lng: 18.9001789 },
    { lat: 48.5392742, lng: 18.8998045 },
    { lat: 48.5399834, lng: 18.8997777 },
    { lat: 48.5412116, lng: 18.8990865 },
    { lat: 48.5414344, lng: 18.8983128 },
    { lat: 48.5423148, lng: 18.8970622 },
    { lat: 48.5425246, lng: 18.896864 },
    { lat: 48.543328, lng: 18.8964398 },
    { lat: 48.5438292, lng: 18.8966053 },
    { lat: 48.5438543, lng: 18.8966291 },
    { lat: 48.5442649, lng: 18.8970038 },
    { lat: 48.5446057, lng: 18.896944 },
    { lat: 48.5446359, lng: 18.8969386 },
    { lat: 48.5447141, lng: 18.8969249 },
    { lat: 48.5447303, lng: 18.8969087 },
    { lat: 48.5452977, lng: 18.8963246 },
    { lat: 48.5455017, lng: 18.8962359 },
    { lat: 48.5455567, lng: 18.896212 },
    { lat: 48.5456351, lng: 18.8961776 },
    { lat: 48.5457995, lng: 18.8959534 },
    { lat: 48.5459238, lng: 18.895784 },
    { lat: 48.5462126, lng: 18.8956161 },
    { lat: 48.5464668, lng: 18.894703 },
    { lat: 48.546795, lng: 18.8940486 },
    { lat: 48.5476714, lng: 18.8932257 },
    { lat: 48.5482056, lng: 18.8926976 },
    { lat: 48.5487001, lng: 18.8924219 },
    { lat: 48.5489776, lng: 18.8920997 },
    { lat: 48.549162, lng: 18.8916941 },
    { lat: 48.5494172, lng: 18.8914194 },
    { lat: 48.5499182, lng: 18.8910955 },
    { lat: 48.5499983, lng: 18.8910096 },
    { lat: 48.5508305, lng: 18.8913382 },
    { lat: 48.5509824, lng: 18.8913976 },
    { lat: 48.5511146, lng: 18.8914504 },
    { lat: 48.5516214, lng: 18.8916317 },
    { lat: 48.5521312, lng: 18.890806 },
    { lat: 48.5523908, lng: 18.890109 },
    { lat: 48.5525931, lng: 18.8893774 },
    { lat: 48.5528367, lng: 18.889031 },
    { lat: 48.5531191, lng: 18.8882939 },
    { lat: 48.5534806, lng: 18.8873482 },
    { lat: 48.5533829, lng: 18.8864396 },
    { lat: 48.5531556, lng: 18.8856853 },
    { lat: 48.5530931, lng: 18.8846096 },
    { lat: 48.5530096, lng: 18.8832993 },
    { lat: 48.5532635, lng: 18.8821014 },
    { lat: 48.5535287, lng: 18.8811242 },
    { lat: 48.553775, lng: 18.880587 },
    { lat: 48.5538025, lng: 18.8802058 },
    { lat: 48.5538037, lng: 18.8801875 },
    { lat: 48.5537936, lng: 18.8801421 },
    { lat: 48.5537694, lng: 18.8800456 },
    { lat: 48.5535531, lng: 18.8787416 },
    { lat: 48.5532964, lng: 18.8780735 },
    { lat: 48.5533059, lng: 18.877841 },
    { lat: 48.5533173, lng: 18.8775827 },
    { lat: 48.5539036, lng: 18.877144 },
    { lat: 48.5546864, lng: 18.8768012 },
    { lat: 48.5549051, lng: 18.8766943 },
    { lat: 48.5553283, lng: 18.8764617 },
    { lat: 48.5556337, lng: 18.8757605 },
    { lat: 48.5564494, lng: 18.8745068 },
    { lat: 48.5568425, lng: 18.874126 },
    { lat: 48.5572173, lng: 18.873894 },
    { lat: 48.557552, lng: 18.8736276 },
    { lat: 48.5577695, lng: 18.8734337 },
    { lat: 48.5580967, lng: 18.8730432 },
    { lat: 48.5582719, lng: 18.8724221 },
    { lat: 48.5583291, lng: 18.8722854 },
    { lat: 48.5583748, lng: 18.8722505 },
    { lat: 48.5587137, lng: 18.871959 },
    { lat: 48.5588604, lng: 18.8716346 },
    { lat: 48.5591096, lng: 18.8711536 },
    { lat: 48.5593409, lng: 18.8707882 },
    { lat: 48.5593781, lng: 18.8707226 },
    { lat: 48.5595397, lng: 18.870443 },
    { lat: 48.5595718, lng: 18.8703915 },
    { lat: 48.5597303, lng: 18.8701412 },
    { lat: 48.5597562, lng: 18.8701207 },
    { lat: 48.5599872, lng: 18.8699401 },
    { lat: 48.5600458, lng: 18.8698948 },
    { lat: 48.5602304, lng: 18.8696509 },
    { lat: 48.5603052, lng: 18.8695524 },
    { lat: 48.5604634, lng: 18.8694049 },
    { lat: 48.5604879, lng: 18.869375 },
    { lat: 48.5606213, lng: 18.8692098 },
    { lat: 48.5606791, lng: 18.8691069 },
    { lat: 48.5609086, lng: 18.8687026 },
    { lat: 48.561199, lng: 18.8680828 },
    { lat: 48.5614768, lng: 18.867875 },
    { lat: 48.5615351, lng: 18.868348 },
    { lat: 48.5616405, lng: 18.8691828 },
    { lat: 48.5616639, lng: 18.8692887 },
    { lat: 48.5617074, lng: 18.8694309 },
    { lat: 48.5619013, lng: 18.86982 },
    { lat: 48.5622603, lng: 18.8704602 },
    { lat: 48.5624329, lng: 18.8702434 },
    { lat: 48.562636, lng: 18.8700728 },
    { lat: 48.5628609, lng: 18.8699937 },
    { lat: 48.5630836, lng: 18.8698774 },
    { lat: 48.563155, lng: 18.8698185 },
    { lat: 48.5631994, lng: 18.8697823 },
    { lat: 48.5635629, lng: 18.8694818 },
    { lat: 48.5639597, lng: 18.869155 },
    { lat: 48.5640213, lng: 18.8691039 },
    { lat: 48.5644322, lng: 18.8688751 },
    { lat: 48.5645215, lng: 18.868835 },
    { lat: 48.5647244, lng: 18.868746 },
    { lat: 48.5649628, lng: 18.8686464 },
    { lat: 48.5651646, lng: 18.8684262 },
    { lat: 48.564971, lng: 18.8681065 },
    { lat: 48.56473, lng: 18.8676781 },
    { lat: 48.5648303, lng: 18.8675246 },
    { lat: 48.564868, lng: 18.8674678 },
    { lat: 48.5649635, lng: 18.8673562 },
    { lat: 48.5650582, lng: 18.8672226 },
    { lat: 48.5650792, lng: 18.8671934 },
    { lat: 48.5652876, lng: 18.8669058 },
    { lat: 48.5653392, lng: 18.8668356 },
    { lat: 48.5655075, lng: 18.8666043 },
    { lat: 48.5656624, lng: 18.8663921 },
    { lat: 48.5657299, lng: 18.8663044 },
    { lat: 48.5659509, lng: 18.8660174 },
    { lat: 48.5660987, lng: 18.8658252 },
    { lat: 48.5661772, lng: 18.8657302 },
    { lat: 48.5664119, lng: 18.8654477 },
    { lat: 48.5665053, lng: 18.8653359 },
    { lat: 48.5666624, lng: 18.8651942 },
    { lat: 48.5667778, lng: 18.8650906 },
    { lat: 48.5669388, lng: 18.8649328 },
    { lat: 48.5672231, lng: 18.8646554 },
    { lat: 48.5672694, lng: 18.8646104 },
    { lat: 48.5673945, lng: 18.8645245 },
    { lat: 48.5675444, lng: 18.8643495 },
    { lat: 48.567653, lng: 18.8642239 },
    { lat: 48.5678625, lng: 18.8639806 },
    { lat: 48.5678868, lng: 18.8639492 },
    { lat: 48.5680101, lng: 18.8642623 },
    { lat: 48.5680907, lng: 18.8644675 },
    { lat: 48.5681362, lng: 18.8645835 },
    { lat: 48.5681997, lng: 18.8647423 },
    { lat: 48.5682699, lng: 18.8649206 },
    { lat: 48.5686298, lng: 18.8644058 },
    { lat: 48.5688505, lng: 18.8640907 },
    { lat: 48.5689419, lng: 18.8639639 },
    { lat: 48.5689897, lng: 18.8639059 },
    { lat: 48.5690867, lng: 18.8636698 },
    { lat: 48.5691007, lng: 18.8636358 },
    { lat: 48.5691057, lng: 18.8636231 },
    { lat: 48.5691068, lng: 18.8635973 },
    { lat: 48.5691584, lng: 18.8635318 },
    { lat: 48.5691499, lng: 18.8635171 },
    { lat: 48.5690185, lng: 18.8633122 },
    { lat: 48.5689314, lng: 18.8631733 },
    { lat: 48.5689385, lng: 18.86316 },
    { lat: 48.5693019, lng: 18.8624717 },
    { lat: 48.5693052, lng: 18.8624663 },
    { lat: 48.5693145, lng: 18.8624488 },
    { lat: 48.5694495, lng: 18.8621819 },
    { lat: 48.5695711, lng: 18.8619433 },
    { lat: 48.5697135, lng: 18.8616638 },
    { lat: 48.5697904, lng: 18.8617985 },
    { lat: 48.5701839, lng: 18.8611028 },
    { lat: 48.5702852, lng: 18.8609238 },
    { lat: 48.5702882, lng: 18.8609178 },
    { lat: 48.5703196, lng: 18.8608724 },
    { lat: 48.5703847, lng: 18.860779 },
    { lat: 48.5704257, lng: 18.8607214 },
    { lat: 48.5704624, lng: 18.860669 },
    { lat: 48.5705223, lng: 18.8605833 },
    { lat: 48.5705821, lng: 18.8604972 },
    { lat: 48.5705978, lng: 18.8604726 },
    { lat: 48.5706591, lng: 18.8605932 },
    { lat: 48.5705508, lng: 18.8607199 },
    { lat: 48.5706336, lng: 18.8608803 },
    { lat: 48.5706637, lng: 18.8609405 },
    { lat: 48.5707164, lng: 18.8610415 },
    { lat: 48.5708247, lng: 18.8609148 },
    { lat: 48.5708675, lng: 18.8609977 },
    { lat: 48.5708503, lng: 18.8610171 },
    { lat: 48.5708391, lng: 18.8609964 },
    { lat: 48.5707508, lng: 18.861099 },
    { lat: 48.57082, lng: 18.8612343 },
    { lat: 48.57085, lng: 18.8612945 },
    { lat: 48.5708889, lng: 18.8613694 },
    { lat: 48.5709103, lng: 18.8613444 },
    { lat: 48.5709579, lng: 18.8614378 },
    { lat: 48.5711015, lng: 18.8612734 },
    { lat: 48.5712452, lng: 18.8611091 },
    { lat: 48.571249, lng: 18.8611151 },
    { lat: 48.5713012, lng: 18.8610537 },
    { lat: 48.571247, lng: 18.8609483 },
    { lat: 48.5712695, lng: 18.8609225 },
    { lat: 48.5712795, lng: 18.860942 },
    { lat: 48.5713816, lng: 18.8608228 },
    { lat: 48.5712448, lng: 18.8605558 },
    { lat: 48.5711938, lng: 18.8606156 },
    { lat: 48.5711069, lng: 18.8604429 },
    { lat: 48.5710514, lng: 18.8603305 },
    { lat: 48.571094, lng: 18.8602806 },
    { lat: 48.5710978, lng: 18.8602869 },
    { lat: 48.5711765, lng: 18.8601954 },
    { lat: 48.5711227, lng: 18.8600917 },
    { lat: 48.5711087, lng: 18.8600631 },
    { lat: 48.5710698, lng: 18.8599876 },
    { lat: 48.5710378, lng: 18.8600227 },
    { lat: 48.5710052, lng: 18.8599585 },
    { lat: 48.5709626, lng: 18.8600073 },
    { lat: 48.5709275, lng: 18.8599402 },
    { lat: 48.5710027, lng: 18.8598003 },
    { lat: 48.5710375, lng: 18.859735 },
    { lat: 48.5710705, lng: 18.8596731 },
    { lat: 48.5710904, lng: 18.8596462 },
    { lat: 48.5711189, lng: 18.8596083 },
    { lat: 48.5711695, lng: 18.8595407 },
    { lat: 48.5711621, lng: 18.8595266 },
    { lat: 48.571245, lng: 18.85943 },
    { lat: 48.5712474, lng: 18.8594347 },
    { lat: 48.5717685, lng: 18.8586992 },
    { lat: 48.5718021, lng: 18.8586504 },
    { lat: 48.5718117, lng: 18.8586379 },
    { lat: 48.5719351, lng: 18.8584749 },
    { lat: 48.5718133, lng: 18.8582325 },
    { lat: 48.571807, lng: 18.8582194 },
    { lat: 48.572009, lng: 18.8579876 },
    { lat: 48.5721261, lng: 18.8582216 },
    { lat: 48.5721421, lng: 18.858203 },
    { lat: 48.5721666, lng: 18.8581731 },
    { lat: 48.5722313, lng: 18.8580953 },
    { lat: 48.57228, lng: 18.8580365 },
    { lat: 48.5722842, lng: 18.8580317 },
    { lat: 48.5723798, lng: 18.8579161 },
    { lat: 48.5724136, lng: 18.8578758 },
    { lat: 48.5727184, lng: 18.8575095 },
    { lat: 48.5726945, lng: 18.8574635 },
    { lat: 48.5727009, lng: 18.8574546 },
    { lat: 48.5726681, lng: 18.8573879 },
    { lat: 48.5726988, lng: 18.8573519 },
    { lat: 48.5726688, lng: 18.8572928 },
    { lat: 48.5726379, lng: 18.8573288 },
    { lat: 48.5725037, lng: 18.8570659 },
    { lat: 48.5725068, lng: 18.8570626 },
    { lat: 48.5724767, lng: 18.8570017 },
    { lat: 48.5724734, lng: 18.8570053 },
    { lat: 48.5723403, lng: 18.8567444 },
    { lat: 48.5723701, lng: 18.8567085 },
    { lat: 48.5722885, lng: 18.8565486 },
    { lat: 48.5726777, lng: 18.8560975 },
    { lat: 48.5727015, lng: 18.8561435 },
    { lat: 48.5727015, lng: 18.8561431 },
    { lat: 48.5727418, lng: 18.8560942 },
    { lat: 48.5727577, lng: 18.8560742 },
    { lat: 48.5727822, lng: 18.8560439 },
    { lat: 48.5728002, lng: 18.8560225 },
    { lat: 48.5728924, lng: 18.8559091 },
    { lat: 48.5729317, lng: 18.855861 },
    { lat: 48.5729486, lng: 18.8558409 },
    { lat: 48.5729298, lng: 18.8558057 },
    { lat: 48.5731914, lng: 18.8555021 },
    { lat: 48.5732041, lng: 18.8555273 },
    { lat: 48.5732783, lng: 18.8554381 },
    { lat: 48.5733326, lng: 18.8553729 },
    { lat: 48.5733526, lng: 18.8553488 },
    { lat: 48.5733771, lng: 18.8553193 },
    { lat: 48.5733962, lng: 18.8552963 },
    { lat: 48.5734078, lng: 18.8552822 },
    { lat: 48.5734502, lng: 18.8552305 },
    { lat: 48.5734629, lng: 18.8552158 },
    { lat: 48.5735447, lng: 18.8551165 },
    { lat: 48.5735371, lng: 18.855101 },
    { lat: 48.573736, lng: 18.8548689 },
    { lat: 48.573751, lng: 18.8548518 },
    { lat: 48.5736304, lng: 18.8546178 },
    { lat: 48.573521, lng: 18.8547454 },
    { lat: 48.5734178, lng: 18.8548658 },
    { lat: 48.5733449, lng: 18.8547224 },
    { lat: 48.5735596, lng: 18.8544702 },
    { lat: 48.5736053, lng: 18.8544171 },
    { lat: 48.5736278, lng: 18.8543917 },
    { lat: 48.5736947, lng: 18.854315 },
    { lat: 48.5737676, lng: 18.8544581 },
    { lat: 48.5737239, lng: 18.8545085 },
    { lat: 48.5736825, lng: 18.8545576 },
    { lat: 48.5738029, lng: 18.8547919 },
    { lat: 48.5738443, lng: 18.8547432 },
    { lat: 48.5738881, lng: 18.8546932 },
    { lat: 48.5738969, lng: 18.8547085 },
    { lat: 48.573927, lng: 18.8547687 },
    { lat: 48.5740326, lng: 18.8546733 },
    { lat: 48.5740551, lng: 18.8546521 },
    { lat: 48.5741565, lng: 18.8545608 },
    { lat: 48.5742975, lng: 18.854433 },
    { lat: 48.5744666, lng: 18.8548899 },
    { lat: 48.5744902, lng: 18.8549533 },
    { lat: 48.5747202, lng: 18.8548635 },
    { lat: 48.5749842, lng: 18.8547605 },
    { lat: 48.5750738, lng: 18.8547257 },
    { lat: 48.5751192, lng: 18.8547082 },
    { lat: 48.5751424, lng: 18.8546991 },
    { lat: 48.57516, lng: 18.8546924 },
    { lat: 48.5752197, lng: 18.8546686 },
    { lat: 48.5755125, lng: 18.8545543 },
    { lat: 48.5756442, lng: 18.8544843 },
    { lat: 48.5759855, lng: 18.8543027 },
    { lat: 48.5761888, lng: 18.8541555 },
    { lat: 48.5766085, lng: 18.8539016 },
    { lat: 48.5769448, lng: 18.8544487 },
    { lat: 48.5771509, lng: 18.8542033 },
    { lat: 48.5772184, lng: 18.8543342 },
    { lat: 48.5771345, lng: 18.8544342 },
    { lat: 48.5771571, lng: 18.8544775 },
    { lat: 48.5770445, lng: 18.8546122 },
    { lat: 48.5771591, lng: 18.8547984 },
    { lat: 48.5771888, lng: 18.8548459 },
    { lat: 48.5772071, lng: 18.8548757 },
    { lat: 48.5772355, lng: 18.8549219 },
    { lat: 48.5772479, lng: 18.8549425 },
    { lat: 48.5772723, lng: 18.8549809 },
    { lat: 48.5772749, lng: 18.8549853 },
    { lat: 48.5772984, lng: 18.8550238 },
    { lat: 48.5773219, lng: 18.855067 },
    { lat: 48.5775375, lng: 18.8554589 },
    { lat: 48.5776842, lng: 18.8556809 },
    { lat: 48.5777479, lng: 18.8557752 },
    { lat: 48.5777489, lng: 18.8557772 },
    { lat: 48.5782381, lng: 18.8564428 },
    { lat: 48.578397, lng: 18.8566577 },
    { lat: 48.5784829, lng: 18.8567754 },
    { lat: 48.5785595, lng: 18.8568796 },
    { lat: 48.5787681, lng: 18.8571623 },
    { lat: 48.5788496, lng: 18.8572752 },
    { lat: 48.5790846, lng: 18.8575694 },
    { lat: 48.5793577, lng: 18.8578307 },
    { lat: 48.5794583, lng: 18.857926 },
    { lat: 48.5796866, lng: 18.8581309 },
    { lat: 48.5798511, lng: 18.8582782 },
    { lat: 48.5800709, lng: 18.8584524 },
    { lat: 48.5803848, lng: 18.8587003 },
    { lat: 48.5804178, lng: 18.858726 },
    { lat: 48.5804543, lng: 18.8587541 },
    { lat: 48.5805106, lng: 18.858798 },
  ],
  LadomerskáVieska: [
    { lat: 48.5880563, lng: 18.8875244 },
    { lat: 48.5882257, lng: 18.8875291 },
    { lat: 48.5886062, lng: 18.8875394 },
    { lat: 48.5896303, lng: 18.887567 },
    { lat: 48.5899044, lng: 18.8876357 },
    { lat: 48.5900898, lng: 18.8873998 },
    { lat: 48.5902168, lng: 18.8871324 },
    { lat: 48.5903657, lng: 18.8866986 },
    { lat: 48.5905183, lng: 18.8855277 },
    { lat: 48.5905968, lng: 18.8850148 },
    { lat: 48.5907401, lng: 18.884364 },
    { lat: 48.5909123, lng: 18.8829582 },
    { lat: 48.5909456, lng: 18.8820535 },
    { lat: 48.5908442, lng: 18.8807903 },
    { lat: 48.5906508, lng: 18.8798655 },
    { lat: 48.5904044, lng: 18.8785862 },
    { lat: 48.5902125, lng: 18.8769457 },
    { lat: 48.5901138, lng: 18.8761053 },
    { lat: 48.5900631, lng: 18.8757387 },
    { lat: 48.5898798, lng: 18.8753328 },
    { lat: 48.5889896, lng: 18.8742122 },
    { lat: 48.5883104, lng: 18.8738123 },
    { lat: 48.5867647, lng: 18.8734264 },
    { lat: 48.5863083, lng: 18.8734281 },
    { lat: 48.5863019, lng: 18.8734787 },
    { lat: 48.5862982, lng: 18.8735165 },
    { lat: 48.5862949, lng: 18.8735404 },
    { lat: 48.5857195, lng: 18.8734072 },
    { lat: 48.5852323, lng: 18.8732522 },
    { lat: 48.5848258, lng: 18.8731547 },
    { lat: 48.5847567, lng: 18.8731254 },
    { lat: 48.5844167, lng: 18.8729845 },
    { lat: 48.5843938, lng: 18.8729751 },
    { lat: 48.5839857, lng: 18.8727617 },
    { lat: 48.5833153, lng: 18.8732063 },
    { lat: 48.5822713, lng: 18.874407 },
    { lat: 48.5818545, lng: 18.8749596 },
    { lat: 48.5814466, lng: 18.875186 },
    { lat: 48.5806309, lng: 18.8742819 },
    { lat: 48.5805048, lng: 18.8740691 },
    { lat: 48.58028, lng: 18.8736885 },
    { lat: 48.5800502, lng: 18.8728003 },
    { lat: 48.5800361, lng: 18.8727471 },
    { lat: 48.5798939, lng: 18.8722012 },
    { lat: 48.5799237, lng: 18.8717536 },
    { lat: 48.5801387, lng: 18.8705489 },
    { lat: 48.5803701, lng: 18.8695505 },
    { lat: 48.5805908, lng: 18.8689815 },
    { lat: 48.5807075, lng: 18.8687295 },
    { lat: 48.5807371, lng: 18.8686665 },
    { lat: 48.5808138, lng: 18.8684951 },
    { lat: 48.5807625, lng: 18.8684403 },
    { lat: 48.5807533, lng: 18.8684139 },
    { lat: 48.5807011, lng: 18.868278 },
    { lat: 48.5806908, lng: 18.8682525 },
    { lat: 48.5806557, lng: 18.8681626 },
    { lat: 48.5806223, lng: 18.8682573 },
    { lat: 48.5806003, lng: 18.868183 },
    { lat: 48.5805145, lng: 18.867895 },
    { lat: 48.5805048, lng: 18.8678641 },
    { lat: 48.5804629, lng: 18.8677291 },
    { lat: 48.5803319, lng: 18.8673122 },
    { lat: 48.5803264, lng: 18.8672929 },
    { lat: 48.5802194, lng: 18.8669248 },
    { lat: 48.5802152, lng: 18.8669082 },
    { lat: 48.5800215, lng: 18.8660646 },
    { lat: 48.5799108, lng: 18.8654747 },
    { lat: 48.5798783, lng: 18.8651723 },
    { lat: 48.5798749, lng: 18.8651741 },
    { lat: 48.5797816, lng: 18.8651787 },
    { lat: 48.579666, lng: 18.8649733 },
    { lat: 48.579471, lng: 18.8645165 },
    { lat: 48.5792875, lng: 18.8640852 },
    { lat: 48.5792396, lng: 18.8641189 },
    { lat: 48.5792067, lng: 18.8642261 },
    { lat: 48.5791448, lng: 18.8643142 },
    { lat: 48.5789915, lng: 18.8644694 },
    { lat: 48.5789017, lng: 18.8645604 },
    { lat: 48.5788183, lng: 18.8648031 },
    { lat: 48.5788012, lng: 18.8649287 },
    { lat: 48.5787198, lng: 18.8653592 },
    { lat: 48.5786654, lng: 18.8655725 },
    { lat: 48.5786316, lng: 18.865704 },
    { lat: 48.5785758, lng: 18.865822 },
    { lat: 48.5785431, lng: 18.8658896 },
    { lat: 48.578374, lng: 18.8660003 },
    { lat: 48.5783578, lng: 18.8660112 },
    { lat: 48.578337, lng: 18.8660243 },
    { lat: 48.578326, lng: 18.8660313 },
    { lat: 48.5782716, lng: 18.8660676 },
    { lat: 48.5782508, lng: 18.8660807 },
    { lat: 48.5782104, lng: 18.8661072 },
    { lat: 48.5781165, lng: 18.8661884 },
    { lat: 48.5780708, lng: 18.866328 },
    { lat: 48.5780015, lng: 18.8665353 },
    { lat: 48.5779956, lng: 18.8665538 },
    { lat: 48.5779292, lng: 18.8667514 },
    { lat: 48.5778775, lng: 18.8669067 },
    { lat: 48.5778726, lng: 18.8669204 },
    { lat: 48.5778223, lng: 18.8670581 },
    { lat: 48.5777951, lng: 18.8670577 },
    { lat: 48.5777319, lng: 18.8670563 },
    { lat: 48.5776956, lng: 18.8670527 },
    { lat: 48.5776276, lng: 18.8670451 },
    { lat: 48.5775775, lng: 18.8670581 },
    { lat: 48.5775409, lng: 18.8670677 },
    { lat: 48.5775285, lng: 18.867071 },
    { lat: 48.5774749, lng: 18.8670812 },
    { lat: 48.5774281, lng: 18.8670895 },
    { lat: 48.5774093, lng: 18.8670953 },
    { lat: 48.577346, lng: 18.8671145 },
    { lat: 48.5773193, lng: 18.867123 },
    { lat: 48.5772785, lng: 18.8671396 },
    { lat: 48.5772316, lng: 18.8671739 },
    { lat: 48.5772218, lng: 18.8671811 },
    { lat: 48.5772219, lng: 18.8671815 },
    { lat: 48.5772155, lng: 18.8671861 },
    { lat: 48.5772132, lng: 18.8671878 },
    { lat: 48.5771566, lng: 18.8672269 },
    { lat: 48.5771414, lng: 18.8672376 },
    { lat: 48.577064, lng: 18.8673087 },
    { lat: 48.5770152, lng: 18.8671107 },
    { lat: 48.5768327, lng: 18.8664157 },
    { lat: 48.5768229, lng: 18.8663763 },
    { lat: 48.5768005, lng: 18.866296 },
    { lat: 48.5767954, lng: 18.865933 },
    { lat: 48.5767931, lng: 18.8658485 },
    { lat: 48.5767784, lng: 18.8656045 },
    { lat: 48.5767628, lng: 18.8653493 },
    { lat: 48.576756, lng: 18.8652187 },
    { lat: 48.5765702, lng: 18.8650486 },
    { lat: 48.5765062, lng: 18.8649906 },
    { lat: 48.5763607, lng: 18.8648582 },
    { lat: 48.5762029, lng: 18.8645763 },
    { lat: 48.5761099, lng: 18.8644149 },
    { lat: 48.5760634, lng: 18.8643196 },
    { lat: 48.5760457, lng: 18.8640471 },
    { lat: 48.5760401, lng: 18.8639395 },
    { lat: 48.5760342, lng: 18.8638245 },
    { lat: 48.5760326, lng: 18.8637944 },
    { lat: 48.5760268, lng: 18.8637466 },
    { lat: 48.5760364, lng: 18.8635625 },
    { lat: 48.5765546, lng: 18.8645737 },
    { lat: 48.5765577, lng: 18.8645701 },
    { lat: 48.5766927, lng: 18.8644121 },
    { lat: 48.5767505, lng: 18.8645242 },
    { lat: 48.5768578, lng: 18.8643973 },
    { lat: 48.5768691, lng: 18.8644191 },
    { lat: 48.5768903, lng: 18.8643949 },
    { lat: 48.576879, lng: 18.8643734 },
    { lat: 48.576894, lng: 18.8643571 },
    { lat: 48.5769293, lng: 18.864427 },
    { lat: 48.5770195, lng: 18.8643209 },
    { lat: 48.5769255, lng: 18.864139 },
    { lat: 48.5770393, lng: 18.8640052 },
    { lat: 48.5769376, lng: 18.8638075 },
    { lat: 48.5765942, lng: 18.8642083 },
    { lat: 48.5765899, lng: 18.8642123 },
    { lat: 48.5765686, lng: 18.8641707 },
    { lat: 48.5765447, lng: 18.8641229 },
    { lat: 48.576566, lng: 18.8640976 },
    { lat: 48.5765508, lng: 18.8640677 },
    { lat: 48.5765182, lng: 18.8640046 },
    { lat: 48.5765002, lng: 18.8640256 },
    { lat: 48.5760586, lng: 18.8631653 },
    { lat: 48.5760732, lng: 18.8630789 },
    { lat: 48.5760695, lng: 18.8630704 },
    { lat: 48.5760771, lng: 18.8630617 },
    { lat: 48.5760962, lng: 18.8629515 },
    { lat: 48.5761132, lng: 18.862848 },
    { lat: 48.5761169, lng: 18.8628316 },
    { lat: 48.5761323, lng: 18.8627372 },
    { lat: 48.5761385, lng: 18.8627058 },
    { lat: 48.5761513, lng: 18.8626477 },
    { lat: 48.5761557, lng: 18.862624 },
    { lat: 48.5761699, lng: 18.8625515 },
    { lat: 48.5762094, lng: 18.8625059 },
    { lat: 48.5762285, lng: 18.8624833 },
    { lat: 48.5762977, lng: 18.8624043 },
    { lat: 48.5762538, lng: 18.8623187 },
    { lat: 48.5762275, lng: 18.862267 },
    { lat: 48.5762543, lng: 18.8621324 },
    { lat: 48.5762568, lng: 18.8621172 },
    { lat: 48.576275, lng: 18.8620306 },
    { lat: 48.5762766, lng: 18.8620212 },
    { lat: 48.5762919, lng: 18.861946 },
    { lat: 48.5763378, lng: 18.8617191 },
    { lat: 48.5763685, lng: 18.8612273 },
    { lat: 48.5763838, lng: 18.8609752 },
    { lat: 48.5763842, lng: 18.8609655 },
    { lat: 48.5763858, lng: 18.8609504 },
    { lat: 48.5763917, lng: 18.860913 },
    { lat: 48.5764135, lng: 18.8607883 },
    { lat: 48.5764178, lng: 18.8607838 },
    { lat: 48.5764152, lng: 18.8607792 },
    { lat: 48.5764431, lng: 18.8606203 },
    { lat: 48.5763802, lng: 18.8604959 },
    { lat: 48.5761757, lng: 18.8600931 },
    { lat: 48.5761704, lng: 18.8600823 },
    { lat: 48.5762268, lng: 18.8600169 },
    { lat: 48.5761904, lng: 18.859945 },
    { lat: 48.5764065, lng: 18.8596952 },
    { lat: 48.576444, lng: 18.859767 },
    { lat: 48.5768205, lng: 18.859332 },
    { lat: 48.5768758, lng: 18.8592678 },
    { lat: 48.57688, lng: 18.859263 },
    { lat: 48.5768865, lng: 18.8592555 },
    { lat: 48.5768941, lng: 18.8592467 },
    { lat: 48.5768971, lng: 18.8592424 },
    { lat: 48.5769485, lng: 18.859345 },
    { lat: 48.576956, lng: 18.8593345 },
    { lat: 48.5769617, lng: 18.8593353 },
    { lat: 48.5769855, lng: 18.8593374 },
    { lat: 48.5769948, lng: 18.8593207 },
    { lat: 48.5769958, lng: 18.8593177 },
    { lat: 48.5770125, lng: 18.8593157 },
    { lat: 48.5770148, lng: 18.8593155 },
    { lat: 48.5770181, lng: 18.8593151 },
    { lat: 48.5770237, lng: 18.8593144 },
    { lat: 48.5770338, lng: 18.8593132 },
    { lat: 48.5771231, lng: 18.85949 },
    { lat: 48.5772178, lng: 18.8593838 },
    { lat: 48.5771914, lng: 18.8593299 },
    { lat: 48.5772706, lng: 18.8593537 },
    { lat: 48.5773872, lng: 18.8593887 },
    { lat: 48.5773949, lng: 18.8594035 },
    { lat: 48.577449, lng: 18.8595143 },
    { lat: 48.577502, lng: 18.8596202 },
    { lat: 48.5775423, lng: 18.8597034 },
    { lat: 48.577546, lng: 18.8597072 },
    { lat: 48.5775635, lng: 18.8597444 },
    { lat: 48.5776031, lng: 18.8597005 },
    { lat: 48.5775854, lng: 18.8596642 },
    { lat: 48.5775829, lng: 18.8596584 },
    { lat: 48.5777096, lng: 18.8595163 },
    { lat: 48.5777666, lng: 18.8595502 },
    { lat: 48.5777808, lng: 18.8595603 },
    { lat: 48.5778392, lng: 18.8596015 },
    { lat: 48.5778941, lng: 18.8596399 },
    { lat: 48.5779037, lng: 18.8596291 },
    { lat: 48.5780062, lng: 18.8595141 },
    { lat: 48.5780328, lng: 18.8594836 },
    { lat: 48.578087, lng: 18.8594227 },
    { lat: 48.5780884, lng: 18.8594272 },
    { lat: 48.5781184, lng: 18.8593937 },
    { lat: 48.5781353, lng: 18.8593746 },
    { lat: 48.5782687, lng: 18.859641 },
    { lat: 48.578222, lng: 18.859697 },
    { lat: 48.5780739, lng: 18.859863 },
    { lat: 48.5780875, lng: 18.8598867 },
    { lat: 48.5781688, lng: 18.8599974 },
    { lat: 48.5782912, lng: 18.860139 },
    { lat: 48.5783764, lng: 18.8602411 },
    { lat: 48.5784428, lng: 18.8603227 },
    { lat: 48.5785052, lng: 18.8603969 },
    { lat: 48.5786554, lng: 18.8607036 },
    { lat: 48.578764, lng: 18.8610163 },
    { lat: 48.57878, lng: 18.8610422 },
    { lat: 48.5788757, lng: 18.8610605 },
    { lat: 48.5789828, lng: 18.8610809 },
    { lat: 48.5792743, lng: 18.8611378 },
    { lat: 48.57976, lng: 18.8612411 },
    { lat: 48.5798319, lng: 18.8612551 },
    { lat: 48.5799025, lng: 18.8612689 },
    { lat: 48.5799193, lng: 18.8611586 },
    { lat: 48.5799668, lng: 18.8608589 },
    { lat: 48.5801093, lng: 18.8601249 },
    { lat: 48.5802461, lng: 18.8595653 },
    { lat: 48.5803168, lng: 18.8593437 },
    { lat: 48.5803759, lng: 18.8591572 },
    { lat: 48.5804031, lng: 18.859071 },
    { lat: 48.5804399, lng: 18.858978 },
    { lat: 48.5805106, lng: 18.858798 },
    { lat: 48.5804543, lng: 18.8587541 },
    { lat: 48.5804178, lng: 18.858726 },
    { lat: 48.5803848, lng: 18.8587003 },
    { lat: 48.5800709, lng: 18.8584524 },
    { lat: 48.5798511, lng: 18.8582782 },
    { lat: 48.5796866, lng: 18.8581309 },
    { lat: 48.5794583, lng: 18.857926 },
    { lat: 48.5793577, lng: 18.8578307 },
    { lat: 48.5790846, lng: 18.8575694 },
    { lat: 48.5788496, lng: 18.8572752 },
    { lat: 48.5787681, lng: 18.8571623 },
    { lat: 48.5785595, lng: 18.8568796 },
    { lat: 48.5784829, lng: 18.8567754 },
    { lat: 48.578397, lng: 18.8566577 },
    { lat: 48.5782381, lng: 18.8564428 },
    { lat: 48.5777489, lng: 18.8557772 },
    { lat: 48.5777479, lng: 18.8557752 },
    { lat: 48.5776842, lng: 18.8556809 },
    { lat: 48.5775375, lng: 18.8554589 },
    { lat: 48.5773219, lng: 18.855067 },
    { lat: 48.5772984, lng: 18.8550238 },
    { lat: 48.5772749, lng: 18.8549853 },
    { lat: 48.5772723, lng: 18.8549809 },
    { lat: 48.5772479, lng: 18.8549425 },
    { lat: 48.5772355, lng: 18.8549219 },
    { lat: 48.5772071, lng: 18.8548757 },
    { lat: 48.5771888, lng: 18.8548459 },
    { lat: 48.5771591, lng: 18.8547984 },
    { lat: 48.5770445, lng: 18.8546122 },
    { lat: 48.5771571, lng: 18.8544775 },
    { lat: 48.5771345, lng: 18.8544342 },
    { lat: 48.5772184, lng: 18.8543342 },
    { lat: 48.5771509, lng: 18.8542033 },
    { lat: 48.5769448, lng: 18.8544487 },
    { lat: 48.5766085, lng: 18.8539016 },
    { lat: 48.5761888, lng: 18.8541555 },
    { lat: 48.5759855, lng: 18.8543027 },
    { lat: 48.5756442, lng: 18.8544843 },
    { lat: 48.5755125, lng: 18.8545543 },
    { lat: 48.5752197, lng: 18.8546686 },
    { lat: 48.57516, lng: 18.8546924 },
    { lat: 48.5751424, lng: 18.8546991 },
    { lat: 48.5751192, lng: 18.8547082 },
    { lat: 48.5750738, lng: 18.8547257 },
    { lat: 48.5749842, lng: 18.8547605 },
    { lat: 48.5747202, lng: 18.8548635 },
    { lat: 48.5744902, lng: 18.8549533 },
    { lat: 48.5744666, lng: 18.8548899 },
    { lat: 48.5742975, lng: 18.854433 },
    { lat: 48.5741565, lng: 18.8545608 },
    { lat: 48.5740551, lng: 18.8546521 },
    { lat: 48.5740326, lng: 18.8546733 },
    { lat: 48.573927, lng: 18.8547687 },
    { lat: 48.5738969, lng: 18.8547085 },
    { lat: 48.5738881, lng: 18.8546932 },
    { lat: 48.5738443, lng: 18.8547432 },
    { lat: 48.5738029, lng: 18.8547919 },
    { lat: 48.5736825, lng: 18.8545576 },
    { lat: 48.5737239, lng: 18.8545085 },
    { lat: 48.5737676, lng: 18.8544581 },
    { lat: 48.5736947, lng: 18.854315 },
    { lat: 48.5736278, lng: 18.8543917 },
    { lat: 48.5736053, lng: 18.8544171 },
    { lat: 48.5735596, lng: 18.8544702 },
    { lat: 48.5733449, lng: 18.8547224 },
    { lat: 48.5734178, lng: 18.8548658 },
    { lat: 48.573521, lng: 18.8547454 },
    { lat: 48.5736304, lng: 18.8546178 },
    { lat: 48.573751, lng: 18.8548518 },
    { lat: 48.573736, lng: 18.8548689 },
    { lat: 48.5735371, lng: 18.855101 },
    { lat: 48.5735447, lng: 18.8551165 },
    { lat: 48.5734629, lng: 18.8552158 },
    { lat: 48.5734502, lng: 18.8552305 },
    { lat: 48.5734078, lng: 18.8552822 },
    { lat: 48.5733962, lng: 18.8552963 },
    { lat: 48.5733771, lng: 18.8553193 },
    { lat: 48.5733526, lng: 18.8553488 },
    { lat: 48.5733326, lng: 18.8553729 },
    { lat: 48.5732783, lng: 18.8554381 },
    { lat: 48.5732041, lng: 18.8555273 },
    { lat: 48.5731914, lng: 18.8555021 },
    { lat: 48.5729298, lng: 18.8558057 },
    { lat: 48.5729486, lng: 18.8558409 },
    { lat: 48.5729317, lng: 18.855861 },
    { lat: 48.5728924, lng: 18.8559091 },
    { lat: 48.5728002, lng: 18.8560225 },
    { lat: 48.5727822, lng: 18.8560439 },
    { lat: 48.5727577, lng: 18.8560742 },
    { lat: 48.5727418, lng: 18.8560942 },
    { lat: 48.5727015, lng: 18.8561431 },
    { lat: 48.5727015, lng: 18.8561435 },
    { lat: 48.5726777, lng: 18.8560975 },
    { lat: 48.5722885, lng: 18.8565486 },
    { lat: 48.5723701, lng: 18.8567085 },
    { lat: 48.5723403, lng: 18.8567444 },
    { lat: 48.5724734, lng: 18.8570053 },
    { lat: 48.5724767, lng: 18.8570017 },
    { lat: 48.5725068, lng: 18.8570626 },
    { lat: 48.5725037, lng: 18.8570659 },
    { lat: 48.5726379, lng: 18.8573288 },
    { lat: 48.5726688, lng: 18.8572928 },
    { lat: 48.5726988, lng: 18.8573519 },
    { lat: 48.5726681, lng: 18.8573879 },
    { lat: 48.5727009, lng: 18.8574546 },
    { lat: 48.5726945, lng: 18.8574635 },
    { lat: 48.5727184, lng: 18.8575095 },
    { lat: 48.5724136, lng: 18.8578758 },
    { lat: 48.5723798, lng: 18.8579161 },
    { lat: 48.5722842, lng: 18.8580317 },
    { lat: 48.57228, lng: 18.8580365 },
    { lat: 48.5722313, lng: 18.8580953 },
    { lat: 48.5721666, lng: 18.8581731 },
    { lat: 48.5721421, lng: 18.858203 },
    { lat: 48.5721261, lng: 18.8582216 },
    { lat: 48.572009, lng: 18.8579876 },
    { lat: 48.571807, lng: 18.8582194 },
    { lat: 48.5718133, lng: 18.8582325 },
    { lat: 48.5719351, lng: 18.8584749 },
    { lat: 48.5718117, lng: 18.8586379 },
    { lat: 48.5718021, lng: 18.8586504 },
    { lat: 48.5717685, lng: 18.8586992 },
    { lat: 48.5712474, lng: 18.8594347 },
    { lat: 48.571245, lng: 18.85943 },
    { lat: 48.5711621, lng: 18.8595266 },
    { lat: 48.5711695, lng: 18.8595407 },
    { lat: 48.5711189, lng: 18.8596083 },
    { lat: 48.5710904, lng: 18.8596462 },
    { lat: 48.5710705, lng: 18.8596731 },
    { lat: 48.5710375, lng: 18.859735 },
    { lat: 48.5710027, lng: 18.8598003 },
    { lat: 48.5709275, lng: 18.8599402 },
    { lat: 48.5709626, lng: 18.8600073 },
    { lat: 48.5710052, lng: 18.8599585 },
    { lat: 48.5710378, lng: 18.8600227 },
    { lat: 48.5710698, lng: 18.8599876 },
    { lat: 48.5711087, lng: 18.8600631 },
    { lat: 48.5711227, lng: 18.8600917 },
    { lat: 48.5711765, lng: 18.8601954 },
    { lat: 48.5710978, lng: 18.8602869 },
    { lat: 48.571094, lng: 18.8602806 },
    { lat: 48.5710514, lng: 18.8603305 },
    { lat: 48.5711069, lng: 18.8604429 },
    { lat: 48.5711938, lng: 18.8606156 },
    { lat: 48.5712448, lng: 18.8605558 },
    { lat: 48.5713816, lng: 18.8608228 },
    { lat: 48.5712795, lng: 18.860942 },
    { lat: 48.5712695, lng: 18.8609225 },
    { lat: 48.571247, lng: 18.8609483 },
    { lat: 48.5713012, lng: 18.8610537 },
    { lat: 48.571249, lng: 18.8611151 },
    { lat: 48.5712452, lng: 18.8611091 },
    { lat: 48.5711015, lng: 18.8612734 },
    { lat: 48.5709579, lng: 18.8614378 },
    { lat: 48.5709103, lng: 18.8613444 },
    { lat: 48.5708889, lng: 18.8613694 },
    { lat: 48.57085, lng: 18.8612945 },
    { lat: 48.57082, lng: 18.8612343 },
    { lat: 48.5707508, lng: 18.861099 },
    { lat: 48.5708391, lng: 18.8609964 },
    { lat: 48.5708503, lng: 18.8610171 },
    { lat: 48.5708675, lng: 18.8609977 },
    { lat: 48.5708247, lng: 18.8609148 },
    { lat: 48.5707164, lng: 18.8610415 },
    { lat: 48.5706637, lng: 18.8609405 },
    { lat: 48.5706336, lng: 18.8608803 },
    { lat: 48.5705508, lng: 18.8607199 },
    { lat: 48.5706591, lng: 18.8605932 },
    { lat: 48.5705978, lng: 18.8604726 },
    { lat: 48.5705821, lng: 18.8604972 },
    { lat: 48.5705223, lng: 18.8605833 },
    { lat: 48.5704624, lng: 18.860669 },
    { lat: 48.5704257, lng: 18.8607214 },
    { lat: 48.5703847, lng: 18.860779 },
    { lat: 48.5703196, lng: 18.8608724 },
    { lat: 48.5702882, lng: 18.8609178 },
    { lat: 48.5702852, lng: 18.8609238 },
    { lat: 48.5701839, lng: 18.8611028 },
    { lat: 48.5697904, lng: 18.8617985 },
    { lat: 48.5697135, lng: 18.8616638 },
    { lat: 48.5695711, lng: 18.8619433 },
    { lat: 48.5694495, lng: 18.8621819 },
    { lat: 48.5693145, lng: 18.8624488 },
    { lat: 48.5693052, lng: 18.8624663 },
    { lat: 48.5693019, lng: 18.8624717 },
    { lat: 48.5689385, lng: 18.86316 },
    { lat: 48.5689314, lng: 18.8631733 },
    { lat: 48.5690185, lng: 18.8633122 },
    { lat: 48.5691499, lng: 18.8635171 },
    { lat: 48.5691584, lng: 18.8635318 },
    { lat: 48.5691068, lng: 18.8635973 },
    { lat: 48.5691057, lng: 18.8636231 },
    { lat: 48.5691007, lng: 18.8636358 },
    { lat: 48.5690867, lng: 18.8636698 },
    { lat: 48.5689897, lng: 18.8639059 },
    { lat: 48.5689419, lng: 18.8639639 },
    { lat: 48.5688505, lng: 18.8640907 },
    { lat: 48.5686298, lng: 18.8644058 },
    { lat: 48.5682699, lng: 18.8649206 },
    { lat: 48.5681997, lng: 18.8647423 },
    { lat: 48.5681362, lng: 18.8645835 },
    { lat: 48.5680907, lng: 18.8644675 },
    { lat: 48.5680101, lng: 18.8642623 },
    { lat: 48.5678868, lng: 18.8639492 },
    { lat: 48.5678625, lng: 18.8639806 },
    { lat: 48.567653, lng: 18.8642239 },
    { lat: 48.5675444, lng: 18.8643495 },
    { lat: 48.5673945, lng: 18.8645245 },
    { lat: 48.5672694, lng: 18.8646104 },
    { lat: 48.5672231, lng: 18.8646554 },
    { lat: 48.5669388, lng: 18.8649328 },
    { lat: 48.5667778, lng: 18.8650906 },
    { lat: 48.5666624, lng: 18.8651942 },
    { lat: 48.5665053, lng: 18.8653359 },
    { lat: 48.5664119, lng: 18.8654477 },
    { lat: 48.5661772, lng: 18.8657302 },
    { lat: 48.5660987, lng: 18.8658252 },
    { lat: 48.5659509, lng: 18.8660174 },
    { lat: 48.5657299, lng: 18.8663044 },
    { lat: 48.5656624, lng: 18.8663921 },
    { lat: 48.5655075, lng: 18.8666043 },
    { lat: 48.5653392, lng: 18.8668356 },
    { lat: 48.5652876, lng: 18.8669058 },
    { lat: 48.5650792, lng: 18.8671934 },
    { lat: 48.5650582, lng: 18.8672226 },
    { lat: 48.5649635, lng: 18.8673562 },
    { lat: 48.564868, lng: 18.8674678 },
    { lat: 48.5648303, lng: 18.8675246 },
    { lat: 48.56473, lng: 18.8676781 },
    { lat: 48.564971, lng: 18.8681065 },
    { lat: 48.5651646, lng: 18.8684262 },
    { lat: 48.5649628, lng: 18.8686464 },
    { lat: 48.5647244, lng: 18.868746 },
    { lat: 48.5645215, lng: 18.868835 },
    { lat: 48.5644322, lng: 18.8688751 },
    { lat: 48.5640213, lng: 18.8691039 },
    { lat: 48.5639597, lng: 18.869155 },
    { lat: 48.5635629, lng: 18.8694818 },
    { lat: 48.5631994, lng: 18.8697823 },
    { lat: 48.563155, lng: 18.8698185 },
    { lat: 48.5630836, lng: 18.8698774 },
    { lat: 48.5628609, lng: 18.8699937 },
    { lat: 48.562636, lng: 18.8700728 },
    { lat: 48.5624329, lng: 18.8702434 },
    { lat: 48.5622603, lng: 18.8704602 },
    { lat: 48.5619013, lng: 18.86982 },
    { lat: 48.5617074, lng: 18.8694309 },
    { lat: 48.5616639, lng: 18.8692887 },
    { lat: 48.5616405, lng: 18.8691828 },
    { lat: 48.5615351, lng: 18.868348 },
    { lat: 48.5614768, lng: 18.867875 },
    { lat: 48.561199, lng: 18.8680828 },
    { lat: 48.5609086, lng: 18.8687026 },
    { lat: 48.5606791, lng: 18.8691069 },
    { lat: 48.5606213, lng: 18.8692098 },
    { lat: 48.5604879, lng: 18.869375 },
    { lat: 48.5604634, lng: 18.8694049 },
    { lat: 48.5603052, lng: 18.8695524 },
    { lat: 48.5602304, lng: 18.8696509 },
    { lat: 48.5600458, lng: 18.8698948 },
    { lat: 48.5599872, lng: 18.8699401 },
    { lat: 48.5597562, lng: 18.8701207 },
    { lat: 48.5597303, lng: 18.8701412 },
    { lat: 48.5595718, lng: 18.8703915 },
    { lat: 48.5595397, lng: 18.870443 },
    { lat: 48.5593781, lng: 18.8707226 },
    { lat: 48.5593409, lng: 18.8707882 },
    { lat: 48.5591096, lng: 18.8711536 },
    { lat: 48.5588604, lng: 18.8716346 },
    { lat: 48.5587137, lng: 18.871959 },
    { lat: 48.5583748, lng: 18.8722505 },
    { lat: 48.5583291, lng: 18.8722854 },
    { lat: 48.5582719, lng: 18.8724221 },
    { lat: 48.5580967, lng: 18.8730432 },
    { lat: 48.5577695, lng: 18.8734337 },
    { lat: 48.557552, lng: 18.8736276 },
    { lat: 48.5572173, lng: 18.873894 },
    { lat: 48.5568425, lng: 18.874126 },
    { lat: 48.5564494, lng: 18.8745068 },
    { lat: 48.5556337, lng: 18.8757605 },
    { lat: 48.5553283, lng: 18.8764617 },
    { lat: 48.5549051, lng: 18.8766943 },
    { lat: 48.5546864, lng: 18.8768012 },
    { lat: 48.5539036, lng: 18.877144 },
    { lat: 48.5533173, lng: 18.8775827 },
    { lat: 48.5533059, lng: 18.877841 },
    { lat: 48.5532964, lng: 18.8780735 },
    { lat: 48.5535531, lng: 18.8787416 },
    { lat: 48.5537694, lng: 18.8800456 },
    { lat: 48.5537936, lng: 18.8801421 },
    { lat: 48.5538037, lng: 18.8801875 },
    { lat: 48.5538025, lng: 18.8802058 },
    { lat: 48.553775, lng: 18.880587 },
    { lat: 48.5535287, lng: 18.8811242 },
    { lat: 48.5532635, lng: 18.8821014 },
    { lat: 48.5530096, lng: 18.8832993 },
    { lat: 48.5530931, lng: 18.8846096 },
    { lat: 48.5531556, lng: 18.8856853 },
    { lat: 48.5533829, lng: 18.8864396 },
    { lat: 48.5534806, lng: 18.8873482 },
    { lat: 48.5531191, lng: 18.8882939 },
    { lat: 48.5528367, lng: 18.889031 },
    { lat: 48.5525931, lng: 18.8893774 },
    { lat: 48.5523908, lng: 18.890109 },
    { lat: 48.5521312, lng: 18.890806 },
    { lat: 48.5516214, lng: 18.8916317 },
    { lat: 48.5511146, lng: 18.8914504 },
    { lat: 48.5509824, lng: 18.8913976 },
    { lat: 48.5508305, lng: 18.8913382 },
    { lat: 48.5499983, lng: 18.8910096 },
    { lat: 48.5499182, lng: 18.8910955 },
    { lat: 48.5494172, lng: 18.8914194 },
    { lat: 48.549162, lng: 18.8916941 },
    { lat: 48.5489776, lng: 18.8920997 },
    { lat: 48.5487001, lng: 18.8924219 },
    { lat: 48.5482056, lng: 18.8926976 },
    { lat: 48.5476714, lng: 18.8932257 },
    { lat: 48.546795, lng: 18.8940486 },
    { lat: 48.5464668, lng: 18.894703 },
    { lat: 48.5462126, lng: 18.8956161 },
    { lat: 48.5459238, lng: 18.895784 },
    { lat: 48.5457995, lng: 18.8959534 },
    { lat: 48.5456351, lng: 18.8961776 },
    { lat: 48.5455567, lng: 18.896212 },
    { lat: 48.5455017, lng: 18.8962359 },
    { lat: 48.5452977, lng: 18.8963246 },
    { lat: 48.5447303, lng: 18.8969087 },
    { lat: 48.5447141, lng: 18.8969249 },
    { lat: 48.5446359, lng: 18.8969386 },
    { lat: 48.5446057, lng: 18.896944 },
    { lat: 48.5442649, lng: 18.8970038 },
    { lat: 48.5438543, lng: 18.8966291 },
    { lat: 48.5438292, lng: 18.8966053 },
    { lat: 48.543328, lng: 18.8964398 },
    { lat: 48.5425246, lng: 18.896864 },
    { lat: 48.5423148, lng: 18.8970622 },
    { lat: 48.5414344, lng: 18.8983128 },
    { lat: 48.5412116, lng: 18.8990865 },
    { lat: 48.5399834, lng: 18.8997777 },
    { lat: 48.5392742, lng: 18.8998045 },
    { lat: 48.5386464, lng: 18.9001789 },
    { lat: 48.5383912, lng: 18.9010275 },
    { lat: 48.5391253, lng: 18.9016597 },
    { lat: 48.5395885, lng: 18.9023581 },
    { lat: 48.5397464, lng: 18.9027061 },
    { lat: 48.5397084, lng: 18.9031062 },
    { lat: 48.53967, lng: 18.903521 },
    { lat: 48.5396679, lng: 18.903544 },
    { lat: 48.5399192, lng: 18.9041992 },
    { lat: 48.5398844, lng: 18.9043349 },
    { lat: 48.539742, lng: 18.904896 },
    { lat: 48.5393832, lng: 18.9046511 },
    { lat: 48.5392525, lng: 18.9047407 },
    { lat: 48.5392274, lng: 18.9047576 },
    { lat: 48.5391227, lng: 18.9055693 },
    { lat: 48.538879, lng: 18.9063115 },
    { lat: 48.5386322, lng: 18.9069922 },
    { lat: 48.538468, lng: 18.907459 },
    { lat: 48.539038, lng: 18.907501 },
    { lat: 48.539585, lng: 18.907718 },
    { lat: 48.539691, lng: 18.907832 },
    { lat: 48.539894, lng: 18.908416 },
    { lat: 48.540337, lng: 18.908481 },
    { lat: 48.540906, lng: 18.908712 },
    { lat: 48.541239, lng: 18.908811 },
    { lat: 48.5411565, lng: 18.9096596 },
    { lat: 48.5415257, lng: 18.9107402 },
    { lat: 48.5419962, lng: 18.9116467 },
    { lat: 48.5427833, lng: 18.9122811 },
    { lat: 48.5438551, lng: 18.9132549 },
    { lat: 48.543911, lng: 18.9133124 },
    { lat: 48.5442013, lng: 18.9140685 },
    { lat: 48.5445562, lng: 18.9143484 },
    { lat: 48.5450461, lng: 18.9146635 },
    { lat: 48.5456843, lng: 18.9150164 },
    { lat: 48.5459219, lng: 18.9148766 },
    { lat: 48.5464039, lng: 18.9151065 },
    { lat: 48.5476899, lng: 18.9159091 },
    { lat: 48.5480482, lng: 18.9165168 },
    { lat: 48.548017, lng: 18.9167193 },
    { lat: 48.5485555, lng: 18.9163839 },
    { lat: 48.5490048, lng: 18.9162393 },
    { lat: 48.5495599, lng: 18.9159611 },
    { lat: 48.5499288, lng: 18.9159664 },
    { lat: 48.5500658, lng: 18.9159988 },
    { lat: 48.5501905, lng: 18.9160099 },
    { lat: 48.5505129, lng: 18.9160307 },
    { lat: 48.5510182, lng: 18.9159237 },
    { lat: 48.5514395, lng: 18.9160023 },
    { lat: 48.5516848, lng: 18.915953 },
    { lat: 48.5518121, lng: 18.9159275 },
    { lat: 48.5518612, lng: 18.9159179 },
    { lat: 48.5519132, lng: 18.9158762 },
    { lat: 48.5519815, lng: 18.9158216 },
    { lat: 48.5521209, lng: 18.9156833 },
    { lat: 48.5525855, lng: 18.9154839 },
    { lat: 48.552623, lng: 18.9154678 },
    { lat: 48.5529441, lng: 18.915289 },
    { lat: 48.5533106, lng: 18.9151156 },
    { lat: 48.5535562, lng: 18.9148691 },
    { lat: 48.5536537, lng: 18.9147713 },
    { lat: 48.5541193, lng: 18.9145714 },
    { lat: 48.5541468, lng: 18.9145593 },
    { lat: 48.5541878, lng: 18.9145196 },
    { lat: 48.554397, lng: 18.9143151 },
    { lat: 48.5545203, lng: 18.9141028 },
    { lat: 48.5545941, lng: 18.913934 },
    { lat: 48.5545503, lng: 18.9134772 },
    { lat: 48.5545265, lng: 18.9131489 },
    { lat: 48.5546428, lng: 18.9128001 },
    { lat: 48.5549275, lng: 18.9122205 },
    { lat: 48.5553083, lng: 18.9117521 },
    { lat: 48.5556816, lng: 18.911274 },
    { lat: 48.555858, lng: 18.9108903 },
    { lat: 48.5560129, lng: 18.9104569 },
    { lat: 48.5561938, lng: 18.9099638 },
    { lat: 48.5565186, lng: 18.9096123 },
    { lat: 48.5567866, lng: 18.9092567 },
    { lat: 48.5570572, lng: 18.908972 },
    { lat: 48.557257, lng: 18.9088423 },
    { lat: 48.5575126, lng: 18.9087957 },
    { lat: 48.5575873, lng: 18.9088001 },
    { lat: 48.5578248, lng: 18.9088144 },
    { lat: 48.5580327, lng: 18.9088424 },
    { lat: 48.5581281, lng: 18.9088551 },
    { lat: 48.5584509, lng: 18.9088416 },
    { lat: 48.5586214, lng: 18.9087573 },
    { lat: 48.5589617, lng: 18.9084036 },
    { lat: 48.5596922, lng: 18.9076319 },
    { lat: 48.5606081, lng: 18.9066332 },
    { lat: 48.5608784, lng: 18.9063378 },
    { lat: 48.5612639, lng: 18.9062711 },
    { lat: 48.5615301, lng: 18.9061649 },
    { lat: 48.5617523, lng: 18.9060335 },
    { lat: 48.5621963, lng: 18.9055025 },
    { lat: 48.5626399, lng: 18.9049661 },
    { lat: 48.5632586, lng: 18.9042114 },
    { lat: 48.5634494, lng: 18.9039121 },
    { lat: 48.5635012, lng: 18.9036925 },
    { lat: 48.5636277, lng: 18.9028219 },
    { lat: 48.5636495, lng: 18.9027406 },
    { lat: 48.5636652, lng: 18.9026932 },
    { lat: 48.5637283, lng: 18.9024961 },
    { lat: 48.5640348, lng: 18.9019701 },
    { lat: 48.5642859, lng: 18.901568 },
    { lat: 48.5643518, lng: 18.9014666 },
    { lat: 48.5655501, lng: 18.8993613 },
    { lat: 48.5657212, lng: 18.8990729 },
    { lat: 48.5661566, lng: 18.8983099 },
    { lat: 48.5669906, lng: 18.8971635 },
    { lat: 48.5673029, lng: 18.8968133 },
    { lat: 48.5675585, lng: 18.8965181 },
    { lat: 48.5676769, lng: 18.8963917 },
    { lat: 48.5678584, lng: 18.8963235 },
    { lat: 48.5679858, lng: 18.8963011 },
    { lat: 48.5680877, lng: 18.8962827 },
    { lat: 48.569703, lng: 18.8959918 },
    { lat: 48.5712686, lng: 18.8956984 },
    { lat: 48.5715013, lng: 18.8956822 },
    { lat: 48.571643, lng: 18.8957646 },
    { lat: 48.5721542, lng: 18.8960312 },
    { lat: 48.5721627, lng: 18.8960007 },
    { lat: 48.5721637, lng: 18.8959959 },
    { lat: 48.5721793, lng: 18.8959286 },
    { lat: 48.5722128, lng: 18.8959457 },
    { lat: 48.5722232, lng: 18.8959035 },
    { lat: 48.5723062, lng: 18.8959408 },
    { lat: 48.5723329, lng: 18.8959547 },
    { lat: 48.5725066, lng: 18.8960886 },
    { lat: 48.5725182, lng: 18.8960306 },
    { lat: 48.5725384, lng: 18.8959403 },
    { lat: 48.5729482, lng: 18.8961889 },
    { lat: 48.5733324, lng: 18.8965979 },
    { lat: 48.5739737, lng: 18.8958285 },
    { lat: 48.5741417, lng: 18.8953925 },
    { lat: 48.5741836, lng: 18.8952851 },
    { lat: 48.5743914, lng: 18.8951169 },
    { lat: 48.5745007, lng: 18.8950282 },
    { lat: 48.5746112, lng: 18.8949395 },
    { lat: 48.5746256, lng: 18.8949342 },
    { lat: 48.5747388, lng: 18.8948978 },
    { lat: 48.5748922, lng: 18.8948578 },
    { lat: 48.5749938, lng: 18.8949032 },
    { lat: 48.5750363, lng: 18.8949171 },
    { lat: 48.5750732, lng: 18.8949348 },
    { lat: 48.5751088, lng: 18.8949659 },
    { lat: 48.5751355, lng: 18.8949624 },
    { lat: 48.5751646, lng: 18.894911 },
    { lat: 48.5751758, lng: 18.8949086 },
    { lat: 48.5751904, lng: 18.8949079 },
    { lat: 48.5752372, lng: 18.8948344 },
    { lat: 48.5755237, lng: 18.8948554 },
    { lat: 48.5755699, lng: 18.8948588 },
    { lat: 48.5759384, lng: 18.8945273 },
    { lat: 48.5760405, lng: 18.8944719 },
    { lat: 48.5763432, lng: 18.8943064 },
    { lat: 48.5769526, lng: 18.8947977 },
    { lat: 48.5770393, lng: 18.8952098 },
    { lat: 48.5770992, lng: 18.8952989 },
    { lat: 48.5772002, lng: 18.8953327 },
    { lat: 48.5774178, lng: 18.8952908 },
    { lat: 48.5774701, lng: 18.8952341 },
    { lat: 48.5775559, lng: 18.8951696 },
    { lat: 48.5777847, lng: 18.8949473 },
    { lat: 48.5780169, lng: 18.8947481 },
    { lat: 48.5781485, lng: 18.8947216 },
    { lat: 48.5782524, lng: 18.8947241 },
    { lat: 48.5784142, lng: 18.894802 },
    { lat: 48.5787808, lng: 18.8947815 },
    { lat: 48.579092, lng: 18.8948903 },
    { lat: 48.5795437, lng: 18.8948791 },
    { lat: 48.5798623, lng: 18.8950045 },
    { lat: 48.5799248, lng: 18.8949024 },
    { lat: 48.580799, lng: 18.8947541 },
    { lat: 48.5808048, lng: 18.8946515 },
    { lat: 48.5808113, lng: 18.8945991 },
    { lat: 48.5808212, lng: 18.8945528 },
    { lat: 48.5808319, lng: 18.8944995 },
    { lat: 48.5808451, lng: 18.8944488 },
    { lat: 48.5808754, lng: 18.8943566 },
    { lat: 48.5810011, lng: 18.8945399 },
    { lat: 48.581079, lng: 18.8946315 },
    { lat: 48.5814941, lng: 18.8942001 },
    { lat: 48.5815822, lng: 18.8939608 },
    { lat: 48.5816286, lng: 18.8935034 },
    { lat: 48.5817241, lng: 18.8924698 },
    { lat: 48.5818617, lng: 18.8917482 },
    { lat: 48.5824903, lng: 18.8899049 },
    { lat: 48.5828722, lng: 18.8892188 },
    { lat: 48.5833804, lng: 18.8886228 },
    { lat: 48.5838175, lng: 18.8883297 },
    { lat: 48.5840531, lng: 18.8881547 },
    { lat: 48.5841901, lng: 18.8881499 },
    { lat: 48.5844308, lng: 18.888089 },
    { lat: 48.586681, lng: 18.8876644 },
    { lat: 48.5866823, lng: 18.8876667 },
    { lat: 48.5866879, lng: 18.887711 },
    { lat: 48.5866943, lng: 18.8877483 },
    { lat: 48.5867014, lng: 18.8877981 },
    { lat: 48.5867084, lng: 18.8878475 },
    { lat: 48.5874047, lng: 18.8876575 },
    { lat: 48.587427, lng: 18.8876556 },
    { lat: 48.5874865, lng: 18.8876511 },
    { lat: 48.5875101, lng: 18.8876498 },
    { lat: 48.5876748, lng: 18.887649 },
    { lat: 48.587724, lng: 18.8876186 },
    { lat: 48.5877629, lng: 18.887608 },
    { lat: 48.5877841, lng: 18.8876027 },
    { lat: 48.587848, lng: 18.887577 },
    { lat: 48.5880563, lng: 18.8875244 },
  ],
  Kremnica: [
    { lat: 48.67382, lng: 19.017014 },
    { lat: 48.673818, lng: 19.017268 },
    { lat: 48.673822, lng: 19.017393 },
    { lat: 48.673901, lng: 19.017609 },
    { lat: 48.673911, lng: 19.018056 },
    { lat: 48.674153, lng: 19.018787 },
    { lat: 48.674355, lng: 19.019152 },
    { lat: 48.674441, lng: 19.019522 },
    { lat: 48.674481, lng: 19.020236 },
    { lat: 48.674485, lng: 19.020321 },
    { lat: 48.674635, lng: 19.020775 },
    { lat: 48.674791, lng: 19.021003 },
    { lat: 48.674877, lng: 19.022093 },
    { lat: 48.675029, lng: 19.022233 },
    { lat: 48.675039, lng: 19.022354 },
    { lat: 48.675088, lng: 19.022517 },
    { lat: 48.67525, lng: 19.022842 },
    { lat: 48.675432, lng: 19.023531 },
    { lat: 48.6755, lng: 19.023944 },
    { lat: 48.675589, lng: 19.024265 },
    { lat: 48.675812, lng: 19.024067 },
    { lat: 48.67595, lng: 19.024303 },
    { lat: 48.676414, lng: 19.024898 },
    { lat: 48.676657, lng: 19.025059 },
    { lat: 48.677269, lng: 19.026003 },
    { lat: 48.677318, lng: 19.026225 },
    { lat: 48.67738, lng: 19.026208 },
    { lat: 48.678099, lng: 19.026003 },
    { lat: 48.678131, lng: 19.026083 },
    { lat: 48.678495, lng: 19.026173 },
    { lat: 48.678687, lng: 19.026369 },
    { lat: 48.678951, lng: 19.02639 },
    { lat: 48.679085, lng: 19.026572 },
    { lat: 48.679298, lng: 19.026496 },
    { lat: 48.680185, lng: 19.027324 },
    { lat: 48.68056, lng: 19.027645 },
    { lat: 48.680943, lng: 19.027932 },
    { lat: 48.681453, lng: 19.028091 },
    { lat: 48.681615, lng: 19.028292 },
    { lat: 48.682013, lng: 19.029146 },
    { lat: 48.682241, lng: 19.02978 },
    { lat: 48.68234, lng: 19.030178 },
    { lat: 48.682517, lng: 19.030577 },
    { lat: 48.68271, lng: 19.03084 },
    { lat: 48.682898, lng: 19.031216 },
    { lat: 48.683147, lng: 19.031605 },
    { lat: 48.683349, lng: 19.03204 },
    { lat: 48.683706, lng: 19.032557 },
    { lat: 48.683817, lng: 19.032722 },
    { lat: 48.684492, lng: 19.033154 },
    { lat: 48.684787, lng: 19.033438 },
    { lat: 48.684787, lng: 19.033176 },
    { lat: 48.684807, lng: 19.032853 },
    { lat: 48.685136, lng: 19.032551 },
    { lat: 48.685338, lng: 19.032274 },
    { lat: 48.685544, lng: 19.031847 },
    { lat: 48.6859, lng: 19.030934 },
    { lat: 48.686291, lng: 19.030434 },
    { lat: 48.686778, lng: 19.030076 },
    { lat: 48.687268, lng: 19.029555 },
    { lat: 48.687519, lng: 19.029341 },
    { lat: 48.688, lng: 19.029005 },
    { lat: 48.689796, lng: 19.027998 },
    { lat: 48.690813, lng: 19.027538 },
    { lat: 48.691063, lng: 19.027435 },
    { lat: 48.692036, lng: 19.027072 },
    { lat: 48.692118, lng: 19.027031 },
    { lat: 48.692378, lng: 19.026839 },
    { lat: 48.692711, lng: 19.026389 },
    { lat: 48.692979, lng: 19.026142 },
    { lat: 48.693136, lng: 19.026015 },
    { lat: 48.693647, lng: 19.025726 },
    { lat: 48.694038, lng: 19.02547 },
    { lat: 48.695142, lng: 19.024642 },
    { lat: 48.695526, lng: 19.0242 },
    { lat: 48.696009, lng: 19.023843 },
    { lat: 48.696737, lng: 19.023386 },
    { lat: 48.696812, lng: 19.023293 },
    { lat: 48.697201, lng: 19.022703 },
    { lat: 48.697531, lng: 19.022073 },
    { lat: 48.697917, lng: 19.021227 },
    { lat: 48.698211, lng: 19.020585 },
    { lat: 48.698421, lng: 19.020173 },
    { lat: 48.698793, lng: 19.019722 },
    { lat: 48.699419, lng: 19.01875 },
    { lat: 48.699546, lng: 19.018714 },
    { lat: 48.699611, lng: 19.018779 },
    { lat: 48.699674, lng: 19.018841 },
    { lat: 48.699724, lng: 19.01889 },
    { lat: 48.700349, lng: 19.019817 },
    { lat: 48.7005, lng: 19.019985 },
    { lat: 48.700696, lng: 19.020079 },
    { lat: 48.700876, lng: 19.020636 },
    { lat: 48.70051, lng: 19.021596 },
    { lat: 48.700468, lng: 19.022463 },
    { lat: 48.700484, lng: 19.022856 },
    { lat: 48.700588, lng: 19.023404 },
    { lat: 48.700658, lng: 19.024094 },
    { lat: 48.70065, lng: 19.024576 },
    { lat: 48.700796, lng: 19.025295 },
    { lat: 48.700872, lng: 19.025541 },
    { lat: 48.701749, lng: 19.026944 },
    { lat: 48.701968, lng: 19.029683 },
    { lat: 48.702061, lng: 19.030011 },
    { lat: 48.702127, lng: 19.030534 },
    { lat: 48.702181, lng: 19.030445 },
    { lat: 48.702257, lng: 19.030066 },
    { lat: 48.70226, lng: 19.029848 },
    { lat: 48.702344, lng: 19.029535 },
    { lat: 48.702682, lng: 19.028661 },
    { lat: 48.70326, lng: 19.027038 },
    { lat: 48.70345, lng: 19.0264068 },
    { lat: 48.7034705, lng: 19.0263386 },
    { lat: 48.703553, lng: 19.026032 },
    { lat: 48.703866, lng: 19.025283 },
    { lat: 48.703969, lng: 19.024805 },
    { lat: 48.704003, lng: 19.024207 },
    { lat: 48.703877, lng: 19.023909 },
    { lat: 48.703751, lng: 19.023692 },
    { lat: 48.70367, lng: 19.023552 },
    { lat: 48.703742, lng: 19.023263 },
    { lat: 48.703625, lng: 19.022672 },
    { lat: 48.703609, lng: 19.022058 },
    { lat: 48.703655, lng: 19.020541 },
    { lat: 48.703753, lng: 19.019406 },
    { lat: 48.703812, lng: 19.018576 },
    { lat: 48.705256, lng: 19.018042 },
    { lat: 48.705687, lng: 19.017585 },
    { lat: 48.706004, lng: 19.016994 },
    { lat: 48.706078, lng: 19.016831 },
    { lat: 48.706188, lng: 19.016591 },
    { lat: 48.706166, lng: 19.016498 },
    { lat: 48.705557, lng: 19.015799 },
    { lat: 48.705402, lng: 19.015421 },
    { lat: 48.705196, lng: 19.01472 },
    { lat: 48.705041, lng: 19.014051 },
    { lat: 48.705014, lng: 19.013933 },
    { lat: 48.704802, lng: 19.013016 },
    { lat: 48.70444, lng: 19.011374 },
    { lat: 48.704209, lng: 19.009767 },
    { lat: 48.705624, lng: 19.008127 },
    { lat: 48.706377, lng: 19.007718 },
    { lat: 48.706572, lng: 19.007646 },
    { lat: 48.706729, lng: 19.007418 },
    { lat: 48.707168, lng: 19.00653 },
    { lat: 48.707214, lng: 19.006437 },
    { lat: 48.707479, lng: 19.005365 },
    { lat: 48.707804, lng: 19.00418 },
    { lat: 48.70843, lng: 19.003696 },
    { lat: 48.708726, lng: 19.003726 },
    { lat: 48.709104, lng: 19.003872 },
    { lat: 48.70927, lng: 19.004027 },
    { lat: 48.709699, lng: 19.004492 },
    { lat: 48.710194, lng: 19.004825 },
    { lat: 48.710593, lng: 19.00524 },
    { lat: 48.711355, lng: 19.005915 },
    { lat: 48.711846, lng: 19.006446 },
    { lat: 48.712329, lng: 19.006621 },
    { lat: 48.712359, lng: 19.006621 },
    { lat: 48.712897, lng: 19.006628 },
    { lat: 48.713317, lng: 19.006522 },
    { lat: 48.713736, lng: 19.006334 },
    { lat: 48.714082, lng: 19.006073 },
    { lat: 48.714636, lng: 19.005852 },
    { lat: 48.71496, lng: 19.005485 },
    { lat: 48.715394, lng: 19.004918 },
    { lat: 48.715906, lng: 19.005202 },
    { lat: 48.716225, lng: 19.005494 },
    { lat: 48.716592, lng: 19.005534 },
    { lat: 48.717347, lng: 19.005535 },
    { lat: 48.71819, lng: 19.00545 },
    { lat: 48.718377, lng: 19.005442 },
    { lat: 48.719011, lng: 19.005104 },
    { lat: 48.719075, lng: 19.00507 },
    { lat: 48.719502, lng: 19.005223 },
    { lat: 48.719879, lng: 19.005403 },
    { lat: 48.720465, lng: 19.00515 },
    { lat: 48.720585, lng: 19.005068 },
    { lat: 48.720955, lng: 19.005089 },
    { lat: 48.721165, lng: 19.004961 },
    { lat: 48.721586, lng: 19.004694 },
    { lat: 48.72206, lng: 19.004092 },
    { lat: 48.722168, lng: 19.003971 },
    { lat: 48.722587, lng: 19.003499 },
    { lat: 48.722907, lng: 19.00328 },
    { lat: 48.723019, lng: 19.003044 },
    { lat: 48.723093, lng: 19.002954 },
    { lat: 48.723567, lng: 19.003299 },
    { lat: 48.72377, lng: 19.00351 },
    { lat: 48.723983, lng: 19.003655 },
    { lat: 48.724222, lng: 19.003719 },
    { lat: 48.724371, lng: 19.003706 },
    { lat: 48.724624, lng: 19.004019 },
    { lat: 48.725232, lng: 19.003811 },
    { lat: 48.725245, lng: 19.003813 },
    { lat: 48.725842, lng: 19.003932 },
    { lat: 48.726065, lng: 19.003866 },
    { lat: 48.726282, lng: 19.003967 },
    { lat: 48.726508, lng: 19.003995 },
    { lat: 48.7268121, lng: 19.0040557 },
    { lat: 48.727174, lng: 19.004128 },
    { lat: 48.727692, lng: 19.00356 },
    { lat: 48.727889, lng: 19.003354 },
    { lat: 48.728185, lng: 19.002918 },
    { lat: 48.728435, lng: 19.00222 },
    { lat: 48.728976, lng: 19.001617 },
    { lat: 48.729431, lng: 19.001087 },
    { lat: 48.730018, lng: 19.001332 },
    { lat: 48.730376, lng: 19.00154 },
    { lat: 48.73076, lng: 19.00145 },
    { lat: 48.731524, lng: 19.001504 },
    { lat: 48.731988, lng: 19.001877 },
    { lat: 48.732567, lng: 19.002249 },
    { lat: 48.733053, lng: 19.002593 },
    { lat: 48.733186, lng: 19.002689 },
    { lat: 48.733417, lng: 19.00261 },
    { lat: 48.733795, lng: 19.002426 },
    { lat: 48.73418, lng: 19.002117 },
    { lat: 48.734646, lng: 19.001639 },
    { lat: 48.734734, lng: 19.001591 },
    { lat: 48.735118, lng: 19.001429 },
    { lat: 48.735465, lng: 19.001068 },
    { lat: 48.735685, lng: 19.001094 },
    { lat: 48.736364, lng: 19.000934 },
    { lat: 48.73669, lng: 19.000762 },
    { lat: 48.737372, lng: 19.000569 },
    { lat: 48.737629, lng: 19.00032 },
    { lat: 48.738084, lng: 18.999301 },
    { lat: 48.738627, lng: 18.998584 },
    { lat: 48.738807, lng: 18.99838 },
    { lat: 48.738996, lng: 18.997774 },
    { lat: 48.739455, lng: 18.997001 },
    { lat: 48.739617, lng: 18.996719 },
    { lat: 48.739885, lng: 18.996478 },
    { lat: 48.740072, lng: 18.996474 },
    { lat: 48.740427, lng: 18.996467 },
    { lat: 48.740815, lng: 18.996387 },
    { lat: 48.741365, lng: 18.99654 },
    { lat: 48.741502, lng: 18.996636 },
    { lat: 48.741869, lng: 18.99697 },
    { lat: 48.742294, lng: 18.996995 },
    { lat: 48.742587, lng: 18.9971 },
    { lat: 48.742954, lng: 18.997213 },
    { lat: 48.743327, lng: 18.997282 },
    { lat: 48.743606, lng: 18.997692 },
    { lat: 48.74375, lng: 18.997666 },
    { lat: 48.743935, lng: 18.997814 },
    { lat: 48.744084, lng: 18.99778 },
    { lat: 48.744285, lng: 18.997391 },
    { lat: 48.74454, lng: 18.99752 },
    { lat: 48.744848, lng: 18.997324 },
    { lat: 48.744973, lng: 18.99735 },
    { lat: 48.745396, lng: 18.997071 },
    { lat: 48.7455236, lng: 18.9970786 },
    { lat: 48.745631, lng: 18.997085 },
    { lat: 48.74594, lng: 18.996898 },
    { lat: 48.746791, lng: 18.997444 },
    { lat: 48.747262, lng: 18.997747 },
    { lat: 48.747471, lng: 18.997671 },
    { lat: 48.747749, lng: 18.997747 },
    { lat: 48.747872, lng: 18.997957 },
    { lat: 48.7479689, lng: 18.9979921 },
    { lat: 48.74801, lng: 18.998007 },
    { lat: 48.74809, lng: 18.99789 },
    { lat: 48.748301, lng: 18.997913 },
    { lat: 48.748451, lng: 18.998114 },
    { lat: 48.748976, lng: 18.998293 },
    { lat: 48.748994, lng: 18.998313 },
    { lat: 48.749163, lng: 18.997279 },
    { lat: 48.74906, lng: 18.997114 },
    { lat: 48.748779, lng: 18.996583 },
    { lat: 48.748449, lng: 18.996005 },
    { lat: 48.748279, lng: 18.995528 },
    { lat: 48.7482663, lng: 18.9955813 },
    { lat: 48.7481996, lng: 18.9955754 },
    { lat: 48.7477071, lng: 18.9958238 },
    { lat: 48.7473756, lng: 18.9957992 },
    { lat: 48.7472101, lng: 18.9957594 },
    { lat: 48.747103, lng: 18.9956939 },
    { lat: 48.7469627, lng: 18.9955533 },
    { lat: 48.7466317, lng: 18.9952911 },
    { lat: 48.7465626, lng: 18.9952201 },
    { lat: 48.7462112, lng: 18.9947595 },
    { lat: 48.7461725, lng: 18.9947286 },
    { lat: 48.7461218, lng: 18.994688 },
    { lat: 48.7453452, lng: 18.9944437 },
    { lat: 48.7452043, lng: 18.9944232 },
    { lat: 48.7449734, lng: 18.9944492 },
    { lat: 48.7447324, lng: 18.9946317 },
    { lat: 48.744613, lng: 18.9948595 },
    { lat: 48.7444751, lng: 18.9950751 },
    { lat: 48.7443186, lng: 18.9952797 },
    { lat: 48.744149, lng: 18.9954707 },
    { lat: 48.7438771, lng: 18.9956922 },
    { lat: 48.7434148, lng: 18.9958467 },
    { lat: 48.7432894, lng: 18.9959097 },
    { lat: 48.7431565, lng: 18.9959294 },
    { lat: 48.7429211, lng: 18.9958483 },
    { lat: 48.7427375, lng: 18.9956931 },
    { lat: 48.7426844, lng: 18.9956485 },
    { lat: 48.7425017, lng: 18.9954456 },
    { lat: 48.7421709, lng: 18.9949033 },
    { lat: 48.7419842, lng: 18.9944427 },
    { lat: 48.7417471, lng: 18.9940989 },
    { lat: 48.7414962, lng: 18.9938186 },
    { lat: 48.7410965, lng: 18.9931611 },
    { lat: 48.7407377, lng: 18.9922659 },
    { lat: 48.7407079, lng: 18.9921904 },
    { lat: 48.7405753, lng: 18.9907741 },
    { lat: 48.7403749, lng: 18.9898856 },
    { lat: 48.7403326, lng: 18.9897873 },
    { lat: 48.7398878, lng: 18.9890836 },
    { lat: 48.7398499, lng: 18.9890244 },
    { lat: 48.7397793, lng: 18.9888536 },
    { lat: 48.739745, lng: 18.9886654 },
    { lat: 48.7397293, lng: 18.9883129 },
    { lat: 48.7396573, lng: 18.9879822 },
    { lat: 48.7396225, lng: 18.9877384 },
    { lat: 48.7396031, lng: 18.9871319 },
    { lat: 48.739615, lng: 18.9869641 },
    { lat: 48.7395996, lng: 18.9867066 },
    { lat: 48.7395159, lng: 18.986234 },
    { lat: 48.7395051, lng: 18.9860452 },
    { lat: 48.7394473, lng: 18.9856126 },
    { lat: 48.7394602, lng: 18.9854893 },
    { lat: 48.7394468, lng: 18.9852004 },
    { lat: 48.7394287, lng: 18.9848257 },
    { lat: 48.7394477, lng: 18.9848235 },
    { lat: 48.7394649, lng: 18.9847797 },
    { lat: 48.7396713, lng: 18.9842275 },
    { lat: 48.7396741, lng: 18.9841939 },
    { lat: 48.7396594, lng: 18.9841939 },
    { lat: 48.7396601, lng: 18.984007 },
    { lat: 48.73963, lng: 18.9839244 },
    { lat: 48.7395948, lng: 18.9839206 },
    { lat: 48.7395512, lng: 18.9838385 },
    { lat: 48.7395536, lng: 18.983686 },
    { lat: 48.7395547, lng: 18.9835955 },
    { lat: 48.7395257, lng: 18.9835498 },
    { lat: 48.7394887, lng: 18.9834917 },
    { lat: 48.7394765, lng: 18.9834731 },
    { lat: 48.7394834, lng: 18.9834102 },
    { lat: 48.7394706, lng: 18.9832688 },
    { lat: 48.7394551, lng: 18.9830984 },
    { lat: 48.7393322, lng: 18.9828079 },
    { lat: 48.7392708, lng: 18.9826647 },
    { lat: 48.7392585, lng: 18.9825554 },
    { lat: 48.7392858, lng: 18.9820928 },
    { lat: 48.7392996, lng: 18.9818516 },
    { lat: 48.7392718, lng: 18.9813679 },
    { lat: 48.7392548, lng: 18.9813681 },
    { lat: 48.7392121, lng: 18.9813702 },
    { lat: 48.7391132, lng: 18.9813756 },
    { lat: 48.739031, lng: 18.981424 },
    { lat: 48.7389477, lng: 18.9813358 },
    { lat: 48.7389216, lng: 18.9812216 },
    { lat: 48.7388647, lng: 18.9811907 },
    { lat: 48.7388226, lng: 18.9809152 },
    { lat: 48.7387394, lng: 18.9808295 },
    { lat: 48.7386322, lng: 18.9808305 },
    { lat: 48.7385466, lng: 18.9806996 },
    { lat: 48.7384637, lng: 18.9805978 },
    { lat: 48.7383975, lng: 18.9804469 },
    { lat: 48.7383463, lng: 18.9802378 },
    { lat: 48.7382905, lng: 18.9800928 },
    { lat: 48.7382668, lng: 18.9799373 },
    { lat: 48.7381803, lng: 18.979853 },
    { lat: 48.7381021, lng: 18.9798485 },
    { lat: 48.7380789, lng: 18.9798347 },
    { lat: 48.7379599, lng: 18.9797557 },
    { lat: 48.7379056, lng: 18.9796634 },
    { lat: 48.7378175, lng: 18.9796132 },
    { lat: 48.7378248, lng: 18.979538 },
    { lat: 48.7377919, lng: 18.9793786 },
    { lat: 48.7376873, lng: 18.9793382 },
    { lat: 48.7376608, lng: 18.979327 },
    { lat: 48.7375523, lng: 18.9792985 },
    { lat: 48.7375128, lng: 18.9791431 },
    { lat: 48.7374144, lng: 18.9791359 },
    { lat: 48.7373195, lng: 18.9790862 },
    { lat: 48.7372662, lng: 18.9789916 },
    { lat: 48.7372649, lng: 18.9788539 },
    { lat: 48.7371944, lng: 18.9786888 },
    { lat: 48.7371836, lng: 18.9786754 },
    { lat: 48.7370333, lng: 18.9784892 },
    { lat: 48.7367827, lng: 18.9784197 },
    { lat: 48.7366332, lng: 18.9784252 },
    { lat: 48.7365412, lng: 18.978168 },
    { lat: 48.7364486, lng: 18.9780976 },
    { lat: 48.7362846, lng: 18.9778452 },
    { lat: 48.7361474, lng: 18.9776732 },
    { lat: 48.7360574, lng: 18.9775858 },
    { lat: 48.7360515, lng: 18.9774932 },
    { lat: 48.7357496, lng: 18.9772557 },
    { lat: 48.735657, lng: 18.9772057 },
    { lat: 48.7354923, lng: 18.977093 },
    { lat: 48.7353182, lng: 18.9770417 },
    { lat: 48.7351864, lng: 18.976978 },
    { lat: 48.7351437, lng: 18.9768901 },
    { lat: 48.735149, lng: 18.9768167 },
    { lat: 48.7351241, lng: 18.9767267 },
    { lat: 48.7350465, lng: 18.9765753 },
    { lat: 48.7350661, lng: 18.9764919 },
    { lat: 48.7350834, lng: 18.976301 },
    { lat: 48.7351619, lng: 18.9761162 },
    { lat: 48.7352598, lng: 18.9757091 },
    { lat: 48.7352858, lng: 18.9755785 },
    { lat: 48.7354614, lng: 18.9756811 },
    { lat: 48.7355304, lng: 18.9755991 },
    { lat: 48.7356596, lng: 18.9756117 },
    { lat: 48.7357491, lng: 18.9756009 },
    { lat: 48.7358129, lng: 18.9755685 },
    { lat: 48.7356606, lng: 18.975543 },
    { lat: 48.7356259, lng: 18.9753066 },
    { lat: 48.7355442, lng: 18.9752504 },
    { lat: 48.7355298, lng: 18.9751199 },
    { lat: 48.735417, lng: 18.9750308 },
    { lat: 48.7354274, lng: 18.9749239 },
    { lat: 48.7355589, lng: 18.9746743 },
    { lat: 48.7355731, lng: 18.9745537 },
    { lat: 48.7356421, lng: 18.9744225 },
    { lat: 48.73568, lng: 18.9743038 },
    { lat: 48.7356512, lng: 18.9742018 },
    { lat: 48.7356949, lng: 18.9740867 },
    { lat: 48.7357144, lng: 18.9739584 },
    { lat: 48.7357959, lng: 18.9737203 },
    { lat: 48.735888, lng: 18.9736271 },
    { lat: 48.7359385, lng: 18.9735316 },
    { lat: 48.736023, lng: 18.9734182 },
    { lat: 48.7362437, lng: 18.9734787 },
    { lat: 48.7363656, lng: 18.9734142 },
    { lat: 48.7363192, lng: 18.973101 },
    { lat: 48.7362331, lng: 18.9726938 },
    { lat: 48.7362144, lng: 18.9726128 },
    { lat: 48.7361837, lng: 18.9724738 },
    { lat: 48.7360791, lng: 18.9721491 },
    { lat: 48.7359864, lng: 18.9718545 },
    { lat: 48.7359002, lng: 18.9714866 },
    { lat: 48.7358766, lng: 18.97147 },
    { lat: 48.7358031, lng: 18.9714167 },
    { lat: 48.7358009, lng: 18.9714199 },
    { lat: 48.7357235, lng: 18.9715163 },
    { lat: 48.7354689, lng: 18.971164 },
    { lat: 48.7353996, lng: 18.9710874 },
    { lat: 48.7350541, lng: 18.9706996 },
    { lat: 48.734926, lng: 18.9704898 },
    { lat: 48.7347571, lng: 18.970224 },
    { lat: 48.7346423, lng: 18.9700948 },
    { lat: 48.7345326, lng: 18.9699775 },
    { lat: 48.7345332, lng: 18.9699878 },
    { lat: 48.7344128, lng: 18.9698825 },
    { lat: 48.7343293, lng: 18.9697704 },
    { lat: 48.7342142, lng: 18.9696805 },
    { lat: 48.7340229, lng: 18.9694449 },
    { lat: 48.7339568, lng: 18.9691625 },
    { lat: 48.7338537, lng: 18.9691555 },
    { lat: 48.7338162, lng: 18.9691013 },
    { lat: 48.7334352, lng: 18.9685962 },
    { lat: 48.7332509, lng: 18.9684926 },
    { lat: 48.7330393, lng: 18.9685039 },
    { lat: 48.7329024, lng: 18.9684923 },
    { lat: 48.7326882, lng: 18.96847 },
    { lat: 48.7325298, lng: 18.9683245 },
    { lat: 48.7324386, lng: 18.9683765 },
    { lat: 48.7323922, lng: 18.9683694 },
    { lat: 48.7321206, lng: 18.9683295 },
    { lat: 48.7321008, lng: 18.9683403 },
    { lat: 48.7318872, lng: 18.9684187 },
    { lat: 48.7315754, lng: 18.9682713 },
    { lat: 48.7314068, lng: 18.9684591 },
    { lat: 48.7314141, lng: 18.9684907 },
    { lat: 48.7314424, lng: 18.9686067 },
    { lat: 48.7313034, lng: 18.9686004 },
    { lat: 48.7311536, lng: 18.968557 },
    { lat: 48.730983, lng: 18.968595 },
    { lat: 48.7308235, lng: 18.9685857 },
    { lat: 48.7308094, lng: 18.9685723 },
    { lat: 48.7306982, lng: 18.9684724 },
    { lat: 48.7306341, lng: 18.9684523 },
    { lat: 48.7305711, lng: 18.9684346 },
    { lat: 48.7304619, lng: 18.968368 },
    { lat: 48.7303829, lng: 18.9683247 },
    { lat: 48.7303018, lng: 18.9682998 },
    { lat: 48.7301436, lng: 18.9681857 },
    { lat: 48.7299928, lng: 18.9680771 },
    { lat: 48.7301684, lng: 18.9678099 },
    { lat: 48.7300771, lng: 18.9677412 },
    { lat: 48.7299644, lng: 18.9676061 },
    { lat: 48.7299084, lng: 18.9674162 },
    { lat: 48.7297244, lng: 18.9671468 },
    { lat: 48.729835, lng: 18.9670133 },
    { lat: 48.7297072, lng: 18.9665195 },
    { lat: 48.7296221, lng: 18.966129 },
    { lat: 48.7293272, lng: 18.9658726 },
    { lat: 48.7294685, lng: 18.9659058 },
    { lat: 48.729567, lng: 18.9658897 },
    { lat: 48.7294756, lng: 18.9657103 },
    { lat: 48.7294298, lng: 18.965631 },
    { lat: 48.7293536, lng: 18.9653074 },
    { lat: 48.7292401, lng: 18.9648463 },
    { lat: 48.7291513, lng: 18.9648094 },
    { lat: 48.7291545, lng: 18.9650501 },
    { lat: 48.7289853, lng: 18.9654712 },
    { lat: 48.7287297, lng: 18.9653894 },
    { lat: 48.7286714, lng: 18.9653709 },
    { lat: 48.7286178, lng: 18.9654278 },
    { lat: 48.7285652, lng: 18.9647221 },
    { lat: 48.7285394, lng: 18.9646384 },
    { lat: 48.728544, lng: 18.9644403 },
    { lat: 48.7284898, lng: 18.9643923 },
    { lat: 48.7283888, lng: 18.9643148 },
    { lat: 48.7281204, lng: 18.9642274 },
    { lat: 48.7278683, lng: 18.9641006 },
    { lat: 48.7277398, lng: 18.9638373 },
    { lat: 48.727691, lng: 18.9636301 },
    { lat: 48.7273647, lng: 18.9634205 },
    { lat: 48.7273382, lng: 18.96341 },
    { lat: 48.7270597, lng: 18.963317 },
    { lat: 48.726987, lng: 18.9630637 },
    { lat: 48.7269097, lng: 18.9627895 },
    { lat: 48.7269588, lng: 18.9621335 },
    { lat: 48.726953, lng: 18.9620645 },
    { lat: 48.7269346, lng: 18.9617688 },
    { lat: 48.7267636, lng: 18.9617097 },
    { lat: 48.7268074, lng: 18.9615054 },
    { lat: 48.7271422, lng: 18.9615954 },
    { lat: 48.7271799, lng: 18.9616017 },
    { lat: 48.7272098, lng: 18.9615251 },
    { lat: 48.7272245, lng: 18.9611962 },
    { lat: 48.7272157, lng: 18.9611112 },
    { lat: 48.7272088, lng: 18.961042 },
    { lat: 48.7271249, lng: 18.9609664 },
    { lat: 48.72704, lng: 18.9607598 },
    { lat: 48.7270385, lng: 18.9607546 },
    { lat: 48.7270111, lng: 18.9605457 },
    { lat: 48.7270014, lng: 18.9605315 },
    { lat: 48.7268743, lng: 18.9603416 },
    { lat: 48.726848, lng: 18.9602425 },
    { lat: 48.7268283, lng: 18.9599666 },
    { lat: 48.7268291, lng: 18.9596944 },
    { lat: 48.7267704, lng: 18.9595148 },
    { lat: 48.7267775, lng: 18.9593019 },
    { lat: 48.7267317, lng: 18.959219 },
    { lat: 48.7265891, lng: 18.9591877 },
    { lat: 48.7264817, lng: 18.9590505 },
    { lat: 48.7263958, lng: 18.958867 },
    { lat: 48.7263413, lng: 18.958618 },
    { lat: 48.7263164, lng: 18.9585466 },
    { lat: 48.7262714, lng: 18.9584382 },
    { lat: 48.7262072, lng: 18.9582857 },
    { lat: 48.7260168, lng: 18.9579982 },
    { lat: 48.7259182, lng: 18.9578575 },
    { lat: 48.7259048, lng: 18.9578359 },
    { lat: 48.7258743, lng: 18.9577469 },
    { lat: 48.7259692, lng: 18.9576195 },
    { lat: 48.7259742, lng: 18.9574068 },
    { lat: 48.7260274, lng: 18.9573185 },
    { lat: 48.7260966, lng: 18.9572404 },
    { lat: 48.7261617, lng: 18.9569925 },
    { lat: 48.7263165, lng: 18.956797 },
    { lat: 48.7260915, lng: 18.9564339 },
    { lat: 48.7259667, lng: 18.9566599 },
    { lat: 48.7258839, lng: 18.9567613 },
    { lat: 48.7257429, lng: 18.9566262 },
    { lat: 48.7257643, lng: 18.9563366 },
    { lat: 48.725655, lng: 18.9559394 },
    { lat: 48.7255939, lng: 18.9558718 },
    { lat: 48.7255598, lng: 18.9557315 },
    { lat: 48.7254914, lng: 18.9556956 },
    { lat: 48.7254922, lng: 18.9555537 },
    { lat: 48.7253942, lng: 18.9554483 },
    { lat: 48.7253444, lng: 18.9552688 },
    { lat: 48.7251785, lng: 18.9552631 },
    { lat: 48.72517, lng: 18.9552494 },
    { lat: 48.7251119, lng: 18.9551522 },
    { lat: 48.7248553, lng: 18.9552917 },
    { lat: 48.7245834, lng: 18.9556672 },
    { lat: 48.7244491, lng: 18.9556425 },
    { lat: 48.7245148, lng: 18.9554716 },
    { lat: 48.7244788, lng: 18.9554516 },
    { lat: 48.7243412, lng: 18.9553833 },
    { lat: 48.7243154, lng: 18.9554523 },
    { lat: 48.7242968, lng: 18.9554856 },
    { lat: 48.7242783, lng: 18.9554992 },
    { lat: 48.7241823, lng: 18.9555632 },
    { lat: 48.7241529, lng: 18.9555827 },
    { lat: 48.7240678, lng: 18.9558382 },
    { lat: 48.7240579, lng: 18.9558619 },
    { lat: 48.7239646, lng: 18.9560656 },
    { lat: 48.7238159, lng: 18.9562278 },
    { lat: 48.7235406, lng: 18.9561324 },
    { lat: 48.7233602, lng: 18.9560476 },
    { lat: 48.7229618, lng: 18.9565014 },
    { lat: 48.7227939, lng: 18.9565713 },
    { lat: 48.7226646, lng: 18.9567338 },
    { lat: 48.7225293, lng: 18.9566652 },
    { lat: 48.7224895, lng: 18.9565463 },
    { lat: 48.722273, lng: 18.9565922 },
    { lat: 48.7222748, lng: 18.9566034 },
    { lat: 48.722288, lng: 18.9568218 },
    { lat: 48.7221911, lng: 18.9567331 },
    { lat: 48.7221593, lng: 18.9567711 },
    { lat: 48.7221652, lng: 18.9569115 },
    { lat: 48.7220855, lng: 18.9568954 },
    { lat: 48.7220492, lng: 18.9567807 },
    { lat: 48.7220093, lng: 18.956795 },
    { lat: 48.7219836, lng: 18.9569312 },
    { lat: 48.7219112, lng: 18.9568839 },
    { lat: 48.7217655, lng: 18.9568565 },
    { lat: 48.7217441, lng: 18.956859 },
    { lat: 48.7216275, lng: 18.9568704 },
    { lat: 48.7215096, lng: 18.9568798 },
    { lat: 48.7214024, lng: 18.9569866 },
    { lat: 48.7214144, lng: 18.9571584 },
    { lat: 48.7212804, lng: 18.9571393 },
    { lat: 48.7211724, lng: 18.9574075 },
    { lat: 48.7208636, lng: 18.9577005 },
    { lat: 48.7210098, lng: 18.9581124 },
    { lat: 48.7207147, lng: 18.9577871 },
    { lat: 48.7206378, lng: 18.9579149 },
    { lat: 48.7205369, lng: 18.9578406 },
    { lat: 48.7203018, lng: 18.9578729 },
    { lat: 48.7202199, lng: 18.9577896 },
    { lat: 48.7201717, lng: 18.9576427 },
    { lat: 48.7201588, lng: 18.9574768 },
    { lat: 48.720153, lng: 18.9574268 },
    { lat: 48.7201273, lng: 18.956992 },
    { lat: 48.7202817, lng: 18.9567894 },
    { lat: 48.7204172, lng: 18.9567512 },
    { lat: 48.7205491, lng: 18.9566601 },
    { lat: 48.7206986, lng: 18.9564967 },
    { lat: 48.7206513, lng: 18.9563422 },
    { lat: 48.7206005, lng: 18.9562764 },
    { lat: 48.720549, lng: 18.9562342 },
    { lat: 48.720296, lng: 18.9561611 },
    { lat: 48.7200764, lng: 18.9562991 },
    { lat: 48.7199886, lng: 18.9560593 },
    { lat: 48.7198741, lng: 18.9560241 },
    { lat: 48.7198147, lng: 18.9561881 },
    { lat: 48.71967, lng: 18.956271 },
    { lat: 48.7194807, lng: 18.956158 },
    { lat: 48.7192922, lng: 18.9560003 },
    { lat: 48.7192663, lng: 18.9559787 },
    { lat: 48.7191634, lng: 18.9556854 },
    { lat: 48.71904, lng: 18.9554323 },
    { lat: 48.7190237, lng: 18.9553999 },
    { lat: 48.7188952, lng: 18.9553124 },
    { lat: 48.7189637, lng: 18.9551951 },
    { lat: 48.7188859, lng: 18.9551303 },
    { lat: 48.718734, lng: 18.955199 },
    { lat: 48.7187049, lng: 18.9550278 },
    { lat: 48.7184323, lng: 18.9548099 },
    { lat: 48.7183793, lng: 18.9549249 },
    { lat: 48.71826, lng: 18.9549717 },
    { lat: 48.7181812, lng: 18.954928 },
    { lat: 48.7179196, lng: 18.955038 },
    { lat: 48.7176597, lng: 18.9550929 },
    { lat: 48.7176699, lng: 18.955141 },
    { lat: 48.7175089, lng: 18.9551893 },
    { lat: 48.7173828, lng: 18.9551904 },
    { lat: 48.7170515, lng: 18.9553282 },
    { lat: 48.7169308, lng: 18.9553947 },
    { lat: 48.7168535, lng: 18.9554908 },
    { lat: 48.7168086, lng: 18.955319 },
    { lat: 48.716694, lng: 18.9551709 },
    { lat: 48.7164734, lng: 18.9549813 },
    { lat: 48.7165913, lng: 18.954749 },
    { lat: 48.7169512, lng: 18.9546443 },
    { lat: 48.7172764, lng: 18.9544966 },
    { lat: 48.7173967, lng: 18.9544893 },
    { lat: 48.7174762, lng: 18.9543933 },
    { lat: 48.7174833, lng: 18.954265 },
    { lat: 48.7175334, lng: 18.9542089 },
    { lat: 48.7176079, lng: 18.9542759 },
    { lat: 48.7178218, lng: 18.9542482 },
    { lat: 48.7180715, lng: 18.9542813 },
    { lat: 48.7181146, lng: 18.9542641 },
    { lat: 48.7179192, lng: 18.9539262 },
    { lat: 48.7178693, lng: 18.9538741 },
    { lat: 48.7178042, lng: 18.9537914 },
    { lat: 48.7177275, lng: 18.9536121 },
    { lat: 48.7177632, lng: 18.9533852 },
    { lat: 48.7179035, lng: 18.9531939 },
    { lat: 48.7178938, lng: 18.9530926 },
    { lat: 48.7178428, lng: 18.9528471 },
    { lat: 48.7178685, lng: 18.952772 },
    { lat: 48.7179177, lng: 18.9526995 },
    { lat: 48.7179765, lng: 18.9524152 },
    { lat: 48.7180668, lng: 18.952329 },
    { lat: 48.7182412, lng: 18.952167 },
    { lat: 48.7184288, lng: 18.9519763 },
    { lat: 48.7185089, lng: 18.9518227 },
    { lat: 48.7186285, lng: 18.9516674 },
    { lat: 48.7187841, lng: 18.9514693 },
    { lat: 48.7188081, lng: 18.9513898 },
    { lat: 48.7190306, lng: 18.9514392 },
    { lat: 48.7191716, lng: 18.9513721 },
    { lat: 48.7193229, lng: 18.9514213 },
    { lat: 48.7194347, lng: 18.9514036 },
    { lat: 48.7194773, lng: 18.9513533 },
    { lat: 48.7196784, lng: 18.9511882 },
    { lat: 48.7198227, lng: 18.9511174 },
    { lat: 48.7199917, lng: 18.9509371 },
    { lat: 48.7199236, lng: 18.950709 },
    { lat: 48.7199394, lng: 18.9504436 },
    { lat: 48.7199384, lng: 18.9502245 },
    { lat: 48.7200987, lng: 18.9500105 },
    { lat: 48.7202865, lng: 18.9499298 },
    { lat: 48.7203988, lng: 18.9498806 },
    { lat: 48.7203893, lng: 18.9498467 },
    { lat: 48.720368, lng: 18.9497824 },
    { lat: 48.7204312, lng: 18.9493855 },
    { lat: 48.7203845, lng: 18.9493124 },
    { lat: 48.7203933, lng: 18.9492164 },
    { lat: 48.7203383, lng: 18.9489807 },
    { lat: 48.7203205, lng: 18.9489381 },
    { lat: 48.7203146, lng: 18.9488035 },
    { lat: 48.720239, lng: 18.9487798 },
    { lat: 48.7201612, lng: 18.9487146 },
    { lat: 48.719971, lng: 18.9486074 },
    { lat: 48.7197747, lng: 18.9485142 },
    { lat: 48.7196649, lng: 18.9484881 },
    { lat: 48.7195781, lng: 18.948334 },
    { lat: 48.719546, lng: 18.9482788 },
    { lat: 48.7195354, lng: 18.9482693 },
    { lat: 48.7194834, lng: 18.9482243 },
    { lat: 48.7194869, lng: 18.9479168 },
    { lat: 48.7194837, lng: 18.9478536 },
    { lat: 48.7194797, lng: 18.9477298 },
    { lat: 48.7194773, lng: 18.9476597 },
    { lat: 48.7197827, lng: 18.9473471 },
    { lat: 48.7197931, lng: 18.9473095 },
    { lat: 48.7198275, lng: 18.947188 },
    { lat: 48.719817, lng: 18.9471574 },
    { lat: 48.7198035, lng: 18.9471144 },
    { lat: 48.7197915, lng: 18.9470779 },
    { lat: 48.7197687, lng: 18.9470074 },
    { lat: 48.7197448, lng: 18.946868 },
    { lat: 48.7197523, lng: 18.9466851 },
    { lat: 48.7197435, lng: 18.9466429 },
    { lat: 48.7197241, lng: 18.9465726 },
    { lat: 48.719723, lng: 18.9465317 },
    { lat: 48.7197213, lng: 18.9464766 },
    { lat: 48.7197161, lng: 18.9463736 },
    { lat: 48.7196695, lng: 18.9463219 },
    { lat: 48.719671, lng: 18.9461935 },
    { lat: 48.7196929, lng: 18.9460942 },
    { lat: 48.7197452, lng: 18.9460124 },
    { lat: 48.7198232, lng: 18.945908 },
    { lat: 48.7198222, lng: 18.9458449 },
    { lat: 48.7197591, lng: 18.9458001 },
    { lat: 48.7197611, lng: 18.9457092 },
    { lat: 48.7197817, lng: 18.9456904 },
    { lat: 48.7198851, lng: 18.9455976 },
    { lat: 48.7200439, lng: 18.945482 },
    { lat: 48.7202157, lng: 18.9453809 },
    { lat: 48.7204078, lng: 18.9452789 },
    { lat: 48.7205638, lng: 18.9451729 },
    { lat: 48.7206393, lng: 18.9450413 },
    { lat: 48.7206433, lng: 18.9449448 },
    { lat: 48.7205251, lng: 18.9449246 },
    { lat: 48.7205098, lng: 18.9447347 },
    { lat: 48.720665, lng: 18.9445952 },
    { lat: 48.7208004, lng: 18.9445076 },
    { lat: 48.7205528, lng: 18.9442304 },
    { lat: 48.7204328, lng: 18.9441098 },
    { lat: 48.7202709, lng: 18.9440544 },
    { lat: 48.7200954, lng: 18.9438377 },
    { lat: 48.720007, lng: 18.9437848 },
    { lat: 48.719629, lng: 18.9440674 },
    { lat: 48.7194718, lng: 18.9441679 },
    { lat: 48.7193441, lng: 18.944232 },
    { lat: 48.7192657, lng: 18.944259 },
    { lat: 48.719068, lng: 18.9442324 },
    { lat: 48.7189134, lng: 18.9442736 },
    { lat: 48.7187226, lng: 18.9443815 },
    { lat: 48.7185536, lng: 18.9444687 },
    { lat: 48.7184174, lng: 18.9446981 },
    { lat: 48.7182277, lng: 18.9447588 },
    { lat: 48.7180546, lng: 18.9447889 },
    { lat: 48.7179421, lng: 18.9446624 },
    { lat: 48.7178925, lng: 18.9443975 },
    { lat: 48.717955, lng: 18.9442946 },
    { lat: 48.7179648, lng: 18.9442695 },
    { lat: 48.7179751, lng: 18.9442247 },
    { lat: 48.7179642, lng: 18.9442331 },
    { lat: 48.7179996, lng: 18.9440844 },
    { lat: 48.7181087, lng: 18.9438839 },
    { lat: 48.7181071, lng: 18.9438716 },
    { lat: 48.7180963, lng: 18.9438182 },
    { lat: 48.718084, lng: 18.9437746 },
    { lat: 48.7180497, lng: 18.9436772 },
    { lat: 48.7178736, lng: 18.9435828 },
    { lat: 48.7177749, lng: 18.9435057 },
    { lat: 48.7177378, lng: 18.9434868 },
    { lat: 48.7172533, lng: 18.9433169 },
    { lat: 48.7171658, lng: 18.9432139 },
    { lat: 48.7171564, lng: 18.9432064 },
    { lat: 48.7171352, lng: 18.9431882 },
    { lat: 48.7170414, lng: 18.9431599 },
    { lat: 48.7169996, lng: 18.9431615 },
    { lat: 48.7165447, lng: 18.9431731 },
    { lat: 48.716563, lng: 18.9430692 },
    { lat: 48.7165205, lng: 18.9429635 },
    { lat: 48.7165564, lng: 18.9428518 },
    { lat: 48.7165341, lng: 18.9427666 },
    { lat: 48.716724, lng: 18.9420239 },
    { lat: 48.7167357, lng: 18.9417676 },
    { lat: 48.71679, lng: 18.9416131 },
    { lat: 48.7168731, lng: 18.9415663 },
    { lat: 48.7168853, lng: 18.9415599 },
    { lat: 48.7167118, lng: 18.941434 },
    { lat: 48.7166647, lng: 18.9414135 },
    { lat: 48.7163396, lng: 18.9414731 },
    { lat: 48.7163161, lng: 18.9414766 },
    { lat: 48.7161472, lng: 18.9414356 },
    { lat: 48.7159817, lng: 18.9413313 },
    { lat: 48.7158833, lng: 18.941236 },
    { lat: 48.7156717, lng: 18.9409797 },
    { lat: 48.7156595, lng: 18.9409615 },
    { lat: 48.7156365, lng: 18.9408624 },
    { lat: 48.7156288, lng: 18.9408462 },
    { lat: 48.7156012, lng: 18.9407908 },
    { lat: 48.7155874, lng: 18.9407624 },
    { lat: 48.7155628, lng: 18.9407425 },
    { lat: 48.7154286, lng: 18.9406313 },
    { lat: 48.7154202, lng: 18.9406248 },
    { lat: 48.715544, lng: 18.940334 },
    { lat: 48.7155589, lng: 18.9401391 },
    { lat: 48.7154481, lng: 18.9399357 },
    { lat: 48.7152513, lng: 18.9397462 },
    { lat: 48.7151364, lng: 18.9398556 },
    { lat: 48.714819, lng: 18.9395806 },
    { lat: 48.7146772, lng: 18.9394968 },
    { lat: 48.7143456, lng: 18.9391662 },
    { lat: 48.7142154, lng: 18.9390865 },
    { lat: 48.7140862, lng: 18.9388748 },
    { lat: 48.713944, lng: 18.9386764 },
    { lat: 48.7138582, lng: 18.9384719 },
    { lat: 48.7137596, lng: 18.9381974 },
    { lat: 48.7136895, lng: 18.938042 },
    { lat: 48.713731, lng: 18.9379069 },
    { lat: 48.7136788, lng: 18.9374759 },
    { lat: 48.7137545, lng: 18.93746 },
    { lat: 48.7138553, lng: 18.937204 },
    { lat: 48.7138735, lng: 18.9371626 },
    { lat: 48.7138048, lng: 18.9371396 },
    { lat: 48.7137719, lng: 18.9369813 },
    { lat: 48.7137889, lng: 18.9369165 },
    { lat: 48.7138026, lng: 18.9368795 },
    { lat: 48.7136322, lng: 18.9366984 },
    { lat: 48.7136172, lng: 18.9365563 },
    { lat: 48.7136633, lng: 18.936488 },
    { lat: 48.7136711, lng: 18.9363129 },
    { lat: 48.7136723, lng: 18.9362717 },
    { lat: 48.7136199, lng: 18.9361932 },
    { lat: 48.7136307, lng: 18.9360298 },
    { lat: 48.7136931, lng: 18.9357712 },
    { lat: 48.713702, lng: 18.9357477 },
    { lat: 48.7137639, lng: 18.935613 },
    { lat: 48.7138549, lng: 18.935562 },
    { lat: 48.7139451, lng: 18.9354083 },
    { lat: 48.7140119, lng: 18.9353009 },
    { lat: 48.7140429, lng: 18.9352231 },
    { lat: 48.7140675, lng: 18.9351781 },
    { lat: 48.71411, lng: 18.9352145 },
    { lat: 48.7141638, lng: 18.9351429 },
    { lat: 48.7142037, lng: 18.9351515 },
    { lat: 48.7143071, lng: 18.9349469 },
    { lat: 48.7143128, lng: 18.934726 },
    { lat: 48.7143141, lng: 18.9345291 },
    { lat: 48.7143237, lng: 18.9342316 },
    { lat: 48.7142777, lng: 18.9339289 },
    { lat: 48.7142637, lng: 18.9338298 },
    { lat: 48.7144416, lng: 18.9338023 },
    { lat: 48.7145095, lng: 18.9338729 },
    { lat: 48.7146654, lng: 18.9339451 },
    { lat: 48.7147503, lng: 18.9339512 },
    { lat: 48.7148317, lng: 18.9339135 },
    { lat: 48.7148401, lng: 18.9340125 },
    { lat: 48.714882, lng: 18.9340369 },
    { lat: 48.7149701, lng: 18.9340405 },
    { lat: 48.715034, lng: 18.9339238 },
    { lat: 48.7151372, lng: 18.9338903 },
    { lat: 48.7151588, lng: 18.9338278 },
    { lat: 48.7153898, lng: 18.9338902 },
    { lat: 48.7154874, lng: 18.9338177 },
    { lat: 48.7155816, lng: 18.9337603 },
    { lat: 48.7155609, lng: 18.9335763 },
    { lat: 48.7154791, lng: 18.9335434 },
    { lat: 48.715396, lng: 18.9334388 },
    { lat: 48.7155132, lng: 18.933262 },
    { lat: 48.7155492, lng: 18.9331071 },
    { lat: 48.715672, lng: 18.9330603 },
    { lat: 48.7157052, lng: 18.9330493 },
    { lat: 48.715702, lng: 18.9329861 },
    { lat: 48.7158462, lng: 18.932936 },
    { lat: 48.7159521, lng: 18.9328912 },
    { lat: 48.7160866, lng: 18.9327859 },
    { lat: 48.7160275, lng: 18.9326014 },
    { lat: 48.7160208, lng: 18.9325807 },
    { lat: 48.7160966, lng: 18.9323898 },
    { lat: 48.7162107, lng: 18.9323015 },
    { lat: 48.7164891, lng: 18.9320355 },
    { lat: 48.7165584, lng: 18.9319167 },
    { lat: 48.7166308, lng: 18.9318533 },
    { lat: 48.7168019, lng: 18.931849 },
    { lat: 48.71692, lng: 18.9318634 },
    { lat: 48.7169608, lng: 18.9318251 },
    { lat: 48.7170233, lng: 18.9316568 },
    { lat: 48.7170709, lng: 18.9314645 },
    { lat: 48.7170701, lng: 18.9313146 },
    { lat: 48.7175663, lng: 18.9315602 },
    { lat: 48.7177686, lng: 18.9316355 },
    { lat: 48.7180972, lng: 18.9318653 },
    { lat: 48.7183902, lng: 18.9320414 },
    { lat: 48.7187665, lng: 18.9322977 },
    { lat: 48.718978, lng: 18.9324662 },
    { lat: 48.7195174, lng: 18.9328268 },
    { lat: 48.7196732, lng: 18.9325008 },
    { lat: 48.719796, lng: 18.9323461 },
    { lat: 48.7198547, lng: 18.9321264 },
    { lat: 48.7199066, lng: 18.9324985 },
    { lat: 48.7199375, lng: 18.932887 },
    { lat: 48.7200258, lng: 18.9333109 },
    { lat: 48.7200445, lng: 18.9335008 },
    { lat: 48.7200707, lng: 18.9337913 },
    { lat: 48.7200808, lng: 18.9340533 },
    { lat: 48.7200751, lng: 18.9340989 },
    { lat: 48.7199166, lng: 18.9346591 },
    { lat: 48.720155, lng: 18.9349327 },
    { lat: 48.720247, lng: 18.9350787 },
    { lat: 48.7203182, lng: 18.9352379 },
    { lat: 48.7203979, lng: 18.9353221 },
    { lat: 48.7205218, lng: 18.9353191 },
    { lat: 48.7207192, lng: 18.9355382 },
    { lat: 48.720773, lng: 18.9354212 },
    { lat: 48.7207885, lng: 18.935374 },
    { lat: 48.7208059, lng: 18.9353156 },
    { lat: 48.7208155, lng: 18.935282 },
    { lat: 48.7208728, lng: 18.9351453 },
    { lat: 48.7208825, lng: 18.9351181 },
    { lat: 48.7209152, lng: 18.9350043 },
    { lat: 48.7209224, lng: 18.9349692 },
    { lat: 48.7209152, lng: 18.934829 },
    { lat: 48.7209489, lng: 18.9347804 },
    { lat: 48.7209626, lng: 18.9347628 },
    { lat: 48.721071, lng: 18.9346137 },
    { lat: 48.7212852, lng: 18.9345691 },
    { lat: 48.7214602, lng: 18.9344858 },
    { lat: 48.7216047, lng: 18.9344889 },
    { lat: 48.7216689, lng: 18.9344629 },
    { lat: 48.7219844, lng: 18.9345075 },
    { lat: 48.7220576, lng: 18.9344832 },
    { lat: 48.7221575, lng: 18.9344501 },
    { lat: 48.7222414, lng: 18.9344121 },
    { lat: 48.7223496, lng: 18.9343477 },
    { lat: 48.7223636, lng: 18.9343361 },
    { lat: 48.7225278, lng: 18.9342373 },
    { lat: 48.7225855, lng: 18.9341738 },
    { lat: 48.7225951, lng: 18.9341634 },
    { lat: 48.7226625, lng: 18.934183 },
    { lat: 48.7227691, lng: 18.9341752 },
    { lat: 48.7228577, lng: 18.934207 },
    { lat: 48.722939, lng: 18.9341432 },
    { lat: 48.7230446, lng: 18.9341145 },
    { lat: 48.7231356, lng: 18.9340828 },
    { lat: 48.7232711, lng: 18.9340245 },
    { lat: 48.7232922, lng: 18.933907 },
    { lat: 48.7234099, lng: 18.9338697 },
    { lat: 48.7234104, lng: 18.9338575 },
    { lat: 48.723409, lng: 18.9337874 },
    { lat: 48.7234089, lng: 18.9337627 },
    { lat: 48.7234091, lng: 18.9337438 },
    { lat: 48.7234254, lng: 18.9337115 },
    { lat: 48.7234748, lng: 18.933619 },
    { lat: 48.7234944, lng: 18.933582 },
    { lat: 48.7235606, lng: 18.9335083 },
    { lat: 48.723639, lng: 18.9334345 },
    { lat: 48.7237074, lng: 18.9333629 },
    { lat: 48.7237638, lng: 18.9333156 },
    { lat: 48.7236175, lng: 18.9329256 },
    { lat: 48.7235411, lng: 18.9327763 },
    { lat: 48.7235027, lng: 18.9326205 },
    { lat: 48.7235517, lng: 18.9323876 },
    { lat: 48.7236907, lng: 18.9323514 },
    { lat: 48.7237085, lng: 18.9322565 },
    { lat: 48.7237588, lng: 18.9321802 },
    { lat: 48.7237964, lng: 18.9321219 },
    { lat: 48.7238925, lng: 18.9317979 },
    { lat: 48.7240394, lng: 18.9313611 },
    { lat: 48.7242242, lng: 18.9308296 },
    { lat: 48.724331, lng: 18.9307796 },
    { lat: 48.7243412, lng: 18.9305627 },
    { lat: 48.7243671, lng: 18.9304501 },
    { lat: 48.7244891, lng: 18.930258 },
    { lat: 48.7245246, lng: 18.930181 },
    { lat: 48.7245542, lng: 18.9301175 },
    { lat: 48.7246953, lng: 18.9298782 },
    { lat: 48.7247146, lng: 18.9298585 },
    { lat: 48.7248401, lng: 18.9296877 },
    { lat: 48.724914, lng: 18.9296127 },
    { lat: 48.7251078, lng: 18.9296315 },
    { lat: 48.7251808, lng: 18.9296701 },
    { lat: 48.7252622, lng: 18.9296756 },
    { lat: 48.7252971, lng: 18.9296754 },
    { lat: 48.7254652, lng: 18.9296547 },
    { lat: 48.7255447, lng: 18.9295772 },
    { lat: 48.7258704, lng: 18.929376 },
    { lat: 48.7259843, lng: 18.9293305 },
    { lat: 48.7260818, lng: 18.929367 },
    { lat: 48.726192, lng: 18.9294041 },
    { lat: 48.7264155, lng: 18.9294087 },
    { lat: 48.7266032, lng: 18.9296839 },
    { lat: 48.726732, lng: 18.9298643 },
    { lat: 48.726785, lng: 18.9299548 },
    { lat: 48.7268161, lng: 18.929943 },
    { lat: 48.7270174, lng: 18.929847 },
    { lat: 48.7271218, lng: 18.929683 },
    { lat: 48.7271361, lng: 18.9296078 },
    { lat: 48.7273383, lng: 18.9296163 },
    { lat: 48.7275453, lng: 18.9297828 },
    { lat: 48.7275758, lng: 18.9298068 },
    { lat: 48.7276483, lng: 18.9297451 },
    { lat: 48.7277453, lng: 18.929748 },
    { lat: 48.7279013, lng: 18.9296662 },
    { lat: 48.7279847, lng: 18.9296426 },
    { lat: 48.7281002, lng: 18.9294994 },
    { lat: 48.7282107, lng: 18.9293212 },
    { lat: 48.7284679, lng: 18.9291719 },
    { lat: 48.7285254, lng: 18.9291283 },
    { lat: 48.7285387, lng: 18.9291236 },
    { lat: 48.7286354, lng: 18.9292494 },
    { lat: 48.7286723, lng: 18.9293526 },
    { lat: 48.7288094, lng: 18.9294798 },
    { lat: 48.7289316, lng: 18.9296416 },
    { lat: 48.7290225, lng: 18.9296317 },
    { lat: 48.7290845, lng: 18.929698 },
    { lat: 48.7291434, lng: 18.9298137 },
    { lat: 48.7291835, lng: 18.9299558 },
    { lat: 48.7292262, lng: 18.9301772 },
    { lat: 48.7292947, lng: 18.9302792 },
    { lat: 48.7293033, lng: 18.9303818 },
    { lat: 48.7293688, lng: 18.930517 },
    { lat: 48.7294375, lng: 18.9308904 },
    { lat: 48.7295046, lng: 18.9310336 },
    { lat: 48.7295825, lng: 18.9311238 },
    { lat: 48.7296744, lng: 18.9310452 },
    { lat: 48.7297807, lng: 18.9308978 },
    { lat: 48.7299739, lng: 18.930776 },
    { lat: 48.7301755, lng: 18.9306799 },
    { lat: 48.7301235, lng: 18.9304585 },
    { lat: 48.7301176, lng: 18.9304306 },
    { lat: 48.7302476, lng: 18.930219 },
    { lat: 48.7302571, lng: 18.9298079 },
    { lat: 48.7302127, lng: 18.9295548 },
    { lat: 48.7302553, lng: 18.9294873 },
    { lat: 48.7307316, lng: 18.9291863 },
    { lat: 48.73086, lng: 18.9289845 },
    { lat: 48.7309709, lng: 18.9287505 },
    { lat: 48.7309641, lng: 18.9286638 },
    { lat: 48.7309809, lng: 18.9286129 },
    { lat: 48.7309912, lng: 18.9285738 },
    { lat: 48.731006, lng: 18.928531 },
    { lat: 48.731007, lng: 18.9285095 },
    { lat: 48.7310079, lng: 18.9284797 },
    { lat: 48.7309914, lng: 18.9284674 },
    { lat: 48.7308439, lng: 18.92836 },
    { lat: 48.7308389, lng: 18.9282616 },
    { lat: 48.7308701, lng: 18.9281247 },
    { lat: 48.7309177, lng: 18.9277342 },
    { lat: 48.7309755, lng: 18.9275188 },
    { lat: 48.7311232, lng: 18.9272523 },
    { lat: 48.7311836, lng: 18.9271352 },
    { lat: 48.7311796, lng: 18.9270096 },
    { lat: 48.7311461, lng: 18.9267114 },
    { lat: 48.731097, lng: 18.9266103 },
    { lat: 48.7311081, lng: 18.9264704 },
    { lat: 48.7310997, lng: 18.9264414 },
    { lat: 48.7310626, lng: 18.9262 },
    { lat: 48.7311193, lng: 18.9259891 },
    { lat: 48.731182, lng: 18.9256892 },
    { lat: 48.7312283, lng: 18.9256056 },
    { lat: 48.7312291, lng: 18.9255523 },
    { lat: 48.7312282, lng: 18.9254478 },
    { lat: 48.731049, lng: 18.9252276 },
    { lat: 48.7311897, lng: 18.92379 },
    { lat: 48.7311976, lng: 18.9237041 },
    { lat: 48.7315364, lng: 18.9238466 },
    { lat: 48.7316756, lng: 18.9238578 },
    { lat: 48.7318621, lng: 18.9238457 },
    { lat: 48.7319822, lng: 18.9238091 },
    { lat: 48.7321608, lng: 18.9237536 },
    { lat: 48.732504, lng: 18.9236728 },
    { lat: 48.7327273, lng: 18.923632 },
    { lat: 48.7327589, lng: 18.9236308 },
    { lat: 48.7328993, lng: 18.9236222 },
    { lat: 48.7331313, lng: 18.9236173 },
    { lat: 48.7331341, lng: 18.9222179 },
    { lat: 48.7329448, lng: 18.9202134 },
    { lat: 48.7325026, lng: 18.9200551 },
    { lat: 48.7324534, lng: 18.9199341 },
    { lat: 48.7319202, lng: 18.9197368 },
    { lat: 48.7313666, lng: 18.9197583 },
    { lat: 48.7306969, lng: 18.9199606 },
    { lat: 48.7306404, lng: 18.919978 },
    { lat: 48.7304428, lng: 18.9200361 },
    { lat: 48.7301995, lng: 18.9201078 },
    { lat: 48.7299226, lng: 18.9200527 },
    { lat: 48.7298303, lng: 18.9200345 },
    { lat: 48.7298031, lng: 18.9200291 },
    { lat: 48.7296589, lng: 18.9198996 },
    { lat: 48.7290325, lng: 18.9197775 },
    { lat: 48.7287306, lng: 18.9197382 },
    { lat: 48.7286783, lng: 18.9197318 },
    { lat: 48.7285739, lng: 18.919718 },
    { lat: 48.7284298, lng: 18.9197887 },
    { lat: 48.7283243, lng: 18.920085 },
    { lat: 48.7282132, lng: 18.9200069 },
    { lat: 48.7279571, lng: 18.9201355 },
    { lat: 48.7278036, lng: 18.9200449 },
    { lat: 48.7275292, lng: 18.9198398 },
    { lat: 48.7273835, lng: 18.9197458 },
    { lat: 48.7272575, lng: 18.9196645 },
    { lat: 48.7271993, lng: 18.9196274 },
    { lat: 48.727981, lng: 18.9183148 },
    { lat: 48.7267841, lng: 18.9159446 },
    { lat: 48.7267509, lng: 18.9159374 },
    { lat: 48.7267487, lng: 18.9159369 },
    { lat: 48.7265171, lng: 18.9158858 },
    { lat: 48.726516, lng: 18.915886 },
    { lat: 48.7261864, lng: 18.9158128 },
    { lat: 48.7260552, lng: 18.9157838 },
    { lat: 48.7254596, lng: 18.9154704 },
    { lat: 48.7247034, lng: 18.9147961 },
    { lat: 48.7252667, lng: 18.9139934 },
    { lat: 48.7252288, lng: 18.9138732 },
    { lat: 48.7252049, lng: 18.9137978 },
    { lat: 48.7251899, lng: 18.9137506 },
    { lat: 48.7251873, lng: 18.9137445 },
    { lat: 48.725171, lng: 18.9136893 },
    { lat: 48.7251603, lng: 18.9136555 },
    { lat: 48.7251511, lng: 18.913628 },
    { lat: 48.7251321, lng: 18.9135667 },
    { lat: 48.7252812, lng: 18.9131982 },
    { lat: 48.7253533, lng: 18.9130894 },
    { lat: 48.7254951, lng: 18.9128635 },
    { lat: 48.7255202, lng: 18.9128237 },
    { lat: 48.7255452, lng: 18.9127848 },
    { lat: 48.7254523, lng: 18.9125767 },
    { lat: 48.7254091, lng: 18.9124811 },
    { lat: 48.725399, lng: 18.912308 },
    { lat: 48.7253262, lng: 18.9123615 },
    { lat: 48.7251333, lng: 18.9122495 },
    { lat: 48.724688, lng: 18.9119891 },
    { lat: 48.7246086, lng: 18.9120013 },
    { lat: 48.7245686, lng: 18.9121195 },
    { lat: 48.7244524, lng: 18.9120339 },
    { lat: 48.7240926, lng: 18.9121628 },
    { lat: 48.7239211, lng: 18.9120079 },
    { lat: 48.7235088, lng: 18.9117734 },
    { lat: 48.7229392, lng: 18.9113951 },
    { lat: 48.7229006, lng: 18.9110347 },
    { lat: 48.7228971, lng: 18.911013 },
    { lat: 48.7228689, lng: 18.9108588 },
    { lat: 48.7228597, lng: 18.9108131 },
    { lat: 48.7228346, lng: 18.910675 },
    { lat: 48.7228018, lng: 18.9104953 },
    { lat: 48.7226916, lng: 18.9100603 },
    { lat: 48.7225981, lng: 18.9096895 },
    { lat: 48.7225678, lng: 18.9096042 },
    { lat: 48.7225463, lng: 18.9095588 },
    { lat: 48.7224529, lng: 18.9093637 },
    { lat: 48.7221228, lng: 18.9086675 },
    { lat: 48.7220287, lng: 18.9085943 },
    { lat: 48.7215254, lng: 18.9082986 },
    { lat: 48.7214302, lng: 18.9081141 },
    { lat: 48.7213915, lng: 18.9080408 },
    { lat: 48.7213752, lng: 18.9080095 },
    { lat: 48.7213695, lng: 18.9079859 },
    { lat: 48.7213468, lng: 18.9078939 },
    { lat: 48.7212894, lng: 18.9078299 },
    { lat: 48.7211465, lng: 18.9077242 },
    { lat: 48.7211594, lng: 18.9075374 },
    { lat: 48.7211639, lng: 18.9074686 },
    { lat: 48.721163, lng: 18.9072588 },
    { lat: 48.7215702, lng: 18.9071032 },
    { lat: 48.7219823, lng: 18.9067399 },
    { lat: 48.7220361, lng: 18.9066711 },
    { lat: 48.7221849, lng: 18.9064708 },
    { lat: 48.722427, lng: 18.9062664 },
    { lat: 48.7225021, lng: 18.9061051 },
    { lat: 48.7226921, lng: 18.9058114 },
    { lat: 48.7228124, lng: 18.9056926 },
    { lat: 48.7228794, lng: 18.9056805 },
    { lat: 48.7231402, lng: 18.9056431 },
    { lat: 48.7231654, lng: 18.9056059 },
    { lat: 48.7232253, lng: 18.9054321 },
    { lat: 48.7232319, lng: 18.9050131 },
    { lat: 48.7232325, lng: 18.9049799 },
    { lat: 48.7232344, lng: 18.904905 },
    { lat: 48.7233, lng: 18.9045173 },
    { lat: 48.7231191, lng: 18.9039343 },
    { lat: 48.7229665, lng: 18.903424 },
    { lat: 48.7225972, lng: 18.9028171 },
    { lat: 48.7225369, lng: 18.9026784 },
    { lat: 48.7221881, lng: 18.9024058 },
    { lat: 48.7219056, lng: 18.9020633 },
    { lat: 48.7218922, lng: 18.9017145 },
    { lat: 48.7219967, lng: 18.9015316 },
    { lat: 48.7221604, lng: 18.9013606 },
    { lat: 48.7222979, lng: 18.9013841 },
    { lat: 48.7224454, lng: 18.9011375 },
    { lat: 48.7226731, lng: 18.9010479 },
    { lat: 48.7231264, lng: 18.9004987 },
    { lat: 48.7231782, lng: 18.9003666 },
    { lat: 48.7232192, lng: 18.900245 },
    { lat: 48.723299, lng: 18.899931 },
    { lat: 48.7235126, lng: 18.8998977 },
    { lat: 48.7234364, lng: 18.8992481 },
    { lat: 48.7234546, lng: 18.8987874 },
    { lat: 48.7233634, lng: 18.8983978 },
    { lat: 48.7231224, lng: 18.8981179 },
    { lat: 48.7229779, lng: 18.8978535 },
    { lat: 48.7229876, lng: 18.8975359 },
    { lat: 48.7223962, lng: 18.8971722 },
    { lat: 48.722141, lng: 18.8968183 },
    { lat: 48.7219978, lng: 18.8965973 },
    { lat: 48.7218561, lng: 18.8961668 },
    { lat: 48.7219063, lng: 18.8961099 },
    { lat: 48.7219793, lng: 18.8959941 },
    { lat: 48.7222032, lng: 18.8959186 },
    { lat: 48.7221909, lng: 18.8957679 },
    { lat: 48.7221141, lng: 18.8953959 },
    { lat: 48.7225288, lng: 18.8951065 },
    { lat: 48.7226595, lng: 18.8950154 },
    { lat: 48.7232496, lng: 18.8945404 },
    { lat: 48.7234409, lng: 18.8943122 },
    { lat: 48.7235985, lng: 18.8940001 },
    { lat: 48.7236416, lng: 18.8938961 },
    { lat: 48.7237053, lng: 18.8937515 },
    { lat: 48.7237876, lng: 18.8935333 },
    { lat: 48.7238532, lng: 18.8933434 },
    { lat: 48.723911, lng: 18.8931485 },
    { lat: 48.7240103, lng: 18.8929104 },
    { lat: 48.7240901, lng: 18.8926664 },
    { lat: 48.7238642, lng: 18.8924129 },
    { lat: 48.7236894, lng: 18.8922174 },
    { lat: 48.7235666, lng: 18.8920661 },
    { lat: 48.7234276, lng: 18.8919275 },
    { lat: 48.7233234, lng: 18.8918083 },
    { lat: 48.7232441, lng: 18.8917134 },
    { lat: 48.7230924, lng: 18.8915301 },
    { lat: 48.7232066, lng: 18.8913806 },
    { lat: 48.7233622, lng: 18.8910524 },
    { lat: 48.7233938, lng: 18.8909858 },
    { lat: 48.7232992, lng: 18.8908369 },
    { lat: 48.7235376, lng: 18.8904061 },
    { lat: 48.7236422, lng: 18.8902938 },
    { lat: 48.7237559, lng: 18.8901343 },
    { lat: 48.7239958, lng: 18.8897372 },
    { lat: 48.7241179, lng: 18.8895886 },
    { lat: 48.7246521, lng: 18.8889919 },
    { lat: 48.7246776, lng: 18.8889628 },
    { lat: 48.7247161, lng: 18.8889229 },
    { lat: 48.7250254, lng: 18.8886015 },
    { lat: 48.7248181, lng: 18.8878606 },
    { lat: 48.7245042, lng: 18.8872793 },
    { lat: 48.7241008, lng: 18.8874389 },
    { lat: 48.7238552, lng: 18.8874014 },
    { lat: 48.7231408, lng: 18.8867522 },
    { lat: 48.7230145, lng: 18.8866839 },
    { lat: 48.7227947, lng: 18.8863176 },
    { lat: 48.7225408, lng: 18.8861165 },
    { lat: 48.7226055, lng: 18.8857485 },
    { lat: 48.7223682, lng: 18.8855875 },
    { lat: 48.7227397, lng: 18.8850056 },
    { lat: 48.722385, lng: 18.884601 },
    { lat: 48.722174, lng: 18.8846833 },
    { lat: 48.7213344, lng: 18.8846496 },
    { lat: 48.7212693, lng: 18.8846991 },
    { lat: 48.7208686, lng: 18.8845852 },
    { lat: 48.7209772, lng: 18.8850045 },
    { lat: 48.7210926, lng: 18.8856705 },
    { lat: 48.7212774, lng: 18.885888 },
    { lat: 48.7215641, lng: 18.8863528 },
    { lat: 48.7216594, lng: 18.8865369 },
    { lat: 48.7218382, lng: 18.8867019 },
    { lat: 48.722073, lng: 18.8871271 },
    { lat: 48.7221439, lng: 18.8872548 },
    { lat: 48.7222548, lng: 18.8875003 },
    { lat: 48.7223861, lng: 18.8877099 },
    { lat: 48.7226555, lng: 18.8879503 },
    { lat: 48.7225746, lng: 18.8889608 },
    { lat: 48.7225709, lng: 18.8890005 },
    { lat: 48.7225028, lng: 18.8898439 },
    { lat: 48.7222394, lng: 18.8904641 },
    { lat: 48.7216402, lng: 18.891158 },
    { lat: 48.7215764, lng: 18.8912319 },
    { lat: 48.7215252, lng: 18.8912912 },
    { lat: 48.7210994, lng: 18.8915401 },
    { lat: 48.7200599, lng: 18.8917634 },
    { lat: 48.7199213, lng: 18.891695 },
    { lat: 48.7200818, lng: 18.8921643 },
    { lat: 48.7198566, lng: 18.8925236 },
    { lat: 48.7196431, lng: 18.8928654 },
    { lat: 48.7189977, lng: 18.8924621 },
    { lat: 48.7189426, lng: 18.8924439 },
    { lat: 48.7185034, lng: 18.8922973 },
    { lat: 48.7183394, lng: 18.8922473 },
    { lat: 48.7177379, lng: 18.8920588 },
    { lat: 48.7173115, lng: 18.8917954 },
    { lat: 48.7172837, lng: 18.8917147 },
    { lat: 48.7171999, lng: 18.8916842 },
    { lat: 48.7172454, lng: 18.8915614 },
    { lat: 48.7169378, lng: 18.8914987 },
    { lat: 48.7169093, lng: 18.8915592 },
    { lat: 48.7167843, lng: 18.8916292 },
    { lat: 48.7161653, lng: 18.8916716 },
    { lat: 48.7152154, lng: 18.8919682 },
    { lat: 48.7149973, lng: 18.8916736 },
    { lat: 48.7148455, lng: 18.8926501 },
    { lat: 48.7152449, lng: 18.8927613 },
    { lat: 48.7151016, lng: 18.8930024 },
    { lat: 48.7149841, lng: 18.8932822 },
    { lat: 48.7148133, lng: 18.8936454 },
    { lat: 48.7146645, lng: 18.8941924 },
    { lat: 48.7145337, lng: 18.8944984 },
    { lat: 48.7144904, lng: 18.894796 },
    { lat: 48.7144736, lng: 18.89491 },
    { lat: 48.7143707, lng: 18.8953199 },
    { lat: 48.7143404, lng: 18.8954341 },
    { lat: 48.7143395, lng: 18.895436 },
    { lat: 48.7141157, lng: 18.8952734 },
    { lat: 48.7139639, lng: 18.8950199 },
    { lat: 48.7137962, lng: 18.8949139 },
    { lat: 48.71376, lng: 18.8948914 },
    { lat: 48.7135944, lng: 18.894717 },
    { lat: 48.7134879, lng: 18.8947091 },
    { lat: 48.713368, lng: 18.8947432 },
    { lat: 48.7132775, lng: 18.8947392 },
    { lat: 48.713202, lng: 18.8948056 },
    { lat: 48.7131265, lng: 18.8948719 },
    { lat: 48.7129446, lng: 18.8953032 },
    { lat: 48.7126826, lng: 18.895777 },
    { lat: 48.7123518, lng: 18.8964953 },
    { lat: 48.7123904, lng: 18.8969782 },
    { lat: 48.7122458, lng: 18.8972401 },
    { lat: 48.7119061, lng: 18.8976052 },
    { lat: 48.7115923, lng: 18.8976863 },
    { lat: 48.7110648, lng: 18.8976479 },
    { lat: 48.7108882, lng: 18.8976315 },
    { lat: 48.710326, lng: 18.8979475 },
    { lat: 48.7098124, lng: 18.8987711 },
    { lat: 48.7090196, lng: 18.8993625 },
    { lat: 48.7087346, lng: 18.8997851 },
    { lat: 48.7086256, lng: 18.9002927 },
    { lat: 48.7080255, lng: 18.9003992 },
    { lat: 48.7070542, lng: 18.9005667 },
    { lat: 48.7068426, lng: 18.9005741 },
    { lat: 48.706049, lng: 18.9004923 },
    { lat: 48.7058149, lng: 18.9004562 },
    { lat: 48.7055584, lng: 18.9005534 },
    { lat: 48.7048151, lng: 18.900591 },
    { lat: 48.7039481, lng: 18.9014716 },
    { lat: 48.7036656, lng: 18.9017 },
    { lat: 48.703268, lng: 18.9019309 },
    { lat: 48.7027427, lng: 18.9020214 },
    { lat: 48.7024296, lng: 18.902026 },
    { lat: 48.7018276, lng: 18.9021363 },
    { lat: 48.7015435, lng: 18.9024712 },
    { lat: 48.7012935, lng: 18.9029799 },
    { lat: 48.7007626, lng: 18.90386 },
    { lat: 48.7004701, lng: 18.9042462 },
    { lat: 48.7001823, lng: 18.9043289 },
    { lat: 48.6996331, lng: 18.9043908 },
    { lat: 48.6995599, lng: 18.9044151 },
    { lat: 48.6992188, lng: 18.9046689 },
    { lat: 48.6988171, lng: 18.904817 },
    { lat: 48.698923, lng: 18.904992 },
    { lat: 48.6991483, lng: 18.9056013 },
    { lat: 48.6991634, lng: 18.9057858 },
    { lat: 48.6985891, lng: 18.9062376 },
    { lat: 48.698567, lng: 18.9062491 },
    { lat: 48.6984659, lng: 18.9063005 },
    { lat: 48.6984202, lng: 18.9061149 },
    { lat: 48.698413, lng: 18.9058827 },
    { lat: 48.6983178, lng: 18.9059613 },
    { lat: 48.6980055, lng: 18.9066661 },
    { lat: 48.6979277, lng: 18.9068394 },
    { lat: 48.697223, lng: 18.9076323 },
    { lat: 48.6968187, lng: 18.9077514 },
    { lat: 48.6964419, lng: 18.9083613 },
    { lat: 48.6960888, lng: 18.9084687 },
    { lat: 48.6960288, lng: 18.9084657 },
    { lat: 48.6957237, lng: 18.9084497 },
    { lat: 48.6956761, lng: 18.9084474 },
    { lat: 48.6956263, lng: 18.9084447 },
    { lat: 48.6954525, lng: 18.908157 },
    { lat: 48.6953493, lng: 18.9080099 },
    { lat: 48.6951207, lng: 18.9076862 },
    { lat: 48.6947689, lng: 18.9072492 },
    { lat: 48.694439, lng: 18.9068838 },
    { lat: 48.6937344, lng: 18.9061198 },
    { lat: 48.6937435, lng: 18.9057219 },
    { lat: 48.6934386, lng: 18.9057812 },
    { lat: 48.6930371, lng: 18.9059363 },
    { lat: 48.6924814, lng: 18.9061342 },
    { lat: 48.6920663, lng: 18.9063735 },
    { lat: 48.6917574, lng: 18.906551 },
    { lat: 48.6913143, lng: 18.9068809 },
    { lat: 48.6911402, lng: 18.9069791 },
    { lat: 48.6908507, lng: 18.9070966 },
    { lat: 48.6907606, lng: 18.9070961 },
    { lat: 48.6904548, lng: 18.9070941 },
    { lat: 48.6900546, lng: 18.9071185 },
    { lat: 48.6899805, lng: 18.9071436 },
    { lat: 48.6897248, lng: 18.9072574 },
    { lat: 48.6893118, lng: 18.9072769 },
    { lat: 48.6892275, lng: 18.9073039 },
    { lat: 48.6891265, lng: 18.9073354 },
    { lat: 48.6890944, lng: 18.9073452 },
    { lat: 48.688824, lng: 18.9074582 },
    { lat: 48.688611, lng: 18.9075471 },
    { lat: 48.688597, lng: 18.9075608 },
    { lat: 48.6883887, lng: 18.907764 },
    { lat: 48.6883096, lng: 18.9078076 },
    { lat: 48.6882527, lng: 18.9078392 },
    { lat: 48.6876809, lng: 18.9077071 },
    { lat: 48.687357, lng: 18.9076326 },
    { lat: 48.6871527, lng: 18.9075852 },
    { lat: 48.6868658, lng: 18.9076844 },
    { lat: 48.6864658, lng: 18.9078223 },
    { lat: 48.6865205, lng: 18.9080739 },
    { lat: 48.6867303, lng: 18.9090435 },
    { lat: 48.6867391, lng: 18.909106 },
    { lat: 48.6867829, lng: 18.9094295 },
    { lat: 48.6868293, lng: 18.9097624 },
    { lat: 48.6868526, lng: 18.9099299 },
    { lat: 48.6868929, lng: 18.9101446 },
    { lat: 48.6869043, lng: 18.9101929 },
    { lat: 48.6869817, lng: 18.9105361 },
    { lat: 48.6871321, lng: 18.911578 },
    { lat: 48.6873136, lng: 18.9119924 },
    { lat: 48.6874349, lng: 18.9122702 },
    { lat: 48.687132, lng: 18.9128045 },
    { lat: 48.6872017, lng: 18.9133495 },
    { lat: 48.6863772, lng: 18.9137186 },
    { lat: 48.685687, lng: 18.9136074 },
    { lat: 48.6852881, lng: 18.9136105 },
    { lat: 48.6845714, lng: 18.9137301 },
    { lat: 48.6840581, lng: 18.9138333 },
    { lat: 48.6837802, lng: 18.9139344 },
    { lat: 48.683762, lng: 18.9141281 },
    { lat: 48.6837232, lng: 18.915282 },
    { lat: 48.6836731, lng: 18.9159138 },
    { lat: 48.6836587, lng: 18.9160932 },
    { lat: 48.6837196, lng: 18.9163587 },
    { lat: 48.6838832, lng: 18.9167756 },
    { lat: 48.6839698, lng: 18.9169938 },
    { lat: 48.6842171, lng: 18.916997 },
    { lat: 48.6849373, lng: 18.9169288 },
    { lat: 48.6849901, lng: 18.9171899 },
    { lat: 48.6854948, lng: 18.9171109 },
    { lat: 48.6855163, lng: 18.9172686 },
    { lat: 48.6855451, lng: 18.917434 },
    { lat: 48.6855538, lng: 18.9174734 },
    { lat: 48.6855587, lng: 18.9174995 },
    { lat: 48.6855673, lng: 18.9175381 },
    { lat: 48.6855764, lng: 18.9175824 },
    { lat: 48.6855867, lng: 18.9176308 },
    { lat: 48.6857172, lng: 18.9175367 },
    { lat: 48.686089, lng: 18.917268 },
    { lat: 48.6863154, lng: 18.9175902 },
    { lat: 48.6864564, lng: 18.9177924 },
    { lat: 48.6864326, lng: 18.9178116 },
    { lat: 48.6863394, lng: 18.9178829 },
    { lat: 48.6860303, lng: 18.9181228 },
    { lat: 48.6859874, lng: 18.918142 },
    { lat: 48.6859675, lng: 18.9181508 },
    { lat: 48.6858617, lng: 18.9181974 },
    { lat: 48.6858506, lng: 18.9182026 },
    { lat: 48.6858395, lng: 18.9182075 },
    { lat: 48.6853833, lng: 18.9184107 },
    { lat: 48.6852499, lng: 18.9184278 },
    { lat: 48.6850455, lng: 18.918467 },
    { lat: 48.6850081, lng: 18.9184842 },
    { lat: 48.6847731, lng: 18.9185424 },
    { lat: 48.6845507, lng: 18.9186012 },
    { lat: 48.6843322, lng: 18.9186528 },
    { lat: 48.6840694, lng: 18.918716 },
    { lat: 48.6838523, lng: 18.9187485 },
    { lat: 48.6833591, lng: 18.9188483 },
    { lat: 48.6832144, lng: 18.9188642 },
    { lat: 48.6831153, lng: 18.9188625 },
    { lat: 48.682916, lng: 18.9188691 },
    { lat: 48.6827064, lng: 18.9188747 },
    { lat: 48.6825565, lng: 18.9188312 },
    { lat: 48.6824084, lng: 18.9187807 },
    { lat: 48.6821617, lng: 18.9186979 },
    { lat: 48.6818874, lng: 18.9186646 },
    { lat: 48.6816985, lng: 18.9186357 },
    { lat: 48.6815748, lng: 18.9185727 },
    { lat: 48.6815122, lng: 18.9185401 },
    { lat: 48.6812989, lng: 18.9184501 },
    { lat: 48.6811629, lng: 18.9183451 },
    { lat: 48.6811143, lng: 18.9183247 },
    { lat: 48.6811052, lng: 18.9183211 },
    { lat: 48.6810349, lng: 18.9182912 },
    { lat: 48.6809842, lng: 18.9182696 },
    { lat: 48.680884, lng: 18.9181871 },
    { lat: 48.680816, lng: 18.9181327 },
    { lat: 48.6807407, lng: 18.9181165 },
    { lat: 48.6807081, lng: 18.9182309 },
    { lat: 48.6806871, lng: 18.9183054 },
    { lat: 48.6806036, lng: 18.9184793 },
    { lat: 48.6802349, lng: 18.9192471 },
    { lat: 48.6799037, lng: 18.9196705 },
    { lat: 48.6796497, lng: 18.9201733 },
    { lat: 48.6795888, lng: 18.9203677 },
    { lat: 48.6795516, lng: 18.9205701 },
    { lat: 48.6796077, lng: 18.9207605 },
    { lat: 48.679609, lng: 18.9213162 },
    { lat: 48.6794863, lng: 18.9218444 },
    { lat: 48.6792054, lng: 18.9218647 },
    { lat: 48.6792158, lng: 18.9219559 },
    { lat: 48.6790706, lng: 18.9219854 },
    { lat: 48.6790674, lng: 18.921924 },
    { lat: 48.678486, lng: 18.9222149 },
    { lat: 48.678098, lng: 18.9224768 },
    { lat: 48.6779702, lng: 18.9226259 },
    { lat: 48.6779321, lng: 18.9227167 },
    { lat: 48.6778772, lng: 18.922899 },
    { lat: 48.6773916, lng: 18.9237646 },
    { lat: 48.6771451, lng: 18.924036 },
    { lat: 48.6769887, lng: 18.9241335 },
    { lat: 48.6768244, lng: 18.9241591 },
    { lat: 48.6765562, lng: 18.924362 },
    { lat: 48.6764331, lng: 18.9240935 },
    { lat: 48.6760599, lng: 18.9232794 },
    { lat: 48.6760005, lng: 18.9233113 },
    { lat: 48.6751501, lng: 18.923766 },
    { lat: 48.6748306, lng: 18.9235807 },
    { lat: 48.6745453, lng: 18.9240671 },
    { lat: 48.6743244, lng: 18.9244186 },
    { lat: 48.6753781, lng: 18.9261518 },
    { lat: 48.6758438, lng: 18.9268117 },
    { lat: 48.6760951, lng: 18.9273803 },
    { lat: 48.6762615, lng: 18.9279414 },
    { lat: 48.6763282, lng: 18.9285191 },
    { lat: 48.6764791, lng: 18.9288682 },
    { lat: 48.6766133, lng: 18.9291544 },
    { lat: 48.6767377, lng: 18.9297985 },
    { lat: 48.6766605, lng: 18.9304511 },
    { lat: 48.67665, lng: 18.9307956 },
    { lat: 48.6766837, lng: 18.9312362 },
    { lat: 48.676724, lng: 18.9316268 },
    { lat: 48.6767137, lng: 18.9317996 },
    { lat: 48.6767369, lng: 18.9318154 },
    { lat: 48.6767422, lng: 18.9324902 },
    { lat: 48.6767343, lng: 18.9331958 },
    { lat: 48.6766325, lng: 18.9337185 },
    { lat: 48.6765493, lng: 18.9340975 },
    { lat: 48.6765543, lng: 18.934129 },
    { lat: 48.6765614, lng: 18.9343098 },
    { lat: 48.676614, lng: 18.9344571 },
    { lat: 48.6766495, lng: 18.9346017 },
    { lat: 48.6766791, lng: 18.9347185 },
    { lat: 48.6766474, lng: 18.9348931 },
    { lat: 48.6766559, lng: 18.9349884 },
    { lat: 48.6766767, lng: 18.9350459 },
    { lat: 48.6766993, lng: 18.9350672 },
    { lat: 48.676735, lng: 18.9352614 },
    { lat: 48.6767896, lng: 18.9355572 },
    { lat: 48.6767761, lng: 18.9362599 },
    { lat: 48.6767649, lng: 18.9367928 },
    { lat: 48.6766888, lng: 18.9373108 },
    { lat: 48.6767521, lng: 18.9381522 },
    { lat: 48.6765587, lng: 18.9388016 },
    { lat: 48.6762511, lng: 18.9398417 },
    { lat: 48.6758012, lng: 18.9408363 },
    { lat: 48.6756246, lng: 18.9415505 },
    { lat: 48.6757984, lng: 18.9419516 },
    { lat: 48.6760043, lng: 18.9423422 },
    { lat: 48.6763027, lng: 18.9429064 },
    { lat: 48.6766323, lng: 18.9432669 },
    { lat: 48.6769775, lng: 18.9434936 },
    { lat: 48.6774309, lng: 18.9436524 },
    { lat: 48.6781084, lng: 18.9438436 },
    { lat: 48.6787944, lng: 18.9436093 },
    { lat: 48.6791161, lng: 18.9436624 },
    { lat: 48.679312, lng: 18.9437877 },
    { lat: 48.6793556, lng: 18.9438044 },
    { lat: 48.6795634, lng: 18.9438533 },
    { lat: 48.6797951, lng: 18.9439306 },
    { lat: 48.6798471, lng: 18.9445104 },
    { lat: 48.6801572, lng: 18.9448642 },
    { lat: 48.6805598, lng: 18.94504 },
    { lat: 48.6811014, lng: 18.9452032 },
    { lat: 48.6816016, lng: 18.9456149 },
    { lat: 48.6818818, lng: 18.9460222 },
    { lat: 48.6821677, lng: 18.9465231 },
    { lat: 48.6822581, lng: 18.9469704 },
    { lat: 48.6824533, lng: 18.9480552 },
    { lat: 48.682529, lng: 18.9484318 },
    { lat: 48.6825281, lng: 18.949234 },
    { lat: 48.6825118, lng: 18.9501101 },
    { lat: 48.6824767, lng: 18.9502176 },
    { lat: 48.6824733, lng: 18.9511204 },
    { lat: 48.6824835, lng: 18.9516116 },
    { lat: 48.6824567, lng: 18.9520554 },
    { lat: 48.6825215, lng: 18.9524653 },
    { lat: 48.682411, lng: 18.9529524 },
    { lat: 48.6824643, lng: 18.9536002 },
    { lat: 48.6825575, lng: 18.9546545 },
    { lat: 48.6826791, lng: 18.955269 },
    { lat: 48.6826896, lng: 18.9556974 },
    { lat: 48.6828749, lng: 18.9556395 },
    { lat: 48.683041, lng: 18.9556252 },
    { lat: 48.6831634, lng: 18.9557929 },
    { lat: 48.6833642, lng: 18.9560376 },
    { lat: 48.6835101, lng: 18.9562911 },
    { lat: 48.6836687, lng: 18.9565481 },
    { lat: 48.6841043, lng: 18.9567309 },
    { lat: 48.6842911, lng: 18.9567252 },
    { lat: 48.6844011, lng: 18.9567606 },
    { lat: 48.684494, lng: 18.9568348 },
    { lat: 48.6845954, lng: 18.9569243 },
    { lat: 48.68494, lng: 18.9571123 },
    { lat: 48.6853322, lng: 18.9577055 },
    { lat: 48.6854595, lng: 18.9579512 },
    { lat: 48.6855453, lng: 18.9582859 },
    { lat: 48.6854941, lng: 18.9592482 },
    { lat: 48.6853265, lng: 18.9602107 },
    { lat: 48.6855788, lng: 18.9607359 },
    { lat: 48.6856388, lng: 18.9612503 },
    { lat: 48.6857845, lng: 18.9617419 },
    { lat: 48.6859887, lng: 18.9617003 },
    { lat: 48.6862749, lng: 18.9624075 },
    { lat: 48.6864401, lng: 18.9630824 },
    { lat: 48.686024, lng: 18.9643921 },
    { lat: 48.6860192, lng: 18.964474 },
    { lat: 48.6860196, lng: 18.9645239 },
    { lat: 48.6860023, lng: 18.9666865 },
    { lat: 48.6860688, lng: 18.9673996 },
    { lat: 48.6860794, lng: 18.9674508 },
    { lat: 48.6861529, lng: 18.968232 },
    { lat: 48.6849753, lng: 18.9685323 },
    { lat: 48.6844988, lng: 18.9685622 },
    { lat: 48.6838783, lng: 18.9685314 },
    { lat: 48.6834577, lng: 18.968355 },
    { lat: 48.683153, lng: 18.9680175 },
    { lat: 48.682983, lng: 18.9678235 },
    { lat: 48.6831718, lng: 18.968163 },
    { lat: 48.6834023, lng: 18.968487 },
    { lat: 48.6837524, lng: 18.9688546 },
    { lat: 48.6838998, lng: 18.9689792 },
    { lat: 48.684163, lng: 18.9691874 },
    { lat: 48.6848644, lng: 18.9695022 },
    { lat: 48.6853904, lng: 18.9696439 },
    { lat: 48.6858876, lng: 18.9697101 },
    { lat: 48.6861765, lng: 18.9698079 },
    { lat: 48.6864091, lng: 18.9699686 },
    { lat: 48.6866585, lng: 18.9702826 },
    { lat: 48.6868524, lng: 18.9705042 },
    { lat: 48.6870635, lng: 18.9707106 },
    { lat: 48.6872812, lng: 18.9709313 },
    { lat: 48.6874046, lng: 18.971054 },
    { lat: 48.6874861, lng: 18.9712162 },
    { lat: 48.6876471, lng: 18.9716785 },
    { lat: 48.6877171, lng: 18.9718324 },
    { lat: 48.6881756, lng: 18.9723563 },
    { lat: 48.6884213, lng: 18.972604 },
    { lat: 48.688531, lng: 18.9727391 },
    { lat: 48.6886597, lng: 18.9729436 },
    { lat: 48.6888308, lng: 18.9732692 },
    { lat: 48.6889408, lng: 18.9734392 },
    { lat: 48.689076, lng: 18.9736177 },
    { lat: 48.6891353, lng: 18.9736971 },
    { lat: 48.6892997, lng: 18.9738455 },
    { lat: 48.6894272, lng: 18.9739128 },
    { lat: 48.6895592, lng: 18.9739821 },
    { lat: 48.6898782, lng: 18.9740243 },
    { lat: 48.690313, lng: 18.9740393 },
    { lat: 48.6908255, lng: 18.9739674 },
    { lat: 48.6912663, lng: 18.9739659 },
    { lat: 48.692326, lng: 18.9739613 },
    { lat: 48.69233, lng: 18.9739937 },
    { lat: 48.6923568, lng: 18.9740566 },
    { lat: 48.6923825, lng: 18.9741617 },
    { lat: 48.6924427, lng: 18.9744178 },
    { lat: 48.6924951, lng: 18.9745152 },
    { lat: 48.6925326, lng: 18.974564 },
    { lat: 48.6927658, lng: 18.9749421 },
    { lat: 48.6929865, lng: 18.9754443 },
    { lat: 48.6930179, lng: 18.9755085 },
    { lat: 48.6933235, lng: 18.9766194 },
    { lat: 48.693489, lng: 18.9770817 },
    { lat: 48.6935365, lng: 18.9773296 },
    { lat: 48.6936118, lng: 18.9782591 },
    { lat: 48.6936816, lng: 18.9786996 },
    { lat: 48.6938715, lng: 18.9796441 },
    { lat: 48.6938808, lng: 18.98034 },
    { lat: 48.6940627, lng: 18.9812982 },
    { lat: 48.6945274, lng: 18.9825514 },
    { lat: 48.6945235, lng: 18.9828758 },
    { lat: 48.6944377, lng: 18.9835364 },
    { lat: 48.6943458, lng: 18.9839046 },
    { lat: 48.6941894, lng: 18.9845347 },
    { lat: 48.6947159, lng: 18.9853803 },
    { lat: 48.6953684, lng: 18.9867974 },
    { lat: 48.6956832, lng: 18.9875362 },
    { lat: 48.6957068, lng: 18.9875995 },
    { lat: 48.6959956, lng: 18.9886057 },
    { lat: 48.6962289, lng: 18.9892051 },
    { lat: 48.6965844, lng: 18.9898147 },
    { lat: 48.696645, lng: 18.9902731 },
    { lat: 48.6967924, lng: 18.9903153 },
    { lat: 48.696866, lng: 18.9906305 },
    { lat: 48.6971286, lng: 18.991189 },
    { lat: 48.6966676, lng: 18.9920596 },
    { lat: 48.6964614, lng: 18.9921958 },
    { lat: 48.6958603, lng: 18.9924376 },
    { lat: 48.6953611, lng: 18.9927315 },
    { lat: 48.6947521, lng: 18.9927937 },
    { lat: 48.6942923, lng: 18.9929981 },
    { lat: 48.6935695, lng: 18.9936879 },
    { lat: 48.6931714, lng: 18.9937959 },
    { lat: 48.6927977, lng: 18.9937217 },
    { lat: 48.6925837, lng: 18.9936579 },
    { lat: 48.6921643, lng: 18.9931488 },
    { lat: 48.6918707, lng: 18.9926271 },
    { lat: 48.691523, lng: 18.9923243 },
    { lat: 48.6914828, lng: 18.9922901 },
    { lat: 48.6909679, lng: 18.9918514 },
    { lat: 48.6906459, lng: 18.9917954 },
    { lat: 48.6904833, lng: 18.9917193 },
    { lat: 48.6901798, lng: 18.9914742 },
    { lat: 48.6899147, lng: 18.9907775 },
    { lat: 48.6895607, lng: 18.9903988 },
    { lat: 48.6891556, lng: 18.9902607 },
    { lat: 48.6890523, lng: 18.990202 },
    { lat: 48.6887517, lng: 18.989857 },
    { lat: 48.6884587, lng: 18.9893646 },
    { lat: 48.6879472, lng: 18.9888845 },
    { lat: 48.6870917, lng: 18.9886004 },
    { lat: 48.6870384, lng: 18.9891525 },
    { lat: 48.6869109, lng: 18.9894395 },
    { lat: 48.6867692, lng: 18.9921835 },
    { lat: 48.6866998, lng: 18.9927078 },
    { lat: 48.6866931, lng: 18.9927768 },
    { lat: 48.6859398, lng: 18.994665 },
    { lat: 48.6858014, lng: 18.995137 },
    { lat: 48.6846439, lng: 18.9968391 },
    { lat: 48.6844213, lng: 18.9972555 },
    { lat: 48.6840104, lng: 18.9978477 },
    { lat: 48.6836665, lng: 18.9986902 },
    { lat: 48.6836295, lng: 18.9987851 },
    { lat: 48.6830463, lng: 18.9988256 },
    { lat: 48.6820656, lng: 18.9986939 },
    { lat: 48.6810163, lng: 18.9984778 },
    { lat: 48.6808258, lng: 18.9984348 },
    { lat: 48.68046, lng: 18.9983579 },
    { lat: 48.680234, lng: 18.9981434 },
    { lat: 48.6799996, lng: 18.9981962 },
    { lat: 48.6796957, lng: 18.9983804 },
    { lat: 48.6791967, lng: 18.9988154 },
    { lat: 48.678743, lng: 18.9990054 },
    { lat: 48.6777506, lng: 18.9994035 },
    { lat: 48.6773158, lng: 18.9994308 },
    { lat: 48.6769382, lng: 18.9992328 },
    { lat: 48.6767334, lng: 18.9990659 },
    { lat: 48.6769573, lng: 18.9986298 },
    { lat: 48.6768487, lng: 18.9985328 },
    { lat: 48.6767089, lng: 18.9988004 },
    { lat: 48.6765907, lng: 18.9986921 },
    { lat: 48.6764351, lng: 18.9991185 },
    { lat: 48.6765615, lng: 18.9993661 },
    { lat: 48.6764483, lng: 18.9997823 },
    { lat: 48.676563, lng: 19.0005786 },
    { lat: 48.6765708, lng: 19.0007318 },
    { lat: 48.6764965, lng: 19.0009358 },
    { lat: 48.6765549, lng: 19.0016669 },
    { lat: 48.6763031, lng: 19.0029952 },
    { lat: 48.6763127, lng: 19.0033854 },
    { lat: 48.6763641, lng: 19.003692 },
    { lat: 48.6765251, lng: 19.0044227 },
    { lat: 48.6765027, lng: 19.0053403 },
    { lat: 48.6764995, lng: 19.0053895 },
    { lat: 48.6762448, lng: 19.0058145 },
    { lat: 48.6760481, lng: 19.0065182 },
    { lat: 48.6759899, lng: 19.006816 },
    { lat: 48.6760467, lng: 19.007427 },
    { lat: 48.6761134, lng: 19.0078342 },
    { lat: 48.6761669, lng: 19.0094049 },
    { lat: 48.6761537, lng: 19.009883 },
    { lat: 48.6763386, lng: 19.0104425 },
    { lat: 48.6763445, lng: 19.0129059 },
    { lat: 48.6762853, lng: 19.0132727 },
    { lat: 48.6762093, lng: 19.0134658 },
    { lat: 48.6759688, lng: 19.0139063 },
    { lat: 48.675922, lng: 19.01404 },
    { lat: 48.675945, lng: 19.014123 },
    { lat: 48.675866, lng: 19.014276 },
    { lat: 48.675498, lng: 19.015105 },
    { lat: 48.675273, lng: 19.015486 },
    { lat: 48.675026, lng: 19.016858 },
    { lat: 48.674978, lng: 19.017339 },
    { lat: 48.674887, lng: 19.01785 },
    { lat: 48.674857, lng: 19.018126 },
    { lat: 48.674799, lng: 19.018168 },
    { lat: 48.67382, lng: 19.017014 },
  ],
  Lúčky: [
    { lat: 48.7061237, lng: 18.8562629 },
    { lat: 48.7058862, lng: 18.8572931 },
    { lat: 48.7058305, lng: 18.8583718 },
    { lat: 48.7057782, lng: 18.8593661 },
    { lat: 48.705522, lng: 18.8611729 },
    { lat: 48.7055231, lng: 18.8630242 },
    { lat: 48.7055251, lng: 18.8636533 },
    { lat: 48.7055243, lng: 18.8639197 },
    { lat: 48.7054538, lng: 18.8644939 },
    { lat: 48.7054368, lng: 18.8646448 },
    { lat: 48.7054311, lng: 18.865171 },
    { lat: 48.7052914, lng: 18.8663481 },
    { lat: 48.7051774, lng: 18.8669363 },
    { lat: 48.70496, lng: 18.868466 },
    { lat: 48.7047774, lng: 18.8696471 },
    { lat: 48.7046657, lng: 18.8702332 },
    { lat: 48.7045648, lng: 18.8707699 },
    { lat: 48.7045415, lng: 18.871255 },
    { lat: 48.7046742, lng: 18.8718387 },
    { lat: 48.7045305, lng: 18.8723736 },
    { lat: 48.7042619, lng: 18.8730065 },
    { lat: 48.7042339, lng: 18.8730733 },
    { lat: 48.704193, lng: 18.8731545 },
    { lat: 48.7041784, lng: 18.8732205 },
    { lat: 48.7039217, lng: 18.8731633 },
    { lat: 48.7037123, lng: 18.8730163 },
    { lat: 48.7036226, lng: 18.8728059 },
    { lat: 48.7034102, lng: 18.8726053 },
    { lat: 48.7030832, lng: 18.8726053 },
    { lat: 48.7030564, lng: 18.8723243 },
    { lat: 48.7028022, lng: 18.8720744 },
    { lat: 48.7025775, lng: 18.8722212 },
    { lat: 48.7023257, lng: 18.8722826 },
    { lat: 48.7022097, lng: 18.8721549 },
    { lat: 48.7020558, lng: 18.8716408 },
    { lat: 48.7019336, lng: 18.8715442 },
    { lat: 48.7016781, lng: 18.8717293 },
    { lat: 48.70137, lng: 18.8717667 },
    { lat: 48.7013646, lng: 18.8715746 },
    { lat: 48.7007692, lng: 18.8714438 },
    { lat: 48.7007184, lng: 18.8713559 },
    { lat: 48.7006825, lng: 18.8710328 },
    { lat: 48.7006219, lng: 18.8709932 },
    { lat: 48.7005307, lng: 18.8710396 },
    { lat: 48.7003149, lng: 18.8709012 },
    { lat: 48.700067, lng: 18.8710325 },
    { lat: 48.6998823, lng: 18.8711257 },
    { lat: 48.6997681, lng: 18.8710781 },
    { lat: 48.6996728, lng: 18.8710222 },
    { lat: 48.6995197, lng: 18.8708297 },
    { lat: 48.6994727, lng: 18.8708574 },
    { lat: 48.6993958, lng: 18.8708975 },
    { lat: 48.699183, lng: 18.8708405 },
    { lat: 48.6988669, lng: 18.8708777 },
    { lat: 48.6988219, lng: 18.8712275 },
    { lat: 48.698832, lng: 18.8714466 },
    { lat: 48.6988424, lng: 18.8716477 },
    { lat: 48.6988942, lng: 18.8718401 },
    { lat: 48.6988839, lng: 18.8720105 },
    { lat: 48.6988188, lng: 18.872151 },
    { lat: 48.6989443, lng: 18.8722204 },
    { lat: 48.6989887, lng: 18.872686 },
    { lat: 48.6990722, lng: 18.8730171 },
    { lat: 48.6990954, lng: 18.8731607 },
    { lat: 48.6992148, lng: 18.8738102 },
    { lat: 48.6992409, lng: 18.8745135 },
    { lat: 48.6992596, lng: 18.8750082 },
    { lat: 48.6993433, lng: 18.8751418 },
    { lat: 48.698799, lng: 18.8751306 },
    { lat: 48.6986406, lng: 18.8751836 },
    { lat: 48.698558, lng: 18.8752868 },
    { lat: 48.6983116, lng: 18.875539 },
    { lat: 48.6983358, lng: 18.8757424 },
    { lat: 48.6981239, lng: 18.8760497 },
    { lat: 48.6980027, lng: 18.8763692 },
    { lat: 48.6979048, lng: 18.8768305 },
    { lat: 48.6978621, lng: 18.8772481 },
    { lat: 48.6977194, lng: 18.8777618 },
    { lat: 48.6976208, lng: 18.8778619 },
    { lat: 48.6975572, lng: 18.877939 },
    { lat: 48.6974393, lng: 18.8781268 },
    { lat: 48.6972321, lng: 18.8780855 },
    { lat: 48.6972463, lng: 18.8781859 },
    { lat: 48.696673, lng: 18.8787667 },
    { lat: 48.6964481, lng: 18.8790419 },
    { lat: 48.6963131, lng: 18.8792417 },
    { lat: 48.6960017, lng: 18.8795671 },
    { lat: 48.6957832, lng: 18.8798573 },
    { lat: 48.6956719, lng: 18.8801049 },
    { lat: 48.6952149, lng: 18.8809061 },
    { lat: 48.6950071, lng: 18.8812057 },
    { lat: 48.694891, lng: 18.8813829 },
    { lat: 48.6947736, lng: 18.8816176 },
    { lat: 48.6947646, lng: 18.8816622 },
    { lat: 48.6947222, lng: 18.8820459 },
    { lat: 48.6946808, lng: 18.882095 },
    { lat: 48.6940046, lng: 18.8817517 },
    { lat: 48.6936943, lng: 18.8816129 },
    { lat: 48.6933528, lng: 18.8813548 },
    { lat: 48.6927533, lng: 18.880971 },
    { lat: 48.6922123, lng: 18.8805817 },
    { lat: 48.6919815, lng: 18.8804372 },
    { lat: 48.6918423, lng: 18.8805332 },
    { lat: 48.6916839, lng: 18.8808281 },
    { lat: 48.6914058, lng: 18.8813419 },
    { lat: 48.6913637, lng: 18.8817023 },
    { lat: 48.6911299, lng: 18.88209 },
    { lat: 48.6911243, lng: 18.8820906 },
    { lat: 48.6909779, lng: 18.8823359 },
    { lat: 48.6908349, lng: 18.8824726 },
    { lat: 48.6908369, lng: 18.8825106 },
    { lat: 48.6907805, lng: 18.8825547 },
    { lat: 48.6901435, lng: 18.8833188 },
    { lat: 48.6899985, lng: 18.8832434 },
    { lat: 48.6898129, lng: 18.8836218 },
    { lat: 48.6894123, lng: 18.8840551 },
    { lat: 48.6893792, lng: 18.88411 },
    { lat: 48.6892428, lng: 18.8843502 },
    { lat: 48.6888854, lng: 18.8852512 },
    { lat: 48.6887247, lng: 18.8860017 },
    { lat: 48.6886966, lng: 18.8862912 },
    { lat: 48.6885728, lng: 18.886511 },
    { lat: 48.6882546, lng: 18.8871636 },
    { lat: 48.6876353, lng: 18.8888235 },
    { lat: 48.687538, lng: 18.8890815 },
    { lat: 48.6869909, lng: 18.8897303 },
    { lat: 48.6867089, lng: 18.8902095 },
    { lat: 48.6865855, lng: 18.8905538 },
    { lat: 48.6864843, lng: 18.8908219 },
    { lat: 48.6863382, lng: 18.891137 },
    { lat: 48.6862364, lng: 18.8913499 },
    { lat: 48.6857158, lng: 18.8923392 },
    { lat: 48.6856231, lng: 18.8924665 },
    { lat: 48.6853431, lng: 18.8923052 },
    { lat: 48.684743, lng: 18.8919935 },
    { lat: 48.6846362, lng: 18.8921313 },
    { lat: 48.6845441, lng: 18.8917881 },
    { lat: 48.6844721, lng: 18.8916849 },
    { lat: 48.6843338, lng: 18.8914938 },
    { lat: 48.6838072, lng: 18.8909219 },
    { lat: 48.6836738, lng: 18.8908966 },
    { lat: 48.6833863, lng: 18.8907887 },
    { lat: 48.6831624, lng: 18.8908639 },
    { lat: 48.6823663, lng: 18.8911005 },
    { lat: 48.6821262, lng: 18.8907554 },
    { lat: 48.6819082, lng: 18.8905516 },
    { lat: 48.6815131, lng: 18.8902797 },
    { lat: 48.6811084, lng: 18.8901242 },
    { lat: 48.6808291, lng: 18.8901103 },
    { lat: 48.6807206, lng: 18.890152 },
    { lat: 48.6806289, lng: 18.8901242 },
    { lat: 48.6801535, lng: 18.8895817 },
    { lat: 48.6799084, lng: 18.8895534 },
    { lat: 48.6795799, lng: 18.8899192 },
    { lat: 48.6791459, lng: 18.8904476 },
    { lat: 48.6788465, lng: 18.8908506 },
    { lat: 48.6787857, lng: 18.8908956 },
    { lat: 48.6786012, lng: 18.890924 },
    { lat: 48.6785041, lng: 18.890964 },
    { lat: 48.6781411, lng: 18.8913167 },
    { lat: 48.6779698, lng: 18.8913218 },
    { lat: 48.6772445, lng: 18.8922993 },
    { lat: 48.6773671, lng: 18.8929964 },
    { lat: 48.6773784, lng: 18.8930375 },
    { lat: 48.6775689, lng: 18.8926848 },
    { lat: 48.6781518, lng: 18.8922913 },
    { lat: 48.6783295, lng: 18.8922815 },
    { lat: 48.6787998, lng: 18.8922651 },
    { lat: 48.678961, lng: 18.8923939 },
    { lat: 48.6793188, lng: 18.8926216 },
    { lat: 48.679551, lng: 18.8927703 },
    { lat: 48.6796057, lng: 18.8928049 },
    { lat: 48.6796638, lng: 18.8928134 },
    { lat: 48.6799496, lng: 18.8930225 },
    { lat: 48.6799441, lng: 18.8932886 },
    { lat: 48.6804101, lng: 18.8936246 },
    { lat: 48.6804815, lng: 18.8938489 },
    { lat: 48.6805131, lng: 18.8941549 },
    { lat: 48.6806507, lng: 18.8943956 },
    { lat: 48.6806342, lng: 18.8948015 },
    { lat: 48.680571, lng: 18.8950412 },
    { lat: 48.6804875, lng: 18.8952365 },
    { lat: 48.6802933, lng: 18.8958677 },
    { lat: 48.6801127, lng: 18.8963656 },
    { lat: 48.6800669, lng: 18.8965504 },
    { lat: 48.6803321, lng: 18.8971901 },
    { lat: 48.6803421, lng: 18.8973845 },
    { lat: 48.6804242, lng: 18.8975344 },
    { lat: 48.6804222, lng: 18.8975813 },
    { lat: 48.680408, lng: 18.898008 },
    { lat: 48.6810147, lng: 18.8980303 },
    { lat: 48.6812114, lng: 18.8979961 },
    { lat: 48.6812508, lng: 18.8980393 },
    { lat: 48.6812556, lng: 18.8980455 },
    { lat: 48.6812951, lng: 18.8980877 },
    { lat: 48.6813339, lng: 18.8982087 },
    { lat: 48.681219, lng: 18.898386 },
    { lat: 48.6812388, lng: 18.8986149 },
    { lat: 48.6812939, lng: 18.8989006 },
    { lat: 48.6813044, lng: 18.8993433 },
    { lat: 48.6812889, lng: 18.8997455 },
    { lat: 48.6813118, lng: 18.9001456 },
    { lat: 48.681359, lng: 18.900382 },
    { lat: 48.6814017, lng: 18.9004273 },
    { lat: 48.6816525, lng: 18.9006948 },
    { lat: 48.6817781, lng: 18.9013652 },
    { lat: 48.6822159, lng: 18.9013052 },
    { lat: 48.6823106, lng: 18.9012613 },
    { lat: 48.6825504, lng: 18.9018858 },
    { lat: 48.6828416, lng: 18.9025097 },
    { lat: 48.6830022, lng: 18.9028252 },
    { lat: 48.6832168, lng: 18.9032718 },
    { lat: 48.683266, lng: 18.9033745 },
    { lat: 48.6833589, lng: 18.9037112 },
    { lat: 48.6835666, lng: 18.9042399 },
    { lat: 48.6835898, lng: 18.9042961 },
    { lat: 48.6839832, lng: 18.9040102 },
    { lat: 48.6843618, lng: 18.9038273 },
    { lat: 48.6843951, lng: 18.9039508 },
    { lat: 48.6844178, lng: 18.9040388 },
    { lat: 48.6844369, lng: 18.9041083 },
    { lat: 48.6845616, lng: 18.9045576 },
    { lat: 48.6845671, lng: 18.9045769 },
    { lat: 48.6851956, lng: 18.9056848 },
    { lat: 48.6854886, lng: 18.9062917 },
    { lat: 48.6856155, lng: 18.9070287 },
    { lat: 48.6852454, lng: 18.9067827 },
    { lat: 48.6852513, lng: 18.9080609 },
    { lat: 48.6859293, lng: 18.9079818 },
    { lat: 48.6859735, lng: 18.9079856 },
    { lat: 48.6860407, lng: 18.9079777 },
    { lat: 48.6864658, lng: 18.9078223 },
    { lat: 48.6868658, lng: 18.9076844 },
    { lat: 48.6871527, lng: 18.9075852 },
    { lat: 48.687357, lng: 18.9076326 },
    { lat: 48.6876809, lng: 18.9077071 },
    { lat: 48.6882527, lng: 18.9078392 },
    { lat: 48.6883096, lng: 18.9078076 },
    { lat: 48.6883887, lng: 18.907764 },
    { lat: 48.688597, lng: 18.9075608 },
    { lat: 48.688611, lng: 18.9075471 },
    { lat: 48.688824, lng: 18.9074582 },
    { lat: 48.6890944, lng: 18.9073452 },
    { lat: 48.6891265, lng: 18.9073354 },
    { lat: 48.6892275, lng: 18.9073039 },
    { lat: 48.6893118, lng: 18.9072769 },
    { lat: 48.6897248, lng: 18.9072574 },
    { lat: 48.6899805, lng: 18.9071436 },
    { lat: 48.6900546, lng: 18.9071185 },
    { lat: 48.6904548, lng: 18.9070941 },
    { lat: 48.6907606, lng: 18.9070961 },
    { lat: 48.6908507, lng: 18.9070966 },
    { lat: 48.6911402, lng: 18.9069791 },
    { lat: 48.6913143, lng: 18.9068809 },
    { lat: 48.6917574, lng: 18.906551 },
    { lat: 48.6920663, lng: 18.9063735 },
    { lat: 48.6924814, lng: 18.9061342 },
    { lat: 48.6930371, lng: 18.9059363 },
    { lat: 48.6934386, lng: 18.9057812 },
    { lat: 48.6937435, lng: 18.9057219 },
    { lat: 48.6937344, lng: 18.9061198 },
    { lat: 48.694439, lng: 18.9068838 },
    { lat: 48.6947689, lng: 18.9072492 },
    { lat: 48.6951207, lng: 18.9076862 },
    { lat: 48.6953493, lng: 18.9080099 },
    { lat: 48.6954525, lng: 18.908157 },
    { lat: 48.6956263, lng: 18.9084447 },
    { lat: 48.6956761, lng: 18.9084474 },
    { lat: 48.6957237, lng: 18.9084497 },
    { lat: 48.6960288, lng: 18.9084657 },
    { lat: 48.6960888, lng: 18.9084687 },
    { lat: 48.6964419, lng: 18.9083613 },
    { lat: 48.6968187, lng: 18.9077514 },
    { lat: 48.697223, lng: 18.9076323 },
    { lat: 48.6979277, lng: 18.9068394 },
    { lat: 48.6980055, lng: 18.9066661 },
    { lat: 48.6983178, lng: 18.9059613 },
    { lat: 48.698413, lng: 18.9058827 },
    { lat: 48.6984202, lng: 18.9061149 },
    { lat: 48.6984659, lng: 18.9063005 },
    { lat: 48.698567, lng: 18.9062491 },
    { lat: 48.6985891, lng: 18.9062376 },
    { lat: 48.6991634, lng: 18.9057858 },
    { lat: 48.6991483, lng: 18.9056013 },
    { lat: 48.698923, lng: 18.904992 },
    { lat: 48.6988171, lng: 18.904817 },
    { lat: 48.6992188, lng: 18.9046689 },
    { lat: 48.6995599, lng: 18.9044151 },
    { lat: 48.6996331, lng: 18.9043908 },
    { lat: 48.7001823, lng: 18.9043289 },
    { lat: 48.7004701, lng: 18.9042462 },
    { lat: 48.7007626, lng: 18.90386 },
    { lat: 48.7012935, lng: 18.9029799 },
    { lat: 48.7015435, lng: 18.9024712 },
    { lat: 48.7018276, lng: 18.9021363 },
    { lat: 48.7024296, lng: 18.902026 },
    { lat: 48.7027427, lng: 18.9020214 },
    { lat: 48.703268, lng: 18.9019309 },
    { lat: 48.7036656, lng: 18.9017 },
    { lat: 48.7039481, lng: 18.9014716 },
    { lat: 48.7048151, lng: 18.900591 },
    { lat: 48.7055584, lng: 18.9005534 },
    { lat: 48.7058149, lng: 18.9004562 },
    { lat: 48.706049, lng: 18.9004923 },
    { lat: 48.7068426, lng: 18.9005741 },
    { lat: 48.7070542, lng: 18.9005667 },
    { lat: 48.7080255, lng: 18.9003992 },
    { lat: 48.7086256, lng: 18.9002927 },
    { lat: 48.7087346, lng: 18.8997851 },
    { lat: 48.7090196, lng: 18.8993625 },
    { lat: 48.7098124, lng: 18.8987711 },
    { lat: 48.710326, lng: 18.8979475 },
    { lat: 48.7108882, lng: 18.8976315 },
    { lat: 48.7110648, lng: 18.8976479 },
    { lat: 48.7115923, lng: 18.8976863 },
    { lat: 48.7119061, lng: 18.8976052 },
    { lat: 48.7122458, lng: 18.8972401 },
    { lat: 48.7123904, lng: 18.8969782 },
    { lat: 48.7123518, lng: 18.8964953 },
    { lat: 48.7126826, lng: 18.895777 },
    { lat: 48.7129446, lng: 18.8953032 },
    { lat: 48.7131265, lng: 18.8948719 },
    { lat: 48.713202, lng: 18.8948056 },
    { lat: 48.7132775, lng: 18.8947392 },
    { lat: 48.713368, lng: 18.8947432 },
    { lat: 48.7134879, lng: 18.8947091 },
    { lat: 48.7135944, lng: 18.894717 },
    { lat: 48.71376, lng: 18.8948914 },
    { lat: 48.7137962, lng: 18.8949139 },
    { lat: 48.7139639, lng: 18.8950199 },
    { lat: 48.7141157, lng: 18.8952734 },
    { lat: 48.7143395, lng: 18.895436 },
    { lat: 48.7143404, lng: 18.8954341 },
    { lat: 48.7143707, lng: 18.8953199 },
    { lat: 48.7144736, lng: 18.89491 },
    { lat: 48.7144904, lng: 18.894796 },
    { lat: 48.7145337, lng: 18.8944984 },
    { lat: 48.7146645, lng: 18.8941924 },
    { lat: 48.7148133, lng: 18.8936454 },
    { lat: 48.7149841, lng: 18.8932822 },
    { lat: 48.7151016, lng: 18.8930024 },
    { lat: 48.7152449, lng: 18.8927613 },
    { lat: 48.7148455, lng: 18.8926501 },
    { lat: 48.7149973, lng: 18.8916736 },
    { lat: 48.7152154, lng: 18.8919682 },
    { lat: 48.7161653, lng: 18.8916716 },
    { lat: 48.7167843, lng: 18.8916292 },
    { lat: 48.7169093, lng: 18.8915592 },
    { lat: 48.7169378, lng: 18.8914987 },
    { lat: 48.7172454, lng: 18.8915614 },
    { lat: 48.7171999, lng: 18.8916842 },
    { lat: 48.7172837, lng: 18.8917147 },
    { lat: 48.7173115, lng: 18.8917954 },
    { lat: 48.7177379, lng: 18.8920588 },
    { lat: 48.7183394, lng: 18.8922473 },
    { lat: 48.7185034, lng: 18.8922973 },
    { lat: 48.7189426, lng: 18.8924439 },
    { lat: 48.7189977, lng: 18.8924621 },
    { lat: 48.7196431, lng: 18.8928654 },
    { lat: 48.7198566, lng: 18.8925236 },
    { lat: 48.7200818, lng: 18.8921643 },
    { lat: 48.7199213, lng: 18.891695 },
    { lat: 48.7200599, lng: 18.8917634 },
    { lat: 48.7210994, lng: 18.8915401 },
    { lat: 48.7215252, lng: 18.8912912 },
    { lat: 48.7215764, lng: 18.8912319 },
    { lat: 48.7216402, lng: 18.891158 },
    { lat: 48.7222394, lng: 18.8904641 },
    { lat: 48.7225028, lng: 18.8898439 },
    { lat: 48.7225709, lng: 18.8890005 },
    { lat: 48.7225746, lng: 18.8889608 },
    { lat: 48.7226555, lng: 18.8879503 },
    { lat: 48.7223861, lng: 18.8877099 },
    { lat: 48.7222548, lng: 18.8875003 },
    { lat: 48.7221439, lng: 18.8872548 },
    { lat: 48.722073, lng: 18.8871271 },
    { lat: 48.7218382, lng: 18.8867019 },
    { lat: 48.7216594, lng: 18.8865369 },
    { lat: 48.7215641, lng: 18.8863528 },
    { lat: 48.7212774, lng: 18.885888 },
    { lat: 48.7210926, lng: 18.8856705 },
    { lat: 48.7209772, lng: 18.8850045 },
    { lat: 48.7208686, lng: 18.8845852 },
    { lat: 48.7206746, lng: 18.8833546 },
    { lat: 48.7205383, lng: 18.8824843 },
    { lat: 48.7205598, lng: 18.8818525 },
    { lat: 48.7208634, lng: 18.8817921 },
    { lat: 48.721029, lng: 18.8818098 },
    { lat: 48.7211242, lng: 18.88125 },
    { lat: 48.7217285, lng: 18.8814231 },
    { lat: 48.7217217, lng: 18.8809829 },
    { lat: 48.7213854, lng: 18.8808725 },
    { lat: 48.7213493, lng: 18.8806714 },
    { lat: 48.7211762, lng: 18.880879 },
    { lat: 48.7210767, lng: 18.8808557 },
    { lat: 48.7210223, lng: 18.8808506 },
    { lat: 48.7204481, lng: 18.8801669 },
    { lat: 48.7202093, lng: 18.8797747 },
    { lat: 48.7193196, lng: 18.8793153 },
    { lat: 48.7192957, lng: 18.879071 },
    { lat: 48.7190665, lng: 18.879002 },
    { lat: 48.7188773, lng: 18.8791367 },
    { lat: 48.7186631, lng: 18.8790705 },
    { lat: 48.7185031, lng: 18.8791615 },
    { lat: 48.7183285, lng: 18.8780058 },
    { lat: 48.7187527, lng: 18.8778344 },
    { lat: 48.7188208, lng: 18.8776925 },
    { lat: 48.7187013, lng: 18.8771895 },
    { lat: 48.7184505, lng: 18.8770512 },
    { lat: 48.7181704, lng: 18.8765368 },
    { lat: 48.7180692, lng: 18.8756057 },
    { lat: 48.718061, lng: 18.874965 },
    { lat: 48.7179714, lng: 18.8747777 },
    { lat: 48.7179272, lng: 18.8746858 },
    { lat: 48.7178807, lng: 18.8745938 },
    { lat: 48.7176358, lng: 18.8745701 },
    { lat: 48.7177052, lng: 18.8739231 },
    { lat: 48.7173892, lng: 18.8738104 },
    { lat: 48.7170937, lng: 18.8738542 },
    { lat: 48.7169893, lng: 18.8739272 },
    { lat: 48.7168892, lng: 18.8740211 },
    { lat: 48.7167496, lng: 18.8739351 },
    { lat: 48.7165602, lng: 18.8736296 },
    { lat: 48.7162364, lng: 18.8733686 },
    { lat: 48.7158757, lng: 18.8732376 },
    { lat: 48.7155221, lng: 18.8729825 },
    { lat: 48.7151649, lng: 18.8727929 },
    { lat: 48.7145883, lng: 18.8723249 },
    { lat: 48.7142719, lng: 18.8720952 },
    { lat: 48.71419, lng: 18.872037 },
    { lat: 48.713948, lng: 18.8719598 },
    { lat: 48.7136439, lng: 18.8718543 },
    { lat: 48.7132476, lng: 18.8715822 },
    { lat: 48.7129568, lng: 18.8712144 },
    { lat: 48.7127308, lng: 18.870814 },
    { lat: 48.7122301, lng: 18.8704389 },
    { lat: 48.7117668, lng: 18.8701829 },
    { lat: 48.7113927, lng: 18.8697936 },
    { lat: 48.7111441, lng: 18.8694555 },
    { lat: 48.7110362, lng: 18.8693108 },
    { lat: 48.710947, lng: 18.8691332 },
    { lat: 48.7107971, lng: 18.8692647 },
    { lat: 48.7105813, lng: 18.8689067 },
    { lat: 48.7106262, lng: 18.8687279 },
    { lat: 48.7104342, lng: 18.8684828 },
    { lat: 48.7102579, lng: 18.8680402 },
    { lat: 48.7105324, lng: 18.8678971 },
    { lat: 48.7106059, lng: 18.8678145 },
    { lat: 48.7104601, lng: 18.8676771 },
    { lat: 48.7103739, lng: 18.8674677 },
    { lat: 48.7100388, lng: 18.8666172 },
    { lat: 48.7100023, lng: 18.8664958 },
    { lat: 48.7099079, lng: 18.8660046 },
    { lat: 48.709869, lng: 18.8654665 },
    { lat: 48.7098679, lng: 18.8652952 },
    { lat: 48.7097813, lng: 18.8645568 },
    { lat: 48.7097793, lng: 18.8639968 },
    { lat: 48.7096558, lng: 18.8631981 },
    { lat: 48.708921, lng: 18.8624237 },
    { lat: 48.7088289, lng: 18.8624521 },
    { lat: 48.7085247, lng: 18.8625651 },
    { lat: 48.7082031, lng: 18.8630622 },
    { lat: 48.7078763, lng: 18.8630662 },
    { lat: 48.707977, lng: 18.8627879 },
    { lat: 48.7079144, lng: 18.8624744 },
    { lat: 48.7080405, lng: 18.8620339 },
    { lat: 48.7080316, lng: 18.8620121 },
    { lat: 48.7079134, lng: 18.8617333 },
    { lat: 48.7078764, lng: 18.8614328 },
    { lat: 48.7083072, lng: 18.8612148 },
    { lat: 48.708364, lng: 18.8610071 },
    { lat: 48.7090796, lng: 18.8601689 },
    { lat: 48.7091359, lng: 18.8597545 },
    { lat: 48.7089596, lng: 18.8593969 },
    { lat: 48.7086667, lng: 18.8593179 },
    { lat: 48.7083318, lng: 18.8589698 },
    { lat: 48.7081853, lng: 18.8582523 },
    { lat: 48.7072763, lng: 18.859011 },
    { lat: 48.7071938, lng: 18.8581564 },
    { lat: 48.7067385, lng: 18.8573126 },
    { lat: 48.7068337, lng: 18.8571025 },
    { lat: 48.7067702, lng: 18.8567691 },
    { lat: 48.7067376, lng: 18.8562499 },
    { lat: 48.7061237, lng: 18.8562629 },
  ],
  SklenéTeplice: [
    { lat: 48.511986, lng: 18.862766 },
    { lat: 48.511975, lng: 18.863065 },
    { lat: 48.512029, lng: 18.863587 },
    { lat: 48.511995, lng: 18.864184 },
    { lat: 48.511869, lng: 18.864749 },
    { lat: 48.511865, lng: 18.864759 },
    { lat: 48.511838, lng: 18.864828 },
    { lat: 48.511819, lng: 18.864875 },
    { lat: 48.511782, lng: 18.86497 },
    { lat: 48.511595, lng: 18.865188 },
    { lat: 48.511606, lng: 18.865428 },
    { lat: 48.511489, lng: 18.865726 },
    { lat: 48.511362, lng: 18.865885 },
    { lat: 48.511168, lng: 18.866198 },
    { lat: 48.511188, lng: 18.866438 },
    { lat: 48.511187, lng: 18.866814 },
    { lat: 48.511355, lng: 18.867253 },
    { lat: 48.51134, lng: 18.867436 },
    { lat: 48.511411, lng: 18.867623 },
    { lat: 48.51157, lng: 18.867814 },
    { lat: 48.511663, lng: 18.86802 },
    { lat: 48.511718, lng: 18.868431 },
    { lat: 48.511791, lng: 18.868734 },
    { lat: 48.511852, lng: 18.869406 },
    { lat: 48.511832, lng: 18.870087 },
    { lat: 48.511752, lng: 18.870604 },
    { lat: 48.511617, lng: 18.870815 },
    { lat: 48.511486, lng: 18.871034 },
    { lat: 48.511526, lng: 18.871256 },
    { lat: 48.511417, lng: 18.871811 },
    { lat: 48.511304, lng: 18.872183 },
    { lat: 48.5111, lng: 18.872687 },
    { lat: 48.510957, lng: 18.872968 },
    { lat: 48.510768, lng: 18.873192 },
    { lat: 48.510547, lng: 18.873311 },
    { lat: 48.510404, lng: 18.873631 },
    { lat: 48.510388, lng: 18.874067 },
    { lat: 48.510523, lng: 18.874405 },
    { lat: 48.510641, lng: 18.874665 },
    { lat: 48.510638, lng: 18.875081 },
    { lat: 48.510606, lng: 18.875349 },
    { lat: 48.510482, lng: 18.875687 },
    { lat: 48.510376, lng: 18.875834 },
    { lat: 48.510317, lng: 18.876184 },
    { lat: 48.510372, lng: 18.876573 },
    { lat: 48.510501, lng: 18.876642 },
    { lat: 48.510749, lng: 18.87672 },
    { lat: 48.511014, lng: 18.877201 },
    { lat: 48.511168, lng: 18.877677 },
    { lat: 48.511269, lng: 18.878118 },
    { lat: 48.511274, lng: 18.878299 },
    { lat: 48.511221, lng: 18.87869 },
    { lat: 48.511255, lng: 18.8791 },
    { lat: 48.511385, lng: 18.879147 },
    { lat: 48.511542, lng: 18.879239 },
    { lat: 48.511696, lng: 18.879467 },
    { lat: 48.511798, lng: 18.879666 },
    { lat: 48.511869, lng: 18.879869 },
    { lat: 48.511995, lng: 18.880523 },
    { lat: 48.511944, lng: 18.880783 },
    { lat: 48.51201, lng: 18.881062 },
    { lat: 48.51204, lng: 18.881457 },
    { lat: 48.512122, lng: 18.881778 },
    { lat: 48.512142, lng: 18.882085 },
    { lat: 48.512308, lng: 18.882373 },
    { lat: 48.512338, lng: 18.882503 },
    { lat: 48.512339, lng: 18.882669 },
    { lat: 48.512329, lng: 18.882759 },
    { lat: 48.512183, lng: 18.88299 },
    { lat: 48.512191, lng: 18.883371 },
    { lat: 48.512038, lng: 18.883717 },
    { lat: 48.511856, lng: 18.883981 },
    { lat: 48.51163, lng: 18.884325 },
    { lat: 48.511386, lng: 18.884845 },
    { lat: 48.511242, lng: 18.885274 },
    { lat: 48.511094, lng: 18.885588 },
    { lat: 48.510882, lng: 18.885862 },
    { lat: 48.510789, lng: 18.885878 },
    { lat: 48.51075, lng: 18.886059 },
    { lat: 48.5106, lng: 18.886378 },
    { lat: 48.510395, lng: 18.886764 },
    { lat: 48.510625, lng: 18.887084 },
    { lat: 48.510715, lng: 18.887433 },
    { lat: 48.510745, lng: 18.888027 },
    { lat: 48.510798, lng: 18.888379 },
    { lat: 48.510978, lng: 18.888844 },
    { lat: 48.510927, lng: 18.889965 },
    { lat: 48.51074, lng: 18.890126 },
    { lat: 48.510884, lng: 18.890191 },
    { lat: 48.511002, lng: 18.890249 },
    { lat: 48.511488, lng: 18.88925 },
    { lat: 48.511674, lng: 18.889372 },
    { lat: 48.512262, lng: 18.889399 },
    { lat: 48.512812, lng: 18.889582 },
    { lat: 48.51338, lng: 18.889861 },
    { lat: 48.513875, lng: 18.890201 },
    { lat: 48.514156, lng: 18.890935 },
    { lat: 48.514388, lng: 18.891461 },
    { lat: 48.514677, lng: 18.891905 },
    { lat: 48.51503, lng: 18.892335 },
    { lat: 48.515436, lng: 18.893118 },
    { lat: 48.516419, lng: 18.89385 },
    { lat: 48.516886, lng: 18.893861 },
    { lat: 48.517667, lng: 18.895004 },
    { lat: 48.518837, lng: 18.895244 },
    { lat: 48.51939, lng: 18.895189 },
    { lat: 48.519867, lng: 18.895247 },
    { lat: 48.520484, lng: 18.896341 },
    { lat: 48.521281, lng: 18.896296 },
    { lat: 48.521521, lng: 18.896326 },
    { lat: 48.521687, lng: 18.896991 },
    { lat: 48.522333, lng: 18.897207 },
    { lat: 48.522433, lng: 18.897725 },
    { lat: 48.523007, lng: 18.898248 },
    { lat: 48.523792, lng: 18.898555 },
    { lat: 48.523792, lng: 18.899415 },
    { lat: 48.523986, lng: 18.899761 },
    { lat: 48.523877, lng: 18.899895 },
    { lat: 48.52374, lng: 18.900516 },
    { lat: 48.524018, lng: 18.900422 },
    { lat: 48.524056, lng: 18.900678 },
    { lat: 48.524269, lng: 18.900603 },
    { lat: 48.524428, lng: 18.900643 },
    { lat: 48.524683, lng: 18.900449 },
    { lat: 48.524926, lng: 18.900394 },
    { lat: 48.524994, lng: 18.90026 },
    { lat: 48.525316, lng: 18.899945 },
    { lat: 48.525715, lng: 18.89926 },
    { lat: 48.526271, lng: 18.899416 },
    { lat: 48.526664, lng: 18.899233 },
    { lat: 48.527591, lng: 18.900359 },
    { lat: 48.527921, lng: 18.90068 },
    { lat: 48.528931, lng: 18.900745 },
    { lat: 48.529348, lng: 18.900392 },
    { lat: 48.529763, lng: 18.900031 },
    { lat: 48.529992, lng: 18.899984 },
    { lat: 48.530174, lng: 18.900003 },
    { lat: 48.530552, lng: 18.900105 },
    { lat: 48.530786, lng: 18.900188 },
    { lat: 48.530943, lng: 18.900343 },
    { lat: 48.531237, lng: 18.900578 },
    { lat: 48.531382, lng: 18.900331 },
    { lat: 48.531383, lng: 18.900574 },
    { lat: 48.531406, lng: 18.90069 },
    { lat: 48.531422, lng: 18.900802 },
    { lat: 48.531445, lng: 18.900883 },
    { lat: 48.531618, lng: 18.901489 },
    { lat: 48.531881, lng: 18.901827 },
    { lat: 48.532131, lng: 18.901843 },
    { lat: 48.533587, lng: 18.901596 },
    { lat: 48.533844, lng: 18.901683 },
    { lat: 48.534393, lng: 18.901976 },
    { lat: 48.535847, lng: 18.902195 },
    { lat: 48.536787, lng: 18.902542 },
    { lat: 48.5368023, lng: 18.9021796 },
    { lat: 48.5367942, lng: 18.901739 },
    { lat: 48.5368395, lng: 18.9011726 },
    { lat: 48.5367777, lng: 18.9009838 },
    { lat: 48.5364123, lng: 18.9007419 },
    { lat: 48.5360134, lng: 18.9002011 },
    { lat: 48.5359419, lng: 18.899928 },
    { lat: 48.5364406, lng: 18.8986429 },
    { lat: 48.5361125, lng: 18.8967454 },
    { lat: 48.5366195, lng: 18.8950309 },
    { lat: 48.5372637, lng: 18.8942083 },
    { lat: 48.5368544, lng: 18.8933568 },
    { lat: 48.5368288, lng: 18.892381 },
    { lat: 48.5369921, lng: 18.8911098 },
    { lat: 48.5368648, lng: 18.8904569 },
    { lat: 48.5367053, lng: 18.8899031 },
    { lat: 48.5361695, lng: 18.8881299 },
    { lat: 48.536419, lng: 18.8871966 },
    { lat: 48.5366727, lng: 18.8859973 },
    { lat: 48.5370167, lng: 18.8839275 },
    { lat: 48.5370287, lng: 18.8838535 },
    { lat: 48.5370009, lng: 18.8834935 },
    { lat: 48.5371522, lng: 18.8827965 },
    { lat: 48.5375564, lng: 18.8816987 },
    { lat: 48.537778, lng: 18.8796784 },
    { lat: 48.537453, lng: 18.8777589 },
    { lat: 48.5371774, lng: 18.8770088 },
    { lat: 48.5372238, lng: 18.8753143 },
    { lat: 48.5374314, lng: 18.8746189 },
    { lat: 48.5378187, lng: 18.8737604 },
    { lat: 48.5377512, lng: 18.8732833 },
    { lat: 48.5376188, lng: 18.8710982 },
    { lat: 48.5373821, lng: 18.8705108 },
    { lat: 48.537192, lng: 18.869022 },
    { lat: 48.5371319, lng: 18.8683427 },
    { lat: 48.5372421, lng: 18.8670086 },
    { lat: 48.5371737, lng: 18.8662965 },
    { lat: 48.5375242, lng: 18.8657287 },
    { lat: 48.5373909, lng: 18.8637059 },
    { lat: 48.5375922, lng: 18.8623664 },
    { lat: 48.5373496, lng: 18.8613435 },
    { lat: 48.5371942, lng: 18.8612398 },
    { lat: 48.5370214, lng: 18.8609758 },
    { lat: 48.536958, lng: 18.861143 },
    { lat: 48.5367324, lng: 18.8609252 },
    { lat: 48.5366519, lng: 18.8606984 },
    { lat: 48.5365845, lng: 18.8605067 },
    { lat: 48.5365817, lng: 18.8604978 },
    { lat: 48.5365021, lng: 18.8602666 },
    { lat: 48.5364546, lng: 18.8601345 },
    { lat: 48.536437, lng: 18.8600544 },
    { lat: 48.5363648, lng: 18.8600761 },
    { lat: 48.5363073, lng: 18.8600974 },
    { lat: 48.5362248, lng: 18.8601587 },
    { lat: 48.5361204, lng: 18.86006 },
    { lat: 48.536123, lng: 18.8599779 },
    { lat: 48.5360943, lng: 18.8599265 },
    { lat: 48.5360657, lng: 18.8598768 },
    { lat: 48.5360263, lng: 18.8597445 },
    { lat: 48.536132, lng: 18.8596747 },
    { lat: 48.5362344, lng: 18.8596065 },
    { lat: 48.5367747, lng: 18.8593738 },
    { lat: 48.5369297, lng: 18.8592093 },
    { lat: 48.5370055, lng: 18.8590396 },
    { lat: 48.5370259, lng: 18.8589956 },
    { lat: 48.5372495, lng: 18.8585241 },
    { lat: 48.537468, lng: 18.8580628 },
    { lat: 48.5375665, lng: 18.8576566 },
    { lat: 48.5376291, lng: 18.8572564 },
    { lat: 48.5376711, lng: 18.8567014 },
    { lat: 48.5380358, lng: 18.8558838 },
    { lat: 48.5380995, lng: 18.8554813 },
    { lat: 48.5381268, lng: 18.8550977 },
    { lat: 48.5380849, lng: 18.8545508 },
    { lat: 48.5384827, lng: 18.8536759 },
    { lat: 48.5388404, lng: 18.8530527 },
    { lat: 48.5397228, lng: 18.8514577 },
    { lat: 48.5397322, lng: 18.8514427 },
    { lat: 48.5397454, lng: 18.8514159 },
    { lat: 48.5402812, lng: 18.850572 },
    { lat: 48.5404317, lng: 18.8498675 },
    { lat: 48.5405474, lng: 18.8496207 },
    { lat: 48.5408649, lng: 18.8490922 },
    { lat: 48.5412516, lng: 18.8482225 },
    { lat: 48.5412437, lng: 18.8480476 },
    { lat: 48.5412698, lng: 18.8479862 },
    { lat: 48.5413151, lng: 18.8479719 },
    { lat: 48.5411963, lng: 18.8478956 },
    { lat: 48.5413227, lng: 18.8476187 },
    { lat: 48.5413177, lng: 18.8469582 },
    { lat: 48.5413089, lng: 18.8464443 },
    { lat: 48.5413813, lng: 18.8460792 },
    { lat: 48.5416135, lng: 18.8456642 },
    { lat: 48.5417004, lng: 18.8452319 },
    { lat: 48.541678, lng: 18.8449549 },
    { lat: 48.5416346, lng: 18.8448568 },
    { lat: 48.5416166, lng: 18.8448162 },
    { lat: 48.5416129, lng: 18.8448085 },
    { lat: 48.5414943, lng: 18.8445453 },
    { lat: 48.5414196, lng: 18.844431 },
    { lat: 48.5413973, lng: 18.8437671 },
    { lat: 48.5414061, lng: 18.8436718 },
    { lat: 48.5414254, lng: 18.8434574 },
    { lat: 48.5415668, lng: 18.8432724 },
    { lat: 48.5417463, lng: 18.8432345 },
    { lat: 48.5418274, lng: 18.8431455 },
    { lat: 48.5418446, lng: 18.8428503 },
    { lat: 48.5418395, lng: 18.8424029 },
    { lat: 48.5420017, lng: 18.842009 },
    { lat: 48.5419573, lng: 18.8418061 },
    { lat: 48.5419337, lng: 18.8414847 },
    { lat: 48.5419256, lng: 18.8410013 },
    { lat: 48.5419239, lng: 18.8408613 },
    { lat: 48.541918, lng: 18.8405972 },
    { lat: 48.5421818, lng: 18.8402728 },
    { lat: 48.5422745, lng: 18.8402327 },
    { lat: 48.5424706, lng: 18.8401469 },
    { lat: 48.5426004, lng: 18.83978 },
    { lat: 48.5426886, lng: 18.8393507 },
    { lat: 48.5428441, lng: 18.8390249 },
    { lat: 48.5429145, lng: 18.8386881 },
    { lat: 48.5432503, lng: 18.837837 },
    { lat: 48.5433303, lng: 18.8369724 },
    { lat: 48.5433917, lng: 18.836483 },
    { lat: 48.5433239, lng: 18.8359611 },
    { lat: 48.5431637, lng: 18.8355256 },
    { lat: 48.5431531, lng: 18.8354948 },
    { lat: 48.5431434, lng: 18.8354408 },
    { lat: 48.543086, lng: 18.8351825 },
    { lat: 48.5431647, lng: 18.8349436 },
    { lat: 48.5431714, lng: 18.8349617 },
    { lat: 48.5431794, lng: 18.8349839 },
    { lat: 48.5432258, lng: 18.834882 },
    { lat: 48.5432114, lng: 18.8348417 },
    { lat: 48.5432692, lng: 18.8347626 },
    { lat: 48.5433276, lng: 18.834713 },
    { lat: 48.5433835, lng: 18.8345758 },
    { lat: 48.5432923, lng: 18.8344488 },
    { lat: 48.543185, lng: 18.8342893 },
    { lat: 48.5430952, lng: 18.8338462 },
    { lat: 48.5428621, lng: 18.8335944 },
    { lat: 48.5428589, lng: 18.8333795 },
    { lat: 48.5428997, lng: 18.8332095 },
    { lat: 48.5429485, lng: 18.8331312 },
    { lat: 48.5430302, lng: 18.8328578 },
    { lat: 48.5428925, lng: 18.8326634 },
    { lat: 48.542792, lng: 18.832354 },
    { lat: 48.5427608, lng: 18.8320185 },
    { lat: 48.5428613, lng: 18.8317846 },
    { lat: 48.5430006, lng: 18.8315795 },
    { lat: 48.542998, lng: 18.8312705 },
    { lat: 48.5427497, lng: 18.8311579 },
    { lat: 48.5426158, lng: 18.8310553 },
    { lat: 48.5424957, lng: 18.8308695 },
    { lat: 48.5423756, lng: 18.8304282 },
    { lat: 48.5421551, lng: 18.8300077 },
    { lat: 48.5419346, lng: 18.8295804 },
    { lat: 48.5419251, lng: 18.8293129 },
    { lat: 48.5420614, lng: 18.8288355 },
    { lat: 48.5417781, lng: 18.8280075 },
    { lat: 48.5416273, lng: 18.8274094 },
    { lat: 48.5414125, lng: 18.8271836 },
    { lat: 48.5412369, lng: 18.8266945 },
    { lat: 48.5409611, lng: 18.8264677 },
    { lat: 48.5408723, lng: 18.8262558 },
    { lat: 48.5408463, lng: 18.8258436 },
    { lat: 48.540622, lng: 18.8253037 },
    { lat: 48.5403292, lng: 18.8252042 },
    { lat: 48.5401843, lng: 18.8250641 },
    { lat: 48.5401554, lng: 18.8248817 },
    { lat: 48.5400894, lng: 18.8246721 },
    { lat: 48.5401921, lng: 18.8245653 },
    { lat: 48.5398975, lng: 18.8242372 },
    { lat: 48.5398522, lng: 18.8242334 },
    { lat: 48.5398148, lng: 18.8243595 },
    { lat: 48.5397576, lng: 18.8244723 },
    { lat: 48.5396818, lng: 18.8245364 },
    { lat: 48.5396528, lng: 18.8245612 },
    { lat: 48.5395898, lng: 18.8246075 },
    { lat: 48.5395714, lng: 18.8246207 },
    { lat: 48.5392798, lng: 18.824826 },
    { lat: 48.5391676, lng: 18.8249446 },
    { lat: 48.5391327, lng: 18.8241894 },
    { lat: 48.5390238, lng: 18.8236842 },
    { lat: 48.5385147, lng: 18.8251604 },
    { lat: 48.5383111, lng: 18.8261215 },
    { lat: 48.5384499, lng: 18.8273323 },
    { lat: 48.5385367, lng: 18.8281764 },
    { lat: 48.5385043, lng: 18.8286321 },
    { lat: 48.5383291, lng: 18.8291873 },
    { lat: 48.5381046, lng: 18.8298341 },
    { lat: 48.537876, lng: 18.8304433 },
    { lat: 48.5373918, lng: 18.8314752 },
    { lat: 48.5372701, lng: 18.8318654 },
    { lat: 48.5371376, lng: 18.832266 },
    { lat: 48.5371036, lng: 18.8328447 },
    { lat: 48.53713, lng: 18.8336547 },
    { lat: 48.5365569, lng: 18.8345782 },
    { lat: 48.5356809, lng: 18.8348398 },
    { lat: 48.5353742, lng: 18.8352756 },
    { lat: 48.5351301, lng: 18.8357432 },
    { lat: 48.5343115, lng: 18.8362399 },
    { lat: 48.5330101, lng: 18.8374939 },
    { lat: 48.5326541, lng: 18.8379764 },
    { lat: 48.5327355, lng: 18.8383695 },
    { lat: 48.5327106, lng: 18.8393196 },
    { lat: 48.5325482, lng: 18.8406186 },
    { lat: 48.5320989, lng: 18.8417098 },
    { lat: 48.5318378, lng: 18.8425685 },
    { lat: 48.5315213, lng: 18.8438953 },
    { lat: 48.5310317, lng: 18.8443907 },
    { lat: 48.5309014, lng: 18.8458498 },
    { lat: 48.5308525, lng: 18.8465988 },
    { lat: 48.5308825, lng: 18.8467635 },
    { lat: 48.5309619, lng: 18.846926 },
    { lat: 48.5309371, lng: 18.8470762 },
    { lat: 48.5304863, lng: 18.8479494 },
    { lat: 48.5303712, lng: 18.8481626 },
    { lat: 48.5302601, lng: 18.8483696 },
    { lat: 48.5298556, lng: 18.8490467 },
    { lat: 48.5295171, lng: 18.8493904 },
    { lat: 48.5292229, lng: 18.849889 },
    { lat: 48.5288035, lng: 18.8502811 },
    { lat: 48.5285862, lng: 18.8503729 },
    { lat: 48.5283234, lng: 18.8504192 },
    { lat: 48.5279225, lng: 18.8507101 },
    { lat: 48.5276031, lng: 18.8509221 },
    { lat: 48.5272468, lng: 18.8510723 },
    { lat: 48.5267188, lng: 18.851242 },
    { lat: 48.5261086, lng: 18.850914 },
    { lat: 48.5260184, lng: 18.8507827 },
    { lat: 48.5259067, lng: 18.8506298 },
    { lat: 48.5257486, lng: 18.8505392 },
    { lat: 48.5254541, lng: 18.8506017 },
    { lat: 48.5248331, lng: 18.8506742 },
    { lat: 48.5244907, lng: 18.8507242 },
    { lat: 48.5243161, lng: 18.8509009 },
    { lat: 48.5241057, lng: 18.8511025 },
    { lat: 48.5238573, lng: 18.8512076 },
    { lat: 48.5237173, lng: 18.8512913 },
    { lat: 48.5236252, lng: 18.8515138 },
    { lat: 48.5233376, lng: 18.8517733 },
    { lat: 48.523154, lng: 18.8519952 },
    { lat: 48.5231106, lng: 18.8527206 },
    { lat: 48.5225155, lng: 18.8529003 },
    { lat: 48.5215922, lng: 18.8534029 },
    { lat: 48.5211962, lng: 18.8536804 },
    { lat: 48.5204036, lng: 18.8542472 },
    { lat: 48.52033, lng: 18.8543078 },
    { lat: 48.5203044, lng: 18.8543343 },
    { lat: 48.520238, lng: 18.8544023 },
    { lat: 48.5201084, lng: 18.8544499 },
    { lat: 48.5201055, lng: 18.854457 },
    { lat: 48.5200747, lng: 18.8545161 },
    { lat: 48.5200707, lng: 18.8545248 },
    { lat: 48.5200101, lng: 18.8545956 },
    { lat: 48.5199687, lng: 18.8546442 },
    { lat: 48.5198028, lng: 18.8548391 },
    { lat: 48.5197722, lng: 18.8548776 },
    { lat: 48.5197362, lng: 18.854922 },
    { lat: 48.5195982, lng: 18.8550901 },
    { lat: 48.5194659, lng: 18.8552533 },
    { lat: 48.5194479, lng: 18.8552754 },
    { lat: 48.519325, lng: 18.8554528 },
    { lat: 48.5190723, lng: 18.8557969 },
    { lat: 48.5189543, lng: 18.8559574 },
    { lat: 48.5189249, lng: 18.8559975 },
    { lat: 48.5185972, lng: 18.8562893 },
    { lat: 48.5183352, lng: 18.8565226 },
    { lat: 48.5181941, lng: 18.8566477 },
    { lat: 48.5181238, lng: 18.8567911 },
    { lat: 48.5180588, lng: 18.8570371 },
    { lat: 48.518106, lng: 18.8575537 },
    { lat: 48.5184385, lng: 18.8580494 },
    { lat: 48.5185529, lng: 18.8582778 },
    { lat: 48.5187303, lng: 18.8586322 },
    { lat: 48.5187308, lng: 18.8586638 },
    { lat: 48.5187866, lng: 18.8592146 },
    { lat: 48.5187985, lng: 18.8594035 },
    { lat: 48.5188712, lng: 18.8595664 },
    { lat: 48.5188957, lng: 18.8595823 },
    { lat: 48.518899, lng: 18.8596453 },
    { lat: 48.5189577, lng: 18.859734 },
    { lat: 48.5189761, lng: 18.8597607 },
    { lat: 48.5189148, lng: 18.8598625 },
    { lat: 48.518892, lng: 18.8599015 },
    { lat: 48.5187956, lng: 18.8600611 },
    { lat: 48.5185393, lng: 18.8604672 },
    { lat: 48.5184799, lng: 18.8605617 },
    { lat: 48.5183881, lng: 18.8607056 },
    { lat: 48.5183431, lng: 18.8606443 },
    { lat: 48.5181133, lng: 18.8605359 },
    { lat: 48.5179586, lng: 18.8604691 },
    { lat: 48.5176319, lng: 18.8605399 },
    { lat: 48.5174461, lng: 18.8605881 },
    { lat: 48.5172477, lng: 18.8605673 },
    { lat: 48.5168802, lng: 18.8603534 },
    { lat: 48.5167071, lng: 18.8602134 },
    { lat: 48.5165005, lng: 18.8599706 },
    { lat: 48.516222, lng: 18.8595759 },
    { lat: 48.5160264, lng: 18.8594826 },
    { lat: 48.5158203, lng: 18.8595538 },
    { lat: 48.5154695, lng: 18.8597245 },
    { lat: 48.5153277, lng: 18.8597924 },
    { lat: 48.515121, lng: 18.8598979 },
    { lat: 48.5148502, lng: 18.8601983 },
    { lat: 48.5145967, lng: 18.860439 },
    { lat: 48.514394, lng: 18.8606176 },
    { lat: 48.5142012, lng: 18.860791 },
    { lat: 48.5139754, lng: 18.8608976 },
    { lat: 48.5137016, lng: 18.8609857 },
    { lat: 48.5135025, lng: 18.8610817 },
    { lat: 48.5134196, lng: 18.8611793 },
    { lat: 48.5133763, lng: 18.8612146 },
    { lat: 48.5133634, lng: 18.8612254 },
    { lat: 48.5133319, lng: 18.8612501 },
    { lat: 48.5132908, lng: 18.861283 },
    { lat: 48.5131229, lng: 18.8613938 },
    { lat: 48.5128612, lng: 18.8617187 },
    { lat: 48.5126159, lng: 18.8620335 },
    { lat: 48.512381, lng: 18.8622638 },
    { lat: 48.5123754, lng: 18.8622659 },
    { lat: 48.5123444, lng: 18.8622777 },
    { lat: 48.5121609, lng: 18.8623491 },
    { lat: 48.5120084, lng: 18.8625388 },
    { lat: 48.5119965, lng: 18.8626327 },
    { lat: 48.5119857, lng: 18.8627111 },
    { lat: 48.511986, lng: 18.862766 },
  ],
  Ihráč: [
    { lat: 48.675922, lng: 19.01404 },
    { lat: 48.6759688, lng: 19.0139063 },
    { lat: 48.6762093, lng: 19.0134658 },
    { lat: 48.6762853, lng: 19.0132727 },
    { lat: 48.6763445, lng: 19.0129059 },
    { lat: 48.6763386, lng: 19.0104425 },
    { lat: 48.6761537, lng: 19.009883 },
    { lat: 48.6761669, lng: 19.0094049 },
    { lat: 48.6761134, lng: 19.0078342 },
    { lat: 48.6760467, lng: 19.007427 },
    { lat: 48.6759899, lng: 19.006816 },
    { lat: 48.6760481, lng: 19.0065182 },
    { lat: 48.6762448, lng: 19.0058145 },
    { lat: 48.6764995, lng: 19.0053895 },
    { lat: 48.6765027, lng: 19.0053403 },
    { lat: 48.6765251, lng: 19.0044227 },
    { lat: 48.6763641, lng: 19.003692 },
    { lat: 48.6763127, lng: 19.0033854 },
    { lat: 48.6763031, lng: 19.0029952 },
    { lat: 48.6765549, lng: 19.0016669 },
    { lat: 48.6764965, lng: 19.0009358 },
    { lat: 48.6765708, lng: 19.0007318 },
    { lat: 48.676563, lng: 19.0005786 },
    { lat: 48.6764483, lng: 18.9997823 },
    { lat: 48.6765615, lng: 18.9993661 },
    { lat: 48.6764351, lng: 18.9991185 },
    { lat: 48.6765907, lng: 18.9986921 },
    { lat: 48.6767089, lng: 18.9988004 },
    { lat: 48.6768487, lng: 18.9985328 },
    { lat: 48.6769573, lng: 18.9986298 },
    { lat: 48.6767334, lng: 18.9990659 },
    { lat: 48.6769382, lng: 18.9992328 },
    { lat: 48.6773158, lng: 18.9994308 },
    { lat: 48.6777506, lng: 18.9994035 },
    { lat: 48.678743, lng: 18.9990054 },
    { lat: 48.6791967, lng: 18.9988154 },
    { lat: 48.6796957, lng: 18.9983804 },
    { lat: 48.6799996, lng: 18.9981962 },
    { lat: 48.680234, lng: 18.9981434 },
    { lat: 48.68046, lng: 18.9983579 },
    { lat: 48.6808258, lng: 18.9984348 },
    { lat: 48.6810163, lng: 18.9984778 },
    { lat: 48.6820656, lng: 18.9986939 },
    { lat: 48.6830463, lng: 18.9988256 },
    { lat: 48.6836295, lng: 18.9987851 },
    { lat: 48.6836665, lng: 18.9986902 },
    { lat: 48.6840104, lng: 18.9978477 },
    { lat: 48.6844213, lng: 18.9972555 },
    { lat: 48.6846439, lng: 18.9968391 },
    { lat: 48.6858014, lng: 18.995137 },
    { lat: 48.6859398, lng: 18.994665 },
    { lat: 48.6866931, lng: 18.9927768 },
    { lat: 48.6866998, lng: 18.9927078 },
    { lat: 48.6867692, lng: 18.9921835 },
    { lat: 48.6869109, lng: 18.9894395 },
    { lat: 48.6870384, lng: 18.9891525 },
    { lat: 48.6870917, lng: 18.9886004 },
    { lat: 48.6879472, lng: 18.9888845 },
    { lat: 48.6884587, lng: 18.9893646 },
    { lat: 48.6887517, lng: 18.989857 },
    { lat: 48.6890523, lng: 18.990202 },
    { lat: 48.6891556, lng: 18.9902607 },
    { lat: 48.6895607, lng: 18.9903988 },
    { lat: 48.6899147, lng: 18.9907775 },
    { lat: 48.6901798, lng: 18.9914742 },
    { lat: 48.6904833, lng: 18.9917193 },
    { lat: 48.6906459, lng: 18.9917954 },
    { lat: 48.6909679, lng: 18.9918514 },
    { lat: 48.6914828, lng: 18.9922901 },
    { lat: 48.691523, lng: 18.9923243 },
    { lat: 48.6918707, lng: 18.9926271 },
    { lat: 48.6921643, lng: 18.9931488 },
    { lat: 48.6925837, lng: 18.9936579 },
    { lat: 48.6927977, lng: 18.9937217 },
    { lat: 48.6931714, lng: 18.9937959 },
    { lat: 48.6935695, lng: 18.9936879 },
    { lat: 48.6942923, lng: 18.9929981 },
    { lat: 48.6947521, lng: 18.9927937 },
    { lat: 48.6953611, lng: 18.9927315 },
    { lat: 48.6958603, lng: 18.9924376 },
    { lat: 48.6964614, lng: 18.9921958 },
    { lat: 48.6966676, lng: 18.9920596 },
    { lat: 48.6971286, lng: 18.991189 },
    { lat: 48.696866, lng: 18.9906305 },
    { lat: 48.6967924, lng: 18.9903153 },
    { lat: 48.696645, lng: 18.9902731 },
    { lat: 48.6965844, lng: 18.9898147 },
    { lat: 48.6962289, lng: 18.9892051 },
    { lat: 48.6959956, lng: 18.9886057 },
    { lat: 48.6957068, lng: 18.9875995 },
    { lat: 48.6956832, lng: 18.9875362 },
    { lat: 48.6953684, lng: 18.9867974 },
    { lat: 48.6947159, lng: 18.9853803 },
    { lat: 48.6941894, lng: 18.9845347 },
    { lat: 48.6943458, lng: 18.9839046 },
    { lat: 48.6944377, lng: 18.9835364 },
    { lat: 48.6945235, lng: 18.9828758 },
    { lat: 48.6945274, lng: 18.9825514 },
    { lat: 48.6940627, lng: 18.9812982 },
    { lat: 48.6938808, lng: 18.98034 },
    { lat: 48.6938715, lng: 18.9796441 },
    { lat: 48.6936816, lng: 18.9786996 },
    { lat: 48.6936118, lng: 18.9782591 },
    { lat: 48.6935365, lng: 18.9773296 },
    { lat: 48.693489, lng: 18.9770817 },
    { lat: 48.6933235, lng: 18.9766194 },
    { lat: 48.6930179, lng: 18.9755085 },
    { lat: 48.6929865, lng: 18.9754443 },
    { lat: 48.6927658, lng: 18.9749421 },
    { lat: 48.6925326, lng: 18.974564 },
    { lat: 48.6924951, lng: 18.9745152 },
    { lat: 48.6924427, lng: 18.9744178 },
    { lat: 48.6923825, lng: 18.9741617 },
    { lat: 48.6923568, lng: 18.9740566 },
    { lat: 48.69233, lng: 18.9739937 },
    { lat: 48.692326, lng: 18.9739613 },
    { lat: 48.6912663, lng: 18.9739659 },
    { lat: 48.6908255, lng: 18.9739674 },
    { lat: 48.690313, lng: 18.9740393 },
    { lat: 48.6898782, lng: 18.9740243 },
    { lat: 48.6895592, lng: 18.9739821 },
    { lat: 48.6894272, lng: 18.9739128 },
    { lat: 48.6892997, lng: 18.9738455 },
    { lat: 48.6891353, lng: 18.9736971 },
    { lat: 48.689076, lng: 18.9736177 },
    { lat: 48.6889408, lng: 18.9734392 },
    { lat: 48.6888308, lng: 18.9732692 },
    { lat: 48.6886597, lng: 18.9729436 },
    { lat: 48.688531, lng: 18.9727391 },
    { lat: 48.6884213, lng: 18.972604 },
    { lat: 48.6881756, lng: 18.9723563 },
    { lat: 48.6877171, lng: 18.9718324 },
    { lat: 48.6876471, lng: 18.9716785 },
    { lat: 48.6874861, lng: 18.9712162 },
    { lat: 48.6874046, lng: 18.971054 },
    { lat: 48.6872812, lng: 18.9709313 },
    { lat: 48.6870635, lng: 18.9707106 },
    { lat: 48.6868524, lng: 18.9705042 },
    { lat: 48.6866585, lng: 18.9702826 },
    { lat: 48.6864091, lng: 18.9699686 },
    { lat: 48.6861765, lng: 18.9698079 },
    { lat: 48.6858876, lng: 18.9697101 },
    { lat: 48.6853904, lng: 18.9696439 },
    { lat: 48.6848644, lng: 18.9695022 },
    { lat: 48.684163, lng: 18.9691874 },
    { lat: 48.6838998, lng: 18.9689792 },
    { lat: 48.6837524, lng: 18.9688546 },
    { lat: 48.6834023, lng: 18.968487 },
    { lat: 48.6831718, lng: 18.968163 },
    { lat: 48.682983, lng: 18.9678235 },
    { lat: 48.6828004, lng: 18.9676248 },
    { lat: 48.6827139, lng: 18.9674989 },
    { lat: 48.682486, lng: 18.9670258 },
    { lat: 48.682453, lng: 18.966888 },
    { lat: 48.6824246, lng: 18.9667942 },
    { lat: 48.6823696, lng: 18.9665587 },
    { lat: 48.6823029, lng: 18.9663762 },
    { lat: 48.682131, lng: 18.9660344 },
    { lat: 48.6820459, lng: 18.9656239 },
    { lat: 48.6819175, lng: 18.9651636 },
    { lat: 48.6817022, lng: 18.9646969 },
    { lat: 48.6815351, lng: 18.9644494 },
    { lat: 48.6813947, lng: 18.9643037 },
    { lat: 48.681321, lng: 18.9642491 },
    { lat: 48.68108, lng: 18.9640123 },
    { lat: 48.6802447, lng: 18.9634199 },
    { lat: 48.6800383, lng: 18.9631951 },
    { lat: 48.6796821, lng: 18.9629079 },
    { lat: 48.6794452, lng: 18.9626414 },
    { lat: 48.6793487, lng: 18.9625184 },
    { lat: 48.6790201, lng: 18.9617755 },
    { lat: 48.6787296, lng: 18.9608074 },
    { lat: 48.6785165, lng: 18.9602317 },
    { lat: 48.6782444, lng: 18.9597374 },
    { lat: 48.6777742, lng: 18.9590263 },
    { lat: 48.6775367, lng: 18.9586358 },
    { lat: 48.6771242, lng: 18.9584657 },
    { lat: 48.6769228, lng: 18.9584498 },
    { lat: 48.6766838, lng: 18.9582981 },
    { lat: 48.6765695, lng: 18.9582204 },
    { lat: 48.6763221, lng: 18.9578574 },
    { lat: 48.6761542, lng: 18.957549 },
    { lat: 48.6759815, lng: 18.9571274 },
    { lat: 48.6758001, lng: 18.9568164 },
    { lat: 48.6753635, lng: 18.9563487 },
    { lat: 48.6750382, lng: 18.9560957 },
    { lat: 48.6748703, lng: 18.9560089 },
    { lat: 48.6747476, lng: 18.9559465 },
    { lat: 48.6744653, lng: 18.9556931 },
    { lat: 48.6742579, lng: 18.9554286 },
    { lat: 48.6737933, lng: 18.9550934 },
    { lat: 48.6734974, lng: 18.9547753 },
    { lat: 48.6733305, lng: 18.9544872 },
    { lat: 48.6731703, lng: 18.9542232 },
    { lat: 48.672271, lng: 18.9527006 },
    { lat: 48.6722115, lng: 18.9526173 },
    { lat: 48.6721396, lng: 18.9525144 },
    { lat: 48.6720744, lng: 18.9524306 },
    { lat: 48.6719583, lng: 18.9522761 },
    { lat: 48.6719116, lng: 18.9521763 },
    { lat: 48.6717357, lng: 18.9518043 },
    { lat: 48.6714064, lng: 18.9512493 },
    { lat: 48.6709773, lng: 18.9508123 },
    { lat: 48.6704457, lng: 18.9507389 },
    { lat: 48.6699157, lng: 18.9507609 },
    { lat: 48.6695027, lng: 18.9504408 },
    { lat: 48.6691055, lng: 18.9502604 },
    { lat: 48.6690327, lng: 18.9502454 },
    { lat: 48.6689886, lng: 18.9500475 },
    { lat: 48.6686601, lng: 18.9494857 },
    { lat: 48.6684142, lng: 18.9490645 },
    { lat: 48.6680313, lng: 18.9484099 },
    { lat: 48.6666828, lng: 18.9469487 },
    { lat: 48.6666222, lng: 18.9468673 },
    { lat: 48.6660958, lng: 18.9461652 },
    { lat: 48.6660603, lng: 18.9461076 },
    { lat: 48.6656066, lng: 18.9459674 },
    { lat: 48.6655354, lng: 18.9459418 },
    { lat: 48.6653372, lng: 18.9458832 },
    { lat: 48.6647684, lng: 18.945714 },
    { lat: 48.6646661, lng: 18.945677 },
    { lat: 48.6645674, lng: 18.945641 },
    { lat: 48.6644192, lng: 18.9455431 },
    { lat: 48.6642216, lng: 18.9455603 },
    { lat: 48.6639612, lng: 18.9456516 },
    { lat: 48.6638621, lng: 18.9456325 },
    { lat: 48.6637143, lng: 18.9456943 },
    { lat: 48.6635109, lng: 18.9457975 },
    { lat: 48.6632719, lng: 18.9458638 },
    { lat: 48.6630886, lng: 18.945323 },
    { lat: 48.6626871, lng: 18.9454971 },
    { lat: 48.6623206, lng: 18.9456499 },
    { lat: 48.6620086, lng: 18.9456784 },
    { lat: 48.6617393, lng: 18.9455503 },
    { lat: 48.6615166, lng: 18.9454281 },
    { lat: 48.6607614, lng: 18.9454126 },
    { lat: 48.6604337, lng: 18.94595 },
    { lat: 48.660062, lng: 18.9463549 },
    { lat: 48.6595792, lng: 18.946635 },
    { lat: 48.6592089, lng: 18.9468707 },
    { lat: 48.6592656, lng: 18.947561 },
    { lat: 48.65854, lng: 18.9477499 },
    { lat: 48.6584934, lng: 18.9491972 },
    { lat: 48.6579264, lng: 18.9494026 },
    { lat: 48.6576641, lng: 18.9494983 },
    { lat: 48.6569313, lng: 18.9497647 },
    { lat: 48.656401, lng: 18.9499576 },
    { lat: 48.6560687, lng: 18.9501827 },
    { lat: 48.6555817, lng: 18.9505143 },
    { lat: 48.6553177, lng: 18.9504665 },
    { lat: 48.6553396, lng: 18.9505906 },
    { lat: 48.6550228, lng: 18.9507447 },
    { lat: 48.65506, lng: 18.9510096 },
    { lat: 48.6550791, lng: 18.9511401 },
    { lat: 48.6550807, lng: 18.9511517 },
    { lat: 48.6550858, lng: 18.9512053 },
    { lat: 48.6550898, lng: 18.951238 },
    { lat: 48.654805, lng: 18.9513381 },
    { lat: 48.6545723, lng: 18.9515513 },
    { lat: 48.6544674, lng: 18.9516166 },
    { lat: 48.6542573, lng: 18.9516988 },
    { lat: 48.6539491, lng: 18.9516876 },
    { lat: 48.6534059, lng: 18.9519839 },
    { lat: 48.6532108, lng: 18.9521385 },
    { lat: 48.6530034, lng: 18.9522446 },
    { lat: 48.6527384, lng: 18.9522244 },
    { lat: 48.6524818, lng: 18.9522321 },
    { lat: 48.6521495, lng: 18.9522636 },
    { lat: 48.6512415, lng: 18.9519901 },
    { lat: 48.6509105, lng: 18.9516655 },
    { lat: 48.6504506, lng: 18.9514034 },
    { lat: 48.6500436, lng: 18.9512071 },
    { lat: 48.6497251, lng: 18.9514613 },
    { lat: 48.6494879, lng: 18.9519856 },
    { lat: 48.6496158, lng: 18.9528575 },
    { lat: 48.6496815, lng: 18.9533904 },
    { lat: 48.649619, lng: 18.9536067 },
    { lat: 48.6494379, lng: 18.953965 },
    { lat: 48.6491635, lng: 18.9541124 },
    { lat: 48.6488409, lng: 18.9540875 },
    { lat: 48.6484218, lng: 18.9537625 },
    { lat: 48.647988, lng: 18.9538371 },
    { lat: 48.647665, lng: 18.9536051 },
    { lat: 48.6475143, lng: 18.9534568 },
    { lat: 48.6474444, lng: 18.9533896 },
    { lat: 48.6472293, lng: 18.9534845 },
    { lat: 48.6470317, lng: 18.9535014 },
    { lat: 48.646609, lng: 18.9537698 },
    { lat: 48.6460635, lng: 18.9541687 },
    { lat: 48.6457236, lng: 18.9545789 },
    { lat: 48.6455402, lng: 18.954786 },
    { lat: 48.6452063, lng: 18.9546975 },
    { lat: 48.6450002, lng: 18.9549236 },
    { lat: 48.6448608, lng: 18.9549968 },
    { lat: 48.6445997, lng: 18.9552692 },
    { lat: 48.6442765, lng: 18.9555435 },
    { lat: 48.6442649, lng: 18.9558447 },
    { lat: 48.6440774, lng: 18.956018 },
    { lat: 48.6436553, lng: 18.9563401 },
    { lat: 48.6433323, lng: 18.956752 },
    { lat: 48.6430257, lng: 18.9570817 },
    { lat: 48.6426244, lng: 18.9572612 },
    { lat: 48.6423768, lng: 18.9572472 },
    { lat: 48.6422035, lng: 18.9571889 },
    { lat: 48.6420627, lng: 18.9572131 },
    { lat: 48.6417698, lng: 18.9573961 },
    { lat: 48.6415945, lng: 18.9574275 },
    { lat: 48.6414074, lng: 18.957252 },
    { lat: 48.6412186, lng: 18.9570654 },
    { lat: 48.6409407, lng: 18.9568566 },
    { lat: 48.6407638, lng: 18.9565937 },
    { lat: 48.6405849, lng: 18.9562031 },
    { lat: 48.6403437, lng: 18.9561865 },
    { lat: 48.6400787, lng: 18.956049 },
    { lat: 48.6396973, lng: 18.9550284 },
    { lat: 48.6395723, lng: 18.9548094 },
    { lat: 48.6393272, lng: 18.9546236 },
    { lat: 48.6390535, lng: 18.9544953 },
    { lat: 48.638708, lng: 18.9543547 },
    { lat: 48.6384098, lng: 18.9543226 },
    { lat: 48.6381911, lng: 18.9543658 },
    { lat: 48.6377327, lng: 18.9543339 },
    { lat: 48.6374675, lng: 18.9542606 },
    { lat: 48.6372858, lng: 18.9543251 },
    { lat: 48.6370934, lng: 18.9544633 },
    { lat: 48.6370145, lng: 18.9547324 },
    { lat: 48.6368735, lng: 18.9548864 },
    { lat: 48.6367105, lng: 18.9549388 },
    { lat: 48.6365284, lng: 18.9549492 },
    { lat: 48.6360796, lng: 18.9549507 },
    { lat: 48.6355567, lng: 18.9550424 },
    { lat: 48.6351303, lng: 18.9553924 },
    { lat: 48.6350729, lng: 18.9554148 },
    { lat: 48.6350411, lng: 18.9554352 },
    { lat: 48.634872, lng: 18.9555247 },
    { lat: 48.6347853, lng: 18.9555712 },
    { lat: 48.6345347, lng: 18.9556095 },
    { lat: 48.6345003, lng: 18.9556164 },
    { lat: 48.6342323, lng: 18.9556706 },
    { lat: 48.6339368, lng: 18.9557548 },
    { lat: 48.6336625, lng: 18.9557282 },
    { lat: 48.6335684, lng: 18.9557185 },
    { lat: 48.6330868, lng: 18.9556934 },
    { lat: 48.6326464, lng: 18.955726 },
    { lat: 48.6322305, lng: 18.9555715 },
    { lat: 48.6319253, lng: 18.955424 },
    { lat: 48.6316068, lng: 18.9552575 },
    { lat: 48.6312639, lng: 18.9550798 },
    { lat: 48.6310383, lng: 18.9549884 },
    { lat: 48.6308204, lng: 18.9550942 },
    { lat: 48.6305404, lng: 18.9552405 },
    { lat: 48.6301092, lng: 18.9551672 },
    { lat: 48.6297434, lng: 18.9552453 },
    { lat: 48.6297561, lng: 18.9553404 },
    { lat: 48.6296971, lng: 18.955508 },
    { lat: 48.6297271, lng: 18.9555869 },
    { lat: 48.629685, lng: 18.9557343 },
    { lat: 48.6296924, lng: 18.9564135 },
    { lat: 48.6296093, lng: 18.9571699 },
    { lat: 48.6296515, lng: 18.9579748 },
    { lat: 48.6296929, lng: 18.9584338 },
    { lat: 48.6297752, lng: 18.9593385 },
    { lat: 48.629763, lng: 18.9595873 },
    { lat: 48.6296641, lng: 18.9601673 },
    { lat: 48.6296579, lng: 18.9603148 },
    { lat: 48.6297087, lng: 18.9605378 },
    { lat: 48.6298237, lng: 18.9608249 },
    { lat: 48.6298589, lng: 18.9609171 },
    { lat: 48.6299632, lng: 18.9611702 },
    { lat: 48.6301382, lng: 18.9622801 },
    { lat: 48.6301555, lng: 18.9629322 },
    { lat: 48.6300735, lng: 18.9642014 },
    { lat: 48.630298, lng: 18.9655765 },
    { lat: 48.630344, lng: 18.9663924 },
    { lat: 48.6303091, lng: 18.9670804 },
    { lat: 48.6303032, lng: 18.9672066 },
    { lat: 48.6303265, lng: 18.9679275 },
    { lat: 48.6303821, lng: 18.9688221 },
    { lat: 48.6304722, lng: 18.9693987 },
    { lat: 48.6308081, lng: 18.9702787 },
    { lat: 48.6310202, lng: 18.9707482 },
    { lat: 48.6310773, lng: 18.9708753 },
    { lat: 48.6311367, lng: 18.9710445 },
    { lat: 48.6311525, lng: 18.9710911 },
    { lat: 48.6312106, lng: 18.9712587 },
    { lat: 48.6311026, lng: 18.971484 },
    { lat: 48.6311477, lng: 18.9717721 },
    { lat: 48.6313106, lng: 18.9723371 },
    { lat: 48.6315476, lng: 18.972453 },
    { lat: 48.6314101, lng: 18.9725833 },
    { lat: 48.6314696, lng: 18.9730669 },
    { lat: 48.6314444, lng: 18.9732402 },
    { lat: 48.6318711, lng: 18.9738663 },
    { lat: 48.6319425, lng: 18.9744946 },
    { lat: 48.6321258, lng: 18.9749543 },
    { lat: 48.6322331, lng: 18.9754434 },
    { lat: 48.6320955, lng: 18.9758407 },
    { lat: 48.6320761, lng: 18.9759292 },
    { lat: 48.6320184, lng: 18.9760995 },
    { lat: 48.6319862, lng: 18.976619 },
    { lat: 48.632142, lng: 18.9770907 },
    { lat: 48.632005, lng: 18.9771707 },
    { lat: 48.6318874, lng: 18.9771633 },
    { lat: 48.6317171, lng: 18.9773174 },
    { lat: 48.6318293, lng: 18.9776142 },
    { lat: 48.6318233, lng: 18.9777842 },
    { lat: 48.6318188, lng: 18.9778535 },
    { lat: 48.6318095, lng: 18.9780477 },
    { lat: 48.6319607, lng: 18.9782709 },
    { lat: 48.6320021, lng: 18.9785316 },
    { lat: 48.6318257, lng: 18.978767 },
    { lat: 48.6318934, lng: 18.9792122 },
    { lat: 48.6318116, lng: 18.9792427 },
    { lat: 48.6317985, lng: 18.9791625 },
    { lat: 48.631599, lng: 18.9791881 },
    { lat: 48.6315153, lng: 18.9795118 },
    { lat: 48.6317546, lng: 18.9796748 },
    { lat: 48.6317914, lng: 18.9800217 },
    { lat: 48.6321754, lng: 18.9804567 },
    { lat: 48.6321775, lng: 18.9812752 },
    { lat: 48.6320479, lng: 18.9814292 },
    { lat: 48.6318964, lng: 18.9815123 },
    { lat: 48.6317329, lng: 18.9814888 },
    { lat: 48.6318406, lng: 18.9819023 },
    { lat: 48.6317677, lng: 18.9823096 },
    { lat: 48.6315286, lng: 18.9826398 },
    { lat: 48.6313195, lng: 18.9824541 },
    { lat: 48.6312221, lng: 18.9826018 },
    { lat: 48.631396, lng: 18.982961 },
    { lat: 48.631469, lng: 18.9833974 },
    { lat: 48.6316229, lng: 18.9835432 },
    { lat: 48.6314997, lng: 18.9838027 },
    { lat: 48.6307683, lng: 18.9840091 },
    { lat: 48.6307014, lng: 18.9842449 },
    { lat: 48.6307374, lng: 18.985114 },
    { lat: 48.6307273, lng: 18.98558 },
    { lat: 48.6303411, lng: 18.9863221 },
    { lat: 48.630236, lng: 18.9868079 },
    { lat: 48.630164, lng: 18.9871898 },
    { lat: 48.6301886, lng: 18.9874286 },
    { lat: 48.6301029, lng: 18.9878733 },
    { lat: 48.630023, lng: 18.9882536 },
    { lat: 48.6299401, lng: 18.9886463 },
    { lat: 48.6297433, lng: 18.9894336 },
    { lat: 48.6296831, lng: 18.9896672 },
    { lat: 48.629323, lng: 18.989952 },
    { lat: 48.629906, lng: 18.989825 },
    { lat: 48.631786, lng: 18.990456 },
    { lat: 48.631957, lng: 18.990512 },
    { lat: 48.63374, lng: 18.992139 },
    { lat: 48.634438, lng: 18.992838 },
    { lat: 48.634525, lng: 18.993061 },
    { lat: 48.635504, lng: 18.994108 },
    { lat: 48.637842, lng: 18.995663 },
    { lat: 48.639008, lng: 18.995921 },
    { lat: 48.639827, lng: 18.997239 },
    { lat: 48.640575, lng: 18.997689 },
    { lat: 48.641446, lng: 18.997434 },
    { lat: 48.641918, lng: 18.997481 },
    { lat: 48.642072, lng: 18.997447 },
    { lat: 48.642246, lng: 18.997344 },
    { lat: 48.642384, lng: 18.997241 },
    { lat: 48.642575, lng: 18.99701 },
    { lat: 48.642717, lng: 18.996811 },
    { lat: 48.64281, lng: 18.996667 },
    { lat: 48.642918, lng: 18.996543 },
    { lat: 48.643077, lng: 18.996399 },
    { lat: 48.643305, lng: 18.996207 },
    { lat: 48.643527, lng: 18.996036 },
    { lat: 48.644096, lng: 18.995605 },
    { lat: 48.644196, lng: 18.995492 },
    { lat: 48.644339, lng: 18.995299 },
    { lat: 48.644638, lng: 18.994808 },
    { lat: 48.644781, lng: 18.994578 },
    { lat: 48.644979, lng: 18.994312 },
    { lat: 48.645199, lng: 18.99402 },
    { lat: 48.645417, lng: 18.993746 },
    { lat: 48.645819, lng: 18.993213 },
    { lat: 48.645962, lng: 18.993011 },
    { lat: 48.646442, lng: 18.992245 },
    { lat: 48.646676, lng: 18.991887 },
    { lat: 48.647208, lng: 18.991098 },
    { lat: 48.647597, lng: 18.990584 },
    { lat: 48.647719, lng: 18.990481 },
    { lat: 48.64781, lng: 18.990374 },
    { lat: 48.647907, lng: 18.990262 },
    { lat: 48.648105, lng: 18.989981 },
    { lat: 48.648236, lng: 18.989751 },
    { lat: 48.648282, lng: 18.989575 },
    { lat: 48.648329, lng: 18.989199 },
    { lat: 48.648352, lng: 18.989018 },
    { lat: 48.648369, lng: 18.988596 },
    { lat: 48.648419, lng: 18.98862 },
    { lat: 48.648453, lng: 18.98858 },
    { lat: 48.648564, lng: 18.988639 },
    { lat: 48.648608, lng: 18.988713 },
    { lat: 48.648741, lng: 18.988976 },
    { lat: 48.648847, lng: 18.989141 },
    { lat: 48.649204, lng: 18.98949 },
    { lat: 48.649386, lng: 18.989642 },
    { lat: 48.649512, lng: 18.989735 },
    { lat: 48.650143, lng: 18.99003 },
    { lat: 48.650243, lng: 18.990058 },
    { lat: 48.650364, lng: 18.990049 },
    { lat: 48.65076, lng: 18.989982 },
    { lat: 48.651058, lng: 18.98998 },
    { lat: 48.651156, lng: 18.98999 },
    { lat: 48.651281, lng: 18.990039 },
    { lat: 48.651455, lng: 18.990147 },
    { lat: 48.651718, lng: 18.990373 },
    { lat: 48.651918, lng: 18.990528 },
    { lat: 48.652133, lng: 18.990608 },
    { lat: 48.652394, lng: 18.990653 },
    { lat: 48.652824, lng: 18.990623 },
    { lat: 48.652997, lng: 18.990537 },
    { lat: 48.653157, lng: 18.990418 },
    { lat: 48.65336, lng: 18.990315 },
    { lat: 48.653593, lng: 18.990239 },
    { lat: 48.653748, lng: 18.990231 },
    { lat: 48.653877, lng: 18.990242 },
    { lat: 48.654012, lng: 18.990295 },
    { lat: 48.65412, lng: 18.990361 },
    { lat: 48.654449, lng: 18.99056 },
    { lat: 48.654712, lng: 18.990693 },
    { lat: 48.654814, lng: 18.990708 },
    { lat: 48.655173, lng: 18.990671 },
    { lat: 48.655555, lng: 18.990589 },
    { lat: 48.655972, lng: 18.99058 },
    { lat: 48.656349, lng: 18.990645 },
    { lat: 48.656448, lng: 18.990741 },
    { lat: 48.656722, lng: 18.991067 },
    { lat: 48.657022, lng: 18.991486 },
    { lat: 48.657537, lng: 18.992114 },
    { lat: 48.657962, lng: 18.992615 },
    { lat: 48.658328, lng: 18.993035 },
    { lat: 48.658411, lng: 18.993131 },
    { lat: 48.65867, lng: 18.993449 },
    { lat: 48.65892, lng: 18.993721 },
    { lat: 48.659216, lng: 18.993997 },
    { lat: 48.659515, lng: 18.99424 },
    { lat: 48.660341, lng: 18.994976 },
    { lat: 48.660526, lng: 18.99519 },
    { lat: 48.660999, lng: 18.995733 },
    { lat: 48.661066, lng: 18.995806 },
    { lat: 48.661271, lng: 18.99594 },
    { lat: 48.662118, lng: 18.99589 },
    { lat: 48.662497, lng: 18.995921 },
    { lat: 48.662639, lng: 18.995968 },
    { lat: 48.66284, lng: 18.996151 },
    { lat: 48.662911, lng: 18.996219 },
    { lat: 48.663191, lng: 18.996513 },
    { lat: 48.663278, lng: 18.99659 },
    { lat: 48.663566, lng: 18.996742 },
    { lat: 48.663667, lng: 18.996823 },
    { lat: 48.663747, lng: 18.996939 },
    { lat: 48.66387, lng: 18.997245 },
    { lat: 48.663973, lng: 18.997407 },
    { lat: 48.664054, lng: 18.997534 },
    { lat: 48.66426, lng: 18.997786 },
    { lat: 48.664405, lng: 18.998034 },
    { lat: 48.664496, lng: 18.998232 },
    { lat: 48.664643, lng: 18.998656 },
    { lat: 48.664703, lng: 18.998945 },
    { lat: 48.664764, lng: 18.999462 },
    { lat: 48.664831, lng: 18.999653 },
    { lat: 48.664945, lng: 18.999823 },
    { lat: 48.665044, lng: 18.999915 },
    { lat: 48.665151, lng: 18.999978 },
    { lat: 48.665543, lng: 19.000169 },
    { lat: 48.666244, lng: 19.000306 },
    { lat: 48.666511, lng: 19.000483 },
    { lat: 48.666701, lng: 19.000723 },
    { lat: 48.666788, lng: 19.000924 },
    { lat: 48.666836, lng: 19.00122 },
    { lat: 48.666849, lng: 19.001321 },
    { lat: 48.666885, lng: 19.001854 },
    { lat: 48.66694, lng: 19.002221 },
    { lat: 48.667042, lng: 19.002481 },
    { lat: 48.667199, lng: 19.002713 },
    { lat: 48.667452, lng: 19.002927 },
    { lat: 48.667569, lng: 19.003004 },
    { lat: 48.667837, lng: 19.003385 },
    { lat: 48.667963, lng: 19.003677 },
    { lat: 48.668096, lng: 19.004237 },
    { lat: 48.668177, lng: 19.004918 },
    { lat: 48.668245, lng: 19.005322 },
    { lat: 48.66826, lng: 19.005655 },
    { lat: 48.668263, lng: 19.006132 },
    { lat: 48.668325, lng: 19.006358 },
    { lat: 48.668475, lng: 19.006682 },
    { lat: 48.668773, lng: 19.007061 },
    { lat: 48.668999, lng: 19.007279 },
    { lat: 48.670011, lng: 19.008133 },
    { lat: 48.670589, lng: 19.008594 },
    { lat: 48.671847, lng: 19.009592 },
    { lat: 48.673395, lng: 19.01125 },
    { lat: 48.673727, lng: 19.011718 },
    { lat: 48.674416, lng: 19.012395 },
    { lat: 48.674735, lng: 19.012831 },
    { lat: 48.675114, lng: 19.013346 },
    { lat: 48.675555, lng: 19.013987 },
    { lat: 48.675922, lng: 19.01404 },
  ],
  StaráKremnička: [
    { lat: 48.6474361, lng: 18.8854853 },
    { lat: 48.6466341, lng: 18.8854112 },
    { lat: 48.6460502, lng: 18.8849582 },
    { lat: 48.6459834, lng: 18.8846654 },
    { lat: 48.6457526, lng: 18.8841081 },
    { lat: 48.644435, lng: 18.8830667 },
    { lat: 48.6441289, lng: 18.8829682 },
    { lat: 48.6421773, lng: 18.8827373 },
    { lat: 48.6420385, lng: 18.8828225 },
    { lat: 48.6413864, lng: 18.8828563 },
    { lat: 48.6408796, lng: 18.8828257 },
    { lat: 48.6402772, lng: 18.8827549 },
    { lat: 48.6400304, lng: 18.8828025 },
    { lat: 48.6395867, lng: 18.8826849 },
    { lat: 48.63959, lng: 18.8825523 },
    { lat: 48.6395945, lng: 18.8819388 },
    { lat: 48.6400298, lng: 18.8816338 },
    { lat: 48.6403553, lng: 18.8808843 },
    { lat: 48.6403127, lng: 18.8802988 },
    { lat: 48.639009, lng: 18.8796186 },
    { lat: 48.6384198, lng: 18.8791039 },
    { lat: 48.638084, lng: 18.8789348 },
    { lat: 48.6378324, lng: 18.8789298 },
    { lat: 48.6371753, lng: 18.879288 },
    { lat: 48.6365343, lng: 18.8790593 },
    { lat: 48.6363229, lng: 18.878984 },
    { lat: 48.6347225, lng: 18.8783437 },
    { lat: 48.6344758, lng: 18.8783067 },
    { lat: 48.6344317, lng: 18.8783041 },
    { lat: 48.6343132, lng: 18.878277 },
    { lat: 48.6338194, lng: 18.8782795 },
    { lat: 48.632699, lng: 18.878418 },
    { lat: 48.6316258, lng: 18.8779425 },
    { lat: 48.6311422, lng: 18.8777288 },
    { lat: 48.6293724, lng: 18.8768883 },
    { lat: 48.6289903, lng: 18.8766078 },
    { lat: 48.6279864, lng: 18.8756194 },
    { lat: 48.6277634, lng: 18.875492 },
    { lat: 48.6274181, lng: 18.8753797 },
    { lat: 48.6273453, lng: 18.8754111 },
    { lat: 48.6265988, lng: 18.8757388 },
    { lat: 48.6258145, lng: 18.8760535 },
    { lat: 48.6242035, lng: 18.8763884 },
    { lat: 48.6238884, lng: 18.876377 },
    { lat: 48.6234023, lng: 18.8760468 },
    { lat: 48.6230284, lng: 18.8759489 },
    { lat: 48.622698, lng: 18.8759069 },
    { lat: 48.6224337, lng: 18.8758955 },
    { lat: 48.6220845, lng: 18.8762054 },
    { lat: 48.6217704, lng: 18.8761724 },
    { lat: 48.6211645, lng: 18.8761664 },
    { lat: 48.6208635, lng: 18.8760571 },
    { lat: 48.6206082, lng: 18.8758091 },
    { lat: 48.6202709, lng: 18.8753491 },
    { lat: 48.6197552, lng: 18.8745341 },
    { lat: 48.6193527, lng: 18.8742315 },
    { lat: 48.6192587, lng: 18.8741367 },
    { lat: 48.6191874, lng: 18.8740646 },
    { lat: 48.6181576, lng: 18.8726647 },
    { lat: 48.6174562, lng: 18.8702479 },
    { lat: 48.6173409, lng: 18.869847 },
    { lat: 48.6158631, lng: 18.8672032 },
    { lat: 48.6154743, lng: 18.8658146 },
    { lat: 48.6136534, lng: 18.8663837 },
    { lat: 48.6130465, lng: 18.86655 },
    { lat: 48.6124328, lng: 18.8668496 },
    { lat: 48.612159, lng: 18.8670522 },
    { lat: 48.611869, lng: 18.867266 },
    { lat: 48.6110753, lng: 18.8684427 },
    { lat: 48.6106833, lng: 18.8688623 },
    { lat: 48.6094381, lng: 18.8698591 },
    { lat: 48.6090644, lng: 18.8701814 },
    { lat: 48.6089154, lng: 18.8703522 },
    { lat: 48.6087658, lng: 18.8707243 },
    { lat: 48.6084776, lng: 18.8710864 },
    { lat: 48.6080725, lng: 18.8713635 },
    { lat: 48.6072977, lng: 18.8717519 },
    { lat: 48.6070086, lng: 18.8720285 },
    { lat: 48.6066754, lng: 18.872475 },
    { lat: 48.6063604, lng: 18.8727936 },
    { lat: 48.6061751, lng: 18.873027 },
    { lat: 48.6060168, lng: 18.8732786 },
    { lat: 48.6059017, lng: 18.8734087 },
    { lat: 48.6054816, lng: 18.8736566 },
    { lat: 48.6052456, lng: 18.8738678 },
    { lat: 48.6050251, lng: 18.8740324 },
    { lat: 48.6039183, lng: 18.8745199 },
    { lat: 48.6032869, lng: 18.8750901 },
    { lat: 48.6030412, lng: 18.8753972 },
    { lat: 48.6021251, lng: 18.8765428 },
    { lat: 48.6018377, lng: 18.876391 },
    { lat: 48.6017634, lng: 18.8763527 },
    { lat: 48.6017009, lng: 18.8763187 },
    { lat: 48.6016739, lng: 18.8763443 },
    { lat: 48.600938, lng: 18.8770507 },
    { lat: 48.6004822, lng: 18.8771342 },
    { lat: 48.6001192, lng: 18.8776343 },
    { lat: 48.6000539, lng: 18.877724 },
    { lat: 48.5988034, lng: 18.8794463 },
    { lat: 48.5979363, lng: 18.879992 },
    { lat: 48.5970776, lng: 18.8809422 },
    { lat: 48.5965611, lng: 18.8812672 },
    { lat: 48.5964716, lng: 18.881324 },
    { lat: 48.5963471, lng: 18.8814024 },
    { lat: 48.5964183, lng: 18.8814307 },
    { lat: 48.5964725, lng: 18.8814518 },
    { lat: 48.596735, lng: 18.8815563 },
    { lat: 48.5979746, lng: 18.8820462 },
    { lat: 48.5981371, lng: 18.8821123 },
    { lat: 48.6000388, lng: 18.8828643 },
    { lat: 48.5998797, lng: 18.8829959 },
    { lat: 48.5998361, lng: 18.8830431 },
    { lat: 48.5995577, lng: 18.8833077 },
    { lat: 48.5992171, lng: 18.8835932 },
    { lat: 48.5981473, lng: 18.8848848 },
    { lat: 48.597461, lng: 18.8856336 },
    { lat: 48.5971189, lng: 18.8864575 },
    { lat: 48.5970508, lng: 18.8866678 },
    { lat: 48.5969009, lng: 18.8869736 },
    { lat: 48.5966768, lng: 18.8871773 },
    { lat: 48.5964259, lng: 18.8872741 },
    { lat: 48.5963329, lng: 18.8883323 },
    { lat: 48.5959804, lng: 18.8891705 },
    { lat: 48.5957482, lng: 18.8897217 },
    { lat: 48.5952726, lng: 18.8903827 },
    { lat: 48.5950284, lng: 18.8915064 },
    { lat: 48.5947525, lng: 18.8919067 },
    { lat: 48.5943767, lng: 18.8924452 },
    { lat: 48.5934032, lng: 18.8938384 },
    { lat: 48.5932944, lng: 18.8940004 },
    { lat: 48.592907, lng: 18.8945573 },
    { lat: 48.5928388, lng: 18.8946554 },
    { lat: 48.5928404, lng: 18.8947325 },
    { lat: 48.5928333, lng: 18.894878 },
    { lat: 48.5928307, lng: 18.8949146 },
    { lat: 48.5928721, lng: 18.8951506 },
    { lat: 48.5928799, lng: 18.8951913 },
    { lat: 48.5928829, lng: 18.8952074 },
    { lat: 48.5928944, lng: 18.8952979 },
    { lat: 48.5926016, lng: 18.8956325 },
    { lat: 48.5922429, lng: 18.8959573 },
    { lat: 48.5922718, lng: 18.8960177 },
    { lat: 48.5923335, lng: 18.8961654 },
    { lat: 48.5930701, lng: 18.897941 },
    { lat: 48.5931022, lng: 18.8980177 },
    { lat: 48.5931233, lng: 18.8980744 },
    { lat: 48.5918505, lng: 18.8993928 },
    { lat: 48.591722, lng: 18.8995258 },
    { lat: 48.5899794, lng: 18.9013322 },
    { lat: 48.5895202, lng: 18.9016798 },
    { lat: 48.5894062, lng: 18.9017655 },
    { lat: 48.5891568, lng: 18.9019143 },
    { lat: 48.589095, lng: 18.9019625 },
    { lat: 48.5889663, lng: 18.9019989 },
    { lat: 48.5885505, lng: 18.9018303 },
    { lat: 48.5885546, lng: 18.9021949 },
    { lat: 48.5885532, lng: 18.9026499 },
    { lat: 48.5885523, lng: 18.9029627 },
    { lat: 48.5885518, lng: 18.9029713 },
    { lat: 48.5885532, lng: 18.9031767 },
    { lat: 48.5885543, lng: 18.9033504 },
    { lat: 48.5885547, lng: 18.9037517 },
    { lat: 48.5885614, lng: 18.9041032 },
    { lat: 48.5885556, lng: 18.9044721 },
    { lat: 48.5885582, lng: 18.9045634 },
    { lat: 48.5885619, lng: 18.9046584 },
    { lat: 48.5885681, lng: 18.9048201 },
    { lat: 48.5885687, lng: 18.9048546 },
    { lat: 48.5885776, lng: 18.9050287 },
    { lat: 48.5885907, lng: 18.9052181 },
    { lat: 48.5885911, lng: 18.9052255 },
    { lat: 48.5886034, lng: 18.9054001 },
    { lat: 48.5886183, lng: 18.9056451 },
    { lat: 48.5886251, lng: 18.9058003 },
    { lat: 48.5886315, lng: 18.9062078 },
    { lat: 48.5886338, lng: 18.9063165 },
    { lat: 48.5886451, lng: 18.9064092 },
    { lat: 48.5886533, lng: 18.9064759 },
    { lat: 48.588679, lng: 18.9066901 },
    { lat: 48.5887017, lng: 18.906696 },
    { lat: 48.5890249, lng: 18.906535 },
    { lat: 48.5892926, lng: 18.9063912 },
    { lat: 48.5893278, lng: 18.9063724 },
    { lat: 48.5896581, lng: 18.9064147 },
    { lat: 48.5902846, lng: 18.9064945 },
    { lat: 48.5906425, lng: 18.9064612 },
    { lat: 48.5909153, lng: 18.9066338 },
    { lat: 48.5911953, lng: 18.9068169 },
    { lat: 48.5914257, lng: 18.9069324 },
    { lat: 48.5925315, lng: 18.9072309 },
    { lat: 48.5928858, lng: 18.9071271 },
    { lat: 48.5931359, lng: 18.9065547 },
    { lat: 48.5933227, lng: 18.9062187 },
    { lat: 48.5935158, lng: 18.9059582 },
    { lat: 48.5936308, lng: 18.9058268 },
    { lat: 48.5937931, lng: 18.9057163 },
    { lat: 48.5940492, lng: 18.9055869 },
    { lat: 48.5942991, lng: 18.9055121 },
    { lat: 48.5948999, lng: 18.9054465 },
    { lat: 48.5955349, lng: 18.9053391 },
    { lat: 48.5961163, lng: 18.9054834 },
    { lat: 48.5969893, lng: 18.9056203 },
    { lat: 48.5972202, lng: 18.9056356 },
    { lat: 48.5975735, lng: 18.9055997 },
    { lat: 48.5990257, lng: 18.9046763 },
    { lat: 48.5993043, lng: 18.9036498 },
    { lat: 48.5995304, lng: 18.9028721 },
    { lat: 48.6000706, lng: 18.9022406 },
    { lat: 48.6004492, lng: 18.90127 },
    { lat: 48.6004959, lng: 18.9010615 },
    { lat: 48.6004531, lng: 18.9006893 },
    { lat: 48.6004637, lng: 18.9003727 },
    { lat: 48.6005345, lng: 18.8998839 },
    { lat: 48.6005348, lng: 18.8990598 },
    { lat: 48.6005545, lng: 18.8986767 },
    { lat: 48.600589, lng: 18.8984464 },
    { lat: 48.6007999, lng: 18.8977883 },
    { lat: 48.6009049, lng: 18.8975989 },
    { lat: 48.6010579, lng: 18.8977652 },
    { lat: 48.6010746, lng: 18.8977825 },
    { lat: 48.6010531, lng: 18.8981772 },
    { lat: 48.6010643, lng: 18.8985917 },
    { lat: 48.6011947, lng: 18.8991496 },
    { lat: 48.6014155, lng: 18.899956 },
    { lat: 48.6015549, lng: 18.9002988 },
    { lat: 48.6017662, lng: 18.9009206 },
    { lat: 48.6020034, lng: 18.9015434 },
    { lat: 48.6021482, lng: 18.9018588 },
    { lat: 48.6022735, lng: 18.9020576 },
    { lat: 48.6023847, lng: 18.9021806 },
    { lat: 48.6032478, lng: 18.9027387 },
    { lat: 48.6035026, lng: 18.9029978 },
    { lat: 48.6038995, lng: 18.9035698 },
    { lat: 48.6040368, lng: 18.9038459 },
    { lat: 48.6040916, lng: 18.9038812 },
    { lat: 48.6044061, lng: 18.904144 },
    { lat: 48.6045644, lng: 18.9045024 },
    { lat: 48.6046735, lng: 18.9052628 },
    { lat: 48.604661, lng: 18.9057214 },
    { lat: 48.6046935, lng: 18.9061786 },
    { lat: 48.6050192, lng: 18.9067226 },
    { lat: 48.6050906, lng: 18.906839 },
    { lat: 48.6054973, lng: 18.9070504 },
    { lat: 48.6057409, lng: 18.9072492 },
    { lat: 48.6059539, lng: 18.9075771 },
    { lat: 48.6063741, lng: 18.9086442 },
    { lat: 48.6070822, lng: 18.9099388 },
    { lat: 48.6078462, lng: 18.9107416 },
    { lat: 48.6082072, lng: 18.9109834 },
    { lat: 48.6083292, lng: 18.911142 },
    { lat: 48.6085942, lng: 18.9114049 },
    { lat: 48.6088428, lng: 18.9116132 },
    { lat: 48.6093822, lng: 18.9116214 },
    { lat: 48.6099591, lng: 18.9116804 },
    { lat: 48.6102642, lng: 18.911736 },
    { lat: 48.6111526, lng: 18.9120847 },
    { lat: 48.6114378, lng: 18.9122374 },
    { lat: 48.6117315, lng: 18.9124906 },
    { lat: 48.6119872, lng: 18.9127472 },
    { lat: 48.6122028, lng: 18.9130805 },
    { lat: 48.612383, lng: 18.9131848 },
    { lat: 48.6126307, lng: 18.9134214 },
    { lat: 48.6127492, lng: 18.9135992 },
    { lat: 48.6130424, lng: 18.9130518 },
    { lat: 48.613212, lng: 18.9127733 },
    { lat: 48.6135999, lng: 18.9122259 },
    { lat: 48.6138806, lng: 18.9117844 },
    { lat: 48.6142341, lng: 18.9111365 },
    { lat: 48.614299, lng: 18.9107537 },
    { lat: 48.6141771, lng: 18.9089527 },
    { lat: 48.6141778, lng: 18.9084641 },
    { lat: 48.6143079, lng: 18.9080124 },
    { lat: 48.6144184, lng: 18.907791 },
    { lat: 48.614635, lng: 18.9074218 },
    { lat: 48.6148845, lng: 18.9069261 },
    { lat: 48.6151842, lng: 18.9064994 },
    { lat: 48.6153245, lng: 18.9063387 },
    { lat: 48.6157984, lng: 18.9058813 },
    { lat: 48.6159907, lng: 18.905739 },
    { lat: 48.6168259, lng: 18.9057564 },
    { lat: 48.617623, lng: 18.9063698 },
    { lat: 48.6181654, lng: 18.9068277 },
    { lat: 48.6191171, lng: 18.9081444 },
    { lat: 48.6193155, lng: 18.9084501 },
    { lat: 48.6197737, lng: 18.9089428 },
    { lat: 48.6200477, lng: 18.9092914 },
    { lat: 48.620121, lng: 18.909357 },
    { lat: 48.6202789, lng: 18.909444 },
    { lat: 48.6207075, lng: 18.9094035 },
    { lat: 48.6208605, lng: 18.9093553 },
    { lat: 48.6210647, lng: 18.9090705 },
    { lat: 48.6211927, lng: 18.9097402 },
    { lat: 48.6211319, lng: 18.9100713 },
    { lat: 48.6209411, lng: 18.9111859 },
    { lat: 48.6205312, lng: 18.9117011 },
    { lat: 48.620515, lng: 18.9120205 },
    { lat: 48.6205775, lng: 18.9124277 },
    { lat: 48.6206515, lng: 18.9137334 },
    { lat: 48.6205681, lng: 18.9143052 },
    { lat: 48.6205247, lng: 18.9146474 },
    { lat: 48.6204687, lng: 18.9149184 },
    { lat: 48.6203607, lng: 18.9152717 },
    { lat: 48.6194455, lng: 18.9171238 },
    { lat: 48.6196067, lng: 18.9191866 },
    { lat: 48.6187873, lng: 18.9200075 },
    { lat: 48.618816, lng: 18.920456 },
    { lat: 48.6188744, lng: 18.9208063 },
    { lat: 48.6189702, lng: 18.9210895 },
    { lat: 48.6191231, lng: 18.9212562 },
    { lat: 48.6193901, lng: 18.9214079 },
    { lat: 48.6195099, lng: 18.92139 },
    { lat: 48.6197759, lng: 18.9212788 },
    { lat: 48.6202882, lng: 18.9217906 },
    { lat: 48.6203997, lng: 18.9215909 },
    { lat: 48.6205047, lng: 18.9214831 },
    { lat: 48.6206428, lng: 18.9214296 },
    { lat: 48.6209335, lng: 18.921293 },
    { lat: 48.6215999, lng: 18.9213599 },
    { lat: 48.6220541, lng: 18.9213557 },
    { lat: 48.6221878, lng: 18.9213233 },
    { lat: 48.6252208, lng: 18.9205943 },
    { lat: 48.6261083, lng: 18.9199165 },
    { lat: 48.6275714, lng: 18.9181513 },
    { lat: 48.6276491, lng: 18.9180574 },
    { lat: 48.628052, lng: 18.9176482 },
    { lat: 48.6287403, lng: 18.9164072 },
    { lat: 48.6289632, lng: 18.9158048 },
    { lat: 48.6292997, lng: 18.9148954 },
    { lat: 48.6293383, lng: 18.9147904 },
    { lat: 48.6293879, lng: 18.914658 },
    { lat: 48.6299052, lng: 18.9137766 },
    { lat: 48.6308234, lng: 18.9142414 },
    { lat: 48.6310595, lng: 18.9143611 },
    { lat: 48.6312958, lng: 18.9144807 },
    { lat: 48.6320566, lng: 18.9146962 },
    { lat: 48.6326585, lng: 18.914824 },
    { lat: 48.6330281, lng: 18.9148607 },
    { lat: 48.6330744, lng: 18.9148652 },
    { lat: 48.6330903, lng: 18.9148673 },
    { lat: 48.6331368, lng: 18.9148701 },
    { lat: 48.6332853, lng: 18.914863 },
    { lat: 48.6335023, lng: 18.9148523 },
    { lat: 48.6336082, lng: 18.9148071 },
    { lat: 48.6339308, lng: 18.9145045 },
    { lat: 48.6344464, lng: 18.9137423 },
    { lat: 48.6345901, lng: 18.9129837 },
    { lat: 48.634079, lng: 18.9124727 },
    { lat: 48.6340367, lng: 18.9122827 },
    { lat: 48.6340838, lng: 18.9120137 },
    { lat: 48.634154, lng: 18.9116045 },
    { lat: 48.63444, lng: 18.9091843 },
    { lat: 48.6344997, lng: 18.9087874 },
    { lat: 48.6345865, lng: 18.9084802 },
    { lat: 48.634857, lng: 18.9077303 },
    { lat: 48.6352423, lng: 18.9065857 },
    { lat: 48.6360135, lng: 18.9044566 },
    { lat: 48.6365615, lng: 18.902703 },
    { lat: 48.6365405, lng: 18.9024481 },
    { lat: 48.6365352, lng: 18.9023895 },
    { lat: 48.6365182, lng: 18.902235 },
    { lat: 48.6365023, lng: 18.9020771 },
    { lat: 48.6365244, lng: 18.9020239 },
    { lat: 48.6367383, lng: 18.9015151 },
    { lat: 48.6367036, lng: 18.9011901 },
    { lat: 48.6366322, lng: 18.9009425 },
    { lat: 48.6366342, lng: 18.9008524 },
    { lat: 48.6366354, lng: 18.9007877 },
    { lat: 48.6366362, lng: 18.9007342 },
    { lat: 48.6366418, lng: 18.9003435 },
    { lat: 48.6365893, lng: 18.8996263 },
    { lat: 48.6365945, lng: 18.8992916 },
    { lat: 48.6365238, lng: 18.8983121 },
    { lat: 48.6364251, lng: 18.8973136 },
    { lat: 48.6362799, lng: 18.8963813 },
    { lat: 48.636324, lng: 18.8962913 },
    { lat: 48.6366075, lng: 18.8956666 },
    { lat: 48.6368449, lng: 18.8956794 },
    { lat: 48.6373849, lng: 18.8955251 },
    { lat: 48.6375639, lng: 18.8955188 },
    { lat: 48.6378306, lng: 18.8955089 },
    { lat: 48.6384804, lng: 18.8951 },
    { lat: 48.638954, lng: 18.8952081 },
    { lat: 48.6400343, lng: 18.8951902 },
    { lat: 48.6408412, lng: 18.8943489 },
    { lat: 48.6414366, lng: 18.8939967 },
    { lat: 48.6415984, lng: 18.8935916 },
    { lat: 48.6416863, lng: 18.8933716 },
    { lat: 48.6417232, lng: 18.8932803 },
    { lat: 48.6417642, lng: 18.8931774 },
    { lat: 48.6417822, lng: 18.8929175 },
    { lat: 48.6418213, lng: 18.8923193 },
    { lat: 48.6421374, lng: 18.8920829 },
    { lat: 48.6431182, lng: 18.8913595 },
    { lat: 48.6439608, lng: 18.8912307 },
    { lat: 48.6447764, lng: 18.8911064 },
    { lat: 48.6448411, lng: 18.8910963 },
    { lat: 48.645141, lng: 18.8910511 },
    { lat: 48.6456663, lng: 18.8906999 },
    { lat: 48.6461285, lng: 18.890062 },
    { lat: 48.6461759, lng: 18.8893407 },
    { lat: 48.6463709, lng: 18.8883533 },
    { lat: 48.6468261, lng: 18.8869249 },
    { lat: 48.6470444, lng: 18.8862381 },
    { lat: 48.6474361, lng: 18.8854853 },
  ],
  HornáVes: [
    { lat: 48.6773784, lng: 18.8930375 },
    { lat: 48.6771089, lng: 18.8933239 },
    { lat: 48.6769322, lng: 18.8933943 },
    { lat: 48.6764455, lng: 18.8933576 },
    { lat: 48.6760049, lng: 18.8928402 },
    { lat: 48.6754414, lng: 18.8932807 },
    { lat: 48.6750378, lng: 18.8934133 },
    { lat: 48.674618, lng: 18.8937594 },
    { lat: 48.674158, lng: 18.8942009 },
    { lat: 48.6738644, lng: 18.8941251 },
    { lat: 48.6736537, lng: 18.8940168 },
    { lat: 48.673389, lng: 18.8940471 },
    { lat: 48.6731457, lng: 18.8940935 },
    { lat: 48.672929, lng: 18.8939599 },
    { lat: 48.6723376, lng: 18.8938823 },
    { lat: 48.6715359, lng: 18.8938187 },
    { lat: 48.6713313, lng: 18.8937646 },
    { lat: 48.6709151, lng: 18.8938481 },
    { lat: 48.6706463, lng: 18.8938604 },
    { lat: 48.6702904, lng: 18.893763 },
    { lat: 48.6695445, lng: 18.8937489 },
    { lat: 48.6693787, lng: 18.8937462 },
    { lat: 48.6690697, lng: 18.8936148 },
    { lat: 48.66886, lng: 18.893571 },
    { lat: 48.6680401, lng: 18.8934828 },
    { lat: 48.6674606, lng: 18.8935266 },
    { lat: 48.6674124, lng: 18.8935322 },
    { lat: 48.6673111, lng: 18.8935366 },
    { lat: 48.6670136, lng: 18.8934727 },
    { lat: 48.6660531, lng: 18.8931692 },
    { lat: 48.66584, lng: 18.8931054 },
    { lat: 48.6654853, lng: 18.892679 },
    { lat: 48.6650668, lng: 18.8924356 },
    { lat: 48.664782, lng: 18.8923778 },
    { lat: 48.6644613, lng: 18.8924315 },
    { lat: 48.6640283, lng: 18.8922836 },
    { lat: 48.6637027, lng: 18.8918239 },
    { lat: 48.6634989, lng: 18.8912204 },
    { lat: 48.663406, lng: 18.8908389 },
    { lat: 48.6633861, lng: 18.890563 },
    { lat: 48.6628564, lng: 18.8904997 },
    { lat: 48.6626892, lng: 18.8903873 },
    { lat: 48.6625421, lng: 18.8901382 },
    { lat: 48.6623616, lng: 18.8912291 },
    { lat: 48.6621274, lng: 18.8919978 },
    { lat: 48.6620259, lng: 18.8926799 },
    { lat: 48.6621118, lng: 18.8930122 },
    { lat: 48.6627481, lng: 18.8932361 },
    { lat: 48.6627709, lng: 18.8933083 },
    { lat: 48.6628197, lng: 18.8938152 },
    { lat: 48.6627768, lng: 18.8946467 },
    { lat: 48.6624938, lng: 18.8962233 },
    { lat: 48.6624904, lng: 18.8966613 },
    { lat: 48.6625901, lng: 18.8970869 },
    { lat: 48.6628541, lng: 18.8979249 },
    { lat: 48.662979, lng: 18.8985294 },
    { lat: 48.6630468, lng: 18.8990391 },
    { lat: 48.6629594, lng: 18.8994891 },
    { lat: 48.6627685, lng: 18.9000084 },
    { lat: 48.6623121, lng: 18.9004913 },
    { lat: 48.6621468, lng: 18.9009173 },
    { lat: 48.6620608, lng: 18.9014164 },
    { lat: 48.6620514, lng: 18.9014732 },
    { lat: 48.662005, lng: 18.9026564 },
    { lat: 48.6620019, lng: 18.902706 },
    { lat: 48.663754, lng: 18.9012975 },
    { lat: 48.6656034, lng: 18.8998104 },
    { lat: 48.6663795, lng: 18.9002363 },
    { lat: 48.6664396, lng: 18.90048 },
    { lat: 48.6664533, lng: 18.9005319 },
    { lat: 48.6663941, lng: 18.9011121 },
    { lat: 48.6663643, lng: 18.901411 },
    { lat: 48.6655193, lng: 18.9031092 },
    { lat: 48.6653187, lng: 18.9037929 },
    { lat: 48.6653521, lng: 18.9040248 },
    { lat: 48.6656508, lng: 18.9047881 },
    { lat: 48.6657781, lng: 18.9052926 },
    { lat: 48.665794, lng: 18.9053589 },
    { lat: 48.6658495, lng: 18.9055846 },
    { lat: 48.6665302, lng: 18.9063003 },
    { lat: 48.6668147, lng: 18.906527 },
    { lat: 48.6678324, lng: 18.9070464 },
    { lat: 48.6679984, lng: 18.9071603 },
    { lat: 48.6682384, lng: 18.9071761 },
    { lat: 48.6684346, lng: 18.9073989 },
    { lat: 48.6685536, lng: 18.9089696 },
    { lat: 48.6685734, lng: 18.909223 },
    { lat: 48.668597, lng: 18.9092406 },
    { lat: 48.668746, lng: 18.9093541 },
    { lat: 48.6687768, lng: 18.9093819 },
    { lat: 48.6688142, lng: 18.909411 },
    { lat: 48.6688354, lng: 18.9094278 },
    { lat: 48.6689946, lng: 18.9094092 },
    { lat: 48.6691929, lng: 18.9094063 },
    { lat: 48.6692459, lng: 18.9093847 },
    { lat: 48.6692835, lng: 18.9093935 },
    { lat: 48.6693178, lng: 18.9093802 },
    { lat: 48.6693434, lng: 18.9093508 },
    { lat: 48.6693708, lng: 18.9093127 },
    { lat: 48.6694056, lng: 18.9092679 },
    { lat: 48.6694557, lng: 18.9092542 },
    { lat: 48.6694933, lng: 18.9092605 },
    { lat: 48.6695272, lng: 18.9092398 },
    { lat: 48.6695513, lng: 18.9092259 },
    { lat: 48.6695905, lng: 18.9092206 },
    { lat: 48.6696606, lng: 18.9092459 },
    { lat: 48.6696887, lng: 18.9092473 },
    { lat: 48.6697097, lng: 18.9092594 },
    { lat: 48.6697434, lng: 18.9092583 },
    { lat: 48.6697723, lng: 18.9092735 },
    { lat: 48.6698094, lng: 18.909292 },
    { lat: 48.6698406, lng: 18.9092819 },
    { lat: 48.6698587, lng: 18.9092848 },
    { lat: 48.6698689, lng: 18.9092865 },
    { lat: 48.6699291, lng: 18.9093179 },
    { lat: 48.6699489, lng: 18.9093306 },
    { lat: 48.6700016, lng: 18.9093683 },
    { lat: 48.6700087, lng: 18.9093732 },
    { lat: 48.6700546, lng: 18.9093864 },
    { lat: 48.6700863, lng: 18.9094165 },
    { lat: 48.6702097, lng: 18.9095312 },
    { lat: 48.6702827, lng: 18.9097706 },
    { lat: 48.6703033, lng: 18.9098406 },
    { lat: 48.670317, lng: 18.9098858 },
    { lat: 48.6703224, lng: 18.9099051 },
    { lat: 48.6703303, lng: 18.9099299 },
    { lat: 48.670336, lng: 18.9099478 },
    { lat: 48.6703545, lng: 18.9100059 },
    { lat: 48.6703988, lng: 18.9101398 },
    { lat: 48.6704214, lng: 18.9102085 },
    { lat: 48.6704254, lng: 18.9102216 },
    { lat: 48.670382, lng: 18.9103833 },
    { lat: 48.67033, lng: 18.9105813 },
    { lat: 48.6703053, lng: 18.9106944 },
    { lat: 48.6702908, lng: 18.9107614 },
    { lat: 48.6703615, lng: 18.9110385 },
    { lat: 48.6704232, lng: 18.9112735 },
    { lat: 48.670498, lng: 18.9115655 },
    { lat: 48.6705534, lng: 18.9119236 },
    { lat: 48.6705221, lng: 18.9122337 },
    { lat: 48.6703227, lng: 18.9123495 },
    { lat: 48.6702796, lng: 18.9128022 },
    { lat: 48.6697312, lng: 18.9134978 },
    { lat: 48.6691257, lng: 18.9145529 },
    { lat: 48.6691591, lng: 18.914854 },
    { lat: 48.6695849, lng: 18.9150664 },
    { lat: 48.6696891, lng: 18.915118 },
    { lat: 48.6715428, lng: 18.9164591 },
    { lat: 48.6727936, lng: 18.9167723 },
    { lat: 48.6745722, lng: 18.9170474 },
    { lat: 48.6749178, lng: 18.9171001 },
    { lat: 48.675029, lng: 18.9180393 },
    { lat: 48.6752511, lng: 18.919753 },
    { lat: 48.6753381, lng: 18.9214739 },
    { lat: 48.6748648, lng: 18.922754 },
    { lat: 48.6748328, lng: 18.9228112 },
    { lat: 48.6748306, lng: 18.9235807 },
    { lat: 48.6751501, lng: 18.923766 },
    { lat: 48.6760005, lng: 18.9233113 },
    { lat: 48.6760599, lng: 18.9232794 },
    { lat: 48.6764331, lng: 18.9240935 },
    { lat: 48.6765562, lng: 18.924362 },
    { lat: 48.6768244, lng: 18.9241591 },
    { lat: 48.6769887, lng: 18.9241335 },
    { lat: 48.6771451, lng: 18.924036 },
    { lat: 48.6773916, lng: 18.9237646 },
    { lat: 48.6778772, lng: 18.922899 },
    { lat: 48.6779321, lng: 18.9227167 },
    { lat: 48.6779702, lng: 18.9226259 },
    { lat: 48.678098, lng: 18.9224768 },
    { lat: 48.678486, lng: 18.9222149 },
    { lat: 48.6790674, lng: 18.921924 },
    { lat: 48.6790706, lng: 18.9219854 },
    { lat: 48.6792158, lng: 18.9219559 },
    { lat: 48.6792054, lng: 18.9218647 },
    { lat: 48.6794863, lng: 18.9218444 },
    { lat: 48.679609, lng: 18.9213162 },
    { lat: 48.6796077, lng: 18.9207605 },
    { lat: 48.6795516, lng: 18.9205701 },
    { lat: 48.6795888, lng: 18.9203677 },
    { lat: 48.6796497, lng: 18.9201733 },
    { lat: 48.6799037, lng: 18.9196705 },
    { lat: 48.6802349, lng: 18.9192471 },
    { lat: 48.6806036, lng: 18.9184793 },
    { lat: 48.6806871, lng: 18.9183054 },
    { lat: 48.6807081, lng: 18.9182309 },
    { lat: 48.6807407, lng: 18.9181165 },
    { lat: 48.680816, lng: 18.9181327 },
    { lat: 48.680884, lng: 18.9181871 },
    { lat: 48.6809842, lng: 18.9182696 },
    { lat: 48.6810349, lng: 18.9182912 },
    { lat: 48.6811052, lng: 18.9183211 },
    { lat: 48.6811143, lng: 18.9183247 },
    { lat: 48.6811629, lng: 18.9183451 },
    { lat: 48.6812989, lng: 18.9184501 },
    { lat: 48.6815122, lng: 18.9185401 },
    { lat: 48.6815748, lng: 18.9185727 },
    { lat: 48.6816985, lng: 18.9186357 },
    { lat: 48.6818874, lng: 18.9186646 },
    { lat: 48.6821617, lng: 18.9186979 },
    { lat: 48.6824084, lng: 18.9187807 },
    { lat: 48.6825565, lng: 18.9188312 },
    { lat: 48.6827064, lng: 18.9188747 },
    { lat: 48.682916, lng: 18.9188691 },
    { lat: 48.6831153, lng: 18.9188625 },
    { lat: 48.6832144, lng: 18.9188642 },
    { lat: 48.6833591, lng: 18.9188483 },
    { lat: 48.6838523, lng: 18.9187485 },
    { lat: 48.6840694, lng: 18.918716 },
    { lat: 48.6843322, lng: 18.9186528 },
    { lat: 48.6845507, lng: 18.9186012 },
    { lat: 48.6847731, lng: 18.9185424 },
    { lat: 48.6850081, lng: 18.9184842 },
    { lat: 48.6850455, lng: 18.918467 },
    { lat: 48.6852499, lng: 18.9184278 },
    { lat: 48.6853833, lng: 18.9184107 },
    { lat: 48.6858395, lng: 18.9182075 },
    { lat: 48.6858506, lng: 18.9182026 },
    { lat: 48.6858617, lng: 18.9181974 },
    { lat: 48.6859675, lng: 18.9181508 },
    { lat: 48.6859874, lng: 18.918142 },
    { lat: 48.6860303, lng: 18.9181228 },
    { lat: 48.6863394, lng: 18.9178829 },
    { lat: 48.6864326, lng: 18.9178116 },
    { lat: 48.6864564, lng: 18.9177924 },
    { lat: 48.6863154, lng: 18.9175902 },
    { lat: 48.686089, lng: 18.917268 },
    { lat: 48.6857172, lng: 18.9175367 },
    { lat: 48.6855867, lng: 18.9176308 },
    { lat: 48.6855764, lng: 18.9175824 },
    { lat: 48.6855673, lng: 18.9175381 },
    { lat: 48.6855587, lng: 18.9174995 },
    { lat: 48.6855538, lng: 18.9174734 },
    { lat: 48.6855451, lng: 18.917434 },
    { lat: 48.6855163, lng: 18.9172686 },
    { lat: 48.6854948, lng: 18.9171109 },
    { lat: 48.6849901, lng: 18.9171899 },
    { lat: 48.6849373, lng: 18.9169288 },
    { lat: 48.6842171, lng: 18.916997 },
    { lat: 48.6839698, lng: 18.9169938 },
    { lat: 48.6838832, lng: 18.9167756 },
    { lat: 48.6837196, lng: 18.9163587 },
    { lat: 48.6836587, lng: 18.9160932 },
    { lat: 48.6836731, lng: 18.9159138 },
    { lat: 48.6837232, lng: 18.915282 },
    { lat: 48.683762, lng: 18.9141281 },
    { lat: 48.6837802, lng: 18.9139344 },
    { lat: 48.6840581, lng: 18.9138333 },
    { lat: 48.6845714, lng: 18.9137301 },
    { lat: 48.6852881, lng: 18.9136105 },
    { lat: 48.685687, lng: 18.9136074 },
    { lat: 48.6863772, lng: 18.9137186 },
    { lat: 48.6872017, lng: 18.9133495 },
    { lat: 48.687132, lng: 18.9128045 },
    { lat: 48.6874349, lng: 18.9122702 },
    { lat: 48.6873136, lng: 18.9119924 },
    { lat: 48.6871321, lng: 18.911578 },
    { lat: 48.6869817, lng: 18.9105361 },
    { lat: 48.6869043, lng: 18.9101929 },
    { lat: 48.6868929, lng: 18.9101446 },
    { lat: 48.6868526, lng: 18.9099299 },
    { lat: 48.6868293, lng: 18.9097624 },
    { lat: 48.6867829, lng: 18.9094295 },
    { lat: 48.6867391, lng: 18.909106 },
    { lat: 48.6867303, lng: 18.9090435 },
    { lat: 48.6865205, lng: 18.9080739 },
    { lat: 48.6864658, lng: 18.9078223 },
    { lat: 48.6860407, lng: 18.9079777 },
    { lat: 48.6859735, lng: 18.9079856 },
    { lat: 48.6859293, lng: 18.9079818 },
    { lat: 48.6852513, lng: 18.9080609 },
    { lat: 48.6852454, lng: 18.9067827 },
    { lat: 48.6856155, lng: 18.9070287 },
    { lat: 48.6854886, lng: 18.9062917 },
    { lat: 48.6851956, lng: 18.9056848 },
    { lat: 48.6845671, lng: 18.9045769 },
    { lat: 48.6845616, lng: 18.9045576 },
    { lat: 48.6844369, lng: 18.9041083 },
    { lat: 48.6844178, lng: 18.9040388 },
    { lat: 48.6843951, lng: 18.9039508 },
    { lat: 48.6843618, lng: 18.9038273 },
    { lat: 48.6839832, lng: 18.9040102 },
    { lat: 48.6835898, lng: 18.9042961 },
    { lat: 48.6835666, lng: 18.9042399 },
    { lat: 48.6833589, lng: 18.9037112 },
    { lat: 48.683266, lng: 18.9033745 },
    { lat: 48.6832168, lng: 18.9032718 },
    { lat: 48.6830022, lng: 18.9028252 },
    { lat: 48.6828416, lng: 18.9025097 },
    { lat: 48.6825504, lng: 18.9018858 },
    { lat: 48.6823106, lng: 18.9012613 },
    { lat: 48.6822159, lng: 18.9013052 },
    { lat: 48.6817781, lng: 18.9013652 },
    { lat: 48.6816525, lng: 18.9006948 },
    { lat: 48.6814017, lng: 18.9004273 },
    { lat: 48.681359, lng: 18.900382 },
    { lat: 48.6813118, lng: 18.9001456 },
    { lat: 48.6812889, lng: 18.8997455 },
    { lat: 48.6813044, lng: 18.8993433 },
    { lat: 48.6812939, lng: 18.8989006 },
    { lat: 48.6812388, lng: 18.8986149 },
    { lat: 48.681219, lng: 18.898386 },
    { lat: 48.6813339, lng: 18.8982087 },
    { lat: 48.6812951, lng: 18.8980877 },
    { lat: 48.6812556, lng: 18.8980455 },
    { lat: 48.6812508, lng: 18.8980393 },
    { lat: 48.6812114, lng: 18.8979961 },
    { lat: 48.6810147, lng: 18.8980303 },
    { lat: 48.680408, lng: 18.898008 },
    { lat: 48.6804222, lng: 18.8975813 },
    { lat: 48.6804242, lng: 18.8975344 },
    { lat: 48.6803421, lng: 18.8973845 },
    { lat: 48.6803321, lng: 18.8971901 },
    { lat: 48.6800669, lng: 18.8965504 },
    { lat: 48.6801127, lng: 18.8963656 },
    { lat: 48.6802933, lng: 18.8958677 },
    { lat: 48.6804875, lng: 18.8952365 },
    { lat: 48.680571, lng: 18.8950412 },
    { lat: 48.6806342, lng: 18.8948015 },
    { lat: 48.6806507, lng: 18.8943956 },
    { lat: 48.6805131, lng: 18.8941549 },
    { lat: 48.6804815, lng: 18.8938489 },
    { lat: 48.6804101, lng: 18.8936246 },
    { lat: 48.6799441, lng: 18.8932886 },
    { lat: 48.6799496, lng: 18.8930225 },
    { lat: 48.6796638, lng: 18.8928134 },
    { lat: 48.6796057, lng: 18.8928049 },
    { lat: 48.679551, lng: 18.8927703 },
    { lat: 48.6793188, lng: 18.8926216 },
    { lat: 48.678961, lng: 18.8923939 },
    { lat: 48.6787998, lng: 18.8922651 },
    { lat: 48.6783295, lng: 18.8922815 },
    { lat: 48.6781518, lng: 18.8922913 },
    { lat: 48.6775689, lng: 18.8926848 },
    { lat: 48.6773784, lng: 18.8930375 },
  ],
};

export default UnitsŽiarNadHronom;
