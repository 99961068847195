import { AnimatePresence, motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faWindowMaximize,
  faWindowMinimize,
  faWindowRestore,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { useMediaQuery } from "usehooks-ts";
import { useState } from "react";
import clsx from "clsx";
import RealEstateOffices from "../../Subjects/RealEstateOffices";
import RealEstateBrokers from "../../Subjects/RealEstateBrokers";
import FinInstitutions from "../../Subjects/FinInstitutions";
import FinBrokers from "../../Subjects/FinBrokers";
import REBroker from "../../../Images/REBroker.png";
import REOffice from "../../../Images/REOffice.png";
import FinInst from "../../../Images/FinInstitution.png";
import FinB from "../../../Images/FinBroker.png";
import { FinInstitution } from "../../../data/FinInstitution";
import { REOffices } from "../../../data/RealEstateOffices";
import { REBrokers } from "../../../data/RealEstateBrokers";
import { FinBroker } from "../../../data/FinBroker";

export default function SubjectsMenu({
  navSmall,
  setNavSmall,
  subjects,
  setSubjects,
  map,
  setHighlight,
  highlight,
  setSubjectsMenu,
  subjectsMenu,
  indx,
  subMenu,
  setSubMenu,
  setForSale,
  forSale,
  viewSale,
  setViewSale,
  indxPrev,
}) {
  const [size, setSize] = useState({ min: false, mid: true, full: false });
  const isSmallDevice = useMediaQuery("only screen and (max-width : 640px)");

  const centralize = (array) => {
    if (array.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      array.forEach((marker) => {
        bounds.extend(new window.google.maps.LatLng(marker.lat, marker.lng));
      });
      map.fitBounds(bounds);
    }
  };

  const buttons = () => {
    return (
      <div className="w-full px-2 flex justify-center items-center flex-col gap-2 pt-1">
        <button
          onClick={() => {
            setSubjects({ ...subjects, ROffice: true, RBroker: false, FInstitution: false, FBroker: false });
            centralize(REOffices);
          }}
          className={clsx("h-10 w-full bg-blue-200 rounded-lg flex flex-row items-center justify-center gap-1", {
            // "w-full": isSmallDevice || !size.full,
            // "w-1/2": !isSmallDevice && size.full,
          })}
        >
          <img src={REOffice} alt="" className="w-6 h-6" /> Realitné kancelárie
        </button>
        <button
          onClick={() => {
            setSubjects({ ...subjects, ROffice: false, RBroker: true, FInstitution: false, FBroker: false });
            centralize(REBrokers);
          }}
          className={clsx("h-10 w-full bg-yellow-200 rounded-lg flex flex-row items-center justify-center gap-1", {
            // "w-full": isSmallDevice || !size.full,
            // "w-1/2": !isSmallDevice && size.full,
          })}
        >
          <img src={REBroker} alt="" className="w-5 h-5" /> Realitní makléri
        </button>
        <button
          onClick={() => {
            setSubjects({ ...subjects, ROffice: false, RBroker: false, FInstitution: true, FBroker: false });
            centralize(FinInstitution);
          }}
          className={clsx("h-10 w-full bg-purple-200 rounded-lg flex flex-row items-center justify-center gap-1", {
            // "w-full": isSmallDevice || !size.full,
            // "w-1/2": !isSmallDevice && size.full,
          })}
        >
          <img src={FinInst} alt="" className="w-5 h-5" />
          Finančné inštitúcie
        </button>
        <button
          onClick={() => {
            setSubjects({ ...subjects, ROffice: false, RBroker: false, FInstitution: false, FBroker: true });
            centralize(FinBroker);
          }}
          className={clsx("h-10 w-full bg-cyan-200 rounded-lg flex flex-row items-center justify-center gap-1", {
            // "w-full": isSmallDevice || !size.full,
            // "w-1/2": !isSmallDevice && size.full,
          })}
        >
          <img src={FinB} alt="" className="w-5 h-5" />
          Finanční makléri
        </button>
      </div>
    );
  };

  const changeSize = () => {
    if (size.mid) {
      setSize({ ...size, mid: false, full: true, min: false });
    } else {
      setSize({ ...size, mid: true, full: false, min: false });
    }
  };

  const minimize = () => {
    if (size.mid) {
      setSize({ ...size, min: true, mid: false, full: true });
    }
    if (size.full) {
      setSize({ ...size, min: true, mid: true, full: false });
    }
  };

  const goBack = () => {
    if (subjectsMenu.REB) {
      if (indxPrev.current !== -2) {
        indx.current = indxPrev.current;
        indxPrev.current = -2;
        if (subjects.RBroker) {
          if (subjectsMenu.menu === "ROffice") {
            setSubjects({ ...subjects, ROffice: true, RBroker: false, FInstitution: false, FBroker: false });
            setSubjectsMenu({ ...subjectsMenu, REO: true, REB: false });
          }
          if (subjectsMenu.menu === "FInstitution") {
            setSubjects({ ...subjects, ROffice: false, RBroker: false, FInstitution: true, FBroker: false });
            setSubjectsMenu({ ...subjectsMenu, REO: false, REB: false, FI: true });
          }
        }
        if (subjects.FBroker) {
          console.log("subjectsMenu.REB subjects.FBroker");
          setSubjects({ ...subjects, ROffice: false, RBroker: false, FInstitution: true, FBroker: false });
          setSubjectsMenu({ ...subjectsMenu, REO: false, FIB: false, FI: true });
        }
      } else {
        setSubjectsMenu({ ...subjectsMenu, REB: false });
      }
      return;
    }
    if (subjectsMenu.FIB) {
      if (indxPrev.current !== -2) {
        indx.current = indxPrev.current;
        indxPrev.current = -2;
        if (subjects.RBroker) {
          console.log("subjectsMenu.FIB subjects.RBroker");
          setSubjects({ ...subjects, ROffice: false, RBroker: false, FInstitution: true, FBroker: false });
          setSubjectsMenu({ ...subjectsMenu, REO: false, FIB: false, FI: true });
        }
        if (subjects.FBroker) {
          if (subjectsMenu.menu === "ROffice") {
            setSubjects({ ...subjects, ROffice: true, RBroker: false, FInstitution: false, FBroker: false });
            setSubjectsMenu({ ...subjectsMenu, REO: true, FIB: false, FI: false });
          }
          if (subjectsMenu.menu === "FInstitution") {
            setSubjects({ ...subjects, ROffice: false, RBroker: false, FInstitution: true, FBroker: false });
            setSubjectsMenu({ ...subjectsMenu, REO: false, FIB: false, FI: true });
          }
        }
      } else {
        setSubjectsMenu({ ...subjectsMenu, FIB: false });
      }
      return;
    }
    if (subjectsMenu.REO) {
      if (indxPrev.current !== -2) {
        indx.current = indxPrev.current;
        indxPrev.current = -2;
        if (subjects.FInstitution) {
          console.log("subjectsMenu.REO subjects.FInstitution");
          setSubjects({ ...subjects, ROffice: false, RBroker: false, FInstitution: true, FBroker: false });
          setSubjectsMenu({ ...subjectsMenu, REO: false, FIB: false, REB: false, FI: true });
        }
        if (subjects.ROffice) {
          if (subjectsMenu.menu === "RBroker") {
            setSubjects({ ...subjects, ROffice: false, RBroker: true, FInstitution: false, FBroker: false });
            setSubjectsMenu({ ...subjectsMenu, REO: false, FIB: false, REB: true, FI: true });
          }
          if (subjectsMenu.menu === "FInstitution") {
            setSubjects({ ...subjects, ROffice: false, RBroker: false, FInstitution: true, FBroker: false });
            setSubjectsMenu({ ...subjectsMenu, REO: false, FIB: false, REB: false, FI: true });
          }
        }
      } else {
        setSubjectsMenu({ ...subjectsMenu, REO: false });
      }
      return;
    }
    if (subjectsMenu.FI) {
      if (indxPrev.current !== -2) {
        indx.current = indxPrev.current;
        indxPrev.current = -2;
        if (subjects.FInstitution) {
          if (subjectsMenu.menu === "ROffice") {
            setSubjects({ ...subjects, ROffice: true, RBroker: false, FInstitution: false, FBroker: false });
            setSubjectsMenu({ ...subjectsMenu, REO: true, FI: false });
          }
          if (subjectsMenu.menu === "FBroker") {
            setSubjects({ ...subjects, ROffice: false, RBroker: false, FInstitution: false, FBroker: true });
            setSubjectsMenu({ ...subjectsMenu, REO: false, FI: false, FIB: true });
          }
        }
        if (subjects.ROffice) {
          console.log("subjectsMenu.FI subjects.ROffice");
          setSubjects({ ...subjects, ROffice: false, RBroker: false, FInstitution: true, FBroker: false });
          setSubjectsMenu({ ...subjectsMenu, REO: false, FIB: false, REB: false, FI: true });
        }
      } else {
        setSubjectsMenu({ ...subjectsMenu, FI: false });
      }
      return;
    }
    if (subjects.ROffice || subjects.RBroker || subjects.FInstitution || subjects.FBroker) {
      setSubjects({ ...subjects, ROffice: false, RBroker: false, FInstitution: false, FBroker: false });
    }
  };

  const getMenu = () => {
    if (subjects.ROffice) {
      return (
        <RealEstateOffices
          size={size}
          setHighlight={setHighlight}
          highlight={highlight}
          map={map}
          setSubjectsMenu={setSubjectsMenu}
          subjectsMenu={subjectsMenu}
          indx={indx}
          subMenu={subMenu}
          setSubMenu={setSubMenu}
          setForSale={setForSale}
          forSale={forSale}
          navSmall={navSmall}
          setNavSmall={setNavSmall}
          viewSale={viewSale}
          setViewSale={setViewSale}
          indxPrev={indxPrev}
          subjects={subjects}
          setSubjects={setSubjects}
        />
      );
    }
    if (subjects.RBroker) {
      return (
        <RealEstateBrokers
          size={size}
          setSubjectsMenu={setSubjectsMenu}
          subjectsMenu={subjectsMenu}
          indx={indx}
          subMenu={subMenu}
          setSubMenu={setSubMenu}
          setForSale={setForSale}
          forSale={forSale}
          navSmall={navSmall}
          setNavSmall={setNavSmall}
          viewSale={viewSale}
          setViewSale={setViewSale}
          indxPrev={indxPrev}
          subjects={subjects}
          setSubjects={setSubjects}
        />
      );
    }
    if (subjects.FInstitution) {
      return (
        <FinInstitutions
          size={size}
          setHighlight={setHighlight}
          highlight={highlight}
          map={map}
          setSubjectsMenu={setSubjectsMenu}
          subjectsMenu={subjectsMenu}
          indx={indx}
          subMenu={subMenu}
          setSubMenu={setSubMenu}
          setForSale={setForSale}
          forSale={forSale}
          navSmall={navSmall}
          setNavSmall={setNavSmall}
          viewSale={viewSale}
          setViewSale={setViewSale}
          indxPrev={indxPrev}
          subjects={subjects}
          setSubjects={setSubjects}
        />
      );
    }
    if (subjects.FBroker) {
      return (
        <FinBrokers
          size={size}
          setSubjectsMenu={setSubjectsMenu}
          subjectsMenu={subjectsMenu}
          indx={indx}
          subMenu={subMenu}
          setSubMenu={setSubMenu}
          setForSale={setForSale}
          forSale={forSale}
          navSmall={navSmall}
          setNavSmall={setNavSmall}
          viewSale={viewSale}
          setViewSale={setViewSale}
          indxPrev={indxPrev}
          subjects={subjects}
          setSubjects={setSubjects}
        />
      );
    }
    return buttons();
  };

  const title = () => {
    if (subjects.ROffice) {
      return "Realitné kancelárie";
    }
    if (subjects.RBroker) {
      return "Realitný makléri";
    }
    if (subjects.FInstitution) {
      return "Finančné insštitúcie";
    }
    if (subjects.FBroker) {
      return "Finanční makléri";
    }

    return "Subjekty";
  };

  const close = () => {
    setNavSmall({ ...navSmall, subjects: false });
    setSubjects({ ...subjects, ROffice: false, RBroker: false, FInstitution: false, FBroker: false });
  };

  return (
    <AnimatePresence>
      {navSmall.subjects ? ( //&& isSmallDevice
        <motion.div
          className={clsx(
            "transition-all duration-200 ease-linear overflow-scroll absolute h-auto bg-sky-100 flex flex-col items-center no-scrollbar select-none",
            {
              "!h-[calc(100%-64px)] !rounded-none": size.full && !size.min && isSmallDevice,
              "!h-[40%]": size.mid && !size.min && isSmallDevice,
              "!h-[10%]": size.min && isSmallDevice,
              "bottom-0 left-0 w-full pb-5 rounded-t-xl": isSmallDevice,
              "top-0 left-16 h-full": !isSmallDevice,
              "w-96": !isSmallDevice && size.mid,
              "w-[calc(100%-64px)]": !isSmallDevice && size.full,
            },
          )}
          initial={{ opacity: 0, scale: 1, y: isSmallDevice ? 300 : 0, x: !isSmallDevice ? -500 : 0 }}
          animate={{ opacity: 1, scale: 1, y: isSmallDevice ? 0 : 0, x: !isSmallDevice ? 0 : 0 }}
          exit={{ opacity: 1, scale: 1, y: isSmallDevice ? 350 : 0, x: !isSmallDevice ? -500 : 0 }}
          transition={{
            duration: 0.3,
            ease: [0, 0.71, 0.2, 1.01],
          }}
        >
          <div
            className={clsx(
              "sticky top-0 right-0 rounded-t-lg w-full h-8 bg-sky-100 border-b border-opacity-50 border-b-sky-500 z-10 grid",
              {
                "grid-cols-[10%_10%_50%_10%_10%_10%]": isSmallDevice,
                "grid-cols-[10%_10%_60%_10%_10%]": !isSmallDevice,
              },
            )}
          >
            <button
              disabled={
                subjects.ROffice || subjects.RBroker || subjects.FInstitution || subjects.FBroker ? false : true
              }
              onClick={() => goBack()}
              className="w-full h-full disabled:text-gray-300"
            >
              <FontAwesomeIcon icon={faChevronLeft} className="w-5 h-4" />
            </button>
            <button
              disabled={true}
              className="w-full h-full border-x border-opacity-50 border-x-sky-500 disabled:text-gray-300"
            >
              <FontAwesomeIcon icon={faChevronRight} className="w-5 h-4" />
            </button>
            <div className="w-full h-full flex justify-start items-center px-1">{title()}</div>
            {isSmallDevice ? (
              <button onClick={() => minimize()} className="w-full h-full border-l border-opacity-50 border-l-sky-500">
                <FontAwesomeIcon icon={faWindowMinimize} />
              </button>
            ) : null}
            <button onClick={() => changeSize()} className="w-full h-full border-x border-opacity-50 border-x-sky-500">
              {size.full ? <FontAwesomeIcon icon={faWindowRestore} /> : null}
              {size.mid ? <FontAwesomeIcon icon={faWindowMaximize} /> : null}
            </button>
            <button onClick={() => close()} className="w-full h-full">
              <FontAwesomeIcon icon={faX} />
            </button>
          </div>
          <div className={clsx("h-full w-full", { "pt-6": isSmallDevice, "pt-2": !isSmallDevice })}>{getMenu()}</div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
}
