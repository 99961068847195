import { useState } from "react";
import { Polygon } from "@react-google-maps/api";
import UnitsMichalovce from "../constants/Units/RegionKosice/UnitsMichalovce";
import UnitsGelnica from "../constants/Units/RegionKosice/UnitsGelnica";
import UnitsKošiceI from "../constants/Units/RegionKosice/UnitsKosiceI";
import UnitsKošiceII from "../constants/Units/RegionKosice/UnitsKosiceII";
import UnitsKošiceIII from "../constants/Units/RegionKosice/UnitsKosiceIII";
import UnitsKošiceIV from "../constants/Units/RegionKosice/UnitsKosiceIV";
import UnitsKošiceOkolie from "../constants/Units/RegionKosice/UnitsKosiceOkolie";
import UnitsRožňava from "../constants/Units/RegionKosice/UnitsRoznava";
import UnitsSobrance from "../constants/Units/RegionKosice/UnitsSobrance";
import UnitsSpišskáNováVes from "../constants/Units/RegionKosice/UnitsSpisskaNovaVes";
import UnitsTrebišov from "../constants/Units/RegionKosice/UnitsTrebisov";
import { averagePriceService } from "../../services/averagePriceService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTags } from "@fortawesome/free-solid-svg-icons";

export default function UnitsofKosiceRegion({
  priceMap,
  genAvgPrice,
  setPMResult,
  PMResult,
  map,
  PMHistory,
  setPMHistory,
}) {
  // console.log("genAvgPrice = ", Object.keys(genAvgPrice).length);
  const [showTooltip, setShowTooltip] = useState({ active: false, name: "", x: 0, y: 0 });

  const defaultColor = "#7551b0";
  const green3 = "#007500";
  const green1 = "#00A300";
  const green2 = "#2EFF2E";
  const yellow1 = "#D1D100";
  const yellow2 = "#FFFF2E";
  const orange1 = "#FFA500";
  // const orange2 = "#FFB52E";
  const red1 = "#FF0000";
  const red2 = "#D10000";

  const setColor = (keyy) => {
    // console.log("units key = ", keyy);
    // console.log("genAvgPrice = ", genAvgPrice);
    const key = keyy.replace("Košicemestskáčasť", "");
    if (Object.keys(genAvgPrice).length > 0) {
      if (genAvgPrice.hasOwnProperty(key)) {
        if (genAvgPrice[key]?.avgBytM2 >= 700 && genAvgPrice[key]?.avgBytM2 <= 1000) {
          return green1;
        } else if (genAvgPrice[key]?.avgBytM2 > 1000 && genAvgPrice[key]?.avgBytM2 <= 1300) {
          return green2;
        } else if (genAvgPrice[key]?.avgBytM2 > 1300 && genAvgPrice[key]?.avgBytM2 <= 1600) {
          return yellow1;
        } else if (genAvgPrice[key]?.avgBytM2 > 1600 && genAvgPrice[key]?.avgBytM2 <= 1800) {
          return yellow2;
        } else if (genAvgPrice[key]?.avgBytM2 > 1800 && genAvgPrice[key]?.avgBytM2 <= 2000) {
          return orange1;
        } else if (genAvgPrice[key]?.avgBytM2 > 2000 && genAvgPrice[key]?.avgBytM2 <= 2100) {
          return red1;
        } else if (genAvgPrice[key]?.avgBytM2 > 2100) {
          return red2;
        }
        return green3;
      } else return defaultColor;
    } else return defaultColor;
  };

  const unitAvgPrice = async (parent, key) => {
    const request = await averagePriceService.getUnitAvgPrice(decodeURIComponent(parent), decodeURIComponent(key));
    const response = await request.json();
    setPMResult({ ...PMResult, active: true, data: response });
  };

  const convertLatLngToPoint = (latLng, map) => {
    const scale = Math.pow(2, map.getZoom());
    const nw = new window.google.maps.LatLng(
      map.getBounds().getNorthEast().lat(),
      map.getBounds().getSouthWest().lng(),
    );
    const worldCoordinateNW = map.getProjection().fromLatLngToPoint(nw);
    const worldCoordinate = map.getProjection().fromLatLngToPoint(latLng);
    const pixelOffset = new window.google.maps.Point(
      Math.floor((worldCoordinate.x - worldCoordinateNW.x) * scale),
      Math.floor((worldCoordinate.y - worldCoordinateNW.y) * scale),
    );
    return pixelOffset;
  };
  // console.log("genAvgPrice = ", genAvgPrice);

  return priceMap.city ? (
    <>
      {Object.keys(UnitsMichalovce).map((key) => {
        if (priceMap.cityName === "DistrictMichalovce") {
          return (
            <Polygon
              key={key}
              paths={UnitsMichalovce[key]}
              options={{
                // strokeColor: "#2d3740",
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                // fillColor: "#16f204",
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("Michalovce", key);
                setPMHistory({ ...PMHistory, city: "Michalovce " + key });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {Object.keys(UnitsGelnica).map((key) => {
        if (priceMap.cityName === "DistrictGelnica") {
          return (
            <Polygon
              key={key}
              paths={UnitsGelnica[key]}
              options={{
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("Gelnica", key);
                setPMHistory({ ...PMHistory, city: "Gelnica " + key });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {Object.keys(UnitsKošiceI).map((key) => {
        if (priceMap.cityName === "DistrictKošiceI") {
          return (
            <Polygon
              key={key}
              paths={UnitsKošiceI[key]}
              options={{
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("KošiceI", key.replace("Košicemestskáčasť", ""));
                setPMHistory({ ...PMHistory, city: "KošiceI " + key.replace("Košicemestskáčasť", "") });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
                // console.log("key = ", key);
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {Object.keys(UnitsKošiceII).map((key) => {
        if (priceMap.cityName === "DistrictKošiceII") {
          return (
            <Polygon
              key={key}
              paths={UnitsKošiceII[key]}
              options={{
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("KošiceII", key.replace("Košicemestskáčasť", ""));
                setPMHistory({ ...PMHistory, city: "KošiceII " + key.replace("Košicemestskáčasť", "") });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {Object.keys(UnitsKošiceIII).map((key) => {
        if (priceMap.cityName === "DistrictKošiceIII") {
          return (
            <Polygon
              key={key}
              paths={UnitsKošiceIII[key]}
              options={{
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("KošiceIII", key.replace("Košicemestskáčasť", ""));
                setPMHistory({ ...PMHistory, city: "KošiceIII " + key.replace("Košicemestskáčasť", "") });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {Object.keys(UnitsKošiceIV).map((key) => {
        if (priceMap.cityName === "DistrictKošiceIV") {
          return (
            <Polygon
              key={key}
              paths={UnitsKošiceIV[key]}
              options={{
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("KošiceIV", key.replace("Košicemestskáčasť", ""));
                setPMHistory({ ...PMHistory, city: "KošiceIV " + key.replace("Košicemestskáčasť", "") });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {Object.keys(UnitsKošiceOkolie).map((key) => {
        if (priceMap.cityName === "DistrictKošiceokolieOuter") {
          return (
            <Polygon
              key={key}
              paths={UnitsKošiceOkolie[key]}
              options={{
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("Košiceokolie", key);
                setPMHistory({ ...PMHistory, city: "KošiceIokolie " + key });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {Object.keys(UnitsRožňava).map((key) => {
        if (priceMap.cityName === "DistrictRožňava") {
          return (
            <Polygon
              key={key}
              paths={UnitsRožňava[key]}
              options={{
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("Rožňava", key);
                setPMHistory({ ...PMHistory, city: "Rožňava " + key });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {Object.keys(UnitsSobrance).map((key) => {
        if (priceMap.cityName === "DistrictSobrance") {
          return (
            <Polygon
              key={key}
              paths={UnitsSobrance[key]}
              options={{
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("Sobrance", key);
                setPMHistory({ ...PMHistory, city: "Sobrance " + key });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {Object.keys(UnitsSpišskáNováVes).map((key) => {
        if (priceMap.cityName === "DistrictSpišskáNováVes") {
          return (
            <Polygon
              key={key}
              paths={UnitsSpišskáNováVes[key]}
              options={{
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("SpišskáNováVes", key);
                setPMHistory({ ...PMHistory, city: "SpišskáNováVes " + key });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {Object.keys(UnitsTrebišov).map((key) => {
        if (priceMap.cityName === "DistrictTrebišov") {
          return (
            <Polygon
              key={key}
              paths={UnitsTrebišov[key]}
              options={{
                strokeColor: setColor(key.replace("Ext", "").replace("1", "")),
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: setColor(key.replace("Ext", "").replace("1", "")),
                fillOpacity: 0.35,
              }}
              onClick={() => {
                unitAvgPrice("Trebišov", key);
                setPMHistory({ ...PMHistory, city: "Trebišov " + key });
              }}
              onMouseOver={(e) => {
                const latLng = e.latLng;
                const point = convertLatLngToPoint(latLng, map);
                setShowTooltip({ ...showTooltip, active: true, name: key, x: point.x, y: point.y });
              }}
              onMouseOut={() => setShowTooltip({ ...showTooltip, active: false, name: "", x: 0, y: 0 })}
            />
          );
        } else return null;
      })}
      {showTooltip.active ? (
        <div
          className="absolute mb-2 h-auto w-auto p-2 bg-sky-200 text-start text-sm rounded-lg opacity-100 transition-opacity duration-300"
          style={{ top: showTooltip.y + 10, left: showTooltip.x + 10 }}
        >
          <div className="flex flex-col">
            <div className="flex flex-row gap-1 justify-between items-center">
              Trhová cena pre
              <FontAwesomeIcon icon={faTags} />
            </div>
            <div>
              {genAvgPrice[showTooltip.name.replace("Košicemestskáčasť", "").replace("Ext", "")]?.obec
                .replace("nad", " nad")
                .replace("pod", " pod")
                .replace("pri", " pri")
                .replace(/(?<!^)(?<=\p{L})(?=[A-Z])/gu, " ")
                .replace("Ext", "")
                .replace("1", "")}
            </div>
            <div>
              za dom: {genAvgPrice[showTooltip.name.replace("Košicemestskáčasť", "").replace("Ext", "")]?.avgDom} €
            </div>
            <div>
              za m² bytu: {genAvgPrice[showTooltip.name.replace("Košicemestskáčasť", "").replace("Ext", "")]?.avgBytM2}{" "}
              €
            </div>
            <div>
              za m² pozemku:{" "}
              {genAvgPrice[showTooltip.name.replace("Košicemestskáčasť", "").replace("Ext", "")]?.avgPozemokM2} €
            </div>
          </div>
        </div>
      ) : null}
      ) : null ;
    </>
  ) : null;
}
