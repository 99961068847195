const UnitsGalanta = {
  VinohradynadVáhom: [
    { lat: 48.31706, lng: 17.749781 },
    { lat: 48.3130814, lng: 17.748963 },
    { lat: 48.3072049, lng: 17.7496568 },
    { lat: 48.3051848, lng: 17.7500168 },
    { lat: 48.3049125, lng: 17.7500859 },
    { lat: 48.3049134, lng: 17.7502304 },
    { lat: 48.305164, lng: 17.7504647 },
    { lat: 48.3052682, lng: 17.7506906 },
    { lat: 48.3054583, lng: 17.7508798 },
    { lat: 48.3057504, lng: 17.7509093 },
    { lat: 48.3054802, lng: 17.7511948 },
    { lat: 48.3055726, lng: 17.7514451 },
    { lat: 48.305514, lng: 17.7521618 },
    { lat: 48.3052304, lng: 17.7525403 },
    { lat: 48.3051671, lng: 17.7527033 },
    { lat: 48.3051055, lng: 17.753457 },
    { lat: 48.3049629, lng: 17.7538719 },
    { lat: 48.3048426, lng: 17.7540457 },
    { lat: 48.30476, lng: 17.7543498 },
    { lat: 48.3047722, lng: 17.7544555 },
    { lat: 48.3045265, lng: 17.7549734 },
    { lat: 48.3041366, lng: 17.7551677 },
    { lat: 48.3038191, lng: 17.7555647 },
    { lat: 48.3037079, lng: 17.7562156 },
    { lat: 48.3035255, lng: 17.7566779 },
    { lat: 48.3031432, lng: 17.7572321 },
    { lat: 48.3022311, lng: 17.7581834 },
    { lat: 48.300715, lng: 17.7588782 },
    { lat: 48.3006803, lng: 17.75881 },
    { lat: 48.299904, lng: 17.7596044 },
    { lat: 48.2995452, lng: 17.7594405 },
    { lat: 48.2984253, lng: 17.759989 },
    { lat: 48.2968086, lng: 17.7605183 },
    { lat: 48.2965581, lng: 17.7606694 },
    { lat: 48.2953203, lng: 17.7611013 },
    { lat: 48.2950573, lng: 17.7611028 },
    { lat: 48.2949827, lng: 17.7609908 },
    { lat: 48.2946371, lng: 17.7610981 },
    { lat: 48.2946377, lng: 17.761199 },
    { lat: 48.2945364, lng: 17.7613858 },
    { lat: 48.293638, lng: 17.7629259 },
    { lat: 48.2930179, lng: 17.7643597 },
    { lat: 48.2927349, lng: 17.7646954 },
    { lat: 48.2931754, lng: 17.7652851 },
    { lat: 48.2939263, lng: 17.7666738 },
    { lat: 48.2950487, lng: 17.7693082 },
    { lat: 48.297479, lng: 17.7740325 },
    { lat: 48.298035, lng: 17.7739338 },
    { lat: 48.2995049, lng: 17.7732561 },
    { lat: 48.3015124, lng: 17.7791839 },
    { lat: 48.3027163, lng: 17.7840228 },
    { lat: 48.3028739, lng: 17.7839041 },
    { lat: 48.3049291, lng: 17.7831988 },
    { lat: 48.3051213, lng: 17.7823421 },
    { lat: 48.3052102, lng: 17.7821716 },
    { lat: 48.305887, lng: 17.781815 },
    { lat: 48.3091967, lng: 17.7880171 },
    { lat: 48.3092012, lng: 17.7880519 },
    { lat: 48.309273, lng: 17.7885218 },
    { lat: 48.3094832, lng: 17.7891839 },
    { lat: 48.309365, lng: 17.7906563 },
    { lat: 48.3094052, lng: 17.7908522 },
    { lat: 48.3096186, lng: 17.7912177 },
    { lat: 48.310149, lng: 17.7916167 },
    { lat: 48.3103893, lng: 17.7919803 },
    { lat: 48.3104522, lng: 17.7921227 },
    { lat: 48.3105845, lng: 17.7929526 },
    { lat: 48.3107441, lng: 17.7933437 },
    { lat: 48.3113068, lng: 17.7935145 },
    { lat: 48.311427, lng: 17.7934684 },
    { lat: 48.3120047, lng: 17.7926189 },
    { lat: 48.3123433, lng: 17.7931699 },
    { lat: 48.3128504, lng: 17.7937977 },
    { lat: 48.3130902, lng: 17.7943926 },
    { lat: 48.3131555, lng: 17.7946812 },
    { lat: 48.3136655, lng: 17.7953721 },
    { lat: 48.3140478, lng: 17.7955486 },
    { lat: 48.3163389, lng: 17.7958697 },
    { lat: 48.3170684, lng: 17.7963877 },
    { lat: 48.3171007, lng: 17.7963237 },
    { lat: 48.3179547, lng: 17.795178 },
    { lat: 48.3185091, lng: 17.7933998 },
    { lat: 48.3188268, lng: 17.7926738 },
    { lat: 48.3199788, lng: 17.7907542 },
    { lat: 48.3209548, lng: 17.789588 },
    { lat: 48.3217208, lng: 17.7889391 },
    { lat: 48.322196, lng: 17.78861 },
    { lat: 48.32222, lng: 17.788586 },
    { lat: 48.32321, lng: 17.787574 },
    { lat: 48.323344, lng: 17.787443 },
    { lat: 48.323771, lng: 17.786938 },
    { lat: 48.323868, lng: 17.786774 },
    { lat: 48.32426, lng: 17.785902 },
    { lat: 48.324454, lng: 17.785528 },
    { lat: 48.324506, lng: 17.785444 },
    { lat: 48.324545, lng: 17.785381 },
    { lat: 48.324581, lng: 17.785324 },
    { lat: 48.32489, lng: 17.784821 },
    { lat: 48.325223, lng: 17.784324 },
    { lat: 48.325412, lng: 17.78406 },
    { lat: 48.325878, lng: 17.783391 },
    { lat: 48.326003, lng: 17.783203 },
    { lat: 48.32669, lng: 17.782368 },
    { lat: 48.327081, lng: 17.782008 },
    { lat: 48.327105, lng: 17.781984 },
    { lat: 48.327557, lng: 17.781581 },
    { lat: 48.327739, lng: 17.781366 },
    { lat: 48.327871, lng: 17.781257 },
    { lat: 48.32795, lng: 17.78121 },
    { lat: 48.328043, lng: 17.781166 },
    { lat: 48.328467, lng: 17.781083 },
    { lat: 48.328545, lng: 17.781093 },
    { lat: 48.328574, lng: 17.780997 },
    { lat: 48.328599, lng: 17.780784 },
    { lat: 48.328598, lng: 17.78063 },
    { lat: 48.328653, lng: 17.780256 },
    { lat: 48.328673, lng: 17.780002 },
    { lat: 48.328695, lng: 17.779654 },
    { lat: 48.32879, lng: 17.779372 },
    { lat: 48.329015, lng: 17.778903 },
    { lat: 48.329204, lng: 17.778491 },
    { lat: 48.329358, lng: 17.777808 },
    { lat: 48.329371, lng: 17.777282 },
    { lat: 48.329346, lng: 17.77701 },
    { lat: 48.329349, lng: 17.776983 },
    { lat: 48.329356, lng: 17.776906 },
    { lat: 48.329353, lng: 17.776879 },
    { lat: 48.329341, lng: 17.77677 },
    { lat: 48.329349, lng: 17.776721 },
    { lat: 48.329427, lng: 17.776277 },
    { lat: 48.32943, lng: 17.776256 },
    { lat: 48.329497, lng: 17.776001 },
    { lat: 48.329527, lng: 17.775895 },
    { lat: 48.329873, lng: 17.774691 },
    { lat: 48.330035, lng: 17.774112 },
    { lat: 48.33006, lng: 17.774051 },
    { lat: 48.330129, lng: 17.774009 },
    { lat: 48.330185, lng: 17.774007 },
    { lat: 48.330585, lng: 17.774068 },
    { lat: 48.330837, lng: 17.773948 },
    { lat: 48.331029, lng: 17.773858 },
    { lat: 48.331235, lng: 17.773666 },
    { lat: 48.331283, lng: 17.77362 },
    { lat: 48.331292, lng: 17.77364 },
    { lat: 48.33148, lng: 17.773683 },
    { lat: 48.331572, lng: 17.773653 },
    { lat: 48.331678, lng: 17.773607 },
    { lat: 48.331746, lng: 17.773595 },
    { lat: 48.331913, lng: 17.77349 },
    { lat: 48.332139, lng: 17.773292 },
    { lat: 48.332268, lng: 17.773198 },
    { lat: 48.332516, lng: 17.773059 },
    { lat: 48.332792, lng: 17.772814 },
    { lat: 48.332882, lng: 17.772754 },
    { lat: 48.333065, lng: 17.772694 },
    { lat: 48.333473, lng: 17.772392 },
    { lat: 48.333627, lng: 17.772251 },
    { lat: 48.333779, lng: 17.772191 },
    { lat: 48.334144, lng: 17.771885 },
    { lat: 48.334208, lng: 17.7718 },
    { lat: 48.334646, lng: 17.771063 },
    { lat: 48.335016, lng: 17.770481 },
    { lat: 48.335059, lng: 17.770414 },
    { lat: 48.335225, lng: 17.770158 },
    { lat: 48.33534, lng: 17.769978 },
    { lat: 48.335501, lng: 17.769776 },
    { lat: 48.335812, lng: 17.769469 },
    { lat: 48.335918, lng: 17.769337 },
    { lat: 48.335979, lng: 17.769288 },
    { lat: 48.336103, lng: 17.769188 },
    { lat: 48.336242, lng: 17.769076 },
    { lat: 48.336496, lng: 17.768927 },
    { lat: 48.336704, lng: 17.768789 },
    { lat: 48.336963, lng: 17.768597 },
    { lat: 48.337029, lng: 17.768456 },
    { lat: 48.337088, lng: 17.768397 },
    { lat: 48.337161, lng: 17.768324 },
    { lat: 48.337431, lng: 17.768068 },
    { lat: 48.337551, lng: 17.767974 },
    { lat: 48.337891, lng: 17.76763 },
    { lat: 48.337976, lng: 17.767586 },
    { lat: 48.338057, lng: 17.767544 },
    { lat: 48.338293, lng: 17.767423 },
    { lat: 48.338336, lng: 17.7674 },
    { lat: 48.338368, lng: 17.767399 },
    { lat: 48.338427, lng: 17.767397 },
    { lat: 48.338647, lng: 17.766673 },
    { lat: 48.338925, lng: 17.766259 },
    { lat: 48.338931, lng: 17.766128 },
    { lat: 48.338892, lng: 17.76584 },
    { lat: 48.338816, lng: 17.765583 },
    { lat: 48.338808, lng: 17.765396 },
    { lat: 48.338871, lng: 17.765288 },
    { lat: 48.338957, lng: 17.765205 },
    { lat: 48.339018, lng: 17.765127 },
    { lat: 48.339053, lng: 17.765056 },
    { lat: 48.339083, lng: 17.765028 },
    { lat: 48.339129, lng: 17.764985 },
    { lat: 48.339167, lng: 17.764895 },
    { lat: 48.339135, lng: 17.764464 },
    { lat: 48.339168, lng: 17.764382 },
    { lat: 48.339183, lng: 17.764103 },
    { lat: 48.339288, lng: 17.763745 },
    { lat: 48.339344, lng: 17.76362 },
    { lat: 48.339391, lng: 17.763509 },
    { lat: 48.339483, lng: 17.763296 },
    { lat: 48.339628, lng: 17.763178 },
    { lat: 48.33977, lng: 17.762843 },
    { lat: 48.339819, lng: 17.762678 },
    { lat: 48.339851, lng: 17.762458 },
    { lat: 48.339979, lng: 17.762068 },
    { lat: 48.340066, lng: 17.76183 },
    { lat: 48.340137, lng: 17.761636 },
    { lat: 48.340182, lng: 17.761428 },
    { lat: 48.340191, lng: 17.76137 },
    { lat: 48.340215, lng: 17.761293 },
    { lat: 48.34025, lng: 17.761189 },
    { lat: 48.340271, lng: 17.761106 },
    { lat: 48.340289, lng: 17.761034 },
    { lat: 48.340305, lng: 17.760966 },
    { lat: 48.340322, lng: 17.760896 },
    { lat: 48.340362, lng: 17.760732 },
    { lat: 48.340396, lng: 17.760418 },
    { lat: 48.340412, lng: 17.760276 },
    { lat: 48.340489, lng: 17.759796 },
    { lat: 48.340498, lng: 17.759736 },
    { lat: 48.340617, lng: 17.758998 },
    { lat: 48.34059, lng: 17.758537 },
    { lat: 48.340588, lng: 17.758513 },
    { lat: 48.340822, lng: 17.758001 },
    { lat: 48.3405, lng: 17.757749 },
    { lat: 48.339671, lng: 17.757342 },
    { lat: 48.337834, lng: 17.757065 },
    { lat: 48.33714, lng: 17.756573 },
    { lat: 48.33677, lng: 17.75608 },
    { lat: 48.336238, lng: 17.755595 },
    { lat: 48.335269, lng: 17.754921 },
    { lat: 48.334193, lng: 17.754836 },
    { lat: 48.333553, lng: 17.754596 },
    { lat: 48.333187, lng: 17.754619 },
    { lat: 48.332662, lng: 17.754512 },
    { lat: 48.33204, lng: 17.754142 },
    { lat: 48.331855, lng: 17.754113 },
    { lat: 48.33141, lng: 17.753644 },
    { lat: 48.331201, lng: 17.753686 },
    { lat: 48.330661, lng: 17.753389 },
    { lat: 48.33001, lng: 17.753172 },
    { lat: 48.329656, lng: 17.750635 },
    { lat: 48.329196, lng: 17.751192 },
    { lat: 48.328659, lng: 17.751573 },
    { lat: 48.327797, lng: 17.751896 },
    { lat: 48.327042, lng: 17.751386 },
    { lat: 48.319298, lng: 17.75042 },
    { lat: 48.317897, lng: 17.750063 },
    { lat: 48.31706, lng: 17.749781 },
  ],
  Hoste: [
    { lat: 48.2556834, lng: 17.6679623 },
    { lat: 48.2557628, lng: 17.6679872 },
    { lat: 48.2559753, lng: 17.6680062 },
    { lat: 48.256301, lng: 17.6678725 },
    { lat: 48.2564294, lng: 17.6677043 },
    { lat: 48.2567072, lng: 17.6669314 },
    { lat: 48.2573119, lng: 17.6665267 },
    { lat: 48.2574924, lng: 17.6663415 },
    { lat: 48.2577338, lng: 17.6659092 },
    { lat: 48.2578253, lng: 17.6658903 },
    { lat: 48.2579991, lng: 17.6660165 },
    { lat: 48.2581271, lng: 17.6664194 },
    { lat: 48.2584336, lng: 17.6669113 },
    { lat: 48.2585658, lng: 17.6669133 },
    { lat: 48.2590994, lng: 17.6664388 },
    { lat: 48.2592331, lng: 17.6661032 },
    { lat: 48.2591741, lng: 17.6658128 },
    { lat: 48.2590763, lng: 17.6655995 },
    { lat: 48.2590664, lng: 17.6651367 },
    { lat: 48.2593031, lng: 17.6644478 },
    { lat: 48.2595026, lng: 17.6641544 },
    { lat: 48.2596305, lng: 17.6637271 },
    { lat: 48.2596528, lng: 17.6633285 },
    { lat: 48.2596095, lng: 17.6631595 },
    { lat: 48.259398, lng: 17.6629027 },
    { lat: 48.2591722, lng: 17.6629022 },
    { lat: 48.2588722, lng: 17.6633392 },
    { lat: 48.2586998, lng: 17.663417 },
    { lat: 48.2585047, lng: 17.6633126 },
    { lat: 48.2584429, lng: 17.6630481 },
    { lat: 48.2588483, lng: 17.6623292 },
    { lat: 48.2588483, lng: 17.6620584 },
    { lat: 48.2590904, lng: 17.6615272 },
    { lat: 48.2593032, lng: 17.6610508 },
    { lat: 48.2593839, lng: 17.6606106 },
    { lat: 48.2592131, lng: 17.6594013 },
    { lat: 48.2592072, lng: 17.6588398 },
    { lat: 48.2594423, lng: 17.6583385 },
    { lat: 48.2596218, lng: 17.6581219 },
    { lat: 48.2598224, lng: 17.6581174 },
    { lat: 48.2601019, lng: 17.6583062 },
    { lat: 48.2603165, lng: 17.6582533 },
    { lat: 48.2605182, lng: 17.6580863 },
    { lat: 48.2606747, lng: 17.6578095 },
    { lat: 48.2607125, lng: 17.6576192 },
    { lat: 48.2604368, lng: 17.6574196 },
    { lat: 48.2603297, lng: 17.6572394 },
    { lat: 48.2603249, lng: 17.6566965 },
    { lat: 48.2604035, lng: 17.6565653 },
    { lat: 48.2605532, lng: 17.6565938 },
    { lat: 48.2607893, lng: 17.6571321 },
    { lat: 48.2609231, lng: 17.6572504 },
    { lat: 48.2615398, lng: 17.6571805 },
    { lat: 48.2618008, lng: 17.6572755 },
    { lat: 48.2622924, lng: 17.6575868 },
    { lat: 48.263088, lng: 17.6576707 },
    { lat: 48.2633893, lng: 17.6575935 },
    { lat: 48.2642047, lng: 17.6569874 },
    { lat: 48.2643802, lng: 17.656704 },
    { lat: 48.2644031, lng: 17.65653 },
    { lat: 48.264194, lng: 17.65608 },
    { lat: 48.264755, lng: 17.654501 },
    { lat: 48.264872, lng: 17.65416 },
    { lat: 48.264971, lng: 17.653504 },
    { lat: 48.264944, lng: 17.653077 },
    { lat: 48.264729, lng: 17.652485 },
    { lat: 48.26474, lng: 17.652208 },
    { lat: 48.264784, lng: 17.652039 },
    { lat: 48.264975, lng: 17.651909 },
    { lat: 48.26512, lng: 17.651941 },
    { lat: 48.265279, lng: 17.652161 },
    { lat: 48.265542, lng: 17.652339 },
    { lat: 48.266087, lng: 17.653022 },
    { lat: 48.266344, lng: 17.653118 },
    { lat: 48.266925, lng: 17.653025 },
    { lat: 48.26735, lng: 17.652782 },
    { lat: 48.267726, lng: 17.652454 },
    { lat: 48.268162, lng: 17.652285 },
    { lat: 48.268965, lng: 17.652451 },
    { lat: 48.269321, lng: 17.652683 },
    { lat: 48.269388, lng: 17.647517 },
    { lat: 48.26936, lng: 17.644431 },
    { lat: 48.269302, lng: 17.642765 },
    { lat: 48.269344, lng: 17.641636 },
    { lat: 48.2693, lng: 17.640529 },
    { lat: 48.269327, lng: 17.638594 },
    { lat: 48.269081, lng: 17.637106 },
    { lat: 48.269106, lng: 17.635248 },
    { lat: 48.268776, lng: 17.634098 },
    { lat: 48.268957, lng: 17.633638 },
    { lat: 48.268588, lng: 17.633024 },
    { lat: 48.268549, lng: 17.630053 },
    { lat: 48.268136, lng: 17.625599 },
    { lat: 48.268425, lng: 17.624535 },
    { lat: 48.268527, lng: 17.622695 },
    { lat: 48.268442, lng: 17.620784 },
    { lat: 48.267988, lng: 17.618436 },
    { lat: 48.266677, lng: 17.61681 },
    { lat: 48.2658281, lng: 17.6182122 },
    { lat: 48.264996, lng: 17.6208062 },
    { lat: 48.2631044, lng: 17.6186584 },
    { lat: 48.2590722, lng: 17.6262607 },
    { lat: 48.2564697, lng: 17.6313672 },
    { lat: 48.2546457, lng: 17.6350684 },
    { lat: 48.2548908, lng: 17.6364305 },
    { lat: 48.2549024, lng: 17.6367045 },
    { lat: 48.2539475, lng: 17.6385575 },
    { lat: 48.253677, lng: 17.6389769 },
    { lat: 48.253783, lng: 17.6390828 },
    { lat: 48.2540393, lng: 17.6391887 },
    { lat: 48.2540629, lng: 17.6393823 },
    { lat: 48.2543238, lng: 17.6400318 },
    { lat: 48.2544124, lng: 17.6401114 },
    { lat: 48.2540735, lng: 17.6403768 },
    { lat: 48.2537289, lng: 17.6401395 },
    { lat: 48.2535071, lng: 17.6402587 },
    { lat: 48.2531384, lng: 17.6403218 },
    { lat: 48.252932, lng: 17.6404661 },
    { lat: 48.2526945, lng: 17.6408394 },
    { lat: 48.2525858, lng: 17.6408158 },
    { lat: 48.2524121, lng: 17.6405342 },
    { lat: 48.2522719, lng: 17.6405136 },
    { lat: 48.2518927, lng: 17.6410887 },
    { lat: 48.2516879, lng: 17.6413171 },
    { lat: 48.2514925, lng: 17.6414215 },
    { lat: 48.2515434, lng: 17.6415132 },
    { lat: 48.2519223, lng: 17.643532 },
    { lat: 48.2524602, lng: 17.6457427 },
    { lat: 48.2514471, lng: 17.6462789 },
    { lat: 48.2521509, lng: 17.6492085 },
    { lat: 48.2523723, lng: 17.6513108 },
    { lat: 48.2536893, lng: 17.654423 },
    { lat: 48.2553409, lng: 17.657649 },
    { lat: 48.2546684, lng: 17.6589324 },
    { lat: 48.2534403, lng: 17.6602534 },
    { lat: 48.2545576, lng: 17.6636408 },
    { lat: 48.25552, lng: 17.6669606 },
    { lat: 48.2556789, lng: 17.6678892 },
    { lat: 48.2556834, lng: 17.6679623 },
  ],
  Sereď: [
    { lat: 48.31706, lng: 17.749781 },
    { lat: 48.316127, lng: 17.745871 },
    { lat: 48.315792, lng: 17.74413 },
    { lat: 48.316107, lng: 17.743201 },
    { lat: 48.317526, lng: 17.742964 },
    { lat: 48.318469, lng: 17.74221 },
    { lat: 48.320052, lng: 17.741935 },
    { lat: 48.320358, lng: 17.738766 },
    { lat: 48.320187, lng: 17.737378 },
    { lat: 48.320316, lng: 17.735722 },
    { lat: 48.320489, lng: 17.73522 },
    { lat: 48.321456, lng: 17.734462 },
    { lat: 48.321679, lng: 17.733814 },
    { lat: 48.322617, lng: 17.730052 },
    { lat: 48.322754, lng: 17.729297 },
    { lat: 48.322315, lng: 17.728097 },
    { lat: 48.321537, lng: 17.728884 },
    { lat: 48.320779, lng: 17.729398 },
    { lat: 48.320142, lng: 17.730061 },
    { lat: 48.319594, lng: 17.728814 },
    { lat: 48.319078, lng: 17.726065 },
    { lat: 48.318483, lng: 17.720671 },
    { lat: 48.318186, lng: 17.719039 },
    { lat: 48.31802, lng: 17.717825 },
    { lat: 48.317954, lng: 17.71702 },
    { lat: 48.317893, lng: 17.715191 },
    { lat: 48.317757, lng: 17.714216 },
    { lat: 48.317618, lng: 17.713751 },
    { lat: 48.317421, lng: 17.713309 },
    { lat: 48.316955, lng: 17.71269 },
    { lat: 48.316598, lng: 17.711966 },
    { lat: 48.315786, lng: 17.708743 },
    { lat: 48.315695, lng: 17.70804 },
    { lat: 48.316225, lng: 17.705258 },
    { lat: 48.316112, lng: 17.704692 },
    { lat: 48.315823, lng: 17.704176 },
    { lat: 48.31553, lng: 17.703955 },
    { lat: 48.314544, lng: 17.701746 },
    { lat: 48.313862, lng: 17.700031 },
    { lat: 48.313106, lng: 17.698677 },
    { lat: 48.311516, lng: 17.696451 },
    { lat: 48.310203, lng: 17.694446 },
    { lat: 48.30932, lng: 17.693266 },
    { lat: 48.307921, lng: 17.694459 },
    { lat: 48.307615, lng: 17.693904 },
    { lat: 48.307501, lng: 17.693488 },
    { lat: 48.306158, lng: 17.690859 },
    { lat: 48.305608, lng: 17.689683 },
    { lat: 48.305077, lng: 17.688858 },
    { lat: 48.304345, lng: 17.687868 },
    { lat: 48.303925, lng: 17.687416 },
    { lat: 48.303217, lng: 17.688925 },
    { lat: 48.302696, lng: 17.689692 },
    { lat: 48.302133, lng: 17.691232 },
    { lat: 48.300529, lng: 17.6945 },
    { lat: 48.301317, lng: 17.69578 },
    { lat: 48.300993, lng: 17.696196 },
    { lat: 48.30056, lng: 17.697203 },
    { lat: 48.300268, lng: 17.697629 },
    { lat: 48.298294, lng: 17.699231 },
    { lat: 48.297955, lng: 17.699374 },
    { lat: 48.297327, lng: 17.699461 },
    { lat: 48.296944, lng: 17.699705 },
    { lat: 48.296784, lng: 17.699943 },
    { lat: 48.296439, lng: 17.700714 },
    { lat: 48.296072, lng: 17.702253 },
    { lat: 48.295887, lng: 17.702781 },
    { lat: 48.295605, lng: 17.703073 },
    { lat: 48.295415, lng: 17.703106 },
    { lat: 48.29518, lng: 17.703051 },
    { lat: 48.29491, lng: 17.702771 },
    { lat: 48.294683, lng: 17.702389 },
    { lat: 48.294604, lng: 17.702123 },
    { lat: 48.294566, lng: 17.701621 },
    { lat: 48.295146, lng: 17.698283 },
    { lat: 48.295276, lng: 17.69787 },
    { lat: 48.295364, lng: 17.697357 },
    { lat: 48.295386, lng: 17.696721 },
    { lat: 48.295328, lng: 17.696452 },
    { lat: 48.294869, lng: 17.695393 },
    { lat: 48.294646, lng: 17.695029 },
    { lat: 48.293904, lng: 17.694524 },
    { lat: 48.292767, lng: 17.694451 },
    { lat: 48.292178, lng: 17.69418 },
    { lat: 48.291514, lng: 17.693501 },
    { lat: 48.290735, lng: 17.691334 },
    { lat: 48.290449, lng: 17.690918 },
    { lat: 48.28997, lng: 17.690774 },
    { lat: 48.289733, lng: 17.690798 },
    { lat: 48.289542, lng: 17.690946 },
    { lat: 48.288757, lng: 17.691937 },
    { lat: 48.288143, lng: 17.692059 },
    { lat: 48.287904, lng: 17.691952 },
    { lat: 48.287775, lng: 17.691674 },
    { lat: 48.287655, lng: 17.691169 },
    { lat: 48.287684, lng: 17.690517 },
    { lat: 48.287651, lng: 17.689691 },
    { lat: 48.287703, lng: 17.688987 },
    { lat: 48.28767, lng: 17.688653 },
    { lat: 48.287439, lng: 17.688082 },
    { lat: 48.287131, lng: 17.6877 },
    { lat: 48.287003, lng: 17.687615 },
    { lat: 48.286566, lng: 17.687503 },
    { lat: 48.286285, lng: 17.687601 },
    { lat: 48.285868, lng: 17.688007 },
    { lat: 48.285669, lng: 17.688433 },
    { lat: 48.285536, lng: 17.688882 },
    { lat: 48.285585, lng: 17.689544 },
    { lat: 48.285576, lng: 17.690337 },
    { lat: 48.285382, lng: 17.690695 },
    { lat: 48.285244, lng: 17.690771 },
    { lat: 48.284766, lng: 17.690634 },
    { lat: 48.284291, lng: 17.690342 },
    { lat: 48.283622, lng: 17.689408 },
    { lat: 48.283484, lng: 17.688866 },
    { lat: 48.283544, lng: 17.688175 },
    { lat: 48.283446, lng: 17.686968 },
    { lat: 48.28314, lng: 17.68445 },
    { lat: 48.283772, lng: 17.684336 },
    { lat: 48.283831, lng: 17.683206 },
    { lat: 48.282011, lng: 17.680482 },
    { lat: 48.281105, lng: 17.67881 },
    { lat: 48.280911, lng: 17.678081 },
    { lat: 48.280744, lng: 17.676571 },
    { lat: 48.280694, lng: 17.674966 },
    { lat: 48.280703, lng: 17.672575 },
    { lat: 48.280257, lng: 17.669329 },
    { lat: 48.280036, lng: 17.667061 },
    { lat: 48.279786, lng: 17.663898 },
    { lat: 48.279718, lng: 17.662277 },
    { lat: 48.279504, lng: 17.659547 },
    { lat: 48.279417, lng: 17.658929 },
    { lat: 48.279267, lng: 17.655383 },
    { lat: 48.278799, lng: 17.656017 },
    { lat: 48.276919, lng: 17.657186 },
    { lat: 48.276483, lng: 17.65616 },
    { lat: 48.276294, lng: 17.655954 },
    { lat: 48.276073, lng: 17.655829 },
    { lat: 48.27581, lng: 17.655763 },
    { lat: 48.274498, lng: 17.655876 },
    { lat: 48.273978, lng: 17.656272 },
    { lat: 48.274031, lng: 17.656796 },
    { lat: 48.273655, lng: 17.657263 },
    { lat: 48.273323, lng: 17.657465 },
    { lat: 48.273068, lng: 17.657514 },
    { lat: 48.272719, lng: 17.657185 },
    { lat: 48.27267, lng: 17.657229 },
    { lat: 48.272104, lng: 17.65556 },
    { lat: 48.271859, lng: 17.655101 },
    { lat: 48.271456, lng: 17.654592 },
    { lat: 48.270773, lng: 17.654101 },
    { lat: 48.270143, lng: 17.654225 },
    { lat: 48.269621, lng: 17.654485 },
    { lat: 48.267876, lng: 17.655784 },
    { lat: 48.267461, lng: 17.655993 },
    { lat: 48.265953, lng: 17.656308 },
    { lat: 48.265795, lng: 17.655479 },
    { lat: 48.2658, lng: 17.655125 },
    { lat: 48.265738, lng: 17.65463 },
    { lat: 48.265359, lng: 17.654396 },
    { lat: 48.264755, lng: 17.654501 },
    { lat: 48.264194, lng: 17.65608 },
    { lat: 48.2644031, lng: 17.65653 },
    { lat: 48.2643802, lng: 17.656704 },
    { lat: 48.2642047, lng: 17.6569874 },
    { lat: 48.2633893, lng: 17.6575935 },
    { lat: 48.263088, lng: 17.6576707 },
    { lat: 48.2622924, lng: 17.6575868 },
    { lat: 48.2618008, lng: 17.6572755 },
    { lat: 48.2615398, lng: 17.6571805 },
    { lat: 48.2609231, lng: 17.6572504 },
    { lat: 48.2607893, lng: 17.6571321 },
    { lat: 48.2605532, lng: 17.6565938 },
    { lat: 48.2604035, lng: 17.6565653 },
    { lat: 48.2603249, lng: 17.6566965 },
    { lat: 48.2603297, lng: 17.6572394 },
    { lat: 48.2604368, lng: 17.6574196 },
    { lat: 48.2607125, lng: 17.6576192 },
    { lat: 48.2606747, lng: 17.6578095 },
    { lat: 48.2605182, lng: 17.6580863 },
    { lat: 48.2603165, lng: 17.6582533 },
    { lat: 48.2601019, lng: 17.6583062 },
    { lat: 48.2598224, lng: 17.6581174 },
    { lat: 48.2596218, lng: 17.6581219 },
    { lat: 48.2594423, lng: 17.6583385 },
    { lat: 48.2592072, lng: 17.6588398 },
    { lat: 48.2592131, lng: 17.6594013 },
    { lat: 48.2593839, lng: 17.6606106 },
    { lat: 48.2593032, lng: 17.6610508 },
    { lat: 48.2590904, lng: 17.6615272 },
    { lat: 48.2591601, lng: 17.6616315 },
    { lat: 48.2615293, lng: 17.6667049 },
    { lat: 48.2621466, lng: 17.6677275 },
    { lat: 48.2634756, lng: 17.6696727 },
    { lat: 48.2648441, lng: 17.6711291 },
    { lat: 48.2654795, lng: 17.6720239 },
    { lat: 48.2662317, lng: 17.6725878 },
    { lat: 48.2679524, lng: 17.6740643 },
    { lat: 48.2682417, lng: 17.6745545 },
    { lat: 48.2697336, lng: 17.6776021 },
    { lat: 48.2701775, lng: 17.6787061 },
    { lat: 48.2701139, lng: 17.6792392 },
    { lat: 48.2700412, lng: 17.6790878 },
    { lat: 48.2697389, lng: 17.6789672 },
    { lat: 48.2695071, lng: 17.6790335 },
    { lat: 48.2684405, lng: 17.680035 },
    { lat: 48.268264, lng: 17.6802618 },
    { lat: 48.2681095, lng: 17.6806056 },
    { lat: 48.267499, lng: 17.6829595 },
    { lat: 48.2669298, lng: 17.6837691 },
    { lat: 48.2669279, lng: 17.684117 },
    { lat: 48.2670318, lng: 17.6844235 },
    { lat: 48.2673681, lng: 17.684871 },
    { lat: 48.2675413, lng: 17.6849321 },
    { lat: 48.2680654, lng: 17.6848833 },
    { lat: 48.2686397, lng: 17.6849827 },
    { lat: 48.2687998, lng: 17.6852147 },
    { lat: 48.2688685, lng: 17.6854815 },
    { lat: 48.268855, lng: 17.6857531 },
    { lat: 48.2687249, lng: 17.6861282 },
    { lat: 48.26844, lng: 17.6865219 },
    { lat: 48.2681963, lng: 17.6866312 },
    { lat: 48.2673323, lng: 17.6867915 },
    { lat: 48.2667352, lng: 17.6869926 },
    { lat: 48.2660369, lng: 17.6868935 },
    { lat: 48.2653427, lng: 17.687003 },
    { lat: 48.264766, lng: 17.6872962 },
    { lat: 48.2644352, lng: 17.6877491 },
    { lat: 48.2642475, lng: 17.6882145 },
    { lat: 48.2642537, lng: 17.6886768 },
    { lat: 48.2645334, lng: 17.6893725 },
    { lat: 48.2650226, lng: 17.6903361 },
    { lat: 48.2653532, lng: 17.6908022 },
    { lat: 48.2655463, lng: 17.6913258 },
    { lat: 48.2658198, lng: 17.6938038 },
    { lat: 48.265803, lng: 17.6942389 },
    { lat: 48.2653831, lng: 17.6954714 },
    { lat: 48.2652241, lng: 17.6957439 },
    { lat: 48.2646799, lng: 17.6960873 },
    { lat: 48.2644152, lng: 17.6960968 },
    { lat: 48.2637795, lng: 17.695828 },
    { lat: 48.2636043, lng: 17.6956446 },
    { lat: 48.2629655, lng: 17.6942554 },
    { lat: 48.2626396, lng: 17.6937196 },
    { lat: 48.262169, lng: 17.6932172 },
    { lat: 48.2611552, lng: 17.6932403 },
    { lat: 48.2605926, lng: 17.6929266 },
    { lat: 48.260067, lng: 17.6927926 },
    { lat: 48.2599418, lng: 17.6928633 },
    { lat: 48.2596922, lng: 17.6932271 },
    { lat: 48.2595658, lng: 17.6936431 },
    { lat: 48.2595445, lng: 17.6952895 },
    { lat: 48.2596267, lng: 17.6960635 },
    { lat: 48.2598365, lng: 17.6968009 },
    { lat: 48.2598811, lng: 17.6970983 },
    { lat: 48.2598765, lng: 17.6974944 },
    { lat: 48.2598279, lng: 17.6976932 },
    { lat: 48.2596172, lng: 17.6980593 },
    { lat: 48.2591594, lng: 17.6983779 },
    { lat: 48.2604312, lng: 17.7006811 },
    { lat: 48.2597457, lng: 17.7014571 },
    { lat: 48.2607578, lng: 17.7030898 },
    { lat: 48.2595797, lng: 17.7042937 },
    { lat: 48.2588367, lng: 17.7057211 },
    { lat: 48.2585918, lng: 17.7064299 },
    { lat: 48.2577262, lng: 17.7082114 },
    { lat: 48.2574295, lng: 17.7090104 },
    { lat: 48.2570277, lng: 17.7098298 },
    { lat: 48.2564972, lng: 17.7107157 },
    { lat: 48.2561661, lng: 17.7117994 },
    { lat: 48.2559242, lng: 17.7140405 },
    { lat: 48.2552426, lng: 17.7151153 },
    { lat: 48.2543431, lng: 17.7156106 },
    { lat: 48.2547407, lng: 17.7161545 },
    { lat: 48.255475, lng: 17.7166503 },
    { lat: 48.2587334, lng: 17.7201498 },
    { lat: 48.2588307, lng: 17.7201344 },
    { lat: 48.2626731, lng: 17.7244915 },
    { lat: 48.2628205, lng: 17.7244465 },
    { lat: 48.263755, lng: 17.7259283 },
    { lat: 48.2645028, lng: 17.7273306 },
    { lat: 48.2656539, lng: 17.7289556 },
    { lat: 48.265837, lng: 17.7291239 },
    { lat: 48.2666102, lng: 17.7301159 },
    { lat: 48.2676453, lng: 17.7316045 },
    { lat: 48.2685739, lng: 17.7333936 },
    { lat: 48.2697239, lng: 17.7361452 },
    { lat: 48.2692423, lng: 17.7370798 },
    { lat: 48.2693417, lng: 17.7379725 },
    { lat: 48.270223, lng: 17.7362726 },
    { lat: 48.2706103, lng: 17.7367499 },
    { lat: 48.2706483, lng: 17.7369771 },
    { lat: 48.2707505, lng: 17.7369494 },
    { lat: 48.2708403, lng: 17.7370694 },
    { lat: 48.2708785, lng: 17.7369957 },
    { lat: 48.2709058, lng: 17.7370351 },
    { lat: 48.2708755, lng: 17.7371102 },
    { lat: 48.2714828, lng: 17.7377992 },
    { lat: 48.2715052, lng: 17.7379772 },
    { lat: 48.2716517, lng: 17.7379897 },
    { lat: 48.2722611, lng: 17.7387851 },
    { lat: 48.2727391, lng: 17.7392285 },
    { lat: 48.2725176, lng: 17.7395365 },
    { lat: 48.2743697, lng: 17.7419093 },
    { lat: 48.2741917, lng: 17.7421846 },
    { lat: 48.2754832, lng: 17.7438931 },
    { lat: 48.2756552, lng: 17.7437894 },
    { lat: 48.2756773, lng: 17.7438381 },
    { lat: 48.2755568, lng: 17.7439885 },
    { lat: 48.2759685, lng: 17.7443674 },
    { lat: 48.2764098, lng: 17.7445084 },
    { lat: 48.2793006, lng: 17.7480483 },
    { lat: 48.2806002, lng: 17.7475934 },
    { lat: 48.2842187, lng: 17.7485775 },
    { lat: 48.2862546, lng: 17.7497652 },
    { lat: 48.289065, lng: 17.7530103 },
    { lat: 48.2893894, lng: 17.7534739 },
    { lat: 48.2907074, lng: 17.7564001 },
    { lat: 48.2910823, lng: 17.7570549 },
    { lat: 48.2912846, lng: 17.7572962 },
    { lat: 48.2923662, lng: 17.7583547 },
    { lat: 48.2933992, lng: 17.7591205 },
    { lat: 48.2943136, lng: 17.7597199 },
    { lat: 48.2949865, lng: 17.7599011 },
    { lat: 48.2954666, lng: 17.7598518 },
    { lat: 48.2969485, lng: 17.7593891 },
    { lat: 48.2975227, lng: 17.7592699 },
    { lat: 48.2982036, lng: 17.7529259 },
    { lat: 48.2985123, lng: 17.7516958 },
    { lat: 48.3049125, lng: 17.7500859 },
    { lat: 48.3051848, lng: 17.7500168 },
    { lat: 48.3072049, lng: 17.7496568 },
    { lat: 48.3130814, lng: 17.748963 },
    { lat: 48.31706, lng: 17.749781 },
  ],
  Sládkovičovo: [
    { lat: 48.2224712, lng: 17.6152833 },
    { lat: 48.2254493, lng: 17.6109002 },
    { lat: 48.2255779, lng: 17.6106903 },
    { lat: 48.2264788, lng: 17.6092163 },
    { lat: 48.2284099, lng: 17.6072907 },
    { lat: 48.2283278, lng: 17.6072245 },
    { lat: 48.2229822, lng: 17.6018273 },
    { lat: 48.222727, lng: 17.6015339 },
    { lat: 48.2211309, lng: 17.5996984 },
    { lat: 48.2207375, lng: 17.5992461 },
    { lat: 48.2187559, lng: 17.5967889 },
    { lat: 48.2157431, lng: 17.5935132 },
    { lat: 48.2145099, lng: 17.5923925 },
    { lat: 48.2134714, lng: 17.5912779 },
    { lat: 48.2134253, lng: 17.5912285 },
    { lat: 48.2125186, lng: 17.5904991 },
    { lat: 48.2107085, lng: 17.5869271 },
    { lat: 48.2089123, lng: 17.5823136 },
    { lat: 48.2066344, lng: 17.5798045 },
    { lat: 48.2030742, lng: 17.5756732 },
    { lat: 48.2011711, lng: 17.5730589 },
    { lat: 48.2004186, lng: 17.5722886 },
    { lat: 48.1992497, lng: 17.5708325 },
    { lat: 48.1986359, lng: 17.5701685 },
    { lat: 48.1979543, lng: 17.5695535 },
    { lat: 48.1968915, lng: 17.5688951 },
    { lat: 48.1956456, lng: 17.5686978 },
    { lat: 48.1938578, lng: 17.5678766 },
    { lat: 48.1928053, lng: 17.5673096 },
    { lat: 48.1927513, lng: 17.5673307 },
    { lat: 48.1925857, lng: 17.567355 },
    { lat: 48.1921462, lng: 17.5672127 },
    { lat: 48.1917841, lng: 17.5667735 },
    { lat: 48.1916169, lng: 17.5663482 },
    { lat: 48.1915438, lng: 17.5659619 },
    { lat: 48.1914602, lng: 17.5647907 },
    { lat: 48.1912878, lng: 17.5644967 },
    { lat: 48.1910638, lng: 17.564419 },
    { lat: 48.1904588, lng: 17.5647618 },
    { lat: 48.1902163, lng: 17.565261 },
    { lat: 48.190033, lng: 17.5659635 },
    { lat: 48.1900402, lng: 17.5663255 },
    { lat: 48.1901327, lng: 17.5668046 },
    { lat: 48.1905528, lng: 17.5674784 },
    { lat: 48.191025, lng: 17.5679922 },
    { lat: 48.1922796, lng: 17.5690903 },
    { lat: 48.1928138, lng: 17.5694821 },
    { lat: 48.1945411, lng: 17.5701304 },
    { lat: 48.1946838, lng: 17.5703868 },
    { lat: 48.1948222, lng: 17.5710336 },
    { lat: 48.194731, lng: 17.5717491 },
    { lat: 48.1945091, lng: 17.5721293 },
    { lat: 48.1941829, lng: 17.5725205 },
    { lat: 48.1936316, lng: 17.5728195 },
    { lat: 48.1931355, lng: 17.5727811 },
    { lat: 48.1928576, lng: 17.5726526 },
    { lat: 48.1922965, lng: 17.5721946 },
    { lat: 48.1919597, lng: 17.572153 },
    { lat: 48.1916871, lng: 17.572201 },
    { lat: 48.1914968, lng: 17.5724571 },
    { lat: 48.1912838, lng: 17.5732076 },
    { lat: 48.1911947, lng: 17.5738336 },
    { lat: 48.1912605, lng: 17.5743512 },
    { lat: 48.1914712, lng: 17.5749993 },
    { lat: 48.1921284, lng: 17.5759746 },
    { lat: 48.1922305, lng: 17.5762474 },
    { lat: 48.192275, lng: 17.5766311 },
    { lat: 48.1922459, lng: 17.5769551 },
    { lat: 48.1921379, lng: 17.5773322 },
    { lat: 48.1920342, lng: 17.577514 },
    { lat: 48.1918459, lng: 17.5777121 },
    { lat: 48.191469, lng: 17.5779282 },
    { lat: 48.1906105, lng: 17.5777325 },
    { lat: 48.1900956, lng: 17.577305 },
    { lat: 48.1897659, lng: 17.5767196 },
    { lat: 48.1895149, lng: 17.5759693 },
    { lat: 48.1894231, lng: 17.5753599 },
    { lat: 48.1894831, lng: 17.5740858 },
    { lat: 48.1893634, lng: 17.5735363 },
    { lat: 48.18921, lng: 17.573362 },
    { lat: 48.1890114, lng: 17.5732941 },
    { lat: 48.1886636, lng: 17.5734363 },
    { lat: 48.1885204, lng: 17.5736881 },
    { lat: 48.1883853, lng: 17.5742269 },
    { lat: 48.1883708, lng: 17.5745648 },
    { lat: 48.1886629, lng: 17.5759451 },
    { lat: 48.188812, lng: 17.5764062 },
    { lat: 48.1891413, lng: 17.576915 },
    { lat: 48.189276, lng: 17.5774507 },
    { lat: 48.1893622, lng: 17.5788586 },
    { lat: 48.1892421, lng: 17.5797565 },
    { lat: 48.1890588, lng: 17.5807289 },
    { lat: 48.1889197, lng: 17.5810459 },
    { lat: 48.1887489, lng: 17.5812553 },
    { lat: 48.1878789, lng: 17.5817768 },
    { lat: 48.1872989, lng: 17.581964 },
    { lat: 48.1871497, lng: 17.5821547 },
    { lat: 48.1869593, lng: 17.5825912 },
    { lat: 48.1867145, lng: 17.5836055 },
    { lat: 48.1867078, lng: 17.5838581 },
    { lat: 48.1867196, lng: 17.5842365 },
    { lat: 48.1868406, lng: 17.5847377 },
    { lat: 48.1870749, lng: 17.585065 },
    { lat: 48.1874172, lng: 17.5853879 },
    { lat: 48.1876587, lng: 17.5855266 },
    { lat: 48.1886339, lng: 17.5857334 },
    { lat: 48.1895933, lng: 17.5858493 },
    { lat: 48.1899925, lng: 17.5860552 },
    { lat: 48.1903431, lng: 17.5864707 },
    { lat: 48.1905517, lng: 17.587071 },
    { lat: 48.1905819, lng: 17.5876358 },
    { lat: 48.1904974, lng: 17.5880126 },
    { lat: 48.1903361, lng: 17.5884054 },
    { lat: 48.1897795, lng: 17.5889778 },
    { lat: 48.1896102, lng: 17.589281 },
    { lat: 48.1895673, lng: 17.5894595 },
    { lat: 48.1896427, lng: 17.5897934 },
    { lat: 48.189798, lng: 17.590051 },
    { lat: 48.1900524, lng: 17.5901454 },
    { lat: 48.1904125, lng: 17.5900875 },
    { lat: 48.1907043, lng: 17.5898284 },
    { lat: 48.1909084, lng: 17.5893981 },
    { lat: 48.1910349, lng: 17.5889714 },
    { lat: 48.1910857, lng: 17.5886163 },
    { lat: 48.1911563, lng: 17.5874512 },
    { lat: 48.1912905, lng: 17.5867558 },
    { lat: 48.1914157, lng: 17.586451 },
    { lat: 48.1917573, lng: 17.5861221 },
    { lat: 48.1919316, lng: 17.5860574 },
    { lat: 48.1921406, lng: 17.5860581 },
    { lat: 48.1925961, lng: 17.5863851 },
    { lat: 48.1928332, lng: 17.5872043 },
    { lat: 48.1928895, lng: 17.5876624 },
    { lat: 48.1928534, lng: 17.5886398 },
    { lat: 48.1921874, lng: 17.5912754 },
    { lat: 48.1919798, lng: 17.5918106 },
    { lat: 48.1911116, lng: 17.5934167 },
    { lat: 48.1910048, lng: 17.5940279 },
    { lat: 48.1909973, lng: 17.5943711 },
    { lat: 48.1910591, lng: 17.5949912 },
    { lat: 48.1911623, lng: 17.595316 },
    { lat: 48.1915592, lng: 17.5960924 },
    { lat: 48.1917883, lng: 17.5963723 },
    { lat: 48.1920082, lng: 17.5965312 },
    { lat: 48.1927826, lng: 17.5968654 },
    { lat: 48.1931038, lng: 17.5971019 },
    { lat: 48.1932145, lng: 17.5973505 },
    { lat: 48.1932239, lng: 17.5975552 },
    { lat: 48.1931379, lng: 17.597997 },
    { lat: 48.1928945, lng: 17.5981196 },
    { lat: 48.1926835, lng: 17.5982197 },
    { lat: 48.1919512, lng: 17.5981233 },
    { lat: 48.191411, lng: 17.5979561 },
    { lat: 48.1911226, lng: 17.5979691 },
    { lat: 48.1894819, lng: 17.5991595 },
    { lat: 48.1892104, lng: 17.5995618 },
    { lat: 48.1887309, lng: 17.5999954 },
    { lat: 48.18768, lng: 17.6001968 },
    { lat: 48.1872605, lng: 17.6003837 },
    { lat: 48.1867514, lng: 17.6007262 },
    { lat: 48.1864942, lng: 17.6007854 },
    { lat: 48.1860742, lng: 17.6007664 },
    { lat: 48.1856643, lng: 17.6006763 },
    { lat: 48.1851966, lng: 17.6009061 },
    { lat: 48.1848678, lng: 17.6012584 },
    { lat: 48.1846396, lng: 17.6017384 },
    { lat: 48.1846378, lng: 17.6023002 },
    { lat: 48.1844553, lng: 17.6033922 },
    { lat: 48.1841953, lng: 17.6038487 },
    { lat: 48.1840194, lng: 17.6039961 },
    { lat: 48.1838305, lng: 17.6040618 },
    { lat: 48.1835905, lng: 17.6039985 },
    { lat: 48.1826176, lng: 17.6030636 },
    { lat: 48.1820995, lng: 17.6024786 },
    { lat: 48.1817343, lng: 17.6022947 },
    { lat: 48.1814224, lng: 17.6023085 },
    { lat: 48.1807377, lng: 17.6026579 },
    { lat: 48.1802825, lng: 17.6030659 },
    { lat: 48.1799356, lng: 17.6035061 },
    { lat: 48.1794004, lng: 17.6045399 },
    { lat: 48.1793129, lng: 17.6047089 },
    { lat: 48.1791373, lng: 17.6053433 },
    { lat: 48.1791839, lng: 17.6059361 },
    { lat: 48.1788821, lng: 17.6078919 },
    { lat: 48.1788903, lng: 17.6082155 },
    { lat: 48.1787173, lng: 17.6089239 },
    { lat: 48.1783972, lng: 17.6094851 },
    { lat: 48.1782211, lng: 17.6095928 },
    { lat: 48.178052, lng: 17.6095838 },
    { lat: 48.1780433, lng: 17.6097301 },
    { lat: 48.1783546, lng: 17.6097763 },
    { lat: 48.178946, lng: 17.6094873 },
    { lat: 48.1800647, lng: 17.6130308 },
    { lat: 48.1808121, lng: 17.6152392 },
    { lat: 48.1812703, lng: 17.6171802 },
    { lat: 48.1812756, lng: 17.6174444 },
    { lat: 48.18142, lng: 17.6181889 },
    { lat: 48.1814243, lng: 17.6184703 },
    { lat: 48.1824368, lng: 17.6205346 },
    { lat: 48.183291, lng: 17.6232211 },
    { lat: 48.1842641, lng: 17.6255217 },
    { lat: 48.1849951, lng: 17.6270655 },
    { lat: 48.1858532, lng: 17.6286923 },
    { lat: 48.1873593, lng: 17.6311727 },
    { lat: 48.1881397, lng: 17.6326944 },
    { lat: 48.188949, lng: 17.6345324 },
    { lat: 48.1812429, lng: 17.6410736 },
    { lat: 48.1814, lng: 17.641613 },
    { lat: 48.1822902, lng: 17.6447521 },
    { lat: 48.1826979, lng: 17.646366 },
    { lat: 48.1836796, lng: 17.6513581 },
    { lat: 48.1841964, lng: 17.6537362 },
    { lat: 48.1836704, lng: 17.6539546 },
    { lat: 48.1850805, lng: 17.6599091 },
    { lat: 48.1850499, lng: 17.6613418 },
    { lat: 48.1870208, lng: 17.6650319 },
    { lat: 48.1880903, lng: 17.6671571 },
    { lat: 48.1892673, lng: 17.6692857 },
    { lat: 48.1932627, lng: 17.6743479 },
    { lat: 48.1974789, lng: 17.6793504 },
    { lat: 48.1984884, lng: 17.6804323 },
    { lat: 48.1993026, lng: 17.6814251 },
    { lat: 48.1995396, lng: 17.6816775 },
    { lat: 48.1999288, lng: 17.6810587 },
    { lat: 48.2000929, lng: 17.6799842 },
    { lat: 48.2000791, lng: 17.6792397 },
    { lat: 48.2005406, lng: 17.6789477 },
    { lat: 48.2011681, lng: 17.6793223 },
    { lat: 48.2015604, lng: 17.6791324 },
    { lat: 48.2018051, lng: 17.6791791 },
    { lat: 48.2019365, lng: 17.6796742 },
    { lat: 48.2025215, lng: 17.6803404 },
    { lat: 48.2026792, lng: 17.6804262 },
    { lat: 48.2029438, lng: 17.6804489 },
    { lat: 48.2033151, lng: 17.6803936 },
    { lat: 48.2039146, lng: 17.6806075 },
    { lat: 48.2043991, lng: 17.6810599 },
    { lat: 48.2047515, lng: 17.6810621 },
    { lat: 48.2049561, lng: 17.6814606 },
    { lat: 48.2052856, lng: 17.6816027 },
    { lat: 48.2064618, lng: 17.6816143 },
    { lat: 48.2067462, lng: 17.6817769 },
    { lat: 48.2071286, lng: 17.6818949 },
    { lat: 48.2084363, lng: 17.6822223 },
    { lat: 48.2090945, lng: 17.6822414 },
    { lat: 48.2091387, lng: 17.6824211 },
    { lat: 48.2093497, lng: 17.6825299 },
    { lat: 48.2108689, lng: 17.6825179 },
    { lat: 48.2109477, lng: 17.6826951 },
    { lat: 48.211497, lng: 17.683136 },
    { lat: 48.2121355, lng: 17.6832889 },
    { lat: 48.2126055, lng: 17.6832035 },
    { lat: 48.2133332, lng: 17.684566 },
    { lat: 48.2136152, lng: 17.6856767 },
    { lat: 48.2139435, lng: 17.6869698 },
    { lat: 48.2142993, lng: 17.6885605 },
    { lat: 48.2143179, lng: 17.6885317 },
    { lat: 48.2148833, lng: 17.6870689 },
    { lat: 48.2152301, lng: 17.6867643 },
    { lat: 48.2154679, lng: 17.686361 },
    { lat: 48.2155725, lng: 17.6860674 },
    { lat: 48.2159057, lng: 17.6842275 },
    { lat: 48.2160963, lng: 17.6838644 },
    { lat: 48.2162622, lng: 17.6837742 },
    { lat: 48.2166509, lng: 17.683758 },
    { lat: 48.2171351, lng: 17.6836309 },
    { lat: 48.217799, lng: 17.6829625 },
    { lat: 48.2185541, lng: 17.6827068 },
    { lat: 48.2187837, lng: 17.682516 },
    { lat: 48.2192681, lng: 17.681577 },
    { lat: 48.2192958, lng: 17.6814605 },
    { lat: 48.219253, lng: 17.6812453 },
    { lat: 48.2178896, lng: 17.6774359 },
    { lat: 48.218505, lng: 17.6771491 },
    { lat: 48.218809, lng: 17.6771191 },
    { lat: 48.218974, lng: 17.6772482 },
    { lat: 48.2193136, lng: 17.6776753 },
    { lat: 48.2199237, lng: 17.6780213 },
    { lat: 48.2202814, lng: 17.6780918 },
    { lat: 48.2205883, lng: 17.6779609 },
    { lat: 48.221677, lng: 17.676595 },
    { lat: 48.2218864, lng: 17.676225 },
    { lat: 48.2221177, lng: 17.6760809 },
    { lat: 48.2222591, lng: 17.6739398 },
    { lat: 48.2222993, lng: 17.6737897 },
    { lat: 48.2229124, lng: 17.6724402 },
    { lat: 48.2231636, lng: 17.6721786 },
    { lat: 48.2236457, lng: 17.6719268 },
    { lat: 48.2238655, lng: 17.6719009 },
    { lat: 48.2242494, lng: 17.6724425 },
    { lat: 48.2239303, lng: 17.6739787 },
    { lat: 48.2239835, lng: 17.6748076 },
    { lat: 48.2243662, lng: 17.6753824 },
    { lat: 48.2245667, lng: 17.6755925 },
    { lat: 48.2249452, lng: 17.6757947 },
    { lat: 48.2251375, lng: 17.6757439 },
    { lat: 48.2254905, lng: 17.6750733 },
    { lat: 48.2256433, lng: 17.6743928 },
    { lat: 48.2264371, lng: 17.673242 },
    { lat: 48.2287203, lng: 17.671174 },
    { lat: 48.2280162, lng: 17.6691688 },
    { lat: 48.2278316, lng: 17.66878 },
    { lat: 48.2273595, lng: 17.6675011 },
    { lat: 48.2245022, lng: 17.660804 },
    { lat: 48.2238879, lng: 17.6592219 },
    { lat: 48.2235291, lng: 17.6585465 },
    { lat: 48.2226982, lng: 17.6566258 },
    { lat: 48.2226585, lng: 17.6564979 },
    { lat: 48.223423, lng: 17.6555832 },
    { lat: 48.2206076, lng: 17.6502289 },
    { lat: 48.2201936, lng: 17.6504866 },
    { lat: 48.2201092, lng: 17.6502198 },
    { lat: 48.2198253, lng: 17.6496565 },
    { lat: 48.2192926, lng: 17.6488549 },
    { lat: 48.2189182, lng: 17.6483957 },
    { lat: 48.2182959, lng: 17.6471925 },
    { lat: 48.2181506, lng: 17.6465353 },
    { lat: 48.2181441, lng: 17.6460717 },
    { lat: 48.2182801, lng: 17.6454694 },
    { lat: 48.2185389, lng: 17.6450969 },
    { lat: 48.2183778, lng: 17.6449051 },
    { lat: 48.2177983, lng: 17.6446529 },
    { lat: 48.2170058, lng: 17.6445661 },
    { lat: 48.2165886, lng: 17.6447527 },
    { lat: 48.2165124, lng: 17.6450676 },
    { lat: 48.2165358, lng: 17.6453684 },
    { lat: 48.2165938, lng: 17.6457674 },
    { lat: 48.2167675, lng: 17.646177 },
    { lat: 48.2167253, lng: 17.6464761 },
    { lat: 48.2166601, lng: 17.6466046 },
    { lat: 48.2162512, lng: 17.647 },
    { lat: 48.2154827, lng: 17.6474886 },
    { lat: 48.2152478, lng: 17.6475224 },
    { lat: 48.2150826, lng: 17.6474477 },
    { lat: 48.2149786, lng: 17.6473543 },
    { lat: 48.2147437, lng: 17.6468713 },
    { lat: 48.2145601, lng: 17.6462333 },
    { lat: 48.2145019, lng: 17.6455944 },
    { lat: 48.2146196, lng: 17.6450092 },
    { lat: 48.214987, lng: 17.644028 },
    { lat: 48.2150037, lng: 17.6433431 },
    { lat: 48.2149114, lng: 17.6429568 },
    { lat: 48.214424, lng: 17.6426032 },
    { lat: 48.2141338, lng: 17.6424784 },
    { lat: 48.2142901, lng: 17.6410685 },
    { lat: 48.2146543, lng: 17.6395946 },
    { lat: 48.2146254, lng: 17.6387759 },
    { lat: 48.214526, lng: 17.6382012 },
    { lat: 48.2145104, lng: 17.6373838 },
    { lat: 48.2147209, lng: 17.6366014 },
    { lat: 48.2148639, lng: 17.6363631 },
    { lat: 48.2151194, lng: 17.636099 },
    { lat: 48.2143367, lng: 17.6344305 },
    { lat: 48.2150202, lng: 17.6321647 },
    { lat: 48.216588, lng: 17.6261063 },
    { lat: 48.2166666, lng: 17.6256721 },
    { lat: 48.2167562, lng: 17.6245179 },
    { lat: 48.2169669, lng: 17.6236171 },
    { lat: 48.2176919, lng: 17.6219914 },
    { lat: 48.2186602, lng: 17.620659 },
    { lat: 48.2213245, lng: 17.6165788 },
    { lat: 48.2218467, lng: 17.6159599 },
    { lat: 48.2224712, lng: 17.6152833 },
  ],
  PustéÚľany: [
    { lat: 48.225548, lng: 17.520557 },
    { lat: 48.225519, lng: 17.520538 },
    { lat: 48.225498, lng: 17.520529 },
    { lat: 48.225088, lng: 17.52036 },
    { lat: 48.224633, lng: 17.520171 },
    { lat: 48.224166, lng: 17.52002 },
    { lat: 48.223954, lng: 17.519951 },
    { lat: 48.223798, lng: 17.5199 },
    { lat: 48.223744, lng: 17.519883 },
    { lat: 48.223376, lng: 17.519552 },
    { lat: 48.222985, lng: 17.5192 },
    { lat: 48.2225894, lng: 17.5188486 },
    { lat: 48.222153, lng: 17.518461 },
    { lat: 48.221979, lng: 17.518305 },
    { lat: 48.221721, lng: 17.518076 },
    { lat: 48.22103, lng: 17.517385 },
    { lat: 48.220645, lng: 17.517065 },
    { lat: 48.22029, lng: 17.516688 },
    { lat: 48.220047, lng: 17.516432 },
    { lat: 48.219712, lng: 17.516097 },
    { lat: 48.219697, lng: 17.516083 },
    { lat: 48.219381, lng: 17.515757 },
    { lat: 48.218771, lng: 17.515145 },
    { lat: 48.218484, lng: 17.514848 },
    { lat: 48.217978, lng: 17.514333 },
    { lat: 48.217573, lng: 17.51393 },
    { lat: 48.217059, lng: 17.513401 },
    { lat: 48.217031, lng: 17.513372 },
    { lat: 48.216966, lng: 17.513303 },
    { lat: 48.21664, lng: 17.512967 },
    { lat: 48.216181, lng: 17.512503 },
    { lat: 48.215688, lng: 17.511985 },
    { lat: 48.215379, lng: 17.511629 },
    { lat: 48.214617, lng: 17.510767 },
    { lat: 48.214243, lng: 17.510333 },
    { lat: 48.214075, lng: 17.510142 },
    { lat: 48.213715, lng: 17.509744 },
    { lat: 48.213479, lng: 17.509481 },
    { lat: 48.213432, lng: 17.509429 },
    { lat: 48.213394, lng: 17.509388 },
    { lat: 48.21298, lng: 17.508932 },
    { lat: 48.212935, lng: 17.508884 },
    { lat: 48.212895, lng: 17.508839 },
    { lat: 48.211212, lng: 17.506984 },
    { lat: 48.210667, lng: 17.506394 },
    { lat: 48.210057, lng: 17.505728 },
    { lat: 48.209532, lng: 17.505163 },
    { lat: 48.209022, lng: 17.504621 },
    { lat: 48.208929, lng: 17.504522 },
    { lat: 48.208855, lng: 17.504442 },
    { lat: 48.208648, lng: 17.504218 },
    { lat: 48.207958, lng: 17.503525 },
    { lat: 48.207868, lng: 17.503435 },
    { lat: 48.207869, lng: 17.503434 },
    { lat: 48.207216, lng: 17.502785 },
    { lat: 48.206572, lng: 17.502094 },
    { lat: 48.205829, lng: 17.501308 },
    { lat: 48.205658, lng: 17.501124 },
    { lat: 48.205539, lng: 17.500998 },
    { lat: 48.2054, lng: 17.500851 },
    { lat: 48.205388, lng: 17.500835 },
    { lat: 48.205313, lng: 17.50074 },
    { lat: 48.205202, lng: 17.500985 },
    { lat: 48.20509, lng: 17.501336 },
    { lat: 48.205015, lng: 17.501511 },
    { lat: 48.204901, lng: 17.501706 },
    { lat: 48.204562, lng: 17.502236 },
    { lat: 48.204516, lng: 17.502331 },
    { lat: 48.204102, lng: 17.50272 },
    { lat: 48.203726, lng: 17.502989 },
    { lat: 48.203489, lng: 17.503084 },
    { lat: 48.203279, lng: 17.50319 },
    { lat: 48.202909, lng: 17.503364 },
    { lat: 48.202743, lng: 17.50342 },
    { lat: 48.202632, lng: 17.503455 },
    { lat: 48.202499, lng: 17.503472 },
    { lat: 48.202256, lng: 17.503488 },
    { lat: 48.202039, lng: 17.503511 },
    { lat: 48.201935, lng: 17.503503 },
    { lat: 48.20185, lng: 17.503501 },
    { lat: 48.20169, lng: 17.503514 },
    { lat: 48.201602, lng: 17.503534 },
    { lat: 48.201481, lng: 17.503573 },
    { lat: 48.201357, lng: 17.503626 },
    { lat: 48.20106, lng: 17.503831 },
    { lat: 48.200814, lng: 17.504075 },
    { lat: 48.200758, lng: 17.50414 },
    { lat: 48.20054, lng: 17.504454 },
    { lat: 48.200327, lng: 17.504919 },
    { lat: 48.200177, lng: 17.505335 },
    { lat: 48.200071, lng: 17.505796 },
    { lat: 48.200011, lng: 17.506055 },
    { lat: 48.19999, lng: 17.506242 },
    { lat: 48.199964, lng: 17.506573 },
    { lat: 48.199927, lng: 17.507184 },
    { lat: 48.199923, lng: 17.507445 },
    { lat: 48.199921, lng: 17.507783 },
    { lat: 48.199912, lng: 17.508292 },
    { lat: 48.199896, lng: 17.508574 },
    { lat: 48.19985, lng: 17.508922 },
    { lat: 48.199768, lng: 17.509461 },
    { lat: 48.199751, lng: 17.509531 },
    { lat: 48.199757, lng: 17.510007 },
    { lat: 48.199782, lng: 17.510405 },
    { lat: 48.199796, lng: 17.510777 },
    { lat: 48.199797, lng: 17.511161 },
    { lat: 48.199796, lng: 17.511587 },
    { lat: 48.199755, lng: 17.512129 },
    { lat: 48.199743, lng: 17.512217 },
    { lat: 48.199629, lng: 17.512621 },
    { lat: 48.199575, lng: 17.512769 },
    { lat: 48.199495, lng: 17.512915 },
    { lat: 48.199378, lng: 17.513201 },
    { lat: 48.199304, lng: 17.513374 },
    { lat: 48.199196, lng: 17.513616 },
    { lat: 48.19914, lng: 17.513749 },
    { lat: 48.199072, lng: 17.513956 },
    { lat: 48.19904, lng: 17.514119 },
    { lat: 48.199024, lng: 17.514227 },
    { lat: 48.19901, lng: 17.514397 },
    { lat: 48.199005, lng: 17.514578 },
    { lat: 48.198999, lng: 17.51478 },
    { lat: 48.199037, lng: 17.514901 },
    { lat: 48.199096, lng: 17.515318 },
    { lat: 48.199132, lng: 17.515576 },
    { lat: 48.199158, lng: 17.515714 },
    { lat: 48.199186, lng: 17.515851 },
    { lat: 48.199247, lng: 17.516155 },
    { lat: 48.19928, lng: 17.516271 },
    { lat: 48.199316, lng: 17.516406 },
    { lat: 48.199355, lng: 17.516548 },
    { lat: 48.199407, lng: 17.516733 },
    { lat: 48.199438, lng: 17.516812 },
    { lat: 48.199487, lng: 17.516942 },
    { lat: 48.199512, lng: 17.516997 },
    { lat: 48.199533, lng: 17.517021 },
    { lat: 48.19963, lng: 17.517165 },
    { lat: 48.19972, lng: 17.517297 },
    { lat: 48.199857, lng: 17.517503 },
    { lat: 48.199894, lng: 17.517538 },
    { lat: 48.199986, lng: 17.517649 },
    { lat: 48.200118, lng: 17.517812 },
    { lat: 48.200161, lng: 17.517854 },
    { lat: 48.200193, lng: 17.517882 },
    { lat: 48.200261, lng: 17.517928 },
    { lat: 48.20029, lng: 17.517947 },
    { lat: 48.200317, lng: 17.517968 },
    { lat: 48.200367, lng: 17.518011 },
    { lat: 48.200437, lng: 17.518055 },
    { lat: 48.200467, lng: 17.518077 },
    { lat: 48.200501, lng: 17.51809 },
    { lat: 48.200565, lng: 17.518117 },
    { lat: 48.200601, lng: 17.518134 },
    { lat: 48.200655, lng: 17.518157 },
    { lat: 48.200781, lng: 17.518204 },
    { lat: 48.200921, lng: 17.518266 },
    { lat: 48.201022, lng: 17.518316 },
    { lat: 48.201112, lng: 17.518358 },
    { lat: 48.201171, lng: 17.518386 },
    { lat: 48.201209, lng: 17.518408 },
    { lat: 48.201226, lng: 17.518413 },
    { lat: 48.201244, lng: 17.518413 },
    { lat: 48.201287, lng: 17.518423 },
    { lat: 48.201319, lng: 17.518432 },
    { lat: 48.201377, lng: 17.518445 },
    { lat: 48.201489, lng: 17.518452 },
    { lat: 48.20155, lng: 17.518465 },
    { lat: 48.201708, lng: 17.51849 },
    { lat: 48.2019, lng: 17.518518 },
    { lat: 48.202079, lng: 17.518563 },
    { lat: 48.202239, lng: 17.51864 },
    { lat: 48.202294, lng: 17.518678 },
    { lat: 48.202383, lng: 17.518776 },
    { lat: 48.202486, lng: 17.518905 },
    { lat: 48.202586, lng: 17.519032 },
    { lat: 48.202676, lng: 17.519196 },
    { lat: 48.202744, lng: 17.519367 },
    { lat: 48.202865, lng: 17.519659 },
    { lat: 48.202952, lng: 17.519889 },
    { lat: 48.203027, lng: 17.520195 },
    { lat: 48.203055, lng: 17.520337 },
    { lat: 48.203078, lng: 17.52053 },
    { lat: 48.20309, lng: 17.520749 },
    { lat: 48.203096, lng: 17.521047 },
    { lat: 48.2031, lng: 17.521153 },
    { lat: 48.203079, lng: 17.521303 },
    { lat: 48.203033, lng: 17.521593 },
    { lat: 48.202971, lng: 17.521855 },
    { lat: 48.20296, lng: 17.521909 },
    { lat: 48.202942, lng: 17.521954 },
    { lat: 48.202845, lng: 17.522146 },
    { lat: 48.202757, lng: 17.52231 },
    { lat: 48.202679, lng: 17.52243 },
    { lat: 48.202587, lng: 17.52258 },
    { lat: 48.202562, lng: 17.522624 },
    { lat: 48.202516, lng: 17.522642 },
    { lat: 48.202396, lng: 17.522712 },
    { lat: 48.202299, lng: 17.522778 },
    { lat: 48.202236, lng: 17.522837 },
    { lat: 48.202192, lng: 17.522881 },
    { lat: 48.202154, lng: 17.522932 },
    { lat: 48.202097, lng: 17.523019 },
    { lat: 48.202037, lng: 17.523102 },
    { lat: 48.202016, lng: 17.523136 },
    { lat: 48.201998, lng: 17.52315 },
    { lat: 48.20193, lng: 17.523194 },
    { lat: 48.201855, lng: 17.523244 },
    { lat: 48.201754, lng: 17.523311 },
    { lat: 48.201602, lng: 17.523406 },
    { lat: 48.201341, lng: 17.523568 },
    { lat: 48.20131, lng: 17.523589 },
    { lat: 48.201255, lng: 17.523624 },
    { lat: 48.201234, lng: 17.52364 },
    { lat: 48.201226, lng: 17.523645 },
    { lat: 48.201168, lng: 17.523652 },
    { lat: 48.201018, lng: 17.523671 },
    { lat: 48.200634, lng: 17.523716 },
    { lat: 48.200498, lng: 17.52371 },
    { lat: 48.200436, lng: 17.52371 },
    { lat: 48.200347, lng: 17.523731 },
    { lat: 48.200312, lng: 17.523739 },
    { lat: 48.200282, lng: 17.523748 },
    { lat: 48.200174, lng: 17.523754 },
    { lat: 48.200138, lng: 17.523753 },
    { lat: 48.200043, lng: 17.523731 },
    { lat: 48.199993, lng: 17.523721 },
    { lat: 48.19994, lng: 17.523717 },
    { lat: 48.199614, lng: 17.523719 },
    { lat: 48.199498, lng: 17.523706 },
    { lat: 48.19936, lng: 17.523723 },
    { lat: 48.198892, lng: 17.5238 },
    { lat: 48.198748, lng: 17.523829 },
    { lat: 48.198622, lng: 17.523896 },
    { lat: 48.198478, lng: 17.523973 },
    { lat: 48.19821, lng: 17.524121 },
    { lat: 48.198115, lng: 17.524185 },
    { lat: 48.198053, lng: 17.524235 },
    { lat: 48.197947, lng: 17.524314 },
    { lat: 48.197884, lng: 17.524363 },
    { lat: 48.197782, lng: 17.524444 },
    { lat: 48.197662, lng: 17.524541 },
    { lat: 48.19764, lng: 17.524558 },
    { lat: 48.197556, lng: 17.524646 },
    { lat: 48.197515, lng: 17.524695 },
    { lat: 48.197469, lng: 17.524753 },
    { lat: 48.197432, lng: 17.524791 },
    { lat: 48.19739, lng: 17.52485 },
    { lat: 48.197347, lng: 17.524918 },
    { lat: 48.197306, lng: 17.524986 },
    { lat: 48.197249, lng: 17.525075 },
    { lat: 48.197178, lng: 17.525212 },
    { lat: 48.197136, lng: 17.525294 },
    { lat: 48.197116, lng: 17.525355 },
    { lat: 48.197095, lng: 17.525429 },
    { lat: 48.197049, lng: 17.525567 },
    { lat: 48.197031, lng: 17.525697 },
    { lat: 48.197017, lng: 17.525838 },
    { lat: 48.196998, lng: 17.526072 },
    { lat: 48.196977, lng: 17.526306 },
    { lat: 48.196957, lng: 17.526462 },
    { lat: 48.196945, lng: 17.526655 },
    { lat: 48.196931, lng: 17.526996 },
    { lat: 48.196933, lng: 17.527377 },
    { lat: 48.196931, lng: 17.527527 },
    { lat: 48.19692, lng: 17.527657 },
    { lat: 48.196916, lng: 17.527804 },
    { lat: 48.196902, lng: 17.528042 },
    { lat: 48.196893, lng: 17.528138 },
    { lat: 48.196854, lng: 17.528351 },
    { lat: 48.196839, lng: 17.528437 },
    { lat: 48.196814, lng: 17.528543 },
    { lat: 48.196765, lng: 17.528658 },
    { lat: 48.196701, lng: 17.528804 },
    { lat: 48.196594, lng: 17.529028 },
    { lat: 48.196528, lng: 17.529144 },
    { lat: 48.196497, lng: 17.529199 },
    { lat: 48.195892, lng: 17.5302054 },
    { lat: 48.195721, lng: 17.5307983 },
    { lat: 48.1957129, lng: 17.5312887 },
    { lat: 48.1958154, lng: 17.5318845 },
    { lat: 48.1959643, lng: 17.5323573 },
    { lat: 48.1963424, lng: 17.5330447 },
    { lat: 48.1977873, lng: 17.5353099 },
    { lat: 48.1984238, lng: 17.5362194 },
    { lat: 48.1986822, lng: 17.5368178 },
    { lat: 48.1987769, lng: 17.5372552 },
    { lat: 48.1987505, lng: 17.5374261 },
    { lat: 48.198417, lng: 17.5380734 },
    { lat: 48.1980062, lng: 17.5384143 },
    { lat: 48.1977788, lng: 17.5384437 },
    { lat: 48.1972718, lng: 17.5383389 },
    { lat: 48.1956416, lng: 17.5374109 },
    { lat: 48.1953628, lng: 17.537466 },
    { lat: 48.1951792, lng: 17.5377184 },
    { lat: 48.1951094, lng: 17.5381287 },
    { lat: 48.1953244, lng: 17.5389814 },
    { lat: 48.1957205, lng: 17.5397017 },
    { lat: 48.1960383, lng: 17.5401351 },
    { lat: 48.1966777, lng: 17.5421854 },
    { lat: 48.1968132, lng: 17.5428053 },
    { lat: 48.1970006, lng: 17.5443571 },
    { lat: 48.1969988, lng: 17.5448954 },
    { lat: 48.19691, lng: 17.5456599 },
    { lat: 48.1968098, lng: 17.5459669 },
    { lat: 48.1966333, lng: 17.5462962 },
    { lat: 48.196537, lng: 17.5466455 },
    { lat: 48.1964393, lng: 17.5474162 },
    { lat: 48.1964739, lng: 17.5476721 },
    { lat: 48.1966889, lng: 17.5482637 },
    { lat: 48.1971222, lng: 17.549179 },
    { lat: 48.197188, lng: 17.5494645 },
    { lat: 48.1971876, lng: 17.5500439 },
    { lat: 48.1970705, lng: 17.550455 },
    { lat: 48.1966468, lng: 17.5508755 },
    { lat: 48.1964763, lng: 17.5509115 },
    { lat: 48.196208, lng: 17.5511307 },
    { lat: 48.1959091, lng: 17.5515415 },
    { lat: 48.1958393, lng: 17.5518311 },
    { lat: 48.1958574, lng: 17.5521662 },
    { lat: 48.1960738, lng: 17.5527097 },
    { lat: 48.1965091, lng: 17.5532294 },
    { lat: 48.1970765, lng: 17.5537512 },
    { lat: 48.1981815, lng: 17.5538833 },
    { lat: 48.198668, lng: 17.5544286 },
    { lat: 48.1989816, lng: 17.5549513 },
    { lat: 48.1991529, lng: 17.5554624 },
    { lat: 48.1991451, lng: 17.5556567 },
    { lat: 48.1993369, lng: 17.5558658 },
    { lat: 48.1995719, lng: 17.5566298 },
    { lat: 48.1996156, lng: 17.5571555 },
    { lat: 48.1995027, lng: 17.5575111 },
    { lat: 48.1992049, lng: 17.5576729 },
    { lat: 48.1990482, lng: 17.55768 },
    { lat: 48.1985144, lng: 17.5575063 },
    { lat: 48.1978018, lng: 17.5567819 },
    { lat: 48.1966536, lng: 17.5559544 },
    { lat: 48.195976, lng: 17.5552293 },
    { lat: 48.1956101, lng: 17.5550511 },
    { lat: 48.1954872, lng: 17.5550339 },
    { lat: 48.1953861, lng: 17.5550965 },
    { lat: 48.1952964, lng: 17.5552477 },
    { lat: 48.1952199, lng: 17.555626 },
    { lat: 48.1952141, lng: 17.556065 },
    { lat: 48.1953826, lng: 17.5568104 },
    { lat: 48.1955109, lng: 17.5570894 },
    { lat: 48.1958718, lng: 17.5577019 },
    { lat: 48.1960746, lng: 17.5579059 },
    { lat: 48.1963061, lng: 17.5581178 },
    { lat: 48.1966788, lng: 17.5583145 },
    { lat: 48.1970522, lng: 17.5584293 },
    { lat: 48.1979544, lng: 17.558554 },
    { lat: 48.1987814, lng: 17.558843 },
    { lat: 48.1990004, lng: 17.5589817 },
    { lat: 48.1995614, lng: 17.5595635 },
    { lat: 48.1997766, lng: 17.5599275 },
    { lat: 48.1999984, lng: 17.5604491 },
    { lat: 48.2000121, lng: 17.5608238 },
    { lat: 48.1998351, lng: 17.5615736 },
    { lat: 48.1992244, lng: 17.5626387 },
    { lat: 48.1985359, lng: 17.5635096 },
    { lat: 48.1976175, lng: 17.5641963 },
    { lat: 48.1965042, lng: 17.5645915 },
    { lat: 48.1961838, lng: 17.5649483 },
    { lat: 48.1961201, lng: 17.5651248 },
    { lat: 48.1960361, lng: 17.5654312 },
    { lat: 48.196124, lng: 17.5668097 },
    { lat: 48.1959155, lng: 17.5672252 },
    { lat: 48.1955806, lng: 17.5673033 },
    { lat: 48.1951422, lng: 17.5670515 },
    { lat: 48.1949534, lng: 17.5666774 },
    { lat: 48.1948434, lng: 17.5663325 },
    { lat: 48.1944438, lng: 17.564544 },
    { lat: 48.1942302, lng: 17.5639211 },
    { lat: 48.193906, lng: 17.563583 },
    { lat: 48.1937178, lng: 17.5635185 },
    { lat: 48.193447, lng: 17.5636668 },
    { lat: 48.1932123, lng: 17.5640567 },
    { lat: 48.1929621, lng: 17.5650586 },
    { lat: 48.1929495, lng: 17.5654985 },
    { lat: 48.1930556, lng: 17.5664726 },
    { lat: 48.1929975, lng: 17.5670135 },
    { lat: 48.1928719, lng: 17.5672562 },
    { lat: 48.1928053, lng: 17.5673096 },
    { lat: 48.1938578, lng: 17.5678766 },
    { lat: 48.1956456, lng: 17.5686978 },
    { lat: 48.1968915, lng: 17.5688951 },
    { lat: 48.1979543, lng: 17.5695535 },
    { lat: 48.1986359, lng: 17.5701685 },
    { lat: 48.1992497, lng: 17.5708325 },
    { lat: 48.2004186, lng: 17.5722886 },
    { lat: 48.2011711, lng: 17.5730589 },
    { lat: 48.2030742, lng: 17.5756732 },
    { lat: 48.2066344, lng: 17.5798045 },
    { lat: 48.2089123, lng: 17.5823136 },
    { lat: 48.2107085, lng: 17.5869271 },
    { lat: 48.2125186, lng: 17.5904991 },
    { lat: 48.2134253, lng: 17.5912285 },
    { lat: 48.2134714, lng: 17.5912779 },
    { lat: 48.2145099, lng: 17.5923925 },
    { lat: 48.2157431, lng: 17.5935132 },
    { lat: 48.2187559, lng: 17.5967889 },
    { lat: 48.2207375, lng: 17.5992461 },
    { lat: 48.2211309, lng: 17.5996984 },
    { lat: 48.222727, lng: 17.6015339 },
    { lat: 48.2229822, lng: 17.6018273 },
    { lat: 48.2283278, lng: 17.6072245 },
    { lat: 48.2284099, lng: 17.6072907 },
    { lat: 48.2287224, lng: 17.6070013 },
    { lat: 48.2298103, lng: 17.6057077 },
    { lat: 48.2319469, lng: 17.6027834 },
    { lat: 48.2327257, lng: 17.6020109 },
    { lat: 48.2335415, lng: 17.6014676 },
    { lat: 48.2346063, lng: 17.600505 },
    { lat: 48.2375647, lng: 17.5961954 },
    { lat: 48.2399799, lng: 17.5942999 },
    { lat: 48.2427696, lng: 17.5913355 },
    { lat: 48.2460832, lng: 17.5878143 },
    { lat: 48.2470205, lng: 17.5871624 },
    { lat: 48.2477335, lng: 17.5866665 },
    { lat: 48.2489853, lng: 17.585467 },
    { lat: 48.250873, lng: 17.583143 },
    { lat: 48.250749, lng: 17.58275 },
    { lat: 48.250792, lng: 17.582247 },
    { lat: 48.250557, lng: 17.581662 },
    { lat: 48.250181, lng: 17.58111 },
    { lat: 48.250236, lng: 17.580442 },
    { lat: 48.250221, lng: 17.579908 },
    { lat: 48.249887, lng: 17.57785 },
    { lat: 48.24988, lng: 17.577362 },
    { lat: 48.250042, lng: 17.576835 },
    { lat: 48.248002, lng: 17.574225 },
    { lat: 48.24817, lng: 17.573954 },
    { lat: 48.246233, lng: 17.571402 },
    { lat: 48.24504, lng: 17.569708 },
    { lat: 48.241757, lng: 17.564641 },
    { lat: 48.239763, lng: 17.561468 },
    { lat: 48.2393301, lng: 17.5609627 },
    { lat: 48.2377648, lng: 17.5595964 },
    { lat: 48.2345492, lng: 17.5574627 },
    { lat: 48.2342877, lng: 17.5572397 },
    { lat: 48.2339567, lng: 17.5567154 },
    { lat: 48.23231, lng: 17.5539058 },
    { lat: 48.2315132, lng: 17.5527265 },
    { lat: 48.2314811, lng: 17.552753 },
    { lat: 48.2302004, lng: 17.5506806 },
    { lat: 48.2277717, lng: 17.5470253 },
    { lat: 48.2259013, lng: 17.5444241 },
    { lat: 48.2237406, lng: 17.5418186 },
    { lat: 48.2223787, lng: 17.5402992 },
    { lat: 48.2216221, lng: 17.5395774 },
    { lat: 48.2200027, lng: 17.5381803 },
    { lat: 48.2193083, lng: 17.5374762 },
    { lat: 48.2194902, lng: 17.5368949 },
    { lat: 48.2203017, lng: 17.5347676 },
    { lat: 48.2205055, lng: 17.5336654 },
    { lat: 48.220845, lng: 17.5324585 },
    { lat: 48.2210314, lng: 17.5320691 },
    { lat: 48.2211803, lng: 17.5315747 },
    { lat: 48.2228555, lng: 17.5276108 },
    { lat: 48.2234033, lng: 17.5266051 },
    { lat: 48.2242608, lng: 17.5248293 },
    { lat: 48.2243223, lng: 17.5248729 },
    { lat: 48.2254176, lng: 17.5214691 },
    { lat: 48.225548, lng: 17.520557 },
  ],
  ČiernyBrod: [
    { lat: 48.097136, lng: 17.590793 },
    { lat: 48.096631, lng: 17.590946 },
    { lat: 48.096168, lng: 17.591058 },
    { lat: 48.095613, lng: 17.591217 },
    { lat: 48.095364, lng: 17.591249 },
    { lat: 48.094415, lng: 17.591461 },
    { lat: 48.093743, lng: 17.591516 },
    { lat: 48.093405, lng: 17.59159 },
    { lat: 48.093174, lng: 17.591682 },
    { lat: 48.092766, lng: 17.591955 },
    { lat: 48.092203, lng: 17.592426 },
    { lat: 48.091722, lng: 17.593036 },
    { lat: 48.091364, lng: 17.593395 },
    { lat: 48.091698, lng: 17.594042 },
    { lat: 48.091812, lng: 17.594263 },
    { lat: 48.092159, lng: 17.594619 },
    { lat: 48.092517, lng: 17.594757 },
    { lat: 48.09257, lng: 17.594777 },
    { lat: 48.0926, lng: 17.594788 },
    { lat: 48.093031, lng: 17.594947 },
    { lat: 48.093475, lng: 17.595116 },
    { lat: 48.093786, lng: 17.595245 },
    { lat: 48.093914, lng: 17.595297 },
    { lat: 48.094137, lng: 17.59539 },
    { lat: 48.094168, lng: 17.595402 },
    { lat: 48.094182, lng: 17.595408 },
    { lat: 48.09465, lng: 17.595577 },
    { lat: 48.094997, lng: 17.595698 },
    { lat: 48.095362, lng: 17.595827 },
    { lat: 48.095592, lng: 17.595895 },
    { lat: 48.095647, lng: 17.595911 },
    { lat: 48.095907, lng: 17.596 },
    { lat: 48.095921, lng: 17.596002 },
    { lat: 48.09622, lng: 17.596024 },
    { lat: 48.096474, lng: 17.596045 },
    { lat: 48.096481, lng: 17.596044 },
    { lat: 48.096496, lng: 17.596045 },
    { lat: 48.096699, lng: 17.596052 },
    { lat: 48.097242, lng: 17.596064 },
    { lat: 48.097648, lng: 17.596158 },
    { lat: 48.097719, lng: 17.596174 },
    { lat: 48.097901, lng: 17.596223 },
    { lat: 48.097986, lng: 17.596252 },
    { lat: 48.098055, lng: 17.596276 },
    { lat: 48.098114, lng: 17.596295 },
    { lat: 48.098156, lng: 17.59631 },
    { lat: 48.098241, lng: 17.596342 },
    { lat: 48.098403, lng: 17.596415 },
    { lat: 48.098544, lng: 17.596492 },
    { lat: 48.098757, lng: 17.596509 },
    { lat: 48.098838, lng: 17.596529 },
    { lat: 48.098884, lng: 17.596543 },
    { lat: 48.098898, lng: 17.596555 },
    { lat: 48.098977, lng: 17.596635 },
    { lat: 48.099098, lng: 17.59678 },
    { lat: 48.099136, lng: 17.596828 },
    { lat: 48.099184, lng: 17.596914 },
    { lat: 48.099211, lng: 17.596967 },
    { lat: 48.09928, lng: 17.59711 },
    { lat: 48.099357, lng: 17.597291 },
    { lat: 48.09942, lng: 17.597487 },
    { lat: 48.099429, lng: 17.597595 },
    { lat: 48.099439, lng: 17.597715 },
    { lat: 48.099437, lng: 17.597985 },
    { lat: 48.099452, lng: 17.598145 },
    { lat: 48.099476, lng: 17.598317 },
    { lat: 48.099492, lng: 17.598409 },
    { lat: 48.099516, lng: 17.598509 },
    { lat: 48.099541, lng: 17.598616 },
    { lat: 48.099584, lng: 17.598697 },
    { lat: 48.099651, lng: 17.598704 },
    { lat: 48.099686, lng: 17.598697 },
    { lat: 48.099728, lng: 17.598676 },
    { lat: 48.099823, lng: 17.598647 },
    { lat: 48.099963, lng: 17.598576 },
    { lat: 48.100333, lng: 17.598693 },
    { lat: 48.100472, lng: 17.598729 },
    { lat: 48.10058, lng: 17.598694 },
    { lat: 48.10082, lng: 17.59854 },
    { lat: 48.101166, lng: 17.59816 },
    { lat: 48.10127, lng: 17.598101 },
    { lat: 48.101336, lng: 17.598068 },
    { lat: 48.101486, lng: 17.598045 },
    { lat: 48.10175, lng: 17.598098 },
    { lat: 48.101958, lng: 17.598137 },
    { lat: 48.102111, lng: 17.598211 },
    { lat: 48.102286, lng: 17.598312 },
    { lat: 48.102342, lng: 17.598427 },
    { lat: 48.10246, lng: 17.598751 },
    { lat: 48.102515, lng: 17.59895 },
    { lat: 48.10254, lng: 17.599037 },
    { lat: 48.102577, lng: 17.599191 },
    { lat: 48.102617, lng: 17.599499 },
    { lat: 48.102712, lng: 17.599872 },
    { lat: 48.102864, lng: 17.600388 },
    { lat: 48.102999, lng: 17.600966 },
    { lat: 48.103118, lng: 17.601379 },
    { lat: 48.103226, lng: 17.601901 },
    { lat: 48.10328, lng: 17.602103 },
    { lat: 48.103339, lng: 17.602336 },
    { lat: 48.103397, lng: 17.602507 },
    { lat: 48.103511, lng: 17.602821 },
    { lat: 48.10355, lng: 17.602923 },
    { lat: 48.103657, lng: 17.603183 },
    { lat: 48.103758, lng: 17.60348 },
    { lat: 48.103785, lng: 17.603577 },
    { lat: 48.103822, lng: 17.60366 },
    { lat: 48.103908, lng: 17.603773 },
    { lat: 48.104013, lng: 17.603828 },
    { lat: 48.104116, lng: 17.603872 },
    { lat: 48.104203, lng: 17.603944 },
    { lat: 48.104365, lng: 17.604043 },
    { lat: 48.104528, lng: 17.604031 },
    { lat: 48.104651, lng: 17.604005 },
    { lat: 48.105672, lng: 17.603794 },
    { lat: 48.105869, lng: 17.60372 },
    { lat: 48.106225, lng: 17.603596 },
    { lat: 48.106361, lng: 17.603565 },
    { lat: 48.106702, lng: 17.603557 },
    { lat: 48.10696, lng: 17.603502 },
    { lat: 48.107277, lng: 17.60336 },
    { lat: 48.10787, lng: 17.603148 },
    { lat: 48.108058, lng: 17.603141 },
    { lat: 48.108354, lng: 17.603087 },
    { lat: 48.108566, lng: 17.603026 },
    { lat: 48.108762, lng: 17.603035 },
    { lat: 48.109099, lng: 17.603229 },
    { lat: 48.109242, lng: 17.603396 },
    { lat: 48.109526, lng: 17.603801 },
    { lat: 48.109873, lng: 17.604152 },
    { lat: 48.110098, lng: 17.604392 },
    { lat: 48.110284, lng: 17.60464 },
    { lat: 48.110482, lng: 17.604941 },
    { lat: 48.110514, lng: 17.60499 },
    { lat: 48.110661, lng: 17.605225 },
    { lat: 48.110731, lng: 17.60534 },
    { lat: 48.11075, lng: 17.60538 },
    { lat: 48.110795, lng: 17.605483 },
    { lat: 48.110851, lng: 17.605632 },
    { lat: 48.11087, lng: 17.605699 },
    { lat: 48.11101, lng: 17.606248 },
    { lat: 48.111121, lng: 17.60654 },
    { lat: 48.111187, lng: 17.606688 },
    { lat: 48.111274, lng: 17.606915 },
    { lat: 48.111322, lng: 17.607111 },
    { lat: 48.111376, lng: 17.607426 },
    { lat: 48.111415, lng: 17.607981 },
    { lat: 48.111437, lng: 17.608194 },
    { lat: 48.111552, lng: 17.608866 },
    { lat: 48.111565, lng: 17.609105 },
    { lat: 48.111598, lng: 17.609415 },
    { lat: 48.111631, lng: 17.609955 },
    { lat: 48.111612, lng: 17.610311 },
    { lat: 48.111582, lng: 17.610675 },
    { lat: 48.111546, lng: 17.611008 },
    { lat: 48.111501, lng: 17.611287 },
    { lat: 48.111394, lng: 17.61166 },
    { lat: 48.111284, lng: 17.61186 },
    { lat: 48.11087, lng: 17.612158 },
    { lat: 48.110675, lng: 17.612401 },
    { lat: 48.110605, lng: 17.612502 },
    { lat: 48.110416, lng: 17.612782 },
    { lat: 48.110057, lng: 17.613342 },
    { lat: 48.110042, lng: 17.613367 },
    { lat: 48.109756, lng: 17.613924 },
    { lat: 48.10961, lng: 17.614153 },
    { lat: 48.109445, lng: 17.614337 },
    { lat: 48.109265, lng: 17.61457 },
    { lat: 48.108956, lng: 17.615027 },
    { lat: 48.108738, lng: 17.615292 },
    { lat: 48.108398, lng: 17.615586 },
    { lat: 48.108022, lng: 17.615951 },
    { lat: 48.107786, lng: 17.616148 },
    { lat: 48.107317, lng: 17.61643 },
    { lat: 48.107245, lng: 17.616484 },
    { lat: 48.107213, lng: 17.616508 },
    { lat: 48.106903, lng: 17.616741 },
    { lat: 48.106547, lng: 17.616943 },
    { lat: 48.106112, lng: 17.617216 },
    { lat: 48.105072, lng: 17.617723 },
    { lat: 48.104943, lng: 17.617788 },
    { lat: 48.10414, lng: 17.618246 },
    { lat: 48.103757, lng: 17.618509 },
    { lat: 48.103188, lng: 17.618343 },
    { lat: 48.102886, lng: 17.618201 },
    { lat: 48.102762, lng: 17.618153 },
    { lat: 48.102722, lng: 17.618139 },
    { lat: 48.102481, lng: 17.618089 },
    { lat: 48.102226, lng: 17.618002 },
    { lat: 48.101944, lng: 17.617937 },
    { lat: 48.101725, lng: 17.617852 },
    { lat: 48.101642, lng: 17.617809 },
    { lat: 48.101497, lng: 17.61773 },
    { lat: 48.1011828, lng: 17.6182229 },
    { lat: 48.1027307, lng: 17.6191117 },
    { lat: 48.1034523, lng: 17.6197001 },
    { lat: 48.1041983, lng: 17.6201586 },
    { lat: 48.1050123, lng: 17.6203153 },
    { lat: 48.1081967, lng: 17.621335 },
    { lat: 48.1105935, lng: 17.6217163 },
    { lat: 48.1121716, lng: 17.6221033 },
    { lat: 48.1129551, lng: 17.6224022 },
    { lat: 48.1136152, lng: 17.6229842 },
    { lat: 48.1142172, lng: 17.6238084 },
    { lat: 48.1147555, lng: 17.6246942 },
    { lat: 48.1153488, lng: 17.6252006 },
    { lat: 48.1161261, lng: 17.6255471 },
    { lat: 48.1167672, lng: 17.6265587 },
    { lat: 48.1175869, lng: 17.6275777 },
    { lat: 48.1178124, lng: 17.6279279 },
    { lat: 48.1181027, lng: 17.6289647 },
    { lat: 48.1187503, lng: 17.6331887 },
    { lat: 48.1186904, lng: 17.6343304 },
    { lat: 48.1188632, lng: 17.636046 },
    { lat: 48.1190129, lng: 17.636037 },
    { lat: 48.1193, lng: 17.635881 },
    { lat: 48.1196845, lng: 17.6354465 },
    { lat: 48.119895, lng: 17.6344875 },
    { lat: 48.1199769, lng: 17.6338165 },
    { lat: 48.1200838, lng: 17.6334576 },
    { lat: 48.120177, lng: 17.6333215 },
    { lat: 48.1205102, lng: 17.633071 },
    { lat: 48.1207167, lng: 17.6329991 },
    { lat: 48.1212258, lng: 17.6331033 },
    { lat: 48.1218306, lng: 17.6329717 },
    { lat: 48.122165, lng: 17.6325984 },
    { lat: 48.1222849, lng: 17.6323875 },
    { lat: 48.1226039, lng: 17.6312724 },
    { lat: 48.1227115, lng: 17.6306359 },
    { lat: 48.1227541, lng: 17.6298616 },
    { lat: 48.1226958, lng: 17.6292609 },
    { lat: 48.1223025, lng: 17.6282396 },
    { lat: 48.1220501, lng: 17.6277837 },
    { lat: 48.1215433, lng: 17.6270703 },
    { lat: 48.1208231, lng: 17.62638 },
    { lat: 48.1206143, lng: 17.6263444 },
    { lat: 48.120195, lng: 17.6266054 },
    { lat: 48.1195692, lng: 17.6272096 },
    { lat: 48.1192678, lng: 17.6273373 },
    { lat: 48.1190085, lng: 17.6273071 },
    { lat: 48.1186498, lng: 17.6271351 },
    { lat: 48.1183432, lng: 17.6268741 },
    { lat: 48.1182195, lng: 17.6266891 },
    { lat: 48.1179233, lng: 17.6259333 },
    { lat: 48.117729, lng: 17.6251109 },
    { lat: 48.117909, lng: 17.6241007 },
    { lat: 48.1178362, lng: 17.6233084 },
    { lat: 48.1179253, lng: 17.6230563 },
    { lat: 48.1180213, lng: 17.622895 },
    { lat: 48.1185032, lng: 17.6225167 },
    { lat: 48.1187506, lng: 17.62241 },
    { lat: 48.119282, lng: 17.622529 },
    { lat: 48.1199143, lng: 17.6225638 },
    { lat: 48.1206088, lng: 17.62226 },
    { lat: 48.1206984, lng: 17.6219827 },
    { lat: 48.1205367, lng: 17.6216935 },
    { lat: 48.1199112, lng: 17.6214072 },
    { lat: 48.1197169, lng: 17.621176 },
    { lat: 48.1194444, lng: 17.6206883 },
    { lat: 48.1192645, lng: 17.6194539 },
    { lat: 48.1193007, lng: 17.6190088 },
    { lat: 48.1188393, lng: 17.6174997 },
    { lat: 48.1184316, lng: 17.617982 },
    { lat: 48.117878, lng: 17.6189398 },
    { lat: 48.1174208, lng: 17.6192786 },
    { lat: 48.1169911, lng: 17.6192524 },
    { lat: 48.1167202, lng: 17.6189136 },
    { lat: 48.1166099, lng: 17.6185794 },
    { lat: 48.1166267, lng: 17.618259 },
    { lat: 48.1168274, lng: 17.6177737 },
    { lat: 48.1173571, lng: 17.6172042 },
    { lat: 48.1175406, lng: 17.6167658 },
    { lat: 48.1175637, lng: 17.6156512 },
    { lat: 48.117614, lng: 17.6153406 },
    { lat: 48.1177348, lng: 17.615144 },
    { lat: 48.117977, lng: 17.6149232 },
    { lat: 48.1181509, lng: 17.6148505 },
    { lat: 48.1184681, lng: 17.6148495 },
    { lat: 48.1186991, lng: 17.6150218 },
    { lat: 48.1188496, lng: 17.6152055 },
    { lat: 48.1192718, lng: 17.6163077 },
    { lat: 48.1203204, lng: 17.6174607 },
    { lat: 48.1203552, lng: 17.618417 },
    { lat: 48.120919, lng: 17.6178889 },
    { lat: 48.1210252, lng: 17.6177128 },
    { lat: 48.1217352, lng: 17.6161391 },
    { lat: 48.1223688, lng: 17.6150699 },
    { lat: 48.1230036, lng: 17.6134898 },
    { lat: 48.1240489, lng: 17.6118976 },
    { lat: 48.1242648, lng: 17.6114734 },
    { lat: 48.1243836, lng: 17.6110678 },
    { lat: 48.1243521, lng: 17.6106227 },
    { lat: 48.1242874, lng: 17.610475 },
    { lat: 48.1240112, lng: 17.6103405 },
    { lat: 48.1230633, lng: 17.6103329 },
    { lat: 48.1227558, lng: 17.6103976 },
    { lat: 48.1219722, lng: 17.609885 },
    { lat: 48.1216212, lng: 17.6095141 },
    { lat: 48.1214985, lng: 17.6093071 },
    { lat: 48.1213476, lng: 17.6088824 },
    { lat: 48.1212462, lng: 17.6082981 },
    { lat: 48.1212426, lng: 17.608048 },
    { lat: 48.121453, lng: 17.6076447 },
    { lat: 48.121932, lng: 17.6072918 },
    { lat: 48.122718, lng: 17.6073477 },
    { lat: 48.1234154, lng: 17.6076579 },
    { lat: 48.1240163, lng: 17.6077492 },
    { lat: 48.1244202, lng: 17.6077256 },
    { lat: 48.1247663, lng: 17.607594 },
    { lat: 48.1250275, lng: 17.6073672 },
    { lat: 48.125039, lng: 17.6067315 },
    { lat: 48.1248888, lng: 17.6054119 },
    { lat: 48.1254996, lng: 17.6034745 },
    { lat: 48.1259749, lng: 17.6024734 },
    { lat: 48.1263826, lng: 17.6020304 },
    { lat: 48.1255555, lng: 17.6017376 },
    { lat: 48.1252846, lng: 17.6017745 },
    { lat: 48.1246073, lng: 17.6012074 },
    { lat: 48.1235542, lng: 17.6005059 },
    { lat: 48.1230968, lng: 17.6004163 },
    { lat: 48.1224736, lng: 17.6011099 },
    { lat: 48.1216284, lng: 17.601013 },
    { lat: 48.1208018, lng: 17.6010128 },
    { lat: 48.1193496, lng: 17.6007481 },
    { lat: 48.1188017, lng: 17.6004589 },
    { lat: 48.1183187, lng: 17.6002834 },
    { lat: 48.117757, lng: 17.6001531 },
    { lat: 48.1167341, lng: 17.6000308 },
    { lat: 48.1151654, lng: 17.5996721 },
    { lat: 48.1137418, lng: 17.5990381 },
    { lat: 48.1133647, lng: 17.5987374 },
    { lat: 48.1130257, lng: 17.5982696 },
    { lat: 48.1126912, lng: 17.5981254 },
    { lat: 48.1102256, lng: 17.5964809 },
    { lat: 48.108028, lng: 17.5968884 },
    { lat: 48.1067934, lng: 17.5964362 },
    { lat: 48.1053101, lng: 17.5954525 },
    { lat: 48.1039379, lng: 17.5941678 },
    { lat: 48.103778, lng: 17.5939078 },
    { lat: 48.1036066, lng: 17.5937696 },
    { lat: 48.1037616, lng: 17.5934904 },
    { lat: 48.103784, lng: 17.5933474 },
    { lat: 48.103711, lng: 17.5929617 },
    { lat: 48.103624, lng: 17.5928186 },
    { lat: 48.1034266, lng: 17.5927876 },
    { lat: 48.1032523, lng: 17.5930468 },
    { lat: 48.1030055, lng: 17.59286 },
    { lat: 48.1029599, lng: 17.592746 },
    { lat: 48.1029278, lng: 17.5925954 },
    { lat: 48.1030923, lng: 17.5923951 },
    { lat: 48.1031343, lng: 17.5922401 },
    { lat: 48.1031623, lng: 17.5914182 },
    { lat: 48.1030403, lng: 17.5911151 },
    { lat: 48.1022296, lng: 17.5901073 },
    { lat: 48.1006976, lng: 17.5889938 },
    { lat: 48.099899, lng: 17.588212 },
    { lat: 48.099772, lng: 17.588304 },
    { lat: 48.09939, lng: 17.5887 },
    { lat: 48.099216, lng: 17.5889 },
    { lat: 48.099035, lng: 17.589124 },
    { lat: 48.098772, lng: 17.589451 },
    { lat: 48.098568, lng: 17.58973 },
    { lat: 48.098279, lng: 17.590042 },
    { lat: 48.097934, lng: 17.590315 },
    { lat: 48.097527, lng: 17.590602 },
    { lat: 48.09721, lng: 17.590771 },
    { lat: 48.097136, lng: 17.590793 },
  ],
  ČiernyBrodExt: [
    { lat: 48.1702348, lng: 17.6155406 },
    { lat: 48.170371, lng: 17.6156066 },
    { lat: 48.1706293, lng: 17.6159227 },
    { lat: 48.1713997, lng: 17.6163008 },
    { lat: 48.1716508, lng: 17.6167347 },
    { lat: 48.1717813, lng: 17.6168528 },
    { lat: 48.1721386, lng: 17.6168776 },
    { lat: 48.1726843, lng: 17.6167937 },
    { lat: 48.1731523, lng: 17.6169273 },
    { lat: 48.1731317, lng: 17.6170466 },
    { lat: 48.1731951, lng: 17.6170876 },
    { lat: 48.1733264, lng: 17.6170014 },
    { lat: 48.1735635, lng: 17.6171614 },
    { lat: 48.1738652, lng: 17.6176108 },
    { lat: 48.174314, lng: 17.6181186 },
    { lat: 48.174541, lng: 17.6182613 },
    { lat: 48.1745563, lng: 17.6184838 },
    { lat: 48.1747016, lng: 17.618783 },
    { lat: 48.1746979, lng: 17.6195636 },
    { lat: 48.174758, lng: 17.6197274 },
    { lat: 48.1759886, lng: 17.622045 },
    { lat: 48.17603, lng: 17.622029 },
    { lat: 48.1762045, lng: 17.6219614 },
    { lat: 48.176238, lng: 17.622009 },
    { lat: 48.1762245, lng: 17.6221694 },
    { lat: 48.1763429, lng: 17.6223993 },
    { lat: 48.1759166, lng: 17.6231374 },
    { lat: 48.1756038, lng: 17.6235676 },
    { lat: 48.1749228, lng: 17.6242638 },
    { lat: 48.1751781, lng: 17.6247647 },
    { lat: 48.1757162, lng: 17.6261126 },
    { lat: 48.1766433, lng: 17.6284913 },
    { lat: 48.1775937, lng: 17.6311106 },
    { lat: 48.1781262, lng: 17.6322146 },
    { lat: 48.1789037, lng: 17.6340988 },
    { lat: 48.1791098, lng: 17.634789 },
    { lat: 48.1809007, lng: 17.6399239 },
    { lat: 48.1812429, lng: 17.6410736 },
    { lat: 48.188949, lng: 17.6345324 },
    { lat: 48.1881397, lng: 17.6326944 },
    { lat: 48.1873593, lng: 17.6311727 },
    { lat: 48.1858532, lng: 17.6286923 },
    { lat: 48.1849951, lng: 17.6270655 },
    { lat: 48.1842641, lng: 17.6255217 },
    { lat: 48.183291, lng: 17.6232211 },
    { lat: 48.1824368, lng: 17.6205346 },
    { lat: 48.1814243, lng: 17.6184703 },
    { lat: 48.18142, lng: 17.6181889 },
    { lat: 48.1812756, lng: 17.6174444 },
    { lat: 48.1812703, lng: 17.6171802 },
    { lat: 48.1808121, lng: 17.6152392 },
    { lat: 48.1800647, lng: 17.6130308 },
    { lat: 48.178946, lng: 17.6094873 },
    { lat: 48.1783546, lng: 17.6097763 },
    { lat: 48.1780433, lng: 17.6097301 },
    { lat: 48.178052, lng: 17.6095838 },
    { lat: 48.1778349, lng: 17.6095782 },
    { lat: 48.1775271, lng: 17.6093872 },
    { lat: 48.1773031, lng: 17.6091125 },
    { lat: 48.1765392, lng: 17.6075204 },
    { lat: 48.1763179, lng: 17.6071991 },
    { lat: 48.1757745, lng: 17.6069626 },
    { lat: 48.1755982, lng: 17.6069624 },
    { lat: 48.1749164, lng: 17.6070919 },
    { lat: 48.174163, lng: 17.6076566 },
    { lat: 48.1732553, lng: 17.6089436 },
    { lat: 48.173077, lng: 17.609266 },
    { lat: 48.1729864, lng: 17.6095463 },
    { lat: 48.1721081, lng: 17.6109031 },
    { lat: 48.1715388, lng: 17.6115087 },
    { lat: 48.1710873, lng: 17.6117254 },
    { lat: 48.1705468, lng: 17.6118738 },
    { lat: 48.169825, lng: 17.6116367 },
    { lat: 48.1695125, lng: 17.6114295 },
    { lat: 48.1688647, lng: 17.6108296 },
    { lat: 48.1682811, lng: 17.6101174 },
    { lat: 48.1678571, lng: 17.6099316 },
    { lat: 48.1675281, lng: 17.6099782 },
    { lat: 48.1667787, lng: 17.6106998 },
    { lat: 48.1664427, lng: 17.611274 },
    { lat: 48.1662782, lng: 17.6114611 },
    { lat: 48.1659825, lng: 17.6123695 },
    { lat: 48.1658754, lng: 17.6137461 },
    { lat: 48.1657039, lng: 17.6146769 },
    { lat: 48.1658146, lng: 17.6154798 },
    { lat: 48.1659342, lng: 17.6156577 },
    { lat: 48.1659403, lng: 17.6160385 },
    { lat: 48.166979, lng: 17.6162308 },
    { lat: 48.1676325, lng: 17.6158529 },
    { lat: 48.1687648, lng: 17.6147772 },
    { lat: 48.1689007, lng: 17.6147106 },
    { lat: 48.1697924, lng: 17.6147945 },
    { lat: 48.1700147, lng: 17.6149919 },
    { lat: 48.1702209, lng: 17.6153399 },
    { lat: 48.1702348, lng: 17.6155406 },
  ],
  čiernyBrodExt1: [
    { lat: 48.1327413, lng: 17.6545624 },
    { lat: 48.1330041, lng: 17.6548432 },
    { lat: 48.1336266, lng: 17.6545651 },
    { lat: 48.1340118, lng: 17.6543127 },
    { lat: 48.1345793, lng: 17.6533955 },
    { lat: 48.1347534, lng: 17.6531999 },
    { lat: 48.1354872, lng: 17.6531809 },
    { lat: 48.1359466, lng: 17.6532852 },
    { lat: 48.1364276, lng: 17.6530143 },
    { lat: 48.1366018, lng: 17.6530032 },
    { lat: 48.1367523, lng: 17.6530789 },
    { lat: 48.1373653, lng: 17.6537764 },
    { lat: 48.1376898, lng: 17.6538909 },
    { lat: 48.1381889, lng: 17.6539261 },
    { lat: 48.1388909, lng: 17.6538121 },
    { lat: 48.1399422, lng: 17.6538888 },
    { lat: 48.1405902, lng: 17.654102 },
    { lat: 48.141349, lng: 17.654585 },
    { lat: 48.1416494, lng: 17.6546399 },
    { lat: 48.141946, lng: 17.6544169 },
    { lat: 48.1426172, lng: 17.6544801 },
    { lat: 48.1431672, lng: 17.6546061 },
    { lat: 48.1434065, lng: 17.6549631 },
    { lat: 48.1435047, lng: 17.6554485 },
    { lat: 48.143753, lng: 17.6558929 },
    { lat: 48.1439758, lng: 17.656079 },
    { lat: 48.1440916, lng: 17.6564456 },
    { lat: 48.144726, lng: 17.6574118 },
    { lat: 48.1449405, lng: 17.6576627 },
    { lat: 48.1458224, lng: 17.6584176 },
    { lat: 48.1459833, lng: 17.6586584 },
    { lat: 48.1460877, lng: 17.6592055 },
    { lat: 48.14605, lng: 17.659377 },
    { lat: 48.1460692, lng: 17.6597911 },
    { lat: 48.1460333, lng: 17.6601335 },
    { lat: 48.1457963, lng: 17.6607668 },
    { lat: 48.1458153, lng: 17.6610126 },
    { lat: 48.1455521, lng: 17.661691 },
    { lat: 48.1451941, lng: 17.6621234 },
    { lat: 48.1451207, lng: 17.6624078 },
    { lat: 48.1451127, lng: 17.6626942 },
    { lat: 48.1449731, lng: 17.6629878 },
    { lat: 48.1446901, lng: 17.6631188 },
    { lat: 48.1443019, lng: 17.6631429 },
    { lat: 48.1441814, lng: 17.6632553 },
    { lat: 48.1441808, lng: 17.6633886 },
    { lat: 48.144233, lng: 17.6634849 },
    { lat: 48.144211, lng: 17.6637966 },
    { lat: 48.1440988, lng: 17.6639687 },
    { lat: 48.1440645, lng: 17.6644324 },
    { lat: 48.1438977, lng: 17.6647584 },
    { lat: 48.1443136, lng: 17.6655556 },
    { lat: 48.1446969, lng: 17.6660483 },
    { lat: 48.1452529, lng: 17.6659989 },
    { lat: 48.1453135, lng: 17.6670001 },
    { lat: 48.1457506, lng: 17.667829 },
    { lat: 48.1456997, lng: 17.6693341 },
    { lat: 48.1460407, lng: 17.6696214 },
    { lat: 48.1463685, lng: 17.6700788 },
    { lat: 48.1466374, lng: 17.6705852 },
    { lat: 48.1500933, lng: 17.6774974 },
    { lat: 48.1508727, lng: 17.6789591 },
    { lat: 48.1526147, lng: 17.6825739 },
    { lat: 48.1540249, lng: 17.6853614 },
    { lat: 48.1587028, lng: 17.6934713 },
    { lat: 48.1592978, lng: 17.6952202 },
    { lat: 48.1594927, lng: 17.6963309 },
    { lat: 48.1594931, lng: 17.697547 },
    { lat: 48.1595487, lng: 17.6985785 },
    { lat: 48.1597176, lng: 17.699527 },
    { lat: 48.1598358, lng: 17.7001149 },
    { lat: 48.1710693, lng: 17.6890266 },
    { lat: 48.1728357, lng: 17.687179 },
    { lat: 48.1739392, lng: 17.6863746 },
    { lat: 48.1692338, lng: 17.672622 },
    { lat: 48.1695648, lng: 17.6724963 },
    { lat: 48.1677155, lng: 17.6661933 },
    { lat: 48.1674945, lng: 17.6661447 },
    { lat: 48.1671008, lng: 17.6662955 },
    { lat: 48.1669128, lng: 17.6656906 },
    { lat: 48.1667012, lng: 17.6655576 },
    { lat: 48.166365, lng: 17.6645767 },
    { lat: 48.1661673, lng: 17.6647169 },
    { lat: 48.1655609, lng: 17.6643759 },
    { lat: 48.1650003, lng: 17.6642952 },
    { lat: 48.1647812, lng: 17.6642043 },
    { lat: 48.1647021, lng: 17.6640602 },
    { lat: 48.1646869, lng: 17.6638901 },
    { lat: 48.164866, lng: 17.6634532 },
    { lat: 48.1647935, lng: 17.6632533 },
    { lat: 48.1639018, lng: 17.6628435 },
    { lat: 48.1638363, lng: 17.6625556 },
    { lat: 48.163926, lng: 17.6622215 },
    { lat: 48.1639223, lng: 17.6619663 },
    { lat: 48.1635231, lng: 17.6617406 },
    { lat: 48.1633099, lng: 17.6617097 },
    { lat: 48.1631234, lng: 17.6619909 },
    { lat: 48.1631071, lng: 17.6623787 },
    { lat: 48.1629214, lng: 17.6634481 },
    { lat: 48.1623168, lng: 17.6637626 },
    { lat: 48.1621752, lng: 17.6642246 },
    { lat: 48.1620409, lng: 17.6643877 },
    { lat: 48.1619179, lng: 17.6643696 },
    { lat: 48.1618322, lng: 17.6642813 },
    { lat: 48.1617474, lng: 17.6639572 },
    { lat: 48.1615121, lng: 17.6634661 },
    { lat: 48.1613984, lng: 17.6633431 },
    { lat: 48.161066, lng: 17.6633559 },
    { lat: 48.1609041, lng: 17.6632934 },
    { lat: 48.1607517, lng: 17.6631327 },
    { lat: 48.160647, lng: 17.6628326 },
    { lat: 48.1606175, lng: 17.6627066 },
    { lat: 48.1607179, lng: 17.6619845 },
    { lat: 48.1607596, lng: 17.6610683 },
    { lat: 48.1608304, lng: 17.6607578 },
    { lat: 48.1610253, lng: 17.6604284 },
    { lat: 48.1611109, lng: 17.660138 },
    { lat: 48.1611877, lng: 17.6593845 },
    { lat: 48.1611424, lng: 17.6592211 },
    { lat: 48.1610575, lng: 17.6591277 },
    { lat: 48.1602553, lng: 17.6584197 },
    { lat: 48.1599178, lng: 17.6583698 },
    { lat: 48.1595293, lng: 17.6585999 },
    { lat: 48.159148, lng: 17.6590192 },
    { lat: 48.1587641, lng: 17.6593246 },
    { lat: 48.1586655, lng: 17.6597469 },
    { lat: 48.1586276, lng: 17.6598636 },
    { lat: 48.1581426, lng: 17.6590481 },
    { lat: 48.1574278, lng: 17.6593681 },
    { lat: 48.1564349, lng: 17.6592386 },
    { lat: 48.1565555, lng: 17.6595573 },
    { lat: 48.1558595, lng: 17.6595656 },
    { lat: 48.1554693, lng: 17.6584079 },
    { lat: 48.1542337, lng: 17.6575618 },
    { lat: 48.154244, lng: 17.6575411 },
    { lat: 48.1542929, lng: 17.6574428 },
    { lat: 48.1554259, lng: 17.6551749 },
    { lat: 48.1556572, lng: 17.6548124 },
    { lat: 48.1563029, lng: 17.6542888 },
    { lat: 48.1566853, lng: 17.6538873 },
    { lat: 48.1561547, lng: 17.6528863 },
    { lat: 48.1569192, lng: 17.6519738 },
    { lat: 48.1587216, lng: 17.6502416 },
    { lat: 48.1570871, lng: 17.6484924 },
    { lat: 48.1563607, lng: 17.6463187 },
    { lat: 48.1558011, lng: 17.6470061 },
    { lat: 48.1544762, lng: 17.6445054 },
    { lat: 48.154387, lng: 17.6444223 },
    { lat: 48.1544329, lng: 17.6446416 },
    { lat: 48.1542917, lng: 17.6446298 },
    { lat: 48.1538287, lng: 17.6440041 },
    { lat: 48.1540291, lng: 17.6436519 },
    { lat: 48.1499708, lng: 17.628898 },
    { lat: 48.1501182, lng: 17.6285339 },
    { lat: 48.1502722, lng: 17.6276656 },
    { lat: 48.1504902, lng: 17.62687 },
    { lat: 48.1506836, lng: 17.6260807 },
    { lat: 48.1510206, lng: 17.6239313 },
    { lat: 48.1514083, lng: 17.623274 },
    { lat: 48.1515806, lng: 17.6228041 },
    { lat: 48.151685, lng: 17.6226706 },
    { lat: 48.152339, lng: 17.6224413 },
    { lat: 48.1526705, lng: 17.6225045 },
    { lat: 48.1527984, lng: 17.6227056 },
    { lat: 48.1532708, lng: 17.6231947 },
    { lat: 48.1536814, lng: 17.6239529 },
    { lat: 48.1543614, lng: 17.6247798 },
    { lat: 48.1547512, lng: 17.6250168 },
    { lat: 48.1554193, lng: 17.6251728 },
    { lat: 48.1571352, lng: 17.6246277 },
    { lat: 48.157317, lng: 17.6247537 },
    { lat: 48.1580198, lng: 17.6250074 },
    { lat: 48.1583078, lng: 17.6252408 },
    { lat: 48.159127, lng: 17.6263226 },
    { lat: 48.1594923, lng: 17.6264036 },
    { lat: 48.15964, lng: 17.626364 },
    { lat: 48.1602459, lng: 17.6256404 },
    { lat: 48.1604708, lng: 17.6253059 },
    { lat: 48.1605723, lng: 17.6250535 },
    { lat: 48.160606, lng: 17.6248199 },
    { lat: 48.1604497, lng: 17.623329 },
    { lat: 48.1605032, lng: 17.6231054 },
    { lat: 48.1605068, lng: 17.6227689 },
    { lat: 48.1604574, lng: 17.6226832 },
    { lat: 48.1604446, lng: 17.6223214 },
    { lat: 48.1605243, lng: 17.6219017 },
    { lat: 48.1606078, lng: 17.6217396 },
    { lat: 48.161033, lng: 17.6214103 },
    { lat: 48.1614911, lng: 17.6207447 },
    { lat: 48.161622, lng: 17.6207262 },
    { lat: 48.1617086, lng: 17.6205594 },
    { lat: 48.1617672, lng: 17.6201645 },
    { lat: 48.1616955, lng: 17.6198737 },
    { lat: 48.161726, lng: 17.6196034 },
    { lat: 48.1616192, lng: 17.6189702 },
    { lat: 48.1616349, lng: 17.6185935 },
    { lat: 48.1617825, lng: 17.6174274 },
    { lat: 48.1619798, lng: 17.6169252 },
    { lat: 48.162189, lng: 17.6168914 },
    { lat: 48.1629404, lng: 17.6169725 },
    { lat: 48.1635922, lng: 17.6175994 },
    { lat: 48.1642031, lng: 17.617848 },
    { lat: 48.1647567, lng: 17.6179631 },
    { lat: 48.1644693, lng: 17.6157918 },
    { lat: 48.1641091, lng: 17.6145464 },
    { lat: 48.1640445, lng: 17.6140947 },
    { lat: 48.1637161, lng: 17.6132962 },
    { lat: 48.1635113, lng: 17.6129884 },
    { lat: 48.1630371, lng: 17.6126322 },
    { lat: 48.1628005, lng: 17.6126225 },
    { lat: 48.1623865, lng: 17.6127543 },
    { lat: 48.1620068, lng: 17.6127476 },
    { lat: 48.161469, lng: 17.612372 },
    { lat: 48.1601654, lng: 17.6119153 },
    { lat: 48.1594009, lng: 17.6113636 },
    { lat: 48.158877, lng: 17.6113666 },
    { lat: 48.1581716, lng: 17.6115492 },
    { lat: 48.1573511, lng: 17.6120738 },
    { lat: 48.1559419, lng: 17.6122892 },
    { lat: 48.1552031, lng: 17.6129069 },
    { lat: 48.1543831, lng: 17.6134744 },
    { lat: 48.1538863, lng: 17.6143832 },
    { lat: 48.1536044, lng: 17.6146579 },
    { lat: 48.1532042, lng: 17.6147396 },
    { lat: 48.1529264, lng: 17.6151325 },
    { lat: 48.1528452, lng: 17.6157034 },
    { lat: 48.1522596, lng: 17.6161551 },
    { lat: 48.1516145, lng: 17.6161508 },
    { lat: 48.150402, lng: 17.6164938 },
    { lat: 48.1500235, lng: 17.6165059 },
    { lat: 48.1494582, lng: 17.6162482 },
    { lat: 48.1489816, lng: 17.6158711 },
    { lat: 48.1482102, lng: 17.6156012 },
    { lat: 48.1474216, lng: 17.6158782 },
    { lat: 48.1464417, lng: 17.6160083 },
    { lat: 48.1460074, lng: 17.6159954 },
    { lat: 48.1453138, lng: 17.6160295 },
    { lat: 48.1446728, lng: 17.6167841 },
    { lat: 48.1442459, lng: 17.6172274 },
    { lat: 48.144111, lng: 17.617291 },
    { lat: 48.1432093, lng: 17.6166424 },
    { lat: 48.1430126, lng: 17.616619 },
    { lat: 48.1425002, lng: 17.6169082 },
    { lat: 48.1421785, lng: 17.6183565 },
    { lat: 48.1421509, lng: 17.6186867 },
    { lat: 48.1423411, lng: 17.6196377 },
    { lat: 48.1426104, lng: 17.6196319 },
    { lat: 48.1427496, lng: 17.6199392 },
    { lat: 48.1425262, lng: 17.6204399 },
    { lat: 48.1419485, lng: 17.62148 },
    { lat: 48.1418915, lng: 17.6221174 },
    { lat: 48.1417449, lng: 17.6225825 },
    { lat: 48.1414579, lng: 17.6229713 },
    { lat: 48.1412471, lng: 17.623386 },
    { lat: 48.1414819, lng: 17.623496 },
    { lat: 48.1405762, lng: 17.6244558 },
    { lat: 48.1402876, lng: 17.6248752 },
    { lat: 48.1401405, lng: 17.6254418 },
    { lat: 48.1403267, lng: 17.6275285 },
    { lat: 48.1404545, lng: 17.6281727 },
    { lat: 48.1406741, lng: 17.6286815 },
    { lat: 48.1409055, lng: 17.6290225 },
    { lat: 48.1411035, lng: 17.6310991 },
    { lat: 48.1409204, lng: 17.6321509 },
    { lat: 48.1411557, lng: 17.6327116 },
    { lat: 48.1407807, lng: 17.6336215 },
    { lat: 48.1403458, lng: 17.6341721 },
    { lat: 48.1401448, lng: 17.6347092 },
    { lat: 48.1400648, lng: 17.6352292 },
    { lat: 48.1393099, lng: 17.6357909 },
    { lat: 48.1385589, lng: 17.6364501 },
    { lat: 48.1380161, lng: 17.6376712 },
    { lat: 48.1381336, lng: 17.6377971 },
    { lat: 48.136902, lng: 17.6400567 },
    { lat: 48.1361547, lng: 17.6412062 },
    { lat: 48.1370198, lng: 17.6422643 },
    { lat: 48.1364482, lng: 17.6425803 },
    { lat: 48.1360327, lng: 17.6432436 },
    { lat: 48.1356728, lng: 17.6441621 },
    { lat: 48.1355272, lng: 17.6473827 },
    { lat: 48.1355601, lng: 17.6485866 },
    { lat: 48.1353135, lng: 17.6500123 },
    { lat: 48.13274, lng: 17.6537167 },
    { lat: 48.1327413, lng: 17.6545624 },
  ],
  Šalgočka: [
    { lat: 48.340445, lng: 17.843566 },
    { lat: 48.340493, lng: 17.843525 },
    { lat: 48.340858, lng: 17.843405 },
    { lat: 48.341315, lng: 17.84326 },
    { lat: 48.342027, lng: 17.843058 },
    { lat: 48.342565, lng: 17.842913 },
    { lat: 48.342968, lng: 17.842804 },
    { lat: 48.343688, lng: 17.842588 },
    { lat: 48.344216, lng: 17.842434 },
    { lat: 48.345298, lng: 17.842122 },
    { lat: 48.345833, lng: 17.841962 },
    { lat: 48.345943, lng: 17.841645 },
    { lat: 48.346061, lng: 17.841305 },
    { lat: 48.346678, lng: 17.841054 },
    { lat: 48.347231, lng: 17.84081 },
    { lat: 48.347716, lng: 17.840593 },
    { lat: 48.34759, lng: 17.840243 },
    { lat: 48.347279, lng: 17.839371 },
    { lat: 48.347002, lng: 17.838572 },
    { lat: 48.347251, lng: 17.8379 },
    { lat: 48.347503, lng: 17.837214 },
    { lat: 48.347713, lng: 17.836648 },
    { lat: 48.347953, lng: 17.83632 },
    { lat: 48.348748, lng: 17.835202 },
    { lat: 48.349145, lng: 17.834645 },
    { lat: 48.349485, lng: 17.834174 },
    { lat: 48.349785, lng: 17.833738 },
    { lat: 48.350109, lng: 17.833253 },
    { lat: 48.350579, lng: 17.832774 },
    { lat: 48.350934, lng: 17.832397 },
    { lat: 48.35142, lng: 17.832028 },
    { lat: 48.351847, lng: 17.831702 },
    { lat: 48.352353, lng: 17.830959 },
    { lat: 48.352807, lng: 17.830298 },
    { lat: 48.353159, lng: 17.829762 },
    { lat: 48.353252, lng: 17.82952 },
    { lat: 48.353519, lng: 17.828805 },
    { lat: 48.353649, lng: 17.828443 },
    { lat: 48.353725, lng: 17.828232 },
    { lat: 48.353874, lng: 17.827828 },
    { lat: 48.354044, lng: 17.827359 },
    { lat: 48.354228, lng: 17.826691 },
    { lat: 48.354416, lng: 17.826022 },
    { lat: 48.354582, lng: 17.825281 },
    { lat: 48.354786, lng: 17.824387 },
    { lat: 48.35491, lng: 17.823831 },
    { lat: 48.355046, lng: 17.82323 },
    { lat: 48.355153, lng: 17.822765 },
    { lat: 48.355126, lng: 17.822744 },
    { lat: 48.35464, lng: 17.822251 },
    { lat: 48.35414, lng: 17.821737 },
    { lat: 48.353761, lng: 17.821342 },
    { lat: 48.353586, lng: 17.821162 },
    { lat: 48.353416, lng: 17.820986 },
    { lat: 48.353, lng: 17.82057 },
    { lat: 48.352559, lng: 17.820396 },
    { lat: 48.352125, lng: 17.82021 },
    { lat: 48.351696, lng: 17.820035 },
    { lat: 48.351765, lng: 17.819118 },
    { lat: 48.351722, lng: 17.818931 },
    { lat: 48.351624, lng: 17.818786 },
    { lat: 48.351623, lng: 17.818704 },
    { lat: 48.352002, lng: 17.818045 },
    { lat: 48.352567, lng: 17.817778 },
    { lat: 48.35266, lng: 17.817628 },
    { lat: 48.352942, lng: 17.816826 },
    { lat: 48.352978, lng: 17.816636 },
    { lat: 48.352999, lng: 17.81641 },
    { lat: 48.352992, lng: 17.815897 },
    { lat: 48.352953, lng: 17.815695 },
    { lat: 48.352921, lng: 17.815496 },
    { lat: 48.352911, lng: 17.815108 },
    { lat: 48.352903, lng: 17.815108 },
    { lat: 48.352873, lng: 17.814782 },
    { lat: 48.352847, lng: 17.814378 },
    { lat: 48.352824, lng: 17.814069 },
    { lat: 48.352767, lng: 17.813531 },
    { lat: 48.35273, lng: 17.813216 },
    { lat: 48.352701, lng: 17.81299 },
    { lat: 48.352589, lng: 17.812491 },
    { lat: 48.35248, lng: 17.812016 },
    { lat: 48.352316, lng: 17.811551 },
    { lat: 48.352111, lng: 17.811057 },
    { lat: 48.351951, lng: 17.810649 },
    { lat: 48.351741, lng: 17.810225 },
    { lat: 48.351496, lng: 17.809704 },
    { lat: 48.351266, lng: 17.809222 },
    { lat: 48.351026, lng: 17.808729 },
    { lat: 48.350774, lng: 17.808246 },
    { lat: 48.350577, lng: 17.807808 },
    { lat: 48.350423, lng: 17.807463 },
    { lat: 48.350239, lng: 17.807102 },
    { lat: 48.349977, lng: 17.806575 },
    { lat: 48.349904, lng: 17.806433 },
    { lat: 48.349669, lng: 17.805863 },
    { lat: 48.34946, lng: 17.805376 },
    { lat: 48.349423, lng: 17.805229 },
    { lat: 48.349365, lng: 17.805093 },
    { lat: 48.349008, lng: 17.804347 },
    { lat: 48.348993, lng: 17.804305 },
    { lat: 48.348508, lng: 17.804695 },
    { lat: 48.348223, lng: 17.804929 },
    { lat: 48.348017, lng: 17.805131 },
    { lat: 48.347977, lng: 17.805079 },
    { lat: 48.347999, lng: 17.80501 },
    { lat: 48.347866, lng: 17.804484 },
    { lat: 48.347741, lng: 17.803988 },
    { lat: 48.347626, lng: 17.803576 },
    { lat: 48.347582, lng: 17.803401 },
    { lat: 48.347505, lng: 17.803005 },
    { lat: 48.34724, lng: 17.803124 },
    { lat: 48.346911, lng: 17.80325 },
    { lat: 48.346729, lng: 17.803346 },
    { lat: 48.346558, lng: 17.803378 },
    { lat: 48.346439, lng: 17.8034 },
    { lat: 48.346112, lng: 17.803518 },
    { lat: 48.345919, lng: 17.803526 },
    { lat: 48.345834, lng: 17.803554 },
    { lat: 48.345664, lng: 17.803657 },
    { lat: 48.345234, lng: 17.803752 },
    { lat: 48.345258, lng: 17.803974 },
    { lat: 48.344746, lng: 17.80427 },
    { lat: 48.344207, lng: 17.804588 },
    { lat: 48.343695, lng: 17.804885 },
    { lat: 48.343233, lng: 17.805162 },
    { lat: 48.343216, lng: 17.805113 },
    { lat: 48.342868, lng: 17.805309 },
    { lat: 48.342485, lng: 17.805516 },
    { lat: 48.342276, lng: 17.805624 },
    { lat: 48.341953, lng: 17.805738 },
    { lat: 48.341524, lng: 17.806018 },
    { lat: 48.341119, lng: 17.806166 },
    { lat: 48.340868, lng: 17.806323 },
    { lat: 48.340516, lng: 17.806568 },
    { lat: 48.340366, lng: 17.806673 },
    { lat: 48.340017, lng: 17.807019 },
    { lat: 48.33978, lng: 17.807255 },
    { lat: 48.339426, lng: 17.807626 },
    { lat: 48.339198, lng: 17.807826 },
    { lat: 48.339013, lng: 17.807964 },
    { lat: 48.338848, lng: 17.808106 },
    { lat: 48.338687, lng: 17.808274 },
    { lat: 48.338457, lng: 17.808441 },
    { lat: 48.338165, lng: 17.808603 },
    { lat: 48.337812, lng: 17.808789 },
    { lat: 48.337498, lng: 17.808952 },
    { lat: 48.337167, lng: 17.809119 },
    { lat: 48.337076, lng: 17.809171 },
    { lat: 48.336782, lng: 17.809363 },
    { lat: 48.336375, lng: 17.809604 },
    { lat: 48.336033, lng: 17.809816 },
    { lat: 48.3357, lng: 17.810028 },
    { lat: 48.33568, lng: 17.809965 },
    { lat: 48.335448, lng: 17.809381 },
    { lat: 48.335338, lng: 17.809454 },
    { lat: 48.335162, lng: 17.809552 },
    { lat: 48.335092, lng: 17.809577 },
    { lat: 48.335045, lng: 17.809561 },
    { lat: 48.334832, lng: 17.809457 },
    { lat: 48.334762, lng: 17.809466 },
    { lat: 48.33461, lng: 17.809516 },
    { lat: 48.334328, lng: 17.80971 },
    { lat: 48.334004, lng: 17.809938 },
    { lat: 48.333728, lng: 17.810144 },
    { lat: 48.333396, lng: 17.810396 },
    { lat: 48.333097, lng: 17.810614 },
    { lat: 48.332981, lng: 17.810703 },
    { lat: 48.332674, lng: 17.810959 },
    { lat: 48.332503, lng: 17.811108 },
    { lat: 48.332227, lng: 17.811349 },
    { lat: 48.332139, lng: 17.811381 },
    { lat: 48.331822, lng: 17.811621 },
    { lat: 48.331592, lng: 17.811802 },
    { lat: 48.331275, lng: 17.811948 },
    { lat: 48.331085, lng: 17.812426 },
    { lat: 48.330721, lng: 17.812746 },
    { lat: 48.330524, lng: 17.812926 },
    { lat: 48.3317756, lng: 17.8170296 },
    { lat: 48.3328467, lng: 17.8184248 },
    { lat: 48.3346114, lng: 17.8218546 },
    { lat: 48.3348486, lng: 17.8230154 },
    { lat: 48.3347824, lng: 17.8242206 },
    { lat: 48.3348034, lng: 17.8251547 },
    { lat: 48.3349025, lng: 17.8257364 },
    { lat: 48.335052, lng: 17.8261504 },
    { lat: 48.3360898, lng: 17.8278879 },
    { lat: 48.3362818, lng: 17.8282964 },
    { lat: 48.336592, lng: 17.8290506 },
    { lat: 48.336959, lng: 17.8303437 },
    { lat: 48.337183, lng: 17.8307713 },
    { lat: 48.3374882, lng: 17.8312781 },
    { lat: 48.3380157, lng: 17.8317991 },
    { lat: 48.3384618, lng: 17.8324292 },
    { lat: 48.3394538, lng: 17.8349162 },
    { lat: 48.339579, lng: 17.8354472 },
    { lat: 48.33962, lng: 17.8369502 },
    { lat: 48.3398084, lng: 17.8382045 },
    { lat: 48.3398916, lng: 17.8392132 },
    { lat: 48.3398919, lng: 17.8396439 },
    { lat: 48.3397453, lng: 17.840439 },
    { lat: 48.3397698, lng: 17.8406734 },
    { lat: 48.3401873, lng: 17.8427063 },
    { lat: 48.340445, lng: 17.843566 },
  ],
  Jánovce: [
    { lat: 48.174951, lng: 17.514293 },
    { lat: 48.1747996, lng: 17.5143384 },
    { lat: 48.174297, lng: 17.514195 },
    { lat: 48.173673, lng: 17.5137139 },
    { lat: 48.1733417, lng: 17.5137767 },
    { lat: 48.1731646, lng: 17.5140437 },
    { lat: 48.1730443, lng: 17.5143529 },
    { lat: 48.1729586, lng: 17.5150229 },
    { lat: 48.1731296, lng: 17.5158393 },
    { lat: 48.1729882, lng: 17.516062 },
    { lat: 48.1728792, lng: 17.5161604 },
    { lat: 48.1724136, lng: 17.5162619 },
    { lat: 48.1720799, lng: 17.5161796 },
    { lat: 48.1718735, lng: 17.5160794 },
    { lat: 48.1714747, lng: 17.5155489 },
    { lat: 48.1713259, lng: 17.5155384 },
    { lat: 48.1708243, lng: 17.5160777 },
    { lat: 48.170677, lng: 17.5164233 },
    { lat: 48.1709555, lng: 17.5176648 },
    { lat: 48.1715441, lng: 17.5184952 },
    { lat: 48.1718177, lng: 17.5184656 },
    { lat: 48.1720218, lng: 17.5181982 },
    { lat: 48.1720915, lng: 17.5181744 },
    { lat: 48.1726089, lng: 17.5185452 },
    { lat: 48.1727413, lng: 17.5185871 },
    { lat: 48.1728843, lng: 17.5185436 },
    { lat: 48.1730209, lng: 17.5184752 },
    { lat: 48.1731256, lng: 17.5183286 },
    { lat: 48.1733173, lng: 17.5179345 },
    { lat: 48.1736237, lng: 17.5176602 },
    { lat: 48.1737527, lng: 17.5176467 },
    { lat: 48.173944, lng: 17.5178092 },
    { lat: 48.1740267, lng: 17.518044 },
    { lat: 48.1742789, lng: 17.5184002 },
    { lat: 48.1744659, lng: 17.5190976 },
    { lat: 48.1744341, lng: 17.5198342 },
    { lat: 48.1744741, lng: 17.5201593 },
    { lat: 48.1744144, lng: 17.5206196 },
    { lat: 48.1743364, lng: 17.520813 },
    { lat: 48.173793, lng: 17.5214754 },
    { lat: 48.172752, lng: 17.5212879 },
    { lat: 48.1722107, lng: 17.5209296 },
    { lat: 48.1719765, lng: 17.5210106 },
    { lat: 48.1717662, lng: 17.5213599 },
    { lat: 48.1714278, lng: 17.5227397 },
    { lat: 48.1712265, lng: 17.5232472 },
    { lat: 48.1711328, lng: 17.5242137 },
    { lat: 48.1711551, lng: 17.5244391 },
    { lat: 48.1713789, lng: 17.5248485 },
    { lat: 48.1721083, lng: 17.5252142 },
    { lat: 48.1722066, lng: 17.525337 },
    { lat: 48.1724655, lng: 17.5265417 },
    { lat: 48.1731559, lng: 17.5276203 },
    { lat: 48.1733855, lng: 17.5278577 },
    { lat: 48.1737482, lng: 17.5284404 },
    { lat: 48.1738132, lng: 17.5286455 },
    { lat: 48.1740295, lng: 17.5296066 },
    { lat: 48.1740199, lng: 17.5301429 },
    { lat: 48.173984, lng: 17.5306243 },
    { lat: 48.1739231, lng: 17.5308198 },
    { lat: 48.1737248, lng: 17.5311259 },
    { lat: 48.1734622, lng: 17.5312934 },
    { lat: 48.1734195, lng: 17.531417 },
    { lat: 48.1733985, lng: 17.5319018 },
    { lat: 48.1734535, lng: 17.532251 },
    { lat: 48.1735346, lng: 17.5324421 },
    { lat: 48.1739727, lng: 17.5333222 },
    { lat: 48.1749303, lng: 17.534237 },
    { lat: 48.1751979, lng: 17.5345727 },
    { lat: 48.1755944, lng: 17.5348047 },
    { lat: 48.1758339, lng: 17.535034 },
    { lat: 48.1760652, lng: 17.535752 },
    { lat: 48.1762009, lng: 17.5370255 },
    { lat: 48.1762073, lng: 17.5377615 },
    { lat: 48.1759434, lng: 17.5387556 },
    { lat: 48.1756498, lng: 17.539305 },
    { lat: 48.1751998, lng: 17.5394875 },
    { lat: 48.1748242, lng: 17.5394609 },
    { lat: 48.1744902, lng: 17.5397107 },
    { lat: 48.1742518, lng: 17.5401347 },
    { lat: 48.1741719, lng: 17.5404391 },
    { lat: 48.1740757, lng: 17.5417428 },
    { lat: 48.174172, lng: 17.5420287 },
    { lat: 48.1754928, lng: 17.5422333 },
    { lat: 48.1756638, lng: 17.5420839 },
    { lat: 48.177287, lng: 17.5421406 },
    { lat: 48.1805102, lng: 17.5421263 },
    { lat: 48.182552, lng: 17.5422869 },
    { lat: 48.1830406, lng: 17.5426661 },
    { lat: 48.1851178, lng: 17.539353 },
    { lat: 48.1864877, lng: 17.5375883 },
    { lat: 48.188755, lng: 17.5342677 },
    { lat: 48.1898034, lng: 17.5329862 },
    { lat: 48.194712, lng: 17.5331717 },
    { lat: 48.1961385, lng: 17.5330679 },
    { lat: 48.1963424, lng: 17.5330447 },
    { lat: 48.1959643, lng: 17.5323573 },
    { lat: 48.1958154, lng: 17.5318845 },
    { lat: 48.1957129, lng: 17.5312887 },
    { lat: 48.195721, lng: 17.5307983 },
    { lat: 48.195892, lng: 17.5302054 },
    { lat: 48.196497, lng: 17.529199 },
    { lat: 48.196423, lng: 17.529065 },
    { lat: 48.196389, lng: 17.529003 },
    { lat: 48.196371, lng: 17.52897 },
    { lat: 48.196367, lng: 17.52887 },
    { lat: 48.196362, lng: 17.528687 },
    { lat: 48.196318, lng: 17.528486 },
    { lat: 48.196314, lng: 17.528301 },
    { lat: 48.19629, lng: 17.528118 },
    { lat: 48.196224, lng: 17.528011 },
    { lat: 48.196146, lng: 17.527803 },
    { lat: 48.196053, lng: 17.52765 },
    { lat: 48.195942, lng: 17.527607 },
    { lat: 48.195778, lng: 17.527576 },
    { lat: 48.195542, lng: 17.527603 },
    { lat: 48.195196, lng: 17.527392 },
    { lat: 48.195159, lng: 17.527362 },
    { lat: 48.19506, lng: 17.527208 },
    { lat: 48.195009, lng: 17.52703 },
    { lat: 48.194983, lng: 17.526802 },
    { lat: 48.194994, lng: 17.526346 },
    { lat: 48.195081, lng: 17.526147 },
    { lat: 48.195139, lng: 17.525967 },
    { lat: 48.195155, lng: 17.525777 },
    { lat: 48.195142, lng: 17.525613 },
    { lat: 48.19508, lng: 17.52538 },
    { lat: 48.194983, lng: 17.52503 },
    { lat: 48.194934, lng: 17.524923 },
    { lat: 48.194709, lng: 17.524572 },
    { lat: 48.194636, lng: 17.523831 },
    { lat: 48.194397, lng: 17.521445 },
    { lat: 48.194194, lng: 17.521457 },
    { lat: 48.186459, lng: 17.521915 },
    { lat: 48.186397, lng: 17.521918 },
    { lat: 48.186896, lng: 17.519173 },
    { lat: 48.185871, lng: 17.518752 },
    { lat: 48.183829, lng: 17.517943 },
    { lat: 48.181599, lng: 17.517056 },
    { lat: 48.180409, lng: 17.51658 },
    { lat: 48.180437, lng: 17.516497 },
    { lat: 48.180445, lng: 17.516469 },
    { lat: 48.18043, lng: 17.516463 },
    { lat: 48.180363, lng: 17.516437 },
    { lat: 48.180355, lng: 17.516462 },
    { lat: 48.180335, lng: 17.516516 },
    { lat: 48.178258, lng: 17.515694 },
    { lat: 48.175067, lng: 17.514437 },
    { lat: 48.175076, lng: 17.514338 },
    { lat: 48.174951, lng: 17.514293 },
  ],
  DolnáStreda: [
    { lat: 48.2465448, lng: 17.7218298 },
    { lat: 48.2460093, lng: 17.7227313 },
    { lat: 48.2457001, lng: 17.7235148 },
    { lat: 48.2444822, lng: 17.7258585 },
    { lat: 48.2441671, lng: 17.7265854 },
    { lat: 48.2436605, lng: 17.7280056 },
    { lat: 48.2433771, lng: 17.7289487 },
    { lat: 48.2433038, lng: 17.7300761 },
    { lat: 48.2430199, lng: 17.7310642 },
    { lat: 48.2425762, lng: 17.7318433 },
    { lat: 48.2416839, lng: 17.7337284 },
    { lat: 48.2412334, lng: 17.7345456 },
    { lat: 48.2411563, lng: 17.7349037 },
    { lat: 48.2409186, lng: 17.7354387 },
    { lat: 48.2399025, lng: 17.7368752 },
    { lat: 48.2397906, lng: 17.7369432 },
    { lat: 48.2386541, lng: 17.738368 },
    { lat: 48.2381915, lng: 17.7390451 },
    { lat: 48.2380256, lng: 17.7392839 },
    { lat: 48.2372182, lng: 17.7411894 },
    { lat: 48.2363335, lng: 17.7428141 },
    { lat: 48.235881, lng: 17.7438718 },
    { lat: 48.2349242, lng: 17.7465009 },
    { lat: 48.2340638, lng: 17.7498679 },
    { lat: 48.2340058, lng: 17.7500772 },
    { lat: 48.2343337, lng: 17.7506339 },
    { lat: 48.2350003, lng: 17.7519667 },
    { lat: 48.2359498, lng: 17.7540435 },
    { lat: 48.2362523, lng: 17.7547172 },
    { lat: 48.2365596, lng: 17.7554505 },
    { lat: 48.2369687, lng: 17.7560917 },
    { lat: 48.2381359, lng: 17.7548504 },
    { lat: 48.2391329, lng: 17.7539756 },
    { lat: 48.2402248, lng: 17.753214 },
    { lat: 48.2410985, lng: 17.7529201 },
    { lat: 48.2421592, lng: 17.7554068 },
    { lat: 48.243866, lng: 17.7589932 },
    { lat: 48.2449969, lng: 17.7617551 },
    { lat: 48.2455106, lng: 17.7627039 },
    { lat: 48.2462412, lng: 17.7642205 },
    { lat: 48.2475317, lng: 17.767069 },
    { lat: 48.2480863, lng: 17.7684376 },
    { lat: 48.2481408, lng: 17.7687566 },
    { lat: 48.2481258, lng: 17.7689695 },
    { lat: 48.2482446, lng: 17.7691004 },
    { lat: 48.2484783, lng: 17.7691923 },
    { lat: 48.2491645, lng: 17.7692434 },
    { lat: 48.2492682, lng: 17.7693328 },
    { lat: 48.2494666, lng: 17.7703134 },
    { lat: 48.2497268, lng: 17.7705236 },
    { lat: 48.2501658, lng: 17.7706292 },
    { lat: 48.2503662, lng: 17.7704735 },
    { lat: 48.2504403, lng: 17.7702868 },
    { lat: 48.2500234, lng: 17.7693868 },
    { lat: 48.2504773, lng: 17.768693 },
    { lat: 48.2507025, lng: 17.7681917 },
    { lat: 48.2509769, lng: 17.7678621 },
    { lat: 48.251061, lng: 17.7678713 },
    { lat: 48.2512734, lng: 17.768218 },
    { lat: 48.251327, lng: 17.7687593 },
    { lat: 48.251511, lng: 17.7691759 },
    { lat: 48.2516563, lng: 17.769081 },
    { lat: 48.2517162, lng: 17.7689703 },
    { lat: 48.2518564, lng: 17.7683915 },
    { lat: 48.2523173, lng: 17.7678837 },
    { lat: 48.2527208, lng: 17.7670711 },
    { lat: 48.2530989, lng: 17.7667763 },
    { lat: 48.2532634, lng: 17.7664602 },
    { lat: 48.253499, lng: 17.7661806 },
    { lat: 48.2539965, lng: 17.766012 },
    { lat: 48.2548929, lng: 17.7660655 },
    { lat: 48.255339, lng: 17.7661407 },
    { lat: 48.2554507, lng: 17.76623 },
    { lat: 48.2557166, lng: 17.7668415 },
    { lat: 48.2557081, lng: 17.7676702 },
    { lat: 48.2557865, lng: 17.7679367 },
    { lat: 48.256338, lng: 17.769367 },
    { lat: 48.2567895, lng: 17.7699318 },
    { lat: 48.2569904, lng: 17.7704602 },
    { lat: 48.2569118, lng: 17.7707963 },
    { lat: 48.2565379, lng: 17.7712495 },
    { lat: 48.2564915, lng: 17.7714678 },
    { lat: 48.256527, lng: 17.771713 },
    { lat: 48.2571072, lng: 17.7726058 },
    { lat: 48.2572884, lng: 17.773179 },
    { lat: 48.2577282, lng: 17.7737048 },
    { lat: 48.2579466, lng: 17.7745156 },
    { lat: 48.2582594, lng: 17.7749721 },
    { lat: 48.258623, lng: 17.7753019 },
    { lat: 48.2587969, lng: 17.7757416 },
    { lat: 48.2588479, lng: 17.7765299 },
    { lat: 48.2591259, lng: 17.7771393 },
    { lat: 48.259886, lng: 17.7831573 },
    { lat: 48.2605856, lng: 17.7871611 },
    { lat: 48.2605997, lng: 17.7872449 },
    { lat: 48.2614013, lng: 17.7860909 },
    { lat: 48.2622478, lng: 17.7878059 },
    { lat: 48.2625914, lng: 17.7885106 },
    { lat: 48.2645734, lng: 17.7865803 },
    { lat: 48.2654089, lng: 17.7858453 },
    { lat: 48.2689519, lng: 17.7865832 },
    { lat: 48.2703098, lng: 17.7851713 },
    { lat: 48.2704899, lng: 17.7847034 },
    { lat: 48.2704779, lng: 17.7840881 },
    { lat: 48.270298, lng: 17.7830577 },
    { lat: 48.269511, lng: 17.7810816 },
    { lat: 48.2692784, lng: 17.7802219 },
    { lat: 48.2677029, lng: 17.7759143 },
    { lat: 48.2678692, lng: 17.772172 },
    { lat: 48.2678665, lng: 17.7712911 },
    { lat: 48.2682295, lng: 17.7703701 },
    { lat: 48.2685541, lng: 17.7693562 },
    { lat: 48.2689889, lng: 17.7684256 },
    { lat: 48.2714654, lng: 17.7618859 },
    { lat: 48.2716633, lng: 17.7614516 },
    { lat: 48.2729125, lng: 17.7579588 },
    { lat: 48.2747568, lng: 17.7545235 },
    { lat: 48.275803, lng: 17.7520913 },
    { lat: 48.2791911, lng: 17.7481745 },
    { lat: 48.2793006, lng: 17.7480483 },
    { lat: 48.2764098, lng: 17.7445084 },
    { lat: 48.2759685, lng: 17.7443674 },
    { lat: 48.2755568, lng: 17.7439885 },
    { lat: 48.2756773, lng: 17.7438381 },
    { lat: 48.2756552, lng: 17.7437894 },
    { lat: 48.2754832, lng: 17.7438931 },
    { lat: 48.2741917, lng: 17.7421846 },
    { lat: 48.2743697, lng: 17.7419093 },
    { lat: 48.2725176, lng: 17.7395365 },
    { lat: 48.2727391, lng: 17.7392285 },
    { lat: 48.2722611, lng: 17.7387851 },
    { lat: 48.2716517, lng: 17.7379897 },
    { lat: 48.2715052, lng: 17.7379772 },
    { lat: 48.2714828, lng: 17.7377992 },
    { lat: 48.2708755, lng: 17.7371102 },
    { lat: 48.2709058, lng: 17.7370351 },
    { lat: 48.2708785, lng: 17.7369957 },
    { lat: 48.2708403, lng: 17.7370694 },
    { lat: 48.2707505, lng: 17.7369494 },
    { lat: 48.2706483, lng: 17.7369771 },
    { lat: 48.2706103, lng: 17.7367499 },
    { lat: 48.270223, lng: 17.7362726 },
    { lat: 48.2693417, lng: 17.7379725 },
    { lat: 48.2692423, lng: 17.7370798 },
    { lat: 48.2697239, lng: 17.7361452 },
    { lat: 48.2685739, lng: 17.7333936 },
    { lat: 48.2676453, lng: 17.7316045 },
    { lat: 48.2666102, lng: 17.7301159 },
    { lat: 48.265837, lng: 17.7291239 },
    { lat: 48.2656539, lng: 17.7289556 },
    { lat: 48.2645028, lng: 17.7273306 },
    { lat: 48.263755, lng: 17.7259283 },
    { lat: 48.2628205, lng: 17.7244465 },
    { lat: 48.2626731, lng: 17.7244915 },
    { lat: 48.2588307, lng: 17.7201344 },
    { lat: 48.2587334, lng: 17.7201498 },
    { lat: 48.255475, lng: 17.7166503 },
    { lat: 48.2547407, lng: 17.7161545 },
    { lat: 48.2543431, lng: 17.7156106 },
    { lat: 48.2536546, lng: 17.7157776 },
    { lat: 48.2530297, lng: 17.7161144 },
    { lat: 48.2518464, lng: 17.7169291 },
    { lat: 48.2509215, lng: 17.7176878 },
    { lat: 48.2494295, lng: 17.7199098 },
    { lat: 48.2482814, lng: 17.7205257 },
    { lat: 48.2478202, lng: 17.7210991 },
    { lat: 48.246781, lng: 17.7216328 },
    { lat: 48.2465448, lng: 17.7218298 },
  ],
  Gáň: [
    { lat: 48.2193134, lng: 17.7072853 },
    { lat: 48.2199996, lng: 17.7087978 },
    { lat: 48.2215578, lng: 17.7127978 },
    { lat: 48.2227202, lng: 17.7162296 },
    { lat: 48.2234065, lng: 17.7178715 },
    { lat: 48.2239288, lng: 17.7189178 },
    { lat: 48.2245315, lng: 17.7203817 },
    { lat: 48.2245958, lng: 17.7207264 },
    { lat: 48.2250243, lng: 17.7217289 },
    { lat: 48.2248269, lng: 17.7219133 },
    { lat: 48.2245697, lng: 17.7224073 },
    { lat: 48.2244417, lng: 17.7227647 },
    { lat: 48.2243955, lng: 17.7230169 },
    { lat: 48.2244382, lng: 17.7237223 },
    { lat: 48.2244703, lng: 17.7240205 },
    { lat: 48.2246334, lng: 17.724371 },
    { lat: 48.2250934, lng: 17.7248889 },
    { lat: 48.2252983, lng: 17.7252247 },
    { lat: 48.2252833, lng: 17.7259438 },
    { lat: 48.2249682, lng: 17.7264502 },
    { lat: 48.2247361, lng: 17.7266936 },
    { lat: 48.2245376, lng: 17.7268413 },
    { lat: 48.2244397, lng: 17.7268457 },
    { lat: 48.22437, lng: 17.7270866 },
    { lat: 48.2244453, lng: 17.7270804 },
    { lat: 48.2245791, lng: 17.7275266 },
    { lat: 48.2249424, lng: 17.728085 },
    { lat: 48.2249174, lng: 17.7281926 },
    { lat: 48.2252554, lng: 17.7287038 },
    { lat: 48.2273598, lng: 17.7353814 },
    { lat: 48.2291558, lng: 17.739355 },
    { lat: 48.2331063, lng: 17.7486472 },
    { lat: 48.2339659, lng: 17.7502194 },
    { lat: 48.2340058, lng: 17.7500772 },
    { lat: 48.2340638, lng: 17.7498679 },
    { lat: 48.2349242, lng: 17.7465009 },
    { lat: 48.235881, lng: 17.7438718 },
    { lat: 48.2363335, lng: 17.7428141 },
    { lat: 48.2372182, lng: 17.7411894 },
    { lat: 48.2380256, lng: 17.7392839 },
    { lat: 48.2381915, lng: 17.7390451 },
    { lat: 48.2386541, lng: 17.738368 },
    { lat: 48.2397906, lng: 17.7369432 },
    { lat: 48.2399025, lng: 17.7368752 },
    { lat: 48.2409186, lng: 17.7354387 },
    { lat: 48.2411563, lng: 17.7349037 },
    { lat: 48.2412334, lng: 17.7345456 },
    { lat: 48.2416839, lng: 17.7337284 },
    { lat: 48.2425762, lng: 17.7318433 },
    { lat: 48.2430199, lng: 17.7310642 },
    { lat: 48.2433038, lng: 17.7300761 },
    { lat: 48.2433771, lng: 17.7289487 },
    { lat: 48.2436605, lng: 17.7280056 },
    { lat: 48.2441671, lng: 17.7265854 },
    { lat: 48.2444822, lng: 17.7258585 },
    { lat: 48.2457001, lng: 17.7235148 },
    { lat: 48.2460093, lng: 17.7227313 },
    { lat: 48.2465448, lng: 17.7218298 },
    { lat: 48.246202, lng: 17.7211642 },
    { lat: 48.2457381, lng: 17.72028 },
    { lat: 48.2440512, lng: 17.7167265 },
    { lat: 48.2423814, lng: 17.7127629 },
    { lat: 48.2404887, lng: 17.7088125 },
    { lat: 48.2403043, lng: 17.7088408 },
    { lat: 48.2400795, lng: 17.70931 },
    { lat: 48.23978, lng: 17.7095573 },
    { lat: 48.2395759, lng: 17.7096351 },
    { lat: 48.2390424, lng: 17.7095701 },
    { lat: 48.2389401, lng: 17.7094899 },
    { lat: 48.2383965, lng: 17.7085593 },
    { lat: 48.2383872, lng: 17.7082649 },
    { lat: 48.2384461, lng: 17.707833 },
    { lat: 48.2383789, lng: 17.7075148 },
    { lat: 48.2382637, lng: 17.7073524 },
    { lat: 48.2380314, lng: 17.7072124 },
    { lat: 48.2379106, lng: 17.7071957 },
    { lat: 48.2377017, lng: 17.7073018 },
    { lat: 48.2374375, lng: 17.7076819 },
    { lat: 48.2373611, lng: 17.7079444 },
    { lat: 48.2373285, lng: 17.7084331 },
    { lat: 48.2374929, lng: 17.7090583 },
    { lat: 48.2379552, lng: 17.7098812 },
    { lat: 48.2379862, lng: 17.7100178 },
    { lat: 48.2378798, lng: 17.71086 },
    { lat: 48.2348594, lng: 17.7061941 },
    { lat: 48.2343675, lng: 17.7050422 },
    { lat: 48.2338469, lng: 17.7039725 },
    { lat: 48.2329225, lng: 17.7024937 },
    { lat: 48.2323703, lng: 17.7014462 },
    { lat: 48.2304096, lng: 17.6971004 },
    { lat: 48.2298572, lng: 17.6961214 },
    { lat: 48.2293856, lng: 17.6956393 },
    { lat: 48.2292798, lng: 17.6956077 },
    { lat: 48.2293598, lng: 17.6966727 },
    { lat: 48.2293032, lng: 17.6969596 },
    { lat: 48.229126, lng: 17.6973563 },
    { lat: 48.2289774, lng: 17.6975644 },
    { lat: 48.2288072, lng: 17.6976392 },
    { lat: 48.2283546, lng: 17.6975521 },
    { lat: 48.227937, lng: 17.6973015 },
    { lat: 48.2273671, lng: 17.6961872 },
    { lat: 48.2270099, lng: 17.6958545 },
    { lat: 48.2262548, lng: 17.6953158 },
    { lat: 48.2252597, lng: 17.6942459 },
    { lat: 48.2247291, lng: 17.6938698 },
    { lat: 48.2245671, lng: 17.693826 },
    { lat: 48.2243143, lng: 17.693934 },
    { lat: 48.2241296, lng: 17.6941964 },
    { lat: 48.2240166, lng: 17.6945376 },
    { lat: 48.2241809, lng: 17.6955917 },
    { lat: 48.2243243, lng: 17.6959544 },
    { lat: 48.2244671, lng: 17.6961415 },
    { lat: 48.2253177, lng: 17.6965656 },
    { lat: 48.2256955, lng: 17.6970272 },
    { lat: 48.2259262, lng: 17.6971216 },
    { lat: 48.2261679, lng: 17.6975219 },
    { lat: 48.2261744, lng: 17.697788 },
    { lat: 48.2259135, lng: 17.6982926 },
    { lat: 48.2249965, lng: 17.6990318 },
    { lat: 48.2247759, lng: 17.6991133 },
    { lat: 48.2243186, lng: 17.6991487 },
    { lat: 48.2234386, lng: 17.6989079 },
    { lat: 48.2230412, lng: 17.6990885 },
    { lat: 48.2228838, lng: 17.6993183 },
    { lat: 48.222818, lng: 17.699636 },
    { lat: 48.2229155, lng: 17.7000592 },
    { lat: 48.223278, lng: 17.7006554 },
    { lat: 48.2240694, lng: 17.7013962 },
    { lat: 48.2241749, lng: 17.7016764 },
    { lat: 48.2241614, lng: 17.7019293 },
    { lat: 48.2240027, lng: 17.7023173 },
    { lat: 48.2236374, lng: 17.702669 },
    { lat: 48.2227055, lng: 17.7031131 },
    { lat: 48.2221261, lng: 17.7030918 },
    { lat: 48.2219372, lng: 17.7030513 },
    { lat: 48.2211995, lng: 17.7026089 },
    { lat: 48.2209553, lng: 17.7026042 },
    { lat: 48.2207277, lng: 17.7027748 },
    { lat: 48.2203162, lng: 17.703453 },
    { lat: 48.2194355, lng: 17.7034176 },
    { lat: 48.2192137, lng: 17.703484 },
    { lat: 48.2189021, lng: 17.7038098 },
    { lat: 48.2188855, lng: 17.7044101 },
    { lat: 48.2189677, lng: 17.7045685 },
    { lat: 48.2193572, lng: 17.7048561 },
    { lat: 48.2202951, lng: 17.7051405 },
    { lat: 48.2204744, lng: 17.7052801 },
    { lat: 48.220514, lng: 17.7053726 },
    { lat: 48.220564, lng: 17.7056131 },
    { lat: 48.2205071, lng: 17.7060392 },
    { lat: 48.2203302, lng: 17.7063352 },
    { lat: 48.2199795, lng: 17.7065779 },
    { lat: 48.2193134, lng: 17.7072853 },
  ],
  Šintava: [
    { lat: 48.2793006, lng: 17.7480483 },
    { lat: 48.2791911, lng: 17.7481745 },
    { lat: 48.275803, lng: 17.7520913 },
    { lat: 48.2747568, lng: 17.7545235 },
    { lat: 48.2729125, lng: 17.7579588 },
    { lat: 48.2716633, lng: 17.7614516 },
    { lat: 48.2714654, lng: 17.7618859 },
    { lat: 48.2689889, lng: 17.7684256 },
    { lat: 48.2685541, lng: 17.7693562 },
    { lat: 48.2682295, lng: 17.7703701 },
    { lat: 48.2678665, lng: 17.7712911 },
    { lat: 48.2678692, lng: 17.772172 },
    { lat: 48.2677029, lng: 17.7759143 },
    { lat: 48.2692784, lng: 17.7802219 },
    { lat: 48.269511, lng: 17.7810816 },
    { lat: 48.270298, lng: 17.7830577 },
    { lat: 48.2704779, lng: 17.7840881 },
    { lat: 48.2704899, lng: 17.7847034 },
    { lat: 48.2703098, lng: 17.7851713 },
    { lat: 48.2689519, lng: 17.7865832 },
    { lat: 48.2654089, lng: 17.7858453 },
    { lat: 48.2645734, lng: 17.7865803 },
    { lat: 48.2625914, lng: 17.7885106 },
    { lat: 48.2637274, lng: 17.7908918 },
    { lat: 48.2637251, lng: 17.79102 },
    { lat: 48.264964, lng: 17.7936496 },
    { lat: 48.2655055, lng: 17.7952008 },
    { lat: 48.2657961, lng: 17.7964879 },
    { lat: 48.2660786, lng: 17.7983832 },
    { lat: 48.26613, lng: 17.7987754 },
    { lat: 48.2661504, lng: 17.8000056 },
    { lat: 48.2677035, lng: 17.7989719 },
    { lat: 48.2688044, lng: 17.7977834 },
    { lat: 48.2698806, lng: 17.7964313 },
    { lat: 48.2713663, lng: 17.7941572 },
    { lat: 48.2724205, lng: 17.7927264 },
    { lat: 48.272838, lng: 17.7922449 },
    { lat: 48.2728698, lng: 17.7983753 },
    { lat: 48.2729244, lng: 17.8014491 },
    { lat: 48.2729412, lng: 17.8023732 },
    { lat: 48.2729922, lng: 17.8057565 },
    { lat: 48.2730199, lng: 17.8057257 },
    { lat: 48.2743295, lng: 17.8041668 },
    { lat: 48.2790503, lng: 17.800657 },
    { lat: 48.2827795, lng: 17.7976152 },
    { lat: 48.2832353, lng: 17.7972436 },
    { lat: 48.2853903, lng: 17.7957735 },
    { lat: 48.2866961, lng: 17.794393 },
    { lat: 48.2877776, lng: 17.7933456 },
    { lat: 48.2904604, lng: 17.7914069 },
    { lat: 48.2912874, lng: 17.7906494 },
    { lat: 48.2915749, lng: 17.7904962 },
    { lat: 48.2927074, lng: 17.7896371 },
    { lat: 48.2934503, lng: 17.7888862 },
    { lat: 48.2946135, lng: 17.7871681 },
    { lat: 48.2954158, lng: 17.7886823 },
    { lat: 48.2954172, lng: 17.7904101 },
    { lat: 48.2966058, lng: 17.7915776 },
    { lat: 48.2992998, lng: 17.7952341 },
    { lat: 48.300035, lng: 17.7974005 },
    { lat: 48.3005567, lng: 17.7964664 },
    { lat: 48.3018546, lng: 17.7941705 },
    { lat: 48.3019521, lng: 17.7941201 },
    { lat: 48.3028637, lng: 17.7955595 },
    { lat: 48.3032276, lng: 17.7950531 },
    { lat: 48.3034953, lng: 17.7945399 },
    { lat: 48.3040345, lng: 17.7943132 },
    { lat: 48.3043235, lng: 17.7940736 },
    { lat: 48.3047488, lng: 17.7935924 },
    { lat: 48.3051639, lng: 17.7928531 },
    { lat: 48.3054334, lng: 17.7922239 },
    { lat: 48.3058297, lng: 17.7917492 },
    { lat: 48.3060343, lng: 17.7914213 },
    { lat: 48.3064394, lng: 17.7910159 },
    { lat: 48.3067743, lng: 17.7903312 },
    { lat: 48.3072539, lng: 17.7896134 },
    { lat: 48.3073782, lng: 17.7894696 },
    { lat: 48.3077945, lng: 17.789245 },
    { lat: 48.3082191, lng: 17.7887333 },
    { lat: 48.3085683, lng: 17.7885381 },
    { lat: 48.3091967, lng: 17.7880171 },
    { lat: 48.305887, lng: 17.781815 },
    { lat: 48.3052102, lng: 17.7821716 },
    { lat: 48.3051213, lng: 17.7823421 },
    { lat: 48.3049291, lng: 17.7831988 },
    { lat: 48.3028739, lng: 17.7839041 },
    { lat: 48.3027163, lng: 17.7840228 },
    { lat: 48.3015124, lng: 17.7791839 },
    { lat: 48.2995049, lng: 17.7732561 },
    { lat: 48.298035, lng: 17.7739338 },
    { lat: 48.297479, lng: 17.7740325 },
    { lat: 48.2950487, lng: 17.7693082 },
    { lat: 48.2939263, lng: 17.7666738 },
    { lat: 48.2931754, lng: 17.7652851 },
    { lat: 48.2927349, lng: 17.7646954 },
    { lat: 48.2930179, lng: 17.7643597 },
    { lat: 48.293638, lng: 17.7629259 },
    { lat: 48.2945364, lng: 17.7613858 },
    { lat: 48.2946377, lng: 17.761199 },
    { lat: 48.2946371, lng: 17.7610981 },
    { lat: 48.2949827, lng: 17.7609908 },
    { lat: 48.2950573, lng: 17.7611028 },
    { lat: 48.2953203, lng: 17.7611013 },
    { lat: 48.2965581, lng: 17.7606694 },
    { lat: 48.2968086, lng: 17.7605183 },
    { lat: 48.2984253, lng: 17.759989 },
    { lat: 48.2995452, lng: 17.7594405 },
    { lat: 48.299904, lng: 17.7596044 },
    { lat: 48.3006803, lng: 17.75881 },
    { lat: 48.300715, lng: 17.7588782 },
    { lat: 48.3022311, lng: 17.7581834 },
    { lat: 48.3031432, lng: 17.7572321 },
    { lat: 48.3035255, lng: 17.7566779 },
    { lat: 48.3037079, lng: 17.7562156 },
    { lat: 48.3038191, lng: 17.7555647 },
    { lat: 48.3041366, lng: 17.7551677 },
    { lat: 48.3045265, lng: 17.7549734 },
    { lat: 48.3047722, lng: 17.7544555 },
    { lat: 48.30476, lng: 17.7543498 },
    { lat: 48.3048426, lng: 17.7540457 },
    { lat: 48.3049629, lng: 17.7538719 },
    { lat: 48.3051055, lng: 17.753457 },
    { lat: 48.3051671, lng: 17.7527033 },
    { lat: 48.3052304, lng: 17.7525403 },
    { lat: 48.305514, lng: 17.7521618 },
    { lat: 48.3055726, lng: 17.7514451 },
    { lat: 48.3054802, lng: 17.7511948 },
    { lat: 48.3057504, lng: 17.7509093 },
    { lat: 48.3054583, lng: 17.7508798 },
    { lat: 48.3052682, lng: 17.7506906 },
    { lat: 48.305164, lng: 17.7504647 },
    { lat: 48.3049134, lng: 17.7502304 },
    { lat: 48.3049125, lng: 17.7500859 },
    { lat: 48.2985123, lng: 17.7516958 },
    { lat: 48.2982036, lng: 17.7529259 },
    { lat: 48.2975227, lng: 17.7592699 },
    { lat: 48.2969485, lng: 17.7593891 },
    { lat: 48.2954666, lng: 17.7598518 },
    { lat: 48.2949865, lng: 17.7599011 },
    { lat: 48.2943136, lng: 17.7597199 },
    { lat: 48.2933992, lng: 17.7591205 },
    { lat: 48.2923662, lng: 17.7583547 },
    { lat: 48.2912846, lng: 17.7572962 },
    { lat: 48.2910823, lng: 17.7570549 },
    { lat: 48.2907074, lng: 17.7564001 },
    { lat: 48.2893894, lng: 17.7534739 },
    { lat: 48.289065, lng: 17.7530103 },
    { lat: 48.2862546, lng: 17.7497652 },
    { lat: 48.2842187, lng: 17.7485775 },
    { lat: 48.2806002, lng: 17.7475934 },
    { lat: 48.2793006, lng: 17.7480483 },
  ],
  Šoporňa: [
    { lat: 48.2605997, lng: 17.7872449 },
    { lat: 48.2607541, lng: 17.7880994 },
    { lat: 48.2611119, lng: 17.7898926 },
    { lat: 48.2597294, lng: 17.7915661 },
    { lat: 48.2601006, lng: 17.7952242 },
    { lat: 48.2621735, lng: 17.7953558 },
    { lat: 48.2619968, lng: 17.8002972 },
    { lat: 48.2608806, lng: 17.801059 },
    { lat: 48.2571853, lng: 17.803581 },
    { lat: 48.2552952, lng: 17.8052007 },
    { lat: 48.2538425, lng: 17.8064455 },
    { lat: 48.2499405, lng: 17.8060369 },
    { lat: 48.2410386, lng: 17.8057411 },
    { lat: 48.2353987, lng: 17.8064972 },
    { lat: 48.2300902, lng: 17.8084769 },
    { lat: 48.2209294, lng: 17.8124503 },
    { lat: 48.2110787, lng: 17.8152827 },
    { lat: 48.20105, lng: 17.820477 },
    { lat: 48.200243, lng: 17.820896 },
    { lat: 48.197058, lng: 17.823217 },
    { lat: 48.193462, lng: 17.830422 },
    { lat: 48.19339, lng: 17.830557 },
    { lat: 48.193327, lng: 17.830659 },
    { lat: 48.193307, lng: 17.830693 },
    { lat: 48.193241, lng: 17.830801 },
    { lat: 48.193231, lng: 17.830818 },
    { lat: 48.193051, lng: 17.83112 },
    { lat: 48.192992, lng: 17.831192 },
    { lat: 48.192937, lng: 17.83126 },
    { lat: 48.192869, lng: 17.831351 },
    { lat: 48.1928, lng: 17.831449 },
    { lat: 48.192679, lng: 17.831574 },
    { lat: 48.1926, lng: 17.83162 },
    { lat: 48.19241, lng: 17.83175 },
    { lat: 48.192163, lng: 17.831885 },
    { lat: 48.191904, lng: 17.831969 },
    { lat: 48.191793, lng: 17.832002 },
    { lat: 48.191627, lng: 17.832049 },
    { lat: 48.191496, lng: 17.832082 },
    { lat: 48.191142, lng: 17.83212 },
    { lat: 48.190952, lng: 17.832154 },
    { lat: 48.190752, lng: 17.832189 },
    { lat: 48.19059, lng: 17.832237 },
    { lat: 48.190538, lng: 17.832255 },
    { lat: 48.19052, lng: 17.832265 },
    { lat: 48.19049, lng: 17.83228 },
    { lat: 48.190474, lng: 17.832289 },
    { lat: 48.19022, lng: 17.832433 },
    { lat: 48.189882, lng: 17.832632 },
    { lat: 48.189819, lng: 17.83267 },
    { lat: 48.189714, lng: 17.832724 },
    { lat: 48.189673, lng: 17.832745 },
    { lat: 48.189608, lng: 17.832785 },
    { lat: 48.189538, lng: 17.832834 },
    { lat: 48.189307, lng: 17.833019 },
    { lat: 48.189047, lng: 17.833217 },
    { lat: 48.188936, lng: 17.833291 },
    { lat: 48.188598, lng: 17.833506 },
    { lat: 48.188156, lng: 17.833786 },
    { lat: 48.187946, lng: 17.833867 },
    { lat: 48.187342, lng: 17.834247 },
    { lat: 48.18666, lng: 17.83473 },
    { lat: 48.18611, lng: 17.83512 },
    { lat: 48.185463, lng: 17.835498 },
    { lat: 48.185028, lng: 17.835661 },
    { lat: 48.18437, lng: 17.836233 },
    { lat: 48.184106, lng: 17.836562 },
    { lat: 48.183981, lng: 17.83664 },
    { lat: 48.183611, lng: 17.836919 },
    { lat: 48.183268, lng: 17.837273 },
    { lat: 48.18297, lng: 17.837478 },
    { lat: 48.182735, lng: 17.837646 },
    { lat: 48.183008, lng: 17.838207 },
    { lat: 48.183145, lng: 17.838486 },
    { lat: 48.18315, lng: 17.838501 },
    { lat: 48.183159, lng: 17.838534 },
    { lat: 48.183339, lng: 17.839238 },
    { lat: 48.183491, lng: 17.839745 },
    { lat: 48.183638, lng: 17.840307 },
    { lat: 48.183707, lng: 17.840574 },
    { lat: 48.183985, lng: 17.841388 },
    { lat: 48.184421, lng: 17.841297 },
    { lat: 48.184561, lng: 17.841634 },
    { lat: 48.185125, lng: 17.841177 },
    { lat: 48.185776, lng: 17.840652 },
    { lat: 48.185934, lng: 17.840761 },
    { lat: 48.186199, lng: 17.840948 },
    { lat: 48.186472, lng: 17.841139 },
    { lat: 48.186599, lng: 17.841227 },
    { lat: 48.186698, lng: 17.841299 },
    { lat: 48.186809, lng: 17.841374 },
    { lat: 48.186857, lng: 17.841408 },
    { lat: 48.186909, lng: 17.841445 },
    { lat: 48.186945, lng: 17.84147 },
    { lat: 48.187024, lng: 17.841526 },
    { lat: 48.187068, lng: 17.841558 },
    { lat: 48.187077, lng: 17.841564 },
    { lat: 48.187436, lng: 17.841844 },
    { lat: 48.187512, lng: 17.841899 },
    { lat: 48.187608, lng: 17.841963 },
    { lat: 48.187701, lng: 17.842024 },
    { lat: 48.187766, lng: 17.842074 },
    { lat: 48.187791, lng: 17.842088 },
    { lat: 48.1878, lng: 17.842093 },
    { lat: 48.187822, lng: 17.842121 },
    { lat: 48.187984, lng: 17.842312 },
    { lat: 48.188088, lng: 17.842434 },
    { lat: 48.188246, lng: 17.842607 },
    { lat: 48.188267, lng: 17.84263 },
    { lat: 48.188438, lng: 17.842816 },
    { lat: 48.188448, lng: 17.842827 },
    { lat: 48.188465, lng: 17.842844 },
    { lat: 48.188485, lng: 17.842866 },
    { lat: 48.188505, lng: 17.842887 },
    { lat: 48.188539, lng: 17.842923 },
    { lat: 48.188559, lng: 17.842947 },
    { lat: 48.188576, lng: 17.842965 },
    { lat: 48.188609, lng: 17.843005 },
    { lat: 48.188626, lng: 17.843025 },
    { lat: 48.188635, lng: 17.843034 },
    { lat: 48.188643, lng: 17.843044 },
    { lat: 48.188656, lng: 17.84306 },
    { lat: 48.188669, lng: 17.843075 },
    { lat: 48.188682, lng: 17.84309 },
    { lat: 48.18874, lng: 17.843161 },
    { lat: 48.188774, lng: 17.843202 },
    { lat: 48.188803, lng: 17.843235 },
    { lat: 48.18882, lng: 17.843255 },
    { lat: 48.188828, lng: 17.843263 },
    { lat: 48.188841, lng: 17.843279 },
    { lat: 48.188858, lng: 17.843297 },
    { lat: 48.188875, lng: 17.843316 },
    { lat: 48.189091, lng: 17.843559 },
    { lat: 48.189112, lng: 17.843584 },
    { lat: 48.189166, lng: 17.843653 },
    { lat: 48.189277, lng: 17.843821 },
    { lat: 48.189564, lng: 17.844451 },
    { lat: 48.18972, lng: 17.844906 },
    { lat: 48.189915, lng: 17.845477 },
    { lat: 48.190001, lng: 17.845679 },
    { lat: 48.190066, lng: 17.846003 },
    { lat: 48.190218, lng: 17.846597 },
    { lat: 48.190606, lng: 17.847887 },
    { lat: 48.191268, lng: 17.84908 },
    { lat: 48.191641, lng: 17.850436 },
    { lat: 48.191652, lng: 17.850482 },
    { lat: 48.192234, lng: 17.851959 },
    { lat: 48.192256, lng: 17.852015 },
    { lat: 48.192421, lng: 17.85243 },
    { lat: 48.19244, lng: 17.85248 },
    { lat: 48.192966, lng: 17.853811 },
    { lat: 48.194105, lng: 17.856439 },
    { lat: 48.194492, lng: 17.857198 },
    { lat: 48.194505, lng: 17.857223 },
    { lat: 48.194541, lng: 17.857287 },
    { lat: 48.19456, lng: 17.857323 },
    { lat: 48.194683, lng: 17.857607 },
    { lat: 48.194733, lng: 17.85772 },
    { lat: 48.195046, lng: 17.858443 },
    { lat: 48.195065, lng: 17.858486 },
    { lat: 48.195395, lng: 17.859252 },
    { lat: 48.195687, lng: 17.85987 },
    { lat: 48.195928, lng: 17.860596 },
    { lat: 48.196323, lng: 17.861719 },
    { lat: 48.19646, lng: 17.862358 },
    { lat: 48.196586, lng: 17.862949 },
    { lat: 48.196606, lng: 17.863045 },
    { lat: 48.196663, lng: 17.863257 },
    { lat: 48.196878, lng: 17.864117 },
    { lat: 48.197379, lng: 17.865746 },
    { lat: 48.197414, lng: 17.866857 },
    { lat: 48.197423, lng: 17.867204 },
    { lat: 48.197426, lng: 17.867255 },
    { lat: 48.197522, lng: 17.868527 },
    { lat: 48.197615, lng: 17.868791 },
    { lat: 48.197746, lng: 17.869162 },
    { lat: 48.198029, lng: 17.86993 },
    { lat: 48.198343, lng: 17.870789 },
    { lat: 48.198723, lng: 17.871837 },
    { lat: 48.198781, lng: 17.871974 },
    { lat: 48.199054, lng: 17.872627 },
    { lat: 48.199099, lng: 17.872733 },
    { lat: 48.199683, lng: 17.873819 },
    { lat: 48.20019, lng: 17.874867 },
    { lat: 48.200302, lng: 17.875191 },
    { lat: 48.200315, lng: 17.875227 },
    { lat: 48.200346, lng: 17.875313 },
    { lat: 48.200401, lng: 17.87547 },
    { lat: 48.200678, lng: 17.876259 },
    { lat: 48.200708, lng: 17.876345 },
    { lat: 48.200817, lng: 17.876655 },
    { lat: 48.20085, lng: 17.876749 },
    { lat: 48.200876, lng: 17.876822 },
    { lat: 48.200891, lng: 17.876866 },
    { lat: 48.200899, lng: 17.8769 },
    { lat: 48.201149, lng: 17.877135 },
    { lat: 48.201292, lng: 17.877277 },
    { lat: 48.201511, lng: 17.877406 },
    { lat: 48.202216, lng: 17.877661 },
    { lat: 48.202947, lng: 17.87802 },
    { lat: 48.203158, lng: 17.878133 },
    { lat: 48.20354, lng: 17.878354 },
    { lat: 48.203889, lng: 17.878495 },
    { lat: 48.204141, lng: 17.878604 },
    { lat: 48.204859, lng: 17.878889 },
    { lat: 48.205724, lng: 17.879257 },
    { lat: 48.206303, lng: 17.879506 },
    { lat: 48.206625, lng: 17.879592 },
    { lat: 48.206795, lng: 17.879645 },
    { lat: 48.206934, lng: 17.879744 },
    { lat: 48.207095, lng: 17.879939 },
    { lat: 48.207228, lng: 17.880156 },
    { lat: 48.207506, lng: 17.880568 },
    { lat: 48.207741, lng: 17.880813 },
    { lat: 48.208291, lng: 17.881636 },
    { lat: 48.2086002, lng: 17.8820921 },
    { lat: 48.208636, lng: 17.882062 },
    { lat: 48.208735, lng: 17.881977 },
    { lat: 48.209489, lng: 17.881326 },
    { lat: 48.210245, lng: 17.88097 },
    { lat: 48.210841, lng: 17.880716 },
    { lat: 48.211126, lng: 17.880588 },
    { lat: 48.211725, lng: 17.880373 },
    { lat: 48.212109, lng: 17.880235 },
    { lat: 48.212222, lng: 17.880205 },
    { lat: 48.212488, lng: 17.880519 },
    { lat: 48.212628, lng: 17.880707 },
    { lat: 48.212818, lng: 17.880972 },
    { lat: 48.212956, lng: 17.881162 },
    { lat: 48.213187, lng: 17.881453 },
    { lat: 48.213518, lng: 17.881778 },
    { lat: 48.213622, lng: 17.881909 },
    { lat: 48.213855, lng: 17.882128 },
    { lat: 48.213969, lng: 17.882168 },
    { lat: 48.214144, lng: 17.882196 },
    { lat: 48.214407, lng: 17.882209 },
    { lat: 48.214587, lng: 17.882188 },
    { lat: 48.214684, lng: 17.882153 },
    { lat: 48.214931, lng: 17.882025 },
    { lat: 48.215114, lng: 17.881865 },
    { lat: 48.215627, lng: 17.881205 },
    { lat: 48.216257, lng: 17.880477 },
    { lat: 48.216482, lng: 17.880208 },
    { lat: 48.216786, lng: 17.879821 },
    { lat: 48.217256, lng: 17.879164 },
    { lat: 48.217655, lng: 17.878719 },
    { lat: 48.218157, lng: 17.878288 },
    { lat: 48.218526, lng: 17.87798 },
    { lat: 48.219061, lng: 17.877458 },
    { lat: 48.219663, lng: 17.876771 },
    { lat: 48.220281, lng: 17.876064 },
    { lat: 48.220851, lng: 17.875409 },
    { lat: 48.221343, lng: 17.874801 },
    { lat: 48.221632, lng: 17.874347 },
    { lat: 48.222116, lng: 17.873348 },
    { lat: 48.222172, lng: 17.873215 },
    { lat: 48.222832, lng: 17.872268 },
    { lat: 48.223231, lng: 17.871806 },
    { lat: 48.224005, lng: 17.870917 },
    { lat: 48.224757, lng: 17.870325 },
    { lat: 48.226445, lng: 17.868959 },
    { lat: 48.225654, lng: 17.868409 },
    { lat: 48.225259, lng: 17.867094 },
    { lat: 48.225052, lng: 17.866415 },
    { lat: 48.225234, lng: 17.866034 },
    { lat: 48.226601, lng: 17.864119 },
    { lat: 48.226647, lng: 17.864042 },
    { lat: 48.227383, lng: 17.862672 },
    { lat: 48.228159, lng: 17.860951 },
    { lat: 48.228611, lng: 17.85991 },
    { lat: 48.229533, lng: 17.858339 },
    { lat: 48.230174, lng: 17.856983 },
    { lat: 48.23068, lng: 17.856392 },
    { lat: 48.230888, lng: 17.855238 },
    { lat: 48.231087, lng: 17.85387 },
    { lat: 48.231166, lng: 17.853627 },
    { lat: 48.231166, lng: 17.853542 },
    { lat: 48.231757, lng: 17.853166 },
    { lat: 48.23208, lng: 17.852923 },
    { lat: 48.232291, lng: 17.852717 },
    { lat: 48.232348, lng: 17.852659 },
    { lat: 48.232374, lng: 17.852684 },
    { lat: 48.232404, lng: 17.852623 },
    { lat: 48.23242, lng: 17.852645 },
    { lat: 48.232491, lng: 17.852546 },
    { lat: 48.232562, lng: 17.852487 },
    { lat: 48.232759, lng: 17.852314 },
    { lat: 48.232854, lng: 17.852254 },
    { lat: 48.232955, lng: 17.852189 },
    { lat: 48.233062, lng: 17.852309 },
    { lat: 48.233192, lng: 17.852393 },
    { lat: 48.233281, lng: 17.852382 },
    { lat: 48.234142, lng: 17.852021 },
    { lat: 48.234558, lng: 17.851843 },
    { lat: 48.234569, lng: 17.851839 },
    { lat: 48.23484, lng: 17.851708 },
    { lat: 48.23582, lng: 17.85127 },
    { lat: 48.236495, lng: 17.851003 },
    { lat: 48.236738, lng: 17.850902 },
    { lat: 48.237679, lng: 17.85057 },
    { lat: 48.23777, lng: 17.850538 },
    { lat: 48.237771, lng: 17.850537 },
    { lat: 48.237868, lng: 17.850496 },
    { lat: 48.23795, lng: 17.850461 },
    { lat: 48.237965, lng: 17.850454 },
    { lat: 48.238229, lng: 17.850339 },
    { lat: 48.239038, lng: 17.850022 },
    { lat: 48.239913, lng: 17.849675 },
    { lat: 48.240001, lng: 17.849636 },
    { lat: 48.240388, lng: 17.849492 },
    { lat: 48.240468, lng: 17.849447 },
    { lat: 48.241992, lng: 17.848874 },
    { lat: 48.242136, lng: 17.848818 },
    { lat: 48.242266, lng: 17.848758 },
    { lat: 48.242865, lng: 17.848374 },
    { lat: 48.244336, lng: 17.847456 },
    { lat: 48.245156, lng: 17.84693 },
    { lat: 48.24562, lng: 17.846644 },
    { lat: 48.246207, lng: 17.84627 },
    { lat: 48.246234, lng: 17.846373 },
    { lat: 48.246267, lng: 17.846503 },
    { lat: 48.246428, lng: 17.846815 },
    { lat: 48.246405, lng: 17.84684 },
    { lat: 48.246614, lng: 17.847251 },
    { lat: 48.246724, lng: 17.847473 },
    { lat: 48.247156, lng: 17.848242 },
    { lat: 48.247562, lng: 17.849034 },
    { lat: 48.248162, lng: 17.850362 },
    { lat: 48.248321, lng: 17.850672 },
    { lat: 48.248426, lng: 17.850878 },
    { lat: 48.248633, lng: 17.851293 },
    { lat: 48.248764, lng: 17.851576 },
    { lat: 48.248864, lng: 17.851823 },
    { lat: 48.248912, lng: 17.851929 },
    { lat: 48.249227, lng: 17.852614 },
    { lat: 48.249266, lng: 17.852695 },
    { lat: 48.249495, lng: 17.853181 },
    { lat: 48.249778, lng: 17.853779 },
    { lat: 48.250104, lng: 17.854424 },
    { lat: 48.250137, lng: 17.854492 },
    { lat: 48.250181, lng: 17.854581 },
    { lat: 48.250377, lng: 17.854962 },
    { lat: 48.250454, lng: 17.855107 },
    { lat: 48.250492, lng: 17.855174 },
    { lat: 48.250614, lng: 17.855379 },
    { lat: 48.251218, lng: 17.856378 },
    { lat: 48.251248, lng: 17.85633 },
    { lat: 48.251744, lng: 17.857454 },
    { lat: 48.252217, lng: 17.858435 },
    { lat: 48.252594, lng: 17.859083 },
    { lat: 48.254124, lng: 17.859105 },
    { lat: 48.255027, lng: 17.858561 },
    { lat: 48.256077, lng: 17.858356 },
    { lat: 48.257036, lng: 17.858083 },
    { lat: 48.257529, lng: 17.857841 },
    { lat: 48.258675, lng: 17.857142 },
    { lat: 48.259573, lng: 17.855669 },
    { lat: 48.260352, lng: 17.854107 },
    { lat: 48.261123, lng: 17.851671 },
    { lat: 48.261325, lng: 17.850654 },
    { lat: 48.2611689, lng: 17.8487436 },
    { lat: 48.2596566, lng: 17.8460202 },
    { lat: 48.2588744, lng: 17.8448043 },
    { lat: 48.2605222, lng: 17.8423966 },
    { lat: 48.2596723, lng: 17.840686 },
    { lat: 48.2590056, lng: 17.8395489 },
    { lat: 48.258219, lng: 17.8383348 },
    { lat: 48.2571595, lng: 17.8370374 },
    { lat: 48.2563599, lng: 17.8355195 },
    { lat: 48.2565064, lng: 17.8351993 },
    { lat: 48.2564986, lng: 17.8330112 },
    { lat: 48.2551026, lng: 17.8306794 },
    { lat: 48.2547602, lng: 17.8302302 },
    { lat: 48.2543181, lng: 17.8297791 },
    { lat: 48.2547059, lng: 17.8285919 },
    { lat: 48.2551087, lng: 17.8277412 },
    { lat: 48.2557839, lng: 17.8289382 },
    { lat: 48.2562077, lng: 17.8285037 },
    { lat: 48.2566706, lng: 17.8278236 },
    { lat: 48.2558522, lng: 17.8263718 },
    { lat: 48.2563787, lng: 17.8252561 },
    { lat: 48.2577703, lng: 17.8235375 },
    { lat: 48.2604963, lng: 17.8191533 },
    { lat: 48.2612603, lng: 17.8205658 },
    { lat: 48.2632493, lng: 17.8205258 },
    { lat: 48.2636034, lng: 17.8205755 },
    { lat: 48.2627412, lng: 17.8189624 },
    { lat: 48.2622207, lng: 17.8187953 },
    { lat: 48.2617623, lng: 17.8179674 },
    { lat: 48.2621107, lng: 17.8171315 },
    { lat: 48.2630042, lng: 17.8158179 },
    { lat: 48.2636238, lng: 17.8150782 },
    { lat: 48.2645024, lng: 17.814274 },
    { lat: 48.2649313, lng: 17.8136111 },
    { lat: 48.264947, lng: 17.8131508 },
    { lat: 48.269227, lng: 17.809551 },
    { lat: 48.2710308, lng: 17.8082809 },
    { lat: 48.2730062, lng: 17.8065685 },
    { lat: 48.2729922, lng: 17.8057565 },
    { lat: 48.2729412, lng: 17.8023732 },
    { lat: 48.2729244, lng: 17.8014491 },
    { lat: 48.2728698, lng: 17.7983753 },
    { lat: 48.272838, lng: 17.7922449 },
    { lat: 48.2724205, lng: 17.7927264 },
    { lat: 48.2713663, lng: 17.7941572 },
    { lat: 48.2698806, lng: 17.7964313 },
    { lat: 48.2688044, lng: 17.7977834 },
    { lat: 48.2677035, lng: 17.7989719 },
    { lat: 48.2661504, lng: 17.8000056 },
    { lat: 48.26613, lng: 17.7987754 },
    { lat: 48.2660786, lng: 17.7983832 },
    { lat: 48.2657961, lng: 17.7964879 },
    { lat: 48.2655055, lng: 17.7952008 },
    { lat: 48.264964, lng: 17.7936496 },
    { lat: 48.2637251, lng: 17.79102 },
    { lat: 48.2637274, lng: 17.7908918 },
    { lat: 48.2625914, lng: 17.7885106 },
    { lat: 48.2622478, lng: 17.7878059 },
    { lat: 48.2614013, lng: 17.7860909 },
    { lat: 48.2605997, lng: 17.7872449 },
  ],
  ZemianskeSady: [
    { lat: 48.340445, lng: 17.843566 },
    { lat: 48.3401873, lng: 17.8427063 },
    { lat: 48.3397698, lng: 17.8406734 },
    { lat: 48.3397453, lng: 17.840439 },
    { lat: 48.3398919, lng: 17.8396439 },
    { lat: 48.3398916, lng: 17.8392132 },
    { lat: 48.3398084, lng: 17.8382045 },
    { lat: 48.33962, lng: 17.8369502 },
    { lat: 48.339579, lng: 17.8354472 },
    { lat: 48.3394538, lng: 17.8349162 },
    { lat: 48.3384618, lng: 17.8324292 },
    { lat: 48.3380157, lng: 17.8317991 },
    { lat: 48.3374882, lng: 17.8312781 },
    { lat: 48.337183, lng: 17.8307713 },
    { lat: 48.336959, lng: 17.8303437 },
    { lat: 48.336592, lng: 17.8290506 },
    { lat: 48.3362818, lng: 17.8282964 },
    { lat: 48.3360898, lng: 17.8278879 },
    { lat: 48.335052, lng: 17.8261504 },
    { lat: 48.3349025, lng: 17.8257364 },
    { lat: 48.3348034, lng: 17.8251547 },
    { lat: 48.3347824, lng: 17.8242206 },
    { lat: 48.3348486, lng: 17.8230154 },
    { lat: 48.3346114, lng: 17.8218546 },
    { lat: 48.3328467, lng: 17.8184248 },
    { lat: 48.3317756, lng: 17.8170296 },
    { lat: 48.330524, lng: 17.812926 },
    { lat: 48.33016, lng: 17.8131 },
    { lat: 48.329871, lng: 17.813326 },
    { lat: 48.329591, lng: 17.813591 },
    { lat: 48.329382, lng: 17.813756 },
    { lat: 48.329213, lng: 17.813825 },
    { lat: 48.329102, lng: 17.813831 },
    { lat: 48.329, lng: 17.813807 },
    { lat: 48.32886, lng: 17.813866 },
    { lat: 48.328653, lng: 17.813835 },
    { lat: 48.328634, lng: 17.813822 },
    { lat: 48.328682, lng: 17.813755 },
    { lat: 48.328725, lng: 17.81366 },
    { lat: 48.328751, lng: 17.813547 },
    { lat: 48.328764, lng: 17.813404 },
    { lat: 48.328762, lng: 17.813252 },
    { lat: 48.328749, lng: 17.813117 },
    { lat: 48.328736, lng: 17.812995 },
    { lat: 48.328732, lng: 17.812799 },
    { lat: 48.328731, lng: 17.812604 },
    { lat: 48.32872, lng: 17.812401 },
    { lat: 48.32874, lng: 17.812131 },
    { lat: 48.328759, lng: 17.811962 },
    { lat: 48.328755, lng: 17.811826 },
    { lat: 48.328765, lng: 17.811595 },
    { lat: 48.328769, lng: 17.811351 },
    { lat: 48.328767, lng: 17.811126 },
    { lat: 48.328764, lng: 17.810763 },
    { lat: 48.32876, lng: 17.810482 },
    { lat: 48.328756, lng: 17.810171 },
    { lat: 48.328744, lng: 17.810014 },
    { lat: 48.32871, lng: 17.809737 },
    { lat: 48.328657, lng: 17.809464 },
    { lat: 48.328579, lng: 17.809138 },
    { lat: 48.32849, lng: 17.808864 },
    { lat: 48.328441, lng: 17.808675 },
    { lat: 48.328364, lng: 17.808309 },
    { lat: 48.328295, lng: 17.808018 },
    { lat: 48.328164, lng: 17.807642 },
    { lat: 48.328085, lng: 17.807419 },
    { lat: 48.327927, lng: 17.80702 },
    { lat: 48.327825, lng: 17.806693 },
    { lat: 48.327717, lng: 17.806313 },
    { lat: 48.327662, lng: 17.806153 },
    { lat: 48.327513, lng: 17.805761 },
    { lat: 48.327353, lng: 17.805351 },
    { lat: 48.327209, lng: 17.804951 },
    { lat: 48.327051, lng: 17.80454 },
    { lat: 48.326865, lng: 17.804033 },
    { lat: 48.326853, lng: 17.804037 },
    { lat: 48.326705, lng: 17.803325 },
    { lat: 48.326578, lng: 17.802679 },
    { lat: 48.326487, lng: 17.802236 },
    { lat: 48.326355, lng: 17.801563 },
    { lat: 48.326262, lng: 17.801097 },
    { lat: 48.326206, lng: 17.800838 },
    { lat: 48.326205, lng: 17.800794 },
    { lat: 48.326233, lng: 17.800173 },
    { lat: 48.326264, lng: 17.799596 },
    { lat: 48.326295, lng: 17.798963 },
    { lat: 48.326329, lng: 17.798222 },
    { lat: 48.326171, lng: 17.797954 },
    { lat: 48.326042, lng: 17.797749 },
    { lat: 48.325896, lng: 17.797511 },
    { lat: 48.325667, lng: 17.796871 },
    { lat: 48.325355, lng: 17.796007 },
    { lat: 48.32507, lng: 17.795518 },
    { lat: 48.324872, lng: 17.795193 },
    { lat: 48.324626, lng: 17.794658 },
    { lat: 48.324453, lng: 17.794277 },
    { lat: 48.324363, lng: 17.79409 },
    { lat: 48.324204, lng: 17.793754 },
    { lat: 48.3242, lng: 17.793541 },
    { lat: 48.324194, lng: 17.793021 },
    { lat: 48.323819, lng: 17.792261 },
    { lat: 48.323389, lng: 17.791361 },
    { lat: 48.323109, lng: 17.790767 },
    { lat: 48.322975, lng: 17.79044 },
    { lat: 48.322763, lng: 17.789935 },
    { lat: 48.322461, lng: 17.789208 },
    { lat: 48.322196, lng: 17.78861 },
    { lat: 48.3217208, lng: 17.7889391 },
    { lat: 48.3209548, lng: 17.789588 },
    { lat: 48.3199788, lng: 17.7907542 },
    { lat: 48.3188268, lng: 17.7926738 },
    { lat: 48.3185091, lng: 17.7933998 },
    { lat: 48.3179547, lng: 17.795178 },
    { lat: 48.3171007, lng: 17.7963237 },
    { lat: 48.3170684, lng: 17.7963877 },
    { lat: 48.3163226, lng: 17.796908 },
    { lat: 48.3155726, lng: 17.7972118 },
    { lat: 48.3149487, lng: 17.7975481 },
    { lat: 48.314319, lng: 17.7980151 },
    { lat: 48.3137592, lng: 17.798971 },
    { lat: 48.3135173, lng: 17.7997003 },
    { lat: 48.3137045, lng: 17.8007969 },
    { lat: 48.3140929, lng: 17.8043785 },
    { lat: 48.3143744, lng: 17.8056142 },
    { lat: 48.3143331, lng: 17.8060626 },
    { lat: 48.314353, lng: 17.8065173 },
    { lat: 48.3145107, lng: 17.807429 },
    { lat: 48.3143136, lng: 17.8078439 },
    { lat: 48.3143874, lng: 17.8083827 },
    { lat: 48.3145081, lng: 17.8102358 },
    { lat: 48.3143896, lng: 17.8116896 },
    { lat: 48.3140096, lng: 17.8141424 },
    { lat: 48.3136679, lng: 17.8158216 },
    { lat: 48.3136379, lng: 17.8162343 },
    { lat: 48.3136853, lng: 17.816548 },
    { lat: 48.3139143, lng: 17.8175205 },
    { lat: 48.3145559, lng: 17.8194957 },
    { lat: 48.3155213, lng: 17.8227586 },
    { lat: 48.315853, lng: 17.8227362 },
    { lat: 48.3161906, lng: 17.822517 },
    { lat: 48.3166179, lng: 17.8223602 },
    { lat: 48.317099, lng: 17.8220495 },
    { lat: 48.3177582, lng: 17.8236491 },
    { lat: 48.3182027, lng: 17.825081 },
    { lat: 48.3187273, lng: 17.8263459 },
    { lat: 48.3203325, lng: 17.8295699 },
    { lat: 48.3209729, lng: 17.8315869 },
    { lat: 48.3214705, lng: 17.8327446 },
    { lat: 48.3218139, lng: 17.8346479 },
    { lat: 48.3218498, lng: 17.8354009 },
    { lat: 48.3228084, lng: 17.837486 },
    { lat: 48.322838, lng: 17.8374709 },
    { lat: 48.3239239, lng: 17.8401493 },
    { lat: 48.3245412, lng: 17.8422307 },
    { lat: 48.3252922, lng: 17.8456173 },
    { lat: 48.3258044, lng: 17.8476079 },
    { lat: 48.326096, lng: 17.848443 },
    { lat: 48.326178, lng: 17.848404 },
    { lat: 48.326327, lng: 17.848337 },
    { lat: 48.326409, lng: 17.848305 },
    { lat: 48.326579, lng: 17.848248 },
    { lat: 48.326648, lng: 17.848227 },
    { lat: 48.326742, lng: 17.848318 },
    { lat: 48.326836, lng: 17.848437 },
    { lat: 48.326902, lng: 17.8485 },
    { lat: 48.326935, lng: 17.848512 },
    { lat: 48.326985, lng: 17.84851 },
    { lat: 48.32716, lng: 17.848403 },
    { lat: 48.32725, lng: 17.848346 },
    { lat: 48.327409, lng: 17.84827 },
    { lat: 48.327467, lng: 17.848246 },
    { lat: 48.3276, lng: 17.848245 },
    { lat: 48.327758, lng: 17.848255 },
    { lat: 48.32774, lng: 17.84832 },
    { lat: 48.328026, lng: 17.848384 },
    { lat: 48.328527, lng: 17.848508 },
    { lat: 48.329126, lng: 17.848655 },
    { lat: 48.329861, lng: 17.848832 },
    { lat: 48.330918, lng: 17.849085 },
    { lat: 48.332068, lng: 17.84936 },
    { lat: 48.332082, lng: 17.849344 },
    { lat: 48.332179, lng: 17.849572 },
    { lat: 48.332321, lng: 17.849947 },
    { lat: 48.332508, lng: 17.850413 },
    { lat: 48.332806, lng: 17.851183 },
    { lat: 48.333228, lng: 17.852266 },
    { lat: 48.333491, lng: 17.85292 },
    { lat: 48.333633, lng: 17.853288 },
    { lat: 48.333828, lng: 17.853699 },
    { lat: 48.333997, lng: 17.854044 },
    { lat: 48.334066, lng: 17.854201 },
    { lat: 48.334224, lng: 17.854533 },
    { lat: 48.334377, lng: 17.854851 },
    { lat: 48.334581, lng: 17.855284 },
    { lat: 48.33471, lng: 17.855548 },
    { lat: 48.334908, lng: 17.855981 },
    { lat: 48.335283, lng: 17.856779 },
    { lat: 48.33566, lng: 17.857581 },
    { lat: 48.336056, lng: 17.858418 },
    { lat: 48.336284, lng: 17.858902 },
    { lat: 48.336679, lng: 17.859738 },
    { lat: 48.336942, lng: 17.860307 },
    { lat: 48.337039, lng: 17.860522 },
    { lat: 48.337062, lng: 17.860506 },
    { lat: 48.338027, lng: 17.859844 },
    { lat: 48.338834, lng: 17.859291 },
    { lat: 48.33996, lng: 17.858514 },
    { lat: 48.340328, lng: 17.858266 },
    { lat: 48.340967, lng: 17.857831 },
    { lat: 48.342359, lng: 17.856889 },
    { lat: 48.342393, lng: 17.856821 },
    { lat: 48.342493, lng: 17.85725 },
    { lat: 48.342611, lng: 17.857703 },
    { lat: 48.342653, lng: 17.857842 },
    { lat: 48.342727, lng: 17.858106 },
    { lat: 48.342847, lng: 17.858539 },
    { lat: 48.342926, lng: 17.858877 },
    { lat: 48.342991, lng: 17.859149 },
    { lat: 48.343123, lng: 17.859572 },
    { lat: 48.3432, lng: 17.859801 },
    { lat: 48.343291, lng: 17.860022 },
    { lat: 48.343436, lng: 17.860372 },
    { lat: 48.343613, lng: 17.860746 },
    { lat: 48.343724, lng: 17.860978 },
    { lat: 48.343737, lng: 17.860948 },
    { lat: 48.343755, lng: 17.860997 },
    { lat: 48.344073, lng: 17.860638 },
    { lat: 48.344689, lng: 17.859932 },
    { lat: 48.345466, lng: 17.859048 },
    { lat: 48.346071, lng: 17.858366 },
    { lat: 48.346317, lng: 17.858091 },
    { lat: 48.346616, lng: 17.857755 },
    { lat: 48.347117, lng: 17.8572 },
    { lat: 48.347233, lng: 17.857073 },
    { lat: 48.34708, lng: 17.856835 },
    { lat: 48.346759, lng: 17.856317 },
    { lat: 48.346468, lng: 17.855947 },
    { lat: 48.34638, lng: 17.855832 },
    { lat: 48.346349, lng: 17.855737 },
    { lat: 48.346282, lng: 17.855279 },
    { lat: 48.34622, lng: 17.854765 },
    { lat: 48.34619, lng: 17.854514 },
    { lat: 48.346177, lng: 17.854278 },
    { lat: 48.346104, lng: 17.85401 },
    { lat: 48.345919, lng: 17.853707 },
    { lat: 48.345684, lng: 17.853296 },
    { lat: 48.345508, lng: 17.852986 },
    { lat: 48.345462, lng: 17.852898 },
    { lat: 48.345422, lng: 17.852812 },
    { lat: 48.345413, lng: 17.852683 },
    { lat: 48.345422, lng: 17.852564 },
    { lat: 48.345478, lng: 17.852328 },
    { lat: 48.345489, lng: 17.852145 },
    { lat: 48.345462, lng: 17.852083 },
    { lat: 48.34521, lng: 17.8518 },
    { lat: 48.34506, lng: 17.851511 },
    { lat: 48.344917, lng: 17.85112 },
    { lat: 48.344833, lng: 17.850792 },
    { lat: 48.344841, lng: 17.850688 },
    { lat: 48.345103, lng: 17.850277 },
    { lat: 48.345127, lng: 17.850211 },
    { lat: 48.345128, lng: 17.850107 },
    { lat: 48.345075, lng: 17.849757 },
    { lat: 48.34503, lng: 17.849583 },
    { lat: 48.344947, lng: 17.849479 },
    { lat: 48.344763, lng: 17.849328 },
    { lat: 48.344631, lng: 17.849198 },
    { lat: 48.344527, lng: 17.84906 },
    { lat: 48.344296, lng: 17.848643 },
    { lat: 48.344209, lng: 17.848559 },
    { lat: 48.343996, lng: 17.848536 },
    { lat: 48.343325, lng: 17.848486 },
    { lat: 48.343055, lng: 17.848464 },
    { lat: 48.342748, lng: 17.848457 },
    { lat: 48.342427, lng: 17.848484 },
    { lat: 48.342107, lng: 17.848535 },
    { lat: 48.341842, lng: 17.848591 },
    { lat: 48.341549, lng: 17.848656 },
    { lat: 48.341475, lng: 17.848449 },
    { lat: 48.341356, lng: 17.848014 },
    { lat: 48.341236, lng: 17.847576 },
    { lat: 48.341103, lng: 17.847092 },
    { lat: 48.340981, lng: 17.846642 },
    { lat: 48.340866, lng: 17.846236 },
    { lat: 48.340844, lng: 17.846126 },
    { lat: 48.340767, lng: 17.845666 },
    { lat: 48.340712, lng: 17.845299 },
    { lat: 48.340659, lng: 17.845052 },
    { lat: 48.340538, lng: 17.844201 },
    { lat: 48.340445, lng: 17.843566 },
  ],
  HornéSaliby: [
    { lat: 48.131157, lng: 17.787234 },
    { lat: 48.13132, lng: 17.78659 },
    { lat: 48.131393, lng: 17.786511 },
    { lat: 48.13234, lng: 17.785479 },
    { lat: 48.133335, lng: 17.784394 },
    { lat: 48.133365, lng: 17.784363 },
    { lat: 48.133716, lng: 17.783994 },
    { lat: 48.13384, lng: 17.783863 },
    { lat: 48.13385, lng: 17.783851 },
    { lat: 48.133891, lng: 17.783809 },
    { lat: 48.134113, lng: 17.783575 },
    { lat: 48.134479, lng: 17.78319 },
    { lat: 48.134601, lng: 17.783062 },
    { lat: 48.134803, lng: 17.782848 },
    { lat: 48.134812, lng: 17.78284 },
    { lat: 48.134849, lng: 17.782795 },
    { lat: 48.13486, lng: 17.782782 },
    { lat: 48.134964, lng: 17.782657 },
    { lat: 48.134979, lng: 17.78264 },
    { lat: 48.136674, lng: 17.780683 },
    { lat: 48.137965, lng: 17.779271 },
    { lat: 48.139557, lng: 17.777672 },
    { lat: 48.140177, lng: 17.77616 },
    { lat: 48.140283, lng: 17.775888 },
    { lat: 48.140344, lng: 17.775711 },
    { lat: 48.140576, lng: 17.775084 },
    { lat: 48.141001, lng: 17.773958 },
    { lat: 48.141402, lng: 17.772524 },
    { lat: 48.141671, lng: 17.771608 },
    { lat: 48.141719, lng: 17.771435 },
    { lat: 48.141816, lng: 17.771049 },
    { lat: 48.141837, lng: 17.770967 },
    { lat: 48.141852, lng: 17.770908 },
    { lat: 48.141868, lng: 17.770841 },
    { lat: 48.141881, lng: 17.770794 },
    { lat: 48.141905, lng: 17.770695 },
    { lat: 48.142572, lng: 17.768068 },
    { lat: 48.142752, lng: 17.767148 },
    { lat: 48.142757, lng: 17.767123 },
    { lat: 48.142786, lng: 17.76698 },
    { lat: 48.143071, lng: 17.765535 },
    { lat: 48.143206, lng: 17.764846 },
    { lat: 48.143959, lng: 17.763647 },
    { lat: 48.144668, lng: 17.762526 },
    { lat: 48.145206, lng: 17.761693 },
    { lat: 48.145632, lng: 17.760695 },
    { lat: 48.145941, lng: 17.759662 },
    { lat: 48.14599, lng: 17.759487 },
    { lat: 48.1455883, lng: 17.757123 },
    { lat: 48.1449622, lng: 17.7557304 },
    { lat: 48.1445293, lng: 17.7549038 },
    { lat: 48.1439021, lng: 17.7542215 },
    { lat: 48.1429451, lng: 17.7535362 },
    { lat: 48.1430998, lng: 17.7531016 },
    { lat: 48.1431185, lng: 17.7524047 },
    { lat: 48.1429308, lng: 17.7518362 },
    { lat: 48.1424361, lng: 17.751143 },
    { lat: 48.142453, lng: 17.7509038 },
    { lat: 48.1432759, lng: 17.749995 },
    { lat: 48.1436461, lng: 17.7497545 },
    { lat: 48.1438443, lng: 17.7492212 },
    { lat: 48.1439729, lng: 17.7486222 },
    { lat: 48.1443775, lng: 17.747865 },
    { lat: 48.1444277, lng: 17.7476371 },
    { lat: 48.1444367, lng: 17.7473439 },
    { lat: 48.1443544, lng: 17.7470364 },
    { lat: 48.1438368, lng: 17.7466123 },
    { lat: 48.1435843, lng: 17.7453663 },
    { lat: 48.1435965, lng: 17.7444561 },
    { lat: 48.1436504, lng: 17.7442344 },
    { lat: 48.1437337, lng: 17.7441012 },
    { lat: 48.1439234, lng: 17.7439225 },
    { lat: 48.1441122, lng: 17.7438683 },
    { lat: 48.1453966, lng: 17.7439707 },
    { lat: 48.1463482, lng: 17.7443143 },
    { lat: 48.146737, lng: 17.7443035 },
    { lat: 48.1470549, lng: 17.7439868 },
    { lat: 48.1471338, lng: 17.7438376 },
    { lat: 48.147448, lng: 17.7422292 },
    { lat: 48.1475398, lng: 17.7420157 },
    { lat: 48.1479522, lng: 17.7416773 },
    { lat: 48.1480877, lng: 17.7414595 },
    { lat: 48.1480219, lng: 17.7409995 },
    { lat: 48.1475128, lng: 17.7395316 },
    { lat: 48.1475079, lng: 17.7393283 },
    { lat: 48.1477203, lng: 17.7389849 },
    { lat: 48.1478761, lng: 17.7389491 },
    { lat: 48.1481552, lng: 17.738678 },
    { lat: 48.1482156, lng: 17.7385271 },
    { lat: 48.1482437, lng: 17.7379597 },
    { lat: 48.1481914, lng: 17.7368653 },
    { lat: 48.1482825, lng: 17.7363372 },
    { lat: 48.1484053, lng: 17.7359885 },
    { lat: 48.1486087, lng: 17.7357167 },
    { lat: 48.1492428, lng: 17.7356571 },
    { lat: 48.1497316, lng: 17.7350567 },
    { lat: 48.1502409, lng: 17.7347137 },
    { lat: 48.1508498, lng: 17.7340856 },
    { lat: 48.1510293, lng: 17.7337958 },
    { lat: 48.1511253, lng: 17.733525 },
    { lat: 48.1511755, lng: 17.7327176 },
    { lat: 48.1511338, lng: 17.7319749 },
    { lat: 48.1513835, lng: 17.7308721 },
    { lat: 48.1517127, lng: 17.7298335 },
    { lat: 48.1517458, lng: 17.7295481 },
    { lat: 48.1516786, lng: 17.7280329 },
    { lat: 48.1516077, lng: 17.7273855 },
    { lat: 48.1514536, lng: 17.7268713 },
    { lat: 48.1514353, lng: 17.7265786 },
    { lat: 48.1515744, lng: 17.726362 },
    { lat: 48.1520698, lng: 17.7263047 },
    { lat: 48.1521549, lng: 17.7261132 },
    { lat: 48.1523191, lng: 17.7253588 },
    { lat: 48.1523434, lng: 17.725331 },
    { lat: 48.1522914, lng: 17.7252598 },
    { lat: 48.1449835, lng: 17.7160394 },
    { lat: 48.1434046, lng: 17.7229433 },
    { lat: 48.1420513, lng: 17.7227507 },
    { lat: 48.1395443, lng: 17.7220886 },
    { lat: 48.1394992, lng: 17.7218934 },
    { lat: 48.1361243, lng: 17.7198097 },
    { lat: 48.1349781, lng: 17.7189546 },
    { lat: 48.1344112, lng: 17.7181927 },
    { lat: 48.1344478, lng: 17.7180208 },
    { lat: 48.1340783, lng: 17.7177266 },
    { lat: 48.1339764, lng: 17.7173984 },
    { lat: 48.1336004, lng: 17.716711 },
    { lat: 48.1335124, lng: 17.7164207 },
    { lat: 48.1334868, lng: 17.7160295 },
    { lat: 48.133612, lng: 17.7156476 },
    { lat: 48.1336667, lng: 17.7152961 },
    { lat: 48.1335627, lng: 17.7148575 },
    { lat: 48.1334655, lng: 17.7147109 },
    { lat: 48.1331838, lng: 17.7146134 },
    { lat: 48.1331868, lng: 17.7144985 },
    { lat: 48.1317046, lng: 17.7124442 },
    { lat: 48.1304443, lng: 17.710398 },
    { lat: 48.1289046, lng: 17.7081088 },
    { lat: 48.1251805, lng: 17.7036453 },
    { lat: 48.1237157, lng: 17.7022154 },
    { lat: 48.1219393, lng: 17.7002807 },
    { lat: 48.1204404, lng: 17.6988257 },
    { lat: 48.1187361, lng: 17.6974112 },
    { lat: 48.11592, lng: 17.6947635 },
    { lat: 48.1157721, lng: 17.6951022 },
    { lat: 48.1154332, lng: 17.6955163 },
    { lat: 48.1147976, lng: 17.6957146 },
    { lat: 48.1146674, lng: 17.6958155 },
    { lat: 48.1145105, lng: 17.6961427 },
    { lat: 48.1144314, lng: 17.6966106 },
    { lat: 48.1144763, lng: 17.6976335 },
    { lat: 48.1147585, lng: 17.6985156 },
    { lat: 48.1147805, lng: 17.6988287 },
    { lat: 48.1147481, lng: 17.6990138 },
    { lat: 48.1146506, lng: 17.6992263 },
    { lat: 48.1144394, lng: 17.699476 },
    { lat: 48.1139394, lng: 17.6995691 },
    { lat: 48.1137058, lng: 17.6998791 },
    { lat: 48.1136281, lng: 17.7001337 },
    { lat: 48.1136048, lng: 17.7005207 },
    { lat: 48.1136603, lng: 17.7008821 },
    { lat: 48.1137799, lng: 17.7012954 },
    { lat: 48.1140315, lng: 17.7017086 },
    { lat: 48.1143321, lng: 17.7018741 },
    { lat: 48.1145713, lng: 17.7018357 },
    { lat: 48.1150706, lng: 17.7015349 },
    { lat: 48.1152769, lng: 17.7015273 },
    { lat: 48.1153512, lng: 17.7015954 },
    { lat: 48.1154382, lng: 17.7021765 },
    { lat: 48.1154266, lng: 17.7028756 },
    { lat: 48.1153055, lng: 17.7033182 },
    { lat: 48.1148063, lng: 17.7041652 },
    { lat: 48.1145065, lng: 17.7048215 },
    { lat: 48.1142026, lng: 17.705179 },
    { lat: 48.1140506, lng: 17.7052416 },
    { lat: 48.1137449, lng: 17.705227 },
    { lat: 48.1130048, lng: 17.7047495 },
    { lat: 48.1128858, lng: 17.7048148 },
    { lat: 48.1127594, lng: 17.7050331 },
    { lat: 48.1127944, lng: 17.7055925 },
    { lat: 48.113072, lng: 17.7064935 },
    { lat: 48.1130166, lng: 17.7070333 },
    { lat: 48.1128925, lng: 17.7074654 },
    { lat: 48.1128578, lng: 17.7077971 },
    { lat: 48.1129569, lng: 17.7093625 },
    { lat: 48.112921, lng: 17.709782 },
    { lat: 48.1128593, lng: 17.7101506 },
    { lat: 48.1126675, lng: 17.7106394 },
    { lat: 48.1121397, lng: 17.7110829 },
    { lat: 48.1112704, lng: 17.7114613 },
    { lat: 48.1110229, lng: 17.7115322 },
    { lat: 48.1103226, lng: 17.7113811 },
    { lat: 48.1101522, lng: 17.71149 },
    { lat: 48.1099214, lng: 17.7123312 },
    { lat: 48.1098869, lng: 17.712665 },
    { lat: 48.1099186, lng: 17.7130827 },
    { lat: 48.1100229, lng: 17.7134486 },
    { lat: 48.1099636, lng: 17.7138731 },
    { lat: 48.1098651, lng: 17.7141027 },
    { lat: 48.1094449, lng: 17.7144293 },
    { lat: 48.1088795, lng: 17.7144862 },
    { lat: 48.1080768, lng: 17.7151004 },
    { lat: 48.1075912, lng: 17.71555 },
    { lat: 48.1074203, lng: 17.7158324 },
    { lat: 48.107259, lng: 17.7162682 },
    { lat: 48.1072572, lng: 17.7168001 },
    { lat: 48.1073397, lng: 17.7178138 },
    { lat: 48.1073049, lng: 17.7180864 },
    { lat: 48.1071477, lng: 17.7183192 },
    { lat: 48.1069618, lng: 17.7184334 },
    { lat: 48.1067261, lng: 17.7184563 },
    { lat: 48.1063455, lng: 17.7183804 },
    { lat: 48.1058981, lng: 17.7180329 },
    { lat: 48.105564, lng: 17.7179632 },
    { lat: 48.1051464, lng: 17.718275 },
    { lat: 48.1047553, lng: 17.7190232 },
    { lat: 48.1045494, lng: 17.7192879 },
    { lat: 48.103515, lng: 17.7202426 },
    { lat: 48.1031551, lng: 17.7210066 },
    { lat: 48.102953, lng: 17.7217867 },
    { lat: 48.1029294, lng: 17.7221464 },
    { lat: 48.103164, lng: 17.7232826 },
    { lat: 48.103067, lng: 17.7237186 },
    { lat: 48.1027498, lng: 17.7238284 },
    { lat: 48.1021045, lng: 17.7236003 },
    { lat: 48.101844, lng: 17.7235549 },
    { lat: 48.1016966, lng: 17.7236143 },
    { lat: 48.100897, lng: 17.7242455 },
    { lat: 48.1002907, lng: 17.7250571 },
    { lat: 48.0999806, lng: 17.7256421 },
    { lat: 48.0989376, lng: 17.7264254 },
    { lat: 48.0980655, lng: 17.7265432 },
    { lat: 48.0979358, lng: 17.7266361 },
    { lat: 48.0977665, lng: 17.7268352 },
    { lat: 48.097638, lng: 17.727131 },
    { lat: 48.0973004, lng: 17.7283774 },
    { lat: 48.0966868, lng: 17.7289109 },
    { lat: 48.0962249, lng: 17.7296147 },
    { lat: 48.0953483, lng: 17.7301876 },
    { lat: 48.0950928, lng: 17.7306882 },
    { lat: 48.0947593, lng: 17.7316034 },
    { lat: 48.0946193, lng: 17.7318587 },
    { lat: 48.0944957, lng: 17.7320122 },
    { lat: 48.0941442, lng: 17.7321886 },
    { lat: 48.0937453, lng: 17.7321984 },
    { lat: 48.0927943, lng: 17.7320304 },
    { lat: 48.0923041, lng: 17.731412 },
    { lat: 48.0918138, lng: 17.7311902 },
    { lat: 48.0912193, lng: 17.7313805 },
    { lat: 48.0907793, lng: 17.7313474 },
    { lat: 48.0905482, lng: 17.7308293 },
    { lat: 48.0902509, lng: 17.7306969 },
    { lat: 48.0896751, lng: 17.730987 },
    { lat: 48.0892407, lng: 17.7314987 },
    { lat: 48.0884158, lng: 17.7314502 },
    { lat: 48.087855, lng: 17.7313282 },
    { lat: 48.087725, lng: 17.7314162 },
    { lat: 48.0869857, lng: 17.7307682 },
    { lat: 48.0867094, lng: 17.7308649 },
    { lat: 48.0863983, lng: 17.7306175 },
    { lat: 48.0860009, lng: 17.7305685 },
    { lat: 48.0835868, lng: 17.730271 },
    { lat: 48.0834765, lng: 17.7299332 },
    { lat: 48.083106, lng: 17.7294777 },
    { lat: 48.0830135, lng: 17.7292091 },
    { lat: 48.0825802, lng: 17.7287595 },
    { lat: 48.0820238, lng: 17.7290331 },
    { lat: 48.0818257, lng: 17.7289572 },
    { lat: 48.0816059, lng: 17.7292328 },
    { lat: 48.0806805, lng: 17.7295651 },
    { lat: 48.080146, lng: 17.7300077 },
    { lat: 48.0800507, lng: 17.7301304 },
    { lat: 48.0803019, lng: 17.730463 },
    { lat: 48.0805132, lng: 17.7308993 },
    { lat: 48.0807486, lng: 17.7317225 },
    { lat: 48.0808772, lng: 17.7323207 },
    { lat: 48.0810202, lng: 17.7333985 },
    { lat: 48.0810359, lng: 17.733938 },
    { lat: 48.0809743, lng: 17.7346018 },
    { lat: 48.0807916, lng: 17.7354544 },
    { lat: 48.0801854, lng: 17.7374494 },
    { lat: 48.0801455, lng: 17.7380767 },
    { lat: 48.0800923, lng: 17.7382701 },
    { lat: 48.0800944, lng: 17.738563 },
    { lat: 48.0800957, lng: 17.7387424 },
    { lat: 48.0801769, lng: 17.7392862 },
    { lat: 48.0803997, lng: 17.739942 },
    { lat: 48.0808855, lng: 17.7407456 },
    { lat: 48.081482, lng: 17.7414936 },
    { lat: 48.0820398, lng: 17.7420598 },
    { lat: 48.0820351, lng: 17.7421664 },
    { lat: 48.0821591, lng: 17.74236 },
    { lat: 48.0822795, lng: 17.7427695 },
    { lat: 48.0823067, lng: 17.7433352 },
    { lat: 48.0822588, lng: 17.7436006 },
    { lat: 48.0821659, lng: 17.74392 },
    { lat: 48.0818072, lng: 17.7445428 },
    { lat: 48.0815539, lng: 17.7448777 },
    { lat: 48.0814266, lng: 17.7447944 },
    { lat: 48.0811383, lng: 17.7449516 },
    { lat: 48.08112, lng: 17.7449085 },
    { lat: 48.0809461, lng: 17.7451826 },
    { lat: 48.0800744, lng: 17.7459775 },
    { lat: 48.079681, lng: 17.7461881 },
    { lat: 48.0791279, lng: 17.74628 },
    { lat: 48.0782751, lng: 17.7458739 },
    { lat: 48.0774567, lng: 17.7452774 },
    { lat: 48.0770462, lng: 17.7447783 },
    { lat: 48.07637, lng: 17.7437113 },
    { lat: 48.0761537, lng: 17.7434634 },
    { lat: 48.0758713, lng: 17.7433349 },
    { lat: 48.0755981, lng: 17.7434081 },
    { lat: 48.0754521, lng: 17.7435286 },
    { lat: 48.0752086, lng: 17.7439161 },
    { lat: 48.0749803, lng: 17.7447093 },
    { lat: 48.0749462, lng: 17.7449607 },
    { lat: 48.074953, lng: 17.745776 },
    { lat: 48.0749967, lng: 17.7462049 },
    { lat: 48.0749231, lng: 17.7471456 },
    { lat: 48.0747703, lng: 17.7476099 },
    { lat: 48.074777, lng: 17.7483916 },
    { lat: 48.0745883, lng: 17.7494764 },
    { lat: 48.0742517, lng: 17.7502899 },
    { lat: 48.0740397, lng: 17.7506938 },
    { lat: 48.0740176, lng: 17.750664 },
    { lat: 48.073958, lng: 17.7507609 },
    { lat: 48.0739843, lng: 17.7509087 },
    { lat: 48.0738574, lng: 17.7511553 },
    { lat: 48.0733521, lng: 17.7516154 },
    { lat: 48.072766, lng: 17.7531588 },
    { lat: 48.0719472, lng: 17.7558935 },
    { lat: 48.071571, lng: 17.75807 },
    { lat: 48.0715796, lng: 17.7588814 },
    { lat: 48.0716528, lng: 17.7595839 },
    { lat: 48.0716472, lng: 17.7596412 },
    { lat: 48.0717565, lng: 17.7598364 },
    { lat: 48.0783226, lng: 17.7717252 },
    { lat: 48.0766826, lng: 17.7740095 },
    { lat: 48.0778908, lng: 17.7762334 },
    { lat: 48.0786998, lng: 17.7756451 },
    { lat: 48.0791816, lng: 17.7754219 },
    { lat: 48.0794815, lng: 17.7753932 },
    { lat: 48.0797641, lng: 17.7754576 },
    { lat: 48.0799714, lng: 17.7756136 },
    { lat: 48.080272, lng: 17.7760547 },
    { lat: 48.0805698, lng: 17.7762343 },
    { lat: 48.0816606, lng: 17.7764551 },
    { lat: 48.081987, lng: 17.7763267 },
    { lat: 48.0821, lng: 17.7761847 },
    { lat: 48.0822684, lng: 17.7757689 },
    { lat: 48.0824347, lng: 17.7757562 },
    { lat: 48.0827105, lng: 17.7760725 },
    { lat: 48.0830045, lng: 17.7767702 },
    { lat: 48.0830549, lng: 17.7766898 },
    { lat: 48.0834338, lng: 17.7770471 },
    { lat: 48.0836759, lng: 17.7774373 },
    { lat: 48.0841176, lng: 17.778538 },
    { lat: 48.0843866, lng: 17.7789955 },
    { lat: 48.0845667, lng: 17.7792065 },
    { lat: 48.0849944, lng: 17.7795881 },
    { lat: 48.0863248, lng: 17.7805418 },
    { lat: 48.0864419, lng: 17.7808089 },
    { lat: 48.0871, lng: 17.7805563 },
    { lat: 48.0877942, lng: 17.7808243 },
    { lat: 48.0883127, lng: 17.7812315 },
    { lat: 48.0883894, lng: 17.781412 },
    { lat: 48.0884549, lng: 17.7818125 },
    { lat: 48.0884707, lng: 17.7827058 },
    { lat: 48.088728, lng: 17.7835266 },
    { lat: 48.0888547, lng: 17.7837302 },
    { lat: 48.0891374, lng: 17.7837728 },
    { lat: 48.0894249, lng: 17.7836586 },
    { lat: 48.0898108, lng: 17.783254 },
    { lat: 48.0899382, lng: 17.7828855 },
    { lat: 48.0900586, lng: 17.7822587 },
    { lat: 48.0901834, lng: 17.7811224 },
    { lat: 48.0901541, lng: 17.7802839 },
    { lat: 48.0901883, lng: 17.7800906 },
    { lat: 48.0904326, lng: 17.7797148 },
    { lat: 48.0907976, lng: 17.7794274 },
    { lat: 48.0911894, lng: 17.7793557 },
    { lat: 48.0916494, lng: 17.779728 },
    { lat: 48.0918992, lng: 17.7801702 },
    { lat: 48.0919576, lng: 17.7806011 },
    { lat: 48.0919158, lng: 17.7817337 },
    { lat: 48.0920549, lng: 17.7823565 },
    { lat: 48.0922402, lng: 17.7827437 },
    { lat: 48.0931041, lng: 17.7838192 },
    { lat: 48.0934769, lng: 17.7845179 },
    { lat: 48.093776, lng: 17.7846793 },
    { lat: 48.0940909, lng: 17.7845508 },
    { lat: 48.0945123, lng: 17.7840654 },
    { lat: 48.0948394, lng: 17.7833655 },
    { lat: 48.0949396, lng: 17.7825949 },
    { lat: 48.0948558, lng: 17.7811889 },
    { lat: 48.0949508, lng: 17.7805917 },
    { lat: 48.0951872, lng: 17.7800678 },
    { lat: 48.0955985, lng: 17.7794711 },
    { lat: 48.0958998, lng: 17.7792144 },
    { lat: 48.0972522, lng: 17.7783564 },
    { lat: 48.0975865, lng: 17.7782841 },
    { lat: 48.0981356, lng: 17.7783835 },
    { lat: 48.098359, lng: 17.7784925 },
    { lat: 48.0987745, lng: 17.7789094 },
    { lat: 48.0994345, lng: 17.7803448 },
    { lat: 48.0999121, lng: 17.7810929 },
    { lat: 48.1001014, lng: 17.7818156 },
    { lat: 48.1004728, lng: 17.7826907 },
    { lat: 48.1006444, lng: 17.7828525 },
    { lat: 48.1008744, lng: 17.7829567 },
    { lat: 48.1010854, lng: 17.7829364 },
    { lat: 48.1012317, lng: 17.7827276 },
    { lat: 48.1012688, lng: 17.7823259 },
    { lat: 48.1010048, lng: 17.780851 },
    { lat: 48.1009533, lng: 17.7800009 },
    { lat: 48.1009787, lng: 17.7793182 },
    { lat: 48.1011129, lng: 17.7779286 },
    { lat: 48.1011354, lng: 17.7772724 },
    { lat: 48.1012405, lng: 17.7767995 },
    { lat: 48.1015548, lng: 17.7760808 },
    { lat: 48.1022955, lng: 17.7756263 },
    { lat: 48.10263, lng: 17.7755953 },
    { lat: 48.1028801, lng: 17.7756604 },
    { lat: 48.1033962, lng: 17.7758999 },
    { lat: 48.1036083, lng: 17.7760772 },
    { lat: 48.104164, lng: 17.7768094 },
    { lat: 48.1047282, lng: 17.7759695 },
    { lat: 48.1052363, lng: 17.7757704 },
    { lat: 48.1052582, lng: 17.7756646 },
    { lat: 48.1055178, lng: 17.7755553 },
    { lat: 48.1060735, lng: 17.7743764 },
    { lat: 48.1070932, lng: 17.7737237 },
    { lat: 48.1080661, lng: 17.7733364 },
    { lat: 48.1084134, lng: 17.7734579 },
    { lat: 48.1088826, lng: 17.7735051 },
    { lat: 48.1089773, lng: 17.7735941 },
    { lat: 48.109138, lng: 17.7739276 },
    { lat: 48.1100301, lng: 17.7729834 },
    { lat: 48.1100102, lng: 17.7724796 },
    { lat: 48.110211, lng: 17.7713677 },
    { lat: 48.1103203, lng: 17.7710173 },
    { lat: 48.1104385, lng: 17.7707749 },
    { lat: 48.1106904, lng: 17.7705952 },
    { lat: 48.1108347, lng: 17.7705753 },
    { lat: 48.1111319, lng: 17.7706334 },
    { lat: 48.1115812, lng: 17.7709585 },
    { lat: 48.111778, lng: 17.7712026 },
    { lat: 48.1122693, lng: 17.7723729 },
    { lat: 48.1125237, lng: 17.772649 },
    { lat: 48.1129595, lng: 17.7727075 },
    { lat: 48.1132271, lng: 17.7725044 },
    { lat: 48.1134815, lng: 17.7722011 },
    { lat: 48.1137214, lng: 17.7728661 },
    { lat: 48.1138736, lng: 17.7730994 },
    { lat: 48.114391, lng: 17.7735811 },
    { lat: 48.1148055, lng: 17.7738549 },
    { lat: 48.1153517, lng: 17.7744701 },
    { lat: 48.1158674, lng: 17.7748118 },
    { lat: 48.116313, lng: 17.7747534 },
    { lat: 48.116441, lng: 17.7748152 },
    { lat: 48.116696, lng: 17.775269 },
    { lat: 48.1167989, lng: 17.7757462 },
    { lat: 48.1169349, lng: 17.7756076 },
    { lat: 48.1170338, lng: 17.7756007 },
    { lat: 48.1179364, lng: 17.775701 },
    { lat: 48.1181224, lng: 17.7758421 },
    { lat: 48.1182939, lng: 17.7761127 },
    { lat: 48.1189392, lng: 17.7767072 },
    { lat: 48.1190491, lng: 17.7769129 },
    { lat: 48.1204591, lng: 17.7777685 },
    { lat: 48.1223121, lng: 17.7791227 },
    { lat: 48.1236064, lng: 17.7798252 },
    { lat: 48.1240827, lng: 17.7799877 },
    { lat: 48.124963, lng: 17.7820245 },
    { lat: 48.1265125, lng: 17.7840576 },
    { lat: 48.1273731, lng: 17.7842827 },
    { lat: 48.1277771, lng: 17.7842824 },
    { lat: 48.1283728, lng: 17.7844723 },
    { lat: 48.129172, lng: 17.7845464 },
    { lat: 48.1297848, lng: 17.7846898 },
    { lat: 48.1305024, lng: 17.785612 },
    { lat: 48.130585, lng: 17.7861823 },
    { lat: 48.1306816, lng: 17.7864818 },
    { lat: 48.1309563, lng: 17.7868541 },
    { lat: 48.131157, lng: 17.787234 },
  ],
  Abrahám: [
    { lat: 48.26493, lng: 17.596234 },
    { lat: 48.264464, lng: 17.597072 },
    { lat: 48.259493, lng: 17.591962 },
    { lat: 48.258153, lng: 17.591989 },
    { lat: 48.25754, lng: 17.591181 },
    { lat: 48.255182, lng: 17.588737 },
    { lat: 48.250873, lng: 17.583143 },
    { lat: 48.2489853, lng: 17.585467 },
    { lat: 48.2477335, lng: 17.5866665 },
    { lat: 48.2470205, lng: 17.5871624 },
    { lat: 48.2460832, lng: 17.5878143 },
    { lat: 48.2427696, lng: 17.5913355 },
    { lat: 48.2399799, lng: 17.5942999 },
    { lat: 48.2375647, lng: 17.5961954 },
    { lat: 48.2346063, lng: 17.600505 },
    { lat: 48.2335415, lng: 17.6014676 },
    { lat: 48.2327257, lng: 17.6020109 },
    { lat: 48.2319469, lng: 17.6027834 },
    { lat: 48.2298103, lng: 17.6057077 },
    { lat: 48.2287224, lng: 17.6070013 },
    { lat: 48.2284099, lng: 17.6072907 },
    { lat: 48.2264788, lng: 17.6092163 },
    { lat: 48.2255779, lng: 17.6106903 },
    { lat: 48.2254493, lng: 17.6109002 },
    { lat: 48.2224712, lng: 17.6152833 },
    { lat: 48.2243363, lng: 17.6180652 },
    { lat: 48.2268029, lng: 17.622162 },
    { lat: 48.2278083, lng: 17.6237412 },
    { lat: 48.2280149, lng: 17.6239705 },
    { lat: 48.2282443, lng: 17.623512 },
    { lat: 48.2283805, lng: 17.6233982 },
    { lat: 48.228671, lng: 17.6236326 },
    { lat: 48.2292036, lng: 17.6238937 },
    { lat: 48.2292686, lng: 17.6239755 },
    { lat: 48.2292932, lng: 17.6241328 },
    { lat: 48.2292732, lng: 17.624749 },
    { lat: 48.2294257, lng: 17.6250696 },
    { lat: 48.2294993, lng: 17.6253963 },
    { lat: 48.2296465, lng: 17.6256358 },
    { lat: 48.2298041, lng: 17.6256999 },
    { lat: 48.2300914, lng: 17.6253288 },
    { lat: 48.2303452, lng: 17.6251821 },
    { lat: 48.2306457, lng: 17.6258631 },
    { lat: 48.2307095, lng: 17.6263761 },
    { lat: 48.2306625, lng: 17.6269356 },
    { lat: 48.2307656, lng: 17.6270839 },
    { lat: 48.2310642, lng: 17.6272149 },
    { lat: 48.2313153, lng: 17.6270434 },
    { lat: 48.2317789, lng: 17.6265723 },
    { lat: 48.2322476, lng: 17.6265187 },
    { lat: 48.2329308, lng: 17.6272703 },
    { lat: 48.2337998, lng: 17.625798 },
    { lat: 48.2343574, lng: 17.6272251 },
    { lat: 48.2347276, lng: 17.6277398 },
    { lat: 48.2352389, lng: 17.6287375 },
    { lat: 48.2355807, lng: 17.629252 },
    { lat: 48.2358671, lng: 17.629906 },
    { lat: 48.2367065, lng: 17.6311496 },
    { lat: 48.2355276, lng: 17.6339776 },
    { lat: 48.2352152, lng: 17.6352741 },
    { lat: 48.2352165, lng: 17.6356136 },
    { lat: 48.2353478, lng: 17.6359967 },
    { lat: 48.235577, lng: 17.636123 },
    { lat: 48.2357884, lng: 17.6360333 },
    { lat: 48.2358279, lng: 17.6359618 },
    { lat: 48.235832, lng: 17.6357608 },
    { lat: 48.2355435, lng: 17.6351594 },
    { lat: 48.2355978, lng: 17.6349994 },
    { lat: 48.2358933, lng: 17.6350483 },
    { lat: 48.2363342, lng: 17.6354128 },
    { lat: 48.2365698, lng: 17.635551 },
    { lat: 48.2368043, lng: 17.6355819 },
    { lat: 48.2370232, lng: 17.6357246 },
    { lat: 48.2372435, lng: 17.6362984 },
    { lat: 48.2372753, lng: 17.6366751 },
    { lat: 48.2374644, lng: 17.6368446 },
    { lat: 48.2378466, lng: 17.6373566 },
    { lat: 48.2381834, lng: 17.6371284 },
    { lat: 48.238278, lng: 17.6370877 },
    { lat: 48.238354, lng: 17.6371294 },
    { lat: 48.2383993, lng: 17.6373487 },
    { lat: 48.2384041, lng: 17.6377479 },
    { lat: 48.2386172, lng: 17.6381177 },
    { lat: 48.2389278, lng: 17.638083 },
    { lat: 48.2392817, lng: 17.6378368 },
    { lat: 48.2394708, lng: 17.6374704 },
    { lat: 48.2394328, lng: 17.6371184 },
    { lat: 48.2396744, lng: 17.6374948 },
    { lat: 48.2398465, lng: 17.6378789 },
    { lat: 48.2405582, lng: 17.6396918 },
    { lat: 48.2411714, lng: 17.6419353 },
    { lat: 48.2407798, lng: 17.6422442 },
    { lat: 48.2405254, lng: 17.642577 },
    { lat: 48.2402829, lng: 17.6450578 },
    { lat: 48.2399695, lng: 17.6459422 },
    { lat: 48.2402008, lng: 17.6475003 },
    { lat: 48.2405182, lng: 17.6491001 },
    { lat: 48.2407068, lng: 17.6509137 },
    { lat: 48.2408421, lng: 17.6531545 },
    { lat: 48.2413109, lng: 17.6550781 },
    { lat: 48.2415629, lng: 17.6563202 },
    { lat: 48.2416966, lng: 17.6575847 },
    { lat: 48.2415435, lng: 17.6589212 },
    { lat: 48.2412262, lng: 17.6597309 },
    { lat: 48.2407706, lng: 17.6606378 },
    { lat: 48.2407089, lng: 17.6612369 },
    { lat: 48.2407438, lng: 17.6614155 },
    { lat: 48.2410488, lng: 17.6621196 },
    { lat: 48.2415332, lng: 17.6627843 },
    { lat: 48.2417579, lng: 17.6629825 },
    { lat: 48.24274, lng: 17.6633211 },
    { lat: 48.2428374, lng: 17.6637569 },
    { lat: 48.2427669, lng: 17.6646637 },
    { lat: 48.2429185, lng: 17.6654429 },
    { lat: 48.2430413, lng: 17.665816 },
    { lat: 48.2438534, lng: 17.6675281 },
    { lat: 48.2449911, lng: 17.6697553 },
    { lat: 48.2452331, lng: 17.6695615 },
    { lat: 48.2455659, lng: 17.6693606 },
    { lat: 48.2465159, lng: 17.6690049 },
    { lat: 48.2467218, lng: 17.6688144 },
    { lat: 48.2471731, lng: 17.6680502 },
    { lat: 48.2473266, lng: 17.667978 },
    { lat: 48.2476695, lng: 17.6680608 },
    { lat: 48.2478583, lng: 17.6682649 },
    { lat: 48.2479792, lng: 17.6688944 },
    { lat: 48.2483064, lng: 17.6695239 },
    { lat: 48.248447, lng: 17.6701245 },
    { lat: 48.2484396, lng: 17.6705644 },
    { lat: 48.2484905, lng: 17.6707458 },
    { lat: 48.2488123, lng: 17.671233 },
    { lat: 48.2489478, lng: 17.6718987 },
    { lat: 48.2495302, lng: 17.6723078 },
    { lat: 48.2497329, lng: 17.672319 },
    { lat: 48.2499223, lng: 17.6722789 },
    { lat: 48.250424, lng: 17.6717967 },
    { lat: 48.2507175, lng: 17.6717427 },
    { lat: 48.250927, lng: 17.6716077 },
    { lat: 48.2510799, lng: 17.671363 },
    { lat: 48.2511094, lng: 17.671041 },
    { lat: 48.251004, lng: 17.6704589 },
    { lat: 48.2507693, lng: 17.6699959 },
    { lat: 48.2504523, lng: 17.669676 },
    { lat: 48.250464, lng: 17.6694816 },
    { lat: 48.2507674, lng: 17.6689539 },
    { lat: 48.2508344, lng: 17.6684049 },
    { lat: 48.2510024, lng: 17.6676993 },
    { lat: 48.2509955, lng: 17.6670119 },
    { lat: 48.2510991, lng: 17.6668266 },
    { lat: 48.2518167, lng: 17.6665669 },
    { lat: 48.2522868, lng: 17.666144 },
    { lat: 48.2523911, lng: 17.6661318 },
    { lat: 48.2525148, lng: 17.6663191 },
    { lat: 48.2525647, lng: 17.6668808 },
    { lat: 48.2527069, lng: 17.6671676 },
    { lat: 48.2528816, lng: 17.6673605 },
    { lat: 48.2531649, lng: 17.6674666 },
    { lat: 48.2538279, lng: 17.6671664 },
    { lat: 48.2539825, lng: 17.6671971 },
    { lat: 48.254114, lng: 17.667494 },
    { lat: 48.2542895, lng: 17.6676113 },
    { lat: 48.2543888, lng: 17.6675906 },
    { lat: 48.2547473, lng: 17.6673117 },
    { lat: 48.2550122, lng: 17.6674682 },
    { lat: 48.2555319, lng: 17.6679233 },
    { lat: 48.2556834, lng: 17.6679623 },
    { lat: 48.2556789, lng: 17.6678892 },
    { lat: 48.25552, lng: 17.6669606 },
    { lat: 48.2545576, lng: 17.6636408 },
    { lat: 48.2534403, lng: 17.6602534 },
    { lat: 48.2546684, lng: 17.6589324 },
    { lat: 48.2553409, lng: 17.657649 },
    { lat: 48.2536893, lng: 17.654423 },
    { lat: 48.2523723, lng: 17.6513108 },
    { lat: 48.2521509, lng: 17.6492085 },
    { lat: 48.2514471, lng: 17.6462789 },
    { lat: 48.2524602, lng: 17.6457427 },
    { lat: 48.2519223, lng: 17.643532 },
    { lat: 48.2515434, lng: 17.6415132 },
    { lat: 48.2514925, lng: 17.6414215 },
    { lat: 48.2516879, lng: 17.6413171 },
    { lat: 48.2518927, lng: 17.6410887 },
    { lat: 48.2522719, lng: 17.6405136 },
    { lat: 48.2524121, lng: 17.6405342 },
    { lat: 48.2525858, lng: 17.6408158 },
    { lat: 48.2526945, lng: 17.6408394 },
    { lat: 48.252932, lng: 17.6404661 },
    { lat: 48.2531384, lng: 17.6403218 },
    { lat: 48.2535071, lng: 17.6402587 },
    { lat: 48.2537289, lng: 17.6401395 },
    { lat: 48.2540735, lng: 17.6403768 },
    { lat: 48.2544124, lng: 17.6401114 },
    { lat: 48.2543238, lng: 17.6400318 },
    { lat: 48.2540629, lng: 17.6393823 },
    { lat: 48.2540393, lng: 17.6391887 },
    { lat: 48.253783, lng: 17.6390828 },
    { lat: 48.253677, lng: 17.6389769 },
    { lat: 48.2539475, lng: 17.6385575 },
    { lat: 48.2549024, lng: 17.6367045 },
    { lat: 48.2548908, lng: 17.6364305 },
    { lat: 48.2546457, lng: 17.6350684 },
    { lat: 48.2564697, lng: 17.6313672 },
    { lat: 48.2590722, lng: 17.6262607 },
    { lat: 48.2631044, lng: 17.6186584 },
    { lat: 48.264996, lng: 17.6208062 },
    { lat: 48.2658281, lng: 17.6182122 },
    { lat: 48.266677, lng: 17.61681 },
    { lat: 48.269403, lng: 17.609321 },
    { lat: 48.270416, lng: 17.606821 },
    { lat: 48.267875, lng: 17.601696 },
    { lat: 48.267015, lng: 17.599818 },
    { lat: 48.266392, lng: 17.598596 },
    { lat: 48.265753, lng: 17.59744 },
    { lat: 48.26493, lng: 17.596234 },
  ],
  Vozokany: [
    { lat: 48.0992672, lng: 17.6695038 },
    { lat: 48.0988649, lng: 17.6694984 },
    { lat: 48.0982836, lng: 17.6696456 },
    { lat: 48.0980479, lng: 17.6697775 },
    { lat: 48.0979223, lng: 17.6698792 },
    { lat: 48.0977136, lng: 17.6704916 },
    { lat: 48.0979056, lng: 17.6715159 },
    { lat: 48.0980311, lng: 17.671842 },
    { lat: 48.0983824, lng: 17.6722918 },
    { lat: 48.0990297, lng: 17.6727843 },
    { lat: 48.0991151, lng: 17.6731188 },
    { lat: 48.0990597, lng: 17.6736002 },
    { lat: 48.0989519, lng: 17.6738605 },
    { lat: 48.0984849, lng: 17.6744081 },
    { lat: 48.0981053, lng: 17.674693 },
    { lat: 48.0979635, lng: 17.6747693 },
    { lat: 48.0973863, lng: 17.6745341 },
    { lat: 48.0971702, lng: 17.6741878 },
    { lat: 48.0970703, lng: 17.6736943 },
    { lat: 48.0970861, lng: 17.6722213 },
    { lat: 48.096938, lng: 17.6717324 },
    { lat: 48.0966528, lng: 17.6713432 },
    { lat: 48.0965017, lng: 17.6712408 },
    { lat: 48.0962259, lng: 17.6711806 },
    { lat: 48.0959503, lng: 17.6713061 },
    { lat: 48.0957683, lng: 17.6714439 },
    { lat: 48.0952891, lng: 17.6720108 },
    { lat: 48.0951951, lng: 17.6721342 },
    { lat: 48.095126, lng: 17.6723629 },
    { lat: 48.0950321, lng: 17.6728271 },
    { lat: 48.0950521, lng: 17.6733781 },
    { lat: 48.0951361, lng: 17.6737622 },
    { lat: 48.0953392, lng: 17.6741029 },
    { lat: 48.0962892, lng: 17.6746105 },
    { lat: 48.0966118, lng: 17.6746956 },
    { lat: 48.0969092, lng: 17.6749354 },
    { lat: 48.097092, lng: 17.6752235 },
    { lat: 48.0973484, lng: 17.6759975 },
    { lat: 48.0974979, lng: 17.6762538 },
    { lat: 48.0980285, lng: 17.6768276 },
    { lat: 48.0982769, lng: 17.6773844 },
    { lat: 48.0982726, lng: 17.6778015 },
    { lat: 48.0981467, lng: 17.6781706 },
    { lat: 48.0975253, lng: 17.6787369 },
    { lat: 48.09723, lng: 17.6791777 },
    { lat: 48.096816, lng: 17.6799919 },
    { lat: 48.0965836, lng: 17.6801424 },
    { lat: 48.0962029, lng: 17.6801727 },
    { lat: 48.0959065, lng: 17.679985 },
    { lat: 48.0956276, lng: 17.679661 },
    { lat: 48.0952362, lng: 17.6789119 },
    { lat: 48.095179, lng: 17.6786147 },
    { lat: 48.095206, lng: 17.6783397 },
    { lat: 48.0955224, lng: 17.6774492 },
    { lat: 48.0955843, lng: 17.6770142 },
    { lat: 48.0953986, lng: 17.6758036 },
    { lat: 48.0951907, lng: 17.6753348 },
    { lat: 48.0950701, lng: 17.675174 },
    { lat: 48.0948203, lng: 17.6750607 },
    { lat: 48.0944344, lng: 17.6750844 },
    { lat: 48.0940064, lng: 17.6751888 },
    { lat: 48.0936553, lng: 17.6754082 },
    { lat: 48.0930962, lng: 17.6760849 },
    { lat: 48.0928773, lng: 17.6765033 },
    { lat: 48.0927044, lng: 17.6770912 },
    { lat: 48.0925819, lng: 17.6777462 },
    { lat: 48.092577, lng: 17.6784776 },
    { lat: 48.0926407, lng: 17.6790627 },
    { lat: 48.092844, lng: 17.6798702 },
    { lat: 48.093104, lng: 17.6803961 },
    { lat: 48.0936419, lng: 17.6809063 },
    { lat: 48.0938784, lng: 17.6810403 },
    { lat: 48.0943918, lng: 17.6811983 },
    { lat: 48.0947691, lng: 17.681201 },
    { lat: 48.0952482, lng: 17.6813515 },
    { lat: 48.0953832, lng: 17.6814322 },
    { lat: 48.0957524, lng: 17.6818446 },
    { lat: 48.0958871, lng: 17.6821917 },
    { lat: 48.0959462, lng: 17.682775 },
    { lat: 48.0959084, lng: 17.6831596 },
    { lat: 48.0957049, lng: 17.6835713 },
    { lat: 48.0954134, lng: 17.6838911 },
    { lat: 48.0950595, lng: 17.6840604 },
    { lat: 48.0946629, lng: 17.6843468 },
    { lat: 48.0940489, lng: 17.6851229 },
    { lat: 48.0938035, lng: 17.6852607 },
    { lat: 48.0935498, lng: 17.6853022 },
    { lat: 48.0932921, lng: 17.6850822 },
    { lat: 48.0931336, lng: 17.6847519 },
    { lat: 48.0929616, lng: 17.6837185 },
    { lat: 48.0927488, lng: 17.6831557 },
    { lat: 48.0922807, lng: 17.6829281 },
    { lat: 48.0918244, lng: 17.6828545 },
    { lat: 48.0914414, lng: 17.6828855 },
    { lat: 48.090501, lng: 17.683698 },
    { lat: 48.0894473, lng: 17.6845016 },
    { lat: 48.0890329, lng: 17.684971 },
    { lat: 48.0887338, lng: 17.6855291 },
    { lat: 48.0885613, lng: 17.686037 },
    { lat: 48.0883197, lng: 17.6871182 },
    { lat: 48.0883006, lng: 17.6879105 },
    { lat: 48.0883336, lng: 17.688489 },
    { lat: 48.0883726, lng: 17.6884817 },
    { lat: 48.0885265, lng: 17.6895465 },
    { lat: 48.0886069, lng: 17.6906501 },
    { lat: 48.0889258, lng: 17.6913772 },
    { lat: 48.0888237, lng: 17.6916577 },
    { lat: 48.0886399, lng: 17.691878 },
    { lat: 48.088521, lng: 17.6921248 },
    { lat: 48.0880613, lng: 17.6931849 },
    { lat: 48.0879691, lng: 17.6932804 },
    { lat: 48.0879567, lng: 17.6933697 },
    { lat: 48.0878436, lng: 17.6934371 },
    { lat: 48.0881934, lng: 17.6950911 },
    { lat: 48.0885351, lng: 17.6957139 },
    { lat: 48.0886657, lng: 17.6965899 },
    { lat: 48.08867, lng: 17.696912 },
    { lat: 48.0886069, lng: 17.6969883 },
    { lat: 48.0885877, lng: 17.6973795 },
    { lat: 48.0885677, lng: 17.6977865 },
    { lat: 48.0884131, lng: 17.6984713 },
    { lat: 48.0881681, lng: 17.6992436 },
    { lat: 48.0879319, lng: 17.6996011 },
    { lat: 48.0872444, lng: 17.7001265 },
    { lat: 48.0859107, lng: 17.7005386 },
    { lat: 48.0856913, lng: 17.7005286 },
    { lat: 48.08535, lng: 17.7006338 },
    { lat: 48.0850397, lng: 17.7006196 },
    { lat: 48.083476, lng: 17.6993583 },
    { lat: 48.0827107, lng: 17.6989295 },
    { lat: 48.0823209, lng: 17.6985838 },
    { lat: 48.081727, lng: 17.6984743 },
    { lat: 48.0814627, lng: 17.69833 },
    { lat: 48.0812574, lng: 17.69846 },
    { lat: 48.0811345, lng: 17.6987138 },
    { lat: 48.0811705, lng: 17.6987635 },
    { lat: 48.0810915, lng: 17.6988924 },
    { lat: 48.0809394, lng: 17.6994362 },
    { lat: 48.0808358, lng: 17.7005399 },
    { lat: 48.0809648, lng: 17.7010124 },
    { lat: 48.080999, lng: 17.7021181 },
    { lat: 48.0808428, lng: 17.7038424 },
    { lat: 48.0808572, lng: 17.7044689 },
    { lat: 48.0809402, lng: 17.704839 },
    { lat: 48.081667, lng: 17.7065126 },
    { lat: 48.0818488, lng: 17.7071606 },
    { lat: 48.0816454, lng: 17.7093043 },
    { lat: 48.0816653, lng: 17.7106704 },
    { lat: 48.0815896, lng: 17.7112103 },
    { lat: 48.081463, lng: 17.7115506 },
    { lat: 48.0813315, lng: 17.7118133 },
    { lat: 48.0810114, lng: 17.712236 },
    { lat: 48.0804947, lng: 17.7128034 },
    { lat: 48.0797835, lng: 17.7133103 },
    { lat: 48.0788636, lng: 17.7136735 },
    { lat: 48.0782986, lng: 17.7136661 },
    { lat: 48.0780959, lng: 17.7137508 },
    { lat: 48.0774465, lng: 17.7142508 },
    { lat: 48.0761506, lng: 17.7150502 },
    { lat: 48.0756134, lng: 17.7152877 },
    { lat: 48.0751639, lng: 17.7152123 },
    { lat: 48.0745782, lng: 17.7152179 },
    { lat: 48.074274, lng: 17.7160387 },
    { lat: 48.0741476, lng: 17.7170519 },
    { lat: 48.0741679, lng: 17.7177364 },
    { lat: 48.0742357, lng: 17.7181019 },
    { lat: 48.0745042, lng: 17.718821 },
    { lat: 48.0746425, lng: 17.7190803 },
    { lat: 48.0748051, lng: 17.7191901 },
    { lat: 48.0750735, lng: 17.7197635 },
    { lat: 48.0756159, lng: 17.7209819 },
    { lat: 48.0759004, lng: 17.7218133 },
    { lat: 48.0760209, lng: 17.7219886 },
    { lat: 48.0760681, lng: 17.7226906 },
    { lat: 48.0759532, lng: 17.7232707 },
    { lat: 48.0757025, lng: 17.7239012 },
    { lat: 48.0753502, lng: 17.7245718 },
    { lat: 48.0750699, lng: 17.725379 },
    { lat: 48.0751159, lng: 17.7260971 },
    { lat: 48.0751954, lng: 17.7265594 },
    { lat: 48.075273, lng: 17.7267527 },
    { lat: 48.0756817, lng: 17.7275322 },
    { lat: 48.0760598, lng: 17.7280509 },
    { lat: 48.0762358, lng: 17.7282135 },
    { lat: 48.0767368, lng: 17.7284959 },
    { lat: 48.0779017, lng: 17.7293063 },
    { lat: 48.0785854, lng: 17.7294806 },
    { lat: 48.0791598, lng: 17.7295138 },
    { lat: 48.0796354, lng: 17.729734 },
    { lat: 48.0799515, lng: 17.730023 },
    { lat: 48.0800507, lng: 17.7301304 },
    { lat: 48.080146, lng: 17.7300077 },
    { lat: 48.0806805, lng: 17.7295651 },
    { lat: 48.0816059, lng: 17.7292328 },
    { lat: 48.0818257, lng: 17.7289572 },
    { lat: 48.0820238, lng: 17.7290331 },
    { lat: 48.0825802, lng: 17.7287595 },
    { lat: 48.0830135, lng: 17.7292091 },
    { lat: 48.083106, lng: 17.7294777 },
    { lat: 48.0834765, lng: 17.7299332 },
    { lat: 48.0835868, lng: 17.730271 },
    { lat: 48.0860009, lng: 17.7305685 },
    { lat: 48.0863983, lng: 17.7306175 },
    { lat: 48.0867094, lng: 17.7308649 },
    { lat: 48.0869857, lng: 17.7307682 },
    { lat: 48.087725, lng: 17.7314162 },
    { lat: 48.087855, lng: 17.7313282 },
    { lat: 48.0884158, lng: 17.7314502 },
    { lat: 48.0892407, lng: 17.7314987 },
    { lat: 48.0896751, lng: 17.730987 },
    { lat: 48.0902509, lng: 17.7306969 },
    { lat: 48.0905482, lng: 17.7308293 },
    { lat: 48.0907793, lng: 17.7313474 },
    { lat: 48.0912193, lng: 17.7313805 },
    { lat: 48.0918138, lng: 17.7311902 },
    { lat: 48.0923041, lng: 17.731412 },
    { lat: 48.0927943, lng: 17.7320304 },
    { lat: 48.0937453, lng: 17.7321984 },
    { lat: 48.0941442, lng: 17.7321886 },
    { lat: 48.0944957, lng: 17.7320122 },
    { lat: 48.0946193, lng: 17.7318587 },
    { lat: 48.0947593, lng: 17.7316034 },
    { lat: 48.0950928, lng: 17.7306882 },
    { lat: 48.0953483, lng: 17.7301876 },
    { lat: 48.0962249, lng: 17.7296147 },
    { lat: 48.0966868, lng: 17.7289109 },
    { lat: 48.0973004, lng: 17.7283774 },
    { lat: 48.097638, lng: 17.727131 },
    { lat: 48.0977665, lng: 17.7268352 },
    { lat: 48.0979358, lng: 17.7266361 },
    { lat: 48.0980655, lng: 17.7265432 },
    { lat: 48.0989376, lng: 17.7264254 },
    { lat: 48.0999806, lng: 17.7256421 },
    { lat: 48.1002907, lng: 17.7250571 },
    { lat: 48.100897, lng: 17.7242455 },
    { lat: 48.1016966, lng: 17.7236143 },
    { lat: 48.101844, lng: 17.7235549 },
    { lat: 48.1021045, lng: 17.7236003 },
    { lat: 48.1027498, lng: 17.7238284 },
    { lat: 48.103067, lng: 17.7237186 },
    { lat: 48.103164, lng: 17.7232826 },
    { lat: 48.1029294, lng: 17.7221464 },
    { lat: 48.102953, lng: 17.7217867 },
    { lat: 48.1031551, lng: 17.7210066 },
    { lat: 48.103515, lng: 17.7202426 },
    { lat: 48.1045494, lng: 17.7192879 },
    { lat: 48.1047553, lng: 17.7190232 },
    { lat: 48.1051464, lng: 17.718275 },
    { lat: 48.105564, lng: 17.7179632 },
    { lat: 48.1058981, lng: 17.7180329 },
    { lat: 48.1063455, lng: 17.7183804 },
    { lat: 48.1067261, lng: 17.7184563 },
    { lat: 48.1069618, lng: 17.7184334 },
    { lat: 48.1071477, lng: 17.7183192 },
    { lat: 48.1073049, lng: 17.7180864 },
    { lat: 48.1073397, lng: 17.7178138 },
    { lat: 48.1072572, lng: 17.7168001 },
    { lat: 48.107259, lng: 17.7162682 },
    { lat: 48.1074203, lng: 17.7158324 },
    { lat: 48.1075912, lng: 17.71555 },
    { lat: 48.1080768, lng: 17.7151004 },
    { lat: 48.1088795, lng: 17.7144862 },
    { lat: 48.1094449, lng: 17.7144293 },
    { lat: 48.1098651, lng: 17.7141027 },
    { lat: 48.1099636, lng: 17.7138731 },
    { lat: 48.1100229, lng: 17.7134486 },
    { lat: 48.1099186, lng: 17.7130827 },
    { lat: 48.1098869, lng: 17.712665 },
    { lat: 48.1099214, lng: 17.7123312 },
    { lat: 48.1101522, lng: 17.71149 },
    { lat: 48.1103226, lng: 17.7113811 },
    { lat: 48.1110229, lng: 17.7115322 },
    { lat: 48.1112704, lng: 17.7114613 },
    { lat: 48.1121397, lng: 17.7110829 },
    { lat: 48.1126675, lng: 17.7106394 },
    { lat: 48.1128593, lng: 17.7101506 },
    { lat: 48.112921, lng: 17.709782 },
    { lat: 48.1129569, lng: 17.7093625 },
    { lat: 48.1128578, lng: 17.7077971 },
    { lat: 48.1128925, lng: 17.7074654 },
    { lat: 48.1130166, lng: 17.7070333 },
    { lat: 48.113072, lng: 17.7064935 },
    { lat: 48.1127944, lng: 17.7055925 },
    { lat: 48.1127594, lng: 17.7050331 },
    { lat: 48.1128858, lng: 17.7048148 },
    { lat: 48.1130048, lng: 17.7047495 },
    { lat: 48.1137449, lng: 17.705227 },
    { lat: 48.1140506, lng: 17.7052416 },
    { lat: 48.1142026, lng: 17.705179 },
    { lat: 48.1145065, lng: 17.7048215 },
    { lat: 48.1148063, lng: 17.7041652 },
    { lat: 48.1153055, lng: 17.7033182 },
    { lat: 48.1154266, lng: 17.7028756 },
    { lat: 48.1154382, lng: 17.7021765 },
    { lat: 48.1153512, lng: 17.7015954 },
    { lat: 48.1152769, lng: 17.7015273 },
    { lat: 48.1150706, lng: 17.7015349 },
    { lat: 48.1145713, lng: 17.7018357 },
    { lat: 48.1143321, lng: 17.7018741 },
    { lat: 48.1140315, lng: 17.7017086 },
    { lat: 48.1137799, lng: 17.7012954 },
    { lat: 48.1136603, lng: 17.7008821 },
    { lat: 48.1136048, lng: 17.7005207 },
    { lat: 48.1136281, lng: 17.7001337 },
    { lat: 48.1137058, lng: 17.6998791 },
    { lat: 48.1139394, lng: 17.6995691 },
    { lat: 48.1144394, lng: 17.699476 },
    { lat: 48.1146506, lng: 17.6992263 },
    { lat: 48.1147481, lng: 17.6990138 },
    { lat: 48.1147805, lng: 17.6988287 },
    { lat: 48.1147585, lng: 17.6985156 },
    { lat: 48.1144763, lng: 17.6976335 },
    { lat: 48.1144314, lng: 17.6966106 },
    { lat: 48.1145105, lng: 17.6961427 },
    { lat: 48.1146674, lng: 17.6958155 },
    { lat: 48.1147976, lng: 17.6957146 },
    { lat: 48.1154332, lng: 17.6955163 },
    { lat: 48.1157721, lng: 17.6951022 },
    { lat: 48.11592, lng: 17.6947635 },
    { lat: 48.1159098, lng: 17.6945999 },
    { lat: 48.1159416, lng: 17.6944057 },
    { lat: 48.1158103, lng: 17.6941831 },
    { lat: 48.1157974, lng: 17.6940118 },
    { lat: 48.1160689, lng: 17.6930121 },
    { lat: 48.1161543, lng: 17.691314 },
    { lat: 48.1161331, lng: 17.6911001 },
    { lat: 48.1159953, lng: 17.6907939 },
    { lat: 48.1158888, lng: 17.6906798 },
    { lat: 48.1155689, lng: 17.6905469 },
    { lat: 48.1153734, lng: 17.6903845 },
    { lat: 48.1153079, lng: 17.6902032 },
    { lat: 48.1153205, lng: 17.6900269 },
    { lat: 48.1153591, lng: 17.689884 },
    { lat: 48.1154223, lng: 17.6898314 },
    { lat: 48.1158819, lng: 17.6897056 },
    { lat: 48.1166666, lng: 17.6890217 },
    { lat: 48.1169751, lng: 17.688486 },
    { lat: 48.1171251, lng: 17.6879608 },
    { lat: 48.117088, lng: 17.6876568 },
    { lat: 48.1167743, lng: 17.6869587 },
    { lat: 48.116489, lng: 17.685523 },
    { lat: 48.1162061, lng: 17.6849556 },
    { lat: 48.1159951, lng: 17.6848689 },
    { lat: 48.1158357, lng: 17.6849004 },
    { lat: 48.1156135, lng: 17.6851036 },
    { lat: 48.1154003, lng: 17.6854655 },
    { lat: 48.1149999, lng: 17.6857434 },
    { lat: 48.114841, lng: 17.6856385 },
    { lat: 48.1147451, lng: 17.6854967 },
    { lat: 48.1146008, lng: 17.6852835 },
    { lat: 48.1147021, lng: 17.684146 },
    { lat: 48.1147898, lng: 17.6838698 },
    { lat: 48.1148001, lng: 17.6831554 },
    { lat: 48.1148877, lng: 17.6825336 },
    { lat: 48.1147288, lng: 17.6801133 },
    { lat: 48.114912, lng: 17.6793079 },
    { lat: 48.115292, lng: 17.6787776 },
    { lat: 48.1155203, lng: 17.6773602 },
    { lat: 48.115373, lng: 17.6772074 },
    { lat: 48.1141439, lng: 17.6764101 },
    { lat: 48.1140696, lng: 17.6753895 },
    { lat: 48.114015, lng: 17.6753261 },
    { lat: 48.113968, lng: 17.6750303 },
    { lat: 48.1135021, lng: 17.6742094 },
    { lat: 48.1131962, lng: 17.6739428 },
    { lat: 48.1126808, lng: 17.6738253 },
    { lat: 48.1122628, lng: 17.6736235 },
    { lat: 48.1119802, lng: 17.6734229 },
    { lat: 48.1117198, lng: 17.6730891 },
    { lat: 48.1108783, lng: 17.6723835 },
    { lat: 48.1095925, lng: 17.6714527 },
    { lat: 48.1089426, lng: 17.6716561 },
    { lat: 48.1086903, lng: 17.6718473 },
    { lat: 48.1084389, lng: 17.6722132 },
    { lat: 48.1081827, lng: 17.6723198 },
    { lat: 48.1077222, lng: 17.6726659 },
    { lat: 48.1067329, lng: 17.6727887 },
    { lat: 48.1061675, lng: 17.6711238 },
    { lat: 48.106036, lng: 17.6710596 },
    { lat: 48.1058479, lng: 17.6712478 },
    { lat: 48.1055306, lng: 17.6713385 },
    { lat: 48.105265, lng: 17.6711709 },
    { lat: 48.1050129, lng: 17.6712214 },
    { lat: 48.104526, lng: 17.6719524 },
    { lat: 48.1045242, lng: 17.6724794 },
    { lat: 48.1044132, lng: 17.6728185 },
    { lat: 48.104299, lng: 17.6731549 },
    { lat: 48.1040125, lng: 17.6734816 },
    { lat: 48.1022924, lng: 17.6723994 },
    { lat: 48.1009683, lng: 17.6718492 },
    { lat: 48.100367, lng: 17.6714608 },
    { lat: 48.1001197, lng: 17.6712105 },
    { lat: 48.0994499, lng: 17.670019 },
    { lat: 48.0992672, lng: 17.6695038 },
  ],
  ČiernaVoda: [
    { lat: 48.1263826, lng: 17.6020304 },
    { lat: 48.1259749, lng: 17.6024734 },
    { lat: 48.1254996, lng: 17.6034745 },
    { lat: 48.1248888, lng: 17.6054119 },
    { lat: 48.125039, lng: 17.6067315 },
    { lat: 48.1250275, lng: 17.6073672 },
    { lat: 48.1247663, lng: 17.607594 },
    { lat: 48.1244202, lng: 17.6077256 },
    { lat: 48.1240163, lng: 17.6077492 },
    { lat: 48.1234154, lng: 17.6076579 },
    { lat: 48.122718, lng: 17.6073477 },
    { lat: 48.121932, lng: 17.6072918 },
    { lat: 48.121453, lng: 17.6076447 },
    { lat: 48.1212426, lng: 17.608048 },
    { lat: 48.1212462, lng: 17.6082981 },
    { lat: 48.1213476, lng: 17.6088824 },
    { lat: 48.1214985, lng: 17.6093071 },
    { lat: 48.1216212, lng: 17.6095141 },
    { lat: 48.1219722, lng: 17.609885 },
    { lat: 48.1227558, lng: 17.6103976 },
    { lat: 48.1230633, lng: 17.6103329 },
    { lat: 48.1240112, lng: 17.6103405 },
    { lat: 48.1242874, lng: 17.610475 },
    { lat: 48.1243521, lng: 17.6106227 },
    { lat: 48.1243836, lng: 17.6110678 },
    { lat: 48.1242648, lng: 17.6114734 },
    { lat: 48.1240489, lng: 17.6118976 },
    { lat: 48.1230036, lng: 17.6134898 },
    { lat: 48.1223688, lng: 17.6150699 },
    { lat: 48.1217352, lng: 17.6161391 },
    { lat: 48.1210252, lng: 17.6177128 },
    { lat: 48.120919, lng: 17.6178889 },
    { lat: 48.1203552, lng: 17.618417 },
    { lat: 48.1203204, lng: 17.6174607 },
    { lat: 48.1192718, lng: 17.6163077 },
    { lat: 48.1188496, lng: 17.6152055 },
    { lat: 48.1186991, lng: 17.6150218 },
    { lat: 48.1184681, lng: 17.6148495 },
    { lat: 48.1181509, lng: 17.6148505 },
    { lat: 48.117977, lng: 17.6149232 },
    { lat: 48.1177348, lng: 17.615144 },
    { lat: 48.117614, lng: 17.6153406 },
    { lat: 48.1175637, lng: 17.6156512 },
    { lat: 48.1175406, lng: 17.6167658 },
    { lat: 48.1173571, lng: 17.6172042 },
    { lat: 48.1168274, lng: 17.6177737 },
    { lat: 48.1166267, lng: 17.618259 },
    { lat: 48.1166099, lng: 17.6185794 },
    { lat: 48.1167202, lng: 17.6189136 },
    { lat: 48.1169911, lng: 17.6192524 },
    { lat: 48.1174208, lng: 17.6192786 },
    { lat: 48.117878, lng: 17.6189398 },
    { lat: 48.1184316, lng: 17.617982 },
    { lat: 48.1188393, lng: 17.6174997 },
    { lat: 48.1193007, lng: 17.6190088 },
    { lat: 48.1192645, lng: 17.6194539 },
    { lat: 48.1194444, lng: 17.6206883 },
    { lat: 48.1197169, lng: 17.621176 },
    { lat: 48.1199112, lng: 17.6214072 },
    { lat: 48.1205367, lng: 17.6216935 },
    { lat: 48.1206984, lng: 17.6219827 },
    { lat: 48.1206088, lng: 17.62226 },
    { lat: 48.1199143, lng: 17.6225638 },
    { lat: 48.119282, lng: 17.622529 },
    { lat: 48.1187506, lng: 17.62241 },
    { lat: 48.1185032, lng: 17.6225167 },
    { lat: 48.1180213, lng: 17.622895 },
    { lat: 48.1179253, lng: 17.6230563 },
    { lat: 48.1178362, lng: 17.6233084 },
    { lat: 48.117909, lng: 17.6241007 },
    { lat: 48.117729, lng: 17.6251109 },
    { lat: 48.1179233, lng: 17.6259333 },
    { lat: 48.1182195, lng: 17.6266891 },
    { lat: 48.1183432, lng: 17.6268741 },
    { lat: 48.1186498, lng: 17.6271351 },
    { lat: 48.1190085, lng: 17.6273071 },
    { lat: 48.1192678, lng: 17.6273373 },
    { lat: 48.1195692, lng: 17.6272096 },
    { lat: 48.120195, lng: 17.6266054 },
    { lat: 48.1206143, lng: 17.6263444 },
    { lat: 48.1208231, lng: 17.62638 },
    { lat: 48.1215433, lng: 17.6270703 },
    { lat: 48.1220501, lng: 17.6277837 },
    { lat: 48.1223025, lng: 17.6282396 },
    { lat: 48.1226958, lng: 17.6292609 },
    { lat: 48.1227541, lng: 17.6298616 },
    { lat: 48.1227115, lng: 17.6306359 },
    { lat: 48.1226039, lng: 17.6312724 },
    { lat: 48.1222849, lng: 17.6323875 },
    { lat: 48.122165, lng: 17.6325984 },
    { lat: 48.1218306, lng: 17.6329717 },
    { lat: 48.1212258, lng: 17.6331033 },
    { lat: 48.1207167, lng: 17.6329991 },
    { lat: 48.1205102, lng: 17.633071 },
    { lat: 48.120177, lng: 17.6333215 },
    { lat: 48.1200838, lng: 17.6334576 },
    { lat: 48.1199769, lng: 17.6338165 },
    { lat: 48.119895, lng: 17.6344875 },
    { lat: 48.1196845, lng: 17.6354465 },
    { lat: 48.1193, lng: 17.635881 },
    { lat: 48.1190129, lng: 17.636037 },
    { lat: 48.1188632, lng: 17.636046 },
    { lat: 48.1183522, lng: 17.6360177 },
    { lat: 48.1178491, lng: 17.6358847 },
    { lat: 48.1172416, lng: 17.6356138 },
    { lat: 48.1169078, lng: 17.6352669 },
    { lat: 48.1164226, lng: 17.6344483 },
    { lat: 48.1160075, lng: 17.6340803 },
    { lat: 48.1155331, lng: 17.6341143 },
    { lat: 48.1152619, lng: 17.63429 },
    { lat: 48.1151069, lng: 17.6344811 },
    { lat: 48.114948, lng: 17.6348081 },
    { lat: 48.1142794, lng: 17.6366097 },
    { lat: 48.114204, lng: 17.6375256 },
    { lat: 48.1142874, lng: 17.6380258 },
    { lat: 48.1146021, lng: 17.6388228 },
    { lat: 48.1147691, lng: 17.638983 },
    { lat: 48.115241, lng: 17.6390338 },
    { lat: 48.1158919, lng: 17.6389545 },
    { lat: 48.116264, lng: 17.6390162 },
    { lat: 48.1165598, lng: 17.6391212 },
    { lat: 48.1167561, lng: 17.6393861 },
    { lat: 48.1170342, lng: 17.639965 },
    { lat: 48.1172029, lng: 17.6407405 },
    { lat: 48.1172637, lng: 17.6414899 },
    { lat: 48.1171915, lng: 17.642275 },
    { lat: 48.1170871, lng: 17.6426614 },
    { lat: 48.1167295, lng: 17.6432033 },
    { lat: 48.1157289, lng: 17.6438819 },
    { lat: 48.1147232, lng: 17.6443154 },
    { lat: 48.1140348, lng: 17.6444306 },
    { lat: 48.1136824, lng: 17.6446085 },
    { lat: 48.1132396, lng: 17.6451768 },
    { lat: 48.1126927, lng: 17.6469946 },
    { lat: 48.112302, lng: 17.6473202 },
    { lat: 48.111998, lng: 17.6474414 },
    { lat: 48.1111434, lng: 17.6475507 },
    { lat: 48.1109343, lng: 17.6474914 },
    { lat: 48.1100998, lng: 17.6469891 },
    { lat: 48.1098216, lng: 17.6469297 },
    { lat: 48.1096272, lng: 17.647033 },
    { lat: 48.109174, lng: 17.6475122 },
    { lat: 48.1086108, lng: 17.6483023 },
    { lat: 48.107692, lng: 17.6501316 },
    { lat: 48.1075434, lng: 17.6508371 },
    { lat: 48.1076374, lng: 17.6512352 },
    { lat: 48.1054383, lng: 17.6492212 },
    { lat: 48.104385, lng: 17.6501179 },
    { lat: 48.1038935, lng: 17.6504022 },
    { lat: 48.1028498, lng: 17.6508269 },
    { lat: 48.1018807, lng: 17.6509632 },
    { lat: 48.0999774, lng: 17.651454 },
    { lat: 48.0993512, lng: 17.6517822 },
    { lat: 48.0990083, lng: 17.65222 },
    { lat: 48.0982251, lng: 17.6546257 },
    { lat: 48.0981435, lng: 17.6553132 },
    { lat: 48.0980717, lng: 17.6559185 },
    { lat: 48.098069, lng: 17.6561452 },
    { lat: 48.0980672, lng: 17.656295 },
    { lat: 48.0982103, lng: 17.6580085 },
    { lat: 48.0987923, lng: 17.6590553 },
    { lat: 48.0988753, lng: 17.6590284 },
    { lat: 48.0994098, lng: 17.659449 },
    { lat: 48.099732, lng: 17.6596171 },
    { lat: 48.1001703, lng: 17.6598335 },
    { lat: 48.1004522, lng: 17.6598985 },
    { lat: 48.1007096, lng: 17.659826 },
    { lat: 48.1017048, lng: 17.6591553 },
    { lat: 48.1026174, lng: 17.658351 },
    { lat: 48.103497, lng: 17.6577403 },
    { lat: 48.1040949, lng: 17.6575189 },
    { lat: 48.1047775, lng: 17.6574739 },
    { lat: 48.1051533, lng: 17.6575769 },
    { lat: 48.1057428, lng: 17.6578474 },
    { lat: 48.1064744, lng: 17.6586702 },
    { lat: 48.1072584, lng: 17.6605763 },
    { lat: 48.1075144, lng: 17.6623124 },
    { lat: 48.1075069, lng: 17.6630342 },
    { lat: 48.1069948, lng: 17.6630788 },
    { lat: 48.1066124, lng: 17.6634985 },
    { lat: 48.1065208, lng: 17.6637085 },
    { lat: 48.1060855, lng: 17.6653865 },
    { lat: 48.1057074, lng: 17.6662503 },
    { lat: 48.1054182, lng: 17.6666972 },
    { lat: 48.1052299, lng: 17.6668963 },
    { lat: 48.1045514, lng: 17.6673829 },
    { lat: 48.1042056, lng: 17.6674862 },
    { lat: 48.1034286, lng: 17.6674687 },
    { lat: 48.1025683, lng: 17.6672884 },
    { lat: 48.1019721, lng: 17.6670559 },
    { lat: 48.1019279, lng: 17.6672324 },
    { lat: 48.1020469, lng: 17.6679204 },
    { lat: 48.1023649, lng: 17.6684555 },
    { lat: 48.1024658, lng: 17.6687652 },
    { lat: 48.1026108, lng: 17.6695623 },
    { lat: 48.1025947, lng: 17.6697907 },
    { lat: 48.1024042, lng: 17.6703515 },
    { lat: 48.1021952, lng: 17.6706203 },
    { lat: 48.1018489, lng: 17.6708207 },
    { lat: 48.1013459, lng: 17.6707242 },
    { lat: 48.100971, lng: 17.6705457 },
    { lat: 48.1003877, lng: 17.6696911 },
    { lat: 48.1002679, lng: 17.6695994 },
    { lat: 48.0997101, lng: 17.6694346 },
    { lat: 48.0994734, lng: 17.6694788 },
    { lat: 48.0992672, lng: 17.6695038 },
    { lat: 48.0994499, lng: 17.670019 },
    { lat: 48.1001197, lng: 17.6712105 },
    { lat: 48.100367, lng: 17.6714608 },
    { lat: 48.1009683, lng: 17.6718492 },
    { lat: 48.1022924, lng: 17.6723994 },
    { lat: 48.1040125, lng: 17.6734816 },
    { lat: 48.104299, lng: 17.6731549 },
    { lat: 48.1044132, lng: 17.6728185 },
    { lat: 48.1045242, lng: 17.6724794 },
    { lat: 48.104526, lng: 17.6719524 },
    { lat: 48.1050129, lng: 17.6712214 },
    { lat: 48.105265, lng: 17.6711709 },
    { lat: 48.1055306, lng: 17.6713385 },
    { lat: 48.1058479, lng: 17.6712478 },
    { lat: 48.106036, lng: 17.6710596 },
    { lat: 48.1061675, lng: 17.6711238 },
    { lat: 48.1067329, lng: 17.6727887 },
    { lat: 48.1077222, lng: 17.6726659 },
    { lat: 48.1081827, lng: 17.6723198 },
    { lat: 48.1084389, lng: 17.6722132 },
    { lat: 48.1086903, lng: 17.6718473 },
    { lat: 48.1089426, lng: 17.6716561 },
    { lat: 48.1095925, lng: 17.6714527 },
    { lat: 48.1108783, lng: 17.6723835 },
    { lat: 48.1117198, lng: 17.6730891 },
    { lat: 48.1119802, lng: 17.6734229 },
    { lat: 48.1122628, lng: 17.6736235 },
    { lat: 48.1126808, lng: 17.6738253 },
    { lat: 48.1131962, lng: 17.6739428 },
    { lat: 48.1135021, lng: 17.6742094 },
    { lat: 48.113968, lng: 17.6750303 },
    { lat: 48.114015, lng: 17.6753261 },
    { lat: 48.1140696, lng: 17.6753895 },
    { lat: 48.1141439, lng: 17.6764101 },
    { lat: 48.115373, lng: 17.6772074 },
    { lat: 48.1155203, lng: 17.6773602 },
    { lat: 48.1156756, lng: 17.6775696 },
    { lat: 48.1158563, lng: 17.6777298 },
    { lat: 48.1168213, lng: 17.6780295 },
    { lat: 48.1179565, lng: 17.6779457 },
    { lat: 48.1181291, lng: 17.677853 },
    { lat: 48.1183056, lng: 17.6776647 },
    { lat: 48.1185985, lng: 17.6769349 },
    { lat: 48.1186816, lng: 17.6759933 },
    { lat: 48.1186631, lng: 17.6756612 },
    { lat: 48.1185287, lng: 17.6751758 },
    { lat: 48.1184183, lng: 17.6749821 },
    { lat: 48.1177927, lng: 17.6744605 },
    { lat: 48.1172802, lng: 17.6740332 },
    { lat: 48.1170016, lng: 17.6736387 },
    { lat: 48.1167044, lng: 17.6729005 },
    { lat: 48.1166027, lng: 17.6722699 },
    { lat: 48.1166297, lng: 17.6716944 },
    { lat: 48.1166737, lng: 17.6713324 },
    { lat: 48.1169349, lng: 17.6706771 },
    { lat: 48.1171919, lng: 17.6705439 },
    { lat: 48.1175198, lng: 17.6705308 },
    { lat: 48.1183905, lng: 17.6707874 },
    { lat: 48.1188227, lng: 17.6710724 },
    { lat: 48.1195293, lng: 17.6713899 },
    { lat: 48.1202489, lng: 17.671842 },
    { lat: 48.1209298, lng: 17.6721688 },
    { lat: 48.1212372, lng: 17.6721353 },
    { lat: 48.1217248, lng: 17.6715778 },
    { lat: 48.1218308, lng: 17.6713216 },
    { lat: 48.1219336, lng: 17.6708764 },
    { lat: 48.1219218, lng: 17.6706512 },
    { lat: 48.1218544, lng: 17.6701726 },
    { lat: 48.1214795, lng: 17.668735 },
    { lat: 48.1213873, lng: 17.6677009 },
    { lat: 48.1213898, lng: 17.6671313 },
    { lat: 48.1215692, lng: 17.6664636 },
    { lat: 48.1219392, lng: 17.6654699 },
    { lat: 48.1221344, lng: 17.6646079 },
    { lat: 48.1223629, lng: 17.6638598 },
    { lat: 48.1227738, lng: 17.6631773 },
    { lat: 48.1230777, lng: 17.6625173 },
    { lat: 48.1235638, lng: 17.6617179 },
    { lat: 48.1236489, lng: 17.6602895 },
    { lat: 48.1238536, lng: 17.659238 },
    { lat: 48.1240381, lng: 17.6588308 },
    { lat: 48.1245136, lng: 17.6580437 },
    { lat: 48.1249973, lng: 17.6574251 },
    { lat: 48.1254766, lng: 17.6566994 },
    { lat: 48.1266564, lng: 17.6553899 },
    { lat: 48.1280613, lng: 17.6550161 },
    { lat: 48.128515, lng: 17.6551725 },
    { lat: 48.1292608, lng: 17.6556116 },
    { lat: 48.1305623, lng: 17.6565748 },
    { lat: 48.1321977, lng: 17.65692 },
    { lat: 48.1323735, lng: 17.6565556 },
    { lat: 48.132464, lng: 17.6560385 },
    { lat: 48.1325038, lng: 17.6554536 },
    { lat: 48.1327413, lng: 17.6545624 },
    { lat: 48.13274, lng: 17.6537167 },
    { lat: 48.1353135, lng: 17.6500123 },
    { lat: 48.1355601, lng: 17.6485866 },
    { lat: 48.1355272, lng: 17.6473827 },
    { lat: 48.1356728, lng: 17.6441621 },
    { lat: 48.1360327, lng: 17.6432436 },
    { lat: 48.1364482, lng: 17.6425803 },
    { lat: 48.1370198, lng: 17.6422643 },
    { lat: 48.1361547, lng: 17.6412062 },
    { lat: 48.136902, lng: 17.6400567 },
    { lat: 48.1381336, lng: 17.6377971 },
    { lat: 48.1380161, lng: 17.6376712 },
    { lat: 48.1385589, lng: 17.6364501 },
    { lat: 48.1393099, lng: 17.6357909 },
    { lat: 48.1400648, lng: 17.6352292 },
    { lat: 48.1401448, lng: 17.6347092 },
    { lat: 48.1403458, lng: 17.6341721 },
    { lat: 48.1407807, lng: 17.6336215 },
    { lat: 48.1411557, lng: 17.6327116 },
    { lat: 48.1409204, lng: 17.6321509 },
    { lat: 48.1411035, lng: 17.6310991 },
    { lat: 48.1409055, lng: 17.6290225 },
    { lat: 48.1406741, lng: 17.6286815 },
    { lat: 48.1404545, lng: 17.6281727 },
    { lat: 48.1403267, lng: 17.6275285 },
    { lat: 48.1401405, lng: 17.6254418 },
    { lat: 48.1402876, lng: 17.6248752 },
    { lat: 48.1405762, lng: 17.6244558 },
    { lat: 48.1414819, lng: 17.623496 },
    { lat: 48.1412471, lng: 17.623386 },
    { lat: 48.1414579, lng: 17.6229713 },
    { lat: 48.1417449, lng: 17.6225825 },
    { lat: 48.1418915, lng: 17.6221174 },
    { lat: 48.1419485, lng: 17.62148 },
    { lat: 48.1425262, lng: 17.6204399 },
    { lat: 48.1427496, lng: 17.6199392 },
    { lat: 48.1426104, lng: 17.6196319 },
    { lat: 48.1423411, lng: 17.6196377 },
    { lat: 48.1421509, lng: 17.6186867 },
    { lat: 48.1421785, lng: 17.6183565 },
    { lat: 48.1425002, lng: 17.6169082 },
    { lat: 48.1430126, lng: 17.616619 },
    { lat: 48.1432093, lng: 17.6166424 },
    { lat: 48.144111, lng: 17.617291 },
    { lat: 48.1442459, lng: 17.6172274 },
    { lat: 48.1446728, lng: 17.6167841 },
    { lat: 48.1453138, lng: 17.6160295 },
    { lat: 48.1452402, lng: 17.6154728 },
    { lat: 48.1450054, lng: 17.6143173 },
    { lat: 48.1447656, lng: 17.613759 },
    { lat: 48.1444559, lng: 17.613252 },
    { lat: 48.1444078, lng: 17.6130116 },
    { lat: 48.1440467, lng: 17.6127456 },
    { lat: 48.1437529, lng: 17.6124219 },
    { lat: 48.142809, lng: 17.6107686 },
    { lat: 48.1416658, lng: 17.6089785 },
    { lat: 48.1414327, lng: 17.609434 },
    { lat: 48.1411256, lng: 17.6097373 },
    { lat: 48.1407724, lng: 17.609794 },
    { lat: 48.1403838, lng: 17.6097411 },
    { lat: 48.1403127, lng: 17.6095444 },
    { lat: 48.1399858, lng: 17.609002 },
    { lat: 48.1388236, lng: 17.60809 },
    { lat: 48.1379811, lng: 17.6075867 },
    { lat: 48.1375385, lng: 17.6073956 },
    { lat: 48.1372137, lng: 17.6074903 },
    { lat: 48.1370643, lng: 17.6076076 },
    { lat: 48.1372927, lng: 17.6082021 },
    { lat: 48.1373044, lng: 17.60842 },
    { lat: 48.1372511, lng: 17.6087032 },
    { lat: 48.1371927, lng: 17.6088861 },
    { lat: 48.136982, lng: 17.6091242 },
    { lat: 48.136661, lng: 17.6092255 },
    { lat: 48.1359364, lng: 17.6093566 },
    { lat: 48.1352976, lng: 17.6093989 },
    { lat: 48.1346182, lng: 17.6093491 },
    { lat: 48.1333429, lng: 17.6095034 },
    { lat: 48.1327251, lng: 17.6093766 },
    { lat: 48.1317455, lng: 17.6089737 },
    { lat: 48.1313159, lng: 17.6087005 },
    { lat: 48.1311009, lng: 17.6084813 },
    { lat: 48.1306851, lng: 17.6077456 },
    { lat: 48.1304955, lng: 17.6071966 },
    { lat: 48.1303682, lng: 17.6059199 },
    { lat: 48.1304393, lng: 17.6048335 },
    { lat: 48.1303883, lng: 17.6043844 },
    { lat: 48.1302245, lng: 17.6036562 },
    { lat: 48.1299732, lng: 17.603093 },
    { lat: 48.129845, lng: 17.6029072 },
    { lat: 48.1294955, lng: 17.602664 },
    { lat: 48.1281818, lng: 17.6021619 },
    { lat: 48.1269024, lng: 17.6018564 },
    { lat: 48.1265706, lng: 17.6019161 },
    { lat: 48.1263826, lng: 17.6020304 },
  ],
  Tomášikovo: [
    { lat: 48.066673, lng: 17.68788 },
    { lat: 48.06667, lng: 17.688076 },
    { lat: 48.066697, lng: 17.688313 },
    { lat: 48.066717, lng: 17.68842 },
    { lat: 48.066719, lng: 17.688599 },
    { lat: 48.066704, lng: 17.688807 },
    { lat: 48.066678, lng: 17.689005 },
    { lat: 48.066633, lng: 17.689216 },
    { lat: 48.066615, lng: 17.689365 },
    { lat: 48.066597, lng: 17.689504 },
    { lat: 48.066586, lng: 17.689585 },
    { lat: 48.066557, lng: 17.689686 },
    { lat: 48.066528, lng: 17.689811 },
    { lat: 48.06652, lng: 17.689881 },
    { lat: 48.066508, lng: 17.689947 },
    { lat: 48.06648, lng: 17.690059 },
    { lat: 48.066447, lng: 17.690223 },
    { lat: 48.066389, lng: 17.69039 },
    { lat: 48.066364, lng: 17.690497 },
    { lat: 48.06629, lng: 17.690735 },
    { lat: 48.066159, lng: 17.691067 },
    { lat: 48.066119, lng: 17.691194 },
    { lat: 48.066085, lng: 17.691287 },
    { lat: 48.065939, lng: 17.691615 },
    { lat: 48.065858, lng: 17.691785 },
    { lat: 48.065753, lng: 17.69197 },
    { lat: 48.0657, lng: 17.692064 },
    { lat: 48.065647, lng: 17.692147 },
    { lat: 48.065593, lng: 17.692226 },
    { lat: 48.065495, lng: 17.692367 },
    { lat: 48.065378, lng: 17.692515 },
    { lat: 48.065283, lng: 17.692638 },
    { lat: 48.065101, lng: 17.692885 },
    { lat: 48.064912, lng: 17.693129 },
    { lat: 48.064776, lng: 17.693289 },
    { lat: 48.064653, lng: 17.69342 },
    { lat: 48.064498, lng: 17.693593 },
    { lat: 48.064358, lng: 17.69372 },
    { lat: 48.064162, lng: 17.693918 },
    { lat: 48.064099, lng: 17.694001 },
    { lat: 48.063918, lng: 17.694174 },
    { lat: 48.063839, lng: 17.694229 },
    { lat: 48.06365, lng: 17.694347 },
    { lat: 48.063288, lng: 17.694603 },
    { lat: 48.063218, lng: 17.694649 },
    { lat: 48.063089, lng: 17.694713 },
    { lat: 48.062741, lng: 17.694947 },
    { lat: 48.062616, lng: 17.695038 },
    { lat: 48.062482, lng: 17.695165 },
    { lat: 48.062256, lng: 17.695294 },
    { lat: 48.062419, lng: 17.695717 },
    { lat: 48.062514, lng: 17.695963 },
    { lat: 48.062456, lng: 17.696255 },
    { lat: 48.062241, lng: 17.6965 },
    { lat: 48.062134, lng: 17.696615 },
    { lat: 48.061898, lng: 17.696765 },
    { lat: 48.061608, lng: 17.696949 },
    { lat: 48.061242, lng: 17.697184 },
    { lat: 48.061075, lng: 17.697294 },
    { lat: 48.060846, lng: 17.697454 },
    { lat: 48.060477, lng: 17.697707 },
    { lat: 48.060457, lng: 17.697906 },
    { lat: 48.060316, lng: 17.698521 },
    { lat: 48.060215, lng: 17.698934 },
    { lat: 48.059907, lng: 17.700091 },
    { lat: 48.059809, lng: 17.700573 },
    { lat: 48.059767, lng: 17.700803 },
    { lat: 48.059799, lng: 17.701164 },
    { lat: 48.059819, lng: 17.701326 },
    { lat: 48.05991, lng: 17.701797 },
    { lat: 48.059939, lng: 17.702152 },
    { lat: 48.059965, lng: 17.70266 },
    { lat: 48.059953, lng: 17.703035 },
    { lat: 48.059847, lng: 17.703647 },
    { lat: 48.059779, lng: 17.704006 },
    { lat: 48.059695, lng: 17.704435 },
    { lat: 48.059666, lng: 17.704741 },
    { lat: 48.059593, lng: 17.705076 },
    { lat: 48.059603, lng: 17.70518 },
    { lat: 48.059647, lng: 17.705588 },
    { lat: 48.059668, lng: 17.706136 },
    { lat: 48.059657, lng: 17.706622 },
    { lat: 48.059569, lng: 17.706953 },
    { lat: 48.059513, lng: 17.707253 },
    { lat: 48.059481, lng: 17.707452 },
    { lat: 48.059319, lng: 17.70802 },
    { lat: 48.05935, lng: 17.70813 },
    { lat: 48.059517, lng: 17.708401 },
    { lat: 48.059742, lng: 17.708423 },
    { lat: 48.059743, lng: 17.708461 },
    { lat: 48.059744, lng: 17.708529 },
    { lat: 48.059745, lng: 17.708561 },
    { lat: 48.059747, lng: 17.708594 },
    { lat: 48.059782, lng: 17.708943 },
    { lat: 48.059792, lng: 17.709047 },
    { lat: 48.059722, lng: 17.709624 },
    { lat: 48.059704, lng: 17.709818 },
    { lat: 48.059691, lng: 17.709965 },
    { lat: 48.05972, lng: 17.710183 },
    { lat: 48.05976, lng: 17.71032 },
    { lat: 48.059861, lng: 17.710529 },
    { lat: 48.059925, lng: 17.710661 },
    { lat: 48.059993, lng: 17.710792 },
    { lat: 48.060122, lng: 17.711012 },
    { lat: 48.060239, lng: 17.711189 },
    { lat: 48.060359, lng: 17.711352 },
    { lat: 48.060476, lng: 17.71151 },
    { lat: 48.060575, lng: 17.711642 },
    { lat: 48.060761, lng: 17.711898 },
    { lat: 48.060921, lng: 17.712146 },
    { lat: 48.060973, lng: 17.712266 },
    { lat: 48.061027, lng: 17.712474 },
    { lat: 48.061007, lng: 17.712603 },
    { lat: 48.060964, lng: 17.712849 },
    { lat: 48.06092, lng: 17.713038 },
    { lat: 48.06083, lng: 17.713406 },
    { lat: 48.060824, lng: 17.713516 },
    { lat: 48.060852, lng: 17.713683 },
    { lat: 48.060854, lng: 17.713695 },
    { lat: 48.060957, lng: 17.713971 },
    { lat: 48.061015, lng: 17.714251 },
    { lat: 48.061036, lng: 17.714347 },
    { lat: 48.061052, lng: 17.714426 },
    { lat: 48.061135, lng: 17.714701 },
    { lat: 48.061218, lng: 17.714925 },
    { lat: 48.061319, lng: 17.715177 },
    { lat: 48.061428, lng: 17.715402 },
    { lat: 48.061538, lng: 17.715679 },
    { lat: 48.061554, lng: 17.715766 },
    { lat: 48.061553, lng: 17.716089 },
    { lat: 48.061368, lng: 17.716547 },
    { lat: 48.061258, lng: 17.716716 },
    { lat: 48.061137, lng: 17.716889 },
    { lat: 48.060992, lng: 17.717055 },
    { lat: 48.060794, lng: 17.71723 },
    { lat: 48.06065, lng: 17.717358 },
    { lat: 48.060574, lng: 17.717429 },
    { lat: 48.060447, lng: 17.717551 },
    { lat: 48.060408, lng: 17.717592 },
    { lat: 48.06035, lng: 17.717656 },
    { lat: 48.06021, lng: 17.717806 },
    { lat: 48.060106, lng: 17.717964 },
    { lat: 48.05995, lng: 17.718192 },
    { lat: 48.059848, lng: 17.718346 },
    { lat: 48.059799, lng: 17.71844 },
    { lat: 48.059719, lng: 17.718641 },
    { lat: 48.059717, lng: 17.718718 },
    { lat: 48.059717, lng: 17.718904 },
    { lat: 48.059737, lng: 17.719232 },
    { lat: 48.059763, lng: 17.719488 },
    { lat: 48.059773, lng: 17.719557 },
    { lat: 48.059807, lng: 17.719789 },
    { lat: 48.059825, lng: 17.719917 },
    { lat: 48.059862, lng: 17.720074 },
    { lat: 48.059898, lng: 17.720227 },
    { lat: 48.059951, lng: 17.720494 },
    { lat: 48.059969, lng: 17.720618 },
    { lat: 48.059976, lng: 17.720671 },
    { lat: 48.059983, lng: 17.72072 },
    { lat: 48.059986, lng: 17.720747 },
    { lat: 48.059994, lng: 17.720798 },
    { lat: 48.059945, lng: 17.721065 },
    { lat: 48.059896, lng: 17.721175 },
    { lat: 48.05986, lng: 17.721281 },
    { lat: 48.059763, lng: 17.721454 },
    { lat: 48.059694, lng: 17.721581 },
    { lat: 48.059563, lng: 17.721814 },
    { lat: 48.059535, lng: 17.721862 },
    { lat: 48.059466, lng: 17.721974 },
    { lat: 48.059363, lng: 17.722172 },
    { lat: 48.059297, lng: 17.722271 },
    { lat: 48.059228, lng: 17.722629 },
    { lat: 48.059224, lng: 17.722645 },
    { lat: 48.059209, lng: 17.7229 },
    { lat: 48.059201, lng: 17.723035 },
    { lat: 48.059186, lng: 17.723149 },
    { lat: 48.059217, lng: 17.723263 },
    { lat: 48.059238, lng: 17.723302 },
    { lat: 48.059268, lng: 17.723362 },
    { lat: 48.0593, lng: 17.723426 },
    { lat: 48.059439, lng: 17.723567 },
    { lat: 48.059523, lng: 17.723716 },
    { lat: 48.059598, lng: 17.723889 },
    { lat: 48.059803, lng: 17.7245 },
    { lat: 48.059744, lng: 17.724672 },
    { lat: 48.05966, lng: 17.724904 },
    { lat: 48.059573, lng: 17.725112 },
    { lat: 48.059254, lng: 17.725615 },
    { lat: 48.059165, lng: 17.725773 },
    { lat: 48.059047, lng: 17.72597 },
    { lat: 48.059001, lng: 17.726097 },
    { lat: 48.058955, lng: 17.726336 },
    { lat: 48.058933, lng: 17.726451 },
    { lat: 48.058944, lng: 17.726658 },
    { lat: 48.058987, lng: 17.726949 },
    { lat: 48.059029, lng: 17.727122 },
    { lat: 48.059098, lng: 17.72745 },
    { lat: 48.05914, lng: 17.727658 },
    { lat: 48.05919, lng: 17.727946 },
    { lat: 48.05921, lng: 17.728093 },
    { lat: 48.059205, lng: 17.728179 },
    { lat: 48.059209, lng: 17.728288 },
    { lat: 48.059179, lng: 17.728534 },
    { lat: 48.059157, lng: 17.728629 },
    { lat: 48.059114, lng: 17.728728 },
    { lat: 48.059009, lng: 17.728954 },
    { lat: 48.058902, lng: 17.729174 },
    { lat: 48.058782, lng: 17.729381 },
    { lat: 48.058721, lng: 17.72951 },
    { lat: 48.058624, lng: 17.729776 },
    { lat: 48.058597, lng: 17.729897 },
    { lat: 48.058547, lng: 17.73019 },
    { lat: 48.058538, lng: 17.730257 },
    { lat: 48.058512, lng: 17.730508 },
    { lat: 48.058466, lng: 17.730818 },
    { lat: 48.058422, lng: 17.731043 },
    { lat: 48.058379, lng: 17.731267 },
    { lat: 48.058361, lng: 17.731363 },
    { lat: 48.058326, lng: 17.731532 },
    { lat: 48.058285, lng: 17.731675 },
    { lat: 48.058216, lng: 17.731937 },
    { lat: 48.058189, lng: 17.73205 },
    { lat: 48.058087, lng: 17.732299 },
    { lat: 48.057929, lng: 17.732638 },
    { lat: 48.057832, lng: 17.732873 },
    { lat: 48.057734, lng: 17.73308 },
    { lat: 48.057545, lng: 17.733527 },
    { lat: 48.057467, lng: 17.733773 },
    { lat: 48.057381, lng: 17.733999 },
    { lat: 48.057344, lng: 17.734121 },
    { lat: 48.057236, lng: 17.73447 },
    { lat: 48.057189, lng: 17.734595 },
    { lat: 48.057156, lng: 17.734761 },
    { lat: 48.057103, lng: 17.734997 },
    { lat: 48.057061, lng: 17.735164 },
    { lat: 48.056968, lng: 17.735492 },
    { lat: 48.056898, lng: 17.735708 },
    { lat: 48.056819, lng: 17.735951 },
    { lat: 48.056759, lng: 17.736172 },
    { lat: 48.056724, lng: 17.736277 },
    { lat: 48.056637, lng: 17.736542 },
    { lat: 48.056614, lng: 17.736703 },
    { lat: 48.05659, lng: 17.736893 },
    { lat: 48.056552, lng: 17.737106 },
    { lat: 48.056527, lng: 17.737246 },
    { lat: 48.056422, lng: 17.737759 },
    { lat: 48.05633, lng: 17.737984 },
    { lat: 48.056231, lng: 17.73821 },
    { lat: 48.056159, lng: 17.7384 },
    { lat: 48.056103, lng: 17.738532 },
    { lat: 48.056065, lng: 17.738644 },
    { lat: 48.056006, lng: 17.738874 },
    { lat: 48.055953, lng: 17.739115 },
    { lat: 48.055928, lng: 17.739269 },
    { lat: 48.055909, lng: 17.739403 },
    { lat: 48.055904, lng: 17.739484 },
    { lat: 48.055909, lng: 17.739745 },
    { lat: 48.055904, lng: 17.740088 },
    { lat: 48.055874, lng: 17.740352 },
    { lat: 48.055827, lng: 17.740623 },
    { lat: 48.055785, lng: 17.740906 },
    { lat: 48.055766, lng: 17.741209 },
    { lat: 48.055732, lng: 17.741562 },
    { lat: 48.055681, lng: 17.741812 },
    { lat: 48.055653, lng: 17.741948 },
    { lat: 48.055609, lng: 17.742072 },
    { lat: 48.055561, lng: 17.742351 },
    { lat: 48.055538, lng: 17.742684 },
    { lat: 48.055543, lng: 17.742773 },
    { lat: 48.055587, lng: 17.742947 },
    { lat: 48.055744, lng: 17.743275 },
    { lat: 48.055789, lng: 17.743427 },
    { lat: 48.055837, lng: 17.743888 },
    { lat: 48.055782, lng: 17.744157 },
    { lat: 48.055731, lng: 17.744249 },
    { lat: 48.05566, lng: 17.744318 },
    { lat: 48.055378, lng: 17.744489 },
    { lat: 48.055267, lng: 17.744531 },
    { lat: 48.055083, lng: 17.744616 },
    { lat: 48.054887, lng: 17.74472 },
    { lat: 48.054727, lng: 17.744833 },
    { lat: 48.054508, lng: 17.744976 },
    { lat: 48.054339, lng: 17.745065 },
    { lat: 48.054209, lng: 17.745117 },
    { lat: 48.054062, lng: 17.745181 },
    { lat: 48.053977, lng: 17.745224 },
    { lat: 48.053932, lng: 17.745271 },
    { lat: 48.053813, lng: 17.745372 },
    { lat: 48.053695, lng: 17.745612 },
    { lat: 48.053624, lng: 17.745828 },
    { lat: 48.053548, lng: 17.746146 },
    { lat: 48.053498, lng: 17.746362 },
    { lat: 48.05347, lng: 17.7465 },
    { lat: 48.053407, lng: 17.74669 },
    { lat: 48.053341, lng: 17.74687 },
    { lat: 48.053233, lng: 17.746987 },
    { lat: 48.053159, lng: 17.747042 },
    { lat: 48.05315, lng: 17.747061 },
    { lat: 48.053115, lng: 17.747136 },
    { lat: 48.053034, lng: 17.74735 },
    { lat: 48.053005, lng: 17.747427 },
    { lat: 48.053092, lng: 17.747703 },
    { lat: 48.053658, lng: 17.748243 },
    { lat: 48.053794, lng: 17.748371 },
    { lat: 48.053985, lng: 17.748602 },
    { lat: 48.054063, lng: 17.748808 },
    { lat: 48.054242, lng: 17.749067 },
    { lat: 48.054403, lng: 17.749248 },
    { lat: 48.054588, lng: 17.749429 },
    { lat: 48.054762, lng: 17.749658 },
    { lat: 48.054895, lng: 17.750036 },
    { lat: 48.054833, lng: 17.750194 },
    { lat: 48.054767, lng: 17.750298 },
    { lat: 48.054628, lng: 17.750348 },
    { lat: 48.054526, lng: 17.750409 },
    { lat: 48.054365, lng: 17.750525 },
    { lat: 48.054206, lng: 17.750676 },
    { lat: 48.054111, lng: 17.750771 },
    { lat: 48.053994, lng: 17.750881 },
    { lat: 48.053923, lng: 17.750976 },
    { lat: 48.053883, lng: 17.751054 },
    { lat: 48.053842, lng: 17.75115 },
    { lat: 48.053777, lng: 17.751397 },
    { lat: 48.053747, lng: 17.751581 },
    { lat: 48.05373, lng: 17.751682 },
    { lat: 48.053703, lng: 17.752018 },
    { lat: 48.053693, lng: 17.752357 },
    { lat: 48.053684, lng: 17.752503 },
    { lat: 48.053685, lng: 17.752566 },
    { lat: 48.053685, lng: 17.752722 },
    { lat: 48.05371, lng: 17.753034 },
    { lat: 48.053722, lng: 17.753196 },
    { lat: 48.053731, lng: 17.753396 },
    { lat: 48.053744, lng: 17.753643 },
    { lat: 48.053781, lng: 17.753908 },
    { lat: 48.053952, lng: 17.754612 },
    { lat: 48.054184, lng: 17.755013 },
    { lat: 48.054237, lng: 17.755184 },
    { lat: 48.05428, lng: 17.755354 },
    { lat: 48.054299, lng: 17.755425 },
    { lat: 48.05435, lng: 17.755631 },
    { lat: 48.05434, lng: 17.755827 },
    { lat: 48.05426, lng: 17.756162 },
    { lat: 48.054129, lng: 17.756447 },
    { lat: 48.053985, lng: 17.756723 },
    { lat: 48.053946, lng: 17.756892 },
    { lat: 48.053863, lng: 17.75726 },
    { lat: 48.05389, lng: 17.757313 },
    { lat: 48.05382, lng: 17.757475 },
    { lat: 48.053765, lng: 17.757733 },
    { lat: 48.053712, lng: 17.757874 },
    { lat: 48.053574, lng: 17.758134 },
    { lat: 48.053433, lng: 17.758399 },
    { lat: 48.053322, lng: 17.758529 },
    { lat: 48.05308, lng: 17.758878 },
    { lat: 48.052882, lng: 17.759213 },
    { lat: 48.052754, lng: 17.759372 },
    { lat: 48.052726, lng: 17.75938 },
    { lat: 48.052627, lng: 17.759503 },
    { lat: 48.05252, lng: 17.759513 },
    { lat: 48.052409, lng: 17.759498 },
    { lat: 48.052351, lng: 17.759489 },
    { lat: 48.052318, lng: 17.759483 },
    { lat: 48.052145, lng: 17.759441 },
    { lat: 48.051986, lng: 17.759379 },
    { lat: 48.051912, lng: 17.759414 },
    { lat: 48.051727, lng: 17.759446 },
    { lat: 48.051533, lng: 17.75946 },
    { lat: 48.051416, lng: 17.75953 },
    { lat: 48.051233, lng: 17.759761 },
    { lat: 48.0512, lng: 17.759867 },
    { lat: 48.051061, lng: 17.760228 },
    { lat: 48.050864, lng: 17.760527 },
    { lat: 48.05071, lng: 17.760647 },
    { lat: 48.050566, lng: 17.760718 },
    { lat: 48.05035, lng: 17.760821 },
    { lat: 48.050276, lng: 17.760837 },
    { lat: 48.049957, lng: 17.760915 },
    { lat: 48.049857, lng: 17.760931 },
    { lat: 48.049654, lng: 17.760965 },
    { lat: 48.049354, lng: 17.761007 },
    { lat: 48.049194, lng: 17.761011 },
    { lat: 48.049085, lng: 17.761057 },
    { lat: 48.048838, lng: 17.761357 },
    { lat: 48.048616, lng: 17.761716 },
    { lat: 48.048381, lng: 17.761841 },
    { lat: 48.048209, lng: 17.761929 },
    { lat: 48.04789, lng: 17.762007 },
    { lat: 48.047679, lng: 17.76203 },
    { lat: 48.047545, lng: 17.762024 },
    { lat: 48.047406, lng: 17.762019 },
    { lat: 48.047284, lng: 17.762021 },
    { lat: 48.047162, lng: 17.762029 },
    { lat: 48.047001, lng: 17.76203 },
    { lat: 48.04686, lng: 17.762087 },
    { lat: 48.046665, lng: 17.762169 },
    { lat: 48.04633, lng: 17.762129 },
    { lat: 48.046143, lng: 17.762028 },
    { lat: 48.045916, lng: 17.761933 },
    { lat: 48.04581, lng: 17.761838 },
    { lat: 48.045674, lng: 17.761666 },
    { lat: 48.045505, lng: 17.761454 },
    { lat: 48.045296, lng: 17.761264 },
    { lat: 48.045233, lng: 17.761232 },
    { lat: 48.045035, lng: 17.761193 },
    { lat: 48.044959, lng: 17.761235 },
    { lat: 48.044876, lng: 17.761286 },
    { lat: 48.044832, lng: 17.761333 },
    { lat: 48.04479, lng: 17.761406 },
    { lat: 48.044601, lng: 17.761592 },
    { lat: 48.044486, lng: 17.761714 },
    { lat: 48.044425, lng: 17.761795 },
    { lat: 48.044344, lng: 17.761941 },
    { lat: 48.044227, lng: 17.762152 },
    { lat: 48.043925, lng: 17.76266 },
    { lat: 48.043796, lng: 17.762929 },
    { lat: 48.043712, lng: 17.763215 },
    { lat: 48.043565, lng: 17.763509 },
    { lat: 48.043494, lng: 17.763681 },
    { lat: 48.04348, lng: 17.76386 },
    { lat: 48.043486, lng: 17.764 },
    { lat: 48.04353, lng: 17.764132 },
    { lat: 48.043611, lng: 17.764251 },
    { lat: 48.043672, lng: 17.764413 },
    { lat: 48.043715, lng: 17.76459 },
    { lat: 48.043718, lng: 17.764884 },
    { lat: 48.043677, lng: 17.765141 },
    { lat: 48.043686, lng: 17.765358 },
    { lat: 48.043614, lng: 17.765511 },
    { lat: 48.043574, lng: 17.765768 },
    { lat: 48.043518, lng: 17.766012 },
    { lat: 48.043417, lng: 17.766315 },
    { lat: 48.04328, lng: 17.766631 },
    { lat: 48.0432, lng: 17.766716 },
    { lat: 48.042973, lng: 17.766899 },
    { lat: 48.042907, lng: 17.767007 },
    { lat: 48.04284, lng: 17.767106 },
    { lat: 48.042718, lng: 17.767224 },
    { lat: 48.042516, lng: 17.767345 },
    { lat: 48.042143, lng: 17.767661 },
    { lat: 48.041856, lng: 17.767941 },
    { lat: 48.04163, lng: 17.76804 },
    { lat: 48.041565, lng: 17.768067 },
    { lat: 48.041375, lng: 17.768111 },
    { lat: 48.041268, lng: 17.768193 },
    { lat: 48.041161, lng: 17.76827 },
    { lat: 48.041015, lng: 17.768439 },
    { lat: 48.040935, lng: 17.768529 },
    { lat: 48.040803, lng: 17.768788 },
    { lat: 48.040793, lng: 17.768817 },
    { lat: 48.040703, lng: 17.768704 },
    { lat: 48.040649, lng: 17.768634 },
    { lat: 48.040184, lng: 17.767963 },
    { lat: 48.039926, lng: 17.767569 },
    { lat: 48.039873, lng: 17.767488 },
    { lat: 48.039668, lng: 17.76717 },
    { lat: 48.039598, lng: 17.767073 },
    { lat: 48.039496, lng: 17.766982 },
    { lat: 48.03939, lng: 17.766902 },
    { lat: 48.039292, lng: 17.766831 },
    { lat: 48.039169, lng: 17.766772 },
    { lat: 48.03904, lng: 17.766675 },
    { lat: 48.038816, lng: 17.766235 },
    { lat: 48.03859, lng: 17.765794 },
    { lat: 48.038347, lng: 17.765311 },
    { lat: 48.038118, lng: 17.764851 },
    { lat: 48.037767, lng: 17.764143 },
    { lat: 48.03745, lng: 17.76362 },
    { lat: 48.037427, lng: 17.76358 },
    { lat: 48.037099, lng: 17.763042 },
    { lat: 48.036733, lng: 17.762599 },
    { lat: 48.036486, lng: 17.762302 },
    { lat: 48.03586, lng: 17.761421 },
    { lat: 48.035545, lng: 17.761105 },
    { lat: 48.03528, lng: 17.760985 },
    { lat: 48.035164, lng: 17.760932 },
    { lat: 48.034676, lng: 17.761279 },
    { lat: 48.034447, lng: 17.761485 },
    { lat: 48.034419, lng: 17.761511 },
    { lat: 48.034168, lng: 17.761926 },
    { lat: 48.033999, lng: 17.762204 },
    { lat: 48.033826, lng: 17.762517 },
    { lat: 48.033788, lng: 17.762583 },
    { lat: 48.033721, lng: 17.762622 },
    { lat: 48.033444, lng: 17.762766 },
    { lat: 48.033647, lng: 17.763338 },
    { lat: 48.033707, lng: 17.763499 },
    { lat: 48.033758, lng: 17.763573 },
    { lat: 48.033862, lng: 17.76391 },
    { lat: 48.033922, lng: 17.764151 },
    { lat: 48.033952, lng: 17.764366 },
    { lat: 48.033969, lng: 17.764571 },
    { lat: 48.034008, lng: 17.764805 },
    { lat: 48.034027, lng: 17.764972 },
    { lat: 48.034034, lng: 17.765486 },
    { lat: 48.033982, lng: 17.765848 },
    { lat: 48.033954, lng: 17.766065 },
    { lat: 48.033743, lng: 17.766248 },
    { lat: 48.033641, lng: 17.766619 },
    { lat: 48.033502, lng: 17.767002 },
    { lat: 48.033304, lng: 17.767319 },
    { lat: 48.032921, lng: 17.767705 },
    { lat: 48.032683, lng: 17.767888 },
    { lat: 48.03252, lng: 17.767985 },
    { lat: 48.032238, lng: 17.768131 },
    { lat: 48.0321, lng: 17.7682 },
    { lat: 48.031824, lng: 17.768269 },
    { lat: 48.031843, lng: 17.768415 },
    { lat: 48.031862, lng: 17.768845 },
    { lat: 48.031855, lng: 17.768967 },
    { lat: 48.031864, lng: 17.769355 },
    { lat: 48.031871, lng: 17.769878 },
    { lat: 48.031869, lng: 17.77039 },
    { lat: 48.03191, lng: 17.770915 },
    { lat: 48.031898, lng: 17.771269 },
    { lat: 48.03185, lng: 17.772089 },
    { lat: 48.031846, lng: 17.772127 },
    { lat: 48.031795, lng: 17.772339 },
    { lat: 48.031742, lng: 17.772669 },
    { lat: 48.031658, lng: 17.772948 },
    { lat: 48.031565, lng: 17.773075 },
    { lat: 48.031311, lng: 17.773421 },
    { lat: 48.031088, lng: 17.773694 },
    { lat: 48.030928, lng: 17.773839 },
    { lat: 48.030848, lng: 17.774083 },
    { lat: 48.030715, lng: 17.77426 },
    { lat: 48.0306, lng: 17.774487 },
    { lat: 48.030471, lng: 17.774676 },
    { lat: 48.030371, lng: 17.774827 },
    { lat: 48.030231, lng: 17.775091 },
    { lat: 48.030129, lng: 17.775233 },
    { lat: 48.029975, lng: 17.77543 },
    { lat: 48.029856, lng: 17.775637 },
    { lat: 48.029658, lng: 17.775953 },
    { lat: 48.029611, lng: 17.776024 },
    { lat: 48.029236, lng: 17.776291 },
    { lat: 48.028883, lng: 17.776536 },
    { lat: 48.028631, lng: 17.776721 },
    { lat: 48.028076, lng: 17.777109 },
    { lat: 48.027918, lng: 17.777313 },
    { lat: 48.027591, lng: 17.777424 },
    { lat: 48.02735, lng: 17.777633 },
    { lat: 48.027182, lng: 17.777749 },
    { lat: 48.027033, lng: 17.77788 },
    { lat: 48.026908, lng: 17.778085 },
    { lat: 48.026753, lng: 17.778371 },
    { lat: 48.026536, lng: 17.778617 },
    { lat: 48.026278, lng: 17.778779 },
    { lat: 48.026014, lng: 17.778836 },
    { lat: 48.025648, lng: 17.778733 },
    { lat: 48.025622, lng: 17.77872 },
    { lat: 48.025405, lng: 17.7788 },
    { lat: 48.025075, lng: 17.778658 },
    { lat: 48.02492, lng: 17.778666 },
    { lat: 48.024712, lng: 17.778761 },
    { lat: 48.024571, lng: 17.778849 },
    { lat: 48.024331, lng: 17.778985 },
    { lat: 48.024215, lng: 17.779043 },
    { lat: 48.024108, lng: 17.779156 },
    { lat: 48.024039, lng: 17.779248 },
    { lat: 48.024024, lng: 17.779458 },
    { lat: 48.024015, lng: 17.779663 },
    { lat: 48.024029, lng: 17.779839 },
    { lat: 48.024123, lng: 17.780123 },
    { lat: 48.024169, lng: 17.780233 },
    { lat: 48.024169, lng: 17.780449 },
    { lat: 48.024154, lng: 17.780587 },
    { lat: 48.024086, lng: 17.780668 },
    { lat: 48.0265678, lng: 17.7838127 },
    { lat: 48.0281028, lng: 17.7861014 },
    { lat: 48.0313773, lng: 17.7912136 },
    { lat: 48.0324805, lng: 17.7926212 },
    { lat: 48.0327514, lng: 17.7928382 },
    { lat: 48.0335775, lng: 17.7905532 },
    { lat: 48.0337185, lng: 17.7899687 },
    { lat: 48.0339251, lng: 17.7887664 },
    { lat: 48.0339537, lng: 17.7870059 },
    { lat: 48.0338833, lng: 17.7860872 },
    { lat: 48.0342087, lng: 17.785252 },
    { lat: 48.0343639, lng: 17.7846955 },
    { lat: 48.0345381, lng: 17.7844281 },
    { lat: 48.0346995, lng: 17.7842807 },
    { lat: 48.0349272, lng: 17.784202 },
    { lat: 48.0352332, lng: 17.7842389 },
    { lat: 48.0353993, lng: 17.7841515 },
    { lat: 48.0356693, lng: 17.7839534 },
    { lat: 48.0360902, lng: 17.7834041 },
    { lat: 48.0363572, lng: 17.7828311 },
    { lat: 48.0366308, lng: 17.7817253 },
    { lat: 48.0374052, lng: 17.7795024 },
    { lat: 48.0376254, lng: 17.7791768 },
    { lat: 48.0381725, lng: 17.7785694 },
    { lat: 48.0384711, lng: 17.7783413 },
    { lat: 48.0389799, lng: 17.7780799 },
    { lat: 48.0391268, lng: 17.7779157 },
    { lat: 48.0397781, lng: 17.776715 },
    { lat: 48.0401078, lng: 17.7759859 },
    { lat: 48.0406757, lng: 17.775076 },
    { lat: 48.04117, lng: 17.7737273 },
    { lat: 48.0412829, lng: 17.7732894 },
    { lat: 48.041925, lng: 17.7721957 },
    { lat: 48.0423645, lng: 17.7719287 },
    { lat: 48.0443999, lng: 17.7714886 },
    { lat: 48.0444836, lng: 17.7715645 },
    { lat: 48.0454367, lng: 17.7718221 },
    { lat: 48.0458503, lng: 17.7717541 },
    { lat: 48.0477375, lng: 17.7709124 },
    { lat: 48.0481011, lng: 17.7706223 },
    { lat: 48.0484182, lng: 17.770129 },
    { lat: 48.048588, lng: 17.7699718 },
    { lat: 48.0490216, lng: 17.7696812 },
    { lat: 48.049367, lng: 17.7695746 },
    { lat: 48.049519, lng: 17.7695839 },
    { lat: 48.0502207, lng: 17.769885 },
    { lat: 48.0508313, lng: 17.7699913 },
    { lat: 48.0519106, lng: 17.7699347 },
    { lat: 48.052955, lng: 17.7697198 },
    { lat: 48.0532516, lng: 17.7695314 },
    { lat: 48.0534553, lng: 17.7692658 },
    { lat: 48.0539791, lng: 17.768301 },
    { lat: 48.0540283, lng: 17.7679128 },
    { lat: 48.0539505, lng: 17.7671701 },
    { lat: 48.0539837, lng: 17.7666493 },
    { lat: 48.0541498, lng: 17.7660134 },
    { lat: 48.0544593, lng: 17.7655454 },
    { lat: 48.0552768, lng: 17.7649458 },
    { lat: 48.0558589, lng: 17.7644116 },
    { lat: 48.0639981, lng: 17.7721656 },
    { lat: 48.0640821, lng: 17.7722458 },
    { lat: 48.0640261, lng: 17.7718353 },
    { lat: 48.0639628, lng: 17.7697056 },
    { lat: 48.0640421, lng: 17.7692905 },
    { lat: 48.0642125, lng: 17.7688533 },
    { lat: 48.0643733, lng: 17.7686239 },
    { lat: 48.0645591, lng: 17.7685598 },
    { lat: 48.0652786, lng: 17.7688384 },
    { lat: 48.0657585, lng: 17.7696411 },
    { lat: 48.066315, lng: 17.7704056 },
    { lat: 48.0672203, lng: 17.7709646 },
    { lat: 48.0675517, lng: 17.7709213 },
    { lat: 48.067891, lng: 17.770747 },
    { lat: 48.0681592, lng: 17.7704862 },
    { lat: 48.068377, lng: 17.7700266 },
    { lat: 48.0685278, lng: 17.7695105 },
    { lat: 48.069051, lng: 17.7668096 },
    { lat: 48.0694528, lng: 17.7657527 },
    { lat: 48.0699359, lng: 17.7653682 },
    { lat: 48.0708185, lng: 17.7640757 },
    { lat: 48.0712888, lng: 17.7631752 },
    { lat: 48.0715998, lng: 17.7619856 },
    { lat: 48.0716472, lng: 17.7596412 },
    { lat: 48.0716528, lng: 17.7595839 },
    { lat: 48.0715796, lng: 17.7588814 },
    { lat: 48.071571, lng: 17.75807 },
    { lat: 48.0719472, lng: 17.7558935 },
    { lat: 48.072766, lng: 17.7531588 },
    { lat: 48.0733521, lng: 17.7516154 },
    { lat: 48.0738574, lng: 17.7511553 },
    { lat: 48.0739843, lng: 17.7509087 },
    { lat: 48.073958, lng: 17.7507609 },
    { lat: 48.0740176, lng: 17.750664 },
    { lat: 48.0740397, lng: 17.7506938 },
    { lat: 48.0742517, lng: 17.7502899 },
    { lat: 48.0745883, lng: 17.7494764 },
    { lat: 48.074777, lng: 17.7483916 },
    { lat: 48.0747703, lng: 17.7476099 },
    { lat: 48.0749231, lng: 17.7471456 },
    { lat: 48.0749967, lng: 17.7462049 },
    { lat: 48.074953, lng: 17.745776 },
    { lat: 48.0749462, lng: 17.7449607 },
    { lat: 48.0749803, lng: 17.7447093 },
    { lat: 48.0752086, lng: 17.7439161 },
    { lat: 48.0754521, lng: 17.7435286 },
    { lat: 48.0755981, lng: 17.7434081 },
    { lat: 48.0758713, lng: 17.7433349 },
    { lat: 48.0761537, lng: 17.7434634 },
    { lat: 48.07637, lng: 17.7437113 },
    { lat: 48.0770462, lng: 17.7447783 },
    { lat: 48.0774567, lng: 17.7452774 },
    { lat: 48.0782751, lng: 17.7458739 },
    { lat: 48.0791279, lng: 17.74628 },
    { lat: 48.079681, lng: 17.7461881 },
    { lat: 48.0800744, lng: 17.7459775 },
    { lat: 48.0809461, lng: 17.7451826 },
    { lat: 48.08112, lng: 17.7449085 },
    { lat: 48.0811383, lng: 17.7449516 },
    { lat: 48.0814266, lng: 17.7447944 },
    { lat: 48.0815539, lng: 17.7448777 },
    { lat: 48.0818072, lng: 17.7445428 },
    { lat: 48.0821659, lng: 17.74392 },
    { lat: 48.0822588, lng: 17.7436006 },
    { lat: 48.0823067, lng: 17.7433352 },
    { lat: 48.0822795, lng: 17.7427695 },
    { lat: 48.0821591, lng: 17.74236 },
    { lat: 48.0820351, lng: 17.7421664 },
    { lat: 48.0820398, lng: 17.7420598 },
    { lat: 48.081482, lng: 17.7414936 },
    { lat: 48.0808855, lng: 17.7407456 },
    { lat: 48.0803997, lng: 17.739942 },
    { lat: 48.0801769, lng: 17.7392862 },
    { lat: 48.0800957, lng: 17.7387424 },
    { lat: 48.0800944, lng: 17.738563 },
    { lat: 48.0800923, lng: 17.7382701 },
    { lat: 48.0801455, lng: 17.7380767 },
    { lat: 48.0801854, lng: 17.7374494 },
    { lat: 48.0807916, lng: 17.7354544 },
    { lat: 48.0809743, lng: 17.7346018 },
    { lat: 48.0810359, lng: 17.733938 },
    { lat: 48.0810202, lng: 17.7333985 },
    { lat: 48.0808772, lng: 17.7323207 },
    { lat: 48.0807486, lng: 17.7317225 },
    { lat: 48.0805132, lng: 17.7308993 },
    { lat: 48.0803019, lng: 17.730463 },
    { lat: 48.0800507, lng: 17.7301304 },
    { lat: 48.0799515, lng: 17.730023 },
    { lat: 48.0796354, lng: 17.729734 },
    { lat: 48.0791598, lng: 17.7295138 },
    { lat: 48.0785854, lng: 17.7294806 },
    { lat: 48.0779017, lng: 17.7293063 },
    { lat: 48.0767368, lng: 17.7284959 },
    { lat: 48.0762358, lng: 17.7282135 },
    { lat: 48.0760598, lng: 17.7280509 },
    { lat: 48.0756817, lng: 17.7275322 },
    { lat: 48.075273, lng: 17.7267527 },
    { lat: 48.0751954, lng: 17.7265594 },
    { lat: 48.0751159, lng: 17.7260971 },
    { lat: 48.0750699, lng: 17.725379 },
    { lat: 48.0753502, lng: 17.7245718 },
    { lat: 48.0757025, lng: 17.7239012 },
    { lat: 48.0759532, lng: 17.7232707 },
    { lat: 48.0760681, lng: 17.7226906 },
    { lat: 48.0760209, lng: 17.7219886 },
    { lat: 48.0759004, lng: 17.7218133 },
    { lat: 48.0756159, lng: 17.7209819 },
    { lat: 48.0750735, lng: 17.7197635 },
    { lat: 48.0748051, lng: 17.7191901 },
    { lat: 48.0746425, lng: 17.7190803 },
    { lat: 48.0745042, lng: 17.718821 },
    { lat: 48.0742357, lng: 17.7181019 },
    { lat: 48.0741679, lng: 17.7177364 },
    { lat: 48.0741476, lng: 17.7170519 },
    { lat: 48.074274, lng: 17.7160387 },
    { lat: 48.0745782, lng: 17.7152179 },
    { lat: 48.0751639, lng: 17.7152123 },
    { lat: 48.0756134, lng: 17.7152877 },
    { lat: 48.0761506, lng: 17.7150502 },
    { lat: 48.0774465, lng: 17.7142508 },
    { lat: 48.0780959, lng: 17.7137508 },
    { lat: 48.0782986, lng: 17.7136661 },
    { lat: 48.0788636, lng: 17.7136735 },
    { lat: 48.0797835, lng: 17.7133103 },
    { lat: 48.0804947, lng: 17.7128034 },
    { lat: 48.0810114, lng: 17.712236 },
    { lat: 48.0813315, lng: 17.7118133 },
    { lat: 48.081463, lng: 17.7115506 },
    { lat: 48.0815896, lng: 17.7112103 },
    { lat: 48.0816653, lng: 17.7106704 },
    { lat: 48.0816454, lng: 17.7093043 },
    { lat: 48.0818488, lng: 17.7071606 },
    { lat: 48.081667, lng: 17.7065126 },
    { lat: 48.0809402, lng: 17.704839 },
    { lat: 48.0808572, lng: 17.7044689 },
    { lat: 48.0808428, lng: 17.7038424 },
    { lat: 48.080999, lng: 17.7021181 },
    { lat: 48.0809648, lng: 17.7010124 },
    { lat: 48.0808358, lng: 17.7005399 },
    { lat: 48.0809394, lng: 17.6994362 },
    { lat: 48.0810915, lng: 17.6988924 },
    { lat: 48.0811705, lng: 17.6987635 },
    { lat: 48.0811345, lng: 17.6987138 },
    { lat: 48.0812574, lng: 17.69846 },
    { lat: 48.0814627, lng: 17.69833 },
    { lat: 48.081727, lng: 17.6984743 },
    { lat: 48.0823209, lng: 17.6985838 },
    { lat: 48.0827107, lng: 17.6989295 },
    { lat: 48.083476, lng: 17.6993583 },
    { lat: 48.0850397, lng: 17.7006196 },
    { lat: 48.08535, lng: 17.7006338 },
    { lat: 48.0856913, lng: 17.7005286 },
    { lat: 48.0859107, lng: 17.7005386 },
    { lat: 48.0872444, lng: 17.7001265 },
    { lat: 48.0879319, lng: 17.6996011 },
    { lat: 48.0881681, lng: 17.6992436 },
    { lat: 48.0884131, lng: 17.6984713 },
    { lat: 48.0885677, lng: 17.6977865 },
    { lat: 48.0885877, lng: 17.6973795 },
    { lat: 48.0886069, lng: 17.6969883 },
    { lat: 48.08867, lng: 17.696912 },
    { lat: 48.0886657, lng: 17.6965899 },
    { lat: 48.0885351, lng: 17.6957139 },
    { lat: 48.0881934, lng: 17.6950911 },
    { lat: 48.0878436, lng: 17.6934371 },
    { lat: 48.0879567, lng: 17.6933697 },
    { lat: 48.0879691, lng: 17.6932804 },
    { lat: 48.0880613, lng: 17.6931849 },
    { lat: 48.088521, lng: 17.6921248 },
    { lat: 48.0886399, lng: 17.691878 },
    { lat: 48.0888237, lng: 17.6916577 },
    { lat: 48.0889258, lng: 17.6913772 },
    { lat: 48.0886069, lng: 17.6906501 },
    { lat: 48.0885265, lng: 17.6895465 },
    { lat: 48.0883726, lng: 17.6884817 },
    { lat: 48.0883336, lng: 17.688489 },
    { lat: 48.0883006, lng: 17.6879105 },
    { lat: 48.0883197, lng: 17.6871182 },
    { lat: 48.0885613, lng: 17.686037 },
    { lat: 48.0887338, lng: 17.6855291 },
    { lat: 48.0890329, lng: 17.684971 },
    { lat: 48.0894473, lng: 17.6845016 },
    { lat: 48.090501, lng: 17.683698 },
    { lat: 48.0914414, lng: 17.6828855 },
    { lat: 48.0918244, lng: 17.6828545 },
    { lat: 48.0922807, lng: 17.6829281 },
    { lat: 48.0927488, lng: 17.6831557 },
    { lat: 48.0929616, lng: 17.6837185 },
    { lat: 48.0931336, lng: 17.6847519 },
    { lat: 48.0932921, lng: 17.6850822 },
    { lat: 48.0935498, lng: 17.6853022 },
    { lat: 48.0938035, lng: 17.6852607 },
    { lat: 48.0940489, lng: 17.6851229 },
    { lat: 48.0946629, lng: 17.6843468 },
    { lat: 48.0950595, lng: 17.6840604 },
    { lat: 48.0954134, lng: 17.6838911 },
    { lat: 48.0957049, lng: 17.6835713 },
    { lat: 48.0959084, lng: 17.6831596 },
    { lat: 48.0959462, lng: 17.682775 },
    { lat: 48.0958871, lng: 17.6821917 },
    { lat: 48.0957524, lng: 17.6818446 },
    { lat: 48.0953832, lng: 17.6814322 },
    { lat: 48.0952482, lng: 17.6813515 },
    { lat: 48.0947691, lng: 17.681201 },
    { lat: 48.0943918, lng: 17.6811983 },
    { lat: 48.0938784, lng: 17.6810403 },
    { lat: 48.0936419, lng: 17.6809063 },
    { lat: 48.093104, lng: 17.6803961 },
    { lat: 48.092844, lng: 17.6798702 },
    { lat: 48.0926407, lng: 17.6790627 },
    { lat: 48.092577, lng: 17.6784776 },
    { lat: 48.0925819, lng: 17.6777462 },
    { lat: 48.0927044, lng: 17.6770912 },
    { lat: 48.0928773, lng: 17.6765033 },
    { lat: 48.0930962, lng: 17.6760849 },
    { lat: 48.0936553, lng: 17.6754082 },
    { lat: 48.0940064, lng: 17.6751888 },
    { lat: 48.0944344, lng: 17.6750844 },
    { lat: 48.0948203, lng: 17.6750607 },
    { lat: 48.0950701, lng: 17.675174 },
    { lat: 48.0951907, lng: 17.6753348 },
    { lat: 48.0953986, lng: 17.6758036 },
    { lat: 48.0955843, lng: 17.6770142 },
    { lat: 48.0955224, lng: 17.6774492 },
    { lat: 48.095206, lng: 17.6783397 },
    { lat: 48.095179, lng: 17.6786147 },
    { lat: 48.0952362, lng: 17.6789119 },
    { lat: 48.0956276, lng: 17.679661 },
    { lat: 48.0959065, lng: 17.679985 },
    { lat: 48.0962029, lng: 17.6801727 },
    { lat: 48.0965836, lng: 17.6801424 },
    { lat: 48.096816, lng: 17.6799919 },
    { lat: 48.09723, lng: 17.6791777 },
    { lat: 48.0975253, lng: 17.6787369 },
    { lat: 48.0981467, lng: 17.6781706 },
    { lat: 48.0982726, lng: 17.6778015 },
    { lat: 48.0982769, lng: 17.6773844 },
    { lat: 48.0980285, lng: 17.6768276 },
    { lat: 48.0974979, lng: 17.6762538 },
    { lat: 48.0973484, lng: 17.6759975 },
    { lat: 48.097092, lng: 17.6752235 },
    { lat: 48.0969092, lng: 17.6749354 },
    { lat: 48.0966118, lng: 17.6746956 },
    { lat: 48.0962892, lng: 17.6746105 },
    { lat: 48.0953392, lng: 17.6741029 },
    { lat: 48.0951361, lng: 17.6737622 },
    { lat: 48.0950521, lng: 17.6733781 },
    { lat: 48.0950321, lng: 17.6728271 },
    { lat: 48.095126, lng: 17.6723629 },
    { lat: 48.0951951, lng: 17.6721342 },
    { lat: 48.0952891, lng: 17.6720108 },
    { lat: 48.0957683, lng: 17.6714439 },
    { lat: 48.0959503, lng: 17.6713061 },
    { lat: 48.0962259, lng: 17.6711806 },
    { lat: 48.0965017, lng: 17.6712408 },
    { lat: 48.0966528, lng: 17.6713432 },
    { lat: 48.096938, lng: 17.6717324 },
    { lat: 48.0970861, lng: 17.6722213 },
    { lat: 48.0970703, lng: 17.6736943 },
    { lat: 48.0971702, lng: 17.6741878 },
    { lat: 48.0973863, lng: 17.6745341 },
    { lat: 48.0979635, lng: 17.6747693 },
    { lat: 48.0981053, lng: 17.674693 },
    { lat: 48.0984849, lng: 17.6744081 },
    { lat: 48.0989519, lng: 17.6738605 },
    { lat: 48.0990597, lng: 17.6736002 },
    { lat: 48.0991151, lng: 17.6731188 },
    { lat: 48.0990297, lng: 17.6727843 },
    { lat: 48.0983824, lng: 17.6722918 },
    { lat: 48.0980311, lng: 17.671842 },
    { lat: 48.0979056, lng: 17.6715159 },
    { lat: 48.0977136, lng: 17.6704916 },
    { lat: 48.0979223, lng: 17.6698792 },
    { lat: 48.0980479, lng: 17.6697775 },
    { lat: 48.0982836, lng: 17.6696456 },
    { lat: 48.0988649, lng: 17.6694984 },
    { lat: 48.0992672, lng: 17.6695038 },
    { lat: 48.0994734, lng: 17.6694788 },
    { lat: 48.0997101, lng: 17.6694346 },
    { lat: 48.1002679, lng: 17.6695994 },
    { lat: 48.1003877, lng: 17.6696911 },
    { lat: 48.100971, lng: 17.6705457 },
    { lat: 48.1013459, lng: 17.6707242 },
    { lat: 48.1018489, lng: 17.6708207 },
    { lat: 48.1021952, lng: 17.6706203 },
    { lat: 48.1024042, lng: 17.6703515 },
    { lat: 48.1025947, lng: 17.6697907 },
    { lat: 48.1026108, lng: 17.6695623 },
    { lat: 48.1024658, lng: 17.6687652 },
    { lat: 48.1023649, lng: 17.6684555 },
    { lat: 48.1020469, lng: 17.6679204 },
    { lat: 48.1019279, lng: 17.6672324 },
    { lat: 48.1019721, lng: 17.6670559 },
    { lat: 48.1018262, lng: 17.6669248 },
    { lat: 48.1015841, lng: 17.6665787 },
    { lat: 48.1014423, lng: 17.6660295 },
    { lat: 48.1013171, lng: 17.665762 },
    { lat: 48.1008888, lng: 17.6651294 },
    { lat: 48.1006294, lng: 17.6646137 },
    { lat: 48.1003748, lng: 17.6636727 },
    { lat: 48.100177, lng: 17.6625203 },
    { lat: 48.0999495, lng: 17.6616353 },
    { lat: 48.0997186, lng: 17.6611064 },
    { lat: 48.0995951, lng: 17.6609553 },
    { lat: 48.0992219, lng: 17.660695 },
    { lat: 48.0988201, lng: 17.6605374 },
    { lat: 48.0983046, lng: 17.6605607 },
    { lat: 48.098049, lng: 17.6608543 },
    { lat: 48.0976749, lng: 17.661284 },
    { lat: 48.0973441, lng: 17.661664 },
    { lat: 48.0972375, lng: 17.6617168 },
    { lat: 48.097019, lng: 17.6618252 },
    { lat: 48.0969721, lng: 17.6618052 },
    { lat: 48.0966904, lng: 17.6616848 },
    { lat: 48.0965896, lng: 17.6616417 },
    { lat: 48.096379, lng: 17.6614749 },
    { lat: 48.0963104, lng: 17.6614206 },
    { lat: 48.0960817, lng: 17.6610916 },
    { lat: 48.0957318, lng: 17.6605881 },
    { lat: 48.0954222, lng: 17.6600254 },
    { lat: 48.0946296, lng: 17.658585 },
    { lat: 48.0945408, lng: 17.6584235 },
    { lat: 48.0941155, lng: 17.6578138 },
    { lat: 48.0939923, lng: 17.6575515 },
    { lat: 48.0938488, lng: 17.6573955 },
    { lat: 48.0935869, lng: 17.6570796 },
    { lat: 48.0935147, lng: 17.6570323 },
    { lat: 48.0932329, lng: 17.6569044 },
    { lat: 48.0930196, lng: 17.6568076 },
    { lat: 48.0927413, lng: 17.6567967 },
    { lat: 48.0922114, lng: 17.656776 },
    { lat: 48.0920117, lng: 17.6568312 },
    { lat: 48.0915135, lng: 17.6570329 },
    { lat: 48.09134, lng: 17.6571032 },
    { lat: 48.0909799, lng: 17.6573925 },
    { lat: 48.090892, lng: 17.6574964 },
    { lat: 48.0906186, lng: 17.6578197 },
    { lat: 48.0903834, lng: 17.6581752 },
    { lat: 48.0899848, lng: 17.6587489 },
    { lat: 48.089368, lng: 17.6598526 },
    { lat: 48.089329, lng: 17.6601463 },
    { lat: 48.0891002, lng: 17.6603673 },
    { lat: 48.0888799, lng: 17.6607436 },
    { lat: 48.0886122, lng: 17.6615874 },
    { lat: 48.088869, lng: 17.662159 },
    { lat: 48.0890328, lng: 17.6625236 },
    { lat: 48.0891734, lng: 17.6630364 },
    { lat: 48.0891899, lng: 17.6632293 },
    { lat: 48.0892201, lng: 17.6635826 },
    { lat: 48.0892116, lng: 17.6637505 },
    { lat: 48.0891754, lng: 17.6644689 },
    { lat: 48.0888139, lng: 17.6660262 },
    { lat: 48.088416, lng: 17.666124 },
    { lat: 48.088441, lng: 17.666236 },
    { lat: 48.088515, lng: 17.66642 },
    { lat: 48.088447, lng: 17.666728 },
    { lat: 48.088343, lng: 17.667072 },
    { lat: 48.088269, lng: 17.667268 },
    { lat: 48.088156, lng: 17.667524 },
    { lat: 48.088063, lng: 17.667686 },
    { lat: 48.087948, lng: 17.667857 },
    { lat: 48.08776, lng: 17.668086 },
    { lat: 48.087554, lng: 17.668301 },
    { lat: 48.087336, lng: 17.668489 },
    { lat: 48.087259, lng: 17.66857 },
    { lat: 48.087148, lng: 17.668714 },
    { lat: 48.087066, lng: 17.668825 },
    { lat: 48.086898, lng: 17.668992 },
    { lat: 48.086546, lng: 17.669333 },
    { lat: 48.086378, lng: 17.669474 },
    { lat: 48.08617, lng: 17.669619 },
    { lat: 48.086031, lng: 17.669692 },
    { lat: 48.085847, lng: 17.669766 },
    { lat: 48.085597, lng: 17.669857 },
    { lat: 48.085409, lng: 17.669895 },
    { lat: 48.085055, lng: 17.669936 },
    { lat: 48.08498, lng: 17.669935 },
    { lat: 48.08485, lng: 17.669922 },
    { lat: 48.084624, lng: 17.669886 },
    { lat: 48.084361, lng: 17.669809 },
    { lat: 48.08424, lng: 17.669763 },
    { lat: 48.08394, lng: 17.669634 },
    { lat: 48.083692, lng: 17.669533 },
    { lat: 48.083508, lng: 17.669444 },
    { lat: 48.083028, lng: 17.669159 },
    { lat: 48.082557, lng: 17.668854 },
    { lat: 48.082119, lng: 17.668559 },
    { lat: 48.081804, lng: 17.668358 },
    { lat: 48.081685, lng: 17.668259 },
    { lat: 48.081544, lng: 17.668156 },
    { lat: 48.081485, lng: 17.668075 },
    { lat: 48.081292, lng: 17.667941 },
    { lat: 48.081001, lng: 17.667729 },
    { lat: 48.080945, lng: 17.667677 },
    { lat: 48.08082, lng: 17.667558 },
    { lat: 48.080656, lng: 17.667458 },
    { lat: 48.080338, lng: 17.667259 },
    { lat: 48.080284, lng: 17.667229 },
    { lat: 48.079973, lng: 17.667082 },
    { lat: 48.07987, lng: 17.667067 },
    { lat: 48.079781, lng: 17.667048 },
    { lat: 48.079659, lng: 17.667009 },
    { lat: 48.079555, lng: 17.666961 },
    { lat: 48.079468, lng: 17.66694 },
    { lat: 48.078941, lng: 17.666881 },
    { lat: 48.078573, lng: 17.666892 },
    { lat: 48.078458, lng: 17.666913 },
    { lat: 48.07828, lng: 17.666948 },
    { lat: 48.078126, lng: 17.666951 },
    { lat: 48.077825, lng: 17.667056 },
    { lat: 48.077797, lng: 17.667059 },
    { lat: 48.077516, lng: 17.667144 },
    { lat: 48.07727, lng: 17.667326 },
    { lat: 48.077129, lng: 17.667475 },
    { lat: 48.077066, lng: 17.66751 },
    { lat: 48.076824, lng: 17.667747 },
    { lat: 48.076746, lng: 17.667828 },
    { lat: 48.076595, lng: 17.668024 },
    { lat: 48.076496, lng: 17.668136 },
    { lat: 48.076365, lng: 17.668366 },
    { lat: 48.076236, lng: 17.668582 },
    { lat: 48.07618, lng: 17.668674 },
    { lat: 48.076153, lng: 17.668766 },
    { lat: 48.076106, lng: 17.668812 },
    { lat: 48.075997, lng: 17.66909 },
    { lat: 48.075943, lng: 17.669341 },
    { lat: 48.075871, lng: 17.669445 },
    { lat: 48.075812, lng: 17.669601 },
    { lat: 48.075737, lng: 17.669798 },
    { lat: 48.075711, lng: 17.669919 },
    { lat: 48.075582, lng: 17.670287 },
    { lat: 48.075525, lng: 17.670556 },
    { lat: 48.075489, lng: 17.670808 },
    { lat: 48.075437, lng: 17.671568 },
    { lat: 48.075421, lng: 17.672207 },
    { lat: 48.075429, lng: 17.673081 },
    { lat: 48.07551, lng: 17.673834 },
    { lat: 48.075528, lng: 17.674059 },
    { lat: 48.075535, lng: 17.674179 },
    { lat: 48.07558, lng: 17.674514 },
    { lat: 48.075628, lng: 17.67491 },
    { lat: 48.075731, lng: 17.675669 },
    { lat: 48.075801, lng: 17.67632 },
    { lat: 48.075816, lng: 17.676568 },
    { lat: 48.075827, lng: 17.676995 },
    { lat: 48.075844, lng: 17.677193 },
    { lat: 48.075841, lng: 17.677442 },
    { lat: 48.075794, lng: 17.67814 },
    { lat: 48.075772, lng: 17.678272 },
    { lat: 48.075635, lng: 17.678845 },
    { lat: 48.075534, lng: 17.679227 },
    { lat: 48.075443, lng: 17.67952 },
    { lat: 48.07536, lng: 17.679716 },
    { lat: 48.075281, lng: 17.679891 },
    { lat: 48.07518, lng: 17.680087 },
    { lat: 48.075096, lng: 17.680233 },
    { lat: 48.07504, lng: 17.680321 },
    { lat: 48.074942, lng: 17.680429 },
    { lat: 48.074808, lng: 17.680646 },
    { lat: 48.074758, lng: 17.680712 },
    { lat: 48.074638, lng: 17.680803 },
    { lat: 48.074462, lng: 17.680942 },
    { lat: 48.074373, lng: 17.681008 },
    { lat: 48.074252, lng: 17.68107 },
    { lat: 48.074071, lng: 17.681131 },
    { lat: 48.073918, lng: 17.681184 },
    { lat: 48.073807, lng: 17.681236 },
    { lat: 48.073554, lng: 17.681263 },
    { lat: 48.073436, lng: 17.681268 },
    { lat: 48.07326, lng: 17.681281 },
    { lat: 48.072942, lng: 17.681243 },
    { lat: 48.072663, lng: 17.681181 },
    { lat: 48.072413, lng: 17.681107 },
    { lat: 48.072346, lng: 17.681106 },
    { lat: 48.072311, lng: 17.681084 },
    { lat: 48.072284, lng: 17.681058 },
    { lat: 48.072207, lng: 17.681033 },
    { lat: 48.072133, lng: 17.681023 },
    { lat: 48.071965, lng: 17.680948 },
    { lat: 48.0718, lng: 17.680851 },
    { lat: 48.071701, lng: 17.680772 },
    { lat: 48.071573, lng: 17.680686 },
    { lat: 48.071448, lng: 17.680616 },
    { lat: 48.071358, lng: 17.680548 },
    { lat: 48.071173, lng: 17.680445 },
    { lat: 48.071103, lng: 17.680391 },
    { lat: 48.070975, lng: 17.680311 },
    { lat: 48.070713, lng: 17.68013 },
    { lat: 48.070562, lng: 17.680046 },
    { lat: 48.070449, lng: 17.680002 },
    { lat: 48.070331, lng: 17.679942 },
    { lat: 48.0703, lng: 17.679931 },
    { lat: 48.070243, lng: 17.679901 },
    { lat: 48.07009, lng: 17.679742 },
    { lat: 48.069968, lng: 17.679647 },
    { lat: 48.069923, lng: 17.679619 },
    { lat: 48.069761, lng: 17.679498 },
    { lat: 48.069589, lng: 17.679367 },
    { lat: 48.069368, lng: 17.679223 },
    { lat: 48.069148, lng: 17.679089 },
    { lat: 48.068824, lng: 17.678894 },
    { lat: 48.068607, lng: 17.678778 },
    { lat: 48.068544, lng: 17.678742 },
    { lat: 48.068461, lng: 17.678672 },
    { lat: 48.068433, lng: 17.678649 },
    { lat: 48.068391, lng: 17.67863 },
    { lat: 48.068293, lng: 17.6786 },
    { lat: 48.068113, lng: 17.678607 },
    { lat: 48.068098, lng: 17.678606 },
    { lat: 48.067649, lng: 17.678596 },
    { lat: 48.067341, lng: 17.678578 },
    { lat: 48.067012, lng: 17.678588 },
    { lat: 48.066713, lng: 17.678605 },
    { lat: 48.066634, lng: 17.678616 },
    { lat: 48.066484, lng: 17.678706 },
    { lat: 48.066334, lng: 17.678793 },
    { lat: 48.065917, lng: 17.679077 },
    { lat: 48.065863, lng: 17.679132 },
    { lat: 48.065724, lng: 17.67929 },
    { lat: 48.065676, lng: 17.679359 },
    { lat: 48.065602, lng: 17.6795 },
    { lat: 48.065449, lng: 17.679847 },
    { lat: 48.065381, lng: 17.680015 },
    { lat: 48.065305, lng: 17.680267 },
    { lat: 48.065123, lng: 17.680875 },
    { lat: 48.065094, lng: 17.681322 },
    { lat: 48.065072, lng: 17.681707 },
    { lat: 48.065084, lng: 17.681972 },
    { lat: 48.065141, lng: 17.682605 },
    { lat: 48.065191, lng: 17.683011 },
    { lat: 48.065242, lng: 17.683309 },
    { lat: 48.065282, lng: 17.683622 },
    { lat: 48.065347, lng: 17.684254 },
    { lat: 48.065426, lng: 17.684666 },
    { lat: 48.065509, lng: 17.684872 },
    { lat: 48.065649, lng: 17.685162 },
    { lat: 48.06609, lng: 17.685818 },
    { lat: 48.066127, lng: 17.685918 },
    { lat: 48.06642, lng: 17.68653 },
    { lat: 48.066544, lng: 17.686843 },
    { lat: 48.066554, lng: 17.686978 },
    { lat: 48.066611, lng: 17.687157 },
    { lat: 48.066663, lng: 17.687568 },
    { lat: 48.066673, lng: 17.68788 },
  ],
  KráľovBrod: [
    { lat: 48.056447, lng: 17.855457 },
    { lat: 48.054622, lng: 17.854155 },
    { lat: 48.05439, lng: 17.853989 },
    { lat: 48.054371, lng: 17.853975 },
    { lat: 48.054496, lng: 17.853759 },
    { lat: 48.05575, lng: 17.851586 },
    { lat: 48.056181, lng: 17.850843 },
    { lat: 48.056204, lng: 17.850804 },
    { lat: 48.057049, lng: 17.849345 },
    { lat: 48.057161, lng: 17.849154 },
    { lat: 48.057835, lng: 17.848001 },
    { lat: 48.058974, lng: 17.846051 },
    { lat: 48.060221, lng: 17.84391 },
    { lat: 48.060245, lng: 17.843869 },
    { lat: 48.060623, lng: 17.843221 },
    { lat: 48.060845, lng: 17.842839 },
    { lat: 48.06333, lng: 17.838573 },
    { lat: 48.063337, lng: 17.838562 },
    { lat: 48.063392, lng: 17.838467 },
    { lat: 48.064251, lng: 17.840402 },
    { lat: 48.065081, lng: 17.84225 },
    { lat: 48.06592, lng: 17.844136 },
    { lat: 48.066196, lng: 17.844761 },
    { lat: 48.066215, lng: 17.844784 },
    { lat: 48.066261, lng: 17.844839 },
    { lat: 48.066284, lng: 17.84489 },
    { lat: 48.066782, lng: 17.845993 },
    { lat: 48.067112, lng: 17.846717 },
    { lat: 48.067539, lng: 17.847807 },
    { lat: 48.067556, lng: 17.847849 },
    { lat: 48.067592, lng: 17.847754 },
    { lat: 48.068444, lng: 17.849666 },
    { lat: 48.069019, lng: 17.850975 },
    { lat: 48.06902, lng: 17.850979 },
    { lat: 48.069123, lng: 17.851212 },
    { lat: 48.069169, lng: 17.851315 },
    { lat: 48.069283, lng: 17.851574 },
    { lat: 48.069795, lng: 17.851035 },
    { lat: 48.069865, lng: 17.850961 },
    { lat: 48.0732, lng: 17.847449 },
    { lat: 48.075782, lng: 17.844693 },
    { lat: 48.076836, lng: 17.843555 },
    { lat: 48.076794, lng: 17.84345 },
    { lat: 48.076783, lng: 17.843422 },
    { lat: 48.074272, lng: 17.836973 },
    { lat: 48.073773, lng: 17.835705 },
    { lat: 48.073749, lng: 17.835646 },
    { lat: 48.072139, lng: 17.83156 },
    { lat: 48.069949, lng: 17.826062 },
    { lat: 48.06957, lng: 17.825109 },
    { lat: 48.069569, lng: 17.825106 },
    { lat: 48.069507, lng: 17.82495 },
    { lat: 48.069498, lng: 17.824926 },
    { lat: 48.069236, lng: 17.824265 },
    { lat: 48.068363, lng: 17.822071 },
    { lat: 48.06745, lng: 17.819771 },
    { lat: 48.067066, lng: 17.818798 },
    { lat: 48.066999, lng: 17.818628 },
    { lat: 48.066955, lng: 17.818518 },
    { lat: 48.06563, lng: 17.815196 },
    { lat: 48.065615, lng: 17.815161 },
    { lat: 48.06556, lng: 17.815021 },
    { lat: 48.065498, lng: 17.814866 },
    { lat: 48.065486, lng: 17.81484 },
    { lat: 48.06539, lng: 17.814621 },
    { lat: 48.0644289, lng: 17.8153248 },
    { lat: 48.0642663, lng: 17.8155472 },
    { lat: 48.0639669, lng: 17.8163461 },
    { lat: 48.0638426, lng: 17.8164757 },
    { lat: 48.0637473, lng: 17.8162996 },
    { lat: 48.0633093, lng: 17.8148739 },
    { lat: 48.0632682, lng: 17.8145722 },
    { lat: 48.0632581, lng: 17.8140964 },
    { lat: 48.0635198, lng: 17.8119539 },
    { lat: 48.0641681, lng: 17.8106216 },
    { lat: 48.0645894, lng: 17.8098917 },
    { lat: 48.065097, lng: 17.8084961 },
    { lat: 48.0651416, lng: 17.8080114 },
    { lat: 48.0651111, lng: 17.8075899 },
    { lat: 48.0649879, lng: 17.8069663 },
    { lat: 48.0648037, lng: 17.8065952 },
    { lat: 48.0645225, lng: 17.8063011 },
    { lat: 48.0641664, lng: 17.806277 },
    { lat: 48.0637871, lng: 17.8063488 },
    { lat: 48.0633646, lng: 17.8066945 },
    { lat: 48.0625717, lng: 17.8078026 },
    { lat: 48.0620598, lng: 17.8081407 },
    { lat: 48.0617598, lng: 17.8082017 },
    { lat: 48.0614709, lng: 17.8081666 },
    { lat: 48.060974, lng: 17.807961 },
    { lat: 48.0605218, lng: 17.8075876 },
    { lat: 48.0596512, lng: 17.8063772 },
    { lat: 48.0589726, lng: 17.804784 },
    { lat: 48.0587736, lng: 17.8041789 },
    { lat: 48.0586814, lng: 17.8033793 },
    { lat: 48.0589515, lng: 17.802306 },
    { lat: 48.0594722, lng: 17.801961 },
    { lat: 48.0600398, lng: 17.80192 },
    { lat: 48.060343, lng: 17.8020223 },
    { lat: 48.0607439, lng: 17.8022999 },
    { lat: 48.0611668, lng: 17.8024175 },
    { lat: 48.0616092, lng: 17.8024375 },
    { lat: 48.0619832, lng: 17.8023487 },
    { lat: 48.0623074, lng: 17.80217 },
    { lat: 48.0626105, lng: 17.8018877 },
    { lat: 48.0628078, lng: 17.8016082 },
    { lat: 48.0629506, lng: 17.8011975 },
    { lat: 48.0631082, lng: 17.8004933 },
    { lat: 48.0631274, lng: 17.8003686 },
    { lat: 48.0630706, lng: 17.7999426 },
    { lat: 48.0629241, lng: 17.7993438 },
    { lat: 48.062689, lng: 17.7986646 },
    { lat: 48.0623564, lng: 17.7979756 },
    { lat: 48.0620723, lng: 17.7969764 },
    { lat: 48.0616432, lng: 17.7960819 },
    { lat: 48.061039, lng: 17.7952566 },
    { lat: 48.0604329, lng: 17.7947133 },
    { lat: 48.060191, lng: 17.7943782 },
    { lat: 48.0597754, lng: 17.7941185 },
    { lat: 48.0591052, lng: 17.7932508 },
    { lat: 48.0590055, lng: 17.7930087 },
    { lat: 48.0587268, lng: 17.7917308 },
    { lat: 48.0586832, lng: 17.7912089 },
    { lat: 48.0587052, lng: 17.7908155 },
    { lat: 48.058905, lng: 17.7902146 },
    { lat: 48.0591195, lng: 17.7899034 },
    { lat: 48.0592949, lng: 17.7897826 },
    { lat: 48.0598146, lng: 17.7896976 },
    { lat: 48.0604035, lng: 17.7898547 },
    { lat: 48.0612217, lng: 17.790217 },
    { lat: 48.0618342, lng: 17.7904083 },
    { lat: 48.0623642, lng: 17.7903984 },
    { lat: 48.0627775, lng: 17.7902723 },
    { lat: 48.0632519, lng: 17.7899667 },
    { lat: 48.0635238, lng: 17.7894327 },
    { lat: 48.063664, lng: 17.7870466 },
    { lat: 48.0638193, lng: 17.7865823 },
    { lat: 48.0641413, lng: 17.7851459 },
    { lat: 48.0642952, lng: 17.7840257 },
    { lat: 48.0642584, lng: 17.7833926 },
    { lat: 48.064133, lng: 17.7828615 },
    { lat: 48.0637962, lng: 17.782183 },
    { lat: 48.0636051, lng: 17.7819785 },
    { lat: 48.0633518, lng: 17.781915 },
    { lat: 48.0628762, lng: 17.782276 },
    { lat: 48.0625833, lng: 17.7826499 },
    { lat: 48.0618663, lng: 17.7832506 },
    { lat: 48.0614124, lng: 17.7833587 },
    { lat: 48.0611078, lng: 17.7832729 },
    { lat: 48.0608189, lng: 17.7827788 },
    { lat: 48.060892, lng: 17.7820117 },
    { lat: 48.0608512, lng: 17.7815169 },
    { lat: 48.0606702, lng: 17.7813449 },
    { lat: 48.0604809, lng: 17.78128 },
    { lat: 48.0603086, lng: 17.7810302 },
    { lat: 48.0601649, lng: 17.7801515 },
    { lat: 48.0601656, lng: 17.7796351 },
    { lat: 48.060351, lng: 17.7788939 },
    { lat: 48.0608403, lng: 17.7780821 },
    { lat: 48.061159, lng: 17.7777191 },
    { lat: 48.0614288, lng: 17.7775379 },
    { lat: 48.062254, lng: 17.777172 },
    { lat: 48.0626582, lng: 17.7768265 },
    { lat: 48.0629504, lng: 17.77635 },
    { lat: 48.0632537, lng: 17.7756725 },
    { lat: 48.0634003, lng: 17.7755216 },
    { lat: 48.0638174, lng: 17.7747473 },
    { lat: 48.0640457, lng: 17.7744616 },
    { lat: 48.0641712, lng: 17.7739516 },
    { lat: 48.0642323, lng: 17.7730464 },
    { lat: 48.0640821, lng: 17.7722458 },
    { lat: 48.0639981, lng: 17.7721656 },
    { lat: 48.0558589, lng: 17.7644116 },
    { lat: 48.0552768, lng: 17.7649458 },
    { lat: 48.0544593, lng: 17.7655454 },
    { lat: 48.0541498, lng: 17.7660134 },
    { lat: 48.0539837, lng: 17.7666493 },
    { lat: 48.0539505, lng: 17.7671701 },
    { lat: 48.0540283, lng: 17.7679128 },
    { lat: 48.0539791, lng: 17.768301 },
    { lat: 48.0534553, lng: 17.7692658 },
    { lat: 48.0532516, lng: 17.7695314 },
    { lat: 48.052955, lng: 17.7697198 },
    { lat: 48.0519106, lng: 17.7699347 },
    { lat: 48.0508313, lng: 17.7699913 },
    { lat: 48.0502207, lng: 17.769885 },
    { lat: 48.049519, lng: 17.7695839 },
    { lat: 48.049367, lng: 17.7695746 },
    { lat: 48.0490216, lng: 17.7696812 },
    { lat: 48.048588, lng: 17.7699718 },
    { lat: 48.0484182, lng: 17.770129 },
    { lat: 48.0481011, lng: 17.7706223 },
    { lat: 48.0477375, lng: 17.7709124 },
    { lat: 48.0458503, lng: 17.7717541 },
    { lat: 48.0454367, lng: 17.7718221 },
    { lat: 48.0444836, lng: 17.7715645 },
    { lat: 48.0443999, lng: 17.7714886 },
    { lat: 48.0423645, lng: 17.7719287 },
    { lat: 48.041925, lng: 17.7721957 },
    { lat: 48.0412829, lng: 17.7732894 },
    { lat: 48.04117, lng: 17.7737273 },
    { lat: 48.0406757, lng: 17.775076 },
    { lat: 48.0401078, lng: 17.7759859 },
    { lat: 48.0397781, lng: 17.776715 },
    { lat: 48.0391268, lng: 17.7779157 },
    { lat: 48.0389799, lng: 17.7780799 },
    { lat: 48.0384711, lng: 17.7783413 },
    { lat: 48.0381725, lng: 17.7785694 },
    { lat: 48.0376254, lng: 17.7791768 },
    { lat: 48.0374052, lng: 17.7795024 },
    { lat: 48.0366308, lng: 17.7817253 },
    { lat: 48.0363572, lng: 17.7828311 },
    { lat: 48.0360902, lng: 17.7834041 },
    { lat: 48.0356693, lng: 17.7839534 },
    { lat: 48.0353993, lng: 17.7841515 },
    { lat: 48.0352332, lng: 17.7842389 },
    { lat: 48.0349272, lng: 17.784202 },
    { lat: 48.0346995, lng: 17.7842807 },
    { lat: 48.0345381, lng: 17.7844281 },
    { lat: 48.0343639, lng: 17.7846955 },
    { lat: 48.0342087, lng: 17.785252 },
    { lat: 48.0342964, lng: 17.7853538 },
    { lat: 48.0368887, lng: 17.7877622 },
    { lat: 48.0367271, lng: 17.7883284 },
    { lat: 48.0374264, lng: 17.789592 },
    { lat: 48.0375297, lng: 17.7899305 },
    { lat: 48.0381363, lng: 17.7912848 },
    { lat: 48.0380483, lng: 17.7916856 },
    { lat: 48.0378065, lng: 17.7922667 },
    { lat: 48.0380709, lng: 17.792635 },
    { lat: 48.0383778, lng: 17.7933815 },
    { lat: 48.0385401, lng: 17.7935771 },
    { lat: 48.0386037, lng: 17.7938746 },
    { lat: 48.0385926, lng: 17.7944454 },
    { lat: 48.0383773, lng: 17.7953245 },
    { lat: 48.038551, lng: 17.7956692 },
    { lat: 48.0393033, lng: 17.7977571 },
    { lat: 48.0394789, lng: 17.7979118 },
    { lat: 48.0398923, lng: 17.7976609 },
    { lat: 48.0408536, lng: 17.7977816 },
    { lat: 48.0418754, lng: 17.797724 },
    { lat: 48.0421347, lng: 17.7978129 },
    { lat: 48.0423402, lng: 17.7979949 },
    { lat: 48.042499, lng: 17.7981727 },
    { lat: 48.0424079, lng: 17.7986689 },
    { lat: 48.0423964, lng: 17.7989958 },
    { lat: 48.0419601, lng: 17.8009233 },
    { lat: 48.042095, lng: 17.8013345 },
    { lat: 48.0423103, lng: 17.8014922 },
    { lat: 48.0424397, lng: 17.8017055 },
    { lat: 48.0426238, lng: 17.8024379 },
    { lat: 48.0427166, lng: 17.802477 },
    { lat: 48.0432642, lng: 17.8021129 },
    { lat: 48.043705, lng: 17.8023052 },
    { lat: 48.0441867, lng: 17.8019681 },
    { lat: 48.0448389, lng: 17.8025463 },
    { lat: 48.0450624, lng: 17.802952 },
    { lat: 48.0450228, lng: 17.8035311 },
    { lat: 48.0452893, lng: 17.8038246 },
    { lat: 48.0486807, lng: 17.8246368 },
    { lat: 48.0469821, lng: 17.8252654 },
    { lat: 48.0463726, lng: 17.825617 },
    { lat: 48.0464563, lng: 17.8262049 },
    { lat: 48.04611, lng: 17.8267777 },
    { lat: 48.0459416, lng: 17.8271778 },
    { lat: 48.0454661, lng: 17.8288252 },
    { lat: 48.0441855, lng: 17.8326867 },
    { lat: 48.0437161, lng: 17.8338657 },
    { lat: 48.0433522, lng: 17.8349999 },
    { lat: 48.0431003, lng: 17.8355462 },
    { lat: 48.0428295, lng: 17.8358436 },
    { lat: 48.0422542, lng: 17.8359981 },
    { lat: 48.0419426, lng: 17.8359395 },
    { lat: 48.0413191, lng: 17.8355665 },
    { lat: 48.041074, lng: 17.8356593 },
    { lat: 48.0408781, lng: 17.8359242 },
    { lat: 48.0406445, lng: 17.8364955 },
    { lat: 48.0405547, lng: 17.8369431 },
    { lat: 48.0405908, lng: 17.8374916 },
    { lat: 48.0407192, lng: 17.8377989 },
    { lat: 48.0411807, lng: 17.8393366 },
    { lat: 48.0410796, lng: 17.8400101 },
    { lat: 48.0407045, lng: 17.8403721 },
    { lat: 48.0402427, lng: 17.8406458 },
    { lat: 48.0398223, lng: 17.8407103 },
    { lat: 48.0395221, lng: 17.8406952 },
    { lat: 48.0391082, lng: 17.8405155 },
    { lat: 48.035587, lng: 17.8367673 },
    { lat: 48.034919, lng: 17.8364795 },
    { lat: 48.0342293, lng: 17.836608 },
    { lat: 48.0339918, lng: 17.836712 },
    { lat: 48.0334233, lng: 17.837122 },
    { lat: 48.0329358, lng: 17.8376584 },
    { lat: 48.0325879, lng: 17.8382252 },
    { lat: 48.0324185, lng: 17.8386098 },
    { lat: 48.0322575, lng: 17.8391683 },
    { lat: 48.0322863, lng: 17.8396909 },
    { lat: 48.0324332, lng: 17.8404457 },
    { lat: 48.0322796, lng: 17.8409587 },
    { lat: 48.0320107, lng: 17.8412684 },
    { lat: 48.0315448, lng: 17.841362 },
    { lat: 48.030994, lng: 17.8412183 },
    { lat: 48.0307867, lng: 17.8410794 },
    { lat: 48.030621, lng: 17.8409148 },
    { lat: 48.0304699, lng: 17.8406279 },
    { lat: 48.0302003, lng: 17.8398014 },
    { lat: 48.0300807, lng: 17.8395843 },
    { lat: 48.0298167, lng: 17.8393498 },
    { lat: 48.0294828, lng: 17.8393704 },
    { lat: 48.0289368, lng: 17.8400012 },
    { lat: 48.0284747, lng: 17.8408762 },
    { lat: 48.0283233, lng: 17.841423 },
    { lat: 48.0282918, lng: 17.8417916 },
    { lat: 48.028365, lng: 17.8422859 },
    { lat: 48.0284869, lng: 17.8426925 },
    { lat: 48.0291314, lng: 17.8436991 },
    { lat: 48.0291823, lng: 17.8441228 },
    { lat: 48.0291184, lng: 17.8443332 },
    { lat: 48.0288794, lng: 17.8446515 },
    { lat: 48.0285421, lng: 17.844729 },
    { lat: 48.028464, lng: 17.8446709 },
    { lat: 48.0280772, lng: 17.8438124 },
    { lat: 48.0279839, lng: 17.8435063 },
    { lat: 48.0278736, lng: 17.8430513 },
    { lat: 48.0277641, lng: 17.8418778 },
    { lat: 48.0277973, lng: 17.8403237 },
    { lat: 48.0278689, lng: 17.8393424 },
    { lat: 48.0281461, lng: 17.8374802 },
    { lat: 48.0285221, lng: 17.8359398 },
    { lat: 48.0285324, lng: 17.8352063 },
    { lat: 48.0283256, lng: 17.8346876 },
    { lat: 48.0280925, lng: 17.8344775 },
    { lat: 48.0279046, lng: 17.8343759 },
    { lat: 48.0274882, lng: 17.8343648 },
    { lat: 48.0268683, lng: 17.8347807 },
    { lat: 48.0265987, lng: 17.8352051 },
    { lat: 48.0263069, lng: 17.8358414 },
    { lat: 48.0256815, lng: 17.8374926 },
    { lat: 48.0252764, lng: 17.8387798 },
    { lat: 48.0251094, lng: 17.8395142 },
    { lat: 48.0249241, lng: 17.8409441 },
    { lat: 48.0247361, lng: 17.8418196 },
    { lat: 48.0246049, lng: 17.8421239 },
    { lat: 48.0243879, lng: 17.8423968 },
    { lat: 48.0241709, lng: 17.8424307 },
    { lat: 48.0238862, lng: 17.8422971 },
    { lat: 48.0235003, lng: 17.8419489 },
    { lat: 48.0231231, lng: 17.8412895 },
    { lat: 48.0229618, lng: 17.8407749 },
    { lat: 48.0229431, lng: 17.8405278 },
    { lat: 48.0230545, lng: 17.840081 },
    { lat: 48.0235506, lng: 17.8396122 },
    { lat: 48.023652, lng: 17.8393336 },
    { lat: 48.0236673, lng: 17.8390864 },
    { lat: 48.0236218, lng: 17.8383465 },
    { lat: 48.0235076, lng: 17.8377358 },
    { lat: 48.023242, lng: 17.8370524 },
    { lat: 48.0229786, lng: 17.8366624 },
    { lat: 48.0225437, lng: 17.8363068 },
    { lat: 48.0222382, lng: 17.8361853 },
    { lat: 48.0214681, lng: 17.8362924 },
    { lat: 48.020453, lng: 17.8367495 },
    { lat: 48.0202154, lng: 17.8369435 },
    { lat: 48.0198368, lng: 17.837504 },
    { lat: 48.0197026, lng: 17.8379286 },
    { lat: 48.0197123, lng: 17.8382136 },
    { lat: 48.0198595, lng: 17.8386067 },
    { lat: 48.0201126, lng: 17.83896 },
    { lat: 48.0205661, lng: 17.8398345 },
    { lat: 48.0213941, lng: 17.8419207 },
    { lat: 48.0215774, lng: 17.8425134 },
    { lat: 48.0216259, lng: 17.842883 },
    { lat: 48.0215924, lng: 17.8433255 },
    { lat: 48.0215401, lng: 17.8436148 },
    { lat: 48.0213177, lng: 17.844203 },
    { lat: 48.0214948, lng: 17.8443671 },
    { lat: 48.0236755, lng: 17.8465673 },
    { lat: 48.0251227, lng: 17.8457935 },
    { lat: 48.0255688, lng: 17.845611 },
    { lat: 48.0277356, lng: 17.8449794 },
    { lat: 48.028322, lng: 17.8449664 },
    { lat: 48.0287493, lng: 17.852827 },
    { lat: 48.0288181, lng: 17.8559721 },
    { lat: 48.0275485, lng: 17.858114 },
    { lat: 48.024863, lng: 17.8631625 },
    { lat: 48.022491, lng: 17.868624 },
    { lat: 48.022542, lng: 17.868695 },
    { lat: 48.022699, lng: 17.868905 },
    { lat: 48.022731, lng: 17.86895 },
    { lat: 48.023327, lng: 17.869763 },
    { lat: 48.024252, lng: 17.871023 },
    { lat: 48.024367, lng: 17.871181 },
    { lat: 48.024393, lng: 17.871215 },
    { lat: 48.024457, lng: 17.871301 },
    { lat: 48.024718, lng: 17.871651 },
    { lat: 48.024725, lng: 17.87166 },
    { lat: 48.025693, lng: 17.872762 },
    { lat: 48.025736, lng: 17.872817 },
    { lat: 48.025781, lng: 17.872867 },
    { lat: 48.026064, lng: 17.873184 },
    { lat: 48.026081, lng: 17.873203 },
    { lat: 48.026436, lng: 17.873592 },
    { lat: 48.026826, lng: 17.874019 },
    { lat: 48.027087, lng: 17.874369 },
    { lat: 48.027095, lng: 17.874379 },
    { lat: 48.027161, lng: 17.874465 },
    { lat: 48.027345, lng: 17.874705 },
    { lat: 48.028301, lng: 17.875947 },
    { lat: 48.028373, lng: 17.87604 },
    { lat: 48.028492, lng: 17.876191 },
    { lat: 48.02886, lng: 17.876666 },
    { lat: 48.02915, lng: 17.87704 },
    { lat: 48.02947, lng: 17.877454 },
    { lat: 48.029539, lng: 17.877566 },
    { lat: 48.029549, lng: 17.877581 },
    { lat: 48.029577, lng: 17.877628 },
    { lat: 48.029613, lng: 17.877685 },
    { lat: 48.030177, lng: 17.878602 },
    { lat: 48.030753, lng: 17.879539 },
    { lat: 48.030993, lng: 17.879928 },
    { lat: 48.031039, lng: 17.880005 },
    { lat: 48.031091, lng: 17.880064 },
    { lat: 48.031611, lng: 17.880672 },
    { lat: 48.032244, lng: 17.88141 },
    { lat: 48.032478, lng: 17.881685 },
    { lat: 48.032532, lng: 17.881767 },
    { lat: 48.032572, lng: 17.881828 },
    { lat: 48.032769, lng: 17.882134 },
    { lat: 48.033351, lng: 17.883039 },
    { lat: 48.033518, lng: 17.883298 },
    { lat: 48.03391, lng: 17.883905 },
    { lat: 48.03426, lng: 17.884449 },
    { lat: 48.034274, lng: 17.88447 },
    { lat: 48.034298, lng: 17.884454 },
    { lat: 48.034342, lng: 17.884433 },
    { lat: 48.034406, lng: 17.884401 },
    { lat: 48.035019, lng: 17.884098 },
    { lat: 48.035863, lng: 17.883678 },
    { lat: 48.03638, lng: 17.883414 },
    { lat: 48.036699, lng: 17.883261 },
    { lat: 48.036883, lng: 17.883055 },
    { lat: 48.037007, lng: 17.882862 },
    { lat: 48.037097, lng: 17.88272 },
    { lat: 48.037188, lng: 17.882578 },
    { lat: 48.037274, lng: 17.882443 },
    { lat: 48.037297, lng: 17.882407 },
    { lat: 48.03731, lng: 17.882388 },
    { lat: 48.037322, lng: 17.882366 },
    { lat: 48.037348, lng: 17.882325 },
    { lat: 48.037367, lng: 17.882291 },
    { lat: 48.037445, lng: 17.882162 },
    { lat: 48.037524, lng: 17.882029 },
    { lat: 48.037639, lng: 17.881838 },
    { lat: 48.037901, lng: 17.881399 },
    { lat: 48.038003, lng: 17.881234 },
    { lat: 48.038366, lng: 17.880651 },
    { lat: 48.03846, lng: 17.880501 },
    { lat: 48.038519, lng: 17.880407 },
    { lat: 48.038533, lng: 17.880383 },
    { lat: 48.038688, lng: 17.88013 },
    { lat: 48.038726, lng: 17.880068 },
    { lat: 48.038769, lng: 17.879997 },
    { lat: 48.038813, lng: 17.879924 },
    { lat: 48.038854, lng: 17.879858 },
    { lat: 48.038912, lng: 17.879764 },
    { lat: 48.038987, lng: 17.879639 },
    { lat: 48.039066, lng: 17.87951 },
    { lat: 48.039116, lng: 17.879429 },
    { lat: 48.03914, lng: 17.879388 },
    { lat: 48.039179, lng: 17.879325 },
    { lat: 48.039222, lng: 17.879257 },
    { lat: 48.039271, lng: 17.879179 },
    { lat: 48.039313, lng: 17.879109 },
    { lat: 48.039418, lng: 17.87894 },
    { lat: 48.039477, lng: 17.878844 },
    { lat: 48.039543, lng: 17.878737 },
    { lat: 48.039641, lng: 17.878577 },
    { lat: 48.03979, lng: 17.878332 },
    { lat: 48.039827, lng: 17.878281 },
    { lat: 48.039951, lng: 17.878104 },
    { lat: 48.040108, lng: 17.877976 },
    { lat: 48.040142, lng: 17.877948 },
    { lat: 48.040237, lng: 17.877941 },
    { lat: 48.040299, lng: 17.877937 },
    { lat: 48.040396, lng: 17.87798 },
    { lat: 48.040476, lng: 17.878015 },
    { lat: 48.040677, lng: 17.87817 },
    { lat: 48.041451, lng: 17.878755 },
    { lat: 48.042313, lng: 17.879413 },
    { lat: 48.043133, lng: 17.880048 },
    { lat: 48.043781, lng: 17.880548 },
    { lat: 48.044397, lng: 17.881027 },
    { lat: 48.044644, lng: 17.881197 },
    { lat: 48.044853, lng: 17.881299 },
    { lat: 48.045085, lng: 17.88133 },
    { lat: 48.045301, lng: 17.881253 },
    { lat: 48.045623, lng: 17.881079 },
    { lat: 48.045642, lng: 17.881068 },
    { lat: 48.04569, lng: 17.881048 },
    { lat: 48.045919, lng: 17.880892 },
    { lat: 48.045965, lng: 17.880851 },
    { lat: 48.04612, lng: 17.880713 },
    { lat: 48.046343, lng: 17.880447 },
    { lat: 48.047108, lng: 17.879432 },
    { lat: 48.047768, lng: 17.878559 },
    { lat: 48.048143, lng: 17.878073 },
    { lat: 48.048845, lng: 17.877142 },
    { lat: 48.049718, lng: 17.875996 },
    { lat: 48.050165, lng: 17.875395 },
    { lat: 48.050366, lng: 17.875126 },
    { lat: 48.051046, lng: 17.874228 },
    { lat: 48.051691, lng: 17.873377 },
    { lat: 48.052311, lng: 17.87258 },
    { lat: 48.05272, lng: 17.872034 },
    { lat: 48.052914, lng: 17.871767 },
    { lat: 48.053031, lng: 17.871586 },
    { lat: 48.053103, lng: 17.871436 },
    { lat: 48.053132, lng: 17.871182 },
    { lat: 48.05305, lng: 17.870515 },
    { lat: 48.052958, lng: 17.870014 },
    { lat: 48.052817, lng: 17.869229 },
    { lat: 48.05281, lng: 17.869146 },
    { lat: 48.052796, lng: 17.869072 },
    { lat: 48.052763, lng: 17.868898 },
    { lat: 48.052701, lng: 17.868683 },
    { lat: 48.052578, lng: 17.868405 },
    { lat: 48.052467, lng: 17.868154 },
    { lat: 48.052377, lng: 17.867849 },
    { lat: 48.052316, lng: 17.867429 },
    { lat: 48.052178, lng: 17.866441 },
    { lat: 48.052149, lng: 17.86612 },
    { lat: 48.05216, lng: 17.865781 },
    { lat: 48.052251, lng: 17.865019 },
    { lat: 48.052299, lng: 17.864561 },
    { lat: 48.052319, lng: 17.864125 },
    { lat: 48.052238, lng: 17.863809 },
    { lat: 48.052012, lng: 17.863414 },
    { lat: 48.051839, lng: 17.863122 },
    { lat: 48.051772, lng: 17.862921 },
    { lat: 48.051725, lng: 17.862677 },
    { lat: 48.051759, lng: 17.862363 },
    { lat: 48.052046, lng: 17.861471 },
    { lat: 48.052265, lng: 17.860791 },
    { lat: 48.052412, lng: 17.860324 },
    { lat: 48.052738, lng: 17.859286 },
    { lat: 48.052878, lng: 17.858839 },
    { lat: 48.053081, lng: 17.8582 },
    { lat: 48.053376, lng: 17.857281 },
    { lat: 48.05352, lng: 17.856833 },
    { lat: 48.053608, lng: 17.856649 },
    { lat: 48.053684, lng: 17.856554 },
    { lat: 48.053777, lng: 17.85644 },
    { lat: 48.053973, lng: 17.856275 },
    { lat: 48.054607, lng: 17.856074 },
    { lat: 48.055159, lng: 17.85595 },
    { lat: 48.055199, lng: 17.855941 },
    { lat: 48.055391, lng: 17.855899 },
    { lat: 48.055456, lng: 17.85588 },
    { lat: 48.055786, lng: 17.855784 },
    { lat: 48.055958, lng: 17.855735 },
    { lat: 48.056165, lng: 17.855672 },
    { lat: 48.056273, lng: 17.85564 },
    { lat: 48.056291, lng: 17.855632 },
    { lat: 48.056297, lng: 17.855629 },
    { lat: 48.056371, lng: 17.855597 },
    { lat: 48.056418, lng: 17.855576 },
    { lat: 48.056422, lng: 17.855561 },
    { lat: 48.056447, lng: 17.855457 },
  ],
  Jelka: [
    { lat: 48.174951, lng: 17.514293 },
    { lat: 48.174994, lng: 17.514265 },
    { lat: 48.175147, lng: 17.514136 },
    { lat: 48.175575, lng: 17.513556 },
    { lat: 48.175655, lng: 17.513328 },
    { lat: 48.175762, lng: 17.513057 },
    { lat: 48.175771, lng: 17.512918 },
    { lat: 48.17574, lng: 17.512426 },
    { lat: 48.175665, lng: 17.512168 },
    { lat: 48.175581, lng: 17.512109 },
    { lat: 48.175252, lng: 17.512177 },
    { lat: 48.174794, lng: 17.512175 },
    { lat: 48.174722, lng: 17.512119 },
    { lat: 48.174634, lng: 17.512021 },
    { lat: 48.17439, lng: 17.511587 },
    { lat: 48.174288, lng: 17.511344 },
    { lat: 48.174269, lng: 17.511267 },
    { lat: 48.174258, lng: 17.511143 },
    { lat: 48.174275, lng: 17.510887 },
    { lat: 48.174229, lng: 17.510728 },
    { lat: 48.174095, lng: 17.510363 },
    { lat: 48.174056, lng: 17.510114 },
    { lat: 48.174067, lng: 17.509933 },
    { lat: 48.174033, lng: 17.509808 },
    { lat: 48.174032, lng: 17.509752 },
    { lat: 48.174057, lng: 17.509613 },
    { lat: 48.174116, lng: 17.509506 },
    { lat: 48.174363, lng: 17.509311 },
    { lat: 48.174495, lng: 17.509176 },
    { lat: 48.174671, lng: 17.50924 },
    { lat: 48.174951, lng: 17.509467 },
    { lat: 48.175354, lng: 17.510006 },
    { lat: 48.175448, lng: 17.510109 },
    { lat: 48.175629, lng: 17.510216 },
    { lat: 48.175731, lng: 17.510223 },
    { lat: 48.175805, lng: 17.510188 },
    { lat: 48.176067, lng: 17.509869 },
    { lat: 48.176472, lng: 17.509553 },
    { lat: 48.176643, lng: 17.509367 },
    { lat: 48.176822, lng: 17.50901 },
    { lat: 48.176851, lng: 17.508881 },
    { lat: 48.176825, lng: 17.508684 },
    { lat: 48.176698, lng: 17.508502 },
    { lat: 48.176621, lng: 17.508389 },
    { lat: 48.176393, lng: 17.50818 },
    { lat: 48.176283, lng: 17.508134 },
    { lat: 48.176231, lng: 17.50805 },
    { lat: 48.176218, lng: 17.507952 },
    { lat: 48.176262, lng: 17.507677 },
    { lat: 48.176515, lng: 17.5071079 },
    { lat: 48.176443, lng: 17.507059 },
    { lat: 48.175838, lng: 17.506645 },
    { lat: 48.175009, lng: 17.505966 },
    { lat: 48.174461, lng: 17.505515 },
    { lat: 48.172223, lng: 17.50359 },
    { lat: 48.171983, lng: 17.503381 },
    { lat: 48.171955, lng: 17.503357 },
    { lat: 48.170355, lng: 17.501968 },
    { lat: 48.168347, lng: 17.500158 },
    { lat: 48.168336, lng: 17.500149 },
    { lat: 48.168448, lng: 17.49993 },
    { lat: 48.168715, lng: 17.499404 },
    { lat: 48.16896, lng: 17.498884 },
    { lat: 48.169156, lng: 17.498444 },
    { lat: 48.169401, lng: 17.497858 },
    { lat: 48.169658, lng: 17.49719 },
    { lat: 48.169849, lng: 17.496633 },
    { lat: 48.170047, lng: 17.496065 },
    { lat: 48.170101, lng: 17.495927 },
    { lat: 48.170308, lng: 17.495314 },
    { lat: 48.170479, lng: 17.494732 },
    { lat: 48.170559, lng: 17.494456 },
    { lat: 48.170666, lng: 17.493993 },
    { lat: 48.170753, lng: 17.49356 },
    { lat: 48.170954, lng: 17.492583 },
    { lat: 48.171058, lng: 17.492035 },
    { lat: 48.171128, lng: 17.491693 },
    { lat: 48.171254, lng: 17.490947 },
    { lat: 48.171334, lng: 17.49039 },
    { lat: 48.171399, lng: 17.490189 },
    { lat: 48.171063, lng: 17.490372 },
    { lat: 48.171339, lng: 17.489504 },
    { lat: 48.17164, lng: 17.488565 },
    { lat: 48.171849, lng: 17.487911 },
    { lat: 48.172015, lng: 17.487382 },
    { lat: 48.172105, lng: 17.487091 },
    { lat: 48.172157, lng: 17.486817 },
    { lat: 48.172278, lng: 17.486236 },
    { lat: 48.172219, lng: 17.485553 },
    { lat: 48.172138, lng: 17.485315 },
    { lat: 48.171986, lng: 17.484889 },
    { lat: 48.171908, lng: 17.484677 },
    { lat: 48.171706, lng: 17.484105 },
    { lat: 48.171397, lng: 17.483335 },
    { lat: 48.17123, lng: 17.482925 },
    { lat: 48.171067, lng: 17.48224 },
    { lat: 48.170649, lng: 17.481543 },
    { lat: 48.170218, lng: 17.480827 },
    { lat: 48.169505, lng: 17.481308 },
    { lat: 48.16914, lng: 17.481562 },
    { lat: 48.168481, lng: 17.481921 },
    { lat: 48.167957, lng: 17.482245 },
    { lat: 48.167568, lng: 17.482488 },
    { lat: 48.167261, lng: 17.482731 },
    { lat: 48.167109, lng: 17.482202 },
    { lat: 48.166928, lng: 17.481942 },
    { lat: 48.166434, lng: 17.481456 },
    { lat: 48.165955, lng: 17.480508 },
    { lat: 48.165552, lng: 17.4796819 },
    { lat: 48.1652677, lng: 17.4801575 },
    { lat: 48.164965, lng: 17.480664 },
    { lat: 48.164353, lng: 17.481865 },
    { lat: 48.163987, lng: 17.482593 },
    { lat: 48.163897, lng: 17.482791 },
    { lat: 48.160872, lng: 17.480379 },
    { lat: 48.16257, lng: 17.475631 },
    { lat: 48.161589, lng: 17.474847 },
    { lat: 48.161063, lng: 17.474427 },
    { lat: 48.160987, lng: 17.474366 },
    { lat: 48.160865, lng: 17.474267 },
    { lat: 48.160755, lng: 17.47418 },
    { lat: 48.160617, lng: 17.474069 },
    { lat: 48.160483, lng: 17.473961 },
    { lat: 48.160342, lng: 17.473849 },
    { lat: 48.160218, lng: 17.47375 },
    { lat: 48.160074, lng: 17.473633 },
    { lat: 48.160012, lng: 17.473584 },
    { lat: 48.159949, lng: 17.473533 },
    { lat: 48.159832, lng: 17.47344 },
    { lat: 48.159764, lng: 17.473386 },
    { lat: 48.159708, lng: 17.473341 },
    { lat: 48.159581, lng: 17.473238 },
    { lat: 48.159457, lng: 17.473139 },
    { lat: 48.159207, lng: 17.472939 },
    { lat: 48.159159, lng: 17.472901 },
    { lat: 48.158954, lng: 17.472736 },
    { lat: 48.158825, lng: 17.472632 },
    { lat: 48.158708, lng: 17.472539 },
    { lat: 48.158585, lng: 17.47244 },
    { lat: 48.158466, lng: 17.472344 },
    { lat: 48.158242, lng: 17.472166 },
    { lat: 48.158183, lng: 17.472119 },
    { lat: 48.158128, lng: 17.472073 },
    { lat: 48.158004, lng: 17.471975 },
    { lat: 48.15797, lng: 17.471947 },
    { lat: 48.157825, lng: 17.471831 },
    { lat: 48.157685, lng: 17.471719 },
    { lat: 48.15741, lng: 17.471498 },
    { lat: 48.157269, lng: 17.471385 },
    { lat: 48.156726, lng: 17.47095 },
    { lat: 48.156458, lng: 17.470735 },
    { lat: 48.156328, lng: 17.470631 },
    { lat: 48.156197, lng: 17.470527 },
    { lat: 48.155932, lng: 17.470313 },
    { lat: 48.155668, lng: 17.470102 },
    { lat: 48.155541, lng: 17.47 },
    { lat: 48.155411, lng: 17.469896 },
    { lat: 48.155275, lng: 17.469788 },
    { lat: 48.155148, lng: 17.469685 },
    { lat: 48.155092, lng: 17.469642 },
    { lat: 48.154977, lng: 17.46955 },
    { lat: 48.154793, lng: 17.469401 },
    { lat: 48.154584, lng: 17.469234 },
    { lat: 48.154112, lng: 17.468854 },
    { lat: 48.154018, lng: 17.46878 },
    { lat: 48.152746, lng: 17.467769 },
    { lat: 48.152296, lng: 17.467401 },
    { lat: 48.151836, lng: 17.46761 },
    { lat: 48.151382, lng: 17.467722 },
    { lat: 48.150916, lng: 17.467605 },
    { lat: 48.150326, lng: 17.467045 },
    { lat: 48.14994, lng: 17.466866 },
    { lat: 48.149808, lng: 17.466834 },
    { lat: 48.14947, lng: 17.466796 },
    { lat: 48.149125, lng: 17.466855 },
    { lat: 48.14876, lng: 17.466975 },
    { lat: 48.147367, lng: 17.466995 },
    { lat: 48.146845, lng: 17.467145 },
    { lat: 48.144936, lng: 17.466684 },
    { lat: 48.144211, lng: 17.466654 },
    { lat: 48.144103, lng: 17.466642 },
    { lat: 48.143704, lng: 17.466737 },
    { lat: 48.14274, lng: 17.467546 },
    { lat: 48.141894, lng: 17.469059 },
    { lat: 48.141606, lng: 17.470099 },
    { lat: 48.141571, lng: 17.470262 },
    { lat: 48.141559, lng: 17.470472 },
    { lat: 48.141543, lng: 17.470589 },
    { lat: 48.141547, lng: 17.470745 },
    { lat: 48.141478, lng: 17.470973 },
    { lat: 48.141418, lng: 17.471237 },
    { lat: 48.14147, lng: 17.471627 },
    { lat: 48.141478, lng: 17.471701 },
    { lat: 48.141447, lng: 17.472065 },
    { lat: 48.141366, lng: 17.472451 },
    { lat: 48.141364, lng: 17.472565 },
    { lat: 48.141359, lng: 17.472701 },
    { lat: 48.141364, lng: 17.472927 },
    { lat: 48.141358, lng: 17.473151 },
    { lat: 48.141353, lng: 17.473457 },
    { lat: 48.141355, lng: 17.473662 },
    { lat: 48.141371, lng: 17.473857 },
    { lat: 48.141397, lng: 17.474248 },
    { lat: 48.141439, lng: 17.474528 },
    { lat: 48.141451, lng: 17.474632 },
    { lat: 48.141411, lng: 17.475074 },
    { lat: 48.141392, lng: 17.475191 },
    { lat: 48.141441, lng: 17.475621 },
    { lat: 48.141439, lng: 17.475873 },
    { lat: 48.141444, lng: 17.47614 },
    { lat: 48.141613, lng: 17.47629 },
    { lat: 48.141656, lng: 17.476933 },
    { lat: 48.141978, lng: 17.477752 },
    { lat: 48.141874, lng: 17.478375 },
    { lat: 48.142401, lng: 17.480834 },
    { lat: 48.142601, lng: 17.48169 },
    { lat: 48.142653, lng: 17.481969 },
    { lat: 48.142745, lng: 17.482421 },
    { lat: 48.143144, lng: 17.483351 },
    { lat: 48.143266, lng: 17.48358 },
    { lat: 48.1434, lng: 17.483845 },
    { lat: 48.14373, lng: 17.484538 },
    { lat: 48.143448, lng: 17.484839 },
    { lat: 48.143324, lng: 17.48497 },
    { lat: 48.143252, lng: 17.484962 },
    { lat: 48.142671, lng: 17.484921 },
    { lat: 48.142582, lng: 17.484945 },
    { lat: 48.142388, lng: 17.484888 },
    { lat: 48.142132, lng: 17.484782 },
    { lat: 48.141838, lng: 17.484728 },
    { lat: 48.141638, lng: 17.484825 },
    { lat: 48.141528, lng: 17.48493 },
    { lat: 48.141415, lng: 17.484935 },
    { lat: 48.141218, lng: 17.484846 },
    { lat: 48.141127, lng: 17.484852 },
    { lat: 48.14091, lng: 17.485 },
    { lat: 48.140702, lng: 17.485058 },
    { lat: 48.140437, lng: 17.485035 },
    { lat: 48.139524, lng: 17.484852 },
    { lat: 48.139295, lng: 17.484889 },
    { lat: 48.139059, lng: 17.484858 },
    { lat: 48.138753, lng: 17.4848 },
    { lat: 48.138588, lng: 17.484714 },
    { lat: 48.138304, lng: 17.484581 },
    { lat: 48.137796, lng: 17.484452 },
    { lat: 48.137338, lng: 17.484308 },
    { lat: 48.136928, lng: 17.484119 },
    { lat: 48.13618, lng: 17.483975 },
    { lat: 48.135868, lng: 17.483924 },
    { lat: 48.135852, lng: 17.483915 },
    { lat: 48.13583, lng: 17.483941 },
    { lat: 48.135815, lng: 17.483983 },
    { lat: 48.135784, lng: 17.484086 },
    { lat: 48.135777, lng: 17.484114 },
    { lat: 48.135763, lng: 17.484147 },
    { lat: 48.135485, lng: 17.484627 },
    { lat: 48.135354, lng: 17.484853 },
    { lat: 48.134958, lng: 17.485532 },
    { lat: 48.134742, lng: 17.485879 },
    { lat: 48.134109, lng: 17.486659 },
    { lat: 48.133284, lng: 17.487237 },
    { lat: 48.132915, lng: 17.487525 },
    { lat: 48.132558, lng: 17.48771 },
    { lat: 48.132422, lng: 17.487791 },
    { lat: 48.132389, lng: 17.487807 },
    { lat: 48.13226, lng: 17.48787 },
    { lat: 48.131694, lng: 17.48815 },
    { lat: 48.13136, lng: 17.488326 },
    { lat: 48.131345, lng: 17.488334 },
    { lat: 48.131294, lng: 17.488361 },
    { lat: 48.131279, lng: 17.488369 },
    { lat: 48.131145, lng: 17.488439 },
    { lat: 48.131051, lng: 17.488489 },
    { lat: 48.130956, lng: 17.488539 },
    { lat: 48.130908, lng: 17.488564 },
    { lat: 48.130543, lng: 17.488683 },
    { lat: 48.130462, lng: 17.488695 },
    { lat: 48.130258, lng: 17.488727 },
    { lat: 48.130206, lng: 17.488735 },
    { lat: 48.130155, lng: 17.488742 },
    { lat: 48.130053, lng: 17.488759 },
    { lat: 48.129977, lng: 17.488771 },
    { lat: 48.129967, lng: 17.488772 },
    { lat: 48.129896, lng: 17.488766 },
    { lat: 48.129787, lng: 17.488758 },
    { lat: 48.129676, lng: 17.488748 },
    { lat: 48.129566, lng: 17.488739 },
    { lat: 48.129455, lng: 17.48873 },
    { lat: 48.129364, lng: 17.488723 },
    { lat: 48.129344, lng: 17.488723 },
    { lat: 48.129289, lng: 17.488726 },
    { lat: 48.129208, lng: 17.488729 },
    { lat: 48.129043, lng: 17.488735 },
    { lat: 48.128931, lng: 17.488738 },
    { lat: 48.128847, lng: 17.488742 },
    { lat: 48.128762, lng: 17.488745 },
    { lat: 48.12865, lng: 17.488749 },
    { lat: 48.128536, lng: 17.488753 },
    { lat: 48.128393, lng: 17.488758 },
    { lat: 48.128305, lng: 17.488761 },
    { lat: 48.128187, lng: 17.488765 },
    { lat: 48.1281272, lng: 17.4887679 },
    { lat: 48.128126, lng: 17.488768 },
    { lat: 48.128085, lng: 17.488769 },
    { lat: 48.127886, lng: 17.488825 },
    { lat: 48.12788, lng: 17.488827 },
    { lat: 48.127671, lng: 17.488905 },
    { lat: 48.127648, lng: 17.488914 },
    { lat: 48.127602, lng: 17.48893 },
    { lat: 48.127572, lng: 17.488941 },
    { lat: 48.127461, lng: 17.488983 },
    { lat: 48.127427, lng: 17.488996 },
    { lat: 48.12742, lng: 17.488999 },
    { lat: 48.127348, lng: 17.489053 },
    { lat: 48.127314, lng: 17.489079 },
    { lat: 48.126725, lng: 17.489522 },
    { lat: 48.126532, lng: 17.489768 },
    { lat: 48.126451, lng: 17.489871 },
    { lat: 48.126434, lng: 17.489893 },
    { lat: 48.126106, lng: 17.49031 },
    { lat: 48.12597, lng: 17.490484 },
    { lat: 48.125822, lng: 17.490673 },
    { lat: 48.125756, lng: 17.490756 },
    { lat: 48.125728, lng: 17.490799 },
    { lat: 48.125663, lng: 17.4909 },
    { lat: 48.125582, lng: 17.491027 },
    { lat: 48.125532, lng: 17.491106 },
    { lat: 48.125463, lng: 17.491234 },
    { lat: 48.125445, lng: 17.491266 },
    { lat: 48.125237, lng: 17.491643 },
    { lat: 48.124993, lng: 17.492413 },
    { lat: 48.124841, lng: 17.492919 },
    { lat: 48.124878, lng: 17.493092 },
    { lat: 48.124884, lng: 17.493121 },
    { lat: 48.124955, lng: 17.493472 },
    { lat: 48.125017, lng: 17.493713 },
    { lat: 48.125098, lng: 17.493793 },
    { lat: 48.125195, lng: 17.493785 },
    { lat: 48.12527, lng: 17.493804 },
    { lat: 48.125455, lng: 17.493977 },
    { lat: 48.125409, lng: 17.494097 },
    { lat: 48.125224, lng: 17.494312 },
    { lat: 48.125173, lng: 17.494457 },
    { lat: 48.125177, lng: 17.494668 },
    { lat: 48.124999, lng: 17.495095 },
    { lat: 48.124995, lng: 17.495219 },
    { lat: 48.124944, lng: 17.495442 },
    { lat: 48.124941, lng: 17.495607 },
    { lat: 48.124962, lng: 17.495715 },
    { lat: 48.124981, lng: 17.495993 },
    { lat: 48.12507, lng: 17.496638 },
    { lat: 48.125074, lng: 17.496671 },
    { lat: 48.125076, lng: 17.496804 },
    { lat: 48.125093, lng: 17.49693 },
    { lat: 48.125238, lng: 17.497379 },
    { lat: 48.12537, lng: 17.497684 },
    { lat: 48.125385, lng: 17.49772 },
    { lat: 48.123433, lng: 17.500116 },
    { lat: 48.122728, lng: 17.500944 },
    { lat: 48.122611, lng: 17.500999 },
    { lat: 48.122355, lng: 17.501321 },
    { lat: 48.122319, lng: 17.501365 },
    { lat: 48.122315, lng: 17.501373 },
    { lat: 48.122266, lng: 17.501453 },
    { lat: 48.122234, lng: 17.501505 },
    { lat: 48.122217, lng: 17.501533 },
    { lat: 48.122187, lng: 17.501582 },
    { lat: 48.12216, lng: 17.501625 },
    { lat: 48.121829, lng: 17.502166 },
    { lat: 48.121549, lng: 17.502667 },
    { lat: 48.12153, lng: 17.502884 },
    { lat: 48.121519, lng: 17.503141 },
    { lat: 48.121518, lng: 17.50318 },
    { lat: 48.121514, lng: 17.5033 },
    { lat: 48.121508, lng: 17.503398 },
    { lat: 48.121508, lng: 17.503409 },
    { lat: 48.121499, lng: 17.503589 },
    { lat: 48.12147, lng: 17.503954 },
    { lat: 48.121468, lng: 17.503979 },
    { lat: 48.121414, lng: 17.504336 },
    { lat: 48.12138, lng: 17.504561 },
    { lat: 48.121354, lng: 17.504736 },
    { lat: 48.121309, lng: 17.505024 },
    { lat: 48.121294, lng: 17.505125 },
    { lat: 48.121294, lng: 17.505157 },
    { lat: 48.121271, lng: 17.506364 },
    { lat: 48.121271, lng: 17.506377 },
    { lat: 48.12127, lng: 17.506442 },
    { lat: 48.121269, lng: 17.506456 },
    { lat: 48.121266, lng: 17.506567 },
    { lat: 48.121299, lng: 17.506677 },
    { lat: 48.12133, lng: 17.506785 },
    { lat: 48.121617, lng: 17.507726 },
    { lat: 48.121642, lng: 17.507809 },
    { lat: 48.121643, lng: 17.507813 },
    { lat: 48.121918, lng: 17.508689 },
    { lat: 48.121906, lng: 17.509049 },
    { lat: 48.121747, lng: 17.509791 },
    { lat: 48.121615, lng: 17.510499 },
    { lat: 48.121618, lng: 17.510534 },
    { lat: 48.121609, lng: 17.51057 },
    { lat: 48.1219112, lng: 17.5120418 },
    { lat: 48.122004, lng: 17.512494 },
    { lat: 48.122008, lng: 17.512514 },
    { lat: 48.122015, lng: 17.512559 },
    { lat: 48.122029, lng: 17.512629 },
    { lat: 48.122238, lng: 17.513606 },
    { lat: 48.12164, lng: 17.513376 },
    { lat: 48.121113, lng: 17.513214 },
    { lat: 48.120309, lng: 17.513195 },
    { lat: 48.120096, lng: 17.513203 },
    { lat: 48.119853, lng: 17.513409 },
    { lat: 48.119386, lng: 17.513681 },
    { lat: 48.118688, lng: 17.514056 },
    { lat: 48.118606, lng: 17.514094 },
    { lat: 48.118399, lng: 17.514167 },
    { lat: 48.117943, lng: 17.514702 },
    { lat: 48.117632, lng: 17.515222 },
    { lat: 48.117234, lng: 17.51611 },
    { lat: 48.116708, lng: 17.51742 },
    { lat: 48.116366, lng: 17.518171 },
    { lat: 48.116226, lng: 17.518466 },
    { lat: 48.115897, lng: 17.519198 },
    { lat: 48.114906, lng: 17.520762 },
    { lat: 48.114544, lng: 17.521516 },
    { lat: 48.114249, lng: 17.522017 },
    { lat: 48.113993, lng: 17.52294 },
    { lat: 48.113807, lng: 17.524128 },
    { lat: 48.113771, lng: 17.524492 },
    { lat: 48.113681, lng: 17.525377 },
    { lat: 48.113696, lng: 17.525771 },
    { lat: 48.113767, lng: 17.526536 },
    { lat: 48.113941, lng: 17.527399 },
    { lat: 48.114311, lng: 17.528603 },
    { lat: 48.114804, lng: 17.529517 },
    { lat: 48.115335, lng: 17.530249 },
    { lat: 48.115603, lng: 17.530504 },
    { lat: 48.115943, lng: 17.530757 },
    { lat: 48.116385, lng: 17.531095 },
    { lat: 48.116637, lng: 17.531246 },
    { lat: 48.116993, lng: 17.53139 },
    { lat: 48.117204, lng: 17.53145 },
    { lat: 48.117459, lng: 17.531481 },
    { lat: 48.118099, lng: 17.531484 },
    { lat: 48.118858, lng: 17.531373 },
    { lat: 48.119768, lng: 17.53101 },
    { lat: 48.120198, lng: 17.530862 },
    { lat: 48.120341, lng: 17.530716 },
    { lat: 48.120659, lng: 17.530552 },
    { lat: 48.120878, lng: 17.531 },
    { lat: 48.120902, lng: 17.53105 },
    { lat: 48.121002, lng: 17.531253 },
    { lat: 48.121003, lng: 17.531257 },
    { lat: 48.121155, lng: 17.531568 },
    { lat: 48.121471, lng: 17.532055 },
    { lat: 48.121683, lng: 17.532504 },
    { lat: 48.121809, lng: 17.53278 },
    { lat: 48.122011, lng: 17.533187 },
    { lat: 48.122125, lng: 17.533478 },
    { lat: 48.122216, lng: 17.533804 },
    { lat: 48.122331, lng: 17.534232 },
    { lat: 48.122338, lng: 17.534832 },
    { lat: 48.122341, lng: 17.535678 },
    { lat: 48.122391, lng: 17.536524 },
    { lat: 48.122266, lng: 17.538116 },
    { lat: 48.121931, lng: 17.539185 },
    { lat: 48.121587, lng: 17.540142 },
    { lat: 48.121374, lng: 17.540829 },
    { lat: 48.121185, lng: 17.541194 },
    { lat: 48.121263, lng: 17.541523 },
    { lat: 48.121499, lng: 17.542251 },
    { lat: 48.121727, lng: 17.542918 },
    { lat: 48.121798, lng: 17.543135 },
    { lat: 48.121822, lng: 17.543204 },
    { lat: 48.121845, lng: 17.543276 },
    { lat: 48.122076, lng: 17.543888 },
    { lat: 48.120229, lng: 17.545414 },
    { lat: 48.11952, lng: 17.545952 },
    { lat: 48.119356, lng: 17.546076 },
    { lat: 48.119248, lng: 17.546148 },
    { lat: 48.118936, lng: 17.546356 },
    { lat: 48.118361, lng: 17.546789 },
    { lat: 48.117877, lng: 17.547063 },
    { lat: 48.117533, lng: 17.547228 },
    { lat: 48.117022, lng: 17.547255 },
    { lat: 48.116098, lng: 17.547207 },
    { lat: 48.115232, lng: 17.546915 },
    { lat: 48.115082, lng: 17.546848 },
    { lat: 48.11451, lng: 17.546474 },
    { lat: 48.114335, lng: 17.546321 },
    { lat: 48.113723, lng: 17.545588 },
    { lat: 48.113081, lng: 17.544617 },
    { lat: 48.112774, lng: 17.543893 },
    { lat: 48.112365, lng: 17.542602 },
    { lat: 48.112163, lng: 17.542126 },
    { lat: 48.112045, lng: 17.541716 },
    { lat: 48.111869, lng: 17.541254 },
    { lat: 48.111798, lng: 17.540959 },
    { lat: 48.111723, lng: 17.540672 },
    { lat: 48.111596, lng: 17.540269 },
    { lat: 48.111229, lng: 17.539358 },
    { lat: 48.110925, lng: 17.538753 },
    { lat: 48.110793, lng: 17.538576 },
    { lat: 48.110438, lng: 17.538133 },
    { lat: 48.110206, lng: 17.537881 },
    { lat: 48.10987, lng: 17.537612 },
    { lat: 48.10954, lng: 17.537423 },
    { lat: 48.109129, lng: 17.537223 },
    { lat: 48.108919, lng: 17.537138 },
    { lat: 48.108559, lng: 17.537039 },
    { lat: 48.1082, lng: 17.537005 },
    { lat: 48.107883, lng: 17.537053 },
    { lat: 48.107462, lng: 17.537178 },
    { lat: 48.107194, lng: 17.537331 },
    { lat: 48.106939, lng: 17.537647 },
    { lat: 48.106696, lng: 17.538052 },
    { lat: 48.106818, lng: 17.538237 },
    { lat: 48.106361, lng: 17.539228 },
    { lat: 48.106154, lng: 17.539969 },
    { lat: 48.105875, lng: 17.540961 },
    { lat: 48.105759, lng: 17.541304 },
    { lat: 48.105649, lng: 17.541828 },
    { lat: 48.105561, lng: 17.542743 },
    { lat: 48.10556, lng: 17.543291 },
    { lat: 48.105725, lng: 17.543732 },
    { lat: 48.106871, lng: 17.5436978 },
    { lat: 48.1077477, lng: 17.543878 },
    { lat: 48.1083291, lng: 17.5441391 },
    { lat: 48.1088857, lng: 17.5444911 },
    { lat: 48.1096479, lng: 17.544224 },
    { lat: 48.1099138, lng: 17.5442854 },
    { lat: 48.1100229, lng: 17.5445447 },
    { lat: 48.1100966, lng: 17.5452897 },
    { lat: 48.1103022, lng: 17.545854 },
    { lat: 48.1105371, lng: 17.5469664 },
    { lat: 48.1107629, lng: 17.5475285 },
    { lat: 48.111244, lng: 17.5480955 },
    { lat: 48.1115002, lng: 17.5485707 },
    { lat: 48.1116969, lng: 17.5490299 },
    { lat: 48.1117654, lng: 17.5496982 },
    { lat: 48.1120304, lng: 17.5503965 },
    { lat: 48.1122669, lng: 17.5507048 },
    { lat: 48.1128878, lng: 17.5517251 },
    { lat: 48.1131059, lng: 17.5521689 },
    { lat: 48.1138876, lng: 17.5531434 },
    { lat: 48.1142897, lng: 17.5537985 },
    { lat: 48.1146039, lng: 17.554564 },
    { lat: 48.1151172, lng: 17.5562496 },
    { lat: 48.1152792, lng: 17.5571068 },
    { lat: 48.115167, lng: 17.5576475 },
    { lat: 48.1149757, lng: 17.558049 },
    { lat: 48.1139583, lng: 17.5591636 },
    { lat: 48.1140178, lng: 17.5594064 },
    { lat: 48.114662, lng: 17.559485 },
    { lat: 48.1150425, lng: 17.5594467 },
    { lat: 48.1152931, lng: 17.5593064 },
    { lat: 48.1158387, lng: 17.5598015 },
    { lat: 48.1166709, lng: 17.5603491 },
    { lat: 48.1173217, lng: 17.5609907 },
    { lat: 48.1176127, lng: 17.5611981 },
    { lat: 48.1176368, lng: 17.5610081 },
    { lat: 48.1175724, lng: 17.5607441 },
    { lat: 48.117569, lng: 17.5604427 },
    { lat: 48.1176275, lng: 17.5601916 },
    { lat: 48.1177599, lng: 17.5600007 },
    { lat: 48.1179903, lng: 17.5599148 },
    { lat: 48.1183812, lng: 17.5600588 },
    { lat: 48.1193697, lng: 17.5607192 },
    { lat: 48.1196969, lng: 17.5606775 },
    { lat: 48.1200694, lng: 17.5604619 },
    { lat: 48.1205107, lng: 17.5603729 },
    { lat: 48.1208005, lng: 17.5604016 },
    { lat: 48.1210593, lng: 17.5605114 },
    { lat: 48.1213942, lng: 17.5608906 },
    { lat: 48.1214993, lng: 17.5611573 },
    { lat: 48.1214734, lng: 17.5616526 },
    { lat: 48.121018, lng: 17.5627816 },
    { lat: 48.1205498, lng: 17.5636728 },
    { lat: 48.1204738, lng: 17.5640911 },
    { lat: 48.1205044, lng: 17.5645515 },
    { lat: 48.1206364, lng: 17.5650809 },
    { lat: 48.1208943, lng: 17.565604 },
    { lat: 48.1210009, lng: 17.5656613 },
    { lat: 48.1217507, lng: 17.5654333 },
    { lat: 48.1221385, lng: 17.5654732 },
    { lat: 48.1224598, lng: 17.5657841 },
    { lat: 48.1226142, lng: 17.5660978 },
    { lat: 48.1229866, lng: 17.5666411 },
    { lat: 48.1231795, lng: 17.5670985 },
    { lat: 48.1235758, lng: 17.5675429 },
    { lat: 48.1237294, lng: 17.5675953 },
    { lat: 48.1241176, lng: 17.5673959 },
    { lat: 48.1244027, lng: 17.5670503 },
    { lat: 48.1245799, lng: 17.5669247 },
    { lat: 48.1250579, lng: 17.5668238 },
    { lat: 48.1253202, lng: 17.5667037 },
    { lat: 48.125586, lng: 17.5666935 },
    { lat: 48.125856, lng: 17.5669268 },
    { lat: 48.1261271, lng: 17.5674407 },
    { lat: 48.126664, lng: 17.5693765 },
    { lat: 48.1268941, lng: 17.5696753 },
    { lat: 48.1272937, lng: 17.569938 },
    { lat: 48.1274549, lng: 17.5699681 },
    { lat: 48.1277923, lng: 17.5698745 },
    { lat: 48.1281723, lng: 17.5687872 },
    { lat: 48.1283885, lng: 17.5688865 },
    { lat: 48.1286053, lng: 17.5690801 },
    { lat: 48.1292796, lng: 17.569964 },
    { lat: 48.1294498, lng: 17.569961 },
    { lat: 48.1298439, lng: 17.5697296 },
    { lat: 48.1302466, lng: 17.5692792 },
    { lat: 48.1303916, lng: 17.5690409 },
    { lat: 48.1309948, lng: 17.566849 },
    { lat: 48.1311048, lng: 17.5665703 },
    { lat: 48.1312863, lng: 17.5662988 },
    { lat: 48.1314646, lng: 17.5661377 },
    { lat: 48.131962, lng: 17.5658484 },
    { lat: 48.1332352, lng: 17.5657127 },
    { lat: 48.1340195, lng: 17.5658659 },
    { lat: 48.1350216, lng: 17.5662623 },
    { lat: 48.1357927, lng: 17.5667546 },
    { lat: 48.1367574, lng: 17.566917 },
    { lat: 48.136928, lng: 17.5670242 },
    { lat: 48.1372383, lng: 17.567358 },
    { lat: 48.1374967, lng: 17.5677808 },
    { lat: 48.1376015, lng: 17.5683259 },
    { lat: 48.1375447, lng: 17.5686758 },
    { lat: 48.1373133, lng: 17.5691026 },
    { lat: 48.1366522, lng: 17.5696227 },
    { lat: 48.1355316, lng: 17.5700961 },
    { lat: 48.1353599, lng: 17.5702021 },
    { lat: 48.135314, lng: 17.5702988 },
    { lat: 48.1354222, lng: 17.5711085 },
    { lat: 48.1355545, lng: 17.5714651 },
    { lat: 48.1362227, lng: 17.572081 },
    { lat: 48.1365259, lng: 17.5721256 },
    { lat: 48.1371175, lng: 17.5720704 },
    { lat: 48.1374305, lng: 17.5718622 },
    { lat: 48.1376737, lng: 17.5715119 },
    { lat: 48.1381326, lng: 17.5703499 },
    { lat: 48.1386632, lng: 17.5693018 },
    { lat: 48.1388736, lng: 17.5690601 },
    { lat: 48.1390185, lng: 17.5690278 },
    { lat: 48.1390449, lng: 17.5687897 },
    { lat: 48.1388297, lng: 17.5683713 },
    { lat: 48.1385788, lng: 17.5677144 },
    { lat: 48.1385169, lng: 17.5666874 },
    { lat: 48.1382912, lng: 17.5660549 },
    { lat: 48.1382678, lng: 17.5657064 },
    { lat: 48.1386162, lng: 17.5644573 },
    { lat: 48.1386747, lng: 17.563267 },
    { lat: 48.1388252, lng: 17.5621927 },
    { lat: 48.1388549, lng: 17.5610296 },
    { lat: 48.138591, lng: 17.5595166 },
    { lat: 48.1385105, lng: 17.558326 },
    { lat: 48.1383748, lng: 17.5574208 },
    { lat: 48.1383956, lng: 17.5568982 },
    { lat: 48.1383243, lng: 17.5565601 },
    { lat: 48.1378524, lng: 17.5559592 },
    { lat: 48.1377523, lng: 17.5554173 },
    { lat: 48.1377849, lng: 17.5550096 },
    { lat: 48.1379675, lng: 17.5540853 },
    { lat: 48.138177, lng: 17.553495 },
    { lat: 48.138554, lng: 17.5520773 },
    { lat: 48.1387744, lng: 17.5515151 },
    { lat: 48.1397798, lng: 17.5499299 },
    { lat: 48.1401009, lng: 17.5496175 },
    { lat: 48.1402935, lng: 17.5495218 },
    { lat: 48.1407595, lng: 17.5494966 },
    { lat: 48.1410734, lng: 17.5495505 },
    { lat: 48.1416509, lng: 17.5494515 },
    { lat: 48.1429099, lng: 17.5499973 },
    { lat: 48.1432435, lng: 17.5501993 },
    { lat: 48.143573, lng: 17.5503008 },
    { lat: 48.1441584, lng: 17.550218 },
    { lat: 48.1447819, lng: 17.5500261 },
    { lat: 48.1462592, lng: 17.5500278 },
    { lat: 48.1462823, lng: 17.5500188 },
    { lat: 48.1472248, lng: 17.5495332 },
    { lat: 48.1475714, lng: 17.5488627 },
    { lat: 48.1478753, lng: 17.5484414 },
    { lat: 48.1480242, lng: 17.5484014 },
    { lat: 48.148328, lng: 17.548597 },
    { lat: 48.1485977, lng: 17.5485592 },
    { lat: 48.1488469, lng: 17.5483221 },
    { lat: 48.1490488, lng: 17.5480005 },
    { lat: 48.149138, lng: 17.5477186 },
    { lat: 48.1493543, lng: 17.5474826 },
    { lat: 48.1496474, lng: 17.5469447 },
    { lat: 48.1500064, lng: 17.545724 },
    { lat: 48.1514345, lng: 17.5448231 },
    { lat: 48.1514952, lng: 17.5447924 },
    { lat: 48.1522812, lng: 17.5444573 },
    { lat: 48.1532084, lng: 17.5445137 },
    { lat: 48.1541795, lng: 17.5430099 },
    { lat: 48.1559747, lng: 17.5420747 },
    { lat: 48.1588604, lng: 17.5406624 },
    { lat: 48.1609096, lng: 17.5399464 },
    { lat: 48.1618312, lng: 17.539733 },
    { lat: 48.1626699, lng: 17.5395387 },
    { lat: 48.1637044, lng: 17.5386204 },
    { lat: 48.1651824, lng: 17.537698 },
    { lat: 48.1662094, lng: 17.5376534 },
    { lat: 48.1669671, lng: 17.5368421 },
    { lat: 48.1670036, lng: 17.5376056 },
    { lat: 48.167782, lng: 17.5379654 },
    { lat: 48.1687984, lng: 17.5388969 },
    { lat: 48.170038, lng: 17.5396709 },
    { lat: 48.1714977, lng: 17.5402964 },
    { lat: 48.1726225, lng: 17.5400673 },
    { lat: 48.1730089, lng: 17.5412553 },
    { lat: 48.1739394, lng: 17.542294 },
    { lat: 48.1741114, lng: 17.5420348 },
    { lat: 48.174172, lng: 17.5420287 },
    { lat: 48.1740757, lng: 17.5417428 },
    { lat: 48.1741719, lng: 17.5404391 },
    { lat: 48.1742518, lng: 17.5401347 },
    { lat: 48.1744902, lng: 17.5397107 },
    { lat: 48.1748242, lng: 17.5394609 },
    { lat: 48.1751998, lng: 17.5394875 },
    { lat: 48.1756498, lng: 17.539305 },
    { lat: 48.1759434, lng: 17.5387556 },
    { lat: 48.1762073, lng: 17.5377615 },
    { lat: 48.1762009, lng: 17.5370255 },
    { lat: 48.1760652, lng: 17.535752 },
    { lat: 48.1758339, lng: 17.535034 },
    { lat: 48.1755944, lng: 17.5348047 },
    { lat: 48.1751979, lng: 17.5345727 },
    { lat: 48.1749303, lng: 17.534237 },
    { lat: 48.1739727, lng: 17.5333222 },
    { lat: 48.1735346, lng: 17.5324421 },
    { lat: 48.1734535, lng: 17.532251 },
    { lat: 48.1733985, lng: 17.5319018 },
    { lat: 48.1734195, lng: 17.531417 },
    { lat: 48.1734622, lng: 17.5312934 },
    { lat: 48.1737248, lng: 17.5311259 },
    { lat: 48.1739231, lng: 17.5308198 },
    { lat: 48.173984, lng: 17.5306243 },
    { lat: 48.1740199, lng: 17.5301429 },
    { lat: 48.1740295, lng: 17.5296066 },
    { lat: 48.1738132, lng: 17.5286455 },
    { lat: 48.1737482, lng: 17.5284404 },
    { lat: 48.1733855, lng: 17.5278577 },
    { lat: 48.1731559, lng: 17.5276203 },
    { lat: 48.1724655, lng: 17.5265417 },
    { lat: 48.1722066, lng: 17.525337 },
    { lat: 48.1721083, lng: 17.5252142 },
    { lat: 48.1713789, lng: 17.5248485 },
    { lat: 48.1711551, lng: 17.5244391 },
    { lat: 48.1711328, lng: 17.5242137 },
    { lat: 48.1712265, lng: 17.5232472 },
    { lat: 48.1714278, lng: 17.5227397 },
    { lat: 48.1717662, lng: 17.5213599 },
    { lat: 48.1719765, lng: 17.5210106 },
    { lat: 48.1722107, lng: 17.5209296 },
    { lat: 48.172752, lng: 17.5212879 },
    { lat: 48.173793, lng: 17.5214754 },
    { lat: 48.1743364, lng: 17.520813 },
    { lat: 48.1744144, lng: 17.5206196 },
    { lat: 48.1744741, lng: 17.5201593 },
    { lat: 48.1744341, lng: 17.5198342 },
    { lat: 48.1744659, lng: 17.5190976 },
    { lat: 48.1742789, lng: 17.5184002 },
    { lat: 48.1740267, lng: 17.518044 },
    { lat: 48.173944, lng: 17.5178092 },
    { lat: 48.1737527, lng: 17.5176467 },
    { lat: 48.1736237, lng: 17.5176602 },
    { lat: 48.1733173, lng: 17.5179345 },
    { lat: 48.1731256, lng: 17.5183286 },
    { lat: 48.1730209, lng: 17.5184752 },
    { lat: 48.1728843, lng: 17.5185436 },
    { lat: 48.1727413, lng: 17.5185871 },
    { lat: 48.1726089, lng: 17.5185452 },
    { lat: 48.1720915, lng: 17.5181744 },
    { lat: 48.1720218, lng: 17.5181982 },
    { lat: 48.1718177, lng: 17.5184656 },
    { lat: 48.1715441, lng: 17.5184952 },
    { lat: 48.1709555, lng: 17.5176648 },
    { lat: 48.170677, lng: 17.5164233 },
    { lat: 48.1708243, lng: 17.5160777 },
    { lat: 48.1713259, lng: 17.5155384 },
    { lat: 48.1714747, lng: 17.5155489 },
    { lat: 48.1718735, lng: 17.5160794 },
    { lat: 48.1720799, lng: 17.5161796 },
    { lat: 48.1724136, lng: 17.5162619 },
    { lat: 48.1728792, lng: 17.5161604 },
    { lat: 48.1729882, lng: 17.516062 },
    { lat: 48.1731296, lng: 17.5158393 },
    { lat: 48.1729586, lng: 17.5150229 },
    { lat: 48.1730443, lng: 17.5143529 },
    { lat: 48.1731646, lng: 17.5140437 },
    { lat: 48.1733417, lng: 17.5137767 },
    { lat: 48.173673, lng: 17.5137139 },
    { lat: 48.174297, lng: 17.514195 },
    { lat: 48.1747996, lng: 17.5143384 },
    { lat: 48.174951, lng: 17.514293 },
  ],
  Galanta: [
    { lat: 48.189487, lng: 17.7519641 },
    { lat: 48.1894918, lng: 17.7519507 },
    { lat: 48.1902762, lng: 17.7494029 },
    { lat: 48.1905764, lng: 17.748651 },
    { lat: 48.1910835, lng: 17.7476015 },
    { lat: 48.1917567, lng: 17.7491693 },
    { lat: 48.1915372, lng: 17.7497266 },
    { lat: 48.1933753, lng: 17.7514192 },
    { lat: 48.1936533, lng: 17.7520215 },
    { lat: 48.1938388, lng: 17.7527193 },
    { lat: 48.1941939, lng: 17.754458 },
    { lat: 48.1955247, lng: 17.7565447 },
    { lat: 48.1958746, lng: 17.7572003 },
    { lat: 48.1964793, lng: 17.7594849 },
    { lat: 48.1966065, lng: 17.7609195 },
    { lat: 48.1974373, lng: 17.7638691 },
    { lat: 48.1975089, lng: 17.7665376 },
    { lat: 48.1976193, lng: 17.7681172 },
    { lat: 48.1978877, lng: 17.7699718 },
    { lat: 48.1979923, lng: 17.7724457 },
    { lat: 48.1976129, lng: 17.7745075 },
    { lat: 48.1975286, lng: 17.7773794 },
    { lat: 48.1976374, lng: 17.7789592 },
    { lat: 48.1973977, lng: 17.7796088 },
    { lat: 48.1961407, lng: 17.7819394 },
    { lat: 48.1967188, lng: 17.783128 },
    { lat: 48.1975008, lng: 17.7854084 },
    { lat: 48.1978691, lng: 17.7865996 },
    { lat: 48.1983591, lng: 17.7886108 },
    { lat: 48.1990447, lng: 17.7911411 },
    { lat: 48.1997655, lng: 17.7901442 },
    { lat: 48.2023191, lng: 17.7861354 },
    { lat: 48.2032216, lng: 17.7848623 },
    { lat: 48.2052294, lng: 17.7827671 },
    { lat: 48.207724, lng: 17.7807328 },
    { lat: 48.2082577, lng: 17.7782455 },
    { lat: 48.2085261, lng: 17.7775076 },
    { lat: 48.2091892, lng: 17.7761831 },
    { lat: 48.2111535, lng: 17.7744812 },
    { lat: 48.2115958, lng: 17.7739672 },
    { lat: 48.2124203, lng: 17.7731525 },
    { lat: 48.2153473, lng: 17.7703503 },
    { lat: 48.2154906, lng: 17.7702225 },
    { lat: 48.2182416, lng: 17.7677544 },
    { lat: 48.2193858, lng: 17.7663384 },
    { lat: 48.2207019, lng: 17.7644017 },
    { lat: 48.2237721, lng: 17.7611824 },
    { lat: 48.2240354, lng: 17.7608771 },
    { lat: 48.2274068, lng: 17.7587476 },
    { lat: 48.2362523, lng: 17.7547172 },
    { lat: 48.2359498, lng: 17.7540435 },
    { lat: 48.2350003, lng: 17.7519667 },
    { lat: 48.2343337, lng: 17.7506339 },
    { lat: 48.2340058, lng: 17.7500772 },
    { lat: 48.2339659, lng: 17.7502194 },
    { lat: 48.2331063, lng: 17.7486472 },
    { lat: 48.2291558, lng: 17.739355 },
    { lat: 48.2273598, lng: 17.7353814 },
    { lat: 48.2252554, lng: 17.7287038 },
    { lat: 48.2249174, lng: 17.7281926 },
    { lat: 48.2249424, lng: 17.728085 },
    { lat: 48.2245791, lng: 17.7275266 },
    { lat: 48.2244453, lng: 17.7270804 },
    { lat: 48.22437, lng: 17.7270866 },
    { lat: 48.2244397, lng: 17.7268457 },
    { lat: 48.2245376, lng: 17.7268413 },
    { lat: 48.2247361, lng: 17.7266936 },
    { lat: 48.2249682, lng: 17.7264502 },
    { lat: 48.2252833, lng: 17.7259438 },
    { lat: 48.2252983, lng: 17.7252247 },
    { lat: 48.2250934, lng: 17.7248889 },
    { lat: 48.2246334, lng: 17.724371 },
    { lat: 48.2244703, lng: 17.7240205 },
    { lat: 48.2244382, lng: 17.7237223 },
    { lat: 48.2243955, lng: 17.7230169 },
    { lat: 48.2244417, lng: 17.7227647 },
    { lat: 48.2245697, lng: 17.7224073 },
    { lat: 48.2248269, lng: 17.7219133 },
    { lat: 48.2250243, lng: 17.7217289 },
    { lat: 48.2245958, lng: 17.7207264 },
    { lat: 48.2245315, lng: 17.7203817 },
    { lat: 48.2239288, lng: 17.7189178 },
    { lat: 48.2234065, lng: 17.7178715 },
    { lat: 48.2227202, lng: 17.7162296 },
    { lat: 48.2215578, lng: 17.7127978 },
    { lat: 48.2199996, lng: 17.7087978 },
    { lat: 48.2193134, lng: 17.7072853 },
    { lat: 48.2199795, lng: 17.7065779 },
    { lat: 48.2203302, lng: 17.7063352 },
    { lat: 48.2205071, lng: 17.7060392 },
    { lat: 48.220564, lng: 17.7056131 },
    { lat: 48.220514, lng: 17.7053726 },
    { lat: 48.2204744, lng: 17.7052801 },
    { lat: 48.2202951, lng: 17.7051405 },
    { lat: 48.2193572, lng: 17.7048561 },
    { lat: 48.2189677, lng: 17.7045685 },
    { lat: 48.2188855, lng: 17.7044101 },
    { lat: 48.2189021, lng: 17.7038098 },
    { lat: 48.2192137, lng: 17.703484 },
    { lat: 48.2194355, lng: 17.7034176 },
    { lat: 48.2203162, lng: 17.703453 },
    { lat: 48.2201916, lng: 17.7033016 },
    { lat: 48.219494, lng: 17.7024563 },
    { lat: 48.2182327, lng: 17.7007744 },
    { lat: 48.2175158, lng: 17.6989502 },
    { lat: 48.2161665, lng: 17.6947059 },
    { lat: 48.215172, lng: 17.6911386 },
    { lat: 48.2144351, lng: 17.6891082 },
    { lat: 48.2142993, lng: 17.6885605 },
    { lat: 48.2139435, lng: 17.6869698 },
    { lat: 48.2136152, lng: 17.6856767 },
    { lat: 48.2133332, lng: 17.684566 },
    { lat: 48.2126055, lng: 17.6832035 },
    { lat: 48.2121355, lng: 17.6832889 },
    { lat: 48.211497, lng: 17.683136 },
    { lat: 48.2109477, lng: 17.6826951 },
    { lat: 48.2108689, lng: 17.6825179 },
    { lat: 48.2093497, lng: 17.6825299 },
    { lat: 48.2091387, lng: 17.6824211 },
    { lat: 48.2090945, lng: 17.6822414 },
    { lat: 48.2084363, lng: 17.6822223 },
    { lat: 48.2071286, lng: 17.6818949 },
    { lat: 48.2067462, lng: 17.6817769 },
    { lat: 48.2064618, lng: 17.6816143 },
    { lat: 48.2052856, lng: 17.6816027 },
    { lat: 48.2049561, lng: 17.6814606 },
    { lat: 48.2047515, lng: 17.6810621 },
    { lat: 48.2043991, lng: 17.6810599 },
    { lat: 48.2039146, lng: 17.6806075 },
    { lat: 48.2033151, lng: 17.6803936 },
    { lat: 48.2029438, lng: 17.6804489 },
    { lat: 48.2026792, lng: 17.6804262 },
    { lat: 48.2025215, lng: 17.6803404 },
    { lat: 48.2019365, lng: 17.6796742 },
    { lat: 48.2018051, lng: 17.6791791 },
    { lat: 48.2015604, lng: 17.6791324 },
    { lat: 48.2011681, lng: 17.6793223 },
    { lat: 48.2005406, lng: 17.6789477 },
    { lat: 48.2000791, lng: 17.6792397 },
    { lat: 48.2000929, lng: 17.6799842 },
    { lat: 48.1999288, lng: 17.6810587 },
    { lat: 48.1995396, lng: 17.6816775 },
    { lat: 48.1993026, lng: 17.6814251 },
    { lat: 48.1991273, lng: 17.6816567 },
    { lat: 48.1990526, lng: 17.6827328 },
    { lat: 48.1989477, lng: 17.6832221 },
    { lat: 48.1989442, lng: 17.6834919 },
    { lat: 48.1990613, lng: 17.6843334 },
    { lat: 48.1994127, lng: 17.6849683 },
    { lat: 48.1993325, lng: 17.6856863 },
    { lat: 48.199393, lng: 17.6858576 },
    { lat: 48.1997134, lng: 17.686213 },
    { lat: 48.200094, lng: 17.6867744 },
    { lat: 48.2001746, lng: 17.6869818 },
    { lat: 48.2003751, lng: 17.6883421 },
    { lat: 48.200299, lng: 17.6885708 },
    { lat: 48.1995946, lng: 17.6897978 },
    { lat: 48.1992315, lng: 17.6909075 },
    { lat: 48.199242, lng: 17.6923702 },
    { lat: 48.1989865, lng: 17.6933764 },
    { lat: 48.1987959, lng: 17.6932198 },
    { lat: 48.1986118, lng: 17.6943915 },
    { lat: 48.1982632, lng: 17.6949527 },
    { lat: 48.198095, lng: 17.695173 },
    { lat: 48.1975062, lng: 17.6955942 },
    { lat: 48.1972084, lng: 17.6951308 },
    { lat: 48.1967218, lng: 17.6948603 },
    { lat: 48.1963348, lng: 17.6951898 },
    { lat: 48.1956478, lng: 17.6953797 },
    { lat: 48.195451, lng: 17.695339 },
    { lat: 48.1952988, lng: 17.6953804 },
    { lat: 48.195152, lng: 17.6956123 },
    { lat: 48.1949141, lng: 17.6962507 },
    { lat: 48.1950632, lng: 17.6972263 },
    { lat: 48.1950548, lng: 17.697833 },
    { lat: 48.1949125, lng: 17.6980663 },
    { lat: 48.1946239, lng: 17.6979471 },
    { lat: 48.1941485, lng: 17.6979641 },
    { lat: 48.1940634, lng: 17.6978854 },
    { lat: 48.1940472, lng: 17.69779 },
    { lat: 48.1937349, lng: 17.6971277 },
    { lat: 48.1928707, lng: 17.6944142 },
    { lat: 48.1927116, lng: 17.694038 },
    { lat: 48.1927456, lng: 17.6936776 },
    { lat: 48.1922803, lng: 17.6926663 },
    { lat: 48.1914524, lng: 17.6911662 },
    { lat: 48.1901298, lng: 17.6891756 },
    { lat: 48.1890845, lng: 17.6880616 },
    { lat: 48.1884278, lng: 17.688844 },
    { lat: 48.1880764, lng: 17.6891076 },
    { lat: 48.1877835, lng: 17.6894257 },
    { lat: 48.1876477, lng: 17.6896372 },
    { lat: 48.1875745, lng: 17.6901269 },
    { lat: 48.1876317, lng: 17.6915282 },
    { lat: 48.1875817, lng: 17.6916321 },
    { lat: 48.1871519, lng: 17.6921778 },
    { lat: 48.1863458, lng: 17.6924502 },
    { lat: 48.1853988, lng: 17.6930152 },
    { lat: 48.1843336, lng: 17.6911143 },
    { lat: 48.1823907, lng: 17.69036 },
    { lat: 48.182075, lng: 17.6903855 },
    { lat: 48.1795159, lng: 17.6823785 },
    { lat: 48.1739392, lng: 17.6863746 },
    { lat: 48.1728357, lng: 17.687179 },
    { lat: 48.1710693, lng: 17.6890266 },
    { lat: 48.1598358, lng: 17.7001149 },
    { lat: 48.159845, lng: 17.7001564 },
    { lat: 48.16028, lng: 17.7022531 },
    { lat: 48.160495, lng: 17.7049867 },
    { lat: 48.1611097, lng: 17.7079015 },
    { lat: 48.1613945, lng: 17.70901 },
    { lat: 48.1616451, lng: 17.7104154 },
    { lat: 48.1618695, lng: 17.7132885 },
    { lat: 48.1620632, lng: 17.7144017 },
    { lat: 48.162407, lng: 17.7157439 },
    { lat: 48.1625397, lng: 17.7162622 },
    { lat: 48.1625175, lng: 17.7172824 },
    { lat: 48.1623615, lng: 17.7183842 },
    { lat: 48.1624096, lng: 17.7185748 },
    { lat: 48.1623459, lng: 17.7187003 },
    { lat: 48.1623192, lng: 17.7187529 },
    { lat: 48.162277, lng: 17.7189816 },
    { lat: 48.1623656, lng: 17.7202197 },
    { lat: 48.1619961, lng: 17.7207596 },
    { lat: 48.1619661, lng: 17.7212574 },
    { lat: 48.1620295, lng: 17.7216015 },
    { lat: 48.162012, lng: 17.7218646 },
    { lat: 48.1617016, lng: 17.7226081 },
    { lat: 48.1615243, lng: 17.723421 },
    { lat: 48.1612329, lng: 17.7239998 },
    { lat: 48.1612484, lng: 17.7241034 },
    { lat: 48.1613069, lng: 17.724155 },
    { lat: 48.1630673, lng: 17.7242482 },
    { lat: 48.1635511, lng: 17.7249461 },
    { lat: 48.1636508, lng: 17.7248063 },
    { lat: 48.1638551, lng: 17.7247712 },
    { lat: 48.1648029, lng: 17.7249946 },
    { lat: 48.1651943, lng: 17.7249542 },
    { lat: 48.1653908, lng: 17.7247556 },
    { lat: 48.1656576, lng: 17.7240525 },
    { lat: 48.1657157, lng: 17.7235383 },
    { lat: 48.1656489, lng: 17.7231356 },
    { lat: 48.1657269, lng: 17.7230466 },
    { lat: 48.1657876, lng: 17.7229883 },
    { lat: 48.1660221, lng: 17.7229849 },
    { lat: 48.1664337, lng: 17.7232105 },
    { lat: 48.1667358, lng: 17.7232561 },
    { lat: 48.1670999, lng: 17.7230282 },
    { lat: 48.1678586, lng: 17.7220708 },
    { lat: 48.1678224, lng: 17.7218513 },
    { lat: 48.1677735, lng: 17.7218305 },
    { lat: 48.1673621, lng: 17.7219105 },
    { lat: 48.1674477, lng: 17.7214016 },
    { lat: 48.1676625, lng: 17.7212974 },
    { lat: 48.167783, lng: 17.7213294 },
    { lat: 48.1682607, lng: 17.7214747 },
    { lat: 48.1685787, lng: 17.7217063 },
    { lat: 48.1686645, lng: 17.7214506 },
    { lat: 48.1692352, lng: 17.721762 },
    { lat: 48.1692629, lng: 17.7217771 },
    { lat: 48.169415, lng: 17.722081 },
    { lat: 48.1696379, lng: 17.7228998 },
    { lat: 48.1698108, lng: 17.7229946 },
    { lat: 48.1699779, lng: 17.7232305 },
    { lat: 48.1700859, lng: 17.7232777 },
    { lat: 48.1701032, lng: 17.7233539 },
    { lat: 48.1702548, lng: 17.7234137 },
    { lat: 48.1702981, lng: 17.723091 },
    { lat: 48.1706551, lng: 17.7231569 },
    { lat: 48.1709036, lng: 17.723056 },
    { lat: 48.1713445, lng: 17.7228222 },
    { lat: 48.1713947, lng: 17.7232086 },
    { lat: 48.1714945, lng: 17.7230334 },
    { lat: 48.1717914, lng: 17.7228706 },
    { lat: 48.1716255, lng: 17.7231428 },
    { lat: 48.1719003, lng: 17.7231735 },
    { lat: 48.1720746, lng: 17.723193 },
    { lat: 48.1718337, lng: 17.7281159 },
    { lat: 48.1741992, lng: 17.7283174 },
    { lat: 48.1754083, lng: 17.7283499 },
    { lat: 48.1754742, lng: 17.728918 },
    { lat: 48.1756534, lng: 17.7288591 },
    { lat: 48.1757963, lng: 17.7299559 },
    { lat: 48.1758496, lng: 17.7299807 },
    { lat: 48.178772, lng: 17.7290276 },
    { lat: 48.1788736, lng: 17.7297309 },
    { lat: 48.1790296, lng: 17.7296805 },
    { lat: 48.179037, lng: 17.7297265 },
    { lat: 48.1795804, lng: 17.7295507 },
    { lat: 48.1794762, lng: 17.7288046 },
    { lat: 48.181693, lng: 17.7281057 },
    { lat: 48.1817213, lng: 17.7283391 },
    { lat: 48.1819054, lng: 17.7287423 },
    { lat: 48.1834839, lng: 17.7295767 },
    { lat: 48.183797, lng: 17.7312129 },
    { lat: 48.1837846, lng: 17.7318496 },
    { lat: 48.1838565, lng: 17.7318598 },
    { lat: 48.1838864, lng: 17.7323594 },
    { lat: 48.1838465, lng: 17.7323728 },
    { lat: 48.1838307, lng: 17.7331526 },
    { lat: 48.183728, lng: 17.733912 },
    { lat: 48.1805941, lng: 17.7377662 },
    { lat: 48.1819763, lng: 17.7410196 },
    { lat: 48.1822252, lng: 17.7414078 },
    { lat: 48.1836009, lng: 17.7441964 },
    { lat: 48.1838758, lng: 17.7446224 },
    { lat: 48.1863827, lng: 17.747974 },
    { lat: 48.1874914, lng: 17.7496435 },
    { lat: 48.189487, lng: 17.7519641 },
  ],
  Váhovce: [
    { lat: 48.2605997, lng: 17.7872449 },
    { lat: 48.2605856, lng: 17.7871611 },
    { lat: 48.259886, lng: 17.7831573 },
    { lat: 48.2591259, lng: 17.7771393 },
    { lat: 48.2588479, lng: 17.7765299 },
    { lat: 48.2587969, lng: 17.7757416 },
    { lat: 48.258623, lng: 17.7753019 },
    { lat: 48.2582594, lng: 17.7749721 },
    { lat: 48.2579466, lng: 17.7745156 },
    { lat: 48.2577282, lng: 17.7737048 },
    { lat: 48.2572884, lng: 17.773179 },
    { lat: 48.2571072, lng: 17.7726058 },
    { lat: 48.256527, lng: 17.771713 },
    { lat: 48.2564915, lng: 17.7714678 },
    { lat: 48.2565379, lng: 17.7712495 },
    { lat: 48.2569118, lng: 17.7707963 },
    { lat: 48.2569904, lng: 17.7704602 },
    { lat: 48.2567895, lng: 17.7699318 },
    { lat: 48.256338, lng: 17.769367 },
    { lat: 48.2557865, lng: 17.7679367 },
    { lat: 48.2557081, lng: 17.7676702 },
    { lat: 48.2557166, lng: 17.7668415 },
    { lat: 48.2554507, lng: 17.76623 },
    { lat: 48.255339, lng: 17.7661407 },
    { lat: 48.2548929, lng: 17.7660655 },
    { lat: 48.2539965, lng: 17.766012 },
    { lat: 48.253499, lng: 17.7661806 },
    { lat: 48.2532634, lng: 17.7664602 },
    { lat: 48.2530989, lng: 17.7667763 },
    { lat: 48.2527208, lng: 17.7670711 },
    { lat: 48.2523173, lng: 17.7678837 },
    { lat: 48.2518564, lng: 17.7683915 },
    { lat: 48.2517162, lng: 17.7689703 },
    { lat: 48.2516563, lng: 17.769081 },
    { lat: 48.251511, lng: 17.7691759 },
    { lat: 48.251327, lng: 17.7687593 },
    { lat: 48.2512734, lng: 17.768218 },
    { lat: 48.251061, lng: 17.7678713 },
    { lat: 48.2509769, lng: 17.7678621 },
    { lat: 48.2507025, lng: 17.7681917 },
    { lat: 48.2504773, lng: 17.768693 },
    { lat: 48.2500234, lng: 17.7693868 },
    { lat: 48.2504403, lng: 17.7702868 },
    { lat: 48.2503662, lng: 17.7704735 },
    { lat: 48.2501658, lng: 17.7706292 },
    { lat: 48.2497268, lng: 17.7705236 },
    { lat: 48.2494666, lng: 17.7703134 },
    { lat: 48.2492682, lng: 17.7693328 },
    { lat: 48.2491645, lng: 17.7692434 },
    { lat: 48.2484783, lng: 17.7691923 },
    { lat: 48.2482446, lng: 17.7691004 },
    { lat: 48.2481258, lng: 17.7689695 },
    { lat: 48.2481408, lng: 17.7687566 },
    { lat: 48.2480863, lng: 17.7684376 },
    { lat: 48.2475317, lng: 17.767069 },
    { lat: 48.2462412, lng: 17.7642205 },
    { lat: 48.2455106, lng: 17.7627039 },
    { lat: 48.2449969, lng: 17.7617551 },
    { lat: 48.243866, lng: 17.7589932 },
    { lat: 48.2421592, lng: 17.7554068 },
    { lat: 48.2410985, lng: 17.7529201 },
    { lat: 48.2402248, lng: 17.753214 },
    { lat: 48.2391329, lng: 17.7539756 },
    { lat: 48.2381359, lng: 17.7548504 },
    { lat: 48.2369687, lng: 17.7560917 },
    { lat: 48.2365596, lng: 17.7554505 },
    { lat: 48.2362523, lng: 17.7547172 },
    { lat: 48.2274068, lng: 17.7587476 },
    { lat: 48.2240354, lng: 17.7608771 },
    { lat: 48.2237721, lng: 17.7611824 },
    { lat: 48.2207019, lng: 17.7644017 },
    { lat: 48.2193858, lng: 17.7663384 },
    { lat: 48.2182416, lng: 17.7677544 },
    { lat: 48.2154906, lng: 17.7702225 },
    { lat: 48.2153473, lng: 17.7703503 },
    { lat: 48.2153543, lng: 17.7725278 },
    { lat: 48.215371, lng: 17.7737475 },
    { lat: 48.2154745, lng: 17.7753827 },
    { lat: 48.2165334, lng: 17.7772606 },
    { lat: 48.2161057, lng: 17.7786154 },
    { lat: 48.2145331, lng: 17.7821254 },
    { lat: 48.2125172, lng: 17.7858787 },
    { lat: 48.2152297, lng: 17.789932 },
    { lat: 48.2158794, lng: 17.7910568 },
    { lat: 48.2164638, lng: 17.7918947 },
    { lat: 48.2172579, lng: 17.7924866 },
    { lat: 48.2182842, lng: 17.7940455 },
    { lat: 48.2200264, lng: 17.7961148 },
    { lat: 48.2205466, lng: 17.7972654 },
    { lat: 48.2208132, lng: 17.7980375 },
    { lat: 48.2210802, lng: 17.7991636 },
    { lat: 48.2211094, lng: 17.7996039 },
    { lat: 48.2209666, lng: 17.80124 },
    { lat: 48.2202834, lng: 17.8044287 },
    { lat: 48.2201323, lng: 17.8050184 },
    { lat: 48.2199622, lng: 17.8054443 },
    { lat: 48.2209294, lng: 17.8124503 },
    { lat: 48.2300902, lng: 17.8084769 },
    { lat: 48.2353987, lng: 17.8064972 },
    { lat: 48.2410386, lng: 17.8057411 },
    { lat: 48.2499405, lng: 17.8060369 },
    { lat: 48.2538425, lng: 17.8064455 },
    { lat: 48.2552952, lng: 17.8052007 },
    { lat: 48.2571853, lng: 17.803581 },
    { lat: 48.2608806, lng: 17.801059 },
    { lat: 48.2619968, lng: 17.8002972 },
    { lat: 48.2621735, lng: 17.7953558 },
    { lat: 48.2601006, lng: 17.7952242 },
    { lat: 48.2597294, lng: 17.7915661 },
    { lat: 48.2611119, lng: 17.7898926 },
    { lat: 48.2607541, lng: 17.7880994 },
    { lat: 48.2605997, lng: 17.7872449 },
  ],
  Matúškovo: [
    { lat: 48.1836009, lng: 17.7441964 },
    { lat: 48.1822252, lng: 17.7414078 },
    { lat: 48.1819763, lng: 17.7410196 },
    { lat: 48.1805941, lng: 17.7377662 },
    { lat: 48.183728, lng: 17.733912 },
    { lat: 48.1838307, lng: 17.7331526 },
    { lat: 48.1838465, lng: 17.7323728 },
    { lat: 48.1838864, lng: 17.7323594 },
    { lat: 48.1838565, lng: 17.7318598 },
    { lat: 48.1837846, lng: 17.7318496 },
    { lat: 48.183797, lng: 17.7312129 },
    { lat: 48.1834839, lng: 17.7295767 },
    { lat: 48.1819054, lng: 17.7287423 },
    { lat: 48.1817213, lng: 17.7283391 },
    { lat: 48.181693, lng: 17.7281057 },
    { lat: 48.1794762, lng: 17.7288046 },
    { lat: 48.1795804, lng: 17.7295507 },
    { lat: 48.179037, lng: 17.7297265 },
    { lat: 48.1790296, lng: 17.7296805 },
    { lat: 48.1788736, lng: 17.7297309 },
    { lat: 48.178772, lng: 17.7290276 },
    { lat: 48.1758496, lng: 17.7299807 },
    { lat: 48.1757963, lng: 17.7299559 },
    { lat: 48.1756534, lng: 17.7288591 },
    { lat: 48.1754742, lng: 17.728918 },
    { lat: 48.1754083, lng: 17.7283499 },
    { lat: 48.1741992, lng: 17.7283174 },
    { lat: 48.1718337, lng: 17.7281159 },
    { lat: 48.1720746, lng: 17.723193 },
    { lat: 48.1719003, lng: 17.7231735 },
    { lat: 48.1716255, lng: 17.7231428 },
    { lat: 48.1717914, lng: 17.7228706 },
    { lat: 48.1714945, lng: 17.7230334 },
    { lat: 48.1713947, lng: 17.7232086 },
    { lat: 48.1713445, lng: 17.7228222 },
    { lat: 48.1709036, lng: 17.723056 },
    { lat: 48.1706551, lng: 17.7231569 },
    { lat: 48.1702981, lng: 17.723091 },
    { lat: 48.1702548, lng: 17.7234137 },
    { lat: 48.1701032, lng: 17.7233539 },
    { lat: 48.1700859, lng: 17.7232777 },
    { lat: 48.1699779, lng: 17.7232305 },
    { lat: 48.1698108, lng: 17.7229946 },
    { lat: 48.1696379, lng: 17.7228998 },
    { lat: 48.169415, lng: 17.722081 },
    { lat: 48.1692629, lng: 17.7217771 },
    { lat: 48.1692352, lng: 17.721762 },
    { lat: 48.1686645, lng: 17.7214506 },
    { lat: 48.1685787, lng: 17.7217063 },
    { lat: 48.1682607, lng: 17.7214747 },
    { lat: 48.167783, lng: 17.7213294 },
    { lat: 48.1676625, lng: 17.7212974 },
    { lat: 48.1674477, lng: 17.7214016 },
    { lat: 48.1673621, lng: 17.7219105 },
    { lat: 48.1677735, lng: 17.7218305 },
    { lat: 48.1678224, lng: 17.7218513 },
    { lat: 48.1678586, lng: 17.7220708 },
    { lat: 48.1670999, lng: 17.7230282 },
    { lat: 48.1667358, lng: 17.7232561 },
    { lat: 48.1664337, lng: 17.7232105 },
    { lat: 48.1660221, lng: 17.7229849 },
    { lat: 48.1657876, lng: 17.7229883 },
    { lat: 48.1657269, lng: 17.7230466 },
    { lat: 48.1656489, lng: 17.7231356 },
    { lat: 48.1657157, lng: 17.7235383 },
    { lat: 48.1656576, lng: 17.7240525 },
    { lat: 48.1653908, lng: 17.7247556 },
    { lat: 48.1651943, lng: 17.7249542 },
    { lat: 48.1648029, lng: 17.7249946 },
    { lat: 48.1638551, lng: 17.7247712 },
    { lat: 48.1636508, lng: 17.7248063 },
    { lat: 48.1635511, lng: 17.7249461 },
    { lat: 48.1630673, lng: 17.7242482 },
    { lat: 48.1613069, lng: 17.724155 },
    { lat: 48.1612484, lng: 17.7241034 },
    { lat: 48.1612329, lng: 17.7239998 },
    { lat: 48.1612303, lng: 17.7239776 },
    { lat: 48.1608923, lng: 17.7242245 },
    { lat: 48.1602098, lng: 17.7244691 },
    { lat: 48.1598495, lng: 17.7244323 },
    { lat: 48.1593379, lng: 17.7245372 },
    { lat: 48.1591067, lng: 17.7245248 },
    { lat: 48.1586013, lng: 17.7241861 },
    { lat: 48.1583618, lng: 17.7241806 },
    { lat: 48.1571366, lng: 17.7244524 },
    { lat: 48.1567471, lng: 17.7243447 },
    { lat: 48.1566477, lng: 17.7242538 },
    { lat: 48.1563665, lng: 17.7237421 },
    { lat: 48.1562423, lng: 17.7236371 },
    { lat: 48.1558339, lng: 17.7236215 },
    { lat: 48.1556773, lng: 17.7237207 },
    { lat: 48.1552568, lng: 17.7243786 },
    { lat: 48.1544512, lng: 17.724371 },
    { lat: 48.1542291, lng: 17.7244854 },
    { lat: 48.1538812, lng: 17.7248983 },
    { lat: 48.1535277, lng: 17.7251138 },
    { lat: 48.1531743, lng: 17.7252252 },
    { lat: 48.1526193, lng: 17.7251416 },
    { lat: 48.152454, lng: 17.7252042 },
    { lat: 48.1523434, lng: 17.725331 },
    { lat: 48.1523191, lng: 17.7253588 },
    { lat: 48.1521549, lng: 17.7261132 },
    { lat: 48.1520698, lng: 17.7263047 },
    { lat: 48.1515744, lng: 17.726362 },
    { lat: 48.1514353, lng: 17.7265786 },
    { lat: 48.1514536, lng: 17.7268713 },
    { lat: 48.1516077, lng: 17.7273855 },
    { lat: 48.1516786, lng: 17.7280329 },
    { lat: 48.1517458, lng: 17.7295481 },
    { lat: 48.1517127, lng: 17.7298335 },
    { lat: 48.1513835, lng: 17.7308721 },
    { lat: 48.1511338, lng: 17.7319749 },
    { lat: 48.1511755, lng: 17.7327176 },
    { lat: 48.1511253, lng: 17.733525 },
    { lat: 48.1510293, lng: 17.7337958 },
    { lat: 48.1508498, lng: 17.7340856 },
    { lat: 48.1502409, lng: 17.7347137 },
    { lat: 48.1497316, lng: 17.7350567 },
    { lat: 48.1492428, lng: 17.7356571 },
    { lat: 48.1486087, lng: 17.7357167 },
    { lat: 48.1484053, lng: 17.7359885 },
    { lat: 48.1482825, lng: 17.7363372 },
    { lat: 48.1481914, lng: 17.7368653 },
    { lat: 48.1482437, lng: 17.7379597 },
    { lat: 48.1482156, lng: 17.7385271 },
    { lat: 48.1481552, lng: 17.738678 },
    { lat: 48.1478761, lng: 17.7389491 },
    { lat: 48.1477203, lng: 17.7389849 },
    { lat: 48.1475079, lng: 17.7393283 },
    { lat: 48.1475128, lng: 17.7395316 },
    { lat: 48.1480219, lng: 17.7409995 },
    { lat: 48.1480877, lng: 17.7414595 },
    { lat: 48.1479522, lng: 17.7416773 },
    { lat: 48.1475398, lng: 17.7420157 },
    { lat: 48.147448, lng: 17.7422292 },
    { lat: 48.1471338, lng: 17.7438376 },
    { lat: 48.1470549, lng: 17.7439868 },
    { lat: 48.146737, lng: 17.7443035 },
    { lat: 48.1463482, lng: 17.7443143 },
    { lat: 48.1453966, lng: 17.7439707 },
    { lat: 48.1441122, lng: 17.7438683 },
    { lat: 48.1439234, lng: 17.7439225 },
    { lat: 48.1437337, lng: 17.7441012 },
    { lat: 48.1436504, lng: 17.7442344 },
    { lat: 48.1435965, lng: 17.7444561 },
    { lat: 48.1435843, lng: 17.7453663 },
    { lat: 48.1438368, lng: 17.7466123 },
    { lat: 48.1443544, lng: 17.7470364 },
    { lat: 48.1444367, lng: 17.7473439 },
    { lat: 48.1444277, lng: 17.7476371 },
    { lat: 48.1443775, lng: 17.747865 },
    { lat: 48.1439729, lng: 17.7486222 },
    { lat: 48.1438443, lng: 17.7492212 },
    { lat: 48.1436461, lng: 17.7497545 },
    { lat: 48.1432759, lng: 17.749995 },
    { lat: 48.142453, lng: 17.7509038 },
    { lat: 48.1424361, lng: 17.751143 },
    { lat: 48.1429308, lng: 17.7518362 },
    { lat: 48.1431185, lng: 17.7524047 },
    { lat: 48.1430998, lng: 17.7531016 },
    { lat: 48.1429451, lng: 17.7535362 },
    { lat: 48.1439021, lng: 17.7542215 },
    { lat: 48.1445293, lng: 17.7549038 },
    { lat: 48.1449622, lng: 17.7557304 },
    { lat: 48.1455883, lng: 17.757123 },
    { lat: 48.14599, lng: 17.759487 },
    { lat: 48.145996, lng: 17.759465 },
    { lat: 48.14645, lng: 17.757805 },
    { lat: 48.146577, lng: 17.757341 },
    { lat: 48.146951, lng: 17.756007 },
    { lat: 48.146971, lng: 17.755935 },
    { lat: 48.14699, lng: 17.755868 },
    { lat: 48.147034, lng: 17.755819 },
    { lat: 48.1471353, lng: 17.7557483 },
    { lat: 48.147784, lng: 17.755199 },
    { lat: 48.148184, lng: 17.754884 },
    { lat: 48.148302, lng: 17.754818 },
    { lat: 48.148332, lng: 17.754766 },
    { lat: 48.148346, lng: 17.754791 },
    { lat: 48.148364, lng: 17.754824 },
    { lat: 48.149062, lng: 17.756114 },
    { lat: 48.149617, lng: 17.75715 },
    { lat: 48.149917, lng: 17.757711 },
    { lat: 48.150067, lng: 17.758018 },
    { lat: 48.15039, lng: 17.758638 },
    { lat: 48.150834, lng: 17.75949 },
    { lat: 48.151697, lng: 17.761099 },
    { lat: 48.151791, lng: 17.761273 },
    { lat: 48.151813, lng: 17.761314 },
    { lat: 48.152484, lng: 17.762867 },
    { lat: 48.152811, lng: 17.763657 },
    { lat: 48.152837, lng: 17.763723 },
    { lat: 48.153364, lng: 17.765028 },
    { lat: 48.153985, lng: 17.765942 },
    { lat: 48.154543, lng: 17.76692 },
    { lat: 48.155478, lng: 17.768752 },
    { lat: 48.155999, lng: 17.770418 },
    { lat: 48.156009, lng: 17.770449 },
    { lat: 48.156052, lng: 17.770579 },
    { lat: 48.156063, lng: 17.770613 },
    { lat: 48.156316, lng: 17.771463 },
    { lat: 48.156619, lng: 17.773631 },
    { lat: 48.156983, lng: 17.774908 },
    { lat: 48.157382, lng: 17.775702 },
    { lat: 48.157552, lng: 17.776039 },
    { lat: 48.158167, lng: 17.778029 },
    { lat: 48.15811, lng: 17.778261 },
    { lat: 48.1619664, lng: 17.7747104 },
    { lat: 48.1616482, lng: 17.7797134 },
    { lat: 48.1675133, lng: 17.7892845 },
    { lat: 48.1676935, lng: 17.7889654 },
    { lat: 48.1686771, lng: 17.7867048 },
    { lat: 48.1695906, lng: 17.7835683 },
    { lat: 48.1699598, lng: 17.7806816 },
    { lat: 48.1700611, lng: 17.7792511 },
    { lat: 48.1702291, lng: 17.7780615 },
    { lat: 48.1704733, lng: 17.7768281 },
    { lat: 48.1711084, lng: 17.7759041 },
    { lat: 48.1712157, lng: 17.7754323 },
    { lat: 48.171539, lng: 17.7747655 },
    { lat: 48.1715872, lng: 17.7741035 },
    { lat: 48.170544, lng: 17.7714771 },
    { lat: 48.1717261, lng: 17.770165 },
    { lat: 48.1711576, lng: 17.7685687 },
    { lat: 48.1710102, lng: 17.7673586 },
    { lat: 48.1711027, lng: 17.7633464 },
    { lat: 48.1712523, lng: 17.760772 },
    { lat: 48.171222, lng: 17.7595171 },
    { lat: 48.1709862, lng: 17.7582291 },
    { lat: 48.1709449, lng: 17.7570373 },
    { lat: 48.172815, lng: 17.7552782 },
    { lat: 48.1735446, lng: 17.7546985 },
    { lat: 48.1771022, lng: 17.7513393 },
    { lat: 48.1778151, lng: 17.750582 },
    { lat: 48.1784974, lng: 17.7497511 },
    { lat: 48.1806243, lng: 17.7470198 },
    { lat: 48.1821303, lng: 17.7457115 },
    { lat: 48.1836009, lng: 17.7441964 },
  ],
  Košúty: [
    { lat: 48.1812429, lng: 17.6410736 },
    { lat: 48.1809007, lng: 17.6399239 },
    { lat: 48.1791098, lng: 17.634789 },
    { lat: 48.1789037, lng: 17.6340988 },
    { lat: 48.1781262, lng: 17.6322146 },
    { lat: 48.1775937, lng: 17.6311106 },
    { lat: 48.1766433, lng: 17.6284913 },
    { lat: 48.1757162, lng: 17.6261126 },
    { lat: 48.1751781, lng: 17.6247647 },
    { lat: 48.1749228, lng: 17.6242638 },
    { lat: 48.1756038, lng: 17.6235676 },
    { lat: 48.1759166, lng: 17.6231374 },
    { lat: 48.1763429, lng: 17.6223993 },
    { lat: 48.1762245, lng: 17.6221694 },
    { lat: 48.176238, lng: 17.622009 },
    { lat: 48.1762045, lng: 17.6219614 },
    { lat: 48.17603, lng: 17.622029 },
    { lat: 48.1759886, lng: 17.622045 },
    { lat: 48.174758, lng: 17.6197274 },
    { lat: 48.1746979, lng: 17.6195636 },
    { lat: 48.1747016, lng: 17.618783 },
    { lat: 48.1745563, lng: 17.6184838 },
    { lat: 48.174541, lng: 17.6182613 },
    { lat: 48.174314, lng: 17.6181186 },
    { lat: 48.1738652, lng: 17.6176108 },
    { lat: 48.1735635, lng: 17.6171614 },
    { lat: 48.1733264, lng: 17.6170014 },
    { lat: 48.1731951, lng: 17.6170876 },
    { lat: 48.1731317, lng: 17.6170466 },
    { lat: 48.1731523, lng: 17.6169273 },
    { lat: 48.1726843, lng: 17.6167937 },
    { lat: 48.1721386, lng: 17.6168776 },
    { lat: 48.1717813, lng: 17.6168528 },
    { lat: 48.1716508, lng: 17.6167347 },
    { lat: 48.1713997, lng: 17.6163008 },
    { lat: 48.1706293, lng: 17.6159227 },
    { lat: 48.170371, lng: 17.6156066 },
    { lat: 48.1702348, lng: 17.6155406 },
    { lat: 48.1702704, lng: 17.6168168 },
    { lat: 48.1700348, lng: 17.6179481 },
    { lat: 48.169922, lng: 17.618252 },
    { lat: 48.1695342, lng: 17.6189068 },
    { lat: 48.1688785, lng: 17.6194445 },
    { lat: 48.1684564, lng: 17.6196814 },
    { lat: 48.1678286, lng: 17.6196832 },
    { lat: 48.1671297, lng: 17.6194866 },
    { lat: 48.1662156, lng: 17.6185661 },
    { lat: 48.1657021, lng: 17.6176614 },
    { lat: 48.1652112, lng: 17.617988 },
    { lat: 48.1649399, lng: 17.6180189 },
    { lat: 48.1647567, lng: 17.6179631 },
    { lat: 48.1642031, lng: 17.617848 },
    { lat: 48.1635922, lng: 17.6175994 },
    { lat: 48.1629404, lng: 17.6169725 },
    { lat: 48.162189, lng: 17.6168914 },
    { lat: 48.1619798, lng: 17.6169252 },
    { lat: 48.1617825, lng: 17.6174274 },
    { lat: 48.1616349, lng: 17.6185935 },
    { lat: 48.1616192, lng: 17.6189702 },
    { lat: 48.161726, lng: 17.6196034 },
    { lat: 48.1616955, lng: 17.6198737 },
    { lat: 48.1617672, lng: 17.6201645 },
    { lat: 48.1617086, lng: 17.6205594 },
    { lat: 48.161622, lng: 17.6207262 },
    { lat: 48.1614911, lng: 17.6207447 },
    { lat: 48.161033, lng: 17.6214103 },
    { lat: 48.1606078, lng: 17.6217396 },
    { lat: 48.1605243, lng: 17.6219017 },
    { lat: 48.1604446, lng: 17.6223214 },
    { lat: 48.1604574, lng: 17.6226832 },
    { lat: 48.1605068, lng: 17.6227689 },
    { lat: 48.1605032, lng: 17.6231054 },
    { lat: 48.1604497, lng: 17.623329 },
    { lat: 48.160606, lng: 17.6248199 },
    { lat: 48.1605723, lng: 17.6250535 },
    { lat: 48.1604708, lng: 17.6253059 },
    { lat: 48.1602459, lng: 17.6256404 },
    { lat: 48.15964, lng: 17.626364 },
    { lat: 48.1594923, lng: 17.6264036 },
    { lat: 48.159127, lng: 17.6263226 },
    { lat: 48.1583078, lng: 17.6252408 },
    { lat: 48.1580198, lng: 17.6250074 },
    { lat: 48.157317, lng: 17.6247537 },
    { lat: 48.1571352, lng: 17.6246277 },
    { lat: 48.1554193, lng: 17.6251728 },
    { lat: 48.1547512, lng: 17.6250168 },
    { lat: 48.1543614, lng: 17.6247798 },
    { lat: 48.1536814, lng: 17.6239529 },
    { lat: 48.1532708, lng: 17.6231947 },
    { lat: 48.1527984, lng: 17.6227056 },
    { lat: 48.1526705, lng: 17.6225045 },
    { lat: 48.152339, lng: 17.6224413 },
    { lat: 48.151685, lng: 17.6226706 },
    { lat: 48.1515806, lng: 17.6228041 },
    { lat: 48.1514083, lng: 17.623274 },
    { lat: 48.1510206, lng: 17.6239313 },
    { lat: 48.1506836, lng: 17.6260807 },
    { lat: 48.1504902, lng: 17.62687 },
    { lat: 48.1502722, lng: 17.6276656 },
    { lat: 48.1501182, lng: 17.6285339 },
    { lat: 48.1499708, lng: 17.628898 },
    { lat: 48.1540291, lng: 17.6436519 },
    { lat: 48.1538287, lng: 17.6440041 },
    { lat: 48.1542917, lng: 17.6446298 },
    { lat: 48.1544329, lng: 17.6446416 },
    { lat: 48.154387, lng: 17.6444223 },
    { lat: 48.1544762, lng: 17.6445054 },
    { lat: 48.1558011, lng: 17.6470061 },
    { lat: 48.1563607, lng: 17.6463187 },
    { lat: 48.1570871, lng: 17.6484924 },
    { lat: 48.1587216, lng: 17.6502416 },
    { lat: 48.1569192, lng: 17.6519738 },
    { lat: 48.1561547, lng: 17.6528863 },
    { lat: 48.1566853, lng: 17.6538873 },
    { lat: 48.1563029, lng: 17.6542888 },
    { lat: 48.1556572, lng: 17.6548124 },
    { lat: 48.1554259, lng: 17.6551749 },
    { lat: 48.1542929, lng: 17.6574428 },
    { lat: 48.154244, lng: 17.6575411 },
    { lat: 48.1542337, lng: 17.6575618 },
    { lat: 48.1554693, lng: 17.6584079 },
    { lat: 48.1558595, lng: 17.6595656 },
    { lat: 48.1565555, lng: 17.6595573 },
    { lat: 48.1564349, lng: 17.6592386 },
    { lat: 48.1574278, lng: 17.6593681 },
    { lat: 48.1581426, lng: 17.6590481 },
    { lat: 48.1586276, lng: 17.6598636 },
    { lat: 48.1586655, lng: 17.6597469 },
    { lat: 48.1587641, lng: 17.6593246 },
    { lat: 48.159148, lng: 17.6590192 },
    { lat: 48.1595293, lng: 17.6585999 },
    { lat: 48.1599178, lng: 17.6583698 },
    { lat: 48.1602553, lng: 17.6584197 },
    { lat: 48.1610575, lng: 17.6591277 },
    { lat: 48.1611424, lng: 17.6592211 },
    { lat: 48.1611877, lng: 17.6593845 },
    { lat: 48.1611109, lng: 17.660138 },
    { lat: 48.1610253, lng: 17.6604284 },
    { lat: 48.1608304, lng: 17.6607578 },
    { lat: 48.1607596, lng: 17.6610683 },
    { lat: 48.1607179, lng: 17.6619845 },
    { lat: 48.1606175, lng: 17.6627066 },
    { lat: 48.160647, lng: 17.6628326 },
    { lat: 48.1607517, lng: 17.6631327 },
    { lat: 48.1609041, lng: 17.6632934 },
    { lat: 48.161066, lng: 17.6633559 },
    { lat: 48.1613984, lng: 17.6633431 },
    { lat: 48.1615121, lng: 17.6634661 },
    { lat: 48.1617474, lng: 17.6639572 },
    { lat: 48.1618322, lng: 17.6642813 },
    { lat: 48.1619179, lng: 17.6643696 },
    { lat: 48.1620409, lng: 17.6643877 },
    { lat: 48.1621752, lng: 17.6642246 },
    { lat: 48.1623168, lng: 17.6637626 },
    { lat: 48.1629214, lng: 17.6634481 },
    { lat: 48.1631071, lng: 17.6623787 },
    { lat: 48.1631234, lng: 17.6619909 },
    { lat: 48.1633099, lng: 17.6617097 },
    { lat: 48.1635231, lng: 17.6617406 },
    { lat: 48.1639223, lng: 17.6619663 },
    { lat: 48.163926, lng: 17.6622215 },
    { lat: 48.1638363, lng: 17.6625556 },
    { lat: 48.1639018, lng: 17.6628435 },
    { lat: 48.1647935, lng: 17.6632533 },
    { lat: 48.164866, lng: 17.6634532 },
    { lat: 48.1646869, lng: 17.6638901 },
    { lat: 48.1647021, lng: 17.6640602 },
    { lat: 48.1647812, lng: 17.6642043 },
    { lat: 48.1650003, lng: 17.6642952 },
    { lat: 48.1655609, lng: 17.6643759 },
    { lat: 48.1661673, lng: 17.6647169 },
    { lat: 48.166365, lng: 17.6645767 },
    { lat: 48.1667012, lng: 17.6655576 },
    { lat: 48.1669128, lng: 17.6656906 },
    { lat: 48.1671008, lng: 17.6662955 },
    { lat: 48.1674945, lng: 17.6661447 },
    { lat: 48.1677155, lng: 17.6661933 },
    { lat: 48.1695648, lng: 17.6724963 },
    { lat: 48.1692338, lng: 17.672622 },
    { lat: 48.1739392, lng: 17.6863746 },
    { lat: 48.1795159, lng: 17.6823785 },
    { lat: 48.182075, lng: 17.6903855 },
    { lat: 48.1823907, lng: 17.69036 },
    { lat: 48.1843336, lng: 17.6911143 },
    { lat: 48.1853988, lng: 17.6930152 },
    { lat: 48.1863458, lng: 17.6924502 },
    { lat: 48.1871519, lng: 17.6921778 },
    { lat: 48.1875817, lng: 17.6916321 },
    { lat: 48.1876317, lng: 17.6915282 },
    { lat: 48.1875745, lng: 17.6901269 },
    { lat: 48.1876477, lng: 17.6896372 },
    { lat: 48.1877835, lng: 17.6894257 },
    { lat: 48.1880764, lng: 17.6891076 },
    { lat: 48.1884278, lng: 17.688844 },
    { lat: 48.1890845, lng: 17.6880616 },
    { lat: 48.1901298, lng: 17.6891756 },
    { lat: 48.1914524, lng: 17.6911662 },
    { lat: 48.1922803, lng: 17.6926663 },
    { lat: 48.1927456, lng: 17.6936776 },
    { lat: 48.1927116, lng: 17.694038 },
    { lat: 48.1928707, lng: 17.6944142 },
    { lat: 48.1937349, lng: 17.6971277 },
    { lat: 48.1940472, lng: 17.69779 },
    { lat: 48.1940634, lng: 17.6978854 },
    { lat: 48.1941485, lng: 17.6979641 },
    { lat: 48.1946239, lng: 17.6979471 },
    { lat: 48.1949125, lng: 17.6980663 },
    { lat: 48.1950548, lng: 17.697833 },
    { lat: 48.1950632, lng: 17.6972263 },
    { lat: 48.1949141, lng: 17.6962507 },
    { lat: 48.195152, lng: 17.6956123 },
    { lat: 48.1952988, lng: 17.6953804 },
    { lat: 48.195451, lng: 17.695339 },
    { lat: 48.1956478, lng: 17.6953797 },
    { lat: 48.1963348, lng: 17.6951898 },
    { lat: 48.1967218, lng: 17.6948603 },
    { lat: 48.1972084, lng: 17.6951308 },
    { lat: 48.1975062, lng: 17.6955942 },
    { lat: 48.198095, lng: 17.695173 },
    { lat: 48.1982632, lng: 17.6949527 },
    { lat: 48.1986118, lng: 17.6943915 },
    { lat: 48.1987959, lng: 17.6932198 },
    { lat: 48.1989865, lng: 17.6933764 },
    { lat: 48.199242, lng: 17.6923702 },
    { lat: 48.1992315, lng: 17.6909075 },
    { lat: 48.1995946, lng: 17.6897978 },
    { lat: 48.200299, lng: 17.6885708 },
    { lat: 48.2003751, lng: 17.6883421 },
    { lat: 48.2001746, lng: 17.6869818 },
    { lat: 48.200094, lng: 17.6867744 },
    { lat: 48.1997134, lng: 17.686213 },
    { lat: 48.199393, lng: 17.6858576 },
    { lat: 48.1993325, lng: 17.6856863 },
    { lat: 48.1994127, lng: 17.6849683 },
    { lat: 48.1990613, lng: 17.6843334 },
    { lat: 48.1989442, lng: 17.6834919 },
    { lat: 48.1989477, lng: 17.6832221 },
    { lat: 48.1990526, lng: 17.6827328 },
    { lat: 48.1991273, lng: 17.6816567 },
    { lat: 48.1993026, lng: 17.6814251 },
    { lat: 48.1984884, lng: 17.6804323 },
    { lat: 48.1974789, lng: 17.6793504 },
    { lat: 48.1932627, lng: 17.6743479 },
    { lat: 48.1892673, lng: 17.6692857 },
    { lat: 48.1880903, lng: 17.6671571 },
    { lat: 48.1870208, lng: 17.6650319 },
    { lat: 48.1850499, lng: 17.6613418 },
    { lat: 48.1850805, lng: 17.6599091 },
    { lat: 48.1836704, lng: 17.6539546 },
    { lat: 48.1841964, lng: 17.6537362 },
    { lat: 48.1836796, lng: 17.6513581 },
    { lat: 48.1826979, lng: 17.646366 },
    { lat: 48.1822902, lng: 17.6447521 },
    { lat: 48.1814, lng: 17.641613 },
    { lat: 48.1812429, lng: 17.6410736 },
  ],
  Trstice: [
    { lat: 47.999597, lng: 17.817185 },
    { lat: 47.999448, lng: 17.817282 },
    { lat: 47.999373, lng: 17.817343 },
    { lat: 47.999287, lng: 17.817434 },
    { lat: 47.999228, lng: 17.817517 },
    { lat: 47.999203, lng: 17.817555 },
    { lat: 47.999174, lng: 17.817622 },
    { lat: 47.999124, lng: 17.817732 },
    { lat: 47.999046, lng: 17.817924 },
    { lat: 47.999013, lng: 17.818011 },
    { lat: 47.998982, lng: 17.818133 },
    { lat: 47.998954, lng: 17.818282 },
    { lat: 47.998904, lng: 17.818545 },
    { lat: 47.998859, lng: 17.8188 },
    { lat: 47.998779, lng: 17.819156 },
    { lat: 47.998744, lng: 17.819324 },
    { lat: 47.998721, lng: 17.819397 },
    { lat: 47.998683, lng: 17.819467 },
    { lat: 47.99851, lng: 17.81977 },
    { lat: 47.998391, lng: 17.819898 },
    { lat: 47.998281, lng: 17.820016 },
    { lat: 47.998225, lng: 17.820082 },
    { lat: 47.998169, lng: 17.820137 },
    { lat: 47.998047, lng: 17.820226 },
    { lat: 47.997898, lng: 17.820326 },
    { lat: 47.997821, lng: 17.82038 },
    { lat: 47.997751, lng: 17.82043 },
    { lat: 47.997703, lng: 17.820458 },
    { lat: 47.997558, lng: 17.82056 },
    { lat: 47.997513, lng: 17.820593 },
    { lat: 47.997367, lng: 17.82069 },
    { lat: 47.997282, lng: 17.820759 },
    { lat: 47.997208, lng: 17.820824 },
    { lat: 47.997108, lng: 17.820913 },
    { lat: 47.997009, lng: 17.820996 },
    { lat: 47.996903, lng: 17.821077 },
    { lat: 47.99664, lng: 17.821271 },
    { lat: 47.996521, lng: 17.821369 },
    { lat: 47.996408, lng: 17.821471 },
    { lat: 47.996325, lng: 17.821552 },
    { lat: 47.996236, lng: 17.821637 },
    { lat: 47.996185, lng: 17.821691 },
    { lat: 47.99612, lng: 17.821768 },
    { lat: 47.996092, lng: 17.821811 },
    { lat: 47.99607, lng: 17.821866 },
    { lat: 47.996014, lng: 17.821987 },
    { lat: 47.995957, lng: 17.822115 },
    { lat: 47.995899, lng: 17.822247 },
    { lat: 47.995836, lng: 17.822379 },
    { lat: 47.995814, lng: 17.822422 },
    { lat: 47.995778, lng: 17.822467 },
    { lat: 47.995748, lng: 17.822507 },
    { lat: 47.995667, lng: 17.822615 },
    { lat: 47.995628, lng: 17.82267 },
    { lat: 47.995588, lng: 17.822723 },
    { lat: 47.995493, lng: 17.822823 },
    { lat: 47.995381, lng: 17.822949 },
    { lat: 47.995307, lng: 17.823007 },
    { lat: 47.995246, lng: 17.823048 },
    { lat: 47.995186, lng: 17.823094 },
    { lat: 47.995132, lng: 17.823129 },
    { lat: 47.995079, lng: 17.823167 },
    { lat: 47.994765, lng: 17.823388 },
    { lat: 47.994685, lng: 17.823447 },
    { lat: 47.994596, lng: 17.823491 },
    { lat: 47.994499, lng: 17.823539 },
    { lat: 47.994404, lng: 17.823591 },
    { lat: 47.994119, lng: 17.823727 },
    { lat: 47.99397, lng: 17.823799 },
    { lat: 47.993904, lng: 17.823833 },
    { lat: 47.993841, lng: 17.823861 },
    { lat: 47.993719, lng: 17.823948 },
    { lat: 47.993662, lng: 17.823998 },
    { lat: 47.9936, lng: 17.824048 },
    { lat: 47.993434, lng: 17.824235 },
    { lat: 47.993388, lng: 17.824307 },
    { lat: 47.993272, lng: 17.824529 },
    { lat: 47.99319, lng: 17.82478 },
    { lat: 47.993167, lng: 17.824914 },
    { lat: 47.993149, lng: 17.824998 },
    { lat: 47.993139, lng: 17.82507 },
    { lat: 47.993125, lng: 17.825152 },
    { lat: 47.993114, lng: 17.825233 },
    { lat: 47.993125, lng: 17.825572 },
    { lat: 47.993163, lng: 17.82623 },
    { lat: 47.993179, lng: 17.826374 },
    { lat: 47.993209, lng: 17.826674 },
    { lat: 47.993187, lng: 17.826946 },
    { lat: 47.993147, lng: 17.827157 },
    { lat: 47.993062, lng: 17.827373 },
    { lat: 47.992984, lng: 17.827543 },
    { lat: 47.992899, lng: 17.827702 },
    { lat: 47.992817, lng: 17.827852 },
    { lat: 47.992708, lng: 17.82796 },
    { lat: 47.992597, lng: 17.828054 },
    { lat: 47.99258, lng: 17.828062 },
    { lat: 47.992461, lng: 17.828114 },
    { lat: 47.992379, lng: 17.828143 },
    { lat: 47.992298, lng: 17.828167 },
    { lat: 47.992121, lng: 17.828213 },
    { lat: 47.992005, lng: 17.828287 },
    { lat: 47.991953, lng: 17.828306 },
    { lat: 47.991891, lng: 17.828322 },
    { lat: 47.991715, lng: 17.828348 },
    { lat: 47.991626, lng: 17.82836 },
    { lat: 47.991547, lng: 17.828377 },
    { lat: 47.991353, lng: 17.828415 },
    { lat: 47.991326, lng: 17.828435 },
    { lat: 47.99124, lng: 17.828497 },
    { lat: 47.991189, lng: 17.82853 },
    { lat: 47.991155, lng: 17.82857 },
    { lat: 47.99107, lng: 17.828679 },
    { lat: 47.990981, lng: 17.828782 },
    { lat: 47.990878, lng: 17.828848 },
    { lat: 47.990854, lng: 17.828874 },
    { lat: 47.990822, lng: 17.828911 },
    { lat: 47.990793, lng: 17.828942 },
    { lat: 47.990758, lng: 17.82897 },
    { lat: 47.990631, lng: 17.829056 },
    { lat: 47.990535, lng: 17.829108 },
    { lat: 47.99046, lng: 17.829134 },
    { lat: 47.990275, lng: 17.8292 },
    { lat: 47.990218, lng: 17.829221 },
    { lat: 47.990048, lng: 17.829235 },
    { lat: 47.989991, lng: 17.829238 },
    { lat: 47.989856, lng: 17.829301 },
    { lat: 47.989828, lng: 17.829316 },
    { lat: 47.989773, lng: 17.829337 },
    { lat: 47.989713, lng: 17.829353 },
    { lat: 47.989641, lng: 17.829394 },
    { lat: 47.989465, lng: 17.829524 },
    { lat: 47.989321, lng: 17.829646 },
    { lat: 47.989144, lng: 17.829751 },
    { lat: 47.989059, lng: 17.829815 },
    { lat: 47.988869, lng: 17.829955 },
    { lat: 47.98864, lng: 17.830151 },
    { lat: 47.988412, lng: 17.830331 },
    { lat: 47.988195, lng: 17.830452 },
    { lat: 47.9885109, lng: 17.8312467 },
    { lat: 47.9901206, lng: 17.8340495 },
    { lat: 47.9937205, lng: 17.8405601 },
    { lat: 47.9939779, lng: 17.8409106 },
    { lat: 47.9943544, lng: 17.8433973 },
    { lat: 47.9950229, lng: 17.8468138 },
    { lat: 47.9974879, lng: 17.860838 },
    { lat: 47.997599, lng: 17.8610672 },
    { lat: 47.998149, lng: 17.8605549 },
    { lat: 47.9988026, lng: 17.8603151 },
    { lat: 47.99983, lng: 17.860282 },
    { lat: 48.000557, lng: 17.8601861 },
    { lat: 48.0008058, lng: 17.8602132 },
    { lat: 48.0011282, lng: 17.8603558 },
    { lat: 48.0012931, lng: 17.8605402 },
    { lat: 48.0014564, lng: 17.8608084 },
    { lat: 48.0015474, lng: 17.8611696 },
    { lat: 48.0016058, lng: 17.8615159 },
    { lat: 48.00161, lng: 17.862044 },
    { lat: 48.0011728, lng: 17.864728 },
    { lat: 48.0011409, lng: 17.8659184 },
    { lat: 48.0014446, lng: 17.867099 },
    { lat: 48.0016408, lng: 17.8676299 },
    { lat: 48.0019891, lng: 17.8680291 },
    { lat: 48.0025407, lng: 17.8683524 },
    { lat: 48.0032659, lng: 17.8684712 },
    { lat: 48.0036364, lng: 17.8683835 },
    { lat: 48.0049618, lng: 17.8677379 },
    { lat: 48.0055448, lng: 17.8671764 },
    { lat: 48.006076, lng: 17.8663385 },
    { lat: 48.0061775, lng: 17.8659683 },
    { lat: 48.0062038, lng: 17.865405 },
    { lat: 48.0060725, lng: 17.8647175 },
    { lat: 48.0059616, lng: 17.8644164 },
    { lat: 48.0055453, lng: 17.8636655 },
    { lat: 48.0035328, lng: 17.8610005 },
    { lat: 48.0024267, lng: 17.8600098 },
    { lat: 48.0017964, lng: 17.8596332 },
    { lat: 48.0009329, lng: 17.8592845 },
    { lat: 48.0007081, lng: 17.8590091 },
    { lat: 48.0006394, lng: 17.8585342 },
    { lat: 48.0007245, lng: 17.8582815 },
    { lat: 48.0009308, lng: 17.8581287 },
    { lat: 48.0011081, lng: 17.8581109 },
    { lat: 48.0017502, lng: 17.8583131 },
    { lat: 48.0022369, lng: 17.8583491 },
    { lat: 48.0026744, lng: 17.8581051 },
    { lat: 48.0028151, lng: 17.8579495 },
    { lat: 48.0028868, lng: 17.8577769 },
    { lat: 48.0030527, lng: 17.8570374 },
    { lat: 48.0031172, lng: 17.855698 },
    { lat: 48.0028802, lng: 17.8524427 },
    { lat: 48.0028942, lng: 17.85153 },
    { lat: 48.003006, lng: 17.8505772 },
    { lat: 48.00312, lng: 17.8499878 },
    { lat: 48.0033354, lng: 17.8483296 },
    { lat: 48.003692, lng: 17.8466757 },
    { lat: 48.0041779, lng: 17.8452336 },
    { lat: 48.0043415, lng: 17.8449205 },
    { lat: 48.0047886, lng: 17.8442959 },
    { lat: 48.004954, lng: 17.8441199 },
    { lat: 48.0054651, lng: 17.8439342 },
    { lat: 48.0058833, lng: 17.84396 },
    { lat: 48.0061404, lng: 17.8440451 },
    { lat: 48.0071904, lng: 17.8446008 },
    { lat: 48.0074462, lng: 17.8449416 },
    { lat: 48.0076287, lng: 17.8454321 },
    { lat: 48.00766, lng: 17.8457557 },
    { lat: 48.0076288, lng: 17.8460567 },
    { lat: 48.0074431, lng: 17.8466539 },
    { lat: 48.0063306, lng: 17.8485361 },
    { lat: 48.0060723, lng: 17.8493105 },
    { lat: 48.0060754, lng: 17.8498237 },
    { lat: 48.0063718, lng: 17.8508059 },
    { lat: 48.0066578, lng: 17.8512375 },
    { lat: 48.0069564, lng: 17.8514315 },
    { lat: 48.0071419, lng: 17.8514366 },
    { lat: 48.0074221, lng: 17.851256 },
    { lat: 48.0077765, lng: 17.8505949 },
    { lat: 48.0081931, lng: 17.8481502 },
    { lat: 48.009505, lng: 17.8421101 },
    { lat: 48.0099792, lng: 17.8410621 },
    { lat: 48.0102304, lng: 17.8406887 },
    { lat: 48.0105971, lng: 17.8404073 },
    { lat: 48.0108915, lng: 17.8403678 },
    { lat: 48.0111837, lng: 17.8404379 },
    { lat: 48.0114516, lng: 17.8407013 },
    { lat: 48.0115681, lng: 17.8410528 },
    { lat: 48.0115358, lng: 17.8416272 },
    { lat: 48.0110916, lng: 17.8426299 },
    { lat: 48.0109508, lng: 17.8431141 },
    { lat: 48.0108855, lng: 17.8437216 },
    { lat: 48.0108769, lng: 17.8446479 },
    { lat: 48.0109122, lng: 17.8453481 },
    { lat: 48.011255, lng: 17.8474934 },
    { lat: 48.0114263, lng: 17.849035 },
    { lat: 48.0115199, lng: 17.8493797 },
    { lat: 48.0117756, lng: 17.8495725 },
    { lat: 48.0122391, lng: 17.849528 },
    { lat: 48.0128742, lng: 17.8482515 },
    { lat: 48.0132911, lng: 17.847725 },
    { lat: 48.0135279, lng: 17.8475182 },
    { lat: 48.0137703, lng: 17.8474058 },
    { lat: 48.0142823, lng: 17.8473035 },
    { lat: 48.0148287, lng: 17.847286 },
    { lat: 48.0155806, lng: 17.8475268 },
    { lat: 48.0161294, lng: 17.8477513 },
    { lat: 48.0170073, lng: 17.8482217 },
    { lat: 48.0174795, lng: 17.8483561 },
    { lat: 48.0187116, lng: 17.8484006 },
    { lat: 48.0193635, lng: 17.8481875 },
    { lat: 48.0198213, lng: 17.8479237 },
    { lat: 48.0200936, lng: 17.8475957 },
    { lat: 48.0203131, lng: 17.8471616 },
    { lat: 48.0210714, lng: 17.844787 },
    { lat: 48.0213177, lng: 17.844203 },
    { lat: 48.0215401, lng: 17.8436148 },
    { lat: 48.0215924, lng: 17.8433255 },
    { lat: 48.0216259, lng: 17.842883 },
    { lat: 48.0215774, lng: 17.8425134 },
    { lat: 48.0213941, lng: 17.8419207 },
    { lat: 48.0205661, lng: 17.8398345 },
    { lat: 48.0201126, lng: 17.83896 },
    { lat: 48.0198595, lng: 17.8386067 },
    { lat: 48.0197123, lng: 17.8382136 },
    { lat: 48.0197026, lng: 17.8379286 },
    { lat: 48.0198368, lng: 17.837504 },
    { lat: 48.0202154, lng: 17.8369435 },
    { lat: 48.020453, lng: 17.8367495 },
    { lat: 48.0214681, lng: 17.8362924 },
    { lat: 48.0222382, lng: 17.8361853 },
    { lat: 48.0225437, lng: 17.8363068 },
    { lat: 48.0229786, lng: 17.8366624 },
    { lat: 48.023242, lng: 17.8370524 },
    { lat: 48.0235076, lng: 17.8377358 },
    { lat: 48.0236218, lng: 17.8383465 },
    { lat: 48.0236673, lng: 17.8390864 },
    { lat: 48.023652, lng: 17.8393336 },
    { lat: 48.0235506, lng: 17.8396122 },
    { lat: 48.0230545, lng: 17.840081 },
    { lat: 48.0229431, lng: 17.8405278 },
    { lat: 48.0229618, lng: 17.8407749 },
    { lat: 48.0231231, lng: 17.8412895 },
    { lat: 48.0235003, lng: 17.8419489 },
    { lat: 48.0238862, lng: 17.8422971 },
    { lat: 48.0241709, lng: 17.8424307 },
    { lat: 48.0243879, lng: 17.8423968 },
    { lat: 48.0246049, lng: 17.8421239 },
    { lat: 48.0247361, lng: 17.8418196 },
    { lat: 48.0249241, lng: 17.8409441 },
    { lat: 48.0251094, lng: 17.8395142 },
    { lat: 48.0252764, lng: 17.8387798 },
    { lat: 48.0256815, lng: 17.8374926 },
    { lat: 48.0263069, lng: 17.8358414 },
    { lat: 48.0265987, lng: 17.8352051 },
    { lat: 48.0268683, lng: 17.8347807 },
    { lat: 48.0274882, lng: 17.8343648 },
    { lat: 48.0279046, lng: 17.8343759 },
    { lat: 48.0280925, lng: 17.8344775 },
    { lat: 48.0283256, lng: 17.8346876 },
    { lat: 48.0285324, lng: 17.8352063 },
    { lat: 48.0285221, lng: 17.8359398 },
    { lat: 48.0281461, lng: 17.8374802 },
    { lat: 48.0278689, lng: 17.8393424 },
    { lat: 48.0277973, lng: 17.8403237 },
    { lat: 48.0277641, lng: 17.8418778 },
    { lat: 48.0278736, lng: 17.8430513 },
    { lat: 48.0279839, lng: 17.8435063 },
    { lat: 48.0280772, lng: 17.8438124 },
    { lat: 48.028464, lng: 17.8446709 },
    { lat: 48.0285421, lng: 17.844729 },
    { lat: 48.0288794, lng: 17.8446515 },
    { lat: 48.0291184, lng: 17.8443332 },
    { lat: 48.0291823, lng: 17.8441228 },
    { lat: 48.0291314, lng: 17.8436991 },
    { lat: 48.0284869, lng: 17.8426925 },
    { lat: 48.028365, lng: 17.8422859 },
    { lat: 48.0282918, lng: 17.8417916 },
    { lat: 48.0283233, lng: 17.841423 },
    { lat: 48.0284747, lng: 17.8408762 },
    { lat: 48.0289368, lng: 17.8400012 },
    { lat: 48.0294828, lng: 17.8393704 },
    { lat: 48.0298167, lng: 17.8393498 },
    { lat: 48.0300807, lng: 17.8395843 },
    { lat: 48.0302003, lng: 17.8398014 },
    { lat: 48.0304699, lng: 17.8406279 },
    { lat: 48.030621, lng: 17.8409148 },
    { lat: 48.0307867, lng: 17.8410794 },
    { lat: 48.030994, lng: 17.8412183 },
    { lat: 48.0315448, lng: 17.841362 },
    { lat: 48.0320107, lng: 17.8412684 },
    { lat: 48.0322796, lng: 17.8409587 },
    { lat: 48.0324332, lng: 17.8404457 },
    { lat: 48.0322863, lng: 17.8396909 },
    { lat: 48.0322575, lng: 17.8391683 },
    { lat: 48.0324185, lng: 17.8386098 },
    { lat: 48.0325879, lng: 17.8382252 },
    { lat: 48.0329358, lng: 17.8376584 },
    { lat: 48.0334233, lng: 17.837122 },
    { lat: 48.0339918, lng: 17.836712 },
    { lat: 48.0342293, lng: 17.836608 },
    { lat: 48.034919, lng: 17.8364795 },
    { lat: 48.035587, lng: 17.8367673 },
    { lat: 48.0391082, lng: 17.8405155 },
    { lat: 48.0395221, lng: 17.8406952 },
    { lat: 48.0398223, lng: 17.8407103 },
    { lat: 48.0402427, lng: 17.8406458 },
    { lat: 48.0407045, lng: 17.8403721 },
    { lat: 48.0410796, lng: 17.8400101 },
    { lat: 48.0411807, lng: 17.8393366 },
    { lat: 48.0407192, lng: 17.8377989 },
    { lat: 48.0405908, lng: 17.8374916 },
    { lat: 48.0405547, lng: 17.8369431 },
    { lat: 48.0406445, lng: 17.8364955 },
    { lat: 48.0408781, lng: 17.8359242 },
    { lat: 48.041074, lng: 17.8356593 },
    { lat: 48.0413191, lng: 17.8355665 },
    { lat: 48.0419426, lng: 17.8359395 },
    { lat: 48.0422542, lng: 17.8359981 },
    { lat: 48.0428295, lng: 17.8358436 },
    { lat: 48.0431003, lng: 17.8355462 },
    { lat: 48.0433522, lng: 17.8349999 },
    { lat: 48.0437161, lng: 17.8338657 },
    { lat: 48.0441855, lng: 17.8326867 },
    { lat: 48.0454661, lng: 17.8288252 },
    { lat: 48.0459416, lng: 17.8271778 },
    { lat: 48.04611, lng: 17.8267777 },
    { lat: 48.0464563, lng: 17.8262049 },
    { lat: 48.0463726, lng: 17.825617 },
    { lat: 48.0469821, lng: 17.8252654 },
    { lat: 48.0486807, lng: 17.8246368 },
    { lat: 48.0452893, lng: 17.8038246 },
    { lat: 48.0450228, lng: 17.8035311 },
    { lat: 48.0450624, lng: 17.802952 },
    { lat: 48.0448389, lng: 17.8025463 },
    { lat: 48.0441867, lng: 17.8019681 },
    { lat: 48.043705, lng: 17.8023052 },
    { lat: 48.0432642, lng: 17.8021129 },
    { lat: 48.0427166, lng: 17.802477 },
    { lat: 48.0426238, lng: 17.8024379 },
    { lat: 48.0424397, lng: 17.8017055 },
    { lat: 48.0423103, lng: 17.8014922 },
    { lat: 48.042095, lng: 17.8013345 },
    { lat: 48.0419601, lng: 17.8009233 },
    { lat: 48.0423964, lng: 17.7989958 },
    { lat: 48.0424079, lng: 17.7986689 },
    { lat: 48.042499, lng: 17.7981727 },
    { lat: 48.0423402, lng: 17.7979949 },
    { lat: 48.0421347, lng: 17.7978129 },
    { lat: 48.0418754, lng: 17.797724 },
    { lat: 48.0408536, lng: 17.7977816 },
    { lat: 48.0398923, lng: 17.7976609 },
    { lat: 48.0394789, lng: 17.7979118 },
    { lat: 48.0393033, lng: 17.7977571 },
    { lat: 48.038551, lng: 17.7956692 },
    { lat: 48.0383773, lng: 17.7953245 },
    { lat: 48.0385926, lng: 17.7944454 },
    { lat: 48.0386037, lng: 17.7938746 },
    { lat: 48.0385401, lng: 17.7935771 },
    { lat: 48.0383778, lng: 17.7933815 },
    { lat: 48.0380709, lng: 17.792635 },
    { lat: 48.0378065, lng: 17.7922667 },
    { lat: 48.0380483, lng: 17.7916856 },
    { lat: 48.0381363, lng: 17.7912848 },
    { lat: 48.0375297, lng: 17.7899305 },
    { lat: 48.0374264, lng: 17.789592 },
    { lat: 48.0367271, lng: 17.7883284 },
    { lat: 48.0368887, lng: 17.7877622 },
    { lat: 48.0342964, lng: 17.7853538 },
    { lat: 48.0342087, lng: 17.785252 },
    { lat: 48.0338833, lng: 17.7860872 },
    { lat: 48.0339537, lng: 17.7870059 },
    { lat: 48.0339251, lng: 17.7887664 },
    { lat: 48.0337185, lng: 17.7899687 },
    { lat: 48.0335775, lng: 17.7905532 },
    { lat: 48.0327514, lng: 17.7928382 },
    { lat: 48.0324805, lng: 17.7926212 },
    { lat: 48.0313773, lng: 17.7912136 },
    { lat: 48.0281028, lng: 17.7861014 },
    { lat: 48.0265678, lng: 17.7838127 },
    { lat: 48.024086, lng: 17.780668 },
    { lat: 48.02399, lng: 17.780711 },
    { lat: 48.023719, lng: 17.781047 },
    { lat: 48.023409, lng: 17.781257 },
    { lat: 48.023369, lng: 17.781277 },
    { lat: 48.023215, lng: 17.781334 },
    { lat: 48.022705, lng: 17.781802 },
    { lat: 48.022537, lng: 17.781808 },
    { lat: 48.022049, lng: 17.781643 },
    { lat: 48.021928, lng: 17.781642 },
    { lat: 48.021702, lng: 17.781717 },
    { lat: 48.021562, lng: 17.781774 },
    { lat: 48.021281, lng: 17.781798 },
    { lat: 48.021075, lng: 17.781735 },
    { lat: 48.020923, lng: 17.78163 },
    { lat: 48.020758, lng: 17.78154 },
    { lat: 48.020432, lng: 17.781564 },
    { lat: 48.020298, lng: 17.78161 },
    { lat: 48.020125, lng: 17.781925 },
    { lat: 48.019772, lng: 17.782409 },
    { lat: 48.019599, lng: 17.782795 },
    { lat: 48.019469, lng: 17.78329 },
    { lat: 48.019444, lng: 17.783615 },
    { lat: 48.019281, lng: 17.784547 },
    { lat: 48.019168, lng: 17.784669 },
    { lat: 48.019087, lng: 17.784775 },
    { lat: 48.019081, lng: 17.78481 },
    { lat: 48.019039, lng: 17.785092 },
    { lat: 48.018957, lng: 17.78552 },
    { lat: 48.018922, lng: 17.785981 },
    { lat: 48.018994, lng: 17.786405 },
    { lat: 48.019202, lng: 17.787085 },
    { lat: 48.01937, lng: 17.787459 },
    { lat: 48.019636, lng: 17.787806 },
    { lat: 48.019675, lng: 17.787904 },
    { lat: 48.019755, lng: 17.78832 },
    { lat: 48.019744, lng: 17.788693 },
    { lat: 48.019661, lng: 17.789035 },
    { lat: 48.019619, lng: 17.789324 },
    { lat: 48.019618, lng: 17.789458 },
    { lat: 48.019073, lng: 17.788892 },
    { lat: 48.018646, lng: 17.788463 },
    { lat: 48.018109, lng: 17.787894 },
    { lat: 48.018051, lng: 17.787837 },
    { lat: 48.017992, lng: 17.788043 },
    { lat: 48.017957, lng: 17.788145 },
    { lat: 48.018326, lng: 17.789151 },
    { lat: 48.018317, lng: 17.789172 },
    { lat: 48.018359, lng: 17.789218 },
    { lat: 48.018586, lng: 17.789886 },
    { lat: 48.018504, lng: 17.789969 },
    { lat: 48.018478, lng: 17.789991 },
    { lat: 48.01862, lng: 17.790383 },
    { lat: 48.018671, lng: 17.790601 },
    { lat: 48.018739, lng: 17.790932 },
    { lat: 48.01874, lng: 17.790962 },
    { lat: 48.01872, lng: 17.791093 },
    { lat: 48.018723, lng: 17.791204 },
    { lat: 48.01872, lng: 17.791576 },
    { lat: 48.018717, lng: 17.791954 },
    { lat: 48.018687, lng: 17.792227 },
    { lat: 48.01863, lng: 17.792635 },
    { lat: 48.018599, lng: 17.792843 },
    { lat: 48.018568, lng: 17.793047 },
    { lat: 48.018503, lng: 17.793308 },
    { lat: 48.018441, lng: 17.793573 },
    { lat: 48.018369, lng: 17.793797 },
    { lat: 48.018295, lng: 17.794032 },
    { lat: 48.018284, lng: 17.794053 },
    { lat: 48.018222, lng: 17.794226 },
    { lat: 48.018146, lng: 17.7944 },
    { lat: 48.018037, lng: 17.794633 },
    { lat: 48.017933, lng: 17.794846 },
    { lat: 48.017869, lng: 17.794953 },
    { lat: 48.017815, lng: 17.795055 },
    { lat: 48.017746, lng: 17.795173 },
    { lat: 48.017698, lng: 17.795258 },
    { lat: 48.017457, lng: 17.795534 },
    { lat: 48.017301, lng: 17.79571 },
    { lat: 48.017084, lng: 17.795943 },
    { lat: 48.016979, lng: 17.796058 },
    { lat: 48.0169, lng: 17.796136 },
    { lat: 48.016841, lng: 17.796177 },
    { lat: 48.016726, lng: 17.796249 },
    { lat: 48.016417, lng: 17.796446 },
    { lat: 48.016177, lng: 17.796551 },
    { lat: 48.016183, lng: 17.796758 },
    { lat: 48.015447, lng: 17.796891 },
    { lat: 48.015377, lng: 17.796899 },
    { lat: 48.015213, lng: 17.796921 },
    { lat: 48.015043, lng: 17.796945 },
    { lat: 48.014717, lng: 17.796987 },
    { lat: 48.014542, lng: 17.796971 },
    { lat: 48.014034, lng: 17.796924 },
    { lat: 48.0136569, lng: 17.7967705 },
    { lat: 48.013641, lng: 17.796764 },
    { lat: 48.013621, lng: 17.7969 },
    { lat: 48.013765, lng: 17.797202 },
    { lat: 48.013652, lng: 17.797323 },
    { lat: 48.013539, lng: 17.797446 },
    { lat: 48.013414, lng: 17.797571 },
    { lat: 48.013292, lng: 17.797704 },
    { lat: 48.01311, lng: 17.797892 },
    { lat: 48.013011, lng: 17.798029 },
    { lat: 48.012975, lng: 17.79807 },
    { lat: 48.012904, lng: 17.798197 },
    { lat: 48.01281, lng: 17.79835 },
    { lat: 48.012713, lng: 17.798488 },
    { lat: 48.012609, lng: 17.798641 },
    { lat: 48.012505, lng: 17.798789 },
    { lat: 48.012404, lng: 17.798941 },
    { lat: 48.012293, lng: 17.799109 },
    { lat: 48.012245, lng: 17.799182 },
    { lat: 48.012128, lng: 17.799314 },
    { lat: 48.012, lng: 17.799437 },
    { lat: 48.011883, lng: 17.79955 },
    { lat: 48.011764, lng: 17.799672 },
    { lat: 48.01164, lng: 17.799791 },
    { lat: 48.011523, lng: 17.79991 },
    { lat: 48.011406, lng: 17.800026 },
    { lat: 48.011283, lng: 17.800145 },
    { lat: 48.011166, lng: 17.800266 },
    { lat: 48.011045, lng: 17.800381 },
    { lat: 48.01092, lng: 17.800503 },
    { lat: 48.010807, lng: 17.800618 },
    { lat: 48.010686, lng: 17.800735 },
    { lat: 48.010567, lng: 17.800853 },
    { lat: 48.010443, lng: 17.800971 },
    { lat: 48.010325, lng: 17.801088 },
    { lat: 48.0102, lng: 17.801207 },
    { lat: 48.010081, lng: 17.801322 },
    { lat: 48.00996, lng: 17.80144 },
    { lat: 48.009839, lng: 17.801558 },
    { lat: 48.009722, lng: 17.801673 },
    { lat: 48.009606, lng: 17.801764 },
    { lat: 48.00948, lng: 17.801859 },
    { lat: 48.009334, lng: 17.801963 },
    { lat: 48.009208, lng: 17.80206 },
    { lat: 48.00908, lng: 17.802157 },
    { lat: 48.008953, lng: 17.802253 },
    { lat: 48.008822, lng: 17.802351 },
    { lat: 48.008696, lng: 17.802446 },
    { lat: 48.008567, lng: 17.802547 },
    { lat: 48.008439, lng: 17.802648 },
    { lat: 48.008313, lng: 17.802741 },
    { lat: 48.008181, lng: 17.802843 },
    { lat: 48.008059, lng: 17.802935 },
    { lat: 48.00794, lng: 17.802987 },
    { lat: 48.007812, lng: 17.803044 },
    { lat: 48.0078, lng: 17.802963 },
    { lat: 48.007742, lng: 17.802682 },
    { lat: 48.00773, lng: 17.802636 },
    { lat: 48.008021, lng: 17.801446 },
    { lat: 48.007924, lng: 17.801388 },
    { lat: 48.007634, lng: 17.802272 },
    { lat: 48.006911, lng: 17.803592 },
    { lat: 48.007176, lng: 17.804081 },
    { lat: 48.00681, lng: 17.804261 },
    { lat: 48.006409, lng: 17.804489 },
    { lat: 48.005798, lng: 17.804899 },
    { lat: 48.00581, lng: 17.804941 },
    { lat: 48.00584, lng: 17.805037 },
    { lat: 48.005857, lng: 17.805089 },
    { lat: 48.006636, lng: 17.807618 },
    { lat: 48.00729, lng: 17.809742 },
    { lat: 48.007332, lng: 17.809879 },
    { lat: 48.007229, lng: 17.809951 },
    { lat: 48.007045, lng: 17.810036 },
    { lat: 48.006762, lng: 17.810195 },
    { lat: 48.006332, lng: 17.810402 },
    { lat: 48.006239, lng: 17.810468 },
    { lat: 48.006137, lng: 17.810589 },
    { lat: 48.006017, lng: 17.810796 },
    { lat: 48.00592, lng: 17.811075 },
    { lat: 48.005856, lng: 17.811346 },
    { lat: 48.005788, lng: 17.811616 },
    { lat: 48.005687, lng: 17.811913 },
    { lat: 48.005565, lng: 17.812156 },
    { lat: 48.005278, lng: 17.812498 },
    { lat: 48.005443, lng: 17.812787 },
    { lat: 48.005708, lng: 17.813301 },
    { lat: 48.006247, lng: 17.814337 },
    { lat: 48.005764, lng: 17.814739 },
    { lat: 48.005209, lng: 17.815331 },
    { lat: 48.004829, lng: 17.815645 },
    { lat: 48.004562, lng: 17.815828 },
    { lat: 48.00432, lng: 17.815931 },
    { lat: 48.004115, lng: 17.815811 },
    { lat: 48.00408, lng: 17.815787 },
    { lat: 48.003994, lng: 17.816016 },
    { lat: 48.003794, lng: 17.816272 },
    { lat: 48.003534, lng: 17.816469 },
    { lat: 48.003256, lng: 17.81658 },
    { lat: 48.002762, lng: 17.816558 },
    { lat: 48.002504, lng: 17.816575 },
    { lat: 48.002204, lng: 17.816625 },
    { lat: 48.001757, lng: 17.816601 },
    { lat: 48.001229, lng: 17.81658 },
    { lat: 48.000922, lng: 17.816704 },
    { lat: 48.000725, lng: 17.816849 },
    { lat: 48.000475, lng: 17.81695 },
    { lat: 48.000206, lng: 17.816994 },
    { lat: 47.999868, lng: 17.817046 },
    { lat: 47.999597, lng: 17.817185 },
  ],
  Topoľnica: [
    { lat: 48.1836009, lng: 17.7441964 },
    { lat: 48.1821303, lng: 17.7457115 },
    { lat: 48.1806243, lng: 17.7470198 },
    { lat: 48.1784974, lng: 17.7497511 },
    { lat: 48.1778151, lng: 17.750582 },
    { lat: 48.1771022, lng: 17.7513393 },
    { lat: 48.1735446, lng: 17.7546985 },
    { lat: 48.172815, lng: 17.7552782 },
    { lat: 48.1709449, lng: 17.7570373 },
    { lat: 48.1709862, lng: 17.7582291 },
    { lat: 48.171222, lng: 17.7595171 },
    { lat: 48.1712523, lng: 17.760772 },
    { lat: 48.1711027, lng: 17.7633464 },
    { lat: 48.1710102, lng: 17.7673586 },
    { lat: 48.1711576, lng: 17.7685687 },
    { lat: 48.1717261, lng: 17.770165 },
    { lat: 48.170544, lng: 17.7714771 },
    { lat: 48.1715872, lng: 17.7741035 },
    { lat: 48.171539, lng: 17.7747655 },
    { lat: 48.1712157, lng: 17.7754323 },
    { lat: 48.1711084, lng: 17.7759041 },
    { lat: 48.1704733, lng: 17.7768281 },
    { lat: 48.1702291, lng: 17.7780615 },
    { lat: 48.1700611, lng: 17.7792511 },
    { lat: 48.1699598, lng: 17.7806816 },
    { lat: 48.1695906, lng: 17.7835683 },
    { lat: 48.1686771, lng: 17.7867048 },
    { lat: 48.1676935, lng: 17.7889654 },
    { lat: 48.1675133, lng: 17.7892845 },
    { lat: 48.1616482, lng: 17.7797134 },
    { lat: 48.1619664, lng: 17.7747104 },
    { lat: 48.15811, lng: 17.778261 },
    { lat: 48.157515, lng: 17.781536 },
    { lat: 48.157471, lng: 17.781775 },
    { lat: 48.157256, lng: 17.782711 },
    { lat: 48.157113, lng: 17.783333 },
    { lat: 48.156986, lng: 17.784474 },
    { lat: 48.156691, lng: 17.786368 },
    { lat: 48.15645, lng: 17.788134 },
    { lat: 48.156185, lng: 17.790094 },
    { lat: 48.156619, lng: 17.791679 },
    { lat: 48.156874, lng: 17.793081 },
    { lat: 48.156936, lng: 17.793468 },
    { lat: 48.157171, lng: 17.794916 },
    { lat: 48.157148, lng: 17.794991 },
    { lat: 48.156126, lng: 17.795323 },
    { lat: 48.155166, lng: 17.795016 },
    { lat: 48.154209, lng: 17.794802 },
    { lat: 48.152495, lng: 17.795337 },
    { lat: 48.152403, lng: 17.79541 },
    { lat: 48.152314, lng: 17.795497 },
    { lat: 48.152059, lng: 17.79572 },
    { lat: 48.152007, lng: 17.795769 },
    { lat: 48.15175, lng: 17.795997 },
    { lat: 48.15125, lng: 17.796657 },
    { lat: 48.15109, lng: 17.796869 },
    { lat: 48.150814, lng: 17.797233 },
    { lat: 48.14965, lng: 17.79843 },
    { lat: 48.148767, lng: 17.799306 },
    { lat: 48.148569, lng: 17.799888 },
    { lat: 48.148535, lng: 17.79999 },
    { lat: 48.148496, lng: 17.800104 },
    { lat: 48.148482, lng: 17.800144 },
    { lat: 48.148467, lng: 17.800189 },
    { lat: 48.148471, lng: 17.800206 },
    { lat: 48.148484, lng: 17.80025 },
    { lat: 48.149609, lng: 17.804049 },
    { lat: 48.14969, lng: 17.80426 },
    { lat: 48.149708, lng: 17.804304 },
    { lat: 48.150675, lng: 17.806809 },
    { lat: 48.152441, lng: 17.80998 },
    { lat: 48.152804, lng: 17.810758 },
    { lat: 48.153864, lng: 17.813017 },
    { lat: 48.155417, lng: 17.816346 },
    { lat: 48.155884, lng: 17.815999 },
    { lat: 48.156061, lng: 17.815867 },
    { lat: 48.157635, lng: 17.814343 },
    { lat: 48.157668, lng: 17.814372 },
    { lat: 48.157703, lng: 17.814402 },
    { lat: 48.158714, lng: 17.815317 },
    { lat: 48.158955, lng: 17.81645 },
    { lat: 48.158898, lng: 17.817764 },
    { lat: 48.158954, lng: 17.817886 },
    { lat: 48.159365, lng: 17.817602 },
    { lat: 48.15961, lng: 17.817544 },
    { lat: 48.159651, lng: 17.817559 },
    { lat: 48.160152, lng: 17.817736 },
    { lat: 48.160176, lng: 17.817744 },
    { lat: 48.16028, lng: 17.817781 },
    { lat: 48.160646, lng: 17.81748 },
    { lat: 48.161006, lng: 17.817271 },
    { lat: 48.161263, lng: 17.817409 },
    { lat: 48.161468, lng: 17.817909 },
    { lat: 48.162317, lng: 17.818706 },
    { lat: 48.162926, lng: 17.81918 },
    { lat: 48.162944, lng: 17.819194 },
    { lat: 48.162951, lng: 17.819184 },
    { lat: 48.163115, lng: 17.818978 },
    { lat: 48.163628, lng: 17.820006 },
    { lat: 48.163912, lng: 17.820325 },
    { lat: 48.164, lng: 17.820387 },
    { lat: 48.164453, lng: 17.820641 },
    { lat: 48.164556, lng: 17.820706 },
    { lat: 48.164861, lng: 17.820709 },
    { lat: 48.165111, lng: 17.820711 },
    { lat: 48.1666918, lng: 17.8171073 },
    { lat: 48.1680662, lng: 17.814678 },
    { lat: 48.1683364, lng: 17.8144435 },
    { lat: 48.1691108, lng: 17.8140388 },
    { lat: 48.1691981, lng: 17.8141577 },
    { lat: 48.1702081, lng: 17.812776 },
    { lat: 48.1704638, lng: 17.8127093 },
    { lat: 48.1709321, lng: 17.812816 },
    { lat: 48.1716302, lng: 17.8128427 },
    { lat: 48.1722664, lng: 17.8125211 },
    { lat: 48.1725865, lng: 17.8122758 },
    { lat: 48.1728896, lng: 17.8118263 },
    { lat: 48.1731355, lng: 17.810464 },
    { lat: 48.1734227, lng: 17.8095725 },
    { lat: 48.173509, lng: 17.8086997 },
    { lat: 48.1735945, lng: 17.8086066 },
    { lat: 48.173912, lng: 17.8084257 },
    { lat: 48.1742297, lng: 17.808453 },
    { lat: 48.1746597, lng: 17.8083786 },
    { lat: 48.1750345, lng: 17.8087988 },
    { lat: 48.1751698, lng: 17.8087788 },
    { lat: 48.1752919, lng: 17.808652 },
    { lat: 48.175467, lng: 17.8083605 },
    { lat: 48.1763386, lng: 17.8063662 },
    { lat: 48.1768784, lng: 17.8047705 },
    { lat: 48.1780221, lng: 17.8021709 },
    { lat: 48.1789138, lng: 17.8003183 },
    { lat: 48.178638, lng: 17.7885494 },
    { lat: 48.1799304, lng: 17.7788653 },
    { lat: 48.18209, lng: 17.7761983 },
    { lat: 48.182284, lng: 17.7756663 },
    { lat: 48.1824154, lng: 17.7754921 },
    { lat: 48.1832739, lng: 17.7747875 },
    { lat: 48.1835235, lng: 17.7744972 },
    { lat: 48.184014, lng: 17.7734472 },
    { lat: 48.1841371, lng: 17.7730474 },
    { lat: 48.1847925, lng: 17.7715239 },
    { lat: 48.1849175, lng: 17.7711001 },
    { lat: 48.1850211, lng: 17.7704016 },
    { lat: 48.1852914, lng: 17.7661966 },
    { lat: 48.1854998, lng: 17.7640404 },
    { lat: 48.1857863, lng: 17.7621048 },
    { lat: 48.1872265, lng: 17.7584591 },
    { lat: 48.1883071, lng: 17.7551268 },
    { lat: 48.1892225, lng: 17.7530308 },
    { lat: 48.189487, lng: 17.7519641 },
    { lat: 48.1874914, lng: 17.7496435 },
    { lat: 48.1863827, lng: 17.747974 },
    { lat: 48.1838758, lng: 17.7446224 },
    { lat: 48.1836009, lng: 17.7441964 },
  ],
  Kajal: [
    { lat: 48.165111, lng: 17.820711 },
    { lat: 48.165542, lng: 17.820717 },
    { lat: 48.165835, lng: 17.82085 },
    { lat: 48.165993, lng: 17.821012 },
    { lat: 48.166025, lng: 17.821042 },
    { lat: 48.166069, lng: 17.821103 },
    { lat: 48.166113, lng: 17.821194 },
    { lat: 48.166121, lng: 17.821247 },
    { lat: 48.166142, lng: 17.8214 },
    { lat: 48.166284, lng: 17.821838 },
    { lat: 48.166552, lng: 17.822336 },
    { lat: 48.166643, lng: 17.822644 },
    { lat: 48.166642, lng: 17.822861 },
    { lat: 48.166602, lng: 17.823046 },
    { lat: 48.166372, lng: 17.823394 },
    { lat: 48.166241, lng: 17.823593 },
    { lat: 48.166018, lng: 17.824015 },
    { lat: 48.16591, lng: 17.824341 },
    { lat: 48.165907, lng: 17.824437 },
    { lat: 48.165904, lng: 17.824477 },
    { lat: 48.165891, lng: 17.824673 },
    { lat: 48.16589, lng: 17.824702 },
    { lat: 48.165884, lng: 17.824774 },
    { lat: 48.165955, lng: 17.825305 },
    { lat: 48.166036, lng: 17.825638 },
    { lat: 48.1662, lng: 17.825982 },
    { lat: 48.166442, lng: 17.826611 },
    { lat: 48.166924, lng: 17.82809 },
    { lat: 48.167187, lng: 17.828965 },
    { lat: 48.167391, lng: 17.828601 },
    { lat: 48.167623, lng: 17.828183 },
    { lat: 48.167891, lng: 17.827698 },
    { lat: 48.168379, lng: 17.826883 },
    { lat: 48.169069, lng: 17.825795 },
    { lat: 48.170228, lng: 17.823798 },
    { lat: 48.170905, lng: 17.82275 },
    { lat: 48.172207, lng: 17.820843 },
    { lat: 48.173681, lng: 17.818838 },
    { lat: 48.174944, lng: 17.817167 },
    { lat: 48.176468, lng: 17.815104 },
    { lat: 48.177548, lng: 17.813623 },
    { lat: 48.177571, lng: 17.81359 },
    { lat: 48.177678, lng: 17.813444 },
    { lat: 48.177693, lng: 17.813423 },
    { lat: 48.177722, lng: 17.813384 },
    { lat: 48.177741, lng: 17.813356 },
    { lat: 48.177755, lng: 17.813337 },
    { lat: 48.177856, lng: 17.8132 },
    { lat: 48.178618, lng: 17.812322 },
    { lat: 48.178967, lng: 17.811779 },
    { lat: 48.179038, lng: 17.811668 },
    { lat: 48.179218, lng: 17.811389 },
    { lat: 48.179373, lng: 17.811147 },
    { lat: 48.179387, lng: 17.811124 },
    { lat: 48.179591, lng: 17.810807 },
    { lat: 48.180001, lng: 17.809713 },
    { lat: 48.181013, lng: 17.808184 },
    { lat: 48.181781, lng: 17.807132 },
    { lat: 48.182171, lng: 17.806727 },
    { lat: 48.18315, lng: 17.805535 },
    { lat: 48.183569, lng: 17.804878 },
    { lat: 48.184025, lng: 17.804033 },
    { lat: 48.184164, lng: 17.803776 },
    { lat: 48.184445, lng: 17.803059 },
    { lat: 48.18456, lng: 17.802759 },
    { lat: 48.184595, lng: 17.802667 },
    { lat: 48.185137, lng: 17.801601 },
    { lat: 48.185394, lng: 17.801106 },
    { lat: 48.185737, lng: 17.800565 },
    { lat: 48.18578, lng: 17.800572 },
    { lat: 48.186076, lng: 17.800618 },
    { lat: 48.186316, lng: 17.800638 },
    { lat: 48.186512, lng: 17.800677 },
    { lat: 48.186624, lng: 17.800718 },
    { lat: 48.186757, lng: 17.800768 },
    { lat: 48.1869, lng: 17.800795 },
    { lat: 48.186946, lng: 17.80081 },
    { lat: 48.186995, lng: 17.800816 },
    { lat: 48.18704, lng: 17.800821 },
    { lat: 48.187473, lng: 17.800873 },
    { lat: 48.188036, lng: 17.800904 },
    { lat: 48.189327, lng: 17.800952 },
    { lat: 48.190431, lng: 17.801122 },
    { lat: 48.19099, lng: 17.80111 },
    { lat: 48.1913, lng: 17.801026 },
    { lat: 48.191348, lng: 17.800996 },
    { lat: 48.191361, lng: 17.800988 },
    { lat: 48.191409, lng: 17.801015 },
    { lat: 48.191703, lng: 17.801591 },
    { lat: 48.191934, lng: 17.801975 },
    { lat: 48.192112, lng: 17.802288 },
    { lat: 48.192298, lng: 17.80262 },
    { lat: 48.192728, lng: 17.803456 },
    { lat: 48.193224, lng: 17.804162 },
    { lat: 48.19357, lng: 17.804591 },
    { lat: 48.193924, lng: 17.805116 },
    { lat: 48.194211, lng: 17.805684 },
    { lat: 48.194497, lng: 17.806073 },
    { lat: 48.1946, lng: 17.806185 },
    { lat: 48.194897, lng: 17.806509 },
    { lat: 48.195163, lng: 17.806841 },
    { lat: 48.195416, lng: 17.807159 },
    { lat: 48.19531, lng: 17.807472 },
    { lat: 48.195148, lng: 17.807978 },
    { lat: 48.195002, lng: 17.808442 },
    { lat: 48.194955, lng: 17.808993 },
    { lat: 48.194903, lng: 17.809498 },
    { lat: 48.194853, lng: 17.810004 },
    { lat: 48.194805, lng: 17.810512 },
    { lat: 48.194799, lng: 17.811017 },
    { lat: 48.194804, lng: 17.811539 },
    { lat: 48.194866, lng: 17.811786 },
    { lat: 48.195005, lng: 17.812244 },
    { lat: 48.195402, lng: 17.813024 },
    { lat: 48.195676, lng: 17.813515 },
    { lat: 48.195917, lng: 17.813947 },
    { lat: 48.196147, lng: 17.814302 },
    { lat: 48.196182, lng: 17.814347 },
    { lat: 48.196373, lng: 17.814586 },
    { lat: 48.196449, lng: 17.814681 },
    { lat: 48.19656, lng: 17.814823 },
    { lat: 48.196584, lng: 17.814853 },
    { lat: 48.196785, lng: 17.815112 },
    { lat: 48.196833, lng: 17.815175 },
    { lat: 48.19713, lng: 17.815451 },
    { lat: 48.197738, lng: 17.816008 },
    { lat: 48.198199, lng: 17.816355 },
    { lat: 48.198331, lng: 17.816452 },
    { lat: 48.19861, lng: 17.817153 },
    { lat: 48.20105, lng: 17.820477 },
    { lat: 48.2110787, lng: 17.8152827 },
    { lat: 48.2209294, lng: 17.8124503 },
    { lat: 48.2199622, lng: 17.8054443 },
    { lat: 48.2201323, lng: 17.8050184 },
    { lat: 48.2202834, lng: 17.8044287 },
    { lat: 48.2209666, lng: 17.80124 },
    { lat: 48.2211094, lng: 17.7996039 },
    { lat: 48.2210802, lng: 17.7991636 },
    { lat: 48.2208132, lng: 17.7980375 },
    { lat: 48.2205466, lng: 17.7972654 },
    { lat: 48.2200264, lng: 17.7961148 },
    { lat: 48.2182842, lng: 17.7940455 },
    { lat: 48.2172579, lng: 17.7924866 },
    { lat: 48.2164638, lng: 17.7918947 },
    { lat: 48.2158794, lng: 17.7910568 },
    { lat: 48.2152297, lng: 17.789932 },
    { lat: 48.2125172, lng: 17.7858787 },
    { lat: 48.2145331, lng: 17.7821254 },
    { lat: 48.2161057, lng: 17.7786154 },
    { lat: 48.2165334, lng: 17.7772606 },
    { lat: 48.2154745, lng: 17.7753827 },
    { lat: 48.215371, lng: 17.7737475 },
    { lat: 48.2153543, lng: 17.7725278 },
    { lat: 48.2153473, lng: 17.7703503 },
    { lat: 48.2124203, lng: 17.7731525 },
    { lat: 48.2115958, lng: 17.7739672 },
    { lat: 48.2111535, lng: 17.7744812 },
    { lat: 48.2091892, lng: 17.7761831 },
    { lat: 48.2085261, lng: 17.7775076 },
    { lat: 48.2082577, lng: 17.7782455 },
    { lat: 48.207724, lng: 17.7807328 },
    { lat: 48.2052294, lng: 17.7827671 },
    { lat: 48.2032216, lng: 17.7848623 },
    { lat: 48.2023191, lng: 17.7861354 },
    { lat: 48.1997655, lng: 17.7901442 },
    { lat: 48.1990447, lng: 17.7911411 },
    { lat: 48.1983591, lng: 17.7886108 },
    { lat: 48.1978691, lng: 17.7865996 },
    { lat: 48.1975008, lng: 17.7854084 },
    { lat: 48.1967188, lng: 17.783128 },
    { lat: 48.1961407, lng: 17.7819394 },
    { lat: 48.1973977, lng: 17.7796088 },
    { lat: 48.1976374, lng: 17.7789592 },
    { lat: 48.1975286, lng: 17.7773794 },
    { lat: 48.1976129, lng: 17.7745075 },
    { lat: 48.1979923, lng: 17.7724457 },
    { lat: 48.1978877, lng: 17.7699718 },
    { lat: 48.1976193, lng: 17.7681172 },
    { lat: 48.1975089, lng: 17.7665376 },
    { lat: 48.1974373, lng: 17.7638691 },
    { lat: 48.1966065, lng: 17.7609195 },
    { lat: 48.1964793, lng: 17.7594849 },
    { lat: 48.1958746, lng: 17.7572003 },
    { lat: 48.1955247, lng: 17.7565447 },
    { lat: 48.1941939, lng: 17.754458 },
    { lat: 48.1938388, lng: 17.7527193 },
    { lat: 48.1936533, lng: 17.7520215 },
    { lat: 48.1933753, lng: 17.7514192 },
    { lat: 48.1915372, lng: 17.7497266 },
    { lat: 48.1917567, lng: 17.7491693 },
    { lat: 48.1910835, lng: 17.7476015 },
    { lat: 48.1905764, lng: 17.748651 },
    { lat: 48.1902762, lng: 17.7494029 },
    { lat: 48.1894918, lng: 17.7519507 },
    { lat: 48.189487, lng: 17.7519641 },
    { lat: 48.1892225, lng: 17.7530308 },
    { lat: 48.1883071, lng: 17.7551268 },
    { lat: 48.1872265, lng: 17.7584591 },
    { lat: 48.1857863, lng: 17.7621048 },
    { lat: 48.1854998, lng: 17.7640404 },
    { lat: 48.1852914, lng: 17.7661966 },
    { lat: 48.1850211, lng: 17.7704016 },
    { lat: 48.1849175, lng: 17.7711001 },
    { lat: 48.1847925, lng: 17.7715239 },
    { lat: 48.1841371, lng: 17.7730474 },
    { lat: 48.184014, lng: 17.7734472 },
    { lat: 48.1835235, lng: 17.7744972 },
    { lat: 48.1832739, lng: 17.7747875 },
    { lat: 48.1824154, lng: 17.7754921 },
    { lat: 48.182284, lng: 17.7756663 },
    { lat: 48.18209, lng: 17.7761983 },
    { lat: 48.1799304, lng: 17.7788653 },
    { lat: 48.178638, lng: 17.7885494 },
    { lat: 48.1789138, lng: 17.8003183 },
    { lat: 48.1780221, lng: 17.8021709 },
    { lat: 48.1768784, lng: 17.8047705 },
    { lat: 48.1763386, lng: 17.8063662 },
    { lat: 48.175467, lng: 17.8083605 },
    { lat: 48.1752919, lng: 17.808652 },
    { lat: 48.1751698, lng: 17.8087788 },
    { lat: 48.1750345, lng: 17.8087988 },
    { lat: 48.1746597, lng: 17.8083786 },
    { lat: 48.1742297, lng: 17.808453 },
    { lat: 48.173912, lng: 17.8084257 },
    { lat: 48.1735945, lng: 17.8086066 },
    { lat: 48.173509, lng: 17.8086997 },
    { lat: 48.1734227, lng: 17.8095725 },
    { lat: 48.1731355, lng: 17.810464 },
    { lat: 48.1728896, lng: 17.8118263 },
    { lat: 48.1725865, lng: 17.8122758 },
    { lat: 48.1722664, lng: 17.8125211 },
    { lat: 48.1716302, lng: 17.8128427 },
    { lat: 48.1709321, lng: 17.812816 },
    { lat: 48.1704638, lng: 17.8127093 },
    { lat: 48.1702081, lng: 17.812776 },
    { lat: 48.1691981, lng: 17.8141577 },
    { lat: 48.1691108, lng: 17.8140388 },
    { lat: 48.1683364, lng: 17.8144435 },
    { lat: 48.1680662, lng: 17.814678 },
    { lat: 48.1666918, lng: 17.8171073 },
    { lat: 48.165111, lng: 17.820711 },
  ],
  MaláMača: [
    { lat: 48.2273595, lng: 17.6675011 },
    { lat: 48.2273842, lng: 17.6674807 },
    { lat: 48.2302771, lng: 17.6648931 },
    { lat: 48.2303629, lng: 17.6650357 },
    { lat: 48.2303434, lng: 17.6651911 },
    { lat: 48.2304702, lng: 17.6656468 },
    { lat: 48.2306337, lng: 17.6659482 },
    { lat: 48.2308081, lng: 17.6660132 },
    { lat: 48.2311398, lng: 17.6657757 },
    { lat: 48.2313209, lng: 17.6657654 },
    { lat: 48.2314483, lng: 17.6658878 },
    { lat: 48.2318855, lng: 17.6665544 },
    { lat: 48.2320698, lng: 17.6670094 },
    { lat: 48.2320912, lng: 17.667708 },
    { lat: 48.2321396, lng: 17.667869 },
    { lat: 48.2322646, lng: 17.6679336 },
    { lat: 48.2328496, lng: 17.6678825 },
    { lat: 48.2331928, lng: 17.6680811 },
    { lat: 48.2333051, lng: 17.6683061 },
    { lat: 48.2333058, lng: 17.6684597 },
    { lat: 48.2332433, lng: 17.6686325 },
    { lat: 48.2328848, lng: 17.6689492 },
    { lat: 48.2328113, lng: 17.669217 },
    { lat: 48.2328787, lng: 17.6694239 },
    { lat: 48.2331144, lng: 17.6698176 },
    { lat: 48.2333024, lng: 17.6699891 },
    { lat: 48.2334916, lng: 17.670051 },
    { lat: 48.2341572, lng: 17.6700063 },
    { lat: 48.2342607, lng: 17.6700538 },
    { lat: 48.2344047, lng: 17.6702446 },
    { lat: 48.2344986, lng: 17.6704807 },
    { lat: 48.2346948, lng: 17.6706037 },
    { lat: 48.2349687, lng: 17.670523 },
    { lat: 48.2357722, lng: 17.6704621 },
    { lat: 48.2361303, lng: 17.6705014 },
    { lat: 48.2370464, lng: 17.6707951 },
    { lat: 48.2375451, lng: 17.6710944 },
    { lat: 48.2377711, lng: 17.6714767 },
    { lat: 48.2377615, lng: 17.6717731 },
    { lat: 48.2373019, lng: 17.6721522 },
    { lat: 48.2372222, lng: 17.6723178 },
    { lat: 48.2372056, lng: 17.6725585 },
    { lat: 48.2375496, lng: 17.6732558 },
    { lat: 48.2377269, lng: 17.6734168 },
    { lat: 48.238086, lng: 17.6733993 },
    { lat: 48.2383096, lng: 17.6732385 },
    { lat: 48.239046, lng: 17.671945 },
    { lat: 48.2391618, lng: 17.6719542 },
    { lat: 48.2394281, lng: 17.6721523 },
    { lat: 48.2396877, lng: 17.672186 },
    { lat: 48.2400555, lng: 17.6717322 },
    { lat: 48.2415268, lng: 17.6718761 },
    { lat: 48.2416739, lng: 17.6721122 },
    { lat: 48.2417566, lng: 17.6727828 },
    { lat: 48.2418057, lng: 17.6728644 },
    { lat: 48.2428453, lng: 17.6726974 },
    { lat: 48.2429419, lng: 17.672618 },
    { lat: 48.2430028, lng: 17.6723827 },
    { lat: 48.2429892, lng: 17.6721657 },
    { lat: 48.2428638, lng: 17.6716594 },
    { lat: 48.2429262, lng: 17.671414 },
    { lat: 48.2430257, lng: 17.6712885 },
    { lat: 48.2434708, lng: 17.6711869 },
    { lat: 48.2438466, lng: 17.6706598 },
    { lat: 48.244372, lng: 17.6703408 },
    { lat: 48.2449911, lng: 17.6697553 },
    { lat: 48.2438534, lng: 17.6675281 },
    { lat: 48.2430413, lng: 17.665816 },
    { lat: 48.2429185, lng: 17.6654429 },
    { lat: 48.2427669, lng: 17.6646637 },
    { lat: 48.2428374, lng: 17.6637569 },
    { lat: 48.24274, lng: 17.6633211 },
    { lat: 48.2417579, lng: 17.6629825 },
    { lat: 48.2415332, lng: 17.6627843 },
    { lat: 48.2410488, lng: 17.6621196 },
    { lat: 48.2407438, lng: 17.6614155 },
    { lat: 48.2407089, lng: 17.6612369 },
    { lat: 48.2407706, lng: 17.6606378 },
    { lat: 48.2412262, lng: 17.6597309 },
    { lat: 48.2415435, lng: 17.6589212 },
    { lat: 48.2416966, lng: 17.6575847 },
    { lat: 48.2415629, lng: 17.6563202 },
    { lat: 48.2413109, lng: 17.6550781 },
    { lat: 48.2408421, lng: 17.6531545 },
    { lat: 48.2407068, lng: 17.6509137 },
    { lat: 48.2405182, lng: 17.6491001 },
    { lat: 48.2402008, lng: 17.6475003 },
    { lat: 48.2399695, lng: 17.6459422 },
    { lat: 48.2402829, lng: 17.6450578 },
    { lat: 48.2405254, lng: 17.642577 },
    { lat: 48.2407798, lng: 17.6422442 },
    { lat: 48.2411714, lng: 17.6419353 },
    { lat: 48.2405582, lng: 17.6396918 },
    { lat: 48.2398465, lng: 17.6378789 },
    { lat: 48.2396744, lng: 17.6374948 },
    { lat: 48.2394328, lng: 17.6371184 },
    { lat: 48.2394708, lng: 17.6374704 },
    { lat: 48.2392817, lng: 17.6378368 },
    { lat: 48.2389278, lng: 17.638083 },
    { lat: 48.2386172, lng: 17.6381177 },
    { lat: 48.2384041, lng: 17.6377479 },
    { lat: 48.2383993, lng: 17.6373487 },
    { lat: 48.238354, lng: 17.6371294 },
    { lat: 48.238278, lng: 17.6370877 },
    { lat: 48.2381834, lng: 17.6371284 },
    { lat: 48.2378466, lng: 17.6373566 },
    { lat: 48.2374644, lng: 17.6368446 },
    { lat: 48.2372753, lng: 17.6366751 },
    { lat: 48.2372435, lng: 17.6362984 },
    { lat: 48.2370232, lng: 17.6357246 },
    { lat: 48.2368043, lng: 17.6355819 },
    { lat: 48.2365698, lng: 17.635551 },
    { lat: 48.2363342, lng: 17.6354128 },
    { lat: 48.2358933, lng: 17.6350483 },
    { lat: 48.2355978, lng: 17.6349994 },
    { lat: 48.2355435, lng: 17.6351594 },
    { lat: 48.235832, lng: 17.6357608 },
    { lat: 48.2358279, lng: 17.6359618 },
    { lat: 48.2357884, lng: 17.6360333 },
    { lat: 48.235577, lng: 17.636123 },
    { lat: 48.2353478, lng: 17.6359967 },
    { lat: 48.2352165, lng: 17.6356136 },
    { lat: 48.2352152, lng: 17.6352741 },
    { lat: 48.2355276, lng: 17.6339776 },
    { lat: 48.2367065, lng: 17.6311496 },
    { lat: 48.2358671, lng: 17.629906 },
    { lat: 48.2355807, lng: 17.629252 },
    { lat: 48.2352389, lng: 17.6287375 },
    { lat: 48.2347276, lng: 17.6277398 },
    { lat: 48.2343574, lng: 17.6272251 },
    { lat: 48.2337998, lng: 17.625798 },
    { lat: 48.2329308, lng: 17.6272703 },
    { lat: 48.2322476, lng: 17.6265187 },
    { lat: 48.2317789, lng: 17.6265723 },
    { lat: 48.2313153, lng: 17.6270434 },
    { lat: 48.2310642, lng: 17.6272149 },
    { lat: 48.2307656, lng: 17.6270839 },
    { lat: 48.2306625, lng: 17.6269356 },
    { lat: 48.2307095, lng: 17.6263761 },
    { lat: 48.2306457, lng: 17.6258631 },
    { lat: 48.2303452, lng: 17.6251821 },
    { lat: 48.2300914, lng: 17.6253288 },
    { lat: 48.2298041, lng: 17.6256999 },
    { lat: 48.2296465, lng: 17.6256358 },
    { lat: 48.2294993, lng: 17.6253963 },
    { lat: 48.2294257, lng: 17.6250696 },
    { lat: 48.2292732, lng: 17.624749 },
    { lat: 48.2292932, lng: 17.6241328 },
    { lat: 48.2292686, lng: 17.6239755 },
    { lat: 48.2292036, lng: 17.6238937 },
    { lat: 48.228671, lng: 17.6236326 },
    { lat: 48.2283805, lng: 17.6233982 },
    { lat: 48.2282443, lng: 17.623512 },
    { lat: 48.2280149, lng: 17.6239705 },
    { lat: 48.2278083, lng: 17.6237412 },
    { lat: 48.2268029, lng: 17.622162 },
    { lat: 48.2243363, lng: 17.6180652 },
    { lat: 48.2224712, lng: 17.6152833 },
    { lat: 48.2218467, lng: 17.6159599 },
    { lat: 48.2213245, lng: 17.6165788 },
    { lat: 48.2186602, lng: 17.620659 },
    { lat: 48.2176919, lng: 17.6219914 },
    { lat: 48.2169669, lng: 17.6236171 },
    { lat: 48.2167562, lng: 17.6245179 },
    { lat: 48.2166666, lng: 17.6256721 },
    { lat: 48.216588, lng: 17.6261063 },
    { lat: 48.2150202, lng: 17.6321647 },
    { lat: 48.2143367, lng: 17.6344305 },
    { lat: 48.2151194, lng: 17.636099 },
    { lat: 48.2148639, lng: 17.6363631 },
    { lat: 48.2147209, lng: 17.6366014 },
    { lat: 48.2145104, lng: 17.6373838 },
    { lat: 48.214526, lng: 17.6382012 },
    { lat: 48.2146254, lng: 17.6387759 },
    { lat: 48.2146543, lng: 17.6395946 },
    { lat: 48.2142901, lng: 17.6410685 },
    { lat: 48.2141338, lng: 17.6424784 },
    { lat: 48.214424, lng: 17.6426032 },
    { lat: 48.2149114, lng: 17.6429568 },
    { lat: 48.2150037, lng: 17.6433431 },
    { lat: 48.214987, lng: 17.644028 },
    { lat: 48.2146196, lng: 17.6450092 },
    { lat: 48.2145019, lng: 17.6455944 },
    { lat: 48.2145601, lng: 17.6462333 },
    { lat: 48.2147437, lng: 17.6468713 },
    { lat: 48.2149786, lng: 17.6473543 },
    { lat: 48.2150826, lng: 17.6474477 },
    { lat: 48.2152478, lng: 17.6475224 },
    { lat: 48.2154827, lng: 17.6474886 },
    { lat: 48.2162512, lng: 17.647 },
    { lat: 48.2166601, lng: 17.6466046 },
    { lat: 48.2167253, lng: 17.6464761 },
    { lat: 48.2167675, lng: 17.646177 },
    { lat: 48.2165938, lng: 17.6457674 },
    { lat: 48.2165358, lng: 17.6453684 },
    { lat: 48.2165124, lng: 17.6450676 },
    { lat: 48.2165886, lng: 17.6447527 },
    { lat: 48.2170058, lng: 17.6445661 },
    { lat: 48.2177983, lng: 17.6446529 },
    { lat: 48.2183778, lng: 17.6449051 },
    { lat: 48.2185389, lng: 17.6450969 },
    { lat: 48.2182801, lng: 17.6454694 },
    { lat: 48.2181441, lng: 17.6460717 },
    { lat: 48.2181506, lng: 17.6465353 },
    { lat: 48.2182959, lng: 17.6471925 },
    { lat: 48.2189182, lng: 17.6483957 },
    { lat: 48.2192926, lng: 17.6488549 },
    { lat: 48.2198253, lng: 17.6496565 },
    { lat: 48.2201092, lng: 17.6502198 },
    { lat: 48.2201936, lng: 17.6504866 },
    { lat: 48.2206076, lng: 17.6502289 },
    { lat: 48.223423, lng: 17.6555832 },
    { lat: 48.2226585, lng: 17.6564979 },
    { lat: 48.2226982, lng: 17.6566258 },
    { lat: 48.2235291, lng: 17.6585465 },
    { lat: 48.2238879, lng: 17.6592219 },
    { lat: 48.2245022, lng: 17.660804 },
    { lat: 48.2273595, lng: 17.6675011 },
  ],
  VeľkýGrob: [
    { lat: 48.2548797, lng: 17.5452047 },
    { lat: 48.25489, lng: 17.545194 },
    { lat: 48.255641, lng: 17.5443294 },
    { lat: 48.259653, lng: 17.53971 },
    { lat: 48.2633249, lng: 17.5363241 },
    { lat: 48.264754, lng: 17.534555 },
    { lat: 48.265772, lng: 17.533412 },
    { lat: 48.267822, lng: 17.530573 },
    { lat: 48.2697216, lng: 17.52837 },
    { lat: 48.2697281, lng: 17.5283655 },
    { lat: 48.270335, lng: 17.527507 },
    { lat: 48.271054, lng: 17.526425 },
    { lat: 48.2728118, lng: 17.5235718 },
    { lat: 48.272867, lng: 17.523485 },
    { lat: 48.274387, lng: 17.520861 },
    { lat: 48.2763542, lng: 17.5178316 },
    { lat: 48.276893, lng: 17.517034 },
    { lat: 48.279077, lng: 17.513744 },
    { lat: 48.278993, lng: 17.513654 },
    { lat: 48.278863, lng: 17.513485 },
    { lat: 48.278577, lng: 17.513129 },
    { lat: 48.278324, lng: 17.512811 },
    { lat: 48.278162, lng: 17.512606 },
    { lat: 48.27793, lng: 17.512324 },
    { lat: 48.277704, lng: 17.51205 },
    { lat: 48.277589, lng: 17.511906 },
    { lat: 48.277341, lng: 17.511599 },
    { lat: 48.277115, lng: 17.511326 },
    { lat: 48.276853, lng: 17.511027 },
    { lat: 48.276555, lng: 17.510688 },
    { lat: 48.276535, lng: 17.510664 },
    { lat: 48.27637, lng: 17.510487 },
    { lat: 48.276209, lng: 17.510311 },
    { lat: 48.275903, lng: 17.509977 },
    { lat: 48.275805, lng: 17.509866 },
    { lat: 48.275472, lng: 17.509501 },
    { lat: 48.275185, lng: 17.509182 },
    { lat: 48.274771, lng: 17.508712 },
    { lat: 48.274665, lng: 17.508596 },
    { lat: 48.274646, lng: 17.508578 },
    { lat: 48.274576, lng: 17.508514 },
    { lat: 48.274429, lng: 17.508377 },
    { lat: 48.274271, lng: 17.508231 },
    { lat: 48.274191, lng: 17.508179 },
    { lat: 48.274163, lng: 17.508109 },
    { lat: 48.27414, lng: 17.508049 },
    { lat: 48.274118, lng: 17.507992 },
    { lat: 48.274086, lng: 17.507958 },
    { lat: 48.27401, lng: 17.507878 },
    { lat: 48.273819, lng: 17.50767 },
    { lat: 48.273797, lng: 17.507647 },
    { lat: 48.273637, lng: 17.507473 },
    { lat: 48.273494, lng: 17.50732 },
    { lat: 48.273174, lng: 17.506975 },
    { lat: 48.272948, lng: 17.506732 },
    { lat: 48.272697, lng: 17.506466 },
    { lat: 48.272511, lng: 17.506267 },
    { lat: 48.272297, lng: 17.506038 },
    { lat: 48.272309, lng: 17.506007 },
    { lat: 48.272147, lng: 17.505834 },
    { lat: 48.271912, lng: 17.505582 },
    { lat: 48.271743, lng: 17.505405 },
    { lat: 48.2714, lng: 17.505038 },
    { lat: 48.27123, lng: 17.504868 },
    { lat: 48.270851, lng: 17.504482 },
    { lat: 48.27069, lng: 17.504319 },
    { lat: 48.270539, lng: 17.504185 },
    { lat: 48.270169, lng: 17.503853 },
    { lat: 48.26997, lng: 17.503673 },
    { lat: 48.269901, lng: 17.503602 },
    { lat: 48.269597, lng: 17.503287 },
    { lat: 48.269266, lng: 17.502922 },
    { lat: 48.269156, lng: 17.502801 },
    { lat: 48.268861, lng: 17.502479 },
    { lat: 48.268823, lng: 17.502441 },
    { lat: 48.26861, lng: 17.502214 },
    { lat: 48.268322, lng: 17.501905 },
    { lat: 48.2682, lng: 17.501771 },
    { lat: 48.268018, lng: 17.501565 },
    { lat: 48.267714, lng: 17.501204 },
    { lat: 48.267488, lng: 17.500951 },
    { lat: 48.267096, lng: 17.500519 },
    { lat: 48.2668, lng: 17.500192 },
    { lat: 48.266763, lng: 17.500149 },
    { lat: 48.266519, lng: 17.499881 },
    { lat: 48.266257, lng: 17.49959 },
    { lat: 48.265939, lng: 17.499236 },
    { lat: 48.265546, lng: 17.498804 },
    { lat: 48.265192, lng: 17.498409 },
    { lat: 48.265187, lng: 17.498402 },
    { lat: 48.265046, lng: 17.498249 },
    { lat: 48.264613, lng: 17.49774 },
    { lat: 48.264352, lng: 17.497433 },
    { lat: 48.264146, lng: 17.497194 },
    { lat: 48.263913, lng: 17.496891 },
    { lat: 48.26366, lng: 17.496567 },
    { lat: 48.263368, lng: 17.496193 },
    { lat: 48.263119, lng: 17.495878 },
    { lat: 48.262833, lng: 17.495515 },
    { lat: 48.262543, lng: 17.495148 },
    { lat: 48.262357, lng: 17.49491 },
    { lat: 48.261906, lng: 17.49436 },
    { lat: 48.261585, lng: 17.493969 },
    { lat: 48.261291, lng: 17.493577 },
    { lat: 48.26096, lng: 17.493131 },
    { lat: 48.260959, lng: 17.493083 },
    { lat: 48.260963, lng: 17.492998 },
    { lat: 48.260966, lng: 17.492949 },
    { lat: 48.260892, lng: 17.492839 },
    { lat: 48.260601, lng: 17.492428 },
    { lat: 48.26041, lng: 17.49216 },
    { lat: 48.260271, lng: 17.491944 },
    { lat: 48.260015, lng: 17.491555 },
    { lat: 48.259818, lng: 17.491264 },
    { lat: 48.25968, lng: 17.491053 },
    { lat: 48.259609, lng: 17.49095 },
    { lat: 48.259414, lng: 17.490669 },
    { lat: 48.259013, lng: 17.490096 },
    { lat: 48.258794, lng: 17.489779 },
    { lat: 48.258776, lng: 17.489753 },
    { lat: 48.258656, lng: 17.489585 },
    { lat: 48.258568, lng: 17.489457 },
    { lat: 48.258358, lng: 17.489164 },
    { lat: 48.258251, lng: 17.48901 },
    { lat: 48.258175, lng: 17.488918 },
    { lat: 48.258032, lng: 17.488734 },
    { lat: 48.257779, lng: 17.488418 },
    { lat: 48.257484, lng: 17.487986 },
    { lat: 48.257412, lng: 17.4879 },
    { lat: 48.257285, lng: 17.487748 },
    { lat: 48.2571629, lng: 17.4876001 },
    { lat: 48.257006, lng: 17.48741 },
    { lat: 48.256659, lng: 17.486983 },
    { lat: 48.256331, lng: 17.486527 },
    { lat: 48.25613, lng: 17.486248 },
    { lat: 48.255896, lng: 17.485955 },
    { lat: 48.255839, lng: 17.485883 },
    { lat: 48.255524, lng: 17.485518 },
    { lat: 48.255509, lng: 17.485547 },
    { lat: 48.255478, lng: 17.485507 },
    { lat: 48.25508, lng: 17.485007 },
    { lat: 48.254445, lng: 17.48432 },
    { lat: 48.254417, lng: 17.484295 },
    { lat: 48.254063, lng: 17.483966 },
    { lat: 48.253807, lng: 17.483741 },
    { lat: 48.253067, lng: 17.482979 },
    { lat: 48.252969, lng: 17.482847 },
    { lat: 48.252908, lng: 17.482765 },
    { lat: 48.252901, lng: 17.482754 },
    { lat: 48.252869, lng: 17.482715 },
    { lat: 48.252881, lng: 17.482684 },
    { lat: 48.252009, lng: 17.482207 },
    { lat: 48.25083, lng: 17.481567 },
    { lat: 48.250449, lng: 17.481366 },
    { lat: 48.250194, lng: 17.481233 },
    { lat: 48.250062, lng: 17.481162 },
    { lat: 48.249354, lng: 17.480799 },
    { lat: 48.24932, lng: 17.48078 },
    { lat: 48.248974, lng: 17.480607 },
    { lat: 48.248456, lng: 17.480348 },
    { lat: 48.247807, lng: 17.480015 },
    { lat: 48.247612, lng: 17.479914 },
    { lat: 48.24693, lng: 17.479546 },
    { lat: 48.246455, lng: 17.479299 },
    { lat: 48.245913, lng: 17.479015 },
    { lat: 48.245633, lng: 17.478866 },
    { lat: 48.245586, lng: 17.478835 },
    { lat: 48.2454968, lng: 17.4787742 },
    { lat: 48.245435, lng: 17.478732 },
    { lat: 48.245406, lng: 17.478713 },
    { lat: 48.244875, lng: 17.478389 },
    { lat: 48.244381, lng: 17.478085 },
    { lat: 48.244316, lng: 17.478082 },
    { lat: 48.243683, lng: 17.478395 },
    { lat: 48.243639, lng: 17.47841 },
    { lat: 48.243595, lng: 17.478422 },
    { lat: 48.243573, lng: 17.478428 },
    { lat: 48.243503, lng: 17.478449 },
    { lat: 48.243458, lng: 17.47846 },
    { lat: 48.242981, lng: 17.478671 },
    { lat: 48.24252, lng: 17.47888 },
    { lat: 48.242224, lng: 17.479007 },
    { lat: 48.241682, lng: 17.478861 },
    { lat: 48.241205, lng: 17.478727 },
    { lat: 48.240912, lng: 17.478639 },
    { lat: 48.240349, lng: 17.478229 },
    { lat: 48.239707, lng: 17.477753 },
    { lat: 48.239527, lng: 17.477577 },
    { lat: 48.239108, lng: 17.477166 },
    { lat: 48.239051, lng: 17.477115 },
    { lat: 48.23903, lng: 17.477094 },
    { lat: 48.238628, lng: 17.476705 },
    { lat: 48.23828, lng: 17.476373 },
    { lat: 48.237764, lng: 17.475878 },
    { lat: 48.237425, lng: 17.475551 },
    { lat: 48.237297, lng: 17.475426 },
    { lat: 48.237024, lng: 17.475161 },
    { lat: 48.236782, lng: 17.474927 },
    { lat: 48.236219, lng: 17.474391 },
    { lat: 48.236022, lng: 17.474204 },
    { lat: 48.235477, lng: 17.473664 },
    { lat: 48.23527, lng: 17.473457 },
    { lat: 48.235098, lng: 17.473292 },
    { lat: 48.235083, lng: 17.473277 },
    { lat: 48.234858, lng: 17.473994 },
    { lat: 48.234667, lng: 17.474598 },
    { lat: 48.234494, lng: 17.475163 },
    { lat: 48.23444, lng: 17.475348 },
    { lat: 48.234194, lng: 17.47615 },
    { lat: 48.234132, lng: 17.476359 },
    { lat: 48.234078, lng: 17.476587 },
    { lat: 48.234025, lng: 17.476814 },
    { lat: 48.233951, lng: 17.477129 },
    { lat: 48.23381, lng: 17.477729 },
    { lat: 48.233654, lng: 17.478399 },
    { lat: 48.23344, lng: 17.479349 },
    { lat: 48.233314, lng: 17.4799 },
    { lat: 48.233201, lng: 17.480279 },
    { lat: 48.233082, lng: 17.480675 },
    { lat: 48.233023, lng: 17.480868 },
    { lat: 48.232988, lng: 17.480988 },
    { lat: 48.232763, lng: 17.481565 },
    { lat: 48.232657, lng: 17.481837 },
    { lat: 48.23238, lng: 17.482232 },
    { lat: 48.232368, lng: 17.482254 },
    { lat: 48.232347, lng: 17.4823 },
    { lat: 48.232007, lng: 17.483068 },
    { lat: 48.232, lng: 17.483085 },
    { lat: 48.231576, lng: 17.484029 },
    { lat: 48.231183, lng: 17.484985 },
    { lat: 48.230748, lng: 17.486007 },
    { lat: 48.2306509, lng: 17.48624 },
    { lat: 48.230768, lng: 17.486301 },
    { lat: 48.230945, lng: 17.4864 },
    { lat: 48.231248, lng: 17.486565 },
    { lat: 48.231285, lng: 17.486585 },
    { lat: 48.231286, lng: 17.486586 },
    { lat: 48.231318, lng: 17.486602 },
    { lat: 48.231478, lng: 17.486723 },
    { lat: 48.231513, lng: 17.486751 },
    { lat: 48.231634, lng: 17.48685 },
    { lat: 48.231731, lng: 17.48688 },
    { lat: 48.232204, lng: 17.487041 },
    { lat: 48.232313, lng: 17.487071 },
    { lat: 48.232304, lng: 17.487108 },
    { lat: 48.231964, lng: 17.48856 },
    { lat: 48.2319, lng: 17.488886 },
    { lat: 48.231893, lng: 17.489172 },
    { lat: 48.231871, lng: 17.490125 },
    { lat: 48.231879, lng: 17.490219 },
    { lat: 48.231883, lng: 17.490272 },
    { lat: 48.23189, lng: 17.490352 },
    { lat: 48.231879, lng: 17.490724 },
    { lat: 48.231881, lng: 17.491584 },
    { lat: 48.231868, lng: 17.491852 },
    { lat: 48.23158, lng: 17.492785 },
    { lat: 48.231264, lng: 17.493884 },
    { lat: 48.231258, lng: 17.493907 },
    { lat: 48.231022, lng: 17.494708 },
    { lat: 48.230787, lng: 17.495529 },
    { lat: 48.230348, lng: 17.49715 },
    { lat: 48.229843, lng: 17.498989 },
    { lat: 48.229446, lng: 17.50043 },
    { lat: 48.229283, lng: 17.501024 },
    { lat: 48.229195, lng: 17.501503 },
    { lat: 48.228968, lng: 17.502343 },
    { lat: 48.22864, lng: 17.503537 },
    { lat: 48.228526, lng: 17.503963 },
    { lat: 48.228315, lng: 17.504753 },
    { lat: 48.228081, lng: 17.505614 },
    { lat: 48.227767, lng: 17.506845 },
    { lat: 48.227525, lng: 17.507849 },
    { lat: 48.227454, lng: 17.508153 },
    { lat: 48.227411, lng: 17.508312 },
    { lat: 48.227171, lng: 17.509151 },
    { lat: 48.227002, lng: 17.509746 },
    { lat: 48.226735, lng: 17.510654 },
    { lat: 48.226685, lng: 17.510836 },
    { lat: 48.22656, lng: 17.511399 },
    { lat: 48.226368, lng: 17.51232 },
    { lat: 48.226146, lng: 17.513411 },
    { lat: 48.226115, lng: 17.513567 },
    { lat: 48.226072, lng: 17.514313 },
    { lat: 48.226039, lng: 17.514926 },
    { lat: 48.225938, lng: 17.516111 },
    { lat: 48.225841, lng: 17.517258 },
    { lat: 48.225648, lng: 17.519408 },
    { lat: 48.225577, lng: 17.52023 },
    { lat: 48.225548, lng: 17.520557 },
    { lat: 48.2254176, lng: 17.5214691 },
    { lat: 48.2243223, lng: 17.5248729 },
    { lat: 48.2242608, lng: 17.5248293 },
    { lat: 48.2234033, lng: 17.5266051 },
    { lat: 48.2228555, lng: 17.5276108 },
    { lat: 48.2211803, lng: 17.5315747 },
    { lat: 48.2210314, lng: 17.5320691 },
    { lat: 48.220845, lng: 17.5324585 },
    { lat: 48.2205055, lng: 17.5336654 },
    { lat: 48.2203017, lng: 17.5347676 },
    { lat: 48.2194902, lng: 17.5368949 },
    { lat: 48.2193083, lng: 17.5374762 },
    { lat: 48.2200027, lng: 17.5381803 },
    { lat: 48.2216221, lng: 17.5395774 },
    { lat: 48.2223787, lng: 17.5402992 },
    { lat: 48.2237406, lng: 17.5418186 },
    { lat: 48.2259013, lng: 17.5444241 },
    { lat: 48.2277717, lng: 17.5470253 },
    { lat: 48.2302004, lng: 17.5506806 },
    { lat: 48.2314811, lng: 17.552753 },
    { lat: 48.2315132, lng: 17.5527265 },
    { lat: 48.23231, lng: 17.5539058 },
    { lat: 48.2339567, lng: 17.5567154 },
    { lat: 48.2342877, lng: 17.5572397 },
    { lat: 48.2345492, lng: 17.5574627 },
    { lat: 48.2377648, lng: 17.5595964 },
    { lat: 48.2393301, lng: 17.5609627 },
    { lat: 48.239763, lng: 17.561468 },
    { lat: 48.241847, lng: 17.559181 },
    { lat: 48.244669, lng: 17.556408 },
    { lat: 48.246557, lng: 17.554419 },
    { lat: 48.2466021, lng: 17.5543623 },
    { lat: 48.248308, lng: 17.552219 },
    { lat: 48.251008, lng: 17.549018 },
    { lat: 48.251889, lng: 17.548101 },
    { lat: 48.253823, lng: 17.546312 },
    { lat: 48.2548797, lng: 17.5452047 },
  ],
  DolnéSaliby: [
    { lat: 48.105754, lng: 17.820104 },
    { lat: 48.105746, lng: 17.819862 },
    { lat: 48.105736, lng: 17.819688 },
    { lat: 48.105769, lng: 17.819575 },
    { lat: 48.105798, lng: 17.819467 },
    { lat: 48.105834, lng: 17.819355 },
    { lat: 48.105866, lng: 17.819243 },
    { lat: 48.105906, lng: 17.819104 },
    { lat: 48.105944, lng: 17.818958 },
    { lat: 48.105975, lng: 17.818869 },
    { lat: 48.105986, lng: 17.818831 },
    { lat: 48.105992, lng: 17.818812 },
    { lat: 48.106061, lng: 17.818576 },
    { lat: 48.106086, lng: 17.818493 },
    { lat: 48.106094, lng: 17.818459 },
    { lat: 48.106106, lng: 17.818418 },
    { lat: 48.106118, lng: 17.818376 },
    { lat: 48.106149, lng: 17.818266 },
    { lat: 48.106185, lng: 17.818186 },
    { lat: 48.106209, lng: 17.818126 },
    { lat: 48.106228, lng: 17.818081 },
    { lat: 48.106249, lng: 17.818036 },
    { lat: 48.106274, lng: 17.817984 },
    { lat: 48.106316, lng: 17.817887 },
    { lat: 48.106343, lng: 17.817821 },
    { lat: 48.106364, lng: 17.817774 },
    { lat: 48.10641, lng: 17.817665 },
    { lat: 48.106433, lng: 17.817613 },
    { lat: 48.106451, lng: 17.817569 },
    { lat: 48.106549, lng: 17.817343 },
    { lat: 48.106602, lng: 17.817219 },
    { lat: 48.106629, lng: 17.817157 },
    { lat: 48.106654, lng: 17.817099 },
    { lat: 48.106668, lng: 17.817062 },
    { lat: 48.106703, lng: 17.816994 },
    { lat: 48.106709, lng: 17.816948 },
    { lat: 48.106725, lng: 17.816825 },
    { lat: 48.106739, lng: 17.8167 },
    { lat: 48.106753, lng: 17.816578 },
    { lat: 48.106762, lng: 17.816497 },
    { lat: 48.106791, lng: 17.816253 },
    { lat: 48.106809, lng: 17.816116 },
    { lat: 48.106812, lng: 17.815952 },
    { lat: 48.10681, lng: 17.81549 },
    { lat: 48.106806, lng: 17.815231 },
    { lat: 48.106806, lng: 17.81499 },
    { lat: 48.106808, lng: 17.814944 },
    { lat: 48.106812, lng: 17.814742 },
    { lat: 48.106819, lng: 17.814484 },
    { lat: 48.10682, lng: 17.814386 },
    { lat: 48.106817, lng: 17.814306 },
    { lat: 48.106819, lng: 17.814251 },
    { lat: 48.10685, lng: 17.814248 },
    { lat: 48.106904, lng: 17.814244 },
    { lat: 48.106914, lng: 17.814243 },
    { lat: 48.106978, lng: 17.814231 },
    { lat: 48.107066, lng: 17.814216 },
    { lat: 48.107149, lng: 17.8142 },
    { lat: 48.107301, lng: 17.814172 },
    { lat: 48.107366, lng: 17.814161 },
    { lat: 48.107417, lng: 17.814152 },
    { lat: 48.107482, lng: 17.814138 },
    { lat: 48.107582, lng: 17.81412 },
    { lat: 48.10805, lng: 17.814026 },
    { lat: 48.108168, lng: 17.813996 },
    { lat: 48.108232, lng: 17.813985 },
    { lat: 48.108295, lng: 17.813966 },
    { lat: 48.108366, lng: 17.813954 },
    { lat: 48.108429, lng: 17.813935 },
    { lat: 48.108499, lng: 17.813924 },
    { lat: 48.10899, lng: 17.813811 },
    { lat: 48.110084, lng: 17.813565 },
    { lat: 48.110682, lng: 17.813432 },
    { lat: 48.111524, lng: 17.813258 },
    { lat: 48.111884, lng: 17.813183 },
    { lat: 48.112746, lng: 17.813173 },
    { lat: 48.112777, lng: 17.813172 },
    { lat: 48.113728, lng: 17.813166 },
    { lat: 48.114124, lng: 17.812374 },
    { lat: 48.114567, lng: 17.811489 },
    { lat: 48.114872, lng: 17.810832 },
    { lat: 48.114934, lng: 17.810697 },
    { lat: 48.115442, lng: 17.809965 },
    { lat: 48.11596, lng: 17.809395 },
    { lat: 48.116202, lng: 17.808796 },
    { lat: 48.116235, lng: 17.808409 },
    { lat: 48.116213, lng: 17.808262 },
    { lat: 48.116341, lng: 17.807777 },
    { lat: 48.116439, lng: 17.807567 },
    { lat: 48.116632, lng: 17.80729 },
    { lat: 48.116666, lng: 17.807268 },
    { lat: 48.11668, lng: 17.807258 },
    { lat: 48.116807, lng: 17.807134 },
    { lat: 48.116925, lng: 17.807021 },
    { lat: 48.11694, lng: 17.807006 },
    { lat: 48.116961, lng: 17.806986 },
    { lat: 48.117299, lng: 17.807071 },
    { lat: 48.117463, lng: 17.807112 },
    { lat: 48.117689, lng: 17.80717 },
    { lat: 48.117719, lng: 17.807181 },
    { lat: 48.117739, lng: 17.807181 },
    { lat: 48.117745, lng: 17.807173 },
    { lat: 48.117762, lng: 17.807147 },
    { lat: 48.117779, lng: 17.807123 },
    { lat: 48.117792, lng: 17.807104 },
    { lat: 48.117804, lng: 17.807091 },
    { lat: 48.117897, lng: 17.806979 },
    { lat: 48.117943, lng: 17.80691 },
    { lat: 48.11798, lng: 17.806859 },
    { lat: 48.118002, lng: 17.806832 },
    { lat: 48.118055, lng: 17.806768 },
    { lat: 48.118106, lng: 17.806702 },
    { lat: 48.118154, lng: 17.806644 },
    { lat: 48.118172, lng: 17.806621 },
    { lat: 48.118301, lng: 17.806451 },
    { lat: 48.118365, lng: 17.806372 },
    { lat: 48.118412, lng: 17.806312 },
    { lat: 48.118558, lng: 17.806121 },
    { lat: 48.118672, lng: 17.805973 },
    { lat: 48.118745, lng: 17.805874 },
    { lat: 48.118749, lng: 17.805869 },
    { lat: 48.118847, lng: 17.805743 },
    { lat: 48.118947, lng: 17.805617 },
    { lat: 48.118998, lng: 17.805555 },
    { lat: 48.11904, lng: 17.805504 },
    { lat: 48.119068, lng: 17.805466 },
    { lat: 48.119095, lng: 17.805427 },
    { lat: 48.119137, lng: 17.80537 },
    { lat: 48.11923, lng: 17.805243 },
    { lat: 48.119342, lng: 17.805099 },
    { lat: 48.119543, lng: 17.804827 },
    { lat: 48.119854, lng: 17.804409 },
    { lat: 48.119932, lng: 17.804309 },
    { lat: 48.120113, lng: 17.804063 },
    { lat: 48.120168, lng: 17.803993 },
    { lat: 48.120207, lng: 17.803949 },
    { lat: 48.120314, lng: 17.803824 },
    { lat: 48.120503, lng: 17.8036 },
    { lat: 48.120584, lng: 17.8035 },
    { lat: 48.120635, lng: 17.803438 },
    { lat: 48.120662, lng: 17.803408 },
    { lat: 48.120808, lng: 17.803212 },
    { lat: 48.120912, lng: 17.803073 },
    { lat: 48.121017, lng: 17.802937 },
    { lat: 48.121167, lng: 17.802735 },
    { lat: 48.12122, lng: 17.802677 },
    { lat: 48.12132, lng: 17.80254 },
    { lat: 48.121376, lng: 17.80246 },
    { lat: 48.121422, lng: 17.8024 },
    { lat: 48.121922, lng: 17.80179 },
    { lat: 48.121969, lng: 17.801725 },
    { lat: 48.122053, lng: 17.801627 },
    { lat: 48.122086, lng: 17.801587 },
    { lat: 48.122152, lng: 17.801499 },
    { lat: 48.122224, lng: 17.8014 },
    { lat: 48.122287, lng: 17.801317 },
    { lat: 48.122353, lng: 17.801237 },
    { lat: 48.122457, lng: 17.801096 },
    { lat: 48.12251, lng: 17.801025 },
    { lat: 48.122568, lng: 17.800953 },
    { lat: 48.122649, lng: 17.800845 },
    { lat: 48.122767, lng: 17.800692 },
    { lat: 48.122813, lng: 17.800621 },
    { lat: 48.122929, lng: 17.800474 },
    { lat: 48.123014, lng: 17.800358 },
    { lat: 48.123117, lng: 17.800232 },
    { lat: 48.123255, lng: 17.80005 },
    { lat: 48.123294, lng: 17.799995 },
    { lat: 48.123375, lng: 17.799892 },
    { lat: 48.123435, lng: 17.799812 },
    { lat: 48.123518, lng: 17.799707 },
    { lat: 48.123572, lng: 17.799632 },
    { lat: 48.123635, lng: 17.79956 },
    { lat: 48.12369, lng: 17.799479 },
    { lat: 48.123745, lng: 17.799411 },
    { lat: 48.123869, lng: 17.79925 },
    { lat: 48.123934, lng: 17.799168 },
    { lat: 48.124017, lng: 17.799055 },
    { lat: 48.124097, lng: 17.798954 },
    { lat: 48.124129, lng: 17.798911 },
    { lat: 48.124238, lng: 17.798767 },
    { lat: 48.124296, lng: 17.798687 },
    { lat: 48.124352, lng: 17.798615 },
    { lat: 48.124524, lng: 17.798394 },
    { lat: 48.124571, lng: 17.798335 },
    { lat: 48.124627, lng: 17.798261 },
    { lat: 48.124684, lng: 17.798181 },
    { lat: 48.124805, lng: 17.798021 },
    { lat: 48.124863, lng: 17.797952 },
    { lat: 48.124917, lng: 17.797871 },
    { lat: 48.124971, lng: 17.797803 },
    { lat: 48.125001, lng: 17.797764 },
    { lat: 48.125085, lng: 17.797655 },
    { lat: 48.125146, lng: 17.797569 },
    { lat: 48.125203, lng: 17.797491 },
    { lat: 48.125259, lng: 17.797414 },
    { lat: 48.125364, lng: 17.797272 },
    { lat: 48.125426, lng: 17.797185 },
    { lat: 48.125481, lng: 17.797112 },
    { lat: 48.125554, lng: 17.79701 },
    { lat: 48.125604, lng: 17.796939 },
    { lat: 48.12566, lng: 17.796867 },
    { lat: 48.125709, lng: 17.796798 },
    { lat: 48.126034, lng: 17.796356 },
    { lat: 48.126093, lng: 17.796276 },
    { lat: 48.126145, lng: 17.796206 },
    { lat: 48.126202, lng: 17.796125 },
    { lat: 48.126259, lng: 17.796048 },
    { lat: 48.126561, lng: 17.795633 },
    { lat: 48.126767, lng: 17.795342 },
    { lat: 48.126793, lng: 17.795305 },
    { lat: 48.126878, lng: 17.795183 },
    { lat: 48.126904, lng: 17.795145 },
    { lat: 48.126933, lng: 17.795105 },
    { lat: 48.126992, lng: 17.795024 },
    { lat: 48.127018, lng: 17.794985 },
    { lat: 48.127071, lng: 17.794908 },
    { lat: 48.127103, lng: 17.794857 },
    { lat: 48.127219, lng: 17.794699 },
    { lat: 48.127248, lng: 17.794657 },
    { lat: 48.127275, lng: 17.794617 },
    { lat: 48.127331, lng: 17.794537 },
    { lat: 48.127449, lng: 17.794369 },
    { lat: 48.127471, lng: 17.794333 },
    { lat: 48.127512, lng: 17.794273 },
    { lat: 48.127574, lng: 17.794183 },
    { lat: 48.127753, lng: 17.793933 },
    { lat: 48.127812, lng: 17.793847 },
    { lat: 48.127843, lng: 17.793807 },
    { lat: 48.127873, lng: 17.793766 },
    { lat: 48.127934, lng: 17.793674 },
    { lat: 48.127989, lng: 17.793596 },
    { lat: 48.128041, lng: 17.793507 },
    { lat: 48.128093, lng: 17.793428 },
    { lat: 48.12815, lng: 17.793333 },
    { lat: 48.128274, lng: 17.793132 },
    { lat: 48.128395, lng: 17.792935 },
    { lat: 48.128453, lng: 17.792835 },
    { lat: 48.128522, lng: 17.792726 },
    { lat: 48.128645, lng: 17.792528 },
    { lat: 48.128765, lng: 17.792328 },
    { lat: 48.128825, lng: 17.79223 },
    { lat: 48.128888, lng: 17.792129 },
    { lat: 48.128939, lng: 17.792041 },
    { lat: 48.129043, lng: 17.791874 },
    { lat: 48.129147, lng: 17.791703 },
    { lat: 48.129225, lng: 17.791573 },
    { lat: 48.129304, lng: 17.791444 },
    { lat: 48.129398, lng: 17.791301 },
    { lat: 48.129473, lng: 17.791176 },
    { lat: 48.129495, lng: 17.791125 },
    { lat: 48.129597, lng: 17.790892 },
    { lat: 48.129701, lng: 17.790639 },
    { lat: 48.129916, lng: 17.79013 },
    { lat: 48.130059, lng: 17.78978 },
    { lat: 48.130166, lng: 17.789536 },
    { lat: 48.130184, lng: 17.789496 },
    { lat: 48.130209, lng: 17.789431 },
    { lat: 48.130234, lng: 17.789374 },
    { lat: 48.130259, lng: 17.789312 },
    { lat: 48.130288, lng: 17.789246 },
    { lat: 48.130374, lng: 17.789044 },
    { lat: 48.130408, lng: 17.78896 },
    { lat: 48.130426, lng: 17.788917 },
    { lat: 48.130454, lng: 17.788847 },
    { lat: 48.13047, lng: 17.788811 },
    { lat: 48.130961, lng: 17.787739 },
    { lat: 48.131002, lng: 17.787629 },
    { lat: 48.131018, lng: 17.787586 },
    { lat: 48.13104, lng: 17.787537 },
    { lat: 48.131157, lng: 17.787234 },
    { lat: 48.1309563, lng: 17.7868541 },
    { lat: 48.1306816, lng: 17.7864818 },
    { lat: 48.130585, lng: 17.7861823 },
    { lat: 48.1305024, lng: 17.785612 },
    { lat: 48.1297848, lng: 17.7846898 },
    { lat: 48.129172, lng: 17.7845464 },
    { lat: 48.1283728, lng: 17.7844723 },
    { lat: 48.1277771, lng: 17.7842824 },
    { lat: 48.1273731, lng: 17.7842827 },
    { lat: 48.1265125, lng: 17.7840576 },
    { lat: 48.124963, lng: 17.7820245 },
    { lat: 48.1240827, lng: 17.7799877 },
    { lat: 48.1236064, lng: 17.7798252 },
    { lat: 48.1223121, lng: 17.7791227 },
    { lat: 48.1204591, lng: 17.7777685 },
    { lat: 48.1190491, lng: 17.7769129 },
    { lat: 48.1189392, lng: 17.7767072 },
    { lat: 48.1182939, lng: 17.7761127 },
    { lat: 48.1181224, lng: 17.7758421 },
    { lat: 48.1179364, lng: 17.775701 },
    { lat: 48.1170338, lng: 17.7756007 },
    { lat: 48.1169349, lng: 17.7756076 },
    { lat: 48.1167989, lng: 17.7757462 },
    { lat: 48.116696, lng: 17.775269 },
    { lat: 48.116441, lng: 17.7748152 },
    { lat: 48.116313, lng: 17.7747534 },
    { lat: 48.1158674, lng: 17.7748118 },
    { lat: 48.1153517, lng: 17.7744701 },
    { lat: 48.1148055, lng: 17.7738549 },
    { lat: 48.114391, lng: 17.7735811 },
    { lat: 48.1138736, lng: 17.7730994 },
    { lat: 48.1137214, lng: 17.7728661 },
    { lat: 48.1134815, lng: 17.7722011 },
    { lat: 48.1132271, lng: 17.7725044 },
    { lat: 48.1129595, lng: 17.7727075 },
    { lat: 48.1125237, lng: 17.772649 },
    { lat: 48.1122693, lng: 17.7723729 },
    { lat: 48.111778, lng: 17.7712026 },
    { lat: 48.1115812, lng: 17.7709585 },
    { lat: 48.1111319, lng: 17.7706334 },
    { lat: 48.1108347, lng: 17.7705753 },
    { lat: 48.1106904, lng: 17.7705952 },
    { lat: 48.1104385, lng: 17.7707749 },
    { lat: 48.1103203, lng: 17.7710173 },
    { lat: 48.110211, lng: 17.7713677 },
    { lat: 48.1100102, lng: 17.7724796 },
    { lat: 48.1100301, lng: 17.7729834 },
    { lat: 48.109138, lng: 17.7739276 },
    { lat: 48.1089773, lng: 17.7735941 },
    { lat: 48.1088826, lng: 17.7735051 },
    { lat: 48.1084134, lng: 17.7734579 },
    { lat: 48.1080661, lng: 17.7733364 },
    { lat: 48.1070932, lng: 17.7737237 },
    { lat: 48.1060735, lng: 17.7743764 },
    { lat: 48.1055178, lng: 17.7755553 },
    { lat: 48.1052582, lng: 17.7756646 },
    { lat: 48.1052363, lng: 17.7757704 },
    { lat: 48.1047282, lng: 17.7759695 },
    { lat: 48.104164, lng: 17.7768094 },
    { lat: 48.1036083, lng: 17.7760772 },
    { lat: 48.1033962, lng: 17.7758999 },
    { lat: 48.1028801, lng: 17.7756604 },
    { lat: 48.10263, lng: 17.7755953 },
    { lat: 48.1022955, lng: 17.7756263 },
    { lat: 48.1015548, lng: 17.7760808 },
    { lat: 48.1012405, lng: 17.7767995 },
    { lat: 48.1011354, lng: 17.7772724 },
    { lat: 48.1011129, lng: 17.7779286 },
    { lat: 48.1009787, lng: 17.7793182 },
    { lat: 48.1009533, lng: 17.7800009 },
    { lat: 48.1010048, lng: 17.780851 },
    { lat: 48.1012688, lng: 17.7823259 },
    { lat: 48.1012317, lng: 17.7827276 },
    { lat: 48.1010854, lng: 17.7829364 },
    { lat: 48.1008744, lng: 17.7829567 },
    { lat: 48.1006444, lng: 17.7828525 },
    { lat: 48.1004728, lng: 17.7826907 },
    { lat: 48.1001014, lng: 17.7818156 },
    { lat: 48.0999121, lng: 17.7810929 },
    { lat: 48.0994345, lng: 17.7803448 },
    { lat: 48.0987745, lng: 17.7789094 },
    { lat: 48.098359, lng: 17.7784925 },
    { lat: 48.0981356, lng: 17.7783835 },
    { lat: 48.0975865, lng: 17.7782841 },
    { lat: 48.0972522, lng: 17.7783564 },
    { lat: 48.0958998, lng: 17.7792144 },
    { lat: 48.0955985, lng: 17.7794711 },
    { lat: 48.0951872, lng: 17.7800678 },
    { lat: 48.0949508, lng: 17.7805917 },
    { lat: 48.0948558, lng: 17.7811889 },
    { lat: 48.0949396, lng: 17.7825949 },
    { lat: 48.0948394, lng: 17.7833655 },
    { lat: 48.0945123, lng: 17.7840654 },
    { lat: 48.0940909, lng: 17.7845508 },
    { lat: 48.093776, lng: 17.7846793 },
    { lat: 48.0934769, lng: 17.7845179 },
    { lat: 48.0931041, lng: 17.7838192 },
    { lat: 48.0922402, lng: 17.7827437 },
    { lat: 48.0920549, lng: 17.7823565 },
    { lat: 48.0919158, lng: 17.7817337 },
    { lat: 48.0919576, lng: 17.7806011 },
    { lat: 48.0918992, lng: 17.7801702 },
    { lat: 48.0916494, lng: 17.779728 },
    { lat: 48.0911894, lng: 17.7793557 },
    { lat: 48.0907976, lng: 17.7794274 },
    { lat: 48.0904326, lng: 17.7797148 },
    { lat: 48.0901883, lng: 17.7800906 },
    { lat: 48.0901541, lng: 17.7802839 },
    { lat: 48.0901834, lng: 17.7811224 },
    { lat: 48.0900586, lng: 17.7822587 },
    { lat: 48.0899382, lng: 17.7828855 },
    { lat: 48.0898108, lng: 17.783254 },
    { lat: 48.0894249, lng: 17.7836586 },
    { lat: 48.0891374, lng: 17.7837728 },
    { lat: 48.0888547, lng: 17.7837302 },
    { lat: 48.088728, lng: 17.7835266 },
    { lat: 48.0884707, lng: 17.7827058 },
    { lat: 48.0884549, lng: 17.7818125 },
    { lat: 48.0883894, lng: 17.781412 },
    { lat: 48.0883127, lng: 17.7812315 },
    { lat: 48.0877942, lng: 17.7808243 },
    { lat: 48.0871, lng: 17.7805563 },
    { lat: 48.0864419, lng: 17.7808089 },
    { lat: 48.0863248, lng: 17.7805418 },
    { lat: 48.0849944, lng: 17.7795881 },
    { lat: 48.0845667, lng: 17.7792065 },
    { lat: 48.0843866, lng: 17.7789955 },
    { lat: 48.0841176, lng: 17.778538 },
    { lat: 48.0836759, lng: 17.7774373 },
    { lat: 48.0834338, lng: 17.7770471 },
    { lat: 48.0830549, lng: 17.7766898 },
    { lat: 48.0830045, lng: 17.7767702 },
    { lat: 48.0827105, lng: 17.7760725 },
    { lat: 48.0824347, lng: 17.7757562 },
    { lat: 48.0822684, lng: 17.7757689 },
    { lat: 48.0821, lng: 17.7761847 },
    { lat: 48.081987, lng: 17.7763267 },
    { lat: 48.0816606, lng: 17.7764551 },
    { lat: 48.0805698, lng: 17.7762343 },
    { lat: 48.080272, lng: 17.7760547 },
    { lat: 48.0799714, lng: 17.7756136 },
    { lat: 48.0797641, lng: 17.7754576 },
    { lat: 48.0794815, lng: 17.7753932 },
    { lat: 48.0791816, lng: 17.7754219 },
    { lat: 48.0786998, lng: 17.7756451 },
    { lat: 48.0778908, lng: 17.7762334 },
    { lat: 48.0766826, lng: 17.7740095 },
    { lat: 48.0783226, lng: 17.7717252 },
    { lat: 48.0717565, lng: 17.7598364 },
    { lat: 48.0716472, lng: 17.7596412 },
    { lat: 48.0715998, lng: 17.7619856 },
    { lat: 48.0712888, lng: 17.7631752 },
    { lat: 48.0708185, lng: 17.7640757 },
    { lat: 48.0699359, lng: 17.7653682 },
    { lat: 48.0694528, lng: 17.7657527 },
    { lat: 48.069051, lng: 17.7668096 },
    { lat: 48.0685278, lng: 17.7695105 },
    { lat: 48.068377, lng: 17.7700266 },
    { lat: 48.0681592, lng: 17.7704862 },
    { lat: 48.067891, lng: 17.770747 },
    { lat: 48.0675517, lng: 17.7709213 },
    { lat: 48.0672203, lng: 17.7709646 },
    { lat: 48.066315, lng: 17.7704056 },
    { lat: 48.0657585, lng: 17.7696411 },
    { lat: 48.0652786, lng: 17.7688384 },
    { lat: 48.0645591, lng: 17.7685598 },
    { lat: 48.0643733, lng: 17.7686239 },
    { lat: 48.0642125, lng: 17.7688533 },
    { lat: 48.0640421, lng: 17.7692905 },
    { lat: 48.0639628, lng: 17.7697056 },
    { lat: 48.0640261, lng: 17.7718353 },
    { lat: 48.0640821, lng: 17.7722458 },
    { lat: 48.0642323, lng: 17.7730464 },
    { lat: 48.0641712, lng: 17.7739516 },
    { lat: 48.0640457, lng: 17.7744616 },
    { lat: 48.0638174, lng: 17.7747473 },
    { lat: 48.0634003, lng: 17.7755216 },
    { lat: 48.0632537, lng: 17.7756725 },
    { lat: 48.0629504, lng: 17.77635 },
    { lat: 48.0626582, lng: 17.7768265 },
    { lat: 48.062254, lng: 17.777172 },
    { lat: 48.0614288, lng: 17.7775379 },
    { lat: 48.061159, lng: 17.7777191 },
    { lat: 48.0608403, lng: 17.7780821 },
    { lat: 48.060351, lng: 17.7788939 },
    { lat: 48.0601656, lng: 17.7796351 },
    { lat: 48.0601649, lng: 17.7801515 },
    { lat: 48.0603086, lng: 17.7810302 },
    { lat: 48.0604809, lng: 17.78128 },
    { lat: 48.0606702, lng: 17.7813449 },
    { lat: 48.0608512, lng: 17.7815169 },
    { lat: 48.060892, lng: 17.7820117 },
    { lat: 48.0608189, lng: 17.7827788 },
    { lat: 48.0611078, lng: 17.7832729 },
    { lat: 48.0614124, lng: 17.7833587 },
    { lat: 48.0618663, lng: 17.7832506 },
    { lat: 48.0625833, lng: 17.7826499 },
    { lat: 48.0628762, lng: 17.782276 },
    { lat: 48.0633518, lng: 17.781915 },
    { lat: 48.0636051, lng: 17.7819785 },
    { lat: 48.0637962, lng: 17.782183 },
    { lat: 48.064133, lng: 17.7828615 },
    { lat: 48.0642584, lng: 17.7833926 },
    { lat: 48.0642952, lng: 17.7840257 },
    { lat: 48.0641413, lng: 17.7851459 },
    { lat: 48.0638193, lng: 17.7865823 },
    { lat: 48.063664, lng: 17.7870466 },
    { lat: 48.0635238, lng: 17.7894327 },
    { lat: 48.0632519, lng: 17.7899667 },
    { lat: 48.0627775, lng: 17.7902723 },
    { lat: 48.0623642, lng: 17.7903984 },
    { lat: 48.0618342, lng: 17.7904083 },
    { lat: 48.0612217, lng: 17.790217 },
    { lat: 48.0604035, lng: 17.7898547 },
    { lat: 48.0598146, lng: 17.7896976 },
    { lat: 48.0592949, lng: 17.7897826 },
    { lat: 48.0591195, lng: 17.7899034 },
    { lat: 48.058905, lng: 17.7902146 },
    { lat: 48.0587052, lng: 17.7908155 },
    { lat: 48.0586832, lng: 17.7912089 },
    { lat: 48.0587268, lng: 17.7917308 },
    { lat: 48.0590055, lng: 17.7930087 },
    { lat: 48.0591052, lng: 17.7932508 },
    { lat: 48.0597754, lng: 17.7941185 },
    { lat: 48.060191, lng: 17.7943782 },
    { lat: 48.0604329, lng: 17.7947133 },
    { lat: 48.061039, lng: 17.7952566 },
    { lat: 48.0616432, lng: 17.7960819 },
    { lat: 48.0620723, lng: 17.7969764 },
    { lat: 48.0623564, lng: 17.7979756 },
    { lat: 48.062689, lng: 17.7986646 },
    { lat: 48.0629241, lng: 17.7993438 },
    { lat: 48.0630706, lng: 17.7999426 },
    { lat: 48.0631274, lng: 17.8003686 },
    { lat: 48.0631082, lng: 17.8004933 },
    { lat: 48.0629506, lng: 17.8011975 },
    { lat: 48.0628078, lng: 17.8016082 },
    { lat: 48.0626105, lng: 17.8018877 },
    { lat: 48.0623074, lng: 17.80217 },
    { lat: 48.0619832, lng: 17.8023487 },
    { lat: 48.0616092, lng: 17.8024375 },
    { lat: 48.0611668, lng: 17.8024175 },
    { lat: 48.0607439, lng: 17.8022999 },
    { lat: 48.060343, lng: 17.8020223 },
    { lat: 48.0600398, lng: 17.80192 },
    { lat: 48.0594722, lng: 17.801961 },
    { lat: 48.0589515, lng: 17.802306 },
    { lat: 48.0586814, lng: 17.8033793 },
    { lat: 48.0587736, lng: 17.8041789 },
    { lat: 48.0589726, lng: 17.804784 },
    { lat: 48.0596512, lng: 17.8063772 },
    { lat: 48.0605218, lng: 17.8075876 },
    { lat: 48.060974, lng: 17.807961 },
    { lat: 48.0614709, lng: 17.8081666 },
    { lat: 48.0617598, lng: 17.8082017 },
    { lat: 48.0620598, lng: 17.8081407 },
    { lat: 48.0625717, lng: 17.8078026 },
    { lat: 48.0633646, lng: 17.8066945 },
    { lat: 48.0637871, lng: 17.8063488 },
    { lat: 48.0641664, lng: 17.806277 },
    { lat: 48.0645225, lng: 17.8063011 },
    { lat: 48.0648037, lng: 17.8065952 },
    { lat: 48.0649879, lng: 17.8069663 },
    { lat: 48.0651111, lng: 17.8075899 },
    { lat: 48.0651416, lng: 17.8080114 },
    { lat: 48.065097, lng: 17.8084961 },
    { lat: 48.0645894, lng: 17.8098917 },
    { lat: 48.0641681, lng: 17.8106216 },
    { lat: 48.0635198, lng: 17.8119539 },
    { lat: 48.0632581, lng: 17.8140964 },
    { lat: 48.0632682, lng: 17.8145722 },
    { lat: 48.0633093, lng: 17.8148739 },
    { lat: 48.0637473, lng: 17.8162996 },
    { lat: 48.0638426, lng: 17.8164757 },
    { lat: 48.0639669, lng: 17.8163461 },
    { lat: 48.0642663, lng: 17.8155472 },
    { lat: 48.0644289, lng: 17.8153248 },
    { lat: 48.06539, lng: 17.814621 },
    { lat: 48.065693, lng: 17.814319 },
    { lat: 48.065963, lng: 17.814156 },
    { lat: 48.066215, lng: 17.814191 },
    { lat: 48.066513, lng: 17.814361 },
    { lat: 48.06667, lng: 17.814495 },
    { lat: 48.066891, lng: 17.814781 },
    { lat: 48.06705, lng: 17.815062 },
    { lat: 48.067669, lng: 17.816252 },
    { lat: 48.067756, lng: 17.81634 },
    { lat: 48.067864, lng: 17.816427 },
    { lat: 48.068002, lng: 17.816448 },
    { lat: 48.068101, lng: 17.816407 },
    { lat: 48.068347, lng: 17.816138 },
    { lat: 48.068444, lng: 17.815973 },
    { lat: 48.068493, lng: 17.815852 },
    { lat: 48.068514, lng: 17.815731 },
    { lat: 48.068474, lng: 17.815457 },
    { lat: 48.06834, lng: 17.815156 },
    { lat: 48.068291, lng: 17.815015 },
    { lat: 48.068255, lng: 17.814826 },
    { lat: 48.068246, lng: 17.81469 },
    { lat: 48.068284, lng: 17.814293 },
    { lat: 48.068306, lng: 17.814108 },
    { lat: 48.06843, lng: 17.813603 },
    { lat: 48.068554, lng: 17.813164 },
    { lat: 48.06878, lng: 17.812709 },
    { lat: 48.068858, lng: 17.812588 },
    { lat: 48.069064, lng: 17.812441 },
    { lat: 48.069177, lng: 17.812325 },
    { lat: 48.069965, lng: 17.811267 },
    { lat: 48.070255, lng: 17.811008 },
    { lat: 48.070606, lng: 17.810866 },
    { lat: 48.071021, lng: 17.810651 },
    { lat: 48.071186, lng: 17.810524 },
    { lat: 48.071479, lng: 17.810038 },
    { lat: 48.07154, lng: 17.80978 },
    { lat: 48.071566, lng: 17.809485 },
    { lat: 48.071536, lng: 17.809241 },
    { lat: 48.071467, lng: 17.809039 },
    { lat: 48.071304, lng: 17.808776 },
    { lat: 48.071032, lng: 17.808387 },
    { lat: 48.070966, lng: 17.808277 },
    { lat: 48.070659, lng: 17.8075 },
    { lat: 48.070557, lng: 17.806888 },
    { lat: 48.070479, lng: 17.806238 },
    { lat: 48.070481, lng: 17.805998 },
    { lat: 48.070536, lng: 17.805774 },
    { lat: 48.070611, lng: 17.805619 },
    { lat: 48.07075, lng: 17.805507 },
    { lat: 48.070831, lng: 17.805501 },
    { lat: 48.070878, lng: 17.805494 },
    { lat: 48.071124, lng: 17.805712 },
    { lat: 48.071211, lng: 17.805812 },
    { lat: 48.071374, lng: 17.80605 },
    { lat: 48.071534, lng: 17.806249 },
    { lat: 48.072375, lng: 17.807025 },
    { lat: 48.0727, lng: 17.807253 },
    { lat: 48.072917, lng: 17.807312 },
    { lat: 48.073039, lng: 17.807211 },
    { lat: 48.073144, lng: 17.807053 },
    { lat: 48.073187, lng: 17.806875 },
    { lat: 48.073214, lng: 17.806621 },
    { lat: 48.07321, lng: 17.806328 },
    { lat: 48.073156, lng: 17.805733 },
    { lat: 48.073142, lng: 17.805045 },
    { lat: 48.073152, lng: 17.804781 },
    { lat: 48.073251, lng: 17.804427 },
    { lat: 48.073328, lng: 17.804199 },
    { lat: 48.073415, lng: 17.803982 },
    { lat: 48.073535, lng: 17.803779 },
    { lat: 48.073751, lng: 17.803528 },
    { lat: 48.074112, lng: 17.803287 },
    { lat: 48.074348, lng: 17.80318 },
    { lat: 48.074905, lng: 17.80306 },
    { lat: 48.075342, lng: 17.802976 },
    { lat: 48.07603, lng: 17.802673 },
    { lat: 48.076145, lng: 17.8026 },
    { lat: 48.076209, lng: 17.802792 },
    { lat: 48.076645, lng: 17.802868 },
    { lat: 48.07668, lng: 17.802885 },
    { lat: 48.07676, lng: 17.802922 },
    { lat: 48.07678, lng: 17.802931 },
    { lat: 48.076788, lng: 17.802935 },
    { lat: 48.077381, lng: 17.803205 },
    { lat: 48.077721, lng: 17.803365 },
    { lat: 48.07797, lng: 17.803459 },
    { lat: 48.07805, lng: 17.803493 },
    { lat: 48.078698, lng: 17.803764 },
    { lat: 48.078829, lng: 17.803811 },
    { lat: 48.079291, lng: 17.804003 },
    { lat: 48.079448, lng: 17.804069 },
    { lat: 48.079524, lng: 17.804102 },
    { lat: 48.07958, lng: 17.804123 },
    { lat: 48.079636, lng: 17.804147 },
    { lat: 48.079729, lng: 17.804184 },
    { lat: 48.080022, lng: 17.804306 },
    { lat: 48.080152, lng: 17.804359 },
    { lat: 48.080286, lng: 17.804412 },
    { lat: 48.080526, lng: 17.804517 },
    { lat: 48.080757, lng: 17.804614 },
    { lat: 48.081044, lng: 17.804711 },
    { lat: 48.081083, lng: 17.804724 },
    { lat: 48.081223, lng: 17.80477 },
    { lat: 48.081476, lng: 17.804851 },
    { lat: 48.081518, lng: 17.804864 },
    { lat: 48.081567, lng: 17.804879 },
    { lat: 48.081627, lng: 17.804897 },
    { lat: 48.082005, lng: 17.805013 },
    { lat: 48.082546, lng: 17.805188 },
    { lat: 48.083129, lng: 17.805359 },
    { lat: 48.083757, lng: 17.805546 },
    { lat: 48.083825, lng: 17.805567 },
    { lat: 48.083901, lng: 17.805589 },
    { lat: 48.08402, lng: 17.805624 },
    { lat: 48.084063, lng: 17.805636 },
    { lat: 48.084098, lng: 17.805645 },
    { lat: 48.084142, lng: 17.805659 },
    { lat: 48.084249, lng: 17.805693 },
    { lat: 48.084293, lng: 17.805708 },
    { lat: 48.084339, lng: 17.805719 },
    { lat: 48.084395, lng: 17.80574 },
    { lat: 48.084443, lng: 17.805758 },
    { lat: 48.084556, lng: 17.805806 },
    { lat: 48.084735, lng: 17.805869 },
    { lat: 48.084804, lng: 17.805898 },
    { lat: 48.084865, lng: 17.805918 },
    { lat: 48.084927, lng: 17.805945 },
    { lat: 48.085034, lng: 17.805987 },
    { lat: 48.085074, lng: 17.806004 },
    { lat: 48.085151, lng: 17.806034 },
    { lat: 48.08519, lng: 17.806046 },
    { lat: 48.085238, lng: 17.806061 },
    { lat: 48.085284, lng: 17.806071 },
    { lat: 48.085306, lng: 17.806078 },
    { lat: 48.085331, lng: 17.806088 },
    { lat: 48.085414, lng: 17.80611 },
    { lat: 48.085509, lng: 17.80614 },
    { lat: 48.085583, lng: 17.80616 },
    { lat: 48.085647, lng: 17.806181 },
    { lat: 48.085676, lng: 17.806189 },
    { lat: 48.085716, lng: 17.806197 },
    { lat: 48.085767, lng: 17.806213 },
    { lat: 48.085862, lng: 17.806241 },
    { lat: 48.085916, lng: 17.806256 },
    { lat: 48.085962, lng: 17.80627 },
    { lat: 48.086001, lng: 17.806279 },
    { lat: 48.086073, lng: 17.806303 },
    { lat: 48.086153, lng: 17.806326 },
    { lat: 48.086187, lng: 17.806335 },
    { lat: 48.086227, lng: 17.80635 },
    { lat: 48.086262, lng: 17.806358 },
    { lat: 48.0863, lng: 17.806367 },
    { lat: 48.08634, lng: 17.80638 },
    { lat: 48.086405, lng: 17.806401 },
    { lat: 48.086464, lng: 17.806415 },
    { lat: 48.086704, lng: 17.806488 },
    { lat: 48.086752, lng: 17.806508 },
    { lat: 48.086784, lng: 17.806518 },
    { lat: 48.086867, lng: 17.806544 },
    { lat: 48.086938, lng: 17.806566 },
    { lat: 48.087099, lng: 17.80662 },
    { lat: 48.087144, lng: 17.806636 },
    { lat: 48.087181, lng: 17.806648 },
    { lat: 48.087218, lng: 17.80666 },
    { lat: 48.087415, lng: 17.806721 },
    { lat: 48.087473, lng: 17.806743 },
    { lat: 48.087602, lng: 17.806786 },
    { lat: 48.087709, lng: 17.806822 },
    { lat: 48.08776, lng: 17.806839 },
    { lat: 48.087816, lng: 17.806859 },
    { lat: 48.087832, lng: 17.806864 },
    { lat: 48.087851, lng: 17.806869 },
    { lat: 48.087871, lng: 17.806876 },
    { lat: 48.087896, lng: 17.806889 },
    { lat: 48.087918, lng: 17.806891 },
    { lat: 48.088056, lng: 17.806936 },
    { lat: 48.088215, lng: 17.806986 },
    { lat: 48.088346, lng: 17.807028 },
    { lat: 48.088802, lng: 17.807451 },
    { lat: 48.089482, lng: 17.808075 },
    { lat: 48.08951, lng: 17.808101 },
    { lat: 48.089668, lng: 17.808249 },
    { lat: 48.090036, lng: 17.808576 },
    { lat: 48.090679, lng: 17.809138 },
    { lat: 48.091493, lng: 17.809857 },
    { lat: 48.092057, lng: 17.810344 },
    { lat: 48.092153, lng: 17.810427 },
    { lat: 48.092211, lng: 17.810476 },
    { lat: 48.092354, lng: 17.8106 },
    { lat: 48.092815, lng: 17.811018 },
    { lat: 48.09286, lng: 17.811059 },
    { lat: 48.093679, lng: 17.811807 },
    { lat: 48.095541, lng: 17.813509 },
    { lat: 48.097358, lng: 17.815079 },
    { lat: 48.09798, lng: 17.815497 },
    { lat: 48.09802, lng: 17.815522 },
    { lat: 48.098155, lng: 17.815617 },
    { lat: 48.098272, lng: 17.815693 },
    { lat: 48.0985, lng: 17.815846 },
    { lat: 48.098573, lng: 17.815894 },
    { lat: 48.098643, lng: 17.815938 },
    { lat: 48.098706, lng: 17.815983 },
    { lat: 48.098755, lng: 17.816011 },
    { lat: 48.098934, lng: 17.816138 },
    { lat: 48.099036, lng: 17.816204 },
    { lat: 48.099144, lng: 17.816283 },
    { lat: 48.10129, lng: 17.817765 },
    { lat: 48.102236, lng: 17.818416 },
    { lat: 48.10233, lng: 17.818481 },
    { lat: 48.102693, lng: 17.81873 },
    { lat: 48.102773, lng: 17.818786 },
    { lat: 48.102843, lng: 17.818834 },
    { lat: 48.102986, lng: 17.818934 },
    { lat: 48.103052, lng: 17.818978 },
    { lat: 48.103383, lng: 17.819205 },
    { lat: 48.103495, lng: 17.819278 },
    { lat: 48.103603, lng: 17.819353 },
    { lat: 48.103713, lng: 17.819431 },
    { lat: 48.103823, lng: 17.819505 },
    { lat: 48.103941, lng: 17.819584 },
    { lat: 48.10403, lng: 17.819642 },
    { lat: 48.10413, lng: 17.819708 },
    { lat: 48.104188, lng: 17.81975 },
    { lat: 48.104246, lng: 17.819789 },
    { lat: 48.104318, lng: 17.819842 },
    { lat: 48.10448, lng: 17.819952 },
    { lat: 48.10452, lng: 17.819979 },
    { lat: 48.104568, lng: 17.819983 },
    { lat: 48.104812, lng: 17.820002 },
    { lat: 48.104905, lng: 17.820011 },
    { lat: 48.104988, lng: 17.820022 },
    { lat: 48.105029, lng: 17.820025 },
    { lat: 48.105071, lng: 17.820027 },
    { lat: 48.105145, lng: 17.820035 },
    { lat: 48.105232, lng: 17.82004 },
    { lat: 48.105565, lng: 17.820072 },
    { lat: 48.105633, lng: 17.820079 },
    { lat: 48.105754, lng: 17.820104 },
  ],
  Mostová: [
    { lat: 48.093184, lng: 17.62106 },
    { lat: 48.093214, lng: 17.621203 },
    { lat: 48.093231, lng: 17.621451 },
    { lat: 48.093263, lng: 17.621632 },
    { lat: 48.093295, lng: 17.621691 },
    { lat: 48.093348, lng: 17.622009 },
    { lat: 48.093406, lng: 17.622301 },
    { lat: 48.093454, lng: 17.622702 },
    { lat: 48.093588, lng: 17.623619 },
    { lat: 48.093621, lng: 17.624024 },
    { lat: 48.09363, lng: 17.624931 },
    { lat: 48.093618, lng: 17.625602 },
    { lat: 48.09361, lng: 17.626017 },
    { lat: 48.093616, lng: 17.626048 },
    { lat: 48.093745, lng: 17.626626 },
    { lat: 48.093722, lng: 17.62699 },
    { lat: 48.093699, lng: 17.627397 },
    { lat: 48.093698, lng: 17.627429 },
    { lat: 48.093686, lng: 17.627783 },
    { lat: 48.093669, lng: 17.628154 },
    { lat: 48.093664, lng: 17.628286 },
    { lat: 48.093645, lng: 17.628905 },
    { lat: 48.093631, lng: 17.629507 },
    { lat: 48.093618, lng: 17.630038 },
    { lat: 48.093595, lng: 17.630867 },
    { lat: 48.093597, lng: 17.631476 },
    { lat: 48.093597, lng: 17.631502 },
    { lat: 48.093614, lng: 17.631952 },
    { lat: 48.093617, lng: 17.632268 },
    { lat: 48.093624, lng: 17.632668 },
    { lat: 48.093627, lng: 17.633129 },
    { lat: 48.0936, lng: 17.633612 },
    { lat: 48.093579, lng: 17.633934 },
    { lat: 48.09356, lng: 17.634154 },
    { lat: 48.093543, lng: 17.634466 },
    { lat: 48.093505, lng: 17.634785 },
    { lat: 48.093461, lng: 17.635281 },
    { lat: 48.093431, lng: 17.635623 },
    { lat: 48.093401, lng: 17.635935 },
    { lat: 48.093395, lng: 17.635973 },
    { lat: 48.093337, lng: 17.63635 },
    { lat: 48.093275, lng: 17.63678 },
    { lat: 48.093254, lng: 17.636891 },
    { lat: 48.093162, lng: 17.637513 },
    { lat: 48.093128, lng: 17.637713 },
    { lat: 48.093029, lng: 17.638256 },
    { lat: 48.092943, lng: 17.638688 },
    { lat: 48.092873, lng: 17.639032 },
    { lat: 48.092823, lng: 17.639297 },
    { lat: 48.092797, lng: 17.639452 },
    { lat: 48.092736, lng: 17.639738 },
    { lat: 48.092676, lng: 17.640042 },
    { lat: 48.092562, lng: 17.640587 },
    { lat: 48.092445, lng: 17.641043 },
    { lat: 48.092276, lng: 17.641733 },
    { lat: 48.092021, lng: 17.642507 },
    { lat: 48.092018, lng: 17.642519 },
    { lat: 48.091808, lng: 17.643165 },
    { lat: 48.091791, lng: 17.643217 },
    { lat: 48.091642, lng: 17.643634 },
    { lat: 48.09148, lng: 17.644103 },
    { lat: 48.091275, lng: 17.644645 },
    { lat: 48.091065, lng: 17.645149 },
    { lat: 48.090848, lng: 17.645658 },
    { lat: 48.090703, lng: 17.645984 },
    { lat: 48.090526, lng: 17.646379 },
    { lat: 48.09041, lng: 17.646619 },
    { lat: 48.090206, lng: 17.647038 },
    { lat: 48.090103, lng: 17.647231 },
    { lat: 48.089985, lng: 17.647437 },
    { lat: 48.08997, lng: 17.647463 },
    { lat: 48.088973, lng: 17.649045 },
    { lat: 48.088587, lng: 17.649612 },
    { lat: 48.088184, lng: 17.650055 },
    { lat: 48.087648, lng: 17.650616 },
    { lat: 48.087606, lng: 17.650687 },
    { lat: 48.087201, lng: 17.651117 },
    { lat: 48.086954, lng: 17.651384 },
    { lat: 48.08657, lng: 17.651772 },
    { lat: 48.086322, lng: 17.652035 },
    { lat: 48.08631, lng: 17.652052 },
    { lat: 48.086277, lng: 17.652087 },
    { lat: 48.086258, lng: 17.652105 },
    { lat: 48.086219, lng: 17.652143 },
    { lat: 48.086182, lng: 17.652184 },
    { lat: 48.086118, lng: 17.652253 },
    { lat: 48.086077, lng: 17.652297 },
    { lat: 48.085712, lng: 17.652697 },
    { lat: 48.085513, lng: 17.652981 },
    { lat: 48.085419, lng: 17.653119 },
    { lat: 48.085227, lng: 17.653449 },
    { lat: 48.085066, lng: 17.653997 },
    { lat: 48.085004, lng: 17.654252 },
    { lat: 48.08496, lng: 17.65438 },
    { lat: 48.084899, lng: 17.655019 },
    { lat: 48.084966, lng: 17.655526 },
    { lat: 48.085101, lng: 17.656186 },
    { lat: 48.085172, lng: 17.656531 },
    { lat: 48.085306, lng: 17.657038 },
    { lat: 48.085444, lng: 17.657549 },
    { lat: 48.085613, lng: 17.658185 },
    { lat: 48.085715, lng: 17.658552 },
    { lat: 48.085866, lng: 17.659061 },
    { lat: 48.085963, lng: 17.65937 },
    { lat: 48.086023, lng: 17.659562 },
    { lat: 48.086194, lng: 17.660443 },
    { lat: 48.086577, lng: 17.661213 },
    { lat: 48.086757, lng: 17.661651 },
    { lat: 48.08701, lng: 17.662336 },
    { lat: 48.08735, lng: 17.663116 },
    { lat: 48.087767, lng: 17.66407 },
    { lat: 48.08788, lng: 17.664335 },
    { lat: 48.088094, lng: 17.665067 },
    { lat: 48.088416, lng: 17.666124 },
    { lat: 48.0888139, lng: 17.6660262 },
    { lat: 48.0891754, lng: 17.6644689 },
    { lat: 48.0892116, lng: 17.6637505 },
    { lat: 48.0892201, lng: 17.6635826 },
    { lat: 48.0891899, lng: 17.6632293 },
    { lat: 48.0891734, lng: 17.6630364 },
    { lat: 48.0890328, lng: 17.6625236 },
    { lat: 48.088869, lng: 17.662159 },
    { lat: 48.0886122, lng: 17.6615874 },
    { lat: 48.0888799, lng: 17.6607436 },
    { lat: 48.0891002, lng: 17.6603673 },
    { lat: 48.089329, lng: 17.6601463 },
    { lat: 48.089368, lng: 17.6598526 },
    { lat: 48.0899848, lng: 17.6587489 },
    { lat: 48.0903834, lng: 17.6581752 },
    { lat: 48.0906186, lng: 17.6578197 },
    { lat: 48.090892, lng: 17.6574964 },
    { lat: 48.0909799, lng: 17.6573925 },
    { lat: 48.09134, lng: 17.6571032 },
    { lat: 48.0915135, lng: 17.6570329 },
    { lat: 48.0920117, lng: 17.6568312 },
    { lat: 48.0922114, lng: 17.656776 },
    { lat: 48.0927413, lng: 17.6567967 },
    { lat: 48.0930196, lng: 17.6568076 },
    { lat: 48.0932329, lng: 17.6569044 },
    { lat: 48.0935147, lng: 17.6570323 },
    { lat: 48.0935869, lng: 17.6570796 },
    { lat: 48.0938488, lng: 17.6573955 },
    { lat: 48.0939923, lng: 17.6575515 },
    { lat: 48.0941155, lng: 17.6578138 },
    { lat: 48.0945408, lng: 17.6584235 },
    { lat: 48.0946296, lng: 17.658585 },
    { lat: 48.0954222, lng: 17.6600254 },
    { lat: 48.0957318, lng: 17.6605881 },
    { lat: 48.0960817, lng: 17.6610916 },
    { lat: 48.0963104, lng: 17.6614206 },
    { lat: 48.096379, lng: 17.6614749 },
    { lat: 48.0965896, lng: 17.6616417 },
    { lat: 48.0966904, lng: 17.6616848 },
    { lat: 48.0969721, lng: 17.6618052 },
    { lat: 48.097019, lng: 17.6618252 },
    { lat: 48.0972375, lng: 17.6617168 },
    { lat: 48.0973441, lng: 17.661664 },
    { lat: 48.0976749, lng: 17.661284 },
    { lat: 48.098049, lng: 17.6608543 },
    { lat: 48.0983046, lng: 17.6605607 },
    { lat: 48.0988201, lng: 17.6605374 },
    { lat: 48.0992219, lng: 17.660695 },
    { lat: 48.0995951, lng: 17.6609553 },
    { lat: 48.0997186, lng: 17.6611064 },
    { lat: 48.0999495, lng: 17.6616353 },
    { lat: 48.100177, lng: 17.6625203 },
    { lat: 48.1003748, lng: 17.6636727 },
    { lat: 48.1006294, lng: 17.6646137 },
    { lat: 48.1008888, lng: 17.6651294 },
    { lat: 48.1013171, lng: 17.665762 },
    { lat: 48.1014423, lng: 17.6660295 },
    { lat: 48.1015841, lng: 17.6665787 },
    { lat: 48.1018262, lng: 17.6669248 },
    { lat: 48.1019721, lng: 17.6670559 },
    { lat: 48.1025683, lng: 17.6672884 },
    { lat: 48.1034286, lng: 17.6674687 },
    { lat: 48.1042056, lng: 17.6674862 },
    { lat: 48.1045514, lng: 17.6673829 },
    { lat: 48.1052299, lng: 17.6668963 },
    { lat: 48.1054182, lng: 17.6666972 },
    { lat: 48.1057074, lng: 17.6662503 },
    { lat: 48.1060855, lng: 17.6653865 },
    { lat: 48.1065208, lng: 17.6637085 },
    { lat: 48.1066124, lng: 17.6634985 },
    { lat: 48.1069948, lng: 17.6630788 },
    { lat: 48.1075069, lng: 17.6630342 },
    { lat: 48.1075144, lng: 17.6623124 },
    { lat: 48.1072584, lng: 17.6605763 },
    { lat: 48.1064744, lng: 17.6586702 },
    { lat: 48.1057428, lng: 17.6578474 },
    { lat: 48.1051533, lng: 17.6575769 },
    { lat: 48.1047775, lng: 17.6574739 },
    { lat: 48.1040949, lng: 17.6575189 },
    { lat: 48.103497, lng: 17.6577403 },
    { lat: 48.1026174, lng: 17.658351 },
    { lat: 48.1017048, lng: 17.6591553 },
    { lat: 48.1007096, lng: 17.659826 },
    { lat: 48.1004522, lng: 17.6598985 },
    { lat: 48.1001703, lng: 17.6598335 },
    { lat: 48.099732, lng: 17.6596171 },
    { lat: 48.0994098, lng: 17.659449 },
    { lat: 48.0988753, lng: 17.6590284 },
    { lat: 48.0987923, lng: 17.6590553 },
    { lat: 48.0982103, lng: 17.6580085 },
    { lat: 48.0980672, lng: 17.656295 },
    { lat: 48.098069, lng: 17.6561452 },
    { lat: 48.0980717, lng: 17.6559185 },
    { lat: 48.0981435, lng: 17.6553132 },
    { lat: 48.0982251, lng: 17.6546257 },
    { lat: 48.0990083, lng: 17.65222 },
    { lat: 48.0993512, lng: 17.6517822 },
    { lat: 48.0999774, lng: 17.651454 },
    { lat: 48.1018807, lng: 17.6509632 },
    { lat: 48.1028498, lng: 17.6508269 },
    { lat: 48.1038935, lng: 17.6504022 },
    { lat: 48.104385, lng: 17.6501179 },
    { lat: 48.1054383, lng: 17.6492212 },
    { lat: 48.1076374, lng: 17.6512352 },
    { lat: 48.1075434, lng: 17.6508371 },
    { lat: 48.107692, lng: 17.6501316 },
    { lat: 48.1086108, lng: 17.6483023 },
    { lat: 48.109174, lng: 17.6475122 },
    { lat: 48.1096272, lng: 17.647033 },
    { lat: 48.1098216, lng: 17.6469297 },
    { lat: 48.1100998, lng: 17.6469891 },
    { lat: 48.1109343, lng: 17.6474914 },
    { lat: 48.1111434, lng: 17.6475507 },
    { lat: 48.111998, lng: 17.6474414 },
    { lat: 48.112302, lng: 17.6473202 },
    { lat: 48.1126927, lng: 17.6469946 },
    { lat: 48.1132396, lng: 17.6451768 },
    { lat: 48.1136824, lng: 17.6446085 },
    { lat: 48.1140348, lng: 17.6444306 },
    { lat: 48.1147232, lng: 17.6443154 },
    { lat: 48.1157289, lng: 17.6438819 },
    { lat: 48.1167295, lng: 17.6432033 },
    { lat: 48.1170871, lng: 17.6426614 },
    { lat: 48.1171915, lng: 17.642275 },
    { lat: 48.1172637, lng: 17.6414899 },
    { lat: 48.1172029, lng: 17.6407405 },
    { lat: 48.1170342, lng: 17.639965 },
    { lat: 48.1167561, lng: 17.6393861 },
    { lat: 48.1165598, lng: 17.6391212 },
    { lat: 48.116264, lng: 17.6390162 },
    { lat: 48.1158919, lng: 17.6389545 },
    { lat: 48.115241, lng: 17.6390338 },
    { lat: 48.1147691, lng: 17.638983 },
    { lat: 48.1146021, lng: 17.6388228 },
    { lat: 48.1142874, lng: 17.6380258 },
    { lat: 48.114204, lng: 17.6375256 },
    { lat: 48.1142794, lng: 17.6366097 },
    { lat: 48.114948, lng: 17.6348081 },
    { lat: 48.1151069, lng: 17.6344811 },
    { lat: 48.1152619, lng: 17.63429 },
    { lat: 48.1155331, lng: 17.6341143 },
    { lat: 48.1160075, lng: 17.6340803 },
    { lat: 48.1164226, lng: 17.6344483 },
    { lat: 48.1169078, lng: 17.6352669 },
    { lat: 48.1172416, lng: 17.6356138 },
    { lat: 48.1178491, lng: 17.6358847 },
    { lat: 48.1183522, lng: 17.6360177 },
    { lat: 48.1188632, lng: 17.636046 },
    { lat: 48.1186904, lng: 17.6343304 },
    { lat: 48.1187503, lng: 17.6331887 },
    { lat: 48.1181027, lng: 17.6289647 },
    { lat: 48.1178124, lng: 17.6279279 },
    { lat: 48.1175869, lng: 17.6275777 },
    { lat: 48.1167672, lng: 17.6265587 },
    { lat: 48.1161261, lng: 17.6255471 },
    { lat: 48.1153488, lng: 17.6252006 },
    { lat: 48.1147555, lng: 17.6246942 },
    { lat: 48.1142172, lng: 17.6238084 },
    { lat: 48.1136152, lng: 17.6229842 },
    { lat: 48.1129551, lng: 17.6224022 },
    { lat: 48.1121716, lng: 17.6221033 },
    { lat: 48.1105935, lng: 17.6217163 },
    { lat: 48.1081967, lng: 17.621335 },
    { lat: 48.1050123, lng: 17.6203153 },
    { lat: 48.1041983, lng: 17.6201586 },
    { lat: 48.1034523, lng: 17.6197001 },
    { lat: 48.1027307, lng: 17.6191117 },
    { lat: 48.1011828, lng: 17.6182229 },
    { lat: 48.101497, lng: 17.61773 },
    { lat: 48.101141, lng: 17.617628 },
    { lat: 48.1009, lng: 17.617475 },
    { lat: 48.100197, lng: 17.61702 },
    { lat: 48.099945, lng: 17.616727 },
    { lat: 48.099576, lng: 17.616297 },
    { lat: 48.099302, lng: 17.615978 },
    { lat: 48.099144, lng: 17.615721 },
    { lat: 48.098874, lng: 17.615054 },
    { lat: 48.098842, lng: 17.614961 },
    { lat: 48.098542, lng: 17.614076 },
    { lat: 48.098203, lng: 17.613146 },
    { lat: 48.098163, lng: 17.613035 },
    { lat: 48.097719, lng: 17.612051 },
    { lat: 48.097165, lng: 17.61102 },
    { lat: 48.097098, lng: 17.61093 },
    { lat: 48.096936, lng: 17.610678 },
    { lat: 48.096645, lng: 17.611002 },
    { lat: 48.096272, lng: 17.611344 },
    { lat: 48.095972, lng: 17.611818 },
    { lat: 48.095657, lng: 17.612199 },
    { lat: 48.095287, lng: 17.612648 },
    { lat: 48.094893, lng: 17.613166 },
    { lat: 48.094665, lng: 17.61349 },
    { lat: 48.094589, lng: 17.613561 },
    { lat: 48.094345, lng: 17.613633 },
    { lat: 48.094208, lng: 17.613608 },
    { lat: 48.093894, lng: 17.613509 },
    { lat: 48.093611, lng: 17.613432 },
    { lat: 48.093445, lng: 17.613397 },
    { lat: 48.093483, lng: 17.613538 },
    { lat: 48.093672, lng: 17.614239 },
    { lat: 48.093716, lng: 17.614377 },
    { lat: 48.093886, lng: 17.615066 },
    { lat: 48.093935, lng: 17.615266 },
    { lat: 48.094075, lng: 17.615725 },
    { lat: 48.094186, lng: 17.616083 },
    { lat: 48.09432, lng: 17.616837 },
    { lat: 48.094403, lng: 17.617283 },
    { lat: 48.094545, lng: 17.618306 },
    { lat: 48.094675, lng: 17.619228 },
    { lat: 48.094788, lng: 17.619998 },
    { lat: 48.094887, lng: 17.620668 },
    { lat: 48.094654, lng: 17.620635 },
    { lat: 48.093847, lng: 17.620524 },
    { lat: 48.093747, lng: 17.620518 },
    { lat: 48.093722, lng: 17.620551 },
    { lat: 48.093686, lng: 17.6206 },
    { lat: 48.093677, lng: 17.620666 },
    { lat: 48.093667, lng: 17.620738 },
    { lat: 48.0937, lng: 17.620926 },
    { lat: 48.093601, lng: 17.620956 },
    { lat: 48.093569, lng: 17.620964 },
    { lat: 48.093546, lng: 17.62097 },
    { lat: 48.093184, lng: 17.62106 },
  ],
  MostováExt: [
    { lat: 48.1523434, lng: 17.725331 },
    { lat: 48.152454, lng: 17.7252042 },
    { lat: 48.1526193, lng: 17.7251416 },
    { lat: 48.1531743, lng: 17.7252252 },
    { lat: 48.1535277, lng: 17.7251138 },
    { lat: 48.1538812, lng: 17.7248983 },
    { lat: 48.1542291, lng: 17.7244854 },
    { lat: 48.1544512, lng: 17.724371 },
    { lat: 48.1552568, lng: 17.7243786 },
    { lat: 48.1556773, lng: 17.7237207 },
    { lat: 48.1558339, lng: 17.7236215 },
    { lat: 48.1562423, lng: 17.7236371 },
    { lat: 48.1563665, lng: 17.7237421 },
    { lat: 48.1566477, lng: 17.7242538 },
    { lat: 48.1567471, lng: 17.7243447 },
    { lat: 48.1571366, lng: 17.7244524 },
    { lat: 48.1583618, lng: 17.7241806 },
    { lat: 48.1586013, lng: 17.7241861 },
    { lat: 48.1591067, lng: 17.7245248 },
    { lat: 48.1593379, lng: 17.7245372 },
    { lat: 48.1598495, lng: 17.7244323 },
    { lat: 48.1602098, lng: 17.7244691 },
    { lat: 48.1608923, lng: 17.7242245 },
    { lat: 48.1612303, lng: 17.7239776 },
    { lat: 48.1612329, lng: 17.7239998 },
    { lat: 48.1615243, lng: 17.723421 },
    { lat: 48.1617016, lng: 17.7226081 },
    { lat: 48.162012, lng: 17.7218646 },
    { lat: 48.1620295, lng: 17.7216015 },
    { lat: 48.1619661, lng: 17.7212574 },
    { lat: 48.1619961, lng: 17.7207596 },
    { lat: 48.1623656, lng: 17.7202197 },
    { lat: 48.162277, lng: 17.7189816 },
    { lat: 48.1623192, lng: 17.7187529 },
    { lat: 48.1623459, lng: 17.7187003 },
    { lat: 48.1624096, lng: 17.7185748 },
    { lat: 48.1623615, lng: 17.7183842 },
    { lat: 48.1625175, lng: 17.7172824 },
    { lat: 48.1625397, lng: 17.7162622 },
    { lat: 48.162407, lng: 17.7157439 },
    { lat: 48.1620632, lng: 17.7144017 },
    { lat: 48.1618695, lng: 17.7132885 },
    { lat: 48.1616451, lng: 17.7104154 },
    { lat: 48.1613945, lng: 17.70901 },
    { lat: 48.1611097, lng: 17.7079015 },
    { lat: 48.160495, lng: 17.7049867 },
    { lat: 48.16028, lng: 17.7022531 },
    { lat: 48.159845, lng: 17.7001564 },
    { lat: 48.1598358, lng: 17.7001149 },
    { lat: 48.1597176, lng: 17.699527 },
    { lat: 48.1595487, lng: 17.6985785 },
    { lat: 48.1594931, lng: 17.697547 },
    { lat: 48.1594927, lng: 17.6963309 },
    { lat: 48.1592978, lng: 17.6952202 },
    { lat: 48.1587028, lng: 17.6934713 },
    { lat: 48.1540249, lng: 17.6853614 },
    { lat: 48.1526147, lng: 17.6825739 },
    { lat: 48.1508727, lng: 17.6789591 },
    { lat: 48.1500933, lng: 17.6774974 },
    { lat: 48.1466374, lng: 17.6705852 },
    { lat: 48.1463685, lng: 17.6700788 },
    { lat: 48.1460407, lng: 17.6696214 },
    { lat: 48.1456997, lng: 17.6693341 },
    { lat: 48.1457506, lng: 17.667829 },
    { lat: 48.1453135, lng: 17.6670001 },
    { lat: 48.1452529, lng: 17.6659989 },
    { lat: 48.1446969, lng: 17.6660483 },
    { lat: 48.1443136, lng: 17.6655556 },
    { lat: 48.1438977, lng: 17.6647584 },
    { lat: 48.1440645, lng: 17.6644324 },
    { lat: 48.1440988, lng: 17.6639687 },
    { lat: 48.144211, lng: 17.6637966 },
    { lat: 48.144233, lng: 17.6634849 },
    { lat: 48.1441808, lng: 17.6633886 },
    { lat: 48.1441814, lng: 17.6632553 },
    { lat: 48.1443019, lng: 17.6631429 },
    { lat: 48.1446901, lng: 17.6631188 },
    { lat: 48.1449731, lng: 17.6629878 },
    { lat: 48.1451127, lng: 17.6626942 },
    { lat: 48.1451207, lng: 17.6624078 },
    { lat: 48.1451941, lng: 17.6621234 },
    { lat: 48.1455521, lng: 17.661691 },
    { lat: 48.1458153, lng: 17.6610126 },
    { lat: 48.1457963, lng: 17.6607668 },
    { lat: 48.1460333, lng: 17.6601335 },
    { lat: 48.1460692, lng: 17.6597911 },
    { lat: 48.14605, lng: 17.659377 },
    { lat: 48.1460877, lng: 17.6592055 },
    { lat: 48.1459833, lng: 17.6586584 },
    { lat: 48.1458224, lng: 17.6584176 },
    { lat: 48.1449405, lng: 17.6576627 },
    { lat: 48.144726, lng: 17.6574118 },
    { lat: 48.1440916, lng: 17.6564456 },
    { lat: 48.1439758, lng: 17.656079 },
    { lat: 48.143753, lng: 17.6558929 },
    { lat: 48.1435047, lng: 17.6554485 },
    { lat: 48.1434065, lng: 17.6549631 },
    { lat: 48.1431672, lng: 17.6546061 },
    { lat: 48.1426172, lng: 17.6544801 },
    { lat: 48.141946, lng: 17.6544169 },
    { lat: 48.1416494, lng: 17.6546399 },
    { lat: 48.141349, lng: 17.654585 },
    { lat: 48.1405902, lng: 17.654102 },
    { lat: 48.1399422, lng: 17.6538888 },
    { lat: 48.1388909, lng: 17.6538121 },
    { lat: 48.1381889, lng: 17.6539261 },
    { lat: 48.1376898, lng: 17.6538909 },
    { lat: 48.1373653, lng: 17.6537764 },
    { lat: 48.1367523, lng: 17.6530789 },
    { lat: 48.1366018, lng: 17.6530032 },
    { lat: 48.1364276, lng: 17.6530143 },
    { lat: 48.1359466, lng: 17.6532852 },
    { lat: 48.1354872, lng: 17.6531809 },
    { lat: 48.1347534, lng: 17.6531999 },
    { lat: 48.1345793, lng: 17.6533955 },
    { lat: 48.1340118, lng: 17.6543127 },
    { lat: 48.1336266, lng: 17.6545651 },
    { lat: 48.1330041, lng: 17.6548432 },
    { lat: 48.1327413, lng: 17.6545624 },
    { lat: 48.1325038, lng: 17.6554536 },
    { lat: 48.132464, lng: 17.6560385 },
    { lat: 48.1323735, lng: 17.6565556 },
    { lat: 48.1321977, lng: 17.65692 },
    { lat: 48.1305623, lng: 17.6565748 },
    { lat: 48.1292608, lng: 17.6556116 },
    { lat: 48.128515, lng: 17.6551725 },
    { lat: 48.1280613, lng: 17.6550161 },
    { lat: 48.1266564, lng: 17.6553899 },
    { lat: 48.1254766, lng: 17.6566994 },
    { lat: 48.1249973, lng: 17.6574251 },
    { lat: 48.1245136, lng: 17.6580437 },
    { lat: 48.1240381, lng: 17.6588308 },
    { lat: 48.1238536, lng: 17.659238 },
    { lat: 48.1236489, lng: 17.6602895 },
    { lat: 48.1235638, lng: 17.6617179 },
    { lat: 48.1230777, lng: 17.6625173 },
    { lat: 48.1227738, lng: 17.6631773 },
    { lat: 48.1223629, lng: 17.6638598 },
    { lat: 48.1221344, lng: 17.6646079 },
    { lat: 48.1219392, lng: 17.6654699 },
    { lat: 48.1215692, lng: 17.6664636 },
    { lat: 48.1213898, lng: 17.6671313 },
    { lat: 48.1213873, lng: 17.6677009 },
    { lat: 48.1214795, lng: 17.668735 },
    { lat: 48.1218544, lng: 17.6701726 },
    { lat: 48.1219218, lng: 17.6706512 },
    { lat: 48.1219336, lng: 17.6708764 },
    { lat: 48.1218308, lng: 17.6713216 },
    { lat: 48.1217248, lng: 17.6715778 },
    { lat: 48.1212372, lng: 17.6721353 },
    { lat: 48.1209298, lng: 17.6721688 },
    { lat: 48.1202489, lng: 17.671842 },
    { lat: 48.1195293, lng: 17.6713899 },
    { lat: 48.1188227, lng: 17.6710724 },
    { lat: 48.1183905, lng: 17.6707874 },
    { lat: 48.1175198, lng: 17.6705308 },
    { lat: 48.1171919, lng: 17.6705439 },
    { lat: 48.1169349, lng: 17.6706771 },
    { lat: 48.1166737, lng: 17.6713324 },
    { lat: 48.1166297, lng: 17.6716944 },
    { lat: 48.1166027, lng: 17.6722699 },
    { lat: 48.1167044, lng: 17.6729005 },
    { lat: 48.1170016, lng: 17.6736387 },
    { lat: 48.1172802, lng: 17.6740332 },
    { lat: 48.1177927, lng: 17.6744605 },
    { lat: 48.1184183, lng: 17.6749821 },
    { lat: 48.1185287, lng: 17.6751758 },
    { lat: 48.1186631, lng: 17.6756612 },
    { lat: 48.1186816, lng: 17.6759933 },
    { lat: 48.1185985, lng: 17.6769349 },
    { lat: 48.1183056, lng: 17.6776647 },
    { lat: 48.1181291, lng: 17.677853 },
    { lat: 48.1179565, lng: 17.6779457 },
    { lat: 48.1168213, lng: 17.6780295 },
    { lat: 48.1158563, lng: 17.6777298 },
    { lat: 48.1156756, lng: 17.6775696 },
    { lat: 48.1155203, lng: 17.6773602 },
    { lat: 48.115292, lng: 17.6787776 },
    { lat: 48.114912, lng: 17.6793079 },
    { lat: 48.1147288, lng: 17.6801133 },
    { lat: 48.1148877, lng: 17.6825336 },
    { lat: 48.1148001, lng: 17.6831554 },
    { lat: 48.1147898, lng: 17.6838698 },
    { lat: 48.1147021, lng: 17.684146 },
    { lat: 48.1146008, lng: 17.6852835 },
    { lat: 48.1147451, lng: 17.6854967 },
    { lat: 48.114841, lng: 17.6856385 },
    { lat: 48.1149999, lng: 17.6857434 },
    { lat: 48.1154003, lng: 17.6854655 },
    { lat: 48.1156135, lng: 17.6851036 },
    { lat: 48.1158357, lng: 17.6849004 },
    { lat: 48.1159951, lng: 17.6848689 },
    { lat: 48.1162061, lng: 17.6849556 },
    { lat: 48.116489, lng: 17.685523 },
    { lat: 48.1167743, lng: 17.6869587 },
    { lat: 48.117088, lng: 17.6876568 },
    { lat: 48.1171251, lng: 17.6879608 },
    { lat: 48.1169751, lng: 17.688486 },
    { lat: 48.1166666, lng: 17.6890217 },
    { lat: 48.1158819, lng: 17.6897056 },
    { lat: 48.1154223, lng: 17.6898314 },
    { lat: 48.1153591, lng: 17.689884 },
    { lat: 48.1153205, lng: 17.6900269 },
    { lat: 48.1153079, lng: 17.6902032 },
    { lat: 48.1153734, lng: 17.6903845 },
    { lat: 48.1155689, lng: 17.6905469 },
    { lat: 48.1158888, lng: 17.6906798 },
    { lat: 48.1159953, lng: 17.6907939 },
    { lat: 48.1161331, lng: 17.6911001 },
    { lat: 48.1161543, lng: 17.691314 },
    { lat: 48.1160689, lng: 17.6930121 },
    { lat: 48.1157974, lng: 17.6940118 },
    { lat: 48.1158103, lng: 17.6941831 },
    { lat: 48.1159416, lng: 17.6944057 },
    { lat: 48.1159098, lng: 17.6945999 },
    { lat: 48.11592, lng: 17.6947635 },
    { lat: 48.1187361, lng: 17.6974112 },
    { lat: 48.1204404, lng: 17.6988257 },
    { lat: 48.1219393, lng: 17.7002807 },
    { lat: 48.1237157, lng: 17.7022154 },
    { lat: 48.1251805, lng: 17.7036453 },
    { lat: 48.1289046, lng: 17.7081088 },
    { lat: 48.1304443, lng: 17.710398 },
    { lat: 48.1317046, lng: 17.7124442 },
    { lat: 48.1331868, lng: 17.7144985 },
    { lat: 48.1331838, lng: 17.7146134 },
    { lat: 48.1334655, lng: 17.7147109 },
    { lat: 48.1335627, lng: 17.7148575 },
    { lat: 48.1336667, lng: 17.7152961 },
    { lat: 48.133612, lng: 17.7156476 },
    { lat: 48.1334868, lng: 17.7160295 },
    { lat: 48.1335124, lng: 17.7164207 },
    { lat: 48.1336004, lng: 17.716711 },
    { lat: 48.1339764, lng: 17.7173984 },
    { lat: 48.1340783, lng: 17.7177266 },
    { lat: 48.1344478, lng: 17.7180208 },
    { lat: 48.1344112, lng: 17.7181927 },
    { lat: 48.1349781, lng: 17.7189546 },
    { lat: 48.1361243, lng: 17.7198097 },
    { lat: 48.1394992, lng: 17.7218934 },
    { lat: 48.1395443, lng: 17.7220886 },
    { lat: 48.1420513, lng: 17.7227507 },
    { lat: 48.1434046, lng: 17.7229433 },
    { lat: 48.1449835, lng: 17.7160394 },
    { lat: 48.1522914, lng: 17.7252598 },
    { lat: 48.1523434, lng: 17.725331 },
  ],
  VeľkéÚľany: [
    { lat: 48.1416658, lng: 17.6089785 },
    { lat: 48.142809, lng: 17.6107686 },
    { lat: 48.1437529, lng: 17.6124219 },
    { lat: 48.1440467, lng: 17.6127456 },
    { lat: 48.1444078, lng: 17.6130116 },
    { lat: 48.1444559, lng: 17.613252 },
    { lat: 48.1447656, lng: 17.613759 },
    { lat: 48.1450054, lng: 17.6143173 },
    { lat: 48.1452402, lng: 17.6154728 },
    { lat: 48.1453138, lng: 17.6160295 },
    { lat: 48.1460074, lng: 17.6159954 },
    { lat: 48.1464417, lng: 17.6160083 },
    { lat: 48.1474216, lng: 17.6158782 },
    { lat: 48.1482102, lng: 17.6156012 },
    { lat: 48.1489816, lng: 17.6158711 },
    { lat: 48.1494582, lng: 17.6162482 },
    { lat: 48.1500235, lng: 17.6165059 },
    { lat: 48.150402, lng: 17.6164938 },
    { lat: 48.1516145, lng: 17.6161508 },
    { lat: 48.1522596, lng: 17.6161551 },
    { lat: 48.1528452, lng: 17.6157034 },
    { lat: 48.1529264, lng: 17.6151325 },
    { lat: 48.1532042, lng: 17.6147396 },
    { lat: 48.1536044, lng: 17.6146579 },
    { lat: 48.1538863, lng: 17.6143832 },
    { lat: 48.1543831, lng: 17.6134744 },
    { lat: 48.1552031, lng: 17.6129069 },
    { lat: 48.1559419, lng: 17.6122892 },
    { lat: 48.1573511, lng: 17.6120738 },
    { lat: 48.1581716, lng: 17.6115492 },
    { lat: 48.158877, lng: 17.6113666 },
    { lat: 48.1594009, lng: 17.6113636 },
    { lat: 48.1601654, lng: 17.6119153 },
    { lat: 48.161469, lng: 17.612372 },
    { lat: 48.1620068, lng: 17.6127476 },
    { lat: 48.1623865, lng: 17.6127543 },
    { lat: 48.1628005, lng: 17.6126225 },
    { lat: 48.1630371, lng: 17.6126322 },
    { lat: 48.1635113, lng: 17.6129884 },
    { lat: 48.1637161, lng: 17.6132962 },
    { lat: 48.1640445, lng: 17.6140947 },
    { lat: 48.1641091, lng: 17.6145464 },
    { lat: 48.1644693, lng: 17.6157918 },
    { lat: 48.1647567, lng: 17.6179631 },
    { lat: 48.1649399, lng: 17.6180189 },
    { lat: 48.1652112, lng: 17.617988 },
    { lat: 48.1657021, lng: 17.6176614 },
    { lat: 48.1662156, lng: 17.6185661 },
    { lat: 48.1671297, lng: 17.6194866 },
    { lat: 48.1678286, lng: 17.6196832 },
    { lat: 48.1684564, lng: 17.6196814 },
    { lat: 48.1688785, lng: 17.6194445 },
    { lat: 48.1695342, lng: 17.6189068 },
    { lat: 48.169922, lng: 17.618252 },
    { lat: 48.1700348, lng: 17.6179481 },
    { lat: 48.1702704, lng: 17.6168168 },
    { lat: 48.1702348, lng: 17.6155406 },
    { lat: 48.1702209, lng: 17.6153399 },
    { lat: 48.1700147, lng: 17.6149919 },
    { lat: 48.1697924, lng: 17.6147945 },
    { lat: 48.1689007, lng: 17.6147106 },
    { lat: 48.1687648, lng: 17.6147772 },
    { lat: 48.1676325, lng: 17.6158529 },
    { lat: 48.166979, lng: 17.6162308 },
    { lat: 48.1659403, lng: 17.6160385 },
    { lat: 48.1659342, lng: 17.6156577 },
    { lat: 48.1658146, lng: 17.6154798 },
    { lat: 48.1657039, lng: 17.6146769 },
    { lat: 48.1658754, lng: 17.6137461 },
    { lat: 48.1659825, lng: 17.6123695 },
    { lat: 48.1662782, lng: 17.6114611 },
    { lat: 48.1664427, lng: 17.611274 },
    { lat: 48.1667787, lng: 17.6106998 },
    { lat: 48.1675281, lng: 17.6099782 },
    { lat: 48.1678571, lng: 17.6099316 },
    { lat: 48.1682811, lng: 17.6101174 },
    { lat: 48.1688647, lng: 17.6108296 },
    { lat: 48.1695125, lng: 17.6114295 },
    { lat: 48.169825, lng: 17.6116367 },
    { lat: 48.1705468, lng: 17.6118738 },
    { lat: 48.1710873, lng: 17.6117254 },
    { lat: 48.1715388, lng: 17.6115087 },
    { lat: 48.1721081, lng: 17.6109031 },
    { lat: 48.1729864, lng: 17.6095463 },
    { lat: 48.173077, lng: 17.609266 },
    { lat: 48.1732553, lng: 17.6089436 },
    { lat: 48.174163, lng: 17.6076566 },
    { lat: 48.1749164, lng: 17.6070919 },
    { lat: 48.1755982, lng: 17.6069624 },
    { lat: 48.1757745, lng: 17.6069626 },
    { lat: 48.1763179, lng: 17.6071991 },
    { lat: 48.1765392, lng: 17.6075204 },
    { lat: 48.1773031, lng: 17.6091125 },
    { lat: 48.1775271, lng: 17.6093872 },
    { lat: 48.1778349, lng: 17.6095782 },
    { lat: 48.178052, lng: 17.6095838 },
    { lat: 48.1782211, lng: 17.6095928 },
    { lat: 48.1783972, lng: 17.6094851 },
    { lat: 48.1787173, lng: 17.6089239 },
    { lat: 48.1788903, lng: 17.6082155 },
    { lat: 48.1788821, lng: 17.6078919 },
    { lat: 48.1791839, lng: 17.6059361 },
    { lat: 48.1791373, lng: 17.6053433 },
    { lat: 48.1793129, lng: 17.6047089 },
    { lat: 48.1794004, lng: 17.6045399 },
    { lat: 48.1799356, lng: 17.6035061 },
    { lat: 48.1802825, lng: 17.6030659 },
    { lat: 48.1807377, lng: 17.6026579 },
    { lat: 48.1814224, lng: 17.6023085 },
    { lat: 48.1817343, lng: 17.6022947 },
    { lat: 48.1820995, lng: 17.6024786 },
    { lat: 48.1826176, lng: 17.6030636 },
    { lat: 48.1835905, lng: 17.6039985 },
    { lat: 48.1838305, lng: 17.6040618 },
    { lat: 48.1840194, lng: 17.6039961 },
    { lat: 48.1841953, lng: 17.6038487 },
    { lat: 48.1844553, lng: 17.6033922 },
    { lat: 48.1846378, lng: 17.6023002 },
    { lat: 48.1846396, lng: 17.6017384 },
    { lat: 48.1848678, lng: 17.6012584 },
    { lat: 48.1851966, lng: 17.6009061 },
    { lat: 48.1856643, lng: 17.6006763 },
    { lat: 48.1860742, lng: 17.6007664 },
    { lat: 48.1864942, lng: 17.6007854 },
    { lat: 48.1867514, lng: 17.6007262 },
    { lat: 48.1872605, lng: 17.6003837 },
    { lat: 48.18768, lng: 17.6001968 },
    { lat: 48.1887309, lng: 17.5999954 },
    { lat: 48.1892104, lng: 17.5995618 },
    { lat: 48.1894819, lng: 17.5991595 },
    { lat: 48.1911226, lng: 17.5979691 },
    { lat: 48.191411, lng: 17.5979561 },
    { lat: 48.1919512, lng: 17.5981233 },
    { lat: 48.1926835, lng: 17.5982197 },
    { lat: 48.1928945, lng: 17.5981196 },
    { lat: 48.1931379, lng: 17.597997 },
    { lat: 48.1932239, lng: 17.5975552 },
    { lat: 48.1932145, lng: 17.5973505 },
    { lat: 48.1931038, lng: 17.5971019 },
    { lat: 48.1927826, lng: 17.5968654 },
    { lat: 48.1920082, lng: 17.5965312 },
    { lat: 48.1917883, lng: 17.5963723 },
    { lat: 48.1915592, lng: 17.5960924 },
    { lat: 48.1911623, lng: 17.595316 },
    { lat: 48.1910591, lng: 17.5949912 },
    { lat: 48.1909973, lng: 17.5943711 },
    { lat: 48.1910048, lng: 17.5940279 },
    { lat: 48.1911116, lng: 17.5934167 },
    { lat: 48.1919798, lng: 17.5918106 },
    { lat: 48.1921874, lng: 17.5912754 },
    { lat: 48.1928534, lng: 17.5886398 },
    { lat: 48.1928895, lng: 17.5876624 },
    { lat: 48.1928332, lng: 17.5872043 },
    { lat: 48.1925961, lng: 17.5863851 },
    { lat: 48.1921406, lng: 17.5860581 },
    { lat: 48.1919316, lng: 17.5860574 },
    { lat: 48.1917573, lng: 17.5861221 },
    { lat: 48.1914157, lng: 17.586451 },
    { lat: 48.1912905, lng: 17.5867558 },
    { lat: 48.1911563, lng: 17.5874512 },
    { lat: 48.1910857, lng: 17.5886163 },
    { lat: 48.1910349, lng: 17.5889714 },
    { lat: 48.1909084, lng: 17.5893981 },
    { lat: 48.1907043, lng: 17.5898284 },
    { lat: 48.1904125, lng: 17.5900875 },
    { lat: 48.1900524, lng: 17.5901454 },
    { lat: 48.189798, lng: 17.590051 },
    { lat: 48.1896427, lng: 17.5897934 },
    { lat: 48.1895673, lng: 17.5894595 },
    { lat: 48.1896102, lng: 17.589281 },
    { lat: 48.1897795, lng: 17.5889778 },
    { lat: 48.1903361, lng: 17.5884054 },
    { lat: 48.1904974, lng: 17.5880126 },
    { lat: 48.1905819, lng: 17.5876358 },
    { lat: 48.1905517, lng: 17.587071 },
    { lat: 48.1903431, lng: 17.5864707 },
    { lat: 48.1899925, lng: 17.5860552 },
    { lat: 48.1895933, lng: 17.5858493 },
    { lat: 48.1886339, lng: 17.5857334 },
    { lat: 48.1876587, lng: 17.5855266 },
    { lat: 48.1874172, lng: 17.5853879 },
    { lat: 48.1870749, lng: 17.585065 },
    { lat: 48.1868406, lng: 17.5847377 },
    { lat: 48.1867196, lng: 17.5842365 },
    { lat: 48.1867078, lng: 17.5838581 },
    { lat: 48.1867145, lng: 17.5836055 },
    { lat: 48.1869593, lng: 17.5825912 },
    { lat: 48.1871497, lng: 17.5821547 },
    { lat: 48.1872989, lng: 17.581964 },
    { lat: 48.1878789, lng: 17.5817768 },
    { lat: 48.1887489, lng: 17.5812553 },
    { lat: 48.1889197, lng: 17.5810459 },
    { lat: 48.1890588, lng: 17.5807289 },
    { lat: 48.1892421, lng: 17.5797565 },
    { lat: 48.1893622, lng: 17.5788586 },
    { lat: 48.189276, lng: 17.5774507 },
    { lat: 48.1891413, lng: 17.576915 },
    { lat: 48.188812, lng: 17.5764062 },
    { lat: 48.1886629, lng: 17.5759451 },
    { lat: 48.1883708, lng: 17.5745648 },
    { lat: 48.1883853, lng: 17.5742269 },
    { lat: 48.1885204, lng: 17.5736881 },
    { lat: 48.1886636, lng: 17.5734363 },
    { lat: 48.1890114, lng: 17.5732941 },
    { lat: 48.18921, lng: 17.573362 },
    { lat: 48.1893634, lng: 17.5735363 },
    { lat: 48.1894831, lng: 17.5740858 },
    { lat: 48.1894231, lng: 17.5753599 },
    { lat: 48.1895149, lng: 17.5759693 },
    { lat: 48.1897659, lng: 17.5767196 },
    { lat: 48.1900956, lng: 17.577305 },
    { lat: 48.1906105, lng: 17.5777325 },
    { lat: 48.191469, lng: 17.5779282 },
    { lat: 48.1918459, lng: 17.5777121 },
    { lat: 48.1920342, lng: 17.577514 },
    { lat: 48.1921379, lng: 17.5773322 },
    { lat: 48.1922459, lng: 17.5769551 },
    { lat: 48.192275, lng: 17.5766311 },
    { lat: 48.1922305, lng: 17.5762474 },
    { lat: 48.1921284, lng: 17.5759746 },
    { lat: 48.1914712, lng: 17.5749993 },
    { lat: 48.1912605, lng: 17.5743512 },
    { lat: 48.1911947, lng: 17.5738336 },
    { lat: 48.1912838, lng: 17.5732076 },
    { lat: 48.1914968, lng: 17.5724571 },
    { lat: 48.1916871, lng: 17.572201 },
    { lat: 48.1919597, lng: 17.572153 },
    { lat: 48.1922965, lng: 17.5721946 },
    { lat: 48.1928576, lng: 17.5726526 },
    { lat: 48.1931355, lng: 17.5727811 },
    { lat: 48.1936316, lng: 17.5728195 },
    { lat: 48.1941829, lng: 17.5725205 },
    { lat: 48.1945091, lng: 17.5721293 },
    { lat: 48.194731, lng: 17.5717491 },
    { lat: 48.1948222, lng: 17.5710336 },
    { lat: 48.1946838, lng: 17.5703868 },
    { lat: 48.1945411, lng: 17.5701304 },
    { lat: 48.1928138, lng: 17.5694821 },
    { lat: 48.1922796, lng: 17.5690903 },
    { lat: 48.191025, lng: 17.5679922 },
    { lat: 48.1905528, lng: 17.5674784 },
    { lat: 48.1901327, lng: 17.5668046 },
    { lat: 48.1900402, lng: 17.5663255 },
    { lat: 48.190033, lng: 17.5659635 },
    { lat: 48.1902163, lng: 17.565261 },
    { lat: 48.1904588, lng: 17.5647618 },
    { lat: 48.1910638, lng: 17.564419 },
    { lat: 48.1912878, lng: 17.5644967 },
    { lat: 48.1914602, lng: 17.5647907 },
    { lat: 48.1915438, lng: 17.5659619 },
    { lat: 48.1916169, lng: 17.5663482 },
    { lat: 48.1917841, lng: 17.5667735 },
    { lat: 48.1921462, lng: 17.5672127 },
    { lat: 48.1925857, lng: 17.567355 },
    { lat: 48.1927513, lng: 17.5673307 },
    { lat: 48.1928053, lng: 17.5673096 },
    { lat: 48.1928719, lng: 17.5672562 },
    { lat: 48.1929975, lng: 17.5670135 },
    { lat: 48.1930556, lng: 17.5664726 },
    { lat: 48.1929495, lng: 17.5654985 },
    { lat: 48.1929621, lng: 17.5650586 },
    { lat: 48.1932123, lng: 17.5640567 },
    { lat: 48.193447, lng: 17.5636668 },
    { lat: 48.1937178, lng: 17.5635185 },
    { lat: 48.193906, lng: 17.563583 },
    { lat: 48.1942302, lng: 17.5639211 },
    { lat: 48.1944438, lng: 17.564544 },
    { lat: 48.1948434, lng: 17.5663325 },
    { lat: 48.1949534, lng: 17.5666774 },
    { lat: 48.1951422, lng: 17.5670515 },
    { lat: 48.1955806, lng: 17.5673033 },
    { lat: 48.1959155, lng: 17.5672252 },
    { lat: 48.196124, lng: 17.5668097 },
    { lat: 48.1960361, lng: 17.5654312 },
    { lat: 48.1961201, lng: 17.5651248 },
    { lat: 48.1961838, lng: 17.5649483 },
    { lat: 48.1965042, lng: 17.5645915 },
    { lat: 48.1976175, lng: 17.5641963 },
    { lat: 48.1985359, lng: 17.5635096 },
    { lat: 48.1992244, lng: 17.5626387 },
    { lat: 48.1998351, lng: 17.5615736 },
    { lat: 48.2000121, lng: 17.5608238 },
    { lat: 48.1999984, lng: 17.5604491 },
    { lat: 48.1997766, lng: 17.5599275 },
    { lat: 48.1995614, lng: 17.5595635 },
    { lat: 48.1990004, lng: 17.5589817 },
    { lat: 48.1987814, lng: 17.558843 },
    { lat: 48.1979544, lng: 17.558554 },
    { lat: 48.1970522, lng: 17.5584293 },
    { lat: 48.1966788, lng: 17.5583145 },
    { lat: 48.1963061, lng: 17.5581178 },
    { lat: 48.1960746, lng: 17.5579059 },
    { lat: 48.1958718, lng: 17.5577019 },
    { lat: 48.1955109, lng: 17.5570894 },
    { lat: 48.1953826, lng: 17.5568104 },
    { lat: 48.1952141, lng: 17.556065 },
    { lat: 48.1952199, lng: 17.555626 },
    { lat: 48.1952964, lng: 17.5552477 },
    { lat: 48.1953861, lng: 17.5550965 },
    { lat: 48.1954872, lng: 17.5550339 },
    { lat: 48.1956101, lng: 17.5550511 },
    { lat: 48.195976, lng: 17.5552293 },
    { lat: 48.1966536, lng: 17.5559544 },
    { lat: 48.1978018, lng: 17.5567819 },
    { lat: 48.1985144, lng: 17.5575063 },
    { lat: 48.1990482, lng: 17.55768 },
    { lat: 48.1992049, lng: 17.5576729 },
    { lat: 48.1995027, lng: 17.5575111 },
    { lat: 48.1996156, lng: 17.5571555 },
    { lat: 48.1995719, lng: 17.5566298 },
    { lat: 48.1993369, lng: 17.5558658 },
    { lat: 48.1991451, lng: 17.5556567 },
    { lat: 48.1991529, lng: 17.5554624 },
    { lat: 48.1989816, lng: 17.5549513 },
    { lat: 48.198668, lng: 17.5544286 },
    { lat: 48.1981815, lng: 17.5538833 },
    { lat: 48.1970765, lng: 17.5537512 },
    { lat: 48.1965091, lng: 17.5532294 },
    { lat: 48.1960738, lng: 17.5527097 },
    { lat: 48.1958574, lng: 17.5521662 },
    { lat: 48.1958393, lng: 17.5518311 },
    { lat: 48.1959091, lng: 17.5515415 },
    { lat: 48.196208, lng: 17.5511307 },
    { lat: 48.1964763, lng: 17.5509115 },
    { lat: 48.1966468, lng: 17.5508755 },
    { lat: 48.1970705, lng: 17.550455 },
    { lat: 48.1971876, lng: 17.5500439 },
    { lat: 48.197188, lng: 17.5494645 },
    { lat: 48.1971222, lng: 17.549179 },
    { lat: 48.1966889, lng: 17.5482637 },
    { lat: 48.1964739, lng: 17.5476721 },
    { lat: 48.1964393, lng: 17.5474162 },
    { lat: 48.196537, lng: 17.5466455 },
    { lat: 48.1966333, lng: 17.5462962 },
    { lat: 48.1968098, lng: 17.5459669 },
    { lat: 48.19691, lng: 17.5456599 },
    { lat: 48.1969988, lng: 17.5448954 },
    { lat: 48.1970006, lng: 17.5443571 },
    { lat: 48.1968132, lng: 17.5428053 },
    { lat: 48.1966777, lng: 17.5421854 },
    { lat: 48.1960383, lng: 17.5401351 },
    { lat: 48.1957205, lng: 17.5397017 },
    { lat: 48.1953244, lng: 17.5389814 },
    { lat: 48.1951094, lng: 17.5381287 },
    { lat: 48.1951792, lng: 17.5377184 },
    { lat: 48.1953628, lng: 17.537466 },
    { lat: 48.1956416, lng: 17.5374109 },
    { lat: 48.1972718, lng: 17.5383389 },
    { lat: 48.1977788, lng: 17.5384437 },
    { lat: 48.1980062, lng: 17.5384143 },
    { lat: 48.198417, lng: 17.5380734 },
    { lat: 48.1987505, lng: 17.5374261 },
    { lat: 48.1987769, lng: 17.5372552 },
    { lat: 48.1986822, lng: 17.5368178 },
    { lat: 48.1984238, lng: 17.5362194 },
    { lat: 48.1977873, lng: 17.5353099 },
    { lat: 48.1963424, lng: 17.5330447 },
    { lat: 48.1961385, lng: 17.5330679 },
    { lat: 48.194712, lng: 17.5331717 },
    { lat: 48.1898034, lng: 17.5329862 },
    { lat: 48.188755, lng: 17.5342677 },
    { lat: 48.1864877, lng: 17.5375883 },
    { lat: 48.1851178, lng: 17.539353 },
    { lat: 48.1830406, lng: 17.5426661 },
    { lat: 48.182552, lng: 17.5422869 },
    { lat: 48.1805102, lng: 17.5421263 },
    { lat: 48.177287, lng: 17.5421406 },
    { lat: 48.1756638, lng: 17.5420839 },
    { lat: 48.1754928, lng: 17.5422333 },
    { lat: 48.174172, lng: 17.5420287 },
    { lat: 48.1741114, lng: 17.5420348 },
    { lat: 48.1739394, lng: 17.542294 },
    { lat: 48.1730089, lng: 17.5412553 },
    { lat: 48.1726225, lng: 17.5400673 },
    { lat: 48.1714977, lng: 17.5402964 },
    { lat: 48.170038, lng: 17.5396709 },
    { lat: 48.1687984, lng: 17.5388969 },
    { lat: 48.167782, lng: 17.5379654 },
    { lat: 48.1670036, lng: 17.5376056 },
    { lat: 48.1669671, lng: 17.5368421 },
    { lat: 48.1662094, lng: 17.5376534 },
    { lat: 48.1651824, lng: 17.537698 },
    { lat: 48.1637044, lng: 17.5386204 },
    { lat: 48.1626699, lng: 17.5395387 },
    { lat: 48.1618312, lng: 17.539733 },
    { lat: 48.1609096, lng: 17.5399464 },
    { lat: 48.1588604, lng: 17.5406624 },
    { lat: 48.1559747, lng: 17.5420747 },
    { lat: 48.1541795, lng: 17.5430099 },
    { lat: 48.1532084, lng: 17.5445137 },
    { lat: 48.1522812, lng: 17.5444573 },
    { lat: 48.1514952, lng: 17.5447924 },
    { lat: 48.1514345, lng: 17.5448231 },
    { lat: 48.1500064, lng: 17.545724 },
    { lat: 48.1496474, lng: 17.5469447 },
    { lat: 48.1493543, lng: 17.5474826 },
    { lat: 48.149138, lng: 17.5477186 },
    { lat: 48.1490488, lng: 17.5480005 },
    { lat: 48.1488469, lng: 17.5483221 },
    { lat: 48.1485977, lng: 17.5485592 },
    { lat: 48.148328, lng: 17.548597 },
    { lat: 48.1480242, lng: 17.5484014 },
    { lat: 48.1478753, lng: 17.5484414 },
    { lat: 48.1475714, lng: 17.5488627 },
    { lat: 48.1472248, lng: 17.5495332 },
    { lat: 48.1462823, lng: 17.5500188 },
    { lat: 48.1462592, lng: 17.5500278 },
    { lat: 48.1447819, lng: 17.5500261 },
    { lat: 48.1441584, lng: 17.550218 },
    { lat: 48.143573, lng: 17.5503008 },
    { lat: 48.1432435, lng: 17.5501993 },
    { lat: 48.1429099, lng: 17.5499973 },
    { lat: 48.1416509, lng: 17.5494515 },
    { lat: 48.1410734, lng: 17.5495505 },
    { lat: 48.1407595, lng: 17.5494966 },
    { lat: 48.1402935, lng: 17.5495218 },
    { lat: 48.1401009, lng: 17.5496175 },
    { lat: 48.1397798, lng: 17.5499299 },
    { lat: 48.1387744, lng: 17.5515151 },
    { lat: 48.138554, lng: 17.5520773 },
    { lat: 48.138177, lng: 17.553495 },
    { lat: 48.1379675, lng: 17.5540853 },
    { lat: 48.1377849, lng: 17.5550096 },
    { lat: 48.1377523, lng: 17.5554173 },
    { lat: 48.1378524, lng: 17.5559592 },
    { lat: 48.1383243, lng: 17.5565601 },
    { lat: 48.1383956, lng: 17.5568982 },
    { lat: 48.1383748, lng: 17.5574208 },
    { lat: 48.1385105, lng: 17.558326 },
    { lat: 48.138591, lng: 17.5595166 },
    { lat: 48.1388549, lng: 17.5610296 },
    { lat: 48.1388252, lng: 17.5621927 },
    { lat: 48.1386747, lng: 17.563267 },
    { lat: 48.1386162, lng: 17.5644573 },
    { lat: 48.1382678, lng: 17.5657064 },
    { lat: 48.1382912, lng: 17.5660549 },
    { lat: 48.1385169, lng: 17.5666874 },
    { lat: 48.1385788, lng: 17.5677144 },
    { lat: 48.1388297, lng: 17.5683713 },
    { lat: 48.1390449, lng: 17.5687897 },
    { lat: 48.1390185, lng: 17.5690278 },
    { lat: 48.1388736, lng: 17.5690601 },
    { lat: 48.1386632, lng: 17.5693018 },
    { lat: 48.1381326, lng: 17.5703499 },
    { lat: 48.1376737, lng: 17.5715119 },
    { lat: 48.1374305, lng: 17.5718622 },
    { lat: 48.1371175, lng: 17.5720704 },
    { lat: 48.1365259, lng: 17.5721256 },
    { lat: 48.1362227, lng: 17.572081 },
    { lat: 48.1355545, lng: 17.5714651 },
    { lat: 48.1354222, lng: 17.5711085 },
    { lat: 48.135314, lng: 17.5702988 },
    { lat: 48.1353599, lng: 17.5702021 },
    { lat: 48.1355316, lng: 17.5700961 },
    { lat: 48.1366522, lng: 17.5696227 },
    { lat: 48.1373133, lng: 17.5691026 },
    { lat: 48.1375447, lng: 17.5686758 },
    { lat: 48.1376015, lng: 17.5683259 },
    { lat: 48.1374967, lng: 17.5677808 },
    { lat: 48.1372383, lng: 17.567358 },
    { lat: 48.136928, lng: 17.5670242 },
    { lat: 48.1367574, lng: 17.566917 },
    { lat: 48.1357927, lng: 17.5667546 },
    { lat: 48.1350216, lng: 17.5662623 },
    { lat: 48.1340195, lng: 17.5658659 },
    { lat: 48.1332352, lng: 17.5657127 },
    { lat: 48.131962, lng: 17.5658484 },
    { lat: 48.1314646, lng: 17.5661377 },
    { lat: 48.1312863, lng: 17.5662988 },
    { lat: 48.1311048, lng: 17.5665703 },
    { lat: 48.1309948, lng: 17.566849 },
    { lat: 48.1303916, lng: 17.5690409 },
    { lat: 48.1302466, lng: 17.5692792 },
    { lat: 48.1298439, lng: 17.5697296 },
    { lat: 48.1294498, lng: 17.569961 },
    { lat: 48.1292796, lng: 17.569964 },
    { lat: 48.1286053, lng: 17.5690801 },
    { lat: 48.1283885, lng: 17.5688865 },
    { lat: 48.1281723, lng: 17.5687872 },
    { lat: 48.1277923, lng: 17.5698745 },
    { lat: 48.1274549, lng: 17.5699681 },
    { lat: 48.1272937, lng: 17.569938 },
    { lat: 48.1268941, lng: 17.5696753 },
    { lat: 48.126664, lng: 17.5693765 },
    { lat: 48.1261271, lng: 17.5674407 },
    { lat: 48.125856, lng: 17.5669268 },
    { lat: 48.125586, lng: 17.5666935 },
    { lat: 48.1253202, lng: 17.5667037 },
    { lat: 48.1250579, lng: 17.5668238 },
    { lat: 48.1245799, lng: 17.5669247 },
    { lat: 48.1244027, lng: 17.5670503 },
    { lat: 48.1241176, lng: 17.5673959 },
    { lat: 48.1237294, lng: 17.5675953 },
    { lat: 48.1235758, lng: 17.5675429 },
    { lat: 48.1231795, lng: 17.5670985 },
    { lat: 48.1229866, lng: 17.5666411 },
    { lat: 48.1226142, lng: 17.5660978 },
    { lat: 48.1224598, lng: 17.5657841 },
    { lat: 48.1221385, lng: 17.5654732 },
    { lat: 48.1217507, lng: 17.5654333 },
    { lat: 48.1210009, lng: 17.5656613 },
    { lat: 48.1208943, lng: 17.565604 },
    { lat: 48.1206364, lng: 17.5650809 },
    { lat: 48.1205044, lng: 17.5645515 },
    { lat: 48.1204738, lng: 17.5640911 },
    { lat: 48.1205498, lng: 17.5636728 },
    { lat: 48.121018, lng: 17.5627816 },
    { lat: 48.1214734, lng: 17.5616526 },
    { lat: 48.1214993, lng: 17.5611573 },
    { lat: 48.1213942, lng: 17.5608906 },
    { lat: 48.1210593, lng: 17.5605114 },
    { lat: 48.1208005, lng: 17.5604016 },
    { lat: 48.1205107, lng: 17.5603729 },
    { lat: 48.1200694, lng: 17.5604619 },
    { lat: 48.1196969, lng: 17.5606775 },
    { lat: 48.1193697, lng: 17.5607192 },
    { lat: 48.1183812, lng: 17.5600588 },
    { lat: 48.1179903, lng: 17.5599148 },
    { lat: 48.1177599, lng: 17.5600007 },
    { lat: 48.1176275, lng: 17.5601916 },
    { lat: 48.117569, lng: 17.5604427 },
    { lat: 48.1175724, lng: 17.5607441 },
    { lat: 48.1176368, lng: 17.5610081 },
    { lat: 48.1176127, lng: 17.5611981 },
    { lat: 48.1173217, lng: 17.5609907 },
    { lat: 48.1166709, lng: 17.5603491 },
    { lat: 48.1158387, lng: 17.5598015 },
    { lat: 48.1152931, lng: 17.5593064 },
    { lat: 48.1150425, lng: 17.5594467 },
    { lat: 48.114662, lng: 17.559485 },
    { lat: 48.1140178, lng: 17.5594064 },
    { lat: 48.1139583, lng: 17.5591636 },
    { lat: 48.1149757, lng: 17.558049 },
    { lat: 48.115167, lng: 17.5576475 },
    { lat: 48.1152792, lng: 17.5571068 },
    { lat: 48.1151172, lng: 17.5562496 },
    { lat: 48.1146039, lng: 17.554564 },
    { lat: 48.1142897, lng: 17.5537985 },
    { lat: 48.1138876, lng: 17.5531434 },
    { lat: 48.1131059, lng: 17.5521689 },
    { lat: 48.1128878, lng: 17.5517251 },
    { lat: 48.1122669, lng: 17.5507048 },
    { lat: 48.1120304, lng: 17.5503965 },
    { lat: 48.1117654, lng: 17.5496982 },
    { lat: 48.1116969, lng: 17.5490299 },
    { lat: 48.1115002, lng: 17.5485707 },
    { lat: 48.111244, lng: 17.5480955 },
    { lat: 48.1107629, lng: 17.5475285 },
    { lat: 48.1105371, lng: 17.5469664 },
    { lat: 48.1103022, lng: 17.545854 },
    { lat: 48.1100966, lng: 17.5452897 },
    { lat: 48.1100229, lng: 17.5445447 },
    { lat: 48.1099138, lng: 17.5442854 },
    { lat: 48.1096479, lng: 17.544224 },
    { lat: 48.1088857, lng: 17.5444911 },
    { lat: 48.1083291, lng: 17.5441391 },
    { lat: 48.1077477, lng: 17.543878 },
    { lat: 48.106871, lng: 17.5436978 },
    { lat: 48.105725, lng: 17.543732 },
    { lat: 48.105772, lng: 17.544096 },
    { lat: 48.105869, lng: 17.544936 },
    { lat: 48.105892, lng: 17.545411 },
    { lat: 48.106025, lng: 17.54589 },
    { lat: 48.106151, lng: 17.546324 },
    { lat: 48.106371, lng: 17.546855 },
    { lat: 48.106652, lng: 17.547452 },
    { lat: 48.106878, lng: 17.54838 },
    { lat: 48.107085, lng: 17.549218 },
    { lat: 48.107208, lng: 17.55006 },
    { lat: 48.107249, lng: 17.550861 },
    { lat: 48.10728, lng: 17.551202 },
    { lat: 48.107365, lng: 17.552209 },
    { lat: 48.107479, lng: 17.553359 },
    { lat: 48.107591, lng: 17.554292 },
    { lat: 48.107669, lng: 17.554845 },
    { lat: 48.107795, lng: 17.555813 },
    { lat: 48.107923, lng: 17.557139 },
    { lat: 48.107967, lng: 17.557616 },
    { lat: 48.108017, lng: 17.557966 },
    { lat: 48.108133, lng: 17.558522 },
    { lat: 48.108215, lng: 17.559121 },
    { lat: 48.108457, lng: 17.559808 },
    { lat: 48.109003, lng: 17.561109 },
    { lat: 48.10915, lng: 17.561496 },
    { lat: 48.109352, lng: 17.56198 },
    { lat: 48.109595, lng: 17.562478 },
    { lat: 48.109856, lng: 17.562862 },
    { lat: 48.110007, lng: 17.563183 },
    { lat: 48.110439, lng: 17.563919 },
    { lat: 48.110827, lng: 17.564346 },
    { lat: 48.111192, lng: 17.564574 },
    { lat: 48.111572, lng: 17.564726 },
    { lat: 48.111827, lng: 17.564842 },
    { lat: 48.112035, lng: 17.564929 },
    { lat: 48.1121, lng: 17.56496 },
    { lat: 48.112737, lng: 17.565321 },
    { lat: 48.113261, lng: 17.565641 },
    { lat: 48.113485, lng: 17.566045 },
    { lat: 48.113732, lng: 17.566431 },
    { lat: 48.113894, lng: 17.566867 },
    { lat: 48.114052, lng: 17.567484 },
    { lat: 48.114135, lng: 17.567976 },
    { lat: 48.114142, lng: 17.568288 },
    { lat: 48.114121, lng: 17.568647 },
    { lat: 48.11406, lng: 17.568994 },
    { lat: 48.113888, lng: 17.569514 },
    { lat: 48.113812, lng: 17.569748 },
    { lat: 48.113588, lng: 17.570181 },
    { lat: 48.113277, lng: 17.570831 },
    { lat: 48.112983, lng: 17.571301 },
    { lat: 48.112691, lng: 17.571595 },
    { lat: 48.112587, lng: 17.57171 },
    { lat: 48.112366, lng: 17.571953 },
    { lat: 48.112185, lng: 17.572092 },
    { lat: 48.111689, lng: 17.572388 },
    { lat: 48.111185, lng: 17.572509 },
    { lat: 48.110947, lng: 17.572519 },
    { lat: 48.110841, lng: 17.57251 },
    { lat: 48.110793, lng: 17.572504 },
    { lat: 48.110428, lng: 17.572347 },
    { lat: 48.110131, lng: 17.572125 },
    { lat: 48.109926, lng: 17.571975 },
    { lat: 48.109455, lng: 17.571603 },
    { lat: 48.109131, lng: 17.571207 },
    { lat: 48.108918, lng: 17.570912 },
    { lat: 48.108763, lng: 17.570719 },
    { lat: 48.108571, lng: 17.570326 },
    { lat: 48.108344, lng: 17.569968 },
    { lat: 48.108195, lng: 17.569703 },
    { lat: 48.107967, lng: 17.569376 },
    { lat: 48.107643, lng: 17.568801 },
    { lat: 48.107491, lng: 17.568568 },
    { lat: 48.107352, lng: 17.56816 },
    { lat: 48.107127, lng: 17.567539 },
    { lat: 48.10698, lng: 17.567355 },
    { lat: 48.106811, lng: 17.56721 },
    { lat: 48.106453, lng: 17.566925 },
    { lat: 48.106124, lng: 17.566837 },
    { lat: 48.105956, lng: 17.566726 },
    { lat: 48.105645, lng: 17.566553 },
    { lat: 48.105487, lng: 17.566471 },
    { lat: 48.105266, lng: 17.566436 },
    { lat: 48.104979, lng: 17.566367 },
    { lat: 48.104672, lng: 17.566284 },
    { lat: 48.104259, lng: 17.566422 },
    { lat: 48.103795, lng: 17.56664 },
    { lat: 48.10349, lng: 17.566959 },
    { lat: 48.103242, lng: 17.567328 },
    { lat: 48.102947, lng: 17.567927 },
    { lat: 48.102814, lng: 17.568236 },
    { lat: 48.102729, lng: 17.568412 },
    { lat: 48.102566, lng: 17.568728 },
    { lat: 48.102443, lng: 17.568979 },
    { lat: 48.10225, lng: 17.569484 },
    { lat: 48.10219, lng: 17.569662 },
    { lat: 48.102085, lng: 17.570309 },
    { lat: 48.102058, lng: 17.570507 },
    { lat: 48.102042, lng: 17.570691 },
    { lat: 48.10206, lng: 17.571115 },
    { lat: 48.102132, lng: 17.571662 },
    { lat: 48.102157, lng: 17.571764 },
    { lat: 48.102229, lng: 17.572068 },
    { lat: 48.102364, lng: 17.572542 },
    { lat: 48.102487, lng: 17.572804 },
    { lat: 48.10255, lng: 17.572935 },
    { lat: 48.102702, lng: 17.573156 },
    { lat: 48.102987, lng: 17.573518 },
    { lat: 48.103145, lng: 17.573695 },
    { lat: 48.10338, lng: 17.573882 },
    { lat: 48.103609, lng: 17.573975 },
    { lat: 48.103749, lng: 17.574048 },
    { lat: 48.10381, lng: 17.574081 },
    { lat: 48.10401, lng: 17.574112 },
    { lat: 48.10426, lng: 17.574149 },
    { lat: 48.104443, lng: 17.574207 },
    { lat: 48.104743, lng: 17.574306 },
    { lat: 48.105174, lng: 17.574451 },
    { lat: 48.105437, lng: 17.574571 },
    { lat: 48.105871, lng: 17.574866 },
    { lat: 48.106319, lng: 17.575331 },
    { lat: 48.106425, lng: 17.575421 },
    { lat: 48.106743, lng: 17.575761 },
    { lat: 48.106864, lng: 17.575942 },
    { lat: 48.107142, lng: 17.576357 },
    { lat: 48.107318, lng: 17.57669 },
    { lat: 48.107577, lng: 17.577213 },
    { lat: 48.107751, lng: 17.577863 },
    { lat: 48.107914, lng: 17.578498 },
    { lat: 48.107988, lng: 17.579113 },
    { lat: 48.108003, lng: 17.57952 },
    { lat: 48.1080052, lng: 17.5798211 },
    { lat: 48.108007, lng: 17.580075 },
    { lat: 48.10796, lng: 17.580503 },
    { lat: 48.107909, lng: 17.580672 },
    { lat: 48.107734, lng: 17.581206 },
    { lat: 48.107538, lng: 17.581724 },
    { lat: 48.107282, lng: 17.582392 },
    { lat: 48.107163, lng: 17.582772 },
    { lat: 48.107017, lng: 17.583371 },
    { lat: 48.106976, lng: 17.583513 },
    { lat: 48.106805, lng: 17.583951 },
    { lat: 48.10658, lng: 17.584368 },
    { lat: 48.106455, lng: 17.584597 },
    { lat: 48.106271, lng: 17.584959 },
    { lat: 48.106079, lng: 17.585252 },
    { lat: 48.105761, lng: 17.585627 },
    { lat: 48.105691, lng: 17.585759 },
    { lat: 48.105497, lng: 17.585932 },
    { lat: 48.105098, lng: 17.586288 },
    { lat: 48.10465, lng: 17.586566 },
    { lat: 48.104252, lng: 17.586755 },
    { lat: 48.104023, lng: 17.586859 },
    { lat: 48.103879, lng: 17.586865 },
    { lat: 48.103552, lng: 17.586956 },
    { lat: 48.103218, lng: 17.587 },
    { lat: 48.102903, lng: 17.58704 },
    { lat: 48.102808, lng: 17.587045 },
    { lat: 48.10263, lng: 17.587037 },
    { lat: 48.102355, lng: 17.586983 },
    { lat: 48.102238, lng: 17.586966 },
    { lat: 48.102149, lng: 17.586988 },
    { lat: 48.101834, lng: 17.587074 },
    { lat: 48.101616, lng: 17.587206 },
    { lat: 48.101343, lng: 17.587293 },
    { lat: 48.101206, lng: 17.587351 },
    { lat: 48.100974, lng: 17.58746 },
    { lat: 48.100685, lng: 17.58759 },
    { lat: 48.100568, lng: 17.587653 },
    { lat: 48.100431, lng: 17.587802 },
    { lat: 48.100278, lng: 17.587908 },
    { lat: 48.100092, lng: 17.588036 },
    { lat: 48.099899, lng: 17.588212 },
    { lat: 48.1006976, lng: 17.5889938 },
    { lat: 48.1022296, lng: 17.5901073 },
    { lat: 48.1030403, lng: 17.5911151 },
    { lat: 48.1031623, lng: 17.5914182 },
    { lat: 48.1031343, lng: 17.5922401 },
    { lat: 48.1030923, lng: 17.5923951 },
    { lat: 48.1029278, lng: 17.5925954 },
    { lat: 48.1029599, lng: 17.592746 },
    { lat: 48.1030055, lng: 17.59286 },
    { lat: 48.1032523, lng: 17.5930468 },
    { lat: 48.1034266, lng: 17.5927876 },
    { lat: 48.103624, lng: 17.5928186 },
    { lat: 48.103711, lng: 17.5929617 },
    { lat: 48.103784, lng: 17.5933474 },
    { lat: 48.1037616, lng: 17.5934904 },
    { lat: 48.1036066, lng: 17.5937696 },
    { lat: 48.103778, lng: 17.5939078 },
    { lat: 48.1039379, lng: 17.5941678 },
    { lat: 48.1053101, lng: 17.5954525 },
    { lat: 48.1067934, lng: 17.5964362 },
    { lat: 48.108028, lng: 17.5968884 },
    { lat: 48.1102256, lng: 17.5964809 },
    { lat: 48.1126912, lng: 17.5981254 },
    { lat: 48.1130257, lng: 17.5982696 },
    { lat: 48.1133647, lng: 17.5987374 },
    { lat: 48.1137418, lng: 17.5990381 },
    { lat: 48.1151654, lng: 17.5996721 },
    { lat: 48.1167341, lng: 17.6000308 },
    { lat: 48.117757, lng: 17.6001531 },
    { lat: 48.1183187, lng: 17.6002834 },
    { lat: 48.1188017, lng: 17.6004589 },
    { lat: 48.1193496, lng: 17.6007481 },
    { lat: 48.1208018, lng: 17.6010128 },
    { lat: 48.1216284, lng: 17.601013 },
    { lat: 48.1224736, lng: 17.6011099 },
    { lat: 48.1230968, lng: 17.6004163 },
    { lat: 48.1235542, lng: 17.6005059 },
    { lat: 48.1246073, lng: 17.6012074 },
    { lat: 48.1252846, lng: 17.6017745 },
    { lat: 48.1255555, lng: 17.6017376 },
    { lat: 48.1263826, lng: 17.6020304 },
    { lat: 48.1265706, lng: 17.6019161 },
    { lat: 48.1269024, lng: 17.6018564 },
    { lat: 48.1281818, lng: 17.6021619 },
    { lat: 48.1294955, lng: 17.602664 },
    { lat: 48.129845, lng: 17.6029072 },
    { lat: 48.1299732, lng: 17.603093 },
    { lat: 48.1302245, lng: 17.6036562 },
    { lat: 48.1303883, lng: 17.6043844 },
    { lat: 48.1304393, lng: 17.6048335 },
    { lat: 48.1303682, lng: 17.6059199 },
    { lat: 48.1304955, lng: 17.6071966 },
    { lat: 48.1306851, lng: 17.6077456 },
    { lat: 48.1311009, lng: 17.6084813 },
    { lat: 48.1313159, lng: 17.6087005 },
    { lat: 48.1317455, lng: 17.6089737 },
    { lat: 48.1327251, lng: 17.6093766 },
    { lat: 48.1333429, lng: 17.6095034 },
    { lat: 48.1346182, lng: 17.6093491 },
    { lat: 48.1352976, lng: 17.6093989 },
    { lat: 48.1359364, lng: 17.6093566 },
    { lat: 48.136661, lng: 17.6092255 },
    { lat: 48.136982, lng: 17.6091242 },
    { lat: 48.1371927, lng: 17.6088861 },
    { lat: 48.1372511, lng: 17.6087032 },
    { lat: 48.1373044, lng: 17.60842 },
    { lat: 48.1372927, lng: 17.6082021 },
    { lat: 48.1370643, lng: 17.6076076 },
    { lat: 48.1372137, lng: 17.6074903 },
    { lat: 48.1375385, lng: 17.6073956 },
    { lat: 48.1379811, lng: 17.6075867 },
    { lat: 48.1388236, lng: 17.60809 },
    { lat: 48.1399858, lng: 17.609002 },
    { lat: 48.1403127, lng: 17.6095444 },
    { lat: 48.1403838, lng: 17.6097411 },
    { lat: 48.1407724, lng: 17.609794 },
    { lat: 48.1411256, lng: 17.6097373 },
    { lat: 48.1414327, lng: 17.609434 },
    { lat: 48.1416658, lng: 17.6089785 },
  ],
  DolnýChotár: [
    { lat: 47.983941, lng: 17.832742 },
    { lat: 47.983924, lng: 17.832764 },
    { lat: 47.983886, lng: 17.83281 },
    { lat: 47.983866, lng: 17.832833 },
    { lat: 47.983852, lng: 17.832851 },
    { lat: 47.983603, lng: 17.833156 },
    { lat: 47.982818, lng: 17.834585 },
    { lat: 47.982286, lng: 17.835659 },
    { lat: 47.981733, lng: 17.836515 },
    { lat: 47.981367, lng: 17.836768 },
    { lat: 47.980912, lng: 17.836854 },
    { lat: 47.980641, lng: 17.836961 },
    { lat: 47.980391, lng: 17.837141 },
    { lat: 47.98006, lng: 17.837449 },
    { lat: 47.97984, lng: 17.837836 },
    { lat: 47.979747, lng: 17.838074 },
    { lat: 47.979727, lng: 17.838369 },
    { lat: 47.979777, lng: 17.838646 },
    { lat: 47.980078, lng: 17.839354 },
    { lat: 47.980395, lng: 17.839996 },
    { lat: 47.980931, lng: 17.84153 },
    { lat: 47.981063, lng: 17.84214 },
    { lat: 47.980993, lng: 17.842472 },
    { lat: 47.980863, lng: 17.842773 },
    { lat: 47.980813, lng: 17.842831 },
    { lat: 47.98062, lng: 17.843047 },
    { lat: 47.980205, lng: 17.843468 },
    { lat: 47.979378, lng: 17.844108 },
    { lat: 47.978585, lng: 17.844631 },
    { lat: 47.977903, lng: 17.844955 },
    { lat: 47.977254, lng: 17.845176 },
    { lat: 47.9764, lng: 17.845553 },
    { lat: 47.975995, lng: 17.845806 },
    { lat: 47.97576, lng: 17.846166 },
    { lat: 47.975575, lng: 17.846612 },
    { lat: 47.975476, lng: 17.847139 },
    { lat: 47.975437, lng: 17.847666 },
    { lat: 47.975421, lng: 17.847896 },
    { lat: 47.975543, lng: 17.848736 },
    { lat: 47.975717, lng: 17.849566 },
    { lat: 47.975889, lng: 17.85023 },
    { lat: 47.975977, lng: 17.850565 },
    { lat: 47.976572, lng: 17.852268 },
    { lat: 47.976819, lng: 17.852973 },
    { lat: 47.976905, lng: 17.853504 },
    { lat: 47.976911, lng: 17.853628 },
    { lat: 47.976889, lng: 17.854021 },
    { lat: 47.97688, lng: 17.854159 },
    { lat: 47.976461, lng: 17.854824 },
    { lat: 47.976292, lng: 17.854989 },
    { lat: 47.976132, lng: 17.85514 },
    { lat: 47.975504, lng: 17.855544 },
    { lat: 47.975239, lng: 17.855598 },
    { lat: 47.974998, lng: 17.855647 },
    { lat: 47.974521, lng: 17.855562 },
    { lat: 47.974284, lng: 17.855524 },
    { lat: 47.974166, lng: 17.855504 },
    { lat: 47.974083, lng: 17.855486 },
    { lat: 47.973809, lng: 17.855557 },
    { lat: 47.973788, lng: 17.855562 },
    { lat: 47.973713, lng: 17.855584 },
    { lat: 47.973487, lng: 17.85565 },
    { lat: 47.973377, lng: 17.855681 },
    { lat: 47.973329, lng: 17.855696 },
    { lat: 47.973291, lng: 17.855704 },
    { lat: 47.973256, lng: 17.855711 },
    { lat: 47.973148, lng: 17.855734 },
    { lat: 47.973003, lng: 17.855764 },
    { lat: 47.972845, lng: 17.855797 },
    { lat: 47.97278, lng: 17.855809 },
    { lat: 47.972637, lng: 17.856492 },
    { lat: 47.972303, lng: 17.857343 },
    { lat: 47.97194, lng: 17.858234 },
    { lat: 47.971575, lng: 17.858741 },
    { lat: 47.971219, lng: 17.859237 },
    { lat: 47.970918, lng: 17.859649 },
    { lat: 47.970625, lng: 17.859942 },
    { lat: 47.970256, lng: 17.86031 },
    { lat: 47.969984, lng: 17.860595 },
    { lat: 47.969722, lng: 17.860858 },
    { lat: 47.96947, lng: 17.861118 },
    { lat: 47.969289, lng: 17.861295 },
    { lat: 47.96903, lng: 17.861514 },
    { lat: 47.968671, lng: 17.861822 },
    { lat: 47.968201, lng: 17.862172 },
    { lat: 47.967769, lng: 17.86249 },
    { lat: 47.967342, lng: 17.862805 },
    { lat: 47.966993, lng: 17.862945 },
    { lat: 47.966435, lng: 17.863182 },
    { lat: 47.966208, lng: 17.863672 },
    { lat: 47.965387, lng: 17.864372 },
    { lat: 47.965006, lng: 17.865141 },
    { lat: 47.964577, lng: 17.865619 },
    { lat: 47.964081, lng: 17.866537 },
    { lat: 47.963418, lng: 17.867098 },
    { lat: 47.962414, lng: 17.867771 },
    { lat: 47.962193, lng: 17.867717 },
    { lat: 47.962009, lng: 17.86769 },
    { lat: 47.961673, lng: 17.867667 },
    { lat: 47.961349, lng: 17.867617 },
    { lat: 47.960809, lng: 17.867622 },
    { lat: 47.960587, lng: 17.867558 },
    { lat: 47.960493, lng: 17.867533 },
    { lat: 47.960389, lng: 17.867523 },
    { lat: 47.960239, lng: 17.869915 },
    { lat: 47.960244, lng: 17.87101 },
    { lat: 47.960303, lng: 17.873448 },
    { lat: 47.960371, lng: 17.874883 },
    { lat: 47.960461, lng: 17.875495 },
    { lat: 47.960568, lng: 17.875601 },
    { lat: 47.960787, lng: 17.875078 },
    { lat: 47.960817, lng: 17.875006 },
    { lat: 47.96086, lng: 17.874904 },
    { lat: 47.960935, lng: 17.874773 },
    { lat: 47.960976, lng: 17.874701 },
    { lat: 47.961035, lng: 17.874596 },
    { lat: 47.961136, lng: 17.874459 },
    { lat: 47.961203, lng: 17.874368 },
    { lat: 47.961319, lng: 17.874245 },
    { lat: 47.961343, lng: 17.87422 },
    { lat: 47.961497, lng: 17.874103 },
    { lat: 47.961652, lng: 17.873984 },
    { lat: 47.96171, lng: 17.873948 },
    { lat: 47.961921, lng: 17.873814 },
    { lat: 47.961999, lng: 17.87377 },
    { lat: 47.962182, lng: 17.873669 },
    { lat: 47.962468, lng: 17.87351 },
    { lat: 47.962494, lng: 17.873497 },
    { lat: 47.962968, lng: 17.873267 },
    { lat: 47.963311, lng: 17.8731 },
    { lat: 47.963373, lng: 17.873407 },
    { lat: 47.963399, lng: 17.873525 },
    { lat: 47.963387, lng: 17.873543 },
    { lat: 47.963189, lng: 17.873869 },
    { lat: 47.963417, lng: 17.874046 },
    { lat: 47.963575, lng: 17.874169 },
    { lat: 47.963623, lng: 17.874206 },
    { lat: 47.963639, lng: 17.874219 },
    { lat: 47.963889, lng: 17.874392 },
    { lat: 47.964262, lng: 17.874585 },
    { lat: 47.964872, lng: 17.874748 },
    { lat: 47.965245, lng: 17.874847 },
    { lat: 47.965509, lng: 17.874917 },
    { lat: 47.965591, lng: 17.874939 },
    { lat: 47.96562, lng: 17.874947 },
    { lat: 47.965664, lng: 17.874958 },
    { lat: 47.965773, lng: 17.874987 },
    { lat: 47.965888, lng: 17.875018 },
    { lat: 47.966089, lng: 17.875072 },
    { lat: 47.966276, lng: 17.875121 },
    { lat: 47.966451, lng: 17.875168 },
    { lat: 47.966536, lng: 17.875188 },
    { lat: 47.966607, lng: 17.875209 },
    { lat: 47.966619, lng: 17.875208 },
    { lat: 47.966626, lng: 17.875208 },
    { lat: 47.966748, lng: 17.875199 },
    { lat: 47.966864, lng: 17.875192 },
    { lat: 47.966967, lng: 17.875184 },
    { lat: 47.966992, lng: 17.875182 },
    { lat: 47.967058, lng: 17.875178 },
    { lat: 47.967651, lng: 17.875136 },
    { lat: 47.967831, lng: 17.875124 },
    { lat: 47.968025, lng: 17.87511 },
    { lat: 47.968256, lng: 17.875094 },
    { lat: 47.9683, lng: 17.875091 },
    { lat: 47.969021, lng: 17.875047 },
    { lat: 47.969068, lng: 17.875044 },
    { lat: 47.969857, lng: 17.874995 },
    { lat: 47.970458, lng: 17.874958 },
    { lat: 47.970674, lng: 17.874944 },
    { lat: 47.970733, lng: 17.874941 },
    { lat: 47.970775, lng: 17.874938 },
    { lat: 47.970792, lng: 17.874937 },
    { lat: 47.970814, lng: 17.874937 },
    { lat: 47.970858, lng: 17.874936 },
    { lat: 47.970924, lng: 17.874935 },
    { lat: 47.970969, lng: 17.874935 },
    { lat: 47.971086, lng: 17.874933 },
    { lat: 47.971294, lng: 17.87493 },
    { lat: 47.971342, lng: 17.874929 },
    { lat: 47.971382, lng: 17.874928 },
    { lat: 47.971413, lng: 17.874929 },
    { lat: 47.971453, lng: 17.874928 },
    { lat: 47.971761, lng: 17.874924 },
    { lat: 47.971885, lng: 17.874922 },
    { lat: 47.972089, lng: 17.874919 },
    { lat: 47.972329, lng: 17.874916 },
    { lat: 47.972677, lng: 17.874911 },
    { lat: 47.972793, lng: 17.874909 },
    { lat: 47.972925, lng: 17.874907 },
    { lat: 47.974349, lng: 17.874887 },
    { lat: 47.974382, lng: 17.874887 },
    { lat: 47.974391, lng: 17.874887 },
    { lat: 47.975182, lng: 17.875191 },
    { lat: 47.975282, lng: 17.875229 },
    { lat: 47.975882, lng: 17.875456 },
    { lat: 47.976494, lng: 17.875687 },
    { lat: 47.97652, lng: 17.875698 },
    { lat: 47.977514, lng: 17.876073 },
    { lat: 47.977719, lng: 17.876151 },
    { lat: 47.978708, lng: 17.876525 },
    { lat: 47.978802, lng: 17.87656 },
    { lat: 47.979385, lng: 17.876779 },
    { lat: 47.980161, lng: 17.87707 },
    { lat: 47.980189, lng: 17.877081 },
    { lat: 47.98025, lng: 17.877104 },
    { lat: 47.980885, lng: 17.877346 },
    { lat: 47.981356, lng: 17.877526 },
    { lat: 47.981637, lng: 17.877633 },
    { lat: 47.981749, lng: 17.877683 },
    { lat: 47.98226, lng: 17.877909 },
    { lat: 47.982417, lng: 17.877978 },
    { lat: 47.982473, lng: 17.878002 },
    { lat: 47.982634, lng: 17.878073 },
    { lat: 47.982684, lng: 17.878095 },
    { lat: 47.9827, lng: 17.878103 },
    { lat: 47.982728, lng: 17.878115 },
    { lat: 47.98276, lng: 17.87813 },
    { lat: 47.982766, lng: 17.878132 },
    { lat: 47.982836, lng: 17.878163 },
    { lat: 47.983242, lng: 17.878347 },
    { lat: 47.983304, lng: 17.878375 },
    { lat: 47.983361, lng: 17.878401 },
    { lat: 47.983592, lng: 17.878505 },
    { lat: 47.983606, lng: 17.878512 },
    { lat: 47.983646, lng: 17.87853 },
    { lat: 47.98368, lng: 17.878545 },
    { lat: 47.983718, lng: 17.878563 },
    { lat: 47.983827, lng: 17.878612 },
    { lat: 47.983879, lng: 17.878635 },
    { lat: 47.983926, lng: 17.878656 },
    { lat: 47.984042, lng: 17.878709 },
    { lat: 47.984141, lng: 17.878763 },
    { lat: 47.985784, lng: 17.879655 },
    { lat: 47.986124, lng: 17.879838 },
    { lat: 47.986256, lng: 17.880241 },
    { lat: 47.986274, lng: 17.880294 },
    { lat: 47.986478, lng: 17.880356 },
    { lat: 47.987889, lng: 17.880516 },
    { lat: 47.987985, lng: 17.880529 },
    { lat: 47.988268, lng: 17.880376 },
    { lat: 47.989349, lng: 17.879718 },
    { lat: 47.990706, lng: 17.878919 },
    { lat: 47.992646, lng: 17.877822 },
    { lat: 47.994125, lng: 17.876925 },
    { lat: 47.995228, lng: 17.876282 },
    { lat: 47.996836, lng: 17.875407 },
    { lat: 47.997556, lng: 17.875004 },
    { lat: 47.998729, lng: 17.874297 },
    { lat: 47.999353, lng: 17.873922 },
    { lat: 47.99995, lng: 17.873561 },
    { lat: 48.001093, lng: 17.872848 },
    { lat: 48.00131, lng: 17.872713 },
    { lat: 48.001406, lng: 17.872653 },
    { lat: 48.001423, lng: 17.872642 },
    { lat: 48.00149, lng: 17.8726 },
    { lat: 48.002139, lng: 17.872191 },
    { lat: 48.003694, lng: 17.871216 },
    { lat: 48.004409, lng: 17.870575 },
    { lat: 48.004596, lng: 17.870407 },
    { lat: 48.004962, lng: 17.870079 },
    { lat: 48.005471, lng: 17.869626 },
    { lat: 48.005895, lng: 17.869777 },
    { lat: 48.006487, lng: 17.87018 },
    { lat: 48.006785, lng: 17.870382 },
    { lat: 48.009287, lng: 17.871222 },
    { lat: 48.009591, lng: 17.871323 },
    { lat: 48.009679, lng: 17.871354 },
    { lat: 48.010438, lng: 17.871619 },
    { lat: 48.011328, lng: 17.87193 },
    { lat: 48.012522, lng: 17.8723 },
    { lat: 48.013598, lng: 17.872686 },
    { lat: 48.016577, lng: 17.873568 },
    { lat: 48.017855, lng: 17.87396 },
    { lat: 48.017942, lng: 17.873986 },
    { lat: 48.018354, lng: 17.874114 },
    { lat: 48.019921, lng: 17.872068 },
    { lat: 48.022491, lng: 17.868624 },
    { lat: 48.024863, lng: 17.8631625 },
    { lat: 48.0275485, lng: 17.858114 },
    { lat: 48.0288181, lng: 17.8559721 },
    { lat: 48.0287493, lng: 17.852827 },
    { lat: 48.028322, lng: 17.8449664 },
    { lat: 48.0277356, lng: 17.8449794 },
    { lat: 48.0255688, lng: 17.845611 },
    { lat: 48.0251227, lng: 17.8457935 },
    { lat: 48.0236755, lng: 17.8465673 },
    { lat: 48.0214948, lng: 17.8443671 },
    { lat: 48.0213177, lng: 17.844203 },
    { lat: 48.0210714, lng: 17.844787 },
    { lat: 48.0203131, lng: 17.8471616 },
    { lat: 48.0200936, lng: 17.8475957 },
    { lat: 48.0198213, lng: 17.8479237 },
    { lat: 48.0193635, lng: 17.8481875 },
    { lat: 48.0187116, lng: 17.8484006 },
    { lat: 48.0174795, lng: 17.8483561 },
    { lat: 48.0170073, lng: 17.8482217 },
    { lat: 48.0161294, lng: 17.8477513 },
    { lat: 48.0155806, lng: 17.8475268 },
    { lat: 48.0148287, lng: 17.847286 },
    { lat: 48.0142823, lng: 17.8473035 },
    { lat: 48.0137703, lng: 17.8474058 },
    { lat: 48.0135279, lng: 17.8475182 },
    { lat: 48.0132911, lng: 17.847725 },
    { lat: 48.0128742, lng: 17.8482515 },
    { lat: 48.0122391, lng: 17.849528 },
    { lat: 48.0117756, lng: 17.8495725 },
    { lat: 48.0115199, lng: 17.8493797 },
    { lat: 48.0114263, lng: 17.849035 },
    { lat: 48.011255, lng: 17.8474934 },
    { lat: 48.0109122, lng: 17.8453481 },
    { lat: 48.0108769, lng: 17.8446479 },
    { lat: 48.0108855, lng: 17.8437216 },
    { lat: 48.0109508, lng: 17.8431141 },
    { lat: 48.0110916, lng: 17.8426299 },
    { lat: 48.0115358, lng: 17.8416272 },
    { lat: 48.0115681, lng: 17.8410528 },
    { lat: 48.0114516, lng: 17.8407013 },
    { lat: 48.0111837, lng: 17.8404379 },
    { lat: 48.0108915, lng: 17.8403678 },
    { lat: 48.0105971, lng: 17.8404073 },
    { lat: 48.0102304, lng: 17.8406887 },
    { lat: 48.0099792, lng: 17.8410621 },
    { lat: 48.009505, lng: 17.8421101 },
    { lat: 48.0081931, lng: 17.8481502 },
    { lat: 48.0077765, lng: 17.8505949 },
    { lat: 48.0074221, lng: 17.851256 },
    { lat: 48.0071419, lng: 17.8514366 },
    { lat: 48.0069564, lng: 17.8514315 },
    { lat: 48.0066578, lng: 17.8512375 },
    { lat: 48.0063718, lng: 17.8508059 },
    { lat: 48.0060754, lng: 17.8498237 },
    { lat: 48.0060723, lng: 17.8493105 },
    { lat: 48.0063306, lng: 17.8485361 },
    { lat: 48.0074431, lng: 17.8466539 },
    { lat: 48.0076288, lng: 17.8460567 },
    { lat: 48.00766, lng: 17.8457557 },
    { lat: 48.0076287, lng: 17.8454321 },
    { lat: 48.0074462, lng: 17.8449416 },
    { lat: 48.0071904, lng: 17.8446008 },
    { lat: 48.0061404, lng: 17.8440451 },
    { lat: 48.0058833, lng: 17.84396 },
    { lat: 48.0054651, lng: 17.8439342 },
    { lat: 48.004954, lng: 17.8441199 },
    { lat: 48.0047886, lng: 17.8442959 },
    { lat: 48.0043415, lng: 17.8449205 },
    { lat: 48.0041779, lng: 17.8452336 },
    { lat: 48.003692, lng: 17.8466757 },
    { lat: 48.0033354, lng: 17.8483296 },
    { lat: 48.00312, lng: 17.8499878 },
    { lat: 48.003006, lng: 17.8505772 },
    { lat: 48.0028942, lng: 17.85153 },
    { lat: 48.0028802, lng: 17.8524427 },
    { lat: 48.0031172, lng: 17.855698 },
    { lat: 48.0030527, lng: 17.8570374 },
    { lat: 48.0028868, lng: 17.8577769 },
    { lat: 48.0028151, lng: 17.8579495 },
    { lat: 48.0026744, lng: 17.8581051 },
    { lat: 48.0022369, lng: 17.8583491 },
    { lat: 48.0017502, lng: 17.8583131 },
    { lat: 48.0011081, lng: 17.8581109 },
    { lat: 48.0009308, lng: 17.8581287 },
    { lat: 48.0007245, lng: 17.8582815 },
    { lat: 48.0006394, lng: 17.8585342 },
    { lat: 48.0007081, lng: 17.8590091 },
    { lat: 48.0009329, lng: 17.8592845 },
    { lat: 48.0017964, lng: 17.8596332 },
    { lat: 48.0024267, lng: 17.8600098 },
    { lat: 48.0035328, lng: 17.8610005 },
    { lat: 48.0055453, lng: 17.8636655 },
    { lat: 48.0059616, lng: 17.8644164 },
    { lat: 48.0060725, lng: 17.8647175 },
    { lat: 48.0062038, lng: 17.865405 },
    { lat: 48.0061775, lng: 17.8659683 },
    { lat: 48.006076, lng: 17.8663385 },
    { lat: 48.0055448, lng: 17.8671764 },
    { lat: 48.0049618, lng: 17.8677379 },
    { lat: 48.0036364, lng: 17.8683835 },
    { lat: 48.0032659, lng: 17.8684712 },
    { lat: 48.0025407, lng: 17.8683524 },
    { lat: 48.0019891, lng: 17.8680291 },
    { lat: 48.0016408, lng: 17.8676299 },
    { lat: 48.0014446, lng: 17.867099 },
    { lat: 48.0011409, lng: 17.8659184 },
    { lat: 48.0011728, lng: 17.864728 },
    { lat: 48.00161, lng: 17.862044 },
    { lat: 48.0016058, lng: 17.8615159 },
    { lat: 48.0015474, lng: 17.8611696 },
    { lat: 48.0014564, lng: 17.8608084 },
    { lat: 48.0012931, lng: 17.8605402 },
    { lat: 48.0011282, lng: 17.8603558 },
    { lat: 48.0008058, lng: 17.8602132 },
    { lat: 48.000557, lng: 17.8601861 },
    { lat: 47.99983, lng: 17.860282 },
    { lat: 47.9988026, lng: 17.8603151 },
    { lat: 47.998149, lng: 17.8605549 },
    { lat: 47.997599, lng: 17.8610672 },
    { lat: 47.9974879, lng: 17.860838 },
    { lat: 47.9950229, lng: 17.8468138 },
    { lat: 47.9943544, lng: 17.8433973 },
    { lat: 47.9939779, lng: 17.8409106 },
    { lat: 47.9937205, lng: 17.8405601 },
    { lat: 47.9901206, lng: 17.8340495 },
    { lat: 47.9885109, lng: 17.8312467 },
    { lat: 47.988195, lng: 17.830452 },
    { lat: 47.98816, lng: 17.830459 },
    { lat: 47.988123, lng: 17.830468 },
    { lat: 47.988066, lng: 17.830477 },
    { lat: 47.988025, lng: 17.830482 },
    { lat: 47.987785, lng: 17.830515 },
    { lat: 47.987386, lng: 17.830583 },
    { lat: 47.987052, lng: 17.830618 },
    { lat: 47.986846, lng: 17.830633 },
    { lat: 47.986656, lng: 17.830654 },
    { lat: 47.986641, lng: 17.830655 },
    { lat: 47.986547, lng: 17.830672 },
    { lat: 47.98646, lng: 17.830688 },
    { lat: 47.986442, lng: 17.830691 },
    { lat: 47.986273, lng: 17.830722 },
    { lat: 47.986236, lng: 17.830733 },
    { lat: 47.986005, lng: 17.83078 },
    { lat: 47.985541, lng: 17.83102 },
    { lat: 47.985455, lng: 17.831083 },
    { lat: 47.985057, lng: 17.831384 },
    { lat: 47.984846, lng: 17.831547 },
    { lat: 47.984698, lng: 17.831894 },
    { lat: 47.984584, lng: 17.832034 },
    { lat: 47.984453, lng: 17.832193 },
    { lat: 47.984359, lng: 17.832312 },
    { lat: 47.984316, lng: 17.832352 },
    { lat: 47.98417, lng: 17.832496 },
    { lat: 47.983941, lng: 17.832742 },
  ],
  PustéSady: [
    { lat: 48.320768, lng: 17.851246 },
    { lat: 48.320774, lng: 17.851271 },
    { lat: 48.321019, lng: 17.851105 },
    { lat: 48.321323, lng: 17.850894 },
    { lat: 48.321706, lng: 17.850635 },
    { lat: 48.322034, lng: 17.850415 },
    { lat: 48.322257, lng: 17.850268 },
    { lat: 48.322453, lng: 17.850142 },
    { lat: 48.322819, lng: 17.849906 },
    { lat: 48.323122, lng: 17.849715 },
    { lat: 48.323498, lng: 17.849476 },
    { lat: 48.323652, lng: 17.849377 },
    { lat: 48.3238, lng: 17.849304 },
    { lat: 48.324109, lng: 17.849169 },
    { lat: 48.324301, lng: 17.849091 },
    { lat: 48.324525, lng: 17.848998 },
    { lat: 48.324717, lng: 17.848928 },
    { lat: 48.324997, lng: 17.84883 },
    { lat: 48.325417, lng: 17.848698 },
    { lat: 48.325529, lng: 17.848666 },
    { lat: 48.325648, lng: 17.848662 },
    { lat: 48.325754, lng: 17.84861 },
    { lat: 48.325888, lng: 17.848543 },
    { lat: 48.326096, lng: 17.848443 },
    { lat: 48.3258044, lng: 17.8476079 },
    { lat: 48.3252922, lng: 17.8456173 },
    { lat: 48.3245412, lng: 17.8422307 },
    { lat: 48.3239239, lng: 17.8401493 },
    { lat: 48.322838, lng: 17.8374709 },
    { lat: 48.3228084, lng: 17.837486 },
    { lat: 48.3218498, lng: 17.8354009 },
    { lat: 48.3218139, lng: 17.8346479 },
    { lat: 48.3214705, lng: 17.8327446 },
    { lat: 48.3209729, lng: 17.8315869 },
    { lat: 48.3203325, lng: 17.8295699 },
    { lat: 48.3187273, lng: 17.8263459 },
    { lat: 48.3182027, lng: 17.825081 },
    { lat: 48.3177582, lng: 17.8236491 },
    { lat: 48.317099, lng: 17.8220495 },
    { lat: 48.3166179, lng: 17.8223602 },
    { lat: 48.3161906, lng: 17.822517 },
    { lat: 48.315853, lng: 17.8227362 },
    { lat: 48.3155213, lng: 17.8227586 },
    { lat: 48.3145559, lng: 17.8194957 },
    { lat: 48.3139143, lng: 17.8175205 },
    { lat: 48.3136853, lng: 17.816548 },
    { lat: 48.3136379, lng: 17.8162343 },
    { lat: 48.3136679, lng: 17.8158216 },
    { lat: 48.3140096, lng: 17.8141424 },
    { lat: 48.3143896, lng: 17.8116896 },
    { lat: 48.3145081, lng: 17.8102358 },
    { lat: 48.3143874, lng: 17.8083827 },
    { lat: 48.3143136, lng: 17.8078439 },
    { lat: 48.3145107, lng: 17.807429 },
    { lat: 48.314353, lng: 17.8065173 },
    { lat: 48.3143331, lng: 17.8060626 },
    { lat: 48.3143744, lng: 17.8056142 },
    { lat: 48.3140929, lng: 17.8043785 },
    { lat: 48.3137045, lng: 17.8007969 },
    { lat: 48.3135173, lng: 17.7997003 },
    { lat: 48.3137592, lng: 17.798971 },
    { lat: 48.314319, lng: 17.7980151 },
    { lat: 48.3149487, lng: 17.7975481 },
    { lat: 48.3155726, lng: 17.7972118 },
    { lat: 48.3163226, lng: 17.796908 },
    { lat: 48.3170684, lng: 17.7963877 },
    { lat: 48.3163389, lng: 17.7958697 },
    { lat: 48.3140478, lng: 17.7955486 },
    { lat: 48.3136655, lng: 17.7953721 },
    { lat: 48.3131555, lng: 17.7946812 },
    { lat: 48.3130902, lng: 17.7943926 },
    { lat: 48.3128504, lng: 17.7937977 },
    { lat: 48.3123433, lng: 17.7931699 },
    { lat: 48.3120047, lng: 17.7926189 },
    { lat: 48.311427, lng: 17.7934684 },
    { lat: 48.3113068, lng: 17.7935145 },
    { lat: 48.3107441, lng: 17.7933437 },
    { lat: 48.3105845, lng: 17.7929526 },
    { lat: 48.3104522, lng: 17.7921227 },
    { lat: 48.3103893, lng: 17.7919803 },
    { lat: 48.310149, lng: 17.7916167 },
    { lat: 48.3096186, lng: 17.7912177 },
    { lat: 48.3094052, lng: 17.7908522 },
    { lat: 48.309365, lng: 17.7906563 },
    { lat: 48.3094832, lng: 17.7891839 },
    { lat: 48.309273, lng: 17.7885218 },
    { lat: 48.3092012, lng: 17.7880519 },
    { lat: 48.3091967, lng: 17.7880171 },
    { lat: 48.3085683, lng: 17.7885381 },
    { lat: 48.3082191, lng: 17.7887333 },
    { lat: 48.3077945, lng: 17.789245 },
    { lat: 48.3073782, lng: 17.7894696 },
    { lat: 48.3072539, lng: 17.7896134 },
    { lat: 48.3067743, lng: 17.7903312 },
    { lat: 48.3064394, lng: 17.7910159 },
    { lat: 48.3060343, lng: 17.7914213 },
    { lat: 48.3058297, lng: 17.7917492 },
    { lat: 48.3054334, lng: 17.7922239 },
    { lat: 48.3051639, lng: 17.7928531 },
    { lat: 48.3047488, lng: 17.7935924 },
    { lat: 48.3043235, lng: 17.7940736 },
    { lat: 48.3040345, lng: 17.7943132 },
    { lat: 48.3034953, lng: 17.7945399 },
    { lat: 48.3032276, lng: 17.7950531 },
    { lat: 48.3028637, lng: 17.7955595 },
    { lat: 48.3019521, lng: 17.7941201 },
    { lat: 48.3018546, lng: 17.7941705 },
    { lat: 48.3005567, lng: 17.7964664 },
    { lat: 48.300035, lng: 17.7974005 },
    { lat: 48.3002746, lng: 17.7981574 },
    { lat: 48.3005626, lng: 17.8003801 },
    { lat: 48.3006563, lng: 17.8028385 },
    { lat: 48.3005561, lng: 17.8049911 },
    { lat: 48.3007493, lng: 17.8096989 },
    { lat: 48.3002531, lng: 17.8117114 },
    { lat: 48.3000665, lng: 17.8126448 },
    { lat: 48.2996747, lng: 17.8141118 },
    { lat: 48.2991161, lng: 17.8154018 },
    { lat: 48.2996483, lng: 17.8180595 },
    { lat: 48.299491, lng: 17.8184769 },
    { lat: 48.2991362, lng: 17.8191328 },
    { lat: 48.2985854, lng: 17.8205705 },
    { lat: 48.298453, lng: 17.8220218 },
    { lat: 48.2981655, lng: 17.8242546 },
    { lat: 48.2981766, lng: 17.8253384 },
    { lat: 48.2982525, lng: 17.8260242 },
    { lat: 48.2985164, lng: 17.8272157 },
    { lat: 48.2985271, lng: 17.8275024 },
    { lat: 48.2973258, lng: 17.8312585 },
    { lat: 48.29675, lng: 17.833366 },
    { lat: 48.296799, lng: 17.833333 },
    { lat: 48.297144, lng: 17.833161 },
    { lat: 48.297252, lng: 17.833108 },
    { lat: 48.297465, lng: 17.833002 },
    { lat: 48.297574, lng: 17.832953 },
    { lat: 48.297923, lng: 17.833282 },
    { lat: 48.298053, lng: 17.833423 },
    { lat: 48.298227, lng: 17.833649 },
    { lat: 48.298512, lng: 17.83443 },
    { lat: 48.298747, lng: 17.835069 },
    { lat: 48.298932, lng: 17.835452 },
    { lat: 48.299161, lng: 17.835927 },
    { lat: 48.299302, lng: 17.836199 },
    { lat: 48.299339, lng: 17.836246 },
    { lat: 48.299653, lng: 17.836671 },
    { lat: 48.299896, lng: 17.837003 },
    { lat: 48.300126, lng: 17.837423 },
    { lat: 48.30029, lng: 17.83772 },
    { lat: 48.300558, lng: 17.838239 },
    { lat: 48.300976, lng: 17.839052 },
    { lat: 48.301284, lng: 17.83963 },
    { lat: 48.301656, lng: 17.840327 },
    { lat: 48.301922, lng: 17.840819 },
    { lat: 48.30207, lng: 17.841053 },
    { lat: 48.302204, lng: 17.841285 },
    { lat: 48.302291, lng: 17.841445 },
    { lat: 48.302787, lng: 17.842487 },
    { lat: 48.303107, lng: 17.843158 },
    { lat: 48.303346, lng: 17.843666 },
    { lat: 48.303401, lng: 17.843724 },
    { lat: 48.303967, lng: 17.844305 },
    { lat: 48.304378, lng: 17.844721 },
    { lat: 48.30479, lng: 17.845152 },
    { lat: 48.305164, lng: 17.845563 },
    { lat: 48.305448, lng: 17.845887 },
    { lat: 48.305742, lng: 17.846208 },
    { lat: 48.306226, lng: 17.846738 },
    { lat: 48.306544, lng: 17.847025 },
    { lat: 48.306623, lng: 17.847098 },
    { lat: 48.306966, lng: 17.847048 },
    { lat: 48.307216, lng: 17.847021 },
    { lat: 48.307377, lng: 17.847003 },
    { lat: 48.307477, lng: 17.846995 },
    { lat: 48.307655, lng: 17.846999 },
    { lat: 48.307907, lng: 17.847003 },
    { lat: 48.308175, lng: 17.847016 },
    { lat: 48.308178, lng: 17.847015 },
    { lat: 48.308244, lng: 17.847019 },
    { lat: 48.308639, lng: 17.847034 },
    { lat: 48.308944, lng: 17.847056 },
    { lat: 48.309227, lng: 17.847063 },
    { lat: 48.309409, lng: 17.84707 },
    { lat: 48.30946, lng: 17.847069 },
    { lat: 48.309543, lng: 17.847068 },
    { lat: 48.30978, lng: 17.84705 },
    { lat: 48.309885, lng: 17.847046 },
    { lat: 48.310309, lng: 17.84703 },
    { lat: 48.310361, lng: 17.84702 },
    { lat: 48.310632, lng: 17.846967 },
    { lat: 48.310662, lng: 17.84696 },
    { lat: 48.310667, lng: 17.846959 },
    { lat: 48.311281, lng: 17.846824 },
    { lat: 48.311768, lng: 17.846771 },
    { lat: 48.312077, lng: 17.846738 },
    { lat: 48.312423, lng: 17.846705 },
    { lat: 48.312925, lng: 17.846683 },
    { lat: 48.312928, lng: 17.846683 },
    { lat: 48.31297, lng: 17.846687 },
    { lat: 48.313019, lng: 17.846692 },
    { lat: 48.313095, lng: 17.846705 },
    { lat: 48.313226, lng: 17.846735 },
    { lat: 48.31329, lng: 17.846749 },
    { lat: 48.313355, lng: 17.846763 },
    { lat: 48.313481, lng: 17.846783 },
    { lat: 48.313569, lng: 17.846785 },
    { lat: 48.313641, lng: 17.846778 },
    { lat: 48.313688, lng: 17.846775 },
    { lat: 48.313813, lng: 17.846767 },
    { lat: 48.313866, lng: 17.846767 },
    { lat: 48.313914, lng: 17.846764 },
    { lat: 48.313979, lng: 17.846764 },
    { lat: 48.314086, lng: 17.846758 },
    { lat: 48.314157, lng: 17.846755 },
    { lat: 48.314297, lng: 17.846755 },
    { lat: 48.314587, lng: 17.846748 },
    { lat: 48.314732, lng: 17.846741 },
    { lat: 48.31481, lng: 17.84674 },
    { lat: 48.314885, lng: 17.846735 },
    { lat: 48.315012, lng: 17.846728 },
    { lat: 48.315096, lng: 17.846734 },
    { lat: 48.315183, lng: 17.846729 },
    { lat: 48.315259, lng: 17.846723 },
    { lat: 48.315385, lng: 17.846722 },
    { lat: 48.315504, lng: 17.846719 },
    { lat: 48.315574, lng: 17.846718 },
    { lat: 48.315638, lng: 17.846717 },
    { lat: 48.315764, lng: 17.846709 },
    { lat: 48.31603, lng: 17.846708 },
    { lat: 48.316172, lng: 17.846711 },
    { lat: 48.316291, lng: 17.846716 },
    { lat: 48.316293, lng: 17.846716 },
    { lat: 48.316351, lng: 17.846716 },
    { lat: 48.316605, lng: 17.84672 },
    { lat: 48.316677, lng: 17.846722 },
    { lat: 48.316758, lng: 17.846724 },
    { lat: 48.316973, lng: 17.846731 },
    { lat: 48.317037, lng: 17.846752 },
    { lat: 48.317105, lng: 17.846771 },
    { lat: 48.317163, lng: 17.84679 },
    { lat: 48.317224, lng: 17.846807 },
    { lat: 48.317318, lng: 17.84683 },
    { lat: 48.317409, lng: 17.846853 },
    { lat: 48.317554, lng: 17.84686 },
    { lat: 48.317741, lng: 17.846875 },
    { lat: 48.317915, lng: 17.84689 },
    { lat: 48.318008, lng: 17.846897 },
    { lat: 48.318091, lng: 17.846898 },
    { lat: 48.318182, lng: 17.846899 },
    { lat: 48.318268, lng: 17.846889 },
    { lat: 48.318368, lng: 17.846887 },
    { lat: 48.318468, lng: 17.84688 },
    { lat: 48.318775, lng: 17.846857 },
    { lat: 48.318935, lng: 17.846843 },
    { lat: 48.319106, lng: 17.846838 },
    { lat: 48.319563, lng: 17.846817 },
    { lat: 48.319572, lng: 17.846817 },
    { lat: 48.319598, lng: 17.846817 },
    { lat: 48.320225, lng: 17.849277 },
    { lat: 48.320569, lng: 17.850526 },
    { lat: 48.320619, lng: 17.850704 },
    { lat: 48.320743, lng: 17.851164 },
    { lat: 48.320747, lng: 17.851179 },
    { lat: 48.320768, lng: 17.851246 },
  ],
  Pata: [
    { lat: 48.280689, lng: 17.865608 },
    { lat: 48.280727, lng: 17.865583 },
    { lat: 48.280737, lng: 17.865577 },
    { lat: 48.280773, lng: 17.865552 },
    { lat: 48.281056, lng: 17.865369 },
    { lat: 48.28138, lng: 17.865203 },
    { lat: 48.281489, lng: 17.865136 },
    { lat: 48.281691, lng: 17.864986 },
    { lat: 48.28201, lng: 17.864777 },
    { lat: 48.282336, lng: 17.864573 },
    { lat: 48.282651, lng: 17.864378 },
    { lat: 48.282962, lng: 17.864172 },
    { lat: 48.283088, lng: 17.864098 },
    { lat: 48.283294, lng: 17.863977 },
    { lat: 48.283415, lng: 17.863924 },
    { lat: 48.283451, lng: 17.863907 },
    { lat: 48.283885, lng: 17.863719 },
    { lat: 48.284091, lng: 17.863623 },
    { lat: 48.284224, lng: 17.863561 },
    { lat: 48.284555, lng: 17.863404 },
    { lat: 48.284757, lng: 17.863276 },
    { lat: 48.284906, lng: 17.863182 },
    { lat: 48.285179, lng: 17.863033 },
    { lat: 48.285241, lng: 17.862999 },
    { lat: 48.28529, lng: 17.86296 },
    { lat: 48.285562, lng: 17.862737 },
    { lat: 48.285797, lng: 17.862529 },
    { lat: 48.28582, lng: 17.862509 },
    { lat: 48.285822, lng: 17.862506 },
    { lat: 48.285864, lng: 17.862467 },
    { lat: 48.285867, lng: 17.862465 },
    { lat: 48.285948, lng: 17.862403 },
    { lat: 48.285959, lng: 17.862395 },
    { lat: 48.285982, lng: 17.862378 },
    { lat: 48.285996, lng: 17.862366 },
    { lat: 48.285998, lng: 17.862365 },
    { lat: 48.286167, lng: 17.862224 },
    { lat: 48.286392, lng: 17.861701 },
    { lat: 48.286581, lng: 17.861307 },
    { lat: 48.286983, lng: 17.860457 },
    { lat: 48.287074, lng: 17.860218 },
    { lat: 48.287276, lng: 17.859686 },
    { lat: 48.287396, lng: 17.858958 },
    { lat: 48.287431, lng: 17.858743 },
    { lat: 48.287449, lng: 17.858622 },
    { lat: 48.287449, lng: 17.858614 },
    { lat: 48.28745, lng: 17.858607 },
    { lat: 48.287456, lng: 17.858565 },
    { lat: 48.287477, lng: 17.858415 },
    { lat: 48.287505, lng: 17.858192 },
    { lat: 48.287519, lng: 17.858008 },
    { lat: 48.28752, lng: 17.858003 },
    { lat: 48.287535, lng: 17.857856 },
    { lat: 48.287536, lng: 17.857831 },
    { lat: 48.287537, lng: 17.857829 },
    { lat: 48.287554, lng: 17.857777 },
    { lat: 48.28785, lng: 17.857203 },
    { lat: 48.28837, lng: 17.8562 },
    { lat: 48.288373, lng: 17.856161 },
    { lat: 48.288423, lng: 17.855541 },
    { lat: 48.288465, lng: 17.854387 },
    { lat: 48.28847, lng: 17.854095 },
    { lat: 48.288496, lng: 17.852682 },
    { lat: 48.288512, lng: 17.852038 },
    { lat: 48.288482, lng: 17.85163 },
    { lat: 48.288445, lng: 17.851379 },
    { lat: 48.288353, lng: 17.850738 },
    { lat: 48.288343, lng: 17.85069 },
    { lat: 48.288309, lng: 17.850132 },
    { lat: 48.288293, lng: 17.849819 },
    { lat: 48.288274, lng: 17.849542 },
    { lat: 48.288269, lng: 17.849466 },
    { lat: 48.288265, lng: 17.849404 },
    { lat: 48.288311, lng: 17.848976 },
    { lat: 48.28836, lng: 17.848442 },
    { lat: 48.288375, lng: 17.848167 },
    { lat: 48.288375, lng: 17.848164 },
    { lat: 48.2884, lng: 17.847693 },
    { lat: 48.288374, lng: 17.846933 },
    { lat: 48.288346, lng: 17.846238 },
    { lat: 48.2883, lng: 17.845567 },
    { lat: 48.288249, lng: 17.844926 },
    { lat: 48.288273, lng: 17.844839 },
    { lat: 48.288439, lng: 17.844481 },
    { lat: 48.288486, lng: 17.84422 },
    { lat: 48.288486, lng: 17.844224 },
    { lat: 48.288533, lng: 17.843963 },
    { lat: 48.288571, lng: 17.843764 },
    { lat: 48.288599, lng: 17.843615 },
    { lat: 48.288621, lng: 17.843285 },
    { lat: 48.288606, lng: 17.843009 },
    { lat: 48.28858, lng: 17.842496 },
    { lat: 48.28858, lng: 17.842475 },
    { lat: 48.288562, lng: 17.842194 },
    { lat: 48.288559, lng: 17.842114 },
    { lat: 48.288551, lng: 17.841986 },
    { lat: 48.288567, lng: 17.841764 },
    { lat: 48.28858, lng: 17.841695 },
    { lat: 48.288596, lng: 17.841591 },
    { lat: 48.288646, lng: 17.840713 },
    { lat: 48.288675, lng: 17.840468 },
    { lat: 48.288687, lng: 17.840134 },
    { lat: 48.288729, lng: 17.83976 },
    { lat: 48.288823, lng: 17.83954 },
    { lat: 48.289158, lng: 17.838758 },
    { lat: 48.289189, lng: 17.838676 },
    { lat: 48.289273, lng: 17.838455 },
    { lat: 48.289291, lng: 17.838408 },
    { lat: 48.289477, lng: 17.837924 },
    { lat: 48.28951, lng: 17.837869 },
    { lat: 48.289668, lng: 17.837632 },
    { lat: 48.290083, lng: 17.837016 },
    { lat: 48.290269, lng: 17.836652 },
    { lat: 48.29048, lng: 17.8362 },
    { lat: 48.290548, lng: 17.836174 },
    { lat: 48.290658, lng: 17.836132 },
    { lat: 48.290661, lng: 17.836131 },
    { lat: 48.290851, lng: 17.83606 },
    { lat: 48.291057, lng: 17.835977 },
    { lat: 48.291393, lng: 17.835853 },
    { lat: 48.291443, lng: 17.835833 },
    { lat: 48.291491, lng: 17.835818 },
    { lat: 48.291699, lng: 17.835753 },
    { lat: 48.291939, lng: 17.835678 },
    { lat: 48.292359, lng: 17.835541 },
    { lat: 48.292722, lng: 17.835429 },
    { lat: 48.292795, lng: 17.835403 },
    { lat: 48.293106, lng: 17.835269 },
    { lat: 48.293468, lng: 17.835115 },
    { lat: 48.29381, lng: 17.834976 },
    { lat: 48.29413, lng: 17.83485 },
    { lat: 48.294437, lng: 17.834721 },
    { lat: 48.294755, lng: 17.83464 },
    { lat: 48.294791, lng: 17.834622 },
    { lat: 48.295536, lng: 17.834258 },
    { lat: 48.29572, lng: 17.834138 },
    { lat: 48.295915, lng: 17.833986 },
    { lat: 48.296349, lng: 17.833646 },
    { lat: 48.296633, lng: 17.833446 },
    { lat: 48.296683, lng: 17.833409 },
    { lat: 48.29675, lng: 17.833366 },
    { lat: 48.2973258, lng: 17.8312585 },
    { lat: 48.2985271, lng: 17.8275024 },
    { lat: 48.2985164, lng: 17.8272157 },
    { lat: 48.2982525, lng: 17.8260242 },
    { lat: 48.2981766, lng: 17.8253384 },
    { lat: 48.2981655, lng: 17.8242546 },
    { lat: 48.298453, lng: 17.8220218 },
    { lat: 48.2985854, lng: 17.8205705 },
    { lat: 48.2991362, lng: 17.8191328 },
    { lat: 48.299491, lng: 17.8184769 },
    { lat: 48.2996483, lng: 17.8180595 },
    { lat: 48.2991161, lng: 17.8154018 },
    { lat: 48.2996747, lng: 17.8141118 },
    { lat: 48.3000665, lng: 17.8126448 },
    { lat: 48.3002531, lng: 17.8117114 },
    { lat: 48.3007493, lng: 17.8096989 },
    { lat: 48.3005561, lng: 17.8049911 },
    { lat: 48.3006563, lng: 17.8028385 },
    { lat: 48.3005626, lng: 17.8003801 },
    { lat: 48.3002746, lng: 17.7981574 },
    { lat: 48.300035, lng: 17.7974005 },
    { lat: 48.2992998, lng: 17.7952341 },
    { lat: 48.2966058, lng: 17.7915776 },
    { lat: 48.2954172, lng: 17.7904101 },
    { lat: 48.2954158, lng: 17.7886823 },
    { lat: 48.2946135, lng: 17.7871681 },
    { lat: 48.2934503, lng: 17.7888862 },
    { lat: 48.2927074, lng: 17.7896371 },
    { lat: 48.2915749, lng: 17.7904962 },
    { lat: 48.2912874, lng: 17.7906494 },
    { lat: 48.2904604, lng: 17.7914069 },
    { lat: 48.2877776, lng: 17.7933456 },
    { lat: 48.2866961, lng: 17.794393 },
    { lat: 48.2853903, lng: 17.7957735 },
    { lat: 48.2832353, lng: 17.7972436 },
    { lat: 48.2827795, lng: 17.7976152 },
    { lat: 48.2790503, lng: 17.800657 },
    { lat: 48.2743295, lng: 17.8041668 },
    { lat: 48.2730199, lng: 17.8057257 },
    { lat: 48.2729922, lng: 17.8057565 },
    { lat: 48.2730062, lng: 17.8065685 },
    { lat: 48.2710308, lng: 17.8082809 },
    { lat: 48.269227, lng: 17.809551 },
    { lat: 48.264947, lng: 17.8131508 },
    { lat: 48.2649313, lng: 17.8136111 },
    { lat: 48.2645024, lng: 17.814274 },
    { lat: 48.2636238, lng: 17.8150782 },
    { lat: 48.2630042, lng: 17.8158179 },
    { lat: 48.2621107, lng: 17.8171315 },
    { lat: 48.2617623, lng: 17.8179674 },
    { lat: 48.2622207, lng: 17.8187953 },
    { lat: 48.2627412, lng: 17.8189624 },
    { lat: 48.2636034, lng: 17.8205755 },
    { lat: 48.2632493, lng: 17.8205258 },
    { lat: 48.2612603, lng: 17.8205658 },
    { lat: 48.2604963, lng: 17.8191533 },
    { lat: 48.2577703, lng: 17.8235375 },
    { lat: 48.2563787, lng: 17.8252561 },
    { lat: 48.2558522, lng: 17.8263718 },
    { lat: 48.2566706, lng: 17.8278236 },
    { lat: 48.2562077, lng: 17.8285037 },
    { lat: 48.2557839, lng: 17.8289382 },
    { lat: 48.2551087, lng: 17.8277412 },
    { lat: 48.2547059, lng: 17.8285919 },
    { lat: 48.2543181, lng: 17.8297791 },
    { lat: 48.2547602, lng: 17.8302302 },
    { lat: 48.2551026, lng: 17.8306794 },
    { lat: 48.2564986, lng: 17.8330112 },
    { lat: 48.2565064, lng: 17.8351993 },
    { lat: 48.2563599, lng: 17.8355195 },
    { lat: 48.2571595, lng: 17.8370374 },
    { lat: 48.258219, lng: 17.8383348 },
    { lat: 48.2590056, lng: 17.8395489 },
    { lat: 48.2596723, lng: 17.840686 },
    { lat: 48.2605222, lng: 17.8423966 },
    { lat: 48.2588744, lng: 17.8448043 },
    { lat: 48.2596566, lng: 17.8460202 },
    { lat: 48.2611689, lng: 17.8487436 },
    { lat: 48.261325, lng: 17.850654 },
    { lat: 48.261586, lng: 17.850912 },
    { lat: 48.261667, lng: 17.850991 },
    { lat: 48.26208, lng: 17.851395 },
    { lat: 48.262476, lng: 17.851855 },
    { lat: 48.263494, lng: 17.85339 },
    { lat: 48.263673, lng: 17.853771 },
    { lat: 48.264236, lng: 17.85506 },
    { lat: 48.264244, lng: 17.855078 },
    { lat: 48.26448, lng: 17.855623 },
    { lat: 48.264501, lng: 17.855673 },
    { lat: 48.264721, lng: 17.856188 },
    { lat: 48.264841, lng: 17.856466 },
    { lat: 48.265122, lng: 17.857117 },
    { lat: 48.265407, lng: 17.857587 },
    { lat: 48.266058, lng: 17.858495 },
    { lat: 48.266458, lng: 17.858964 },
    { lat: 48.266667, lng: 17.85921 },
    { lat: 48.266679, lng: 17.859223 },
    { lat: 48.267472, lng: 17.860088 },
    { lat: 48.26806, lng: 17.860388 },
    { lat: 48.268453, lng: 17.860579 },
    { lat: 48.269226, lng: 17.861184 },
    { lat: 48.269991, lng: 17.861748 },
    { lat: 48.27039, lng: 17.862034 },
    { lat: 48.270714, lng: 17.862209 },
    { lat: 48.270754, lng: 17.86223 },
    { lat: 48.270805, lng: 17.862278 },
    { lat: 48.271159, lng: 17.862539 },
    { lat: 48.271365, lng: 17.862707 },
    { lat: 48.271618, lng: 17.862915 },
    { lat: 48.272215, lng: 17.863404 },
    { lat: 48.272935, lng: 17.86376 },
    { lat: 48.273438, lng: 17.864047 },
    { lat: 48.273462, lng: 17.86406 },
    { lat: 48.27348, lng: 17.864071 },
    { lat: 48.273944, lng: 17.864338 },
    { lat: 48.274547, lng: 17.864205 },
    { lat: 48.275142, lng: 17.864309 },
    { lat: 48.275786, lng: 17.864468 },
    { lat: 48.276682, lng: 17.86553 },
    { lat: 48.276748, lng: 17.865635 },
    { lat: 48.277373, lng: 17.866616 },
    { lat: 48.277518, lng: 17.866637 },
    { lat: 48.278718, lng: 17.866863 },
    { lat: 48.279226, lng: 17.866551 },
    { lat: 48.27964, lng: 17.866295 },
    { lat: 48.279643, lng: 17.866293 },
    { lat: 48.280196, lng: 17.865916 },
    { lat: 48.280689, lng: 17.865608 },
  ],
  VeľkáMača: [
    { lat: 48.2556834, lng: 17.6679623 },
    { lat: 48.2555319, lng: 17.6679233 },
    { lat: 48.2550122, lng: 17.6674682 },
    { lat: 48.2547473, lng: 17.6673117 },
    { lat: 48.2543888, lng: 17.6675906 },
    { lat: 48.2542895, lng: 17.6676113 },
    { lat: 48.254114, lng: 17.667494 },
    { lat: 48.2539825, lng: 17.6671971 },
    { lat: 48.2538279, lng: 17.6671664 },
    { lat: 48.2531649, lng: 17.6674666 },
    { lat: 48.2528816, lng: 17.6673605 },
    { lat: 48.2527069, lng: 17.6671676 },
    { lat: 48.2525647, lng: 17.6668808 },
    { lat: 48.2525148, lng: 17.6663191 },
    { lat: 48.2523911, lng: 17.6661318 },
    { lat: 48.2522868, lng: 17.666144 },
    { lat: 48.2518167, lng: 17.6665669 },
    { lat: 48.2510991, lng: 17.6668266 },
    { lat: 48.2509955, lng: 17.6670119 },
    { lat: 48.2510024, lng: 17.6676993 },
    { lat: 48.2508344, lng: 17.6684049 },
    { lat: 48.2507674, lng: 17.6689539 },
    { lat: 48.250464, lng: 17.6694816 },
    { lat: 48.2504523, lng: 17.669676 },
    { lat: 48.2507693, lng: 17.6699959 },
    { lat: 48.251004, lng: 17.6704589 },
    { lat: 48.2511094, lng: 17.671041 },
    { lat: 48.2510799, lng: 17.671363 },
    { lat: 48.250927, lng: 17.6716077 },
    { lat: 48.2507175, lng: 17.6717427 },
    { lat: 48.250424, lng: 17.6717967 },
    { lat: 48.2499223, lng: 17.6722789 },
    { lat: 48.2497329, lng: 17.672319 },
    { lat: 48.2495302, lng: 17.6723078 },
    { lat: 48.2489478, lng: 17.6718987 },
    { lat: 48.2488123, lng: 17.671233 },
    { lat: 48.2484905, lng: 17.6707458 },
    { lat: 48.2484396, lng: 17.6705644 },
    { lat: 48.248447, lng: 17.6701245 },
    { lat: 48.2483064, lng: 17.6695239 },
    { lat: 48.2479792, lng: 17.6688944 },
    { lat: 48.2478583, lng: 17.6682649 },
    { lat: 48.2476695, lng: 17.6680608 },
    { lat: 48.2473266, lng: 17.667978 },
    { lat: 48.2471731, lng: 17.6680502 },
    { lat: 48.2467218, lng: 17.6688144 },
    { lat: 48.2465159, lng: 17.6690049 },
    { lat: 48.2455659, lng: 17.6693606 },
    { lat: 48.2452331, lng: 17.6695615 },
    { lat: 48.2449911, lng: 17.6697553 },
    { lat: 48.244372, lng: 17.6703408 },
    { lat: 48.2438466, lng: 17.6706598 },
    { lat: 48.2434708, lng: 17.6711869 },
    { lat: 48.2430257, lng: 17.6712885 },
    { lat: 48.2429262, lng: 17.671414 },
    { lat: 48.2428638, lng: 17.6716594 },
    { lat: 48.2429892, lng: 17.6721657 },
    { lat: 48.2430028, lng: 17.6723827 },
    { lat: 48.2429419, lng: 17.672618 },
    { lat: 48.2428453, lng: 17.6726974 },
    { lat: 48.2418057, lng: 17.6728644 },
    { lat: 48.2417566, lng: 17.6727828 },
    { lat: 48.2416739, lng: 17.6721122 },
    { lat: 48.2415268, lng: 17.6718761 },
    { lat: 48.2400555, lng: 17.6717322 },
    { lat: 48.2396877, lng: 17.672186 },
    { lat: 48.2394281, lng: 17.6721523 },
    { lat: 48.2391618, lng: 17.6719542 },
    { lat: 48.239046, lng: 17.671945 },
    { lat: 48.2383096, lng: 17.6732385 },
    { lat: 48.238086, lng: 17.6733993 },
    { lat: 48.2377269, lng: 17.6734168 },
    { lat: 48.2375496, lng: 17.6732558 },
    { lat: 48.2372056, lng: 17.6725585 },
    { lat: 48.2372222, lng: 17.6723178 },
    { lat: 48.2373019, lng: 17.6721522 },
    { lat: 48.2377615, lng: 17.6717731 },
    { lat: 48.2377711, lng: 17.6714767 },
    { lat: 48.2375451, lng: 17.6710944 },
    { lat: 48.2370464, lng: 17.6707951 },
    { lat: 48.2361303, lng: 17.6705014 },
    { lat: 48.2357722, lng: 17.6704621 },
    { lat: 48.2349687, lng: 17.670523 },
    { lat: 48.2346948, lng: 17.6706037 },
    { lat: 48.2344986, lng: 17.6704807 },
    { lat: 48.2344047, lng: 17.6702446 },
    { lat: 48.2342607, lng: 17.6700538 },
    { lat: 48.2341572, lng: 17.6700063 },
    { lat: 48.2334916, lng: 17.670051 },
    { lat: 48.2333024, lng: 17.6699891 },
    { lat: 48.2331144, lng: 17.6698176 },
    { lat: 48.2328787, lng: 17.6694239 },
    { lat: 48.2328113, lng: 17.669217 },
    { lat: 48.2328848, lng: 17.6689492 },
    { lat: 48.2332433, lng: 17.6686325 },
    { lat: 48.2333058, lng: 17.6684597 },
    { lat: 48.2333051, lng: 17.6683061 },
    { lat: 48.2331928, lng: 17.6680811 },
    { lat: 48.2328496, lng: 17.6678825 },
    { lat: 48.2322646, lng: 17.6679336 },
    { lat: 48.2321396, lng: 17.667869 },
    { lat: 48.2320912, lng: 17.667708 },
    { lat: 48.2320698, lng: 17.6670094 },
    { lat: 48.2318855, lng: 17.6665544 },
    { lat: 48.2314483, lng: 17.6658878 },
    { lat: 48.2313209, lng: 17.6657654 },
    { lat: 48.2311398, lng: 17.6657757 },
    { lat: 48.2308081, lng: 17.6660132 },
    { lat: 48.2306337, lng: 17.6659482 },
    { lat: 48.2304702, lng: 17.6656468 },
    { lat: 48.2303434, lng: 17.6651911 },
    { lat: 48.2303629, lng: 17.6650357 },
    { lat: 48.2302771, lng: 17.6648931 },
    { lat: 48.2273842, lng: 17.6674807 },
    { lat: 48.2273595, lng: 17.6675011 },
    { lat: 48.2278316, lng: 17.66878 },
    { lat: 48.2280162, lng: 17.6691688 },
    { lat: 48.2287203, lng: 17.671174 },
    { lat: 48.2264371, lng: 17.673242 },
    { lat: 48.2256433, lng: 17.6743928 },
    { lat: 48.2254905, lng: 17.6750733 },
    { lat: 48.2251375, lng: 17.6757439 },
    { lat: 48.2249452, lng: 17.6757947 },
    { lat: 48.2245667, lng: 17.6755925 },
    { lat: 48.2243662, lng: 17.6753824 },
    { lat: 48.2239835, lng: 17.6748076 },
    { lat: 48.2239303, lng: 17.6739787 },
    { lat: 48.2242494, lng: 17.6724425 },
    { lat: 48.2238655, lng: 17.6719009 },
    { lat: 48.2236457, lng: 17.6719268 },
    { lat: 48.2231636, lng: 17.6721786 },
    { lat: 48.2229124, lng: 17.6724402 },
    { lat: 48.2222993, lng: 17.6737897 },
    { lat: 48.2222591, lng: 17.6739398 },
    { lat: 48.2221177, lng: 17.6760809 },
    { lat: 48.2218864, lng: 17.676225 },
    { lat: 48.221677, lng: 17.676595 },
    { lat: 48.2205883, lng: 17.6779609 },
    { lat: 48.2202814, lng: 17.6780918 },
    { lat: 48.2199237, lng: 17.6780213 },
    { lat: 48.2193136, lng: 17.6776753 },
    { lat: 48.218974, lng: 17.6772482 },
    { lat: 48.218809, lng: 17.6771191 },
    { lat: 48.218505, lng: 17.6771491 },
    { lat: 48.2178896, lng: 17.6774359 },
    { lat: 48.219253, lng: 17.6812453 },
    { lat: 48.2192958, lng: 17.6814605 },
    { lat: 48.2192681, lng: 17.681577 },
    { lat: 48.2187837, lng: 17.682516 },
    { lat: 48.2185541, lng: 17.6827068 },
    { lat: 48.217799, lng: 17.6829625 },
    { lat: 48.2171351, lng: 17.6836309 },
    { lat: 48.2166509, lng: 17.683758 },
    { lat: 48.2162622, lng: 17.6837742 },
    { lat: 48.2160963, lng: 17.6838644 },
    { lat: 48.2159057, lng: 17.6842275 },
    { lat: 48.2155725, lng: 17.6860674 },
    { lat: 48.2154679, lng: 17.686361 },
    { lat: 48.2152301, lng: 17.6867643 },
    { lat: 48.2148833, lng: 17.6870689 },
    { lat: 48.2143179, lng: 17.6885317 },
    { lat: 48.2142993, lng: 17.6885605 },
    { lat: 48.2144351, lng: 17.6891082 },
    { lat: 48.215172, lng: 17.6911386 },
    { lat: 48.2161665, lng: 17.6947059 },
    { lat: 48.2175158, lng: 17.6989502 },
    { lat: 48.2182327, lng: 17.7007744 },
    { lat: 48.219494, lng: 17.7024563 },
    { lat: 48.2201916, lng: 17.7033016 },
    { lat: 48.2203162, lng: 17.703453 },
    { lat: 48.2207277, lng: 17.7027748 },
    { lat: 48.2209553, lng: 17.7026042 },
    { lat: 48.2211995, lng: 17.7026089 },
    { lat: 48.2219372, lng: 17.7030513 },
    { lat: 48.2221261, lng: 17.7030918 },
    { lat: 48.2227055, lng: 17.7031131 },
    { lat: 48.2236374, lng: 17.702669 },
    { lat: 48.2240027, lng: 17.7023173 },
    { lat: 48.2241614, lng: 17.7019293 },
    { lat: 48.2241749, lng: 17.7016764 },
    { lat: 48.2240694, lng: 17.7013962 },
    { lat: 48.223278, lng: 17.7006554 },
    { lat: 48.2229155, lng: 17.7000592 },
    { lat: 48.222818, lng: 17.699636 },
    { lat: 48.2228838, lng: 17.6993183 },
    { lat: 48.2230412, lng: 17.6990885 },
    { lat: 48.2234386, lng: 17.6989079 },
    { lat: 48.2243186, lng: 17.6991487 },
    { lat: 48.2247759, lng: 17.6991133 },
    { lat: 48.2249965, lng: 17.6990318 },
    { lat: 48.2259135, lng: 17.6982926 },
    { lat: 48.2261744, lng: 17.697788 },
    { lat: 48.2261679, lng: 17.6975219 },
    { lat: 48.2259262, lng: 17.6971216 },
    { lat: 48.2256955, lng: 17.6970272 },
    { lat: 48.2253177, lng: 17.6965656 },
    { lat: 48.2244671, lng: 17.6961415 },
    { lat: 48.2243243, lng: 17.6959544 },
    { lat: 48.2241809, lng: 17.6955917 },
    { lat: 48.2240166, lng: 17.6945376 },
    { lat: 48.2241296, lng: 17.6941964 },
    { lat: 48.2243143, lng: 17.693934 },
    { lat: 48.2245671, lng: 17.693826 },
    { lat: 48.2247291, lng: 17.6938698 },
    { lat: 48.2252597, lng: 17.6942459 },
    { lat: 48.2262548, lng: 17.6953158 },
    { lat: 48.2270099, lng: 17.6958545 },
    { lat: 48.2273671, lng: 17.6961872 },
    { lat: 48.227937, lng: 17.6973015 },
    { lat: 48.2283546, lng: 17.6975521 },
    { lat: 48.2288072, lng: 17.6976392 },
    { lat: 48.2289774, lng: 17.6975644 },
    { lat: 48.229126, lng: 17.6973563 },
    { lat: 48.2293032, lng: 17.6969596 },
    { lat: 48.2293598, lng: 17.6966727 },
    { lat: 48.2292798, lng: 17.6956077 },
    { lat: 48.2293856, lng: 17.6956393 },
    { lat: 48.2298572, lng: 17.6961214 },
    { lat: 48.2304096, lng: 17.6971004 },
    { lat: 48.2323703, lng: 17.7014462 },
    { lat: 48.2329225, lng: 17.7024937 },
    { lat: 48.2338469, lng: 17.7039725 },
    { lat: 48.2343675, lng: 17.7050422 },
    { lat: 48.2348594, lng: 17.7061941 },
    { lat: 48.2378798, lng: 17.71086 },
    { lat: 48.2379862, lng: 17.7100178 },
    { lat: 48.2379552, lng: 17.7098812 },
    { lat: 48.2374929, lng: 17.7090583 },
    { lat: 48.2373285, lng: 17.7084331 },
    { lat: 48.2373611, lng: 17.7079444 },
    { lat: 48.2374375, lng: 17.7076819 },
    { lat: 48.2377017, lng: 17.7073018 },
    { lat: 48.2379106, lng: 17.7071957 },
    { lat: 48.2380314, lng: 17.7072124 },
    { lat: 48.2382637, lng: 17.7073524 },
    { lat: 48.2383789, lng: 17.7075148 },
    { lat: 48.2384461, lng: 17.707833 },
    { lat: 48.2383872, lng: 17.7082649 },
    { lat: 48.2383965, lng: 17.7085593 },
    { lat: 48.2389401, lng: 17.7094899 },
    { lat: 48.2390424, lng: 17.7095701 },
    { lat: 48.2395759, lng: 17.7096351 },
    { lat: 48.23978, lng: 17.7095573 },
    { lat: 48.2400795, lng: 17.70931 },
    { lat: 48.2403043, lng: 17.7088408 },
    { lat: 48.2404887, lng: 17.7088125 },
    { lat: 48.2423814, lng: 17.7127629 },
    { lat: 48.2440512, lng: 17.7167265 },
    { lat: 48.2457381, lng: 17.72028 },
    { lat: 48.246202, lng: 17.7211642 },
    { lat: 48.2465448, lng: 17.7218298 },
    { lat: 48.246781, lng: 17.7216328 },
    { lat: 48.2478202, lng: 17.7210991 },
    { lat: 48.2482814, lng: 17.7205257 },
    { lat: 48.2494295, lng: 17.7199098 },
    { lat: 48.2509215, lng: 17.7176878 },
    { lat: 48.2518464, lng: 17.7169291 },
    { lat: 48.2530297, lng: 17.7161144 },
    { lat: 48.2536546, lng: 17.7157776 },
    { lat: 48.2543431, lng: 17.7156106 },
    { lat: 48.2552426, lng: 17.7151153 },
    { lat: 48.2559242, lng: 17.7140405 },
    { lat: 48.2561661, lng: 17.7117994 },
    { lat: 48.2564972, lng: 17.7107157 },
    { lat: 48.2570277, lng: 17.7098298 },
    { lat: 48.2574295, lng: 17.7090104 },
    { lat: 48.2577262, lng: 17.7082114 },
    { lat: 48.2585918, lng: 17.7064299 },
    { lat: 48.2588367, lng: 17.7057211 },
    { lat: 48.2595797, lng: 17.7042937 },
    { lat: 48.2607578, lng: 17.7030898 },
    { lat: 48.2597457, lng: 17.7014571 },
    { lat: 48.2604312, lng: 17.7006811 },
    { lat: 48.2591594, lng: 17.6983779 },
    { lat: 48.2596172, lng: 17.6980593 },
    { lat: 48.2598279, lng: 17.6976932 },
    { lat: 48.2598765, lng: 17.6974944 },
    { lat: 48.2598811, lng: 17.6970983 },
    { lat: 48.2598365, lng: 17.6968009 },
    { lat: 48.2596267, lng: 17.6960635 },
    { lat: 48.2595445, lng: 17.6952895 },
    { lat: 48.2595658, lng: 17.6936431 },
    { lat: 48.2596922, lng: 17.6932271 },
    { lat: 48.2599418, lng: 17.6928633 },
    { lat: 48.260067, lng: 17.6927926 },
    { lat: 48.2605926, lng: 17.6929266 },
    { lat: 48.2611552, lng: 17.6932403 },
    { lat: 48.262169, lng: 17.6932172 },
    { lat: 48.2626396, lng: 17.6937196 },
    { lat: 48.2629655, lng: 17.6942554 },
    { lat: 48.2636043, lng: 17.6956446 },
    { lat: 48.2637795, lng: 17.695828 },
    { lat: 48.2644152, lng: 17.6960968 },
    { lat: 48.2646799, lng: 17.6960873 },
    { lat: 48.2652241, lng: 17.6957439 },
    { lat: 48.2653831, lng: 17.6954714 },
    { lat: 48.265803, lng: 17.6942389 },
    { lat: 48.2658198, lng: 17.6938038 },
    { lat: 48.2655463, lng: 17.6913258 },
    { lat: 48.2653532, lng: 17.6908022 },
    { lat: 48.2650226, lng: 17.6903361 },
    { lat: 48.2645334, lng: 17.6893725 },
    { lat: 48.2642537, lng: 17.6886768 },
    { lat: 48.2642475, lng: 17.6882145 },
    { lat: 48.2644352, lng: 17.6877491 },
    { lat: 48.264766, lng: 17.6872962 },
    { lat: 48.2653427, lng: 17.687003 },
    { lat: 48.2660369, lng: 17.6868935 },
    { lat: 48.2667352, lng: 17.6869926 },
    { lat: 48.2673323, lng: 17.6867915 },
    { lat: 48.2681963, lng: 17.6866312 },
    { lat: 48.26844, lng: 17.6865219 },
    { lat: 48.2687249, lng: 17.6861282 },
    { lat: 48.268855, lng: 17.6857531 },
    { lat: 48.2688685, lng: 17.6854815 },
    { lat: 48.2687998, lng: 17.6852147 },
    { lat: 48.2686397, lng: 17.6849827 },
    { lat: 48.2680654, lng: 17.6848833 },
    { lat: 48.2675413, lng: 17.6849321 },
    { lat: 48.2673681, lng: 17.684871 },
    { lat: 48.2670318, lng: 17.6844235 },
    { lat: 48.2669279, lng: 17.684117 },
    { lat: 48.2669298, lng: 17.6837691 },
    { lat: 48.267499, lng: 17.6829595 },
    { lat: 48.2681095, lng: 17.6806056 },
    { lat: 48.268264, lng: 17.6802618 },
    { lat: 48.2684405, lng: 17.680035 },
    { lat: 48.2695071, lng: 17.6790335 },
    { lat: 48.2697389, lng: 17.6789672 },
    { lat: 48.2700412, lng: 17.6790878 },
    { lat: 48.2701139, lng: 17.6792392 },
    { lat: 48.2701775, lng: 17.6787061 },
    { lat: 48.2697336, lng: 17.6776021 },
    { lat: 48.2682417, lng: 17.6745545 },
    { lat: 48.2679524, lng: 17.6740643 },
    { lat: 48.2662317, lng: 17.6725878 },
    { lat: 48.2654795, lng: 17.6720239 },
    { lat: 48.2648441, lng: 17.6711291 },
    { lat: 48.2634756, lng: 17.6696727 },
    { lat: 48.2621466, lng: 17.6677275 },
    { lat: 48.2615293, lng: 17.6667049 },
    { lat: 48.2591601, lng: 17.6616315 },
    { lat: 48.2590904, lng: 17.6615272 },
    { lat: 48.2588483, lng: 17.6620584 },
    { lat: 48.2588483, lng: 17.6623292 },
    { lat: 48.2584429, lng: 17.6630481 },
    { lat: 48.2585047, lng: 17.6633126 },
    { lat: 48.2586998, lng: 17.663417 },
    { lat: 48.2588722, lng: 17.6633392 },
    { lat: 48.2591722, lng: 17.6629022 },
    { lat: 48.259398, lng: 17.6629027 },
    { lat: 48.2596095, lng: 17.6631595 },
    { lat: 48.2596528, lng: 17.6633285 },
    { lat: 48.2596305, lng: 17.6637271 },
    { lat: 48.2595026, lng: 17.6641544 },
    { lat: 48.2593031, lng: 17.6644478 },
    { lat: 48.2590664, lng: 17.6651367 },
    { lat: 48.2590763, lng: 17.6655995 },
    { lat: 48.2591741, lng: 17.6658128 },
    { lat: 48.2592331, lng: 17.6661032 },
    { lat: 48.2590994, lng: 17.6664388 },
    { lat: 48.2585658, lng: 17.6669133 },
    { lat: 48.2584336, lng: 17.6669113 },
    { lat: 48.2581271, lng: 17.6664194 },
    { lat: 48.2579991, lng: 17.6660165 },
    { lat: 48.2578253, lng: 17.6658903 },
    { lat: 48.2577338, lng: 17.6659092 },
    { lat: 48.2574924, lng: 17.6663415 },
    { lat: 48.2573119, lng: 17.6665267 },
    { lat: 48.2567072, lng: 17.6669314 },
    { lat: 48.2564294, lng: 17.6677043 },
    { lat: 48.256301, lng: 17.6678725 },
    { lat: 48.2559753, lng: 17.6680062 },
    { lat: 48.2557628, lng: 17.6679872 },
    { lat: 48.2556834, lng: 17.6679623 },
  ],
};

export default UnitsGalanta;
