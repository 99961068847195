import HistoryList from "../History/HistoryList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import { useMediaQuery } from "usehooks-ts";

export default function HistoryPanel({
  openLeftBar,
  setLeftBar,
  setVisibilityOfMarkers,
  reversedHistoryItems,
  map,
  setCurrentIndex,
}) {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 640px)");

  return (
    <div
      className={clsx(
        "transition-all duration-200 ease-linear overflow-auto absolute top-16 left-0 sm:top-0 sm:left-16 bg-sky-100 w-screen sm:w-0 h-0 sm:h-screen flex flex-col items-center gap-4",
        {
          "h-44 p-3 sm:w-96": openLeftBar.history && !isSmallDevice,
        },
      )}
    >
      {openLeftBar.history ? (
        <>
          <div className="w-full flex flex-row justify-center">
            <h1 className="text-lg">Nedávne</h1>
            <div className="absolute right-3">
              <button
                onClick={() => {
                  setLeftBar({ ...openLeftBar, history: false });
                  setVisibilityOfMarkers("false");
                }}
              >
                <FontAwesomeIcon icon="xmark" className="text-xl" />
              </button>
            </div>
          </div>
          <div className="w-full flex flex-col gap-2">
            <HistoryList
              historyItems={reversedHistoryItems}
              extended={true}
              map={map}
              setCurrentIndex={setCurrentIndex}
              setVisibilityOfMarkers={setVisibilityOfMarkers}
            />
          </div>
        </>
      ) : null}
    </div>
  );
}
