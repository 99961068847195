import { useMediaQuery } from "usehooks-ts";
import clsx from "clsx";
import AkcenLogo from "../../../Images/logo/akcen_logo_v3_black_trans_edit.png";

export default function HomeNav({
  setMapActive,
  setPriceMap,
  priceMap,
  setGotResponse,
  setVisibilityOfMarkers,
  setNavSmall,
  navSmall,
  setForSale,
  forSale,
  setGallery,
  setHistory,
  setHistoryMenu,
  setSubMenu,
  subMenu,
  setViewSale,
  setCurrentItem,
  viewSale,
  subjects,
  setSubjects,
  setFilterSale,
  optSubMenu,
  setOptSubMenu,
  PMResult,
  setPMResult,
  map,
  setZoom,
}) {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 640px)");

  const closeAll = () => {
    setPMResult({ ...PMResult, active: false });
    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;
      map.panTo({
        lat: latitude !== undefined ? latitude : 48.747332,
        lng: longitude !== undefined ? longitude : 21.92219,
      });
    });
    setZoom(15);
    setPriceMap({
      ...priceMap,
      region: false,
      district: false,
      city: false,
      districtName: "",
      cityName: "",
    });
    setMapActive(true);
    setGotResponse(false);
    setVisibilityOfMarkers("false");
    setNavSmall({
      ...navSmall,
      properties: false,
      subjects: false,
      helper: false,
      options: false,
      login: false,
    });
    setForSale({ ...forSale, menu: false });
    setGallery(false);
    setHistory(false);
    setHistoryMenu(false);
    setSubMenu({ ...subMenu, lv: false, interest: false, owner: true, rating: false, share: false });
    setViewSale({ ...viewSale, view: false });
    setVisibilityOfMarkers("");
    setCurrentItem(null);
    setSubjects({ ...subjects, ROffice: false, RBroker: false, FInstitution: false, FBroker: false });
    setOptSubMenu({ ...optSubMenu, aboutUs: false, contact: false, privacy: false, helper: false });
    setFilterSale([]);
  };

  return (
    <>
      <div className={`transition-all duration-500 ease-linear min-h-max flex flex-col justify-center w-14 sm:w-auto`}>
        <button
          className={`disabled:opacity-60 px-3 py-2 flex items-center justify-center text-xs leading-snug text-black sm:flex-col hover:opacity-75 gap-2`}
          onClick={() => closeAll()}
        >
          <div
            className={clsx("flex flex-col justify-center items-center", {
              "w-16": !isSmallDevice,
            })}
          >
            <img src={AkcenLogo} alt="logo" className="w-8 h-[22px] object-contain" />
            {isSmallDevice ? null : <p className="w-full overflow-hidden text-ellipsis">Domov</p>}
          </div>
        </button>
      </div>
    </>
  );
}
