import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEuro } from "@fortawesome/free-solid-svg-icons";

export default function Price({ inputValues, setInputValues }) {
  return (
    <motion.div onClick={(e) => e.stopPropagation()} className="w-full flex flex-col items-start pt-1">
      <p className="font-semibold flex flex-row items-center gap-1">
        <FontAwesomeIcon icon={faEuro} />
        Suma
      </p>
      <div className="flex flex-row items-center w-full py-2 border-b border-opacity-50 border-b-sky-500">
        <div className="flex flex-row gap-1 w-full items-center">
          <p>Suma: </p>
          <input
            placeholder="100000"
            value={inputValues?.price || ""}
            onChange={(e) => setInputValues({ ...inputValues, price: e.target.value })}
            className="w-4/5 h-6 rounded-lg bg-sky-100 placeholder:text-gray-500 placeholder:font-normal border border-opacity-50 border-sky-500"
            type="number"
          />
          €
        </div>
      </div>
    </motion.div>
  );
}
