import { motion } from "framer-motion";

export default function AboutUs({ subMenu, previous, office }) {
  return subMenu.owner ? (
    <motion.div
      onClick={(e) => e.stopPropagation()}
      className="w-auto flex flex-col items-start overflow-x-scroll pt-2 px-1"
      initial={{ x: previous === "" ? null : -500 }}
      animate={{ x: previous === "" ? null : 0 }}
      transition={{ duration: 0.5 }}
    >
      {office?.name}
    </motion.div>
  ) : null;
}
